define([
    'angular',
    './detailedDiagnosticController',
    './detailedDiagnosticService'

], function () {
    'use strict';
    return angular.module('app.detailedDiagnostic', ['app.detailedDiagnosticController','app.deatiledDiagnosticService'] )
    
    // .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
    //     $stateProvider
    //         .state('workspace.detail-diagnostic-new', {
    //             url: '/detail-diagnostic-new',
    //             templateUrl: ' app/components/diagnosticDashboard/detailedDiagnostic/detailed-diagnostic.html',
    //             type:'custom',
    //             "api": {"get":GENERAL_CONFIG.base_url + "loadGridJson?grid_id=5157&action_id=30227","grid_id":"5157"},
    //             access: "4"
    //         });
    // }]);
});
