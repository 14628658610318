define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.partnershipMtwoTaxService',[])
        .factory("partnershipMtwoTaxFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
            function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){

                var returnObj = {};
                var partnershipMtwoTaxFactory ={};
                partnershipMtwoTaxFactory.url ={};

                partnershipMtwoTaxFactory.url.saveM2TaxAmounts= GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qttpro";

                partnershipMtwoTaxFactory.saveM2TaxAmounts = function(_callName ,_data){
                    console.log("data", _data);
                    console.log("callname",_callName);

                    var rowData = _data.rowData;
                    var service = $injector.get('JsonObjectFactory');
                    var defer = $q.defer();

                    returnObj.combination_key = _data.rowData.COMBINATION_KEY;

                    var jsonObj = [{
                        combination_key: _data.rowData.COMBINATION_KEY,
                        trans_sub_type: _data.rowData[_data.colData.map+'_TRANS_TYPE'],
                        amount: _data.rowData[_data.colData.map]
                    }];
                    console.log(jsonObj);

                    var jsonSettings = [{
                        tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        group_trans_key: _data.rowData.GROUP_TRANS_KEY
                    }];

                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = _.merge({}, GlobalService.globalParams, filterParams);
                    var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
                    var params = _.extend({jsonSettings: JSON.stringify(jsonSettings)}, params);

                    service.saveJSON(partnershipMtwoTaxFactory.url.saveM2TaxAmounts,params).then(function (data) {
                        if(data.callSuccess === "1"){
                            AlertService.add("success", "Data saved Successfully.",3000);
                            var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
                            $rootScope.$emit('gridUpdate', args);
                            defer.resolve(data);
                        }else {
                            AlertService.add("error", data.errorMessage);
                            defer.reject(data);
                        }
                    },function(data){
                        defer.reject(data);
                    });
                    return defer.promise;
                }


                return partnershipMtwoTaxFactory;
            }]);


    return services;
});


