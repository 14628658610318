define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.batchOpenInAmendedController', [])
            .controller('batchOpenInAmendedController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'batchOpenInAmendedServiceFactory',
                        'rowData', 'gridData', batchOpenInAmendedController]
            );
            function batchOpenInAmendedController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, batchOpenInAmendedServiceFactory, rowData, gridData) {

                let vm = this;
                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    
                vm.userSettings = USER_SETTINGS;
    
                vm.tax_year = filterParams.tax_year;
                vm.scenario = filterParams.scenario;
                vm.jcd_key = GlobalService.globalParams.jcd_key;
                vm.logged_in_user = vm.userSettings.user.sso_id;
    
                vm.currentyear = filterParams.tax_year;
                vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
                vm.isSaveClicked = false;
                vm.syncSaveDtls = [];
    
                if(gridData != null) {
    
                    vm.gridDataMultipleSelect =  _.clone(gridData);
    
                    if (vm.gridDataMultipleSelect != undefined) {
    
                        for(let key in vm.gridDataMultipleSelect) {
                            let gridObj = {};
                            gridObj["ho_le_key"] = vm.gridDataMultipleSelect[key].data.HO_LE_KEY;
                            vm.syncSaveDtls.push(gridObj);
                        }
                    }
                }
    
                vm.fetchMaxCountEntities = function() {
                    var params = { "action_code": 'mysfkb', "screen_key": GlobalService.globalParams.screen_key};
                    return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                        vm.allocationLimit = data.jsonObject[0].ATTRIB_VALUE;
                    });
                }
    
                vm.fetchMaxCountEntities();
    
                vm.confirm_sync = function() {
    
                    if(vm.syncSaveDtls.length > parseInt(vm.allocationLimit)){
                        AlertService.add("error", "Can't select more than " + vm.allocationLimit + " partnerships.");
                        return;
                    }
    
                    let returnClobSettingsObj = {};
    
                    returnClobSettingsObj['tax_year'] = vm.tax_year;
                    returnClobSettingsObj['scenario'] = vm.scenario;
                    returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                    returnClobSettingsObj['sso_id'] = vm.logged_in_user;
    
                    let ecSaveDtls = vm.syncSaveDtls;
                    if(vm.syncSaveDtls != undefined && vm.syncSaveDtls.length > 0) {
                        let message = "Selected Partnerships Submitted for Bulk Open in Amended Cycle";
                        sendDetails(returnClobSettingsObj,ecSaveDtls, message);
                    }
                };
    
                vm.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
    
                function sendDetails(returnClobSettingsObj, ecSaveDtls, message) {
                    $rootScope.$broadcast("showLoader", true);
                    $uibModalInstance.dismiss('cancel');
                    batchOpenInAmendedServiceFactory.batchOpenPshipsInAmended(returnClobSettingsObj,ecSaveDtls).then(function(result) {
                        if (result.errorMessage && result.errorMessage !== 'null') {
                            vm.isSaveClicked = false;
                            AlertService.add("error", result.errorMessage, 4000);
                        } else {
                            AlertService.add("success", message, 4000);
                        };
                        $rootScope.$broadcast("showLoader", false);
                    });
                }                
            
            }
            
            return controllers;
        }
    );            