define(
		[ 'angular'

			],
			function() {
			'use strict';

			var controllers = angular.module('app.ConsolidationLedgersCtrl', [])
			.controller('ConsolidationLedgersPartCtrl', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory', 'rowData', 'gridData','ConsolidationLedgersServiceFactory', ConsolidationLedgersPartCtrl])


			function ConsolidationLedgersPartCtrl($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,rowData, gridData,ConsolidationLedgersServiceFactory) {

				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.curentyear = filterParams.tax_year;
				vm.scopeRowData = JSON.parse(JSON.stringify($scope.$resolve.rowData));
				vm.loadingData = true;
				vm.variance = 0;
				vm.tableHeader = [
					"ME Code",
					"Account",
					"Account Desc",
					"Amount"
					];
				vm.tabList = [
					"Income Statement",
					"Balance Sheet"
					];
				vm.tabName = "Income Statement";

				Array.prototype.groupBy = function(prop) {
					return this.reduce(function(groups, item) {
						const val = item[prop]
						groups[val] = groups[val] || []
						groups[val].push(item)
						return groups
					}, {})
				}

				//Fetch Ledger Data.
				vm.fetchIncStmtData = function(){
					var params = { "action_code": 'cdc47d', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "me_codes": vm.scopeRowData.ME_CODE, "le_key": vm.scopeRowData.LE_KEY, "cdr_no": vm.scopeRowData.CDR_NO, "ledger": vm.scopeRowData.LEDGER, "combination_key": vm.scopeRowData.COMBINATION_KEY, "scenario": null, "jcd_key": GlobalService.globalParams.jcd_key
					};
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						vm.incomeStmtList = JSON.parse(JSON.stringify(data.jsonObject));
						var map = new Map();
						//To get total amount for each account.
						for(var i = 0;i<vm.incomeStmtList.length;i++) {
							if(map != null && map.has(vm.incomeStmtList[i].ACCOUNT)){
								var amountMap = parseInt(map.get(vm.incomeStmtList[i].ACCOUNT)) + parseInt(vm.incomeStmtList[i].AMOUNT); 
								map.set(vm.incomeStmtList[i].ACCOUNT,amountMap);
							}else{
								map.set(vm.incomeStmtList[i].ACCOUNT,parseInt(vm.incomeStmtList[i].AMOUNT));
							}
							vm.incomeStmtList[i].AMOUNT = vm.incomeStmtList[i].AMOUNT + "";
						}
						//Add the total amount of an account to each data row line.
						for(var j = 0;j<vm.incomeStmtList.length;j++) {
							vm.incomeStmtList[j].TOTAL_AMOUNT = parseInt(map.get(vm.incomeStmtList[j].ACCOUNT));
							vm.incomeStmtList[j].IS_DISPLAY = true;
						}
						//Sort the list in the order of the account
						vm.incomeStmtList.sort(compare);
						vm.incomeStmtListBackup = angular.copy(vm.incomeStmtList);
						vm.getVariance();
						vm.loadingData = false;

						if(Array.isArray(vm.incomeStmtList) && vm.incomeStmtList.length > 0)
						{	vm.noRecords = false;
						}else{
							vm.noRecords = true;	
						}
					});
				}


				vm.fetchBalSheetData = function(){
					var params = { "action_code": 'p7xrsm', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": null, "jcd_key": GlobalService.globalParams.jcd_key, "me_codes": vm.scopeRowData.ME_CODE, "combination_key": vm.scopeRowData.COMBINATION_KEY 
					};
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						vm.balanceSheetList = JSON.parse(JSON.stringify(data.jsonObject));
					});
				}



				//Fetch the category List
				vm.fetchCateList = function(){
					var params = { "action_code": '4l2j4c', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": null, "jcd_key": GlobalService.globalParams.jcd_key 
					};
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						console.log(data);
						vm.catDropdown = data.jsonObject;
						vm.loadingData = false;
					});
				}

				vm.fetchIncStmtData();
				vm.fetchBalSheetData();
				vm.fetchCateList();

				//To get the track by as combination key
				vm.itemTracker= function(item) {
					return item.ACCOUNT + '-' + item.OCCURRENCE;
				}

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				vm.getVariance = function(){
					var total = 0;
					for(var i=0;i<vm.incomeStmtList.length;i++){
						if(parseInt(vm.incomeStmtList[i].AMOUNT) == undefined || parseInt(vm.incomeStmtList[i].AMOUNT) == null){
							vm.incomeStmtList[i].AMOUNT = "0";
						}
						if(vm.incomeStmtList[i].IS_DISPLAY) {
							total = total + parseInt(vm.incomeStmtList[i].AMOUNT);
						}
					}
					console.log("total value:" + total);
					vm.variance = vm.scopeRowData.NET_INCM - total;
				}

				//value change listener for input text. Filter the incomeStmtList for account on which the value is changed excluding the first non editable
				//row. Then iterate the list to get total amount for the account, then subtract that amount with first (Non-editable) row.
				//Make the first row type as 'U' as value of non editbale has changed.
				//Make the row type of the value changed text to 'U' if it is not inserted by user in this transaction.
				vm.valueChanged = function(row){
					var localList = vm.incomeStmtList.filter(item => row.ACCOUNT == item.ACCOUNT && item.OCCURRENCE != 1 && item.IS_DISPLAY);
					var total = 0;
					for(var i=0;i<localList.length;i++){
						if(parseInt(localList[i].AMOUNT) == undefined || parseInt(localList[i].AMOUNT) == null || localList[i].AMOUNT == ""){
							localList[i].AMOUNT = "0";
						}
						total = total + parseInt(localList[i].AMOUNT);
					} 

					row.AMOUNT = parseInt(row.AMOUNT) + "";//To remove zeros

					var localObj1 = vm.incomeStmtList.filter(item => row.ACCOUNT == item.ACCOUNT && item.OCCURRENCE == 1 && item.IS_DISPLAY);
					localObj1[0].AMOUNT = (parseInt(localObj1[0].TOTAL_AMOUNT) - total) + "";
					var index = vm.incomeStmtList.findIndex((item) => row.ACCOUNT == item.ACCOUNT && item.OCCURRENCE == 1 && item.IS_DISPLAY);
					localObj1[0].type = 'U';
					vm.incomeStmtList[index] = localObj1[0];
					if(row.type != 'I'){
						row.type = 'U';
					}
					row.AMOUNT = parseInt(row.AMOUNT) + "";

					vm.getVariance();
					return;
				}

				//Value change of the category field and check that if the category selected by other rows of the same account.
				//If this category already present then reset and give error. If this is unique then change the row type to U if it is not 
				//inserted row by user in this transaction.
				vm.valueCategoryChanged = function(row){
					var localObj = vm.incomeStmtList.filter(item => row.ACCOUNT == item.ACCOUNT
							&& item.CATEGORY == row.CATEGORY && item.OCCURRENCE != row.OCCURRENCE && item.IS_DISPLAY);
					console.log("category slected is:" + row.CATEGORY);
					if(localObj != null && localObj.length > 0 ){
						console.log("inside the ifffff");
						AlertService.add("error", "Category cannot be same for the given ACCOUNT = " +row.ACCOUNT,4000);
						row.CATEGORY = "";
						return;
					}
					if(row.type != 'I'){
						row.type = 'U';
					}
					return;
				}

				//Add new row method will first check if there is any row in completed then don't add row, just show the alert.
				//Else add the row with the details of the button clicked (As we need to add row for that account so get the data from that row).
				//Then change then get the next occurrence value by getting the max for that account. Then increment the value by one and add to this
				//row Occurrence and change the type to I, other default values.
				vm.addRow = function(row){
					var localObj = vm.incomeStmtList.filter(item => row.ACCOUNT == item.ACCOUNT
							&& ((item.AMOUNT == "0" && item.OCCURRENCE != '1') || item.CATEGORY == null || item.CATEGORY == "" || item.CATEGORY == "0") && item.IS_DISPLAY);
					if(localObj != null && localObj.length > 0 ){
						AlertService.add("error", "Please fill amount and category details for the ACCOUNT = " +row.ACCOUNT,4000);
						return;
					}
					var row1 = JSON.parse(JSON.stringify(row));
					var filterAccountObj = vm.incomeStmtList.filter(item => row.ACCOUNT == item.ACCOUNT);
					var maxValue = Math.max.apply(Math,filterAccountObj.map(function(item) {
						return item.OCCURRENCE;
					}));
					row1.OCCURRENCE = parseInt(maxValue) + 1;
					row1.type = "I";
					row1.CATEGORY = "";
					row1.AMOUNT = "0";
					vm.incomeStmtList.push(row1);
					vm.incomeStmtList.sort(compare);
					vm.getVariance();
					return;
				}

				//Get the first row (non Editable) of the account.
				//Then if the row to be deleted is inserted in this transaction then remove permanently from the list
				//Else make the type as D. Also add the amount of the deleted row value to first row.
				vm.deleteRow = function(row){
					var localObj = vm.incomeStmtList.filter(item => row.ACCOUNT == item.ACCOUNT && item.OCCURRENCE == 1 && item.IS_DISPLAY);
					localObj[0].AMOUNT = parseInt(localObj[0].AMOUNT) + parseInt(row.AMOUNT) + "";
					if(row.type != 'I'){
						row.type = "D";
						localObj[0].type = 'U';
						var index = vm.incomeStmtList.findIndex((item) => row.ACCOUNT == item.ACCOUNT && item.OCCURRENCE == row.OCCURRENCE && item.IS_DISPLAY);
						row.IS_DISPLAY = false;
						vm.incomeStmtList[index] = row;
					}else{
						vm.incomeStmtList = vm.incomeStmtList.filter(item => !(row.OCCURRENCE == item.OCCURRENCE && row.ACCOUNT == item.ACCOUNT));
					}
					var index = vm.incomeStmtList.findIndex((item) => row.ACCOUNT == item.ACCOUNT && item.OCCURRENCE == 1 );
					vm.incomeStmtList[index] = localObj[0];
					vm.getVariance();
					return;
				}

				//This function is used for sorting by account number. To keep the Occurrence value on the top for second level of compare is
				//on Occurrence.
				function compare(a, b) {
					let comparison = 0;
					if (parseInt(a.ACCOUNT) > parseInt(b.ACCOUNT)) {
						comparison = 1;
					} else if (parseInt(a.ACCOUNT) < parseInt(b.ACCOUNT)) {
						comparison = -1;
					}else if(a.OCCURRENCE == '1'){
						comparison = 1;
					}
					return comparison;
				}


				vm.reset = function(){
					vm.incomeStmtList = angular.copy(vm.incomeStmtListBackup);
					vm.getVariance();
					vm.tabName = "Income Statement";
				}

				//Save function will check if any of the rows are incomplete if yes then give alert error to fill it.
				//Then check if none of the data is modified or inserted if yes then give alert error.
				//If above condition are false then do group by the account value and then make that as Json list with
				//Account and Account list object. This will help to send the return object list with each object with
				//common account values plus list of category list row added to that account.
				//Then call the send details function to save in the data base.
				vm.save = function() {
					var emptyValuesList = vm.incomeStmtList.filter(item => ((item.AMOUNT == "0" && item.OCCURRENCE != '1')) && item.IS_DISPLAY);
					if(emptyValuesList != null && emptyValuesList.length > 0 ){
						AlertService.add("error", "Please fill amount details before saving.",4000);
						return;
					}
					var saveList = vm.incomeStmtList.filter(item => item.type != undefined && item.type != null && item.type != "");
					if(Object.keys(saveList).length === 0){
						AlertService.add("error", "No data has been changed.",4000);
						return;
					}
					vm.isSaveClicked = true;
					var returnClobSettingsObj = {};
					var ledgersFormSaveDtls = [];
					var groupedByAccount = saveList.groupBy('ACCOUNT');
					groupedByAccount = JSON.parse(JSON.stringify(groupedByAccount));
					var groupArray = [];
					for (var groupName in groupedByAccount) {
						groupArray.push({ACCOUNT: groupName, accList: groupedByAccount[groupName]});
					}
					for(var k = 0;k<groupArray.length;k++){
						var account = groupArray[k].ACCOUNT;
						var localAccList = groupArray[k].accList;
						var returnObj = {};
						returnObj['leid'] =  vm.scopeRowData.LEID;
						returnObj['cdr_no'] =  vm.scopeRowData.CDR_NO;
						returnObj['me_code'] =  vm.scopeRowData.ME_CODE;
						returnObj['ledger'] =  vm.scopeRowData.LEDGER;
						returnObj['acct_ref_key'] =  account;
						returnObj['code_combination'] = vm.scopeRowData.COMBINATION_KEY;
						returnObj['trans_header_key'] = saveList[k].TRANS_HEADER_KEY;
						returnObj['group_trans_key'] = saveList[k].GROUP_TRANS_KEY;
						console.log("inside the k loop:" + localAccList);
						var ledgersCatSaveDtls = [];
						for(var l=0;l<localAccList.length;l++){
							var returninnerObj = {};
							returninnerObj['category'] = localAccList[l].CATEGORY;
							returninnerObj['amount'] = parseInt(localAccList[l].AMOUNT);
							returninnerObj['occurrence'] = localAccList[l].OCCURRENCE;
							returninnerObj['cl_dml_type'] = localAccList[l].type;
							ledgersCatSaveDtls.push(returninnerObj);
						}
						returnObj['catList'] =  ledgersCatSaveDtls;
						ledgersFormSaveDtls.push(returnObj);

					}
					var message = "Data has been successfully saved/updated";
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['tax_year'] = vm.curentyear;
					returnClobSettingsObj['scenario'] = GlobalService.globalParams.scenario;
					returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					sendDetails(returnClobSettingsObj,ledgersFormSaveDtls, message);
				}

				function sendDetails(returnClobSettingsObj, ledgersFormSaveDtls, message) {
					ConsolidationLedgersServiceFactory.saveConsLedgers(returnClobSettingsObj,ledgersFormSaveDtls).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							vm.isSaveClicked = false;
							AlertService.add("error", result.data.errorMessage, 4000);
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									combination_key: vm.scopeRowData.COMBINATION_KEY,
									gridFilter: {
										combination_key: vm.scopeRowData.COMBINATION_KEY
									}
							}; 
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
						};

					});
				}
			}
			return controllers;
		});	
