define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.aggregationController', [])
        .controller('aggregationController', ['USER_SETTINGS','$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal', '$parse', 'ClientAdminFactory', 'GENERAL_CONFIG', aggregationController])
function aggregationController (USER_SETTINGS,$rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal, $parse, ClientAdminFactory, GENERAL_CONFIG){
    var vm = this;
    $scope.filter = {}
    vm.data = [
        {"name":"AE/12/01","value":"AE/12/01"},
        {"name":"BE/12/01","value":"BE/12/01"},
        {"name":"CE/12/01","value":"CE/12/01"}]
    $scope.filter.recordMultiSelectObj = {};
    $scope.filter.recordMultiSelectObj.values = vm.data;
    $scope.filter.recordMultiSelectObj.name = 'Record';
    $scope.filter.recordMultiSelectObj.selected =[];

    vm.aggreTrigger = function(){
        console.log(" I am on my way!!!" + $scope.filter.record);
    }

}
return controllers;
})