define(['angular'], function(){
    "use strict";
    let contorllers = angular.module('admin.notificationController',['ngSanitize'])
    .controller('NotificationController', ['$scope','$filter','$state','$log','AlertService','$timeout','UserAdminFactory','$window', 'USER_SETTINGS','JsonObjectFactory','GlobalService','SERVER_CONFIG','GENERAL_CONFIG','NotificationFactory','DTOptionsBuilder','DTColumnBuilder','GridFactory', notificationController])
    .controller('payloadDetail', ['$scope','$filter','$state','$log','AlertService','$timeout','UserAdminFactory','$window', 'USER_SETTINGS','JsonObjectFactory','GlobalService','SERVER_CONFIG','GENERAL_CONFIG','NotificationFactory','DTOptionsBuilder','DTColumnBuilder','GridFactory','$uibModalInstance','ObjectDiff', payloadDetail])    
    function notificationController($scope,$filter,$state,$log,AlertService,$timeout,UserAdminFactory,$window,USER_SETTINGS,JsonObjectFactory,GlobalService,SERVER_CONFIG,GENERAL_CONFIG,NotificationFactory, DTOptionsBuilder,DTColumnBuilder,GridFactory) {
        $scope.showleftPanelMenu=true;
        $scope.toggleMenu = function() {
            $scope.showleftPanelMenu = !$scope.showleftPanelMenu;
            setTimeout(function(){ window.dispatchEvent(new Event('resize')); }, 300);            
        };
        $scope.isProcessListLoading = true;
        $scope.processNameMessage = "All caps without space";
        $scope.selectedProcessBackup; 
        $scope.selectedProcess = {
            "addlEmailList" : "",
            "created_by" : "",
            "description" : "",
            "html_ind" : "",
            "processStatus" : "",
            "process_id" : "",
            "process_name" : "",
            "subject" : "",
            "template" : "",
            "templateId" : "",
            "templateStatus" : "",
            "userTypeKey" : ""
        };

        $scope.onDemandNotifDetails = {
            "process_name" : "",
            "payload" : "",
            "subject" : "",
            "client_key" : "",
            "source_name" : "GTW",
            "email_addr" : ""
        };

        $scope.searchProcessText;
        $scope.processList;
        $scope.pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
        // Roles setting and changing
        $scope.roles= [
            {"id":1,"label":"User"},
            {"id":2,"label":"Admin"},
            {"id":3,"label":"Editor"},
            {"id":4,"label":"Developer"},
            {"id":5,"label":"Super Admin"}
        ];
        $scope.selectedRoles=[];
        $scope.rolesSetting = { 
            smartButtonMaxItems: 5, 
            smartButtonTextConverter: function(itemText, originalItem) { 
                return itemText; 
            } 
        };
        $scope.multiselectEvents = {
			onItemSelect: function (item) {
                $scope.selectedProcess.user_type_key = $scope.selectedRoles.map((role)=>{
                    return role.label;
                }).join();
			},
			onItemDeselect: function (item) {
				$scope.selectedProcess.user_type_key = $scope.selectedRoles.map((role)=>{
                    return role.label;
                }).join();
			}
		};
        // Roles setting and changing--END
        $scope.emailSelected;
        $scope.emailSelectedCC;
        $scope.emailSelectedBCC;
        $scope.emailList_to=[];
        $scope.emailList_cc=[];
        $scope.emailList_bcc=[];

        $scope.notification_to = [];

        $scope.userSettings = USER_SETTINGS;
        var user = $scope.userSettings.user;
        $scope.clients = user.clients;
        $scope.client_key = user.client.client_key;

        $scope.templateBody={text:""};
        $scope.isJsonValid = false;
        $scope.isJsonValidOnInit = true;

        $scope.addUser = function (user,email_type) {
            console.log('add user', user);
            const email = {
                "emailAddress": user.email, 
                "status": "A",
                "copy_type" : email_type 
            };
            let completeEmailList = $scope.emailList_to.concat($scope.emailList_cc,$scope.emailList_bcc);
            if(NotificationFactory.isDulplicateEmail(completeEmailList, email)){
                AlertService.add('warning', 'Email already added',1000);
                return;
            }else{
                switch(email_type){
                    case 'TO':
                        $scope.emailList_to.push(email);
                        break;
                    case 'CC':
                        $scope.emailList_cc.push(email);
                        break;
                    case 'BCC':
                        $scope.emailList_bcc.push(email);
                        break;
                    default:
                        $scope.notification_to.push(email);
                }
            }
            
        }

        $scope.removeUser = function (index, email_type){
            console.log('remove user', index);
            switch(email_type){
                case 'TO':
                    $scope.emailList_to.splice(index, 1);
                    break;
                case 'CC':
                    $scope.emailList_cc.splice(index, 1);
                    break;
                case 'BCC':
                    $scope.emailList_bcc.splice(index, 1);
                    break;
                default:
                    $scope.notification_to.splice(index, 1);
            }
        }

        $scope.getUser = function(val){
            console.log('hello froom the get user function', val);
            // return  [{name:"chandresh ", value : "c@a.com"}]
            $scope.isLoadingEmail_to = true;
            return NotificationFactory.getUser(val).then(function(users){
                console.log(users);
                $scope.isLoadingEmail_to= false;
                return users;
            });
        }

        // --------------------------------
        this.$onInit = function() {
            $scope.loadProcessList();    
        };
        $scope.loadProcessList = function(){
            $scope.htmlParts = NotificationFactory.getHtmlParts();
            NotificationFactory.getProcessList().then(function(data){
                $scope.isProcessListLoading =false;
                $scope.processList= data;
                $scope.processListArray= [];
                if(data.callSuccess != 0){
                    $scope.processListArray= data.map(item => {
                        return item.process_name;
                    }); 
                }
                
            });
        };

        $scope.clearSearch = function() {
            $scope.searchProcessText = "";
        };
        
        $scope.deleteSelectedProcess = function(){
            console.log("delete Selected Process", $scope.selectedProcess.process_id);
        };

        $scope.selectProcess = function(process, index) {
            console.log("Select Processs",  process);
            $scope.selectedIndex = index;
            // NotificationFactory.getSelectedIndex($scope.processList, process);
            $scope.isLoadingProcessDetails = true;
            $scope.selectedProcessBackup = {};
            $scope.templateBody = { text:""};
            NotificationFactory.getProcessDetails(process).then(function(response){
                $scope.isLoadingProcessDetails = false;
                $scope.selectedProcess = response;//$scope.processList[index];
                Object.assign($scope.selectedProcessBackup,response);
                
                if($scope.selectedProcess.template!=null ){
                    $scope.templateBody.text = $scope.pattern.exec($scope.selectedProcess.template)[0];
                }
                setSelectedRoles();
                setEmailList();
            }).catch(function(error){
                $scope.isLoadingProcessDetails = false;
            });
            //////Notification tab
            // callNotification(process);
            if($scope.isProcessConsoleVisible){
                $scope.initProcessConsole(process);
            }
            
            

            console.log($scope.selectedProcessBackup  );
        };

        const setSelectedRoles = function(){
            if($scope.selectedProcess.userTypeKey === null) return;

            $scope.selectedRoles = $scope.selectedProcess.userTypeKey.split(',').map((role)=>{
                if(role === "1") return $scope.roles[0];
                if(role === "2") return $scope.roles[1];
                if(role === "3") return $scope.roles[2];
                if(role === "4") return $scope.roles[3];
                if(role === "5") return $scope.roles[4];
            });
            // if(role.toUpperCase() === "USER") return $scope.roles[0];
            // if(role.toUpperCase() === "ADMIN") return $scope.roles[1];
            // if(role.toUpperCase() === "EDITOR") return $scope.roles[2];
            // if(role.toUpperCase() === "DEVELOPER") return $scope.roles[3];
            // if(role.toUpperCase() === "SUPER ADMIN") return $scope.roles[4];
        };

        const setEmailList = function(){
            $scope.emailList_to=[];
            $scope.emailList_cc=[];
            $scope.emailList_bcc=[];
            if($scope.selectedProcess.addlEmailList != null && $scope.selectedProcess.addlEmailList.length !=0){
                $scope.selectedProcess.addlEmailList.forEach(user => {
                    switch(user.copy_type){
                        case 'TO':
                            $scope.emailList_to.push(user);
                            break;
                        case 'CC':
                            $scope.emailList_cc.push(user);
                            break;
                        case 'BCC':
                            $scope.emailList_bcc.push(user);
                            break;
                    }
                })
            }
        };

        $scope.onChangeProcessName = function (){
            $scope.selectedProcess.process_name = $scope.selectedProcess.process_name.toUpperCase().replace(' ','');
        };

        $scope.onChangeTemplateBody = function(event) {
            console.log(this.templateBody);
            $scope.selectedProcess.template = $scope.htmlParts.htmlBeforeBody+ this.templateBody.text + $scope.htmlParts.htmlAfterBody;
        }
        
        $scope.addNewProcess = function() {
            $scope.selectedProcess = {
                "addlEmailList" : "",
                "created_by" : "",
                "description" : "",
                "html_ind" : "",
                "processStatus" : "",
                "process_id" : "",
                "process_name" : "",
                "subject" : "",
                "template" : "",
                "templateId" : "",
                "templateStatus" : "",
                "userTypeKey" : ""
            };
            $scope.selectedRoles =[];
            $scope.templateBody = {text:""};
            $scope.emailList_to=[];
            $scope.emailList_cc=[];
            $scope.emailList_bcc=[];


        };

        $scope.saveProcess = function() {
            $scope.isProcessSaving = true;
            const process = constructSaveObject();
            if(!isValidProcessObject()){
                AlertService.add("error", "Please provide all the data");
                return false;
            }
            NotificationFactory.saveProcessDetails($scope.selectedProcess).then(function(response){
                $scope.isProcessSaving =false;
                $scope.loadProcessList();
                if(response.errorMessage == null && response.responseData == "1"){
                    AlertService.add("success", "Data saved Successfully.",4000);
                    
                }else{
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                }
            }).catch(function(error){
                $scope.isProcessSaving =false;
                console.log(error);
                return error;
            });
        }

        $scope.sendNotification = function() {
            if(!$scope.isJsonValid){
                $scope.onDemandNotifDetails.payload = "";
                AlertService.add("error", "Please enter valid payload", 4000);
                return;
            }
            $scope.isProcessSaving = true;
            $scope.onDemandNotifDetails.email_addr = $scope.notification_to.map((mail)=>{
                return mail.emailAddress;
            }).join();
            $scope.onDemandNotifDetails.sso = USER_SETTINGS.user.sso_id;

            NotificationFactory.sendNotifDetails($scope.onDemandNotifDetails).then(function(response){
                $scope.isProcessSaving =false;
                //$scope.loadProcessList();
                AlertService.add("success", "Notification Sent Successfully.",4000);
                $scope.onDemandNotifDetails = {};
                $scope.notification_to = [];
                /*if(response.errorMessage == null && response.responseData == "1"){
                    AlertService.add("success", "Notification Sent Successfully.",4000);
                    $scope.onDemandNotifDetails = {};
                    $scope.notification_to = [];
                }else{
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                }*/
            }).catch(function(error){
                $scope.isProcessSaving =false;
                console.log(error);
                return error;
            });
        }

        $scope.updateJsonObject = function(json) {
            $scope.isJsonValidOnInit = true;
            if(json){
                $scope.isJsonValidOnInit = false;
                $scope.isJsonValid = true;
                try {
                    JSON.parse(json);
                }catch (e) {
                    $scope.isJsonValid = false;
                }
            }
        };

        const constructSaveObject = function(){
            const process = $scope.selectedProcess;
            process.addlEmailList = $scope.emailList_to.concat($scope.emailList_cc,$scope.emailList_bcc);
            process.userTypeKey = $scope.selectedRoles.map((role)=>{
                                        return role.id;
                                    }).join();
            return process;

        }

        const isValidProcessObject = function(){
            if($scope.selectedProcess.process_name == '' || $scope.selectedProcess.process_name == undefined || $scope.selectedProcess.process_name == null){
                return false;
            }
            return true;
        }

        $scope.resetProcess = function(){
            $scope.selectedProcess = {};
            Object.assign($scope.selectedProcess, $scope.selectedProcessBackup);
        }
        
        $scope.resetNotification = function(){
            $scope.isJsonValidOnInit = false;
            $scope.onDemandNotifDetails = {};
            $scope.notification_to = [];
        }

        $scope.setProcessDetails = function(){
            $scope.isProcessConsoleVisible = false;
        }
        // -------------------------process console----------------------------------------------
        $scope.isProcessConsoleVisible = false;
        $scope.initProcessConsole = function(process){
            
            $('.fixed-col-handler').hide();
            $scope.filter = {
                client_key:JSON.stringify( USER_SETTINGS.user.client.client_key),
                process_name: process
            };
            $scope.getProcessConsoleData();
        };

        $scope.setProcessConsole =function(){
            
            setTimeout(function(){ window.dispatchEvent(new Event('resize')); }, 300);     
            document.getElementsByClassName('gg-loader')[0].style.width='100%';
            document.getElementsByClassName('gg-loader')[1].style.width='100%';    
            $scope.isProcessConsoleVisible =true;
            $scope.initProcessConsole($scope.selectedProcess.process_name);

        };

        $scope.getProcessConsoleData = function() {
            $scope.$broadcast("showLoader", true);
            $scope.loading = true;
            $scope.gridData = [];
            $scope.gridData.DATA = [];
            let url = GENERAL_CONFIG.base_url + '/loadGridJson?action_id=31159&grid_id=5331&process_name='+ $scope.filter.process_name +'&client_key='+$scope.filter.client_key;
            GridFactory.getDataCustom(url, $scope.filter).then(function (data) {
            if (data.callSuccess === "1") {
                    $scope.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $scope.ctrlFn = function(_callName,_data,_type) {

            if(_callName === 'payloadDetail'){
                
                NotificationFactory.processDetailsPopUpData = {
                    process_name:  _data.rowData.PROCESS_NAME,
                    notificationId : _data.rowData.NOTIFY_ID,
                    payload : _data.rowData.PAYLOAD
                };
                _data = NotificationFactory.processDetailsPopUpData;
                $state.go('admin.notification.payload-details' ,  {data :_data });
            }

        };
    }
    function payloadDetail($scope,$filter,$state,$log,AlertService,$timeout,UserAdminFactory,$window,USER_SETTINGS,JsonObjectFactory,GlobalService,SERVER_CONFIG,GENERAL_CONFIG,NotificationFactory, DTOptionsBuilder,DTColumnBuilder,GridFactory,$uibModalInstance,ObjectDiff){
        console.log('this si called payload details');
        var vm = this;
        vm.loading = false;
        vm.resending= false;
        vm.title = NotificationFactory.processDetailsPopUpData.process_name;
        
        vm.$onInit = function() {
            vm.data = NotificationFactory.processDetailsPopUpData;
            vm.data.payload = ObjectDiff.objToJsonView(JSON.parse(vm.data.payload));
            console.log('payloadDetail', vm.data);
        };
        vm.resendNotifications = function(){
            vm.resending= true;
            NotificationFactory.resendNotifications(vm.data.notificationId).then(function() {
                vm.resending= false;
            }).catch(function(error) {
                vm.resending= false;
            });
        };
        vm.cancel = function(){
    		$uibModalInstance.dismiss('cancel');
    	}
    }
    return contorllers;
});