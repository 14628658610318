define([
    'angular',
    './cfcsWithQualDefsCtrl',
    './cfcsWithQualDefsService', 
], function () {
    'use strict';
    return angular.module('app.FIR_CFCs_With_Qual_Def', ['app.cfcsWithQualDefsCtrl', 'app.cfcsWithQualDefsService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('cfcs-with-qual-defs', {
                url: '/cfcs-with-qual-defs',
                templateUrl: 'app/components/FIR_CFCs_With_Qual_Def/cfcsWithQualDefs.html',
                backdrop: 'static',
                data:{},
                access:""
            })
    }])

});