
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.dcsGhostSettlementService',[])

    .factory('DcsGhostServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
            var dcsGhostServiceFactory = {};
            dcsGhostServiceFactory.url = {};
    
            dcsGhostServiceFactory.saveGhostSettlement = function(_data,Details){
            	if(_data.save_type == 'processTransactions'){
            		dcsGhostServiceFactory.url.ec_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=1yd60j";
            	}else{//clear clearTransactions
            		dcsGhostServiceFactory.url.ec_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=xj03yn";
            	}

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params.save_type = _data.save_type;
            params = _.merge({},  GlobalService.globalParams , params);
           // params =  _.extend({clob_data:jsonObj}, params);
           // params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "dcs-settlement-grid-save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: dcsGhostServiceFactory.url.ec_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        return dcsGhostServiceFactory
    }])

    return services;

});