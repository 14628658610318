define([
    'angular'

], function () {
    'use strict';


    var controllers = angular.module('app.adminController', [])
        .controller('AdminController', ['USER_SETTINGS', 'GENERAL_CONFIG', 'MessageService', 'SERVER_CONFIG', adminController])
        .controller('SystemSettingsController', ['$scope', '$http', '$state', '$uibModal', '$log', 'SERVER_CONFIG', 'USER_SETTINGS', 'AdminFactory', 'AlertService', systemSettingsController])
        .controller('SwitchUserController', ['$rootScope', '$scope', '$http', '$state', '$uibModalInstance', '$log', 'SERVER_CONFIG', 'USER_SETTINGS', 'AdminFactory', 'AlertService', switchUserController])
        .controller('GirdUserController', ['$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', girdUserController])
        .controller('ClobInsertController', ['$scope', '$state', '$log', 'AlertService', '$timeout', 'AdminFactory', 'GENERAL_CONFIG', clobInsertController])
        .controller('CreateSettingsController', ['$rootScope', '$scope', '$http', '$state', '$uibModalInstance', '$log', 'SERVER_CONFIG', 'USER_SETTINGS', 'AdminFactory', 'AlertService', 'addSettingsData', createSettingsController])

        .directive('settingsList', function() {
            return {
                restrict: 'E',
                scope: {
                    serverSettings: '@'
                },
              templateUrl: 'app/components/admin/settings-listing.html'
            };
        });

    function clobInsertController($scope, $state, $log, AlertService, $timeout, AdminFactory, GENERAL_CONFIG) {
        var vm = this;
        vm.pageTitle = "Clob Insert ";
        vm.gridData = [];
        vm.processName = "";
        vm.jsonSettings = "";
        vm.jsonData = "";

        vm.submit = function () {
            var params = {};
            params.jsonSettings = vm.jsonSettings;
            params.jsonData = vm.jsonData;
            params.processName = vm.processName;
            var url = GENERAL_CONFIG.base_url + "/insertJsonClob";

            //adminFactory.clobInsert = function(url,_params){

            AdminFactory.clobInsert(url, params).then(function (data) {
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                } else {
                    console.log(data);
                }
            });

        };

        vm.clear = function () {
            vm.processName = "";
            vm.jsonSettings = "";
            vm.jsonData = "";
        };


    }


    function adminController(USER_SETTINGS, GENERAL_CONFIG, MessageService, SERVER_CONFIG) {
        var vm = this;
        vm.pageTitle = "Admin";
        vm.gridData = [];

        vm.userAccessLevel = USER_SETTINGS.user.system_access_level;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        vm.prvSsoList = SERVER_CONFIG.configSettings.privileged_sso_list;
        vm.efilePrvList = SERVER_CONFIG.configSettings.can_migrate_efile + SERVER_CONFIG.configSettings.can_signoff_efile;
        vm.regenerateDataPrvList = SERVER_CONFIG.configSettings.can_regenrate_fir_form;

        vm.adminLinks = [
            {"link": "admin.client", "label": "Clients", "access": 5, id: "clients"},
            {"link": "admin.users-list", "label": "Users", "access": 3, id: "users-list"},
            {"link": "admin.all-users-list", "label": "All Users ", "access": 4, id: "all-users-list"},
            {"link": "admin.user-expiry-report", "label": "Access Renewal", "access": 3, id: "user-expiry-report"},
            //{"link": "admin.users-list-module", "label": "Users Modules", "access": 3, id: "users-list-module"},
            {"link": "admin.users-list-module", "label": "Users Access Report", "access": 3, id: "users-access-report"},
            {"link": "admin.system-settings", "label": "System Settings", "access": 4, id: "system-settings"},
            {"link": "admin.jdc-manager", "label": "Application", "access": 4, id: "jdc-manager"},
            /* {"link":"admin.workflow" ,"label":"Workflows" , "access":4  },*/
            {"link": "admin.actions-list", "label": "Actions", "access": 4, id: "actions-list"},
            {"link": "admin.grid-list", "label": "Grids", "access": 4, id: "grid-list"},
            /*{"link":"admin.dashboard-list" ,"label":"Dashboards" , "access":4  },*/
            {"link": "admin.screen-list", "label": "Screens", "access": 4, id: "screen-list"},
            /* {"link":"admin.email-settings" ,"label":"Email Settings" , "access":4},*/
            {"link": "admin.templates-list", "label": "Manage Templates", "access": 4, id: "templates-list"},
            {"link": "admin.regeneratedata", "label": "Generate Foreign Form Data", "access": 4, id: "regenerate-data"},
            //{"link": "admin.bu-le.bussiness-hierarchy", "parentTab": "admin.bu-le", "label": "BU/LE", "access": 4, id: "bu-le"}, // label name changed from gold sync to BU/LE
            {"link": "admin.bizz-hier", "label": "Business Hierarchy", "access": 4, id: "bizz-hier"},
            {"link": "admin.gold-sync", "label": "Gold Sync", "access": 4, id: "gold-sync"},
            {"link": "admin.tax-placemat", "label": "Tax Placemat", "access": 4, id: "tax-placemat"},
            {"link": "admin.cache-control", "label": "Cache Control", "access": 4, id: "cache-control"},
            {"link": "admin.template-assign", "label": "Template Assign", "access": 3, id: "template-assign"},
            {"link": "admin.rules-engine", "label": "Rules Engine", "access": 4, id: "rules-engine"},
            //{"link": "admin.notif-engine", "label": "Notifications Engine", "access": 4, id: "notif-engine"},
            {"link": "admin.admin-filters", "label": "Filters", "access": 4, id: "admin-filters"},
            {"link": "admin.audit-engine", "label": "Audit Engine", "access": 4, id: "audit-engine"},
            {"link": "admin.admin-dashboards", "label": "Admin Dashboards", "access": 4, id: "admin-dashboards"},
            {"link": "admin.master-data.system-mapping", "label": "Master Data", "access": 4, id: "master-data"},
            // {"link": "admin.aggregation-trigger","label":"Aggregation Trigger","access":5,"id":"aggregation-trigger"},
            {"link": "admin.notification", "label": "Notification", "access": 4, id: "notification"},
            {"link": "admin.issue-mgmt", "label": "Issue Management", "access": 3, id: "issue-mgmt"},
            {"link": "admin.system-message", "label": "System Message", "access": 4, id: "system-message"},
            {"link": "admin.job-console-list", "label": "Job Console", "access": 4, id: "job-console-list"},
            {"link": "admin.change-log-job-run-details", "label": "Change Log Job Run", "access": 4, id: "change-log-job-run-details"}
            
        ];

        vm.tabs = [];

        vm.updateFn = function (arg) {
            vm.adminLinks.find(link => link.label === arg.tabScrollTitle);
        }

        vm.checkPrivileges = function (adminLink) {
            if (adminLink.id === "gold-sync")
                return vm.prvSsoList.includes(vm.ssoId);
            else if (adminLink.id === "e-file")
                return vm.efilePrvList.includes(vm.ssoId);
            else if (adminLink.id === "regenerate-data")
                return vm.regenerateDataPrvList.includes(vm.ssoId);
            else
                return true;
        }

        // establish websocket connection only in admin settings
        MessageService.establishConnection(GENERAL_CONFIG.base_url + '/gtw-websockets');

    }


    function girdUserController($scope, $state, $log, GridFactory, AlertService, $timeout) {

        var vm = this;
        vm.name = "Update Data";
        var getData = function () {

            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];


            GridFactory.getDataCustom($state.current.api.get).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });

        }

        $timeout(function () {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);


    };


    function systemSettingsController($scope, $http, $state, $uibModal, $log, SERVER_CONFIG, USER_SETTINGS, AdminFactory, AlertService ) {
        var vm = this;
        vm.pageTitle = "Admin - Settings";
        vm.edit = false;
        vm.tab = 1;
        $scope.app = false;
        vm.previousIndex = 0;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
        vm.selectedAppClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });
        vm.selectedClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });
        vm.serverSettings = {
            configSettings : [],
            appconfigSettings : [],
            clientconfigSettings : []
        };
        vm.previousTab = 'configSettings';
        vm.channelList = [];
        vm.selectedChannel = "";

        vm.loadChannel = function(clientKey){
            AdminFactory.getAppKeys(clientKey).then(function (data) {
                vm.channelList = data.channel.p_cursor;
                vm.selectedChannel = vm.channelList[0];
            });
        }
        
        vm.loadSettings = function (clientKey){
            vm.loading = true;
            AdminFactory.getAppKeys(clientKey).then(function (data) {
                vm.channelList = data.channel.p_cursor;
                vm.selectedChannel = vm.channelList[0];
                AdminFactory.getConfigSettings(vm.selectedChannel.channel_key).then(function (data) {
                    angular.forEach(data.configSettings, function(row){
                        row.toggle = false;
                        row.hover = false;
                        row.enableActions = false;
                        row.loading = false;
                    });
                    angular.forEach(data.clientconfigSettings, function(row){
                        row.toggle = false;
                        row.hover = false;
                        row.enableActions = false;
                        row.loading = false;
                    });
                    vm.serverSettings.configSettings = data.configSettings;
                    vm.serverSettings.clientconfigSettings = data.clientconfigSettings;
                    vm.resetServerSettings = angular.copy(vm.serverSettings);
                    vm.loading = false;
                });

                AdminFactory.loadAppSettings(vm.selectedClient.client_key, vm.selectedClient.client_datasource, vm.selectedChannel.channel_key).then(function (data) {
                    angular.forEach(data.appconfigSettings, function(row){
                        row.toggle = false;
                        row.hover = false;
                        row.enableActions = false;
                        row.loading = false;
                    });
                    vm.serverSettings.appconfigSettings = data.appconfigSettings;
                    vm.resetServerSettings.appconfigSettings = angular.copy(data.appconfigSettings);
                });
            });
        };

        vm.loadClientSettings = function(client){
            vm.loading = true;
            AdminFactory.loadClientSettings(client.client_key, client.client_datasource).then(function (data) {
                angular.forEach(data.clientconfigSettings, function(row){
                    row.toggle = false;
                    row.hover = false;
                    row.enableActions = false;
                    row.loading = false;
                });
                vm.serverSettings.clientconfigSettings = data.clientconfigSettings;
                vm.resetServerSettings.clientconfigSettings = angular.copy(data.clientconfigSettings);
                vm.loading = false;
            });
        };

        vm.loadAppSettings = function(){
            vm.loading = true;
            AdminFactory.loadAppSettings(vm.selectedAppClient.client_key, vm.selectedAppClient.client_datasource, vm.selectedChannel.channel_key).then(function (data) {
                angular.forEach(data.appconfigSettings, function(row){
                    row.toggle = false;
                    row.hover = false;
                    row.enableActions = false;
                    row.loading = false;
                });
                vm.serverSettings.appconfigSettings = data.appconfigSettings;
                vm.resetServerSettings.appconfigSettings = angular.copy(data.appconfigSettings);
                vm.loading = false;
            });
        };

        vm.resetTab = function(type){
            if(vm.serverSettings[vm.previousTab].length > 0){
                vm.serverSettings[vm.previousTab][vm.previousIndex].toggle = false;
                if(vm.serverSettings[vm.previousTab][vm.previousIndex].enableActions){
                    vm.resetSettingValue(vm.previousTab);
                }
                vm.previousIndex = 0;
                vm.previousTab = type;
            }else{
                vm.previousIndex = 0;
                vm.previousTab = type;
            }
        };

        vm.getIndex = function(key, type){
            var key = _.findIndex(vm.serverSettings[type], function (setting) {
                return setting.CONFIG_KEY == key;
            });
            return key;
        };

        vm.toggleSetting = function(key, type){
            var index = vm.getIndex(key, type);
            if(vm.previousIndex !== index){
                vm.serverSettings[type][index].toggle = !vm.serverSettings[type][index].toggle;
                vm.serverSettings[type][vm.previousIndex].toggle = false;
                vm.previousIndex = index;
                vm.resetSettingValue(type);
            }else{
                vm.serverSettings[type][vm.previousIndex].toggle = !vm.serverSettings[type][vm.previousIndex].toggle;
            }
        };

        vm.resetSettings = function (type, key) {
            var params = {};
            params.clientKey = vm.selectedClient.client_key;
            params.clientDatasource = vm.selectedClient.client_datasource;
            params.config = type;
            params.appKey = key;
            vm.loading = true;
            AdminFactory.setCachedConfigSetting(params).then(function (data) {
                if (data.ajaxSuccess) {
                    AlertService.add("success", "Server Config Cache was reset ", 4000);
                    vm.loadSettings(user.client.client_key);
                } else {
                    AlertService.add("error", "Error cache was not reset! You might not have the correct permissions for this action ");
                    vm.loading = false;
                }
            });
        };

        vm.resetSettingValue = function(type){
            vm.serverSettings[type][vm.previousIndex].VALUE = vm.resetServerSettings[type][vm.previousIndex].VALUE;
            vm.serverSettings[type][vm.previousIndex].enableActions = false;
        };

        vm.editable = function () {
            vm.edit = true;
        };

        vm.cancel = function () {
            vm.edit = false;
        };

        vm.saveSettings = function(data, type, client, appKey){
            vm.serverSettings[type][vm.previousIndex].loading = true;
            var params = {};
            params.key = data.NAME;
            params.value = data.VALUE;

            AdminFactory.saveSystemSetting(params, type, client, appKey).then(function (data) {
                if (data.callSuccess == "1") {
                    AlertService.add("success", "Setting saved successfully.", 4000);
                    vm.serverSettings[type][vm.previousIndex].enableActions = false;
                    vm.serverSettings[type][vm.previousIndex].loading = false;
                } else {
                    AlertService.add("error", data.p_error_out, 4000);
                    vm.serverSettings[type][vm.previousIndex].loading = false;
                }
            });
        };

        vm.openModal = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: "app/components/admin/switch-user-tpl.html",
                controller: "SwitchUserController as ctrl",
                size: "md",
                backdrop: "static"
            });
        };

        vm.createNewSettings = function(setting_type) {
            
            if(setting_type == 'configSettings'){
                vm.settingData = {
                    client:'',
                    appKey:''
                };
                vm.settingData.type = 'configSettings';
                vm.settingData.title= 'Create new system setting';
            }else if(setting_type == 'appconfigSettings'){
                vm.settingData = {
                    client:vm.selectedClient,
                    appKey:vm.selectedChannel.channel_key
                };
                vm.settingData.type = 'appconfigSettings';
                vm.settingData.title= 'Create new app setting';
            }else if(setting_type == 'clientconfigSettings'){
                vm.settingData = {
                    client:vm.selectedClient,
                    appKey:''
                };
                vm.settingData.type = 'clientconfigSettings';
                vm.settingData.title= 'Create new client setting';
            }
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: "app/components/admin/create-new-settings.html",
                controller: "CreateSettingsController as ctrl",
                size: "sm",
                backdrop: "static",
                resolve: {
                    addSettingsData: function () {
                        return vm.settingData;
                    }
                }
            });

            modalInstance.closed.then(function(){
                vm.loadSettings(user.client.client_key);
            });
        };

        vm.deleteSettings = function(setting_type, row){
            row.loadingDeleteSettings = true;
            AdminFactory.deleteSystemSetting(setting_type, row.NAME, vm.selectedClient, vm.selectedChannel.channel_key).then( (data) =>{
                row.loadingDeleteSettings = false;
                if (data.callSuccess == "1") {
                    AlertService.add("success", "Setting deleted successfully.", 4000);
                    vm.loadSettings(user.client.client_key);
                } else {
                    AlertService.add("error", data.p_error_out, 4000);
                }
            });

        };

        vm.loadSettings(user.client.client_key);
    }

    function switchUserController($rootScope, $scope, $http, $state, $uibModalInstance, $log, SERVER_CONFIG, USER_SETTINGS, AdminFactory, AlertService) {
        var vm = this;
        vm.title = "Search for new user";
        
        vm.loading = false;
        vm.showList = false;
        vm.switch_reset = true;
        vm.selectedUser = '';
        vm.noRecords = false;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.getUsers = function () {
            vm.loading = true;
            vm.noRecords = false;
            var params = {};
            $scope.userDetailsArr = [];
            if (!!vm.selectedUser) {
                params.userName = vm.selectedUser;
                AdminFactory.getuserDetails(params).then(function (data) {
                    if (data.userDetails.length > 0) {
                        vm.userDetails = data.userDetails
                        angular.forEach(vm.userDetails, function (item) {
                            if ($scope.userDetailsArr.indexOf(item.first_name + ' ' + item.last_name + ' - ' + item.sso_id) == -1) {
                                $scope.userDetailsArr.push(item.first_name + ' ' + item.last_name + ' - ' + item.sso_id);
                            }
                        });
                        vm.loading = false;
                        vm.showList = true;
                        vm.noRecords = false;
                    } else {
                        vm.noRecords = true;
                        vm.loading = false;
                        vm.showList = false;
                    }
                });
            } else {
                vm.loading = false;
                vm.noRecords = false;
            }
        };

        vm.switchUser = function () {
            vm.cancel();
            vm.loading = true;
            $scope.userDetailsArr = [];
            if (vm.selectedUser != '') {
                var userStr = vm.selectedUser;
                var array = userStr.split("- ");
                AdminFactory.postUserDetails(array[1]).then(function (data) {
                    if (data.callSuccess == 1) {
                        $rootScope.donotLogoutUser = true; // shouldn't logout on reload flag used in GlobalController
                        window.location.reload(true);
                    } else {
                        AlertService.add("error", data.message);
                    }
                });
            }
            vm.loading = false;
        };

        vm.fillTextbox = function (userData) {
            vm.selectedUser = userData;
            vm.showList = false;
            vm.switch_reset = false;
        };

        vm.reset = function () {
            vm.selectedUser = '';
            $scope.userDetailsArr = [];
            vm.switch_reset = true;
        }
    }

    function createSettingsController($rootScope, $scope, $http, $state, $uibModalInstance, $log, SERVER_CONFIG, USER_SETTINGS, AdminFactory, AlertService, addSettingsData) {
        var vm = this;
        vm.loading = false;
        
        vm.title = addSettingsData.title;
        
        vm.saveSettings = function(){
            vm.loading = true;
            var params = {};
            params.key = vm.name;
            params.value = vm.value;

            AdminFactory.saveSystemSetting(params, addSettingsData.type, addSettingsData.client, addSettingsData.appKey).then(function (data) {
                vm.loading = false;
                if (data.callSuccess == "1") {
                    AlertService.add("success", "Setting saved successfully.", 4000);
                } else {
                    AlertService.add("error", data.p_error_out, 4000);
                }
            });
        };
        
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    return controllers;
});
