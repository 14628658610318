const { element } = require("angular");

define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.schdA63JController', [])
    .controller('schA63JDataRefreshCtrl', ['$injector','GENERAL_CONFIG','AlertService','GlobalService','rowData','gridData','colData','GridSaveFactory','USER_SETTINGS', '$uibModal', '$log', 'workspaceFactory','$uibModalInstance', schA63JDataRefreshCtrl])


function schA63JDataRefreshCtrl($injector,GENERAL_CONFIG,AlertService,GlobalService,rowData,gridData,colData,GridSaveFactory,USER_SETTINGS, $uibModal, $log, workspaceFactory,$uibModalInstance) {
    var vm = this;
    var trans_json = [];

    vm.cancel = function(){
        $uibModalInstance.dismiss('cancel');

    }
    vm.crudLoading = false;
    var json_arrOBJ = [];
     var activeScreenData = workspaceFactory.activeScreen.data.data;
    vm.refreshData = function(){
        console.log(workspaceFactory.activeScreen.data)
        _.each(activeScreenData,function(i){
           var ar=[];
            ar = [
                {"amount":i.LN_43C,"acct_key":i['LN_43C_TO_ACCT'],"pship_combination_key":i.PSHIP_COMBINATION_KEY,"ptr_combination_key":i.PTR_COMBINATION_KEY},
                {"amount":i.LN_43D,"acct_key":i['LN_43D_TO_ACCT'],"pship_combination_key":i.PSHIP_COMBINATION_KEY,"ptr_combination_key":i.PTR_COMBINATION_KEY},
                {"amount":i.LN_43F,"acct_key":i['LN_43F_TO_ACCT'],"pship_combination_key":i.PSHIP_COMBINATION_KEY,"ptr_combination_key":i.PTR_COMBINATION_KEY},
                {"amount":i.LN_43G,"acct_key":i['LN_43G_TO_ACCT'],"pship_combination_key":i.PSHIP_COMBINATION_KEY,"ptr_combination_key":i.PTR_COMBINATION_KEY},

                {"amount":i.LN_43E,"acct_key":i['LN_43E_TO_ACCT'],"pship_combination_key":i.PSHIP_COMBINATION_KEY,"ptr_combination_key":i.PTR_COMBINATION_KEY},
                {"amount":i.LN_43H,"acct_key":i['LN_43H_TO_ACCT'],"pship_combination_key":i.PSHIP_COMBINATION_KEY,"ptr_combination_key":i.PTR_COMBINATION_KEY},
                {"amount":i.LN_43I,"acct_key":i['LN_43I_TO_ACCT'],"pship_combination_key":i.PSHIP_COMBINATION_KEY,"ptr_combination_key":i.PTR_COMBINATION_KEY}];
                
                json_arrOBJ.push(ar);
        })
        
        var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31780";
                   
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = _.merge({}, GlobalService.globalParams, filterParams);
                   
                    params = _.extend({
                        jsonObj: JSON.stringify(json_arrOBJ)
                    }, params);
                    vm.crudLoading = true;

                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);
                            vm.crudLoading = false;
                            vm.cancel();
                        }
                        else{
                            AlertService.add("error", data.errorMessage, 4000);
                            vm.cancel();
                        }
                    }
                );
                }
        }
    return controllers;

});