define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.newProformaFormReviewServices', [])
                            .factory("NewProformaFormReviewFactory", ['$http', '$q', 'GENERAL_CONFIG', 'USER_SETTINGS', 'GlobalService',
                                function ($http, $q, GENERAL_CONFIG, USER_SETTINGS, GlobalService) {
                                    const requestUrlBuilder = {
                                        url: '',
                                        isFirstParam: true,
                                        set: function (url) {
                                            this.url = url;
                                            this.isFirstParam = true;
                                            return this;
                                        },
                                        get: function () {
                                            return this.url;
                                        },
                                        appendEndpoint: function (endpoint) {
                                            this.url = this.url + endpoint;
                                            return this;
                                        },
                                        appendParam: function (paramName, paramVal) {
                                            if (!this.url || this.url.length === 0) throw Error('Bad Url');
                                            if (paramName !== undefined && paramVal !== undefined) {
                                                this.url = this.url + (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                                                this.isFirstParam = false;
                                            }
                                            return this;
                                        }
                                    };
                                    const factory = {};
                                    // default selections
                                    factory.formReviewFilter = {
                                        category: 'C',
                                        location: 'D',
                                        lEType: 'HO',
                                        entityType: 'ALL'
                                    };
                                    factory.formReviewCache = {};
                                    factory.selectedEntityCached = {};
                                    factory.pdfsCached = [];
                                    factory.selectedPdfsCached = {};
                                    const JCD_KEY = 250;
                                    
                                    factory.gtwViewStore = {
                                        gtwViewFilter: {
                                            category: 'C',
                                            location: 'D',
                                            lEType: 'CONSOL',
                                            entityType: 'ALL',
                                            contSrc: 'FED'
                                        },
                                        gtwViewCache: {},
                                        gtwViewPdf: {}
                                    };
                                    factory.stateViewStore = {
                                        gtwViewFilter: {
                                            category: 'C',
                                            location: 'D',
                                            lEType: 'STATE_CONSOL',
                                            HO_lEType: 'STATE_HO',
                                            entityType: 'ALL',
                                            contSrc: 'STATE'
                                        },
                                        gtwViewCache: {},
                                        gtwViewPdf: {}
                                    };
                                    factory.cbcrViewStore = {
                                        gtwViewFilter: {
                                            category: 'C',
                                            location: 'D',
                                            lEType: 'CBCR',
                                            HO_lEType: 'STATE_HO',
                                            entityType: 'ALL',
                                            contSrc: 'STATE'
                                        },
                                        gtwViewCache: {},
                                        gtwViewPdf: {}
                                    };
                                    

                                    factory.gtwViewStore.gtwViewCache = {};
                                    factory.gtwViewStore.gtwViewPdf = {};

                                    // get flat data for the entities
                                    factory.getPdfView = function (category, src_system_flag, lEType) {
                                        if (lEType === 'CONSOL') lEType = 'CON';
                                        const promise = $http({
                                            method: "POST",
                                            url: GENERAL_CONFIG.pdf_engine_api_url + '/getPdfView?taxYear=' + GlobalService.globalParams.tax_year + '&scenario=' +
                                                GlobalService.globalParams.scenario + '&jcdKey=' + JCD_KEY + '&leType=' + lEType + '&src_system_flag=' + src_system_flag
                                                + '&business_type=' + category
                                        });
                                        return promise;
                                    };

                                    // formType = 'CBCR' is used to determine whether user is entering from the CBC View
                                    factory.getTaxForms = function (selectedView, rp_period = '', lEType, cdrNo = '', src_system_flag, category,
                                        entityType = '', selectedConsolGroup = '', taxYear, scenario, entityName = '', cont_src,
                                        filing_group = '', formType = '') {
                                        if (src_system_flag === 'F' && lEType === 'PSHIP') {
                                            lEType = 'HO';
                                        }
                                        if (entityType === '') {
                                            selectedConsolGroup = '';
                                        }

                                        let jcd = cont_src === "STATE" ? 253 : JCD_KEY;

                                        filing_group = filing_group || '';
                                        const promise = $http({
                                            method: 'POST',
                                            url: GENERAL_CONFIG.pdf_engine_api_url + '/getPdfForms?taxYears=' + taxYear + '&scenario=' +
                                                scenario + '&jcdKey=' + jcd + '&selectedView=' + selectedView + '&cdrNo=' + (cdrNo || '') +
                                                '&reportingPeriod=' + (rp_period || '') + '&leType=' + lEType + '&src_system_flag=' + src_system_flag + '&business_type=' +
                                                category + '&entity_type=' + entityType + '&cgkey=' + selectedConsolGroup + '&entityName=' + encodeURIComponent(entityName) +
                                                '&contSrc=' + cont_src + '&filing_group=' + filing_group + '&formType=' + formType
                                        });
                                        return promise;
                                    };


                                    factory.getTaxFormsCorpDomestic = function (selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType, selectedConsolGroup) {
                                        const getTaxFormsPromise = factory.getTaxForms(selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType, selectedConsolGroup, 'FED')
                                                                          .then((response) => {
                                                                              return response;
                                                                          });
                                        const getJobIdForCorpPromise = factory.getJobIdForCorp(selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType, selectedConsolGroup)
                                                                              .then((response) => {
                                                                                  return response;
                                                                              });

                                        const promises = [getTaxFormsPromise, getJobIdForCorpPromise];
                                        return $q.all(promises);
                                    };

                                    factory.getJobIdForCorp = function (selectedView, rp_period = '', lEType, cdrNo = '', src_system_flag, category, entityType = '', selectedConsolGroup = '') {
                                        if (src_system_flag === 'F' && lEType === 'PSHIP') {
                                            lEType = 'HO';
                                        }
                                        const promise = $http({
                                            method: 'POST',
                                            url: GENERAL_CONFIG.pdf_engine_api_url + '/getJobIdForCorp?taxYears=' + GlobalService.globalParams.tax_year + '&scenario=' +
                                                GlobalService.globalParams.scenario + '&jcdKey=' + JCD_KEY + '&selectedView=' + selectedView + '&cdrNo=' + cdrNo +
                                                '&reportingPeriod=' + rp_period + '&leType=' + lEType + '&src_system_flag=' + src_system_flag + '&business_type=' +
                                                category + '&entity_type=' + '&cgkey=' + selectedConsolGroup
                                        });
                                        return promise;
                                    };

                                    factory.getDiagStatus = function (businessType, taxYear, scenario, systemFlag, leId, cdrNo, reportingPeriod, entityType, entType) {
                                        return $http.post(requestUrlBuilder.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                                           .appendEndpoint('/getDiagStatus')
                                                                           .appendParam('businessType', businessType)
                                                                           .appendParam('taxYear', taxYear)
                                                                           .appendParam('scenario', scenario)
                                                                           .appendParam('jcdKey', JCD_KEY)
                                                                           .appendParam('systemFlag', systemFlag)
                                                                           .appendParam('leId', leId)
                                                                           .appendParam('cdrNo', cdrNo)
                                                                           .appendParam('reportingPeriod', reportingPeriod)
                                                                           .appendParam('entityType', entityType)
                                                                           .appendParam('leType', '')
                                                                           .appendParam('entType', entType)
                                                                           .appendParam('sso_id', USER_SETTINGS.user.sso_id)
                                                                           .get());
                                    };

                                    return factory;
                                }]);
    return services;
});
