/**
 * Created by 502595611 on 8/7/2017.
 */
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.subPartFService',[])
        .factory("SubPartFFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$q', '$injector', 'workspaceFactory','GENERAL_CONFIG', 'GlobalService', function($rootScope, $log,JsonObjectFactory,AlertService, $q, $injector, workspaceFactory,GENERAL_CONFIG, GlobalService) {
            var factory = {};
            var returnObj = {};
            
            factory.saveSubPartFDetails = function(_callName, _data){
				//Do Nothing if old and new values are same
				if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]) {
					return;
				} else if (_data.rowData[_data.col_id] === null || _data.rowData[_data.col_id] === undefined || _data.rowData[_data.col_id] === '') {
					_data.rowData[_data.col_id] = _data.oldRowData[_data.col_id]
					return;
				}

            	//console.log("_data------", _data.rowData.LE_TAX_TYPE );
            	if(_data.rowData.LE_TAX_TYPE === 'CFC' ||  _data.rowData.LE_TAX_TYPE === 'BRCFC')
            	{
	            		if(_callName === 'saveBasketValue' && _data.rowData.MANUFACTURING != 1 && _data.rowData.RESELLING != 1 && _data.rowData.PROVIDING_SERVICES != 1 && _data.rowData.OTHER != 1)
	            		{
	            			_data.rowData.CY_NON_SUB_F_SERVICES = 0;
	            			_data.rowData.CY_NON_SUB_F_SALES = 0;
	            			_data.rowData.PHYSICAL_MANUFACTURING = 0;
	            			_data.rowData.SAME_COUNTRY_MANUFACTURING = 0;
	            			_data.rowData.SUBSTANTIAL_CONTRIBUTION = 0;
	            			_data.rowData.SAME_COUNTRY_SALES_USE = 0;
	            			_data.rowData.BRANCH = 0;
	            			_data.rowData.AGRICULTURAL_COMMODITIES = 0;
	            			_data.rowData.OTHERS = 0;
	            			var intr=setInterval(function(){ 
	                            AlertService.add("error", 'Cannot Proceed, Please first select the type of activity the entity is engaged in.');
	                           clearInterval(intr);
	                           $rootScope.$apply();
	                        },1000);
	                		return false;
	            		}
	            		else if(_callName === 'saveBasketValue' && ( (parseInt(_data.rowData.CY_NON_SUB_F) < 0 && (parseInt(_data.rowData.CY_NON_SUB_F_SERVICES) > 0 || -1*parseInt(_data.rowData.CY_NON_SUB_F_SERVICES) > -1*parseInt(_data.rowData.CY_NON_SUB_F)) ) || (parseInt(_data.rowData.CY_NON_SUB_F) > 0 && parseInt(_data.rowData.CY_NON_SUB_F_SERVICES) > parseInt(_data.rowData.CY_NON_SUB_F)) ) )
	            		{
	            			
	            			_data.rowData.CY_NON_SUB_F_SERVICES = _data.oldRowData.CY_NON_SUB_F_SERVICES;
	            			var intr=setInterval(function(){ 
	                            AlertService.add("error", '"Total Non-SubpartF" Services Income cannnot be more than "Total SubpartF sales and Services"');
	                           clearInterval(intr);
	                           $rootScope.$apply();
	                        },1000);
	                		return false;
	            		}
	            		else if(_callName === 'saveBasketValue' && (parseInt(_data.rowData.PHYSICAL_MANUFACTURING) > 0 || parseInt(_data.rowData.SAME_COUNTRY_MANUFACTURING) > 0 || parseInt(_data.rowData.SUBSTANTIAL_CONTRIBUTION) > 0 || parseInt(_data.rowData.SAME_COUNTRY_SALES_USE) > 0 || parseInt(_data.rowData.BRANCH) > 0 || parseInt(_data.rowData.AGRICULTURAL_COMMODITIES) >0 || parseInt(_data.rowData.OTHERS) >0) )
	            		{
	            			_data.rowData.PHYSICAL_MANUFACTURING = _data.oldRowData.PHYSICAL_MANUFACTURING;
	                		_data.rowData.SAME_COUNTRY_MANUFACTURING = _data.oldRowData.SAME_COUNTRY_MANUFACTURING;
	                		_data.rowData.SUBSTANTIAL_CONTRIBUTION = _data.oldRowData.SUBSTANTIAL_CONTRIBUTION;
	                		_data.rowData.SAME_COUNTRY_SALES_USE = _data.oldRowData.SAME_COUNTRY_SALES_USE;
	                		_data.rowData.BRANCH = _data.oldRowData.BRANCH;
	                		_data.rowData.AGRICULTURAL_COMMODITIES = _data.oldRowData.AGRICULTURAL_COMMODITIES;
	                		_data.rowData.OTHERS = _data.oldRowData.OTHERS;
	                		
	            			var intr=setInterval(function(){ 
	                            AlertService.add("error", "Non Subpart F Sales categories value cannot be grater than 0");
	                           clearInterval(intr);
	                           $rootScope.$apply();
	                        },1000);
	                		return false;
	            		}
	            		else
	            		{
		            		if(_data.rowData.MANUFACTURING != 1 && _data.rowData.RESELLING != 1 && _data.rowData.PROVIDING_SERVICES != 1 && _data.rowData.OTHER != 1 && _callName === 'saveCehckboxDetails')
		            		{
		            			_data.rowData.CY_NON_SUB_F_SERVICES = 0;
		            			_data.rowData.CY_NON_SUB_F_SALES = 0;
		            			_data.rowData.PHYSICAL_MANUFACTURING = 0;
		            			_data.rowData.SAME_COUNTRY_MANUFACTURING = 0;
		            			_data.rowData.SUBSTANTIAL_CONTRIBUTION = 0;
		            			_data.rowData.SAME_COUNTRY_SALES_USE = 0;
		            			_data.rowData.BRANCH = 0;
		            			_data.rowData.AGRICULTURAL_COMMODITIES = 0;
		            			_data.rowData.OTHERS = 0;
		            		}
		            		if(_data.rowData.MANUFACTURING == 1)
		            		{
		            			if( _data.oldRowData.MANUFACTURING == 0)
		            				_data.rowData.SAME_COUNTRY_SALES_USE = 0;
		            					//(_data.rowData.RESELLING != _data.oldRowData.RESELLING && _data.oldRowData.RESELLING == 1) || (_data.rowData.OTHER != _data.oldRowData.OTHER && _data.oldRowData.OTHER == 1) )	
		            			if(parseInt(_data.rowData.CY_NON_SUB_F) <= 0)
		            				_data.rowData.CY_NON_SUB_F_SALES = parseInt(_data.rowData.CY_NON_SUB_F) - parseInt(_data.rowData.CY_NON_SUB_F_SERVICES);
		            			/*if(_data.rowData.RESELLING == 1 || _data.rowData.OTHER == 1 || _data.rowData.MANUFACTURING == 1)
		            				_data.rowData.SAME_COUNTRY_SALES_USE = 0;*/
		            			
		            			//CHNAGED FROM CY_NON_SUB_F_SALES_TOTAL TO HO_POST_ELIM_NON_SUB_F --DT-02/26/2020
		            			if(parseInt(_data.rowData.HO_POST_ELIM_NON_SUB_F) - (parseInt(_data.rowData.SAME_COUNTRY_MANUFACTURING) + parseInt(_data.rowData.SUBSTANTIAL_CONTRIBUTION) + parseInt(_data.rowData.SAME_COUNTRY_SALES_USE) + parseInt(_data.rowData.BRANCH) + parseInt(_data.rowData.AGRICULTURAL_COMMODITIES) + parseInt(_data.rowData.OTHERS)) <= 0)
		            				_data.rowData.PHYSICAL_MANUFACTURING = parseInt(_data.rowData.HO_POST_ELIM_NON_SUB_F) - (parseInt(_data.rowData.SAME_COUNTRY_MANUFACTURING) + parseInt(_data.rowData.SUBSTANTIAL_CONTRIBUTION) + parseInt(_data.rowData.SAME_COUNTRY_SALES_USE) + parseInt(_data.rowData.BRANCH) + parseInt(_data.rowData.AGRICULTURAL_COMMODITIES) + parseInt(_data.rowData.OTHERS));
		            			else
		            			{
		            				_data.rowData.CY_NON_SUB_F_SERVICES = _data.oldRowData.CY_NON_SUB_F_SERVICES;
			            			_data.rowData.CY_NON_SUB_F_SALES = _data.oldRowData.CY_NON_SUB_F_SALES;
			            			_data.rowData.PHYSICAL_MANUFACTURING = _data.oldRowData.PHYSICAL_MANUFACTURING;
			            			_data.rowData.SAME_COUNTRY_MANUFACTURING = _data.oldRowData.SAME_COUNTRY_MANUFACTURING;
			            			_data.rowData.SUBSTANTIAL_CONTRIBUTION = _data.oldRowData.SUBSTANTIAL_CONTRIBUTION;
			            			_data.rowData.SAME_COUNTRY_SALES_USE = _data.oldRowData.SAME_COUNTRY_SALES_USE;
			            			_data.rowData.OTHERS = _data.oldRowData.OTHERS;
			            			_data.rowData.BRANCH = _data.oldRowData.BRANCH;
			            			_data.rowData.AGRICULTURAL_COMMODITIES = _data.oldRowData.AGRICULTURAL_COMMODITIES;
			            			_data.rowData.MANUFACTURING = _data.oldRowData.MANUFACTURING
			            			_data.rowData.RESELLING = _data.oldRowData.RESELLING
			            			_data.rowData.OTHER = _data.oldRowData.OTHER
			            			_data.rowData.PROVIDING_SERVICES = _data.oldRowData.PROVIDING_SERVICES
		            				var intr=setInterval(function(){ 
		                                AlertService.add("error", "Physical Manufacturing value cannot be positive value, please change the value accordingly");
		                               clearInterval(intr);
		                               $rootScope.$apply();
		                            },1000);
		                    		return false;
		            			}
		            		}
		            		if( (_data.rowData.RESELLING == 1 || _data.rowData.OTHER == 1)  && _data.rowData.MANUFACTURING != 1 )
		            		{
		            			if(_data.oldRowData.MANUFACTURING == 1)
		            				_data.rowData.PHYSICAL_MANUFACTURING = 0;
		            			if(parseInt(_data.rowData.CY_NON_SUB_F) <= 0)
		            				_data.rowData.CY_NON_SUB_F_SALES = parseInt(_data.rowData.CY_NON_SUB_F) - parseInt(_data.rowData.CY_NON_SUB_F_SERVICES);
		            			//CHNAGED FROM CY_NON_SUB_F_SALES_TOTAL TO HO_POST_ELIM_NON_SUB_F --DT-02/26/2020
		            			if(parseInt(_data.rowData.HO_POST_ELIM_NON_SUB_F) - (parseInt(_data.rowData.PHYSICAL_MANUFACTURING) + parseInt(_data.rowData.SAME_COUNTRY_MANUFACTURING) + parseInt(_data.rowData.SUBSTANTIAL_CONTRIBUTION) + parseInt(_data.rowData.BRANCH) + parseInt(_data.rowData.AGRICULTURAL_COMMODITIES) + parseInt(_data.rowData.OTHERS)) <= 0)
		            				_data.rowData.SAME_COUNTRY_SALES_USE = parseInt(_data.rowData.HO_POST_ELIM_NON_SUB_F) - (parseInt(_data.rowData.PHYSICAL_MANUFACTURING) + parseInt(_data.rowData.SAME_COUNTRY_MANUFACTURING) + parseInt(_data.rowData.SUBSTANTIAL_CONTRIBUTION) + parseInt(_data.rowData.BRANCH) + parseInt(_data.rowData.AGRICULTURAL_COMMODITIES) + parseInt(_data.rowData.OTHERS));
		            			else
		            			{
		            				_data.rowData.CY_NON_SUB_F_SERVICES = _data.oldRowData.CY_NON_SUB_F_SERVICES;
			            			_data.rowData.CY_NON_SUB_F_SALES = _data.oldRowData.CY_NON_SUB_F_SALES;
			            			_data.rowData.PHYSICAL_MANUFACTURING = _data.oldRowData.PHYSICAL_MANUFACTURING;
			            			_data.rowData.SAME_COUNTRY_MANUFACTURING = _data.oldRowData.SAME_COUNTRY_MANUFACTURING;
			            			_data.rowData.SUBSTANTIAL_CONTRIBUTION = _data.oldRowData.SUBSTANTIAL_CONTRIBUTION;
			            			_data.rowData.SAME_COUNTRY_SALES_USE = _data.oldRowData.SAME_COUNTRY_SALES_USE;
			            			_data.rowData.OTHERS = _data.oldRowData.OTHERS;
			            			_data.rowData.BRANCH = _data.oldRowData.BRANCH;
			            			_data.rowData.AGRICULTURAL_COMMODITIES = _data.oldRowData.AGRICULTURAL_COMMODITIES;
			            			_data.rowData.MANUFACTURING = _data.oldRowData.MANUFACTURING
			            			_data.rowData.RESELLING = _data.oldRowData.RESELLING
			            			_data.rowData.OTHER = _data.oldRowData.OTHER
			            			_data.rowData.PROVIDING_SERVICES = _data.oldRowData.PROVIDING_SERVICES
		            				var intr=setInterval(function(){ 
		                                AlertService.add("error", "Same Country Sales Use basket value cannot be positive value, please change the value accordingly");
		                               clearInterval(intr);
		                               $rootScope.$apply();
		                            },1000);
		                    		return false;
		            			}
		            		}
		            		if( _data.rowData.MANUFACTURING != 1 && _data.rowData.RESELLING != 1 &&  _data.rowData.PROVIDING_SERVICES == 1 && _data.rowData.OTHER != 1)
		            		{
		            			
		            			if(parseInt(_data.rowData.CY_NON_SUB_F) <= 0)
		            			{
		            				if(_callName === 'saveBasketValue')
			            			{
			            				_data.rowData.CY_NON_SUB_F_SALES = parseInt(_data.rowData.CY_NON_SUB_F) - parseInt(_data.rowData.CY_NON_SUB_F_SERVICES);
			            			}
			            			else
			            			{
			            				_data.rowData.CY_NON_SUB_F_SERVICES = parseInt(_data.rowData.CY_NON_SUB_F);
			            				_data.rowData.CY_NON_SUB_F_SALES = parseInt(_data.rowData.CY_NON_SUB_F) - parseInt(_data.rowData.CY_NON_SUB_F_SERVICES);
			            			}
			            		}
		            			//CHNAGED FROM CY_NON_SUB_F_SALES_TOTAL TO HO_POST_ELIM_NON_SUB_F --DT-02/26/2020
		            			if(parseInt(_data.rowData.HO_POST_ELIM_NON_SUB_F) - (parseInt(_data.rowData.PHYSICAL_MANUFACTURING) + parseInt(_data.rowData.SAME_COUNTRY_MANUFACTURING) + parseInt(_data.rowData.SUBSTANTIAL_CONTRIBUTION) + parseInt(_data.rowData.BRANCH) + parseInt(_data.rowData.AGRICULTURAL_COMMODITIES)+ parseInt(_data.rowData.OTHERS)) <= 0)
		            				_data.rowData.SAME_COUNTRY_SALES_USE = parseInt(_data.rowData.HO_POST_ELIM_NON_SUB_F) - (parseInt(_data.rowData.PHYSICAL_MANUFACTURING) + parseInt(_data.rowData.SAME_COUNTRY_MANUFACTURING) + parseInt(_data.rowData.SUBSTANTIAL_CONTRIBUTION) + parseInt(_data.rowData.BRANCH) + parseInt(_data.rowData.AGRICULTURAL_COMMODITIES)+ parseInt(_data.rowData.OTHERS));
		            			else
		            			{
		            				_data.rowData.CY_NON_SUB_F_SERVICES = _data.oldRowData.CY_NON_SUB_F_SERVICES;
			            			_data.rowData.CY_NON_SUB_F_SALES = _data.oldRowData.CY_NON_SUB_F_SALES;
			            			_data.rowData.PHYSICAL_MANUFACTURING = _data.oldRowData.PHYSICAL_MANUFACTURING;
			            			_data.rowData.SAME_COUNTRY_MANUFACTURING = _data.oldRowData.SAME_COUNTRY_MANUFACTURING;
			            			_data.rowData.SUBSTANTIAL_CONTRIBUTION = _data.oldRowData.SUBSTANTIAL_CONTRIBUTION;
			            			_data.rowData.SAME_COUNTRY_SALES_USE = _data.oldRowData.SAME_COUNTRY_SALES_USE;
			            			_data.rowData.OTHERS = _data.oldRowData.OTHERS;
			            			_data.rowData.BRANCH = _data.oldRowData.BRANCH;
			            			_data.rowData.AGRICULTURAL_COMMODITIES = _data.oldRowData.AGRICULTURAL_COMMODITIES;
			            			_data.rowData.MANUFACTURING = _data.oldRowData.MANUFACTURING
			            			_data.rowData.RESELLING = _data.oldRowData.RESELLING
			            			_data.rowData.OTHER = _data.oldRowData.OTHER
			            			_data.rowData.PROVIDING_SERVICES = _data.oldRowData.PROVIDING_SERVICES
		            				var intr=setInterval(function(){ 
		                                AlertService.add("error", "Same Country Sales Use basket value cannot be positive value, please change the value accordingly");
		                               clearInterval(intr);
		                               $rootScope.$apply();
		                            },1000);
		                    		return false;
		            			}	            		
		            		}
		            	
		/////creating jsonobject to save 
		            	
		            	/*var jsonSettingsData = [{
							"combination_key": _data.rowData.COMBINATION_KEY,
							"tax_year": _data.rowData.TAX_YEAR,
							"jcd_key" : (GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key,
						    "scenario" : GlobalService.globalParams.scenario
						}];*/
		            	var jsonObjData = [];
		    ////-----create json object for all baskets
		            	
		            	//--1) NON_SUBF_SERVICE
		            	var tempJsonObj1 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.NON_SUBF_SERVICE_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.NON_SUBF_SERVICE_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.CY_NON_SUB_F_SERVICES
		            	}];
		            	jsonObjData.push(tempJsonObj1);
		            	//--2) NON_SUBF_SALES
		            	var tempJsonObj2 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.NON_SUBF_SALES_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.NON_SUBF_SALES_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.CY_NON_SUB_F_SALES
		            	}];
		            	jsonObjData.push(tempJsonObj2);
		            	//--3) PHY_MANU
		            	var tempJsonObj3 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.PHY_MANU_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.PHY_MANU_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.PHYSICAL_MANUFACTURING
		            	}];
		            	jsonObjData.push(tempJsonObj3);
		            	//--4) SAME_COUNTRY_MANU
		            	var tempJsonObj4 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.SAME_COUNTRY_MANU_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.SAME_COUNTRY_MANU_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.SAME_COUNTRY_MANUFACTURING
		            	}];
		            	jsonObjData.push(tempJsonObj4);
		            	//--5) SUBSTN_CONTRIBUTION
		            	var tempJsonObj5 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.SUBSTN_CONTRIBUTION_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.SUBSTN_CONTRIBUTION_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.SUBSTANTIAL_CONTRIBUTION
		            	}];
		            	jsonObjData.push(tempJsonObj5);
		            	//--6) SAME_COUNTRY_SALES
		            	var tempJsonObj6 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.SAME_COUNTRY_SALES_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.SAME_COUNTRY_SALES_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.SAME_COUNTRY_SALES_USE
		            	}];
		            	jsonObjData.push(tempJsonObj6);
		            	//--7) BRANCH
		            	var tempJsonObj7 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.BRANCH_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.BRANCH_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.BRANCH
		            	}];
		            	jsonObjData.push(tempJsonObj7);
		            	//--8) AGRI_COMMODITIES
		            	var tempJsonObj8 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.AGRI_COMMODITIES_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.AGRI_COMMODITIES_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.AGRICULTURAL_COMMODITIES
		            	}];
		            	jsonObjData.push(tempJsonObj8);
		            	var tempJsonObj9 = [{
		            		"TRANS_DETAILS_KEY" : _data.rowData.OTHERS_TRANS_DETAILS_KEY,
		            		"ACCT_KEY" : _data.rowData.OTHERS_ACCT_KEY,
		            		"BASKET_VALUE" : _data.rowData.OTHERS
		            	}];
		            	jsonObjData.push(tempJsonObj9);
		            	
		            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
						 //var jsonSettings =  JSON.stringify(jsonSettingsData);
						 var jsonSettings = [{
							tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
							"ISSUE_KEY" : _data.rowData.ISSUE_KEY
						}];
		 	           var jsonObj = JSON.stringify(jsonObjData);
		 	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
		 	           //console.log("jsonSettings-----", jsonSettings);
		 	           console.log("jsonObj-------", jsonObj);
		 	           var params = filterParams;
		 	           params = _.merge({},  GlobalService.globalParams , params);
						params = _.extend({
							jsonSettings: JSON.stringify(jsonSettings)
						}, params);
						params =  _.extend({jsonObj:jsonObj}, params);
		 	           //params =  _.extend({jsonSettings:jsonSettings}, params);
		 	          /* if( GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined){
		 	        	   params.jcd_key = "250";
		 	           }*/
		 	          
		 	          
		 	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
		 	           params.combination_key = _data.rowData.COMBINATION_KEY;
		 	           params.jcd_key = (GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key;
		 	           params.process_name =  "Non-subpartF";
		 	           params.subpartf_activity = _data.rowData.MANUFACTURING.toString() + _data.rowData.RESELLING.toString() + _data.rowData.PROVIDING_SERVICES.toString() + _data.rowData.OTHER.toString();
		 	          var service = $injector.get('JsonObjectFactory');
		 	          var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=uwacqr";
		 	         service.saveJSON(url , params).then(function(data){
						if(data.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",4000);
							var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
							$rootScope.$emit('gridUpdate', args);
						}else if (data.errorMessage && data.errorMessage != ""){
							AlertService.add("error", data.errorMessage, 4000);
						} else{
							AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
						}
					  });
					  
		 	      //CHNAGED FROM CY_NON_SUB_F_SALES_TOTAL TO HO_POST_ELIM_NON_SUB_F --DT-02/26/2020
					  _data.rowData.UNALOCATED_CY_NON_SUB_F_SALES_TOTAL = _data.rowData.HO_POST_ELIM_NON_SUB_F - _data.rowData.PHYSICAL_MANUFACTURING - _data.rowData.SAME_COUNTRY_MANUFACTURING - _data.rowData.SUBSTANTIAL_CONTRIBUTION
					  														- _data.rowData.SAME_COUNTRY_SALES_USE - _data.rowData.BRANCH - _data.rowData.AGRICULTURAL_COMMODITIES - parseInt(_data.rowData.OTHERS);
	            	}
            	}
            	else
            	{
            		if(_callName === 'saveCehckboxDetails')
            		{
	            		var jsonObjData = [];
	            		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
						 //var jsonSettings =  JSON.stringify(jsonSettingsData);
						 var jsonSettings = [{
							tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
							"ISSUE_KEY" : _data.rowData.ISSUE_KEY
						}];
		 	           var jsonObj = JSON.stringify(jsonObjData);
		 	           var params = filterParams;
						params = _.merge({},  GlobalService.globalParams , params);
						params = _.extend({
							jsonSettings: JSON.stringify(jsonSettings)
						}, params);
		 	           params =  _.extend({jsonObj:jsonObj}, params);
		 	           //params =  _.extend({jsonSettings:jsonSettings}, params);
		 	          /* if( GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined){
		 	        	   params.jcd_key = "250";
		 	           }*/
		 	           console.log("params-------------", params);
		 	          
		 	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
		 	           params.combination_key = _data.rowData.COMBINATION_KEY;
		 	           params.jcd_key = (GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key;
		 	           params.process_name =  "Non-subpartF";
		 	           params.subpartf_activity = _data.rowData.MANUFACTURING.toString() + _data.rowData.RESELLING.toString() + _data.rowData.PROVIDING_SERVICES.toString() + _data.rowData.OTHER.toString();
		 	          var service = $injector.get('JsonObjectFactory');
		 	          var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=uwacqr";
		 	         service.saveJSON(url , params).then(function(data){
						if(data.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",4000);
							var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
							$rootScope.$emit('gridUpdate', args);
						} else if (data.errorMessage && data.errorMessage != ""){
							AlertService.add("error", data.errorMessage, 4000);
						} else {
							AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
						}
					  });
		 	      //CHNAGED FROM CY_NON_SUB_F_SALES_TOTAL TO HO_POST_ELIM_NON_SUB_F --DT-02/26/2020
					  _data.rowData.UNALOCATED_CY_NON_SUB_F_SALES_TOTAL = _data.rowData.HO_POST_ELIM_NON_SUB_F - _data.rowData.PHYSICAL_MANUFACTURING - _data.rowData.SAME_COUNTRY_MANUFACTURING - _data.rowData.SUBSTANTIAL_CONTRIBUTION
					  														- _data.rowData.SAME_COUNTRY_SALES_USE - _data.rowData.BRANCH - _data.rowData.AGRICULTURAL_COMMODITIES - parseInt(_data.rowData.OTHERS);
            		}
            		else if(_callName === 'saveBasketValue')
            		{
            			
            			_data.rowData.CY_NON_SUB_F_SERVICES = 0;
            			_data.rowData.CY_NON_SUB_F_SALES = 0;
            			_data.rowData.PHYSICAL_MANUFACTURING = 0;
            			_data.rowData.SAME_COUNTRY_MANUFACTURING = 0;
            			_data.rowData.SUBSTANTIAL_CONTRIBUTION = 0;
            			_data.rowData.SAME_COUNTRY_SALES_USE = 0;
            			_data.rowData.BRANCH = 0;
            			_data.rowData.AGRICULTURAL_COMMODITIES = 0;
            			_data.rowData.OTHERS = 0;
						_data.rowData.UNALOCATED_CY_NON_SUB_F_SALES_TOTAL = _data.rowData.HO_POST_ELIM_NON_SUB_F;
						 //CHNAGED FROM CY_NON_SUB_F_SALES_TOTAL TO HO_POST_ELIM_NON_SUB_F --DT-02/26/2020
						
            			var intr=setInterval(function(){ 
                            AlertService.add("error", "You can enter NonSubpartF categories value only for 'CFC' & 'BRCFC' Tax Types.");
                           clearInterval(intr);
                           $rootScope.$apply();
                        },1000);
                		return false;
            		}
            	}
            	
            }
  //=========================================================================================================

            factory.saveSubPartF = function(_callName , _data){
            	
            	var defer = $q.defer();
				//Do Nothing if old and new values are same
				/*if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]) {
					return;
				} else if (_data.rowData[_data.col_id] === null || _data.rowData[_data.col_id] === undefined || (_data.rowData[_data.col_id]).trim() === '') {
					_data.rowData[_data.col_id] = _data.oldRowData[_data.col_id]
					return;
				}*/
            	if (_data.rowData[_data.col_id] === (_data.oldRowData == undefined? _data.oldValue : _data.oldRowData[_data.col_id] ) ) {
					return defer.promise;
				}
            	else if (_data.rowData[_data.col_id] === null || _data.rowData[_data.col_id] === undefined || (_data.rowData[_data.col_id]).trim() === '') {
            		_data.rowData[_data.col_id] = _data.oldValue != undefined ? _data.oldValue : _data.oldRowData[_data.col_id] ;
            		var intr=setInterval(function(){ 
                        AlertService.add("error", "You cannot enter null or empty values for Subpart F Sales and SubPart F Services, Please enter valid values");
                       clearInterval(intr);
                       $rootScope.$apply();
                    },1000);
        					return defer.promise;
            	}
            	/*if((_data.rowData.SUB_F_CY_SALES === null || _data.rowData.SUB_F_CY_SALES.toString().length == 0) && ( _data.rowData.SUB_F_CY_SERVICES === null ||  _data.rowData.SUB_F_CY_SERVICES.toString().length == 0) )
            	{
            		var intr=setInterval(function(){ 
                        AlertService.add("error", "You cannot enter null or empty values for Subpart F Sales and SubPart F Services, Please enter valid values");
                       clearInterval(intr);
                       $rootScope.$apply();
                    },1000);
            		return false;
            	}*/
            	else if(_data.rowData.TO_ACCT_TYPE_DESC == 'Income' && (_data.rowData.SUB_F_CY_SALES > 0 || _data.rowData.SUB_F_CY_SERVICES > 0)  )
                {
            		//console.log("data", _data.oldRowData.SUB_F_CY_SERVICES);
                	var intr=setInterval(function(){ 
                        AlertService.add("error", "Please enter only negative numbers in Subpart F Sales and SubPart F Services columns");
                       clearInterval(intr);
                       $rootScope.$apply();
                    },1000);
            		/*if(_data.colData.label == "Subpart F Services")
            			_data.rowData.SUB_F_CY_SERVICES =_data.oldRowData.SUB_F_CY_SERVICES;
            		else
            			_data.rowData.SUB_F_CY_SALES = _data.oldRowData.SUB_F_CY_SALES;*/
                	_data.rowData[_data.col_id] = _data.oldRowData != undefined?_data.oldRowData[_data.col_id] : _data.oldValue;
        			return defer.promise;
                }
            	else
            	{
	                var gridData = workspaceFactory.activeScreen.data.data;
	                returnObj.COMBINATION_KEY = _data.rowData.COMBINATION_KEY;
	                returnObj.SALES_COL_CHANGED = _data.colData.map;
	                
	                if(_data.rowData.TO_ACCT_TYPE_DESC === 'Income') {
	                    returnObj.SUB_F_CY_SALES = parseInt(_data.rowData.SUB_F_CY_SALES);
	                    returnObj.SUB_F_CY_SERVICES = parseInt(_data.rowData.SUB_F_CY_SERVICES) 
	                }
	                
	                if(_data.rowData.TO_ACCT_TYPE_DESC === 'Expense') {
	                    returnObj.SUB_F_CY_SALES = parseInt(_data.rowData.SUB_F_CY_SALES);
	                    returnObj.SUB_F_CY_SERVICES = parseInt(_data.rowData.SUB_F_CY_SERVICES) 
	                }
	                
	                console.log("Edited rowdata ============================ " , _data);
	                console.log("COMBINATION_KEY >> "+_data.rowData.COMBINATION_KEY);
	                console.log("gridData >>>>>>> ",gridData);
	                console.log("_data.rowData.FROM_ACCT_DESC >> "+_data.rowData.FROM_ACCT_DESC);
	                var total_income_amts = _.filter(gridData, { 'COMBINATION_KEY': returnObj.COMBINATION_KEY, 'TO_ACCT_TYPE_DESC': 'Income' });
	                
	               // var total_income_amts = _.filter(gridData, { 'COMBINATION_KEY': returnObj.COMBINATION_KEY, 'TO_ACCT_TYPE': 5, 'TO_ACCT_TYPE_DESC': 'Income' });
	                console.log("TOTAL_INCOME_AMTS------------------",total_income_amts);
	                
	                // TODO ...replace combination key with leid, cdr, rpt pd
	                var total_income_row = _.find(gridData, { 'COMBINATION_KEY': returnObj.COMBINATION_KEY, 'TOTAL_ROW': 1, 'TO_ACCT_TYPE_DESC': 'Income' });
	                console.log(total_income_row);
	                
	                var total_expense_row = _.find(gridData, { 'COMBINATION_KEY': returnObj.COMBINATION_KEY, 'TOTAL_ROW': 1, 'TO_ACCT_TYPE_DESC': 'Expense', 'TOTAL_ROW_TYPE': 'TOTAL_EXPENSE' });
	                console.log(total_expense_row);
	               
	                returnObj.total_income_amts_sales_rows = 0;
	                returnObj.total_income_amts_service_rows = 0;
	                for(var i = 0; i < total_income_amts.length; i++)
	                {
						//We shouldn't add Total row to Total amt to avoid doubling of the amt
						if (total_income_amts[i].TOTAL_ROW === 1) {
							continue;
						}
	                	returnObj.total_income_amts_sales_rows = returnObj.total_income_amts_sales_rows + parseInt(total_income_amts[i].SUB_F_CY_SALES);
	                	returnObj.total_income_amts_service_rows = returnObj.total_income_amts_service_rows + parseInt(total_income_amts[i].SUB_F_CY_SERVICES);
	                }
	                
	               /* returnObj.total_income_amts_sales_rows = parseInt(total_income_amts[0].SUB_F_CY_SALES) + parseInt(total_income_amts[1].SUB_F_CY_SALES) + parseInt(total_income_amts[2].SUB_F_CY_SALES) + parseInt(total_income_amts[3].SUB_F_CY_SALES);
	                returnObj.total_income_amts_service_rows = parseInt(total_income_amts[0].SUB_F_CY_SERVICES) + parseInt(total_income_amts[1].SUB_F_CY_SERVICES) + parseInt(total_income_amts[2].SUB_F_CY_SERVICES) + parseInt(total_income_amts[3].SUB_F_CY_SERVICES);
					*/
	
	                if(_data.rowData.TO_ACCT_TYPE_DESC === 'Income') {
	                total_income_row.SUB_F_CY_SALES = returnObj.total_income_amts_sales_rows;
	                total_income_row.SUB_F_CY_SERVICES = returnObj.total_income_amts_service_rows; 
	                }
	                
					returnObj.sales_ratio = (total_income_row === undefined ? 0 : ( total_income_row.AMT_PER_CY_TB === undefined ? 0 :  (parseInt(total_income_row.AMT_PER_CY_TB) === 0 ? 0 : parseInt(total_income_row.SUB_F_CY_SALES) / parseInt(total_income_row.AMT_PER_CY_TB) )));
					returnObj.services_ratio = (total_income_row === undefined ? 0 : ( total_income_row.AMT_PER_CY_TB === undefined ? 0 : (parseInt(total_income_row.AMT_PER_CY_TB) === 0 ? 0 : parseInt(total_income_row.SUB_F_CY_SERVICES) / parseInt(total_income_row.AMT_PER_CY_TB) )));
					var cost_of_sales_rows = _.filter(gridData, { 'COMBINATION_KEY': returnObj.COMBINATION_KEY, 'IS_COS_ACCT' : 'Y', 'TO_ACCT_TYPE_DESC': 'Expense' });
					//Update Cost of Sales accts only if the changed row's Acct Type is Income
					if(_data.rowData.TO_ACCT_TYPE_DESC === 'Income') {
						for(var i = 0; i < cost_of_sales_rows.length; i++)
						{
							//Don't apply this logic to Totals Row
							console.log(cost_of_sales_rows[i]);
							if(cost_of_sales_rows[i] !== undefined && cost_of_sales_rows[i].TOTAL_ROW !== 1)
							{
								returnObj.cost_tb_amt = parseInt(cost_of_sales_rows[i].AMT_PER_CY_TB);
								returnObj.cost_sales_amt = parseInt(cost_of_sales_rows[i].SUB_F_CY_SALES);
								returnObj.cost_services_amt = parseInt(cost_of_sales_rows[i].SUB_F_CY_SERVICES);
							
							
								if(returnObj.SALES_COL_CHANGED === 'SUB_F_CY_SALES' && _data.rowData.TO_ACCT_TYPE_DESC === 'Income' && cost_of_sales_rows[i].OVERRIDE_Y_N === 'N') {
									cost_of_sales_rows[i].SUB_F_CY_SALES === 0;
									cost_of_sales_rows[i].SUB_F_CY_SALES = ((returnObj.cost_tb_amt <= 0) ? 0 : ((parseInt(cost_of_sales_rows[i].SUB_F_CY_SERVICES) === 0) ? Math.round(returnObj.cost_tb_amt * returnObj.sales_ratio) : (((returnObj.cost_tb_amt - parseInt(cost_of_sales_rows[i].SUB_F_CY_SERVICES)) < (returnObj.cost_tb_amt * returnObj.sales_ratio) ? (returnObj.cost_tb_amt - parseInt(cost_of_sales_rows[i].SUB_F_CY_SERVICES)) : Math.round(returnObj.cost_tb_amt * returnObj.sales_ratio)))));
								}
								
								if(returnObj.SALES_COL_CHANGED === 'SUB_F_CY_SERVICES' && _data.rowData.TO_ACCT_TYPE_DESC === 'Income' && cost_of_sales_rows[i].OVERRIDE_Y_N === 'N') {
									cost_of_sales_rows[i].SUB_F_CY_SERVICES === 0;
									cost_of_sales_rows[i].SUB_F_CY_SERVICES = ((returnObj.cost_tb_amt <= 0) ? 0 : ((parseInt(cost_of_sales_rows[i].SUB_F_CY_SALES) === 0) ? Math.round(returnObj.cost_tb_amt * returnObj.services_ratio) : (((returnObj.cost_tb_amt - parseInt(cost_of_sales_rows[i].SUB_F_CY_SALES)) < (returnObj.cost_tb_amt * returnObj.services_ratio) ? (returnObj.cost_tb_amt - parseInt(cost_of_sales_rows[i].SUB_F_CY_SALES)) : Math.round(returnObj.cost_tb_amt * returnObj.services_ratio)))));
								}
							}
						}
					}
	                
	                var total_expense_amts = _.filter(gridData, { 'COMBINATION_KEY': returnObj.COMBINATION_KEY, 'TO_ACCT_TYPE_DESC': 'Expense' });
	                console.log(total_expense_amts);
	                returnObj.total_expense_amts_sales_rows = 0;
	                returnObj.total_expense_amts_service_rows = 0;
	                
	                for(var j = 0; j < total_expense_amts.length; j++)
	                {
						//We shouldn't add Total row to Total amt to avoid doubling of the amt
						if (total_expense_amts[i].TOTAL_ROW === 1) {
							continue;
						}
	                	returnObj.total_expense_amts_sales_rows = returnObj.total_expense_amts_sales_rows + parseInt(total_expense_amts[j].SUB_F_CY_SALES);
	                	returnObj.total_expense_amts_service_rows = returnObj.total_expense_amts_service_rows + parseInt(total_expense_amts[j].SUB_F_CY_SERVICES);
	                }
	               /* returnObj.total_expense_amts_sales_rows = parseInt(total_expense_amts[0].SUB_F_CY_SALES) + parseInt(total_expense_amts[1].SUB_F_CY_SALES) + parseInt(total_expense_amts[2].SUB_F_CY_SALES) + parseInt(total_expense_amts[3].SUB_F_CY_SALES) + parseInt(total_expense_amts[4].SUB_F_CY_SALES) + parseInt(total_expense_amts[5].SUB_F_CY_SALES) + parseInt(total_expense_amts[6].SUB_F_CY_SALES) + parseInt(total_expense_amts[7].SUB_F_CY_SALES) + parseInt(total_expense_amts[8].SUB_F_CY_SALES) + parseInt(total_expense_amts[9].SUB_F_CY_SALES) + parseInt(total_expense_amts[10].SUB_F_CY_SALES) + parseInt(total_expense_amts[11].SUB_F_CY_SALES) + parseInt(total_expense_amts[12].SUB_F_CY_SALES) + parseInt(total_expense_amts[13].SUB_F_CY_SALES) + parseInt(total_expense_amts[14].SUB_F_CY_SALES) + parseInt(total_expense_amts[15].SUB_F_CY_SALES);
	                returnObj.total_expense_amts_service_rows = parseInt(total_expense_amts[0].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[1].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[2].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[3].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[4].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[5].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[6].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[7].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[8].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[9].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[10].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[11].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[12].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[13].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[14].SUB_F_CY_SERVICES) + parseInt(total_expense_amts[15].SUB_F_CY_SERVICES);                                                          
	                */
	                
	                total_expense_row.SUB_F_CY_SALES = returnObj.total_expense_amts_sales_rows;
	                total_expense_row.SUB_F_CY_SERVICES = returnObj.total_expense_amts_service_rows; 
	                
	                
	                        if(_data.rowData.IS_COS_ACCT === 'Y' && (_data.rowData.SUB_F_CY_SALES != _data.rowData.OLD_SUB_F_CY_SALES) || (_data.rowData.SUB_F_CY_SERVICES != _data.rowData.OLD_SUB_F_CY_SERVICES)){
	                            _data.rowData.OVERRIDE_Y_N = 'Y';
	                        }
	                        else if(_data.rowData.IS_COS_ACCT === 'Y'  && (_data.rowData.SUB_F_CY_SALES == _data.rowData.OLD_SUB_F_CY_SALES) && (_data.rowData.SUB_F_CY_SERVICES == _data.rowData.OLD_SUB_F_CY_SERVICES) )
	                        	_data.rowData.OVERRIDE_Y_N = 'N';
	                        	
	                        
	                
	                //$rootScope.$broadcast('gridRefresh', false); 
	                //$rootScope.$broadcast('dataUpdate', gridData);
	                
	                var json_arr = [];
					json_arr[0] = _data.rowData;
					//Update Cost of Sales accts only if the changed row's Acct Type is Income
					if(_data.rowData.TO_ACCT_TYPE_DESC === 'Income') {
						for(var i = 0; i < cost_of_sales_rows.length; i++)
						{
							if(cost_of_sales_rows[i] !== undefined && cost_of_sales_rows[i].TOTAL_ROW !== 1)
							{
								json_arr[i+1] = cost_of_sales_rows[i];
							}
						}
					}
	                var service = $injector.get('JsonObjectFactory');
	                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=130";
	                var jsonObj = JSON.stringify(json_arr);
	
					//var jsonSettings = '{}';
					var jsonSettings = [{
						tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
						ISSUE_KEY: GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)
					}];

					jsonSettings = JSON.stringify(jsonSettings);
	
	                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {}; 
	                var params = filterParams;
		 	        params = _.merge({},  GlobalService.globalParams , params);
	                params = _.extend({jsonObj:jsonObj}, params);
	                params =  _.extend({jsonSettings:jsonSettings}, params);
	                params.process_name =  "saveSubPartF";
	                console.log("params-----------------", params);
	               service.saveJSON(url , params).then(function(data){
	                    if(data.callSuccess === "1"){
	                        AlertService.add("success", "Data saved Successfully.",4000);
	                        defer.resolve(data);
	                    }else{
	                        AlertService.add("error", data.errorMessage);
	                        defer.reject(data);
	                        
	                    }
	                    var args = {combination_keys: returnObj.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: returnObj.COMBINATION_KEY}};
	                    $rootScope.$emit('gridUpdate', args);
	                },function(data){
                        defer.reject(data);
                       // var args = {COMBINATION_KEYs: returnObj.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: returnObj.COMBINATION_KEY}};
	                    //$rootScope.$emit('gridUpdate', args);
                    });
	                //if(_data.rowData.TRANS_HEADER_KEY === 0) {
	                //var args = {COMBINATION_KEYs: returnObj.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: returnObj.COMBINATION_KEY}};
                    //$rootScope.$emit('gridUpdate', args);
	                //}
                    return defer.promise;
	                
            	}
                $rootScope.$emit('gridUpdate', returnObj.COMBINATION_KEY);
            }
            return factory;
        }]) 

    return services;
});