define([
    'angular',
    './countByBusinessController'
], function () {
    'use strict';
    
    var module = angular.module('app.countByBusinessReport', ['app.CountByBusinessController'])
        
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('count-by-business', {
                url: '/count-by-business',
                "templateUrl": "app/components/DST/reports/count-by-business/count-by-business.html",
                "controller": "CountByBusinessCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
