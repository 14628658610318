
define([
    'angular',
    './MessageController',
    './MessageService',

], function () {
    'use strict';
    return angular.module('app.messages', ['app.messageController','app.MessageService'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider
                .state('messages', {
                    url: '/messages',
                    templateUrl: 'app/components/messages/messages-tpl.html',
                    data:{ncyBreadcrumbLabel: 'Tasks'},
                    access:""
                })

                .state('messages.edit', {
                    url: '/messages-eidt',
                    templateUrl: 'app/components/messages/messages-tpl.html',
                    data:{ncyBreadcrumbLabel: 'Tasks'},
                    access:""
                });

        }]);

});