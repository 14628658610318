define([
    'angular',
    './updateChangeListController',
    './updateChangeListService'



], function () {
    'use strict';
    return angular.module('app.updateChangeList', ['app.updateChangeListController','app.updateChangeListService'] );


});