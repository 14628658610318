define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.reclassController', [])
    .controller('ReclassBookController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', 'ModalFactory', 'AlertService',
        'CodeComboFactory','JsonObjectFactory', 'ReclassFactory','GENERAL_CONFIG', '$uibModalInstance',  'rowData', 'colData', reclassBookController])
    .controller('ReclassBookControllerModal', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', 'ModalFactory', 'AlertService',
    'CodeComboFactory','JsonObjectFactory', 'ReclassFactory','GENERAL_CONFIG', reclassBookControllerModal]);



    function reclassBookControllerModal($rootScope, $scope, $http, $timeout, GlobalService, ModalFactory, AlertService, CodeComboFactory,JsonObjectFactory,
        ReclassFactory,GENERAL_CONFIG) {
            reclassBookController($rootScope, $scope, $http, $timeout, GlobalService, ModalFactory, AlertService, CodeComboFactory,JsonObjectFactory,
                ReclassFactory,GENERAL_CONFIG, null,{}, null, this);
        }

    function reclassBookController($rootScope, $scope, $http, $timeout, GlobalService, ModalFactory, AlertService, CodeComboFactory, JsonObjectFactory, ReclassFactory,GENERAL_CONFIG
       , $uibModalInstance,rowData, colData, thisObj) {

        var vm  = this||thisObj;
        $scope.rowData = rowData||$scope.$parent.rowData;
        $scope.accounts = [];
        $scope.selectAccountData = [];
        $scope.sourceAcctType = null;
        $scope.reclassTotal = "";
        $scope.title = "Book Adjustment Reclass";
        $scope.RECLASS_DESCRIPTION = "";
        $scope.TRANS_HEADER_KEY = "";
        $scope.reclassArray = [];
        $scope.showReclassForm = false;
        $scope.crudLoading = false;
        $scope.existingLoading = false;
        $scope.noRecords = false;
        $scope.fromScreen = false;
        $scope.colData = colData;
        $scope.RECLASS_TYPE = "";
        $scope.scenario = GlobalService.globalParams.scenario;
        $scope.taxYear = GlobalService.globalParams.tax_year;
        $scope.localChartKey = "";
        $scope.mainScreenKey = ReclassFactory.getActiveScreen();
        $scope.showReclassType = true;
        $scope.showNovYrReclassType = true;
        $scope.delTransDetails = [];


        if($uibModalInstance) {
            $scope.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
            $scope.cancel = function () {
                $uibModalInstance.close();
                $scope.modal_name = null;
            };
        } else {
            $scope.modal_name = null;
        }


        /*  FOR workflow perspective added by Divya */
       if( $scope.colData=== undefined || $scope.colData === null){
        $scope.colData = { apply_rules: null,
                    call_back: "reclassBookByLocalChart",
                    col_class: "right-align",
                    data_api: null,
                    data_type: "number",
                    display: "y",
                    drilldown: null,
                    edit_icon: null,
                    edit_rule_message: null,
                    edit_rules: null,
                    edit_type: "form",
                    editable: "y",
                    format: "numberWithCommas",
                    full_label: null,
                    grid_header_key: "80811",
                    grid_key: "12",
                    header_class: null,
                    label: "11 / 30 PY Dec",
                    map: "reclassBookByLocalChart",
                    new_row_editable: null,
                    object_id: "25",
                    priority: 26,
                    required: "0",
                    static_values: null,
                    validation: "numeric",
                    width: 129
                }
       }
        $scope.addTargetAccount = function () {
            var targetAcct = {
                "ACCTTP": "",
                "ACCOUNT_KEY": "",
                "SYSTEM_ACCOUNT_KEY": "",
                "ACCOUNT": "",
                "ACCOUNT_DESC": "",
                "TP_ENTITY": "",
                "TP_KEY": null,
                "TP_REP_PD": 0,
                "IS_TP_EXISTS": false,
                "BOOK_AMT": null,
                "PREV_ADJ_AMT": null,
                "CUR_ADJ_AMT": null,
                "TYPE": "blank",
                "IS_DELETE": "NO",
                "TRANS_DETAILS_KEY": 0,
                "ISBOOKAMTLOADING": false,
                "ISBOOKADJLOADING": false,
                "INVALIDACCTTP": false
            };

            $scope.accounts.push(targetAcct);
        };

        /*$scope.validateAccountTPKey = function (_accountKey, _tpKey, _tpRepPd, _index) {

            if (_.find($scope.accounts, {'ACCTTP': "" + _accountKey + _tpKey + _tpRepPd}) !== undefined) {
                AlertService.add("error", "The Account/Trading Partner combination has already been used for this adjustment. Please correct the errors.", 4000);

                $timeout(function () {
                    $scope.accounts.splice(_index, 1);
                });

                return false;
            }
        };*/

        $scope.setTradingPartner = function (tp, index) {
            var currAcct = $scope.accounts[index];

            currAcct["TP_ENTITY"] = tp.TP_LEID + "-" + tp.TP_CDR_NO + "(Pd:" + tp.TP_REP_PD + ")";
            currAcct["TP_KEY"] = tp.TP_KEY;
            currAcct["TP_REP_PD"] = tp.TP_REP_PD;
            currAcct["IS_TP_SELECTED"] = true;
            currAcct["ACCTTP"] = "" + currAcct["ACCOUNT_KEY"] + tp.TP_KEY + tp.TP_REP_PD;
            currAcct["ISBOOKAMTLOADING"] = true;
            currAcct["ISBOOKADJLOADING"] = true;


            if(tp.TP_KEY !== 0) {
                $scope.validateAcctTp(index);
               //$scope.validateAccountTPKey(currAcct["ACCOUNT_KEY"], tp.TP_KEY, tp.TP_REP_PD, index);
                var AcctTpKey = "" + currAcct["SYSTEM_ACCOUNT_KEY"] + currAcct["TP_KEY"];
                //$scope.getAccounts(AcctTpKey, 'TP', index);


                var params = {
                    "action_id": 127,
                    "screen_key": $scope.mainScreenKey,
                    "combination_key": $scope.rowData.COMBINATION_KEY,
                    "source_type": ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? 'D' : 'F'),
                    "search_type": 'TP',
                    "search_string": AcctTpKey
                };

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        AlertService.add("error", data.errorMessage);
                    } else {
                        currAcct["ISBOOKAMTLOADING"] = false;
                        currAcct["ISBOOKADJLOADING"] = false;
                        if (data.jsonObject.length) {

                                var bookAmt = data.jsonObject[0].BOOK_AMT;
                                var bookAdj = data.jsonObject[0].BOOK_ADJ;

                                $scope.accounts[index]["BOOK_AMT"] = bookAmt;
                                $scope.accounts[index]["PREV_ADJ_AMT"] = bookAdj;

                        } else {
                            $scope.accounts[index]["BOOK_AMT"] = 0;
                            $scope.accounts[index]["PREV_ADJ_AMT"] = 0;
                        }
                    }
                });
            }

        };
        function init() {
            $scope.accounts = [];
            $scope.delTransDetails = [];
            $scope.crudLoading = false;
            $scope.RECLASS_DESCRIPTION = "";
            $scope.reclassArray = [];
            $scope.TRANS_HEADER_KEY = "";
            $scope.existingLoading = false;
            $scope.noRecords = false;
            $scope.RECLASS_TYPE = "";
            $scope.showReclassType = ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? false : true);
            $scope.showNovYrReclassType = ($scope.rowData.NOV_YEAR_END === 'N' ? false : true);

            switch ($scope.colData.label) {
                case 'Book Adj':
                    $scope.RECLASS_TYPE = 'RCY';
                    break;
                case '11 / 30 CY  Dec':
                    $scope.RECLASS_TYPE = 'CYD';
                    break;
                case '11 / 30 PY Dec':
                    $scope.RECLASS_TYPE = 'PYD';
                    break;
                default:
                    $scope.RECLASS_TYPE = 'RCY';
            }

            if ($scope.reclassFrom !== undefined) {
                $scope.reclassFrom.$setPristine();
                $scope.reclassFrom.$setUntouched();
            }

            if ($scope.rowData.COMBINATION_KEY) {

                CodeComboFactory.setCombinationCodeByKey($scope.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                $scope.fromScreen = true;
                CodeComboFactory.showFormBtn = false;

                loadExistingReclass();
                $scope.sourceAcctType = $scope.rowData.TO_ACCT_TYPE;

                getAccountData($scope.rowData, 0);


            } else {
                CodeComboFactory.toggle = true;
            }
        }

        init();



        $scope.$on('CCKeySet', function (event, data) {

            $scope.localChartKey = CodeComboFactory.chartKey;
            $scope.showReclassForm = true;

            if (!$scope.accounts.length && !$scope.fromScreen) {
                $scope.addTargetAccount();
            }

        });


        $scope.setSourceTotal = function (_index) {
            var account = $scope.accounts[_index];
            account.TOTAL = parseFloat(account.BOOK_AMT) + parseFloat(account.PREV_ADJ_AMT);
            if (account.CUR_ADJ_AMT !== undefined) {
                account.TOTAL += parseFloat(account.CUR_ADJ_AMT);
            }
            setReclassTotal();
        };


        var setExistingReclassTotal = function (array) {
            angular.forEach(array, function (value, key) {
                angular.forEach(value.ACCOUNTS, function (value, key) {
                    value.TOTAL = parseFloat(value.BOOK_AMT) + parseFloat(value.PREV_ADJ_AMT);
                    if (value.CUR_ADJ_AMT !== undefined) {
                        value.TOTAL += parseFloat(value.CUR_ADJ_AMT);
                    }
                });
            });
        };

        $scope.removeAccount = function (_index) {

            var getTransType = $scope.accounts[_index]["TYPE"];
            if(getTransType === 'edit') {

                $scope.accounts[_index]["IS_DELETE"] = 'YES';
                $scope.delTransDetails.push($scope.accounts[_index]);
            }
            $scope.accounts.splice(_index, 1);
            setReclassTotal();
        };

        var setReclassTotal = function () {
            var total = 0;
            angular.forEach($scope.accounts, function (value, key) {
                if (value.CUR_ADJ_AMT && !isNaN(value.CUR_ADJ_AMT)) {
                    total += parseFloat(value.CUR_ADJ_AMT);
                }
            });
            $scope.reclassTotal = total;
        };

        $scope.getAccounts = function (val) {

            //console.log('local chart key is : ' + $scope.localChartKey);
            var balSheetAccts = [1, 2, 3];

            var acctType = $scope.sourceAcctType;
            var acct_type_str = "";

            if (acctType in balSheetAccts) {
                acct_type_str = '1,2,3';
            } else {
                acct_type_str = '5,6,7';
            }

            var params = {
                "action_id": 127,
                "screen_key": $scope.mainScreenKey,
                "combination_key": $scope.rowData.COMBINATION_KEY,
                "source_type": ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? 'D' : 'F'),
                "search_type": 'ACCT',
                "search_string": val,
                "acct_type_str": acct_type_str
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };

        $scope.getTradingPartners = function (val) {
            // alert(val);
            var params = {
                "action_id": 128,
                "tax_year": $scope.taxYear,
                "scenario": $scope.scenario,
                "search_string": val
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };


        $scope.setAccount = function (account, index) {
            /*console.log("--selected account object is below and selected account index is --- " + index);
            console.log(account);*/

            account.COMBINATION_KEY = $scope.rowData.COMBINATION_KEY;
            if ($scope.accounts.length === 1 && $scope.rowData.ACCOUNT_KEY === undefined && !$scope.fromScreen) {
                $scope.rowData.ACCOUNT_KEY = account.ACCOUNT_KEY;
                alert("load existing reclass 1");
                loadExistingReclass();
            }
            getAccountData(account, index);
        };

        $scope.validateAcctTp = function(_index) {

            var currAcct = $scope.accounts[_index];
            var _accountKey =  currAcct["ACCOUNT_KEY"];
            var _tpKey = currAcct["TP_KEY"];
            var _tpRepPd = currAcct["TP_REP_PD"];

            var getIndexFoundAt = _.findIndex($scope.accounts, {'ACCTTP': "" + _accountKey + _tpKey + _tpRepPd});
            if(getIndexFoundAt < 0 || _index === getIndexFoundAt) {
                $scope.reclassFrom.innerForm.tradingPartner.$setValidity("invalidAcctTPCombo", true);
                currAcct["INVALIDACCTTP"] = false;
            } else {
                 $scope.reclassFrom.innerForm.tradingPartner.$setValidity("invalidAcctTPCombo", false);
                //AlertService.add("error", "The Account/Trading Partner combination has already been used for this adjustment. Please correct the errors.", 4000);
                /*console.log("account array before splicing FOR INDEX " + _index );
                 console.log($scope.accounts);*/
                $timeout(function () {
                    //$scope.accounts.splice(_index, 1);
                    currAcct["TP_ENTITY"] =  "";
                    currAcct["TP_KEY"] = 0;
                    currAcct["TP_REP_PD"] = 0,
                    currAcct["IS_TP_EXISTS"] = false;
                    currAcct["ACCTTP"] = "";
                    currAcct["ISBOOKAMTLOADING"] = false;
                    currAcct["ISBOOKADJLOADING"] = false;
                    currAcct["INVALIDACCTTP"] = true;
                    currAcct.tpObject = {
                        "TP_ENTITY": "",
                        "TP_KEY": 0,
                        "TP_REP_PD": 0,
                        "TP_LEID": "",
                        "TP_CDR_NO": ""
                    };
                });
            }
        };


        function getAccountData(_data, _index) {

            var tp_exists = ((_data.TP_KEY === 0) ? false : true);
            var tp_rep_pd = ((_data.TP_KEY === 0) ? 0:_data.TP_REP_PD);

            var tp_entity = ((_data.TP_KEY === 0) ? "" : _data.TP_LEID + "-" + _data.TP_CDR_NO + "(Pd:" + tp_rep_pd + ")");
            var type = ((_index < 1) ? "source" : "target");

            if(_index < 1) {
                var tot_book_adj = _data.BOOK_ADJ + _data.PY_DECEMBER + _data.CY_DECEMBER;
            } else {
                 tot_book_adj = _data.BOOK_ADJ;
            }
            var record = {
                "ACCTTP": "" + _data.ACCOUNT_KEY + _data.TP_KEY + tp_rep_pd,
                "ACCOUNT_KEY": _data.ACCOUNT_KEY,
                "SYSTEM_ACCOUNT_KEY": _data.SYSTEM_ACCOUNT_KEY,
                "ACCOUNT": _data.ACCT,
                "ACCOUNT_DESC": _data.ACCT_DESC,
                "TP_ENTITY": tp_entity,
                "TP_KEY": _data.TP_KEY,
                "TP_REP_PD": tp_rep_pd,
                "IS_TP_EXISTS": tp_exists,
                "BOOK_AMT": _data.BOOK_AMT,
                "PREV_ADJ_AMT": tot_book_adj,
                "CUR_ADJ_AMT": 0,
                "TYPE": type,
                "TRANS_DETAILS_KEY": 0,
                "IS_DELETE": "NO"
            };


            // prepopulate trading partner and it should be editable
            if(_data.TP_KEY !== 0) {
                record.tpObject = {
                "TP_ENTITY": tp_entity,
                "TP_KEY": _data.TP_KEY,
                "TP_REP_PD": tp_rep_pd,
                "TP_LEID": _data.TP_LEID,
                "TP_CDR_NO": _data.TP_CDR_NO
            };

            }

            if (typeof $scope.accounts[_index] === 'undefined') {
                record.TYPE = "source";

                $scope.accounts.push(record);
                $scope.addTargetAccount();
                //console.log("source acct type is : " + $scope.sourceAcctType);
            } else {

                /*if( _.find($scope.accounts, {'ACCTTP': record.ACCOUNT_KEY + record.TP_KEY}) !== undefined ){
                 AlertService.add("error", "The Account AND TP Key combination "+record.ACCOUNT+ "/ " + record.TP_ENTITY + " has already been used in this reclass", 4000);
                 $scope.accounts.splice(_index,1);
                 //$scope.addTargetAccount();
                 return false;
                 }*/
                /*console.log('prnting separate attributes');
                console.log(record.ACCOUNT_KEY + "," + record.TP_KEY + "," + record.TP_REP_PD);*/
                //$scope.validateAccountTPKey(record.ACCOUNT_KEY, record.TP_KEY, record.TP_REP_PD, _index);
                record.TYPE = "target";
                if (_index === 0) {
                    record.TYPE = "source";
                    $scope.addTargetAccount();
                }
                $scope.accounts[_index] = record;
            }
            // $scope.setSourceTotal($scope.accounts.length - 1);

            /*var params = {
             "action_id":21,
             "combination_key":_data.COMBINATION_KEY,
             "account_key": _data.ACCOUNT_KEY
             };

             if(typeof $scope.accounts[_index] !== 'undefined') {
             $scope.accounts[_index].TYPE = 'loading';
             }
             JsonObjectFactory.getJSONObj('loadJsonObject.ge',params).then(function(data) {

             if( data.errorMessage &&   data.errorMessage.length > 0 && data.errorMessage !== 'null' ){
             AlertService.add("error", data.errorMessage, 4000);
             }else{


             if(data.jsonObject && data.jsonObject.length && data.jsonObject[0].ACCOUNT_KEY ){
             var record  = data.jsonObject[0];

             console.log("----------printing record 1 ----------");
             console.log(record);
             record.CUR_ADJ_AMT = null;

             if(typeof $scope.accounts[_index] === 'undefined') {
             record.TYPE = "source";

             $scope.accounts.push(record);
             $scope.addTargetAccount();
             }else{
             if( _.find($scope.accounts, {'ACCOUNT_KEY': record.ACCOUNT_KEY}) &&
             _.find($scope.accounts, {'TP_KEY': record.TP_KEY}) !== undefined ){
             AlertService.add("error", "The Account AND TP Key combination "+record.ACCOUNT+ "/ " + record.TP_ENTITY + " has already been used in this reclass", 4000);
             $scope.accounts.splice(_index,1);
             $scope.addTargetAccount();
             return false;
             }
             record.TYPE = "target";
             if(_index === 0 ){
             record.TYPE = "source";
             $scope.addTargetAccount();
             }
             $scope.accounts[_index] =  record;
             }
             $scope.setSourceTotal($scope.accounts.length - 1);
             }else{
             $scope.accounts.splice(_index,1);
             $scope.addTargetAccount();
             AlertService.add("error", "The selected Account did not return a book amount", 4000);
             }

             }
             });*/

        }

        $scope.showDetails = function (_index, _boolean) {
            $scope.reclassArray[_index].open = _boolean;
        };



        $scope.deleteReclass = function (reclass) {
            var returnObj = {};
            $scope.existingLoading = true;
            //returnObj.reclass_description = $scope.RECLASS_DESCRIPTION;
            returnObj.trans_header_key = reclass.TRANS_HEADER_KEY;
            returnObj.type = "D";
            returnObj.combination_key = $scope.rowData.COMBINATION_KEY;
            returnObj.chart_key = $scope.rowData.CHART_KEY;
            returnObj.screen_key = 23;
            //var accountArray = [];
            var message = "Reclass has been successfully deleted";
            reclassCRUDHttp(returnObj, '', message);
        };


        $scope.reset = function () {
            init();
        };



        $scope.save = function () {
            //alert("the adjustment type selected is : " + $scope.RECLASS_TYPE);
            if ($scope.reclassFrom.$invalid) {
                $scope.reclassFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            $scope.crudLoading = true;
            var objSettings = [];
            var returnObj = {};
            var reclassMainType, reclassSubtype;
            returnObj.RECLASS_DESCRIPTION = $scope.RECLASS_DESCRIPTION;
            //returnObj.TRANS_HEADER_KEY = $scope.TRANS_HEADER_KEY;
            returnObj.TYPE = ($scope.TRANS_HEADER_KEY !== "") ? "U" : "C";
            returnObj.TRANS_HEADER_KEY = ($scope.TRANS_HEADER_KEY !== "") ? $scope.TRANS_HEADER_KEY : 0;
            switch ($scope.RECLASS_TYPE) {
                case 'RCY':
                    reclassMainType = 'R';
                    reclassSubtype = 'M';
                    break;
                case 'CYD':
                    reclassMainType = 'R';
                    reclassSubtype = 'C';
                    break;
                case 'PYD':
                    reclassMainType = 'R';
                    reclassSubtype = 'P';
                    break;
                case 'SCY':
                    reclassMainType = 'S';
                    reclassSubtype = 'M';
                    break;
            }

            returnObj.RECLASSMAINTYPE = reclassMainType;
            returnObj.RECLASSSUBTYPE = reclassSubtype;
            returnObj.ADJ_ORIGIN_SOURCE = $scope.rowData.SOURCE_SYSTEM;
            returnObj.SOURCE_SYSTEM_FLAG = (returnObj.ADJ_ORIGIN_SOURCE === 'DCS') ? 'D':'F';
            var message = (returnObj.type === "U") ? "Reclass has been successfully updated" : "Reclass has been successfully created";
            returnObj.COMBINATION_KEY = $scope.rowData.COMBINATION_KEY;
            returnObj.CHART_KEY = $scope.rowData.CHART_KEY;
            returnObj.SCREEN_KEY = 23;
            returnObj.TRANS_DETAILS_KEY = {};

            var accountArray = [];
            var accounts = $scope.accounts;

           /* console.log('-----------accounts for saving ---------------');
            console.log(accounts);*/
            _.forEach($scope.accounts, function (value, key) {

                var accountM = new accountModel(value.ACCOUNT, value.SYSTEM_ACCOUNT_KEY, value.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
                accountArray.push(accountM);
            });

            //alert($scope.delTransDetails.length);
            if($scope.delTransDetails.length) {
                 _.forEach($scope.delTransDetails, function (value, key) {

                    var accountM = new accountModel(value.ACCOUNT, value.SYSTEM_ACCOUNT_KEY, value.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
                    accountArray.push(accountM);
                });

            }
            /*console.log("accounts to be saved below ");
            console.log(accountArray);
            console.log("return object to be saved");
            console.log(returnObj);*/

            objSettings.push(returnObj);

            /*console.log("object settings to be saved");
            console.log(objSettings);*/

            reclassCRUDHttp(objSettings, accountArray, message);

        };


        function reclassCRUDHttp(returnObj, accountArray, message) {

            if(returnObj.type === 'D') {
                ReclassFactory.deleteReclass(returnObj).then(function (result) {

                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                        //BROADCAST CHANGE
                        //$uibModalInstance.close();
                        var args = {combination_keys: returnObj.combination_key, gridFilter: {COMBINATION_KEY: returnObj.combination_key}};
                        $rootScope.$emit('gridUpdate', args);

                       // var args = {system_chart: returnObj.combination_key, gridFilter: {COMBINATION_KEY: returnObj.combination_key}};
                       // $rootScope.$emit('gridUpdate', args);


                        init();
                    }
                });
            } else {


                ReclassFactory.saveReclass(returnObj, accountArray).then(function (result) {

                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                        //BROADCAST CHANGE
                        //$uibModalInstance.close();
                        var args = {combination_keys: returnObj.combination_key, gridFilter: {COMBINATION_KEY: returnObj.combination_key}};
                        $rootScope.$emit('gridUpdate', args);
                        init();
                    }
                });
            }
        }

        function accountModel(accountCode, acctKey, tp_key, amount, trans_dtls_key, is_delete) {
            this.ACCOUNT_CODE = accountCode;
            this.ACCT_KEY = acctKey;
            this.TP_KEY = tp_key;
            this.AMOUNT = amount;
            this.TRANS_DETAILS_KEY = trans_dtls_key;
            this.IS_DELETE = is_delete;
        }

        accountModel.prototype.addAccount = function () {

        };

     /*    $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }; */



        $scope.loadReclass = function (_index) {
            $scope.delTransDetails = [];
            var data = $scope.reclassArray[_index];

            $scope.RECLASS_DESCRIPTION = data.ADJ_DESC;
            $scope.TRANS_HEADER_KEY = data.TRANS_HEADER_KEY;
            $scope.accounts = data.ACCOUNTS;
            $scope.RECLASS_TYPE = data.SHORT_ADJ_TYPE;

            _.forEach($scope.accounts, function (value, key) {
                $scope.setSourceTotal(key);
            });

            setReclassTotal();

        };


        function loadExistingReclass() {
            $scope.existingLoading = true;
            var params = {
                "action_id": 23,
                "screen_key": $scope.mainScreenKey,
                "combination_key": $scope.rowData.COMBINATION_KEY,
                "account_key": $scope.rowData.ACCOUNT_KEY
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {


                $scope.reclassArray = formatReclassData(data.jsonObject);

                setExistingReclassTotal($scope.reclassArray);


                $scope.existingLoading = false;
                if ($scope.reclassArray.length === 0) {
                    $scope.noRecords = true;
                }
            });
        }

        function formatReclassData(_data) {
            var reclasses = [];
            var accounts = [];
            _.forEach(_data, function (value, key) {

                if (value.ROW_INDICATOR === 1) {

                    var ReclassObj = {};
                    ReclassObj = value;
                    ReclassObj.ACCOUNTS = [];
                    reclasses.push(ReclassObj); // JUST USE VALUE
                    /*console.log("row indicator 1");
                    console.log(ReclassObj);*/
                } else {
                    var AccountObj = {};
                    AccountObj = value;
                    /*if (AccountObj.ACCOUNT === $scope.rowData.LOCAL_ACCT) {
                        AccountObj.TYPE = "source";
                    }*/
                  /*  console.log("row indicator : " + value.ROW_INDICATOR);
                    console.log(AccountObj);*/
                    AccountObj.TYPE = "edit";
                    accounts.push(AccountObj);

                }
            });


            _.forEach(reclasses, function (reclassVal, reclassKey) {
                reclasses[reclassKey].ACCOUNTS = [];
                var row_one_trans_header_key = reclassVal.TRANS_HEADER_KEY;

                _.forEach(accounts, function (value, key) {

                    var account_trans_header_key = value.TRANS_HEADER_KEY;

                    if (value.ROW_INDICATOR !== 1 && row_one_trans_header_key === account_trans_header_key) {
                      reclasses[reclassKey].ACCOUNTS.push(value);

                    }

                });
            });

            accounts = null;
            return reclasses;

        }
    }///////////////END CTRL

    return controllers;


});