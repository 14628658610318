define([
    'angular'

 ], function () {
    'use strict';
    
    
    var controllers = angular.module('app.F-8824Ctrl', [])
    .controller('F8824Controller', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F8824Factory', 'ModalFactory',F8824Controller])
    .controller('F8824ControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F8824Factory', 'ModalFactory','$uibModalInstance','rowData', 'colData', 'gridData',F8824ControllerModal])    ;

    function F8824Controller(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F8824Factory,ModalFactory) {
    	F8824ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F8824Factory,ModalFactory,{},{}, {}, {},this);
    }


	function F8824ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F8824Factory,ModalFactory,$uibModalInstance,
        rowData, colData, gridData,thisObj) {
		console.log("------------At the start-----------");
		/* Flags : IS_EDITTED : Used to show the green color check On Prop table (1st Table),
					IS_PROP_CHANGED :  Will tell if any attribute is changed on the Current Occ List,
					currentOccValChanged : Flag will tell values are diff from previous ones
					IS_NEW_ROW : Flag is used to tell if it's new row
		*/			
		var vm = this;
		vm.loadingData = true;
		vm.rowData = rowData;
		vm.globalParams = GlobalService.globalParams;
		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		vm.curentyear = filterParams.tax_year;
		console.log("The Global Params like tax_year, scenario and jcd are ::::::::::::", vm.globalParams);
		vm.f8824Header = [];
		vm.f8824MasterData = [];
		vm.f8824OriginalData = [];
		vm.f8824OriginalRadioData = [];
		vm.f6252FinalData = [];
		vm.crudLoading = false;
		vm.showOccurrenceTable = false;
		vm.showTransAttributes = false;
		vm.initialLoad = false;
		vm.calendar = {
    			opened: false
    		};
		
		
		vm.cancel = function() {
			console.log("Form 6252 data from DB is :::::::::::", vm.f8824MasterData);
			console.log("Form 6252 data from original Data is saved in :::::::::::", vm.f8824OriginalData);
			if(angular.equals(vm.f8824MasterData, vm.f8824OriginalData)){
				$uibModalInstance.dismiss('cancel');
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
					vm.cancelModal();
				}
				else {
					$uibModalInstance.dismiss('cancel');
				}
			}
			
		};
		
        
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		console.log(vm.logged_in_user);
		
		function getF8824MasterData(){
			//get master data
			F8824Factory.fetchF8824MasterData(rowData, vm.logged_in_user).then(function (data){
				console.log("Came back to controller Master data from Service...............:::::::::", data);
				vm.f8824Header = data.jsonObject[0];
				vm.f8824List1 = data.jsonObject.slice(1);
				vm.tableHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"TH"});
				
				vm.f8824MasterData = vm.f8824List1.filter(function( obj ) {
					return obj.ROW_TYPE !== 'TH';
				});

				vm.f8824PropertyTable = vm.f8824List1.filter(function( obj ) {
					return obj.ROW_TYPE == 'CH';
				});
				
			//	vm.f8824PropertyTable1.push(vm.f8824PropertyTable);

				//ROW_TYPE
				var m = vm.f8824PropertyTable.reduce( (a,b) => {
					a[b.ROW_TYPE] = a[b.ROW_TYPE] || [];
					a[b.ROW_TYPE].push(b);
					return a;
				}, {});
				
				var b = Object.keys(m).map(function(k){
					return {ROW_TYPE: m[k][0].ROW_TYPE, Row_data: m[k], OCCURRENCE: null, IS_EDITTED:"N", DELETE_ROWS: []};
				});
				
				vm.f8824OccData = b ;
				vm.f8824OccDataOriginal = angular.copy(vm.f8824OccData);

				vm.outerList = [];
				vm.innerList = [];
				vm.radioLines = [];
				for(var i=0;i<vm.f8824MasterData.length;i++){
					if(vm.f8824MasterData[i].FORM_LINE_KEY != null){
						//radio
						if(vm.f8824MasterData[i].EDIT_TYPE == "radio"){
							vm.radioLines.push(vm.f8824MasterData[i]);
						}//end radio
						if(vm.outerList != null && vm.outerList.length > 0){
							var tempCheck1 = $filter("filter")(vm.outerList, {FORM_LINE_KEY:vm.f8824MasterData[i].FORM_LINE_KEY, IS_FORM_DISPLAY:'Y'});
						}
						if(tempCheck1 == undefined || tempCheck1.length==0){
							vm.outerList.push(vm.f8824MasterData[i]);
						}

					}
				}

				if(vm.f8824TransRowData.length != 0){
					vm.showOccurrenceTable = true;
					vm.f8824OccDataOriginal = vm.f8824TransRowData;
				}
				vm.loadingData = false;
				
			});	
		}

		function getF8824TransData(){

				F8824Factory.fetchF8824Details(rowData, vm.logged_in_user).then(function (data){
					console.log("Came back to controller from Service...............:::::::::", data);
					vm.f8824TransList = data.jsonObject;

					//Header Transactions
					vm.f8824TransList1 = vm.f8824TransList.filter(function( obj ) {
						return obj.ROW_TYPE == 'CH';
					});

					//Body Transactions
					vm.f8824BodyTransList = vm.f8824TransList.filter(function( obj ) {
						return obj.ROW_TYPE !== 'CH';
					});

					//ROW_TYPE
					var m = vm.f8824TransList1.reduce( (a,b) => {
						a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
						a[b.OCCURRENCE].push(b);
						return a;
					}, {});
					
					var b = Object.keys(m).map(function(k){
						return {ROW_TYPE: m[k][0].ROW_TYPE, Row_data: m[k], OCCURRENCE:  m[k][0].OCCURRENCE, IS_EDITTED:"N", DELETE_ROWS: []};
					});
					
					vm.f8824TransRowData = b;
					console.log("After first Reduce at IS_MULTI now the data is", vm.f6252TransRowData);
					
					vm.maxOccObj = _.maxBy(vm.f8824TransRowData, 'OCCURRENCE');
					if(vm.maxOccObj != undefined){
						vm.maxOcc = vm.maxOccObj.OCCURRENCE;
						console.log(vm.maxOcc);
					}else{
						vm.maxOcc = 0;
					}

					getF8824MasterData();
				});	
			
		}

		vm.fetchLOV = function(order) {
			var params = { "action_code": 'sjh60s', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": vm.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "form_no":'6252' };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				vm.formLOV = data.jsonObject;
				getF8824TransData();
	
			});
		}
		
		vm.fetchModalCalcData = function() {
			var params = { "action_code": '1ubsuu', "tax_year": vm.curentyear, "scenario": vm.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
					"form_no":'6252',"schedule_name":'N/A',"part_no":'',"section_name":'N/A' };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				vm.formCalcData =  data.jsonObject;
			});
		}
		vm.fetchLOV();
		vm.fetchModalCalcData();
		
		vm.checkBoxHandler = function(lineKey, attribKey, attribCol, occurrence){
			vm.checkedVal = [];		    
		    vm.checkedVal = $filter("filter")(vm.radioLines, {FORM_LINE_KEY:lineKey, ATTRIB_COL:attribCol, OCCURRENCE : occurrence, IS_FORM_DISPLAY : 'Y'});

			_.forEach(vm.checkedVal,function(el,index){
				if (el.LINE_ATTRIB_KEY == attribKey) {
					if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
						el.ATTRIB_VALUE = undefined;
						el.ATTRIB_VALUE = 'true';
					}else{
						el["ATTRIB_VALUE"] = 'true';
					} 
					el.IS_CHANGED_FLAG = 'Y';
				}else{
					if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
						el.ATTRIB_VALUE = undefined;
						el.ATTRIB_VALUE = 'false';
					}else{
						el.ATTRIB_VALUE = undefined;
						el["ATTRIB_VALUE"] = 'false';
					} 
					el.IS_CHANGED_FLAG = 'Y';
				}
			});

		}			
		
		vm.reset = function(){
			//getF8824Data();
			getF8824TransData();
			vm.showTransAttributes = false;
		}
				
		vm.save = function(occurrence, type){
			vm.finalData = [];
			vm.clob_settings = [];
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			vm.f6252FinalData = angular.copy(vm.f8824MasterData);
			if(type != 'delete' && angular.equals(vm.f8824MasterData, vm.f8824OriginalData)){
				//if(angular.equals(vm.f8824MasterData, vm.f8824OriginalData)){
					vm.cancel();
				//}
			}else{
				//updateFormDataValues();
				var returnValue = validateFormValues(vm.currentOccurrence);
				if(returnValue == 1){
					return false;
				}
				
						
						vm.finalLines = _.filter(vm.f6252FinalData, function(o) { 
							return o.OCCURRENCE == occurrence; 
						})
						vm.finalOccLines = _.filter(vm.f8824OccDataOriginal, function(o) { 
							return o.OCCURRENCE == occurrence; 
						})
						
						if(type == 'save'){
								for(var k = 0; k < vm.finalLines.length; k++){
									if(	vm.finalLines[k].ROW_TYPE != 'CH'){
										//always will send line 1 details as changed
										//vm.finalLines[k].IS_CHANGED_FLAG = "Y";
										// For dates
										if(vm.finalLines[k].EDIT_TYPE == 'custom_date_various'){
											var str = $.datepicker.formatDate('mm/dd/yy', vm.finalLines[k].ATTRIB_VALUE);
											vm.finalLines[k].ATTRIB_VALUE = str;
										}
										vm.finalData.push(vm.finalLines[k]);
									}
								 }
								 for(var m = 0; m < vm.finalOccLines.length; m++){
									if(	vm.finalOccLines[m].ROW_TYPE === 'CH'){
										for(var n = 0; n < vm.finalOccLines[m].Row_data.length; n++){
											vm.finalOccLines[m].Row_data[n].IS_CHANGED_FLAG = "Y";
											vm.finalData.push(vm.finalOccLines[m].Row_data[n]);
										}										
									}
								 }

						} else{
							//If deleted row

						for(var k = 0; k < vm.finalLines.length; k++){
							if(	vm.finalLines[k].ROW_TYPE != 'CH'){
								vm.finalLines[k].IS_CHANGED_FLAG = "D";
								vm.finalData.push(vm.finalLines[k]);
							}
						}

						if(vm.finalOccLines[0].DELETE_ROWS.length != null && vm.finalOccLines[0].DELETE_ROWS.length != undefined){
							if(	vm.finalOccLines[0].ROW_TYPE === 'CH'){
								for(var m = 0; m < vm.finalOccLines[0].DELETE_ROWS.length; m++){
										vm.finalOccLines[0].DELETE_ROWS[m].IS_CHANGED_FLAG = "D";
										vm.finalData.push(vm.finalOccLines[0].DELETE_ROWS[m]);									
								}
							}	
						}								
					 }
				

				
				console.log("After changes, the final data was :::::::::::=========", vm.f6252FinalData);
				console.log("After changes, the manipulated data was :::::::::::=========", vm.f8824MasterData);
				console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
				vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
				sendDetails(vm.finalData, vm.clob_settings);
			}
		}
		
		function sendDetails(final_data, clob_settings){
			var message = "Form 8824 Details are successfully updated."
			vm.crudLoading = true;
			F8824Factory.saveForm8824(final_data, clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		}
		
		function getDateFormat(date_value){
			console.log("The value of the date for the row that needs to convert is ::::::", date_value);
			var date_selected = null;
			if(date_value != "VARIOUS" && date_value != null){
				date_selected = ((date_value.getMonth() > 8) ? (date_value.getMonth() + 1) : ('0' + (date_value.getMonth() + 1))) + '/' + ((date_value.getDate() > 9) ? date_value.getDate() : ('0' + date_value.getDate())) + '/' + date_value.getFullYear();
			};
			
			return date_selected;
		}
		
		function updateFormDataValues(){
			for (var i=0; i < vm.f6252FinalData.length; i++){
				if(vm.f6252FinalData[i].IS_MULTI == 'Y'){
					for(var j=0; j < vm.f6252FinalData[i].Row_data.length; j++){
						if(vm.f6252FinalData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW"){
							for(var l=0; l < vm.f6252FinalData[i].Row_data[j].Line_data.length; l++){
								if(vm.f6252FinalData[i].Row_data[j].Line_data[l].IS_EDITABLE_CELL == "Y" && vm.f6252FinalData[i].Row_data[j].Line_data[l].DATA_TYPE == "dateVarious" && vm.f6252FinalData[i].Row_data[j].Line_data[l].EDIT_TYPE == "custom_date_various"){
									vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = getDateFormat(vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE);
								} else if(vm.f6252FinalData[i].Row_data[j].Line_data[l].IS_EDITABLE_CELL == "Y" && vm.f6252FinalData[i].Row_data[j].Line_data[l].DATA_TYPE == "string" && vm.f6252FinalData[i].Row_data[j].Line_data[l].EDIT_TYPE == "checkbox"){
									if(vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE == null ||vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE == undefined){
										vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = "N";
									}
								}
							}
						}
					}
				}
			}
		}
		
		function validateFormValues(currentOccurrence){
			var returnValue = 0;
			var dateSold = null;
			var dateAcquired = null;

			vm.currentOccData = _.filter(vm.f8824OccDataOriginal, function(o) { 
				return o.OCCURRENCE == currentOccurrence;
			})

			for (var i=0; i < vm.currentOccData.length; i++){
				if(vm.currentOccData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.currentOccData[i].Row_data.length; j++){
						var line_no = vm.currentOccData[i].Row_data[j].LINE_NO;

						if(vm.currentOccData[i].Row_data[j].OCCURRENCE == currentOccurrence){
								var long_short_index = vm.currentOccData[i].Row_data.findIndex(x => x.ATTRIB_SHORT_CODE == "LONG_SHORT_TERM_"+line_no);
								var schedule_index = vm.currentOccData[i].Row_data.findIndex(x => x.ATTRIB_SHORT_CODE == "SCHEDULE_"+line_no);

								if(vm.currentOccData[i].Row_data[long_short_index].ATTRIB_VALUE == null){
									vm.currentOccData[i].Row_data[long_short_index].HAS_ERROR = "Y";
									vm.currentOccData[i].Row_data[long_short_index].ATTRIB_VALUE_ERROR = "Please select Long/Short Term.";
									returnValue = 1; 
								}
								if(vm.currentOccData[i].Row_data[schedule_index].ATTRIB_VALUE == null){
									vm.currentOccData[i].Row_data[schedule_index].HAS_ERROR = "Y";
									vm.currentOccData[i].Row_data[schedule_index].ATTRIB_VALUE_ERROR = "Please select Schedule.";
									returnValue = 1;
								}
					  }
					}
				}
			}
			return returnValue;
		}
		
		function getDiffDates(dateAcquired, dateSold){
			if(typeof dateAcquired === 'string'){
				dateAcquired = convertToDate(dateAcquired);
				dateSold = convertToDate(dateSold);
			}
			
			return ((dateSold -  dateAcquired)/ (1000 * 3600 * 24));
		}
		
		function convertToDate(str)
		{
		   var dateArr = str.split("/");
		   return new Date(dateArr[2], dateArr[0], dateArr[1])
		}
		
		
		
		vm.AddNewRow = function(occurrence,line,index){			
			var trans_details_key = null;

			//On Add check if any Property details are editted
			vm.prevEdittedRow = vm.f8824OccDataOriginal.filter(function( obj ) {
				return obj.IS_PROP_CHANGED == 'Y';
			});

			if(vm.prevEdittedRow.length > 0 ){
				AlertService.add("error", "Please save the details of the changed S No " + vm.prevEdittedRow[0].OCCURRENCE + "  before you Add a new S No");
				return;
			}

			//On Add check if already any New Property is added
			vm.newRow = vm.f8824OccDataOriginal.filter(function( obj ) {
				return obj.IS_EDITTED == 'Y';
			});
	
			if(vm.newRow.length > 0 ){
				AlertService.add("error", "Please save the details of the S No " + vm.newRow[0].OCCURRENCE + "  before you Add a new S No");
				return;
			}

			if(vm.maxOcc == 0){
				vm.maxOcc = vm.maxOcc + 1;
				vm.f8824OccDataOriginal[0].OCCURRENCE = vm.maxOcc;
				vm.f8824OccDataOriginal[0].IS_EDITTED = "Y";
			_.forEach(vm.f8824OccDataOriginal[0].Row_data,function(dl,index){
				if(dl.ATTRIB_SHORT_CODE =='SNO_N/A'){
					dl.ATTRIB_VALUE = vm.maxOcc;
				}else{
					dl.ATTRIB_VALUE = null;
				}
					dl.OCCURRENCE = vm.maxOcc;
			});

			}else{
				vm.maxOcc = vm.maxOcc + 1;
				var new_row = angular.copy(vm.f8824OccDataOriginal[0]);
				new_row.OCCURRENCE = vm.maxOcc;
				new_row.IS_EDITTED = "Y";
				new_row.IS_NEW_ROW = "Y";

				for (var j=0; j < new_row.Row_data.length; j++){
					if(new_row.Row_data[j].EDIT_TYPE == "radio" && new_row.Row_data[j].DATA_TYPE == "string"){
						new_row.Row_data[j].ATTRIB_VALUE = null;
					} else if (new_row.Row_data[j].EDIT_TYPE == "label" && new_row.Row_data[j].DATA_TYPE == "string"){
						new_row.Row_data[j].ATTRIB_VALUE = vm.maxOcc;
					} else {	
						new_row.Row_data[j].ATTRIB_VALUE = null;
					}
					new_row.Row_data[j].OCCURRENCE = vm.maxOcc;
					//new_row.Row_data[j].TRANS_DETAILS_KEY = trans_details_key;
					new_row.Row_data[j].IS_CHANGED_FLAG = "Y";
				}
				vm.f8824OccDataOriginal.push(new_row);
		}


		for (var m = 0; m < vm.radioLines.length; m++){
			vm.radioLines[m]['OCCURRENCE'] = vm.maxOcc;
		}

		for (var i = 0; i < vm.outerList.length; i++){
			
				if(vm.outerList[i].EDIT_TYPE == "custom_date_various" && vm.outerList[i].DATA_TYPE == "dateVarious"){
					vm.outerList[i].ATTRIB_VALUE = null;//new Date();
					vm.outerList[i]['OCCURRENCE'] = vm.maxOcc;
				} else if (vm.outerList[i].EDIT_TYPE == "currency" && vm.outerList[i].DATA_TYPE == "number"){
					vm.outerList[i].ATTRIB_VALUE = 0;
					vm.outerList[i]['OCCURRENCE'] = vm.maxOcc;
				} else if (vm.outerList[i].EDIT_TYPE == "label" && vm.outerList[i].DATA_TYPE == "string"){
					vm.outerList[i]['OCCURRENCE'] = vm.maxOcc;
					if(vm.outerList[i].ATTRIB_SHORT_CODE == 'SNO_N/A'){
						vm.outerList[i].ATTRIB_VALUE = vm.maxOcc;
					}else{
						vm.outerList[i].ATTRIB_VALUE = null;
					}								
				} else {	
					vm.outerList[i].ATTRIB_VALUE = null;
					vm.outerList[i]['OCCURRENCE'] = vm.maxOcc;
				}							
			vm.currentOccurrence = vm.maxOcc;//outerList.TRANS_OCCURRENCE; 
		}

		
			//Original Data
			vm.f8824OriginalData = angular.copy(vm.f8824MasterData);
			vm.f8824OriginalRadioData = angular.copy(vm.radioLines);

			vm.showOccurrenceTable = true;
			vm.showTransAttributes = true;
			console.log(vm.outerList);
		}
		
		vm.editExistingRecord = function(line_no, occurrence, index, type){
			//On edit show the check if any Property details are editted
			vm.prevEdittedRow = vm.f8824OccDataOriginal.filter(function( obj ) {
				return obj.IS_PROP_CHANGED == 'Y' && obj.OCCURRENCE != occurrence;
			});

			if(vm.prevEdittedRow.length > 0 ){
				AlertService.add("error", "Please save the details of the changed S No " + vm.prevEdittedRow[0].OCCURRENCE + "  before you Edit a new S No");
				return;
			}

			//On edit check if already any New Property is added
			vm.excistRow = vm.f8824OccDataOriginal.filter(function( obj ) {
				return obj.IS_EDITTED == 'Y';
			});
	
			if(vm.excistRow.length > 0 ){
				AlertService.add("error", "Please save the details of the S No " + vm.excistRow[0].OCCURRENCE + "  before you edit other S No");
				return;
			}
			
			//On edit show the save circle symbol
			vm.edittedRow = vm.f8824OccDataOriginal.filter(function( obj ) {
				return obj.OCCURRENCE == occurrence;
			});
			vm.edittedRow[0].IS_EDITTED = 'Y';

			//To show the ediited values of same occurrence
			if(vm.edittedRow[0].IS_PROP_CHANGED != 'Y'){
				vm.assignTransToMasterData(occurrence);
			}
		
			vm.currentOccurrence = occurrence;
			vm.showOccurrenceTable = true;
			vm.showTransAttributes = true;
		}

		//Assign Transactions To Master data on Occurrence
		vm.assignTransToMasterData = function(occurrence) {

			//Body Occ Transactions
			vm.f8824BodyOccTransList = vm.f8824BodyTransList.filter(function( obj ) {
				return obj.OCCURRENCE == occurrence;
			});

			for (var j=0; j < vm.outerList.length; j++){
				vm.outerList[j]['OCCURRENCE'] = occurrence;

				for(var i = 0; i < vm.f8824BodyOccTransList.length; i++){
						vm.currentList = vm.outerList.filter(function( obj ) {
							return (obj.LINE_ATTRIB_KEY == vm.f8824BodyOccTransList[i].LINE_ATTRIB_KEY) &&  vm.f8824BodyOccTransList[i].EDIT_TYPE != 'radio'; 
						});
						if(vm.currentList.length != 0){
							if(vm.currentList[0].EDIT_TYPE == 'custom_date_various'){
								vm.currentList[0].ATTRIB_VALUE =  new Date(vm.f8824BodyOccTransList[i].ATTRIB_VALUE);
								vm.currentList[0].TRANS_DETAILS_KEY = vm.f8824BodyOccTransList[i].TRANS_DETAILS_KEY;
							}else{
								vm.currentList[0].ATTRIB_VALUE = vm.f8824BodyOccTransList[i].ATTRIB_VALUE;
								vm.currentList[0].TRANS_DETAILS_KEY = vm.f8824BodyOccTransList[i].TRANS_DETAILS_KEY;
							}
							
						}
				}
			}

			for (var m = 0; m < vm.radioLines.length; m++){
				vm.radioLines[m]['OCCURRENCE'] = occurrence;

				for(var i = 0; i < vm.f8824BodyOccTransList.length; i++){
					vm.currentList = vm.radioLines.filter(function( obj ) {
						return (obj.LINE_ATTRIB_KEY == vm.f8824BodyOccTransList[i].LINE_ATTRIB_KEY) && vm.f8824BodyOccTransList[i].EDIT_TYPE == 'radio'; 
					});
					if(vm.currentList.length != 0){
						vm.currentList[0].ATTRIB_VALUE = vm.f8824BodyOccTransList[i].ATTRIB_VALUE;
					}
				}
				
			}

			//Original Data
			vm.f8824OriginalData = angular.copy(vm.f8824MasterData);
			vm.f8824OriginalRadioData = angular.copy(vm.radioLines);

		}

		
		vm.removeExistingRecord = function(line_no, Occurrence, index){

			//On delete check if already any Property is editted
			vm.editRow = vm.f8824OccDataOriginal.filter(function( obj ) {
				return obj.IS_PROP_CHANGED == 'Y' && obj.OCCURRENCE != Occurrence;
			});

			if(vm.editRow.length > 0 ){
				AlertService.add("error", "Please save the details of the S No " + vm.editRow[0].OCCURRENCE + "  before you delete a S No " + Occurrence + "." );
				return;
			}

			for (var i=0; i < vm.f8824OccDataOriginal.length; i++){	
				for(var j=0; j < vm.f8824OccDataOriginal[i].Row_data.length; j++){

						if(vm.f8824OccDataOriginal[i].IS_NEW_ROW == "Y" &&  vm.f8824OccDataOriginal[i].OCCURRENCE != "" && null != vm.f8824OccDataOriginal[i].OCCURRENCE && vm.f8824OccDataOriginal[i].OCCURRENCE == Occurrence){
							//never saved in to DB
							vm.f8824OccDataOriginal[i].DELETE_ROWS = [];
							vm.deleteSave = false;

						}else{
							if(vm.f8824OccDataOriginal[i].OCCURRENCE == Occurrence){
								vm.f8824OccDataOriginal[i].DELETE_ROWS.push(vm.f8824OccDataOriginal[i].Row_data[j]);
								vm.deleteSave = true;
							}
						}
					}			

			}

			vm.currentOccurrence = Occurrence;
			if(vm.deleteSave == true){
				vm.assignTransToMasterData(vm.currentOccurrence);
				vm.save(vm.currentOccurrence,'delete');
			}else{
				vm.reset();
			}
		}
		
		vm.saveNewEdittedRecord = function(line_no, occurrence, index){
			for (var i=0; i < vm.f8824OccDataOriginal.length; i++){
				if(vm.f8824OccDataOriginal[i].OCCURRENCE == occurrence){
					vm.f8824OccDataOriginal[i].IS_EDITTED = 'N';							
					//To fill the required fields
					var returnValue = validateFormValues(occurrence);
					if(returnValue == 1){
						vm.f8824OccDataOriginal[i].IS_EDITTED = 'Y';	
						return false;
					}
				}
			}
			vm.showTransAttributes = false;
		}

		
		vm.valuechanged = function(row, occurrence, line_no, data_type){
			
			vm.outerListLine = _.filter(vm.outerList, function(o) { 
				return o.LINE_NO == line_no && o.OCCURRENCE == occurrence ; 
			})
			vm.outerListLine[0].IS_CHANGED_FLAG = "Y";
			vm.outerListLine[0].HAS_ERROR = "N";

			if(data_type != 'number'){
				vm.OccLines = _.filter(vm.f8824OccDataOriginal, function(o) { 
					return o.OCCURRENCE == occurrence; 
				})
				vm.OccLines[0].IS_PROP_CHANGED = 'Y';
				row.HAS_ERROR = "N";
			}
			
			console.log(vm.f8824MasterData);
		}
		
		
		vm.updateAmount = function(index,occurence, line_no){
			console.log("We are updating the amounts for this column:::::: ", index);
			vm.calcFinalAmounts(occurence);
		}
		

		vm.calcFinalAmounts = function(occurence){
			for (var i=0; i < vm.f8824MasterData.length; i++){
				if(vm.f8824MasterData[i].ROW_TYPE != 'CH'){
					//	if( vm.f8824MasterData[i].OCCURRENCE == occurence){
						if(vm.f8824MasterData[i].ATTRIB_CALC_FORMULA != null){
							vm.f8824MasterData[i].ATTRIB_VALUE = vm.calc_final_amt(vm.f8824MasterData[i].ATTRIB_CALC_FORMULA,occurence);
							if(vm.f8824MasterData[i].IS_FORM_DISPLAY == 'Y'){
								vm.f8824MasterData[i].IS_CHANGED_FLAG = "Y";
								//To Know if Attributes of this Occ is changed 
								if(vm.f8824MasterData[i].DATA_TYPE == 'number' && vm.currentOccValChanged == true){
									vm.OccNumLines = _.filter(vm.f8824OccDataOriginal, function(o) { 
										return o.OCCURRENCE == occurence; 
									})
									vm.OccNumLines[0].IS_PROP_CHANGED = 'Y';
								}
							}
						}							
					//	}
				}
			}
			
		}
		
		vm.calc_final_amt = function(formula,occurence){	
//			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			
			var matches = formula.match(/\b[^\d\s]+\b/g);
			if(matches ==  null){
				var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			}else{
				var formulaLength = formula.length;
				var subFormula = "";
				subFormula = formula.substring(formula.indexOf("("),formulaLength-1);
				var lineFormulaArray = subFormula.split(/[+-,/\\*\\]/);
			}
			
			vm.currentOccValChanged = false;
			for(var i=0; i<lineFormulaArray.length; i++){
				var lineAmt = 0;
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					//	var currentTabAtrribVal = $filter("filter")(vm.currentOccData[0].Line_data[a].Line_data_occ[b], {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var OldAtrribVal = $filter("filter")(vm.f8824OriginalData, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var currentNewAtrribVal = _.filter(vm.f8824MasterData, function(o) { 
						return o.ATTRIB_SHORT_CODE == lineFormulaArray[i].trim(); 
					});

						if(currentNewAtrribVal[0].ATTRIB_SHORT_CODE == lineFormulaArray[i].trim()){
							lineAmt =  (currentNewAtrribVal[0].ATTRIB_VALUE);

							if(lineAmt != parseFloat(OldAtrribVal[0].ATTRIB_VALUE)){
								vm.currentOccValChanged = true;
							}
							
							
							if(parseFloat(lineAmt)>= 0){
								formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
							}else{
								formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
							}
						}
				}
			}
		
				if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
					var calculatedVal = eval(formula);
					return calculatedVal.toFixed();
				}else return 0;
		}


		vm.formatDate = function(row) {
        	row["taxyearBE"] = $filter('date')(row.ATTRIB_VALUE, 'MM/dd/yyyy');
        };
		
		vm.openCalendar = function(row) {
			row['isOpen'] = true;
		};
		
		vm.openCalendar1 = function(row) {
			row['isOpen'] = true;
		};
		
		
	}
});