define([
    'angular',
    './diagnosticsReportController'
], function () {
    'use strict';
    
    var module = angular.module('app.diagnosticsReport', ['app.DiagnosticsReportController'])
        
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('diagnostics-report', {
                url: '/diagnostics-report',
                "templateUrl": "app/components/DST/reports/diagnostics-report/diagnostics-report.html",
                "controller": "DiagnosticsReportCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
