/**
 * Created by 212544474 on 2/17/2016.
 */

define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.deleteLocalChartService', [])

					.factory(
							"LocalCDescGroupObjFactory",
							[
									'$q',
									'$http',
									'$stateParams',
									'$timeout',
									'$rootScope',
									'$log',
									'GlobalService',
									'DataFiltersFactory',
									'workspaceFactory',
									'JsonObjectFactory',
									'GENERAL_CONFIG',
									function($q, $http, $stateParams, $timeout,
											$rootScope, $log, GlobalService,
											DataFiltersFactory,
											workspaceFactory,
											JsonObjectFactory, GENERAL_CONFIG) {

										var localcdescgroupobjFactory = {};
										localcdescgroupobjFactory.url = {};
										localcdescgroupobjFactory.url.saveDeleteChart = GENERAL_CONFIG.base_url
												+ "/saveJsonObject?action_code=2sh6ag";
										
										localcdescgroupobjFactory.saveDeleteChart = function(
												_data, _accounts) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var jsonObj = JSON
													.stringify(_accounts);
											var jsonSettings = JSON
													.stringify(_data);

											var params = filterParams;
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _.extend({
												jsonObj : jsonObj
											}, params);
											params = _.extend({
												jsonSettings : jsonSettings
											}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "DELETE_CHART";
											
											var promise = $http(
													{
														method : "post",
														url : localcdescgroupobjFactory.url.saveDeleteChart,
														data : params
													}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										};


										return localcdescgroupobjFactory;
									} ]);

			return services;

		});