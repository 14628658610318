define([
    'angular',
    './relatedPartyPaymentController',
    './relatedPartyPaymntServce'


], function () {
    'use strict';
    return angular.module('app.relatedPartyPayment', ['app.relatedPartyPaymentController','app.relatedPartyPaymntServce'] );


});