define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.notiMesgController', [])
          .controller('notifMesgController', ['$scope', '$rootScope', 'AlertService', 'MessageFactory', '$state', '$window', 'SERVER_CONFIG', '$uibModal',
                'USER_SETTINGS','JsonObjectFactory','workspaceFactory','orderByFilter','$filter','GENERAL_CONFIG', notifMesgController])
        
        function notifMesgController($scope,$rootScope,AlertService,MessageFactory,$state,$window,SERVER_CONFIG,$uibModal,USER_SETTINGS,JsonObjectFactory,workspaceFactory,orderByFilter,$filter,GENERAL_CONFIG){
            console.log("notifContoller initiated");
           // debugger;
            $scope.comment = {};
            var vm = this;
            var URLS = {getComment: '/commentByKey'}
            
            $scope.load = function(){
                if(location.href.indexOf('COMMENT_KEY')>-1){
                        var getUrlVars=function () {
                            var vars = {};
                            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
                            function(m,key,value) {
                                vars[key] = value;
                            });
                            return vars;
                            }
                            MessageFactory.getComments(GENERAL_CONFIG.base_url + URLS.getComment, {comment_key:getUrlVars()['COMMENT_KEY']})
                            .then(function (data) {
                            $scope.comment=data.comment;
                            console.log("new not msg data",$scope.comment);
                            vm.launchNotifBuoy();
                            }); 
                        return;
                }
            }
            $scope.load();
          
          vm.launchNotifBuoy = function () {    
            var _data = {};
            // console.log("vm.comment " , vm.comment);
             var location_obj = {col_key:$scope.comment.col_key,row_key:$scope.comment.row_key}
             var filter_json = JSON.parse($scope.comment.filter_json);
             var table_state = JSON.parse($scope.comment.table_state);
             console.log("MY ENW DATA BUIO",location_obj+"filter::",filter_json,"table_state:::::",table_state);
             
             workspaceFactory.loadLink("comment",$scope.comment.activity_key,$scope.comment.screen_key,filter_json,table_state,location_obj);


            }
        }

        return controllers;
});