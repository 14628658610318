
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.ExpAnACalcAdminService',[])

    .factory('initiateApprFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var initiateApprFactory = {};
        initiateApprFactory.url = {};
        initiateApprFactory.url.apprmntProcess = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=izrqni";    
        initiateApprFactory.url.rolloverProcess = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=imiyis";
        initiateApprFactory.url.assetsRolloverProcess = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=quo991";
        initiateApprFactory.url.examsRolloverProcess = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=uveuip";
        initiateApprFactory.url.k2SyncProcess = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vjs7yo";
          
      //To run the Apportionment process
        initiateApprFactory.executeApprmnt = function(_data){        	
        		var params = {};
        		params = _.merge({},  GlobalService.globalParams , params);        		             
        		params = _.merge({},  _data[0] , params);        		 
        		
        		console.log("execute......params::",params);        
        		
        		var promise = $http({
                    method: "post",
                    url: initiateApprFactory.url.apprmntProcess,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
        		        	
        	return promise;
        } 
        
        //To run the Component Defaults Rollover process
        initiateApprFactory.executeRollover = function(_data){        	
    		var params = {};
    		params = _.merge({},  GlobalService.globalParams , params);    		            
    		params = _.merge({},  _data[0] , params);        		 
    		
    		console.log("execute......params::",params);        
    		
    		var promise = $http({
                method: "post",
                url: initiateApprFactory.url.rolloverProcess,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
    		        	
    	return promise;
    } 
    
      //To run the Assets  Rollover process
        initiateApprFactory.executeAssetsRollover = function(_data){        	
    		var params = {};
    		params = _.merge({},  GlobalService.globalParams , params);    		            
    		params = _.merge({},  _data[0] , params);        		 
    		
    		console.log("execute......params::",params);        
    		
    		var promise = $http({
                method: "post",
                url: initiateApprFactory.url.assetsRolloverProcess,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
    		        	
    	return promise;
    } 
        
      //To run the Exams  Rollover process
        initiateApprFactory.executeExamsRollover = function(_data){        	
    		var params = {};
    		params = _.merge({},  GlobalService.globalParams , params);    		            
    		params = _.merge({},  _data[0] , params);        		 
    		
    		console.log("execute......params::",params);        
    		
    		var promise = $http({
                method: "post",
                url: initiateApprFactory.url.examsRolloverProcess,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
    		        	
    	return promise;
    } 
        
      //To run the K2 Sync process
        initiateApprFactory.k2SyncProcess = function(_data){        	
    		var params = {};
    		params = _.merge({},  GlobalService.globalParams , params);    		            
    		params = _.merge({},  _data[0] , params);        		 
    		
    		console.log("execute......params::",params);        
    		
    		var promise = $http({
                method: "post",
                url: initiateApprFactory.url.k2SyncProcess,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
    		        	
    	return promise;
    } 
        return initiateApprFactory;
    }])

    return services;

});