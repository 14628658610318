define([
    'moment',
    'angular'
], function (moment) {
    'use strict';

    var services = angular.module('app.entityFillingActionsService',[])
        .factory("entityFillingActionsService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG','JsonObjectFactory', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,JsonObjectFactory) {

                var entityFillingActionsService = {};

                var URLS = {
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj',
                    GET_SCENARIO_TYPE_CODE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_SCENARIO_DESC: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_GROUP_OBJ_BY_YEAR: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_FILING_LEID: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32823",
                    GET_FILING_CYCLE_TYPES: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32824",
                    GET_FILING_SCENARIO: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32825",
                }

                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};


                entityFillingActionsService.loadScenarioTypeCode = function(_data, _details){
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : null,
                        scenario_desc : null
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_SCENARIO_TYPE_CODE,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario Type Code.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve  Scenario Type Code. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityFillingActionsService.loadScenarioDesc = function(_selectedValue){
                    console.log(_selectedValue);
                    var scenario_type_code = _selectedValue;
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : scenario_type_code,
                        scenario_desc : null
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_SCENARIO_DESC,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario Type Code.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve  Scenario Type Code. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityFillingActionsService.loadGroupObjKey = function(_selectedVal1, _selectedVal2){
                    console.log(_selectedVal1+'--------'+_selectedVal2);
                    var scenario_type_code = _selectedVal1;
                    var scenario_desc = _selectedVal2;
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : scenario_type_code,
                        scenario_desc : scenario_desc
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_GROUP_OBJ_BY_YEAR,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Group Obj Key.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Group Obj key. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityFillingActionsService.loadAllEntities = function(_selectedVal1){
                    console.log(_selectedVal1);
                    var group_obj_key = _selectedVal1;
                    
                    var  params = {
                        action_code: 'lvgt0l', 
                        group_obj_key: group_obj_key
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_FILING_LEID,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retriveclassifications.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve classifications. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityFillingActionsService.loadAllFilingCycleType = function(){
                    console.log('Load All Filing Cycle Types');
                    
                    
                    var  params = {
                        action_code: 'gexp8r'
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_FILING_CYCLE_TYPES,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Filing Cycle Types.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Filing Cycle Types. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                
                entityFillingActionsService.loadAllAppScenario = function(){
                    console.log('Load All App Scenario');
                    
                    
                    var  params = {
                        action_code: 'sra6sw'
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_FILING_SCENARIO,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Filing Scenario.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Filing Scenario. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityFillingActionsService.convertDateFormates=function(jsonObj){
                    jsonObj.TAX_YEAR_END= moment(jsonObj.TAX_YEAR_END).format('MM/DD/YYYY');
                    jsonObj.TAX_YEAR_BEGIN= moment(jsonObj.TAX_YEAR_BEGIN).format('MM/DD/YYYY');
                    return jsonObj;
                }
                


                return entityFillingActionsService;

        }]);

    return services;

});