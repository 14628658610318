define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.FormScheduleBCtrl', [])
        .controller('F1120LSCHDBController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS',F1120LSCHDBController]);
    function F1120LSCHDBController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG,USER_SETTINGS) {
        var vm = this;

        vm.modalTitle = "Schedule B Investment Income (see instructions)";
		vm.lossesIncurred = 0;
		vm.originalData = [];
        vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';

        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };

        $scope.premiums = {
                "tax_year": rowData.TAX_YEAR,
                "leid": rowData.LEID,
                "le_name": rowData.LE_NAME,
                "le_type": rowData.LE_TYPE,
                "ledger": rowData.LEDGER,
                "me": rowData.ME
            };

    	$scope.unallocatedAmount = rowData.dig || {};

		var saveParamDtls = [];
		var tempDataObj = {
			num :'',
			description : '',
			value :''
		};
		$scope.scheduleFFormView = {rows:[]};
		vm.showFormLoader = true;
        function fetchSchFrecords() {
            var params = { "action_id": 603, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY};



            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
				var dataRows = data.irsForm.formItemList;
				for(var i=1;i<dataRows.length;i++){
					saveParamDtls = [];
					tempDataObj = {};
					saveParamDtls = dataRows[i].dataRowList[0].rowDimensions.object_id.split('~');
					for(var j=0;j<dataRows[i].dataRowList[0].dataCellList.length;j++){
						if (j==0){
							tempDataObj.num = dataRows[i].dataRowList[0].dataCellList[j].name;
						} else if (j==1){
							tempDataObj.description = dataRows[i].dataRowList[0].dataCellList[j].name;
						}else{
							tempDataObj.lineAttrKey = dataRows[i].dataRowList[0].dataCellList[j].key.split('~')[1];
							tempDataObj.attrib_name = dataRows[i].dataRowList[0].dataCellList[j].key.split('~')[0];
							tempDataObj.is_editable = dataRows[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y' ? false : true;
							tempDataObj.is_display = dataRows[i].dataRowList[0].dataCellList[j].property.display == 'Y' ? true : false;
							tempDataObj.value = dataRows[i].dataRowList[0].dataCellList[j].value == null ? 0 : parseInt(dataRows[i].dataRowList[0].dataCellList[j].value);
						}
					}
					tempDataObj.line_order = saveParamDtls[8];
					tempDataObj.saveParams = saveParamDtls;
					$scope.scheduleFFormView.rows.push(tempDataObj);
				}
				vm.originalData = angular.copy($scope.scheduleFFormView);
				initFormView($scope.scheduleFFormView);
				vm.showFormLoader = false;
            });
        }

        function fetchSchBimports() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
            	"process_name": workspaceFactory.activeScreen.label,
            	"status": 'Y'
            };

			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=wwledr', params).then(function (data) {
				vm.BatchList = data.jsonObject;
				angular.forEach(vm.BatchList, function (value, key) {
                	vm.BatchList[key].TOGGLE = false;
            	});
            	vm.showRequestLoader = false;
            });
        }

        fetchSchBimports();
        fetchSchFrecords();

        function loadTransactions(index, trans_key){
        	vm.batchLoader = true;
        	var params = { "client_key": USER_SETTINGS.user.client_key,
            	"process_name": workspaceFactory.activeScreen.label,
            	"transaction_key": trans_key
            };
        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=l6gzle', params).then(function (data) {
				vm.batchLoader = false;
				vm.showBatchData = true;
				vm.BatchListData = data.jsonObject;
            });
        };

        var prev_index = null;

        vm.showUploads = function(index, trans_key){
        	if(prev_index !== null){
        		if(prev_index !== index){
        			vm.BatchList[prev_index].TOGGLE = false;
        			prev_index = index;
        			vm.showBatchData = false;
        			vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
        			loadTransactions(index, trans_key);
        		}else{
        			vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
        			vm.showBatchData = true;
        		}
        	}else{
        		prev_index = index;
        		vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
        		loadTransactions(index, trans_key);
        	}
        };

        vm.computeValues = function(){
			console.log($scope.scheduleFFormView.rows);
			//Total. Add lines 1,2,3,4,5
			$scope.scheduleFFormView.rows[5].value = Math.round(($scope.scheduleFFormView.rows[0].value || 0) + ($scope.scheduleFFormView.rows[1].value || 0) + ($scope.scheduleFFormView.rows[2].value || 0)
					+ ($scope.scheduleFFormView.rows[3].value || 0) + ($scope.scheduleFFormView.rows[4].value || 0));

			// TODO : change it to generic computation
			$scope.unallocatedAmount[0][0].value = $scope.scheduleFFormView.rows[1].value;
			$scope.unallocatedAmount[1][0].value = $scope.scheduleFFormView.rows[5].value;

			$scope.unallocatedAmount[0][2].value = $scope.unallocatedAmount[0][0].value - $scope.unallocatedAmount[0][1].value;
			$scope.unallocatedAmount[1][2].value = $scope.unallocatedAmount[1][0].value - $scope.unallocatedAmount[1][1].value;
		}

        vm.saveSchddForm = function (url, data){

            var jsonSettings = { "tax_year": rowData.TAX_YEAR,
	            "scenario": rowData.SCENARIO,
	            "jcd_key": rowData.JCD_KEY,
	            "combination_key": rowData.COMBINATION_KEY,
	            "trans_type_key": data[0].editrows[0][0].trans_type_key
	        };

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);

            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchdFForm() response.data.jsonObj ::",response.data.jsonObject);
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
					$rootScope.$emit('gridUpdate', args);
					AlertService.add("success", "Transactions saved successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                    }
				}
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
		}

        vm.saveDiagnostic = function(url, diagnosticData){
            var params =  _.extend({diag_exception :diagnosticData});
            params.tax_year =  rowData.TAX_YEAR;
            params.screen_key =  workspaceFactory.activeScreen.screen_key;
            params.scenario = rowData.SCENARIO;
            params.jcd_key = rowData.JCD_KEY;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        vm.save = function(){
            var formRowData = {};
			var tempArray = [];

			for(var i=0; i<$scope.scheduleFFormView.rows.length ;i++){
				formRowData = {
					tax_year: rowData.TAX_YEAR,
					trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
					combination_key: rowData.COMBINATION_KEY,
					group_obj_key: rowData.GROUP_OBJ_KEY,
					form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
					line_no: $scope.scheduleFFormView.rows[i].num,
					line_order: $scope.scheduleFFormView.rows[i].line_order,
					occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
					line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKey,
					line_description: $scope.scheduleFFormView.rows[i].description,
					Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name,
					Attribute_Value: $scope.scheduleFFormView.rows[i].value,
					trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
					trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
					is_dirty: ''
				};
				tempArray.push(formRowData);
			}
			vm.formData.editrows.push(tempArray);
			vm.mainData = [];
			vm.mainData.push(vm.formData);
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nkepph";
            var saveDiagURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ftmltp";
			vm.saveSchddForm(url,vm.mainData);
            var diagData = [];
            angular.forEach($scope.unallocatedAmount, function (array, key) {
                angular.forEach(array, function (obj, key) {
                    if(key === 3){
                        diagData.push(obj);
                    }
                });
            });
            vm.saveDiagnostic(saveDiagURL, JSON.stringify(diagData));
            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        }
		vm.reset = function (){
			$scope.scheduleFFormView = angular.copy(vm.originalData);
			vm.computeValues();
		}
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function initFormView(filteredFormData) {
			$scope.scheduleFFormView = filteredFormData;
			if(rowData.TAX_YEAR === 2016){
					vm.computeValues();
			}else if(rowData.TAX_YEAR === 2018){
				vm.computeValues();
			}
		}
    }
});