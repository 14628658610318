define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.maxLoanDeleteCtrl',  [])
		.controller('maxLoanDeleteCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','USER_SETTINGS','MaxLoanFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', maxLoanDeleteCtrl]);


	function maxLoanDeleteCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,USER_SETTINGS,MaxLoanFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		
		var vm = this;
		 vm.rowData = rowData ||$scope.$parent.rowData;
	        vm.colData = colData;
	        vm.gridData = gridData;
	        vm.globalParams = GlobalService.globalParams;
	        vm.userSettings = USER_SETTINGS;
	        vm.modalTitle = 'Delete Override Amount';
	        $scope.crudLoading = false;
	        
	        
		vm.deleteMaxLoanOverride = function(){
			$scope.crudLoading = true;
			  var jsonSettings = '{}';
			  var jsonObj =[];
			  var tempObj = {
					  'COMBINATION_KEY': vm.rowData.COMBINATION_KEY,
					  'TRANS_DETAILS_KEY' : (vm.rowData.TRANS_DETAILS_KEY == undefined ||vm.rowData.TRANS_DETAILS_KEY === 0)  ? 0 : vm.rowData.TRANS_DETAILS_KEY ,
					  'TRANS_HEADER_KEY' :(vm.rowData.TRANS_HEADER_KEY == undefined ||vm.rowData.TRANS_HEADER_KEY === 0)  ? 0 : vm.rowData.TRANS_HEADER_KEY ,
					  'ACCT_KEY' :vm.rowData.LOCAL_ACCT_KEY,
					  'TP_KEY' : vm.rowData.TP_KEY,
			  		  'CHART_KEY' : vm.rowData.LOCAL_CHART_KEY,
			  		  'ISSUE_KEY' : vm.rowData.ISSUE_KEY
			  };
			  jsonObj[0] = tempObj;
			  
			
			  MaxLoanFactory.delete_maxloan_override(jsonSettings, jsonObj ).then(function(result){
	        		if(result.callSuccess === "1"){
	        			$scope.crudLoading = false;
	        			AlertService.add("success", "Overriden value has been deleted sucessfully", 1000);
	        			$uibModalInstance.dismiss('cancel');
	        			 var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
                         $rootScope.$emit('gridUpdate', args);
                    }else{
                    	$scope.crudLoading = false;
                        //AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
                        AlertService.add("error", result.errorMessage, 1000);
                        $uibModalInstance.dismiss('cancel');
                        //var args = {};
                        $rootScope.$emit('gridUpdate', {});
                    }
	        	});
	        }
		
		
        
        vm.cancel = function () {
			$scope.crudLoading = false;
    		$uibModalInstance.dismiss('cancel');
        };
		
	
	
	}
}); 