define(
		[ 'angular','./Form1118Service'],
		function() {
			'use strict';
			 var controllers = angular.module('app.Form1118Ctrl', [])
			 .directive(
							'validNumberForm',
							function() {
								return {
									require : '?ngModel',
									link : function(scope, element, attrs,
											ngModelCtrl) {
										if (!ngModelCtrl) {
											return;
										}
										ngModelCtrl.$parsers
												.push(function(val) {
													if (angular
															.isUndefined(val)) {
														var val = '';
													}

													var clean = val
															.toString()
															.replace(
																	/[^-0-9\.]/g,
																	'');
													var negativeCheck = clean
															.split('-');
													var decimalCheck = clean
															.split('.');
													if (!angular
															.isUndefined(negativeCheck[1])) {
														negativeCheck[1] = negativeCheck[1]
																.slice(
																		0,
																		negativeCheck[1].length);
														clean = negativeCheck[0]
																+ '-'
																+ negativeCheck[1];
														if (negativeCheck[0].length > 0) {
															clean = negativeCheck[0];
														}

													}

													if (!angular
															.isUndefined(decimalCheck[1])) {
														decimalCheck[1] = decimalCheck[1]
																.slice(0, 2);
														clean = decimalCheck[0]
																+ '.'
																+ decimalCheck[1];
													}
													if (val !== clean) {
														ngModelCtrl
																.$setViewValue(clean);
														ngModelCtrl.$render();
													}
													return clean;
												});
										element.bind('keypress',
												function(event) {
													if (event.keyCode === 32) {
														event.preventDefault();
													}
												});
									}
								};
							})
			.controller('form1118', ['$rootScope', '$scope', '$state', '$log', 'JsonObjectFactory', 'AlertService', '$timeout', '$uibModal', '$uibModalInstance', 'rowData', 'gridData',
                'GENERAL_CONFIG', 'Form1118Factory', '$http', 'USER_SETTINGS', 'GlobalService', 'workspaceFactory', '$filter', formIIIEightCtrl
            ]);
			 function formIIIEightCtrl($rootScope, $scope, $state, $log, JsonObjectFactory, AlertService, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG,
			            Form1118Factory, $http, USER_SETTINGS, GlobalService, workspaceFactory, $filter) {
				 var vm = this,exists = null;;
		            vm.rowData = null;
		            vm.userSettings = USER_SETTINGS;
		            vm.jsonData;
		            vm.scheduleData;
					vm.sicCodeList;
					vm.selectedSICCode;
		            vm.basketData;
		            vm.lineNo;
		            vm.colNo;
		            vm.sequenceKey;
		            //Get the Grid filter Scenario
					vm.scenario = GlobalService.globalParams.scenario;
					//Get the jcd_key from left nav
					vm.jcd_key  = GlobalService.globalParams.jcd_key;
					
		            //A flag to enable and disable save button
		            vm.isSaveClicked = false;
		            //Original Column Data
		            vm.origColData;
		            //Flag to disable Basket dropdown when its a Schd-J
		            vm.isSchdJ = false;
		            //Flag to disable Basket dropdown and Have a Basket as 'GENLIM' only
		            vm.isBasketGENLIM = false;
					
		            //To get the filter params 
		            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {
		                "tax_year": GlobalService.globalParams.tax_year
					};
		            //Get the Current Year 
		            vm.curentyear = filterParams.tax_year; //GlobalService.globalParams.tax_year;
		            vm.isCreate = (gridData.length == 0) ? true : false;
		            vm.selectedSchedule;

		            vm.cancel = function() {
		                $uibModalInstance.dismiss('cancel');
		                // $rootScope.$emit('gridUpdate', args);
		            };
		            
		          //To get the Lock_Y_N flag from backend
					var lockparams = {
							"action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
						};				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;				
						//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
						$scope.$watch(function() { return vm.is_locked }, function() {
							if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
									vm.cancel();	
									AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
									return;
							}							
						});
					});

					vm.loadDropDownData = function(isChanged) {
		                var params = {
		                    "action_code": 'y7xpnk',
		                    'schedule_key': vm.selectedSchedule,
		                };
		                
		                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
		                    .then(function(data) {
		                        vm.jsonData = data.jsonObject;

		                        vm.scheduleData = _.filter(vm.jsonData, function(o) {
		                            return o.ROW_TYPE == 'SCHD';
		                        });
		                        
		                        vm.lineData = _.filter(vm.jsonData, function(o) {
		                            return o.ROW_TYPE == 'L';
		                        });
		                       
		                        vm.colData = _.filter(vm.jsonData, function(o) {
		                            return o.ROW_TYPE == 'C';
		                        });
		                       
		                        vm.origColData = vm.colData;

		                        if (!vm.isCreate && !isChanged) {
		                        	vm.isSchdJ = false;
		                        	vm.isBasketGENLIM = false;
		                            vm.rowData = gridData.rowData;
		                            vm.amount = vm.rowData.AMOUNT
		                            vm.reason = vm.rowData.REASON;

		                            vm.schedule = {
		                                CODE_KEY: vm.rowData.SCHD_KEY,
		                                CODE_DESCRIPTION: vm.rowData.SCHD_NAME
		                            };
									vm.selectedSICCode = {
		                                CODE_KEY: vm.rowData.SIC_CODE_KEY,
		                                CODE_DESCRIPTION: vm.rowData.SIC_CODE_DESC
										/*CODE_KEY: 7021,
		                                CODE_DESCRIPTION: "206-Sugar & Confectionery Products"*/
		                            };
		                            vm.lineNo = {
		                                CODE_KEY: vm.rowData.LINE_KEY,
		                                LINE_DESCRIPTION: vm.rowData.LINE_DESCRIPTION
		                            };
		                            vm.colNo = {
		                                CODE_KEY: vm.rowData.COL_KEY,
		                                CODE_DESCRIPTION: vm.rowData.COL_DESCRIPTION
		                            };
		                            vm.sequenceKey = vm.rowData.SEQ_KEY;

		                            var firstRow = _.first(vm.origColData, 1);
		                            if ((firstRow.SCHD_NAME != 'F1118_A' && firstRow.SCHD_NAME != 'F1118_BPI') && (vm.lineNo != undefined && vm.lineNo != null)) {
		                                vm.colData = _.filter(vm.origColData, function(o) {
		                                    return (vm.lineNo.CODE_KEY === o.MAP_KEY)
		                                });
		                            }
		                            if(firstRow.SCHD_NAME.startsWith("F1118_JP")){
		                            	vm.isSchdJ = true;
		                            }
		                            if(firstRow.SCHD_NAME.startsWith("F1118_HP") || vm.schedule.SCHD_NAME == 'F1118_BPIII' ){
		                            	vm.isBasketGENLIM = true;
		                            }
									if(firstRow.SCHD_NAME == "F1118_HPI"){
		                            	vm.isSchdHPI = true;
		                            }
		                        }
		                    })
		            }

					// LOAD SIC CODES
					vm.loadSICCodeDropdownData = function(){
		                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', {
		                        "action_code": 'e8jfqu',
		                    }).then(function(data) {
		                        vm.rowData = gridData.rowData;
		                        vm.sicCodeList = data.jsonObject;
								vm.sicCodeList = _.filter(data.jsonObject, function(o) {
									return (o.IS_DISPLAY == 'Y')
								});
								vm.sicCodeList = _.orderBy(vm.sicCodeList, ['CODE_DESCRIPTION']);
		                        if (!vm.isCreate) {
		                            let selectedSicCodeObj = _.find(vm.sicCodeList, ['CODE_KEY', vm.rowData.SIC_CODE_KEY])
		                            if (selectedSicCodeObj)
		                                vm.selectedSICCode = selectedSicCodeObj;
		                        }
		                    });
		            }
		            
		            //Load the Basket dropdown data
		            vm.loadBasketDropdownData = function(){
		                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', {
		                        "action_code": '1x4adz',
		                    }).then(function(data) {
		                        vm.rowData = gridData.rowData;
		                        vm.basketData = data.jsonObject;
		                        
		                        if (!vm.isCreate) {
		                            exists = _.find(vm.basketData, ['BASKET_DESC', vm.rowData.BASKET_DESC])
		                            if (exists)
		                                vm.basket = exists;
		                        }
		                    });
		            }
		            
		            
		            //load the columns related to the Line_No only
		            vm.loadColumnDropDownData = function(isChanged) {
		                if ((vm.lineNo.SCHD_NAME != 'F1118_A' && vm.lineNo.SCHD_NAME != 'F1118_BPI') && (vm.lineNo != undefined && vm.lineNo != null)) {
		                    vm.colData = _.filter(vm.origColData, function(o) {
		                        return (vm.lineNo.CODE_KEY === o.MAP_KEY)
		                    });
		                }
		            }

		            //When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
		            if (gridData != null) {
		                var tempGridData = _.clone(gridData),
		                    groups = Object
		                    .create(null),
		                    result, finalKeyCheck = 0;

		                vm.gridDataMultipleSelect = _.clone(tempGridData);

		                if (vm.gridDataMultipleSelect != undefined) {		                   
		                    if (vm.gridDataMultipleSelect.rowData != undefined) {
		                        var selectedRow = _.clone(vm.gridDataMultipleSelect.rowData);		                      
		                        //Set the update params						
		                        vm.selectedTaxYear = '' + vm.curentyear;
		                        vm.selectedSchedule = selectedRow.SCHD_KEY;
		                    } else {
		                        //Set the insert params						
		                        vm.selectedTaxYear = '' + vm.curentyear;
		                        vm.selectedSchedule = null;
		                    }

		                    if (selectedRow != undefined) {
		                        if (selectedRow.AMOUNT == null && selectedRow.REASON == null) {
		                            //Set the insert params
		                            vm.operationType = 'I';
		                        } else {
		                            //Set the update params
		                            vm.operationType = 'U';
		                            vm.sequenceKey = selectedRow.SEQ_KEY;
		                        }
		                    } else {
		                        //Set the insert params
		                        vm.operationType = 'I';
		                    }
		                }
						vm.loadSICCodeDropdownData();
		                vm.loadDropDownData(false);
		                vm.loadBasketDropdownData();
		            }

		            //On change of the Schedule, Line or Column --  Get their dependent dropdowns
		            vm.getScheduleLineColAmountAndReason = function() {	
		            	
		                if (vm.schedule != undefined && vm.schedule != null) {
							vm.isSchdHPI = false;
		                	if(vm.schedule.SCHD_NAME == 'F1118_JPI' 
		                			|| vm.schedule.SCHD_NAME == 'F1118_JPII' 
		                				|| vm.schedule.SCHD_NAME == 'F1118_JPIII' 
		                					|| vm.schedule.SCHD_NAME == 'F1118_JPIV'){		                			
		                		vm.isSchdJ = true;	
		                		vm.isBasketGENLIM = false;		                			                			
		                		vm.basket = null;   		                		
		                	}else if(vm.schedule.SCHD_NAME == 'F1118_HPI' 
	                				|| vm.schedule.SCHD_NAME == 'F1118_HPII' 
	                					|| vm.schedule.SCHD_NAME == 'F1118_BPIII'
											|| vm.schedule.SCHD_NAME == 'F1118_HPIII' ){		                			
		                		vm.isSchdJ = false;	
		                		vm.isBasketGENLIM = true;
		                		
		                		/*vm.genlimBasketData = _.filter(vm.basketData, function(p){
			                		  return _.includes(['GENLIM'], p.CODE_SHORT_DESC);  
								});*/	
		                        vm.basket = _.find(vm.basketData, ['BASKET_SHORT_DESC', 'GENLIM'])		                        
		                		if (vm.schedule.SCHD_NAME == 'F1118_HPI') {
									vm.isSchdHPI = true;
									vm.loadSICCodeDropdownData();
								}
		                	}else{
		                		vm.isSchdJ = false;	
		                		vm.isBasketGENLIM = false;
		                		vm.loadBasketDropdownData();
		                	}
		                	vm.loadDropDownData(true);
		                    vm.selectedSchedule = vm.schedule.CODE_KEY;
		                   
		                }
		            }

		            //On change of Schedule Selection
		            vm.scheduleChange = function() {
		                vm.basket = null
		                vm.lineNo = null;
		                vm.colNo = null;
		                vm.amount = null;
		                vm.reason = null;
						vm.selectedSICCode = null;
		                vm.selectedSchedule = vm.schedule.CODE_KEY;
		                vm.getScheduleLineColAmountAndReason();
		            }

		            //On change of Line No Selection
		            vm.lineNoChange = function() {
		                vm.colNo = null;
		                vm.amount = null;
		                vm.reason = null;
		                vm.loadColumnDropDownData();
		            }

		            //Reset the fields in Modal
		            vm.reset = function(type) {
		                if (type == "add") {
		                    vm.amount = "";
		                    vm.reason = "";
		                    vm.schedule = null;
		                    vm.lineNo = null;
		                    vm.colNo = null;
		                    vm.basket = null;
		                    vm.sequenceKey = null;
							vm.selectedSICCode = null;
		                } else {
							vm.loadSICCodeDropdownData();
		                    vm.loadDropDownData(false);
		                    vm.loadBasketDropdownData();
		                }
		            }

		            //Delete the fields from Modal
		            vm.deleteAmt = function() {
		            	
		                //Disable the Save button till the processing completes
		                vm.isSaveClicked = true;

		                var details = [],
		                    returnObj = {},
		                    otherInfo = {};

		                otherInfo['sso_id'] = vm.userSettings.user.sso_id;
		                otherInfo['screen_key'] = GlobalService.globalParams.screen_key;
		                otherInfo['tax_year'] = GlobalService.globalParams.tax_year;
		                otherInfo['scenario'] = GlobalService.globalParams.scenario;
						otherInfo['jcd_key'] = GlobalService.globalParams.jcd_key;
						otherInfo['filing_group_key'] = filterParams.filing_group_key;
						otherInfo['filer_entity_key'] = filterParams.filer_entity_key;

		                returnObj["type"] = 'D';
		                returnObj["SCHD_KEY"] = vm.schedule.CODE_KEY;
		                returnObj['BASKET_KEY'] = vm.basket ? vm.basket.BASKET_KEY : null;
		                returnObj["LINE_KEY"] = vm.lineNo ? vm.lineNo.CODE_KEY : null;
		                returnObj['COL_KEY'] = vm.colNo ? vm.colNo.CODE_KEY : null;
		                returnObj['SEQ_KEY'] = vm.sequenceKey;
		                returnObj["amount"] = vm.amount;
		                returnObj['reason'] = vm.reason;
		                returnObj['FORM_NO'] = 'F1118';
		                returnObj['object_id'] = vm.rowData.object_id;

		                details.push(returnObj);
		                var message = "Overrides has been deleted successfully ";
		                //Call the save service
		                sendDetails(returnObj, details, message, otherInfo);
		            }

		            //Perform the Save operations for data entered in the fields of Modal
		            vm.save = function() {
		            	
		                //Disable the Save button till the processing completes
		                vm.isSaveClicked = true;

		                var details = [],
		                    returnObj = {},
		                    otherInfo = {};
		                var isError = false,
		                    errorInfo = "";

		                otherInfo['sso_id'] = vm.userSettings.user.sso_id;
		                otherInfo['screen_key'] = GlobalService.globalParams.screen_key;
		                otherInfo['tax_year'] = GlobalService.globalParams.tax_year;
		                otherInfo['scenario'] = GlobalService.globalParams.scenario;
						otherInfo['jcd_key'] = GlobalService.globalParams.jcd_key;
						otherInfo['filing_group_key'] = filterParams.filing_group_key;
						otherInfo['filer_entity_key'] = filterParams.filer_entity_key;

		                returnObj["type"] = vm.operationType;
		                returnObj["SCHD_KEY"] = vm.schedule.CODE_KEY;
						returnObj['SIC_CODE_KEY'] = vm.selectedSICCode ? vm.selectedSICCode.CODE_KEY : null;
		                returnObj['BASKET_KEY'] = vm.basket ? vm.basket.BASKET_KEY : null;
		                returnObj["LINE_KEY"] = vm.lineNo ? vm.lineNo.CODE_KEY : null;
		                returnObj['COL_KEY'] = vm.colNo ? vm.colNo.CODE_KEY : null;
		                returnObj['SEQ_KEY'] = vm.sequenceKey;
		                returnObj["amount"] = vm.amount;
		                returnObj['reason'] = vm.reason;
		                returnObj['FORM_NO'] = 'F1118';

		                if (!vm.isCreate) {
		                    returnObj['object_id'] = vm.rowData.object_id;
		                }

						if (vm.isSchdHPI && returnObj['SIC_CODE_KEY'] === null) {
							isError = true;
		                    errorInfo = "Please select the SIC CODE ";
		                    AlertService.add("error", errorInfo, 4000);
		                    //Disable the Save button till the processing completes
		                    vm.isSaveClicked = false;
		                    return;
						}
		               
		                if (!(vm.isSchdJ) && returnObj['BASKET_KEY'] === null) {
		                    isError = true;
		                    errorInfo = "Please select the Basket ";
		                    AlertService.add("error", errorInfo, 4000);
		                    //Disable the Save button till the processing completes
		                    vm.isSaveClicked = false;
		                    return;
		                }
		                
		                if (returnObj['LINE_KEY'] === null) {
		                    isError = true;
		                    errorInfo = "Please select the Line ";
		                    AlertService.add("error", errorInfo, 4000);
		                    //Disable the Save button till the processing completes
		                    vm.isSaveClicked = false;
		                    return;
		                }
		                
		                if (returnObj['COL_KEY'] === null) {
		                    isError = true;
		                    errorInfo = "Please select the Column ";
		                    AlertService.add("error", errorInfo, 4000);
		                    //Disable the Save button till the processing completes
		                    vm.isSaveClicked = false;
		                    return;
		                }
		                
		                if (!returnObj['amount'] || returnObj['amount'] && returnObj['amount'].length === 0) {
		                    isError = true;
		                    errorInfo = "Please enter the Amount ";
		                    AlertService.add("error", errorInfo, 4000);
		                    //Disable the Save button till the processing completes
		                    vm.isSaveClicked = false;
		                    return;
		                }
		                
		                details.push(returnObj);
		                var message = "Overrides has been successfully saved/updated";

		                if (isError == false) {
		                  sendDetails(returnObj, details, message, otherInfo);  
		                }
		            }

		            //Send the details to the Service Layer
		            function sendDetails(returnObj, details, message, otherInfo) {
		                Form1118Factory.save(returnObj, details, otherInfo).then(function(result) {
		                    console.log(" result", result);
		                    if (result.errorMessage && result.errorMessage !== 'null') {
		                        AlertService.add("error", result.errorMessage, 4000);
		                        vm.isSaveClicked = false;
		                    } else {
		                        AlertService.add("success", message, 4000);
		                        var args = {};
		                        if (returnObj.object_id) {
		                            args = {
		                                object_id: returnObj.object_id,
		                                gridFilter: {
		                                    object_id: returnObj.object_id
		                                }
		                            };
		                        }
		                        $uibModalInstance.dismiss('cancel');
		                        $rootScope.$emit('gridUpdate', args);
		                        vm.isSaveClicked = false;
		                    };
		                });

		            } 
		            
			 }
			return controllers;

		});
