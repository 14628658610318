define(["angular"], function () {
    "use strict";

    var controllers = angular
        .module("app.footNotesCtrl", ["ngAnimate"])
        .controller("footNotesCtrl", footNotesCtrl);

    footNotesCtrl.$inject = [
        "FormReviewFactory",
        "$timeout",
        "footNotesFactory",
        "AlertService",
        "GlobalService",
        "$uibModal",
        "$state",
        "$scope",
    ];

    function footNotesCtrl(
        FormReviewFactory,
        $timeout,
        footNotesFactory,
        AlertService,
        GlobalService,
        $uibModal,
        $state,
        $scope
    ) {
        var footNoteParentEle = angular.element('#Foot-Notes').parent();
        var vm = this;
        vm.events = {};
        vm.editor = {};
        vm.activeNote = {};
        vm.selectedFilterType = "P";
        vm.hoList = [];
        vm.scheduleList = [];
        vm.partners = [];

        ///// scope functions
        vm.noteSelected = noteSelected;
        vm.switchFilters = switchFilters;
        vm.changeHO = changeHO;
        vm.changeForm = changeForm;
        vm.onFilterClick = onFilterClick;
        vm.saveContent = saveContent;
        vm.exportPdf = exportPdf;
        vm.addNote = addNote;
        vm.cloneNote = cloneNote;
        vm.deleteNote = deleteNote;
        vm.showMessage = showMessage;

        ///// editor events
        vm.events.rendered = function (editor) {
            console.log("rendered");
            vm.editor = editor;
            console.log('vm.edito')

            let firstNote = _.minBy(vm.footNotes, "order_id");//_.find(vm.footNotes, ["order_id", 1]);
            firstNote && activateNote(firstNote);
        };

        activate();

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (!vm.skipTransitionCheck && vm.activeNote.note_name && activeNoteChanged()) {
                event.preventDefault();
                showConfirmDialog().then((proceedWithoutSave) => {
                    if (proceedWithoutSave) {
                        vm.skipTransitionCheck = true;
                        $state.go(toState.name).finally(() => {
                            vm.skipTransitionCheck = false;
                        });
                    } else {
                        $timeout(() => {
                            footNoteParentEle.triggerHandler('click');
                        });
                    }
                });
            }
        });

        //////// private methods
        function activate() {
            loadDropdowns();
        }

        function showConfirmDialog() {
            return $uibModal.open({
                templateUrl: 'app/templates/modals/confirm-action.html',
                windowClass: 'custom',
                controller: ['$scope', '$stateParams', '$uibModalInstance',
                    function ($scope, $stateParams, $uibModalInstance) {
                        $scope.header = 'Unsaved Footnote';
                        $scope.message = 'You have unsaved changes on the footnote, are you sure you want to discard the changes and continue?';
                        new Audio('assets/sound/voice_alert.mp3').play().then();
                        $scope.cancel = function () {
                            $uibModalInstance.close(false);
                        }
                        $scope.confirm = function () {
                            $uibModalInstance.close(true);
                        }
                    }
                ]
            }).result;
        }

        function changeHO() {
            var pieces = vm.selectedHO.value.split('/');
            var rptPrd = vm.selectedHO.name.substring(vm.selectedHO.name.lastIndexOf('/') + 1, vm.selectedHO.name.lastIndexOf(')'));
            var leid = pieces[0];
            var cdr_no = pieces[1];
            vm.selectedPartner = undefined;
            footNotesFactory.getPartners(
                GlobalService.globalParams.tax_year,
                GlobalService.globalParams.scenario,
                leid,
                cdr_no,
                rptPrd
            ).then((response) => {
                if (response.data.partnersList) {
                    vm.partners = response.data.partnersList;
                    if (vm.partners.length === 0 || (vm.selectedForm && !vm.selectedForm.form.toLowerCase().includes('sch'))) {
                        vm.hidePartners = true;
                        vm.selectedPartner = {leid: null};
                    } else {
                        vm.hidePartners = false;
                        vm.selectedPartner = vm.partners[0];
                    }
                }
            }).finally(() => {

            })
        }

        function changeForm() {
            vm.scheduleList = vm.selectedForm.schedules;
            vm.scheduleList.length > 0 && (vm.selectedSchedule = vm.scheduleList[0]);
            vm.hideSchedules = vm.scheduleList[0] === null;

            if ((vm.selectedForm && !vm.selectedForm.form.toLowerCase().includes('sch'))) {
                vm.hidePartners = true;
                vm.selectedPartner = {leid: null};
            } else if (vm.partners.length > 0) {
                vm.hidePartners = false;
                vm.selectedPartner = vm.partners[0];
            }
        }

        function activeNoteChanged() {
            return !angular.equals(vm.activeNote.note_name, vm.activeNoteCopy.note_name) || !angular.equals(vm.activeNote.html_data, vm.editor.getData());
        }

        function onFilterClick() {
            if (vm.activeNote && vm.activeNote.note_name && activeNoteChanged()) {
                showConfirmDialog().then((proceedWithoutSave) => {
                    if (proceedWithoutSave) {
                        loadFootNotes();
                    }
                });
            } else if (vm.selectedHO && vm.selectedForm && vm.selectedPartner !== undefined) {
                loadFootNotes();
            }
        }

        function loadFootNotes() {
            vm.footNotesLoading = true;
            vm.footNotesLoaded = false;
            vm.activeNote = null;
            footNotesFactory
                .getFootNotes(
                    GlobalService.globalParams.tax_year,
                    GlobalService.globalParams.scenario,
                    250,
                    vm.selectedHO.value.split('/')[0],
                    vm.selectedHO.value.split('/')[1],
                    vm.selectedHO.name.substring(vm.selectedHO.name.lastIndexOf('/') + 1, vm.selectedHO.name.lastIndexOf(')')),
                    vm.selectedPartner.leid,
                    vm.selectedForm.form,
                    vm.selectedSchedule
                )
                .then((footNotes) => {
                    vm.footNotesLoading = false;
                    vm.footNotesLoaded = true;
                    if (!footNotes) {
                        unexpectedError();
                        return;
                    }

                    vm.footNotes = footNotes;
                })
                .catch((err) => {
                    unexpectedError();
                });
        }

        function noteSelected(note) {
            if (note.note_id === null) {
                return;
            }
            note.contentLoading = true;
            footNotesFactory
                .getFootNote(note.note_id)
                .then((fullNote) =>
                    fullNote && fullNote.html_data
                    ? ((note.html_data = fullNote.html_data),
                        (note.contentLoading = false),
                        activateNote(note))
                    : unexpectedError()
                )
                .catch((e) => unexpectedError());
        }

        function loadDropdowns() {
            vm.hoLoading = true;
            FormReviewFactory.getViewForDropdown("P", "D", "HO")
                             .then((response) => {
                                 var list = response.data.viewList;
                                 if (list && list.length > 0) {
                                     vm.hoList = list;
                                 } else {
                                     // test only
                                     vm.hoList.forEach((homeOffice) => {
                                         homeOffice.me_code = homeOffice.value.split('/')[0];
                                     })
                                 }
                             })
                             .finally(() => {
                                 vm.hoLoading = false;
                             });

            footNotesFactory.getForms(GlobalService.globalParams.tax_year, vm.selectedFilterType === 'P' ? 'PSHIP' : 'CORP', 'D')
                            .then((response) => {
                                if (response.data.callSuccess === '1') {
                                    vm.formScheduleList = response.data.forms;
                                    vm.selectedForm = vm.formScheduleList.length > 0 ? vm.formScheduleList[0] : null;
                                    vm.changeForm();
                                }

                            })
        }

        function switchFilters(filterType, $event) {
            vm.selectedFilterType = filterType;
        }

        function activateNote(note) {
            vm.footNotes.forEach((n) => (n.active = false));
            vm.editor.setData(note.html_data);
            note.active = true;
            vm.activeNote = note;
            vm.activeNoteCopy = angular.copy(vm.activeNote);
        }

        function saveContent() {
            if (vm.activeNote.note_name === "Untitled") {
                AlertService.add("warning", "Please name your footnote by double clicking on the title!", 4000);
                return;
            }
            vm.saving = true;
            footNotesFactory
                .saveFootNote(
                    vm.activeNote.note_id,
                    vm.activeNote.note_name,
                    vm.activeNote.tax_year || GlobalService.globalParams.tax_year,
                    vm.activeNote.scenario || GlobalService.globalParams.scenario,
                    vm.selectedHO.me_code,//not used anymore
                    vm.activeNote.leid || vm.selectedHO.value.split('/')[0],
                    vm.activeNote.cdr_no || vm.selectedHO.value.split('/')[1],
                    vm.activeNote.reporting_period || vm.selectedHO.name.substring(vm.selectedHO.name.lastIndexOf('/') + 1, vm.selectedHO.name.lastIndexOf(')')),
                    vm.activeNote.form_sub || vm.selectedPartner.leid,
                    vm.activeNote.form_name || vm.selectedForm.form,
                    vm.activeNote.schedule || vm.selectedSchedule,
                    vm.editor.getData(),
                    "N"
                )
                .then((response) => {
                    vm.saving = false;
                    console.log(response);
                    if (response.data.callSuccess === "1") {
                        AlertService.add("success", "Saved Successfully!", 4000);
                        // activateNote(response.data.footNote);
                        vm.activeNote.note_id = response.data.footNote.note_id;
                        vm.activeNote.html_data = response.data.footNote.html_data;
                        vm.activeNoteCopy = angular.copy(vm.activeNote);
                    } else {
                        AlertService.add("error", "Save Failed!", 4000);
                    }
                });
        }

        function addNote() {
            let newOrderId = _.chain(vm.footNotes).map("order_id").max().value() + 1;
            let newNote = {
                note_id: null,
                note_name: "Untitled",
                // combination_key: "2019149",
                // leid: "AH0413",
                // form_sub: "AH0001",
                // form_name: "1065",
                html_data: "",
                order_id: newOrderId,
                input: true,
            };
            vm.footNotes.push(newNote);

            activateNote(newNote);
        }

        function cloneNote() {
            if (vm.deletingNote) return;
            let modal = $uibModal.open({
                templateUrl:
                    "app/components/dashboardExecutive/footNotes/copy-note.html",
                controller: [
                    "$uibModalInstance",
                    "lists",
                    "defaultSelections",
                    "activeNote",
                    "footNotesFactory",
                    "GlobalService",
                    "AlertService",
                    "$q",
                    copyCtrl,
                ],
                bindToController: true,
                controllerAs: "ctrl",
                size: "sm",
                resolve: {
                    lists: function () {
                        return {
                            hoList: vm.hoList,
                            formList: vm.formScheduleList,
                            scheduleList: vm.scheduleList,
                            partners: vm.partners,
                        };
                    },
                    defaultSelections: function () {
                        return {
                            selectedFilterType: vm.selectedFilterType,
                            selectedHO: vm.selectedHO,
                            selectedForm: vm.selectedForm,
                            selectedSchedule: vm.selectedSchedule,
                            selectedPartner: vm.selectedPartner,
                            hideSchedules: vm.hideSchedules,
                            hidePartners: vm.hidePartners
                        };
                    },
                    activeNote: function () {
                        return vm.activeNote;
                    }
                },
            });
        }

        function exportPdf() {
            if (vm.deletingNote) return;
            let html = vm.editor.getData();
            footNotesFactory.exportPdf(html);
        }

        function deleteNote() {
            if (vm.activeNote && !vm.deletingNote) {
                vm.deletingNote = true;
                footNotesFactory.deleteNote(vm.activeNote.note_id).then((response) => {
                    if (response.data.callSuccess === "1") {
                        // vm.activeNote = null;

                        footNotesFactory
                            .getFootNotes(
                                GlobalService.globalParams.tax_year,
                                GlobalService.globalParams.scenario,
                                250,
                                vm.selectedHO.value.split('/')[0],
                                vm.selectedHO.value.split('/')[1],
                                vm.selectedHO.name.substring(vm.selectedHO.name.lastIndexOf('/') + 1, vm.selectedHO.name.lastIndexOf(')')),
                                vm.selectedPartner.leid,
                                vm.selectedForm.form,
                                vm.selectedSchedule
                            )
                            .then((footNotes) => {
                                AlertService.add("success", "Footnote deleted!");

                                if (!footNotes) {
                                    unexpectedError();
                                    return;
                                }

                                vm.footNotes = footNotes;
                                let firstNote = _.minBy(vm.footNotes, "order_id");

                                firstNote && activateNote(firstNote);
                            })
                            .catch((err) => {
                                unexpectedError();
                            });
                    } else {
                        unexpectedError();
                    }
                }).catch((error) => {
                    unexpectedError();
                }).finally(() => {
                    vm.deletingNote = false;
                });
            }
        }

        function unexpectedError() {
            AlertService.add(
                "error",
                "Something went wrong! Please try again later."
            );
        }

        function showMessage() {
            return vm.footNotesLoaded && (vm.footNotes.length === 0 || vm.activeNote === null || vm.activeNote === undefined);
        }
    }

    function copyCtrl(
        $uibModalInstance,
        lists,
        defaultSelections,
        activeNote,
        footNotesFactory,
        GlobalService,
        AlertService,
        $q
    ) {
        let vm = this;
        vm.hoList = lists.hoList;
        vm.formList = lists.formList;
        vm.scheduleList = lists.scheduleList;
        vm.partners = angular.copy(lists.partners);
        var indexOfSelectedPartner = vm.partners.findIndex(partner => partner.leid !== null && partner.leid === defaultSelections.selectedPartner.leid);
        indexOfSelectedPartner > -1 && vm.partners.splice(indexOfSelectedPartner, 1);
        vm.mulSelectCustomTexts = {
            buttonDefaultText: 'Select partners',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };
        vm.partnersDropdownSettings = {
            smartButtonMaxItems: 1,
            enableSearch: true,
            buttonClasses: 'mul-select-btn',
            scrollable: true,
            scrollableHeight: 250,
            displayProp: 'le_name',
            idProperty: 'leid'
        };
        vm.state = {
            // icon:'fa-check-circle',
            // text:'Copied Successfully'
        };

        vm.selectedFilterType = angular.copy(defaultSelections.selectedFilterType);
        vm.selectedHO = angular.copy(defaultSelections.selectedHO);
        vm.selectedForm = angular.copy(defaultSelections.selectedForm.form)
        vm.selectedSchedule = angular.copy(defaultSelections.selectedSchedule);
        vm.selectedPartners = defaultSelections.selectedPartner.leid === null ? [defaultSelections.selectedPartner] : [];
        vm.hideSchedules = defaultSelections.hideSchedules;
        vm.hidePartners = defaultSelections.hidePartners;

        vm.changeHO = function () {
            var pieces = vm.selectedHO.value.split('/');
            var rptPrd = vm.selectedHO.name.substring(vm.selectedHO.name.lastIndexOf('/') + 1, vm.selectedHO.name.lastIndexOf(')'));
            var leid = pieces[0];
            var cdr_no = pieces[1];
            vm.selectedPartners = undefined;
            vm.partnersLoading = true;
            footNotesFactory.getPartners(
                GlobalService.globalParams.tax_year,
                GlobalService.globalParams.scenario,
                leid,
                cdr_no,
                rptPrd
            ).then((response) => {
                if (response.data.partnersList) {
                    vm.partners = response.data.partnersList;
                    if (vm.partners.length === 0 || (vm.selectedForm && !vm.selectedForm.toLowerCase().includes('sch'))) {
                        vm.hidePartners = true;
                        vm.selectedPartners = [{leid: null}];
                    } else {
                        vm.hidePartners = false;
                        vm.selectedPartners = [vm.partners[0]];
                    }
                }
            }).finally(() => {
                vm.partnersLoading = false;
            })
        }

        vm.changeForm = function () {
            vm.scheduleList = vm.selectedForm.schedules;
            vm.scheduleList && vm.scheduleList.length > 0 && (vm.selectedSchedule = vm.scheduleList[0]);
            vm.hideSchedules = !vm.scheduleList || vm.scheduleList[0] === null;

            if ((vm.selectedForm && !vm.selectedForm.toLowerCase().includes('sch'))) {
                vm.hidePartners = true;
                vm.selectedPartners = [{leid: null}];
            } else if (vm.partners.length > 0) {
                vm.hidePartners = false;
                vm.selectedPartners = [vm.partners[0]];
            }
        }

        vm.close = function () {
            $uibModalInstance.close();
        };

        vm.disableCopy = function () {
            if (!vm.selectedHO || !vm.selectedForm || vm.selectedPartners === undefined || vm.selectedPartners.length === 0 || vm.selectedFilterType === 'C') return true;
            else if (
                vm.selectedPartners[0].leid === null &&
                vm.selectedFilterType === defaultSelections.selectedFilterType &&
                vm.selectedHO.value === defaultSelections.selectedHO.value &&
                vm.selectedForm === defaultSelections.selectedForm.form &&
                vm.selectedSchedule === defaultSelections.selectedSchedule
                // (vm.selectedPartners.findIndex(selected => selected.leid === defaultSelections.selectedPartner.leid) > -1)
            ) {
                return true;
            }

            return false;
        };

        vm.copy = function () {
            if (vm.disableCopy()) {
                return;
            }
            if (activeNote.note_name === "Untitled") {
                AlertService.add("warning", "Please name your footnote by double clicking on the title!", 4000);
                return;
            }
            vm.state = {
                icon: "fa-spinner fa-spin",
                text: "Copying...",
            };
            vm.hideCopy = true;

            var promises = [];

            vm.selectedPartners.forEach((selectedPartner) => {
                var promise = footNotesFactory
                    .saveFootNote(
                        null,
                        activeNote.note_name,
                        GlobalService.globalParams.tax_year,
                        GlobalService.globalParams.scenario,
                        vm.selectedHO.me_code,
                        vm.selectedHO.value.split('/')[0],
                        vm.selectedHO.value.split('/')[1],
                        vm.selectedHO.name.substring(vm.selectedHO.name.lastIndexOf('/') + 1, vm.selectedHO.name.lastIndexOf(')')),
                        selectedPartner.leid,
                        vm.selectedForm,
                        vm.selectedSchedule,
                        activeNote.html_data,
                        "N"
                    ).then((response) => {
                        if (response.data.callSuccess !== '1') {
                            AlertService.add("error", "Failed to copy to " + (selectedPartner.le_name ? selectedPartner.le_name : vm.selectedHO.name) + "!");
                        }
                        return response;
                    });
                promises.push(promise);
            });

            $q.all(promises).then((responses) => {
                var copyFailed = responses.findIndex((response) => response.data.callSuccess !== '1') > -1;
                if (copyFailed) {
                    vm.state = {
                        icon: "fa-times-circle-o",
                        text: "Copy failed!",
                    };
                } else {
                    vm.state = {
                        icon: "fa-check-circle",
                        text: "Copied Successfully!",
                    };
                }
            })

        };
    }

    return controllers;
});
