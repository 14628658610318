define(
    ['angular'

    ],
    function () {
        'use strict';

        var controllers = angular.module('app.ReclassTrigger163jController', [])
            .controller('ReclassTrigger163jController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'ReclassTriggerFactory', 'rowData', 'gridData', ReclassTrigger163jController])


        function ReclassTrigger163jController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, ReclassTriggerFactory, rowData, gridData) {
            var vm = this;
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var data = (workspaceFactory.activeScreen.data) ? workspaceFactory.activeScreen.data.data : data;
            vm.currentyear = filterParams.tax_year;
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };



            vm.confirm_save = function () {
                $rootScope.$broadcast("showLoader", true);
                let dataToSend = [];
                var returnClobSettingsObj = {};
                returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                returnClobSettingsObj['tax_year'] = vm.currentyear;
                returnClobSettingsObj['scenario'] = filterParams.scenario;
                returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
                data.forEach(element => {
                    if (element.INCLUDE_163J_YN == 1 && element.LINE_32 != 0 && (element.HO_LE_TAX_TYPE == 'PTSP' || element.HO_LE_TAX_TYPE == 'NCPTSP' || element.HO_LE_TAX_TYPE == 'NCPTSPNF' || element.HO_LE_TAX_TYPE == 'DPS')) {
                        dataToSend.push({
                            tax_year: element.TAX_YEAR,
                            ho_combination_key: element.COMBINATION_KEY,
                            ho_leid: element.HO_LEID,
                            ho_cdr_no: element.HO_CDR_NO,
                            ho_rp: element.HO_REPORTING_PERIOD,
                            ho_functional_currency: element.HO_FUNCTIONAL_CURRENCY,
                            line_32_amt: element.LINE_32,
                            le_tax_type: element.HO_LE_TAX_TYPE,
                            source_system_flag: element.SOURCE_SYSTEM_FLAG
                        });
                    }

                });
                if (dataToSend.length) {
                    $uibModalInstance.dismiss('cancel');
                    ReclassTriggerFactory.saveDetails(returnClobSettingsObj, dataToSend).then((result) => {
                        $rootScope.$broadcast("showLoader", true);
                        if (parseInt(result.data.callSuccess) == 1) {
                            AlertService.add("success", "Success!", 4000);
                            var args = {};
                            $rootScope.$emit('gridUpdate', args);
                        }
                        else {
                            AlertService.add("error", result.data.errorMessage, 4000);
                        }
                        $rootScope.$broadcast("showLoader", false);
                    })
                }
                else {
                    AlertService.add("error", "No data eligible to reclass!", 4000);
                    $rootScope.$broadcast("showLoader", false);
                    $uibModalInstance.dismiss('cancel');
                    var args = {};
                    $rootScope.$emit('gridUpdate', args);
                }



            };
        }
        return controllers;
    });