define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.CopyLegacyFilesService', [])
                            .factory("CopyLegacyFilesFactory", ['$http','GENERAL_CONFIG', 'USER_SETTINGS', function ($http, GENERAL_CONFIG, USER_SETTINGS) {

                                const copyLegacyFilesFactory = {};

                                copyLegacyFilesFactory.getTaxYears = function () {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32172&sso_id=${USER_SETTINGS.user.sso_id}`);
                                }

                                copyLegacyFilesFactory.moveLegacyFilestoS3 = function (tax_year) {
                                    return $http.post(`${GENERAL_CONFIG.admin_api_url}/moveLegacyFilestoS3?taxYear=${tax_year}`);
                                }

                                return copyLegacyFilesFactory;
                            }]);

    return services;
});
