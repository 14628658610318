define([
    'angular'

], function () {
    'use strict';


    var filters = angular.module('app.dashboardFilters', [])
        .filter('convertAmount', function () {
            return function (number, prefix) {
                if (typeof number === 'undefined' || number === null) {
                    return "";
                }
                else if (typeof number !== 'number') {
                    return "Not a number";
                }

                return Math.abs(Number(number)) >= 1.0e+12
                    ? prefix + ((Number(number) / 1.0e+12).toFixed(2) + "T")
                    
                    : Math.abs(Number(number)) >= 1.0e+9

                        ? prefix + ((Number(number) / 1.0e+9).toFixed(2) + "B")
                        // Six Zeroes for Millions
                        : Math.abs(Number(number)) >= 1.0e+6

                            ? prefix + (Number(number) / 1.0e+6).toFixed(2) + "M"
                            // Three Zeroes for Thousands
                            : Math.abs(Number(number)) >= 1.0e+3

                                ? prefix + (Number(number) / 1.0e+3).toFixed(2) + "K"

                                : prefix + Number(number).toFixed(2);
            };
        });
    return filters;

});
