define([
    'angular',
    './sec332ReportController'
], function () {
    'use strict';
    var $dstSection332ReportState = null;
    var module = angular.module('app.dstSection332Report', ['app.Section332ReportController'])
        .provider('$dstSection332ReportState', ['$stateProvider', function ($stateProvider) {
            this.$get = function () {
                return {
                    addState: function (stateName, url, controllerAs, templatePrefix, api, gridType, params, views) {
                        $entityMgmtAppState.state('section-332-report.' + stateName, {
                            url: '/' + url,
                            type: gridType,
                            "api": api,
                            templateUrl: templatePrefix ? templatePrefix : null,
                            controller: controllerAs ? controllerAs : null,
                            params: params,
                            resolve: {},
                            views: views
                        });
                    }
                };
            }
        }])
        .config(['$stateProvider', function ($stateProvider) {
            $dstSection332ReportState = $stateProvider;
            $stateProvider.state('section-332-report', {
                url: '/section-332-report',
                "templateUrl": "app/components/DST/reports/section-332-report/sec-332-report.html",
                "controller": "Section332ReportCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
