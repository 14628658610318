define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.sltSyncErrorService',[])

        .factory("sltSyncErrorFactory",['GENERAL_CONFIG', 'AlertService', '$http', '$rootScope',
                function(GENERAL_CONFIG, AlertService, $http, $rootScope) {
          var sltSyncErrorFactory = {};
          
            var URLS = {
                getSyncErrorResponse: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32710"
            }

            //Service call to Get all the user list.. 32653  
            sltSyncErrorFactory.getSltSyncData = function(type, id) {
                var promise = $http({
                    method: "GET",
                    url: URLS.getSyncErrorResponse + '&input='+ type +'&log_id=' + id
                }).then(function(response) {
                    if(response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        AlertService.add("error", "No users list found", 4000);
                    }
                });
                return promise;
            }

            return sltSyncErrorFactory;
        }])

    return services;
});