define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.SystemSettingsFTCController', [])
					.controller('parentMeController', ['$scope','$rootScope','$state', '$http', 'gridData', '$uibModalInstance', 'ModalFactory','GridFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'SysSettingsFactory', parentMeController])
					.controller('filingGrpController', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'SysSettingsFactory', filingGrpController])		    
			function parentMeController($scope, $rootScope, $state, $http, gridData, $uibModalInstance, ModalFactory,GridFactory, AlertService, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, SysSettingsFactory) {
				var vm = this;						 
				//To get the Grid Filter Params 
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario				
				vm.scenario = GlobalService.globalParams.scenario;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;								
				//Get the Loggin USER settings 
				vm.userSettings = USER_SETTINGS;
				//Set the Loggin USER settings details
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.globalParams = GlobalService.globalParams;
				//vm.selectedTaxYear = vm.globalParams.tax_year;
				vm.currentScenario = filterParams.scenario;
				
				vm.showSpinner = false;
            	vm.showParentMeDropdown = true;
            	vm.showOptionSicCodeSelect = true;
					
            	//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				            	
				
				//Get the Parent ME (BSLA) Drop-Down
				vm.getParentMeDropdown = function() {
					var params = {"action_code" : 'vxg30f', "sso_id" : vm.logged_in_user, };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.bslaCodeData = data.jsonObject;
							console.log("vm.bslaCodeData : ", data.jsonObject );
						});
				}
				vm.getParentMeDropdown();			
				
				 //When User clicks on a 'Filter' button	    
			    vm.filterParentMeSelection = function() {  
			    	//vm.showSpinner = true;	
			    	vm.showOptionSicCodeSelect = false;
			    	
			    	vm.sicCodeData = [];            //reset the form data to empty so that new set will be loaded
			    	vm.ftcCodeMastYesNoOpts = [];   //reset the form data to empty so that new set will be loaded
			    	vm.selectedSicCodes = []; 		//reset the form data to empty so that new set will be loaded
			    	vm.selectedYesNoCode = [];  	//reset the form data to empty so that new set will be loaded
			    	vm.selectedSicCodesTemp = [];   //reset the form data to empty so that new set will be loaded
			    				    	 
		            var params= {	               
		                "tax_year": vm.selectedTaxYear,
		                "parent_me_string" : vm.selectedMEKey
		            }	        
		           		            
		            //load the filter data
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=3vvxs7', params).then(function(data) {						
						vm.parentMeData = data.jsonObject;	
						vm.newParentMeList = [];
						if(angular.isArray(vm.parentMeData) && (vm.parentMeData.length > 0)) {							
			                for(var i in vm.parentMeData) {		                	
			                	//Skip the YES_NO drop down and the Option Type - R&D Tax Credit - State  from displaying on the screen
			                	if(vm.parentMeData[i].OPTION_CODE_MAP != 'YES_NO'){ //'R&D Tax Credit - State'
			                		vm.newParentMeList.push(_.clone(vm.parentMeData[i]));			                		
			                	}
			                    if(vm.parentMeData[i].CODE_GROUP === 'SIC_CODE'){
			                    	vm.parentMeData[i]['SIC_CODE_DESC'] = vm.parentMeData[i].CODE_DISP_DESC;
			                    	vm.sicCodeData.push(vm.parentMeData[i]);
			                    }
			                    /*if(vm.parentMeData[i].CODE_GROUP === 'YES_NO'){		                    		  
			                    	vm.ftcCodeMastYesNoOpts.push(vm.parentMeData[i]);
			                    }*/		                    	  
			               }			               
						}			
					});			                    
									
		            //load the saved data		            
		            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=ejsaqd",params).then(function(resultset) {
		            	if (resultset.errorMessage && resultset.errorMessage.length > 0 && resultset.errorMessage !== 'null' ) {
		                    AlertService.add("error", resultset.errorMessage);
		                	return false;
		                } else {
		                	console.log("resultset.jsonObject : ", resultset.jsonObject);	                	
		                	if (angular.isArray(resultset.jsonObject) && (resultset.jsonObject.length > 0)) {
		                		angular.forEach(resultset.jsonObject, function(item) {                            	
		                			if(item.CODE_GROUP == "SIC_CODE"){                            		
		                				vm.selectedSicCodesTemp.push(item);   
		                				//console.log(" push this item ", item  );
		                			}
		                			/*if(item.CODE_GROUP == "YES_NO"){
	                        			vm.selectedYesNoCode = item;                            		
	                        		}*/
		                		});		                		
		                	}else{
		                		console.log("++++++++++++++   No Such Data for given Filter Criteria ++++++++++++++");		                		
		                		//vm.showSpinner = false;
					            vm.showOptionSicCodeSelect = true;
		                	}  
		               }
		            });
		        }
			    
			  //Set the modal for reload
	            $scope.$watch(function() { return vm.sicCodeData + vm.selectedSicCodesTemp }, function() {
	            	vm.selectedSicCodes = [];
	            	if((vm.sicCodeData!=undefined && vm.sicCodeData!=null) &&
	            			(vm.selectedSicCodesTemp && vm.selectedSicCodesTemp.length > 0)){	            		
	            		vm.selectedSicCodesTemp.filter(function (selected) {
							 vm.sicCodeData.some(function (sicCollection) {								
								if ((selected.CODE_KEY === sicCollection.CODE_KEY) &&  (''+selected.ME_KEY === vm.selectedMEKey)){
									vm.selectedSicCodes.push(sicCollection);									
								}; 
							 });
						});	               	
	            		//vm.showSpinner = false;
			            vm.showOptionSicCodeSelect = true;
					}	                	
	            });
			    
	            vm.loadInitialData = function(){
	            	//When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
	            	if (gridData != null) {					
	            		var tempGridData = _.clone(gridData), groups = Object
								.create(null), result, finalKeyCheck = 0;					
					
	            		vm.gridDataMultipleSelect = _.clone(tempGridData);					
	            		//console.log("vm.gridDataMultipleSelect:: ", vm.gridDataMultipleSelect);					
	            		if (vm.gridDataMultipleSelect != undefined) {						
	            			//console.log("vm.gridDataMultipleSelect.rowData:: ", vm.gridDataMultipleSelect.rowData);
	            			if (vm.gridDataMultipleSelect.rowData != undefined) {	
	            				var selectedRow = _.clone(vm.gridDataMultipleSelect.rowData);
	            				//Set the update params
	            				vm.operationType = 'U';
	            				vm.selectedTaxYear = selectedRow.TAX_YEAR;	
	            				vm.selectedMEKey = ''+selectedRow.ME_KEY;
							
	            				vm.showParentMeDropdown = false;
	            				//vm.showSpinner = true;
	            				vm.filterParentMeSelection(); 
							
	            			}else{							
	            				//Set the insert params
	            				vm.operationType = 'I';
	            				 vm.selectedTaxYear = vm.filterTaxYear;
	            				//vm.selectedTaxYear = vm.globalParams.tax_year;
							
	            				vm.showParentMeDropdown = true;
	            				//vm.showSpinner = false;
	            				vm.showOptionSicCodeSelect = false;
	            			}						
	            		}
	            	}
	            }
	            //Initial Load of the modal
	            vm.loadInitialData(); 
				
				//On Click of Save Button on the Screen
				vm.saveParentMeForm = function(){
					
					//To Block user from saving for Return filed year
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
						vm.cancel();										
						AlertService.add("error", "You cannot perform this action as Tax return is already filed.",4000);
						return;
					}
					
		        	vm.savedData = [];
		        	vm.selectedSicCodeKey = '';
		        	
		        	if(vm.selectedSicCodes == undefined || vm.selectedSicCodes == null || vm.selectedSicCodes == ''
		        			|| vm.selectedMEKey == undefined || vm.selectedMEKey == null || vm.selectedMEKey == ''
		        				|| vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select/enter all the required fields.");
						return;
					}
		        	//Add up all the selected sic_codes to one single param
		        	for(var indx in vm.selectedSicCodes){
		        		vm.selectedSicCodeKey = (vm.selectedSicCodeKey === '') ? vm.selectedSicCodes[indx].CODE_KEY : vm.selectedSicCodeKey+','+vm.selectedSicCodes[indx].CODE_KEY;
		        	}	        	
		        	
		        	var sicCodeJsonObject = {		        					
							'option_key':(vm.selectedSicCodes[0].OPTION_KEY === undefined) ? null : vm.selectedSicCodes[0].OPTION_KEY,
							'me_key': (vm.selectedMEKey === undefined) ? null : vm.selectedMEKey,
							'tax_year':(vm.selectedTaxYear === undefined) ? null : vm.selectedTaxYear,
							'code_key':(vm.selectedSicCodeKey === undefined) ? null : vm.selectedSicCodeKey	        							
					};
		        	vm.savedData.push(sicCodeJsonObject);
		        	
		        	/*  //Not saving the YES_NO dropdown into DB right now
		        	 var yesNoJsonObject = {		        					
							'option_key':(vm.selectedYesNoCode.OPTION_KEY === undefined) ? null : vm.selectedYesNoCode.OPTION_KEY,
							'me_key': (vm.selectedME === undefined) ? null : vm.selectedME,
							'tax_year':(vm.selectedTaxYear === undefined) ? null : vm.selectedTaxYear,
							'code_key':(vm.selectedYesNoCode.CODE_KEY === undefined) ? null : ''+vm.selectedYesNoCode.CODE_KEY	        							
					};
		        	vm.savedData.push(yesNoJsonObject);*/
		        	
		         	var data = {
		         		'data':	vm.savedData,
		         		'tax_year': vm.selectedTaxYear
		         	};	
		         		         	
		         	//Call the save service
		         	SysSettingsFactory.saveParentMe(data).then(function (response) {
		         		$uibModalInstance.dismiss('cancel');		         		
		         		var args = {};		
						$rootScope.$emit('gridUpdate', args);
		            });		         	
		        }
					
				
				//reset the modal data
				vm.reset = function() {
					if(vm.operationType == 'I'){
						vm.selectedMEKey = '';
						vm.selectedSicCodes = [];
						vm.sicCodeDataTemp = angular.copy(vm.sicCodeData);
						vm.sicCodeData = [];
						setTimeout(function(){
							vm.sicCodeData = angular.copy(vm.sicCodeDataTemp);
						},100);
						vm.loadInitialData(); 
					}else{
						vm.loadInitialData(); 
					}
				};
				
			
		   }
			
			function filingGrpController($scope, $rootScope, $state, $http,  ModalFactory,GridFactory, AlertService, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, SysSettingsFactory) {
				var vm = this;		
				vm.formData = {};
				//To get the filter params 
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.scenario = filterParams.scenario;				
				//Get the Loggin USER settings 
				vm.userSettings = USER_SETTINGS;
				//Set the Loggin USER settings details
				vm.logged_in_user = vm.userSettings.user.sso_id;
				//Get the certain Tax_Year Data for drop downs
				vm.filteredTaxYearData = [];
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//Redirect the URL to a sourcing screen after closing the Modal screen 
				vm.redirectUrl = function(selectedScreenLink){		
					//console.log("selcted url link ", selectedScreenLink);
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
				
				//Get the Data from FTC Code Mast 
				vm.getFtcCodeMastForDropDown = function() {		
					var params = {"action_code" : 'lfmzzq',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.taxYearsData = _.filter(data.jsonObject, function(p){
	                		 return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});
						vm.YesNoData = _.filter(data.jsonObject, function(p){
	                		 return _.includes(['YES_NO'], p.CODE_GROUP);  
	                	});												
					});
					
					$scope.$watch(function() { return vm.taxYearsData },   function() {
						if(vm.taxYearsData!=undefined || vm.taxYearsData!=null){
							for(var indx in vm.taxYearsData){
								if(vm.taxYearsData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearsData[indx].CODE_DISP_DESC >= vm.filterTaxYear && vm.taxYearsData[indx].CODE_DISP_DESC <= (parseInt(vm.filterTaxYear)+10))){
									vm.filteredTaxYearData.push(vm.taxYearsData[indx]);							
								}
							}
						}
					});
				};
				vm.getFtcCodeMastForDropDown();	
				
				//Get Filing groups Drop Down	
				vm.getFilingGroups = function() {
		    		var params = {"action_code" : 'gk7crv',};		    			
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.filingGroupData = data.jsonObject;		
						//console.log("filingGroupData : ", vm.filingGroupData);
					});
				}
				vm.getFilingGroups();
				
				//Getting the Options - Option Key, Option Desc for Filing Group
				vm.getFtcCodeMastOptions = function() {
					vm.ftcCodeMastOpts;
					var params = {"action_code" : 'bpf6xk',};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.ftcCodeMastOpts = data.jsonObject;
						});
				}
				vm.getFtcCodeMastOptions();
				
				//Get the previously selected values persisted for Filing Group
				vm.getApportionmentValues = function() {		
					vm.getFtcApptSelectedData = [];
					var params = {"action_code" : 'hmmhly',"tax_year":vm.formData.selectedFilingGrpTaxYear,"consol_group_key" : vm.selectedFilingGroup};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.getFtcApptSelectedData = data.jsonObject;							
						});									    	
				}
				
				//Get the filter results for Filing Group
				vm.filterFilingGroup = function() {					 		 
			    	vm.isFilingGroupAvailable = (vm.selectedFilingGroup && vm.formData.selectedFilingGrpTaxYear) ? true: false;			    	
			    	if (vm.isFilingGroupAvailable == true){
			    		vm.getApportionmentValues();						
			    	}			    					
			    }
				
				//Saving the Filing Group Tab 
				vm.saveFilingGroup = function(){					
					if(vm.formData.selectedFilingGrpTaxYear == undefined || vm.formData.selectedFilingGrpTaxYear == '' || vm.selectedFilingGroup == undefined || vm.selectedFilingGroup == '') {
	                    AlertService.add("warning", "Tax Year and Filing Group are required. Please select a value.");
	                    vm.formData.show = false;
	                    return false;
					}
										
		        	angular.forEach(vm.getFtcApptSelectedData, function(Value, Key){		        		
		        			angular.forEach(vm.getFtcApptSelectedData, function(innerValue, innerKey){ 
		        				if((Value.OPTION_KEY == innerValue.OPTION_KEY) && (innerValue.SELECTED_ANSWER !=undefined || innerValue.SELECTED_ANSWER>0)){
		        					Value.ANSWER = innerValue.SELECTED_ANSWER;		        					
		        				}
		        			});       			        				
        			});
		        
		        	var validSelections = false;
		        	for(var itm in vm.getFtcApptSelectedData){		        		
		        		if(vm.getFtcApptSelectedData[itm].ANSWER == null || vm.getFtcApptSelectedData[itm].ANSWER == 0){
		        			AlertService.add("warning", "Please select All the required Dropdowns");		        			
		        			return false;
		        		}else{
		        			validSelections = true;
		        		}
					}
		        	
		        	if(validSelections){  		
		        		var data = {
		        				'data':	vm.getFtcApptSelectedData,
		        				'tax_year': vm.formData.selectedFilingGrpTaxYear,
		        				'filing_grp': vm.selectedFilingGroup
		        		};
		        		SysSettingsFactory.saveFilingGrp(data).then(function (response) {		            	
		        			console.log("Received response data.jsonObject ", response);   		            	
		        		}); 
		        	}
		        }
								
				//Refresh button on the ME SIC CODE MAP page of FTC System Settings
				vm.refresh = function(){
		            getData(true);
		        };
		        
			}
			
			return controllers;
		});