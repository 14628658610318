define([
    'angular',
    './QreEntitydetailsController',
    './QreEntitydetailsService'



], function () {
    'use strict';
    return angular.module('app.qreEntitydetails', ['app.qreentitydetailsController','app.qreentitydetailsService'] );


});