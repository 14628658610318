define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.trueUpTaxesController', [])
					.controller(
							'TrueUpTaxesController',
							[ '$rootScope', '$scope', '$filter','$state', '$log','$http','workspaceFactory','$injector','AlertService', 'GlobalService','CommonUtilitiesFactory',
							         '$timeout', '$uibModal','CodeComboFactory', 'JsonObjectFactory','TrueUpTaxesFactory', 'GENERAL_CONFIG',
									'ModalFactory','$uibModalInstance','rowData', 'colData','gridData','USER_SETTINGS', trueUpTaxesController ])


			function trueUpTaxesController($rootScope, $scope, $filter, $state, $log,$http,workspaceFactory,$injector,
					AlertService, GlobalService, CommonUtilitiesFactory,$timeout, $uibModal, 
					CodeComboFactory, JsonObjectFactory, TrueUpTaxesFactory,
					GENERAL_CONFIG, ModalFactory, $uibModalInstance, rowData,
					colData,gridData, USER_SETTINGS) {

				var vm = this;
				$scope.form = {
					divForm : {}
				};
				vm.rowData = rowData;
				vm.colData = colData;
				$scope.objects = [];
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
		        vm.titleCom = 'Comments';
		        vm.COMMENTS ='';
		        vm.OLD_COMMENTS=vm.COMMENTS;
		        vm.allComments = []
		        vm.GENERAL_CONFIG=GENERAL_CONFIG;

				vm.principalCCY = {};
				vm.principalCCYCode = "";
				vm.principalCCYRate = "";
				vm.currencies = null;
				vm.taxyear = null;
				vm.taxesAmount = null;
				vm.filteredEntities =[];
				vm.editRecords=[];
				vm.editExisitngEntity = true;
				if ($uibModalInstance) {
					vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
							: null;
					vm.cancel = function() {
						if(vm.editExisitngEntity== false){
							 var args = {COMBINATION_KEYS: rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: rowData.COMBINATION_KEY}};
                              $rootScope.$emit('gridUpdate', args);
						}else{
							var args = {};
						$rootScope.$emit('gridUpdate', args);
						}
						
						$uibModalInstance.close();
						vm.modal_name = null;
					};
				} else {
					vm.modal_name = null;
				}
				
				function prepareEditRows(res) {
					vm.getEntities('',function(filteredEntities){
						var selectedEntity = _.find(filteredEntities,{COMBINATION_KEY:res.COMBINATION_KEY});
						res.selectedEntity = selectedEntity;
						vm.getCurrencies(res,function(){
								res.currency=_.find(res.currencies,{CURR_CODE:res.CURR});
								vm.updateWeightedAverage(res);
							});
						/*vm.getTaxyears(selectedEntity,res,function() {
							res.year=_.find(res.years,{TAX_YEAR:res.BRANCH_TAX_YEAR});
							});*/
					});
				}
				
				function getAllComments(){
			        	var params = {
			        			"action_code": 'xcko1g',
			        			tax_year:	vm.rowData.TAX_YEAR,
			        			scenario: 	workspaceFactory.activeScreen.filters.filterParams.scenario,
			        			jcd_key: 	GlobalService.globalParams.jcd_key,
			        			combination_key:vm.rowData.COMBINATION_KEY,
			        			currency:vm.rowData.PARENT_FUNCTIONAL_CURRENCY,
			        			sso_id:USER_SETTINGS.user.sso_id,
			        			GROUP_TRANS_KEY:vm.rowData.GROUP_TRANS_KEY
						}
			        	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
			                console.log(data.jsonObject, "all comments");
			                vm.allComments = data.jsonObject;
			            });
			        }
			
			        if(ModalFactory.response.data.modalScreen.modal_name !== 'open905cAnalysisBox' && gridData.colData.label === "Comments"){
			        	getAllComments(vm.rowData);
			        }
			        
				function init() {
					vm.title = 'Add Entity';
					vm.editRecords=[];
					vm.rowData = rowData || $scope.$parent.rowData;
					
				if(ModalFactory.response.data.modalScreen.modal_name === 'exisitng905Entity'){
					vm.editExisitngEntity = false;
                		 return TrueUpTaxesFactory.getTrueUpTaxes(rowData.TAX_YEAR, rowData.COMBINATION_KEY, rowData.TRANS_HEADER_KEY, rowData.TRANS_DETAILS_KEY).then(
						function(response) {
							console.log(response);
							var minRows=1;
							if(response){
								vm.editRecords=response;
								for(var i=0;i<response.length;i++){
									var res=response[i];
									vm.addNew(res,res.US_WTD_AVG_RATE,res.FIR_CTX_TAXES,res.TLC_AMT,res.GROUP_TRANS_KEY,res.TRANS_HEADER_KEY,res.TRANS_DETAILS_KEY,res.COMMENTS,true,res.UPDATED_BY+' '+res.UPDATED_ON.substring(0,10));
									prepareEditRows(res);
									minRows=minRows-1;
								}
							}
							for(var i=0;i<minRows;i++) {
								vm.addNew({});
							}
						}) 
                	 }else{
							var minRows=3;
							for(var i=0;i<minRows;i++) {
								vm.addNew({});
							}
                        }
							
				}
				
				vm.getEntities = function(val,callBack) {
					return TrueUpTaxesFactory.getEntities($scope.taxYear, $scope.scenario, $scope.jcd_key, val).then(
							function(res) {
								vm.filteredEntities=res;//_.filter(res,{LEID:rowData.LEID});
								if(callBack){
									callBack(vm.filteredEntities);
								}
								return vm.filteredEntities;
								/*vm.filteredEntities== res;
            					return res;*/
							});
				};
				
				vm.setEntity = function($item,$event,selectedObject) {
					selectedObject.selectedEntity = $item;
					//vm.getTaxyears($item,selectedObject);
				};
				
				
				vm.getTaxyears = function(selectedEntity,selectedObject,callBack) {
					return TrueUpTaxesFactory.getTaxyears($scope.taxYear,selectedEntity.LEID,selectedEntity.CDR_NO)
					.then(function(res) {
						selectedObject.years=res;
						if(callBack){
							callBack();
						}
					});
				};
				
				vm.getCurrencies = function(selectedObject,callBack) {
					return TrueUpTaxesFactory.getCurrencies(selectedObject.year.TAX_YEAR).then(
						function(res) {
							selectedObject.currencies= res;
							if(callBack){
								callBack();
							}
						});
				};
				
				vm.getFirTaxAmt = function(selectedObject) {
					if(vm.filteredEntities.length && selectedObject.year.TAX_YEAR && selectedObject.selectedEntity){
						var combination_key=selectedObject.selectedEntity.COMBINATION_KEY;
						TrueUpTaxesFactory.getTLCAmount(selectedObject.year.TAX_YEAR,combination_key)
						.then(function(res) {
							var txAmount=_.filter(res,{TAX_YEAR:selectedObject.year.TAX_YEAR,COMBINATION_KEY:combination_key});
							//selectedObject.taxesAmount='';
							if(txAmount.length){
								selectedObject.TLCAmount = txAmount[0].ADJ_AMT_1;
							}
							vm.updateWeightedAverage(selectedObject);
						});
						return TrueUpTaxesFactory.getFirTaxAmt(selectedObject.year.TAX_YEAR,/*$scope.scenario, $scope.jcd_key,*/combination_key)
						.then(function(res) {
							var txAmount=_.filter(res,{TAX_YEAR:selectedObject.year.TAX_YEAR,COMBINATION_KEY:combination_key});
							//selectedObject.taxesAmount='';
							if(txAmount.length){
								selectedObject.taxesAmount = txAmount[0].PER_FIR_CTX_TAXES;
							}
							vm.updateWeightedAverage(selectedObject);
						});
					}
				};
				
				vm.updateWeightedAverage = function(obj) {
					obj.principalCCYRate = obj.US_WTD_AVG_RATE;
					vm.calcTaxAmount(obj);
				}
				
				vm.calcTaxAmount=function(obj){
					if(obj.taxesAmount && obj.TLCAmount && obj.principalCCYRate){
						obj.adjInFC		=	Number(obj.TLCAmount)-Number(obj.taxesAmount);
						obj.adjInUSD	= Number(obj.principalCCYRate)*obj.adjInFC;
					} else {
						obj.adjInFC		= '';
						obj.adjInUSD	= '';
					}
				}
				
				vm.addNew = function(row,principalCCYRate,taxesAmount,TLCAmount,groupTransKey,transHeaderKey,transDetailsKey,usrcomments,isEdit,userUpdate) {
					if(ModalFactory.response.data.modalScreen.modal_name === 'open905cAnalysisBox'){ 
						var row = {};
					 }
					row.id =$scope.objects.length+1;
					row.name = "PL"+$scope.objects.length+1;
					row.selectedValue= "";
					row.year=workspaceFactory.activeScreen.filters.filterParams.tax_year;//GlobalService.globalParams.tax_year;
					row.years=[];
					row.currency="";
					row.currencies=[];
					row.principalCCYRate=principalCCYRate||"";
					row.selectedEntity= {DISPLAY_VALUE:''};
					row.isEdit= isEdit||false;
					row.taxesAmount=taxesAmount||"";
					row.TLCAmount=TLCAmount||'';
					row.groupTransKey = groupTransKey||"";
					row.transHeaderKey = transHeaderKey||"";
					row.transDetailsKey = transDetailsKey||"";
					row.adjInFC='';
					row.adjInUSD='';
					row.userUpdate=userUpdate||'';
					row.usrcomments=usrcomments||'';
					$scope.objects.push(row);
					return row;
				};
				 vm.save = function() {
					 
				 }
				 vm.singleRowSave = function(obj,isDelete) {
					 console.log(obj);
					 var requestPayload={};
					 if(obj.selectedEntity && obj.selectedEntity.COMBINATION_KEY
							 && obj.currency && obj.currency.CURR_CODE){
						 var combination_key=obj.selectedEntity.COMBINATION_KEY;
						 
						 requestPayload.LEID=obj.selectedEntity.LEID;
						 requestPayload.CDRNO=obj.selectedEntity.CDR_NO;
						 requestPayload.REPORTING_PERIOD=obj.selectedEntity.REPORTING_PERIOD;
						 requestPayload.COMBINATION_KEYS = combination_key;
						 requestPayload.TAX_YEAR= workspaceFactory.activeScreen.filters.filterParams.tax_year;
						 requestPayload.GROUP_TRANS_KEY = obj.groupTransKey;
						 requestPayload.TRANS_HEADER_KEY =obj.transHeaderKey;
						 requestPayload.TRANS_DETAILS_KEY =obj.transDetailsKey;
						 requestPayload.COMBINATION_KEY = obj.selectedEntity.COMBINATION_KEY;
						 requestPayload.CHART_KEY = obj.selectedEntity.CHART_KEY;
						 requestPayload.CURRENCY=obj.currency.CURR_CODE;
						 requestPayload.EXCHANGE_RATE=obj.principalCCYRate;
						 requestPayload.TLC_AMOUNT=obj.TLCAmount;
						 requestPayload.TAXES_AMOUNT=obj.taxesAmount;
						 requestPayload.COMMENTS=obj.usrcomments;
						 if(obj.SEQUENCE_ID){
							 requestPayload.SEQUENCE_ID=obj.SEQUENCE_ID;
						 }
						 
						 var checkLockObj =[];
		                 checkLockObj.push({combination_key:obj.selectedEntity.COMBINATION_KEY});
		                 var LockObj = {};
		                 LockObj.modal_name=ModalFactory.response.data.modalScreen.modal_name,
		                 LockObj.lockingObj=checkLockObj;
		                 
						 TrueUpTaxesFactory.save(requestPayload,isDelete,LockObj).then(function(res){
							console.log(res);
							if(isDelete){
								$scope.objects.splice(_.findIndex($scope.objects,{SEQUENCE_ID:obj.SEQUENCE_ID}),1);
							}
								if(vm.editExisitngEntity== false){
								     vm.cancel();
							     }
						 });
					 }
				 }
				 
				 vm.saveComments = function() {
						        	 if(vm.COMMENTS == undefined || vm.COMMENTS == null || vm.COMMENTS == ''){
											AlertService.add("error", "Please enter a valid comments",4000);
											return;
										}
						
							        	if(vm.COMMENTS.length > 2000){
											AlertService.add("error", "Please comments entered cannot be more than 2000.",4000);
											return;
										}
						
						        	vm.loading = true;
						
						        	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=3pu788";
						          var affectedRow = vm.rowData;
						
						          var jsonObj = [{
						         	 combination_key:vm.rowData.COMBINATION_KEY,
						         	 chart_key:vm.rowData.CHART_KEY,
						             currency:vm.rowData.PARENT_FUNCTIONAL_CURRENCY,
						        	 comments:  vm.COMMENTS.replace(/’/g, "'"),
						        	 GROUP_TRANS_KEY: vm.rowData.GROUP_TRANS_KEY
									}];
						        	console.log(jsonObj);
						
						         var jsonSettings = [{
						         	 combination_key:vm.rowData.COMBINATION_KEY,
						             tax_year:vm.rowData.TAX_YEAR
									}];
						
						
						         var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
						         var params = _.merge({}, GlobalService.globalParams, filterParams);
						         var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
						         var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
						          params.process_name =  "TaxTrueUpCommentsSave";
						
						          var service = $injector.get('JsonObjectFactory');
						          service.saveJSON(url , params).then(function(result){
						
						              if(result.callSuccess === "1"){
						                AlertService.add("success", "Data saved Successfully.", 4000);
						              /* var args = {COMBINATION_KEYS:vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
						                   $rootScope.$emit('gridUpdate', args);*/
						
						              }else{
						                if(result.errorMessage){
						                    AlertService.add("error", result.errorMessage,4000);
						                }else{
						                    AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
						                }
						
						
						              }
						
						              vm.loading = false;
						              var args = {COMBINATION_KEYS: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEYS:vm.rowData.COMBINATION_KEY}};
						              $rootScope.$emit('gridUpdate', args);
						
						              $uibModalInstance.dismiss('cancel');
						
						
						          });
						      };
				 init();
			}
			return controllers;
		});