
define([
    'angular',
    './HelpController',
    './TrainingVideosController',
    './UploadTrainingVideosController'


], function () {
    'use strict';
    return angular.module('app.help', ['app.helpController','app.trainingVideosController','app.uploadTrainingVideosController'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider
                .state('help', {
                    url: '/help',
                    templateUrl: 'app/components/help/help-tpl.html',
                    data:{ncyBreadcrumbLabel: 'Help'},
                    access:""
                })
        }])

});