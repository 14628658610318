define([
    'angular',
    '../../adminDashboardCtrl'

], function () {
    'use strict';

    angular.module('app.adminDashboardCtrl')
        .controller('regularTreeMapController', ['$scope','dataFormatterFactory', regularTreeMapController])

    function regularTreeMapController($scope,dataFormatterFactory) {
        $scope.formatters = dataFormatterFactory.getAllFormatters();
        // var parentScope = $scope.$parent.$parent.ctrl //TODO : Find Better Solution
        // vm.$parent
        $scope.treeLevelObj = {
            // level: 1,
            colorByPoint: null,
            dataLabels: { enabled: true },
            layoutAlgorithm: "squarified",
            borderWidth: 3
        }

        $scope.layoutAlgorithms = [
            { name: 'Slice And Dice', value: 'sliceAndDice' },
            { name: 'Stripes', value: 'stripes' },
            { name: 'Squarified', value: 'squarified' },
            { name: 'Strip', value: 'strip' }
        ]

        $scope.addNewLevel = function () {
            $scope.componentSettings.levels.push(angular.copy($scope.treeLevelObj))
            // parentScope.component.extraOptions.componentSettings.levels[parentScope.component.extraOptions.componentSettings.levels.length - 1].level += 1

        }

        // parentScope.component.extraOptions.componentSettings.treeType = "treemap";
        // parentScope.component.extraOptions.componentSettings.levels = []
        // parentScope.component.extraOptions.componentSettings.levels.push($scope.treeLevelObj)



        $scope.$on('component-object:loaded', function (e, component) {
            $scope.component = component;
            $scope.componentSettings = component.extraOptions.componentSettings
            $scope.componentSettings.type = "treemap";
            $scope.componentSettings.levelIsConstant = false,
            $scope.componentSettings.levels = [];
            $scope.componentSettings.levels.push($scope.treeLevelObj);
            console.log($scope.component)
        });

        $scope.updateComponent = function () {
            $scope.$emit('component-object:changed', $scope.componentSettings);
        }
    }
});