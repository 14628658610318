/**
 * Created on 10/1/2019.
 */

define(
		[ 'angular' ],
		function() {
			'use strict';
			var services = angular
					.module('app.recurringMultiYearTaxAdjustmentService', [])
					.factory(
							"recurringMultiYearTaxAdjustmentService",
							[
									'$q',
									'$http',
									'$stateParams',
									'$timeout',
									'$rootScope',
									'$log',
									'GlobalService',
									'DataFiltersFactory',
									'workspaceFactory',
									'GENERAL_CONFIG',
									function($q, $http, $stateParams, $timeout,
											$rootScope, $log, GlobalService,
											DataFiltersFactory,
											workspaceFactory, GENERAL_CONFIG) {
										var recService = {};
										recService.url = {};
										recService.url.saveRecurringMultiYearTaxAdjustments = GENERAL_CONFIG.base_url
												+ "/saveJsonObject?action_code=ev11st";

										recService.saveRecurringMultiYearTaxAdjustments = function(
												_data) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var jsonObj = JSON.stringify(_data);
											var jsonSettings = JSON
													.stringify(_data);

											var params = filterParams;
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _.extend({
												jsonObj : jsonObj
											}, params);
											params = _.extend({
												jsonSettings : jsonSettings
											}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "PREDEFINED_SCHDM_ADJ";
											console
													.log("RECURRING_SCHDM to save");
											console.log(params);

											console.log(params);
											var promise = $http(
													{
														method : "post",
														url : recService.url.saveRecurringMultiYearTaxAdjustments,
														data : params
													}).then(function(response) {
												return response.data;
											});

											return promise;
										}

										return recService;
									} ]);

			return services;
		});
