/**
 * @author Vijay Ramagiri This is to get and save WithHolding Taxes 
 * 
 * 
 */
define(
		[ 'angular',
		    './WithHoldTaxService',
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.WithHoldTaxCtrl', ['app.WithHoldTaxService'])
					.controller('withHoldTaxCtrl',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
									'$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','WithHoldTaxFactory','workspaceFactory','$http','USER_SETTINGS', withHoldTaxCtrl ])

			function withHoldTaxCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, WithHoldTaxFactory,workspaceFactory, $http,USER_SETTINGS) {
				
				var vm = this;				
				vm.formData = {};
				//Handle Creditable and Non-Creditable radio button and HTML fields
				vm.formData.panel = true;
				//Show the loading spinners when data loading takes time
				vm.formData.tabular = true;	
				//Handle Basket and Country for Standard Reason Amt Not Creditable as - 'Section 909 Suspended Splitter Taxes'
				vm.formData.isSection909SplitrTax = false;
				vm.formData.isSectionReductionTax = false;
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				vm.isSaveCredClicked = false;
				
				vm.userSettings = USER_SETTINGS;
				
				//Load the ATTRIB_MAST data to get the Pivot Keys
				vm.attribMastData;
				//Load the Basket Data for the Drop down
				vm.basketCatgData;
				//Load the Country data for join with Generated LEID drop down
				vm.countryData;
				//Load the Tax Years for the Drop down
				vm.taxYearData;
				//Load the Standard Reason for the Drop down
				vm.stdReasonCodeData;
				//Find the Default Basket Key after loading the Basket Data for the Drop down
				vm.basketCodeDefault;
				//Find the US Country key
				vm.countryUS;
				//Set the LEID for the selected Withhold Tax
				vm.formData.LEID;
				//Identify TAG_KEY for Non Creditable Tax Amount screen 
				vm.formData.tagKey;
				//Data retrieved from Grid to Popup screen
				vm.defaultsMultipleSelected = [];
				//Sorted Tax Year Data filtered for last ten years
				vm.filteredTaxYearData = [];
				//To get the Lock_Y_N flag from backend
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.formData.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.formData.scenario = filterParams.scenario;    
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				
				vm.formData.whTaxOnDividend;
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				// Get all the Trading partner data (all the data from mvgt for a given me)				
				vm.getTradingPartnerData = function() {
					var params = {
							"action_code" : 'ayqh3u',
							'p_scenario':   vm.formData.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	//vm.tradingPartnerCodeData = data.jsonObject;
			                	vm.beatYesforeignTradingPartnerData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['RELATED_FGN_BEAT_YES'], p.CODE_GROUP);  
			                		});
			                	
			                	vm.beatNoforeignTradingPartnerData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['RELATED_FGN_BEAT_NO'], p.CODE_GROUP);  
			                		});
			                	
			                	vm.tradingPartnerCodeData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['RELATED_FGN_BEAT_YES','RELATED_FGN_BEAT_NO'], p.CODE_GROUP);  
			                		});
			                /*	
			                		
			                	vm.foreignTradingPartnerCodeData = angular.copy(vm.tradingPartnerCodeData);
			                	_.remove(vm.foreignTradingPartnerCodeData, {LE_COUNTRY: 'US'});
			                				                	
			                	vm.domesticTradingPartnerCodeData = angular.copy(vm.tradingPartnerCodeData);
			                		_.filter(vm.tradingPartnerCodeData, function(o){return o.LE_COUNTRY == "US"})*/
			                	
			                	// vm.getMethod();
			                }
						});
				}
				vm.getTradingPartnerData();
				
				//Redirect the URL to a sourcing screen after closing the Modal screen 
				vm.redirectUrl = function(){
					$uibModalInstance.dismiss('cancel');
					//$state.transitionTo('workspace.tag-summary', null, {'reload':true});	
					
					var tag_summary_filters = [
						{"filter_key":"1","name":"tax_year","value":filterParams.tax_year},
						{"filter_key":"24","name":"scenario","value":filterParams.scenario},
						{"filter_key":"45","name":"filing_key","value":filterParams.filing_key},
						{"filter_key":"26","name":"cc_key","value":filterParams.cc_key},
						{"filter_key":"52","name":"parent_me_string","value":filterParams.parent_me_string}
					  ];					 
					workspaceFactory.loadLink("drillDown",null,	"832", "291", tag_summary_filters,{},null,null);
					                          //"drillDown", null, activity_key, screen_key, filters[], {}, null, null
		
				}
								
				var lockparams = {"action_code": 'n10cpv', "p_scenario" : vm.formData.scenario, "p_jcd_key" :vm.jcd_key	};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;	
					vm.formData.ReturnAsFiled = ((vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y')?true : false);
				});	
							        
				//Retreive the Attrib Mast data
				vm.getAttribMast = function() {		
					var params = {"action_code" : '5u7cuj',}; 
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.attribMastData = data.jsonObject;							
					});					
				}
				vm.getAttribMast();
				
				//Get the CODE_KEY for the given TAX_YEAR CODE_DISP_DESC 
				function getTaxYearKey(taxYearDesc) {
					var selectedTaxYearKey;
					for(var indx in vm.taxYearData){								
						if(vm.taxYearData[indx].CODE_DISP_DESC == taxYearDesc){
							selectedTaxYearKey = ''+vm.taxYearData[indx].CODE_KEY;							
						}
					}
					return selectedTaxYearKey;
				};
				
				//Filter the tax_year between [current year - 1] to [current year -11]
				vm.tillCurrentTaxYear = function(curentYr, fromPriorYr, tillPriorYr) {
					vm.filteredTaxYearData = [];						
					for(var indx in vm.taxYearData){								
						if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' && (vm.taxYearData[indx].CODE_DISP_DESC <= (curentYr-fromPriorYr) && vm.taxYearData[indx].CODE_DISP_DESC >= (curentYr-tillPriorYr))){
							vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
						}
					}
					$scope.$watch(function() { return vm.filteredTaxYearData }, function() {
						vm.formData.applicableYearKey = getTaxYearKey(vm.formData.filterTaxYear-fromPriorYr);
					});
				};
				
				//On select of Std Reason - update the Applicable Year Dropdown
				vm.isTaxPriorYear = function() {
					vm.formData.selectedSplitterCountryDesc = '';
					vm.formData.selectedSplitterTradingPartnerCode = '';
					vm.selectedTradingPartnerCode = '';
					vm.selectedCountryCode ='';
					
					if(vm.formData.selectStdReasonCd!=undefined && vm.formData.selectStdReasonCd == vm.taxPriorYearCodeKey){
						//if the as Std Reason is selected as 'Tax Prior Year'
						vm.tillCurrentTaxYear(vm.formData.filterTaxYear,1,11);
					}else{
						//if the as Std Reason is selected other than 'Tax Prior Year'
						vm.tillCurrentTaxYear(vm.formData.filterTaxYear,0,11);
					}
					
					if(vm.formData.selectStdReasonCd!=undefined && vm.formData.selectStdReasonCd == vm.section909SplitrTaxKey){
						vm.formData.selectedSplitterBasketCatg = ''+vm.basketCodeDefault[0].CODE_KEY;
						vm.formData.isSection909SplitrTax = true;						
					}else{
						vm.formData.isSection909SplitrTax = false;
					}
					
					if(vm.formData.selectStdReasonCd!=undefined && (vm.formData.selectStdReasonCd == vm.sectionReduction_GKEY || vm.formData.selectStdReasonCd == vm.sectionReduction_AKEY )){
						vm.formData.selectedSplitterBasketCatg = ''+vm.basketCodeDefault[0].CODE_KEY;
						vm.formData.isSectionReductionTax = true;						
					}else{
						vm.formData.isSectionReductionTax = false;
					}					
				}
				
				//Get the All the required Dropdown data
				vm.getRequiredDropDowns = function() {		
					var params = {"action_code" : 'e83e1x', "tax_year" : vm.formData.filterTaxYear};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.basketCatgData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
	                		});
						vm.countryData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
	                		});
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                		});	
						vm.stdReasonCodeData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['STD_REASON_CODE'], p.CODE_GROUP);  
	                		});	
						vm.basketCodeDefault = _.filter(vm.basketCatgData, function(p){
	                		  return _.includes(['BRANCH'], p.CODE_SHORT_DESC);  
						});	
						vm.countryUS =  _.filter(vm.countryData, function(p){
	                		  return _.includes(['US'], p.CODE_SHORT_DESC);  
						});
						vm.taxPriorYearStdReason = _.filter(vm.stdReasonCodeData, function(p){
	                		  return _.includes(['TPPY'], p.CODE_SHORT_DESC);  
						});	
						vm.section909SplitrTax = _.filter(vm.stdReasonCodeData, function(p){
	                		  return _.includes(['SUSPT'], p.CODE_SHORT_DESC);  
						});	
						
						vm.sectionReduction_G = _.filter(vm.stdReasonCodeData, function(p){
	                		  return _.includes(['RTX-965(g)'], p.CODE_SHORT_DESC);  
						});	
						vm.sectionReduction_A = _.filter(vm.stdReasonCodeData, function(p){
	                		  return _.includes(['RTX-245A'], p.CODE_SHORT_DESC);  
						});	
						
						vm.taxPriorYearCodeKey = ''+vm.taxPriorYearStdReason[0].CODE_KEY;
						vm.section909SplitrTaxKey = ''+vm.section909SplitrTax[0].CODE_KEY;
						
						if(vm.sectionReduction_G[0] != undefined) {
						 vm.sectionReduction_GKEY = ''+vm.sectionReduction_G[0].CODE_KEY;
						}
						
						if(vm.sectionReduction_A[0] != undefined) {
							vm.sectionReduction_AKEY = ''+vm.sectionReduction_A[0].CODE_KEY;
						}
					});					
				};																
				//Load the Data from FTC_CODE_MAST for dropdowns
				vm.getRequiredDropDowns();
				vm.isTaxPriorYear();
				
				/*//Get the Trading Partner data for dropdown				
				vm.getTradingPartnerData = function() {
					var params = {
							"action_code" : 'ayqh3u',
							'p_scenario':   vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
			                }else{
			                	
			                	vm.beatTypeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BEAT_TYPE_CODE'], p.CODE_GROUP);  
			                		});		                	
			                	vm.countryData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
			                	});
	                		    vm.foreignTradingPartnerCodeData = _.filter(data.jsonObject, function(p){
		                		   return _.includes(['RELATED_FGN_BEAT_YES'], p.CODE_GROUP);  	  
	                		    });
	                		    //vm.getPopUpData();
			                }
						});					
					
				}*/
				
				vm.changeCountry = function(data) {
					vm.formData.selectedSplitterCountryDesc = vm.selectedCountryCode;
				}
				
				
				vm.numberWithCommas = function(x) {
				    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
				}

				vm.whTaxOnDividendAmount = function(event){
								if(vm.formData.whTaxOnDividend){
									vm.formData.whTaxOnDividend = vm.formData.whTaxOnDividend.replace(/(?!^-)[^0-9\.]/g, '')
									let amount =  vm.formData.whTaxOnDividend;
									if(amount.indexOf('.') !== -1){
										let amountSp = amount.split('.'),bPre=0,aPre=0,amt = 0;
											bPre = amountSp[0];
											aPre = amountSp[1];
											if(bPre && bPre.length > 20){
													bPre = bPre.substring(0, 19)
											}
											if(aPre && aPre.length > 2){
												aPre = aPre.substring(0, 2)
											}
											vm.formData.whTaxOnDividend =  bPre+"."+aPre;
									}else if(amount.length > 20){
										amount = amount.substring(0, amount.length - 1)
										vm.formData.whTaxOnDividend = amount;
									}
								}
								
								vm.formData.whTaxOnDividend = vm.numberWithCommas(vm.formData.whTaxOnDividend);
							}
				
            	// This will populate the Country Dropdown with the COUNTRY of selected trading partner.			
				vm.changeTradingPartner = function(data){					
					var exists = _.find(vm.tradingPartnerCodeData, ['COMBINATION_KEY', data]);
                	if(exists != undefined){
                		vm.selectedCountry = exists.LE_COUNTRY;
                		vm.selectedTradingPartnerLEID = exists.COMBINATION_KEY;
                	}
                	vm.formData.selectedSplitterTradingPartnerCode = vm.selectedTradingPartnerCode;
					var exists = _.find(vm.countryData, ['CODE_SHORT_DESC', vm.selectedCountry]);
					if(exists != undefined){
						vm.selectedCountryCode = exists;
						vm.formData.selectedSplitterCountryDesc = exists.CODE_KEY;
					}else{ 
						vm.selectedCountryCode ='';
						vm.formData.selectedSplitterCountryDesc = '';
					}					
				}
				
				//Get the CODE_KEY for the given COUNTRY CODE_DISP_DESC 
				function getCountryKey(countryDesc) {
					var selectedCountryKey;
					for(var indx in vm.countryData){								
						if(vm.countryData[indx].CODE_DISP_DESC == countryDesc){
							selectedCountryKey = ''+vm.countryData[indx].CODE_KEY;							
						}
					}
					return selectedCountryKey;
				};
				
				//Retreive the TAG Details data using CC_KEY
				vm.getTagDetailsData = function(cc_key) {	
					var params = {"action_code" : 'sopgak', "combination_key":cc_key, "tax_year" : vm.formData.filterTaxYear,  "scenario" : vm.formData.scenario}; 
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.ftcTagDtlsData = data.jsonObject;								
					});						
				}
				
				//Grid Data from WithHold Tax  Screen
				if (gridData != null) {
					//console.log("::: ->  -> gridData:: ", gridData);
					//console.log("::: ->  ->  rowData:: ", rowData);					
					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					//console.log("tempGridData:: ", tempGridData);
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
															
					if (vm.gridDataMultipleSelect != undefined && vm.gridDataMultipleSelect.rowData != undefined) {
						vm.formData.LEID = vm.gridDataMultipleSelect.rowData.LEID;
						vm.formData.openVariance = parseInt(''+vm.gridDataMultipleSelect.rowData.VARIANCE_AMT).toLocaleString(undefined, {maximumFractionDigits:0});
						vm.formData.withHoldTaxTrailBalanceAmt = parseInt(''+vm.gridDataMultipleSelect.rowData.TB_AMT).toLocaleString(undefined, {maximumFractionDigits:0});
						vm.formData.totalWithHoldTaxAmt = parseInt(''+vm.gridDataMultipleSelect.rowData.SOURCED_AMT).toLocaleString(undefined, {maximumFractionDigits:0});
						vm.formData.withHoldVarianceTaxAmt = parseInt(''+vm.gridDataMultipleSelect.rowData.VARIANCE_AMT).toLocaleString(undefined, {maximumFractionDigits:0});
						vm.formData.whTaxOnDividend = vm.gridDataMultipleSelect.rowData.WHT_DIVIDEND ? vm.numberWithCommas(parseFloat(vm.gridDataMultipleSelect.rowData.WHT_DIVIDEND)) : null;
						
						//Get All the available attrib_names and values from back end DB
						vm.getTagDetailsData(vm.gridDataMultipleSelect.rowData.COMBINATION_KEY);
						vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect.rowData);
						vm.formData.tabular = false;
					}
				}
				
				//Delete the selected row 
				vm.deleteRow = function(selectedRowData){
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['tax_year'] = vm.formData.filterTaxYear;
					returnClobSettingsObj['scenario'] = vm.formData.scenario;
					
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					var returnObj = {};
					returnObj["TAG_KEY"] = selectedRowData.TAG_KEY;
					returnObj['COMBINATION_KEY'] = selectedRowData.COMBINATION_KEY;
					returnObj["OPERATION_TYPE"] = 'D';
					returnObj['GROUP_ID'] = 1;
					returnObj["IS_CREDITABLE"] = 'N';
					returnObj["0_0"] = '0';
					returnObj["AMOUNT"] =  0;				
					
					var message = "WithHold Tax Data has been successfully Deleted";
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);
					//send the data to delete
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);
				}
				
				//Save Creditable Amt
				vm.saveCreditWHData = function() {
					/*if(vm.formData.whTaxOnDividend == null || (vm.formData.whTaxOnDividend == '' && vm.formData.whTaxOnDividend != '0')){
						AlertService.add("error", "Please enter a valid Withholding Tax dividend.",4000);
						return;
					}*/
					
					//Disable the Save button till the processing completes
					vm.isSaveCredClicked = true;
					
					var key = 0;															
					var returnObj = {};
					var whTaxOnDividendAct = vm.formData.whTaxOnDividend;
					if (whTaxOnDividendAct) {
						whTaxOnDividendAct = whTaxOnDividendAct.replace(/,/g,"");
					}
					returnObj['sso_id'] = vm.userSettings.user.sso_id;
					returnObj['tax_year'] = vm.formData.filterTaxYear;
					returnObj['scenario'] = vm.formData.scenario;
					returnObj['object_id'] = vm.defaultsMultipleSelected[key].COMBINATION_KEY;
					returnObj["combination_key"] = vm.defaultsMultipleSelected[key].COMBINATION_KEY;
					returnObj["wht_dividend"] = (whTaxOnDividendAct !== '') ? whTaxOnDividendAct : null;
					//returnObj["IS_CREDITABLE"] = 'N';
				//	returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'WHT_DIVIDEND']).ATTRIB_KEY +'_0'] = vm.formData.whTaxOnDividend;
					console.log("returnObj::FNL:: ",returnObj);
					
					var message = "Creditable Tax has been successfully saved/updated";
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);	
					console.log(defaultsChangeDetails);
					//send to save the Data
					sendCreditableDetails(returnObj,defaultsChangeDetails, message);
					
				}
				
				//Save the Non Creditable Amt
				vm.saveNonCreditWHData = function(){					
					if(vm.formData.selectStdReasonCd == null || vm.formData.selectStdReasonCd == '' || vm.formData.detailReason == null || vm.formData.detailReason == '' 
						|| vm.formData.applicableYearKey == null  || vm.formData.applicableYearKey == ''){
						AlertService.add("error", "Please select/enter all the fields.",4000);
						return;
					}
					
					//Validations for the Amounts field				
					if(vm.formData.nonCrdAmount == null || vm.formData.nonCrdAmount == ''){
						AlertService.add("error", "Please enter a valid Withholding Tax Non-Creditable Amount.",4000);
						return;
					}
					if(vm.formData.nonCrdAmount !=undefined && vm.formData.nonCrdAmount !=null ){
						var validNumberWithDecimals = ((parseInt(vm.formData.nonCrdAmount) - parseFloat(vm.formData.nonCrdAmount)) !== 0);
						if(validNumberWithDecimals){
							AlertService.add("error", "Please do not enter decimals for Withholding Tax Non-Creditable Amount.",4000);
							return;
						}
					}
					
					
					/*if(vm.formData.whTaxOnDividend == null || vm.formData.whTaxOnDividend == ''){
						AlertService.add("error", "Please enter a valid Withholding Tax dividend.",4000);
						return;
					}*/
					
					if(vm.formData.selectStdReasonCd!=undefined && (vm.formData.selectStdReasonCd == vm.section909SplitrTaxKey || vm.formData.selectStdReasonCd == vm.sectionReduction_GKEY || vm.formData.selectStdReasonCd == vm.sectionReduction_AKEY)){
						if(vm.formData.selectedSplitterBasketCatg == null || vm.formData.selectedSplitterBasketCatg == ''){
							AlertService.add("error", "Please select Basket for selected Standard Reason Amount is Non-Creditable.",4000);
							return;
						}
						if(vm.formData.selectedSplitterCountryDesc == null || vm.formData.selectedSplitterCountryDesc == ''){
							AlertService.add("error", "Please select Country for selected Standard Reason Amount is Non-Creditable.",4000);
							return;
						}
					}
					
					if(vm.formData.selectStdReasonCd!=undefined && ( vm.formData.selectStdReasonCd == vm.sectionReduction_GKEY || vm.formData.selectStdReasonCd == vm.sectionReduction_AKEY)){
						
						if(vm.formData.selectedSplitterTradingPartnerCode == null || vm.formData.selectedSplitterTradingPartnerCode == ''){
							AlertService.add("error", "Please select Trading Partner for selected Standard Reason Amount is Non-Creditable.",4000);
							return;
						}
					}
					
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var key = 0;															
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['tax_year'] = vm.formData.filterTaxYear;
					returnClobSettingsObj['scenario'] = vm.formData.scenario;
					returnClobSettingsObj['object_id'] = vm.defaultsMultipleSelected[key].COMBINATION_KEY;
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					//SAVE the Standard Rason and Non_Creditable Amount
					var returnObj = {};					
					returnObj["COMBINATION_KEY"] = vm.defaultsMultipleSelected[key].COMBINATION_KEY;					
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'STANDARD_REASON_NOT_CREDITABLE']).ATTRIB_KEY +'_0'] = vm.formData.selectStdReasonCd; 
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'DETAIL_REASON_NOT_CREDITABLE']).ATTRIB_KEY +'_0'] = vm.formData.detailReason; 
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'APPLICABLE_YEAR']).ATTRIB_KEY +'_0'] = vm.formData.applicableYearKey;
					if(vm.formData.selectStdReasonCd!=undefined && (vm.formData.selectStdReasonCd == vm.section909SplitrTaxKey || vm.formData.selectStdReasonCd == vm.sectionReduction_GKEY || vm.formData.selectStdReasonCd == vm.sectionReduction_AKEY)){
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = vm.formData.selectedSplitterBasketCatg;
						if (typeof vm.selectedCountryCode === 'object') {
							returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = vm.selectedCountryCode.CODE_KEY;
						} else {
							returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = getCountryKey(vm.selectedCountryCode);	
						}
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'ACTUAL_TP_KEY']).ATTRIB_KEY +'_0'] = vm.formData.selectedSplitterTradingPartnerCode;	
						returnObj["IS_CREDITABLE"] = 'N';
					}
					
					/*else{
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = 0;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = 0;	
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'ACTUAL_TP_KEY']).ATTRIB_KEY +'_0'] = 0;
					}*/
					returnObj["IS_CREDITABLE"] = 'N';
					returnObj["AMOUNT"] = (vm.formData.nonCrdAmount != null) ? vm.formData.nonCrdAmount : vm.defaultsMultipleSelected[key].NON_CREDIT_AMT;
					//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'WHT_DIVIDEND']).ATTRIB_KEY +'_0'] = vm.formData.whTaxOnDividend;
					returnObj['GROUP_ID'] = 1;
					if(vm.formData.editFlagSet){
						if(vm.defaultsMultipleSelected[key].TAG_KEY!=undefined && vm.defaultsMultipleSelected[key].TAG_KEY !=null){
							returnObj["TAG_KEY"] = (vm.formData.tagKey != null) ? vm.formData.tagKey : vm.defaultsMultipleSelected[key].TAG_KEY; 
							returnObj["OPERATION_TYPE"] = 'U';
						}else{
							returnObj["TAG_KEY"] = null;
							returnObj["OPERATION_TYPE"] = 'I';
						}
					}else{
						returnObj["TAG_KEY"] = null;
						returnObj["OPERATION_TYPE"] = 'I';
					}						
					
					console.log("returnObj::FNL:: ",returnObj);
						
					var message = "Non-Creditable Tax has been successfully saved/updated";
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);	
					console.log(defaultsChangeDetails,returnClobSettingsObj);
					//send to save the Data
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);
					
				}
				
				//Send Details
				function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
					WithHoldTaxFactory.savePivotedData(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id:returnClobSettingsObj.object_id,
                                    gridFilter: {
                                        object_id: returnClobSettingsObj.object_id
                                    }
							};
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
				
				//Send Details
				function sendCreditableDetails(returnObj, defaultsChangeDetails, message) {
					WithHoldTaxFactory.saveWHCreditablePivotedData(returnObj,defaultsChangeDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveCredClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id:returnObj.object_id,
                                    gridFilter: {
                                        object_id: returnObj.object_id 
                                    }
							};
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
								
				//To Edit the Non-Creditable TAX screen popup
				vm.editNonCreditableTaxRow = function(selectedRowData){			        
					for(var key in vm.stdReasonCodeData){
						if(vm.stdReasonCodeData[key].CODE_DISP_DESC == selectedRowData.STANDARD_REASON_NOT_CREDITABLE){
							vm.formData.selectStdReasonCd = ''+vm.stdReasonCodeData[key].CODE_KEY;
						}
					}			
					vm.isTaxPriorYear();//To reset Applicable Year when the Std Reason is selected as 'Tax Prior Year'	or Other					
					$scope.$watch(function() { return vm.filteredTaxYearData },   function() {
						for(var key in vm.taxYearData){
							if(vm.taxYearData[key].CODE_DISP_DESC == selectedRowData.APPLICABLE_YEAR){
								vm.formData.applicableYearKey = ''+vm.taxYearData[key].CODE_KEY;							
							}
						}
					});
					if (selectedRowData.COUNTRY) {
						for(var indx in vm.countryData){								
							if(vm.countryData[indx].CODE_DISP_DESC == selectedRowData.COUNTRY){
								vm.formData.selectedSplitterCountryKey = ''+vm.countryData[indx].CODE_KEY;
								vm.formData.selectedSplitterCountryDesc = vm.countryData[indx].CODE_DISP_DESC;	
								vm.selectedCountryCode = vm.formData.selectedSplitterCountryDesc;
							}
						}
					} else {
						let exist = _.find(vm.countryData, ['CODE_DISP_DESC', selectedRowData.LE_COUNTRY_NAME]);
						vm.formData.selectedSplitterCountryKey = ''+exist.CODE_KEY;
						vm.formData.selectedSplitterCountryDesc = exist.CODE_DISP_DESC;	
						vm.selectedCountryCode = vm.formData.selectedSplitterCountryDesc;
					}
					for(var key in vm.basketCatgData){
						if(vm.basketCatgData[key].CODE_DISP_DESC == selectedRowData.BASKET){
							vm.formData.selectedSplitterBasketCatg = ''+vm.basketCatgData[key].CODE_KEY;							
						}
					}
					for(var key in vm.tradingPartnerCodeData){
						if(vm.tradingPartnerCodeData[key].COMBINATION_KEY == selectedRowData.ACTUAL_TP_KEY){
							vm.selectedTradingPartnerCode = ''+vm.tradingPartnerCodeData[key].LE_NAME;	
							vm.formData.selectedSplitterTradingPartnerCode = ''+vm.tradingPartnerCodeData[key].COMBINATION_KEY;
						}
					}
					vm.formData.editFlagSet = true;						
					vm.formData.nonCrdAmount = selectedRowData.NON_CREDIT_AMT;					
					vm.formData.detailReason = selectedRowData.DETAIL_REASON_NOT_CREDITABLE;
					vm.formData.tagKey = selectedRowData.TAG_KEY;
					vm.formData.whTaxOnDividend = selectedRowData.WHT_DIVIDEND ? vm.numberWithCommas(parseFloat(selectedRowData.WHT_DIVIDEND)) : null;
			    };
			    
			    //reset the non-creditable form
			    vm.reset = function(){	
			    	vm.tillCurrentTaxYear(vm.formData.filterTaxYear,0,11);	
					vm.formData.detailReason = '';
					vm.formData.nonCrdAmount = '';										
					vm.formData.applicableYearKey = getTaxYearKey(vm.formData.filterTaxYear);				
					vm.formData.selectStdReasonCd = '';
					vm.formData.selectedSplitterBasketCatg = ''+vm.basketCodeDefault[0].CODE_KEY;
					vm.formData.selectedSplitterCountryDesc = '';
					vm.formData.selectedSplitterTradingPartnerCode = '';
					vm.formData.tagKey = null;
					vm.formData.editFlagSet = false;
					vm.formData.isSection909SplitrTax = false;
					vm.formData.isSectionReductionTax = false;
					//vm.formData.whTaxOnDividend = '';					
				};
				
				//reset creditable form
				vm.reset_credit = function() {
					if(vm.gridDataMultipleSelect.rowData) {
						vm.formData.whTaxOnDividend = vm.gridDataMultipleSelect.rowData.WHT_DIVIDEND ? vm.numberWithCommas(parseFloat(vm.gridDataMultipleSelect.rowData.WHT_DIVIDEND)) : null;
					} else {
						vm.formData.whTaxOnDividend = '';
					}
					
				}
				
			    
			    //Provide the Information of how the Transaction Amounts for Local Accts are derived
				vm.getSummaryInformation = function(){
			    	var params = {
			    					'sso_id': vm.logged_in_user,
			    					'tax_year' : vm.formData.filterTaxYear,
			    					'scenario': vm.formData.scenario,
			    					'leid':rowData.LEID,
			    					'cdr_no':rowData.CDR_NO,
			    					'reporting_period':rowData.REPORTING_PERIOD,
			    					'me_code': rowData.ME_CODE
                            	};
			    	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=35n2lz', params).then(function(data) {
						vm.summaryInfo = data.jsonObject;	
						console.log("vm.summaryInformation	:: ",vm.summaryInformation);
			    	
			    	});	
			    	
			    }
				if(rowData) {			    	
					vm.getSummaryInformation();
			    }			    
			    							
			  //Load the Data for Popup from Summary Screen				
				$scope.$watch(function() { return vm.ftcTagDtlsData },   function() {
										
					if(vm.ftcTagDtlsData!=undefined){
						var allTagKeys = [];
						for ( var item in vm.ftcTagDtlsData) {
							if(_.includes(allTagKeys, vm.ftcTagDtlsData[item].TAG_KEY) == false){
								allTagKeys.push(vm.ftcTagDtlsData[item].TAG_KEY); 
							}
						}
						
						if(allTagKeys.length > 0){
							vm.defaultsMultipleSelected = [];
						}
						
						for( var tagkey in allTagKeys){
							var newObj = angular.copy(vm.gridDataMultipleSelect.rowData);
							for ( var key in vm.ftcTagDtlsData) {
								if(vm.ftcTagDtlsData[key].TAG_KEY == allTagKeys[tagkey]){
									newObj["TAG_KEY"] = vm.ftcTagDtlsData[key].TAG_KEY;
									newObj[vm.ftcTagDtlsData[key].ATTRIB_NAME.replace(/(^"|"$)/g, '')] = vm.ftcTagDtlsData[key].ATTRIB_VALUE;
									newObj["IS_CREDITABLE"] = vm.ftcTagDtlsData[key].IS_CREDITABLE;
									newObj.NON_CREDIT_AMT = vm.ftcTagDtlsData[key].BRANCH_TAXES_AMT;	
								}
							}							
							vm.defaultsMultipleSelected.push(newObj);
						}								
					}				
				});	
				
				//On load -  populate the Applicable Year drop down 
				$scope.$watch(function() { return vm.taxYearData }, function() {
					if(vm.taxYearData!=undefined){
						vm.tillCurrentTaxYear(vm.formData.filterTaxYear,0,11);
					}
				});
			}
			
			return controllers;

		});
