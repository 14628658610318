define([
    'angular',
    './ghostcNonghostScreenService', 
    './ghostcNonghostScreenCtrl',
    './ghostcNonghostDeleteCtrl',
    
], function () {
    'use strict';
    return angular.module('app.FIR_Ghost_Schedule_C_Nonghost_Screen', ['app.ghostcNonghostScreenService','app.ghostcNonghostScreenCtrl','app.ghostcNonghostDeleteCtrl'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        // $stateProvider
        //     .state('sourcing-input-screen', {
        //         url: '/sourcing-input-screen',
        //         templateUrl: 'app/components/FIR_Sourcing_Input/sourcingInputScreen.html',
        //         backdrop: 'static',
        //         data:{},
        //         access:""
        //     })
    }])

});