
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.changeLogMonitorService',[])

    .factory('changeLogMonitorServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var changeLogMonitorServiceFactory = {};
        
        changeLogMonitorServiceFactory.getMonitoringData = function(params){
            var params = params;
            var promise = $http({
                method: "get",
                url: GENERAL_CONFIG.bulk_pdf_engine_api_url + '/getSnsMessage?client_key=' + params.client_key + '&le_key=' + params.le_key + '&le_type=' + params.le_type + '&submissionId=' + params.submissionId,
                data: params
            }).then(function (response) {
                return response;
            });
            return promise;
        };
        
        return changeLogMonitorServiceFactory
    }])

    return services;

});