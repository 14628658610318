define([
    'angular'

], function () {
    'use strict';

    let controllers = angular.module('app.Form8865SchdK1Controllers', [])
        .controller('Form8865SchdK1Controller', ['GlobalService', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'JsonObjectFactory', '$filter', 'AlertService', '$timeout', '$uibModal', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', 'Form8865SchdK1ServicesFactory', '$uibModalInstance', 'rowData', Form8865SchdK1Controller]);

    function Form8865SchdK1Controller(GlobalService, $rootScope, $scope, $state, $log, GridFactory, JsonObjectFactory, $filter, AlertService, $timeout, $uibModal, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, Form8865SchdK1ServicesFactory, $uibModalInstance, rowData) {

        let vm = this;

        let K_and_K1_lines_combined = [];

        vm.title = 'Edit 8865 Schedule K1';

        vm.F8865SchdKWPDDetailForK1Popup;
        vm.F8865SchdK1WPDDetailForEntity;

        let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

        vm.userSettings = USER_SETTINGS;
        vm.sso_id = vm.userSettings.user.sso_id;

        vm.tax_year = rowData.TAX_YEAR;
        vm.scenario = filterParams.scenario;
        vm.jcd_key = GlobalService.globalParams.jcd_key;
        vm.leid = rowData.LEID;
        vm.le_name = rowData.LE_NAME;
        vm.combination_key = rowData.COMBINATION_KEY;
        vm.issue_key       = rowData.ISSUE_KEY;

        vm.userType = vm.userSettings.user.client.user_type_key;

        vm.entityList = {
            "Tax Year": vm.tax_year,
            "LEID": vm.leid,
            "LE Name": vm.le_name
        };


        vm.getF8865SchdKWPDDetailForK1Popup = function() {
            let params = {"action_code" : '0mbkyx', "tax_year" : vm.tax_year, "scenario" : vm.scenario, "jcd_key": vm.jcd_key, "combination_key" : vm.combination_key};
            Form8865SchdK1ServicesFactory.getF8865SchdKWPDDetailForK1Popup(params).then(function(response){
                vm.F8865SchdKWPDDetailForK1Popup = response.jsonObject;
                console.log('vm.F8865SchdKWPDDetailForK1Popup = ', vm.F8865SchdKWPDDetailForK1Popup);
                let params2 = {"action_code" : 'dr530x', "tax_year" : vm.tax_year, "scenario" : vm.scenario, "jcd_key": vm.jcd_key, "combination_key" : vm.combination_key};
                Form8865SchdK1ServicesFactory.getF8865SchdK1WPDDetailForEntity(params2).then(function(response){
                    vm.F8865SchdK1WPDDetailForEntity = response.jsonObject;
                    console.log('vm.F8865SchdK1WPDDetailForEntity = ', vm.F8865SchdK1WPDDetailForEntity);


                    vm.K_K1_array = new Array(vm.F8865SchdKWPDDetailForK1Popup.length);

                    for(let x = 0; x < vm.F8865SchdKWPDDetailForK1Popup.length; x++) {
                        let k_line = vm.F8865SchdKWPDDetailForK1Popup[x];
                        K_and_K1_lines_combined = [];
                        K_and_K1_lines_combined.push(k_line);
                        //console.log('k_line.LINE_ACCT_REFERENCE_KEY = ', k_line.LINE_ACCT_REFERENCE_KEY);
                        let k1_lines = $filter("filter")(vm.F8865SchdK1WPDDetailForEntity, {LINE_ACCT_REFERENCE_KEY: k_line.LINE_ACCT_REFERENCE_KEY});
                        let total = 0;
                        for(let y = 0; y < k1_lines.length; y++) {


                            K_and_K1_lines_combined.push(k1_lines[y]);

                            total = total + k1_lines[y].ADJ_AMT_1;
                        }

                        let total_k_line = angular.copy(k_line);
                        total_k_line.LINE_NO = 'TOTAL_' + k_line.LINE_NO;
                        total_k_line.ADJ_AMT_1 = total;
                        K_and_K1_lines_combined.push(total_k_line);

                        let variance_k_line = angular.copy(k_line);
                        variance_k_line.LINE_NO = 'VARIANCE_' + k_line.LINE_NO;
                        variance_k_line.ADJ_AMT_1 = total - k_line.ADJ_AMT_1;
                        K_and_K1_lines_combined.push(variance_k_line);

                        vm.K_K1_array[x] = K_and_K1_lines_combined;
                    }
                    console.log('vm.K_K1_array = ', vm.K_K1_array);

                });
            });

        }

        vm.getF8865SchdKWPDDetailForK1Popup();

        vm.setChange = function(row) {
            row.IS_CHANGED = "Y";
            console.log('row = ', row);
            if(row.ADJ_AMT_1 == undefined) {
                row.ADJ_AMT_1 = 0;
            }
            for(let x = 0; x < vm.K_K1_array.length; x++) {
                let tabular_K_K1_record =  vm.K_K1_array[x];
                let filtered_tabular_record = $filter("filter")(tabular_K_K1_record,{LINE_ACCT_REFERENCE_KEY:row.LINE_ACCT_REFERENCE_KEY});
                if(filtered_tabular_record.length > 0) {
                    let total = 0;
                    let pshipAmt = 0;
                    for(let y = 0; y < filtered_tabular_record.length; y++) {
                        if(filtered_tabular_record[y].LEID == vm.leid && y == 0) {
                            pshipAmt = filtered_tabular_record[y].ADJ_AMT_1;
                        }
                        if(filtered_tabular_record[y].LEID != vm.leid) {
                           total = total + filtered_tabular_record[y].ADJ_AMT_1;
                       }
                    }
                    $filter("filter")(filtered_tabular_record,{LINE_ACCT_REFERENCE_KEY:row.LINE_ACCT_REFERENCE_KEY, LINE_NO:'TOTAL_'+row.LINE_NO})[0].ADJ_AMT_1 = total;
                    $filter("filter")(filtered_tabular_record,{LINE_ACCT_REFERENCE_KEY:row.LINE_ACCT_REFERENCE_KEY, LINE_NO:'VARIANCE_'+row.LINE_NO})[0].ADJ_AMT_1 = total - pshipAmt;
                    break;
                }
            }
        }


        vm.save = function() {
            let message;

            vm.clob_settings = {
                "tax_year": vm.tax_year,
                "scenario": vm.scenario,
                "jcd_key": vm.jcd_key,
                "combination_key": vm.combination_key,
                "sso_id": vm.sso_id,
                "issue_key": vm.issue_key
            };

            vm.clob_data = [];

            console.log('vm.clob_settings = ', vm.clob_settings);

            for(let x = 0; x < vm.K_K1_array.length; x++) {
                let tabularK_K1 = vm.K_K1_array[x];
                for(let y = 0; y < tabularK_K1.length; y++) {
                    let row = tabularK_K1[y];
                    if(row.IS_CHANGED == "Y") {
                        vm.clob_data.push(row);
                    }
                }
            }

            console.log('vm.clob_data = ', vm.clob_data);

            if(vm.clob_data.length > 0 ) {

                let validated = true;
                let validationMsg;

                for(let x = 0; x < vm.K_K1_array.length; x++) {
                    let tabularK_K1 = vm.K_K1_array[x];
                    for(let y = 0; y < tabularK_K1.length; y++) {
                        let row = tabularK_K1[y];
                        if(row.LINE_NO.includes('VARIANCE')) {
                            if(row.ADJ_AMT_1 != 0) {
                                validated = false;
                                validationMsg = 'Variance exist ' + ' for Line No : ' + tabularK_K1[0].LINE_NO + ' . Please make sure no variance exist before saving';
                                break;
                            }
                        }
                    }
                    if(validated == false) {
                        break;
                    }
                }

                if(validated) {
                    message = '8865 Schedule K1 white paper details saved successfully.';
                    sendDetails(vm.clob_settings,vm.clob_data,message);
                }
                else {
                    AlertService.add("danger", validationMsg, 4000);
                }

            }


        }

        function sendDetails(clobSettings, clobData, message) {

            Form8865SchdK1ServicesFactory.save8865SchdK1WPDForEntity (clobSettings,clobData).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {
                    //vm.crudLoading = false;
                    AlertService.add("success", message, 4000);
                    var args = {
                        pship_leid: vm.PSHIP_LEID,
                        gridFilter: {
                            pship_leid: vm.PSHIP_LEID
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);
                };

            });
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
    }
});