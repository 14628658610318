define(["angular"], function () {
    "use strict";

    angular
        .module("app.footNotesServices", [])
        .factory("footNotesFactory", footNotesFactory);

    footNotesFactory.$inject = ["$http", "GENERAL_CONFIG", "$q", "GlobalService"];

    function footNotesFactory($http, GENERAL_CONFIG, $q, GlobalService) {
        const footNotesFactory = {
            getForms: _getForms,
            getPartners: _getPartners,
            getFootNotes: _getFootNotes,
            saveFootNote: _saveFootNote,
            getFootNote: _getFootNote,
            getViewForDropdown: _getViewForDropdown,
            exportPdf: _exportPdf,
            deleteNote: _deleteNote
        };
        const JCD_KEY = 250;

        function _getForms(tax_year, filing_type, system_flag) {
            return $http.get(
                GENERAL_CONFIG.pdf_engine_api_url + "/getFootNoteForms" + `?tax_year=${tax_year}&filing_type=${filing_type}&system_flag=${system_flag}`
            )
        }

        function _getPartners(tax_year, scenario, leid, cdr_no, rptPrd) {
            return $http.get(
                GENERAL_CONFIG.pdf_engine_api_url + "/getPartners" + `?tax_year=${tax_year}&scenario=${scenario}&jcd_key=${JCD_KEY}&leid=${leid}&cdr_no=${cdr_no}&reporting_period=${rptPrd}`
            );
        }

        function _saveFootNote(note_id, note_name, tax_year, scenario, me_code, leid, cdr_no, rptPrd, form_sub, form_name, schedule, html_data, is_template) {

            return $http.post(
                GENERAL_CONFIG.pdf_engine_api_url + "/saveFootNote",
                {
                    note_id,
                    note_name,
                    tax_year,
                    scenario,
                    me_code,
                    // combination_key,
                    leid,
                    cdr_no,
                    reporting_period: rptPrd,
                    form_sub,
                    form_name,
                    schedule,
                    html_data,
                    is_template,
                    jcd_key: JCD_KEY
                },
                function (response) {
                    // body
                    return response.data;
                }
            );
        }

        function _exportPdf(footNoteContent) {
            return $http.post(
                GENERAL_CONFIG.pdf_engine_api_url + "/getFootNotePDF",
                {
                    footNoteContent
                }, {responseType: "arraybuffer"}).then(response => {
                // body
                let file = new Blob([response.data], {type: 'application/pdf'});
                let pdfPath = URL.createObjectURL(file);

                const a = document.createElement('a');
                a.href = pdfPath;
                //   a.download = 'preview.pdf';
                a.target = '_blank';
                a.click();
            });
        }

        function _getFootNotes(
            taxYear,
            scenario,
            jcdKey,
            leId,
            cdrNo,
            rptPrd,
            formSub,
            formName,
            schedule
        ) {
            let defer = $q.defer();

            if (schedule === 'NA') formSub = null;

            $http
                .get(
                    GENERAL_CONFIG.pdf_engine_api_url +
                    `/getFootNotes?taxYear=${taxYear}&scenario=${scenario}&jcdKey=${jcdKey}&leId=${leId}&cdrNo=${cdrNo}&reporting_period=${rptPrd}&formSub=${formSub}&formName=${formName}&schedule=${schedule}`
                )
                .then(function (response) {
                    if (response.data.callSuccess === "1") {
                        defer.resolve(response.data.footNotes);
                    } else {
                        response.data.errorMessage && console.log(response.data.errorMessage);
                        return defer.resolve(null);
                    }
                });

            return defer.promise;
        }


        function _getFootNote(
            footNoteId
        ) {
            let defer = $q.defer();

            $http
                .get(
                    GENERAL_CONFIG.pdf_engine_api_url +
                    `/getFootNote?footNoteId=${footNoteId}`
                )
                .then(function (response) {
                    if (response.data.callSuccess === "1") {
                        defer.resolve(response.data.footNote);
                    } else {
                        response.data.errorMessage && console.log(response.data.errorMessage);
                        return defer.resolve(null);
                    }
                });

            return defer.promise;
        }

        function _deleteNote(footNodeId) {
            return $http({
                method: "GET",
                url: GENERAL_CONFIG.pdf_engine_api_url + "/deleteFootNote?footNoteId=" + footNodeId
            })
        }

        function _getViewForDropdown(
            category,
            src_system_flag,
            lEType
        ) {
            const promise = $http({
                method: "POST",
                url:
                    GENERAL_CONFIG.pdf_engine_api_url +
                    "/getViewForDropdown?taxYear=" +
                    GlobalService.globalParams.tax_year +
                    "&scenario=" +
                    GlobalService.globalParams.scenario +
                    "&jcdKey=" +
                    JCD_KEY +
                    "&leType=" +
                    lEType +
                    "&src_system_flag=" +
                    src_system_flag +
                    "&business_type=" +
                    category,
            });
            return promise;
        }

        return footNotesFactory;
    }
});
