define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.wwnimappingController', [])
					.controller(
							'WwniMController',
							[ '$rootScope', '$scope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'JsonObjectFactory', '$timeout',
									'WWniGroupObjFactory', 'GENERAL_CONFIG',
									wwnimappingController ])

			function wwnimappingController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					$timeout, WWniGroupObjFactory, GENERAL_CONFIG) {

				var vm = this;
				vm.entities = [];
				$scope.crudLoading = false;
				var sendobj = [];
				vm.repointList = [];
				vm.mappingList = [];
				vm.applyflag = "";
				var getwwniModalcallFlag = false;
				vm.tagetlistADD = false;
				vm.defaultvalue = 0;
				vm.totalVariancea = 0;
				vm.totalVarianceRemaina = 0;
				vm.totalVarianceAllocateda = 0;
				vm.loading = false;
				vm.totalAmt = 0;
				vm.totalNetInc = 0;
				vm.totalVariance = 0;
				vm.netIncPerLoss = 0;
				vm.displayNdata = [];
				vm.displayYdata = [];
				vm.removeFlag = false;
				vm.lookUpVariance = false;
				vm.sourceAmt = 0;
				vm.reasonData = [];
				vm.reasonData_dropdown = [];
				vm.viewColumnNames = [ "Updated On", "Compl. Entity",
						"DML Type", "Old-New Flag", "Trans Type", "Amount",
						"Comments", "Updated By" ];

				vm.MAIN_SOURCE_AMT = (rowData != undefined) ? rowData.TOTAL_SOURCE
						: gridData[0].data.TOTAL_SOURCE;
				if (vm.MAIN_SOURCE_AMT == undefined) {
					vm.MAIN_SOURCE_AMT = 0;
				}
				vm.netIncRemain = 0;
				// After Number vm.netIncRemain = vm.sourceAmt -
				vm.sysLeidDropdown = [];
				vm.sysLeidDropdownNdata = [];
				vm.sysLeidDropdownDefault = "";
				vm.varianceList = [];
				vm.varianceSubcategory = [];

				vm.entities = _.clone(gridData);
				vm.expla_code_dropdown = [];
				vm.sub_category_dropdown = [];
				vm.changeColorwwni = false;
				vm.changeColorVariance = false;
				vm.saveSwapFlag = true;
				vm.saveSwapvariance = false;
				vm.saveSwapnetInc = false;
				var source_combinationKey = "";
				var source_headerKey = "";
				var source_combinationKeyS = "";
				var source_headerKeyS = "";
				vm.tempListSwap = [];
				vm.tempsourceCombi = [];
				var counter = 0;
				var counterV = 0;

				vm.columnNames = [];

				// vm.changeColorvariance = false;
				function init() {

					$scope.crudLoading = false;
					if (!_.isArray(gridData)) {

						var obj = {}
						obj.row_id = gridData.rowData.object_id;
						obj.data = gridData.rowData;
						gridData = [];
						gridData.push(obj)
					}
					vm.entities = _.clone(gridData);

					/*
					 * for (var a in vm.entities) {
					 * vm.tempsourceCombi.push(vm.entities[a].data.SOURCE_COMBINATION_KEY) }
					 * vm.tempsourceCombi = _.uniq(vm.tempsourceCombi,
					 * JSON.stringify); if(vm.tempsourceCombi.length == 1) {
					 * AlertService.add("", "Please Select Diffrent Source
					 * LEIDs.", 40000); vm.userMessage = "!! Unable to perform
					 * Selected ..."; throw new ("This is not an error.
					 * This is just to abort javascript!"); }
					 */

					// vm.entities =
					// _.uniq(vm.entities.data.SOURCE_COMBINATION_KEY,
					// JSON.stringify)
					if (typeof rowData !== 'undefined') {
						vm.rowData = rowData;
					}

					for ( var h in vm.entities) {
						vm.entities[h].colorvalue = "";

					}

					vm.entities[0].colorvalue = true;

					vm.loading = false;
					getVariancesubCategory()
					getWWNImodalData();

				}

				$scope.showDetails = function(_index, _boolean) {
					vm.mappingList[_index].open = _boolean;

				};

				vm.addRepointVariance = function() {
					$timeout(function() {
						vm.tagetlistADD = false;
						;

						vm.mappingList.push({
							ALLOCATED_AMT : 0,
							VARIANCE : 0,
							SYSTEM_AMT_USD : 0,
							tagetlistADD : "true",
							colorvaluevariance : "gray",
							COLUMN_FLAG : 'F',
							ROW_INDICATOR : 'M',
							TARGET_COMBINATION_KEY : 0,
							variancedata : [ {
								VARIANCE_ALLOCATED : 0,
								VARIANCE_EXPL_CODE : "",
								VARIANCE_SUB_CATEFORY : "",
								VARIANCE_EXPL_CODE_KEY : "",
								ROW_KEY : "",
								VARIANCE_COMMENTS : "",
							} ],

						});
						vm.tagetlistADD = true;
					});

				}

				vm.targetKey = function(index, row) {
					vm.changeColorVariance = false;
					vm.changeColorVariance = false;
					for ( var t in vm.displayNdata) {
						if (vm.displayNdata[t].TARGET_KEY == row.TARGET_KEY) {
							vm.mappingList[index].ALLOCATED_AMT = vm.displayNdata[t].ALLOCATED_AMT;
							vm.mappingList[index].COLUMN_FLAG = vm.displayNdata[t].COLUMN_FLAG;
							vm.mappingList[index].DEFAULT_FLAG = vm.displayNdata[t].DEFAULT_FLAG;
							vm.mappingList[index].SYSTEM_AMT_LOCAL = vm.displayNdata[t].SYSTEM_AMT_LOCAL;
							vm.mappingList[index].EXCHANGE_RATE = vm.displayNdata[t].EXCHANGE_RATE;
							vm.mappingList[index].MAP_ACCT_KEY = vm.displayNdata[t].MAP_ACCT_KEY;
							vm.mappingList[index].MAP_KEY = vm.displayNdata[t].MAP_KEY;
							vm.mappingList[index].ROW_KEY = vm.displayNdata[t].ROW_KEY;
							vm.mappingList[index].SYSTEM_AMT_USD = vm.displayNdata[t].SYSTEM_AMT_USD;
							vm.mappingList[index].VARIANCE = vm.displayNdata[t].VARIANCE;
							// vm.mappingList[index].ATTRIB_TEXT_VALUE =
							// vm.displayNdata[t].ATTRIB_TEXT_VALUE;
							vm.mappingList[index].COMMENTS = vm.displayNdata[t].COMMENTS;
							vm.mappingList[index].TARGET_HEADER_KEY = vm.displayNdata[t].TARGET_HEADER_KEY;
							vm.mappingList[index].ROW_INDICATOR = vm.displayNdata[t].ROW_INDICATOR;
							vm.mappingList[index].VARIANCE_SUB_CATEFORY_KEY = vm.displayNdata[t].VARIANCE_SUB_CATEFORY_KEY;
							vm.mappingList[index].TARGET_COMBINATION_KEY = vm.displayNdata[t].TARGET_COMBINATION_KEY;
							vm.mappingList[index].TARGET_CHART_KEY = vm.displayNdata[t].TARGET_CHART_KEY;
							vm.mappingList[index].TARGET_ACCT_KEY = vm.displayNdata[t].TARGET_ACCT_KEY;

						}
					}

					// vm.totalVariance = 0;
					// vm.totalVarianceAllocateda = 0;

					vm.totalVariancea = 0;
					vm.netIncPerLoss = 0;
					vm.netIncRemain = 0
					for (var i = 0; i < vm.mappingList.length; i++) {
						// vm.totalNetInc +=
						// vm.repointList[i].SYSTEM_AMT_LOCAL;
						// vm.totalVariancea += vm.mappingList[i].VARIANCE;
						vm.netIncPerLoss += vm.mappingList[i].ALLOCATED_AMT;
						vm.netIncRemain += vm.mappingList[i].SYSTEM_AMT_USD;
						vm.totalVariancea = vm.netIncPerLoss - vm.netIncRemain;
					}
					vm.tempInternalvariance = 0;
					vm.mappingList[index].colorvaluevariance = "";
					for (var a = 0; a < vm.mappingList[index].variancedata.length; a++)

					{
						vm.tempInternalvariance += vm.mappingList[index].variancedata[a].VARIANCE_ALLOCATED;
						vm.totalVarianceAllocateda += vm.mappingList[index].variancedata[a].VARIANCE_ALLOCATED;
					}

					if (vm.mappingList[index].VARIANCE == vm.tempInternalvariance) {
						vm.mappingList[index].colorvaluevariance = "green";

					} else if (vm.mappingList[index].VARIANCE > 0
							|| vm.mappingList[index].VARIANCE < 0) {
						vm.mappingList[index].colorvaluevariance = "red";
						if (vm.tempInternalvariance > 0
								|| vm.tempInternalvariance < 0) {
							$scope.showDetails(index, true);
						}
					} else {
						vm.mappingList[index].colorvaluevariance = "gray";
					}

					vm.totalVarianceRemaina = vm.totalVariancea
							- vm.totalVarianceAllocateda;
					// vm.netIncRemain = vm.sourceAmt - vm.netIncPerLoss;
					if (vm.netIncRemain > 0 || vm.netIncRemain < 0) {
						vm.changeColorwwni = true;

					}
					if (vm.totalVarianceRemaina > 0
							|| vm.totalVarianceRemaina < 0) {
						vm.changeColorVariance = true;
					}
					vm.checkSaveErrors();
				}

				vm.varianceexplcode = function(index, row1, row2) {
					vm.sub_category_dropdown = [];
					// vm.subcateDropdown = [];
					for ( var c in vm.varianceSubcategory)

					{
						if (vm.varianceSubcategory[c].VARIANCE_EXPL_CODE == row2.VARIANCE_EXPL_CODE) {

							vm.sub_category_dropdown
									.push(vm.varianceSubcategory[c].VARIANCE_SUB_CATEFORY);
							// vm.sub_category_dropdown =
							// vm.sub_category_dropdown =
							// _.uniq(vm.sub_category_dropdown,JSON.stringify)
						}

					}
					vm.sub_category_dropdown = _.uniq(vm.sub_category_dropdown,
							JSON.stringify)

					for (var i = 0; i < vm.mappingList.length; i++) {
						if (vm.mappingList[i].$$hashKey == row1.$$hashKey) {

							if (vm.sub_category_dropdown.length > 1) {
								vm.mappingList[i].variancedata[index].variance_Dropdown_Flag = true;
								vm.mappingList[i].variancedata[index].subcateDropdown = vm.sub_category_dropdown;
							} else {
								vm.mappingList[i].variancedata[index].variance_Dropdown_Flag = false;
								vm.mappingList[i].variancedata[index].VARIANCE_SUB_CATEFORY = "";
								vm.mappingList[i].variancedata[index].subcateDropdown = [];
							}
						}
					}

					/*
					 * for (var i = 0; i < vm.mappingList.length; i++) { if
					 * (vm.mappingList[i].$$hashKey == row1.$$hashKey) {
					 * vm.mappingList[i].variancedata[index].variance_Dropdown_Flag =
					 * false;
					 * 
					 * if (vm.varianceSubcategory[c].VARIANCE_SUB_CATEFORY !=
					 * '0') {
					 * vm.mappingList[i].variancedata[index].VARIANCE_SUB_CATEFORY =
					 * vm.varianceSubcategory[c].VARIANCE_SUB_CATEFORY; } if
					 * (vm.sub_category_dropdown.length > 1) {
					 * vm.mappingList[i].variancedata[index].variance_Dropdown_Flag =
					 * true; } } }
					 */

					vm.checkSaveErrors();
				}

				// }

				/*
				 * vm.change_total_variance = function() { vm.totalVariance = 0;
				 * vm.totalAmt = 0; if (vm.repointList.length > 0 ||
				 * vm.mappingList.length > 0) {
				 * 
				 * if (vm.repointList.length > 0) { for ( var c in
				 * vm.repointList) { vm.totalAmt += vm.repointList[c].ADJ_AMT;
				 * vm.totalVariance += vm.repointList[c].VARIANCE; } } if
				 * (vm.mappingList.length > 0) { for ( var d in vm.mappingList) {
				 * vm.totalAmt += vm.mappingList[d].ADJ_AMT; vm.totalVariance +=
				 * vm.mappingList[d].VARIANCE; } } } }
				 */

				vm.highlight = function(index) {
					/*
					 * if( vm.saveSwapvariance === true || vm.saveSwapnetInc ===
					 * true) {vm.saveSwapFlag = false; }
					 */
					// vm.saveSwapFlag = true;
					vm.MAIN_SOURCE_AMT = gridData[index].data.TOTAL_SOURCE;
					console.log("Call The stored Proc");
					// getWWNImodalData();
					for ( var h in vm.entities) {
						if (vm.entities[h].colorvalue === true) {
							source_combinationKey = vm.entities[h].data.SOURCE_COMBINATION_KEY;
							source_headerKey = vm.entities[h].data.SOURCE_HEADER_KEY;
						}
						vm.entities[h].colorvalue = "";

					}

					for ( var g in vm.entities) {
						if (index == g)
							vm.entities[g].colorvalue = true;
						source_combinationKeyS = vm.entities[g].data.SOURCE_COMBINATION_KEY;
						source_headerKeyS = vm.entities[g].data.SOURCE_HEADER_KEY;
					}

					// getWWNImodalData(index);

					// }

					// vm.swapSource = function(index) {
					getwwniModalcallFlag = false;

					for (var s = 0; s < vm.mappingList.length; s++) {
						var tempJson = {};
						if (vm.mappingList[s].ALLOCATED_AMT != 0) {
							tempJson["tax_year"] = GlobalService.globalParams.tax_year;
							tempJson["scenario"] = GlobalService.globalParams.scenario;
							tempJson["jcd_key"] = GlobalService.globalParams.jcd_key;
							tempJson["source_combination_key"] = source_combinationKey;
							tempJson["target_combination_key"] = vm.mappingList[s].TARGET_COMBINATION_KEY;
							tempJson["map_key"] = vm.mappingList[s].MAP_KEY;
							tempJson["source_header_key"] = source_headerKey;
							// dummyJson["source_details_key"] =
							// rowData.SOURCE_DETAILS_KEY;
							tempJson["target_header_key"] = vm.mappingList[s].TARGET_HEADER_KEY;
							tempJson["target_row_flag"] = vm.mappingList[s].ROW_INDICATOR;
							tempJson["target_chart_key"] = vm.mappingList[s].TARGET_CHART_KEY;
							tempJson["target_acct_key_1"] = vm.mappingList[s].TARGET_ACCT_KEY;
							tempJson["target_acct_key_2"] = vm.mappingList[s].VARIANCE_SUB_CATEFORY_KEY;
							tempJson["target_amount"] = vm.mappingList[s].ALLOCATED_AMT;
							// tempJson["target_reason"] =
							// vm.mappingList[s].ATTRIB_TEXT_VALUE;
							tempJson["target_comments"] = vm.mappingList[s].COMMENTS;
							tempJson["ROW_KEY"] = vm.mappingList[s].ROW_KEY;
							tempJson["typeOf"] = "Edit";
							if (sendobj.length > 0) {
								var sendObjintermapping = false;
								for ( var c in sendobj) {
									if (sendobj[c].target_combination_key == vm.mappingList[s].TARGET_COMBINATION_KEY
											&& sendobj[c].target_row_flag == 'M') {
										sendobj[c] = tempJson;
										sendObjintermapping = true;
									}

								}
								if (sendObjintermapping === false) {
									sendobj.push(tempJson);
								}
							}
							if (sendobj.length == 0) {
								sendobj.push(tempJson);

							}

						}

					}
					for (var s = 0; s < vm.mappingList.length; s++) {
						for (var t = 0; t < vm.mappingList[s].variancedata.length; t++) {
							tempJson = {};

							if (vm.mappingList[s].variancedata[t].VARIANCE_ALLOCATED != 0) {
								tempJson["tax_year"] = GlobalService.globalParams.tax_year;
								tempJson["scenario"] = GlobalService.globalParams.scenario;
								tempJson["jcd_key"] = GlobalService.globalParams.jcd_key;
								tempJson["source_combination_key"] = source_combinationKey;
								tempJson["target_combination_key"] = vm.mappingList[s].TARGET_COMBINATION_KEY;
								tempJson["map_key"] = vm.mappingList[s].MAP_KEY;
								tempJson["source_header_key"] = source_headerKey;
								// dummyJson["source_details_key"] =
								// rowData.SOURCE_DETAILS_KEY;
								tempJson["target_header_key"] = vm.mappingList[s].TARGET_HEADER_KEY;
								tempJson["target_row_flag"] = "V";

								for ( var j in vm.varianceSubcategory) {
									if (vm.varianceSubcategory[j].VARIANCE_EXPL_CODE == vm.mappingList[s].variancedata[t].VARIANCE_EXPL_CODE) {
										vm.mappingList[s].variancedata[t].VARIANCE_ACCT_KEY = vm.varianceSubcategory[j].VARIANCE_ACCT_KEY;
										vm.mappingList[s].variancedata[t].VARIANCE_CHART_KEY = vm.varianceSubcategory[j].VARIANCE_CHART_KEY;
										vm.mappingList[s].variancedata[t].VARIANCE_SUB_CATEFORY_KEY = vm.varianceSubcategory[j].VARIANCE_SUB_CATEFORY_KEY;
									}
								}

								for ( var js in vm.varianceSubcategory) {
									if (vm.varianceSubcategory[js].VARIANCE_EXPL_CODE == vm.mappingList[s].variancedata[t].VARIANCE_EXPL_CODE) {

										vm.mappingList[s].variancedata[t].VARIANCE_SUB_CATEFORY_KEY = vm.varianceSubcategory[js].VARIANCE_SUB_CATEFORY_KEY;
									}
								}

								tempJson["target_acct_key_1"] = vm.mappingList[s].variancedata[t].VARIANCE_ACCT_KEY;
								tempJson["target_acct_key_2"] = vm.mappingList[s].variancedata[t].VARIANCE_SUB_CATEFORY_KEY;
								tempJson["target_chart_key"] = vm.mappingList[s].variancedata[t].VARIANCE_CHART_KEY;
								tempJson["target_amount"] = vm.mappingList[s].variancedata[t].VARIANCE_ALLOCATED;
								// tempJson["target_reason"] =
								// vm.mappingList[s].ATTRIB_TEXT_VALUE;
								tempJson["target_comments"] = vm.mappingList[s].variancedata[t].VARIANCE_COMMENTS;
								tempJson["ROW_KEY"] = vm.mappingList[s].variancedata[t].ROW_KEY;
								tempJson["typeOf"] = "Edit";
								if (sendobj.length > 0) {
									var sendObjintermapping = false;
									for ( var c in sendobj) {
										if (sendobj[c].ROW_KEY == vm.mappingList[s].variancedata[t].ROW_KEY
												&& sendobj[c].target_row_flag == 'V'
												&& sendobj[c].target_combination_key == vm.mappingList[s].TARGET_COMBINATION_KEY) {
											sendobj[c] = tempJson;
											sendObjintermapping = true;
										}

									}
									if (sendObjintermapping === false) {
										sendobj.push(tempJson);
									}
								}
								if (sendobj.length == 0) {
									sendobj.push(tempJson);

								}

							}
						}

					}
					if (vm.tempListSwap.length > 0) {
						var internalFlagtempList = false;
						for ( var c in vm.tempListSwap) {
							if (vm.tempListSwap[c].sourceCombikey == source_combinationKey) {
								vm.tempListSwap[c] = vm.mappingList;
								vm.tempListSwap[c].sourceCombikey = source_combinationKey;

								internalFlagtempList = true;

							}

						}
						if (internalFlagtempList === false) {
							vm.tempListSwap.push(vm.mappingList);
							var source_combi_index = vm.tempListSwap.length - 1;
							vm.tempListSwap[source_combi_index].sourceCombikey = source_combinationKey;
						}

					}

					if (vm.tempListSwap.length == 0) {
						vm.tempListSwap.push(vm.mappingList);
						var source_combi_index = vm.tempListSwap.length - 1;
						vm.tempListSwap[source_combi_index].sourceCombikey = source_combinationKey;
					}

					for ( var c in vm.tempListSwap) {
						if (vm.tempListSwap[c].sourceCombikey == gridData[index].data.SOURCE_COMBINATION_KEY) {
							vm.mappingList = [];
							vm.mappingList = vm.tempListSwap[c];
							getwwniModalcallFlag = true;
							// vm.saveSwapFlag = true;
							vm.totalVariancea = 0;
							vm.totalVarianceAllocateda = 0;
							vm.totalVarianceRemaina = 0;
							vm.totalNetInc = 0;
							vm.netIncPerLoss = 0;
							vm.netIncRemain = 0;

							for (var i = 0; i < vm.mappingList.length; i++) {
								// vm.totalNetInc +=
								// vm.repointList[i].SYSTEM_AMT_LOCAL;
								// vm.totalVariancea +=
								// vm.mappingList[i].VARIANCE;
								vm.netIncPerLoss += vm.mappingList[i].ALLOCATED_AMT;
								vm.netIncRemain += vm.mappingList[i].SYSTEM_AMT_USD;
								vm.totalVariancea = vm.netIncPerLoss
										- vm.netIncRemain;
								for (var h = 0; h < vm.mappingList[i].variancedata.length; h++) {

									vm.totalVarianceAllocateda += vm.mappingList[i].variancedata[h].VARIANCE_ALLOCATED;
								}
							}

							// vm.netIncRemain = vm.sourceAmt -
							// vm.netIncPerLoss;
							vm.totalVarianceRemaina = vm.totalVariancea
									- vm.totalVarianceAllocateda;
							vm.loading = true;
							vm.changeColorwwni = false;
							vm.changeColorVariance = false;
							if (vm.netIncRemain > 0 || vm.netIncRemain < 0) {
								vm.changeColorwwni = true;

							}
							if (vm.totalVarianceRemaina > 0
									|| vm.totalVarianceRemaina < 0) {
								vm.changeColorVariance = true;

							}

						}
					}

					if (getwwniModalcallFlag == false) {
						getWWNImodalData(index);
					}

					/*
					 * for(var c in vm.tempListSwap ) {
					 * if(vm.tempListSwap[c].sourceCombikey ==
					 * gridData[index].data.SOURCE_COMBINATION_KEY) {
					 * vm.mappingList =[]; vm.mappingList = vm.tempListSwap[c];
					 * getwwniModalcallFlag = true; //vm.saveSwapFlag = true; } }
					 * 
					 * 
					 * 
					 * 
					 * if(getwwniModalcallFlag == false) {
					 * getWWNImodalData(index); }
					 */

				}
				// }

				vm.removeEntity = function(_index) {
					vm.entities.splice(_index, 1);
					gridData.splice(_index, 1);

					if (vm.entities.length === 0) {
						$uibModalInstance.dismiss('cancel');
					}

					for (var a = 0; a < vm.entities.length; a++) {
						vm.entities[a].colorvalue = "";
					}
					if (vm.entities.length != 0) {
						vm.entities[0].colorvalue = true;
						vm.sourceAmt = vm.entities[0].data.NET_INC_LOSS_PER_FS;
						getWWNImodalData(0);

					}

				}

				vm.varianceRemove = function(row, index, index1) {
					vm.varianceDirectDeleteflag = true;
					for (var l = 0; l < vm.mappingList.length; l++) {
						if (vm.mappingList[l].$$hashKey == index.$$hashKey)

						{

							for (var i = 0; i < vm.mappingList[l].variancedata.length; i++) {
								if (vm.mappingList[l].variancedata[i].$$hashKey == index1.$$hashKey) {

									for ( var d in sendobj) {
										if (sendobj[d].target_row_flag == 'V'
												&& sendobj[d].target_combination_key == vm.mappingList[l].variancedata[i].TARGET_KEY) {

											sendobj[d].typeOf = "Delete";

											vm.varianceDirectDeleteflag = false;

											sendobj.splice(d, 1);

											// call the delete Stored proc

										}
									}
									// delete logic
									if (vm.varianceDirectDeleteflag === true) {

										var tempJson = {};

										tempJson["tax_year"] = GlobalService.globalParams.tax_year;
										tempJson["scenario"] = GlobalService.globalParams.scenario;
										tempJson["jcd_key"] = GlobalService.globalParams.jcd_key;
										tempJson["source_combination_key"] = source_combinationKey;
										tempJson["target_combination_key"] = index.TARGET_COMBINATION_KEY;
										tempJson["map_key"] = index.MAP_KEY;
										tempJson["source_header_key"] = source_headerKey;
										// dummyJson["source_details_key"]
										// =
										// rowData.SOURCE_DETAILS_KEY;
										tempJson["target_header_key"] = index.TARGET_HEADER_KEY;
										tempJson["target_row_flag"] = "V";

										for ( var j in vm.varianceSubcategory) {
											if (vm.varianceSubcategory[j].VARIANCE_EXPL_CODE == index1.VARIANCE_EXPL_CODE) {
												index.VARIANCE_ACCT_KEY = vm.varianceSubcategory[j].VARIANCE_ACCT_KEY;
												// index.VARIANCE_SUB_CATEFORY_KEY
												// =
												// vm.varianceSubcategory[j].VARIANCE_SUB_CATEFORY_KEY;
												index.VARIANCE_CHART_KEY = vm.varianceSubcategory[j].VARIANCE_CHART_KEY;
											}
										}

										for ( var jm in vm.varianceSubcategory) {
											if (vm.varianceSubcategory[jm].VARIANCE_SUB_CATEFORY == index1.VARIANCE_SUB_CATEFORY) {
												index.VARIANCE_SUB_CATEFORY_KEY = vm.varianceSubcategory[jm].VARIANCE_SUB_CATEFORY_KEY;
											}
										}

										tempJson["target_acct_key_1"] = index.VARIANCE_ACCT_KEY;
										tempJson["target_acct_key_2"] = index.VARIANCE_SUB_CATEFORY_KEY;
										tempJson["target_chart_key"] = index.VARIANCE_CHART_KEY;
										tempJson["target_amount"] = index1.VARIANCE_ALLOCATED;
										// tempJson["target_reason"] =
										// index.ATTRIB_TEXT_VALUE;
										tempJson["target_comments"] = index1.VARIANCE_COMMENTS;
										tempJson["ROW_KEY"] = index1.ROW_KEY;
										tempJson["typeOf"] = "Delete";

										sendobj.push(tempJson);

									}

									vm.mappingList[l].variancedata.splice(i, 1);

								}
							}

						}
					}

					vm.changeInternalVar(row, index, index1);

				}

				vm.removeRepointAmount = function(index) {

					vm.repointList.splice(index, 1);
					vm.changeColorVariance = false;
					vm.totalNetInc = 0;

					if (vm.repointList.length > 0 || vm.mappingList.length > 0) {
						if (vm.repointList.length > 0) {
							for ( var i in vm.repointList) {
								vm.totalNetInc += vm.repointList[i].SYSTEM_AMT_LOCAL;
							}
						}
						if (vm.mappingList.length > 0) {
							for ( var j in vm.mappingList) {
								vm.totalNetInc += vm.mappingList[j].SYSTEM_AMT_LOCAL;
							}
						}
					}
					vm.change_total_variance();
				}
				vm.removeRepointAmountOne = function(index) {
					vm.changeColorVariance = false;
					vm.mainrowDirectDeleteflag = true;
					for ( var c in sendobj) {
						if (sendobj[c].target_combination_key == vm.mappingList[index].TARGET_COMBINATION_KEY
								&& sendobj[c].target_row_flag == 'M') {

							sendobj[c].typeOf = "Delete";
							vm.mainrowDirectDeleteflag = false;
							// call the Delete Stored Proc
							sendobj.splice(c, 1);

						}

					}
					for ( var d in sendobj) {
						if (sendobj[d].target_row_flag == 'V'
								&& sendobj[d].target_combination_key == vm.mappingList[index].TARGET_COMBINATION_KEY) {
							sendobj[d].typeOf = "Delete";
							vm.mainrowDirectDeleteflag = false;
							// call the Delete Stored Proc
							sendobj.splice(d, 1);

						}
					}

					if (vm.mainrowDirectDeleteflag === true) {

						var dummyJson = {};

						dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
						dummyJson["scenario"] = GlobalService.globalParams.scenario;
						dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
						dummyJson["source_combination_key"] = (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
								: source_combinationKeyS;
						dummyJson["target_combination_key"] = vm.mappingList[index].TARGET_COMBINATION_KEY;
						dummyJson["map_key"] = vm.mappingList[index].MAP_KEY;
						dummyJson["source_header_key"] = (rowData != undefined) ? rowData.SOURCE_HEADER_KEY
								: source_headerKeyS;
						// dummyJson["source_details_key"] =
						// rowData.SOURCE_DETAILS_KEY;
						dummyJson["target_header_key"] = vm.mappingList[index].TARGET_HEADER_KEY;
						dummyJson["target_row_flag"] = vm.mappingList[index].ROW_INDICATOR;
						dummyJson["target_chart_key"] = vm.mappingList[index].TARGET_CHART_KEY;
						dummyJson["target_acct_key_1"] = vm.mappingList[index].TARGET_ACCT_KEY;
						dummyJson["target_acct_key_2"] = vm.mappingList[index].VARIANCE_SUB_CATEFORY_KEY;
						dummyJson["target_amount"] = vm.mappingList[index].ALLOCATED_AMT;
						// dummyJson["target_reason"] =
						// vm.mappingList[index].ATTRIB_TEXT_VALUE;
						dummyJson["target_comments"] = vm.mappingList[index].COMMENTS;
						dummyJson["ROW_KEY"] = vm.mappingList[index].ROW_KEY;
						dummyJson["typeOf"] = "Delete";
						sendobj.push(dummyJson);

					}

					vm.mappingList.splice(index, 1);
					// vm.repointList.splice(index, 1);

					vm.netINC(index);

					vm.totalVarianceAllocateda = 0;
					vm.totalVariancea = 0;

					for (var j = 0; j < vm.mappingList.length; j++)

					{

						// vm.totalVariancea += vm.mappingList[j].VARIANCE;

						vm.tempInternalvariance = 0;
						// vm.mappingList[j].colorvaluevariance = "";
						for (var a = 0; a < vm.mappingList[j].variancedata.length; a++)

						{
							vm.tempInternalvariance += vm.mappingList[j].variancedata[a].VARIANCE_ALLOCATED;
							vm.totalVarianceAllocateda += vm.mappingList[j].variancedata[a].VARIANCE_ALLOCATED;
						}

					}
					vm.totalVariancea = vm.netIncPerLoss - vm.netIncRemain;
					vm.totalVarianceRemaina = vm.totalVariancea
							- vm.totalVarianceAllocateda;

					if (vm.totalVarianceRemaina > 0
							|| vm.totalVarianceRemaina < 0) {
						vm.changeColorVariance = true;
					}
				}

				/*
				 * vm.changeWWNiA = function(index) {
				 * 
				 * vm.totalAmt = 0; vm.totalVariance = 0;
				 * 
				 * vm.repointList[index].ADJ_NET_INC =
				 * vm.repointList[index].ALLOCATED_AMT +
				 * vm.repointList[index].ADJ_AMT; vm.repointList[index].VARIANCE =
				 * vm.repointList[index].ADJ_NET_INC -
				 * vm.repointList[index].SYSTEM_AMT_LOCAL;
				 * 
				 * 
				 * if(vm.repointList.length>0 ||vm.mappingList.length>0 ){
				 * 
				 * 
				 * if(vm.repointList.length>0){ for(var c in vm.repointList) {
				 * vm.totalAmt += vm.repointList[c].ADJ_AMT; vm.totalVariance +=
				 * vm.repointList[c].VARIANCE; } } if(vm.mappingList.length>0){
				 * for(var d in vm.mappingList) { vm.totalAmt +=
				 * vm.mappingList[d].ADJ_AMT; vm.totalVariance +=
				 * vm.mappingList[d].VARIANCE; } } }
				 * 
				 * vm.change_total_variance(); }
				 */

				/*
				 * vm.changeWWNiB = function(index) {
				 * 
				 * vm.totalAmt = 0; vm.totalVariance = 0;
				 * 
				 * vm.mappingList[index].ADJ_NET_INC =
				 * vm.mappingList[index].ALLOCATED_AMT +
				 * vm.mappingList[index].ADJ_AMT;
				 * 
				 * vm.mappingList[index].VARIANCE =
				 * vm.mappingList[index].ADJ_NET_INC -
				 * vm.mappingList[index].SYSTEM_AMT_LOCAL;
				 * 
				 * vm.change_total_variance(); }
				 */

				vm.netINC = function(index) {
					vm.changeColorwwni = false;
					// vm.saveSwapFlag = false;
					vm.netIncPerLoss = 0;
					vm.netIncRemain = 0;
					for (var q = 0; q < vm.mappingList.length; q++) {
						if (vm.mappingList[q].ALLOCATED_AMT == undefined
								|| vm.mappingList[q].ALLOCATED_AMT == NaN) {
							vm.mappingList[q].ALLOCATED_AMT = 0;
						}
						vm.netIncPerLoss += vm.mappingList[q].ALLOCATED_AMT;
						vm.netIncRemain += vm.mappingList[q].SYSTEM_AMT_USD;

					}

					for (var r = 0; r < vm.mappingList.length; r++) {

						if (r == index) {
							vm.mappingList[r].VARIANCE = vm.mappingList[r].ALLOCATED_AMT
									- vm.mappingList[r].SYSTEM_AMT_USD;
						}

					}

					// vm.netIncRemain = vm.sourceAmt - vm.netIncPerLoss;
					vm.totalVariancea = vm.netIncPerLoss - vm.netIncRemain;
					vm.totalVarianceRemaina = vm.totalVariancea
							- vm.totalVarianceAllocateda;
					if (vm.netIncRemain > 0 || vm.netIncRemain < 0) {
						vm.changeColorwwni = true;

					}

				}
				vm.applyButton = function(index) {
					vm.mappingList[index].ALLOCATED_AMT += vm.MAIN_SOURCE_AMT
							- vm.netIncPerLoss;
					-

					vm.netINC(index);

				};

				vm.applyVariance = function(index, repoint, repoint1) {
					vm.tempValueVariance = 0;
					for ( var g in vm.mappingList) {
						vm.tempValueVariance = 0;

						if (vm.mappingList[g].$$hashKey == repoint.$$hashKey) {
							for ( var h in vm.mappingList[g].variancedata) {

								vm.tempValueVariance += vm.mappingList[g].variancedata[h].VARIANCE_ALLOCATED;

							}
							for ( var t in vm.mappingList[g].variancedata) {
								if (vm.mappingList[g].variancedata[t].$$hashKey == repoint1.$$hashKey) {

									vm.mappingList[g].variancedata[t].VARIANCE_ALLOCATED += vm.mappingList[g].VARIANCE
											- vm.tempValueVariance;

								}
							}

						}
					}
					vm.changeInternalVar(index, repoint, repoint1);
				}

				vm.changeInternalVar = function($index, repoint, repoint1) {
					vm.totalVarianceAllocateda = 0;
					vm.changeColorVariance = false;
					// vm.saveSwapFlag = false;

					for (var i = 0; i < vm.mappingList.length; i++) {

						for ( var h in vm.mappingList[i].variancedata) {

							if (vm.mappingList[i].variancedata[h].VARIANCE_ALLOCATED == undefined
									|| vm.mappingList[i].variancedata[h].VARIANCE_ALLOCATED == NaN) {
								vm.mappingList[i].variancedata[h].VARIANCE_ALLOCATED = 0;
							}

							vm.totalVarianceAllocateda += vm.mappingList[i].variancedata[h].VARIANCE_ALLOCATED;
						}
					}

					vm.totalVarianceRemaina = vm.totalVariancea
							- vm.totalVarianceAllocateda;

					if (vm.totalVarianceRemaina > 0
							|| vm.totalVarianceRemaina < 0) {
						vm.changeColorVariance = true;

					}
					for (var j = 0; j < vm.mappingList.length; j++)

					{
						vm.tempInternalvariance = 0;
						vm.mappingList[j].colorvaluevariance = "";
						for (var a = 0; a < vm.mappingList[j].variancedata.length; a++)

						{
							vm.tempInternalvariance += vm.mappingList[j].variancedata[a].VARIANCE_ALLOCATED;

						}

						if (vm.mappingList[j].VARIANCE == vm.tempInternalvariance) {
							vm.mappingList[j].colorvaluevariance = "green";
							// $scope.showDetails(j,true);
						} else if (vm.mappingList[j].VARIANCE > 0
								|| vm.mappingList[j].VARIANCE < 0) {
							vm.mappingList[j].colorvaluevariance = "red";
							if (vm.tempInternalvariance > 0
									|| vm.tempInternalvariance < 0) {
								$scope.showDetails(j, true);
							}
						} else {
							vm.mappingList[j].colorvaluevariance = "gray";
						}

					}

				}

				vm.checkSaveErrors = function() {

					counter = 0;
					counterV = 0;
					for (var e = 0; e < vm.mappingList.length; e++) {
						if (vm.mappingList[e].TARGET_KEY == ""
								|| vm.mappingList[e].TARGET_KEY == undefined) {
							vm.mappingList[e].saveError = true;
							counter++
						} else {
							vm.mappingList[e].saveError = false;
						}
					}

					for (var sv = 0; sv < vm.mappingList.length; sv++) {
						for (var tv = 0; tv < vm.mappingList[sv].variancedata.length; tv++) {

							if (vm.mappingList[sv].variancedata[tv].VARIANCE_EXPL_CODE == ""
									|| vm.mappingList[sv].variancedata[tv].VARIANCE_EXPL_CODE == undefined) {

								if (vm.mappingList[sv].variancedata[tv].VARIANCE_ALLOCATED != 0) {
									vm.mappingList[sv].variancedata[tv].varianceError = true;
									counterV++;
								} else {
									vm.mappingList[sv].variancedata[tv].varianceError = false;
								}
							} else {
								vm.mappingList[sv].variancedata[tv].varianceError = false;
								/*
								 * if(vm.mappingList[sv].variancedata[tv].subcateDropdown !=
								 * undefined) {
								 * if(vm.mappingList[sv].variancedata[tv].subcateDropdown.length
								 * >1 &&
								 * vm.mappingList[sv].variancedata[tv].VARIANCE_SUB_CATEFORY == "" ) {
								 * counterV++; } } else{
								 * vm.mappingList[sv].variancedata[tv].varianceError =
								 * false; }
								 */
							}

						}
					}

				}

				/*
				 * vm.checkSaveErrorsVariance = function() {
				 * 
				 * var counterV = 0; for (var sv = 0; sv <
				 * vm.mappingList.length; sv++) { for (var tv = 0; tv <
				 * vm.mappingList[sv].variancedata.length; tv++) {
				 * 
				 * if (vm.mappingList[sv].variancedata[tv].VARIANCE_EXPL_CODE == "" ||
				 * vm.mappingList[sv].variancedata[tv].VARIANCE_EXPL_CODE ==
				 * undefined) {
				 * vm.mappingList[sv].variancedata[tv].varianceError = true;
				 * counterV++; } else{
				 * vm.mappingList[sv].variancedata[tv].varianceError = false; } } }
				 * if (counterV > 0) { AlertService.add("danger", "Please
				 * correct the errors below", 4000); $scope.crudLoading = false;
				 * throw new ("This is not an error. This is just to abort
				 * javascript!"); } }
				 */

				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}

					$scope.crudLoading = true;

					// vm.crudLoading = true;

					vm.checkSaveErrors();

					/*
					 * if (counterV > 0 || counter > 0) {
					 * AlertService.add("danger", "Please correct the errors
					 * below", 4000); $scope.crudLoading = false; throw new
					 * ("This is not an error. This is just to abort
					 * javascript!"); }
					 */

					if (counter > 0 || counterV > 0) {
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						$scope.crudLoading = false;
						throw new ("This is not an error. This is just to abort javascript!");
					}
					var dummyJson = {};
					// sendobj = [];
					for (var s = 0; s < vm.mappingList.length; s++) {
						dummyJson = {};
//						if (vm.mappingList[s].ALLOCATED_AMT != 0) { // Harika 4/28/2021 as we want to send 0 allocated amount record
							dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
							dummyJson["scenario"] = GlobalService.globalParams.scenario;
							dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
							dummyJson["source_combination_key"] = (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
									: source_combinationKeyS;
							dummyJson["target_combination_key"] = vm.mappingList[s].TARGET_COMBINATION_KEY;
							dummyJson["map_key"] = vm.mappingList[s].MAP_KEY;
							dummyJson["source_header_key"] = (rowData != undefined) ? rowData.SOURCE_HEADER_KEY
									: source_headerKeyS;
							// dummyJson["source_details_key"] =
							// rowData.SOURCE_DETAILS_KEY;
							dummyJson["target_header_key"] = vm.mappingList[s].TARGET_HEADER_KEY;
							dummyJson["target_row_flag"] = vm.mappingList[s].ROW_INDICATOR;
							dummyJson["target_chart_key"] = vm.mappingList[s].TARGET_CHART_KEY;
							dummyJson["target_acct_key_1"] = vm.mappingList[s].TARGET_ACCT_KEY;
							dummyJson["target_acct_key_2"] = vm.mappingList[s].VARIANCE_SUB_CATEFORY_KEY;
							dummyJson["target_amount"] = vm.mappingList[s].ALLOCATED_AMT;
							// dummyJson["target_reason"] =
							// vm.mappingList[s].ATTRIB_TEXT_VALUE;
							dummyJson["target_comments"] = vm.mappingList[s].COMMENTS;
							dummyJson["ROW_KEY"] = vm.mappingList[s].ROW_KEY;
							dummyJson["typeOf"] = "Edit";
							if (sendobj.length > 0) {
								var sendObjintermapping = false;
								for ( var c in sendobj) {
									if (sendobj[c].target_combination_key == vm.mappingList[s].TARGET_COMBINATION_KEY
											&& sendobj[c].target_row_flag == 'M') {
										sendobj[c] = dummyJson;
										sendObjintermapping = true;
									}

								}
								if (sendObjintermapping === false) {
									sendobj.push(dummyJson);
								}
							}
							if (sendobj.length == 0) {
								sendobj.push(dummyJson);

							}

							// sendobj.push(dummyJson);
//						}

					}
					for (var s = 0; s < vm.mappingList.length; s++) {
						for (var t = 0; t < vm.mappingList[s].variancedata.length; t++) {
							dummyJson = {};

							if (vm.mappingList[s].variancedata[t].VARIANCE_ALLOCATED != 0) {
								dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
								dummyJson["scenario"] = GlobalService.globalParams.scenario;
								dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
								dummyJson["source_combination_key"] = (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
										: source_combinationKeyS;
								dummyJson["target_combination_key"] = vm.mappingList[s].TARGET_COMBINATION_KEY;
								dummyJson["map_key"] = vm.mappingList[s].MAP_KEY;
								dummyJson["source_header_key"] = (rowData != undefined) ? rowData.SOURCE_HEADER_KEY
										: source_headerKeyS;
								// dummyJson["source_details_key"] =
								// rowData.SOURCE_DETAILS_KEY;
								dummyJson["target_header_key"] = vm.mappingList[s].TARGET_HEADER_KEY;
								dummyJson["target_row_flag"] = "V";

								for ( var j in vm.varianceSubcategory) {
									if (vm.varianceSubcategory[j].VARIANCE_EXPL_CODE == vm.mappingList[s].variancedata[t].VARIANCE_EXPL_CODE) {
										vm.mappingList[s].variancedata[t].VARIANCE_ACCT_KEY = vm.varianceSubcategory[j].VARIANCE_ACCT_KEY;
										// vm.mappingList[s].variancedata[t].VARIANCE_SUB_CATEFORY_KEY
										// =
										// vm.varianceSubcategory[j].VARIANCE_SUB_CATEFORY_KEY;
										vm.mappingList[s].variancedata[t].VARIANCE_CHART_KEY = vm.varianceSubcategory[j].VARIANCE_CHART_KEY;
									}
								}

								for ( var js in vm.varianceSubcategory) {
									if (vm.varianceSubcategory[js].VARIANCE_SUB_CATEFORY == vm.mappingList[s].variancedata[t].VARIANCE_SUB_CATEFORY) {

										vm.mappingList[s].variancedata[t].VARIANCE_SUB_CATEFORY_KEY = vm.varianceSubcategory[js].VARIANCE_SUB_CATEFORY_KEY;

									}
								}

								dummyJson["target_acct_key_1"] = vm.mappingList[s].variancedata[t].VARIANCE_ACCT_KEY;
								dummyJson["target_acct_key_2"] = vm.mappingList[s].variancedata[t].VARIANCE_SUB_CATEFORY_KEY;
								dummyJson["target_chart_key"] = vm.mappingList[s].variancedata[t].VARIANCE_CHART_KEY;
								dummyJson["target_amount"] = vm.mappingList[s].variancedata[t].VARIANCE_ALLOCATED;
								// dummyJson["target_reason"] =
								// vm.mappingList[s].ATTRIB_TEXT_VALUE;
								dummyJson["target_comments"] = vm.mappingList[s].variancedata[t].VARIANCE_COMMENTS;
								dummyJson["ROW_KEY"] = vm.mappingList[s].variancedata[t].ROW_KEY;
								dummyJson["typeOf"] = "Edit";
								if (sendobj.length > 0) {
									// var sendObjintermapping = false;
									/*
									 * for ( var c in sendobj) { if
									 * (sendobj[c].ROW_KEY ==
									 * vm.mappingList[s].variancedata[t].ROW_KEY &&
									 * sendobj[c].target_row_flag == 'V' &&
									 * sendobj[c].target_combination_key ==
									 * vm.mappingList[s].TARGET_COMBINATION_KEY) {
									 * sendobj[c] = dummyJson;
									 * sendObjintermapping = true; } }
									 */
									// if (sendObjintermapping === false) {
									sendobj.push(dummyJson);
									// }
								}
								/*
								 * if (sendobj.length == 0) {
								 * sendobj.push(dummyJson); }
								 */
							}
						}

					}
					// sendobj.push(dummyJson);

					WWniGroupObjFactory
							.saveProjectTagW(sendobj, sendobj)
							.then(
									function(result) {

										if (result.callSuccess !== "1") {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoading = false;

										} else {
											AlertService
													.add(
															"success",
															"Data has been saved successfully ",
															4000);
											$uibModalInstance.dismiss('cancel');

											$timeout(function() {

												var args = {
													refresh_cc_key : (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
															: gridData[0].data.SOURCE_COMBINATION_KEY,

													gridFilter : {
														refresh_cc_key : (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
																: gridData[0].data.SOURCE_COMBINATION_KEY,

													}
												};
												$rootScope.$emit('gridUpdate',
														args);
											});

										}
									});
				}

				vm.addRepointOne = function() {
					$timeout(function() {

						vm.mappingList.push({
							ADJ_AMT : "",
							ADJ_NET_INC : "",
							DEFAULT_FLAG : "",
							EXCHANGE_RATE : "",
							SYSTEM_AMT_LOCAL : "",
							VARIANCE : "",
							COMMENTS : "",
							ALLOCATED_AMT : "",
							ROW_KEY : "",

						});

					});
				}

				vm.varianceAdd = function(index) {
					$timeout(function() {

						vm.mappingList[index].variancedata.push({
							VARIANCE_ALLOCATED : 0,
							VARIANCE_EXPL_CODE : "",
							VARIANCE_SUB_CATEFORY : "",
							VARIANCE_SUB_CATEFORY_KEY : "",
							VARIANCE_EXPL_CODE_KEY : "",
							VARIANCE_COMMENTS : "",
							variance_Dropdown_Flag : false,

						});

					});
				}
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				init();

				vm.reset = function() {
					getWWNImodalData();

				}

				// 4bjt2k

				// getWWNImodalData();

				function getWWNImodalData(index) {
					if (index == undefined) {
						index = 0;
					}
					var params = {
						"action_code" : '4bjt2k',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						'leid' : (rowData != undefined) ? rowData.SOURCE_LEID
								: gridData[index].data.SOURCE_LEID,

						'cdr' : (rowData != undefined) ? rowData.SOURCE_CDR_NO
								: gridData[index].data.SOURCE_CDR_NO,
						'me' : (rowData != undefined) ? rowData.SOURCE_ME_CODE
								: gridData[index].data.SOURCE_ME_CODE,
						'cc_key' : (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
								: gridData[index].data.SOURCE_COMBINATION_KEY,

					}

					vm.loading = false;
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {
											vm.modalData = [];
											vm.mappingList = [];
											vm.displayNdata = [];
											vm.displayYdata = [];
											vm.totalVariancea = 0;
											vm.totalVarianceAllocateda = 0;
											vm.totalVarianceRemaina = 0;
											vm.totalNetInc = 0;

											vm.netIncPerLoss = 0;
											vm.netIncRemain = 0;
											vm.changeColorwwni = false;
											vm.changeColorVariance = false;

											vm.saveSwapvariance = false;
											vm.saveSwapnetInc = false;
											// vm.saveSwapFlag = true;

											vm.testData = [];
											var testObj = {};

											vm.modalData = data.jsonObject;
											for ( var c in vm.tempListSwap) {
												if (vm.tempListSwap[c].sourceCombikey == params.cc_key) {
													vm.modalData = [];
													vm.modalData = vm.tempListSwap[c];
												}
												// delete
												// vm.modalData.sourceCombikey;
											}

											for ( var a in vm.modalData) {

												if (vm.modalData[a].DEFAULT_FLAG == "N") {

													vm.displayNdata
															.push(vm.modalData[a]);

												} else {
													vm.displayYdata
															.push(vm.modalData[a]);

												}
											}

											// vm.repointList = vm.displayYdata;

											for ( var g in vm.displayYdata) {
												if (vm.displayYdata[g].ROW_INDICATOR == "M") {
													vm.mappingList
															.push(vm.displayYdata[g]);
												}
											}

											// vm.mappingList = vm.displayYdata;

											vm.finallisttest = vm.mappingList;
											vm.finallisttest1 = [];
											for ( var j in vm.mappingList) {
												vm.finallisttest1 = [];
												for (var k = 0; k < data.jsonObject.length; k++) {
													testObj = {};
													if (vm.mappingList[j].TARGET_COMBINATION_KEY == data.jsonObject[k].TARGET_COMBINATION_KEY
															&& data.jsonObject[k].ROW_INDICATOR == "V") {
														testObj["TARGET_KEY"] = (data.jsonObject[k].TARGET_COMBINATION_KEY);
														// testObj["VARIANCE"] =
														// (data.jsonObject[k].VARIANCE);
														testObj["VARIANCE_ALLOCATED"] = (data.jsonObject[k].VARIANCE_ALLOCATED === undefined) ? 0
																: data.jsonObject[k].VARIANCE_ALLOCATED;
														testObj["VARIANCE_EXPL_CODE"] = (data.jsonObject[k].VARIANCE_EXPL_CODE === undefined) ? " "
																: data.jsonObject[k].VARIANCE_EXPL_CODE;
														testObj["VARIANCE_SUB_CATEFORY"] = (data.jsonObject[k].VARIANCE_SUB_CATEFORY === undefined) ? ""
																: data.jsonObject[k].VARIANCE_SUB_CATEFORY;

														testObj["ROW_KEY"] = (data.jsonObject[k].ROW_KEY === undefined) ? 0
																: data.jsonObject[k].ROW_KEY;
														testObj["VARIANCE_COMMENTS"] = (data.jsonObject[k].VARIANCE_COMMENTS === undefined) ? " "
																: data.jsonObject[k].VARIANCE_COMMENTS;

														testObj["variance_Dropdown_Flag"] = (data.jsonObject[k].VARIANCE_SUB_CATEFORY === undefined) ? false
																: true;

														vm.finallisttest1
																.push(testObj);

														for ( var a in vm.finallisttest1) {
															vm.sub_category_dropdown = [];
															if (data.jsonObject[k].VARIANCE_SUB_CATEFORY != undefined
																	|| data.jsonObject[k].VARIANCE_SUB_CATEFORY != "") {
																for ( var o in vm.varianceSubcategory) {
																	if (vm.varianceSubcategory[o].VARIANCE_EXPL_CODE == data.jsonObject[k].VARIANCE_EXPL_CODE) {
																		vm.sub_category_dropdown
																				.push(vm.varianceSubcategory[o].VARIANCE_SUB_CATEFORY);
																	}
																}
															}
															vm.sub_category_dropdown = _
																	.uniq(
																			vm.sub_category_dropdown,
																			JSON.stringify)
															vm.finallisttest1[a].subcateDropdown = vm.sub_category_dropdown;
														}

													}

													if (k == data.jsonObject.length - 1) {
														vm.mappingList[j].variancedata = vm.finallisttest1;
													}
												}

											}

											// for ( var j in vm.mappingList)
											//
											// {
											// vm.mappingList[j].colorvaluevariance
											// = "";
											//
											// if (vm.mappingList[j].VARIANCE >
											// 0
											// || vm.mappingList[j].VARIANCE <
											// 0) {
											// vm.mappingList[j].colorvaluevariance
											// = true;
											// }
											//
											// }
											//											
											// colorGreen

											for (var j = 0; j < vm.mappingList.length; j++)

											{
												vm.tempInternalvariance = 0;
												vm.mappingList[j].colorvaluevariance = "";
												for ( var a in vm.mappingList[j].variancedata)

												{
													vm.tempInternalvariance += vm.mappingList[j].variancedata[a].VARIANCE_ALLOCATED;

												}

												if (vm.mappingList[j].VARIANCE == vm.tempInternalvariance) {
													vm.mappingList[j].colorvaluevariance = "green";
													$scope.showDetails(j, true);
												} else if (vm.mappingList[j].VARIANCE > 0
														|| vm.mappingList[j].VARIANCE < 0) {
													vm.mappingList[j].colorvaluevariance = "red";
													if (vm.tempInternalvariance > 0
															|| vm.tempInternalvariance < 0) {
														$scope.showDetails(j,
																true);
													}
												} else {
													vm.mappingList[j].colorvaluevariance = "gray";
												}

											}

											for ( var p in vm.displayNdata) {
												vm.sysLeidDropdownNdata
														.push(vm.displayNdata[p].TARGET_KEY);
											}
											vm.sysLeidDropdownNdata = _.uniq(
													vm.sysLeidDropdownNdata,
													JSON.stringify)

											for ( var b in vm.displayYdata) {
												vm.sysLeidDropdown
														.push(vm.displayYdata[b].TARGET_KEY);
											}
											vm.sysLeidDropdown = _.uniq(
													vm.sysLeidDropdown,
													JSON.stringify)

										}

										// testOne row

										for (var i = 0; i < vm.mappingList.length; i++) {
											if (vm.mappingList[i].variancedata.length == 0) {
												vm.varianceAdd(i);
											}
										}
										if (vm.mappingList.length == 0) {
											vm.addRepointVariance();
										}

										for (var i = 0; i < vm.mappingList.length; i++) {
											// vm.totalNetInc +=
											// vm.repointList[i].SYSTEM_AMT_LOCAL;
											// vm.totalVariancea +=
											// vm.mappingList[i].VARIANCE;
											vm.netIncPerLoss += vm.mappingList[i].ALLOCATED_AMT;
											vm.netIncRemain += vm.mappingList[i].SYSTEM_AMT_USD;
											vm.totalVariancea = vm.netIncPerLoss
													- vm.netIncRemain;
											for (var h = 0; h < vm.mappingList[i].variancedata.length; h++) {

												vm.totalVarianceAllocateda += vm.mappingList[i].variancedata[h].VARIANCE_ALLOCATED;
											}
										}

										// vm.netIncRemain = vm.sourceAmt -
										// vm.netIncPerLoss;
										vm.totalVarianceRemaina = vm.totalVariancea
												- vm.totalVarianceAllocateda;
										vm.loading = true;

										if (vm.netIncRemain > 0
												|| vm.netIncRemain < 0) {
											vm.changeColorwwni = true;

										}
										if (vm.totalVarianceRemaina > 0
												|| vm.totalVarianceRemaina < 0) {
											vm.changeColorVariance = true;

										}

									})
				}

				getVariancesubCategory()
				function getVariancesubCategory() {
					var params = {
						"action_code" : 'z5j10g',
						"tax_year": GlobalService.globalParams.tax_year

					}
					vm.loading = false;
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.varianceSubcategory = data.jsonObject;

											for ( var c in vm.varianceSubcategory)

											{
												vm.expla_code_dropdown
														.push(vm.varianceSubcategory[c].VARIANCE_EXPL_CODE);
												// vm.sub_category_dropdown.push(vm.varianceSubcategory[c].VARIANCE_SUB_CATEFORY);
											}
											vm.expla_code_dropdown = _.uniq(
													vm.expla_code_dropdown,
													JSON.stringify)
											// vm.sub_category_dropdown =
											// _.uniq(vm.sub_category_dropdown,JSON.stringify)

										}

									})
				}
				getReason()
				function getReason() {
					var params = {
						"action_code" : 'lsmb5n',

					}
					vm.loading = false;
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.reasonData = data.jsonObject;

											for ( var c in vm.reasonData)

											{
												vm.reasonData_dropdown
														.push(vm.reasonData[c].VARIANCE_REASON);
												// vm.sub_category_dropdown.push(vm.varianceSubcategory[c].VARIANCE_SUB_CATEFORY);
											}
											vm.reasonData_dropdown = _.uniq(
													vm.reasonData_dropdown,
													JSON.stringify)
											// vm.sub_category_dropdown =
											// _.uniq(vm.sub_category_dropdown,JSON.stringify)

										}

									})
				}

				vm.auditCall
				{
					// euekku

					vm.auditData = [];
					$scope.crudLoadingaudit = true;
					getAuditData()
					function getAuditData() {
						var params = {
							"action_code" : 'euekku',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"source_cc_key" : (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
									: gridData[index].data.SOURCE_COMBINATION_KEY,
						}
						$scope.crudLoadingaudit = true;
						vm.noRecords = false
						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {

											if (data.errorMessage.length > 0
													&& data.errorMessage !== 'null') {
												AlertService.add("error",
														data.errorMessage);
												return false;
											} else {

												vm.auditData = data.jsonObject;

												// Checking to see if any data
												// was returned
												if (vm.auditData
														&& vm.auditData.length) {
													vm.noRecords = false;
												} else {
													vm.noRecords = true;
												}

												if (!vm.noRecords) {
													vm.columnNames = Object
															.keys(vm.auditData[0]);

													// Moving "Updated On"
													// column to the beginning
													var updated_on = vm.columnNames
															.pop();
													vm.columnNames
															.unshift(updated_on);

													// Initializing things for
													// sorting in Audit tab
													vm.column = vm.columnNames[0];
													vm.reverse;
													vm.sort_key = {};
													for (var i = 1; i < 9; i++) {
														vm.sort_key[i] = 0;
													}
													// Function to handle
													// sorting columns in Audit
													// tab
													// @param colNum --> The
													// number of the column
													// which was clicked
													// @param sortKey --> The
													// sort key which was passed
													// from the HTML
													vm.sortBy = function(
															colNum, sortKey) {
														vm.column = vm.columnNames[colNum - 1];
														if (sortKey === 0
																|| sortKey === 2) {
															vm.sort_key[colNum] = 1;
															vm.reverse = false;
														} else if (sortKey === 1) {
															vm.sort_key[colNum] = 2;
															vm.reverse = true;
														}
														for (var i = 1; i < 9; i++) {
															if (i === Number(colNum)) {
																continue;
															} else {
																vm.sort_key[i] = 0;
															}
														}
													}
												}

												$scope.crudLoadingaudit = false;
												if (vm.auditData.length == 0) {
													vm.noRecords = true;
												}

											}

										})
					}
				}
			}
			return controllers;

		});
