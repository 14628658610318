
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.ReportableTransactionService',[])
    .factory('ReportableTransactionFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        
    		var factory = {};
    		factory.formConfigData = {};
    		factory.finalRepTransaction = [];
    		factory.getReportableTransactionTypes=function(data, sso_id){
    			var jcd_key = 0;
        		if(data.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = data.JCD_KEY;
        		var params = { "action_code": 'w1ubsf', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO_KEY, "jcd_key": jcd_key};
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.getReportableTransReasonTypes=function(data, sso_id){
    			var jcd_key = 0;
        		if(data.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = data.JCD_KEY;
        		var params = { "action_code": 'hg67p4', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO_KEY, "jcd_key": jcd_key};
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.getLocalAccountList=function(data, sso_id, rep_trans_id){
    			var jcd_key = 0;
        		if(data.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = data.JCD_KEY;
        		var params = { "action_code": 'wj7wci', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO_KEY, "jcd_key": jcd_key, "flc_code": 'CGNI', "chart_key": '500386317', "combination_key": data.COMBINATION_KEY, "rep_trans_id": rep_trans_id};//Need to pull chart_key from rowData after implementing Entity_type
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.getLocalAccountAmtData=function(data, sso_id, accountSelected, rep_trans_id){
    			var jcd_key = 0;
        		if(data.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = data.JCD_KEY;
        		var params = { "action_code": 'agsirv', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO_KEY, "jcd_key": jcd_key, "cc_key": data.COMBINATION_KEY, "gl_acct_key": accountSelected, "trans_type": 'SCHDD_REPORTABLE_TRANS', "rep_trans_id": rep_trans_id, "issue_key":data.ISSUE_KEY};
        		console.log("Now in the Servie before action call the params was ................:::::", params);
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.getPriorReportableAdjustment=function(data, rep_trans_id, sso_id){
    			var jcd_key = 0;
        		if(data.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = data.JCD_KEY;
        		var params = { "action_code": '194d7r', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO_KEY, "jcd_key": jcd_key, "combination_key": data.COMBINATION_KEY, "rep_source": 'SCHDD_REPORTABLE_TRANS', "rep_trans_id": rep_trans_id,"issue_key":data.ISSUE_KEY};
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.getRepTransaction=function(data, rep_trans_id, sso_id){
    			var jcd_key = 0;
        		if(data.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = data.JCD_KEY;
        		var params = { "action_code": '1cro7n', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO_KEY, "jcd_key": jcd_key, "combination_key": data.COMBINATION_KEY, "rep_source": 'SCHDD_REPORTABLE_TRANS', "rep_trans_id": rep_trans_id, "issue_key":data.ISSUE_KEY};
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.getRepTransHeader=function(dataRow, rowData, rep_trans_id, sso_id){
    			var jcd_key = 0;
        		if(rowData.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = rowData.JCD_KEY;
        		var params = { "action_code": '5z0g9r', "sso_id": sso_id, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO_KEY, "jcd_key": jcd_key, "combination_key": rowData.COMBINATION_KEY, "rep_source": 'SCHDD_REPORTABLE_TRANS', "rep_trans_id": rep_trans_id, "schdd_detail_key": dataRow.Trans_details_key, "schdd_occurrence": dataRow.Occurrence, "issue_key":rowData.ISSUE_KEY};
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.getRepTransDetails=function(dataRow, rowData, rep_trans_id, sso_id){
    			var jcd_key = 0;
        		if(rowData.JCD_KEY == undefined){
        			jcd_key = 250;
        		}else jcd_key = rowData.JCD_KEY;
        		var params = { "action_code": 'bpp2tl', "sso_id": sso_id, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO_KEY, "jcd_key": jcd_key, "combination_key": rowData.COMBINATION_KEY, "rep_source": 'SCHDD_ADJ_REP_TRANS', "rep_trans_id": rep_trans_id, "schdd_detail_key": dataRow.Trans_details_key, "schdd_occurrence": dataRow.Occurrence, "issue_key":rowData.ISSUE_KEY};
        		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        			console.log("Now in the Servie after action call the data was ................:::::", data);
        			if(null != data.callSuccess && data.callSuccess == "1"){
        				factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
        			}else{
        				AlertService.add("error", data.errorMessage);
                        return false;
        			}
        		});
            };
            
            factory.saveSchddReportableTransaction=function(rowdata, sso_id, repTransHeader, repTransDetails, repTransId, screen_key){
        		var repTransHeaderarr = []
        		repTransHeaderarr.push(repTransHeader);
        		console.log("trans_header data was done as:::::::::------------", repTransHeaderarr);
        		console.log("trans_details data was done as:::::::::------------", repTransDetails);
        		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vlae0g";
        		var json_settings = JSON.stringify(repTransDetails);
        		var json_data = JSON.stringify(repTransHeaderarr);
        		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

        		
        		var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({json_data:json_data}, params);
                params =  _.extend({json_settings:json_settings}, params);
                params.process_name =  "Schedule-D-Reportable-Transaction-save";
                params.rep_trans_id = repTransId;
                params.screen_key = screen_key;
                params.combination_key = rowdata.COMBINATION_KEY;
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
        	}
            
            factory.sendFinalRepData=function(repTransData){
            	factory.finalRepTransaction = repTransData;
            	return repTransData;
            };
        
         return factory;
        
    }]);

    return services;

});