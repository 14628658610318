define([
    'angular',
    '../../adminDashboardCtrl'

], function () {
    'use strict';

    angular.module('app.adminDashboardCtrl')
        .controller("chartSettingsCtrl", ["$scope", "dataFormatterFactory", "strategyFactory", "GENERAL_CONFIG", "JsonObjectFactory", chartSettingsCtrl])

    function chartSettingsCtrl($scope, dataFormatterFactory, strategyFactory, GENERAL_CONFIG, JsonObjectFactory) {
        $scope.formatters = dataFormatterFactory.getAllFormatters();
        $scope.safeFormatCopy = {};
        $scope.$on('component-object:loaded', function (e, component) {
            // $scope.component = component;
            $scope.componentSettings = angular.copy(component.extraOptions.componentSettings);

            //TODO: check if all the strategy inputs are selected...
            var componentData = strategyFactory.runStrategy(component.strategy, component.previewSheetData.data, component.extraOptions.strategySettings);

            if ($scope.componentSettings.dataOverrides &&
                $scope.componentSettings.dataOverrides.series) {
                _.each(componentData.series, function (series) {
                    var editedSeries = _.find($scope.componentSettings.dataOverrides.series, ['name', series.name]);
                    if (editedSeries) {
                        series.color = editedSeries.color || series.color;
                        series.displayName = editedSeries.displayName || series.name;
                        delete series.data;
                    }
                });
            }

            //only add those properties that are needed to be overriden
            $scope.componentSettings.dataOverrides = { series: componentData.series };



        })

        $scope.initChart = function () {
            if ($scope.componentSettings.chart.type === "waterfall") {

                _.set(
                    $scope.componentSettings, 'plotOptions.series.color',
                    _.get($scope.componentSettings, 'plotOptions.series.color', Highcharts.getOptions().colors[1])
                );
                _.set(
                    $scope.componentSettings, 'plotOptions.series.upColor',
                    _.get($scope.componentSettings, 'plotOptions.series.upColor', Highcharts.getOptions().colors[0])
                );
            }
        }

        $scope.updateComponent = function () {
            $scope.$emit('component-object:changed', $scope.componentSettings);
        }
        $scope.setHighchartFormatting = function (key, defaultValue) {
            var labelObj = _.get($scope.componentSettings, key);
            $scope.safeFormatCopy[key] = labelObj.format;

            labelObj.formatter && labelObj.formatter !== 'None' ?
                labelObj.format = undefined :
                labelObj.format = $scope.safeFormatCopy[key] || defaultValue;
        }

        $scope.formatChanged = function (key, defaultValue) {
            $scope.setHighchartFormatting(key, defaultValue);
            $scope.updateComponent();
        }
    }

})