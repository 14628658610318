define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.schedule-JService',[])
       .factory("scheduleJGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'

    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http,
		           $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var scheduleJGroupObjFactory ={};
	           scheduleJGroupObjFactory.url ={};
	           scheduleJGroupObjFactory.url.save_schedule_J = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=34euhn";
	           scheduleJGroupObjFactory.url.updateFlag = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0wcwam";

	           scheduleJGroupObjFactory.saveScheduleJ = function(_data,Details){

	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "Schedule_J_Save";
	           var promise = $http({
	        	   method: "post",
	               url: scheduleJGroupObjFactory.url.save_schedule_J,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });

	           return promise;
           };

           scheduleJGroupObjFactory.updateFlag = function(_data,Details){

	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	           if( GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined){
	        	   params.jcd_key = "250";
	           }
	           console.log("params-------------", params);

	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "Schedule_J_updateFlag";
	           var promise = $http({
	        	   method: "post",
	               url: scheduleJGroupObjFactory.url.updateFlag,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });

	           return promise;
           };

return scheduleJGroupObjFactory;
       }]);

   return services;
});