define([
    'angular'
], function () {
    'use strict';
    
    var controllers = angular.module('app.firLocalSysChartAcctMapController', []);
    controllers.controller('firLocalSysChartAcctMapCtrl', ['GlobalService','$scope', '$rootScope', 'JsonObjectFactory', 'FirLocalSysChartAcctMapcGroupObjFactory',  '$parse', '$timeout', 'workspaceFactory', '$state', '$window',
			   'AlertService', 'GENERAL_CONFIG', 'ModalFactory', '$uibModalInstance', 'rowData', '$filter', 'colData', 'gridData',firLocalSysChartAcctMapCtrl])
			   
    var firLookupData = [];
	var firLookupData1 = [];
	var firLookupData2 = [];
	var firLookupData3 = [];
	var firLookupData4 = [];
	
	function format(value, replace) {
				if (!value) {
					return value;
				}
				var target = value.toString();
				if (replace === undefined) {
					return target;
				}
				if (!angular.isArray(replace) && !angular.isObject(replace)) {
					return target.split('$0').join(replace);
				}
				var token = (angular.isArray(replace) && '$') || ':';

				angular.forEach(replace, function(value, key) {
					target = target.split(token + key).join(value);
				});
				return target;
			}

     controllers.value('customFirSelectDefaultsLocalSystem', {
				displayText : 'Select...',
				emptyListText : 'There are no items to display',
				emptySearchResultText : 'No results match "$0"',
				addText : 'Add',
				searchDelay : 300,
			});

			controllers
					.directive(
							'customFirSelectLocalSystem',
							[
									'$parse',
									'$compile',
									'$timeout',
									'$q',
									'customFirSelectDefaultsLocalSystem',
									function($parse, $compile, $timeout, $q,
											baseOptions) {
										var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

										return {
											restrict : 'A',
											require : 'ngModel',
											link : function(scope, elem, attrs,
													controller) {
												var customSelect = attrs.customFirSelectLocalSystem;
												if (!customSelect) {
													throw new ('Expected custom-select attribute value.');
												}
												scope
														.$watch(
																"ctrl.fir_sys_acc.SYS_ACCT.SYS_ACCT",
																function() {
																	// scope.getChartAcctTableData();
																	scope
																			.$emit(
																					'SYS_ACCT_CHANGED',
																					'');
																});
												var match = customSelect
														.match(CS_OPTIONS_REGEXP);

												if (!match) {
													throw new ('Expected expression in form of '
															+ "'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'"
															+ " but got '"
															+ customSelect + "'.");
												}

												elem
														.addClass('dropdown custom-select');

												// Ng-Options break down
												var displayFn = $parse(match[2]
														|| match[1]), valueName = match[3], valueFn = $parse(match[2] ? match[1]
														: valueName), values = match[4], valuesFn = $parse(values), track = match[5], trackByExpr = track ? ' track by '
														+ track
														: '', dependsOn = attrs.csDependsOn;

												var options = getOptions(), timeoutHandle, lastSearch = '', focusedIndex = -1, matchMap = {};

												var itemTemplate = elem.html()
														.trim()
														|| '{{'
														+ (match[2] || match[1])
														+ '}}', dropdownTemplate = '<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">'
														+ '<span>{{displayText}}</span>'
														+ '<b></b>'
														+ '</a>'
														+ '<div class="dropdown-menu">'
														+ '<div stop-propagation="click" class="custom-select-search">'
														+ '<input class="'
														+ attrs.selectClass
														+ '" type="text" autocomplete="off" ng-model="searchTerm" />'
														+ '</div>'
														+ '<ul role="menu">'
														+ '<li role="presentation" ng-repeat="'
														+ valueName
														+ ' in matches'
														+ trackByExpr
														+ '">'
														+ '<a role="menuitem" tabindex="-1" href ng-click="select('
														+ valueName
														+ ')">'
														+ itemTemplate
														+ '</a>'
														+ '</li>'
														+ '<li ng-hide="matches.length" class="empty-result" stop-propagation="click">'
														+ '<em class="muted">'
														+ '<span ng-hide="searchTerm">{{emptyListText}}</span>'
														+ '<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>'
														+ '</em>'
														+ '</li>'
														+ '</ul>'
														+ '<div class="custom-select-action">'
														+ (typeof options.onAdd === 'function' ? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
																: '')
														+ '</div>' + '</div>';

												// Clear element contents
												elem.empty();

												// Create dropdown element
												var dropdownElement = angular
														.element(dropdownTemplate), anchorElement = dropdownElement
														.eq(0).dropdown(), inputElement = dropdownElement
														.eq(1).find(':text'), ulElement = dropdownElement
														.eq(1).find('ul');

												// Create child scope for input
												// and dropdown
												var childScope = scope
														.$new(true);
												configChildScope();

												// Click event handler to set
												// initial values and focus when
												// the dropdown is shown
												anchorElement
														.on(
																'click',
																function(event) {
																	if (childScope.disabled) {
																		return;
																	}
																	childScope
																			.$apply(function() {
																				lastSearch = '';
																				childScope.searchTerm = '';
																			});

																	focusedIndex = -1;
																	inputElement
																			.focus();

																	// If filter
																	// is not
																	// async,
																	// perform
																	// search in
																	// case
																	// model
																	// changed
																	if (!options.async) {
																		getMatches('');
																	}
																});

												if (dependsOn) {
													scope
															.$watch(
																	dependsOn,
																	function(
																			newVal,
																			oldVal) {
																		if (newVal !== oldVal) {
																			childScope.matches = [];
																			childScope
																					.select(undefined);
																		}
																	});
												}

												// Event handler for key press
												// (when the user types a
												// character while focus is on
												// the anchor element)
												anchorElement
														.on(
																'keypress',
																function(event) {
																	if (!(event.altKey || event.ctrlKey)) {
																		anchorElement
																				.click();
																	}
																});

												// Event handler for Esc, Enter,
												// Tab and Down keys on input
												// search
												inputElement
														.on(
																'keydown',
																function(event) {
																	if (!/(13|27|40|^9$)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	switch (event.keyCode) {
																	case 27: // Esc
																		anchorElement
																				.dropdown('toggle');
																		break;
																	case 13: // Enter
																		selectFromInput();
																		break;
																	case 40: // Down
																		focusFirst();
																		break;
																	case 9: // Tab
																		anchorElement
																				.dropdown('toggle');
																		break;
																	}
																});

												// Event handler for Up and Down
												// keys on dropdown menu
												ulElement
														.on(
																'keydown',
																function(event) {
																	if (!/(38|40)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	var items = ulElement
																			.find('li > a');

																	if (!items.length)
																		return;
																	if (event.keyCode == 38)
																		focusedIndex--; // up
																	if (event.keyCode == 40
																			&& focusedIndex < items.length - 1)
																		focusedIndex++; // down
																	if (focusedIndex >= 0) {
																		items
																				.eq(
																						focusedIndex)
																				.focus();
																	} else {
																		focusedIndex = -1;
																		inputElement
																				.focus();
																	}
																});

												resetMatches();

												// Compile template against
												// child scope
												$compile(dropdownElement)(
														childScope);
												elem.append(dropdownElement);

												// When model changes outside of
												// the control, update the
												// display text
												controller.$render = function() {
													setDisplayText();
												};

												// Watch for changes in the
												// default display text
												childScope.$watch(
														getDisplayText,
														setDisplayText);

												childScope
														.$watch(
																function() {
																	return elem
																			.attr('disabled');
																},
																function(value) {
																	childScope.disabled = value;
																});

												childScope
														.$watch(
																'searchTerm',
																function(
																		newValue) {
																	if (timeoutHandle) {
																		$timeout
																				.cancel(timeoutHandle);
																	}

																	var term = (newValue || '')
																			.trim();
																	timeoutHandle = $timeout(
																			function() {
																				getMatches(term);
																			},
																			// If
																			// empty
																			// string,
																			// do
																			// not
																			// delay
																			(term && options.searchDelay) || 0);
																});

												// Support for autofocus
												if ('autofocus' in attrs) {
													anchorElement.focus();
												}

												var needsDisplayText;
												function setDisplayText() {
													var locals = {};
													locals[valueName] = controller.$modelValue;
													var text = displayFn(scope,
															locals);
													if (text === undefined) {
														var map = matchMap[hashKey(controller.$modelValue)];
														if (map) {
															text = map.label;
														}
													}

													needsDisplayText = !text;
													childScope.displayText = text
															|| controller.$modelValue // text
												}

												function getOptions() {
													return angular
															.extend(
																	{},
																	baseOptions,
																	scope
																			.$eval(attrs.customSelectOptionsLocalSystem));
												}

												function getDisplayText() {
													options = getOptions();

													return options.displayText;
												}

												function focusFirst() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														focusedIndex = 0;
														opts.eq(0).focus();
													}
												}

												// Selects the first element on
												// the list when the user
												// presses Enter inside the
												// search input
												function selectFromInput() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														var ngRepeatItem = opts
																.eq(0).scope();
														var item = ngRepeatItem[valueName];
														childScope
																.$apply(function() {
																	childScope
																			.select(item);
																});
														anchorElement
																.dropdown('toggle');
													}
												}

												function getMatches(searchTerm) {
													var locals = {
														$searchTerm : searchTerm
													};
													$q
															.when(
																	valuesFn(
																			scope,
																			locals))
															.then(
																	function(
																			matches) {
																		if (!matches)
																			return;

																		if (searchTerm === inputElement
																				.val()
																				.trim() /*
																		 * &&
																		 * hasFocus
																		 */) {
																			matchMap = {};
																			childScope.matches.length = 0;
																			for (var i = 0; i < matches.length; i++) {
																				locals[valueName] = matches[i];
																				var value = valueFn(
																						scope,
																						locals), label = displayFn(
																						scope,
																						locals);

																				matchMap[hashKey(value)] = {
																					value : value,
																					label : label,
																				};

																				childScope.matches
																						.push(matches[i]);
																			}
																		}

																		if (needsDisplayText)
																			setDisplayText();
																	},
																	function() {
																		resetMatches();
																	});
												}

												function resetMatches() {
													childScope.matches = [];
													focusedIndex = -1;
												}

												function configChildScope() {
													childScope.addText = options.addText;
													childScope.emptySearchResultText = options.emptySearchResultText;
													childScope.emptyListText = options.emptyListText;

													childScope.select = function(
															item) {
														var locals = {};
														locals[valueName] = item;
														var value = valueFn(
																childScope,
																locals);
														childScope.displayText = displayFn(
																childScope,
																locals)
																|| options.displayText;
														controller
																.$setViewValue(value);

														anchorElement.focus();

														typeof options.onSelect === 'function'
																&& options
																		.onSelect(item);
													};

													childScope.add = function() {
														$q
																.when(
																		options
																				.onAdd(),
																		function(
																				item) {
																			if (!item)
																				return;

																			var locals = {};
																			locals[valueName] = item;
																			var value = valueFn(
																					scope,
																					locals), label = displayFn(
																					scope,
																					locals);

																			matchMap[hashKey(value)] = {
																				value : value,
																				label : label,
																			};

																			childScope.matches
																					.push(item);
																			childScope
																					.select(item);
																		});
													};

													childScope.format = format;

													setDisplayText();
												}

												var current = 0;
												function hashKey(obj) {
													if (obj === undefined)
														return 'undefined';

													var objType = typeof obj, key;

													if (objType == 'object'
															&& obj !== null) {
														if (typeof (key = obj.$$hashKey) == 'function') {
															// must invoke on
															// object to keep
															// the right this
															key = obj
																	.$$hashKey();
														} else if (key === undefined) {
															key = obj.$$hashKey = 'cs-'
																	+ current++;
														}
													} else {
														key = obj;
													}

													return objType + ':' + key;
												}
											},
										};
									}, ]);
	controllers.directive('stopPropagation', function() {
				return {
					restrict : 'A',
					link : function(scope, elem, attrs, ctrl) {
						var events = attrs['stopPropagation'];
						elem.bind(events, function(event) {
							event.stopPropagation();
						});
					},
				};
	});
    
    function firLocalSysChartAcctMapCtrl(GlobalService,$scope, $rootScope, JsonObjectFactory, FirLocalSysChartAcctMapcGroupObjFactory, $parse, $timeout,  workspaceFactory, $state, $window,
			AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,  rowData, $filter, colData, gridData) {
    	
    	var vm = this;
    	//vm.rowData= rowData;
    	console.log("rowData-------", rowData);
    	console.log("gridData-----", gridData);
    	console.log("workspaceFactory-------------", workspaceFactory);
    	vm.fir_sys_acc = {};
    	vm.fir_sys_acc_dropdown_data = [];
    	vm.firLocalAccountMultipleSelected = [];
    	vm.firlocalaccounts = [];
    	vm.firlocalacctdisplay = [];
    	vm.firGridDataMultipleSelect = [];
    	vm.firlocalAccountKeys = [];
    	vm.passfirgriddata = [];
    	vm.paramfirssystemaccount = [];
    	vm.firlocaltaxsensitivity = [];
    	vm.loadingFirSysAcct = true;
    	vm.firLookUpMapping = false;
    	vm.loadingFirLookup = false;
    	vm.firTaxSensLookup = true;
    	vm.loadingFirTaxSensLookup = true;
    	vm.firTaxsensitivelookUpArrow = true;
    	vm.firPostingLocalAcctList = [];
    	vm.firOffsetLocalAcctList = [];
    	vm.selectedPsotingLocalAcct = {};
    	vm.sysAcctHide = false;
    	vm.form5471SchdH = [];
    	vm.form8865SchdM1 = [];
    	vm.taxSensFlag = true;
    	if(angular.isDefined(colData) && colData.call_back == 'foreignLocalAcctTaxSens')
    	{
    		vm.sysAcctHide = true;
    	}
    	vm.firEPTypeList = [/*{EP_TYPE_CODE: 'C', EP_TYPE_DESC_DETAIL : 'C - Admin - Reverse Current Year Balance'}, 
    		{EP_TYPE_CODE: 'P', EP_TYPE_DESC_DETAIL : 'P - Admin - Current Year Balance'},*/ 
    		{EP_TYPE_CODE: 'AB', EP_TYPE_DESC : 'Change In Balance'}, 
    		{EP_TYPE_CODE: 'AR', EP_TYPE_DESC : 'Reverse PL'}, 
    		{EP_TYPE_CODE: 'AY', EP_TYPE_DESC : 'BS acct - CY Activity'}];
    	vm.sort_key = {
				'1' : 0,
				'2' : 0,
				'3' : 0,
				'4' : 0,
				'5' : 0
			};
    	//vm.fir_sys_acct_selected = {};
    	console.log("rowData------", rowData);
    	if(rowData == undefined || rowData == null)
    	{
    		for ( var index in gridData) {
				vm.firlocalaccounts
						.push(gridData[index].data.LOCAL_CHART_ACCT_KEY);
			}
			vm.firlocalaccounts = _.uniq(vm.firlocalaccounts, JSON.stringify)
			var firparamlocalaccounts = vm.firlocalaccounts.join();
			//console.log("local_Accts",vm.firlocalaccounts);
			//console.log("firparams",firparamlocalaccounts);
    	}
    	
    	if (rowData == undefined || rowData == null) {

			var tempGridData = _.clone(gridData), groups = Object
					.create(null), result, finalKeyCheck = 0;

			tempGridData.forEach(function(a) {
				groups[a.data.SYSTEM_ACCT] = groups[a.data.SYSTEM_ACCT]
						|| [];
				groups[a.data.SYSTEM_ACCT].push(a);
			});

			result = Object.keys(groups).map(function(k) {
				var temp = {};
				temp[k] = groups[k];
				return temp;
			});

			var resultCheck = result.map(function(a) {
				console.log(a, "a");
				var keys = Object.keys(a);
				return {
					key : keys[0],
					length : a[keys[0]].length
				};
			});
			var length = Math.max.apply(Math, resultCheck.map(function(
					o) {
				return o.length;
			}))
			var checkKeyFinal = 0;
			for ( var res in resultCheck) {
				if (resultCheck[res].length == length) {
					checkKeyFinal = resultCheck[res].key

				}
			}
			for ( var resData in result) {
				var rsultKeys = Object.keys(result[resData]);
				if (rsultKeys[0] == checkKeyFinal) {
					vm.firlocalacctdisplay = result[resData][checkKeyFinal];
				}

			}
			//console.log("----firlocalacctdisplay-----------", vm.firlocalacctdisplay);
		} else {
			vm.firlocalacctdisplay = rowData;
		}
    	
    	vm.firGridDataMultipleSelect = _.clone(vm.firlocalacctdisplay);
    	
		vm.buildFirLocalAccounts = function() {
			if (vm.firGridDataMultipleSelect != undefined) {
				for ( var data in vm.firGridDataMultipleSelect) {
					if (vm.firGridDataMultipleSelect[data].data != undefined) {
						vm.firLocalAccountMultipleSelected
								.push(vm.firGridDataMultipleSelect[data].data.LOCAL_ACCT_DISPLAY);
						vm.firlocalAccountKeys
								.push(vm.firGridDataMultipleSelect[data].data.LOCAL_ACCT_KEY);
						vm.firlocaltaxsensitivity
								.push(vm.firGridDataMultipleSelect[data].data.SENSITIVE_ACCT);
					}

				}
			}
		}
		if (rowData == null || rowData == undefined) {
			vm.buildFirLocalAccounts();
		}

		vm.passfirgriddata = _.clone(gridData);

		vm.checkfirsystemaccount = [];
		vm.fir_local_acct_grid_update = [];

		if (rowData == null || rowData == undefined) {

			for ( var index_ in gridData) {
				vm.checkfirsystemaccount
						.push(gridData[index_].data.SYS_ACCT_KEY);
				vm.fir_local_acct_grid_update
						.push(gridData[index_].data.LOCAL_ACCT_KEY);
			}
			vm.checkfirsystemaccount = _.uniq(vm.checkfirsystemaccount,
					JSON.stringify)
			vm.fir_local_acct_grid_update = _.uniq(
					vm.fir_local_acct_grid_update, JSON.stringify)
			vm.fir_local_acct_grid_update = vm.fir_local_acct_grid_update
					.join();
			var paramfirssystemaccount = vm.checkfirsystemaccount;
			//console.log("vm.checkfirsystemaccount-----------", vm.checkfirsystemaccount);
			//console.log("vm.fir_local_acct_grid_update-------", vm.fir_local_acct_grid_update);
			if (vm.checkfirsystemaccount.length > 1) {
				stopFirSystemaccountSelection();

			}
			
		}

		function stopFirSystemaccountSelection() {

			AlertService.add("",
					"Please Select Only One System Account.", 400000);
			vm.userMessage = "!! Unable to perform Selected Action...";
			throw new ("This is not an error. This is just to abort javascript!");

		}

		if (rowData != null) {
			vm.selectedFirSysAcc = rowData.SYSTEM_ACCT;
		} else {
			vm.selectedFirSysAcc = gridData[0].data.SYSTEM_ACCT;
		}
    	
    	if(rowData == undefined || rowData == null)
    	{
    		vm.firLocalChartAcct_data = {
    				local_acct : gridData[0].data.LOCAL_ACCT_DISPLAY,
					localaccts : (vm.firLocalAccountMultipleSelected.length > 0) ? vm.firLocalAccountMultipleSelected
							: rowData.LOCAL_ACCT_DISPLAY,
					local_chart : gridData[0].data.CHART_DISPLAY,
					system_acct : (vm.firLocalAccountMultipleSelected.length > 0) ? vm.firlocalacctdisplay[0].data.SYSTEM_ACCT
							: rowData.SYSTEM_ACCT,
					systemacct_desc : gridData[0].data.SYSTEM_ACCT_DESC,
					local_acct_tax_sensitive : (vm.firlocaltaxsensitivity.length > 0) ? vm.firlocaltaxsensitivity
							: rowData.SENSITIVE_ACCT,
					acct_type : gridData[0].data.ACCT_TYPE
							|| gridData[0].data.SYSTEM_ACCT
				}
    	}
    	else
    	{
	    	vm.firLocalChartAcct_data = {
	    		local_acct : rowData.LOCAL_ACCT,
	    		localaccts : (vm.firLocalAccountMultipleSelected.length > 0) ? vm.firLocalAccountMultipleSelected
						: rowData.LOCAL_ACCT_DISPLAY,
			    local_chart :rowData.CHART_DISPLAY,
	    		system_acct : rowData.SYSTEM_ACCT,
				systemacct_desc : rowData.SYSTEM_ACCT_DESC,
	    		local_acct_tax_sensitive :rowData.SENSITIVE_ACCT,
	    		acct_type : rowData.ACCT_TYPE
	    	};
    	}
    	if (vm.firLocalAccountMultipleSelected.length > 0) {
			vm.firLocalChartAcct_data.localaccts = vm.firLocalChartAcct_data.localaccts
					.map(function(item) {
						return item;
					}).join('\n');
		}
    	
    	vm.flag_value = [];
    	vm.flag_value[0] = {flag : 'Yes', value: 'Y'};
    	vm.flag_value[1] = {flag : 'No', value: 'N'};
    	vm.perm_tempflag  = [];
    	vm.perm_tempflag[0] = {flag : 'Permanent', value :'PM'};
    	vm.perm_tempflag[1] = {flag : 'Temporary', value :'TN'}
  
    	vm.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		vm.reset = function()
		{
			//console.log("vm.rowData.SYSTEM_ACCT------", rowData.SYSTEM_ACCT);
			console.log("vm.fir_sys_acc_dropdown_data_TEMP-----", vm.fir_sys_acc_dropdown_data_TEMP);
			var tempIndex = _.findIndex(vm.fir_sys_acc_dropdown_data_TEMP,{"SYS_ACCT" : rowData== null || rowData == undefined ? gridData[0].data.SYSTEM_ACCT : rowData.SYSTEM_ACCT});
			console.log("index------", tempIndex);
			
			vm.fir_sys_acc.SYS_ACCT = vm.fir_sys_acc_dropdown_data_TEMP[tempIndex];
			vm.fir_sys_acc.SYS_ACCT_TEMP = vm.fir_sys_acc_dropdown_data_TEMP[tempIndex];
			vm.fir_sys_acct_selected = (vm.fir_sys_acc_dropdown_data_TEMP[tempIndex] !== undefined && vm.fir_sys_acc_dropdown_data_TEMP[tempIndex].SYS_DROPDOWN !== null) ? (vm.fir_sys_acc_dropdown_data_TEMP[tempIndex].SYS_DROPDOWN)
					: 'Please select System Account';
			/*if(vm.fir_sys_acct_selected !== 'Please select System Account')
			{
				/*if(vm.fir_sys_acc.SYS_ACCT.TAX_SENSITIVE == 'Y')
				{*/
			//vm.fir_tax_sensitive_data = [];
					
					//fetchFirTaxSensitiveData(vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY);
					vm.taxSensFlag = true;
					vm.resetFirTaxSensLookup();
					//vm.firTaxSensLookup = true;
					//vm.loadingFirTaxSensLookup = false;
					//vm.firTaxsensitivelookUpArrow = false;
				//}
				//else
					//vm.firTaxSensLookup = false;
			//}
			/*else
			{
				vm.firTaxSensLookup = true;
				vm.loadingFirTaxSensLookup = false;
			}*/
			//vm.sys_acc_dropdown_data_TEMP = data.jsonObject;
			fetchSystemAcctFormData(vm.fir_sys_acct_selected.SYS_ACCT_KEY);
			vm.loadingFirSysAcct = false;
			vm.firLookUpMapping = false;
			vm.CHECK_FIR_FORM = '';
			vm.CHECK_FIR_SCHEDULE = '';
			vm.CHECK_FIR_LINE = '';
			vm.CHECK_FIR_LINE_DESC = '';
			vm.CHECK_FIR_COL = '';
			vm.CHECK_FIR_SYS_MAP_ACCT = '';
		}
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
    	function init() {
    		fetchFirLocalPsotingAcct();
			getFirSystemAcctdropdown();
			fetchFirSystemAcctLookup();
			getF5471SchdHData();
			$scope.crudLoading = false;
		}
		init();

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		

		function getF5471SchdHData()
		{
			var params = {
					"action_code": '6mnbxg',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key
			}
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
					function(data) {
						if (data.callSuccess === "1") {
							vm.form5471SchdH = data.jsonObject;
							getF8865SchdM1Data();
						}
						else {
							if (data.errorMessage === "no access") {
								AlertService.add("", "Sorry you do not have access to do the requested action.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add("", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}
					});
		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		

		function getF8865SchdM1Data()
		{
			var params = {
			"action_code": 'r7pupz',
			"tax_year" : GlobalService.globalParams.tax_year,
			"scenario" : GlobalService.globalParams.scenario,
			"jcd_key" : GlobalService.globalParams.jcd_key
			}
		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
				function(data) {
					if (data.callSuccess === "1") {
						vm.form8865SchdM1 = data.jsonObject;
						//fetchFirTaxSensitiveData();
					}
					else {
						if (data.errorMessage === "no access") {
							AlertService.add("", "Sorry you do not have access to do the requested action.", 4000);
							vm.userMessage = "!! Unable to perform Selected Action...";
						} else {
							AlertService.add("", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
							vm.userMessage = "!! Unable to perform Selected Action...";
						}
					}
				});

    		
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		vm.save = function(){
			if ($scope.crudLoading) {
				AlertService.add("info",
						"Please wait while we save this request", 4000);
				return;
			}
			$scope.crudLoading = true;
			console.log("vm.rowData--------------", rowData);
			var selectedIndex = _.findIndex(vm.fir_sys_acc_dropdown_data_TEMP,{'SYS_DROPDOWN' : vm.fir_sys_acct_selected});
			var sys_tax_sensitive = vm.fir_sys_acc_dropdown_data_TEMP[selectedIndex].TAX_SENSITIVE;
			var returnObj = {};
			
			returnObj['system_acct_key_old'] = (rowData != undefined) ? rowData.SYS_ACCT_KEY
					: gridData[0].data.SYS_ACCT_KEY;
			returnObj['system_acct_key_new'] = vm.fir_sys_acc_dropdown_data_TEMP[selectedIndex].SYS_ACCT_KEY;
			returnObj['map_type'] = (rowData != undefined) ? rowData.MAP_TYPE
					: gridData[0].data.MAP_TYPE;
			returnObj['group_obj_key'] = (rowData != undefined) ? rowData.GROUP_OBJ_KEY
					: gridData[0].data.GROUP_OBJ_KEY;
			returnObj['tax_year'] = (rowData != undefined) ? rowData.TAX_YEAR
					: gridData[0].data.TAX_YEAR;
			returnObj['local_chart_key'] = (rowData != undefined) ? rowData.LOCAL_CHART_KEY
					: gridData[0].data.LOCAL_CHART_KEY;
			returnObj['acct_type'] = vm.firLocalChartAcct_data.acct_type;
			returnObj['local_acct_key'] = (rowData != undefined) ? rowData.LOCAL_ACCT_KEY
							: gridData[0].data.LOCAL_ACCT_KEY,
			returnObj['scenario'] = GlobalService.globalParams.scenario;
			vm.firLocalChartAcct_data.local_system_accounts = vm.firLocalChartAcct_data.localaccts
			.split('\n')
			.map(
					function(item, index) {
						return {
							system_acct_key_old : returnObj.system_acct_key_old,
							system_acct_key_new : returnObj.system_acct_key_new,
							local_acct_key : (rowData != undefined) ? rowData.LOCAL_ACCT_KEY
									: vm.firlocalacctdisplay[index].data.LOCAL_ACCT_KEY,
							map_type : returnObj.map_type,
							group_obj_key : returnObj.group_obj_key,
							tax_year : returnObj.tax_year,
							local_chart_key : returnObj.local_chart_key,
							acct_type : returnObj.acct_type,
							scenario : returnObj.scenario
						};
						return {
							local_account_keys : gridData[index].data.LOCAL_ACCT_KEYS,
							local_account : item
						};
					})
					
			/*var returnObj= {
					"system_acct_key_old" : vm.rowData.SYS_ACCT_KEY,
					"system_acct_key_new" : vm.fir_sys_acc_dropdown_data_TEMP[selectedIndex].SYS_ACCT_KEY,
					"map_type" : vm.rowData.MAP_TYPE,
					"tax_year" : vm.rowData.TAX_YEAR,
					"local_acct_key" : vm.rowData.LOCAL_ACCT_KEY,
					"acct_type" : vm.firLocalChartAcct_data.acct_type,
					"local_chart_key" : vm.rowData.LOCAL_CHART_KEY,
					"group_obj_key" : vm.rowData.GROUP_OBJ_KEY,
					"scenario" : GlobalService.globalParams.scenario
			}*/
			//---tax sensitive data
			var saveTaxSenisitveData = [];
			//if(sys_tax_sensitive == 'Y')
			//{
				console.log("vm.fir_tax_sensitive_data-------------------", vm.fir_tax_sensitive_data);
				console.log("vm.fir_tax_sensitive_data_temp-------------------", vm.fir_tax_sensitive_data_temp);
		        checkTaxSensitivityDetails();
		        if(vm.taxSensFlag == false)
		        {
		        	AlertService.add("error",
							"Please fill the below fields", 4000);
		        	$scope.crudLoading = false;
					return;
					console.log("vm.fir_tax_sensitive_data-------------------", vm.fir_tax_sensitive_data);
					console.log("vm.fir_tax_sensitive_data_temp-------------------", vm.fir_tax_sensitive_data_temp);
		        }
		        else
		        {
				for(var i in vm.fir_tax_sensitive_data)
				{
					if( ( vm.fir_tax_sensitive_data[i].LOCAL_ACCT_TAX_SENSITIVE.value == 'Y')  || (vm.fir_tax_sensitive_data[i].LOCAL_ACCT_TAX_SENSITIVE.value == 'N' && vm.fir_tax_sensitive_data[i].EP_AUTO_KEY !== 0 ))
					{
						var tempObj = {
							"NEW_LOCAL_TAX_SENSITIVITY": vm.fir_tax_sensitive_data[i].LOCAL_ACCT_TAX_SENSITIVE.value,
							"SYSTEM_ACCT_KEY_OLD" : (rowData == null || rowData == undefined ) ? gridData[0].data.SYS_ACCT_KEY : rowData.SYS_ACCT_KEY,
							"POSTING_LOCAL_ACCT_KEY" : (vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS == undefined ) ? null : vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS.POSTING_LOCAL_ACCT_KEY,
							"LOCAL_CHART_KEY" : vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS.CHART_KEY,
							"TRIGGER_LOCAL_ACCT_KEY" :vm.fir_tax_sensitive_data[i].TRIGGER_LOCAL_ACCT_KEY,
							//"system_acct_key" : vm.fir_tax_sensitive_data[i].SYSTEM_ACCT_KEY,
							"EP_AUTO_KEY" : vm.fir_tax_sensitive_data[i].EP_AUTO_KEY ,
						    "EP_DESC" :  vm.fir_tax_sensitive_data[i].EP_DESC ,
						    "PERM_TEMP_FLAG" : (angular.isDefined(vm.fir_tax_sensitive_data[i].PERM_TEMP_FLAG) ?  vm.fir_tax_sensitive_data[i].PERM_TEMP_FLAG.value : null),
						    "EP_TYPE" :  vm.fir_tax_sensitive_data[i].EP_TYPE_DETAILS.EP_TYPE_CODE,
						    "MULTIPLIER" :  vm.fir_tax_sensitive_data[i].MULTIPLIER,
						    "LINE_5471" : vm.fir_tax_sensitive_data[i].LINE_5471_DETAILS.LINE_NO,
						    "LINE_8865" : vm.fir_tax_sensitive_data[i].LINE_8865_DETAILS.LINE,
						    //"US_TAX_TYPE" :  vm.fir_tax_sensitive_data[i].US_TAX_TYPE,
						    "DEFAULT_METHOD" :  angular.isDefined(vm.fir_tax_sensitive_data[i].DEFAULT_METHOD) ? vm.fir_tax_sensitive_data[i].DEFAULT_METHOD.value : null,
						    "PERMISSIBLE" : angular.isDefined(vm.fir_tax_sensitive_data[i].PERMISSIBLE) ? vm.fir_tax_sensitive_data[i].PERMISSIBLE.value : null,
						    "AMT_SIGN" : vm.fir_tax_sensitive_data[i].AMT_SIGN,
						    "OFFSET_LOCAL_ACCT_KEY" : (vm.fir_tax_sensitive_data[i].OFFSET_LOCAL_ACCT_DETAILS == undefined ) ? null : vm.fir_tax_sensitive_data[i].OFFSET_LOCAL_ACCT_DETAILS.OFFSET_LOCAL_ACCT_KEY
						   // "LINE_NO" : vm.fir_tax_sensitive_data[i].LINE_NO
						}
						saveTaxSenisitveData.push(tempObj);
					}
				}
			/*}
			else
			{
				var tempObj = {
						"system_tax_sensitivity" : sys_tax_sensitive,
						"new_local_tax_sensitivity" : 'N',
						"system_acct_key_old" : (rowData == null || rowData == undefined ) ? gridData[0].data.SYS_ACCT_KEY : rowData.SYS_ACCT_KEY,
						"local_acct_key" : (rowData == null || rowData == undefined ) ? gridData[0].data.LOCAL_ACCT_KEY : rowData.LOCAL_ACCT_KEY,
						"local_chart_key" : (rowData == null || rowData == undefined ) ? gridData[0].data.LOCAL_CHART_KEY : rowData.LOCAL_CHART_KEY,
						"system_acct_key" : vm.fir_sys_acc_dropdown_data_TEMP[selectedIndex].SYS_ACCT_KEY,
						"ep_sequence_key" : '',
					    "ep_desc" :  '',
					    "perm_temp_flag" :'',
					    "ep_Type" :  '' ,
					    "multiplier" :  '',
					    "us_tax_type" :  '',
					    "default_method" :  '',
					    "permissible" : '',
					    "amt_sign" : '',
					    "line_no" : '',
					    "override" : ''
				}
				saveTaxSenisitveData.push(tempObj);
			}*/
			console.log("vm.firLocalChartAcct_data.local_system_accounts&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&", vm.firLocalChartAcct_data.local_system_accounts);
			console.log("saveTaxSenisitveData-------------------", saveTaxSenisitveData);
			
			FirLocalSysChartAcctMapcGroupObjFactory.saveFIRFormDetails(vm.firLocalChartAcct_data.local_system_accounts, saveTaxSenisitveData).then(function(result) {
						// vm.setSaveflag = false;
						if (result.callSuccess == "0"
								&& result.errorMessage
								&& result.errorMessage !== 'null') {
							var messageCustom = null;
							if (result.errorMessage
									.includes('Invalid System Account Number')) {
								var systemMessage = (details != undefined && details.length > 0) ? details[0].system_acct_key_new
										: '';

								messageCustom = result.errorMessage
										+ 'System Account Key : '
										+ systemMessage;
							}
							if (result.errorMessage
									.includes('Invalid Local Account Number')) {
								var localMessage = details
										.map(function(msg) {
											return 'Local Account Key : '
													+ msg.local_acct_key
													+ '\n';
										});
								messageCustom = result.errorMessage
										+ localMessage;
							}

							if (messageCustom === null) {
								messageCustom = "An internal error has occurred. The Global Tax support team has been notified of this error";
							}
							AlertService.add("error",
									messageCustom, 4000);
							$scope.crudLoading = false;

						} else if (result.callSuccess == "1") {
							AlertService.add("success",
									'Data has been successfully save', 4000);
							$uibModalInstance.dismiss('cancel');
							$uibModalInstance.dismiss('cancel');
							var args = {
								local_acct_key : (rowData == null) ? vm.fir_local_acct_grid_update
										: rowData.LOCAL_ACCT_KEY,
								sys_acct_key : (typeof vm.fir_sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.fir_sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY
										: vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY,

								gridFilter : {
									local_acct_key : (rowData == null) ? vm.fir_local_acct_grid_update
											: rowData.LOCAL_ACCT_KEY,
									sys_acct_key : (typeof vm.fir_sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.fir_sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY
											: vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY,

								}
							};

							$rootScope
									.$emit('gridUpdate', args);
						}
					});
			}
		       
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
		vm.firToggleLookup = function() {
			vm.firLookUpMapping = !vm.firLookUpMapping;
		};
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.firTaxsensitivetoggleLookup = function() {
			vm.firTaxsensitivelookUpArrow = !vm.firTaxsensitivelookUpArrow;
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
		vm.changeFirTaxSensitivity = function(editRow){
			if(editRow.LOCAL_ACCT_TAX_SENSITIVE.value == 'N')
			    editRow.isEditable = false;
			else
				 editRow.isEditable = true;
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.changeMultiplier = function(editRow){
			if(editRow.EP_TYPE_DETAILS.EP_TYPE_CODE == 'AR')
				editRow.MULTIPLIER = -1;
			else if(editRow.EP_TYPE_DETAILS.EP_TYPE_CODE == 'AY')
				editRow.MULTIPLIER = 1;
			else if(editRow.EP_TYPE_DETAILS.EP_TYPE_CODE == 'AB')
				editRow.MULTIPLIER = null;
		}

//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
		
		vm.resetFirTaxSensLookup = function()
		{
			vm.fir_tax_sensitive_data = [];
			for(var i in vm.fir_tax_sensitive_data_temp) 
			{
				if( rowData == null || rowData == undefined )
				{
					for(var j in vm.firlocalacctdisplay)
					{
						if(vm.firlocalacctdisplay[j].data.LOCAL_ACCT_KEY == vm.fir_tax_sensitive_data_temp[i].LOCAL_ACCT_KEY)
						{
							var temp_local_acct = vm.firlocalacctdisplay[j].data.LOCAL_ACCT;
							var temp_local_chart_key = vm.firlocalacctdisplay[j].data.LOCAL_CHART_KEY;
							break;
						}
					}
				}
				else
				{
					var temp_local_acct = vm.firlocalacctdisplay.LOCAL_ACCT;
					var temp_local_chart_key = vm.firlocalacctdisplay.LOCAL_CHART_KEY;
				}
				//var temp_local_acct = 
				var tempjsonObj = {
						"TRIGGER_LOCAL_ACCT_KEY" : vm.fir_tax_sensitive_data_temp[i].TRIGGER_LOCAL_ACCT_KEY,
						"TRIGGER_LOCAL_ACCT" : temp_local_acct,
						"LOCAL_ACCT_TAX_SENSITIVE" : (vm.fir_tax_sensitive_data_temp[i].TAX_SENSITIVE == 'Y' ? {flag:'Yes', value : 'Y'} : {flag:'No', value : 'N'}),
						//"SYSTEM_ACCT_KEY" : vm.fir_tax_sensitive_data_temp[i].SYSTEM_ACCT_KEY,
					    "EP_AUTO_KEY" : (vm.fir_tax_sensitive_data_temp[i].EP_AUTO_KEY == undefined) ? null: vm.fir_tax_sensitive_data_temp[i].EP_AUTO_KEY,
					    "EP_DESC" :  (vm.fir_tax_sensitive_data_temp[i].EP_DESC == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].EP_DESC,
					    "PERM_TEMP_FLAG" :  (vm.fir_tax_sensitive_data_temp[i].PERM_TEMP_FLAG ==='PM'? {flag:'Permanent', value:'PM'} : vm.fir_tax_sensitive_data_temp[i].PERM_TEMP_FLAG ==='TN'? {flag:'Temporary', value:'TN'} : vm.fir_tax_sensitive_data_temp[i].PERM_TEMP_FLAG),
					    "EP_TYPE_CODE" :  (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE ,
					    "EP_TYPE_DESC" : (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC,
					    "EP_TYPE_DETAILS" : { EP_TYPE_CODE : (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE ,
					    	                  EP_TYPE_DESC : (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC},
					    "MULTIPLIER" :  vm.fir_tax_sensitive_data_temp[i].MULTIPLIER,
					    //"US_TAX_TYPE" :  vm.fir_tax_sensitive_data_temp[i].US_TAX_TYPE,
					    "DEFAULT_METHOD" :  (vm.fir_tax_sensitive_data_temp[i].DEFAULT_METHOD  ==='Y' ? {flag:'Yes', value : 'Y'} : vm.fir_tax_sensitive_data_temp[i].DEFAULT_METHOD  ==='N'? {flag:'No', value : 'N'} : vm.fir_tax_sensitive_data_temp[i].DEFAULT_METHOD),
					    "PERMISSIBLE" : (vm.fir_tax_sensitive_data_temp[i].PERMISSIBLE  ==='Y' ? {flag:'Yes', value : 'Y'} : vm.fir_tax_sensitive_data_temp[i].PERMISSIBLE === 'N' ? {flag:'No', value : 'N'} : vm.fir_tax_sensitive_data_temp[i].PERMISSIBLE),
					    "AMT_SIGN" : (vm.fir_tax_sensitive_data_temp[i].AMT_SIGN == undefined)? null: vm.fir_tax_sensitive_data_temp[i].AMT_SIGN,
					    //"LINE_NO" : vm.fir_tax_sensitive_data_temp[i].LINE_NO == undefined ? null : vm.fir_tax_sensitive_data_temp[i].LINE_NO,
					    "LINE_5471" :(vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_5471,
					    //"LINE_5471_DESC" : vm.getLineDesc( 'F5471', (vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_5471),
					    "LINE_5471_DETAILS" : {'IRS_FORM_NO' : 'F5471', 'SCHEDULE' : 'H', 'LINE_NO' : (vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_5471,
					    		'DESCRIPTION' : vm.getLineDesc( 'F5471', (vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_5471)},
					    "LINE_8865" :(vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_8865,
					    //"LINE_8865_DESC" : vm.getLineDesc('F8865', (vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_8865),
					    "LINE_8865_DETAILS" : {'IRS_FORM_NO' : 'F8865', 'SCHEDULE' : 'K', 'LINE' : (vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_8865,
					    		'DESCRIPTION' : vm.getLineDesc( 'F8865', (vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_8865)},
					    "POSTING_LOCAL_ACCT_KEY" : (vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY,
					    "POSTING_LOCAL_ACCT_DETAILS" : (vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY == undefined) ? null :{CHART_KEY : temp_local_chart_key,
					    								POSTING_LOCAL_ACCT : vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT ,
					    								POSTING_LOCAL_ACCT_KEY : vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY
					    },
					    "OFFSET_LOCAL_ACCT_KEY" : (vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY,
						"OFFSET_LOCAL_ACCT_DETAILS" : (vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY == undefined) ? null :{CHART_KEY : temp_local_chart_key,
								    								OFFSET_LOCAL_ACCT : vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT ,
								    								OFFSET_LOCAL_ACCT_KEY : vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY
					   },
					   // "OVERRIDE_Y_N" : vm.fir_tax_sensitive_data_temp[i].OVERRIDE_Y_N,
					    "isEditable" : false
					    //"isDelete" :false
					    }
				console.log('tempjsonObj-----------', tempjsonObj);
					vm.fir_tax_sensitive_data.push(tempjsonObj);
			} 
			
		}

//-----------------------------------------------------------------------------------------------------------
		vm.getLineDesc = function(form, line)
		{
			var desc = '';
			if(form == 'F5471')
			{
				for(var i = 0; i<vm.form5471SchdH.length ;i++)
				{
					if(vm.form5471SchdH[i].LINE_NO == line)
					{
						desc = vm.form5471SchdH[i].DESCRIPTION;
						break;
					}
				}
			}
			if(form == 'F8865')
			{
				for(var i = 0; i<vm.form8865SchdM1.length ;i++)
				{
					if(vm.form8865SchdM1[i].LINE == line)
					{
						desc = vm.form8865SchdM1[i].DESCRIPTION;
						break;
					}
				}
			}
			return desc;
		}
		
//---------------------------------------------------------------------------------------------------------
		
		vm.changeLine = function()
	       {
			vm.CHECK_FIR_LINE = vm.CHECK_FIR_LINE_DESC.split("-")[0].replace(/^\s+|\s+$/g, '');
	       }
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
		vm.firFormEdit = function(editRow){
			editRow.isEditable = true;
			console.log("editRow------", editRow);
		}
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
		
		function fetchFirLocalPsotingAcct()
		{
			var params = {
					"action_code": 'xzxjuh',
					'local_acct_key':(rowData == null || rowData == undefined) ?  vm.firlocalAccountKeys.join() : rowData.LOCAL_ACCT_KEY,
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key
			}
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
					function(data) {
						if (data.callSuccess === "1") {
							for(var i = 0 ; i<data.jsonObject.length; i++)
							{
								if(data.jsonObject[i].ACCT_FLAG == 'INCOME_STATEMENT')
									vm.firPostingLocalAcctList.push(data.jsonObject[i]);
								else
									vm.firOffsetLocalAcctList.push(data.jsonObject[i]);
							}
							vm.firPostingLocalAcctList = $filter('orderBy')(vm.firPostingLocalAcctList, 'POSTING_LOCAL_ACCT');
							vm.firOffsetLocalAcctList = $filter('orderBy')(vm.firOffsetLocalAcctList, 'OFFSET_LOCAL_ACCT');
							//vm.firPostingLocalAcctList = data.jsonObject;
							//vm.firOffsetLocalAcctList = data.jsonObject;

							console.log("vm.firPostingLocalAcctList---", vm.firPostingLocalAcctList);
							console.log("vm.firOffsetLocalAcctList---", vm.firOffsetLocalAcctList);
							fetchFirTaxSensitiveData();
						}
						else {
							if (data.errorMessage === "no access") {
								AlertService.add("", "Sorry you do not have access to do the requested action.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add("", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}
					});
	    	
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		function fetchFirTaxSensitiveData() {
			console.log("vm.firlocalAccountKeys.join()----------", vm.firlocalAccountKeys.join());
			vm.fir_tax_sensitive_data = [];
			vm.fir_sys_acct_tax_sensitive_data=[];
			var params = {
					"action_code" : 'lxmsjj',
					'sys_acct_key' : null,
					'local_acct_key':(rowData == null || rowData == undefined) ?  vm.firlocalAccountKeys.join() : rowData.LOCAL_ACCT_KEY,
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key
			}
			
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
					function(data) {
						console.log("Response Data ::", data);
						if (data.callSuccess === "1") {
							vm.fir_tax_sensitive_data = [];
							vm.fir_sys_acct_tax_sensitive_data=[];
							vm.fir_tax_sensitive_data_temp = data.jsonObject;
							console.log("vm.fir_tax_sensitive_data_temp--------------", vm.fir_tax_sensitive_data_temp);
							console.log("vm.firlocalacctdisplay", vm.firlocalacctdisplay);
							for(var i in vm.fir_tax_sensitive_data_temp) 
							{
								if( rowData == null || rowData == undefined )
								{
									for(var j in vm.firlocalacctdisplay)
									{
										if(vm.firlocalacctdisplay[j].data.LOCAL_ACCT_KEY == vm.fir_tax_sensitive_data_temp[i].TRIGGER_LOCAL_ACCT_KEY)
										{
											var temp_local_acct = vm.firlocalacctdisplay[j].data.LOCAL_ACCT;
											var temp_local_chart_key = vm.firlocalacctdisplay[j].data.LOCAL_CHART_KEY;
											break;
										}
									}
								}
								else
								{
									var temp_local_acct = vm.firlocalacctdisplay.LOCAL_ACCT;
									var temp_local_chart_key = vm.firlocalacctdisplay.LOCAL_CHART_KEY;
								}
								//var temp_local_acct = 
									var tempjsonObj = {
										"TRIGGER_LOCAL_ACCT_KEY" : vm.fir_tax_sensitive_data_temp[i].TRIGGER_LOCAL_ACCT_KEY,
										"TRIGGER_LOCAL_ACCT" : temp_local_acct,
										"LOCAL_ACCT_TAX_SENSITIVE" : (vm.fir_tax_sensitive_data_temp[i].TAX_SENSITIVE == 'Y' ? {flag:'Yes', value : 'Y'} : {flag:'No', value : 'N'}),
										//"SYSTEM_ACCT_KEY" : vm.fir_tax_sensitive_data_temp[i].SYSTEM_ACCT_KEY,
									    "EP_AUTO_KEY" : (vm.fir_tax_sensitive_data_temp[i].EP_AUTO_KEY == undefined) ? null: vm.fir_tax_sensitive_data_temp[i].EP_AUTO_KEY,
									    "EP_DESC" :  (vm.fir_tax_sensitive_data_temp[i].EP_DESC == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].EP_DESC,
									    "PERM_TEMP_FLAG" :  (vm.fir_tax_sensitive_data_temp[i].PERM_TEMP_FLAG ==='PM'? {flag:'Permanent', value:'PM'} : vm.fir_tax_sensitive_data_temp[i].PERM_TEMP_FLAG ==='TN'? {flag:'Temporary', value:'TN'} : vm.fir_tax_sensitive_data_temp[i].PERM_TEMP_FLAG),
									    "EP_TYPE_CODE" :  (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE,
									    "EP_TYPE_DESC" : (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC,
									    "EP_TYPE_DETAILS" : { EP_TYPE_CODE : (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_CODE ,
									    						EP_TYPE_DESC :  (vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].EP_TYPE_DESC},
									    "MULTIPLIER" :  vm.fir_tax_sensitive_data_temp[i].MULTIPLIER,
									    //"US_TAX_TYPE" :  vm.fir_tax_sensitive_data_temp[i].US_TAX_TYPE,
									    "DEFAULT_METHOD" :  (vm.fir_tax_sensitive_data_temp[i].DEFAULT_METHOD  ==='Y' ? {flag:'Yes', value : 'Y'} : vm.fir_tax_sensitive_data_temp[i].DEFAULT_METHOD  ==='N'? {flag:'No', value : 'N'} : vm.fir_tax_sensitive_data_temp[i].DEFAULT_METHOD),
									    "PERMISSIBLE" : (vm.fir_tax_sensitive_data_temp[i].PERMISSIBLE  ==='Y' ? {flag:'Yes', value : 'Y'} : vm.fir_tax_sensitive_data_temp[i].PERMISSIBLE === 'N' ? {flag:'No', value : 'N'} : vm.fir_tax_sensitive_data_temp[i].PERMISSIBLE),
									    "AMT_SIGN" : (vm.fir_tax_sensitive_data_temp[i].AMT_SIGN == undefined)? null: vm.fir_tax_sensitive_data_temp[i].AMT_SIGN,
									    //"LINE_NO" : vm.fir_tax_sensitive_data_temp[i].LINE_NO == undefined ? null : vm.fir_tax_sensitive_data_temp[i].LINE_NO,
									    "LINE_5471" :(vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_5471,
									    "LINE_5471_DETAILS" : {'IRS_FORM_NO' : 'F5471', 'SCHEDULE' : 'H', 'LINE_NO' : (vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_5471,
												    		'DESCRIPTION' : vm.getLineDesc( 'F5471', (vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_5471)},
									    		//"LINE_5471_DESC" : vm.getLineDesc( 'F5471', (vm.fir_tax_sensitive_data_temp[i].LINE_5471 == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].LINE_5471),	                
									    "LINE_8865" :(vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_8865,
									    //"LINE_8865_DESC" : vm.getLineDesc('F8865', (vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].LINE_8865),   	                 
									   "LINE_8865_DETAILS" : {'IRS_FORM_NO' : 'F8865', 'SCHEDULE' : 'K', 'LINE' : (vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_8865,
												    		'DESCRIPTION' : vm.getLineDesc( 'F8865', (vm.fir_tax_sensitive_data_temp[i].LINE_8865 == undefined) ? '' : vm.fir_tax_sensitive_data_temp[i].LINE_8865)},
									    "POSTING_LOCAL_ACCT_KEY" : (vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY,
									    "POSTING_LOCAL_ACCT_DETAILS" : (vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY == undefined) ? null : {CHART_KEY : temp_local_chart_key,
									    								POSTING_LOCAL_ACCT : vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT ,
									    								POSTING_LOCAL_ACCT_KEY : vm.fir_tax_sensitive_data_temp[i].POSTING_LOCAL_ACCT_KEY
									    	},
									    "OFFSET_LOCAL_ACCT_KEY" : (vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY == undefined) ? null : vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY,
										"OFFSET_LOCAL_ACCT_DETAILS" : (vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY == undefined) ? null :{CHART_KEY : temp_local_chart_key,
										    								OFFSET_LOCAL_ACCT : vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT ,
										    								OFFSET_LOCAL_ACCT_KEY : vm.fir_tax_sensitive_data_temp[i].OFFSET_LOCAL_ACCT_KEY
										    },
									   // "OVERRIDE_Y_N" : vm.fir_tax_sensitive_data_temp[i].OVERRIDE_Y_N,
									    "isEditable" : false
									    //"isDelete" :false
									    }
										
									vm.fir_tax_sensitive_data.push(tempjsonObj);
									
							} 
							console.log("vm.fir_tax_sensitive_data-------", vm.fir_tax_sensitive_data);
							console.log("vm.fir_sys_acct_tax_sensitive_data--------------", vm.fir_sys_acct_tax_sensitive_data);
							
							
							vm.firTaxsensitivelookUpArrow = true;
							vm.loadingFirTaxSensLookup = false;
						}
						else {
							if (data.errorMessage === "no access") {
								AlertService.add("", "Sorry you do not have access to do the requested action.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add("", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
							vm.firTaxSensLookup = false;
							//vm.loadingFirTaxSensLookup = true;
						}
						
						
					});
			
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		function getFirSystemAcctdropdown() {
			var params = {
					"action_code" : 'pfwnw7',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key
			}
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
					function(data) {
						console.log("Response Data ::", data);
						if (data.callSuccess === "1") {
							vm.fir_sys_acc_dropdown_data = [];
							//vm.pl_acct_flag = true;
							for ( var index_ in data.jsonObject) {
								vm.fir_sys_acc_dropdown_data.push(data.jsonObject[index_].SYS_DROPDOWN);
							}

							//console.log("sys_acc_dropdown_data "+ vm.fir_sys_acc_dropdown_data);
							
							vm.fir_sys_acc_dropdown_data_TEMP = data.jsonObject;
//							/console.log("vm.rowData.SYSTEM_ACCT------", rowData.SYSTEM_ACCT);
							console.log("vm.fir_sys_acc_dropdown_data_TEMP-----", vm.fir_sys_acc_dropdown_data_TEMP);
							
							if(rowData == null || rowData == undefined)
								var index = _.findIndex(vm.fir_sys_acc_dropdown_data_TEMP,{'SYS_ACCT' : vm.firlocalacctdisplay[0].data.SYSTEM_ACCT});
							else
								var index = _.findIndex(vm.fir_sys_acc_dropdown_data_TEMP,{'SYS_ACCT' : rowData.SYSTEM_ACCT});
							console.log("index-----", index);
							
							vm.fir_sys_acc.SYS_ACCT = vm.fir_sys_acc_dropdown_data_TEMP[index];
							vm.fir_sys_acc.SYS_ACCT_TEMP = vm.fir_sys_acc_dropdown_data_TEMP[index];
							vm.fir_sys_acct_selected = (vm.fir_sys_acc_dropdown_data_TEMP[index] !== undefined && vm.fir_sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN !== null) ? (vm.fir_sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN)
									: 'Please select System Account';
							/*if(vm.fir_sys_acct_selected !== 'Please select System Account')
							{
								/*if(vm.fir_sys_acc.SYS_ACCT.TAX_SENSITIVE == 'Y')
								{
									vm.firTaxSensLookup = true;
									vm.loadingFirTaxSensLookup = true;
									vm.firTaxsensitivelookUpArrow = false;
									fetchFirTaxSensitiveData(vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY);
								}
							}
							else
							{
								vm.firTaxSensLookup = false;
								vm.loadingFirTaxSensLookup = false;
							}*/
							//vm.sys_acc_dropdown_data_TEMP = data.jsonObject;
							fetchSystemAcctFormData(vm.fir_sys_acct_selected.SYS_ACCT_KEY);
							vm.loadingFirSysAcct = false;
							
						} else {
							if (data.errorMessage === "no access") {
								AlertService.add("", "Sorry you do not have access to do the requested action.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add("", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

					});
			}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.getChartAcctTableData = function() {
			//vm.taxsensitivelookUpMapping = true;
			//fetchtaxsensitivedata(true);
			var index = _.findIndex(vm.fir_sys_acc_dropdown_data_TEMP, {
				'SYS_DROPDOWN' : vm.fir_sys_acct_selected
			});
			if (vm.fir_sys_acc.SYS_ACCT !== undefined) {
				vm.fir_sys_acc.SYS_ACCT = (index != -1) ? vm.fir_sys_acc_dropdown_data_TEMP[index]
						: vm.fir_sys_acc.SYS_ACCT_TEMP;
				if(vm.fir_sys_acc.SYS_ACCT.TAX_SENSITIVE == 'Y')
				{
					/*vm.firTaxSensLookup = true;
					vm.loadingFirTaxSensLookup = true;
					vm.firTaxsensitivelookUpArrow = false;*/
					AlertService
					.add(
							"warning",
							"Selected system account is tax sensitive",
							40000);
					//fetchFirTaxSensitiveData(vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY)
				}
				/*else
				{
					vm.firTaxSensLookup = false;
					vm.loadingFirTaxSensLookup = false;
				}*/
			} else {
				vm.fir_sys_acc.SYS_ACCT = {};
				vm.fir_sys_acc.SYS_ACCT = (index != -1) ? vm.fir_sys_acc_dropdown_data_TEMP[index]
						: vm.fir_sys_acc.SYS_ACCT_TEMP;
				if(vm.fir_sys_acc.SYS_ACCT.TAX_SENSITIVE == 'Y')
				{
					/*vm.firTaxSensLookup = true;
					vm.loadingFirTaxSensLookup = true;
					vm.firTaxsensitivelookUpArrow = false;*/
					AlertService
					.add(
							"warning",
							"Selected system account is tax sensitive",
							40000);
					//fetchFirTaxSensitiveData(vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY)
				}
				/*else
				{
					vm.firTaxSensLookup = false;
					vm.loadingFirTaxSensLookup = false;
				}*/
			}
			vm.selectedSystemAccountKey = angular
					.copy(vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY);
			
			fetchSystemAcctFormData();
			
			vm.selectedSysAcc = vm.fir_sys_acc.SYS_ACCT;
			
			vm.firLocalChartAcct_data.systemacct_des = (vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_DESC != undefined) ? vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_DESC
					: vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_DESC;
			if (rowData && rowData.ACCT_TYPE.trim().length == 0) {
				vm.firLocalChartAcct_data.acct_type = vm.selectedSysAcc.ACCT_TYPE;
			}
			
		}	

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
		function fetchSystemAcctFormData(){
			if(rowData == null || rowData == undefined)
			{
				if (vm.fir_sys_acc.SYS_ACCT == undefined) {
					return;
				} else { 
						var params = {
						"action_code" : '4fmxwf',
						"tax_year" : gridData[0].data.TAX_YEAR,
						"local_acct" : gridData[0].data.LOCAL_ACCT_KEY,
						"sys_acct" : (typeof vm.fir_sys_acc.SYS_ACCT == "object") ? vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY
								: vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY
					};
				}
			}
			else
			{
				if (vm.fir_sys_acc.SYS_ACCT == undefined) {
					return;
				} else { 
						var params = {
						"action_code" : '4fmxwf',
						"tax_year" : rowData.TAX_YEAR,
						"local_acct" : rowData.LOCAL_ACCT_KEY,
						"sys_acct" : (typeof vm.fir_sys_acc.SYS_ACCT == "object") ? vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY
								: vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY
					};
				}
			}
			vm.loadingFirIRSform = true;
			//console.log("Input Data fetchSystemAcctData**********::",
					//params);
			return JsonObjectFactory
					.getJSONObj(
							GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
								console.log("Response Data ::", data);
								if (data.callSuccess === "1") {
									vm.firIRSFormData = data.jsonObject;
									vm.loadingIRSform = false;
									console.log("form Data-----", vm.firIRSFormData);
									
								} else {
									if (data.errorMessage === "no access") {
										AlertService
												.add(
														"",
														"Sorry you do not have access to do the requested action.",
														4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									} else {
										AlertService
												.add(
														"",
														"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
														4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									}
								}

							});
			
			
			}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		function fetchFirSystemAcctLookup(){
			vm.loadingFirLookup = true;
			var params = {
					"action_code" : 'oz9yxt',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key
		     }
			
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(
					function(data) {
						console.log("Response Data x ::", data);
						if (data.callSuccess === "1") {

							vm.firSystemAcctLookupTemp = data.jsonObject;
							vm.firLookupForm = [];
							vm.firLookupSchedule = [];
							vm.firLookupLine = [];
							vm.firLookupColumn = [];
							vm.firLookupSysMapAcct = [];
							vm.firLookupSysMapAcct1 = [];
							vm.firLookupLineDesc = [];
							vm.firLookupcheckdata = data.jsonObject;

							for ( var index_ in data.jsonObject) {
								vm.firLookupForm
										.push(data.jsonObject[index_].FORM);
							}
							vm.firLookupForm = _.uniq(
									vm.firLookupForm,
									JSON.stringify)
							vm.loadingFirLookup = false;		
									console.log("sys_acc_dropdown_data "+ vm.fir_sys_acc_dropdown_data);
						} else {
							vm.loadingFirLookup = false;
							if (data.errorMessage === "no access") {
								AlertService
										.add(
												"",
												"Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService
										.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

					});

		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.applyFirChanges = function() {
			vm.fir_sys_acct_selected = vm.CHECK_FIR_SYS_MAP_ACCT;
			vm.getChartAcctTableData();
		}	
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
		vm.resetFirLookup = function() {
			vm.CHECK_FIR_FORM = '';
			vm.CHECK_FIR_SCHEDULE = '';
			vm.CHECK_FIR_LINE = '';
			vm.CHECK_FIR_LINE_DESC = '';
			vm.CHECK_FIR_COL = '';
			vm.CHECK_FIR_SYS_MAP_ACCT = '';
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
			
		vm.sortBy = function(key, value) {
			$timeout(function() {
				switch (value) {
				case 0:
					vm.sort_key[key] = 1;
					vm.reverseSort = false;
					break;
				case 1:
					vm.sort_key[key] = 2;
					vm.reverseSort = true;
					break;
				case 2:
					vm.sort_key[key] = 0;
					vm.reverseSort = "";
					break;
				}
			});
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		
		vm.getFirLookupNext = function(key, nextLookupKey,
				currentLookupValue) {
			var firLookupData = angular.copy(vm.firLookupcheckdata);
			var a = "SYS_ACCT";

			switch (key) {
			case 1:
				vm.firLookupSchedule = filterFirDataByKey("FORM",
						nextLookupKey, firLookupData, currentLookupValue);

				break;
			case 2:
				vm.firLookupLineDesc = filterFirDataByKey1("SCHEDULE",
						nextLookupKey, firLookupData1, currentLookupValue, vm.CHECK_FIR_FORM);
				break;
			case 3:
				vm.firLookupColumn = filterFirDataByKey2("LINE_DESC",
						nextLookupKey, firLookupData2, currentLookupValue, vm.CHECK_FIR_SCHEDULE);
				vm.firLookupSysMapAcct = filterFirDataByKey22("LINE_DESC", a,
						firLookupData2, currentLookupValue, vm.CHECK_FIR_SCHEDULE);
				break;

			case 4:
				vm.firLookupSysMapAcct = filterFirDataByKey3("COL",
						nextLookupKey, firLookupData3, currentLookupValue);
				break;
			case 5:
				vm.firLookupSysMapAcct = filterFirDataByKey4("LINE_DESC",
						nextLookupKey, firLookupData2, currentLookupValue);
				break;
			}

		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		
		function filterFirDataByKey(currentLookupKey, nextLookupKey,
				firLookupData, currentLookupValue) {
			var nextLookupData = [];

			var currentLookupFilter = firLookupData.filter(function(
					object, index) {
				//console.log(object, index)
				if (object[currentLookupKey] == currentLookupValue) {
					return true;
				}
			});

			currentLookupFilter.forEach(function(object, index) {
				nextLookupData.push(object[nextLookupKey]);
			});
			nextLookupData = _.uniq(nextLookupData, JSON.stringify);
			firLookupData1 = currentLookupFilter;
			console.log(firLookupData1, "check Here IMPORTANT inside");
			return nextLookupData;
		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

		function filterFirDataByKey1(currentLookupKey, nextLookupKey,
				firLookupData1, currentLookupValue, preLookUpValue) {
			var nextLookupData = [];
			var currentLookupFilter = firLookupData1.filter(function(
					object, index) {
				//console.log(object, index)
				if (object[currentLookupKey] == currentLookupValue && object['FORM'] == preLookUpValue) {
					return true;
				}
			});

			currentLookupFilter.forEach(function(object, index) {
				nextLookupData.push(object[nextLookupKey]);
			});
			nextLookupData = _.uniq(nextLookupData, JSON.stringify); 
			firLookupData2 = currentLookupFilter;
			nextLookupData = _.sortBy(nextLookupData, function(line) {return parseInt(line.split("-")[0].match(/(\d+)/)); });
			return nextLookupData;
		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		
		function filterFirDataByKey2(currentLookupKey, nextLookupKey,
				firLookupData2, currentLookupValue, preLookUpValue) {
			var nextLookupData = [];
			var nextLookupData1 = [];
			var currentLookupFilter = firLookupData2.filter(function(
					object, index) {
				//console.log(object, index)
				if (object[currentLookupKey] == currentLookupValue && object['SCHEDULE'] == preLookUpValue) {
					return true;
				}
			});

			currentLookupFilter.forEach(function(object, index) {
				nextLookupData.push(object[nextLookupKey]);

			});
			nextLookupData = _.uniq(nextLookupData, JSON.stringify);
			firLookupData3 = currentLookupFilter;
			return nextLookupData;
		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
		
		function filterFirDataByKey22(currentLookupKey, a, firLookupData2,
				currentLookupValue, preLookUpValue) {
			var nextLookupData = [];

			var currentLookupFilter = firLookupData2.filter(function(
					object, index) {
				//console.log(object, index)
				if (object[currentLookupKey] == currentLookupValue && object['SCHEDULE'] == preLookUpValue) {
					return true;
				}
			});

			currentLookupFilter.forEach(function(object, index) {
				nextLookupData.push(object[a]);

			});
			nextLookupData = _.uniq(nextLookupData, JSON.stringify);
			return nextLookupData;
		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
		
		function filterFirDataByKey3(currentLookupKey, nextLookupKey,
				firLookupData3, currentLookupValue) {
			var nextLookupData = [];
			var currentLookupFilter = firLookupData3.filter(function(
					object, index) {
				//console.log(object, index)
				if (object[currentLookupKey] == currentLookupValue) {
					return true;
				}
			});

			currentLookupFilter.forEach(function(object, index) {
				nextLookupData.push(object[nextLookupKey]);
			});
			nextLookupData = _.uniq(nextLookupData, JSON.stringify);
			firLookupData4 = currentLookupFilter;
			return nextLookupData;
		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
		
		function filterFirDataByKey4(currentLookupKey, nextLookupKey,
				firLookupData4, currentLookupValue) {
			var nextLookupData = [];
			var currentLookupFilter = firLookupData4.filter(function(
					object, index) {
				//console.log(object, index)
				if (object[currentLookupKey] == currentLookupValue) {
					return true;
				}
			});

			currentLookupFilter.forEach(function(object, index) {
				nextLookupData.push(object[nextLookupKey]);
			});
			nextLookupData = _.uniq(nextLookupData, JSON.stringify);
			return nextLookupData;
		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
		vm.firFormchanged = function(_item) {
			vm.CHECK_FIR_SCHEDULE = null;
			vm.CHECK_FIR_LINE = null;
			vm.CHECK_FIR_LINE_DESC = null;
			vm.CHECK_FIR_COL = null;
			vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
		vm.firSchedulechanged = function(_item) {
			vm.CHECK_FIR_LINE = null;
			vm.CHECK_FIR_LINE_DESC = null;
			vm.CHECK_FIR_COL = null;
			vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.firLinechanged = function(_item) {
			vm.CHECK_FIR_COL = null;
			vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.firColumnchanged = function(_item) {
			vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};
		vm.changeTaxSensData = function(flag, firTaxSensitive)
	    {
			if(flag == 'PERM_TEMP_FLAG')
			{
				if(firTaxSensitive.PERM_TEMP_FLAG != null)
					firTaxSensitive.permTempFlag = true;
			}
			else if(flag == 'POSTING_ACCT')
			{
				if(firTaxSensitive.POSTING_LOCAL_ACCT_DETAILS != undefined && firTaxSensitive.POSTING_LOCAL_ACCT_DETAILS.POSTING_LOCAL_ACCT_KEY != undefined )
					firTaxSensitive.postingAcctFlag = true;
			}
			else if(flag == 'OFFSET_ACCT')
			{
				if(firTaxSensitive.OFFSET_LOCAL_ACCT_DETAILS != undefined && firTaxSensitive.OFFSET_LOCAL_ACCT_DETAILS.OFFSET_LOCAL_ACCT_KEY != undefined )
					firTaxSensitive.offsetAcctFlag = true;
			}
			else if(flag == 'EP_TYPE')
			{
				if(firTaxSensitive.EP_TYPE_DETAILS != undefined && firTaxSensitive.EP_TYPE_DETAILS.EP_TYPE_CODE != '')
					firTaxSensitive.epTypeFlag = true;
			}
			else if(flag == 'LINE_5471')
			{
				if(firTaxSensitive.LINE_5471_DETAILS != undefined && firTaxSensitive.LINE_5471_DETAILS.LINE_NO != '')
					firTaxSensitive.line5471Flag = true;
			}
			else if(flag == 'LINE_8865')
			{
				if(firTaxSensitive.LINE_8865_DETAILS != undefined && firTaxSensitive.LINE_8865_DETAILS.LINE != '')
					firTaxSensitive.line8865Flag = true;
			}
			else if(flag == 'EP_DESC')
			{
				if(firTaxSensitive.EP_DESC != '' )
					firTaxSensitive.epDescFlag = true;
			}
	    }
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		function checkTaxSensitivityDetails()
		{
			vm.taxSensFlag = true;
			for(var i in vm.fir_tax_sensitive_data)
			{
				if(vm.fir_tax_sensitive_data[i].LOCAL_ACCT_TAX_SENSITIVE.value == 'Y')
				{
				 //checking for posting acct
					if(vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS == undefined || vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS == null)
					{
						vm.fir_tax_sensitive_data[i].postingAcctFlag = false;
						vm.taxSensFlag = false;
					}
					else if(vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS != undefined && (vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS.POSTING_LOCAL_ACCT_KEY == undefined || vm.fir_tax_sensitive_data[i].POSTING_LOCAL_ACCT_DETAILS.POSTING_LOCAL_ACCT_KEY == null) )
					{
						vm.fir_tax_sensitive_data[i].postingAcctFlag = false;
						vm.taxSensFlag = false;
					}
					else
						vm.fir_tax_sensitive_data[i].postingAcctFlag = true;
				//checking for offset acct
					if(vm.fir_tax_sensitive_data[i].OFFSET_LOCAL_ACCT_DETAILS == undefined || vm.fir_tax_sensitive_data[i].OFFSET_LOCAL_ACCT_DETAILS == null)
					{
						vm.fir_tax_sensitive_data[i].offsetAcctFlag = false;
						vm.taxSensFlag = false;
					}
					else if(vm.fir_tax_sensitive_data[i].OFFSET_LOCAL_ACCT_DETAILS != undefined && (vm.fir_tax_sensitive_data[i].OFFSET_LOCAL_ACCT_DETAILS.OFFSET_LOCAL_ACCT_KEY == undefined || vm.fir_tax_sensitive_data[i].OFFSET_LOCAL_ACCT_DETAILS.OFFSET_LOCAL_ACCT_KEY == null) )
					{	
						vm.fir_tax_sensitive_data[i].offsetAcctFlag = false;
						vm.taxSensFlag = false;
					}
					else
						vm.fir_tax_sensitive_data[i].offsetAcctFlag = true;
				  //checking for ep_type
					if(vm.fir_tax_sensitive_data[i].EP_TYPE_DETAILS == undefined || vm.fir_tax_sensitive_data[i].EP_TYPE_DETAILS == null)
					{
						vm.fir_tax_sensitive_data[i].epTypeFlag = false;
						vm.taxSensFlag = false;
					}
					else if( vm.fir_tax_sensitive_data[i].EP_TYPE_DETAILS.EP_TYPE_CODE == undefined || vm.fir_tax_sensitive_data[i].EP_TYPE_DETAILS.EP_TYPE_CODE == '')
					{
						vm.fir_tax_sensitive_data[i].epTypeFlag = false;
					}
					else
						vm.fir_tax_sensitive_data[i].epTypeFlag = true;
				 //checking for PERM_TEMP_FLAG
					if(vm.fir_tax_sensitive_data[i].PERM_TEMP_FLAG == undefined || vm.fir_tax_sensitive_data[i].PERM_TEMP_FLAG == null || vm.fir_tax_sensitive_data[i].PERM_TEMP_FLAG == '')
					{
						vm.fir_tax_sensitive_data[i].permTempFlag = false;
						vm.taxSensFlag = false;
					}
					else if( vm.fir_tax_sensitive_data[i].PERM_TEMP_FLAG.value == undefined || vm.fir_tax_sensitive_data[i].PERM_TEMP_FLAG.value == null)
					{
						vm.fir_tax_sensitive_data[i].permTempFlag = false;
						vm.taxSensFlag = false;
					}
					else
						vm.fir_tax_sensitive_data[i].permTempFlag = true;
				//checking for  ep_desc
					if(vm.fir_tax_sensitive_data[i].EP_DESC == undefined || vm.fir_tax_sensitive_data[i].EP_DESC == null || vm.fir_tax_sensitive_data[i].EP_DESC == '')
					{
						vm.fir_tax_sensitive_data[i].epDescFlag = false;
						vm.taxSensFlag = false;
					}
					else
						vm.fir_tax_sensitive_data[i].epDescFlag = true;
			   //checking for 5471 line
					if(vm.fir_tax_sensitive_data[i].LINE_5471_DETAILS == undefined || vm.fir_tax_sensitive_data[i].LINE_5471_DETAILS == null )
					{
						vm.fir_tax_sensitive_data[i].line5471Flag = false;
						vm.taxSensFlag = false;
					}
					else if( vm.fir_tax_sensitive_data[i].LINE_5471_DETAILS.LINE_NO == undefined || vm.fir_tax_sensitive_data[i].LINE_5471_DETAILS.LINE_NO == '')
					{
						vm.fir_tax_sensitive_data[i].line5471Flag = false;
						vm.taxSensFlag = false;
					}
					else
						vm.fir_tax_sensitive_data[i].line5471Flag = true;
			   //checking for 8865 line
					if(vm.fir_tax_sensitive_data[i].LINE_8865_DETAILS == undefined || vm.fir_tax_sensitive_data[i].LINE_8865_DETAILS == null )
					{
						vm.fir_tax_sensitive_data[i].line8865Flag = false;
						vm.taxSensFlag = false;
					}
					else if( vm.fir_tax_sensitive_data[i].LINE_8865_DETAILS.LINE == undefined || vm.fir_tax_sensitive_data[i].LINE_8865_DETAILS.LINE == '')
					{
						vm.fir_tax_sensitive_data[i].line8865Flag = false;
						vm.taxSensFlag = false;
					}
					else
						vm.fir_tax_sensitive_data[i].line8865Flag = true;
			   }
			}
		}
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
    }
});
    