/**
 * Created by 503037154 [Mallesh Nagothi] on 02/12/2018.
 */
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.goldService',[])
        .factory("GoldFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector','GENERAL_CONFIG', function($rootScope, $log,JsonObjectFactory,AlertService, $injector,GENERAL_CONFIG) {
            var factory = {};
            var returnObj = {};

            factory.updateEntityName = function(_callName , _data){
                console.log("Updating Gold Entity.......");
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=4j7huk";
                var addressId = _data.rowData.ADDRESS_ID;
                var entityName = _data.rowData.LEGAL_ENTITY_NAME;
                var entityPayloadUpper = [{OBJECT_ID: _data.rowData.KEY,ADDRESS_ID:addressId,LEGAL_ENTITY_NAME: entityName}];
                var jsonObj = JSON.stringify(entityPayloadUpper);
                var jsonSettings = '{}';
                console.log("Change Entity Name jsonObj:",jsonObj);
                var params = _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                service.saveJSON(url , params).then(function(data){
                    if(data.callSuccess === "1"){
                        AlertService.add("success", "Entity Name changed Successfully.",4000);
                        var args = {};
                        $rootScope.$emit('gridUpdate', args);
                    }else{
                        AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                    }
                });
            }
            return factory;
        }])

    return services;
});
