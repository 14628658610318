define([
    'angular',
    './post86EpHistoryReportCtrl',
    './post86EpHistoryReportService', 
], function () {
    'use strict';
    return angular.module('app.FIR_Post86_EP_History', ['app.post86EpHistoryReportCtrl', 'app.post86EpHistoryReportService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('post-86-e-p-history', {
                url: '/post-86-e-p-history',
                templateUrl: 'app/components/FIR_Post86_EP_History/post86EpHistoryReport.html',
                backdrop: 'static',
                data:{},
                access:""
            })
    }])

});