define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.reTriggerMessageController', [])

        .controller('reTriggerMessageController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter', 'reTriggerMessageServiceFactory', reTriggerMessageController]);
    	function reTriggerMessageController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter, reTriggerMessageServiceFactory) {
        var vm = this;

        vm.modalTitle = "Retrigger Message";
		vm.saveloader = false;
		vm.tags = [];
		vm.leKey = [];
		vm.leType = [];
		vm.submissionId = [];
		vm.filingGroupKey = [];
		angular.forEach(gridData, function(value, key) {
			vm.tags.push(value.data.LEID);
			vm.leKey.push(value.data.LE_KEY);
			vm.leType.push(value.data.LE_TYPE);
			vm.submissionId.push(value.data.SUBMISSION_ID);
			vm.filingGroupKey.push(value.data.FILING_GROUP_KEY);
		});
		console.log(vm.tags);

		vm.reTriggerMessage = function() {
			vm.saveloader = true;
			vm.showJsonLoader = true;
            var params = {"client_key": USER_SETTINGS.user.client_key, "le_key": vm.leKey.toString(), "le_type": vm.leType.toString(), "submissionId": vm.submissionId.toString()};

			return reTriggerMessageServiceFactory.saveRetriggerMessage(params).then(function (data) {
				if(data.data.callSuccess == "1" ){
					vm.saveloader = false;
					AlertService.add("success", "Message Retriggered Successfully" , 4000);
					vm.cancel();
				}else{
					vm.saveloader = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };

		vm.removeTag = function (index) {
			vm.tags.splice(index, 1);
	  	};

		vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		
    }
});