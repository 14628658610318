define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.schedule-PService',[])
        .factory("SchedulePServiceFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
	         
	           var returnObj = {};
	           var schedulePServiceFactory ={};
	           schedulePServiceFactory.url ={};
	           schedulePServiceFactory.url.save_schedule_P = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ze6q6h";
	           
	           schedulePServiceFactory.url.saveStockDiposition = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ze6q6h";
	           schedulePServiceFactory.url.saveProportionalInterest = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ze6q6h";
	           schedulePServiceFactory.url.savesupplementalInformation = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ze6q6h";
	           
	           schedulePServiceFactory.url.deleteSchedule = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=qsz4q9";
	           
	           schedulePServiceFactory.url.saveSchedPEnabledFlag = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=7g8pdb";
	           
	           
	           
	           var service = $injector.get('JsonObjectFactory');
			   var defer = $q.defer();
	           schedulePServiceFactory.saveScheduleP = function(_data,Details) {
	
		           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		           var jsonSettings =  JSON.stringify(_data);
		           var jsonObj = JSON.stringify(Details);
		               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
		           var params = filterParams;
		           params = _.merge({},  GlobalService.globalParams , params);
		           params =  _.extend({jsonObj:jsonObj}, params);
		           params =  _.extend({jsonSettings:jsonSettings}, params);
		//               params =  _.extend({jsonSettings:jsonSettings}, _data);
		           params.process_name =  "Schedule_P_Save";
		           service.saveJSON(schedulePServiceFactory.url.save_schedule_P,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   AlertService.add("success", "Data is successfully saved/edited.", 4000);
							defer.resolve(data);
						}else {
                           AlertService.add("error", data.errorMessage);
                           defer.reject(data);
                       }},function(data){
                       defer.reject(data);
                   });
                   return defer.promise;
			   };
		          /* var promise = $http({
		        	   method: "post",
		               url: schedulePServiceFactory.url.save_schedule_P,
		               data: params
		           }).then(function (response) {
		               var status = response.status;
		               return response.data;
		           });
		
		           return promise;
           };*/
           
           schedulePServiceFactory.saveStockDiposition = function(_data,Details) {
        		
	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "save_Stock_Diposition";
	           var promise = $http({
	        	   method: "post",
	               url: schedulePServiceFactory.url.save_schedule_P,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });
	
	           return promise;
       };
       
       
       schedulePServiceFactory.saveProportionalInterest = function(_data,Details) {
   		
           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
           var jsonSettings =  JSON.stringify(_data);
           var jsonObj = JSON.stringify(Details);
               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
           var params = filterParams;
           params = _.merge({},  GlobalService.globalParams , params);
           params =  _.extend({jsonObj:jsonObj}, params);
           params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
           params.process_name =  "save_Proportional_Interest";
           var promise = $http({
        	   method: "post",
               url: schedulePServiceFactory.url.save_schedule_P,
               data: params
           }).then(function (response) {
               var status = response.status;
               return response.data;
           });

           return promise;
   };
   
   
   schedulePServiceFactory.savesupplementalInformation = function(_data,Details) {
  		
       var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
       var jsonSettings =  JSON.stringify(_data);
       var jsonObj = JSON.stringify(Details);
           //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
       var params = filterParams;
       params = _.merge({},  GlobalService.globalParams , params);
       params =  _.extend({jsonObj:jsonObj}, params);
       params =  _.extend({jsonSettings:jsonSettings}, params);
//           params =  _.extend({jsonSettings:jsonSettings}, _data);
       params.process_name =  "save_supplemental_Information";
       var promise = $http({
    	   method: "post",
           url: schedulePServiceFactory.url.save_schedule_P,
           data: params
       }).then(function (response) {
           var status = response.status;
           return response.data;
       });

       return promise;
};
           schedulePServiceFactory.deleteSchedule = function(data){
      		 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
      		 var jsonObj = JSON.stringify(data);
      		 
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
      		 var promise = $http({
      			 method: "POST",
      			 url: schedulePServiceFactory.url.deleteSchedule,
      			 data: params
      		}). then(function (response){
      				//console.log(response.data)
      				return response.data;
      				})
      			
      			return promise;	
      	 };
      	 
      	 //// Check box enabled disabled on the grid
      	schedulePServiceFactory.saveSchedPEnabledFlag = function(_callName , _data){
            console.log("data", _data);
            console.log("callname",_callName);
            
            $rootScope.$broadcast('gridRefresh', false); 
            if(_callName === 'save_schd_p_enabled')
            {           	
            	
            	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
            	var editRow = [];
            	var tempJsonObj = [{
					"tax_year":GlobalService.globalParams.tax_year,
					"trans_type_key":_data.rowData.SCHEDULE_P_8865_ENABLED_TRANS_TYPE_KEY,
					"combination_key":_data.rowData.COMBINATION_KEY,
					"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
					"form_key":_data.rowData.FORM_KEY,
					"line_no":_data.rowData.SCHEDULE_P_8865_ENABLED_LINE_NO,
					"occurence":((_data.rowData.SCHEDULE_P_8865_ENABLED_OCCURRENCE == "0") ? null : _data.rowData.SCHEDULE_P_8865_ENABLED_OCCURRENCE),
					"line_attrib_key" : _data.rowData.SCHEDULE_P_8865_ENABLED_LINE_ATTRIB_KEY,
					"Attribute_Name":_data.rowData.SCHEDULE_P_8865_ENABLED_ATTRIB_NAME,
					"Attribute_Value":((_data.rowData.SCHD_P_ENABLED == 0 ) ? 'N' : 'Y'),
					"trans_dtls_key" : ((_data.rowData.SCHEDULE_P_8865_ENABLED_TRANS_DETAILS_KEY== "0") ? null : _data.rowData.SCHEDULE_P_8865_ENABLED_TRANS_DETAILS_KEY),
					"trans_status":"A"
				}];
				
				editRow.push(tempJsonObj);
				var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
				console.log("scheduleDetails--------------", scheduleDetails);
				
				schedulePServiceFactory.saveScheduleP(scheduleSettings, scheduleDetails).then(function(result) {
					if (result.errorMessage && result.errorMessage !== 'null') 
					{
						AlertService.add("error", result.errorMessage, 1000);
					} 
					else
					{
						//AlertService.add("success", "Data is successfully saved/edited", 1000);
						var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: _data.rowData.COMBINATION_KEY,object_id:_data.rowData.object_id}};
                        $rootScope.$emit('gridUpdate', args);
					}
				});
            }         	 
         }
           
return schedulePServiceFactory;
       }]);

   
   return services;
});