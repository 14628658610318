define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.DefaultsController', [])
					.controller('ComponentDefaultsCtrl', ['$rootScope','$scope','$http', '$timeout', 'GlobalService', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','DefaultServiceFactory', 'rowData', 'gridData', componentDefaultsCtrl])
			
			
			function componentDefaultsCtrl($rootScope, $scope, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,DefaultServiceFactory,rowData, gridData) {
				
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
			//	vm.crudLoading = false;
				vm.gridData = {};
				vm.sicCodeData = [];
				vm.yesNoData = [];
				vm.gpVarianceData = [];
				vm.countryData = [];
				vm.sourceData = [];
				vm.fdiiData = [];
				vm.methodData = [];
				vm.selectedSourceCode = null;
				vm.selectedFdiiCode = null;
				vm.selectedCountryCode = null;
				vm.selectedgpVariances = null;
				vm.selectedProrateCOGS = null;
				vm.selectedProrateFSC = null;
				vm.selectedFinancialCorp = null;
				vm.selectedSicCode = null;
				vm.selectedMethod = null;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
	            vm.scenario = filterParams.scenario;
	            vm.jcd_key  = GlobalService.globalParams.jcd_key 
				vm.isSaveClicked = false;
				vm.lockedRowExist = false;
				vm.allStatus = [];
				vm.QARLocked = 'N';
				vm.scenarioDesc = null;
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"p_scenario" : vm.scenario,
						"p_jcd_key" : vm.jcd_key,
						
						};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;	
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].SCENARIO_DESC == 'EXAM')
						vm.scenarioDesc = 'EXAM';
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].SCENARIO_DESC == 'EXAM'
						&& vm.QARLocked == 'Y'){
						vm.scenarioDesc = 'EXAM';
						vm.cancel();										
						AlertService.add("error","Updating Component Default is not available for selected Scenario." ,'4000');
						return;
					}
				});
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				
				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
						
						//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
						$scope.$watch(function() { return vm.is_locked }, function() {
							if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
									vm.cancel();										
									AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
									return;
							}						
							
						});
						
						for ( var data in vm.gridDataMultipleSelect) {
							if (vm.gridDataMultipleSelect[data].data != undefined) {
								vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect[data].data);
								vm.allStatus.push(vm.gridDataMultipleSelect[data].data.STATUS_FLAG);
								
								 if(vm.gridDataMultipleSelect[data].data.STATUS_FLAG == 'Locked') {                             
	                            	 vm.lockedRowExist = true;
	                            }
								 
								if(vm.defaultsMultipleSelected[data].BASKET_0 != '' || vm.defaultsMultipleSelected[data].FDII_0 != '' || vm.defaultsMultipleSelected[data].COUNTRY_0 != '' || vm.defaultsMultipleSelected[data].PRORATE_COGS_0 != ''  ||
									vm.defaultsMultipleSelected[data].GP_VARIANCES_0 != ''  || vm.defaultsMultipleSelected[data].SIC_CODES_0 != ''  || vm.defaultsMultipleSelected[data].FSE_0 != ''  ||
									vm.defaultsMultipleSelected[data].FINANCIAL_CORP_0 != '' ){
									vm.defaultsMultipleSelected[data]["operation_type"] = "U";
								}else{
									vm.defaultsMultipleSelected[data]["operation_type"] = "I";
									vm.scenarioDesc = vm.defaultsMultipleSelected[data].SCENARIO;
									
								}
								
								if(vm.defaultsMultipleSelected[data].operation_type == 'U')
									vm.QARLocked = 'Y';
								if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].SCENARIO_DESC == 'EXAM'
									&& vm.defaultsMultipleSelected[data].operation_type == 'U' ){
									vm.cancel();										
									AlertService.add("error","Updating Component Default is not available for selected Scenario." ,'4000');
									return;
								}
							}

						}
						
						vm.selectedMECode = vm.gridDataMultipleSelect[0].data.PARENT_ME_CODE;
						
						vm.otherSelectedMe = _.filter(vm.gridDataMultipleSelect, function(o){
								return o.data.PARENT_ME_CODE !== vm.selectedMECode
							})
						let lockedInfo = _.includes(vm.allStatus, 'Locked'),unLockedInfo = _.includes(vm.allStatus, 'Unlocked'),undefinedInfo = _.includes(vm.allStatus, undefined);
						
						
						if(lockedInfo && (unLockedInfo || undefinedInfo)) {
							AlertService.add("error","Please select Locked or Unlocked or empty", 4000);							
							throw new Error("Please select only Locked or Unlocked or empty!");
							return;
						}
						
						
						if(lockedInfo && vm.allStatus.length === vm.gridDataMultipleSelect.length) {
							AlertService.add("warning","Entitiy is locked ", 4000);
							return;
						}
						if(vm.otherSelectedMe && vm.otherSelectedMe.length > 0 ){
								AlertService.add("error","Please select components from same Parent ME Code", 400000);	
								throw new Error("Please select components from same Parent ME Code!");
						}
						
						
						console.log("List-----" + vm.defaultsMultipleSelected);
					}

				} 
				
				
				vm.getAttribMast = function() {
					var params = {
							"action_code" : 'bsob9d',
							"p_scenario" : vm.scenario,
							"p_jcd_key" : vm.jcd_key,
//							"tax_year" : GlobalService.globalParams.tax_year,
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.attribMastData = data.jsonObject;
			                }
						});
				}
				
				
				vm.getCodeMastData = function() {
					var params = {
						"action_code" : 'dbty56',
						"p_scenario" : vm.scenario,
						"p_jcd_key" : vm.jcd_key,
						"cc_key" :vm.gridDataMultipleSelect[0].data.COMBINATION_KEY,
					};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.sicCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SIC_CODE_PER_ME'], p.CODE_GROUP);  
			                		});
			                	if(vm.sicCodeData.length == 0){
			                		vm.sicCodeData = _.filter(data.jsonObject, function(p){
				                		  return _.includes(['SIC_CODE'], p.CODE_GROUP);  
				                		});
			                	}
			                	vm.countryData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.sourceData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.fdiiData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['FDII_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.gpVarianceData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['GP_VARIANCE'], p.CODE_GROUP);  
			                		});
			                	vm.yesNoData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['YES_NO'], p.CODE_GROUP);  
			                		});
			                	vm.prorateYesNoData = _.filter(data.jsonObject, function(p){
									  return _.includes(['YES_NO'], p.CODE_GROUP);  
								}); 
								vm.methodData = _.filter(data.jsonObject, function(p){
									return _.includes(['METHOD_CODE_EXPAA'], p.CODE_GROUP);  
								  });
			                	
			                	if (vm.scenarioDesc == 'EXAM'){
			                		_.remove(vm.gpVarianceData, {CODE_DESCRIPTION: 'Prorate'});
			                		_.remove(vm.prorateYesNoData, {CODE_DESCRIPTION: 'Yes'});
			                	}
			                }
						});
				}
				

				vm.getCodeMastData();
				vm.getAttribMast();

				vm.removeDefaultsRow = function(name){					
					var index = vm.defaultsMultipleSelected.indexOf(name);
					vm.defaultsMultipleSelected.splice(index, 1);					
				};
				
				
				//reset the form
				vm.reset = function(){
					console.log(" Need to reset the fields. ");
					vm.selectedSourceCode = '';
					vm.selectedFdiiCode = '';
					vm.selectedCountryCode = '';
					vm.selectedgpVariances = '';
					vm.selectedProrateCOGS = '';
					vm.selectedFSC = '';
					vm.selectedFinancialCorp = '';
					vm.selectedSicCode = '';
					vm.selectedMethod = '';
				};
				

				vm.save = function() {
					 vm.isSaveClicked = true;
					/*if (vm.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
		                return;
		              }
					
					vm.crudLoading = true; */
					
					if(vm.defaultsMultipleSelected.length == 0 ){
						AlertService.add("error", "Please select atleast one Component Default Mapping");
//						vm.crudLoading = false;
						return;
					}
					
					var insertExist = _.find(vm.defaultsMultipleSelected, ['operation_type', 'I']);
					
					if(insertExist != undefined){
						if(vm.selectedSourceCode == null || vm.selectedFdiiCode == null || vm.selectedCountryCode == null || vm.selectedgpVariances == null ||  vm.selectedProrateCOGS == null || 
						vm.selectedFSC == null || vm.selectedFinancialCorp == null || vm.selectedSicCode == null || vm.selectedMethod == null){
							 vm.isSaveClicked = false;
							AlertService.add("error", "Please select all the Defaults");
							//vm.crudLoading = false;
							return;
						}
					}else{
						if(vm.selectedSourceCode == null && vm.selectedFdiiCode == null && vm.selectedCountryCode == null && vm.selectedgpVariances == null &&  vm.selectedProrateCOGS == null && 
							vm.selectedFSC == null && vm.selectedFinancialCorp == null && vm.selectedSicCode == null && vm.selectedMethod == null){
							 vm.isSaveClicked = false;
								AlertService.add("error", "Please select atleast one Default");
								//vm.crudLoading = false;
								return;
							}
					}

					var returnClobSettingsObj = {};
					var defaultsChangeDetails = [];
					for(var key in vm.defaultsMultipleSelected){
						var returnObj = {};
						
						returnObj["object_id"] = gridData[key].data.object_id;
						returnObj['ho_leid'] = gridData[key].data.HO_LEID;
						returnObj['leid'] =  gridData[key].data.LEID;
						returnObj['cdr_number'] = gridData[key].data.CDR_NO;
						returnObj['reporting_period'] = gridData[key].data.REPORTING_PERIOD;
						returnObj['me_code'] = gridData[key].data.ME_CODE;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = (vm.selectedSourceCode != null) ? vm.selectedSourceCode : gridData[key].data.SOURCE_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'FDII']).ATTRIB_KEY + '_0']=  (vm.selectedFdiiCode != null) ? vm.selectedFdiiCode : gridData[key].data.FDII_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = (vm.selectedCountryCode != null) ? vm.selectedCountryCode :  gridData[key].data.COUNTRY_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'PRORATE_COGS']).ATTRIB_KEY +'_0'] = (vm.selectedProrateCOGS != null) ? vm.selectedProrateCOGS : gridData[key].data.COGS_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'GP_VARIANCES']).ATTRIB_KEY +'_0'] = (vm.selectedgpVariances != null) ? vm.selectedgpVariances : gridData[key].data.GROSS_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'SIC_CODES']).ATTRIB_KEY +'_0'] = (vm.selectedSicCode != null) ? vm.selectedSicCode : gridData[key].data.SIC_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'FSE']).ATTRIB_KEY +'_0'] = (vm.selectedFSC != null) ? vm.selectedFSC : gridData[key].data.FSC_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'FINANCIAL_CORP']).ATTRIB_KEY +'_0'] = (vm.selectedFinancialCorp != null) ? vm.selectedFinancialCorp : gridData[key].data.FINANCIAL_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'METHOD']).ATTRIB_KEY +'_0'] = (vm.selectedMethod != null) ? vm.selectedMethod : gridData[key].data.METHOD;
						returnObj['COMBINATION_KEY'] = vm.defaultsMultipleSelected[key].COMBINATION_KEY;
						returnObj['operation_type'] = vm.defaultsMultipleSelected[key].operation_type ;
						var message = "Defaults has been successfully saved/updated";
						defaultsChangeDetails.push(returnObj);
						
					}
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
//					returnClobSettingsObj['me_code'] = gridData[0].data.ME_CODE;

					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);

				};

				function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
					DefaultServiceFactory.saveDefaults(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {

								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
									 vm.isSaveClicked = false;
									AlertService.add("error", result.data.errorMessage, 4000);
								} else {
									//vm.crudLoading = false;
									AlertService.add("success", message, 4000);
									var args = {
											me_code: returnClobSettingsObj.me_code,
		                                    gridFilter: {
		                                    	me_code: returnClobSettingsObj.me_code
		                                    }
		                                };
									$uibModalInstance.dismiss('cancel');
									$rootScope.$emit('gridUpdate', args);
                            	};

							});
				}		
				
			}

			return controllers;
		});