define(["angular"], function () {
    "use strict";
    var directives = angular
        .module("app.dashboardDatatable", [])

        .directive('gtDatatable', gtDatatable);


    gtDatatable.$inject = ['$compile', '$filter', '$uibModal', '$templateCache'];
    function gtDatatable($compile, $filter, $uibModal, $templateCache) {
        // Usage:The parent div of the grid should have position:relative as the grid will fill the parent as absolute.
        //
        // Creates:
        //
        var directive = {
            link: link,
            scope: {
                tableId: '@',
                groupBy: '=',
                searchable: '=',
                footerType: "@",
                headerGroupBy: "=",
                sheetData: "=",
                childTableOptions: '=',
                parentTableOptions: "=",
                childTableColumnProperties: "=",
                parentTableColumnProperties: "=",
                topFilter: '=',
                temp: '@'
            },

            template: `<div class='grid-wrapper'>
        
                    </div>`,
            controller: ['$scope', gtDatatableController]
        };
        return directive;

        function link(scope, element, attrs) {
            if (scope.childTableOptions) {
                scope.childTableOptions.columns = buildChildTableColumns(scope.childTableColumnProperties.columns)
            }


            var childTableOptions = _.cloneDeep(scope.childTableOptions);
            var totalRevenues = {};
            var selectedRevenueProperty = [];
            var actualOptions;
            var groupedArray = [];
            var resizerSensor;
            var global;
            var oldGroupBy;
            var oldData;
            var remainingData;
            var nonAggregateColumnsSafeCopy = [];
            var trs;

            if (scope.parentTableColumnProperties.nonAggregateColumns) {
                nonAggregateColumnsSafeCopy = scope.parentTableColumnProperties.nonAggregateColumns.slice(0);
            }
            var parentTableOptionsSafeCopy = angular.copy(scope.parentTableOptions)
            selectedRevenueProperty = scope.parentTableColumnProperties.aggregateColumns;

            $(element[0].firstChild).append("<table id=" + scope.tableId + " class='table table-bordered table-striped parent-table table-responsive table-condensed'></table>");

            console.log("scope.id : 999999999999999999999999", scope.tableId)

            // scope.parentTableOptions.searching = scope.searchable;

            scope.table = scope.groupBy ? groupTable() : renderGrid();

            $(element[0]).find('tfoot').addClass(scope.footerType);

            var panel = element.closest('.gt-dashboard-ex');
            // chart.setSize(panel.width()-50,panel.height());
            if (panel.length > 0) {
                var grids = panel.find('.grid-wrapper');
                if (grids.length > 1) {
                    grids.css({
                        position: 'relative'
                    })
                }
                var timeout;
                resizerSensor = new ResizeSensor(panel, function () {

                    clearTimeout(timeout);

                    timeout = setTimeout(doneResizing, 50);//This is to avoid calling the setsize on each animation frame

                    function doneResizing() {
                        // console.log('resize detected, panel height',panel.height())
                        var grids = panel.find('.grid-wrapper');
                        console.log(grids.length + " grids found in the panel.");
                        grids.height((100 / grids.length) - 5 + '%');
                        if (scope.groupBy) {
                            scope.table.fnDraw();
                            console.log('table redrawn');
                        }
                    }
                });
            }

            registerEvents();
            // buildDataColumns();

            var watcherForGroupBy = scope.$watch('groupBy', function (newVal, oldVal) {
                if (newVal && !_.isEqual(newVal, oldVal)) {

                    if (scope.groupBy) {
                        oldGroupBy = oldVal;
                        scope.groupBy = newVal;
                        if (!nonAggregateColumnsSafeCopy.includes(scope.groupBy)) {
                            for (var i = 0; i < nonAggregateColumnsSafeCopy.length; i++) {
                                if (nonAggregateColumnsSafeCopy[i] === oldGroupBy) {
                                    nonAggregateColumnsSafeCopy[i] = scope.groupBy
                                }
                            }
                        }
                        groupTable(false);
                    } else {
                        filterTable(scope.sheetData);
                    }
                }

            })
            var watcher = scope.$watch('sheetData', function (newVal, oldVal) {
                if (newVal && !_.isEqual(newVal, oldVal)) {
                    // if (oldData !== oldVal) {
                    //     oldData = newVal;
                    if (scope.groupBy) {
                        if (!nonAggregateColumnsSafeCopy.includes(scope.groupBy)) {
                            for (var i = 0; i < nonAggregateColumnsSafeCopy.length; i++) {
                                if (nonAggregateColumnsSafeCopy[i] === oldGroupBy) {
                                    nonAggregateColumnsSafeCopy[i] = scope.groupBy
                                }
                            }
                        }
                        groupTable(false);
                    } else {
                        filterTable(scope.sheetData);
                    }

                }
                // }
            })

            scope.$on('$destroy', function () {
                console.log('datatable destroyed');
                watcher();
                watcherForGroupBy();
                element.remove();
                if (resizerSensor)
                    resizerSensor.detach();
            })

            ////////////

            function renderGrid() {

                if (scope.parentTableOptions.footer) {
                    var footer = "<tfoot><tr>";
                    _.each(scope.parentTableOptions.columns, function (col) {
                        footer += "<th></th>"
                    })
                    footer += "</tr></tfoot>";
                    $(element).find('tfoot').remove();
                    $(element).find("#" + scope.tableId).append(footer);
                    $(element[0]).find('tfoot').addClass(scope.footerType);
                }
                if (scope.table) {
                    scope.table.fnClearTable();
                    scope.table.fnDestroy();
                }




                scope.parentTableOptions.deferRender = true;
                buildDataColumns(scope.parentTableOptions);
                contextGroupBy(scope.parentTableOptions, $compile, scope, scope.columnFilters);
                // setFirstColumn(scope.parentTableOptions)
                scope.table = $(element[0].firstChild).find("#" + scope.tableId).dataTable(scope.parentTableOptions);

                // scope.table.fnDraw();
                return scope.table
            }



            function groupTable(changeData, groupingSettingChanged) {
                // if (groupingSettingChanged) {
                //     var groupedData = _.groupBy(dataSafeCopy, scope.groupBy);
                // }
                // else {

                var groupedData = _.groupBy(scope.sheetData, scope.groupBy);
                // }
                groupedArray = [];

                getTotalRevenue(groupedData, selectedRevenueProperty)




                //If Correct Group Parameter Given
                if (!groupedData.undefined) {
                    buildGroupedArray(groupedData, totalRevenues);
                    actualOptions = angular.copy(childTableOptions);
                    // getGroupedArray(groupedData)
                    // if (!groupingSettingChanged) {
                    //     actualOptions = angular.copy(scope.childTableOptions);
                    // }

                    if (changeData) {
                        // table.fnClearTable();
                        filterTable(groupedArray);
                        return scope.table;
                    } else {
                        scope.parentTableOptions = optionsForParentTable(groupedArray)
                        var table = renderGrid();
                        // if (scope.childTableOptions.footer) {
                        //     var footer = "<tfoot><tr>";
                        //     _.each(scope.childTableOptions.columns, function (col) {
                        //         footer += "<th></th>"
                        //     })
                        //     footer += "</tr></tfoot>";
                        //     $(element).find("#" + scope.tableId).append(footer);
                        // }
                        // if (scope.table) {
                        //     scope.table.fnClearTable();
                        //     scope.table.fnDestroy();
                        // }
                        // var table = $(element[0].firstChild).find("#" + scope.tableId).dataTable(scope.childTableOptions);
                        // table.fnDraw();
                        return table;
                    }


                } else {
                    element[0].textContent = "Invalid Group-By Parameter";
                    console.log("Invalid Group-By Parameter");
                    return null;
                }
            }
            function filterTable(data) {
                scope.table.fnClearTable();
                scope.table.fnAddData(data);
                scope.table.fnDraw();
                console.log('data filtered and table redrawn');

            }

            function getTotalRevenue(groupedData, selectedRevenueProperty) {

                console.log(" selectedRevenueProperty ", selectedRevenueProperty);

                console.log(" groupedData ", groupedData);

                for (var key in groupedData) {
                    totalRevenues[key] = [];
                    //Aggreagating amounts based on the grouping
                    var total = {};
                    _.each(groupedData[key], function (item) {
                        _.each(selectedRevenueProperty, function (amount) {
                            total[amount] = parseInt(total[amount] || 0) + parseInt(item[amount]);


                        })
                    })

                    for (var i = 0; i < selectedRevenueProperty.length; i++) {
                        for (var k in total) {
                            if (k === selectedRevenueProperty[i]) {
                                totalRevenues[key].push({ [selectedRevenueProperty[i]]: total[k] });
                            }
                        }
                    }


                    console.log(totalRevenues[key], "  ------------------------------------------------- totalRevenues[key]");


                }
            }

            function buildChildTableColumns(columns) {
                var tableColumns = [];
                for (var i = 0; i < columns.length; i++) {
                    tableColumns.push({ title: columns[i].replace(/_/g, " "), data: columns[i] });
                }
                return tableColumns;
            }

            function optionsForParentTable(groupedArray) {
                //TODO
                _.map(parentTableOptionsSafeCopy.columns, function (value, index) {
                    if (value.data === oldGroupBy) {
                        value.data = scope.groupBy;
                        value.mData = scope.groupBy;
                    }
                })
                parentTableOptionsSafeCopy['data'] = groupedArray;
                parentTableOptionsSafeCopy['aaData'] = groupedArray;
                parentTableOptionsSafeCopy['searching'] = scope.searchable;
                return parentTableOptionsSafeCopy
            }

            function buildGroupedArray(groupedData, totalRevenues) {
                var tableDataCollection = {};
                for (var key in groupedData) {
                    tableDataCollection[key] = {};
                    _.forEach(nonAggregateColumnsSafeCopy, function (property, index) {
                        _.forEach(groupedData[key], function (value, i) {
                            tableDataCollection[key][property] = value[property]
                        });
                    });
                    // tableDataCollection[key]["name"] = key;
                    tableDataCollection[key]["children"] = groupedData[key];
                    for (var i = 0; i < groupedData[key].length; i++) {
                        if (groupedData[key][i].commentObject !== undefined && groupedData[key][i].commentObject !== {})
                            tableDataCollection[key]["commentObject"] = groupedData[key][i].commentObject
                    }

                    if (scope.headerGroupBy) {
                        var groupByProperty = []

                        _.chain(groupedData[key]).find(function (value, index) {
                            //Change this
                            return value
                        }).map(function (val, key) {
                            if (key === scope.headerGroupBy[0]) {
                                groupByProperty.push(val)
                            }
                        }).value()
                        tableDataCollection[key][scope.headerGroupBy[0]] = groupByProperty.toString();
                    }
                }
                for (var key in totalRevenues) {
                    for (var i = 0; i < totalRevenues[key].length; i++) {
                        for (var k in tableDataCollection) {
                            if (key === k) {
                                tableDataCollection[k][_.keys(totalRevenues[key][i]).toString()] = parseInt(_.values(totalRevenues[key][i]));
                            }
                        }
                    }
                }
                getGroupedArray(tableDataCollection);
            }

            function getGroupedArray(_data) {
                for (var key in _data) {
                    groupedArray.push(
                        _data[key]
                    )
                }
            }


            function buildDataColumns(table) {
                for (var i = 0; i < table.columns.length; i++) {
                    if (table.columns[i].data !== null) {

                        table.columns[i].fnCreatedCell = function (nTd, sData, oData, iRow, iCol) {
                            for (let j = 0; j < table.rowButtons.length; j++) {

                                $(nTd).append(table.rowButtons[j].template)

                            }

                            if (oData.commentObject && oData.commentObject[iCol]) {
                                $(nTd).prepend("<span><i class='fa fa-comment-o info-icon' id=" + iRow + "-" + iCol + "></i></span>")
                            }

                            $(nTd).find('.add-comment-btn').on('click', openModal())

                            $(nTd).find('.info-icon').on('click', function (e) {

                                var tr = $(this).closest('tr');
                                var row = scope.table.api().row(tr);
                                scope.commentObject = oData.commentObject[iCol];

                                var buildTableObject = {
                                    sData: sData,
                                    rowNum: iRow,
                                    colNum: iCol,
                                    event: e,
                                    commentData: scope.commentObject,
                                    row: row,
                                    tr: tr
                                }



                                $(e.target).toggleClass("info-icon-active")

                                //If table is present in row child
                                if (row.child.isShown()) {
                                    //If comment table is of same cell 
                                    if (!$(e.target).hasClass('info-icon-active')) {
                                        //Slide up the previous comment table
                                        commentTableSlideUp(buildTableObject)
                                    }
                                    //If comment table is of different cell /child data table is displayed
                                    else {
                                        //In case child data table is shown then toggle '-' icon to '+' icon
                                        $(tr).removeClass('shown')
                                        //Remove active info icon from row cells
                                        $(tr).find('.info-icon').removeClass('info-icon-active')
                                        //Add active info icon on row cell
                                        $(e.target).addClass('info-icon-active')
                                        //Slide up the previous comment/child data table and show current comment table
                                        commentTableSlideUp(buildTableObject, true)
                                    }
                                }
                                //If no table present in row child then display current comment table
                                else {

                                    buildMessageTable(buildTableObject)
                                }
                            })


                        }
                    }
                }

            }

            function openModal(comment) {
                return function (e) {
                    var ctrlString = "AddCommentController as ctrl";
                    scope.gridData = {};
                    if (comment) {
                        ctrlString = "EditCommentController as ctrl";
                        scope.gridData.comment_key = comment.comment_key;
                    }
                    scope.rowData = [];
                    scope.colData = [];
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: "app/components/messages/templates/add-comment-modal-tpl.html",
                        controller: ctrlString,
                        size: "md",
                        resolve: {
                            rowData: function () {
                                return scope.rowData;
                            },
                            colData: function () {
                                return scope.colData;
                            },
                            gridData: function () {
                                return scope.gridData;
                            }
                        }
                    });
                    modalInstance.result.then(function (selectedItem) {
                        scope.selected = selectedItem;
                    }, function () {
                        if (comment) {
                            if (isUpdateClicked)
                                $scope.loadComment($scope._SelectedComment);
                            isUpdateClicked = false;
                        }
                    });
                }
            }

            function commentTableSlideUp(tableObject, flag) {
                scope.$apply();
                //Check if row child is comment table or child table
                var element = $((tableObject.tr)[0].nextElementSibling).find('.comment-table')[0] ? $((tableObject.tr)[0].nextElementSibling).find('.comment-table')[0] : $((tableObject.tr)[0].nextElementSibling).find('.child-data-table')[0];

                $(element, tableObject.row.child()).slideUp(function () {
                    tableObject.row.child.hide();
                    // tr.removeClass('shown');
                    if (flag)
                        buildMessageTable(tableObject)
                })
                scope.$apply();
            }



            function buildMessageTable(tableObject) {
                var table = '<div class="comment-table"><table class="table table-bordered message-table" id=' + tableObject.rowNum + '-' + tableObject.colNum + '>' +
                    '<tbody>' +
                    '<tr>' +
                    '<td class="v-middle min-width-250" rowspan="2"><div class="short-name">{{parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].shortName}}</div><div class="name-time-wrapper"><div class="message-name"> {{parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].name}} </div><div class="message-time">' + tableObject.commentData.messageTime + '</div></div></td>' +
                    '<td rowspan="1">{{parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].message}} </td>' +
                    '<td class="v-middle min-width-250" rowspan="2"><div ng-repeat="file in parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].attach" class="message-attachments"><i class="fa fa-file file-icon"></i><span class="file-name">{{file.fileName}}</span></td>' +
                    '<td class="v-middle text-center" rowspan="2"><div><i class="fa fa-remove delete-icon"></i></div><div><i class="fa fa-pencil edit-icon"></i></div></td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td rowspan="1">Cell Data' + ': ' + tableObject.sData + '</td>' +
                    '</tr>' +
                    '</tbody>' +
                    ' </table></div>'
                // $(table).slideDown(400)
                var html = $compile(table)(scope)
                scope.$apply()
                tableObject.row.child(html).show();
                $('div.comment-table', tableObject.row.child()).slideDown()
                // $(table).slideDown(400)
            }




            function registerEvents() {
                // var $demo1;
                //On Drilldown
                $(element).on('click', 'td.details-control', function (e) {

                    global = e;
                    var tr = $(this).closest('tr');
                    var row = scope.table.api().row(tr);

                    //If table is present in row child
                    if (row.child.isShown()) {
                        //If comment table is already present then slide it up and show child data table
                        if ($(tr).find('.info-icon').hasClass('info-icon-active')) {
                            $('div.comment-table', row.child()).slideUp(function () {
                                row.child.hide();
                                $(tr).find('.info-icon').removeClass('info-icon-active')
                                renderChildDataTable(actualOptions, row, e, tr)
                            })

                        }
                        //If Comment Table is not present but child data table is present then toggle 
                        else {
                            row.child.hide();
                            tr.removeClass('shown');
                        }
                        // scope.table.fnDraw();
                        console.log('table redrawn');
                    }
                    //If no table is shown
                    else {

                        renderChildDataTable(actualOptions, row, e, tr)


                    }






                    ////////////






                });




            }

            function filteredChildData(data, filter) {
                var filteredData = [];
                for (var i = 0; i < filter.value.length; i++) {
                    //Get Data For Each Top Level Filter
                    var subFilteredData = _.chain(data).filter(function (val, index) {
                        if (_.keys(val).includes(filter.predicate)) {

                            if (filter.value[i] === val[filter.predicate]) {
                                return val
                            }
                        }
                        else {
                            return true;
                        }

                    }).value()

                    filteredData = filteredData.concat(subFilteredData)
                }
                return filteredData
            }

            function renderChildDataTable(actualOptions, row, e, tr) {
                if (actualOptions.isAjax && actualOptions.ajax !== undefined) {

                    actualOptions.ajax.dataSrc = function (json) {
                        //Filter Child Ajax Data With Top Level Filters
                        var childData = _.chain(json).filter([scope.groupBy, row.data()[scope.groupBy]]).value()
                        var childDataSafeArr = childData.slice(0);

                        _.forEach(scope.topFilter, function (value) {
                            if (value.value.length > 0) {
                                childDataSafeArr = filteredChildData(childDataSafeArr, value);
                            }
                        })

                        return childDataSafeArr


                    }
                    actualOptions.paging = false;
                }
                else {
                    actualOptions.fixedHeader = true;
                    actualOptions.paging = true;
                    actualOptions.scroller = true;
                    actualOptions.deferRender = true;
                    actualOptions.scrollY = 300;
                    actualOptions.scrollCollapse = true;
                    actualOptions.scrollResize = true;
                    actualOptions.data = row.data().children;
                }

                var temp = "<div class='wrapper child-data-table'><table id='table-" + e.clientX + "' class='table table-bordered table-striped sticky-header  table-condensed' style='width:100%'></table></div>"
                row.child(temp).show();
                // $('div.comment-table', row.child()).slideDown()
                tr.addClass('shown');
                var dataTableOptions = Object.assign({}, actualOptions);
                dataTableOptions.searching = scope.searchable;
                // dataTableOptions.deferRender = true;
                // dataTableOptions.responsive = true;
                // dataTableOptions.dom = 't';
                scope.childTable = $("#table-" + e.clientX).dataTable(dataTableOptions);
            }
        }

        function gtDatatableController($scope) {
            console.log("Datatable Controller")
            $scope.groupNames = [];// Only non revenue properties
            // $scope.dataValue = "positive";
            $scope.columnFilters = {};

            for (var columnType in $scope.parentTableColumnProperties) {
                for (var i = 0; i < $scope.parentTableColumnProperties[columnType].length; i++) {
                    $scope.columnFilters[columnType] = {}
                    $scope.columnFilters[columnType][$scope.parentTableColumnProperties[columnType][i]] = {}
                }

            }

            var tableColumnFilters = {
                aggregateColumns: {
                    tab1: {
                        filterExtreme: null,
                        isClearFilterDisabled: true
                    }
                },
                nonAggregateColumns: {
                    tab1: null,
                    tab2: {
                        searchText: null
                    }
                }
            }

            for (var columns in tableColumnFilters) {
                for (var columnType in $scope.columnFilters) {
                    if (columns === columnType) {
                        for (var key in $scope.columnFilters[columnType]) {
                            $scope.columnFilters[columnType][key] = tableColumnFilters[columns]
                        }


                    }
                }
            }

            console.log($scope.columnFilters)

            $scope.filterObject = {}
            $scope.filterObject.aggregateTab = {};
            $scope.filterObject.nonAggregateTab = {};

            $scope.filterObject.aggregateTab.tab1 = {
                filterExtreme: null,
                isClearFilterDisabled: true,
            }
            $scope.filterObject.nonAggregateTab.tab2 = {
                searchText: null
            }



            var parentTableDataSafeCopy;
            var sheetDataKeys = _.keys($scope.sheetData[0]);


            _.forEach(sheetDataKeys, function (value, i) {


                if (value !== 'object_id' && value !== 'AMOUNT' && value !== 'TAX_YEAR' && !$scope.parentTableColumnProperties.aggregateColumns.includes(value)) {
                    var newValue = value.split("_").join(" ");
                    $scope.groupNames.push({ name: newValue, oldValue: value, active: false })
                }

            })

            $scope.groupNames.map(function (name) {
                if (name.oldValue === $scope.groupBy) {
                    name.active = true;
                }
                return name;
            })

            $scope.groupByList = function () {
                $scope.toggleGroupBy = !$scope.toggleGroupBy
            }

            $scope.groupSelect = function (value) {
                $scope.groupBy = value;

                $scope.groupNames.map(function (name) {
                    if (name.oldValue !== $scope.groupBy) {
                        name.active = false;
                    }
                    else if (name.oldValue === $scope.groupBy) {
                        name.active = true;
                    }
                    return name;
                })
            }
            $scope.stopTabClose = function (e) {
                if (e) {
                    e.stopPropagation()
                }
            }
            $scope.searchTableMessage = {}
            $scope.searchTable = function (searchText) {
                console.log($scope.columnFilters)
                $scope.searchTableMessage.isError = false;
                var searchFilterData = $scope.parentTableOptions.data.filter(function (item) {
                    return item[$scope.groupBy].toLowerCase().includes(searchText.toLowerCase());
                })
                if (searchFilterData.length > 0) {
                    $scope.table.fnClearTable();
                    $scope.table.fnAddData(searchFilterData);
                    $scope.table.fnDraw()
                }
                else {
                    $scope.searchTableMessage = {
                        isError: true,
                        errorMessage: "No record found. Please correct it."
                    }

                }

            }

            $scope.radioChange = function (value) {

                parentTableDataSafeCopy = $scope.parentTableOptions.data.slice(0);
                $scope.filterObject.aggregateTab.tab1.isClearFilterDisabled = false;

                var newParentTableData = []
                if (value) {

                    newParentTableData = $scope.parentTableOptions.data.filter(function (val) {
                        return val.AMOUNT > 0
                    })
                }
                else {
                    newParentTableData = $scope.parentTableOptions.data.filter(function (val) {
                        return val.AMOUNT < 0
                    })
                }
                $scope.table.fnClearTable();
                if (newParentTableData.length > 0) {
                    $scope.table.fnAddData(newParentTableData);
                }
                $scope.table.fnDraw()
                contextGroupBy($scope.parentTableOptions, $compile, $scope, $scope.columnFilters)


            }

            $scope.clearAggregateFilter = function () {

                $scope.table.fnClearTable();
                $scope.table.fnAddData(parentTableDataSafeCopy);
                $scope.table.fnDraw();
                $scope.filterObject.aggregateTab.tab1.filterExtreme = null;
                $scope.filterObject.aggregateTab.tab1.isClearFilterDisabled = true;

            }

            // $scope.dropdownClick = function(e){
            //     e.stopPropagation()
            //     console.log("Hello")
            // }
            // $scope.test = function(e){
            //     e.stopPropagation()
            //     console.log("Hello")
            // }
            // $scope.test1 = function(e){
            //     e.stopPropagation()
            //     console.log("Hello")
            // }

            // $scope.toggled = function(open){
            //     console.log(open)
            // }

            $scope.status = {
                isopen: false
            }

        }
    }

    function dataTableColumnFilterDropdownTemplate(tabs) {
        var buttons = {};


        var dropdownTemplate =
            '<span uib-dropdown >' +
            '<a  uib-dropdown-toggle>' +
            "<i class='fa fa-filter'></i>" +
            '</a>' +
            '<ul class="dropdown-menu " ng-click="$event.stopPropagation()" uib-dropdown-menu >' +

            tabs

        '</ul>' +
            '</span>'

        buttons.dropdownTemplate = dropdownTemplate
        return buttons

    }

    function datatableNonAggregateColumnFilters(nonAggregateColumns) {
        var nonAggregateColumnTabs = []
        for (var column in nonAggregateColumns) {
            for (var tabs in nonAggregateColumns[column]) {
                switch (tabs) {
                    case "tab1":
                        nonAggregateColumnTabs.push({
                            [tabs]: '<uib-tab index="0" select="stopTabClose($event)">' +
                                '<uib-tab-heading><img src="../../../assets/img/diagram.png" /></uib-tab-heading>' +
                                '<p class="tab-pane--heading">Group By</p>' +
                                '<div class="tab-pane--content">' +
                                '<ul class="list-style-none padding-0">' +
                                '<li ng-repeat="node in groupNames" ng-class="{groupActive : node.active}" ng-click="groupSelect(node.oldValue)">' +
                                '<a>{{node.name}}</a>' +
                                '</li>' +
                                '</ul>' +
                                '</div>' +
                                '</uib-tab>'
                        })
                        break;
                    case "tab2":
                        nonAggregateColumnTabs.push({
                            [tabs]:
                                '<uib-tab index="1" select="stopTabClose($event)">' +
                                '<uib-tab-heading><i class="fa fa-filter"></i></uib-tab-heading>' +
                                '<p class="tab-pane--heading">Custom Filter</p>' +
                                '<div class="tab-pane--content">' +
                                '<input type="text" ng-model="columnFilters.nonAggregateColumns[column][tabs].searchText" ng-trim="false" ng-model-options="{debounce : 300}" ng-change="searchTable(columnFilters.nonAggregateColumns[column ][ tabs ].searchText)">' +
                                '<p ng-if="searchTableMessage.isError">{{searchTableMessage.errorMessage}}</p>' +
                                '</div>' +
                                '</uib-tab>'
                        })

                        break;
                }
            }
        }


        // var nonAggregateColumnButton2 =
        //     '<uib-tab index="1" select="stopTabClose($event)">' +
        //     '<uib-tab-heading><i class="fa fa-filter"></i></uib-tab-heading>' +
        //     '<p class="tab-pane--heading">Custom Filter</p>' +
        //     '<div class="tab-pane--content">' +
        //     '<input type="text" ng-model="filterObject.nonAggregateTab.tab2.searchText" ng-trim="false" ng-model-options="{debounce : 300}" ng-change="searchTable(filterObject.nonAggregateTab.tab2.searchText)">' +
        //     '<p ng-if="searchTableMessage.isError">{{searchTableMessage.errorMessage}}</p>' +
        //     '</div>' +
        //     '</uib-tab>'
        console.log(nonAggregateColumnTabs)
        var nonAggregateUibTab = '<uib-tabset  class="uib-tabset">'
        var finalTab;
        console.log(nonAggregateColumnTabs)
        for (var i = 0; i < nonAggregateColumnTabs.length; i++) {
            finalTab = nonAggregateUibTab.concat(_.values(nonAggregateColumnTabs[i])[0])
        }
        finalTab.concat('</uib-tabset>');
        console.log(finalTab)

        return finalTab;
    }

    function datatableAggregateColumnFilters() {

        var aggregateColumnButton1 =
            '<uib-tab index="0" select="stopTabClose($event)">' +
            '<uib-tab-heading><i class="fa fa-filter"></i></uib-tab-heading>' +
            '<p class="tab-pane--heading">Filters</p>' +
            '<div class="tab-pane--content">' +
            '<div class="filter-section">' +
            // "{{dataValue}}"+
            "{{filterObject.aggregateTab.tab1.filterExtreme}}" +
            '<input type="radio" class="input-radio" name="filterValue" ng-model="filterObject.aggregateTab.tab1.filterExtreme"  ng-change="radioChange(filterObject.aggregateTab.tab1.filterExtreme)" ng-value="true" >' +

            '<span>Positive</span>' +
            '</div>' +
            '<div class="filter-section">' +
            '<input type="radio" class="input-radio" name="filterValue" ng-model="filterObject.aggregateTab.tab1.filterExtreme"  ng-change="radioChange(filterObject.aggregateTab.tab1.filterExtreme)" ng-value="false">' +
            '<span>Negative</span>' +
            '</div>' +
            '<div class="clear-filter">' +
            '<button type="button" class="btn btn-sm btn-default" ng-disabled="filterObject.aggregateTab.tab1.isClearFilterDisabled" ng-click="clearAggregateFilter()">Clear Filter</button>' +
            '</div>' +
            '</div>' +
            '</uib-tab>'

        var aggregateUibTab = '<uib-tabset class="uib-tabset">' + aggregateColumnButton1 + '</uib>'

        return aggregateUibTab;
    }

    function contextGroupBy(parentTableOptions, $compile, scope, columnFilters) {
        // console.log("Template test", $templateCache.get(scope.temp))
        parentTableOptions.buttons = [];
        var nonAggregateColumnsButtons = datatableNonAggregateColumnFilters(columnFilters.nonAggregateColumns)
        var nonAggregateColumnsDropdown = dataTableColumnFilterDropdownTemplate(nonAggregateColumnsButtons)


        var aggregateColumnsButtons = datatableAggregateColumnFilters()
        var aggregateColumnsDropdown = dataTableColumnFilterDropdownTemplate(aggregateColumnsButtons)


        var nonRevenueColumnsCompiled = $compile(nonAggregateColumnsDropdown.dropdownTemplate)(scope);
        var revenueColumnsCompiled = $compile(aggregateColumnsDropdown.dropdownTemplate)(scope);
        parentTableOptions.buttons.push(
            {
                text: nonRevenueColumnsCompiled,
                action: function (e) {
                    e.stopPropagation()
                    // scope.showContext();
                }
            },
            {
                text: revenueColumnsCompiled,
                action: function (e) {
                    e.stopPropagation()
                }
            }

        )
    }
})