define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.transCatgSyncCtrl', [])
					.controller('transCatgSyncCtrl', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', '$timeout', transCatgSyncCtrl])
					
			function transCatgSyncCtrl($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG,$timeout) {
				
				var vm = this;	
				vm.filteredTaxYearData = [];	
				//Get the current filing Tax Year
				vm.curentyear = GlobalService.globalParams.tax_year;
				//Get the scenario
				vm.scenario = GlobalService.globalParams.scenario;
				//JCD Key for the given custom screen
				vm.jcd_key = GlobalService.globalParams.jcd_key;
				//JCD TA Key for the given custom screen
/*				vm.jcd_ta_key;*/
				vm.selectedFromAcctKey
				//Show Scenario dropdown
				vm.showscenario = false;
				vm.showTransCatg = false;
				
				vm.localAccts = [];
				vm.filingGroupDropDown = [];
				  vm.filingAllDetails = [];
				     vm.filingEnityDetails = [];
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"scenario": GlobalService.globalParams.scenario 
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				
					
				
				/*$scope.$watch(function() { return JCDFactory.JCDList },   function() {
					if(JCDFactory.JCDList!=undefined && JCDFactory.JCDList!=null && JCDFactory.JCDList.length > 0){											
						var b = _.find(JCDFactory.JCDList, function(o) { return o.ACTIVITY_NAME.includes(workspaceFactory.activity.label); });							
						//Get the JCD Core details
						vm.jcd_key = b.JCD_KEY;
						vm.jcd_ta_key = b.JCD_TA_KEY;
						vm.task_name = b.TASK_NAME;							
					}
				});*/
				
				//Redirect the URL to a sourcing screen after closing the Modal screen 
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
								
				//Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {		
					var params = {"action_code" : 'e83e1x',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});							
						for(var indx in vm.taxYearData){
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearData[indx].CODE_DISP_DESC <= (vm.curentyear) && vm.taxYearData[indx].CODE_DISP_DESC >= (vm.curentyear-5))){
								vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
							}
						}
					});					
				};
				vm.getTaxYearDropDown();
				
				/*vm.getFilingGroupDropDown = function() {
					let params = {
			                "action_code": '0udsuc',
			                "scenario": vm.selectedScenario,
			                "tax_year":  vm.selectedTaxYear
			            };
			            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
			                .then(function(data) {
			                	console.log(data);
			                	vm.filingGroupDropDown = data.jsonObject;
			                	vm.showFilingGroup = true;
			                });
				}
				*/
				
				 vm.transCatgInfo = function() {
			        	var params = {
								//"action_code": 'hmkia6',
			        			"action_code":"lrjifk",
			        			"tax_year":  vm.selectedTaxYear
						};
		            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
		                .then(function(data) {
		                	
		                vm.transCatgDetails = Object.assign([],data.jsonObject);
		               					                      
					     vm.showTransCatg = true;
		                    
		                });
			        }
			        
			       // vm.filingEnityDetailInfo();
				
				//Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
						AlertService.add("error", "Please select the Tax Year to get the Scenario.",4000);
						return;
					}
			
					var params = {"action_code" : '7q0wiy',"tax_year" : vm.selectedTaxYear};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;		
						vm.showscenario = true;
					});					
				};
				
				  $scope.$watch(function(){ return workspaceFactory.screens}, function(val){
		            vm.workspaces = workspaceFactory.getScreen();
		            $timeout(function() {
		                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
		                    vm.scrlTabsApi.init();
		                }
		            });
		        });
			
			  vm.tabs = [];
		        vm.updateFn = function(arg){
		            var obj = [];
		            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
		            vm.setTab(obj);
		        }

				
				
				//Call the Sync process of 1118 Sync
				vm.transCatgSync = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					/*if(vm.selectedFilingGroup == undefined || vm.selectedFilingGroup == null || vm.selectedFilingGroup == ''){
						AlertService.add("error", "Please select a valid Filing Group.", 5000);
						return;
					}*/
					
					if(vm.selectedTransCatg == undefined || vm.selectedTransCatg == null || vm.selectedTransCatg == ''){
						AlertService.add("error", "Please select a valid Trans Category.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "jcd_key":vm.jcd_key };				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N'){
							var params = {"sso_id":USER_SETTINGS.user.sso_id,
									"action_code" : 'ghtqbf',
									"tax_year": vm.selectedTaxYear, 
									"scenario" : vm.selectedScenario, 
									"jcd_key":vm.jcd_key,
									//"filing_key":vm.selectedFilingGroup,
				        			"trans_catg_key": vm.selectedTransCatg
								};		    			
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {	
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});	
							AlertService.add("success", "Trans Catg Sync initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Process. Tax Return has already been filed for selected Tax Year.", 4000);
						}
						
					});
				}
				
			}
			return controllers;
		});