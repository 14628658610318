
define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.goldSyncOldServices', [])

        .factory("GoldSyncOldFactory", ['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService', 'AlertService', 'USER_SETTINGS', '$rootScope', function ($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS, $rootScope) {
            var goldSyncOldFactory = {};
            var totalModulesAssigned = 0;

            goldSyncOldFactory.previewExistingBizzHierarchy = function (data) {
                console.log("goldSyncOldFactory.previewExistingBizzHierarchy data::", data);
                var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.admin_api_url + '/previewBizzHierarchy',
                    data: data
                }).then(function (response) {
                    if (response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        if (response.data.errorMessage === "no access") {
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        } else {
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            goldSyncOldFactory.previewStagedBizzHierarchy = function (data) {
                var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.admin_api_url + '/previewStagedBizzHierarchy',
                    data: data
                }).then(function (response) {
                    if (response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        if (response.data.errorMessage === "no access") {
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        } else {
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            goldSyncOldFactory.finalizeStagedBizHierarchy = function (data) {
                console.log("Finalizing Staged Biz Hierarchy....Pl wait......Data::", data);
                data.action_code = 'ewwww9';
                var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.base_url + '/loadJsonObject',
                    data: data
                }).then(function (response) {
                    if (response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        if (response.data.errorMessage === "no access") {
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        } else {
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            }

            /** TESTING TESTING TESTING**/
            goldSyncOldFactory.syncGoldDataForBizzHierarchy = function (data) {
                console.log("syncGoldDataForBizzHierarchy....Pl wait......Data::", data);
                var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.base_url + '/syncGoldBizzHierarchyData',
                    data: data
                }).then(function (response) {
                    if (response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        if (response.data.errorMessage === "no access") {
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        } else {
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            }



            return goldSyncOldFactory;
        }])


    return services;


});