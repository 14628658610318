define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.firGridSaveService',[])
       .factory("FirGridSaveFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   		,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
			   var firGridSaveFactory ={};
			   var returnObj = {};
	           firGridSaveFactory.url ={};
	           firGridSaveFactory.url.htieNewElectionSave = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=q78v9b";//Action id 30024
	           
		       firGridSaveFactory.htieNewElectionSave = function(_callName , _data) {
					console.log("data - ", _data);
					console.log("callname - ",_callName);	

					$rootScope.$broadcast('gridRefresh', false); 
					//Do Nothing if old and new values are same
					if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]) {
						return;
					}
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
					var ftcId = null;
					var passiveId = null;
					var subfId = null;
					var fphciId = null;
					if (_data.col_id !== null && _data.col_id !== undefined) {
						if (_data.rowData.hasOwnProperty("PIVOT_COLUMN_UNPIVOTED_DATA")
							&& _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA.hasOwnProperty(_data.col_id)) {
							ftcId = _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].hasOwnProperty("FTC_ID") ? _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].FTC_ID : null;
							passiveId = _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].hasOwnProperty("PASSIVE_ID") ? _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].PASSIVE_ID : null;
							subfId = _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].hasOwnProperty("SUBF_ID") ? _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].SUBF_ID : null;
							fphciId = _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].hasOwnProperty("FPHCI_ID") ? _data.rowData.PIVOT_COLUMN_UNPIVOTED_DATA[_data.col_id].FPHCI_ID : null;
						}
					}
					var service = $injector.get('JsonObjectFactory');
					var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=q78v9b";
					var json_obj = [{
						FIR_HO_LEID : filterParams.fir_ho_leid,                     
						HTIE_PASSIVE: 'N',
						NEW_ELECTION : _data.rowData[_data.col_id],                       
						JOB_ID : _data.rowData.JOB_ID,                       
						FTC_ID : ftcId,                       
						PASSIVE_ID : passiveId,                       
						SUBF_ID : subfId,                    
						FPHCI_ID : fphciId,
					}];
					console.log(json_obj);
					var params = _.merge({}, GlobalService.globalParams, filterParams);
					var params = _.extend({jsonObj: JSON.stringify(json_obj)}, params);	
					/*var promise = $http({
						method: "post",
						url: firGridSaveFactory.url.htieNewElectionSave,
						data: params
					}).then(function (response) {
						if(response.data.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",3000);
						} else {
							AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
						}
						return response.data;
					});
		
					return promise;*/
					
					service.saveJSON(url , params).then(function(data){
						if(data.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",4000);
						}else if (data.errorMessage && data.errorMessage != ""){
							AlertService.add("error", data.errorMessage, 4000);
						} else{
							AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
						}
					  });
				};
	           
				firGridSaveFactory.htiePassiveNewElectionSave = function(_callName , _data) {
					console.log("data - ", _data);
					console.log("callname - ",_callName);	

					$rootScope.$broadcast('gridRefresh', false); 
					//Do Nothing if old and new values are same
					if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]) {
						return;
					}
					
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
					var service = $injector.get('JsonObjectFactory');
					var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=q78v9b";

					var json_obj = [{
						FIR_HO_LEID : filterParams.fir_ho_leid,                     
						HTIE_PASSIVE: 'Y',
						NEW_ELECTION : _data.rowData.PASSIVE_FPCHI,                       
						JOB_ID : _data.rowData.JOB_ID,                       
						FTC_ID : null,                       
						PASSIVE_ID : null,                       
						SUBF_ID : null,                    
						FPHCI_ID : null
					}];
					console.log(json_obj);
					var params = _.merge({}, GlobalService.globalParams, filterParams);
					var params = _.extend({jsonObj: JSON.stringify(json_obj)}, params);	
					
					service.saveJSON(url , params).then(function(data){
						if(data.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",4000);
						}else if (data.errorMessage && data.errorMessage != ""){
							AlertService.add("error", data.errorMessage, 4000);
						} else{
							AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
						}
					  });
					/*var promise = $http({
						method: "post",
						url: firGridSaveFactory.url.htieNewElectionSave,
						data: params
					}).then(function (response) {
						if(response.data.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",3000);
						} else {
							AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
						}
						return response.data;
					});
		
					return promise;*/
				 };
	           
				 firGridSaveFactory.tbImportFromFDMAlert = function(_callName , _data) {
					 console.log("data - ", _data);
					 console.log("callname - ",_callName);	
 
					 //Do Nothing if old and new values are same
					 if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]) {
						 return;
					 }
					 
					 //Show Alert if user selects import for already loaded tb
					 if (_data.rowData[_data.col_id] === 1 && _data.rowData['TB_STATUS'] === 'Loaded') {
						AlertService.add("warning", "TB is already loaded for LEID - " + _data.rowData["LEID"] + " Tax Code - " + _data.rowData["CDR_NO"]
										 + " ME Code - " + _data.rowData["ME_CODE"] + " Rpt Pd - " + _data.rowData["REPORTING_PERIOD"]
										 + ". Please uncheck Import checkbox if you don't wish to override the existing TB.");
					 }
				  };
           
	           return firGridSaveFactory;
       }]);

   	  return services;
});