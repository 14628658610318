define([
    'angular',
    './consolGroupEditCtrl',
    './consolGroupEditService', 
], function () {
    'use strict';
    return angular.module('app.consolGroupEdit', ['app.consolgroupEditController', 'app.consolGroupEditService'] );
  
});


