define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.FormScheduleGCtrl', [])
        .controller('F1120LSCHDGController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS',F1120LSCHDGController]);
    function F1120LSCHDGController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        var saveParamDtls = [];
        vm.modalTitle = "Schedule G  Policy Acquisition Expenses (section 848) (see instructions)";
        vm.deductiblePolicy = 0;
        vm.formListOriginal = [];
        vm.unallocatedAmount = 0;
        vm.batchLoader = false;
        vm.showBatchData = false;
        vm.showRequestLoader = true;
        vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';

        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME,
            "LE TYPE": rowData.LE_TYPE,
            "ME": rowData.ME
        };

        $scope.unallocatedAmount = rowData.dig || {};

        var tempDataObj = {
            num :'',
            description : '',
            value :''
        };
        $scope.scheduleGFormView = {rows:[]};
        var line = [];
        var description = [];

        vm.showFormLoader = true;
        function fetchSchDrecords() {
            var params = { "action_id": 603, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY};
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
                for(var i=2;i<data.irsForm.formItemList.length;i++){
                    tempDataObj = {};
                    saveParamDtls = [];
                    line = '';
                    description = '';
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    line = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
                    description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;;
                    var total = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
                    var toIterateArray = data.irsForm.formItemList[i].dataRowList[0].dataCellList.slice(2);
                    iterateArray(toIterateArray, ['scheduleGFormView'], saveParamDtls);
                }
                vm.formListOriginal = angular.copy($scope.scheduleGFormView);
                initFormView($scope.scheduleGFormView);
                vm.showFormLoader = false;              
            });
        };

        var iterateArray = function(array, tempDisplayArray, saveParamDtls){
            for(var k = 0; k < array.length; k++){
                var column = array[k].key.split('_').pop().charAt(0);
                var attribNames = returnAttributes(column);
                if(k==0 || k==1){
                    if(tempDataObj.num !== "9" && tempDataObj.num !== "13" && tempDataObj.num !== "19"){
                        tempDataCreation(attribNames.val, attribNames.lineAttrKey, attribNames.attrName, attribNames.attrOrder, array, k, saveParamDtls);
                        if(k == array.length - 1){
                            addParams(tempDisplayArray[0], saveParamDtls);
                        }
                    }
                }else{
                    tempDataCreation(attribNames.val, attribNames.lineAttrKey, attribNames.attrName, attribNames.attrOrder, array, k, saveParamDtls);
                    if(k == array.length - 1){
                        addParams(tempDisplayArray[0], saveParamDtls);
                    }
                }
            }
        };

        var addParams = function(name, saveParamDtls){
            tempDataObj.num = line;
            tempDataObj.description = description;
            tempDataObj.line_order = saveParamDtls[8];
            tempDataObj.saveParams = saveParamDtls;
            $scope[name].rows.push(tempDataObj);
            tempDataObj = {};
        };

        var returnAttributes = function(column){
            var type = {};
            var val,lineAttrKey,attrName,attrOrder;
            switch (column) {
                case 'A':
                    type.val = 'value1';
                    type.lineAttrKey = 'lineAttrKeyA';
                    type.attrName = 'attrib_name_a';
                    type.attrOrder = 'attrib_order_a';
                    break ;
                case 'B':
                    type.val = 'value2';
                    type.lineAttrKey = 'lineAttrKeyB';
                    type.attrName = 'attrib_name_b';
                    type.attrOrder = 'attrib_order_b';
                    break;
                case 'C':
                    type.val = 'value3';
                    type.lineAttrKey = 'lineAttrKeyC';
                    type.attrName = 'attrib_name_c';
                    type.attrOrder = 'attrib_order_c';
                    break ;
                case 'D':
                    type.val = 'value4';
                    type.lineAttrKey = 'lineAttrKeyD';
                    type.attrName = 'attrib_name_d';
                    type.attrOrder = 'attrib_order_d';
                    break;
                case 'E':
                    type.val = 'value5';
                    type.lineAttrKey = 'lineAttrKeyE';
                    type.attrName = 'attrib_name_e';
                    type.attrOrder = 'attrib_order_e';
                    break ;
                case 'F':
                    type.val = 'value6';
                    type.lineAttrKey = 'lineAttrKeyF';
                    type.attrName = 'attrib_name_f';
                    type.attrOrder = 'attrib_order_f';
                    break;
                case 'G':
                    type.val = 'value7';
                    type.lineAttrKey = 'lineAttrKeyG';
                    type.attrName = 'attrib_name_g';
                    type.attrOrder = 'attrib_order_g';
                    break ;
                case 'H':
                    type.val = 'value8';
                    type.lineAttrKey = 'lineAttrKeyH';
                    type.attrName = 'attrib_name_h';
                    type.attrOrder = 'attrib_order_h';
                    break;
                default: 
                    type.val = 'value9';
                    type.lineAttrKey = 'lineAttrKeyI';
                    type.attrName = 'attrib_name_i';
                    type.attrOrder = 'attrib_order_i';
            }
            return type;
        }

        function tempDataCreation (val, lineAttrKey, attrName, attrOrder, array, k, saveParamDtls, toPush){
            tempDataObj[lineAttrKey] = array[k].key.split('~')[1];
            tempDataObj[attrName] = array[k].key.split('~')[0];
            tempDataObj[attrOrder] = array[k].key.split('~')[2];
            if(array[k].value == null){
                tempDataObj[val] = 0;
            } else {
                tempDataObj[val] = parseFloat(array[k].value);
            }
            if(array[k].property.is_editable_cell == 'Y'){
                tempDataObj.is_editable = true;
            }else {
                tempDataObj.is_editable = false;
            }
        }

        function fetchSchGimports() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "status": 'Y'
            };
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=wwledr', params).then(function (data) {
                vm.BatchList = data.jsonObject;
                angular.forEach(vm.BatchList, function (value, key) { 
                    vm.BatchList[key].TOGGLE = false; 
                });
                vm.showRequestLoader = false;
            });
        }
        
        vm.fetchSchGwhitepaper = function(num) {
            $scope.activeTab = 3;
            vm.showFormLoader = true;
            
            if (num==19 ) {
                var params = { "action_id": 30014 ,"line_no": num, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY,  "combination_key": rowData.COMBINATION_KEY, "is_whitepaper": 1};
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    vm.showFormLoader = false;
                    var form_account = data.jsonObject;
                    vm.formWhitepaper = [];
                    var f_Amt = 0;
                    var formTempData = {};
                    angular.forEach(form_account, function(value, key){
                         formTempData = form_account[key];
                         vm.formWhitepaper.push(formTempData);
                         f_Amt += form_account[key].AMOUNT;
                    });
                    vm.formsAmt = f_Amt;
                    vm.showDiagLoader = false;
                })
            }
            else {
                var params = { "action_id": 30014 ,"line_no": num,"tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY, "is_whitepaper": 1};
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    vm.showFormLoader = false;
                    var form_account = data.jsonObject;
                    vm.formWhitepaper = [];
                    vm.accountData = [];
                    var f_Amt = 0, a_Amt = 0;
                    var currFormKey = 0;
                    var currAcctKey = 0;
                    var formDrillDown = [];
                    var formTempData = {};
                    var acctDrillDown = [];
                    var acctTempData = {};
                    angular.forEach(form_account, function(value, key){
                        if(form_account[key].TYPE == "form" || form_account[key].TYPE == "form-acct"){
                            if(form_account[key].TYPE == "form"){
                                if(key !== 0){
                                    formTempData.childAcct = formDrillDown;
                                    formTempData.toggle = false;
                                    vm.formWhitepaper.push(formTempData);
                                    formDrillDown = [];
                                    formTempData = {};
                                }
                                currFormKey = form_account[key].ACCT;
                                formTempData = form_account[key];
                            }else {
                                if(currFormKey == form_account[key].ACCT){
                                    formDrillDown.push(form_account[key]);
                                }
                            }
                            f_Amt += form_account[key].AMOUNT;
                        }
                    });
                    vm.drillFormData = vm.formWhitepaper.splice(1);
                    if (vm.formWhitepaper.length > 0) {
                        vm.formWhitepaper[0].totalLines = vm.drillFormData;
                        vm.formWhitepaper[0].toggleTotal = false;
                    }
                    vm.formsAmt = f_Amt;
                    vm.acctAmt = a_Amt;
                    vm.showDiagLoader = false;
                })  
            }
        }

        fetchSchDrecords();
        fetchSchGimports();

        function loadTransactions(index, trans_key){
            vm.batchLoader = true;
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "transaction_key": trans_key
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=l6gzle', params).then(function (data) {
                var tempData = data.jsonObject;
                var currLineNo;
                var tempBatchList = {};
                var tempBatchData = [];
                angular.forEach(tempData, function (value, key) {
                    if(key == 0){
                        currLineNo = tempData[key].LINE_NO;
                        tempBatchList = tempData[key];
                    }else{
                        if(tempData[key].LINE_NO == currLineNo){
                            if(tempData[key].ATTRIB_ORDER == 4){
                                tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                            }else{
                                tempBatchList.ATTRIBUTE_VALUE_C = tempData[key].ATTRIBUTE_VALUE;
                                tempBatchData.push(tempBatchList);
                            }
                        }else{
                            if(tempData[key].ATTRIB_ORDER == 4){
                                currLineNo = tempData[key].LINE_NO;
                                tempBatchList = {};
                                tempBatchList = tempData[key];
                                tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                                delete tempBatchList.ATTRIBUTE_VALUE;
                                tempBatchData.push(tempBatchList);
                            }else if(tempData[key].ATTRIB_ORDER == 5){
                                currLineNo = tempData[key].LINE_NO;
                                tempBatchList = {};
                                tempBatchList = tempData[key];
                                tempBatchList.ATTRIBUTE_VALUE_C = tempData[key].ATTRIBUTE_VALUE;
                                delete tempBatchList.ATTRIBUTE_VALUE;
                                tempBatchData.push(tempBatchList);
                            }else{
                                currLineNo = tempData[key].LINE_NO;
                                tempBatchList = {};
                                tempBatchList = tempData[key];
                            }                            
                        }
                    }
                });
                vm.batchLoader = false;
                vm.showBatchData = true;
                vm.BatchListData = tempBatchData;
            });
        };

        var prev_index = null;
        vm.showUploads = function(index, trans_key){
            if(prev_index !== null){
                if(prev_index !== index){
                    vm.BatchList[prev_index].TOGGLE = false;
                    prev_index = index;
                    vm.showBatchData = false;
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    loadTransactions(index, trans_key);
                }else{
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    vm.showBatchData = true;
                }
            }else{
                prev_index = index;
                vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                loadTransactions(index, trans_key);
            }
        };

        vm.adjustGainLoss = function() {
            vm.unallocatedAmount = $scope.scheduleGFormEntry.singlerows[1] - vm.deductiblePolicy;
        };
    
        vm.combinedComputeValues = function(view1, view2, onLoad, row){
            switch (rowData.TAX_YEAR) {
                case 2017:
                    vm.computeValues2017(row);
                    break ;
                case 2018:
                    if(onLoad){
                        vm.computeValues2018(view1, row);
                        vm.computeValues2018(view2, row);
                        vm.addDomesticForeign();
                    }else{
                        vm.computeValues2018(view1, row);
                        vm.addDomesticForeign();
                    }
                    break;
                default:
                    if(onLoad){
                        vm.computeValues2019(view1, row);
                        vm.computeValues2019(view2, row);
                        vm.addDomesticForeign();
                    }else{
                        vm.computeValues2019(view1, row);
                        vm.addDomesticForeign();
                    }
            }
        }

        vm.addDomesticForeign = function(){
            for(var i =0; i < $scope.scheduleGFormView.rows.length; i++){
                if(i == 8){
                    if($scope.scheduleGFormView.rows[i].value6 == 0){
                        $scope.scheduleGFormView.rows[i].value3 = $scope.scheduleGFormView.rows[i].value9;
                    }else{
                        $scope.scheduleGFormView.rows[i].value3 = $scope.scheduleGFormView.rows[i].value6;
                    }
                }else{
                    if(typeof($scope.scheduleGFormView.rows[i].value1) !== 'undefined'){
                        $scope.scheduleGFormView.rows[i].value1 = $scope.scheduleGFormView.rows[i].value4 + $scope.scheduleGFormView.rows[i].value7;
                    }
                    if(typeof($scope.scheduleGFormView.rows[i].value2) !== 'undefined'){
                        $scope.scheduleGFormView.rows[i].value2 = $scope.scheduleGFormView.rows[i].value5 + $scope.scheduleGFormView.rows[i].value8;
                    }
                    if(typeof($scope.scheduleGFormView.rows[i].value3) !== 'undefined'){
                        $scope.scheduleGFormView.rows[i].value3 = $scope.scheduleGFormView.rows[i].value6 + $scope.scheduleGFormView.rows[i].value9;
                    }
                }
            }

            // TODO : change it to generic computation
            $scope.unallocatedAmount[0][0].value = $scope.scheduleGFormView.rows[10].value3;
            $scope.unallocatedAmount[1][0].value = $scope.scheduleGFormView.rows[22].value3;
            $scope.unallocatedAmount[2][0].value = $scope.scheduleGFormView.rows[22].value3

            $scope.unallocatedAmount[0][2].value = $scope.unallocatedAmount[0][0].value - $scope.unallocatedAmount[0][1].value;
            $scope.unallocatedAmount[1][2].value = $scope.unallocatedAmount[1][0].value - $scope.unallocatedAmount[1][1].value;
            $scope.unallocatedAmount[2][2].value = $scope.unallocatedAmount[2][0].value - $scope.unallocatedAmount[2][1].value;
        };

        vm.computeValues2019 = function(view, row) {
            if(view == "domestic"){
                var a = "value4";
                var b = "value5";
                var c = "value6";
            }else{
                var a = "value7";
                var b = "value8";
                var c = "value9";
            }
            var view = "scheduleGFormView";
            var currentLine = angular.copy(row);
            //for NaN Error
            /**for(let i=0; i<23; i++){
                $scope.scheduleGFormView.rows[i].value1 = $scope.scheduleGFormView.rows[i].value1 || 0;
                $scope.scheduleGFormView.rows[i].value2 = $scope.scheduleGFormView.rows[i].value2 || 0;
                $scope.scheduleGFormView.rows[i].value3 = $scope.scheduleGFormView.rows[i].value3 || 0;
            }**/

            // computeColA();
            $scope[view].rows[2][a] = Math.round($scope[view].rows[0][a] - $scope[view].rows[1][a]);
            $scope[view].rows[4][a] = Math.round($scope[view].rows[2][a] * 0.0209);             
            // computeColB();
            $scope[view].rows[2][b] = Math.round($scope[view].rows[0][b] - $scope[view].rows[1][b]);
            $scope[view].rows[4][b] = Math.round($scope[view].rows[2][b] * 0.0245);
            // computeColC();
            $scope[view].rows[2][c] = Math.round($scope[view].rows[0][c] - $scope[view].rows[1][c]);
            $scope[view].rows[4][c] = Math.round($scope[view].rows[2][c] * 0.092);  

            //Line 6
            $scope[view].rows[5][c] = $scope[view].rows[4][a] + $scope[view].rows[4][b] + $scope[view].rows[4][c];

            if($scope[view].rows[5][c] <= 0){
                $scope[view].rows[7][c] = 0;
            }
            else{
                $scope[view].rows[6][c] = $scope[view].rows[6][c];
                $scope[view].rows[6].is_editable = true;
                var lineEightTotal = $scope[view].rows[5][c] + $scope[view].rows[6][c];

                if(lineEightTotal <= 0){
                    $scope[view].rows[7][c] = 0;
                } else {
                    $scope[view].rows[7][c] = Math.round(lineEightTotal);
                }            
            }

            $scope[view].rows[9][c] = Math.round(Math.min($scope[view].rows[7][c], $scope[view].rows[8][c]));
            if($scope[view].rows[9][c] <= 0 && $scope[view].rows[8][c] <=0
                &&$scope[view].rows[9][c]==$scope[view].rows[8][c]){
              $scope[view].rows[10][c] = $scope[view].rows[9][c];
            }else{
              $scope[view].rows[10][c] = $scope[view].rows[8][c]-$scope[view].rows[9][c];
            }

            $scope[view].rows[13][c] = Math.round(Math.min($scope[view].rows[11][c], $scope[view].rows[12][c]));

            //$scope.scheduleGFormView.rows[14].value2 = Math.min($scope.scheduleGFormView.rows[9].value3, 5000000);

            var phaseOutAmt = $scope[view].rows[9][c] - 10000000;
            if (phaseOutAmt > 0){
                $scope[view].rows[16][b] = phaseOutAmt;
            }
            else{
                $scope[view].rows[16][b] = 0;
            }

            var policyAcqExpenses = $scope[view].rows[14][b] - 
                                    $scope[view].rows[16][b];
            if (policyAcqExpenses > 0){
                $scope[view].rows[17][b] = policyAcqExpenses;
            }
            else{
                $scope[view].rows[17][b] = 0;
            }

            $scope[view].rows[18][c] = Math.round($scope[view].rows[17][b] * 0.10);

            $scope[view].rows[20][c] = Math.round($scope[view].rows[19][b] * 0.0334);

            $scope[view].rows[22][c] = Math.round($scope[view].rows[13][c] + 
                                                       $scope[view].rows[18][c] + 
                                                       $scope[view].rows[20][c] + 
                                                       $scope[view].rows[21][c]);
        }

        vm.computeValues2018 = function(view, row) {
            if(view == "domestic"){
                var a = "value4";
                var b = "value5";
                var c = "value6";
            }else{
                var a = "value7";
                var b = "value8";
                var c = "value9";
            }
            var view = "scheduleGFormView";
            var currentLine = angular.copy(row);
            //for NaN Error
            /**for(let i=0; i<23; i++){
                $scope.scheduleGFormView.rows[i].value1 = $scope.scheduleGFormView.rows[i].value1 || 0;
                $scope.scheduleGFormView.rows[i].value2 = $scope.scheduleGFormView.rows[i].value2 || 0;
                $scope.scheduleGFormView.rows[i].value3 = $scope.scheduleGFormView.rows[i].value3 || 0;
            }**/

            // computeColA();
            $scope[view].rows[2][a] = Math.round($scope[view].rows[0][a] - $scope[view].rows[1][a]);
            $scope[view].rows[4][a] = Math.round($scope[view].rows[2][a] * 0.0209);             
            // computeColB();
            $scope[view].rows[2][b] = Math.round($scope[view].rows[0][b] - $scope[view].rows[1][b]);
            $scope[view].rows[4][b] = Math.round($scope[view].rows[2][b] * 0.0245);
            // computeColC();
            $scope[view].rows[2][c] = Math.round($scope[view].rows[0][c] - $scope[view].rows[1][c]);
            $scope[view].rows[4][c] = Math.round($scope[view].rows[2][c] * 0.092);  

            //Line 6
            $scope[view].rows[5][c] = $scope[view].rows[4][a] + $scope[view].rows[4][b] + $scope[view].rows[4][c];

            if($scope[view].rows[5][c] <= 0){
                $scope[view].rows[7][c] = 0;            }
            else{
                $scope[view].rows[6][c] = $scope[view].rows[6][c];
                $scope[view].rows[6].is_editable = true;
                var lineEightTotal = $scope[view].rows[5][c] + $scope[view].rows[6][c];

                if(lineEightTotal <= 0){
                    $scope[view].rows[7][c] = 0;
                } else {
                    $scope[view].rows[7][c] = Math.round(lineEightTotal);
                }
            }

            $scope[view].rows[9][c] = Math.round(Math.min($scope[view].rows[7][c], $scope[view].rows[8][c]));

            if((Math.sign($scope[view].rows[8][c]) == -1 && Math.sign($scope[view].rows[8][c]) == -1) && ($scope[view].rows[8][c] == $scope[view].rows[9][c])){
                $scope[view].rows[10][c] = $scope[view].rows[9][c];
            }else{
                $scope[view].rows[10][c] = $scope[view].rows[8][c] - $scope[view].rows[9][c];
            }
            
            $scope[view].rows[13][c] = Math.round(Math.min($scope[view].rows[11][c], $scope[view].rows[12][c]));

            var phaseOutAmt = $scope[view].rows[9][c] - 10000000;
            if (phaseOutAmt > 0){
                $scope[view].rows[16][b] = phaseOutAmt;
            }
            else{
                $scope[view].rows[16][b] = 0;
            }

            var policyAcqExpenses = $scope[view].rows[14][b] - $scope[view].rows[16][b];
            if (policyAcqExpenses > 0){
                $scope[view].rows[17][b] = policyAcqExpenses;
            }
            else{
                $scope[view].rows[17][b] = 0;
            }

            $scope[view].rows[18][c] = Math.round($scope[view].rows[17][b] * 0.10);

            $scope[view].rows[20][c] = Math.round($scope[view].rows[19][b] * 0.0334);

            $scope[view].rows[22][c] = Math.round($scope[view].rows[13][c] + 
                                                       $scope[view].rows[18][c] + 
                                                       $scope[view].rows[20][c] + 
                                                       $scope[view].rows[21][c]);
        }

        vm.computeValues2017 = function(row) {
            // computeColA();
            $scope.scheduleGFormView.rows[2].value1 = $scope.scheduleGFormView.rows[0].value1 - $scope.scheduleGFormView.rows[1].value1;
            $scope.scheduleGFormView.rows[4].value1 = 0.0175 * $scope.scheduleGFormView.rows[2].value1;             
            // computeColB();
            $scope.scheduleGFormView.rows[2].value2 = $scope.scheduleGFormView.rows[0].value2 - $scope.scheduleGFormView.rows[1].value2;
            $scope.scheduleGFormView.rows[4].value2 = 0.0205 * $scope.scheduleGFormView.rows[2].value2;
            // computeColC();
            $scope.scheduleGFormView.rows[2].value3 = $scope.scheduleGFormView.rows[0].value3 - $scope.scheduleGFormView.rows[1].value3;
            $scope.scheduleGFormView.rows[4].value3 = 0.077 * $scope.scheduleGFormView.rows[2].value3;  

            //Line 6
            $scope.scheduleGFormView.rows[5].value3 = $scope.scheduleGFormView.rows[4].value1 + 
                                                      $scope.scheduleGFormView.rows[4].value2 + 
                                                      $scope.scheduleGFormView.rows[4].value3;

            if($scope.scheduleGFormView.rows[5].value3 <= 0){
                $scope.scheduleGFormView.rows[6].value3 = 0;
                $scope.scheduleGFormView.rows[6].is_editable = false;
                $scope.scheduleGFormView.rows[7].value3 = 0;
                $scope.scheduleGFormView.rows[11].value3 = Math.abs($scope.scheduleGFormView.rows[5].value3);
            } else{
                // $scope.scheduleGFormView.rows[6].value3 = $scope.scheduleGFormView.rows[5].value3;
                var lineEightTotal = $scope.scheduleGFormView.rows[5].value3 + $scope.scheduleGFormView.rows[6].value3;
                if(lineEightTotal <= 0){
                    $scope.scheduleGFormView.rows[7].value3 = 0;
                }else {
                    $scope.scheduleGFormView.rows[7].value3 = lineEightTotal;
                }            
                $scope.scheduleGFormView.rows[11].value3 = 0;
            }

            $scope.scheduleGFormView.rows[9].value3 = Math.min($scope.scheduleGFormView.rows[7].value3, $scope.scheduleGFormView.rows[8].value3);

            $scope.scheduleGFormView.rows[10].value3 = $scope.scheduleGFormView.rows[8].value3 - $scope.scheduleGFormView.rows[9].value3;

            $scope.scheduleGFormView.rows[13].value3 = Math.min($scope.scheduleGFormView.rows[11].value3, $scope.scheduleGFormView.rows[12].value3);

            $scope.scheduleGFormView.rows[14].value2 = Math.min($scope.scheduleGFormView.rows[9].value3, 5000000);

            var phaseOutAmt = $scope.scheduleGFormView.rows[9].value3 - 10000000;
            if (phaseOutAmt > 0){
                $scope.scheduleGFormView.rows[16].value2 = phaseOutAmt;
            }
            else{
                $scope.scheduleGFormView.rows[16].value2 = 0;
            }

            var policyAcqExpenses = $scope.scheduleGFormView.rows[14].value2 - $scope.scheduleGFormView.rows[16].value2;
            if (policyAcqExpenses > 0){
                $scope.scheduleGFormView.rows[17].value2 = policyAcqExpenses;
            }
            else{
                $scope.scheduleGFormView.rows[17].value2 = 0;
            }

            $scope.scheduleGFormView.rows[18].value3 = $scope.scheduleGFormView.rows[17].value2 * 0.10;

            $scope.scheduleGFormView.rows[19].value2 = $scope.scheduleGFormView.rows[9].value3 - $scope.scheduleGFormView.rows[17].value2;

            $scope.scheduleGFormView.rows[20].value3 = $scope.scheduleGFormView.rows[19].value2 * 0.05;

            $scope.scheduleGFormView.rows[22].value3 = $scope.scheduleGFormView.rows[13].value3 + $scope.scheduleGFormView.rows[18].value3 + $scope.scheduleGFormView.rows[20].value3 + $scope.scheduleGFormView.rows[21].value3;
        };

        vm.saveSchdGForm = function (url, data){
            var jsonSettings = { 
                "tax_year": rowData.TAX_YEAR, 
                "scenario": rowData.SCENARIO, 
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);

            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
                    $uibModalInstance.dismiss('cancel');
                    var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                }else{
                    AlertService.add("", response.data.errorMessage);
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.saveDiagnostic = function(url, diagnosticData){
            var params =  _.extend({diag_exception :diagnosticData});
            params.tax_year =  rowData.TAX_YEAR;
            params.screen_key =  workspaceFactory.activeScreen.screen_key;
            params.scenario = rowData.SCENARIO;
            params.jcd_key = rowData.JCD_KEY;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        vm.save = function(){
            var tempArray = [];

            for (var i = 0; i < $scope.scheduleGFormView.rows.length; i++) {
                if($scope.scheduleGFormView.rows[i].attrib_order_a !== undefined){
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyA', 'attrib_name_a', 'attrib_order_a', 'value1', i));
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyD', 'attrib_name_d', 'attrib_order_d', 'value4', i));
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyG', 'attrib_name_g', 'attrib_order_g', 'value7', i));
                }
                if ($scope.scheduleGFormView.rows[i].attrib_order_b !== undefined){
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyB', 'attrib_name_b', 'attrib_order_b', 'value2', i));
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyE', 'attrib_name_e', 'attrib_order_e', 'value5', i));
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyH', 'attrib_name_h', 'attrib_order_h', 'value8', i));
                }
                if ($scope.scheduleGFormView.rows[i].attrib_order_c !== undefined){
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyC', 'attrib_name_c', 'attrib_order_c', 'value3', i));
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyF', 'attrib_name_f', 'attrib_order_f', 'value6', i));
                    tempArray.push(saveDataCreation(rowData, $scope.scheduleGFormView, 'lineAttrKeyI', 'attrib_name_i', 'attrib_order_i', 'value9', i));
                }
                
                function saveDataCreation (rowData, scheduleGFormView, attrKey, attrName, attrOrder,val, i){
                    var formRowData = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: scheduleGFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleGFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleGFormView.rows[i][attrOrder],
                        line_description: $scope.scheduleGFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: scheduleGFormView.rows[i].saveParams[2],
                        line_no: scheduleGFormView.rows[i].num,
                        occurence: scheduleGFormView.rows[i].saveParams[7],
                        line_attrib_key: scheduleGFormView.rows[i][attrKey],
                        Attribute_Name: scheduleGFormView.rows[i][attrName],
                        Attribute_Value: scheduleGFormView.rows[i][val],
                        trans_dtls_key: scheduleGFormView.rows[i].saveParams[3],
                        trans_status: scheduleGFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    return formRowData;
                };
                
            }
            vm.formData.editrows.push(tempArray);
            vm.mainData = [];
            vm.mainData.push(vm.formData);
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nkepph";
            var saveDiagURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ftmltp";
            vm.saveSchdGForm(url, vm.mainData);
            var diagData = [];
            angular.forEach($scope.unallocatedAmount, function (array, key) {
                angular.forEach(array, function (obj, key) {
                    if(key === 3){
                        diagData.push(obj);
                    }
                });
            });
            vm.saveDiagnostic(saveDiagURL, JSON.stringify(diagData));
            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function () {
                $scope.$broadcast("showLoader", true);
            }, 100);
        };

        vm.reset = function (){
            $scope.scheduleGFormView = angular.copy(vm.formListOriginal);
            vm.combinedComputeValues('domestic', 'foreign', true);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.getText = function (num,obj) {
            if (num == 9) {
                var oldText = obj;
                return oldText.replace("(attach statement)", "<a class='whitepaperlink' ng-click='{{ctrl.fetchSchGwhitepaper(row.num)}}'>(attach statement)</a>");
                
            } else if (num == 19) {
                var oldText = obj;
                return oldText.replace("Attach statement", "<a class='whitepaperlink' ng-click='{{ctrl.fetchSchGwhitepaper(row.num)}}'>Attach statement</a>");
            }
        };

        function initFormView(filteredFormData) {
            $scope.scheduleGFormView = filteredFormData
            vm.combinedComputeValues('domestic', 'foreign', true);
        };
    }
});