define([
    'angular',
    './ghostEarnprofitNonghostScreenService', 
    './ghostEarnprofitNonghostScreenCtrl',
    './ghostEarnprofitNonghostDeleteCtrl',
    
], function () {
    'use strict';
    return angular.module('app.FIR_Ghost_Earnprofit_Nonghost_Screen', ['app.ghostEarnprofitNonghostScreenService','app.ghostEarnprofitNonghostScreenCtrl','app.ghostEarnprofitNonghostDeleteCtrl'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        // $stateProvider
        //     .state('sourcing-input-screen', {
        //         url: '/sourcing-input-screen',
        //         templateUrl: 'app/components/FIR_Sourcing_Input/sourcingInputScreen.html',
        //         backdrop: 'static',
        //         data:{},
        //         access:""
        //     })
    }])

});