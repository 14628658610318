define([
    'moment',
    'angular'
], function (moment) {
    'use strict';

    var services = angular.module('app.configDataService',[])
        .factory("configDataService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG','JsonObjectFactory', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,JsonObjectFactory) {

                var configDataService = {};

                var URLS = {
                    GET_ENTITY_DETAILS: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32023",
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj',
                    GET_ONLY_ENTITY: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32525",    
                    GET_GROUP_OBJ_BY_YEAR: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_SCENARIO_BY_YEAR: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32731",
                    GET_SCENARIO_TYPE_CODE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_SCENARIO_DESC: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_FILING_LEID: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32738",
                    GET_EC_CLIENT_ID:GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32761",
                }

                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};


                configDataService.loadAllOverrideEntities = function(_data, _details){
                    var  params = {
                                   action_code: 'lvcm1n', 
                                };

                    return JsonObjectFactory.getJSONObj(URLS.GET_ENTITY_DETAILS,params).then((response)=>{
                        if(response.callSuccess === "1" ){

                            let arr = response.jsonObject.map(elem => {
                                if(elem.START_DATE)
                                    elem.START_DATE = moment(elem.START_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.END_DATE)
                                    elem.END_DATE = moment(elem.END_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.ACQUISITION_DATE)
                                    elem.ACQUISITION_DATE = moment(elem.ACQUISITION_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_INCORPORATED)
                                    elem.DATE_INCORPORATED = moment(elem.DATE_INCORPORATED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_MERGED)
                                    elem.DATE_MERGED = moment(elem.DATE_MERGED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_DISSOLVED)
                                    elem.DATE_DISSOLVED = moment(elem.DATE_DISSOLVED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_SOLD)
                                    elem.DATE_SOLD = moment(elem.DATE_SOLD,'YYYY-MM-DD').format('MM/DD/YYYY');
                                return elem;
                            });

                            return arr;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Override Entities.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Override Entities. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                configDataService.loadAllEntities = function(_selectedVal1){
                    console.log(_selectedVal1);
                    var group_obj_key = _selectedVal1;
                    
                    var  params = {
                        action_code: 'm5xlvl', 
                        group_obj_key: group_obj_key
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_FILING_LEID,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retriveclassifications.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve classifications. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                configDataService.loadGroupObjKey = function(_selectedVal1, _selectedVal2){
                    console.log(_selectedVal1+'--------'+_selectedVal2);
                    var scenario_type_code = _selectedVal1;
                    var scenario_desc = _selectedVal2;
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : scenario_type_code,
                        scenario_desc : scenario_desc
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_GROUP_OBJ_BY_YEAR,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Group Obj Key.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Group Obj key. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                configDataService.loadGroupObjKey898 = function(_selectedVal1, _selectedVal2){
                    console.log(_selectedVal1+'--------'+_selectedVal2);
                    var scenario_type_code = _selectedVal1;
                    var scenario_desc = _selectedVal2;
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year - 1,
                        scenario_type_code : scenario_type_code,
                        scenario_desc : scenario_desc
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_GROUP_OBJ_BY_YEAR,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Group Obj Key 898.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Group Obj key 898. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                configDataService.loadScenarioByYear = function(_data, _details){
                    var  params = {
                        action_code: 'o2umqh', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_SCENARIO_BY_YEAR,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Scenario. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };


                configDataService.loadScenarioTypeCode = function(_data, _details){
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : null,
                        scenario_desc : null
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_SCENARIO_TYPE_CODE,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario Type Code.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve  Scenario Type Code. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                configDataService.loadScenarioDesc = function(_selectedValue){
                    console.log(_selectedValue);
                    var scenario_type_code = _selectedValue;
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : scenario_type_code,
                        scenario_desc : null
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_SCENARIO_DESC,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario Type Code.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve  Scenario Type Code. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                configDataService.loadClientId = function(){
                    
                    var  params = {
                        action_code: 'lppcun', 
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_EC_CLIENT_ID,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario Client Id.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve  Scenario Client Id. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                configDataService.save =function(){

                };

                configDataService.convertDateFormates=function(jsonObj){
                    jsonObj.TRADITIONAL_START_DATE= moment(jsonObj.TRADITIONAL_START_DATE).format('MM/DD/YYYY');
                    jsonObj.TAX_YEAR_START_DATE= moment(jsonObj.TAX_YEAR_START_DATE).format('MM/DD/YYYY');
                    jsonObj.TAX_YEAR_END_DATE= moment(jsonObj.TAX_YEAR_END_DATE).format('MM/DD/YYYY');
                    jsonObj.BATCH_START_DATE= moment(jsonObj.BATCH_START_DATE).format('MM/DD/YYYY');
                    jsonObj.BATCH_END_DATE= (jsonObj.BATCH_END_DATE == undefined) ? null: moment(jsonObj.BATCH_END_DATE).format('MM/DD/YYYY');
                    jsonObj.VIEW_START_DATE= moment(jsonObj.VIEW_START_DATE).format('MM/DD/YYYY');
                    jsonObj.VIEW_END_DATE= (jsonObj.VIEW_END_DATE == undefined) ? null: moment(jsonObj.VIEW_END_DATE).format('MM/DD/YYYY');
                    jsonObj.IRS_FORM_TY_BEGIN_DATE= (jsonObj.IRS_FORM_TY_BEGIN_DATE == undefined) ? null: moment(jsonObj.IRS_FORM_TY_BEGIN_DATE).format('MM/DD/YYYY');
                    jsonObj.IRS_FORM_TY_END_DATE= (jsonObj.IRS_FORM_TY_END_DATE == undefined) ? null: moment(jsonObj.IRS_FORM_TY_END_DATE).format('MM/DD/YYYY');
                    return jsonObj;
                }

                return configDataService;

        }]);

    return services;

});