define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.Form8865SchdK1Services',[])
        .factory("Form8865SchdK1ServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', Form8865SchdK1ServicesFactory])
    function Form8865SchdK1ServicesFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory) {


        Form8865SchdK1ServicesFactory.getF8865SchdKWPDDetailForK1Popup = function(params) {
            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function (response) {
                    return response;
                }
            );

            return promise;
        }

        Form8865SchdK1ServicesFactory.getF8865SchdK1WPDDetailForEntity = function(params) {
            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function (response) {
                    return response;
                }
            );

            return promise;
        }


        Form8865SchdK1ServicesFactory.save8865SchdK1WPDForEntity = function(_settings, _data) {
            let save_url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fl4p7n";
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonObj = JSON.stringify(_data);
            let jsonSettings =  JSON.stringify(_settings);

            console.log(jsonSettings);
            let params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);


            params.tax_year = filterParams.tax_year;
            params.scenario = filterParams.scenario;
            params.jcd_key = GlobalService.globalParams.jcd_key;
            params.process_name =  "Save F8865 Schd-K1 WPD for Entity";
            params.sso_id = _settings.sso_id;

            console.log('params = ', params);
            console.log('clob_data = ', params.clob_data);
            console.log('clob_settings = ', params.clob_settings);

            var promise = $http({
                method: "post",
                url: save_url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        }







        return Form8865SchdK1ServicesFactory;
    }

    return services;
});