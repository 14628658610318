define([
    'angular'
], function () {
    'use strict';
    angular.module('app.DstStatementsFiledStatusController', [])
        .controller('DstStatementsFiledStatusCtrl', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'DstStatementsFiledStatusFactory', 'AlertService', DstStatementsFiledStatusCtrl]);

    function DstStatementsFiledStatusCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, DstStatementsFiledStatusFactory, AlertService) {
        var vm = this;

        vm.taxYears = [];
        vm.businesses = [];
        vm.selectedTaxYear = '';
        vm.selectedBusiness = '';
        vm.gridLoading = undefined;
        vm.statementFiledStatusUpdating = false;
        vm.successMsg = '';
        vm.in_type = '';
        vm.in_status_type = '';

        vm.loadTaxyears = function () {
            DstStatementsFiledStatusFactory.getTaxYears().then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        vm.taxYears = response.data.jsonObject;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            );
        }

        vm.loadBusinesses = function () {
            DstStatementsFiledStatusFactory.getBusinesses(vm.selectedTaxYear.text).then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        vm.businesses = response.data.jsonObject;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            );
        }

        vm.changeTaxYear = function () {
            vm.businesses = [];
            vm.selectedBusiness = '';
            vm.loadBusinesses();
        };

        vm.changeBusinesses = function () {
            vm.loadStatementsFiledStatusGrid();
        };

        vm.checkUncheckAll = function (check) {
            const checkAll = check ? true: false;
            angular.forEach(vm.dtOptions.data, function(row){
                row.SELECTED = checkAll;
            });
            $("input:checkbox[id='stmts-filed-row-select']").prop('checked', checkAll);
        };

        vm.markAsFiled = function () {
            vm.in_type = 'FILED_STATUS';
            vm.in_status_type = '1';
            vm.successMsg = 'Marked As Filed';
            vm.updateStatementFiledStatus(vm.in_status_type, vm.in_type);
        };

        vm.markAsNotFiled = function () {
            vm.in_type = 'FILED_STATUS';
            vm.in_status_type = '0';
            vm.successMsg = 'Marked As Not Filed';
            vm.updateStatementFiledStatus(vm.in_status_type, vm.in_type);
        };

        vm.markQarAsY = function () {
            vm.in_type = 'FILED_QAR_STATUS';
            vm.in_status_type = '1';
            vm.successMsg = 'Marked QAR As Y';
            vm.updateStatementFiledStatus(vm.in_status_type, vm.in_type);
        };

        vm.markQarAsN = function () {
            vm.in_type = 'FILED_QAR_STATUS';
            vm.in_status_type = '0';
            vm.successMsg = 'Marked QAR As N';
            vm.updateStatementFiledStatus(vm.in_status_type, vm.in_type);
        };

        function getStatementidList() {
            return _.chain(vm.dtOptions.data).filter(item => item.SELECTED).map(item=>item.STATEMENTID ? item.STATEMENTID : "" ).join(",").value();
        }

        vm.rowSelect = function(statementId, isChecked) {
            var selectedRow = vm.dtOptions.data.filter(item => item.STATEMENTID === statementId);
            selectedRow[0].SELECTED = isChecked;
        }

        vm.dtOptions = {
            extraOptions: {
                componentSettings: {
                    tableOptions: {
                        searching: true,
                        columns: [
                            {
                                title: 'Select', data: null, className: 'text-center',
                                render: function (row) {
                                    return `<input type="checkbox" id="stmts-filed-row-select" class="select">`
                                },
                                actions: [
                                    {
                                        event: 'click', targetSelector: '.select', callback: function (rowData, event) {
                                            vm.rowSelect(rowData.STATEMENTID, (event.currentTarget.checked));
                                        }
                                    }
                                ]
                            },
                            {
                                title: 'Statement Id', data: 'STATEMENTID', className: 'text-center',
                            },
                            {
                                title: 'Primary LEID', data: 'PRIMARYLEID', className: 'text-center',
                            },
                            {
                                title: 'Secondary LEID', data: 'SECONDARYLEID', className: 'text-center',
                            },
                            {
                                title: 'Branch LEID', data: 'BRANCHLEID', className: 'text-center',
                            },
                            {
                                title: 'Statement Name', data: 'STATEMENTNAME',
                            },
                            {
                                title: 'Is QAR', data: 'ISQAR', className: 'text-center',
                                render: function (data) {
                                    return data === 0 ? 'N': 'Y';
                                }
                            },
                            {
                                title: 'Filed Status', data: 'FILEDSTATUS', className: 'text-center',
                            },
                            {
                                title: 'Created By', data: 'CREATEDBY', className: 'text-center',
                            },
                        ],
                        language: {
                            emptyTable: "No Data found.",
                        },
                        initCallBack: () => {
                            vm.gridLoading = false;
                        }
                    }
                }
            }
        };

        vm.updateStatementFiledStatus = function (in_status_type, in_type) {
            var statement_ids = getStatementidList();
            if (statement_ids === "") {
                return;
            }

            vm.statementFiledStatusUpdating = true;
            DstStatementsFiledStatusFactory.updateStatementFiledStatus(statement_ids, in_status_type, in_type).then(
                (response) => {
                    if (response && response.callSuccess === '1') {
                        AlertService.add('success', 'Statement ' +vm.successMsg+ ' Successfully!', 4000);
                        vm.statementFiledStatusUpdating = false;
                        vm.loadStatementsFiledStatusGrid();
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                    vm.statementFiledStatusUpdating = false;
                }
            );
        }

        vm.loadStatementsFiledStatusGrid = function () {
            if (!vm.selectedTaxYear || !vm.selectedBusiness) {
                return;
            }
            vm.gridLoading = true;
            DstStatementsFiledStatusFactory.getStatementsFiledStatusGridData(vm.selectedTaxYear.text, vm.selectedBusiness.id)
            .then(function (response) {
                vm.dtOptions.data = response.data.jsonObject;
                vm.gridLoading = false;
            });
        };

        vm.loadTaxyears();
    }
}
);
