define(
		[ 'angular',
			'./ExpAnACalcAdminService',
		],
		function() {
			'use strict';

			var controllers = angular.module('app.ExpAnACalcAdmin', [])
					.controller('expAnACalcAdminCtrl', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'initiateApprFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG','$timeout', expAnACalcAdminCtrl])
					
			function expAnACalcAdminCtrl($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, USER_SETTINGS, 
					initiateApprFactory,JsonObjectFactory, workspaceFactory, GENERAL_CONFIG,$timeout) {
				
				var vm = this;	
				vm.selectedTaxYear = GlobalService.globalParams.tax_year; 			
				// Get the selected Current Year
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {"tax_year":GlobalService.globalParams.tax_year};
				vm.curentyear = filterParams.tax_year;   // GlobalService.globalParams.tax_year;
				// Tax Year Dropdown
				vm.filteredTaxYearData = [];				
				// Set the Loggin USER settings details
				vm.logged_in_user = USER_SETTINGS.user.sso_id;
				// Flag to show or hide the Parent MEs dropdown
				vm.showParentMes = false;
				// Flag to show or hide the Entities dropdown
				vm.showEachEntities = false;
				// Flag to show or hide the home offices dropdown
				vm.showHomeOffices = false;
				// Flag to show Spinner
				vm.loading = false;
				
				// Redirect the URL to a sourcing screen after closing the Modal
				// screen
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
								
				// Get the Parent ME Drop-Down
				vm.getParentMeDropdown = function() {  // hmkia6
					// var params = {"action_code" : 'vxg30f', "sso_id" :
					// vm.logged_in_user, "tax_year" : vm.selectedTaxYear };
					var params = {"action_code" : 'hmkia6', "sso_id" : vm.logged_in_user, "tax_year" : vm.selectedTaxYear };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.parentMEDropDown = data.jsonObject;							
						});
				}
				vm.getParentMeDropdown();	
				
				// Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {		
					var params = {"action_code" : 'e83e1x',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});							
						for(var indx in vm.taxYearData){
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearData[indx].CODE_DISP_DESC <= (vm.curentyear) && vm.taxYearData[indx].CODE_DISP_DESC >= (vm.curentyear-5))){
								vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
							}
						}						
					});					
				};
				vm.getTaxYearDropDown();
								
				// Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
						AlertService.add("error", "Please select the Tax Year to get the Scenario.",4000);
						return;
					}
			
					var params = {"action_code" : '7q0wiy',"tax_year" : vm.selectedTaxYear};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;						
					});					
				};
				vm.getScenarioDropDown();		
				
				
				// Get the Filing Group Dropdown
				vm.getFilingGrpDropDown = function() {	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
						AlertService.add("error", "Please select the Tax Year to get the Filing Group.",4000);
						return;
					}
					
					// Reset the Filing_Grp and Appr_option whenever Tax_year is
					// changed
					vm.selectedFilingGrp = '';  	
					vm.selectedOption = '';
					vm.showParentMes = false;
					vm.showHomeOffices = false;
					vm.showEachEntities = false;
					vm.parentMEDropDown = [];
					vm.getParentMeDropdown();	
					
					var params = {"action_code" : 'gk7crv',"tax_year" : vm.selectedTaxYear};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.filingGrpDropDown =  data.jsonObject;						
					});					
				};
				vm.getFilingGrpDropDown();				
				
				
				// Get the Entities for the Modal dropdown
				vm.getHomeOfficeData = function(filingGrpKey) {					
					vm.showParentMes = false;
					vm.showHomeOffices = true;
					vm.showEachEntities = false;
					vm.loading = true;
					
					var params = {"action_code" : 'knji8n', "filing_key" : filingGrpKey, "tax_year" : vm.selectedTaxYear};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.homeOfficeData = data.jsonObject;								
							vm.loading = false;
														
					}); 
				}	
				
				// Get the Entities Code Combincations
				vm.getCodeCombinationData = function(filingGrpKey) {					
					vm.showParentMes = false;
					vm.showHomeOffices = false;
					vm.showEachEntities = true;
					vm.loading = true;
					
					var params = {"action_code" : '8ovje3', "filing_key" : filingGrpKey, "tax_year" : vm.selectedTaxYear};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.codeCombinationData = data.jsonObject;		
							vm.loading = false;											
					}); 
				}	
				
				// Get the Main MEs Drop-Down
				vm.getMainDropdown = function() {
					var option1 = {};
					option1["OPTION_KEY"] = '1';
					option1['OPTION_NAME'] = 'Parent ME(s)';
					option1["OPTION_DESC"] = 'PARENT_ME';
					
					var option2 = {};
					option2["OPTION_KEY"] = '2';
					option2['OPTION_NAME'] = 'Home Office';
					option2["OPTION_DESC"] = 'HOME_OFFICE';
					
					var option3 = {};
					option3["OPTION_KEY"] = '3';
					option3['OPTION_NAME'] = 'Entity/Component';
					option3["OPTION_DESC"] = 'ENTITY_COMPONENT';
										
					var option4 = {};
					option4["OPTION_KEY"] = '4';
					option4['OPTION_NAME'] = 'All';
					option4["OPTION_DESC"] = 'ALL';
					
					vm.defaultDropDown = [];
					vm.defaultDropDown.push(option1);	
					vm.defaultDropDown.push(option2);	
					vm.defaultDropDown.push(option3);	
					vm.defaultDropDown.push(option4);						
				}
				vm.getMainDropdown();	
				
				vm.onFilingGrpChange = function(){
					vm.selectedOption = '';
					vm.showParentMes = false;
					vm.showHomeOffices = false;
					vm.showEachEntities = false;
					
					vm.selectedParentMes = [];
					vm.selectedHomeOffices = [];
					vm.selectedEntities = [];					
					
				}
				
				// On Selection of Apportionment Dropdown
				vm.onSelectOption = function() {					
					vm.loading = true;
					if(vm.selectedOption && vm.selectedOption == '1'){
						if(vm.selectedFilingGrp == undefined || vm.selectedFilingGrp == null || vm.selectedFilingGrp.toString() == ''){
							AlertService.add("error", "Please select Filing Group to get the Parent ME(s).",4000);
							return;
						}
						
						vm.showParentMes = true;
						vm.showHomeOffices = false;
						vm.showEachEntities = false;
						vm.loading = false;
					}
					
					if(vm.selectedOption && vm.selectedOption == '2'){
						
						if(vm.selectedFilingGrp == undefined || vm.selectedFilingGrp == null || vm.selectedFilingGrp.toString() == ''){
							AlertService.add("error", "Please select Filing Group to get the Home Office(s).",4000);
							return;
						}						
						vm.getHomeOfficeData(vm.selectedFilingGrp);					
					}
					
					if(vm.selectedOption && vm.selectedOption == '3'){
						
						if(vm.selectedFilingGrp == undefined || vm.selectedFilingGrp == null || vm.selectedFilingGrp.toString() == ''){
							AlertService.add("error", "Please select Filing Group to get the Code Combination(s).",4000);
							return;
						}
						vm.getCodeCombinationData(vm.selectedFilingGrp);	
					}
					
					if(vm.selectedOption && vm.selectedOption == '4'){
						vm.showParentMes = false;
						vm.showHomeOffices = false;
						vm.showEachEntities = false;
					}
					
					
				}				

				$scope.$watch(function(){ return workspaceFactory.screens}, function(val){
		            vm.workspaces = workspaceFactory.getScreen();
		            $timeout(function() {
		                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
		                    vm.scrlTabsApi.init();
		                }
		            });
		        });
			
			  vm.tabs = [];
		        vm.updateFn = function(arg){
		            var obj = [];
		            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
		            vm.setTab(obj);
		        }
		        
				// Execute/Run the Apportionments
				vm.runApprtionment = function(){
					
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select the Tax Year to run the Apportionment.",4000);
						return;
					}
					
					if(vm.selectedScenario == undefined || vm.selectedScenario == null|| vm.selectedScenario.toString() == ''){
						AlertService.add("error", "Please select Scenario to run the Apportionment.",4000);
						return;
					}
					
					if(vm.selectedFilingGrp == undefined || vm.selectedFilingGrp == null|| vm.selectedFilingGrp.toString() == ''){
						AlertService.add("error", "Please select Filing Group to run the Apportionment.",4000);
						return;
					}
					
					if(vm.selectedOption == undefined || vm.selectedOption == null || vm.selectedOption == ''){
						AlertService.add("error", "Please select the Apportionment Option (dropdown) to proceed.",4000);
						return;
					}
					
					// Send the input params
					var apprtObj = {};
					apprtObj['sso_id'] = vm.logged_in_user;
					apprtObj["p_tax_year"] = vm.selectedTaxYear;
					apprtObj['filing_key'] = vm.selectedFilingGrp; 
					apprtObj['p_scenario'] = vm.selectedScenario;
					apprtObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					
					if(vm.selectedOption && vm.selectedOption == '1'){	
						if(vm.selectedParentMes == undefined || vm.selectedParentMes == null || vm.selectedParentMes.length == 0){
							AlertService.add("error", "Please select the Parent ME(s) to run Apportionment.",4000);
							return;
						}						
						apprtObj["p_me_key"] = _.map(vm.selectedParentMes, 'PARENT_ME_KEY').toString();					
					}
					
					if(vm.selectedOption && vm.selectedOption == '2'){	
						if(vm.selectedHomeOffices == undefined || vm.selectedHomeOffices == null || vm.selectedHomeOffices.length == 0){
							AlertService.add("error", "Please select the Home Office(s) to run Apportionment.",4000);
							return;
						}
						apprtObj["p_le_key"] = _.map(vm.selectedHomeOffices, 'VALUE').toString();	
					}
					
					if(vm.selectedOption && vm.selectedOption == '3'){	
						if(vm.selectedEntities == undefined || vm.selectedEntities == null || vm.selectedEntities.length == 0){
							AlertService.add("error", "Please select the Entities to run Apportionment.",4000);
							return;
						}
						apprtObj["p_cc_key"] = _.map(vm.selectedEntities, 'KEY').toString();		
					}
					
					if(vm.selectedOption && vm.selectedOption == '4'){
						apprtObj["p_me_key"] = _.map(vm.parentMEDropDown, 'PARENT_ME_KEY').toString()
					}
										
					// Clob Details
					var clobDataDetails = [];
					clobDataDetails.push(apprtObj);	
					console.log(" clobDataDetails : ", clobDataDetails);
					// Send details
					sendApportionmentDetails(clobDataDetails);
				}
				
				// Send Details to the Service Layer for persisting the data
				function sendApportionmentDetails(clobDataDetails) {		
					var message = "For the selection made, the process to run an Apportionment is triggered.";
					
					initiateApprFactory.executeApprmnt(clobDataDetails).then(function(result) {
						console.log("  result  :", result );
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							// vm.isSaveClicked = false;
							return;
						} else {
							AlertService.add("success", message, 4000);								
	                    }
					});
				}
				
				// To Reset the selected values
				vm.reset = function(){
					vm.showParentMes = false;
					vm.showHomeOffices = false;
					vm.showEachEntities = false;
					
					vm.selectedTaxYear = '';
					vm.selectedFilingGrp = '';  	
					vm.selectedOption = '';
					vm.selectedParentMes = '';
					vm.selectedHomeOffices = '';
					vm.selectedEntities = '';					
				}
				
				
				
				
				
				/*
				 * //Get the Entities for the Modal dropdown
				 * vm.getCodeCombinationData = function(parent_me_strng) {
				 * console.log("GetCodeCombinationData -- parent_me_strng : ",
				 * parent_me_strng);
				 * 
				 * var params = {"action_code" : 'g1mvvs', "parent_me_string" :
				 * parent_me_strng};
				 * JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject',
				 * params).then(function(data) { vm.codeCombinationData =
				 * data.jsonObject; console.log("CHCK -- codeCombinationData: ",
				 * vm.codeCombinationData); vm.showEachEntities = true; }); }
				 */
				
				/*
				 * //Load the Entities based on Parent ME selected
				 * vm.loadEntities = function(){ console.log("
				 * vm.selectedParentMes : ", vm.selectedParentMes); var
				 * parent_me_strng = ''; for(var indx in vm.selectedParentMes){
				 * if(parent_me_strng!=''){ parent_me_strng = parent_me_strng
				 * +','+ vm.selectedParentMes[indx].PARENT_ME_KEY; }else{
				 * parent_me_strng = vm.selectedParentMes[indx].PARENT_ME_KEY; }
				 *  } console.log("loadEntities -- parent_me_strng : ",
				 * parent_me_strng); vm.getCodeCombinationData(parent_me_strng); }
				 */
				
			     			
			}
			return controllers;
		});