define([
    'angular',
    './relatedPartyclassificationController',
    './relatedPartyclassificationService'


], function () {
    'use strict';
    return angular.module('app.relatedPartyclassification', ['app.relatedPartyclassificationController','app.relatedPartyclassificationService'] );


});