define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.fedFormService',[])
        .factory("FedFormFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,JsonObjectFactory,GENERAL_CONFIG) {
            var fedFormFactory = {};


            var URLS = {
                FETCH: GENERAL_CONFIG.base_url + '/loadPivotFormJson',
                UPDATE: GENERAL_CONFIG.base_url + '/saveFedForm?process_name=F4797Save',
                DELETE: GENERAL_CONFIG.base_url + '/deleteRowData'
            }


            fedFormFactory.getData = function (_url,_params) {
                console.log("TEST/////////////////fedFormFactory.getData////////////////////////////////");
            }


            fedFormFactory.loadGenForm = function(_TbAmt,_combKey,_taxYear,_jcd,_scenario,_formId) {
           	   
                var params = {
                		"agg_total" : _TbAmt,
                		"action_id" : "65", //"51",
                		"combination_key" : _combKey,
                		"tax_year" : _taxYear,
                		"scenario" :_scenario,
                		"jcd_key" :_jcd,
                		"form_no" :_formId //"F_SCHDD"//"F4797"
                };

                console.log("Within loadForm..",URLS.FETCH);
                //var canceler = $q.defer();

                  var promise = $http({
                      method: "post",
                      url: URLS.FETCH,
                      data: params
                      //timeout: canceler.promise
                  }).then(function (response) {
                      console.log("response: " , response)
                      console.log("response.status: " , response.status)
                      return response.data;
                  });   

                  return promise;   	   
              }//end loadGenForm  
            
            fedFormFactory.saveData = function (_formLines,_combKey,_form,_taxYear,_jcd,_scenario) {
               var fin_data = fetchDirtyObjectArray(_formLines,_combKey,_form,_taxYear,_jcd,_scenario);
               console.log("Fin Data: "+JSON.stringify(fin_data));
            	   console.log("Rows being saved: "+fin_data.length);
               var promise;
               if(fin_data.length > 0){
            	   promise = JsonObjectFactory.saveJSONObj(URLS.UPDATE,fin_data).then(function (response) {
            	   console.log("response: " , response);
            	   //console.log("response data: " , JSON.stringify(response.data));
                   return response;
                  }); 
               }
               else
            	   {
            	   	//promise = $q.when(fin_data.length == 0);
            	   promise = $q.when("NO_SAVE");
            	   }
               return promise;   
           	//alert("Saved!");
          }
            
           fedFormFactory.deleteRowData = function(_rowId,_combKey,_taxYear,_jcd,_scenario) {            	

                var row_data = fetchDeleteRow(_rowId,_combKey,_taxYear,_jcd,_scenario);

                console.log("Delete Data _combKey: "+JSON.stringify(_rowId));
                
                
                var promise = JsonObjectFactory.saveJSONObj(URLS.DELETE,row_data).then(function (response) {
                	console.log("Del response: " , response)
                    return response;
                   });
                   return promise; 
               /* 
                var params = {
                		"row_id" : _rowId,
                };
                
                var promise = $http({
                    method: "post",
                    url: URLS.DELETE,
                    data: params
                    //timeout: canceler.promise
                }).then(function (response) {
                    console.log("response: " , response)
                    console.log("response.status: " , response.status)
                    return response;
                }); */ 
            	//alert("Saved!");
           }
           
           function fetchDeleteRow(_trans_dtl,_combKey,_taxYear,_jcd,_scenario)
           {
           	  var rowData = {    					   
   					   "tax_year":_taxYear, //vm.taxYear,
   					   "scenario":_scenario, //vm.scenario,
   					   "jcd_key" :_jcd, // vm.jcd,
   					   "trans_type_key" : 7,
   					   "combination_key" :_combKey,
   					   "group_obj_key" :201473,
   					   "form_key" :1,
   					   "edit_status" :"D",
   					   "trans_dtls_key" : _trans_dtl//trans_details_key  					 
   			   		}        	
           	  return rowData;
           }//end populateDirtyJsonObj
            
            
            /**
             * Iterate through the form, figure out which fields have changed and 
             * build the return values Object Array accordingly.
             * 
             */
            function fetchDirtyObjectArray(_formLines,_combKey,_form,_taxYear,_jcd,_scenario){

            	//console.log("generic Form: "+JSON.stringify(_genForm));
            	var returnObj = [];
            	
            	angular.forEach(_formLines, function(formLine) {
            		if(formLine.objectDimensions.IS_MULTI  != 'Y'){
            			if(formLine.dataRowList[0].rowDimensions.TRANS_DETAILS_KEY != 0){ 
            			var _el = eval("_form.el_"+formLine.dataRowList[0].rowDimensions.TRANS_DETAILS_KEY);
            			console.log("^^^^ Generic Form: el "+_form+": formLine.id : "+formLine.dataRowList[0].rowDimensions.TRANS_DETAILS_KEY);
                    	console.log("^^^^ Generic Form: el dirty? "+_el.$dirty);
            			if(_el.$dirty){
            				returnObj = populateDirtyJsonObj(formLine.dataRowList[0].rowDimensions.TRANS_DETAILS_KEY,formLine.objectDimensions.LINE_NO,formLine.dataRowList[0].dataCellList[2],formLine.dataRowList[0].rowDimensions.OCCURRENCE,_combKey,_form,_taxYear,_jcd,_scenario,returnObj);
            			}
            		  }
            			else
            			{
            				//Multi != Y and Trans Dtl ==0..
            				if(formLine.dataRowList[0].rowDimensions.IS_EDITABLE_ROW == 'Y'){
            					//Possible record for insertion
            					//Get 3rd cell.
            					if(formLine.dataRowList[0].dataCellList.length == 3){
            						if(formLine.dataRowList[0].dataCellList[2].value != 0){
            							//Gain Loss Cell has non zero value..insert!
            							returnObj = populateDirtyJsonObj(formLine.dataRowList[0].rowDimensions.TRANS_DETAILS_KEY,formLine.objectDimensions.LINE_NO,formLine.dataRowList[0].dataCellList[2],formLine.dataRowList[0].rowDimensions.OCCURRENCE,_combKey,_form,_taxYear,_jcd,_scenario,returnObj);
            						}
            					}
            				}
            			}
            		}
            		else{//Multi  
            			console.log("Multi Line: "+formLine.objectDimensions.LINE_NO)
            			var i=0;
            			angular.forEach(formLine.dataRowList, function(dataRow) {
            				if(i != 0){//skip header row.
    	        				var alwaysAdd = false;
    	            			if(dataRow.rowDimensions.TRANS_DETAILS_KEY == 0){
    	            				//new multi row..
    	            				alwaysAdd = true;
    	            			}  
    	            			//var j=0;
    	        				angular.forEach(dataRow.dataCellList, function(cell) {	 
    	        					//if(j != 0){//skip first cell - action cell.
    	        						console.log("cell.key: "+cell.key);
    	        						var _el = eval("_form.el_"+dataRow.rowDimensions.OCCURRENCE+"_"+cell.key);
    	        						console.log("^^^^ Generic Form: el dirty? "+_el.$dirty);
    	                        	
    	        						if(alwaysAdd || (cell.property.is_editable_cell && _el.$dirty)){
    	        							returnObj = populateDirtyJsonObj(dataRow.rowDimensions.TRANS_DETAILS_KEY,formLine.objectDimensions.LINE_NO,cell,dataRow.rowDimensions.OCCURRENCE,_combKey,_form,_taxYear,_jcd,_scenario,returnObj);
    	        						} 
    	        					//}
    	        					//j++;
    	        				  });
            				}
        					i++;
            			});
            		  }        	   
            	});
            	
            	return returnObj;
            }
            
            function populateDirtyJsonObj(_trans_dtl, _lineNo, _cell,_occurence,_combKey, _form,_taxYear,_jcd,_scenario,_retObj)
            {
            		_cell = prepareForSave(_cell);
            	  var targetRow = {    					   
    					   "tax_year":_taxYear, //vm.taxYear,
    					   "scenario":_scenario, //vm.scenario,
    					   "jcd_key" :_jcd, // vm.jcd,
    					   "trans_type_key" : 7,
    					   "combination_key" :_combKey,
    					   "group_obj_key" :201473,
    					   "form_key" :1,
    					   "line_no" :_lineNo,
    					   "occurence" :_occurence,
                                           "line_attrib_key" :_cell.key,//line attrib key
    					   "Attribute_Name" : _cell.name,
    					   "Attribute_Value" : _cell.value,
    					   "edit_status" : _trans_dtl === "0"? "I":"U",
    					   "trans_dtls_key" : _trans_dtl,//trans_details_key,
    					   "trans_status" : "A"    					 
    			   		}
            	  _retObj.push(targetRow);
            	  return _retObj;
            }//end populateDirtyJsonObj
            
            function prepareForSave(_cell){
            	if(_cell.property.edit_type == 'checkbox'){//or radio
            		_cell.value = _cell.booleanVal ? "Y":"N";
            	}
            	return _cell;  
            }//end prepareForSave
            
            

            return fedFormFactory;
        }])

    return services;
});