define([
    'angular',
    './F304DiagReportController'
], function () {
    'use strict';
    var module = angular.module('app.dstF304DiagReport', ['app.F304DiagReportController'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('f304-diag-report', {
                url: '/f304-diag-report',
                "templateUrl": "app/components/DST/diagnostics/f304-diag-report/f304DiagReport.html",
                "controller": "F304DiagReportCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
