/**
 * Created by 502149104 on 5/26/2017.
 */

define ([
    'angular'
   ], function () {

       'use strict';

       var services = angular.module('app.gridBuilderServices', [])
           .factory("GridBuilderFactory",["$http",'AlertService','GENERAL_CONFIG', function($http, AlertService,GENERAL_CONFIG) {

               var gridBuilderFactory = {};

               var URLS = {
                   genGridHeader : GENERAL_CONFIG.admin_api_url + "/generateGridHeader",
                   saveGrid : GENERAL_CONFIG.admin_api_url + "/saveGrid",
                   getHeaders: GENERAL_CONFIG.admin_api_url + "/gridHeaderByKey",
                   genGridAction: GENERAL_CONFIG.admin_api_url + "/saveGridAction",
                   saveScreenModal: GENERAL_CONFIG.admin_api_url + "/saveScreenModal",
                   getScreenMdlName : GENERAL_CONFIG.admin_api_url + "/getScreenModalNme",
                   getActionNameByGrid : GENERAL_CONFIG.admin_api_url + "/getActionsByGrid",
                   removeGridAction: GENERAL_CONFIG.admin_api_url + "/removeGridActionName"
               }

               // Generates grid headers
               gridBuilderFactory.genGridHeader = function(data) {
                   var promise = $http({
                       method: "POST",
                       url: URLS.genGridHeader,
                       data: data
                   }).then(function (response) {


                        console.log("response : " , response);

                       if( response.data.callSuccess === "1" ){
                           console.log("response.data : " , response.data);
                           return response.data;
                       }else{
                               AlertService.add("error", "Headers could not be generated. The action type must be set to 'grid' . Please make sure that the parameters being passed to the procedure have the correct default values if needed.   " , 6000);
                       }




                   });

                   return promise;
               };

               gridBuilderFactory.getGridHeaderByKey = function(data) {
                   var promise = $http({
                       method: "POST",
                       url: URLS.getHeaders,
                       data: data
                   }).then(function (response) {

                       if( response.data.callSuccess === "1" ){
                           return response.data;
                       }else{
                           AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);

                       }

                   });

                   return promise;
               };





               // Saves grid (along with grid headers)
               gridBuilderFactory.saveGrid = function(grid){

                   var data = {
                       grid_key:grid.grid_key,
                       action_key: grid.action_key,
                       grid_name:grid.grid_name,
                       grid_desc:grid.grid_desc,
                       default_row_height:grid.default_row_height,
                       batch_select:grid.batch_select,
                       comments:grid.comments,
                       grid_type:grid.grid_type,
                       is_pivot:grid.is_pivot,
                       max_xls_rows:grid.max_xls_rows,
                       expand_cols:grid.expand_cols,
                       fixed_columns:grid.fixed_columns,
                       client_key: grid.client_key,
                       attachments:grid.attachments,
                       header_data:JSON.stringify(grid.headers),
                       batch_select_rules:grid.batch_select_rules,
                       batch_rule_message:grid.batch_rule_message,
                       header_height:grid.header_height,
                       documentation:grid.documentation,
                       doc_header_key:grid.doc_header_key,
                       doc_header_type:grid.doc_header_type,
                       screen_validation:grid.screen_validation,
                       screen_validation_rules:grid.screen_validation_rules,
                       audit_cells:grid.audit_cells
                   }

                   var promise = $http({
                       method: "POST",
                       url: URLS.saveGrid,
                       data: data
                   }).then(function (response) {
                       console.log(response);
                       if(response.data.callSuccess === "1" ) {
                           AlertService.add("success", "Grid has been successfully saved", 3000);
                           return response.data;
                       }else{
                           let error = response.data.errorMessage ? response.data.errorMessage : 'An error has occurred, If you continue to experience this issue please visit the help page to contact support.';
                           AlertService.add("error", error, 3000);
                       }
                   });
                   return promise;
               };

               // API for generating gridAction in Batch select modal.
               gridBuilderFactory.saveGridAction = function(data) {
                   var promise = $http({
                       method: "POST",
                       url: URLS.genGridAction,
                       data: data
                   }).then(function (response) {
                        console.log("response : " , response);
                           return response;
                   });
                   return promise;
               };

               // API for generating screenModal in Batch select modal.
               gridBuilderFactory.saveScreenModal = function(data) {
                   var promise = $http({
                       method: "POST",
                       url: URLS.saveScreenModal,
                       data: data
                   }).then(function (response) {
                        console.log("response : " , response);
                           return response;

                   });
                   return promise;
               }

               //getScreenModalName
               gridBuilderFactory.getScreenModalName = function(data) {
                    var promise = $http({
                        method: "POST",
                        url: URLS.getScreenMdlName,
                        data: data
                    }).then(function (response) {
                        console.log("response : " , response);
                        return response;
                    });
                    return promise;
                }

            //getActionNameByGrid
            gridBuilderFactory.getActionName = function(data) {
                var promise = $http({
                    method: "POST",
                    url: URLS.getActionNameByGrid,
                    data: data
                }).then(function (response) {
                     console.log("response : " , response);
                        return response;

                });
                return promise;
            }

            //Remove grid action
            gridBuilderFactory.removeActionName = function(data) {
                var promise = $http({
                    method: "POST",
                    url: URLS.removeGridAction,
                    data: data
                }).then(function (response) {
                     console.log("response : " , response);
                        return response;
                });
                return promise;
            }
               return gridBuilderFactory;
           }
       ]);

       return  services;
   });
