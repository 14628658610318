define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.firf5471h1Service',[])
        .factory("firf5471h1ServiceFactory",['$q','$http','$rootScope','$log','JsonObjectFactory','AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams','GENERAL_CONFIG', function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
        	var firf5471h1ServiceFactory = {};
            var returnObj = {};
            var logged_in_user = USER_SETTINGS.user.sso_id;
            firf5471h1ServiceFactory.saveTestedUnits = function(_callName ,_data, columnName){
            	 console.log("data", _data);
            	 
            
            }
//*************for gilti hte analysis grid******************
//**********************************************************
            return firf5471h1ServiceFactory;
        }]);

    return services;
});










