define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.rolloverDashboardController', [])

        .controller('RolloverDashboardController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter', rolloverDashboardController]);
    	function rolloverDashboardController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter) {
        var vm = this;

        vm.modalTitle = "Rollover Dashboard";
		vm.showFormLoader = true;
		vm.sourceData = _.clone(gridData);
		vm.combination_key_List = [];
		vm.saveloader = false;
		vm.targetData = [];
		vm.originalSourceData = [];
		vm.originalTargetData = [];

		function RenderSourceData(){
			angular.forEach(vm.sourceData, function (row, key) {
				var tempTargetData = {
					OBJ_ID: key + 1,
					SOURCE_CCK : "-",
					SOURCE_CDR_NO: "-",
					SOURCE_GOK: "-",
					SOURCE_LEDGER: "-",
					SOURCE_LEID: "-",
					SOURCE_ME_CODE: "-",
					SOURCE_RPT_PERIOD: "-",
					SOURCE_CHART_CODE: "-",
					loading: false,
					noRecords: false,
					showList: false,
					isTarget: false
				};
				vm.targetData.push(tempTargetData);
			});
			vm.originalSourceData = angular.copy(vm.sourceData);
			vm.originalTargetData = angular.copy(vm.targetData);
			vm.showFormLoader = false;
		}

		vm.getBusinessInfo = function (search_key, row) {
			vm.targetData[row].loading = true;
			vm.targetData[row].noRecords = false;
			
            var params = {};
            vm.targetDetails = [];
            if (!!search_key) {
                params.search_text = search_key;
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=90513b', params).then(function (data) {
					if (data.jsonObject.length > 0) {
						vm.targetDetails = data.jsonObject;
						vm.targetData[row].loading = false;
						vm.targetData[row].showList = true;
						vm.targetData[row].noRecords = false;
					} else {
						vm.targetData[row].noRecords = true;
						vm.targetData[row].loading = false;
						vm.targetData[row].showList = false;
					}
				});
            } else {
                vm.targetData[row].loading = false;
                vm.targetData[row].noRecords = false;
            }
		};
		
		vm.fillTargetRow = function (searched_data, row) {
			if(vm.sourceData[row].data.SOURCE_RF_CHART_KEY == searched_data.CHART_KEY){
				var selectedTarget = searched_data.TARGET_ENTITY_DETAILS.split("-");
				vm.targetData[row].SOURCE_CCK = searched_data.COMBINATION_KEY;
				vm.targetData[row].SOURCE_LEID = selectedTarget[0];
				vm.targetData[row].SOURCE_CDR_NO = selectedTarget[1];
				vm.targetData[row].SOURCE_ME_CODE = selectedTarget[2];
				vm.targetData[row].SOURCE_RPT_PERIOD = selectedTarget[3];
				vm.targetData[row].SOURCE_CHART_CODE = selectedTarget[4];
				vm.targetData[row].TAX_YEAR = searched_data.TAX_YEAR;
				vm.targetData[row].JCD_KEY = searched_data.JCD_KEY;
				vm.targetData[row].SCENARIO = searched_data.SCENARIO;
				vm.targetData[row].showList = false;
				vm.targetData[row].isTarget = true;
			}else{
				AlertService.add("warning", "This selection cannot be made as the source chart key does not matches the target chart key", 6000);
			}
        };

		vm.removeSourceTarget = function(i){
			if(vm.sourceData.length > 1){
				vm.sourceData.splice(i, 1);
				vm.targetData.splice(i, 1);
			}else{
				AlertService.add("warning", "Atleast one source entity must be present for save.", 6000);
			}
		};

		RenderSourceData();

        vm.saveRollover = function (url, data){
			var jsonObj =  _.extend({clob_json : JSON.stringify(data)});
			jsonObj.tax_year = data[0].TAX_YEAR;
			jsonObj.jcd_key = data[0].JCD_KEY;
			jsonObj.scenario = data[0].SCENARIO;
            var promise = $http({
                method: "post",
                url: url,
                data: jsonObj
            }).then(function (response) {
            	vm.saveloader = false;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchdIForm() response.data.jsonObj ::",response.data.jsonObject);
				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					var args = {combination_key: vm.combination_key_List[0], gridFilter: {combination_key: vm.combination_key_List[0]}};
					$rootScope.$emit('gridUpdate', args);
					AlertService.add("success", "FR process saved successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                    }
				}
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
		};
		
        vm.save = function(){
			var targetPresent = false;
			var targetPresentIndex = [];
			var sourceTargetjson = [];
			var saveJsonObj = {};

        	if(vm.targetData.length > 0){
				angular.forEach(vm.targetData, function (value, key) {
					if(value.isTarget){
						targetPresent = true;
						targetPresentIndex.push(key);
					}
				});
				if(targetPresent){
					for(var i = 0; i < targetPresentIndex.length; i++){
						saveJsonObj = {
							"TAX_YEAR": vm.targetData[targetPresentIndex[i]].TAX_YEAR,
							"JCD_KEY": vm.targetData[targetPresentIndex[i]].JCD_KEY,
							"SCENARIO": vm.targetData[targetPresentIndex[i]].SCENARIO,
							"IS_BUSINESS_CHANGE": "N",
							"PRIOR_YR_CC_KEY_LIST": vm.sourceData[targetPresentIndex[i]].data.SOURCE_CCK,
							"CURR_YR_CC_KEY": vm.targetData[targetPresentIndex[i]].SOURCE_CCK,
							"RF_CC_KEY_LIST": "",
							"PRIOR_YR_ME_KEY_LIST": "",
							"CURR_YR_ME_KEY": ""
						};
						sourceTargetjson.push(saveJsonObj);
					}
					if(targetPresentIndex.length !== vm.sourceData.length){
						AlertService.add("info", "NOTE : Only records with target selected would be saved, Rest all would be ignored", 4000);
					}
				}else{
					AlertService.add("warning", "No Data to save, as target is not selected for any of the source entities", 4000);
        			return;
				}
        	};

        	vm.saveloader = true;

			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=efro2f";
			vm.saveRollover(url, sourceTargetjson);

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        };

		vm.reset = function (){
			vm.sourceData = angular.copy(vm.originalSourceData);
			vm.targetData = angular.copy(vm.originalTargetData);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
});