define(
		[ 'angular'
			
		],
		function() {
			'use strict';

			var controllers = angular.module('app.K2SyncCtrl', [])
					.controller('K2SyncCtrl', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'initiateApprFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG','$filter','$timeout', K2SyncCtrl])
					
			function K2SyncCtrl($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, USER_SETTINGS, 
					initiateApprFactory,JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, $filter,$timeout) {
				
				var vm = this;	
				//vm.selectedTaxYear = GlobalService.globalParams.tax_year; 	
				//Tax Year Dropdown
				vm.filteredTaxYearData = [];				
				//Set the Loggin USER settings details
				vm.logged_in_user = USER_SETTINGS.user.sso_id;
				//Flag to show Spinner
				vm.loading = true;
				//Flag to show the parent me
				vm.loadParentMEDropdown = false;
				vm.jcd_key  = GlobalService.globalParams.jcd_key;	
				
				//Redirect the URL to a sourcing screen after closing the Modal screen 
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
				
				//Get the Parent ME Drop-Down
				vm.getParentMeDropdown = function() {	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select the Tax Year to run the Apportionment.",4000);
						return;
					}
					vm.parentMEDropDown = [];
					vm.selectedParentMes = '';
					
					//var params = {"action_code" : 'vxg30f', "sso_id" : vm.logged_in_user, "tax_year" : vm.selectedTaxYear };
					var params = {"action_code" : '07roqs', "sso_id" : vm.logged_in_user, "p_tax_year" : vm.selectedTaxYear, "p_scenario" : vm.selectedScenario };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.parentMEDropDown =  $filter('filter')(data.jsonObject, function(data) {
			                       					return (data.LEVEL_GROUP === 'Parent ME');
			                   						});	
							vm.loadParentMEDropdown = true;
							vm.loading = false;
					});
				}
				//vm.getParentMeDropdown();	
				
				//Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {	
					vm.selectedTaxYear = '';
					
					var params = {"action_code" : 'e83e1x',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});							
						for(var indx in vm.taxYearData){
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearData[indx].CODE_DISP_DESC <= (GlobalService.globalParams.tax_year) && vm.taxYearData[indx].CODE_DISP_DESC >= (GlobalService.globalParams.tax_year-5))){
								vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
							}
						}						
					});					
				};
				vm.getTaxYearDropDown();
				
				//Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {
					vm.selectedScenario = "";	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
						AlertService.add("error", "Please select the Tax Year to get the Filing Group.",4000);
						return;
					}
			
					var params = {"action_code" : 'va45va',"p_tax_year" : vm.selectedTaxYear, 'p_jcd_key' :vm.jcd_key};	
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;							
						// vm.scenarioRAF = _.filter(vm.scenarioDropDown, function(p){
	                	// 	  return _.includes(['Return As Filed'], p.LABEL);  
						// });	
						//vm.selectedScenario = (vm.scenarioRAF!=undefined && vm.scenarioRAF.length>0) ? vm.scenarioRAF[0].VALUE : undefined;		
						
						// vm.getParentMeDropdown();
						// vm.getFromDropdown();
						
					});					
				};
				
				vm.getFromDropdown = function() {	
					vm.selectedForm = '';
					
					var params = {"action_code" : 'abkcdl',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.getForm = data.jsonObject;
						_.remove(vm.getForm, {PRIORITY_ORDER: 1}); 
											
					});	
				};
				
				vm.getPshipDropdown = function() {	
					vm.selectedPship = '';
					vm.showPship = false;
					
					var params = {"action_code" : 'kae9bk',"tax_year" : vm.selectedTaxYear, 'scenario' : vm.selectedScenario,'jcd_key' :vm.jcd_key,'p_form':vm.selectedForm,
								  'p_schedule': 'K2', 'parent_me_string' : vm.selectedParentMes};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.getPshipDtls = data.jsonObject;
						_.remove(vm.getPshipDtls, {VALUE: -1});
						vm.showPship = true;											

					});					

				};
				
				$scope.$watch(function(){ return workspaceFactory.screens}, function(val){
		            vm.workspaces = workspaceFactory.getScreen();
		            $timeout(function() {
		                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
		                    vm.scrlTabsApi.init();
		                }
		            });
		        });
			
			  vm.tabs = [];
		        vm.updateFn = function(arg){
		            var obj = [];
		            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
		            vm.setTab(obj);
		        }
		        
				///vm.getScenarioDropDown();
				
				/*
				$scope.$watch(function() { return JCDFactory.JCDList },   function() {
					if(JCDFactory.JCDList!=undefined && JCDFactory.JCDList!=null && JCDFactory.JCDList.length > 0){											
						var b = _.find(JCDFactory.JCDList, function(o) { return o.ACTIVITY_NAME.includes(workspaceFactory.activity.label); });							
						//Get the JCD Core details
						vm.jcd_key = b.JCD_KEY;
						vm.jcd_ta_key = b.JCD_TA_KEY;
					}
				});*/
				
				//Execute/Run the Component Defaults Rollover process
				vm.runSyncProcess = function(){
					
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select the Tax Year to run the sync process.",4000);
						return;
					}
					if(vm.selectedForm == undefined || vm.selectedForm == null || vm.selectedForm == ""
					){
						AlertService.add("error", "Please select the Form to run the sync process.",4000);
						return;
					}
					if(vm.selectedParentMes == undefined || vm.selectedParentMes == null || vm.selectedParentMes == ""
					){
						AlertService.add("error", "Please select the ME(s) to run the sync process.",4000);
						return;
					}	
					if(vm.selectedPship == undefined || vm.selectedPship == null || vm.selectedPship == ""
					){
						AlertService.add("error", "Please select the Partnership to run the sync process.",4000);
						return;
					}	
					
					for(var i=0; i < vm.getPshipDtls.length; i ++) {
					if((vm.selectedPship == vm.getPshipDtls[i].VALUE) && (vm.getPshipDtls[i].COMP_DEFAULT == 'N')){
						AlertService.add("error", "Please set the Component Defaults before running the K2 Sync.",4000);
						return;
					}
				}
					//Send the input params 
					var saveSyncObj = {};
					saveSyncObj['sso_id'] = vm.logged_in_user;
					saveSyncObj["tax_year"] = vm.selectedTaxYear;	
					saveSyncObj["scenario"] = vm.selectedScenario;
					saveSyncObj["jcd_key"] = GlobalService.globalParams.jcd_key; //vm.jcd_key;  -- 
					saveSyncObj["p_form"] = vm.selectedForm;
					saveSyncObj["p_filer_key"] = vm.selectedPship;
															
					//Clob Details
					var clobDataDetails = [];
					clobDataDetails.push(saveSyncObj);					
					//Send details
					vm.initiateSync();
				}

				vm.initiateSync = function () {
					var lockInfo = {},lockedParams = {};
					var params = {
					"sso_id":USER_SETTINGS.user.sso_id,
					"tax_year": vm.selectedTaxYear,
					"scenario" : vm.selectedScenario,
					"jcd_key": GlobalService.globalParams.jcd_key,
					"p_form":vm.selectedForm,
					"p_filer_key": vm.selectedPship,
					"p_filer_label": vm.getPshipDtls.find(item => item.VALUE == vm.selectedPship).LABEL
					};
					lockInfo = {
					"tax_year": vm.selectedTaxYear,
					"scenario" : vm.selectedScenario,
					"jcd_key": vm.jcd_key,
					"combination_key":vm.selectedPship
					};
					
					lockedParams = params;
					// lockedParams["action_code"] = 'n10cpv';
					lockedParams.checkLockedbj = JSON.stringify([lockInfo]);
					var promise = $http({
									   method: "POST",
									//   url: GENERAL_CONFIG.base_url+'/loadJsonObject',
									   url: GENERAL_CONFIG.pdf_engine_api_url + '/initiateSync' ,
									   data: lockedParams
								   }).then(function (response) {
										if (response.data.callSuccess != 1) {
											AlertService.add("error", response.data.errorMessage, 4000);
											return;
										}
										AlertService.add("success", "Sync Process for K2 is initiated. Email will be sent, after the process is complete.", 4000);
								});
				
			}
				
				//To Reset the selected values
				vm.reset = function(){
					vm.selectedTaxYear = '';
					//vm.scenarioRAF=''
					vm.selectedScenario ='';
					vm.getForm='';
					vm.selectedForm = '';
					vm.loadParentMEDropdown = false;
					vm.parentMEDropDown = [];
					vm.selectedParentMes = '';
					vm.getPshipDtls =[];
					vm.selectedPship = '';
					vm.showPship = false;
				}
			     			
			}
			return controllers;
		});