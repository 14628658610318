define(
    ['angular'],
    function () {
        'use strict';

        var services = angular.module('app.est-formService',[])
            .factory('estFormServiceFactory',
                ['$q', '$http', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG',estFormServiceFactory]);

        function estFormServiceFactory($q, $http, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG) {

            console.log('... factory called ...');

            var estFormServiceFactory = {};
            estFormServiceFactory.url = {};
            estFormServiceFactory.url.defaults_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=c4i7xh";

            estFormServiceFactory.saveESTForm = function(_data, Details) {

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params.process_name =  "est-form-save";
                params.jcd_key = GlobalService.globalParams.jcd_key;
                params.sso_id = _data.sso_id;
                params.form_name = _data.form_name;
                params.wf = _data.wf;

                console.log('params = ', params);
                console.log('clob_data = ', params.clob_data);
                console.log('clob_settings = ', params.clob_settings);

                var promise = $http({
                    method: "post",
                    url: estFormServiceFactory.url.defaults_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            return estFormServiceFactory;
        }

        return services;
    }
);