define([
    'angular',
    './secondaryActionDashboardController',
    './secondaryActionDashboardService'



], function () {
    'use strict';
    return angular.module('app.secondaryActionDashboard', ['app.secondaryActionDashboardController','app.secondaryActionDashboardService'] );

});