//dpzi4u
define(
	[ 'angular'

	],
	function() {
		'use strict';

		var controllers = angular.module(
				'app.epAdjustmentsLocalController', []).controller(
				'EpAdjustmentsLocalController',
				[ '$rootScope', '$scope', '$anchorScroll', 'AlertService',
						'CodeComboFactory', 'JsonObjectFactory',
						'EPLocalTaxAdjustmentsFactory', 'GENERAL_CONFIG',
						'ModalFactory', 'GlobalService', '$uibModal',
						'USER_SETTINGS', '$uibModalInstance', 'rowData',
						epAdjustmentsLocalController ])

		.controller(
				'EpAdjustmentsLocalControllerModal',
				[ '$rootScope', '$scope', '$anchorScroll', 'AlertService',
						'CodeComboFactory', 'JsonObjectFactory',
						'EPLocalTaxAdjustmentsFactory', 'GENERAL_CONFIG',
						'ModalFactory', 'GlobalService', 'USER_SETTINGS',
						epAdjustmentsLocalControllerModal ]);

		function epAdjustmentsLocalControllerModal($rootScope, $scope,
				$anchorScroll, AlertService, CodeComboFactory,
				JsonObjectFactory, EPLocalTaxAdjustmentsFactory,
				GENERAL_CONFIG, ModalFactory, GlobalService, USER_SETTINGS) {
			epAdjustmentsLocalController($rootScope, $scope, $anchorScroll,
					AlertService, CodeComboFactory, JsonObjectFactory,
					EPLocalTaxAdjustmentsFactory, GENERAL_CONFIG,
					ModalFactory, GlobalService, {}, USER_SETTINGS, null,
					{}, this);
		}

		function epAdjustmentsLocalController($rootScope, $scope,
				$anchorScroll, AlertService, CodeComboFactory,
				JsonObjectFactory, EPLocalTaxAdjustmentsFactory,
				GENERAL_CONFIG, ModalFactory, GlobalService, $uibModal,
				USER_SETTINGS, $uibModalInstance, rowData, thisObj) {
			// var vm = this;
			// call init() once all the function expressions used by the
			// view model are defined
			var vm = this || thisObj;
			var lookupData = [];
			var lookupData1 = [];
			var lookupData2 = [];
			var lookupData3 = [];
			var lookupData4 = [];
			vm.line_flag = true;
			var tp_key_save = 0;
			vm.FIR_TRANS_TYPE = "LOCAL_TAX_ADJ";

			vm.rowData = rowData || $scope.$parent.rowData;
			if(vm.rowData.TAX_TYPE == 'PTSP' || vm.rowData.TAX_TYPE == 'BRPTSP' || vm.rowData.TAX_TYPE == 'BRPTP'|| vm.rowData.TAX_TYPE == 'NCPTSP'|| vm.rowData.TAX_TYPE == 'NCPTSPNF')
				vm.line_flag = false;

			if ($uibModalInstance) {
				vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
						: null;
				vm.cancel = function() {
					var args = {
						combination_keys : vm.rowData.COMBINATION_KEY,
						gridFilter : {
							COMBINATION_KEY : vm.rowData.COMBINATION_KEY
						}
					};
					$rootScope.$emit('gridUpdate', args);
					$uibModalInstance.close();
					vm.modal_name = null;
				};
			} else {
				vm.modal_name = null;
			}

			vm.showDetails = function(_index, _boolean) {
				vm.existingAdjustments[_index].open = _boolean;
			};

			vm.getAccounts = function(val, acctFor) {

				var balSheetAccts = [ 1, 2, 3 ];
				var acctType = vm.sourceAcctType;
				var acct_type_str = "", is_system_acct_search = 'N', parent_acct_key;
				if (vm.rowData.GRID_TYPE == 'SYSTEM_BOOK_TO_TAX_SUMMARY') {
					is_system_acct_search = 'Y';
				}

				if (acctFor == 'OFFSET') {
					is_system_acct_search = 'N';
					acct_type_str = '1,2,3';
				} else {
					acct_type_str = '5,6,7';
				}

				parent_acct_key = (acctFor === 'OFFSET' ? vm.parentObject.LOCAL_ACCT_KEY
						: 0);

				return EPLocalTaxAdjustmentsFactory.getAccounts(val,
						vm.mainScreenKey, vm.rowData.GE_GECS_ENTITY,
						vm.rowData.SOURCE_SYSTEM, acct_type_str,
						vm.parentObject.CHART_KEY, parent_acct_key,
						is_system_acct_search, vm.rowData.SYSTEM_ACCT_KEY);

			};

			vm.setAccount = function(account, acctFor, index) {

				// validateAcctLineNo(account.FROM_ACCT_TYPE, acctFor);
				if (acctFor === 'PARENT') {
					vm.parentObject["LOCAL_ACCT_KEY"] = account.LOCAL_ACCT_KEY;
					vm.parentObject["LOCAL_ACCT_TYPE"] = account.LOCAL_ACCT_TYPE;
				} else {
					vm.addOffsetRows[index]["LOCAL_ACCT_KEY"] = account.LOCAL_ACCT_KEY;
					vm.addOffsetRows[index]["LOCAL_ACCT_TYPE"] = account.LOCAL_ACCT_TYPE;
					vm.addOffsetRows[index]["ACCT_TP_LINE_NO"] = ""
							+ account.LOCAL_ACCT_KEY
							+ vm.addOffsetRows[index]["TP_KEY"]
							+ vm.addOffsetRows[index]["SCHD_H_LINE_NO"];
					vm.isOffSetAcctExists = true;

					vm.INVALID_OBJECT = "N";
					$scope.adjsForm.invalid_object_holder.$setValidity(
							"invalidAcctTPCombo", true);
				}

			};

			vm.validateDesc = function() {
				if (vm.ADJ_DESC.length === 250) {
					vm.isMaxLimitReached = true;
				} else {
					vm.isMaxLimitReached = false;
				}
			};

			vm.removeMessage = function() {
				vm.isMaxLimitReached = false;
			};

			vm.validateMultiplier = function() {
				if (vm.parentObject.MULTIPLIER != -1
						|| vm.parentObject.MULTIPLIER !== 1) {
					$scope.adjsForm.multiplier.$setValidity(
							"invalidMultiplier", true);
				} else {
					$scope.adjsForm.multiplier.$setValidity(
							"invalidMultiplier", false);
				}
			};

			vm.changeSchd = function() {

				vm.parentObject["SCHD_H_LINE_NO"] = vm.getLineDetails.selected.LINE_NO;
				// validateAcctLineNo(vm.parentObject["LOCAL_ACCT_TYPE"],
				// 'PARENT');
			};

			vm.changeOffsetSchd = function(index, model) {
				// vm.addOffsetRows[index]["SCHD_H_LINE_NO"] =
				// vm.getOffsetLineDetails.selected.LINE_NO;
				vm.addOffsetRows[index]["SCHD_H_LINE_NO"] = model.LINE_NO;
				vm.addOffsetRows[index]["ACCT_TP_LINE_NO"] = ""
						+ vm.addOffsetRows[index]["LOCAL_ACCT_KEY"]
						+ vm.addOffsetRows[index]["TP_KEY"] + model.LINE_NO;
				vm.INVALID_OBJECT = "N";
				$scope.adjsForm.invalid_object_holder.$setValidity(
						"invalidAcctTPCombo", true);
			};

			vm.getTradingPartners = function(val) {
				return EPLocalTaxAdjustmentsFactory.getTradingPartners(
						$scope.taxYear, $scope.scenario, val).then(
						function(res) {
							return res;
						});
			};

			vm.setTradingPartner = function(tp, type, index) {
				if (type === 'PARENT') {
					vm.parentObject["TP_KEY"] = tp.TP_KEY;
				} else {
					vm.addOffsetRows[index]["TP_KEY"] = tp.TP_KEY;
					vm.addOffsetRows[index]["ACCT_TP_LINE_NO"] = ""
							+ vm.addOffsetRows[index]["LOCAL_ACCT_KEY"]
							+ tp.TP_KEY
							+ vm.addOffsetRows[index]["SCHD_H_LINE_NO"];
					vm.INVALID_OBJECT = "N";
					$scope.adjsForm.invalid_object_holder.$setValidity(
							"invalidAcctTPCombo", true);
				}

			};

			vm.changeDCSType = function() {
				vm.dcsHeaderObj["SCHDM_TYPE"] = vm.getDcsAdjsDetails.selected.ADJ_TYPE;
			};

			vm.changeProject = function() {
				if (vm.projectsList.selected !== null) {

					var unsortedProjectSteps;
					var stepsExists = 0;

					stepsExists = vm.projectsList.selected.STEPS_EXISTS;

					unsortedProjectSteps = _
							.filter(
									vm.allProjectsWithSteps,
									{
										'PROJECT_ID' : vm.projectsList.selected.PROJECT_ID
									});
					/*  */

					if (stepsExists > 0) {
						vm.projectSteps.list = _.sortBy(
								unsortedProjectSteps,
								[ 'STEP_NUMBER' ]);
					} else {
						vm.projectSteps.list = [ {
							"STEP_SHORT_DESCRIPTION" : "No Steps Found"
						} ];
					}

					vm.parentObject["PROJECT_ID"] = vm.projectsList.selected.PROJECT_ID;
				} else {
					vm.projectSteps.list = [];
					vm.projectSteps.selected = null;
					vm.parentObject["PROJECT_ID"] = 0;
					vm.parentObject["PROJECT_STEP_ID"] = 0;
				}
			};

			vm.changeProjectStep = function() {
				if (vm.projectsList.selected !== null) {
					vm.parentObject["PROJECT_STEP_ID"] = vm.projectSteps.selected.PROJECT_STEP_ID;
				} else {
					vm.parentObject["PROJECT_STEP_ID"] = 0;
				}
			};

			// not used anywhere
			vm.changePostingGLAcct = function() {
				vm.parentObject["POSTING_GL_ACCT_KEY"] = vm.getDCSPLAcctDetails.selected.GL_ACCT_KEY;
			};

			vm.addOffsetAccount = function() {

				var offsetObject = {
					"CHART_KEY" : vm.rowData.LOCAL_CHART_KEY,
					"LOCAL_ACCT_KEY" : "",
					"ACCT_DD_DISPLAY" : "",
					"LOCAL_ACCT_TYPE" : "",
					"SCHD_H_LINE_NO" : "0",
					"PRIOR_YEAR_AMT" : 0,
					"CURR_YEAR_AMT" : 0,
					"ADJ_AMT" : 0,
					"MULTIPLIER" : 0,
					"TP_KEY" : 0,
					"PROJECT_ID" : 0,
					"PROJECT_STEP_ID" : 0,
					"AUTO_REVERSE_YN" : "N",
					"TRANS_DETAILS_KEY" : 0,
					"IS_ACCT_EDITABLE" : "Y",
					"ACCT_TP_LINE_NO" : "",
					"selectedSchdH" : vm.getOffsetLineDetails.list[0]
				};

				vm.addOffsetRows.push(offsetObject);
			};

			vm.changepostingacct = function() {
				if (vm.posting_change == true) {
					vm.testflag = true;
				} else {
					vm.testflag = false;
				}
			}
			$scope.getTradingPartners_fir = function (val) {
				// alert(val);
				var params = {
					"action_code": '50swfi',
					"tax_year": $scope.taxYear,
					"scenario": $scope.scenario,
					"search_string": val
				};

				return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

					if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
						AlertService.add("error", data.errorMessage);
					} else {
						if (data.jsonObject.length) {
							return data.jsonObject;
						}
					}
				});
			};
			vm.setTradingPartnerD = function(tp, type, index) {
				tp_key_save = 0;
				 tp_key_save = tp.TP_KEY;
			};
			vm.loadAdjustment = function(_index) {
				var _data = [];
				var ref_key = 0;
				_data = vm.existingAdjustments[_index];
				
				if (vm.SOURCE_SYSTEM == 'DCS') {
					
					if(_data.TP_KEY == undefined || _data.TP_KEY == 0 || _data.TP_KEY == '')
					{
					vm.tpObjectD =  {"TP_ENTITY" : "",
							};
					}
					else{
						vm.tpObjectD =  {"TP_ENTITY" : _data.TP_ENTITY,
								"TP_KEY": _data.TP_KEY,
								};	
					}
					vm.partnershipflag = false;
					if (vm.existingAdjustments[_index].ADJ_SOURCE === 'Custom') {
						vm.custom2amtflag = false;
						vm.hide_mandatory_field_c_schdm_type = false;

					} else {
						vm.custom2amtflag = true;
						vm.hide_mandatory_field_c_schdm_type = true;
					}
					if (vm.existingAdjustments[_index].ADJ_SOURCE === 'Custom'
							|| vm.existingAdjustments[_index].ADJ_SOURCE === 'Predefined') {
						vm.hide_mandatory_field_c_p = false;

					} else {
						vm.hide_mandatory_field_c_p = true;
					}
					// partnership conditions FACS, INTPCS, EXTPCS, GHOST

					if (vm.existingAdjustments[_index].ADJ_SOURCE_TYPE === 'FACS'
							|| vm.existingAdjustments[_index].ADJ_SOURCE_TYPE === 'INTPCS'
							|| vm.existingAdjustments[_index].ADJ_SOURCE_TYPE === 'EXTPCS'
							|| vm.existingAdjustments[_index].ADJ_SOURCE_TYPE === 'GHOST'
							|| vm.existingAdjustments[_index].ADJ_SOURCE_TYPE === 'PYPCS') {
						vm.hide_mandatory_field_c_p = false;
						//vm.hide_mandatory_field_c_schdm_type = true;
						vm.partnershipflag = true;
					}

					if (vm.existingAdjustments[_index].PROVISION_M1 === 'Y') {
						vm.hide_save_provi_y = false;
					} else {
						vm.hide_save_provi_y = true;
					}
					vm.hidecgni = false;
					if (vm.existingAdjustments[_index].FLC_CODE === 'CGNI')
					{
						vm.hidecgni = true;

					}
					
					vm.custom2Amt = vm.existingAdjustments[_index].COL_2_AMT;
					vm.oldcustom2Amt = vm.custom2Amt;
					ref_key = _data.REFERENCE_KEY;
					vm.userm1 = vm.existingAdjustments[_index].USER_M1_DESC;
					vm.olduserm1 = vm.userm1;
					vm.templatekey = vm.existingAdjustments[_index].TEMPLATE_ID;
					vm.template = vm.existingAdjustments[_index].TEMPLATE;
					vm.schdmt = vm.existingAdjustments[_index].SCHDM_TYPE;
					vm.oldschdm = vm.existingAdjustments[_index].SCHDM_TYPE_ID;
					vm.tbbsacctval = vm.existingAdjustments[_index].TBBS_ACCT;
					vm.oldtbbs = vm.existingAdjustments[_index].TBBS_ACCT_KEY;
					vm.CURRENT_YR_AMT = vm.existingAdjustments[_index].CY_M1_AMT;
					vm.oldCURRENT_YR_AMT = vm.CURRENT_YR_AMT;
					vm.recurring = (vm.existingAdjustments[_index].RECUR_FLAG == 'Y') ? true
							: false;
					vm.old_recurring = vm.recurring;
					vm.book_amt_exist = (vm.existingAdjustments[_index].NO_BOOK_AMT == 'Y') ? true
							: false;
					vm.old_book_amt_exist = vm.book_amt_exist;
					vm.sch = (vm.existingAdjustments[_index].SCHDM_REVIEW == 'Y') ? 'Yes'
							: 'No';
					vm.old_sch = vm.sch;
					vm.userreviewd = vm.existingAdjustments[_index].USER_REVIEW;
					vm.old_userreviewd = vm.userreviewd;
					vm.timestamp = vm.existingAdjustments[_index].REVIEW_ON;
					vm.old_timestamp = vm.timestamp;
					vm.project = vm.existingAdjustments[_index].PROJECT_NAME;
					vm.old_project_id = vm.existingAdjustments[_index].PROJECT_ID;
					vm.projectStep = vm.existingAdjustments[_index].PROJECT_STEP_NAME;
					vm.old_project_step_id = vm.existingAdjustments[_index].PROJECT_STEP_ID;
					vm.oldschdmid = vm.existingAdjustments[_index].SCHDM_ID;
					// vm.sec174amt =
					// vm.existingAdjustments[_index].SEC_174_AMT;
					// vm.old_sec174amt = vm.sec174amt;

					vm.settledyesno = (vm.existingAdjustments[_index].SETTLE_YN == 'Y') ? 'Yes'
							: 'No';
					vm.oldsettledyesno = vm.settledyesno;
					vm.provision = (vm.existingAdjustments[_index].PROVISION_M1 == 'Y') ? 'Yes'
							: 'No';
					vm.COMMENTS = vm.existingAdjustments[_index].COMMENTS;
					vm.oldcomments = vm.COMMENTS;
					vm.rpt_t_flag = (vm.existingAdjustments[_index].REPO_TRANS == 'Y') ? 'Yes'
							: 'No';
					vm.reportable = (vm.existingAdjustments[_index].REPO_TRANS == 'Y') ? 'Yes'
							: 'No';
					vm.old_reportable = vm.reportable;
					vm.transaction_type = vm.existingAdjustments[_index].REPO_ACTION;

					vm.old_reportable_action = vm.existingAdjustments[_index].REPO_ACTION_CODE;
					vm.reportableactionchange_flag = vm.old_reportable_action;
					vm.rpt_tax_acct = vm.existingAdjustments[_index].REPO_TAX_AMT;
					vm.old_rpt_tax_acct = vm.rpt_tax_acct;
					vm.reasonforchange = vm.existingAdjustments[_index].REASON_BOOK_AMT;
					vm.old_reasonforchange = vm.reasonforchange;
					vm.reportable_trans_desc = vm.existingAdjustments[_index].REPO_TRANS_DESC;
					vm.old_reportable_trans_desc = vm.reportable_trans_desc;
					vm.tax_shetlter_reg_num = vm.existingAdjustments[_index].DR8A_LINE_NO;
					vm.old_tax_shetlter_reg_num = vm.tax_shetlter_reg_num;
					vm.type_of_reportable_trans = vm.existingAdjustments[_index].TYPE_REPO_TRNS;
					vm.old_type_of_reportable_trans = vm.existingAdjustments[_index].TYPE_REPO_TRNS_CODE;
					vm.ref_key = vm.existingAdjustments[_index].REFERENCE_KEY;

					vm.loadProjectSteps();
					// loadtbbsdata();
					loadschdmtype_temp();

					function loadschdmtype_temp() {

						var schdm_temp = "";
						vm.schdmtype = [];
						vm.hideschdmtype = false;
						vm.schdmloading = true;
						vm.schdmtypedata = [];
						for ( var a in vm.mainmodaldata) {
							if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
								schdm_temp = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
							}
						}

						var params = {
							"action_code" : 'jhsi7h',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,

							"sys_acct" : rowData.LOCAL_CHART_KEY + "_"
									+ rowData.LOCAL_ACCT_KEY + "-"
									+ rowData.SYSTEM_ACCT_KEY,
							// "local_accts": rowData.SYS_ACCT_KEY,
							// "schdm_temp" : _item.SCHDM_TEMPLATE_DEFAULT,
							"schdm_temp" : schdm_temp,

						}

						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {

											if (data.callSuccess !== "1") {
												AlertService.add("error",
														data.errorMessage);
												vm.schdmloading = false;
												vm.hideschdmtype = false;
											} else {
												vm.schdmtypedata = data.jsonObject;
												// vm.schdmt =
												// vm.schdmtypedata[0].SCHDM_TYPE_DEFAULT;
												for ( var i in data.jsonObject) {
													vm.schdmtype
															.push(vm.schdmtypedata[i].SCHDM_TYPE);

												}
												vm.schdmtype = _.uniq(
														vm.schdmtype,
														JSON.stringify)

												if (vm.type === false) {

													vm.schdmt = data.jsonObject[0].SCHDM_TYPE_DEFAULT;
												}
												vm.hideschdmtype = false;
												vm.schdmloading = false;

												loadtbbsdata_temp();
												function loadtbbsdata_temp() {

													vm.hidetbbss = false;
													vm.tbbsLoading = true;
													var schdm_temp_tbbs = "";
													vm.tbbsacct = [];
													vm.tbbsacctdata = [];
													// vm.tbbsacctval ="";
													var temp_schdm_key = "";
													for ( var a in vm.mainmodaldata) {
														if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
															schdm_temp_tbbs = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
														}
													}
													for ( var lst in vm.schdmtypedata) {
														if (vm.schdmt == vm.schdmtypedata[lst].SCHDM_TYPE) {
															temp_schdm_key = vm.schdmtypedata[lst].SCHDM_TYPE_SAVE;
														}

													}

													var params = {
														"action_code" : '26k8ua',
														"tax_year" : GlobalService.globalParams.tax_year,
														"scenario" : GlobalService.globalParams.scenario,
														"jcd_key" : GlobalService.globalParams.jcd_key,
														"local_acct" : rowData.LOCAL_ACCT_KEY,
														"sys_acct" : rowData.LOCAL_CHART_KEY,
														"schdm_temp" : schdm_temp_tbbs,
														'source_system' : 'D',
														"schdm_type" : temp_schdm_key,
														"combination_key" :vm.rowData.COMBINATION_KEY

													}

													return JsonObjectFactory
															.getJSONObj(
																	GENERAL_CONFIG.base_url
																			+ '/loadJsonObject',
																	params)
															.then(
																	function(
																			data) {

																		if (data.callSuccess !== "1") {
																			AlertService
																					.add(
																							"error",
																							data.errorMessage);
																			vm.hidetbbss = false;
																			vm.tbbsLoading = false;
																		} else {
																			vm.tbbsacctdata = data.jsonObject;
																			for ( var i in vm.tbbsacctdata) {
																				vm.tbbsacct
																						.push(vm.tbbsacctdata[i].TAX_STREAM_ACCT);
																			}
																			vm.tbbsacct = _
																					.uniq(
																							vm.tbbsacct,
																							JSON.stringify)

																			if (vm.tbbsacct.length > 0
																					&& vm.type === false) {
																				vm.tbbsacctval = vm.tbbsacctdata[0].TAX_STREAM_ACCT;
																			}
																			vm.hidetbbss = false;
																			vm.tbbsLoading = false;
																		}
																		if (vm.tbbsacct.length < 1) {
																			vm.hidetbbss = true;
																		}

																	});
												}
												;

												if (vm.schdmtype.length < 1) {
													vm.hideschdmtype = true;
												}
											}

										});
					}
					;

					if (vm.reportableactionchange_flag === 'BAA') {
						vm.reportableList = [];
						for ( var a in vm.existingAdjustments_temp) {
							if (ref_key == vm.existingAdjustments_temp[a].REFERENCE_KEY) {
								if (vm.existingAdjustments_temp[a].GL_ACCT != undefined
										&& vm.existingAdjustments_temp[a].GL_ACCT != "5555555 - Reportable Transactions") {
									vm.reportableList
											.push({
												glacct : vm.existingAdjustments_temp[a].GL_ACCT,
												old_gl_acct_key : vm.existingAdjustments_temp[a].GL_ACCT_KEY,
												cy_amt : 0,
												earlieradj_amt : 0,
												cy_amt_loading : false,
												earlieradj_amt_loading : false,
												thisadj_amt : vm.existingAdjustments_temp[a].ADJ_AMOUNT,
												old_thisadj_amt : vm.existingAdjustments_temp[a].ADJ_AMOUNT,
												adj_cy_amt : 0,
												glacct_error : '',
												thisadj_amt_error : '',
												type : 'E'

											});
								} else {
									if (vm.existingAdjustments_temp[a].GL_ACCT == "5555555 - Reportable Transactions") {
										vm.glacct1 = vm.existingAdjustments_temp[a].GL_ACCT;
										vm.thisadj_amt1 = vm.existingAdjustments_temp[a].ADJ_AMOUNT;
									}
								}
							}
						}
						vm.adjusted_amt_change();

						for ( var s in vm.reportableList) {
							vm.reportableList[s].adj_cy_amt = vm.reportableList[s].cy_amt
									+ vm.reportableList[s].earlieradj_amt
									+ vm.reportableList[s].thisadj_amt;
						}
					}

					// SCHDM_TEMPLATE_KEY

					vm.type = true;
					vm.TRANS_HEADER_KEY_OLD = vm.existingAdjustments[_index].TRANS_HEADER_KEY;

					// vm.oldtbbsacctval
					// =vm.existingAdjustments[_index].ACCOUNTS[0].PROVISION_ACCT_KEY;;
					vm.checktemplateError();
					vm.cyAmount();
					vm.checktypeError();
					vm.tbbsChange();
					vm.reviewdChange();
				} else {

					console.log('data on load', _data);
					var lineIndex = 0, projectIdIndex = 0, projectStepIdIndex = 0, postingGLIndex = 0, dcsAdjTypeIndex = 0;
					vm.ADJ_DESC = _data.ADJ_DESC;
					vm.SUPPL_INFO = _data.SUPPL_INFO;
					vm.ADJ_SOURCE_TYPE = _data.ADJ_SOURCE_TYPE;
					vm.TRANS_HEADER_KEY = _data.TRANS_HEADER_KEY;
					vm.FIR_TRANS_TYPE = _data.TRANS_TYPE;
					vm.isEdit = true;
					vm.dcsHeaderObj["RECUR_FLAG"] = _data.ACCOUNTS[0].RECUR_FLAG;
					// dcs title

					vm.projectsList.selected = null;
					vm.projectSteps.selected = null;

					for ( var prop in vm.parentObject) {
						if (_data.ACCOUNTS[0].hasOwnProperty(prop)) {
							vm.parentObject[prop] = _data.ACCOUNTS[0][prop];
						}
					}

					if (_data.ACCOUNTS[0].TP_KEY !== 0) {
						vm.tpObject = {
							"TP_ENTITY" : _data.ACCOUNTS[0].TP_ME ? _data.ACCOUNTS[0].TP_LEID + "-"
									+ _data.ACCOUNTS[0].TP_CDR_NO +  "-"
									+ _data.ACCOUNTS[0].TP_REP_PD + "-" + _data.ACCOUNTS[0].TP_ME 
									: _data.ACCOUNTS[0].TP_LEID + "-"
									+ _data.ACCOUNTS[0].TP_CDR_NO + "-"
									+ _data.ACCOUNTS[0].TP_REP_PD  ,
							"TP_KEY" : _data.ACCOUNTS[0].TP_KEY,
							"TP_REP_PD" : _data.ACCOUNTS[0].TP_REP_PD,
							"TP_LEID" : _data.ACCOUNTS[0].TP_LEID,
							"TP_CDR_NO" : _data.ACCOUNTS[0].TP_CDR_NO
						};

					}

					lineIndex = _.findIndex(vm.getLineDetails.list, {
						'LINE_NO' : vm.parentObject.SCHD_H_LINE_NO
					});
					vm.getLineDetails.selected = vm.getLineDetails.list[lineIndex];

					if (vm.parentObject.PROJECT_ID !== 0) {
						projectIdIndex = _
								.findIndex(
										vm.projectsList.list,
										{
											'PROJECT_ID' : vm.parentObject.PROJECT_ID
										});
						vm.projectsList.selected = vm.projectsList.list[projectIdIndex];
						vm.changeProject();
						projectStepIdIndex = _
								.findIndex(
										vm.projectSteps.list,
										{
											'PROJECT_STEP_ID' : vm.parentObject.PROJECT_STEP_ID
										});
						vm.projectSteps.selected = vm.projectSteps.list[projectStepIdIndex];
					}

					if (vm.parentObject.POSTING_GL_ACCT_KEY) {
						postingGLIndex = _
								.findIndex(
										vm.getDCSPLAcctDetails.list,
										{
											'GL_ACCT_KEY' : vm.parentObject.POSTING_GL_ACCT_KEY
										});
						vm.getDCSPLAcctDetails.selected = vm.getDCSPLAcctDetails.list[postingGLIndex];
					}

					dcsAdjTypeIndex = _.findIndex(
							vm.getDcsAdjsDetails.list, {
								'ADJ_TYPE' : _data.ACCOUNTS[0].ADJ_TYPE
							});
					vm.getDcsAdjsDetails.selected = vm.getDcsAdjsDetails.list[dcsAdjTypeIndex];

					if (_data.ACCOUNTS.length > 1) {

						vm.existingOffsetAdjustments = _.filter(
								_data.ACCOUNTS, function(adjObj) {
									return adjObj.ROW_INDICATOR > 2;
								});

					}
					// scroll to top
					$anchorScroll('mainSection');

				}
				$rootScope.$broadcast('table.edit', _data);
			};

			vm.cancel = function() {
				// var args = {};
				// $rootScope.broadcast("gridRefresh", {});
				var args = {
					combination_keys : vm.rowData.COMBINATION_KEY,
					gridFilter : {
						COMBINATION_KEY : vm.rowData.COMBINATION_KEY
					}
				};
				// $rootScope.$emit('gridUpdate', args);
				$uibModalInstance.dismiss('cancel');
			};

			vm.save = function() {

				if ($scope.crudLoading) {
					AlertService.add("info",
							"Please wait while we save this request", 4000);
					return;
				}
				$scope.crudLoading = true;
				var headerSettings = [];
				var tempobj = {};
				var tempobjStatic = {};
				var tempSettings = [];
				var transactions = [];
				var headerObj = {};
				var oldtemplate_key = "";
				var newtemplate_key = "";
				var old_tbbs_acct_key = "";
				var new_tbbs_acct_key = "";
				var old_schdm_key = "";
				var new_schdm_key = "";
				var project_step_id = "";
				var project_id = "";			    
				if (vm.SOURCE_SYSTEM == 'DCS') {
					checkDCSData();

					if (vm.testflag == true) {
						if (vm.templateError || vm.userDescError
								|| vm.cyamtError || vm.typeError
								|| vm.tbbsError || vm.schdmReviewedError
								|| vm.postingtaxaccountcodeError) {
							AlertService
									.add(
											"danger",
											"Please correct the errors below",
											4000);
							$scope.crudLoading = false;
							throw new ("This is not an error. This is just to abort javascript!");
						}
					}

					if (vm.templateError || vm.userDescError
							|| vm.cyamtError || vm.typeError
							|| vm.tbbsError || vm.schdmReviewedError

					// || vm.reportableactionError
					// || vm.reportabletaxamountError || vm.rptdescError
					// || vm.taxshelterError || vm.typeofrptError
					// || vm.reasonforzeroError || vm.glacctError
					// || vm.thisadjamterror
					) {
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						$scope.crudLoading = false;
						throw new ("This is not an error. This is just to abort javascript!");

					}

					if (vm.rpt_t_flag == 'Yes') {
						if (vm.reportableactionError
								|| vm.reportabletaxamountError) {
							AlertService
									.add(
											"danger",
											"Please correct the errors below",
											4000);
							$scope.crudLoading = false;
							throw new ("This is not an error. This is just to abort javascript!");

						}
						if (vm.transaction_type.CODE_NAME == 'DP') {
							if (vm.reportabletaxamountError
									|| vm.rptdescError

									|| vm.typeofrptError) {
								AlertService.add("danger",
										"Please correct the errors below",
										4000);
								$scope.crudLoading = false;
								throw new ("This is not an error. This is just to abort javascript!");
							}
						}

						if (vm.transaction_type.CODE_NAME == 'BAA') {
							if (vm.reportabletaxamountError
									|| vm.rptdescError

									|| vm.typeofrptError || vm.glacctError
									|| vm.thisadjamterror) {
								AlertService.add("danger",
										"Please correct the errors below",
										4000);
								$scope.crudLoading = false;
								throw new ("This is not an error. This is just to abort javascript!");
							}
						}
						if (vm.transaction_type.CODE_NAME == 'ZBA') {
							if (vm.reportabletaxamountError
									|| vm.reasonforzeroError
									|| vm.rptdescError

									|| vm.typeofrptError) {
								AlertService.add("danger",
										"Please correct the errors below",
										4000);
								$scope.crudLoading = false;
								throw new ("This is not an error. This is just to abort javascript!");
							}
						}
					}

					for ( var j in vm.mainmodaldata) {
						if (vm.olduserm1 == vm.mainmodaldata[j].SCHDM_TEMPLATE) {
							oldtemplate_key = vm.mainmodaldata[j].SCHDM_TEMPLATE_ID;
						}
					}

					for ( var k in vm.mainmodaldata) {
						if (vm.template == vm.mainmodaldata[k].SCHDM_TEMPLATE) {
							newtemplate_key = vm.mainmodaldata[k].SCHDM_TEMPLATE_ID;
						}
					}

					for ( var l in vm.schdmtypedata) {
						if (vm.oldschdm == vm.schdmtypedata[l].SCHDM_TYPE) {
							old_schdm_key = vm.schdmtypedata[l].SCHDM_TYPE_SAVE;
						}

					}
					for ( var m in vm.schdmtypedata) {
						if (vm.schdmt == vm.schdmtypedata[m].SCHDM_TYPE) {
							new_schdm_key = vm.schdmtypedata[m].SCHDM_TYPE_SAVE;
						}

					}
					/*
					 * for ( var b in vm.tbbsacctdata) { if (vm.oldtbbs ==
					 * vm.tbbsacctdata[b].TAX_STREAM_ACCT) {
					 * old_tbbs_acct_key =
					 * vm.tbbsacctdata[b].TAX_STREAM_ACCT_SAVE } }
					 */
					for ( var n in vm.tbbsacctdata) {
						if (vm.tbbsacctval == vm.tbbsacctdata[n].TAX_STREAM_ACCT) {
							new_tbbs_acct_key = vm.tbbsacctdata[n].TAX_STREAM_ACCT_SAVE
						}
					}

					for ( var o in vm.temp_project_step_List) {
						if (vm.projectStep == vm.temp_project_step_List[o].LABEL) {
							project_step_id = vm.temp_project_step_List[o].PROJECT_STEP_ID;
						}
					}
					for ( var p in vm.temp_projectList) {
						if (vm.temp_projectList[p].PROJECT_NAME == vm.project) {
							project_id = vm.temp_projectList[p].PROJECT_ID;
						}
					}
					if (vm.testflag == true) {
						var posting_acct_key = "";
						for ( var cgl in vm.glacctsMdata) {
							if (vm.glacctsMdata[cgl].GL_ACCT == vm.postingTaxTop) {
								posting_acct_key = vm.glacctsMdata[cgl].GL_ACCT_KEY;
							}
						}

					}

					headerObj.TYPE = (vm.type === true) ? "E" : "I";
					headerObj.REF_KEY = (vm.type === true) ? vm.ref_key : 0;
					headerObj.TAX_YEAR = GlobalService.globalParams.tax_year;
					headerObj.SCENARIO = GlobalService.globalParams.scenario;
					headerObj.JCD_KEY = GlobalService.globalParams.jcd_key;
					headerObj.OLD_Template = vm.template;
					headerObj.NEW_Template = vm.template;
					// headerObj.NEW_ADJ_ORIGIN_SOURCE =
					// vm.rowData.SOURCE_SYSTEM;
					// headerObj.ADJ_SOURCE_TYPE =
					// (headerObj.ADJ_ORIGIN_SOURCE === 'DCS') ? "" :
					// vm.ADJ_SOURCE_TYPE;
					// headerObj.SUPPL_INFO = vm.SUPPL_INFO;
					// headerObj.TYPE = (vm.TRANS_HEADER_KEY === 0) ?
					// "I" : "U";
					headerObj.OLD_TEMPLATE_ID = (vm.type === true) ? vm.templatekey
							: newtemplate_key;
					headerObj.NEW_TEMPLATE_ID = newtemplate_key;
					headerObj.NEW_USER_M1_DESCRIPTION = vm.userm1;
					headerObj.OLD_USER_M1_DESCRIPTION = (vm.type === true) ? vm.olduserm1
							: vm.userm1;
					headerObj.OLD_schdm_Type = (vm.type === true) ? vm.oldschdm
							: new_schdm_key;
					headerObj.NEW_SCHDM_TYPE = new_schdm_key;
					headerObj.OLD_TAX_BALANCESHEET_ACCT = (vm.type === true) ? vm.oldtbbs
							: new_tbbs_acct_key;
					headerObj.NEW_TAX_BALANCESHEET_ACCT = new_tbbs_acct_key;

					if (vm.partnershipflag === true) {
						headerObj.NEW_TEMPLATE_ID = vm.templatekey;
						headerObj.NEW_SCHDM_TYPE = vm.oldschdm;
						headerObj.NEW_TAX_BALANCESHEET_ACCT = vm.oldtbbs;
					}
					headerObj.OLD_PROVISON_M1 = vm.provisionM1;
					headerObj.NEW_PROVISON_M1 = vm.provisionM1;
					headerObj.NEW_PROJECT_NAME = project_id;
					headerObj.OLD_PROJECT_NAME = (vm.type === true) ? vm.old_project_id
							: project_id;
					headerObj.NEW_PROJECT_STEP_CODE = project_step_id;
					headerObj.OLD_PROJECT_STEP_CODE = (vm.type === true) ? vm.old_project_step_id
							: project_step_id;
					headerObj.NEW_COMMENTS = vm.COMMENTS;
					headerObj.OLD_COMMENTS = (vm.type === true) ? vm.oldcomments
							: vm.COMMENTS;
					// headerObj.NEW_SECTION_174 = vm.sec174amt;
					// headerObj.OLD_SECTION_174 = (vm.type === true) ?
					// vm.old_sec174amt
					// : vm.sec174amt;
					headerObj.NEW_PY_SCHD_M1_AMOUNT = vm.parentObject.PRIOR_YEAR_AMT;
					headerObj.OLD_PY_SCHD_M1_AMOUNT = vm.parentObject.PRIOR_YEAR_AMT;
					headerObj.NEW_CY_SCHD_M1_AMOUNT = vm.CURRENT_YR_AMT;
					headerObj.OLD_CY_SCHD_M1_AMOUNT = (vm.type === true) ? vm.oldCURRENT_YR_AMT
							: vm.CURRENT_YR_AMT;
					headerObj.SOURCE_SYSTEM_FLAG = 'D';
					// headerObj.TRANS_HEADER_KEY =
					// vm.TRANS_HEADER_KEY_OLD;
					headerObj.CHART_KEY = vm.rowData.LOCAL_CHART_KEY;
					// headerObj.LOCAL_ACCT_KEY = vm.rowData.LOCAL_ACCT_KEY;
					headerObj.LOCAL_ACCT_KEY = (vm.testflag === true) ? posting_acct_key
							: vm.rowData.LOCAL_ACCT_KEY;
					headerObj.COMBINATION_KEY = vm.rowData.COMBINATION_KEY;
					headerObj.SCHDM_TRANS_HEADER_KEY = vm.rowData.TRANS_HEADER_KEY;
					headerObj.SCHDM_TRANS_DETAILS_KEY = vm.rowData.TRANS_DETAILS_KEY;
					headerObj.OLD_COL_2_AMT = vm.oldcustom2Amt;
					headerObj.NEW_COL_2_AMT = vm.custom2Amt;
					headerObj.SCHDM_ID = (vm.type === true) ? vm.oldschdmid
							: 0;
					headerObj.TP_KEY = (vm.tpObjectD== undefined || vm.tpObjectD== "") ?0 :vm.tpObjectD.TP_KEY;
					// start new attribs
					if (vm.type === true) {
						headerObj.OLD_SETTLED_FLAG = vm.oldsettledyesno;
						headerObj.OLD_RECURRING_FLAG = vm.old_recurring;
						headerObj.OLD_BA_EXIST = vm.old_book_amt_exist;
						headerObj.OLD_REPO_TRANS = vm.old_reportable;
						headerObj.OLD_REVIEWED = vm.old_sch;

					} else {
						headerObj.OLD_SETTLED_FLAG = (vm.settledyesno == 'No') ? 'N'
								: 'Y';
						headerObj.OLD_RECURRING_FLAG = (vm.recurring == true) ? 'Y'
								: 'N';
						headerObj.OLD_BA_EXIST = (vm.book_amt_exist == 'No') ? 'N'
								: 'Y';
						headerObj.OLD_REPO_TRANS = (vm.rpt_t_flag == 'Yes') ? 'Y'
								: 'N';
						headerObj.OLD_REVIEWED = (vm.sch == 'Yes') ? 'Y'
								: 'N';
					}

					headerObj.NEW_SETTLED_FLAG = (vm.settledyesno == 'No') ? 'N'
							: 'Y';

					headerObj.NEW_RECURRING_FLAG = (vm.recurring == true) ? 'Y'
							: 'N';
					headerObj.NEW_REVIEWED = (vm.sch == 'Yes') ? 'Y' : 'N';
					headerObj.OLD_USER_REVIEWED = (vm.type === true) ? vm.old_userreviewd
							: vm.userreviewd;
					headerObj.NEW_USER_REVIEWED = vm.userreviewd;
					headerObj.OLD_REVIEWED_ON = (vm.type === true) ? vm.old_timestamp
							: vm.timestamp;
					headerObj.NEW_REVIEWED_ON = vm.timestamp;

					headerObj.NEW_BA_EXIST = (vm.book_amt_exist == 'No') ? 'N'
							: 'Y';

					headerObj.NEW_REPO_TRANS = (vm.rpt_t_flag == 'Yes') ? 'Y'
							: 'N';
if(headerObj.NEW_TEMPLATE_ID == undefined || headerObj.NEW_TEMPLATE_ID ==''|| headerObj.NEW_SCHDM_TYPE == ""
  || headerObj.NEW_SCHDM_TYPE == undefined || headerObj.NEW_TAX_BALANCESHEET_ACCT == '' ||headerObj.NEW_TAX_BALANCESHEET_ACCT ==undefined)
  {
  AlertService
	.add(
			"danger",
			"Please wait!",
			4000);
		  $scope.crudLoading = false;
		  throw new ("This is not an error. This is just to abort javascript!");
  }
					headerSettings.push(headerObj);
					if (vm.rpt_t_flag == 'Yes') {
						for ( var c in vm.reportableList) {
							tempobj = {};

							if (vm.transaction_type.CODE_NAME == undefined) {
								for ( var p in vm.transactions) {
									if (vm.transactions[p].CODE_DESC == vm.transaction_type) {
										tempobj.NEW_REPORTABLE_ACTION = vm.transactions[p].CODE_NAME;
									}
								}
							} else {
								tempobj.NEW_REPORTABLE_ACTION = vm.transaction_type.CODE_NAME;

							}

							if (vm.type === true) {

								tempobj.OLD_REPORTABLE_ACTION = vm.old_reportable_action;
								tempobj.OLD_REPORTABLE_TAX_AMOUNT = vm.old_rpt_tax_acct;
								tempobj.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.old_tax_shetlter_reg_num;
								tempobj.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.old_reportable_trans_desc;

								tempobj.OLD_TYPE_REPORTABLE_TRANSACTION = vm.old_type_of_reportable_trans;
								tempobj.OLD_REASON_FOR_ZERO_BOOK_AMT = vm.old_reasonforchange;

								if (vm.reportableList[c].type == "I") {
									tempobj.OLD_GL_ACCT = vm.reportableList[c].glacct.GL_ACCT_KEY;
									tempobj.OLD_THIS_ADJ_AMT = vm.reportableList[c].thisadj_amt;
								} else {
									tempobj.OLD_GL_ACCT = vm.reportableList[c].old_gl_acct_key;
									tempobj.OLD_THIS_ADJ_AMT = vm.reportableList[c].old_thisadj_amt;
								}

							}

							else {

								tempobj.OLD_REPORTABLE_ACTION = vm.transaction_type.CODE_NAME;
								tempobj.OLD_REPORTABLE_TAX_AMOUNT = vm.rpt_tax_acct;
								tempobj.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num;
								tempobj.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc;

								tempobj.OLD_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans.CODE_NAME;
								tempobj.OLD_REASON_FOR_ZERO_BOOK_AMT = vm.reasonforchange;
								tempobj.OLD_GL_ACCT = vm.reportableList[c].glacct.GL_ACCT_KEY;
								tempobj.OLD_THIS_ADJ_AMT = vm.reportableList[c].thisadj_amt;

							}
							tempobj.NEW_REPORTABLE_TAX_AMOUNT = vm.rpt_tax_acct;
							tempobj.NEW_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num;
							tempobj.NEW_REASON_FOR_ZERO_BOOK_AMT = vm.reasonforchange;
							tempobj.NEW_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc;
							if (vm.type_of_reportable_trans.CODE_NAME == undefined) {
								for ( var d in vm.reportable_trans_list) {
									if (vm.reportable_trans_list[d].CODE_DESC == vm.type_of_reportable_trans) {
										tempobj.NEW_TYPE_REPORTABLE_TRANSACTION = vm.reportable_trans_list[d].CODE_NAME;
									}
								}
							} else {
								tempobj.NEW_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans.CODE_NAME;
							}

							// tempobj.NEW_REASON_FOR_ZERO_BOOK_AMT =
							// vm.reasonforchange;
							// tempobj.OLD_REASON_FOR_ZERO_BOOK_AMT =
							// vm.reasonforchange;
							// tempobj.NEW_REASON_FOR_ZERO_BOOK_AMT =
							// vm.reasonforchange;
							/*
							 * tempobj.NEW_GL_ACCT =
							 * (vm.reportableList[c].glacct.GL_ACCT_KEY ==
							 * undefined) ? vm.reportableList[c].GL_ACCT_KEY :
							 * vm.reportableList[c].glacct.GL_ACCT_KEY;
							 */
							if (vm.reportableList[c].glacct.GL_ACCT_KEY == undefined) {

								for ( var y in vm.glaccts) {
									if (vm.reportableList[c].glacct == vm.glaccts[y].GL_ACCT) {
										tempobj.NEW_GL_ACCT = vm.glaccts[y].GL_ACCT_KEY
									}
								}
							} else {
								tempobj.NEW_GL_ACCT = vm.reportableList[c].glacct.GL_ACCT_KEY;
							}
							tempobj.NEW_THIS_ADJ_AMT = vm.reportableList[c].thisadj_amt;
							tempSettings.push(tempobj);
						}

						if (vm.type === true) {

							tempobjStatic.OLD_REPORTABLE_ACTION = vm.old_reportable_action;
							tempobjStatic.OLD_REPORTABLE_TAX_AMOUNT = vm.old_rpt_tax_acct;
							tempobjStatic.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.old_tax_shetlter_reg_num;
							tempobjStatic.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.old_reportable_trans_desc;

							tempobjStatic.OLD_TYPE_REPORTABLE_TRANSACTION = vm.old_type_of_reportable_trans;
							tempobjStatic.OLD_REASON_FOR_ZERO_BOOK_AMT = vm.old_reasonforchange;

						}

						else {

							tempobjStatic.OLD_REPORTABLE_ACTION = vm.transaction_type.CODE_NAME;
							tempobjStatic.OLD_REPORTABLE_TAX_AMOUNT = vm.rpt_tax_acct;
							tempobjStatic.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num;
							tempobjStatic.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc;

							tempobjStatic.OLD_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans.CODE_NAME;
							tempobjStatic.OLD_REASON_FOR_ZERO_BOOK_AMT = vm.reasonforchange;

						}

						if (vm.transaction_type.CODE_NAME == undefined) {
							for ( var h in vm.transactions) {
								if (vm.transactions[h].CODE_DESC == vm.transaction_type) {
									tempobjStatic.NEW_REPORTABLE_ACTION = vm.transactions[h].CODE_NAME;
								}
							}
						} else {
							tempobjStatic.NEW_REPORTABLE_ACTION = vm.transaction_type.CODE_NAME;

						}

						tempobjStatic.NEW_REPORTABLE_TAX_AMOUNT = vm.rpt_tax_acct;
						tempobjStatic.NEW_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num;
						tempobjStatic.NEW_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc;

						if (vm.type_of_reportable_trans.CODE_NAME == undefined) {
							for ( var d in vm.reportable_trans_list) {
								if (vm.reportable_trans_list[d].CODE_DESC == vm.type_of_reportable_trans) {
									tempobjStatic.NEW_TYPE_REPORTABLE_TRANSACTION = vm.reportable_trans_list[d].CODE_NAME;
								}
							}
						} else {
							tempobjStatic.NEW_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans.CODE_NAME;
						}

						tempobjStatic.OLD_GL_ACCT = vm.glacctsrpt[0].RT_ACCT_KEY;
						tempobjStatic.NEW_GL_ACCT = vm.glacctsrpt[0].RT_ACCT_KEY;
						tempobjStatic.OLD_THIS_ADJ_AMT = vm.thisadj_amt1;
						tempobjStatic.NEW_THIS_ADJ_AMT = vm.thisadj_amt1;
						tempSettings.push(tempobjStatic);

					}
					// end new attribs

					if (transactions.length == 0) {
						transactions.push(headerObj);
					}
					var message = (headerObj.type === "U") ? "Tax Adjustments have been successfully updated"
							: "Tax Adjustments have been successfully created";

					EPLocalTaxAdjustmentsFactory
							.saveUpdateAdjustmentsDCS(tempSettings,
									transactions)
							.then(
									function(result) {

										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage,
													4000);
											$scope.crudLoading = false;
										} else {
											AlertService.add("success",
													message, 4000);

											if (vm.testflag === true) {
												vm.cancel();
											}
											// BROADCAST CHANGE
											// $uibModalInstance.close();
											// var args = {combination_keys:
											// headerSettings.COMBINATION_KEY,
											// gridFilter: {COMBINATION_KEY:
											// headerSettings.COMBINATION_KEY}};
											// $rootScope.$emit('gridUpdate',
											// args);
											$scope.crudLoading = false;
											vm.clickNo();
											// init();
											var args = {
												combination_keys : vm.rowData.COMBINATION_KEY,
												gridFilter : {
													COMBINATION_KEY : vm.rowData.COMBINATION_KEY
												}
											};

											// var args = {};
											$rootScope.$emit('gridUpdate',
													args);
											$uibModalInstance.close();
										}
									});

					// headerObj.SCHDM_RECEIVED = vm.SCHDM_RECEIVED;

					// headerObj.RECUR_FLAG = vm.dcsHeaderObj.RECUR_FLAG;

					// headerObj.POSTING_TAX_ACCT_CODE =
					// vm.getDCSPLAcctDetails.selected3.GL_ACCT_KEY;
					// headerObj.POSTING_GL_ACCT =
					// vm.getDCSPLAcctDetails.selected4.GL_ACCT_KEY;

					// headerObj.ADJ_AMT = vm.parentObject.ADJ_AMT;
					// headerObj.M3_FORM_LINE = vm.parentObject.M3_FORM_LINE
					// ;
					// headerObj.STANDARD_M1_DESC = vm.STANDARD_M1_DESC;
					// headerObj.COMMENTS = vm.COMMENTS;
				} else {
					console.log('adj type', vm.FIR_TRANS_TYPE);
					if (vm.isOffSetAcctExists) {
						if (vm.addOffsetRows.length > 1) {
							validateData();
						}
					}

					if ($scope.adjsForm.$invalid) {
						$scope.adjsForm.$submitted = true;
						$scope.crudLoading = false;
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						return;
					}
					
					
					headerObj.tax_year = EPLocalTaxAdjustmentsFactory.getScreenFilters().tax_year;
					headerObj.ADJ_DESC = vm.ADJ_DESC;
					headerObj.ADJ_ORIGIN_SOURCE = vm.rowData.SOURCE_SYSTEM;
					headerObj.ADJ_SOURCE_TYPE = (headerObj.ADJ_ORIGIN_SOURCE === 'DCS') ? ""
							: vm.ADJ_SOURCE_TYPE;
					headerObj.SUPPL_INFO = vm.SUPPL_INFO;
					headerObj.TYPE = (vm.TRANS_HEADER_KEY === 0) ? "S"
							: "U";
					headerObj.TRANS_HEADER_KEY = vm.TRANS_HEADER_KEY;

					headerObj.SOURCE_SYSTEM_FLAG = (headerObj.ADJ_ORIGIN_SOURCE === 'DCS') ? 'D'
							: 'F';

					headerObj.CHART_KEY = vm.rowData.LOCAL_CHART_KEY;
					headerObj.COMBINATION_KEY = vm.rowData.COMBINATION_KEY;
					headerObj.FIR_TRANS_TYPE = vm.FIR_TRANS_TYPE;
					var txIssueId = GlobalService.globalParams.issue_id_for_tx_save;
					headerObj.ISSUE_KEY = txIssueId === undefined || txIssueId === '' ? 
						(GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)) 
						: txIssueId;
                    // headerObj.ISSUE_KEY = GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id);
					/*
					 * if(headerObj.ADJ_ORIGIN_SOURCE === 'DCS') {
					 * _.merge(headerObj, vm.dcsHeaderObj); }
					 */

					// for main parent adjustment
					var adjModel = new adjustment_model(
							vm.parentObject["LOCAL_ACCT_KEY"],
							vm.parentObject["CHART_KEY"],
							vm.parentObject["ADJ_AMT"],
							vm.parentObject["MULTIPLIER"],
							vm.parentObject["PROJECT_ID"],
							vm.parentObject["PROJECT_STEP_ID"],
							vm.parentObject["AUTO_REVERSE_YN"],
							vm.parentObject["SCHD_H_LINE_NO"],
							vm.parentObject["TP_KEY"],
							vm.parentObject["TRANS_DETAILS_KEY"],
							vm.parentObject["POSTING_GL_ACCT_KEY"]);
					transactions.push(adjModel);

					// for offset accounts
					if (vm.isOffSetAcctExists) {
						if (vm.addOffsetRows.length > 1) {
							validateData();
						}

						_.forEach(vm.addOffsetRows, function(value, key) {
							var adjModel = new adjustment_model(
									value.LOCAL_ACCT_KEY, value.CHART_KEY,
									value.ADJ_AMT, value.MULTIPLIER,
									value.PROJECT_ID,
									value.PROJECT_STEP_ID,
									value.AUTO_REVERSE_YN,
									value.SCHD_H_LINE_NO, value.TP_KEY,
									value.TRANS_DETAILS_KEY, "");

							transactions.push(adjModel);
						});
					}

					// in edit mode, if there are existing offset
					// adjustments that needs to be updated
					if (vm.isEdit && vm.existingOffsetAdjustments.length) {
						_.forEach(vm.existingOffsetAdjustments, function(
								value, key) {
							var adjModel = new adjustment_model(
									value.LOCAL_ACCT_KEY,
									value.LOCAL_CHART_KEY, value.ADJ_AMT,
									value.MULTIPLIER, value.PROJECT_ID,
									value.PROJECT_STEP_ID,
									value.AUTO_REVERSE_YN,
									value.SCHD_H_LINE_NO, value.TP_KEY,
									value.TRANS_DETAILS_KEY, "");
							transactions.push(adjModel);
						});

					}
					headerSettings.push(headerObj);
					if (transactions.length == 0) {
						transactions.push(headerObj);
					}

					// const returnedTarget = Object.assign(headerObj,
					// transactions);
					var message = (headerObj.type === "U") ? "E&P Adjustments have been successfully updated"
							: "E&P Adjustments have been successfully created";
					EPLocalTaxAdjustmentsFactory
							.saveUpdateAdjustments(headerSettings,
									transactions)
							.then(
									function(result) {
										GlobalService.globalParams.issue_id_for_tx_save = '';
										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage,
													4000);
											$scope.crudLoading = false;
										} else {
											AlertService.add("success",
													message, 4000);
											$scope.crudLoading = false;

											// BROADCAST CHANGE
											// $uibModalInstance.close();
											// var args = {combination_keys:
											// headerSettings.COMBINATION_KEY,
											// gridFilter: {COMBINATION_KEY:
											// headerSettings.COMBINATION_KEY}};
											// $rootScope.$emit('gridUpdate',
											// args);
											// init();
											var args = {
												combination_keys : vm.rowData.COMBINATION_KEY,
												gridFilter : {
													COMBINATION_KEY : vm.rowData.COMBINATION_KEY
												}
											};

											// var args = {};
											$rootScope.$emit('gridUpdate',
													args);
											$uibModalInstance.close();
										}
									});

				}

			};

			vm.deleteAdjustment = function(adjs, deleteType) {
				var returnObj = {};
				var message = (deleteType === "PARENT") ? "Adjustment has been successfully deleted"
						: "Offset Adjustment has been successfully deleted";
				returnObj.trans_header_key = adjs.TRANS_HEADER_KEY;
				returnObj.delete_type = deleteType;
				if (deleteType === "OFFSET") {
					returnObj.trans_details_key = adjs.TRANS_DETAILS_KEY;
				}
				EPLocalTaxAdjustmentsFactory
						.deleteAdjustment(returnObj, deleteType)
						.then(
								function(result) {

									if (result.errorMessage
											&& result.errorMessage !== 'null') {
										AlertService.add("error",
												result.errorMessage, 4000);
									} else {
										AlertService.add("success",
												message, 4000);
										// BROADCAST CHANGE
										// $uibModalInstance.close();
										/*
										 * var args = {combination_keys:
										 * returnObj.combination_key,
										 * gridFilter: {COMBINATION_KEY:
										 * returnObj.combination_key}};
										 * $rootScope.$emit('gridUpdate',
										 * args);
										 */
										// init();
										var args = {
											combination_keys : vm.rowData.COMBINATION_KEY,
											gridFilter : {
												COMBINATION_KEY : vm.rowData.COMBINATION_KEY
											}
										};

										// var args = {};
										$rootScope
												.$emit('gridUpdate', args);
										$uibModalInstance.close();
									}
								});
			};

			/*
			 * vm.cancel = function() { var args = {combination_keys:
			 * vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY:
			 * vm.rowData.COMBINATION_KEY}};
			 *
			 * //var args = {}; $rootScope.$emit('gridUpdate', args);
			 * $uibModalInstance.dismiss('cancel'); };
			 */

			vm.reset = function() {
				init();
			};
			vm.applyTaxacct = function() {
				vm.rpt_tax_acct = vm.CURRENT_YR_AMT;
				vm.reportabletaxamountchange();

			}
			vm.clickYes = function() {
				vm.rpt_t_flag = 'Yes';
			}
			vm.clickNo = function() {
				vm.rpt_t_flag = 'No';

			}

			vm.adjusted_amt_change = function(index) {

				if (index != undefined) {
					vm.reportableList[index].adj_cy_amt = vm.reportableList[index].cy_amt
							+ vm.reportableList[index].earlieradj_amt
							+ vm.reportableList[index].thisadj_amt;
				}

				vm.thisadjamterror = false;
				vm.total_this_adj_amt = 0;
				vm.total_this_adj_amt_static = 0;
				for ( var a in vm.reportableList) {
					if (vm.reportableList[a].thisadj_amt == ''
							|| vm.reportableList[a].thisadj_amt == undefined) {
						vm.reportableList[a].thisadj_amt = 0;
					}
					vm.total_this_adj_amt += vm.reportableList[a].thisadj_amt;
				}
				vm.thisadj_amt1 = vm.total_this_adj_amt * -1;
				vm.total_this_adj_amt_static = vm.thisadj_amt1
						+ vm.total_this_adj_amt;
				vm.adj_cy_amt1 = vm.thisadj_amt1;

				for ( var b in vm.reportableList) {
					if (vm.reportableList[b].thisadj_amt == 0)

					{
						vm.reportableList[b].thisadj_amt_error = true;
						vm.thisadjamterror = true;
					} else {
						vm.reportableList[b].thisadj_amt_error = false;
					}
				}
			}

			vm.openEditModal = function(details) {
				var params = {
					templateName : 'app/components/messages/templates/add-comment-modal-taxadjustments-tpl.html',
					contrlString : 'AddCommentController as ctrl',
				// commentKey: details.comment_key
				}
				var data = {}
				data.rowData = [];
				data.gridData = {};
				data.colData = [];

				vm.openModal(params, data);
			}
			vm.openModal = function(modalObj, _data) {
				$scope.rowData = _data.rowData;
				$scope.gridData = _data;
				var modalInstance = $uibModal.open({
					animation : true,
					templateUrl : modalObj.templateName,
					controller : modalObj.contrlString,
					size : 'md',
					resolve : {
						rowData : function() {
							return $scope.rowData;
						},
						gridData : function() {
							return $scope.gridData;
						}
					}
				});
				modalInstance.result.then(function(selectedItem) {
					$scope.selected = selectedItem;
				}, function() {
					/*
					 * $log.info('Modal dismissed at: ' + new Date());
					 * $log.info('$scope.rowData', $scope.rowData);
					 */
				});
			};

		/* 	vm.attachments = function() {

				vm.openEditModal1($scope.attch_docu);

			} */

			vm.openEditModal1 = function(details) {
				var params = {
					// templateName :
					// 'app/components/messages/templates/add-comment-modal-taxadjustments-attachments-tpl.html',
					templateName : 'app/components/epAdjsLocalChart/add-attachments/add-comment-modal-taxadjustments-attachments-tpl.html',
					contrlString : 'addCommentModalTaxAdjustmentsAttachmentsController as ctrl',
				// commentKey: details.comment_key
				}
				var data = {}
				data.rowData = [];
				data.gridData = {};
				data.colData = [];

				data.formData = {};
				data.formData.leid = vm.rowData.LEID || '';// ENTITY_NAME
				data.formData.cdr = vm.rowData.CDR_NO || '';
				data.formData.business = vm.rowData.ME || '';
				data.formData.tax_year = vm.rowData.TAX_YEAR || '';
				data.formData.standard_desc = vm.template || '';
				data.formData.customer_desc = vm.userm1 || '';
				data.formData.amount = vm.CURRENT_YR_AMT || '';
				data.formData.coa = vm.rowData.LOCAL_CHART_CODE || '';
				data.formData.authority = '';
				data.formData.support_type = "Supporting Documentation";
				data.formData.attachment_source = '';
				data.formData.purpose = '';
				data.formData.isNull_purpose = false;
				data.formData.book_treatment = '';
				data.formData.isNull_book_treatment = false;
				data.formData.tax_treatment = '';
				data.formData.isNull_tax_treatment = false;
				data.formData.description = '';
				data.formData.isNull_description = false;

				vm.openModal1(params, data);
			}

			vm.openModal1 = function(modalObj, _data) {
				$scope.rowData = _data.rowData;
				$scope.gridData = _data;
				var modalInstance = $uibModal.open({
					animation : true,
					templateUrl : modalObj.templateName,
					controller : modalObj.contrlString,
					size : 'md',
					resolve : {
						rowData : function() {
							return $scope.rowData;
						},
						gridData : function() {
							return $scope.gridData;
						}
					}
				});
				modalInstance.result.then(function(selectedItem) {
					$scope.selected = selectedItem;
				}, function() {
					// $log.info('Modal dismissed at: ' + new Date());
					console.log('Modal dismissed at: ' + new Date())
					// $log.info('$scope.rowData', $scope.rowData);
					console.log('$scope.rowData', $scope.rowData)
				});
			};

			vm.addReportable = function() {
				vm.reportableList.push({
					glacct : '',
					cy_amt : 0,
					earlieradj_amt : 0,
					cy_amt_loading : false,
					earlieradj_amt_loading : false,
					thisadj_amt : 0,
					adj_cy_amt : 0,
					glacct_error : '',
					thisadj_amt_error : '',

				});
			};

			vm.removeportable = function(index) {
				vm.reportableList.splice(index, 1);
				vm.adjusted_amt_change();
			};

			vm.toggleLookup = function() {
				vm.lookUpMapping = !vm.lookUpMapping;
			};

			vm.toggleLookup1 = function() {
				vm.lookUpMapping1 = !vm.lookUpMapping1;
			};

			function adjustment_model(acct_key, chart_key, adj_amt,
					multiplier, project_id, project_step_id,
					auto_reverse_yn, schd_h_line_no, tp_key,
					trans_details_key, posting_gl_acct_key) {
				this.ACCT_KEY = acct_key;
				this.CHART_KEY = chart_key;
				this.ADJ_AMT = adj_amt;
				this.MULTIPLIER = multiplier;
				this.PROJECT_ID = project_id;
				this.PROJECT_STEP_ID = project_step_id;
				this.AUTO_REVERSE_YN = auto_reverse_yn;
				this.SCHD_H_LINE_NO = schd_h_line_no;
				this.TP_KEY = tp_key;
				this.TRANS_DETAILS_KEY = trans_details_key;
				this.POSTING_GL_ACCT_KEY = posting_gl_acct_key;

			}
			// byc4kc

			function loadtriggerdata() {
				vm.triggerloading = false;
				var params = {
					"action_code" : 'byc4kc',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
					"combi_key" : vm.rowData.COMBINATION_KEY,
					"posting_acct_key" : vm.rowData.LOCAL_ACCT_KEY
				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.triggerloading = true;
									} else {
										vm.triggerloading = true;
										vm.triggerdata = data.jsonObject;
										if (vm.triggerdata.length > 0)
										/*
										 * BOOK_AMT: 301964 M3_ACCT:
										 * "1120_SCH_M3P3_LN11_COLA - Meals
										 * and entertainment (Expense
										 * perIncome Statement)"
										 * M3_ACCT_KEY: 24410873
										 * POSTING_ACCT: "5060201533 - All
										 * Other Variable Costs-T&L-NonDed"
										 * POSTING_ACCT_KEY: 3641732
										 * POSTING_TAC: "5795650 -
										 * NON-DEDUCTIBLE MEALS AND
										 * ENTERTAINMENT EXPENSES"
										 * POSTING_TAC_KEY: 3920299
										 */
										{
											vm.postingGL = vm.triggerdata[0].POSTING_ACCT;
											vm.postingTax = vm.triggerdata[0].POSTING_TAC;
											vm.m3formline = vm.triggerdata[0].M3_ACCT;
											vm.bookamt = vm.triggerdata[0].BOOK_AMT;
											vm.bookadj = vm.triggerdata[0].BOOK_ADJ;
											vm.taxadj = vm.triggerdata[0].TAX_ADJ;
										}
									}

								});
			}
			;

			function getSystemAcctLookUP() {
				var params = {
					"action_code" : '5gdshx',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
				}
				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {
									console.log("Response Data x ::", data);
									if (data.callSuccess === "1") {

										vm.loadSystemAcctLookupTemp = data.jsonObject;
										vm.loadLookupForm = [];
										vm.loadLookupSchedule = [];
										vm.loadLookupLine = [];
										vm.loadLookupColumn = [];
										vm.loadLookupSysMapAcct = [];
										vm.loadLookupSysMapAcct1 = [];
										vm.lookupcheckdata = data.jsonObject;

										for ( var index_ in data.jsonObject) {
											vm.loadLookupForm
													.push(data.jsonObject[index_].FORM);
										}
										vm.loadLookupForm = _.uniq(
												vm.loadLookupForm,
												JSON.stringify)
									} else {
										if (data.errorMessage === "no access") {
											AlertService
													.add(
															"",
															"Sorry you do not have access to do the requested action.",
															4000);
											vm.userMessage = "!! Unable to perform Selected Action...";
										} else {
											AlertService
													.add(
															"",
															"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
															4000);
											vm.userMessage = "!! Unable to perform Selected Action...";
										}
									}

								});

			}

			// /irs form start
			vm.getLookupNext = function(key, nextLookupKey,
					currentLookupValue) {
				var lookupData = angular.copy(vm.lookupcheckdata);
				var a = "SYS_ACCT";

				switch (key) {
				case 1:
					vm.loadLookupSchedule = filterDataByKey("FORM",
							nextLookupKey, lookupData, currentLookupValue);

					break;
				case 2:
					vm.loadLookupLine = filterDataByKey1("SCHEDULE",
							nextLookupKey, lookupData1, currentLookupValue);
					break;
				case 3:
					vm.loadLookupColumn = filterDataByKey2("LINE",
							nextLookupKey, lookupData2, currentLookupValue);
					vm.loadLookupSysMapAcct = filterDataByKey22("LINE", a,
							lookupData2, currentLookupValue);
					break;

				case 4:
					vm.loadLookupSysMapAcct = filterDataByKey3("COL",
							nextLookupKey, lookupData3, currentLookupValue);
					break;
				case 5:
					vm.loadLookupSysMapAcct = filterDataByKey4("LINE",
							nextLookupKey, lookupData2, currentLookupValue);
					break;
				}

			}

			function filterDataByKey(currentLookupKey, nextLookupKey,
					lookupData, currentLookupValue) {
				var nextLookupData = [];

				var currentLookupFilter = lookupData.filter(function(
						object, index) {
					console.log(object, index)
					if (object[currentLookupKey] == currentLookupValue) {
						return true;
					}
				});

				currentLookupFilter.forEach(function(object, index) {
					nextLookupData.push(object[nextLookupKey]);
				});
				nextLookupData = _.uniq(nextLookupData, JSON.stringify);
				lookupData1 = currentLookupFilter;
				console.log(lookupData1, "check Here IMPORTANT inside");
				return nextLookupData;
			}

			console.log(lookupData1,
					"check Here IMPORTANT Outside 1  st time");

			function filterDataByKey1(currentLookupKey, nextLookupKey,
					lookupData1, currentLookupValue) {
				var nextLookupData = [];
				var currentLookupFilter = lookupData1.filter(function(
						object, index) {
					console.log(object, index)
					if (object[currentLookupKey] == currentLookupValue) {
						return true;
					}
				});

				currentLookupFilter.forEach(function(object, index) {
					nextLookupData.push(object[nextLookupKey]);
				});
				nextLookupData = _.uniq(nextLookupData, JSON.stringify);
				lookupData2 = currentLookupFilter;

				return nextLookupData;
			}

			function filterDataByKey2(currentLookupKey, nextLookupKey,
					lookupData2, currentLookupValue) {
				var nextLookupData = [];
				var nextLookupData1 = [];
				var currentLookupFilter = lookupData2.filter(function(
						object, index) {
					console.log(object, index)
					if (object[currentLookupKey] == currentLookupValue) {
						return true;
					}
				});

				currentLookupFilter.forEach(function(object, index) {
					nextLookupData.push(object[nextLookupKey]);

				});
				nextLookupData = _.uniq(nextLookupData, JSON.stringify);
				lookupData3 = currentLookupFilter;
				return nextLookupData;
			}
			function filterDataByKey22(currentLookupKey, a, lookupData2,
					currentLookupValue) {
				var nextLookupData = [];

				var currentLookupFilter = lookupData2.filter(function(
						object, index) {
					console.log(object, index)
					if (object[currentLookupKey] == currentLookupValue) {
						return true;
					}
				});

				currentLookupFilter.forEach(function(object, index) {
					nextLookupData.push(object[a]);

				});
				nextLookupData = _.uniq(nextLookupData, JSON.stringify);
				return nextLookupData;
			}

			function filterDataByKey3(currentLookupKey, nextLookupKey,
					lookupData3, currentLookupValue) {
				var nextLookupData = [];
				var currentLookupFilter = lookupData3.filter(function(
						object, index) {
					console.log(object, index)
					if (object[currentLookupKey] == currentLookupValue) {
						return true;
					}
				});

				currentLookupFilter.forEach(function(object, index) {
					nextLookupData.push(object[nextLookupKey]);
				});
				nextLookupData = _.uniq(nextLookupData, JSON.stringify);
				lookupData4 = currentLookupFilter;
				return nextLookupData;
			}

			function filterDataByKey4(currentLookupKey, nextLookupKey,
					lookupData4, currentLookupValue) {
				var nextLookupData = [];
				var currentLookupFilter = lookupData4.filter(function(
						object, index) {
					console.log(object, index)
					if (object[currentLookupKey] == currentLookupValue) {
						return true;
					}
				});

				currentLookupFilter.forEach(function(object, index) {
					nextLookupData.push(object[nextLookupKey]);
				});
				nextLookupData = _.uniq(nextLookupData, JSON.stringify);
				return nextLookupData;
			}

			/*
			 * vm.applyChanges = function() { vm.CHECK_SYS_ACCT =
			 * vm.CHECK_SYS_MAP_ACCT; vm.getChartAcctTableData(); }
			 */
			vm.formchanged = function(_item) {
				vm.CHECK_SCHEDULE = null;
				vm.CHECK_LINE = null;
				vm.CHECK_COL = null;
				vm.CHECK_SYS_MAP_ACCT = null;

			};
			vm.schedulechanged = function(_item) {
				vm.CHECK_LINE = null;
				vm.CHECK_COL = null;
				vm.CHECK_SYS_MAP_ACCT = null;
			};
			vm.linechanged = function(_item) {
				vm.CHECK_COL = null;
				vm.CHECK_SYS_MAP_ACCT = null;
			};
			vm.columnchanged = function(_item) {
				vm.CHECK_SYS_MAP_ACCT = null;
			};
			// IRS Form end

			vm.resetLookup = function() {
				vm.CHECK_FORM = '';
				vm.CHECK_SCHEDULE = '';
				vm.CHECK_LINE = '';
				vm.CHECK_COL = '';
				vm.CHECK_SYS_MAP_ACCT = '';
			};

			function init() {

				var lookupData = [];
				var lookupData1 = [];
				var lookupData2 = [];
				var lookupData3 = [];
				var lookupData4 = [];
				vm.loadLookupForm = [];
				vm.reportableList = [];
				vm.temp_projectList = [];
				vm.temp_project_step_List = [];
				vm.projectList = [];
				vm.projectStepList = [];
				vm.isMaxLimitReached = false;
				vm.type = false;
				$scope.crudLoading = false;
				vm.book_amt_exist = 'No';
				vm.recurring = 'No';
				vm.posting_change = 'No';
				vm.reportable = "No";
				$scope.value = '';
				$scope.provision = "No";
				vm.rpt_t_flag = 'No';
				vm.partnershipflag = false;
				vm.lookUpMapping = false;
				vm.testflag = false;
				var tp_key_save = 0;
				if (vm.modal_name == 'epTaxAdjsLocalCharttest') {
					vm.testflag = true;
				}
				if (vm.testflag === false) {
					loadtriggerdata();

					vm.postingGL = vm.rowData.LOCAL_ACCT + '  -  '
							+ vm.rowData.LOCAL_ACCT_DESC;
					vm.postingTax = vm.rowData.SYSTEM_ACCT + '  -  '
							+ vm.rowData.SYSTEM_ACCT_DESC;
					vm.bookamt = Number(vm.rowData.BOOK_AMT)
							.toLocaleString('en');
					vm.bookadj = Number(vm.rowData.BOOK_ADJ)
							.toLocaleString('en');
				}

				vm.checkdcsflag = true;
				vm.lookUpMapping1 = false;
				vm.reportableList.push({
					glacct : '',
					cy_amt : 0,
					earlieradj_amt : 0,
					thisadj_amt : 0,
					cy_amt_loading : false,
					earlieradj_amt_loading : false,
					adj_cy_amt : 0,
					glacct_error : '',
					thisadj_amt_error : '',
					type : 'I'

				});
				getSystemAcctLookUP();
				vm.rowData = rowData || $scope.$parent.rowData;
				vm.ADJ_DESC = "";
				vm.SUPPL_INFO = "";
				vm.offsetAcct = '';
				vm.INVALID_OBJECT = "N";
				vm.total_this_adj_amt = 0;
				// vm.rowData = rowData;

				vm.title = "Create/Edit E&P Adjustments";
				vm.combination_key = vm.rowData.COMBINATION_KEY;
				vm.le_tax_type = vm.rowData.LE_TAX_TYPE;
				vm.mainScreenKey = EPLocalTaxAdjustmentsFactory
						.getActiveScreen();
				vm.ADJ_SOURCE_TYPE = 'MA';
				vm.isadjTypeDisabled = false;
				vm.showAdjsForm = false;
				vm.SOURCE_SYSTEM = vm.rowData.SOURCE_SYSTEM;
				vm.existingAdjustments = [];
				vm.existingOffsetAdjustments = [];
				vm.addOffsetRows = [];
				vm.taxAdjustments = [];
				vm.sourceAcctType = rowData.SYSTEM_ACCT_TYPE;
				vm.noRecords = false;
				vm.TRANS_HEADER_KEY = 0;
				vm.crudLoading = false;
				vm.isOffSetAcctExists = false;
				vm.isEdit = false;
				vm.validateDCSData = false;
				vm.settledyesno = 'No';
				vm.reportable_transaction = 'No'
				// vm.book_amt_exist = 'No';
				vm.test1 = '23';
				vm.schdmreviewed = [ 'Yes', 'No' ];
				vm.reportable_trans_list = [];
				vm.glaccts = [];
				vm.glacctsrpt = [];
				vm.transactions = [];
				vm.transactions_temp = [];
				vm.allProjectsWithSteps = [];
				// vm.transaction_type = '-----Identify the Book Amount for
				// this transaction.-----';
				vm.projectsList = {
					selected : null,
					list : [],
					disabled : true
				};
				vm.projectSteps = {
					selected : null,
					list : [],
					disabled : true
				};
				vm.getLineDetails = {
					selected : null,
					list : [],
					disabled : true
				};
				vm.getOffsetLineDetails = {
					selected : null,
					list : [],
					disabled : true
				};
				vm.getDcsAdjsDetails = {
					selected : null,
					list : [],
					disabled : true
				};
				vm.getDCSPLAcctDetails = {
					selected : null,
					list : [],
					disabled : true
				};
				vm.parentObject = {};
				vm.STANDARD_M1_DESC = "Test Standard M1 Desc";
				vm.COMMENTS = "";
				vm.METHOD_OF_CALC = "Method of calc";
				vm.provisionM1 = 'N';
				vm.provision = 'No';
				vm.custom2amtflag = true;
				vm.hide_mandatory_field_c_p = true;
				vm.hide_mandatory_field_c_schdm_type = true;
				vm.hide_save_provi_y = true;
				vm.custom2Amt = 0;
				vm.oldcustom2Amt = 0;
				vm.oldschdmid = 0;
				vm.postingtaxaccountcodeError = false;
				// vm.offsetObject = {};
				vm.dcsHeaderObj = {};
				// dcs title
				if (vm.SOURCE_SYSTEM == 'DCS') {
					vm.title = "SCHDM Adjustments"
				}
				vm.templateError = false;
				vm.userDescError = false;
				vm.cyamtError = false;
				vm.typeError = false;
				vm.tbbsError = false;
				vm.schdmReviewedError = false;
				vm.reportableactionError = false;
				vm.reportabletaxamountError = false;
				vm.glacctError = false;
				vm.rptdescError = false;

				vm.typeofrptError = false;
				vm.reasonforzeroError = false;
				vm.thisadjamterror = false;
				vm.reportableflag = false;

				// dcs curr
				vm.curr = vm.rowData.HO_FUNCTIONAL_CURRENCY;
				vm.SELECTED_SYSTEM_ACCT = vm.rowData.SYSTEM_ACCT;
				vm.SELECTED_SYSTEM_ACCT_DESC = vm.rowData.SYSTEM_ACCT_DESC;
				// new added for dcs

				vm.dcscurr = "USD";
				vm.userm1 = "";
				vm.olduserm1 = "";
				vm.oldschdm = "";
				vm.oldtbbs = "";
				vm.oldproject = "";
				vm.oldprojectstep = ";"
				vm.CURRENT_YR_AMT = "";
				vm.oldCURRENT_YR_AMT = "";
				vm.template = "";
				vm.oldtemplate = "";
				vm.project = "";
				vm.oldproject = "";
				vm.projectStep = "";
				vm.oldprojectStep = "";
				// vm.sec174amt = "";
				// vm.oldsec174amt = "";
				vm.schdmt = "";
				vm.oldschdmt = "";
				vm.tbbsacctval = "";
				vm.oldtbbsacctval = "";
				vm.hideProjectSteps = false;
				vm.projectLoading = true;
				vm.projectStepLoading = false;
				vm.templateLoading = false;
				vm.tbbsLoading = false;
				vm.schdmloading = false;
				vm.triggerloading = false;
				vm.hidetbbss = false;
				vm.hideschdmtype = false;
				loadProjects();
				loadtemplatedata();
				vm.tempsystemaccount = [];
				vm.mainmodaldata = [];
				vm.tbbsacctdata = [];
				vm.schdmtypedata = [];

				vm.transaction_type = '';
				vm.rpt_tax_acct = '';
				vm.reportable_trans_desc = '';
				vm.tax_shetlter_reg_num = '';
				vm.type_of_reportable_trans = '';
				vm.reasonforchange = '';
				vm.thisadj_amt1 = 0;
				vm.glacct1 = '';
				vm.cy_amt1 = 0;
				vm.earlieradj_amt1 = 0;
				vm.adj_cy_amt1 = 0;
				vm.sch = '';
				vm.userreviewd = '';
				vm.timestamp = '';
				getReportableAction();
				getReportableActionType();
				vm.hidecgni = false;
				if(vm.rowData.TO_FLC_CODE == 'CGNI' || vm.rowData.FLC_CODE == 'CGNI')
				{
					if(vm.rowData.SCHDM_TEMPLATE_ID !== 428) {
						vm.hidecgni = true;
					}
				}
				getglAccts();
				vm.triggerloadingnew = true;
				if (vm.testflag === true) {
					getTopglAccts();
				}

				vm.postinggl_acct_M = [];
				vm.glacctsMdata = [];
				getreportableglAccts();
				// vm.m3formline

				if ($scope.adjsForm !== undefined) {
					$scope.adjsForm.$setPristine();
					$scope.adjsForm.$setUntouched();
				}

				if(vm.rowData.TRANS_TYPE_ACTUAL) {
					vm.FIR_TRANS_TYPE = vm.rowData.TRANS_TYPE_ACTUAL;
				}

				if (vm.rowData.COMBINATION_KEY) {

					CodeComboFactory
							.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
					CodeComboFactory.toggle = false;
					CodeComboFactory.showFormBtn = false;

					initAdjustments();
					loadExistingAdjustments();
					getAllLineDetails();
					getProjectsAndSteps();
					getDcsAdjsTypes();
					getDCSLineDetails();
					getDCSPLDetails();

				} else {

					console
							.log(
									"nnnnnnnnnnnnnnnnn $scope.rowData.COMBINATION_KEY NOT FOUND ",
									vm.rowData.COMBINATION_KEY);
					CodeComboFactory.toggle = true;
					// alert("yyyyyyyy $scope.rowData.COMBINATION_KEY not
					// FOUND");
				}
			}
			;

			// dcs data

			function getReportableAction() {
				var params = {
					"action_code" : 'w1ubsf',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,

				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params).then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.reportableactionLoading = false;
									} else {

										vm.transactions = data.jsonObject;

										vm.reportableactionLoading = false;

									}

								});
			}
			;
			function getReportableActionType() {
				var params = {
					"action_code" : 'hg67p4',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,

				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.reportableactiontypeLoading = false;
									} else {

										vm.reportable_trans_list = data.jsonObject;

										vm.reportableactiontypeLoading = false;

									}

								});
			}
			;

			function getglAccts() {
				var params = {
					"action_code" : 'aucc6o',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
					"chart_key" : vm.rowData.LOCAL_CHART_KEY

				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params).then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.glacctsLoading = false;
									} else {

										vm.glaccts = data.jsonObject;

										vm.glacctsLoading = false;

									}

								});
			}
			;

			function getTopglAccts() {
				var params = {
					"action_code" : 'p88nfx',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
					"chart_key" : vm.rowData.LOCAL_CHART_KEY

				}
				vm.glacctsTOPLoading = false;
				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.glacctsTOPLoading = true;
									} else {
										vm.postinggl_acct_M = [];
										vm.glacctsMdata = [];
										vm.postingTaxTop = "";
										vm.postingGLTop = "";
										vm.glacctsMdata = data.jsonObject;

										for ( var a in vm.glacctsMdata) {
											vm.postinggl_acct_M
													.push(vm.glacctsMdata[a].GL_ACCT);
										}

										vm.postinggl_acct_M = _.uniq(
												vm.postinggl_acct_M,
												JSON.stringify);

										vm.glacctsTOPLoading = true;
										// loadtriggerdatanew_posting();
									}

								});
			}
			;
			vm.applyChanges = function() {
				vm.postingTaxTop = vm.CHECK_SYS_MAP_ACCT;
				loadtriggerdatanew_posting();
				// vm.getChartAcctTableData();
			}

			vm.newPostingTaxchange = function() {
				loadtriggerdatanew_posting();
				checkPostingtaxerror();
			}
			function loadtriggerdatanew_posting() {
				vm.triggerloadingnew = false;
				var posting_acct_key = "";
				for ( var c in vm.glacctsMdata) {
					if (vm.glacctsMdata[c].GL_ACCT == vm.postingTaxTop) {
						posting_acct_key = vm.glacctsMdata[c].GL_ACCT_KEY;
					}
				}
				var params = {
					"action_code" : '4o4eso',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
					"combi_key" : vm.rowData.COMBINATION_KEY,
					"posting_acct_key" : posting_acct_key
				}
				vm.triggerloadingnew = false;
				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.triggerloadingnew = true;
									} else {
										vm.triggerloadingnew = true;
										vm.triggerdata = data.jsonObject;
										if (vm.triggerdata.length > 0)
										/*
										 * BOOK_AMT: 301964 M3_ACCT:
										 * "1120_SCH_M3P3_LN11_COLA - Meals
										 * and entertainment (Expense
										 * perIncome Statement)"
										 * M3_ACCT_KEY: 24410873
										 * POSTING_ACCT: "5060201533 - All
										 * Other Variable Costs-T&L-NonDed"
										 * POSTING_ACCT_KEY: 3641732
										 * POSTING_TAC: "5795650 -
										 * NON-DEDUCTIBLE MEALS AND
										 * ENTERTAINMENT EXPENSES"
										 * POSTING_TAC_KEY: 3920299
										 */
										{
											// vm.postingGLTop =
											// vm.triggerdata[0].POSTING_TAC;
											// vm.postingTax =
											// vm.triggerdata[0].POSTING_TAC;
											vm.m3formline = vm.triggerdata[0].M3_ACCT;

										}
									}

								});
			}
			;

			// 4o4eso
			/*
			 * function loadtriggerdata() { vm.triggerloading = false;
			 *
			 * var posting_acct_key = ""; for ( var c in vm.glacctsMdata) {
			 * if (vm.glacctsMdata[c].GL_ACCT == vm.postingTax) {
			 * posting_acct_key = vm.glacctsMdata[c].GL_ACCT_KEY; } }
			 *
			 * var params = { "action_code" : '4o4eso', "tax_year" :
			 * GlobalService.globalParams.tax_year, "scenario" :
			 * GlobalService.globalParams.scenario, "jcd_key" :
			 * GlobalService.globalParams.jcd_key, "combi_key" :
			 * vm.rowData.COMBINATION_KEY, "posting_acct_key" :
			 * posting_acct_key }
			 *
			 * return JsonObjectFactory .getJSONObj( GENERAL_CONFIG.base_url +
			 * '/loadJsonObject', params) .then( function(data) { if
			 * (data.callSuccess !== "1") { AlertService.add("error",
			 * data.errorMessage); vm.triggerloading = true; } else {
			 *
			 * vm.triggerdata = data.jsonObject; if (vm.triggerdata.length >
			 * 0) { vm.postingGL = vm.triggerdata[0].POSTING_ACCT;
			 * vm.postingTax = vm.triggerdata[0].POSTING_TAC; vm.m3formline =
			 * vm.triggerdata[0].M3_ACCT; vm.bookamt =
			 * vm.triggerdata[0].BOOK_AMT; vm.bookadj =
			 * vm.triggerdata[0].BOOK_ADJ; vm.taxadj =
			 * vm.triggerdata[0].TAX_ADJ; vm.postingacct_key =
			 * vm.triggerdata[0].POSTING_TAC_KEY; } } }); } ;
			 */

			function getreportableglAccts() {
				var params = {
					"action_code" : 'n6rrgu',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
					"chart_key" : vm.rowData.LOCAL_CHART_KEY

				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.glacctsrptLoading = false;
									} else {

										vm.glacctsrpt = data.jsonObject;
										vm.glacct1 = vm.glacctsrpt[0].RT_ACCT;
										vm.glacct1_book = vm.glacctsrpt[0].RT_ACCT;

									}

								});
			}
			;
			function getreportableglAcctchangedata(repotable) {
				vm.loaddata = [];
				if (repotable != undefined) {

					for ( var a in vm.reportableList) {
						if (vm.reportableList[a].glacct.GL_ACCT_KEY == repotable.glacct.GL_ACCT_KEY) {
							vm.reportableList[a].cy_amt_loading = true;
							vm.reportableList[a].earlieradj_amt_loading = true;
						}
					}

					var params = {
						"action_code" : 'dpzi4u',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"cc_key" : vm.rowData.COMBINATION_KEY,
						"gl_acct_key" : repotable.glacct.GL_ACCT_KEY,

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url
											+ '/loadJsonObject', params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.glacctsrptdataLoading = false;
											for ( var a in vm.reportableList) {
												if (vm.reportableList[a].glacct.GL_ACCT_KEY == repotable.glacct.GL_ACCT_KEY) {
													vm.reportableList[a].cy_amt_loading = false;
													vm.reportableList[a].earlieradj_amt_loading = false;
												}
											}
										} else {

											for ( var a in vm.reportableList) {
												if (vm.reportableList[a].glacct.GL_ACCT_KEY == repotable.glacct.GL_ACCT_KEY) {
													vm.reportableList[a].cy_amt = 0;
													vm.reportableList[a].earlieradj_amt = 0;
													vm.reportableList[a].adj_cy_amt = 0;
													vm.reportableList[a].adj_cy_amt = vm.reportableList[a].cy_amt
															+ vm.reportableList[a].earlieradj_amt
															+ vm.reportableList[a].thisadj_amt;
												}
											}

											vm.loaddata = data.jsonObject;

											if (vm.loaddata[0].CY_AMOUNT != undefined
													|| vm.loaddata[0].EARLY_ADJ_AMT != undefined) {
												for ( var a in vm.reportableList) {
													if (vm.reportableList[a].glacct.GL_ACCT_KEY == repotable.glacct.GL_ACCT_KEY) {
														if (vm.loaddata[0].CY_AMOUNT != undefined) {
															vm.reportableList[a].cy_amt = vm.loaddata[0].CY_AMOUNT;
														}
														if (vm.loaddata[0].EARLY_ADJ_AMT != undefined) {
															vm.reportableList[a].earlieradj_amt = vm.loaddata[0].EARLY_ADJ_AMT;
														}
														vm.reportableList[a].adj_cy_amt = vm.reportableList[a].cy_amt
																+ vm.reportableList[a].earlieradj_amt
																+ vm.reportableList[a].thisadj_amt;
													}
												}
											}
											for ( var a in vm.reportableList) {
												if (vm.reportableList[a].glacct.GL_ACCT_KEY == repotable.glacct.GL_ACCT_KEY) {
													vm.reportableList[a].cy_amt_loading = false;
													vm.reportableList[a].earlieradj_amt_loading = false;
												}
											}
										}

									});
				}
			}
			;

			vm.glacctchange = function(repotable) {
				getreportableglAcctchangedata(repotable);
				vm.glacctError = false;
				for ( var a in vm.reportableList) {
					if (vm.reportableList[a].glacct === ''
							|| vm.reportableList[a].glacct === undefined) {
						vm.reportableList[a].glacct_error = true;
						vm.glacctError = true;

					} else {
						vm.reportableList[a].glacct_error = false;

					}
				}

			}

			function loadProjects() {
				var params = {
					"action_code" : 'hp3vyj',
				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.projectLoading = false;
									} else {
										if (vm) {
											vm.projectList = [];
											vm.temp_projectList = [];
											vm.temp_projectList = data.jsonObject;
											for ( var a in vm.temp_projectList) {
												vm.projectList
														.push(vm.temp_projectList[a].PROJECT_NAME);
											}
											vm.projectList = _.uniq(
													vm.projectList,
													JSON.stringify)
											// vm.projectList =
											// data.jsonObject;
											vm.projectLoading = false;

											vm.loadProjectSteps();
										} else {
											console
													.log("element closed before ajax call")
										}

									}

								});
			}
			;

			vm.loadProjectSteps = function() {

				if (vm.project == null || typeof vm.project == "undefined") {
					// vm.hideProjectSteps = true;
					vm.projectStep = null;
					return false;
				}

				vm.hideProjectSteps = false;
				vm.projectStepLoading = true;
				var project_id = "";
				for ( var a in vm.temp_projectList) {
					if (vm.project == vm.temp_projectList[a].PROJECT_NAME) {
						project_id = vm.temp_projectList[a].PROJECT_ID;
					}
				}
				var params = {
					"action_code" : 'rvqx3d',
					"project_id" : project_id,
				}
				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.projectStepLoading = false;
									} else {

										if (vm) {

											vm.projectStepList = [];
											vm.temp_project_step_List = [];
											vm.temp_project_step_List = data.jsonObject;
											for ( var a in vm.temp_project_step_List) {
												vm.projectStepList
														.push(vm.temp_project_step_List[a].LABEL);
											}
											vm.projectStepList = _.uniq(
													vm.projectStepList,
													JSON.stringify)
											vm.projectStepLoading = false;

											// console.log("vm.projectStepList.length
											// :" ,
											// vm.projectStepList.length);
											// console.log("vm.projectStepList
											// :" , vm.projectStepList);

											if (vm.projectStepList.length === 0) {
												vm.hideProjectSteps = true;
												vm.projectStep = null;

											} else {
												vm.hideProjectSteps = false;
												// setProjectStep();

												// vm.projectStep =
												// vm.rowData.STEP_SHORT_DESCRIPTION;

											}

										}

									}
								});
			};

			function loadtemplatedata() {
				vm.mainmodaldata = [];
				vm.templateLoading = true;
				var params = {
					"action_code" : 'uewdwv',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
					"sys_acct" : rowData.LOCAL_CHART_KEY + "_"
							+ rowData.LOCAL_ACCT_KEY + "-"
							+ rowData.SYSTEM_ACCT_KEY

				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.templateLoading = false;
									} else {
										vm.mainmodaldata = data.jsonObject;
										for ( var i in vm.mainmodaldata) {
											vm.tempsystemaccount
													.push(data.jsonObject[i].SCHDM_TEMPLATE);
										}
										vm.tempsystemaccount = _.uniq(
												vm.tempsystemaccount,
												JSON.stringify)
										vm.templateLoading = false;

									}

								});
			}
			;

			vm.reviewdChange = function(value) {

				if (vm.checkdcsflag) {
					if (vm.sch === "" || vm.sch === undefined) {
						vm.schdmReviewedError = true;
					} else {
						vm.schdmReviewedError = false;
					}
				}

				if (value === 'Yes') {

					vm.userreviewd = USER_SETTINGS.user.display_name;
					var dt = new Date();
					vm.timestamp = dt;
					vm.checkdcsflag = false;
					// vm.openEditModal(vm.userreviewd);
					vm.schdmReviewedError = false;
				}
				if (value === 'No') {
					vm.userreviewd = '';
					vm.timestamp = '';
					vm.openEditModal(vm.userreviewd);
					vm.checkdcsflag = false;
					vm.schdmReviewedError = false;
				}
			}
			vm.changeTemplate = function() {

				// loadtbbsdata();
				loadschdmtype();

				for ( var ag in vm.mainmodaldata) {
					if (vm.mainmodaldata[ag].SCHDM_TEMPLATE == vm.template) {
						vm.userm1 = vm.mainmodaldata[ag].USER_DESC;
					}
				}
				vm.checktemplateError();

			}

			vm.checktemplateError = function() {
				if (vm.template === "" || vm.template === undefined) {
					vm.templateError = true;
				} else {
					vm.templateError = false;
				}

				vm.m1Desc();

			}

			function checkPostingtaxerror() {
				if (vm.postingTaxTop === ""
						|| vm.postingTaxTop === undefined) {
					vm.postingtaxaccountcodeError = true;
				} else {
					vm.postingtaxaccountcodeError = false;
				}
			}

			vm.m1Desc = function() {
				if (vm.userm1 === "" || vm.userm1 === undefined) {
					vm.userDescError = true;
				} else {
					vm.userDescError = false;
				}

			}

			vm.cyAmount = function() {
				if (vm.CURRENT_YR_AMT === ""
						|| vm.CURRENT_YR_AMT === undefined) {
					vm.cyamtError = true;
				} else {
					vm.cyamtError = false;
				}

			}

			vm.typeChange = function() {

				vm.checktypeError();
				vm.tbbsacctval = "";

				loadtbbsdata();
				function loadtbbsdata() {

					vm.hidetbbss = false;
					vm.tbbsLoading = true;
					var schdm_temp_tbbs = "";
					vm.tbbsacct = [];
					vm.tbbsacctdata = [];
					vm.tbbsacctval = "";
					var temp_schdm_key = "";
					for ( var a in vm.mainmodaldata) {
						if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
							schdm_temp_tbbs = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
						}
					}
					for ( var lst in vm.schdmtypedata) {
						if (vm.schdmt == vm.schdmtypedata[lst].SCHDM_TYPE) {
							temp_schdm_key = vm.schdmtypedata[lst].SCHDM_TYPE_SAVE;
						}

					}

					var params = {
						"action_code" : '26k8ua',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"local_acct" : rowData.LOCAL_ACCT_KEY,
						"sys_acct" : rowData.LOCAL_CHART_KEY,
						"schdm_temp" : schdm_temp_tbbs,
						'source_system' : 'D',
						"schdm_type" : temp_schdm_key,
						"combination_key" :vm.rowData.COMBINATION_KEY

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url
											+ '/loadJsonObject', params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.hidetbbss = false;
											vm.tbbsLoading = false;
										} else {
											vm.tbbsacctdata = data.jsonObject;
											for ( var i in vm.tbbsacctdata) {
												vm.tbbsacct
														.push(vm.tbbsacctdata[i].TAX_STREAM_ACCT);
											}
											vm.tbbsacct = _.uniq(
													vm.tbbsacct,
													JSON.stringify)

											if (vm.tbbsacct.length > 0) {
												vm.tbbsacctval = vm.tbbsacctdata[0].TAX_STREAM_ACCT;
											}
											vm.hidetbbss = false;
											vm.tbbsLoading = false;
										}
										if (vm.tbbsacct.length < 1) {
											vm.hidetbbss = true;
										}

									});
				}
				;

			}

			vm.checktypeError = function() {
				if (vm.schdmt === "" || vm.schdmt === undefined) {
					vm.typeError = true;
				} else {
					vm.typeError = false;
				}

			}
			vm.tbbsChange = function() {

				if (vm.tbbsacctval === "" || vm.tbbsacctval === undefined) {
					vm.tbbsError = true;
				} else {
					vm.tbbsError = false;
				}

			}
			// vm.changeSchdmReviewed = function (){
			//
			//
			// if ($scope.value === ""
			// || $scope.value === undefined) {
			// vm.schdmReviewedError = true;
			// } else {
			// vm.schdmReviewedError = false;
			// }
			//
			//
			//
			// }
			function checkDCSData() {

				vm.checktemplateError();
				vm.m1Desc();
				vm.checktypeError();
				vm.tbbsChange();
				vm.cyAmount();
				vm.reviewdChange();
				if (vm.testflag == true) {
					checkPostingtaxerror();
				}
				// vm.changeSchdmReviewed();
				// if (!vm.checkdcsflag) {
				// vm.reviewdChange();
				// } else {
				// vm.schdmReviewedError = true;
				// }
				if (vm.rpt_t_flag == 'Yes') {
					checkReportabletransactions();
				}
			}

			function checkReportabletransactions() {

				vm.reportabletaxamountchange();
				vm.reportableactionchange();

				if (vm.transaction_type.CODE_NAME == 'DP') {
					vm.rptdescchange();
					vm.typeofrpttranschange();
				}
				if (vm.transaction_type.CODE_NAME == 'BAA') {
					vm.rptdescchange();
					vm.typeofrpttranschange();
					vm.glacctchange();
					vm.adjusted_amt_change();
				}

				if (vm.transaction_type.CODE_NAME == 'ZBA') {
					vm.reasonforzerobookamtchange();
					vm.rptdescchange();
					vm.typeofrpttranschange();
				}
				/*
				 * vm.rptdescchange(); vm.taxshelterchange();
				 * vm.typeofrpttranschange();
				 * vm.reasonforzerobookamtchange(); vm.glacctchange();
				 * vm.adjusted_amt_change();
				 */
				// reportableactionError
			}

			vm.reportableactionchange = function() {

				if (vm.transaction_type === ""
						|| vm.transaction_type === undefined) {
					vm.reportableactionError = true;
				} else {
					vm.reportableactionError = false;
				}
				vm.reportableactionchange_flag = vm.transaction_type.CODE_NAME;

			}
			vm.reportabletaxamountchange = function() {
				if (vm.rpt_tax_acct === "" || vm.rpt_tax_acct === undefined) {
					vm.reportabletaxamountError = true;
				} else {
					vm.reportabletaxamountError = false;
				}
			}

			vm.rptdescchange = function() {
				if (vm.reportable_trans_desc === ""
						|| vm.reportable_trans_desc === undefined) {
					vm.rptdescError = true;
				} else {
					vm.rptdescError = false;
				}
			}

			vm.typeofrpttranschange = function() {
				if (vm.type_of_reportable_trans === ""
						|| vm.type_of_reportable_trans === undefined) {
					vm.typeofrptError = true;
				} else {
					vm.typeofrptError = false;
				}
			}

			vm.reasonforzerobookamtchange = function() {
				if (vm.reasonforchange === ""
						|| vm.reasonforchange === undefined) {
					vm.reasonforzeroError = true;
				} else {
					vm.reasonforzeroError = false;
				}
			}

			// vm.applyButton = function() {
			//
			// vm.sec174amt = vm.CURRENT_YR_AMT;
			// }
			/*
			 * function loadtbbsdata() {
			 *
			 * vm.hidetbbss = false; vm.tbbsLoading = true; var
			 * schdm_temp_tbbs = ""; vm.tbbsacct = []; vm.tbbsacctdata = [];
			 * var temp_schdm_key = ""; for ( var a in vm.mainmodaldata) {
			 * if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
			 * schdm_temp_tbbs = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID; } }
			 * for ( var lst in vm.schdmtypedata) { if (vm.schdmt ==
			 * vm.schdmtypedata[lst].SCHDM_TYPE) { temp_schdm_key =
			 * vm.schdmtypedata[lst].SCHDM_TYPE_SAVE; } }
			 *
			 * var params = { "action_code" : '26k8ua', "tax_year" :
			 * GlobalService.globalParams.tax_year, "scenario" :
			 * GlobalService.globalParams.scenario, "jcd_key" :
			 * GlobalService.globalParams.jcd_key, "local_acct" :
			 * rowData.LOCAL_ACCT_KEY, "sys_acct" : rowData.LOCAL_CHART_KEY,
			 * "schdm_temp" : schdm_temp_tbbs, 'source_system' : 'D',
			 * "schdm_type" : temp_schdm_key }
			 *
			 * return JsonObjectFactory .getJSONObj( GENERAL_CONFIG.base_url +
			 * '/loadJsonObject', params) .then( function(data) {
			 *
			 * if (data.callSuccess !== "1") { AlertService.add("error",
			 * data.errorMessage); vm.hidetbbss = false; vm.tbbsLoading =
			 * false; } else { vm.tbbsacctdata = data.jsonObject; for ( var
			 * i in vm.tbbsacctdata) { vm.tbbsacct
			 * .push(data.jsonObject[i].TAX_STREAM_ACCT); } vm.tbbsacct =
			 * _.uniq(vm.tbbsacct, JSON.stringify)
			 *
			 * if (vm.type === false && vm.tbbsacct.length > 0) {
			 * vm.tbbsacctval = data.jsonObject[0].TAX_STREAM_ACCT_DEFAULT; }
			 * vm.hidetbbss = false; vm.tbbsLoading = false; } if
			 * (vm.tbbsacct.length < 1) { vm.hidetbbss = true; }
			 *
			 * }); } ;
			 */

			function loadschdmtype() {

				var schdm_temp = "";
				vm.schdmtype = [];
				vm.hideschdmtype = false;
				vm.schdmloading = true;
				vm.schdmtypedata = [];
				vm.schdmt = "";
				for ( var a in vm.mainmodaldata) {
					if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
						schdm_temp = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
					}
				}

				var params = {
					"action_code" : 'jhsi7h',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,

					"sys_acct" : rowData.LOCAL_CHART_KEY + "_"
							+ rowData.LOCAL_ACCT_KEY + "-"
							+ rowData.SYSTEM_ACCT_KEY,
					// "local_accts": rowData.SYS_ACCT_KEY,
					// "schdm_temp" : _item.SCHDM_TEMPLATE_DEFAULT,
					"schdm_temp" : schdm_temp,

				}

				return JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								params)
						.then(
								function(data) {

									if (data.callSuccess !== "1") {
										AlertService.add("error",
												data.errorMessage);
										vm.schdmloading = false;
										vm.hideschdmtype = false;
									} else {
										vm.schdmtypedata = data.jsonObject;
										// vm.schdmt =
										// vm.schdmtypedata[0].SCHDM_TYPE_DEFAULT;
										for ( var i in data.jsonObject) {
											vm.schdmtype
													.push(vm.schdmtypedata[i].SCHDM_TYPE);

										}
										vm.schdmtype = _.uniq(vm.schdmtype,
												JSON.stringify)
										vm.schdmt = vm.schdmtypedata[0].SCHDM_TYPE_DEFAULT;
										vm.hideschdmtype = false;
										vm.schdmloading = false;
										vm.checktypeError();
										loadtbbsdata();
										function loadtbbsdata() {

											vm.hidetbbss = false;
											vm.tbbsLoading = true;
											var schdm_temp_tbbs = "";
											vm.tbbsacct = [];
											vm.tbbsacctdata = [];
											vm.tbbsacctval = "";
											var temp_schdm_key = "";
											for ( var a in vm.mainmodaldata) {
												if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
													schdm_temp_tbbs = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
												}
											}
											for ( var lst in vm.schdmtypedata) {
												if (vm.schdmt == vm.schdmtypedata[lst].SCHDM_TYPE) {
													temp_schdm_key = vm.schdmtypedata[lst].SCHDM_TYPE_SAVE;
												}

											}

											var params = {
												"action_code" : '26k8ua',
												"tax_year" : GlobalService.globalParams.tax_year,
												"scenario" : GlobalService.globalParams.scenario,
												"jcd_key" : GlobalService.globalParams.jcd_key,
												"local_acct" : rowData.LOCAL_ACCT_KEY,
												"sys_acct" : rowData.LOCAL_CHART_KEY,
												"schdm_temp" : schdm_temp_tbbs,
												'source_system' : 'D',
												"schdm_type" : temp_schdm_key,
												"combination_key" :vm.rowData.COMBINATION_KEY

											}

											return JsonObjectFactory
													.getJSONObj(
															GENERAL_CONFIG.base_url
																	+ '/loadJsonObject',
															params)
													.then(
															function(data) {

																if (data.callSuccess !== "1") {
																	AlertService
																			.add(
																					"error",
																					data.errorMessage);
																	vm.hidetbbss = false;
																	vm.tbbsLoading = false;
																} else {
																	vm.tbbsacctdata = data.jsonObject;
																	for ( var i in vm.tbbsacctdata) {
																		vm.tbbsacct
																				.push(vm.tbbsacctdata[i].TAX_STREAM_ACCT);
																	}
																	vm.tbbsacct = _
																			.uniq(
																					vm.tbbsacct,
																					JSON.stringify)

																	if (vm.tbbsacct.length > 0) {
																		vm.tbbsacctval = vm.tbbsacctdata[0].TAX_STREAM_ACCT;
																	}
																	vm.hidetbbss = false;
																	vm.tbbsLoading = false;
																}
																if (vm.tbbsacct.length < 1) {
																	vm.hidetbbss = true;
																}

															});
										}
										;

										if (vm.schdmtype.length < 1) {
											vm.hideschdmtype = true;
										}
									}

								});
			}
			;

			// dcs data end

			init();

			$scope.$on('CCKeySet', function(event, data) {
				vm.showAdjsForm = true;

			});

			function validateData() {
				var object_to_validate, formName, getInnerForm;

				_.forEach(vm.addOffsetRows, function(value, key) {

					object_to_validate = "" + value.LOCAL_ACCT_KEY
							+ value.TP_KEY + value.SCHD_H_LINE_NO;
					var getIndexFoundAt = _.findIndex(vm.addOffsetRows, {
						'ACCT_TP_LINE_NO' : object_to_validate
					});

					if (getIndexFoundAt < 0 || key === getIndexFoundAt) {
						vm.INVALID_OBJECT = "N";
						$scope.adjsForm.invalid_object_holder.$setValidity(
								"invalidAcctTPCombo", true);
					} else {
						vm.INVALID_OBJECT = "Y";
						$scope.adjsForm.invalid_object_holder.$setValidity(
								"invalidAcctTPCombo", false);

					}

				});
			}

			function initAdjustments() {

				vm.parentObject = {
					"CHART_KEY" : vm.rowData.LOCAL_CHART_KEY,
					"LOCAL_ACCT_KEY" : vm.rowData.LOCAL_ACCT_KEY,
					"ACCT_DD_DISPLAY" : (vm.rowData.GRID_TYPE === 'LOCAL_BOOK_TO_TAX_SUMMARY') ? vm.rowData.LOCAL_ACCT
							+ '-' + vm.rowData.LOCAL_ACCT_DESC
							: '',
					"LOCAL_ACCT_TYPE" : vm.rowData.LOCAL_ACCT_TYPE,
					"SCHD_H_LINE_NO" : (vm.rowData.SOURCE_SYSTEM === 'DCS') ? ""
							: "0",
					"PRIOR_YEAR_AMT" : vm.rowData.BOY_AMT,
					"CURR_YEAR_AMT" : vm.rowData.BOOK_AMT,
					"ADJ_AMT" : 0,
					"MULTIPLIER" : (vm.rowData.SOURCE_SYSTEM === 'DCS') ? ""
							: 0,
					"TP_KEY" : vm.rowData.TP_KEY,
					"PROJECT_ID" : 0,
					"PROJECT_STEP_ID" : 0,
					"AUTO_REVERSE_YN" : (vm.rowData.SOURCE_SYSTEM === 'DCS') ? ""
							: "N",
					"TRANS_DETAILS_KEY" : 0,
					"IS_ACCT_EDITABLE" : (vm.rowData.GRID_TYPE === 'LOCAL_BOOK_TO_TAX_SUMMARY') ? "N"
							: "Y",
					"STANDARD_M1_DESC" : "",
					"M3_FORM_LINE" : "M3_FORM_LINE",
					"POSTING_GL_ACCT_KEY" : ""

				};

				vm.addOffsetAccount();
				// dcs specific header properties
				vm.dcsHeaderObj = {
					"DR8A_LINE_NO" : "",
					"ZERO_BK_AMT_YN" : (vm.rowData.BOOK_AMT === 0) ? 'Y'
							: 'N',
					"PROVISION_YN" : "N",
					"RECUR_FLAG" : "N",
					"SETT_CODE" : "S",
					"METHOD_OF_CALC" : "",
					"SCHDM_TEMPLATE_KEY" : vm.rowData.SCHDM_TEMPLATE_KEY,
					"SCHDM_TYPE" : ""
				};

				if (vm.rowData.TP_KEY !== 0) {
					vm.tpObject = {
						"TP_ENTITY" : vm.rowData.TP_ME?vm.rowData.TP_LEID + "-"
								+ vm.rowData.TP_CDR_NO + "-"
								+ vm.rowData.TP_REP_PD + "-" + vm.rowData.TP_ME 
								:vm.rowData.TP_LEID + "-"
								+ vm.rowData.TP_CDR_NO + "-"
								+ vm.rowData.TP_REP_PD,
						"TP_KEY" : vm.rowData.TP_KEY,
						"TP_REP_PD" : vm.rowData.TP_REP_PD,
						"TP_LEID" : vm.rowData.TP_LEID,
						"TP_CDR_NO" : vm.rowData.TP_CDR_NO
					};
				}

			}
			;

			function getAllLineDetails() {
				EPLocalTaxAdjustmentsFactory
						.getLineDetails(vm.mainScreenKey, vm.rowData.TAX_TYPE)
						.then(
								function(res) {
									vm.getLineDetails.list = res;
									vm.getOffsetLineDetails.list = res;
									vm.getLineDetails.selected = null;/* vm.getLineDetails.list[0]; */
									vm.getOffsetLineDetails.selected = vm.getOffsetLineDetails.list[0];
									vm.addOffsetRows[0]["selectedSchdH"] = vm.getOffsetLineDetails.list[0];

								});
			}
			;

			function getDCSLineDetails() {
				var dcsAdjTypeIndex = 0;
				EPLocalTaxAdjustmentsFactory
						.getDCSLineDetails(vm.rowData.SYSTEM_ACCT,
								vm.rowData.SCHDM_TEMPLATE_KEY)
						.then(
								function(res) {

									if (res.length) {
										dcsAdjTypeIndex = _
												.findIndex(
														vm.getDcsAdjsDetails.list,
														{
															'ADJ_TYPE' : res[0].SCHDM_TYPE
														});

										vm.getDcsAdjsDetails.selected = vm.getDcsAdjsDetails.list[dcsAdjTypeIndex];
										vm.parentObject["STANDARD_M1_DESC"] = res[0].SCHDM_DESC;
										vm.parentObject["M3_FORM_LINE"] = res[0].FORM_LINE_1120_M3;

										vm.dcsHeaderObj["DR8A_LINE_NO"] = res[0].DR8A_LINE_NO;
										vm.dcsHeaderObj["METHOD_OF_CALC"] = res[0].METHOD_OF_CALC;
										vm.dcsHeaderObj["SCHDM_TYPE"] = vm.getDcsAdjsDetails.selected.ADJ_TYPE;
									}

								});
			}
			;

			function getDCSPLDetails() {
				EPLocalTaxAdjustmentsFactory
						.getDCSPLDetails(vm.rowData.SYSTEM_ACCT_KEY,
								vm.rowData.LOCAL_CHART_KEY)
						.then(
								function(res) {
									if (res.length) {
										vm.getDCSPLAcctDetails.list = res;

										// dcs title
										if (vm.SOURCE_SYSTEM == 'DCS') {
											res.splice(0, 0, " ");
											vm.getDCSPLAcctDetails.selected1 = res[0];
											vm.getDCSPLAcctDetails.selected2 = res[0];
											vm.getDCSPLAcctDetails.selected3 = res[0];
											vm.getDCSPLAcctDetails.selected4 = res[0];
											vm.parentObject["POSTING_GL_ACCT_KEY"] = vm.getDCSPLAcctDetails.selected4.GL_ACCT_KEY;
										} else {
											vm.getDCSPLAcctDetails.selected = res[0];
											vm.parentObject["POSTING_GL_ACCT_KEY"] = vm.getDCSPLAcctDetails.selected.GL_ACCT_KEY;
										}

									}

								});
			}
			;

			function getProjectsAndSteps() {
				EPLocalTaxAdjustmentsFactory.getProjectsAndSteps(
						$scope.taxYear).then(
						function(res) {

							vm.allProjectsWithSteps = res;

							vm.projectsList.list = _.map(_.uniqBy(res,
									'PROJECT_ID'), function(item) {
								return {
									PROJECT_ID : item.PROJECT_ID,
									PROJECT_NAME : item.PROJECT_NAME,
									STEPS_EXISTS : item.STEPS_EXISTS
								};
							});

						});
			}
			;

			function getDcsAdjsTypes() {
				vm.getDcsAdjsDetails.list = EPLocalTaxAdjustmentsFactory
						.getDcsAdjsTypes();

			}
			;

			function validateAcctLineNo(selectedAcctType, acctFor) {

				var balSheetAccts = [ 1, 2, 3 ];
				var incomeAccts = [ 5, 6, 7 ];
				var lineNumber = vm.getLineDetails.selected.LINE_NO;

				// Balance sheet accounts should not have a line number
				if (balSheetAccts.indexOf(selectedAcctType) >= 0
						&& lineNumber !== "0") {
					// alert("Balance Sheet Statement Parent Account
					// selected with 5471/8865 Line No");
					$scope.adjsForm.schdhClassification.$setValidity(
							"invalidLineNo", false);
				} else {
					$scope.adjsForm.schdhClassification.$setValidity(
							"invalidLineNo", true);
				}

				// Income accounts should have a line number
				if (incomeAccts.indexOf(selectedAcctType) >= 0
						&& lineNumber === "0") {
					$scope.adjsForm.schdhClassification.$setValidity(
							"invalidIncomeLineNo", false);
				} else {
					$scope.adjsForm.schdhClassification.$setValidity(
							"invalidIncomeLineNo", true);
				}
			}
			;

			function loadExistingAdjustments() {
				vm.existingLoading = true;
				if (vm.SOURCE_SYSTEM == 'DCS') {
					var params = {
						"action_code" : 'z7xfyr',
						"screen_key" : vm.mainScreenKey,
						"combination_key" : vm.combination_key,
						"chart_key" : vm.parentObject.CHART_KEY,
						"local_acct_key" : rowData.SYSTEM_ACCT_KEY,
					};
					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url
											+ '/loadJsonObject', params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.existingLoading = false;
										} else {

											var refkey_temp = 0;
											vm.existingAdjustments_temp = data.jsonObject;

											var lookup = {};
											var items = data.jsonObject;
											var result = [];
											var counter = 0;
											var temp_counter_obj = {};
											for (var item, i = 0; item = items[i++];) {
												var name = item.SCHDM_ID;

												if (!(name in lookup)) {
													lookup[name] = 1;
													result.push(name);
												}
											}

											for ( var t in result) {
												counter = 0;
												for ( var q in vm.existingAdjustments_temp) {
													temp_counter_obj = {};
													if (result[t] == vm.existingAdjustments_temp[q].SCHDM_ID
															&& counter == 0) {
														temp_counter_obj = vm.existingAdjustments_temp[q];
														vm.existingAdjustments
																.push(temp_counter_obj);
														counter++;
													}
												}

											}
											// vm.existingAdjustments =
											// data.jsonObject;

											vm.existingLoading = false;

											if (vm.existingAdjustments.length === 0) {
												vm.noRecords = true;
											}
										}

									});
				} else {
					var params = {
						"action_code" : 'q15pkg',
						"screen_key" : vm.mainScreenKey,
						"combination_key" : vm.combination_key,
						"chart_key" : vm.parentObject.CHART_KEY
					};
					
					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url
											+ '/loadJsonObject', params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.existingLoading = false;
										} else {
											vm.existingAdjustments = formatAdjustmentData(data.jsonObject);

											vm.existingLoading = false;

											if (vm.existingAdjustments.length === 0) {
												vm.noRecords = true;
											}
										}
									});
				}

			}

			function formatAdjustmentData(_data) {
				var adjustments = [];
				var adjAccounts = [];
				var index = 0;
				_.forEach(_data, function(value, key) {

					if (value.ROW_INDICATOR === 1) {

						var adjObject = {};
						adjObject = value;
						adjObject.ACCOUNTS = [];
						adjObject.REFERENCE_KEY = index++; // This is done
						// for
						// |unique:'REFERENCE_KEY'
						// pipe
						// mentioned in
						// html. Pipe
						// was intended
						// only DCS.
						adjustments.push(adjObject);

					} else {
						var AccountObj = {};
						AccountObj = value;

						AccountObj.TYPE = "edit";
						AccountObj.REFERENCE_KEY = index++; // This is done
						// for
						// |unique:'REFERENCE_KEY'
						// pipe
						// mentioned in
						// html. Pipe
						// was intended
						// only DCS.
						adjAccounts.push(AccountObj);

					}
				});

				_
						.forEach(
								adjustments,
								function(adjVal, adjKey) {
									adjustments[adjKey].ACCOUNTS = [];
									var row_one_trans_header_key = adjVal.TRANS_HEADER_KEY;

									_
											.forEach(
													adjAccounts,
													function(value, key) {

														var account_TRANS_HEADER_KEY = value.TRANS_HEADER_KEY;

														if (value.ROW_INDICATOR !== 1
																&& row_one_trans_header_key === account_TRANS_HEADER_KEY) {
															adjustments[adjKey].ACCOUNTS
																	.push(value);
														}

													});
								});

				adjAccounts = null;
				return adjustments;

			}

			vm.reclassCall1 = function() {
				vm.reportableflag = false;
				// vm.custom2amtflag = true;
				// vm.hide_mandatory_field_c_p = true;
				// vm.hide_save_provi_y = true;
			}

			vm.reclassCall = function() {
				vm.testflag = false;
				vm.custom2amtflag = true;
				vm.hide_mandatory_field_c_p = true;
				vm.hide_mandatory_field_c_schdm_type = true;
				vm.hide_save_provi_y = true;
				if (vm.modal_name == 'epTaxAdjsLocalCharttest') {
					vm.testflag = true;
				}
				$scope.accounts = [];
				vm.m3formlineB = "";
				$scope.crudLoading_book = false;
				vm.rptdescError_book = false;
				vm.total_this_adj_amt_static_book = 0;
				vm.typeofrptError_book = false;
				vm.thisadjamterror_book = false;
				vm.glacctError_book = false;
				vm.noRecords_book = false;
				vm.type_book = false;
				vm.reportableList_book = [];
				vm.reportable_trans_desc_book = "";
				vm.tax_shetlter_reg_num_book = "";
				vm.type_of_reportable_trans_book = "";
				vm.adj_cy_amt1_book = 0;
				vm.cy_amt1_book = 0;
				vm.earlieradj_amt1_book = 0;
				vm.thisadj_amt1_book = 0;
				vm.ref_key = "";
				vm.existing_chart_data = [];
				vm.reportableList_book.push({
					glacct_book : '',
					glacct_key_book : '',
					cy_amt_book : 0,
					cy_amt_book_loading : false,
					earlieradj_amt_book : 0,
					earlieradj_amt_book_loading : false,
					thisadj_amt_book : 0,
					adj_cy_amt_book : 0,
					glacct_error_book : '',
					thisadj_amt_error_book : '',
					type : 'I'

				});
				vm.reportableflag = true;
				getTopglAcctsB();
				getexistingBookReportable();
				vm.glacctchange_book = function(repotable) {
					getreportableglAcctchangedata_book(repotable);
					vm.glacctError_book = false;
					for ( var a in vm.reportableList_book) {
						if (vm.reportableList_book[a].glacct_book === ''
								|| vm.reportableList_book[a].glacct_book === undefined) {
							vm.reportableList_book[a].glacct_error_book = true;
							vm.glacctError_book = true;

						} else {
							vm.reportableList_book[a].glacct_error_book = false;

						}
					}
				}

				// 6lsis2

				function getTopglAcctsB() {
					var params = {
						"action_code" : 'p88nfx',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"chart_key" : vm.rowData.LOCAL_CHART_KEY

					}
					vm.glacctsTOPLoading = false;
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url
											+ '/loadJsonObject', params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.glacctsTOPLoading = true;
										} else {
											vm.postinggl_acct_MB = [];
											vm.glacctsMdataB = [];
											vm.postingTaxTopB = "";
											vm.postingGLTopB = "";
											vm.glacctsMdataB = data.jsonObject;

											for ( var a in vm.glacctsMdataB) {
												vm.postinggl_acct_MB
														.push(vm.glacctsMdataB[a].GL_ACCT);
											}

											vm.postinggl_acct_MB = _.uniq(
													vm.postinggl_acct_MB,
													JSON.stringify);

											vm.glacctsTOPLoading = true;
											// loadtriggerdatanew_posting();
										}

									});
				}
				;

				vm.newPostingTaxchangeB = function() {
					loadtriggerdatanew_postingB();
				}
				function loadtriggerdatanew_postingB() {
					vm.triggerloadingnew = false;
					var posting_acct_key = "";
					for ( var c in vm.glacctsMdataB) {
						if (vm.glacctsMdataB[c].GL_ACCT == vm.postingTaxTopB) {
							posting_acct_key = vm.glacctsMdataB[c].GL_ACCT_KEY;
						}
					}
					var params = {
						"action_code" : '4o4eso',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"combi_key" : vm.rowData.COMBINATION_KEY,
						"posting_acct_key" : posting_acct_key
					}
					vm.triggerloadingnew = false;
					return JsonObjectFactory.getJSONObj(
							GENERAL_CONFIG.base_url + '/loadJsonObject',
							params).then(function(data) {

						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage);
							vm.triggerloadingnew = true;
						} else {
							vm.triggerloadingnew = true;
							vm.triggerdata = data.jsonObject;
							if (vm.triggerdata.length > 0)
							/*
							 * BOOK_AMT: 301964 M3_ACCT:
							 * "1120_SCH_M3P3_LN11_COLA - Meals and
							 * entertainment (Expense perIncome Statement)"
							 * M3_ACCT_KEY: 24410873 POSTING_ACCT:
							 * "5060201533 - All Other Variable
							 * Costs-T&L-NonDed" POSTING_ACCT_KEY: 3641732
							 * POSTING_TAC: "5795650 - NON-DEDUCTIBLE MEALS
							 * AND ENTERTAINMENT EXPENSES" POSTING_TAC_KEY:
							 * 3920299
							 */
							{
								// vm.postingGLTop =
								// vm.triggerdata[0].POSTING_TAC;
								// vm.postingTax =
								// vm.triggerdata[0].POSTING_TAC;
								vm.m3formlineB = vm.triggerdata[0].M3_ACCT;

							}
						}

					});
				}
				;

				function getexistingBookReportable() {
					vm.existingchartLoading = true;
					var params = {
						"action_code" : '6lsis2',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"cc_key" : vm.rowData.COMBINATION_KEY,
						"local_acct_key" : vm.rowData.LOCAL_ACCT_KEY
					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url
											+ '/loadJsonObject', params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											vm.existingchartLoading = false;
											return false;
										} else {
											var refkey_temp = 0;
											vm.existing_chart_data_temp = data.jsonObject;
											vm.existing_chart_data = [];

											var lookup_book = {};
											var items_book = data.jsonObject;

											var result_book = [];
											var counter_book = 0;
											var temp_counter_obj_book = {};
											for (var item, i = 0; item = items_book[i++];) {
												var name = item.REF_KEY;

												if (!(name in lookup_book)) {
													lookup_book[name] = 1;
													result_book.push(name);
												}
											}

											for ( var t in result_book) {
												counter_book = 0;
												for ( var q in vm.existing_chart_data_temp) {
													temp_counter_obj_book = {};
													if (result_book[t] == vm.existing_chart_data_temp[q].REF_KEY
															&& counter_book == 0) {
														temp_counter_obj_book = vm.existing_chart_data_temp[q];
														vm.existing_chart_data
																.push(temp_counter_obj_book);
														counter_book++;
													}
												}

											}

											// vm.existing_chart_data =
											// data.jsonObject;

											if (vm.existing_chart_data.length == 0) {
												vm.noRecords_book = true;
											}
											vm.existingchartLoading = false;
										}
									})
				}

				function getreportableglAcctchangedata_book(repotable) {
					vm.loaddata_book = [];

					if (repotable != undefined) {
						for ( var a in vm.reportableList_book) {
							if (vm.reportableList_book[a].glacct_book.GL_ACCT_KEY == repotable.glacct_book.GL_ACCT_KEY) {
								vm.reportableList_book[a].cy_amt_book_loading = true;
								vm.reportableList_book[a].earlieradj_amt_book_loading = true;
							}
						}

						var params = {
							"action_code" : 'dpzi4u',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"cc_key" : vm.rowData.COMBINATION_KEY,
							"gl_acct_key" : repotable.glacct_book.GL_ACCT_KEY,

						}

						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {

											if (data.callSuccess !== "1") {
												AlertService.add("error",
														data.errorMessage);
												vm.glacctsrptdataLoading = false;

												for ( var a in vm.reportableList_book) {
													if (vm.reportableList_book[a].glacct_book.GL_ACCT_KEY == repotable.glacct_book.GL_ACCT_KEY) {
														vm.reportableList_book[a].cy_amt_book_loading = false;
														vm.reportableList_book[a].earlieradj_amt_book_loading = false;
													}
												}

											} else {

												for ( var b in vm.reportableList_book) {
													if (vm.reportableList_book[b].glacct_book.GL_ACCT_KEY == repotable.glacct_book.GL_ACCT_KEY) {
														vm.reportableList_book[b].cy_amt_book = 0;
														vm.reportableList_book[b].earlieradj_amt_book = 0;
														vm.reportableList_book[b].adj_cy_amt_book = 0;
														vm.reportableList_book[b].adj_cy_amt_book = vm.reportableList_book[b].cy_amt_book
																+ vm.reportableList_book[b].earlieradj_amt_book
																+ vm.reportableList_book[b].thisadj_amt_book;
														vm.reportableList_book[b].cy_amt_book_loading = false;
														vm.reportableList_book[b].earlieradj_amt_book_loading = false;

													}
												}

												vm.loaddata_book = data.jsonObject;

												if (vm.loaddata_book[0].CY_AMOUNT != undefined
														|| vm.loaddata_book[0].EARLY_ADJ_AMT != undefined) {
													for ( var c in vm.reportableList_book) {
														if (vm.reportableList_book[c].glacct_book.GL_ACCT_KEY == repotable.glacct_book.GL_ACCT_KEY) {

															if (vm.loaddata_book[0].CY_AMOUNT != undefined) {
																vm.reportableList_book[c].cy_amt_book = vm.loaddata_book[0].CY_AMOUNT;
																vm.reportableList_book[c].cy_amt_book_loading = false;
															}
															if (vm.loaddata_book[0].EARLY_ADJ_AMT != undefined) {
																vm.reportableList_book[c].earlieradj_amt_book = vm.loaddata_book[0].EARLY_ADJ_AMT;
																vm.reportableList_book[c].earlieradj_amt_book_loading = false;
															}
															vm.reportableList_book[c].adj_cy_amt_book = vm.reportableList_book[c].cy_amt_book
																	+ vm.reportableList_book[c].earlieradj_amt_book
																	+ vm.reportableList_book[c].thisadj_amt_book;
														}

													}
												}
											}

										});
					}
				}
				;

				vm.removeportable_book = function(index) {
					vm.reportableList_book.splice(index, 1);
					vm.adjusted_amt_change_book();
				};
				vm.addReportable_book = function() {
					vm.reportableList_book.push({
						glacct_book : '',
						glacct_key_book : '',
						cy_amt_book : 0,
						cy_amt_book_loading : false,
						earlieradj_amt_book : 0,
						earlieradj_amt_book_loading : false,
						thisadj_amt_book : 0,
						adj_cy_amt_book : 0,
						glacct_error_book : '',
						thisadj_amt_error_book : '',
						type : 'I'

					});
				};

				vm.rptdescchange_book = function() {
					if (vm.reportable_trans_desc_book === ""
							|| vm.reportable_trans_desc_book === undefined) {
						vm.rptdescError_book = true;
					} else {
						vm.rptdescError_book = false;
					}
				}

				vm.typeofrpttranschange_book = function() {
					if (vm.type_of_reportable_trans_book === ""
							|| vm.type_of_reportable_trans_book === undefined) {
						vm.typeofrptError_book = true;
					} else {
						vm.typeofrptError_book = false;
					}
				}

				vm.adjusted_amt_change_book = function(index) {

					if (index != undefined) {
						vm.reportableList_book[index].adj_cy_amt_book = vm.reportableList_book[index].cy_amt_book
								+ vm.reportableList_book[index].earlieradj_amt_book
								+ vm.reportableList_book[index].thisadj_amt_book;
					}

					vm.thisadjamterror_book = false;
					vm.total_this_adj_amt_book = 0;
					vm.total_this_adj_amt_static_book = 0;
					for ( var a in vm.reportableList_book) {
						if (vm.reportableList_book[a].thisadj_amt_book == ''
								|| vm.reportableList_book[a].thisadj_amt_book == undefined) {
							vm.reportableList_book[a].thisadj_amt_book = 0;
						}
						vm.total_this_adj_amt_book += vm.reportableList_book[a].thisadj_amt_book;
					}
					vm.thisadj_amt1_book = vm.total_this_adj_amt_book * -1;
					vm.total_this_adj_amt_static_book = vm.thisadj_amt1_book
							+ vm.total_this_adj_amt_book;
					vm.adj_cy_amt1_book = vm.thisadj_amt1_book;
					for ( var b in vm.reportableList_book) {
						if (vm.reportableList_book[b].thisadj_amt_book == 0)

						{
							vm.reportableList_book[b].thisadj_amt_error_book = true;
							vm.thisadjamterror_book = true;
						} else {
							vm.reportableList_book[b].thisadj_amt_error_book = false;
						}
					}
				}

				// vm.glacctchange_book = function() {
				// vm.glacctError_book = false;
				// for ( var a in vm.reportableList_book) {
				// if (vm.reportableList_book[a].glacct_book === ''
				// || vm.reportableList_book[a].glacct_book === undefined) {
				// vm.reportableList_book[a].glacct_error_book = true;
				// vm.glacctError_book = true;
				//
				// } else {
				// vm.reportableList_book[a].glacct_error_book = false;
				// ;
				// }
				// }
				// }
				vm.reset1 = function() {
					vm.reclassCall();
				};
				function checkData() {
					vm.rptdescchange_book();

					vm.typeofrpttranschange_book();
					vm.adjusted_amt_change_book();
					vm.glacctchange_book();
				}

				vm.loadAdjustment_b = function(index) {

					vm.reportableList_book = [];
					vm.type_book = true;
					vm.ref_key = vm.existing_chart_data[index].REF_KEY;
					var data_edit = vm.existing_chart_data[index];
					vm.old_reportable_trans_desc_book = vm.existing_chart_data[index].REPORTABLE_TRANSACTION_DESCRIPTION;
					vm.reportable_trans_desc_book = vm.existing_chart_data[index].REPORTABLE_TRANSACTION_DESCRIPTION;
					vm.old_tax_shetlter_reg_num_book = vm.existing_chart_data[index].TAX_SHELTER_REGISTRATION_NUMBER;
					vm.tax_shetlter_reg_num_book = vm.existing_chart_data[index].TAX_SHELTER_REGISTRATION_NUMBER;
					vm.old_type_of_reportable_trans_book = vm.existing_chart_data[index].TYPE_REPORTABLE_TRANSACTION;
					vm.type_of_reportable_trans_book = vm.existing_chart_data[index].TYPE_REPORTABLE_TRANSACTION;
					// vm.old_glacct_key =
					// vm.existing_chart_data[index].GL_ACCT_KEY;
					// vm.old_thisadj_amt_book =
					// vm.existing_chart_data[index].ADJ_AMOUNT;
					vm.reportableList_book = [];

					/*
					 * vm.reportableList_book .push({ glacct_book :
					 * vm.existing_chart_data[index].GL_ACCT, cy_amt_book :
					 * vm.existing_chart_data[index].CY_AMOUNT,
					 * earlieradj_amt_book_loading : false,
					 * cy_amt_book_loading : false, earlieradj_amt_book :
					 * vm.existing_chart_data[index].EARLY_ADJ_AMT,
					 * thisadj_amt_book :
					 * vm.existing_chart_data[index].ADJ_AMOUNT,
					 * adj_cy_amt_book : 0, glacct_error_book : '',
					 * thisadj_amt_error_book : '', type : 'E'
					 *
					 * });
					 */

					for ( var z in vm.existing_chart_data_temp) {
						if (vm.existing_chart_data_temp[z].REF_KEY === vm.ref_key) {
							if (vm.existing_chart_data_temp[z].GL_ACCT != undefined
									&& vm.existing_chart_data_temp[z].GL_ACCT != "5555555 - Reportable Transactions") {
								vm.reportableList_book
										.push({
											glacct_book : vm.existing_chart_data_temp[z].GL_ACCT,
											old_gl_acct_key : vm.existing_chart_data_temp[z].GL_ACCT_KEY,
											old_thisadj_amt : vm.existing_chart_data_temp[z].ADJ_AMOUNT,
											cy_amt_book : vm.existing_chart_data_temp[z].CY_AMOUNT,
											earlieradj_amt_book_loading : false,
											cy_amt_book_loading : false,
											earlieradj_amt_book : vm.existing_chart_data_temp[z].EARLY_ADJ_AMT,
											thisadj_amt_book : vm.existing_chart_data_temp[z].ADJ_AMOUNT,
											adj_cy_amt_book : 0,
											glacct_error_book : '',
											thisadj_amt_error_book : '',
											type : 'E'

										});
							} else {
								if (vm.existing_chart_data_temp[z].GL_ACCT == "5555555 - Reportable Transactions") {
									vm.glacct1_book = vm.existing_chart_data_temp[z].GL_ACCT;
									vm.thisadj_amt1_book = vm.existing_chart_data_temp[z].ADJ_AMOUNT;
								}
							}
						}

					}

					vm.adjusted_amt_change_book();
					for ( var s in vm.reportableList_book) {
						vm.reportableList_book[s].adj_cy_amt_book = vm.reportableList_book[s].cy_amt_book
								+ vm.reportableList_book[s].earlieradj_amt_book
								+ vm.reportableList_book[s].thisadj_amt_book;
					}

				}

				vm.save1 = function(form) {

					if ($scope.crudLoading_book) {
						AlertService.add("info",
								"Please wait while we save this request",
								4000);
						return;
					}
					$scope.crudLoading_book = true;
					var headerSettings = [];
					var tempobj_book = {};
					var tempobjStatic_book = {};
					var tempSettings_book = [];
					var temp_gl_acct_book = 0;
					checkData();
					if (vm.rptdescError_book || vm.typeofrptError_book
							|| vm.glacctError_book
							|| vm.thisadjamterror_book) {
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						$scope.crudLoading_book = false;
						throw new ("This is not an error. This is just to abort javascript!");
					}

					for ( var c in vm.reportableList_book) {
						tempobj_book = {};
						tempobjStatic_book = {};
						tempobj_book.TYPE = (vm.type_book === true) ? "E"
								: "I";
						tempobj_book.REF_KEY = (vm.type_book === true) ? vm.ref_key
								: 0;

						tempobj_book.TAX_YEAR = GlobalService.globalParams.tax_year;
						tempobj_book.SCENARIO = GlobalService.globalParams.scenario;
						tempobj_book.JCD_KEY = GlobalService.globalParams.jcd_key;
						tempobj_book.CHART_KEY = vm.rowData.LOCAL_CHART_KEY;

						if (vm.testflag == true) {
							var posting_acct_key = "";
							for ( var cd in vm.glacctsMdataB) {
								if (vm.glacctsMdataB[cd].GL_ACCT == vm.postingTaxTopB) {
									posting_acct_key = vm.glacctsMdataB[cd].GL_ACCT_KEY;
								}
							}

						}

						tempobj_book.LOCAL_ACCT_KEY = (vm.testflag === true) ? posting_acct_key
								: vm.rowData.LOCAL_ACCT_KEY;
						tempobj_book.COMBINATION_KEY = vm.rowData.COMBINATION_KEY;
						/*
						 * tempobj_book.NEW_TAX_SHELTER_REGISTRATION_NUMBER =
						 * vm.tax_shetlter_reg_num_book;
						 * tempobj_book.OLD_TAX_SHELTER_REGISTRATION_NUMBER =
						 * (vm.type_book === true) ?
						 * vm.old_tax_shetlter_reg_num_book :
						 * vm.tax_shetlter_reg_num_book;
						 * tempobj_book.NEW_REPORTABLE_TRANSACTION_DESCRIPTION =
						 * vm.reportable_trans_desc_book;
						 * tempobj_book.OLD_REPORTABLE_TRANSACTION_DESCRIPTION =
						 * (vm.type_book === true) ?
						 * vm.old_reportable_trans_desc_book :
						 * vm.reportable_trans_desc_book;
						 */
						/*
						 * for ( var b in vm.reportable_trans_list) { if
						 * (vm.reportable_trans_list[b].CODE_DESC ==
						 * vm.type_of_reportable_trans_book) {
						 * tempobj_book.NEW_TYPE_REPORTABLE_TRANSACTION =
						 * vm.reportable_trans_list[b].CODE_NAME; } }
						 */
						// tempobj_book.OLD_TYPE_REPORTABLE_TRANSACTION =
						// (vm.type_book === true)
						// ?vm.old_type_of_reportable_trans_book
						// :vm.type_of_reportable_trans_book.CODE_NAME;
						/*
						 * if (vm.reportableList_book[c].type == 'E') { for (
						 * var a in vm.glaccts) { if (vm.glaccts[a].GL_ACCT ==
						 * vm.reportableList_book[c].glacct_book) {
						 * tempobj_book.NEW_GL_ACCT =
						 * vm.glaccts[a].GL_ACCT_KEY; } } } if
						 * (vm.reportableList_book[c].type == 'I') {
						 * tempobj_book.NEW_GL_ACCT =
						 * vm.reportableList_book[c].glacct_book.GL_ACCT_KEY;
						 * temp_gl_acct_book = vm.glaccts[a].GL_ACCT_KEY; }
						 */
						// tempobj_book.OLD_GL_ACCT = (vm.type_book ===
						// true) ? vm.old_glacct_key :temp_gl_acct_book;
						// tempobj_book.OLD_THIS_ADJ_AMT = (vm.type_book ===
						// true) ? test:vm.old_thisadj_amt_book;
						// tempobj_book.NEW_THIS_ADJ_AMT =
						// vm.reportableList_book[c].thisadj_amt_book;
						if (vm.type_book === true) {

							if (vm.reportableList_book[c].type == "I") {
								tempobj_book.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.old_tax_shetlter_reg_num_book;
								tempobj_book.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.old_reportable_trans_desc_book;
								tempobj_book.OLD_TYPE_REPORTABLE_TRANSACTION = vm.old_type_of_reportable_trans_book;
								tempobj_book.OLD_GL_ACCT = vm.reportableList_book[c].glacct_book.GL_ACCT_KEY;
								tempobj_book.OLD_THIS_ADJ_AMT = vm.reportableList_book[c].thisadj_amt_book;
							} else {
								tempobj_book.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.old_tax_shetlter_reg_num_book;
								tempobj_book.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.old_reportable_trans_desc_book;
								tempobj_book.OLD_TYPE_REPORTABLE_TRANSACTION = vm.old_type_of_reportable_trans_book;

								tempobj_book.OLD_GL_ACCT = vm.reportableList_book[c].old_gl_acct_key;
								tempobj_book.OLD_THIS_ADJ_AMT = vm.reportableList_book[c].old_thisadj_amt;

							}

						} else {

							tempobj_book.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num_book;
							tempobj_book.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc_book;
							tempobj_book.OLD_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans_book.CODE_NAME;
							if (vm.testflag == false) {
								tempobj_book.OLD_GL_ACCT = vm.reportableList_book[c].glacct_book.GL_ACCT_KEY;
								tempobj_book.OLD_THIS_ADJ_AMT = vm.reportableList_book[c].thisadj_amt_book;
							}
						}
						tempobj_book.NEW_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num_book;
						tempobj_book.NEW_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc_book;

						if (vm.type_of_reportable_trans_book.CODE_NAME == undefined) {
							for ( var d in vm.reportable_trans_list) {
								if (vm.reportable_trans_list[d].CODE_DESC == vm.type_of_reportable_trans_book) {
									tempobj_book.NEW_TYPE_REPORTABLE_TRANSACTION = vm.reportable_trans_list[d].CODE_NAME;
								}
							}
						} else {
							tempobj_book.NEW_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans_book.CODE_NAME;
						}
						// tempobj_book.NEW_GL_ACCT =
						// (vm.reportableList_book[c].glacct_book.GL_ACCT_KEY
						// == undefined) ?
						// vm.reportableList_book[c].GL_ACCT_KEY
						// :
						// vm.reportableList_book[c].glacct_book.GL_ACCT_KEY;
						// tempobj_book.NEW_THIS_ADJ_AMT =
						// vm.reportableList_book[c].thisadj_amt_book;

						if (vm.reportableList_book[c].glacct_book.GL_ACCT_KEY == undefined) {

							for ( var y in vm.glaccts) {
								if (vm.reportableList_book[c].glacct_book == vm.glaccts[y].GL_ACCT) {
									tempobj_book.NEW_GL_ACCT = vm.glaccts[y].GL_ACCT_KEY
								}
							}
						} else {
							tempobj_book.NEW_GL_ACCT = vm.reportableList_book[c].glacct_book.GL_ACCT_KEY;
						}
						tempobj_book.NEW_THIS_ADJ_AMT = vm.reportableList_book[c].thisadj_amt_book;
						tempSettings_book.push(tempobj_book);

					}
					if (vm.type === true) {

						tempobjStatic_book.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.old_tax_shetlter_reg_num_book;
						tempobjStatic_book.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.old_reportable_trans_desc_book;
						tempobjStatic_book.OLD_TYPE_REPORTABLE_TRANSACTION = vm.old_type_of_reportable_trans_book;

					}

					else {

						tempobjStatic_book.OLD_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num_book;
						tempobjStatic_book.OLD_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc_book;
						tempobjStatic_book.OLD_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans_book.CODE_NAME;

					}

					tempobjStatic_book.NEW_TAX_SHELTER_REGISTRATION_NUMBER = vm.tax_shetlter_reg_num_book;
					tempobjStatic_book.NEW_REPORTABLE_TRANSACTION_DESCRIPTION = vm.reportable_trans_desc_book;

					if (vm.type_of_reportable_trans_book.CODE_NAME == undefined) {
						for ( var d in vm.reportable_trans_list) {
							if (vm.reportable_trans_list[d].CODE_DESC == vm.type_of_reportable_trans_book) {
								tempobjStatic_book.NEW_TYPE_REPORTABLE_TRANSACTION = vm.reportable_trans_list[d].CODE_NAME;
							}
						}
					} else {
						tempobjStatic_book.NEW_TYPE_REPORTABLE_TRANSACTION = vm.type_of_reportable_trans_book.CODE_NAME;
					}

					// tempobjStatic_book.NEW_TYPE_REPORTABLE_TRANSACTION =
					// vm.type_of_reportable_trans_book.CODE_NAME;
					tempobjStatic_book.OLD_GL_ACCT = vm.glacctsrpt[0].RT_ACCT_KEY;
					tempobjStatic_book.NEW_GL_ACCT = vm.glacctsrpt[0].RT_ACCT_KEY;
					tempobjStatic_book.OLD_THIS_ADJ_AMT = vm.thisadj_amt1_book;
					tempobjStatic_book.NEW_THIS_ADJ_AMT = vm.thisadj_amt1_book;
					tempobjStatic_book.REF_KEY = (vm.type_book === true) ? vm.ref_key
							: 0;

					tempobjStatic_book.TAX_YEAR = GlobalService.globalParams.tax_year;
					tempobjStatic_book.SCENARIO = GlobalService.globalParams.scenario;
					tempobjStatic_book.JCD_KEY = GlobalService.globalParams.jcd_key;
					tempobjStatic_book.CHART_KEY = vm.rowData.LOCAL_CHART_KEY;
					tempobjStatic_book.LOCAL_ACCT_KEY = vm.rowData.LOCAL_ACCT_KEY;
					tempobjStatic_book.COMBINATION_KEY = vm.rowData.COMBINATION_KEY;
					tempSettings_book.push(tempobjStatic_book);

					var message = "Book Reportable Adjustments have been successfully saved";
					EPLocalTaxAdjustmentsFactory
							.saveUpdateAdjustmentsDCSBOOKREPORTABLE(
									tempSettings_book, tempSettings_book)
							.then(
									function(result) {

										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage,
													4000);

											$scope.crudLoading_book = false;
										} else {
											AlertService.add("success",
													message, 4000);
											$scope.crudLoading_book = false;
											if (vm.testflag === true) {
												vm.cancel();
											}
											vm.reset1();
											// $uibModalInstance.close();
											/*
											 * var args =
											 * {combination_keys:vm.rowData.COMBINATION_KEY ,
											 * gridFilter: {COMBINATION_KEY:
											 * vm.rowData.COMBINATION_KEY}};
											 * $rootScope.$emit('gridUpdate',
											 * args);
											 */
											// $scope.crudLoading = false;
											// vm.clickNo();
											// init();
										}
									});

				};

				vm.cancel1 = function() {
					$uibModalInstance.dismiss('cancel');
				};

			}
		}
		// /////////////END CTRL

		return controllers;

	});