define(['angular'], function () {
    'use strict';

    var controllers = angular.module('app.EditSytemAccountCtrl', []);

    controllers.controller('EditSytemAccountCtrl', [
        '$rootScope',
        '$scope',
        '$http',
        '$timeout',
        'GlobalService',
        '$uibModalInstance',
        'ModalFactory',
        'AlertService',
        'JsonObjectFactory',
        'SystemGroupObjFactory',
        'rowData',
        'USER_SETTINGS',
        'GENERAL_CONFIG',
        'SystemMappingFactory',
        editSytemAccountCtrl
    ])

    function editSytemAccountCtrl($rootScope, $scope, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, SystemGroupObjFactory, rowData, USER_SETTINGS,GENERAL_CONFIG, SystemMappingFactory) {

    	// TODO:the hard coded jcd_key in this file will be removed once the global params bug is fixed
    	var vm = this;
    	vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
		var lookupData1 = [];
		var lookupData2 = [];
		var lookupData3 = [];
		var lookupData4 = [];
		vm.clientList = [];
		vm.clientList2 = [];
		vm.selectedClientList = [];
		vm.selectedClientList2 = [];
		vm.sysacctloading = true;
		vm.sysdropdownloading = true;
		vm.accountsTableData = [];
		vm.formLookupFlagContent = true;
		vm.starter_ds='';
        function init() {
   		 	getSystemAcctdropdown();
            fetchSystemAcctLookup();
			getFormChartList();
			getCodeDesc();
			getClientDropdownList();
   	 	}
   	 	init();
   	  	$scope.crudLoading = false;
		vm.sys_acc = {};
		vm.orderByField = 'FORM';
		vm.reverseSort = false;
		vm.showDetails = {text:'lookup'};

		vm.sort_key = {'1':0,'2':0,'3':0,'4':0,'5':0};
		vm.lookUpMapping = false;
		
		vm.sysAcctObj = {};
		vm.selectedSysAcc = rowData.SYSTEM_ACCT;
		vm.old_form_acct_key= "";
   	 
		vm.chartacct_data = {
				localacct:rowData.LOCAL_ACCT_DISPLAY,
				chart:rowData.CHART_DISPLAY,
				systemacct:rowData.SYSTEM_ACCT,
				systemacct_des:rowData.SYSTEM_ACCT_DESC,
				acctType:rowData.ACCT_TYPE,
				tdcCode:rowData.TDC_CODE
		};

		var sampleObject = {
			form : '',
			schedule : '',
			line : '',
			column : '',
			section : '',
			part : '',
			form_chart_key : '',
			form_seq_key: ""
		};
		vm.formLineMapping = [Object.assign({},sampleObject)];
		vm.sortBy = function(key,value){
						switch(value){
							case 0:
							vm.sort_key[key] = 1;
							vm.reverseSort = false;
							break;
							case 1:
							vm.sort_key[key] = 2;
							vm.reverseSort = true;
							break;
							case 2:
							vm.sort_key[key] = 0;
							vm.reverseSort = "";
							break;
						}
		}
		/** This function hits the DB and retrieves System as well as Local Data **/
	//
		vm.cancel = function(){
			$uibModalInstance.dismiss('cancel');
		};

		vm.reset = function(){
			$scope.crudLoading = false;
			init();
			vm.chartacct_data = {
					localacct:rowData.LOCAL_ACCT_DISPLAY,
					chart:rowData.CHART_DISPLAY,
					systemacct:rowData.SYSTEM_ACCT,
					systemacct_des:rowData.SYSTEM_ACCT_DESC,
					acctType:rowData.ACCT_TYPE,
					tdcCode:rowData.TDC_CODE
			}
		};

		vm.resetLookup = function(){
			
			vm.formLineMapping =[];
			vm.formLineMapping.push(Object.assign({},sampleObject));
		};

		vm.addNewFormLineMapping = function () {
			vm.formLineMapping.push(Object.assign({},sampleObject));
		};
		
		

		vm.toggleLookup = function () {
			vm.formLineMapping =[];
			vm.formLineMapping.push(Object.assign({},sampleObject))
			vm.lookUpMapping = vm.isToggleClient ? false : !vm.lookUpMapping;
			vm.showDetails.text = 'lookup';
			vm.changeddropdownflag = false;
			vm.isToggleClient=false;
		};

		function fetchSystemAcctData(sysAcctKey){ 
		var params = {
			"action_code": '8n2j2s',
			"tax_year": rowData.TAX_YEAR,
			"jcd_key": 250, //"jcd_key": GlobalService.globalParams.jcd_key,
			"scenario" : GlobalService.globalParams.scenario,
			"sys_acct_key": sysAcctKey//(typeof vm.sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY:vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY
				}
		vm.sysacctloading = true;
		console.log("Input Data fetchSystemAcctData**********::",params);
			return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
				console.log("Response Data ::",data);
				if (data.callSuccess === "1" ) {
					vm.loadSystemAndLocalAccountData = data.jsonObject;
					vm.sysacctloading = false;
					console.log(data.jsonObject,"Check Here Y1");
					console.log(vm.loadSystemAndLocalAccountData,"Check Here Y1");
				}
				else
				{
					if(data.errorMessage === "no access"){
					AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
					vm.userMessage = "ERROR!! Unable to perform Selected Action...";
				}
					else
					{
					AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
					vm.userMessage = "ERROR!! Unable to perform Selected Action...";
					}
				}

			});
		};
			
		function getCodeDesc(){ 
			var params = {
				"action_code": '0cz3ni',
				"tax_year": rowData.TAX_YEAR,
				"scenario" : GlobalService.globalParams.scenario,
				"jcd_key": 250,//"jcd_key": GlobalService.globalParams.jcd_key,
				"code_name": rowData.ACCT_TYPE
					}
			vm.sysacctloading = true;
			console.log("Input Data getCodeDesc**********::",params);
				return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					console.log("Response getCodeDesc ::",data);
					if (data.callSuccess === "1" ) {
						vm.code_desc =  data.jsonObject[0].CODE_DESC;
						vm.sysacctloading = false;
						console.log(data.jsonObject,"Check Here Y1");
						console.log(vm.loadSystemAndLocalAccountData,"Check Here Y1");
					}
					else
					{
						if(data.errorMessage === "no access"){
						AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
						vm.userMessage = "ERROR!! Unable to perform Selected Action...";
					}
						else
						{
						AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
						vm.userMessage = "ERROR!! Unable to perform Selected Action...";
						}
					}

				});
			};
		function  getSystemAcctdropdown(){
				var params = {
					"action_code": '9bappg',
					"tax_year": rowData.TAX_YEAR,
					"screen_key": rowData.SCREEN_KEY,
					"acct_type": rowData.ACCT_TYPES,
					"jcd_key": 250,//"jcd_key": GlobalService.globalParams.jcd_key,
				}
				vm.sysdropdownloading = true;
				return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					if (data.callSuccess === "1") {
						vm.sys_acc_dropdown_data = [];
						for(var index_ in data.jsonObject ){
							vm.sys_acc_dropdown_data.push(data.jsonObject[index_].SYS_DROPDOWN);
						}
						/*vm.sys_acc_dropdown_data_TEMP = data.jsonObject;
						var index = _.findIndex( vm.sys_acc_dropdown_data_TEMP, { 'SYS_ACCT':rowData.SYSTEM_ACCT});
						vm.sys_acc.SYS_ACCT = vm.sys_acc_dropdown_data_TEMP[index];
						vm.sys_acc.SYS_ACCT_TEMP =  vm.sys_acc_dropdown_data_TEMP[index];*/
						vm.CHECK_SYS_ACCT = rowData.ACCT + ' - '+rowData.ACCT_DESC// vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN;
						vm.sysdropdownloading = false;
						fetchSystemAcctData(rowData.ACCT_KEY);

					}else{
						if(data.errorMessage === "no access"){
							AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
							vm.userMessage = "ERROR!! Unable to perform Selected Action...";
						}else{
							AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
							vm.userMessage = "ERROR!! Unable to perform Selected Action...";
						}
					}

				});
		};
			
		function getFormChartList() {
			var params = {
				"action_code": 'wkl7ky',
				"tax_year": GlobalService.globalParams.tax_year,
				"jcd_key": 250//"jcd_key": GlobalService.globalParams.jcd_key,
			}
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
				if (data.callSuccess === "1") {
					vm.formChartList = [];
					vm.formChartList = data.jsonObject;
						
				} else {
					if (data.errorMessage === "no access") {
						AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
						vm.userMessage = "ERROR!! Unable to perform Selected Action...";
					} else {
						AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
						vm.userMessage = "ERROR!! Unable to perform Selected Action...";
					}
				}

			});
		};

		function fetchSystemAcctLookup(){
			var params = {
				"action_code": '2losso',
				"tax_year": rowData.TAX_YEAR,
				"screen_key": rowData.SCREEN_KEY,
				"acct_type": rowData.ACCT_TYPES,
				"jcd_key": 250//"jcd_key": GlobalService.globalParams.jcd_key,
			}
			console.log("Input Data x **********::",params);
			return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
				console.log("Response Data x ::",data);
				if (data.callSuccess === "1" ) {

				vm.loadSystemAcctLookupTemp = data.jsonObject;
					vm.loadLookupForm = [];
					vm.loadLookupSchedule = [];
					vm.loadLookupLine = [];
					vm.loadLookupColumn = [];
					vm.loadLookupSysMapAcct = [];
					vm.loadLookupSysMapAcct1 = [];
					vm.lookupcheckdata = data.jsonObject;
					for(var index_ in data.jsonObject ){
						vm.loadLookupForm.push(data.jsonObject[index_].FORM);
					}
					vm.loadLookupForm = _.uniq(vm.loadLookupForm, JSON.stringify)
					//vm.CHECK_FORM = vm.loadLookupForm_TEMP[-1].FORM;
				}
				else
				{
					if(data.errorMessage === "no access"){
						AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
						vm.userMessage = "ERROR!! Unable to perform Selected Action...";
					}
					else
					{
						AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
						vm.userMessage = "ERROR!! Unable to perform Selected Action...";
					}
				}

			});
		};
			
		vm.formDelete =  function(_item){
			var index = _.findIndex(vm.loadSystemAndLocalAccountData, { 'FORM_ACCT_KEY': _item.FORM_ACCT_KEY });
							//HERE WE WOULD CALL SERVICE DELETE
			var objSettings = [];
			var returnObj = {};
			returnObj.type = 'D';
			returnObj['FORM_ACCT_KEY']  =  _item.FORM_ACCT_KEY 
			returnObj["object_id"]= rowData.object_id
			returnObj['system_acct_key'] = rowData.SYS_ACCT_KEY;
			returnObj['tax_year'] = rowData.TAX_YEAR;
			returnObj['jcd_key'] =  GlobalService.globalParams.jcd_key; 
			returnObj['scenario'] = GlobalService.globalParams.scenario;
			returnObj['screen_key'] = GlobalService.globalParams.screen_key;
			var IRSFormDetails = angular.copy(vm.loadSystemAndLocalAccountData);
		}
		
		vm.formEdit = function(_item,index){
				
			vm.CHECK_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT; 
			vm.SYSTEM_ACCT_KEY = _item.SYSTEM_ACCT_KEY; 
			vm.FORM_ACCT_KEY = _item.FORM_ACCT_KEY; 
			vm.old_form_acct_key = _item.FORM_ACCT_KEY;
			vm.isToggleClient = false;
			vm.showDetails.text = 'edit';
			vm.currentEditedIndex = index;
			
			vm.changeddropdownflag = true;
			vm.lookUpMapping = true;

			vm.CHECK_FORM = _item.FORM.trim();
			vm.CHECK_SCHEDULE = _item.SCHEDULE.trim();
			vm.CHECK_LINE = _item.LINE +" - "+_item.LINE_DESC.trim();
			vm.CHECK_COL = _item.COL;
			vm.CHECK_SECTION = _item.SECTION;
			vm.CHECK_PART = _item.PART;
			vm.CHECK_FORM_CHART = _item.TO_CHART_KEY;


			vm.formLineMapping = [{
				form : vm.CHECK_FORM ,
				schedule : vm.CHECK_SCHEDULE,
				line : vm.CHECK_LINE,
				column : vm.CHECK_COL,
				section : vm.CHECK_SECTION,
				part : vm.CHECK_PART,
				form_chart_key : vm.CHECK_FORM_CHART
			}];


			vm.formEditFlagContent = true;
			var testdata = vm.lookupcheckdata;
			var nexttestLookupDataschedule =[];
			var scheduletestvalue  = testdata.filter(function(object,index){
				if(object["FORM"] == vm.CHECK_FORM ){
						return true;
				}
			});
	
			scheduletestvalue.forEach(function(object,index){
			nexttestLookupDataschedule.push(object["SCHEDULE"]);
			});
			nexttestLookupDataschedule =  _.uniq(nexttestLookupDataschedule, JSON.stringify);
			vm.loadLookupSchedule = nexttestLookupDataschedule;
			var nexttestLookupDataLine =[];
	
			var Linetestvalue  = testdata.filter(function(object,index){
				if(object["FORM"] == vm.CHECK_FORM  && object["SCHEDULE"] == vm.CHECK_SCHEDULE){
						return true;
				}
			});
		
			Linetestvalue.forEach(function(object,index){
				nexttestLookupDataLine.push(object["LINE"]);
				});
			nexttestLookupDataLine =  _.uniq(nexttestLookupDataLine, JSON.stringify);
			vm.loadLookupLine = nexttestLookupDataLine;
//		
			var nexttestLookupDataCol =[];
			var COltestvalue  = testdata.filter(function(object,index){
				if(object["FORM"] == vm.CHECK_FORM  && object["SCHEDULE"] == vm.CHECK_SCHEDULE && object["LINE"] == vm.CHECK_LINE){
						return true;
				}
			});
		
			COltestvalue.forEach(function(object,index){
				nexttestLookupDataCol.push(object["COL"]);
			});
			nexttestLookupDataCol =  _.uniq(nexttestLookupDataCol, JSON.stringify);
			vm.loadLookupColumn= nexttestLookupDataCol;
//
			var nexttestLookupDataSection = [];
			var sectionTestValue = testdata.filter(function(object,index){
				if(object["FORM"] == vm.CHECK_FORM  && object["SCHEDULE"] == vm.CHECK_SCHEDULE && object["LINE"] == vm.CHECK_LINE && object["SECTION"] == vm.CHECK_SECTION){
						return true;
				}
			});
			sectionTestValue.forEach(function(object,index){
				nexttestLookupDataSection.push(object["COL"]);
			});
			nexttestLookupDataSection =  _.uniq(nexttestLookupDataSection, JSON.stringify);
			vm.loadLookupSection= nexttestLookupDataSection;

//
			var nexttestLookupDataPart = [];
			var partTestValue = testdata.filter(function(object,index){
				if(object["FORM"] == vm.CHECK_FORM  && object["SCHEDULE"] == vm.CHECK_SCHEDULE && object["LINE"] == vm.CHECK_LINE && object["SECTION"] == vm.CHECK_SECTION && object["PART"] == vm.CHECK_PART){
						return true;
				}
			});
			partTestValue.forEach(function(object,index){
				nexttestLookupDataPart.push(object["COL"]);
			});
			nexttestLookupDataPart =  _.uniq(nexttestLookupDataPart, JSON.stringify);
			vm.loadLookupPart= nexttestLookupDataPart;			


		}
			
		vm.formAdd = function(option){
			vm.old_form_acct_key = '';
			if(option == 'toggleClient'){
				vm.isToggleClient = true;
				vm.showDetails.text = 'toggleClient';
				vm.lookUpMapping = false;
				vm.showDetails.text = 'lookup';
			}else{
				vm.isToggleClient = false;
				vm.showDetails.text = 'add';
				vm.changeddropdownflag = true;
				vm.formLineMapping =[];
				vm.formLineMapping.push(Object.assign({},sampleObject));
				vm.lookUpMapping = true;
			}						
		}
		
			
		function  validateData(){
			var haveError = false;
			if(vm.isClientSpecific && vm.selectedClientList.length <= 0){
				haveError = true;
				AlertService.add("danger", "Please select atleast 1 client", 3000);
			}
			
			for(var formLine of vm.formLineMapping){
				if(!formLine.form || !formLine.schedule || !formLine.column || !formLine.section || !formLine.part || !formLine.form_chart_key){
					haveError = true;
					AlertService.add("danger", "Please select all possible form mapping details", 3000); 
				} 
			} 
			return haveError;
					
		}

		function  buildJson(){
			var selectedClientsArray = [];
			
			

			vm.sysAcctObj['sys_acct_key'] = rowData.ACCT_KEY;
			vm.sysAcctObj['old_form_acct_key'] = vm.old_form_acct_key;

			vm.sysAcctObj['is_client_specific'] = vm.isClientSpecific ==1 ? true : false;
			
			
			
			vm.sysAcctObj['form_data'] = vm.formLineMapping;
			vm.sysAcctObj['starter_ds'] =  vm.starter_ds;
			
			if(!vm.sysAcctObj.is_client_specific){
					angular.forEach(vm.clientList, function(obj, key) {
					obj.is_active = 'A';
					selectedClientsArray.push(obj.label);
					});
				}else{
					angular.forEach(vm.clientList, function(obj, key) {
						if(obj.is_active == 'A'){
						selectedClientsArray.push(obj.label);
					}
					});
				}
			
			if(vm.selectedClientList.length > 0){
		    	angular.forEach(vm.selectedClientList, function(obj, key) {
	   				  obj.is_active = 'A';
	   				selectedClientsArray.push(obj.label);
	   				});
		    }

			vm.sysAcctObj['selectedClients'] = vm.clientList;
			vm.sysAcctObj['selectedClientsString'] = selectedClientsArray.join(',');
			
					
		}
		
		vm.save = function(){
				
			if(validateData()){
				return ;
			}
			buildJson();
			
			vm.loading = true; 
			SystemMappingFactory.editSytemAccount(vm.sysAcctObj).then(function (data) {
				if (data.callSuccess === 1 ) {
					AlertService.add("success", "Form mapping updated successfully", 3000);
				}else {
					var failedClients = '';
		            	if(data.failedCients.includes(",")){
		            		failedClients =data.failedCients.join();
		            	}else {
		            		failedClients =data.failedCients;
		            	} 
					AlertService.add("danger", "Form mapping failed for " + failedClients +" .Please try again after sometime", 5000);
				}
				vm.toggleLookup();
				vm.isClientSpecific = 0;
				fetchSystemAcctData(rowData.ACCT_KEY);
				vm.loading = false; 
			}); 
			
			
		};
		
		vm.getLookupNext = function(key,nextLookupKey,currentLookupValue){
				var lookupData = angular.copy(vm.lookupcheckdata);
				var a = "SYS_ACCT";
				
				switch(key){
					case 1:
						vm.loadLookupSchedule =  filterDataByKey("FORM",nextLookupKey,lookupData,currentLookupValue);
						break;
					case 2:
						vm.loadLookupLine =  filterDataByKey1("SCHEDULE",nextLookupKey,lookupData1,currentLookupValue);
						break;
					case 3:
						vm.loadLookupColumn =  filterDataByKey2("LINE",nextLookupKey,lookupData2,currentLookupValue);
						vm.loadLookupSysMapAcct = filterDataByKey22("LINE",a,lookupData2,currentLookupValue);
						break;
					case 4:
						vm.loadLookupSection=  filterDataByKey3("COL",nextLookupKey,lookupData3,currentLookupValue);
						break;
					case 5:
						vm.loadLookupPart=  filterDataByKey4("SECTION",nextLookupKey,lookupData4,currentLookupValue);
						break;
				}
			}

		function filterDataByKey(currentLookupKey,nextLookupKey,lookupData,currentLookupValue){
					var nextLookupData = [];
					var index  = _.findIndex(vm.loadSystemAcctLookupTemp, { 'FORM': currentLookupValue })
					vm.FORM_ACCT_KEY = vm.loadSystemAcctLookupTemp[index].FORM_ACCT_KEY;
					var currentLookupFilter = lookupData.filter(function(object,index){
					if(object[currentLookupKey] == currentLookupValue)
					{
							return true;
					}
					});
			
				currentLookupFilter.forEach(function(object,index){
						nextLookupData.push(object[nextLookupKey]);
				});
				nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
				lookupData1 = currentLookupFilter;
				console.log(lookupData1 , "check Here IMPORTANT inside");
				return nextLookupData ;
			}
		
		function filterDataByKey1(currentLookupKey,nextLookupKey,lookupData1,currentLookupValue){
			var nextLookupData = [];
				var currentLookupFilter = lookupData1.filter(function(object,index){
				if(object[currentLookupKey] == currentLookupValue)
				{
						return true;
				}
			});

		currentLookupFilter.forEach(function(object,index){
				nextLookupData.push(object[nextLookupKey]);
		});
		nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
		lookupData2 = currentLookupFilter;
		return nextLookupData ;
		}
		
		function filterDataByKey2(currentLookupKey,nextLookupKey,lookupData2,currentLookupValue){
			var nextLookupData = [];
				var currentLookupFilter = lookupData2.filter(function(object,index){
				if(object[currentLookupKey] == currentLookupValue)
				{
						return true;
				}
			});

		currentLookupFilter.forEach(function(object,index){
				nextLookupData.push(object[nextLookupKey]);
		});
		nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
		lookupData3 = currentLookupFilter;
		return nextLookupData ;
		}

		function filterDataByKey22(currentLookupKey,a,lookupData2,currentLookupValue){
			var nextLookupData = [];
			
			var currentLookupFilter = lookupData2.filter(function(object,index){
				if(object[currentLookupKey] == currentLookupValue)
				{
						return true;
				}
			});

			currentLookupFilter.forEach(function(object,index){
				nextLookupData.push(object[a]);
				
			});
			nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
			return nextLookupData ;
		}
		function filterDataByKey3(currentLookupKey,nextLookupKey,lookupData3,currentLookupValue){
			var nextLookupData = [];
				var currentLookupFilter = lookupData3.filter(function(object,index){
				if(object[currentLookupKey] == currentLookupValue)
				{
						return true;
				}
			});

		currentLookupFilter.forEach(function(object,index){
				nextLookupData.push(object[nextLookupKey]);
		});
		nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
		lookupData4 = currentLookupFilter;
		return nextLookupData ;
		}
		
		function filterDataByKey4(currentLookupKey,nextLookupKey,lookupData4,currentLookupValue){
			var nextLookupData = [];
			var currentLookupFilter = lookupData4.filter(function(object,index){
				if((object[currentLookupKey]).startsWith(currentLookupValue)){
						return true;
				}
			});

			currentLookupFilter.forEach(function(object,index){
					nextLookupData.push(object[nextLookupKey]);
			});
			nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
			var lookupData5 = currentLookupFilter;
			return nextLookupData ;
		}  
		
		vm.formchanged = function(index){
	 		vm.formLineMapping[index].schedule = null;
	 		vm.formLineMapping[index].line = null;
	 		vm.formLineMapping[index].column = null;
	 		vm.formLineMapping[index].section = null;
	 		vm.formLineMapping[index].part = null;				
    };
    	
    vm.schedulechanged = function(index){
    	vm.formLineMapping[index].line = null;
 		vm.formLineMapping[index].column = null;
 		vm.formLineMapping[index].section = null;
 		vm.formLineMapping[index].part = null;	
    };
    vm.linechanged = function(index){
 		vm.formLineMapping[index].column = null;
 		vm.formLineMapping[index].section = null;
 		vm.formLineMapping[index].part = null;	
    };
    
    vm.columnchanged = function(index){
 		vm.formLineMapping[index].section = null;
 		vm.formLineMapping[index].part = null;	
	};
	vm.sectionChanged = function (index){
 		vm.formLineMapping[index].part = null;	
	};
			
		 function getClientDropdownList(){
		   		var arrayLength = vm.clients.length;
		
				for (var i = 0; i < arrayLength; i++) {
					if(vm.clients[i].client_key != 0){ 
						var displayClientObj = {}
						displayClientObj["id"] = vm.clients[i].client_datasource;
						displayClientObj["label"] = vm.clients[i].client_name;
						displayClientObj["is_active"] = 'N';
						console.log(displayClientObj);
						vm.clientList.push(displayClientObj);
					}else{
						vm.starter_ds =  vm.clients[i].client_datasource;3
					}
				}
				vm.loading = false;
	 	  }; 
	 	  
		$scope.yourEvents = {
			onItemSelect: function (item) {
				item.is_active = 'A';
				if (vm.selectedClientList.length > 0) {
					vm.clientsSelected = true;
				}

			},
			onItemDeselect: function (item) {
				item.is_active = 'N';
				if (vm.selectedClientList.length == 0) {
					vm.clientsSelected = false;
				}
			}
		};
		
		vm.taxTypeDDsettings = {
			scrollableHeight: '200px',
			left: '94px',
			scrollable: true,
			enableSearch: true
		};

		vm.removeEntity = function (_index) {
			var item = vm.selectedClientList[_index];
			vm.selectedClientList.splice(_index, 1);
			item.is_active = 'N';
			if (vm.selectedClientList.length == 0) {
				vm.clientsSelected = false;
			}
		}

		$scope.yourEvents2 = {
			onItemSelect: function (item) {
				item.is_active = 'A';
				if (vm.selectedClientList2.length > 0) {
					vm.clientsSelected = true;
				}

			},
			onItemDeselect: function (item) {
				item.is_active = 'N';
				if (vm.selectedClientList2.length == 0) {
					vm.clientsSelected = false;
				}
			}
		};

		vm.removeEntity2 = function (_index) {
			var item = vm.selectedClientList2[_index];
			vm.selectedClientList2.splice(_index, 1);
			item.is_active = 'N';
			if (vm.selectedClientList2.length == 0) {
				vm.clientsSelected = false;
			}
		}

		vm.toggleClient = function (action) {
				if(vm.selectedClientList2.length>0){
					var jsonObj ={};
					var selectedClientsArray =[];
					jsonObj.sys_acct_key = rowData.ACCT_KEY;
					jsonObj.is_active = action;
					jsonObj.starter_ds = vm.starter_ds;
	
	
					angular.forEach(vm.selectedClientList2, function(obj, key) {
							obj.is_active = action;
							selectedClientsArray.push(obj.label);
					});
	
					jsonObj.selectedClients = vm.selectedClientList2;
					jsonObj.selectedClientsString = selectedClientsArray.join(',');
	
					vm.loading = true; 
					SystemMappingFactory.toggleClient(jsonObj).then(function (data) {
						if (data.callSuccess === 1 ) {
							AlertService.add("success", "Action completed", 3000);
						}else {
							var failedClients = '';
	   		            	if(data.failedCients.includes(",")){
                                failedClients =data.failedCients.join();
	   		            	}else {
	   		            		failedClients =data.failedCients;
	   		            	} 
							AlertService.add("danger", "Request failed. Please try again after sometime", 5000);
						}
						vm.loading = false; 
					}); 
	
			  }else {
				AlertService.add("danger", "Please select atleast 1 client", 5000);
			  }
		}
    }


    return controllers;
});