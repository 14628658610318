
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.1065ScheduleM2Service',[])

    .factory('F1065ScheduleM2ServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        var f1065ScheduleM2ServiceFactory = {};
	        f1065ScheduleM2ServiceFactory.url = {};
	        f1065ScheduleM2ServiceFactory.url.defaults_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=to8rt3";
	
	
	        f1065ScheduleM2ServiceFactory.saveSchdM2 = function(_data,Details){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "scdM2_1065_save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: f1065ScheduleM2ServiceFactory.url.defaults_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        return f1065ScheduleM2ServiceFactory
    }])

    return services;

});