define([
    'angular',

    './QrevarianceYoYController',
    './QrevarianceYoYService',
    './QreVarianceYoYAutomation',
    './QreCreditAllocation'



], function () {
    'use strict';
    return angular.module('app.qrevarianceYoY', ['app.qrevarianceYoYController','app.qrevarianceYoYService','app.qrevarianceYoYAutomation','app.qrecreditAllocation'] );


});