define([
    'angular',
    './PartnerMaintenanceControllers',
    './PartnerMaintenanceServices'


], function () {
    'use strict';
    return angular.module('app.mgmtPartners', ['app.PartnerMaintenanceControllers','app.PartnerMaintenanceServices'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {


        }]);

});