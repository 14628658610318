define([
	'angular'
 
 ], function () {
	'use strict';
 
	var services =  angular.module('app.firDiagnosticsService',[])
		 .factory("FirDiagnosticsServiceFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
		  function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
				
			  var returnObj = {};
				var FirDiagnosticsServiceFactory ={};
				FirDiagnosticsServiceFactory.url ={};
				
				
				FirDiagnosticsServiceFactory.url.saveFIRDiagnostics = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=7edm8n";
			 
			   
				//saveFirDiagnosticsAction
				FirDiagnosticsServiceFactory.saveFIRDiagnosticsAction = function(_callName ,_data){
					console.log("data", _data);
					console.log("callname",_callName);
					if(_callName == 'saveFirDiagnosticsAction')
					{
					   
						//for old grid save part starts here............................
						if(( _data.rowData.COMMON_EQUITY_ACTION == undefined && angular.isDefined(_data.rowData.COMMON_EQUITY_COMMENT) ) || (_data.rowData.PREFERRED_ACTION == undefined && angular.isDefined(_data.rowData.PREFERRED_COMMENT)) || (_data.rowData.APIC_ACTION == undefined && angular.isDefined(_data.rowData.APIC_COMMENT)) )
						{
							_data.rowData.COMMON_EQUITY_COMMENT = (_data.oldRowData.COMMON_EQUITY_COMMENT == undefined) ? undefined  : _data.oldRowData.COMMON_EQUITY_COMMENT;
							_data.rowData.PREFERRED_COMMENT = (_data.oldRowData.PREFERRED_COMMENT == undefined) ? undefined  : _data.oldRowData.PREFERRED_COMMENT;
							_data.rowData.APIC_COMMENT = (_data.oldRowData.APIC_COMMENT == undefined) ? undefined  : _data.oldRowData.APIC_COMMENT;

							var intr=setInterval(function(){ 
								AlertService.add("error", "Please First select the Action");
							   clearInterval(intr);
							   $rootScope.$apply();
							},1000);
							return false;
						}
						else if(( _data.rowData.COMMON_EQUITY_ACTION != undefined && _data.rowData.COMMON_EQUITY_ACTION == "" && angular.isDefined(_data.rowData.COMMON_EQUITY_COMMENT) ) 
							|| (_data.rowData.PREFERRED_ACTION != undefined && _data.rowData.PREFERRED_ACTION == "" && angular.isDefined(_data.rowData.PREFERRED_COMMENT)) 
							|| (_data.rowData.APIC_ACTION != undefined && _data.rowData.APIC_ACTION == "" && angular.isDefined(_data.rowData.APIC_COMMENT)) )
						{
							_data.rowData.COMMON_EQUITY_ACTION = (_data.oldRowData.COMMON_EQUITY_ACTION == undefined) ? undefined  : _data.oldRowData.COMMON_EQUITY_ACTION;
							_data.rowData.PREFERRED_ACTION = (_data.oldRowData.PREFERRED_ACTION == undefined) ? undefined  : _data.oldRowData.PREFERRED_ACTION;
							_data.rowData.APIC_ACTION = (_data.oldRowData.APIC_ACTION == undefined) ? undefined  : _data.oldRowData.APIC_ACTION;
							
							var intr=setInterval(function(){ 
								AlertService.add("error", "You cannot remove Action first, please first remove comments.");
							   clearInterval(intr);
							   $rootScope.$apply();
							},1000);
							return false;
						}
						//ends here..........................
						else if(_data.rowData.GRID_TYPE == 'EQUITY' && (_data.rowData.ACTION == undefined || _data.rowData.ACTION == null || _data.rowData.ACTION == "") && angular.isDefined(_data.rowData.COMMENTS) )
						{
						
						 var error_message = "";
						 if(_data.rowData.ACTION == undefined && angular.isDefined(_data.rowData.COMMENTS)  )
							{
								error_message = "Please First select the Action";
							}
							else if( _data.rowData.ACTION != undefined && _data.rowData.ACTION == "" && angular.isDefined(_data.rowData.COMMENTS))
							{
								error_message = "You cannot remove Action first, please first remove comments."
							}
							_data.rowData.COMMENTS = (_data.oldRowData.COMMENTS == undefined) ? undefined  : _data.oldRowData.COMMENTS;
							_data.rowData.ACTION = (_data.oldRowData.ACTION == undefined) ? undefined  : _data.oldRowData.ACTION;
						 var intr=setInterval(function(){ 
							 AlertService.add("error", error_message);
							clearInterval(intr);
							$rootScope.$apply();
						 },1000);
						 return false;
						}
						else 
						{
							var jsonSettings = [{
								 
								 COMBINATION_KEY:_data.rowData.COMBINATION_KEY,
								 TP_KEY :(_data.rowData.TP_KEY == undefined ?0 : _data.rowData.TP_KEY == null ? 0 : _data.rowData.TP_KEY) 
							 }];
							   
							   var jsonObj = [];
							 if(_data.rowData.GRID_TYPE == 'EQUITY' || _data.rowData.GRID_TYPE == 'LOAN')
							 {
								 //NEW CODE FOR NEW TWO GRIDS
								 if( _data.rowData.GRID_TYPE == 'EQUITY' && angular.isDefined(_data.rowData.ACTION) )
									 {
										 var tempObj = {
												 DIAG_TYPE : _data.rowData.DIAG_TYPE,
												 ACTION_ID : parseInt(_data.rowData.ACTION.split("-")[0]),
												 COMMENTS : (_data.rowData.COMMENTS == undefined) ? null : _data.rowData.COMMENTS
										 }
										 jsonObj.push(tempObj);
									 }
									 if( _data.rowData.GRID_TYPE != 'EQUITY')
									 {
										 var tempObj = {
											 DIAG_TYPE : _data.rowData.DIAG_TYPE,
											 ACTION_ID : null,
											 COMMENTS : (_data.rowData.COMMENTS == undefined) ? null : _data.rowData.COMMENTS
											 }
											 jsonObj.push(tempObj);
									 }
							}
 //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
						 //for old grid save part starts here............................
							  else
							  {	 
							//for equity 
								if((_data.oldRowData.COMMON_EQUITY_ACTION == undefined && angular.isDefined(_data.rowData.COMMON_EQUITY_ACTION)) ||  (_data.oldRowData.COMMON_EQUITY_COMMENT == undefined && angular.isDefined(_data.rowData.COMMON_EQUITY_COMMENT)) )
								 {
									var tempObj = {
										 DIAG_TYPE : 'EQUITY',
										 ACTION_ID : parseInt(_data.rowData.COMMON_EQUITY_ACTION.split("-")[0]),
										 COMMENTS : (_data.rowData.COMMON_EQUITY_COMMENT == undefined) ? null : _data.rowData.COMMON_EQUITY_COMMENT
									}
									jsonObj.push(tempObj);
								 }
								else if(_data.oldRowData.COMMON_EQUITY_ACTION != _data.rowData.COMMON_EQUITY_ACTION || _data.oldRowData.COMMON_EQUITY_COMMENT != _data.rowData.COMMON_EQUITY_COMMENT)
								{
									var tempObj = {
											 DIAG_TYPE : 'EQUITY',
											 ACTION_ID : parseInt(_data.rowData.COMMON_EQUITY_ACTION.split("-")[0]),
											 COMMENTS : (_data.rowData.COMMON_EQUITY_COMMENT == undefined) ? null : _data.rowData.COMMON_EQUITY_COMMENT
										}
										jsonObj.push(tempObj);
								}
						 //for preferred   
								if((_data.oldRowData.PREFERRED_ACTION == undefined && angular.isDefined(_data.rowData.PREFERRED_ACTION)) ||  (_data.oldRowData.PREFERRED_COMMENT == undefined && angular.isDefined(_data.rowData.PREFERRED_COMMENT)) )
								 {
									var tempObj = {
										 DIAG_TYPE : 'PREFERRED',
										 ACTION_ID : parseInt(_data.rowData.PREFERRED_ACTION.split("-")[0]),
										 COMMENTS : (_data.rowData.PREFERRED_COMMENT == undefined) ? null : _data.rowData.PREFERRED_COMMENT
									}
									jsonObj.push(tempObj);
								 }
								else if(_data.oldRowData.PREFERRED_ACTION != _data.rowData.PREFERRED_ACTION || _data.oldRowData.PREFERRED_COMMENT != _data.rowData.PREFERRED_COMMENT)
								{
									var tempObj = {
											 DIAG_TYPE : 'PREFERRED',
											 ACTION_ID : parseInt(_data.rowData.PREFERRED_ACTION.split("-")[0]),
											 COMMENTS : (_data.rowData.PREFERRED_COMMENT == undefined) ? null : _data.rowData.PREFERRED_COMMENT
										}
										jsonObj.push(tempObj);
								}
								
						 // for APIC	   
								if((_data.oldRowData.APIC_ACTION == undefined && angular.isDefined(_data.rowData.APIC_ACTION)) ||  (_data.oldRowData.APIC_COMMENT == undefined && angular.isDefined(_data.rowData.APIC_COMMENT)) )
								 {
									var tempObj = {
										 DIAG_TYPE : 'APIC',
										 ACTION_ID : parseInt(_data.rowData.APIC_ACTION.split("-")[0]),
										 COMMENTS : (_data.rowData.APIC_COMMENT == undefined) ? null : _data.rowData.APIC_COMMENT
									}
									jsonObj.push(tempObj);
								 }
								else if(_data.oldRowData.APIC_ACTION != _data.rowData.APIC_ACTION || _data.oldRowData.APIC_COMMENT != _data.rowData.APIC_COMMENT)
								{
									var tempObj = {
											 DIAG_TYPE : 'APIC',
											 ACTION_ID : parseInt(_data.rowData.APIC_ACTION.split("-")[0]),
											 COMMENTS : (_data.rowData.APIC_COMMENT == undefined) ? null : _data.rowData.APIC_COMMENT
										}
										jsonObj.push(tempObj);
								}
								
						 ///for Loan Comment
								if(_data.oldRowData.LOAN_COMMENT == undefined && angular.isDefined(_data.rowData.LOAN_COMMENT) )
								 {
									var tempObj = {
										 DIAG_TYPE : 'LOAN',
										 ACTION_ID : null,
										 COMMENTS : (_data.rowData.LOAN_COMMENT == undefined) ? null : _data.rowData.LOAN_COMMENT
									}
									jsonObj.push(tempObj);
								 }
								else if(_data.oldRowData.LOAN_COMMENT != _data.rowData.LOAN_COMMENT)
								{
									var tempObj = {
											 DIAG_TYPE : 'LOAN',
											 ACTION_ID : null,
											 COMMENTS : (_data.rowData.LOAN_COMMENT == undefined) ? null : _data.rowData.LOAN_COMMENT
										}
										jsonObj.push(tempObj);
								}
								
						 ///for Borrow Comment
								if(_data.oldRowData.BORROW_COMMENT == undefined && angular.isDefined(_data.rowData.BORROW_COMMENT) )
								 {
									var tempObj = {
										 DIAG_TYPE : 'BORROW',
										 ACTION_ID : null,
										 COMMENTS : (_data.rowData.BORROW_COMMENT == undefined) ? null : _data.rowData.BORROW_COMMENT
									}
									jsonObj.push(tempObj);
								 }
								else if(_data.oldRowData.BORROW_COMMENT != _data.rowData.BORROW_COMMENT)
								{
									var tempObj = {
											 DIAG_TYPE : 'BORROW',
											 ACTION_ID : null,
											 COMMENTS : (_data.rowData.BORROW_COMMENT == undefined) ? null : _data.rowData.BORROW_COMMENT
										}
										jsonObj.push(tempObj);
								}
							 //ends here.........................................................
							 }
								console.log(jsonObj);
								
							
								
								var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
								var params = filterParams;
								params = _.merge({},  GlobalService.globalParams , params);
								params = _.merge(GlobalService.globalFilterValues , params);
								//params.jcd_key =  (GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key
								//params = _.merge({}, GlobalService.globalParams, filterParams);
								params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
								params = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), params);
								params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
								var checkLockedbj = [{"combination_key":_data.rowData.COMBINATION_KEY,"scenario_key":filterParams.scenario,"tax_year":filterParams.tax_year}];
								params = _.extend({checkLockedbj: JSON.stringify(checkLockedbj)}, params);
									console.log("params---------------", params);
									//7edm8n	
									 var promise = $http({
										   method: "POST",
										   url: FirDiagnosticsServiceFactory.url.saveFIRDiagnostics,
										   data: params
									  }). then(function (response){
											  console.log(response.data);
											  if(response.data.callSuccess === "1"){
												AlertService.add("success", "Data saved Successfully.",3000);
												var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
															 $rootScope.$emit('gridUpdate', args);
											}else{
												//AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
											 AlertService.add("error", response.data.errorMessage, 4000);
											}
											  
											var args = {combination_keys: _data.rowData.COMBINATION_KEY,gridFilter: {combination_keys: _data.rowData.COMBINATION_KEY}};
									   $rootScope.$emit('gridRefresh', args);
									   
											  return response.data;
											  })
										  
										  return promise;		          	 
						 }
					 }
				 }
				   
		return FirDiagnosticsServiceFactory;
			   }]);
 
		   
		   return services;
		});