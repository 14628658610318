define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.gridCellAuditService',[])

        .factory("GridCellAuditFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory',
        function( $q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory) {
            var factory = {};

            factory.getCellAuditByContext = function(_url,_params){
                var promise = $http({
                    method: "post",
                    url: _url,
                    data: _params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };
            return factory;

        }])

        return services;


    });