define([
    'angular',
    './entityUpdateController',
    './entityUpdateService',


], function () {
    'use strict';
    return angular.module('app.entityUpdate', ['app.entityUpdateController', 'app.entityUpdateService'] );


});