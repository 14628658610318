define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.gtapsCommonTemplateService',[])
       .factory("gtapsCommonTemplateFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   		,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var gtapsCommonTemplateFactory ={};
	           gtapsCommonTemplateFactory.url ={};
	           gtapsCommonTemplateFactory.url.getGtapsPtiSummaryReportData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=vz4pf4";//Action id 1194
			 
			   gtapsCommonTemplateFactory.url.getGtapsPtepSummaryReportData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=8m6hpz";


		       gtapsCommonTemplateFactory.getGtapsPtiSummaryReportData = function(passedParams) {
					var params = {"process_name":"GTAPS Get CFC's with Recapture Accounts"};
				   params = _.merge({},  passedParams, params);
		           var promise = $http({
		        	   method: "post",
		               url: gtapsCommonTemplateFactory.url.getGtapsPtiSummaryReportData,
		               data: params
		           }).then(function (response) {
		               //var status = response.status;
		        	   gtapsCommonTemplateFactory.statusPti = response.data;
		               return response.data;
		           });
		
		           return promise;
			   };
			   
			   gtapsCommonTemplateFactory.getGtapsPtepSummaryReportData = function(passedParams) {
				var params = {};
				params = _.merge({},  passedParams, params);
					var promise = $http({
						method: "post",
						url: gtapsCommonTemplateFactory.url.getGtapsPtepSummaryReportData,
						data: params
					}).then(function (response) {
						//var status = response.status;
						gtapsCommonTemplateFactory.statusPtep = response.data;
						return response.data;
					});
			
					return promise;
				};
           
	           return gtapsCommonTemplateFactory;
       }]);

   	  return services;
});