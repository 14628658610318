define([
    'angular'
], function () {
    'use strict';
    angular.module('app.SystemLockUnlockController', [])
        .controller('SystemLockUnlockCtrl', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'SystemLockUnlockFactory', 'AlertService', SystemLockUnlockCtrl]);

    function SystemLockUnlockCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, SystemLockUnlockFactory, AlertService) {
        var vm = this;
        
        vm.savingLock = false;
        // vm.rollingOver = false;
        //get system lock status
        SystemLockUnlockFactory.getDSTSystemLockUnlockStatus().then(
            () => {
                vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;
            }
        );

        vm.saveLockUnlock = function () {
            vm.savingLock = true;
            var lockSystem = vm.isLocked === "0" ? "1": "0";
            SystemLockUnlockFactory.saveSystemLockUnlock(lockSystem).then(
                (response) => {
                    if (response && response.callSuccess === '1') {
                        AlertService.add('success', 'System ' + (lockSystem === "1" ? 'Locked': 'Unlocked') + ' Successfully!', 4000);
                        vm.savingLock = false;
                        vm.isLocked = lockSystem; SystemLockUnlockFactory.isLocked = lockSystem;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                    vm.savingLock = false;
                }
            );
        }

        // vm.rollOverProcess = function () {
        //     console.log("roll over process");
        // }

    }
}
);
