define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.ecConfigurationActionsController', [])
    .controller('ecConfigurationCreateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecConfigurationActionsService','$filter', ecConfigurationCreateActionController])
    .controller('ecConfigurationUpdateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecConfigurationActionsService', ecConfigurationUpdateActionController]);
 
    function ecConfigurationCreateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,ecConfigurationActionsService, $filter){
        var vm = this;
        
        vm.modalTitle = "EC Configuration";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy',
            onClose: function(dateText, inst) {   
            }
        };
 		
        vm.configurationEntity_bckup = {
            CONFIG_ITEM:"",
            CONFIG_VALUE:"",
            FROM_YEAR:"",
            TO_YEAR:""
        };

        vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0] ? $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE : 'RAF';


		if (vm.isIssueKeyEnabled === undefined) {
			if (vm.scenarioCode !== undefined) {
				if (vm.scenarioCode === 'RAF') {
					vm.isIssueKeyEnabled = 'N';
				}
				else {
					vm.isIssueKeyEnabled = 'Y';
				}
			}
		}

        
        vm.configurationEntity_bckup.FROM_YEAR = new Date(workspaceFactory.activeScreen.filters.getFilterParams().tax_year - 1, '0', '1');
        vm.configurationEntity_bckup.TO_YEAR =new Date(9999+"", '0', '1');
        vm.configurationEntity = angular.copy(vm.configurationEntity_bckup);


        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
        };
		vm.init();

        vm.isValid = function(){
            if(vm.configurationEntity.CONFIG_ITEM == null || 
                vm.configurationEntity.CONFIG_ITEM == undefined || 
                vm.configurationEntity.CONFIG_ITEM == ""){
                     vm.configurationEntity.CONFIG_ITEM_NOT_VALID = true;
                     return false;
            }  
            if(vm.configurationEntity.FROM_YEAR == null || 
                vm.configurationEntity.FROM_YEAR == undefined || 
                vm.configurationEntity.FROM_YEAR == ""){
                     vm.configurationEntity.FROM_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.configurationEntity.TO_YEAR == null || 
                vm.configurationEntity.TO_YEAR == undefined || 
                vm.configurationEntity.TO_YEAR == ""){
                     vm.configurationEntity.TO_YEAR_NOT_VALID = true;
                     return false;
            }            
            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
            };
            jsonSettings = JSON.stringify(jsonSettings);
            
            let jsonObj = angular.copy(vm.configurationEntity);
            jsonObj.FROM_YEAR = jsonObj.FROM_YEAR.getFullYear().toString();
            jsonObj.TO_YEAR = jsonObj.TO_YEAR.getFullYear().toString();
            jsonObj = JSON.stringify([jsonObj]);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=1e7w72";
            params.is_issue_key_enabled = vm.isIssueKeyEnabled;
            params.scenarioCode = vm.scenarioCode;
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "EC Configuration created successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.upper = function(configItem) {
            vm.configurationEntity.CONFIG_ITEM = configItem.toUpperCase();
        }

        vm.reset = function (){
			vm.configurationEntity = angular.copy(vm.configurationEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function ecConfigurationUpdateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, entityClassificationActionsService){
        var vm = this;

        vm.modalTitle = "EC Configuration";
		vm.showFormLoader = true;


        vm.showEntityLoader = false;
        vm.isSavingUpdate = false;

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy',
            onClose: function(dateText, inst) { 
                
            }
        };
 		
        vm.configurationEntity_bckup = gridData.rowData;
        vm.configurationEntity_bckup.FROM_YEAR = new Date(gridData.rowData.FROM_YEAR,'0','1');
        vm.configurationEntity_bckup.TO_YEAR = new Date(gridData.rowData.TO_YEAR,'0','1');
        vm.configurationEntity = angular.copy(vm.configurationEntity_bckup);
		
        vm.isValid = function(){
            if(vm.configurationEntity.FROM_YEAR == null || 
                vm.configurationEntity.FROM_YEAR == undefined || 
                vm.configurationEntity.FROM_YEAR == "" || vm.configurationEntity.FROM_YEAR == "Invalid Date"){
                     vm.configurationEntity.FROM_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.configurationEntity.TO_YEAR == null || 
                vm.configurationEntity.TO_YEAR == undefined || 
                vm.configurationEntity.TO_YEAR == "" || vm.configurationEntity.TO_YEAR == "Invalid Date"){
                     vm.configurationEntity.TO_YEAR_NOT_VALID = true;
                     return false;
            }            
            return true;
        }
        
        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSavingUpdate = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
			};
            jsonSettings = JSON.stringify(jsonSettings);
            let jsonObj = angular.copy(vm.configurationEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            jsonObj.IS_UPDATE = 'True';
            jsonObj.FROM_YEAR = jsonObj.FROM_YEAR.getFullYear();
            jsonObj.TO_YEAR = jsonObj.TO_YEAR.getFullYear();
            jsonObj = JSON.stringify(jsonObj);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=je5ozq";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "EC Configuration Updated successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.reset = function (){
			vm.configurationEntity = angular.copy(vm.configurationEntity_bckup);
		};

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };
    }
    return controllers;
});
