
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.FTC1065K2DataEntryService',[])

        .factory("FTC1065K2DataEntryFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, workspaceFactory, USER_SETTINGS, $rootScope) {
            var FTC1065K2DataEntryFactory = {};           
            FTC1065K2DataEntryFactory.url = {};
            //zr6n2u works perfectly good, but with merge statement
            //FTC1065K2DataEntryFactory.url.save_data = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zr6n2u";
            FTC1065K2DataEntryFactory.url.save_data = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=bixi9s";
            
            FTC1065K2DataEntryFactory.saveData =  function(_data,Details){
            	console.log("===============     Calling Service using FTC1065K2DataEntryFactory  =========================", _data);
                
            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                var isIssueIdEnabled =  GlobalService.isIssueIDEnabled(filterParams.tax_year,filterParams.scenario);      

                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);

                setLockObj(filterParams.p_filer_key);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params = _.merge({}, isIssueIdEnabled, params);    
                
                //params =  _.extend({ind:'U'}, params);
                console.log("Save params :",params);
            	
                var promise = $http({
                    method: "post",
                    url: FTC1065K2DataEntryFactory.url.save_data,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            function setLockObj(filer_combination_key) {
                var checkLockObj =[];
                checkLockObj.push({combination_key:filer_combination_key});
                var lockObj = {};
                lockObj.lockingObj=checkLockObj;	
                if(lockObj){
                    GlobalService.modalLockFilters(lockObj.lockingObj); /*  for locking Object */
                }
            }
            
            return FTC1065K2DataEntryFactory;
        }])


    return services;


});