define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F1065ScheduleM3Ctrl', [])

    	.directive("limitToMax", function() {
		return {
			link: function(scope, element, attributes) {
				element.on("keydown keyup", function(e) {
					if (Number(element.val()) > Number(attributes.max) &&
					  e.keyCode != 46 // delete
					  &&
					  e.keyCode != 8 // backspace
					) {
						e.preventDefault();
						element.val(attributes.max);
					}
					});
				}
			};
		})

        .controller('F1065ScheduleM3Controller', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','$filter',F1065ScheduleM3Controller]);
    	function F1065ScheduleM3Controller(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, $filter) {
        var vm = this;
        vm.modalTitle = "Part I Financial Information and Net Income (Loss) Reconciliation";
        vm.originalData = [];
        vm.leId = rowData.LEID;
        vm.formName = '1120PC';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';
        vm.isSaving = false;
        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME
        };

        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = vm.modalTitle + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';
        
        var saveParamDtls = [];
        var tempDataObj = {};
        $scope.scheduleM3FormView = {rows:[]};
        vm.showFormLoader = true;
        vm.partnerData = [];
        vm.dataLine = {
            line_5a : [],
            line_5b : [],
            line_6a : [],
            line_6b : [],
            line_7a : [],
            line_7b : [],
            line_8 : [],
            line_9 : [],
            line_10 : []
        };

        function fetchSchIrecords() {
            var params = { "action_id": 31259, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO_KEY, "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ "combination_key": rowData.COMBINATION_KEY, "schedule_name": "M3", "part_no": "P1"};

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
                for(var i=0;i<data.irsForm.formItemList.length;i++){
                    saveParamDtls = [];
                    tempDataObj = {};
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    var rows = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
                    tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
                    tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;
                    if(rows > 2){
                        iterateArray(rows, data.irsForm.formItemList[i].dataRowList[0].dataCellList);
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    tempDataObj.saveParams = saveParamDtls;
                    $scope.scheduleM3FormView.rows.push(tempDataObj);
                }
                $scope.scheduleM3FormView.rows[0].value = rowData.LE_NAME;
                $scope.scheduleM3FormView.rows[0].value_user_input = rowData.EIN;
                vm.originalData = angular.copy($scope.scheduleM3FormView);              
                initFormView($scope.scheduleM3FormView);
                vm.showFormLoader = false;
            });
        };

        var getCheckBoxValue = function(rowNum, val){
            if(rowNum == "A" || rowNum == "D" || rowNum == 'E' || rowNum == '4b1' || rowNum == '4b2' || rowNum == '4b3' || rowNum == '4b4'){
                if(val === "true"){
                    return true;
                }else{
                    return false;
                }
            }else if(rowNum == "2b" || rowNum == "2e"){
                return new Date();
            }else{
            	return 0;
            }
        };

        var iterateArray = function(count, array){
            if(count == 3){
                tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                if(array[2].value == null){
                    tempDataObj.value = getCheckBoxValue(tempDataObj.num, null);
                }else if(array[2].value === "true"){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "true");
                }else if (array[2].value === "false"){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "false");
                }else if (array[2].value === "0"){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "0");
                }else if (array[2].value === 'NA'){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "NA");
                }else {
                	if(tempDataObj.num == "2b" || tempDataObj.num == "2e"){
		                tempDataObj.value = new Date(array[2].value);
		            }else{
		            	tempDataObj.value = array[2].value ? parseFloat(array[2].value) : 0;
		            }
                }
                if(tempDataObj.num == 'D'){
                    tempDataObj.JSON_DATA = vm.partnerData;
                }
            }else if (count > 4 && count < 9){
                tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                tempDataObj.value = array[2].value ? parseFloat(array[2].value) : 0;
                var temp_columns = [];
                angular.forEach(array, function (row, key) {
                    if(key > 2){
                        var col_name = row.key.split('~')[0];
                        temp_columns.push(col_name);
                    }
                });
                tempDataObj.table_columns = temp_columns;
                if(tempDataObj.num == '5a'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_5a;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.NetIncomeLossAmt)){
                                count += row.NetIncomeLossAmt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else if(tempDataObj.num == '5b'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_5b;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.NetIncomeLossAmt)){
                                count += row.NetIncomeLossAmt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else if(tempDataObj.num == '6a'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_6a;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.NetIncomeLossAmt)){
                                count += row.NetIncomeLossAmt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else if(tempDataObj.num == '6b'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_6b;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.NetIncomeLossAmt)){
                                count += row.NetIncomeLossAmt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else if(tempDataObj.num == '7a'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_7a;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.NetIncomeLossAmt)){
                                count += row.NetIncomeLossAmt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else if(tempDataObj.num == '7b'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_7b;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.NetIncomeLossAmt)){
                                count += row.NetIncomeLossAmt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else if(tempDataObj.num == '8'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_8;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.Amt)){
                                count += row.Amt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else if(tempDataObj.num == '9'){
                    tempDataObj.JSON_DATA = vm.dataLine.line_9;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.Amt)){
                                count += row.Amt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }else{
                    tempDataObj.JSON_DATA = vm.dataLine.line_10;
                    tempDataObj.tableCheckBox = tempDataObj.JSON_DATA.length > 0 ? true : false;
                    if(tempDataObj.tableCheckBox){
                        var count = 0;
                        angular.forEach(tempDataObj.JSON_DATA, function (row, key) {
                            if(!isNaN(row.Amt)){
                                count += row.Amt;    
                            }
                        });
                        tempDataObj.unallocatedAmt = tempDataObj.value - count;
                    }else{
                        tempDataObj.unallocatedAmt = 0;
                    }
                }
            }else{
                if(tempDataObj.num == "B" || tempDataObj.num == "C" || tempDataObj.num == "4b5"){
                    tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                    tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                    tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                    tempDataObj.value = array[2].value;
                    tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                    tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                    tempDataObj.attrib_order_b = array[3].key.split('~')[2];
                    tempDataObj.value_user_input = array[3].value;
                    if(tempDataObj.value == null){
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }else if(tempDataObj.value === "true"){
                        tempDataObj.value = true;
                        tempDataObj.conditionalField = false;
                    }else if (tempDataObj.value === "false"){
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }else if (tempDataObj.value === "0"){
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }else {
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }
                }else if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == "1c" || tempDataObj.num == "3a" || tempDataObj.num == "3b"){
                    tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                    tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                    tempDataObj.attrib_order_a = array[2].key.split('~')[2];

                    tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                    tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                    tempDataObj.attrib_order_b = array[3].key.split('~')[2];

                    if(array[2].value === "true"){
                        tempDataObj.value = true;
                    }else if(array[3].value === "true"){
                        tempDataObj.value = false;
                    }else{
                        tempDataObj.value = 'NA';
                    }
                }else{
                    tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                    tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                    tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                    tempDataObj.value = array[2].value;
                    tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                    tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                    tempDataObj.attrib_order_b = array[3].key.split('~')[2];
                    tempDataObj.value_user_input = array[3].value;
                }
            }
        };

        function fetchSubTableRecords(){      
            var params = {
                combination_key: rowData.COMBINATION_KEY,
                schedule: "M3"
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=z57kmr', params).then(function (data) {
                angular.forEach(data.jsonObject, function (row, key) {
                    if(row.LINE_NO == 'D1'){
                        row.DATA_ATTRIB_C = parseFloat(row.DATA_ATTRIB_C);
                        vm.partnerData.push(row);
                    }else if(row.LINE_NO == '5a'){
                        row.loading = false;
                        row.showList = false;
                        row.noRecords = false;
                        row.BusinessNameLine1Txt = row.BusinessNameLine1Txt + row.BusinessNameLine2Txt;
                        row.NetIncomeLossAmt = parseFloat(row.NetIncomeLossAmt);
                        row.EIN = row.EIN ? row.EIN : row.MissingEINReasonCd;
                    	vm.dataLine.line_5a.push(row);
                	}else if(row.LINE_NO == '5b'){
                        row.loading = false;
                        row.showList = false;
                        row.noRecords = false;
                        row.BusinessNameLine1Txt = row.BusinessNameLine1Txt + row.BusinessNameLine2Txt;
                        row.NetIncomeLossAmt = parseFloat(row.NetIncomeLossAmt);
                        row.EIN = row.EIN ? row.EIN : row.MissingEINReasonCd;
                        vm.dataLine.line_5b.push(row);
                    }else if(row.LINE_NO == '6a'){
                        row.loading = false;
                        row.showList = false;
                        row.noRecords = false;
                        row.NetIncomeLossAmt = parseFloat(row.NetIncomeLossAmt);
                        row.EIN = row.EIN ? row.EIN : row.MissingEINReasonCd;
                        vm.dataLine.line_6a.push(row);
                    }else if(row.LINE_NO == '6b'){
                        row.loading = false;
                        row.showList = false;
                        row.noRecords = false;
                        row.NetIncomeLossAmt = parseFloat(row.NetIncomeLossAmt);
                        row.EIN = row.EIN ? row.EIN : row.MissingEINReasonCd;
                        vm.dataLine.line_6b.push(row);
                    }else if(row.LINE_NO == '7a'){
                        row.NetIncomeLossAmt = parseFloat(row.NetIncomeLossAmt);
                        vm.dataLine.line_7a.push(row);
                    }else if(row.LINE_NO == '7b'){
                        row.NetIncomeLossAmt = parseFloat(row.NetIncomeLossAmt);
                        vm.dataLine.line_7b.push(row);
                    }else if(row.LINE_NO == '8'){
                        row.Amt = parseFloat(row.Amt);
                        vm.dataLine.line_8.push(row);
                    }else if(row.LINE_NO == '9'){
                        row.Amt = parseFloat(row.Amt);
                        vm.dataLine.line_9.push(row);
                    }else{
                        row.Amt = parseFloat(row.Amt);
                        vm.dataLine.line_10.push(row);
                    }
                });
            }); 
        };

        vm.getBusinessInfo = function (search_key, line, row) {
            var index = _.findIndex($scope.scheduleM3FormView.rows, {num: line});
            $scope.scheduleM3FormView.rows[index].JSON_DATA[row].loading = true;
            $scope.scheduleM3FormView.rows[index].JSON_DATA[row].noRecords = false;
            var params = {};
            $scope.businessDetails = [];
            if (!!search_key) {
                params.search_val = search_key;
                params.source_type = line == '5a' || line == '5b' ? 'F' : 'D';
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=1foxtj', params).then(function (data) {
                if (data.jsonObject.length > 0) {
                    $scope.businessDetails = data.jsonObject;
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].loading = false;
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].showList = true;
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].noRecords = false;
                } else {
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].BusinessNameLine1Txt = "";
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].EIN = "";
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].noRecords = true;
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].loading = false;
                    $scope.scheduleM3FormView.rows[index].JSON_DATA[row].showList = false;
                }
            });
            } else {
                $scope.scheduleM3FormView.rows[index].JSON_DATA[row].loading = false;
                $scope.scheduleM3FormView.rows[index].JSON_DATA[row].noRecords = false;
            }
        };

        vm.fillTextbox = function (searched_data, line, row) {
            var index = _.findIndex($scope.scheduleM3FormView.rows, {num: line});
            $scope.scheduleM3FormView.rows[index].JSON_DATA[row].BusinessNameLine1Txt = searched_data.LE_NAME;
            $scope.scheduleM3FormView.rows[index].JSON_DATA[row].EIN = searched_data.EIN;
            $scope.scheduleM3FormView.rows[index].JSON_DATA[row].showList = false;
        };

        vm.limitDecimal = function(val, line, i){
            var index = _.findIndex($scope.scheduleM3FormView.rows, {num: line});
            if (Number(val) > 100){
                $scope.scheduleM3FormView.rows[index].JSON_DATA[i].DATA_ATTRIB_C = 100;
            }
        };

        vm.addNetIncomeLossAmt = function(line){
            if(line == '5a' || line == '5b' || line == '6a' || line == '6b' || line == '7a' || line == '7b' || line == '8' || line == '9' || line == '10'){
                var index = _.findIndex($scope.scheduleM3FormView.rows, {num: line});
                var total_count = 0;
                var amt_col = (line == '8' || line == '9' || line == '10') ? 'Amt' : 'NetIncomeLossAmt';
                angular.forEach($scope.scheduleM3FormView.rows[index].JSON_DATA, function (row, key) {
                    if(!isNaN(parseFloat(row[amt_col]))){
                        total_count += roundToThree(row[amt_col]);
                    }
                });
                $scope.scheduleM3FormView.rows[index].unallocatedAmt = ($scope.scheduleM3FormView.rows[index].value ? $scope.scheduleM3FormView.rows[index].value : 0) - total_count;
            }
        };

        function toggleConditionalField(arr, flag){
            angular.forEach(arr, function (value, key) { 
                $scope.scheduleM3FormView.rows[value].conditionalField = flag;
                if(flag){
                    $scope.scheduleM3FormView.rows[value].value = "";
                }
            });
        };

        fetchSubTableRecords().then(function(){
            fetchSchIrecords();
        });

        $scope.fieldVisibility = function (index, state, name){
            if(index == 3 || index == 4){
                checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
            }else if(index == 29 || index == 30 || index == 31 || index == 32 || index == 33){
                if(state && index == 29){
                    setValue([30,31,32,33], [33], true);
                }else if(state && index == 30){
                    setValue([29,31,32,33], [33], true);
                }else if (state && index == 31){
                    setValue([29,30,32,33], [33], true);
                }else if (state && index == 32){
                    setValue([29,30,31,33], [33], true);
                }else if(state && index == 33){
                	setValue([29,30,31,32], [33], false);
                }else{
                    setValue([29,30,31,32], [33], true);
                }
            }else{
                $scope.scheduleM3FormView.rows[index].conditionalField = !$scope.scheduleM3FormView.rows[index].conditionalField;
                if((name == 'yes' && !state) || (name == 'no' && state)){
                    $scope.scheduleM3FormView.rows[index].value = 'NA';
                };              
            }
        };

        function setValue(arr, toggle_i, state){
            angular.forEach(arr, function (value, key) { 
                $scope.scheduleM3FormView.rows[value].value = false;
            });
            $scope.scheduleM3FormView.rows[toggle_i].conditionalField = state;
            $scope.scheduleM3FormView.rows[toggle_i].value_user_input = '';
        };

        function checkBoxToggle (index, name, state, arr, val, attr){
            if((name == 'yes' && !state) || (name == 'no' && state)){
                $scope.scheduleM3FormView.rows[index].value = 'NA';
                if(name == 'yes' && !state){
                    angular.forEach(arr, function (value, key) {
                        $scope.scheduleM3FormView.rows[value].conditionalField = true;
                        $scope.scheduleM3FormView.rows[value].value_user_input = '';
                    });
                }
            }else{
                toggleCondition(arr, state, val, attr);
            }
        };

        function toggleCondition (index, state, type, attr){
            angular.forEach(index, function (value, key) { 
                if(($scope.scheduleM3FormView.rows[value].conditionalField && !state) || (!$scope.scheduleM3FormView.rows[value].conditionalField && state)){
                    return;
                }else {
                    $scope.scheduleM3FormView.rows[value].conditionalField = !$scope.scheduleM3FormView.rows[value].conditionalField; 
                    $scope.scheduleM3FormView.rows[value].value_user_input = '';
                }
            });
        };

        vm.addRows = function(type, i, arr){
            var newRow = {};
            if(type == 'dynamic'){
                angular.forEach(arr, function (value, key) {
                    newRow[value] = "";
                });
                newRow.loading = false;
                newRow.showList = false;
                newRow.noRecords = false;
                newRow.OCCURRENCE = $scope.scheduleM3FormView.rows[i].JSON_DATA.length + 1
            }else{
                newRow = {
                    OCCURRENCE : $scope.scheduleM3FormView.rows[i].JSON_DATA.length + 1,
                    DATA_ATTRIB_A : "",
                    DATA_ATTRIB_B : "",
                    DATA_ATTRIB_C : ""
                };
            }
            $scope.scheduleM3FormView.rows[i].JSON_DATA.push(newRow);
        };

        vm.computeValues2019 = function() {
        	$scope.scheduleM3FormView.rows[43].value =  $scope.scheduleM3FormView.rows[27].value + 
			$scope.scheduleM3FormView.rows[34].value + $scope.scheduleM3FormView.rows[35].value + 
			$scope.scheduleM3FormView.rows[36].value + $scope.scheduleM3FormView.rows[37].value + 
			$scope.scheduleM3FormView.rows[38].value + $scope.scheduleM3FormView.rows[39].value + 
			$scope.scheduleM3FormView.rows[40].value + $scope.scheduleM3FormView.rows[41].value +
			$scope.scheduleM3FormView.rows[42].value;
        };

        vm.saveSchdIForm = function (url, data){
            var jsonSettings = { 
                "tax_year": rowData.TAX_YEAR, 
                "scenario": rowData.SCENARIO_KEY, 
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
                    $uibModalInstance.dismiss('cancel');
                    var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                }else{
                    AlertService.add("", response.data.errorMessage );
                }
                vm.isSaving = false;
                return response.data;
            });
            
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function(){
        	if($scope.scheduleM3FormView.rows[19].value > $scope.scheduleM3FormView.rows[20].value){
        		AlertService.add("error", "End date cannot be before Start date", 4000);  
          	}else{
                vm.isSaving = true;
          		var formRowDataA = {};
	            var formRowDataB = {};
	            var tempArray = [];
                $scope.scheduleM3FormView.rows[19].value = $filter('date')($scope.scheduleM3FormView.rows[19].value,'MM/dd/yyyy');
                $scope.scheduleM3FormView.rows[20].value = $filter('date')($scope.scheduleM3FormView.rows[20].value,'MM/dd/yyyy');
	            for(var i=0; i<$scope.scheduleM3FormView.rows.length ;i++){
	                formRowDataA = {
	                    tax_year: rowData.TAX_YEAR,
	                    trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
	                    line_order: $scope.scheduleM3FormView.rows[i].line_order,
	                    attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_a,
	                    line_description: $scope.scheduleM3FormView.rows[i].description,
	                    combination_key: rowData.COMBINATION_KEY,
	                    group_obj_key: rowData.GROUP_OBJ_KEY,
	                    form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
	                    line_no: $scope.scheduleM3FormView.rows[i].num,
	                    occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
	                    line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_a,
	                    Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_a,
	                    Attribute_Value: $scope.scheduleM3FormView.rows[i].value === true ? 'true' : $scope.scheduleM3FormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleM3FormView.rows[i].value === false ? 'false' :  $scope.scheduleM3FormView.rows[i].value ? $scope.scheduleM3FormView.rows[i].value.toString() : '',
	                    trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
	                    trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
	                    is_dirty: ''
	                };
	                if((formRowDataA.line_no == 'D1' && $scope.scheduleM3FormView.rows[i-1].value == true && $scope.scheduleM3FormView.rows[i-1].JSON_DATA.length > 0) || formRowDataA.line_no == '5a' || formRowDataA.line_no == '5b' || formRowDataA.line_no == '6a' || formRowDataA.line_no == '6b'  || formRowDataA.line_no == '7a' || formRowDataA.line_no == '7b' || formRowDataA.line_no == '8' || formRowDataA.line_no == '9' || formRowDataA.line_no == '10'){
	                    var attributes = [];
                        var json_data_array = [];
                        if(formRowDataA.line_no == 'D1'){
                            attributes = ['A', 'B', 'C'];
                            json_data_array = $scope.scheduleM3FormView.rows[i-1].JSON_DATA;
                            formRowDataA.JSON_DATA = [];
                        }else{
                            attributes = $scope.scheduleM3FormView.rows[i].table_columns;
                            json_data_array = $scope.scheduleM3FormView.rows[i].JSON_DATA;
                        }
                        
	                    var tempDataArray = [];
	                    formRowDataA.JSON_DATA = [];
	                    angular.forEach(json_data_array, function (row, rowKey) {                     
	                        var hasData = false;
                            var tempObj = {};
	                        tempDataArray = [];
	                        angular.forEach(attributes, function (value, colKey) {
                                if(formRowDataA.line_no == 'D1'){
                                    var index = formRowDataA.JSON_DATA.length / 3 == 0 ? 1 : formRowDataA.JSON_DATA.length / 3 + 1;
                                    tempObj = {
                                        OCCURRENCE : index,
                                        ATTRIB_NAME : "DATA_ATTRIB_" + value,
                                        ATTRIB_VALUE : row["DATA_ATTRIB_" + value]
                                    }
                                }else{
                                    var index = formRowDataA.JSON_DATA.length / $scope.scheduleM3FormView.rows[i].table_columns.length == 0 ? 1 : formRowDataA.JSON_DATA.length / $scope.scheduleM3FormView.rows[i].table_columns.length + 1;
                                    tempObj = {
                                        OCCURRENCE : index,
                                        ATTRIB_NAME : value,
                                        ATTRIB_VALUE : value === 'BusinessNameLine1Txt' ? row['BusinessNameLine1Txt'].slice(0, 76) : value === 'BusinessNameLine2Txt' ? row['BusinessNameLine1Txt'].slice(76) : row[value]
                                    }
                                }
	                            if(tempObj.ATTRIB_VALUE !== undefined && tempObj.ATTRIB_VALUE !== ""  && tempObj.ATTRIB_VALUE !== null){
	                                hasData = true;
	                            }
	                            tempDataArray.push(tempObj);
	                        });
	                        if(hasData){
	                            angular.forEach(tempDataArray, function (column, colKey) {
	                                formRowDataA.JSON_DATA.push(column);
	                            });
	                        }
	                    });
	                }else{
	                    formRowDataA.JSON_DATA = [];
	                }
	                tempArray.push(formRowDataA);

	                if(typeof $scope.scheduleM3FormView.rows[i].attrib_name_b !== "undefined"){
	                    if($scope.scheduleM3FormView.rows[i].num == 'PSHIP' || $scope.scheduleM3FormView.rows[i].num == 'B' || $scope.scheduleM3FormView.rows[i].num == 'C' || $scope.scheduleM3FormView.rows[i].num == '4b5' || $scope.scheduleM3FormView.rows[i].num == '12a' || $scope.scheduleM3FormView.rows[i].num == '12b' || $scope.scheduleM3FormView.rows[i].num == '12c' || $scope.scheduleM3FormView.rows[i].num == '12d'){
	                        formRowDataB = {
	                            tax_year: rowData.TAX_YEAR,
	                            trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
	                            line_order: $scope.scheduleM3FormView.rows[i].line_order,
	                            attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_b,
	                            line_description: $scope.scheduleM3FormView.rows[i].description,
	                            combination_key: rowData.COMBINATION_KEY,
	                            group_obj_key: rowData.GROUP_OBJ_KEY,
	                            form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
	                            line_no: $scope.scheduleM3FormView.rows[i].num,
	                            occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
	                            line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_b,
	                            Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_b,
	                            Attribute_Value: $scope.scheduleM3FormView.rows[i].value_user_input,
	                            trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
	                            trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
	                            is_dirty: ''
	                        };
	                        tempArray.push(formRowDataB);
	                    }else{
	                        if($scope.scheduleM3FormView.rows[i].num !== 'D1'){
                                formRowDataA = {
                                    tax_year: rowData.TAX_YEAR,
                                    trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
                                    line_order: $scope.scheduleM3FormView.rows[i].line_order,
                                    attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_b,
                                    line_description: $scope.scheduleM3FormView.rows[i].description,
                                    combination_key: rowData.COMBINATION_KEY,
                                    group_obj_key: rowData.GROUP_OBJ_KEY,
                                    form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
                                    line_no: $scope.scheduleM3FormView.rows[i].num,
                                    occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
                                    line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_b,
                                    Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_b,
                                    Attribute_Value: $scope.scheduleM3FormView.rows[i].value === true ? 'false' : $scope.scheduleM3FormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleM3FormView.rows[i].value === false ? 'true' : $scope.scheduleM3FormView.rows[i].value ? $scope.scheduleM3FormView.rows[i].value.toString() : '',
                                    trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
                                    trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
                                    is_dirty: ''
                                };
                                tempArray.push(formRowDataA);
                            }
	                    }   
	                }
	            }
                vm.formData.editrows = [];
	            vm.formData.editrows.push(tempArray);       
	            vm.mainData = [];
	            vm.mainData.push(vm.formData);
	            console.log(vm.mainData);
	            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tkgaam";
	            vm.saveSchdIForm(url,vm.mainData);
	            $rootScope.$broadcast("gridRefresh", true);
	            $timeout(function() {
	                $scope.$broadcast("showLoader", true);
	            },100);
          	}
        };
        
        vm.reset = function (){
            $scope.scheduleM3FormView = angular.copy(vm.originalData);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function initFormView(filteredFormData) {
            $scope.scheduleM3FormView = filteredFormData;
            vm.computeValues2019();
        };

		//Dynamic Tabset Code
		vm.tabs = [
	        {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Sch-M3/F1065-scheduleM3Entry.html", active: true }
	        //{id: 3, name: "Audit", url: "app/components/partnership/F1065-Sch-M3/F1065-scheduleM3Version.html", active: false}
	    ];

        function roundToThree(num) {
            return +(Math.round(num + "e+3")  + "e-3");
        }
    }
});