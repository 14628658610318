define([
    'angular',
    './ptiSummaryReportCtrl',
    './ptiSummaryReportService', 
], function () {
    'use strict';
    return angular.module('app.FIR_PTI_Summary_Report', ['app.ptiSummaryReportCtrl', 'app.ptiSummaryReportService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('pti-summary-report', {
                url: '/pti-summary-report',
                templateUrl: 'app/components/FIR_PTI_Summary_Report/ptiSummaryReport.html',
                data:{},
                access:""
            })
    }])

});