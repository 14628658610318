define([
    'angular',
    './GtapsCommonTemplateCtrl',
    './GtapsCommonTemplateService', 
], function () {
    'use strict';
    return angular.module('app.FIR_GTAPS_Common_Template', ['app.gtapsCommonTemplateCtrl', 'app.gtapsCommonTemplateService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('gtaps-common-template', {
                url: '/gtaps-common-template',
                templateUrl: 'app/components/FIR_GTAPS_Common_Template/gtapsCommonTemplate.html',
                data:{},
                access:""
            })
    }])

});