
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.partnershipServices',[])

        .factory("PartnershipServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', PartnershipServicesFactory])
        .factory("PartnerAllocServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', PartnerAllocServicesFactory])
        .factory("PartnerRollupServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','$injector','workspaceFactory', PartnerRollupServicesFactory])

        function PartnershipServicesFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory) {

            var PartnershipServicesFactory = {};
           
            PartnershipServicesFactory.url = {};
            PartnershipServicesFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=n2pswc";

            PartnershipServicesFactory.savePartnershipSetupForm = function(_settings, _data) {

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_data);
                var jsonSettings =  JSON.stringify(_settings);
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);


                params.tax_year = filterParams.tax_year;
                params.scenario = filterParams.scenario;
                params.jcd_key = GlobalService.globalParams.jcd_key;
                params.process_name =  "Partnership Partner Setup";
                params.sso_id = _settings.sso_id;

                console.log('params = ', params);
                console.log('clob_data = ', params.clob_data);
                console.log('clob_settings = ', params.clob_settings);

                var promise = $http({
                    method: "post",
                    url: PartnershipServicesFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            return PartnershipServicesFactory;
        }

        function PartnerAllocServicesFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory) {

            var partnerAllocServicesFactory = {};
            console.log('... factory called ...');
            
            PartnerAllocServicesFactory.url = {};
            //PartnerAllocServicesFactory.url.defaults_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=6xgoog";

            PartnerAllocServicesFactory.savePartnerAllocationForm = function(action_code, _data, Details) {

                PartnerAllocServicesFactory.url.defaults_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=" + action_code;

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                _data['jcd_key'] = GlobalService.globalParams.jcd_key;
                var jsonSettings =  JSON.stringify(_data);

                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params.process_name =  "Partner Allocation Setup";
                params.jcd_key = GlobalService.globalParams.jcd_key;
                params.sso_id = _data.sso_id;

                console.log('params = ', params);
                console.log('clob_data = ', params.clob_data);
                console.log('clob_settings = ', params.clob_settings);
                var promise = $http({
                    method: "post",
                    url: PartnerAllocServicesFactory.url.defaults_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            return PartnerAllocServicesFactory;

        }

        function PartnerRollupServicesFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,$injector,workspaceFactory) {

            let service = $injector.get('JsonObjectFactory');
 			let defer = $q.defer();

 			PartnerRollupServicesFactory.url = {};
            PartnerRollupServicesFactory.url.specialAllocOverride = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=40xlbs";
            PartnerRollupServicesFactory.url.sourcingSave = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=jg2bxp";
            
            PartnerRollupServicesFactory.specialAllocOverride = function(_callName ,_data) {

                let rowData = _data.rowData;
                let filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                let params = _.merge({}, GlobalService.globalParams, filterParams);
                params.sso_id = USER_SETTINGS.user.sso_id;
                params.pship_cc_key = rowData.PSHIP_CC_KEY;
                params.ptr_le_key = rowData.PTR_LE_KEY;
                params.acct_key = rowData.PSHIP_ACCT_KEY;
                params.tp_cc_key = rowData.TRADING_PARTNER;
                params.amount = (rowData.OVERRIDE_ALLOCATION_AMT ? rowData.OVERRIDE_ALLOCATION_AMT : 0);
                params.pcnt = (rowData.SA_PCT_OVERRIDE ? rowData.SA_PCT_OVERRIDE : 0);
                params.issue_key = rowData.ISSUE_KEY;
                //added the generic service for inline save 12/11/2020
                service.saveJSON(PartnerRollupServicesFactory.url.specialAllocOverride, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 3000);
                            let args = {
                                COMBINATION_KEYS: rowData.PSHIP_CC_KEY,
                                gridFilter: {COMBINATION_KEY: rowData.PSHIP_CC_KEY}
                            };
                            $rootScope.$emit('gridUpdate', args);
                            defer.resolve(data);
                        } else {
                            AlertService.add("error", data.errorMessage);
                            defer.reject(data);
                        }
                    }, function (data) {
                        defer.reject(data);
                    }
                );
                return defer.promise;
            }

            PartnerRollupServicesFactory.ptpSourcingSave = function(_callName ,_data){

                 let rowData = _data.rowData;
                 let filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                 let params = _.merge({}, GlobalService.globalParams, filterParams);
                
                 let json_arrOBJ = [{
                    basket_name : _data.colData.map,
                    pship_cc_key : _data.rowData.PSHIP_CC_KEY, 
                    pship_acct_key : _data.rowData.PSHIP_ACCT_KEY,  
                    ptr_le_key: _data.rowData.PTR_LE_KEY,
                    trading_partner:_data.rowData.TRADING_PARTNER,
                    basket_value : _data.rowData[_data.colData.map],                    
                    country_code : _data.rowData.COUNTRY_CODE
                }];

                 let jsonSettings = [{
                    tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                }];

                params = _.extend({jsonObj: JSON.stringify(json_arrOBJ)}, params);
                params =  _.extend({jsonSettings:JSON.stringify(jsonSettings)}, params);
                params.sso_id = USER_SETTINGS.user.sso_id;
                params.issue_key = _data.rowData.ISSUE_KEY;

                //added the generic service for inline save 12/11/2020
                service.saveJSON(PartnerRollupServicesFactory.url.sourcingSave,params).then(function (data) {
                         if(data.callSuccess === "1"){
                             AlertService.add("success", "Data saved Successfully.",3000);
                             let args = {COMBINATION_KEYS: rowData.PSHIP_CC_KEY, gridFilter: {COMBINATION_KEY: rowData.PSHIP_CC_KEY}};
                                         $rootScope.$emit('gridUpdate', args);
                                         defer.resolve(data)
                         }else {
                             AlertService.add("error", data.errorMessage);
                             defer.reject(data);
                         }
                     },function(data){
                         defer.reject(data);
                     }
                );
                return defer.promise;
            };
         
            return PartnerRollupServicesFactory;

        }
    return services;
});