define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.cacheService',[])

        .factory("CacheFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,JsonObjectFactory, GENERAL_CONFIG) {
          var cacheFactory = {};

            var URLS = {
                FETCH: GENERAL_CONFIG.common_api_url + '/fetchCacheObjControlDetails',
                REFRESH: GENERAL_CONFIG.common_api_url + '/refreshCachedObject',
				REMOVE: GENERAL_CONFIG.common_api_url + '/removeCachedObject', //-- Addition of Remove URL --//
				DOWNLOAD: GENERAL_CONFIG.common_api_url + '/downloadCachedObject', //-- Addition of Download URL --//
				REFRESHALL: GENERAL_CONFIG.common_api_url + '/refreshAllCachedObject', //-- Addition of Refresh All URL --//
				SWITCH: GENERAL_CONFIG.common_api_url + '/enableDisableCaching',
                OBTAIN: GENERAL_CONFIG.common_api_url + '/obtainBOFromCache',
                CACHE_DROPDOWN: GENERAL_CONFIG.common_api_url + '/getAllCacheNames',
                FETCH_CACHE_KEYS: GENERAL_CONFIG.common_api_url + '/getAllCacheKeysAndCount',
                FETCH_KEY_JSON: GENERAL_CONFIG.common_api_url + '/getCacheData',
                DELETE: GENERAL_CONFIG.base_url + '/deleteIndexes?deleteall=',
            }

            var data = {
                obj_key : '',
                params : ''
            }
            cacheFactory.setCacheParams = function(obj_name, params){
                data.obj_key = obj_name;
                data.params = params;
            }

            cacheFactory.getCacheParams = function(){
                return data;
            }

            cacheFactory.fetchCacheObjControlDetails = function(data) {
             	
            	var promise = $http({
                    method: "POST",
            		url: URLS.FETCH,
            		data: data
            	}).then(function (response) {
            		return response;
            	});   

            	return promise;   	   
            };

            cacheFactory.refreshCachedObject = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.REFRESH,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };
			
			cacheFactory.refreshAllCachedObject = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.REFRESHALL,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };
			
			cacheFactory.removeCachedObject = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.REMOVE,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };
			
			cacheFactory.downloadCachedObject = function(data) {

                var promise = $http({
                    method: "GET",
                    url: URLS.DOWNLOAD,
					responseType: 'arraybuffer',
					 observe: 'response',
                    params: { objectId: data.objectId }
                }).then(function (response) {					
					//response.headers = headers();
					//console.log();
                    return response;
                });

                return promise;
            };

            cacheFactory.enableDisableCaching = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.SWITCH,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };

            cacheFactory.getDataFromCache = function(obj_name, params) {

                var promise = $http({
                    method: "GET",
                    url: URLS.OBTAIN + '?object_key=' + obj_name + '&proc_in_params=' + params + '&fetch_from_cache=Y'
                }).then(function (response) {
                    return response.data;
                });

                return promise;
            };

            cacheFactory.getDataFromDB = function(obj_name, params) {

                var promise = $http({
                    method: "GET",
                    url: URLS.OBTAIN + '?object_key=' + obj_name + '&proc_in_params=' + params + '&fetch_from_cache=N'
                }).then(function (response) {
                    return response.data;
                });

                return promise;
            };

            cacheFactory.getCacheDropdown = function(data) {
                var promise = $http({
                    method: "GET",
            		url: URLS.CACHE_DROPDOWN,
            		data: data
            	}).then(function (response) {
            		return response;
            	});   
            	return promise;   
            };

            cacheFactory.getSelectedCacheDropdown = function(obj_name, params) {
                var promise = $http({
                    method: "GET",
                    url: URLS.FETCH_CACHE_KEYS
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            cacheFactory.getCacheKeyJson = function(cache_name, cache_key) {
                var promise = $http({
                    method: "GET",
                    url: URLS.FETCH_KEY_JSON + '?cache_name=' + cache_name + '&cache_key=' + cache_key
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            cacheFactory.deleteIndexes = function(type) {

                var promise = $http({
                    method: "POST",
                    url: URLS.DELETE + type
                }).then(function (response) {
                    return response;
                });

                return promise;
            };
            
            return cacheFactory;
        }])

    return services;
});