define(
    ['angular'],
    function () {
        'use strict';

        var services = angular.module('app.estSignOffService', [])
            .factory('estSignOffFactory',
                ['$q', '$http', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG', '$injector', estSignOffFactory]);

        function estSignOffFactory($q, $http, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG, $injector) {
            var URLS = {
                SAVE_SIGNOFF: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=rxomdr",
            };
            var factory = {};
            factory.saveSignOff = function (_callName, _data,) {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                console.log(filterParams)
                var defer = $q.defer();
                var clob_setting = {};
                var clob_data = [];
                var params = {};
                clob_data.push(_data.rowData);
                clob_setting = [{ tax_year: GlobalService.globalParams.tax_year }];
                params.process_name = "EST-save-signoff";
                params.tax_year = GlobalService.globalParams.tax_year;
                params.out_year = filterParams.out_year;
                params.qtr = filterParams.qtr;
                params.workflow = clob_data[0].WORKFLOW;
                params = _.extend({
                    clob_data: JSON.stringify(clob_data)
                }, params);
                params = _.extend({
                    clob_settings: JSON.stringify(clob_setting)
                }, params)
                var service = $injector.get('JsonObjectFactory');
                service.saveJSON(URLS.SAVE_SIGNOFF, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        AlertService.add("success", "Data saved Successfully.", 4000);

                        var args = {
                            combination_key: _data.rowData.COMBINATION_KEY,
                            gridFilter: {
                                combination_key: _data.rowData.COMBINATION_KEY
                            }
                        };
                        $rootScope.$emit('gridUpdate', args);

                        defer.resolve(data);
                    }
                    else {
                        AlertService.add("error", data.errorMessage, 4000);
                        defer.reject(data);
                    }
                }
                    , function (data) {
                        defer.reject(data);
                    });
                return defer.promise;
            };
            return factory;
        }
        return services;
    }
);