define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.helpController',[])
        .controller('HelpController',['$scope','$rootScope','AlertService','USER_SETTINGS', 'GENERAL_CONFIG', helpController]);

        function helpController($scope,$rootScope,AlertService,USER_SETTINGS, GENERAL_CONFIG) {
            const currentUser = USER_SETTINGS.user;
            $scope.baseURLs = {};
            $scope.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            $scope.baseURLs.api = GENERAL_CONFIG.base_url;
            $scope.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
            $scope.user = JSON.stringify({sso:currentUser.sso_id,role:currentUser.user_type, access:currentUser.system_access_level});
            $scope.pageTitle = "Help";
            $scope.isSuperAdminRole = (USER_SETTINGS.user.user_type === "Super Admin");
            $scope.refreshTrainingVideo = () =>{
                $scope.$broadcast('traingingVideoClicked');
            };
            $scope.supportURL = "https://jira.ist.pwc.com/servicedesk/customer/portal/4";
        }

        return controllers;

});
