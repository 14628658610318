define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.Form5471-textInfoCtrl', [])
    
    .controller('textInfoCtrl', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory','$parse', 'form5471ScheduleGGroupObjFactory',
			   'AlertService', 'GENERAL_CONFIG', 'ModalFactory',textInfoCtrl])
	.controller('textInfoCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory', '$parse','form5471ScheduleGGroupObjFactory',
	'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',textInfoCtrlModal])
	
	.controller('scheduleGInfoCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory', '$parse','form5471ScheduleGGroupObjFactory',
	'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',scheduleGInfoCtrlModal])
	
	.controller('scheduleIInfoCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory', '$parse','form5471ScheduleGGroupObjFactory',
	'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',scheduleIInfoCtrlModal]);



	function textInfoCtrl(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, form5471ScheduleGGroupObjFactory,
		AlertService, GENERAL_CONFIG, ModalFactory) {
		textInfoCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, form5471ScheduleGGroupObjFactory, AlertService, GENERAL_CONFIG,ModalFactory, null, null, null, null, this);
		}


    function textInfoCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, form5471ScheduleGGroupObjFactory,
    		AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,
			rowData, colData, gridData,thisObj) {
    
    
    	var vm = this||thisObj;
        vm.rowData = rowData||$scope.$parent.rowData;
        vm.checkFlag = true;
        //vm.rowData = $scope.$parent.rowData;
        vm.scheduleGDetails = [];
        vm.scheduleGDetailsOriginal = [];
        vm.scheduleGDetails.value = "";
        vm.schedule_g = {};
        vm.invalidSaveMessage = "";
        vm.data = {};
        $scope.irsF5471ScheduleGData = [];
        $scope.irsF8858ScheduleC1Data = [];
        //$scope.crudLoading = false;
        vm.selectedCountry='';
        vm.tabTitle = "Schedule G Other Information";        
        vm.selectedGroup= {};
        vm.filedOnBehalf;
        vm.selectedGroup1 = {};
        vm.selectedFiledOnBehalf;
        vm.countries = null;
        vm.attribData = [];
        vm.countryVar;
        vm.countryCust;
        vm.countryCorporation;
        vm.countryAgent;
        vm.checkBoxd;
        vm.checkBoxf;
        vm.checkBoxb1;
        vm.checkBoxb3;
        vm.checkBoxb4;
        vm.checkBoxb5;
        vm.checkShareholder;
        vm.checkOfficer;
        vm.checkDirector;
        vm.checkAquisition;
        vm.checkComparable;
        vm.checkMarket;
        vm.checkIncome;
        vm.checkResidual;
        vm.checkUnspecified;
        vm.radioUnblk;
        vm.radioBlk;
        vm.radiodisp;
        vm.loadPersonBehalf = false;
        vm.loadingfob = false;
        $scope.lazyLoadObj = {};
        $scope.local_acc_dropdown_data = [];
        $scope.submitted= false;
        vm.line_2a_name = false;
        vm.line_2a_country_flag = false;
        vm.add_flag_2a = false;
        vm.add_flag_2a_f = false;
        vm.city_flag_2a = false;
        vm.state_flag_2a = false;
        vm.zip_flag_2a = false;
        
        
        vm.line_2c_name = false;
        vm.line_2c_country_flag = false;
        vm.add_flag_2c = false;
        vm.add_flag_2c_f = false;
        vm.city_flag_2c = false;
        vm.state_flag_2c = false;
        vm.zip_flag_2c = false;
        
        vm.line_2d_name = false;
        vm.line_2d_country_flag = false;
        vm.add_flag_2d = false;
        vm.add_flag_2d_f = false;
        vm.city_flag_2d = false;
        vm.state_flag_2d = false;
        vm.zip_flag_2d = false;
        
        vm.refrence_iddisabled = false;
        vm.batch_flag_2a = false;
        vm.batch_flag_2c = false;
        vm.batch_flag_2d = false;
        vm.tax_year_selected = workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year;
        
        //vm.trans = '';
        vm.tax_year_flag  = workspaceFactory.activeScreen.filters.filterParams.tax_year == '2019' ? true :false;
        
        vm.modalTitle = "Form 5471- Information Return of U.S. Persons With Respect To Certain Foreign Corporations";
        if (angular.isObject($uibModalInstance)) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};			
		} else {
			vm.modal_name = null;			
		}
        
       // vm.PrincipalBusinessActivity = CommonUtilitiesFactory.getPrincipalBusinessActivity();

        var saveParamDtls = [];
        var tempDataObj = {
            num :'',
            description : '',
            value :''
        };

        vm.initLoadData = function(){
        	vm.dataLoading = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var param = _.merge({}, GlobalService.globalParams, filterParams);
            param.action_code= "f5sne6";
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', param).then(function (data) {
                vm.filingGroup =  data.jsonObject;
                vm.filingGroup1 = data.jsonObject;
                getCountries();
                getCurrencies();
                getStates();               
                getFormData();
                getCatgFilerData();	
                get5471Line1Data();
                
                
            });

        };
        vm.initLoadData();

        vm.openCalendar = function() {
            vm.calendar.opened = true;
        };

        
        /// getting the textual_info_data from db
        
        function getFormData()
        {
            var params = {
                "action_code": 'cv31te',
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "irs_form_no" : 'F5471',
                "combination_key": vm.rowData.HO_COMBINATION_KEY
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                    vm.irsF5471ScheduleNAData = data.jsonObject;
                    vm.attribData = [];
                    vm.irsF5471ScheduleNADataTemp = data.jsonObject;
                    vm.arraySize = vm.irsF5471ScheduleNADataTemp;
                    console.log("check this out ::::::: ", vm.irsF5471ScheduleNADataTemp);

                    var attribName = null;
                    var attribValue = null;
                    for(var i=1; i <data.jsonObject.length; i++) {
                    var tempString = vm.irsF5471ScheduleNAData[i].object_id;
                    var line_no_new = vm.irsF5471ScheduleNAData[i].line_no;
                    var tempValue = vm.irsF5471ScheduleNAData[i].attrib_value;
                    attribName = tempValue.split('~')[0];
                    attribValue =  ((vm.irsF5471ScheduleNAData[i].VALUE=== "0" )? '':vm.irsF5471ScheduleNAData[i].VALUE);//vm.irsF5471ScheduleNAData[i].VALUE;
                    var updateFlag = vm.irsF5471ScheduleNAData[i].OVERRIDE_FLAG;
                    
                    if( vm.irsF5471ScheduleNAData[i].BATCH_FLAG == 'Y'&& vm.irsF5471ScheduleNAData[i].line_no == '2a'){
						vm.batch_flag_2a = true;
                    	};
                    	
                     if( vm.irsF5471ScheduleNAData[i].BATCH_FLAG == 'Y'&& vm.irsF5471ScheduleNAData[i].line_no == '2c'){
						vm.batch_flag_2c = true;
                    	}; 	
                     if( vm.irsF5471ScheduleNAData[i].BATCH_FLAG == 'Y'&& vm.irsF5471ScheduleNAData[i].line_no == '2d'){
						vm.batch_flag_2d = true;
                    	};	
                    
                    console.log(attribName,attribValue, 'attribValue')
                    
                    vm.irsF5471ScheduleNAData[i] = {
                    i : vm.irsF5471ScheduleNAData[i],
                    "tax_year": tempString.split('~')[0],
                    "trans_type_key":tempString.split('~')[1],
                    "attrib_name":tempValue.split('~')[0],
                    "attrib_desc":tempValue.split('~')[3],
                    "line_attrib_key":tempValue.split('~')[1],
                    "trans_details_key":tempString.split('~')[3],
                    "occurrence":tempString.split('~')[4],
                    "VALUE":vm.irsF5471ScheduleNAData[i].VALUE,
                    "form_key":tempString.split('~')[6],
                    "is_update":updateFlag,
                    "line_no":line_no_new
                    
                    };
                    if(attribName === '5471_NA_CFC_BEGIN_DATE') {                    	
                        vm.attribData['5471_NA_CFC_BEGIN_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                    } else if(attribName === '5471_NA_CFC_END_DATE') {
                    	vm.attribData['5471_NA_CFC_END_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                    } else if(attribName === '5471_NA_1D') {
                    	vm.attribData['5471_NA_1D'] = new Date(attribValue);//, "MM/dd/yyyy");
                    } else if (attribName === '5471_NA_CFC_US_COUNTRY' && angular.isDefined(attribValue) && attribValue === 'US') {
                  	  vm.countryVar = attribValue ;  
          		     vm.attribData['5471_NA_CFC_US_COUNTRY'] = attribValue;
          		     vm.attribData['5471_NA_CFC_F_COUNTRY'] = null;
          		     vm.attribData['5471_NA_CFC_F_ADDRESS_1'] = null;
          		     vm.attribData['5471_NA_CFC_F_ADDRESS_2'] = null;
          		     vm.attribData['5471_NA_CFC_F_CITY'] = null;
          		     vm.attribData['5471_NA_CFC_F_STATE'] = null;
          		     vm.attribData['5471_NA_CFC_F_ZIPCODE'] = null;
                  } else if (attribName === '5471_NA_CFC_F_COUNTRY' && angular.isDefined(attribValue) && attribValue !== 'US') { 
                  	  vm.countryVar = attribValue ;  
          		     vm.attribData['5471_NA_CFC_F_COUNTRY'] = attribValue;
          		     vm.attribData['5471_NA_CFC_US_COUNTRY'] = null;
          		     vm.attribData['5471_NA_CFC_US_ADDRESS_1'] = null;
          		     vm.attribData['5471_NA_CFC_US_ADDRESS_2'] = null;
          		     vm.attribData['5471_NA_CFC_US_CITY'] = null;
          		     vm.attribData['5471_NA_CFC_US_STATE'] = null;
          		     vm.attribData['5471_NA_CFC_US_ZIPCODE'] = null;
                  }else if (attribName === '5471_NA_PER_RECORDS_CUST_US_COUNTRY' && angular.isDefined(attribValue) && attribValue === 'US') {
                	  vm.countryCust = attribValue ;  
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] = attribValue;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'] = null;
                   } else if (attribName === '5471_NA_PER_RECORDS_CUST_F_COUNTRY' && angular.isDefined(attribValue) && attribValue !== 'US') { 
                	   vm.countryCust = attribValue ;  
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] = attribValue;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] = null;
           		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] = null;
                   }else if (attribName === '5471_NA_BRANCH_US_COUNTRY' && angular.isDefined(attribValue) && attribValue === 'US') {
                 	  vm.countryAgent = attribValue ;  
            		     vm.attribData['5471_NA_BRANCH_US_COUNTRY'] = attribValue;
            		     vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_F_CITY'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_F_STATE'] = null;
            		     vm.attribData['5471_NA_BRANCH_F_ZIP'] = null;
                    } else if (attribName === '5471_NA_BRANCH_US_F_COUNTY' && angular.isDefined(attribValue) && attribValue !== 'US') { 
                 	   vm.countryAgent = attribValue ;  
            		     vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] = attribValue;
            		     vm.attribData['5471_NA_BRANCH_US_COUNTRY'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_CITY'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_STATE'] = null;
            		     vm.attribData['5471_NA_BRANCH_US_ZIP'] = null;
                    }else if (attribName === '5471_NA_FOREIGN_AGENT_US_COUNTRY' && angular.isDefined(attribValue) && attribValue === 'US') {
                   	  	 vm.countryCorporation = attribValue ;  
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] = attribValue;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'] = null;
	                 } else if (attribName === '5471_NA_FOREIGN_AGENT_F_COUNTRY' && angular.isDefined(attribValue) && attribValue !== 'US') { 
	              	   vm.countryCorporation = attribValue ;  
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] = attribValue;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] = null;
	         		     vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] = null;
	                 }else if(attribName === '5471_NA_FILER_LEGAL_ENTITY_ID'){
                	   angular.forEach(vm.filingGroup, function(fg) {
               	   		   if (attribValue == fg.LEGAL_ENTITY_ID) {
               	   			   vm.selectedGroup = fg;               	   			   
               	   		   }
               	   	   });
                   }else if(attribName === '5471_NA_PERSON_BEHALF' ){
                	   		vm.loadPersonBehalf = true;
                	   		getTransId(attribValue);
                	   		
                   }else if(attribName === '5471_NA_1F' ){
                  	    vm.attribData['5471_NA_1F'] = attribValue;
                 	   angular.forEach(vm.PrincipalBusinessActivity, function(hg) {
                	   		   if (vm.attribData['5471_NA_1F'] == hg.ACTIVITY_CODE) {
                	   			   vm.selectedGroup1 = hg;
                	   		   }
                	   	   });
                    }else if (attribName === '5471_NA_D_Y' && attribValue === '1'){
                		    vm.checkBoxd = attribValue;
                			vm.attribData['5471_NA_D_Y'] = attribValue;
                			vm.attribData['5471_NA_D_N'] = null;
                	}else if (attribName === '5471_NA_D_N' && attribValue === '0'){
                    		 vm.checkBoxd = attribValue;
                    		 vm.attribData['5471_NA_D_N'] = attribValue;
                    		 vm.attribData['5471_NA_D_Y'] = null;
                	}else if (attribName === '5471_NA_ALT_INFO_2019_40_IND_Y' && attribValue === '1'){
            		    vm.checkBoxf = attribValue;
            			vm.attribData['5471_NA_ALT_INFO_2019_40_IND_Y'] = attribValue;
            			vm.attribData['5471_NA_ALT_INFO_2019_40_IND_N'] = null;
                	}else if (attribName === '5471_NA_ALT_INFO_2019_40_IND_N' && attribValue === '0'){
                		 vm.checkBoxf = attribValue;
                		 vm.attribData['5471_NA_ALT_INFO_2019_40_IND_N'] = attribValue;
                		 vm.attribData['5471_NA_ALT_INFO_2019_40_IND_Y'] = null;		 
                	}else if (attribName === '5471_NA_B_1_Y' && attribValue === '1'){
            		    vm.checkBoxb1 = attribValue;
            			vm.attribData['5471_NA_B_1_Y'] = attribValue;
            			vm.attribData['5471_NA_B_1_N'] = null;
            		}else if (attribName === '5471_NA_B_1_N' && attribValue === '0'){
                		 vm.checkBoxb1 = attribValue;
                		 vm.attribData['5471_NA_B_1_N'] = attribValue;
                		 vm.attribData['5471_NA_B_1_Y'] = null;
            		}else if (attribName === '5471_NA_B_3_Y' && attribValue === '1'){
            		    vm.checkBoxb3 = attribValue;
            			vm.attribData['5471_NA_B_3_Y'] = attribValue;
            			vm.attribData['5471_NA_B_3_N'] = null;
            		}else if (attribName === '5471_NA_B_3_N' && attribValue === '0'){
                		 vm.checkBoxb3 = attribValue;
                		 vm.attribData['5471_NA_B_3_N'] = attribValue;
                		 vm.attribData['5471_NA_B_3_Y'] = null;
            		}else if (attribName === '5471_NA_B_4_Y' && attribValue === '1'){
            		    vm.checkBoxb4 = attribValue;
            			vm.attribData['5471_NA_B_4_Y'] = attribValue;
            			vm.attribData['5471_NA_B_4_N'] = null;
            		}else if (attribName === '5471_NA_B_4_N' && attribValue === '0'){
                		 vm.checkBoxb4 = attribValue;
                		 vm.attribData['5471_NA_B_4_N'] = attribValue;
                		 vm.attribData['5471_NA_B_4_Y'] = null;
            		}else if (attribName === '5471_NA_B_5_Y' && attribValue === '1'){
            		    vm.checkBoxb5 = attribValue;
            			vm.attribData['5471_NA_B_5_Y'] = attribValue;
            			vm.attribData['5471_NA_B_5_N'] = null;
            		}else if (attribName === '5471_NA_B_5_N' && attribValue === '0'){
                		 vm.checkBoxb5 = attribValue;
                		 vm.attribData['5471_NA_B_5_N'] = attribValue;
                		 vm.attribData['5471_NA_B_5_Y'] = null;
            		}else if (attribName === '5471_NA_B_1A_Y' && attribValue === '1'){
            		    vm.checkBoxb1a = attribValue;
            			vm.attribData['5471_NA_B_1A_Y'] = attribValue;
            			vm.attribData['5471_NA_B_1A_N'] = null;
            		}else if (attribName === '5471_NA_B_1A_N' && attribValue === '0'){
                		 vm.checkBoxb1a = attribValue;
                		 vm.attribData['5471_NA_B_1A_N'] = attribValue;
                		 vm.attribData['5471_NA_B_1A_Y'] = null;
            		}else if (attribName === '5471_NA_B_1B_Y' && attribValue === '1'){
            		    vm.checkBoxb1b = attribValue;
            			vm.attribData['5471_NA_B_1B_Y'] = attribValue;
            			vm.attribData['5471_NA_B_1B_N'] = null;
            		}else if (attribName === '5471_NA_B_1B_N' && attribValue === '0'){
                		 vm.checkBoxb1b = attribValue;
                		 vm.attribData['5471_NA_B_1B_N'] = attribValue;
                		 vm.attribData['5471_NA_B_1B_Y'] = null;
            		}
					else if (attribName === '5471_NA_B_1C_Y' && attribValue === '1'){
            		    vm.checkBoxb1c = attribValue;
            			vm.attribData['5471_NA_B_1C_Y'] = attribValue;
            			vm.attribData['5471_NA_B_1C_N'] = null;
            		}else if (attribName === '5471_NA_B_1C_N' && attribValue === '0'){
                		 vm.checkBoxb1c = attribValue;
                		 vm.attribData['5471_NA_B_1C_N'] = attribValue;
                		 vm.attribData['5471_NA_B_1C_Y'] = null;
            		}else if (attribName === '5471_NA_B_5A_Y' && attribValue === '1'){
            		    vm.checkBoxb5a = attribValue;
            			vm.attribData['5471_NA_B_5A_Y'] = attribValue;
            			vm.attribData['5471_NA_B_5A_N'] = null;
            		}else if (attribName === '5471_NA_B_5A_N' && attribValue === '0'){
                		 vm.checkBoxb5a = attribValue;
                		 vm.attribData['5471_NA_B_5A_N'] = attribValue;
                		 vm.attribData['5471_NA_B_5A_Y'] = null;
					}else if (attribName === '5471_NA_B_5B_Y' && attribValue === '1'){
            		    vm.checkBoxb5b = attribValue;
            			vm.attribData['5471_NA_B_5B_Y'] = attribValue;
            			vm.attribData['5471_NA_B_5B_N'] = null;
            		}else if (attribName === '5471_NA_B_5B_N' && attribValue === '0'){
                		 vm.checkBoxb5b = attribValue;
                		 vm.attribData['5471_NA_B_5B_N'] = attribValue;
                		 vm.attribData['5471_NA_B_5B_Y'] = null;
				    }else if (attribName === '5471_NA_B_5C_Y' && attribValue === '1'){
            		    vm.checkBoxb5c = attribValue;
            			vm.attribData['5471_NA_B_5C_Y'] = attribValue;
            			vm.attribData['5471_NA_B_5C_N'] = null;
            		}else if (attribName === '5471_NA_B_5C_N' && attribValue === '0'){
                		 vm.checkBoxb5c = attribValue;
                		 vm.attribData['5471_NA_B_5C_N'] = attribValue;
                		 vm.attribData['5471_NA_B_5C_Y'] = null;			
				    }else if (attribName === '5471_NA_FOB_SHAREHOLDER_Y' && attribValue === '1'){
            		    vm.checkShareholder = attribValue;
            			vm.attribData['5471_NA_FOB_SHAREHOLDER_Y'] = attribValue;
            			vm.attribData['5471_NA_FOB_SHAREHOLDER_N'] = null;
            		}else if (attribName === '5471_NA_FOB_SHAREHOLDER_N' && attribValue === '0'){
                		 vm.checkShareholder = attribValue;
                		 vm.attribData['5471_NA_FOB_SHAREHOLDER_Y'] = attribValue;
                		 vm.attribData['5471_NA_FOB_SHAREHOLDER_N'] = null;
            		}else if (attribName === '5471_NA_FOB_OFFICER_Y' && attribValue === '1'){
            		    vm.checkOfficer = attribValue;
            			vm.attribData['5471_NA_FOB_OFFICER_Y'] = attribValue;
            			vm.attribData['5471_NA_FOB_OFFICER_N'] = null;
            		}else if (attribName === '5471_NA_FOB_OFFICER_N' && attribValue === '0'){
                		 vm.checkOfficer = attribValue;
                		 vm.attribData['5471_NA_FOB_OFFICER_Y'] = attribValue;
                		 vm.attribData['5471_NA_FOB_OFFICER_N'] = null;
            		}else if (attribName === '5471_NA_FOB_DIRECTOR_Y' && attribValue === '1'){
            		    vm.checkDirector = attribValue;
            			vm.attribData['5471_NA_FOB_DIRECTOR_Y'] = attribValue;
            			vm.attribData['5471_NA_FOB_DIRECTOR_N'] = null;
            		}else if (attribName === '5471_NA_FOB_DIRECTOR_N' && attribValue === '0'){
                		 vm.checkDirector = attribValue;
                		 vm.attribData['5471_NA_FOB_DIRECTOR_Y'] = attribValue;
                		 vm.attribData['5471_NA_FOB_DIRECTOR_N'] = null;
            		}else if (attribName === '5471_NA_1B(1)_EIN_NUMBER'&& (angular.isUndefined(attribValue) ||attribValue == ""))
       					{
                		 vm.refrence_iddisabled = true;
            			}            		
                  else {
                  	vm.attribData[attribName]=attribValue;
                  }
              }
              
                    
          }
                else 
                {
                    if (data.errorMessage ) {
                        AlertService.add("",data.errorMessage ,
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
               vm.dataLoading = false;
            });
        } 
        
        
        
        
        
        
        // setting override flag for filing group
        vm.updated = function(filingId) { 
			if(vm.irsF5471ScheduleNAData.length >0){
			 var filing_group_db = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name === '5471_NA_FILER_LEGAL_ENTITY_ID')
			 filing_group_db.VALUE == filingId ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FILER_LEGAL_ENTITY_ID','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FILER_LEGAL_ENTITY_ID','Y');
			 
		}
            vm.selectedGroup = getFilingGroup(filingId);     
            vm.filingGroup1= _.filter(vm.filingGroup, function(obj){
                return obj.LEGAL_ENTITY_ID !== vm.selectedGroup.LEGAL_ENTITY_ID ;
            });
        };
        
        
        
        function getCatgFilerData()
				        		{
				            	 var params = {
				                "action_code": 'ot2ww8',
				                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
				                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
				                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
				                "combination_key": vm.rowData.HO_COMBINATION_KEY,
				                "action": 'modal'
				                
				            	};
				            	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				                if (data.callSuccess === "1") {
				                    vm.CatgData = data.jsonObject;
				                     }
				                    });
						}
								
		function get5471Line1Data()
				        		{
				            	 var params = {
				                "action_code": 'sslirf',
				                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
				                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
				                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
				                "combination_key": vm.rowData.HO_COMBINATION_KEY,
				                "ho_leid": vm.rowData.LEID,
				                "ho_cdr_no": vm.rowData.CDR_NO,
				                "ho_rpt_pd": vm.rowData.REPORTING_PERIOD
				                
				                
				            	};
				            	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				                if (data.callSuccess === "1") {
				                    vm.line1Data = data.jsonObject;
				                     }
				                    });
						}					
			
        vm.set_override_flag = function(value , line_no){
	
	
				if (line_no == 'CATEGORY_1'&& value == '0'){
				    var catg_1_N = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_1A')
				   if (catg_1_N.VALUE == value){
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_N','N')
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_Y','N')
					}else{
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_N','Y')
				       vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_Y','Y')
				       }
				  }
			   if (line_no == 'CATEGORY_1'&& value == '1'){
				   var catg_1_Y = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_1A')
				    if(catg_1_Y.VALUE == value){
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_Y','N')
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_N','N')
					}else{
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1_N','Y')
					}
				    
			      }	
			      
			      if (line_no == 'CATEGORY_5'&& value == '0'){
				    var catg_5_N = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_5A')
				   if (catg_5_N.VALUE == value){
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_N','N')
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_Y','N')
					}else{
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_N','Y')
				       vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_Y','Y')
				       }
				  }
			   if (line_no == 'CATEGORY_5'&& value == '1'){
				   var catg_5_Y = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_5A')
				    if(catg_5_Y.VALUE == value){
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_Y','N')
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_N','N')
					}else{
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5_N','Y')
					}
				    
			      }	
	              
        		if (line_no == 'CATEGORY_1A'&& value == '0'){
				  // var catg_1a_N = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name === '5471_NA_B_1A_N')
				    var catg_1a_N = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_1A')
				   if (catg_1a_N.VALUE == value){
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_N','N')
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_Y','N')
					}else{
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_N','Y')
				       vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_Y','Y')
				       }
				  }
			   if (line_no == 'CATEGORY_1A'&& value == '1'){
				   // var catg_1a_Y = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name === '5471_NA_B_1A_Y')
				   var catg_1a_Y = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_1A')
				    if(catg_1a_Y.VALUE == value){
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_Y','N')
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_N','N')
					}else{
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1A_N','Y')
					}
				    
			      }
			    if (line_no == '1b'){
				vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1B_Y','Y')
				vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1B_N','Y')
				      }
			   
			   if (line_no == '1c'){
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1C_Y','Y')
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_1C_N','Y')
				   }
			   if (line_no == '2'){				  
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_2_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_2_N','Y')
			      }
			   if (line_no == 'CATEGORY_3'&& value == '0'){
				   var catg_3_N = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_3')
				   if (catg_3_N.VALUE == value){
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_N','N')
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_Y','N')
					}else{
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_N','Y')
				       vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_Y','Y')
				       }
				  }
			   if (line_no == 'CATEGORY_3'&& value == '1'){
					var catg_3_Y = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_3')
				    if(catg_3_Y.VALUE == value){
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_Y','N')
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_N','N')
					}else{
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_3_N','Y')
					}
			      }
			   if (line_no == 'CATEGORY_4'&& value == '0'){
				   var catg_4_N = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_4')
				   if (catg_4_N.VALUE == value){
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_N','N')
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_Y','N')
					}else{
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_N','Y')
				       vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_Y','Y')
				       }
				  }
			   if (line_no == 'CATEGORY_4'&& value == '1'){
					var catg_4_Y = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_4')
				    if(catg_4_Y.VALUE == value){
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_Y','N')
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_N','N')
					}else{
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_4_N','Y')
					}
			      }
			      
			      
			    if (line_no == 'CATEGORY_5A'&& value == '0'){
				    var catg_5a_N = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_5A')
				   if (catg_5a_N.VALUE == value){
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_N','N')
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_Y','N')
					}else{
					   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_N','Y')
				       vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_Y','Y')
				       }
				  }
			   if (line_no == 'CATEGORY_5A'&& value == '1'){
					var catg_5a_Y = vm.CatgData.find(x=> x.ATTRIB_NAME === 'CATEGORY_5A')
				    if(catg_5a_Y.VALUE == value){
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_Y','N')
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_N','N')
					}else{
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5A_N','Y')
					}
			      }
			  if (line_no == '5b'){
					vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5B_Y','Y')
				    vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5B_N','Y')
			      }
			      
			   if (line_no == '5c'&& value == '0'){
						vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5C_Y','Y')
				        vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_B_5C_N','Y')
			      }
			  if (line_no == 'd'){
				  vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_D_Y','Y')	
				  vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_D_N','Y')
			   }
			   if (line_no == 'g'){
				vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_ALT_INFO_2019_40_CODE','Y')	
			   }
			   if (line_no == 'shareholder'){
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FOB_SHAREHOLDER_N','Y')
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FOB_SHAREHOLDER_Y','Y')		
				  }
			   if (line_no == 'officer'){
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FOB_OFFICER_N','Y')	
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FOB_OFFICER_Y','Y')
				  }
			   
			   if (line_no == 'director'){
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FOB_DIRECTOR_N','Y')	
				   vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_FOB_DIRECTOR_Y','Y')	
				  }
				  
				  
		}
		
		
		// to set filed on behalf override flag and chnaging the value for FOB dropdown
        vm.selected = function(arg1){
    	   	 console.log("check this out arg::::::: ", arg1);
    	   	//console.log("check this out index::::::: ", $index);
    	   	vm.filedOnBehalf = getFobDetails(arg1.LEGAL_ENTITY_ID);
    	   	var fob_db = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name === '5471_NA_PERSON_BEHALF')
    	   	fob_db.VALUE == arg1.LEGAL_ENTITY_ID ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_PERSON_BEHALF','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_PERSON_BEHALF','Y');
        }
        
        
         vm.changedPercent = function(value){
            /*if(typeof (value)=== "number" && value<= 100 && value>=0){
                vm.checkFlag= true;
            }
            else{
                vm.checkFlag = false;
            }*/
            var value1 = value;
            var num = !isNaN(parseFloat(value1));
            if(num){
                vm.checkFlag = true;
                var num = parseFloat(value1);
                console.log(num.toFixed(6));
            }
            else{
                vm.checkFlag= false;
            }
            
             var voting_percent_db = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name === '5471_NA_C')
             voting_percent_db.VALUE == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_C','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_C','Y');

            //var num = parseFloat(value);
        };
       
        vm.findIndex_1 = function(data, attribName,value){
            	var findData = data.find(x => x.attrib_name === attribName);
            	findData.is_update = value; 
            	return findData;
       }
        
       
            
            
        var PrincipalBusinessActivity = {};
	       PrincipalBusinessActivity.action_code = "y5ypqx";
	       JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject", PrincipalBusinessActivity).then(function (data) {
	           if(data.callSuccess === "1"){
	            vm.PrincipalBusinessActivity = data.jsonObject;
	           }
	       });
            
            vm.updated1 = function(activityCode) {
	
	
				if(vm.irsF5471ScheduleNAData.length >0){
			 		var principal_business_db = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1F')
					 	principal_business_db.VALUE == activityCode ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1F','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1F','Y');
					 	var principal_business_desc = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name ==  '5471_NA_1F')
			 			if(principal_business_desc.is_update == 'Y'){
							vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1G','Y')
							}
						else(
							vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1G','N')
						)	
					}
            	//vm.attribData['5471_NA_1F']= getPrincipalBusinessActivity(activityCode);
                vm.selectedGroup1 = getPrincipalBusinessActivity(activityCode);
                //return activityCode
                };
                
                
               function getTransId(attribValue) {            	   
                    var params = {
                        "action_code": "b2d73q",
                        "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                        "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                        "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                        "filing_group_key": vm.selectedGroup.FILING_GROUP_KEY
                    };
                    return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                        if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                           // AlertService.add("error", data.errorMessage);
                        } else {
                            if (data.jsonObject.length) {
                            	vm.fobDetails = data.jsonObject;
                            	$scope.local_acc_dropdown_data = vm.fobDetails;
                         	   	//console.log("check this out a::::::: ", $scope.local_acc_dropdown_data);
                         	   	$scope.lazyLoadObj.totalDrpList = $scope.local_acc_dropdown_data;
                         	   	//console.log("check this out b::::::: ", $scope.lazyLoadObj.totalDrpList);
                         	   	vm.loadingfob = true;
                        	   	$scope.lazyLoadObj.cachedDrpList = $scope.local_acc_dropdown_data.slice(0, 10);
                        	   	//console.log("check this out c ::::::: ", $scope.lazyLoadObj.cachedDrpList);
                            	if(vm.loadPersonBehalf == true){
                            		angular.forEach(vm.fobDetails, function(bg) {
                          	   		   if (attribValue == bg.LEGAL_ENTITY_ID) {
                          	   			   vm.filedOnBehalf = bg; 
                          	   		   }
                          	   	   });
                            		
                            	}
                                //return data.jsonObject;
                            }
                        }
                    });
                };
                    
        
 
          function getCountries() {
            
        	var params = {
        			    "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                         "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
        	};

        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=kbuwei", params)
        	.then(function(data) {
        	if (data.callSuccess === "1") {
        	vm.countries = data.jsonObject;
        	}else{
                AlertService.add("", data.errorMessage);
            }
        	    });
        	    }
        	    

        function getCurrencies() {
            
            var params = {
            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            };

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3ucwjb", params)
            .then(function(data) {
            if (data.callSuccess === "1") {
            vm.currencies = data.jsonObject;
            }
               });
               }

        function getStates() {
            

            var params = {
            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            };

        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=6eyqnz", params)
        	.then(function(data) {
        	if (data.callSuccess === "1") {
        	vm.states = data.jsonObject;
        	}else{
                AlertService.add("", data.errorMessage);
            }
        	});
        	                }

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        function getFilingGroup(filingId){
            var tempObj = {};
            for(var i = 0; i<vm.filingGroup.length; i++)
            {
                if(vm.filingGroup[i].LEGAL_ENTITY_ID === filingId)
                {
                    tempObj  = vm.filingGroup[i];
                    break;
                }
            }
            return tempObj;
        }
        function getFobDetails(entityId){
            var tempObj = {};
            for(var i = 0; i<vm.fobDetails.length; i++)
            {
              if(vm.fobDetails[i].LEGAL_ENTITY_ID === entityId)
                {
                    tempObj  = vm.fobDetails[i];
                    break;
                }
             }
             return tempObj;
        }
        function getPrincipalBusinessActivity(activityCode){
            var tempObj = {};
            for(var i = 0; i<vm.PrincipalBusinessActivity.length; i++)
            {
                if(vm.PrincipalBusinessActivity[i].ACTIVITY_CODE === activityCode)
                {
                    tempObj  = vm.PrincipalBusinessActivity[i];
                    break;
                }
            }
            return tempObj;
        }
        
        vm.radioClicked = function(value, value1, value2)
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON']))
        		{
        			if(vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON'] == 'APPLD FOR' || vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON'] == 'FOREIGNUS' )
        			{
        				vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] = null;
        				value1 = null;
        				vm.refrence_iddisabled  = true;
        			}
        			var ein_reason = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(1)_EIN_MISSING_REASON')
			 			ein_reason.VALUE == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_MISSING_REASON','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_MISSING_REASON','Y');	
			 			
			 	     var ident_number = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(1)_EIN_NUMBER')
			 	     	 var iden_num =  ident_number.VALUE == undefined ? undefined: value1;		
			 			 ident_number.VALUE == iden_num ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','Y');				
        			
        			var refr_id = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(2)')
        			var refrence_d =  refr_id.VALUE == ' ' && value2 == ' '? null :refr_id.VALUE	;   
        			vm.attribData['5471_NA_1B(2)'] = refr_id.VALUE == ' '? null :vm.attribData['5471_NA_1B(2)'];
			 		refrence_d == vm.attribData['5471_NA_1B(2)'] ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(2)','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(2)','Y');	
			 		
        		}
        		
        		

        
        };

        vm.uncheckedRadio = function(value,value1,value2)
        {
        	console.log("inchange---------")
        	
        		if(angular.isDefined(vm.attribData['5471_NA_1B(1)_EIN_NUMBER']))
        		{
        			if(vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] != null)
        			{
        				vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON'] = null;
        				value1 = null;
        				vm.attribData['5471_NA_1B(2)'] = null;
        				value2 = null;
        			}
        		}
        		
        		
						
			  var identifying_number = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(1)_EIN_NUMBER')
			       var ein_value =  identifying_number.VALUE == undefined && value == '' ? undefined: value;			  
			       identifying_number.VALUE == ein_value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','Y');	
			 
			 var ein_missing_reason = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(1)_EIN_MISSING_REASON')
			      var missing_reason = ein_missing_reason.VALUE == undefined ? undefined : value1;
			     ein_missing_reason.VALUE == missing_reason? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_MISSING_REASON','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_MISSING_REASON','Y');	
			 
			  var refrence_id_2 = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(2)')
			 if((value == undefined || value == null || value == '') && (value2 == undefined || value2 == null || value2 == ''|| value2 == ' ') &&  
			 		(refrence_id_2.VALUE !== ' ' ) ){
						vm.attribData['5471_NA_1B(2)'] = refrence_id.VALUE; 
				}
				var refrence =  refrence_id_2.VALUE == ' '? null :refrence_id_2.VALUE	;
			 		refrence == vm.attribData['5471_NA_1B(2)'] ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(2)','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(2)','Y');	
			 						
        		
				
		   
		   if(vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] == undefined ||vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] == null || vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] == '')
        			{
        				vm.refrence_iddisabled  = true;
        			}
        		else {
						vm.refrence_iddisabled  = false;
						}		
        	
        } ;
        
        
         vm.ref_id = function(value, value1, value2)
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_1B(2)']))
        		{
        			var refrence_id = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(2)')
        			var refrence_id_d =  refrence_id.VALUE == ' '? null :refrence_id.VALUE	;
        			    value = value == '' ? null : value;
			 			refrence_id_d == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(2)','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(2)','Y');	
			 			 
        			var ein_missing_reason = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(1)_EIN_MISSING_REASON')
			 			ein_missing_reason.VALUE == vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON'] ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_MISSING_REASON','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_MISSING_REASON','Y');	
			 			
			 	     var identifying_number = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(1)_EIN_NUMBER')
			 	     	 var identity=  identifying_number.VALUE === undefined ? undefined: value2;		
			 			 //identifying_number.VALUE == value2 ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','Y');			
        				identifying_number.VALUE == identity? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(1)_EIN_NUMBER','Y');		
        			
        		}
        		
        		

        
        };
        
		//added by Hardi on 14-Feb-2023
		vm.prev_ref_id = function(value)
        {
			var prev_refrence_id = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1B(3)');
			var prev_refrence_id_d =  prev_refrence_id.VALUE == ' '? null :prev_refrence_id.VALUE	;
        	value = value == '' ? null : value;
			prev_refrence_id_d == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(3)','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1B(3)','Y');	
			 			 
		}
        
          vm.changeCountryLaws = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_1C']))
        		{
        			var country_law = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1C')  
        			country_law.VALUE == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1C','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1C','Y');	
			 		
        		}

        
        };
          vm.changePrincipalPlace = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_1E']))
        		{
        			var country_law = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1E')
			 			country_law.VALUE == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1E','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1E','Y');	
			 		
        		}

        
        };
        
        
        
         vm.changeDateIncorp = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_1D']))
        		{
        		value = $filter('date')(value, "MM/dd/yyyy");
        		var country_law = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F5471_NA_1D')
			 	     country_law.VALUE == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1D','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_1D','Y');	
			 		
        		}

        
        };
        
        
          vm.changeCFCDate = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_CFC_BEGIN_DATE']) )
        		{
        		value = $filter('date')(value, "MM/dd/yyyy");
        		var cfc_begin_date = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name  === '5471_NA_CFC_BEGIN_DATE')
			 	     cfc_begin_date.VALUE == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_CFC_BEGIN_DATE','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_CFC_BEGIN_DATE','Y');	
			 		
        		}
				
				if(angular.isDefined(vm.attribData['5471_NA_CFC_END_DATE']) )
        		{
        		value = $filter('date')(value, "MM/dd/yyyy");
        		var cfc_begin_date = vm.irsF5471ScheduleNAData.find(x=> x.attrib_name  === '5471_NA_CFC_END_DATE')
			 	     cfc_begin_date.VALUE == value ? vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_CFC_END_DATE','N') : vm.findIndex_1(vm.irsF5471ScheduleNAData, '5471_NA_CFC_END_DATE','Y');	
			 		
        		}
        
        };
        
        
        
        
        
        
        vm.clicked = function()
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_2D_EIN_MISSING_REASON']))
        		{
        			if(vm.attribData['5471_NA_2D_EIN_MISSING_REASON'] == 'APPLD FOR' || vm.attribData['5471_NA_2D_EIN_MISSING_REASON'] == 'FOREIGNUS' )
        			{
        				vm.attribData['5471_NA_2D_EIN_NUMBER'] = null;
        			}
        			/*else
        			{
        				vm.attribData['5471_NA_2D_EIN_MISSING_REASON'] = vm.attribData['5471_NA_2D_EIN_NUMBER'];
        			}*/
        		}

        
        };
        
        vm.unchecked = function()
        {
        	console.log("inchange---------")
        	
        		if(/*angular.isDefined(vm.attribData['5471_NA_2D_EIN_MISSING_REASON']) && */angular.isDefined(vm.attribData['5471_NA_2D_EIN_NUMBER']))
        		{
        			if(vm.attribData['5471_NA_2D_EIN_NUMBER'] != null)
        			{
        				//vm.attribData['5471_NA_2D_EIN_MISSING_REASON'] = vm.attribData['5471_NA_2D_EIN_NUMBER'];
        				vm.attribData['5471_NA_2D_EIN_MISSING_REASON'] = null;
        			}
        		}
        	
        };
        
        vm.unchecked2a = function()
        {
        	console.log("inchange---------")
        	
        		if(/*angular.isDefined(vm.attribData['5471_NA_2A_EIN_MISSING_REASON']) &&*/ angular.isDefined(vm.attribData['5471_NA_2A_EIN_NUMBER']))
        		{
        			if(vm.attribData['5471_NA_2A_EIN_NUMBER'] != null)
        			{
        				vm.attribData['5471_NA_2A_EIN_MISSING_REASON'] = null;
        			}
        		}
        	
        };
        
         vm.clicked2a = function()
        {
        
        		if(angular.isDefined(vm.attribData['5471_NA_2A_EIN_MISSING_REASON']))
        		{
        			if(vm.attribData['5471_NA_2A_EIN_MISSING_REASON'] == 'APPLD FOR' || vm.attribData['5471_NA_2A_EIN_MISSING_REASON'] == 'FOREIGNUS' )
        			{
        				vm.attribData['5471_NA_2A_EIN_NUMBER'] = null;
        			}
        			/*else
        			{
        				vm.attribData['5471_NA_2D_EIN_MISSING_REASON'] = vm.attribData['5471_NA_2D_EIN_NUMBER'];
        			}*/
        		}

        
        };
        
        vm.personBnssChange = function(){
            if(vm.attribData['5471_NA_2D_FLAG'] === "N")
            {
                vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] = null;
                vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_2'] = null;
                vm.attribData['5471_NA_2D_EIN_NUMBER'] = null;
				vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] = null;
				vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] = null;
				vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] = null;
			    vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] = null;
			    vm.countryCust = null;
			    vm.line_2d_name = false;
					vm.add_flag_2d = false;
					vm.add_flag_2d_f = false;
					vm.line_2d_country_flag = false;
					vm.city_flag_2d = false;
					vm.state_flag_2d= false;
					vm.zip_flag_2d= false;
					vm.zip_flag_2d_pattern= false;
            }
            if(vm.attribData['5471_NA_2D_FLAG'] === "P")
                vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_2'] = null;
                
        };
        
        
        
         //line 1a
        vm.changeCountry = function(value, selectedCountry){
    		 if(value !== 'US'){  
    		 vm.attribData['5471_NA_CFC_F_COUNTRY'] = selectedCountry;
    		 vm.attribData['5471_NA_CFC_F_ADDRESS_1'] = null;
    		 vm.attribData['5471_NA_CFC_F_ADDRESS_2'] = null;
    		 vm.attribData['5471_NA_CFC_F_CITY'] = null;
    		 vm.attribData['5471_NA_CFC_F_STATE'] = null;
    		 vm.attribData['5471_NA_CFC_F_ZIPCODE'] = null;
    		 }
    		 
    	}
    	
        // line 2a
        vm.changeCountryAgent = function(value, selectedCountryAgent){
	     //   vm.line_2a_country_flag = selectedCountryAgent == undefined ? true : false ;
        	if(selectedCountryAgent !== 'US'&& selectedCountryAgent != undefined && selectedCountryAgent != null ){
        		 vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] = selectedCountryAgent;
    		     vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] = (vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] == undefined || vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] == null|| vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] == '') ? null : vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'];
    		     vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] = (vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] == undefined || vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] == null|| vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] == '') ? null : vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'];
    		     vm.attribData['5471_NA_BRANCH_US_F_CITY'] =   (vm.attribData['5471_NA_BRANCH_US_CITY'] == undefined || vm.attribData['5471_NA_BRANCH_US_CITY'] == null|| vm.attribData['5471_NA_BRANCH_US_CITY'] == '') ? null : vm.attribData['5471_NA_BRANCH_US_CITY'];
    		     vm.attribData['5471_NA_BRANCH_US_F_STATE'] = null;
    		     vm.attribData['5471_NA_BRANCH_F_ZIP'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_COUNTRY'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_STATE'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_ZIP'] = null;
    		     
        	}
        	else if (selectedCountryAgent == 'US' && selectedCountryAgent != undefined && selectedCountryAgent != null)
        	{ 
	             vm.attribData['5471_NA_BRANCH_US_COUNTRY'] = selectedCountryAgent;
    		     vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] = (vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] == undefined || vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] == null|| vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] == '') ? null : vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'];
    		     vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] = (vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] == undefined || vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] == null|| vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] == '') ? null : vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'];
    		     vm.attribData['5471_NA_BRANCH_US_CITY'] = (vm.attribData['5471_NA_BRANCH_US_F_CITY'] == undefined || vm.attribData['5471_NA_BRANCH_US_F_CITY'] == null|| vm.attribData['5471_NA_BRANCH_US_F_CITY'] == '') ? null : vm.attribData['5471_NA_BRANCH_US_F_CITY'];
    		     vm.attribData['5471_NA_BRANCH_US_STATE'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_ZIP'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_F_STATE'] = null;
    		     vm.attribData['5471_NA_BRANCH_F_ZIP'] = null;
    		     
    		     }
    		 else {
				 vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_F_CITY'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_F_STATE'] = null;
    		     vm.attribData['5471_NA_BRANCH_F_ZIP'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_COUNTRY'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_CITY'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_STATE'] = null;
    		     vm.attribData['5471_NA_BRANCH_US_ZIP'] = null;
              } 
              
              line_2a_validations ('change');   
              
              
        }
        
        ///Line 2c

        vm.changeCountryCorporation = function(value, selectedCountryCorp){
	    
        	if(selectedCountryCorp !== 'US'&& selectedCountryCorp != undefined && selectedCountryCorp != null ){
        		 vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] = selectedCountryCorp;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] = (vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] == undefined || vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] == null|| vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] == '') ? null : vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'];
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] = (vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] == undefined || vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] == null|| vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] == '') ? null : vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'];
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] =   (vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] == undefined || vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] == null|| vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] == '') ? null : vm.attribData['5471_NA_FOREIGN_AGENT_CITY'];
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] = null;
    		     
        	}
        	else if (selectedCountryCorp == 'US' && selectedCountryCorp != undefined && selectedCountryCorp != null)
        	{ 
	             vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] = selectedCountryCorp;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] = (vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] == undefined || vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] == null|| vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] == '') ? null : vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'];
    		     vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] = (vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] == undefined || vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] == null|| vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] == '') ? null : vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'];
    		     vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] = (vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] == undefined || vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] == null|| vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] == '') ? null : vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'];
    		     vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'] = null;
    		     
    		     }
    		 else {
				 vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] = null;
    		     vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] = null;
              } 
              
              line_2c_validations ('change');   
              
              
        }
        
        //line 2d
         vm.changeCountryCust = function(value, selectedCountryCust){
	    
        	if(selectedCountryCust !== 'US'&& selectedCountryCust != undefined && selectedCountryCust != null ){
        		 vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] = selectedCountryCust;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] = (vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] == undefined || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] == null|| vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] == '') ? null : vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'];
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] = (vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] == undefined || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] == null|| vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] == '') ? null : vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'];
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] =   (vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] == undefined || vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] == null|| vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] == '') ? null : vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'];
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] = null;
    		     
        	}
        	else if (selectedCountryCust == 'US' && selectedCountryCust != undefined && selectedCountryCust != null)
        	{ 
	             vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY']= selectedCountryCust;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1']=(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] == undefined || vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] == null|| vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] == '') ? null : vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'];
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2']=(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] == undefined || vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] == null|| vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] == '') ? null : vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'];
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY']=(vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] == undefined || vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] == null|| vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] == '') ? null : vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'];
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE']= null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP']= null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY']= null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE']= null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP']= null;
    		     
    		     }
    		 else {
				 vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] = null;
    		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] = null;
              } 
              
              line_2d_validations ('change');   
              
              
        }
        
        
        vm.refreshGoldIdLookup = function(searchInput) {
            vm.filteredGoldIds = (vm.goldLookupList || []).filter(function(item) {
                var legalEntityId = ($parse('legal_entity_id')(item) || '') + '-' + ($parse('legal_entity_name')(item) || '') ;
                return legalEntityId.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
            });
            vm.filteredGoldIds = vm.filteredGoldIds.splice(0, 10);
        };



       vm.change2a = function(attrib_name,value){
	      if (attrib_name == '5471_NA_BRANCH_US_NAME_1' && value != undefined && value != null && value != '' )
	      	 {
		     vm.line_2a_name = false;
		     }
		     else {
			 vm.line_2a_name = true;
			 }
		  if (attrib_name == '5471_NA_BRANCH_US_F_ADDR_1' && value != undefined && value != null  && value != '' )
	      	{
		     vm.add_flag_2a_f = false;
		     }  
		  if (attrib_name == '5471_NA_BRANCH_US_ADDR_LINE_1' && value != undefined && value != null  && value != '' )
	      	{
		     vm.add_flag_2a = false;
		     }  
		 if (attrib_name == '5471_NA_BRANCH_US_F_CITY' && value != undefined && value != null  && value != '')
	      	{
		     vm.city_flag_2a = false;
		     }  
		  if (attrib_name == '5471_NA_BRANCH_US_CITY' && value != undefined && value != null  && value != '')
	      	{
		     vm.city_flag_2a = false;
		     } 
		   if (attrib_name == '5471_NA_BRANCH_US_F_STATE' && value != undefined && value != null  && value != '')
	      	{
		     vm.state_flag_2a = false;
		     }  
		  if (attrib_name == '5471_NA_BRANCH_US_STATE' && value != undefined && value != null  && value != '')
	      	{
		     vm.state_flag_2a = false;
		     }  
		  if (attrib_name == '5471_NA_BRANCH_F_ZIP' && value != undefined && value != null  && value != '')
	      	{
		     vm.zip_flag_2a = false;
		     }  
		  if (attrib_name == '5471_NA_BRANCH_US_ZIP' && value != undefined && value != null  && value != '')
	      	{
		     vm.zip_flag_2a = false;
		     vm.zip_flag_2a_pattern = false;
		     } 
		  if (attrib_name == '5471_NA_BRANCH_US_ZIP' && value == undefined)
	      	{
			 vm.zip_flag_2a = false;
		     vm.zip_flag_2a_pattern = true;
		     }     
		        
		          
		      
		     line_2a_validations();
	
	}
	
	   function line_2a_validations (value){
		
		if (
				 (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_COUNTY'])) || vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] == null ||  vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] == '')  &&
				 (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'])) || vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] == null ||  vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] == '')  &&
				 (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'])) || vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] == null ||  vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_CITY'])) || vm.attribData['5471_NA_BRANCH_US_F_CITY'] == null ||  vm.attribData['5471_NA_BRANCH_US_F_CITY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_STATE'])) || vm.attribData['5471_NA_BRANCH_US_F_STATE'] == null ||  vm.attribData['5471_NA_BRANCH_US_F_STATE'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_F_ZIP'])) || vm.attribData['5471_NA_BRANCH_F_ZIP'] == null ||  vm.attribData['5471_NA_BRANCH_F_ZIP'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_COUNTRY'])) || vm.attribData['5471_NA_BRANCH_US_COUNTRY'] == null ||  vm.attribData['5471_NA_BRANCH_US_COUNTRY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'])) || vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] == null ||  vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'])) || vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] == null ||  vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_CITY'])) || vm.attribData['5471_NA_BRANCH_US_CITY'] == null ||  vm.attribData['5471_NA_BRANCH_US_CITY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_STATE'])) || vm.attribData['5471_NA_BRANCH_US_STATE'] == null ||  vm.attribData['5471_NA_BRANCH_US_STATE'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ZIP'])) || vm.attribData['5471_NA_BRANCH_US_ZIP'] == null ||  vm.attribData['5471_NA_BRANCH_US_ZIP'] == '')  
              ){
				vm.line_2a_name = false;
        		vm.line_2a_country_flag = false;
		        vm.add_flag_2a = false;
		        vm.add_flag_2a_f = false;
		        vm.city_flag_2a = false;
		        vm.state_flag_2a = false;
		        vm.zip_flag_2a = false;
				}
			
		//if(
		  vm.line_2a_name =  (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1']))&& vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != null && vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_CITY'])) && vm.attribData['5471_NA_BRANCH_US_CITY'] != null && vm.attribData['5471_NA_BRANCH_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_COUNTRY'])) && vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != null && vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_STATE'])) && vm.attribData['5471_NA_BRANCH_US_STATE'] != null && vm.attribData['5471_NA_BRANCH_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ZIP'])) && vm.attribData['5471_NA_BRANCH_US_ZIP'] != null && vm.attribData['5471_NA_BRANCH_US_ZIP'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_ADDR_1']))&& vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != null && vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_COUNTY'])) && vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] != null && vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) || vm.attribData['5471_NA_BRANCH_US_NAME_1'] == null || vm.attribData['5471_NA_BRANCH_US_NAME_1'] == '')
		
		//){
								//   vm.line_2a_name = true;
								   /*//AlertService.add("error", "Please fill out required fields.", 1000);
								   return;*/
		//}
		
	/*	if(
				(((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_ADDR_1']))&& vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != null && vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != "")
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_COUNTY'])) && vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] != null && vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) || vm.attribData['5471_NA_BRANCH_US_NAME_1'] == null || vm.attribData['5471_NA_BRANCH_US_NAME_1'] == '')
		
		){
								   vm.line_2a_name = true;
								   AlertService.add("error", "Please fill out required fields.", 1000);
								   return;
		}
		*/
		
		
		//if(
		 vm.line_2a_country_flag =   (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1']))&& vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != null && vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_CITY'])) && vm.attribData['5471_NA_BRANCH_US_CITY'] != null && vm.attribData['5471_NA_BRANCH_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_STATE'])) && vm.attribData['5471_NA_BRANCH_US_STATE'] != null && vm.attribData['5471_NA_BRANCH_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ZIP'])) && vm.attribData['5471_NA_BRANCH_US_ZIP'] != null && vm.attribData['5471_NA_BRANCH_US_ZIP'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_ADDR_1']))&& vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != null && vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != ''))
				&&
				(!(angular.isDefined(vm.countryAgent)) || vm.countryAgent == null || vm.countryAgent == '')
		
		//){
								//   vm.line_2a_country_flag = true;
								 /*  AlertService.add("error", "Please fill out required fields.", 1000);
								   return;*/
		//}
		
		/*if(
		  (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_ADDR_1']))&& vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != null && vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] != "")
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != ''))
				&&
				(!(angular.isDefined(vm.countryAgent)) || vm.countryAgent == null || vm.countryAgent == '')
		
		){
								   vm.line_2a_country_flag = true;
								   AlertService.add("error", "Please fill out required fields.", 1000);
								   return;
		}*/
		
		
		//if(
		vm.add_flag_2a =  (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_COUNTRY']))&& vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != null && vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_CITY'])) && vm.attribData['5471_NA_BRANCH_US_CITY'] != null && vm.attribData['5471_NA_BRANCH_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_STATE'])) && vm.attribData['5471_NA_BRANCH_US_STATE'] != null && vm.attribData['5471_NA_BRANCH_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ZIP'])) && vm.attribData['5471_NA_BRANCH_US_ZIP'] != null && vm.attribData['5471_NA_BRANCH_US_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'])) || vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] == null || vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] == '')
				&& vm.countryAgent == 'US'
		
		//){
	//							   vm.add_flag_2a = true;
								  /* AlertService.add("error", "Please fill out required fields.", 1000);
								   return;*/
	//	}
		
		
		//if(
		vm.add_flag_2a_f =   (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_COUNTY']))&& vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] != null && vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'])) || vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] == null || vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] == '')
				&& vm.countryAgent != 'US'
		
		//){
								   //vm.add_flag_2a = true;
								   /*AlertService.add("error", "Please fill out required fields.", 1000);
								   return;*/
	//	}
		
	//	if(
		vm.city_flag_2a =  (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_COUNTRY']))&& vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != null && vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != '')
		         ||
		        ((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1']))&& vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != null && vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_STATE'])) && vm.attribData['5471_NA_BRANCH_US_STATE'] != null && vm.attribData['5471_NA_BRANCH_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ZIP'])) && vm.attribData['5471_NA_BRANCH_US_ZIP'] != null && vm.attribData['5471_NA_BRANCH_US_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_CITY'])) || vm.attribData['5471_NA_BRANCH_US_CITY'] == null || vm.attribData['5471_NA_BRANCH_US_CITY'] == '')
				&& vm.countryAgent == 'US'
		
		//){
								  // vm.city_flag_2a = true;
								  /* AlertService.add("error", "Please fill out required fields.", 1000);
								   return;*/
		//}
		
		//if(
		 vm.state_flag_2a =   (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1']))&& vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != null && vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_CITY'])) && vm.attribData['5471_NA_BRANCH_US_CITY'] != null && vm.attribData['5471_NA_BRANCH_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_COUNTRY']))&& vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != null && vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ZIP'])) && vm.attribData['5471_NA_BRANCH_US_ZIP'] != null && vm.attribData['5471_NA_BRANCH_US_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_STATE'])) || vm.attribData['5471_NA_BRANCH_US_STATE'] == null || vm.attribData['5471_NA_BRANCH_US_STATE'] == '')
				&& vm.countryAgent == 'US'
		
			//){
									 //  vm.state_flag_2a = true;
									   /*AlertService.add("error", "Please fill out required fields.", 1000);
									   return;*/
			//}
			
			
			//if(
		  vm.zip_flag_2a =  (((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1']))&& vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != null && vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_CITY'])) && vm.attribData['5471_NA_BRANCH_US_CITY'] != null && vm.attribData['5471_NA_BRANCH_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_NAME_1'])) && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != null && vm.attribData['5471_NA_BRANCH_US_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_COUNTRY']))&& vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != null && vm.attribData['5471_NA_BRANCH_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_BRANCH_US_STATE'])) && vm.attribData['5471_NA_BRANCH_US_STATE'] != null && vm.attribData['5471_NA_BRANCH_US_STATE'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_BRANCH_US_ZIP'])) || vm.attribData['5471_NA_BRANCH_US_ZIP'] == null || vm.attribData['5471_NA_BRANCH_US_ZIP'] == '')
				&& vm.countryAgent == 'US'
		
		//	){
									  // vm.zip_flag_2a = true;
									  /* AlertService.add("error", "Please fill out required fields.", 1000);
									   return;*/
		//	}
		
		   if (value == 'change' && vm.countryAgent == 'US')
		   {if (vm.line_2a_name  == true || 
        		vm.line_2a_country_flag == true||
		        vm.add_flag_2a == true || 
		        vm.city_flag_2a == true || 
		        vm.state_flag_2a == true || 
		        vm.zip_flag_2a ==  true){
				vm.validations_2a = true;
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			}
		   
		   if (value == 'change' && vm.countryAgent != 'US')
		   {if (vm.line_2a_name  == true || 
        		vm.line_2a_country_flag == true||
		        vm.add_flag_2a_f == true ){
			    vm.city_flag_2a = false;
		        vm.state_flag_2a = false;
		        vm.zip_flag_2a= false;
		        vm.validations_2a = true;
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			}

	}
	     


         vm.change2c = function(attrib_name,value){
	      if (attrib_name == '5471_NA_FOREIGN_AGENT_NAME_1' && value != undefined && value != null && value != '' )
	      	 {
		     vm.line_2c_name = false;
		     }
		     else {
			 vm.line_2c_name = true;
			 }
		  if (attrib_name == '5471_NA_FOREIGN_AGENT_F_ADDR_1' && value != undefined && value != null  && value != '' )
	      	{
		     vm.add_flag_2c_f = false;
		     }  
		  if (attrib_name == '5471_NA_FOREIGN_AGENT_ADDR_1' && value != undefined && value != null  && value != '' )
	      	{
		     vm.add_flag_2c = false;
		     }  
		 if (attrib_name == '5471_NA_FOREIGN_AGENT_F_CITY' && value != undefined && value != null  && value != '')
	      	{
		     vm.city_flag_2c = false;
		     }  
		  if (attrib_name == '5471_NA_FOREIGN_AGENT_CITY' && value != undefined && value != null  && value != '')
	      	{
		     vm.city_flag_2c = false;
		     } 
		   if (attrib_name == '5471_NA_FOREIGN_AGENT_F_STATE' && value != undefined && value != null  && value != '')
	      	{
		     vm.state_flag_2c = false;
		     }  
		  if (attrib_name == '5471_NA_FOREIGN_AGENT_STATE' && value != undefined && value != null  && value != '')
	      	{
		     vm.state_flag_2c = false;
		     }  
		  if (attrib_name == '5471_NA_FOREIGN_AGENT_F_ZIP' && value != undefined && value != null  && value != '')
	      	{
		     vm.zip_flag_2c = false;
		     }  
		  if (attrib_name == '5471_NA_FOREIGN_AGENT_ZIP' && value != undefined && value != null  && value != '')
	      	{
		     vm.zip_flag_2c = false;
		     vm.zip_flag_2c_pattern = false;
		     } 
		  if (attrib_name == '5471_NA_FOREIGN_AGENT_ZIP' && value == undefined)
	      	{
			 vm.zip_flag_2c = false;
		     vm.zip_flag_2c_pattern = true;
		     }     
		        
		          
		      
		     line_2c_validations();
	
	}
	
	   function line_2c_validations (value){
		
		if (
				 (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'])) || vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] == '')  &&
				 (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'])) || vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] == '')  &&
				 (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'])) || vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'])) || vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'])) || vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'])) || vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'])) || vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'])) || vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'])) || vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_CITY'])) || vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_STATE'])) || vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'])) || vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] == null ||  vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] == '')  
              ){
				vm.line_2c_name = false;
        		vm.line_2c_country_flag = false;
		        vm.add_flag_2c = false;
		        vm.add_flag_2c_f = false;
		        vm.city_flag_2c = false;
		        vm.state_flag_2c = false;
		        vm.zip_flag_2c = false;
				}
			
		//if(
		  vm.line_2c_name =  (((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1']))&& vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_CITY'])) && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'])) && vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_STATE'])) && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'])) && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1']))&& vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'])) && vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) || vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] == null || vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] == '')
		
		
		 vm.line_2c_country_flag =   (((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1']))&& vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_CITY'])) && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_STATE'])) && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'])) && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1']))&& vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != ''))
				&&
				(!(angular.isDefined(vm.countryCorporation)) || vm.countryCorporation == null || vm.countryCorporation == '')
		
		
		vm.add_flag_2c =  (((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY']))&& vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_CITY'])) && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_STATE'])) && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'])) && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'])) || vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] == null || vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] == '')
				&& vm.countryCorporation == 'US'
	
		vm.add_flag_2c_f =   (((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY']))&& vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'])) || vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] == null || vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] == '')
				&& vm.countryCorporation != 'US'
		
		
		vm.city_flag_2c =  (((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY']))&& vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != '')
		         ||
		        ((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1']))&& vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_STATE'])) && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'])) && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_CITY'])) || vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] == null || vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] == '')
				&& vm.countryCorporation == 'US'
		
		
		 vm.state_flag_2c =   (((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1']))&& vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_CITY'])) && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY']))&& vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'])) && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_STATE'])) || vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] == null || vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] == '')
				&& vm.countryCorporation == 'US'
		
		  vm.zip_flag_2c =  (((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1']))&& vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_CITY'])) && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'])) && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY']))&& vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_STATE'])) && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != null && vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'])) || vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] == null || vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] == '')
				&& vm.countryCorporation == 'US'
		
		
		   if (value == 'change' && vm.countryCorporation == 'US')
		   {if (vm.line_2c_name  == true || 
        		vm.line_2c_country_flag == true||
		        vm.add_flag_2c == true || 
		        vm.city_flag_2c == true || 
		        vm.state_flag_2c == true || 
		        vm.zip_flag_2c ==  true){
				vm.validations_2c = true;
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			}
		   
		   if (value == 'change' && vm.countryCorporation != 'US')
		   {if (vm.line_2c_name  == true || 
        		vm.line_2c_country_flag == true||
		        vm.add_flag_2c_f == true ){
			    vm.city_flag_2c = false;
		        vm.state_flag_2c = false;
		        vm.zip_flag_2c= false;
		        vm.validations_2c = true;
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			}

	}
	
	
	
	vm.change2d = function(attrib_name,value){
	      if (attrib_name == '5471_NA_PER_RECORDS_CUST_NAME_1' && value != undefined && value != null && value != '' )
	      	 {
		     vm.line_2d_name = false;
		     }
		     else {
			 vm.line_2d_name = true;
			 }
		  if (attrib_name == '5471_NA_PER_RECORDS_CUST_F_ADDR_1' && value != undefined && value != null  && value != '' )
	      	{
		     vm.add_flag_2d_f = false;
		     }  
		  if (attrib_name == '5471_NA_PER_RECORDS_CUST_US_ADDR_1' && value != undefined && value != null  && value != '' )
	      	{
		     vm.add_flag_2d = false;
		     }  
		 if (attrib_name == '5471_NA_PER_RECORDS_CUST_F_CITY' && value != undefined && value != null  && value != '')
	      	{
		     vm.city_flag_2d = false;
		     }  
		  if (attrib_name == '5471_NA_PER_RECORDS_CUST_US_CITY' && value != undefined && value != null  && value != '')
	      	{
		     vm.city_flag_2d = false;
		     } 
		   if (attrib_name == '5471_NA_PER_RECORDS_CUST_F_STATE' && value != undefined && value != null  && value != '')
	      	{
		     vm.state_flag_2d = false;
		     }  
		  if (attrib_name == '5471_NA_PER_RECORDS_CUST_US_STATE' && value != undefined && value != null  && value != '')
	      	{
		     vm.state_flag_2d = false;
		     }  
		  if (attrib_name == '5471_NA_PER_RECORDS_CUST_F_ZIP' && value != undefined && value != null  && value != '')
	      	{
		     vm.zip_flag_2d = false;
		     }  
		  if (attrib_name == '5471_NA_PER_RECORDS_CUST_US_ZIP' && value != undefined && value != null  && value != '')
	      	{
		     vm.zip_flag_2d = false;
		     vm.zip_flag_2d_pattern = false;
		     } 
		  if (attrib_name == '5471_NA_PER_RECORDS_CUST_US_ZIP' && value == undefined)
	      	{
			 vm.zip_flag_2d = false;
		     vm.zip_flag_2d_pattern = true;
		     }     
		        
		          
		      
		     line_2d_validations();
	
	}
		
		
		 function line_2d_validations (value){
		
		if (
				 (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] == '')  &&
				 (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] == '')  &&
				 (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] == '')  &&
    		     (!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] == null ||  vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] == '')  
              ){
				vm.line_2d_name = false;
        		vm.line_2d_country_flag = false;
		        vm.add_flag_2d = false;
		        vm.add_flag_2d_f = false;
		        vm.city_flag_2d = false;
		        vm.state_flag_2d = false;
		        vm.zip_flag_2d = false;
				}
			
		//if(
		  vm.line_2d_name =  (((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] == null || vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] == '')
		
		
		 vm.line_2d_country_flag =   (((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != ''))
				&&
				(!(angular.isDefined(vm.countryCust)) || vm.countryCust == null || vm.countryCust == '')
		
		
		vm.add_flag_2d =  (((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] == null || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] == '')
				&& vm.countryCust == 'US'
	
		vm.add_flag_2d_f =   (((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] == null || vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] == '')
				&& vm.countryCust != 'US'
		
		
		vm.city_flag_2d =  (((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != '')
		         ||
		        ((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] == null || vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] == '')
				&& vm.countryCust == 'US'
		
		
		 vm.state_flag_2d =   (((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] == null || vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] == '')
				&& vm.countryCust == 'US'
		
		  vm.zip_flag_2d =  (((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] != '')
				 ||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_NAME_1'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY']))&& vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] != '')
				||
				((angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'])) && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != null && vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] != ''))
				&&
				(!(angular.isDefined(vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'])) || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] == null || vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] == '')
				&& vm.countryCust == 'US'
		
		
		   if (value == 'change' && vm.countryCust == 'US')
		   {if (vm.line_2d_name  == true || 
        		vm.line_2d_country_flag == true||
		        vm.add_flag_2d == true || 
		        vm.city_flag_2d == true || 
		        vm.state_flag_2d == true || 
		        vm.zip_flag_2d ==  true){
				vm.validations_2d = true;
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			}
		   
		   if (value == 'change' && vm.countryCust != 'US')
		   {if (vm.line_2d_name  == true || 
        		vm.line_2d_country_flag == true||
		        vm.add_flag_2d_f == true ){
			    vm.city_flag_2d = false;
		        vm.state_flag_2d = false;
		        vm.zip_flag_2d= false;
		        vm.validations_2d = true;
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			}

	}
	
	
	
	
	
        
        vm.saveForm = function () {

            var editRow = [];
            var addRow = [];
            var deleteRow = [];
            var scheduleSettings = [{
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            }];
           
         if(angular.isDefined(vm.attribData['5471_NA_CFC_BEGIN_DATE']) ) {
            	vm.attribData['5471_NA_CFC_BEGIN_DATE'] = $filter('date')(vm.attribData['5471_NA_CFC_BEGIN_DATE'], "MM/dd/yyyy");
        	}
         if(angular.isDefined(vm.attribData['5471_NA_CFC_END_DATE']) ) {
         	vm.attribData['5471_NA_CFC_END_DATE'] = $filter('date')(vm.attribData['5471_NA_CFC_END_DATE'], "MM/dd/yyyy");
     	    }
         if(angular.isDefined(vm.attribData['5471_NA_1D']) ) {
         	vm.attribData['5471_NA_1D'] = $filter('date')(vm.attribData['5471_NA_1D'], "MM/dd/yyyy");
     	}
         
         if(
            (vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] == undefined || vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] == null  || vm.attribData['5471_NA_1B(1)_EIN_NUMBER'] == '' 
            )
			&& (vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON']  == undefined || vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON'] == null  || vm.attribData['5471_NA_1B(1)_EIN_MISSING_REASON'] == '' 
			 )
			 && (vm.attribData['5471_NA_1B(2)']  == undefined || vm.attribData['5471_NA_1B(2)'] == null  || vm.attribData['5471_NA_1B(2)'] == '' || vm.attribData['5471_NA_1B(2)'] == ' '
			 )
			){ 
				AlertService.add("error", "Please enter Identifying number or EIN missing reason Or Reference ID number ",4000);
	     	    return;
				
			} 
			
			      
         if(vm.countryVar === 'US') {
    		 vm.attribData['5471_NA_CFC_US_COUNTRY'] = vm.countryVar;  
    		 vm.attribData['5471_NA_CFC_F_COUNTRY'] = null;
    		 vm.attribData['5471_NA_CFC_F_ADDRESS_1'] = null;
    		 vm.attribData['5471_NA_CFC_F_ADDRESS_2'] = null;
    		 vm.attribData['5471_NA_CFC_F_CITY'] = null;
    		 vm.attribData['5471_NA_CFC_F_STATE'] = null;
    		 vm.attribData['5471_NA_CFC_F_ZIPCODE'] = null;
    		
    		} else{ 
    			vm.attribData['5471_NA_CFC_F_COUNTRY'] = vm.countryVar;
    			vm.attribData['5471_NA_CFC_US_COUNTRY'] = null;
    			vm.attribData['5471_NA_CFC_US_ADDRESS_1'] = null;
       		    vm.attribData['5471_NA_CFC_US_ADDRESS_2'] = null;
       		    vm.attribData['5471_NA_CFC_US_CITY'] = null;
       		    vm.attribData['5471_NA_CFC_US_STATE'] = null;
       		    vm.attribData['5471_NA_CFC_US_ZIPCODE'] = null;
    		  }	
         if(vm.countryCust === 'US') {
    		 vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] = vm.countryCust;  
    		 vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] = null;
   		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_1'] = null;
   		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ADDR_2'] = null;
   		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_CITY'] = null;
   		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_STATE'] = null;
   		     vm.attribData['5471_NA_PER_RECORDS_CUST_F_ZIP'] = null;
    		
    		} else{ 
    			vm.attribData['5471_NA_PER_RECORDS_CUST_F_COUNTRY'] = vm.countryCust;
    			vm.attribData['5471_NA_PER_RECORDS_CUST_US_COUNTRY'] = null;
      		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_1'] = null;
      		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ADDR_2'] = null;
      		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_CITY'] = null;
      		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_STATE'] = null;
      		     vm.attribData['5471_NA_PER_RECORDS_CUST_US_ZIP'] = null;
    		  }	
         if(vm.countryAgent === 'US') {
		    		vm.attribData['5471_NA_BRANCH_US_COUNTRY'] = vm.countryAgent;  
		    		vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] = null;
		            vm.attribData['5471_NA_BRANCH_US_F_ADDR_1'] = null;
		            vm.attribData['5471_NA_BRANCH_US_F_ADDR_2'] = null;
		            vm.attribData['5471_NA_BRANCH_US_F_CITY'] = null;
		            vm.attribData['5471_NA_BRANCH_US_F_STATE'] = null;
		            vm.attribData['5471_NA_BRANCH_F_ZIP'] = null;
    		
    		} else{ 
    			vm.attribData['5471_NA_BRANCH_US_F_COUNTY'] = vm.countryAgent;
    			vm.attribData['5471_NA_BRANCH_US_COUNTRY'] = null;
            	vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_1'] = null;
            	vm.attribData['5471_NA_BRANCH_US_ADDR_LINE_2'] = null;
            	vm.attribData['5471_NA_BRANCH_US_CITY'] = null;
            	vm.attribData['5471_NA_BRANCH_US_STATE'] = null;
            	vm.attribData['5471_NA_BRANCH_US_ZIP'] = null;
    		  }	
			  if(vm.countryCorporation === 'US') {
			  vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] = vm.countryCorporation;  
			  vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] = null;
	          vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_1'] = null;
	          vm.attribData['5471_NA_FOREIGN_AGENT_F_ADDR_2'] = null;
	          vm.attribData['5471_NA_FOREIGN_AGENT_F_CITY'] = null;
	          vm.attribData['5471_NA_FOREIGN_AGENT_F_STATE'] = null;
	          vm.attribData['5471_NA_FOREIGN_AGENT_F_ZIP'] = null;    		
			  } else{ 
    		 vm.attribData['5471_NA_FOREIGN_AGENT_F_COUNTRY'] = vm.countryCorporation;
			 vm.attribData['5471_NA_FOREIGN_AGENT_US_COUNTRY'] = null;
	         vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_1'] = null;
	         vm.attribData['5471_NA_FOREIGN_AGENT_ADDR_2'] = null;
	         vm.attribData['5471_NA_FOREIGN_AGENT_CITY'] = null;
	         vm.attribData['5471_NA_FOREIGN_AGENT_STATE'] = null;
	         vm.attribData['5471_NA_FOREIGN_AGENT_ZIP'] = null;
    		  }	
         
         if((angular.isDefined(vm.filedOnBehalf) && vm.filedOnBehalf)){
        	 vm.attribData['5471_NA_PERSON_BEHALF'] = vm.filedOnBehalf.LEGAL_ENTITY_ID;
         }
         
        
         /* if((angular.isDefined(vm.selectedGroup) && vm.selectedGroup)){
	      		   vm.attribData['5471_NA_FILER_LEGAL_ENTITY_ID'] = vm.selectedGroup.LEGAL_ENTITY_ID;
	      	 }*/
	      	 
	     if((angular.isDefined(vm.selectedGroup) && vm.selectedGroup)){
	      	   if(vm.selectedGroup.REG_ADDRESS_COUNTRY === 'US') {
	      		   vm.attribData['5471_NA_FILER_ADDRESS'] = vm.selectedGroup.REG_ADDRESS_LINE1_5471;
	      		   vm.attribData['5471_NA_FILER_ADDRESS_LINE_2'] = vm.selectedGroup.REG_ADDRESS_LINE2_5471;
	      		   vm.attribData['5471_NA_FILER_CITY'] = vm.selectedGroup.REG_ADDRESS_CITY;
	      		   vm.attribData['5471_NA_FILER_STATE'] = vm.selectedGroup.REG_ADDRESS_STATE;
	      		   vm.attribData['5471_NA_FILER_COUNTRY'] = vm.selectedGroup.REG_ADDRESS_COUNTRY;
	      		   vm.attribData['5471_NA_FILER_ZIP'] = vm.selectedGroup.REG_ADDRESS_ZIP;
	      		   vm.attribData['5471_NA_FILER_BEGIN_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_BEGIN;
       		       vm.attribData['5471_NA_FILER_END_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_END;
	      		   vm.attribData['5471_NA_FILER_NAME'] = vm.selectedGroup.LEGAL_ENTITY_NAME;
	      		   vm.attribData['5471_NA_FILER_LEGAL_ENTITY_ID'] = vm.selectedGroup.LEGAL_ENTITY_ID;
      		       vm.attribData['5471_NA_A'] = vm.selectedGroup.FILER_EIN.replace('-','');
      		       vm.attribData['5471_NA_FILER_F_ADDR_LINE_1'] = null;
	               vm.attribData['5471_NA_FILER_F_ADDR_LINE_2'] = null;
	               vm.attribData['5471_NA_FILER_F_CITY'] = null;
	               vm.attribData['5471_NA_FILER_F_STATE'] = null;
	               vm.attribData['5471_NA_FILER_F_COUNTRY'] =null;
	               vm.attribData['5471_NA_FILER_F_ZIPCODE'] = null;
	              } else {
	              	vm.attribData['5471_NA_FILER_F_ADDR_LINE_1'] = vm.selectedGroup.REG_ADDRESS_LINE1_5471;
	              	vm.attribData['5471_NA_FILER_F_ADDR_LINE_2'] = vm.selectedGroup.REG_ADDRESS_LINE2_5471;
	              	vm.attribData['5471_NA_FILER_F_CITY'] = vm.selectedGroup.REG_ADDRESS_CITY;
	              	vm.attribData['5471_NA_FILER_F_STATE'] = vm.selectedGroup.REG_ADDRESS_STATE;
	              	vm.attribData['5471_NA_FILER_F_COUNTRY'] =vm.selectedGroup.REG_ADDRESS_COUNTRY;
	              	vm.attribData['5471_NA_FILER_F_ZIPCODE'] = vm.selectedGroup.REG_ADDRESS_ZIP;
	              	vm.attribData['5471_NA_FILER_BEGIN_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_BEGIN;
	       		    vm.attribData['5471_NA_FILER_END_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_END;
	              	vm.attribData['5471_NA_FILER_NAME'] = vm.selectedGroup.LEGAL_ENTITY_NAME;
	              	vm.attribData['5471_NA_FILER_LEGAL_ENTITY_ID'] = vm.selectedGroup.LEGAL_ENTITY_ID;
	      		    vm.attribData['5471_NA_A'] = vm.selectedGroup.FILER_EIN.replace('-','');
	      		    vm.attribData['5471_NA_FILER_ADDRESS'] = null;
	      		    vm.attribData['5471_NA_FILER_ADDRESS_LINE_2'] = null;
	      		    vm.attribData['5471_NA_FILER_CITY'] = null;
	      		    vm.attribData['5471_NA_FILER_STATE'] = null;
	      		    vm.attribData['5471_NA_FILER_COUNTRY'] = null;
	      		    vm.attribData['5471_NA_FILER_ZIP'] = null;
	          	   }
	      	 }
 	 
          
          if(angular.isDefined(vm.attribData['5471_NA_1F'])){
	      		vm.attribData['5471_NA_1G'] = vm.selectedGroup1.ACTIVITY_DESC;
	      	 }
         if (vm.checkBoxd === '1'){
  			vm.attribData['5471_NA_D_Y'] = vm.checkBoxd;
  			vm.attribData['5471_NA_D_N'] = null;
  		}else {
      		 vm.attribData['5471_NA_D_N'] = vm.checkBoxd;
      		vm.attribData['5471_NA_D_Y'] = null;
  		}
         if (vm.checkBoxf === '1'){
   			vm.attribData['5471_NA_ALT_INFO_2019_40_IND_Y'] = vm.checkBoxf;
   			vm.attribData['5471_NA_ALT_INFO_2019_40_IND_N'] = null;
   		}else {
       		 vm.attribData['5471_NA_ALT_INFO_2019_40_IND_N'] = vm.checkBoxf;
       		vm.attribData['5471_NA_ALT_INFO_2019_40_IND_Y'] = null;
   		}
         if (vm.checkBoxb1 === '1'){
    			vm.attribData['5471_NA_B_1_Y'] = vm.checkBoxb1;
    			vm.attribData['5471_NA_B_1_N'] = null;
    		}else {
        		 vm.attribData['5471_NA_B_1_N'] = vm.checkBoxb1;
        		vm.attribData['5471_NA_B_1_Y'] = null;
    		}
         if (vm.checkBoxb3 === '1'){
   			vm.attribData['5471_NA_B_3_Y'] = vm.checkBoxb3;
   			vm.attribData['5471_NA_B_3_N'] = null;
   		}else {
       		 vm.attribData['5471_NA_B_3_N'] = vm.checkBoxb3;
       		vm.attribData['5471_NA_B_3_Y'] = null;
   		}
         if (vm.checkBoxb4 === '1'){
    			vm.attribData['5471_NA_B_4_Y'] = vm.checkBoxb4;
    			vm.attribData['5471_NA_B_4_N'] = null;
    	}else {
        		 vm.attribData['5471_NA_B_4_N'] = vm.checkBoxb4;
        		vm.attribData['5471_NA_B_4_Y'] = null;
    		}
         if (vm.checkBoxb5 === '1'){
    			vm.attribData['5471_NA_B_5_Y'] = vm.checkBoxb5;
    			vm.attribData['5471_NA_B_5_N'] = null;
    	}else {
        		 vm.attribData['5471_NA_B_5_N'] = vm.checkBoxb5;
        		vm.attribData['5471_NA_B_5_Y'] = null;
    		}
         if (vm.checkBoxb1a === '1'){
 			vm.attribData['5471_NA_B_1A_Y'] = vm.checkBoxb1a;
 			vm.attribData['5471_NA_B_1A_N'] = null;
 		}else {
     		 vm.attribData['5471_NA_B_1A_N'] = vm.checkBoxb1a;
     		vm.attribData['5471_NA_B_1A_Y'] = null;
 		}
         if (vm.checkBoxb1b === '1'){
 			vm.attribData['5471_NA_B_1B_Y'] = vm.checkBoxb1b;
 			vm.attribData['5471_NA_B_1B_N'] = null;
 		}else {
     		 vm.attribData['5471_NA_B_1B_N'] = vm.checkBoxb1b;
     		vm.attribData['5471_NA_B_1B_Y'] = null;
 		}
			if (vm.checkBoxb1c === '1'){
 			vm.attribData['5471_NA_B_1C_Y'] = vm.checkBoxb1c;
 			vm.attribData['5471_NA_B_1C_N'] = null;
 		}else {
     		 vm.attribData['5471_NA_B_1C_N'] = vm.checkBoxb1c;
     		vm.attribData['5471_NA_B_1C_Y'] = null;
 		}
     
      if (vm.checkBoxb5a === '1'){
 			vm.attribData['5471_NA_B_5A_Y'] = vm.checkBoxb5a;
 			vm.attribData['5471_NA_B_5A_N'] = null;
 	    }else {
     		 vm.attribData['5471_NA_B_5A_N'] = vm.checkBoxb5a;
     		vm.attribData['5471_NA_B_5A_Y'] = null;
 		}
			if (vm.checkBoxb5b === '1'){
 			vm.attribData['5471_NA_B_5B_Y'] = vm.checkBoxb5b;
 			vm.attribData['5471_NA_B_5B_N'] = null;
 	}else {
     		 vm.attribData['5471_NA_B_5B_N'] = vm.checkBoxb5b;
     		vm.attribData['5471_NA_B_5B_Y'] = null;
 		}
			if (vm.checkBoxb5c === '1'){
 			vm.attribData['5471_NA_B_5C_Y'] = vm.checkBoxb5c;
 			vm.attribData['5471_NA_B_5C_N'] = null;
 	}else {
     		 vm.attribData['5471_NA_B_5C_N'] = vm.checkBoxb5c;
     		vm.attribData['5471_NA_B_5C_Y'] = null;
 		}
         if (vm.checkShareholder === '1'){
 			vm.attribData['5471_NA_FOB_SHAREHOLDER_Y'] = vm.checkShareholder;
 			vm.attribData['5471_NA_FOB_SHAREHOLDER_N'] = null;
 	    }else {
     		 vm.attribData['5471_NA_FOB_SHAREHOLDER_N'] = vm.checkShareholder;
     		vm.attribData['5471_NA_FOB_SHAREHOLDER_Y'] = null;
 		  }
         if (vm.checkOfficer === '1'){
 			vm.attribData['5471_NA_FOB_OFFICER_Y'] = vm.checkOfficer;
 			vm.attribData['5471_NA_FOB_OFFICER_N'] = null;
 	}else {
     		 vm.attribData['5471_NA_FOB_OFFICER_N'] = vm.checkOfficer;
     		vm.attribData['5471_NA_FOB_OFFICER_Y'] = null;
 		}
         if (vm.checkDirector === '1'){
 			vm.attribData['5471_NA_FOB_DIRECTOR_Y'] = vm.checkDirector;
 			vm.attribData['5471_NA_FOB_DIRECTOR_N'] = null;
 	}else {
     		 vm.attribData['5471_NA_FOB_DIRECTOR_N'] = vm.checkDirector;
     		vm.attribData['5471_NA_FOB_DIRECTOR_Y'] = null;
 		}
 		
 /////////// validations for line 2a
 
            line_2a_validations ('save');
            
            if (vm.line_2a_name  == true || 
        		vm.line_2a_country_flag == true||
		        vm.add_flag_2a == true || 
		        vm.add_flag_2a_f == true ||
		        vm.city_flag_2a == true || 
		        vm.state_flag_2a == true || 
		        vm.zip_flag_2a ==  true){
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			
			
			
			
			/////////// validations for line 2c
            line_2c_validations ('save');
            
            if (vm.line_2c_name  == true || 
        		vm.line_2c_country_flag == true||
		        vm.add_flag_2c == true || 
		        vm.add_flag_2c_f == true ||
		        vm.city_flag_2c == true || 
		        vm.state_flag_2c == true || 
		        vm.zip_flag_2c ==  true){
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			
			
			
			
			/////////// validations for line 2d
            line_2d_validations ('save');
            
            if (vm.line_2d_name  == true || 
        		vm.line_2d_country_flag == true||
		        vm.add_flag_2d == true || 
		        vm.add_flag_2d_f == true ||
		        vm.city_flag_2d == true || 
		        vm.state_flag_2d == true || 
		        vm.zip_flag_2d ==  true){
			    AlertService.add("error", "Please fill out required fields.", 1000);
				return;
			}
			

 
            if(vm.irsF5471ScheduleNAData.length > 0) {
                var saveObject = [];
                var temporaryObject = vm.irsF5471ScheduleNAData;
                	
                for (var loopIndex = 1; loopIndex < temporaryObject.length; loopIndex++){
                   
                    saveObject = [{
                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                        "trans_type_key":temporaryObject[loopIndex].trans_type_key,
                        "combination_key": vm.rowData.HO_COMBINATION_KEY,
                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                        "form_key":temporaryObject[loopIndex].form_key,
                        "line_no":temporaryObject[loopIndex].i.line_no,
                        "occurence":((temporaryObject[loopIndex].occurrence === "0") ? null : temporaryObject[loopIndex].occurrence),
                        "line_attrib_key" : temporaryObject[loopIndex].line_attrib_key,
                        "Attribute_Name": temporaryObject[loopIndex].attrib_name,
                        "Attribute_Value":((vm.attribData[temporaryObject[loopIndex].attrib_name] === '' )? '' :vm.attribData[temporaryObject[loopIndex].attrib_name]),//vm.attribData[temporaryObject[loopIndex].attrib_name],
                        "trans_dtls_key" : ((temporaryObject[loopIndex].trans_details_key === "0") ? null : temporaryObject[loopIndex].trans_details_key),
                        "trans_status":"A",
                        "override_y_n":((temporaryObject[loopIndex].is_update === undefined)? 'N' : temporaryObject[loopIndex].is_update)
                    }];
                    editRow.push(saveObject);
                }

            }

           

                var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                var message = "Textual Info details successfully saved";


                form5471ScheduleGGroupObjFactory.saveForm5471ScheduleNA(scheduleSettings,
                    scheduleDetails).then(
                    function(result) {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error", result.errorMessage, 4000);
                          
                        } else {
                            vm.saveFlag = true;
                            AlertService.add("success", message, 4000);
                            getFormData();
                          
                        }
                    });
            
        };
       

        vm.savingFraction = function(value){
            var num= value.toFixed(6);
            return num;
        };
        
        vm.onRadioBtnSelection = function(row, index, value) {
        	if (value === 'Y') {
        		row.value[1].VALUE = null;
        	} else if (value === 'N') {
        		row.value[0].VALUE = null;
        	}
        }
        
        

        vm.formatNumberForDecimal = function(value, attrName) {
            vm.schedule_g[attrName] = parseInt(Number(value));

        }

       
        vm.findIndex = function(data, attribName, value){
        	var findData = data.find(x => x.attrib_name === attribName);
        	findData.VALUE = value;
        	return findData;
        }
        vm.reset = function () {
            vm.getFormData();
            //$scope.crudLoading = false;
            $scope.irsF5471ScheduleGData = angular.copy(vm.formListOriginal);
        }

    }





function scheduleGInfoCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, form5471ScheduleGGroupObjFactory,
    		AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,
			rowData, colData, gridData,thisObj) {
				
	var vm = this||thisObj;
    vm.rowData = rowData||$scope.$parent.rowData;
    vm.scheduleGDetails = [];
        vm.scheduleGDetailsOriginal = [];
        vm.scheduleGDetails.value = "";
        vm.schedule_g = {};
        vm.invalidSaveMessage = "";
        vm.data = {};
        $scope.irsF5471ScheduleGData = [];
        vm.auto5 = false;
        
       vm.modalTitle = "Schedule G Other Information";
        if (angular.isObject($uibModalInstance)) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};			
		} else {
			vm.modal_name = null;			
		}


				
				
	  vm.initLoadData = function(){
        	vm.dataLoading = true;
            loadIRSF5471ScheduleGData();
          
        	};
        vm.initLoadData();
			
	 function loadIRSF5471ScheduleGData()
                    {
            var params = {
                "action_code": '2pql6p',
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "irs_form_no" : 'F5471',
                "combination_key": vm.rowData.HO_COMBINATION_KEY
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                    var irsF5471ScheduleGData = data.jsonObject;
                    var i = 1;

                    var previous_line_no = irsF5471ScheduleGData[i].line_no;
                    while( i < irsF5471ScheduleGData.length )
                    {
                        var tempObj = {};
                        var j = i;
                        var count = 0;
                        while(j <irsF5471ScheduleGData.length && previous_line_no === irsF5471ScheduleGData[j].line_no)
                        {
                            if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_NO")
                            {
                                tempObj.propertyType = irsF5471ScheduleGData[j].PROPERTY_TYPE;
                                tempObj.line_no = irsF5471ScheduleGData[j].line_no;
                                tempObj.parent_line_no = irsF5471ScheduleGData[j].PARENT_LINE_NO;
                                tempObj.col_id = irsF5471ScheduleGData[j].COL_ID;
                                tempObj.parent_col_id = irsF5471ScheduleGData[j].PARENT_COL_ID;
                                tempObj.line_type = irsF5471ScheduleGData[j].LINE_TYPE;
                                tempObj.saveParams = {
                                    "tax_year":irsF5471ScheduleGData[j].object_id.split("~")[0],
                                    "trans_type_key" : irsF5471ScheduleGData[j].object_id.split("~")[1],
                                    "trans_Details_key" : irsF5471ScheduleGData[j].object_id.split("~")[3],
                                    "occurrence" : irsF5471ScheduleGData[j].object_id.split("~")[4],
                                    "form_key" : irsF5471ScheduleGData[j].object_id.split("~")[6]
                                }
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_DESCRIPTION")
                            {
                                tempObj.line_description = irsF5471ScheduleGData[j].attrib_value.split("~")[3];
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_19_1_VALUE_5471" || irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_14_1_VALUE_5471")
                            {
                                tempObj.propertyType1 = irsF5471ScheduleGData[j].PROPERTY_TYPE;
                                tempObj.isEditableFlag = 'Y';
                                tempObj.isEditableFlagText = 'Y';
                                tempObj.isSaveValidFlag = 'Y';
                                tempObj.line_description1 = irsF5471ScheduleGData[j].attrib_value.split("~")[3];
                                tempObj.value_1 = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name" : irsF5471ScheduleGData[j].attrib_value.split("~")[0]
                                }
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_20_1_VALUE_5471" ||irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_15_1_VALUE_5471")
                            {
                                tempObj.propertyType1 = irsF5471ScheduleGData[j].PROPERTY_TYPE;
                                tempObj.isEditableFlag = 'Y';
                                tempObj.isEditableFlagText = 'Y';
                                tempObj.isSaveValidFlag = 'Y' ;
                                tempObj.line_description2 = irsF5471ScheduleGData[j].attrib_value.split("~")[3];
                                tempObj.value_2 = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name" : irsF5471ScheduleGData[j].attrib_value.split("~")[0]
                                }
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_21_1_VALUE_5471" ||irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "LINE_16_1_VALUE_5471" )
                            {
                                tempObj.propertyType1 = irsF5471ScheduleGData[j].PROPERTY_TYPE;
                                tempObj.isEditableFlag = 'Y';
                                tempObj.isEditableFlagText = 'Y';
                                tempObj.isSaveValidFlag = 'Y';
                                tempObj.line_description3 = irsF5471ScheduleGData[j].attrib_value.split("~")[3];
                                tempObj.value_3 = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name" : irsF5471ScheduleGData[j].attrib_value.split("~")[0]
                                }
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "COMPARABLE_UNCONTROLLED_Y" )
                            {   
                                tempObj.value_comparable = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleGData[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleGData[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "INCOME_METHOD_Y")
                            {
                                tempObj.value_income = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleGData[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleGData[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "ACQUISITION_PRICE_METHOD_Y" )
                            {
                                tempObj.value_acquisition = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleGData[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleGData[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "MARKET_CAPITALIZATION_Y")
                            {
                                tempObj.value_market = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleGData[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleGData[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "RESIDUAL_PROFIT_Y")
                            {
                                tempObj.value_residual = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleGData[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleGData[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleGData[j].attrib_value.split("~")[0] === "UNSPECIFIED_METHODS_Y")
                            {
                                tempObj.value_unspecified = {
                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleGData[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleGData[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleGData[j].PROPERTY_TYPE
                                };
                            }
                            else
                            {
                            	if (count > 0) {
                            		if (count == 1) {
                            			tempObj.attrib_name = [tempObj.attrib_name];
                            			tempObj.value = [tempObj.value];
                            		}
                        			tempObj.attrib_name[count] = irsF5471ScheduleGData[j].attrib_value.split("~")[0];
                        			tempObj.value[count] = {
    	                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
    	                                    "line_attrib_key": irsF5471ScheduleGData[j].attrib_value.split("~")[1]
    	                             }
                            	} else {
	                                tempObj.propertyType = irsF5471ScheduleGData[j].PROPERTY_TYPE;
	                                tempObj.isEditableFlag = 'Y';
	                                tempObj.isSaveValidFlag = 'Y';
	                                tempObj.attrib_name = irsF5471ScheduleGData[j].attrib_value.split("~")[0];
	                               // tempObj.col_type = irsF5471ScheduleGData[j].PROPERTY_TYPE == '13'? 'radio': irsF5471ScheduleGData[j].PROPERTY_TYPE == '7'? 'amount' :irsF5471ScheduleGData[j].PROPERTY_TYPE == '11' ? 'checkbox':'NA'
                                    tempObj.value = {
	                                    "VALUE": irsF5471ScheduleGData[j].VALUE,
	                                    "line_attrib_key": irsF5471ScheduleGData[j].attrib_value.split("~")[1]
	                                }
                            	}
                            }
                            previous_line_no = irsF5471ScheduleGData[j].line_no;
                    		if (irsF5471ScheduleGData[j].PROPERTY_TYPE == 13 ) {
                    			count++;
                    		}
                            j++;
                        }
                        if(j < irsF5471ScheduleGData.length)
                            previous_line_no = irsF5471ScheduleGData[j].line_no;
                        var tempIndex = $scope.irsF5471ScheduleGData.length;
                        $scope.irsF5471ScheduleGData.push(tempObj);
                        console.log("irsF5471ScheduleGDatafinal---------", $scope.irsF5471ScheduleGData)                        
                        i = j;
                        
                    }
                    //getScheduleIdata();
                }
                else
                {
                    if (data.errorMessage) {
                        AlertService.add("",data.errorMessage ,
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
                
               
                //vm.formListOriginal = angular.copy($scope.irsF5471ScheduleGData);
                setAmt();
            });
        }
        
        
        function setAmt()
        {
        	var params = {
        			"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
        			"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
        			"combination_key":vm.rowData.COMBINATION_KEY, //674867,        			
        			"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
        	}
        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=n33ft3",params).then(function(data) {
        		console.log("Response Data x ::", data.jsonObject);
        		if (data.callSuccess === "1") {
        			var temp_amt = data.jsonObject[0].SEC_901M_AMT;
        			var temp_amt1 = data.jsonObject[0].SEC_909_SUSP_TAX;
        			var temp_amt2 = data.jsonObject[0].SEC_909_TAX_RELEASED;
        			var temp_value1 = data.jsonObject[0].LINE_1 === undefined ? null:data.jsonObject[0].LINE_1;
        			var temp_value2 = data.jsonObject[0].LINE_3 === undefined ? null:data.jsonObject[0].LINE_3;
        			var temp_value3 = data.jsonObject[0].LINE_19 === undefined ? null:data.jsonObject[0].LINE_19 ;
        			
        			//vm.autoList = _.filter($scope.irsF5471ScheduleGData,{line_type:'auto'});
        			
        			vm.autoList1 = _.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(['auto1'], p.line_type) ;
			                		});	
			         vm.autoList2 = _.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(['auto2'], p.line_type) ;
			                		});	
			        vm.autoList3 = _.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(['auto3'], p.line_type) ;
			                		});	
			        vm.autoList4 = _.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(['auto4'], p.line_type) ;
			                		});	
			         vm.autoList5 = _.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(['auto5'], p.line_type) ;
			                		});	       		
        		     vm.autoList6 = _.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(['auto6'], p.line_type) ;
			                		});	 
        			///if(data.jsonObject[0].LINE_1 == 'Y'){
        			/*if (workspaceFactory.activeScreen.filters.filterParams.tax_year > '2019'){
        				$scope.irsF5471ScheduleGData[28].value[1].VALUE = $scope.irsF5471ScheduleGData[28].value[0].VALUE === undefined ? 'N' :$scope.irsF5471ScheduleGData[28].value[1].VALUE;
        				$scope.irsF5471ScheduleGData[29].value[1].VALUE = $scope.irsF5471ScheduleGData[29].value[0].VALUE === undefined ? 'N' :$scope.irsF5471ScheduleGData[29].value[1].VALUE;
        				
        			}*/
        			    if (workspaceFactory.activeScreen.filters.filterParams.tax_year > '2019'&& vm.autoList6 !== undefined ){
        				vm.value6 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList6[0].attrib_name);
        				vm.value6.value[1].VALUE = 'N';
						vm.value6.value[0].disabled = true;
        				vm.value6.value[0].VALUE = null;
        				};
        				
        				if (temp_value1 == 'Y'){///line1
							 vm.value1 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList1[0].attrib_name);						     
						     vm.value1.value[0].VALUE = 'Y';
						     vm.value1.value[1].disabled = true;
						     vm.value1.value[1].VALUE = null;
        				}else {
						     vm.value1 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList1[0].attrib_name);						     
						     vm.value1.value[1].VALUE = 'N';
						     vm.value1.value[0].disabled = true;
						     vm.value1.value[0].VALUE = null;
							}
						if (temp_value2 == 'Y'){///line3
							 vm.value2 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList2[0].attrib_name);						     
						     vm.value2.value[0].VALUE = 'Y';
						     vm.value2.value[1].disabled = true;
						     vm.value2.value[1].VALUE = null;
        				}else {
						     vm.value2 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList2[0].attrib_name);						     
						     vm.value2.value[1].VALUE = 'N';
						     vm.value2.value[0].disabled = true;
						     vm.value2.value[0].VALUE = null;
							}	
						if (temp_amt != 0){///line901m
							 vm.value3 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList3[0].attrib_name);						     
						     vm.value3.value[0].VALUE = 'Y';
						     vm.value3.value[1].disabled = true;
						     vm.value3.value[1].VALUE = null;
        				}else {
						     vm.value3 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList3[0].attrib_name);						     
						     vm.value3.value[1].VALUE = 'N';
						     vm.value3.value[0].disabled = true;
						     vm.value3.value[0].VALUE = null;
							}		
						if(temp_amt1 && temp_amt2 != 0){///line909c
							vm.value4 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList4[0].attrib_name);						     
						     vm.value4.value[0].VALUE = 'Y';
						     vm.value4.value[1].disabled = true;
						     vm.value4.value[1].VALUE = null;
        				}else {
						     vm.value4 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList4[0].attrib_name);						     
						     vm.value4.value[1].VALUE = 'N';
						     vm.value4.value[0].disabled = true;
						     vm.value4.value[0].VALUE = null;
							}	
        				
        				if (temp_value3 == 'Y'){///linesubF
							 vm.value5 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList5[0].attrib_name);						     
						     vm.value5.value[0].VALUE = 'Y';
						     vm.value5.value[1].disabled = true;
						     vm.auto5 = true;
        				}else {
						     vm.value5 = $scope.irsF5471ScheduleGData.find(x=> x.attrib_name == vm.autoList5[0].attrib_name);						     
						     vm.value5.value[1].VALUE = 'N';
						     vm.value5.value[0].disabled = true;
						     vm.value5.isEditableFlagText = 'N';
						     vm.value5.isSaveValidFlag === 'Y';
						     vm.auto5 = true;
							}	
        				/*else {
        					$scope.irsF5471ScheduleGData[0].value[0].disabled = true;
        					
        				}
        				
        				$scope.irsF5471ScheduleGData[2].value[0].VALUE = data.jsonObject[0].LINE_3 === 'Y' ? 'Y' : null;
        				$scope.irsF5471ScheduleGData[2].value[1].VALUE = data.jsonObject[0].LINE_3 === 'N' ? 'N' : null;
        				if($scope.irsF5471ScheduleGData[2].value[0].VALUE === 'Y') {
        					$scope.irsF5471ScheduleGData[2].value[1].disabled = true;
        					
        				} else {
        					$scope.irsF5471ScheduleGData[2].value[0].disabled = true;
        					
        				}
        				
        				$scope.irsF5471ScheduleGData[25].value[0].VALUE = data.jsonObject[0].LINE_19 === 'Y' ? 'Y' : null;
        				$scope.irsF5471ScheduleGData[25].value[1].VALUE = data.jsonObject[0].LINE_19 === 'N' ? 'N' : null;
        				
        				if($scope.irsF5471ScheduleGData[25].value[0].VALUE === 'Y') {
        					$scope.irsF5471ScheduleGData[25].value[1].disabled = true;
        					$scope.irsF5471ScheduleGData[25].value[0].disabled = true;
        					$scope.irsF5471ScheduleGData[25].value_1.disabled = true;
        					
        				} else {
        					$scope.irsF5471ScheduleGData[25].value[0].disabled = true;
        					$scope.irsF5471ScheduleGData[25].value[1].disabled = true;
        					$scope.irsF5471ScheduleGData[25].value_1.disabled = true;
        				}*/
        				
        			//}
        			/*if(temp_amt != 0  )
       			{
       				
        				$scope.irsF5471ScheduleGData[23].value[0].VALUE = $scope.irsF5471ScheduleGData[23].value[1].VALUE === 'N'? null :'Y';
        				$scope.irsF5471ScheduleGData[23].value[1].VALUE = $scope.irsF5471ScheduleGData[23].value[1].VALUE === 'N'? 'N' : null;
       			}
       			else
       			{   if($scope.irsF5471ScheduleGData[23].value[0].VALUE === 'Y'){
       				    $scope.irsF5471ScheduleGData[23].value[0].VALUE = 'Y';
       				    $scope.irsF5471ScheduleGData[23].value[1].VALUE = null;
       			    }else{
       			    	$scope.irsF5471ScheduleGData[23].value[1].VALUE = 'N';
       			    	$scope.irsF5471ScheduleGData[23].value[0].VALUE = null;
       			    }
       				
       			}
        			
        			if(temp_amt1 && temp_amt2 != 0 )
       			{
       				
        				$scope.irsF5471ScheduleGData[24].value[0].VALUE = 'Y';
        				$scope.irsF5471ScheduleGData[24].value[1].VALUE = null;
       			}
       			else
       			{    if($scope.irsF5471ScheduleGData[24].value[0].VALUE === 'Y'){
       				     $scope.irsF5471ScheduleGData[24].value[0].VALUE = 'Y';
       				     $scope.irsF5471ScheduleGData[24].value[1].VALUE = null;
       			        }else {
       			         $scope.irsF5471ScheduleGData[24].value[1].VALUE = 'N';
       			         $scope.irsF5471ScheduleGData[24].value[0].VALUE = null;
       			        }
       				
       			}
        			*/
        			/*for(var i = 0;i<$scope.irsF5471ScheduleGData.length;i++){
                      	 vm.checkingForValidation($scope.irsF5471ScheduleGData[i]);
                      }
       			console.log("vm.scheduleGDetails---------", vm.scheduleGDetails);
        			*/
        			
        		}
        		else {
					if (data.errorMessage === "no access") {
						AlertService.add("error","Sorry you do not have access to do the requested action.",
										4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
										"error",
										"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
										4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
				}
        		
        	});
        	
        }
   
   vm.onRadioBtnSelection = function(row, index, value) {
        	if (value === 'Y') {
        		row.value[1].VALUE = null;
        	} else if (value === 'N') {
        		row.value[0].VALUE = null;
        	}
        }
        
        vm.checkingForValidation = function (row, index,col_type){
	
	console.log("vm.scheduleGDetailsNew---------", row);
	
	    
		if(col_type == 'radio' && row.col_id !== '7'){
			
			/*var occList = _.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(p.parent_line_no,row.line_no) ;
			                		});	*/
			/*var occList =_.filter($scope.irsF5471ScheduleGData, function(p){
			                       return _.includes(row.line_no,p.parent_line_no) ;
			                		});	*/ 
			                		
			 var occList = _.filter($scope.irsF5471ScheduleGData, function(o) { 
						return (o.parent_line_no  == row.line_no )
					});              		
			                		
		// var occList = _.filter($scope.irsF5471ScheduleGData,{parent_line_no:row.line_no});            		
		}
		else if (col_type == 'radio' && row.col_id == '7'){
		var occList = _.filter($scope.irsF5471ScheduleGData, function(o) { 
						return (o.line_type == 'check' || o.line_type == 'multi' )
					});
		}
		else if ((col_type == 'radio' && row.line_no!== row.parent_line_no) || (col_type == 'amount')|| (row.line_type == 'check')){
			var occList = _.filter($scope.irsF5471ScheduleGData,{line_no:row.line_no}) 
			
			};
			
		console.log("acctlist---------", occList);
		   occList.forEach(function(currentObj){
			if(currentObj['line_no'] == currentObj['parent_line_no'] && currentObj['col_id'] == currentObj['parent_col_id']){
			return;
			}
			var attribute = currentObj['attrib_name'];
			var col_id = currentObj['col_id'];
			var parent_col_id = currentObj['parent_col_id'];
			
			if(col_type == 'radio'){
						if(row.value[1].VALUE === 'N'&& col_id == parent_col_id){
							
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'N','Y');
						
						}else if (row.value[0].VALUE == 'Y'&& col_id == parent_col_id && row.col_id !== '7')
						{
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','N');
						}
						else if (row.value[1].VALUE == 'N' && col_id !== parent_col_id)
						{
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'N','Y');
						}
						else if(row.value[0].VALUE  == 'Y' && col_id !== parent_col_id && row.line_type !== 'auto5')
						{
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','N');
						}
						else if(row.value[0].VALUE  == 'Y' && col_id !== parent_col_id && row.line_type == 'auto5')
						{
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
						}
						else if (row.value[0].VALUE == 'Y'&& col_id == parent_col_id && row.col_id == '7')
						{
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','N', row.col_id);
						}
						else if (((row.value[0].VALUE == undefined && row.value[1].VALUE == undefined )||(row.value[0].VALUE == null && row.value[1].VALUE == null )))
						{
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
						}
	       }
	       else if (col_type == 'amount' && row.line_type !== 'check' && row.parent_line_no !== '7')
	         {
		         vm.validLine_no = row.parent_col_id == '10'? currentObj['parent_col_id'] : currentObj['parent_line_no'];
		         vm.findLineNoData = $scope.irsF5471ScheduleGData.find(x => x.line_no === vm.validLine_no);
		         if ((vm.findLineNoData.value[0].VALUE == undefined &&  vm.findLineNoData.value[1].VALUE == undefined) ||
		         	(vm.findLineNoData.value[0].VALUE == null &&  vm.findLineNoData.value[1].VALUE == null)){
					 vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
					 }
					 
			else{
				
				if(row.col_id == row.parent_col_id){
					if((row.value.VALUE !== undefined || row.value.VALUE !== null)){
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
					}
					if (row.value.VALUE == undefined || row.value.VALUE == null){
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','N');
					}
					if(vm.findLineNoData.value[1].VALUE == 'N'){
						vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'N','Y');
					}
					
				}
				if (row.col_id !== row.parent_col_id){
					 if(((row.value[0].VALUE == 'Y') && (angular.isDefined(row.value_2) && row.value_2.VALUE !== '')) || ((row.value[0].VALUE == 'Y') && (angular.isDefined(row.value_3) && row.value_3.VALUE !== '')))			 
					{
					vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
					}
					if(((row.value[0].VALUE == 'Y') && (angular.isDefined(row.value_2) && row.value_2.VALUE == '')) || ((row.value[0].VALUE == 'Y') && (angular.isDefined(row.value_3) && row.value_3.VALUE == '')))			 
					{
					vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','N');
					}
				if(row.value[1].VALUE == 'N' )	
					{					
					vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'N','Y');
					}	
					
				}
					
				 }
			 }
			 else if (row.line_type == 'check'){
				vm.validLine_no = currentObj['parent_col_id'];
		         vm.findLineNoData = $scope.irsF5471ScheduleGData.find(x => x.line_no === vm.validLine_no);
		         if ((vm.findLineNoData.value[0].VALUE == undefined &&  vm.findLineNoData.value[1].VALUE == undefined) ||
		         	(vm.findLineNoData.value[0].VALUE == null &&  vm.findLineNoData.value[1].VALUE == null)){
					 vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
					 }
				if((!angular.isDefined(row.value_comparable.VALUE) && row.value_comparable.VALUE !== '0') 
				    || (!angular.isDefined(row.value_acquisition.VALUE)&& row.value_acquisition.VALUE !== '0')
				    || (!angular.isDefined(row.value_income.VALUE) && row.value_income.VALUE !== '0')
                    || (!angular.isDefined(row.value_market.VALUE) && row.value_market.VALUE !== '0') 
                    || (!angular.isDefined(row.value_residual.VALUE) && row.value_residual.VALUE !== '0')
                    || (!angular.isDefined(row.value_unspecified.VALUE) && row.value_unspecified.VALUE !== '0')
				    
				 )
					{
					vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
					}
				if((angular.isDefined(row.value_comparable.VALUE)  &&row.value_comparable.VALUE === '0') 
				    || (angular.isDefined(row.value_acquisition.VALUE) && row.value_acquisition.VALUE === '0') 
				    || (angular.isDefined(row.value_income.VALUE) && row.value_income.VALUE === '0')
                    || (angular.isDefined(row.value_market.VALUE) && row.value_market.VALUE === '0') 
                    || (angular.isDefined(row.value_residual.VALUE) && row.value_residual.VALUE === '0')
                    || (angular.isDefined(row.value_unspecified.VALUE) && row.value_unspecified.VALUE === '0'))
					{					
					vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','N');
					}	
					
			if((angular.isDefined(row.value_comparable.VALUE)  &&row.value_comparable.VALUE == null ) 
				    && (angular.isDefined(row.value_acquisition.VALUE) && row.value_acquisition.VALUE == null) 
				    && (angular.isDefined(row.value_income.VALUE) && row.value_income.VALUE == null)
                    && (angular.isDefined(row.value_market.VALUE) && row.value_market.VALUE == null) 
                    && (angular.isDefined(row.value_residual.VALUE) && row.value_residual.VALUE == null)
                    &&(angular.isDefined(row.value_unspecified.VALUE) && row.value_unspecified.VALUE == null))
					{					
					vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'N','Y');
					}		 
					 
			if((angular.isDefined(row.value_comparable.VALUE)  &&row.value_comparable.VALUE == '1' ) 
				    || (angular.isDefined(row.value_acquisition.VALUE) && row.value_acquisition.VALUE == '1') 
				    || (angular.isDefined(row.value_income.VALUE) && row.value_income.VALUE == '1')
                    || (angular.isDefined(row.value_market.VALUE) && row.value_market.VALUE == '1') 
                    || (angular.isDefined(row.value_residual.VALUE) && row.value_residual.VALUE == '1')
                    || (angular.isDefined(row.value_unspecified.VALUE) && row.value_unspecified.VALUE == '1'))
					{					
					vm.findIndex_1($scope.irsF5471ScheduleGData, attribute,'Y','Y');
					}			
			}
		});
			
			vm.findIndex_1 = function(data, attribName,value,saveFlag, multiple){
            	var findData = data.find(x => x.attrib_name === attribName);
            		if (findData.col_id == findData.parent_col_id){
							findData.isEditableFlag = value;
							findData.isSaveValidFlag = multiple == '7'? 'Y' : saveFlag;
							
						if(value == 'N'){
							findData.isEditableFlag = 'N';
							findData.value.VALUE = null;
					    if (angular.isDefined(findData.value_acquisition)){
						findData.value_acquisition.VALUE = null;
						};
						if (angular.isDefined(findData.value_comparable)){
						findData.value_comparable.VALUE = null;
						};
						if (angular.isDefined(findData.value_income)){
						findData.value_income.VALUE = null;
						};
						if (angular.isDefined(findData.value_market)){
						findData.value_market.VALUE = null;
						};
						if (angular.isDefined(findData.value_residual)){
						findData.value_residual.VALUE = null;
						};
						if (angular.isDefined(findData.value_unspecified)){
						findData.value_unspecified.VALUE = null;
							};
						if (angular.isDefined(findData.value[1]) && findData.value[1].VALUE !== undefined ){
						findData.value[0].VALUE = null;
						  };
						}
						
						if (angular.isDefined(findData.value[0]) && findData.value[0].VALUE !== undefined ){
						findData.value[1].VALUE = null;
						  };
						/*if (findData.value[0] == undefined ){
						findData.value[0].VALUE = null;
						};
						if (findData.value[1] == undefined ){
						findData.value[1].VALUE = null;
						};*/
					}
            		else{
            		findData.isEditableFlagText = value;
            		findData.isSaveValidFlag = saveFlag;
            		//findData.isEditableFlag = value;
            		if(value == 'N'){
            		if (angular.isDefined(findData.value_1)){
						findData.isEditableFlagText = 'N';
						findData.isSaveValidFlag == 'Y';
						findData.value_1 = null;
						};
						if (angular.isDefined(findData.value_2)){
						findData.value_2 = null;
						};
						if (angular.isDefined(findData.value_3)){
						findData.value_3 = null;
						};
						if (angular.isDefined(findData.value[0])){
						findData.value[0].VALUE = null;
						};
						findData.isSaveValidFlag = 'Y';
						
            		}
            		}
            	    return findData;
      	 }
		 
		 
        	/* if(row.line_no === '4A'){
                if(row.value[1].VALUE === 'N'){
                    $scope.irsF5471ScheduleGData[4].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[4].value.VALUE = null;
                    $scope.irsF5471ScheduleGData[5].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[5].value.VALUE = null;
                }
                else{
                    $scope.irsF5471ScheduleGData[4].isEditableFlag = 'Y';
                    $scope.irsF5471ScheduleGData[5].isEditableFlag = 'Y';
                }
            }         
            else if(row.line_no === '5A'){
                if(row.value[1].VALUE === 'N'){
                    $scope.irsF5471ScheduleGData[7].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[7].value.VALUE = null;
                }
                else{
                    $scope.irsF5471ScheduleGData[7].isEditableFlag = 'Y';
                }
            }
            else if(row.line_no === '6A'){
                if(row.value[1].VALUE === 'N'){
                    $scope.irsF5471ScheduleGData[9].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[9].value.VALUE = null;
                    $scope.irsF5471ScheduleGData[10].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[10].value.VALUE = null;
                    $scope.irsF5471ScheduleGData[11].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[11].value.VALUE = null;
                }
                else{
                    $scope.irsF5471ScheduleGData[9].isEditableFlag = 'Y';
                    $scope.irsF5471ScheduleGData[10].isEditableFlag = 'Y';
                    $scope.irsF5471ScheduleGData[11].isEditableFlag = 'Y';
                }
            }
            else if(row.line_no === '7'){
                if(row.value[0].VALUE !== 'Y'){
                    $scope.irsF5471ScheduleGData[14].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[14].value[0].VALUE = null;
                    $scope.irsF5471ScheduleGData[15].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[15].value[0].VALUE = null;
                    $scope.irsF5471ScheduleGData[16].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[16].value.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[17].value.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_acquisition.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_comparable.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_income.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_market.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_residual.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_unspecified.VALUE = null;
                }
                else{
                    $scope.irsF5471ScheduleGData[14].isEditableFlag = 'Y';
                    $scope.irsF5471ScheduleGData[15].isEditableFlag = 'Y';
                    $scope.irsF5471ScheduleGData[16].isEditableFlag = 'Y';
                    $scope.irsF5471ScheduleGData[17].isEditableFlag = 'Y';
                }
            }
            else if(row.line_no === '10'){
                if(row.value[0].VALUE !== 'Y'){
                    $scope.irsF5471ScheduleGData[16].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[16].value.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[17].value.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_acquisition.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_comparable.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_income.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_market.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_residual.VALUE = null;
                    $scope.irsF5471ScheduleGData[17].value_unspecified.VALUE = null;
                }
                else{
                    $scope.irsF5471ScheduleGData[16].isEditableFlag = 'Y';
                    $scope.irsF5471ScheduleGData[17].isEditableFlag = 'Y';
                }

            }
            else if(row.line_no === '14A'){
                if(row.value[1].VALUE === 'N'){
                    $scope.irsF5471ScheduleGData[20].isEditableFlag = 'N';
                    $scope.irsF5471ScheduleGData[20].value.VALUE = null;
                }
                else{
                    $scope.irsF5471ScheduleGData[20].isEditableFlag = 'Y';
                }
            }
            else if(row.line_no === '19'){
                if(row.value[0].VALUE !== 'Y'){
                    $scope.irsF5471ScheduleGData[25].value_1.VALUE = null;
                    $scope.irsF5471ScheduleGData[25].isEditableFlagText = 'N';
                }
                else{
                    $scope.irsF5471ScheduleGData[25].isEditableFlagText = 'Y';
                }
            }
            else if(row.line_no === '20'){
                if(row.value[0].VALUE !== 'Y'){
                    $scope.irsF5471ScheduleGData[26].value_2.VALUE = null;
                    $scope.irsF5471ScheduleGData[26].isEditableFlagText = 'N';
                }
                else{
                    $scope.irsF5471ScheduleGData[26].isEditableFlagText = 'Y';
                }
            }
            else if(row.line_no === '21'){
                if(row.value[0].VALUE !== 'Y'){
                    $scope.irsF5471ScheduleGData[27].value_3.VALUE = null;
                    $scope.irsF5471ScheduleGData[27].isEditableFlagText = 'N';
                }
                else{
                    $scope.irsF5471ScheduleGData[27].isEditableFlagText = 'Y';
                }
            }*/
        };

        vm.formatNumberForDecimal = function(value, attrName) {
            vm.schedule_g[attrName] = parseInt(Number(value));

        }
		
		

        /*vm.checkingForValidSave = function (scope) {
	
	     vm.isSaveValidFlag = true;
            vm.invalidSaveMessage = "Error saving Schedule G data - ";
            
            
            
            
          
            if(scope[3].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[4].value.VALUE) || scope[4].value.VALUE === null)||
                (!scope[5].value.VALUE) || scope[5].value.VALUE === null)){
                vm.isSaveValidFlag = false;
                vm.invalidSaveMessage += "Check values for lines 4B and 4C. ";
            }
            
            if(scope[6].value[0].VALUE === 'Y' && (!angular.isDefined(scope[7].value.VALUE) || scope[7].value.VALUE === null)){
                vm.isSaveValidFlag = false;
                vm.invalidSaveMessage += "Check values for lines 5B. ";
            }

            if(scope[8].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[9].value.VALUE) || scope[9].value.VALUE === null) ||
                (!angular.isDefined(scope[10].value.VALUE) || scope[10].value.VALUE === null) || (!angular.isDefined(scope[11].value.VALUE)
                    || scope[11].value.VALUE === null))){
                vm.isSaveValidFlag = false;
                vm.invalidSaveMessage += "Check values for lines 6B, 6C and 6D. "
            }

            if(scope[12].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[14].value[0].VALUE) || scope[14].value[0].VALUE === null) ||
                (!angular.isDefined(scope[15].value[0].VALUE) || scope[15].value[0].VALUE === null))){
                vm.isSaveValidFlag = false;
                vm.invalidSaveMessage += "Check values for lines 9 and 10. "

            }

            if(scope[15].value[0].VALUE === 'Y'){
                if(!angular.isDefined(scope[16].value.VALUE) || scope[16].value.VALUE === null){
                    vm.isSaveValidFlag = false;
                    vm.invalidSaveMessage += "Check values for line 11. "
                }
                else if((!angular.isDefined(scope[17].value_comparable.VALUE) || scope[17].value_comparable.VALUE === null) && (!angular.isDefined(scope[17].value_acquisition.VALUE)
                    || scope[17].value_acquisition.VALUE === null)  && (!angular.isDefined(scope[17].value_income.VALUE) || scope[17].value_income.VALUE === null)
                    && (!angular.isDefined(scope[17].value_market.VALUE) || scope[17].value_market.VALUE === null) && (!angular.isDefined(scope[17].value_residual.VALUE) || scope[17].value_residual.VALUE === null)
                    && (!angular.isDefined(scope[17].value_unspecified.VALUE) || scope[17].value_unspecified.VALUE === null)){
                    vm.isSaveValidFlag = false;
                    vm.invalidSaveMessage += "Check values for line 12. "
                }
                else if(scope[17].value_comparable.VALUE === '0' && scope[17].value_acquisition.VALUE === '0' && scope[17].value_income.VALUE === '0'
                    && scope[17].value_market.VALUE === '0' && scope[17].value_residual.VALUE === '0' && scope[17].value_unspecified.VALUE === '0'){
                    vm.isSaveValidFlag = false;
                    vm.invalidSaveMessage += "Check values for line 12. "
                }
            }

            if(scope[19].value[0].VALUE === 'Y' && (!angular.isDefined(scope[20].value.VALUE) || scope[20].value.VALUE === null)){
                vm.isSaveValidFlag = false;
                vm.invalidSaveMessage += "Check values for lines 14B. "

            }

          
            if(scope[26].value[0].VALUE === 'Y' && (!angular.isDefined(scope[26].value_2.VALUE) || scope[26].value_2.VALUE === null)){
                vm.isSaveValidFlag = false;
                vm.invalidSaveMessage += "Check values for lines 20."
            }
            if(scope[27].value[0].VALUE === 'Y' && (!angular.isDefined(scope[27].value_3.VALUE) || scope[27].value_3.VALUE === null)){
                vm.isSaveValidFlag = false;
                vm.invalidSaveMessage += "Check values for lines 21."
            }

            return vm.isSaveValidFlag;
        };
        */
        
        
        vm.checkingForValidSave = function (scope) {
        
        for(var i = 0;i<$scope.irsF5471ScheduleGData.length;i++){
                if ($scope.irsF5471ScheduleGData[i].isSaveValidFlag == 'N'){
				vm.isSaveValidFlag = false;
				vm.invalidSaveMessage = "Please check the below errors"
				AlertService.add("error", vm.invalidSaveMessage, 6000);
			    return;
					}
				else vm.isSaveValidFlag = true;	
               }
   
  			 }
   vm.saveScheduleGForm = function () {

            var editRow = [];
            var addRow = [];
            var deleteRow = [];
            var scheduleSettings = [{
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            }];

            /*if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            $scope.crudLoading = true;*/

             vm.checkingForValidSave($scope.irsF5471ScheduleGData);
          
            if($scope.irsF5471ScheduleGData.length > 0) {
                var saveObject = [];
                var value = '';
                var temporaryObject = $scope.irsF5471ScheduleGData;

                for (var loopIndex = 0; loopIndex < temporaryObject.length; loopIndex++){
                    if(temporaryObject[loopIndex].line_type == 'auto5' || temporaryObject[loopIndex].line_type == 'check' || temporaryObject[loopIndex].line_type == 'a' ||temporaryObject[loopIndex].line_type == 'b'){
                   /* if(loopIndex === 17 || loopIndex === 25 || loopIndex === 26 || loopIndex === 27){*/
                        for(var key in temporaryObject[loopIndex]){
                            if (!temporaryObject[loopIndex].hasOwnProperty(key)) continue;

                            var obj = temporaryObject[loopIndex][key];
                            if(typeof obj !== "undefined" && obj!== null &&  obj.hasOwnProperty('attrib_name')){
                                if(temporaryObject[loopIndex].line_no === '12') {
                                    if(obj.VALUE === "0") {
                                        value = '';
                                    } else {
                                        value = obj.VALUE;
                                    }
                                } else {
                                    value = obj.VALUE;
                                }
                                
                                var lineAttribKey = obj.line_attrib_key;
                                var attribName = obj.attrib_name;
                                
                                saveObject = [{
                                    "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                                    "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
                                    "combination_key": vm.rowData.HO_COMBINATION_KEY,
                                    "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                                    "form_key":temporaryObject[loopIndex].saveParams.form_key,
                                    "line_no":temporaryObject[loopIndex].line_no,
                                    "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
                                    "line_attrib_key" : lineAttribKey,
                                    "Attribute_Name": attribName,
                                    "Attribute_Value":value,
                                    "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
                                    "trans_status":"A"
                                }];
                                editRow.push(saveObject);
                            }
                        }
                    }
                    
                    if (temporaryObject[loopIndex].hasOwnProperty('propertyType') &&
                    		temporaryObject[loopIndex].propertyType==13) {
                        for (var k=0; k < temporaryObject[loopIndex].attrib_name.length; k++) {
                                saveObject = [{
                                    "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                                    "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
                                    "combination_key": vm.rowData.HO_COMBINATION_KEY,
                                    "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                                    "form_key":temporaryObject[loopIndex].saveParams.form_key,
                                    "line_no":temporaryObject[loopIndex].line_no,
                                    "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
                                    "line_attrib_key" : temporaryObject[loopIndex].value[k].line_attrib_key,
                                    "Attribute_Name": temporaryObject[loopIndex].attrib_name[k],
                                    "Attribute_Value":temporaryObject[loopIndex].value[k].VALUE,
                                    "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
                                    "trans_status":"A"
                                }];
                                editRow.push(saveObject);
                        }
                    	
                    } else if (temporaryObject[loopIndex].propertyType !== '0') {
	                    saveObject = [{
	                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
	                        "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
	                        "combination_key": vm.rowData.HO_COMBINATION_KEY,
	                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
	                        "form_key":temporaryObject[loopIndex].saveParams.form_key,
	                        "line_no":temporaryObject[loopIndex].line_no,
	                        "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
	                        "line_attrib_key" : temporaryObject[loopIndex].value.line_attrib_key,
	                        "Attribute_Name": temporaryObject[loopIndex].attrib_name,
	                        "Attribute_Value":temporaryObject[loopIndex].value.VALUE,
	                        "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
	                        "trans_status":"A"
	                    }];
	                    editRow.push(saveObject);
                    }
                }

				}

            vm.checkingForValidSave($scope.irsF5471ScheduleGData);
            
            if (vm.isSaveValidFlag == true){

                var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                var message = "Schedule G details successfully saved";

               

                form5471ScheduleGGroupObjFactory.saveForm5471ScheduleG(scheduleSettings,
                    scheduleDetails).then(
                    function(result) {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error", result.errorMessage, 4000);
                            //$scope.crudLoading = false;
                        } else {
                            vm.saveFlag = true;
                            AlertService.add("success", message, 4000);
                           // $scope.crudLoading = false;
                        }
                    });
                   }
            
        };

	}
				
	///////////////////////////////////
	
	
	 function scheduleIInfoCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, form5471ScheduleGGroupObjFactory,
    		AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,
			rowData, colData, gridData,thisObj) {			
				
				
				var vm = this||thisObj;
    			vm.rowData = rowData||$scope.$parent.rowData;
    			vm.data = {};
		        
		        vm.tax_year_flag  = workspaceFactory.activeScreen.filters.filterParams.tax_year == '2019' ? true :false;
        
       vm.modalTitle = "Schedule I Summary of Shareholder's Income Form Foreign Corporation";
       
        if (angular.isObject($uibModalInstance)) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				/*var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
				$rootScope.$emit('gridUpdate', args);*/
				$uibModalInstance.close();
				vm.modal_name = null;
			};			
		} else {
			vm.modal_name = null;			
		}


				
				
	  vm.initLoadData = function(){
        	vm.dataLoading = true;
            getScheduleIdata();
          
        	};
        vm.initLoadData();
        
        
        
        
        
        
         function getScheduleIdata()
        {
            var params = {
                "action_code": 'pru0jb',
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "irs_form_no" : 'F5471',
                "combination_key": vm.rowData.HO_COMBINATION_KEY
            };
           
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {                	
                    vm.irsF5471ScheduleIData = data.jsonObject;
                    vm.attribData = [];
                    vm.irsF5471ScheduleIDataTemp = data.jsonObject;
                    vm.arraySize = vm.irsF5471ScheduleIDataTemp;
                    console.log("check this out ::::::: ", vm.irsF5471ScheduleIDataTemp);

                    var attribName = null;
                    var attribValue = null;
                    for(var i=1; i <data.jsonObject.length; i++) {
                    var tempString = vm.irsF5471ScheduleIData[i].object_id;
                    var tempValue = vm.irsF5471ScheduleIData[i].attrib_value;
                    attribName = tempValue.split('~')[0];
                    attribValue =  ((vm.irsF5471ScheduleIData[i].VALUE=== "0" )? '':vm.irsF5471ScheduleIData[i].VALUE);
                    
                    vm.irsF5471ScheduleIData[i] = {
                    i : vm.irsF5471ScheduleIData[i],
                    "tax_year": tempString.split('~')[0],
                    "trans_type_key":tempString.split('~')[1],
                    "attrib_name":tempValue.split('~')[0],
                    "attrib_desc":tempValue.split('~')[3],
                    "line_attrib_key":tempValue.split('~')[1],
                    "trans_details_key":tempString.split('~')[3],
                    "occurrence":tempString.split('~')[4],
                    "VALUE":vm.irsF5471ScheduleIData[i].VALUE,
                    "form_key":tempString.split('~')[6]
                    };
                    if (attribName === '5471_I_UNBLK_Y' && attribValue === 'Y'){
            		    vm.radioUnblk = attribValue;
            			vm.attribData['5471_I_UNBLK_Y'] = attribValue;
            			vm.attribData['5471_I_UNBLK_N'] = null;
            		}else if (attribName === '5471_I_UNBLK_N' && attribValue === 'N'){
                		 vm.radioUnblk = attribValue;
                		 vm.attribData['5471_I_UNBLK_Y'] = attribValue;
                		 vm.attribData['5471_I_UNBLK_N'] = null;
            		}else if (attribName === '5471_I_UNBLK_Y' && attribValue === undefined){
               		 vm.radioUnblk = 'N';
            		 vm.attribData['5471_I_UNBLK_Y'] = attribValue;
            		 vm.attribData['5471_I_UNBLK_N'] = null;
            		}                   
            		else if (attribName === '5471_I_BLK_Y' && attribValue === 'Y'){
            		    vm.radioBlk = attribValue;
            			vm.attribData['5471_I_BLK_Y'] = attribValue;
            			vm.attribData['5471_I_BLK_N'] = null;
            		}else if (attribName === '5471_I_BLK_N' && attribValue === 'N'){
                		 vm.radioBlk = attribValue;
                		 vm.attribData['5471_I_BLK_Y'] = attribValue;
                		 vm.attribData['5471_I_BLK_N'] = null;
            		}
            		else if (attribName === '5471_I_BLK_Y' && attribValue === undefined){
                  		 vm.radioBlk = 'N';
                  		 vm.attribData['5471_I_BLK_Y'] = attribValue;
                  		 vm.attribData['5471_I_BLK_N'] = null;
               		}  
            		else if (attribName === '5471_I_ED_ACCOUNT_IND_Y' && attribValue === 'Y'){
            		    vm.radiodisp = attribValue;
            			vm.attribData['5471_I_ED_ACCOUNT_IND_Y'] = attribValue;
            			vm.attribData['5471_I_ED_ACCOUNT_IND_N'] = null;
            		}else if (attribName === '5471_I_ED_ACCOUNT_IND_N' && attribValue === 'N'){
                		 vm.radiodisp = attribValue;
                		 vm.attribData['5471_I_ED_ACCOUNT_IND_Y'] = attribValue;
                		 vm.attribData['5471_I_ED_ACCOUNT_IND_N'] = null;
            		}
            		else if (attribName === '5471_I_ED_ACCOUNT_IND_Y' && attribValue === undefined){
	            		    vm.radiodisp = 'N';
	            			vm.attribData['5471_I_ED_ACCOUNT_IND_Y'] = attribValue;
	            			vm.attribData['5471_I_ED_ACCOUNT_IND_N'] = null;
            		}else if (attribName === '5471_I_BOY_ED_ACCOUNT_BAL_AMT'){
            			    	 vm.boyEd = vm.radiodisp ==  'N'? null :attribValue ;
            		}else if (attribName === '5471_I_EOY_ED_ACCOUNT_BAL_AMT'){
               		 		 vm.eoyEd = vm.radiodisp ==  'N'? null :attribValue ;
            		}
            		else if (attribName === '5471_I_BOY_AGGRGT_ED_ACCOUNT_BAL_AMT'){
          		 		 vm.boyAgg = attribValue
            		}
            		else if (attribName === '5471_I_EOY_AGGRGT_ED_ACCOUNT_BAL_AMT'){
         		 		 vm.eoyAgg = attribValue
            		}
            		else if (attribName === '5471_I_TOT_HYBRID_DEDUCTION_ACCT_AMT'){
        		 		 vm.totDed = attribValue
            		}
                  else {
                  	vm.attribData[attribName]=attribValue;
                  }
                   
             }
            /* getFormData();     */ 
          }
                else 
                {
                    if (data.errorMessage) {
                        AlertService.add("",data.errorMessage ,
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
                vm.dataLoading = false;
            });
        }
        
        
        vm.checkingForValidationForI = function (value){        	
        	if (value === 'N') {
        		vm.boyEd = null;
        		vm.eoyEd = null;
        	}
        	else if (value ==='0') {            		
            		vm.attribData['5471_NA_ALT_INFO_2019_40_CODE'] = null;            	
            }        	
        }



			vm.saveScheduleIForm = function () {

            var editRow = [];
            var addRow = [];
            var deleteRow = [];
            var scheduleSettings = [{
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            }];
          
         if (vm.radioUnblk === 'Y'){
 			vm.attribData['5471_I_UNBLK_Y'] = vm.radioUnblk;
 			vm.attribData['5471_I_UNBLK_N'] = null;
         }else{
     		 vm.attribData['5471_I_UNBLK_N'] = vm.radioUnblk;
     		 vm.attribData['5471_I_UNBLK_Y'] = null;
 		}
         if (vm.radioBlk === 'Y'){
 			vm.attribData['5471_I_BLK_Y'] = vm.radioBlk;
 			vm.attribData['5471_I_BLK_N'] = null;
 	}else {
     		 vm.attribData['5471_I_BLK_N'] = vm.radioBlk;
     		vm.attribData['5471_I_BLK_Y'] = null;
 		}
         if (vm.radiodisp === 'Y'){
  			vm.attribData['5471_I_ED_ACCOUNT_IND_Y'] = vm.radiodisp;
  			vm.attribData['5471_I_ED_ACCOUNT_IND_N'] = null;
  	}else {
      		 vm.attribData['5471_I_ED_ACCOUNT_IND_N'] = vm.radiodisp;
      		vm.attribData['5471_I_ED_ACCOUNT_IND_Y'] = null;
  		}
         
   			vm.attribData['5471_I_BOY_ED_ACCOUNT_BAL_AMT'] = vm.boyEd;
   			vm.attribData['5471_I_EOY_ED_ACCOUNT_BAL_AMT'] = vm.eoyEd;
   			vm.attribData['5471_I_BOY_AGGRGT_ED_ACCOUNT_BAL_AMT'] = vm.boyAgg;
   			vm.attribData['5471_I_EOY_AGGRGT_ED_ACCOUNT_BAL_AMT'] = vm.eoyAgg;
   			vm.attribData['5471_I_TOT_HYBRID_DEDUCTION_ACCT_AMT'] = vm.totDed;
         
            if(vm.irsF5471ScheduleIData.length > 0) {
                var saveObject = [];
                var temporaryObject = vm.irsF5471ScheduleIData;
                var saveSchedule =[];
               
			                
			                		if (workspaceFactory.activeScreen.filters.filterParams.tax_year > '2019'){
			                			saveSchedule.push(
			        				vm.findIndex(temporaryObject, '5471_I_UNBLK_Y', vm.attribData['5471_I_UNBLK_Y']),
			        				vm.findIndex(temporaryObject, '5471_I_UNBLK_N', vm.attribData['5471_I_UNBLK_N']),
			        				vm.findIndex(temporaryObject, '5471_I_BLK_Y', vm.attribData['5471_I_BLK_Y']),
			        				vm.findIndex(temporaryObject, '5471_I_BLK_N', vm.attribData['5471_I_BLK_N']),
			        				vm.findIndex(temporaryObject, '5471_I_ED_ACCOUNT_IND_Y', vm.attribData['5471_I_ED_ACCOUNT_IND_Y']),
			        				vm.findIndex(temporaryObject, '5471_I_ED_ACCOUNT_IND_N', vm.attribData['5471_I_ED_ACCOUNT_IND_N']),
			        				vm.findIndex(temporaryObject, '5471_I_BOY_ED_ACCOUNT_BAL_AMT', vm.attribData['5471_I_BOY_ED_ACCOUNT_BAL_AMT']),
			        				vm.findIndex(temporaryObject, '5471_I_EOY_ED_ACCOUNT_BAL_AMT', vm.attribData['5471_I_EOY_ED_ACCOUNT_BAL_AMT']),
			        				vm.findIndex(temporaryObject, '5471_I_BOY_AGGRGT_ED_ACCOUNT_BAL_AMT', vm.attribData['5471_I_BOY_AGGRGT_ED_ACCOUNT_BAL_AMT']),
			        				vm.findIndex(temporaryObject, '5471_I_EOY_AGGRGT_ED_ACCOUNT_BAL_AMT', vm.attribData['5471_I_EOY_AGGRGT_ED_ACCOUNT_BAL_AMT']),
			        				vm.findIndex(temporaryObject, '5471_I_TOT_HYBRID_DEDUCTION_ACCT_AMT', vm.attribData['5471_I_TOT_HYBRID_DEDUCTION_ACCT_AMT'])
			                		 )}
			                		 else {
			                			 saveSchedule.push(
			                			 vm.findIndex(temporaryObject, '5471_I_UNBLK_Y', vm.attribData['5471_I_UNBLK_Y']),
			              				vm.findIndex(temporaryObject, '5471_I_UNBLK_N', vm.attribData['5471_I_UNBLK_N']),
			              				vm.findIndex(temporaryObject, '5471_I_BLK_Y', vm.attribData['5471_I_BLK_Y']),
			              				vm.findIndex(temporaryObject, '5471_I_BLK_N', vm.attribData['5471_I_BLK_N'])
			              				)
			                		 }
			        				
                
                
            
                for (var loopIndex = 0; loopIndex < saveSchedule.length; loopIndex++){
                   
                    saveObject = [{
                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                        "trans_type_key":saveSchedule[loopIndex].trans_type_key,
                        "combination_key": vm.rowData.HO_COMBINATION_KEY,
                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                        "form_key":saveSchedule[loopIndex].form_key,
                        "line_no":saveSchedule[loopIndex].i.line_no,
                        "occurence":((saveSchedule[loopIndex].occurrence === "0") ? null : saveSchedule[loopIndex].occurrence),
                        "line_attrib_key" : saveSchedule[loopIndex].line_attrib_key,
                        "Attribute_Name": saveSchedule[loopIndex].attrib_name,
                        "Attribute_Value":((vm.attribData[saveSchedule[loopIndex].attrib_name] === '' )? '' :vm.attribData[saveSchedule[loopIndex].attrib_name]),//vm.attribData[temporaryObject[loopIndex].attrib_name],
                        "trans_dtls_key" : ((saveSchedule[loopIndex].trans_details_key === "0") ? null : saveSchedule[loopIndex].trans_details_key),
                        "trans_status":"A"
                    }];
                    editRow.push(saveObject);
                }

            }

           

                var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                var message = "Schedule I details successfully saved";


                form5471ScheduleGGroupObjFactory.saveForm5471ScheduleI(scheduleSettings,
                    scheduleDetails).then(
                    function(result) {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error", result.errorMessage, 4000);
                          
                        } else {
                            vm.saveFlag = true;
                            AlertService.add("success", message, 4000);
                        }
                    });
         
        };

        vm.findIndex = function(data, attribName, value){
        	var findData = data.find(x => x.attrib_name === attribName);
        	findData.VALUE = value;
        	return findData;
        }
        vm.reset = function () {
            vm.getFormData();
            //$scope.crudLoading = false;
            $scope.irsF5471ScheduleGData = angular.copy(vm.formListOriginal);
        }

				
				}
				
				
});		
				