define([
    'angular',
    './auditIDRCtrl'

], function () {
    'use strict';

  
   return angular.module('app.audit-IDR', ['app.auditIDRCtrl'])
   .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
    $stateProvider
        .state('idr-controversy', {
            url: '/idr-controversy',
            templateUrl: 'app/components/ControversyApp/controversy-audits/audit-IDR/audit-idr.html',
            controller: 'auditIDRController as ctrl',
            type:'custom',
        });
    }]);
   
});