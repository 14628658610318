
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.maxLoanService',[])
        .factory("MaxLoanFactory",['$rootScope', '$http', '$log','JsonObjectFactory','AlertService', '$injector', 'workspaceFactory', 'GlobalService', 'GENERAL_CONFIG', function($rootScope, $http, $log,JsonObjectFactory,AlertService, $injector, workspaceFactory,GlobalService, GENERAL_CONFIG) {
            var factory = {};
            var returnObj = {};

            factory.save_max_loan_user_amt = function(_callName , _data){
                	if(_data.rowData.USER_AMT === null || _data.rowData.USER_AMT === '' || _data.rowData.USER_AMT == undefined)
                	{
                		_data.rowData.USER_AMT = _data.oldRowData.USER_AMT === undefined ? 0 : _data.oldRowData.USER_AMT;
                		AlertService.add("error", "Cannot enter null value in Override Column", 4000);
                	}
                	else
                	{
	                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qx3meb";
	                    var affectedRow = _data.rowData;
	        
	                    var jsonObj = JSON.stringify(affectedRow);
	        
	                    var jsonSettings = '{}';
	        
	                    var params = _.extend({jsonObj:jsonObj}, params);
	                    params =  _.extend({jsonSettings:jsonSettings}, params);
	                    params.process_name =  "saveMaxLoanDetails";
	                    var service = $injector.get('JsonObjectFactory');
	                    service.saveJSON(url , params).then(function(result){
	        
	                        if(result.callSuccess === "1"){
	                        	AlertService.add("success", "Data has been saved successfully", 1000);
	                        	  var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
	      	                    
	                            $rootScope.$emit('gridUpdate', args);
	                        }else{
	                            //AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
	                            AlertService.add("error", result.errorMessage, 4000);
	                            var args = {};
	                            $rootScope.$emit('gridUpdate', args);
	                        }
	
	                        
	
	                    });
                	}
            }
            factory.delete_maxloan_override = function(jsonSettings, jsonData){
            	
            	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vms99b";
            	var jsonObj = JSON.stringify(jsonData);
                
                var jsonSettings = jsonSettings;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = filterParams;
 	            params = _.merge({},  GlobalService.globalParams , params);
                params = _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                params.process_name =  "deleteMaxloanOverrideAmt";
                var service = $injector.get('JsonObjectFactory');
                var promise = $http({
 	        	   method: "post",
 	               url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vms99b",
 	               data: params
 	           }).then(function (response) {
 	               var status = response.status;
 	               return response.data;
 	           });
 	
 	           return promise;
                
            }
            return factory;
        }]) 

    return services;
});
