define([
    'angular',
    './ProjectTagController',
    './ProjectTagService',
    './schdmSelector'


], function () {
    'use strict';
    return angular.module('app.projectTagging', ['app.projectTagController','app.projectTagService','app.schdmSelector'] );


});