define(
		[ 'angular',
			'./RdSalesAdjPercentageService',

		],
		function() {
			'use strict';

			var controllers = angular.module('app.RdSalesAdjPercentageCtrl', [])
			.directive("limitToMaxFromOne", function() {
                return {
                    link: function(scope, element, attributes) {
                        //var count = 0;
                        element.bind('keypress', function(e) {
                            if (!(e.charCode >= 46 && e.charCode <= 57) || e.charCode === 47) {
                                e.preventDefault();
                            }
                            var newVal = $(this).val() + (e.charCode !== 0 ? String.fromCharCode(e.charCode) : '');
                            if (String.fromCharCode(e.charCode) === ".") {

                                if ($(this).val().indexOf(".") != -1) {
                                    e.preventDefault();
                                }
                            }

                            if ($(this).val().search(/(.*)\.[0-9][0-9]/) === 0 && newVal.length > $(this).val().length) {
                                e.preventDefault();
                            }
                        });
                        element.on("keydown keyup", function(e) {
                            var decimalData = element[0].value
                            if(decimalData<0 || decimalData>100){
                                console.log(" Its not in the range ..  so revert back ");
                                element[0].value=100;
                                
                           }

                            function countDecimalPlaces() {
                                var decimalPos = String(decimalData).indexOf('.');
                                if (decimalPos === -1) {
                                    return 0;
                                } else {
                                    return String(decimalData).length - decimalPos - 1;
                                }
                            }




                            // removeExtraDots();
                            if (countDecimalPlaces() > 2) {
                                element.val(Number(decimalData).toFixed(2));
                            }
                            if (Number(element.val()) > Number(attributes.max) &&
                                e.keyCode != 46 && e.keyCode != 8
                            ) {
                                e.preventDefault();
                                element.val(attributes.max);
                            }
                            
                            if (element.val() !== "" && Number(element.val()) < Number(attributes.min) &&
                                    e.keyCode != 46 && e.keyCode != 8
                                ) {
                                    e.preventDefault();
                                    element.val(attributes.min);
                                }

                        });
                    }
                };
            })
					.controller('rdSalesAdjPercentageCtrl', ['$scope','$rootScope','$state', '$http', 
						'AlertService', 'USER_SETTINGS', 'GlobalService', 
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
						'rdSalesAdjPercentageFactory','rowData',
						'gridData', '$uibModal', '$uibModalInstance','$filter',rdSalesAdjPercentageCtrl])
							    
			function rdSalesAdjPercentageCtrl($scope, $rootScope, $state, $http,
					 AlertService,USER_SETTINGS, GlobalService,
					JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, rdSalesAdjPercentageFactory,rowData,
					gridData, $uibModal, $uibModalInstance,$filter) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.jsonObject = null;
				vm.taxYearInfo = [];
				vm.percentageInfo = [];
				vm.isSaveDisabled = false;
				vm.selected = _.get(GlobalService,'inputs.tax_year.selected');
				vm.isCreate = (gridData.length == 0) ? true : false;
				vm.taxYear = vm.isCreate ? vm.selected.value : rowData.TAX_YEAR;
				vm.percentage = vm.isCreate ? "": rowData.PERCENTAGE;
				//To get the Grid Filter Params 
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.scenario = GlobalService.globalParams.scenario; //filterParams.scenario; 
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				
				//Cancel/Close the Modal
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				//reset the form
				vm.reset = function(type){	
					if(type == 'add') {
						vm.percentage = "";
					}else{
						vm.percentage = rowData.PERCENTAGE;
					}
				}
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"p_scenario" : vm.scenario,
						"p_jcd_key" :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + vm.taxYear + "." ,'4000');
							return;
						}							
					});
				});
				
				//Get the Data from FTC Code Mast 
				/*vm.getDefaultDropDown = function() {		
					var params = {"action_code" : 'dbty56'};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.jsonObject = data.jsonObject;
						 vm.percentageInfo = $filter('filter')(data.jsonObject, function(data) {
	                            return (data.CODE_GROUP === '861_CONFIG');
	                     });
						 if(!vm.isCreate){
							 vm.percentage = {CODE_KEY:rowData.CODE_KEY,CODE_DESC:rowData.CODE_DESC};
						 }
		
					});
					
					
				};*/
			    //vm.getDefaultDropDown();
				
				//Get the Current Year
				vm.getCurrentYear = function() {
					var currentdate = new Date();
				    return currentdate.getFullYear();
				}
				
				//Save the Modal Details
				vm.save = function(){	
					/*var isExistInGrid = _.find(vm.jsonObject, {'TAX_YEAR':parseInt(vm.taxYear.value),'IS_DEFAULT':'Y'})
					if( rowData == null && isExistInGrid !== undefined){
						AlertService.add("warning", "For "+vm.taxYear.value+" tax year config is already set", 4000);
					}else{*/
					console.log(vm.getCurrentYear());
					if((vm.getCurrentYear()-1) < vm.taxYear){
						AlertService.add("warning", "For "+vm.taxYear+" tax year percentage can not be created", 4000);
						return;
					}
					
					if(vm.percentage === undefined){
						AlertService.add("error", "Percentage is required", 4000);
						return;
					}
					
					var clobSettingsObj = {};
					clobSettingsObj['sso_id'] = vm.userSettings.user.sso_id;
					clobSettingsObj['scenario'] = vm.scenario;
					clobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					
					var returnObj = {};
					
					returnObj['sso_id'] = vm.userSettings.user.sso_id;
					returnObj["tax_year"] = vm.taxYear;
					returnObj['scenario'] = vm.scenario;
					returnObj['jcd_key'] = GlobalService.globalParams.jcd_key;					
					returnObj["operation_type"] = vm.isCreate ? 'I':'U';
					//returnObj["CODE_DESC"] = vm.isCreate ? : vm.percentage.CODE_DESC;
					returnObj["code_key"] = vm.isCreate ? null : rowData.ADJ_KEY;
					returnObj["percentage"]=vm.percentage;
					var clobDataDetails = [];
					clobDataDetails.push(returnObj);
					console.log("saveDataDetails  -- ", clobDataDetails);
					
					var message = "R&d Percentage have been saved/updated";
					//send to save the Data					
					sendSaveDetails(clobSettingsObj, clobDataDetails, message);
					}
				
				
				//Send Details to the Service Layer for persisting the data
				function sendSaveDetails(clobSettingsObj, clobDataDetails, message) {					
					rdSalesAdjPercentageFactory.savePercentage(clobSettingsObj, clobDataDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							// vm.isSaveClicked = false;
							return;
						} else {
							AlertService.add("success", message, 4000);							
							$uibModalInstance.dismiss('cancel');
							var args = {};
							$rootScope.$emit('gridUpdate', args);
								$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
				
				//Redirect the URL to a respective screen during navigation 
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
				
			}		
		
			return controllers;
			
		});