define([
    'angular'
], function () {
    'use strict';

    var services = angular.module('app.ProjectService', [])
                          .factory('ProjectFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', ProjectFactory])
                          .factory('ProjectStepFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', ProjectStepFactory])
                          ;

    function ProjectFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateProjectData = function (_callName, _data) {
            var json_arrOBJ = [];
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=3lwv34';
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);
            params.process_name = 'UPDATE_PROJECT_DATA';
            json_arrOBJ.push(_data.rowData);
            params = _.extend({
                sso_id: sso_id,
                clob_data: JSON.stringify(json_arrOBJ)
            }, params);

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        PROJECT_ID: _data.rowData.PROJECT_ID,
                        gridFilter: {
                            PROJECT_ID: _data.rowData.PROJECT_ID
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteProjectData = function (data) {
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            // var jsonSettings = JSON.stringify(_data1);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                sso_id: sso_id,
                clob_data: jsonObj
            }, params);
            // params = _.extend({jsonSettings: jsonSettings}, params);
            params.process_name = "";

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=7c0ztf",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    };


    function ProjectStepFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateProjectStepData = function (_callName, _data) {
            var json_arrOBJ = [];
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=xnged6';
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);
            params.process_name = 'UPDATE_PROJECT_STEP_DATA';
            json_arrOBJ.push(_data.rowData);
            params = _.extend({
                sso_id: sso_id,
                clob_data: JSON.stringify(json_arrOBJ)
            }, params);

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        PROJECT_STEP_ID: _data.rowData.PROJECT_STEP_ID,
                        gridFilter: {
                            PROJECT_STEP_ID: _data.rowData.PROJECT_STEP_ID
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteProjectStepData = function (data) {
            var jsonObj = JSON.stringify(data);
            // var jsonSettings = JSON.stringify(_data1);
            var params = {};
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                sso_id: sso_id,
                clob_data: jsonObj
            }, params);
            // params = _.extend({jsonSettings: jsonSettings}, params);
            params.process_name = "";

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=k3gshw",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    };

    return services;
});
