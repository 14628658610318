define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.post86EpHistoryReportService',[])
       .factory("post86EpHistoryReportFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   		,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var post86EpHistoryReportFactory ={};
	           post86EpHistoryReportFactory.url ={};
	           post86EpHistoryReportFactory.url.getGtapsPost86EpHistoryData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=9ediuv";//Action id 1147
	           
		       post86EpHistoryReportFactory.getGtapsPost86EpHistoryData = function(passedParams) {
				   var params = {"process_name":"GTAPS Get Post 86 E&P History Data"};
				   params = _.merge({},  passedParams, params);
		           var promise = $http({
		        	   method: "post",
		               url: post86EpHistoryReportFactory.url.getGtapsPost86EpHistoryData,
		               data: params
		           }).then(function (response) {
		               //var status = response.status;
		               return response.data;
		           });
		
		           return promise;
	           };
           
	           return post86EpHistoryReportFactory;
       }]);

   	  return services;
});