/**
 * Created by 212544474 on 8/7/2017.
 */
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.adjPriorYearService',[])
        .factory("AdjPriorYearFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector', '$http', 'GlobalService', 'workspaceFactory','GENERAL_CONFIG', 
        	function($rootScope, $log,JsonObjectFactory,AlertService, $injector, $http, GlobalService, workspaceFactory,GENERAL_CONFIG) {
            var factory = {};
            var returnObj = {};

            factory.saveAdjPriorYearData = function(_callName , _data){
            	console.log("data", _data);
                if(_data.rowData.CHART_KEY != _data.rowData.CY_CHART_KEY) {
                    var intr=setInterval(function(){ 
                        AlertService.add("error", "You cannot make changes to prior year balance.");
                       clearInterval(intr);
                       $rootScope.$apply();
                    },1000);
                    _data.rowData.ADJ_FOR_OPEN_BS = _data.oldRowData.ADJ_FOR_OPEN_BS;
                    
        			return false;
                }
                else {
                    _data.rowData.ADJ_BALANCE = parseInt(_data.rowData.PRIOR_YEAR_SUBMISSION) + parseInt(_data.rowData.ADJ_FOR_OPEN_BS);
                    $rootScope.$broadcast('gridRefresh', false); 
                    
                    returnObj.combination_key = _data.rowData.COMBINATION_KEY;

                    var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=107";
                    var jsonObj = JSON.stringify(_data.rowData);
                    //var jsonSettings = '{}';
                    var jsonSettings = [{
                        tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        issue_key : GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)
                    }];
                    jsonSettings = JSON.stringify(jsonSettings);
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = _.merge({}, GlobalService.globalParams, filterParams);
                    var params = _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    params.process_name =  "saveLocalAdjPriorYearData";

                    service.saveJSON(url, params).then(function(data){
                        if(data.callSuccess === "1"){
                            AlertService.add("success", "Data saved Successfully.",4000);
                            var args = {combination_keys: returnObj.combination_key, gridFilter: {COMBINATION_KEY: returnObj.combination_key}};
                            $rootScope.$emit('gridUpdate', args);
                        }else{
                            AlertService.add("", data.errorMessage);
                        }
                    });
                   
                }
            }
            
            factory.updatePriorYearScenario = function(_data,Details){
               
               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
  	           var jsonSettings =  JSON.stringify(_data);
  	           var jsonObj = JSON.stringify(Details);
  	           
  	           var params = filterParams;
  	           params = _.merge({},  GlobalService.globalParams , params);
  	           params =  _.extend({jsonObj:jsonObj}, params);
  	           params =  _.extend({jsonSettings:jsonSettings}, params);
  	           
  	          var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=3jibvt";

  	           if( GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined){
  	        	   params.jcd_key = "250";
  	           }
  	           params.process_name =  "Update Prior Year Scenario - FIR";
  	           var promise = $http({
  	        	   method: "post",
  	               url: url,
  	               data: params
  	           }).then(function (response) {
  	               var status = response.status;
  	               return response.data;
  	           });
  	
  	           return promise;
            }
            return factory;
        }])

    return services;
});
