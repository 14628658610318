define([
    'angular',
    './partnershipControllers',
    './partnershipServices'
   
    
], function () {
    'use strict';
    return angular.module('app.partnership', ['app.partnershipControllers','app.partnershipServices'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
      
    	
    }]);
        
});
