
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.RdExpAdjService',[])

        .factory("RdExpAdjFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','workspaceFactory'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,workspaceFactory) {
            var rdExpAdjFactory = {};
            var totalModulesAssigned = 0;
            rdExpAdjFactory.url ={};
            rdExpAdjFactory.url.RandD_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zlxjsx";

            
            
            rdExpAdjFactory.save = function(_data,Details,otherInfo, lockObj){
            	
            	if(lockObj && lockObj.modal_name == 'adjExpRnDSales'){
                    GlobalService.modalLockFilters(lockObj.lockingObj); /*  for locking Object */
                }

                var filterParams =  (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                var clobSettings = JSON.stringify({sso_id:otherInfo.sso_id,tax_year:otherInfo.tax_year,scenario:otherInfo.scenario,jcd_key:otherInfo.jcd_key,screen_key:otherInfo.screen_key});
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
               // params =  _.extend({clob_settings:jsonSettings}, params);
                params =  _.extend({clob_settings:clobSettings}, params);
                params =  _.extend({sso_id:otherInfo.sso_id,tax_year:otherInfo.tax_year,scenario:otherInfo.scenario,jcd_key:otherInfo.jcd_key,screen_key:otherInfo.screen_key}, params);
//                params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "R&D_EXPSAVE";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: rdExpAdjFactory.url.RandD_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
              
            
            
            return rdExpAdjFactory;
        }])


    return services;


});