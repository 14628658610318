define([
    'angular',
    './consolidationTrialBalanceCtrl',    
], function () {
    'use strict';
    return angular.module('app.FIR_ConsolidationTrialBalance', ['app.consolidationTrialBalanceCtrl'] )
    .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
        $stateProvider
            .state('consolidation-trial-balance', {
                url: '/consolidation-trial-balance',
                templateUrl: 'app/components/FIR_ConsolidationTrialBalance/consolidationTrialBalance.html',
                onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
                api: {},
                data:{},
                access:""
            })
    }])

});


        