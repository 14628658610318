define([
    'angular',
    './sourcingAmountsMainController'



], function () {
    'use strict';
    return angular.module('app.sourcingAmountsMain', ['app.sourcingAmountsMainController'] );

});
