define([
    'angular',
    './ValidationRulesController',
    './ValidationRulesService',
    './ruleEngineConfigControllers',
    './ruleEngineConfigService',
    './ruleEngineNewConfigService',
    './ruleEngineDirectives'
    
], function () {
    'use strict';
    return angular.module('app.validationrules', ['app.validationRulesControllers','app.validationRulesServices',
    	'app.ruleEngineConfigControllers','app.ruleEngineConfigService','app.ruleEngineNewConfigService','app.ruleEngineConfigDirectives','ui.toggle'])
    .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
        $stateProvider
            .state('admin.rules-engine', {
                url: '/rules-engine',
                templateUrl: ' app/components/admin/rulesengine/rules-engine-list.html',
                type:'custom',
                api: {"get":GENERAL_CONFIG.base_url+"/loadGridJson?action_code=d4yabw&grid_id=102","grid_id":"102"},
                access: "4"
            });
    }]);
        
});
