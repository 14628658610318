define([
    'angular',
    './EpAdjustmentsLocalController',
    './EpAdjustmentsLocalService',
    './add-attachments/addCommentModalTaxAdjustmentsAttachmentsController'
], function () {
    'use strict';
    return angular.module('app.epTaxAdjsLocalChart', ['app.epAdjustmentsLocalController','app.epAdjustmentsLocalService',
        'app.addCommentModalTaxAdjustmentsAttachmentsController'] );
});
