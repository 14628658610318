define([
    'angular',
    './submissionDashboardController',
    './submissionDashboardService'


], function () {
    'use strict';
    return angular.module('app.submissionDashboard', ['app.submissionDashboardController', 'app.submissionDashboardService'])
                  .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
                      $stateProvider
                          .state('submissionDashboard', {
                              url: 'submissionDashboard',
                              templateUrl: 'app/components/_componentStarter/index.html',
                              data: {},
                              access: ""
                          })
                  }])
                  .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
                      $stateProvider
                          .state('summary-diagnostics', {
                              url: '/summary-diagnostics',
                              templateUrl: 'app/components/submissionDashboard/templates/summary-diag_tpl.html',
                              data: {},
                              access: ""
                          })
                  }])
});