define([
    'angular',
    './controversyAuditsCtrl'

], function () {
    'use strict';

  
   return angular.module('app.controversy-audits', ['app.controversyAuditsCtrl'])
   .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
    $stateProvider
        .state('federal', {
            url: '/controversy/federal',
            templateUrl: 'app/components/ControversyApp/controversy-audits/controversy-federal-component.html',
            controller: 'controversyFederalCtrl as ctrl',
            type:'custom',
        });
    }]);
   
});