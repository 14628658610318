define([
    'angular',
    './DupsStmtReportController'
], function () {
    'use strict';
    var module = angular.module('app.dstDupsStmtReport', ['app.DupsStmtReportController'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('dst-dups-diag', {
                url: '/dst-dups-diag',
                "templateUrl": "app/components/DST/diagnostics/dups-stmt-report/dupsStmtReport.html",
                "controller": "DupsStmtReportCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
