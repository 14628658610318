define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.elimReclassService',[])

        .factory("EliminationFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
            var elimFactory = {};
            elimFactory.url = {};
            elimFactory.url.saveUpdateElims  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=108";
            elimFactory.url.deleteElims = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=109";
            
            elimFactory.getActiveScreen = function() {
                return workspaceFactory.activeScreen.screen_key;
            };
            
             elimFactory.saveUpdateEliminations = function(_data,_accounts){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_accounts);
                var jsonSettings =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "ELIMINATION_SAVE";
                console.log("elimination params to save");
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: elimFactory.url.saveUpdateElims,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            
            elimFactory.deleteElims = function(_data) {
              
              var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({}, _data, params);
              
                var promise = $http({
                    method: "post",
                    url: elimFactory.url.deleteElims,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            return elimFactory;
        }]);


    return services;


});