define(
		[ 'angular',
			'./htkoConfigService',

		],
		function() {
			'use strict';

			var controllers = angular.module('app.htkoConfigCtrl', [])
					.controller('htkoConfigCtrl', ['$scope','$rootScope','$state', '$http', 'AlertService', 'USER_SETTINGS', 'GlobalService', 
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'HTKOConfigFactory','rowData', 'gridData', '$uibModal', '$uibModalInstance','$filter',htkoConfigCtrl])
							    
			function htkoConfigCtrl($scope, $rootScope, $state, $http, AlertService,USER_SETTINGS, GlobalService,
					JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, HTKOConfigFactory, rowData, gridData, $uibModal, $uibModalInstance,$filter) {


				var vm = this;	
				vm.userSettings = USER_SETTINGS;
				vm.rowData = rowData;
				//Logged user id
				vm.logged_in_user = vm.userSettings.user.sso_id;
				//Show the loading Spinner
				vm.loadingData = true;	
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//A edit modal
				vm.isEditMode = false;
				//To get the Grid Filter Params 
				var filterParams = workspaceFactory.activeScreen.filters.getFilterParams();
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.scenario = GlobalService.globalParams.scenario;  //filterParams.scenario;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				vm.sourceData = [];
				vm.selectedSourceCode = null;
				vm.filing_group_key = filterParams.filing_group_key;
				vm.filer_entity_key = filterParams.filer_entity_key;
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"p_scenario" : vm.scenario,
						"p_jcd_key" :  vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
							
				
				//Get the Data from FTC Code Mast 
				vm.gethtkoConfigCodeMast = function() {		
					var params = {"action_code" : 'lfmzzq',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						console.log(" data.jsonObject : ", data.jsonObject);
						vm.htkoConfigCodeMastData = _.filter(data.jsonObject, function(p){
	                		 return _.includes(['FORM_1118_HTKO_CONFIG'], p.CODE_GROUP);  
	                	});																	
					});
				}
				//get the data for basket from code mast
				vm.getCodeMastData = function() {
					var params = {
						"action_code" : 'dbty56',
						"p_scenario" : vm.scenario,
						"p_jcd_key" : vm.jcd_key
					};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	
			                	vm.sourceData = $filter('filter')(data.jsonObject, function(data) {
	                                return (data.CODE_GROUP === 'SOURCE_CODE' && data.CODE_DESCRIPTION != 'Passive'&& data.CODE_DESCRIPTION != 'Exempt');
	                            });
			                	if(vm.rowData)
			                	vm.selectedSourceCode = vm.rowData.BASKET_KEY;
			                }
						});
				}
				

				vm.getCodeMastData();
				
				//Get the saved data 
				vm.getSavedConfigData = function(){
					var params = {"action_code" : 'ynnh7m', "scenario" : vm.scenario };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.gotExistingData = data.jsonObject;
						console.log("vm.gotExistingData : ", vm.gotExistingData );
					});
				}
				vm.getSavedConfigData();
				
				vm.setSavedValues = function(){
					//When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
					if (gridData != null) {					
						var tempGridData = _.clone(gridData), groups = Object
								.create(null), result, finalKeyCheck = 0;					
					
						vm.gridDataMultipleSelect = _.clone(tempGridData);					
										
						if (vm.gridDataMultipleSelect != undefined) {						
							//console.log("vm.gridDataMultipleSelect:-: ", vm.gridDataMultipleSelect.rowData);
							if (vm.gridDataMultipleSelect.rowData != undefined) {	
							
								//Set the update params
								vm.operationType = 'U';
							
								//Get the saved data
								$scope.$watch(function() { return vm.gotExistingData }, function() {
									if(vm.gotExistingData != undefined || vm.gotExistingData != null){
										vm.savedData = [];
										vm.gotExistingData.find(exist => {
											if (exist.BASKET_KEY == vm.rowData.BASKET_KEY) {
												vm.savedData.push(exist);
											}
										});
										//vm.savedData = angular.copy(vm.gotExistingData);

										
										
										//Enable after Loading Data 
										vm.loadingData = false;
										vm.isEditMode = true;	
									}								
								});		
							
							}else{							
								//Set the insert params
								vm.operationType = 'I'; 
														
								//Get the config code mast 
								vm.gethtkoConfigCodeMast();
							
								vm.savedData = [];							
								$scope.$watch(function() { return vm.htkoConfigCodeMastData }, function() {
									if(vm.htkoConfigCodeMastData != undefined || vm.htkoConfigCodeMastData != null){
										for(var i in vm.htkoConfigCodeMastData){
											var tempObj = {};
											tempObj["ADJ_TYPE_KEY"] = vm.htkoConfigCodeMastData[i].CODE_KEY;
											tempObj["CODE_DESC"] = vm.htkoConfigCodeMastData[i].CODE_DISP_DESC;
											tempObj["CODE_SHORT_DESC"] = vm.htkoConfigCodeMastData[i].CODE_DISP_DESC;
											tempObj["CONFIG_PERCENTAGE"] = null;
											tempObj["ADJ_KEY"] = null;										
											vm.savedData.push(tempObj);
										}
										//Enable after Loading Data 
										vm.loadingData = false;
										vm.isEditMode = false;
									}								
								});
							}											
						}
					}
					
				}
				vm.setSavedValues();
				
				
				vm.getGridInfoForCheck = function() {
					var params = {
							"action_code" : 'ynnh7m',
							"p_scenario" : vm.scenario,
							"p_jcd_key" : vm.jcd_key
						};
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							console.log(data.jsonObject,"data.jsonObject-----check");
						})
				}
				
				
				vm.getGridInfoForCheck();
				
				//Saving the Data with percentages
				vm.save = function(){					
					
					//To Block user from saving for Return filed year
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
						vm.cancel();										
						AlertService.add("error", "You cannot perform this action as Tax return is already filed.",4000);
						return;
					}	
					
					if(vm.selectedSourceCode === undefined || vm.selectedSourceCode === null || vm.selectedSourceCode === ""){
						AlertService.add("error", "Please select Basket.",4000);
						vm.isSaveClicked = false;
						return;
					}	
					
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var dataToBeSaved = [];
					for(var indx in vm.savedData){
						console.log(" vm.savedData : ", vm.savedData[indx] );
						
						if(vm.savedData[indx].CONFIG_PERCENTAGE !== 0 &&
						   (vm.savedData[indx].CONFIG_PERCENTAGE == null || vm.savedData[indx].CONFIG_PERCENTAGE == '')){
							AlertService.add("error", "Please enter a valid Amount/Percentage.",4000);
							vm.isSaveClicked = false;
							return;
						}
						
						/*if(vm.savedData[indx].CODE_DESC.contains('Percentage') && parseInt(vm.savedData[indx].CONFIG_PERCENTAGE)> 100 ){
							AlertService.add("error", "Please enter a valid Percentage.",4000);
							vm.isSaveClicked = false;
							return;
						}*/
						
						/*if(vm.operationType == 'I' && vm.gotExistingData!= undefined && vm.gotExistingData != null && vm.gotExistingData.length >0 ){
							AlertService.add("error", "Amount/Percentage for the selected Tax Year is already configured.",4000);
							vm.isSaveClicked = false;
							return;
						}*/
						
						if((vm.operationType == 'I' ) && vm.gotExistingData!= undefined && vm.gotExistingData != null && vm.gotExistingData.length >0 ){
							var checkExist = false;
							vm.gotExistingData.find(exist => {
								if(exist.BASKET_KEY == vm.selectedSourceCode && exist.CONSOL_GROUP_KEY == vm.filing_group_key && exist.FILER_LE_KEY == vm.filer_entity_key) {
									checkExist = true;
								}
							});
							
							if (checkExist) {
								AlertService.add("error", "High Taxed Kick Out Configuration for the selected Tax Year and Filer Entity is already configured.",4000);
								vm.isSaveClicked = false;
								return;
							}
							
						}
						
														
						var returnObj = {};					
						returnObj['ADJ_KEY'] = vm.savedData[indx].ADJ_KEY;	
						returnObj['ADJ_TYPE_KEY'] = vm.savedData[indx].ADJ_TYPE_KEY;	
						returnObj['CONFIG_PERCENTAGE'] = vm.savedData[indx].CONFIG_PERCENTAGE;
						returnObj["OPERATION_TYPE"] = vm.operationType;	
						returnObj["BASKET_KEY"] = vm.selectedSourceCode;
						returnObj['FILING_GROUP_KEY'] = vm.filing_group_key;				
						returnObj['FILER_ENTITY_KEY'] = vm.filer_entity_key;
						console.log("returnObj::FNL:: ",returnObj);									
						//Add to a clob_data to be send to the DB	
						dataToBeSaved.push(returnObj);
					}
					
					var message = "High Taxed Kick Out Configuration has been successfully saved/updated";								
										
					
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['tax_year'] = vm.filterTaxYear; //GlobalService.globalParams.tax_year;
					returnClobSettingsObj['scenario'] = vm.scenario;					
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;	
					returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;				
					returnClobSettingsObj['object_id'] = vm.object_id;
					returnClobSettingsObj['filer_entity_key'] = vm.filer_entity_key;
					//returnClobSettingsObj['filing_group_key'] = vm.filing_group_key;				
					//returnClobSettingsObj['filer_entity_key'] = vm.filer_entity_key;
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					/* Getting the filer Entity dropdown details, since combination_key is required for lock check
					 while the top filter provides only le_key */
					getFilerEntityDropdown(returnClobSettingsObj, dataToBeSaved, message);										
				}

				//Get the Filer Entity dropdown
				function getFilerEntityDropdown(returnClobSettingsObj, dataToBeSaved, message) {		
					//resetFilerEntity();
					if (vm.filing_group_key != '') {
						var params = {"action_code" : '35gc5l',"tax_year" : vm.filterTaxYear,
						"scenario" : vm.scenario, "filing_group_key" : vm.filing_group_key};					
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							let filerEntityArr = data.jsonObject;
							vm.filerEntityDropdown =  Object.assign([], _.filter(filerEntityArr, {FILER_LE_KEY: vm.filer_entity_key}));	
							let selectedFECombinationKey = vm.filerEntityDropdown[0].FILER_COMBINATION_KEY;	

							returnClobSettingsObj['combination_key'] = selectedFECombinationKey;
							//send to save the Data after getting combination key details
							sendDetails(returnClobSettingsObj, dataToBeSaved, message);	
						});		
					}	
				};
				
				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, dataToBeSaved, message) {
					HTKOConfigFactory.saveData(returnClobSettingsObj, dataToBeSaved).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id: returnClobSettingsObj.object_id,
	                                gridFilter: {
	                                	object_id: returnClobSettingsObj.object_id
	                                }
	                            };
							//vm.loadingData = false;
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
								
				//reset the form
				vm.reset = function(){
					if(vm.operationType == 'I'){
						vm.selectedSourceCode = "";
						for(var i in vm.savedData){
							vm.savedData[i].CONFIG_PERCENTAGE = null;
						}
					}else{
						vm.savedData = [];
						vm.setSavedValues();
						vm.getCodeMastData();
						vm.getSavedConfigData();
					}
								
				};	
			
			}		
		
			return controllers;
			
		});