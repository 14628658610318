define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.domesticProjectTaggingCtrl',  [])
		.controller('domesticProjectTaggingCtrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',domesticProjectTaggingCtrl]);


	function domesticProjectTaggingCtrl($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {
		
		let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.userSettings = USER_SETTINGS;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;   
			
            vm.rowData = gridData.rowData;
			
            var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
            vm.globalParams = JSON.stringify(tempObj);
            vm.checkLockeOdbj =[];
            vm.deleteParams =[];
            vm.dataLoading = true;



            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = filterParams.scenario;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.filing_key = filterParams.filing_key;
                    vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.checkLockeOdbj.push({"me_code":gridData.rowData.BSLA_CODE,"consol_group_key":vm.filing_key, "scenario_key":vm.scenario,
                    						"combination_key":gridData.rowData.COMBINATION_KEY, "tax_year":vm.tax_year, "scenarioCode": vm.scenarioCode,
                    						"is_issue_key_enabled":'N'});

                 
                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);
					

                    const html = `<gtw-domestic-project-tagging-ce id="open-domestic-project-tagging-info" base-urls="{{ctrl.baseURLs}}" global-params="{{ctrl.globalParams}}" 
                    				tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" screen-key="{{ctrl.screen_key}}" sso-id="{{ctrl.logged_in_user}}"                     				
                    				check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" tagging-param-obj ="{{ctrl.rowData}}"
                    				class="gtw-web-components">
                    		</gtw-domestic-project-tagging-ce>`;
                    vm.webComponentHtml = html;

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {
                	$window.document.querySelector('gtw-domestic-project-tagging-ce')
                           .addEventListener('open-domestic-project-tagging-info', (event)=>{
                               console.log('event',event);
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {

                        $window.document.querySelector('gtw-domestic-project-tagging-ce')
                            .addEventListener('save-domestic-project-tagging-info', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-domestic-project-tagging-info'){
								var args = {combination_keys: vm.rowData.SEQUENCE_KEY, gridFilter:{ 
													object_id:vm.rowData.object_id//,action:"delete"
            									}};
            					$rootScope.$emit('gridUpdate', args);
                    			//$uibModalInstance.dismiss('cancel');
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added ...');
                        vm.dataLoading = false;
                    },3000
                );

                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
    ); 