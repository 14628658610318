/**
 * Created by 212544474 on 8/16/2016.
 */
 define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.entityChangeLogRunCtrl',[])
    .controller('entityChangeLogRunCtrl', ['$scope','$rootScope','$state','$log','GridFactory','AlertService','$timeout','$uibModal', '$parse', 'ClientAdminFactory','GENERAL_CONFIG','USER_SETTINGS','GridSaveFactory', changeLogJobRunCtrl])

    function changeLogJobRunCtrl($scope,$rootScope,$state,$log,GridFactory,AlertService,$timeout,$uibModal, $parse, ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS,GridSaveFactory) {

        var vm = this;
        vm.name = "Change Log Job Run Details";
        vm.userSettings = USER_SETTINGS;
        // vm.filter = {
        //     client_key:vm.userSettings.user.client.client_key
        // };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            console.log("SCREEN WAS THIS DESTROYED ")
            gridUpdateListener();
        });

        vm.refresh = function(){
            getData();
        };

        vm.selected = null;


        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];

            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", data.errorMessage);
                }
            });
        }

        var getUpDate = function(_params,_filterObj) {
            var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
                params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        $timeout(function() {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);


        $scope.ctrlFn = function(_callName,_data,_type) {
            if(_type=="input"){
                console.log("I am clicked saving")
                GridSaveFactory.saveSwitch(_callName, _data);
            }
        };
    }

    return controllers;

});
