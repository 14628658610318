define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F1120ScheduleM3Ctrl', [])

    	.directive("limitToMax", function() {
		return {
			link: function(scope, element, attributes) {
				element.on("keydown keyup", function(e) {
					if (Number(element.val()) > Number(attributes.max) &&
					  e.keyCode != 46 // delete
					  &&
					  e.keyCode != 8 // backspace
					) {
						e.preventDefault();
						element.val(attributes.max);
					}
					});
				}
			};
		})

        .controller('F1120ScheduleM3Controller', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','$filter',F1120ScheduleM3Controller]);
    	function F1120ScheduleM3Controller(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, $filter) {
        var vm = this;
        vm.modalTitle = "Part I Financial Information and Net Income (Loss) Reconciliation";
        vm.originalData = [];
        vm.leId = rowData.LEID;
        vm.formName = '1120PC';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.filing_key = rowData.CONSOL_GROUP_KEY;
        vm.group_type = rowData.CONSOL_GROUP_TYPE;
        vm.e_type = rowData.ENTITY_TYPE;
        
        vm.systemFlag = 'D';
		$scope.crudLoading = false;
        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME
        };
        
        var saveParamDtls = [];
        var tempDataObj = {};
        $scope.scheduleM3FormView = {rows:[]};
        vm.showFormLoader = true;
        vm.partnerData = [];

        function fetchSchIrecords() {
            var params = { "action_id": 31296, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ "combination_key": rowData.COMBINATION_KEY, "schedule_name": "M3", "part_no": "P1",
            		"filing_key": vm.filing_key, "group_type": vm.group_type};

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
                for(var i=0;i<data.irsForm.formItemList.length;i++){
                    saveParamDtls = [];
                    tempDataObj = {};
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    var rows = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
                    tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
                    tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;
                    if(rows > 2){
                        iterateArray(rows, data.irsForm.formItemList[i].dataRowList[0].dataCellList);
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    tempDataObj.saveParams = saveParamDtls;
                    $scope.scheduleM3FormView.rows.push(tempDataObj);
                }
                $scope.scheduleM3FormView.rows[0].value = rowData.LE_NAME;
                $scope.scheduleM3FormView.rows[0].value_user_input = rowData.EIN;
                vm.originalData = angular.copy($scope.scheduleM3FormView);              
                initFormView($scope.scheduleM3FormView);
                if(vm.filing_key != '1'){
                	vm.validateLines();
                }
                vm.showFormLoader = false;
            });
        };

        var getCheckBoxValue = function(rowNum, val){
        	if(vm.e_type == 'REG'){
            if(rowNum == "A" || rowNum == "B" ||rowNum == "C" ||rowNum == "D" || rowNum == 'E' || rowNum == '4b1' || rowNum == '4b2' || rowNum == '4b3' || rowNum == '4b4'){
                if(val === "true"){
                    return true;
                }else{
                    return false;
                }
            }else if(rowNum == "2ab" || rowNum == "2ae" || rowNum == "3b" || rowNum == "3c"){
               // return new Date();
                return null;
          }else{
            	return 0;
            }
        	}
        	else{
        		if(rowNum == "A" || rowNum == "B" ||rowNum == "C" ||rowNum == "D" || rowNum == 'E' || rowNum == '4b1' || rowNum == '4b2' || rowNum == '4b3' ){
                    if(val === "true"){
                        return true;
                    }else{
                        return false;
                    }
                }else if(rowNum == "2ab" || rowNum == "2ae" || rowNum == "3b" || rowNum == "3c"){
              //      return new Date();
                        return null;
                }else{
                	return 0;
                }
        	}
        	
        };

        var iterateArray = function(count, array){
        	if (vm.e_type == 'REG'){
            if(count == 3){
                tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                tempDataObj.formula = array[2].formula;
                if(array[2].value == null){
                    tempDataObj.value = getCheckBoxValue(tempDataObj.num, null);
                }else if(array[2].value === "true"){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "true");
                }else if (array[2].value === "false"){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "false");
                }else if (array[2].value === "0"){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "0");
                }else if (array[2].value === 'NA'){
                	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "NA");
                }else {
                	if(tempDataObj.num == "2ab" || tempDataObj.num == "2ae"){
		                tempDataObj.value = new Date(array[2].value);
		            }
                	
                	else{
                		 if(tempDataObj.num == "3b" || tempDataObj.num == "3c"){
		                tempDataObj.value = array[2].value;
                		}
                		 else{
		            	tempDataObj.value = array[2].value ? parseInt(array[2].value) : 0;
                		 }
		            }
                }
                if(tempDataObj.num == 'D'){
                    tempDataObj.JSON_DATA = vm.partnerData;
                }
            }else{
                if(tempDataObj.num == "B" || tempDataObj.num == "C" || tempDataObj.num == "4b5"){
                    tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                    tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                    tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                    tempDataObj.value = array[2].value;
                    tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                    tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                    tempDataObj.attrib_order_b = array[3].key.split('~')[2];
                    tempDataObj.value_user_input = array[3].value;
                    if(tempDataObj.value == null){
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }else if(tempDataObj.value === "true"){
                        tempDataObj.value = true;
                        tempDataObj.conditionalField = false;
                    }else if (tempDataObj.value === "false"){
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }else if (tempDataObj.value === "0"){
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }else {
                        tempDataObj.value = false;
                        tempDataObj.conditionalField = true;
                    }
                }else if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == "1c" || tempDataObj.num == "3a" || tempDataObj.num == "2b" ||  tempDataObj.num == "2c"){
                    tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                    tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                    tempDataObj.attrib_order_a = array[2].key.split('~')[2];

                    tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                    tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                    tempDataObj.attrib_order_b = array[3].key.split('~')[2];

                    if(array[2].value === "true"){
                        tempDataObj.value = true;
                    }else if(array[3].value === "true"){
                        tempDataObj.value = false;
                    }else{
                        tempDataObj.value = 'NA';
                    } 
                }else{
                    tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                    tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                    tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                    tempDataObj.value = array[2].value;
                    tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                    tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                    tempDataObj.attrib_order_b = array[3].key.split('~')[2];
                    tempDataObj.value_user_input = array[3].value;
                }
            }
            
        	}
        	else{
        		if(count == 3){
                    tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                    tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                    tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                    tempDataObj.formula = array[2].formula;
                    if(array[2].value == null){
                        tempDataObj.value = getCheckBoxValue(tempDataObj.num, null);
                    }else if(array[2].value === "true"){
                    	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "true");
                    }else if (array[2].value === "false"){
                    	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "false");
                    }else if (array[2].value === "0"){
                    	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "0");
                    }else if (array[2].value === 'NA'){
                    	tempDataObj.value = getCheckBoxValue(tempDataObj.num, "NA");
                    }else {
                    	if(tempDataObj.num == "2ab" || tempDataObj.num == "2ae"){
    		                tempDataObj.value = new Date(array[2].value);
    		            }
                    	
                    	else{
                    		 if(tempDataObj.num == "3b" || tempDataObj.num == "3c"){
    		                 tempDataObj.value = array[2].value;
                    		}
                    		 else{
    		            	tempDataObj.value = array[2].value ? parseInt(array[2].value) : 0;
                    		 }
    		            }
                    }
                    if(tempDataObj.num == 'D'){
                        tempDataObj.JSON_DATA = vm.partnerData;
                    }
                }else{
                    if(tempDataObj.num == "B" || tempDataObj.num == "C" || tempDataObj.num == "4b4"){
                        tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                        tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                        tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                        tempDataObj.value = array[2].value;
                        tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                        tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                        tempDataObj.attrib_order_b = array[3].key.split('~')[2];
                        tempDataObj.value_user_input = array[3].value;
                        if(tempDataObj.value == null){
                            tempDataObj.value = false;
                            tempDataObj.conditionalField = true;
                        }else if(tempDataObj.value === "true"){
                            tempDataObj.value = true;
                            tempDataObj.conditionalField = false;
                        }else if (tempDataObj.value === "false"){
                            tempDataObj.value = false;
                            tempDataObj.conditionalField = true;
                        }else if (tempDataObj.value === "0"){
                            tempDataObj.value = false;
                            tempDataObj.conditionalField = true;
                        }else {
                            tempDataObj.value = false;
                            tempDataObj.conditionalField = true;
                        }
                    }else if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == "1c" || tempDataObj.num == "3a" || tempDataObj.num == "2b" ||  tempDataObj.num == "2c"){
                        tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                        tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                        tempDataObj.attrib_order_a = array[2].key.split('~')[2];

                        tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                        tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                        tempDataObj.attrib_order_b = array[3].key.split('~')[2];

                        if(array[2].value === "true"){
                            tempDataObj.value = true;
                        }else if(array[3].value === "true"){
                            tempDataObj.value = false;
                        }else{
                            tempDataObj.value = 'NA';
                        } 
                    }else{
                        tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
                        tempDataObj.attrib_name_a = array[2].key.split('~')[0];
                        tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                        tempDataObj.value = array[2].value;
                        tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
                        tempDataObj.attrib_name_b = array[3].key.split('~')[0];
                        tempDataObj.attrib_order_b = array[3].key.split('~')[2];
                        tempDataObj.value_user_input = array[3].value;
                    }
                }
        	}
        };

        function fetchSubTableRecords(){      
            var params = {
                combination_key: rowData.COMBINATION_KEY,
                schedule: "M3"
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=z57kmr', params).then(function (data) {
                angular.forEach(data.jsonObject, function (row, key) {
                	if(row.LINE_NO == 'D1'){
                		row.DATA_ATTRIB_C = parseInt(row.DATA_ATTRIB_C);
                    	vm.partnerData.push(row);
                	}
                });
            }); 
        };

        function toggleConditionalField(arr, flag){
            angular.forEach(arr, function (value, key) { 
                $scope.scheduleM3FormView.rows[value].conditionalField = flag;
                if(flag){
                    $scope.scheduleM3FormView.rows[value].value = "";
                }
            });
        };

        fetchSchIrecords();
        fetchSubTableRecords();
    //    29--33
      //  31-35
        $scope.fieldVisibility = function (index, state, name){
        	if (vm.e_type == 'REG') {
            if(index == 3 || index == 4){
                checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
            }else if(index == 35 || index == 34 || index == 31 || index == 32 || index == 33){
                if(state && index == 31){
                    setValue([32,33,34,35], [35], true);
                }else if(state && index == 32){
                    setValue([31,33,34,35], [35], true);
                }else if (state && index == 33){
                    setValue([31,32,34,35], [35], true);
                }else if (state && index == 34){
                    setValue([31,32,33,35], [35], true);
                }else if(state && index == 35){
                	setValue([31,32,33,34], [35], false);
                }else{
                    setValue([31,32,33,34], [35], true);
                }
            }else{
                $scope.scheduleM3FormView.rows[index].conditionalField = !$scope.scheduleM3FormView.rows[index].conditionalField;
                if((name == 'yes' && !state) || (name == 'no' && state)){
                   $scope.scheduleM3FormView.rows[index].value = 'NA';
                };              
            }
        	}
        	else
        		{
        		 if(index == 3 || index == 4){
                     checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
                 }else if( index == 34 || index == 31 || index == 32 || index == 33){
                     if(state && index == 31){
                         setValue([32,33,34], [34], true);
                     }else if(state && index == 32){
                         setValue([31,33,34], [34], true);
                     }else if (state && index == 33){
                         setValue([31,32,34], [34], true);
                     }else if (state && index == 34){
                         setValue([31,32,33], [34], false);
                     }else{
                         setValue([31,32,33,34], [34], true);
                     }
                 }else{
                     $scope.scheduleM3FormView.rows[index].conditionalField = !$scope.scheduleM3FormView.rows[index].conditionalField;
                     if((name == 'yes' && !state) || (name == 'no' && state)){
                        $scope.scheduleM3FormView.rows[index].value = 'NA';
                     };              
                 }
        		}
        		
        };

        function setValue(arr, toggle_i, state){
            angular.forEach(arr, function (value, key) { 
                $scope.scheduleM3FormView.rows[value].value = false;
            });
            $scope.scheduleM3FormView.rows[toggle_i].conditionalField = state;
            $scope.scheduleM3FormView.rows[toggle_i].value_user_input = '';
        };

        function checkBoxToggle (index, name, state, arr, val, attr){
            if((name == 'yes' && !state) || (name == 'no' && state)){
                $scope.scheduleM3FormView.rows[index].value = 'NA';
                if(name == 'yes' && !state){
                    angular.forEach(arr, function (value, key) {
                        $scope.scheduleM3FormView.rows[value].conditionalField = true;
                        $scope.scheduleM3FormView.rows[value].value_user_input = '';
                    });
                }
            }else{
                toggleCondition(arr, state, val, attr);
            }
        };

        function toggleCondition (index, state, type, attr){
            angular.forEach(index, function (value, key) { 
                if(($scope.scheduleM3FormView.rows[value].conditionalField && !state) || (!$scope.scheduleM3FormView.rows[value].conditionalField && state)){
                    return;
                }else {
                    $scope.scheduleM3FormView.rows[value].conditionalField = !$scope.scheduleM3FormView.rows[value].conditionalField; 
                    $scope.scheduleM3FormView.rows[value].value_user_input = '';
                }
            });
        };

        vm.addRows = function(type, i){
            var newRow = {
                OCCURRENCE : $scope.scheduleM3FormView.rows[i].JSON_DATA.length + 1,
                DATA_ATTRIB_A : "",
                DATA_ATTRIB_B : "",
                DATA_ATTRIB_C : ""
            };
            $scope.scheduleM3FormView.rows[i].JSON_DATA.push(newRow);
        };

        
		vm.validateLines = function(line) {
			vm.disabledLines = _.filter($scope.scheduleM3FormView.rows, function(o) { 
				return o.num == '11'; 
			})
		
			for(var i=0; i < vm.disabledLines.length; i++){
				
				var txt = vm.disabledLines[i].formula;
				var result = txt.match(/\((.*)\)/);
				if(result != null){
				// vm.myList = angular.copy(result[1]);
				 var list1 = result[1].replace(/L/g, "");
				 var finalRes = list1.split(/[-+,/\\*\\]/);
				}
			
				for(var g=0; g < finalRes.length; g++){
					var apprVal12 = _.filter($scope.scheduleM3FormView.rows, function(o) { 
						return o.num == finalRes[g].trim();
					})
					
					if(apprVal12[0].value == ''){
						apprVal12[0].value = 0;
					}
					result[1] = result[1].replace("L"+apprVal12[0].num.trim(),apprVal12[0].value);
				}
				
				if(null!= eval(result[1]) && typeof eval(result[1]) !== 'undefined' && parseInt(eval(result[1])) === parseInt(eval(result[1]))){
					 vm.disabledLines[i].value = eval(result[1]);
				}
				
				
//				var output = [];
//				 output = _(apprVal).groupBy('attrib_name_a')
//					    .map((objs, key) => ({
//					        'attrib_name_a': key,
//					        'value': _.sumBy(objs, 'value') })).value();
				 
			} 

	}
       
        vm.saveSchdIForm = function (url, data){
            var jsonSettings = { 
                "tax_year": rowData.TAX_YEAR, 
                "scenario": rowData.SCENARIO, 
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchdIForm() response.data.jsonObj ::",response.data.jsonObject);
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
                    $uibModalInstance.dismiss('cancel');
                    var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                }else{
                    AlertService.add("", response.data.errorMessage );
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function(){

        	if($scope.scheduleM3FormView.rows[16].value > $scope.scheduleM3FormView.rows[17].value){
        		AlertService.add("error", "End date cannot be before Start date", 4000);  
				$scope.crudLoading = false;
				return;


          	}
        	if ($scope.crudLoading) {
				AlertService.add("info",
						"Please wait while we save this request", 4000);
				return;
			}

        	else{
    			$scope.crudLoading = true;
          		var formRowDataA = {};
	            var formRowDataB = {};
	            var tempArray = [];
                $scope.scheduleM3FormView.rows[16].value = $filter('date')($scope.scheduleM3FormView.rows[16].value,'MM/dd/yyyy');
                $scope.scheduleM3FormView.rows[17].value = $filter('date')($scope.scheduleM3FormView.rows[17].value,'MM/dd/yyyy');
	            for(var i=0; i<$scope.scheduleM3FormView.rows.length ;i++){
	                formRowDataA = {
	                    tax_year: rowData.TAX_YEAR,
	                    trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
	                    line_order: $scope.scheduleM3FormView.rows[i].line_order,
	                    attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_a,
	                    line_description: $scope.scheduleM3FormView.rows[i].description,
	                    combination_key: rowData.COMBINATION_KEY,
	                    group_obj_key: rowData.GROUP_OBJ_KEY,
	                    form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
	                    line_no: $scope.scheduleM3FormView.rows[i].num,
	                    occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
	                    line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_a,
	                    Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_a,
	                    Attribute_Value: $scope.scheduleM3FormView.rows[i].value === true ? 'true' : $scope.scheduleM3FormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleM3FormView.rows[i].value === false ? 'false' :  $scope.scheduleM3FormView.rows[i].value ? $scope.scheduleM3FormView.rows[i].value.toString() : '',
	                    trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
	                    trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
	                    is_dirty: ''
	                };
	                if(formRowDataA.line_no == 'D1' && $scope.scheduleM3FormView.rows[i-1].value == true && $scope.scheduleM3FormView.rows[i-1].JSON_DATA.length > 0){
	                    var attributes = ['A', 'B', 'C'];
	                    var tempDataArray = [];
	                    formRowDataA.JSON_DATA = [];
	                    angular.forEach($scope.scheduleM3FormView.rows[i-1].JSON_DATA, function (row, rowKey) {                     
	                        var hasData = false;
	                        tempDataArray = [];
	                        angular.forEach(attributes, function (value, colKey) {
	                            var index = formRowDataA.JSON_DATA.length / 3 == 0 ? 1 : formRowDataA.JSON_DATA.length / 3 + 1;
	                            var tempObj = {
	                                OCCURRENCE : index,
	                                ATTRIB_NAME : "DATA_ATTRIB_" + value,
	                                ATTRIB_VALUE : row["DATA_ATTRIB_" + value]
	                            }
	                            if(tempObj.ATTRIB_VALUE !== undefined && tempObj.ATTRIB_VALUE !== ""){
	                                hasData = true;
	                            }
	                            tempDataArray.push(tempObj);
	                        });
	                        if(hasData){
	                            angular.forEach(tempDataArray, function (column, colKey) {
	                                formRowDataA.JSON_DATA.push(column);
	                            });
	                        }
	                    });
	                }else{
	                    formRowDataA.JSON_DATA = [];
	                }
	                tempArray.push(formRowDataA);
	                if (vm.e_type == 'REG'){
	                if(typeof $scope.scheduleM3FormView.rows[i].attrib_name_b !== "undefined"){
	                    if($scope.scheduleM3FormView.rows[i].num == 'PSHIP' || $scope.scheduleM3FormView.rows[i].num == 'B' || $scope.scheduleM3FormView.rows[i].num == 'C' || $scope.scheduleM3FormView.rows[i].num == '4b5' || $scope.scheduleM3FormView.rows[i].num == '12a' || $scope.scheduleM3FormView.rows[i].num == '12b' || $scope.scheduleM3FormView.rows[i].num == '12c' || $scope.scheduleM3FormView.rows[i].num == '12d'){
	                        formRowDataB = {
	                            tax_year: rowData.TAX_YEAR,
	                            trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
	                            line_order: $scope.scheduleM3FormView.rows[i].line_order,
	                            attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_b,
	                            line_description: $scope.scheduleM3FormView.rows[i].description,
	                            combination_key: rowData.COMBINATION_KEY,
	                            group_obj_key: rowData.GROUP_OBJ_KEY,
	                            form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
	                            line_no: $scope.scheduleM3FormView.rows[i].num,
	                            occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
	                            line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_b,
	                            Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_b,
	                            Attribute_Value: $scope.scheduleM3FormView.rows[i].value_user_input,
	                            trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
	                            trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
	                            is_dirty: ''
	                        };
	                        tempArray.push(formRowDataB);
	                    }else{
	                        formRowDataB = {
	                            tax_year: rowData.TAX_YEAR,
	                            trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
	                            line_order: $scope.scheduleM3FormView.rows[i].line_order,
	                            attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_b,
	                            line_description: $scope.scheduleM3FormView.rows[i].description,
	                            combination_key: rowData.COMBINATION_KEY,
	                            group_obj_key: rowData.GROUP_OBJ_KEY,
	                            form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
	                            line_no: $scope.scheduleM3FormView.rows[i].num,
	                            occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
	                            line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_b,
	                            Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_b,
	                            Attribute_Value: $scope.scheduleM3FormView.rows[i].value === true ? 'false' : $scope.scheduleM3FormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleM3FormView.rows[i].value === false ? 'true' : $scope.scheduleM3FormView.rows[i].value ? $scope.scheduleM3FormView.rows[i].value.toString() : '',
	                            trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
	                            trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
	                            is_dirty: ''
	                        };
	                        tempArray.push(formRowDataB);
	                    }   
	                }
	            }
	                else
	                	{
	                	  if(typeof $scope.scheduleM3FormView.rows[i].attrib_name_b !== "undefined"){
	  	                    if($scope.scheduleM3FormView.rows[i].num == 'PSHIP' || $scope.scheduleM3FormView.rows[i].num == 'B' || $scope.scheduleM3FormView.rows[i].num == 'C' || $scope.scheduleM3FormView.rows[i].num == '4b4' || $scope.scheduleM3FormView.rows[i].num == '12a' || $scope.scheduleM3FormView.rows[i].num == '12b' || $scope.scheduleM3FormView.rows[i].num == '12c' || $scope.scheduleM3FormView.rows[i].num == '12d'){
	  	                        formRowDataB = {
	  	                            tax_year: rowData.TAX_YEAR,
	  	                            trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
	  	                            line_order: $scope.scheduleM3FormView.rows[i].line_order,
	  	                            attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_b,
	  	                            line_description: $scope.scheduleM3FormView.rows[i].description,
	  	                            combination_key: rowData.COMBINATION_KEY,
	  	                            group_obj_key: rowData.GROUP_OBJ_KEY,
	  	                            form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
	  	                            line_no: $scope.scheduleM3FormView.rows[i].num,
	  	                            occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
	  	                            line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_b,
	  	                            Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_b,
	  	                            Attribute_Value: $scope.scheduleM3FormView.rows[i].value_user_input,
	  	                            trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
	  	                            trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
	  	                            is_dirty: ''
	  	                        };
	  	                        tempArray.push(formRowDataB);
	  	                    }else{
	  	                        formRowDataB = {
	  	                            tax_year: rowData.TAX_YEAR,
	  	                            trans_type_key: $scope.scheduleM3FormView.rows[i].saveParams[1],
	  	                            line_order: $scope.scheduleM3FormView.rows[i].line_order,
	  	                            attrib_order: $scope.scheduleM3FormView.rows[i].attrib_order_b,
	  	                            line_description: $scope.scheduleM3FormView.rows[i].description,
	  	                            combination_key: rowData.COMBINATION_KEY,
	  	                            group_obj_key: rowData.GROUP_OBJ_KEY,
	  	                            form_key: $scope.scheduleM3FormView.rows[i].saveParams[2],
	  	                            line_no: $scope.scheduleM3FormView.rows[i].num,
	  	                            occurence: $scope.scheduleM3FormView.rows[i].saveParams[7],
	  	                            line_attrib_key: $scope.scheduleM3FormView.rows[i].lineAttrKey_b,
	  	                            Attribute_Name: $scope.scheduleM3FormView.rows[i].attrib_name_b,
	  	                            Attribute_Value: $scope.scheduleM3FormView.rows[i].value === true ? 'false' : $scope.scheduleM3FormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleM3FormView.rows[i].value === false ? 'true' : $scope.scheduleM3FormView.rows[i].value ? $scope.scheduleM3FormView.rows[i].value.toString() : '',
	  	                            trans_dtls_key: $scope.scheduleM3FormView.rows[i].saveParams[3],
	  	                            trans_status: $scope.scheduleM3FormView.rows[i].saveParams[6],
	  	                            is_dirty: ''
	  	                        };
	  	                        tempArray.push(formRowDataB);
	  	                    }   
	  	                }
	                	}
	            }
	            vm.formData.editrows.push(tempArray);       
	            vm.mainData = [];
	            vm.mainData.push(vm.formData);
	            console.log(vm.mainData);
	            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=801vwi";
	            vm.saveSchdIForm(url,vm.mainData);
	            $rootScope.$broadcast("gridRefresh", true);
	            $timeout(function() {
	                $scope.$broadcast("showLoader", true);
					
	            },100);
          	}
        };
        
        vm.reset = function (){
            $scope.scheduleM3FormView = angular.copy(vm.originalData);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function initFormView(filteredFormData) {
            $scope.scheduleM3FormView = filteredFormData;
          //  vm.computeValues2019();
        };

		//Dynamic Tabset Code
		vm.tabs = [
	        {id: 1, name: "Form Entry", url: "app/components/F1120-Sch-M3/F1120-scheduleM3Entry.html", active: true }
	        //{id: 3, name: "Audit", url: "app/components/partnership/F1065-Sch-M3/F1065-scheduleM3Version.html", active: false}
	    ];
    }
});