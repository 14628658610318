define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.deleteLocalChartController',
					[]).controller(
					'DeleteLocalChartController',
					[ '$rootScope', '$scope', '$filter', '$http', '$timeout',
							'GlobalService', '$uibModalInstance',
							'ModalFactory', 'AlertService', 'CodeComboFactory',
							'rowData', 'colData', 'JsonObjectFactory',
							'LocalCDescGroupObjFactory', 'GENERAL_CONFIG',
							deleteLocalChartController ]);

			function deleteLocalChartController($rootScope, $scope, $filter,
					$http, $timeout, GlobalService, $uibModalInstance,
					ModalFactory, AlertService, CodeComboFactory, rowData,
					colData, JsonObjectFactory, LocalCDescGroupObjFactory,
					GENERAL_CONFIG) {
				var vm = this;
				$scope.rowData = rowData;
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				var vm = this || thisObj;

				vm.confirm_delete = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}

					$scope.crudLoading = true;
					var dummyJson = {};
					var sendobj = [];

					dummyJson["object_id"] = rowData.object_id;
					dummyJson['local_chart_key'] = rowData.CHART_KEY;
					dummyJson['tax_year'] = GlobalService.globalParams.tax_year;
					dummyJson['scenario'] = GlobalService.globalParams.scenario;
					dummyJson['jcd_key'] = GlobalService.globalParams.jcd_key;
					sendobj.push(dummyJson);
					var message = "Chart has been Deleted";
					deletechartCRUD(dummyJson, sendobj, message);

				};

				function deletechartCRUD(returnObj, accountArray, message) {
					LocalCDescGroupObjFactory.saveDeleteChart(returnObj,
							accountArray).then(
							function(result) {
								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoading = false;
									$uibModalInstance.dismiss('cancel');

								} else {
									AlertService.add("success", message, 4000);
									// BROADCAST CHANGE
									$uibModalInstance.dismiss('cancel');
									$rootScope.$emit('gridUpdate', {});
								}
							});
				}
			}

			return controllers;

		});