define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.Form8865-textualInfoService',[])
       .factory("scheduleOPGGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http,
		           $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var scheduleOPGGroupObjFactory ={};
	           scheduleOPGGroupObjFactory.url ={};
	           scheduleOPGGroupObjFactory.url.save_schedule_OPG = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ysbq1g";
	           
	           scheduleOPGGroupObjFactory.saveScheduleOPG = function(_data,Details){
	
		           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		           var jsonSettings =  JSON.stringify(_data);
		           var jsonObj = JSON.stringify(Details);
		               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
		           var params = filterParams;
		           params = _.merge({},  GlobalService.globalParams , params);
		           params =  _.extend({jsonObj:jsonObj}, params);
		           params =  _.extend({jsonSettings:jsonSettings}, params);
		//               params =  _.extend({jsonSettings:jsonSettings}, _data);
		           params.process_name =  "Schedule_O_P_G_Save";
		           var promise = $http({
		        	   method: "post",
		               url: scheduleOPGGroupObjFactory.url.save_schedule_OPG,
		               data: params
		           }).then(function (response) {
		               var status = response.status;
		               return response.data;
		           });
		
		           return promise;
           };
      
return scheduleOPGGroupObjFactory;
       }]);

   return services;
});