define([
    'angular',
    './clientLandingPageCtrl',
    './clientLandingService'

], function () {
    'use strict';
    return angular.module('app.clientLandingPage', ['app.clientLandingPageCtrl','app.clientLandingService'] )

    .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
        $stateProvider
            .state('client-landing-page', {
                url: '/client-landing-page',
                templateUrl: ' app/components/clientLandingPage/client-landing-page.html',
                controller: 'clientLandingPageCtrl as ctrl',
                backdrop: 'static',
                type:'custom',
            });
    }]);
});