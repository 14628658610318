define([
    'angular',
    './irs4562Controllers',
    './irs4562Service'

], function () {
    'use strict';	
    return angular.module('app.irs4562', ['app.Irs4562Controller','app.Irs4562Service'] )


});