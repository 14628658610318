define([
    'angular',
    './GoldSyncOldCtrl',
    './GoldSyncOldServices'



], function () {
    'use strict';
    return angular.module('app.goldSyncOld', ['app.goldSyncOldServices','app.goldSyncOldCtrl','ui.toggle','ngAnimate'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('admin.bu-le.bussiness-hierarchy', {
                url: '/bussiness-hierarchy',
                templateUrl: 'app/components/admin/goldsync-old/bu-le-bussiness-hierarchy.html',
                controller: 'goldSyncOldCtrl as ctrl'
            })
            .state('admin.bu-le.leid-assign', {
                url: '/leid-assign',
                templateUrl: 'app/components/admin/goldsync-old/bu-le-Leid-assign.html',
                controller: ''
            })
            .state('admin.bu-le.gold-sync-old', {
                url: '/gold-sync-old',
                templateUrl: 'app/components/admin/goldsync-old/bu-le-goldSyncOld.html',
                controller: ''
            })
    }])
    
    //goldsync-old
    
});