define([
    'angular',
    './TbbsController',
    './TbbsService'



], function () {
    'use strict';
    return angular.module('app.tbbs', ['app.tbbsController','app.tbbsService'] );


});