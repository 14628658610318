/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.tbbsreclassController', [])
					.controller(
							'TbbsReclassController',
							[ '$rootScope', '$scope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'JsonObjectFactory', 'TbReclassFactoryA',
									'$timeout', 'TbbsService', 'USER_SETTINGS',
									'GENERAL_CONFIG', tbbsreclassController ])

			function tbbsreclassController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					TbReclassFactoryA, $timeout, tbbsService, USER_SETTINGS, GENERAL_CONFIG) {

				var vm = this || thisObj;
				$scope.rowData = rowData || $scope.$parent.rowData;
				$scope.accounts = [];
				$scope.selectAccountData = [];
				$scope.sourceAcctType = null;
				$scope.reclassTotal = "";

				$scope.RECLASS_DESCRIPTION = "";
				$scope.TRANS_HEADER_KEY = "";
				$scope.reclassArray = [];
				$scope.showReclassForm = false;
				$scope.crudLoading = false;
				$scope.existingLoading = false;
				$scope.systemaLoading = false;
				$scope.templateLoading = false;
				$scope.noRecords = false;
				$scope.fromScreen = false;
				// $scope.colData = colData;
				$scope.RECLASS_TYPE = "";
				$scope.scenario = GlobalService.globalParams.scenario;
				$scope.taxYear = GlobalService.globalParams.tax_year;
				$scope.localChartKey = "";
				// $scope.mainScreenKey = ReclassFactory.getActiveScreen();
				$scope.showReclassType = true;
				$scope.showNovYrReclassType = true;
				$scope.delTransDetails = [];
				$scope.toDeleteTransaction = [];

				vm.schdmtype = [ 'a', 'b', 'c' ];
				vm.templateDataMain = [];
				vm.systemAcctMain = [];
				vm.SYSTEM_ACCT = $scope.rowData.ACCT;
				vm.SYSTEM_ACCT_DESC = $scope.rowData.ACCT_DESC;
				vm.pyadj = $scope.rowData.PY_ADJUSTED_TBBS_AMT_1;
				vm.cygaapamt = $scope.rowData.CY_ADJUSTED_GAAP_TB_AMT;
				vm.bookadj = $scope.rowData.BOOK_ADJ_AMT;
				vm.cyadjgaap = $scope.rowData.CY_ADJUSTED_GAAP_TB_AMT;
				vm.pycumulative = $scope.rowData.PY_CUMULATIVE_DEFERRED_ADJ_AMT_1;
				vm.cydeferred = $scope.rowData.CY_DEFERRED_ADJ_AMT;
				vm.otheradj = $scope.rowData.OTHER_ADJ_AMT;
				vm.cyadjtbbs = $scope.rowData.CY_ADJUSTED_TBBS_AMT;
				if ($uibModalInstance) {
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
							: null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				$scope.addTargetAccount = function() {
					vm.displaysysAcct = vm.SYSTEM_ACCT + '-'
							+ vm.SYSTEM_ACCT_DESC;
					if ($scope.accounts.length == 0) {
						var targetAcct = {
							"template" : "",
							"systemacct" : vm.displaysysAcct,
							"amount" : "",
							"type" : "I",
							"trans_header_key" : 0,
							"trans_details_key" : 0
						};

						$scope.accounts.push(targetAcct);
					} else {
						var targetAcct = {
							"template" : "",
							"systemacct" : "",
							"amount" : "",
							"type" : "I",
							"trans_header_key" : 0,
							"trans_details_key" : 0
						};

						$scope.accounts.push(targetAcct);
					}
				};

				// 31jsr3
				// rjubnm

				function getSystmAcctDropDown() {
					$scope.systemaLoading = true;
					var params = {
						"action_code" : '31jsr3',
						"tax_year" : GlobalService.globalParams.tax_year,
						"chart_type" : $scope.rowData.CHART_TYPE,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {
											vm.sys_acc_dropdown_data = [];
											vm.systemAcctMain = data.jsonObject;
											for ( var a in data.jsonObject) {
												vm.sys_acc_dropdown_data
														.push(data.jsonObject[a].SYSTEM_ACCT_DESC);
											}

											vm.sys_acc_dropdown_data = _.uniq(
													vm.sys_acc_dropdown_data,
													JSON.stringify);
											$scope.systemaLoading = false;
										}
									})
				}

				function gettemplateData() {
					$scope.templateLoading = true;
					var params = {
						"action_code" : 'rjubnm',
						"tax_year" : GlobalService.globalParams.tax_year,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.templatedata = [];
											vm.templateDataMain = data.jsonObject;
											for ( var a in data.jsonObject) {

												vm.templatedata
														.push(data.jsonObject[a].SCHDM_TEMPLATE);
											}

											vm.templatedata = _.uniq(
													vm.templatedata,
													JSON.stringify);
											$scope.templateLoading = false;
										}
									})
				}

				function init() {
					$scope.accounts = [];
					$scope.delTransDetails = [];
					$scope.crudLoading = false;
					$scope.RECLASS_DESCRIPTION = "";
					$scope.reclassArray = [];
					$scope.TRANS_HEADER_KEY = "";
					$scope.existingLoading = false;
					$scope.systemaLoading = false;
					$scope.templateLoading = false;
					$scope.noRecords = false;
					$scope.RECLASS_TYPE = "";
					$scope.showReclassType = ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? false
							: true);
					$scope.showNovYrReclassType = ($scope.rowData.NOV_YEAR_END === 'N' ? false
							: true);
					vm.templateError = false;
					vm.systemacctError = false;
					vm.amtError = false;
					vm.desError = false;
					vm.type = false;
					vm.adj_desc = '';
					getSystmAcctDropDown();
					gettemplateData();
					loadExistingReclass();
					if ($scope.accounts.length == 0) {
						$scope.addTargetAccount();
					}

					if ($scope.reclassFrom !== undefined) {
						$scope.reclassFrom.$setPristine();
						$scope.reclassFrom.$setUntouched();
					}

				}

				init();

				$scope.removeAccount = function(_index) {
					$scope.accounts[_index].type = 'D';
					$scope.toDeleteTransaction.push($scope.accounts[_index]);
					var getTransType = $scope.accounts[_index]["TYPE"];
					if (getTransType === 'edit') {

						$scope.accounts[_index]["IS_DELETE"] = 'YES';
						$scope.delTransDetails.push($scope.accounts[_index]);
					}
					$scope.accounts.splice(_index, 1);
					setReclassTotal();
				};

				$scope.showDetails = function(_index, _boolean) {
					$scope.reclassArray[_index].open = _boolean;
				};

				var setReclassTotal = function() {
					var total = 0;
					angular.forEach($scope.accounts, function(value, key) {
						if (value.amount && !isNaN(value.amount)) {
							total += parseFloat(value.amount);
						}
					});
					$scope.reclassTotal = total;
				};

				$scope.deleteReclass = function(reclass) {
					var returnObj = {};
					$scope.existingLoading = true;
					// returnObj.reclass_description =
					// $scope.RECLASS_DESCRIPTION;
					returnObj.trans_header_key = reclass.TRANS_HEADER_KEY;
					returnObj.type = "D";
					returnObj.trans_details_key = reclass.TRANS_DETAILS_KEY;
					// returnObj.chart_key = $scope.rowData.CHART_KEY;
					// returnObj.screen_key = 23;
					var message = "Reclass has been successfully deleted";
					reclassCRUDHttp(returnObj, returnObj, message);
				};

				$scope.reset = function() {
					init();
					setReclassTotal();

				};
				vm.templateChange = function(index) {
					if (index === undefined) {
						for ( var a in $scope.accounts) {
							$scope.accounts[a].templateError = "";
							if ($scope.accounts[a].template === ""
									|| $scope.accounts[a].template === undefined) {
								$scope.accounts[a].templateError = true;
								vm.templateError = true;
							} else {
								vm.templateError = false;
							}
						}
					}

					else {
						$scope.accounts[index].templateError = "";
						if ($scope.accounts[index].template === ""
								|| $scope.accounts[index].template === undefined) {
							$scope.accounts[index].templateError = true;
							vm.templateError = true;
						} else {
							vm.templateError = false;
						}
					}
				}
				vm.adjChange = function() {

					if (vm.adj_desc === "" || vm.adj_desc === undefined) {
						vm.desError = true;
					} else {
						vm.desError = false;
					}

				}
				vm.acctChange = function(index) {
					if (index === undefined) {
						for ( var a in $scope.accounts) {
							$scope.accounts[a].systemacctError = "";
							if ($scope.accounts[a].systemacct === ""
									|| $scope.accounts[a].systemacct === undefined) {
								$scope.accounts[a].systemacctError = true;
								vm.systemacctError = true;
							} else {
								vm.systemacctError = false;
							}
						}
					} else {
						$scope.accounts[index].systemacctError = "";
						if ($scope.accounts[index].systemacct === ""
								|| $scope.accounts[index].systemacct === undefined) {
							$scope.accounts[index].systemacctError = true;
							vm.systemacctError = true;
						} else {
							vm.systemacctError = false;
						}
					}

				}

				vm.amtChange = function(index) {
					if (index === undefined) {
						for ( var a in $scope.accounts) {
							$scope.accounts[a].amtError = "";
							if ($scope.accounts[a].amount === ""
									|| $scope.accounts[a].amount === undefined
									|| $scope.accounts[a].amount === 0) {
								$scope.accounts[a].amtError = true;
								vm.amtError = true;
							} else {
								vm.amtError = false;
							}
						}

					} else {
						$scope.accounts[index].amtError = "";
						if ($scope.accounts[index].amount === ""
								|| $scope.accounts[index].amount === undefined
								|| $scope.accounts[index].amount === 0) {
							$scope.accounts[index].amtError = true;
							vm.amtError = true;
						} else {
							vm.amtError = false;
						}
					}
					setReclassTotal();
				}

				$scope.loadReclass = function(_index) {

					$scope.accounts = [];
					var editAcct = {};
					var templateid = "";
					var systemacct = "";
					for ( var a in _index.arrData) {
						templateid = "";
						systemacct = "";
						for ( var g in vm.templateDataMain) {
							if (vm.templateDataMain[g].SCHDM_TEMPLATE == _index.arrData[a].SCHDM_TEMPLATE) {
								templateid = vm.templateDataMain[g].SCHDM_TEMPLATE_ID;
							}
						}
						for ( var h in vm.systemAcctMain) {
							if (vm.systemAcctMain[h].SYSTEM_ACCT_DESC == _index.arrData[a].SYSTEM_ACCT_DESC) {
								systemacct = vm.systemAcctMain[h].SYSTEM_ACCT_KEY;
							}
						}
						editAcct = {};
						editAcct = {
							"template" : _index.arrData[a].SCHDM_TEMPLATE,
							"templateid" : templateid,
							"systemacct" : _index.arrData[a].SYSTEM_ACCT_DESC,
							"systemacctid" : systemacct,
							"amount" : _index.arrData[a].AMOUNT,
							"type" : 'E',
							"trans_header_key" : _index.arrData[a].TRANS_HEADER_KEY,
							"trans_details_key" : _index.arrData[a].TRANS_DETAILS_KEY
						};

						$scope.accounts.push(editAcct);
					}

					// $scope.accounts.push(editAcct);
					/*
					 * vm.old_template =
					 * $scope.reclassArray[_index].SCHDM_TEMPLATE;
					 * vm.old_systemacct =
					 * $scope.reclassArray[_index].SYSTEM_ACCT_DESC;
					 * vm.old_amount = $scope.reclassArray[_index].AMOUNT;
					 */
					// vm.templateDataMain = [];
					// vm.systemAcctMain = [];
					/*
					 * for(var c in vm.templateDataMain) {
					 * if(vm.templateDataMain[b].SCHDM_TEMPLATE ==
					 * _index.template) { old_template =
					 * vm.templateDataMain[b].SCHDM_TEMPLATE_ID; } }
					 * 
					 * for(var c in vm.systemAcctMain) { if(_index.systemacct ==
					 * vm.systemAcctMain[d].SYSTEM_ACCT_DESC) { old_systemAcct =
					 * vm.systemAcctMain[d].SYSTEM_ACCT_KEY; } }
					 */

					vm.old_adj_desc = _index.arrData[0].ADJ_DESCRIPTIO;
					vm.adj_desc = _index.arrData[0].ADJ_DESCRIPTION;
					vm.type = true;
					setReclassTotal();
				};

				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoading = true;
					var dummyJson = {};
					var sendobj = [];
					vm.templateChange();
					vm.adjChange();
					vm.acctChange();
					vm.amtChange();
					if (vm.templateError || vm.desError || vm.systemacctError
							|| vm.amtError) {
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						$scope.crudLoading = false;
						throw new ("This is not an error. This is just to abort javascript!");

					}

					else {
						for (var s = 0; s < $scope.toDeleteTransaction.length; s++) {
							dummyJson = {};
							dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
							dummyJson["scenario"] = GlobalService.globalParams.scenario;
							dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
							dummyJson["LEID"] = $scope.rowData.LEID;
							dummyJson["CDR"] = $scope.rowData.CDR_NO;
							dummyJson["Reporting_period"] = $scope.rowData.REPORTING_PERIOD;
							dummyJson["me_code"] = $scope.rowData.ME_CODE;
							dummyJson["adj_desc_old"] = ($scope.toDeleteTransaction[s].type === 'I') ? vm.adj_desc
									: vm.old_adj_desc;
							dummyJson["adj_desc_new"] = vm.adj_desc;

							for ( var a in vm.systemAcctMain) {
								if ($scope.toDeleteTransaction[s].systemacct == vm.systemAcctMain[a].SYSTEM_ACCT_DESC) {
									dummyJson["system_acct_new"] = vm.systemAcctMain[a].SYSTEM_ACCT_KEY;
								}
							}

							for ( var b in vm.templateDataMain) {
								if (vm.templateDataMain[b].SCHDM_TEMPLATE == $scope.toDeleteTransaction[s].template) {
									dummyJson["template_new"] = vm.templateDataMain[b].SCHDM_TEMPLATE_ID;
								}
							}

							dummyJson["system_acct_old"] = ($scope.toDeleteTransaction[s].type === 'I') ? dummyJson["system_acct_new"]
									: $scope.toDeleteTransaction[s].systemacctid;
							dummyJson["template_old"] = ($scope.toDeleteTransaction[s].type === 'I') ? dummyJson["template_new"]
									: $scope.accounts[s].templateid;
							dummyJson["amount_new"] = $scope.toDeleteTransaction[s].amount;
							dummyJson["amount_old"] = ($scope.toDeleteTransaction[s].type === 'I') ? $scope.toDeleteTransaction[s].amount
									: $scope.toDeleteTransaction[s].amount;

							dummyJson["type"] = "D"
							dummyJson["trans_header_key"] = $scope.toDeleteTransaction[s].trans_header_key;
							dummyJson["trans_details_key"] = $scope.toDeleteTransaction[s].trans_details_key;
							sendobj.push(dummyJson);
						}
						for (var s = 0; s < $scope.accounts.length; s++) {

							dummyJson = {};
							dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
							dummyJson["scenario"] = GlobalService.globalParams.scenario;
							dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
							dummyJson["LEID"] = $scope.rowData.LEID;
							dummyJson["CDR"] = $scope.rowData.CDR_NO;
							dummyJson["Reporting_period"] = $scope.rowData.REPORTING_PERIOD;
							dummyJson["me_code"] = $scope.rowData.ME_CODE;
							dummyJson["adj_desc_old"] = ($scope.accounts[s].type === 'I') ? vm.adj_desc
									: vm.old_adj_desc;
							dummyJson["adj_desc_new"] = vm.adj_desc;

							// vm.templateDataMain = [];
							// vm.systemAcctMain = [];

							for ( var a in vm.systemAcctMain) {
								if ($scope.accounts[s].systemacct == vm.systemAcctMain[a].SYSTEM_ACCT_DESC) {
									dummyJson["system_acct_new"] = vm.systemAcctMain[a].SYSTEM_ACCT_KEY;
								}
							}

							for ( var b in vm.templateDataMain) {
								if (vm.templateDataMain[b].SCHDM_TEMPLATE == $scope.accounts[s].template) {
									dummyJson["template_new"] = vm.templateDataMain[b].SCHDM_TEMPLATE_ID;
								}
							}
							// dummyJson["system_acct_new"] =
							// $scope.accounts[s].systemacct;

							dummyJson["system_acct_old"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
									: $scope.accounts[s].systemacctid;
							// dummyJson["template_new"] =
							// $scope.accounts[s].template;
							dummyJson["template_old"] = ($scope.accounts[s].type === 'I') ? dummyJson["template_new"]
									: $scope.accounts[s].templateid;
							dummyJson["amount_new"] = $scope.accounts[s].amount;
							dummyJson["amount_old"] = ($scope.accounts[s].type === 'I') ? $scope.accounts[s].amount
									: $scope.accounts[s].amount;

							dummyJson["type"] = ($scope.accounts[s].type === 'I') ? "I"
									: "E";
							dummyJson["trans_header_key"] = $scope.accounts[s].trans_header_key;
							dummyJson["trans_details_key"] = $scope.accounts[s].trans_details_key;
							sendobj.push(dummyJson);

							// usumyc
						}
						var message = "TBBS Reclass has been successfully Added";
						reclassCRUDHttp(sendobj, sendobj, message);
					}

				};

				function reclassCRUDHttp(returnObj, accountArray, message) {

					if (returnObj.type === 'D') {
						TbReclassFactoryA.deleteReclass(returnObj).then(
								function(result) {

									if (result.errorMessage
											&& result.errorMessage !== 'null') {
										AlertService.add("error",
												result.errorMessage, 4000);
									} else {
										AlertService.add("success", message,
												4000);
										// BROADCAST CHANGE
										// $uibModalInstance.close();
										// var args = {
										// combination_keys :
										// returnObj.combination_key,
										// gridFilter : {
										// COMBINATION_KEY :
										// returnObj.combination_key
										// }
										// };
										// $rootScope.$emit('gridUpdate',
										// args);

										// var args = {system_chart:
										// returnObj.combination_key,
										// gridFilter: {COMBINATION_KEY:
										// returnObj.combination_key}};
										// $rootScope.$emit('gridUpdate',
										// args);

										init();
									}
								});
					} else {
						TbReclassFactoryA
								.saveTBBSReclass(returnObj, accountArray)
								.then(
										function(result) {

											if (result.errorMessage
													&& result.errorMessage !== 'null') {
												AlertService.add("error",
														result.errorMessage,
														4000);
												$scope.crudLoading = false;
											} else {
												AlertService.add("success",
														message, 4000);
												// BROADCAST CHANGE
												$uibModalInstance
														.dismiss('cancel');
												var args = {

													combi_key : $scope.rowData.COMBINATION_KEY,
													gridFilter : {

														combi_key : $scope.rowData.COMBINATION_KEY
													}
												};
												$rootScope.$emit('gridUpdate',
														args);

											}
										});
					}
				}

				function accountModel(accountCode, acctKey, tp_key, amount,
						trans_dtls_key, is_delete) {
					this.ACCOUNT_CODE = accountCode;
					this.ACCT_KEY = acctKey;
					this.TP_KEY = tp_key;
					this.AMOUNT = amount;
					this.TRANS_DETAILS_KEY = trans_dtls_key;
					this.IS_DELETE = is_delete;
				}

				accountModel.prototype.addAccount = function() {

				};

				/*
				 * $scope.cancel = function () {
				 * $uibModalInstance.dismiss('cancel'); };
				 */

				function loadExistingReclass() {
					$scope.existingLoading = true;
					vm.transHeaderkeys = [];
					var tempobj = {};
					vm.tempArray = [];
					$scope.tbbsArray = [];
					var params = {
						"action_code" : 'iu9fsa',
						"sso_id": USER_SETTINGS.user.sso_id,
						"combination_key" : $scope.rowData.COMBINATION_KEY,
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" :  GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					};

					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										$scope.reclassArray = data.jsonObject;
										for (var a = 0; a < $scope.reclassArray.length; a++) {

											vm.transHeaderkeys
													.push($scope.reclassArray[a].TRANS_HEADER_KEY);

										}
										vm.transHeaderkeys = _.uniq(
												vm.transHeaderkeys,
												JSON.stringify);

										for ( var a in vm.transHeaderkeys) {
											$scope.tbbsArray
													.push({
														trans_key : vm.transHeaderkeys[a],
														arrData : [ {
															ADJ_DESCRIPTION : '',
															AMOUNT : '',
															SYSTEM_ACCT_DESC : '',
															TRANS_DETAILS_KEY : '',
															TRANS_HEADER_KEY : '',
															UPDATED_BY : '',
															UPDATED_ON : ''
														} ]
													});
										}

										for (var a = 0; a < $scope.tbbsArray.length; a++) {
											vm.tempArray = [];
											for (var b = 0; b < $scope.reclassArray.length; b++) {
												tempobj = {};

												if ($scope.tbbsArray[a].trans_key === $scope.reclassArray[b].TRANS_HEADER_KEY) {
													tempobj["ADJ_DESCRIPTION"] = $scope.reclassArray[b].ADJ_DESCRIPTION;
													tempobj["AMOUNT"] = $scope.reclassArray[b].AMOUNT;
													tempobj["SCHDM_TEMPLATE"] = $scope.reclassArray[b].SCHDM_TEMPLATE;
													tempobj["SYSTEM_ACCT_DESC"] = $scope.reclassArray[b].SYSTEM_ACCT_DESC;
													tempobj["TRANS_DETAILS_KEY"] = $scope.reclassArray[b].TRANS_DETAILS_KEY;
													tempobj["TRANS_HEADER_KEY"] = $scope.reclassArray[b].TRANS_HEADER_KEY;
													tempobj["UPDATED_ON"] = $scope.reclassArray[b].UPDATED_ON;
													tempobj["UPDATED_BY"] = $scope.reclassArray[b].UPDATED_BY;
													vm.tempArray.push(tempobj);

												}
												if (b == $scope.reclassArray.length - 1) {
													$scope.tbbsArray[a].arrData = vm.tempArray;
												}
											}
										}
										// formatReclassData($scope.reclassArray);

										$scope.existingLoading = false;
										if ($scope.reclassArray.length === 0) {
											$scope.noRecords = true;
										}
									});
				}

				/*
				 * function formatReclassData(_data) { var reclasses = []; var
				 * accounts = []; _.forEach(_data, function (value, key) {
				 * 
				 * if (value.ROW_INDICATOR === 1) {
				 * 
				 * var ReclassObj = {}; ReclassObj = value; ReclassObj.ACCOUNTS =
				 * []; reclasses.push(ReclassObj); // JUST USE VALUE
				 * console.log("row indicator 1"); console.log(ReclassObj); }
				 * else { var AccountObj = {}; AccountObj = value; if
				 * (AccountObj.ACCOUNT === $scope.rowData.LOCAL_ACCT) {
				 * AccountObj.TYPE = "source"; } console.log("row indicator : " +
				 * value.ROW_INDICATOR); console.log(AccountObj);
				 * AccountObj.TYPE = "edit"; accounts.push(AccountObj); } });
				 * 
				 * 
				 * _.forEach(reclasses, function (reclassVal, reclassKey) {
				 * reclasses[reclassKey].ACCOUNTS = []; var
				 * row_one_trans_header_key = reclassVal.TRANS_HEADER_KEY;
				 * 
				 * _.forEach(accounts, function (value, key) {
				 * 
				 * var account_trans_header_key = value.TRANS_HEADER_KEY;
				 * 
				 * if (value.ROW_INDICATOR !== 1 && row_one_trans_header_key ===
				 * account_trans_header_key) {
				 * reclasses[reclassKey].ACCOUNTS.push(value); }
				 * 
				 * }); });
				 * 
				 * accounts = null; return reclasses; }
				 */

			}// /////////////END CTRL

			return controllers;

		});