
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.QbaiServices',[])

        .factory("qbaiFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService,workspaceFactory, USER_SETTINGS,$rootScope) {
            var qbaiFactory = {};
            qbaiFactory.url ={};
            qbaiFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=pfnux6";

            
            
            qbaiFactory.save = function(_data,Details,otherInfo){

               // var filterParams =  {};
            	var filterParams =  (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               // params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({sso_id:otherInfo.sso_id,tax_year:otherInfo.tax_year,
                	scenario:otherInfo.scenario,jcd_key:otherInfo.jcd_key,
                	screen_key:otherInfo.screen_key}, params);
                console.log(params);
                params.process_name =  "QBAI_SAVE";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: qbaiFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            
            
            
            
            
            return qbaiFactory;
        }])


    return services;


});