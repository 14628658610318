define([
    'angular',
    './ReportableController',
    './ReportableService'

], function () {
    'use strict';
    return angular.module('app.reportableTransaction', ['app.reportableController','app.reportableService'] );


});