define([
    'angular',
    './entityChangeLogRunCtrl.js',
    './entityChangeLogJobRunService'


], function () {
    'use strict';
    return angular.module('app.changeLogJobRunDetails', ['app.entityChangeLogRunCtrl', 'app.entityChangeLogJobRunService'])

        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider
                .state('admin.change-log-job-run-details', {
                    url: '/change-log-job-run-details',
                    templateUrl: 'app/components/admin/changeLogJobRunDetails/change-log-job-list-tpl.html',
                    type:'custom',
                    "api": {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=32966&grid_id=6025","grid_id":"6025"},
                    access: "4"
                });
        }]);
});