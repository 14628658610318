define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.adjustPriorYearDataCtrl', [])
        .controller('adjustPriorYearDataController', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
        'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', adjustPriorYearDataController]);

    function adjustPriorYearDataController(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG) {
    var vm= this;
    vm.adjustData= [
        {
            "num": "010100001",
            "des": "Cash In Banks",
            "prioi_year_trail": "48,998",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "-2,263,277",
            "adjusted_bal": "-2,263,277"
        },
        {
            "num": "010100001",
            "des": "Cash And Cash Equivalents",
            "prioi_year_trail": "90,999",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "010200001",
            "des": "Cash In Transit And Accured",
            "prioi_year_trail": "-45,999",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "10,013,191",
            "adjusted_bal": "10,013,191"
        },
        {
            "num": "010300001",
            "des": "Local Cash Funds",
            "prioi_year_trail": "89,090",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "48,998",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "010400001",
            "des": "Cash Currency Control",
            "prioi_year_trail": "48,998",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "-44,753,592",
            "adjusted_bal": "-44,753,592"
        },
        {
            "num": "015315001",
            "des": "GECC CommercialPaper-HLdByGECC",
            "prioi_year_trail": "-99,899,676",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "018100001",
            "des": "CDs & Cash Equiv-Net",
            "prioi_year_trail": "33,989",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "-1,202",
            "adjusted_bal": "-1,202"
        },
        {
            "num": "018100002",
            "des": "GE Intrest Plus Business Activity Elimination",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "010100003",
            "des": "CDs & Cash Equiv-Net-Reverse Repo",
            "prioi_year_trail": "90,090",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "21,606,787",
            "adjusted_bal": "21,606,787"
        },
        //////////////////////////
        {
            "num": "001046000",
            "des": "Reserve for Other Receivables",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "-45,999",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001050000",
            "des": "Inventories",
            "prioi_year_trail": "44,333",
            "prioi_year_GAAP": "89,090",
            "prioi_year_submission": "0",
            "adjustment_open": "401,234,485",
            "adjusted_bal": "401,234,485"
        },
        {
            "num": "001055000",
            "des": "Other Current Assets",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001061000",
            "des": "Loans and Advances to Related U.S. companies",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "90,999",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001061001",
            "des": "Loans and Advances to Related Non-U.S. companies",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "48,998",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001071000",
            "des": "Equity Investment in Affiliates owned >50% directly and indirectly by GE",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001072000",
            "des": "Equity Investment in Affiliates owned 10-50% directly and indirectly by GE",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001073000",
            "des": "Equity Investment in Affiliates owned <10% directly and indirectly by GE",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001080000",
            "des": "Other Investments",
            "prioi_year_trail": "-45,999",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "0"
        },
        {
            "num": "001090000",
            "des": "Fixed Costs and Fixed Assets-Unfinished Plant and Equipment",
            "prioi_year_trail": "89,090",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "-10,537,361",
            "adjusted_bal": "-10,537,361"
        },
        {
            "num": "001091000",
            "des": "Fixed Costs and Fixed Assets-Buildings and Structures",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "139,005,689",
            "adjusted_bal": "139,005,689"
        },
        {
            "num": "001092000",
            "des": "Fixed Costs and Fixed Assets-Furniture and Fixtures",
            "prioi_year_trail": "0",
            "prioi_year_GAAP": "0",
            "prioi_year_submission": "0",
            "adjustment_open": "0",
            "adjusted_bal": "90,999"
        }
    ]
    
    
    
    
    
    }
});