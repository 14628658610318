define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.cfcsWithQualDefsService',[])
       .factory("cfcsWithQualDefsFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   		,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var cfcsWithQualDefsFactory ={};
	           cfcsWithQualDefsFactory.url ={};
	           cfcsWithQualDefsFactory.url.getGtapsCfcsWithQualDefsData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=a4hz8l";//Action id 1191
	           
		       cfcsWithQualDefsFactory.getGtapsCfcsWithQualDefsData = function(passedParams) {
					var params = {"process_name":"GTAPS Get CFC's with Qualified Deficits Data"};
				   params = _.merge({},  passedParams, params);
		           var promise = $http({
		        	   method: "post",
		               url: cfcsWithQualDefsFactory.url.getGtapsCfcsWithQualDefsData,
		               data: params
		           }).then(function (response) {
		               //var status = response.status;
		               return response.data;
		           });
		
		           return promise;
	           };
           
	           return cfcsWithQualDefsFactory;
       }]);

   	  return services;
});