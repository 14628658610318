define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.ConsolidatedCtrl', [])
        .controller('consolViewCtrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
            'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', consolViewCtrl
        ]);

    function consolViewCtrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG) {
        var vm = this;
        vm.consolAmount =

            [{
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "GBP",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "GBP",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL SIGMA HOLDING LTD",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "B450",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE COMMERCIAL FINANCE LIMITED",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE COMMERCIAL FINANCE LIMITED",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "2",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "B450",
                    "RPT_ID": "3",
                    "BSLA_BUSINESS": "C5XUKB ",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL SIGMA HOLDING LTD",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                },
                {
                    "C_I": "I",
                    "LE_ID": "000CGM",
                    "TAX_CODE": "247",
                    "RPT_ID": "1",
                    "BSLA_BUSINESS": "C5XUKB",
                    "FEED_CURR": "USD",
                    "LEGAL_ENTITY_NAME": "GE CAPITAL CORPORATION",
                    "GAAP": " ",
                    "E_PADJUSTMENT": "0",
                    "E&P": " ",
                    "BASELINE_AMOUNT": "NO DATA",
                    "CHANGE": " "
                }
            ];
    }
});