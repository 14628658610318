define([
	'uiMask',
    'angular'
], function (uiMask) {
    'use strict';
    var controllers = angular.module('app.F7004Ctrl', ['ui.mask'])
    
        .controller('F7004Controller', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'F7004ServiceFactory', 'USER_SETTINGS','$filter',F7004Controller]);
    	function F7004Controller(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, F7004ServiceFactory, USER_SETTINGS, $filter) {
            var vm = this;
       //     var uiMask = require('angular-ui-mask');
    		vm.originalData = [];
    		vm.userSettings = USER_SETTINGS;
    		vm.logged_in_user = vm.userSettings.user.sso_id;
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
			vm.currentyear = filterParams.tax_year;
    		vm.leId = rowData.LEID;
            vm.formName = 'F7004';
            vm.hgt = '96%';
            vm.cdr_no = rowData.MARS;
            vm.leType = rowData.LE_TYPE;
            vm.disableDate = true;
            vm.isSaveClicked = false;
            vm.calendar = {
        			opened: false
        		};

            vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
            $scope.entityList = {
    			"Tax Year": rowData.TAX_YEAR,
    			"LEID": rowData.LEID,
    			"LE Name": rowData.LE_NAME
    		};
            
            vm.bankAcctTypeOptions = {"0":{"CODE_NAME":"1","CODE_DESC":"Checking"}, "1":{"CODE_NAME":"2","CODE_DESC":"Savings"}};

 
    		var saveParamDtls = [];
    		var tempDataObj = {};
    		$scope.f7004FormView = [];
    		vm.showFormLoader = true;
    		vm.corporationList = [];
    		vm.entityList = [];
    		vm.entitiesData = [];
    		vm.individualsList = [];
    		vm.codeMastMap = [];

            function fetch7400records() {
                var params = { "action_code": 'sl17nu', "tax_year": vm.currentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": rowData.COMBINATION_KEY,"form_no": "7004", "schedule_name": "NA", "part_no" : "", "section_name": "NA", "jcd_ta_key": null};

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                	vm.modalTitle = data.jsonObject[0].ATTRIB_DESCRIPTION;
                	vm.formHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"FH"});
					vm.tableHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"TH"});
                	vm.formList1 =  data.jsonObject.slice(1);

                	$scope.f7004FormView = vm.formList1.filter(function( obj ) {
						return obj.ROW_TYPE !== 'TH';
					});
                	
                	vm.irsPayment = vm.formList1.filter(function( obj ) {
						return obj.PART_NO == 'III';
					});
                	
                	
                	for(var i=0; i< vm.irsPayment.length; i++){
                		if(vm.irsPayment[i].LINE_NO == 9){
                			vm.routingTransNum = vm.irsPayment[i].ATTRIB_VALUE
                		}else if(vm.irsPayment[i].LINE_NO == 10){
                			vm.bankAccountNum = vm.irsPayment[i].ATTRIB_VALUE
                		}else if(vm.irsPayment[i].LINE_NO == 11){
                			vm.bankAccountType = vm.irsPayment[i].ATTRIB_VALUE
                		}else if(vm.irsPayment[i].LINE_NO == 12){
                			vm.paymentType = vm.irsPayment[i].ATTRIB_VALUE
                		}else if(vm.irsPayment[i].LINE_NO == 13){
                			if(vm.irsPayment[i].ATTRIB_VALUE != 0){
                				vm.paymentDate = new Date(vm.irsPayment[i].ATTRIB_VALUE)
                			}
                		}else if(vm.irsPayment[i].LINE_NO == 14){
                			vm.phoneNumber = vm.irsPayment[i].ATTRIB_VALUE
                		}
                	}
                	
                	vm.getdetails(vm.bankAccountNum);
                	
                	
    				vm.originalData = angular.copy($scope.f7004FormView);
    				initFormView($scope.f7004FormView);
    				vm.validateSubForm();
    				fetchSubsidiaryEntities(); 
                });
            };
            
            if(rowData.EFILE_Y_N == 'YES'){
            	vm.isSaveClicked = true;
            }
            
            function fetchSubsidiaryEntities() {
                var params = { "action_code": 'eqyp2c', "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY, "filing_key": rowData.CONSOL_GROUP_KEY};

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                	vm.subsidiaries = data.jsonObject;
                	for(var i=0;i<data.jsonObject.length;i++){
                		vm.formDataObj = {};
                		vm.formDataObj["taxYearEnd"] = $filter('date')(data.jsonObject[i].TAX_YEAR_END, "MM/dd/yyyy");
                		vm.subsidiaries[i]["taxYearEnd"] = vm.formDataObj["taxYearEnd"];
                	}
                	
    				vm.showFormLoader = false; 
                });
            };
            
            
            vm.assignTabNames = function(tabName){
            	if(tabName == null){
            		vm.currentTabName = "Form Entry";
            	}else{
            		vm.currentTabName = tabName;
            	}
            }
            

			vm.fetchLOV = function() {
				var params = { "action_code": 'sjh60s', "sso_id": vm.logged_in_user, "tax_year": vm.currentyear, "scenario":  filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "form_no":"7004" };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					//vm.formLOV = data.jsonObject;
					if(data != null && data.jsonObject != null){
						vm.formLOV = data.jsonObject;
						vm.result = [];
						for (var item, i = 0; item = vm.formLOV[i++];) {
							var name = item.CODE_DESC;
							vm.result.push(name);
						}
					}/* -------- END --------- */

				});
			}
			
			vm.fetchModalCalcData = function(order) {
				var params = { "action_code": '1ubsuu', "tax_year": vm.currentyear, "scenario":  filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
						"form_no":"7004","schedule_name":"NA","part_no":"","section_name":"NA" };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					vm.formCalcData =  data.jsonObject;
				});
			}

			vm.validateSubForm =  function(entities, row, position){
				if(row == null || row.LINE_NO == '5b(v)' ){
					if(rowData.CONSOL_GROUP_KEY == '3'){
						 vm.disableNumberFields = _.filter($scope.f7004FormView, function(o) { 
								return o.DATA_TYPE == 'number'; 
						})
						_.forEach(vm.disableNumberFields,function(dl,index){
								dl['conditionalField'] = true
						});
					}
					
					vm.disabledLines = _.filter($scope.f7004FormView, function(o) { 
						return (o.LINE_NO == '5b(v)' && o.ATTRIB_SHORT_CODE == 'F7004_LINE_5b_CHECK5'); 
					})
					if (vm.disabledLines[0].ATTRIB_VALUE == 'true'){
						vm.conditionalField = false
					}else{
						vm.conditionalField = true
					}
				  
				  var userInputField = _.filter($scope.f7004FormView, function(o) { 
						return (o.LINE_NO == '5b(v)' && o.ATTRIB_SHORT_CODE == 'F7004_LINE_5b_OTHER_INPUT'); 
					})
					/*------If not disabled --------*/
					if (vm.disabledLines[0].ATTRIB_VALUE == 'true'){
						if(row == null){
							vm.disabledLines[0]["value_user_input"] = userInputField[0].ATTRIB_VALUE;
						}else{
							userInputField[0].ATTRIB_VALUE = vm.disabledLines[0]["value_user_input"];
						}
					}else{
						if(row != null){
							row.value_user_input = '';
						}
					}
				}
				
				if(row == null){
					//enableDisableLines();
					vm.dateFields = $filter("filter")($scope.f7004FormView, {DATA_TYPE:'dateVarious'});
					_.forEach(vm.dateFields,function(dl,index){
//						if(dl.ATTRIB_VALUE == undefined){
//								if(dl.LINE_NO == '5a(i)'){
//									dl["ATTRIB_VALUE"] = new Date($filter('date')(dl.TAX_YEAR_BEGIN, "MM/dd/yyyy"));
//								}else{
//									dl["ATTRIB_VALUE"] = new Date($filter('date')(dl.TAX_YEAR_END, "MM/dd/yyyy"));
//								}
//							}else{
							var formatDate = $filter('date')(dl.ATTRIB_VALUE, "MM/dd/yyyy");
								dl["ATTRIB_VALUE"] = new Date(formatDate);
//							}
							//vm.formatDate(dl);
					});
				} 
				
				
				
				/*------This is to run the calculations only on change --------*/
				//if(row != null && rowData.CONSOL_GROUP_KEY != '3') {
				if(row != null ) {
				   if (row.DATA_TYPE == 'number' ){
					for(var g=0; g<vm.formCalcData.length; g++){
						vm.calcAmt(vm.formCalcData[g].ATTRIB_CALC_FORMULA,vm.formCalcData[g].LINE_NO,vm.formCalcData[g].LINE_ATTRIB_KEY,vm.formCalcData[g].ATTRIB_COL,vm.formCalcData[g].EDIT_TYPE);
					}
				}else{
					
					vm.checkChangedValues(row);
					
				}}
				
			}
			
			vm.checkChangedValues = function (row) { 
				var lineVal;
				var oldAttribVal = $filter("filter")(vm.originalData, {ATTRIB_SHORT_CODE:row.ATTRIB_SHORT_CODE.trim()});
				var currentTabNewAtrribVal = _.filter($scope.f7004FormView, function(o) { 
					return o.ATTRIB_SHORT_CODE == row.ATTRIB_SHORT_CODE.trim(); 
				})
				if(currentTabNewAtrribVal[0].EDIT_TYPE == "custom_date_various"){
					 var currDate = $filter('date')(currentTabNewAtrribVal[0].ATTRIB_VALUE, 'MM/dd/yyyy');
				     lineVal = String(currDate);
				}else{
					lineVal = String(currentTabNewAtrribVal[0].ATTRIB_VALUE);
				}

				if(lineVal != oldAttribVal[0].ATTRIB_VALUE){
					currentTabNewAtrribVal[0].IS_CHANGED_FLAG = 'Y';
				}else{
					currentTabNewAtrribVal[0].IS_CHANGED_FLAG = 'N';
				}
			 
            }; 
			
			//Year Validation
			vm.checkYr = function (row, position) { 
				vm.msg = undefined;
				if(row.ATTRIB_VALUE != undefined){
					if((isNaN(row.ATTRIB_VALUE)) || row.ATTRIB_VALUE.toString().length < 4 || row.ATTRIB_VALUE <= 0 || (row.ATTRIB_VALUE > vm.currentyear)){
						vm.msg = "Input Date";
					}else{
						vm.checkChangedValues(row);
					}
				}
			 
            }; 
			
			function enableDisableLines(){
//				vm.inputVal = _.filter($scope.f7004FormView, function(o) { 
//					return o.LINE_NO == '5a'; 
//				})
				vm.dateFields = $filter("filter")($scope.f7004FormView, {DATA_TYPE:'dateVarious'});
	
//				if(vm.inputVal[0].ATTRIB_VALUE == undefined){
//					vm.inputVal[0].ATTRIB_VALUE = vm.currentyear;
//				}
				
				_.forEach(vm.dateFields,function(dl,index){
					if(dl.ATTRIB_VALUE == undefined){
							if(dl.LINE_NO == '5a(i)'){
								dl["ATTRIB_VALUE"] = new Date($filter('date')(dl.TAX_YEAR_BEGIN, "MM/dd/yyyy"));
							}else{
								dl["ATTRIB_VALUE"] = new Date($filter('date')(dl.TAX_YEAR_END, "MM/dd/yyyy"));
							}
						}else{
							dl["ATTRIB_VALUE"] = new Date(dl.ATTRIB_VALUE);
						}
						vm.formatDate(dl);
				});
				
			};
			
    	
    	vm.calcAmt = function(formula, line_no, attrib_key, attrib_col, edit_type){
			vm.currentFormula = [];
			vm.currentFormula = formula;
			var calcLineAmt = 0;

			var lineAmt = 0;
			var matches = formula.match(/\b[^\d\s]+\b/g);
			if(matches ==  null){
				var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			}
			
			for(var i=0; i<lineFormulaArray.length; i++){
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");

					var currentTabOldAtrribVal = $filter("filter")(vm.originalData, {FORM_NAME:"7004"});
					var oldAttribVal = $filter("filter")(currentTabOldAtrribVal, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var currentTabNewAtrribVal = _.filter($scope.f7004FormView, function(o) { 
						return o.ATTRIB_SHORT_CODE == lineFormulaArray[i].trim(); 
					})
					lineAmt = parseFloat(currentTabNewAtrribVal[0].ATTRIB_VALUE);

					if(lineAmt != parseFloat(oldAttribVal[0].ATTRIB_VALUE)){
						currentTabNewAtrribVal[0].IS_CHANGED_FLAG = 'Y';
					}
					if(parseFloat(lineAmt)>= 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself

				var changedFormulaAttribVal = $filter("filter")($scope.f7004FormView, {LINE_NO : line_no, LINE_ATTRIB_KEY : attrib_key});
				var calculatedVal = eval(formula);

				if(parseFloat(changedFormulaAttribVal[0].ATTRIB_VALUE) != calculatedVal.toFixed()){
					changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
					changedFormulaAttribVal[0].IS_CHANGED_FLAG = 'Y';
				}else{
					changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
				}
				if(line_no === '8' && changedFormulaAttribVal[0].ATTRIB_VALUE <= 0) {
					changedFormulaAttribVal[0].ATTRIB_VALUE = 0;
				}
			}
			
    	}
    	
    	
      	vm.assignIRSPaymentValues = function (name,value) { 
      		vm.currentRecord = vm.irsPayment.filter(function( obj ) {
				return obj.ATTRIB_SHORT_CODE == name;
			});
//      		if(value != undefined){
      			if(value != undefined && name == 'F7004_ROUTING_NUM'){
//      				vm.bankAccountNum = value;
//      				vm.currentRecord[0].ATTRIB_VALUE = value;
      			/*	var trailingCharsIntactCount = 4;
      				value = new Array(value.length - trailingCharsIntactCount + 1).join('x')
      		       + value.slice(-trailingCharsIntactCount);
      				vm.bankAccountNum = value;*/
//      				str = str.replace(/.(?=.{4})/g, 'x');
      				
      				//var value1 = value.toString().trim().replace(/^\+/, '');
      				vm.currentRecord[0].ATTRIB_VALUE = value;
      				var routingNoRegex = /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2]))([0-9]{7})$/;
//      				var routingNoRegex = /^(?=(?:[0][1-9]|[13][0-2]|[2][0-9]))\d{9}$/;
      				//^(?=(?:[0][1-9]|[13][0-2]|[2][0-9]))\d{9}$
      				 vm.validRoutingNum = routingNoRegex.test(value);
      				
      			}else if(value != undefined && name == 'F7004_PAYMENT_DATE'){
      				vm.currentRecord[0].ATTRIB_VALUE = $filter('date')(value, 'MM/dd/yyyy');
      			}else{
      				vm.currentRecord[0].ATTRIB_VALUE = value;
      			}
      			
      			vm.checkChangedValues(vm.currentRecord[0]);
//      		}
    		
    	}
      	
      	//ng-focus
      	vm.getViewdetails = function (value) { 
    			if(value != undefined){
//    				vm.currentRecord[0].ATTRIB_VALUE = value;
    				vm.bankAccountNum = vm.acctNum[0].ATTRIB_VALUE;
    			}
      		
      	}
      	
      	//ng-blur
      	vm.getdetails = function (value) { 
	      		vm.acctNum = vm.irsPayment.filter(function( obj ) {
					return obj.ATTRIB_SHORT_CODE == 'F7004_ACCOUNT_NUM';
				});
    			var trailingCharsIntactCount = 4;
    			if(value != undefined){
    				vm.acctNum[0].ATTRIB_VALUE = value;
    				value = new Array(value.length - trailingCharsIntactCount + 1).join('x') + value.slice(-trailingCharsIntactCount);
    				vm.bankAccountNum = value;
    			}
      		
      	}
      	
    	function fetchClientDates() {
			var params = { "action_code": 'x9fn73', "tax_year": vm.currentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "filing_key": rowData.CONSOL_GROUP_KEY };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				if(data != null && data.jsonObject != null){
					vm.formDates = data.jsonObject;
					vm.filerYearBegin = $filter('date')(vm.formDates[0].FILER_TAX_YEAR_BEGIN, "MM/dd");
//					vm.filerYearBegin = "02/03";
					vm.filerYearEnd = $filter('date')(vm.formDates[0].FILER_TAX_YEAR_END, "MM/dd");

				}
	
			});
		} 

    		
    		function init7004(){
    			vm.dtmax = new Date();
    				vm.fetchLOV();
    				fetchClientDates();
    			    vm.fetchModalCalcData();
    				fetch7400records();
    				vm.assignTabNames();
    		};
    		init7004(); 
            


			vm.save = function() { 
				vm.isSaveClicked = true;
				
				if(vm.msg != undefined ){
					AlertService.add("error", "Please enter a valid Calendar Year");
					vm.isSaveClicked = false;
					return;
				}
				
				if(!vm.validRoutingNum && vm.validRoutingNum != undefined){
					AlertService.add("error", "Please enter a valid Routing Transit Num");
					vm.isSaveClicked = false;
					return;
				}

				var returnClobSettingsObj = {};
				var f7004SaveDtls = [];
				var combinationKey = 0;
				
					vm.saveData = $filter("filter")($scope.f7004FormView, {IS_CHANGED_FLAG:'Y'});
					
					if(vm.saveData.length == 0){
						AlertService.add("error", "No chnages to save.");
						vm.isSaveClicked = false;
						return;
					}
					
					for(var i=0;i < vm.saveData.length;i++){
						var returnObj = {};

						returnObj['form_name'] =  vm.saveData[i].FORM_NAME;
//						combinationKey = vm.saveData[i].COMBINATION_KEY;
						returnObj['part_no'] =  vm.saveData[i].PART_NO;
						returnObj['line_no'] =  vm.saveData[i].LINE_NO;
						returnObj['schd_no'] =  vm.saveData[i].SCHEDULE_NAME;
						returnObj['section_name'] =  vm.saveData[i].SECTION_NAME;
						returnObj['combination_key'] = vm.saveData[i].COMBINATION_KEY;
						returnObj['form_key'] = vm.saveData[i].FORM_KEY;
						returnObj['form_line_key'] = vm.saveData[i].FORM_LINE_KEY;
						returnObj['line_attrib_key'] = vm.saveData[i].LINE_ATTRIB_KEY;
						returnObj['attrib_type'] = vm.saveData[i].ATTRIB_TYPE;
						returnObj['attrib_prop_key'] = vm.saveData[i].ATTRIB_PROP_KEY;
						returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REF_KEY;
						if(vm.saveData[i].EDIT_TYPE == 'custom_date_various'){
							var str = $.datepicker.formatDate('mm/dd/yy', vm.saveData[i].ATTRIB_VALUE);
							vm.saveData[i].ATTRIB_VALUE = str;
							returnObj['attrib_value'] = vm.saveData[i].ATTRIB_VALUE;
						}else if (vm.saveData[i].LINE_NO == '20'){
							if(vm.saveData[i].ATTRIB_DESCRIPTION == 'LE Name'){
								returnObj['attrib_value'] =  rowData.HO_LE_NAME;
							}else if(vm.saveData[i].ATTRIB_DESCRIPTION == 'EIN'){
								returnObj['attrib_value'] = rowData.EIN;
							}else if(vm.saveData[i].ATTRIB_DESCRIPTION == 'Address1'){
								returnObj['attrib_value'] = rowData.ADDRESS_1;
							}else if(vm.saveData[i].ATTRIB_DESCRIPTION == 'Address2'){
								returnObj['attrib_value'] =  rowData.ADDRESS_2;
							}else if(vm.saveData[i].ATTRIB_DESCRIPTION == 'City'){
								returnObj['attrib_value'] =  rowData.CITY;
							}else if(vm.saveData[i].ATTRIB_DESCRIPTION == 'State'){
								returnObj['attrib_value'] =  rowData.STATE;
							}else if(vm.saveData[i].ATTRIB_DESCRIPTION == 'Zip'){
								returnObj['attrib_value'] =  rowData.ZIP;
							} 
						}else if (vm.saveData[i].LINE_NO == '10'){
							
							returnObj['attrib_value_10'] = vm.saveData[i].ATTRIB_VALUE;
						}else{
							returnObj['attrib_value'] = vm.saveData[i].ATTRIB_VALUE;
						}
						returnObj['trans_details_key'] = vm.saveData[i].TRANS_DETAILS_KEY;
						returnObj['trans_type_key'] = vm.saveData[i].TRANS_TYPE_KEY;
						returnObj['is_changed_flag'] = vm.saveData[i].IS_CHANGED_FLAG;
						returnObj['occurrence'] = (vm.saveData[i].OCCURRENCE != null) ? parseInt(vm.saveData[i].OCCURRENCE) : 1;

						f7004SaveDtls.push(returnObj);
					}
					var message = "Form has been successfully saved/updated";


				returnClobSettingsObj['sso_id'] = vm.logged_in_user;
				returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
				returnClobSettingsObj['form_name'] = $scope.f7004FormView[0].FORM_NAME;
				returnClobSettingsObj['combination_key'] = $scope.f7004FormView[0].COMBINATION_KEY;

				sendDetails(returnClobSettingsObj,f7004SaveDtls, message);

			};

			function sendDetails(returnClobSettingsObj, f7004SaveDtls, message) {
				F7004ServiceFactory.savef7004(returnClobSettingsObj,f7004SaveDtls).then(function(result) {

					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						//vm.crudLoading = false;
						AlertService.add("success", message, 4000);
						var args = {
								combination_key: returnClobSettingsObj.combination_key,
								gridFilter: {
									combination_key: returnClobSettingsObj.combination_key
								}
						};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
					};

				});
			}	


    		vm.reset = function (){
    			$scope.f7004FormView = angular.copy(vm.originalData);
    			vm.validateSubForm();// This is called here to reset line 5a
    			
    			
    			//for IRS details reset
    			vm.irsPaymentReset = vm.originalData.filter(function( obj ) {
					return obj.PART_NO == 'III';
				});
            	
            	
            	for(var i=0; i< vm.irsPaymentReset.length; i++){
            		if(vm.irsPaymentReset[i].LINE_NO == 9){
            			vm.routingTransNum = vm.irsPaymentReset[i].ATTRIB_VALUE
            		}else if(vm.irsPaymentReset[i].LINE_NO == 10){
            			vm.bankAccountNum = vm.irsPaymentReset[i].ATTRIB_VALUE
            		}else if(vm.irsPaymentReset[i].LINE_NO == 11){
            			vm.bankAccountType = vm.irsPaymentReset[i].ATTRIB_VALUE
            		}else if(vm.irsPaymentReset[i].LINE_NO == 12){
            			vm.paymentType = vm.irsPaymentReset[i].ATTRIB_VALUE
            		}else if(vm.irsPaymentReset[i].LINE_NO == 13){
            			if(vm.irsPaymentReset[i].ATTRIB_VALUE != 0){
            				vm.paymentDate = new Date(vm.irsPaymentReset[i].ATTRIB_VALUE)
            			}
            		}else if(vm.irsPaymentReset[i].LINE_NO == 14){
            			vm.phoneNumber = vm.irsPaymentReset[i].ATTRIB_VALUE
            		}
            	}
            	
            	vm.getdetails(vm.bankAccountNum);
    		};

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
            
            vm.formatDate = function(row) {
            	row["taxyearBE"] = $filter('date')(row.ATTRIB_VALUE, 'MM/dd/yyyy');
            	vm.checkChangedValues(row);
            };
            
    		vm.openCalendar = function(row) {
//				vm.calendar.opened = true;
    			row['isOpen'] = true;
			};
			
			vm.openCalendar1 = function() {
				vm.calendar.opened = true;
			};
			
            function initFormView(filteredFormData) {
    			$scope.f7004FormView = filteredFormData;
    		};

    		//Dynamic Tabset Code
    		vm.tabs = [
    			 {id: 1, name: "Form Entry", url: "app/components/F1120/F7004/F7004Entry.html", active: true },
    			 {id: 2, name: "Subsidiary", url: "app/components/F1120/F7004/F7004Entry.html", active: true }
    	    ];
        }
});