define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.epAdjustmentsLocalService', [])

					.factory(
							"EPLocalTaxAdjustmentsFactory",
							[
									'$q',
									'$http',
									'$stateParams',
									'$timeout',
									'$rootScope',
									'$log',
									'AlertService',
									'GlobalService',
									'DataFiltersFactory',
									'workspaceFactory',
									'JsonObjectFactory',
									'GENERAL_CONFIG',
									function($q, $http, $stateParams, $timeout,
											$rootScope, $log, AlertService,
											GlobalService, DataFiltersFactory,
											workspaceFactory,
											JsonObjectFactory, GENERAL_CONFIG) {
										var epAdjsFactory = {};
										epAdjsFactory.url = {};
										epAdjsFactory.url.saveUpdateAdjs = GENERAL_CONFIG.base_url
												+ "/saveJsonObject?action_code=es4lx5";
										epAdjsFactory.url.saveUpdateAdjsDCS = GENERAL_CONFIG.base_url
												+ "/saveJsonObject?action_code=lmbn9a";
										epAdjsFactory.url.deleteAdjs = GENERAL_CONFIG.base_url
												+ "/saveJsonObject?action_code=k2p5gw";
										epAdjsFactory.url.saveUpdateAdjsDCSBOOKREPORTABLE = GENERAL_CONFIG.base_url
												+ "/saveJsonObject?action_code=ea3aa3";

										// ea3aa3
										epAdjsFactory.getActiveScreen = function() {
											return workspaceFactory.activeScreen.screen_key;
										};
										epAdjsFactory.getScreenFilters = function() {
											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams(): {};

											return filterParams;
										};

										epAdjsFactory.getAccounts = function(
												val, mainScreenKey,
												ge_gecs_entity, source_system,
												acct_type_str, chart_key,
												parent_acct_key,
												is_system_acct_search,
												system_acct_key) {
											console.log(
													"chart key for tax adjs: ",
													chart_key);
											var params = {
												"action_code" : 'd1ndzz',
												"screen_key" : mainScreenKey,
												"ge_gecs_entity" : ge_gecs_entity,
												"source_type" : (source_system === 'DCS' ? 'D'
														: 'F'),
												"search_string" : val,
												"acct_type_str" : acct_type_str,
												"chart_key" : chart_key,
												"parent_acct_key" : parent_acct_key,
												"system_acct_search" : is_system_acct_search,
												"parent_system_acct_key" : system_acct_key
											};

											return JsonObjectFactory
													.getJSONObj(
															GENERAL_CONFIG.base_url
																	+ '/loadJsonObject',
															params)
													.then(
															function(data) {
																if (data.errorMessage.length > 0
																		&& data.errorMessage !== 'null') {
																	AlertService
																			.add(
																					"error",
																					data.errorMessage);
																	return false;
																} else {
																	return data.jsonObject;
																}
															});
										};

										epAdjsFactory.getLineDetails = function(
												mainScreenKey,tax_type) {
											 var params = {
									                    "action_code" : (tax_type === 'PTSP' || tax_type == 'BRPTP' || tax_type == 'BRPTSP'|| tax_type == 'NCPTSP'|| tax_type == 'NCPTSPNF') ? 'r7pupz' : '6mnbxg',
									                    "screen_key" : mainScreenKey
									                };

											return JsonObjectFactory
													.getJSONObj(
															GENERAL_CONFIG.base_url
																	+ '/loadJsonObject',
															params)
													.then(
															function(data) {
																if (data.errorMessage.length > 0
																		&& data.errorMessage !== 'null') {
																	AlertService
																			.add(
																					"error",
																					data.errorMessage);
																	return false;
																} else {

																	return data.jsonObject;
																}
															});

										};

										epAdjsFactory.getDCSLineDetails = function(
												posting_acct, template_id) {
											var params = {
												"action_code" : 'p7ymkm',
												"posting_acct" : posting_acct,
												"template_id" : template_id
											};

											return JsonObjectFactory
													.getJSONObj(
															GENERAL_CONFIG.base_url
																	+ '/loadJsonObject',
															params)
													.then(
															function(data) {
																if (data.errorMessage.length > 0
																		&& data.errorMessage !== 'null') {
																	AlertService
																			.add(
																					"error",
																					data.errorMessage);
																	return false;
																} else {
																	return data.jsonObject;
																}
															});

										};

										epAdjsFactory.getDCSPLDetails = function(
												posting_acct, local_chart_key) {
											var params = {
												"action_code" : 'eeusaq',
												"posting_acct_key" : posting_acct,
												"chart_key" : local_chart_key
											};

											return JsonObjectFactory
													.getJSONObj(
															GENERAL_CONFIG.base_url
																	+ '/loadJsonObject',
															params)
													.then(
															function(data) {
																if (data.errorMessage.length > 0
																		&& data.errorMessage !== 'null') {
																	AlertService
																			.add(
																					"error",
																					data.errorMessage);
																	return false;
																} else {
																	return data.jsonObject;
																}
															});

										};

										epAdjsFactory.getTradingPartners = function(
												tax_year, scenario, val) {
											var params = {
												"action_code" : '50swfi',
												"tax_year" : tax_year,
												"scenario" : scenario,
												"search_string" : val
											};

											return JsonObjectFactory
													.getJSONObj(
															GENERAL_CONFIG.base_url
																	+ '/loadJsonObject',
															params)
													.then(
															function(data) {

																if (data.errorMessage
																		&& data.errorMessage.length > 0
																		&& data.errorMessage !== 'null') {
																	AlertService
																			.add(
																					"error",
																					data.errorMessage);
																} else {
																	if (data.jsonObject.length) {
																		return data.jsonObject;
																	}
																}
															});
										};

										epAdjsFactory.getProjectsAndSteps = function(
												tax_year) {
											var params = {
												"action_code" : 'qbnyai',
												"tax_year" : tax_year
											};

											return JsonObjectFactory
													.getJSONObj(
															GENERAL_CONFIG.base_url
																	+ '/loadJsonObject',
															params)
													.then(
															function(data) {

																if (data.errorMessage
																		&& data.errorMessage.length > 0
																		&& data.errorMessage !== 'null') {
																	AlertService
																			.add(
																					"error",
																					data.errorMessage);
																} else {
																	if (data.jsonObject.length) {
																		return data.jsonObject;
																	}
																}
															});
										};

										epAdjsFactory.getDcsAdjsTypes = function() {
											var adjTypes = [
													{
														"ADJ_TYPE" : "PM",
														"ADJ_DESC" : "Permanent"
													},
													{
														"ADJ_TYPE" : "EQ",
														"ADJ_DESC" : "Equity"
													},
													{
														"ADJ_TYPE" : "TC",
														"ADJ_DESC" : "Current Temporary"
													},
													{
														"ADJ_TYPE" : "TN",
														"ADJ_DESC" : "NonCurrent Temporary"
													}, {
														"ADJ_TYPE" : "TX",
														"ADJ_DESC" : "Taxes"
													} ];

											return adjTypes;
										};

										epAdjsFactory.saveUpdateAdjustments = function(
												_data, _accounts) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var jsonObj = JSON
													.stringify(_accounts);
											var jsonSettings = JSON
													.stringify(_data);

											var params = filterParams;
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _.extend({
												jsonObj : jsonObj
											}, params);
											params = _.extend({
												jsonSettings : jsonSettings
											}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "TAX_ADJS_LOCAL_ACCT";
											/*
											 * console.log("adjustments params
											 * to save"); console.log(params);
											 */

											var promise = $http(
													{
														method : "post",
														url : epAdjsFactory.url.saveUpdateAdjs,
														data : params
													}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										};

										// dcs

										epAdjsFactory.saveUpdateAdjustmentsDCSBOOKREPORTABLE = function(
												_data, _accounts) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var jsonObj = JSON
													.stringify(_accounts);
											var jsonSettings = JSON
													.stringify(_data);

											var params = filterParams;
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _.extend({
												jsonObj : jsonObj
											}, params);
											params = _.extend({
												jsonSettings : jsonSettings
											}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "TAX_ADJS_BOOK_REPORTABLE";
											/*
											 * console.log("adjustments params
											 * to save"); console.log(params);
											 */

											var promise = $http(
													{
														method : "post",
														url : epAdjsFactory.url.saveUpdateAdjsDCSBOOKREPORTABLE,
														data : params
													}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										};

										epAdjsFactory.saveUpdateAdjustmentsDCS = function(
												_data, _accounts) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var jsonObj = JSON
													.stringify(_accounts);
											var jsonSettings = JSON
													.stringify(_data);

											var params = filterParams;
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _.extend({
												jsonObj : jsonObj
											}, params);
											params = _.extend({
												jsonSettings : jsonSettings
											}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "TAX_ADJS_LOCAL_ACCT";
											/*
											 * console.log("adjustments params
											 * to save"); console.log(params);
											 */

											var promise = $http(
													{
														method : "post",
														url : epAdjsFactory.url.saveUpdateAdjsDCS,
														data : params
													}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										};

										epAdjsFactory.deleteAdjustment = function(
												_data) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};

											var params = filterParams;
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _
													.extend({}, _data, params);

											var promise = $http(
													{
														method : "post",
														url : epAdjsFactory.url.deleteAdjs,
														data : params
													}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										};
										return epAdjsFactory;
									} ]);

			return services;

		});