define([
    'angular'
], function () {
    'use strict';

    angular.module('app.formViewCtrl', [])
        .controller('FormViewCtrl', ['$scope','$rootScope', 'FormViewFactory', 'GlobalService', 'TAX_YEARS', '$uibModal', 'AlertService', 'USER_SETTINGS','MessageService','GENERAL_CONFIG','$timeout', 'SERVER_CONFIG','$state','JsonObjectFactory','EfileFactory', FormViewCtrl])
        .controller('ViewBulkExportSelectionCtrl', ['$rootScope', 'FormViewFactory', 'rowData', 'USER_SETTINGS', ViewBulkExportSelectionCtrl])
        .controller('ViewBulkExportEntitySelectionCtrl', ['$rootScope', 'FormViewFactory', 'rowData', 'USER_SETTINGS', ViewBulkExportEntitySelectionCtrl])
        .controller('S3TemplateView', ['$scope','$rootScope','GENERAL_CONFIG', 'rowData', 'USER_SETTINGS','$http','$sce', 'FormViewFactory','AlertService','workspaceFactory','$uibModalInstance','JsonObjectFactory', S3TemplateView]);
   
        function FormViewCtrl($scope,$rootScope, FormViewFactory, GlobalService, TAX_YEARS, $uibModal, AlertService, USER_SETTINGS,MessageService,GENERAL_CONFIG,$timeout, SERVER_CONFIG,$state,JsonObjectFactory,EfileFactory) {

        let vm = this;
        const LEGACY_TAX_YEAR = 2018;
        const WEBSOCKET_ENDPOINT = '/gtw-websockets';
        const WEBSOCKET_CHANNEL = '/notify/form-view/';
        vm.isState = $state.current.name === 'state-form-view.dashboard';
        vm.store = $state.current.name === 'state-form-view.dashboard' ?'stateFormViewCache':'formViewCache';
        vm.JCD_KEY = $state.current.name === 'state-form-view.dashboard' ? 253:250;
        vm.SRC = $state.current.name === 'state-form-view.dashboard' ? "STATE":"FED";
        vm.cacheStore = FormViewFactory[vm.store];
        vm.pdfParams = {
            systemFlag:'D',
            jcdKey:vm.JCD_KEY
        };
        vm.canTriggerS3Process = SERVER_CONFIG.clientConfig.pdf_s3_generation_sso_list.includes(USER_SETTINGS.user.sso_id);
        let treeDataSet = new Set;
        let parentNodesAhead = [];
        vm.pdfLoaded = false;
        vm.expandPdfView = true;
        vm.noFormsMsg = 'Please select an entity from the list!';
        vm.expandAll = true;
        
        //Theme Init
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
            $scope.$apply();
        });
        
        $rootScope.$on('pdf:loaded', function (ev, theme) {
            vm.pdfLoaded = true;
        });
        $rootScope.$on('pdf:running-pdf-action', function (ev, theme) {
            vm.runningAction = true;
        });
        $rootScope.$on('pdf:completed-pdf-action', function (ev, theme) {
            vm.runningAction = false;
        });
        


        //Filters Intitiation

        //1. Tax Year
       vm.taxYears = GlobalService.inputs.tax_year.values;
       var selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:GlobalService.inputs.tax_year.selected.value})
        vm.taxYear = vm.cacheStore.filters.taxYear = vm.cacheStore.filters.taxYear || selectedTaxYear;
        vm.scenarios = vm.taxYear.scenarios.filter(function(s) {
            return s.jcd_key == vm.JCD_KEY;
        })

        //2. Scenario
        vm.scenario =
            vm.cacheStore.filters.scenario = 
                vm.cacheStore.filters.scenario ||
                vm.scenarios.find(scenario => scenario.default_scenario === 'Y') ||
                vm.scenarios[0] || {};
        

        //3. LE Type
        vm.treeTypeTabs = [
            {name: 'Form', value: 'FORM'},
            {name: 'ME', value: 'BSLA'},
            // {name: 'Custom Group', value: 'CG',showTooltip:true,tooltip:"Coming Soon!!",disabled:true},
            
        ];
        vm.getTreeName = () => _.find(vm.treeTypeTabs,["value",vm.treeType]).name;
        const DEFAULT_TREE_TYPE = vm.treeTypeTabs[0].value
        vm.treeType = DEFAULT_TREE_TYPE;

        //4. Entity Type (for ME/BSLA)
        vm.bslaTypeTabs = [
            {name: 'ALL', value: 'ALL'},
            {name: 'REG', value: 'REG'},
            {name: 'LI', value: 'LI'},
            {name: 'NLI', value: 'NLI'}
        ];
        const DEFAULT_BSLA_TYPE = vm.bslaTypeTabs[0].value;
        vm.bslaType = '';

        //Public methods
        
        //Business methods
        vm.entitySelected = entitySelected;
        vm.loadHierarchy = loadHierarchy;
        vm.loadPDF = loadPDF;
        vm.selectForm = selectForm;
        vm.selectAllPDFs = selectAllPDFs;
        vm.formFilter = formFilter;
        vm.loadMergedPDF = loadMergedPDF;
        vm.changeTaxYear = changeTaxYear;
        vm.changeScenario = changeScenario;
        vm.runAction = runAction;
        vm.pushPdfsToS3 = pushPdfsToS3;
        vm.updateEntityS3Status = updateS3Status;
        vm.getProccessList = getProccessList;
        vm.openBulkExportPopUp = openBulkExportPopUp;
        vm.createSnapshot = createSnapshot;
        vm.loadIRSId = loadIRSId;
        vm.downloadXMLorZIPBySubmission = downloadXMLorZIPBySubmission;
        

        //Tree Utility Methods
        vm.toggleNode = toggleNode;
        vm.visibleNodes = visibleNodes;
        vm.getRecordsCount = getRecordsCount;
        vm.expandCollapseAll = expandCollapseAll;
        vm.filterTreeData = filterTreeData;
        vm.getIconClass = getIconClass;

        vm.disableS3PushButton = false;

        setupDrilldownsListener();

        loadCache();

        function getIconClass(item){
            return item.noEntities ? 'fa-exclamation-circle ' : 
                                item.is_leaf == 1 && item.is_entity ? 
                                (
                                    item.is_accepted === "Y" ? 'fa fa-check toggle':
                                    (
                                        item.is_locked === "Y" ? 'fa fa-lock toggle' :
                                        (    
                                            
                                            item.external_y_n === 'Y'? 'fa fa-globe toggle':
                                            'fa fa-file-pdf-o toggle'
                                        )
                                        
                                    )
                                ):
                                 item.childrenHidden ?
                                'hover-override fa-folder' : 'hover-override fa-folder-open'
        }
        function changeTaxYear() {
            vm.cacheStore.filters.taxYear = vm.taxYear;
            vm.scenarios = vm.taxYear.scenarios.filter(function(s) {
                return s.jcd_key == vm.JCD_KEY;
            })
            vm.scenario = vm.cacheStore.filters.scenario = vm.scenarios.find(scenario => scenario.default_scenario === 'Y') || vm.scenarios[0] || {};
            console.log("changed scenario to" + vm.scenario.scenario)
        }
        function changeScenario() {

            vm.cacheStore.filters.scenario = vm.scenario;
            console.log("changed scenario to" + vm.scenario.scenario)
        }
        /**
         * Sets up the state change even for catching drilldown url change
         */
        function setupDrilldownsListener() {
            $scope.$on('$locationChangeSuccess',
                function (event, toUrl, toParams, fromState, fromParams) {
                    if (toUrl.includes('popup')) {
                        const paramsStr = toUrl.split('?')[1].split('&');
                        let params = {};
                        paramsStr.forEach((param) => {
                            let keyValues = param.split('=');
                            params[keyValues[0]] = keyValues[1] === 'null' ? '' : keyValues[1];
                        });
                        FormViewFactory.getCombinationKeysByEntity(vm.taxYear.label,vm.scenario.scenario,vm.JCD_KEY, vm.selectedEntity.leid,vm.selectedEntity.cdr_no,vm.selectedEntity.reporting_period, vm.selectedEntity.le_type)
                            .then((response) => {
                                if (response.data.callSuccess === '1') {
                                    const keys = response.data.jsonObject;
                                    let combinationKeys = '';
                                    keys.forEach((key) => {
                                        combinationKeys += key.COMBINATION_KEY + ',';
                                    });
                                    params.combinationKeys = combinationKeys.substring(0, combinationKeys.length - 1);
                                    // openDetailsPopup(params); commented by dvalaboju001
                                    openTabDetails(params);
                                }
                            })
                            .catch((error) => {
                                AlertService.add('error', 'Error retrieving combination keys for selected entity!');
                            });
                    }
                }
            );
        }

        function openTabDetails(params){
            var _data = {
                leName:  vm.selectedEntity.name,
                sso: USER_SETTINGS.user.sso_id,
                taxYear:  vm.taxYear.label,
                scenario:vm.scenario.scenario,
                jcdKey: vm.JCD_KEY,
                sourceSystem:vm.selectedEntity.source_system_flag,
                summaryLevel:vm.selectedEntity.le_type,
                meCodes: vm.selectedEntity.le_type === 'BSLA' ? vm.selectedEntity.leid : '',
                combinationKeys: params.combinationKeys,
                form:vm.selectedForm.form_name,
                schedule: params.schedule,
                partNo:params.partNo,
                line: params.line,
                colId: params.col_id,
                lineTotal:params.line_total,
                elementName:params.element_name
            }
            $rootScope.$broadcast("proformaView.addTab", _data);

        }

        function loadCache(){
            if(vm.cacheStore["entityTreeCopy"][vm.treeType+'_'+vm.bslaType]){
                vm.entityTree = vm.cacheStore["entityTreeCopy"][vm.treeType+'_'+vm.bslaType];
                vm.entityTreeCopy = angular.copy(vm.entityTree);

                vm.pdfs = vm.cacheStore["pdfs"] || [];
                vm.pdfs && vm.pdfs.length > 0 && (vm.noFormsMsg = '');
            
                if(vm.cacheStore["selectedEntity"]){
                    setFlags();

                    setSelectedEntity(vm.cacheStore["selectedEntity"]);
        
                    setEntityLevelPdfParams();                    
                } 
    
                vm.cacheStore["selectedForm"] && loadPDF(vm.cacheStore["selectedForm"]);
            }


        }

        function pushPdfsToS3() {
            vm.disableS3PushButton = true;
            FormViewFactory.pushPdfsToS3(vm.treeType,vm.taxYear.label,vm.scenario.scenario,vm.JCD_KEY).then(function(response) {
                if(response && response.data) {
                    vm.disableS3PushButton = false;
                }
            }).catch(function(e) {
                
            })
        }

        /**
         * load the hierarchy
         */
        function loadHierarchy(treeType,filterClicked) {
            if(treeType && treeType.disabled){
                return;
            }
            
            vm.treeType = treeType ? treeType.value : (vm.treeType ? vm.treeType : DEFAULT_TREE_TYPE);

            if(vm.treeType === 'FORM'){
                vm.bslaType = '';
            }else if(vm.bslaType === ''){
                vm.bslaType = DEFAULT_BSLA_TYPE;
            }
            
            if(!filterClicked && vm.cacheStore["entityTreeCopy"][vm.treeType+'_'+vm.bslaType] && vm.cacheStore["entityTreeCopy"][vm.treeType+'_'+vm.bslaType].length > 1){
                vm.entityTree = vm.cacheStore["entityTreeCopy"][vm.treeType+'_'+vm.bslaType];
                vm.entityTreeCopy = angular.copy(vm.entityTree);
                
                // vm.noFormsMsg = 'Please select an entity from the list!';
                vm.pdfs = [];
                return;
            }
            
            vm.loadingEntities = true;
            vm.pdfLoaded = false;
            vm.entityTree = vm.entityTree ? [] : undefined;
            vm.entityTreeCopy = vm.entityTreeCopy ? [] : undefined;
            
            FormViewFactory.loadHierarchy(vm.treeType,vm.bslaType,vm.taxYear.label,vm.scenario.scenario,vm.JCD_KEY,vm.isState).then(function(response) {
                vm.loadingEntities = false;
                vm.expandPdfView = true;
                let list = response.data.viewList;
                
                if(!list){
                    list = [{
                        name: 'Something went wrong!',
                        noDataFlag: true
                    }];
                }else if(list.length === 0){
                    list = [{
                        name: 'No data available!',
                        noDataFlag: true
                    }];
                }
                //tree manipulations

                //tree manipulations - end
                vm.entityTree = list;
                vm.treeType !== "BSLA" && sanatizeTree();
                vm.entityTreeCopy = vm.cacheStore["entityTreeCopy"][vm.treeType+'_'+vm.bslaType] = angular.copy(vm.entityTree);


                vm.noFormsMsg = 'Please select an entity from the list!';
                vm.pdfs = [];

                // localStorage.setItem("localTreeCache",JSON.stringify(vm.entityTree));
                vm.treeType === 'FORM' && expandCollapseAll();
            }).catch(function(e) {
                list = [{
                    name: 'Something went wrong!',
                    noDataFlag: true
                }];
                vm.loadingEntities = false;
                vm.expandPdfView = true;
            })
        }

        /**
         * Loads Entity Hierarchy
         *  Also, Resets all the flags
         * @param {Entity} entity 
         * @param {string} subsidiaryType 
         * @param {boolean} useCache 
         * @returns 
         */
         function entitySelected(entity,subsidiaryType = '', useCache = false) {
            // if ((entity.is_entity === 0 && vm.treeType !== 'BSLA')) {
            //     vm.toggleNode(entity);
            //     return;
            // }

            vm.selectedForm = vm.cacheStore["selectedForm"] = null;

            setFlags();

            setSelectedEntity(entity); //added HO/HOO in breadcrumb

            setEntityLevelPdfParams();

            getForms(entity,'N');
        }

        function setFlags() {

            vm.isSelectAll =  vm.pdfs && vm.pdfs.length > 0 && getSelectedPDFs().length === vm.pdfs.length;
            vm.showSelectAll = vm.showMerge = vm.pdfs && vm.pdfs.length > 0 ? true :false;
            vm.enableMerge = getSelectedPDFs().length > 1;
            
            vm.showExpand = false;
            

            vm.formSearchTerm = '';

            vm.expandPdfView = true;

            vm.messageTxt = "";
            vm.messageComplete = false;

            vm.pdfLoaded = false;
            vm.updateS3Status = false;
        }

        function setSelectedEntity(entity) {
            vm.selectedEntity = vm.cacheStore["selectedEntity"] = entity;
            vm.selectedEntityBreadcrumb = entity.path.replaceAll('/ ', ' -> ');
            vm.selectedEntityBreadcrumb = vm.selectedEntityBreadcrumb.replace(entity.leid, entity.le_type + '-> ' + entity.leid);

            loadIRSId();
        }

        function setEntityLevelPdfParams() {
            vm.pdfParams.leId = vm.selectedEntity.leid;
            vm.pdfParams.cdrNo = vm.selectedEntity.cdr_no || '';
            vm.pdfParams.rptPeriod = vm.selectedEntity.reporting_period || '';
            vm.pdfParams.leType = vm.selectedEntity.le_type;
            vm.pdfParams.selectedEntityType = vm.selectedEntity.entity_type;
            vm.pdfParams.selectedConsolGroup = vm.selectedEntity.consol_group_key;
            vm.pdfParams.keyId = vm.selectedEntity.le_type === "HOO" ? vm.selectedEntity.hoo_key_id : vm.selectedEntity.key_id;
            vm.pdfParams.submission_id = vm.selectedEntity.le_type === "HOO" ? vm.selectedEntity.hoo_submission_id : vm.selectedEntity.submission_id;//vm.selectedEntity.submission_id;
            vm.pdfParams.leName = vm.selectedEntity.name;
            vm.pdfParams.returnType = vm.selectedEntity.return_type;
            vm.pdfParams.consolType = vm.selectedEntity.consol_type;
            vm.pdfParams.sourceSystemFlag = vm.selectedEntity.source_system_flag || 'D';
            // Added for entity tax year in place of user selected year
           // vm.pdfParams.taxYear = vm.taxYear.label;
           vm.pdfParams.taxYear = vm.selectedEntity.tax_year;
           // vm.pdfParams.scenarioLocal = vm.scenario.scenario;
           vm.pdfParams.scenarioLocal = vm.selectedEntity.scenario;
        }
        
        function updateS3Status() {
            vm.disableS3UpdateStatus = true;
            FormViewFactory.updateS3Status(vm.taxYear.label,vm.selectedEntity.leid, vm.selectedEntity.le_type, vm.pdfParams.keyId, vm.pdfParams.submission_id)
                    .then(function(response){
                        vm.disableS3UpdateStatus = false;
                        if(response.data.callSuccess === "1") {
                            AlertService.add("success", response.data.message,3000);
                        }
                    });
        }
        
        function getProccessList() {
            FormViewFactory.getDomProcessList()
                    .then(function(response){
                        alert(JSON.stringify(response.data));
                    });
        }

        function getForms (entity, isAutoReload) {
            vm.pdfs = [];
            vm.enableMerge = false;
            vm.isSelectAll = false;
            vm.loadingForms = true;
            vm.noFormsMsg = '';
            FormViewFactory.getTaxForms(
                vm.leType === 'BSLA' ? entity.me_label : entity.leid, entity.reporting_period, entity.le_type || 'HO', entity.cdr_no
                , entity.source_system_flag || 'D', entity.return_type === '1065' ? 'P' : 'C', (vm.treeType === 'BSLA' ? vm.bslaType : entity.entity_type) || ''
                , entity.consol_group_key, entity.tax_year, entity.scenario, entity.name,entity.path, vm.SRC, entity.consol_group_key, 'GTW'
                , entity.le_type === "HOO" ? entity.hoo_key_id :entity.key_id
                , entity.le_type === "HOO" ? entity.hoo_submission_id : entity.submission_id, entity.return_type || '',entity.consol_type || '', isAutoReload,vm.JCD_KEY
                ).then(function(response) {
                    vm.loadingForms = false;
                    if(response.data.callSuccess === "1" && response.data.message.length > 0) {
                        vm.noFormsMsg = response.data.message;
                        // AlertService.add('warning', response.data.message, 3000);
                        return;
                    }
                    if(isAutoReload !== 'Y')
                        vm.expandPdfView = true;
                    vm.showSelectAll = vm.showMerge = true;
                    vm.updateS3Status = SERVER_CONFIG.clientConfig.pdf_s3_generation_sso_list.includes(USER_SETTINGS.user.sso_id);
                    if(response.data.taxForms && response.data.taxForms.length === 0){
                        vm.noFormsMsg = 'No Forms Available';
                        return;
                    }
                    var formTypes = JSON.parse(response.data.taxForms[0].jsonString);
                    vm.noFormsMsg = '';
                    vm.selectedEntity.batchId = response.data.batchId;
                    formTypes.forEach((formType) => {
                        const typeName = Object.keys(formType)[0];
                        const subTypes = formType[typeName];
                        subTypes.forEach((subType) => {
                            const forms = subType['attachment_form'];
                            forms.forEach((form) => {
                                form.checked = false;
                                form.label = form.irs_form && form.bucket_sub !== '5471' ? form.irs_form + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form_name : (form.form_name.toLowerCase().includes('schedule') ? form.form_name.replace('Schedule', '-') : form.form_name);
                                // form.label = form.label.toLowerCase().startsWith('f') ? form.label.substring(1) : (form.label.toLowerCase().startsWith('irs') ? form.label.substring(3) : form.label);
                                form.label = form.subform && form.form_sub_cat === 'DIFF_PDF' ? form.label + '-' + form.subform : form.label;
                                form.le_name = vm.leName;
                                form.ein = vm.leEin;
                                form.form_sub_cat === 'SAME_PDF' && (form.subform = null);
                                form.form_order = Number(form.form_order);
                                vm.pdfs.push(form);
                            })
                        });
                });
                vm.pdfs = vm.cacheStore["pdfs"] = _.chain(vm.pdfs).uniqBy('label').sortBy('form_order').value();


                if(vm.selectedEntity.consol_type === "PSHIP" && vm.pdfParams.keyId === 0){
                    //to handle key_id=0
                    let form = vm.pdfs[0];
                    
                    if(form){
                        vm.pdfParams.keyId = form.key_id;
                    }
                }
            }).catch(function(e){
                vm.loadingForms = false;
                vm.expandPdfView = true;
                vm.noFormsMsg = 'PDFs not available at this time. The Global Tax support team has been notified.';
            })
        }

        function runAction(action){
            if(action === "xml" || action === "zip"){
                downloadXMLorZIP(action);  
                return;
            }

            $scope.$broadcast("pdf:run-action",action);
        }

        function downloadXMLorZIP(type){
            vm.runningAction = true;
            FormViewFactory.downloadXMLorZIP(vm.selectedEntity.submission_id,vm.selectedEntity.key_id,vm.selectedEntity.le_type,type)
                .then(function(response) {
                    if(response.data.byteLength === 0){
                        AlertService.add('warning',"No file available to download");
                        vm.runningAction = false;
                        return;
                    }

                    let blob = new Blob([response.data], {type: "application/"+type});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    let return_type = vm.selectedEntity.return_type === "1120" ? 
                                            "Corporate" :
                                            vm.selectedEntity.return_type === "1065" ?
                                            "Partnership" : 
                                            "Extension";
                    link.download = `${vm.selectedEntity.tax_year}_${return_type}_${vm.selectedEntity.name}(${vm.selectedEntity.leid})_${vm.selectedEntity.le_type.includes('HO')? vm.selectedEntity.le_type: ""}.${type}`;
                    link.click();
                    link.remove();
                    vm.runningAction = false;
                }).catch((error) => {
                    vm.runningAction = false;
                    console.log(error);
                    AlertService.add('error', 'Something went wrong!');
                })
            
        }

        function selectForm() {
            vm.enableMerge = getSelectedPDFs().length > 1;
        };

        function getSelectedPDFs (){
            let selectedPDFs = [];
            vm.pdfs.forEach((pdf) => {
                if (pdf.checked) {
                    selectedPDFs.push(pdf);
                }
            });
            return selectedPDFs;
        };

        function selectAllPDFs() {
            vm.pdfs.forEach((pdf) => {
                if (pdf.isDisplay) {
                    pdf.checked = !vm.isSelectAll;
                }
            });
            vm.isSelectAll = !vm.isSelectAll;
            vm.enableMerge = getSelectedPDFs().length > 1;
        };

        function formFilter(pdf) {
            if (vm.formSearchTerm.length > 0) {
                pdf.isDisplay = pdf.checked || pdf.label.toString().toLowerCase().includes(vm.formSearchTerm.toString().toLowerCase());
                return pdf.isDisplay;
            }
            pdf.isDisplay = true;
            return pdf.isDisplay;
        };

        function loadPDF(pdf) {
            vm.showExpand = true;
            vm.expandPdfView = vm.isMerge = false;
            vm.isLoadPdf = true;
            vm.pdfLoaded = false;
            vm.showSelectAll = vm.showMerge = true;

            vm.selectedForm = vm.cacheStore["selectedForm"] = pdf;
            vm.pdfParams.formName = vm.selectedEntity.tax_year <= LEGACY_TAX_YEAR ? pdf.form_id : pdf.form_name;
            vm.pdfParams.wpIrsFormNo = pdf.irs_form || '';
            vm.pdfParams.s3_url = pdf.s3_url || '';
            vm.pdfParams.formSubCat = pdf.form_sub_cat;
            vm.pdfParams.formSub = pdf.subform || '';
            vm.pdfParams.parentLeId = '';//need this?
            vm.pdfParams.line = pdf.line_no || '';
            vm.pdfParams.schedule = pdf.schedule || '';
            vm.pdfParams.partNo = pdf.part_no || '';
            vm.pdfParams.ein = pdf.ein || '';
            vm.pdfParams.is_attachment = pdf.is_attachment || 0;
            vm.pdfParams.is_statement = pdf.is_whitepaper == 2 ? 1 :0 || 0;  // if is_Whitepaper = 2 then it is a statement
            vm.pdfParams.jcdKey = vm.JCD_KEY;

            if(vm.selectedEntity.consol_type === "PSHIP" && vm.pdfParams.keyId === 0){
                //to handle key_id=0
                vm.pdfParams.keyId = pdf.key_id;
            }
            
            vm.pdfParams.contSrc = vm.SRC;
            vm.pdfParams.batchId = vm.selectedEntity.batchId;
            vm.pdfParams.srcType = 'DOM';

            vm.pdfParams.mergeAll = false;
            vm.pdfParams.pdfToBeMerged = false;

            // if(vm.cacheStore['pdfBlob']){
                $scope.$broadcast('pdf:reload');
            // }
        };

        function loadMergedPDF(pdf) {
            vm.showExpand = true;
            vm.expandPdfView = vm.isMerge = false;
            vm.isLoadPdf = true;
            vm.pdfLoaded = false;
            vm.pdfParams.pdfToBeMerged = true;

            let selectedPdfs = getSelectedPDFs();

            vm.pdfParams.formName = getJoinedStringBy(selectedPdfs,vm.selectedEntity.tax_year <= LEGACY_TAX_YEAR ? "form_id":"form_name");
            vm.pdfParams.wpIrsFormNo = getJoinedStringBy(selectedPdfs,"irs_form");
            // vm.pdfParams.s3_url =  getJoinedStringBy(selectedPdfs,"s3_url");
            vm.pdfParams.formSubCat = getJoinedStringBy(selectedPdfs,"form_sub_cat");
            vm.pdfParams.formSub = getJoinedStringBy(selectedPdfs,"subform");
            vm.pdfParams.parentLeId = '';//need this?
            vm.pdfParams.line = getJoinedStringBy(selectedPdfs,"line_no");
            vm.pdfParams.schedule = getJoinedStringBy(selectedPdfs,"schedule");
            vm.pdfParams.partNo =getJoinedStringBy(selectedPdfs,"part_no"); 
            vm.pdfParams.ein = getJoinedStringBy(selectedPdfs,"ein");
            vm.pdfParams.is_attachment = getJoinedStringBy(selectedPdfs,"is_attachment");
            vm.pdfParams.jcdKey = vm.JCD_KEY;
            
            let temp = getJoinedStringBy(selectedPdfs,"is_whitepaper");
            vm.pdfParams.is_statement = _.map(temp,i=>i == "2"? "1":"0");


            vm.pdfParams.mergeAll = vm.pdfs.length === selectedPdfs.length;
            vm.pdfParams.contSrc = vm.SRC;
            vm.pdfParams.batchId = vm.selectedEntity.batchId;
            vm.pdfParams.srcType = 'DOM';

            // if(vm.cacheStore['pdfBlob']){
                $scope.$broadcast('pdf:reload');
            // }
        };


        function getJoinedStringBy(arr,prop) {
            return _.chain(arr).map(item=>item[prop] ? item[prop] : "" )/*.join(",")*/.value()
        }


        //Tree Utility Methods

        function getRecordsCount(name, parent, consolGroupKey){
            var rowsCount = 0;
            if (vm.entityTree && vm.entityTree.length > 0) {
                if (!vm.entityTree[0].noDataFlag) {
                    if (name)
                        rowsCount = vm.entityTree.filter((item) => { return (parent) ? ((item.parent === name) && item.path.includes('/ ' + parent + '/ ' + name) && (item.consol_group_key === consolGroupKey))
                            : (item.parent === name) }).length;
                    else if (vm.treeType === 'BSLA')
                        rowsCount = vm.entityTree.filter(item => vm.treeType === "BSLA" || item.is_entity).length;
                    else
                        rowsCount = vm.entityTree.filter(item => ((item.external_y_n === 'Y') || (item.cdr_no !== null
                            && item.reporting_period !== null && item.le_name !== null)) && item.is_entity).length;
                }
            }
            return rowsCount;
        };

        function filterTreeData() {
            let searchLabel;
            if (vm.leType === 'BSLA')
                searchLabel = 'me_label';
            else
                searchLabel = 'name';

            if (vm.searchTerm.length === 0) {
                vm.entityTree = angular.copy(vm.entityTreeCopy);
                vm.entityTree.forEach(node => clearHiddenFlag(node));
                return;
            }

            treeDataSet.clear();
            parentNodesAhead = vm.entityTreeCopy.filter(item => !item.is_leaf);

            for (let i = 0; i < vm.entityTreeCopy.length; i++) {
                let node = vm.entityTreeCopy[i];
                clearHiddenFlag(node);
                // if (!node.is_leaf) {
                //     parentNodesAhead.push(node);
                // }
                if ((node[searchLabel] + (node['leid'] || '') + (node['cdr_no']|| '') + (node['reporting_period']|| '')).toLowerCase().includes(vm.searchTerm.toLowerCase())) {
                    findParents(node.path, searchLabel); // add parents
                    treeDataSet.add(node); // add itself
                    if (!node.is_leaf) {
                        i = findChildren(node.lvl, i + 1); // add children and update index
                    }
                }
            }

            vm.entityTree = Array.from(treeDataSet);
        };

        function expandCollapseAll() {
            vm.expandAll = !vm.expandAll;
            if (!vm.expandAll) {
                vm.expandAllTxt = 'Expand All';
                if (vm.entityTree) {
                    vm.entityTree.forEach((row) => {
                        row.childrenHidden = true;
                        row.lvl !== 0 && (row.hidden = true);
                    });
                }
            } else {
                vm.expandAllTxt = 'Collapse All';
                if (vm.entityTree) {
                    vm.entityTree.forEach((row) => {
                        row.childrenHidden = false;
                        row.hidden = false;
                    });
                }
            }
        };

        /**
         * Collapses the tree node
         * @param {node} item 
         * @returns 
         */
        function toggleNode(item) {
            if (item.is_leaf === 1)
                return;

            let tree = vm.entityTree;
            let index = tree.indexOf(item) + 1;
            // Collapse
            if (!item.childrenHidden) {
                item.childrenHidden = true;
                for (let i = index; i < tree.length; i++) {
                    let prev = i - 1;
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else {
                        tree[i].hidden = true;
                        // tree[i].childrenHidden = false;
                    }
                    // collapse folder icon if children are hidden
                    if (tree[prev].lvl < tree[i].lvl) {
                        tree[prev].childrenHidden = true;
                    }
                }
            }
            // Expand
            else {
                item.childrenHidden = false;
                for (let i = index; i < tree.length; i++) {
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else if (tree[i].lvl - 1 === item.lvl) {
                        tree[i].hidden = false;
                    } else {
                        tree[i].hidden = true;
                    }
                }
            }
        };

        function visibleNodes(node) {
            return !node.hidden;// && !(node.is_leaf === 1 && node.is_entity === 0);
        };

        function sanatizeTree(){
            for (let index = 0; index < 4; index++) {//run n times for sanatizing n levels
                vm.entityTree = vm.entityTree.filter((item) => 
                (vm.getRecordsCount(item.name, item.parent, item.consol_group_key) > 0 || (item.is_leaf && item.is_entity)));
 
            }     
        }

        function clearHiddenFlag(node) {
            node.hidden = false;
            node.childrenHidden = false;
        }

        function findParents(path, searchLabel) {
            let nodes = path.split('/');
            if (nodes.length > 2) {
                nodes = nodes.slice(1, -1);
                nodes.forEach(node => {
                    let parentNode = parentNodesAhead.find((item) => item[searchLabel].includes(node.trim()));
                    parentNode !== undefined && (treeDataSet.add(parentNode));
                });
            }
        }

        function findChildren(parentLvl, index) {
            while (index < vm.entityTreeCopy.length) {
                let node = vm.entityTreeCopy[index];
                if (node.lvl > parentLvl) {
                    treeDataSet.add(node);
                    index++;
                } else {
                    break;
                }
            }
            return index;
        }



        /**
         * Handle Web socket Messages
         */

        function establishWebSocketConnection() {
            // establish pdf engine websocket connection
            MessageService.establishConnection(GENERAL_CONFIG.custom_api_url + WEBSOCKET_ENDPOINT, (client) => {
                MessageService.subscribeToChannelWithCallback(GENERAL_CONFIG.custom_api_url + WEBSOCKET_ENDPOINT,WEBSOCKET_CHANNEL + USER_SETTINGS.user.sso_id, function(payload) {
                    $rootScope.$broadcast("form-view:websocket-message",payload);
                },client);
            });
            
        }

        vm.cacheStore.listener && vm.cacheStore.listener();
        vm.cacheStore.listener = $rootScope.$on('form-view:websocket-message',function(ev,payload) {
            handleMessage(payload);
        })

        function handleMessage(payload) {
            var notification = JSON.parse(payload.body);

            if(vm.selectedEntity 
                && (notification.keyId + "" === vm.selectedEntity.key_id + ""
                    || notification.keyId + "" === vm.selectedEntity.hoo_key_id + "")
                && notification.leType === vm.selectedEntity.le_type 
                && (notification.submissionId + "" === vm.selectedEntity.submission_id + "" 
                    || notification.submissionId + "" === vm.selectedEntity.hoo_submission_id + "") ){
                vm.messageTxt = notification.message;
                if(notification.requestStatus === "COMPLETE"){
                    vm.messageComplete = true;
                    
                    if(vm.messageTxt.includes("New PDFs are ready")){
                        getForms(vm.selectedEntity,'Y');
    
                        if(vm.pdfLoaded){
                            vm.pdfParams.pdfToBeMerged ? loadMergedPDF() : loadPDF(vm.selectedForm);
                        }
                    }

                    vm.messageWarn = false;
                    vm.completeTimer  = $timeout(function() {
                        vm.messageComplete = false;
                        vm.messageTxt = "";
                    },20000)
                }else if(notification.requestStatus === "NO_CHANGE" || notification.requestStatus === "FAILED"){
                    vm.messageWarn = true;
                    vm.completeTimer = $timeout(function() {
                        vm.messageWarn = false;
                        vm.messageTxt = "";
                    },20000)
                }else{
                    $timeout.cancel(vm.completeTimer);
                }
            }
                
            $scope.$apply();
        }


        function openBulkExportPopUp() {
            $uibModal.open({
                templateUrl: 'app/components/formView/templates/bulk-export-modal.html',
                size: 'lg',
                controllerAs: 'ctrl',
                resolve: {
                    entityTree: function () {
                        return vm.entityTreeCopy;
                    },
                    taxYear: function() {
                        return vm.taxYear;
                    },
                    scenario: function() {
                        return vm.scenario;
                    },
                    jcdKey: function() {
                        return vm.JCD_KEY;
                    },
                    contSrc: function() {
                        return vm.SRC;
                    }
                },
                controller: ['USER_SETTINGS', 'entityTree','taxYear','scenario','jcdKey','contSrc', 'AlertService', '$uibModalInstance','FormViewFactory',
                    BulkExportModal
                ]
            });
        }

        async function createSnapshot(){
            if(!vm.selectedEntity) {
                AlertService.add("warning","Please select an entity!");
                return;
            }

            const response = await FormViewFactory.createSnapshot(vm.selectedEntity.submission_id, vm.taxYear.label, vm.scenario.scenario, vm.JCD_KEY);

            if(response.data.callSuccess === "1"){
                AlertService.add("success","Snapshot creation has been initiated.");
            }
        }

        function loadIRSId(){
            var params = {
                'action_code': 'u0pmel',
                'submission_id': vm.selectedEntity.submission_id
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                .then(function (data) {
                    if (data.jsonObject && data.jsonObject.length > 0) {                        
                        vm.irsSubmissionId = data.jsonObject[0].IRS_SUBMISSION_ID;
                    }else{
                        vm.irsSubmissionId = null;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong!');
                })
                .finally(() => {
                    
                });
        }
        function downloadXMLorZIPBySubmission(irsSubmissionId,type){
            vm.runningAction = true;
            EfileFactory.downloadSubStatusDoc(irsSubmissionId,type).then(function(response) {
                vm.runningAction = false;
                if(response.data && response.data.byteLength > 0){
                    type +='_';
                    let ext;
                    switch(type){
                        case 'xml_':
                            ext = 'xml';
                            break;
                        default:
                            type = '';
                            ext='zip';
                    }
                    let file = new Blob([response.data],{type:'application/'+ext});
                    let pdfPath = URL.createObjectURL(file);
                    var link = document.createElement('a');
                    link.href = pdfPath;
                    link.download = `${type}${irsSubmissionId}.${ext}`;
                    link.dispatchEvent(new MouseEvent('click'));
                }else{
                    AlertService.add('warning',"No file available to download");
                }
            }).catch((error) => {
                vm.runningAction = false;
                console.log(error);
                AlertService.add('error', 'Something went wrong!');
            })
        }

        if (!window.location.origin.includes("localhost")) {
            establishWebSocketConnection();
        }

        // setTimeout(function() {
        //     handleMessage({body:'{"leType":"HOO","submissionId":"5377","keyId":"1567749","message":"New PDFs are ready!","entity":"1567749","requestStatus":"COMPLETE","status":"success"}'});
            
        // },10000);
 	}

    function BulkExportModal(USER_SETTINGS, entityTree,taxYear,scenario,jcdKey,contSrc, AlertService, $uibModalInstance,FormViewFactory) {

        let vm = this;
        let treeDataSet = new Set;
       
        let parentNodesAhead = [];

        //Theme Init
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        vm.PDFformats = [
            { format: "PDF", type: "PDF_FORM", name: "Generate PDFs by form", selected: true },
            { format: "PDF", type: "PDF_ENTITY", name: "Generate PDFs by Entity", selected: false },
            { format: "PDF", type: "PDF_SINGLE", name: "Merge all PDFs into One PDF", selected: false },
        ];
        
        vm.XMLformats = [
            { format: "XML", type: "XML_ENTITY", name: "Generate XML by Entity", selected: false }
        ];
        vm.ZIPformats = [
            { format: "ZIP", type: "ZIP_ENTITY", name: "Generate ZIP by Entity", selected: false }
        ];

        vm.groupList = _.filter(entityTree, function (item) {
            if (contSrc === "STATE") {
                return item.lvl === 0;
            } else {
                return (item.name !== "Corporation") && ((item.is_entity === 0 && item.consol_group_key === null && item.lvl <= 1) || (item.is_entity === 0 && item.consol_group_key !== null && item.lvl === 1));
            }
        })
        vm.downloadAllForms = false;
        vm.entityTreeCopy = angular.copy(entityTree);
        vm.selectedGroup = vm.groupList[0];
        vm.entityTree = filterTreeByGroup(vm.selectedGroup);
        
        
        loadForms();

        //Business methods
        vm.filterTreeByGroup = filterTreeByGroup;
        vm.selectEntity = selectEntity;
        vm.loadForms = loadForms;
        vm.bulkExport = bulkExport;
        vm.enableDisableExport = enableDisableExport;
        vm.selectFormat =selectFormat;
        vm.selectForm = selectForm;
        vm.selectAllForm = selectAllForm;
        vm.close = close;

        //filterFormTreeByGroup(vm.selectForm);
       

        //Tree Utility Methods
        vm.toggleNode = toggleNode;
        vm.visibleNodes = visibleNodes;
        vm.getRecordsCount = getRecordsCount;
        vm.expandCollapseAll = expandCollapseAll;
        vm.filterTreeData = filterTreeData;
        vm.searchForm=searchForm;
        //Business methods

        function close(){
            $uibModalInstance.close();
        }

        
        function selectFormat(selectedFormat){
            for (let i = 0; i < vm.PDFformats.length; i++) {
                const format = vm.PDFformats[i];
                if(format.type === selectedFormat.type) continue;
                format.selected = false;
            }

            for (let i = 0; i < vm.XMLformats.length; i++) {
                const format = vm.XMLformats[i];
                if(format.type === selectedFormat.type) continue;
                format.selected = false;
            }

            for (let i = 0; i < vm.ZIPformats.length; i++) {
                const format = vm.ZIPformats[i];
                if(format.type === selectedFormat.type) continue;
                format.selected = false;
            }
            

            selectedFormat.selected = !selectedFormat.selected;
        }
        function filterTreeByGroup(group){
            let tree =  _.filter(vm.entityTreeCopy,(item) => item.path.includes(group.name));
            vm.entityGroupTreeCopy=angular.copy(tree);
            vm.returnType = _.find(tree,["is_entity",1]).return_type;
            return tree;
        }

       
        function selectEntity(item, $event) {
            item.selected = !item.selected;
            if (!item.is_leaf) {
                const index = vm.entityTree.indexOf(item);
                for (let i = index + 1; i < vm.entityTree.length; i++) {
                    if (vm.entityTree[i].lvl <= item.lvl) {
                        break;
                    }
                    vm.entityTree[i].selected = item.selected;
                }
            }
            enableDisableExport();
            $event.preventDefault();
        };

        /*
            This method is to perform search operation at bulk exports forms
        */
        function searchForm() {
             const labelKey = 'form';
             treeDataSet.clear();
             parentNodesAhead = [];
           
             if (vm.formSearchTerm.length === 0) {
                 vm.forms = angular.copy(vm.Allforms);
                 return;
             }

             for (let i = 0; i < vm.Allforms.length; i++) {
                 let node = vm.Allforms[i];
                 if (!node.is_leaf) {
                     parentNodesAhead.push(node);
                 }
                 if (node[labelKey].toLowerCase().includes(vm.formSearchTerm.toLowerCase())) {

                     findParents(node.path, labelKey); // add parents
                     node.hidden = false; // reveal the item after search
                     treeDataSet.add(node); // add itself
                     if (!node.is_leaf) {
                         i = findFormChildren(vm.Allforms, node.lvl, i + 1); // add children and update index
                     }
                 }
             }
            
             vm.forms = Array.from(treeDataSet);

         };
    

        function loadForms(){
            vm.formLoading = true;
            vm.foreignFormsDisplay = true;
            FormViewFactory.getBulkExportForms(taxYear.value,vm.returnType,'','','').then((response)=>{
                vm.formLoading = false;
                if (response.data.callSuccess === '1') {
                    vm.Allforms  = response.data.viewList;

                    vm.Allforms.forEach((form) => {
                        if (form.is_whitepaper) {
                            form.label = form.irs_form_no + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form;
                        } else {
                            form.label = form.form_sub && form.form_sub_cat === 'DIFF_PDF' ? ((form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form) + '_' + form.form_sub) :
                                (form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form);
                            form.form_sub_cat === 'SAME_PDF' && (form.form_sub = null);
                        }
                        form.hidden = form.lvl > 1;
                        form.childrenHidden = form.lvl === 1;
                        if (form.path.includes('Corporate Domestic')) {
                            form.type = 'D';
                        } else if (form.path.includes('Corporate Foreign')) {
                            form.type = 'F';
                        }
                    });
                    vm.Allforms =  _.chain(vm.Allforms).uniqBy('label').value();
                    vm.forms = angular.copy(vm.Allforms);
                }
            })
        }
        function selectForm(item, $event) {
            item.selected = !item.selected;

            if (!item.is_leaf) {
                const index = vm.forms.indexOf(item);
                for (let i = index + 1; i < vm.forms.length; i++) {
                    if (vm.forms[i].lvl <= item.lvl) {
                        break;
                    }
                    vm.forms[i].selected = item.selected;
                }
            }
        }
        function selectAllForm() {
                const index = 0;
                for (let i = index; i < vm.forms.length; i++) {
                    vm.forms[i].selected = vm.downloadAllForms;
                }
        }

        function bulkExport(){
            let selectedEntities = angular.copy(vm.entityTree.filter(item => item.selected && item.is_leaf));
           
            let selectedPDFFormat = vm.PDFformats.find(item => item.selected);
            let format =[],mode = [];
            if(selectedPDFFormat){
                mode.push(selectedPDFFormat.type);
                format.push(selectedPDFFormat.format);
            } 
            let selectedXMLFormat = vm.XMLformats.find(item => item.selected);
            if(selectedXMLFormat){
                mode.push(selectedXMLFormat.type);
                format.push(selectedXMLFormat.format);
            } 
            let selectedZIPFormat = vm.ZIPformats.find(item => item.selected);
            if(selectedZIPFormat){
                mode.push(selectedZIPFormat.type);
                format.push(selectedZIPFormat.format);
            } 
            let selectedForms = vm.downloadAllForms || format[0] !== "PDF" ? [] : vm.forms.filter(item => item.selected && item.is_leaf);
            let forms = [];
            selectedForms.forEach((form) => {
                let formObj = {};
                formObj.form_sub = form.form_sub ? (vm.consolGroup.VALUE === 3 ?form.form_sub.split('_')[0] : form.form_sub ) : ''; // removes reporting period
                formObj.irs_form = form.subform && form.form_sub_cat === 'DIFF_PDF' ? form.form + '_' + formObj.form_sub : form.form;
                formObj.form_sub_cat = form.form_sub_cat;
                formObj.wpirs_form_no = form.is_whitepaper ? (form.irs_form_no.toLowerCase().startsWith('f') ? form.irs_form_no.substring(1)
                    : (form.irs_form_no.toLowerCase().startsWith('irs') ? form.irs_form_no.substring(3) : form.irs_form_no)) : ''; //removing F and IRS from formName
                formObj.schedule = form.schedule;
                formObj.line = form.line_no;
                formObj.part_no = form.part_no || 'NA';
                formObj.is_whitepaper = form.is_whitepaper;
                forms.push(formObj);
            });

            let payload = {
                entities: selectedEntities,
                forms: forms
            }
            vm.exportLoading = true;
            FormViewFactory.pdfsBulkExport(taxYear.value,scenario.scenario,jcdKey,format.join(','),mode.join(','),contSrc,payload).then(function(response) {
                vm.exportLoading = false;
                if(response.data.callSuccess === "1"){
                    AlertService.add("success","Bulk export request initiated successfully! Please go to PDF Bulk Export Screen to download the export when ready.");
                }else{
                    AlertService.add("error","Something went wrong!");
                }
                
            },function(e){
                vm.exportLoading = false;
                AlertService.add("error","Something went wrong!");
                
            })
        }

        function enableDisableExport() {
            const selectedEntities = vm.entityTree.filter(item => item.selected && item.is_leaf);
            const pdfFormatSelectedCount = vm.PDFformats.filter(item => item.selected);
            if (selectedEntities.length == 0 || pdfFormatSelectedCount.length == 0 )
                vm.exportDisable = true;
            else
                vm.exportDisable = false;
        }
        //Tree Utility Methods

        function getRecordsCount(type, parent){
            var rowsCount = 0;
            var treeData = (type == "forms") ? vm.forms : vm.entityTree;
            if (treeData.length > 0) {
                if (!treeData[0].noDataFlag) {
                    rowsCount = treeData.filter(item => item.parent === parent).length;
                }
            }
            return rowsCount;
        };

        function filterTreeData() {
            let searchLabel = 'name';

            if (vm.searchTerm.length === 0) {
                 vm.entityTree = angular.copy(vm.entityGroupTreeCopy);
                 // vm.entityTree = angular.copy(vm.entityTreeCopy);
                vm.entityTree.forEach(node => clearHiddenFlag(node));
                return;
            }

            treeDataSet.clear();
             parentNodesAhead = vm.entityGroupTreeCopy.filter(item => !item.is_leaf);

             for (let i = 0; i < vm.entityGroupTreeCopy.length; i++) {

                 let node = vm.entityGroupTreeCopy[i];
                clearHiddenFlag(node);
                // if (!node.is_leaf) {
                //     parentNodesAhead.push(node);
                // }
                if ((node[searchLabel] + (node['leid'] || '') + (node['cdr_no']|| '') + (node['reporting_period']|| '')).toLowerCase().includes(vm.searchTerm.toLowerCase())) {
                    findParents(node.path, searchLabel); // add parents
                    treeDataSet.add(node); // add itself
                    if (!node.is_leaf) {
                        i = findChildren(node.lvl, i + 1); // add children and update index
                    }
                }
            }

            vm.entityTree = Array.from(treeDataSet);
        };

        function expandCollapseAll() {
            vm.expandAll = !vm.expandAll;
            if (!vm.expandAll) {
                vm.expandAllTxt = 'Expand All';
                if (vm.entityTree) {
                    vm.entityTree.forEach((row) => {
                        row.childrenHidden = true;
                        row.lvl !== 0 && (row.hidden = true);
                    });
                }
            } else {
                vm.expandAllTxt = 'Collapse All';
                if (vm.entityTree) {
                    vm.entityTree.forEach((row) => {
                        row.childrenHidden = false;
                        row.hidden = false;
                    });
                }
            }
        };

        /**
         * Collapses the tree node
         * @param {node} item 
         * @returns 
         */
        function toggleNode(tree,item) {
            if (item.is_leaf === 1)
                return;

            // let tree = tree;
            let index = tree.indexOf(item) + 1;
            // Collapse
            if (!item.childrenHidden) {
                item.childrenHidden = true;
                for (let i = index; i < tree.length; i++) {
                    let prev = i - 1;
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else {
                        tree[i].hidden = true;
                        // tree[i].childrenHidden = false;
                    }
                    // collapse folder icon if children are hidden
                    if (tree[prev].lvl < tree[i].lvl) {
                        tree[prev].childrenHidden = true;
                    }
                }
            }
            // Expand
            else {
                item.childrenHidden = false;
                for (let i = index; i < tree.length; i++) {
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else if (tree[i].lvl - 1 === item.lvl) {
                        tree[i].hidden = false;
                    } else {
                        tree[i].hidden = true;
                    }
                }
            }
        };

        function visibleNodes(node) {
            return !node.hidden;// && !(node.is_leaf === 1 && node.is_entity === 0);
        };

        function clearHiddenFlag(node) {
            node.hidden = false;
            node.childrenHidden = false;
        }

        function findParents(path, searchLabel) {
            let nodes = path.split('/');
            if (nodes.length > 2) {
                nodes = nodes.slice(1, -1);
                nodes.forEach(node => {
                    let parentNode = parentNodesAhead.find((item) => item[searchLabel].includes(node.trim()));
                    parentNode !== undefined && (treeDataSet.add(parentNode));
                });
            }
        }

        function findChildren(parentLvl, index) {
            while (index < vm.entityGroupTreeCopy.length) {
                let node = vm.entityGroupTreeCopy[index];
                if (node.lvl > parentLvl) {
                    treeDataSet.add(node);
                    index++;
                } else {
                    break;
                }
            }
            return index;
        }

        function findFormChildren(treeData, parentLvl, index) {
            while (index < treeData.length) {
                let node = treeData[index];
                if (node.lvl > parentLvl) {
                    node.hidden = false;
                    treeDataSet.add(node);
                    index++;
                } else {
                    break;
                }
            }
            return index;
        }
    }
    
    function ViewBulkExportEntitySelectionCtrl($rootScope, FormViewFactory, rowData, USER_SETTINGS) {
        var vm = this;

        vm.requestedType = 'Entities';

        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });

        vm.listFiltered = [];

        vm.options = {
            extraOptions: {
                componentSettings: {
                    tableOptions: {
                        searching: true,
                        columns: [
                            {
                                data: 'ENTITY',
                                title: 'Entity',
                                width: 120
                            },
                            {
                                data: 'CDR_NO',
                                title: 'Cdr No',
                                width: 100
                            },
                            {
                                data: 'REPORTING_PERIOD',
                                title: 'Reporting Period',
                                width: 100
                            },
                        ],
                        language: {
                            emptyTable: "No Selected Entities were found.",
                        },
                        initCallBack: () => {
                            vm.listLoading = false;
                        }
                    }
                }
            }
        }

        function loadDetails() {
            vm.listLoading = vm.dataLoading = true;
            FormViewFactory.getRequestDetails(rowData.REQUEST_ID, 'ENTITY').then(function (response) {
                vm.listFiltered = response.data.jsonObject;
                vm.options.data = response.data.jsonObject;
                vm.dataLoading = false;
            })
        }

        loadDetails();

    }
    
    function ViewBulkExportSelectionCtrl($rootScope, FormViewFactory, rowData, USER_SETTINGS) {
        var vm = this;

        vm.requestedType = 'Forms';

        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });

        vm.listFiltered = [];

        vm.options = {
            extraOptions: {
                componentSettings: {
                    tableOptions: {
                        searching: true,
                        columns: [
                            {
                                data: 'IRS_FORM_NO', 
                                title: 'Irs Form No' 
                            },
                            {
                                data: 'WPIRS_FORM_NO', 
                                title: 'Parent Form Name' 
                            },
                            {
                                data: 'SCHEDULE', 
                                title: 'Schedule' 
                            },
                            {
                                data: 'LINE', 
                                title: 'Line' 
                            },
                        ],
                        language: {
                            emptyTable: "No Selected Forms were found.",
                        },
                        initCallBack: () => {
                            vm.listLoading = false;
                        }
                    }
                }
            }
        }

        function loadDetails() {
            vm.listLoading = vm.dataLoading = true;
            FormViewFactory.getRequestDetails(rowData.REQUEST_ID, 'FORM').then(function (response) {
                vm.listFiltered = response.data.jsonObject;
                vm.options.data = response.data.jsonObject;
                vm.dataLoading = false;
            })
        }

        loadDetails();

    }

    function S3TemplateView($scope,$rootScope, GENERAL_CONFIG, rowData, USER_SETTINGS, $http, $sce, FormViewFactory,AlertService,workspaceFactory,$uibModalInstance,JsonObjectFactory) {

        var vm = this;
        vm.formlist = {};
        vm.formlist.selected = [];
        vm.formlist.name = "forms";
        vm.formlist.values = [];
        vm.dropdownSettings = {
            selectionLimit: 1,
            enableSearch:true,
            displayProp: 'name',
            idProperty: 'value',
            scrollable: true,
            closeOnSelect:true
        };
        let url = null; let blob = null;
        vm.showCommentsToggle = true;
        vm.showDetails = true;
        vm.tax_year = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        // rowData = null;

        $scope.$on('$locationChangeSuccess',
        function (event, toUrl, toParams, fromState, fromParams) {
            if (toUrl.includes('popup')) {
                
                const paramsStr = toUrl.split('?')[1].split('&');
                let params = {};
                paramsStr.forEach((param) => {
                    let keyValues = param.split('=');
                    params[keyValues[0]] = keyValues[1] === 'null' ? '' : keyValues[1];
                });
                var getDDRecordParams = {
                    'tax_year': rowData.TAX_YEAR,
                    'irs_form_no': rowData.IRS_FORM_NO,
                    'pdf_field_name': decodeURIComponent(params.pdf_field_name),
                    'action_code': '3ktoss'
                };


                vm.loadingDD = true;
                vm.showDetails = true;
                vm.showComments = false;
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', getDDRecordParams).then(function (res) {
                    vm.loadingDD = false;
                    vm.details = res.jsonObject[0];
                    
                }, (e) => {
                    vm.loadingDD = false;
                    console.error(e);
                })

            }
        });
        function getBucketRecordActions() {
            var Params = {
                'action_key': '32687',
                'action_code': 'sikgs1',
            };
            vm.loadingDD = true;
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', Params).then(function (res) {
                vm.formlist.values = res.jsonObject.map((value) => { return { 'name': value.FORM, 'value': value.FORM } });
            }, (e) => {
                console.error(e);
            })
        }

        function getAllComments() {
            var Params = {
                'sso_id': USER_SETTINGS.user.sso_id,
                'tax_year': rowData.TAX_YEAR,
                'irs_form_no': rowData.IRS_FORM_NO,
                'action_code': '3rsj53'
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', Params).then(function (res) {
                vm.allcomments = res.jsonObject;
                
            }, (e) => {
                console.error(e);
            })
        }

        vm.exportToExcel = function() {
            vm.downloading = true;
            FormViewFactory.exportMappingsToExcel(rowData.TAX_YEAR,rowData.IRS_FORM_NO).then(function(response) {
                vm.downloading = false;
                let blob = new Blob([response.data], { type: "application/octet-stream" });

                const a = document.createElement('a');
                a.href=URL.createObjectURL(blob);
                a.download = rowData.TAX_YEAR + "_" + rowData.IRS_FORM_NO + '_mapping.xlsx';
                a.click();
            })
        }

        vm.upload = function() {
            let uploadData = rowData || {
                TAX_YEAR:vm.tax_year,
                IRS_FORM_NO: vm.formlist.selected[0].value,
                FILE_KEY:''
            }
            FormViewFactory.uploadPdf(uploadData.TAX_YEAR,uploadData.IRS_FORM_NO,uploadData.FILE_KEY,vm.comment,vm.pdfFile).then(function(response) {
                AlertService.add("success","File Uploaded Successfully");
                if(!vm.newUpload)
                    loadTemplate();
                else{
                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                    $uibModalInstance.close();
                }

            },(e)=>{
                AlertService.add("error","Something went wrong!");
                console.error(e);
            })
        }
        if(!rowData){
            vm.newUpload = true;
            vm.showCommentsToggle = false;
            vm.showDetails = true;
            vm.showComments = true;
            vm.comment = 'Inital file upload';
            getBucketRecordActions();
            return;
        }

        loadTemplate();

        function loadTemplate() {
            vm.loadingForm = true;
            vm.showDetails = false;
            vm.showComments = false;
            vm.comment = '';
            vm.url = null;
            FormViewFactory.getFormTemplate(rowData).then(function (response) {
                vm.loadingForm = false;
                let blob = new Blob([response.data], { type: "application/pdf" });
                vm.url = $sce.trustAsResourceUrl(URL.createObjectURL(blob));
                getAllComments();

                console.log(vm.url);
            }, (e) => {
                vm.loadingForm = false;
                getAllComments();
                console.error(e);
            });
        }


        vm.downloadTemplate = function() {
            vm.downloading = true;
            FormViewFactory.getFormTemplate({...rowData,isDownloadRequest:'Y'}).then(function (response) {
                vm.downloading = false;
                let blob = new Blob([response.data], { type: "application/pdf" });

                const a = document.createElement('a');
                a.href=URL.createObjectURL(blob);
                a.download = rowData.TAX_YEAR + "_" + rowData.IRS_FORM_NO + '_template.pdf';
                a.click();

            }, (e) => {
                vm.downloading = false;
            });
        }



    }
 });
