define(['angular'], function () {
    'use strict';
    const directives = angular.module('app.projectReviewDirective', [])
                              .directive('gtProjectReview', gtProjectReview);

    function gtProjectReview() {
        const directive = {
            restrict: 'A',
            scope: {
                options: '='
            },
            templateUrl: 'app/components/dashboardExecutive/templates/project-review-component-tpl.html',
            link: link,
            controller: DashboardProjectReviewCtrl,
            controllerAs: 'ctrl',
            bindToController: true
        };

        function link(scope, element, attrs) {

        }

        DashboardProjectReviewCtrl.$inject = ['$rootScope', '$scope', 'GENERAL_CONFIG', 'GlobalService', 'AlertService', 'dashboardFactory', 'workspaceFactory', 'MessageFactory', 'ProjectReviewFactory'];

        function DashboardProjectReviewCtrl($rootScope, $scope, GENERAL_CONFIG, GlobalService, AlertService, dashboardFactory, workspaceFactory, MessageFactory, ProjectReviewFactory) {
            const vm = this;
            const dashboard_id = 'project_review';
            const dashboard_title = 'Project Review';
            const data_sheets = [];
            const dashboard = dashboardFactory.createDashboardOld(dashboard_title, dashboard_id, data_sheets);
            const activeScreenLocal = workspaceFactory.activeScreen;
            let screenDataCache = activeScreenLocal.data || {};
            // vm.selectedFilterTaxYear = activeScreenLocal.filters.filterParams.tax_year;
            vm.selectedFilter = activeScreenLocal.filters.filterParams;
            vm.tableIndex = screenDataCache.tableIndex || 0;
            vm.menuOpen = screenDataCache.menuOpen || true;
            vm.projectList = screenDataCache.projectList || [];
            vm.selectedProject = screenDataCache.selectedProject;
            vm.transByStepData = screenDataCache.transByStepData || [];
            vm.transData = screenDataCache.transData || [];
            vm.transactionStepParentTable = {};
            vm.transactionDataParentTable = {};
            vm.dtInstance = {};
            vm.dtInstanceTransaction = {};

            vm.destroyDashboard = function () {
                dashboard.destroy(dashboard_id);
            };

            vm.clearData = function () {
                dashboard.clearData();
            };

            vm.reloadData = function () {
                dashboard.reloadData();
            };

            vm.dataLoaded = function () {
                transactionTableInit(vm.transByStepData, vm.transData);
            };

            $rootScope.$on('dashboard:loaded', function (event, data) {
                vm.dataLoaded();
            });

            if (screenDataCache.hasTableData) {
                vm.dataLoaded();
            }

            function transactionTableInit(stepData, transData) {
                const transactionStepTableColConf = [
                    {
                        data: null,
                        orderable: false,
                        defaultContent: '',
                        uneditable: true,
                        className: 'details-control',
                        fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
                            if (sData.has_transaction !== 'true')
                                $(nTd).removeClass('details-control');
                        }
                    },
                    {title: 'Step Number', data: 'step_label', width: 100, className: 'data-column'},
                    {title: 'Step Name', data: 'step_name', className: 'data-column'},
                    {title: 'Step Description', data: 'step_description', className: 'data-column'}
                ];
                const transactionDataTableColConf = [
                    {title: 'Leid/CDR No/Rep Pd', data: 'entity_details', width: 70, className: 'data-column'},
                    {title: 'Entity Name', data: 'le_name', width: 200, className: 'data-column'},
                    {title: 'BSLA', data: 'me_code', width: 40, className: 'data-column text-center'},
                    {title: 'Source', data: 'source_system_flag', width: 40, className: 'data-column text-center'},
                    {title: 'Adj. Desc', data: 'adj_desc', width: 200, className: 'data-column'},
                    {title: 'Usr. Desc', data: 'user_desc', width: 200, className: 'data-column'},
                    {title: 'Curr.', data: 'curr', width: 30, className: 'data-column text-center'},
                    {title: 'FG', data: 'consol_group_key', width: 10, className: 'data-column text-center'},
                    {
                        title: 'Amount - Incr/ (Decr)', data: 'amount', width: 50, className: 'data-column', render: function (data, type) {
                            if (type !== 'display') return data;
                            if (data === 0) return '';
                            // add thousands separators
                            let dataToShow = Math.abs(data).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            let showInRed = '';
                            if (data < 0) {
                                showInRed = 'style="color: red;"';
                                dataToShow = '(' + dataToShow + ')';
                            }
                            return `<span ${showInRed} class="datatable-revenue">${dataToShow}</span>`;
                        }
                    },
                    {
                        title: 'Docs', data: 'doc_key', width: 20, className: 'data-column', render: function (data, type, row) {
                            if (type === 'display') {
                                const template = `<span class="doc-icon text-right">
                                                    <i class="fa fa-paperclip doc-download"></i>
                                                  </span>`;
                                return row.source_system_flag !== 'DST' && row.doc_key ? template : '';
                            }
                        },
                        actions: [
                            {
                                event: 'click', targetSelector: '.doc-download', callback: function (rowData, event) {
                                    const docKeys = rowData.doc_key.split(',');
                                    docKeys.forEach((docKey) => {
                                        MessageFactory.downloadMsgAttachment(undefined, undefined, docKey);
                                    });
                                }
                            }
                        ]
                    },
                    {
                        title: 'DST',
                        data: 'statement_name',
                        width: 300,
                        className: 'data-column text-link',
                        render: function (data, type, row) {
                            return !data ? '' : `<a href="#/statement-tracker/${vm.selectedFilter.tax_year}/${row.parent_statement_id || row.doc_key}${row.parent_statement_id? "/"+row.doc_key: ''}">§&nbsp;${data}</a>`;
                        }
                    }
                ];
                const transactionStepTableOptions = {
                    order: [[1, 'asc']],
                    columns: transactionStepTableColConf,
                    paging: false
                };
                const transactionDataTableOptions = {
                    order: [[1, 'asc']],
                    columns: transactionDataTableColConf,
                    columnDefs: [
                        {
                            targets: 8,
                            type: 'num'
                        }
                    ],
                    paging: false
                };
                const transactionChildDataTableOptions = {
                    order: [[1, 'asc']],
                    columns: transactionDataTableColConf,
                    columnDefs: [
                        {
                            targets: 8,
                            type: 'num'
                        }
                    ],
                    isAjax: true,
                    ajax: {
                        url: `${GENERAL_CONFIG.custom_api_url}/projectReview/getTransactionDataForProjectStep?project_id={project_id}&project_step_id={project_step_id}&tax_year=${vm.selectedFilter.tax_year}&scenario=${vm.selectedFilter.scenario}&filingKey=${vm.selectedFilter.filing_key}&parent_me_string=${vm.selectedFilter.parent_me_string}`,
                        dataProp: 'transactionData'
                    }
                };

                // configure directive settings
                vm.transactionStepParentTable.options = {
                    data: stepData,
                    extraOptions: {
                        componentSettings: {
                            // childDataProp: 'transactionDataList',
                            tableOptions: transactionStepTableOptions,
                            showChildRows: true,
                            childTableOptions: transactionChildDataTableOptions
                        }
                    }
                }

                // configure directive settings
                vm.transactionDataParentTable.options = {
                    data: transData,
                    extraOptions: {
                        componentSettings: {
                            tableOptions: transactionDataTableOptions
                        }
                    },
                }
            }

            vm.loadProjects = function () {
                if (vm.projectList.length > 0) return;
                vm.projectListLoading = true;

                ProjectReviewFactory.getProjectsByTaxYear(activeScreenLocal.filters.filterParams.tax_year)
                                    .then((response) => {
                                        screenDataCache.projectList = response.data.ProjectsByTaxyear;
                                        screenDataCache.projectList.forEach((project) => {
                                            if (project.project_name) {
                                                const words = project.project_name.split(' ');
                                                let initials = [];
                                                for (let i = 0; i < words.length && initials.length < 2; i++) {
                                                    if (isNaN(words[i])) {
                                                        initials.push(words[i]);
                                                    }
                                                }
                                                // pick the first two letters as initials if there's only one word
                                                if (initials.length === 1) {
                                                    project.label = initials[0].replace(/\d/g, '').substring(0, 2);
                                                } else {
                                                    project.label = initials[0].replace(/\d/g, '')[0] + initials[1].replace(/\d/g, '')[0];
                                                }
                                            }
                                        });
                                        vm.projectList = screenDataCache.projectList;
                                        workspaceFactory.setCache(activeScreenLocal, screenDataCache);
                                    })
                                    .finally(() => {
                                        vm.projectListLoading = false;
                                    });
            }();

            vm.toggleMenu = function () {
                vm.menuOpen = !vm.menuOpen;
                screenDataCache.menuOpen = vm.menuOpen;
                workspaceFactory.setCache(activeScreenLocal, screenDataCache);
            };

            vm.selectProject = function (project) {
                if (vm.tableLoading) return;
                vm.selectedProject = screenDataCache.selectedProject = project;
                workspaceFactory.setCache(activeScreenLocal, screenDataCache);
                vm.options.title = dashboard_title + ' > ' + vm.selectedProject.project_name;
                vm.projectList.forEach((project) => {
                    project.selected = false;
                });
                project.selected = true;
                vm.loadTableData();
            };

            vm.projectFilter = function (project) {
                if (vm.projectSearchTerm) {
                    return project.project_name.toLowerCase().includes(vm.projectSearchTerm.toLowerCase().trim());
                }
                return true;
            };

            vm.selectTab = function (tabIndex) {
                if (vm.tableLoading) return;
                vm.tableIndex = screenDataCache.tableIndex = tabIndex;
                workspaceFactory.setCache(activeScreenLocal, screenDataCache);
                vm.selectedProject && vm.loadTableData();
            };

            vm.loadTableData = function () {
                vm.tableLoading = true;
                if (vm.tableIndex === 0) {
                    vm.transData = null;
                    ProjectReviewFactory.getProjectSteps(vm.selectedProject.project_id, activeScreenLocal.filters.filterParams)
                                        .then((response) => {
                                            vm.transByStepData = screenDataCache.transByStepData = response.data.projectSteps;
                                            screenDataCache.hasTableData = true;
                                            workspaceFactory.setCache(activeScreenLocal, screenDataCache);
                                            vm.dataLoaded();
                                            vm.tableLoading = false;
                                        });
                } else if (vm.tableIndex === 1) {
                    vm.transByStepData = null;
                    ProjectReviewFactory.getTransactionData(vm.selectedProject.project_id, activeScreenLocal.filters.filterParams)
                                        .then((response) => {
                                            vm.transData = screenDataCache.transData = response.data.transactionData;
                                            screenDataCache.hasTableData = true;
                                            workspaceFactory.setCache(activeScreenLocal, screenDataCache);
                                            vm.dataLoaded();
                                            vm.tableLoading = false;
                                        });
                }
            };
        }

        return directive;
    }
});
