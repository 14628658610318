
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.genericFormScreenService',[])

        .factory("GenericFormServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope) {
            var genericFormServicesFactory = {};
            var URLS = {
                getFormScreenJson: GENERAL_CONFIG.base_url + '/getFormProformaWithData'
            }
         genericFormServicesFactory.getFormScreenData = function(data){
            	console.log("Request Payload ::",data);
            	var promise = $http({
                    method: "POST",
                    url: URLS.getFormScreenJson,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            }
            
            return genericFormServicesFactory;
        }])

    return services;
});