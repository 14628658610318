define([
    'angular',
    'vsRepeat',
    './formViewTabCtrl',
    './formViewTabService'
], function () {
    'use strict';
    return angular.module('app.formViewTab', ['app.formViewTabCtrl','app.formViewTabServices'])

        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
            $stateProvider
                // .state('workspace.newproformagtw', {
                //     //parent: 'workspace.newproformagtw',
                //     // redirectTo: 'newproformagtw.gtwview12', 
                //     url: '/newproformagtw',
                //     templateUrl: 'app/components/newProforma/newProformaGtw/index.html',
                //     api:{},

                // })
                .state('form-view', {
                    url: '/form-view',
                    templateUrl: 'app/components/formView/tabWrapper/index.html',
                    access: ""
                })
                .state('state-form-view', {
                    url: '/state-form-view',
                    templateUrl: 'app/components/formView/tabWrapper/index.html',
                    access: ""
                })
                .state('state-form-view.dashboard', {
                    url: '/dashboard',
                    templateUrl: 'app/components/formView/form-view.html',
                    controller: 'FormViewCtrl as ctrl',
                    api:{},
                })
                .state('form-view.dashboard', {
                    url: '/dashboard',
                    templateUrl: 'app/components/formView/form-view.html',
                    controller: 'FormViewCtrl as ctrl',
                    api:{},
                })
                .state('form-view.element-view', {
                    url: '/:id',
                    templateUrl: 'app/components/formView/tabWrapper/form-view-tab.html',
                    controller: 'FormViewTabCtrl as ctrl',
                    api:{},
                })
            }]);
        });
        