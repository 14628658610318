
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.InvestmentValidationService',[])

        .factory("InvestmentValidationFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, workspaceFactory, USER_SETTINGS, $rootScope) {
            var investValidationFactory = {};           
            investValidationFactory.url = {};
            investValidationFactory.url.invest_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=4bela7"           
            
            investValidationFactory.saveData =  function(_data,Details, issueId){
            	console.log("===============     Calling Service using InvestmentValidationFactory  =========================", _data);
                
            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params =  _.extend({issue_key:issueId}, params);
                
                params =  _.extend({ind:'U'}, params);
                //params.process_name =  "component-defaults-import";
                console.log("Save params :",params);
            	
                var promise = $http({
                    method: "post",
                    url: investValidationFactory.url.invest_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };
            
            return investValidationFactory;
        }])


    return services;


});