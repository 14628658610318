define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.codeCombinationsController',[])
        .controller('CodeCombinationsController', ['$scope', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory','CodeComboFactory', codeCombinationsController]);



    function codeCombinationsController($scope, $http, ModalFactory, AlertService, JsonObjectFactory,CodeComboFactory) {

        var vm = this;
        vm.name = "Code Combinations Controller";
        $scope.codeCombo1 = "";
        $scope.codeComboLable = "";
        $scope.comboCodeArray = [];
        $scope.uniqueMeCodes = [];
        $scope.uniqueLedgers=[];

   //     $scope.comboCodeArray = CodeComboFactory.comboCodeArray
        function init(){
          //  CodeComboFactory.resetValues(); /* commented on  2/10/2020 */
            $scope.codeCombo1 = "";
            $scope.codeComboLable = "";
            $scope.comboCodeArray = CodeComboFactory.comboCodeArray;
            $scope.uniqueMeCodes = [];
            $scope.uniqueLedgers=[];
        }

        init();


        $scope.disabled = {
            "LeCdrRpt": false,
            "ME": true,
            "Ledger": true
        }

        vm.toggle = CodeComboFactory.toggle;

        vm.toggleForm  = function(){
            CodeComboFactory.toggle = !CodeComboFactory.toggle;
            vm.toggle = CodeComboFactory.toggle;
        }

        $scope.clearSelection = function(){
            CodeComboFactory.resetValues()
            $scope.codeComboLable = "";
        }

        $scope.$on('CCKeySet', function (event, data) {
            $scope.comboCodeArray = CodeComboFactory.comboCodeArray;

        });

        $scope.onLeCdrRptSelect = function ( _model) {
            CodeComboFactory.disabled.LeCdrRpt = true;
            CodeComboFactory.codeCombo1 = _model;
            CodeComboFactory.getCombinationsKeys(_model);
            $scope.codeComboLable = _model.KEY_COL;
        };


        $scope.$watch(function () { return CodeComboFactory.showFormBtn }, function (newVal, oldVal) {
            if (typeof newVal !== 'undefined') {
                $scope.showFormBtn = newVal;
            }
        });


        $scope.$watch(function () { return CodeComboFactory.uniqueMeCodes }, function (newVal, oldVal) {
            if (typeof newVal !== 'undefined') {
                $scope.uniqueMeCodes = newVal;
            }
        });

        $scope.$watch(function () { return CodeComboFactory.uniqueLedgers }, function (newVal, oldVal) {
            if (typeof newVal !== 'undefined') {
                $scope.uniqueLedgers = newVal;
            }
        });


        // $scope.$watch(function () { return CodeComboFactory.comboCodeArray }, function (newVal, oldVal) {
        //     if (newVal && newVal.length>0) {
        //         $scope.comboCodeArray = newVal;
        //     }
        // });


        //HANDLE FORM DISABLE
        $scope.$watch(function () { return CodeComboFactory.disabled }, function (newVal, oldVal) {
            if (typeof newVal !== 'undefined') {
                $scope.disabled = newVal;
            }
        });

        $scope.meSelected = function($item,$model) {
            CodeComboFactory.meSelected($model);
        }

        $scope.ledgerSelected = function($item,$model) {
            if($item.LEDGER != "" ){
                CodeComboFactory.setCombinationCode();
            }else{
                CodeComboFactory.comboCodeArray.selected = null;
            }
        }

        $scope.getCodeCombo = function(val) {

         console.log("val " , val);

          return  CodeComboFactory.getCodeCombo(val);



        };
    }


    return controllers;


});