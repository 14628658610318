define(["angular"], function () {
    "use strict";

    var controllers = angular
        .module("app.scenarioSetupCtrl", [])
        .controller("scenarioSetupCtrl", [
            'GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','scenarioSetupActionsService', '$filter', 'TAX_YEARS',
            scenarioSetupCtrl,
        ]);

    function scenarioSetupCtrl(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,scenarioSetupActionsService, $filter, TAX_YEARS) {
        var vm = this;

        vm.modalTitle = "Scenario Setup";
        vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.taxYears = angular.copy(TAX_YEARS);
        vm.jcdKeyList = ['250']
        vm.scenarioEntity = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            JCD_KEY: vm.jcdKeyList[0],
            SCENARIO_TYPE_CODE: '',
            SCENARIO_JURISDICTION: '',
            FED_SCENARIO: '',
            FED_JCD_KEY: '',
            SCENARIO_DESC: ''

        };
        vm.scenarioEntity_backup = vm.scenarioEntity;


        vm.changeTaxYear = function() {
            vm.scenarioEntity.TAX_YEAR = vm.selectedTaxYear;
        }

        vm.changeJCDKey = function() {
            vm.scenarioEntity.JCD_KEY = vm.selectedJcdKey;
        }

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.reset = function() {
            vm.scenarioEntity = vm.scenarioEntity_backup;
        }

        vm.isValid = function(){
            if(vm.scenarioEntity.TAX_YEAR == null || 
                vm.scenarioEntity.TAX_YEAR == undefined || 
                vm.scenarioEntity.TAX_YEAR == ""){
                     vm.scenarioEntity.TAX_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.SCENARIO_TYPE_CODE == null || 
                vm.scenarioEntity.SCENARIO_TYPE_CODE == undefined || 
                vm.scenarioEntity.SCENARIO_TYPE_CODE == ""){
                     vm.scenarioEntity.SCENARIO_TYPE_CODE_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.JCD_KEY == null || 
                vm.scenarioEntity.JCD_KEY == undefined || 
                vm.scenarioEntity.JCD_KEY == ""){
                     vm.scenarioEntity.JCD_KEY_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.SCENARIO_JURISDICTION == null || vm.scenarioEntity.SCENARIO_JURISDICTION == undefined || vm.scenarioEntity.SCENARIO_JURISDICTION == ""){
                vm.scenarioEntity.SCENARIO_JURISDICTION_NOT_VALID = true;
                return false;
            }
            if(vm.scenarioEntity.SCENARIO_DESC == null || vm.scenarioEntity.SCENARIO_DESC == undefined || vm.scenarioEntity.SCENARIO_DESC == ""){
                vm.scenarioEntity.SCENARIO_DESC_NOT_VALID = true;
                return false;
            }
            
            return true;
        }
        
        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
    
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.scenarioEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            
    
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                clob_data: jsonObj
            }, params);
            params = _.extend({
                clob_settings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=f5r4g2";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Scenario created successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };


    }
    return controllers;
});
