define([
    'angular'

 ], function () {
    'use strict';
    
    
    var controllers = angular.module('app.F-4562Ctrl', [])
    .controller('F4562Controller', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4562Factory', 'ModalFactory',F4562Controller])
    .controller('F4562ControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4562Factory', 'ModalFactory','$uibModalInstance','rowData', 'colData', 'gridData',F4562ControllerModal])    ;

    function F4562Controller(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4562Factory,ModalFactory) {
    	F4562ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4562Factory,ModalFactory,{},{}, {}, {},this);
    }


	function F4562ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4562Factory,ModalFactory,$uibModalInstance,
        rowData, colData, gridData,thisObj) {
		console.log("------------At the start-----------");
		
		var vm = this;
		vm.rowData = rowData;
		console.log(rowData);
		vm.f4562Header = [];
		vm.f4562List = [];
		vm.f4562OriginalData = [];
		vm.f4562FormData = [];
		vm.f4562ColumnHeader = [];
		vm.f4562FinalData = [];
		vm.f4562LHData = [];		
		
		vm.scenario 		    = GlobalService.globalParams.scenario;
		vm.glbl_issue_id  		= GlobalService.globalParams.issue_id;
		vm.local_issue_id  		= '';
		vm.bShowSaveButton 		= true;
		vm.cur_scenario_type    = '';
		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		vm.f4562FACSHeader = [];
		vm.f4562FACSList = [];
		vm.f4562FACSFormData = [];
		vm.f4562FACSLHData = [];
		var LH_index = 0;
		vm.unAllocatedAmt = 0;
		vm.crudLoading = false;
		//todo: store below values in db
		vm.convensions =  ["HY", "MM", "MQ", "S/L"];
		vm.methods = ["150 DB", "200 DB", "DB", "S/L"];
		vm.recoveryPeriod = ["3 Yrs", "5 Yrs", "7 Yrs", "10 Yrs", "15 Yrs", "20 Yrs", "25 Yrs", "27.5 Yrs", "39 Yrs", "50 Yrs"];
		vm.isValidScenario = false;
		vm.isExamScenario = GlobalService.inputs.tax_year.selected.scenario_type_code === 'EXAM' && checkExamScenario();
		function checkExamScenario() {
			const filters = workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getFilters();
			if (!filters) {
				return false;
			}
			const isExamScenario = filters.some((filter) => {
				return filter.param_name.trim().toLowerCase() === 'scenario' &&
					filter.selected.CODE &&
					filter.selected.CODE.trim().toUpperCase() === 'EXAM';
			});
			return isExamScenario;
		}
		
		vm.onTabChange = function(e) {
            vm.selectedTabName = e;
            $timeout(function() {
                var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            }, 50);
        };
		
		vm.cancel = function() {
			console.log("Form 4562 data from DB is :::::::::::", vm.f4562FormData);
			console.log("Form 4562 data from original Data is saved in :::::::::::", vm.f4562OriginalData);
			
			vm.f4562FormJSONParseData = JSON.parse(angular.toJson(vm.f4562FormData));
			console.log("Form 4562 data after JSON is :::::::::::", vm.f4562FormJSONParseData);
			if(_.isEqual(vm.f4562OriginalData, vm.f4562FormJSONParseData)){
				$uibModalInstance.dismiss('cancel');
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
					vm.cancelModal();
				}
				else if(confirm("Depreciation and Amortization Information is not save. Do you want to close Form without saving changes?")){
					$uibModalInstance.dismiss('cancel');
				}
			}
		};
		
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		console.log(vm.logged_in_user);
		function getF4562Data(){
			F4562Factory.fetchF4562Details(rowData, vm.logged_in_user, filterParams.scenario).then(function (data){
				console.log("Came back to controller from Service...............:::::::::", data);
				vm.f4562Header = data.jsonObject[0];				
				vm.f4562List = data.jsonObject.slice(1);
				for(var i=0;i<vm.f4562List.length;i++){
					if(!vm.isValidScenario && vm.f4562List[i].T_SCENARIO_TYPE_CODE){
						vm.isValidScenario = vm.checkEditScenario(vm.f4562List[i].T_SCENARIO_TYPE_CODE,  vm.f4562List[i].T_SCENARIO,  vm.f4562List[i].ISSUE_KEY);
					}
				}
				var o = vm.f4562List.reduce( (a,b) => {
					a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
					a[b.LINE_ORDER].push(b);
					return a;
				}, {});
				
				var a = Object.keys(o).map(function(k){
					return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
				});
				
				vm.f4562FormData = a;
				console.log(vm.f4562FormData);
				
				/*var m = vm.f4562FormData.reduce( (a,b) => {
					//console.log("The value of ROW_TYPE is :::::::::::::::::::::::", a[b.ROW_TYPE], b.ROW_TYPE);
					//if(b.ROW_TYPE == "LH" && a[b.ROW_TYPE] != undefined){
						a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
						a[b.OCCURRENCE].push(b);
						return a;
					//}
				}, {});*/
				
				
				LH_index = vm.f4562FormData.findIndex(x => x.ROW_TYPE == "LH");
				console.log("The index of the LH value is ::::::::::::::::::::", LH_index);
				var n = vm.f4562FormData.reduce( (a,b) => {
					//console.log("The value of ROW_TYPE is :::::::::::::::::::::::", a[b.ROW_TYPE], b.ROW_TYPE);
					//if(b.ROW_TYPE == "LH" && a[b.ROW_TYPE] != undefined){
						a[b.ROW_TYPE] = a[b.ROW_TYPE] || [];
						a[b.ROW_TYPE].push(b);
						return a;
					//}
				}, {});
				
				
				console.log("The value before making keys and after reduce is :::::::::", n);
				
				var b = Object.keys(n).map(function(k){
					//console.log("The value of k after reduce before Map is :::::::::::::::::::::::", k, n[k]);
					if(k == 'LH'){
						return {ROW_TYPE: n[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: n[k]};
					}
				});
				
				/*var lx = b.reduce((m,n) =>{
					m[n.OCCURRENCE] = m[n.OCCURRENCE] || [];
					m[n.OCCURRENCE].push(n);
					return m;
				}, {});*/
				
				console.log("The value of form data after reducing LH data was ::::::::::", b);
				console.log("The 4562 form data before modifications was :::::::::::::", vm.f4562FormData);
				
				for (var i = vm.f4562FormData.length -1; i >= 0; i--){
					if(vm.f4562FormData[i].ROW_TYPE == 'LH'){
						vm.f4562FormData.splice(i, 1);
					}
				}
				
				console.log("The 4562 form data in between modifications was :::::::::::::", vm.f4562FormData);
				
				for(var g=0; g < b.length; g++){
					if(b[g] != undefined){
						for(var h=0; h < b[g].Line_data.length; h++ ){
							if(b[g].Line_data[h].Line_data[0].OCCURRENCE != null){
								var z = b[g].Line_data[h].Line_data.reduce( (a,b) => {
									a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
									a[b.OCCURRENCE].push(b);
									return a;
								}, {});
								
								var y = Object.keys(z).map(function(k){
									return {ROW_TYPE: z[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: z[k]};
								});
								
								//b[g].Line_data[h].Line_data = angular.copy(y);
								b[g].Line_data.splice(h, 1, y[0]);
								if(y.length > 0){
									for(var a=1; a < y.length; a++){
										b[g].Line_data.splice(h+a, 0, y[a]);
									}
								}
							}
						}
						vm.f4562LHData = b[g];
					} 
				}
				
				vm.f4562FormData.splice(LH_index, 0, vm.f4562LHData);
				console.log("The 4562 form data after modifications was :::::::::::::", vm.f4562FormData);
				
				var c = [];
				
				for (var i=0; i < vm.f4562FormData.length; i++){
					if(vm.f4562FormData[i].ROW_TYPE == 'CH'){
						
						if(vm.f4562FormData[i].Line_data[0].OCCURRENCE != null){
							var z = vm.f4562FormData[i].Line_data.reduce( (a,b) => {
								a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
								a[b.OCCURRENCE].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITTED:"N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Occurrence: z[k][0].OCCURRENCE, Line_data_occ: z[k]};
							});
							
							console.log("At this point of the value of y is -------::::::", y);
							var m = angular.copy(y);
							console.log("At this point of the value of m is -------::::::", m);
							
							c = angular.copy(y[0]);
							c.Line_Type = "HEADER_ROW";
							c.Occurrence = "";
							c.Trans_details_key = "";
							for (var j=0; j < c.Line_data_occ.length; j++){
								c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
							vm.f4562FormData[i].Line_data = angular.copy(y);
							
							for(var z=0; z < vm.f4562FormData[i].Line_data.length; z++){
								if(vm.f4562FormData[i].Line_data[z].Line_Type == "DATA_ROW"){
									var line_no = vm.f4562FormData[i].Line_data[z].Line_no;
									for(var y=0; y < vm.f4562FormData[i].Line_data[z].Line_data_occ.length; y++){
										if(vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_SHORT_CODE == "METHOD_OR_CONVENTION_"+line_no){
											var attrib_vale_array = vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE.split('~');
											vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_METHOD = attrib_vale_array[0];
											vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_CONVENTION = attrib_vale_array[1];
										} else if(vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_SHORT_CODE == "AMORTIZATION_PERIOD_OR_PTG_"+line_no){
											var attrib_vale_array = vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE.split('~');
											vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_PERIOD = attrib_vale_array[0];
											vm.f4562FormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_PERCENTAGE = attrib_vale_array[1];
										}
									}
								}
							}
							
						} else {
							var z = vm.f4562FormData[i].Line_data.reduce( (a,b) => {
								a[b.LINE_NO] = a[b.LINE_NO] || [];
								a[b.LINE_NO].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "HEADER_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITTED:"N", Trans_details_key: "", Occurrence: "", Line_data_occ: z[k]};
							});
							
							for(var j=0; j < y[0].Line_data_occ.length; j++){
								y[0].Line_data_occ[j].ATTRIB_VALUE = y[0].Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.push({Line_Type:"SUB_ROW", Line_no:y[0].Line_no, Line_data_occ: []});
							vm.f4562FormData[i].Line_data = y;
						}
					} else if(vm.f4562FormData[i].ROW_TYPE == 'DT'){
						for(var j=0; j < vm.f4562FormData[i].Line_data.length; j++){
							if(vm.f4562FormData[i].Line_data[j].IS_EDITABLE == 'Y' && vm.f4562FormData[i].Line_data[j].EDIT_TYPE == 'currency' && vm.f4562FormData[i].Line_data[j].DATA_TYPE == 'number'){
								vm.f4562FormData[i].Line_data[j].ATTRIB_VALUE = parseInt(vm.f4562FormData[i].Line_data[j].ATTRIB_VALUE);
							}
						}
					} else if(vm.f4562FormData[i].ROW_TYPE == 'LH'){
						for(var j=0; j < vm.f4562FormData[i].Line_data.length; j++){
							for(var l=0; l < vm.f4562FormData[i].Line_data[j].Line_data.length; l++){
								if(vm.f4562FormData[i].Line_data[j].Line_data[l].IS_EDITABLE == 'Y' && vm.f4562FormData[i].Line_data[j].Line_data[l].EDIT_TYPE == 'currency' && vm.f4562FormData[i].Line_data[j].Line_data[l].DATA_TYPE == 'number'){
									vm.f4562FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE = parseInt(vm.f4562FormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE);
								}
							}
						}
					} 
				}
				
				console.log(vm.f4562FormData);
				getUnallocatedAmt();
				vm.f4562OriginalData = angular.copy(vm.f4562FormData);
				console.log("Copy of original data to a variable :::::::::::", vm.f4562OriginalData);
			});
			
		};
		
		function getF4562FACSData(){
			F4562Factory.fetchF4562FACSDetails(rowData, vm.logged_in_user, filterParams.scenario).then(function (data){
				console.log("Came back to controller from Service...............:::::::::", data);
				vm.f4562FACSHeader = data.jsonObject[0];
				vm.f4562FACSList = data.jsonObject.slice(1);
				for(var i=0;i<vm.f4562FACSList.length;i++){
					if(!vm.isValidScenario && vm.f4562FACSList[i].T_SCENARIO_TYPE_CODE){
						vm.isValidScenario = vm.checkEditScenario(vm.f4562FACSList[i].T_SCENARIO_TYPE_CODE,  vm.f4562FACSList[i].T_SCENARIO,  vm.f4562FACSList[i].ISSUE_KEY);
					}
				}
				var o = vm.f4562FACSList.reduce( (a,b) => {
					a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
					a[b.LINE_ORDER].push(b);
					return a;
				}, {});
				
				var a = Object.keys(o).map(function(k){
					return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
				});
				
				vm.f4562FACSFormData = a;
				console.log(vm.f4562FACSFormData);
				
				
				LH_index = vm.f4562FACSFormData.findIndex(x => x.ROW_TYPE == "LH");
				console.log("The index of the LH value is ::::::::::::::::::::", LH_index);
				var n = vm.f4562FACSFormData.reduce( (a,b) => {
						a[b.ROW_TYPE] = a[b.ROW_TYPE] || [];
						a[b.ROW_TYPE].push(b);
						return a;
				}, {});
				
				
				console.log("The value before making keys and after reduce is :::::::::", n);
				
				var b = Object.keys(n).map(function(k){
					if(k == 'LH'){
						return {ROW_TYPE: n[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: n[k]};
					}
				});
				
				console.log("The value of form data after reducing LH data was ::::::::::", b);
				console.log("The 4562 form data before modifications was :::::::::::::", vm.f4562FACSFormData);
				
				for (var i = vm.f4562FACSFormData.length -1; i >= 0; i--){
					if(vm.f4562FACSFormData[i].ROW_TYPE == 'LH'){
						vm.f4562FACSFormData.splice(i, 1);
					}
				}
				
				console.log("The 4562 form data in between modifications was :::::::::::::", vm.f4562FACSFormData);
				
				for(var g=0; g < b.length; g++){
					if(b[g] != undefined){
						for(var h=0; h < b[g].Line_data.length; h++ ){
							if(b[g].Line_data[h].Line_data[0].OCCURRENCE != null){
								var z = b[g].Line_data[h].Line_data.reduce( (a,b) => {
									a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
									a[b.OCCURRENCE].push(b);
									return a;
								}, {});
								
								var y = Object.keys(z).map(function(k){
									return {ROW_TYPE: z[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: z[k]};
								});
								
								//b[g].Line_data[h].Line_data = angular.copy(y);
								b[g].Line_data.splice(h, 1, y[0]);
								if(y.length > 0){
									for(var a=1; a < y.length; y++){
										b[g].Line_data.splice(h+a, 0, y[a]);
									}
								}
							}
						}
						vm.f4562FACSLHData = b[g];
					}
				}
				
				vm.f4562FACSFormData.splice(LH_index, 0, vm.f4562FACSLHData);
				console.log("The 4562 form data after modifications was :::::::::::::", vm.f4562FACSFormData);
				
				var c = [];
				
				for (var i=0; i < vm.f4562FACSFormData.length; i++){
					if(vm.f4562FACSFormData[i].ROW_TYPE == 'CH'){
						
						if(vm.f4562FACSFormData[i].Line_data[0].OCCURRENCE != null){
							var z = vm.f4562FACSFormData[i].Line_data.reduce( (a,b) => {
								a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
								a[b.OCCURRENCE].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITTED:"N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Occurrence: z[k][0].OCCURRENCE, Line_data_occ: z[k]};
							});
							
							console.log("At this point of the value of y is -------::::::", y);
							var m = angular.copy(y);
							console.log("At this point of the value of m is -------::::::", m);
							
							c = angular.copy(y[0]);
							c.Line_Type = "HEADER_ROW";
							c.Occurrence = "";
							c.Trans_details_key = "";
							for (var j=0; j < c.Line_data_occ.length; j++){
								c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
							vm.f4562FACSFormData[i].Line_data = angular.copy(y);
							
							for(var z=0; z < vm.f4562FACSFormData[i].Line_data.length; z++){
								if(vm.f4562FACSFormData[i].Line_data[z].Line_Type == "DATA_ROW"){
									var line_no = vm.f4562FACSFormData[i].Line_data[z].Line_no;
									for(var y=0; y < vm.f4562FACSFormData[i].Line_data[z].Line_data_occ.length; y++){
										if(vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_SHORT_CODE == "METHOD_OR_CONVENTION_"+line_no){
											var attrib_vale_array = vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE.split('~');
											vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_METHOD = attrib_vale_array[0];
											vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_CONVENTION = attrib_vale_array[1];
										} else if(vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_SHORT_CODE == "AMORTIZATION_PERIOD_OR_PTG_"+line_no){
											var attrib_vale_array = vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE.split('~');
											vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_PERIOD = attrib_vale_array[0];
											vm.f4562FACSFormData[i].Line_data[z].Line_data_occ[y].ATTRIB_VALUE_PERCENTAGE = attrib_vale_array[1];
										}
									}
								}
							}
							
						} else {
							var z = vm.f4562FACSFormData[i].Line_data.reduce( (a,b) => {
								a[b.LINE_NO] = a[b.LINE_NO] || [];
								a[b.LINE_NO].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "HEADER_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITTED:"N", Trans_details_key: "", Occurrence: "", Line_data_occ: z[k]};
							});
							
							for(var j=0; j < y[0].Line_data_occ.length; j++){
								y[0].Line_data_occ[j].ATTRIB_VALUE = y[0].Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.push({Line_Type:"SUB_ROW", Line_no:y[0].Line_no, Line_data_occ: []});
							vm.f4562FACSFormData[i].Line_data = y;
						}
					} else if(vm.f4562FACSFormData[i].ROW_TYPE == 'DT'){
						for(var j=0; j < vm.f4562FACSFormData[i].Line_data.length; j++){
							if(vm.f4562FACSFormData[i].Line_data[j].IS_EDITABLE == 'Y' && vm.f4562FACSFormData[i].Line_data[j].EDIT_TYPE == 'currency' && vm.f4562FACSFormData[i].Line_data[j].DATA_TYPE == 'number'){
								vm.f4562FACSFormData[i].Line_data[j].ATTRIB_VALUE = parseInt(vm.f4562FACSFormData[i].Line_data[j].ATTRIB_VALUE);
							}
						}
					} else if(vm.f4562FACSFormData[i].ROW_TYPE == 'LH'){
						for(var j=0; j < vm.f4562FACSFormData[i].Line_data.length; j++){
							for(var l=0; l < vm.f4562FACSFormData[i].Line_data[j].Line_data.length; l++){
								if(vm.f4562FACSFormData[i].Line_data[j].Line_data[l].IS_EDITABLE == 'Y' && vm.f4562FACSFormData[i].Line_data[j].Line_data[l].EDIT_TYPE == 'currency' && vm.f4562FACSFormData[i].Line_data[j].Line_data[l].DATA_TYPE == 'number'){
									vm.f4562FACSFormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE = parseInt(vm.f4562FACSFormData[i].Line_data[j].Line_data[l].ATTRIB_VALUE);
								}
							}
						}
					} 
				}
				
				console.log(vm.f4562FACSFormData);
				
			});
			
		}
		
		getF4562Data();
		getF4562FACSData();
		
		vm.reset = function(){
			getF4562Data();
		}
		
		vm.checkEditScenario = function (s_type_code, t_scenario, t_issue){        	
        	if(vm.scenario == t_scenario && ((s_type_code == 'EXAM' && vm.glbl_issue_id == t_issue) || s_type_code != 'EXAM')){
        		return true;
        	}        	
        	return false;        	
        }		
		
		vm.save = function(){
			vm.finalData = [];
			vm.clob_settings = [];
			console.log(vm.f4562FormData );
			console.log("Original Data was ::::::::==========", vm.f4562OriginalData);
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			vm.f4562FinalData = angular.copy(vm.f4562FormData);
			if(angular.equals(vm.f4562FormData, vm.f4562OriginalData)){
				vm.cancel();
			}else{
				updateFormDataValues();
				var returnValue = validateFormValues();
				if(returnValue == 1){
					return false;
				}
				if(vm.unAllocatedAmt != 0){
					if(!confirm("Total Depreciation Expense Per Tax Return is not fully adjusted. Confirm to save the changes.")){
						return false;
					}
				}
				for(var x=0; x < vm.f4562FinalData.length; x++){
					if(vm.f4562FinalData[x].ROW_TYPE == 'CH'){
						vm.f4562FinalData[x].Line_data.splice(0, 2);
						if(vm.f4562FinalData[x].Line_data.length != null && vm.f4562FinalData[x].Line_data.length != undefined){
							for(var j=0; j < vm.f4562FinalData[x].Line_data.length; j++){
								for(var k = 0; k < vm.f4562FinalData[x].Line_data[j].Line_data_occ.length; k++){
									vm.finalData.push(vm.f4562FinalData[x].Line_data[j].Line_data_occ[k]);
								}
							}
						}
						if(vm.f4562FinalData[x].DELETE_ROWS.length != null && vm.f4562FinalData[x].DELETE_ROWS.length != undefined){
							for(var j=0; j < vm.f4562FinalData[x].DELETE_ROWS.length; j++){
								for(var k = 0; k < vm.f4562FinalData[x].DELETE_ROWS[j].Line_data_occ.length; k++){
									vm.f4562FinalData[x].DELETE_ROWS[j].Line_data_occ[k].IS_CHANGED_FLAG = "D";
									vm.finalData.push(vm.f4562FinalData[x].DELETE_ROWS[j].Line_data_occ[k]);
								}
							}
						}
					}
					if(vm.f4562FinalData[x].ROW_TYPE == 'TH' || vm.f4562FinalData[x].ROW_TYPE == 'DT' || vm.f4562FinalData[x].ROW_TYPE == 'RT'){
						for(var j=0; j < vm.f4562FinalData[x].Line_data.length; j++){
							vm.finalData.push(vm.f4562FinalData[x].Line_data[j]);
						}
					}
					if(vm.f4562FinalData[x].ROW_TYPE == 'LH'){
						for(var j=0; j < vm.f4562FinalData[x].Line_data.length; j++){
							for(var l=0; l < vm.f4562FinalData[x].Line_data[j].Line_data.length; l++){
								vm.finalData.push(vm.f4562FinalData[x].Line_data[j].Line_data[l]);
							}
						}
					}
				}
				console.log("After changes, the final data was :::::::::::=========", vm.f4562FinalData);
				console.log("After changes, the manipulated data was :::::::::::=========", vm.f4562FormData);
				console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
				vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
				sendDetails(vm.finalData, vm.clob_settings);
			}
		}
		
		function sendDetails(final_data, clob_settings){
			var message = "Form 4562 Details are successfully updated."
			vm.crudLoading = true;
			F4562Factory.saveForm4562(final_data, clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		}
		
		function getDateFormat(date_value){
			console.log("The value of the date for the row that needs to convert is ::::::", date_value);
			var date_selected = null;
			if(date_value != "VARIOUS"){
				date_selected = ((date_value.getMonth() > 8) ? (date_value.getMonth() + 1) : ('0' + (date_value.getMonth() + 1))) + '/' + ((date_value.getDate() > 9) ? date_value.getDate() : ('0' + date_value.getDate())) + '/' + date_value.getFullYear();
			}else date_selected = "VARIOUS";
			
			return date_selected;
		}
		
		function updateFormDataValues(){
			for (var i=0; i < vm.f4562FinalData.length; i++){
				if(vm.f4562FinalData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4562FinalData[i].Line_data.length; j++){
						if(vm.f4562FinalData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
							for(var l=0; l < vm.f4562FinalData[i].Line_data[j].Line_data_occ.length; l++){
								var line_no = vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].LINE_NO;
								if(vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious" && vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various"){
									vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = getDateFormat(vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
								}
								if(vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_SHORT_CODE == "METHOD_OR_CONVENTION_"+line_no){
									if(vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_METHOD == undefined){
										vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_METHOD = '';
									}
									if(vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_CONVENTION == undefined){
										vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_CONVENTION = '';
									}
									vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_METHOD + '~' + vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_CONVENTION;
								} else if (vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_SHORT_CODE == "AMORTIZATION_PERIOD_OR_PTG_"+line_no){
									if(vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERIOD == undefined){
										vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERIOD = '';
									}
									if(vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERCENTAGE == undefined){
										vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERCENTAGE = '';
									}
									vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERIOD + '~' + vm.f4562FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERCENTAGE;
								}
							}
						}
					}
				}
			}
		}
		
		function validateFormValues(){
			var returnValue = 0;
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4562FormData[i].Line_data.length; j++){
						var line_no = vm.f4562FormData[i].Line_data[j].Line_no;
						if(vm.f4562FormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.f4562FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
							var property_desc_index = vm.f4562FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "PROPERTY_DESC_"+line_no);
							var recovery_period_index = vm.f4562FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "RECOVERY_PERIOD_"+line_no);
							var method_convention_index = vm.f4562FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "METHOD_OR_CONVENTION_"+line_no);
							var desc_costs_index = vm.f4562FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "DESCRIPTION_COSTS_"+line_no);
							var code_section_index = vm.f4562FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "CODE_SECTION_"+line_no);
							var period_percentage_index = vm.f4562FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "AMORTIZATION_PERIOD_OR_PTG_"+line_no);
							
							if(null != property_desc_index && property_desc_index != -1 && (vm.f4562FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE == undefined ||vm.f4562FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE.length <=0)){
								vm.f4562FormData[i].Line_data[j].Line_data_occ[property_desc_index].HAS_ERROR = "Y";
								vm.f4562FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE_ERROR = "Enter Property Type Here.";
								returnValue = 1; 
							} 
							if(null != desc_costs_index && desc_costs_index != -1 && (vm.f4562FormData[i].Line_data[j].Line_data_occ[desc_costs_index].ATTRIB_VALUE == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[desc_costs_index].ATTRIB_VALUE == undefined ||vm.f4562FormData[i].Line_data[j].Line_data_occ[desc_costs_index].ATTRIB_VALUE.length <=0)){
								vm.f4562FormData[i].Line_data[j].Line_data_occ[desc_costs_index].HAS_ERROR = "Y";
								vm.f4562FormData[i].Line_data[j].Line_data_occ[desc_costs_index].ATTRIB_VALUE_ERROR = "Enter Description of Amortization Costs Here.";
								returnValue = 1; 
							}
							if(null != recovery_period_index && recovery_period_index != -1 && (vm.f4562FormData[i].Line_data[j].Line_data_occ[recovery_period_index].ATTRIB_VALUE == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[recovery_period_index].ATTRIB_VALUE == undefined ||vm.f4562FormData[i].Line_data[j].Line_data_occ[recovery_period_index].ATTRIB_VALUE.length <=0)){
								vm.f4562FormData[i].Line_data[j].Line_data_occ[recovery_period_index].HAS_ERROR = "Y";
								vm.f4562FormData[i].Line_data[j].Line_data_occ[recovery_period_index].ATTRIB_VALUE_ERROR = "Select the Recovery Period for the Depreciation.";
								returnValue = 1; 
							}
							if(null != code_section_index && code_section_index != -1 && (vm.f4562FormData[i].Line_data[j].Line_data_occ[code_section_index].ATTRIB_VALUE == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[code_section_index].ATTRIB_VALUE == undefined ||vm.f4562FormData[i].Line_data[j].Line_data_occ[code_section_index].ATTRIB_VALUE.length <=0)){
								vm.f4562FormData[i].Line_data[j].Line_data_occ[code_section_index].HAS_ERROR = "Y";
								vm.f4562FormData[i].Line_data[j].Line_data_occ[code_section_index].ATTRIB_VALUE_ERROR = "Enter the Code Section for the Amortization.";
								returnValue = 1;
							}
							if(null != method_convention_index && method_convention_index != -1){
								if(vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].ATTRIB_VALUE_METHOD == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].ATTRIB_VALUE_METHOD == undefined){
									vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].HAS_ERROR = "Y";
									vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].ATTRIB_VALUE_ERROR = "Select the Method for the Depreciation.";
									returnValue = 1;
								}
								if(vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].ATTRIB_VALUE_CONVENTION == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].ATTRIB_VALUE_CONVENTION == undefined){
									vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].HAS_ERROR = "Y";
									vm.f4562FormData[i].Line_data[j].Line_data_occ[method_convention_index].ATTRIB_VALUE_ERROR = "Select the Convention for the Depreciation.";
									returnValue = 1;
								}
							}
							if(null != period_percentage_index && period_percentage_index != -1){
								if((vm.f4562FormData[i].Line_data[j].Line_data_occ[period_percentage_index].ATTRIB_VALUE_PERIOD == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[period_percentage_index].ATTRIB_VALUE_PERIOD == undefined)
										&& (vm.f4562FormData[i].Line_data[j].Line_data_occ[period_percentage_index].ATTRIB_VALUE_PERCENTAGE == null || vm.f4562FormData[i].Line_data[j].Line_data_occ[period_percentage_index].ATTRIB_VALUE_PERCENTAGE == undefined)){
									vm.f4562FormData[i].Line_data[j].Line_data_occ[period_percentage_index].HAS_ERROR = "Y";
									vm.f4562FormData[i].Line_data[j].Line_data_occ[period_percentage_index].ATTRIB_VALUE_ERROR = "Select the Amortizarion Period or Enter Percentage for the Depreciation.";
									returnValue = 1;
								}
							}
						}
					}
				}
			}
			return returnValue;
		}
		
		function getDiffDates(dateAcquired, dateSold){
			if(typeof dateAcquired === 'string'){
				dateAcquired = convertToDate(dateAcquired);
				dateSold = convertToDate(dateSold);
			}
			
			return ((dateSold -  dateAcquired)/ (1000 * 3600 * 24));
		}
		
		function convertToDate(str)
		{
		   var dateArr = str.split("/");
		   return new Date(dateArr[2], dateArr[0], dateArr[1])
		}
		
		function getUnallocatedAmt(){
			for(var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'TH'){
					if(vm.f4562FormData[i].Line_data[1].DESCRIPTION == 'Unallocated Amount' && vm.f4562FormData[i].Line_data[1].DATA_TYPE == 'number' && vm.f4562FormData[i].Line_data[1].ATTRIB_VALUE != null && vm.f4562FormData[i].Line_data[1].ATTRIB_VALUE != undefined){
						vm.unAllocatedAmt = parseInt(vm.f4562FormData[i].Line_data[1].ATTRIB_VALUE);
						console.log("the unallocatedamt was updated. And the unallocated amt is :::::", vm.unAllocatedAmt);
					}
				}
			}
		}
		
		vm.AddNewRow = function(line_no){
			console.log("The new line is created under :::::::", line_no);
			var occurrance = 1;
			var trans_details_key = null;
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'CH'){
					if(null != vm.f4562FormData[i].Line_data[vm.f4562FormData[i].Line_data.length -1].Occurrence && vm.f4562FormData[i].Line_data[vm.f4562FormData[i].Line_data.length -1].Occurrence != "" && vm.f4562FormData[i].Line_data[vm.f4562FormData[i].Line_data.length -1].Occurrence != undefined){
						occurrance = vm.f4562FormData[i].Line_data[vm.f4562FormData[i].Line_data.length -1].Occurrence + 1;
						trans_details_key = vm.f4562FormData[i].Line_data[vm.f4562FormData[i].Line_data.length -1].Trans_details_key;
					}
					
					var new_row = angular.copy(vm.f4562FormData[i].Line_data[0]);
					new_row.Line_Type = "NEW_EDIT_ROW";
					new_row.Occurrence = occurrance;
					new_row.Trans_details_key = trans_details_key;
					new_row.IS_EDITTED = "Y";
					
					if(new_row.Line_no == line_no){
						for (var j=0; j < new_row.Line_data_occ.length; j++){
							if(new_row.Line_data_occ[j].EDIT_TYPE == "custom_date_various" && new_row.Line_data_occ[j].DATA_TYPE == "dateVarious"){
								new_row.Line_data_occ[j].ATTRIB_VALUE = new Date();
							} else if (new_row.Line_data_occ[j].EDIT_TYPE == "currency" && new_row.Line_data_occ[j].DATA_TYPE == "number"){
								new_row.Line_data_occ[j].ATTRIB_VALUE = 0;
							} else if (new_row.Line_data_occ[j].EDIT_TYPE == "label" && new_row.Line_data_occ[j].DATA_TYPE == "string"){
							} else {	
								new_row.Line_data_occ[j].ATTRIB_VALUE = null;
							}
							new_row.Line_data_occ[j].OCCURRENCE = occurrance;
							new_row.Line_data_occ[j].TRANS_DETAILS_KEY = trans_details_key;
							new_row.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
						}
						vm.f4562FormData[i].Line_data.push(new_row);
					}
				}
			}
			console.log(vm.f4562FormData);
		}
		
		vm.editExistingRecord = function(line_no, occurrence, index){
			console.log("These are the line_no and occurrence for the editting rows", line_no, occurrence, index);
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4562FormData[i].Line_data.length; j++){
						if(j == index || j == index+1){
							if(vm.f4562FormData[i].Line_data[j].Line_Type == "DATA_ROW" && vm.f4562FormData[i].Line_data[j].Line_no == line_no && vm.f4562FormData[i].Line_data[j].Occurrence == occurrence){
								vm.f4562FormData[i].Line_data[j].Line_Type = "NEW_EDIT_ROW";
								for(var l=0; l < vm.f4562FormData[i].Line_data[j].Line_data_occ.length; l++){
									if(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "currency" && vm.f4562FormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "number"){
										vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = parseInt(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
									} else if (vm.f4562FormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various" && vm.f4562FormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious"){
										if (vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE != "VARIOUS"){
											vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = new Date(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
										}
									}
								}
							} 
						}
					}
					
				}
			}
			console.log(vm.f4562FormData);
		}
		
		vm.removeExistingRecord = function(line_no, Occurrence, index){
			console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4562FormData[i].Line_data.length; j++){
						if(j == index){
							if((vm.f4562FormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.f4562FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && vm.f4562FormData[i].Line_data[j].Line_no == line_no && null != vm.f4562FormData[i].Line_data[j].Occurrence && vm.f4562FormData[i].Line_data[j].Occurrence != "" && vm.f4562FormData[i].Line_data[j].Occurrence == Occurrence){
								vm.f4562FormData[i].DELETE_ROWS.push(vm.f4562FormData[i].Line_data[j]);
								vm.f4562FormData[i].Line_data.splice(j,1);
							}else if((vm.f4562FormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.f4562FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && vm.f4562FormData[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
								vm.f4562FormData[i].Line_data.splice(j,1);
							}
						}
					}
				}
			}
			vm.calcFinalAmounts();
			console.log(vm.f4562FormData);
		}
		
		vm.saveNewEdittedRecord = function(line_no, occurrence, index){
			console.log("These are the line_no and occurrence that are newly added or editted", line_no, occurrence, index);
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4562FormData[i].Line_data.length; j++){
						if(j== index){
							if(vm.f4562FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW" && vm.f4562FormData[i].Line_data[j].Line_no == line_no && vm.f4562FormData[i].Line_data[j].Occurrence == occurrence){
								vm.f4562FormData[i].Line_data[j].Line_Type = "DATA_ROW";
							}
							for(var l=0; l < vm.f4562FormData[i].Line_data[j].Line_data_occ.length; l++){
								if(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.f4562FormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious" && vm.f4562FormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various"){
									vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = getDateFormat(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
								}
								if(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_SHORT_CODE == "METHOD_OR_CONVENTION_"+line_no){
									if(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_METHOD == undefined){
										vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_METHOD = '';
									}
									if(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_CONVENTION == undefined){
										vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_CONVENTION = '';
									}
									vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_METHOD + '~' + vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_CONVENTION;
								} else if (vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_SHORT_CODE == "AMORTIZATION_PERIOD_OR_PTG_"+line_no){
									if(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERIOD == undefined){
										vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERIOD = '';
									}
									if(vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERCENTAGE == undefined){
										vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERCENTAGE = '';
									}
									vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERIOD + '~' + vm.f4562FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE_PERCENTAGE;
								}
							}
						}
					}
				}
			}
			console.log(vm.f4562FormData);
		}
		
		vm.valuechanged = function(parent_index, occurrence, line_no, index){
			console.log("These are the parent_index, occurrence and index of the editted field", parent_index, occurrence, line_no, index);
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'CH'){
					if(vm.f4562FormData[i].Line_data[parent_index] != null && vm.f4562FormData[i].Line_data[parent_index] != undefined){
						if(vm.f4562FormData[i].Line_data[parent_index].Line_no == line_no && vm.f4562FormData[i].Line_data[parent_index].Occurrence == occurrence){
							vm.f4562FormData[i].Line_data[parent_index].Line_data_occ[index+1].IS_CHANGED_FLAG = "Y";
							vm.f4562FormData[i].Line_data[parent_index].Line_data_occ[index+1].HAS_ERROR = "N";
						}
					}
				} else if(vm.f4562FormData[i].ROW_TYPE == 'DT' || vm.f4562FormData[i].ROW_TYPE == 'RT'){
					if(i == parent_index && vm.f4562FormData[i].Line_data[index] != null && vm.f4562FormData[i].Line_data[index] != undefined){
						vm.f4562FormData[i].Line_data[index].IS_CHANGED_FLAG = "Y";
					}
				} else if(vm.f4562FormData[i].ROW_TYPE == 'LH'){
					if(vm.f4562FormData[i].Line_data[parent_index] != null && vm.f4562FormData[i].Line_data[parent_index] != undefined){
						if(vm.f4562FormData[i].Line_data[parent_index].Line_data[index] != null && vm.f4562FormData[i].Line_data[parent_index].Line_data[index].LINE_NO == line_no){
							// set dropdown changed flag to true whenever the value is changed
							if (['19a', '19b', '19c', '19d', '19e', '19f', '19g', '19h', '19i', '20a', '20b', '20c', '20d'].includes(vm.f4562FormData[i].Line_data[parent_index].Line_data[index].LINE_NO)) {
								for (let k = 0; k < vm.f4562FormData[i].Line_data[parent_index].Line_data.length; k++) {
									if (vm.f4562FormData[i].Line_data[parent_index].Line_data[k].EDIT_TYPE === 'select' && vm.f4562FormData[i].Line_data[parent_index].Line_data[k].DATA_TYPE === 'string' && vm.f4562FormData[i].Line_data[parent_index].Line_data[k].IS_EDITABLE == 'Y') {
										vm.f4562FormData[i].Line_data[parent_index].Line_data[k].IS_CHANGED_FLAG = "Y";
									}
								}
							}
							vm.f4562FormData[i].Line_data[parent_index].Line_data[index].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}
			console.log(vm.f4562FormData);
		}
		
		vm.updateAmount = function(index, line_no){
			console.log("We are updating the amounts for this column:::::: ", index);
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'CH' && vm.f4562FormData[i].Line_data[0].Line_no == line_no){
					for(var j=0; j < vm.f4562FormData[i].Line_data[index].Line_data_occ.length; j++ ){
						if(vm.f4562FormData[i].Line_data[index].Line_data_occ[j].ATTRIB_CALC_FORMULA != null){
							var line_data_values = vm.f4562FormData[i].Line_data[index].Line_data_occ;
							vm.f4562FormData[i].Line_data[index].Line_data_occ[j].ATTRIB_VALUE = vm.calcLineFormula(vm.f4562FormData[i].Line_data[index].Line_data_occ[j].ATTRIB_CALC_FORMULA, line_data_values);
							vm.f4562FormData[i].Line_data[index].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}
			vm.calcFinalAmounts();
		}
		
		vm.calcLineFormula = function(formula, line_data_values){
			console.log("Given formula and index of the row are ", formula, line_data_values);
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					var currentTabAtrribVal = $filter("filter")(line_data_values, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var lineAmt = parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			console.log("Given formula and index of the row are ", formula, line_data_values);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		}
		
		//console.log(vm.calcFormula((SALE_PRICE_1)-(COST_BASIS_1), 2));
		vm.calcFinalAmounts = function(){
			for (var i=0; i < vm.f4562FormData.length; i++){
				if(vm.f4562FormData[i].ROW_TYPE == 'TH'){
					for(var j=0; j < vm.f4562FormData[i].Line_data.length; j++){
						if(vm.f4562FormData[i].Line_data[j].ATTRIB_CALC_FORMULA != null){
							vm.f4562FormData[i].Line_data[j].ATTRIB_VALUE = vm.calc_final_amt(vm.f4562FormData[i].Line_data[j].ATTRIB_CALC_FORMULA);
							vm.f4562FormData[i].Line_data[j].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}
			getUnallocatedAmt();
		}
		
		vm.calc_final_amt = function(formula){	
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				var lineAmt = 0;
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					for(var k=0; k < vm.f4562FormData.length; k++){
						if(vm.f4562FormData[k].ROW_TYPE == 'CH'){
							for(var j=0; j < vm.f4562FormData[k].Line_data.length; j++){
								if(vm.f4562FormData[k].Line_data[j].Line_Type == "DATA_ROW" || vm.f4562FormData[k].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
									var row_data = vm.f4562FormData[k].Line_data[j].Line_data_occ;
									var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
									if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
										lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
									}
								}
							}
						} else if(vm.f4562FormData[k].ROW_TYPE == 'LH'){
							for(var j=0; j < vm.f4562FormData[k].Line_data.length; j++){
								var row_data = vm.f4562FormData[k].Line_data[j].Line_data;
								var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
								if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
									lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
								}
							}
						} else {
							var row_data = vm.f4562FormData[k].Line_data;
							var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
							if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
								lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
							}
						}
					}
					//console.log("Formula before Replacing with amount========", formula);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			//console.log("Given formula and index of the row are ", formula);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		}
	}
});