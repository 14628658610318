define([
    'angular',
    './ghostUsdbasisptiNonghostScreenService', 
    './ghostUsdbasisptiNonghostScreenCtrl',
    './ghostUsdbasisptiNonghostDeleteCtrl',
    
], function () {
    'use strict';
    return angular.module('app.FIR_Ghost_Usdbasispti_Nonghost_Screen', ['app.ghostUsdbasisptiNonghostScreenService','app.ghostUsdbasisptiNonghostScreenCtrl','app.ghostUsdbasisptiNonghostDeleteCtrl'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        // $stateProvider
        //     .state('sourcing-input-screen', {
        //         url: '/sourcing-input-screen',
        //         templateUrl: 'app/components/FIR_Sourcing_Input/sourcingInputScreen.html',
        //         backdrop: 'static',
        //         data:{},
        //         access:""
        //     })
    }])

});