define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.extEntityMaintenanceControllers',[])
        .directive('onlyDigitsLeadingZero', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.replace(/[^0-9]/g, '');

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return digits;
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        })

        .controller('extEntityMaintenanceController', ['GlobalService','$rootScope','$scope','$state','$log','GridFactory','JsonObjectFactory','$filter','AlertService','$timeout','$uibModal','GENERAL_CONFIG','USER_SETTINGS', 'workspaceFactory','ExtEntityMaintenanceServicesFactory','$uibModalInstance','rowData',extEntityMaintenanceController]);

    function extEntityMaintenanceController(GlobalService,$rootScope,$scope,$state,$log,GridFactory,JsonObjectFactory,$filter,AlertService,$timeout,$uibModal,GENERAL_CONFIG,USER_SETTINGS,workspaceFactory,ExtEntityMaintenanceServicesFactory,$uibModalInstance,rowData) {

        var vm = this;
        vm.isNew;
        vm.object_id;
        vm.LEID;
        vm.CDR_NO;
        vm.REPORTING_PERIOD;
        vm.COMP_PER;
        vm.ENTITY_PERSON_NAME;
        vm.HO_EIN;
        vm.HO_LEID;
        vm.HO_LE_NAME;
        vm.LE_TAX_TYPE;
        vm.LE_TYPE;
        vm.LE_TYPE_CODE;
        vm.PTR_TYPE;
        vm.REG_ADDRESS_CITY;
        vm.REG_ADDRESS_COUNTRY;
        vm.REG_ADDRESS_LINE1;
        vm.REG_ADDRESS_LINE2;
        vm.REG_ADDRESS_STATE;
        vm.REG_ADDRESS_ZIP;
        vm.SSN_EIN;
        vm.DISREGARDED = 'N';
        vm.PERSON_NAME;
        vm.SSN;
        vm.entityTypeList = [];

        vm.RETIRE_PLAN_KEY = '0';
        vm.TYPE_OF_ENTITY_KEY = 'C';


        vm.COUNTRY_NAME;
        vm.stateList = [];
        vm.STATE_NAME;
        vm.holeList = [];
        vm.ho_le;
        vm.einList = [];

        vm.vaildationErrMsg;

        vm.ssn_ein_List;

        vm.ssn_ein_already_Exists;

        vm.isSaveClicked = false;

        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.userSettings = USER_SETTINGS;
        vm.TAX_YEAR = filterParams.tax_year;
        vm.scenario = filterParams.scenario;
        vm.jcd_key = GlobalService.globalParams.jcd_key;
        vm.logged_in_user = vm.userSettings.user.sso_id;

        if (rowData === undefined) {
            vm.isNew = 'Y';
            vm.COMP_PER = 'C';
        }
        else {

            vm.isNew = 'N';
            console.log(rowData);

            vm.object_id          = rowData.object_id;
            vm.LEID               = rowData.LEID;
            vm.CDR_NO             = rowData.LEID;
            vm.REPORTING_PERIOD   = 1;
            vm.COMP_PER           = rowData.COMP_PER;
            console.log('vm.COMP_PER=' + vm.COMP_PER);
            //vm.ENTITY_PERSON_NAME = rowData.ENTITY_PERSON_NAME;
            vm.HO_EIN             = rowData.HO_EIN;
            vm.HO_LEID            = rowData.HO_LEID;
            vm.HO_LE_NAME         = rowData.HO_LE_NAME;
            vm.LE_TAX_TYPE        = rowData.LE_TAX_TYPE;
            vm.LE_TYPE            = rowData.LE_TYPE;
            vm.PTR_TYPE           = rowData.PTR_TYPE;

            vm.REG_ADDRESS_COUNTRY= rowData.REG_ADDRESS_COUNTRY;
            vm.REG_ADDRESS_LINE1  = rowData.REG_ADDRESS_LINE1;
            vm.REG_ADDRESS_LINE2  = rowData.REG_ADDRESS_LINE2;
            vm.REG_ADDRESS_CITY   = rowData.REG_ADDRESS_CITY;
            vm.REG_ADDRESS_STATE = rowData.REG_ADDRESS_STATE;
            vm.REG_ADDRESS_ZIP = rowData.REG_ADDRESS_ZIP;

            vm.RETIRE_PLAN_KEY        = rowData.RETIRE_PLAN_KEY;
            vm.TYPE_OF_ENTITY_KEY     = rowData.TYPE_OF_ENTITY_KEY;

            vm.COUNTRY_NAME = rowData.COUNTRY_NAME;
            vm.STATE_NAME = rowData.STATE_NAME;

            vm.HO_CDR_NO = rowData.HO_CDR_NO;
            vm.HO_REPORTING_PERIOD = rowData.HO_REPORTING_PERIOD;


            if(vm.LE_TYPE != null) {
                vm.LE_TYPE_CODE = vm.LE_TYPE.substring(0, vm.LE_TYPE.indexOf(' '));
                console.log('vm.LE_TYPE_CODE = ' + vm.LE_TYPE_CODE);
            }
            //vm.SSN_EIN            = rowData.SSN_EIN;
            if(vm.LEID != vm.HO_LEID) {
                vm.DISREGARDED = 'Y'
            }
            if(vm.COMP_PER == 'C') {
                vm.ENTITY_PERSON_NAME = rowData.ENTITY_PERSON_NAME;
                vm.SSN_EIN            = rowData.SSN_EIN;
                vm.PERSON_NAME = null;
                vm.SSN = null;
            }
            else if (vm.COMP_PER == 'P') {
                vm.PERSON_NAME = rowData.ENTITY_PERSON_NAME;
                vm.SSN = rowData.SSN_EIN;
                vm.ENTITY_PERSON_NAME = null;
                vm.SSN_EIN = null;
            }

            vm.ho_le = vm.HO_LEID+'/'+vm.HO_CDR_NO+'/'+vm.HO_REPORTING_PERIOD+' - '+ vm.HO_LE_NAME;
            console.log('vm.ho_le=' + vm.ho_le);
        }

        if(vm.isNew == 'Y') {
            vm.title = 'Add External Entity';
        }
        else {
            vm.title = 'Modify External Entity :' + vm.LEID;
        }

        vm.loadAllEntityTypes = function(tax_year) {
            var params = {
                "action_code" : 'pn93eo', "tax_year" : tax_year
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.entityTypeList = data.jsonObject;
                }
            );
        }

        vm.loadCountryList = function() {

            var params = {
                "action_code" : 'ynqodb', "tax_year" : filterParams.tax_year, "sso_id": vm.logged_in_user
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.countryList = data.jsonObject;
                    console.log(vm.countryList);
                }
            );
        }

        vm.loadStateList = function() {

            var params = {
                "action_code" : 'gfze8m', "tax_year" : filterParams.tax_year
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.stateList = data.jsonObject;
                    console.log(vm.stateList);
                }
            );
        }

        vm.loadHoleList = function() {

            var params = {
                "action_code" : 'oxfnru', "tax_year" : filterParams.tax_year, "scenario" : filterParams.scenario, "jcd_key" : vm.jcd_key
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.holeList = data.jsonObject;
                    console.log(vm.holeList);
                }
            );
        }

        vm.setHO_EIN = function(ho_le) {

            var le = ho_le.substring(0,15);
            console.log('le=' + le);

            var params = {
                "action_code" : 'zg806r', "tax_year" : filterParams.tax_year, "scenario" : filterParams.scenario, "jcd_key" : vm.jcd_key, "le":le
            };
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.einList = data.jsonObject;
                    console.log(vm.einList);
                    vm.HO_EIN = vm.einList[0].HO_EIN;
                }
            );
        }


        vm.loadAllEntityTypes(vm.TAX_YEAR);
        vm.loadHoleList();
        vm.loadCountryList();
        vm.loadStateList();

        vm.save = function() {

            var ssn_ein;
            var returnValue;
            var exists  = false;

            vm.isSaveClicked = true;

            if(vm.SSN_EIN != null)
                ssn_ein =  vm.SSN_EIN;
            else if (vm.SSN != null)
                ssn_ein =  vm.SSN;

            if(vm.isNew == 'Y')   {

                if(isNumber(ssn_ein)) {

                    var params = {
                        "action_code" : 'uaw9ux', "tax_year" : filterParams.tax_year, "scenario" : filterParams.scenario, "jcd_key" : vm.jcd_key, "ssn_ein" : ssn_ein
                    };

                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                        function(data) {

                            vm.ssn_ein_List = data.jsonObject;

                            if(vm.ssn_ein_List.length !=0 )
                                returnValue = vm.ssn_ein_List[0].SSN_EIN;

                            if ( returnValue!= null)
                                exists = true;
                            else
                                exists = false;

                            if(exists) {
                                if(confirm("The EIN/SSN already exists. Do you want to continue? Please confirm.")){
                                    vm.processSave();
                                }
                                else {
                                    vm.isSaveClicked = false;
                                }

                            }
                            else {
                                vm.processSave();
                            }

                        }
                    );

                }
                else {
                    vm.processSave();
                }
            }
            else {
                vm.processSave();
            }

        }

        vm.processSave = function () {

            var leTypeString;
            var countryName;
            var countryCode;
            var stateName;
            var stateCode;
            var holeString;
            var message = null;
            var extSetupSaveDtls = {};
            var returnClobSettingsObj = {};

            var validationStat = validateFormData();

            if(validationStat) {

                console.log('Validation passed... sennding details to service layer for saving in the DB');

                returnClobSettingsObj['tax_year'] = vm.TAX_YEAR;
                returnClobSettingsObj['scenario'] = vm.scenario;
                returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                returnClobSettingsObj['sso_id'] = vm.logged_in_user;

                extSetupSaveDtls['isNew'] = vm.isNew;

                if(vm.isNew != 'Y')
                    extSetupSaveDtls['LE_KEY'] = rowData.LE_KEY;
                else
                    extSetupSaveDtls['LE_KEY'] = '';

                if(vm.isNew != 'Y')
                    extSetupSaveDtls['COMBINATION_KEY'] = rowData.COMBINATION_KEY;
                else
                    extSetupSaveDtls['COMBINATION_KEY'] = '';

                if(vm.isNew != 'Y')
                    extSetupSaveDtls['LEID'] = vm.LEID;
                else
                    extSetupSaveDtls['LEID'] = '';


                if(vm.isNew != 'Y') {
                    //extSetupSaveDtls['HO_LEID'] = vm.HO_LEID;

                    holeString = $("#hole option:selected").text();
                    extSetupSaveDtls['HO_LEID'] = holeString.substr(0, holeString.indexOf('/')) ;
                }

                else {

                    holeString = $("#hole option:selected").text();
                    extSetupSaveDtls['HO_LEID'] = holeString.substr(0, holeString.indexOf('/')) ;

                }


                extSetupSaveDtls['COMP_PER'] = vm.COMP_PER;
                if(vm.ENTITY_PERSON_NAME != null)
                    extSetupSaveDtls['entity_person_name'] = vm.ENTITY_PERSON_NAME;
                else if (vm.PERSON_NAME != null)
                    extSetupSaveDtls['entity_person_name'] = vm.PERSON_NAME;
                if(vm.SSN_EIN != null)
                    extSetupSaveDtls['SSN_EIN'] = vm.SSN_EIN;
                else if(vm.SSN != null)
                    extSetupSaveDtls['SSN_EIN'] = vm.SSN;

                extSetupSaveDtls['DISREGARDED'] = vm.DISREGARDED;

                leTypeString = $("#entity_type option:selected").text();
                extSetupSaveDtls['LE_TYPE'] = leTypeString.substr(0, leTypeString.indexOf(' ')) ;


                extSetupSaveDtls['RETIRE_PLAN'] = vm.RETIRE_PLAN_KEY;

                extSetupSaveDtls['TYPE_OF_ENTITY'] = vm.TYPE_OF_ENTITY_KEY;

                countryName = $("#countryName option:selected").text();
                countryCode = countryName.substr(0, countryName.indexOf(' ')) ;
                extSetupSaveDtls['REG_ADDRESS_COUNTRY'] = countryCode;

                extSetupSaveDtls['REG_ADDRESS_LINE1'] = vm.REG_ADDRESS_LINE1;

                if(vm.REG_ADDRESS_LINE2 == null)
                    extSetupSaveDtls['REG_ADDRESS_LINE2'] = '';
                else
                    extSetupSaveDtls['REG_ADDRESS_LINE2'] = vm.REG_ADDRESS_LINE2;

                extSetupSaveDtls['REG_ADDRESS_CITY'] = vm.REG_ADDRESS_CITY;

                if(countryCode == 'US') {
                    stateName = $("#stateName option:selected").text();
                    stateCode = stateName.substr(0, stateName.indexOf(' ')) ;
                    extSetupSaveDtls['REG_ADDRESS_STATE'] = stateCode;
                }
                else {
                    if(vm.REG_ADDRESS_STATE == null)
                        extSetupSaveDtls['REG_ADDRESS_STATE'] = '';
                    else
                        extSetupSaveDtls['REG_ADDRESS_STATE'] = vm.REG_ADDRESS_STATE;
                }

                if(vm.REG_ADDRESS_ZIP == null)
                    extSetupSaveDtls['REG_ADDRESS_ZIP'] = '';
                else
                    extSetupSaveDtls['REG_ADDRESS_ZIP'] = vm.REG_ADDRESS_ZIP;

                console.log('extSetupSaveDtls = ', JSON.stringify(extSetupSaveDtls));

                message = "External Entity saved successfully";
                sendDetails(returnClobSettingsObj,extSetupSaveDtls,message);
            }
            else {
                AlertService.add("danger", vm.vaildationErrMsg, 4000);
                vm.isSaveClicked = false;
            }
        }

        function validateFormData() {

            var validationStatus = true;
            var x;
            var y;

            if(vm.COMP_PER=='C') {
                if(vm.ENTITY_PERSON_NAME == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'Entity Name is mandatory';
                    return validationStatus;
                }
            }
            if(vm.COMP_PER=='C' && vm.DISREGARDED == 'Y') {
                if(vm.ho_le == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'Must select a Home Office for a disregarded Entity';
                    return validationStatus;
                }
            }

            if(vm.COMP_PER=='P') {
                if(vm.PERSON_NAME == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'Person Name is mandatory';
                    return validationStatus;
                }
            }

            if(vm.LE_TYPE == null) {
                validationStatus = false;
                vm.vaildationErrMsg = 'Entity Type is mandatory';
                return validationStatus;
            }
            /*if(vm.COUNTRY_NAME == null) {
                validationStatus = false;
                vm.vaildationErrMsg = 'Address Country is mandatory';
                return validationStatus;
            }*/

            if(vm.COUNTRY_NAME == 'US - United States of America') {

                if(vm.COMP_PER=='C' && vm.SSN_EIN == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'EIN is mandatory, if the country is US';
                    return validationStatus;
                }

                if(vm.COMP_PER=='P' && vm.SSN == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'SSN is mandatory, if the country is US';
                    return validationStatus;
                }

                if(vm.REG_ADDRESS_LINE1 == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'Address Line1 is mandatory';
                    return validationStatus;
                }
                if(vm.REG_ADDRESS_CITY == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'City is mandatory';
                    return validationStatus;
                }
                if(vm.STATE_NAME == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'State is mandatory';
                    return validationStatus;
                }
                if(vm.REG_ADDRESS_ZIP == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'Postal / Zip Code is mandatory';
                    return validationStatus;
                }
            }
            if(vm.COUNTRY_NAME != null && vm.COUNTRY_NAME != 'US - United States of America') {
                if(vm.REG_ADDRESS_LINE1 == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'Address Line1 is mandatory';
                    return validationStatus;
                }
                if(vm.REG_ADDRESS_CITY == null) {
                    validationStatus = false;
                    vm.vaildationErrMsg = 'City is mandatory';
                    return validationStatus;
                }
            }

            //if(vm.COUNTRY_NAME == 'US - United States of America') {

                if(vm.SSN_EIN != null) {

                    x = vm.SSN_EIN.toString();
                    console.log(x.length);

                    if(x.length < 9) {
                        validationStatus = false;
                        vm.vaildationErrMsg = 'EIN must be 9 characters.';
                        return validationStatus;
                    }
                }
                if(vm.SSN != null) {

                    y = vm.SSN.toString();
                    console.log(y.length);

                    if(y.length < 9) {
                        validationStatus = false;
                        vm.vaildationErrMsg = 'SSN must be 9 characters.';
                        return validationStatus;
                    }
                }
            //}


            return validationStatus;
        }


        function sendDetails(returnClobSettingsObj, extSetupSaveDtls, message) {

            ExtEntityMaintenanceServicesFactory.saveExternalEntitySetup(returnClobSettingsObj,extSetupSaveDtls).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {

                    AlertService.add("success", message, 4000);
                    var args = {
                        leid: vm.LEID,
                        gridFilter: {
                            leid: vm.LEID
                        }
                    };

                    $timeout(function(){
                        $uibModalInstance.dismiss('cancel');
                    }, 4000);

                    $timeout(function(){
                        $rootScope.$emit('gridUpdate', args);
                    },12000);

                };

            });
        }

        vm.resetHO = function() {

            if(vm.isNew == 'Y' && vm.DISREGARDED == 'N') {
                vm.HO_LE_NAME = '';
                vm.ho_le = '';
                vm.HO_EIN = '';
            }
        }

        vm.reset = function() {
            vm.isNew = 'Y';
            vm.COMP_PER = 'C';
            vm.ENTITY_PERSON_NAME = '';
            vm.SSN_EIN = '';
            vm.HO_EIN = '';
            vm.DISREGARDED = '';
            vm.COUNTRY_NAME = '';
            vm.LE_TYPE = '';
            vm.REG_ADDRESS_LINE1 = '';
            vm.REG_ADDRESS_LINE2 = '';
            vm.REG_ADDRESS_CITY = '';
            vm.STATE_NAME ='';
            vm.REG_ADDRESS_STATE='';
            vm.REG_ADDRESS_ZIP='';
            vm.RETIRE_PLAN_KEY='0';
            vm.TYPE_OF_ENTITY = 'C';
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

        function isNumber(number) {
            return angular.isNumber(number) && !isNaN(number);
        }

    }

});