define([
	'angular'
 
 ], function () {
	'use strict';
 
	var services =  angular.module('app.cfcsWithRecapAcctsService',[])
		.factory("cfcsWithRecapAcctsFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
					,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
			
			,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
					,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
				var factory = {};
				var returnObj = {};
				var cfcsWithRecapAcctsFactory ={};
				cfcsWithRecapAcctsFactory.url ={};
				cfcsWithRecapAcctsFactory.url.getGtapsCfcsWithRecapAcctsData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=6bl9nc";//Action id 1190
				
				cfcsWithRecapAcctsFactory.getGtapsCfcsWithRecapAcctsData = function(passedParams) {
					 var params = {"process_name":"GTAPS Get CFC's with Recapture Accounts"};
					params = _.merge({},  passedParams, params);
					var promise = $http({
						method: "post",
						url: cfcsWithRecapAcctsFactory.url.getGtapsCfcsWithRecapAcctsData,
						data: params
					}).then(function (response) {
						//var status = response.status;
						return response.data;
					});
		 
					return promise;
				};
			
				return cfcsWithRecapAcctsFactory;
		}]);
 
		  return services;
 });