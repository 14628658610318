define([
    'angular',
    './post86TaxHistoryReportCtrl',
    './post86TaxHistoryReportService', 
], function () {
    'use strict';
    return angular.module('app.FIR_Post86_Tax_History', ['app.post86TaxHistoryReportCtrl', 'app.post86TaxHistoryReportService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('post-86-tax-history-report', {
                url: '/post-86-tax-history-report',
                templateUrl: 'app/components/FIR_Post86_Tax_History/post86TaxHistoryReport.html',
                backdrop: 'static',
                data:{},
                access:""
            })
    }])

});