define([
    'angular'

], function () {
    'use strict';




    var controllers =  angular.module('app.partnershipControllers',[])
        .controller('partnershipSetupController', ['GlobalService','$rootScope','$scope','$state','$log','GridFactory','JsonObjectFactory','$filter','AlertService','$timeout','$uibModal','GENERAL_CONFIG','USER_SETTINGS', 'workspaceFactory','PartnershipServicesFactory','$uibModalInstance','rowData',partnershipSetupController])
        .controller('partnerAllocationController', ['GlobalService','$rootScope','$scope','$state','$log','GridFactory','JsonObjectFactory','$filter','AlertService','$timeout','$uibModal','GENERAL_CONFIG','USER_SETTINGS', 'workspaceFactory', 'PartnerAllocServicesFactory','$uibModalInstance','rowData',partnerAllocationController])

    function partnershipSetupController(GlobalService,$rootScope,$scope,$state,$log,GridFactory,JsonObjectFactory,$filter,AlertService,$timeout,$uibModal,GENERAL_CONFIG,USER_SETTINGS,workspaceFactory,PartnershipServicesFactory,$uibModalInstance,rowData) {

        var vm = this;
        vm.isSaveClicked = false;
        //$scope.data.dataLoading = true;
        vm.syncPosting = '0';

        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.tax_year = filterParams.tax_year;
        vm.scenario = filterParams.scenario;
        vm.jcd_key = GlobalService.globalParams.jcd_key;

        vm.scenarioLabel = null;
       /* _.filter(workspaceFactory.activeScreen.filters.filters,function(item){return item.selected}).forEach(function (value) {
            if(value.label=='Scenario (PCS)') {
                vm.scenarioLabel = value.selected.LABEL;
            }
        });*/
        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = 'Partnership/Partner Setup Details ( Tax Year: ' + vm.tax_year + ' - Scenario: ' +  vm.scenarioLabel + ' )';

        vm.dateOptions = false;

        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;

        vm.partnerList = null;
        vm.postingComboList = null;
        vm.partnerLEList = [];
        vm.postingLOVArray = [];
        vm.partnerLE = null;
        vm.partnerListOrig = null;


        vm.effectiveOwnershipPercentageList = null;
        vm.allocationPercentageList = null;
        vm.totalEffectiveOwnershipPercentage = 0;
        vm.totalAllocationPercentage = 0;

        vm.vaildattionErrMsg = null;

        vm.startDt;
        vm.endDt;
        vm.startDt1;
        vm.endDt1;
        vm.startDt2;
        vm.endDt2;
        vm.startDt3;
        vm.endDt3;
        vm.startDt4;
        vm.endDt4;
        vm.startDt5;
        vm.endDt5;
        vm.startDt6;
        vm.endDt6;
        vm.selectedName1;
        vm.selectedName2;
        vm.selectedName3;
        vm.selectedName4;
        vm.selectedName5;
        vm.selectedName6;
        vm.selectedName7;


        vm.ACTIVE_Y_OR_N=rowData.ACTIVE_Y_OR_N;
        vm.ALLOCATION_PERCETAGE=rowData.ALLOCATION_PERCETAGE;
        vm.ALLOCATION_RATIO_TYPE=rowData.ALLOCATION_RATIO_TYPE;
        vm.EFFECTIVE_DATE_BEGIN=rowData.EFFECTIVE_DATE_BEGIN;
        vm.EFFECTIVE_DATE_END=rowData.EFFECTIVE_DATE_END;
        vm.EFFECTIVE_OWNERSHIP_PERCENTAGE=rowData.EFFECTIVE_OWNERSHIP_PERCENTAGE;
        vm.GENERAL_PARTNER=rowData.GENERAL_PARTNER;
        vm.PARTNER_HO_LEID=rowData.PARTNER_HO_LEID;
        vm.PARTNER_HO_NAME=rowData.PARTNER_HO_NAME;
        vm.PARTNER_HO_REPORTING_PERIOD=rowData.PARTNER_HO_REPORTING_PERIOD;
        vm.PARTNER_HO_TAX_CODE=rowData.PARTNER_HO_TAX_CODE;
        vm.PARTNER_HO_US_TAX_TYPE=rowData.PARTNER_HO_US_TAX_TYPE;
        vm.PARTNER_LEID=rowData.PARTNER_LEID;
        vm.PARTNER_NAME=rowData.PARTNER_NAME;
        vm.PARTNER_REPORTING_PERIOD=rowData.PARTNER_REPORTING_PERIOD;
        vm.PARTNER_TAX_CODE=rowData.PARTNER_TAX_CODE;
        vm.PARTNER_US_TAX_TYPE=rowData.PARTNER_US_TAX_TYPE;
        vm.POSTING_PARTNER_LEID=rowData.POSTING_PARTNER_LEID;
        vm.POSTING_PARTNER_NAME=rowData.POSTING_PARTNER_NAME;
        vm.POSTING_PARTNER_REPORTING_PERIOD=rowData.POSTING_PARTNER_REPORTING_PERIOD;
        vm.POSTING_PARTNER_TAX_CODE=rowData.POSTING_PARTNER_TAX_CODE;
        vm.PSHIP_BEGIN_DATE=rowData.PSHIP_BEGIN_DATE;
        vm.PSHIP_BUSINESS=rowData.PSHIP_BUSINESS;
        vm.PSHIP_COMPANY_CODE=rowData.PSHIP_COMPANY_CODE;
        vm.PSHIP_CURRENCY=rowData.PSHIP_CURRENCY;
        vm.PSHIP_END_DATE=rowData.PSHIP_END_DATE;
        vm.PSHIP_HO_COMPANY_CODE=rowData.PSHIP_HO_COMPANY_CODE;
        vm.PSHIP_HO_LEID=rowData.PSHIP_HO_LEID;
        vm.PSHIP_HO_NAME=rowData.PSHIP_HO_NAME;
        vm.PSHIP_HO_REPORTING_PERIOD=rowData.PSHIP_HO_REPORTING_PERIOD;
        vm.PSHIP_HO_TAX_CODE=rowData.PSHIP_HO_TAX_CODE;
        vm.PSHIP_HO_US_TAX_TYPE=rowData.PSHIP_HO_US_TAX_TYPE;
        vm.PSHIP_INTERNAL_OR_EXTERNAL_INDICATOR=rowData.PSHIP_INTERNAL_OR_EXTERNAL_INDICATOR;
        vm.PSHIP_LEID=rowData.PSHIP_LEID;
        vm.PSHIP_NAME=rowData.PSHIP_NAME;
        vm.PSHIP_REPORTING_PERIOD=rowData.PSHIP_REPORTING_PERIOD;
        vm.PSHIP_TAX_CODE=rowData.PSHIP_TAX_CODE;
        vm.PSHIP_US_TAX_TYPE=rowData.PSHIP_US_TAX_TYPE;
        vm.TAX_MATTERS_PARTNER=rowData.TAX_MATTERS_PARTNER;
        vm.US_RETURN_PREPARED_BY=rowData.US_RETURN_PREPARED_BY;
        vm.PTR_K1_INITIAL = rowData.PTR_K1_INITIAL;
        vm.PTR_K1_FINAL = rowData.PTR_K1_FINAL;
        vm.PSHIP_COMBINATION_KEY = rowData.COMBINATION_KEY;


        var params = {"action_code":'3nizjt',"tax_year": vm.tax_year,"scenario": vm.scenario,"jcd_key": vm.jcd_key,"pship_cc": vm.PSHIP_COMBINATION_KEY,"sso_id": vm.logged_in_user};


        var params1 = {"action_code":'j6y2x9',"tax_year": vm.tax_year,"scenario": filterParams.scenario,"leid": vm.PSHIP_LEID, "cdr_no": vm.PSHIP_TAX_CODE,"rp": vm.PSHIP_REPORTING_PERIOD,"me_code":vm.PSHIP_BUSINESS,"currency": vm.PSHIP_CURRENCY,"sso_id": vm.logged_in_user};


        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
            console.log(data);
            vm.partnerList = data.jsonObject;
            vm.partnerListOrig = angular.copy(vm.partnerList);

            for(var i=0; i < vm.partnerList.length; i++) {
                vm.totalEffectiveOwnershipPercentage = vm.totalEffectiveOwnershipPercentage + vm.partnerList[i].EFFECTIVE_OWNERSHIP_PERCENTAGE;
                vm.totalAllocationPercentage = vm.totalAllocationPercentage + vm.partnerList[i].ALLOCATION_PERCENTAGE;
                vm.partnerLE = vm.partnerList[i].PARTNER_LEID+'/'+vm.partnerList[i].PARTNER_TAX_CODE+'/'+vm.partnerList[i].PARTNER_REPORTING_PERIOD;
                vm.partnerLEList.push(vm.partnerLE);
            }

            if(!isNumber(vm.totalEffectiveOwnershipPercentage))
                vm.totalEffectiveOwnershipPercentage = 0;

            if(!isNumber(vm.totalAllocationPercentage))
                vm.totalAllocationPercentage = 0;

            vm.totalAllocationPercentage = roundToSix(vm.totalAllocationPercentage);

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params1).then(function (postingData) {
                console.log(postingData);
                vm.postingComboList = postingData.jsonObject;
                for(var i = 0; i < vm.partnerLEList.length; i++) {
                    var postingLOV = $filter("filter")(vm.postingComboList, {PTR_LE:vm.partnerLEList[i]});
                    vm.postingLOVArray.push(postingLOV);
                }

            });

        })/*.finally(function() {
              $scope.data.dataLoading = false;
          })*/;


        console.log("Row DATA ::@@@@@",rowData);

        vm.save = function() {

            var pshipSetupSaveDtls = [];
            var returnClobSettingsObj = {};
            var message = null;
            var validationStat = validateSetupData();
            var oldPtrPosting = null;
            var newPtrPosting = null;
            var oldPostingAttr = [];
            var newPostingAttr = [];

            vm.isSaveClicked = true;

            if(validationStat) {

                console.log('Validation passed... sennding details to service layer for saving in the DB');

                returnClobSettingsObj['tax_year'] = vm.tax_year;
                returnClobSettingsObj['scenario'] = vm.scenario;
                returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                returnClobSettingsObj['pship_cc_key'] = vm.PSHIP_COMBINATION_KEY;
                returnClobSettingsObj['sync_posting'] = vm.syncPosting;


                //returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;

                message = "Partner Setup changes saved successfully";

                for(var i = 0; i< vm.partnerList.length; i++) {
                    var pshipPtrPostingRec = {};

                    pshipPtrPostingRec['leid'] = vm.PSHIP_LEID;
                    pshipPtrPostingRec['cdr_no'] = vm.PSHIP_TAX_CODE;
                    pshipPtrPostingRec['reporting_period'] = vm.PSHIP_REPORTING_PERIOD;
                    pshipPtrPostingRec['me_code'] = vm.PSHIP_BUSINESS;
                    pshipPtrPostingRec['curr'] = vm.PSHIP_CURRENCY;
                    pshipPtrPostingRec['pship_cc_key'] = vm.PSHIP_COMBINATION_KEY;
                    pshipPtrPostingRec['pship_int_ext'] = vm.PSHIP_INTERNAL_OR_EXTERNAL_INDICATOR;

                    pshipPtrPostingRec['pship_ptr_key'] = vm.partnerList[i].PSHIP_PTR_KEY;

                    oldPtrPosting = vm.partnerListOrig[i].POSTING_COMBO;
                    if(oldPtrPosting != null) {

                        pshipPtrPostingRec['old_ptr_cc_key'] = vm.partnerListOrig[i].PTR_COMBINATION_KEY;
                        oldPostingAttr = oldPtrPosting.split("/");
                        pshipPtrPostingRec['old_ptr_posting_leid'] = oldPostingAttr[0];
                        pshipPtrPostingRec['old_ptr_posting_cdr_no'] = oldPostingAttr[1];
                        pshipPtrPostingRec['old_ptr_posting_reporting_period'] = oldPostingAttr[2];
                        pshipPtrPostingRec['old_ptr_posting_me_code'] = oldPostingAttr[3];
                        pshipPtrPostingRec['old_ptr_posting_curr'] = oldPostingAttr[4];
                        pshipPtrPostingRec['old_is_inactive'] = vm.partnerListOrig[i].IS_INACTIVE;
                        pshipPtrPostingRec['old_ptr_k1_initial'] = vm.partnerListOrig[i].PTR_K1_INITIAL;
                        pshipPtrPostingRec['old_ptr_k1_final'] = vm.partnerListOrig[i].PTR_K1_FINAL;
                    }
                    else {
                        pshipPtrPostingRec['old_ptr_cc_key'] = null;
                        pshipPtrPostingRec['old_ptr_posting_leid'] = null;
                        pshipPtrPostingRec['old_ptr_posting_cdr_no'] = null;
                        pshipPtrPostingRec['old_ptr_posting_reporting_period'] = null;
                        pshipPtrPostingRec['old_ptr_posting_me_code'] = null;
                        pshipPtrPostingRec['old_ptr_posting_curr'] = null;
                        pshipPtrPostingRec['old_is_inactive'] = null;
                        pshipPtrPostingRec['old_ptr_k1_initial'] = vm.partnerListOrig[i].PTR_K1_INITIAL;
                        pshipPtrPostingRec['old_ptr_k1_final'] = vm.partnerListOrig[i].PTR_K1_FINAL;
                    }

                    newPtrPosting = vm.partnerList[i].POSTING_COMBO;
                    if(newPtrPosting != null) {
                    newPostingAttr = newPtrPosting.split("/");
                    pshipPtrPostingRec['new_ptr_cc_key'] = vm.partnerList[i].PTR_COMBINATION_KEY;
                    pshipPtrPostingRec['new_ptr_posting_leid'] = newPostingAttr[0];
                    pshipPtrPostingRec['new_ptr_posting_cdr_no'] = newPostingAttr[1];
                    pshipPtrPostingRec['new_ptr_posting_reporting_period'] = newPostingAttr[2];
                    pshipPtrPostingRec['new_ptr_posting_me_code'] = newPostingAttr[3];
                    pshipPtrPostingRec['new_ptr_posting_curr'] = newPostingAttr[4];
                    }
                    else {
                        pshipPtrPostingRec['new_ptr_cc_key'] = null;
                        pshipPtrPostingRec['new_ptr_posting_leid'] = null;
                        pshipPtrPostingRec['new_ptr_posting_cdr_no'] = null;
                        pshipPtrPostingRec['new_ptr_posting_reporting_period'] = null;
                        pshipPtrPostingRec['new_ptr_posting_me_code'] = null;
                        pshipPtrPostingRec['new_ptr_posting_curr'] = null;
                    }
                    pshipPtrPostingRec['new_is_inactive'] = vm.partnerList[i].IS_INACTIVE;
                    pshipPtrPostingRec['new_ptr_k1_initial'] = vm.partnerList[i].PTR_K1_INITIAL;
                    pshipPtrPostingRec['new_ptr_k1_final'] = vm.partnerList[i].PTR_K1_FINAL;
                    pshipPtrPostingRec['posting_alloc'] = vm.partnerList[i].ALLOCATION_PERCENTAGE/100;
                    //push it to pshipSetupSaveDtls array
                    pshipSetupSaveDtls.push(pshipPtrPostingRec);

                }

                console.log('pshipSetupSaveDtls = ', pshipSetupSaveDtls);
                sendDetails(returnClobSettingsObj,pshipSetupSaveDtls,message);
            }
            else {
                AlertService.add("danger", vm.vaildattionErrMsg, 4000);
                vm.isSaveClicked = false;
            }

        }

        function validateSetupData() {

            var alloc_per;
            var validationStatus = true;

            if(vm.PSHIP_INTERNAL_OR_EXTERNAL_INDICATOR == 'External') {
                return validationStatus;
            }
            else {

                for(var i=0; i < vm.partnerList.length; i++) {

                    alloc_per = vm.partnerList[i].ALLOCATION_PERCENTAGE;

                    /*if(alloc_per == 0) {
                        validationStatus = false;
                        vm.vaildattionErrMsg = 'Partner allocation percentage cannot be zero';
                        break;
                    }*/

                    if(alloc_per < 0) {
                        validationStatus = false;
                        vm.vaildattionErrMsg = 'Partner allocation percentage cannot be less than zero';
                        break;
                    }

                    /*if(alloc_per == 100) {
                        validationStatus = false;
                        vm.vaildattionErrMsg = 'Partner allocation percentage cannot be 100';
                        break;
                    }*/

                    if(alloc_per > 100) {
                        validationStatus = false;
                        vm.vaildattionErrMsg = 'Partner allocation percentage cannot be more than 100';
                        break;
                    }


                    console.log('alloc_per = ', alloc_per);
                    console.log('Posting Combo = ', vm.partnerList[i].POSTING_COMBO);

                    if(alloc_per != null && angular.isUndefined(vm.partnerList[i].POSTING_COMBO)) {
                        validationStatus = false;
                        vm.vaildattionErrMsg = 'Posting Partner Combo must be selected for non-zero Allocation %';
                        break;
                    }

                }

                if(validationStatus == true) {

                    if(vm.totalAllocationPercentage > 100) {
                        validationStatus = false;
                        vm.vaildattionErrMsg = 'The sum of allocation percentage must be equal or less than 100';
                    }
                    /*if(vm.PSHIP_INTERNAL_OR_EXTERNAL_INDICATOR == 'External') {
                        if(vm.totalAllocationPercentage > 100) {
                            validationStatus = false;
                            vm.vaildattionErrMsg = 'For External Partnership, the sum of allocation percentage must be equal or less than 100';
                        }
                    }*/
                    if(vm.PSHIP_INTERNAL_OR_EXTERNAL_INDICATOR == 'Internal') {
                        if(vm.totalAllocationPercentage != 100) {
                            validationStatus = false;
                            vm.vaildattionErrMsg = 'For Internal Partnership, the sum of allocation percentage must be equal to 100';
                        }
                    }
                }

                return validationStatus;
            }

        }

        function sendDetails(returnClobSettingsObj, pshipSetupSaveDtls, message) {

            PartnershipServicesFactory.savePartnershipSetupForm(returnClobSettingsObj,pshipSetupSaveDtls).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {
                    //vm.crudLoading = false;
                    AlertService.add("success", message, 4000);
                    var args = {
                        pship_leid: returnClobSettingsObj.pship_leid,
                        gridFilter: {
                            pship_leid: returnClobSettingsObj.pship_leid
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);
                };

            });
        }

        vm.reset = function() {
            vm.partnerList = angular.copy(vm.partnerListOrig);
            vm.totalAllocationPercentage = 0;
            for(var i=0; i < vm.partnerList.length; i++) {
                vm.totalAllocationPercentage = vm.totalAllocationPercentage + vm.partnerList[i].ALLOCATION_PERCENTAGE;
            }
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

        vm.setPosting = function(ptr) {
            console.log(ptr);



            var postingAttr = [];

            //ptr.PTR_COMBINATION_KEY = $filter("filter")(vm.postingComboList, {PTR_LE:ptr.PARTNER_LEID+'/'+ptr.PARTNER_TAX_CODE+'/'+ptr.PARTNER_REPORTING_PERIOD, POSTING_COMBO:ptr.POSTING_COMBO});

            ptr.PTR_COMBINATION_KEY = (_.find(vm.postingComboList, {PTR_LE: ptr.PARTNER_LEID+'/'+ptr.PARTNER_TAX_CODE+'/'+ptr.PARTNER_REPORTING_PERIOD, POSTING_COMBO: ptr.POSTING_COMBO})).COMBINATION_KEY;

            postingAttr = ptr.POSTING_COMBO.split("/");

            ptr.POSTING_PARTNER_LEID = postingAttr[0];
            ptr.POSTING_PARTNER_TAX_CODE = postingAttr[1];
            ptr.POSTING_PARTNER_REPORTING_PERIOD = postingAttr[2];
            ptr.POSTING_PARTNER_BUSINESS = postingAttr[3];
            ptr.POSTING_PARTNER_COMPANY_CODE = postingAttr[1];
            ptr.POSTING_PARTNER_CURRENCY = postingAttr[4];
            ptr.POSTING_PARTNER_NAME = $filter("filter")(vm.postingComboList, {POSTING_COMBO:ptr.POSTING_COMBO})[0].POSTING_LE_NAME;


            /*if($filter("filter")(vm.partnerListOrig,{POSTING_COMBO:ptr.POSTING_COMBO}).length == 0)
                ptr.ALLOCATION_PERCENTAGE = 0;
            else
                ptr.ALLOCATION_PERCENTAGE = $filter("filter")(vm.partnerListOrig,{POSTING_COMBO:ptr.POSTING_COMBO})[0].ALLOCATION_PERCENTAGE;*/

            vm.adjustAllocPercentage(ptr);


        }

        vm.adjustAllocPercentage = function(ptr) {
            console.log('... adjustAllocPercentage() called ...');

            vm.totalAllocationPercentage = 0.000000;

            for(var i=0; i < vm.partnerList.length; i++) {

                if(angular.isUndefined(vm.partnerList[i].ALLOCATION_PERCENTAGE))
                    vm.partnerList[i].ALLOCATION_PERCENTAGE = 0.000000;

                vm.totalAllocationPercentage = vm.totalAllocationPercentage + vm.partnerList[i].ALLOCATION_PERCENTAGE;

            }

            vm.totalAllocationPercentage = roundToSix(vm.totalAllocationPercentage);

        }

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        }

        function isNumber(number) {
            return angular.isNumber(number) && !isNaN(number);
        }


        function roundToSix(num) {
            return +(Math.round(num + "e+6")  + "e-6");
        }



    }

    function partnerAllocationController(GlobalService,$rootScope,$scope,$state,$log,GridFactory,JsonObjectFactory,$filter,AlertService,$timeout,$uibModal,GENERAL_CONFIG,USER_SETTINGS,workspaceFactory,PartnerAllocServicesFactory,$uibModalInstance,rowData){

        console.log('Called the modal mock-up for external');

        var vm = this;
        vm.allocation_heading = null;
        vm.isSaveClicked = false;

        vm.alphaArray = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','aa','ab','ac','ad','ae','af','ag','ah','ai','aj','ak','al','am','an','ao','ap','aq','ar','as','at','au','av','aw','ax','ay','az'];

        vm.postingMetaData = [];
        vm.countryKeys = [];
        vm.countryDetails = [];
        vm.partHeaderElement = {};
        vm.clonedCountryHeaderElement = {};
        vm.partHeaderIIFormData = [];
        vm.partHeaderIIIFormData = [];
        vm.partHeaderIIIFSIFormData = [];
        vm.tiFormData= [];
        vm.creditFormData = [];
        vm.amtaceFormData = [];
        vm.otherFormData = [];
        vm.taxcapitalFormData = [];
        vm.formData = [];
        vm.fsiFormData = [];
        vm.countryList = [];
        vm.issueList = [];
        vm.issueID = 0;
        vm.selectedIssue = ''; // {}

        vm.allDropdownList = [];
        vm.OtherFormDataObj = [];

        vm.fsiTableRows = [];

        vm.partHeaderDynamic = [];
        vm.partDetailDynamic = [];

        var countryCount = 1;
        var fsiFormElement;
        var countryKey;
        var countryDetail;
        var attribValue;

        vm.showK1Caplib = false;
        vm.showTI = true;
        vm.showCredits = false;
        vm.showAMTACE = false;
        vm.showOTHER = false;
        vm.showTAXCAPITAL = false;
        vm.showFSI = true;

        vm.rowObject = [];
        vm.allTableRows = [];

        vm.COUNTRY_KEY = 1;
        vm.COUNTRY_VALUE = '<-- Please select a country from the dropdown -->';

        vm.colspan = 2;

        vm.selectedCountry = {};
        vm.selectedCountryKey = 0;
        vm.selectedCountryValue;

        vm.allFormFormulaList=[];
        vm.formFormulaList=[];

        vm.formListTaxCap = [];
        vm.formulaListTaxCap = [];

        vm.tiLinesByFLCCode;

        vm.taxCapitalFormulaList_K1CAPLIB = [];
        vm.taxCapitalFormulaList_TI = [];
        vm.taxCapitalFormulaList_CREDIT = [];
        vm.taxCapitalFormulaList_AMTACE = [];
        vm.taxCapitalFormulaList_OTHER = [];
        vm.taxCapitalFormulaList_FSI = [];

        vm.taxCapitalFormulaList_DEPENDENT = [];

        vm.countriesToRemove = [];

        vm.fsiGIBasketData = [];
        vm.fsiGIBasketDataByCountry = [];

        vm.beatGiltiFdiiData = [];

        vm.sn = null;
        vm.pn = null;
        vm.sm = null;

        vm.formList = [];

        vm.sourceList = [];

        var clonedCountryElement = {};

        vm.ACTIVE_Y_OR_N=rowData.ACTIVE_Y_OR_N;
        vm.ALLOCATION_PERCETAGE=rowData.ALLOCATION_PERCETAGE;
        vm.ALLOCATION_RATIO_TYPE=rowData.ALLOCATION_RATIO_TYPE;
        vm.EFFECTIVE_DATE_BEGIN=rowData.EFFECTIVE_DATE_BEGIN;
        vm.EFFECTIVE_DATE_END=rowData.EFFECTIVE_DATE_END;
        vm.EFFECTIVE_OWNERSHIP_PERCENTAGE=rowData.EFFECTIVE_OWNERSHIP_PERCENTAGE;
        vm.GENERAL_PARTNER=rowData.GENERAL_PARTNER;
        vm.PARTNER_HO_LEID=rowData.PARTNER_HO_LEID;
        vm.PARTNER_HO_NAME=rowData.PARTNER_HO_NAME;
        vm.PARTNER_HO_REPORTING_PERIOD=rowData.PARTNER_HO_REPORTING_PERIOD;
        vm.PARTNER_HO_TAX_CODE=rowData.PARTNER_HO_TAX_CODE;
        vm.PARTNER_HO_US_TAX_TYPE=rowData.PARTNER_HO_US_TAX_TYPE;
        vm.PARTNER_LEID=rowData.PARTNER_LEID;
        vm.PARTNER_NAME=rowData.PARTNER_NAME;
        vm.PARTNER_REPORTING_PERIOD=rowData.PARTNER_REPORTING_PERIOD;
        vm.PARTNER_TAX_CODE=rowData.PARTNER_TAX_CODE;
        vm.PARTNER_US_TAX_TYPE=rowData.PARTNER_US_TAX_TYPE;
        vm.POSTING_PARTNER_LEID=rowData.POSTING_PARTNER_LEID;
        vm.POSTING_PARTNER_NAME=rowData.POSTING_PARTNER_NAME;
        vm.POSTING_PARTNER_REPORTING_PERIOD=rowData.POSTING_PARTNER_REPORTING_PERIOD;
        vm.POSTING_PARTNER_TAX_CODE=rowData.POSTING_PARTNER_TAX_CODE;
        vm.POSTING_PARTNER_BUSINESS = rowData.POSTING_PARTNER_BUSINESS;
        vm.POSTING_PARTNER_CURRENCY = rowData.POSTING_PARTNER_CURRENCY;
        vm.PSHIP_BEGIN_DATE=rowData.PSHIP_BEGIN_DATE;
        vm.PSHIP_BUSINESS=rowData.PSHIP_BUSINESS;
        vm.PSHIP_COMPANY_CODE=rowData.PSHIP_COMPANY_CODE;
        vm.PSHIP_CURRENCY=rowData.PSHIP_CURRENCY;
        vm.PSHIP_END_DATE=rowData.PSHIP_END_DATE;
        vm.PSHIP_HO_COMPANY_CODE=rowData.PSHIP_HO_COMPANY_CODE;
        vm.PSHIP_HO_LEID=rowData.PSHIP_HO_LEID;
        vm.PSHIP_HO_NAME=rowData.PSHIP_HO_NAME;
        vm.PSHIP_HO_REPORTING_PERIOD=rowData.PSHIP_HO_REPORTING_PERIOD;
        vm.PSHIP_HO_TAX_CODE=rowData.PSHIP_HO_TAX_CODE;
        vm.PSHIP_HO_US_TAX_TYPE=rowData.PSHIP_HO_US_TAX_TYPE;
        vm.PSHIP_INTERNAL_EXTERNAL_INDICATOR=rowData.PSHIP_INTERNAL_EXTERNAL_INDICATOR;
        vm.PSHIP_LEID=rowData.PSHIP_LEID;
        vm.PSHIP_NAME=rowData.PSHIP_NAME;
        vm.PSHIP_REPORTING_PERIOD=rowData.PSHIP_REPORTING_PERIOD;
        vm.PSHIP_TAX_CODE=rowData.PSHIP_TAX_CODE;
        vm.PSHIP_US_TAX_TYPE=rowData.PSHIP_US_TAX_TYPE;
        vm.TAX_MATTERS_PARTNER=rowData.TAX_MATTERS_PARTNER;
        vm.US_RETURN_PREPARED_BY=rowData.US_RETURN_PREPARED_BY;

        vm.PSHIP_PTR_POSTING_KEY = rowData.PSHIP_PTR_POSTING_KEY;
        vm.COMBINATION_KEY = rowData.COMBINATION_KEY;
        vm.PTR_COMBINATION_KEY = rowData.PTR_COMBINATION_KEY;
        vm.chart_key = [];

        vm.myCountryList = [];

        vm.mySelectedCountry = {};

        vm.GIAccts = [];
        vm.basketData = [];

        vm.general;
        vm.branch;
        vm.passive;
        vm.treaty;
        vm.sec901j;


        vm.general_amt_fsi = 0;
        vm.branch_amt_fsi = 0;
        vm.passive_amt_fsi = 0;
        vm.treaty_amt_fsi = 0;
        vm.sec901j_amt_fsi = 0;

        vm.general_amt_total = 0;
        vm.branch_amt_total = 0;
        vm.passive_amt_total = 0;
        vm.treaty_amt_total = 0;
        vm.sec901j_amt_total = 0;

        vm.general_amt_var = 0;
        vm.branch_amt_var = 0;
        vm.passive_amt_var = 0;
        vm.treaty_amt_var = 0;
        vm.sec901j_amt_var = 0;

        vm.countryKeyList;


        vm.allBasketData = [];
        vm.selectedBasketData = [];

        vm.countryPresent = false;

        vm.title = 'Partnership Details: ' + vm.PSHIP_LEID + ' / ' +  vm.PSHIP_REPORTING_PERIOD + ' - ' + 'Partner Details: ' +  vm.POSTING_PARTNER_LEID + ' / ' + vm.POSTING_PARTNER_REPORTING_PERIOD;

        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

        console.log('------------- filterParams --------------', filterParams);


        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;

        if(vm.PSHIP_INTERNAL_EXTERNAL_INDICATOR === "INTERNAL") {
            vm.allocation_heading = 'Partner Allocation - Schedule K';
        }
        else if(vm.PSHIP_INTERNAL_EXTERNAL_INDICATOR === "EXTERNAL") {    //"EXTERNAL"
            vm.allocation_heading = 'Partner Allocation - External K1';
        }

        console.log('vm.allocation_heading = ', vm.allocation_heading);

        vm.TAX_YEAR = filterParams.tax_year;
        vm.scenario = filterParams.scenario;
        vm.jcd_key = GlobalService.globalParams.jcd_key;

        vm.loadIssueDropdown = function(tax_year, scenario, jcd_key) {
            var params = {
                "action_code" : 'pgfpd1', "tax_year" : tax_year, "scenario": scenario, "jcd_key": jcd_key
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.issueList = data.jsonObject;
                }
            );
        }

        vm.loadAllDropdowns = function(tax_year) {
            var params = {
                "action_code" : 'oz319t', "tax_year" : tax_year
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.allDropdownList = data.jsonObject;
                }
            );
        }

        vm.loadAllockFormDetails = function(tabName) {

            console.log('tabName = ', tabName);

            var dataParams =  {"action_code" : 'ljeotj', "tax_year": filterParams.tax_year, "scenario": filterParams.scenario, "section_name": '', "leid" : vm.PSHIP_LEID, "cdr_no": vm.PSHIP_TAX_CODE, "rp": vm.PSHIP_REPORTING_PERIOD, "bsla": vm.PSHIP_BUSINESS, "curr": vm.PSHIP_CURRENCY, "ptr_leid": vm.POSTING_PARTNER_LEID, "ptr_cdr_no": vm.POSTING_PARTNER_TAX_CODE, "ptr_rp": vm.POSTING_PARTNER_REPORTING_PERIOD, "ptr_bsla": vm.POSTING_PARTNER_BUSINESS, "ptr_curr": vm.POSTING_PARTNER_CURRENCY, "sso_id": vm.logged_in_user, "cc_key": vm.COMBINATION_KEY, "ptr_cc_key": vm.PTR_COMBINATION_KEY};
            var obj;

            var issueParams = {"action_code" : 'fqrfx9', "tax_year": vm.TAX_YEAR, "scenario": vm.scenario,"jcd_key": vm.jcd_key, "combination_key":vm.COMBINATION_KEY, "ptr_combination_key":vm.PTR_COMBINATION_KEY};

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', dataParams).then(function (data) {

                vm.allFormData = data.jsonObject;

                vm.partHeaderIIFormData = $filter("filter")(vm.allFormData, {ATTRIB_NAME:"EXTK1_K1_II_PART_HEADER"});
                vm.k1caplibFormData = $filter("filter")(vm.allFormData, {SECTION_NAME:"K1CAPLIB"});
                vm.partHeaderIIIFormData = $filter("filter")(vm.allFormData, {ATTRIB_NAME:"EXTK1_K1_III_PART_HEADER"});
                vm.tiFormData= $filter("filter")(vm.allFormData, {SECTION_NAME:"TI"});
                vm.creditFormData = $filter("filter")(vm.allFormData, {SECTION_NAME:"CREDIT"});
                vm.amtaceFormData = $filter("filter")(vm.allFormData, {SECTION_NAME:"AMTACE"});


                vm.otherFormData = $filter("filter")(vm.allFormData, {SECTION_NAME:"OTHER"});
                for(var x = 0; x < vm.otherFormData.length; x++) {
                    obj = vm.otherFormData[x];
                    //handle json string from ATTRIB_VALUE
                    if(isJson(obj.ATTRIB_VALUE) && obj.SUB_FORM != null )
                        obj.ATTRIB_VALUE = JSON.parse(obj.ATTRIB_VALUE);
                    vm.OtherFormDataObj.push(obj);
                }
                vm.otherFormData = vm.OtherFormDataObj;

                vm.taxcapitalFormData = $filter("filter")(vm.allFormData, {SECTION_NAME:"TAXCAPITAL"});

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', issueParams).then(function (data) {
                    console.log('loaded issue = ' , data.jsonObject);
                    if(data.jsonObject.length > 0) {
                        vm.selectedIssue = data.jsonObject[0].ISSUE_ID_SHORT_DESC;
                    }
                    console.log('selectedIssue = ', vm.selectedIssue);
                });

            });

        }


        vm.formCalcDataAll = function() {
            var formulaParams = {"action_code" : '99t6eb', "tax_year": filterParams.tax_year, "scenario": filterParams.scenario, "part_no":vm.pn,"section_name":vm.sm};
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', formulaParams).then(function (data) {
                vm.allFormFormulaList =  data.jsonObject;
                console.log(vm.allFormFormulaList);
            });
        }

        vm.loadCountryList = function() {

            var params = {
                "action_code" : 'ynqodb', "tax_year" : filterParams.tax_year, "sso_id": vm.logged_in_user
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.countryList = data.jsonObject;
                    console.log(vm.countryList);
                }
            );
        }


        vm.loadAllockFormDetailsFSI = function(tabName) {

            console.log('tabName = ', tabName);

            var dataParams =  {"action_code" : 'xuhds1', "tax_year": filterParams.tax_year, "scenario": filterParams.scenario, "section_name": tabName, "leid" : vm.PSHIP_LEID, "cdr_no": vm.PSHIP_TAX_CODE, "rp": vm.PSHIP_REPORTING_PERIOD, "bsla": vm.PSHIP_BUSINESS, "curr": vm.PSHIP_CURRENCY, "ptr_leid": vm.POSTING_PARTNER_LEID, "ptr_cdr_no": vm.POSTING_PARTNER_TAX_CODE, "ptr_rp": vm.POSTING_PARTNER_REPORTING_PERIOD, "ptr_bsla": vm.POSTING_PARTNER_BUSINESS, "ptr_curr": vm.POSTING_PARTNER_CURRENCY, "sso_id": vm.logged_in_user, "cc_key": vm.COMBINATION_KEY, "ptr_cc_key": vm.PTR_COMBINATION_KEY};

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', dataParams).then(function (data) {


                vm.formData = data.jsonObject;
                vm.partHeaderIIIFSIFormData = $filter("filter")(vm.formData, {ATTRIB_NAME:"EXTK1_K1_III_PART_HEADER"});

                //console.log(vm.partHeaderIIIFSIFormData);

                vm.partHeaderElement = vm.partHeaderIIIFSIFormData[0];
                var partHeaderElementAttribValue = vm.partHeaderElement.ATTRIB_VALUE;

                vm.countryKeys = vm.partHeaderElement.COUNTRY_KEY.split('~~');
                vm.countryDetails = partHeaderElementAttribValue.split('~~');

                var countryAttribName = vm.partHeaderElement.ATTRIB_NAME;

                vm.partHeaderElement.ATTRIB_NAME = countryAttribName + '_' + '0';
                //vm.partHeaderElement.ATTRIB_DESCRIPTION = vm.countryDetails[0];
                vm.partHeaderElement.ATTRIB_VALUE = vm.countryDetails[0];

                //vm.partHeaderDynamic.push(vm.partHeaderElement);


                for(var i = 1; i< vm.countryDetails.length ; i++) {

                    countryCount = countryCount + 1;

                    vm.colspan = vm.colspan + 1;

                    vm.clonedCountryHeaderElement = angular.copy(vm.partHeaderElement);

                    vm.clonedCountryHeaderElement.ATTRIB_NAME = countryAttribName + '_' + i;
                    vm.clonedCountryHeaderElement.ATTRIB_VALUE =  vm.countryDetails[i];
                    vm.clonedCountryHeaderElement.ATTRIB_DESCRIPTION = vm.countryDetails[i];

                    vm.partHeaderDynamic.push(vm.clonedCountryHeaderElement);
                }

                vm.fsiFormData = $filter("filter")(vm.formData, {SECTION_NAME:"FSI"});

                for(var k = 0; k < vm.fsiFormData.length; k++) {
                    if(vm.fsiFormData[k].CHART_KEY != null) {
                        if(!vm.chart_key.includes(vm.fsiFormData[k].CHART_KEY)) {
                            vm.chart_key.push(vm.fsiFormData[k].CHART_KEY);
                        }
                    }
                }


                for(var j = 0; j < vm.fsiFormData.length; j++) {

                    if(vm.fsiFormData[j].LINE_NO != 'N/A') {

                        vm.partDetailDynamic = [];
                        clonedCountryElement = {};

                        fsiFormElement = vm.fsiFormData[j];

                        if(fsiFormElement.COUNTRY_KEY == null) {
                            countryKey = /*fsiFormElement.COUNTRY_KEY*/'0';
                            countryDetail = /*fsiFormElement.COUNTRY_DETAILS*/'Consolidated';
                            attribValue = fsiFormElement.ATTRIB_VALUE;
                        }
                        else {

                            if(fsiFormElement.COUNTRY_KEY.includes('~~')) {
                                countryKey = (fsiFormElement.COUNTRY_KEY.split('~~'))[0];
                                countryDetail = (fsiFormElement.COUNTRY_DETAILS.split('~~'))[0];
                                attribValue = (fsiFormElement.ATTRIB_VALUE.split('~~'))[0];
                            }
                            else {
                                countryKey = fsiFormElement.COUNTRY_KEY;
                                countryDetail = fsiFormElement.COUNTRY_DETAILS;
                                attribValue = fsiFormElement.ATTRIB_VALUE;
                            }

                        }

                        // for consolidated column
                        clonedCountryElement['COUNTRY_KEY'] = countryKey;
                        clonedCountryElement['COUNTRY_DETAILS'] = countryDetail;
                        clonedCountryElement['ATTRIB_VALUE'] =  attribValue;

                        vm.partDetailDynamic.push(clonedCountryElement);

                        for(var k = 1; k < vm.countryDetails.length ; k++) {

                            clonedCountryElement = {};

                            //if(fsiFormElement.COUNTRY_KEY != null && fsiFormElement.COUNTRY_KEY.includes('~~'))
                            countryKey = vm.countryKeys[k]/*(fsiFormElement.COUNTRY_KEY.split('~~'))[k]*/;

                            //if(fsiFormElement.COUNTRY_DETAILS != null && fsiFormElement.COUNTRY_DETAILS.includes('~~'))
                            countryDetail = vm.countryDetails[k]/*(fsiFormElement.COUNTRY_DETAILS.split('~~'))[k]*/;

                            if(fsiFormElement.ATTRIB_VALUE != null && fsiFormElement.ATTRIB_VALUE.includes('~~'))
                                attribValue = (fsiFormElement.ATTRIB_VALUE.split('~~'))[k];

                            // for each country-wise column
                            clonedCountryElement['COUNTRY_KEY'] = countryKey;
                            clonedCountryElement['COUNTRY_DETAILS'] = countryDetail;

                            if(vm.fsiFormData[j].COL_ID == 'CONS')
                                clonedCountryElement['ATTRIB_VALUE'] =  '0';
                            else if(vm.fsiFormData[j].COL_ID != 'CONS')
                                clonedCountryElement['ATTRIB_VALUE'] =  attribValue;

                            vm.partDetailDynamic.push(clonedCountryElement);
                        }

                        fsiFormElement['DYNAMIC_COUNTRY'] = vm.partDetailDynamic;
                    }
                }

                console.log(vm.fsiFormData);

                vm.myCountryList = [];

                if(vm.countryKeys.length > 1)
                    vm.countryPresent = true;
                else
                    vm.countryPresent = false;

                if(vm.countryKeys.length > 1) {

                    var myCountry = {};

                    for(var c = 1; c < vm.countryKeys.length; c++ ) {

                        myCountry = {};

                        myCountry['COUNTRY_KEY'] = vm.countryKeys[c];
                        myCountry['COUNTRY_DESCRIPTION'] = vm.countryDetails[c];

                        if(c == 1) {
                            vm.mySelectedCountry['COUNTRY_KEY'] = vm.countryKeys[c];
                            vm.mySelectedCountry['COUNTRY_DESCRIPTION'] = vm.countryDetails[c];
                        }

                        vm.myCountryList.push(myCountry);


                    }
                    var CountryString = vm.countryKeys.join(",");
                    vm.countryKeyList = CountryString.substring(2, CountryString.length);
                    vm.loadGIAccountsWithBaskets('FSI_GI', vm.countryKeys[1]);
                }


            });

        }

        vm.displayDropDownList = function(form_line_key,code_group) {
            var dropDownList = [];
            var listOfSelectedGroup = $filter("filter")(vm.allDropdownList, {CODE_GROUP:code_group});
            var dropDownElement = {};
            for(var i = 0; i < listOfSelectedGroup.length; i++) {
                dropDownElement = {};
                dropDownElement['name'] = listOfSelectedGroup[i].CODE_DESC;
                dropDownElement['value'] = listOfSelectedGroup[i].CODE_KEY;
                dropDownList.push(dropDownElement);
            }
            //console.log('form_line_key=', form_line_key ,' dropDownList=', dropDownList);
            return dropDownList;
        }




        vm.loadGIAccountsWithBaskets = function(tabName, country_key) {

            var accCount = 0;
            var dataParams =  {"action_code" : 'rp94bf', "tax_year": filterParams.tax_year, "scenario": filterParams.scenario, "jcd_key": vm.jcd_key, "cc_key": vm.COMBINATION_KEY, "ptr_cc_key": vm.PTR_COMBINATION_KEY, "country_keys": vm.countryKeyList, "sso_id": vm.logged_in_user};

            var rowData = {}

            vm.general =  $filter("filter")(vm.fsiFormData, {LINE_NO:'16G'});
            vm.branch =  $filter("filter")(vm.fsiFormData, {LINE_NO:'16E'});
            vm.passive =  $filter("filter")(vm.fsiFormData, {LINE_NO:'16F'});
            vm.treaty =  $filter("filter")(vm.fsiFormData, {LINE_NO:'16H1'});
            vm.sec901j =  $filter("filter")(vm.fsiFormData, {LINE_NO:'16H2'});



            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', dataParams).then(function (data) {

                vm.fsiGIBasketData = data.jsonObject;

                console.log('vm.fsiGIBasketData=', vm.fsiGIBasketData);

                for(var c = 1; c < vm.countryKeys.length; c++) {
                    var ctKey =  vm.countryKeys[c];
                    vm.fsiGIBasketDataByCountry[c-1] =  $filter("filter")(vm.fsiGIBasketData,{COUNTRY_KEY:ctKey});
                }

                for(var i = 0; i < vm.fsiGIBasketDataByCountry.length; i++) {

                    var aCountryFSIGIBasketData = [];

                    aCountryFSIGIBasketData = vm.fsiGIBasketDataByCountry[i];

                    accCount = 0;

                    for(var j = 0; j < aCountryFSIGIBasketData.length; j++) {
                        //console.log('j=', j);
                        rowData = aCountryFSIGIBasketData[j];
                        rowData['GENLIM'] = 0; rowData['GENLIM_CHANGED'] = 'N';
                        rowData['BRANCH'] = 0; rowData['BRANCH_CHANGED'] = 'N';
                        rowData['PASSIVE'] = 0; rowData['PASSIVE_CHANGED'] = 'N';
                        rowData['TREATY'] = 0; rowData['TREATY_CHANGED'] = 'N';
                        rowData['SEC901J'] = 0; rowData['SEC901J_CHANGED'] = 'N';

                        if (rowData['BASKET_CODE'] == 'GENLIM') {
                            rowData['GENLIM'] = rowData['BASKET_AMT'];
                        }
                        if (rowData['BASKET_CODE'] == 'BRANCH') {
                            rowData['BRANCH'] = rowData['BASKET_AMT'];
                        }
                        if (rowData['BASKET_CODE'] == 'PASSIVE') {
                            rowData['PASSIVE'] = rowData['BASKET_AMT'];
                        }
                        if (rowData['BASKET_CODE'] == 'TREATY') {
                            rowData['TREATY'] = rowData['BASKET_AMT'];
                        }
                        if (rowData['BASKET_CODE'] == 'SEC901J') {
                            rowData['SEC901J'] = rowData['BASKET_AMT'];
                        }

                        if(j==0) {
                            vm.basketData.push(rowData);
                        }
                        else {

                            if(aCountryFSIGIBasketData[j]['ACCT_KEY'] == aCountryFSIGIBasketData[j-1]['ACCT_KEY']) {

                                if (rowData['BASKET_CODE'] == 'GENLIM') {
                                    vm.basketData[accCount]['GENLIM'] = rowData['GENLIM'];
                                }
                                if (rowData['BASKET_CODE'] == 'BRANCH') {
                                    vm.basketData[accCount]['BRANCH'] = rowData['BRANCH'];
                                }
                                if (rowData['BASKET_CODE'] == 'PASSIVE') {
                                    vm.basketData[accCount]['PASSIVE'] = rowData['PASSIVE'];
                                }
                                if (rowData['BASKET_CODE'] == 'TREATY') {
                                    vm.basketData[accCount]['TREATY'] = rowData['TREATY'];
                                }
                                if (rowData['BASKET_CODE'] == 'SEC901J') {
                                    vm.basketData[accCount]['SEC901J'] = rowData['SEC901J'];
                                }
                            }
                            else {
                                vm.basketData.push(rowData);
                                accCount = accCount + 1;
                            }
                        }
                    }

                    vm.allBasketData.push(vm.basketData);

                    vm.basketData = [];
                }

                console.log('vm.allBasketData = ', vm.allBasketData);

                vm.showSelectedCountryFSIBasket(country_key);


            });
        }


        vm.showSelectedCountry = function (country_name) {

            var countryKey = $filter("filter")(vm.countryList,{CODE_DESCRIPTION:country_name})[0]['CODE_KEY'];
            vm.showSelectedCountryFSIBasket(countryKey);
        }


        vm.showSelectedCountryFSIBasket = function(country_key) {

            vm.general_amt_total = 0;
            vm.branch_amt_total = 0;
            vm.passive_amt_total = 0;
            vm.treaty_amt_total = 0;
            vm.sec901j_amt_total = 0;

            vm.general_amt_var = 0;
            vm.branch_amt_var = 0;
            vm.passive_amt_var = 0;
            vm.treaty_amt_var = 0;
            vm.sec901j_amt_var = 0;

            vm.general_amt_fsi = $filter("filter")(vm.general[0]['DYNAMIC_COUNTRY'],{COUNTRY_KEY:country_key})[0]['ATTRIB_VALUE'];
            vm.branch_amt_fsi = $filter("filter")(vm.branch[0]['DYNAMIC_COUNTRY'],{COUNTRY_KEY:country_key})[0]['ATTRIB_VALUE'];
            vm.passive_amt_fsi = $filter("filter")(vm.passive[1]['DYNAMIC_COUNTRY'],{COUNTRY_KEY:country_key})[0]['ATTRIB_VALUE'];
            vm.treaty_amt_fsi = $filter("filter")(vm.treaty[0]['DYNAMIC_COUNTRY'],{COUNTRY_KEY:country_key})[0]['ATTRIB_VALUE'];
            vm.sec901j_amt_fsi = $filter("filter")(vm.sec901j[0]['DYNAMIC_COUNTRY'],{COUNTRY_KEY:country_key})[0]['ATTRIB_VALUE'];



            for(var x = 0; x < vm.allBasketData.length; x++) {
                vm.selectedBasketData = $filter("filter")(vm.allBasketData[x],{COUNTRY_KEY:country_key});
                if(vm.selectedBasketData.length > 0) {
                    break;
                }
            }

            for (var i = 0; i < vm.selectedBasketData.length; i++ ) {
                vm.general_amt_total = vm.general_amt_total + Number(vm.selectedBasketData[i]['GENLIM']);
                vm.branch_amt_total = vm.branch_amt_total + Number(vm.selectedBasketData[i]['BRANCH']);
                vm.passive_amt_total = vm.passive_amt_total + Number(vm.selectedBasketData[i]['PASSIVE']);
                vm.treaty_amt_total = vm.treaty_amt_total + Number(vm.selectedBasketData[i]['TREATY']);
                vm.sec901j_amt_total = vm.sec901j_amt_total + Number(vm.selectedBasketData[i]['SEC901J']);
            }

            vm.general_amt_var = vm.general_amt_fsi  - vm.general_amt_total;
            vm.branch_amt_var  = vm.branch_amt_fsi   - vm.branch_amt_total;
            vm.passive_amt_var = vm.passive_amt_fsi  - vm.passive_amt_total;
            vm.treaty_amt_var  = vm.treaty_amt_fsi   - vm.treaty_amt_total;
            vm.sec901j_amt_var = vm.sec901j_amt_fsi  - vm.sec901j_amt_total;

        }

        vm.recalcGeneral = function(row) {
            row.IS_CHANGED_FLAG = 'Y';
            row.GENLIM_CHANGED = 'Y';
            vm.general_amt_total = 0;
            vm.general_amt_var = 0;
            for (var i = 0; i < vm.selectedBasketData.length; i++ ) {
                vm.general_amt_total = vm.general_amt_total + Number(vm.selectedBasketData[i]['GENLIM']);
            }
            vm.general_amt_var = vm.general_amt_fsi  - vm.general_amt_total;
        }

        vm.recalcBranch = function(row) {
            row.IS_CHANGED_FLAG = 'Y';
            row.BRANCH_CHANGED = 'Y';
            vm.branch_amt_total = 0;
            vm.branch_amt_var = 0;
            for (var i = 0; i < vm.selectedBasketData.length; i++ ) {
                vm.branch_amt_total = vm.branch_amt_total + Number(vm.selectedBasketData[i]['BRANCH']);
            }
            vm.branch_amt_var  = vm.branch_amt_fsi   - vm.branch_amt_total;
        }

        vm.recalcPassive = function(row) {
            row.IS_CHANGED_FLAG = 'Y';
            row.PASSIVE_CHANGED = 'Y';
            vm.passive_amt_total = 0;
            vm.passive_amt_var = 0;
            for (var i = 0; i < vm.selectedBasketData.length; i++ ) {
                vm.passive_amt_total = vm.passive_amt_total + Number(vm.selectedBasketData[i]['PASSIVE']);
            }
            vm.passive_amt_var = vm.passive_amt_fsi  - vm.passive_amt_total;
        }

        vm.recalcTreaty = function(row) {
            row.IS_CHANGED_FLAG = 'Y';
            row.TREATY_CHANGED = 'Y';
            vm.treaty_amt_total = 0;
            vm.treaty_amt_var = 0;
            for (var i = 0; i < vm.selectedBasketData.length; i++ ) {
                vm.treaty_amt_total = vm.treaty_amt_total + Number(vm.selectedBasketData[i]['TREATY']);
            }
            vm.treaty_amt_var  = vm.treaty_amt_fsi   - vm.treaty_amt_total;
        }

        vm.recalcSec901j = function(row) {
            row.IS_CHANGED_FLAG = 'Y';
            row.SEC901J_CHANGED = 'Y';
            vm.sec901j_amt_total = 0;
            vm.sec901j_amt_var = 0;
            for (var i = 0; i < vm.selectedBasketData.length; i++ ) {
                vm.sec901j_amt_total = vm.sec901j_amt_total + Number(vm.selectedBasketData[i]['SEC901J']);
            }
            vm.sec901j_amt_var = vm.sec901j_amt_fsi  - vm.sec901j_amt_total;
        }

        vm.getTISumByFLCCodeGrouping = function(flc_code) {
            var amount = 0;
            vm.tiLinesByFLCCode = $filter("filter")(vm.allFormData, {SECTION_NAME:"TI",FLC_CODE:flc_code});

            for(var i=0; i< vm.tiLinesByFLCCode.length; i++) {
                amount = amount + Number(vm.tiLinesByFLCCode[i]['ATTRIB_VALUE']);
            }
            return amount;
        }

        vm.getBasketSumByFLCCodeGrouping = function(flc_code) {
            var basketAmount = 0;
            var basketDataByCountry;
            var basketDataByFLC;

            for(var i = 0; i < vm.allBasketData.length; i++) {
                basketDataByCountry = vm.allBasketData[i];
                basketDataByFLC =  $filter("filter")(basketDataByCountry,{FLC_CODE:flc_code})[0];
                basketAmount = basketAmount + Number(basketDataByFLC['GENLIM']) + Number(basketDataByFLC['BRANCH']) + Number(basketDataByFLC['PASSIVE']) + Number(basketDataByFLC['TREATY']) + Number(basketDataByFLC['SEC901J']);
            }
            return basketAmount;
        }

        vm.loadBeatGiltiFdii = function(tabName) {

            var dataParams = {"action_code" : 'g443my', "tax_year": filterParams.tax_year, "scenario": filterParams.scenario, "jcd_key": vm.jcd_key, "cc_key": vm.COMBINATION_KEY, "ptr_cc_key": vm.PTR_COMBINATION_KEY, "country_keys": vm.countryKeyList, "sso_id": vm.logged_in_user};
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', dataParams).then(function (data) {
                vm.beatGiltiFdiiData = data.jsonObject;

                console.log('vm.beatGiltiFdiiData=', vm.beatGiltiFdiiData);

                vm.beatData = $filter("filter")(vm.beatGiltiFdiiData, {SECTION_NAME:"BEAT"});
                vm.giltiData = $filter("filter")(vm.beatGiltiFdiiData, {SECTION_NAME:"GILTI"});
                vm.fdiiData = $filter("filter")(vm.beatGiltiFdiiData, {SECTION_NAME:"FDII"});
                vm.s864c8Data = $filter("filter")(vm.beatGiltiFdiiData, {SECTION_NAME:"S864C8"});
            });
        }

        ////////////////////////////////// All these are called at popup open - starts ///////////////////////////////////

        vm.loadIssueDropdown(vm.TAX_YEAR, vm.scenario, vm.jcd_key);

        vm.loadAllDropdowns(filterParams.tax_year);

        vm.loadAllockFormDetails('Main');

        vm.formCalcDataAll();

        vm.loadCountryList();

        vm.loadAllockFormDetailsFSI('FSI');

        vm.loadBeatGiltiFdii('BEAT_GILTI_FDII');

        //vm.loadGIAccountsWithBaskets('FSI_GI');

        ////////////////////////////////// All these are called at popup open - ends ///////////////////////////////////

        vm.onTabChange = function(e) {
            vm.selectedTabName = e;
            $timeout(function() {
                var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            }, 200);
        };

        vm.showHideSection = function (id) {

            console.log('showHideSection called for :' ,  id, angular.element(document.getElementById(id)));

            if(id === 'k1caplib')
                vm.showK1Caplib = !vm.showK1Caplib;

            if(id === 'ti')
                vm.showTI = !vm.showTI;

            if(id === 'credits')
                vm.showCredits = !vm.showCredits;

            if(id === 'amtace')
                vm.showAMTACE = !vm.showAMTACE;

            if(id === 'other')
                vm.showOTHER = !vm.showOTHER;

            if(id === 'taxcapital')
                vm.showTAXCAPITAL = !vm.showTAXCAPITAL;

            if(id === 'fsi')
                vm.showFSI = !vm.showFSI;

            var className = angular.element(document.getElementById(id))[0].className;


            if( className === 'ng-scope')
                angular.element(document.getElementById(id))[0].className='shown' + " " + className;
            else
                angular.element(document.getElementById(id))[0].className='ng-scope';
        }

        vm.setCountry = function (countryName) {
            vm.selectedCountryValue = countryName;
            vm.selectedCountryKey= $filter("filter")(vm.countryList, {CODE_DESCRIPTION:countryName})[0].CODE_KEY;
        }

        /*vm.setIssueId = function(selectedIssue) {
            vm.issueID = selectedIssue.ISSUE_ID;
            console.log('vm.issueID = ', vm.issueID);
        }*/

        // called when Add Country button is called
        vm.addCountryColumn = function() {
            console.log('country to add = ' , ' key = ' , vm.selectedCountryKey , ' , value = ',  vm.selectedCountryValue);
            var countryAlreadyAdded = false;
            if(vm.selectedCountryValue != null && vm.selectedCountryKey != 0) {

                if(vm.partHeaderDynamic.length == 0) {
                    countryAlreadyAdded == false
                }
                else {
                    for(var x = 0; x < vm.partHeaderDynamic.length; x++) {
                        if(vm.partHeaderDynamic[x].ATTRIB_VALUE == vm.selectedCountryValue) {
                            countryAlreadyAdded = true;
                            break;
                        }
                    }
                }

                if(countryAlreadyAdded == false) {

                    vm.colspan = vm.colspan + 1;

                    // Add country Column for header row
                    //var length = vm.partHeaderDynamic.length;
                    //var headerElement = vm.partHeaderDynamic[length - 1];
                    var clonedHeaderColumn = angular.copy(vm.partHeaderElement);

                    clonedHeaderColumn.ATTRIB_NAME = vm.partHeaderIIIFSIFormData[0].ATTRIB_NAME + '_' + length;
                    clonedHeaderColumn.ATTRIB_VALUE = vm.selectedCountryValue;
                    clonedHeaderColumn.ATTRIB_DESCRIPTION = vm.selectedCountryValue;

                    vm.partHeaderDynamic.push(clonedHeaderColumn)

                    // Add country Column  (for all rows)
                    for(var j = 0; j < vm.fsiFormData.length; j++) {
                        if(vm.fsiFormData[j].ROW_TYPE != 'SH') {
                            var dynamicCountryElementArray = vm.fsiFormData[j].DYNAMIC_COUNTRY;
                            var dynamicCountryElement = dynamicCountryElementArray[dynamicCountryElementArray.length - 1];
                            var clonedDynamicCountryElement = angular.copy(dynamicCountryElement);
                            clonedDynamicCountryElement['COUNTRY_KEY'] = vm.selectedCountryKey;
                            clonedDynamicCountryElement['COUNTRY_DETAILS'] = vm.selectedCountryValue;

                            if(vm.fsiFormData[j].DATA_TYPE == 'number')
                                clonedDynamicCountryElement['ATTRIB_VALUE'] =  '0';
                            if(vm.fsiFormData[j].DATA_TYPE == 'string')
                                clonedDynamicCountryElement['ATTRIB_VALUE'] =  null;

                            clonedDynamicCountryElement['IS_CHANGED_FLAG'] = 'Y';
                            dynamicCountryElementArray.push(clonedDynamicCountryElement);
                        }
                    }
                }

                // additionally do the following for FSI-Sourcing
                vm.countryKeys.push((dynamicCountryElementArray[dynamicCountryElementArray.length-1])['COUNTRY_KEY']);
                vm.countryDetails.push((dynamicCountryElementArray[dynamicCountryElementArray.length-1])['COUNTRY_DETAILS']);

                if(vm.countryKeys.length > 1)
                    vm.countryPresent = true;
                else
                    vm.countryPresent = false;

                vm.myCountryList = [];

                if(vm.countryKeys.length > 1) {

                    var myCountry = {};

                    for(var c = 1; c < vm.countryKeys.length; c++ ) {

                        myCountry = {};

                        myCountry['COUNTRY_KEY'] = vm.countryKeys[c];
                        myCountry['COUNTRY_DESCRIPTION'] = vm.countryDetails[c];

                        if(c == 1) {
                            vm.mySelectedCountry['COUNTRY_KEY'] = vm.countryKeys[c];
                            vm.mySelectedCountry['COUNTRY_DESCRIPTION'] = vm.countryDetails[c];
                        }

                        vm.myCountryList.push(myCountry);


                    }
                    var CountryString = vm.countryKeys.join(",");
                    vm.countryKeyList = CountryString.substring(2, CountryString.length);
                    vm.loadGIAccountsWithBaskets('FSI_GI', vm.countryKeys[1]);
                }

            }

        }


        // called when Remove Country button is clicked
        vm.removeCountryColumn = function(index) {

            console.log(index);
            console.log(vm.partHeaderDynamic);

            vm.countriesToRemove.push($filter("filter")(vm.countryList, {CODE_DESCRIPTION:vm.partHeaderDynamic[index].ATTRIB_VALUE})[0].CODE_KEY);


            vm.partHeaderDynamic.splice(index, 1);

            var tdIndex = index + 1;

            for(var j = 0; j < vm.fsiFormData.length; j++) {
                if(vm.fsiFormData[j].ROW_TYPE != 'SH') {
                    vm.fsiFormData[j].DYNAMIC_COUNTRY.splice(tdIndex , 1);
                    //vm.fsiFormData[j].IS_CHANGED_FLAG = 'Y';
                }
            }

            vm.colspan = vm.colspan - 1;

            // additionally do the following for FSI-Sourcing

            vm.countryKeys.splice(index,1);
            vm.countryDetails.splice(index, 1);

            if(vm.countryKeys.length > 1)
                vm.countryPresent = true;
            else
                vm.countryPresent = false;

            vm.myCountryList = [];

            if(vm.countryKeys.length > 1) {

                var myCountry = {};

                for(var c = 1; c < vm.countryKeys.length; c++ ) {

                    myCountry = {};

                    myCountry['COUNTRY_KEY'] = vm.countryKeys[c];
                    myCountry['COUNTRY_DESCRIPTION'] = vm.countryDetails[c];

                    if(c == 1) {
                        vm.mySelectedCountry['COUNTRY_KEY'] = vm.countryKeys[c];
                        vm.mySelectedCountry['COUNTRY_DESCRIPTION'] = vm.countryDetails[c];
                    }

                    vm.myCountryList.push(myCountry);


                }
                var CountryString = vm.countryKeys.join(",");
                vm.countryKeyList = CountryString.substring(2, CountryString.length);
                vm.loadGIAccountsWithBaskets('FSI_GI', vm.countryKeys[1]);
            }

        }

        // called for every editable text box on-change (main tab)
        vm.applyFormulaMain = function(row) {
            console.log('section = ' , row.SECTION_NAME, ', line = ', row.LINE_NO);
            row.IS_CHANGED_FLAG = 'Y';
            if(row.DATA_TYPE === 'number') {

                if(row.SECTION_NAME == 'K1CAPLIB' || row.SECTION_NAME == 'TI' || row.SECTION_NAME == 'CREDIT' || row.SECTION_NAME == 'AMTACE' || row.SECTION_NAME == 'OTHER' || row.SECTION_NAME == 'TAXCAPITAL') {
                    vm.formFormulaList =   $filter("filter")(vm.allFormFormulaList, {SECTION_NAME:row.SECTION_NAME, ATTRIB_CALC_FORMULA:row.SECTION_NAME});
                    vm.formList = $filter("filter")(vm.allFormData, {SECTION_NAME:row.SECTION_NAME});
                    //$filter("filter")(vm.allFormData, {SECTION_NAME:row.SECTION_NAME})
                    for(var i=0; i < vm.formFormulaList.length; i++) {
                        var calcFormula = vm.formFormulaList[i].ATTRIB_CALC_FORMULA;
                        if(calcFormula != null && calcFormula.trim().length > 0)
                            calcAmt(calcFormula,vm.formFormulaList[i].LINE_NO,vm.formFormulaList[i].LINE_ATTRIB_KEY, row.DATA_TYPE, vm.formList);
                    }
                }

                // evaluate dependent lines
                vm.formListTaxCap =  $filter("filter")(vm.allFormData, {SECTION_NAME:'TAXCAPITAL'});

                vm.formulaListTaxCap =  $filter("filter")(vm.allFormFormulaList, {SECTION_NAME:'TAXCAPITAL'});

                vm.taxCapitalFormulaList_K1CAPLIB = $filter("filter")(vm.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'K1CAPLIB'});
                vm.taxCapitalFormulaList_TI = $filter("filter")(vm.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'TI'});
                vm.taxCapitalFormulaList_OTHER = $filter("filter")(vm.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'OTHER'});
                //vm.taxCapitalFormulaList_FSI = $filter("filter")(vm.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'FSI'});

                for(var s = 0; s < vm.taxCapitalFormulaList_K1CAPLIB.length; s++) {
                    vm.taxCapitalFormulaList_DEPENDENT.push(vm.taxCapitalFormulaList_K1CAPLIB[s]);
                }
                for(var s = 0; s < vm.taxCapitalFormulaList_TI.length; s++) {
                    vm.taxCapitalFormulaList_DEPENDENT.push(vm.taxCapitalFormulaList_TI[s]);
                }
                for(var s = 0; s < vm.taxCapitalFormulaList_OTHER.length; s++) {
                    vm.taxCapitalFormulaList_DEPENDENT.push(vm.taxCapitalFormulaList_OTHER[s]);
                }
                /*for(var s = 0; s < vm.taxCapitalFormulaList_FSI.length; s++) {
                    vm.taxCapitalFormulaList_DEPENDENT.push(vm.taxCapitalFormulaList_FSI[s]);
                }*/

                for(var i=0; i < vm.taxCapitalFormulaList_DEPENDENT.length; i++) {
                    var calcFormula = vm.taxCapitalFormulaList_DEPENDENT[i].ATTRIB_CALC_FORMULA;
                    if(calcFormula != null && calcFormula.trim().length > 0)
                        calcTaxCapitalAmt(calcFormula,vm.taxCapitalFormulaList_DEPENDENT[i].LINE_NO,vm.taxCapitalFormulaList_DEPENDENT[i].LINE_ATTRIB_KEY, row.DATA_TYPE, vm.formListTaxCap, 'main');
                }

                //re-evaluate only those formula lines having only TAXCAPITAL in them; each element in this formula are TAXCAPITAL lines which gets changed by other screen changes
                vm.formFormulaList =   $filter("filter")(vm.allFormFormulaList, {SECTION_NAME:'TAXCAPITAL', ATTRIB_CALC_FORMULA:'TAXCAPITAL'});
                vm.formList = $filter("filter")(vm.allFormData, {SECTION_NAME:'TAXCAPITAL'});
                //$filter("filter")(vm.allFormData, {SECTION_NAME:row.SECTION_NAME})
                for(var i=0; i < vm.formFormulaList.length; i++) {
                    var calcFormula = vm.formFormulaList[i].ATTRIB_CALC_FORMULA;
                    if(calcFormula != null && calcFormula.trim().length > 0)
                        calcAmt(calcFormula,vm.formFormulaList[i].LINE_NO,vm.formFormulaList[i].LINE_ATTRIB_KEY, row.DATA_TYPE, vm.formList);
                }


                if(vm.countryPresent) {

                    if(row.SECTION_NAME == 'TI' && row.FLC_CODE != null && ( row.FLC_CODE == 'CGNI' || row.FLC_CODE == 'DIV' ||row.FLC_CODE == 'RENTINC' ||row.FLC_CODE == 'ROYALTY' ||row.FLC_CODE == 'INTINC' ||row.FLC_CODE == 'NETGNLS' ||row.FLC_CODE == 'OTHINC') ) {

                        var selCountryKey = $filter("filter")(vm.countryList,{CODE_DESCRIPTION:vm.mySelectedCountry.COUNTRY_DESCRIPTION})[0]['CODE_KEY'];
                        vm.showSelectedCountryFSIBasket(selCountryKey);
                    }
                }


            }
        }

        // called for every editable text box on-change (fsi tab)
        vm.applyFormulaFSI = function(row, col) {
            console.log('section = ' , row.SECTION_NAME, ', line = ', row.LINE_NO , ', col =' , col);
            row.IS_CHANGED_FLAG = 'Y';

            //------------------------- TO DO in future , if FSI agg lines are added, then formula evaluation needs col index -----------------------------//
            /*if(row.SECTION_NAME == 'FSI') {
                vm.formFormulaList =   $filter("filter")(vm.allFormFormulaList, {SECTION_NAME:row.SECTION_NAME, ATTRIB_CALC_FORMULA:row.SECTION_NAME});
                vm.formList = $filter("filter")(vm.allFormData, {SECTION_NAME:row.SECTION_NAME});
                //$filter("filter")(vm.allFormData, {SECTION_NAME:row.SECTION_NAME})
                for(var i=0; i < vm.formFormulaList.length; i++) {
                    var calcFormula = vm.formFormulaList[i].ATTRIB_CALC_FORMULA;
                    if(calcFormula != null && calcFormula.trim().length > 0)
                        calcAmt(calcFormula,vm.formFormulaList[i].LINE_NO,vm.formFormulaList[i].LINE_ATTRIB_KEY, row.DATA_TYPE, vm.formList);
                }
            }*/

            //evaluate dependent lines (taxCapital lines )for FSI lines
            vm.formListTaxCap =  $filter("filter")(vm.allFormData, {SECTION_NAME:'TAXCAPITAL'});
            vm.taxCapitalFormulaList_FSI =  $filter("filter")(vm.allFormFormulaList, {SECTION_NAME:'TAXCAPITAL',ATTRIB_CALC_FORMULA:'FSI'});
            for(var i = 0; i < vm.taxCapitalFormulaList_FSI.length; i++){
                var calcFormula = vm.taxCapitalFormulaList_FSI[i].ATTRIB_CALC_FORMULA;
                if(calcFormula != null && calcFormula.trim().length > 0)
                    calcTaxCapitalAmt(calcFormula,vm.taxCapitalFormulaList_FSI[i].LINE_NO,vm.taxCapitalFormulaList_FSI[i].LINE_ATTRIB_KEY, row.DATA_TYPE, vm.formListTaxCap,'fsi');
            }

            //re-evaluate only those formula lines having only TAXCAPITAL in them; each element in this formula are TAXCAPITAL lines which gets changed by FSI changes
            vm.formFormulaList =   $filter("filter")(vm.allFormFormulaList, {SECTION_NAME:'TAXCAPITAL', ATTRIB_CALC_FORMULA:'TAXCAPITAL'});
            vm.formList = $filter("filter")(vm.allFormData, {SECTION_NAME:'TAXCAPITAL'});
            //$filter("filter")(vm.allFormData, {SECTION_NAME:row.SECTION_NAME})
            for(var i=0; i < vm.formFormulaList.length; i++) {
                var calcFormula = vm.formFormulaList[i].ATTRIB_CALC_FORMULA;
                if(calcFormula != null && calcFormula.trim().length > 0)
                    calcAmt(calcFormula,vm.formFormulaList[i].LINE_NO,vm.formFormulaList[i].LINE_ATTRIB_KEY, row.DATA_TYPE, vm.formList);
            }

            if(vm.countryPresent) {

                if(row.LINE_NO == '16G' || row.LINE_NO == '16E' || row.LINE_NO == '16F' || row.LINE_NO == '16H1' || row.LINE_NO == '16H2') {
                    var country_key = (row.DYNAMIC_COUNTRY[col])['COUNTRY_KEY'];
                    var selCountryKey = $filter("filter")(vm.countryList,{CODE_DESCRIPTION:vm.mySelectedCountry.COUNTRY_DESCRIPTION})[0]['CODE_KEY'];
                    if(country_key == selCountryKey)
                        vm.showSelectedCountryFSIBasket(country_key);
                }
            }

        }

        function calcAmt(formula, line_no, attrib_key, dataType, targetFormList){

            var lineAmt = 0;
            var matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
            var lineFormulaArray;
            var formulaOperandText;

            console.log('------- formula ----------' , formula);

            if(matches ==  null){
                lineFormulaArray = formula.split(/[+-/\\*\\]/);
            }
            //console.log('lineFormulaArray = ' , lineFormulaArray);

            for(var i = 0; i<lineFormulaArray.length; i++){

                lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                formulaOperandText = lineFormulaArray[i].trim();

                vm.sourceList = vm.formList;

                //var newTemp1 = $filter("filter")(vm.sourceList, {ATTRIB_NAME:/*lineFormulaArray[i].trim()*/ formulaOperandText,DATA_TYPE:'number'});

                var newTemp1 = _.find(vm.sourceList, {ATTRIB_NAME: formulaOperandText, DATA_TYPE: 'number'});

                //var newTemp1 = $filter("filter")(vm.allFormList, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
                //console.log('ATTRIBUTE_SHORT_CODE = ', lineFormulaArray[i].trim());
                //console.log('newTemp1 =', newTemp1);

                lineAmt = parseFloat(newTemp1/*[0]*/.ATTRIB_VALUE);
                if(newTemp1/*[0]*/.IS_EDITABLE_CELL == 'Y' && dataType != null){

                    //newTemp1[0].IS_CHANGED_FLAG = 'Y';
                }
                if(parseFloat(lineAmt)>= 0){
                    formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                }
                else
                    formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
            }

            if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself
                //document.getElementById(subfrmTxtPrfx+line_no).value = eval(formula);// Not parsing to Int for getting Float values

                var newTemp123 = $filter("filter")(/*vm.formList*/targetFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                //var newTemp123 = $filter("filter")(vm.allFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                var originalValue = newTemp123[0].ATTRIB_VALUE;
                var xyz = eval(formula);

                newTemp123[0].ATTRIB_VALUE = xyz.toFixed();
                if(dataType != null && (originalValue != newTemp123[0].ATTRIB_VALUE) ){
                    newTemp123[0].IS_CHANGED_FLAG = 'Y';
                }
            }

        }

        function calcTaxCapitalAmt(formula, line_no, attrib_key, dataType, targetFormList, tabName){

            var lineAmt = 0;
            var matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
            var lineFormulaArray;
            var formulaOperandText;
            var hasGT = false;
            var hasLT = false;

            console.log('------- formula ----------' , formula);

            if(matches ==  null){
                lineFormulaArray = formula.split(/[+-/\\*\\]/);
            }
            //console.log('lineFormulaArray = ' , lineFormulaArray);

            for(var i = 0; i<lineFormulaArray.length; i++){

                lineAmt = 0;
                hasGT = false;
                hasLT = false;

                lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");


                formulaOperandText = lineFormulaArray[i].trim();

                if(formulaOperandText.includes('>')) {
                    hasGT = true;
                    formulaOperandText = formulaOperandText.replace('>','');
                }
                else if(formulaOperandText.includes('<')) {
                    hasLT = true;
                    formulaOperandText = formulaOperandText.replace('<','');
                }

                if (tabName == 'main') {
                    vm.sourceList =  vm.allFormData ;
                }
                else if(tabName == 'fsi') {
                    vm.sourceList = [];
                    for(var s = 0; s < vm.fsiFormData.length; s++) {
                        vm.sourceList.push(vm.fsiFormData[s]);
                    }
                }

                //var newTemp1 = $filter("filter")(vm.sourceList, {ATTRIB_NAME:/*lineFormulaArray[i].trim()*/ formulaOperandText,DATA_TYPE:'number'});
                var newTemp1 = _.find(vm.sourceList, {ATTRIB_NAME: formulaOperandText, DATA_TYPE: 'number'});

                if(tabName == 'main') {
                    lineAmt = parseFloat(newTemp1/*[0]*/.ATTRIB_VALUE);
                }
                else if(tabName == 'fsi') {
                    for(var x = 1; x < newTemp1.DYNAMIC_COUNTRY.length; x++) {
                        lineAmt = lineAmt + parseFloat(newTemp1.DYNAMIC_COUNTRY[x].ATTRIB_VALUE);
                    }
                }


                if(newTemp1/*[0]*/.IS_EDITABLE_CELL == 'Y' && dataType != null){

                    //newTemp1[0].IS_CHANGED_FLAG = 'Y';
                }
                if(hasGT == false && hasLT == false) {

                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+formulaOperandText+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+formulaOperandText+")","("+lineAmt+")");
                }
                else if (hasGT == true || hasLT == true)  {

                    if(hasGT == true) {

                        if(parseFloat(lineAmt)> 0){
                            formula = formula.replace("("+formulaOperandText+"(>)"+")",lineAmt);
                        }
                        else
                            formula = formula.replace("("+formulaOperandText+"(>)"+")",0);
                    }
                    else if (hasLT == true)  {

                        if(parseFloat(lineAmt) < 0){
                            formula = formula.replace("("+formulaOperandText+"(<)"+")","("+lineAmt+")");
                        }
                        else
                            formula = formula.replace("("+formulaOperandText+"(<)"+")",0);
                    }
                }

                console.log('formula b4 eval : ' + formula);

            }

            if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself
                //document.getElementById(subfrmTxtPrfx+line_no).value = eval(formula);// Not parsing to Int for getting Float values

                var newTemp123 = $filter("filter")(/*vm.formList*/targetFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                //var newTemp123 = $filter("filter")(vm.allFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                var originalValue = newTemp123[0].ATTRIB_VALUE;
                var xyz = eval(formula);

                newTemp123[0].ATTRIB_VALUE = xyz.toFixed();
                if(dataType != null && (originalValue != newTemp123[0].ATTRIB_VALUE) ){
                    newTemp123[0].IS_CHANGED_FLAG = 'Y';
                }
            }

        }


        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

        vm.setChange = function(row) {
            row.IS_CHANGED_FLAG = 'Y';
        }


        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }



        vm.reset = function(tabIndex) {
            console.log('reset button clicked');
        }

        vm.save = function() {
            console.log('save button clicked');
            handleSave('6xgoog');
        }

        vm.saveAndPost = function() {
            console.log('save and Post button clicked');
            if( vm.issueList.length > 0 && vm.issueList[0].SCENARIO_TYPE_CODE == 'EXAM') {
                if(vm.selectedIssue == '') {
                    AlertService.add("danger", 'Please select an issue from the list', 4000);
                    vm.isSaveClicked = false;
                }
                else {
                    vm.issueID = vm.selectedIssue.split('--')[0];
                    handleSave('ge4rfd');
                }
            }
            else {
                vm.issueID = 0;
                handleSave('ge4rfd');
            }
        }

        function handleSave(action_code) {

            vm.isSaveClicked = true;
            vm.saveData;
            var returnClobSettingsObj = {};
            var EXTK1FormSaveDtls = [];
            var allSaveData = [];
            var tabNames = ['main', 'fsi'];
            var returnObj;
            var amount = 0;
            var val;
            var fsi_gi_index;
            var beat_gilti_fdii_index;

            var message;

            if(vm.countryKeys.length > 1) {
                tabNames.push('fsi_gi');
            }

            if(vm.TAX_YEAR >= 2020) {
                tabNames.push('beat_gilti_fdii');
            }

            for(var j = 0; j < tabNames.length; j++) {
                if( j == 0) { // main tab
                    vm.saveData = $filter("filter")(vm.allFormData, {IS_CHANGED_FLAG:'Y', ATTRIB_COL:'a'});
                }
                else if (j == 1 ) { // fsi tab
                    vm.saveData = $filter("filter")(vm.fsiFormData, {IS_CHANGED_FLAG:'Y', ATTRIB_COL:'a'});
                }
                else if(j == tabNames.indexOf('fsi_gi') ) { //fsi_gi tab
                    vm.saveData = $filter("filter")(vm.allBasketData,{IS_CHANGED_FLAG:'Y'});
                    fsi_gi_index = tabNames.indexOf('fsi_gi');
                }
                else if( j == tabNames.indexOf('beat_gilti_fdii') ) { //beat/gilti/fdii tab
                    vm.saveData = $filter("filter")(vm.beatGiltiFdiiData,{IS_CHANGED_FLAG:'Y'});
                    beat_gilti_fdii_index = tabNames.indexOf('beat_gilti_fdii');
                }
                allSaveData.push(vm.saveData);
            }
            console.log('allSaveData=' + allSaveData);

            for(var key in allSaveData) {

                vm.saveData = allSaveData[key];

                for(var i= 0;i < vm.saveData.length;i++) {

                    if(key == 0) { // main tab

                        returnObj = {};
                        returnObj['section_name'] = 'main';
                        returnObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                        returnObj['combination_key'] = vm.COMBINATION_KEY;
                        returnObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                        returnObj['chart_key'] = vm.saveData[i].CHART_REF_KEY;
                        returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REFERENCE_KEY;

                        if(typeof vm.saveData[i].ATTRIB_VALUE === 'object' && vm.saveData[i].SUB_FORM != null)
                            val = JSON.stringify(vm.saveData[i].ATTRIB_VALUE);
                        else
                            val = vm.saveData[i].ATTRIB_VALUE;

                        returnObj['allocation_amount'] = /*vm.saveData[i].ATTRIB_VALUE*/ val;
                        returnObj['flow_through'] = 0;

                        EXTK1FormSaveDtls.push(returnObj);
                    }

                    if (key == 1) { // FSI tab

                        for(var x = 0; x < vm.saveData[i].DYNAMIC_COUNTRY.length; x++) {

                            if(vm.saveData[i].COL_ID == 'CONS') { // consolidated line
                                if(x == 0) {
                                    returnObj = {};
                                    returnObj['section_name'] = 'main';
                                    returnObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                                    returnObj['combination_key'] = vm.COMBINATION_KEY;
                                    returnObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                                    returnObj['chart_key'] = vm.saveData[i].CHART_REF_KEY;
                                    returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REFERENCE_KEY;
                                    returnObj['country_key'] = vm.saveData[i].DYNAMIC_COUNTRY[x].COUNTRY_KEY;
                                    returnObj['allocation_amount'] = vm.saveData[i].DYNAMIC_COUNTRY[x].ATTRIB_VALUE;
                                    returnObj['flow_through'] = 0;

                                    EXTK1FormSaveDtls.push(returnObj);
                                }

                            }
                            else { // not a consolidated line

                                if(x == 0 ) {
                                    returnObj = {};
                                    returnObj['section_name'] = 'main';
                                    returnObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                                    returnObj['combination_key'] = vm.COMBINATION_KEY;
                                    returnObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                                    returnObj['chart_key'] = vm.saveData[i].CHART_REF_KEY;
                                    returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REFERENCE_KEY;
                                    returnObj['country_key'] = vm.saveData[i].DYNAMIC_COUNTRY[x].COUNTRY_KEY;

                                    amount = 0;
                                    for(var y = 1; y < vm.saveData[i].DYNAMIC_COUNTRY.length; y++) {

                                        if(vm.saveData[i].DYNAMIC_COUNTRY[y].ATTRIB_VALUE != null) {
                                            amount = amount + parseFloat(vm.saveData[i].DYNAMIC_COUNTRY[y].ATTRIB_VALUE);
                                        }

                                    }

                                    returnObj['allocation_amount'] = amount/*vm.saveData[i].DYNAMIC_COUNTRY[x].ATTRIB_VALUE*/;
                                    returnObj['flow_through'] = 0;

                                    EXTK1FormSaveDtls.push(returnObj);
                                }

                                if( x > 0 ) {

                                    returnObj = {};
                                    returnObj['section_name'] = 'fsi';
                                    returnObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                                    returnObj['combination_key'] = vm.COMBINATION_KEY;
                                    returnObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                                    returnObj['chart_key'] = vm.saveData[i].CHART_REF_KEY;
                                    returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REFERENCE_KEY;
                                    returnObj['country_key'] = vm.saveData[i].DYNAMIC_COUNTRY[x].COUNTRY_KEY;
                                    returnObj['allocation_amount'] = vm.saveData[i].DYNAMIC_COUNTRY[x].ATTRIB_VALUE;
                                    returnObj['flow_through'] = 0;

                                    EXTK1FormSaveDtls.push(returnObj);
                                }

                            }

                        }

                    }

                    if(key == fsi_gi_index) { // FSI_GI tab

                        if(allSaveData[key][0]) {

                            for(var a = 0; a < 7; a ++) { // where a is account counter

                                if(vm.saveData[i][a].IS_CHANGED_FLAG == 'Y') {

                                    for( var b = 0; b < 5; b++) {  // where b is basket counter
                                        returnObj = {};
                                        returnObj['section_name'] = 'fsi_gi';
                                        returnObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                                        returnObj['combination_key'] = vm.COMBINATION_KEY;
                                        returnObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                                        returnObj['country_key'] = vm.saveData[i][a].COUNTRY_KEY;
                                        returnObj['chart_key'] = vm.saveData[i][a].CHART_KEY;
                                        returnObj['acct_ref_key'] = vm.saveData[i][a].ACCT_KEY;

                                        if(b == 0 && (vm.saveData[i][a].GENLIM_CHANGED == 'Y') ) {
                                            returnObj['fsi_key'] = 91;
                                            returnObj['allocation_amount'] = vm.saveData[i][a].GENLIM;
                                            EXTK1FormSaveDtls.push(returnObj);
                                        }
                                        if( b == 1 && (vm.saveData[i][a].BRANCH_CHANGED == 'Y')) {
                                            returnObj['fsi_key'] = 92;
                                            returnObj['allocation_amount'] = vm.saveData[i][a].BRANCH;
                                            EXTK1FormSaveDtls.push(returnObj);
                                        }
                                        if( b == 2 && (vm.saveData[i][a].PASSIVE_CHANGED == 'Y')) {
                                            returnObj['fsi_key'] = 93;
                                            returnObj['allocation_amount'] = vm.saveData[i][a].PASSIVE;
                                            EXTK1FormSaveDtls.push(returnObj);
                                        }
                                        if( b ==3 && (vm.saveData[i][a].TREATY_CHANGED == 'Y')) {
                                            returnObj['fsi_key'] = 89;
                                            returnObj['allocation_amount'] = vm.saveData[i][a].TREATY;
                                            EXTK1FormSaveDtls.push(returnObj);
                                        }
                                        if( b == 4 && (vm.saveData[i][a].SEC901J_CHANGED == 'Y')) {
                                            returnObj['fsi_key'] = 86;
                                            returnObj['allocation_amount'] = vm.saveData[i][a].SEC901J;
                                            EXTK1FormSaveDtls.push(returnObj);
                                        }
                                    }
                                }
                            }
                        }

                        //EXTK1FormSaveDtls.push(returnObj);
                    }

                    if(key == beat_gilti_fdii_index) { // beat/gilti/fdii tab
                        returnObj = {};
                        returnObj['section_name'] = 'beat_gilti_fdii';
                        returnObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                        returnObj['combination_key'] = vm.COMBINATION_KEY;
                        returnObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                        returnObj['chart_key'] = vm.saveData[i].CHART_REF_KEY;
                        returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REFERENCE_KEY;
                        val = vm.saveData[i].ATTRIB_VALUE;

                        if(vm.saveData[i].ROW_TYPE == 'DTA')
                          returnObj['fgn_trans_dtls'] = val;
                        else
                          returnObj['allocation_amount'] = val;

                        returnObj['flow_through'] = 0;

                        EXTK1FormSaveDtls.push(returnObj);
                    }

                }

            }

            console.log(EXTK1FormSaveDtls);

            if(action_code == '6xgoog')
                message = "Partner Allocation Details successfully saved";
            else if(action_code == 'ge4rfd')
                message = "Partner Allocation Details successfully saved and posted";

            returnClobSettingsObj['tax_year'] = vm.TAX_YEAR
            returnClobSettingsObj['scenario'] = vm.scenario
            returnClobSettingsObj['sso_id'] = vm.logged_in_user;
            returnClobSettingsObj['issue_id'] = vm.issueID;

            if(vm.countriesToRemove != null && vm.countriesToRemove.length > 0) {
                returnClobSettingsObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                returnClobSettingsObj['combination_key'] = vm.COMBINATION_KEY;
                returnClobSettingsObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                returnClobSettingsObj['chart_key'] = vm.chart_key[0];
                returnClobSettingsObj['countries_to_remove'] = vm.countriesToRemove.toString();
            }
            else {
                returnClobSettingsObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                returnClobSettingsObj['combination_key'] = vm.COMBINATION_KEY;
                returnClobSettingsObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                returnClobSettingsObj['chart_key'] = vm.chart_key[0];
                returnClobSettingsObj['countries_to_remove'] = '0';
            }

            sendDetails(action_code, returnClobSettingsObj,EXTK1FormSaveDtls, message);

        }

        function sendDetails(action_code, returnClobSettingsObj, EXTK1FormSaveDtls, message) {

            PartnerAllocServicesFactory.savePartnerAllocationForm(action_code, returnClobSettingsObj,EXTK1FormSaveDtls).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {
                    //vm.crudLoading = false;
                    AlertService.add("success", message, 4000);
                    var args = {
                        pship_leid: returnClobSettingsObj.pship_leid,
                        gridFilter: {
                            pship_leid: returnClobSettingsObj.pship_leid
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);

                };

            });
        }

    }

    return controllers;

});