define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.ghostSourcingInputService',[])
       .factory("sourcingInputScreenFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector', 'USER_SETTINGS',
    	   		'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'

		,function($rootScope, $log, JsonObjectFactory, AlertService, $injector, USER_SETTINGS,
				$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
			var sourcingInputScreenFactory ={};
			sourcingInputScreenFactory.url ={};
			sourcingInputScreenFactory.url.getSourcingInputScreenData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3l9pk9";//Action id 1213
			sourcingInputScreenFactory.url.getSourcingInputScreenLeData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=l6bo67";//Action id 1236
			sourcingInputScreenFactory.url.saveSourcingInputScreenData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=7s7kpw";//Action id 1230
			sourcingInputScreenFactory.url.saveSourcingInputScreenLeData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=scbgzl";//Action id 7015
			sourcingInputScreenFactory.url.getAppBases = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=oslque";//Action id 30128
			sourcingInputScreenFactory.url.getBaskets = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=xm5oxg";//Action id 30128
			sourcingInputScreenFactory.url.saveAppBases = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=l49zjo";//Action id 30129
			sourcingInputScreenFactory.url.saveAppBases_qbu = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ftveh4";
			sourcingInputScreenFactory.url.saveSourcingBasketData = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=7s7kpw";//Action id 1230
			sourcingInputScreenFactory.url.saveSourcingBasketData_qbu = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qwwxtm";//Action id 1230
			sourcingInputScreenFactory.url.saveScenarioAdjustments = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hp1utk";//Action id 30244
			sourcingInputScreenFactory.url.getExistingBaskets = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=geqc7n";//Action id 32664
			sourcingInputScreenFactory.url.getIssueId= GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=8r4vs8";//Action id 32666
			sourcingInputScreenFactory.url.getAppBaseIssueKey = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=ne26e4";//Action id 32696
			
			
			var service = $injector.get('JsonObjectFactory');
			var defer = $q.defer();
			var ssoId = USER_SETTINGS.user.sso_id;
			var saveError = "Save Failed. Please contact System Administrator.";
			var saveSuccess = "Data Saved Successfully.";
			var defaultBasketReadonlyError = "This Amount cannot be edited as it is default Basket of this Income Acct";
			var invalidValueError = "Please enter a valid amount.";
			var totalRowReadonlyWarning = "This cell is not editable.";

			sourcingInputScreenFactory.saveGhostSourcingBasketData = function(_data , settings) {
				console.log("data - ", _data);
				console.log("settings - ",settings);
				var jsonSettings = "";
	            var jsonObj = "";
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

				if(_data == "save_ghost_sourcing_basket_data"){
					filterParams['col_id'] = settings.col_id;
					jsonSettings =  JSON.stringify(filterParams);
					jsonObj = JSON.stringify(settings.rowData);
				}else{
					jsonSettings = JSON.stringify(settings);
					jsonObj = JSON.stringify(_data);
				}
				
				
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario),  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "SAVE_GHOST_BASKET_DATA";
				
			
	           var promise = $http({
	        	   method: "post",
	               url: sourcingInputScreenFactory.url.saveSourcingBasketData_qbu,
	               data: params
	           }).then(function (data) {
	               var status = data.status;
				   if (data.data.callSuccess === "1") {
						$rootScope.$broadcast('gridRefresh', true); 
						$rootScope.$emit('gridUpdate', {});
						AlertService.add("success", "Data saved Successfully.", 3000);
						$rootScope.$broadcast('dataFilters:refreshGrid', {
							"refresh": true
						});
					} else {
						console.log('error data' , data);
						AlertService.add("error", data.data.errorMessage, 3000);
					}
					return data.data;
	           });
	
	           return promise;

				/*//Do Nothing if new value is null/undefined/0 or old and new values are same
				if (_data.rowData['GGROWCOLORTOTAL'] === 1) {
					_data.rowData[_data.col_id] = _data.oldRowData[_data.col_id];
					//AlertService.add("warning", totalRowReadonlyWarning, 4000);
					var intr=setInterval(function(){
						AlertService.add("error", totalRowReadonlyWarning);
					   clearInterval(intr);
					   $rootScope.$apply();
					},1000);
					//$rootScope.$broadcast('gridRefresh', true);
					//return;
				} else if (_data.rowData[_data.col_id] === null || _data.rowData[_data.col_id] === undefined
					|| _data.rowData[_data.col_id].trim() === '') {
					_data.rowData[_data.col_id] = _data.oldRowData[_data.col_id];
					//AlertService.add("error", invalidValueError, 4000);
					var intr=setInterval(function(){
						AlertService.add("error", invalidValueError);
					   clearInterval(intr);
					   $rootScope.$apply();
					},1000);
					//$rootScope.$broadcast('gridRefresh', true);
					//return;
				} else if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]) {
					//Do Nothing
					//return;
				} else if (_data.rowData['SYSTEM_ACCT_TYPE'] === 6 &&
						   _data.rowData['DEFAULT_SOURCE'] === 'B' &&
						   _data.rowData['PIVOT_COLUMN_UNPIVOTED_DATA'][_data.col_id]['BASKET_ID'] ===  _data.rowData['DEFAULT_ID']) {
					//If System Account Type is Income i.e. 6 and current BAsket is the default Basket then it can't be edited.
					//Show Error and revert to old value and refresh grid
					_data.rowData[_data.col_id] = _data.oldRowData[_data.col_id];
					//AlertService.add("error", defaultBasketReadonlyError, 4000);
					var intr=setInterval(function(){
						AlertService.add("error", defaultBasketReadonlyError);
					   clearInterval(intr);
					   $rootScope.$apply();
					},1000);
					//$rootScope.$broadcast('gridRefresh', true);
					//return;
				} else {
					var saveUrl;
					var clobSettings = workspaceFactory.activeScreen.filters.getFilterParams();
					var clobData = _.clone(_data.rowData);
					clobData.$log
					clobSettings.jcd_key = workspaceFactory.activeScreen.jcd_key;
					//Set Apportioned Amt as (Apportioned Amt + old BasketAmt - new Basket Amt)
					var oldBasketAmt = _data.oldRowData[_data.col_id] ? _data.oldRowData[_data.col_id] : 0;
					var defaultSourceName;
					if (_data.rowData['DEFAULT_SOURCE'] === 'B') {
						angular.forEach(_data.rowData['PIVOT_COLUMN_UNPIVOTED_DATA'], function(basketData, basketName) {
							if (!defaultSourceName && basketData.BASKET_ID === _data.rowData['DEFAULT_ID']) {
								defaultSourceName = basketName;
								clobData['SOURCE_AMT'] = Number(_data.rowData[defaultSourceName]) + Number(oldBasketAmt) - Number(_data.rowData[_data.col_id]);
								clobData['SOURCE_ID'] = basketData.BASKET_ID;
								clobData['SOURCE_TYPE'] = 'B';
							}
						});
					} else {
						clobData['SOURCE_AMT'] = Number(clobData['UNSOURCED_APPORTIONED_AMT']) + Number(oldBasketAmt) - Number(_data.rowData[_data.col_id]);
						clobData['SOURCE_ID'] = _data.rowData['BASE_ID'] ? _data.rowData['BASE_ID'] : _data.rowData['DEFAULT_ID'];
						clobData['SOURCE_TYPE'] = 'A';
					}
					clobData['BASKET_AMT'] = Number(_data.rowData[_data.col_id]);
					clobData['BASKET_ID'] = _data.rowData['PIVOT_COLUMN_UNPIVOTED_DATA'][_data.col_id]['BASKET_ID'];
					clobData['FILING_GROUP'] = _data.rowData['FILING_GROUP'];
					var params = {sso_id: ssoId, process_name: 'SAVE_GHOST_BASKET_DATA',
								  jsonSettings: JSON.stringify(clobSettings), jsonObj: JSON.stringify(clobData)};

					if(clobData.GRID_TYPE && clobData.GRID_TYPE === 'QBU') {
						saveUrl = sourcingInputScreenFactory.url.saveSourcingBasketData_qbu;
					} else {
						saveUrl = sourcingInputScreenFactory.url.saveSourcingBasketData;
					}
					//service.saveJSON(sourcingInputScreenFactory.url.saveSourcingBasketData,params).then(function (data) {
					service.saveJSON(saveUrl,params).then(function (data) {
						if (data.callSuccess === "1") {
							//Update rowData default source amount
							if (_data.rowData['SYSTEM_ACCT_TYPE'] === 6 &&
								_data.rowData['DEFAULT_SOURCE'] === 'B') {
								_data.rowData[defaultSourceName] = clobData['SOURCE_AMT'];
								 
							} else {
								_data.rowData['UNSOURCED_APPORTIONED_AMT'] = clobData['SOURCE_AMT'];
							}
							$rootScope.$broadcast('gridRefresh', true);
    						$rootScope.$emit('gridUpdate', {});
							AlertService.add("success", saveSuccess, 3000);
							defer.resolve(data);
						}else {
                            AlertService.add("error", data.errorMessage);
                            defer.reject(data);
                        }
                    },function(data){
                        defer.reject(data);
                    });
                    return defer.promise;
				}*/

			};

			/*sourcingInputScreenFactory.saveNewBasketData = function(rowData, colData, basket, default_basket_amt, new_basket_amt) {

					var saveUrl;
					var clobSettings = workspaceFactory.activeScreen.filters.getFilterParams();
					var clobData = _.clone(rowData);
					clobData.$log
					clobSettings.jcd_key = workspaceFactory.activeScreen.jcd_key;
					//Set Apportioned Amt as (Apportioned Amt + old BasketAmt - new Basket Amt)
					var oldBasketAmt = 0;
					var defaultSourceName;
					if (rowData['DEFAULT_SOURCE'] === 'B') {
						angular.forEach(rowData['PIVOT_COLUMN_UNPIVOTED_DATA'], function(basketData, basketName) {
							if (!defaultSourceName && basketData.BASKET_ID === rowData['DEFAULT_ID']) {
								defaultSourceName = basketName;
								clobData['SOURCE_AMT'] = Number(rowData[defaultSourceName]) + Number(oldBasketAmt) - Number(newParams["BASKET_AMT"]);
								clobData['SOURCE_ID'] = newParams["BASKET_ID"];
								clobData['SOURCE_TYPE'] = 'B';
							}
						});
					} else {
						clobData['SOURCE_AMT'] = Number(clobData['UNSOURCED_APPORTIONED_AMT']) + Number(oldBasketAmt) - Number(newParams["BASKET_AMT"]);
						clobData['SOURCE_ID'] = rowData['BASE_ID'] ? rowData['BASE_ID'] : rowData['DEFAULT_ID'];
						clobData['SOURCE_TYPE'] = 'A';
					}
					clobData['BASKET_AMT'] = newParams["BASKET_AMT"];
					clobData['BASKET_ID'] = newParams["BASKET_ID"];
					clobData['FILING_GROUP'] = rowData['FILING_GROUP'];
					var params = {sso_id: ssoId, process_name: 'SAVE_GHOST_BASKET_DATA',
								  jsonSettings: JSON.stringify(clobSettings), jsonObj: JSON.stringify(clobData)};
					
					params = _.merge({},  GlobalService.globalParams , params);
					saveUrl = sourcingInputScreenFactory.url.saveSourcingBasketData_qbu;
				
					//service.saveJSON(sourcingInputScreenFactory.url.saveSourcingBasketData,params).then(function (data) {
					// service.saveJSON(saveUrl,params).then(function (data) {
					// 	if (data.callSuccess === "1") {
					// 		//Update rowData default source amount
					// 	    $rootScope.$broadcast('gridRefresh', true);
    				// 		$rootScope.$emit('gridUpdate', {});
					// 		AlertService.add("success", saveSuccess, 3000);
					// 		defer.resolve(data);
					// 	}else {
                    //         AlertService.add("error", data.errorMessage);
                    //         defer.reject(data);
                    //     }
                    // },function(data){
                    //     defer.reject(data);
                    // });
					var promise = $http({
						method: "post",
						url: saveUrl,
						data: params
					}).then(function (response) {
						return response.data;
					});
	
                    return promise;

			};
*/
			sourcingInputScreenFactory.getAppBases = function(passedParams) {
				var params = passedParams;
				var promise = $http({
					method: "post",
					url: sourcingInputScreenFactory.url.getAppBases,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};


            
            
            sourcingInputScreenFactory.getAppBaseIssueKey = function(appParams) {
				
				var params = {tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year, 
				              scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
							  jcd_key: GlobalService.globalParams.jcd_key,
							  combination_key: appParams.COMBINATION_KEY,
							  acct_key:appParams.RECORD_ID == "GHOST"?appParams.GHOST_ACCT.toString():appParams.LOCAL_ACCT.toString() ,
							  tp_key:appParams.TP_KEY,
							  base_id: appParams.BASE_ID}
			
				
				var promise = $http({
					method: "post",
					url: sourcingInputScreenFactory.url.getAppBaseIssueKey,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};
			
			
			
			
			
			sourcingInputScreenFactory.getBaskets = function(passedParams) {
				var params = passedParams;
				var promise = $http({
					method: "post",
					url: sourcingInputScreenFactory.url.getBaskets,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};

			sourcingInputScreenFactory.saveApportionmentBase = function(ssoId, clobSettings , clobData) {
				var saveUrl;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				var params = {sso_id: ssoId, process_name: 'SAVE_GHOST_APPORTIONMENT_BASE',
							  jsonSettings: JSON.stringify(clobSettings), jsonObj: JSON.stringify(clobData)};
				params = _.merge({},  GlobalService.globalParams , params,filterParams);
				if(clobData.GRID_TYPE && clobData.GRID_TYPE === 'QBU') {
					saveUrl = sourcingInputScreenFactory.url.saveAppBases_qbu;
				} else {
					saveUrl = sourcingInputScreenFactory.url.saveAppBases;
				}
				var promise = $http({
					method: "post",
					url: saveUrl,
					data: params
				}).then(function (response) {
					return response.data;
				});

				return promise;
			 };

			 
			 sourcingInputScreenFactory.saveGhostScenarioAdjustments = function(_callName , _data) {
				console.log("data - ", _data);
				console.log("callname - ",_callName);

				var saveError = "Save Failed. Please contact System Administrator.";
				var saveSuccess = "Data Saved Successfully.";

				//Do Nothing if new value is null/undefined/0 or old and new values are same
				if (_data.rowData['GGROWCOLORTOTAL'] === 1) {
					_data.rowData[_data.col_id] = _data.oldRowData[_data.col_id];
					//AlertService.add("warning", totalRowReadonlyWarning, 4000);
					var intr=setInterval(function(){
						AlertService.add("error", totalRowReadonlyWarning);
					   clearInterval(intr);
					   $rootScope.$apply();
					},1000);
					//$rootScope.$broadcast('gridRefresh', true);
					//return;
				} else if (_data.rowData[_data.col_id] === null || _data.rowData[_data.col_id] === undefined
					|| _data.rowData[_data.col_id].trim() === '') {
					_data.rowData[_data.col_id] = _data.oldRowData[_data.col_id];
					//AlertService.add("error", invalidValueError, 4000);
					var intr=setInterval(function(){
						AlertService.add("error", invalidValueError);
					   clearInterval(intr);
					   $rootScope.$apply();
					},1000);
					//$rootScope.$broadcast('gridRefresh', true);
					//return;
				} else if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]) {
					//Do Nothing
					//return;
				} else {
					var clobSettings = workspaceFactory.activeScreen.filters.getFilterParams();
					var clobData = _.clone(_data.rowData);
					clobSettings.jcd_key = workspaceFactory.activeScreen.jcd_key;
					clobData['SCENARIO_ADJUSTMENTS'] = _data.rowData[_data.col_id];
					clobData['FILING_GROUP'] = _data.rowData['FILING_GROUP'];
					var params = {sso_id: ssoId, process_name: 'SAVE_GHOST_SCENARIO_ADJUSTMENTS',
								  jsonSettings: JSON.stringify(clobSettings), jsonObj: JSON.stringify(clobData)};
					service.saveJSON(sourcingInputScreenFactory.url.saveScenarioAdjustments,params).then(function (data) {					
						if (data.callSuccess === "1") {
							//Update rowData  amount
							_data.rowData[_data.col_id] = clobData['SCENARIO_ADJUSTMENTS'];
							$rootScope.$broadcast('gridRefresh', true);
    						$rootScope.$emit('gridUpdate', {});
							AlertService.add("success", saveSuccess, 3000);
							defer.resolve(data);
						}else {
                            AlertService.add("error", data.errorMessage);
                            defer.reject(data);
                        }
                    },function(data){
                        defer.reject(data);
                    });
                    return defer.promise;
				}

			};
			
			
			sourcingInputScreenFactory.getExistingBaskets = function(basketParam) {
				
				var params = {tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year, 
				              scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
							  jcd_key: GlobalService.globalParams.jcd_key,
							  combination_key: basketParam.COMBINATION_KEY,
							  acct_key:basketParam.LOCAL_ACCT.toString(),
							  tp_key:basketParam.TP_KEY,
							  ho_combination_key:basketParam.HO_COMBINATION_KEY}
			
				
				var promise = $http({
					method: "post",
					url: sourcingInputScreenFactory.url.getExistingBaskets,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};
			
			
			sourcingInputScreenFactory.getIssueId = function(basketParam) {
				
				var params = {sso_id :ssoId,
							 issue_tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year, 
				             issue_scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario}
			
				
				var promise = $http({
					method: "post",
					url: sourcingInputScreenFactory.url.getIssueId,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};


			 /*sourcingInputScreenFactory.getSourcingInputScreenData = function(passedParams) {
				var actionUrl = (passedParams.hoLeLevel === 'LE') ? sourcingInputScreenFactory.url.getSourcingInputScreenLeData : sourcingInputScreenFactory.url.getSourcingInputScreenData;
				var params = {"process_name":"GHOST Get Sourcing Input Screen Data"};
				params = _.merge({},  passedParams, params);
				var promise = $http({
					method: "post",
					url: actionUrl,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};

			sourcingInputScreenFactory.saveSourcingInput = function(hoLeLevel, ssoId, settings, changedSourcingData) {
				var actionUrl = (hoLeLevel === 'LE') ? sourcingInputScreenFactory.url.saveSourcingInputScreenLeData : sourcingInputScreenFactory.url.saveSourcingInputScreenData;
				var jsonSettings =  JSON.stringify(settings);
				var jsonObj = JSON.stringify(changedSourcingData);
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				var params = GlobalService.globalParams;
				params = _.merge({},  params, filterParams);
				params =  _.extend({jsonObj:jsonObj}, params);
				params =  _.extend({jsonSettings:jsonSettings}, params);
				params =  _.extend({sso_id:ssoId}, params);
				params.process_name =  "SAVE_GHOST_SOURCING_INPUT_FOR_HO";
				var promise = $http({
					method: "post",
					url: actionUrl,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};

			sourcingInputScreenFactory.getSourcingInputScreenData = function(passedParams) {
				var actionUrl = (passedParams.hoLeLevel === 'LE') ? sourcingInputScreenFactory.url.getSourcingInputScreenLeData : sourcingInputScreenFactory.url.getSourcingInputScreenData;
				var params = {"process_name":"GHOST Get Sourcing Input Screen Data"};
				params = _.merge({},  passedParams, params);
				var promise = $http({
					method: "post",
					url: actionUrl,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});

				return promise;
			};

			sourcingInputScreenFactory.saveSourcingInput = function(hoLeLevel, ssoId, settings, changedSourcingData) {
			 var actionUrl = (hoLeLevel === 'LE') ? sourcingInputScreenFactory.url.saveSourcingInputScreenLeData : sourcingInputScreenFactory.url.saveSourcingInputScreenData;
			 var jsonSettings =  JSON.stringify(settings);
			  var jsonObj = JSON.stringify(changedSourcingData);
			  var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
			  var params = GlobalService.globalParams;
			  params = _.merge({},  params, filterParams);
			  params =  _.extend({jsonObj:jsonObj}, params);
			  params =  _.extend({jsonSettings:jsonSettings}, params);
			  params =  _.extend({sso_id:ssoId}, params);
			  params.process_name =  "SAVE_GHOST_SOURCING_INPUT_FOR_HO";
			  var promise = $http({
				  method: "post",
				  url: actionUrl,
				  data: params
			  }).then(function (response) {
				  //var status = response.status;
				  return response.data;
			  });

			  return promise;
			};*/

			return sourcingInputScreenFactory;
       }]);

   	  return services;
});