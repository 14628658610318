define([
    'angular',
    './scheduleJController',
    './scheduleJService'

], function () {
    'use strict';
    return angular.module('app.scheduleJ', ['app.scheduleJController','app.scheduleJService'] )


});