/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.qrevarianceYoYController', [])
					.controller(
							'QrevarianceYoYController',
							[ '$rootScope', '$scope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'JsonObjectFactory', 'QevYoYEFactoryA','qreYOYServiceFactory',
									'$timeout', 'TbbsService',
									'GENERAL_CONFIG', qrevarianceYoYController ])

			function qrevarianceYoYController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					QevYoYEFactoryA, qreYOYServiceFactory,$timeout, tbbsService, GENERAL_CONFIG) {

				var vm = this || thisObj;
				$scope.rowData = rowData || $scope.$parent.rowData;
				$scope.accounts = [];
				$scope.selectAccountData = [];
				$scope.sourceAcctType = null;
				$scope.reclassTotal = "";

				$scope.RECLASS_DESCRIPTION = "";
				$scope.TRANS_HEADER_KEY = "";
				$scope.reclassArray = [];
				$scope.showReclassForm = false;
				$scope.crudLoading = false;
				$scope.existingLoading = false;
				$scope.systemaLoading = false;
				$scope.templateLoading = false;
				$scope.noRecords = false;
				$scope.fromScreen = false;
				// $scope.colData = colData;

				vm.templateDataMain = [];
				vm.systemAcctMain = [];

				if ($uibModalInstance) {
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
							: null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				// 31jsr3
				// rjubnm

				function getSystmAcctDropDown() {
					$scope.systemaLoading = true;
					var params = {
						"action_code" : '31jsr3',
						"tax_year" : GlobalService.globalParams.tax_year,
						"chart_type" : $scope.rowData.CHART_TYPE,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {
											vm.sys_acc_dropdown_data = [];
											vm.systemAcctMain = data.jsonObject;
											for ( var a in data.jsonObject) {
												vm.sys_acc_dropdown_data
														.push(data.jsonObject[a].SYSTEM_ACCT_DESC);
											}

											vm.sys_acc_dropdown_data = _.uniq(
													vm.sys_acc_dropdown_data,
													JSON.stringify);
											$scope.systemaLoading = false;
										}
									})
				}

				function gettemplateData() {
					$scope.templateLoading = true;
					var params = {
						"action_code" : 'g9d8eo',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.templatedata = [];
											vm.templateDataMain = data.jsonObject;
											for ( var a in data.jsonObject) {

												vm.templatedata
														.push(data.jsonObject[a].STATE_NAME);
											}

											vm.templatedata = _.uniq(
													vm.templatedata,
													JSON.stringify);
											$scope.templateLoading = false;
										}
									})
				}

				function init() {
					$scope.accounts = [];
					$scope.accounts1 = [];

					$scope.accounts.push({
						base_entity1 : '',
						base_entity2 : '',
						base_entity3 : '',
						sys_acc_dropdown_data : [],
						templatedata : [],
						templatedata1 : []

					});

					$scope.crudLoading = false;
					$scope.accounts1 = [];
					$scope.existingLoading = false;
					$scope.systemaLoading = false;
					$scope.templateLoading = false;
					$scope.noRecords = false;

					vm.templateError = false;
					vm.systemacctError = false;

					vm.type = false;
					vm.adj_desc = '';
					getSystmAcctDropDown();
					gettemplateData();
					loadExistingReclass();

				}

				init();

				$scope.reset = function() {
					init();

				};
				/*
				 * vm.templateChange = function(index) { if (index ===
				 * undefined) { for ( var a in $scope.accounts) {
				 * $scope.accounts[a].templateError = ""; if
				 * ($scope.accounts[a].template === "" ||
				 * $scope.accounts[a].template === undefined) {
				 * $scope.accounts[a].templateError = true; vm.templateError =
				 * true; } else { vm.templateError = false; } } }
				 * 
				 * else { $scope.accounts[index].templateError = ""; if
				 * ($scope.accounts[index].template === "" ||
				 * $scope.accounts[index].template === undefined) {
				 * $scope.accounts[index].templateError = true; vm.templateError =
				 * true; } else { vm.templateError = false; } } }
				 */

				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoading = true;
					var dummyJson = {};
					var sendobj = [];
					vm.templateChange();
					vm.adjChange();
					vm.acctChange();
					vm.amtChange();
					if (vm.templateError || vm.desError || vm.systemacctError
							|| vm.amtError) {
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						$scope.crudLoading = false;
						throw new ("This is not an error. This is just to abort javascript!");

					}

					else {
						for (var s = 0; s < $scope.accounts.length; s++) {

							dummyJson = {};
							dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
							dummyJson["scenario"] = GlobalService.globalParams.scenario;
							dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
							dummyJson["LEID"] = $scope.rowData.LEID;
							dummyJson["CDR"] = $scope.rowData.CDR_NO;
							dummyJson["Reporting_period"] = $scope.rowData.REPORTING_PERIOD;
							dummyJson["me_code"] = $scope.rowData.ME_CODE;
							dummyJson["adj_desc_old"] = ($scope.accounts[s].type === 'I') ? vm.adj_desc
									: vm.old_adj_desc;
							dummyJson["adj_desc_new"] = vm.adj_desc;

							// vm.templateDataMain = [];
							// vm.systemAcctMain = [];

							for ( var a in vm.systemAcctMain) {
								if ($scope.accounts[s].systemacct == vm.systemAcctMain[a].SYSTEM_ACCT_DESC) {
									dummyJson["system_acct_new"] = vm.systemAcctMain[a].SYSTEM_ACCT_KEY;
								}
							}

							for ( var b in vm.templateDataMain) {
								if (vm.templateDataMain[b].SCHDM_TEMPLATE == $scope.accounts[s].template) {
									dummyJson["template_new"] = vm.templateDataMain[b].SCHDM_TEMPLATE_ID;
								}
							}

							dummyJson["system_acct_old"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
									: $scope.accounts[s].systemacctid;

							dummyJson["template_old"] = ($scope.accounts[s].type === 'I') ? dummyJson["template_new"]
									: $scope.accounts[s].templateid;
							dummyJson["amount_new"] = $scope.accounts[s].amount;
							dummyJson["amount_old"] = ($scope.accounts[s].type === 'I') ? $scope.accounts[s].amount
									: $scope.accounts[s].amount;

							dummyJson["type"] = ($scope.accounts[s].type === 'I') ? "I"
									: "E";
							dummyJson["trans_header_key"] = $scope.accounts[s].trans_header_key;
							dummyJson["trans_details_key"] = $scope.accounts[s].trans_details_key;
							sendobj.push(dummyJson);

							// usumyc
						}
						var message = "TBBS Reclass has been successfully Added";
						reclassCRUDHttp(sendobj, sendobj, message);
					}

				};

				function reclassCRUDHttp(returnObj, accountArray, message) {

					if (returnObj.type === 'D') {
						TbReclassFactoryA.deleteReclass(returnObj).then(
								function(result) {

									if (result.errorMessage
											&& result.errorMessage !== 'null') {
										AlertService.add("error",
												result.errorMessage, 4000);
									} else {
										AlertService.add("success", message,
												4000);

										init();
									}
								});
					} else {
						TbReclassFactoryA
								.saveTBBSReclass(returnObj, accountArray)
								.then(
										function(result) {

											if (result.errorMessage
													&& result.errorMessage !== 'null') {
												AlertService.add("error",
														result.errorMessage,
														4000);
												$scope.crudLoading = false;
											} else {
												AlertService.add("success",
														message, 4000);
												// BROADCAST CHANGE
												$uibModalInstance
														.dismiss('cancel');
												var args = {

													combi_key : $scope.rowData.COMBINATION_KEY,
													gridFilter : {

														combi_key : $scope.rowData.COMBINATION_KEY
													}
												};
												$rootScope.$emit('gridUpdate',
														args);

											}
										});
					}
				}

				function loadExistingReclass() {
					$scope.existingLoading = true;
					vm.transHeaderkeys = [];
					var tempobj = {};
					vm.tempArray = [];
					$scope.tbbsArray = [];
					var params = {
						"action_code" : 'xkk1gy',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					};

					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params).then(
									function(data) {

										$scope.accounts = data.jsonObject;

										for ( var g in data.jsonObject) {

											$scope.accounts1
													.push(data.jsonObject[g]);

										}

										$scope.accounts1 = _.uniq(
												$scope.accounts1,
												JSON.stringify);

										$scope.existingLoading = false;
										if ($scope.reclassArray.length === 0) {
											$scope.noRecords = true;
										}
									});
				}

			}// /////////////END CTRL

			return controllers;

		});