define([
    'angular'

], function () {
    'use strict';
    
    var controllers =  angular.module('app.templateAssignController',[])
        .controller('cacheModalCtrl', ['$scope','$rootScope','$state','$log','JsonObjectFactory','AlertService','$timeout','$uibModalInstance','rowData','$parse','JCDFactory','ClientAdminFactory', 'GENERAL_CONFIG','USER_SETTINGS','GlobalService', cacheModalCtrl])
        .controller('templateAssignController', ['$scope','$rootScope','$http','$state','$uibModal','$log','$timeout', 'SERVER_CONFIG','USER_SETTINGS','TemplateAssignFactory','AlertService','JsonObjectFactory','GlobalService','GENERAL_CONFIG', 'AdminFactory',  templateAssignController])
        .controller('multiplpeTemplateAssignController', ['$scope','$rootScope','$http','$state','$uibModal','$log','$timeout', 'SERVER_CONFIG','USER_SETTINGS','TemplateAssignFactory','AlertService','JsonObjectFactory','GlobalService','GENERAL_CONFIG', 'NotificationFactory', 'AdminFactory', multiplpeTemplateAssignController])
        .controller('multiplpeTemplateUnassignController', ['$scope','$rootScope','$http','$state','$uibModal','$log','$timeout', 'SERVER_CONFIG','USER_SETTINGS','TemplateAssignFactory','AlertService','JsonObjectFactory','GlobalService','GENERAL_CONFIG', 'NotificationFactory', 'AdminFactory', multiplpeTemplateUnassignController])

    function cacheModalCtrl($scope,$rootScope,$state,$log,JsonObjectFactory,AlertService,$timeout,rowData,$parse,JCDFactory,ClientAdminFactory, GENERAL_CONFIG,USER_SETTINGS,GlobalService) {

        var vm = this;
        vm.userSettings = USER_SETTINGS;
        vm.title = "New Cache Definition";
        vm.loading = false;
        vm.cache_data = {};
        vm.validation_rule = "";
        vm.maxLength = 100;
        vm.isMaxLimitReached = false;
        vm.remainingCharacters = 100;
        vm.cacheControlTable = null;
        vm.selectedCache = '';
        vm.showModulesListing = false;
        
		vm.clients = GlobalService.getClients();
        vm.client = GlobalService.getClient();
		vm.client_key=vm.client.client_key
        vm.clientName = vm.client.client_name;
		
        if(vm.userSettings){
            var user = vm.userSettings.user;
            vm.logged_in_user = user.sso_id;
        }

        vm.setOutPut = function(_value){                
            vm.cache_data.proc_params_out = _value;
        }

        vm.setInPut = function(_value){            
            vm.cache_data.proc_params_in = _value;
        }

        vm.setProc = function(_value){           
            vm.cache_data.proc_call = _value;
        }
		
		vm.setFilters = function(_value){                
            vm.cache_data.filter_params = _value;
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.countCharacters = function(){
        	if(typeof vm.cache_data.filter_params === 'undefined'){
        		vm.remainingCharacters = 100;
        	}else {
        		vm.remainingCharacters = vm.maxLength - vm.cache_data.filter_params.length 
            	if(vm.cache_data.filter_params.length >= vm.maxLength){
            		vm.isMaxLimitReached = true;
            	}else {
            		vm.isMaxLimitReached = false;
            	}		
        	}
        }
        
        vm.save = function(){

            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            if (vm.cacheFrom.$invalid) {
                vm.cacheFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }
			
            var data =  {};            
            vm.cache_data.client_key  = vm.client_key;            
            data.json_string = JSON.stringify(vm.cache_data);

            
            JsonObjectFactory.saveJSON(GENERAL_CONFIG.common_api_url + '/saveCache', data).then(function (data) {
                vm.loading = false;
                if (data.callSuccess !== "1"){
                    AlertService.add("error", data.errorMessage);
                } else {

                    AlertService.add("success", "Cache definition saved successfully.", 2000);					
                    $rootScope.$emit('gridUpdate', {});
                    vm.cancel();
                }
            });           
        };
    }

    
    function templateAssignController($scope, $rootScope, $http, $state, $uibModal, $log, $timeout, SERVER_CONFIG, USER_SETTINGS, TemplateAssignFactory, AlertService, JsonObjectFactory,GlobalService,GENERAL_CONFIG, AdminFactory) {

        var vm = this;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
        vm.selectedAppClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });
        vm.selectedClientName = "";
        vm.btnDisabled = "N";
        vm.toggleAll = toggleAll;
        vm.isAllExpanded = isAllExpanded;
        vm.setApplication = setApplication;
        vm.changeGroupRole = changeGroupRole;
        vm.applicationFilter = {
            values: [],
            selected: [],
            name: 'Application(s)',
            model: null,
        };
        vm.showModuleLoader = false;
        vm.showTemplateLoader = false;
        vm.user_id = vm.userSettings.user.sso_id;
        vm.actionType = "TemplateAdminFactory";
        vm.rowPrimaryKey = 0;

        vm.addTemplate = function(){
            var data = {};
            var modalObj = {};
            modalObj.template = "app/components/admin/templates/template-tpl.html";
            modalObj.controler = "templatesCtrl as ctrl";
            modalObj.size = "md";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.openModal(modalObj,data);
        };

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };

        vm.getAllTemplates = function (clientKey){
            vm.showTemplateLoader = true;
            vm.loading = true;
            AdminFactory.getAppKeys(clientKey).then(function (data) {
                vm.channelList = data.channel.p_cursor;
                vm.selectedChannel = vm.channelList[0];
                AdminFactory.getConfigSettings(vm.selectedChannel.channel_key).then(function (data) {
                    //vm.templateGroups = JSON.parse(data.configSettings[142].VALUE);
                    var tempGroupObj = _.find(data.configSettings, {NAME: "access_template_groups"});
                    vm.templateGroups = JSON.parse(tempGroupObj.VALUE);
                    vm.loading = false;

                    TemplateAssignFactory.getAllTemplates(vm.selectedAppClient.client_key).then(function (data) {
                        vm.showTemplateLoader = false;
                        if(data.data.callSuccess === "1" ){
                            console.log(data.data.jsonObject);
                            vm.allTemplates = data.data.jsonObject;
                            vm.templatesGroup = _.groupBy(vm.allTemplates, 'TEMPLATE_GROUP_KEY');
                        }else {
                            if(data.data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
                            }else{
                                AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                    });
                });
            });
        };

        vm.getAllTemplates(user.client.client_key);

        vm.getGroupName = function(value){
            console.log(vm.templateGroups);
            return Object.keys(vm.templateGroups).find(key => vm.templateGroups[key] == value);
        };

        // vm.getAllTemplates = function () {
        //     vm.showTemplateLoader = true;

        //     TemplateAssignFactory.getAllTemplates(vm.selectedAppClient.client_key).then(function (data) {
        //         vm.showTemplateLoader = false;
        //         if(data.data.callSuccess === "1" ){
        //             console.log(data.data.jsonObject);
        //             vm.allTemplates = data.data.jsonObject;
        //             vm.templatesGroup = _.groupBy(vm.allTemplates, 'TEMPLATE_GROUP_KEY');
        //             console.log(templatesGroup);
        //         }else {
        //             if(data.data.errorMessage === "no access"){
        //                 AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
        //             }else{
        //                 AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
        //             }
        //         }
        //     });
        // }

        vm.getTemplateModules = function (template_key) {
            vm.loading = true;
            
            TemplateAssignFactory.getTemplateModules(vm.selectedAppClient.client_key, template_key).then(function (data) {
                vm.loading = false;
                if(data.data.callSuccess === "1" ){
                    console.log(data.data.jsonObject);
                    vm.allTemplates = data.data.jsonObject;
                }else {
                    if(data.data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
                    }else{
                        AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        }

        function mergeNonMatchedTaskHierachy(jsonAry){
            var doProcess1 = function() {
                 for(var i = 0 ; i < jsonAry.length; i++){
                     var chlObj = jsonAry[i];
                     process11(chlObj);
                     chlObj = process12(chlObj);
                 }
             }
            var process11=function(chlObj){
             if (chlObj.children && chlObj.children.length) {
                 for (var c = 0; c < chlObj.children.length; c++) {
                     var childTaskObj = chlObj.children[c];
                     // check any parent key for the current task
                     // if present, add
                     var childObjList = _.filter(chlObj.children,{parentTaskKey : childTaskObj.id});
                     if(childObjList.length){
                         childTaskObj.processed=true;
                         for(var i=0;i<childObjList.length;i++){
                             var childObj = childObjList[i];
                             if(childTaskObj.children)
                                 childTaskObj.children.unshift(childObj);
                             else
                                 childTaskObj.children=[childObj];
                             // process11(chlObj);
                         }
                     }
                 }
             }
         }
         var process12=function(chlObj){
             chlObj.children=_.filter(chlObj.children,{parentTaskKey:null});
             return chlObj;
         }
         console.log(doProcess1());
         }

        vm.getTemplateData = function (template, index){
            vm.showModuleLoader = true;
            vm.showModulesListing = false;
            vm.rowPrimaryKey = template.TEMPLATE_KEY;
            console.log(template, "has been selected");

            TemplateAssignFactory.getTemplateUsers(template.CLIENT_KEY, template.TEMPLATE_KEY).then(function (data) {
                  console.log("fetchAllGTWModules ::",data.data.jsonObject);
                  if(data.data.callSuccess === "1" ){
                       console.log(data.data.jsonObject);
                       vm.userList = data.data.jsonObject;
                  }else {
                      if(data.errorMessage === "no access"){
                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                      }else{
                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                      }
                  }
            });

            TemplateAssignFactory.getTemplateModules(template.CLIENT_KEY, template.TEMPLATE_KEY).then(function (data) {
                vm.loading = false;
                  console.log("fetchAllGTWModules ::",data.data.jsonObject);
                  if(data.data.callSuccess === "1" ){
                       vm.userAssignNav = data.data.jsonObject;
                       vm.applicationFilter.values = data.data.jsonObject.map(function(item) {
                        return { ...item, value: item.id };
                        });
                        vm.applicationFilter.selected = angular.copy(vm.applicationFilter.values);
                        mergeNonMatchedTaskHierachy(vm.userAssignNav);
                        var dropdownOption = _.find(vm.userAssignNav,{id: "5"});
                        setApplication(dropdownOption);
                        filterMenu();
                       vm.getTotalModulesFound();
                       vm.showModuleLoader = false;
                       vm.showModulesListing = true;
                  }else {
                      if(data.errorMessage === "no access"){
                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                      }else{
                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                      }
                  }
            });
        }

        //Used to expand and collpase all the application module cards.
        function isAllExpanded() {
            return vm.filteredUserAssignNav && vm.filteredUserAssignNav.some(function(node) {
                return !node.collapsed;
            });
        }

        //Used to expand and collpase all the application module cards.
        function toggleAll() {
            var expand = isAllExpanded();
            vm.filteredUserAssignNav.forEach(function(node) {
                node.collapsed = expand;
            });
        }

        function setApplication(item) {
            vm.searchTerm = '';
            if (item) {
                vm.selectedApplication = item;
                vm.filteredUserAssignNav = item.children;
            } else {
                filterMenu();
            }
        };

        function filterMenu(searchTerm) {
            var matchName = function(name) {
                return (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            };
            var filter = function(arr) {
                return angular.isArray(arr) && arr.filter(function(item) {
                    if (angular.isArray(item.children)) {
                        item.children = filter(item.children);
                    }
                    return matchName(item.name) || (item.children && item.children.length);
                });
            };
            if (searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.selectedApplication.children));
            } else if (searchTerm && !vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.applicationFilter.selected));
            } else if (!searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = vm.selectedApplication.children;
            } else {
                vm.filteredUserAssignNav = vm.applicationFilter.selected;
            }
        }

        function changeGroupRole(roleHigh, group) {
            if (angular.isArray(group.children)) {
                group.children.forEach(function(item) {
                    changeGroupRole(roleHigh, item);
                });
            }
            if (angular.isArray(group.roles)) {
                if (roleHight) {
                    group.role = _.get(group.roles, { value: Math.max.aplly(Math, _.map(group.roles, 'value'))});
                } else {
                    group.role = _.get(group.roles, { value: Math.min.aplly(Math, _.map(group.roles, 'value'))});
                }
            }
        }

        vm.getTotalModulesFound = function(){
        	vm.totalModulesForSelectedClient = 0;
        	angular.forEach(vm.userAssignNav, function(module) {
        		if(module.children){
        			angular.forEach(module.children, function(child){
        			   if(child !==null){
        				   if(child.children){
        					   angular.forEach(child.children, function(grandChild){
        						   if(grandChild.type && grandChild.type === "activity"){
       	            				   vm.totalModulesForSelectedClient++;
        						   }
        					   })
        				   }
        			   }
        			})
        		}
        	});
        	console.log("In getTotalModulesFound() totalModulesForSelectedClient ::",vm.totalModulesForSelectedClient);
        };

        function filterMenu(searchTerm) {
            var matchName = function(name) {
                return (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            };
            var filter = function(arr) {
                return angular.isArray(arr) && arr.filter(function(item) {
                    if (angular.isArray(item.children)) {
                        item.children = filter(item.children);
                    }
                    return matchName(item.name) || (item.children && item.children.length);
                });
            };
            if (searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.selectedApplication.children));
            } else if (searchTerm && !vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.applicationFilter.selected));
            } else if (!searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = vm.selectedApplication.children;
            } else {
                vm.filteredUserAssignNav = vm.applicationFilter.selected;
            }
        }

        vm.getAllTemplates(user.client.client_key);
        
        $rootScope.$on("TemplateCreated", function(evt, data){ 
            console.log("Template is created.");
            vm.allTemplates = [];
            vm.getAllTemplates(user.client.client_key);
        });
    }

    function multiplpeTemplateAssignController($scope, $rootScope, $http, $state, $uibModal, $log, $timeout, SERVER_CONFIG, USER_SETTINGS, TemplateAssignFactory, AlertService, JsonObjectFactory,GlobalService,GENERAL_CONFIG, NotificationFactory, AdminFactory) {

        var vm = this;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
        vm.selectedAppClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });
        vm.selectedClientName = "";
        vm.btnDisabled = "N";
        vm.toggleAll = toggleAll;
        vm.isAllExpanded = isAllExpanded;
        vm.setApplication = setApplication;
        vm.changeGroupRole = changeGroupRole;
        vm.applicationFilter = {
            values: [],
            selected: [],
            name: 'Application(s)',
            model: null,
        };
        vm.showModuleLoader = false;
        vm.showTemplateLoader = false;
        vm.selectedTemplates = {};
        vm.userList = [];
        vm.user_id = vm.userSettings.user.sso_id;
        vm.actionType = "TemplateAssignFactory";
        vm.rowPrimaryKey = 0;
        var multipleAssignParam = {
            templateId : [],
            userId : []
        };
        vm.showOverlayScreen = false;
        vm.showModuleOverlay = true;
        vm.global_params = angular.copy(GlobalService.globalParams);
        vm.saveDisabled = true;
        // var templateId = [];
        // var userId = [];

        // vm.getAllTemplates = function () {
        //     vm.showTemplateLoader = true;

        //     TemplateAssignFactory.getAllTemplates(vm.selectedAppClient.client_key).then(function (data) {
        //         vm.showTemplateLoader = false;
        //         if(data.data.callSuccess === "1" ){
        //             console.log(data.data.jsonObject);
        //             angular.forEach(data.data.jsonObject, function(value, key) {
        //                 //value.disable = true;
        //             });
        //             vm.allTemplates = data.data.jsonObject;
        //         }else {
        //             if(data.data.errorMessage === "no access"){
        //                 AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
        //             }else{
        //                 AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
        //             }
        //         }
        //     });
        // };

        vm.getAllTemplates = function (clientKey){
            vm.showTemplateLoader = true;
            vm.loading = true;
            AdminFactory.getAppKeys(clientKey).then(function (data) {
                vm.channelList = data.channel.p_cursor;
                vm.selectedChannel = vm.channelList[0];
                AdminFactory.getConfigSettings(vm.selectedChannel.channel_key).then(function (data) {
                    //vm.templateGroups = JSON.parse(data.configSettings[142].VALUE);
                    var tempGroupObj = _.find(data.configSettings, {NAME: "access_template_groups"});
                    vm.templateGroups = JSON.parse(tempGroupObj.VALUE);
                    vm.loading = false;

                    TemplateAssignFactory.getAllTemplates(vm.selectedAppClient.client_key).then(function (data) {
                        vm.showTemplateLoader = false;
                        if(data.data.callSuccess === "1" ){
                            console.log(data.data.jsonObject);
                            vm.allTemplates = data.data.jsonObject;
                            vm.templatesGroup = _.groupBy(vm.allTemplates, 'TEMPLATE_GROUP_KEY');
                        }else {
                            if(data.data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
                            }else{
                                AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                    });
                });
            });
        };

        vm.updateModuleParams = function(type){
            var templateCount = 0;
            angular.forEach(vm.selectedTemplates, function(value, key){
                if(value){
                    templateCount ++;
                }
            });
            vm.saveDisabled = templateCount > 0 ? false : true;
            // if(type === 'template'){
            //     multipleAssignParam.templateId = [];
            //     angular.forEach(vm.selectedTemplates, function(value, key) {
            //         console.log(key + ': ' + value);
            //         if(value){
            //             multipleAssignParam.templateId.push(key);
            //         }
            //     });
            //     vm.showModuleOverlay = multipleAssignParam.templateId.length > 0 ? false : true;
            //     console.log(multipleAssignParam.templateId);
            // }else{
            //     multipleAssignParam.userId = [];
            //     angular.forEach(vm.userList, function(value, key) {
            //         console.log(key + ': ' + value);
            //         // if(value){
            //         //     userId.push(key);
            //         // }
            //         multipleAssignParam.userId.push(value.ssoId);
            //     });
            //     console.log(multipleAssignParam.userId);
            // }
            // TemplateAssignFactory.setModuleParams(multipleAssignParam);
        };

        vm.getTemplateModules = function (template_key) {
            vm.loading = true;
            
            TemplateAssignFactory.getTemplateModules(vm.selectedAppClient.client_key, template_key).then(function (data) {
                vm.loading = false;
                if(data.data.callSuccess === "1" ){
                    console.log(data.data.jsonObject);
                    vm.allTemplates = data.data.jsonObject;
                }else {
                    if(data.data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
                    }else{
                        AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        }

        function mergeNonMatchedTaskHierachy(jsonAry){
            var doProcess1 = function() {
                 for(var i = 0 ; i < jsonAry.length; i++){
                     var chlObj = jsonAry[i];
                     process11(chlObj);
                     chlObj = process12(chlObj);
                 }
             }
            var process11=function(chlObj){
                if (chlObj.children && chlObj.children.length) {
                    for (var c = 0; c < chlObj.children.length; c++) {
                        var childTaskObj = chlObj.children[c];
                        // check any parent key for the current task
                        // if present, add
                        var childObjList = _.filter(chlObj.children,{parentTaskKey : childTaskObj.id});
                        if(childObjList.length){
                            childTaskObj.processed=true;
                            for(var i=0;i<childObjList.length;i++){
                                var childObj = childObjList[i];
                                if(childTaskObj.children)
                                    childTaskObj.children.unshift(childObj);
                                else
                                    childTaskObj.children=[childObj];
                                // process11(chlObj);
                            }
                        }
                    }
                }
            }
            var process12=function(chlObj){
                chlObj.children=_.filter(chlObj.children,{parentTaskKey:null});
                return chlObj;
            }
            console.log(doProcess1());
        }

        $scope.getUser = function(val){
            $scope.isLoadingEmail_to = true;
            return TemplateAssignFactory.getUser(val).then(function(users){
                console.log(users);
                $scope.isLoadingEmail_to= false;
                return users;
            });
        }

        $scope.addUser = function (user,email_type) {
            console.log('add user', user);
            const email = {
                "name": user.name,
                "userName": user.userName,
                "emailAddress": user.email,
                "ssoId": user.ssoId
            };
            //let completeEmailList = $scope.emailList_to.concat($scope.emailList_cc,$scope.emailList_bcc);
            let completeEmailList = vm.userList;
            if(NotificationFactory.isDulplicateEmail(completeEmailList, email)){
                AlertService.add('warning', 'Email already added',1000);
                return;
            }else{
                vm.userList.push(email);
            }
            //vm.updateModuleParams('user');
        }

        $scope.removeUser = function (index, email_type){
            console.log('remove user', index);
            vm.userList.splice(index, 1);
            //vm.updateModuleParams('user');
            // switch(email_type){
            //     case 'TO':
            //         $scope.emailList_to.splice(index);
            //         break;
            //     case 'CC':
            //         $scope.emailList_cc.splice(index);
            //         break;
            //     case 'BCC':
            //         $scope.emailList_bcc.splice(index);
            //         break;
            //     default:
            //         $scope.notification_to.splice(index);
            // }
        }

        vm.getTemplateData = function (template, index){
            vm.showModuleLoader = true;
            vm.showModulesListing = false;
            console.log(template, "has been selected");
            console.log(vm.selectedTemplates);

            // TemplateAssignFactory.getTemplateUsers(template.CLIENT_KEY, template.TEMPLATE_KEY).then(function (data) {
            //       console.log("fetchAllGTWModules ::",data.data.jsonObject);
            //       if(data.data.callSuccess === "1" ){
            //            console.log(data.data.jsonObject);
            //            vm.userList = data.data.jsonObject;
            //       }else {
            //           if(data.errorMessage === "no access"){
            //               AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
            //           }else{
            //               AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
            //           }
            //       }
            // });

            TemplateAssignFactory.getTemplateModules(0, 1065).then(function (data) {
                vm.loading = false;
                  console.log("fetchAllGTWModules ::",data.data.jsonObject);
                  if(data.data.callSuccess === "1" ){
                       vm.userAssignNav = data.data.jsonObject;
                       vm.applicationFilter.values = data.data.jsonObject.map(function(item) {
                        return { ...item, value: item.id };
                        });
                        vm.applicationFilter.selected = angular.copy(vm.applicationFilter.values);
                        mergeNonMatchedTaskHierachy(vm.userAssignNav);
                        var dropdownOption = _.find(vm.userAssignNav,{id: "5"});
                        setApplication(dropdownOption);
                        filterMenu();
                        vm.getTotalModulesFound();
                        vm.showModuleLoader = false;
                        vm.showModulesListing = true;
                        angular.forEach(vm.allTemplates, function(value, key) {
                            value.disable = false;
                        });
                  }else {
                      if(data.errorMessage === "no access"){
                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                      }else{
                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                      }
                  }
            });
        }

        //$rootScope.$on('showLoader', function(event, args) {
            //vm.showOverlayScreen = args;
            // if(args){
            //     vm.showOverlayScreen = args;
            // }else{
            //     vm.showOverlayScreen = args;
            //     AlertService.add("warning", "Please select atleast 1 template to assign modules.", 4000);
            // }
        //});

        vm.saveUserList = function (){
            var clobData = {
                users : [],
                templates : [],
                isFinalList : 'N'
            };

            angular.forEach(vm.userList, function(user){
                clobData.users.push(user.ssoId);
            });

            angular.forEach(vm.selectedTemplates, function(value, key){
                if(value){
                    clobData.templates.push(value);
                }
            });

            TemplateAssignFactory.saveMultiTempUser(clobData, vm.selectedAppClient.client_key, vm.global_params).then(function (data) {
                  if(data.data.callSuccess === "1" ){
                       AlertService.add("success", "Multiple templates and users mapped successfully", 4000);
                  }else {
                      if(data.errorMessage === "no access"){
                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                      }else{
                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                      }
                  }
            });
        };

        vm.resetUserList = function (){
            vm.userList = [];
            vm.selectedTemplates = {};
            vm.saveDisabled = false;
        };

        //Used to expand and collpase all the application module cards.
        function isAllExpanded() {
            return vm.filteredUserAssignNav && vm.filteredUserAssignNav.some(function(node) {
                return !node.collapsed;
            });
        }

        //Used to expand and collpase all the application module cards.
        function toggleAll() {
            var expand = isAllExpanded();
            vm.filteredUserAssignNav.forEach(function(node) {
                node.collapsed = expand;
            });
        }

        function setApplication(item) {
            vm.searchTerm = '';
            if (item) {
                vm.selectedApplication = item;
                vm.filteredUserAssignNav = item.children;
            } else {
                filterMenu();
            }
        };

        function filterMenu(searchTerm) {
            var matchName = function(name) {
                return (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            };
            var filter = function(arr) {
                return angular.isArray(arr) && arr.filter(function(item) {
                    if (angular.isArray(item.children)) {
                        item.children = filter(item.children);
                    }
                    return matchName(item.name) || (item.children && item.children.length);
                });
            };
            if (searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.selectedApplication.children));
            } else if (searchTerm && !vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.applicationFilter.selected));
            } else if (!searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = vm.selectedApplication.children;
            } else {
                vm.filteredUserAssignNav = vm.applicationFilter.selected;
            }
        }

        function changeGroupRole(roleHigh, group) {
            if (angular.isArray(group.children)) {
                group.children.forEach(function(item) {
                    changeGroupRole(roleHigh, item);
                });
            }
            if (angular.isArray(group.roles)) {
                if (roleHight) {
                    group.role = _.get(group.roles, { value: Math.max.aplly(Math, _.map(group.roles, 'value'))});
                } else {
                    group.role = _.get(group.roles, { value: Math.min.aplly(Math, _.map(group.roles, 'value'))});
                }
            }
        }

        vm.getTotalModulesFound = function(){
        	vm.totalModulesForSelectedClient = 0;
        	angular.forEach(vm.userAssignNav, function(module) {
        		if(module.children){
        			angular.forEach(module.children, function(child){
        			   if(child !==null){
        				   if(child.children){
        					   angular.forEach(child.children, function(grandChild){
        						   if(grandChild.type && grandChild.type === "activity"){
       	            				   vm.totalModulesForSelectedClient++;
        						   }
        					   })
        				   }
        			   }
        			})
        		}
        	});
        	console.log("In getTotalModulesFound() totalModulesForSelectedClient ::",vm.totalModulesForSelectedClient);
        };

        function filterMenu(searchTerm) {
            var matchName = function(name) {
                return (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            };
            var filter = function(arr) {
                return angular.isArray(arr) && arr.filter(function(item) {
                    if (angular.isArray(item.children)) {
                        item.children = filter(item.children);
                    }
                    return matchName(item.name) || (item.children && item.children.length);
                });
            };
            if (searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.selectedApplication.children));
            } else if (searchTerm && !vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.applicationFilter.selected));
            } else if (!searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = vm.selectedApplication.children;
            } else {
                vm.filteredUserAssignNav = vm.applicationFilter.selected;
            }
        }

        vm.getGroupName = function(value){
            console.log(vm.templateGroups);
            return Object.keys(vm.templateGroups).find(key => vm.templateGroups[key] == value);
        };

        vm.getAllTemplates(user.client.client_key);
        //vm.getTemplateData();

        $rootScope.$on("TemplateCreated", function(evt, data){ 
            console.log("Template is created.");
            vm.allTemplates = [];
            vm.getAllTemplates(user.client.client_key);
        });
       
    }

    function multiplpeTemplateUnassignController($scope, $rootScope, $http, $state, $uibModal, $log, $timeout, SERVER_CONFIG, USER_SETTINGS, TemplateAssignFactory, AlertService, JsonObjectFactory,GlobalService,GENERAL_CONFIG, NotificationFactory, AdminFactory) {

        var vm = this;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
        vm.selectedAppClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });
        vm.selectedClientName = "";
        vm.btnDisabled = "N";
        vm.applicationFilter = {
            values: [],
            selected: [],
            name: 'Application(s)',
            model: null,
        };
        vm.showModuleLoader = false;
        vm.showTemplateLoader = false;
        vm.selectedTemplates = {};
        vm.userList = [];
        vm.user_id = vm.userSettings.user.sso_id;
        vm.actionType = "TemplateAssignFactory";
        vm.rowPrimaryKey = 0;
        vm.showOverlayScreen = false;
        vm.showModuleOverlay = true;
        vm.global_params = angular.copy(GlobalService.globalParams);
        vm.saveDisabled = true;
        vm.allUserTemplates = [];
        vm.userSearch = false;
        vm.unAssignedTemplatesList = [];
        vm.onLoadTempList = [];

        vm.getAllTemplates = function (clientKey, sso_id){
            vm.showTemplateLoader = true;
            vm.loading = true;
            TemplateAssignFactory.getUserTemplates(clientKey, sso_id).then(function (data) {
                vm.showTemplateLoader = false;
                if(data.data.callSuccess === "1" ){
                    vm.showTemplateLoader = false;
                    vm.allUserTemplates = data.data.jsonObject;
                    vm.onLoadTempList = angular.copy(data.data.jsonObject);
                    vm.saveDisabled = false;
                }else {
                    if(data.data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
                    }else{
                        AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        };

        $scope.getUser = function(val){
            $scope.isLoadingEmail_to = true;
            return TemplateAssignFactory.getUser(val).then(function(users){
                console.log(users);
                $scope.isLoadingEmail_to= false;
                return users;
            });
        }

        $scope.addUser = function (user, email_type) {
            console.log('add user', user);
            const email = {
                "name": user.name,
                "userName": user.userName,
                "emailAddress": user.email,
                "ssoId": user.ssoId
            };
            //let completeEmailList = $scope.emailList_to.concat($scope.emailList_cc,$scope.emailList_bcc);
            let completeEmailList = vm.userList;
            if(NotificationFactory.isDulplicateEmail(completeEmailList, email)){
                AlertService.add('warning', 'Email already added',1000);
                return;
            }else{
                vm.userList.push(email);
                vm.showTemplateLoader = true;
                vm.userSearch = true;
            }
            vm.getAllTemplates(vm.selectedAppClient.client_key, email.ssoId);
        }

        $scope.removeTemplate = function (index){
            console.log('remove user', index);
            vm.unAssignedTemplatesList.push(vm.allUserTemplates[index].TEMPLATE_KEY);
            vm.allUserTemplates.splice(index, 1);
        }

        vm.saveTemplateList = function (){
            var clobData = {
                user_sso : vm.userList[0].ssoId,
                unassigned_template_keys : vm.unAssignedTemplatesList.toString()
            };

            TemplateAssignFactory.saveUserTemplates(clobData, user.client.client_key, vm.global_params).then(function (data) {
                  if(data.data.callSuccess === "1" ){
                       AlertService.add("success", "Templates unassigned successfully", 4000);
                  }else {
                      if(data.errorMessage === "no access"){
                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                      }else{
                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                      }
                  }
            });
        };

        vm.resetTemplateList = function (){
            vm.allUserTemplates = angular.copy(vm.onLoadTempList);
            vm.saveDisabled = false;
        };

        vm.clearUser = function(){
            vm.userSearch = false; 
            vm.userList = [];
            vm.allUserTemplates = [];
        }

        vm.getGroupName = function(value){
            console.log(vm.templateGroups);
            return Object.keys(vm.templateGroups).find(key => vm.templateGroups[key] == value);
        };

        $rootScope.$on("TemplateCreated", function(evt, data){ 
            console.log("Template is created.");
            vm.allTemplates = [];
        });
       
    }

    return controllers;

});