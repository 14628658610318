define(
		[ 'angular',
			'./Configuration861Service',

		],
		function() {
			'use strict';

			var controllers = angular.module('app.Configuration861Ctrl', [])
					.controller('configuration861Ctrl', ['$scope','$rootScope','$state', '$http', 'AlertService', 'USER_SETTINGS', 'GlobalService', 
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'configuration861Factory','rowData', 'gridData', '$uibModal', '$uibModalInstance','$filter',configuration861Ctrl])
							    
			function configuration861Ctrl($scope, $rootScope, $state, $http, AlertService,USER_SETTINGS, GlobalService,
					JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, configuration861Factory,rowData, gridData, $uibModal, $uibModalInstance,$filter) {
				
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.jsonObject = null;
				vm.taxYearInfo = [];
				vm.configInfoTemp = [];
				vm.isSaveDisabled = false;
				vm.selected = _.get(GlobalService,'inputs.tax_year.selected');						
				//To get the Grid Filter Params 
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.scenario = filterParams.scenario;   //GlobalService.globalParams.scenario;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				//Set Tax_year during add/edit
				vm.isCreate = (gridData.length == 0) ? true : false;
				//Get the selected Tax Year
				vm.taxYear = vm.isCreate ? vm.filterTaxYear : rowData.TAX_YEAR;
				
				//Cancel/CLose the Modal
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + vm.taxYear + "." ,'4000');
							return;
						}							
					});
				});
				
				//reset the form
				vm.reset = function(type){	
					if(type == 'add') {
						vm.configuration = {};
						vm.configGIEAppr = {};
					}else{
						vm.getDefaultDropDown();
					}
				}				
				
				//Get the Data from FTC Code Mast 
				vm.getDefaultDropDown = function() {		
					var params = {"action_code" : 'tcanfc', "tax_year" : vm.filterTaxYear, "scenario" : vm.scenario };					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.jsonObject = data.jsonObject;
						 vm.configInfoTemp = $filter('filter')(data.jsonObject, function(data) {
	                            return (data.CODE_GROUP === '861_CONFIG' || data.CODE_GROUP === 'YES_NO');
	                     });
						 vm.configGIEApprtionData = $filter('filter')(data.jsonObject, function(data) {
	                            return (data.CODE_GROUP === '861_GIE_CONFIG' || data.CODE_GROUP === '861_GIE_CONFIG_NEW');
	                     });
						 if(!vm.isCreate){
							 console.log( " : ----  rowData : ", rowData);
							 vm.configuration = {CODE_KEY:rowData.CONFIG_KEY,CODE_DESCRIPTION:rowData.CONFIG_DESC};
							 for(var indx in vm.configGIEApprtionData){
								 if(vm.configGIEApprtionData[indx].CODE_DESCRIPTION == rowData.GIE_DESC
										 && parseInt(vm.configGIEApprtionData[indx].CODE_SHORT_DESC) == parseInt(rowData.GIE_KEY)){
									 vm.configGIEAppr = vm.configGIEApprtionData[indx];
								 }
							 }	

							 vm.apprConfigDropdownDetails();
						 }
		
					});
				};
				
				vm.apprConfigDropdownDetails = function() 
				{
				  if(vm.configGIEAppr.CODE_GROUP == "861_GIE_CONFIG"){
					vm.configInfo = $filter('filter')(vm.configInfoTemp, function(data) {
                        return (data.CODE_GROUP === '861_CONFIG');
                    });
				  }
				  else if(vm.configGIEAppr.CODE_GROUP == "861_GIE_CONFIG_NEW")
				  {
					  vm.configInfo = $filter('filter')(vm.configInfoTemp, function(data) {
	                        return (data.CODE_GROUP === 'YES_NO');
	                    });
				  }
					
				};
				
				
				vm.getDefaultDropDown();
				
				//Get the Current year from calendar
				vm.getCurrentYear = function() {
					var currentdate = new Date();
				    return currentdate.getFullYear();
				}
				
				
				//Get the Data from FTC Code Mast 
				vm.getSaved861Config = function() {		
					var params = {"action_code" : 'bb3f1t', "tax_year" : vm.filterTaxYear, "scenario" : vm.scenario };					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.saved861ConfigData = data.jsonObject;
						console.log(" vm.saved861ConfigData : ", vm.saved861ConfigData);
					});
				};
				vm.getSaved861Config();
				
				//Save the data
				vm.save = function(){	
					
					if((vm.getCurrentYear()-1) < vm.taxYear){
						AlertService.add("warning", "For "+vm.taxYear+" tax year config can not be created", 4000);
						return;
					}
					if(vm.configuration === undefined || vm.configuration.CODE_KEY === undefined || vm.configuration.CODE_KEY === null){
						AlertService.add("error", "Configuration is required", 4000);
						return;
					}
					
					if(vm.configGIEAppr === undefined || vm.configGIEAppr.CODE_KEY === undefined || vm.configGIEAppr.CODE_KEY === null){
						AlertService.add("error", "GIE/Apportionment is required", 4000);
						return;
					}
										
					if(vm.isCreate == false){
						for(var indx in vm.saved861ConfigData){
							if(vm.saved861ConfigData[indx].GIE_KEY == vm.configGIEAppr.CODE_SHORT_DESC 
								&& vm.saved861ConfigData[indx].ADJ_KEY!=rowData.ADJ_KEY){
								AlertService.add("error", "Configuration already exists for the selected Apportionment.", 4000);
								return;
							}
						}
					}
					vm.isSaveDisabled = true;
					
					var clobSettingsObj = {};
					clobSettingsObj['sso_id'] = vm.userSettings.user.sso_id;
					clobSettingsObj['scenario'] = vm.scenario;
					clobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					
					var returnObj = {};
					
					returnObj['sso_id'] = vm.userSettings.user.sso_id;
					returnObj["tax_year"] = vm.taxYear;
					returnObj['scenario'] = vm.scenario;
					returnObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					returnObj["operation_type"] = vm.isCreate ? 'I':'U';
					returnObj["config_desc"] = vm.configuration.CODE_DESCRIPTION;
					returnObj["config_key"] = vm.configuration.CODE_KEY;
					returnObj["gie_desc"] = vm.configGIEAppr.CODE_DESCRIPTION;
					returnObj["gie_key"] = vm.configGIEAppr.CODE_SHORT_DESC;
					
					var clobDataDetails = [];
					clobDataDetails.push(returnObj);
					console.log("saveDataDetails  -- ", clobDataDetails);
					
					var message = "861 Configuration have been saved/updated";
					//send to save the Data					
					sendSaveDetails(clobSettingsObj, clobDataDetails, message);
					}
				
				
				//Send Details to the Service Layer for persisting the data
				function sendSaveDetails(clobSettingsObj, clobDataDetails, message) {					
					configuration861Factory.saveConfig(clobSettingsObj, clobDataDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveDisabled = false;
							return;
						} else {
							AlertService.add("success", message, 4000);
							vm.getDefaultDropDown();
							$uibModalInstance.dismiss('cancel');
							var args = {};
							if(vm.isCreate == false){
								$rootScope.$emit('gridUpdate', args);
							}else{
								//$rootScope.$broadcast('gridRefresh', {});
								$rootScope.$broadcast('dataFilters:refreshGrid', {
				                    "refresh": true
				                });
							}						
	                     };
					});
				}
				
				//Redirect the URL to a respective screen during navigation 
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
				
			}		
		
			return controllers;
			
		});