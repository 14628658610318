define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.diagnosticDashboardCtrl', []);
    var controllers = module
        .controller('diagnosticCenterController', ['diagnosticDashboardFactory', '$http', '$scope', 'UserAdminFactory', '$state','GENERAL_CONFIG', diagnosticCenterController])
        .animation('.diag-view-details', ['$animateCss', contentAnimate]);

		function diagnosticCenterController(diagnosticDashboardFactory, $http, $scope, UserAdminFactory, $state,GENERAL_CONFIG) {
            var vm = this;
            vm.titleName= "Module Inventory/Request Access";
            vm.dashboardCards=[];
            vm.client_key = 0;
            vm.user_id = 400380129;
            vm.jurisdiction_key= 1;
            vm.gettingStatedPage = false;
            vm.childDetailObj = [];
            vm.selectedChannel = [];
            vm.dashboardCardsList = [];
            vm.loading = false;
            vm.crudLoading = false;
            //  vm.taskLogo = GENERAL_CONFIG.base_url + '/previewCommentAttachement?commentKey=12';
            // vm.taskLogo = 'https://ist-app-dev.s3.amazonaws.com/999/gtw/320';

            vm.onInit = function() {
                var data = {client_key:vm.client_key};
                data.user_id =  vm.user_id;
                data.jurisdiction_key = vm.jurisdiction_key;
                vm.loading = true;
                vm.crudLoading = true;
                UserAdminFactory.getUserAssign(data).then(function (data) {
                    if(data.callSuccess === "1" ){
                        vm.dashboardCards = data.jsonObject;
                        var dropdownOption = _.find(vm.dashboardCards, {id: "5"});
                        vm.channelChange(dropdownOption);
                    //    vm.act;vm.tyn;
                    //    _.each(vm.dashboardCards,function(item){
                    //     _.each(item.children,function(i){
                    //         vm.act = i.children.length;
                    //         console.log("adfadfad",vm.act);
                    //         i.assginCount = 0;
                    //         if(i.children.length>0){
                    //             var outdst=0;
                    //             _.each(i.children,function(x){
                    //                 _.find(x.roles, function(y) {
                    //                     if(x.role.value!=undefined){
                    //                         if(y.value == x.role.value){
                    //                             return outdst++;
                    //                         }
                    //                     }
                    //                 });
                    //             });
                    //             vm.tyn = (outdst!=0)?outdst-1:0;
                    //             i.assginCount = (outdst!=0)?outdst-1:0;
                    //         }
                    //     })
                    // })
                    }else {
                        if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }

                    vm.loading = false;
                    vm.crudLoading = false;
                });
            }
            vm.onInit();

            vm.getEmptyRoleCount = function(childs) {
                var list = _.filter(childs, function(item) {
                    return !!_.get(item, 'role.value');
                });
                return list.length;
            };

            vm.channelChange = function(name) {
                vm.selectedChannel = name;
                vm.dashboardCardsList = name.children;
                console.log(vm.dashboardCardsList, "vm.dashboardCardssssssss");
            }

            vm.viewDetailsModal = function(children) {
                vm.gettingStatedPage = true;
                window.scrollTo(0, 0);
                vm.childDetailObj = children;
                console.log(vm.childDetailObj, "vm.childDetailObj");
            }
        }

        function contentAnimate($animateCss) {
            return {
              enter: function(element, doneFn) {
                var width = element[0].offsetWidth;
                return $animateCss(element, {
                  from: { width: 0, left: width/2, overflow: 'hidden', height: 0, marginTop: '30%', position: 'absolute'},
                  to: { width:'95%', left: 0, height: 'inherit', marginTop: '1%', position: 'relative'},
                  duration: 0.5 // seconds
                });
              }
            }
          }


        return controllers;
    });