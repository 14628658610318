define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.assignPreparerController', [])

        .controller('AssignPreparerController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter',assignPreparerController]);
    	function assignPreparerController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter) {
        var vm = this;

        vm.modalTitle = "Assign Preparer";
		var saveParamDtls = [];
		var tempDataObj = {};
		vm.showFormLoader = true;
		vm.passgriddata = _.clone(gridData);
		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		var taxYear = GlobalService.globalParams.tax_year;
		vm.scenario  = GlobalService.globalParams.scenario;
		vm.globalScenarioDesc	= workspaceFactory.activeScreen.dataCurFilters[1].valueLabel;
		vm.glbl_issue_short_desc = GlobalService.globalParams.issue_short_desc;
		vm.glbl_issue_id  = GlobalService.globalParams.issue_id;
		var templates = []
		var all_templates = {};
		var template_select_arr = [];

		var temp_display_Obj = {};
		var new_template_obj = {};
		var headerList = [];

		vm.template_selection;
		vm.selectedTemplate;
		vm.display_template;
		vm.showFormLoader = true;
		vm.selectedRowIndex;
		vm.selectedRow = {};
		vm.combination_key_List = [];
		vm.leid_list = [];
		vm.saveBtn = true;
		vm.saveloader = false;
		vm.selectedPreparer;
		vm.selfEmployed = [
		{
			id : '1',
			name : 'Yes' 
		},{
			id : '2',
			name : 'No' 
		}];
		var country_US = ['ADDRESS_LINE_1', 'CITY', 'STATE', 'ZIP'];
		var country_non_US = ['ADDRESS_LINE_1'];
		vm.default_country = {};

		angular.forEach(vm.passgriddata, function (row, key) {
			var temp_data = {
				leid: row.data.HO_LEID,
				combination_key: row.data.COMBINATION_KEY,
				reporting_period: row.data.HO_REPORTING_PERIOD,
				group_obj_key: row.data.GROUP_OBJ_KEY
			};
			vm.leid_list.push(temp_data);
		});

		$scope.dropdownSetting ={
			enableSearch: true,
			selectionLimit: 1,
			scrollableHeight: '200px', 
			scrollable: true,
			showUncheckAll:false,
			showCheckAll:false,
			smartButtonTextConverter: function(itemText, originalItem) { 
                return itemText; 
			},
			smartButtonTextProvider:function(selectionArray) { return selectionArray[0].label || "Select"; },
		};

		$scope.multiselectEvents1 = {
			onItemSelect: function (item) {
				if(item.id == "US"){
					vm.display_template.dataRows[vm.selectedRowIndex].STATE = [vm.allUSStates[0]];
				}else{
					vm.display_template.dataRows[vm.selectedRowIndex].STATE = "";
				}
			},
			onItemDeselect: function (item) {
				vm.display_template.dataRows[vm.selectedRowIndex].STATE = "";
			}
		};

		$scope.CountrySelection = function(k){
			var index = _.findIndex(vm.display_template.dataRows, {TEMPLATE_DATA_KEY: k})
			vm.selectedRowIndex = index;
		};

        function fetchTemplates(){
            var params = {
            	tax_year: taxYear,
				scenario: filterParams.scenario,
				jcd_key: GlobalService.globalParams.jcd_key,
				issue_key: gridData[0].data.ISSUE_KEY

            };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=4pr8wo', params).then(function (data) {
				var prev_temp_id = 0;
				var temp_data_arr = [];
				var tempDataId;
				var tempDataArray;
				var sorted_jsonObject = _.sortBy(data.jsonObject, ['TEMPLATE_KEY']);
				angular.forEach(sorted_jsonObject, function (row, key) {
					var temp_id = row.TEMPLATE_KEY;
					
					if(temp_id !== prev_temp_id){
						if(prev_temp_id !== 0){
							templates.push(all_templates);
							all_templates = {};
							headerList = [];
						}
						//prepare dropdown data
						var temp_dropdown_data = {
							id : row.TEMPLATE_KEY,
							value : row.TEMPLATE_NAME 
						};

						//set new template key
						prev_temp_id = row.TEMPLATE_KEY;

						//delete unwanted properties
						delete row.TEMPLATE_KEY;
						delete row.TEMPLATE_NAME;
						var tempDatakey = row.TEMPLATE_DATA_KEY;
						delete row.TEMPLATE_DATA_KEY;
						delete row.ATTRIB_NAME;
						delete row.ATTRIB_VALUE;
						delete row.DATE;
						delete row.PREPARER_SIGNATURE;

						//create valid array of objects
						for (var col in row) {
						    var attributes = row[col].split('~');
						    var temp_table_obj = {
						    	id: attributes[0],
						    	description: attributes[4],
						    	[col]: attributes[5]
						    };
						    temp_data_arr.push(temp_table_obj);
						}

						angular.forEach(temp_data_arr, function (row, key) {
							delete row.id;
							headerList.push(row.description);
							delete row.description;
							for(var col in row){
								if(col === 'DATE'){
									row[col] = new Date(row[col]);
								}else if (col === 'COUNTRY'){
									tempDataId = row[col];
									row[col] = [];
									for (let i =0 ; i< vm.allCountries.length ;i++){
										if(vm.allCountries[i].id == tempDataId){
											row[col] = [vm.allCountries[i]];
										}
									}
									if(row[col].length < 1){
										row[col] = [vm.default_country];
									}
								}else if (col === 'STATE'){
									tempDataId = row[col];
									row[col] = [];
									for (let i =0 ; i< vm.allUSStates.length ;i++){
										if(vm.allUSStates[i].id == tempDataId){
											row[col] = [vm.allUSStates[i]];
										}
									}
									if(temp_display_Obj.COUNTRY[0].id != 'US'){
										row[col] = tempDataId;
									}
								}else if(col === 'PHONE_NO'){
									row[col] = parseInt(row[col]);
								}
								temp_display_Obj[col] = row[col];
								new_template_obj[col] = "";
							}
						});
						temp_data_arr = [];
						temp_display_Obj.TEMPLATE_DATA_KEY = tempDatakey;
						temp_display_Obj.flag = false;
						temp_display_Obj.is_new = false;

						all_templates.tableHeaders = headerList;
						all_templates.newRowData = new_template_obj;
						new_template_obj = {};
						all_templates.dataRows = [];
						all_templates.dataRows.push(temp_display_Obj);
						temp_display_Obj = {};

						template_select_arr.push(temp_dropdown_data);

						if(data.jsonObject.length -1 == key){
							templates.push(all_templates);
							all_templates = {};
						}
					}else{
						prev_temp_id = row.TEMPLATE_KEY;

						//delete unwanted properties
						delete row.TEMPLATE_KEY;
						delete row.TEMPLATE_NAME;
						var tempDatakey = row.TEMPLATE_DATA_KEY;
						delete row.TEMPLATE_DATA_KEY;
						delete row.ATTRIB_NAME;
						delete row.ATTRIB_VALUE;
						delete row.DATE;
						delete row.PREPARER_SIGNATURE;
						
						//create valid array of objects
						for (var col in row) {
						    var attributes = row[col].split('~');
						    var temp_table_obj = {
						    	id: attributes[0],
						    	description: attributes[4],
						    	[col]: attributes[5]
						    };
						    temp_data_arr.push(temp_table_obj);
						}

						angular.forEach(temp_data_arr, function (row, key) {
							delete row.id;
							delete row.description;
							for(var col in row){
								if(col === 'DATE'){
									row[col] = new Date(row[col]);
								}else if (col === 'COUNTRY'){
									tempDataId = row[col];
									row[col] = [];
									for (let i =0 ; i< vm.allCountries.length ;i++){
										if(vm.allCountries[i].id == tempDataId){
											row[col] = [vm.allCountries[i]];
										}
									}
									if(row[col].length < 1){
										row[col] = [vm.default_country];
									}
								}else if (col === 'STATE'){
									tempDataId = row[col];
									row[col] = [];
									for (let i =0 ; i< vm.allUSStates.length ;i++){
										if(vm.allUSStates[i].id == tempDataId){
											row[col] = [vm.allUSStates[i]];
										}
									}
									if(temp_display_Obj.COUNTRY[0].id != 'US'){
										row[col] = tempDataId;
									}
								}else if(col === 'PHONE_NO'){
									row[col] = parseInt(row[col]);
								}
								temp_display_Obj[col] = row[col];
							}
						});
						temp_data_arr = [];

						temp_display_Obj.TEMPLATE_DATA_KEY = tempDatakey;
						temp_display_Obj.flag = false;
						temp_display_Obj.is_new = false;

						all_templates.dataRows.push(temp_display_Obj);
						temp_display_Obj = {};

						if(data.jsonObject.length -1 == key){
							templates.push(all_templates);
							all_templates = {};
						}
					}
	        	});
        		initView();
            });
        };

        function fetchCountryList(){
			var params = {};
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=fyl404').then(function (data) {
				vm.allCountries = []
				if (data.jsonObject) {
					data.jsonObject.forEach(function(country){
						vm.allCountries.push({"id":country.COUNTRY_CODE,"label":country.COUNTRY_DESC});
						if(country.COUNTRY_CODE == 'US'){
							vm.default_country = {
								"id":country.COUNTRY_CODE,
								"label":country.COUNTRY_DESC
							}
						}
					});
				}
            });
		};

		function fetchUSStateList(){
			var params = {};
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=oppj8b').then(function (data) {
				vm.allUSStates =[];
				if (data.jsonObject) {
					data.jsonObject.forEach(function(state){
						vm.allUSStates.push({"id":state.STATE_CODE,"label":state.STATE_DESC})
					});
				}
            });
		};

		fetchCountryList().then(function(){
			fetchUSStateList().then(function(){
				fetchTemplates();
			});			
		});

        function initView() {
        	vm.showFormLoader = false;
			vm.template_selection = template_select_arr;
			vm.selectedTemplate = template_select_arr[0];
			vm.display_template = templates[0];
			vm.display_template_copy = angular.copy(vm.display_template);
		};

		vm.removeLeid = function(i){
			vm.leid_list.splice(i, 1);
		};

		vm.addRows = function(){
			var newRow = angular.copy(vm.display_template.newRowData);
			newRow.TEMPLATE_DATA_KEY = 0;
			newRow.COUNTRY = [vm.default_country];
			newRow.STATE = [];
			newRow.is_new = true;
			vm.display_template.dataRows.push(newRow);
		};

		vm.loadTemplate = function(){
			if(templates.length == 1) {
				vm.display_template = templates[0];
			} else {
				vm.display_template = templates[vm.selectedTemplate.id - 1];
			}
			
			vm.display_template_copy = angular.copy(vm.display_template);
		};

		vm.checkedRow = function(i, row, state){
			vm.selectedRowIndex = i;
			vm.saveBtn = !state;
			vm.selectedRow = row;
			angular.forEach(vm.display_template.dataRows, function (tem, key) {
				if(i !== key){
					vm.display_template.dataRows[key].flag = false;
				}
			});
		};

		vm.isValidDate = function(dateObject){
			return new Date(dateObject) == 'Invalid Date';
		};

        vm.saveSchdIForm = function (url, data){
            var jsonSettings = {
            	"tax_year": vm.passgriddata[0].data.TAX_YEAR,
            	"scenario": vm.passgriddata[0].data.SCENARIO,
            	"jcd_key": vm.passgriddata[0].data.JCD_KEY,
            	"combination_key": vm.passgriddata[0].data.COMBINATION_KEY
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  "Assign Template";
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
            	vm.saveloader = false;
                var status = response.status;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchdIForm() response.data.jsonObj ::",response.data.jsonObject);
				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					var args = {combination_key: vm.combination_key_List[0], gridFilter: {combination_key: vm.combination_key_List[0]}};
					$rootScope.$emit('gridUpdate', args);
					AlertService.add("success", "Template Assigned successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                    }
				}
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function(){
        	var errorFields = [];
        	if(vm.leid_list.length === 0){
        		AlertService.add("error", "Cannot assign preparer, please select atleast one LEID", 4000);
        		return;
        	};
        	if(_.filter(vm.display_template_copy.dataRows, {PREPARER_NAME: vm.selectedRow.PREPARER_NAME}).length && vm.selectedRow.is_new){
				AlertService.add("error", "Preparer already exists with the name," + " \"" + vm.selectedRow.PREPARER_NAME + "\" " + "please provide a different name", 4000);
        		return;
			};
			vm.mainData = {};
			vm.mainData = angular.copy(vm.selectedRow);
			// if(vm.mainData.DATE !== 'Invalid Date' && vm.isValidDate(new Date(vm.mainData.DATE))){
        	// 	AlertService.add("error", "Invalid Date", 4000);
        	// 	return;
        	// };
        	if(vm.mainData.COUNTRY.length == 1){
        		if(vm.mainData.COUNTRY[0].id == 'US'){
        			angular.forEach(country_US, function (val, key) {
        				if(Array.isArray(vm.mainData[val])){
        					if(vm.mainData[val].length == 0){
        						errorFields.push(val.split('_').join(" "));
        					}
        				}else{
        					if(!vm.mainData[val]){
        						errorFields.push(val.split('_').join(" "));
        					}
        				}
					});
        		}else{
        			angular.forEach(country_non_US, function (val, key) {
        				if(!vm.mainData[val]){
    						errorFields.push(val.split('_').join(" "));
    					}
        			});
        		}
        	};
        	if(errorFields.length >= 1){
        		AlertService.add("error", "Following fields are Mandatory : " + errorFields.join(', '), 4000);
        		return;
        	};
        	vm.saveloader = true;
        	vm.mainData.COUNTRY = vm.mainData.COUNTRY.length ? vm.mainData.COUNTRY[0].id : "";
        	if(Array.isArray(vm.mainData.STATE)){
        		vm.mainData.STATE =  vm.mainData.STATE[0].id
        	};
			//vm.mainData.DATE = $filter('date')(vm.mainData.DATE,'MM/dd/yyyy');
			delete vm.mainData.flag;
			vm.mainData.TEMPLATE_KEY = vm.selectedTemplate.id;
			angular.forEach(vm.leid_list, function (val, key) {
				vm.combination_key_List.push(val.combination_key);
			});
			vm.mainData.COMBINATION_KEY = vm.combination_key_List.join(',');
			vm.mainData.GROUP_OBJ_KEY = vm.leid_list[0].group_obj_key;
			vm.mainData.DATE = "";
			vm.mainData.PREPARER_SIGNATURE = "";
			vm.mainData.ISSUE_KEY = gridData[0].data.ISSUE_KEY;

			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=d7mh38";
			vm.saveSchdIForm(url,[vm.mainData]);

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        };

		vm.reset = function (){
			vm.display_template = angular.copy(vm.display_template_copy);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
});