/**
 * This is to sync GOLD Data to GTW
 *
 *
 */
define([
    'angular'

], function () {
    'use strict';


    var controllers = angular.module('app.bizzHierCtrl', [])
        .controller('bizzHierCtrl', ['BizzHierFactory', '$scope', 'USER_SETTINGS', 'TAX_YEARS', 'ModalFactory', '$uibModal', "AlertService",
            "$filter", "GlobalService", "$rootScope", 'SERVER_CONFIG', 'USER_MENU', bizzHierCtrl])


    function bizzHierCtrl(BizzHierFactory, $scope, USER_SETTINGS, TAX_YEARS, ModalFactory, $uibModal, AlertService, $filter, GlobalService, $rootScope, SERVER_CONFIG, USER_MENU) {
        var vm = this;
        // var globalParams = GlobalService.globalParams;
        console.log("user settings ", USER_SETTINGS)
        var changeGlobalParams = $rootScope.$on('GlobalService.globalParams: changed', function (payload) {
            console.log("global params changed;");
            // globalParams = GlobalService.globalParams;
            vm.selectedTaxYear = GlobalService.inputs.tax_year.selected.label;
            vm.selectedScenario = GlobalService.globalParams.scenario;
            vm.trees.left.selectedAppCode = null
            vm.trees.right.selectedAppCode = null
            vm.trees.left.selectedHierarchyId = null
            vm.trees.right.selectedHierarchyId = null
            vm.loadHierarchies('left');
            vm.loadHierarchies('right');
        })
        $scope.$on('$destroy', function () {
            changeGlobalParams()
        })
        vm.lineOfBizz = ['FIN', 'IND'];
        vm.isPrivilegedSSO = SERVER_CONFIG.configSettings.privileged_sso_list.includes(USER_SETTINGS.user.sso_id);
        vm.collapsed = {};
        vm.nodeToMove = {};
        vm.taxAndScenarios = TAX_YEARS
        vm.jcds = USER_MENU.slice(0);
        vm.jcds.push({
            id: '0',
            label: 'GOLD',
            jcd_key: '0'//TODO: MAKE DYNAMIC?
        })
        vm.jcdsWithoutGold = vm.jcds.filter(function (item) {
            return item.label !== 'GOLD'
        })
        // vm.alertMessages = {
        //     "GOLD": ,
        //     "TAX": "Please Select the Client, Tax Year and Hierarchy Type"
        // };
        // vm.loading = {
        //     "GOLD": false,
        //     "TAX": false
        // }
        vm.trees = {
            left: {
                selectedAppCode: null,
                selectedHierarchyId: null,
                selectedJcdkey: null,
                masterHierarchies: [],
                hierarchies: [],
                apps: vm.jcds,
                nodes: [],
                currentAlertMessage: "Please Select the Application and Hierarchy",
                loading: false
            },
            right: {
                selectedAppCode: null,
                selectedHierarchyId: null,
                selectedJcdkey: null,
                masterHierarchies: [],
                hierarchies: [],
                apps: vm.jcdsWithoutGold,
                nodes: [],
                currentAlertMessage: "Please Select the Application and Hierarchy",
                loading: false
            }
        }
        vm.clients = USER_SETTINGS.user.clients;
        vm.taxYears = _.filter(TAX_YEARS, function (item) {
            return typeof item !== 'undefined';
        });


        console.log("JCD's", vm.jcds)
        // vm.hierarchyList1 =
        vm.hierarchyList = [];

        vm.selectedClient = vm.clients[0],
            vm.selectedTaxYear = GlobalService.inputs.tax_year.selected.label;//vm.taxYears[0].label;
        vm.selectedScenario = GlobalService.globalParams.scenario;
        vm.selectedJcd = 250;//TODO: Take from JCD Selection

        // vm.nodes = {
        //     "GOLD": null,
        //     "TAX": null
        // };
        var treeComparisionResult = {
            newleftNodes: [],
            editedrightNodes: [],
            newrightNodes: []
        }
        vm.hierarchyName = '';
        vm.showErrorDupicate = false;
        var flattened = {};
        vm.treeFilter = $filter('uiTreeFilter');

        vm.data = [];

        vm.goldAppCode = 'GOLD';
        vm.goldHierarchyId = 2;


        vm.assignModal = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/admin/bizzHier/templates/assign-modal.html',
                controllerAs: 'ctrl',
                bindToController: true,
                controller: ['$scope', 'BizzHierFactory', '$uibModalInstance', 'TAX_YEARS', 'USER_MENU', function ($scope, BizzHierFactory, $uibModalInstance, TAX_YEARS, USER_MENU) {
                    var vm = this;
                    vm.jcds = USER_MENU;
                    vm.assignHierarchy = {
                        taxYears: [],
                        scenarios: null,
                        jcds: null,
                        selectedTaxYear: null,
                        selectedScenario: null,
                        selectedJcdkey: null,
                        hierarchiesList: null,
                        newHierarchyId: null
                    }

                    vm.assignHierarchy.taxYears.push(TAX_YEARS)


                    vm.taxYearChange = function (taxYear) {
                        vm.loading = true
                        BizzHierFactory.getScenariosJcd(taxYear).then(function (response) {
                            vm.assignHierarchy.jcds = response.jcds.slice(0)
                            vm.assignHierarchy.scenarios = response.scenarios.slice(0)
                            vm.enableScenarios = true;
                            vm.loading = false;
                        })
                        // vm.assignHierarchy.selectedJcd = null;
                        // vm.assignHierarchy.scenariosItem = _.find(TAX_YEARS, (function (item) {
                        //     return item.value === taxYear

                        // }))

                    }

                    vm.applicationChange = function () {
                        vm.loading = true
                        var appSelected = _.find(vm.assignHierarchy.jcds, function (item) {
                            return item.jcd_key === vm.assignHierarchy.selectedJcdkey
                        })
                        var getDefaultHierarchyPromise = BizzHierFactory.getDefaultHierarchy(appSelected.channel_key, vm.assignHierarchy.selectedTaxYear, vm.assignHierarchy.selectedScenario, vm.assignHierarchy.selectedJcdkey).then(function (response) {
                            return response
                        })

                        Promise.all([getDefaultHierarchyPromise]).then(function (response) {
                            var defaultHierarchyResponse = response;
                            BizzHierFactory.getHierachyList(vm.assignHierarchy.selectedTaxYear, vm.assignHierarchy.selectedScenario, vm.assignHierarchy.selectedJcdkey, appSelected.channel_key).then(function (response) {
                                vm.assignHierarchy.hierarchiesList = response
                                if (defaultHierarchyResponse[0].hierarchyId) {
                                    var defaultHierarchy = vm.assignHierarchy.hierarchiesList.find(function (item) {
                                        return item.hierarchy_id === defaultHierarchyResponse[0].hierarchyId
                                    })
                                    if (defaultHierarchy)
                                        vm.defaultHierarchyName = defaultHierarchy.hierarchy_name
                                    else
                                        vm.defaultHierarchyName = 'None'
                                }
                                console.log("List", response)
                                vm.enableNewHierachiesList = true
                                vm.loading = false
                            })
                        })


                    }

                    vm.selectNewHierarchy = function (newHierarchyId) {
                        var newHierarchy = vm.assignHierarchy.hierarchiesList.find(function (item) {
                            return item.hierarchy_id === newHierarchyId
                        })

                        vm.newHierarchyName = newHierarchy.hierarchy_name
                    }

                    vm.assignBizzHierarchy = function () {
                        BizzHierFactory.assignBizzHierarchy(vm.assignHierarchy.newHierarchyId).then(function (response) {
                            console.log(response)
                            if (response.callSuccess === "1") {
                                AlertService.add("success", "Hierarchy assigned successfully", 3000);
                                $uibModalInstance.dismiss('cancel');
                            }

                        })
                    }

                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                }],
                size: 'md',

            });
        }
        vm.openHierarchyExcelModel = function () {
            var modalObj = {};
            modalObj.template = "app/components/admin/bizzHier/templates/gold-add-hierarchy-excel-model.html";
            modalObj.size = "sm";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            $scope.openModal(modalObj);
        }
        vm.openHierarchyModel = function () {
            var modalObj = {};
            modalObj.template = "app/components/admin/bizzHier/templates/gold-add-hierarchy-model.html";
            // modalObj.controler = "goldSyncNewCtrl as ctrl";
            modalObj.size = "sm";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            $scope.openModal(modalObj);
        }

        vm.addRootNode = function () {
            var modalAddObj = {};
            modalAddObj.template = "app/components/admin/bizzHier/templates/gold-create-hierarchy-model.html";
            modalAddObj.controller = function ($scope, $uibModalInstance) {
                $scope.title = 'CREATE';
                $scope.actionBtnLabel = 'Create';
                $scope.visibleBH = false;
                $scope.lineOfBizz = vm.lineOfBizz;

                $scope.ok = function () {
                    $uibModalInstance.close({
                        "hierarchyName" : $scope.newHzchName, "buCode": $scope.buCode, "buDesc": $scope.buDesc, "line_of_business": $scope.selectedLOB, "detail_bsla_ind": $scope.detailBSLA, "estDataCollLvl": $scope.estDataCollection
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
               
                /**
                 * Check duplicate hierarchy name in vm.trees.
                 */
                if(vm.trees.right.selectedHierarchyId === null){
                    $scope.visibleBH = true;
                }
                $scope.checkDuplicateNames = function (newHierarchyName) {
                    if (vm.trees['left'].selectedAppCode === vm.trees['right'].selectedAppCode) {
                        $scope.showErrorDupicateForSource = vm.trees['left'].hierarchies.find(function (h) {
                            return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                        });
                    } else {
                        $scope.showErrorDupicateForDest = vm.trees['right'].hierarchies.find(function (h) {
                            return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                        });
                    }
                }


            }

            modalAddObj.size = "sm";
            $scope.openAddModal(modalAddObj);

        }

        $scope.openModal = function (modalObj) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: ['$scope', 'BizzHierFactory', '$uibModalInstance', 'inputData', function ($scope, BizzHierFactory, $uibModalInstance, inputData) {
                    $scope.trees = {
                        left: {
                            hierarchies: [],
                            loaded: true
                        },
                        right: {
                            hierarchies: [],
                            loaded: true
                        }
                    }

                    $scope.inputData = inputData;
                    $scope.creating = true;
                    $scope.enableMe = true;

                    $scope.createhierarchy = function () {
                        $scope.creating = true;
                        var srcHierarchyId = $scope.srcHierarchyId === undefined ? '' : $scope.srcHierarchyId;
                        if( $scope.srcHierarchyId !== ''){
                            $scope.enableMe = false;
                        }
                        $scope.createBtnText = 'Creating...';
                        BizzHierFactory.createHierarchy(
                            srcHierarchyId,
                            $scope.hierarchyName,
                            $scope.descAppCode,
                            inputData.selectedTaxYear,
                            inputData.selectedScenario,
                            $scope.descAppSelected.jcd_key).then(function (response) {
                                $scope.createBtnText = 'Create';
                                $scope.creating = false;
                                console.log(response);
                                if (response.p_retval == "1") {
                                    AlertService.add("success", "Hierarchy created successfully", 3000);
                                    $uibModalInstance.close();
                                } else {
                                    AlertService.add("error", "Hierarchy creation failed", 3000);
                                }
                            })
                    }

                    $scope.createHierarchyWithExcel = function () {
                        $scope.creating = true;
                        $scope.createBtnText = 'Creating...';
                        var file = $scope.excelFile;
                        
                        var ckheader = $scope.ckHeader;
                        
                        console.log(ckheader);

                        BizzHierFactory.uploadTemplate(file.name, file).then(function (response) {
                            if (response.data.callSuccess === '1') {
                                var encodedPath = response.data.file.replace(/\\/g, '%5C');
                                BizzHierFactory.uploadExcelBizzHierarchy(
                                    encodedPath,
                                    inputData.selectedTaxYear,
                                    inputData.selectedScenario,
                                    $scope.descAppSelected.jcd_key,
                                    $scope.newHierarchyName,
                                    $scope.descAppSelected.id,
                                    ckheader).then(function (response) {
                                        $scope.createBtnText = 'Create';
                                        $scope.creating = false;
                                        if (response.data.callSuccess === '1') {
                                            AlertService.add("success", "Hierarchy created successfully", 3000);
                                            $uibModalInstance.close();
                                        } else {
                                            AlertService.add("error", "Error occurred during inserting data into DB! Cause: " + response.data.errorMessage, 3000);
                                        }
                                    });
                            } else {
                                $scope.createBtnText = 'Create';
                                $scope.creating = false;
                                AlertService.add("error", "Error during uploading the file! Cause: " + response.data.message, 3000);
                            }
                        });
                    }

                    $scope.loadHierarchies = function (treeType, appCode) {
                        $scope.treeLoading = true;
                        var appSelected;
                        var appSelected = inputData.scrApps.find(function (item) {
                            return item.id === appCode
                        })
                        $scope.descAppSelected = inputData.descApps.find(function (item) {
                            return item.id === $scope.descAppCode
                        })
                        BizzHierFactory.getHierachyList(inputData.selectedTaxYear, inputData.selectedScenario, appSelected.jcd_key, appCode).then(function (response) {
                            $scope.treeLoading = false;
                            $scope.trees[treeType].hierarchies = response;
                            $scope.trees[treeType].loaded = false
                            if (treeType !== 'right') {
                                if (response.length == 0) {

                                    AlertService.add('warning', "No Hierarchies available.", 4000);
                                } else if (response.length == 1) {
                                    $scope.srcHierarchyId = response[0].hierarchy_id;
                                }
                                // if(!vm.trees[treeType].selectedHierarchyId)
                                //     vm.trees[treeType].selectedHierarchyId = vm.hierarchyList[0].hierarchy_id;

                                // vm.loadTree(vm.trees[treeType].selectedHierarchyId,treeType);//Call immediately on load
                            }
                            // also check hierarchy name duplicates here
                            if ($scope.newHierarchyName) {
                                $scope.checkDuplicateNamesExcel($scope.newHierarchyName);
                            }
                        })
                    }

                    /**
                     * Check duplicate hierarchy name in $scope destination application tree
                     */
                    $scope.checkDuplicateNamesExcel = function (newHierarchyName) {
                        // temp fix for empty string response received from getHierachyList()
                        if ($scope.trees['right'].hierarchies === '') {
                            $scope.showErrorDupicateForDest = false;
                        } else {
                            $scope.showErrorDupicateForDest = $scope.trees['right'].hierarchies.find(function (h) {
                                return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                            });
                        }
                    }

                    $scope.isRequiredFields = function () {
                        $scope.showErrorDupicateForSource = false
                        $scope.showErrorDupicateForDest = false
                        // $scope.isHierarchyPresentInSource = []
                        // $scope.isHierarchyPresentInDest = []
                        if ($scope.srcAppCode === $scope.descAppCode) {
                            $scope.isHierarchyPresentInSource = $scope.trees['left'].hierarchies.filter(function (item) {
                                return item.hierarchy_name === $scope.hierarchyName
                            });

                            if ($scope.isHierarchyPresentInSource.length > 0) {
                                $scope.creating = true;
                                $scope.showErrorDupicateForSource = true
                                $scope.showErrorDupicateForDest = false
                            }
                        } else {
                            $scope.isHierarchyPresentInDest = $scope.trees['right'].hierarchies.filter(function (item) {
                                return item.hierarchy_name === $scope.hierarchyName
                            });
                            if ($scope.isHierarchyPresentInDest.length > 0) {
                                $scope.creating = true;
                                $scope.showErrorDupicateForSource = false
                                $scope.showErrorDupicateForDest = true
                            }
                        }
                        if ($scope.isHierarchyPresentInDest) {
                            if ($scope.isHierarchyPresentInDest.length === 0) {
                                $scope.creating = false
                            }
                        } else if ($scope.isHierarchyPresentInSource) {
                            if ($scope.isHierarchyPresentInSource.length === 0) {
                                $scope.creating = false
                            }
                        }
                    }

                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                }],
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    inputData: function () {
                        console.log("Inpu.t Apps", vm.trees.left.apps)
                        return {
                            selectedTaxYear: vm.selectedTaxYear,
                            selectedScenario: vm.selectedScenario,
                            selectedJcd: vm.trees.right.selectedJcdkey,
                            selectedAppCode: vm.trees.right.selectedAppCode,
                            srcHierarchyId: vm.goldHierarchyId,//always create from
                            scrApps: vm.trees.left.apps,
                            descApps: vm.trees.right.apps
                        };
                    }
                }
            });

            modalInstance.result.then(function (newHierarchy) {
                vm.loadHierarchies('right');
            }, function () {

            });
        };
        function findMovedNodes(rightNodes, flattenedLeftNodes, movedNodes, hasChildren) {
            for (var i = 0; i < rightNodes.length; i++) {
                if (flattenedLeftNodes[rightNodes[i].meCode]) {
                    if (hasChildren || rightNodes[i].parentMeCode !== flattenedLeftNodes[rightNodes[i].meCode].parentMeCode) {
                        movedNodes.push(rightNodes[i].meCode)
                        if (rightNodes[i].children.length > 0) {
                            findMovedNodes(rightNodes[i].children, flattenedLeftNodes, movedNodes , true)
                        }

                    }
                    else {
                        if (rightNodes[i].children.length > 0) {
                            findMovedNodes(rightNodes[i].children, flattenedLeftNodes, movedNodes , false)
                        }

                    }
                }
            }
        }
        vm.compareHierarchies = function () {
            vm.treeCompared = true;
            vm.movedNodes = []
            vm.onlyInGold = _.difference(Object.keys(flattened[vm.trees.left.selectedHierarchyId]), Object.keys(flattened[vm.trees.right.selectedHierarchyId]));
            vm.onlyInTax = _.difference(Object.keys(flattened[vm.trees.right.selectedHierarchyId]), Object.keys(flattened[vm.trees.left.selectedHierarchyId]));
            vm.edited = _.differenceBy(Object.values(flattened[vm.trees.right.selectedHierarchyId]), Object.values(flattened[vm.trees.left.selectedHierarchyId]), 'meName').map(function (item) {
                return item.meCode;
            });

            findMovedNodes(vm.trees.right.nodes, flattened[vm.trees.left.selectedHierarchyId], vm.movedNodes , false)
            // vm.movedNodes = vm.movedNodes.map(function (item, index) {
            //     return item.meCode
            // })
            console.table(vm.movedNodes)
            //To remove the new nodes created from edited array
            vm.edited = _.difference(vm.edited, vm.onlyInTax);

            // vm.moved = _.differenceBy(Object.values(flattened[vm.hierarchyType]), Object.values(flattened["GOLD"]),'parentbucode').map(function(item) {
            //     return item.bucode;
            // });
            console.log(vm.edited);
            highlightHierarchy(vm.trees.left.nodes, vm.onlyInGold, "absentInRightTree");
            highlightHierarchy(vm.trees.right.nodes, vm.onlyInTax, "absentInLeftTree");
            // highlightHierarchy(vm.nodes["GOLD"], vm.edited);
            highlightHierarchy(vm.trees.right.nodes, vm.edited, "nodeEdited");
            highlightHierarchy(vm.trees.right.nodes, vm.movedNodes, "nodeMoved")
            // highlightHierarchy(vm.nodes["TAX"], vm.moved);
        }

        function highlightHierarchy(tree, diff, flag) {
            for (var i = 0; i < tree.length; i++) {
                tree[i][flag] = false;
                if (diff.includes(tree[i].meCode)) {
                    tree[i][flag] = true;
                }
                // else{
                //     tree[i].absentInOtherTree = false;
                // }
                highlightHierarchy(tree[i].children, diff, flag);

            }
        }

        function refreshHighlightHierarchy(nodes, flag) {

            for (var i = 0; i < nodes.length; i++) {
                nodes[i][flag] = false;
                if (nodes[i].children) {
                    if (nodes[i].children.length > 0) {
                        refreshHighlightHierarchy(nodes[i].children, flag)
                    }
                }
            }

        }

        vm.flattenHierarchy = function (arr, type) {
            for (var i = 0; i < arr.length; i++) {
                if (!arr[i].children) {
                    arr[i].children = [];
                }
                addOrUpdateNodeToFlattenedStore(type, arr[i]);

                vm.flattenHierarchy(arr[i].children, type);
            }


            // return flattened
        }

        function addOrUpdateNodeToFlattenedStore(type, node) {
            //             children: [{…}]
            // groupObjKey: 2018135
            // hierarchyId: 4
            // level: 1
            // meCode: "AP0000"
            // meKey: 808
            // meName: "Appliances"
            // meParentKey: null
            // path: "/808"
            // taxYear: 2018
            if (flattened[type][node.meCode]) {
                console.log("Node Updated to :", node);
            } else {
                console.log("New Node Added :", node);
            }
            flattened[type][node.meCode] = {
                "meParentKey": node.meParentKey,
                "meCode": node.meCode,
                "meKey": node.meKey,
                "meName": node.meName,
                "path": node.path,
                "level": node.level,
                "hierarchyId": node.hierarchyId,
                "groupObjKey": node.groupObjKey,
                "taxYear": node.taxYear,
                "parentMeCode": node.parentMeCode,
                "line_of_business": node.line_of_business,
                "detail_bsla_ind": node.detail_bsla_ind
            };
            if (node.children)
                if (node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        addOrUpdateNodeToFlattenedStore(type, node.children[i]);
                    }
                }

        }


        vm.sync = function () {
            vm.syncing = true;
            vm.treeLoading = true
            var goldJcd = vm.trees.left.apps.find(function (item) {
                return item.label.toLowerCase() === 'gold'
            })
            BizzHierFactory.syncGoldBusinessTree(vm.selectedTaxYear, vm.selectedScenario, goldJcd.jcd_key).then(function (response) {
                vm.syncing = false;
                vm.treeLoading = false;
                // var goldJcd = vm.trees.left.apps.find(function (item) {
                //     return item.label.toLowerCase() === 'gold'
                // })
                vm.trees.left.selectedAppCode = goldJcd.id
                if (response.callSuccess === "1" && vm.trees.left.selectedAppCode)
                    vm.loadHierarchies('left');

            })
        }
        vm.loadHierarchies = function (treeType) {
            vm.isAppSelected = true;
            vm.treeLoading = true;
            vm.trees[treeType].nodes = []

            console.log("Enter Load Hierarchies Function", treeType)
            if (vm.trees[treeType].selectedAppCode) {
                var jcdSelected = _.find(vm.trees[treeType].apps, function (item) {
                    return item.id === vm.trees[treeType].selectedAppCode
                })
                vm.trees[treeType].selectedJcdkey = jcdSelected.jcd_key

                BizzHierFactory.getHierachyList(vm.selectedTaxYear, vm.selectedScenario, vm.trees[treeType].selectedJcdkey, vm.trees[treeType].selectedAppCode).then(function (response) {
                    console.log("Enter Load Hierarchies Promise", treeType)
                    vm.treeLoading = false;
                    var hierarchiesList = response === "" ? null : angular.copy(response)
                    if (hierarchiesList) {
                        vm.trees[treeType].hierarchies = angular.copy(hierarchiesList);
                        vm.trees[treeType].masterHierarchies = angular.copy(hierarchiesList);
                    }

                    var otherTreeType = treeType === 'left' ? 'right' : 'left'
                    removeSameHierarchy(treeType, otherTreeType)

                    if (response.length > 0) {


                        var goldHierarchy = response.find(function (item) {
                            return item.app_code === vm.goldAppCode
                        });
                        if (goldHierarchy) {
                            vm.goldHierarchyId = goldHierarchy.hierarchy_id;
                        }

                        if (response.length == 1 && !vm.trees[treeType].hierarchies[0].existsInOtherTree) {
                            console.log("Hierarchy Response Length 1", treeType)
                            vm.trees[treeType].selectedHierarchyId = response[0].hierarchy_id
                            vm.loadTree(treeType)
                        } else {
                            // if(!vm.trees[treeType].selectedHierarchyId)
                            //     vm.trees[treeType].selectedHierarchyId = vm.hierarchyList[0].hierarchy_id;

                            // vm.loadTree(vm.trees[treeType].selectedHierarchyId,treeType);//Call immediately on load
                        }
                    } else if (response.length === 0) {
                        // vm.trees[treeType].hierarchies = null;
                        // vm.trees[treeType].masterHierarchies = null;
                        console.log("Hierarchy Response Length 0", treeType)
                        AlertService.add('warning', "No Hierarchies available.", 4000);
                        return
                    }
                })

            } else {
                vm.treeLoading = false;
            }
        }

        // function loadApps() {
        //     vm.treeLoading = true;
        //     BizzHierFactory.getAllApps().then(function (response) {
        //         vm.treeLoading = false;
        //         vm.trees.right.apps = response.filter(function (item) {
        //             return item.app_code !== vm.goldAppCode//TODO: add a system/src flag instead of hard coding one source i.e, gold
        //         });
        //         vm.trees.left.apps = response;
        //         if (response.length > 0) {
        //             if (!vm.trees.left.selectedAppCode) {
        //                 var gold = vm.trees.left.apps.find(function (item) {
        //                     return item.app_code === vm.goldAppCode
        //                 })
        //                 if (gold) {
        //                     vm.trees.left.selectedAppCode = gold.app_code;
        //                     vm.loadHierarchies('left');

        //                 }
        //             }
        //             if (!vm.trees.right.selectedAppCode) {
        //                 vm.trees.right.selectedAppCode = vm.trees.right.apps[0].app_code;
        //                 vm.loadHierarchies('right');
        //             }

        //         } else {
        //             AlertService.add('warning', "No Appications available.", 4000);
        //         }
        //     })
        // }

        vm.loadTree = function (treeType) {
            var otherTreeType = treeType === 'left' ? 'right' : 'left'
            removeSameHierarchy(otherTreeType, treeType)
            if (vm.trees[treeType].hierarchies) {

                flattened[vm.trees[treeType].selectedHierarchyId] = {};
                if (vm.trees.right.nodes.length > 0 && vm.trees.left.nodes.length > 0) {
                    for (var i = 0; i < vm.trees.left.nodes.length; i++) {
                        refreshPresentInRightTree(vm.trees.left.nodes[i])
                    }
                }
                refreshHighlightHierarchy(vm.trees.left.nodes, "absentInRightTree");
                refreshHighlightHierarchy(vm.trees.right.nodes, "absentInLeftTree");
                // refreshHighlightHierarchy(vm.nodes["GOLD"], vm.edited);
                refreshHighlightHierarchy(vm.trees.right.nodes, "nodeEdited");
                refreshHighlightHierarchy(vm.trees.left.nodes, "nodeMoved");
                // var otherTreeType = treeType === 'right' ? 'left' : 'right'
                // if (vm.trees[treeType].selectedAppCode === vm.trees[otherTreeType].selectedAppCode) {
                //     vm.trees[treeType].hierarchies = vm.trees[treeType].masterHierarchies.filter(function (item) {
                //         return item.hierarchy_id !== vm.trees[otherTreeType].selectedHierarchyId
                //     })
                // }
                // else {
                //     vm.trees[treeType].hierarchies = vm.trees[treeType].masterHierarchies.slice(0)
                // }
                console.log("Enter Load Tree Function", treeType)
                // if (vm.nodes["GOLD"]) {
                //     highlightHierarchy(vm.nodes["GOLD"], [], "absentInOtherTree");
                vm.treeCompared = false;
                // }

                if (typeof vm.trees[treeType].selectedAppCode === "undefined" || typeof vm.trees[treeType].selectedHierarchyId === "undefined") {
                    return;
                }
                // if (hierarchyId !== goldHierarchyId) {
                //     type = "TAX";
                //     if (typeof vm.hierarchyType === "undefined") {
                //         return;
                //     }
                // } else {
                //     vm.loadHierarchies();
                // }
                vm.trees[treeType].loading = true;
                vm.trees[treeType].nodes.length = 0;
                // var actualHierarchyId = hierarchyId === goldHierarchyId ? goldHierarchyId : vm.hierarchyId;
                if (vm.trees[treeType].selectedHierarchyId) {
                    BizzHierFactory.getBizzHierarchyTree(vm.trees[treeType].selectedHierarchyId.toString(), vm.selectedTaxYear, parseInt(vm.selectedScenario), parseInt(vm.trees[treeType].selectedJcdkey)).then(function (response) {
                        console.log("Enter getBizzHierarchyTree Promise", treeType)
                        if (response.length > 0) {
                            vm.trees[treeType].nodes = response;

                            // if (type === "GOLD")
                            //     vm.lastSyncedDate = response[0].dateTime;
                            // addEmptyChildrenArrays(vm.nodes[type]);//temporary until be is implemented
                            // flattened[vm.trees[treeType].selectedHierarchyId] = {};
                            vm.flattenHierarchy(vm.trees[treeType].nodes, vm.trees[treeType].selectedHierarchyId);

                            //  flattened = flattened.concat(vm.nodes[type]);
                            //  window.flattenHierarchy = flattened;
                            console.log("flattened Hierarchy", flattened);
                            vm.trees[treeType].currentAlertMessage = "";

                        } else if (response.length === 0)
                            vm.trees[treeType].currentAlertMessage = "There is no data available";
                        else
                            AlertService.add("error", "Error while fetching data");


                        vm.trees[treeType].loading = false;
                        if (vm.trees.right.nodes.length > 0 && vm.trees.left.nodes.length > 0) {
                            for (var i = 0; i < vm.trees.left.nodes.length; i++) {
                                presentInrightTree(vm.trees.left.nodes[i])
                            }
                        }

                        // console.log("Node present", vm.nodes['GOLD'])
                        // vm.alertMessages["TAX"] = "There are no data available";
                        // vm.nodes2 = angular.copy(vm.nodes);
                    })
                } else {
                    vm.trees[treeType].loading = false;
                }

            }
        }

        //INIT
        // loadApps();


        // function addEmptyChildrenArrays(tree) {
        //     for (var i = 0; i < tree.length; i++) {
        //         if (!tree[i].children) {
        //             tree[i].children = [];
        //         } else {
        //             addEmptyChildrenArrays(tree[i].children);
        //         }


        //     }

        vm.addBHModal = function (node, nodeScope) {
            var modalAddObj = {};
            modalAddObj.template = "app/components/admin/bizzHier/templates/gold-create-hierarchy-model.html";
            modalAddObj.controller = function ($scope, $uibModalInstance) {
                $scope.title = 'CREATE';
                $scope.actionBtnLabel = 'Create';
                $scope.lineOfBizz = vm.lineOfBizz;

                $scope.ok = function () {
                    $uibModalInstance.close({
                        "buCode": $scope.buCode, "buDesc": $scope.buDesc, "line_of_business": $scope.selectedLOB, "detail_bsla_ind": $scope.detailBSLA, "estDataCollLvl": $scope.estDataCollection
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };

                
            }

            

            modalAddObj.size = "sm";
            $scope.openAddModal(modalAddObj, node);
        }

        $scope.openAddModal = function (modalAddObj, node) {
            console.log(vm.trees)
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalAddObj.template,
                controller: ['$scope', '$uibModalInstance',modalAddObj.controller],
                size: modalAddObj.size,
            });

            modalInstance.closed.then(function (result) {
                vm.treeLoading = false
            })
            modalInstance.result.then(function (result) {
                // console.log("Add : " + result.buCode + ": " + result.buDesc + ": " + node);
                vm.treeLoading = true;
                var parentMeKey = node === undefined ? '' : node.meKey;
                var parentHierarchyId = vm.trees.right.selectedHierarchyId;

                if(parentHierarchyId === null){
                    BizzHierFactory.createHierarchy(
                        '',
                        result.hierarchyName,
                        vm.trees.right.selectedAppCode,
                        vm.selectedTaxYear,
                        vm.selectedScenario,
                        vm.trees.right.selectedJcdkey).then(function (res) {
                            console.log(res);
                            if (res.p_retval == "1") {
                                AlertService.add("success", "Hierarchy created successfully", 3000);

                                BizzHierFactory.addBusinessUnit(
                                        result.buCode,
                                        result.buDesc,
                                        vm.selectedScenario,
                                        parentMeKey,
                                        vm.selectedTaxYear,
                                        vm.trees.right.selectedJcdkey,
                                        res.p_hierarchyId,
                                        result.line_of_business,
                                        result.detail_bsla_ind,
                                        result.estDataCollLvl
                                    ).then(function (response) {
                                        console.log(response);
                                        var newNode = {
                                            meKey: response.p_me_key,
                                            meName: result.buDesc,
                                            meParentKey: parentMeKey,
                                            meCode: result.buCode,
                                            hierarchyId: parentHierarchyId,
                                            parentMeCode: node === undefined ? null : node.meCode,
                                            children: [],
                                            groupObjKey: parseInt(response.p_group_obj_key),
                                            line_of_business: result.line_of_business,
                                            detail_bsla_ind: result.detail_bsla_ind,
                                            estDataCollLvl: result.estDataCollLvl
                                        };
                                        if (node) {
                                            node.children.push(newNode);
                                        } else {
                                            //vm.trees.right.nodes.unshift(newNode);
                                            vm.loadHierarchies('right');
                                            vm.trees.right.selectedHierarchyId = parseInt(res.p_hierarchyId);
                                            vm.loadTree('right');
                                        }
                    
                    
                                        addOrUpdateNodeToFlattenedStore(parentHierarchyId, newNode);
                                        vm.treeLoading = false;
                                        //nodeScope.editing = false;
                                        AlertService.add("success", "Created Business Unit Successfully", 3000);
                                        $uibModalInstance.close();
                                    });
                                
                            } else {
                                AlertService.add("error", "Hierarchy creation failed", 3000);
                            }
                        })
                }else{
                    BizzHierFactory.addBusinessUnit(
                        result.buCode,
                        result.buDesc,
                        vm.selectedScenario,
                        parentMeKey,
                        vm.selectedTaxYear,
                        vm.trees.right.selectedJcdkey,
                        parentHierarchyId,
                        result.line_of_business,
                        result.detail_bsla_ind,
                        result.estDataCollLvl
                    ).then(function (response) {
                        console.log(response);
                        var newNode = {
                            meKey: response.p_me_key,
                            meName: result.buDesc,
                            meParentKey: parentMeKey,
                            meCode: result.buCode,
                            hierarchyId: parentHierarchyId,
                            parentMeCode: node === undefined ? null : node.meCode,
                            children: [],
                            groupObjKey: parseInt(response.p_group_obj_key),
                            line_of_business: result.line_of_business,
                            detail_bsla_ind: result.detail_bsla_ind
                        };
                        if (node) {
                            node.children.push(newNode);
                        } else {
                            vm.trees.right.nodes.unshift(newNode);
                        }
    
    
                        addOrUpdateNodeToFlattenedStore(parentHierarchyId, newNode);
                        vm.treeLoading = false;
                        //nodeScope.editing = false;
                        AlertService.add("success", "Created Business Unit Successfully", 3000);
                    });
                }
            
            }, function () {

            });
        };

        vm.editNode = function (node) {
            var modalAddObj = {};
            modalAddObj.template = "app/components/admin/bizzHier/templates/gold-create-hierarchy-model.html";
            modalAddObj.controller = function ($scope, $uibModalInstance) {
                $scope.title = 'EDIT';
                $scope.actionBtnLabel = 'Save';
                $scope.lineOfBizz = vm.lineOfBizz;
                $scope.buCode = node.meCode;
                $scope.buDesc = node.meName;
                $scope.selectedLOB = node.line_of_business;
                $scope.detailBSLA = node.detail_bsla_ind;
                $scope.estDataCollection = node.EST_Data_coll_level;
                $scope.scenario_dec = GlobalService.inputs.tax_year.selected.scenario_desc;
                $scope.level = node.level > 2 ? true : false;
                $scope.display_detailBSLA = node.children.length > 0 ? false : true;

                $scope.ok = function () {
                    $uibModalInstance.close({
                        "buCode": $scope.buCode, "buDesc": $scope.buDesc, "line_of_business": $scope.selectedLOB, "detail_bsla_ind": $scope.detailBSLA, "estDataCollLvl": $scope.estDataCollection
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }

            modalAddObj.size = "sm";
            // $scope.openAddModal(modalAddObj, node);

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalAddObj.template,
                controller: ['$scope', '$uibModalInstance',modalAddObj.controller],
                size: modalAddObj.size
            });

            modalInstance.closed.then(function (result) {
                vm.treeLoading = false;
            });

            modalInstance.result.then(function (result) {
                if (result) {
                    vm.saveEditBH(node, result);
                }
            });
        }

        vm.saveEditBH = function (node, result) {
            //alert("save");
            vm.treeLoading = true;
            BizzHierFactory.editBusinessUnit(
                node.meKey,
                node.groupObjKey,
                result.buDesc,
                result.buCode,
                result.line_of_business,
                result.detail_bsla_ind,
                node.hierarchyId,
                node.hierarchyId,
                node.taxYear,
                result.estDataCollLvl,
                GlobalService.globalParams.jcd_key,
                GlobalService.globalParams.scenario  
            ).then(function (response) {
                // nodeScope.editing = false;
                if (response.result.value === '1') {
                    node.meName = result.buDesc;
                    node.meCode = result.buCode;
                    node.line_of_business = result.line_of_business;
                    node.detail_bsla_ind = result.detail_bsla_ind;
                    node.EST_Data_coll_level = result.EST_Data_coll_level;
                    addOrUpdateNodeToFlattenedStore(node.hierarchyId, node);
                    AlertService.add("success", "Saved Business Unit Successfully", 3000);
                } else {
                    AlertService.add("error", "Something went wrong, please try again!");
                }
            }).finally(() => {
                vm.treeLoading = false;
            })
        }
        vm.deleteBH = function (node, nodeScope) {
            vm.treeLoading = true;
            BizzHierFactory.deleteBusinessUnit(
                node.meKey,
                node.meParentKey ? node.meParentKey : '',
                node.groupObjKey,
                node.hierarchyId,
                GlobalService.globalParams.tax_year,
                GlobalService.globalParams.jcd_key,
                GlobalService.globalParams.scenario).then(function (response) {
                    if (response.value === "2") {
                        vm.treeLoading = false;
                        AlertService.add("warning", "Cannot delete business as it is used in code combination.", 3000);
                    } else {
                        vm.treeLoading = false;
                        console.log(response);
                        deleteNodes(node);
                        var deletedMeCodes = [];
                        deletedMeCodes.push(node.meCode);
                        getAllMeCodesFromNode(node.children, deletedMeCodes);
                        showAutoPushIcons(deletedMeCodes, vm.trees.left.nodes);
                        //
                        nodeScope.remove();

                        console.log("Deleted node : ", node);
                        if (response.value === "1")
                            AlertService.add("success", "Deleted Business Unit Successfully", 3000);
                    }
                })

        }

        function getAllMeCodesFromNode(nodes, deletedMeCodes) {
            for (var i = 0; i < nodes.length; i++) {
                deletedMeCodes.push(nodes[i].meCode);

                if (nodes[i].children.length > 0) {
                    getAllMeCodesFromNode(nodes[i].children, deletedMeCodes);
                }
            }
        }

        function showAutoPushIcons(meCodes, leftNodes) {
            for (var i = 0; i < leftNodes.length; i++) {
                if (meCodes.includes(leftNodes[i].meCode)) {
                    leftNodes[i].presentInrightTree = !leftNodes[i].presentInrightTree
                    // return true
                }
                if (leftNodes[i].children) {
                    if (leftNodes[i].children.length > 0) {
                        showAutoPushIcons(meCodes, leftNodes[i].children)
                    }
                }
            }
        }

        function deleteNodes(node) {
            delete flattened[vm.trees.right.selectedHierarchyId][node.meCode];

            if (node.children.length > 0) {
                for (var i = 0; i < node.children.length; i++) {
                    deleteNodes(node.children[i]);
                }
            }
        }

        function switchType(node, hierarchyId) {
            node.hierarchyId = hierarchyId;

            if (node.children.length > 0) {
                for (var i = 0; i < node.children.length; i++) {
                    switchType(node.children[i], hierarchyId);
                }
            }
        }

        $scope.openModalBH = function (modalObj, _data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });


            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });
        };

        // vm.deleteBusinessHierarchy = function (type) {
        //     GoldSyncNewFactory.deleteBusinessHierarchy(vm.selectedClient.client_key, type === "GOLD" ? type : vm.hierarchyType, vm.selectedTaxYear).then(function (response) {
        //         if (response.length > 0) {
        //             vm.nodes[type] = response;
        //             vm.alertMessages[type] = "";

        //         }
        //         else
        //             vm.alertMessages[type] = "Some Thing went wrong to deleted";

        //     })
        // }

        function refreshPresentInRightTree(node) {
            if (!flattened[vm.trees.right.selectedHierarchyId][node.meCode]) {
                node.presentInrightTree = false

            }
            if (node.children) {
                if (node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        refreshPresentInRightTree(node.children[i])
                    }

                }
            }

        }

        function presentInrightTree(node) {
            if (!flattened[vm.trees.right.selectedHierarchyId][node.meCode]) {
                node.presentInrightTree = !node.presentInrightTree

            }
            if (node.children) {
                if (node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        presentInrightTree(node.children[i])
                    }

                }
            }

        }


        vm.moveNode = function (node, nodeScope) {
            vm.showInBeginning = true;
            vm.moveIcon = true;
            console.log(flattened);
            node.nodeToMove = true;
            node.isSelected = true;
            node.highlightNode = true;
            toggleMoveIcons(node, vm.trees.right.nodes);
            toggleMoveIconsChildren(node);

            var nodeScopeSafe = Object.create(nodeScope);
            vm.dropNode = createDropNode(node, nodeScopeSafe);
            // vm.cancelNodeMove = cancelNodeMove(node, nodeScopeSafe)
            vm.makeRootNode = rootNodeWrapper(node, nodeScopeSafe);
            // vm.autoPush = autoPushWrapper(node, nodeScopeSafe)
        }

        function toggleMoveIconsChildren(srcNode) {
            for (var i = 0; i < srcNode.children.length; i++) {
                srcNode.children[i].nodeToMove = !srcNode.children[i].nodeToMove
                srcNode.children[i].highlightNode = !srcNode.children[i].highlightNode
                toggleMoveIconsChildren(srcNode.children[i])
            }
        }

        function toggleMoveIcons(srcNode, nodes) {


            for (var i = 0; i < nodes.length; i++) {
                if (srcNode.meParentKey === nodes[i].meKey) {
                    nodes[i].nodeToMove = !nodes[i].nodeToMove
                    return true
                    // toggleMoveIcons(srcNode , nodes[i].children)
                } else {
                    toggleMoveIcons(srcNode, nodes[i].children)
                }
            }


        }

        function removeSameHierarchy(selectedTreeType, otherTreeType) {
            if (vm.trees[selectedTreeType].masterHierarchies.length > 0) {
                // var otherTreeType = selectedTreeType === 'left' ? 'right' : 'left'
                if (vm.trees[selectedTreeType].selectedAppCode === vm.trees[otherTreeType].selectedAppCode) {
                    if (vm.trees[otherTreeType].selectedHierarchyId) {
                        vm.trees[selectedTreeType].hierarchies.map(function (item) {
                            if (item.hierarchy_id === vm.trees[otherTreeType].selectedHierarchyId) {
                                item.existsInOtherTree = true
                            }
                            return item
                        })
                    }
                } else {
                    vm.trees[selectedTreeType].hierarchies = angular.copy(vm.trees[selectedTreeType].masterHierarchies)
                }
            }
            // else{

            // }

        }

        function checkForParent(srcNodeParent, flattened) {
            var parentItem = _.filter(flattened, function (item) {
                return item.meCode === srcNodeParent.meCode
            })
            if (parentItem) {
                return true
            } else {
                return false
            }
        }


        vm.autoPush = function (srcNode, srcNodeScope, hierarchyId) {
            var srcNodeSafeCopy = angular.copy(srcNode)
            srcNodeSafeCopy.hierarchyId = vm.trees.right.selectedHierarchyId
            var selectedHierarchy = vm.trees.right.hierarchies.find(function (item) {
                return item.hierarchy_id === vm.trees.right.selectedHierarchyId
            })

            if (flattened[hierarchyId][srcNodeScope.$modelValue.meCode]) {
                AlertService.add('warning', "This BU already exists in '" + vm.trees.right.selectedHierarchyId + "'", 7000)
                return true
            }
            if (srcNodeSafeCopy.meParentKey !== null) {
                findImmediateParent(srcNodeSafeCopy, vm.trees.right.nodes)
                if (!vm.immediateParent) {
                    AlertService.add('warning', "This BU does not have a parent in '" + selectedHierarchy.hierarchy_name + "' Please add its parent first.", 7000)
                    return true
                } else {
                    srcNodeSafeCopy.meParentKey = vm.immediateParent.meKey
                    refreshHighlightHierarchy([srcNodeSafeCopy], 'absentInRightTree')
                    // refreshHighlightHierarchy([srcNode] , 'absentInRightTree')
                    vm.immediateParent.children.push(srcNodeSafeCopy)
                }
            } else {
                vm.trees.right.nodes.push(srcNodeSafeCopy)
                refreshHighlightHierarchy(vm.trees.right.nodes, 'absentInRightTree')
            }

            presentInrightTree(srcNode)
            addOrUpdateNodeToFlattenedStore(srcNodeSafeCopy.hierarchyId, srcNode);

            BizzHierFactory.moveBusinessHierarchy(
                srcNodeSafeCopy.meKey,
                srcNodeSafeCopy.meParentKey ? srcNodeSafeCopy.meParentKey : '',
                vm.trees.left.selectedHierarchyId,
                vm.trees.right.selectedHierarchyId
            ).then(function (response) {
                console.log(response)
                srcNodeSafeCopy.parentMeCode = vm.immediateParent.meCode
                // srcNodeSafeCopy.nodeMoved = true
                // if(vm.immediateParent)
                // refreshHighlightHierarchy(vm.immediateParent , 'absentInLeftTree')
                if (vm.treeCompared)
                    vm.compareHierarchies();
                AlertService.add("success", "Moved Business Unit Successfully", 3000);
            })
            // GoldSyncNewFactory.addBusinessUnit(
            //     srcNodeSafeCopy.meCode,
            //     srcNodeSafeCopy.meName,
            //     vm.selectedScenario,
            //     immediateParent ? immediateParent.meKey : '',
            //     vm.selectedTaxYear,
            //     vm.selectedJcd,
            //     srcNodeSafeCopy.hierarchyId
            // ).then(function (response) {
            //     console.log(response)
            //     AlertService.add("success", "Moved Business Unit Successfully", 3000);
            // })
            // if (srcNodeSafeCopy.meParentKey !== null) {
            //     var immediateParent = findImmediateParent(srcNodeSafeCopy, vm.trees.right.nodes)
            //     if (immediateParent) {
            //         if (!flattened[hierarchyId][srcNodeScope.$modelValue.meCode]) {
            //             srcNodeSafeCopy.meParentKey = immediateParent.meKey
            //             addOrUpdateNodeToFlattenedStore(srcNodeSafeCopy.hierarchyId, srcNode)
            //             immediateParent.children.push(srcNodeSafeCopy)
            //             presentInrightTree(srcNode)

            //             GoldSyncNewFactory.addBusinessUnit(
            //                 srcNodeSafeCopy.meCode,
            //                 srcNodeSafeCopy.meName,
            //                 vm.selectedScenario,
            //                 immediateParent.meKey,
            //                 vm.selectedTaxYear,
            //                 vm.selectedJcd,
            //                 srcNodeSafeCopy.hierarchyId
            //             ).then(function (response) {
            //                 console.log(response)
            //                 AlertService.add("success", "Moved Business Unit Successfully", 3000);
            //             })

            //         }
            //         else {
            //             AlertService.add('warning', "This BU already exists in '" + vm.trees.right.selectedHierarchyId + "'", 7000)
            //         }
            //     }

            //     else {
            //         AlertService.add('warning', "This BU does not have a parent in '" + vm.trees.right.selectedHierarchyId + "' Please add its parent first.", 7000)
            //     }
            // }

            vm.cancelNodeMove()
        }


        function findImmediateParent(srcNode, rightTreeNodes) {
            for (var i = 0; i < rightTreeNodes.length; i++) {
                if (srcNode.meParentKey === rightTreeNodes[i].meKey) {
                    // rightTreeNodes[i].children.push(srcNode)
                    // refreshHighlightHierarchy( rightTreeNodes[i], 'absentInRightTree')
                    vm.immediateParent = rightTreeNodes[i];
                    // break;
                } else {
                    // if(rightTreeNodes[i].children.length > 0)
                    findImmediateParent(srcNode, rightTreeNodes[i].children)
                }
            }


        }

        function refreshNodes(nodes) {
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].nodeToMove = false
                nodes[i].isSelected = false
                nodes[i].highlightNode = false
                if (nodes[i].children) {
                    refreshNodes(nodes[i].children)
                }
            }
        }

        function rootNodeWrapper(srcNode, srcNodeScope) {
            return function () {
                BizzHierFactory.moveBusinessHierarchy(
                    srcNode.meKey,
                    '',
                    vm.trees.right.selectedHierarchyId,
                    vm.trees.right.selectedHierarchyId
                ).then(function (response) {
                    console.log(response);
                    AlertService.add("success", "Moved Business Unit Successfully", 3000);
                })
                srcNode.meParentKey = null
                vm.trees.right.nodes.unshift(srcNode)
                srcNodeScope.remove()
                vm.cancelNodeMove()
                // delete flattened[vm.trees.right.selectedHierarchyId][srcNode.meCode];
                // addOrUpdateNodeToFlattenedStore(vm.trees.right.selectedHierarchyId , srcNode)
            }
        }

        vm.cancelNodeMove = function () {

            vm.dropNode = null
            vm.showInBeginning = false
            vm.moveIcon = false

            refreshNodes(vm.trees.right.nodes)
            // toggleMoveIcons(srcNode , vm.trees.right.nodes)
            // srcNode.nodeToMove = false
            // srcNode.isSelected = true


        }

        function createDropNode(srcNode, srcNodeScope) {
            return function (destNode, destNodesScope) {
                console.log(vm.nodeToMove)
                srcNode.meParentKey = destNode.meKey
                srcNode.parentMeCode = destNode.meCode
                destNode.children.push(srcNode)
                BizzHierFactory.moveBusinessHierarchy(
                    srcNode.meKey,
                    destNode.meKey,
                    srcNodeScope.$modelValue.hierarchyId,
                    destNodesScope.$modelValue.hierarchyId
                ).then(function (response) {
                    console.log(response)
                    AlertService.add("success", "Moved Business Unit Successfully", 3000);
                })
                srcNodeScope.remove()

                vm.cancelNodeMove()
            }
        }


        vm.toggleCollapse = function (id) {
            vm.collapsed[id] = !vm.collapsed[id];
            var treeScope = _getRootNodesScope(id);
            if (vm.collapsed[id]) {
                // angular.element('[ui-tree]').scope().collapseAll();
                treeScope.$broadcast('angular-ui-tree:collapse-all');
            } else {
                treeScope.$broadcast('angular-ui-tree:expand-all');

            }
        };

        function _getRootNodesScope(myTreeId) {
            var treeElement = angular.element(document.getElementById(myTreeId));
            if (treeElement) {
                var treeScope = (typeof treeElement.scope === 'function') ?
                    treeElement.scope() : undefined;
                return treeScope;
            }
            return undefined;
        };
        vm.filter = function (node, searchText) {
            $filter('uiTreeFilter')(node, searchText, ['meName']);
        }
        vm.searchTree = function (listToSearch) {
            // if (!listToSearch)
            //     listToSearch = vm.devNodes;
            
            var a = $.map(vm.searchText.match(/"[^"]+"|[^ ]+/g) || [''], function (word) {
                if (word.charAt(0) === '"') {
                    var m = word.match(/^"(.*)"$/);
                    word = m ? m[1] : word;
                }

                return word.replace('"', '');
            });

            var search = '^(?=.*?' + a.join(')(?=.*?') + ').*$';
            var regExp = new RegExp(search, 'i')
            // vm.devNodes = [];
            for (var index = 0; index < listToSearch.length; index++) {
                var item = listToSearch[index];
                item.filteredOut = !regExp.test(item.meName);


                if (item.children) {
                    var nextItem = vm.searchTree(item.children)
                    if (nextItem)
                        nextItem.filteredOut = !regExp.test(nextItem.meName);

                }
                // else{
                //     continue;
                // }
            }
        }

        function dragMoveNode(event) {
            vm.treeLoading = true;
            var parentKey = '';
            var parentHierarchyId = null;
            if (event.dest.nodesScope.$nodeScope) {
                parentKey = event.dest.nodesScope.$nodeScope.$modelValue.meKey;
                parentHierarchyId = vm.trees.right.selectedHierarchyId;
            }
            BizzHierFactory.moveBusinessHierarchy(
                event.source.nodeScope.$modelValue.meKey,
                parentKey,
                event.source.nodeScope.$modelValue.hierarchyId,
                parentHierarchyId ? parentHierarchyId : event.source.nodeScope.$modelValue.hierarchyId,
            ).then(function (response) {
                console.log(response);
                presentInrightTree(event.source.nodeScope.$modelValue);
                addOrUpdateNodeToFlattenedStore(vm.trees.right.selectedHierarchyId,
                    event.source.nodeScope.$modelValue);

                event.source.nodeScope.$modelValue.meParentKey = event.dest.nodesScope.$nodeScope !== null ? event.dest.nodesScope.$nodeScope.$modelValue.meKey : null;
                event.source.nodeScope.$modelValue.parentMeCode = event.dest.nodesScope.$nodeScope !== null ? event.dest.nodesScope.$nodeScope.$modelValue.parentMeCode : null;


                vm.treeLoading = false;
                var newlyAdded = event.dest.nodesScope.$modelValue.find(function (item) {
                    return item.meKey === event.source.nodeScope.$modelValue.meKey;
                })
                refreshHighlightHierarchy(event.dest.nodesScope.$modelValue, 'absentInRightTree');
                if (newlyAdded)
                    switchType(newlyAdded, vm.trees.right.selectedHierarchyId);

                if (vm.treeCompared)
                    vm.compareHierarchies();
                AlertService.add("success", "Moved Business Unit Successfully", 3000);
            })
        }

        vm.treeOptions = {

            // accept: function (sourceNodeScope, destNodesScope) {
            //     console.log(event);


            //     if (_.findIndex(destNodesScope.$modelValue, ['bucode', sourceNodeScope.$modelValue.bucode]) === -1) {
            //         //api call to
            //         return true;
            //     }
            //     // destNodesScope.$nodeScope.$modelValue.name = sourceNodeScope.$modelValue.name;//overwrite
            //     return false;
            // },
            // beforeDrag: function (sourceNodeScope, destNodesScope) {
            //     // if (vm.hierarchyId) {
            //     console.log("dragging from Right Side: " + (sourceNodeScope.$modelValue.hierarchyId === vm.trees.right.selectedHierarchyId));
            //     console.log("dragging from Right Side: " + (sourceNodeScope.$modelValue.hierarchyId === vm.trees.left.selectedHierarchyId));
            //     console.log("node exists in Tax side: " + (flattened[vm.trees.right.selectedHierarchyId][sourceNodeScope.$modelValue.meCode]));
            //     if (sourceNodeScope.$modelValue.hierarchyId === vm.trees.right.selectedHierarchyId
            //         || !flattened[vm.trees.right.selectedHierarchyId][sourceNodeScope.$modelValue.meCode]) {
            //         return true;
            //     }
            //     var selectedHierarchy = vm.trees.right.hierarchies.find(function (item) {
            //         return item.hierarchy_id === vm.trees.right.selectedHierarchyId
            //     })
            //     AlertService.add('warning', "This BU already exists in '" + selectedHierarchy.hierarchy_name + "'", 2000)
            //     // }
            //     return true;
            // },
            // dropped: function (event) {
            //     if (event.dest.nodesScope.$nodeScope) {
            //         if (event.source.nodeScope.$modelValue.meParentKey !== event.dest.nodesScope.$nodeScope.$modelValue.meKey
            //             || event.source.nodeScope.$modelValue.hierarchyId !== event.dest.nodesScope.$nodeScope.$modelValue.hierarchyId) {
            //             dragMoveNode(event);
            //         }
            //     } else {
            //         //level 1 drop
            //         dragMoveNode(event);
            //     }
            //
            // }
        }
    }

    return controllers;

});
