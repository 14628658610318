define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.otherOverrideScreenService', [])
     .factory("firOtherOverrideScreenFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
    	 var firOtherOverrideScreenFactory = {};
    	 
    	 firOtherOverrideScreenFactory.deletePayment = function(paymentData, clob_settings, sso_id){
    		 var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=mz28fw";
    		 var jsonSettings = JSON.stringify(clob_settings);
    		 var jsonObj = JSON.stringify(paymentData);
			 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		 var params = filterParams;

    		 params = _.merge({},  GlobalService.globalParams , params);
    		 //params =  _.extend({jsonObj:jsonObj}, params);
    		 // params =  _.extend({jsonSettings:jsonSettings}, params);
    		 params = _.merge(params,  paymentData , params);
			 params.filing_key = workspaceFactory.activeScreen.filters.filterParams.filing_key;
    		 params.sso_id = sso_id;
    		// params.rp_seq_id = paymentData[0].rp_seq_id;
    		 
    		 //params.filing_group =  1;
    		 console.log(params);
    		 var promise = $http({
    			 	method: "post",
    		        url: url,
    		        data: params
    		     }).then(function (response) {
    		    	 var status = response.status;
    		         return response;
    		     });
    		 return promise;
    	 }
    	 firOtherOverrideScreenFactory.deleteBatchPayment = function(paymentData, clob_settings, sso_id){
    		 var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=46d5az";
    		 var jsonSettings = JSON.stringify(clob_settings);
    		 var jsonObj = JSON.stringify(paymentData);
			 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		 var params = filterParams;

    		 params = _.merge({},  GlobalService.globalParams , params);
    		 //params =  _.extend({jsonObj:jsonObj}, params);
    		 // params =  _.extend({jsonSettings:jsonSettings}, params);
    		 params = _.merge(params,  paymentData , params);
			 params.filing_key = workspaceFactory.activeScreen.filters.filterParams.filing_key;
    		 params.sso_id = sso_id;
    		// params.rp_seq_id = paymentData[0].rp_seq_id;
    		 
    		 //params.filing_group =  1;
    		 console.log(params);
    		 var promise = $http({
    			 	method: "post",
    		        url: url,
    		        data: params
    		     }).then(function (response) {
    		    	 var status = response.status;
    		         return response;
    		     });
    		 return promise;
    	 }


    	 return firOtherOverrideScreenFactory;
     }]);

    return services;
});

