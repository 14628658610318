define([
    'angular',
    './dataPushToEfileController',
    './pushToEfileServices',
], function () {
    'use strict';
    return angular.module('app.dataPushToEfile', ['app.DataPushToEfileController', 'app.PushToEfileServices'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('data-push-to-efile', {
                url: '/data-push-to-efile',
                "templateUrl": "app/components/DST/pushToEfile/dataPushToEfile.html",
                "controller": "DataPushToEfileCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);
}
);
