define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.detailedDiagnosticController', [])

    .controller('DetailedDiagnosticController', ['$state','GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout','workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', '$filter', 'GridFactory', '$window', 'TAX_YEARS', 'ModalFactory', 'GridSaveFactory', 'SERVER_CONFIG', '$uibModal', '$log','detailedDiagnosticFacoty', detailedDiagnosticController]);
    function detailedDiagnosticController($state,GlobalService, JsonObjectFactory, $parse, $scope, $rootScope, $timeout , workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, $filter, GridFactory, $window, TAX_YEARS, ModalFactory, GridSaveFactory, SERVER_CONFIG, $uibModal, $log,detailedDiagnosticFacoty) {
        var vm = this;
        const currentUser = USER_SETTINGS.user;
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
        vm.clientKey = currentUser.client_key;
        var activeScreen = workspaceFactory.activeScreen;
        var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
        vm.globalParams = JSON.stringify(tempObj);
        vm.taxYears = TAX_YEARS;
        vm.showPanel = false;
        vm.showGrid = false;
        var modalOpenedFlag = false; // Flag to disable multiple modals open

        console.log('$state $state',$state)
        var detailDiagData = GlobalService.getDetailDiagData();
          $timeout(function() {
           if(!_.isEmpty(detailDiagData)){
                console.log("Grid is already loaded");
                $scope.$broadcast("showLoader", true);
                vm.loading = true;
                vm.gridData = [];
                vm.gridData.DATA = [];
                detailDiagData.firstLoad = 0;
                $scope.$broadcast("dataLoaded", detailDiagData);
                $scope.$broadcast("showLoader", false);
                return true;
            }
       
         }, 5);

        var getData = function(data) {
            var detailDiagData = GlobalService.getDetailDiagData();

            if(!_.isEmpty(detailDiagData)){
                $scope.$broadcast("showLoader", true);

                console.log("Grid is already loaded");
                $scope.$broadcast("showLoader", true);
                vm.loading = true;
                vm.gridData = [];
                vm.gridData.DATA = [];
                detailDiagData.firstLoad = 0;
                $scope.$broadcast("dataLoaded", detailDiagData);
                $scope.$broadcast("showLoader", false);
                detailedDiagnosticFacoty.drillDownParams.fromLoadLink = false;

                return true;
            }else{
               if(detailedDiagnosticFacoty.drillDownParams.fromLoadLink){ /// from drillDown
                 data = detailedDiagnosticFacoty.drillDownParams
               }
                //vm.filterParams = {tax_year: 2020, scenario: 40, filing_key: 1, parent_me_string: '99999999999999999999,6196', system: 'A'}
                vm.filterParams = data;
                vm.filter = _.merge({}, vm.filterParams);
                if(!_.isEmpty(data)){
                    $scope.$broadcast("showLoader", true);

                    vm.loading = true;
                    vm.gridData = [];
                    vm.gridData.DATA = [];
                    GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=5980&action_id=30227", vm.filter).then(function (data) {
                        if (data.callSuccess === "1") {
                            GlobalService.setDetailDiagData(data);
                            $scope.$broadcast("dataLoaded", data);
                            $scope.$broadcast("showLoader", false);
                            detailedDiagnosticFacoty.drillDownParams.fromLoadLink = false;
                        } else {
                            AlertService.add("error", data.errorMessage);
                        }
                    });
                }
                
            }
            
        }

        $scope.openModal = function (modalObj, _data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            $scope.colData = _data.colData;
            if (modalOpenedFlag) return;


            var separator = 'c='
            if (!GENERAL_CONFIG.cache_templates) {
                separator += Math.random().toString(36).slice(2);
            } else {
                separator += SERVER_CONFIG.configSettings.release_version;
            }

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template + '?' + separator,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop, // added by Omi-07/24/2019(for disabling the modal outside click)
                windowClass: modalObj.windowClass,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    colData: function () {
                        return $scope.colData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });
            modalOpenedFlag = true;

            modalInstance.opened.then(function() {
                var modalLockProps = [];
                //&& modalObj.modal_lock == "Y"
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                
               

                if(modalObj  && modalObj.lock_check_required == "Y") {
                    if(workspaceFactory.activeScreen.data.settings.batch_select == 1){
                        _.each($scope.gridData,function(item){
                            if(item && item.data){
                                modalLockProps.push({
                                    "leid": item.data.LEID ,//"IF1139",
                                    "me_code": item.data.ME_CODE ||  item.data.ME || item.data.MAINTENANCE_OWNER || item.data.SOURCE_ME_CODES,
                                    "cdr_number":item.data.CDR_NO ||  item.data.TAX_CODE ,
                                    "consol_group_key": item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP,//1,
                                    "reporting_period": item.data.REPORTING_PERIOD||item.data.HO_REPORTING_PERIOD,
                                    "combination_key":item.data.COMBINATION_KEY ||item.data.HO_COMBINATION_KEY || item.data.COMPL_COMBINATION_KEY //1
                                    ,"scenario_key": item.data.SCENARIO_KEY ? item.data.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                                })
                            }
                           
                        })
                        if($scope.gridData && $scope.gridData.rowData){
                            modalLockProps.push({
                                "leid":$scope.gridData.rowData.LEID,//"IF1139",
                                "me_code": $scope.gridData.rowData.ME_CODE || $scope.rowData.ME || $scope.rowData.MAINTENANCE_OWNER ||  $scope.rowData.SOURCE_ME_CODES,
                                "cdr_number":$scope.gridData.rowData.CDR_NO || $scope.rowData.TAX_CODE,
                                "consol_group_key": $scope.gridData.rowData.CONSOL_GROUP_KEY || $scope.gridData.rowData.FILING_GROUP,//1,
                                "reporting_period": $scope.gridData.rowData.REPORTING_PERIOD,
                                "combination_key":$scope.gridData.rowData.COMBINATION_KEY  || $scope.gridData.rowData.HO_COMBINATION_KEY || $scope.gridData.rowData.COMPL_COMBINATION_KEY,
                                "scenario_key": $scope.gridData.rowData.SCENARIO_KEY ? $scope.gridData.rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                            })
                        }
                    }else if($scope.rowData){
                        if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.screen_key == 10075){ /* Tax Attribute Screen */
                                modalLockProps.push({  
                                "leid":$scope.rowData.POSTING_LEID,//"IF1139",
                                "me_code": $scope.rowData.POSTING_ME_CODE,
                                "cdr_number":$scope.rowData.POSTING_CDR_NO,
                                "reporting_period": $scope.rowData.POSTING_REPORTING_PERIOD
                                ,"scenario_key": $scope.rowData.SCENARIO_KEY ? $scope.rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario
                            })
                        }else{
                            modalLockProps.push({
                                "leid":$scope.rowData.LEID,//"IF1139",
                                "me_code": $scope.rowData.ME_CODE || $scope.rowData.ME || $scope.rowData.MAINTENANCE_OWNER || $scope.rowData.SOURCE_ME_CODES,
                                "cdr_number":$scope.rowData.CDR_NO || $scope.rowData.TAX_CODE,
                                "consol_group_key": $scope.rowData.CONSOL_GROUP_KEY ||  $scope.rowData.FILING_GROUP,//1,
                                "reporting_period": $scope.rowData.REPORTING_PERIOD,
                                "combination_key":$scope.rowData.COMBINATION_KEY || $scope.gridData.rowData.HO_COMBINATION_KEY|| $scope.gridData.rowData.COMPL_COMBINATION_KEY,
                                "scenario_key": $scope.rowData.SCENARIO_KEY ? $scope.rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario
                            })
                        }                       
                    }                  
                }                
                GlobalService.modalLockFilters(modalLockProps);
            });
            

            modalInstance.rendered.then(function() {
                let scenarioCode = '';
                if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.filters  && typeof workspaceFactory.activeScreen.filters.getFilters === "function"){
/*                     scenarioCode =  _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;
                    GlobalService.globalParams.scenarioCode = scenarioCode.CODE; */
                    let foundScenario =  _.find(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'});
                    if(foundScenario && foundScenario.selected){
                        scenarioCode = foundScenario.selected;
                        GlobalService.globalParams.scenarioCode = scenarioCode.CODE;
                        GlobalService.globalParams.is_issue_key_enabled = scenarioCode.ISSUE_ID_ENABLED;
                    }else{
                        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                        let taxYear = filterParams.tax_year ?filterParams.tax_year : GlobalService.globalParams.taxYear
                        _.find(GlobalService.inputs.tax_year.values,function(i){
                            if(i.value == taxYear){
                                foundScenario = _.find(i.scenarios,{scenario:GlobalService.globalParams.scenario});
                            }
                        });
                        if(foundScenario){
                            scenarioCode = foundScenario.scenario_type_code;
                            GlobalService.globalParams.scenarioCode = scenarioCode;
                            GlobalService.globalParams.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
                        }
                    }
                   
                }

                if(modalObj && modalObj.modal_lock == "Y" && modalObj.lock_check_required == "Y"){
                    var modalLockSelector = document.querySelector('.modal-window-lock-class .modal-dialog .modal-content');
                    modalLockSelector.querySelector('.modal-header').classList.add("modal-lock-hdr-class");
                    var modalfooters = modalLockSelector.querySelectorAll('.modal-footer'); //changed logic, as some modals are using morethan one modal-footer..
                    if(modalfooters && modalfooters.length > 0) {
                        _.forEach(modalfooters, function(eachFooter) {
                            if(eachFooter) {
                                _.forEach(eachFooter.querySelectorAll('[class*="btn"]'), function(eachBtn) {
                                    eachBtn.disabled = true;
                                })
                            }
                        })
                    }   
                    if((scenarioCode.ISSUE_ID_ENABLED == 'Y' && (GlobalService.globalParams.issue_id == ''))){
                            AlertService.add("error", 'Please select the Global Issue id', 3000);
                     }
                    else if( modalObj.modal_lock_err_msg){
                        AlertService.add("error", modalObj.modal_lock_err_msg, 3000);
                    }             
                }
                else if((scenarioCode.ISSUE_ID_ENABLED == 'Y' && (GlobalService.globalParams.issue_id == ''))){
                        var modalLockSelector = document.querySelector('.modal-window-lock-class .modal-dialog .modal-content');

                        var modalfooters = modalLockSelector.querySelectorAll('.modal-footer'); //changed logic, as some modals are using morethan one modal-footer..
                        if(modalfooters && modalfooters.length > 0) {
                            _.forEach(modalfooters, function(eachFooter) {
                                if(eachFooter) {
                                    _.forEach(eachFooter.querySelectorAll('[class*="btn"]'), function(eachBtn) {
                                        eachBtn.disabled = true;
                                    })
                                }
                            })
                        }                    
                    AlertService.add("error", 'Please select the Global Issue id', 3000);
                }
            })

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                modalOpenedFlag = false;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
                if(GlobalService.globalParams.checkLockedbj && (GlobalService.globalParams.checkLockedbj.length > 0 || !(_.isEmpty( GlobalService.globalParams.checkLockedbj)))) {
                    var modalLockProps = {};
                    GlobalService.modalLockFilters(modalLockProps);
                }
                modalOpenedFlag = false;
            });
        };

        $scope.ctrlFn = function (_callName, _data, _type) {
            switch (_type) {
                case "link":
                    //TODO: PASS DATA TO FILTER ON
                    if (_data.colData.link) {
                        var objCol = JSON.parse(_data.colData.link);
                        var table_state = null;

                        if (_data.colData.table_state) {
                            table_state = JSON.parse(_data.colData.table_state);
                        }

                        //console.log("=================table_state============", table_state)

                        workspaceFactory.loadLink("link", objCol.activity_key, objCol.screen_key, null, table_state, null);
                    }

                    break;
                case "openGridModal":
                    // $rootScope.$broadcast("rowAdded", _data);
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });

                    break;


                case "row-added":
                    $rootScope.$broadcast("rowAdded", _data);
                    break;

                case "batch-save":
                    if (!_data.colData.removable) {
                        $rootScope.$broadcast("dataChanged", _data);
                        break;
                    }

                case "reloadComment":
                    var type = _data.type;
                    $rootScope.$broadcast("loadGridMessages", type, true);
                    break;

                case "comment":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });
                    break;

                case "attachment":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });
                    break;
                case "preview":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        $scope.openModal(modalObj, _data);
                    });
                break;
                case "reloadAttachment":
                    $rootScope.$broadcast("loadGridAttachments", true);
                    break;

                case "batchSave":
                    if (_callName === "batchSaveDelete") {
                        $rootScope.$broadcast("batchSaveDelete", _data);
                        return true;
                    } else if (_callName === "batchSaveUnDelete") {
                        $rootScope.$broadcast("batchSaveUnDelete", _data);
                        return true;
                    }
                    break;

                case "form":

                    //console.log("_data ", _data);
                    if(workspaceFactory.activeScreen.screen_key == '167' || workspaceFactory.activeScreen.screen_key == '78') {
                        if(_data && _data.rowData && (_data.colData.call_back != 'editAttachment') && (_data.colData.call_back !='analyzeComments')){
                            if(_data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_ELIM') {
                                _callName = 'elimReclassAdjsByLocalChart';
                            } else if (_data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ_PY' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ_CY') {
                                _callName = 'reclassBookByLocalChart';
                            } else if (_data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_GHOST' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_PERM' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_TEMP') {
                                _callName = 'epTaxAdjsLocalChart';
                            }
                        }
                        
                    }

                    ModalFactory.getModal(_callName,_data).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi-07/24/2019(for disabling the modal outside click) //&& data.lockResult.is_locked == "Y"
                        if(data.lockResult && data.lockResult.is_locked  && data.modalScreen.lock_check_required == "Y") {
                            modalObj.windowClass = "modal-window-lock-class";
                            modalObj.modal_lock= data.lockResult.is_locked;
                            modalObj.modal_lock_err_msg = data.lockResult.lock_message;
                            modalObj.lock_check_required = data.modalScreen.lock_check_required;
                        }
                        $scope.openModal(modalObj, _data);
                    })
                    break;

                case "drillDown":
                    var gridFilters = {
                        gridFilters:[],
                        drillDownRefresh:0,
                        filterObj:{},
                        screenFilter:[]
                    };
                    var callDrillDown=function(drillDownObject){
                        var dataObj = JSON.parse(JSON.stringify(eval("(" + (drillDownObject) + ")")));//JSON.parse(drillDownObject);
                        if (dataObj.filters && dataObj.filters.length) {
                            _.forEach(dataObj.filters, function (value, key) {
                                var _value;
                                if(_data && _data.rowData && _data.rowData.FROMDOCUMENT == 'document'){
                                    _value = Number(_data.rowData.HEADER_KEY);
                                }else{
                                    _value = _data.rowData[value]
                                }

                                if (_value && (_value.length || _.isNumber(_value))) {
                                    var obj = {};
                                    obj.predicate = value;
                                    obj.value = [];
                                    obj.type = "array";
                                    obj.reverse = false;
                                    obj.operator = "[]";
                                    obj.value.push(_value);
                                    gridFilters.gridFilters.push(obj);
                                }
                            });
                            gridFilters.drillDownRefresh = _data.colData.drilldown_filter_refresh == null ? gridFilters.drillDownRefresh : _data.colData.drilldown_filter_refresh
                        }
                        var gridFilterObj = [];
                        var screenFilters = function(screenFilters){
                            _.forEach(screenFilters, function (value, key) {
                                var _value= _data.rowData[value.row_data_key];
                                if (_value && (_value.length || _.isNumber(_value))) {
                                    var obj = {};
                                   // obj.predicate = value.name;
                                    obj.value = [];
                                    obj.type = "array";
                                    obj.reverse = false;
                                    obj.operator = "[]";
                                    obj.value.push(_value);
                                    if(value.row_data_key=='TAX_YEAR'){
                                        value.value = _value+'';
                                    }else{
                                        value.value = _value;
                                    }
                                    gridFilters.screenFilter.push(obj);
                                }
                            });
                                gridFilters.screenFilter.forEach(item=>{
                                gridFilterObj.push({name:item.predicate,value:item.value.join('')});
                            });
                        }
                        var action_template = dataObj.action_template_key !=null ? dataObj.action_template_key: null;
                            /*********************************************************************************************************
                             *   dirlldown from the one activity screen to another activity screen without filter inherit 9/5/2019
                             ********************************************************************************************************/
                        if(dataObj.activity_key && dataObj.screen_key && dataObj.screenFilter ||dataObj.filter_json){
                            if (dataObj.screenFilter && dataObj.screenFilter.length) {
                                screenFilters(dataObj.screenFilter)
                            }

                            gridFilters.filterObj=(gridFilterObj);
                            workspaceFactory.loadLink("drillDown",null,dataObj.activity_key,dataObj.screen_key,dataObj.screenFilter||dataObj.filter_json,gridFilters.gridFilters,null,action_template);
                        //   _type,_comment,_activity_key,_screen_key,_filters,_table_state,_locationObj,_actionGrid,_noReload
                                /*********************************************************************************************************
                             *   dirlldown from the one screen to another screen without filter inherit 9/5/2019
                                 ********************************************************************************************************/

                            }else if(dataObj.activity_key && dataObj.screen_key){
                                 workspaceFactory.loadLink("drillDown",null,dataObj.activity_key,dataObj.screen_key,{},{},null,action_template);
                            }
                            else if (dataObj.screen_key && dataObj.screenFilter){
                                screenFilters(dataObj.screenFilter);
                                gridFilters.filterObj=(gridFilterObj);
                                workspaceFactory.loadLink("drillDown","{}",workspaceFactory.activeScreen.activity_key,dataObj.screen_key,(dataObj.screenFilter||dataObj.filter_json),
                                gridFilters.gridFilters,null,action_template);
                            }
                                /*********************************************************************************************************
                             *   dirlldown from the one screen to another screen with filters inherit
                                 ********************************************************************************************************/
                            else{
                                var index = _.findIndex(workspaceFactory.screens, {
                                                'screen_key': dataObj.screen_key
                                            });
                            if (index >= 0) {
                                workspaceFactory.loadDrillDown(workspaceFactory.screens[index], gridFilters);
                            }
                        }
                    }
                    if(_data.rowData && _data.rowData.DRILLDOWN) {
                        callDrillDown(_data.rowData.DRILLDOWN);
                    } else  if (_data.colData && _data.colData.drilldown) {
                        if(_.isArray(JSON.parse(_data.colData.drilldown))){ /* drilldown with rule --- based on rule it will redirect to target screen 8/24/2020 */
                            var arryObj = JSON.parse(_data.colData.drilldown);
                            var isFound = false;
                            var drillObj = "";
                            var checkCondition = function(rule,data){
                                var isValid =false;
                                if(rule.operator === "=" && rule.value == data[rule.map]){
                                     isValid = true;
                                }else if(rule.operator === "!=" && rule.value != data[rule.map]){
                                     isValid = true;
                                }
                                return isValid;
                            }
                            _.each(arryObj,function(item){
                                if(item.rule != undefined){
                                   var x = checkCondition(item.rule,_data.rowData);
                                   if(x){
                                        isFound = true;
                                        drillObj = JSON.stringify(item.drillDown);
                                   }
                                }
                            })
                            if(isFound){
                                callDrillDown((drillObj));
                            }
                        }else{
                            callDrillDown(_data.colData.drilldown); /* drilldown without rule */
                        }
                    }
                    break;

                case "input":
                        GridSaveFactory.saveSwitch(_callName, _data);
                        break;
                case "columnTemplate":
                    GridSaveFactory.colTemplatesUpdate(workspaceFactory.activeScreen.data.settings.grid_key, _callName, _data);
                   /*  $rootScope.$on('loadColumnTemplates', function (event, data) {
                        ggApi.colToolSetTemplates(data);
                    }); */
                    break;
                case "auditcell":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });
                    break;

                case "downloadDoc":
                    if (_callName === "downloadDocuments") {
                        return true;
                    }
                    break;
                case "removeBatchSelect":
                    workspaceFactory.activeScreen.data.batchSelect = _data.batchSelect;
                    break;
                case "applyPivotTemplate":
                    if(!_.isEmpty(_data.pivotGroupObject)){
                        workspaceFactory.activeScreen.data['pivotRowList'] =[];
                        workspaceFactory.activeScreen.data['pivotColumn'] = [];
                        workspaceFactory.activeScreen.data['pivotValList'] = [];
                      if(_data.pivotGroupObject.groupRows && _data.pivotGroupObject.groupRows.length){
                        workspaceFactory.activeScreen.data['pivotRowList'] = _.filter(workspaceFactory.activeScreen.data.header, (h) => {
                            return _data.pivotGroupObject.groupRows.indexOf(h.map) >=0;
                          
                          });
                      }
                      if(_data.pivotGroupObject.columnToRows && _data.pivotGroupObject.columnToRows.length){
                         workspaceFactory.activeScreen.data['pivotColumn'] = _.filter(workspaceFactory.activeScreen.data.header, (h) => {
                            return _data.pivotGroupObject.columnToRows.indexOf(h.map) >=0;
                          
                          });
                      }
                      if(_data.pivotGroupObject.agg && !_.isEmpty(_data.pivotGroupObject.agg)){
                          var valKeys = _.keys(_data.pivotGroupObject.agg);
                          workspaceFactory.activeScreen.data['pivotValList'] =   
                            _.filter(workspaceFactory.activeScreen.data.header, (h) => {
                                return valKeys.indexOf(h.map) >=0;
                            
                            });                       
                      }

                    }
                    workspaceFactory.activeScreen.data['pivotGroup'] = _data.pivotGroupObject;
                    $scope.$broadcast('applyPivotPanel',workspaceFactory.activeScreen.data, _data.clearActionTemplate);                    
                   // $scope.$broadcast('dataLoaded',workspaceFactory.activeScreen.data) ;
                   if(_data.clearActionTemplate){
                        workspaceFactory.activeScreen.data.pivotdataArr =[];
                        workspaceFactory.activeScreen.data.pivotheaderArray =[];
                        if(_.filter( workspaceFactory.activeScreen.data.header,{map:'group'}).length>=0){
                            _.remove( workspaceFactory.activeScreen.data.header,function(item){
                                return item.map == 'group';
                            });
                        }
                        $scope.$broadcast('dataLoaded',workspaceFactory.activeScreen.data)

                    }else{
                        GridPivotFactory.applyGroupPivot();
                    }

                    break;
                default:
                    ////console.error("Type was not correct")
                    break;
            }
        }

        $window.document.querySelector('gtw-detail-diagnostic-ce')
        .addEventListener('load-detail-diag-grid', (event)=>{
            console.log('event',event);
            if(event != undefined){
                let cachedDrillDownObject = {};
                let screenFilterParams = {};
                if(!_.isEmpty(detailedDiagnosticFacoty.drillDownParams)){
                    cachedDrillDownObject['tax_year'] = detailedDiagnosticFacoty.drillDownParams.tax_year;
                    cachedDrillDownObject['scenario']= detailedDiagnosticFacoty.drillDownParams.scenario;
                    cachedDrillDownObject['filing_key']= detailedDiagnosticFacoty.drillDownParams.filing_key;
                    cachedDrillDownObject['system']=detailedDiagnosticFacoty.drillDownParams.system;
                    cachedDrillDownObject['parent_me_string']=detailedDiagnosticFacoty.drillDownParams.parent_me_string;
                }
                screenFilterParams['tax_year'] = event.detail.tax_year;
                screenFilterParams['scenario']= event.detail.scenario;
                screenFilterParams['filing_key']= event.detail.filing_key;
                screenFilterParams['system']=event.detail.system;
                screenFilterParams['parent_me_string']=event.detail.parent_me_string;
                
                if(detailedDiagnosticFacoty.drillDownParams.fromLoadLink){
                    if(!_.isEqual(cachedDrillDownObject,screenFilterParams) ){
                        GlobalService.setDetailDiagData({});
                    }
                }else{
                    GlobalService.setDetailDiagData({});

                }
            }
            getData(event.detail);
        });
        $scope.$on('loadDetailDiag',function(event){
            getData(event.detail);
        })

    }
});