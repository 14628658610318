/**
 * Created by 400371031 on 1/20/2021.
 */

define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.m3PayorAllocateServce',[])

        .factory('m3PayorAllocateFactory',['$q','$http','$timeout','$rootScope','$log','DataFiltersFactory','workspaceFactory','JsonObjectFactory','AlertService','GlobalService','GENERAL_CONFIG',
        function m3PayorAllocateFactory($q,$http,$timeout,$rootScope,$log,DataFiltersFactory,workspaceFactory,JsonObjectFactory,AlertService,GlobalService,GENERAL_CONFIG) {
            var factory = {};
            
            factory.getPayorAccountDetails = function(le_keys, payment_type, classification_type, trans_type, sso_id){
            	//var le_key_list = JSON.stringify(le_key_list);
	    		var params = { "action_code": 'q7a4cr',
	    					   "sso_id": sso_id, 
	    					   "tax_year": GlobalService.globalParams.tax_year, 
	    					   "scenario": GlobalService.globalParams.scenario, 
	    					   "jcd_key":  GlobalService.globalParams.jcd_key, 
	    					   "le_keys":  le_keys,
	    					   "payment_type": payment_type,
	    					   "classification_type": classification_type,
	    					   "trans_type": trans_type
	    					  };
	    					  
	    		console.log("service:::::getPayorAccountDetails:::::");
	    		console.log(params);		
	    			  
	    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
	    			console.log("Now in the Servie after action call the data was ................:::::", data);
	    			if(null != data.callSuccess && data.callSuccess == "1"){	    				
	                    console.log("callSuccess:::: Data Now is::-----", data);
	                    return data;
	    			}else{
	    				AlertService.add("error", data.errorMessage);
	                    return false;
	    			}
	    		});
	        };
	        
	        factory.getM3PayorList = function(){
	        	var params = { "action_code": 'eosx7n',
 					   "tax_year": GlobalService.globalParams.tax_year, 
 					   "scenario": GlobalService.globalParams.scenario, 
 					   "jcd_key":  GlobalService.globalParams.jcd_key
 					  };
	        	console.log(params);
	        	
	        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
	    			console.log("Now in the Servie after action call the data was ................:::::", data);
	    			if(null != data.callSuccess && data.callSuccess == "1"){	    				
	                    console.log("callSuccess:::: Data Now is::-----", data);
	                    return data;
	    			}else{
	    				AlertService.add("error", data.errorMessage);
	                    return false;
	    			}
	    		});
	        };
	        
	        factory.saveRPModal = function(final_data, clob_settings, classification_type, trans_type){
	    		console.log("Clob_data was done as:::::::::------------", final_data);
	    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=y0onfe";
	    		var json_settings = JSON.stringify(clob_settings);
	    		var json_data = JSON.stringify(final_data);
	    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	    		
	    		var params = filterParams;
	            params = _.merge({},  GlobalService.globalParams , params);
	            params =  _.extend({json_data:json_data}, params);
	            params =  _.extend({json_settings:json_settings}, params);
	            params.classification_type =  classification_type;
	            params.trans_type =  trans_type;
	            console.log(params);
	            var promise = $http({
	                method: "post",
	                url: url,
	                data: params
	            }).then(function (response) {
	                var status = response.status;
	                return response;
	            });
	            return promise;
	    	};
	    	
	    	factory.allocateProcess = function(final_data, clob_settings){
	    		console.log("Clob_data was done as:::::::::------------", final_data);
	    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=oexyp0";
	    		var json_settings = JSON.stringify(clob_settings);
	    		var json_data = JSON.stringify(final_data);
	    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	    		
	    		var params = filterParams;
	            params = _.merge({},  GlobalService.globalParams , params);
	            params =  _.extend({json_data:json_data}, params);
	            params =  _.extend({json_settings:json_settings}, params);
	            console.log(params);
	            var promise = $http({
	                method: "post",
	                url: url,
	                data: params
	            }).then(function (response) {
	                var status = response.status;
	                return response;
	            });
	            return promise;
	    	};

			factory.getThridPartyPayorList = function(){
	        	var params = { "action_code": 'zp7sok',
 					   "tax_year": GlobalService.globalParams.tax_year, 
 					   "scenario": GlobalService.globalParams.scenario, 
 					   "jcd_key":  GlobalService.globalParams.jcd_key
 					  };
	        	console.log(params);
	        	
	        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
	    			console.log("Now in the Servie after action call the data was ................:::::", data);
	    			if(null != data.callSuccess && data.callSuccess == "1"){	    				
	                    console.log("callSuccess:::: Data Now is::-----", data);
	                    return data;
	    			}else{
	    				AlertService.add("error", data.errorMessage);
	                    return false;
	    			}
	    		});
	        };

			factory.saveThirdPartyPayor = function(payor_name, payor_ein){
	    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=o3m4sg";
	    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	    		
	    		var params = filterParams;
	            params = _.merge({},  GlobalService.globalParams , params);
	            params.payor_name =  payor_name;
	            params.payor_ein =  payor_ein;
	            console.log(params);
	            var promise = $http({
	                method: "post",
	                url: url,
	                data: params
	            }).then(function (response) {
	                var status = response.status;
	                return response;
	            });
	            return promise;
	    	};


            return factory;

        }]);


    return services;


});