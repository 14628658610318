
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.expenseTransServices',[])

        .factory("ExpenseTransFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','workspaceFactory'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,workspaceFactory) {
            var expenseTransFactory = {};
            var totalModulesAssigned = 0;
            expenseTransFactory.url ={};
            expenseTransFactory.url.trans_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=4x3jcm";

            
            
            expenseTransFactory.save = function(_data,Details,otherInfo){

                var filterParams =  (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params =  _.extend({sso_id:otherInfo.sso_id,tax_year:otherInfo.tax_year,scenario:otherInfo.scenario,jcd_key:otherInfo.jcd_key,screen_key:otherInfo.screen_key}, params);
//                params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "Trans_SAVE";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: expenseTransFactory.url.trans_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            
            expenseTransFactory.previewExistingBizzHierarchy = function(data){
            	console.log("goldSyncFactory.previewExistingBizzHierarchy data::",data);
            	var promise = $http({
                    method: "POST",
                    url: 'previewBizzHierarchy.ge',
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };
            
            expenseTransFactory.previewStagedBizzHierarchy = function(data){
            	var promise = $http({
                    method: "POST",
                    url: 'previewStagedBizzHierarchy.ge',
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };
            
            expenseTransFactory.finalizeStagedBizHierarchy = function(data){
            	console.log("Finalizing Staged Biz Hierarchy....Pl wait......Data::",data);
            	data.action_code = 'ewwww9';
            	var promise = $http({
                    method: "POST",
                    url: 'loadJsonObject.ge',
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            }
            
            /** TESTING TESTING TESTING**/
            expenseTransFactory.syncGoldDataForBizzHierarchy = function(data){
            	console.log("syncGoldDataForBizzHierarchy....Pl wait......Data::",data);
            	var promise = $http({
                    method: "POST",
                    url: 'syncGoldBizzHierarchyData.ge',
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            }
            
            
            
            return expenseTransFactory;
        }])


    return services;


});