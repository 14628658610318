define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.formReviewServices', [])
                            .factory("FormReviewFactory", ['$http', '$q', 'GENERAL_CONFIG', 'GlobalService', function ($http, $q, GENERAL_CONFIG, GlobalService) {
                                const formReviewFactory = {};
                                // default selections
                                formReviewFactory.formReviewFilter = {
                                    category: 'C',
                                    location: 'D',
                                    lEType: 'HO'
                                };
                                formReviewFactory.formReviewCache = {};
                                const JCD_KEY = 250;


                                // get flat data for the entities
                                formReviewFactory.getViewForDropdown = function (category, src_system_flag, lEType) {
                                    if (lEType === 'CONSOL') lEType = 'CON';
                                    const promise = $http({
                                        method: "POST",
                                        url: GENERAL_CONFIG.pdf_engine_api_url + '/getViewForDropdown?taxYear=' + GlobalService.globalParams.tax_year + '&scenario=' +
                                            GlobalService.globalParams.scenario + '&jcdKey=' + JCD_KEY + '&leType=' + lEType + '&src_system_flag=' + src_system_flag
                                            + '&business_type=' + category
                                    });
                                    return promise;
                                };

                                formReviewFactory.getTaxForms = function (selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType = '', selectedConsolGroup = '') {
                                    if (src_system_flag === 'F' && lEType === 'PSHIP') {
                                        lEType = 'HO';
                                    }
                                    if (entityType === '') {
                                        selectedConsolGroup = '';
                                    }
                                    const promise = $http({
                                        method: 'POST',
                                        url: GENERAL_CONFIG.pdf_engine_api_url + '/getTaxForms?taxYears=' + GlobalService.globalParams.tax_year + '&scenario=' +
                                            GlobalService.globalParams.scenario + '&jcdKey=' + JCD_KEY + '&selectedView=' + selectedView + '&cdrNo=' + cdrNo +
                                            '&reportingPeriod=' + rp_period + '&leType=' + lEType + '&src_system_flag=' + src_system_flag + '&business_type=' +
                                            category + '&entity_type=' + entityType + '&cgkey=' + selectedConsolGroup
                                    });
                                    return promise;
                                };

                                formReviewFactory.getTaxFormsCorpDomestic = function (selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType, selectedConsolGroup) {
                                    const getTaxFormsPromise = formReviewFactory.getTaxForms(selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType, selectedConsolGroup)
                                                                                .then((response) => {
                                                                                    return response;
                                                                                });
                                    const getJobIdForCorpPromise = formReviewFactory.getJobIdForCorp(selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType, selectedConsolGroup)
                                                                                    .then((response) => {
                                                                                        return response;
                                                                                    });

                                    const promises = [getTaxFormsPromise, getJobIdForCorpPromise];
                                    return $q.all(promises);
                                };

                                formReviewFactory.getJobIdForCorp = function (selectedView, rp_period, lEType, cdrNo, src_system_flag, category, entityType = '', selectedConsolGroup = '') {
                                    if (src_system_flag === 'F' && lEType === 'PSHIP') {
                                        lEType = 'HO';
                                    }
                                    const promise = $http({
                                        method: 'POST',
                                        url: GENERAL_CONFIG.pdf_engine_api_url + '/getJobIdForCorp?taxYears=' + GlobalService.globalParams.tax_year + '&scenario=' +
                                            GlobalService.globalParams.scenario + '&jcdKey=' + JCD_KEY + '&selectedView=' + selectedView + '&cdrNo=' + cdrNo +
                                            '&reportingPeriod=' + rp_period + '&leType=' + lEType + '&src_system_flag=' + src_system_flag + '&business_type=' +
                                            category + '&entity_type=' + entityType + '&cgkey=' + selectedConsolGroup
                                    });
                                    return promise;
                                };


                                return formReviewFactory;
                            }]);
    return services;
});
