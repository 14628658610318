
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.businessSignOffDescService',[])

    .factory('businessSignOffDescFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var factory = {};
        factory.getBusSignOffListUrl = GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=31304";
        factory.saveBusCommentListUrl = GENERAL_CONFIG.base_url + "/saveBusinessSignOff";

        factory.getSignOffList = function (_params) {
            var promise = $http({
                method: "post",
                url: factory.getBusSignOffListUrl,
                data: _params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        factory.saveSignOffCmt = function(saveParams) {

            var promise = $http({
                method: "post",
                url: factory.saveBusCommentListUrl,
                data: saveParams
            }).then(function (response) {
                return response.data;
            });

            return promise;
        }

        return factory;

    }])

    return services;

});
