const { filter } = require("lodash");

define(
    [ 'angular'

    ],
    function() {
        'use strict';
        var controllers = angular.module('app.F1118GPIAttachSchdCtrl', [])
                .controller(
                        'f1118GPIAttachSchedule',
                        [ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'GlobalService', 'AlertService','$timeout','workspaceFactory', 'USER_SETTINGS', 'GENERAL_CONFIG','$uibModal',
                            '$uibModalInstance', 'gridData', f1118GPIAttachSchedule ])

        /***************************************
         * 1118 SCHD GPI ATTACHMENT DETAILS SCREEN
         * *************************************/
        function f1118GPIAttachSchedule($rootScope, $scope, $state, $log,
            JsonObjectFactory, GlobalService, AlertService, $timeout, workspaceFactory, USER_SETTINGS, GENERAL_CONFIG,$uibModal,
            $uibModalInstance, gridData) {

                var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                vm.taxYear = filterParams.tax_year;
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;
                vm.filerEntityKey = filterParams.filer_entity_key;
                vm.gridDataCloned;
                vm.resultJson = [];
                vm.loadingData = false;
                vm.generalInfo = {};
                //Current screen's activity name
				vm.activity_name = workspaceFactory.activity.label;

                if (gridData != null) {
					vm.gridDataCloned = _.clone(gridData);
                    //if(vm.gridDataCloned.colData){
						var rowData = vm.gridDataCloned.rowData; 
                        vm.generalInfo.basketName = vm.activity_name;
                        vm.generalInfo.schdName = workspaceFactory.activeScreen.label;
                        vm.generalInfo.line = rowData.ELEMENT_NAME.split(".")[0];
                    //}
                   console.log("rowData.LINE_KEY" + rowData.LINE_KEY);
                   
				}

                vm.getCodeSplitupAmt = function() {
                    vm.loadingData = true;
					if(vm.gridDataCloned.colData){
						var rowData = vm.gridDataCloned.rowData; 
                        var basketKey = rowData.BASKET_KEY;
                        var schdName = 'F1118_GPI';
                        var lineKey = parseInt(rowData.LINE_KEY) - 2;
                        var colNumber = vm.gridDataCloned.colData.map.split("_")[0];
                        vm.generalInfo.colNumber = colNumber;

                        var params = {
                                "action_code" : '1zrq5v',
                                "tax_year" : vm.tax_year,
                                "scenario" : vm.scenario,
                                "jcd_key" : vm.jcd_key,
                                "filer_entity_key" : vm.filerEntityKey,
                                "basket_key" : basketKey,
                                "schd_name" : schdName,
                                "line_key" : lineKey,
                                "col_number" : '1',
                                "ein" : null
                                
                            };
                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                                vm.resultJson = [];
                                vm.loadingData = false;
                                if (data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                                    AlertService.add("error", data.errorMessage);
                                    return false;
                                } else {
                                    vm.resultJson = data.jsonObject;
                                    var amtWithoutComma;
                                    vm.resultJson.forEach(function (item) {
                                        amtWithoutComma = item.AMOUNT;
                                        amtWithoutComma = parseInt(amtWithoutComma.replace(/,/g, ''));
                                        console.log(amtWithoutComma);
                                        if (amtWithoutComma < 0) {
                                            item.AMOUNT_FORMATTED = item.AMOUNT.replace('-','(') + ')';
                                        }
                                        else {
                                            item.AMOUNT_FORMATTED = item.AMOUNT;
                                        }
                                    });
                                }
                            });
					
				}
            };
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
            vm.getCodeSplitupAmt();
        }
        return controllers;
    });