define([
    'angular'
], function () {
    'use strict';


    var controllers = angular.module('app.ghostSystemCtrl',  [])
	.controller('ghostSystemCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
		'GlobalService','USER_SETTINGS','ghostStatusSaveFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData','gridData', ghostSystemCtrl]);


    	function ghostSystemCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope, ModalFactory, AlertService, $timeout,
					   GlobalService,USER_SETTINGS,ghostStatusSaveFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {	
				
				
			}


});
