const { sum } = require("lodash");

define([
    'angular'

], function () {
    'use strict';

    var directives =  angular.module('app.gridDirective',[])

        .directive("gg___Grid", [ function () {

            //http://henriquat.re/directives/advanced-directives-combining-angular-with-existing-components-and-jquery/angularAndJquery.html
            var options = {};
            return {
                restrict: "A",
                scope: {
                    ggData: '@ggData',
                    ggDataUpdate: '=ggDataUpdate',
                    ggLoading: '=ggLoading'
                },

                link: function (scope, element, attrs) {
                    var ggGrid =  angular.element(element);
                    ggGrid.gizmoGrid({
                        fixedColumn:2,
                        gridHeight:"auto",
                        defaultRowHeight:35,
                        callBack: callBack
                    });

                    var ggApi = ggGrid.data('gizmoGridApi');

                    var callBack = function(object){
                        scope.ggCallBack(object.callName, object.data);
                    }
                    scope.$on("dataLoaded", function (event, data, filters) {

                        if(typeof filters == "undefined"){
                            filters = null;
                        }

                        var  newData = data;
                        ggApi.hideOverLay();
                        ggApi.loadData({data: data, filters:filters});
                    })

                    ggApi.showLoader();

                    scope.$on("$destroy",function() {
                        scope.ggData = {}
                        ggApi.destroy();
                    });
                }

            };
        }])


        .directive("ggGrid", [ '$rootScope', 'USER_SETTINGS','MessageFactory','workspaceFactory','$state','$timeout', 'GENERAL_CONFIG','AlertService', function ($rootScope, USER_SETTINGS,MessageFactory,workspaceFactory,$state,$timeout, GENERAL_CONFIG,AlertService) {

            //http://henriquat.re/directives/advanced-directives-combining-angular-with-existing-components-and-jquery/angularAndJquery.html
            var options = {};
            return {
                restrict: "A",
                scope: {
                    ggCallBack: '&'
                },
                link: function (scope, element, attrs) {
                    var ggGrid =  angular.element(element);
                    var callBack = function(object){
                        scope.ggCallBack(object);
                    }

                    ggGrid.gizmoGrid({
                        fixedColumn:2,
                        gridHeight:"auto",
                        defaultRowHeight:35,
                        callBack: callBack
                    });

                    var ggApi = ggGrid.data('gizmoGridApi');

                    scope.$on('navPanel', function() {
                        var data = $rootScope.editedSettings.navPanel
                        ggApi.adjustModal(data);
                    });

                    scope.$on('dataUpdate', function (event, data) {
                        console.log(" data ON THE WAY IN ----  "  , data.data )
                        ggApi.updateData(data);
                    });
                    scope.$on('gridRefresh', function (event, args) {
                        ggApi.gridRefresh();
                    });

                    scope.$on('gridRefreshPipe', function (event, data) {
                        ggApi.gridRefreshPipe(data);
                    });

                    scope.$on('loadActonTemplates', function (event, data) {
                        ggApi.loadActonTemplates(data);
                    });
                    scope.$on('showDataRefresh', function (event, show) {
                        ggApi.dataRefresh(show);
                    });

                    scope.$on('deleteRows', function (event, array) {
                        ggApi.deleteRows(array);
                    });

                    scope.$on('deleteRow', function (event, object_id) {
                        ggApi.deleteRow(object_id);
                    });

                    scope.$on('gridDeleteSelectedRow', function (event, object_id) {
                        ggApi.deleteSelectedRow(object_id);
                    });
                    scope.$on('showLoader', function (event, show) {

                        console.log(" ===================== showLoader : " , show )
                        /* show is coming as object when we open the Grid on Modal */
                        if(_.isObject(show) && show.isLoader){
                            ggApi.showLoader(show.isFromModal);
                        }
                        else if(_.isObject(show) && !show.isLoader){
                            ggApi.hideLoader();
                        }
                        /* regular flow */
                        if(!_.isObject(show) && show ){
                            ggApi.showLoader();
                        }else if(!_.isObject(show) && !show){
                            ggApi.hideLoader();
                        }
                    });

                    scope.$on('hideLoader', function (event) {
                            ggApi.hideLoader();
                    });

                    scope.$on('removeNewRow', function (event,object_id) {
                        console.log("object_id  " , object_id)
                        ggApi.removeNewRow();
                    });
                    scope.$on('dataLoaded', function (event, data) {
                        var ggData = [];
                        ggData.columnTemplates = data.columnTemplates;
                        ggData.actionTemplates = data.actionTemplates;
                       
                        ggData.pivotActionTemplates = [];
                        
                        var selectedActionTemplate = _.filter(data.actionTemplates,{selectedAction:true});
                        if(!selectedActionTemplate.length){
                            ggApi.clearSlectedActionTemplateFirst();
                        }
                        ggData.firstLoad = data.firstLoad;

                        // addition of issue cols -- start
                        // remove issue_key from header if already exists
                        if((data.settings.grid_type === 'batch-save' || data.settings.grid_type === 'dynamic') && data.firstLoad === 1){
                            if(data.header.filter(i => i.map === 'ISSUE_KEY').length > 0) {
                                var index =  _.findIndex(data.header, {map: 'ISSUE_KEY'});
                                data.header.splice(index, 1);
                            }
                            if(data.header.filter(i => i.map === 'STD_DESC').length > 0) {
                                var index =  _.findIndex(data.header, {map: 'STD_DESC'});
                                data.header.splice(index, 1);
                            }
                        }
        
                   //     issue_desc, issue_short_desc
                        
                        if( data.settings.display_issue_columns === 1) {
                            if(data.settings.grid_type === 'import' && data.screen_key != 11237){
                                if(data.header.filter(i => i.map === 'issue_key').length === 0){
                                    var header = {
                                        "data_type":"number",
                                        "display":"y",
                                        "editable":"y",
                                        "label":"Issue Id",
                                        "map":"issue_key",
                                        "validation":"numeric",
                                        "call_back" : "rowValidation",
                                        "edit_type" : "text",
                                        "width":90,
                                        "header_class":"issueColumns",
                                        "required" : "1",
                                    };        
                                    data.header[data.header.length] = header; 
                                }        
                            } else if(data.settings.grid_type !== 'import' 
                                    && data.header.filter(i => i.map === 'ISSUE_KEY').length < 1) {
                               var index = data.settings.fixed_columns;
                                var header = {
                                    "data_type":(workspaceFactory.activeScreen.screen_type == 'grid-dynamic' || workspaceFactory.activeScreen.screen_type =='grid-standard-pivot')
                                                ?"string":"number" ,
                                    "display":"y",
                                    "editable":"n",
                                    "label":"Issue Id",
                                    "map":"ISSUE_KEY",
                                    "validation":null,
                                    "width":90,
                                    "header_class":"issueColumns",
                                    "object_id" : "issueId"
                                };
                               
                                var stdDesc = {
                                    "data_type":"string",
                                    "display":"y",
                                    "editable":"n",
                                    "label":"QAR Standard Description",
                                    "map":"STD_DESC",
                                    "validation":null,
                                    "width":90,
                                    "header_class":"issueColumns",
                                    "object_id" : "stdDesc"
                                }, 
                                 shortDesc = {
                                    "data_type":"string",
                                    "display":"y",
                                    "editable":"n",
                                    "label":"Issue Short Description",
                                    "map":"ISSUE_SHORT_DESC",
                                    "validation":null,
                                    "width":90,
                                    "header_class":"issueColumns",
                                    "object_id" : "shortDesc"
                                },
                                 issueDesc = {
                                    "data_type":"string",
                                    "display":"y",
                                    "editable":"n",
                                    "label":"Issue Description",
                                    "map":"ISSUE_DESC",
                                    "validation":null,
                                    "width":90,
                                    "header_class":"issueColumns",
                                    "object_id" : "issueDesc"
                                }; 
                                // adding issue cols to default columns template
                                // var colsIndex = _.findIndex(data.columnTemplates, {'default_template':1});
                                        
                                // if(colsIndex > -1) {
                                if(data.columnTemplates.length >0){
                                    // var toBeDisplayedCols = _.filter(data.header, { 'display': 'y' });
                                    // var toNotBeDisplayedCols = _.filter(data.header, { 'display': 'n' });
                                    // data.header = [];
                                    // data.header = [...toBeDisplayedCols, ...toNotBeDisplayedCols];
                         

                                    /*  data.header.splice(index-2, 0, header);
                                    data.header.splice(index-1, 0, stdDesc);*/
                                    data.header.splice(index+1, 0,header);
                                    data.header.splice(index+2, 0,stdDesc);
                                    data.header.splice(index+3, 0,issueDesc);
                                    data.header.splice(index+4, 0,shortDesc);
                                   
                                    header = {
                                        "display":"y",
                                        "map":"ISSUE_KEY",
                                        "width":90
                                    };
                                    stdDesc = {
                                        "display":"y",
                                        "map":"STD_DESC",
                                        "width":90
                                    };
                                    shortDesc = {
                                        "display":"y",
                                        "map":"ISSUE_SHORT_DESC",
                                        "width":90
                                    };
                                    issueDesc = {
                                        "display":"y",
                                        "map":"ISSUE_DESC",
                                        "width":90
                                    };
                                    // toBeDisplayedCols = _.filter(data.columnTemplates[colsIndex].columns, { 'display': 'y' });
                                    // toNotBeDisplayedCols = _.filter(data.columnTemplates[colsIndex].columns, { 'display': 'n' });
                                    // data.columnTemplates[colsIndex].columns = [];
                                    // data.columnTemplates[colsIndex].columns = [...toBeDisplayedCols, ...toNotBeDisplayedCols];
                                    // data.columnTemplates[colsIndex].columns.splice(index-1, 0, header);
                                    // data.columnTemplates[colsIndex].columns.splice(index, 0, stdDesc);

                                    _.each(data.columnTemplates,(v,k)=>{
                                        data.columnTemplates[k].columns.splice(index+1, 0,header);
                                        data.columnTemplates[k].columns.splice(index+2, 0,stdDesc);
                                        data.columnTemplates[k].columns.splice(index+3, 0,issueDesc);
                                        data.columnTemplates[k].columns.splice(index+4, 0,shortDesc);
                                    })
                                } else {
                                    /*  data.header.splice(index-1, 0, header);
                                    data.header.splice(index, 0, stdDesc);*/
                                    data.header.splice(index+1, 0,header);
                                    data.header.splice(index+2, 0,stdDesc);
                                    data.header.splice(index+3, 0,issueDesc);
                                    data.header.splice(index+4, 0,shortDesc);
                                }

                                if((data.header.filter(i => i.map === 'SCENARIO').length === 0 && 
                                    data.header.filter(j => j.map === 'SCENARIO_DESC').length === 0)
                                        && angular.element('#display_scenario')[0]){
                                    var scenario = {
                                        "data_type":"string",
                                        "display":"n",
                                        "editable":"n",
                                        "label":"Scenario",
                                        "map":"SCENARIO",
                                        "validation":null,
                                        "width":90,
                                        "header_class":"issueColumns",
                                    };   
                                    data.header[data.header.length] = scenario;
                                        data.data.map(i => i.ISSUE_KEY > 0 ? 
                                            i.SCENARIO = angular.element('#display_scenario')[0].innerHTML.replace(/&amp;/g, '&') : 
                                            i.SCENARIO = 'Return As Filed');                
                                }
                                data.settings.fixed_columns = data.settings.fixed_columns + 4;


                            }

                        }                          
                        data.data.map(i => i.SCENARIO_TYPE_CODE = data.settings.display_issue_columns === 1 ? 'EXAM' : '');

                        // addition of issue cols -- end
                        ggData.headerArray =  data.header;
                        ggData.dataArr =  data.data;
                        // need this to save manual pivot list item deletion..
                        var pivotDelTriggeredVal =  workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data.pivotListDelTriggered;
                        if(data.firstLoad == 0){
                            ggData.dataArr = data.data  && data.data.length>0 ? _.each(data.data, function(item){item.isFiltered = 'N';}) :  data.data;
                            //this is used to handle the pivot panel open and close based on tabs switched/ panel array list..
                            if((data.pivotRowList && data.pivotRowList.length > 0) || (data.pivotValList && data.pivotValList.length > 0) || (data.pivotColumn && data.pivotColumn.length > 0)) {
                                $rootScope.$broadcast('applyPivotPanel',data.pivotGroup, false);
                            } else if(((data.pivotRowList && data.pivotRowList.length == 0) || (data.pivotValList && data.pivotValList.length == 0) || (data.pivotColumn && data.pivotColumn.length == 0)) && (pivotDelTriggeredVal !=undefined && pivotDelTriggeredVal == true)){
                                $rootScope.$broadcast('applyPivotPanel',data.pivotGroup, false);
                            } else {
                                $rootScope.$broadcast('applyPivotPanel',data.pivotGroup, true);
                            }
                       }else{
                           ggData.dataArr =   data.data;
                           ggData.pivotGroup = {};
                            
                       }  
                                     
                        ggData.tableState = data.tableState;
                        ggData.cellData = data.cellData;
                        ggData.selected = data.selected;
                        //data.settings.hasPivot = 1;// this is similar meaing flag to ctrl.showPivot//
                        ggData.settings = data.settings;
                        ggData.vDragg = data.vDragg;
                        ggData.hDragg = data.hDragg;
                        ggData.batchSelect =  data.batchSelect;
                        ggData.fromModal = data.fromModal;

                        ggData.messageObject = [];
                        ggData.userAccessLevel = (USER_SETTINGS.user.system_access_level) ? USER_SETTINGS.user.system_access_level : 1;
                        ggData.sso_id = USER_SETTINGS.user.sso_id;
                        ggData.hasActionSelected = data.hasActionSelected;
                        for (var i = 0; i < ggData.headerArray.length; i++) {
                            ggData.headerArray[i]['object_id']  = i.toString();
                        }
                        // var pivotGroup = {
                        //     groupRows: ["LEID"],//["LEID","MARS"],//,
                        //     columnToRows:["IS_BS_PL","SYSTEM_ACCT_TYPE_DESC"],
                        //    /*  groupRows: ["IS_BS_PL"],
                        //     columnToRows:["SYSTEM_ACCT_TYPE_DESC"], */
                        //     agg:{BOY_BALANCE:"sum",BOOK_AMT:"sum"},
                        //     collapsed: [ false, false]
                        // }
                        // data.pivotGroup = pivotGroup;
                        ggData.pivotGroup = data.pivotGroup;
                        /* convert rows to columns */
                        /*  case 1 ::::::agg columns, column dimension and row Dimensions 
                            case 2::::::: agg columns, column dimensions*/
                        if((data.pivotGroup && !_.isEmpty(data.pivotGroup) && 
                            ((data.pivotGroup.columnToRows && data.pivotGroup.columnToRows.length>0)||
                            (data.pivotGroup.groupRows && data.pivotGroup.groupRows.length>0)))&& workspaceFactory.activeScreen.data.pivotdataArr && 
                            workspaceFactory.activeScreen.data.pivotdataArr.length>0){
                                ggData.dataArr = workspaceFactory.activeScreen.data.pivotdataArr;
                                ggData.headerArray = workspaceFactory.activeScreen.data.pivotheaderArray;
                                ggData.totalRecords = workspaceFactory.activeScreen.data.data.length;
                        }
                    
                        ggApi.hideOverLay();
                        ggApi.loadData({data: ggData});
                        if (ggData.dataArr.length < 1) { // Added for client selection on admin screens for grid data
                            ggApi.gridRefreshPipe([]);
                        }

                        //TURN MESSAGING OFF
                       // ggData.settings.comments = 0;
                       if(workspaceFactory.activeScreen && !_.isEmpty(workspaceFactory.activeScreen.commentLink)
                            && workspaceFactory.activeScreen.commentLink.action_template_key==null && workspaceFactory.activeScreen.commentLink.type == 'comment'){
                        if(selectedActionTemplate && selectedActionTemplate[0]&&selectedActionTemplate[0].action_template_key != workspaceFactory.activeScreen.commentLink.action_template_key){
                            _.each(data.actionTemplates,function(item){
                                if(item.selectedAction==true){
                                    item.selectedAction = false;
                                }

                            });
                         }

                            selectedActionTemplate = _.filter(data.actionTemplates,{selectedAction:true});
                            ggApi.clearSelectedTemplateFromData();

                        }
                       if(selectedActionTemplate && selectedActionTemplate.length>0){
                            if(workspaceFactory.activeScreen.actionGrid!=null){
                                if(selectedActionTemplate[0].action_template_key != workspaceFactory.activeScreen.actionGrid)    {
                                    _.each(data.actionTemplates,function(item){
                                        if(item.selectedAction==true ){
                                            item.selectedAction = false;
                                        }
                                        if(item.action_template_key== workspaceFactory.activeScreen.actionGrid){
                                            item.selectedAction = true;
                                        }

                                        });
                                    selectedActionTemplate =  _.filter(data.actionTemplates,{selectedAction:true});
                                }
                            }
                            workspaceFactory.activeScreen.actionGrid = selectedActionTemplate[0].action_template_key;
                            if(workspaceFactory.activeScreen.commentLink!=null){
                                workspaceFactory.activeScreen.commentLink=null;
                                    ggApi.clearSelectedTemplateFromData();
                            }

                        }

                        /* 2/4/2020 if grid api not return anything  */
                        if(data && data.settings && data.settings.commentType){
                            var type = (data.settings.commentType) ? data.settings.commentType : 'all';
                            getMessages(type);
                          //  getAttachments()
                        }else if(data.callSuccess == 0){
                            AlertService.add("error","Grid is Not returning any data");
                            return;
                        }

                        console.log(" workspaceFactory.activeScreen.gridAction --------ZZZZZZZZZZZZZZZZZZZ------------------------------------ " , workspaceFactory.activeScreen.actionGrid);
                        if(workspaceFactory.activeScreen.actionGrid){
                            console.log("TEST GRID ------------------------------------------------------------------------------------- " , workspaceFactory.activeScreen);
                            $timeout(function() {
                                if(workspaceFactory.activeScreen.actionGrid!=null){
                                    ggApi.goToActionTemplate(workspaceFactory.activeScreen.actionGrid);
                                }
                              workspaceFactory.activeScreen.actionGrid = null;
                            }, 300);
                        }
                        if(workspaceFactory.activeScreen.commentLink){
                            if(workspaceFactory.activeScreen.commentLink.type==='comment'){
                                console.log("YEP THIS TME WE HAVE IT ",  workspaceFactory.activeScreen.commentLink)
                                var table_state = (workspaceFactory.activeScreen.commentLink.table_state) ? workspaceFactory.activeScreen.commentLink.table_state : null;
                                var col_id = (workspaceFactory.activeScreen.commentLink.locationObj) ? workspaceFactory.activeScreen.commentLink.locationObj.col_key : null;
                                var row_id = (workspaceFactory.activeScreen.commentLink.locationObj) ? workspaceFactory.activeScreen.commentLink.locationObj.row_key : null;
                                var type = (workspaceFactory.activeScreen.commentLink.type) ? workspaceFactory.activeScreen.commentLink.type : "link";

                                $timeout(function() {
                                    ggApi.goToComment({type:type,col_id:col_id,row_id:row_id, tableState:table_state } );
                                    workspaceFactory.activeScreen.data.tableState=table_state;
                                    workspaceFactory.activeScreen.commentLink = null;
                                    console.log("should be null ", workspaceFactory.activeScreen.commentLink);
                                }, 300);
                            }
                            if(workspaceFactory.activeScreen.commentLink.type!=undefined){
                                workspaceFactory.activeScreen.commentLink.filters = null;
                            }
                        }
                       
                });

                    scope.$on('loadGridMessages', function (event, type) {
                        getMessages(type);
                    });

                    function getAttachments(){

                     //   console.log("===============================", type)
                        var params = {};
                       // var type = (workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data.settings.commentType) ? workspaceFactory.activeScreen.data.settings.commentType : 'all';
                        var filterCached =  (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                        /* if(type === 'none'){
                            ggApi.loadAttachments([],'none');
                            return true;
                        }  */
                      //  params.type = type;
                        params.filters = JSON.stringify(filterCached);
                        params.screen_key =  workspaceFactory.activeScreen.screen_key;

                        console.log()
                        MessageFactory.getComments(GENERAL_CONFIG.base_url + '/getAttachmentByContext',params).then(function (data) {
                            if (data.callSuccess === "1"){
                                console.log("----ATTACHEMNT CONTEXT-------",data);
                                ggApi.loadAttachments(data.attachmentLocations);


                            }
                        });


                    }

                    function getMessages(type){
                        //ggApi.loadComments([],'none');

                        console.log("===============================", type)

                        var params = {};

                        var filterCached =  (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};

                        if(!filterCached){
                                    ggApi.loadComments([],'none')
                                   // ggApi.loadAuditCells([],'none');
                                    return true;
                                }
                                params.type = type;
                            params.filters = JSON.stringify(filterCached);
                                params.screen_key =  workspaceFactory.activeScreen.screen_key;
                                MessageFactory.getComments(GENERAL_CONFIG.base_url + '/getCommentByContext',params).then(function (data) {
                                    if (data.callSuccess === "1"){
                                        ggApi.loadComments(data.commentLocations,type);
                                         ggApi.loadAuditCells(data.auditLocations,"grid");
                                    }
                                });
                            }

                    element.on('$destroy', function () {
                        scope.$destroy();
                    });

                    scope.$on('releaseData', function (event, show) {
                        scope.ggData = {}
                        ggApi.releaseData();
                    });


                    scope.$on("$destroy",function() {
                        scope.ggData = {};
                        ggApi.destroy();
                        ggGrid = null;
                    });

                }
            };
        }])

         /* dvalaboju001 8/25/2020 pivot rows and nested rows directive*/
         .directive("pivotRows",function(){
            return {
                restrict: "AE",
                require: '^?pivotHeader',
                controllerAs: 'ctrl',
                scope:{
                    headers: '=',
                    callbackModel: '=?',
                    callbackFn: '&',
                    fromColumns: '=',
                    dropDownFld: '='
                },
                controller:['$scope',function($scope){
                    $scope.callbackModel = $scope;
                    $scope.headersData = $scope.headers;
                    console.log("scope.callbackModel",$scope.callbackModel);
                   /*  $timeout(function () {
                        $scope.callbackFn();
                    }, 10); */
                    this.setSelected = function(message){
                        $scope.callbackModel = message;
                    }
                    console.log("scope.callbackModel",$scope.callbackModel);
                }],
                link: function (scope, element, attrs,ctrl){
                    console.log("ctorl pivot rows", ctrl);
                    scope.callbackModel = ctrl;
                    console.log("from pivto rows callbca",scope);

                },
                template: "<ul style='padding-left: 10px;'><div pivot-header fromcolumns=fromColumns dropdownf=dropDownFld drpheadervalues='headersData' data='header' ng-repeat='header in headers'></div></ul>"

            }

        })
      
    return directives;

});
