define([
  'angular'

], function () {
  'use strict';

  var services =  angular.module('app.firLocalSysChartAcctMapService',[])
      .factory("FirLocalSysChartAcctMapcGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
          ,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG'
         
          ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                  ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
    	  var factory = {};
          var returnObj = {};
          var firLocalSysChartAcctMapcGroupObjFactory ={};
          firLocalSysChartAcctMapcGroupObjFactory.url ={};
          
          firLocalSysChartAcctMapcGroupObjFactory.url.fir_local_to_system_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9h4eue";
          
          firLocalSysChartAcctMapcGroupObjFactory.saveFIRFormDetails = function(_data,Details){

              var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
              var jsonObj = JSON.stringify(Details);
              var jsonSettings =  JSON.stringify(_data);
              //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
              
              var params = filterParams;
              params = _.merge({},  GlobalService.globalParams , params);
              params =  _.extend({jsonObj:jsonObj}, params);
              params =  _.extend({jsonSettings:jsonSettings}, params);
              params.process_name =  "FIR_LOCAL_TO_SYSTEM_SAVE";
              var promise = $http({
                  method: "post",
                  url: firLocalSysChartAcctMapcGroupObjFactory.url.fir_local_to_system_save,
                  data: params
              }).then(function (response) {
                  var status = response.status;
                  if(response.data.callSuccess != "1"){
                      AlertService.add("error", response.data.errorMessage, 4000);
                  }else {
                      return response.data;
                  }
              });
              return promise;
        };
          
          return firLocalSysChartAcctMapcGroupObjFactory;
      }]);

  return services;
});