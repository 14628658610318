define(["angular"], function () {
    "use strict";

    var controllers = angular
        .module("app.sourcingOverrideController", [])
        .controller("sourcingOverrideController", [
            'GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'scenarioSetupActionsService', '$filter', 'TAX_YEARS', 'rowData', 'gridData',
            sourcingOverrideController,
        ]);

    function sourcingOverrideController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, scenarioSetupActionsService, $filter, TAX_YEARS, rowData, gridData) {
        var vm = this;
        vm.modalTitle = "Default Sourcing Override";
        vm.isEdit = false;
        vm.showEntityLoader = true;


        vm.overrideEntity = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            HO_LEID_RP: '',
            BASKET: '',
            FSI_KEY: '',
        }
        if (rowData) {
            vm.isEdit = true;
            vm.overrideEntity = rowData;
            vm.overrideEntity.FSI_KEY = rowData.DEF_FSI_KEY;
            vm.overrideEntity.BASKET = rowData.OVERRIDE_DEFAULT_BASKET;
        }
        vm.dateOptions = {};
        vm.isSaving = false;
        vm.overrideEntity_backup = vm.overrideEntity;

        vm.fetchDropdowns = function () {
            let filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params = {
                "tax_year": filterParams.tax_year,
                "scenario": filterParams.scenario,
                "jcd_key": filterParams.jcd_key,
                "sso_id": USER_SETTINGS.user.sso_id
            };
            let Promise1 = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=u99vya', params);
            let Promise2 = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=ms4tvf', params);
            Promise.all([Promise1, Promise2]).then((response) => {
                vm.ho_leid_RP_options = response[0].jsonObject;
                vm.basket_options = response[1].jsonObject;
                vm.showEntityLoader = false;
                $scope.$digest()


            }).catch((e) => {
                console.log(e);
                AlertService.add("error",
                    e, 4000);
                vm.showEntityLoader = false;
                $scope.$digest();
            }).finally(() => {

            })
        }
        vm.fetchDropdowns();

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.updateBasket = function () {
            let selectedOption = vm.basket_options.find(function (option) {
                return option.BASKET === vm.overrideEntity.BASKET;
            });

            // Update the FSI_KEY value  
            vm.overrideEntity.FSI_KEY = selectedOption.FSI_KEY;

        }

        vm.reset = function () {
            vm.overrideEntity = vm.overrideEntity_backup;
        }

        vm.isValid = function () {
            if (vm.overrideEntity.TAX_YEAR == null ||
                vm.overrideEntity.TAX_YEAR == undefined ||
                vm.overrideEntity.TAX_YEAR == "") {
                vm.overrideEntity.TAX_YEAR_NOT_VALID = true;
                return false;
            }
            if (vm.overrideEntity.HO_LEID_RP == null ||
                vm.overrideEntity.HO_LEID_RP == undefined ||
                vm.overrideEntity.HO_LEID_RP == "") {
                vm.overrideEntity.HO_LEID_RP_NOT_VALID = true;
                return false;
            }
            if (vm.overrideEntity.BASKET == null ||
                vm.overrideEntity.BASKET == undefined ||
                vm.overrideEntity.BASKET == "") {
                vm.overrideEntity.BASKET_NOT_VALID = true;
                return false;
            }

            if (vm.overrideEntity.FSI_KEY == null ||
                vm.overrideEntity.FSI_KEY == undefined ||
                vm.overrideEntity.FSI_KEY == "") {
                vm.overrideEntity.FSI_KEY_NOT_VALID = true;
                return false;
            }

            return true;
        }

        vm.save = function () {
            if (!vm.isValid()) {
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.showEntityLoader = true;
            vm.overrideEntity.EDIT_FLAG = vm.isEdit ? 'U' : 'I';


            let filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                "tax_year": filterParams.tax_year,
                "scenario": filterParams.scenario,
                "jcd_key": filterParams.jcd_key,
                "sso_id": USER_SETTINGS.user.sso_id
            };
            jsonSettings = JSON.stringify(jsonSettings);


            let jsonObj = JSON.stringify(vm.overrideEntity);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                clob_data: jsonObj
            }, params);
            params = _.extend({
                clob_settings: jsonSettings
            }, params);
            params.sso_id = USER_SETTINGS.user.sso_id;
            params.process_name = 'SAVE_HO_DEF_SRC';
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=y45hps";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.showEntityLoader = false;

                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Data has been updated successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error", data.errorMessage, 4000);
                }
            });


        };
    }
    return controllers;
});