define([
    'angular',
    './QrevarianceExplainController',
    './QrevarianceExplainService'



], function () {
    'use strict';
    return angular.module('app.qrevarianceExplain', ['app.qrevarianceExplainController','app.qrevarianceExplainService'] );


});