define(
    [ 'angular'

        ],
        function() {
        'use strict';

        var controllers = angular.module('app.controversyCtrl', [])
        .controller('controversyCtrl', [ controversyCtrl])


        function controversyCtrl() {

        }
        return controllers;
    })