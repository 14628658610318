
define([
    'angular',
    './SettingsController'


], function () {
    'use strict';
    return angular.module('app.settings', ['app.settingsController'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider
                .state('settings', {
                    url: '/settings',
                    templateUrl: 'app/components/settings/settings-tpl.html',
                    data:{ncyBreadcrumbLabel: 'Settings'},
                    access:""
                })
        }])

});