define([
    'angular'
], function () {
    'use strict';

    var controllers =  angular.module('app.trainingVideosController',[])
        .controller('TrainingVideosController',['$scope','$rootScope','AlertService','$sce','$http','USER_SETTINGS','GENERAL_CONFIG', trainingVideosController]);

        function trainingVideosController($scope,$rootScope,AlertService,$sce,$http,USER_SETTINGS,GENERAL_CONFIG) {
            $scope.pageTitle = "Training Videos"
            $scope.isLoading =true;
            $scope.videoList=[];
            $scope.videoSource="";
            $scope.videoSourceBase=GENERAL_CONFIG.admin_api_url + '/video/';

            this.$onInit = function() {
                $scope.loadTrainVideo();
            };
            $scope.$on('traingingVideoClicked', function(event, args) {
                $scope.loadTrainVideo();
            });

            $scope.loadTrainVideo = () => {
                $scope.isLoading = true;
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/getHelpVideos'
                }).then(function (response) {
                    console.log(response);

                    if(response.data.callSuccess != "1"){
                        AlertService.add("error", response.data.errorMessage, 4000);
                        return;
                    }

                    $scope.isLoading =false;
                    $scope.videoList= response.data.data;
                    if($scope.videoList.length > 0){
                        $scope.videoSource=$sce.trustAsResourceUrl($scope.videoSourceBase+$scope.videoList[0].video_id+"?client_key="+sessionStorage.getItem('client_key'));
                    }

                });
            };

            $scope.changeVideoLink = (id,index) => {
                console.log('change video link',id, index);
                $scope.videoSource=$sce.trustAsResourceUrl($scope.videoSourceBase+id+"?client_key="+sessionStorage.getItem('client_key'));
                // document.getElementById('gtw-video').load();

            }
        }

        return controllers;

});
