define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.Form1118SchdKController', [])
					.controller('scheduleKCtrl', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'Form1118SchdKFactory','$timeout', scheduleKCtrl])						    
			function scheduleKCtrl($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, Form1118SchdKFactory,$timeout) {
				var vm = this;
				
				//Get the Loggin USER settings 
				vm.userSettings = USER_SETTINGS;
				//Set the Loggin USER settings details
				vm.logged_in_user = vm.userSettings.user.sso_id;
				//Form Lines passed to the UI
				vm.groupedFormLines;
				//JCD Key for the given custom screen
				vm.jcd_key;
				//JCD TA Key for the given custom screen
				vm.jcd_ta_key;
				//Hard-coded Basket value
				vm.sourceKey;
				//Show the spinner
				vm.showSpinner = true;
				//Flag for Is_Admin check
				vm.isAdmin = false; 
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//Current screen's activity name
				vm.activity_name = workspaceFactory.activity.label;
				//Get the Grid filter Scenario
				vm.scenario = GlobalService.globalParams.scenario;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;

				//Get the selected Current Year 
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {"tax_year":GlobalService.globalParams.tax_year};
				vm.curentyear = filterParams.tax_year;

				vm.filteredTaxYearData = [];	
				vm.filterLoading = false;
				vm.selectedScenario;
				vm.showBanner = true;
				
				//Redirect the URL to a screen based on workspace url link
				vm.redirectUrl = function(selectedScreenLink){	
					var redirectLink = 'workspace.'+vm.activity_name+'-1118-'+selectedScreenLink;					
					$state.transitionTo(redirectLink.toLowerCase(), null, {'reload':true});					
				}

				//Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {	
					vm.scenarioLoading = true;
					//resetFilingGroup();
					var params = {"action_code" : '7q0wiy',"tax_year" : vm.selectedTaxYear.value};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;		
						vm.selectedScenario = vm.scenarioDropDown[0];	
						vm.getFilingGroupDropdown();
						vm.scenarioLoading = false;	
						vm.filterLoading = false;		
					});					
				};

				//Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {
					vm.filteredTaxYearData = GlobalService.inputs.tax_year.values;
					vm.selectedTaxYear = GlobalService.inputs.tax_year.selected;	
					vm.getScenarioDropDown();		
					// var params = {"action_code" : 'e83e1x',};					
					// JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
					// 	let taxYearData = _.filter(data.jsonObject, function(p){
	                // 		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                // 	});							
					// 	for(var indx in taxYearData){
					// 		if(taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
					// 				&& (taxYearData[indx].CODE_DISP_DESC <= (vm.curentyear) && taxYearData[indx].CODE_DISP_DESC >= (vm.curentyear-5))){
					// 			vm.filteredTaxYearData.push(taxYearData[indx]);			
					// 		}
					// 	}
					// 	vm.filteredTaxYearData = vm.filteredTaxYearData.reverse();
					// 	vm.selectedTaxYear = vm.filteredTaxYearData[0];
					// 	vm.getScenarioDropDown();		
					// });					
				};
				vm.getTaxYearDropDown();

				//Get the Filing Group dropdown
				vm.getFilingGroupDropdown = function() {	
					if (vm.selectedTaxYear && vm.selectedScenario) {
						vm.filingGrpLoading = true;
						// Reset Filer entity first
						//resetFilerEntity();
						var params = {"action_code" : '0udsuc',"tax_year" : vm.selectedTaxYear.value,
						"scenario" : vm.selectedScenario.VALUE};					
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.filingGroupDropdown =  data.jsonObject;	
							//vm.filingGroupDropdown.unshift({"VALUE":"","LABEL":"Select"});	
							vm.selectedFG = vm.filingGroupDropdown[0];						
							vm.filingGrpLoading = false;
							vm.getFilerEntityDropdown();
					});	
					}			
				};

				//Get the Filer Entity dropdown
				vm.getFilerEntityDropdown = function() {		
					//resetFilerEntity();
					if (vm.selectedFG.VALUE != '') {
						vm.filerEntityLoading = true;
						var params = {"action_code" : '35gc5l',"tax_year" : vm.selectedTaxYear.value,
						"scenario" : vm.selectedScenario.VALUE, "filing_group_key" : vm.selectedFG.VALUE};					
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							let filerEntityArr = data.jsonObject;
							vm.filerEntityDropdown =  Object.assign([], _.filter(filerEntityArr, {CONSOL_GROUP_KEY: vm.selectedFG.VALUE}));	
							vm.selectedFE = vm.filerEntityDropdown[0];	
							//vm.filerEntityDropdown.unshift({"FILER_LE_KEY":"","FILER_LE":"Select"});
							vm.filerEntityLoading = false;
						});		
					}	
				};
				
				$scope.$watch(function(){ return workspaceFactory.screens}, function(val){
		            vm.workspaces = workspaceFactory.getScreen();
		            $timeout(function() {
		                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
		                    vm.scrlTabsApi.init();
		                }
		            });
		        });
			
			  vm.tabs = [];
		        vm.updateFn = function(arg){
		            var obj = [];
		            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
		            vm.setTab(obj);
		        }

				function resetFilingGroup() {
					resetFilerEntity();
					vm.filingGroupDropdown = [{"VALUE":"","LABEL":"Select"}];
					vm.selectedFG = vm.filingGroupDropdown[0];	
				}

				function resetFilerEntity() {
					vm.filerEntityDropdown = [{"FILER_LE_KEY":"","FILER_LE":"Select"}];
					vm.selectedFE = vm.filerEntityDropdown[0];	
				}

				vm.refresh = function() {
					console.log("filter clicked");
					if (vm.selectedFE == undefined || vm.selectedFE.FILER_LE_KEY == '') {
						AlertService.add("error", "Please select all the filters.",4000);
						return;
					}
					vm.showBanner = false;
					vm.showSpinner = true;
					vm.getFormColumnDescriptions();
					vm.getFormLinesAndDescriptions();
				}	
				
				
				//Get the Schd-K Column Descriptions
				vm.getFormColumnDescriptions = function() {
					var params = {"action_code" : 'gwo2xj', 'tax_year' : vm.selectedTaxYear.value};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.formColumns = data.jsonObject;
							//console.log("vm.formColumns: - - > ",vm.formColumns);
					}); 
				}
				//vm.getFormColumnDescriptions();
				
				//Get the Schd-K Line Descriptions
				vm.getFormLinesAndDescriptions = function() {					
					if(vm.activity_name == 'General'){
						vm.basketToFetch = 'GENLIM';
					}else if(vm.activity_name == 'Passive'){
						vm.basketToFetch = 'PASSIVE';
					}else if(vm.activity_name == 'Branch'){
						vm.basketToFetch = 'BRANCH';
					}else if(vm.activity_name == 'GILTI'){
						vm.basketToFetch = 'GILTI';
					}else if(vm.activity_name == 'Treaty'){
						vm.basketToFetch = 'TREATY';
					}else if(vm.activity_name == '901j'){
						vm.basketToFetch = '901j';
					}
					
					var params = {
						"action_code": 'gz06qo', "basket": vm.basketToFetch, "jcd_key": vm.jcd_key, "jcd_ta_key": vm.jcd_ta_key,
						"tax_year": vm.selectedTaxYear.value, "scenario": vm.selectedScenario.VALUE, "filer_entity_key": vm.selectedFE.FILER_LE_KEY
					};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.formLines = data.jsonObject;							
					}); 
				}				
				//retrieve the Line_No and Column_No(s)
				//vm.getFormLinesAndDescriptions();
				$scope.$watchGroup(['filterLoading', 'filingGrpLoading', 'filerEntityLoading'], function(newValues, oldValues, scope) {
					if (vm.filterLoading == true || vm.filingGrpLoading == true || vm.filerEntityLoading == true) {
						vm.showBanner = true;
					}
					//vm.showBanner = newValues.some(function(value) {return value == true;});
					console.log('watchgroup: ' + vm.showBanner);
				});
				
				
				$scope.$watch(function() { return JCDFactory.JCDList },   function() {
					if(JCDFactory.JCDList!=undefined && JCDFactory.JCDList!=null && JCDFactory.JCDList.length > 0){											
						var b = _.find(JCDFactory.JCDList, function(o) { return (o.TASK_NAME === workspaceFactory.activity.parent.label && o.ACTIVITY_NAME.includes(workspaceFactory.activity.label)); });							
						//Get the JCD Core details
						vm.jcd_key = b.JCD_KEY;
						vm.jcd_ta_key = b.JCD_TA_KEY;
						//vm.task_name = b.TASK_NAME;	
					}
				});
				
				//Load the System Account Details
				$scope.$watch(function() { return vm.formLines },   function() {
					
					if(vm.formLines!=undefined && vm.formLines!=null && vm.formLines.length > 0){
						var init = 0;						
						//assign the is_admin flag
						if(vm.formLines[0].IS_ADMIN == 'Y'){
							vm.isAdmin = true;
						}
												
						vm.groupedFormLines = _.reduce(
								vm.formLines, 
							    function(memo, property) {
							        // Find the correct bucket for the property
							        var bucket = _.find(memo, {
							        	SCHD_KEY: property.SCHD_KEY,	
							        	LINE_KEY: property.LINE_KEY, 
							        	LINE_NO: property.LINE_NO,
							        	LINE_DESCRIPTION: property.LINE_DESCRIPTION,
							        	LINE_ORDER: property.LINE_ORDER,							        	
							        	BASKET_KEY: property.BASKET_KEY,	
							        	BASKET_DESC: property.BASKET_DESC							        							        	
							        });							        
							        //console.log("bucket :", bucket);
							        
							        // Create a new bucket if needed.
							        if (!bucket) {
							            bucket = {
							            		SCHD_KEY: property.SCHD_KEY,	
									        	LINE_KEY: property.LINE_KEY,
									        	LINE_NO: property.LINE_NO,
									        	LINE_DESCRIPTION: property.LINE_DESCRIPTION,
									        	LINE_ORDER: property.LINE_ORDER,							        	
									        	BASKET_KEY: property.BASKET_KEY,	
									        	BASKET_DESC: property.BASKET_DESC,									        	
										    	items: []};
							            //if(bucket.LINE_NO != 'N/A'){
							            	memo.push(bucket);
							            //}
							        }
							        //console.log("memo :", memo);
							        
							        var attribs = {
							        		SCHD_KEY: property.SCHD_KEY,	
								        	LINE_KEY: property.LINE_KEY, 
								        	BASKET_KEY: property.BASKET_KEY,
								        	COL_KEY: property.COL_KEY,
								        	COL_DESCRIPTION: property.COL_DESCRIPTION,
								        	COL_ORDER: property.COL_ORDER,
							        		IS_ENABLED: property.IS_DISPLAY,							        		
							        		ATTRIB_AMOUNT: property.AMOUNT
							        };
							        //console.log("attribs :", attribs);
							        if( init == 0 && (attribs.BASKET_KEY != undefined && attribs.BASKET_KEY != null && attribs.BASKET_KEY != '')){
							        	init = 1;
							        	vm.sourceKey = attribs.BASKET_KEY;
							        }
							        if(attribs.IS_ENABLED == 'N'){
							        	//attribs.ATTRIB_AMOUNT = null;
							        	attribs.OPERATION_TYPE = null;
							        }
							        if(attribs.ATTRIB_AMOUNT != null){
							        	attribs.OPERATION_TYPE = 'U';
							        }							        
							        // Add the property to the correct bucket
							        bucket.items.push(attribs);
							        return memo;
							    }, 
							    [] // The starting memo
							);
						//console.log("vm.groupedFormLines: ",vm.groupedFormLines);
						
						//console.log("vm.sourceKey: - - > ",vm.sourceKey);
						vm.addTotals();
						vm.showSpinner = false;
						//console.log("vm.showSpinner: - - > ",vm.showSpinner);
					}					
				});
				
				//Reset the form
				vm.reset = function(){					
					for(var d in vm.groupedFormLines){						
						for(var a in vm.groupedFormLines[d].items){							
							//console.log(" reset : ",vm.groupedFormLines[d].items[a]);
							vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT = null;
						}
					}
					vm.getFormLinesAndDescriptions();
				};
				
				//Calculate Totals of the amount entered for all preceding tax_years
				vm.addTotals = function(){					
					for(var d in vm.groupedFormLines){
						//Calculate Line# 3 amounts
						if(vm.groupedFormLines[d].LINE_NO.startsWith("3")){
							
							var index1 = _.findIndex(vm.groupedFormLines, { LINE_NO: '1.' });
							var index2a = _.findIndex(vm.groupedFormLines, { LINE_NO: '2a.' });
							var index2b = _.findIndex(vm.groupedFormLines, { LINE_NO: '2b.' });
							var index3 = _.findIndex(vm.groupedFormLines, { LINE_NO: '3.' });
							var index4 = _.findIndex(vm.groupedFormLines, { LINE_NO: '4.' });
							var index5 = _.findIndex(vm.groupedFormLines, { LINE_NO: '5.' });
							var index6 = _.findIndex(vm.groupedFormLines, { LINE_NO: '6.' });
							var index7 = _.findIndex(vm.groupedFormLines, { LINE_NO: '7.' });
							var index8 = _.findIndex(vm.groupedFormLines, { LINE_NO: '8.' });
							
							var i;
							//console.log(" length:: ", vm.groupedFormLines[index3].items.length);
							for (i = 0; i<vm.groupedFormLines[index3].items.length ; i++) { 
								
								if(vm.groupedFormLines[index3].items[i].IS_ENABLED == 'Y'){
									vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT = ((vm.groupedFormLines[index1].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index1].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index1].items[i].ATTRIB_AMOUNT : 0)
																					+ ((vm.groupedFormLines[index2a].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index2a].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index2a].items[i].ATTRIB_AMOUNT : 0)  
																					+ ((vm.groupedFormLines[index2b].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index2b].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index2b].items[i].ATTRIB_AMOUNT : 0);
									//console.log(" COL_DESC3:: ", vm.groupedFormLines[index3].items[i].COL_DESCRIPTION+" : "+ vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT);
									if(vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT == 0){
										vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT = null;
									}
								}else{
									vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT = null;
								}
								
								if(vm.groupedFormLines[index8].items[i].IS_ENABLED == 'Y'){
									vm.groupedFormLines[index8].items[i].ATTRIB_AMOUNT = ((vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index3].items[i].ATTRIB_AMOUNT : 0)
																					+ ((vm.groupedFormLines[index4].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index4].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index4].items[i].ATTRIB_AMOUNT : 0)
																					+ ((vm.groupedFormLines[index5].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index5].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index5].items[i].ATTRIB_AMOUNT : 0)
																					+ ((vm.groupedFormLines[index6].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index6].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index6].items[i].ATTRIB_AMOUNT : 0)
																					+ ((vm.groupedFormLines[index7].items[i].ATTRIB_AMOUNT != undefined || vm.groupedFormLines[index7].items[i].ATTRIB_AMOUNT!=null) ? vm.groupedFormLines[index7].items[i].ATTRIB_AMOUNT : 0);
									
									//console.log(" COL_DESC8:: ", vm.groupedFormLines[index8].items[i].COL_DESCRIPTION+" : "+ vm.groupedFormLines[index8].items[i].ATTRIB_AMOUNT);
									if(vm.groupedFormLines[index8].items[i].ATTRIB_AMOUNT == 0){
										vm.groupedFormLines[index8].items[i].ATTRIB_AMOUNT = null;
									}
								}else{
									vm.groupedFormLines[index8].items[i].ATTRIB_AMOUNT = null;
								}
							
							}
							
						}
								
						
						var totalAmount = 0;
						for(var a in vm.groupedFormLines[d].items){		
							//console.log("--> >:Line: ", vm.groupedFormLines[d]);
							//console.log("--> >:items: ", vm.groupedFormLines[d].items[a]);
							if(vm.groupedFormLines[d].items[a].COL_DESCRIPTION != 'Totals'){
								totalAmount = totalAmount + (vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT==null?0:vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT);
							}						
						}						
						var index = _.findIndex(vm.groupedFormLines[d].items, { COL_DESCRIPTION: 'Totals' });
						if(totalAmount == 0){
							vm.groupedFormLines[d].items[index].ATTRIB_AMOUNT = null;
						}else{
							vm.groupedFormLines[d].items[index].ATTRIB_AMOUNT = totalAmount;		
						}
					}	
				};
				
				//Save the Amounts
				vm.saveGeneralBasket = function(){			
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var dataToSave = [];					
					//Validation to have All amounts entered
					/*for(var d in vm.groupedFormLines){						
						for(var a in vm.groupedFormLines[d].items){							
							if(vm.groupedFormLines[d].items[a].IS_ENABLED == 'Y' && vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT == null){
								 //console.log("Empty for ",vm.groupedFormLines[d].items[a].COL_DESCRIPTION);
								 AlertService.add("error", "Please enter valid amounts in all the fields. ",4000);
								 vm.isSaveClicked = false;
								 return;
							}
						}
					}*/					
					
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;	
					returnClobSettingsObj['jcd_key'] = vm.jcd_key;	
					returnClobSettingsObj['jcd_ta_key'] = vm.jcd_ta_key;
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					for(var d in vm.groupedFormLines){						
						for(var a in vm.groupedFormLines[d].items){							
							//console.log(" >  >  >  >  >  >  >  > ",vm.groupedFormLines[d].items[a]);
														
							var returnObj = {};		
							returnObj["AMOUNT"] = vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT;
							returnObj["REASON"] = null;							
							returnObj["SCHD_KEY"] = vm.groupedFormLines[d].items[a].SCHD_KEY;		
							returnObj["LINE_KEY"] = vm.groupedFormLines[d].items[a].LINE_KEY;
							returnObj["COL_KEY"] = vm.groupedFormLines[d].items[a].COL_KEY;							
							if(vm.groupedFormLines[d].items[a].BASKET_KEY == undefined || vm.groupedFormLines[d].items[a].BASKET_KEY == null || vm.groupedFormLines[d].items[a].BASKET_KEY == ''){
								returnObj["BASKET_KEY"] = vm.sourceKey;
							}else{
								returnObj["BASKET_KEY"] = vm.groupedFormLines[d].items[a].BASKET_KEY;
							}
							if(vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT != undefined 
									&& vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT !=null 
										&& vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT != 0 
											&& vm.groupedFormLines[d].items[a].OPERATION_TYPE == 'U'){								
								returnObj["OPERATION_TYPE"] = vm.groupedFormLines[d].items[a].OPERATION_TYPE;								
							}else if(vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT !=null && vm.groupedFormLines[d].items[a].OPERATION_TYPE == null){
								returnObj["OPERATION_TYPE"] = 'I';
								vm.groupedFormLines[d].items[a].OPERATION_TYPE = 'U';
							}else if((vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT == undefined 
										|| vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT == null 
											|| vm.groupedFormLines[d].items[a].ATTRIB_AMOUNT == 0) && vm.groupedFormLines[d].items[a].OPERATION_TYPE == 'U'){
								returnObj["OPERATION_TYPE"] = 'D';
							}								
							//console.log("returnObj::FNL:: ",returnObj);	
							
							if(returnObj.OPERATION_TYPE!=null){
								dataToSave.push(returnObj);
							}
						}
					}		
					
					//console.log("dataToSave-----" + dataToSave);
					var message = "Form 1118 Schedule-K Amount(s) have been saved/updated";	
					//send to save the Data					
					sendDetails(returnClobSettingsObj, dataToSave, message);
								
				}

				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, returnClobDataObj, message) {
					Form1118SchdKFactory.saveBasketAmounts(returnClobSettingsObj, returnClobDataObj, vm.jcd_key, 
						vm.selectedTaxYear.value, vm.selectedScenario.VALUE + '', vm.selectedFE.FILER_LE_KEY
						, vm.selectedFE.FILER_COMBINATION_KEY).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							vm.isSaveClicked = false;
	                     };
				 	});
				}
				}

						
			return controllers;
		});