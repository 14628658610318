define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.sltApiDataService', [])
    .factory("sltApiDataFactory", ['$http', 'GENERAL_CONFIG', 'AlertService', function ($http, GENERAL_CONFIG, AlertService) {
        const sltApiDataFactory = {};

        var URLS = {
            testDataServiceAction: GENERAL_CONFIG.base_url + "/v1/dataservices/data/json?"
        }

        sltApiDataFactory.testDataService = function(inParams) {
            let paramsData = '';
            inParams.forEach(element => {
                paramsData += element.urlparam + "=" + element.value + "&";
            });
            paramsData = paramsData.substring(0, paramsData.length - 1);
            var promise = $http({
                method: "GET",
                url: URLS.testDataServiceAction + paramsData
            }).then(function(response) {
                // if(response.data.callSuccess === "1") {
                    return response.data;
                // } else {
                //     AlertService.add("error", "No slt api data list found", 4000);
                // }
            });
            return promise;
        }

        return sltApiDataFactory;
    }]);


    return services;
});