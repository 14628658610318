/**
 * Created by 212544474 on 2/17/2016.
 */

define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.wwnimappingService', [])

					.factory(
							"WWniGroupObjFactory",
							[
									'$q',
									'$http',
									'$stateParams',
									'$timeout',
									'$rootScope',
									'$log',
									'AlertService',
									'GlobalService',
									'$injector',
									'JsonObjectFactory',
									'DataFiltersFactory',
									'workspaceFactory',
									'USER_SETTINGS',
									'GENERAL_CONFIG',
									function($q, $http, $stateParams, $timeout,
											$rootScope, $log, AlertService,
											GlobalService, $injector,
											JsonObjectFactory,
											DataFiltersFactory,
											workspaceFactory, USER_SETTINGS,
											GENERAL_CONFIG) {
										var tbbsServiceW = {};

										var URLS = {
											// FETCH: GENERAL_CONFIG.base_url +
											// '/getProjectTag', // we can use
											// this when we have more URL'S
											SAVE : GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=v3cjp3"
										}
										var userSettings = USER_SETTINGS;
										var logged_in_user = userSettings.user.sso_id;
										var service_table_wwni = $injector
												.get('JsonObjectFactory');
										tbbsServiceW.saveProjectTagW = function(
												_data, _schdMs) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var jsonObj = JSON
													.stringify(_schdMs);
											var jsonSettings = JSON
													.stringify(_data);

											var params = filterParams;
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _.extend({
												jsonObj : jsonObj
											}, params);
											params = _.extend({
												jsonSettings : jsonSettings
											}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "SAVE_WWNI_DETAILS";
											console.log(params);
											var promise = $http({
												method : "post",
												url : URLS.SAVE,
												data : params
											}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										}
										tbbsServiceW.saveWwni = function(
												_callName, _data) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											// var rowData = _data.rowData;

											console.log(_data);
											var jsonObj = [];
											var dummyjson = {};
											var dummyjsonsettings = {};
											var jsonSettings = [];

											var jsonObj = [];
											var jsonSettings = [];
											jsonObj = _data.rowData;

											var objId = _data.rowData.OBJECT_ID;
											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var params = _.merge({},
													GlobalService.globalParams,
													filterParams);

											params.process_name = "WWNI_COMMENT_SAVE";
											params.sso_id = logged_in_user;
											dummyjsonsettings["tax_year"] = GlobalService.globalParams.tax_year;
											dummyjsonsettings["scenario"] = GlobalService.globalParams.scenario;
											dummyjsonsettings["jcd_key"] = GlobalService.globalParams.jcd_key;
											dummyjsonsettings["process_name"] = "WWNI_COMMENT_SAVE";
											dummyjsonsettings["sso_id"] = logged_in_user;
											jsonSettings
													.push(dummyjsonsettings);
											params = _
													.extend(
															{
																jsonSettings : JSON
																		.stringify(jsonSettings)
															}, params);
											params = _.extend({
												jsonObj : JSON
														.stringify(jsonObj)
											}, params);
											var url = GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=d1dvsu";

											service_table_wwni
													.saveJSON(url, params)
													.then(
															function(data) {
																if (data.callSuccess === "1") {
																	AlertService
																			.add(
																					"success",
																					"Data saved Successfully.",
																					4000);
																	var args = {
																		OBJECT_ID : objId,
																		gridFilter : {
																			gridFilter : objId
																		}
																	};
																	$rootScope
																			.$emit(
																					'gridUpdate',
																					args);// $rootScope.$emit('gridUpdate',
																	// args);
																} else {
																	// rowData.ILLEGAL_PAYMENTS
																	// = null;
																	// rowData.SANCTIONED_COUNTRY
																	// = null;
																	AlertService
																			.add(
																					"error",
																					data.errorMessage,
																					4000);
																	$rootScope
																			.$broadcast(
																					'gridRefresh',
																					true);

																}
															});
										}

										tbbsServiceW.saveWwniAsset = function(
												_callName, _data) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											// var rowData = _data.rowData;

											console.log(_data);
											var jsonObj = [];
											var dummyjson = {};
											var dummyjsonsettings = {};
											var jsonSettings = [];

											var jsonObj = [];
											var jsonSettings = [];
											jsonObj = _data.rowData;

											var objId = _data.rowData.OBJECT_ID;
											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var params = _.merge({},
													GlobalService.globalParams,
													filterParams);
											params.process_name = "WWNI_ASSET_LIABILITY_SAVE";
											params.sso_id = logged_in_user;
											dummyjsonsettings["tax_year"] = GlobalService.globalParams.tax_year;
											dummyjsonsettings["scenario"] = GlobalService.globalParams.scenario;
											dummyjsonsettings["jcd_key"] = GlobalService.globalParams.jcd_key;
											dummyjsonsettings["process_name"] = "WWNI_ASSET_LIABILITY_SAVE";
											dummyjsonsettings["sso_id"] = logged_in_user;
											jsonSettings
													.push(dummyjsonsettings);
											params = _
													.extend(
															{
																jsonSettings : JSON
																		.stringify(jsonSettings)
															}, params);
											params = _.extend({
												jsonObj : JSON
														.stringify(jsonObj)
											}, params);
											var url = GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=ld99kg";

											service_table_wwni
													.saveJSON(url, params)
													.then(
															function(data) {
																if (data.callSuccess === "1") {
																	AlertService
																			.add(
																					"success",
																					"Data saved Successfully.",
																					4000);
																	var args = {
																		OBJECT_ID : objId,
																		gridFilter : {
																			gridFilter : objId
																		}
																	};
																	$rootScope
																			.$emit(
																					'gridUpdate',
																					args);// $rootScope.$emit('gridUpdate',
																	// args);
																} else {
																	// rowData.ILLEGAL_PAYMENTS
																	// = null;
																	// rowData.SANCTIONED_COUNTRY
																	// = null;
																	AlertService
																			.add(
																					"error",
																					data.errorMessage,
																					4000);
																	$rootScope
																			.$broadcast(
																					'gridRefresh',
																					true);

																}
															});
										}		
										return tbbsServiceW;

									} ])

			return services;

		});
