define(
    [ 'angular'

        ],
        function() {
        'use strict';

        var controllers = angular.module('app.controversyProjectDashboardCtrl', [])
        .controller('controversyProjectDashboardController', [ controversyProjectDashboardController])


        function controversyProjectDashboardController() {
            
        }
        return controllers;
    })