define([

    'angular'

], function () {
    'use strict';
    var services = angular.module('app.scheduleJService', [])
        .factory("scheduleJFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', function ($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG, USER_SETTINGS) {


            var factory = {};
            factory.formConfigData = {};
            var URLS = {
                GET_MODAL_DATA: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=s4yf8z",
                SAVE_MODAL: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9qa2sc",
            };
            var userSettings = USER_SETTINGS;
            var logged_in_user = userSettings.user.sso_id;

            factory.getModalData = function () {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                    .getFilterParams()
                    : {};
                var params = filterParams;
                params.sso_id = logged_in_user;
                params.screen_key = GlobalService.globalParams.screen_key;
                params.jcd_key = 250;
                params.form_name = 'F1120';
                params.schedule_name = 'J';
                params.fg_key = 2;
                var promise = $http({
                    method: "get",
                    url: URLS.GET_MODAL_DATA,
                    params: params

                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });
                return promise;
            }

            factory.saveModal = function(final_data, clob_settings){
                var clob_setting = JSON.stringify(clob_settings);
                var clob_data = JSON.stringify(final_data);
                let scenarioCode = workspaceFactory.activeScreen.filters.getFilters().length>0 ? 
                                    _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected : '';
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = filterParams;
                params.combination_key = final_data[0].COMBINATION_KEY;
                params.scenarioCode = scenarioCode.CODE;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:clob_data}, params);
                params =  _.extend({clob_setting:clob_setting}, params);
                params.process_name =  "schedulej-consol-amounts-save";
                var promise = $http({
                    method: "post",
                    url: URLS.SAVE_MODAL,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            }

            return factory;

        }]);

    return services;

});