define([
    'angular',
   './basketMaintenanceService', 
   './basketMaintenanceCtrl', 
   './basketMaintenanceDeleteCtrl',
    
], function () {
    'use strict';
    return angular.module('app.FIR_Basket_Maintenance', ['app.basketMaintenanceService','app.basketMaintenanceCtrl','app.basketMaintenanceDeleteCtrl'])
   .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        // $stateProvider
        //     .state('sourcing-input-screen', {
        //         url: '/sourcing-input-screen',
        //         templateUrl: 'app/components/FIR_Sourcing_Input/sourcingInputScreen.html',
        //         backdrop: 'static',
        //         data:{},
        //         access:""
        //     })
    }])

});