define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.scheduleB8990Service',[])
        .factory("ScheduleB8990Factory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
	           
        	 var returnObj = {};
	           var ScheduleB8990Factory ={};
	           ScheduleB8990Factory.url ={};

	           
        	ScheduleB8990Factory.url.save8990ScheduleBAmounts = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ak4spe";
        	
	          
	           
	           ScheduleB8990Factory.save8990ScheduleBAmounts = function(_callName ,_data){
	               console.log("data", _data);
	               console.log("callname",_callName);
                   var rowData = _data.rowData; 

	               var service = $injector.get('JsonObjectFactory');
	               var defer = $q.defer(); 
	               returnObj.combination_key = _data.rowData.COMBINATION_KEY;
	               
	               var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var params = _.merge({}, GlobalService.globalParams, filterParams);
	               
	               var jsonObj = [{
	            	   combination_key: _data.rowData.COMBINATION_KEY,
	                   acct_key: _data.rowData[_data.colData.map+'_ACCT_KEY'],
                       amount: _data.rowData[_data.colData.map]
	   			}];
	           	console.log(jsonObj);
	           	
	            var jsonSettings = [{
	                tax_year:_data.rowData.TAX_YEAR,
	                scenario:filterParams.scenario,
	                jcd_key:GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
	   			}];
	          
	           	
	               
	               
	               var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
	               var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
	   	            
	               
	               service.saveJSON(ScheduleB8990Factory.url.save8990ScheduleBAmounts,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   AlertService.add("success", "Data saved Successfully", 3000);
		        		   var args = {COMBINATION_KEY: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY,object_id:_data.rowData.object_id}};
                              $rootScope.$emit('gridUpdate', args);
							defer.resolve(data);
						}else {
	                     AlertService.add("error", data.errorMessage, 3000);
	                     defer.reject(data);
	                 }},function(data){
	                 defer.reject(data);
	             });
	             return defer.promise;			   
	            
	            };
	           
	            
	   return ScheduleB8990Factory;
	          }]);

	      
	      return services;
	   });