define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.firfdiiQBAIAcctExcludeCtrl',  [])
		.controller('firfdiiQBAIAcctExcludeCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout', 'workspaceFactory',
			'GlobalService','USER_SETTINGS','firfdiiQBAIExcludeServiceFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', firfdiiQBAIAcctExcludeCtrl]);


	function firfdiiQBAIAcctExcludeCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout, workspaceFactory,
						   GlobalService,USER_SETTINGS,firfdiiQBAIExcludeServiceFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		
		var vm = this;
		 vm.rowData = rowData ||$scope.$parent.rowData;
	        vm.colData = colData;
	        vm.gridData = gridData;
	        vm.globalParams = GlobalService.globalParams;
	        vm.userSettings = USER_SETTINGS;
	        vm.selectedData = [];
	        $scope.crudLoading = false;
	        vm.savingFlag = false;
	        
	        console.log("data---", vm.gridData);
	        
	        if (vm.gridData != null || vm.gridData != undefined) {
				
				var tempGridData = _.clone(gridData), groups = Object
						.create(null), result, finalKeyCheck = 0;
				
				vm.gridDataMultipleSelect = _.clone(tempGridData);
				vm.checkincexcAcct = [];
				/*if (vm.gridDataMultipleSelect != undefined) {						
												
						for ( var index_ in vm.gridDataMultipleSelect) {
							vm.checkincexcAcct
									.push(vm.gridDataMultipleSelect[index_].data.IS_INCLUDE_VAL);
						}
						vm.checkincexcAcct = _.uniq(vm.checkincexcAcct,
								JSON.stringify);
						if(vm.checkincexcAcct.length > 1)
						{
							AlertService.add("error","You cannot select already excluded accounts to be excluded",'4000');
							return;
						}
					}*/
					
                    //Logic to Displaying the Warning Messages 
					vm.isExcludedWarning = false;
					vm.isincludedWarning= false;
					vm.modal_name = ModalFactory.response.data.modalScreen.modal_name;
                    for ( var index in vm.gridDataMultipleSelect) {                                    
                        if(vm.gridDataMultipleSelect[index].data.IS_INCLUDE_VAL === 0 && vm.modal_name  == 'excludeAcct') {                             
                        	 vm.gridDataMultipleSelect[index].data["INC_EXC_STATUS"] = 'Already Excluded';
                        	 vm.gridDataMultipleSelect[index].data["INC_EXC_STATUS_FLAG"] = 1;
                        	 vm.isExcludedWarning = true;
                        }
                        else if(vm.gridDataMultipleSelect[index].data.IS_INCLUDE_VAL === 1 && vm.modal_name  == 'includeAcct') {                             
	                       	 vm.gridDataMultipleSelect[index].data["INC_EXC_STATUS"] = 'Already Included';
	                       	vm.gridDataMultipleSelect[index].data["INC_EXC_STATUS_FLAG"] = 1;
	                       	 vm.isincludedWarning = true;
                       }else{
                       		vm.gridDataMultipleSelect[index].data["INC_EXC_STATUS"] = '';
                       		vm.gridDataMultipleSelect[index].data["INC_EXC_STATUS_FLAG"] = 0;
                       }
                        
                        console.log(vm.gridDataMultipleSelect[index].data,"vm.gridDataMultipleSelect[index].data");
                        vm.selectedData.push(vm.gridDataMultipleSelect[index].data);
                    } 

                    console.log(vm.selectedData,"selectedData");
                    if(vm.isExcludedWarning || vm.isincludedWarning){
                    	vm.isExcludedWarningMsgs = "Please resolve the warnings before Save";  
                    }
	        }
		
		   vm.removeRow = function(data){					
					var index = vm.selectedData.indexOf(data);					
					vm.selectedData.splice(index, 1);	
					
					vm.isExcludedWarning = false;
					vm.isincludedWarning= false;
					vm.isExcludedWarningMsgs = null;
					for ( var index in vm.selectedData) {                                    
	                    if(vm.selectedData[index].IS_INCLUDE_VAL === 0 && vm.modal_name  == 'excludeAcct')
	                    	 vm.isExcludedWarning = true;
	                    
	                    if(vm.selectedData[index].IS_INCLUDE_VAL === 1 && vm.modal_name  == 'includeAcct') 
	                       	 vm.isincludedWarning = true;
	                    
	                } 
	
	                console.log(vm.selectedData,"selectedData");
	                if(vm.isExcludedWarning || vm.isincludedWarning){
	                	vm.isExcludedWarningMsgs = "Please resolve the warnings before Save";  
	                }
		   }
	   
		   vm.saveIncludeExcludeAcct = function()
		   {
			   vm.savingFlag = true;
				if(vm.selectedData.length == 0 ){
					vm.savingFlag = false;
					AlertService.add("error", "Please select atleast one row for Saving");
					return;
				}	
				var jsonObjDetails = [];
				for(var index in vm.selectedData)
				{
					var jsonObj = {
		                "combination_key" : vm.selectedData[index].COMBINATION_KEY,
		                "local_acct_key" : vm.selectedData[index].LOCAL_ACCT_KEY,
		                "local_chart_key" : vm.selectedData[index].LOCAL_CHART_KEY,
		                "is_include" : (vm.modal_name === 'excludeAcct' ? 0 : 1),
		                
		            };
					jsonObjDetails.push(jsonObj);
				}
				
				console.log("json_data---", jsonObjDetails);
				var message = (vm.modal_name  == 'excludeAcct' ? 'Selected accounts have been Excluded' : 'Selected accounts have been Included');
				
				firfdiiQBAIExcludeServiceFactory.saveFdiiQbaiAcctExclude(jsonObjDetails).then(function(result) {
					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
							AlertService.add("success", message, 4000);
							var args = {}; //(workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};							
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
                     	};
				});
		   }
	 
		
        
        vm.cancel = function () {
			$scope.crudLoading = false;
    		$uibModalInstance.dismiss('cancel');
        };
		
	
	
	}
}); 