/**
 * @author Vijay Ramagiri This is to get and save Creditable Tax Amount and Non-Creditable Tax Amount
 * 
 * 
 */
define(
		[ 'angular',
		    './BranchTaxService',
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.BranchTaxCtrl', ['app.BranchTaxService'])
					.controller('branchTaxCtrl',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
									'$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG', 'workspaceFactory','BranchTaxFactory','$http', branchTaxCtrl ])
				
			function branchTaxCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, workspaceFactory, BranchTaxFactory,$http) {

				//console.log(rowData,gridData,"asda");
				var vm = this;				
				vm.formData = {};
				//Handle Creditable and Non-Creditable radio button and HTML fields
				vm.formData.panel = true;
				//Enable the Creditable Amount Panel
				vm.formData.enableCreditablePanel = true;
				//Enable the Non-Creditable Amount Panel
				vm.formData.enableNonCreditablePanel = true;				
				//Show the spinner if the Load is taking time
				vm.formData.spinner = true;
				//Handle Basket and Country for Standard Reason Amt Not Creditable as - 'Section 909 Suspended Splitter Taxes'
				vm.formData.isSection909SplitrTax = false;
				//A Flag to differentiate whether its a new Add or its a Edit(Update)
				vm.formData.editFlagSet = false;
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//Load all the Drop downs in the popup 
				vm.formData.creditableDropDown = [];
				//Load the Generated LEID drop down
				vm.formData.generatedLeidDropDown = [];
				//Set the Trail Balance Amount
				vm.formData.branchTaxTrailBalanceAmt = 0;
				//Set the Variance Amt in Creditable Popup
				vm.formData.variance = 0;
				//Set the LEID for the selected Branch Tax
				vm.formData.LEID;
				//Set the Creditable BR Tax Amt
				vm.formData.creditTaxAmt;
				//Set the Non-Creditable BR Tax Amount
				vm.formData.nonCrdBrAmount;	
				//Set the Open Variance Amt in Non-Creditable Popup
				vm.formData.openVariance = 0;	
				//Identify TAG_KEY for Creditable Tax Amount screen
				vm.formData.creditableTagKey;
				//Identify TAG_KEY for Non Creditable Tax Amount screen  
				vm.formData.nonCrdtableTagKey;
				//Restore Explanation for US Entity if already exists
				vm.formData.explainUsEntityRestore = '';
				//Load the ATTRIB_MAST data to get the Pivot Keys
				vm.attribMastData;
				//Data retrieved from Grid to Popup screen
				vm.defaultsMultipleSelected = [];
				//Load the Basket Data for the Drop down
				vm.basketCatgData;
				//Filtered Tax Year Drop down data for last ten years - Non Creditable
				vm.filteredTaxYearData = [];
				//Filtered Tax Year Drop down data for last ten years - Creditable
				vm.filteredTaxYearForCreditable = [];				
				//To get the Grid Filter Params 
				var filterParams = workspaceFactory.activeScreen.filters.getFilterParams();
				//Get the Grid filter Tax Year  
				vm.formData.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.formData.scenario = filterParams.scenario;  				
				//Set the current client key
				vm.formData.clientKey = GlobalService.getClient().client_key;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				vm.global_issue_id  = GlobalService.globalParams.issue_id;
				vm.isAmendedScenario = GlobalService.globalParams.issue_id != 0 && GlobalService.globalParams.issue_id != null;
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
				var lockparams = {"action_code": 'n10cpv',	"p_scenario" : vm.formData.scenario, "p_jcd_key" :vm.jcd_key };				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;		
					vm.formData.ReturnAsFiled = ((vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y')?true : false);


				});
				
				//Get the Entities for the Modal dropdown
				vm.getCodeCombinationData = function() {					
					var params = {"action_code" : 'g1mvvs', "p_tax_year" : vm.formData.filterTaxYear, "p_scenario" : vm.formData.scenario };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.codeCombinationData = data.jsonObject;
							//console.log("vm.codeCombinationData: ", vm.codeCombinationData);
					}); 
				}
				vm.getCodeCombinationData();


				//Get the Entities for the Modal dropdown
				vm.getIssueDropdown = function(){
					let issueDropdownData = {};
					if (vm.isAmendedScenario) {
						vm.issueDropdownData = $http({
							method: "GET",
							url: GENERAL_CONFIG.base_url + '/getIssues?tax_year=' + vm.formData.filterTaxYear + '&scenario=' + vm.formData.scenario                       
						}).then(function (response) {
							vm.issueDropdownData = response.data.issue;
							//return vm.issueDropdownData;
						});
					}
            	}
				vm.getIssueDropdown();
				
				//Retreive the TAG Details data using CC_KEY
				vm.getTagDetailsData = function(cc_key) {	
					let issueKey = (vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData && vm.gridDataMultipleSelect.rowData.ISSUE_KEY != undefined)? vm.gridDataMultipleSelect.rowData.ISSUE_KEY : 0;								
					var params = {"action_code" : 'm6myyu', "combination_key":cc_key, "p_tax_year" : vm.formData.filterTaxYear,  "p_scenario" : vm.formData.scenario, "p_issue_key" : issueKey}; 					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.ftcTagDtlsData = data.jsonObject;								
					});					
				}
				
				//Retreive the Attrib Mast data
				vm.getAttribMast = function() {		
					var params = {"action_code" : 'e0vg4g', "tax_year" : vm.formData.filterTaxYear}; 
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.attribMastData = data.jsonObject;								
					});					
				}
				
				//Applying Filters and Data Displaying Creditable data only for Creditable Grid
				vm.creditableTypeFilter = function(rowData){
					if(rowData.IS_CREDITABLE === 'Y' || rowData.IS_DETAILS_SCREEN === 'Y' ){
						return rowData;
					}					
				};
				
				//Applying Filters and Data Displaying NotCreditable data only for NotCreditable Grid
				vm.notCreditableTypeFilter = function(rowData){
					if(rowData.IS_CREDITABLE === 'N'){
						return rowData;
					}
				};
								
				//Get the Creditable Tax Amount Dropdown
				vm.getGeneratedLeidDropDown = function(selectedRowData) {	
					var params = {
									"action_code" : 'yxpce4',
									"p_tax_year" : vm.formData.filterTaxYear, 
									"p_scenario" : vm.formData.scenario,
									"ho_leid" : selectedRowData.HO_LEID,
									"ho_cdr_no" : selectedRowData.HO_CDR_NO,
									"ho_reporting_pd" : selectedRowData.HO_REPORTING_PERIOD, 
								};					 
					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {										
					      vm.formData.generatedLeidDropDown = data.jsonObject;						      
					});						
				};	
				
				//Get the Creditable and NonCreditable Tax Amount Dropdown
				vm.getCreditableDropDown = function() {		
					var params = {"action_code" : 'e83e1x', "tax_year" : vm.formData.filterTaxYear, };					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.basketCatgData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
	                		});
						vm.countryData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
	                		});
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                		});	
						vm.stdReasonCodeData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['STD_REASON_CODE'], p.CODE_GROUP);  
	                		});	
						vm.basketCodeDefault = _.filter(vm.basketCatgData, function(p){
	                		  return _.includes(['BRANCH'], p.CODE_SHORT_DESC);  
						});	
						vm.countryUS =  _.filter(vm.countryData, function(p){
	                		  return _.includes(['US'], p.CODE_SHORT_DESC);  
						});
						vm.taxPriorYearStdReason = _.filter(vm.stdReasonCodeData, function(p){
	                		  return _.includes(['TPPY'], p.CODE_SHORT_DESC);  
						});	
						vm.section909SplitrTax = _.filter(vm.stdReasonCodeData, function(p){
	                		  return _.includes(['SUSPT'], p.CODE_SHORT_DESC);  
						});	
						vm.taxPriorYearCodeKey = ''+vm.taxPriorYearStdReason[0].CODE_KEY;
						vm.section909SplitrTaxKey = ''+vm.section909SplitrTax[0].CODE_KEY;
					});					
				};
				
				//Filter the tax_year between [current year - 1] to [current year -11]
				vm.tillCurrentTaxYear = function(curentYr, fromPriorYr, tillPriorYr) {
					vm.filteredTaxYearData = [];					
					if(vm.filteredTaxYearData.length == 0){
						for(var indx in vm.taxYearData){
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' && (vm.taxYearData[indx].CODE_DISP_DESC <= (curentYr-fromPriorYr) && vm.taxYearData[indx].CODE_DISP_DESC >= (curentYr-tillPriorYr))){
								vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
							}
						}	
						$scope.$watch(function() { return vm.filteredTaxYearData },   function() {
							//Defaulting the NonCreditable Applicable Year to Default Current Year as per Mary Cordier  
							vm.formData.applicableYearKey2 = getTaxYearKey(vm.formData.filterTaxYear-fromPriorYr);
						});						
					}
					
					if(vm.filteredTaxYearForCreditable.length == 0){
						for(var indx in vm.taxYearData){								
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' && (vm.taxYearData[indx].CODE_DISP_DESC <= (curentYr-fromPriorYr) && vm.taxYearData[indx].CODE_DISP_DESC >= (curentYr-tillPriorYr))){
								vm.filteredTaxYearForCreditable.push(vm.taxYearData[indx]);
							}
						}
						//Defaulting the Creditable Applicable Year to Default Current Year as per Mary Cordier 
						$scope.$watch(function() { return vm.filteredTaxYearForCreditable },   function() {
							//Defaulting the NonCreditable Applicable Year to Default Current Year as per Mary Cordier  
							vm.formData.applicableYearKey = getTaxYearKey(vm.formData.filterTaxYear-fromPriorYr); 
						});	
						
					}
				};
				
				//On select of Std Reason - update the Applicable Year Dropdown
				vm.isTaxPriorYear = function() {
					if(vm.formData.selectStdReasonCd!=undefined && vm.formData.selectStdReasonCd == vm.taxPriorYearCodeKey){
						//if the as Std Reason is selected as 'Tax Prior Year'
						vm.tillCurrentTaxYear(vm.formData.filterTaxYear,1,11);						
					}else{
						//if the as Std Reason is selected other than 'Tax Prior Year'
						vm.tillCurrentTaxYear(vm.formData.filterTaxYear,0,11);						
					}
					if(vm.formData.selectStdReasonCd!=undefined && vm.formData.selectStdReasonCd == vm.section909SplitrTaxKey){
						vm.formData.selectedSplitterBasketCatg = ''+vm.basketCodeDefault[0].CODE_KEY;
						vm.formData.isSection909SplitrTax = true;						
					}else{
						vm.formData.isSection909SplitrTax = false;
					}
				}
				
				//Load the Data from FTC_CODE_MAST and Attrib Mast
				vm.getCreditableDropDown();
				vm.getAttribMast();
				vm.isTaxPriorYear();
								
				//Get the CODE_KEY for the given COUNTRY CODE_DISP_DESC 
				function getCountryKey(countryDesc) {
					var selectedCountryKey;
					for(var indx in vm.countryData){							
						if(vm.countryData[indx].CODE_DISP_DESC == countryDesc){
							selectedCountryKey = ''+vm.countryData[indx].CODE_KEY;							
						}
					}
					return selectedCountryKey;
				};
				
				//Get the CODE_KEY for the given TAX_YEAR CODE_DISP_DESC 
				function getTaxYearKey(taxYearDesc) {
					var selectedTaxYearKey;
					for(var indx in vm.taxYearData){								
						if(vm.taxYearData[indx].CODE_DISP_DESC == taxYearDesc){
							selectedTaxYearKey = ''+vm.taxYearData[indx].CODE_KEY;							
						}
					}
					return selectedTaxYearKey;
				};
				
				//Get the CODE_KEY for the given COUNTRY CODE_DISP_DESC 
				function getCountryDesc(countryKey) {
					var selectedCountryDesc;
					for(var indx in vm.countryData){								
						if(vm.countryData[indx].CODE_KEY == countryKey){
							selectedCountryDesc = ''+vm.countryData[indx].CODE_DISP_DESC;							
						}
					}
					return selectedCountryDesc;
				};
				
				//Get the CODE_KEY for the given TAX_YEAR CODE_DISP_DESC 
				function getTaxYearDesc(taxYearKey) {
					var selectedTaxYearDesc;
					for(var indx in vm.taxYearData){								
						if(vm.taxYearData[indx].CODE_KEY == taxYearKey){
							selectedTaxYearDesc = ''+vm.taxYearData[indx].CODE_DISP_DESC;							
						}
					}
					return selectedTaxYearDesc;
				};
				
				//Grid Data from Branch Tax  Screen
				if (gridData != null) {	
					//console.log("::: ->  -> gridData:: ", gridData);
					//console.log("::: ->  ->  rowData:: ", rowData);
					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;					
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);		
					console.log(" vm.gridDataMultipleSelect :", vm.gridDataMultipleSelect);
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					/*$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
								vm.cancel();										
								AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
								return;
						}
					});*/
					
					if (vm.gridDataMultipleSelect != undefined) {						
						if (vm.gridDataMultipleSelect.rowData != undefined) {	
							//vm.formData.issueKey = vm.gridDataMultipleSelect.rowData.ISSUE_KEY != undefined?  vm.gridDataMultipleSelect.rowData.ISSUE_KEY : 0;						
							vm.getGeneratedLeidDropDown(vm.gridDataMultipleSelect.rowData);	
							vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect.rowData);
							vm.formData.branchTaxTrailBalanceAmt = vm.gridDataMultipleSelect.rowData.TB_AMT.toLocaleString(undefined, {maximumFractionDigits:0});
							vm.formData.totalBranchTaxes = vm.gridDataMultipleSelect.rowData.SOURCED_AMT.toLocaleString(undefined, {maximumFractionDigits:0});
							vm.formData.variance = vm.gridDataMultipleSelect.rowData.VARIANCE_AMT.toLocaleString(undefined, {maximumFractionDigits:0});
							vm.formData.openVariance = vm.gridDataMultipleSelect.rowData.VARIANCE_AMT.toLocaleString(undefined, {maximumFractionDigits:0});	
							vm.formData.LEID = vm.gridDataMultipleSelect.rowData.LEID;
							//Get the TAG details based on COMBINATION_KEY
							vm.getTagDetailsData(vm.gridDataMultipleSelect.rowData.COMBINATION_KEY);
							
							if(vm.gridDataMultipleSelect.rowData.IS_DEFAULT_ALLOWED == undefined || vm.gridDataMultipleSelect.rowData.IS_DEFAULT_ALLOWED == null){								
								if(vm.gridDataMultipleSelect.rowData.IS_CREDITABLE == undefined || vm.gridDataMultipleSelect.rowData.IS_CREDITABLE == null){
									if(vm.gridDataMultipleSelect.rowData.CREDIT_AMT == undefined || vm.gridDataMultipleSelect.rowData.CREDIT_AMT == 0 ){
										vm.gridDataMultipleSelect.rowData["IS_CREDITABLE"] = "N";
										vm.formData.panel = false;
										if(vm.gridDataMultipleSelect.rowData.APPLICABLE_YEAR == null || vm.gridDataMultipleSelect.rowData.APPLICABLE_YEAR == ''){
											vm.gridDataMultipleSelect.rowData.APPLICABLE_YEAR = vm.gridDataMultipleSelect.rowData.APPLICABLE_YEAR2;
										}											
									}else{
										vm.gridDataMultipleSelect.rowData["IS_CREDITABLE"] = "Y";	
										vm.formData.panel = true;											
									}										
								}else{
									vm.gridDataMultipleSelect.rowData["IS_CREDITABLE"] = vm.gridDataMultipleSelect.rowData.IS_CREDITABLE;
									if(vm.gridDataMultipleSelect.rowData.IS_CREDITABLE == 'Y'){
										vm.formData.panel = true;
									}else{
										vm.formData.panel = false;
									}										
								}
							}
						}					
					}
				}											
				
				//reset the form
				vm.resetCreditable = function(){
					vm.selectedCodeCombination = undefined;
					vm.formData.selectedBasketCatg = ''+vm.basketCodeDefault[0].CODE_KEY;					
					vm.formData.creditTaxAmt = '';
					vm.formData.genLeid = '';
					vm.formData.selectedCountryDesc = '';
					vm.formData.selectedCountryKey = '';
					vm.formData.selectedBranchTaxLEID_Orgnl_CountryKey = '';
					vm.formData.applicableYearKey = getTaxYearKey(vm.formData.filterTaxYear);
					vm.formData.explainUsEntity='Not Applicable';	
					vm.formData.creditableTagKey = null;
					
				};
				
				//reset the form
				vm.resetNonCreditable = function(){
					vm.selectedCodeCombination = undefined;
					vm.tillCurrentTaxYear(vm.formData.filterTaxYear,0,11);		
					vm.formData.selectStdReasonCd = '';
					vm.formData.detailReason ='';					
					vm.formData.applicableYearKey2 = getTaxYearKey(vm.formData.filterTaxYear);	
					vm.formData.nonCrdBrAmount = '';
					vm.formData.selectedSplitterBasketCatg = ''+vm.basketCodeDefault[0].CODE_KEY;
					vm.formData.selectedSplitterCountryDesc = '';
					vm.formData.nonCrdtableTagKey = null;
				};
							
				//Change the LE_COUNTRY based on LEID 
				vm.changeLeCountry = function(){
					
					for(var row in vm.formData.generatedLeidDropDown){
						if(vm.formData.generatedLeidDropDown[row].LEID_LABEL == vm.formData.genLeid){							
							for(var indx in vm.countryData){								
								if(vm.countryData[indx].CODE_SHORT_DESC == vm.formData.generatedLeidDropDown[row].COUNTRY_CODE){
									vm.formData.selectedCountryKey = ''+vm.countryData[indx].CODE_KEY;
									vm.formData.selectedCountryDesc = vm.countryData[indx].CODE_DISP_DESC;
									//For the logic to enable - Explain why Branch Tax LEID is US entity
									vm.formData.selectedBranchTaxLEID_Orgnl_CountryKey = ''+vm.countryData[indx].CODE_KEY;
									vm.formData.selectedBranchTaxLEID_Orgnl_CountryDesc = vm.countryData[indx].CODE_DISP_DESC;
								}
							}
						 }
					}
					if(vm.formData.explainUsEntityRestore == '' && (vm.formData.explainUsEntity != 'Not Applicable' && vm.formData.explainUsEntity != '')){
						vm.formData.explainUsEntityRestore = vm.formData.explainUsEntity;
					}
					if(vm.formData.selectedBranchTaxLEID_Orgnl_CountryKey == vm.countryUS[0].CODE_KEY){
						if(vm.formData.explainUsEntity == 'Not Applicable' && vm.formData.explainUsEntityRestore == ''){
								vm.formData.explainUsEntity = '';
						}else{
							vm.formData.explainUsEntity = vm.formData.explainUsEntityRestore;
						}
					}else{
						vm.formData.explainUsEntity = 'Not Applicable';
					}
				}	
								
				//Delete operation for Branch Tax Screen
				vm.deleteRow = function(selectedRowData){					
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['scenario'] = vm.formData.scenario; //GlobalService.globalParams.scenario;
					//returnClobSettingsObj['me_code'] = vm.defaultsMultipleSelected[key].ME_CODE;

					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					var returnObj = {};
					returnObj["TAG_KEY"] = selectedRowData.TAG_KEY;
					returnObj['COMBINATION_KEY'] = selectedRowData.COMBINATION_KEY;
					returnObj["OPERATION_TYPE"] = 'D';
					returnObj['GROUP_ID'] = 1;
					returnObj["IS_CREDITABLE"] = 'D';
					returnObj["0_0"] = '0';
					returnObj["AMOUNT"] =  0;				
					
					var message = "Branch Tax Data has been successfully Deleted";
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);
					//send the data to delete
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);
				}
							
				//Saving the Data for both Non Creditable and Creditable Tax forms 
				vm.saveCreditAmtBranchTaxForm = function(){	
					
					if(vm.addEntityPage!=undefined || vm.addEntityPage!=null || vm.addEntityPage == 'true') {
					    if ((vm.selectedCodeCombination == undefined || vm.selectedCodeCombination == null)) {
							AlertService.add("error", "Please select an Entity.",4000);
						    return;
						}
						if (vm.isAmendedScenario && (vm.formData.selectedIssue == undefined || vm.formData.selectedIssue == null)) {
							AlertService.add("error", "Please select an Issue.",4000);
						    return;
						}	
					}
					
					if(vm.formData.selectedBasketCatg == null || vm.formData.selectedBasketCatg == '' || vm.formData.selectedCountryDesc == null || vm.formData.selectedCountryDesc == '' ||
							vm.formData.genLeid == null	|| vm.formData.genLeid == '' || vm.formData.applicableYearKey == null || vm.formData.applicableYearKey == '' ){
						AlertService.add("error", "Please select/enter all the fields.",4000);
						return;
					}
					
					if(vm.formData.selectedBranchTaxLEID_Orgnl_CountryKey == vm.countryUS[0].CODE_KEY && 
							(vm.formData.explainUsEntity == null|| vm.formData.explainUsEntity == '')){
						AlertService.add("error", "Please enter explanation why Branch Tax LEID is US entity",4000);
						return;
					}
				
					if(vm.formData.creditTaxAmt == null || vm.formData.creditTaxAmt == ''){
						AlertService.add("error", "Please enter a valid Branch Tax Creditable Amount.",4000);
						return;
					}
					
					if(vm.formData.creditTaxAmt !=undefined && vm.formData.creditTaxAmt !=null ){
						var validNumberWithDecimals = ((parseInt(vm.formData.creditTaxAmt) - parseFloat(vm.formData.creditTaxAmt)) !== 0);
						if(validNumberWithDecimals){
							AlertService.add("error", "Please do not enter decimals for Branch Tax Creditable Amount.",4000);
							return;
						}
					}
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var key = 0;															
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['scenario'] = vm.formData.scenario; //GlobalService.globalParams.scenario;
					returnClobSettingsObj['object_id'] = vm.defaultsMultipleSelected[key] ? vm.defaultsMultipleSelected[key].COMBINATION_KEY : vm.selectedCodeCombination.KEY;

					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
										
					var returnObj = {};	
					returnObj['ISSUE_KEY'] = (vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData && vm.gridDataMultipleSelect.rowData.ISSUE_KEY != undefined)? vm.gridDataMultipleSelect.rowData.ISSUE_KEY : vm.formData.selectedIssue;				
					returnObj['COMBINATION_KEY'] = vm.defaultsMultipleSelected[key] ? vm.defaultsMultipleSelected[key].COMBINATION_KEY :  vm.selectedCodeCombination.KEY;					
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = vm.formData.selectedBasketCatg;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'GEN_LEID']).ATTRIB_KEY +'_0'] = vm.formData.genLeid;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = getCountryKey(vm.formData.selectedCountryDesc);
					//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = vm.formData.selectedCountryKey;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'EXPLANATION_CREDITABLE_US_ENTITY']).ATTRIB_KEY +'_0'] = vm.formData.explainUsEntity.replace(/’/g, "'");
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'APPLICABLE_YEAR']).ATTRIB_KEY +'_0'] = vm.formData.applicableYearKey;
					returnObj["IS_CREDITABLE"] = 'Y';					
					returnObj["AMOUNT"] =  ((vm.formData.creditTaxAmt != undefined || vm.formData.creditTaxAmt != null) ? vm.formData.creditTaxAmt : vm.defaultsMultipleSelected[key].CREDIT_AMT);						
					returnObj['GROUP_ID'] = 1;
					if(vm.formData.editFlagSet){
						if(vm.formData.creditableTagKey!=undefined && vm.formData.creditableTagKey!=null){
							returnObj["TAG_KEY"] = vm.formData.creditableTagKey; 
							returnObj["OPERATION_TYPE"] = 'U';
						}else{
							returnObj["TAG_KEY"] = null;
							returnObj["OPERATION_TYPE"] = 'I';
						}
					}else{
						returnObj["TAG_KEY"] = null;
						returnObj["OPERATION_TYPE"] = 'I';
					}
					console.log("returnObj::FNL:: ",returnObj);
						
					var message = "Creditable Tax has been successfully saved/updated";
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);					
										
					//send to save the Data
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);										
				}

				//Save the Non Creditable Amt
				vm.saveNonCreditAmtBranchTaxForm = function(){
					
					if(vm.addEntityPage!=undefined || vm.addEntityPage!=null || vm.addEntityPage == 'true') {
					    if ((vm.selectedCodeCombination == undefined || vm.selectedCodeCombination == null)) {
							AlertService.add("error", "Please select an Entity.",4000);
						    return;
						}
						if (vm.isAmendedScenario && (vm.formData.selectedIssue == undefined || vm.formData.selectedIssue == null)) {
							AlertService.add("error", "Please select an Issue.",4000);
						    return;
						}	
					}
					
					if(vm.formData.selectStdReasonCd == null || vm.formData.selectStdReasonCd == '' || vm.formData.detailReason == null || vm.formData.detailReason == '' ||
							vm.formData.applicableYearKey2 == null || vm.formData.applicableYearKey2 == ''){
						AlertService.add("error", "Please select/enter all the fields.",4000);
						return;
					}
					//Validations for the Amounts field				
					if(vm.formData.nonCrdBrAmount == null || vm.formData.nonCrdBrAmount == ''){
						AlertService.add("error", "Please enter a valid Branch Tax Non-Creditable Amount.",4000);
						return;
					}
					if(vm.formData.nonCrdBrAmount !=undefined && vm.formData.nonCrdBrAmount !=null ){
						var validNumberWithDecimals = ((parseInt(vm.formData.nonCrdBrAmount) - parseFloat(vm.formData.nonCrdBrAmount)) !== 0);
						if(validNumberWithDecimals){
							AlertService.add("error", "Please do not enter decimals for Branch Tax Non-Creditable Amount.",4000);
							return;
						}
					}
					
					if(vm.formData.selectStdReasonCd!=undefined && vm.formData.selectStdReasonCd == vm.section909SplitrTaxKey){
						if(vm.formData.selectedSplitterBasketCatg == null || vm.formData.selectedSplitterBasketCatg == ''){
							AlertService.add("error", "Please select Basket for selected Standard Reason Amount is Non-Creditable.",4000);
							return;
						}
						if(vm.formData.selectedSplitterCountryDesc == null || vm.formData.selectedSplitterCountryDesc == ''){
							AlertService.add("error", "Please select Country for selected Standard Reason Amount is Non-Creditable.",4000);
							return;
						}
					}
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var key = 0;															
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['scenario'] = vm.formData.scenario; //GlobalService.globalParams.scenario;
					returnClobSettingsObj['object_id'] = vm.defaultsMultipleSelected[key] ? vm.defaultsMultipleSelected[key].COMBINATION_KEY : vm.selectedCodeCombination.KEY;;
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					//SAVE the Standard Rason and Non_Creditable Amount
					var returnObj = {};		
					returnObj['ISSUE_KEY'] = (vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData && vm.gridDataMultipleSelect.rowData.ISSUE_KEY != undefined)? vm.gridDataMultipleSelect.rowData.ISSUE_KEY : vm.formData.selectedIssue;				
					returnObj['COMBINATION_KEY'] = vm.defaultsMultipleSelected[key] ? vm.defaultsMultipleSelected[key].COMBINATION_KEY :  vm.selectedCodeCombination.KEY;					
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'STANDARD_REASON_NOT_CREDITABLE']).ATTRIB_KEY +'_0'] = vm.formData.selectStdReasonCd; 
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'DETAIL_REASON_NOT_CREDITABLE']).ATTRIB_KEY +'_0'] = vm.formData.detailReason.replace(/’/g, "'");
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'APPLICABLE_YEAR']).ATTRIB_KEY +'_0'] = vm.formData.applicableYearKey2;
					if(vm.formData.selectStdReasonCd!=undefined && vm.formData.selectStdReasonCd == vm.section909SplitrTaxKey){
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = vm.formData.selectedSplitterBasketCatg;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = getCountryKey(vm.formData.selectedSplitterCountryDesc);					
					}else{
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = 0;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = 0;	
					}
					returnObj["IS_CREDITABLE"] = 'N';
					returnObj["AMOUNT"] = ((vm.formData.nonCrdBrAmount != null) ? vm.formData.nonCrdBrAmount : vm.defaultsMultipleSelected[key].NON_CREDIT_AMT);					
					returnObj['GROUP_ID'] = 1;
					if(vm.formData.editFlagSet){
						if(vm.formData.nonCrdtableTagKey!=undefined && vm.formData.nonCrdtableTagKey!=null){
							returnObj["TAG_KEY"] = vm.formData.nonCrdtableTagKey;
							returnObj["OPERATION_TYPE"] = 'U';
						}else{
							returnObj["TAG_KEY"] = null;
							returnObj["OPERATION_TYPE"] = 'I';
						}
					}else{
						returnObj["TAG_KEY"] = null;
						returnObj["OPERATION_TYPE"] = 'I';
					}						
					console.log("returnObj::FNL:: ",returnObj);
						
					var message = "Non-Creditable Tax has been successfully saved/updated";
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);					
					//send to save the Data
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);
					
				}
				
				//Send Details
				function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
					BranchTaxFactory.savePivotedData(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id:returnClobSettingsObj.object_id,
									p_tax_year : vm.formData.filterTaxYear,
                                    p_scenario : vm.formData.scenario,
                                    tax_year : vm.formData.filterTaxYear,
                                    scenario : vm.formData.scenario,
                                    gridFilter: {
                                        object_id: returnClobSettingsObj.object_id,
                                        p_tax_year : vm.formData.filterTaxYear,
                                        p_scenario : vm.formData.scenario,
                                        tax_year : vm.formData.filterTaxYear,
                                        scenario : vm.formData.scenario
                                    }
							};
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);							
                        };
					});
				}

				//To Edit the Non-Creditable TAX screen popup
				vm.editNonCreditableTaxRow = function(selectedRowData){	
					
					for(var key in vm.stdReasonCodeData){
						if(vm.stdReasonCodeData[key].CODE_DISP_DESC == selectedRowData.STANDARD_REASON_NOT_CREDITABLE){
							vm.formData.selectStdReasonCd = ''+vm.stdReasonCodeData[key].CODE_KEY;
							if(vm.formData.selectStdReasonCd!=undefined && vm.formData.selectStdReasonCd == vm.section909SplitrTaxKey){
								vm.formData.isSection909SplitrTax = true;
							}else{
								vm.formData.isSection909SplitrTax = false;
							}
						}						
					}
					vm.isTaxPriorYear();//To reset Applicable Year when the Std Reason is selected as 'Tax Prior Year'	or Other				
					$scope.$watch(function() { return vm.filteredTaxYearData },   function() {
						for(var key in vm.taxYearData){
							if(vm.taxYearData[key].CODE_DISP_DESC == selectedRowData.APPLICABLE_YEAR){
								vm.formData.applicableYearKey2 = ''+vm.taxYearData[key].CODE_KEY;							
							}
						}
					});
					for(var indx in vm.countryData){								
						if(vm.countryData[indx].CODE_DISP_DESC == selectedRowData.COUNTRY){
							vm.formData.selectedSplitterCountryKey = ''+vm.countryData[indx].CODE_KEY;
							vm.formData.selectedSplitterCountryDesc = vm.countryData[indx].CODE_DISP_DESC;							
						}
					}
					for(var key in vm.basketCatgData){
						if(vm.basketCatgData[key].CODE_DISP_DESC == selectedRowData.BASKET){
							vm.formData.selectedSplitterBasketCatg = ''+vm.basketCatgData[key].CODE_KEY;							
						}
					}
					//vm.formData.lockStatusFlag = selectedRowData.LOCKED_STATUS_FLAG;  
					vm.formData.editFlagSet = true;
					vm.formData.nonCrdtableTagKey = selectedRowData.TAG_KEY;	
					vm.formData.nonCrdBrAmount = parseInt(''+selectedRowData.NON_CREDIT_AMT);
					vm.formData.openVariance = parseInt((''+selectedRowData.VARIANCE_AMT).replace(/\,/g,'')).toLocaleString(undefined, {maximumFractionDigits:0}); 
					//parseFloat(parseFloat((vm.formData.branchTaxTrailBalanceAmt ==  null || vm.formData.branchTaxTrailBalanceAmt == undefined)?0: vm.formData.branchTaxTrailBalanceAmt) - parseFloat((vm.formData.creditTaxAmt == null || vm.formData.creditTaxAmt == undefined)?0: vm.formData.creditTaxAmt) - parseFloat((vm.formData.nonCrdBrAmount ==  null || vm.formData.nonCrdBrAmount == undefined)?0: vm.formData.nonCrdBrAmount)).toFixed(2);
					vm.formData.detailReason = selectedRowData.DETAIL_REASON_NOT_CREDITABLE;
			    };
				
				//To Edit the Creditable TAX screen popup 
				vm.editCreditableTaxRow = function(selectedRowData){
					for(var key in vm.basketCatgData){
						if(vm.basketCatgData[key].CODE_DISP_DESC == selectedRowData.BASKET){
							vm.formData.selectedBasketCatg = ''+vm.basketCatgData[key].CODE_KEY;							
						}
					}
					
					for(var key in vm.taxYearData){
						if(vm.taxYearData[key].CODE_DISP_DESC == selectedRowData.APPLICABLE_YEAR){
							vm.formData.applicableYearKey = ''+vm.taxYearData[key].CODE_KEY;							
						}
					}
					//vm.formData.lockStatusFlag = selectedRowData.LOCKED_STATUS_FLAG;  					
					vm.formData.genLeid = angular.uppercase(selectedRowData.GEN_LEID); //Converting to Uppercase to handle Ingestion Grid entering lower case BRTAX LEID
					vm.formData.selectedCountryDesc = selectedRowData.COUNTRY;
					vm.formData.selectedCountryKey = ''+getCountryKey(selectedRowData.COUNTRY);
					vm.formData.selectedSavedCountryKey = ''+getCountryKey(selectedRowData.COUNTRY);
					vm.formData.editFlagSet = true;
					vm.formData.creditableTagKey = selectedRowData.TAG_KEY;					
					vm.formData.explainUsEntity = (selectedRowData.EXPLANATION_CREDITABLE_US_ENTITY != null && selectedRowData.EXPLANATION_CREDITABLE_US_ENTITY != '') ? selectedRowData.EXPLANATION_CREDITABLE_US_ENTITY: 'Not Applicable'  ;					
					vm.formData.explainUsEntityRestore = ''; //Reset whenever a new row is tried to Edit
					vm.formData.creditTaxAmt = parseInt(''+selectedRowData.CREDIT_AMT);	
					vm.formData.branchTaxTrailBalanceAmt = parseInt((''+selectedRowData.TB_AMT).replace(/\,/g,'')).toLocaleString(undefined, {maximumFractionDigits:0});						
					vm.formData.variance = parseInt((''+selectedRowData.VARIANCE_AMT).replace(/\,/g,'')).toLocaleString(undefined, {maximumFractionDigits:0});	
					//parseFloat(parseFloat((vm.formData.branchTaxTrailBalanceAmt ==  null || vm.formData.branchTaxTrailBalanceAmt == undefined)?0: vm.formData.branchTaxTrailBalanceAmt) - parseFloat((vm.formData.creditTaxAmt == null || vm.formData.creditTaxAmt == undefined)?0: vm.formData.creditTaxAmt) - parseFloat((vm.formData.nonCrdBrAmount ==  null || vm.formData.nonCrdBrAmount == undefined)?0: vm.formData.nonCrdBrAmount)).toFixed(2);
					vm.formData.totalBranchTaxes = parseInt((''+selectedRowData.SOURCED_AMT).replace(/\,/g,'')).toLocaleString(undefined, {maximumFractionDigits:0});  
					//parseFloat(parseFloat((vm.formData.creditTaxAmt == null || vm.formData.creditTaxAmt == undefined)?0: vm.formData.creditTaxAmt) + parseFloat((vm.formData.nonCrdBrAmount == null || vm.formData.nonCrdBrAmount == undefined)?0: vm.formData.nonCrdBrAmount)).toFixed(2);
					
					/*This is a new Logic added on Sept 9th,2019 - based on changes needed by Sarah --
					 *     For a given Branch Tax LEID, the associated Country is US country then Tax Area should be enabled.
					 *     But not based on the saved Country.					 */
					
					for(var row in vm.formData.generatedLeidDropDown){
						if(vm.formData.generatedLeidDropDown[row].LEID_LABEL == vm.formData.genLeid){							
							for(var indx in vm.countryData){								
								if(vm.countryData[indx].CODE_SHORT_DESC == vm.formData.generatedLeidDropDown[row].COUNTRY_CODE){
									vm.formData.selectedBranchTaxLEID_Orgnl_CountryKey = ''+vm.countryData[indx].CODE_KEY;
									vm.formData.selectedBranchTaxLEID_Orgnl_CountryDesc = vm.countryData[indx].CODE_DISP_DESC;
								}
							}
						 }
					}
					if(vm.formData.explainUsEntityRestore == '' && (vm.formData.explainUsEntity != 'Not Applicable' && vm.formData.explainUsEntity != '')){
						vm.formData.explainUsEntityRestore = vm.formData.explainUsEntity;
					}
					if(vm.formData.selectedBranchTaxLEID_Orgnl_CountryKey == vm.countryUS[0].CODE_KEY){
						if(vm.formData.explainUsEntity == 'Not Applicable' && vm.formData.explainUsEntityRestore == ''){
								vm.formData.explainUsEntity = '';
						}else{
							vm.formData.explainUsEntity = vm.formData.explainUsEntityRestore;
						}
					}else{
						vm.formData.explainUsEntity = 'Not Applicable';
					}					
			    };

			    //Provide the Information of how the Transaction Amounts for Local Accts are derived
			    vm.getSummaryInfo  = function(){
			    	var params = { 	'sso_id': vm.logged_in_user,
			    					'scenario': vm.formData.scenario,
			    					'leid':rowData.LEID,			    			
			    					'cdr_no':rowData.CDR_NO,
			    					'reporting_period':rowData.REPORTING_PERIOD,
			    					'me_code': rowData.ME_CODE};
			    	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=42kxhl', params).then(function(data) {
						vm.summaryInfo = data.jsonObject;	
						console.log("vm.summaryInfo	:: ",vm.summaryInfo);			    	
			    	});	
			    }
			    if(rowData) {
			    	console.log(" rowData : ", rowData);
			    	vm.getSummaryInfo();
			    }
				
				
				
				//Set All the TAGs retreived using COMBINATION_KEY
				$scope.$watch(function() { return vm.ftcTagDtlsData }, function() {					
					if(vm.ftcTagDtlsData != undefined && vm.ftcTagDtlsData.length > 0){						
						var allTagKeys = [];
						for ( var item in vm.ftcTagDtlsData) {
							if(_.includes(allTagKeys, vm.ftcTagDtlsData[item].TAG_KEY) == false){
								allTagKeys.push(vm.ftcTagDtlsData[item].TAG_KEY);								
							}
						}
						
						if(allTagKeys.length > 0){
							vm.defaultsMultipleSelected = [];
						}
						for( var tagkey in allTagKeys  ){
							var newObj = angular.copy(vm.gridDataMultipleSelect.rowData);
							for ( var key in vm.ftcTagDtlsData) {
								if(vm.ftcTagDtlsData[key].TAG_KEY == allTagKeys[tagkey]){
									newObj["TAG_KEY"] = vm.ftcTagDtlsData[key].TAG_KEY;   
									newObj["LOCKED_STATUS_FLAG"] = ((vm.ftcTagDtlsData[key].STATUS_FLAG == 'L') ? true : false); 	
									//vm.formData.lockStatusFlag = ((vm.ftcTagDtlsData[key].STATUS_FLAG == 'L') ? true : false); 	
									newObj[vm.ftcTagDtlsData[key].ATTRIB_NAME.replace(/(^"|"$)/g, '')] = vm.ftcTagDtlsData[key].ATTRIB_VALUE;
									newObj["IS_CREDITABLE"] = vm.ftcTagDtlsData[key].IS_CREDITABLE;	
									if(vm.ftcTagDtlsData[key].IS_CREDITABLE == 'Y'){
										newObj.CREDIT_AMT = vm.ftcTagDtlsData[key].BRANCH_TAXES_AMT;														
									}else{
										newObj.NON_CREDIT_AMT = vm.ftcTagDtlsData[key].BRANCH_TAXES_AMT;														
									}
									newObj["APPLICABLE_YEAR2"] = newObj["APPLICABLE_YEAR"];
								}
								vm.formData.branchTaxTrailBalanceAmt = parseInt(''+newObj.TB_AMT).toLocaleString(undefined, {maximumFractionDigits:0});
								vm.formData.openVariance = parseInt(''+newObj.VARIANCE_AMT).toLocaleString(undefined, {maximumFractionDigits:0});								
								vm.formData.totalBranchTaxes = parseInt(''+newObj.SOURCED_AMT).toLocaleString(undefined, {maximumFractionDigits:0});
								vm.formData.variance = parseInt(''+newObj.VARIANCE_AMT).toLocaleString(undefined, {maximumFractionDigits:0});								
							}							
							vm.defaultsMultipleSelected.push(newObj);											
						}						
						vm.formData.spinner = false;
					}else{
						vm.formData.spinner = false;
					}
					
				});	
				
				//On load -  populate the default Basket Code as 'Foreign Branch' 
				$scope.$watch(function() { return vm.basketCodeDefault }, function() {
					if(vm.basketCodeDefault!=undefined){
						vm.formData.selectedBasketCatg =  ''+vm.basketCodeDefault[0].CODE_KEY;
					}
				});	
				
				//On load -  populate the Applicable Year drop down 
				$scope.$watch(function() { return vm.taxYearData }, function() {
					if(vm.taxYearData!=undefined){
						vm.tillCurrentTaxYear(vm.formData.filterTaxYear,0,11);
					}
				});	
				
				//On Change of Radio Button - control the panels
				$scope.$watch(function() { return vm.formData.panel }, function() { 
				   if(vm.formData.panel){    
					   vm.formData.enableCreditablePanel = true;
					   vm.formData.enableNonCreditablePanel = false;					   
				   }else{
					   vm.formData.enableCreditablePanel = false;
					   vm.formData.enableNonCreditablePanel = true;					  
				   }
			    });
				

				
				//When change a Country for a given Branch Tax LEID
				/*vm.countryChanged = function(){
					vm.formData.selectedCountryKey = ''+getCountryKey(vm.formData.selectedCountryDesc);
					
					//To set  --  Explain why Branch Tax LEID is US entity 
					//if(vm.formData.explainUsEntityRestore == '' && (vm.formData.explainUsEntity != 'Not Applicable' && vm.formData.explainUsEntity != '')){
					//	vm.formData.explainUsEntityRestore = vm.formData.explainUsEntity;
					//}
					if(vm.formData.selectedBranchTaxLEID_Orgnl_CountryKey == vm.countryUS[0].CODE_KEY){
						if(vm.formData.explainUsEntity == 'Not Applicable' && vm.formData.explainUsEntityRestore == ''){
								vm.formData.explainUsEntity = '';
						}else if(vm.formData.selectedCountryKey == vm.formData.selectedSavedCountryKey){
							vm.formData.explainUsEntity = vm.formData.explainUsEntityRestore;
						}
					}else if(vm.formData.selectedCountryKey == vm.formData.selectedSavedCountryKey){
						vm.formData.explainUsEntity = vm.formData.explainUsEntityRestore;
					}else{
						vm.formData.explainUsEntity = 'Not Applicable';
					}
				}*/
				
			}	
					
			return controllers;

		});
