define([
    'angular'

], function () {
    'use strict';
    var controllers = angular.module('app.InsAcctController', [])
        .controller('insAcctCtrl', ['$scope', '$state', '$http', 'ModalFactory', 'AlertService',
            'JsonObjectFactory', '$timeout', '$uibModalInstance', 'InsAcctFactory', insAcctCtrl
        ]);

    function insAcctCtrl($scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, $timeout, $uibModalInstance, InsAcctFactory) {
        var vm = this;
        vm.modalTitle = "Purchases of Inventory/Wages in Cost of Sales/Other Pmts";
        
    }
    return controllers;
});