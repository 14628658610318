define(
	['angular'],
	function() {
		'use strict';
		var controllers = angular.module('app.FormScheduleM3Ctrl', []).controller('F1120M3Ctrl', ['$rootScope', '$scope',
			'$http', '$filter', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService',
			'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'FormScheduleM3ServiceFactory',
			'rowData', 'gridData', F1120M3Ctrl
		])

		function F1120M3Ctrl($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModalInstance, ModalFactory,
			AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, FormScheduleM3ServiceFactory,
			rowData, gridData) {
			var vm = this;
			vm.userSettings = USER_SETTINGS;
			vm.logged_in_user = vm.userSettings.user.sso_id;
			var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
			vm.currentyear = filterParams.tax_year;
			vm.loadingData = true;
			vm.tabName = "PartII";
			var partIIIBuilt = false;
			
			vm.cancel = function() {
				$uibModalInstance.dismiss('cancel');
			};
			
			/* 
			 Trying to create an array where each element is an entire row
			 So element X would have a line number, description, and up to 4 values.
			 For example, vm.allPartII[2] should look something like this:
			 {
			   LINE_NO: "2", 
			   DESCRIPTION: "Gross foreign dividends not previously taxed", 
			   A: "0", B: "0", C: "0", D: "0" 
			 }
			 Then we can loop through the array in the view, taking all the values and using them
			 in the HTML.

			 formList is either vm.formListII or vm.formListIII
			 formListToBuild is either vm.allPartII or vm.allPartIII, and both are used in the HTML
			*/
			function buildFormLists(formList, formListToBuild) {
				var start = 0;	
										
				while (start < formList.length) {
					var line = {};
					var skip = false;
					// We are adding 14 each loop because of how the data is structured from the database
					for (var i = start; i < start + 7; i++) {
						var row = formList[i];						
											
						// We don't need to display the lines below in Part II
						if (row.PART_NO === "P2") {
							if (row.LINE_NO === "26" || row.LINE_NO === "27" || row.LINE_NO === "29b" || 
								row.LINE_NO === "29c" || row.LINE_NO === "30") {
								skip = true;
								break;
							}
						}
						
						if (row.ATTRIB_SHORT_CODE === "LINE_NO" && !line.hasOwnProperty('LINE_NO')) {
							line.LINE_NO = row.LINE_NO;
						} else if (row.ATTRIB_SHORT_CODE === "PROPERTY_DESC" && !line.hasOwnProperty('DESCRIPTION')) {
							line.DESCRIPTION = row.DESCRIPTION;
						} else if (row.ATTRIB_COL === 'a' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('A')) {
							line.A = row.ATTRIB_VALUE;
						} else if (row.ATTRIB_COL === 'b' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('B')) {
							line.B = row.ATTRIB_VALUE;
						} else if (row.ATTRIB_COL === 'c' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('C')) {
							line.C = row.ATTRIB_VALUE;
						} else if (row.ATTRIB_COL === 'd' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('D')) {
							line.D = row.ATTRIB_VALUE;
						}
					}
					if (!skip) {
						formListToBuild.push(line);
					}
					start = start + 7;
				}
			}
			
			function buildFormListsPartIII(formList, formListToBuild) {
				var start = 0;
				while (start < formList.length) {
					var line = {};
					var skip = false;
					// We are adding 14 each loop because of how the data is structured from the database
					for (var i = start; i < start + 7; i++) {
						var row = formList[i];
						
						if (row.ATTRIB_SHORT_CODE === "LINE_NO" && !line.hasOwnProperty('LINE_NO')) {
							line.LINE_NO = row.LINE_NO;
						} else if (row.ATTRIB_SHORT_CODE === "PROPERTY_DESC" && !line.hasOwnProperty('DESCRIPTION')) {
							line.DESCRIPTION = row.DESCRIPTION;
						} else if (row.ATTRIB_COL === 'a' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('A')) {
							line.A = row.ATTRIB_VALUE;
						} else if (row.ATTRIB_COL === 'b' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('B')) {
							line.B = row.ATTRIB_VALUE;
						} else if (row.ATTRIB_COL === 'c' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('C')) {
							line.C = row.ATTRIB_VALUE;
						} else if (row.ATTRIB_COL === 'd' && row.hasOwnProperty('ACCT_REF_KEY') && row.IS_EDITABLE_CELL === "Y" && !line.hasOwnProperty('D')) {
							line.D = row.ATTRIB_VALUE;
						}
					}
					if (!skip) {
						formListToBuild.push(line);
					}
					start = start + 7;
				}
			}
			
			// When a tab is clicked, this function is called
			vm.switchTab = function(clickedTab) {
				if (clickedTab === "PartII") {		
					vm.tabName = clickedTab;
				} else if (clickedTab === "PartIII") {
					// We check if we already have the data for Part III
					if (partIIIBuilt === true) {
						vm.tabName = clickedTab;
					}
					// If we don't, then we will call buildFormLists to get the data
					else {
						buildFormListsPartIII(vm.formPartIII, vm.allPartIII);
						vm.tabName = clickedTab;
						partIIIBuilt = true;
					}
				}
			}

			function fetchSchM3records() {
				
				var params = {
					"action_code": 'akh56r',
					"sso_id": vm.logged_in_user,
					"tax_year": vm.currentyear,
					"scenario": null,
					"jcd_key": GlobalService.globalParams.jcd_key,
					"combination_key": rowData.COMBINATION_KEY,
					"jcd_ta_key": null,
					"form_no": vm.activeTab,
					"schedule_name": vm.sn,
					"part_no": vm.pn,
					"section_name": vm.sm
				};

				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
					
					vm.formHeaderIII = "Part III: " + data.jsonObject[1].ATTRIB_DESCRIPTION;
					
					// Part II header is very similar to Part III header, so we can just do this to get it
					vm.formHeaderII = vm.formHeaderIII.split('—')[0];
					vm.formHeaderII = vm.formHeaderII.replace('III', 'II');
					
					vm.tableHeader = $filter("filter")(data.jsonObject, {
						ROW_TYPE: "TH"
					});
					
					vm.formListAll = data.jsonObject.slice(1);
					
					// Taking away headers
					vm.formList = vm.formListAll.filter(function(obj) {
						return obj.ROW_TYPE !== 'TH';
					});
					
					vm.formPartII = [];
					vm.formPartIII = [];
					vm.formPartII = _.filter(vm.formList, function(o1) {
						return o1.PART_NO == "P2";
					});
					vm.formPartIII = _.filter(vm.formList, function(o2) {
						return o2.PART_NO == "P3";
					});
					
					// These will hold just the necessary information we need for the front-end
					vm.allPartII = [];
					vm.allPartIII = [];
					
					buildFormLists(vm.formPartII, vm.allPartII);
					
					// The original table header did not seem correct so I made another one with the headers
					// that are needed
					vm.partIITableHeader = [];
					vm.partIITableHeader.push(vm.tableHeader[0].DESCRIPTION);
					// And the same for part III
					vm.partIIITableHeader = [];
					vm.partIIITableHeader.push(vm.tableHeader[1].DESCRIPTION);
					for (var i = 2; i < 6; i++) {
						vm.partIITableHeader.push(vm.formPartII[i].ATTRIB_DESCRIPTION);
						vm.partIIITableHeader.push(vm.formPartIII[i].ATTRIB_DESCRIPTION);
					}
					
					vm.loadingData = false;
				});
			}
			
			fetchSchM3records();
			
			$scope.formData = {
				"tax_year": rowData.TAX_YEAR,
				"leid": rowData.LEID,
				"le_name": rowData.LE_NAME,
				"le_type": rowData.LE_TYPE,
				"ledger": rowData.LEDGER,
				"taxCode": rowData.CDR_NO,
				"meName": rowData.ME_NAME,
				"taxYearBegin": $filter('date')(rowData.TAX_YEAR_BEGIN, "MM/dd"),
				"taxYearEnd": $filter('date')(rowData.TAX_YEAR_END, "MM/dd/yyyy"),
				"meCode": rowData.ME_CODE
			};
		}
		return controllers;
	});
