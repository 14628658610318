
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.WithHoldTaxService',[])

        .factory("WithHoldTaxFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService,workspaceFactory, USER_SETTINGS,$rootScope) {
            var withHoldTaxFactory = {};           
            withHoldTaxFactory.url ={};
            withHoldTaxFactory.url.non_creditable_save = GENERAL_CONFIG.base_url + "/saveJsonAsPivotObject?action_code=xyw55b"; //action_key=636
            withHoldTaxFactory.url.creditable_save = GENERAL_CONFIG.base_url + "/saveJsonAsPivotObject?action_code=2cn72k";     
            
            withHoldTaxFactory.savePivotedData = function(_data,Details){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);                
                console.log("Save NonCreditable params :",params);
                                
                var promise = $http({
                    method: "post",
                    url: withHoldTaxFactory.url.non_creditable_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });                
                return promise;
            };
            
            withHoldTaxFactory.saveWHCreditablePivotedData = function(_data,Details){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams,actual_params = null;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                actual_params = _.assign({}, _data, params);
                
                if(_data['wht_dividend'] === null) {
                	actual_params['wht_dividend'] = null;
                }
                console.log("Save Creditable params :",params);
                                
                var promise = $http({
                    method: "post",
                    url: withHoldTaxFactory.url.creditable_save,
                    data: actual_params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });                
                return promise;
            };
            
            
            return withHoldTaxFactory;
        }])


    return services;


});