const { VM } = require("handlebars");

define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.ecQuarterMapActionsController', [])
    .controller('ecQuarterMapCreateActionsController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecQuarterMapActionsService', ecQuarterMapCreateActionsController])
    .controller('ecQuarterMapUpdateActionsController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecQuarterMapActionsService', ecQuarterMapUpdateActionsController]);
 
    function ecQuarterMapCreateActionsController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,ecQuarterMapActionsService){
        var vm = this;
               
        vm.modalTitle = "EC QuarterMap";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {};
        vm.dateOptionsYear = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy',
            onClose: function(dateText, inst) {   
            }
        };
 		
        vm.quarterMapEntity_bckup = {
            GROUP_OBJ_KEY:"", 
            TAX_YEAR:workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            RPT_MTH:"",
            TAX_YEAR_END:"",
            FILING_CYCLE:"",
            FROM_TAX_YEAR:"",
            FROM_RPT_MTH:"",
            TAX_YEAR_BEGIN:""
        };

        vm.quarterMapEntity_bckup.TAX_YEAR = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.quarterMapEntity_bckup.TAX_YEAR_BEGIN = "01/01/"+workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.quarterMapEntity_bckup.TAX_YEAR_END = "12/31/"+workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.quarterMapEntity = angular.copy(vm.quarterMapEntity_bckup);

        let loadScenarioTypeCode = function(){
            
            ecQuarterMapActionsService.loadScenarioTypeCode().then((scenarioTypeCode) => {
                console.log("load all Scenario Type Code", scenarioTypeCode);
                vm.allscenarioTypeCode = scenarioTypeCode;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadScenarioDesc = function(selectedValue){
            
            ecQuarterMapActionsService.loadScenarioDesc(selectedValue).then((scenarioDesc) => {
                console.log("load all Scenario Desc", scenarioDesc);
                vm.allscenarioDesc = scenarioDesc;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadGroupObjKey = function(selectedVal1, selectedVal2){
            
            ecQuarterMapActionsService.loadGroupObjKey(selectedVal1, selectedVal2).then((groupObjByYear) => {
                console.log("load all Group Obj", groupObjByYear);
                vm.allGroupObjByYear = groupObjByYear;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadDistFilingCycle = function(){
            ecQuarterMapActionsService.loadDistFilingCycle().then((filingCycle) => {
                console.log("load all Filing Cycle", filingCycle);
                vm.allFilingCycle = filingCycle;
            });
        }
        


        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
            loadScenarioTypeCode();
            loadDistFilingCycle();
        };
		vm.init();

        vm.onScenarioTypeCodeChange = function(){
            console.log(vm.quarterMapEntity.SCENARIO_TYPE_CODE);
            loadScenarioDesc(vm.quarterMapEntity.SCENARIO_TYPE_CODE);
            vm.quarterMapEntity.GROUP_OBJ_KEY = undefined;
            
        }; 

        vm.onPopulateGroupObjYear = function(){
            console.log(vm.quarterMapEntity.SCENARIO_TYPE_CODE+'............'+ vm.quarterMapEntity.SCENARIO_DESC);
            loadGroupObjKey(vm.quarterMapEntity.SCENARIO_TYPE_CODE, vm.quarterMapEntity.SCENARIO_DESC);
        };

        vm.isValid = function(){
            if(vm.quarterMapEntity.SCENARIO_TYPE_CODE == null || 
                vm.quarterMapEntity.SCENARIO_TYPE_CODE == undefined || 
                vm.quarterMapEntity.SCENARIO_TYPE_CODE == ""){
                     vm.quarterMapEntity.SCENARIO_TYPE_CODE_NOT_VALID = true;
                     return false;
            }
            if(vm.quarterMapEntity.SCENARIO_DESC == null || 
                vm.quarterMapEntity.SCENARIO_DESC == undefined || 
                vm.quarterMapEntity.SCENARIO_DESC == ""){
                     vm.quarterMapEntity.SCENARIO_DESC_NOT_VALID = true;
                     return false;
            }  
            if(vm.quarterMapEntity.GROUP_OBJ_KEY == null || 
                vm.quarterMapEntity.GROUP_OBJ_KEY == undefined || 
                vm.quarterMapEntity.GROUP_OBJ_KEY == ""){
                     vm.quarterMapEntity.GROUP_OBJ_KEY_NOT_VALID = true;
                     return false;
            }
            if(vm.quarterMapEntity.RPT_MTH == null || 
                vm.quarterMapEntity.RPT_MTH == undefined || 
                vm.quarterMapEntity.RPT_MTH == ""){
                     vm.quarterMapEntity.RPT_MTH_NOT_VALID = true;
                     return false;
            } 
            if(vm.quarterMapEntity.TAX_YEAR_END == null || 
                vm.quarterMapEntity.TAX_YEAR_END == undefined || 
                vm.quarterMapEntity.TAX_YEAR_END == ""){
                     vm.quarterMapEntity.TAX_YEAR_END_NOT_VALID = true;
                     return false;
            } 
            if(vm.quarterMapEntity.FILING_CYCLE == null || 
                vm.quarterMapEntity.FILING_CYCLE == undefined || 
                vm.quarterMapEntity.FILING_CYCLE == ""){
                     vm.quarterMapEntity.FILING_CYCLE_NOT_VALID = true;
                     return false;
            } 
            if(vm.quarterMapEntity.FROM_TAX_YEAR == null || 
                vm.quarterMapEntity.FROM_TAX_YEAR == undefined || 
                vm.quarterMapEntity.FROM_TAX_YEAR == ""){
                     vm.quarterMapEntity.FROM_TAX_YEAR_NOT_VALID = true;
                     return false;
            } 
            if(vm.quarterMapEntity.FROM_RPT_MTH == null || 
                vm.quarterMapEntity.FROM_RPT_MTH == undefined || 
                vm.quarterMapEntity.FROM_RPT_MTH == ""){
                     vm.quarterMapEntity.FROM_RPT_MTH_NOT_VALID = true;
                     return false;
            } 
            if(vm.quarterMapEntity.TAX_YEAR_BEGIN == null || 
                vm.quarterMapEntity.TAX_YEAR_BEGIN == undefined || 
                vm.quarterMapEntity.TAX_YEAR_BEGIN == ""){
                     vm.quarterMapEntity.TAX_YEAR_BEGIN_NOT_VALID = true;
                     return false;
            }            
            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.quarterMapEntity);
            jsonObj.FROM_TAX_YEAR = jsonObj.FROM_TAX_YEAR.getFullYear().toString();
            jsonObj = _.merge({}, jsonObj , filterParams);
            
            jsonObj = ecQuarterMapActionsService.convertDateFormates(jsonObj);
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params.scenarioCode = vm.quarterMapEntity.SCENARIO_TYPE_CODE;
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=411nrd";
            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "EC Quarter Map created successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.upper = function(configItem) {
            vm.quarterMapEntity.CONFIG_ITEM = configItem.toUpperCase();
        }

        vm.reset = function (){
			vm.quarterMapEntity = angular.copy(vm.quarterMapEntity_bckup);
		};

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };
    }

    function ecQuarterMapUpdateActionsController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, ecQuarterMapActionsService){
        var vm = this;

        vm.modalTitle = "EC Configuration";
		vm.showFormLoader = true;


        vm.showEntityLoader = false;
        vm.isSavingUpdate = false;

        vm.dateOptions = {
            
        };
        vm.dateOptionsYear = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy',
            onClose: function(dateText, inst) { 
                
            }
        };
 		
        vm.quarterMapEntity_bckup = gridData.rowData;
        vm.quarterMapEntity_bckup.FROM_TAX_YEAR = new Date(gridData.rowData.FROM_TAX_YEAR,'0','1');
        vm.quarterMapEntity = angular.copy(vm.quarterMapEntity_bckup);
		
        vm.delete = function(_grp_obj_key, _filingCycle, _rpt_mth){
            console.log("Delete function called");
            console.log(_grp_obj_key+'............'+_filingCycle+'............'+_rpt_mth);
            vm.isSavingDelete = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                grp_obj_key: _grp_obj_key,
                filing_cycle: _filingCycle,
                rpt_mth: _rpt_mth
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wo1977";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Quarter Map deleted successfully", 4000);
                    console.log("deleted", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };        
        
        

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };
    }
    return controllers;
});
