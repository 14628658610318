
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.tagSourcingService',[])

    .factory('tagSourcingServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var tagSourcingServiceFactory = {};
        tagSourcingServiceFactory.url = {};
        tagSourcingServiceFactory.url.saveTagDetails = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wlslig";

        tagSourcingServiceFactory.saveTagData1 = function(_data){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(IRSFormDetails);
            var jsonSettings =  JSON.stringify(_data);
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            console.log(params);
            var promise = $http({
                method: "post",
                url: tagSourcingServiceFactory.url.saveTagDetails,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response.data;
            });
            return promise;
        };


        tagSourcingServiceFactory.saveTagData = function(_data){							
            var jsonObj = _data;	
            var jsonSettings = GlobalService.globalParams;								
                
            //var ssoId = USER_SETTINGS.user.sso_id;
            
            var params = {
                clob_settings: JSON.stringify(jsonSettings),
                clob_data: JSON.stringify(jsonObj)
            };
            params = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario),  GlobalService.globalParams , params);
            console.log('save params', params);
            var promise = $http({
                method: "post",
                url: tagSourcingServiceFactory.url.saveTagDetails,
                data: params
            }).then(function (response) {
                return response.data;
            });
            //return defer.promise;
            return promise;
        };
        
        return tagSourcingServiceFactory
    }])

    return services;

});