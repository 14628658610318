
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.Configuration861Service',[])

    .factory('configuration861Factory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var configuration861Factory = {};
        configuration861Factory.url = {};
        configuration861Factory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=iqec76";       
          
        configuration861Factory.saveConfig = function(_settings, _data){
        		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        		var params = filterParams;
    			var jsonObj = JSON.stringify(_data);
                var jsonSettings =  JSON.stringify(_settings);
              
        		params = _.merge({},  _data[0] , params);
                params = _.merge({},  GlobalService.globalParams , params);
        		params = _.extend({clob_data:jsonObj}, params);
        		params = _.extend({clob_settings:jsonSettings}, params);		  
        		
        		console.log("saveConfig......params::",params);        
        		
        		var promise = $http({
                    method: "post",
                    url: configuration861Factory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
        		
        		
        	
        	return promise;
        } 
        
        return configuration861Factory;
    }])

    return services;

});