/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './FormScheduleM2Ctrl',
    './FormScheduleM2Service',

], function () {
    'use strict';

  
   return angular.module('app.Form-scheduleM2', ['app.FormScheduleM2Ctrl','app.FormScheduleM2Service'])

    });