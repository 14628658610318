define(['angular'],function() {
        'use strict';

    var controllers = angular.module('app.addCommentModalTaxAdjustmentsAttachmentsController', [])
                            .controller('addCommentModalTaxAdjustmentsAttachmentsController',['$rootScope', '$scope', 
                            '$anchorScroll', 'AlertService', 'FileAttachementFactory','CodeComboFactory', 'JsonObjectFactory',
                            'EPLocalTaxAdjustmentsFactory','SERVER_CONFIG', 'GENERAL_CONFIG', 'ModalFactory', 'GlobalService', 
                            '$uibModal', 'USER_SETTINGS', '$uibModalInstance', 'rowData','gridData', 
                            addCommentModalTaxAdjustmentsAttachmentsController ]);

                            // .controller('EpAdjustmentsLocalControllerModal', [ '$rootScope', '$scope', '$anchorScroll', 
                            // 'AlertService',  'CodeComboFactory', 'JsonObjectFactory', 'EPLocalTaxAdjustmentsFactory', 
                            // 'GENERAL_CONFIG', 'ModalFactory', 'GlobalService', 'USER_SETTINGS', epAdjustmentsLocalControllerModal]);
    var URLS = {
        uploadAttachements: '/uploadCommentAttachements'
    }

    function addCommentModalTaxAdjustmentsAttachmentsController($rootScope, $scope, $anchorScroll, AlertService,FileAttachementFactory,
        CodeComboFactory, JsonObjectFactory, EPLocalTaxAdjustmentsFactory, SERVER_CONFIG, GENERAL_CONFIG, ModalFactory, GlobalService,
        $uibModal, USER_SETTINGS, $uibModalInstance, rowData,gridData, thisObj) {

        const vm = this;
        vm.crudLoading = false;
        vm.rowData = rowData;
        vm.gridData = gridData;
        vm.SERVER_CONFIG = SERVER_CONFIG;
        $scope.upload_url = GENERAL_CONFIG.base_url + URLS.uploadAttachements;
        // Assign all the function from 
        _.each(_.keys(FileAttachementFactory.fileUploadConfig), function (item) {
            if (!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
                    vm[item] = _.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
            else vm[item] = FileAttachementFactory.fileUploadConfig[item];
        });
        
        vm.isValidSave = function(dataType) {
            switch(dataType) {
                case "purpose":
                    vm.gridData.formData.isNull_purpose = vm.gridData.formData.purpose ? false : true;
                    break;
                case "book_treatment":
                    vm.gridData.formData.isNull_book_treatment = vm.gridData.formData.book_treatment ? false : true;
                    break;
                case "tax_treatment":
                    vm.gridData.formData.isNull_tax_treatment = vm.gridData.formData.tax_treatment ? false : true;
                    break;
                case "description" :
                    vm.gridData.formData.isNull_description = vm.gridData.formData.description ? false : true;  
                    break;
                default:
                    vm.gridData.formData.isNull_purpose = vm.gridData.formData.purpose ? false : true;
                    vm.gridData.formData.isNull_book_treatment = vm.gridData.formData.book_treatment ? false : true;
                    vm.gridData.formData.isNull_tax_treatment = vm.gridData.formData.tax_treatment ? false : true;
                    vm.gridData.formData.isNull_description = vm.gridData.formData.description ? false : true;  
            }

            return vm.gridData.formData.isNull_purpose || vm.gridData.formData.isNull_book_treatment ||
            vm.gridData.formData.isNull_tax_treatment || vm.gridData.formData.isNull_description;
        };

        vm.save = function () {
            if (vm.isValidSave('all')) return false;//all is just a placeholder and doesn't hold any significance 
        };

        vm.reset = function () {
            vm.gridData.formData.purpose = '';
            vm.gridData.formData.book_treatment = '';
            vm.gridData.formData.tax_treatment = '';
            vm.gridData.formData.description = '';

        };
        
        vm.cancel = function () {
            vm.rowData = null;
            vm.gridData = null;
            $uibModalInstance.dismiss('cancel');
        };
        
    }
});
