define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.reclassLocalController', [])
            .controller('ReclassLocalBookController', ['$rootScope', '$scope', '$filter', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData', 'colData', 'JsonObjectFactory','ReclassLocalFactory','GENERAL_CONFIG', reclassLocalBookController]);



    function reclassLocalBookController($rootScope, $scope, $filter, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, colData, JsonObjectFactory,ReclassLocalFactory,GENERAL_CONFIG) {

    	var vm = this;
        $scope.rowData = rowData;
        $scope.grid_type = $scope.rowData.GRID_TYPE;
        $scope.SELECTED_SYSTEM_ACCT = $scope.rowData.SYSTEM_ACCT;
        $scope.SELECTED_SYSTEM_ACCT_DESC = $scope.rowData.SYSTEM_ACCT_DESC;
        $scope.SELECTED_SYSTEM_BOOK_AMT = $scope.rowData.BOOK_AMT;
        $scope.SELECTED_BOOK_ADJS = $scope.rowData.BOOK_ADJ;
        $scope.accounts = [];
        $scope.selectAccountData = [];
        $scope.sourceAcctType = null;
        $scope.reclassTotal = "";
        $scope.title = "Book Adjustment";
        $scope.RECLASS_DESCRIPTION = "";
        $scope.modal = {};
        $scope.modal.R_STD_DESC = "";
        $scope.modal.R_CMT = "";
        $scope.TRANS_HEADER_KEY = "";
        $scope.reclassArray = [];
        $scope.showReclassForm = false;
        $scope.crudLoading = false;
        $scope.existingLoading = false;
        $scope.noRecords = false;
        $scope.fromScreen = false;
        $scope.colData = colData;
        $scope.RECLASS_TYPE = "";
        // $scope.enableEdit = $scope.rowData.SCENARIO === GlobalService.selected_scenario_desc && $scope.rowData.TAX_YEAR.toString() === GlobalService.globalParams.tax_year;
        //$scope.scenario = workspaceFactory.activeScreen.filters.filterParams.scenario;
        //$scope.taxYear = workspaceFactory.activeScreen.filters.filterParams.tax_year;
        $scope.localChartKey = "";
        $scope.mainScreenKey = ReclassLocalFactory.getActiveScreen();
        $scope.showReclassType = true;
        $scope.showNovYrReclassType = true;
        $scope.delTransDetails = [];
        $scope.local_acc_dropdown_data = [];
        $scope.tp_dropdown_data = [];
        $scope.taxYearBegin = $filter('date')($scope.rowData.TAX_YEAR_BEGIN, "MM/dd");
        $scope.taxYearEnd = $filter('date')($scope.rowData.TAX_YEAR_END, "MM/dd/yyyy");
        $scope.lazyLoadObj = {};
        $scope.viewOnly = false;
        $scope.reclass_desc_dropdown_data = [];
        $scope.comments_desc_dropdown_data = [];
        $scope.reclass_desc_dropdown_data_d = [];
        $scope.comments_desc_dropdown_data_d = [];
        $scope.addTargetAccount = function () {
            var targetAcct = {
                "ACCTTP": "",
                "LOCAL_ACCOUNT_KEY": "",
                "SYSTEM_ACCT_KEY": "",
                "SYSTEM_ACCT":"",
                "SYSTEM_ACCT_DESC":"",
                "LOCAL_ACCT": "",
                "LOCAL_ACCT_DESC": "",
                "TP_ENTITY": "",
                "TP_KEY": null,
                "TP_REP_PD": 0,
                "IS_TP_EXISTS": false,
                "BOOK_AMT": null,
                "PREV_ADJ_AMT": null,
                "CUR_ADJ_AMT": null,
                "TYPE": "blank",
                "IS_DELETE": "NO",
                "TRANS_DETAILS_KEY": 0,
                "ISBOOKAMTLOADING": false,
                "ISBOOKADJLOADING": false,
                "INVALIDACCTTP": false,
 //               "ISSUE_ID" : 0
            };

            $scope.accounts.push(targetAcct);
        };

        /*$scope.validateAccountTPKey = function (_accountKey, _tpKey, _tpRepPd, _index) {
            
            if (_.find($scope.accounts, {'ACCTTP': "" + _accountKey + _tpKey + _tpRepPd}) !== undefined) {
                AlertService.add("error", "The Account/Trading Partner combination has already been used for this adjustment. Please correct the errors.", 4000);
                
                $timeout(function () {
                    $scope.accounts.splice(_index, 1);
                });
               
                return false;
            }
        };*/
        
        $scope.setTradingPartner = function (tp, index, form) {
            var currAcct = $scope.accounts[index];
            
            currAcct["TP_ENTITY"] = tp.TP_LEID + "-" + tp.TP_CDR_NO + "(Pd:" + tp.TP_REP_PD + ")";
            currAcct["TP_KEY"] = tp.TP_KEY;
            currAcct["TP_REP_PD"] = tp.TP_REP_PD;
            currAcct["IS_TP_SELECTED"] = true;
            currAcct["ACCTTP"] = "" + currAcct["LOCAL_ACCT_KEY"] + tp.TP_KEY + tp.TP_REP_PD;
            currAcct["ISBOOKAMTLOADING"] = true;
            currAcct["ISBOOKADJLOADING"] = true;
           
            
            if(tp.TP_KEY !== 0) {
                $scope.validateAcctTp(index, form);
               //$scope.validateAccountTPKey(currAcct["LOCAL_ACCT_KEY"], tp.TP_KEY, tp.TP_REP_PD, index);
                var AcctTpKey = "" + currAcct["LOCAL_ACCT_KEY"] + currAcct["TP_KEY"];
                //$scope.getAccounts(AcctTpKey, 'TP', index);
            
            
                var params = {
                    "action_code": 'unmge8',
                    "screen_key": $scope.mainScreenKey,
                    "combination_key": $scope.rowData.COMBINATION_KEY,
                    "chart_key": $scope.rowData.LOCAL_CHART_KEY,
                    "source_type": ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? 'D' : 'F'),
                    "search_type": 'TP',
                    "search_string": AcctTpKey
                };

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        AlertService.add("error", data.errorMessage);
                    } else {
                        currAcct["ISBOOKAMTLOADING"] = false;
                        currAcct["ISBOOKADJLOADING"] = false;
                        if (data.jsonObject.length) { 
                                
                                var bookAmt = data.jsonObject[0].BOOK_AMT;
                                var bookAdj = data.jsonObject[0].BOOK_ADJ;
                                
                                $scope.accounts[index]["BOOK_AMT"] = bookAmt;
                                $scope.accounts[index]["PREV_ADJ_AMT"] = bookAdj;

                        } else {
                            $scope.accounts[index]["BOOK_AMT"] = 0;
                            $scope.accounts[index]["PREV_ADJ_AMT"] = 0;
                        }
                    }
                });
            }
            
        };
        function init() {
            if($scope.rowData.SOURCE_SYSTEM === 'DCS') {
                getTradingPartners();
            }
            $scope.viewOnly = false;
            $scope.accounts = [];
            $scope.delTransDetails = [];
            $scope.crudLoading = false;
            $scope.RECLASS_DESCRIPTION = "";
            $scope.modal.R_STD_DESC = "";
            $scope.modal.R_CMT = "";
            $scope.isMaxLimitReached = false;
            $scope.reclassArray = [];
            $scope.TRANS_HEADER_KEY = "";
            $scope.existingLoading = false;
            $scope.noRecords = false;
            $scope.RECLASS_TYPE = "";
            $scope.showReclassType = ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? false : true);
            $scope.showNovYrReclassType = ($scope.rowData.NOV_YEAR_END === 'N' ? false : true);
            $scope.loadingTpAcct = true;
            $scope.loadingSysAcct = true;
            $scope.detailsAmt = { "BOOK_AMT": 0,
                                  "PREV_ADJ_AMT": 0
                                };
            getAccounts();
            if (rowData.SOURCE_SYSTEM == 'DCS'){
				getstdreclass();
				getcmtreclass();
				}
            switch ($scope.colData.map) {
                case 'BOOK_ADJ':
                    $scope.RECLASS_TYPE = 'RCY';
                    break;
                case 'CY_DECEMBER':
                    $scope.RECLASS_TYPE = 'CYD';
                    break;
                case 'PY_DECEMBER':
                    $scope.RECLASS_TYPE = 'PYD';
                    break;
                default:
                    $scope.RECLASS_TYPE = 'RCY';
            }
            
            if($scope.rowData.hasOwnProperty("TRANS_TYPE_ACTUAL")) {
                switch ($scope.rowData.TRANS_TYPE_ACTUAL) {
                    case 'LOCAL_BOOK_ADJ':
                        $scope.RECLASS_TYPE = 'RCY';
                        break;
                    case 'LOCAL_BOOK_ADJ_CY':
                        $scope.RECLASS_TYPE = 'CYD';
                        break;
                    case 'LOCAL_BOOK_ADJ_PY':
                        $scope.RECLASS_TYPE = 'PYD';
                        break;
                    default:
                        $scope.RECLASS_TYPE = 'RCY';
                }
            }
           

            if ($scope.reclassFrom !== undefined) {
                $scope.reclassFrom.$setPristine();
                $scope.reclassFrom.$setUntouched();
            }

            if ($scope.rowData.COMBINATION_KEY) {
                
                CodeComboFactory.setCombinationCodeByKey($scope.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                $scope.fromScreen = true;
                CodeComboFactory.showFormBtn = false;

                loadExistingReclass();
                $scope.sourceAcctType = $scope.rowData.SYSTEM_ACCT_TYPE;
                if($scope.grid_type==='LOCAL_BOOK_TO_TAX_SUMMARY') {
                    
                    getAccountData($scope.rowData, 0);
                } else {
                    $scope.addTargetAccount();
                }
                


            } else {
                CodeComboFactory.toggle = true;
            }
        }

        init();
        function getstdreclass () {
            // alert(val);
            var params = {
                "action_code": 'akjjtr',
				"tax_year" : GlobalService.globalParams.tax_year,
                "scenario" : GlobalService.globalParams.scenario,
				"jcd_key" : GlobalService.globalParams.jcd_key,
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                    	$scope.reclass_desc_dropdown_data = data.jsonObject;
                    	for (var r in $scope.reclass_desc_dropdown_data)
                    		{
                    		$scope.reclass_desc_dropdown_data_d.push($scope.reclass_desc_dropdown_data[r].RECLASSSTDDESC)
                    		}
                    	$scope.reclass_desc_dropdown_data_d = _.uniq(
                    			$scope.reclass_desc_dropdown_data_d,
								JSON.stringify)
                    	
                    }
                }
            });
        };
        function getcmtreclass () {
            // alert(val);
            var params = {
                "action_code": 'jo8asp',
				"tax_year" : GlobalService.globalParams.tax_year,
                "scenario" : GlobalService.globalParams.scenario,
				"jcd_key" : GlobalService.globalParams.jcd_key,
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                    	$scope.comments_desc_dropdown_data = data.jsonObject;
                    	for (var c in $scope.comments_desc_dropdown_data)
                    		{
                    		$scope.comments_desc_dropdown_data_d.push($scope.comments_desc_dropdown_data[c].RECLASSSTDCOMMENTS)
                    		}
                    	$scope.comments_desc_dropdown_data_d = _.uniq(
                    			$scope.comments_desc_dropdown_data_d,
								JSON.stringify)
                    	
                    }
                }
            });
        };


        $scope.$on('CCKeySet', function (event, data) {

            $scope.localChartKey = CodeComboFactory.chartKey;
            $scope.showReclassForm = true;

            if (!$scope.accounts.length && !$scope.fromScreen) {
                $scope.addTargetAccount();
            }

        });

        $scope.updateReclassType = function(val) {
            $scope.RECLASS_TYPE = val;
        }

        $scope.setSourceTotal = function (_index) {
            var account = $scope.accounts[_index];
            //console.log('account at index ' + _index, account)
            account.TOTAL = parseFloat(account.BOOK_AMT) + parseFloat(account.PREV_ADJ_AMT);
            if (account.CUR_ADJ_AMT !== undefined) {
                account.TOTAL += parseFloat(account.CUR_ADJ_AMT);
            }
            setReclassTotal();
        };

        $scope.validateDesc = function() {
            if($scope.RECLASS_DESCRIPTION.length === 250) {
                $scope.isMaxLimitReached = true;
            } else {
                $scope.isMaxLimitReached = false;
            }
        };
        
        $scope.removeMessage = function() {
            $scope.isMaxLimitReached = false;
        };

        var setExistingReclassTotal = function (array) {
            angular.forEach(array, function (value, key) {
                angular.forEach(value.ACCOUNTS, function (value, key) {
                    value.TOTAL = parseFloat(value.BOOK_AMT) + parseFloat(value.PREV_ADJ_AMT);
                    if (value.CUR_ADJ_AMT !== undefined) {
                        value.TOTAL += parseFloat(value.CUR_ADJ_AMT);
                    }
                });
            });
        };

        $scope.removeAccount = function (_index) {

            var getTransType = $scope.accounts[_index]["TYPE"];
            if(getTransType === 'edit') {
                
                $scope.accounts[_index]["IS_DELETE"] = 'YES';
                $scope.delTransDetails.push($scope.accounts[_index]);
            }
            $scope.accounts.splice(_index, 1);
            setReclassTotal();
        };

        var setReclassTotal = function () {
            var total = 0;
            angular.forEach($scope.accounts, function (value, key) {
                if (value.CUR_ADJ_AMT && !isNaN(value.CUR_ADJ_AMT)) {
                    total += parseFloat(value.CUR_ADJ_AMT);
                }
            });
            $scope.reclassTotal = total;
        };

        function getAccounts(val, _index) {
            
            console.log('index in getAccounts : ' , _index);
            
            var balSheetAccts = [1, 2, 3];

            var acctType = $scope.sourceAcctType;
            var acct_type_str = "", is_system_acct_search = 'N';

            if (balSheetAccts.includes(acctType)) {
                acct_type_str = '1,2,3';
            } else {
                acct_type_str = '5,6,7';
            }

            if($scope.grid_type=='SYSTEM_BOOK_TO_TAX_SUMMARY' && _index === 0) {
                is_system_acct_search = 'Y';
            }
            var params = {
                "action_code": 'unmge8',
                "screen_key": $scope.mainScreenKey, 
                "system_acct_search":is_system_acct_search,               
                "combination_key": $scope.rowData.COMBINATION_KEY,
                "ge_gecs_entity": $scope.rowData.GE_GECS_ENTITY,
                "chart_key": $scope.rowData.LOCAL_CHART_KEY,
                "parent_system_acct_key": $scope.rowData.SYSTEM_ACCT_KEY,
                "source_type": ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? 'D' : 'F'),
                "search_type": 'ACCT',
                "search_string": val,
                "acct_type_str": acct_type_str
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) { 
                        $scope.local_acc_dropdown_data = data.jsonObject;
                        $scope.lazyLoadObj.totalDrpList = $scope.local_acc_dropdown_data;
                    	$scope.loadingSysAcct = false;
                        $scope.lazyLoadObj.cachedDrpList = $scope.local_acc_dropdown_data.slice(0, 10);
                    }
                }
            });
        };

        $scope.getTradingPartners_fir = function (val) {
            // alert(val);
            var params = {
                "action_code": '50swfi',
                "tax_year": $scope.taxYear,
                "scenario": $scope.scenario,
                "search_string": val
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };

        function getTradingPartners(val) {
            var params = {
                "action_code":  'sckfcq', //'sckfcq', '50swfi',
                "tax_year": $scope.taxYear,
                "scenario": $scope.scenario,
                "search_string": val
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        $scope.loadingTpAcct = false;
                        if($scope.rowData.SOURCE_SYSTEM === 'DCS') {
                            $scope.tp_dropdown_data = data.jsonObject;
                    	   
                        } else {
                            return data.jsonObject;
                        }
                    	
                    }
                }
            });
        };


        $scope.setAccount = function (account, index) {
            console.log("--selected account object is below and selected account index is --- " + index);
            console.log(account);
            
            account.COMBINATION_KEY = $scope.rowData.COMBINATION_KEY;
            if ($scope.accounts.length === 1 && $scope.rowData.ACCOUNT_KEY === undefined && !$scope.fromScreen) {
                $scope.rowData.ACCOUNT_KEY = account.ACCOUNT_KEY;
                alert("load existing reclass 1");
                loadExistingReclass();
            }
            getAccountData(account, index);
        };

        $scope.validateAcctTp = function(_index, form) {
            
            var currAcct = $scope.accounts[_index];
            var _accountKey =  currAcct["LOCAL_ACCT_KEY"];
            var _tpKey = currAcct["TP_KEY"];
            var _tpRepPd = currAcct["TP_REP_PD"];
           
            var getIndexFoundAt = _.findIndex($scope.accounts, {'ACCTTP': "" + _accountKey + _tpKey + _tpRepPd});
//            var duplicateLocalAccts = _.filter($scope.accounts, function(o){
//				return o.LOCAL_ACCT_KEY == _accountKey;
//			})
            if(getIndexFoundAt < 0 || _index === getIndexFoundAt) {
//			if(duplicateLocalAccts.length < 1 || duplicateLocalAccts.length == 1 ){
                $scope.reclassFrom.innerForm.tradingPartner.$setValidity("invalidAcctTPCombo", true);
                currAcct["INVALIDACCTTP"] = false;
            } else {
                 $scope.reclassFrom.innerForm.tradingPartner.$setValidity("invalidAcctTPCombo", false);
                //AlertService.add("error", "The Account/Trading Partner combination has already been used for this adjustment. Please correct the errors.", 4000);
                
                $timeout(function () {
                    //$scope.accounts.splice(_index, 1);
                    currAcct["TP_ENTITY"] =  "";
                    currAcct["TP_KEY"] = 0;
                    currAcct["TP_REP_PD"] = 0,
                    currAcct["IS_TP_EXISTS"] = false;
                    currAcct["ACCTTP"] = "";
                    currAcct["ISBOOKAMTLOADING"] = false;
                    currAcct["ISBOOKADJLOADING"] = false;
                    currAcct["INVALIDACCTTP"] = true;
//                    currAcct.tpObject = {
//                        "TP_ENTITY": "",
//                        "TP_KEY": 0,
//                        "TP_REP_PD": 0,
//                        "TP_LEID": "",
//                        "TP_CDR_NO": ""
//                    };
                }); 
            }
        };

     
        function getAccountData(_data, _index) {
            var adj_source = 'M';
            var tp_exists = ((_data.TP_KEY === 0) ? false : true);
            var tp_rep_pd = ((_data.TP_KEY === 0) ? 0:_data.TP_REP_PD);

            var tp_entity = ((_data.TP_KEY === 0) ? "" : _data.TP_LEID + "-" + _data.TP_CDR_NO + "(Pd:" + tp_rep_pd + ")");
            var type = ((_index < 1) ? "source" : "target");
            
            if(_index < 1 && $scope.grid_type==='LOCAL_BOOK_TO_TAX_SUMMARY') {
                var tot_book_adj = _data.BOOK_ADJ + _data.PY_DECEMBER + _data.CY_DECEMBER;
            } else {
                 tot_book_adj = _data.BOOK_ADJ;
            }
            var record = {
                "ACCTTP": "" + _data.LOCAL_ACCT_KEY + _data.TP_KEY + tp_rep_pd,
                "LOCAL_ACCT_KEY": _data.LOCAL_ACCT_KEY,
                "SYSTEM_ACCT_KEY": _data.SYSTEM_ACCT_KEY,
                "SYSTEM_ACCT":_data.SYSTEM_ACCT,
                "SYSTEM_ACCT_DESC": _data.SYSTEM_ACCT_DESC,
                "LOCAL_ACCT": _data.LOCAL_ACCT,
                "LOCAL_ACCT_DESC": _data.LOCAL_ACCT_DESC,
                "TP_ENTITY": tp_entity,
                "TP_KEY": _data.TP_KEY,
                "TP_REP_PD": tp_rep_pd,
                "IS_TP_EXISTS": tp_exists,
                "BOOK_AMT": _data.BOOK_AMT,
                "PREV_ADJ_AMT": tot_book_adj,
                "CUR_ADJ_AMT": 0,
                "TYPE": type,
                "TRANS_DETAILS_KEY": 0,
                "IS_DELETE": "NO"
            };
            
            console.log('record', record);
            
            // prepopulate trading partner and it should be editable
            if(_data.TP_KEY !== 0) {
                record.tpObject = {
                    "TP_ENTITY": tp_entity,
                    "TP_KEY": _data.TP_KEY,
                    "TP_REP_PD": tp_rep_pd,
                    "TP_LEID": _data.TP_LEID,
                    "TP_CDR_NO": _data.TP_CDR_NO
                };
          
            }
          
            if (typeof $scope.accounts[_index] === 'undefined') {
                if($scope.rowData.hasOwnProperty("TRANS_TYPE_ACTUAL")) {
                    
                
                    if($scope.rowData.TRANS_TYPE_ACTUAL === "LOCAL_BOOK_ADJ") {
                        adj_source = 'M';
                    } else if ($scope.rowData.TRANS_TYPE_ACTUAL === "LOCAL_BOOK_ADJ_PY") {
                        adj_source = 'P';
                    } else {
                        adj_source = 'C';
                    }
                    
                    ReclassLocalFactory.getAmountsForDtlsAdj( $scope.rowData.COMBINATION_KEY, $scope.rowData.LOCAL_ACCT_KEY, $scope.rowData.LOCAL_CHART_KEY,$scope.rowData.TP_KEY,adj_source).then(function(res) {
                        record.BOOK_AMT =  res[0].LOCAL_BOOK_AMT;
                        record.PREV_ADJ_AMT =  res[0].LOCAL_BOOK_ADJ;
                        
                       
                    });
                    
                }
                record.TYPE = "source";

                $scope.accounts.push(record);
                $scope.addTargetAccount();
                //console.log("source acct type is : " + $scope.sourceAcctType);
            } else {

                /*if( _.find($scope.accounts, {'ACCTTP': record.ACCOUNT_KEY + record.TP_KEY}) !== undefined ){
                 AlertService.add("error", "The Account AND TP Key combination "+record.ACCOUNT+ "/ " + record.TP_ENTITY + " has already been used in this reclass", 4000);
                 $scope.accounts.splice(_index,1);
                 //$scope.addTargetAccount();
                 return false;
                 }*/
                /*console.log('prnting separate attributes');
                console.log(record.ACCOUNT_KEY + "," + record.TP_KEY + "," + record.TP_REP_PD);*/
                //$scope.validateAccountTPKey(record.ACCOUNT_KEY, record.TP_KEY, record.TP_REP_PD, _index);
                record.TYPE = "target";
                if (_index === 0) {
                    if($scope.grid_type==='LOCAL_BOOK_TO_TAX_SUMMARY') {
                        record.TYPE = "source";
                    }
                    
                    $scope.addTargetAccount();
                }
                $scope.accounts[_index] = record;
            }
            // $scope.setSourceTotal($scope.accounts.length - 1);

            /*var params = {
             "action_id":21,
             "combination_key":_data.COMBINATION_KEY,
             "LOCAL_ACCT_KEY": _data.ACCOUNT_KEY
             };
             
             if(typeof $scope.accounts[_index] !== 'undefined') {
             $scope.accounts[_index].TYPE = 'loading';
             }
             JsonObjectFactory.getJSONObj('loadJsonObject.ge',params).then(function(data) {
             
             if( data.errorMessage &&   data.errorMessage.length > 0 && data.errorMessage !== 'null' ){
             AlertService.add("error", data.errorMessage, 4000);
             }else{
             
             
             if(data.jsonObject && data.jsonObject.length && data.jsonObject[0].ACCOUNT_KEY ){
             var record  = data.jsonObject[0];
             
             console.log("----------printing record 1 ----------");
             console.log(record);
             record.CUR_ADJ_AMT = null;
             
             if(typeof $scope.accounts[_index] === 'undefined') {
             record.TYPE = "source";
             
             $scope.accounts.push(record);
             $scope.addTargetAccount();
             }else{
             if( _.find($scope.accounts, {'ACCOUNT_KEY': record.ACCOUNT_KEY}) &&
             _.find($scope.accounts, {'TP_KEY': record.TP_KEY}) !== undefined ){
             AlertService.add("error", "The Account AND TP Key combination "+record.ACCOUNT+ "/ " + record.TP_ENTITY + " has already been used in this reclass", 4000);
             $scope.accounts.splice(_index,1);
             $scope.addTargetAccount();
             return false;
             }
             record.TYPE = "target";
             if(_index === 0 ){
             record.TYPE = "source";
             $scope.addTargetAccount();
             }
             $scope.accounts[_index] =  record;
             }
             $scope.setSourceTotal($scope.accounts.length - 1);
             }else{
             $scope.accounts.splice(_index,1);
             $scope.addTargetAccount();
             AlertService.add("error", "The selected Account did not return a book amount", 4000);
             }
             
             }
             });*/

        }

        $scope.showDetails = function (_index, _boolean) {
            $scope.reclassArray[_index].open = _boolean;
        };



        $scope.deleteReclass = function (reclass) {
            var returnObj = {};
            var deleteObj = [];
            $scope.existingLoading = true;
            //returnObj.reclass_description = $scope.RECLASS_DESCRIPTION;

            returnObj.trans_header_key = reclass.TRANS_HEADER_KEY;
            returnObj.type = "D";
            returnObj.combination_key = $scope.rowData.COMBINATION_KEY;
            returnObj.chart_key = $scope.rowData.LOCAL_CHART_KEY;
            //returnObj.screen_key = 23;
            //var accountArray = [];
            _.forEach(reclass.ACCOUNTS, function (value, key) {

                var tempObj = {
                		COMBINATION_KEY : $scope.rowData.COMBINATION_KEY,
                		ACCT_KEY : value.LOCAL_ACCT_KEY,
                		CHART_KEY : $scope.rowData.LOCAL_CHART_KEY,
                		TP_KEY : value.TP_KEY
                }
                deleteObj.push(tempObj);
            });
            var message = "Reclass has been successfully deleted";
            reclassCRUDHttp(returnObj, '', message, deleteObj);
        };


        $scope.reset = function () {
            init();
        };



        $scope.save = function () {
            //alert("the adjustment type selected is : " + $scope.RECLASS_TYPE);
            $scope.isMaxLimitReached = false;
            if ($scope.reclassFrom.$invalid) {
                $scope.reclassFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            $scope.crudLoading = true;
            var objSettings = [];
            var returnObj = {};
            var reclassMainType, reclassSubtype;
            returnObj.RECLASS_DESCRIPTION = $scope.RECLASS_DESCRIPTION;
            var txIssueId = GlobalService.globalParams.issue_id_for_tx_save;
            returnObj.ISSUE_KEY = txIssueId === undefined || txIssueId === '' ? 
                (GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)) 
                : txIssueId;
            
            // returnObj.ISSUE_KEY = GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id);
            
            //returnObj.TRANS_HEADER_KEY = $scope.TRANS_HEADER_KEY;
            if (rowData.SOURCE_SYSTEM == 'DCS')
			{
			var reclass_std_desc = "";
			for (var g in $scope.reclass_desc_dropdown_data)
				{
				if ($scope.reclass_desc_dropdown_data[g].RECLASSSTDDESC == $scope.modal.R_STD_DESC)
					{
					reclass_std_desc = $scope.reclass_desc_dropdown_data[g].RECLASS_STD_DESC_KEY;
					}
				}
			var reclass_cmt = "";
			for (var h in $scope.comments_desc_dropdown_data)
				{
				if ($scope.comments_desc_dropdown_data[h].RECLASSSTDCOMMENTS == $scope.modal.R_CMT)
					{
					reclass_cmt = $scope.comments_desc_dropdown_data[h].RECLASS_STD_COMMENTS_KEY;
					}
				}
			returnObj.reclassStdDesc =reclass_std_desc;
			returnObj.reclassStdComments = reclass_cmt;
			}
            returnObj.TYPE = ($scope.TRANS_HEADER_KEY !== "") ? "U" : "C";
            returnObj.TRANS_HEADER_KEY = ($scope.TRANS_HEADER_KEY !== "") ? $scope.TRANS_HEADER_KEY : 0;

           
            switch ($scope.RECLASS_TYPE) {
                case 'RCY':
                    reclassMainType = 'R';
                    reclassSubtype = 'M';
                    break;
                case 'CYD':
                    reclassMainType = 'R';
                    reclassSubtype = 'C';
                    break;
                case 'PYD':
                    reclassMainType = 'R';
                    reclassSubtype = 'P';
                    break;
                case 'SCY':
                    reclassMainType = 'S';
                    reclassSubtype = 'M';
                    break;
            }
            returnObj.tax_year = ReclassLocalFactory.getScreenFilters().tax_year;
            returnObj.RECLASSMAINTYPE = reclassMainType;
            returnObj.RECLASSSUBTYPE = reclassSubtype;
            returnObj.ADJ_ORIGIN_SOURCE = $scope.rowData.SOURCE_SYSTEM;
            returnObj.SOURCE_SYSTEM_FLAG = (returnObj.ADJ_ORIGIN_SOURCE === 'DCS') ? 'D':'F'; 
            var message = (returnObj.type === "U") ? "Reclass has been successfully updated" : "Reclass has been successfully created";
            returnObj.COMBINATION_KEY = $scope.rowData.COMBINATION_KEY;
            returnObj.CHART_KEY = $scope.rowData.LOCAL_CHART_KEY;
            returnObj.SCREEN_KEY = 23;
            returnObj.TRANS_DETAILS_KEY = {};
            
            var accountArray = [];
            var accounts = $scope.accounts;
            
           /* console.log('-----------accounts for saving ---------------');
            console.log(accounts);*/
            _.forEach($scope.accounts, function (value, key) {

                var accountM = new accountModel(value.LOCAL_ACCT, value.LOCAL_ACCT_KEY, value.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
                accountArray.push(accountM);
            });
            
            //alert($scope.delTransDetails.length);
            if($scope.delTransDetails.length) {
                 _.forEach($scope.delTransDetails, function (value, key) {

                    var accountM = new accountModel(value.LOCAL_ACCT, value.LOCAL_ACCT_KEY, value.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
                    accountArray.push(accountM);
                });
            
            }
            
            objSettings.push(returnObj);
            reclassCRUDHttp(objSettings, accountArray, message, {});

        };


        function reclassCRUDHttp(returnObj, accountArray, message, deleteObj) {
            
            if(returnObj.type === 'D') {
                ReclassLocalFactory.deleteReclass(returnObj, deleteObj).then(function (result) {
                    GlobalService.globalParams.issue_id_for_tx_save = '';
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                        //BROADCAST CHANGE
                       
                        var args = {combination_keys: $scope.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: $scope.rowData.COMBINATION_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                        $uibModalInstance.close();
                        //init();
                    }
                });
            } else {
                
            
                ReclassLocalFactory.saveReclass(returnObj, accountArray).then(function (result) {
                    GlobalService.globalParams.issue_id_for_tx_save = '';
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                        //BROADCAST CHANGE
                        
                        var args = {combination_keys:$scope.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: $scope.rowData.COMBINATION_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                        $uibModalInstance.close();
                       // init();
                    }
                });
            }
        }

        function accountModel(accountCode, acctKey, tp_key, amount, trans_dtls_key, is_delete) {
            this.ACCOUNT_CODE = accountCode;
            this.ACCT_KEY = acctKey;
            this.TP_KEY = tp_key;
            this.AMOUNT = amount;
            this.TRANS_DETAILS_KEY = trans_dtls_key;
            this.IS_DELETE = is_delete;
        }

        accountModel.prototype.addAccount = function () {

        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };



        $scope.loadReclass = function (_index) {
            $scope.delTransDetails = [];
            var data = $scope.reclassArray[_index];
            
            $scope.RECLASS_DESCRIPTION = data.ADJ_DESC;
            if (rowData.SOURCE_SYSTEM == 'DCS')
			{
            	 $scope.modal.R_STD_DESC = data.RECLASS_STD_DESC;
                 $scope.modal.R_CMT = data.RECLASS_STD_COMMENTS;
			}
            $scope.TRANS_HEADER_KEY = data.TRANS_HEADER_KEY;
            $scope.accounts = data.ACCOUNTS;
            $scope.RECLASS_TYPE = data.SHORT_ADJ_TYPE;
            if (data.SHORT_ADJ_TYPE== 'A')
            	{
            	$scope.viewOnly = true;
            	}
            else{
            	$scope.viewOnly = false;

            }
            _.forEach($scope.accounts, function (value, key) {
                $scope.setSourceTotal(key);
            });

            setReclassTotal();
            $rootScope.$broadcast('table.edit', data);
        };


        function loadExistingReclass() {
            $scope.existingLoading = true;
            var params = {
                "action_code": 'clgdjl',
                "screen_key": $scope.mainScreenKey,
                "combination_key": $scope.rowData.COMBINATION_KEY,
                "chart_key": $scope.rowData.LOCAL_CHART_KEY,
                "account_key": $scope.rowData.SYSTEM_ACCT_KEY
            };
            
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                
                
                $scope.reclassArray = formatReclassData(data.jsonObject);
                
                setExistingReclassTotal($scope.reclassArray);


                $scope.existingLoading = false;
                if ($scope.reclassArray.length === 0) {
                    $scope.noRecords = true;
                }
            });
        }

        function formatReclassData(_data) {
            var reclasses = [];
            var accounts = [];
            _.forEach(_data, function (value, key) {

                if (value.ROW_INDICATOR === 1) {
                    
                    var ReclassObj = {};
                    ReclassObj = value;
                    ReclassObj.ACCOUNTS = [];
                    reclasses.push(ReclassObj); // JUST USE VALUE
                 
                } else {
                    var AccountObj = {};
                    AccountObj = value;
                    /*if (AccountObj.ACCOUNT === $scope.rowData.LOCAL_ACCT) {
                        AccountObj.TYPE = "source";
                    }*/
                  
                    AccountObj.TYPE = "edit";
                    accounts.push(AccountObj);

                }
            });
            
          
            _.forEach(reclasses, function (reclassVal, reclassKey) {
                reclasses[reclassKey].ACCOUNTS = [];
                var row_one_trans_header_key = reclassVal.TRANS_HEADER_KEY;

                _.forEach(accounts, function (value, key) {

                    var account_trans_header_key = value.TRANS_HEADER_KEY;
                    
                    if (value.ROW_INDICATOR !== 1 && row_one_trans_header_key === account_trans_header_key) {
                      reclasses[reclassKey].ACCOUNTS.push(value);
                      
                    }

                });
            });

            accounts = null;
            return reclasses;

        }
    }///////////////END CTRL

    return controllers;


}); 