define([
    'angular',
    './Form8865SchdKControllers',
    './Form8865SchdKServices'


], function () {
    'use strict';
    return angular.module('app.F8865SchdK', ['app.Form8865SchdKControllers','app.Form8865SchdKServices'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {


        }]);

});