define([
    'angular',
    './methodSettlementController',
    './methodSettlementService'

], function () {
    'use strict';
    return angular.module('app.methodSettlement', ['app.methodSettlementController','app.methodSettlementService'] )


});