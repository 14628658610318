/**
 * Created by Achin Kumar on 04/08/2019.
 */


define([
    'angular'

], function () {
    'use strict';

    var directives =  angular.module('app.processMapConfigDirectives',[])
    .directive('btDropPlacemat', ['$rootScope', '$compile', function( $rootScope, $compile) {
        return {
            restrict: 'A',
            scope: {
                dropResult: '&' 
            },
            link:function ( scope, element, attrs ) {
                element.on('drop', function(event) {
                    scope.dropResult();
                });
            }
        };
    }])

    .directive('btDraggablePlacemat', ['$rootScope', '$compile', function( $rootScope, $compile) {
        return {
            restrict: 'A',
            scope: {
                dragActivity : '&'
            },
            link:function ( scope, element, attrs ) {
                element.on('drag', function(event) {
                    scope.dragActivity();
                });
            }
        };
    }])
    
    return directives;
});

