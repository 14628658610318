define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.pshipSetupDiagController', [])
            .controller('pshipSetupDiagController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'pshipSetupDiagServiceFactory',
                'rowData', 'gridData', pshipSetupDiagController]
            );

        function pshipSetupDiagController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, pshipSetupDiagServiceFactory, rowData, gridData) {


            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.userSettings = USER_SETTINGS;

            vm.tax_year = filterParams.tax_year;
            vm.scenario = filterParams.scenario;
            vm.jcd_key = GlobalService.globalParams.jcd_key;
            vm.logged_in_user = vm.userSettings.user.sso_id;

            vm.currentyear = filterParams.tax_year;
            vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
            vm.isSaveClicked = false;
            vm.markPshipsReviewedDtls = [];

            if(gridData != null) {

                vm.gridDataMultipleSelect =  _.clone(gridData);

                if (vm.gridDataMultipleSelect != undefined) {

                    for(let key in vm.gridDataMultipleSelect) {
                        let gridObj = {};
                        gridObj["le_key"] = vm.gridDataMultipleSelect[key].data.LE_KEY;
                        vm.markPshipsReviewedDtls.push(gridObj);
                    }
                }
            }

            vm.fetchMaxCountEntities = function() {
                var params = { "action_code": 'mysfkb', "screen_key": GlobalService.globalParams.screen_key};
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    vm.markAsReviewedLimit = data.jsonObject[0].ATTRIB_VALUE;
                });
            }

            vm.fetchMaxCountEntities();

            vm.confirm_mark_for_reviewed = function() {

                if(vm.markPshipsReviewedDtls.length > parseInt(vm.markAsReviewedLimit)){
                    AlertService.add("error", "Can't select more than " + vm.markAsReviewedLimit + " entities");
                    return;
                }

                let returnClobSettingsObj = {};

                returnClobSettingsObj['tax_year'] = vm.tax_year;
                returnClobSettingsObj['scenario'] = vm.scenario;
                returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                returnClobSettingsObj['sso_id'] = vm.logged_in_user;

                let ecSaveDtls = vm.markPshipsReviewedDtls;
                if(vm.markPshipsReviewedDtls != undefined && vm.markPshipsReviewedDtls.length > 0) {
                    let message = "Submitted Selected Partnership(s) to Marked as Reviewed";
                    sendDetails(returnClobSettingsObj,ecSaveDtls, message);
                }
            };

            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            function sendDetails(returnClobSettingsObj, ecSaveDtls, message) {
                $rootScope.$broadcast("showLoader", true);
                $uibModalInstance.dismiss('cancel');
                pshipSetupDiagServiceFactory.markAsReviewed(returnClobSettingsObj,ecSaveDtls).then(function(result) {
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        vm.isSaveClicked = false;
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                    };
                    $rootScope.$broadcast("showLoader", false);
                });
            }
        }

        return controllers;
    }
);