
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.ftcCommentsService',[])

        .factory("FTCCommentsFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService,workspaceFactory, USER_SETTINGS,$rootScope) {
            
        	var FTCCommentsFactory = {};           
            FTCCommentsFactory.url ={};            
            FTCCommentsFactory.url.comment_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zyk0j5";
            
            // // Save the comments
            // FTCCommentsFactory.saveComments = function(objectId, screenKey, comments, operationType) {

            // }

            // Save the comments
            FTCCommentsFactory.saveComments = function(_data,Details,otherInfo){

                var filterParams = workspaceFactory.activeScreen.filters.getFilterParams();
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params =  _.extend({
                						sso_id:otherInfo.sso_id,
                						tax_year:otherInfo.tax_year,
                						scenario:otherInfo.scenario,
                						jcd_key:otherInfo.jcd_key,
                						screen_key:otherInfo.screen_key,
                						object_id:otherInfo.object_id,
                						comments:otherInfo.comments,
                						OPERATION_TYPE:otherInfo.OPERATION_TYPE
                				   }, params);
                
                console.log("Save params :",params);
                                
                var promise = $http({
                    method: "post",
                    url: FTCCommentsFactory.url.comment_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };
            
            return FTCCommentsFactory;
        }])


    return services;


});