define([
    'angular'
 
 ], function () {
    'use strict';
 
    var services =  angular.module('app.ghostOwnershipService',[])
        .factory("ghostownershipSaveFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG', 
            
            function($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG) {
                var factory = {};
                var returnObj = {}; 	    
                var ghostownershipSaveFactory ={};
                var service = $injector.get('JsonObjectFactory');
                var defer = $q.defer();
                ghostownershipSaveFactory.url ={};
                ghostownershipSaveFactory.url.saveGhostOwnership = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=pi3aff";
              
                ghostownershipSaveFactory.saveGhostOwnership = function(_callName , _data){
                    console.log("data", _data);
                    console.log("callname - ",_callName);
 
                       $rootScope.$broadcast('gridRefresh', false);
                       if(_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id])
                     {
                         return;
                     }
                                     
                       
                       var ssoId = USER_SETTINGS.user.sso_id;
                       var params = {
                         filing_key:_data.rowData.FILING_GROUP,
                         column_value : _data.rowData[_data.colData.map],
                         column_name :_data.colData.map,
                         ho_leid: _data.rowData.HO_LEID,
                         ho_cdr_no: _data.rowData.HO_CDR_NO,
                         parent_leid: _data.rowData.PARENT_LEID,
                         parent_cdr_no: _data.rowData.PARENT_CDR_NO,
                         ghost_scenario: _data.rowData.SCENARIO
                         /*tax_year: _data.rowData.TAX_YEAR,
                         jcd_key: _data.rowData.JCD_KEY*/
                       
                       };
                       params = _.merge({},  GlobalService.globalParams , params);
                       /*var promise = $http({
                           method: "post",
                           url: ghostownershipSaveFactory.url.saveGhostOwnership,
                           data: params
                       }).then(function (response) {
                           if(response.data.callSuccess === "1"){
                               AlertService.add("success", "Data saved Successfully.",3000);
                               var args = {tax_year: _data.rowData.TAX_YEAR, gridFilter: {TAX_YEAR: _data.rowData.TAX_YEAR}};
                             $rootScope.$emit('gridUpdate', args);
                           }else{
                               if(response.data.errorMessage){
                                   AlertService.add("error", response.data.errorMessage, 3000);
                                   return;
                               }else{
                                   AlertService.add("error", "Error!!! Ghost System Staus is not updated", 3000);
                                   return;
                               }
                           }	  					
                       });
             return promise;
 
                }*/
                        service.saveJSON(ghostownershipSaveFactory.url.saveGhostOwnership,params).then(function (data) {
                            if (data.callSuccess === "1") {						
                                AlertService.add("success", "Data saved Successfully.",3000);
                                   var args = {tax_year: _data.rowData.TAX_YEAR, gridFilter: {TAX_YEAR: _data.rowData.TAX_YEAR}};
                                 $rootScope.$emit('gridUpdate', args);
                                 defer.resolve(data);
                             }else {
                              AlertService.add("error", data.errorMessage, 4000);
                              defer.reject(data);
                          }},function(data){
                          defer.reject(data);
                      });
                      return defer.promise;
                    }
        return ghostownershipSaveFactory;
               }]);
    return services;
 });