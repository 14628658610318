// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor.js";
// import ExportPdf from "@ckeditor/ckeditor5-export-pdf/src/exportpdf.js";
define([
    "angular",
    //   "@ckeditor/ckeditor5-build-decoupled-document",
    //   "@ckeditor/ckeditor5-export-pdf/src/exportpdf",
  ], function () {
    "use strict";
    const DecoupledEditor = require("ckeditor");
    // const DecoupledEditor = require("@ckeditor/ckeditor5-build-decoupled-document");
    // const ExportPdf = require("@ckeditor/ckeditor5-export-pdf/src/exportpdf");
  
    var directives = angular
      .module("app.systemMessageDirective", [])
  
      .directive("messageCkeditor", ckeditor);
  
    ckeditor.$inject = [];
    function ckeditor() {
  
  
      return {
        scope:{
          events:'='
        },
        link: link,
        template: `
          <div class="sys-message-body-editor">
          <div class="sys-message-body-editor__toolbar"></div>
              <div class="sys-message-body-editor__editable-container">
                  <div class="sys-message-body-editor__editable">
                  </div>
              </div>
          </div>
          `,
      };
  
      function link(scope, element) {
        let toolbar = element.find(".sys-message-body-editor__toolbar");
        scope.events = scope.events || {};
  
        DecoupledEditor.create(element.find(".sys-message-body-editor__editable")[0], {
          // plugins: [ExportPdf],
          fontFamily: {
            options: [
              // 'default',
                "Arial, Helvetica, sans-serif",
                "Courier New, Courier, monospace",
                "Georgia, serif",
                "Lucida Sans Unicode, Lucida Grande, sans-serif",
                "Tahoma, Geneva, sans-serif",
                "Times New Roman, Times, serif",
                "Trebuchet MS, Helvetica, sans-serif",
                "Verdana, Geneva, sans-serif",
            ],
          },
          toolbar: {
            items: [
                          'heading',
                          '|',
                          'fontSize',
                          'fontFamily',
                          '|',
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                          // 'highlight',
                          '|',
                          'alignment',
                          '|',
                          'numberedList',
                          'bulletedList',
                          '|',
                          'indent',
                          'outdent',
                          '|',
                          // 'todoList',
                          // 'link',
                          // 'blockQuote',
                          // 'imageUpload',
                          'insertTable',
                          // 'mediaEmbed',
                          'pageBreak',
                          '|',
                          'undo',
                          'redo'
            ],
          },
        //   exportPdf: {
        //     stylesheets: ["./theme-black.css", "EDITOR_STYLES"],
        //     fileName: "foot-notes.pdf",
        //     converterOptions: {
        //       format: "A4",
        //       margin_top: "20mm",
        //       margin_bottom: "20mm",
        //       margin_right: "12mm",
        //       margin_left: "12mm",
        //       page_orientation: "portrait",
        //     },
        //   },
          table: {
            contentToolbar: [
                'tableColumn', 'tableRow', 'mergeTableCells'
            ],
        }
        })
          .then((systemMessageEditor) => {
            toolbar.append(systemMessageEditor.ui.view.toolbar.element);
  
            systemMessageEditor.execute("fontFamily", { value: "Verdana" });
  
            // window.systemMessageEditor = systemMessageEditor;
            scope.systemMessageEditor = systemMessageEditor;
  
            scope.events.rendered && scope.events.rendered(systemMessageEditor);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  });
  