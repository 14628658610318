define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.schedule-MService',[])
       .factory("scheduleMGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector ,$q, $http,
		           $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var scheduleMGroupObjFactory ={};
	           scheduleMGroupObjFactory.url ={};
	           scheduleMGroupObjFactory.url.save_schedule_M = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=p5vfbm";
	           
	           scheduleMGroupObjFactory.saveScheduleM = function(_data,Details){
	
	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "Schedule_M_Save";
	           var promise = $http({
	        	   method: "post",
	               url: scheduleMGroupObjFactory.url.save_schedule_M,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });
	
	           return promise;
           };
	           
           
	           return scheduleMGroupObjFactory;
       }]);

   return services;
});