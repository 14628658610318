define([
    'angular'

 ], function () {
    'use strict';
    var controllers = angular.module('app.signoffCommentsctrl', [])
        .controller('signoffCommentsctrl', ['$rootScope', '$injector','$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
			'$timeout', '$uibModal','$uibModalInstance','rowData',
			'gridData','GENERAL_CONFIG', 'workspaceFactory','AnalyzeTaxesServiceFactory','$http','USER_SETTINGS', signoffCommentsctrl ]);

    //function supplementCtrl($scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, $timeout, $uibModalInstance, SupplementFactory) {
    function signoffCommentsctrl($rootScope, $injector,$scope, $state, $log,
			JsonObjectFactory, AlertService, GlobalService,
			$timeout, $uibModal, $uibModalInstance, rowData,
			gridData, GENERAL_CONFIG, workspaceFactory, AnalyzeTaxesServiceFactory,$http,USER_SETTINGS) {
    	var vm = this;
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.rowData = rowData;
        vm.title = 'Comments';
        vm.COMMENTS ='';
        vm.OLD_COMMENTS=vm.COMMENTS;
        vm.allComments = []
        vm.GENERAL_CONFIG=GENERAL_CONFIG;
        

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }


        function getAllComments(){
			var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        	var params = {
        			"action_code": 'mg9894',
        			tax_year:	filterParams.tax_year,
        			scenario: 	filterParams.scenario,
        			jcd_key: 	GlobalService.globalParams.jcd_key,
        			combination_key:vm.rowData.COMBINATION_KEY,
        			sso_id:USER_SETTINGS.user.sso_id
			}
        	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
                console.log(data.jsonObject, "all comments");
                vm.allComments = data.jsonObject;
            });
        }

        if(gridData.colData.label === "Comments"){
        	getAllComments(vm.rowData);
        }

         vm.save = function() {
        	 if(vm.COMMENTS == undefined || vm.COMMENTS == null || vm.COMMENTS == ''){
					AlertService.add("error", "Please enter a valid comments",4000);
					return;
				}

	        	if(vm.COMMENTS.length > 2000){
					AlertService.add("error", "Please comments entered cannot be more than 2000.",4000);
					return;
				}

        	vm.loading = true;

        	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=babj7u";
          var affectedRow = vm.rowData;

          var jsonObj = [{
         	 combination_key:vm.rowData.COMBINATION_KEY,
         	 chart_key:vm.rowData.CHART_KEY,
             trans_header_key:vm.rowData.SIGNOFF_TRANS_HEADER_KEY === undefined ? 0 : vm.rowData.SIGNOFF_TRANS_HEADER_KEY,
             issue_key: vm.rowData.ISSUE_KEY,
        	 comments:  vm.COMMENTS.replace(/’/g, "'")//vm.rowData.DETAIL_COMMENTS === undefined ? null : vm.rowData.DETAIL_COMMENTS
			}];
        	console.log(jsonObj);

         var jsonSettings = [{
         	 combination_key:vm.rowData.COMBINATION_KEY,
             tax_year:vm.rowData.TAX_YEAR
			}];


         var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
         var params = _.merge({}, GlobalService.globalParams, filterParams);
         var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
         var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
          params.process_name =  "AnalyzeCommentsSave";

          var service = $injector.get('JsonObjectFactory');
          service.saveJSON(url , params).then(function(result){

              if(result.callSuccess === "1"){
                AlertService.add("success", "Data saved Successfully.", 4000);
               var args = {COMBINATION_KEYS:vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
                   $rootScope.$emit('gridUpdate', args);

              }else{
                if(result.errorMessage){
                    AlertService.add("error", result.errorMessage,4000);
                }else{
                    AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
                }


              }

              vm.loading = false;
              var args = {COMBINATION_KEYS: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEYS:vm.rowData.COMBINATION_KEY}};
              $rootScope.$emit('gridUpdate', args);

              $uibModalInstance.dismiss('cancel');


          });
      };

    }
    return controllers;
 });