define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.firTestedUnitsService',[])
        .factory("firTestedUnitsServiceFactory",['$q','$http','$rootScope','$log','JsonObjectFactory','AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams','GENERAL_CONFIG', function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
        	var firTestedUnitsServiceFactory = {};
            var returnObj = {};
            var logged_in_user = USER_SETTINGS.user.sso_id;
            firTestedUnitsServiceFactory.saveTestedUnits = function(_callName ,_data, columnName){
            	 console.log("data", _data);
            	 
            if(!angular.isDefined(_data.rowData[_data.col_id]))
            {
            	var intr=setInterval(function(){ 
                    AlertService.add("error", "You cannot enter null or empty values, Please enter valid values");
                   clearInterval(intr);
                   $rootScope.$apply();
                },1000);
            }
           
            else
            {
                $rootScope.$broadcast('gridRefresh', false); 
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=rbzvw7";
                //var jsonSettings = JSON.stringify(clob_settings);
       		 	//var jsonObj = JSON.stringify(_callName);
       		 	var jsonObj = [{
	            	"combination_key"    : _data.rowData.COMBINATION_KEY,
	            	"ho_combination_key"  : _data.rowData.HO_COMBINATION_KEY,
	                "col_type"           : _data.col_id,
	                "col_value"          : _data.rowData[_data.col_id]
	                
	            }];
	            var jsonSettings = [{tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,sso_id: logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
                var saveData = true;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params =  _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
       		 	params =  _.extend({jsonSettings:JSON.stringify(jsonSettings)}, params);
                params.object_id = _data.rowData.object_id;
                params.sso_id = logged_in_user;
                var defer = $q.defer();
                //params.tax_year = rowData.TAX_YEAR;
                params.process_name =  "Save Tested Units";
              
                
                service.saveJSON(url, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        AlertService.add("success", "Data saved Successfully.", 4000);
                       var args = {
                                combination_key: _data.rowData.COMBINATION_KEY,
                                gridFilter: {
                                    combination_key: _data.rowData.COMBINATION_KEY
                                }
                            };
                            $rootScope.$emit('gridUpdate', args);
                        defer.resolve(data);
                    } else {
                        AlertService.add("error", data.errorMessage);
                        defer.reject(data);
                    }
                },function(data){
                    defer.reject(data);
                });
                return defer.promise;
            }
            }
//*************for gilti hte analysis grid******************
//**********************************************************
            firTestedUnitsServiceFactory.saveGILTIHTEAnalysisOverride = function(_callName ,_data, columnName){
           	 console.log("data", _data);
           	 
	           	if(!angular.isDefined(_data.rowData[_data.col_id]))
	            {
	            	var intr=setInterval(function(){ 
	                    AlertService.add("error", "You cannot enter null or empty values, Please enter valid values");
	                   clearInterval(intr);
	                   $rootScope.$apply();
	                },1000);
	            }
	           	else
	            {
	                $rootScope.$broadcast('gridRefresh', false); 
	                var service = $injector.get('JsonObjectFactory');
	                var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=fo97y6";
	                //var jsonSettings = JSON.stringify(clob_settings);
	       		 	//var jsonObj = JSON.stringify(_callName);
	       		 	var jsonObj = [{
		            	"ho_combination_key"  : _data.rowData.HO_COMBINATION_KEY,
		                "tested_unit"           : _data.rowData.TESTED_UNIT_NAME,
		                "group_type"          : _data.rowData.GROUP_TYPE,
		                "gilti_hte_map"	      :_data.col_id,
		                "basket_id"			  : _data.rowData.BASKET_ID,
		                "value"				  :_data.rowData[_data.col_id]
		                
		            }];
		            var jsonSettings = [{tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,sso_id: logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
	                var saveData = true;
	                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	                var params = _.merge({}, GlobalService.globalParams, filterParams);
	                params =  _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
	       		 	params =  _.extend({jsonSettings:JSON.stringify(jsonSettings)}, params);
	                params.object_id = _data.rowData.object_id;
	                params.sso_id = logged_in_user;
	                var defer = $q.defer();
	                //params.tax_year = rowData.TAX_YEAR;
	                params.process_name =  "Save GILTI HTE analysis override";
	              
	                
	                service.saveJSON(url, params).then(function (data) {
	                    if (data.callSuccess === "1") {
	                        AlertService.add("success", "Data saved Successfully.", 4000);
	                      
	                        defer.resolve(data);
	                    } else {
	                        AlertService.add("error", data.errorMessage);
	                        defer.reject(data);
	                    }
	                    var args = {
                                combination_key: _data.rowData.HO_COMBINATION_KEY,
                                gridFilter: {
                                    combination_key: _data.rowData.HO_COMBINATION_KEY
                                }
                            };
                            $rootScope.$emit('gridUpdate', args);
	                },function(data){
	                    defer.reject(data);
	                });
	                return defer.promise;
	            }
           	 
           }
            return firTestedUnitsServiceFactory;
        }]);

    return services;
});










