define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.qbuSourcingDeleteCtrl',  [])
		.controller('qbuSourcingDeleteCtrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',qbuSourcingDeleteCtrl]);


	function qbuSourcingDeleteCtrl($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {
		
		let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.userSettings = USER_SETTINGS;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;   
			
            vm.ho_leid = gridData.rowData.HO_LEID;
			vm.ho_cdr_no = gridData.rowData.HO_CDR_NO;
			vm.leid = gridData.rowData.LEID;
			vm.cdr_no = gridData.rowData.CDR_NO;
            vm.me_code = gridData.rowData.ME_CODE;
            vm.acct = gridData.rowData.ACCT;			
			vm.source_type = gridData.rowData.SOURCE_TYPE;
            vm.source_id = gridData.rowData.SOURCE_ID;
            vm.qbu_amt_ind = gridData.rowData.QBU_AMT_IND;			
			vm.rec_type = gridData.rowData.REC_TYPE;
            vm.tp_key = gridData.rowData.TP_KEY;
            vm.reporting_period = gridData.rowData.REPORTING_PERIOD;	
			vm.acct_key = gridData.rowData.ACCT_KEY;
            vm.chart_key = gridData.rowData.CHART_KEY;
            vm.issue_key = gridData.rowData.ISSUE_KEY;
            vm.ho_combination_key = gridData.rowData.HO_COMBINATION_KEY;
            vm.combination_key = gridData.rowData.COMBINATION_KEY;
			
            var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
            vm.globalParams = JSON.stringify(tempObj);
            vm.checkLockeOdbj =[];
            vm.deleteParams =[];
            vm.dataLoading = true;



            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = filterParams.scenario;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.filing_key = filterParams.filing_key;
                    vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.checkLockeOdbj.push({"me_code":gridData.rowData.ME_CODE,"consol_group_key":vm.filing_key, "scenario_key":vm.scenario,
                    						"combination_key":vm.combination_key, "tax_year":vm.tax_year, "scenarioCode": vm.scenarioCode,
                    						"is_issue_key_enabled":'N'});

                 
                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);
                    
                    
                    vm.deleteParams.push({"ho_leid":vm.ho_leid,
										  "ho_cdr_no":vm.ho_cdr_no, 
										  "leid":vm.leid, 
										  "cdr_no":vm.cdr_no, 
										  "me_code": vm.me_code,
                    					  "acct":vm.acct,
										  "source_type":vm.source_type,
										  "source_id":vm.source_id, 
										  "qbu_amt_ind":vm.qbu_amt_ind, 
										  "rec_type":vm.rec_type, 
										  "tp_key": vm.tp_key,
                    					  "reporting_period":vm.reporting_period,
										  "acct_key": vm.acct_key,
                    					  "chart_key":vm.chart_key,
                    					  "issue_key":vm.issue_key,
                    					  "ho_combination_key":vm.ho_combination_key
										  });
											
					vm.deleteParamsStr = JSON.stringify(vm.deleteParams);			
					
					

                    const html = `<gtw-sourcing-delete-records-ce id="open-sourcing-info" base-urls="{{ctrl.baseURLs}}" global-params="{{ctrl.globalParams}}" 
                    				tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" screen-key="{{ctrl.screen_key}}" sso-id="{{ctrl.logged_in_user}}"                     				
                    				check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" delete-param-obj ="{{ctrl.deleteParamsStr}}"
                    				class="gtw-web-components">
                    		</gtw-sourcing-delete-records-ce>`;
                    vm.webComponentHtml = html;
					
					
								

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {
                	$window.document.querySelector('gtw-sourcing-delete-records-ce')
                           .addEventListener('open-sourcing-delete-info', (event)=>{
                               console.log('event',event);
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {

                        $window.document.querySelector('gtw-sourcing-delete-records-ce')
                            .addEventListener('save-sourcing-delete-info', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-sourcing-delete-info'){
									var args = {};
                        			$rootScope.$emit('gridUpdate', args);
                                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added ...');
                        vm.dataLoading = false;
                    },3000
                );

                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
    ); 