/**
 * @author mnagothi001 [503037154 - Mallesh Nagothi] on 07/07/2018.
 * This is to sync GOLD Data to GTW
 * 
 * 
 */
define([
    'angular'

], function () {
    'use strict';




    var controllers =  angular.module('app.goldSyncOldCtrl',[])
        .controller('goldSyncOldCtrl', ['$rootScope','$scope','$state','$log','JsonObjectFactory','AlertService','$timeout','$uibModal','USER_SETTINGS','TAX_YEARS','GoldSyncOldFactory','GENERAL_CONFIG','GlobalService', goldSyncOldCtrl])
        
  
     /**
     * @Author: Mallesh Nagothi
     */    
    function goldSyncOldCtrl($rootScope,$scope,$state,$log,JsonObjectFactory,AlertService,$timeout,$uibModal,USER_SETTINGS,TAX_YEARS,GoldSyncOldFactory,GENERAL_CONFIG, GlobalService) {
    	
    	var vm = this;
    	vm.userSettings = USER_SETTINGS;
        vm.loading = false;
        vm.client_key = 0;
        vm.clients = [];
        vm.taxYear = 0;
        vm.reportingMonth = 0;
        vm.grpObjKeys = [];
        vm.taxYears = [];
        vm.reportingMonths = [];
        vm.txYearScenarioJcdOptions = [];
        vm.currentTaxYearGold = 0;
        vm.currentReportingMonthGold = "";
        
        vm.businessAssignNavPreview = [];
        vm.isPreviewAvailable = false;
        vm.previewMode = 2; // true; //1-Preview Mode, 2-Already existing Biz Heirarchy for selected Grp Obj Key
        
        vm.businessAssignNavExisting = [];
        vm.isExistingBizHierAvailable = false;
        vm.existingBizHierPreviewMode = 2; // true; //1-Preview Mode, 2-Already existing Biz Heirarchy for selected Grp Obj Key
        
        vm.businessAssignNavStaged = [];
        vm.isStagedBizHierAvailable = false;
        vm.stagedBizHierPreviewMode = 1; // true; //1-Preview Mode, 2-Already existing Biz Heirarchy for selected Grp Obj Key
        
        vm.showPreviews = false;
        vm.readyToGoldSync = false;
        vm.readyToPreviewStage = false;
        
        
        
        if(vm.userSettings){
    		var user = vm.userSettings.user;
    		vm.clients = user.clients;
    		vm.client_key = vm.clients[0].client_key;
    		console.log("user ::",user);
    		vm.logged_in_user = user.sso_id;
        }
        
        /** TESTING TESTING **/
        vm.getGoldBizzHierarchyData = function(){
        	vm.loading = true;
        	var data = {};
    		console.log("getGoldBizzHierarchyData Data ::",data);
            GoldSyncOldFactory.getGoldBizzHierarchyData(data).then(function (data) {
        		vm.loading = false;
        		console.log("getGoldBizzHierarchyData returned data.jsonObject",data);
        	});
        }
        
        vm.getTaxYearsByClient = function(clientKey){
        	console.log("Inside getTaxYearsByClient()",clientKey);
        	/** Resetting Staged Bizz Hierarchy Data**/
        	vm.businessAssignNavStaged = [];
            vm.isStagedBizHierAvailable = false;
            vm.stagedBizHierPreviewMode = 1;
            if(clientKey>0){
        		vm.loading = true;
                var  params = {action_code: 'etxzx9', sso_id: vm.logged_in_user, client_key: clientKey};
                
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    vm.loading = false;
                    console.log("getTaxYearsByClient() returned Data ::",data);
                    if(data.callSuccess === "1" ){
                    	if(data.jsonObject){
                    		vm.taxYears = _.map(data.jsonObject,'TAX_YEAR');
                    		if(vm.taxYears && vm.taxYears.length > 0){
                    			if(_.map(vm.taxYears,vm.currentTaxYearGold).length > 0 ){
                    		    	vm.taxYear = vm.currentTaxYearGold; 
                    		    }else{
                    		    	vm.taxYear = vm.taxYears[0];
                    		    	console.log(" :: vm.taxYears[0] ::",vm.taxYears[0]);
                    		    }
                    		}
                    	}
                    	console.log("vm.taxYears ::",vm.taxYears);
                    	
                    }else{
                    	if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to retrive Tax Years.", 4000);
                        }else{
                            AlertService.add("error", "Unalbe to retrieve Tax Years. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                    
                });
        		
        	}
        }
        
        /** Displays GOLD Current Year and Month**/
        function getGoldCurrentReportingYearMonth(){
        	vm.loading = true;
            var  params = {action_code: 'etjjj9', sso_id: vm.logged_in_user};
            
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                vm.loading = false;
                console.log("vm.getGoldCurrentReportingYearMonth DATA::",data);
                if(data.callSuccess === "1" ){
                	if(data.jsonObject && data.jsonObject.length>0){
                		vm.currentTaxYearGold = Number(data.jsonObject[0].CURRENT_TAX_YEAR);
                   	 	vm.currentReportingMonthGold = data.jsonObject[0].CURRENT_REPORT_MONTH;
	                   	if(_.map(vm.taxYears,vm.currentTaxYearGold).length > 0 ){
	         		    	vm.taxYear = vm.currentTaxYearGold; 
	         		    }else{
	         		    	vm.taxYear = vm.taxYears[0];
	         		    }
	                   	//commented bcoz no automoatic submission
	                   	//vm.getObjectKeyByClientAndTaxYear();
                	}
                	
                }else{
                	if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to Gold Current TaxYear  the requested action.", 4000);
                    }else{
                        AlertService.add("error", "Unalbe to fetch Gold Current TaxYear, An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        	
        }
        
        vm.previewBizzHierarchy = function(previewMode){
        	vm.loading = true;
        	var data = {};
        	data.taxyear     = vm.taxYear;
    		data.sso_id      = vm.logged_in_user;
    		data.grp_obj_key = vm.selectedGrpObj.groupObjKey;
    		console.log("Data ::",data);
    		vm.previewMode = previewMode;
    		if(vm.currentTaxYearGold === vm.taxYear){
        		vm.reportingMonth = vm.currentReportingMonthGold;
        	}else{
        		vm.reportingMonth = "12";
        	}
        	
        	if(vm.taxYear && vm.reportingMonth && vm.client_key && vm.selectedGrpObj.groupObjKey){
                GoldSyncOldFactory.previewExistingBizzHierarchy(data).then(function (data) {
	        		  vm.loading = false;
	                  if(data.callSuccess === "1" ){
	                      vm.businessAssignNavPreview = data.jsonObject;
	                      console.log("data.jsonObject ", data.jsonObject);
	                      if(data.jsonObject.length<=0){
	                      	AlertService.add("warning", "Business Hierarchy Data is not available for Tax Year:"+vm.taxYear+" Group Obj Key: "+vm.selectedGrpObj.groupObjKey, 4000);
	                      }else{
	                    	  vm.isPreviewAvailable = true;
	                      }
	                      
	                  }else {
	                      if(data.errorMessage === "no access"){
	                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
	                      }else{
	                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
	                      }
	                  }
	              });
        	}else{
        		 AlertService.add("warning", "Please make sure Tax Year, Client and Group Object Key are selected", 4000);
        		 vm.loading = false;
        	}
        }
        
        /** Kick Of Gold Sync To GTW STAGING AREA **/
        vm.kickOfGoldSyncToGtwStage = function(){
        	console.log("taxYear....",vm.taxYear);
        	console.log("reportingMonth....",vm.reportingMonth);
        	console.log("client_key....",vm.client_key);
        	var selctdGrpObjKeys = "";
        	var uniqGrpObjKeys = [];
        	var selectedGrpObjArray =  _.filter(vm.txYearScenarioJcdOptions, function(o) { return o.selected; });
        	angular.forEach(selectedGrpObjArray, function(item, index) {
        		if(uniqGrpObjKeys.indexOf(item) < 0 ) {
        			uniqGrpObjKeys.push(item);
        		}
    		});
        	angular.forEach(uniqGrpObjKeys, function(item, index) {
        		selctdGrpObjKeys =  selctdGrpObjKeys+"-" + item.GROUP_OBJ_KEY;
    		});
        	console.log("selctdGrpObjKeys ::",selctdGrpObjKeys);
        	
            if(vm.currentTaxYearGold === vm.taxYear){
        		vm.reportingMonth = vm.currentReportingMonthGold;
        	}else{
        		vm.reportingMonth = "12";
        	}
            console.log("grp_obj_key....",selctdGrpObjKeys);
        	
        	if(vm.taxYear && vm.reportingMonth && vm.client_key && selctdGrpObjKeys.length>0){
        		vm.loading = true;
                var  data = { };
                data.sso_id  = vm.logged_in_user;
                data.taxyear = vm.taxYear;
                data.rpt_mth = vm.reportingMonth;
                data.client_key  = vm.client_key;
                data.grp_obj_key = selctdGrpObjKeys;  
                console.log("kickOfGoldSyncToGtwStage(Stage Sync) params::",data);
                GoldSyncOldFactory.syncGoldDataForBizzHierarchy(data).then(function(data){
                	console.log("kickOfGoldSyncToGtwStage Gold Sync Status(STG) ::",data);
                	if(data.callSuccess === "1"){
                		
                		 var  data = { };
                         data.sso_id  = vm.logged_in_user;
                         data.taxyear = vm.taxYear;
                         data.client_key  = vm.client_key;
                         data.grp_obj_key = selectedGrpObjArray[0].GROUP_OBJ_KEY; //auto preview only first grp obj key for now. change this logic once it is agreed to have multiple grp keys //vm.selectedGrpObj.groupObjKey;
                        GoldSyncOldFactory.previewStagedBizzHierarchy(data).then(function (data) {
                    		vm.loading = false;
                    		if(data.callSuccess === "1"){
                    			console.log("previewStagedBizzHierarchy returned data.jsonObject",data.jsonObject);
                    			vm.businessAssignNavStaged = data.jsonObject; //this will ensure Bizz Hier UI tree gets staged data
                                vm.isStagedBizHierAvailable = true; //this will enable Bizz Hier component on UI
                                vm.showPreviews = true;
                                vm.stagedBizHierPreviewMode = 2; //this will ensure Finalize, Override Buttons enabled on Bizz Hierarchy Tree
                                vm.selectedGrpObj.groupObjKey = selectedGrpObjArray[0].GROUP_OBJ_KEY; //passing first grp obj key for preview. Need to write ng-repeat to handle multiple group ids
                    			if(data.jsonObject.length<=0){
                                  	AlertService.add("warning", "Business Hierarchy (Stage)Data is not available for Tax Year:"+vm.taxYear+" Group Obj Key: "+vm.selectedGrpObj.groupObjKey, 4000);
                                }
                    		}
                    	});
                		
                	}else{
                   	 if(data.errorMessage === "no access"){
                         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                     }else{
                         AlertService.add("error", "Unable to Sync Business Hierarchy Data from GOLD to GTW: Error No:"+data.callSuccess, 4000);
                     }
                }
                });
                
                /*//var  params = {action_code: 'eoooo9', sso_id: vm.logged_in_user,taxyear: vm.taxYear,rpt_mth: vm.reportingMonth,client_key: vm.client_key,grp_obj_key: vm.selectedGrpObj.groupObjKey};
                JsonObjectFactory.getJSONObj('loadJsonObject.ge', params).then(function (data) {
                    vm.loading = false;
                    console.log("kickOfGoldSyncToGtwStage()-Step#1 returned Data ::",data);
                    //improve following logic to handle all error conditions (0 to -8 from stored proc)
                    if(data.callSuccess === "1"){
                    	*//** Gold to GTW Staging Area Sync success. Proceed to Preview Staging Bizz Hier Tree **//*
                    	vm.loading = true;
                    	var data = {};
                    	data.taxyear     = vm.taxYear;
                		data.sso_id      = vm.logged_in_user;
                		data.grp_obj_key = vm.selectedGrpObj.groupObjKey;
                		console.log("previewStagedBizzHierarchy Data ::",data);
                    	GoldSyncFactory.previewStagedBizzHierarchy(data).then(function (data) {
                    		vm.loading = false;
                    		if(data.callSuccess === "1"){
                    			console.log("previewStagedBizzHierarchy returned data.jsonObject",data.jsonObject);
                    			vm.businessAssignNavStaged = data.jsonObject; //this will ensure Bizz Hier UI tree gets staged data
                                vm.isStagedBizHierAvailable = true; //this will enable Bizz Hier component on UI
                                vm.stagedBizHierPreviewMode = 2; //this will ensure Finalize, Override Buttons enabled on Bizz Hierarchy Tree
                    			if(data.jsonObject.length<=0){
                                  	AlertService.add("warning", "Business Hierarchy (Stage)Data is not available for Tax Year:"+vm.taxYear+" Group Obj Key: "+vm.selectedGrpObj.groupObjKey, 4000);
                                }
                    		}
                    	});
                    }else{
                    	 if(data.errorMessage === "no access"){
                             AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                         }else{
                             AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                         }
                    }
                });
        	}else{
        		AlertService.add("warning","Client, Tax Year, and Group Object Key are mandatory  ", 4000);
        	}*/
        }
        }
        
        /** Calling Wrapper i.e., New Entry Point**/
        vm.kickOffGoldSync = function(){
        	console.log("taxYear....",vm.taxYear);
        	console.log("reportingMonth....",vm.reportingMonth);
        	console.log("client_key....",vm.client_key);
        	console.log("grpObjKey.....",vm.selectedGrpObj.groupObjKey);
        	if(vm.currentTaxYearGold === vm.taxYear){
        		vm.reportingMonth = vm.currentReportingMonthGold;
        	}else{
        		vm.reportingMonth = "12";
        	}
        	
        	if(vm.taxYear && vm.reportingMonth && vm.client_key && vm.selectedGrpObj.groupObjKey){
        		vm.loading = true;
                var  params = {action_code: 'ewwww9', sso_id: vm.logged_in_user,taxyear: vm.taxYear,rpt_mth: vm.reportingMonth,client_key: vm.client_key,grp_obj_key: vm.selectedGrpObj.groupObjKey};
                console.log("params::",params);
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    vm.loading = false;
                    console.log("startGoldSync()-Step#1 returned Data ::",data);
                    if(data.callSuccess === "1"){
                    	/** Bizz hierarchy download and prep is successful. Proceed to Preview **/
                    	var data = {};
                    	data.taxyear     = vm.taxYear;
                		data.sso_id      = vm.logged_in_user;
                		data.grp_obj_key = vm.selectedGrpObj.groupObjKey;
                		console.log("Data ::",data);
                        GoldSyncOldFactory.previewExistingBizzHierarchy(data).then(function (data) {
                    		  vm.loading = false;
                              if(data.callSuccess === "1" ){
                                  vm.businessAssignNavPreview = data.jsonObject;
                                  console.log("data.jsonObject ", data.jsonObject);
                                  if(data.jsonObject.length<=0){
                                  	AlertService.add("warning", "Business Hierarchy Data is not available for Tax Year:"+vm.taxYear+" Group Obj Key: "+vm.selectedGrpObj.groupObjKey, 4000);
                                  }
                                  
                              }else {
                                  if(data.errorMessage === "no access"){
                                      AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                  }else{
                                      AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                  }
                              }
                          });
                    	
                    }else{
                    	AlertService.add("error", "Unable to download Business Hierarchy for Tax Year:"+taxYear+" Group Obj Key: "+vm.selectedGrpObj.groupObjKey, 4000);
                    }
                    
                });
        	}
        }
        
        /** Individually calling Bizz Hierarchy STEPS (Step By Step) **/
        vm.startGoldSync = function(){
        	console.log("taxYear....",vm.taxYear);
        	console.log("reportingMonth....",vm.reportingMonth);
        	console.log("client_key....",vm.client_key);
        	console.log("grpObjKey.....",vm.selectedGrpObj.groupObjKey);
        	
        	if(vm.currentTaxYearGold === vm.taxYear){
        		vm.reportingMonth = vm.currentReportingMonthGold;
        	}else{
        		vm.reportingMonth = "12";
        	}
        	
        	if(vm.taxYear && vm.reportingMonth && vm.client_key && vm.selectedGrpObj.groupObjKey){
        		vm.loading = true;
                var  params = {action_code: 'etbsx9', sso_id: vm.logged_in_user, tax_year: vm.taxYear,report_mth: vm.reportingMonth,client_key: vm.client_key };
                console.log("params::",params);
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    vm.loading = false;
                    console.log("startGoldSync()-Step#1 returned Data ::",data);
                    var retVal = parseInt(data.callSuccess);
                    if(retVal >0){
                    	
                    	AlertService.add("success", "STEP#1. GOLD Data successfully imported to GTW...", 4000);
                    	vm.loading = true;
                    	var  params = {action_code: 'etbsw9', sso_id: vm.logged_in_user, tax_year: vm.taxYear};
                    	console.log("params::",params);
                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                            vm.loading = false;
                            console.log("startGoldSync()-Step#2 returned Data ::",data);
                            if(data.callSuccess === "1"){
                            	AlertService.add("success", "GOLD Data[Business Hierarchy-GOLD] successfully imported to GTW...", 4000);
                            	vm.loading = true;
                            	var  params = {action_code: 'etbso9', sso_id: vm.logged_in_user, tax_year: vm.taxYear};
                            	console.log("params::",params);
                                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                                    vm.loading = false;
                                    console.log("startGoldSync()-Step#3 returned Data ::",data);
                                    if(data.callSuccess === "1"){
                                    	AlertService.add("success", "GOLD Data[Business Hierarchy-TAX] successfully imported to GTW...", 4000);
                                    	vm.loading = true;
                                    	var  params = {action_code: 'etbxx9', sso_id: vm.logged_in_user, tax_year: vm.taxYear, grp_obj_key: vm.selectedGrpObj.groupObjKey, client_key: vm.client_key};
                                    	console.log("params::",params);
                                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                                            vm.loading = false;
                                            console.log("startGoldSync()-Step#4 returned Data ::",data);
                                            if(data.callSuccess === "1"){
                                            	AlertService.add("success", "Business Mast successfully built...", 4000);
                                            	//Building Business Hierarchy Gold
                                            	vm.loading = true;
                                            	var  params = {action_code: 'etbss9', sso_id: vm.logged_in_user, tax_year: vm.taxYear, grp_obj_key: vm.selectedGrpObj.groupObjKey, client_key: vm.client_key};
                                            	console.log("params::",params);
                                                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                                                    vm.loading = false;
                                                    console.log("startGoldSync()-Step#5 returned Data ::",data);
                                                    if(data.callSuccess === "1"){
                                                    	AlertService.add("success", "Business Hierarchy(GOLD) successfully built...", 4000);
                                                    }else{
                                                    	if(data.errorMessage === "no access"){
                                                    		vm.loading = false;
                                                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                                        }else{
                                                        	vm.loading = false;
                                                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                                        }
                                                    }
                                                });
                                                
                                               //Building Business Hierarchy TAX
                                            	vm.loading = true;
                                            	var  params = {action_code: 'etbqq9', sso_id: vm.logged_in_user, tax_year: vm.taxYear, grp_obj_key: vm.selectedGrpObj.groupObjKey, client_key: vm.client_key};
                                            	console.log("params::",params);
                                                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                                                    vm.loading = false;
                                                    console.log("startGoldSync()-Step#6 returned Data ::",data);
                                                    if(data.callSuccess === "1"){
                                                    	AlertService.add("success", "Business Hierarchy(TAX) successfully built...", 4000);
                                                    	 //etbaz9 refresh mvgt biz det
                                                        vm.loading = true;
                                                    	var  params = {action_code: 'etbaz9', sso_id: vm.logged_in_user};
                                                    	console.log("params::",params);
                                                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                                                            vm.loading = false;
                                                            console.log("startGoldSync()-Step#7 returned Data ::",data);
                                                            if(data.callSuccess === "1"){
                                                            	AlertService.add("success", "Successfully Refreshed BUSINESS DETAILS MVGT...", 4000);
                                                            }else{
                                                            	if(data.errorMessage === "no access"){
                                                            		vm.loading = false;
                                                                    AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                                                }else{
                                                                	vm.loading = false;
                                                                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                                                }
                                                            }
                                                           
                                                        });
                                                        //etzzz9 refresh mvgt biz hier dpc
                                                        vm.loading = true;
                                                    	var  params = {action_code: 'etzzz9', sso_id: vm.logged_in_user};
                                                    	console.log("params::",params);
                                                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
                                                            vm.loading = false;
                                                            console.log("startGoldSync()-Step#8 returned Data ::",data);
                                                            if(data.callSuccess === "1"){
                                                            	AlertService.add("success", "Successfully Refreshed BUSINESS HIERARCHY DPC MVGT...", 4000);
                                                            }else{
                                                            	if(data.errorMessage === "no access"){
                                                            		vm.loading = false;
                                                                    AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                                                }else{
                                                                	vm.loading = false;
                                                                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                                                }
                                                            }
                                                           
                                                        });
                                                    }else{
                                                    	if(data.errorMessage === "no access"){
                                                    		vm.loading = false;
                                                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                                        }else{
                                                        	vm.loading = false;
                                                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                                        }
                                                    }
                                                   
                                                });
                                               
                                                
                                                
                                                
                                            }else{
                                            	if(data.errorMessage === "no access"){
                                            		vm.loading = false;
                                                    AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                                }else{
                                                	vm.loading = false;
                                                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                                }
                                            }
                                           
                                        });
                                    }else{
                                    	if(data.errorMessage === "no access"){
                                    		vm.loading = false;
                                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                        }else{
                                        	vm.loading = false;
                                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                        }
                                    }
                                   
                                });
                            }else{
                            	if(data.errorMessage === "no access"){
                            		vm.loading = false;
                                    AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                }else{
                                	vm.loading = false;
                                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                }
                            }
                           
                        });
                    	
                    	
                    	
                    }else{
                    	if(data.errorMessage === "no access"){
                    		vm.loading = false;
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                        	vm.loading = false;
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                   
                });
        	}
        	
        }//start gold sync function ends here
        
        /**Retrieving JCD/Scenario/Group Object Keys Method **/
        vm.getObjectKeyByClientAndTaxYear = function(){
        	
        	/** Resetting Existing Bizz Hierarchy Data**/
        	vm.businessAssignNavPreview = [];
        	vm.isPreviewAvailable = false;
        	
        	/** Resetting Staged Bizz Hierarchy Data**/
        	vm.businessAssignNavStaged = [];
            vm.isStagedBizHierAvailable = false;
            vm.stagedBizHierPreviewMode = 1;
            
        	if(vm.taxYear && vm.client_key){
        		vm.loading = true;
                var  params = {action_code: 'etbll9', sso_id: vm.logged_in_user, client_key: vm.client_key, taxyear: vm.taxYear};
                
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
                    vm.loading = false;
                    if(data.callSuccess === "1" ){
                    	vm.readyToGoldSync = true;
                    	vm.grpObjKeys = data.jsonObject;
                    	vm.txYearScenarioJcdOptions = data.jsonObject;
                    	console.log("vm.txYearScenarioJcdOptions ::",vm.txYearScenarioJcdOptions);
                    	if(vm.txYearScenarioJcdOptions && vm.txYearScenarioJcdOptions.length > 0 ){
                    		angular.forEach(vm.txYearScenarioJcdOptions, function(item, index) {
                    			item.selected = true;
                    		});
                    		
                    		vm.selectedGrpObj = vm.txYearScenarioJcdOptions[0];
                    		vm.selectedGrpObj.groupObjKey = vm.txYearScenarioJcdOptions[0].GROUP_OBJ_KEY;
                    		//commented bcoz auto preview is turned off
                    		//vm.previewBizzHierarchy(1);
                    		//vm.showPreviews = true;
                    		console.log("vm.txYearScenarioJcdOptions SELECT=true for all ::",vm.txYearScenarioJcdOptions);
                    	}else{
                    		vm.showPreviews = false;
                    		vm.readyToGoldSync = false;
                    	}
                    }else{
                    	vm.readyToGoldSync = false;
                    	if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                    console.log("getObjectKeyByClientAndTaxYear() returned Data ::",data);
                });
                
        	}
        }
        
        // Getting Obj Key By Client and Tax Year
        
        function getTxYearScenarioOptions(){
        	vm.loading = true;
            var  params = {action_code: 'dkm185', sso_id: vm.logged_in_user};
            
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'loadJsonObject', params).then(function (data) {
                vm.loading = false;
                if(data.callSuccess === "1" ){
                	vm.showPreviews = true;
                	vm.txYearScenarioJcdData = data.jsonObject;
                	angular.forEach(data.jsonObject, function(item){
                		var keyItem = item.TAX_YEAR +'-'+item.SCENARIO+'-'+item.JCD_KEY;
                		var val = 'TaxYear/Scenario/JCDKey:'+item.TAX_YEAR +'/'+item.SCENARIO+'/'+item.JCD_KEY+'-'+item.SCENARIO_DESC+'/'+item.JURISDICTION_CODE+'/'+item.DOMAIN_NAME+'/'+item.CHANNEL_NAME+']'; 
                		var record = { keyItem: val };
                        console.log("record ::",record);  
                        vm.txYearScenarioJcdOptions.push(record);
                        console.log("vm.txYearScenarioJcdOptions ::",vm.txYearScenarioJcdOptions);
                    });
                }else{
                	vm.showPreviews = false;
                	if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
                console.log("getTaxYearScenarioJCDKey() returned Data ::",data);
            });
            
        }
        
        getGoldCurrentReportingYearMonth();
        vm.getTaxYearsByClient(vm.client_key);
    	
        
        vm.kickOfECSyncToGtw =  function(){
	            var params = { "action_code": 'hh1pt8', "sso_id": vm.logged_in_user, "tax_year": null, "scenario": null, "inscenario": null,
	            		"jcd_key": GlobalService.globalParams.jcd_key, "save_type": null, "clob_settings": null, "clob_data": null, "custom_json": null};
	            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
//	            .then(function (data) {
//	            	vemodal.taxAttribEntityData = data.jsonObject;
//	            	vemodal.loadingEntities = false;
//	            });
        }
        
        vm.kickOfECSyncToGtw();
        
    }
       return controllers;

});
