define([
    'angular'
], function () {
    'use strict';

    var services = angular.module('app.adminResetDsConnectionService',[])
        .factory("adminResetDsConnectionService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG', 'JsonObjectFactory', 'AlertService',
            function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG, JsonObjectFactory, AlertService) {

                var adminResetDsConnectionService = {};

                adminResetDsConnectionService.resetDsConnection = function (params) {
                    // var defer = $q.defer();
                    // console.log("resetDatasourceConnection:"+_callName+"_"+_data);
                    var url = '';
                    var apiCall = params.api;
                    if(apiCall === 'gtw') {
                        url = GENERAL_CONFIG.base_url;
                    } else if(apiCall === 'admin') {
                        url = GENERAL_CONFIG.admin_api_url;
                    } else if(apiCall === 'common') {
                        url = GENERAL_CONFIG.common_api_url;
                    } else if(apiCall === 'custom') {
                        url = GENERAL_CONFIG.custom_api_url;
                    } else if(apiCall === 'scheduler') {
                        url = GENERAL_CONFIG.gold_sync_api_url;
                    } else if(apiCall === 'efile') {
                        url = GENERAL_CONFIG.gtw_efile_api_url;
                    } else if(apiCall === 'bulkpdf') {
                        url = GENERAL_CONFIG.bulk_pdf_engine_api_url;
                    } else if(apiCall === 'pdf') {
                        url = GENERAL_CONFIG.pdf_engine_api_url;
                    }
                    url += '/resetDataSourceConnection?datasource=' + params.datasource + '&refreshFromDB=' + params.refreshFromDB;
                    var promise = $http({
                        method: "GET",
                        url: url,
                        //data: params
                    }).then(function (response) {
                        return response;
                    });
                    return promise;
                }

                return adminResetDsConnectionService;

        }]);

    return services;

});