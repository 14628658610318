define(
    ['angular'],
    function() {
        'use strict';

        var controllers = angular.module('app.est-formCtrl',[])
            .controller('estFormCtrlModal',['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','$filter','$timeout','estFormServiceFactory','USER_SETTINGS','GENERAL_CONFIG', 'workspaceFactory', '$uibModalInstance', 'rowData', 'colData', 'gridData', estFormCtrlModal]);

        function estFormCtrlModal(GlobalService, AlertService, JsonObjectFactory, ModalFactory, $parse, $rootScope,$scope, $http, $filter, $timeout,estFormServiceFactory,USER_SETTINGS,GENERAL_CONFIG, workspaceFactory,$uibModalInstance, rowData, colData, gridData) {

            var vm = this;
            vm.fm = null;
            vm.sn = null;
            vm.pn = null;
            vm.sm = null;


            vm.workflow = null;
            vm.editFlag = false;

            vm.originalOutterData = null;
            vm.isSaveClicked = false;
            vm.wf = null;
            vm.userSettings = USER_SETTINGS;
            vm.logged_in_user = vm.userSettings.user.sso_id;

            vm.formHeader = null;
            vm.tableHeader = null;
            vm.formList1 = null;
            vm.formList = null;
            vm.tableRows = [];
            vm.formFormulaList = null;

            vm.allFormHeader = [];
            vm.allTableHeader = [];
            vm.allFormList = [];
            vm.allTableRows = [];
            vm.allFormFormulaList = [];

            vm.allFormHeaderOrig = [];
            vm.allTableHeaderOrig = [];
            vm.allFormListOrig = [];
            vm.allTableRowsOrig = [];
            vm.allFormFormulaListOrig = [];

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.wf = rowData.WORKFLOW;

            if( vm.wf == 'F') {
                vm.workflow = "Forecast";
            } else if(vm.wf == 'A') {
                vm.workflow = 'Annualized';
            }

            vm.modalTitle = "Estimated Tax Projection - " + vm.workflow + ' for ' + rowData.ME_NAME + " ( " + rowData.ME_CODE + " )";
            if($uibModalInstance) {
                vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
                vm.cancel = function () {
                    $uibModalInstance.close();
                    vm.modal_name = null;
                };
            } else {
                vm.modal_name = null;
            }
            vm.selectedTabName = 'Taxable Income';

            var dataParams = {"action_code" : 'bd3szx', "sso_id": vm.logged_in_user, "tax_year": filterParams.tax_year,
                              "out_year": filterParams.out_year, "qtr":filterParams.qtr, "wf_name": rowData.WORKFLOW, 
                              "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": rowData.COMBINATION_KEY,
                              "schedule_name": vm.sn,"part_no":vm.pn,"section_name":vm.sm,"jcd_ta_key": null,"client_key":USER_SETTINGS.user.client_key};

            var formulaParams = {"action_code" : '39bdwl', "tax_year": filterParams.tax_year, "jcd_key": GlobalService.globalParams.jcd_key, "wf_name": rowData.WORKFLOW, "schedule_name": vm.sn,"part_no":vm.pn,"section_name":vm.sm};

            vm.estFormDataAll = function(defaultSchedule) {

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', dataParams).then(function (data) {
                    vm.allTableRows = [];
                    var scheduleName;
                    vm.allFormData = data.jsonObject;
                    vm.allFormHeader = $filter("filter")(vm.allFormData, {ROW_TYPE:"FH"}); //vm.allFormData[0];
                    vm.allTableHeader = $filter("filter")(vm.allFormData, {ROW_TYPE:"TH"});
                    // exlude form header
                    vm.allFormList1 = vm.allFormData.slice(1);
                    //exlude table header ( now formList has PH, DT and AGG ROW_TYPE)
                    vm.allFormList = vm.allFormList1.filter(function( obj ) {
                        return obj.ROW_TYPE !== 'TH';
                    });

                    vm.allOuterList = [];

                    for(var i=0;i<vm.allFormList.length;i++){

                        if(vm.allFormList[i].FORM_LINE_KEY != null){

                            scheduleName = vm.allFormList[i].SCHEDULE_NAME;

                            if(vm.allFormList[i].ATTRIB_ORDER == 1) {
                                vm.allOuterList = [];
                                vm.allOuterList.push(vm.allFormList[i]);

                            }
                            else {

                                if(vm.allOuterList != null && vm.allOuterList.length > 0){
                                    var tempCheck1 = $filter("filter")(vm.allOuterList, {FORM_LINE_KEY:vm.allFormList[i].FORM_LINE_KEY});
                                    if(tempCheck1 != undefined && tempCheck1.length != 0){
                                        //var tempCheck1 = $filter("filter")(vm.allOuterList, {FORM_LINE_KEY:vm.allFormList[i].FORM_LINE_KEY});
                                        vm.allOuterList.push(vm.allFormList[i]);
                                    }
                                }

                            }

                            if(scheduleName == 'TI' || scheduleName == 'FSI' || scheduleName == 'OI' || scheduleName == 'GBF') {
                                if(vm.allFormList[i].ROW_TYPE == 'PH' && vm.allFormList[i].ATTRIB_ORDER == 4) {
                                    vm.allTableRows.push(vm.allOuterList);
                                }
                                if(vm.allFormList[i].ROW_TYPE == 'DT' && vm.allFormList[i].ATTRIB_ORDER == 3) {
                                    vm.allTableRows.push(vm.allOuterList);
                                }
                                if(vm.allFormList[i].ROW_TYPE == 'AGG' && (vm.allFormList[i].ATTRIB_ORDER == 4) ) {
                                    vm.allTableRows.push(vm.allOuterList);
                                }

                            }
                            else if (scheduleName == 'CF') {

                                vm.allOuterList = [];

                                if(vm.allOuterList != null && vm.allOuterList.length > 0){
                                    var tempCheck2 = $filter("filter")(vm.allOuterList, {FORM_LINE_KEY:vm.allFormList[i].FORM_LINE_KEY});
                                }

                                if(tempCheck2 == undefined || tempCheck2.length==0){
                                    vm.allOuterList.push(vm.allFormList[i]);
                                }
                                vm.allTableRows.push(vm.allOuterList);
                            }
                        }
                    }

                    //initialize with defaultSchedule's data
                    vm.formHeader = $filter("filter")(vm.allFormHeader, {SCHEDULE_NAME:defaultSchedule});
                    vm.tableHeader = $filter("filter")(vm.allTableHeader, {SCHEDULE_NAME:defaultSchedule});
                    vm.formList = $filter("filter")(vm.allFormList, {SCHEDULE_NAME:defaultSchedule});
                    vm.tableRows = $filter("filter")(vm.allTableRows, {SCHEDULE_NAME:defaultSchedule});

                });
            }

            vm.estFormCalcDataAll = function(defaultSchedule) {

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', formulaParams).then(function (data) {
                    vm.allFormFormulaList =  data.jsonObject;
                    //initialize with the formula for defaultSchedule
                    vm.formFormulaList = $filter("filter")(vm.allFormFormulaList, {SCHEDULE_NAME:defaultSchedule});

                });

            };

            vm.estFormDataAll('TI');
            vm.estFormCalcDataAll('TI');

            vm.loadESTFormDetails = function(scheduleName) {
                vm.formHeader = $filter("filter")(vm.allFormHeader, {SCHEDULE_NAME:scheduleName});
                vm.tableHeader = $filter("filter")(vm.allTableHeader, {SCHEDULE_NAME:scheduleName});
                vm.formList = $filter("filter")(vm.allFormList, {SCHEDULE_NAME:scheduleName});
                vm.tableRows = $filter("filter")(vm.allTableRows, {SCHEDULE_NAME:scheduleName});
                vm.formFormulaList = $filter("filter")(vm.allFormFormulaList, {SCHEDULE_NAME:scheduleName});
            }

            vm.applyFormula = function(line) {

                line.IS_CHANGED_FLAG = 'Y';

                if(line.DATA_TYPE === 'number') {

                    if(line.SCHEDULE_NAME == 'TI' || line.SCHEDULE_NAME == 'FSI' || line.SCHEDULE_NAME == 'OI' || line.SCHEDULE_NAME == 'GBF') {

                        console.log(' line ', line);
                        for(var i=0; i < vm.formFormulaList.length; i++) {
                            var calcFormula = vm.formFormulaList[i].ATTRIB_CALC_FORMULA;

                            if(calcFormula != null && calcFormula.trim().length > 0)
                                calcAmt(calcFormula,vm.formFormulaList[i].LINE_NO,vm.formFormulaList[i].LINE_ATTRIB_KEY, line.DATA_TYPE, vm.formList);
                        }

                        vm.cashFlowFormList = $filter("filter")(vm.allFormList, {SCHEDULE_NAME:'CF'});
                        vm.cashFlowFormulaList = $filter("filter")(vm.allFormFormulaList, {SCHEDULE_NAME:'CF'});

                        console.log('vm.cashFlowFormList = ', vm.cashFlowFormList);
                        console.log('vm.cashFlowFormulaList = ', vm.cashFlowFormulaList);

                        for(var i=0; i < vm.cashFlowFormulaList.length; i++) {
                            var calcFormulaCF = vm.cashFlowFormulaList[i].ATTRIB_CALC_FORMULA;
                            if(calcFormulaCF != null && calcFormulaCF.trim().length > 0) {
                                calcCFAmt(calcFormulaCF,vm.cashFlowFormulaList[i].LINE_NO,vm.cashFlowFormulaList[i].LINE_ATTRIB_KEY, line.DATA_TYPE, vm.cashFlowFormList);
                            }

                        }

                    }

                    else if(line.SCHEDULE_NAME == 'CF') {

                        console.log(' line ', line);
                        for(var i=0; i < vm.formFormulaList.length; i++) {
                            var calcFormula = vm.formFormulaList[i].ATTRIB_CALC_FORMULA;

                            if(calcFormula != null && calcFormula.trim().length > 0)
                                calcCFAmt(calcFormula,vm.formFormulaList[i].LINE_NO,vm.formFormulaList[i].LINE_ATTRIB_KEY, line.DATA_TYPE, vm.formList);
                        }

                    }

                }

            }

            vm.markChange = function(line) {
                line.IS_CHANGED_FLAG = 'Y';
            }

            function calcAmt(formula, line_no, attrib_key, dataType, targetFormList){

                var lineAmt = 0;
                var matches = formula.match(/\b[^\d\s]+\b/g);
                var lineFormulaArray;

                console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }
                console.log('lineFormulaArray = ' , lineFormulaArray);

                for(var i = 0; i<lineFormulaArray.length; i++){

                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");

                    var newTemp1 = $filter("filter")(vm.formList, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
                    //var newTemp1 = $filter("filter")(vm.allFormList, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
                    //console.log('ATTRIBUTE_SHORT_CODE = ', lineFormulaArray[i].trim());
                    //console.log('newTemp1 =', newTemp1);

                    lineAmt = parseFloat(newTemp1[0].ATTRIB_VALUE);
                    if(newTemp1[0].IS_EDITABLE_CELL == 'Y' && dataType != null){

                        //newTemp1[0].IS_CHANGED_FLAG = 'Y';
                    }
                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
                }

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself
                    //document.getElementById(subfrmTxtPrfx+line_no).value = eval(formula);// Not parsing to Int for getting Float values

                    var newTemp123 = $filter("filter")(/*vm.formList*/targetFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                    //var newTemp123 = $filter("filter")(vm.allFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                    var originalValue = newTemp123[0].ATTRIB_VALUE;
                    var xyz = eval(formula);

                    newTemp123[0].ATTRIB_VALUE = xyz.toFixed();
                    if(dataType != null && (originalValue != newTemp123[0].ATTRIB_VALUE) ){
                        newTemp123[0].IS_CHANGED_FLAG = 'Y';
                    }
                }

            }

            function calcCFAmt(formula, line_no, attrib_key, dataType, targetFormList){

                var lineAmt = 0;
                var matches = formula.match(/\b[^\d\s]+\b/g);
                var lineFormulaArray;

                console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }
                console.log('lineFormulaArray = ' , lineFormulaArray);

                for(var i = 0; i<lineFormulaArray.length; i++){

                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");

                    var newTemp1 = $filter("filter")(vm.allFormList, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
                    //var newTemp1 = $filter("filter")(vm.allFormList, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
                    //console.log('ATTRIBUTE_SHORT_CODE = ', lineFormulaArray[i].trim());
                    //console.log('newTemp1 =', newTemp1);

                    console.log('quarter =' , filterParams.qtr);


                    if(newTemp1[0].ATTRIB_SHORT_CODE == 'EST_A_CF_43_2_MULTI' || newTemp1[0].ATTRIB_SHORT_CODE == 'EST_A_CF_43_4_MULTI') {
                        console.log( '---------------special multiplier 1 -------------' , newTemp1[0].ATTRIB_SHORT_CODE);

                        if(filterParams.qtr == '1' || filterParams.qtr == '2')
                            lineAmt = 4.0;
                        else if(filterParams.qtr == '3')
                            lineAmt = 2.0;
                        else if(filterParams.qtr == '4')
                            lineAmt = 1.33333;
                        else if(filterParams.qtr == '5')
                            lineAmt = 1.0;
                        else
                            lineAmt = parseFloat(newTemp1[0].ATTRIB_VALUE);
                    }
                    else if(newTemp1[0].ATTRIB_SHORT_CODE == 'EST_A_CF_53_1_MULTI' || newTemp1[0].ATTRIB_SHORT_CODE == 'EST_F_CF_26_1_MULTI') {
                        console.log('---------------special multiplier 2 -------------' ,  newTemp1[0].ATTRIB_SHORT_CODE);

                        if(filterParams.qtr == '1' )
                            lineAmt = 0.25;
                        else if (filterParams.qtr == '2')
                            lineAmt = 0.5;
                        else if(filterParams.qtr == '3')
                            lineAmt = 0.75;
                        else if(filterParams.qtr == '4' || filterParams.qtr == '5')
                            lineAmt = 1.0;
                        else
                            lineAmt = parseFloat(newTemp1[0].ATTRIB_VALUE);
                    }
                    else {
                        lineAmt = parseFloat(newTemp1[0].ATTRIB_VALUE);
                    }


                    if(newTemp1[0].IS_EDITABLE_CELL == 'Y' && dataType != null){


                        //newTemp1[0].IS_CHANGED_FLAG = 'Y';
                    }
                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
                }

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself
                    //document.getElementById(subfrmTxtPrfx+line_no).value = eval(formula);// Not parsing to Int for getting Float values

                    var newTemp123 = $filter("filter")(/*vm.formList*/targetFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                    //var newTemp123 = $filter("filter")(vm.allFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                    var originalValue = newTemp123[0].ATTRIB_VALUE;
                    var xyz = eval(formula)
                    newTemp123[0].ATTRIB_VALUE = xyz;
                   // newTemp123[0].ATTRIB_VALUE = xyz.toFixed(); // ON 01/07/2021 --Removed toFixed() to align according to DCS TAX DUE IN CF POPUP SCREEN.
                    if(dataType != null && (originalValue != newTemp123[0].ATTRIB_VALUE) ){
                        newTemp123[0].IS_CHANGED_FLAG = 'Y';
                    }
                }

            }

            vm.onTabChange = function(e) {
                vm.selectedTabName = e;
                $timeout(function() {
                    var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                    var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                    if (movingTab && selectedTab) {
                        $(movingTab).width(selectedTab.offsetWidth);
                        $(movingTab).height(selectedTab.offsetHeight)
                            .css('line-height', selectedTab.offsetHeight + 'px')
                            .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                    }
                }, 200);
            };

            vm.cancel = function () {
                if(angular.isObject($uibModalInstance)) {
                    $uibModalInstance.close();
                    vm.modal_name = null;
                }

            };

            vm.reset = function(tabIndex) {

                var activeTabs = ['TI','FSI','OI','GBF','CF'];
                vm.estFormDataAll(activeTabs[tabIndex]);
                vm.estFormCalcDataAll(activeTabs[tabIndex]);

            };

            vm.save = function() {
                vm.isSaveClicked = true;
                vm.saveData;
                vm.commentsData;

                var returnClobSettingsObj = {};
                var ESTFormSaveDtls = [];
                var allSaveData = [];
                var scheduleNames = ['TI', 'FSI', 'OI', 'GBF', 'CF'];

                for(var j=0; j < scheduleNames.length; j++) {
                    var scheduleName = scheduleNames[j];
                    vm.saveData = $filter("filter")(vm.allFormList, {SCHEDULE_NAME:scheduleName, IS_CHANGED_FLAG:'Y', ATTRIB_COL:'b'});
                    vm.commentsData = $filter("filter")(vm.allFormList, {SCHEDULE_NAME:scheduleName, IS_CHANGED_FLAG:'Y', ATTRIB_COL:'d'});
                    if (vm.commentsData.length > 0) {

                        for(var i=0; i < vm.commentsData.length; i++) {
                            vm.saveData.push(vm.commentsData[i]);
                        }

                    }

                    allSaveData.push(vm.saveData);
                }

                for(var key in allSaveData) {

                    vm.saveData = allSaveData[key];
                    for(var i=0;i < vm.saveData.length;i++) {
                        var returnObj = {};
                        returnObj['form_name'] =  vm.saveData[i].FORM_NAME;
                        returnObj['part_no'] =  vm.saveData[i].PART_NO;
                        returnObj['schd_no'] =  vm.saveData[i].SCHEDULE_NAME;
                        returnObj['section_name'] =  vm.saveData[i].SECTION_NAME;
                        returnObj['row_type'] = vm.saveData[i].ROW_TYPE;
                        returnObj['combination_key'] = vm.saveData[i].COMBINATION_KEY;
                        returnObj['form_key'] = vm.saveData[i].FORM_KEY;
                        returnObj['form_line_key'] = vm.saveData[i].FORM_LINE_KEY;
                        returnObj['line_attrib_key'] = vm.saveData[i].LINE_ATTRIB_KEY;
                        returnObj['attrib_type'] = vm.saveData[i].ATTRIB_TYPE;
                        returnObj['attrib_prop_key'] = vm.saveData[i].ATTRIB_PROP_KEY;
                        returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REF_KEY;
                        returnObj['attrib_value'] = vm.saveData[i].ATTRIB_VALUE;
                        returnObj['trans_details_key'] = vm.saveData[i].TRANS_DETAILS_KEY;
                        returnObj['trans_type_key'] = vm.saveData[i].TRANS_TYPE_KEY;
                        returnObj['is_changed_flag'] = vm.saveData[i].IS_CHANGED_FLAG;
                        returnObj['occurrence'] = (vm.saveData[i].OCCURRENCE != null) ? parseInt(vm.saveData[i].OCCURRENCE) : 1;
                        var message = "Form Details successfully saved/updated";
                        ESTFormSaveDtls.push(returnObj);
                    }

                }

                console.log(JSON.stringify(ESTFormSaveDtls));

                returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
                returnClobSettingsObj['form_name'] = 'EST_' + vm.wf;
                returnClobSettingsObj['wf'] = vm.wf;

                sendDetails(returnClobSettingsObj,ESTFormSaveDtls, message);

            }

            function sendDetails(returnClobSettingsObj, ESTFormSaveDtls, message) {
                estFormServiceFactory.saveESTForm(returnClobSettingsObj,ESTFormSaveDtls).then(function(result) {

                    if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                        vm.isSaveClicked = false;
                        AlertService.add("error", result.data.errorMessage, 4000);
                    } else {
                        //vm.crudLoading = false;
                        AlertService.add("success", message, 4000);
                        var args = {
                            me_code: returnClobSettingsObj.me_code,
                            gridFilter: {
                                me_code: returnClobSettingsObj.me_code
                            }
                        };
                        $uibModalInstance.dismiss('cancel');
                        $rootScope.$emit('gridUpdate', args);
                    };

                });
            }

        }

        return controllers;
    }
);