/**
 * Created by 212544474 on 4/1/2016.
 */
 define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.sourcingDefaultController',[])
        .controller('sourcingDefaultController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','Tagging163jFactory','GENERAL_CONFIG', 'workspaceFactory','USER_SETTINGS','SystemLockUnlockFactory', '$window', sourcingDefaultController])

    function sourcingDefaultController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory,$timeout,Tagging163jFactory,GENERAL_CONFIG, workspaceFactory, USER_SETTINGS, SystemLockUnlockFactory, $window){

        let vm = this;
        let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

        vm.webComponentHtml = '';
        vm.userSettings = USER_SETTINGS;
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;            
        // vm.acct_key = gridData.rowData.ACCT_KEY;
        // vm.parent_me_key = gridData.rowData.PARENT_ME_KEY;
        // vm.acct_type = gridData.rowData.ACCT_TYPE;
        var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
        vm.globalParams = JSON.stringify(tempObj);
        vm.tempGridData = JSON.stringify(gridData);
        vm.checkLockeOdbj =[];
        vm.dataLoading = true;

        SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
            () => {
                vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                vm.tax_year = filterParams.tax_year;
                vm.scenario = filterParams.scenario;
                vm.jcd_key = GlobalService.globalParams.jcd_key;
                vm.filing_key = filterParams.filing_key;
                vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                vm.logged_in_user = vm.userSettings.user.sso_id;
                vm.screen_key = workspaceFactory.activeScreen.screen_key;
                vm.checkLockeOdbj.push({"me_code":gridData[0].data.ME_CODE, "consol_group_key":vm.filing_key, "scenario_key":vm.scenario, "tax_year":vm.tax_year, "scenarioCode": vm.scenarioCode, "is_issue_key_enabled":'N'});

                //  console.log(vm.tax_year , ' : ', vm.scenario, ' : ', vm.jcd_key, ' : ', vm.logged_in_user , ' : ', vm.screen_key , ' : ', vm.combination_key , ' : ', vm.checkLockeOdbj);

                vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);

                const html = `<gtw-batch-sourcing-default-me-ce id="open-batch-sourcing-info" id="save-batch-sourcing-info" base-urls="{{ctrl.baseURLs}}" global-params="{{ctrl.globalParams}}" 
                                tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" screen-key="{{ctrl.screen_key}}" sso-id="{{ctrl.logged_in_user}}" 
                                filing-key ="{{ctrl.filing_key}}" grid-data = "{{ctrl.tempGridData}}" scenario-code = "{{ctrl.scenarioCode}}" check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" class="gtw-web-components"></gtw-batch-sourcing-default-me-ce>`;
                vm.webComponentHtml = html;
            }
        );

        if ($uibModalInstance) {

            $timeout(() => {
                $window.document.querySelector('gtw-batch-sourcing-default-me-ce')
                        .addEventListener('open-batch-sourcing-info', (event)=>{
                            console.log('event',event);
                            $uibModalInstance.close();
                        });
                    console.log('...event listener added ...');
                },3000
            );

            $timeout(() => {

                    $window.document.querySelector('gtw-batch-sourcing-default-me-ce')
                        .addEventListener('save-batch-sourcing-info', (event)=>{
                            console.log('event',event);
                            
                            if(event?.detail == 'save-batch-sourcing-info'){
                                AlertService.add("info", "The default sourcing will be regenerated for selected businesses", 4000);
                                $uibModalInstance.close();
                                // var args = {gridFilter: {ACCOUNT: gridData[0].data.ACCOUNT, ME_CODE :gridData[0].data.ME_CODE, object_id:gridData[0].data.object_id}};
                                // $rootScope.$emit('gridUpdate', args);
                                /* $rootScope.$broadcast('dataFilters:refreshGrid', {
                                    "refresh": true
                                });*/
                                // $window.document.querySelector('gtw-batch-sourcing-default-me-ce')
                                //     .removeEventListener('save-batch-sourcing-info', (event)=>{
                                //         console.log('Listener Removed',event);
                                //     });
                            }
                        });
                    console.log('...event listener added ...');
                    vm.dataLoading = false;
                },3000
            );

            $scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

            $scope.cancel = function() {
                $uibModalInstance.close();
                $scope.modal_name = null;
            };

        } else {
            $scope.modal_name = null;
        }

    }///////////////END CTRL
    
    return controllers;
});