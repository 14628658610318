define([
    'angular'

], function () {
    'use strict';




    var controllers =  angular.module('app.ruleEngineConfigControllers',[])
       .controller('validationRuleEditCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','USER_SETTINGS','RuleEngineConfigFactory','$uibModalInstance','rowData','gridData', validationRuleEditCtrl])
       .controller('validationRulesGridCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','USER_SETTINGS','ClientAdminFactory','$parse', validationRulesGridCtrl])
       .controller('validationRuleNewEditCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','USER_SETTINGS','RuleEngineNewConfigFactory','$uibModalInstance','rowData','gridData', validationRuleNewEditCtrl])

       function validationRuleEditCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,USER_SETTINGS,RuleEngineConfigFactory,$uibModalInstance,rowData,gridData) {
    	 var vm = this;
         vm.title = "Manage Validation Rules";
         vm.clients = [];
         vm.all_v_rules_data = [];
         vm.userSettings = USER_SETTINGS;
          vm.loading = false;
          vm.client = gridData;

         vm.getValidationRuleConfigData = function(client_key){
          	vm.loading = true;
          	var data = {};
          	data.client_key = vm.client.clientKey;
            console.log("getValidationRuleByKey data ::",data);
            RuleEngineConfigFactory.getRulesAllData(data).then(function (data) {
                console.log("data ::",data);
             	vm.loading = false;
             	if(data){
             		vm.all_v_rules_data = data.jsonObject;
             	}
             	console.log("vm.all_v_rules_data ::",vm.all_v_rules_data );
             });
         }

         vm.saveRule = function(){
        	 console.log("Saving Please wait... integrate with sid save logic.");
         }
         vm.close = function(){
            $rootScope.$broadcast('gridRefresh', true);
           	$uibModalInstance.dismiss('cancel');
         }

         vm.getValidationRuleConfigData();

    }
    function validationRulesGridCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,USER_SETTINGS,ClientAdminFactory,$parse) {
    	 var vm = this;
         vm.name = "Validation Rules Grid";
         vm.clients = [];
         vm.userSettings = USER_SETTINGS;
         vm.filter = {
            client_key:vm.userSettings.user.client_key.toString()
         }

         if(vm.userSettings){
        	 vm.clients = vm.userSettings.user.clients;
             vm.client_key = vm.clients[0].client_key;
             vm.logged_in_user = vm.userSettings.user.sso_id;
         }
         vm.manageValidationRules = function(){
        	 console.log("Integrate with Sids code.. please wait....");
        	 var modalObj = {};
         	 modalObj.template = "app/components/admin/rulesengine/editValidationRule.html";
             modalObj.controler = "validationRuleNewEditCtrl as ctrl";
             modalObj.size = "lg";
             modalObj.keyboard = false;
             modalObj.backdrop = "static";
             var _data = {
                 clientKey : vm.filter.client_key,
                 isNewRule : true
             };

             vm.openModal(modalObj,_data);
         }

         vm.refresh = function(){
        	 console.log("Refreshing.. Please wait....");
         }

         vm.getValidationRulesByByClient = function(){
        	 console.log("Not Implemented yet.. Please wait....")
         }
         var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
             var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
             delete args['gridFilter'];
             getUpDate(args,filterObj);
         });


         $scope.$on('$destroy', function iVeBeenDismissed() {
             gridUpdateListener();
         });

         vm.refresh = function(_params){
             getData();
         };

         vm.editRule =function(){
            console.log("Edit Rule please wait....");
            
            var modalObj = {};
            modalObj.template = "app/components/admin/rulesengine/editValidationRule.html";
            modalObj.controler = "validationRuleNewEditCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            
            var _data = {
                clientKey : vm.filter.client_key,
                isNewRule : false,
                currentRule : {RULE_KEY: 519}
            };

            vm.openModal(modalObj,_data);
         };

         vm.addNewValidationRule = function(){
             var data = {};
             var modalObj = {};
             modalObj.template = "app/components/admin/rulesengine/sidd-xyz.html";
             modalObj.controler = "siddNewValidationPopup-xyz-Ctrl as ctrl";
             modalObj.size = "md";
             modalObj.keyboard = false;
             modalObj.backdrop = "static";
             vm.openModal(modalObj,data);
         }

        // get the clientList initail load of page
		vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
            });
        };
        vm.getClientList();

         var getData = function() {
         	 $scope.$broadcast("showLoader", true);
             vm.loading = true;
             vm.gridData = [];
             vm.gridData.DATA = [];

             GridFactory.getDataCustom($state.current.api.get,vm.filter).then(function (data) {
                 if (data.callSuccess === "1") {
                     $scope.$broadcast("dataLoaded", data);
                     $scope.$broadcast("showLoader", false);
                 } else {
                     AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                 }
             });

         }

         vm.onClientChange = function() {
            getData();
        };

         var getUpDate = function(_params,_filterObj) {
           	 var additionalParams = {}
             var params = _.merge({}, additionalParams, _params,vm.filter);
             $scope.$broadcast("showDataRefresh", true);

             GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                 if (data.callSuccess === "1") {
                     $scope.$broadcast("showDataRefresh", false);
                     $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                 } else {
                     $scope.$broadcast("showDataRefresh", false);
                     AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                 }
             });
         }

         $timeout(function() {
         	$scope.$broadcast("showLoader", true);
             getData();
         }, 5);


         $scope.ctrlFn = function(_callName,_data,_type) {
         	var modalObj = {};
         	 modalObj.template = "app/components/admin/rulesengine/editValidationRule.html";
             modalObj.controler = "validationRuleNewEditCtrl as ctrl";
             modalObj.size = "lg";
             modalObj.keyboard = false;
             modalObj.backdrop = "static";
             _data = {
                clientKey : vm.filter.client_key,
                isNewRule : false,
                currentRule : {
                    RULE_KEY: _data.rowData.DROOLSRULEKEY,
                    RULE_NAME : _data.rowData.DROOLSRULENAME,
                    RULE_DESC : _data.rowData.DROOLSRULEDESCRIPTION,
                    RULE_PATH : _data.rowData.DROOLSRULESESSION
                }
            };
             vm.openModal(modalObj,_data);
         };

         vm.openModal = function (modalObj,_data) {
             vm.rowData = _data.rowData;
             vm.gridData = _data;

             var modalInstance = $uibModal.open({
                 animation: true,
                 templateUrl: modalObj.template,
                 controller: modalObj.controler,
                 size: modalObj.size,
                 backdrop: modalObj.backdrop,
                 keyboard: modalObj.keyboard,
                 resolve: {
                     rowData: function () {
                         return vm.rowData;
                     },
                     gridData: function () {
                         return vm.gridData;
                     }
                 }
             });

             modalInstance.result.then(function (selectedItem) {
                 $scope.selected = selectedItem;
             }, function () {
                 $log.info('Modal dismissed at: ' + new Date());
                 $log.info('$scope.rowData' , $scope.rowData);
             });
         };


    }

    
    function validationRuleNewEditCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,USER_SETTINGS,RuleEngineNewConfigFactory,$uibModalInstance,rowData,gridData) {
        var vm = this;
        vm.stepNumber = 1;
        vm.isNewRule = gridData.isNewRule ;
        vm.loading = false;
        vm.clientKey = gridData.clientKey;
        console.log('ValidationRuleNewEditRule - isNew', vm.isNewRule);
        
        vm.currentRule = {
            RULE_KEY : vm.isNewRule ? '' : gridData.currentRule.RULE_KEY,
            RULE_NAME : vm.isNewRule ? '' : gridData.currentRule.RULE_NAME,
            RULE_DESC: vm.isNewRule ? '' : gridData.currentRule.RULE_DESC,
            RULE_PATH : vm.isNewRule ? '' : gridData.currentRule.RULE_PATH,
            RULE_ACTIVE_FLAG : vm.isNewRule ? '1' : gridData.currentRule.RULE_ACTIVE_FLAG,
        };

        vm.stepOne = function(){
            vm.stepNumber =1;
        }

        vm.save = function () {
            vm.isLoading = true;
            const params ={
                client_key: vm.clientKey,
                clob_data: JSON.stringify([{...vm.currentRule}])
            };

            if(vm.isNewRule){
                RuleEngineNewConfigFactory.saveNewRuleData(params).then(function(data){
                    console.log({data});
                    vm.isLoading = false;
                    if(data && data.callSuccess==="1"){
                        vm.currentRule.RULE_KEY = data.jsonObject[0].VALIDATION_RULE_KEY;
                        vm.stepTwo();
                    }else if(data && data.callSuccess==="0"){
                         AlertService.add("warn", data.errorMessage, 4000);
                    }
                }).catch(function(error){
                    console.log({error});
                    vm.isLoading = false;
                    AlertService.add("warn", error, 4000);
                })
            } else {
                RuleEngineNewConfigFactory.updateRuleData(params).then(function(data){
                    console.log({data});
                    vm.isLoading = false;
                    if(data && data.callSuccess==="1"){
                        vm.stepTwo();
                    }else if(data && data.callSuccess==="0"){
                         AlertService.add("warn", data.errorMessage, 4000);
                    }
                }).catch(function(error){
                    console.log({error});
                    vm.isLoading = false;
                    AlertService.add("warn", error, 4000);
                })
            }
            
        }
        
        vm.getRuleLinkedAction = function(){
            const params = {
                client_key:vm.clientKey,
                rule_key: vm.currentRule.RULE_KEY
            }
            RuleEngineNewConfigFactory.getLinkedAction(params).then(function(data){
                console.log(data);
                vm.isLoading = false;
                if(data && data.callSuccess==="1"){
                    data.jsonObject.forEach(function(validationRule,index){
                        if(validationRule.validation_rule_key == params.rule_key){
                            vm.allLinkedActions = validationRule.validation_rule_actions;
                            vm.allLinkedCacheObjects =  validationRule.validation_rule_cache_objects;
                            console.log(vm.allLinkedCacheObjects);
                        }
                    });
                    
                }else if(data && data.callSuccess==="0"){
                    AlertService.add("warn", data.errorMessage, 4000);
                }
            }).catch(function(error){
                console.log({error});
                vm.isLoading = false;
                AlertService.add("warn", error, 4000);
            });

        };

        vm.addAction = function(){
            console.log("addAction()  :"); 
            const params = {
                "new_action_key":vm.new_action_key,
                "action_key": vm.new_action_key,
                "client_key": vm.clientKey,
                "validation_rule_key": vm.currentRule.RULE_KEY
            };

            vm.isLoading = true;
            RuleEngineNewConfigFactory.addAction(params).then(function(data){
                console.log(data);
                vm.new_action_key='';
                vm.isLoading = false;
                if(data && data.callSuccess==="1"){
                    vm.getRuleLinkedAction();
                    AlertService.add("info", "Added successfully", 4000);
                }else if(data && data.callSuccess && data.callSuccess==="0"){
                    AlertService.add("warn", data.errorMessage, 4000);
                }
            }).catch(function(error){
                console.log({error});
                vm.isLoading = false;
                AlertService.add("warn", error, 4000);
            });            

        }

        vm.linkDelinkAction = function(action,link_delink){
            vm.paramsAction = {
                rule_action_key: action.action_validation_key,
                client_key: vm.clientKey,
                lnk_or_d_lnk: link_delink
            };
            vm.isLoading = true;
            RuleEngineNewConfigFactory.delinkAction(vm.paramsAction).then(function(data){
                console.log(data);
                vm.isLoading = false;
                if(data && data.callSuccess==="1"){
                    AlertService.add("info", "Operation Completed", 4000);
                    vm.stepTwo();
                }else if(data && data.callSuccess && data.callSuccess==="0"){
                    AlertService.add("warn", data.errorMessage, 4000);
                }
            }).catch(function(error){
                console.log({error});
                vm.isLoading = false;
                AlertService.add("warn", error, 4000);
            });

        };

        vm.stepTwo = function(){
            vm.isLoading = true;
            vm.stepNumber = 2;
            vm.getRuleLinkedAction();
        };
        
        vm.stepThree = function(){
            vm.stepNumber = 3 ;
        };

        vm.delinkCacheObjects = function(cache){
            vm.paramsCacheObjects = {
                drools_cache_key: cache.drools_cache_key,
                validation_rule_key: vm.currentRule.RULE_KEY,
                client_key: vm.clientKey
            };

            vm.isLoading = true;
            RuleEngineNewConfigFactory.delinkCacheObject(vm.paramsCacheObjects).then(function(data){
                console.log(data);
                vm.isLoading = false;
                if(data && data.callSuccess==="1"){
                    vm.getRuleLinkedAction();
                    AlertService.add("info", "Operation Completed", 4000);
                }else if(data && data.callSuccess && data.callSuccess==="0"){
                    AlertService.add("warn", data.errorMessage, 4000);
                }
            }).catch(function(error){
                console.log({error});
                vm.isLoading = false;
                AlertService.add("warn", error, 4000);
            });            
        }

        vm.addCacheObject = function(){
            const params = {
                    "new_cache_key":vm.new_cache_object_key,
                    "url": "g1pp14",
                    "cache_key": vm.new_cache_object_key,
                    "client_key": vm.clientKey,
                    "validation_rule_key": vm.currentRule.RULE_KEY
            };

            vm.isLoading = true;
            RuleEngineNewConfigFactory.addCacheObject(params).then(function(data){
                console.log(data);
                vm.new_cache_object_key='';
                vm.isLoading = false;
                if(data && data.callSuccess==="1"){
                    vm.getRuleLinkedAction();
                    AlertService.add("info", "Added successfully", 4000);
                }else if(data && data.callSuccess && data.callSuccess==="0"){
                    AlertService.add("warn", data.errorMessage, 4000);
                }
            }).catch(function(error){
                console.log({error});
                vm.isLoading = false;
                AlertService.add("warn", error, 4000);
            });            

        }

        vm.close = function(){
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('gridRefresh', true);
        }
    }


    return controllers;

});
