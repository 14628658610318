define([
    'angular',
    './ghostSettlementReclassesService', 
    './ghostSettlementReclassesDeleteCtrl',
    
], function () {
    'use strict';
    return angular.module('app.FIR_Ghost_Settlement_Reclasses', ['app.ghostSettlementReclassesService','app.ghostSettlementReclassesDeleteCtrl'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        
    }])

});