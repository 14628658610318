define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.ghostSystemService',[])
       .factory("ghostStatusSaveFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG', 
    	   
    	   function($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG) {
	           var factory = {};
	           var returnObj = {};
	           var ghostStatusSaveFactory ={};
	           var service = $injector.get('JsonObjectFactory');
               var defer = $q.defer();
	           ghostStatusSaveFactory.url ={};
	           ghostStatusSaveFactory.url.saveGhostStatus = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=5l44op";
	         
	           ghostStatusSaveFactory.saveGhostStatus = function(_callName , _data){
	               console.log("data", _data);
	               console.log("callname - ",_callName);

	  				$rootScope.$broadcast('gridRefresh', false);
	  				if(_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id])
					{
						return;
					}
					if(_data.col_id !== 'SCENARIO_STATUS' && _data.col_id !== 'CLIENT_NAME'){
						if( _data.rowData[_data.col_id] !== 'Y' && _data.rowData[_data.col_id] !== 'N') {	  						
							var intr=setInterval(function(){ 
								AlertService.add("error", "Value can be only Y or N ");
							   clearInterval(intr);
							   $rootScope.$apply();
							},1000);
							return false;
						}
					}
	  				
	  				var ssoId = USER_SETTINGS.user.sso_id;
	  				var params = {
	  					filing_key:_data.rowData.FILING_GROUP,
	                    column_value : _data.rowData[_data.colData.map],
	  					column_name :_data.colData.map,
	  					tax_year: _data.rowData.TAX_YEAR,
	  					scenario: _data.rowData.SCENARIO
	  				
	  				};
	  				/*var promise = $http({
	  					method: "post",
	  					url: ghostStatusSaveFactory.url.saveGhostStatus,
	  					data: params
	  				}).then(function (response) {
	  					if(response.data.callSuccess === "1"){
	  						AlertService.add("success", "Data saved Successfully.",3000);
	  						var args = {tax_year: _data.rowData.TAX_YEAR, gridFilter: {TAX_YEAR: _data.rowData.TAX_YEAR}};
			                $rootScope.$emit('gridUpdate', args);
	  	                }else{
	  	                	if(response.data.errorMessage){
	  	                		AlertService.add("error", response.data.errorMessage, 3000);
	  							return;
	  	                	}else{
	  	                		AlertService.add("error", "Error!!! Ghost System Staus is not updated", 3000);
	  							return;
	  	                	}
	  					}	  					
	  				});
	        return promise;

	           }*/
	  				 service.saveJSON(ghostStatusSaveFactory.url.saveGhostStatus,params).then(function (data) {
			        	   if (data.callSuccess === "1") {						
			        		   AlertService.add("success", "Data saved Successfully.",3000);
		  						var args = {tax_year: _data.rowData.TAX_YEAR, gridFilter: {TAX_YEAR: _data.rowData.TAX_YEAR}};
				                $rootScope.$emit('gridUpdate', args);
								defer.resolve(data);
							}else {
		                     AlertService.add("error", data.errorMessage, 4000);
		                     defer.reject(data);
		                 }},function(data){
		                 defer.reject(data);
		             });
		             return defer.promise;
				   }
	   return ghostStatusSaveFactory;
	          }]);
   return services;
});