/**
 * Created by 400371031 on 1/20/2021.
 */
define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.m3PayorAllocateController',[])
        .controller('m3PayorAllocateController', 
        ['$scope', '$filter', '$rootScope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService',
         'rowData', 'gridData' ,'JsonObjectFactory','$timeout','GENERAL_CONFIG', 'USER_SETTINGS',
         'm3PayorAllocateFactory', m3PayorAllocateController])


    function m3PayorAllocateController( $scope, $filter, $rootScope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory,$timeout,GENERAL_CONFIG,USER_SETTINGS,m3PayorAllocateFactory){

  
        var vm = this;
        
        console.log("=======m3PayorAllocateController=======");
        console.log(gridData);
        console.log("=======rowData=======");
        console.log(rowData);
        
        vm.allocatedPayors    	 = [];  
        vm.tp_pool_list       	 = [];
        vm.tp_dropdown_data      = [];
        vm.tp_me_dropdown_data   = [];
        vm.payorAccountData   	 = [];
		vm.entityOriginalData    = [];
		vm.le_key_list		     = [];
		vm.M3payorList           = [];
		vm.clob_settings		 = [];
		vm.crudLoading = false;
		vm.is_allocate_access = false;
		
		vm.userSelectedData = gridData;
		vm.payment_type 		= vm.userSelectedData[0].data.PAYMENT_TYPE;
		vm.classification_type 	= vm.userSelectedData[0].data.CLASSIFICATION_TYPE;
		vm.trans_type 			= "BOOK_AMT";
		console.log(vm.payment_type);
		vm.le_keys			= "";
        vm.title 			= "Payor Allocation";       
        vm.taxYear  		= GlobalService.globalParams.tax_year;   
        vm.scenario 		= GlobalService.globalParams.scenario;
        vm.taxYear  		= GlobalService.globalParams.jcd_key;
        vm.userSettings 	= USER_SETTINGS;
		vm.logged_in_user 	= vm.userSettings.user.sso_id;
		vm.M3_Payor_types 	= [{"PAYOR_TYPE": 'Foreign Corp', "TYPE": 'FC'},
			   				   {"PAYOR_TYPE": 'Domestic Corp', "TYPE": 'DC'},	
			   				   {"PAYOR_TYPE": 'Domestic Partnership', "TYPE": 'DP'},
			   				   {"PAYOR_TYPE": 'Foreign Partnership', "TYPE": 'FP'}];
		
  
        $scope.loadingAccounts = false;
        $scope.existingLoading = false;
        $scope.noRecords = false;
        $scope.fromScreen = false;                
        $scope.delTransDetails = [];
        $scope.local_acc_dropdown_data = [];
        $scope.tp_dropdown_data = [];
        $scope.taxYearBegin = "";
        $scope.taxYearEnd = "";
        $scope.lazyLoadObj = {};    
		
		vm.payor_name = '';
		vm.payor_ein = '';

		m3PayorAllocateFactory.getThridPartyPayorList().then((data) => {
			vm.thirdPartyData = [];	
			data.jsonObject.forEach(element => {
				vm.thirdPartyData.push({
					PAYOR_NAME: element.PAYOR_EIN ? `${element.PAYOR_NAME}-${element.PAYOR_EIN}` : element.PAYOR_NAME
				});
			});
		});
   
        
        vm.cancel = function () {	
        	if(angular.equals(vm.entityData, vm.entityOriginalData)){
				$uibModalInstance.dismiss('cancel');
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
				}
				else if(confirm("Do you want to close Form without saving changes?")){
					$uibModalInstance.dismiss('cancel');
				}
			}
        };  
        
        vm.allocate_payor = function(){
        	
        	var o = vm.payorAccountData.reduce( (a,b) => {
				a[b.CC_KEY] = a[b.CC_KEY] || [];
				a[b.CC_KEY].push(b);
				return a;
			}, {});
			
			console.log("object o-------", o);
			
			var a = Object.keys(o).map(function(k){
				return { CC_KEY: o[k][0].CC_KEY , leid: o[k][0].LEID, cdr_number: o[k][0].CDR_NO, reporting_period: o[k][0].REPORTING_PERIOD, me_code:o[k][0].ME_CODE, LEDGER:o[k][0].LEDGER };
			});
			
			console.log("object a-------", a);				
			vm.allocateprocessdata = a;
			vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
			vm.crudLoading = true;
			m3PayorAllocateFactory.allocateProcess(vm.allocateprocessdata, vm.clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
        };

		vm.saveNewPayor = function() {
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			if(vm.payor_name == '') {
				AlertService.add("error", "Please enter the Payor Name", 4000);
				return;
			}
			let pattern = /\d{9}/;
			if(vm.payor_ein !== '' && !vm.payor_ein.match(pattern)) {
				AlertService.add("error", "EIN should be a 9 digit number", 4000);
				return;
			}
			var message = "New Third Party Payor was Saved";
			vm.crudLoading = true;
			m3PayorAllocateFactory.saveThirdPartyPayor(vm.payor_name, vm.payor_ein).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					vm.payor_name = '';
					vm.payor_ein = '';
					m3PayorAllocateFactory.getThridPartyPayorList().then((data) => {
						// console.log(data);
						vm.thirdPartyData = [];	
						data.jsonObject.forEach(element => {
							vm.thirdPartyData.push({
								PAYOR_NAME: element.PAYOR_EIN ? `${element.PAYOR_NAME}-${element.PAYOR_EIN}` : element.PAYOR_NAME
							});
						});
					});
				}
			});
		}
	    
	  	vm.save = function(activeTab){
			if(activeTab == 'setupPayor') {
				vm.saveNewPayor();
			} else {
				vm.finalData = [];
				vm.clob_settings = [];
				
				console.log("Original Data::::::::==========", vm.entityOriginalData);
				console.log("vm.entityData::::", vm.entityData);
				
				if(vm.crudLoading){
					AlertService.add("info", "Please wait while we save this request", 4000);
					return;
				}
				
				vm.entityFinalData = angular.copy(vm.entityData);
				if(angular.equals(vm.entityFinalData, vm.entityOriginalData)){
					vm.cancel();
				} else {
					
					var returnValue = validateFormValues();
					if(returnValue == 1){
						return false;
					};
					
					for(var x=0; x < vm.entityFinalData.length; x++){
						
						if(vm.entityFinalData[x].Sys_Account_data.length != null && vm.entityFinalData[x].Sys_Account_data.length != undefined){
							for(var w=0; w < vm.entityFinalData[x].Sys_Account_data.length; w++){
								if(vm.entityFinalData[x].Sys_Account_data[w].Account_data.length != null && vm.entityFinalData[x].Sys_Account_data[w].Account_data.length != undefined){
									for(var j=0; j < vm.entityFinalData[x].Sys_Account_data[w].Account_data.length; j++){
										if(angular.equals(vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].AMT_TYPE, "BOOK_AMT")){
											vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data.splice(0, 1);
										}
										if(vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data.length != null && vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data.length != undefined){
											for(var k=0; k < vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data.length; k++){
												if(vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data[k].IS_CHANGED === 'Y' && vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data[k].IS_OTHER_PAYOR == 'N'){
													
													vm.finalData.push(vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data[k]);
													
												}
												else{
													vm.finalData.push(vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].sub_acct_data[k]);
												}
											}
										}
										
										if(vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].DELETE_ROWS.length != null && vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].DELETE_ROWS.length != undefined){
											for(var k=0; k < vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].DELETE_ROWS.length; k++){
												vm.finalData.push(vm.entityFinalData[x].Sys_Account_data[w].Account_data[j].DELETE_ROWS[k]);
											}
										}
										
									}
								}
							}
						}
						
						
					}
					
					console.log("After changes, the final data was :::::::::::=========", vm.entityFinalData);
					console.log("After changes, the manipulated data was :::::::::::=========", vm.entityData);
					console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
					console.log("Data for the first time from DB was :::::::::::=========", vm.payorAccountData);
					//vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
					//sendDetails(vm.finalData, vm.clob_settings);
					vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
					sendDetailsDB(vm.finalData, vm.clob_settings);
			}
			
			}
			
		};
		
		function validateFormValues(){
			var returnValue = 0;
			for (var i=0; i < vm.entityData.length; i++){
				if(vm.entityData[i].Sys_Account_data.length != null && vm.entityData[i].Sys_Account_data.length != undefined){
					for(var w=0; w < vm.entityData[i].Sys_Account_data.length; w++){
						if(vm.entityData[i].Sys_Account_data[w].Account_data.length != null && vm.entityData[i].Sys_Account_data[w].Account_data.length != undefined){
							for (var j=0; j < vm.entityData[i].Sys_Account_data[w].Account_data.length; j++){
								if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != null && vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != undefined){
									for (var k=0; k < vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length; k++){
										if(angular.equals(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].AMT_TYPE, "BOOK_AMT")){
											if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].ROW_TYPE == 'NEW'){
												if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].hasOwnProperty('IS_OTHER_PAYOR') &&  vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].IS_OTHER_PAYOR == 'Y'){
													if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].LINE == '1' && vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_TYPE == null){
														vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].HAS_TYPE_ERROR = 'Y';
														vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_TYPE_ERROR = 'Please select the Payor Type';
														vm.entityData[i].Sys_Account_data[w].IS_OPEN = true;
														returnValue = 1;
													}
													if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_NAME == null){
														vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].HAS_NAME_ERROR = 'Y';
														vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_NAME_ERROR = 'Please enter Payor Name';
														vm.entityData[i].Sys_Account_data[w].IS_OPEN = true;
														returnValue = 1;
													}
												} else if(!vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].hasOwnProperty('TP_DETAILS') || vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_LEID == undefined || vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_LEID == null){
													vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].HAS_ERROR = 'Y';
													vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_ERROR = 'Please assign the Payor';
													vm.entityData[i].Sys_Account_data[w].IS_OPEN = true;
													returnValue = 1;
												}
											}
										}else {
											if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].hasOwnProperty('IS_OTHER_PAYOR') && vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].IS_OTHER_PAYOR == 'Y'){
												if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].LINE == '1' && vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_TYPE == null){
													vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].HAS_TYPE_ERROR = 'Y';
													vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_TYPE_ERROR = 'Please select the Payor Type';
													vm.entityData[i].Sys_Account_data[w].IS_OPEN = true;
													returnValue = 1;
												}
												if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_NAME == null){
													vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].HAS_NAME_ERROR = 'Y';
													vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].PAYOR_NAME_ERROR = 'Please enter Payor Name';
													vm.entityData[i].Sys_Account_data[w].IS_OPEN = true;
													returnValue = 1;
												}
											}
											
										}
									}
								}
							}
						}
					}
				}
			}
			
			/*if(returnValue != 1){
				if(ischangeLine1()){
					returnValue = validatePayor();
				}
			}*/
			return returnValue;
		};

		vm.reset = function(activeTab) {
			if(activeTab == 'setupPayor') {
				vm.payor_name = '';
				vm.payor_ein = '';
			} else {
			}
		}
		
		function ischangeLine1(){
			var returnOutput = true;
			for (var i=0; i < vm.entityData.length; i++){
				if(vm.entityData[i].Sys_Account_data.length != null && vm.entityData[i].Sys_Account_data.length != undefined){
					for(var w=0; w < vm.entityData[i].Sys_Account_data.length; w++){
						if(vm.entityData[i].Sys_Account_data[w].LINE != null && vm.entityData[i].Sys_Account_data[w].LINE != undefined && vm.entityData[i].Sys_Account_data[w].LINE == '1' && vm.entityData[i].Sys_Account_data[w].IS_VALIDATE == 1){
							if(vm.entityData[i].Sys_Account_data[w].Account_data.length != null && vm.entityData[i].Sys_Account_data[w].Account_data.length != undefined){
								for (var j=0; j < vm.entityData[i].Sys_Account_data[w].Account_data.length; j++){
									if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != null && vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != undefined){
										for (var k=0; k < vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length; k++){
											if(null != vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].IS_CHANGED){
												if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].IS_CHANGED == 'N'){
													returnOutput = false;
												}else{
													returnOutput = true;
													return returnOutput;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			return returnOutput;
		};
		
		function validatePayor(){
			var returnValue = 0;
			var assigned_tp_m_level = [];
			var assigned_tp_book_level = [];
			for (var i=0; i < vm.entityData.length; i++){
				if(vm.entityData[i].Sys_Account_data.length != null && vm.entityData[i].Sys_Account_data.length != undefined){
					for(var w=0; w < vm.entityData[i].Sys_Account_data.length; w++){
						if(vm.entityData[i].Sys_Account_data[w].LINE != null && vm.entityData[i].Sys_Account_data[w].LINE != undefined && vm.entityData[i].Sys_Account_data[w].LINE == '1' && vm.entityData[i].Sys_Account_data[w].IS_VALIDATE == 1){
							if(vm.entityData[i].Sys_Account_data[w].Account_data.length != null && vm.entityData[i].Sys_Account_data[w].Account_data.length != undefined){
								for (var j=0; j < vm.entityData[i].Sys_Account_data[w].Account_data.length; j++){
									if(vm.entityData[i].Sys_Account_data[w].Account_data[j].AMT_TYPE == 'M_ADJUSTMENT'){
										if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != null && vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != undefined){
											for (var k=0; k < vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length; k++){
												if(null != vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID && undefined != vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID){
													//assigned_tp_m_level = assigned_tp_m_level + '/' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID + '-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_CDR_NO +
													  //'-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_REP_PD;
													assigned_tp_m_level.push({tp_details:vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID + '-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_CDR_NO +
													  '-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_REP_PD, amount: (vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].ALLOCATED_AMT * -1)});
												}
											}
										}	
									}
									if(vm.entityData[i].Sys_Account_data[w].Account_data[j].AMT_TYPE == 'BOOK_AMT'){
										if(vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != null && vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length != undefined){
											for (var k=0; k < vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data.length; k++){
												if(null != vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID && undefined != vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID){
													//assigned_tp_book_level = assigned_tp_book_level + '/' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID + '-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_CDR_NO +
													  //'-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_REP_PD;
													assigned_tp_book_level.push({tp_details:vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_LEID + '-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_CDR_NO +
														  '-' + vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].TP_REP_PD, amount: vm.entityData[i].Sys_Account_data[w].Account_data[j].sub_acct_data[k].ALLOCATED_AMT});
												}
											}
										}	
									}
								}
							}
							returnValue = comparePayors(assigned_tp_m_level, assigned_tp_book_level);
							if(returnValue == 1){
								vm.entityData[i].Sys_Account_data[w].IS_OPEN = true;
								return returnValue;
							}
						}
					}
				}
			}
			
			return returnValue;
		};
		
		function objectsAreSame(x, y) {
			var objectsAreSame = true;
			
			if (x.length !== y.length) {
				return false;
		    }
			
			for(var i = 0; i < x.length; i++) {
				console.log("object x[i]-------", x[i]);
				console.log("object y[i]-------", y[i]);
				if(x[i].tp_details == y[i].tp_details) {
					if(x[i].amount != y[i].amount){
						objectsAreSame = false;
					}
			     }
			}
			return objectsAreSame;
		};
		
		function comparePayors(assigned_tp_m_level, assigned_tp_book_level){
			var returnValue = 0;
			console.log("All Payors at M Level are  ---------------::::", assigned_tp_m_level);
			console.log("All Payors at Book Level are ---------------::::", assigned_tp_book_level);
			//var m_level_tp_array = assigned_tp_m_level.split('/');
			//var book_level_tp_array = assigned_tp_book_level.split('/');
			//var m_level_tp_array = getarraySum(assigned_tp_m_level);
			//var book_level_tp_array = getarraySum(assigned_tp_book_level);
			
			var m_level_tp_array = [];
			var book_level_tp_array = [];
			
			assigned_tp_m_level.reduce(function(res, value) {
				if (!res[value.tp_details]) {
					res[value.tp_details] = { tp_details: value.tp_details, amount: 0 };
					m_level_tp_array.push(res[value.tp_details]);
				}
				res[value.tp_details].amount += value.amount;
				return res;
			}, {});
			
			assigned_tp_book_level.reduce(function(res, value) {
				if (!res[value.tp_details]) {
					res[value.tp_details] = { tp_details: value.tp_details, amount: 0 };
					book_level_tp_array.push(res[value.tp_details]);
				}
				res[value.tp_details].amount += value.amount;
				return res;
			}, {});
			
			m_level_tp_array = $filter('orderBy')(m_level_tp_array, 'tp_details');
			book_level_tp_array = $filter('orderBy')(book_level_tp_array, 'tp_details');
			
			console.log("object m_level_tp_array-------", m_level_tp_array);
			console.log("object book_level_tp_array-------", book_level_tp_array);
			
			//var output = objectsAreSame(m_level_tp_array, book_level_tp_array);
			var output = angular.equals(m_level_tp_array, book_level_tp_array);
			
			if(output)
				returnValue = 0;
			else
				returnValue = 1;
			
			if(returnValue == 1){
				AlertService.add("error", "The Trading Partner Details and the amount assigned for Book & M Adjustment of Line 1 is not same. Please verify and re-assign", 6000);
			}
			return returnValue;
		};
		
		function sendDetailsDB(final_data, clob_settings){
			var message = "Related Party Modal Data was Saved";
			vm.crudLoading = true;
			m3PayorAllocateFactory.saveRPModal(final_data, clob_settings, vm.classification_type, vm.trans_type).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		};
		
		$scope.getTradingPartners = function (line, val) {
			console.log("Line_no selected is :::::::", line);
            var params = {
                "action_code": 'eosx7n',
                "tax_year": $scope.taxYear,
                "scenario": $scope.scenario,
                "search_string": val,
                "line_no": line
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };
        
        $scope.setTradingPartner = function (tp_detais, entity_index, sys_acct_index, acct_index, sub_index) {
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].HAS_ERROR = 'N';
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        	if(tp_detais.IS_VALID == 1){
        		vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'N';
        		vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID   		= tp_detais.TP_LEID;
    			vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO 		= tp_detais.TP_CDR_NO;
    			vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD 		= tp_detais.TP_REP_PD;
    			vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE 		= tp_detais.TP_ME_CODE;
    			if(vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].AMT_TYPE == 'BOOK_AMT'){
    				vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_DETAILS 		= {"TP_LEID":tp_detais.TP_LEID, "TP_CDR_NO":tp_detais.TP_CDR_NO, "TP_REP_PD":tp_detais.TP_REP_PD, "TP_ME_CODE":tp_detais.TP_ME_CODE, "TP_ENTITY":""};
    			}
    			console.log("vm.entityData:::::::", vm.entityData);
        	}else if (tp_detais.IS_VALID == 0){
        		vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'Y';
        		vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID   		= null;
    			vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO 		= null;
    			vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD 		= null;
    			vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE 		= null;
    			console.log("vm.entityData:::::::", vm.entityData);
        	}
        };
        
        vm.setPayorType = function (payor_type, entity_index, sys_acct_index, acct_index, sub_index){
        	console.log("payor_type selected is:::::::", payor_type);
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        	if(payor_type != null && payor_type != undefined){
        		vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].HAS_TYPE_ERROR = 'N';
        		vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].PAYOR_TYPE =  payor_type;
        	};
        	console.log("vm.entityData:::::::", vm.entityData);
        };
        
        vm.updatingPayor = function(entity_index, sys_acct_index, acct_index, sub_index){
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].HAS_ERROR = 'N';
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'N';
        };
        
        vm.updatedDetails = function(entity_index, sys_acct_index, acct_index, sub_index){
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        };
	 
	    
		function setSelectedParams() {
		
			console.log("-----In setSelectedParams fn----");			
			
			for (var i=0; i < vm.userSelectedData.length; i++){
				vm.le_key_list.push(vm.userSelectedData[i].data.COMBINATION_KEY);			
        	}	
        	
        	vm.le_keys = vm.le_key_list.toString(); 
        	
        	console.log("userSelectedData:::::", vm.userSelectedData);        	
        	console.log("selected Records::::::", vm.le_key_list);        	
		}
		
		function getPayorAccountData(){		   
        	m3PayorAllocateFactory.getPayorAccountDetails(vm.le_keys, vm.payment_type, vm.classification_type, vm.trans_type, vm.logged_in_user).then(function (data){
        		
        		console.log("::::::getPayorAccountData()::::");
        		console.log("Payor Account Details:    ::::", data);
        		console.log("Payor data.jsonObject:    ::::", data.jsonObject);
        		
        		$scope.loadingAccounts = false;
        		vm.payorAccountData = data.jsonObject;
				vm.isThirdPartyEnabled = data.jsonObject ? data.jsonObject[0].IS_THIRDPARTY_PAYOR_ENABLED : 'N';

        		setPayorModalData();
        	});   
        }
		
		vm.openSubDetails = function(mainIndex, sysacctIndex, flag){
			console.log("---------mainIndex in open details is---------", mainIndex);
			console.log("---------sysacctIndex in open details is---------", sysacctIndex);
			for(var i=0; i < vm.entityData.length; i++){
				if(vm.entityData[mainIndex].Sys_Account_data.length > 0){
					for(var j=0; j < vm.entityData[i].Sys_Account_data.length; j++){
						vm.entityData[i].Sys_Account_data[j].IS_OPEN = false;
					}
				}
			}
			if(flag == "O"){
				if(vm.entityData[mainIndex].Sys_Account_data.length > 0){
					vm.entityData[mainIndex].Sys_Account_data[sysacctIndex].IS_OPEN = true;
				}
			}
		}
        
        function setPayorModalData(){        
           
           console.log("---------setPayorModalData()---------");
           console.log(vm.payorAccountData);
        	
    		var o = vm.payorAccountData.reduce( (a,b) => {
				a[b.CC_KEY] = a[b.CC_KEY] || [];
				a[b.CC_KEY].push(b);
				return a;
			}, {});
			
			console.log("object o-------", o);
			
			var a = Object.keys(o).map(function(k){
				var m = angular.copy(o[k]);
				if(angular.equals(m[0].IS_ALLOCATION_ACCESS, "Y")){
					vm.is_allocate_access = true;
				}
				return { CC_KEY:o[k][0].CC_KEY , LEID: o[k][0].LEID, CDR_NO: o[k][0].CDR_NO, REP_PD:o[k][0].REPORTING_PERIOD, ME_CODE:o[k][0].ME_CODE					  					  
					   , TP_ME_CODE: o[k][0].TP_ME_CODE || "", TOTAL_ALLOCATED_AMT: 0, UNALLOCATED_AMT: 0, LEDGER:o[k][0].LEDGER, AMT_TYPE:o[k][0].AMT_TYPE	
					   , URP_FLAG: o[k][0].URP_FLAG, ROW_TYPE: o[k][0].ROW_TYPE, Sys_Account_data: o[k] };
			});
			
			console.log("object a-------", a);				
			vm.entityData = a;
			
			for (var i=0; i < vm.entityData.length; i++){
				
				if(vm.entityData[i].Sys_Account_data.length > 0 && vm.entityData[i].Sys_Account_data[0].TO_ACCT_KEY != null){
					var w = vm.entityData[i].Sys_Account_data.reduce( (a,b) => {
						a[b.TO_ACCT_KEY] = a[b.TO_ACCT_KEY] || [];
						a[b.TO_ACCT_KEY].push(b);
						return a;
					}, {});
					
					console.log("object w to test json data at system acct level -------", w);
					
					var b = Object.keys(w).map(function(k){
						var p = angular.copy(w[k]);
						return { CC_KEY:w[k][0].CC_KEY , LEID: w[k][0].LEID, CDR_NO: w[k][0].CDR_NO, REP_PD:w[k][0].REPORTING_PERIOD, ME_CODE:w[k][0].ME_CODE					  					  
							   , TP_ME_CODE:w[k][0].TP_ME_CODE || "", LEDGER:w[k][0].LEDGER, AMT_TYPE:w[k][0].AMT_TYPE, LINE:w[k][0].LINE, IS_OPEN: false
							   , TO_ACCT_KEY:w[k][0].TO_ACCT_KEY, TO_ACCT:w[k][0].TO_ACCT, TO_ACCT_DESC:w[k][0].TO_ACCT_DESC, IS_VALIDATE:w[k][0].IS_VALIDATE
							   , SYS_BOOK_AMT:w[k][0].SYS_BOOK_AMT, SYS_TAX_ADJ:w[k][0].SYS_TAX_ADJ, SYS_TOTAL_AMT:w[k][0].SYS_TOTAL_AMT, IS_SYS_TOTAL_ALLOCATED:w[k][0].IS_SYS_TOTAL_ALLOCATED
							   , ROW_TYPE:w[k][0].ROW_TYPE, Account_data:w[k] };
					});
					
					console.log("At this point, value of b after looping System acct is -------::::::", b);
					
					vm.entityData[i].Sys_Account_data = angular.copy(b);
				}
				
				for (var j=0; j < vm.entityData[i].Sys_Account_data.length; j++){
					if(vm.entityData[i].Sys_Account_data[j].Account_data.length > 0 && vm.entityData[i].Sys_Account_data[j].Account_data[0].FROM_ACCT_KEY != null){
						var z = vm.entityData[i].Sys_Account_data[j].Account_data.reduce( (a,b) => {
							a[b.REDUCE_TYPE] = a[b.REDUCE_TYPE] || [];
							a[b.REDUCE_TYPE].push(b);
							return a;
						}, {});
						
						console.log("object z to test json data-------", z);
						
						var y = Object.keys(z).map(function(k){
							var c = angular.copy(z[k]);
							c[0].ROW_TYPE = "HEADER_ROW";
							if(angular.equals(c[0].AMT_TYPE, "BOOK_AMT")){
								z[k].unshift(c[0]);
							}
							return {ROW_TYPE: z[k][0].ROW_TYPE, LEID: z[k][0].LEID, CDR_NO: z[k][0].CDR_NO, REP_PD:z[k][0].REPORTING_PERIOD, ME_CODE:z[k][0].ME_CODE
								  , FROM_ACCT: z[k][0].FROM_ACCT, FROM_ACCT_DESC: z[k][0].FROM_ACCT_DESC, FROM_ACCT_KEY: z[k][0].FROM_ACCT_KEY, AMT_TYPE: z[k][0].AMT_TYPE 	
								  , SCHDM_TEMPLATE_KEY: z[k][0].SCHDM_TEMPLATE_KEY, ADJ_ORIGIN_SOURCE: z[k][0].ADJ_ORIGIN_SOURCE, AMT_TYPE:z[k][0].AMT_TYPE						
								  , BOOK_AMT: z[k][0].BOOK_AMT, TOTAL_ALLOCATED_AMT: z[k][0].TOTAL_ALLOCATED_AMT, UNALLOCATED_AMT: z[k][0].UNALLOCATED_AMT
								  , tp_dropdown_data: [], tpObject: {"TP_LEID":"", "TP_CDR_NO": "", "TP_REP_PD": 0, "TP_ME_CODE":"", "TP_ENTITY":""}
								  , tp_me_dropdown_data: [], TP_ME_CODE: z[k][0].TP_ME_CODE
								  , sub_acct_data: z[k]
								  , DELETE_ROWS: [] };
						});
						
						console.log("Account index is -------::::::", i);
						console.log("At this point, value of y is -------::::::", y);
						
						vm.entityData[i].Sys_Account_data[j].Account_data = angular.copy(y);
					}
				}
				
			}
			
			console.log("entityData -------::::::", vm.entityData);
			
			//console.log(":::set existing payor info to dropdown model:::");
			
			vm.entityOriginalData = angular.copy(vm.entityData);
			console.log("vm.entityData:::::::", vm.entityData);
			
        }
        
        
     vm.addNewPayor = function(entity_index, sys_acct_index, acct_index, sub_index){           
		
			var new_row = angular.copy(vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index]);	
			
			console.log("::addNewPayor:::");
			console.log("::new_row:::", new_row);
			console.log("::entityData:::", vm.entityData[entity_index]);
			
			//remove current LEID|CDR|REP from TP dropdown list							
			var cur_tp_options = vm.tp_pool_list.reduce( (a,b) => {
				if(b.TP_LEID != new_row.LEID || b.TP_CDR_NO != new_row.CDR_NO  || b.TP_REP_PD != new_row.REPORTING_PERIOD){
					a.push(b);
				}
				return a;
			}, []);								
			
			//add existing TP to list, if any.
		/*	if(vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID != null &&
				vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID != undefined){
				cur_tp_options.push({"TP_LEID": vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID,
					  "TP_CDR_NO":  vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO,
					  "TP_REP_PD":  vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD,
					  "TP_ME_CODE": vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE,
					  "TP_ENTITY":  vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID + "-" 
					  			  + vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO + "-" 
					  			  + vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD});
			} */
			
			//set new row attribs & push to sub_acct array
			new_row.ROW_TYPE = "NEW";	
			new_row.IS_CHANGED = 'Y';			
			new_row.ALLOCATED_AMT = 0;
			new_row.tp_dropdown_data = cur_tp_options;	
			new_row.TP_DETAILS = "";
			new_row.TP_LEID = "";
			new_row.TP_CDR_NO = "";
			new_row.TP_REP_PD = "";
			new_row.TP_ME_CODE = "";
			new_row.TP_ENTITY = "";
			
			vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data.push(new_row);
			
			/* if(angular.equals(vm.payment_type, "M3PAYOR")){
				getM3PayorList();
			} */
		} 
		
		vm.removePayor = function (entity_index, sys_acct_index, acct_index, sub_index){
			console.log("index::", entity_index);
			console.log("index::", acct_index);
			console.log("index::", sub_index);
			console.log("index::", vm.entityData);
			if(vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].ROW_TYPE == 'EX'){
				vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'D';
				vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].DELETE_ROWS.push(vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index]);
				vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data.splice(sub_index,1);
			}else if (vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[sub_index].ROW_TYPE == 'NEW'){
				vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data.splice(sub_index,1);
			}
			vm.updateAmount(entity_index, sys_acct_index, acct_index, sub_index, 'R');
        };
        
        vm.updateAmount = function (entity_index, sys_acct_index, acct_index, sub_index, flag){
        	console.log("index::", vm.entityData); 
        	var allocated_amt = 0
        	for (var k=1; k < vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data.length; k++){
        		if(null != vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[k].TP_LEID && undefined != vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[k].TP_LEID){
        			allocated_amt = allocated_amt + vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[k].ALLOCATED_AMT;
        		}
        	}
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[0].TOTAL_ALLOCATED_AMT = allocated_amt;
        	vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[0].UNALLOCATED_AMT = vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[0].BOOK_AMT - vm.entityData[entity_index].Sys_Account_data[sys_acct_index].Account_data[acct_index].sub_acct_data[0].TOTAL_ALLOCATED_AMT;
        	
        }
		
		
		function getPayorDetails(){
			 $scope.loadingAccounts = true;
			 setSelectedParams();
			 getPayorAccountData();	
		}
	    
	    if(vm.userSelectedData.length != 0){    
			getPayorDetails();
		}

    }

    return controllers;


});
