define([
    'angular',
    'vsRepeat',
    './formReviewDashboardCtrl',
    './formReviewServices',
], function () {
    'use strict';
    return angular.module('app.Form-Review', ['app.formReviewDashboardCtrl', 'app.formReviewServices', 'vs-repeat'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
            $stateProvider
                .state('form-review', {
                    url: '/form-review',
                    templateUrl: 'app/components/Form-Review/form-review-dashboard.html',
                    data:{},
                    access:""
                })
        }])
});
