define([
    'angular',
    './allocBatchRerunController',
    './allocBatchRerunService'



], function () {
    'use strict';
    return angular.module('app.allocBatchRerun', ['app.allocBatchRerunController','app.allocBatchRerunService'] );

});