define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.PartnerMaintenanceControllers',[])
        .controller('PartnerMaintenanceController', ['GlobalService','$rootScope','$scope','$state','$log','GridFactory','JsonObjectFactory','$filter','AlertService','$timeout','$uibModal','GENERAL_CONFIG','USER_SETTINGS', 'workspaceFactory','PartnerMaintenanceServicesFactory','$uibModalInstance','rowData',PartnerMaintenanceController]);

    function PartnerMaintenanceController(GlobalService,$rootScope,$scope,$state,$log,GridFactory,JsonObjectFactory,$filter,AlertService,$timeout,$uibModal,GENERAL_CONFIG,USER_SETTINGS,workspaceFactory,PartnerMaintenanceServicesFactory,$uibModalInstance,rowData) {

        var vm = this;

        $scope.lazyLoadObj = {};

        vm.isSaveClicked = false;

        vm.add = 'n';

        vm.selectedOption;
        vm.showMessage = false;
        vm.tooltipMsg = "Add New Partner";

        vm.loading = true;
        vm.ptrLoading = false;

        vm.HAS_ERROR = false;
        vm.ERROR_MESSAGE = null;

        vm.emptyPartnerLabel = '';

        vm.partnerList = [];
        vm.partnerListOrig = [];
        vm.partnerListToAdd = [];

        vm.ListOfRowsToSave = [];
        vm.ListOfRowsToAdd = [];
        vm.ListOfRowsToEdit = [];
        vm.ListOfRowsToDelete = [];

        vm.eligiblePartnerList = [];

        vm.eligiblePartnerList1 = [];

        vm.missingPartnersList = [];
        vm.externalEntitiesList = [];
        vm.goldEntitiesList = [];

        vm.genericErrMsg = 'Error!';

        vm.ptrCanBeRemoved;

        vm.userType;

        //vm.partner = {};
        vm.totalEffectiveOwnershipPercentage = 0;
        vm.totalEffectiveOwnershipPercentageOrig = 0;
        vm.totalEffectiveOwnershipPercentageToAdd = 0;
        vm.totalEffectiveOwnershipPercentageToShow = 0;
        vm.totalEffectiveOwnershipPercentageToShowOrig = 0;

        vm.selMissingPtr = {};
        vm.selExtEntity = {};
        vm.selGoldEntity = {};

        vm.ptrAttributes = [];

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.partnerCount = 0;

        console.log('rowData=',rowData);

        vm.PSHIP_LEID=rowData.LEID;
        vm.PSHIP_NAME=rowData.LE_NAME;
        vm.PSHIP_REPORTING_PERIOD=rowData.REPORTING_PERIOD;
        vm.PSHIP_US_TAX_TYPE=rowData.TAX_TYPE;
        vm.PSHIP_BEGIN_DATE=rowData.TAX_YEAR_BEGIN;
        vm.PSHIP_END_DATE=rowData.TAX_YEAR_END;
        vm.INT_EXT = (rowData.RETURN_PREPARED_BY == 'Internal') ? '1': '0';

        console.log('vm.INT_EXT=',vm.INT_EXT);

        vm.PARTNER_LEID;
        vm.PARTNER_REPORTING_PERIOD;
        vm.PARTNER_NAME;
        vm.PARTNER_US_TAX_TYPE;
        vm.EFFECTIVE_OWNERSHIP_PERCENTAGE;
        vm.EFFECTIVE_DATE_BEGIN;
        vm.EFFECTIVE_DATE_END;
        vm.IS_PTR_INACTIVE;
        vm.PTR_K1_INITIAL;
        vm.PTR_K1_FINAL;


        vm.filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.userSettings = USER_SETTINGS;
        console.log('vm.userSettings =', vm.userSettings);

        console.log('GENERAL_CONFIG= ', GENERAL_CONFIG);

        vm.tax_year = vm.filterParams.tax_year;
        vm.scenario = vm.filterParams.scenario;
        vm.jcd_key = GlobalService.globalParams.jcd_key;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.userType = vm.userSettings.user.client.user_type_key;

        vm.scenarioLabel = null;
       /* _.filter(workspaceFactory.activeScreen.filters.filters,function(item){return item.selected}).forEach(function (value) {
            if(value.label=='Scenario (PCS)') {
                vm.scenarioLabel = value.selected.LABEL;
            }
        });*/
        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = 'Partnership/Partner Setup Details ( Tax Year: ' + vm.tax_year + ' - Scenario: ' +  vm.scenarioLabel + ' )';

        var params = {"action_code":'80ghdk',"tax_year": vm.tax_year,"scenario": vm.scenario,"jcd_key": vm.jcd_key,"leid": vm.PSHIP_LEID,"rp": vm.PSHIP_REPORTING_PERIOD};

        var params1 = {"action_code":'kenbvi',"tax_year": vm.tax_year,"scenario": vm.scenario,"jcd_key": vm.jcd_key,"leid": vm.PSHIP_LEID,"rp": vm.PSHIP_REPORTING_PERIOD};

        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
            vm.partnerList = data.jsonObject;

            vm.partnerCount = vm.partnerList.length;

            for(var i=0; i < vm.partnerList.length; i++) {

                vm.ptrCanBeRemoved = (vm.partnerList[i].CAN_DELETE_PTR == 1);

                vm.partnerList[i]['view']=true;
                vm.partnerList[i]['edit']=false;
                vm.partnerList[i]['delete']= vm.ptrCanBeRemoved;
                vm.partnerList[i]['is_dirty']=false;
                vm.partnerList[i]['ptr_sel_err_msg'] = null;
                vm.partnerList[i]['eff_own_err_msg'] = null;
                vm.partnerList[i]['beg_date_err_msg'] = null;
                vm.partnerList[i]['end_date_err_msg'] = null;
                vm.partnerList[i]['has_ptr_sel_err'] = false;
                vm.partnerList[i]['has_ownership_err'] = false;
                vm.partnerList[i]['has_beg_date_err'] = false;
                vm.partnerList[i]['has_end_date_err'] = false;
                vm.partnerList[i]['calendar'] = {opened: false};
                vm.partnerList[i]['calendar1'] = {opened: false};

                vm.totalEffectiveOwnershipPercentage = vm.totalEffectiveOwnershipPercentage + vm.partnerList[i].EFFECTIVE_OWNERSHIP_PERCENTAGE;

                vm.totalEffectiveOwnershipPercentageOrig = vm.totalEffectiveOwnershipPercentageOrig + vm.partnerList[i].EFFECTIVE_OWNERSHIP_PERCENTAGE;
            }

            vm.partnerListOrig = angular.copy(vm.partnerList);

            vm.totalEffectiveOwnershipPercentageToShow = vm.totalEffectiveOwnershipPercentage;
            vm.totalEffectiveOwnershipPercentageToShowOrig = vm.totalEffectiveOwnershipPercentageToShow;

            vm.emptyPartnerLabel = 'No partner(s) are setup.';

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params1).then(function (data) {
                vm.eligiblePartnerList = data.jsonObject;

                vm.missingPartnersList = $filter("filter")(vm.eligiblePartnerList, {PARTNER_SECTION:"missing_partners"});
                //console.log('vm.missingPartnersList=', vm.missingPartnersList);
                vm.externalEntitiesList = $filter("filter")(vm.eligiblePartnerList, {PARTNER_SECTION:"external_entities"});
                //console.log('vm.externalEntitiesList=', vm.externalEntitiesList);
                vm.goldEntitiesList = $filter("filter")(vm.eligiblePartnerList, {PARTNER_SECTION:"gold_entities"});
                //console.log('vm.goldEntitiesList=', vm.goldEntitiesList);

                vm.selMissingPtr['PTR_LEID_RP'] = '0';
                vm.selMissingPtr['PTR_LE'] = '------ Choose Missing Partner ------';

                vm.selExtEntity['PTR_LEID_RP'] = '1';
                vm.selExtEntity['PTR_LE'] = '------ Choose External Entity ------';

                vm.selGoldEntity['PTR_LEID_RP'] = '2';
                vm.selGoldEntity['PTR_LE'] = '------ Choose GOLD Entity ------';

                if(vm.missingPartnersList.length > 0) {
                    vm.eligiblePartnerList1.push(vm.selMissingPtr);
                    for(var i=0; i< vm.missingPartnersList.length; i++) {
                        vm.eligiblePartnerList1.push(vm.missingPartnersList[i]);
                    }
                }

                if(vm.externalEntitiesList.length > 0) {
                    vm.eligiblePartnerList1.push(vm.selExtEntity);
                    for(var i=0; i< vm.externalEntitiesList.length; i++) {
                        vm.eligiblePartnerList1.push(vm.externalEntitiesList[i]);
                    }
                }

                if(vm.goldEntitiesList.length > 0) {
                    vm.eligiblePartnerList1.push(vm.selGoldEntity);
                    for(var i=0; i< vm.goldEntitiesList.length; i++) {
                        vm.eligiblePartnerList1.push(vm.goldEntitiesList[i]);
                    }
                }

                $scope.local_acc_dropdown_data = vm.eligiblePartnerList1;
                $scope.lazyLoadObj.totalDrpList = $scope.local_acc_dropdown_data;
                $scope.lazyLoadObj.cachedDrpList = $scope.local_acc_dropdown_data.slice(0, 10);

                //console.log('vm.eligiblePartnerList1=',vm.eligiblePartnerList1);

                //vm.selectedOption = vm.eligiblePartnerList1[0]['PTR_LE'];

                console.log('vm.selectedOption=',vm.selectedOption);
                vm.loading = false;

            });

        });

        vm.showYesNo = function(value) {
            if(value =="1") return "Yes";
            if(value =="0") return "No";
        }

        vm.openCalendar = function(index) {
            var row = {};
            row = vm.partnerListToAdd[index];
            row['calendar'].opened = true;
        };

        vm.openCalendar1 = function(index) {
            var row = {};
            row = vm.partnerListToAdd[index];
            row['calendar1'].opened = true;
        };

        vm.openCalendarE = function(index) {
            var row = {};
            row = vm.partnerList[index];
            row['calendar'].opened = true;
        };

        vm.openCalendar1E = function(index) {
            var row = {};
            row = vm.partnerList[index];
            row['calendar1'].opened = true;
        };


        vm.loadPartnerAttributes = function(arg1, index) {

            console.log('index=',index);
            var ptr_leid_rp;
            var ptr_leid;
            var ptr_rp;
            var ptr_catg;

            //var object_id = 'eligiblePtr_'+index_id;

            var row = {};

            row = vm.partnerListToAdd[index];

            console.log(vm.selectedOption);

            ptr_leid_rp = arg1['PTR_LEID_RP'] ; //row['PTR_LE'];

            ptr_leid = ptr_leid_rp.substr(0,6);
            ptr_rp = ptr_leid_rp.substr(7);


            var params = {
                "action_code" : 'm2yzyy', "tax_year" : vm.filterParams.tax_year,  "scenario" : vm.filterParams.scenario, "jcd_key" : vm.jcd_key, "leid": vm.PSHIP_LEID,"rp": vm.PSHIP_REPORTING_PERIOD, "ptr_leid":ptr_leid, "ptr_rp":ptr_rp
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(data) {
                    vm.ptrAttributes = data.jsonObject;
                    console.log('vm.ptrAttributes=',vm.ptrAttributes);

                    row['PARTNER_LEID'] = vm.ptrAttributes[0].PTR_LEID;
                    row['PARTNER_REPORTING_PERIOD'] = vm.ptrAttributes[0].PTR_RP;
                    row['PTR_LE'] = vm.ptrAttributes[0].PTR_LEID+'/'+ vm.ptrAttributes[0].PTR_RP+'/'+vm.ptrAttributes[0].LE_NAME;
                    row['PARTNER_NAME']  = vm.ptrAttributes[0].LE_NAME;
                    row['PARTNER_US_TAX_TYPE'] = vm.ptrAttributes[0].TAX_TYPE;

                    if(vm.ptrAttributes[0].EFFECTIVE_OWNERSHIP != null)
                        row['EFFECTIVE_OWNERSHIP_PERCENTAGE'] = roundToSix(vm.ptrAttributes[0].EFFECTIVE_OWNERSHIP) ;
                    else
                        row['EFFECTIVE_OWNERSHIP_PERCENTAGE'] = 0;

                    row['EFFECTIVE_DATE_BEGIN'] = new Date(vm.ptrAttributes[0].PTR_TAX_YEAR_BEGIN);
                    row['EFFECTIVE_DATE_END'] = new Date(vm.ptrAttributes[0].PTR_TAX_YEAR_END);
                    row['IS_PTR_INACTIVE'] = vm.ptrAttributes[0].IS_PTR_INACTIVE; // Need to come from DB
                    row['PTR_K1_INITIAL'] = vm.ptrAttributes[0].PTR_K1_INITIAL;
                    row['PTR_K1_FINAL'] = vm.ptrAttributes[0].PTR_K1_FINAL;

                    vm.totalEffectiveOwnershipPercentageToAdd = vm.totalEffectiveOwnershipPercentageToAdd + row['EFFECTIVE_OWNERSHIP_PERCENTAGE'];
                    vm.totalEffectiveOwnershipPercentageToShow = roundToSix(vm.totalEffectiveOwnershipPercentage + vm.totalEffectiveOwnershipPercentageToAdd);
                }
            );
        }

        vm.showPartnerAddMsg = function() {
            vm.tooltipMsg="Loading partner list...Please wait";

            $timeout(function(){
                vm.tooltipMsg = "Add New Partner";
            }, 10000);
        }


        vm.addPartner = function() {

            var partner = {};
            var calendarState = {};
            var calendar1State = {};

            vm.add = 'y';
            partner['PARTNER_LEID'] = '';
            partner['PARTNER_REPORTING_PERIOD'] = '';

            if(vm.missingPartnersList.length > 0) {
                partner['PTR_LE'] = '------ Choose Missing Partner ------';
            }

            else if(vm.externalEntitiesList.length > 0) {
                partner['PTR_LE'] = '------ Choose External Entity ------';
            }

            else if(vm.goldEntitiesList.length > 0) {
                partner['PTR_LE'] = '------ Choose GOLD Entity ------';
            }


            partner['PARTNER_NAME'] = '';
            partner['PARTNER_US_TAX_TYPE'] = '';
            partner['EFFECTIVE_OWNERSHIP_PERCENTAGE'] = 0.00;

            partner['EFFECTIVE_DATE_BEGIN'] = null;
            calendarState = {opened: false};
            partner['calendar'] = calendarState;

            partner['EFFECTIVE_DATE_END'] = null;
            calendar1State = {opened: false};
            partner['calendar1'] = calendar1State;

            partner['IS_PTR_INACTIVE'] = 0;
            partner['PTR_K1_INITIAL'] = 0;
            partner['PTR_K1_FINAL'] = 0;

            partner['ptr_sel_err_msg'] = null;
            partner['eff_own_err_msg'] = null;
            partner['beg_date_err_msg'] = null;
            partner['end_date_err_msg'] = null;

            partner['has_ptr_sel_err'] = false;
            partner['has_ownership_err'] = false;
            partner['has_beg_date_err'] = false;
            partner['has_end_date_err'] = false;

            vm.partnerListToAdd.push(partner);

        }


        vm.editExistingPartner = function(index) {
            var beginDate;
            var endDate;
            vm.partnerList[index].view = ! (vm.partnerList[index].view);
            vm.partnerList[index].edit = ! (vm.partnerList[index].edit);
            //vm.partnerList[index].delete = ! (vm.partnerList[index].delete);

            if((vm.partnerList[index].CAN_DELETE_PTR == 1)) {
                vm.partnerList[index].delete = ! (vm.partnerList[index].delete);
            }
            else {
                vm.partnerList[index].delete = false;
            }

            if(vm.partnerList[index].view == false && vm.partnerList[index].edit == true) {

                beginDate  = vm.partnerList[index]['EFFECTIVE_DATE_BEGIN'];
                endDate = vm.partnerList[index]['EFFECTIVE_DATE_END'];

                vm.partnerList[index]['EFFECTIVE_DATE_BEGIN'] = new Date(beginDate);
                vm.partnerList[index]['EFFECTIVE_DATE_END'] = new Date(endDate);
            }


            if(vm.partnerList[index].view == true && vm.partnerList[index].edit == false) {

                var correctIndex = 0;
                var partner_leid = vm.partnerList[index]['PARTNER_LEID'];
                var partner_reporting_period = vm.partnerList[index]['PARTNER_REPORTING_PERIOD'];

                beginDate  = vm.partnerList[index]['EFFECTIVE_DATE_BEGIN'];
                endDate = vm.partnerList[index]['EFFECTIVE_DATE_END'];

                vm.partnerList[index]['EFFECTIVE_DATE_BEGIN'] = ((beginDate.getMonth() > 8) ? (beginDate.getMonth() + 1) : ('0' + (beginDate.getMonth() + 1))) + '/' + ((beginDate.getDate() > 9) ? beginDate.getDate() : ('0' + beginDate.getDate())) + '/' + beginDate.getFullYear();
                vm.partnerList[index]['EFFECTIVE_DATE_END'] = ((endDate.getMonth() > 8) ? (endDate.getMonth() + 1) : ('0' + (endDate.getMonth() + 1))) + '/' + ((endDate.getDate() > 9) ? endDate.getDate() : ('0' + endDate.getDate())) + '/' + endDate.getFullYear();

                for(var i=0; i< vm.partnerListOrig.length; i++) {
                    if(partner_leid+'/'+partner_reporting_period == vm.partnerListOrig[i]['PARTNER_LEID']+'/'+vm.partnerListOrig[i]['PARTNER_REPORTING_PERIOD']) {
                        correctIndex = i;
                        break;
                    }
                }
                vm.partnerList[index] = vm.partnerListOrig[correctIndex];
                vm.partnerList[index]['is_dirty'] = false;
                vm.adjustEffectiveOwnwerShipPercentage(vm.partnerListOrig[index]);
            }

        }

        vm.removeExistingPartner = function(index) {
            var rowToDelete = {};
            vm.totalEffectiveOwnershipPercentage = vm.totalEffectiveOwnershipPercentage - vm.partnerList[index]['EFFECTIVE_OWNERSHIP_PERCENTAGE'];
            vm.totalEffectiveOwnershipPercentageToShow = roundToSix(vm.totalEffectiveOwnershipPercentage + vm.totalEffectiveOwnershipPercentageToAdd);

            rowToDelete['mode'] = 'delete';
            rowToDelete['leid'] = vm.PSHIP_LEID;
            rowToDelete['reporting_period'] = vm.PSHIP_REPORTING_PERIOD;
            rowToDelete['ptr_leid'] = vm.partnerList[index].PARTNER_LEID;
            rowToDelete['ptr_reporting_period'] = vm.partnerList[index].PARTNER_REPORTING_PERIOD;
            vm.ListOfRowsToDelete.push(rowToDelete);
            console.log('vm.ListOfRowsToDelete=', vm.ListOfRowsToDelete);

            vm.partnerList.splice(index,1);
        }

        vm.removePartnerToAdd = function(index) {
            vm.totalEffectiveOwnershipPercentageToAdd = vm.totalEffectiveOwnershipPercentageToAdd - vm.partnerListToAdd[index]['EFFECTIVE_OWNERSHIP_PERCENTAGE'];
            vm.totalEffectiveOwnershipPercentageToShow = roundToSix(vm.totalEffectiveOwnershipPercentage + vm.totalEffectiveOwnershipPercentageToAdd);

            if(vm.partnerListToAdd[index]['ptr_sel_err_msg'] != null || vm.partnerListToAdd[index]['eff_own_err_msg'] != null || vm.partnerListToAdd[index]['beg_date_err_msg'] != null || vm.partnerListToAdd[index]['end_date_err_msg'] != null) {
                vm.HAS_ERROR = false;
                vm.ERROR_MESSAGE = null;
            }

            if (vm.partnerListToAdd[index]['ptr_sel_err_msg'] != null) {

                vm.partnerListToAdd[index]['ptr_sel_err_msg'] = null;
                vm.partnerListToAdd[index]['has_ptr_sel_err'] = false;
            }

            if (vm.partnerListToAdd[index]['eff_own_err_msg'] != null) {

                vm.partnerListToAdd[index]['eff_own_err_msg'] = null;
                vm.partnerListToAdd[index]['has_ownership_err'] = false;
            }

            else if (vm.partnerListToAdd[index]['beg_date_err_msg'] != null) {
                vm.partnerListToAdd[index]['beg_date_err_msg'] = null;
                vm.partnerListToAdd[index]['has_beg_date_err'] = false;
            }

            if (vm.partnerListToAdd[index]['end_date_err_msg'] != null) {
                vm.partnerListToAdd[index]['end_date_err_msg'] = null;
                vm.partnerListToAdd[index]['has_end_date_err'] = false;
            }

            vm.partnerListToAdd.splice(index,1);
        }

        vm.save = function() {

            vm.ListOfRowsToAdd = [];
            vm.ListOfRowsToEdit = [];
            vm.ListOfRowsToSave = [];
            var beginDate;
            var endDate;
            var message = null;
            var returnClobSettingsObj = {};
            var deleteWarningMsg ='The following Partner(s) will be removed. Do you want to Proceed?';
            var ptrListToDelete = '';
            var continueSave = true;
            //vm.isSaveClicked = false;
            var partnerRow;
            for(var i=0; i < vm.partnerListToAdd.length; i++) {

                var rowToAdd = {};
                partnerRow = vm.partnerListToAdd[i];
                rowToAdd['mode'] = 'add';
                rowToAdd['leid'] = vm.PSHIP_LEID;
                rowToAdd['reporting_period'] = vm.PSHIP_REPORTING_PERIOD;
                rowToAdd['int_ext'] = vm.INT_EXT;
                rowToAdd['ptr_leid'] = partnerRow.PARTNER_LEID;
                rowToAdd['ptr_reporting_period'] = partnerRow.PARTNER_REPORTING_PERIOD;
                rowToAdd['allocation_ratio'] = partnerRow.EFFECTIVE_OWNERSHIP_PERCENTAGE;

                beginDate = partnerRow.EFFECTIVE_DATE_BEGIN;
                endDate  = partnerRow.EFFECTIVE_DATE_END;

                if(beginDate != null)
                    rowToAdd['tax_year_begin'] = ((beginDate.getMonth() > 8) ? (beginDate.getMonth() + 1) : ('0' + (beginDate.getMonth() + 1))) + '/' + ((beginDate.getDate() > 9) ? beginDate.getDate() : ('0' + beginDate.getDate())) + '/' + beginDate.getFullYear();
                else
                    rowToAdd['tax_year_begin'] = '';

                if(endDate != null)
                    rowToAdd['tax_year_end'] = ((endDate.getMonth() > 8) ? (endDate.getMonth() + 1) : ('0' + (endDate.getMonth() + 1))) + '/' + ((endDate.getDate() > 9) ? endDate.getDate() : ('0' + endDate.getDate())) + '/' + endDate.getFullYear();
                else
                    rowToAdd['tax_year_end'] = '';


                rowToAdd['ptr_k1_initial'] = partnerRow.PTR_K1_INITIAL;
                rowToAdd['ptr_k1_final'] = partnerRow.PTR_K1_FINAL;
                rowToAdd['is_ptr_inactive'] = partnerRow.IS_PTR_INACTIVE;

                rowToAdd['ptr_sel_err_msg'] = null;
                rowToAdd['eff_own_err_msg'] = null;
                rowToAdd['beg_date_err_msg'] = null;
                rowToAdd['end_date_err_msg'] = null;

                rowToAdd['has_ptr_sel_err'] = false;
                rowToAdd['has_ownership_err'] = false;
                rowToAdd['has_beg_date_err'] = false;
                rowToAdd['has_end_date_err'] = false;

                vm.ListOfRowsToAdd.push(rowToAdd);
            }
            console.log('vm.ListOfRowsToAdd=', vm.ListOfRowsToAdd);


            for(var i=0; i < vm.partnerList.length; i++) {

                var rowToEdit = {};
                var partnerRow = vm.partnerList[i];

                if(partnerRow.view == false && partnerRow.edit == true && partnerRow.is_dirty == true) {

                    rowToEdit['mode'] = 'edit';
                    rowToEdit['leid'] = vm.PSHIP_LEID;
                    rowToEdit['reporting_period'] = vm.PSHIP_REPORTING_PERIOD;
                    rowToEdit['ptr_leid'] = partnerRow.PARTNER_LEID;
                    rowToEdit['ptr_reporting_period'] = partnerRow.PARTNER_REPORTING_PERIOD;
                    rowToEdit['allocation_ratio'] = partnerRow.EFFECTIVE_OWNERSHIP_PERCENTAGE;

                    beginDate = partnerRow.EFFECTIVE_DATE_BEGIN;
                    endDate  = partnerRow.EFFECTIVE_DATE_END;

                    rowToEdit['tax_year_begin'] = ((beginDate.getMonth() > 8) ? (beginDate.getMonth() + 1) : ('0' + (beginDate.getMonth() + 1))) + '/' + ((beginDate.getDate() > 9) ? beginDate.getDate() : ('0' + beginDate.getDate())) + '/' + beginDate.getFullYear();
                    rowToEdit['tax_year_end'] = ((endDate.getMonth() > 8) ? (endDate.getMonth() + 1) : ('0' + (endDate.getMonth() + 1))) + '/' + ((endDate.getDate() > 9) ? endDate.getDate() : ('0' + endDate.getDate())) + '/' + endDate.getFullYear();

                    rowToEdit['ptr_k1_initial'] = partnerRow.PTR_K1_INITIAL;
                    rowToEdit['ptr_k1_final'] = partnerRow.PTR_K1_FINAL;
                    rowToEdit['is_ptr_inactive'] = partnerRow.IS_PTR_INACTIVE;

                    rowToEdit['eff_own_err_msg'] = null;
                    rowToEdit['beg_date_err_msg'] = null;
                    rowToEdit['end_date_err_msg'] = null;

                    rowToEdit['has_ownership_err'] = false;
                    rowToEdit['has_beg_date_err'] = false;
                    rowToEdit['has_end_date_err'] = false;

                    vm.ListOfRowsToEdit.push(rowToEdit);
                }

            }
            console.log('vm.ListOfRowsToEdit=', vm.ListOfRowsToEdit);

            for(var i=0; i< vm.ListOfRowsToAdd.length; i++)  {
                vm.ListOfRowsToSave.push(vm.ListOfRowsToAdd[i]);
            }

            for(var i=0; i< vm.ListOfRowsToEdit.length; i++)  {
                vm.ListOfRowsToSave.push(vm.ListOfRowsToEdit[i]);
            }

            for(var i=0; i< vm.ListOfRowsToDelete.length; i++)  {
                vm.ListOfRowsToSave.push(vm.ListOfRowsToDelete[i]);
            }

            console.log('vm.ListOfRowsToSave=', vm.ListOfRowsToSave);

            if(vm.ListOfRowsToSave.length == 0) {

                for(var i=0; i < vm.partnerList.length; i++) {

                    rowToAdd = {};
                    partnerRow = vm.partnerList[i];
                    rowToAdd['mode'] = 'add';
                    rowToAdd['leid'] = vm.PSHIP_LEID;
                    rowToAdd['reporting_period'] = vm.PSHIP_REPORTING_PERIOD;
                    rowToAdd['int_ext'] = vm.INT_EXT;
                    rowToAdd['ptr_leid'] = partnerRow.PARTNER_LEID;
                    rowToAdd['ptr_reporting_period'] = partnerRow.PARTNER_REPORTING_PERIOD;
                    rowToAdd['allocation_ratio'] = partnerRow.EFFECTIVE_OWNERSHIP_PERCENTAGE;

                    beginDate = partnerRow.EFFECTIVE_DATE_BEGIN;
                    endDate  = partnerRow.EFFECTIVE_DATE_END;

                    if(beginDate != null)
                        rowToAdd['tax_year_begin'] = beginDate;
                    else
                        rowToAdd['tax_year_begin'] = '';

                    if(endDate != null)
                        rowToAdd['tax_year_end'] = endDate;
                    else
                        rowToAdd['tax_year_end'] = '';

                    rowToAdd['ptr_k1_initial'] = partnerRow.PTR_K1_INITIAL;
                    rowToAdd['ptr_k1_final'] = partnerRow.PTR_K1_FINAL;
                    rowToAdd['is_ptr_inactive'] = partnerRow.IS_PTR_INACTIVE;

                    rowToAdd['ptr_sel_err_msg'] = null;
                    rowToAdd['eff_own_err_msg'] = null;
                    rowToAdd['beg_date_err_msg'] = null;
                    rowToAdd['end_date_err_msg'] = null;

                    rowToAdd['has_ptr_sel_err'] = false;
                    rowToAdd['has_ownership_err'] = false;
                    rowToAdd['has_beg_date_err'] = false;
                    rowToAdd['has_end_date_err'] = false;

                    vm.ListOfRowsToAdd.push(rowToAdd);
                }

                for(var i=0; i< vm.ListOfRowsToAdd.length; i++)  {
                    vm.ListOfRowsToSave.push(vm.ListOfRowsToAdd[i]);
                }

            }

            if(vm.ListOfRowsToSave.length > 0) {

                vm.isSaveClicked = true;

                var validationStat = validateSetupData();

                if(validationStat) {

                    if(vm.ListOfRowsToDelete.length > 0) {

                        for(var i=0; i< vm.ListOfRowsToDelete.length; i++) {

                            ptrListToDelete = ptrListToDelete + vm.ListOfRowsToDelete[i]['ptr_leid']+'/'+vm.ListOfRowsToDelete[i]['ptr_reporting_period'] + '\n';

                        }

                        if(confirm("The following partner(s) will be removed. Do you want to Proceed? Please confirm.\n" + ptrListToDelete)){
                            //vm.processSave();
                            continueSave = true;
                        }
                        else {
                            continueSave = false;
                            vm.isSaveClicked = false;
                        }
                    }

                    if(continueSave) {

                        returnClobSettingsObj['tax_year'] = vm.tax_year;
                        returnClobSettingsObj['scenario'] = vm.scenario;
                        returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                        returnClobSettingsObj['sso_id']  = vm.logged_in_user;

                        message = 'Partner setup saved successfully.';
                        sendDetails(returnClobSettingsObj,vm.ListOfRowsToSave,message);

                    }

                }
                else {
                    vm.isSaveClicked = false;
                }

            }

        }

        vm.adjustEffectiveOwnwerShipPercentage = function(row) {
            console.log('... adjustEffectiveOwnwerShipPercentage() called ...', row['EFFECTIVE_OWNERSHIP_PERCENTAGE']);

            vm.totalEffectiveOwnershipPercentage = 0.000000;
            vm.totalEffectiveOwnershipPercentageToAdd = 0.000000;

            for(var i=0; i < vm.partnerList.length; i++) {

                if(angular.isUndefined(vm.partnerList[i].EFFECTIVE_OWNERSHIP_PERCENTAGE))
                    vm.partnerList[i].EFFECTIVE_OWNERSHIP_PERCENTAGE = 0.000000;

                vm.totalEffectiveOwnershipPercentage = vm.totalEffectiveOwnershipPercentage + vm.partnerList[i].EFFECTIVE_OWNERSHIP_PERCENTAGE;

            }

            for(var i=0; i < vm.partnerListToAdd.length; i++) {

                if(angular.isUndefined(vm.partnerListToAdd[i].EFFECTIVE_OWNERSHIP_PERCENTAGE))
                    vm.partnerListToAdd[i].EFFECTIVE_OWNERSHIP_PERCENTAGE = 0.000000;

                vm.totalEffectiveOwnershipPercentageToAdd = vm.totalEffectiveOwnershipPercentageToAdd + vm.partnerListToAdd[i].EFFECTIVE_OWNERSHIP_PERCENTAGE;

            }

            //vm.totalEffectiveOwnershipPercentageToAdd = roundToSix(vm.totalEffectiveOwnershipPercentageToAdd);

            vm.totalEffectiveOwnershipPercentageToShow = roundToSix(vm.totalEffectiveOwnershipPercentage + vm.totalEffectiveOwnershipPercentageToAdd);

        }

        function sendDetails(returnClobSettingsObj, pshipSetupSaveDtls, message) {

            PartnerMaintenanceServicesFactory.savePartnerSetup(returnClobSettingsObj,pshipSetupSaveDtls).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {
                    //vm.crudLoading = false;
                    AlertService.add("success", message, 4000);
                    var args = {
                        pship_leid: vm.PSHIP_LEID,
                        gridFilter: {
                            pship_leid: vm.PSHIP_LEID
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);
                };

            });
        }

        function validateSetupData() {

            vm.HAS_ERROR = false;
            vm.ERROR_MESSAGE = null;

            console.log('vm.partnerList=',vm.partnerList);
            console.log('vm.partnerListToAdd=',vm.partnerListToAdd);

            // add validation logic

            for(var i=0; i< vm.partnerList.length; i++) {

                if(vm.partnerList[i]['is_dirty']) {

                    vm.partnerList[i]['eff_own_err_msg'] = null;
                    vm.partnerList[i]['has_ownership_err'] = false;
                    vm.partnerList[i]['beg_date_err_msg'] = null;
                    vm.partnerList[i]['has_beg_date_err'] = false;
                    vm.partnerList[i]['end_date_err_msg'] = null;
                    vm.partnerList[i]['has_end_date_err'] = false;

                    if(vm.partnerList[i]['EFFECTIVE_OWNERSHIP_PERCENTAGE'] > 100) {
                        vm.partnerList[i]['eff_own_err_msg'] = 'Ownership must not be more than 100';
                        vm.partnerList[i]['has_ownership_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerList[i]['eff_own_err_msg'];
                        vm.HAS_ERROR = true;
                        break;
                    }
                    else if(vm.partnerList[i]['EFFECTIVE_DATE_BEGIN'] > vm.partnerList[i]['EFFECTIVE_DATE_END']) {
                        vm.partnerList[i]['beg_date_err_msg'] = 'Partner Begin date must not more be than Partner End Date';
                        vm.partnerList[i]['has_beg_date_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerList[i]['beg_date_err_msg'];
                        vm.HAS_ERROR = true;
                        break;
                    }
                    else if(vm.partnerList[i]['EFFECTIVE_DATE_BEGIN'] < new Date(vm.PSHIP_BEGIN_DATE)) {
                        vm.partnerList[i]['beg_date_err_msg'] = 'Partner Begin date must not be less than Partnership Begin Date';
                        vm.partnerList[i]['has_beg_date_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerList[i]['beg_date_err_msg'];
                        vm.HAS_ERROR = true;
                        break;
                    }
                    else if(vm.partnerList[i]['EFFECTIVE_DATE_END'] > new Date(vm.PSHIP_END_DATE)) {
                        vm.partnerList[i]['end_date_err_msg'] = 'Partner End date must not be more than Partnership End Date';
                        vm.partnerList[i]['has_end_date_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerList[i]['end_date_err_msg'];
                        vm.HAS_ERROR = true;
                        break;
                    }
                }
            }

            if(!vm.HAS_ERROR) {

                for(var i=0; i< vm.partnerListToAdd.length; i++) {

                    vm.partnerListToAdd[i]['ptr_sel_err_msg'] = null;
                    vm.partnerListToAdd[i]['has_ptr_sel_err'] = false;
                    vm.partnerListToAdd[i]['eff_own_err_msg'] = null;
                    vm.partnerListToAdd[i]['has_ownership_err'] = false;
                    vm.partnerListToAdd[i]['beg_date_err_msg'] = null;
                    vm.partnerListToAdd[i]['has_beg_date_err'] = false;
                    vm.partnerListToAdd[i]['end_date_err_msg'] = null;
                    vm.partnerListToAdd[i]['has_end_date_err'] = false;

                    if (vm.partnerListToAdd[i]['PTR_LE'] == '------ Choose Missing Partner ------' || vm.partnerListToAdd[i]['PTR_LE'] == '------ Choose External Entity ------' || vm.partnerListToAdd[i]['PTR_LE'] == '------ Choose GOLD Entity ------') {

                        vm.partnerListToAdd[i]['ptr_sel_err_msg'] = 'Please select partner from the list';
                        vm.partnerListToAdd[i]['has_ptr_sel_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['ptr_sel_err_msg']
                        vm.HAS_ERROR = true;
                        break;
                    }
                    else {
                        if(isDuplicatePartner(vm.partnerListToAdd[i]['PTR_LE'])) {
                            vm.partnerListToAdd[i]['ptr_sel_err_msg'] = 'Please select same partner only once';
                            vm.partnerListToAdd[i]['has_ptr_sel_err'] = true;
                            vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['ptr_sel_err_msg']
                            vm.HAS_ERROR = true;
                            break;
                        }
                    }
                    if(vm.partnerListToAdd[i]['EFFECTIVE_OWNERSHIP_PERCENTAGE'] > 100) {
                        vm.partnerListToAdd[i]['eff_own_err_msg'] = 'Ownership must not be more than 100';
                        vm.partnerListToAdd[i]['has_ownership_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['eff_own_err_msg'];
                        vm.HAS_ERROR = true;
                        break;
                    }
                    else if(vm.partnerListToAdd[i]['EFFECTIVE_DATE_BEGIN'] > vm.partnerListToAdd[i]['EFFECTIVE_DATE_END']) {
                        vm.partnerListToAdd[i]['beg_date_err_msg'] = 'Partner Begin date must not be more than Partner End Date';
                        vm.partnerListToAdd[i]['has_beg_date_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['beg_date_err_msg'];
                        vm.HAS_ERROR = true;
                        break;

                    }
                    else if(vm.partnerListToAdd[i]['EFFECTIVE_DATE_BEGIN'] < new Date(vm.PSHIP_BEGIN_DATE)) {
                        vm.partnerListToAdd[i]['beg_date_err_msg'] = 'Partner Begin date must not be less than Partnership Begin Date';
                        vm.partnerListToAdd[i]['has_beg_date_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['beg_date_err_msg'];
                        vm.HAS_ERROR = true;
                        break;
                    }
                    else if(vm.partnerListToAdd[i]['EFFECTIVE_DATE_END'] > new Date(vm.PSHIP_END_DATE)) {
                        vm.partnerListToAdd[i]['end_date_err_msg'] = 'Partner End date must not be more than Partnership End Date';
                        vm.partnerListToAdd[i]['has_end_date_err'] = true;
                        vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['end_date_err_msg'];
                        vm.HAS_ERROR = true;
                        break;
                    }

                }
            }

            /*for(var i=0; i < listOfRowsToSave.length; i++) {
               if(listOfRowsToSave[i]['mode'] == 'add' || listOfRowsToSave[i]['mode'] == 'edit') {

                   if(listOfRowsToSave[i]['mode'] == 'add') {

                       vm.partnerListToAdd[i]['ptr_sel_err_msg'] = null;
                       vm.partnerListToAdd[i]['has_ptr_sel_err'] = false;
                       vm.partnerListToAdd[i]['eff_own_err_msg'] = null;
                       vm.partnerListToAdd[i]['has_ownership_err'] = false;
                       vm.partnerListToAdd[i]['beg_date_err_msg'] = null;
                       vm.partnerListToAdd[i]['has_beg_date_err'] = false;
                       vm.partnerListToAdd[i]['end_date_err_msg'] = null;
                       vm.partnerListToAdd[i]['has_end_date_err'] = false;
                   }

                   else if(listOfRowsToSave[i]['mode'] == 'edit') {
                       vm.partnerList[i]['eff_own_err_msg'] = null;
                       vm.partnerList[i]['has_ownership_err'] = false;
                       vm.partnerList[i]['beg_date_err_msg'] = null;
                       vm.partnerList[i]['has_beg_date_err'] = false;
                       vm.partnerList[i]['end_date_err_msg'] = null;
                       vm.partnerList[i]['has_end_date_err'] = false;
                   }

                   if(listOfRowsToSave[i]['mode'] == 'add') {
                       if (vm.partnerListToAdd[i]['PTR_LE'] == '------ Choose Missing Partner ------' || vm.partnerListToAdd[i]['PTR_LE'] == '------ Choose External Entity ------' || vm.partnerListToAdd[i]['PTR_LE'] == '------ Choose GOLD Entity ------') {

                           vm.partnerListToAdd[i]['ptr_sel_err_msg'] = 'Please select partner from the list';
                           vm.partnerListToAdd[i]['has_ptr_sel_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['ptr_sel_err_msg']
                           vm.HAS_ERROR = true;
                           break;
                       }
                       else {
                           if(isDuplicatePartner(vm.partnerListToAdd[i]['PTR_LE'])) {
                               vm.partnerListToAdd[i]['ptr_sel_err_msg'] = 'Please select same partner only once';
                               vm.partnerListToAdd[i]['has_ptr_sel_err'] = true;
                               vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['ptr_sel_err_msg']
                               vm.HAS_ERROR = true;
                               break;
                           }
                       }
                   }

                   if(listOfRowsToSave[i]['allocation_ratio'] > 100) {

                       if(listOfRowsToSave[i]['mode'] == 'add') {
                           vm.partnerListToAdd[i]['eff_own_err_msg'] = 'Ownership must not be more than 100';
                           vm.partnerListToAdd[i]['has_ownership_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['eff_own_err_msg']
                       }
                       else if(listOfRowsToSave[i]['mode'] == 'edit') {
                           vm.partnerList[i]['eff_own_err_msg'] = 'Ownership must not be more than 100';
                           vm.partnerList[i]['has_ownership_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerList[i]['eff_own_err_msg'];
                       }

                       vm.HAS_ERROR = true;
                       break;
                   }
                   else if(new Date(listOfRowsToSave[i]['tax_year_begin']) > new Date(listOfRowsToSave[i]['tax_year_end'])) {
                       if(listOfRowsToSave[i]['mode'] == 'add') {
                           vm.partnerListToAdd[i]['beg_date_err_msg'] = 'Partner Begin date must not be more than Partner End Date';
                           vm.partnerListToAdd[i]['has_beg_date_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['beg_date_err_msg'];
                       }
                       else if(listOfRowsToSave[i]['mode'] == 'edit') {
                           vm.partnerList[i]['beg_date_err_msg'] = 'Partner Begin date must not more be than Partner End Date';
                           vm.partnerList[i]['has_beg_date_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerList[i]['beg_date_err_msg'];
                       }

                       vm.HAS_ERROR = true;
                       break;
                   }
                   else if(new Date(listOfRowsToSave[i]['tax_year_begin']) < new Date(vm.PSHIP_BEGIN_DATE)) {

                       if(listOfRowsToSave[i]['mode'] == 'add') {
                           vm.partnerListToAdd[i]['beg_date_err_msg'] = 'Partner Begin date must not be less than Partnership Begin Date';
                           vm.partnerListToAdd[i]['has_beg_date_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['beg_date_err_msg'];
                       }
                       else if(listOfRowsToSave[i]['mode'] == 'edit') {
                           vm.partnerList[i]['beg_date_err_msg'] = 'Partner Begin date must not be less than Partnership Begin Date';
                           vm.partnerList[i]['has_beg_date_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerList[i]['beg_date_err_msg'];
                       }

                       vm.HAS_ERROR = true;
                       break;

                   }
                   else if(new Date(listOfRowsToSave[i]['tax_year_end']) > new Date(vm.PSHIP_END_DATE)) {

                       if(listOfRowsToSave[i]['mode'] == 'add') {
                           vm.partnerListToAdd[i]['end_date_err_msg'] = 'Partner End date must not be more than Partnership End Date';
                           vm.partnerListToAdd[i]['has_end_date_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerListToAdd[i]['end_date_err_msg'];
                       }
                       else if(listOfRowsToSave[i]['mode'] == 'edit') {
                           vm.partnerList[i]['end_date_err_msg'] = 'Partner End date must not be more than Partnership End Date';
                           vm.partnerList[i]['has_end_date_err'] = true;
                           vm.ERROR_MESSAGE = vm.partnerList[i]['end_date_err_msg'];
                       }

                       vm.HAS_ERROR = true;
                       break;

                   }
               }
            }*/

            return !vm.HAS_ERROR;
        }

        function isDuplicatePartner(ptrLE) {
            var ptrSel = 0;
            for(var i=0; i<vm.partnerListToAdd.length;i++) {
                if(ptrLE == vm.partnerListToAdd[i]['PTR_LE'])
                    ptrSel = ptrSel + 1;
            }
            if(ptrSel > 1)
                return true;
            else
                return false;
        }

        vm.mark_dirty = function(index) {
            vm.partnerList[index]['is_dirty']=true;
            console.log('Row '+ index + ' is_dirty = ' + vm.partnerList[index]['is_dirty']);
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

        function isNumber(number) {
            return angular.isNumber(number) && !isNaN(number);
        }


        function roundToSix(num) {
            return +(Math.round(num + "e+6")  + "e-6");
        }

        vm.reset = function() {

            console.log('reset called');
            console.log('vm.partnerList.length = ', vm.partnerList.length);
            console.log('vm.partnerListOrig.length = ', vm.partnerListOrig.length);

            vm.partnerList = angular.copy(vm.partnerListOrig);
            vm.totalEffectiveOwnershipPercentageToShow = vm.totalEffectiveOwnershipPercentageOrig;
            vm.partnerListToAdd = [];

            vm.ListOfRowsToSave = [];
            vm.ListOfRowsToAdd = [];
            vm.ListOfRowsToEdit = [];
            vm.ListOfRowsToDelete = [];

            /*for(var i=0; i< vm.partnerList.length; i++) {

                if(vm.partnerList[i].view == false && vm.partnerList[i].edit == true) {

                    vm.partnerList[i].view = true;
                    vm.partnerList[i].edit = false;
                    vm.partnerList[i].delete = true;

                    //var beginDate = vm.partnerList[i]['EFFECTIVE_DATE_BEGIN'];
                    //var endDate = vm.partnerList[i]['EFFECTIVE_DATE_END'];


                    if(vm.partnerList[i].view == true && vm.partnerList[i].edit == false) {
                        //vm.partnerList[i]['EFFECTIVE_DATE_BEGIN'] = ((beginDate.getMonth() > 8) ? (beginDate.getMonth() + 1) : ('0' + (beginDate.getMonth() + 1))) + '/' + ((beginDate.getDate() > 9) ? beginDate.getDate() : ('0' + beginDate.getDate())) + '/' + beginDate.getFullYear();
                        //vm.partnerList[i]['EFFECTIVE_DATE_END'] = ((endDate.getMonth() > 8) ? (endDate.getMonth() + 1) : ('0' + (endDate.getMonth() + 1))) + '/' + ((endDate.getDate() > 9) ? endDate.getDate() : ('0' + endDate.getDate())) + '/' + endDate.getFullYear();

                        vm.partnerList[i] = vm.partnerListOrig[i];

                        vm.partnerList[i]['is_dirty'] = false;

                        vm.adjustEffectiveOwnwerShipPercentage(vm.partnerListOrig[i]);
                    }
                }

            }*/

            vm.HAS_ERROR = false;
            vm.ERROR_MESSAGE = null;

        }

    }

});