const { element } = require("angular");

define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.gridController', [])

        .controller('GirdStandardController', ['USER_SETTINGS','$location', '$document', '$rootScope', '$scope', '$state', '$uibModal', '$log', 'workspaceFactory', 'GridFactory', 'ModalFactory', 'AlertService', '$timeout', 'JsonObjectFactory', 'DataFiltersFactory', 'GridSaveFactory', 'MessageFactory', 'GENERAL_CONFIG', 'SERVER_CONFIG', 'GlobalService','GridPivotFactory', girdStandardController])
        .controller('attachmentRemove', ['AlertService','rowData','gridData','colData','GridSaveFactory','USER_SETTINGS','$location', '$document', '$rootScope', '$scope', '$state', '$uibModal', '$log', 'workspaceFactory','$uibModalInstance', attachmentRemove])
        .controller('auditDocument', ['JsonObjectFactory','MessageFactory','GENERAL_CONFIG','AlertService','rowData','gridData','colData','GridSaveFactory','USER_SETTINGS','$location', '$document', '$rootScope', '$scope', '$state', '$uibModal', '$log', 'workspaceFactory','$uibModalInstance', auditDocument]);


    function girdStandardController(USER_SETTINGS,$location, $document, $rootScope, $scope, $state, $uibModal, $log, workspaceFactory, GridFactory, ModalFactory, AlertService, $timeout, JsonObjectFactory, DataFiltersFactory, GridSaveFactory, MessageFactor, GENERAL_CONFIG, SERVER_CONFIG, GlobalService,GridPivotFactory) {
        var vm = this;
        vm.name = "Update Data";

        $scope.action_key = '';
        $scope.grid_key = '';
        $scope.all_filter_data = '';
        $scope.display_filter_data = '';
        $scope.display_parent_me_data = '';
        $scope.display_parent_me_code = '';
        $scope.display_all_me_code = '';
        $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
        vm.batch_save_template = 'app/components/grid/delete----grid-batch-save-tpl.html'
        vm.showBatchSave = (workspaceFactory.activeScreen.screen_type && workspaceFactory.activeScreen.screen_type == "batch-save") ? true : false;
        $scope.tableState = [];
        vm.headerArray = [];
        vm.headerArrayNum = [];

        $scope.screenObj = '';
        $scope.screen_name = '';
        $scope.client_name = '';
        $scope.screen_key = workspaceFactory.activeScreen.screen_key;

        var listeners = {};
        $scope.screen_type = "";
        vm.gridsettings = {}

        if (workspaceFactory.activeScreen.screen_type && workspaceFactory.activeScreen.screen_type === 'grid-import' || workspaceFactory.activeScreen.screen_type && workspaceFactory.activeScreen.screen_type === 'grid-import-pivot') {
            $scope.filter_template = 'app/components/dataFilters/grid-import-tpl.html';
        }
        if (workspaceFactory.activeScreen.screen_type && workspaceFactory.activeScreen.screen_type === 'grid-import-td') {
            $scope.filter_template = 'app/components/dataFilters/grid-import-td-tpl.html';
        }


        //TODO rename this to GridUpdateListener
        var gridUpdateListener = $rootScope.$on('gridUpdate', function (event, args) {
            vm.update(args);
        });

        var gridDeleteListener = $rootScope.$on('gridDeleteRows', function (event, array) {
            $scope.$broadcast("deleteRows", array);
        });

        var gridDeleteRowListener = $rootScope.$on('gridDeleteSelectedRow', function (event, id) {
            $scope.$broadcast("deleteSelectedRow", id);
        });


        //console.log("workspaceFactory.activeScreen : ", workspaceFactory.activeScreen);


        // Drags only each single row..
        vm.pivotType = "rows";
        vm.dropRowList = [];
        vm.fromDirective = [];
        vm.valueTabDropList = [
            {name: "sum", value: "sum"},
            {name: "avg", value: "avg"},
            {name: "max", value: "max"},
            {name: "min", value: "min"}
           
        ];
        vm.valueShowDropList = [
            {name: "Default", value: "defualt"},
            {name: "% of row", value: "per_row"},
            {name: "% of column", value: "per_column"},
            {name: "% of grand total", value: "per_grand_total"}
           
        ];

        vm.showPivotHandler = true;
        vm.className = "";

        if(vm.showPivotHandler == true) {
            vm.className = "isPivot-gridLayout";
        }

        $scope.dropListRow = function (item, index) {
            dropRowList.push(item);
        }
        vm.openPiviot = false;

        vm.openPivotMethod = function() {
            console.log('$scope.openPiviot',vm.openPiviot);
            if(vm.className == "isPivot-gridLayout") {
                vm.className = "isPivotExpand-gridLayout";
            } else {
                vm.className = "isPivot-gridLayout";
            }
        }

        vm.pivotGroup = {
            agg:{ // aggregated fields

            },
            collapsed: [ false, false],
            groupRows: [],
            columnToRows: [],
            isPivotEnabled : false
        }
        vm.isPivotEnabled= false;
        vm.aggregationRowList = [];
        vm.selectedVal = vm.valueTabDropList[0].value;
        vm.selectValue = function(val, index) {
            vm.pivotValList[index].selectedDropVal = val;
            vm.pivotGroup.agg[vm.pivotValList[index].map] = val;
            vm.activeScreenPivotgroup();
        }
        vm.iamClicked = function(){
            console.log('I am clicked');
        }
        var destroyListerner = function(){
             $scope.$on('$destroy','dataFilters:dataBuoyLoad');
        }


      var dataBuoyload = 
        $scope.$on('dataFilters:dataBuoyLoad', function (event, args) {
            //console.log("===============================dataFilters:dataBuoyLoad ", args);
            //console.log("===============================dataFilters:dataBuoyLoadccccc  cdd   cccccccccccccccccccccccccccccccccccccccccccccccccccccccc ", args);
            listeners['dataFilters:dataBuoyLoad'] = true;
            $timeout(function () {
                if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.commentLink
                    && workspaceFactory.activeScreen.commentLink.filters  && workspaceFactory.activeScreen.commentLink.filters.length>0){
                        getData(args);
                    }

            });
            
        });
        

       

        //PREVENT BACKSPACE NAVAGATION
        $document.on('keydown', function (e) {
            if (e.which === 8 && (e.target.nodeName !== "INPUT" && e.target.nodeName !== "SELECT" && e.target.nodeName !== "TEXTAREA")) { // you can add others here inside brackets.
                e.preventDefault();
            }
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            if(listeners['dataFilters:dataBuoyLoad']){
               /*  if($rootScope.$$listeners['dataFilters:dataBuoyLoad'] && $rootScope.$$listeners['dataFilters:dataBuoyLoad'].length>1){
                    $rootScope.$$listeners['dataFilters:dataBuoyLoad'] = [];
                } */
                if($scope.$$listeners['dataFilters:dataBuoyLoad'] && $scope.$$listeners['dataFilters:dataBuoyLoad'].length>=1){
                    $scope.$$listeners['dataFilters:dataBuoyLoad'] = [];
                } 
                
            }
           gridUpdateListener();
          // destroyListerner();
           dataBuoyload();
            
        });


        vm.update = function (_params) {
            var filterObj = (typeof _params.gridFilter !== 'undefined') ? _params.gridFilter : null;
            delete _params['gridFilter'];
            getUpDate(_params, filterObj);
        };



        var getUpDate = function (_params, _filterObj) {
            var additionalParams = {}
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, filterParams, _params);
            $scope.$broadcast("showDataRefresh", true);


            //console.log("params -------------------------------------------------- : ", params);

            GridFactory.getDataUpdate($state.current.api.get, params).then(function (data) {

                //console.log("data -------------------------------------------------- : ", data);

                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    /* 6/5/2020 grid update check the compressed and split */
                    if((data && data && data.compressed && data.split_response ) ||
                       ( data && data && data.compressed)  && data.files == undefined){
                        data.data =  GridFactory.processGridData(data.header_keys,data.data);
                    }
                    else if(((data.split_response && data.files)||( data.compressed && data.files)) && data.files.length > 0){

                        GridFactory.getFilesData(data.activeScreen,data.files, data).then(function (data) {
                            //loadGridData(data, gridFilters);
                            data.data = data.data;
                        });
                    }
                    /* ends here */
                    data.activeScreen.data.data = data.data;
                    data.activeScreen.data.batchSelect = [];
                    $scope.$broadcast("dataUpdate", {
                        filter: _filterObj,
                        data: data.data
                    });
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $scope.ctrlFn = function (_callName, _data, _type) {



            switch (_type) {
                case "link":
                    //TODO: PASS DATA TO FILTER ON
                    if (_data.colData.link) {
                        var objCol = JSON.parse(_data.colData.link);
                        var table_state = null;

                        if (_data.colData.table_state) {
                            table_state = JSON.parse(_data.colData.table_state);
                        }

                        //console.log("=================table_state============", table_state)

                        workspaceFactory.loadLink("link", objCol.activity_key, objCol.screen_key, null, table_state, null);
                    }

                    break;
                case "openGridModal":
                    // $rootScope.$broadcast("rowAdded", _data);
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });

                    break;


                case "row-added":
                    $rootScope.$broadcast("rowAdded", _data);
                    break;

                case "batch-save":
                    if (!_data.colData.removable) {
                        $rootScope.$broadcast("dataChanged", _data);
                        break;
                    }

                case "reloadComment":
                    var type = _data.type;
                    $rootScope.$broadcast("loadGridMessages", type, true);
                    break;

                case "comment":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });
                    break;

                case "attachment":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });
                    break;
                case "preview":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        $scope.openModal(modalObj, _data);
                    });
                break;
                case "reloadAttachment":
                    $rootScope.$broadcast("loadGridAttachments", true);
                    break;

                case "batchSave":
                    if (_callName === "batchSaveDelete") {
                        $rootScope.$broadcast("batchSaveDelete", _data);
                        return true;
                    } else if (_callName === "batchSaveUnDelete") {
                        $rootScope.$broadcast("batchSaveUnDelete", _data);
                        return true;
                    }
                    break;

                case "form":

                    //console.log("_data ", _data);
                    if(workspaceFactory.activeScreen.screen_key == '167' || workspaceFactory.activeScreen.screen_key == '78') {
                        if(_data && _data.rowData && (_data.colData.call_back != 'editAttachment') && (_data.colData.call_back !='analyzeComments')){
                            if(_data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_ELIM') {
                                _callName = 'elimReclassAdjsByLocalChart';
                            } else if (_data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ_PY' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ_CY') {
                                _callName = 'reclassBookByLocalChart';
                            } else if (_data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_GHOST' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_PERM' || _data.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_TEMP') {
                                _callName = 'epTaxAdjsLocalChart';
                            }
                        }
                        
                    }

                    ModalFactory.getModal(_callName,_data).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi-07/24/2019(for disabling the modal outside click) //&& data.lockResult.is_locked == "Y"
                        if(data.lockResult && data.lockResult.is_locked  && data.modalScreen.lock_check_required == "Y") {
                            modalObj.windowClass = "modal-window-lock-class";
                            modalObj.modal_lock= data.lockResult.is_locked;
                            modalObj.modal_lock_err_msg = data.lockResult.lock_message;
                            modalObj.lock_check_required = data.modalScreen.lock_check_required;
                        }
                        $scope.openModal(modalObj, _data);
                    })
                    break;

                case "drillDown":
                    var gridFilters = {
                        gridFilters:[],
                        drillDownRefresh:0,
                        filterObj:{},
                        screenFilter:[]
                    };
                    var callDrillDown=function(drillDownObject){
                        var dataObj = JSON.parse(JSON.stringify(eval("(" + (drillDownObject) + ")")));//JSON.parse(drillDownObject);
                        if (dataObj.filters && dataObj.filters.length) {
                            _.forEach(dataObj.filters, function (value, key) {
                                var _value;
                                if(_data && _data.rowData && _data.rowData.FROMDOCUMENT == 'document'){
                                    _value = Number(_data.rowData.HEADER_KEY);
                                }else{
                                    _value = _data.rowData[value]
                                }

                                if (_value && (_value.length || _.isNumber(_value))) {
                                    var obj = {};
                                    obj.predicate = value;
                                    obj.value = [];
                                    obj.type = "array";
                                    obj.reverse = false;
                                    obj.operator = "[]";
                                    obj.value.push(_value);
                                    gridFilters.gridFilters.push(obj);
                                }
                            });
                            gridFilters.drillDownRefresh = _data.colData.drilldown_filter_refresh == null ? gridFilters.drillDownRefresh : _data.colData.drilldown_filter_refresh
                        }
                        var gridFilterObj = [];
                        var screenFilters = function(screenFilters){
                            _.forEach(screenFilters, function (value, key) {
                                var _value= _data.rowData[value.row_data_key];
                                if (_value && (_value.length || _.isNumber(_value))) {
                                    var obj = {};
                                   // obj.predicate = value.name;
                                    obj.value = [];
                                    obj.type = "array";
                                    obj.reverse = false;
                                    obj.operator = "[]";
                                    obj.value.push(_value);
                                    if(value.row_data_key=='TAX_YEAR'){
                                        value.value = _value+'';
                                    }else{
                                        value.value = _value;
                                    }
                                    gridFilters.screenFilter.push(obj);
                                }
                            });
                                gridFilters.screenFilter.forEach(item=>{
                                gridFilterObj.push({name:item.predicate,value:item.value.join('')});
                            });
                        }
                        var action_template = dataObj.action_template_key !=null ? dataObj.action_template_key: null;
                            /*********************************************************************************************************
                             *   dirlldown from the one activity screen to another activity screen without filter inherit 9/5/2019
                             ********************************************************************************************************/
                        if(dataObj.activity_key && dataObj.screen_key && dataObj.screenFilter ||dataObj.filter_json){
                            if (dataObj.screenFilter && dataObj.screenFilter.length) {
                                screenFilters(dataObj.screenFilter)
                            }

                            gridFilters.filterObj=(gridFilterObj);
                            workspaceFactory.loadLink("drillDown",null,dataObj.activity_key,dataObj.screen_key,dataObj.screenFilter||dataObj.filter_json,gridFilters.gridFilters,null,action_template);
                        //   _type,_comment,_activity_key,_screen_key,_filters,_table_state,_locationObj,_actionGrid,_noReload
                                /*********************************************************************************************************
                             *   dirlldown from the one screen to another screen without filter inherit 9/5/2019
                                 ********************************************************************************************************/

                            }else if(dataObj.activity_key && dataObj.screen_key){
                                 workspaceFactory.loadLink("drillDown",null,dataObj.activity_key,dataObj.screen_key,{},{},null,action_template);
                            }
                            else if (dataObj.screen_key && dataObj.screenFilter){
                                screenFilters(dataObj.screenFilter);
                                gridFilters.filterObj=(gridFilterObj);
                                workspaceFactory.loadLink("drillDown","{}",workspaceFactory.activeScreen.activity_key,dataObj.screen_key,(dataObj.screenFilter||dataObj.filter_json),
                                gridFilters.gridFilters,null,action_template);
                            }
                                /*********************************************************************************************************
                             *   dirlldown from the one screen to another screen with filters inherit
                                 ********************************************************************************************************/
                            else{
                                var index = _.findIndex(workspaceFactory.screens, {
                                                'screen_key': dataObj.screen_key
                                            });
                            if (index >= 0) {
                                workspaceFactory.loadDrillDown(workspaceFactory.screens[index], gridFilters);
                            }
                        }
                    }
                    if(_data.rowData && _data.rowData.DRILLDOWN) {
                        callDrillDown(_data.rowData.DRILLDOWN);
                    } else  if (_data.colData && _data.colData.drilldown) {
                        if(_.isArray(JSON.parse(_data.colData.drilldown))){ /* drilldown with rule --- based on rule it will redirect to target screen 8/24/2020 */
                            var arryObj = JSON.parse(_data.colData.drilldown);
                            var isFound = false;
                            var drillObj = "";
                            var checkCondition = function(rule,data){
                                var isValid =false;
                                if(rule.operator === "=" && rule.value == data[rule.map]){
                                     isValid = true;
                                }else if(rule.operator === "!=" && rule.value != data[rule.map]){
                                     isValid = true;
                                }
                                return isValid;
                            }
                            _.each(arryObj,function(item){
                                if(item.rule != undefined){
                                   var x = checkCondition(item.rule,_data.rowData);
                                   if(x){
                                        isFound = true;
                                        drillObj = JSON.stringify(item.drillDown);
                                   }
                                }
                            })
                            if(isFound){
                                callDrillDown((drillObj));
                            }
                        }else{
                            callDrillDown(_data.colData.drilldown); /* drilldown without rule */
                        }
                    }
                    break;

                case "input":
                        GridSaveFactory.saveSwitch(_callName, _data);
                        break;
                case "columnTemplate":
                    GridSaveFactory.colTemplatesUpdate(workspaceFactory.activeScreen.data.settings.grid_key, _callName, _data);
                   /*  $rootScope.$on('loadColumnTemplates', function (event, data) {
                        ggApi.colToolSetTemplates(data);
                    }); */
                    break;
                case "auditcell":
                    ModalFactory.getModal(_callName).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi(for disabling the modal outside click)
                        $scope.openModal(modalObj, _data);
                    });
                    break;

                case "downloadDoc":
                    if (_callName === "downloadDocuments") {
                        return true;
                    }
                    break;
                case "removeBatchSelect":
                    workspaceFactory.activeScreen.data.batchSelect = _data.batchSelect;
                    break;
                case "applyPivotTemplate":
                    if(!_.isEmpty(_data.pivotGroupObject)){
                        workspaceFactory.activeScreen.data['pivotRowList'] =[];
                        workspaceFactory.activeScreen.data['pivotColumn'] = [];
                        workspaceFactory.activeScreen.data['pivotValList'] = [];
                      if(_data.pivotGroupObject.groupRows && _data.pivotGroupObject.groupRows.length){
                        workspaceFactory.activeScreen.data['pivotRowList'] = _.filter(workspaceFactory.activeScreen.data.header, (h) => {
                            return _data.pivotGroupObject.groupRows.indexOf(h.map) >=0;
                          
                          });
                      }
                      if(_data.pivotGroupObject.columnToRows && _data.pivotGroupObject.columnToRows.length){
                         workspaceFactory.activeScreen.data['pivotColumn'] = _.filter(workspaceFactory.activeScreen.data.header, (h) => {
                            return _data.pivotGroupObject.columnToRows.indexOf(h.map) >=0;
                          
                          });
                      }
                      if(_data.pivotGroupObject.agg && !_.isEmpty(_data.pivotGroupObject.agg)){
                          var valKeys = _.keys(_data.pivotGroupObject.agg);
                          workspaceFactory.activeScreen.data['pivotValList'] =   
                            _.filter(workspaceFactory.activeScreen.data.header, (h) => {
                                return valKeys.indexOf(h.map) >=0;
                            
                            });                       
                      }

                    }
                    workspaceFactory.activeScreen.data['pivotGroup'] = _data.pivotGroupObject;
                    $scope.$broadcast('applyPivotPanel',workspaceFactory.activeScreen.data, _data.clearActionTemplate);                    
                   // $scope.$broadcast('dataLoaded',workspaceFactory.activeScreen.data) ;
                   if(_data.clearActionTemplate){
                        workspaceFactory.activeScreen.data.pivotdataArr =[];
                        workspaceFactory.activeScreen.data.pivotheaderArray =[];
                        if(_.filter( workspaceFactory.activeScreen.data.header,{map:'group'}).length>=0){
                            _.remove( workspaceFactory.activeScreen.data.header,function(item){
                                return item.map == 'group';
                            });
                        }
                        $scope.$broadcast('dataLoaded',workspaceFactory.activeScreen.data)

                    }else{
                        GridPivotFactory.applyGroupPivot();
                    }

                    break;
                default:
                    ////console.error("Type was not correct")
                    break;
            }
        }
        var splitParameter = function (args) {
            if(!workspaceFactory.activeScreen.filters.filters) return false;
            $scope.$broadcast("showLoader", true);

            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var splitParam = workspaceFactory.activeScreen.split_call_param;
            var splitObj = _.find(workspaceFactory.activeScreen.filters.filters, {
                param_name: splitParam
            })
            var filterArray = splitObj.selected;
            var filters = [];
            var meSelectedList = []; var gridFilters =[];

            //BUILD SEPARATE FILTER OBJECTS FOR EACH AJAX REQUEST
             /* Ends Here 8/28/2019 */
             for (var i = 0; i < filterArray.length; i++) {
                var filterObject = _.cloneDeep(filterParams);
                filterObject.parent_me_string = filterArray[i];

                /* Divya -08/27/2019 if the grid have drilldownRefresh =1 then prepare the filter objects */

                if($state.params.gridFilters && $state.params.gridFilters.drillDownRefresh){
                    if(parseInt($state.params.gridFilters.drillDownRefresh)==1){
                        $state.params.gridFilters.gridFilters.forEach(function(gridFilter){
                            filterObject[gridFilter.predicate.toLowerCase()]=gridFilter.value.join(',');
                        });
                    }
                }
                filters.push(filterObject);
            }

            GridFactory.getDataSplitProcess($state.current.api.get, filters, args,($state.params.gridFilters.drillDownRefresh || gridFilters)).then(function (data) {

                // Heath Stein - 03/08/2017
                // Trap: If we call more then one scree at the same time. Then we need to check for a undefined return.
                // The data has been stored cache but is not being returned to the screen cause the screen is not loaded.
                if (typeof data === "undefined" || data === null) {
                    return false;
                }

                if (data && data.callSuccess === "1") {

                    //IF WE HAVE DRILL DOWN INJECT FILTERS
                    if ($state.params && $state.params.drillDown && $state.params.gridFilters.gridFilter || $state.params.gridFilters || gridFilters && gridFilters.length) {
                        data.tableState.colSearch = [];
                        data.tableState.colSearch = ($state.params.gridFilters && $state.params.gridFilters.gridFilters || gridFilters);
                        $scope.tableState = data.tableState.colSearch;
                    }
                    var inWorkspace = ($state.current.name && $state.current.name.includes("workspace.")) ? true : false;
                    if (inWorkspace) { //TEMP HACK - ISSUE WITH ADMIN PAGES OPENED WHILE A GETDATA OF A WORKSPACE IS STILL RUNNING CAUSING THE GRID TO BE REFRESHED IN THE ADMIN VIEW
                        $rootScope.$broadcast("dataLoaded", data);
                        $rootScope.$broadcast("showLoader", false);
                    }

                    var actionKey = GlobalService.getActionKey();
                    if (!!actionKey) {
                        //console.log("ACTION KEY IS PRESENT");
                        //window.jQuery.actionsService.applyActionTemplate(actionKey);
                    };

                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                    $rootScope.$broadcast("showLoader", false);
                }
            });

        }
        
        var getData = function (args) {
            vm.isAuditCellsEnable = 0;
            $rootScope.$broadcast("releaseData");
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};


            // JOYS FUNCTION FOR EXCEL EXPORT
            makeParamsForExcel(filterParams);

            var gridFilters=[];
            var filterObject = _.cloneDeep(filterParams);

            $rootScope.$broadcast('dataFilters:filtersLoaded');
                if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.commentLink){
                gridFilters= (workspaceFactory.activeScreen.commentLink.table_state);
            }



            if (workspaceFactory.activeScreen && workspaceFactory.activeScreen.split_call_param) {
                splitParameter(args);
             //   $rootScope.$broadcast('dataFilters:filtersLoaded'); // added 4/9/2019
                return true;
            }
            //  $rootScope.$broadcast('filter:updated');
            //   $rootScope.$broadcast('dataFilters:filtersLoaded');
            if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.filters){
                _.each(workspaceFactory.activeScreen.filters.filters,function(item){
                    if(item.param_name == 'parent_me_string'){
                        $rootScope.$broadcast('dataFilters:filtersLoaded');
                    }
                })
            }


            if($state.params.gridFilters && $state.params.gridFilters.drillDownRefresh){
                if(parseInt($state.params.gridFilters.drillDownRefresh)==1){
                    $state.params.gridFilters.gridFilters.forEach(function(gridFilter){
                        filterObject[gridFilter.predicate.toLowerCase()]=gridFilter.value.join(',');
                    });
                }
            }

            
            $rootScope.$broadcast("showLoader", true);
            vm.isAuditCellsEnable = 0;

            if($state.current.api && $state.current.api.get){
                vm.dropRowList = [];

                GridFactory.getData($state.current.api.get, args, filterObject,($state.params.gridFilters.drillDownRefresh || gridFilters)).then(function (data) {
                //    var activeScreen = workspaceFactory.activeScreen;
                    if (data.callSuccess === "1") {
                        //IF WE HAVE DRILL DOWN INJECT FILTERS
                        if(((data.split_response && data.files) || (data.compressed && data.files)) && data.files.length > 0){

                            GridFactory.getFilesData(data.activeScreen,data.files, data).then(function (data) {
                                loadGridData(data, gridFilters);
                            });
                        }else{
                            //Dual grid load implementation, renders data on 2 grids simultaneously on a single feed upload.
                            if(data.screen_key == '11169'){
                                var rawData = GlobalService.getRawData();
                                if(rawData.length > 1){
                                    data.data = rawData;
                                }
                                else{
                                    data.data = [];
                                    AlertService.add("warning", "This Grid is only for displaying raw data, Kindly load the feed data first", 4000);
                                }
                            }
                            if(data.screen_key == '11471'){
                                var pRawData = GlobalService.getpRawData();
                                if(pRawData.length > 1){
                                    data.data = pRawData;
                                    data.batchSelect = [];
                                    for(let i=0;i<pRawData.length;i++) {
                                        data.batchSelect.push({
                                            row_id: i+1,
                                            data: pRawData[i]
                                        })
                                    }
                                }
                                else{
                                    data.data = [];
                                    AlertService.add("warning", "This Grid is only for displaying raw data, Kindly load the feed data first", 4000);
                                }
                            }
                            loadGridData(data, gridFilters);
                        }
                        vm.isAuditCellsEnable =  (data.settings && data.settings.audit_cells)?  data.settings.audit_cells : 0;
                        vm.gridsettings = data.settings;
                        if(vm.gridsettings.grid_type == 'import' || vm.gridsettings.grid_type == 'custom'){
                            vm.showPivotHandler = false;
                            vm.openPiviot = false;
                             vm.className = "";

                        }
                    } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                        $rootScope.$broadcast("showLoader", false);
                    }

                });
            }
        }

        function loadGridData(data, gridFilters) {
            //IF WE HAVE DRILL DOWN INJECT FILTERS
            if ($state.params && $state.params.drillDown && $state.params.gridFilters.gridFilter || $state.params.gridFilters || gridFilters && gridFilters.length) {
                data.tableState.colSearch = [];
               // data.tableState.colSearch = ($state.params.gridFilters||($state.params.gridFilters && $state.params.gridFilters.gridFilters) || gridFilters);
              //  $scope.tableState = data.tableState.colSearch;
              if($state.params.gridFilters && $state.params.gridFilters.gridFilters){
                  data.tableState.colSearch = $state.params.gridFilters.gridFilters;
              }else{
                  data.tableState.colSearch = $state.params.gridFilters ||gridFilters;
              }

            }
            var inWorkspace = ($state.current.name && $state.current.name.includes("workspace.")) ? true : false; //TEMP HACK - ISSUE WITH ADMIN PAGES OPENED WHILE A GETDATA OF A WORKSPACE IS STILL RUNNING CAUSING THE GRID TO BE REFRESHED IN THE ADMIN VIEW
            //IF we are on the current screen that just finished loading then show data.
            if (workspaceFactory.activeScreen.screen_key === data.screen_key && inWorkspace) {
            $rootScope.$broadcast("dataLoaded", data);

                vm.cloneArrHeader = _.cloneDeep(data.header);
                vm.cloneColumnsArray = _.cloneDeep(data.header);
                vm.cloneColHdrArray = _.cloneDeep(data.header);
                vm.cloneValHdrArray = _.cloneDeep(data.header);

                vm.headerArray = data.header;
                vm.arrHeader = vm.cloneArrHeader;
                vm.colHdrArray = _.filter(vm.cloneColHdrArray,function(i){return i.format=='String'||i.format == null});
                vm.valHdrArray = _.filter(vm.cloneValHdrArray,function(i)
                                    {return (i.data_type == 'number' && (i.format == 'numberWithCommas' || i.format=='floatWithCommas'))})//_.filter(vm.cloneValHdrArray, {data_type: "number"});               
                vm.copyHeaderArray = _.cloneDeep(vm.headerArray);// to avoid same value selections in rows and values..

                if(workspaceFactory.activeScreen.data.firstLoad == 1){
                    vm.pivotRowList = [];
                    vm.pivotColList = [];
                    vm.pivotValList = [];
                    vm.pivotGroup = {
                        agg:{ // aggregated fields
            
                        },
                        collapsed: [ false, false],
                        groupRows: [],
                        columnToRows: [],
                        isPivotEnabled : false
                    }
                }
                vm.headerArrayNum = processHeaderArray(vm.headerArray);

                
                $rootScope.$broadcast("showLoader", false);

                //Fires an event to GridBatchSaveController.js
                if (workspaceFactory.activeScreen.screen_type && workspaceFactory.activeScreen.screen_type === 'batch-save') {
                    $rootScope.$broadcast("batchSaveLoaded", data);
                }
            }
        }

        // Row pivot tab functions
        vm.pivotRowList = [];
        vm.pivotColList = [];
        vm.pivotValList = [];
       
        // DO not remove pivot panelObj(placeholder) parameter, actonTemplatVal is boolen is in USE.. 
        $scope.$on('applyPivotPanel',function(data, panelObj, actionTemplateVal){
            if(workspaceFactory.activeScreen.data && !_.isEmpty(workspaceFactory.activeScreen.data.pivotGroup)){
                vm.showPivotHandler = true;
                vm.openPiviot = true;
                vm.className = "isPivotExpand-gridLayout";
                vm.pivotRowList = workspaceFactory.activeScreen.data['pivotRowList'] ;
                vm.pivotValList = workspaceFactory.activeScreen.data['pivotValList'] ;
                vm.pivotColList =  workspaceFactory.activeScreen.data['pivotColumn'] ;
                vm.pivotGroup = workspaceFactory.activeScreen.data.pivotGroup;
                if(vm.pivotColList && vm.pivotColList.length>0){
                    vm.isPivotEnabled = vm.pivotGroup.isPivotEnabled//true;
                }else{
                    vm.isPivotEnabled = false;
                }
                if(actionTemplateVal !='undefined' && actionTemplateVal == true) {
                    vm.showPivotHandler = true;
                    vm.openPiviot = false;
                    vm.className = "isPivot-gridLayout";
                    // workspaceFactory.activeScreen.data.pivotGroup.clearActionTemplate = false;
                }
            }
            
        })
        function swap(arr, from, to) {
            let temp = arr[from];
            arr[from] = arr[to];
            arr[to] = temp;
            return arr;
        }
        /* pivot panel properties rows,columns,values */
        vm.addLabelsToPivotList = function(tabName, selectedDataFrmPopover, index,fromDragDrop) {
            let isPivotAppied = [];
            let actionPivotGroup = '';
            if(workspaceFactory.activeScreen.data.actionTemplates){
                isPivotAppied = _.filter(workspaceFactory.activeScreen.data.actionTemplates,function(i){
                    return i.selectedAction;
                });
                if(isPivotAppied && isPivotAppied.length>0){
                    actionPivotGroup =  JSON.parse(isPivotAppied[0].grid_data);
                   if(actionPivotGroup['groupRows'] == undefined || actionPivotGroup['columnToRows'] == undefined){
                        AlertService.add('warning','Please remove action template and apply the pivot or group',3000);
                         return;
                   }
                   
                }
               
            }
            if(tabName == 'pivotRow') {
                if(_.filter(vm.pivotRowList,{map:selectedDataFrmPopover.map}).length ==0){
                    vm.pivotRowList.push(selectedDataFrmPopover);
                    vm.pivotGroup.groupRows.push(selectedDataFrmPopover.map);
                    vm.arrHeader.splice(index, 1);
                    if(isPivotAppied && isPivotAppied.length>0){
                        if(actionPivotGroup['groupRows']){
                            actionPivotGroup['groupRows'].push(selectedDataFrmPopover.map);
                            isPivotAppied[0].grid_data = JSON.stringify(actionPivotGroup);
                        }
                       
                     }

                    vm.activeScreenPivotgroup();
                }else if(fromDragDrop){
                    var findCurIndex = _.findIndex(vm.pivotGroup.groupRows, function(i){return i == selectedDataFrmPopover.map});
                    vm.pivotGroup.groupRows= swap(vm.pivotGroup.groupRows,findCurIndex-1,index-1);
                    vm.activeScreenPivotgroup();
                }
               
            } else if(tabName == 'pivotValue') {
                if(_.filter(vm.pivotValList,{map:selectedDataFrmPopover.map}).length ==0){
                    selectedDataFrmPopover.selectedDropVal = "sum"
                    vm.pivotValList.push(selectedDataFrmPopover);
                    vm.pivotGroup.agg[selectedDataFrmPopover.map] = selectedDataFrmPopover.selectedDropVal
                    vm.valHdrArray.splice(index, 1);
                    if(isPivotAppied && isPivotAppied.length>0){
                       if(actionPivotGroup['agg']){
                            actionPivotGroup['agg'][selectedDataFrmPopover.map] = selectedDataFrmPopover.selectedDropVal;
                            isPivotAppied[0].grid_data = JSON.stringify(actionPivotGroup);
                       }                       
                     }

                    vm.activeScreenPivotgroup();
                }else if(fromDragDrop){
                    var ob = {};
                    _.each(vm.pivotValList,function(i){
                        ob[i.map] = i.selectedDropVal;
                    })
                    console.log("ob",ob);
                    vm.pivotGroup.agg = ob;
                  if(vm.pivotGroup.columnToRows && vm.pivotGroup.columnToRows.length>0){
                    vm.activeScreenPivotgroup();
                  }
                }
                
            } else if(tabName == 'pivotColumn') {
                if(_.filter(vm.pivotColList,{map:selectedDataFrmPopover.map}).length ==0){
                    vm.pivotColList.push(selectedDataFrmPopover);
                    vm.pivotGroup.columnToRows.push(selectedDataFrmPopover.map);
                    vm.colHdrArray.splice(index, 1);
                    if(vm.pivotValList && vm.pivotValList.length>0){
                        if(isPivotAppied && isPivotAppied.length>0){
                            if(actionPivotGroup['columnToRows']){
                                actionPivotGroup['columnToRows'].push(selectedDataFrmPopover.map);
                                isPivotAppied[0].grid_data = JSON.stringify(actionPivotGroup);
                            }                            
                        }
                        vm.activeScreenPivotgroup();
                    }else{
                        AlertService.add('warning',"Please select at least one value from values list",2000);
                        return;
                    }                }
                else if(fromDragDrop){
                    var findCurIndex = _.findIndex(vm.pivotGroup.columnToRows, function(i){return i == selectedDataFrmPopover.map});
                    vm.pivotGroup.columnToRows= swap(vm.pivotGroup.columnToRows,findCurIndex-1,index-1);
                    vm.activeScreenPivotgroup();
                }
               
            }
        }

        if(workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data) {
            workspaceFactory.activeScreen.data.pivotListDelTriggered = false;
        }

        vm.deletePivotListData = function(tabName, selectedDataList, index, triggerVal) {
            workspaceFactory.activeScreen.data.pivotListDelTriggered = triggerVal;
            let isPivotAppied = _.filter(workspaceFactory.activeScreen.data.actionTemplates,function(i){
                return i.selectedAction;
            });
            let actionPivotGroup = {};
            if(isPivotAppied && isPivotAppied.length>0){
                actionPivotGroup =  JSON.parse(isPivotAppied[0].grid_data);
               
            }
            if(tabName == 'delPivotRow') {
                vm.pivotRowList.splice(index, 1);
                vm.pivotGroup.groupRows.splice(index, 1);
                if(isPivotAppied && isPivotAppied.length>0){
                    if(actionPivotGroup['groupRows']){
                        actionPivotGroup['groupRows'].splice(index, 1);
                        isPivotAppied[0].grid_data = JSON.stringify(actionPivotGroup);
                    }
                   
                 }
                vm.arrHeader.push(selectedDataList);
                vm.activeScreenPivotgroup();
            } else if(tabName == 'delPivotValue') {
                vm.pivotValList.splice(index, 1);
                delete vm.pivotGroup.agg[selectedDataList.map];
                if(isPivotAppied && isPivotAppied.length>0){
                    if(actionPivotGroup['agg']){
                        delete actionPivotGroup['agg'][selectedDataList.map];
                        isPivotAppied[0].grid_data = JSON.stringify(actionPivotGroup);
                    }
                   
                 }
                vm.valHdrArray.push(selectedDataList);
                vm.activeScreenPivotgroup();
            } else if(tabName == 'delPivotColumn') {
                vm.pivotColList.splice(index, 1);
                vm.pivotGroup.columnToRows.splice(index, 1);
                vm.colHdrArray.push(selectedDataList);
                if(isPivotAppied && isPivotAppied.length>0){
                    if(actionPivotGroup['columnToRows']){
                        actionPivotGroup['columnToRows'].splice(index, 1);
                        isPivotAppied[0].grid_data = JSON.stringify(actionPivotGroup);
                    }                            
                }
                vm.activeScreenPivotgroup();
            }
        }

        vm.applyPivotMode = function(){
            vm.pivotGroup.isPivotEnabled = vm.isPivotEnabled;
            vm.isPivotEnabled = vm.isPivotEnabled;
            if( vm.pivotColList && vm.pivotColList.length>0 &&vm.pivotValList && vm.pivotValList.length>0){
                vm.activeScreenPivotgroup();
            }else if(vm.pivotColList && vm.pivotColList.length>0){
                AlertService.add('warning',"Please select at least one value from values list",2000);
                return;
            }
        }
        vm.activeScreenPivotgroup = function() {
            workspaceFactory.activeScreen.data['pivotGroup'] = vm.pivotGroup;
            workspaceFactory.activeScreen.data['pivotRowList'] = vm.pivotRowList;
            workspaceFactory.activeScreen.data['pivotValList'] = vm.pivotValList;
            workspaceFactory.activeScreen.data['pivotColumn'] =  vm.pivotColList;

            GridPivotFactory.applyGroupPivot(); 
        }




        /* get the number header 8/27/2020 */
        function processHeaderArray(headers){
            var headArr = [];
            _.each(headers,function(item){
                var childArr = function(item){
                    _.each(item,function(i){
                        if(i && i.data_type && i.data_type == 'number' && item.format != null && item.map != 'OBJECT_ID' || item.map == 'object_id'){
                            headArr.push(i);
                        }
                    })
                }
                if(item && item.data_type && item.data_type == 'number' && item.format != null && item.map != 'OBJECT_ID' || item.map == 'object_id'){
                    headArr.push(item);
                }else if(item && _.isArray(item.children) && item.children.length>0){
                    childArr(item.children);
                }
            });
            console.log("number header Arry", headArr);
        return headArr;
        }
        
        $scope.$on('dataFilters:refreshGrid', function (event, args) {
            getData(args);
            if (workspaceFactory.activeScreen.screen_type && workspaceFactory.activeScreen.screen_type === 'batch-save') {
                $rootScope.$broadcast("clearBatchSave");
            }
        });
        var modalOpenedFlag = false; // Flag to disable multiple modals open

        $scope.openModal = function (modalObj, _data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            $scope.colData = _data.colData;
            if (modalOpenedFlag) return;


            var separator = 'c='
            if (!GENERAL_CONFIG.cache_templates) {
                separator += Math.random().toString(36).slice(2);
            } else {
                separator += SERVER_CONFIG.configSettings.release_version;
            }

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template + '?' + separator,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop, // added by Omi-07/24/2019(for disabling the modal outside click)
                windowClass: modalObj.windowClass,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    colData: function () {
                        return $scope.colData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });
            modalOpenedFlag = true;

            modalInstance.opened.then(function() {
                var modalLockProps = [];
                //&& modalObj.modal_lock == "Y"
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                
               

                if(modalObj  && modalObj.lock_check_required == "Y") {
                    if(workspaceFactory.activeScreen.data.settings.batch_select == 1){
                        _.each($scope.gridData,function(item){
                            if(item && item.data){
                                modalLockProps.push({
                                    "leid": item.data.LEID ,//"IF1139",
                                    "me_code": item.data.ME_CODE ||  item.data.ME || item.data.MAINTENANCE_OWNER || item.data.SOURCE_ME_CODES,
                                    "cdr_number":item.data.CDR_NO ||  item.data.TAX_CODE ,
                                    "consol_group_key": item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP,//1,
                                    "reporting_period": item.data.REPORTING_PERIOD||item.data.HO_REPORTING_PERIOD,
                                    "combination_key":item.data.COMBINATION_KEY ||item.data.HO_COMBINATION_KEY || item.data.COMPL_COMBINATION_KEY //1
                                    ,"scenario_key": item.data.SCENARIO_KEY ? item.data.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                                })
                            }
                           
                        })
                        if($scope.gridData && $scope.gridData.rowData){
                            modalLockProps.push({
                                "leid":$scope.gridData.rowData.LEID,//"IF1139",
                                "me_code": $scope.gridData.rowData.ME_CODE || $scope.rowData.ME || $scope.rowData.MAINTENANCE_OWNER ||  $scope.rowData.SOURCE_ME_CODES,
                                "cdr_number":$scope.gridData.rowData.CDR_NO || $scope.rowData.TAX_CODE,
                                "consol_group_key": $scope.gridData.rowData.CONSOL_GROUP_KEY || $scope.gridData.rowData.FILING_GROUP,//1,
                                "reporting_period": $scope.gridData.rowData.REPORTING_PERIOD,
                                "combination_key":$scope.gridData.rowData.COMBINATION_KEY  || $scope.gridData.rowData.HO_COMBINATION_KEY || $scope.gridData.rowData.COMPL_COMBINATION_KEY,
                                "scenario_key": $scope.gridData.rowData.SCENARIO_KEY ? $scope.gridData.rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                            })
                        }
                    }else if($scope.rowData){
                        if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.screen_key == 10075){ /* Tax Attribute Screen */
                                modalLockProps.push({  
                                "leid":$scope.rowData.POSTING_LEID,//"IF1139",
                                "me_code": $scope.rowData.POSTING_ME_CODE,
                                "cdr_number":$scope.rowData.POSTING_CDR_NO,
                                "reporting_period": $scope.rowData.POSTING_REPORTING_PERIOD
                                ,"scenario_key": $scope.rowData.SCENARIO_KEY ? $scope.rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario
                            })
                        }else{
                            modalLockProps.push({
                                "leid":$scope.rowData.LEID,//"IF1139",
                                "me_code": $scope.rowData.ME_CODE || $scope.rowData.ME || $scope.rowData.MAINTENANCE_OWNER || $scope.rowData.SOURCE_ME_CODES,
                                "cdr_number":$scope.rowData.CDR_NO || $scope.rowData.TAX_CODE,
                                "consol_group_key": $scope.rowData.CONSOL_GROUP_KEY ||  $scope.rowData.FILING_GROUP,//1,
                                "reporting_period": $scope.rowData.REPORTING_PERIOD,
                                "combination_key":$scope.rowData.COMBINATION_KEY || $scope.gridData.rowData.HO_COMBINATION_KEY|| $scope.gridData.rowData.COMPL_COMBINATION_KEY,
                                "scenario_key": $scope.rowData.SCENARIO_KEY ? $scope.rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario
                            })
                        }                       
                    }                  
                }                
                GlobalService.modalLockFilters(modalLockProps);
            });
            

            modalInstance.rendered.then(function() {
                let scenarioCode = '';
                if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.filters  && typeof workspaceFactory.activeScreen.filters.getFilters === "function"){
/*                     scenarioCode =  _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;
                    GlobalService.globalParams.scenarioCode = scenarioCode.CODE; */
                    let foundScenario =  _.find(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'});
                    if(foundScenario && foundScenario.selected){
                        scenarioCode = foundScenario.selected;
                        GlobalService.globalParams.scenarioCode = scenarioCode.CODE;
                        GlobalService.globalParams.is_issue_key_enabled = scenarioCode.ISSUE_ID_ENABLED;
                    }else{
                        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                        let taxYear = filterParams.tax_year ?filterParams.tax_year : GlobalService.globalParams.taxYear
                        _.find(GlobalService.inputs.tax_year.values,function(i){
                            if(i.value == taxYear){
                                foundScenario = _.find(i.scenarios,{scenario:GlobalService.globalParams.scenario});
                            }
                        });
                        if(foundScenario){
                            scenarioCode = foundScenario.scenario_type_code;
                            GlobalService.globalParams.scenarioCode = scenarioCode;
                            GlobalService.globalParams.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
                        }
                    }
                   
                }

                if(modalObj && modalObj.modal_lock == "Y" && modalObj.lock_check_required == "Y"){
                    var modalLockSelector = document.querySelector('.modal-window-lock-class .modal-dialog .modal-content');
                    modalLockSelector.querySelector('.modal-header').classList.add("modal-lock-hdr-class");
                    var modalfooters = modalLockSelector.querySelectorAll('.modal-footer'); //changed logic, as some modals are using morethan one modal-footer..
                    if(modalfooters && modalfooters.length > 0) {
                        _.forEach(modalfooters, function(eachFooter) {
                            if(eachFooter) {
                                _.forEach(eachFooter.querySelectorAll('[class*="btn"]'), function(eachBtn) {
                                    eachBtn.disabled = true;
                                })
                            }
                        })
                    }   
                    if((scenarioCode.ISSUE_ID_ENABLED == 'Y' && (GlobalService.globalParams.issue_id == ''))){
                            AlertService.add("error", 'Please select the Global Issue id', 3000);
                     }
                    else if( modalObj.modal_lock_err_msg){
                        AlertService.add("error", modalObj.modal_lock_err_msg, 3000);
                    }             
                }
                else if((scenarioCode.ISSUE_ID_ENABLED == 'Y' && (GlobalService.globalParams.issue_id == ''))){
                        var modalLockSelector = document.querySelector('.modal-window-lock-class .modal-dialog .modal-content');

                        var modalfooters = modalLockSelector.querySelectorAll('.modal-footer'); //changed logic, as some modals are using morethan one modal-footer..
                        if(modalfooters && modalfooters.length > 0) {
                            _.forEach(modalfooters, function(eachFooter) {
                                if(eachFooter) {
                                    _.forEach(eachFooter.querySelectorAll('[class*="btn"]'), function(eachBtn) {
                                        eachBtn.disabled = true;
                                    })
                                }
                            })
                        }                    
                    AlertService.add("error", 'Please select the Global Issue id', 3000);
                }
            })

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                modalOpenedFlag = false;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
                if(GlobalService.globalParams.checkLockedbj && (GlobalService.globalParams.checkLockedbj.length > 0 || !(_.isEmpty( GlobalService.globalParams.checkLockedbj)))) {
                    var modalLockProps = {};
                    GlobalService.modalLockFilters(modalLockProps);
                }
                modalOpenedFlag = false;
            });
        };




        function onLoad() {

            if ($state.params && $state.params.launchBuoy) {
                return true;
            }
            $scope.$broadcast("showLoader", true);
            var drillDown = false;


            if (($state.params && $state.params.drillDown)) {
                drillDown = true;
                GlobalService.isDrillDown = true;
            } else {
                GlobalService.isDrillDown = false;
            }
            $timeout(function () {

                if (workspaceFactory.activeScreen.filter_type != 'required' || (workspaceFactory.activeScreen.filter_type == 'required' &&
                    (workspaceFactory.activeScreen.screen_type == 'grid-import' || workspaceFactory.activeScreen.screen_type == 'grid-import-td' ||
                    workspaceFactory.activeScreen.screen_type == 'grid-import-pivot')) || workspaceFactory.activeScreen.data) {
                    var args = {
                        "cache": false
                    };
                    getData(args);
                } else if (drillDown === true) {
                    //TODO: Heath added 3/3/2019 - this is a hack to make sure the filters are loaded on dropdowns
                    $timeout(function () {
                        var args = {
                            "cache": false
                        };
                        getData(args);
                    }, 500);

                } else if (workspaceFactory.activeScreen.loading_data) {
                    $scope.$broadcast("showLoader", true);
                }
            }, 10);
        }


        //console.log("--------------------Filters are loaded ---------------------")
        onLoad();


        $scope.$on("$destroy", function () {
            vm = null;
        })

        function makeParamsForExcel(filterParams) {
            var token = '';
            var action_KV = '';
            var grid_KV = '';
            var all_params_data = '';
            var display_filter_param = '';
            var screenObj={};

            var base_Url = $state.current.api.get;

            angular.forEach(filterParams, function (value, key) {
                display_filter_param = display_filter_param + '&' + 'display_' + key + '=' + value;
            });

            $scope.display_filter_data = display_filter_param;
            var tokens = base_Url.split("?");
            angular.forEach(tokens, function (value, key) {
                if (key === 1) {
                    token = value;
                }

            });

            tokens = token.split("&");
            angular.forEach(tokens, function (value, key) {
                if (value.includes('action')) {
                    action_KV = value;
                }
                if (value.includes('grid')) {
                    grid_KV = value;
                }
            });

            tokens = action_KV.split("=");
            angular.forEach(tokens, function (value, key) {
                if (key === 1)
                    $scope.action_key = value;

            });

            tokens = grid_KV.split("=");
            angular.forEach(tokens, function (value, key) {
                if (key === 1)
                    $scope.grid_key = value;

            });

            var all_params = GridFactory.getExcelUrl(filterParams);

            angular.forEach(all_params, function (value, key) {
                all_params_data = all_params_data + '&' + key + '=' + value;
            });

            $scope.all_filter_data = all_params_data;




            //console.log("screenOBJE",$scope.screenObj)

            /* -------------------------------------------------------------------------------------*/
            /* ---------- Added by Vijay to pass ME Codes to ExportToExcel instead of MeKeys--------*/
            /* -------------------------------------------------------------------------------------*/
            var filter_scenario = '';
            var selected_all_me_code = [];
            var selected_all_me_name_code = []
            if((workspaceFactory.activeScreen.filters) && (workspaceFactory.activeScreen.filters.filters)) {
            	var selectedFilters = workspaceFactory.activeScreen.filters.filters;

                /********************** re written by dvalaboju001 11/13/2019 ************************/
            	var meFilter = _.filter(selectedFilters,function(item){return item.param_name == 'parent_me_string'});
                var iterChildren = function(item){
                    _.each(item,function(value){
                        if(value.selected){
                                selected_all_me_code.push(value.mekey);
                                selected_all_me_name_code.push(value.name+"("+(value.key)+")")
                        }
                        if(value.children){
                            iterChildren(value.children);
                        }
                    });
                }
                if(meFilter.length){
                    _.each(meFilter,function(item){
                        if(item.selected && item.selected.length>0){
                                iterChildren(item.values);
                        }
                    })
                 }

            }

            var scenarioFilter = _.filter(selectedFilters,function(item){return item.param_name == 'scenario'});
        	var filingGrpFilter = _.filter(selectedFilters,function(item){return item.param_name == 'filing_key'});
        	$scope.display_scenario = scenarioFilter.length>0?scenarioFilter[0].selected.LABEL:null;
            $scope.selected_scenario_type_code =scenarioFilter.length>0?scenarioFilter[0].selected.CODE :null;
            $scope.display_filingGrp = filingGrpFilter.length>0?filingGrpFilter[0].selected.LABEL:null;
            $scope.display_all_me_code = selected_all_me_code.toString();
            $scope.screen_name = workspaceFactory.activeScreen.label;
            $scope.client_name = USER_SETTINGS.user.client.client_name;

            /* dvalaboju001 2/5/2020 */
            var labelFilterParams = {};

            if(selectedFilters && selectedFilters.length>0){
                _.each(selectedFilters,function(item){
                    for(var key in filterParams){
                        var value = filterParams[key];
                        if(item.param_name == key && item.param_name != 'parent_me_string'){
                            var filetrName = _.filter(item.values,function(i){
                                if((i.value || i.VALUE) == value){
                                    var v = i.value != undefined?i.label + '('+i.value+')': i.LABEL + '('+i.VALUE+')'
                                    labelFilterParams[item.label] = v;
                                }
                            })
                        }else if(item.param_name == 'parent_me_string'){
                            var me ;
                           if(selected_all_me_name_code && selected_all_me_name_code.length>0){
                                    me = selected_all_me_name_code.join(',')
                           }
                           labelFilterParams.ME =  me;
                        }


                    }
                });
                console.log("labelFilterParams",labelFilterParams);
            }
            screenObj= {
                report_name : workspaceFactory.activeScreen.label,
                client_name : USER_SETTINGS.user.client.client_name,
                Export_date: new Date(),
                filters : (labelFilterParams)
            }
            $scope.screenObj = JSON.stringify(screenObj)
            /* ends here dvalaboju001 2/5/2020 */

            /* -------------------------------------------------------------------------------------*/
            /* -------------------------------------------------------------------------------------*/
        }
        /* dvalaboju001 11/18/2019 */

        $scope.isFiltered = function(){
            var selectedAction = _.filter(workspaceFactory.activeScreen.data.actionTemplates,{selectedAction:true});/* check grid any grid action applied or not 3/4/2020 */
            if(workspaceFactory.activeScreen.data.tableState.colSearch.length>0 || selectedAction.length>0){
                $scope.tableState = true;
            }else{
                $scope.tableState= false;
            }

        }
    }

    function attachmentRemove(AlertService,rowData,gridData,colData,GridSaveFactory,USER_SETTINGS,$location, $document, $rootScope, $scope, $state, $uibModal, $log, workspaceFactory,$uibModalInstance) {
        var vm = this;
        var trans_json = [];

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
        if( !_.isArray(gridData)){
            var obj  = {}
            obj.row_id = gridData.rowData.object_id;
            obj.data = gridData.rowData;
            gridData = [];

            gridData.push(obj);

        }
        if (typeof rowData !== 'undefined') {
                    vm.rowData = rowData;
        }
        vm.entities  = _.clone(gridData);
        vm.deleteGridAttachment = function() {

            vm.rowData = rowData;
            if(rowData == null || rowData == undefined){
                for ( var index_ in vm.entities) {
                      if(index_ != "selected"){
                            trans_json.push({
                                           parent_key: vm.entities[index_].row_id,
                                           parent_type:workspaceFactory.activeScreen.label,
                                           doc_batch_id:vm.entities[index_].data.DOC_BATCH_ID,
                                           combination_keys:vm.entities[index_].data.COMBINATION_KEY,
                                           screen_name: workspaceFactory.activeScreen.label,
                                           object_id: vm.entities[index_].row_id,
                                           combination_key: vm.entities[index_].data.COMBINATION_KEY,
                                           header_key :  vm.entities[index_].data[workspaceFactory.activeScreen.data.settings.doc_header_key],
                                           doc_keys: vm.entities[index_].data.DOC_KEYS
                            });
                      }
                }
            }
            trans_json = _.filter(trans_json,function(item){
                return item.doc_batch_id != null;
            });
            var objectContext = {
                trans_json : JSON.stringify(trans_json),
                grid_key : workspaceFactory.activeScreen.data.settings.grid_key
            }
            if(trans_json && trans_json.length==0){
                    AlertService.add("Warning","There is no documents on selected batch",4000);
                    vm.cancel();
                    return;
            }
            if(USER_SETTINGS.user.system_access_level>=3){
                GridSaveFactory.deleteBatchDocuments(objectContext).then(function (data) {
                    if(data.data.callSuccess == 1){
                        AlertService.add("success","Documents are deleted successfully",4000);
                        if(trans_json && _.isArray(trans_json) && trans_json.length<10){
                            var arg1 = _.uniqBy(trans_json,function(e){
                                return e.combination_keys;
                            })
                            var arg2= (Array.prototype.map.call(arg1, s=> s.combination_keys).toString());
                            var args = {
                                    combination_keys : arg2,
                                    gridFilter :{
                                        COMBINATION_KEY : arg2
                                    }
                            }
                            $rootScope.$emit('gridUpdate', args);
                        }else{
                            $rootScope.$broadcast('dataFilters:refreshGrid', {
                                  "refresh": true
                              });
                        }

                        $uibModalInstance.dismiss('cancel');
                    }else{
                        AlertService.add("error",data.data.errorMessage,4000);
                        $uibModalInstance.dismiss('cancel');
                    }
                })
         }else{
             AlertService.add("Warning","Please contact to System Admin",4000);
             vm.cancel();
         }


        }
    }
    function auditDocument(JsonObjectFactory,MessageFactory,GENERAL_CONFIG,AlertService,rowData,gridData,colData,GridSaveFactory,USER_SETTINGS,$location, $document, $rootScope, $scope, $state, $uibModal, $log, workspaceFactory,$uibModalInstance) {
        var vm = this;
        //fnlwi5
        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
        vm.clear = function(){
            vm.searchInput = "";
        }
        vm.getDocAudit = function(){
            var params = {
                object_id: rowData.OBJECT_ID,
            }
            MessageFactory.getComments(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=fnlwi5', params).then(function (data) {
                vm.auditData = data.jsonObject;
                console.log("adfasdf",data);
            });
        }
        vm.getDocAudit();
        vm.downloadDoc = function(fileName, commentKey,docKey){
            //vm.downloadAttachmentFile = function () {
                MessageFactory.downloadMsgAttachment(fileName, rowData.OBJECT_ID,docKey);
         // }
        }

    }

    return controllers;

});
