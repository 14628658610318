const { Alert } = require("bootstrap");
const { data } = require("jquery");

/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'moment','angular'

		],
		function(moment) {
			'use strict';

			var controllers = angular.module('app.triggerghostownershipController', [])
					.controller('triggerghostownershipController',
								['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData', 'JsonObjectFactory','USER_SETTINGS' ,
								'TriggerGhostOwnershipFactory', '$timeout', 'TbbsService', 'GENERAL_CONFIG', 'workspaceFactory', triggerghostownershipController ]);



			function triggerghostownershipController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory, USER_SETTINGS, TriggerGhostOwnershipFactory, $timeout, GENERAL_CONFIG,workspaceFactory) {

				
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.syncSaveDtls = [];
				/* var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year; */
				vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
				vm.isSaveClicked = false;
				
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				/* if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
								for(var key in vm.gridDataMultipleSelect){
									var gridObj = {};
									
									gridObj["object_id"] = vm.gridDataMultipleSelect[key].data.object_id;
									gridObj["combination_key"] = vm.gridDataMultipleSelect[key].data.COMBINATION_KEY;
									gridObj["leid"] = vm.gridDataMultipleSelect[key].data.LEID;
									gridObj["cdr_number"] = vm.gridDataMultipleSelect[key].data.CDR_NO;

									vm.syncSaveDtls.push(gridObj);
									
								}
					}

				} */
				vm.confirm_sync = function() {
					var returnClobSettingsObj = {};
					
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					
					var message = "Ownership process is triggered";
					sendDetails(returnClobSettingsObj, message);

				};

				function sendDetails(returnClobSettingsObj, message) {
					$rootScope.$broadcast("showLoader", true);
					$uibModalInstance.dismiss('cancel');
					
					$rootScope.$broadcast("showLoader", false);
					AlertService.add("info", message, 4000);
					TriggerGhostOwnershipFactory.triggerOwnership(returnClobSettingsObj).then(function(result) {
							console.log('printing result');
							console.log(result);
							if(result.data.callSuccess === '1') {
								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
									AlertService.add("error", result.data.errorMessage);
								} else {
									AlertService.add("success", "Ownership Process has been successfully completed", 4000);
								}
								
							}
						/* if (result.data.errorMessage && result.data.errorMessage !== 'null') {
								$uibModalInstance.dismiss('cancel');
							AlertService.add("error", result.data.errorMessage, 4000);
						} else {
							$rootScope.$broadcast("showLoader", false);
							AlertService.add("success", message, 4000);
						};
 */
					});
			   }
		   
			}
			return controllers;

		});