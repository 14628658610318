define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.F8858-schdC1Service',[])
        .factory("form8858ScheduleC1GroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
            ,'$q', '$http',
            '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'

            ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                ,$q, $http,
                      $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
                var factory = {};
                var returnObj = {};
                var form8858ScheduleC1GroupObjFactory ={};
                form8858ScheduleC1GroupObjFactory.url ={};
                form8858ScheduleC1GroupObjFactory.url.save_F8858_Schedule_C1 = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=jrj82n";

                form8858ScheduleC1GroupObjFactory.saveF8858ScheduleC1 = function(_data,Details){

                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var jsonSettings =  JSON.stringify(_data);
                    var jsonObj = JSON.stringify(Details);
                    //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    //               params =  _.extend({jsonSettings:jsonSettings}, _data);
                    params.process_name =  "F8858_Schedule_C1_Save";
                    var promise = $http({
                        method: "post",
                        url: form8858ScheduleC1GroupObjFactory.url.save_F8858_Schedule_C1,
                        data: params
                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });

                    return promise;
                };

                return form8858ScheduleC1GroupObjFactory;
            }]);

    return services;
});