define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartV',[])
        .factory("K3PartVFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K3PartVFactory])


    function K3PartVFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K3PartVFactory.getCurrencyCodes = function(taxYear) {
            let currencyCodes;
            let  params = {
                "action_code" : '2dokld', "tax_year" : taxYear
            };

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(response) {
                    currencyCodes = response.jsonObject;
                    console.log('currencyCodes = ', currencyCodes);
                    return currencyCodes;
                }
            );

            return promise;

        }

        K3PartVFactory.renderSK3PartVDetails = function(ctrl) {

            //part
            ctrl.part_V_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'V', SECTION_NAME:'N/A', ATTRIB_NAME: '1065_K3_V_N/A_N/A'});
            ctrl.part_V_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'V', SECTION_NAME:'N/A', ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_V_N/A_1'});
            for(let x = 0; x < ctrl.part_V_1065SK3Data.length; x++) {
                if('ATTRIB_ACCT_REFERENCE_KEY' in ctrl.part_V_1065SK3Data[x]) {
                    if (ctrl.part_V_1065SK3Data[x].ATTRIB_ACCT_REFERENCE_KEY != null)
                        if(ctrl.part_V_1065SK3Data[x].DATA_TYPE === 'dateVarious') {
                            ctrl.part_V_1065SK3Data[x]['calendar'] = {opened:false};
                            if(ctrl.part_V_1065SK3Data[x].ATTRIBUTE_VALUE != null) {
                                ctrl.part_V_1065SK3Data[x].ATTRIBUTE_VALUE = new Date((ctrl.part_V_1065SK3Data[x].ATTRIBUTE_VALUE).substring(6,8)+"/"+(ctrl.part_V_1065SK3Data[x].ATTRIBUTE_VALUE).substring(4,6)+"/"+(ctrl.part_V_1065SK3Data[x].ATTRIBUTE_VALUE).substring(0,4))
                            }
                            else {
                                ctrl.part_V_1065SK3Data[x].ATTRIBUTE_VALUE = new Date();
                            }
                        }
                }
            }
            ctrl.part_V_1065SK3Data_Transposed = transpose_part_V(ctrl.part_V_1065SK3Data);
            return ctrl;
        }

        K3PartVFactory.addRowOccurrenceP5 = function(ctrl, line_no, transposedSectionData){

            let row_occurrence;

            for(let i = 0; i < transposedSectionData.length; i++) {
                if(transposedSectionData[i].ROW_TYPE == 'CH') {
                    row_occurrence = transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length -1].Row_Occurrence + 1;
                }

                let new_row = angular.copy(transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length - 1]);
                new_row.Line_Type = "DATA_ROW";
                new_row.Row_Occurrence = row_occurrence;
                new_row.IS_EDITTED = "Y";

                if(new_row.Line_no == line_no){

                    for (let j = 0; j < new_row.Line_data_occ.length; j++){
                        if ( (new_row.Line_data_occ[j].EDIT_TYPE === "currency" || new_row.Line_data_occ[j].EDIT_TYPE === "label") && new_row.Line_data_occ[j].DATA_TYPE === "number"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = 0;
                        } else if (new_row.Line_data_occ[j].EDIT_TYPE === "text" && new_row.Line_data_occ[j].DATA_TYPE === "string"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = null;
                        }
                        else if(new_row.Line_data_occ[j].EDIT_TYPE === "custom_date_various" && new_row.Line_data_occ[j].DATA_TYPE === 'dateVarious') {
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE  = new Date();
                        }
                        else if(new_row.Line_data_occ[j].EDIT_TYPE === "checkbox" && new_row.Line_data_occ[j].DATA_TYPE === 'string') {
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE  = '0';
                        }
                        new_row.Line_data_occ[j].ROW_OCCURRENCE = row_occurrence;
                        new_row.Line_data_occ[j].COL_OCCURRENCE = 1;
                        //new_row.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
                    }
                    transposedSectionData[i].Line_data.push(new_row);
                }

            }

            console.log('transposedSectionData = ' , transposedSectionData);

            return ctrl;
        }

        K3PartVFactory.removeRowOccurrenceP5 = function(ctrl, line_no, Occurrence, index, transposedSectionData) {

            console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);

            for (var i=0; i < transposedSectionData.length; i++){
                if(transposedSectionData[i].ROW_TYPE == 'CH'){
                    for(let j=0; j < transposedSectionData[i].Line_data.length; j++){
                        if(j == index){
                            if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && null != transposedSectionData[i].Line_data[j].Row_Occurrence && transposedSectionData[i].Line_data[j].Row_Occurrence != "" && transposedSectionData[i].Line_data[j].Row_Occurrence == Occurrence){

                                if(transposedSectionData[i].Line_data[j].IS_EDITTED == 'N') {
                                    transposedSectionData[i].DELETE_ROWS.push(transposedSectionData[i].Line_data[j]);
                                }
                                transposedSectionData[i].Line_data.splice(j,1);
                            }else if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
                                transposedSectionData[i].Line_data.splice(j,1);
                            }
                        }
                    }
                }
            }

            return ctrl;
        }

        K3PartVFactory.obtainChanges = function(ctrl) {

            let a;
            let b;
            let c;
            let cells = [];
            let p;

            ctrl.partVChanges = [];


            if(ctrl.checkValueEnteredInSection(ctrl.part_V_1065SK3Data_Transposed)) {
                for(let x = 0; x < ctrl.part_V_1065SK3Data_Transposed.length; x++) {
                    a = ctrl.part_V_1065SK3Data_Transposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            for(let z = 0; z < c.length; z++) {
                                if(c[z].DATA_TYPE === 'dateVarious') {
                                    let dateTaxPaid =  c[z].ATTRIBUTE_VALUE;
                                    c[z].ATTRIBUTE_VALUE = dateTaxPaid.getFullYear() + ''+((dateTaxPaid.getDate() > 9) ? dateTaxPaid.getDate() : ('0' + dateTaxPaid.getDate())) + '' + ((dateTaxPaid.getMonth() > 8) ? (dateTaxPaid.getMonth() + 1) : ('0' + (dateTaxPaid.getMonth() + 1)));
                                    break;
                                }
                            }
                            ctrl.partVChanges.push(c);
                        }
                    }
                }
            }

            if(ctrl.checkValueEnteredInSection(ctrl.part_V_1065SK3Data_Transposed)) {
                for(let x = 0; x < ctrl.part_V_1065SK3Data_Transposed.length; x++) {
                    p = ctrl.part_V_1065SK3Data_Transposed[x];
                    if(p.DELETE_ROWS.length > 0) {
                        for(let d = 0; d < p.DELETE_ROWS.length; d++) {
                            let lineData = p.DELETE_ROWS[d];
                            for(let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                            }
                            ctrl.partVChanges.push(lineData.Line_data_occ);
                        }
                    }
                }
            }

            for(let row = 0; row < ctrl.partVChanges.length; row++) {
                cells = ctrl.partVChanges[row]
                for(let c = 0; c < cells.length; c++) {
                    ctrl.saveData.push(cells[c]);
                }
            }

            return ctrl;
        }

        K3PartVFactory.validateSK3DataPartV = function(ctrl, k3Data) {

            let cell;
            let line_no;
            let lineObj;
            let lineData;
            let cellObj;
            let cellValue;
            let cellValues = [];

            if(k3Data != undefined) {

                for(let i = 0; i < k3Data.length; i++) {
                    cell = k3Data[i];
                    line_no = cell.LINE_NO;
                    if(cell.IS_CHANGED_FLAG == 'Y') {
                        if(ctrl.checkValueEnteredInSection(ctrl.part_V_1065SK3Data_Transposed)) {
                            if(cell.ATTRIB_COL == 'a') {

                                ctrl.ERROR_MESSAGE = null;
                                if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                    ctrl.ERROR_MESSAGE = 'Name of distributing foreign corporation Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                }


                            }
                            else if(cell.ATTRIB_COL == 'b') {
                                ctrl.ERROR_MESSAGE = null;
                                if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                    ctrl.ERROR_MESSAGE = 'EIN or Reference ID Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                }

                            }
                            else if(cell.ATTRIB_COL == 'd') {
                                ctrl.ERROR_MESSAGE = null;
                                if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                    ctrl.ERROR_MESSAGE = 'Functional Currency of the distributive foreign corporation Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                }
                            }

                        }
                    }

                    if(ctrl.ERROR_MESSAGE != null) {
                        ctrl.HAS_ERROR = true;
                        break;
                    }
                }
            }

            return ctrl;


        }

        return K3PartVFactory;

    }

    function transpose_part_V(partData) {
        let transposedSectionData = [];

        let o = partData.reduce((a, b) => {
            a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
            a[b.LINE_ORDER].push(b);
            return a;
        }, {});
        let a = Object.keys(o).map(function (k) {
            return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], DELETE_COLS: [],Line_data: o[k]};
        });
        transposedSectionData = a;

        let c = [];

        for (let i = 0; i < transposedSectionData.length; i++) {

            let z = transposedSectionData[i].Line_data.reduce( (a,b) => {
                a[b.ROW_OCCURRENCE] = a[b.ROW_OCCURRENCE] || [];
                a[b.ROW_OCCURRENCE].push(b);
                return a;
            }, {});

            let y = Object.keys(z).map(function (k) {
                return { Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED: "N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Row_Occurrence: /*row_occ*/ z[k][0].ROW_OCCURRENCE, Line_data_occ: z[k]};
            });

            if (i == 0) {


                let m = angular.copy(y);


                c = angular.copy(y[0]);
                c.Line_Type = "HEADER_ROW";
                c.Row_Occurrence = "";
                c.Trans_details_key = "";
                c.IS_EDITABLE = "Y";
                for (var j=0; j < c.Line_data_occ.length; j++){
                    c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
                }
                if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                    y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
                }
                else {
                    y.unshift(c);
                }


            }
            else {
                let key = Object.keys(z)[0];
                if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                    y.unshift({Line_Type: "SUB_ROW", Line_no: z[key][0].LINE_NO, Line_data_occ: []});
                }
            }

            transposedSectionData[i].Line_data = angular.copy(y);

        }

        console.log('transposedSectionData = ' , transposedSectionData);

        return transposedSectionData;
    }






    return services;
});