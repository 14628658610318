define([
    'angular',
    './schPSaveCtrl',
    './schPSaveService'



], function () {
    'use strict';
    return angular.module('app.schPSave', ['app.schPSaveCtrl','app.schPSaveService'] );


});