define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.section965TaxStmtController', [])
            .controller('Section965TaxController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'JsonObjectFactory', 'Section965TaxStmtFactory', section965TaxController]);
           


    function section965TaxController($rootScope, $scope, $http, $timeout, GlobalService, ModalFactory, AlertService, CodeComboFactory, JsonObjectFactory, Section965TaxStmtFactory) {
        var vm = this;
       
        vm.title = 'Section 965 Tax Statement';
        vm.show965Table = false;
        vm.GHOST_SCENARIO = 0;
        vm.TOTAL_AGG_FOREIGN_CASH = 0;
        vm.TOTAL_NET_SECTION965H = 0;
        vm.TOTAL_NET_SECTION965I = 0;

        vm.GE_AGG_FOREIGN_CASH = 0;
        vm.GE_TOTAL_NET_SECTION965H = 0;
        vm.GE_TOTAL_NET_SECTION965I = 0;

        vm.GECS_AGG_FOREIGN_CASH = 0;
        vm.GECS_TOTAL_NET_SECTION965H = 0;
        vm.GECS_TOTAL_NET_SECTION965I = 0;
        vm.crudLoading = false;
        vm.electionLines = [];
        vm.election = [];
       
        vm.save = function(_row, _saveType) {
            
            var row_order = _row.ROW_ORDER,
                ghost_scenario = _row.GHOST_SCENARIO,
                total_amt = 0, ge_total_amt =0, gecs_total_amt =0, election_type = "";

            
            if(row_order === 2) {
                total_amt = vm.TOTAL_AGG_FOREIGN_CASH;
                ge_total_amt = vm.GE_AGG_FOREIGN_CASH;
                gecs_total_amt = vm.GECS_AGG_FOREIGN_CASH;
                election_type = "NA";
            } else if(row_order === 7) {
                total_amt = vm.TOTAL_NET_SECTION965H;
                ge_total_amt = vm.GE_TOTAL_NET_SECTION965H;
                gecs_total_amt = vm.GECS_TOTAL_NET_SECTION965H;
                election_type = "NA";
            } else if(row_order === 9) {
                total_amt = 0;
                ge_total_amt = 0;
                gecs_total_amt = 0;
                _.forEach(vm.electionLines, function(value, key){
                

                    if(vm.election[key + 1] !== "") {
                        if(key === 0) {
                            election_type = vm.election[key + 1];
                        } else {
                            election_type = election_type + ', ' + vm.election[key + 1];
                        }
                    }
                    
                });

            } else {
                total_amt = vm.TOTAL_NET_SECTION965I;
                ge_total_amt = vm.GE_TOTAL_NET_SECTION965I;
                gecs_total_amt = vm.GECS_TOTAL_NET_SECTION965I;
                election_type = "NA"
            }

            if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            vm.crudLoading = true;

            if(row_order !== 9) {
                if (!(validateTotalAmount(row_order,total_amt, ge_total_amt, gecs_total_amt))) {
                    vm.crudLoading = false;
                    return;
                }
            }
               
            var params =  {
                ghost_scenario: ghost_scenario, 
                row_order: row_order, 
                total_amt: total_amt, 
                ge_total_amt: ge_total_amt,
                gecs_total_amt: gecs_total_amt,
                election_type: election_type
            };
            
            Section965TaxStmtFactory.saveData(params).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    vm.crudLoading = false;
                    if(row_order === 9) {
                        AlertService.add("success", "Elections have been saved successfully " , 1000);
                    } else {
                        AlertService.add("success", "Amounts have been saved successfully " , 1000);
                    }
                    
                    init();
                }
            });
        };

        function validateTotalAmount(row_order, total_amt, ge_total_amt, gecs_total_amt) {
           
            var ge_gecs_total_amt = ge_total_amt + gecs_total_amt;
            var rowName = "row_order_" + row_order + "_total";

            $scope.section965Form[rowName].$setValidity("invalidTotalAmt", true);

            if(total_amt !== ge_gecs_total_amt) {
                $scope.section965Form[rowName].$setValidity("invalidTotalAmt", false);
                return false;
            }

            
            return true;
        }
       
        function init() {
            vm.electionLines = [];
            get965TaxReturnData();
           
        };
        

        init();

        function get965TaxReturnData() {
            var filingIndex = 0;
            
            var electionObj = {};
            var startLoop = 1, endLoop = 5;
            Section965TaxStmtFactory.getSchdCData().then(function(res) {
                
                vm.schdData = res;
                
                _.forEach(vm.schdData, function (value, key) {
                    
                   if(value.ROW_ORDER === 2) {
                        vm.TOTAL_AGG_FOREIGN_CASH = value.TOTAL_AMOUNT;
                        vm.GE_AGG_FOREIGN_CASH = value.GE_TOTAL_AMOUNT;
                        vm.GECS_AGG_FOREIGN_CASH = value.GECS_TOTAL_AMOUNT;
                    } else if(value.ROW_ORDER === 7) {
                        vm.TOTAL_NET_SECTION965H = value.TOTAL_AMOUNT;
                        vm.GE_TOTAL_NET_SECTION965H = value.GE_TOTAL_AMOUNT;
                        vm.GECS_TOTAL_NET_SECTION965H = value.GECS_TOTAL_AMOUNT;
                    } else if(value.ROW_ORDER === 8) {
                        vm.TOTAL_NET_SECTION965I = value.TOTAL_AMOUNT;
                        vm.GE_TOTAL_NET_SECTION965I = value.GE_TOTAL_AMOUNT;
                        vm.GECS_TOTAL_NET_SECTION965I = value.GECS_TOTAL_AMOUNT;
                    } else if(value.ROW_ORDER === 9) {
                        
                        if(value.ELECTION_TYPE_CNT > 0) {
                             electionObj = {
                                ELECTIONNO: value.ELECTION_TYPE_NUMBER,
                                ROW_ORDER: 9,
                                VALUE: value.ELECTION_TYPE,
                                GHOST_SCENARIO: value.GHOST_SCENARIO 
                            }

                            vm.electionLines.push(electionObj); 
                            vm.election[value.ELECTION_TYPE_NUMBER] = value.ELECTION_TYPE;
                            startLoop = value.ELECTION_TYPE_NUMBER + 1;
                            endLoop = 5;  
                        }

                    }
                    // SCENARIO IS SAME FOR ALL ROWS. UPDATE WIH THE LAST ONE IN THE LOOP
                    vm.GHOST_SCENARIO = value.GHOST_SCENARIO;
                   
                });

                if(startLoop <= endLoop) {
                    for(var i=startLoop; i<=5; i++) {
                            electionObj = {
                                ELECTIONNO: i,
                                ROW_ORDER: 9,
                                VALUE: "",
                                GHOST_SCENARIO: vm.GHOST_SCENARIO 
                            }
                            
                            vm.electionLines.push(electionObj);
                            vm.election[i] = "";
                    }
                }
                
                vm.show965Table = true;
               
            });
        }
       
       
    }///////////////END CTRL
    
    
       
    return controllers;


}); 