
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.CounterPartyService',[])
    .factory('CounterPartyFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        
    	var factory = {};
    	factory.formConfigData = {};
    	
    	factory.getEntityDetails=function(sso_id){
    		var params = { "action_code": 'xsivl3', "sso_id": sso_id};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
        };
        
        factory.getSourceDetails=function(sso_id){
    		var params = { "action_code": '9qlq82', "sso_id": sso_id};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
        };
        
        factory.getCountryDetails=function(sso_id){
    		var params = { "action_code": 'kwnh6r', "sso_id": sso_id};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
        };
        
        factory.getCPFormDetails=function(combination_key, cp_form_no, cp_schedule, line_no, column, form_selected, schedule_selected, part_selected, sys_acct_selected, sso_id){
    		var params = { "action_code": 'zxb20a', "sso_id": sso_id, "combination_key": combination_key, "cp_form_name": cp_form_no, "cp_schedule_name": cp_schedule, "line_no": line_no, "col_id": column, "form_no": form_selected, "schedule": schedule_selected, "part_no": part_selected, "sys_acct_selected": sys_acct_selected};
    		
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Service - getCPFormDetails after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	};
    	
    	factory.getCPSubFormDetails=function(combination_key, cp_form_no, cp_schedule, line_no, column, form_selected, schedule_selected, part_selected, sso_id){
    		var params = { "action_code": 'cug2fy', "sso_id": sso_id, "combination_key": combination_key, "cp_form_name": cp_form_no, "cp_schedule_name": cp_schedule, "line_no": line_no, "col_id": column, "form_no": form_selected, "schedule": schedule_selected, "part_no": part_selected};
    		
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Service - getCPFormDetails after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	};
    	
    	factory.getPayorDetails=function(sso_id, combination_key, form_no, schedule, line_no){
    		var params = { "action_code": 'u8rrpy', "sso_id": sso_id, "combination_key": combination_key, "form_name": form_no, "schedule_name": schedule, "classification": '', "line_no": line_no};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.getBSLADetails=function(sso_id, combination_key, payor_leid, payor_cdr_no, payor_rp_period){
    		var params = { "action_code": 'myu41i', "sso_id": sso_id, "combination_key": combination_key, "payor_leid": payor_leid, "payor_cdr_no": payor_cdr_no, "payor_rp_period": payor_rp_period};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.getIncomeCategoryDetails=function(){
    		var params = { "action_code": 'zg307b'};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.getCPEntityTBAmt=function(sso_id, combination_key, form_no, schedule, line_no, column){
    		var params = { "action_code": 'lxged7', "sso_id": sso_id, "combination_key": combination_key, "form_name": form_no, "schedule": schedule, "line_no": line_no, "column": column};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.saveCPForm=function(final_data, clob_settings, cp_form_no, cp_schedule, form_selected, schedule_selected, part_selected, line_no, column){
    		console.log("Clob_setting was done as:::::::::------------", clob_settings);
    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=w6ug7s";
    		var json_settings = JSON.stringify(clob_settings);
    		var json_data = JSON.stringify(final_data);
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		
    		var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({json_data:json_data}, params);
            params =  _.extend({json_settings:json_settings}, params);
            params.process_name =  "Counter-Party-Modal-Save";
            params.cp_form_name = cp_form_no;
            params.cp_schd_name = cp_schedule;
            params.form_selected = form_selected;
            params.schd_selected = schedule_selected;
            params.part_selected = part_selected;
            params.line_selected = line_no;
            params.col_selected = column;
            console.log(params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
    	}
        
        return factory;
        
    }]);

    return services;

});