define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.ptiSummaryReportCtrl', []);
    var controllers = module.controller('ptiSummaryReportMainCtrl',
            		['$element','menuDataService','workspaceFactory','gtapsCommonTemplateFactory','WorkflowTCFactory','$uibModal','GlobalService', 'AlertService', '$timeout', '$window', '$scope','JsonObjectFactory', '$parse', '$rootScope', 'GENERAL_CONFIG', 'ptiSummaryReportFactory', ptiSummaryReportMainCtrl]);

		function ptiSummaryReportMainCtrl(element,menuDataService,workspaceFactory, gtapsCommonTemplateFactory,WorkflowTCFactory, $uibModal, GlobalService, AlertService, $timeout, $window, $scope,JsonObjectFactory, $parse, $rootScope, GENERAL_CONFIG, ptiSummaryReportFactory) {
			var vm = this;
			vm.loading = false;
			vm.showData = false;
			vm.name = "ptiSummaryReportMainCtrl";
			$scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';

			var activeScreenEp = workspaceFactory.activeScreen;
			vm.cacheAllActiveData = {};
			
			vm.totalPtiFC;
			vm.fxRate;
			vm.ptiAmtInUSD;
			vm.usdBasisInPTI;
			vm.estimateExchangeGainLossInUSD;
			//using these parameters from common controller
			/*vm.total959c3EPPoolFuncCurr;
			vm.totalNH959c3EPPoolFuncCurr;
			vm.totalPtiFuncCurr;
			vm.total959c3EPPoolUSD;
			vm.totalNH959c3EPPoolUSD;
			vm.totalPtiUSD;
			vm.totalTaxPool;
			vm.totalNHTaxPool;
			vm.hovering959c3Ep;
			vm.hovering959c3Taxes;
			vm.totalUSDBasisInPti;
			vm.effectiveRateOn959c3TotalEp;
			vm.effectiveRateOn959c3TotalEpNH;
			vm.estimatedPtiExchangeGainLoss;
			vm.recaptureAccounts = "No";
			vm.qualifiedDeficits = "No";*/
			vm.baseCurrency = "USD";

			//labels
			vm.pageTitle = "PTI Summary Report - Income/(Deficit)";
			vm.labelSummary = "Summary";
			vm.labelUnlessNoted = "Unless otherwise noted:";
			vm.labelPtiSumCurrenciesNote = "PTI amounts are in FC           PTI Taxes amounts are in USD";
			vm.labelTotalPtiFC = "Total PTI in FC";
			vm.labelFxRate = "FX Rate";
			vm.labelPtiAmtInUSD = "PTI Amount in USD";
			vm.labelUSDBasisInPTI = "USD Basis in PTI";
			vm.labelEstimateExchangeGainLossInUSD = "Estimated Exchange Gain/(Loss) in USD";
			/*vm.labelTotal959c3EPPoolUSD = "Total Section 959(c)(3) E&P Pool (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalPtiUSD = "Total PTI (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalTaxPool = "Total Tax Pool";
			vm.labelTotalUSDBasisInPti = "Total USD Basis in PTI";
			vm.labelEffectiveRateOn959c3TotalEp = "Effective Rate on 959(c)(3) Total E&P";
			vm.labelEstimatedPtiExchangeGainLoss = "Estimated PTI Exchange Gain/(Loss)";
			vm.labelRecaptureAccts = "Recapture Accounts";*/
			vm.labelQualDefs = "Qualified Deficits";
			vm.labelTotalEPTaxes = "Total E&P / Taxes";
			vm.labelNonHoveringEPTaxes = "Non-Hovering E&P / Taxes";
			vm.labelTotalSection959c3TaxPool = "Total Section 959(c)(3) Tax Pool";
			vm.labelHovering959c3Ep = "Hovering 959(c)(3) E&P";
			vm.labelHovering959c3Taxes = "Hovering 959(c)(3) Taxes";
			vm.labelPre87EP = "Pre-87 E&P";
			vm.labelPre87Tax = "Pre-87 Taxes";
			vm.label965a = "965(a)";
			vm.label965b4= "965(b)(4)";
			vm.label959c1a= "959(c)(1)(A) - 956";
			vm.label959c1b= "959(c)(1)(B) - 956A";
			vm.label951a= "951A - GILTI";
			vm.label951a1a= "951(a)(1)(A) - SubF";
			vm.label245ae2= "245A(a)(e)(2) - Hybrid";
			vm.label959e1248= "959(e) - 1248";
			vm.label964e4= "964(e)(4) - Stock Sale";
			vm.pteptotals= "Totals";

			if (menuDataService) {
				vm.menuData = menuDataService.data;
			}
			if (angular.isUndefined(workspaceFactory.activeScreen.filter_key) || workspaceFactory.activeScreen.filter_key === null) {
				workspaceFactory.activeScreen.filter_key = 41;
			}

			//Copy this controller object data from workspaceFactory.activeScreen.data if its already available
			if (angular.isDefined(workspaceFactory.activeScreen.data) && workspaceFactory.activeScreen.data !== 'LOAD') {
				//populateCachedData(workspaceFactory.activeScreen.data);
				refreshData();
			} else if (angular.isDefined(workspaceFactory.activeScreen.data) && workspaceFactory.activeScreen.data === 'LOAD') {
				refreshData();
			} else {
				vm.hoLeid = '';
				vm.entityName = '';
				vm.hoTaxCode = '';
				vm.funcCurrency = "";
				vm.selectedHoLeidParams;

				//Data Lists
				vm.columnNames = [];
				vm.ptiTotalsData = [];

				vm.showData = false;
			}
			
			function populateCachedData(cachedData) {
				vm.hoLeid = cachedData.hoLeid;
				vm.entityName = cachedData.entityName;
				vm.hoTaxCode = cachedData.hoTaxCode;
				vm.funcCurrency = cachedData.funcCurrency;
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";

				/*vm.total959c3EPPoolFuncCurr = cachedData.total959c3EPPoolFuncCurr;
				vm.totalNH959c3EPPoolFuncCurr = cachedData.totalNH959c3EPPoolFuncCurr;
				vm.totalPtiFuncCurr = cachedData.totalPtiFuncCurr;
				vm.total959c3EPPoolUSD = cachedData.total959c3EPPoolUSD;
				vm.totalNH959c3EPPoolUSD = cachedData.totalNH959c3EPPoolUSD;
				vm.totalPtiUSD = cachedData.totalPtiUSD;
				vm.totalTaxPool = cachedData.totalTaxPool;
				vm.totalNHTaxPool = cachedData.totalNHTaxPool;
				vm.hovering959c3Ep = cachedData.hovering959c3Ep;
				vm.hovering959c3Taxes = cachedData.hovering959c3Taxes;
				vm.pre87EPAmt = cachedData.pre87EPAmt;
				vm.pre87TaxAmt = cachedData.pre87TaxAmt;
				vm.totalUSDBasisInPti = cachedData.totalUSDBasisInPti;
				vm.effectiveRateOn959c3TotalEp = cachedData.effectiveRateOn959c3TotalEp;
				vm.effectiveRateOn959c3TotalEpNH = cachedData.effectiveRateOn959c3TotalEpNH;
				vm.estimatedPtiExchangeGainLoss = cachedData.estimatedPtiExchangeGainLoss;
				vm.recaptureAccounts = cachedData.recaptureAccounts;
				vm.qualifiedDeficits = cachedData.qualifiedDeficits;*/
				vm.totalPtiFC = cachedData.totalPtiFC;
				vm.fxRate = cachedData.fxRate;
				vm.ptiAmtInUSD = cachedData.ptiAmtInUSD;
				vm.usdBasisInPTI = cachedData.usdBasisInPTI;
				vm.estimateExchangeGainLossInUSD = cachedData.estimateExchangeGainLossInUSD;
				vm.selectedHoLeidParams = cachedData.selectedHoLeidParams;
				
				
				vm.c1a245ae2_amt   = cachedData.c1a245ae2_amt;
				vm.c1a951a1a_amt = cachedData.c1a951a1a_amt;
				vm.c1a951a_amt = cachedData.c1a951a_amt;
				vm.c1a959e_amt = cachedData.c1a959e_amt;
				vm.c1a964e4_amt = cachedData.c1a964e4_amt;
				vm.c1a965a_amt = cachedData.c1a965a_amt;
				vm.c1a965b4_amt = cachedData.c1a965b4_amt;
				vm.c1ana_amt  = cachedData.c1ana_amt;
				vm.c1bna_amt  = cachedData.c1bna_amt;
				vm.c1_c2_ptep_total_amt = cachedData.c1_c2_ptep_total_amt; 
				vm.c1_c2_ptep_total_amt_usd = cachedData.c1_c2_ptep_total_amt_usd; 
				vm.c1_ptep_total_amt  = cachedData.c1_ptep_total_amt;
				vm.c2_ptep_total_amt = cachedData.c2_ptep_total_amt;
				vm.c2245ae2_amt = cachedData.c2245ae2_amt;
				vm.c2951a1a_amt = cachedData.c2951a1a_amt;
				vm.c2951a_amt = cachedData.c2951a_amt;
				vm.c2959e_amt = cachedData.c2959e_amt;
				vm.c2964e4_amt = cachedData.c2964e4_amt;
				vm.c2965a_amt = cachedData.c2965a_amt;
				vm.c2965b4_amt = cachedData.c2965b4_amt;
				vm.ptep_ex_gain_loss_245ae2 = cachedData.ptep_ex_gain_loss_245ae2; 
				vm.ptep_ex_gain_loss_951a = cachedData.ptep_ex_gain_loss_951a;
				vm.ptep_ex_gain_loss_951a1a = cachedData.ptep_ex_gain_loss_951a1a;
				vm.ptep_ex_gain_loss_965a = cachedData.ptep_ex_gain_loss_965a;
				vm.ptep_ex_gain_loss_965b4 = cachedData.ptep_ex_gain_loss_965b4;
				vm.ptep_ex_gain_loss_c1ana = cachedData.ptep_ex_gain_loss_c1ana;
				vm.ptep_ex_gain_loss_c1bna = cachedData.ptep_ex_gain_loss_c1bna;
				vm.total_245ae2_pti_amt = cachedData.total_245ae2_pti_amt;
				vm.total_951a1a_pti_amt = cachedData.total_951a1a_pti_amt;
				vm.total_951a_pti_amt = cachedData.total_951a_pti_amt;
				vm.total_964e4_fc_amt = cachedData.total_964e4_fc_amt;
				vm.total_964e4_usd_amt = cachedData.total_964e4_usd_amt;
				vm.total_965a_pti_amt = cachedData.total_965a_pti_amt;
				vm.total_965b4_pti_amt = cachedData.total_965b4_pti_amt;
				vm.total_c1ana_fc_amt = cachedData.total_c1ana_fc_amt;
				vm.total_c1ana_pti_amt = cachedData.total_c1ana_pti_amt;
				vm.total_c1ana_usd_amt = cachedData.total_c1ana_usd_amt;
				vm.total_c1bna_fc_amt = cachedData.total_c1bna_fc_amt;
				vm.total_c1bna_pti_amt = cachedData.total_c1bna_pti_amt;
				vm.total_c1bna_usd_amt = cachedData.total_c1bna_usd_amt;
				vm.total_ptep_ex_gain_loss = cachedData.total_ptep_ex_gain_loss; 
				vm.tot_245ae2_fc_amt = cachedData.tot_245ae2_fc_amt;
				vm.tot_245ae2_usd_amt = cachedData.tot_245ae2_usd_amt;
				vm.tot_951a1a_fc_amt = cachedData.tot_951a1a_fc_amt;
				vm.tot_951a1a_usd_amt = cachedData.tot_951a1a_usd_amt;
				vm.tot_951a_fc_amt = cachedData.tot_951a_fc_amt;
				vm.tot_951a_usd_amt = cachedData.tot_951a_usd_amt;
				vm.tot_959e_fc_amt = cachedData.tot_959e_fc_amt;
				vm.tot_959e_usd_amt = cachedData.tot_959e_usd_amt;
				vm.tot_965a_ptp_fc_amt = cachedData.tot_965a_ptp_fc_amt;
				vm.tot_965a_ptp_fc_usd = cachedData.tot_965a_ptp_fc_usd;
				vm.tot_965b4_ptp_fc_amt = cachedData.tot_965b4_ptp_fc_amt;
				vm.tot_965b4_ptp_usd = cachedData.tot_965b4_ptp_usd;
				vm.us_basis_total_amt = cachedData.us_basis_total_amt;

				//Data Lists
				vm.columnNames = cachedData.columnNames;
				vm.ptiTotalsData = cachedData.ptiTotalsData;
	
				
				vm.displayTable = cachedData.displayTable;
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959c1ptepfunccurr = "959(c)(1) PTEP (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959c2ptepfunccurr = "959(c)(2) PTEP (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959totalptepfunccurr = "Total PTEP (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959totalptepusd = "Total PTEP (Functional Currency = " + vm.baseCurrency + ")";
				vm.labeltotalusdbasisinptep = "Total USD Basis in PTEP";
				vm.labelestimatedptepexchangegainloss = "Estimated PTEP Exchange Gain/(Loss)";
				vm.showData = true;
			}

			// $scope.$watch(function() { 
			// 	return (angular.isDefined(workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.loading : true);
			// }, function(newValue, oldValue) {
			// 	if (newValue === false && newValue !== oldValue
			// 		&& angular.isDefined(workspaceFactory.activeScreen.filters.filters)) {
			// 		//Refresh the filters for which data is not loaded using the last filter in the ResfreshList
			// 		angular.forEach(workspaceFactory.activeScreen.filters.filters, function(filter) {
			// 			if (angular.isUndefined(filter.values) || filter.values === null) {
			// 				if (filter.refresh_list && filter.refresh_list.length) {
			// 					var refresh_array = filter.refresh_list.split(',');
			// 					var requiredFilter = _.find(workspaceFactory.activeScreen.filters.filters, {
			// 						'filter_key': refresh_array[refresh_array.length-1]
			// 					});
			// 					if (typeof requiredFilter === 'undefined' || requiredFilter.selected === null ||
			// 						_.isEmpty(requiredFilter.selected)) {
			// 						//Do Nothing
			// 					} else {
			// 						workspaceFactory.activeScreen.filters.checkDataRefresh(requiredFilter, workspaceFactory.activeScreen.filter_key);
			// 					}
			// 				}
			// 			}
			// 		});
			// 	}
			// },true);

			$scope.$on('dataFilters:refreshGrid', function (event, args) {
				refreshData();
			});

			function refreshData() {
				vm.showData = true;
				workspaceFactory.activeScreen.filters.getFilterParams();
				workspaceFactory.setCurrentFilters(workspaceFactory.activeScreen);
				workspaceFactory.activeScreen.loading_data = true;
				var filterTaxYear;
				var filterScenario;
				var filterFilingGroup;
				var filterHoLeidKey;
				var hoLeid;
				var hoCdrNo;
				var hoReportingPeriod;
				if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams)) {
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.tax_year)) {
						filterTaxYear = workspaceFactory.activeScreen.filters.filterParams.tax_year;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.scenario)) {
						filterScenario = workspaceFactory.activeScreen.filters.filterParams.scenario;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.filing_key)) {
						filterFilingGroup = workspaceFactory.activeScreen.filters.filterParams.filing_key;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key)) {
						if (workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key === null) {
							//Do Nothing
						} else {
							filterHoLeidKey = JSON.parse(workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key);
							if (angular.isDefined(filterHoLeidKey.HO_LEID)) {
								hoLeid = filterHoLeidKey.HO_LEID;
								vm.hoLeid = hoLeid;
							}
							if (angular.isDefined(filterHoLeidKey.HO_LE_NAME)) {
								vm.entityName = filterHoLeidKey.HO_LE_NAME;
							}
							if (angular.isDefined(filterHoLeidKey.HO_CDR_NO)) {
								hoCdrNo = filterHoLeidKey.HO_CDR_NO;
								vm.hoTaxCode = hoCdrNo;
							}
							if (angular.isDefined(filterHoLeidKey.HO_REPORTING_PERIOD)) {
								hoReportingPeriod = filterHoLeidKey.HO_REPORTING_PERIOD;
							}
							if (angular.isDefined(filterHoLeidKey.HO_FUNCTIONAL_CURRENCY)) {
								vm.funcCurrency = filterHoLeidKey.HO_FUNCTIONAL_CURRENCY;
							}
						}
					}
					vm.selectedHoLeidParams =  {"ho_leid":hoLeid,"ho_cdr_no":hoCdrNo,"ho_reporting_period":hoReportingPeriod, 
												"filing_key":filterFilingGroup, "tax_year":filterTaxYear,"scenario":filterScenario};
				}
				//Reset data as Entity changed
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959c1ptepfunccurr = "959(c)(1) PTEP (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959c2ptepfunccurr = "959(c)(2) PTEP (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959totalptepfunccurr = "Total PTEP (Functional Currency = " + vm.funcCurrency + ")";
				vm.label959totalptepusd = "Total PTEP (Functional Currency = " + vm.baseCurrency + ")";
				vm.labeltotalusdbasisinptep = "Total USD Basis in PTEP";
				vm.labelestimatedptepexchangegainloss = "Estimated PTEP Exchange Gain/(Loss)";
				vm.columnNames = [];
				vm.ptiTotalsData = [];
				loadGTAPSPtiSummaryReportData();
				loadGTAPSPTEPSummaryReportData();
			}

			function loadGTAPSPtiSummaryReportData()
			{
				vm.loading = true;
				ptiSummaryReportFactory.getGtapsPtiSummaryReportData(vm.selectedHoLeidParams).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
							vm.displayTable = false;
						} else {				
							vm.ptiSummaryReportData = data.jsonObject;
							vm.cacheAllActiveData.ptiSummaryReportData = data.jsonObject;
							vm.loadAllData();
							vm.loading = false;
						}
						//workspaceFactory.activeScreen.loading_data = false;
						//workspaceFactory.setCache(workspaceFactory.activeScreen, vm);
				});
			}

			function loadGTAPSPTEPSummaryReportData()
			{
				vm.loading = true;
				ptiSummaryReportFactory.getGtapsPtepSummaryReportData(vm.selectedHoLeidParams).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
							vm.displayTable = false;
						} else {				
							vm.ptepSummaryReportData = data.jsonObject;
							vm.cacheAllActiveData.ptiSummaryReportData = data.jsonObject;
							vm.loadPTEPData();
							vm.loading = false;
						}
						//workspaceFactory.activeScreen.loading_data = false;
						//workspaceFactory.setCache(workspaceFactory.activeScreen, vm);
						//console.log('PTEP', vm.ptepSummaryReportData);
				});
			}

			function resetData() {
				/*vm.total959c3EPPoolFuncCurr = 0;
				vm.totalNH959c3EPPoolFuncCurr = 0;
				vm.totalPtiFuncCurr = 0;
				vm.total959c3EPPoolUSD = 0;
				vm.totalNH959c3EPPoolUSD = 0;
				vm.totalPtiUSD = 0;
				vm.totalTaxPool = 0;
				vm.totalNHTaxPool = 0;
				vm.hovering959c3Ep = 0;
				vm.hovering959c3Taxes = 0;
				vm.totalUSDBasisInPti = 0;
				vm.effectiveRateOn959c3TotalEp = 0;
				vm.effectiveRateOn959c3TotalEpNH = 0;
				vm.estimatedPtiExchangeGainLoss = 0;
				vm.recaptureAccounts = "No";
				vm.qualifiedDeficits = "No";
				vm.pre87EPAmt = 0 ;
				vm.pre87TaxAmt = 0;*/
			}

			function resetPTEPData() {
				vm.c1a245ae2_amt   = 0;
				vm.c1a951a1a_amt = 0;
				vm.c1a951a_amt = 0;
				vm.c1a959e_amt = 0;
				vm.c1a964e4_amt = 0;
				vm.c1a965a_amt = 0;
				vm.c1a965b4_amt = 0;
				vm.c1ana_amt  = 0;
				vm.c1bna_amt  = 0;
				vm.c1_c2_ptep_total_amt = 0; 
				vm.c1_c2_ptep_total_amt_usd = 0; 
				vm.c1_ptep_total_amt  = 0;
				vm.c2_ptep_total_amt = 0;
				vm.c2245ae2_amt = 0;
				vm.c2951a1a_amt = 0;
				vm.c2951a_amt = 0;
				vm.c2959e_amt = 0;
				vm.c2964e4_amt = 0;
				vm.c2965a_amt = 0;
				vm.c2965b4_amt = 0;
				vm.ptep_ex_gain_loss_245ae2 = 0; 
				vm.ptep_ex_gain_loss_951a = 0;
				vm.ptep_ex_gain_loss_951a1a = 0;
				vm.ptep_ex_gain_loss_965a = 0;
				vm.ptep_ex_gain_loss_965b4 = 0;
				vm.ptep_ex_gain_loss_c1ana = 0;
				vm.ptep_ex_gain_loss_c1bna = 0;
				vm.total_245ae2_pti_amt = 0;
				vm.total_951a1a_pti_amt = 0;
				vm.total_951a_pti_amt = 0;
				vm.total_964e4_fc_amt = 0;
				vm.total_964e4_usd_amt = 0;
				vm.total_965a_pti_amt = 0;
				vm.total_965b4_pti_amt = 0;
				vm.total_c1ana_fc_amt = 0;
				vm.total_c1ana_pti_amt = 0;
				vm.total_c1ana_usd_amt = 0;
				vm.total_c1bna_fc_amt = 0;
				vm.total_c1bna_pti_amt = 0;
				vm.total_c1bna_usd_amt = 0;
				vm.total_ptep_ex_gain_loss = 0; 
				vm.tot_245ae2_fc_amt = 0;
				vm.tot_245ae2_usd_amt = 0;
				vm.tot_951a1a_fc_amt = 0;
				vm.tot_951a1a_usd_amt = 0;
				vm.tot_951a_fc_amt = 0;
				vm.tot_951a_usd_amt = 0;
				vm.tot_959e_fc_amt = 0;
				vm.tot_959e_usd_amt = 0;
				vm.tot_965a_ptp_fc_amt = 0;
				vm.tot_965a_ptp_fc_usd = 0;
				vm.tot_965b4_ptp_fc_amt = 0;
				vm.tot_965b4_ptp_usd = 0;
				vm.us_basis_total_amt = 0;
			}
			vm.loadAllData = function() {
				//Reset all Data
				resetData();
				vm.columnNames = [];
				vm.ptiTotalsData = [];
				vm.loading = false;

				if (vm.ptiSummaryReportData[0].hasOwnProperty("GET_FX_RATE_JSON")) {
					var fxRateJson = JSON.parse(vm.ptiSummaryReportData[0].GET_FX_RATE_JSON);
					if (fxRateJson.length > 0) {
						if (fxRateJson[0].hasOwnProperty("END_RATE")) {
							vm.fxRate = fxRateJson[0].END_RATE;
						}
					}
				}
				if (vm.ptiSummaryReportData[0].hasOwnProperty("GET_PTI_BASIS_TOT_AMT_JSON")) {
					var ptiBaisTotalJson = JSON.parse(vm.ptiSummaryReportData[0].GET_PTI_BASIS_TOT_AMT_JSON);
					if (ptiBaisTotalJson.length > 0) {
						if (ptiBaisTotalJson[0].hasOwnProperty("TOTAL")) {
							vm.usdBasisInPTI = ptiBaisTotalJson[0].TOTAL;
						}
					}
				}
				/*if (vm.ptiSummaryReportData[0].hasOwnProperty("RECAPTURE_ACCT_COUNT")) {
					 vm.recaptureAccounts = vm.ptiSummaryReportData[0].RECAPTURE_ACCT_COUNT > 0 ? "Yes" : "No"; 
					vm.recaptureAccounts = vm.ptiSummaryReportData[0].RECAPTURE_ACCT_COUNT;
				}
				if (vm.ptiSummaryReportData[0].hasOwnProperty("QUAL_DEF_COUNT")) {
					 vm.qualifiedDeficits = vm.ptiSummaryReportData[0].QUAL_DEF_COUNT > 0 ? "Yes" : "No"; 
					vm.qualifiedDeficits = vm.ptiSummaryReportData[0].QUAL_DEF_COUNT;
				}
				if (vm.ptiSummaryReportData[0].hasOwnProperty("PTI_BASIS_TOTAL_AMT")) {
					vm.totalUSDBasisInPti = vm.ptiSummaryReportData[0].PTI_BASIS_TOTAL_AMT;
				}
				if (vm.ptiSummaryReportData[0].hasOwnProperty("EP_SUMMARY_INFO_JSON")) {
					var epSummaryInfoJson = JSON.parse(vm.ptiSummaryReportData[0].EP_SUMMARY_INFO_JSON);
					if (epSummaryInfoJson.length > 0) {
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EP_FC")) {
							vm.total959c3EPPoolFuncCurr = epSummaryInfoJson[0].POST_1986C3EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3EP_FC")) {
							vm.totalNH959c3EPPoolFuncCurr = epSummaryInfoJson[0].POST_HD_1986C3EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EPUSD")) {
							vm.total959c3EPPoolUSD = epSummaryInfoJson[0].POST_1986C3EPUSD;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3EPUSD")) {
							vm.totalNH959c3EPPoolUSD = epSummaryInfoJson[0].POST_HD_1986C3EPUSD;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3TAXES")) {
							vm.totalTaxPool = epSummaryInfoJson[0].POST_1986C3TAXES;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3TAXES")) {
							vm.totalNHTaxPool = epSummaryInfoJson[0].POST_HD_1986C3TAXES;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("HD_EP_FC")) {
							vm.hovering959c3Ep = epSummaryInfoJson[0].HD_EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("HD_TAX_FC")) {
							vm.hovering959c3Taxes = epSummaryInfoJson[0].HD_TAX_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("ETRONPOST_1986C3EP")) {
							vm.effectiveRateOn959c3TotalEp = epSummaryInfoJson[0].ETRONPOST_1986C3EP;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("ETRONPOST_HD_1986C3EP")) {
							vm.effectiveRateOn959c3TotalEpNH = epSummaryInfoJson[0].ETRONPOST_HD_1986C3EP;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("PTIFC")) {
							vm.totalPtiFuncCurr = epSummaryInfoJson[0].PTIFC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("PTIUSD")) {
							vm.totalPtiUSD = epSummaryInfoJson[0].PTIUSD;
						}
					}
				}*/

				if (vm.ptiSummaryReportData[0].hasOwnProperty("PRE_TOTALS_JSON")) {
					var preTotalsJson = JSON.parse(vm.ptiSummaryReportData[0].PRE_TOTALS_JSON);
					if (preTotalsJson.length > 0) {
						if (preTotalsJson[0].hasOwnProperty("PRE_87_EP_AMT")) {
							vm.pre87EPAmt = preTotalsJson[0].PRE_87_EP_AMT;
						}
						if (preTotalsJson[0].hasOwnProperty("PRE_87_TAX_AMT")) {
							vm.pre87TaxAmt = preTotalsJson[0].PRE_87_TAX_AMT;
						}
						
					}
				}

				console.log('preTotalsJson', preTotalsJson);
				console.log(vm);
				//Calculate estimatedPtiExchangeGainLoss
				if (angular.isDefined(vm.totalPtiUSD) && angular.isDefined(vm.totalUSDBasisInPti)) {
					vm.estimatedPtiExchangeGainLoss = vm.totalPtiUSD - vm.totalUSDBasisInPti;
				}
				var index = 0;
				vm.columnNames = [];
				vm.ptiTotalsData = [];
				angular.forEach(vm.ptiSummaryReportData, function(ptiAmtRow) {
					if (index === 0) {
						//Do Nothing
					} else {
						if (vm.columnNames.length === 0) {
							angular.forEach(ptiAmtRow, function(value, columnName) {
								vm.columnNames.push(columnName);
							});
						} else if (index === (vm.ptiSummaryReportData.length-1)) {
							if (angular.isDefined(ptiAmtRow.PTI)) {
								vm.totalPtiFC = ptiAmtRow.PTI;
								vm.ptiAmtInUSD = (vm.totalPtiFC * vm.fxRate);
								vm.estimateExchangeGainLossInUSD = (vm.usdBasisInPTI - vm.ptiAmtInUSD);
								if (vm.estimateExchangeGainLossInUSD < 0) {
									vm.estimateExchangeGainLossInUSD = vm.estimateExchangeGainLossInUSD * -1;
								}
							}
						}
						vm.ptiTotalsData.push(ptiAmtRow);
					}
					index++;
				});
				//Check if table has to be displayed or not
				if (vm.columnNames && vm.columnNames.length > 1) {
					vm.displayTable = true;
				} else{
					vm.displayTable = false;
				}
			}


			vm.loadPTEPData = function() {
				//Reset all Data
				resetPTEPData();
				vm.loading = false;
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1A245AE2_AMT")) {
					vm.c1a245ae2_amt = vm.ptepSummaryReportData[0].C1A245AE2_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1A951A1A_AMT")) {
					vm.c1a951a1a_amt = vm.ptepSummaryReportData[0].C1A951A1A_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1A951A_AMT")) {
					vm.c1a951a_amt = vm.ptepSummaryReportData[0].C1A951A_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1A959E_AMT")) {
					vm.c1a959e_amt = vm.ptepSummaryReportData[0].C1A959E_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1A964E4_AMT")) {
					vm.c1a964e4_amt = vm.ptepSummaryReportData[0].C1A964E4_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1A965A_AMT")) {
					vm.c1a965a_amt = vm.ptepSummaryReportData[0].C1A965A_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1A965B4_AMT")) {
					vm.c1a965b4_amt = vm.ptepSummaryReportData[0].C1A965B4_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1ANA_AMT")) {
					vm.c1ana_amt = vm.ptepSummaryReportData[0].C1ANA_AMT;
				}

				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1BNA_AMT")) {
					vm.c1bna_amt = vm.ptepSummaryReportData[0].C1BNA_AMT;
				}

				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1_C2_PTEP_TOTAL_AMT")) {
					vm.c1_c2_ptep_total_amt = vm.ptepSummaryReportData[0].C1_C2_PTEP_TOTAL_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1_C2_PTEP_TOTAL_AMT_USD")) {
					vm.c1_c2_ptep_total_amt_usd = vm.ptepSummaryReportData[0].C1_C2_PTEP_TOTAL_AMT_USD;
				} 
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C1_PTEP_TOTAL_AMT")) {
					vm.c1_ptep_total_amt = vm.ptepSummaryReportData[0].C1_PTEP_TOTAL_AMT;
				} 

				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2_PTEP_TOTAL_AMT")) {
					vm.c2_ptep_total_amt = vm.ptepSummaryReportData[0].C2_PTEP_TOTAL_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2245AE2_AMT")) {
					vm.c2245ae2_amt = vm.ptepSummaryReportData[0].C2245AE2_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2951A1A_AMT")) {
					vm.c2951a1a_amt = vm.ptepSummaryReportData[0].C2951A1A_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2951A_AMT")) {
					vm.c2951a_amt = vm.ptepSummaryReportData[0].C2951A_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2959E_AMT")) {
					vm.c2959e_amt = vm.ptepSummaryReportData[0].C2959E_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2964E4_AMT")) {
					vm.c2964e4_amt = vm.ptepSummaryReportData[0].C2964E4_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2965A_AMT")) {
					vm.c2965a_amt = vm.ptepSummaryReportData[0].C2965A_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("C2965B4_AMT")) {
					vm.c2965b4_amt = vm.ptepSummaryReportData[0].C2965B4_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("PTEP_EX_GAIN_LOSS_245AE2")) {
					vm.ptep_ex_gain_loss_245ae2 = vm.ptepSummaryReportData[0].PTEP_EX_GAIN_LOSS_245AE2;
				}
				 
				if (vm.ptepSummaryReportData[0].hasOwnProperty("PTEP_EX_GAIN_LOSS_951A")) {
					vm.ptep_ex_gain_loss_951a = vm.ptepSummaryReportData[0].PTEP_EX_GAIN_LOSS_951A;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("PTEP_EX_GAIN_LOSS_951A1A")) {
					vm.ptep_ex_gain_loss_951a1a = vm.ptepSummaryReportData[0].PTEP_EX_GAIN_LOSS_951A1A;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("PTEP_EX_GAIN_LOSS_965A")) {
					vm.ptep_ex_gain_loss_965a = vm.ptepSummaryReportData[0].PTEP_EX_GAIN_LOSS_965A;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("PTEP_EX_GAIN_LOSS_965B4")) {
					vm.ptep_ex_gain_loss_965b4 = vm.ptepSummaryReportData[0].PTEP_EX_GAIN_LOSS_965B4;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("PTEP_EX_GAIN_LOSS_C1ANA")) {
					vm.ptep_ex_gain_loss_c1ana = vm.ptepSummaryReportData[0].PTEP_EX_GAIN_LOSS_C1ANA;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("PTEP_EX_GAIN_LOSS_C1BNA")) {
					vm.ptep_ex_gain_loss_c1bna = vm.ptepSummaryReportData[0].PTEP_EX_GAIN_LOSS_C1BNA;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_245AE2_PTI_AMT")) {
					vm.total_245ae2_pti_amt = vm.ptepSummaryReportData[0].TOTAL_245AE2_PTI_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_951A1A_PTI_AMT")) {
					vm.total_951a1a_pti_amt = vm.ptepSummaryReportData[0].TOTAL_951A1A_PTI_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_951A_PTI_AMT")) {
					vm.total_951a_pti_amt = vm.ptepSummaryReportData[0].TOTAL_951A_PTI_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_964E4_FC_AMT")) {
					vm.total_964e4_fc_amt = vm.ptepSummaryReportData[0].TOTAL_964E4_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_964E4_USD_AMT")) {
					vm.total_964e4_usd_amt = vm.ptepSummaryReportData[0].TOTAL_964E4_USD_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_965A_PTI_AMT")) {
					vm.total_965a_pti_amt = vm.ptepSummaryReportData[0].TOTAL_965A_PTI_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_965B4_PTI_AMT")) {
					vm.total_965b4_pti_amt = vm.ptepSummaryReportData[0].TOTAL_965B4_PTI_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_C1ANA_FC_AMT")) {
					vm.total_c1ana_fc_amt = vm.ptepSummaryReportData[0].TOTAL_C1ANA_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_C1ANA_PTI_AMT")) {
					vm.total_c1ana_pti_amt = vm.ptepSummaryReportData[0].TOTAL_C1ANA_PTI_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_C1ANA_USD_AMT")) {
					vm.total_c1ana_usd_amt = vm.ptepSummaryReportData[0].TOTAL_C1ANA_USD_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_C1BNA_FC_AMT")) {
					vm.total_c1bna_fc_amt = vm.ptepSummaryReportData[0].TOTAL_C1BNA_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_C1BNA_PTI_AMT")) {
					vm.total_c1bna_pti_amt = vm.ptepSummaryReportData[0].TOTAL_C1BNA_PTI_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_C1BNA_USD_AMT")) {
					vm.total_c1bna_usd_amt = vm.ptepSummaryReportData[0].TOTAL_C1BNA_USD_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOTAL_PTEP_EX_GAIN_LOSS")) {
					vm.total_ptep_ex_gain_loss = vm.ptepSummaryReportData[0].TOTAL_PTEP_EX_GAIN_LOSS;
				}
				 
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_245AE2_FC_AMT")) {
					vm.tot_245ae2_fc_amt = vm.ptepSummaryReportData[0].TOT_245AE2_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_245AE2_USD_AMT")) {
					vm.tot_245ae2_usd_amt = vm.ptepSummaryReportData[0].TOT_245AE2_USD_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_951A1A_FC_AMT")) {
					vm.tot_951a1a_fc_amt = vm.ptepSummaryReportData[0].TOT_951A1A_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_951A1A_USD_AMT")) {
					vm.tot_951a1a_usd_amt = vm.ptepSummaryReportData[0].TOT_951A1A_USD_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_951A_FC_AMT")) {
					vm.tot_951a_fc_amt = vm.ptepSummaryReportData[0].TOT_951A_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_951A_USD_AMT")) {
					vm.tot_951a_usd_amt = vm.ptepSummaryReportData[0].TOT_951A_USD_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_959E_FC_AMT")) {
					vm.tot_959e_fc_amt = vm.ptepSummaryReportData[0].TOT_959E_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_959E_USD_AMT")) {
					vm.tot_959e_usd_amt = vm.ptepSummaryReportData[0].TOT_959E_USD_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_965A_PTP_FC_AMT")) {
					vm.tot_965a_ptp_fc_amt = vm.ptepSummaryReportData[0].TOT_965A_PTP_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_965A_PTP_FC_USD")) {
					vm.tot_965a_ptp_fc_usd = vm.ptepSummaryReportData[0].TOT_965A_PTP_FC_USD;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_965B4_PTP_FC_AMT")) {
					vm.tot_965b4_ptp_fc_amt = vm.ptepSummaryReportData[0].TOT_965B4_PTP_FC_AMT;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("TOT_965B4_PTP_USD")) {
					vm.tot_965b4_ptp_usd = vm.ptepSummaryReportData[0].TOT_965B4_PTP_USD;
				}
				
				if (vm.ptepSummaryReportData[0].hasOwnProperty("US_BASIS_TOTAL_AMT")) {
					vm.us_basis_total_amt = vm.ptepSummaryReportData[0].US_BASIS_TOTAL_AMT;
				}
				vm.cacheAllActiveData.commonPtiData = gtapsCommonTemplateFactory.statusPti.jsonObject;
				  console.log("|||||||| cmmon pti data||||||||||" , vm.cacheAllActiveData.commonPtiData)
				vm.cacheAllActiveData.commonPtepData = gtapsCommonTemplateFactory.statusPtep.jsonObject;
				activeScreenEp.loading_data = false;
			    workspaceFactory.setCache(activeScreenEp, vm.cacheAllActiveData);
				
			}

			/*vm.goToScreen = function(screenName) {
				if (vm.menuData) {
					angular.forEach(vm.menuData, function (channelNode) {
						if (channelNode.label === 'Tax Compliance') {
							angular.forEach(channelNode.children, function (taskNode) {
								if (taskNode.label === 'GTAPS') {
									angular.forEach(taskNode.children, function (activityNode) {
										if (activityNode.label === screenName) {
											var _node = activityNode;
											console.log("activity:", _node);
											if (_node.children && _node.children.length) {
												_node.collapsed = !_node.collapsed;
											} else {
												menuDataService.removeSelected();
												_node.selected = true;
												menuDataService.setSelected(_node); //THIS DOES THE HIGHLIGHTING OF PARENT NODES
												$scope.currentNode = _node;
												if (_node.screens && _node.screens.length && _node.screens[0].link) {
													_node.screens[0].filters = workspaceFactory.activeScreen.filters;
													_node.screens[0].data = "LOAD";
													workspaceFactory.setActivity(_node, true);
												} else {
													$state.go('404', {}, {
														inherit: true
													});
													AlertService.add("warning", "No screens defined for this activity");
												}
											}
										}
									});
								}
							});
						}
					});
				}
			}*/
		}
});
