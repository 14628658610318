define([
    'angular'

], function () {
    'use strict';


    var controllers =  angular.module('app.allAdjustmentsController',[])
        .controller('AllAdjustmentsController',  ['$rootScope','$scope','$timeout','$uibModal','$uibModalInstance','$uibModalStack','$log','JsonObjectFactory','AlertService' ,'gridData','rowData', 'colData','CodeComboFactory','ModalFactory', allAdjustmentsController]);



    function allAdjustmentsController ($rootScope, $scope,$timeout,$uibModal,$uibModalInstance,$uibModalStack,$log,JsonObjectFactory,AlertService,gridData,rowData,colData,CodeComboFactory,ModalFactory) {
        var vm = this;
        //vm.title = "Schedule B Questions";
        vm.loading = true;
        vm.rowData = rowData;
        vm.colData = colData;
        vm.body = "";
        vm.openModal = function (modalObj,_data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            $scope.colData = _data.colData;
            $uibModalStack.close(1);
            console.log('uibModalStack', $uibModalStack.close(0));
             var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    colData: function () {
                        return vm.colData;
                    }
                }
            });

            modalInstance.result.then(function () {
                alert('modal Instance');
                console.log('console from all adjustments has been opened');
                
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , vm.rowData);
            });
        };

       vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');        
        };

        function init() {
            var openedModal = $uibModalStack.getTop();
            
            var _data = {};
            var callName;
            var reclassArr = ['Book Adjustment','11/30 CY Book Adjustment','11/30 PY Book Adjustment'];
            CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
            CodeComboFactory.toggle = false;
            CodeComboFactory.showFormBtn = false;
            vm.showElimForm = true;

            _data = {
                rowData: vm.rowData,
                colData: vm.colData
            }

           
            if(vm.rowData.TRANS_TYPE === 'Book Amount') {
                vm.body = "This modal does not work for Book Amount.";
            }
            else {
                $timeout(function() {
                    var el = document.getElementById('cancelModal');
                    angular.element(el).triggerHandler('click');
                }, 0);
                if(vm.rowData.TRANS_TYPE === 'Local Eliminations') {
                    callName = 'elimReclassAdjs';
                } else if (reclassArr.indexOf(vm.rowData.TRANS_TYPE) != -1) {
                    callName = 'reclassTaxAdj';
                } else if (vm.rowData.TRANS_TYPE === 'Local Tax Adjustment') {
                    callName = 'epTaxAdjs';
                }
                ModalFactory.getModal(callName).then(function (data) {
                    var modalObj = {};
                    modalObj.template = data.modalScreen.modal_template;
                    modalObj.controler = data.modalScreen.modal_controler;
                    modalObj.size = data.modalScreen.modal_size;
    
                    console.log('modal object ', modalObj);
                    vm.openModal(modalObj, _data);
                })
            }
            
        }

        init();
    }

    
    return controllers;

});
