define([

	'angular'

], function () {

	'use strict';

	var controllers = angular.module('app.partnershipDashboardController', [])

	.controller('PartnershipDashboardController', ['GlobalService', 'GENERAL_CONFIG','USER_SETTINGS', 'TAX_YEARS', partnershipDashboardCtrl]);
    function partnershipDashboardCtrl(GlobalService,  GENERAL_CONFIG, 
        USER_SETTINGS, TAX_YEARS) {
        var vm = this;
        const currentUser = USER_SETTINGS.user;
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
        vm.clientKey = currentUser.client_key;
        vm.globalParams = JSON.stringify(GlobalService.globalParams);
        vm.taxYears = TAX_YEARS;

	
        vm.isEnable = false;
        vm.showBackdrop = true;
        vm.enableFilter = function(){
            vm.isEnable = !vm.isEnable;
        }
    } 
	return controllers;

});