define([
    'angular',
    './PshipCodeMastControllers',
    './PshipCodeMastServices'


], function () {
    'use strict';
    return angular.module('app.codeMast', ['app.PshipCodeMastControllers','app.PshipCodeMastServices'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {


        }]);

});