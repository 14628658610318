/**
 * Created by 212544474 on 2/17/2016.
 */

define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.entitychartmapService', [])

					.factory("EntityChartFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG','USER_SETTINGS',
									function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,USER_SETTINGS) {
										var tbbsreclassFactory = {};
										tbbsreclassFactory.url = {};

										// tbbsreclassFactory.url.saveentityChartMap = GENERAL_CONFIG.base_url
										// 		+ "/saveJsonObject?action_code=p9xu04"; //h1rkx6";

										// GENERAL_CONFIG.base_url +
										// "/saveJsonObject?action_code=hhba22"
										/*
										 * var URLS = { FETCH: 'getReclass.ge',
										 * UPDATE: 'saveReclass.ge' }
										 */

										tbbsreclassFactory.getActiveScreen = function() {
											return workspaceFactory.activeScreen.screen_key;
										};

										tbbsreclassFactory.getData = function(_url, _params) {
											console.log("TEST/////////////////////////////////////////////////");
										};

										tbbsreclassFactory.saveentitychartmap = function(_data, _accounts) {
											if(_data.save_type == 'taxCode'){
											tbbsreclassFactory.url.saveentityChartMap = GENERAL_CONFIG.base_url
											+ "/saveJsonObject?action_code=p9xu04"; //h1rkx6"
											}else {
												tbbsreclassFactory.url.saveentityChartMap = GENERAL_CONFIG.base_url 
												+ "/saveJsonObject?action_code=h1rkx6"; 
											}


											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams(): {};
											var jsonObj = JSON.stringify(_accounts);
											var jsonSettings = JSON.stringify(_data);

											var params = filterParams;
											params = _.merge({},GlobalService.globalParams,params);
											params = _.extend({jsonObj : jsonObj}, params);
											params = _.extend({jsonSettings : jsonSettings}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "ENTITY_CHART_MAP_SAVE";

											var promise = $http(
													{
														method : "post",
														url : tbbsreclassFactory.url.saveentityChartMap,
														data : params
													}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										};

										tbbsreclassFactory.updateEntityChartDetails = function(data){
											let url = GENERAL_CONFIG.base_url + '/updateEntityChartDetails',params ={};
											params.ssoId = USER_SETTINGS.user.sso_id;
											params.tax_year = GlobalService.globalParams.tax_year,
											params.json_string = JSON.stringify(data);
											var promise = $http({
																method : "post",
																url : url,
																data : params
															}).then(function(response) {
																	var status = response.status;
																	return response.data;
																});

											return promise;
										};

										return tbbsreclassFactory;
									} ]);
			        
			return services;

		});