define([
    'angular',
    './system-mapping/SystemToFormMappingCtrl',
    './system-mapping/CreateSytemAccountCtrl',
    './system-mapping/EditSytemAccountCtrl',
    './system-mapping/SystemMappingService',
], function () {
    'use strict';
   return angular.module('app.masterData', ['app.SystemToFormMappingCtrl','app.CreateSytemAccountCtrl','app.EditSytemAccountCtrl','app.SystemMappingService'])
    .config(['$stateProvider','GENERAL_CONFIG', function ($stateProvider,GENERAL_CONFIG) {
        $stateProvider
            .state('admin.master-data.system-mapping', {
                url: '/system-mapping',
                templateUrl: 'app/components/admin/masterData/system-mapping/system-mapping.html',
                "controller": "SystemToFormMappingCtrl as ctrl",
                type:'custom',
                "api": {"get":GENERAL_CONFIG.base_url+"/loadGridJson?action_id=30256&grid_id=1054"},
                access: "4"
            })
    }])
    
});