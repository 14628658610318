define([
        'angular'
    ], function () {
        'use strict';
        var app = angular.module('app.EntityMgmtAppController', []);
        app.directive('dateVal', function () {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.dateVal = function (modelValue, viewValue) {
                        // if (ctrl.$isEmpty(modelValue)) {
                        //     // consider empty models to be valid
                        //     return true;
                        // }

                        console.log(viewValue, modelValue);

                        // it is invalid
                        return false;
                    };
                }
            };
        });
        app.controller('EntityMgmtController', ['$rootScope', '$scope', '$state', '$http', '$timeout', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'workspaceFactory', '$location', 'GlobalService', 'EntityMgmtAppService', '$uibModal', EntityMgmtController])
           .controller('EntityListCtrl', ['TAX_YEARS', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal', 'workspaceFactory', 'GlobalService', 'EntityMgmtAppService', entityListCtrl])
           .controller('EntityController', ['$timeout', 'TAX_YEARS', '$scope', '$state', '$parse', '$rootScope', 'AlertService', 'GlobalService', 'EntityMgmtAppService', 'workspaceFactory', '$location', 'USER_SETTINGS', entityController])
           .controller('EntityDetailsCtrl', ['$rootScope', '$scope', '$state', 'workspaceFactory', 'GlobalService', 'EntityMgmtAppService', 'AlertService', '$uibModal', '$timeout', '$sce', 'DTOptionsBuilder', 'USER_SETTINGS', entityDetailsCtrl])
           .controller('SummaryDetailsLinkCtrl', ['$rootScope', '$timeout', '$uibModalInstance', '$state', 'EntityMgmtAppService', 'workspaceFactory', 'gridData', 'AlertService', summaryDetailsLinkCtrl])
            // .controller('DiagSummaryCtrl', ['$scope', '$rootScope', '$log', '$timeout', '$state', 'TAX_YEARS', 'EntityMgmtAppService', 'GlobalService', 'workspaceFactory', 'GridFactory', 'AlertService', 'USER_SETTINGS', diagSummaryCtrl])
           .controller('DiagMaintCtrl', ['$scope', '$window', '$uibModal', 'DTOptionsBuilder', 'TAX_YEARS', 'EntityMgmtAppService', 'GlobalService', 'AlertService', 'workspaceFactory', diagMaintCtrl])

        function entityListCtrl(TAX_YEARS, $rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal,
            workspaceFactory, GlobalService, EntityMgmtAppService) {
            var vm = this;
            $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
            vm.name = "userListCtrl";
            vm.data = [];
            vm.api = $state.current.api.get;
            var custom_data_key = "AC1245678"
            vm.taxYears = TAX_YEARS;
            vm.scenairoList = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            vm.buzloding = false;
            $scope.filter = {}
            vm.filterObjs = {}
            EntityMgmtAppService.selectedLE = null;

            vm.getScenarioList = function (year) {
                vm.scenairoList = _.find(TAX_YEARS, {
                    value: year.value
                }).scenarios;
                vm.defScenario = _.filter(vm.scenairoList, {
                    default_scenario: 'Y'
                });
                vm.wfScenario = vm.defScenario[0].scenario;
                vm.filingGroup();
                vm.getBizzHierarchyFilter();
            }
            vm.filingGroup = function () {
                var params = {
                    tax_year: vm.taxYear.value,
                    scenario: vm.wfScenario,
                    jcd_key: GlobalService.globalParams.jcd_key === "" ? 250 : GlobalService.globalParams.jcd_key
                }
                EntityMgmtAppService.getFilingGroup(params).then(function (data) {
                    if (data && data.data.callSuccess === "1") {
                        vm.filingGroupList = data.data.jsonObject;
                        vm.filing_key = vm.filingGroupList[0].VALUE;
                    }
                })

            }
            vm.getBizzHierarchyFilter = function () {
                var params = {
                    tax_year: vm.taxYear.value,
                    scenario: vm.wfScenario,
                    jcd_key: GlobalService.globalParams.jcd_key === "" ? 250 : GlobalService.globalParams.jcd_key
                }
                $scope.filter.loading = true;
                $scope.filter.selected = [];
                GlobalService.getBizHeirarch(params).then(function (data) {
                    if (data.data.callSuccess === "1") {
                        vm.data = data.data.jsonObject;
                        $scope.filter.values = vm.data;
                        vm.filterObjs.loading = false;
                        $scope.filter.loading = false;

                        if (cachedData && cachedData.data) {
                            vm.filterMatch = false;
                            cachedData.data.mevalues = [];
                            cachedData.data.meSeleted = [];
                            cachedData.data.parent_me_string = '';
                        }
                        EntityMgmtAppService.setWorkflowMes($scope.filter);// sending and storing an instance to service, which would be helpful in caching the filter object before entire screen load..
                        console.log('buziness filter', $scope.filter);
                    } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                    }
                });
            }

            if (cachedData && cachedData.data) {
                vm.filterObjs.loading = false;
                vm.taxYear = _.find(TAX_YEARS, {
                    value: cachedData.data.params.tax_year
                })
                vm.wfScenario = cachedData.data.params.scenario;
                vm.filing_key = cachedData.data.params.filing_key;
                vm.scenairoList = _.find(TAX_YEARS, {
                    value: vm.taxYear.label
                }).scenarios
                $scope.filter.values = cachedData.data.mevalues;
                vm.filingGroupList = cachedData.data.filingList;
                $scope.filter.selected = cachedData.data.meSeleted;
                vm.filterMatch = true;
            } else {
                vm.filterObjs.loading = false;
                vm.taxYear = GlobalService.inputs.tax_year.selected;
                vm.scenairoList = vm.taxYear.scenarios;
                vm.wfScenario = GlobalService.globalParams.scenario;

                vm.filterMatch = true;
                vm.filingGroup();
                vm.check = _.isEqual(GlobalService.global_selected_me.values, EntityMgmtAppService.workflowMes.values);
                // gets the filter object from a stored instance(workflow service) and will only call the bizz hirarchy call if no cached object in service level..
                // global me selection and worflow screen level me selections are now independent to each other..
                if (EntityMgmtAppService.workflowMes && EntityMgmtAppService.workflowMes.values.length > 0 && vm.check == true) {
                    $scope.filter = EntityMgmtAppService.workflowMes;
                    // } else if (vm.check == false){
                    //     $scope.filter = GlobalService.global_selected_me;
                } else {
                    vm.getBizzHierarchyFilter();
                }
            }

            vm.refresh = function () {
                getData(true);
            };

            var gridUpdateListener = $rootScope.$on('gridUpdate', function (event, args) {
                getUpDate(args);
            });

            $scope.$on('$destroy', function () {
                $log.log("Unregistering listener");
                gridUpdateListener();
            });

            var getUpDate = function (args) {
                $scope.$broadcast("showDataRefresh", true);
                var params = args;
                var _filterObj = {};
                GridFactory.getDataUpdate(vm.api, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        $scope.$broadcast("showDataRefresh", false);
                        $scope.$broadcast("dataUpdate", {
                            filter: _filterObj,
                            data: data.data
                        });
                    } else {
                        $scope.$broadcast("showDataRefresh", false);
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                    }
                });
            };

            var getData = function (refresh) {
                vm.loading = true;
                vm.gridData = [];
                vm.gridData.DATA = [];
                var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

                if (cachedData && cachedData.data && !refresh) {
                    vm.data = cachedData.data.data;
                    vm.filterMatch = true;
                    // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                    $scope.$broadcast("dataLoaded", vm.data);
                    $scope.$broadcast("showLoader", false);
                    return true;
                }
                /*  if filters are changed, deleting the existing cached Grid Data*/
                else if (cachedData && cachedData.data && refresh) {
                    for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                        if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                            workspaceFactory.customDataCache.splice(i, 1);
                        }
                    }
                    console.log("cusomCache", workspaceFactory.customDataCache);
                }

                var parent_me_string = _.map($scope.filter.selected, function (item) {
                    return item.parnet;
                }).join(',');

                if (parent_me_string != "") {
                    $scope.$broadcast("showLoader", true);
                    vm.filterMatch = true;
                    var params = {
                        tax_year: vm.taxYear.value,
                        scenario: vm.wfScenario,
                        filing_key: vm.filing_key,
                        parent_me_string: parent_me_string,

                    }

                    GridFactory.getDataCustom($state.current.api.get, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            vm.data = data;
                            // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                            $scope.$broadcast("dataLoaded", data);
                            $scope.$broadcast("showLoader", false);

                            workspaceFactory.setCustomDataCache(custom_data_key, {
                                params: params,
                                filingList: vm.filingGroupList,
                                mevalues: $scope.filter.values,
                                meSeleted: $scope.filter.selected,
                                data: vm.data
                            });
                        } else {
                            AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                        }
                    });
                } else {
                    AlertService.add("warning", "Please select the ME(s) filter", 4000);
                }
            };

            $timeout(function () {
                var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
                if (cachedData && cachedData.data) {
                    getData(false);
                }
            }, 5);

            /* pencil icon function */
            $scope.ctrlFn = function (_callName, _data, _type) {
                console.log("_callName ", _callName);
                console.log("_data ", _data.rowData);
                console.log("_type ", _type);
                _data.params = {
                    tax_year: vm.taxYear.value,
                    scenario: vm.wfScenario
                };

                $rootScope.$broadcast("entity-mgmt.addTab", _data);
                // $rootScope.$broadcast("entity-mgmt.entity", _data);
            };

            //FOR ADD USER
            $scope.openModal = function (modalObj, _data) {
                $scope.rowData = _data.rowData;
                $scope.gridData = _data;
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: modalObj.template,
                    controller: modalObj.controler,
                    size: modalObj.size,
                    backdrop: modalObj.backdrop,
                    resolve: {
                        rowData: function () {
                            return $scope.rowData;
                        },
                        gridData: function () {
                            return $scope.gridData;
                        }
                    }
                });

                modalInstance.result.then(function (selectedItem) {
                    $scope.selected = selectedItem;
                }, function () {
                    $log.info('Modal dismissed at: ' + new Date());
                    $log.info('$scope.rowData', $scope.rowData);
                });
            };


            /* workflow entity mapping */
            vm.restult = [];

            $scope.$watch('filter.selected', function (newVal, oldVal) {
                vm.model = newVal
                console.log("vm.model", vm.model);
                console.log("$scope.filter", $scope.filter);
                if (!_.isEqual(newVal, oldVal)) {
                    vm.filterMatch = false;
                }
            }, true);
        }

        function entityController($timeout, TAX_YEARS, $scope, $state, $parse, $rootScope, AlertService, GlobalService, EntityMgmtAppService, workspaceFactory, $location, USER_SETTINGS) {
            var vm = this;
            var id = $state.params.id;
            if (id.length > 0) {
                // storing the params id in service level, which is used to fetch the cached data on route change(when landing from different route).
                EntityMgmtAppService.settingId(id);
            }

            vm.workflow = {};
            vm.workflow.details = {};
            // vm.workflow.steps = "";
            // $scope.steps = [];
            var custom_data_key = "EMAE324522";
            var entityData = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            var rowData = {};
            vm.percentile = 0;
            vm.params = _.find(cachedData.data, function (item) {
                return item.code === EntityMgmtAppService.selectedId;
            })

            // if ($state.params.catKey || $state.params.compKey || vm.params) {
            //     var catKey = $state.params.catKey;
            //     var compKey = $state.params.compKey;
            //     var taxYear = $state.params.taxYear || vm.params.data.rowData.TAX_YEAR;
            //     var scenario = $state.params.scenario || vm.params.data.rowData.SCENARIO;
            // }
            vm.taxYear = '';
            vm.scenario_name = '';

            /* check whether the tab is already created or not */
            vm.checkCreateTab = function () {
                if ($location.path().split('/').length > 2) {
                    if (cachedData) {
                        // data - entity data
                        // WFS_DATA - workflow data
                        if (cachedData.data) {
                            entityData = _.find(cachedData.data, function (item) {
                                return item.code.toString() === EntityMgmtAppService.selectedId;
                            });
                            // entity data
                            if (entityData) {
                                rowData = entityData.data.rowData;
                                vm.workflow.details = rowData;
                                // if (!rowData.WFS_DATA) { // entity present & workflow not present, call API to get JSON.
                                //     getWorkflowByEntity(rowData, true);
                                // } else if (rowData.WFS_DATA) { // both entity present & workflow present, use existing cache JSON.
                                //     $scope.steps = rowData.WFS_DATA;
                                //     vm.processWFSJSON();
                                // }
                            }
                        }
                    }
                    var COMBINATION_KEY = $location.path().split('/')[3];
                    // check already opened
                    if (_.find($scope.$parent.ctrl.tabs, function (item) {
                        return item.code.toString() === COMBINATION_KEY;
                    })) {
                        return;
                    }
                    // if (($scope.$parent.ctrl.tabs.length > 0 && COMBINATION_KEY.length > 0) || $state.params.id.length > 0) {
                    // var params = {
                    //     taxYear: ((entityData && entityData.data) || (entityData == "undefined")) ? entityData.data.params.tax_year : $location.path().split('/')[7],//GlobalService.globalParams.tax_year ,
                    //     scenario: ((entityData && entityData.data) || (entityData == "undefined")) ? entityData.data.params.scenario : $location.path().split('/')[8], //GlobalService.globalParams.scenario,
                    //     combinationKey: COMBINATION_KEY
                    // }
                    // EntityMgmtAppService.getEntityByID(params).then(function (data) {
                    //     if (data.data.callSuccess === "1") {
                    //         var entityData = [];
                    //         _.each(data.data.entityList, function (item) {
                    //             var keys = Object.keys(item);
                    //             var newItem = {};
                    //             for (var i = 0; i < keys.length; i++) {
                    //                 newItem[keys[i].toUpperCase()] = item[keys[i]];
                    //             }
                    //             entityData.push(newItem);
                    //         });
                    //         rowData = entityData[0];
                    //         $rootScope.$broadcast("entity-mgmt.addTab", {
                    //             rowData
                    //         });
                    //         $rootScope.$broadcast("entity-mgmt.entity", {
                    //             rowData
                    //         });
                    //         if (rowData) {
                    //             $rootScope.$broadcast("collapseLeft", function (event, data) {
                    //             });
                    //             getWorkflowByEntity(rowData, true);
                    //         } else {
                    //             $state.transitionTo('entity-mgmt.list');
                    //         }
                    //     } else {
                    //         AlertService.add("error", "error getting records");
                    //     }
                    // });
                    // }
                }
            };

            // $scope.$on("entity-mgmt.entity", function (event, data) {
            //     vm.workflow.details = data;
            //     console.log("details", vm.workflow.details);
            //     $scope.$apply(function () {
            //         vm.workflow.details = data;
            //     });
            // });
            vm.showDetails = null;

            // vm.processWFSJSON = function () {
            //     $scope.list = [];
            //     var uniqChildren = _.uniq(_.map($scope.steps, 'cat_Name'));
            //     $scope.list = _.map(uniqChildren, function (childCatName) {
            //         var children = _.filter($scope.steps, {
            //             cat_Name: childCatName
            //         });
            //         _.each(children, function (item) {
            //             item.compKey = $parse('comp_Wfscomp_Key')(item) || '';
            //         });
            //         return {
            //             title: childCatName,
            //             children: children,
            //             isNotCompleted: _.some(children, function (item) {
            //                 return item.comp_status_key !== 'C'
            //             }),
            //             catKey: $parse('cat_Wfscat_Key')(children[0]) || '',
            //         };
            //     });
            //
            //     if ($state.params.catKey) {
            //         var selectedCat = _.find($scope.list, {
            //             catKey: $state.params.catKey
            //         });
            //         if (selectedCat) {
            //             selectedCat.isOpen = true;
            //         }
            //     }
            //     $rootScope.$broadcast("workflowsteps", $scope.list);
            //     console.log($scope.list);
            //
            //     /* check the percentile to complete status  5/3/2019*/
            //     if ($scope.list) {
            //         var count = _.filter($scope.steps, {
            //             'comp_status_key': 'C'
            //         });
            //         vm.percentile = Math.round((count.length * 100) / $scope.steps.length) || 0;
            //     }
            // };

            /* get the workflow based on tax_type */
            // function getWorkflowByEntity(rowData, isReloadState) {
            //     var requestPayLoad = {
            //         clientKey: USER_SETTINGS.user.client_key,
            //         taxYear: rowData.TAX_YEAR,
            //         //  scenario: params.data.params.tax_year,
            //         scenario: rowData.SCENARIO || params.data.params.scenario,
            //         taxType: rowData.LE_TAX_TYPE,
            //         rpt: rowData.REPORTING_PERIOD,
            //         combinationKey: rowData.COMBINATION_KEY,
            //         parentMeKey: rowData.PARENT_ME_KEY,
            //         sourceSytemFlag: rowData.SOURCE_SYSTEM_FLAG,
            //     }
            //     EntityMgmtAppService.getWFEntityMapping(requestPayLoad).then(function (data) {
            //         if (data.data.callSuccess && data.data.callSuccess == "1") {
            //             if (data.data.workFlowList.length > 0) {
            //                 $scope.steps = data.data.workFlowList;
            //             } else {
            //                 AlertService.add("warning", "No workflow List assigned to this entity, please select different Entity...");
            //             }
            //
            //             rowData.WFS_DATA = _.cloneDeep($scope.steps);
            //             rowData.lastParams = _.cloneDeep($state.params);
            //             vm.processWFSJSON();
            //
            //             if (isReloadState) {
            //                 $scope.$parent.$parent.ctrl.$state.reload();
            //             }
            //         } else {
            //             AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
            //         }
            //     });
            // }

            // vm.toggleSummery = function () {
            //     vm.show_summery = !vm.show_summery;
            //     if (!vm.show_summery) {
            //         window.scrollTo(0, 20)
            //     }
            // };

            /* workflow List html toggle */
            // vm.toggleState = function (event) {
            //     if ($state.$current.name === 'entity-mgmt.entity.workflow' && $state.params.listview === 'true') {
            //         event.preventDefault();
            //         $state.go('entity-mgmt.entity.workflow', {
            //             listview: 'false',
            //         });
            //     }
            // }

            var tempTitle;
            vm.setCurrentState = function (title) {
                tempTitle = title;
            };

            /* moving tabs  */
            $scope.$watch(function () {
                return $state.$current.name;
            }, function () {
                $timeout(function () {
                    vm.currentStateTitle = tempTitle;
                    var movingTab = document.querySelector('.app-nav.wizard-tabs .wizard-tab-hilighter');
                    var menu = document.querySelectorAll('.app-nav.wizard-tabs li');
                    var selectedTab = null;
                    menu.forEach(function (item) {
                        if (item.innerText === vm.currentStateTitle) {
                            selectedTab = item
                        }
                    });
                    if (movingTab && selectedTab) {
                        $(movingTab).width(selectedTab.offsetWidth);
                        $(movingTab).height(selectedTab.offsetHeight)
                                    .css('line-height', selectedTab.offsetHeight + 'px')
                                    .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                    }
                });
            });

            vm.setCurrentState('Entity Details');
            vm.checkCreateTab();

            vm.editSave = function ($event) {
                $rootScope.$broadcast('entity.editSave');
            };

            $rootScope.$on('entity.editSuccess', () => {
                vm.isEdit = false;
            });

            vm.toggleEdit = function ($event) {
                vm.isEdit = !vm.isEdit;
                $rootScope.$broadcast('entity.toggleEdit', $event);
            };

            // if (catKey && compKey) {
            //     $state.transitionTo('entity-mgmt.entity.workflow.details', {
            //         'id': id,
            //         'catKey': catKey,
            //         'compKey': compKey,
            //         'taxYear': taxYear,
            //         'scenario': scenario
            //     });
            // } else if (rowData.lastParams && rowData.lastParams.catKey && rowData.lastParams.compKey) {
            //     $state.transitionTo('entity-mgmt.entity.workflow.details', {
            //         'id': id,
            //         'catKey': rowData.lastParams.catKey,
            //         'compKey': rowData.lastParams.compKey,
            //         'taxYear': rowData.lastParams.taxYear,
            //         'scenario': rowData.lastParams.scenario,
            //     });
            // } else {
            $state.transitionTo('entity-mgmt.entity.entity_details', {
                'id': id,
                // 'taxYear': taxYear,
                // 'scenario': scenario
            });
            // }

            // Added more checks to avoid undefined errors..
            // vm.taxYear = $location.path().split('/')[7] || (rowData && rowData.lastParams && rowData.lastParams.taxYear) ? (rowData.lastParams ? rowData.lastParams.taxYear : '') : JSON.stringify(vm.params ? vm.params.data.rowData.TAX_YEAR : '') || '';//GlobalService.globalParams.tax_year ,
            // vm.scenario = $location.path().split('/')[8] || (rowData && rowData.lastParams && rowData.lastParams.scenario) ? (rowData.lastParams ? rowData.lastParams.scenario : '') : JSON.stringify(vm.params ? vm.params.data.rowData.SCENARIO : '') || '';
            // var taxYearObjFilter = _.find(TAX_YEARS, {value: vm.taxYear});
            // vm.sceList = taxYearObjFilter ? taxYearObjFilter.scenarios : '';
            // vm.scenairo_name = vm.sceList ? _.find(vm.sceList, {scenario: vm.scenario}).scenario_desc : '';//_.filter(vm.sceList,{})//

            //setting current route in service level in workspace service.
            // var routes = {};
            // routes.prevoiusDashboard = 'entity-mgmt.entity.workflow.details';
            // workspaceFactory.setRoutes(routes);
        }

        function EntityMgmtController($rootScope, $scope, $state, $http, $timeout, ModalFactory, AlertService, JsonObjectFactory, workspaceFactory, $location, GlobalService, EntityMgmtAppService, $uibModal) {
            var vm = this;
            vm.$state = $state;
            vm.selectedLE = EntityMgmtAppService.selectedLE;
            var custom_data_key = "EMAE324522"
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            var entityArray = [];
            console.log("cachedData ================================", cachedData);
            console.log("$state ================================", $state);
            vm.tabs = [];
            /* check the cached Data has tablist of not */
            if (cachedData) {
                vm.tabs = cachedData.data;
            } else {
                workspaceFactory.setCustomDataCache(custom_data_key, vm.tabs);
            }

            /*  vm.includes = function (_link) {
                 if (vm.$state.current.name.indexOf(_link) >= 0) {
                     return true;
                 } else {
                     return false;
                 }
             } */
            $scope.$on("entity-mgmt.addTab", function (event, data) {
                vm.addTab(data);
            });

            // EntityMgmtAppService.loadingEntityList = true;
            function getEntityList() {
                return EntityMgmtAppService.getEntityList()
                                           .then((response) => {
                                               if (angular.isArray(response.data)) {
                                                   EntityMgmtAppService.entityList = response.data;
                                               } else {
                                                   AlertService.add('error', 'Something went wrong with getting the entity list, you might not be able to edit the ownership details, please reload the page and try again!');
                                               }
                                           })
                                           .finally(() => {
                                               // EntityMgmtAppService.loadingEntityList = false;
                                               $rootScope.$broadcast('entityList loaded');
                                           });
            }

            getEntityList();

            /* create Tab */
            vm.addTab = function (data) {
                if (data.rowData) {
                    var tooltip = data.rowData.LE_NAME;

                    if (_.find(vm.tabs, function (item) {
                        return item.name === data.params.tax_year + '-' + data.rowData.LEID;
                    })) {
                        $timeout(() => {
                            AlertService.add("warn", "Entity is already opened.", 3000);
                        });
                    } else {
                        var entity = {
                            name: data.params.tax_year + '-' + data.rowData.LEID,
                            code: data.rowData.LEID,
                            data: data,
                            tooltip: tooltip
                        }
                        var leid = entity.code;
                        if (!EntityMgmtAppService.entityDetailsCache[entity.name]) {
                            entity.loading = true;
                            EntityMgmtAppService.getEntityDetails(data.params.tax_year, leid)
                                                .then((response) => {
                                                    if (response.data) {
                                                        EntityMgmtAppService.entityDetailsCache[entity.name] = {};
                                                        EntityMgmtAppService.entityDetailsCache[entity.name].data = response.data;
                                                        EntityMgmtAppService.entityDetailsCache[entity.name].taxYear = data.params.tax_year;
                                                        $rootScope.$broadcast('entity_details.loaded');
                                                    } else {
                                                        EntityMgmtAppService.entityDetailsCache[entity.name] = {};
                                                        EntityMgmtAppService.entityDetailsCache[entity.name].noDataFlag = true;
                                                        $rootScope.$broadcast('entity_details.loadedNoData');
                                                        AlertService.add('warning', 'Entity Details not available for ' + leid + '!', 3000);
                                                    }
                                                })
                                                .catch((error) => {
                                                    EntityMgmtAppService.entityDetailsCache[entity.name] = {};
                                                    EntityMgmtAppService.entityDetailsCache[entity.name].noDataFlag = true;
                                                    $rootScope.$broadcast('entity_details.loadedNoData');
                                                    AlertService.add('error', 'Something went wrong while loading details for ' + leid + '!', 3000);
                                                })
                                                .finally(() => {
                                                    entity.loading = false;
                                                });

                            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
                            if (cachedData) {
                                cachedData.data.push(entity);
                            } else {
                                //  var entityArray = [];
                                entityArray.push(entity);
                                workspaceFactory.setCustomDataCache(custom_data_key, entityArray);
                            }
                        }
                        setTimeout(function () {
                            $scope.$apply();
                        }, 500);
                    }
                }
            };

            vm.clearSelectedLE = function () {
                vm.selectedLE = EntityMgmtAppService.selectedLE = null;
            };

            vm.goToTab = function (tabData, $event) {
                if (tabData) {
                    if (tabData.name === EntityMgmtAppService.selectedLE) {
                        $event.preventDefault();
                        return;
                    }
                    vm.selectedLE = EntityMgmtAppService.selectedLE = tabData.name;
                }
            };

            vm.removeTab = function (tab) {
                var index = _.findIndex(vm.tabs, tab);
                vm.tabs.splice(index, 1);
                // clear cache for the removed entity
                var leid = tab.code;
                var taxYear = tab.name.split('/')[0];
                EntityMgmtAppService.entityDetailsCache[taxYear + '-' + leid] = null;
                // redirecting to workflow list page if no tabs
                if (vm.tabs.length === 0) {
                    $timeout(function () {
                        vm.clearSelectedLE();
                        $state.transitionTo('entity-mgmt.list');
                    });
                } else {
                    var lastTab = vm.tabs[vm.tabs.length - 1];
                    vm.selectedLE = EntityMgmtAppService.selectedLE = lastTab.name;
                    $timeout(function () {
                        $state.transitionTo('entity-mgmt.entity.entity_details', {id: lastTab.name});
                    });
                }

                /**
                 * deleting the tab from cached tab list, which is no need because the tabs list has the same object as cached data list(causing double deletion).
                 * so commenting out the code...
                 * * */

                // event.stopPropogation();
                // var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
                // if (cachedData && cachedData.data) {
                //     cachedData.data.splice(index, 1);
                // }
            };

            $scope.getClasses = function (tab) {
                console.log("tab : ", tab);
                return 'wf-levelA';
            };
        }

        function entityDetailsCtrl($rootScope, $scope, $state, workspaceFactory, GlobalService, EntityMgmtAppService, AlertService, $uibModal, $timeout, $sce, DTOptionsBuilder, USER_SETTINGS) {
            var vm = this;
            vm.le = EntityMgmtAppService.selectedLE;
            vm.leid = vm.le && vm.le.split('-')[1];
            vm.entityList = EntityMgmtAppService.entityList || [];
            vm.entityListLoaded = !!EntityMgmtAppService.entityList;
            vm.entitySearchTerm = '';
            if (EntityMgmtAppService.entityDetailsCache[vm.le]) {
                if (EntityMgmtAppService.entityDetailsCache[vm.le].noDataFlag) {
                    vm.noDataFlag = true;
                } else {
                    vm.entityDetails = EntityMgmtAppService.entityDetailsCache[vm.le].data;
                    vm.taxYear = EntityMgmtAppService.entityDetailsCache[vm.le].taxYear;
                    vm.entityDetails && processDates();
                    vm.entityDetailsEdit = angular.copy(vm.entityDetails);
                }
            }
            vm.enableEdit = false;

            vm.addressList = vm.entityDetails ? vm.entityDetails.leAddressList : [];
            vm.ownershipList = vm.entityDetails ? vm.entityDetails.ownerShipList : [];
            vm.addressListEdit = angular.copy(vm.addressList);
            vm.ownershipListEdit = angular.copy(vm.ownershipList);

            vm.addressTable = {};
            vm.ownershipTable = {};

            vm.addressTableIns = {};
            vm.ownershipTableIns = {};

            vm.addressTable.options = vm.ownershipTable.options = DTOptionsBuilder.newOptions()
                                                                                  .withOption('paging', false)
                                                                                  .withOption('processing', true)
                                                                                  .withOption('autoWidth', false)
                                                                                  .withOption('searching', false)
                                                                                  .withOption('info', false);
            vm.addressTable.dtColumnDefs = [
                {orderable: true, targets: 0},
                {orderable: false, targets: '_all'}
            ];

            vm.ownershipTable.dtColumnDefs = [
                {orderable: true, targets: 0},
                {orderable: false, targets: '_all'}
            ];

            $scope.$on('entity_details.loaded', () => {
                vm.taxYear = EntityMgmtAppService.entityDetailsCache[vm.le].taxYear;
                vm.entityDetails = EntityMgmtAppService.entityDetailsCache[vm.le].data;
                vm.entityDetails && processDates();
                vm.entityDetailsEdit = angular.copy(vm.entityDetails);
                vm.addressList = vm.entityDetails ? vm.entityDetails.leAddressList : [];
                vm.ownershipList = vm.entityDetails ? vm.entityDetails.ownerShipList : [];
                vm.addressListEdit = angular.copy(vm.addressList);
                vm.ownershipListEdit = angular.copy(vm.ownershipList);
            });

            $scope.$on('entity_details.loadedNoData', () => {
                vm.noDataFlag = true;
            });

            $scope.$on('entityList loaded', () => {
                vm.entityList = EntityMgmtAppService.entityList || [];
                vm.entityListLoaded = true;
            });

            $scope.$on('entity.toggleEdit', () => {
                vm.enableEdit = !vm.enableEdit;
            });

            function processDates() {
                vm.entityDetails.date_incorporated_obj = vm.entityDetails.date_incorporated ? new Date(vm.entityDetails.date_incorporated) : null;
                vm.entityDetails.date_reincorporated_obj = vm.entityDetails.date_reincorporated ? new Date(vm.entityDetails.date_reincorporated) : null;
                vm.entityDetails.acquisition_date_obj = vm.entityDetails.acquisition_date ? new Date(vm.entityDetails.acquisition_date) : null;
                vm.entityDetails.date_created_obj = vm.entityDetails.date_created ? new Date(vm.entityDetails.date_created) : null;
                vm.entityDetails.date_sold_obj = vm.entityDetails.date_sold ? new Date(vm.entityDetails.date_sold) : null;
                vm.entityDetails.date_reacquired_obj = vm.entityDetails.date_reacquired ? new Date(vm.entityDetails.date_reacquired) : null;
                vm.entityDetails.date_strike_off_requested_obj = vm.entityDetails.date_strike_off_requested ? new Date(vm.entityDetails.date_strike_off_requested) : null;
                vm.entityDetails.date_liquidated_obj = vm.entityDetails.date_liquidated ? new Date(vm.entityDetails.date_liquidated) : null;
                vm.entityDetails.date_spawned_consumed_obj = vm.entityDetails.date_spawned_consumed ? new Date(vm.entityDetails.date_spawned_consumed) : null;
                vm.entityDetails.date_dissolved_obj = vm.entityDetails.date_dissolved ? new Date(vm.entityDetails.date_dissolved) : null;
                vm.entityDetails.ownerShipList.forEach((item) => {
                    item.start_date_obj = item.start_date ? new Date(item.start_date) : null;
                    item.end_date_obj = item.end_date ? new Date(item.end_date) : null;
                });
            }

            function convertDateToString(d) {
                // var monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                if (!d) {
                    return '';
                } else {
                    var month = '' + (d.getMonth() + 1),
                        day = '' + d.getDate(),
                        year = d.getFullYear();

                    month = month.length === 1 ? '0' + month : month;
                    day = day.length === 1 ? '0' + day : day;

                    return [month, day, year].join('/');
                }
            }

            vm.saveDetails = function (detailsForm) {
                if (detailsForm.$invalid) {
                    AlertService.add('warning', 'Invalid entry found! Please check and correct all input fields!', 4000);
                    return;
                }
                vm.entityDetailsEdit.date_incorporated = convertDateToString(vm.entityDetailsEdit.date_incorporated_obj);
                vm.entityDetailsEdit.date_reincorporated = convertDateToString(vm.entityDetailsEdit.date_reincorporated_obj);
                vm.entityDetailsEdit.acquisition_date = convertDateToString(vm.entityDetailsEdit.acquisition_date_obj);
                vm.entityDetailsEdit.date_created = convertDateToString(vm.entityDetailsEdit.date_created_obj);
                vm.entityDetailsEdit.date_sold = convertDateToString(vm.entityDetailsEdit.date_sold_obj);
                vm.entityDetailsEdit.date_reacquired = convertDateToString(vm.entityDetailsEdit.date_reacquired_obj);
                vm.entityDetailsEdit.date_strike_off_requested = convertDateToString(vm.entityDetailsEdit.date_strike_off_requested_obj);
                vm.entityDetailsEdit.date_liquidated = convertDateToString(vm.entityDetailsEdit.date_liquidated_obj);
                vm.entityDetailsEdit.date_spawned_consumed = convertDateToString(vm.entityDetailsEdit.date_spawned_consumed_obj);
                vm.entityDetailsEdit.date_dissolved = convertDateToString(vm.entityDetailsEdit.date_dissolved_obj);
                var entityDetails = (
                    ({
                        tax_year, legal_entity_id, legal_entity_name, le_short_name, gecc_entity_id, business_activity_type_desc, federal_id_number,
                        legal_entity_type_desc, tax_type_desc, alt_tax_type_desc, legal_entity_purpose, date_incorporated, date_reincorporated,
                        acquisition_date, date_created, date_sold, date_reacquired, date_strike_off_requested, date_liquidated, date_dissolved,
                        date_spawned_consumed
                    }) => ({
                        tax_year, legal_entity_id, legal_entity_name, le_short_name, gecc_entity_id, business_activity_type_desc, federal_id_number,
                        legal_entity_type_desc, tax_type_desc, alt_tax_type_desc, legal_entity_purpose, date_incorporated, date_reincorporated,
                        acquisition_date, date_created, date_sold, date_reacquired, date_strike_off_requested, date_liquidated, date_dissolved,
                        date_spawned_consumed
                    })
                )(vm.entityDetailsEdit);

                vm.savingDetails = true;
                EntityMgmtAppService.saveEntityDetails(entityDetails)
                                    .then((response) => {
                                        if (response.data.result && response.data.result.value === '1') {
                                            AlertService.add('success', 'Edit Saved Successfully!', 4000);
                                            vm.entityDetails = vm.entityDetailsEdit;
                                            vm.entityDetailsEdit = angular.copy(vm.entityDetails);
                                            vm.enableEdit = false;
                                            $rootScope.$emit('entity.editSuccess');
                                        } else {
                                            AlertService.add('error', 'Something went wrong while saving your edit, please try again!');
                                        }
                                    })
                                    .finally(() => {
                                        vm.savingDetails = false;
                                    });
            };

            vm.saveAddressDetails = function (addressForm) {
                if (addressForm.$invalid) {
                    AlertService.add('warning', 'Invalid entry found! Please check and correct all input fields!', 4000);
                    return;
                }
                vm.addressSaving = true;
                EntityMgmtAppService.saveAddressDetails(vm.taxYear, vm.leid, vm.addressListEdit)
                                    .then((response) => {
                                        if (response.data.result && response.data.result.value === '1') {
                                            EntityMgmtAppService.reloadAddressDetails(vm.taxYear, vm.leid)
                                                                .then((res) => {
                                                                    if (res.data) {
                                                                        vm.addressList = EntityMgmtAppService.entityDetailsCache[vm.le].data.addressList = res.data;
                                                                        vm.addressListEdit = angular.copy(vm.addressList);
                                                                        vm.enableEdit = false;
                                                                        $rootScope.$emit('entity.editSuccess');
                                                                        vm.addressSaved = true;
                                                                        AlertService.add('success', 'Edit Saved Successfully!', 4000);
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    AlertService.add('error', 'Something went wrong while reloading the data, to avoid data inconsistency, please refresh the page!');
                                                                })
                                                                .finally(() => {
                                                                    vm.addressSaving = false;
                                                                    vm.addressSaved = false;
                                                                });
                                        } else {
                                            AlertService.add('error', 'Something went wrong while saving your edit, please try again!');
                                        }
                                    })
            };

            vm.saveOwnershipDetails = function (ownershipForm) {
                let hasEmptyLeName = vm.ownershipListEdit.some((own) => {
                    return !own.ownership_id && !own.leName_obj;
                });
                if (ownershipForm.$invalid) {
                    AlertService.add('warning', 'Invalid entry found! Please check and correct all input fields!', 4000);
                    return;
                } else if (hasEmptyLeName) {
                    AlertService.add('warning', 'LE name is required! Please check and select from the dropdown list!', 4000);
                    return;
                }

                vm.ownershipListEdit.forEach((own) => {
                    own.start_date = convertDateToString(own.start_date_obj);
                    own.end_date = convertDateToString(own.end_date_obj);
                    own.record_date && (own.record_date = convertDateToString(new Date(own.record_date)));
                    own.leName_obj && (own.parent_le_id = own.leName_obj.legal_entity_id);
                    own.leName_obj && (own.legal_entity_id = vm.leid);
                    own.userid_last_chg = USER_SETTINGS.user.sso_id;
                });
                vm.ownershipSaving = true;
                EntityMgmtAppService.saveOwnershipDetails(vm.taxYear, vm.leid, vm.ownershipListEdit)
                                    .then((response) => {
                                        if (response.data.result && response.data.result.value === '1') {
                                            EntityMgmtAppService.reloadOwnershipDetails(vm.taxYear, vm.leid)
                                                                .then((res) => {
                                                                    if (res.data) {
                                                                        const list = res.data;
                                                                        list.forEach((item) => {
                                                                            item.start_date_obj = item.start_date ? new Date(item.start_date) : null;
                                                                            item.end_date_obj = item.end_date ? new Date(item.end_date) : null;
                                                                        });
                                                                        vm.ownershipList = EntityMgmtAppService.entityDetailsCache[vm.le].data.ownershipList = list;
                                                                        vm.ownershipListEdit = angular.copy(vm.ownershipList);
                                                                        vm.enableEdit = false;
                                                                        $rootScope.$emit('entity.editSuccess');
                                                                        vm.ownershipSaved = true;
                                                                        AlertService.add('success', 'Edit Saved Successfully!', 4000);
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    AlertService.add('error', 'Something went wrong while reloading the data, to avoid data inconsistency, please refresh the page!');
                                                                })
                                                                .finally(() => {
                                                                    vm.ownershipSaving = false;
                                                                    vm.ownershipSaved = false;
                                                                });
                                        } else {
                                            AlertService.add('error', 'Something went wrong while saving your edit, please try again!');
                                        }
                                    });
            };

            // vm.toggleInlineAddr = function (row) {
            //     !row.inlineEdit && (vm.rowCopyAddr = angular.copy(row));
            //     row.inlineEdit = !row.inlineEdit;
            // };

            // vm.saveInlineAddr = function (row, index) {
            //     vm.addressList[index] = vm.rowCopyAddr;
            // };

            vm.removeRowAddr = function (row, index) {
                if (!row.reg_address_id || row.reg_address_id === '') {
                    // unsaved row, remove from view model only
                    vm.addressListEdit.splice(index, 1);
                    return;
                }
                EntityMgmtAppService.deleteAddressDetails(vm.taxYear, vm.leid, row.reg_address_id)
                                    .then((response) => {
                                        if (response.data.callSuccess === '1') {
                                            vm.addressList.splice(index, 1);
                                            vm.addressListEdit = angular.copy(vm.addressList);
                                            AlertService.add('success', 'Row deleted successfully!', 3000);
                                        } else {
                                            AlertService.add('error', 'Something went wrong while deleting the data, please try again!');
                                        }
                                    });
            };

            vm.removeRowOwn = function (row, index) {
                if (!row.ownership_id || row.ownership_id === '') {
                    // unsaved row, remove from view model only
                    vm.ownershipListEdit.splice(index, 1);
                    return;
                }
                EntityMgmtAppService.deleteOwnershipDetails(vm.leid, row.ownership_id)
                                    .then((response) => {
                                        if (response.data.callSuccess === '1') {
                                            vm.ownershipList.splice(index, 1);
                                            vm.ownershipListEdit = angular.copy(vm.ownershipList);
                                            AlertService.add('success', 'Row deleted successfully!', 3000);
                                        } else {
                                            AlertService.add('error', 'Something went wrong while deleting the data, please try again!');
                                        }
                                    });
            };

            vm.newRow = function (tableName) {
                if (tableName === 'address') {
                    vm.addressListEdit.push({});
                } else {
                    vm.ownershipListEdit.push({});
                }
            };

            vm.openSearchDD = function (row, event) {
                row.showEntities = true;
                $timeout(() => {
                    vm.searchDDWidth = event.currentTarget.clientWidth + 'px';
                    $('.search-select')[0].focus();
                });
            };

            vm.onSearchDDBlur = function (row, event) {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    row.showEntities = false;
                    vm.entitySearchTerm = '';
                }
            };

            vm.onEntitySelect = function (row, entity) {
                row.leName_obj = entity;
            };

            vm.searchEntity = function (entity) {
                if (vm.entitySearchTerm.length > 0) {
                    return entity.leIdName.toLowerCase().includes(vm.entitySearchTerm.toLowerCase());
                }
                return true;
            };
        }

        function summaryDetailsLinkCtrl($rootScope, $timeout, $uibModalInstance, $state, EntityMgmtAppService, workspaceFactory, gridData, AlertService) {
            var vm = this;
            vm.redirect = function () {
                var leid = gridData.rowData.LEGAL_ENTITY_ID;
                var leName = gridData.rowData.LEGAL_ENTITY_NAME || '';
                var taxYear = workspaceFactory.activeScreen.filters ? workspaceFactory.activeScreen.filters.getFilterParams().tax_year : '';
                var le = taxYear + '-' + leid;
                var custom_data_key = "EMAE324522"
                var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
                var tabs = cachedData ? cachedData.data : [];
                var tab = _.find(tabs, tab => tab.name === le);
                EntityMgmtAppService.selectedLE = le;
                if (tab) {
                    $uibModalInstance.dismiss();
                    if (!EntityMgmtAppService.entityDetailsCache[le] || !EntityMgmtAppService.entityDetailsCache[le].data) {
                        EntityMgmtAppService.entityDetailsCache[le] = {};
                        EntityMgmtAppService.entityDetailsCache[le].noDataFlag = true;
                    }
                    $timeout(() => {
                        workspaceFactory.loadLink('Custom', '', '6667', '10786', null, {}, {}, '');
                        $state.transitionTo('entity-mgmt.entity.entity_details', {id: le});
                    });
                } else {
                    // open the new tab and get tab data
                    tab = {
                        name: le,
                        code: leid,
                        data: {},
                        tooltip: leName
                        // var tooltip = data.rowData.LE_NAME + " - ME:  " + data.rowData.ME_NAME + " ( " + data.rowData.ME_CODE + " )";
                    };
                    tabs.push(tab);
                    workspaceFactory.setCustomDataCache(custom_data_key, tabs);
                    $timeout(() => {
                        workspaceFactory.loadLink('Custom', '', '6667', '10786', null, {}, {}, '');
                        $state.transitionTo('entity-mgmt.entity.entity_details', {id: le});
                    });
                    tab.loading = true;
                    $uibModalInstance.dismiss();
                    EntityMgmtAppService.getEntityDetails(taxYear, leid)
                                        .then((response) => {
                                            if (response.data) {
                                                EntityMgmtAppService.entityDetailsCache[le] = {};
                                                EntityMgmtAppService.entityDetailsCache[le].data = response.data;
                                                EntityMgmtAppService.entityDetailsCache[le].taxYear = taxYear;
                                                $rootScope.$broadcast('entity_details.loaded');
                                            } else {
                                                EntityMgmtAppService.entityDetailsCache[le] = {};
                                                EntityMgmtAppService.entityDetailsCache[le].noDataFlag = true;
                                                $rootScope.$broadcast('entity_details.loadedNoData');
                                                AlertService.add('warning', 'Entity Details not available for ' + leid + '!', 3000);
                                            }
                                        })
                                        .catch((error) => {
                                            EntityMgmtAppService.entityDetailsCache[le] = {};
                                            EntityMgmtAppService.entityDetailsCache[le].noDataFlag = true;
                                            $rootScope.$broadcast('entity_details.loadedNoData');
                                            AlertService.add('error', 'Something went wrong while getting data for ' + leid + '!');
                                        })
                                        .finally(() => {
                                            tab.loading = false;
                                        });
                }
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        // function diagSummaryCtrl($scope, $rootScope, $log, $timeout, $state, TAX_YEAR, EntityMgmtAppService, GlobalService, workspaceFactory, GridFactory, AlertService, USER_SETTINGS) {
        //     var vm = this;
        //     vm.filterMatch = true;
        //     vm.taxYears = TAX_YEAR;
        //     vm.taxYear = GlobalService.inputs.tax_year.selected;
        //     const custom_data_key = 'DS1245638';
        //
        //     function getGOKs() {
        //         EntityMgmtAppService.getGOKs(vm.taxYear.value).then((response) => {
        //             if (response.data.jsonObject) {
        //                 vm.goks = response.data.jsonObject;
        //                 vm.gok = vm.goks[0];
        //             } else {
        //                 throw new Error();
        //             }
        //         }).catch((e) => {
        //             AlertService.add('error', 'Group Object Key not available!', 3000);
        //         });
        //     }
        //
        //     vm.changeTaxYear = function () {
        //         getGOKs();
        //     };
        //
        //     vm.refresh = function () {
        //         getData(true);
        //     };
        //
        //     var gridUpdateListener = $rootScope.$on('gridUpdate', function (event, args) {
        //         getUpDate(args);
        //     });
        //
        //     $scope.$on('$destroy', function () {
        //         $log.log("Unregistering listener");
        //         gridUpdateListener();
        //     });
        //
        //     var getUpDate = function (args) {
        //         $scope.$broadcast("showDataRefresh", true);
        //         var params = args;
        //         var _filterObj = {};
        //         GridFactory.getDataUpdate(vm.api, params).then(function (data) {
        //             if (data.callSuccess === "1") {
        //                 $scope.$broadcast("showDataRefresh", false);
        //                 $scope.$broadcast("dataUpdate", {
        //                     filter: _filterObj,
        //                     data: data.data
        //                 });
        //             } else {
        //                 $scope.$broadcast("showDataRefresh", false);
        //                 AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
        //             }
        //         });
        //     };
        //
        //     function getData(refresh) {
        //         vm.loading = true;
        //         vm.gridData = [];
        //         vm.gridData.DATA = [];
        //         var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
        //
        //         if (cachedData && cachedData.data && !refresh) {
        //             vm.taxYear = cachedData.data.params.taxYear;
        //             vm.goks = cachedData.data.params.goks;
        //             vm.gok = cachedData.data.params.gok;
        //             vm.data = cachedData.data.data;
        //             vm.filterMatch = true;
        //             // $rootScope.$broadcast("collapseLeft", function (event, data) {});
        //             $scope.$broadcast("dataLoaded", vm.data);
        //             $scope.$broadcast("showLoader", false);
        //             return true;
        //         }
        //         /*  if filters are changed, deleting the existing cached Grid Data*/
        //         else if (cachedData && cachedData.data && refresh) {
        //             for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
        //                 if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
        //                     workspaceFactory.customDataCache.splice(i, 1);
        //                 }
        //             }
        //             console.log("customCache", workspaceFactory.customDataCache);
        //         }
        //
        //         $scope.$broadcast("showLoader", true);
        //         vm.filterMatch = true;
        //         var params = {
        //             tax_year: vm.taxYear.value,
        //             gok: vm.gok.VALUE,
        //             sso_id: USER_SETTINGS.user.sso_id
        //         };
        //
        //         GridFactory.getDataCustom($state.current.api.get, params).then(function (data) {
        //             if (data.callSuccess === "1") {
        //                 vm.data = data;
        //                 // $rootScope.$broadcast("collapseLeft", function (event, data) {});
        //                 $scope.$broadcast("dataLoaded", data);
        //                 $scope.$broadcast("showLoader", false);
        //
        //                 workspaceFactory.setCustomDataCache(custom_data_key, {
        //                     params: {
        //                         taxYear: vm.taxYear,
        //                         gok: vm.gok,
        //                         goks: vm.goks
        //                     },
        //                     data: vm.data
        //                 });
        //             } else {
        //                 AlertService.add("error", "Error occurred fetching data. If this continues please contact support.");
        //             }
        //         });
        //     }
        //
        //     $timeout(function () {
        //         var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
        //         if (cachedData && cachedData.data) {
        //             getData(false);
        //         } else {
        //             getGOKs();
        //         }
        //     }, 5);
        // }

        function diagMaintCtrl($scope, $window, $uibModal, DTOptionsBuilder, TAX_YEAR, EntityMgmtAppService, GlobalService, AlertService, workspaceFactory) {
            var vm = this;
            $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
            vm.activeScreenLocal = workspaceFactory.activeScreen;
            vm.filtersCache = vm.activeScreenLocal.filters ? vm.activeScreenLocal.filters.getFilterParams() : {};
            vm.taxYear = vm.filtersCache ? vm.filtersCache.tax_year : null;
            vm.scenario = vm.filtersCache ? vm.filtersCache.scenario : null;
            vm.parentMeString = vm.filtersCache ? vm.filtersCache.parent_me_string : null;
            vm.diagMaintCache = vm.activeScreenLocal.data || {};
            vm.diagList = vm.diagMaintCache.diagList;
            vm.diagListCopy = vm.diagMaintCache.diagListCopy; // copy of data to show
            vm.searchTerms = {};
            vm.filterSearchTerm = '';
            vm.filter = vm.diagMaintCache.filter || {};
            vm.diagnosticsTable = {};
            vm.diagnosticsTable.options = DTOptionsBuilder.newOptions()
                                                          .withOption('paging', false)
                                                          .withOption('rowReorder', true)
                                                          .withOption('processing', true)
                                                          .withOption('autoWidth', false)
                                                          .withOption('info', false);
            vm.diagnosticsTable.dtColumnDefs = [
                {orderable: true, className: 'reorder', targets: '_all'}
            ];
            vm.diagnosticsTableIns = {};
            vm.categories = vm.diagMaintCache.categories;
            vm.subcategories = vm.diagMaintCache.subcategories;
            vm.messages = vm.diagMaintCache.messages;
            vm.entities = vm.diagMaintCache.entities;

            vm.runDiagnostics = function () {
                EntityMgmtAppService.runDiagnostics(vm.taxYear, vm.scenario)
                                    .then((response) => {
                                        if (response.data.callSuccess === '1') {
                                            AlertService.add('success', 'Job Initiation Successful!', 4000);
                                        } else {
                                            AlertService.add('error', 'Run Diagnostics Failed!');
                                        }
                                    })
                                    .catch(() => {
                                        AlertService.add('error', 'Run Diagnostics Failed!');
                                    });
            };

            $scope.$on('dataFilters:refreshGrid', function (event, data) {
                vm.getData();
            });

            function getEditPopupLists() {
                EntityMgmtAppService.getCategoryList().then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.categories = vm.diagMaintCache.categories = response.data.jsonObject;
                    } else {
                        AlertService.add('error', 'Error loading the category list!', 3000);
                    }
                }).catch((e) => {
                    AlertService.add('error', 'Error loading the category list!', 3000);
                });
                EntityMgmtAppService.getSubcategoryList().then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.subcategories = vm.diagMaintCache.subcategories = response.data.jsonObject;
                    } else {
                        AlertService.add('error', 'Error loading the subcategory list!', 3000);
                    }
                }).catch((e) => {
                    AlertService.add('error', 'Error loading the subcategory list!', 3000);
                });
                EntityMgmtAppService.getMessageList().then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.messages = vm.diagMaintCache.messages = response.data.jsonObject;
                    } else {
                        AlertService.add('error', 'Error loading the message list!', 3000);
                    }
                }).catch((e) => {
                    AlertService.add('error', 'Error loading the message list!', 3000);
                });
            }

            (!vm.categories || !vm.subcategories || !vm.messages) && getEditPopupLists();

            function getAllEntities() {

            }

            vm.changeTaxYear = function () {
                // getGOKs();
            };

            vm.getData = function () {
                getDiagList();
            };

            function getDiagList() {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                vm.taxYear = filterParams.tax_year;
                vm.scenario = filterParams.scenario;
                vm.parentMeString = filterParams.parent_me_string;

                getAllEntities();
                vm.diagListCopy = [];
                vm.loadingList = true;
                EntityMgmtAppService.getDiagList(vm.taxYear, vm.scenario, vm.parentMeString)
                                    .then((response) => {
                                        if (response.data.callSuccess === '1') {
                                            vm.diagList = response.data.jsonObject;
                                            vm.diagListCopy = angular.copy(vm.diagList);
                                            vm.diagMaintCache.diagList = vm.diagList;
                                            vm.diagMaintCache.diagListCopy = vm.diagListCopy;
                                            workspaceFactory.setCache(vm.activeScreenLocal, vm.diagMaintCache);
                                            if (vm.diagList.length === 0) {
                                                AlertService.add('warning', 'No data for the selected tax year and GOK, please try a different one.', 4000);
                                            } else {
                                                prepareFilterLists();
                                            }
                                        }
                                    }).catch((e) => {
                    AlertService.add('error', 'Error loading the grid, please try again!', 3000);
                }).finally(() => {
                    vm.loadingList = false;
                });
            }

            function prepareFilterLists() {
                var catList = vm.diagListCopy.map(({CAT_KEY, CATEGORY_DESC}) => {
                    return {key: CAT_KEY, label: CATEGORY_DESC}
                });
                vm.filter['category-filter-menu'] = {};
                vm.filter['category-filter-menu'].keys = [];

                var subcatList = vm.diagListCopy.map(({SUBCAT_KEY, SUBCATEGORY_DESC}) => {
                    return {key: SUBCAT_KEY, label: SUBCATEGORY_DESC}
                });
                vm.filter['subcat-filter-menu'] = {};
                vm.filter['subcat-filter-menu'].keys = [];

                var msgList = vm.diagListCopy.map(({DIAG_MSG}) => {
                    return {key: DIAG_MSG, label: DIAG_MSG}
                });
                vm.filter['msg-filter-menu'] = {};
                vm.filter['msg-filter-menu'].keys = [];

                var uniqueCat = [],
                    uniqueSubcat = [],
                    uniqueMsg = [];
                for (let i = 0; i < vm.diagListCopy.length; i++) {
                    if (!uniqueCat[catList[i].key]) {
                        vm.filter['category-filter-menu'].keys.push(catList[i]);
                        uniqueCat[catList[i].key] = 1;
                    }
                    if (!uniqueSubcat[subcatList[i].key]) {
                        vm.filter['subcat-filter-menu'].keys.push(subcatList[i]);
                        uniqueSubcat[subcatList[i].key] = 1;
                    }
                    if (!uniqueMsg[msgList[i].key]) {
                        vm.filter['msg-filter-menu'].keys.push(msgList[i]);
                        uniqueMsg[msgList[i].key] = 1;
                    }
                }

                // cache filters
                vm.filter['category-filter-menu'].propName = 'CAT_KEY';
                vm.filter['subcat-filter-menu'].propName = 'SUBCAT_KEY';
                vm.filter['msg-filter-menu'].propName = 'DIAG_MSG';
                vm.diagMaintCache.filter = vm.filter;
                workspaceFactory.setCache(vm.activeScreenLocal, vm.diagMaintCache);
            }

            vm.openFilter = function (filterId, $event) {
                const filterMenuRef = $(`#${filterId}`);
                filterMenuRef.css('left', 100);
                filterMenuRef.show();
                vm.closeFilters = true;
                $event.stopPropagation();
            };

            vm.onFiltersClicked = function () {
                vm.closeFilters = false;
            };

            vm.onFilterSelectAll = function (filterId, $event) {
                vm.filter[filterId].keys.forEach((key) => {
                    !key.hide && key.show && (key.selected = $event.target.checked);
                });
            };

            vm.searchInFilter = function (key) {
                if (key.selected) return true;
                const termVal = vm.filterSearchTerm;
                if (termVal.length > 0) {
                    key.show = key.label.toLowerCase().includes(termVal.toLowerCase());
                } else {
                    key.show = true;
                }
                return !key.hide && key.show;
            };

            // hide filter keys that do not belong to the resulting (filtered) dataset
            function refreshFilterKeys() {
                for (let filterName in vm.filter) {
                    const filterObj = vm.filter[filterName];
                    // only hide for non-applied filters
                    if (filterObj.applied)
                        continue;
                    filterObj.keys.forEach((key) => {
                        key.hide = !vm.diagListCopy.some((diag) => {
                            return diag[filterObj.propName] === key.key;
                        });
                    });
                }
            }

            // chaining all the filters when applying
            vm.applyFilters = function (filterNameClicked) {
                let selectedCategories = vm.filter['category-filter-menu'].keys.filter(cat => cat.selected);
                let selectedSubcats = vm.filter['subcat-filter-menu'].keys.filter(subcat => subcat.selected);
                let selectedMsgs = vm.filter['msg-filter-menu'].keys.filter(msg => msg.selected);
                if (selectedCategories.length === 0) {
                    // when no selections are made, same as select all
                    selectedCategories = vm.filter['category-filter-menu'].keys;
                    vm.filter['category-filter-menu'].applied = vm.diagMaintCache.filter['category-filter-menu'].applied = false;
                } else {
                    vm.filter['category-filter-menu'].applied = vm.diagMaintCache.filter['category-filter-menu'].applied = true;
                }
                if (selectedSubcats.length === 0) {
                    selectedSubcats = vm.filter['subcat-filter-menu'].keys;
                    vm.filter['subcat-filter-menu'].applied = vm.diagMaintCache.filter['subcat-filter-menu'].applied = false;
                } else {
                    vm.filter['subcat-filter-menu'].applied = vm.diagMaintCache.filter['subcat-filter-menu'].applied = true;
                }
                if (selectedMsgs.length === 0) {
                    selectedMsgs = vm.filter['msg-filter-menu'].keys;
                    vm.filter['msg-filter-menu'].applied = vm.diagMaintCache.filter['msg-filter-menu'].applied = false;
                } else {
                    vm.filter['msg-filter-menu'].applied = vm.diagMaintCache.filter['msg-filter-menu'].applied = true;
                }
                vm.diagListCopy = vm.diagList.filter((diag) => {
                    return selectedCategories.some(cat => cat.key === diag.CAT_KEY) &&
                        selectedSubcats.some(subcat => subcat.key === diag.SUBCAT_KEY) &&
                        selectedMsgs.some(msg => msg.key === diag.DIAG_MSG);
                });
                // Array filter creates a new array, so we need to update the cache
                vm.diagMaintCache.diagListCopy = vm.diagListCopy;
                // update unused filter keys based on the result
                refreshFilterKeys();
                workspaceFactory.setCache(vm.activeScreenLocal, vm.diagMaintCache);
                vm.closeFiltersFn();
            };

            vm.closeFiltersFn = function () {
                vm.filterSearchTerm = '';
                const filterMenusRef = $('.diag-maint-filter');
                filterMenusRef.hide();
            };

            $window.onmousedown = function () {
                if (!vm.closeFilters) {
                    vm.closeFilters = true;
                    return;
                }
                vm.closeFiltersFn();
            };

            vm.toggleDiagStatus = function (diag, enabled) {
                if (diag.DIAG_SWITCH === enabled) return;
                EntityMgmtAppService.toggleDiagStatus(vm.taxYear, vm.scenario, vm.parentMeString, diag.DIAG_KEY, enabled)
                                    .then((response) => {
                                        if (response.data.callSuccess === '1') {
                                            diag.DIAG_SWITCH = enabled;
                                            var status = enabled ? 'Enabled' : 'Disabled';
                                            AlertService.add('success', 'Diagnostic ' + status + ' Successfully!', 3000);
                                        } else {
                                            throw new Error();
                                        }
                                    })
                                    .catch((e) => {
                                        AlertService.add('error', 'Something went wrong, please try again!', 3000);
                                    });
            };

            vm.editDiagnostic = function (diag) {
                if (!diag.DIAG_SWITCH) {
                    return;
                }
                // if (!vm.entities || vm.entities.length === 0) {
                //     AlertService.add('warning', 'Entities Not Available, please reload the data and try again!', 4000);
                //     return;
                // }
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'app/components/EntityMgmtApp/edit-diag-modal.html',
                    controller: ['$uibModalInstance', '$scope', 'AlertService', 'EntityMgmtAppService',
                        function ($uibModalInstance, $scope, AlertService, EntityMgmtAppService) {
                            $scope.selectedDiagName = diag.DIAG_KEY + ' - ' + diag.DIAG_DESC;
                            $scope.categories = vm.categories;
                            $scope.subcategories = vm.subcategories;
                            $scope.messages = vm.messages;
                            $scope.selectedCat = $scope.categories.find((cat) => cat.CAT_KEY === diag.CAT_KEY);
                            $scope.selectedSubcat = $scope.subcategories.find((subcat) => subcat.SUBCAT_KEY === diag.SUBCAT_KEY);
                            $scope.selectedMsg = $scope.messages.find((msg) => msg.DIAG_MSG === diag.DIAG_MSG);
                            $scope.entities = [];
                            $scope.disabledSearchTerm = '';
                            $scope.entitySearchTerm = '';

                            $scope.loadingEntities = true;
                            EntityMgmtAppService.getAllEntities(vm.taxYear, vm.scenario, vm.parentMeString, diag.DIAG_KEY)
                                                .then((response) => {
                                                    if (response.data.callSuccess === '1') {
                                                        $scope.entities = response.data.jsonObject;
                                                    } else {
                                                        AlertService.add('error', 'Entity List not available!', 3000);
                                                    }
                                                })
                                                .catch((e) => {
                                                    AlertService.add('error', 'Error loading the entity list!', 3000);
                                                })
                                                .finally(() => {
                                                    $scope.loadingEntities = false;
                                                });

                            $scope.loadingLEs = true;
                            EntityMgmtAppService.getDisabledLEs(vm.taxYear, vm.scenario, vm.parentMeString, diag.DIAG_KEY)
                                                .then((response) => {
                                                    if (response.data.callSuccess === '1') {
                                                        $scope.disabledLEs = response.data.jsonObject;
                                                    } else {
                                                        AlertService.add('error', 'Disabled Entity List not available!', 3000);
                                                    }
                                                })
                                                .catch((e) => {
                                                    AlertService.add('error', 'Error loading the disabled entities list!', 3000);
                                                })
                                                .finally(() => {
                                                    $scope.loadingLEs = false;
                                                });

                            $scope.searchDisabled = function (entity) {
                                if ($scope.disabledSearchTerm.length > 0) {
                                    entity.show = entity.LEID.toLowerCase().includes($scope.disabledSearchTerm.toLowerCase()) ||
                                        entity.TAX_PAYER.toLowerCase().includes($scope.disabledSearchTerm.toLowerCase());
                                } else {
                                    entity.show = true;
                                }
                                return entity.show;
                            };

                            $scope.searchEntity = function (entity) {
                                if ($scope.entitySearchTerm.length > 0) {
                                    entity.show = entity.LEID.toLowerCase().includes($scope.entitySearchTerm.toLowerCase()) ||
                                        entity.TAX_PAYER.toLowerCase().includes($scope.entitySearchTerm.toLowerCase());
                                } else {
                                    entity.show = true;
                                }
                                return entity.show;
                            };

                            $scope.selectAllDisabled = function ($event) {
                                $scope.disabledLEs.forEach((entity) => {
                                    // select all filtered results
                                    entity.show && (entity.selected = $event.target.checked);
                                });
                            };

                            $scope.selectAllEntities = function ($event) {
                                $scope.entities.forEach((entity) => {
                                    // select all filtered results
                                    entity.show && (entity.selected = $event.target.checked);
                                });
                            };

                            $scope.removeEntities = function () {
                                const entitiesToRemove = $scope.disabledLEs.filter(entity => entity.selected);
                                $scope.disabledLEs = $scope.disabledLEs.filter(entity => !entity.selected);
                                entitiesToRemove.forEach((entity) => {
                                    entity.selected = false;
                                });
                                $scope.entities = $scope.entities.concat(entitiesToRemove);
                                $scope.entities.sort((a, b) => {
                                    if (a.LEID < b.LEID)
                                        return -1;
                                    else if (a.LEID > b.LEID)
                                        return 1;
                                    else
                                        return 0;
                                });
                            };

                            $scope.addEntities = function () {
                                const entitiesToRemove = $scope.entities.filter(entity => entity.selected);
                                $scope.entities = $scope.entities.filter(entity => !entity.selected);
                                entitiesToRemove.forEach((entity) => {
                                    entity.selected = false;
                                });
                                $scope.disabledLEs = $scope.disabledLEs.concat(entitiesToRemove);
                                $scope.disabledLEs.sort((a, b) => {
                                    if (a.LEID < b.LEID)
                                        return -1;
                                    else if (a.LEID > b.LEID)
                                        return 1;
                                    else
                                        return 0;
                                });
                            };

                            $scope.save = function () {
                                const leIdStr = $scope.disabledLEs.map(disabled => disabled.LEID).join(',');
                                $scope.saving = true;
                                EntityMgmtAppService.editDiagnostic(
                                    vm.taxYear,
                                    vm.scenario,
                                    vm.parentMeString,
                                    diag.DIAG_KEY,
                                    $scope.selectedCat.CAT_KEY,
                                    $scope.selectedSubcat.SUBCAT_KEY,
                                    $scope.selectedMsg.DIAG_MSG,
                                    leIdStr
                                ).then((response) => {
                                    if (response.data.callSuccess === '1') {
                                        vm.getData();
                                        AlertService.add('success', 'Diagnostic Details saved successfully!', 3000);
                                    } else {
                                        AlertService.add('error', 'Something went wrong while saving your changes, please try again!', 4000);
                                    }
                                }).catch(() => {
                                    AlertService.add('error', 'Something went wrong while saving your changes, please try again!', 4000);
                                }).finally(() => {
                                    $scope.saving = false;
                                    $scope.cancel();
                                });
                            };

                            $scope.cancel = function () {
                                $uibModalInstance.close();
                            };
                        }],
                    size: 'md'
                });
            };
        }

        return app;
    }
);
