define([
    'angular',
    './163jAutoEPDataCtrl',



], function () {
    'use strict';
    return angular.module('app.163J_Auto_EP', ['app.163jAutoEPDataCtrl'])
});



