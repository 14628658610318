define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.fir163joverridesService',[])
        .factory("fir163joverridesServiceFactory",['$q','$http','$rootScope','$log','JsonObjectFactory','AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams','GENERAL_CONFIG', function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
        	var fir163joverridesServiceFactory = {};
            var returnObj = {};
            var logged_in_user = USER_SETTINGS.user.sso_id;
            fir163joverridesServiceFactory.saveTestedUnits = function(_callName ,_data, columnName){
            	 console.log("data", _data);
            	 
            
            }

            return fir163joverridesServiceFactory;
        }]);

    return services;
});










