define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.updateChangeListController', [])
					.controller('updateChangeListDtlsController', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','updateChangeListServiceFactory', 'rowData', 'gridData', updateChangeListDtlsController])

			
			function updateChangeListDtlsController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,updateChangeListServiceFactory,rowData, gridData) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.syncSaveDtls = [];
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year;
				vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
				vm.isSaveClicked = false;
				vm.save_json = [];
				
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
								for(var key in vm.gridDataMultipleSelect){
									var gridObj = {};
									
									gridObj["le_me_key"] = vm.gridDataMultipleSelect[key].data.LE_ME_KEY;
									gridObj["data_level"] = vm.gridDataMultipleSelect[key].data.DATA_LEVEL;
									gridObj["block_type"] = vm.gridDataMultipleSelect[key].data.BLOCK_TYPE;
									gridObj["group_obj_key"] = vm.gridDataMultipleSelect[key].data.GROUP_OBJ_KEY;
									gridObj["tax_year"] = vm.gridDataMultipleSelect[key].data.TAX_YEAR;
									
									vm.syncSaveDtls.push(gridObj);
									
									vm.save_json.push({"LE_ME_KEY": vm.gridDataMultipleSelect[key].data.LE_ME_KEY,
										  "DATA_LEVEL": vm.gridDataMultipleSelect[key].data.DATA_LEVEL,
										  "BLOCK_TYPE": vm.gridDataMultipleSelect[key].data.BLOCK_TYPE,
										  "GROUP_OBJ_KEY": vm.gridDataMultipleSelect[key].data.GROUP_OBJ_KEY,
										  "TAX_YEAR": vm.gridDataMultipleSelect[key].data.TAX_YEAR});	
									
								}
					}

				} 
				
				
				  vm.fetchMaxCountEntities = function() {//GlobalService.globalParams.screen_key
			            var params = { "action_code": 'mysfkb', "screen_key": GlobalService.globalParams.screen_key};
			            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
			            	vm.aggLimit = data.jsonObject[0].ATTRIB_VALUE;
			            });
			        }
				
				  vm.fetchMaxCountEntities();

				//Rerun selected Entities
				vm.confirm_sync = function() {
					if(vm.syncSaveDtls.length > parseInt(vm.aggLimit)){
						AlertService.add("error", "Can't select more than " + vm.aggLimit + " entities");
						return;
					}
					var returnClobSettingsObj = {};
					var ecSaveDtls = [];
					var returnObj = {};
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;

					var message = "Submitted for Updating Generated On";
					sendDetails(returnClobSettingsObj,vm.save_json, message);

				};

				function sendDetails(returnClobSettingsObj, save_json, message) {
					 $rootScope.$broadcast("showLoader", true);
					 $uibModalInstance.dismiss('cancel');
					 
					 $rootScope.$broadcast("showLoader", false);
					 updateChangeListServiceFactory.updateGeneratedOn(returnClobSettingsObj,save_json).then(function(result) {
						 	console.log("Result from data was ---------------::::", result);
							if(result.data.errorMessage != 'null'){
								AlertService.add("error", data.data.errorMessage, 4000);
							}else {
								AlertService.add("success", message, 4000);
								var args = {};
								$rootScope.$emit('gridUpdate', args);
							}

						 
//								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
//									 vm.isSaveClicked = false;
//									 $uibModalInstance.dismiss('cancel');
//									AlertService.add("error", result.data.errorMessage, 4000);
//								} else {
//									$rootScope.$broadcast("showLoader", false);
//									AlertService.add("success", message, 4000);
//                            	};

							});
				}
			}
			return controllers;
		});