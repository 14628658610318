define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.ghostSourcingInputCtrl', []);
    var controllers = module.controller('ghostSourcingInputMainCtrl',
					['menuDataService','workspaceFactory','WorkflowTCFactory','$uibModal', '$uibModalInstance','GlobalService', 'AlertService', '$timeout',
					'$window', '$scope','JsonObjectFactory', '$parse', '$rootScope', 'rowData', 'colData', 'GENERAL_CONFIG', 'USER_SETTINGS','sourcingInputScreenFactory', ghostSourcingInputMainCtrl]);

		function ghostSourcingInputMainCtrl(menuDataService, workspaceFactory, WorkflowTCFactory, $uibModal, $uibModalInstance, GlobalService, AlertService, $timeout,
										 	$window, $scope, JsonObjectFactory, $parse, $rootScope, rowData, colData, GENERAL_CONFIG, USER_SETTINGS, sourcingInputScreenFactory) {
			var vm = this;
			vm.modalTitle = "Select Apportionment Base";
			$scope.angular = angular;
			vm.loading = true;
			vm.name = "ghostSourcingInputMainCtrl";
			vm.ssoId = USER_SETTINGS.user.sso_id;
			vm.rowData = rowData;
			vm.colData = colData;

			//labels
			vm.pageTitle = "GHOST Sourcing Screen - Dr/(Cr)";
			vm.labelSaveAndUpdate = "Save and Update";
			vm.labelReset = "Reset";
			vm.labelAddBasket = "Add Basket";
			vm.okLabel = "Ok";
			vm.labelCancel = "Cancel";
			vm.selectAppotionmentLabel = "Select Apportionment Base";
			vm.saveClickedMsg = "Please wait while we save this request";
			
			// //Error Texts
			vm.nonNumericAmtError = "Sourced Amount cannot be Non-numeric. Reverting it to original value.";
			vm.resetMessage = "Data has been reset.";
			vm.saveError = "Save Failed.";
			vm.saveSuccess = "Data Saved Successfully.";
			vm.saveInProgress = "Please wait while we save this request.";
			vm.noChangesMade = "No changes have been made on this screen, nothing to Save.";
			vm.sameAppBaseError =  "Selected Apportionment Base is same as the existing Apportionment Base. Please select a different one.";
			vm.sameAppBaseError_QBU =  "Selected Apportionment Base and Base level is same as the existing Apportionment Base and Base level. Please select a different one.";
			vm.totalRowReadonlyWarning = "This cell is not editable.";
			vm.grid_type = (rowData.GRID_TYPE?'QBU':'HO');
			vm.isbaseLevelDisabled = "";
			vm.BASE_LEVEL = "";									
			vm.selectedAppBase;			
			vm.selectedBasket;						
			vm.appBases = {};
			vm.baskets = {};
			vm.newBasketParams = {};
			vm.objects = [];
			vm.tableTitle = "Edit Baskets";
			vm.scenario = _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;
			vm.tableMinimiseAdd = true;
			vm.unsourced_amt_modal = vm.rowData.UNSOURCED_APPORTIONED_AMT;
			
			
				
			/*function init() {
				sourcingInputScreenFactory.getIssueId().then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
						} else {				
							vm.issueDropdown = data.jsonObject;
							vm.issueDropdown_edit = data.jsonObject;
							
			    sourcingInputScreenFactory.getExistingBaskets(vm.rowData).then(
					function(data) {
						if (data.callSuccess !== "1") {
							console.log(vm.basketTable);
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
						} else {							
							vm.disableTable = data.jsonObject.length == 0 ? true :false; 
									angular.forEach(data.jsonObject, function(val) {
										val.ISSUE_KEY= val.ISSUE_KEY.toString().trim();
									})		
							vm.basketTable = data.jsonObject;
							vm.default_basket = vm.basketTable.find(x=> x.REC_TYPE === 'D');
							console.log(vm.basketTable);
							vm.loading = false;
						}
				});
				
				
						}
				});
				};
				
			
			init();*/
			
        
	
	vm.loadBaskets = function() {
				console.log('rowData',rowData);
				
				if (vm.rowData['GGROWCOLORTOTAL'] === 1) {
					AlertService.add("warning", vm.totalRowReadonlyWarning, 4000);
					//$rootScope.$broadcast('gridRefresh', true); 
					//return;
					vm.cancel();
					return;
				} 

				sourcingInputScreenFactory.getBaskets(workspaceFactory.activeScreen.filters.getFilterParams()).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
						} else {				
							vm.baskets = data.jsonObject;
							getBasketDisplay();
							console.log(vm.baskets);
							vm.loading = false;
						}
				});
				
				sourcingInputScreenFactory.getIssueId().then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
						} else {				
							vm.issueDropdown = data.jsonObject;
							vm.issueDropdown_edit = data.jsonObject;
							
			  sourcingInputScreenFactory.getExistingBaskets(vm.rowData).then(
					function(data) {
						if (data.callSuccess !== "1") {
							console.log(vm.basketTable);
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
						} else {							
							vm.disableTable = data.jsonObject.length == 0 ? true :false; 
									angular.forEach(data.jsonObject, function(val) {
										val.ISSUE_KEY= val.ISSUE_KEY.toString().trim();
									})		
							vm.basketTable = data.jsonObject;
							vm.default_basket = vm.basketTable.find(x=> x.REC_TYPE === 'D');
							console.log(vm.basketTable);
							vm.loading = false;
						}
				           });
				
				    }
				});
				
				
			};
			
		function getBasketDisplay() {
		            for(var i = 0; i < vm.baskets.length; i++)
		            {
		                if(vm.baskets[i].CODE_DESC === vm.descriptionValue)
		                {
		                    vm.basketDesc  = vm.baskets[i].CODE_DESC;
		                    break;
		                }
		            }
		        }
		        
		        
		vm.changeBaskets = function(value1,value2){
            		vm.basketDesc = value1;
            		var basketVariable = value2;
            		vm.objects[basketVariable].basketFlag = false;
           	        }
		        
		        
		     
			
             vm.changeEditIssueId = function(value,value3){
            		vm.issueId = value;
            		var editIssueVariable = value3;
            		vm.objects[editIssueVariable].issueIdFlag = false;
           	
            }
            
          
				
		  vm.changeIssueId = function(value,value3){
            		vm.issueId = value;
            		var issueVariable = value3;
            		vm.basketTable[issueVariable].issueFlag = false;
           	
            }
            
				
			vm.change_amount = function(issue_id_enabled){
				if (issue_id_enabled == 'N'){
					
					vm.default_basket = vm.basketTable.find(x=> x.REC_TYPE === 'D');
					vm.edit_total = null;
					vm.total = null;
					
					if (vm.basketTable !== undefined){ // existing Baskets
						
						vm.edit_basket = _.filter(vm.basketTable, function(o) { 
															return (o.REC_TYPE  == 'M' )
											})
											
		            if (vm.edit_basket.length > 0  ){
							for(var i = 0 ; i < vm.edit_basket.length; i++)
		        			{
		        				vm.edit_total = vm.edit_total + parseInt(vm.edit_basket[i].AMOUNT);
		        			}		
			
						}									
						else { vm.edit_total = 0 ;
							}					
									
					}
					else {
						vm.edit_total = 0 ;
					};
					/////////////////////////////////////////
					
					
					if(vm.objects !== undefined ){ // No Baskets
					
					for(var k = 0 ; k < vm.objects.length; k++)
		        		{ 
		        		vm.total = vm.total + parseInt(vm.objects[k].basketAmount);
		        		}
		        	  	} else{
							vm.total = 0;
							
						};
						
						/////////////
						
					if (vm.default_basket !== undefined){
						
						vm.default_basket.AMOUNT = vm.rowData.ADJUSTED_BOOK - (vm.edit_total + vm.total);
						vm.default_value = vm.default_basket.AMOUNT	;
						
						}else {
						   vm.default_value = 0;
						vm.unsourced_amt_modal = vm.rowData.ADJUSTED_BOOK - (vm.edit_total + vm.total);
					}
					
					console.log('record',vm.basketTable);
					console.log('newrecord',vm.objects );
					
					
				      } 
					
				}
				
				
			
			
		
			vm.addNew = function() {
				    vm.tableMinimiseAdd = false;
                    var row = {};
                   //$scope.objects.length = vm.newSortOrder
					row.id = vm.objects.length+1;
					row.combination_key = vm.rowData.COMBINATION_KEY;
					row.basketDesc="";
					row.baskets=[];
					row.basketAmount=0;
					row.issueDropdown =[];
					row.issueId = "";
					row.flag = "add_basket"
					row.basketFlag= false;
					row.issueIdFlag = false;
					vm.objects.push(row);
					return row;
				};
			
		/*	
			function init() {
				vm.tableMinimiseAdd = true;
				vm.tableMinimise = false;
	 	       // vm.addBasketRows = [];
				//initAdjustments();
				};
				
				
			function initAdjustments(){
				vm.addNew();
			}
			
			init();*/
			
			
			
			
			
			vm.loadAppBases = function() {
				console.log('rowData',rowData);
				vm.BASE_LEVEL = rowData.QBU_AMT_IND;
				if(['3041','3112','3116'].indexOf(rowData.GHOST_ACCT) > 0) {
					
					vm.isbaseLevelDisabled = "disabled";
				} 
				
				if (vm.rowData['GGROWCOLORTOTAL'] === 1) {
					AlertService.add("warning", vm.totalRowReadonlyWarning, 4000);
					//$rootScope.$broadcast('gridRefresh', true); 
					//return;
					vm.cancel();
					return;
				} 
				sourcingInputScreenFactory.getAppBases(workspaceFactory.activeScreen.filters.getFilterParams()).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
						} else {				
							vm.appBases = data.jsonObject;
							//Set current Apportionment Base as the selected one
							for (var i=0; i< vm.appBases.length; i++) {
								if (angular.isDefined(vm.appBases[i].BASE_ID) && angular.isDefined(vm.rowData.BASE_ID)
									&& vm.appBases[i].BASE_ID === vm.rowData.BASE_ID) {
									vm.selectedAppBase = vm.appBases[i];
									break;
								}
							}
							vm.loading = false;
						}
				});
				
				
				sourcingInputScreenFactory.getAppBaseIssueKey(vm.rowData).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
						} else {
							vm.appBaseIssueKey = data.jsonObject;
							
				sourcingInputScreenFactory.getIssueId().then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.appIssueId = data.jsonObject;
							angular.forEach(vm.appIssueId, function(fg) {
               	   		   if (vm.appBaseIssueKey.length > 0 && vm.appBaseIssueKey[0].ISSUE_KEY == fg.ISSUE_ID) { // needs to fix
               	   			   vm.selectedIssueId = fg;               	   			   
               	   		   }
               	   			   });
							//Set current iisue Id for Apportionment Base as the selected one
							/*for (var i=0; i< vm.appIssueId.length; i++) {
								if (angular.isDefined(vm.appIssueId[i].ISSUE_ID ) && vm.appBases[i].ISSUE_ID === vm.rowData.BASE_ID ){
									vm.selectedIssueId = vm.appIssueId[i];
									break;
								}
							}*/
							vm.loading = false;
						}
				});
				
							console.log(vm.appBaseIssueKey);
							vm.loading = false;
						}
					});
				
				
				
				
				
				
				
			};

			
			
			if(vm.colData.call_back === "sourcngInputScreenModal") {
				vm.loadAppBases();
			} else {
				vm.loadBaskets();
				
			}
			
			
			
			

			vm.reset = function () {
				$scope.crudLoading = false;
				vm.selectedAppBase = null;
				vm.selectedIssueId = null;
			};
			
			vm.saveApportionmentBase = function() {
				console.log("in saveApportionmentBase method");
				if ($scope.crudLoading) {
					AlertService.add("info", vm.saveClickedMsg, 4000);
					return;
				} else if (angular.isDefined(vm.selectedAppBase.BASE_ID) && angular.isDefined(vm.rowData.BASE_ID)
							&& vm.selectedAppBase.BASE_ID === vm.rowData.BASE_ID) {
					if(rowData.GRID_TYPE && rowData.GRID_TYPE === 'QBU') {
						if(rowData.QBU_AMT_IND === vm.BASE_LEVEL) {
							AlertService.add("error", vm.sameAppBaseError_QBU, 4000);
							return;
						}
					} else {
						AlertService.add("error", vm.sameAppBaseError, 4000);
						return;
					}
				}
				
				if ( vm.selectedIssueId == undefined || vm.selectedIssueId == null || vm.selectedIssueId == ""){
					 vm.app_issue_id = '0'
				}
				else {
					vm.app_issue_id =  vm.selectedIssueId.ISSUE_ID.toString()
				};
				
				if (vm.scenario.ISSUE_ID_ENABLED != 'N' && vm.app_issue_id == 0 ){
					AlertService.add("error", "Please select issue Id", 4000);
							return;
				}
				
				$scope.crudLoading = true;
				var jsonSettings = workspaceFactory.activeScreen.filters.getFilterParams();
				var jsonObj = _.clone(vm.rowData);
				jsonSettings.jcd_key = workspaceFactory.activeScreen.jcd_key;
				jsonObj.PREV_SOURCE_ID = vm.rowData.BASE_ID;
				jsonObj.SOURCE_TYPE = 'A';
				jsonObj.SOURCE_ID = vm.selectedAppBase.BASE_ID;
				jsonObj.ISSUE_KEY = vm.app_issue_id ; 
				jsonObj.FILING_GROUP = vm.rowData.FILING_GROUP;
				jsonObj.BASE_LEVEL = vm.BASE_LEVEL;
				
				
				sourcingInputScreenFactory.saveApportionmentBase(vm.ssoId, jsonSettings, jsonObj).then(
					function(data) {
						if (data.callSuccess === "1") {
							//Update rowData BASE_ID, APPORTIONMENT_BASE
							// vm.rowData.BASE_ID = vm.selectedAppBase.BASE_ID;
							// vm.rowData.APPORTIONMENT_BASE = vm.selectedAppBase.BASE_NAME;
							
							AlertService.add("success", vm.saveSuccess, 3000);
							$uibModalInstance.close();
							$rootScope.$broadcast('gridRefresh', true); 
							$rootScope.$emit('gridUpdate', {});
							
						} else {
							console.log('error data' , data);
							AlertService.add("error", vm.saveError);
						}
						//vm.loading = false;
						$scope.crudLoading = false;
					}
				);
			};

			
			vm.cancel = function () {
				$scope.crudLoading = false;
				console.log("in cancel");
				//var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
				//$rootScope.$emit('gridUpdate', args);  
				$uibModalInstance.
				dismiss('cancel');
			};
			
			
			
			/// ADD /EDIT Baskets Validation for Save

            vm.validateNewBaskets = function()
		          {
			         for(var i=0; i< vm.objects.length; i++) {
				
				              if(
					              ((angular.isDefined(vm.objects[i].basketDesc)) && vm.objects[i].basketDesc == '' )
					              || ((angular.isDefined(vm.objects[i].basketDesc)) && vm.objects[i].basketDesc ==  null )
					             ){
									 vm.objects[i].basketFlag = true;
								   }
			
		                }
		           };
		           
		           
		      vm.validateIssueId = function(){
			
			     for(var i=0; i< vm.objects.length; i++) {
				
				              if(
					              ((angular.isDefined(vm.objects[i].issueId)) && vm.objects[i].issueId == "")
					             ){
									 vm.objects[i].issueIdFlag = true;
									}
			
		                }
			
				};     
		           
		           
		       vm.validateIssueFlag = function(){
			
			     for(var i=0; i< vm.basketTable.length; i++) {
				
				              if(
					              ((angular.isDefined(vm.basketTable[i].ISSUE_KEY)) && vm.basketTable[i].ISSUE_KEY == "")
					             ){
									 vm.basketTable[i].issueFlag = true;
									}
			
		                }
			
				};    
				    

		    vm.singleRowSave = function(){
			
			vm.json_arrOBJ = [];
			vm.total_basket = null
			
			
			
			 if(vm.objects !== undefined){
				
				vm.validateNewBaskets();
				for(var i=0; i< vm.objects.length; i++){
							  if(vm.objects[i].basketFlag == true){
								vm.basketRowFlag = true;
								break;
							     }else
							     {
								      vm.basketRowFlag = false;
							     }
						    }
						    
				if (vm.scenario.ISSUE_ID_ENABLED !== 'N'){
					vm.validateIssueId();
					vm.validateIssueFlag();
				}		    
						    
				if (vm.scenario.ISSUE_ID_ENABLED !== 'N'){
					
					for(var i=0; i< vm.objects.length; i++){
							  if(vm.objects[i].issueIdFlag == true){
								vm.issueIdRowFlag = true;
								break;
							     }else
							     {
								      vm.issueIdRowFlag = false;
							     }
							     
					}
					
					for(var i=0; i< vm.basketTable.length; i++){
							  if(vm.basketTable[i].issueFlag == true){
								vm.issueRowFlag = true;
								break;
							     }else
							     {
								      vm.issueRowFlag = false;
							     }
							     
					}
					
					
					}
				
				
				_.each(vm.objects, function (item) {
                            vm.json_arrOBJ.push({
                                basket_amount: Number(item.basketAmount),
                                basket_id: item.basketDesc.BASKET_ID,
                                basket_name :item.basketDesc.BASKET_NAME ,
                                issue_key: item.issueId.ISSUE_ID == undefined ? "" : item.issueId.ISSUE_ID,
                                rec_type : 'M',
                                row_id: '0'
                            });
                        });
               // vm.add_basket = json_arrOBJ;  
				}
				
				if(vm.basketTable !== undefined){
				
				_.each(vm.basketTable, function (item) {
                            vm.json_arrOBJ.push({
                                basket_amount: item.AMOUNT,
                                basket_id: item.BASKET_ID,
                                basket_name :item.BASKET_NAME ,
                                issue_key :item.ISSUE_KEY == undefined ? "" :item.ISSUE_KEY,
                                rec_type:item.REC_TYPE,
                                row_id: item.ROW_ID                                
                            });
                        });
               // vm.edit_basket = edit_rowOBJ;
				}	
				
				console.log('jsonArray' , vm.json_arrOBJ);
				
				
				for(var k = 0 ; k < vm.json_arrOBJ.length; k++)
		        	{
		        		vm.total_basket = vm.total_basket + parseInt(vm.json_arrOBJ[k].basket_amount);
		        	}
		        	

		        vm.unsourced_amt = (vm.rowData.ADJUSTED_BOOK - vm.total_basket);	
		         	
		      	console.log('total_basket' , vm.total_basket);
				if(vm.total_basket !== vm.rowData.ADJUSTED_BOOK && vm.rowData.DEFAULT_SOURCE == 'B'){
					AlertService.add("error","Total Sourced amount is not equal to GHOST amount.",4000);
					return;
				}else if (vm.rowData.DEFAULT_SOURCE == 'A' && (vm.total_basket + vm.unsourced_amt) !== vm.rowData.ADJUSTED_BOOK){
				AlertService.add("error","Total Sourced amount is greater than GHOST amount",4000);
					return;
				}else{
					vm.saveBasket();
				}
			
			
		    }
		    
		    
		    vm.saveBasket = function() {
			
			if (vm.scenario.ISSUE_ID_ENABLED !== 'N' && (vm.basketRowFlag == true || vm.issueIdRowFlag == true || vm.issueRowFlag == true)){
				return;
			}
			
			if(vm.scenario.ISSUE_ID_ENABLED == 'N' && vm.basketRowFlag == true ){
				
				return;
				 }
			
			//	vm.unsourced_amt = (vm.rowData.ADJUSTED_BOOK - vm.total_basket);	
		    	var settings = {"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
		    					"scenario" :workspaceFactory.activeScreen.filters === undefined ?GlobalService.globalParams.scenario:workspaceFactory.activeScreen.filters.filterParams.scenario,
                         		"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key ,
                         		 "unsourced_amt": vm.unsourced_amt,
                         		 "ho_combination_key" : vm.rowData.HO_COMBINATION_KEY
                         		}
			    vm.newSettings = _.merge({}, settings ,rowData)
			
			sourcingInputScreenFactory.saveGhostSourcingBasketData(vm.json_arrOBJ, vm.newSettings).then(
					function(data) {
						if (data.callSuccess === "1") {
							$rootScope.$broadcast('gridRefresh', true); 
							$rootScope.$emit('gridUpdate', {});
							//vm.rowData.SOURCE_OBJECT_ID = vm.selectedBasket.BASKET_ID;
							// vm.rowData.BASKET_NAME = vm.selectedBasket.BASKET_NAME;
							AlertService.add("success", vm.saveSuccess, 3000);
							$uibModalInstance.close();
							//$rootScope.$broadcast('gridRefresh', true); 
							//var args = {gridFilter: {object_id:vm.rowData.SOURCE_OBJECT_ID}};
							//$rootScope.$emit('gridUpdate', {});
							//$uibModalInstance.dismiss('cancel');
							$rootScope.$broadcast('dataFilters:refreshGrid', {
								"refresh": true
							});
						} else {
							console.log('error data' , data);
							AlertService.add("error", vm.saveError);
						}
						//vm.loading = false;
						$scope.crudLoading = false;
					})
			}
			
			
			
			
			
			/*vm.saveBasket = function() {
				console.log(vm.selectedBasket);
				
				sourcingInputScreenFactory.saveNewBasketData(rowData, colData, vm.selectedBasket).then(
					function(data) {
						if (data.callSuccess === "1") {
							
							//vm.rowData.SOURCE_OBJECT_ID = vm.selectedBasket.BASKET_ID;
							// vm.rowData.BASKET_NAME = vm.selectedBasket.BASKET_NAME;
							AlertService.add("success", vm.saveSuccess, 3000);
							$uibModalInstance.close();
							//$rootScope.$broadcast('gridRefresh', true); 
							//var args = {gridFilter: {object_id:vm.rowData.SOURCE_OBJECT_ID}};
							//$rootScope.$emit('gridUpdate', {});
							//$uibModalInstance.dismiss('cancel');
							$rootScope.$broadcast('dataFilters:refreshGrid', {
								"refresh": true
							});
						} else {
							console.log('error data' , data);
							AlertService.add("error", vm.saveError);
						}
						//vm.loading = false;
						$scope.crudLoading = false;
					})
			}*/

			vm.resetBasket = function () {
				$scope.crudLoading = false;
				vm.selectedBasket = null;
			};

			vm.cancelBasket = function () {
				$scope.crudLoading = false;
				console.log("in cancel");
				//var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
				//$rootScope.$emit('gridUpdate', args);  
				$uibModalInstance.
				dismiss('cancel');
			};
		}


});
