define([
    'angular',
    './disregardedEntitiesController'
], function () {
    'use strict';
    
    var module = angular.module('app.disregardedEntities', ['app.DisregardedEntitiesController'])
        
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('disregarded-entities', {
                url: '/disregarded-entities',
                "templateUrl": "app/components/DST/reports/disregarded-entities/disregarded-entities.html",
                "controller": "DisregardedEntitiesCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
