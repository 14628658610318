define([
    'angular',
    './adminResetDsConnectionCtrl.js',
    './adminResetDsConnectionService'

], function () {
    'use strict';
    return angular.module('app.adminResetDsConnection', ['app.adminResetDsConnectionCtrl', 'app.adminResetDsConnectionService'])

        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider
                .state('admin.reset-ds-connection', {
                    url: '/reset-ds-connection',
                    templateUrl: 'app/components/admin/adminResetDSConnection/admin-reset-ds-connection.html',
                    type:'custom',
                    "api": {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=33026&grid_id=6051","grid_id":"6051"},
                    access: "4"
                });
        }]);
});