define([
    'angular',
    './F1120SubConsolController',
    './1120SubConsolService'


], function () {
    'use strict';
    return angular.module('app.1120SubConsol', ['app.F1120SubConsolController','app.1120SubConsolService'] );


});