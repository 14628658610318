define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.regenerateFirFormService', []).factory("FirformFactory", ['$http', 'GENERAL_CONFIG', 'JsonObjectFactory', 'AlertService',
        function ($http, GENERAL_CONFIG, JsonObjectFactory, AlertService) {
            const FILING_GROUP_ACTION_KEY = 'gk7crv';
            const JCD_KEY = 250;
            const FirformFactory = {};

            FirformFactory.getFilingGroups = function () {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', {"action_code": FILING_GROUP_ACTION_KEY});
            };
/*
            FirformFactory.getTaxPayerList = function (tax_year, consol_group_key) {
                const promise = $http.post(GENERAL_CONFIG.base_url + '/firform/getTaxPayerList?tax_year=' + tax_year +
                    '&consol_group_key=' + consol_group_key);
                return promise;
            };*/
            
            FirformFactory.regenerateFormData = function(tax_year, scenario, form ,filing_key,sso_id,jcd_key){
            	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=523tu3";
            	var params = {
        				"tax_year": tax_year,
        				"irs_form_no": form,
        				"scenario": scenario, //vm.rowData.COMBINATION_KEY,
        				"jcd_key": jcd_key,
        				"sso_id" :sso_id,
        				"filing_key":filing_key
        			};
       		  console.log(params);
       		  var promise = $http({
       			 	method: "post",
       		        url: url,
       		        data: params
       		     }).then(function (response) {
       		    	 var status = response.status;
       		         return response;
       		     });
       		 return promise;
       	 }
            
            return FirformFactory;
        }]);

    return services;
});
