define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.FormScheduleB2Ctrl', [])
        .controller('F1065ScheduleB2Controller', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope','$filter', '$rootScope', '$timeout', 'rowData', 'colData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', F1065ScheduleB2Controller]);


    function F1065ScheduleB2Controller(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $filter,$rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        
        // modalTitle shouldn't be hardcoded.
        vm.modalTitle = "SCHEDULE B-2- Election Out of the Centralized Partnership Audit Regime";
        vm.isSaving = false;
        vm.validationStatus = true;
        vm.vaildationErrMsg = '';

        vm.formData = {
            "addrows": [],
            "editrows": [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME,
            // "LE TYPE": rowData.LE_TYPE,
            //"LEDGER": rowData.LEDGER,
            // "ME": rowData.ME
        };

        var saveParamDtls = [];
        var tempDataObj = {};
        $scope.scheduleFFormView = {rows: []};
        vm.showFormLoader = true;
        vm.part1DropDown=[{"value" : "I", "label" : "Individual (I)"},
        {"value" : "C", "label" : "Corporation (C)"},
        {"value" : "E", "label" : "Estate of Deceased Partner (E)"},
        {"value" : "F", "label" : "Eligible Foreign Entity (F)"},
        {"value" : "S", "label" : "S corporation (S)"}];

        vm.part2DropDown = [{"value": "I", "label" : "Individual"},
        {"value": "E", "label" : "Estate of Deceased Shareholder"},
        {"value": "T", "label" : "Trust"},
        {"value": "O", "label" : "Other"}];

        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = vm.modalTitle + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';

        let loadScheduleB2Data = function (){
			vm.showFormLoader = true;
			var params = { 
				"action_code":"9ha43j",
				"activity_key":"6170",
				"is_locked":"",
				"jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ 
				"jcd_ta_key": 5321,
				"me_by_entity":"",

				"scenario": rowData.SCENARIO_KEY, 
				"screen_type":"grid",
				"tax_year": rowData.TAX_YEAR, 
				
				// "action_id": 31274, 
				"combination_key": rowData.COMBINATION_KEY, 
				"schedule_name": "B2",
				
			};
			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
				.then(function (response) {
					vm.showFormLoader = false;
					let data = response.jsonObject;
                    vm.original_API_DATA = angular.copy(data);
                    //transformData(data);
					
                    //multirow data for share holders

                    var params = {
                        combination_key: rowData.COMBINATION_KEY,
                        schedule: "B2"
                    };
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=z57kmr', params)
                    .then(function (mrdata) {
                        vm.transformData(data);
                        vm.transformShareHolderData(mrdata.jsonObject);
                    });
                
                });
           
		};
       
        loadScheduleB2Data();

        vm.transformData = function (data){
            $scope.scheduleB2Data ={};
            vm.part2List = [];
            for(let i=0 ; i < data.length; i++){
                data[i].ATTRIB_VALUE = data[i].ATTRIB_VALUE || "";
                if(data[i]["PART_NO"] == "P2") {
                    vm.part2List.push(data[i]);
                    continue;
                }
                if(!$scope.scheduleB2Data[data[i]["PART_NO"]] ) {
                    $scope.scheduleB2Data[data[i]["PART_NO"]] = [data[i]]; 
                } else {
                    $scope.scheduleB2Data[data[i]["PART_NO"]].push(data[i]);
                }
            }
            $scope.scheduleB2Data["P2"] = {};
            for(let i=2; i<$scope.scheduleB2Data["P1"].length;i+=3){
                console.log('$scope.scheduleB2Data[P1][i][ATTRIBUTE_VALUE] = ' , $scope.scheduleB2Data["P1"][i]["ATTRIB_VALUE"]);

                if($scope.scheduleB2Data["P1"][i]["ATTRIB_VALUE"] == "S"){
                   addPart2ForPartner($scope.scheduleB2Data["P1"][i]["LEID"]);
                }
            }
            computeP3Value();
            vm.backupScheduleB2Data = angular.copy($scope.scheduleB2Data);
        }
        vm.transformShareHolderData = function(data){
            vm.multirow_data = vm.multirow_data || {};
            data.forEach(shareholder => {
                vm.multirow_data[shareholder.COMBINATION_KEY] = vm.multirow_data[shareholder.COMBINATION_KEY] || [];

                vm.multirow_data[shareholder.COMBINATION_KEY].push(
                    {
                        "OCCURRENCE": shareholder.OCCURRENCE,
                        "ATTRIB_NAME": "SNAME",
                        "ATTRIB_VALUE": shareholder.SNAME
                    }, {
                        "OCCURRENCE": shareholder.OCCURRENCE,
                        "ATTRIB_NAME": "STIN",
                        "ATTRIB_VALUE": shareholder.STIN
                    }, {
                        "OCCURRENCE": shareholder.OCCURRENCE,
                        "ATTRIB_NAME": "STYPE",
                        "ATTRIB_VALUE": shareholder.STYPE
                    }
                )
            });

            vm.multirow_data_backup = angular.copy(vm.multirow_data);
            computeP3Value();
        }
        vm.part2Compute = function(leid,index){

            if($scope.scheduleB2Data['P1'][index+2].ATTRIB_VALUE == "S"){
                addPart2ForPartner(leid);
            }else{
                removePart2ForPartner(leid);
            }
            computeP3Value();
        }

        vm.addShareholder = function(partner){
            
            vm.multirow_data = vm.multirow_data || {};

            vm.multirow_data[partner.COMBINATION_KEY] = vm.multirow_data[partner.COMBINATION_KEY] || [];
            let occurenceNumber = vm.multirow_data[partner.COMBINATION_KEY].length / 3;
            vm.multirow_data[partner.COMBINATION_KEY].push(
                {
                    "OCCURRENCE": occurenceNumber+1,
                    "ATTRIB_NAME": "SNAME",
                    "ATTRIB_VALUE": ""
                }, {
                    "OCCURRENCE": occurenceNumber+1,
                    "ATTRIB_NAME": "STIN",
                    "ATTRIB_VALUE": ""
                }, {
                    "OCCURRENCE": occurenceNumber+1,
                    "ATTRIB_NAME": "STYPE",
                    "ATTRIB_VALUE": ""
                }
            );

            computeP3Value();
        }

        vm.removeShareHolder = function(combination_key,index){
            vm.multirow_data[combination_key].splice(index,3);

        }

        function addPart2ForPartner(leid){
            $scope.scheduleB2Data["P2"][leid] = [];
            for(let i=0 ;i< vm.part2List.length;i++){
                if(vm.part2List[i]["LEID"] == leid){
                    $scope.scheduleB2Data["P2"][leid].push(vm.part2List[i]);
                }
            }
            console.log(" transfomr b2 ", $scope.scheduleB2Data);
        }

        function removePart2ForPartner(leid){
            delete $scope.scheduleB2Data["P2"][leid];
            console.log(" transfomr b2 ", $scope.scheduleB2Data);
        }
        function computeP3Value(){
            $scope.scheduleB2Data['P3'][0].ATTRIB_VALUE = parseInt($scope.scheduleB2Data['P1'].length/3);
            $scope.scheduleB2Data['P3'][1].ATTRIB_VALUE =0;
            for (const key in $scope.scheduleB2Data['P2']) {
                $scope.scheduleB2Data['P3'][1].ATTRIB_VALUE = parseInt($scope.scheduleB2Data['P3'][1].ATTRIB_VALUE ) 
                                                            + parseInt(($scope.scheduleB2Data['P2'][key].length - 2)/3);
            }          
            for(const key in vm.multirow_data){
                $scope.scheduleB2Data['P3'][1].ATTRIB_VALUE = parseInt($scope.scheduleB2Data['P3'][1].ATTRIB_VALUE ) 
                                                            + parseInt(vm.multirow_data[key].length/3);
            }

            $scope.scheduleB2Data['P3'][2].ATTRIB_VALUE = parseInt($scope.scheduleB2Data['P3'][0].ATTRIB_VALUE) + 
                                                          parseInt($scope.scheduleB2Data['P3'][1].ATTRIB_VALUE);
        }
        vm.saveSchdB2Form = function (url, data) {
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                // "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);
            params.process_name = "FORM_SAVE_1065_SCHB2";//workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            // params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            console.log("B2 params",params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isSaving =false;
                var status = response.status;
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage);
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function () {
            
            vm.isSaving = true;

            var tempArray = $scope.scheduleB2Data["NA"]
                                .concat($scope.scheduleB2Data["P1"])
                                .concat($scope.scheduleB2Data["P3"]);
            
            for(let key in $scope.scheduleB2Data["P2"]){
                tempArray =  tempArray.concat($scope.scheduleB2Data["P2"][key]);
            }

            let tempArray2 = [{
                "COMBINATION_KEY": rowData.COMBINATION_KEY,
                "LEID":rowData.LEID,
                "LE_NAME": rowData.LE_NAME,
                "VALUES": tempArray,
                "MULTIROW_DATA":[]
            }];
            
            for(let key in vm.multirow_data){                
                let obj = {
                    "TAX_YEAR": rowData.TAX_YEAR,
                    "TRANS_TYPE_KEY": "1136",
                    "COMBINATION_KEY": key,
                    "LEID": rowData.LEID,
                    "GROUP_OBJ_KEY": 2020193,
                    "IRS_FORM_NO": "1065",
                    "SCHEDULE_NAME": "B2",
                    "PART_NO": "P2",
                    "SECTION": "NA",
                    "LINE_NO": "3",
                    "JSON_DATA": vm.multirow_data[key]
                };

                tempArray2[0].MULTIROW_DATA.push(obj);
            }
            
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tkgaam";
            
            if(vm.validate(tempArray2)) {

                vm.saveSchdB2Form(url, tempArray2);

                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function () {
                    $scope.$broadcast("showLoader", true);
                }, 100);
            }
            else {
                AlertService.add("danger", vm.vaildationErrMsg, 4000);
                vm.isSaving = false;
            }

        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.reset = function () {
            $scope.scheduleB2Data= angular.copy(vm.backupScheduleB2Data );
            vm.multirow_data= angular.copy(vm.multirow_data_backup);
		}

        //Dynamic Tabset Code
        vm.tabs = [
            {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Sch-B2/F1065-scheduleB2Entry.html", active: true },
            // {id: 2, name: "Form View", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFView.html", active: false},
            // {id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFVersion.html", active: false},
            // {id: 4, name: "PDF", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFPdf.html", active: false}
        ];

        vm.validate = function(data) {

            let validationStatus = true;
            let STINArray
            let stinValue;
            let occurrence;
            let x;
            let ptrCombi;
            let ptrName;

            for(let j = 0; j < data[0].MULTIROW_DATA.length; j++) {

                ptrCombi = data[0].MULTIROW_DATA[j].COMBINATION_KEY;
                ptrName = $filter("filter")(data[0].VALUES,{COMBINATION_KEY:ptrCombi})[0].LE_NAME;

                STINArray = $filter("filter")(data[0].MULTIROW_DATA[j].JSON_DATA,{ATTRIB_NAME:'STIN'});

                for(let i = 0; i < STINArray.length; i++) {

                    stinValue = STINArray[i].ATTRIB_VALUE;
                    occurrence = STINArray[i].OCCURRENCE;

                    if(stinValue != null) {
                        x = stinValue.toString();
                        if(x.length < 9) {
                            validationStatus = false;
                            vm.vaildationErrMsg = 'Shareholder TIN must be 9 characters for Row Occurrence : ' + occurrence + ' of S Corporation Partner : ' + ptrName + '.';
                            break;
                        }
                    }
                }

                if(validationStatus == false) {
                    break;
                }

            }

            return validationStatus;
        }

    }
});
