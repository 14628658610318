define([
    'angular',
    './schedule-ICtrl',
    './schedule-IService',
    
], function () {
    'use strict';
    return angular.module('app.FIR_5471_Schedule_I', ['app.schedule-ICtrl', 'app.schedule-IService'] );
});

