define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.newProformaFormReviewCtrl', [])
                             .controller('NewProformaFormReviewCtrl', ['$rootScope', '$scope', 'NewProformaFactory', 'NewProformaFormReviewFactory', 'GlobalService', 'TAX_YEARS', '$uibModal', 'AlertService', newProformaFormReviewCtrl]);

    function newProformaFormReviewCtrl($rootScope, $scope, NewProformaFactory, NewProformaFormReviewFactory, GlobalService, TAX_YEARS, $uibModal, AlertService) {
        var vm = this;
        vm.recordsCount = 0;
        vm.loadingList = false;
        vm.loadingForms = false;
        vm.expandPdfView = true;
        vm.pdfs = [];
        vm.flatData = [];
        vm.formsPerEntity = [];
        vm.noDataMsg = '';
        vm.requestForEntities = null;
        vm.filterCatMapping = {
            'C': 'Corporation',
            'P': 'Partnership'
        };
        vm.filterLocMapping = {
            'F': 'Foreign',
            'D': 'Domestic'
        };
        vm.filter = NewProformaFormReviewFactory.formReviewFilter;
        vm.selectedEntity = NewProformaFormReviewFactory.selectedEntityCached.entity;
        vm.selectedView = NewProformaFormReviewFactory.selectedEntityCached.view;
        vm.cdrNo = NewProformaFormReviewFactory.selectedEntityCached.cdrNo;
        vm.rpPeriod = NewProformaFormReviewFactory.selectedEntityCached.rpPeriod;
        vm.selectedPdf = NewProformaFormReviewFactory.selectedPdfCached;
        vm.selectedPdfs = NewProformaFormReviewFactory.selectedPdfsCached;
        vm.searchTerm = '';
        vm.formSearchTerm = '';

        // refresh list on global params change
        $rootScope.$on('GlobalParams is updated', function () {
            vm.selectedEntity = null;
            vm.selectedForm = null;
            vm.irs_form_name = null;
            vm.expandPdfView = true;
            vm.getEntities();
        });

        vm.isProdEnv = function () {
            //return window.location.hostname.startsWith('gtw');
            return false;
        };

        vm.initialize = function () {
            vm.getEntities(true);
        };

        vm.catChanged = function (category) {
            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = '';
            vm.filter.category = category;
            if (vm.filter.category === 'P') {
                if (vm.filter.location === 'D' && vm.filter.lEType === 'BSLA')
                    vm.filter.lEType = 'HO';
                else if (vm.filter.location === 'F' && (vm.filter.lEType === 'HO' || vm.filter.lEType === 'CONSOL'))
                    vm.filter.lEType = 'PSHIP';
                // force Foreign selection in Prod
                if (vm.isProdEnv()) {
                    vm.filter.location = 'F';
                    vm.filter.lEType = 'PSHIP';
                }
            } else {
                if (vm.filter.location === 'F' && vm.filter.lEType === 'PSHIP')
                    vm.filter.lEType = 'HO';
            }
            vm.getEntities();
        };

        vm.locationChanged = function (location) {
            if (vm.isProdEnv() && vm.filter.category === 'P') return;

            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = '';
            vm.filter.location = location;
            // reset le type on unavailable options
            if (vm.filter.category === 'C') {
                if (vm.filter.location === 'F' && (vm.filter.lEType === 'BSLA' || vm.filter.lEType === 'HOO'))
                    vm.filter.lEType = 'HO';
                else if (vm.filter.location === 'D' && vm.filter.lEType === 'CONSOL')
                    vm.filter.lEType = 'HO';
            } else {
                if (vm.filter.location === 'D' && vm.filter.lEType === 'PSHIP')
                    vm.filter.lEType = 'HO';
                else if (vm.filter.location === 'F' && (vm.filter.lEType === 'HO' || vm.filter.lEType === 'HOO'))
                    vm.filter.lEType = 'PSHIP';
            }
            vm.getEntities();
        };

        vm.typeChanged = function (lEType) {
            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = '';
            vm.filter.lEType = lEType;
            vm.filter.entityType = 'ALL';
            vm.getEntities();
        };

        vm.entityTypeChanged = function (lEType, entityType) {
            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = '';
            vm.filter.lEType = lEType;
            vm.filter.entityType = entityType;
            vm.getEntities();
        };

        // make sequential calls to initialize the screen with default selected entity
        vm.getEntities = function (sequentialCall = false) {
            vm.recordsCount = 0;
            vm.loadingList = true;
            vm.flatData = [];
            vm.treeData = [];
            vm.pdfs = [];
            vm.searchTerm = '';
            vm.formSearchTerm = '';
            vm.noDataMsg = 'Please select an entity from the list!';
            const selectedTaxYear = GlobalService.globalParams.tax_year;
            const selectedScenario = GlobalService.globalParams.scenario;
            const selectedCategory = vm.filter.category;
            const selectedLocation = vm.filter.location;
            const selectedType = vm.filter.lEType;

            NewProformaFactory.resetStates();

            if (vm.filter.lEType === 'BSLA') {
                // load data from cache or fetch it from server if cache is empty
                if ((vm.treeData = vm.getEntitiesFromCache()).length === 0) {
                    NewProformaFormReviewFactory.getPdfView(selectedCategory, selectedLocation, selectedType).then(
                        (response) => {
                            const list = response.data.viewList;
                            // ignore the response if previous filter selection when api call is made
                            // does not match the current selection
                            if (!vm.filterMatch(selectedTaxYear, selectedScenario, selectedCategory, selectedLocation, selectedType)) {
                                return;
                            }
                            if (list && list.length > 0) {
                                list.forEach((item) => {
                                    item.hidden = false;
                                });
                                vm.treeData = NewProformaFormReviewFactory.formReviewCache[selectedCategory + '_' + selectedLocation + '_' + selectedType + '_' + GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario] = list;
                            } else {
                                vm.treeData = [{
                                    me_label: 'No Data Available!',
                                    noDataFlag: true
                                }];
                            }
                            vm.loadingList = false;
                        },
                        (err) => {
                            vm.loadingList = false;
                        }
                    );
                } else {
                    vm.loadingList = false;
                }
            } else {
                if ((vm.flatData = vm.getEntitiesFromCache()).length === 0) {
                    NewProformaFormReviewFactory.getPdfView(selectedCategory, selectedLocation, selectedType).then(
                        (response) => {
                            const list = response.data.viewList;
                            // ignore the response if previous filter selection when api call is made
                            // does not match the current selection
                            if (!vm.filterMatch(selectedTaxYear, selectedScenario, selectedCategory, selectedLocation, selectedType)) {
                                return;
                            }
                            if (list && list.length > 0) {
                                list.forEach((item) => {
                                    item.SOURCE_SYSTEM_FLAG = this.filter.location;
                                });
                                vm.flatData = NewProformaFormReviewFactory.formReviewCache[selectedCategory + '_' + selectedLocation + '_' + selectedType + '_' + GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario] = list;

                                // call next API
                                if (sequentialCall) {
                                    vm.entitySelected(vm.flatData[0], sequentialCall);
                                }
                                NewProformaFactory.sharedStates.showBulkExport = true;
                            } else {
                                vm.flatData = [{
                                    name: 'No Data Available!',
                                    noDataFlag: true
                                }];
                            }
                            vm.loadingList = false;
                        },
                        (err) => {
                            vm.loadingList = false;
                        }
                    );
                } else {
                    vm.loadingList = false;
                }
            }
        };

        vm.filterMatch = function (selectedTaxYear, selectedScenario, selectedCat, selectedLocation, selectedType) {
            return selectedTaxYear === GlobalService.globalParams.tax_year && selectedScenario === GlobalService.globalParams.scenario &&
                selectedCat === this.filter.category && selectedLocation === this.filter.location && selectedType === this.filter.lEType;
        };

        vm.getEntitiesFromCache = function () {
            return NewProformaFormReviewFactory.formReviewCache[vm.filter.category + '_' + vm.filter.location + '_' + vm.filter.lEType + '_' + GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario] || [];
        };

        vm.listFilter = (item) => {
            if (this.searchTerm.length > 0) {
                return item.name.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase());
            }
            return true;
        };

        vm.formFilter = (pdf) => {
            if (this.formSearchTerm.length > 0) {
                pdf.isDisplay = pdf.checked || pdf.form_name.toString().toLowerCase().includes(this.formSearchTerm.toString().toLowerCase());
                return pdf.isDisplay;
            }
            pdf.isDisplay = true;
            return pdf.isDisplay;
        };

        vm.treeFilter = (item) => {
            // search based on the filtered result
            if (this.searchTerm.length > 0) {
                return item.me_label.toString().concat(item.me_code.toString()).toLowerCase().includes(this.searchTerm.toString().toLowerCase());
            } else {
                return !item.hidden;
            }
        };

        vm.getRecordsCount = () => {
            if (vm.flatData.length > 0) {
                if (vm.flatData[0].noDataFlag) {
                    return 0;
                }
                return vm.flatData.length;
            } else if (vm.treeData.length > 0) {
                if (vm.treeData[0].noDataFlag) {
                    return 0;
                }
                return vm.treeData.length;
            }
        };

        vm.toggleBSLA = (item) => {
            vm.toggle(vm.treeData, item);
        };

        vm.toggle = function (tree, item) {
            if (item.is_leaf === 1)
                return;

            let index = tree.indexOf(item) + 1;
            // Collapse
            if (!item.childrenHidden) {
                item.childrenHidden = true;
                for (let i = index; i < tree.length; i++) {
                    let prev = i - 1;
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else {
                        tree[i].hidden = true;
                        // tree[i].childrenHidden = false;
                    }
                    // collapse folder icon if children are hidden
                    if (tree[prev].lvl < tree[i].lvl) {
                        tree[prev].childrenHidden = true;
                    }
                }
            }
            // Expand
            else {
                item.childrenHidden = false;
                for (let i = index; i < tree.length; i++) {
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else if (tree[i].lvl - 1 === item.lvl) {
                        tree[i].hidden = false;
                    } else {
                        tree[i].hidden = true;
                    }
                }
            }
        };

        // vm.toggle = (item) => {
        //     let index = this.treeData.indexOf(item) + 1;
        //     // Collapse
        //     if (!item.childrenHidden) {
        //         item.childrenHidden = true;
        //         for (let i = index; i < this.treeData.length; i++) {
        //             let prev = i - 1;
        //             if (this.treeData[i].lvl <= item.lvl) {
        //                 return;
        //             } else {
        //                 this.treeData[i].hidden = true;
        //                 // this.treeData[i].childrenHidden = false;
        //             }
        //             // collapse folder icon if children are hidden
        //             if (this.treeData[prev].lvl < this.treeData[i].lvl) {
        //                 this.treeData[prev].childrenHidden = true;
        //             }
        //         }
        //     }
        //     // Expand
        //     else {
        //         item.childrenHidden = false;
        //         for (let i = index; i < this.treeData.length; i++) {
        //             if (this.treeData[i].lvl <= item.lvl) {
        //                 return;
        //             } else if (this.treeData[i].lvl - 1 === item.lvl) {
        //                 this.treeData[i].hidden = false;
        //             } else {
        //                 this.treeData[i].hidden = true;
        //             }
        //         }
        //     }
        // };

        vm.treeLoaded = () => {
            this.loading = false;
        };

        $scope.$on('showBulkExportModal::PDF', () => {
            vm.export();
        });

        vm.export = function () {
            $uibModal.open({
                animation: true,
                windowClass: 'pdf-bulk-export-modal',
                templateUrl: 'app/components/newProforma/pdf-bulk-export-modal.html',
                controller: ['$scope', 'NewProformaFormReviewFactory', 'AlertService', 'USER_SETTINGS', '$uibModalInstance',
                    function ($scope, NewProformaFormReviewFactory, AlertService, USER_SETTINGS, $uibModalInstance) {
                        let treeDataSet = new Set();
                        let parentNodesAhead = [];
                        $scope.clientName = USER_SETTINGS.user.client.client_name;
                        if (vm.filter.lEType === 'BSLA') {
                            $scope.isBSLAView = true;
                            $scope.entities = angular.copy(vm.treeData);
                        } else {
                            $scope.isBSLAView = false;
                            $scope.entities = angular.copy(vm.flatData);
                        }
                        $scope.entitiesFiltered = $scope.entities;
                        $scope.selectedFilterType = 'C';
                        $scope.zippedExport = true;
                        $scope.exportDisable = true;
                        $scope.noFormsMsg = '';

                        $scope.toggleBSLAView = function (view, $event) {
                            vm.toggle($scope.entitiesFiltered, view);
                            $event.preventDefault();
                        };

                        $scope.toggleForm = function (form, $event) {
                            vm.toggle($scope.formsFiltered, form);
                            $event.preventDefault();
                        };

                        if (!NewProformaFactory.modalFormsCache) {
                            $scope.formLoading = true;
                            NewProformaFactory.getBulkExportForms(
                                GlobalService.globalParams.tax_year,
                                vm.filter.category === 'P' ? '1065' : '1120',
                                'Dom'
                            ).then((response) => {
                                if (response.data.callSuccess === '1') {
                                    $scope.forms = NewProformaFactory.modalFormsCache = response.data.viewList;
                                    $scope.forms.forEach((form) => {
                                        form.label = form.irs_form_no && form.bucket_sub !== '5471' ? form.irs_form_no + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form
                                                                                                    : (form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form);
                                        // form.label = form.label.toLowerCase().startsWith('f') ? form.label.substring(1) : (form.label.toLowerCase().startsWith('irs') ? form.label.substring(3) : form.label);
                                        form.label = form.form_sub && form.form_sub_cat === 'DIFF_PDF' ? form.label + '-' + form.form_sub : form.label;

                                        form.hidden = form.lvl > 1;
                                        form.childrenHidden = form.lvl === 1;
                                        if (form.path.includes('Corporate Domestic')) {
                                            form.type = 'D';
                                        } else if (form.path.includes('Corporate Foreign')) {
                                            form.type = 'F';
                                        }
                                    });
                                    $scope.formsFiltered = $scope.forms;
                                }
                            }).catch(() => {
                                $scope.noFormsMsg = 'Forms unavailable.';
                            }).finally(() => {
                                $scope.formLoading = false;
                            });
                        } else {
                            // reset form states
                            NewProformaFactory.modalFormsCache.forEach((form) => {
                                form.childrenHidden = form.lvl >= 1;
                                form.hidden = form.lvl >= 2;
                                form.selected = false;
                            });
                            $scope.formsFiltered = $scope.forms = NewProformaFactory.modalFormsCache || [];
                        }

                        $scope.searchEntity = function () {
                            if ($scope.entitySearchTerm.length === 0) {
                                $scope.entitiesFiltered = $scope.entities;
                                return;
                            }
                            if ($scope.isBSLAView)
                                filterTreeData($scope.entities, $scope.entitySearchTerm, 'entity');
                            else
                                filterFlatData();
                        };

                        $scope.searchForm = function () {
                            if ($scope.formSearchTerm.length === 0) {
                                $scope.formsFiltered = $scope.forms;
                                return;
                            }
                            filterTreeData($scope.forms, $scope.formSearchTerm, 'form');
                        };

                        function filterFlatData() {
                            $scope.entitiesFiltered = $scope.entitiesFiltered.filter((item) => {
                                return item.name.toString().toLowerCase().includes($scope.entitySearchTerm.toString().toLowerCase());
                            });
                        }

                        function filterTreeData(treeData, searchTerm, type) {
                            const labelKey = type === 'entity' ? 'me_label' : 'form';
                            const labelKey2 = type === 'entity' ? 'me_code' : '';
                            treeDataSet.clear();
                            ``
                            parentNodesAhead = [];
                            for (let i = 0; i < treeData.length; i++) {
                                let node = treeData[i];
                                if (!node.is_leaf) {
                                    parentNodesAhead.push(node);
                                }
                                if (node[labelKey].toLowerCase().includes(searchTerm.toLowerCase()) || (type === 'entity' && node[labelKey2].toLowerCase().includes(searchTerm.toLowerCase()))) {
                                    findParents(node.path, labelKey); // add parents
                                    node.hidden = false; // reveal the item after search
                                    treeDataSet.add(node); // add itself
                                    if (!node.is_leaf) {
                                        i = findChildren(treeData, node.lvl, i + 1); // add children and update index
                                    }
                                }
                            }

                            if (type === 'entity') {
                                $scope.entitiesFiltered = Array.from(treeDataSet);
                            } else {
                                $scope.formsFiltered = Array.from(treeDataSet);
                            }
                        }

                        function findParents(path, labelKey) {
                            let nodes = path.split('/');
                            if (nodes.length > 2) {
                                nodes = nodes.slice(1, -1);
                                nodes.forEach(node => {
                                    let parentNode = parentNodesAhead.find((parent) => parent[labelKey] === node);
                                    parentNode.childrenHidden = false;
                                    treeDataSet.add(parentNode);
                                });
                            }
                        }

                        function findChildren(treeData, parentLvl, index) {
                            while (index < treeData.length) {
                                let node = treeData[index];
                                if (node.lvl > parentLvl) {
                                    node.hidden = false;
                                    treeDataSet.add(node);
                                    index++;
                                } else {
                                    break;
                                }
                            }
                            return index;
                        }

                        $scope.selectEntity = function (item, $event) {
                            item.selected = !item.selected;
                            if ($scope.isBSLAView && !item.is_leaf) {
                                const index = $scope.entitiesFiltered.indexOf(item);
                                for (let i = index + 1; i < $scope.entitiesFiltered.length; i++) {
                                    if ($scope.entitiesFiltered[i].lvl <= item.lvl) {
                                        break;
                                    }
                                    $scope.entitiesFiltered[i].selected = item.selected;
                                }
                            }
                            enableDisableExport();
                            $event.preventDefault();
                        };

                        $scope.selectForm = function (item, $event) {
                            item.selected = !item.selected;

                            if (!item.is_leaf) {
                                const index = $scope.formsFiltered.indexOf(item);
                                for (let i = index + 1; i < $scope.formsFiltered.length; i++) {
                                    if ($scope.formsFiltered[i].lvl <= item.lvl) {
                                        break;
                                    }
                                    $scope.formsFiltered[i].selected = item.selected;
                                }
                            }

                            if (item.selected) {
                                if (item.type === 'D') {
                                    $scope.domFormSelected = true;
                                } else {
                                    $scope.forFormSelected = true;
                                }
                                if ($scope.domFormSelected && $scope.forFormSelected) {
                                    $scope.zippedExport = true;
                                }
                            } else {
                                checkUnselectedForms(item.type);
                            }
                            enableDisableExport();

                            $event.preventDefault();
                        };

                        function enableDisableExport() {
                            // need is_leaf for bsla tree
                            const selectedEntities = $scope.entities.filter(item => item.selected);
                            const selectedForms = $scope.forms.filter(item => item.selected && item.is_leaf);
                            if (selectedEntities.length == 0 || selectedForms.length == 0)
                                $scope.exportDisable = true;
                            else
                                $scope.exportDisable = false;
                        }

                        function checkUnselectedForms(type) {
                            const hasCheckedForms = $scope.forms.some((form) => {
                                return form.type === type && form.selected;
                            })
                            if (hasCheckedForms) {
                                return;
                            } else if (type === 'D') {
                                $scope.domFormSelected = false;
                            } else if (type === 'F') {
                                $scope.forFormSelected = false;
                            }
                        }

                        $scope.export = function () {
                            $scope.exportLoading = true;
                            // need is_leaf for bsla tree
                            const selectedEntities = $scope.entities.filter(item => item.selected);
                            const selectedForms = $scope.forms.filter(item => item.selected && item.is_leaf);

                            let entities = [];
                            let forms = [];

                            selectedEntities.forEach((entity) => {
                                let entityObj = {};
                                entityObj.leid = entity.leid;
                                entityObj.cdr_no = entity.cdr_no;
                                entityObj.reporting_period = entity.reporting_period;
                                entityObj.system_flag = entity.SOURCE_SYSTEM_FLAG;
                                entityObj.le_type = vm.filter.lEType;
                                entityObj.le_name = entity.le_name;
                                entityObj.ein = entity.ein;
                                entityObj.entity_type = entity.entity_type;
                                entityObj.cd_key = '1';
                                entityObj.path = `/${vm.filterCatMapping[vm.filter.category]}/${vm.filterLocMapping[vm.filter.location]}/${vm.filter.lEType}/${entity.leid}`;
                                entities.push(entityObj);
                            });

                            selectedForms.forEach((form) => {
                                let formObj = {};
                                formObj.irs_form = form.form;
                                formObj.form_sub = form.form_sub ? form.form_sub.split('_')[0] : ''; // removes reporting period
                                formObj.form_sub_cat = form.form_sub_cat;
                                formObj.wpirs_form_no = form.is_whitepaper ? (form.irs_form_no.toLowerCase().startsWith('f') ? form.irs_form_no.substring(1)
                                                                                                                             : (form.irs_form_no.toLowerCase().startsWith('irs') ? form.irs_form_no.substring(3) : form.irs_form_no)) : ''; //removing F and IRS from formName
                                formObj.schedule = form.schedule;
                                formObj.line = form.line;
                                formObj.part_no = form.part_no;
                                forms.push(formObj);
                            });

                            let payload = {
                                entities: entities,
                                forms: forms
                            }

                            NewProformaFactory.pdfsBulkExport(
                                GlobalService.globalParams.tax_year,
                                GlobalService.globalParams.scenario,
                                '',//parentLeId
                                $scope.zippedExport ? 'Zip' : 'Merged',
                                'Dom',
                                vm.filter.category === 'C' ? '1120' : '1065',
                                vm.filter.category,
                                '250',
                                payload
                            ).then((response) => {
                                                  AlertService.add('success', 'Your Bulk Export Request is submitted!', 4000);
                                                  console.log(response);
                                              })
                                              .catch((err) => {
                                                  AlertService.add('warning', 'Something went wrong while sending Bulk Request!');
                                                  console.log(err.toString());
                                              })
                                              .finally(() => {
                                                  $scope.exportLoading = false;
                                                  $uibModalInstance.close();
                                              });
                        };
                    }]
            });
        };

        vm.isSelected = (item) => {
            return item.name === vm.selectedEntity;
        };

        vm.entitySelected = (item, sequentialCall = false) => {
            vm.pdfs = [];
            vm.isLoadPdf = false;
            vm.loadingForms = true;
            vm.noDataMsg = '';
            if (vm.selectedForm !== undefined) vm.expandPdfView = true;
            vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = vm.formSearchTerm = '';
            vm.leName = item.le_name;
            vm.leEin = item.ein;

            if (vm.filter.lEType === 'BSLA') {
                vm.selectedEntity = item.me_label;
                vm.selectedView = item.me_code;
                vm.cdrNo = item.cdr_no;
                vm.rpPeriod = item.reporting_period;
                vm.leName = item.me_label;
            } else {
                vm.selectedEntity = item.name;
                var pieces = item.value.split('/');
                vm.selectedView = pieces[0];
                vm.cdrNo = pieces[1];
                vm.rpPeriod = item.name.substring(item.name.lastIndexOf('/') + 1, item.name.lastIndexOf(')'));
            }

            const selectedCatLocal = vm.filter.category;
            const selectedLocLocal = vm.filter.location;
            const selectedLETypeLocal = vm.filter.lEType;
            const selectedEntityTypeLocal = vm.filter.entityType;
            const selectedViewLocal = vm.selectedView; // entity id (le_id/me_code)

            NewProformaFactory.resetStates();

            NewProformaFormReviewFactory.getDiagStatus(
                selectedCatLocal,
                GlobalService.globalParams.tax_year,
                GlobalService.globalParams.scenario,
                selectedLocLocal,
                selectedViewLocal,
                vm.cdrNo,
                vm.rpPeriod,
                selectedLETypeLocal,
                selectedEntityTypeLocal).then((response) => {
                if (response.data.callSuccess === '1') {
                    let diagList = response.data.diagList;
                    let failedList = diagList.filter((diag) => diag.diag_status === '0');
                    showEntityDiagModal(failedList, failedList.length, diagList.length);
                }
            });

            // read forms from cache
            if (getFormsFromCache(selectedCatLocal, selectedLocLocal, selectedLETypeLocal, selectedEntityTypeLocal, selectedViewLocal)) return;

            NewProformaFormReviewFactory.getTaxForms(vm.selectedView, vm.rpPeriod, vm.filter.lEType, vm.cdrNo, vm.filter.location, vm.filter.category).then(
                (response) => {
                    if (selectedCatLocal !== vm.filter.category || selectedLocLocal !== vm.filter.location ||
                        selectedLETypeLocal !== vm.filter.lEType || selectedEntityTypeLocal !== vm.filter.entityType
                        || selectedViewLocal !== vm.selectedView)
                        return; // ignore the response
                    handleForms(response, sequentialCall, selectedCatLocal, selectedLocLocal, selectedLETypeLocal, selectedEntityTypeLocal, selectedViewLocal);
                }
            ).catch(() => {
                if (selectedCatLocal !== vm.filter.category || selectedLocLocal !== vm.filter.location ||
                    selectedLETypeLocal !== vm.filter.lEType || selectedEntityTypeLocal !== vm.filter.entityType
                    || selectedViewLocal !== vm.selectedView)
                    return; // ignore the response
                vm.pdfs = [];
                vm.noDataMsg = 'No Forms Available!';
            }).finally(() => {
                vm.loadingForms = false;
            });
        };

        function getFormsFromCache(selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView) {
            if (vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView]) {
                vm.jobId = vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].jobId || '';
                vm.pdfs = vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].pdfs || [];
                vm.loadingForms = false;
                NewProformaFactory.sharedStates.showSelectAll = true;
                NewProformaFactory.sharedStates.showMerge = true;
                return true;
            }
            return false;
        }

        function handleForms(formsResponse, sequentialCall, selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView, jobId) {
            vm.loadingForms = false;
            NewProformaFactory.sharedStates.showBulkExport = true;

            if (formsResponse.data.taxForms && formsResponse.data.taxForms.length > 0) {
                NewProformaFactory.sharedStates.showSelectAll = true;
                NewProformaFactory.sharedStates.showMerge = true;
                vm.pdfs = [];
                var formTypes = JSON.parse(formsResponse.data.taxForms[0].jsonString);
                formTypes.forEach((formType) => {
                    const typeName = Object.keys(formType)[0];
                    const subTypes = formType[typeName];
                    subTypes.forEach((subType) => {
                        const forms = subType['attachment_form'];
                        forms.forEach((form) => {
                            form.checked = false;
                            form.label = form.irs_form && form.bucket_sub !== '5471' ? form.irs_form + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form_name : (form.form_name.toLowerCase().includes('schedule') ? form.form_name.replace('Schedule', '-') : form.form_name);
                            form.label = form.label.toLowerCase().startsWith('f') ? form.label.substring(1) : (form.label.toLowerCase().startsWith('irs') ? form.label.substring(3) : form.label);
                            form.label = form.subform && form.form_sub_cat === 'DIFF_PDF' ? form.label + '-' + form.subform : form.label;
                            form.le_name = vm.leName;
                            form.ein = vm.leEin;
                            vm.pdfs.push(form);
                        })
                    });
                });
                // sort forms based on form_order field
                //vm.pdfs.sort((a, b) => a.form_order > b.form_order ? 1 : -1);
                vm.pdfs.sort((a, b) => Number(a.form_order) > Number(b.form_order) ? 1 : -1);

                // getting job id on the forms
                jobId = vm.pdfs[0].job_id || jobId;

                // set forms cache
                vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView] = {};
                vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].pdfs = angular.copy(vm.pdfs);
                vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].jobId = angular.copy(jobId);

                // make sequential call
                if (sequentialCall) {
                    vm.loadPDF(vm.pdfs[0]);
                }
            } else {
                vm.pdfs = [];
                vm.noDataMsg = 'No Forms Available!';
            }
        }

        function getJobId(selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView) {
            if (vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView])
                return vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].jobId || '';
            else return '';
        }

        function showEntityDiagModal(diagList, failed, total) {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/components/newProforma/entity-diagnostics-modal.html',
                windowClass: 'entity-diag-modal',
                controller: ['$scope', '$uibModalInstance', 'workspaceFactory', function ($scope, $uibModalInstance, workspaceFactory) {
                    $scope.failed = failed;
                    $scope.total = total;
                    $scope.leMe = vm.filter.lEType === 'BSLA' ? 'ME Code' : 'LEID';
                    $scope.leMeCode = vm.selectedView;
                    $scope.dtInstance = {};
                    $scope.entityDiagTable = {};
                    // configure datatable settings
                    $scope.entityDiagTable.options = {
                        data: diagList,
                        extraOptions: {
                            componentSettings: {
                                tableOptions: {
                                    columns: [
                                        // {title: 'Diagnostic Key', data: 'diag_key', width: 70, orderable: false, className: 'data-column'},
                                        // {title: 'Diagnostic Code', data: 'diag_name', width: 70, orderable: false, className: 'data-column'},
                                        {title: 'Diagnostic Description', data: 'diag_desc', width: 200, orderable: false, className: 'data-column'},
                                        {
                                            title: 'Diagnostic Status',
                                            data: 'diag_status',
                                            width: 70,
                                            orderable: false,
                                            className: 'data-column text-center',
                                            render: function (data, type) {
                                                if (type !== 'display') return data;
                                                let color = '';
                                                if (data === '0') {
                                                    color = 'style="color: red;"';
                                                    return `<span ${color}>FAILED</span>`;
                                                } else {
                                                    color = 'style="color: #008700;"';
                                                    return `<span ${color}>PASSED</span>`;
                                                }
                                            }
                                        },
                                        {
                                            title: 'Count',
                                            data: null,
                                            width: 50,
                                            orderable: false,
                                            className: 'data-column text-center',
                                            render: function (data, type) {
                                                if (type === 'display') {
                                                    let numLink = Math.floor(Math.random() * 10) + 1;
                                                    return `<a href="#">${numLink}</a>`;
                                                }
                                            }
                                        },
                                    ],
                                    paging: false
                                }
                            }
                        }
                    };

                    $scope.goToSubDashboard = function () {
                        workspaceFactory.loadLink('Grid Standard', '', '1024', '10246', {}, {}, {}, '30227');
                        $uibModalInstance.close();
                    };
                }]
            })
        }

        vm.selectForm = function () {
            NewProformaFactory.sharedStates.enableMerge = vm.getSelectedPDFs().length > 0;
        };

        $scope.$on('selectAll::PDF', () => {
            vm.selectAllPDFs();
        });

        vm.selectAllPDFs = () => {
            vm.pdfs.forEach((pdf) => {
                pdf.checked = !NewProformaFactory.sharedStates.isSelectAll;
            });
            NewProformaFactory.sharedStates.isSelectAll = !NewProformaFactory.sharedStates.isSelectAll;
            NewProformaFactory.sharedStates.enableMerge = NewProformaFactory.sharedStates.isSelectAll;
        };

        vm.getSelectedPDFs = () => {
            let selectedPDFs = [];
            vm.pdfs.forEach((pdf) => {
                if (pdf.checked) {
                    selectedPDFs.push({'form_name': pdf.form_name, 'subform': pdf.subform && pdf.form_sub_cat === "DIFF_PDF" ? '-' + pdf.subform : ''});
                }
            });
            return selectedPDFs;
        };

        $scope.$on('merge::PDF', () => {
            vm.merge();
        });

        vm.merge = () => {
            const formNames = vm.getSelectedPDFs();
            const mergePopupRef = $uibModal.open({
                animation: true,
                template: `
                <div class="modal-header">
                  <div class="modal-title">Merge PDFs
                    <i ng-click="uibModalInstance.close()" class="fa fa-times pull-right"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div style="font-weight: bolder;">
                    The following forms will be merged into one PDF, are you sure you want to continue?
                  </div>
                  <div style="padding: 20px 20px;">
                    <span ng-if="isLoading">Please wait while we retrieve the data...</span>
                    <span ng-if="hasLoadErr" style="color: red;">Data not available, please try again!</span>
                    <div ng-if="!isLoading" ng-repeat="formName in formNames" style="margin-bottom: 5px;">
                      <span style="margin-right: 5px;">{{formName.form_name + formName.subform}}:</span>
                      <span><i ng-class="formName.exist ? 'fa-check' : 'fa-times'" class="fa" ng-style="{color: formName.exist ? 'green' : 'red'}"></i></span>
                    </div>
                  </div>
                  <div ng-if="hasInvalidForms">Note: forms marked with x do not exist, and will be excluded from merge.</div>
                </div>
                <div class="modal-footer">
                  <span ng-if="!isLoading && !isMergeValid" style="color: red; margin-right: 10px;">
                    Selection contains less than two valid forms!
                  </span>
                  <button class="btn btn-info btn-sm" data-ng-disabled="isLoading || !isMergeValid" ng-click="merge()">
                    Merge
                  </button>
                  <button class="btn btn-warning btn-sm" ng-click="uibModalInstance.close()">Cancel</button>
                </div>
                `,
                controller: ['$scope', '$uibModalInstance', 'formNames', 'WorkflowService', function ($scope, $uibModalInstance, formNames, WorkflowService) {
                    $scope.isLoading = true;
                    $scope.formNames = formNames;
                    $scope.uibModalInstance = $uibModalInstance;
                    var formNoList = formNames.map(formName => formName.form_name);

                    WorkflowService.getPdfpFormS3UrlList(formNoList).then((response) => {
                        if (response && response.callSuccess && response.result) {
                            $scope.s3List = response.result.filter(s3 => s3 !== null);
                            $scope.s3List.forEach((s3Url) => {
                                let existingFormList = formNames.filter((formName) => {
                                    return s3Url && formName.form_name === s3Url.irs_form_no;
                                });
                                if (existingFormList) {
                                    existingFormList.forEach((existingForm) => {
                                        existingForm.exist = true;
                                    });
                                }
                            });
                            $scope.hasInvalidForms = formNames.length > $scope.s3List.length;
                            $scope.isMergeValid = $scope.s3List.length >= 2;
                            $scope.isLoading = false;
                        } else {
                            $scope.isLoading = false;
                            $scope.hasLoadErr = true;
                        }
                    });

                    $scope.merge = function () {
                        $uibModalInstance.close($scope.s3List);
                    };
                }],
                resolve: {
                    formNames: () => formNames
                }
            });

            mergePopupRef.result.then((s3List) => {
                if (s3List) {
                    s3List = s3List.filter(s3 => s3 !== null);
                    const irsFormNoList = s3List.map(({irs_form_no}) => {
                        return irs_form_no;
                    });
                    const irsFormNoListIdent = s3List.map(({irs_form_no, subform}) => {
                        return irs_form_no + (subform && form_sub_cat === "DIFF_PDF" ? '-' + subform : '');
                    });
                    const s3UrlList = s3List.map(({s3_url}) => {
                        return s3_url;
                    });
                    vm.loadPDFs(irsFormNoList, irsFormNoListIdent, s3UrlList);
                }
            })
        };

        vm.loadPDF = (pdf) => {
            NewProformaFactory.sharedStates.showExpand = true;
            vm.expandPdfView = false;
            vm.isMerge = false;
            vm.s3UrlList = [];
            vm.taxYear = GlobalService.globalParams.tax_year;
            vm.selectedFormIdent = pdf.form_name + (pdf.subform && pdf.form_sub_cat === "DIFF_PDF" ? '-' + pdf.subform : '');
            vm.selectedForm = pdf.form_name;
            vm.irs_form_name = pdf.irs_form;
            vm.jobId = getJobId(vm.filter.category, vm.filter.location, vm.filter.lEType, vm.filter.entityType, vm.selectedView);
            vm.schedule = pdf.schedule;
            vm.le_type = vm.filter.lEType;
            vm.line = pdf.line_no;
            vm.partNo = pdf.part_no;
            vm.form_sub = pdf.subform;
            vm.form_sub_cat = pdf.form_sub_cat;
            vm.isLoadPdf = true;
            vm.le_name = pdf.le_name;
            vm.le_ein = pdf.ein;
            vm.scenarioLocal = GlobalService.globalParams.scenario;
            // call new proforma api
            // vm.callNewProforma = (vm.filter.category === 'P' && vm.filter.location === 'D') ||
            //     (!(clientName.toUpperCase().includes('AON') && vm.filter.lEType === 'BSLA') && pdf.is_new_proforma === '1');
        };

        vm.loadPDFs = (irsFormNoList, irsFormNoListIdent, s3UrlList) => {
            NewProformaFactory.sharedStates.showExpand = true;
            vm.expandPdfView = false;
            vm.isMerge = true;
            vm.s3UrlList = s3UrlList;
            vm.taxYear = GlobalService.globalParams.tax_year;
            vm.selectedForm = irsFormNoList;
            vm.selectedFormIdent = irsFormNoListIdent;
            vm.le_name = vm.leName;
            vm.le_ein = vm.leEin;
            vm.jobId = getJobId(vm.filter.category, vm.filter.location, vm.filter.lEType, vm.filter.entityType, vm.selectedView);
            vm.irs_form_name = vm.pdfs.filter(i => i.checked).map(({irs_form}) => {
                return irs_form
            });
            vm.schedule = vm.pdfs.filter(pdf => pdf.checked).map(({schedule}) => {
                return schedule
            });
            vm.line = vm.pdfs.filter(pdf => pdf.checked).map(({line_no}) => {
                return line_no
            });
            vm.partNo = vm.pdfs.filter(pdf => pdf.checked).map(({part_no}) => {
                return part_no
            });
            vm.form_sub = vm.pdfs.filter(pdf => pdf.checked).map(({subform}) => {
                return subform
            });
            vm.form_sub_cat = vm.pdfs.filter(pdf => pdf.checked).map(({form_sub_cat}) => {
                return form_sub_cat
            });
            // vm.is_new_proforma = vm.pdfs.filter(pdf => pdf.checked).map(({is_new_proforma}) => {
            //     return is_new_proforma
            // });
            vm.isLoadPdf = true;
            // call new proforma api under PSHIP / Domestic
            // vm.callNewProforma = vm.filter.category === 'P' && vm.filter.location === 'D';
        };

        vm.getSelectedForms = () => {
            return vm.selectedForm ? vm.selectedForm.toString() : '';
        };
    }

    return controllers;
});
