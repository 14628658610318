define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module(
					'app.localSysChartAcctMapDescController', []);

			controllers.controller('localSysChartAcctMapDescController', [
					'$rootScope', '$scope', '$http', '$timeout',
					'GlobalService', '$uibModalInstance', 'ModalFactory',
					'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG',
					'LocalDescGroupObjFactory', 'rowData', 'gridData',
					localSysChartAcctMapDescController ])

			var lookupData = [];
			var lookupData1 = [];
			var lookupData2 = [];
			var lookupData3 = [];
			var lookupData4 = [];
			function format(value, replace) {
				if (!value) {
					return value;
				}
				var target = value.toString();
				if (replace === undefined) {
					return target;
				}
				if (!angular.isArray(replace) && !angular.isObject(replace)) {
					return target.split('$0').join(replace);
				}
				var token = (angular.isArray(replace) && '$') || ':';

				angular.forEach(replace, function(value, key) {
					target = target.split(token + key).join(value);
				});
				return target;
			}
			controllers.directive('noSpecialChar', function() {
			    return {
			      require: 'ngModel',
			      restrict: 'A',
			      link: function(scope, element, attrs, modelCtrl) {
			        modelCtrl.$parsers.push(function(inputValue) {
			          if (inputValue == undefined)
			            return ''
			          var cleanInputValue = inputValue.replace(/[^\w\s-/:.?]/gi, '');
			          if (cleanInputValue != inputValue) {
			            modelCtrl.$setViewValue(cleanInputValue);
			            modelCtrl.$render();
			          }
			          return cleanInputValue;
			        });
			      }
			    }
			  });	
			controllers.value('customSelectDefaultsLocalSystemDesc', {
				displayText : 'Select...',
				emptyListText : 'There are no items to display',
				emptySearchResultText : 'No results match "$0"',
				addText : 'Add',
				searchDelay : 300,
			});

			controllers
					.directive(
							'customSelectLocalSystemDesc',
							[
									'$parse',
									'$compile',
									'$timeout',
									'$q',
									'customSelectDefaultsLocalSystemDesc',
									function($parse, $compile, $timeout, $q,
											baseOptions) {
										var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

										return {
											restrict : 'A',
											require : 'ngModel',
											link : function(scope, elem, attrs,
													controller) {
												var customSelect = attrs.customSelectLocalSystemDesc;
												if (!customSelect) {
													throw new Error(
															'Expected custom-select attribute value.');
												}
												scope
														.$watch(
																"ctrl.sys_acc.SYS_ACCT.SYS_ACCT",
																function() {
																	// scope.getChartAcctTableData();
																	scope
																			.$emit(
																					'SYS_ACCT_CHANGED',
																					'');
																});
												var match = customSelect
														.match(CS_OPTIONS_REGEXP);

												if (!match) {
													throw new Error(
															'Expected expression in form of '
																	+ "'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'"
																	+ " but got '"
																	+ customSelect
																	+ "'.");
												}

												elem
														.addClass('dropdown custom-select');

												// Ng-Options break down
												var displayFn = $parse(match[2]
														|| match[1]), valueName = match[3], valueFn = $parse(match[2] ? match[1]
														: valueName), values = match[4], valuesFn = $parse(values), track = match[5], trackByExpr = track ? ' track by '
														+ track
														: '', dependsOn = attrs.csDependsOn;

												var options = getOptions(), timeoutHandle, lastSearch = '', focusedIndex = -1, matchMap = {};

												var itemTemplate = elem.html()
														.trim()
														|| '{{'
														+ (match[2] || match[1])
														+ '}}', dropdownTemplate = '<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">'
														+ '<span>{{displayText}}</span>'
														+ '<b></b>'
														+ '</a>'
														+ '<div class="dropdown-menu">'
														+ '<div stop-propagation="click" class="custom-select-search">'
														+ '<input class="'
														+ attrs.selectClass
														+ '" type="text" autocomplete="off" ng-model="searchTerm" />'
														+ '</div>'
														+ '<ul role="menu">'
														+ '<li role="presentation" ng-repeat="'
														+ valueName
														+ ' in matches'
														+ trackByExpr
														+ '">'
														+ '<a role="menuitem" tabindex="-1" href ng-click="select('
														+ valueName
														+ ')">'
														+ itemTemplate
														+ '</a>'
														+ '</li>'
														+ '<li ng-hide="matches.length" class="empty-result" stop-propagation="click">'
														+ '<em class="muted">'
														+ '<span ng-hide="searchTerm">{{emptyListText}}</span>'
														+ '<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>'
														+ '</em>'
														+ '</li>'
														+ '</ul>'
														+ '<div class="custom-select-action">'
														+ (typeof options.onAdd === 'function' ? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
																: '')
														+ '</div>' + '</div>';

												// Clear element contents
												elem.empty();

												// Create dropdown element
												var dropdownElement = angular
														.element(dropdownTemplate), anchorElement = dropdownElement
														.eq(0).dropdown(), inputElement = dropdownElement
														.eq(1).find(':text'), ulElement = dropdownElement
														.eq(1).find('ul');

												// Create child scope for input
												// and dropdown
												var childScope = scope
														.$new(true);
												configChildScope();

												// Click event handler to set
												// initial values and focus when
												// the dropdown is shown
												anchorElement
														.on(
																'click',
																function(event) {
																	if (childScope.disabled) {
																		return;
																	}
																	childScope
																			.$apply(function() {
																				lastSearch = '';
																				childScope.searchTerm = '';
																			});

																	focusedIndex = -1;
																	inputElement
																			.focus();

																	// If filter
																	// is not
																	// async,
																	// perform
																	// search in
																	// case
																	// model
																	// changed
																	if (!options.async) {
																		getMatches('');
																	}
																});

												if (dependsOn) {
													scope
															.$watch(
																	dependsOn,
																	function(
																			newVal,
																			oldVal) {
																		if (newVal !== oldVal) {
																			childScope.matches = [];
																			childScope
																					.select(undefined);
																		}
																	});
												}

												// Event handler for key press
												// (when the user types a
												// character while focus is on
												// the anchor element)
												anchorElement
														.on(
																'keypress',
																function(event) {
																	if (!(event.altKey || event.ctrlKey)) {
																		anchorElement
																				.click();
																	}
																});

												// Event handler for Esc, Enter,
												// Tab and Down keys on input
												// search
												inputElement
														.on(
																'keydown',
																function(event) {
																	if (!/(13|27|40|^9$)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	switch (event.keyCode) {
																	case 27: // Esc
																		anchorElement
																				.dropdown('toggle');
																		break;
																	case 13: // Enter
																		selectFromInput();
																		break;
																	case 40: // Down
																		focusFirst();
																		break;
																	case 9: // Tab
																		anchorElement
																				.dropdown('toggle');
																		break;
																	}
																});

												// Event handler for Up and Down
												// keys on dropdown menu
												ulElement
														.on(
																'keydown',
																function(event) {
																	if (!/(38|40)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	var items = ulElement
																			.find('li > a');

																	if (!items.length)
																		return;
																	if (event.keyCode == 38)
																		focusedIndex--; // up
																	if (event.keyCode == 40
																			&& focusedIndex < items.length - 1)
																		focusedIndex++; // down
																	// if
																	// (!~focusedIndex)
																	// focusedIndex
																	// = 0;

																	if (focusedIndex >= 0) {
																		items
																				.eq(
																						focusedIndex)
																				.focus();
																	} else {
																		focusedIndex = -1;
																		inputElement
																				.focus();
																	}
																});

												resetMatches();

												// Compile template against
												// child scope
												$compile(dropdownElement)(
														childScope);
												elem.append(dropdownElement);

												// When model changes outside of
												// the control, update the
												// display text
												controller.$render = function() {
													setDisplayText();
												};

												// Watch for changes in the
												// default display text
												childScope.$watch(
														getDisplayText,
														setDisplayText);

												childScope
														.$watch(
																function() {
																	return elem
																			.attr('disabled');
																},
																function(value) {
																	childScope.disabled = value;
																});

												childScope
														.$watch(
																'searchTerm',
																function(
																		newValue) {
																	if (timeoutHandle) {
																		$timeout
																				.cancel(timeoutHandle);
																	}

																	var term = (newValue || '')
																			.trim();
																	timeoutHandle = $timeout(
																			function() {
																				getMatches(term);
																			},
																			// If
																			// empty
																			// string,
																			// do
																			// not
																			// delay
																			(term && options.searchDelay) || 0);
																});

												// Support for autofocus
												if ('autofocus' in attrs) {
													anchorElement.focus();
												}

												var needsDisplayText;
												function setDisplayText() {
													console.log(controller,
															"controller");
													var locals = {};
													locals[valueName] = controller.$modelValue;
													var text = displayFn(scope,
															locals);
													console.log(text, "text",
															options);
													if (text === undefined) {
														var map = matchMap[hashKey(controller.$modelValue)];
														if (map) {
															text = map.label;
														}
													}

													needsDisplayText = !text;
													childScope.displayText = text
															|| controller.$modelValue // text
																						// ||
																						// options.displayText;
												}

												function getOptions() {
													return angular
															.extend(
																	{},
																	baseOptions,
																	scope
																			.$eval(attrs.customSelectOptionsLocalSystemDesc));
												}

												function getDisplayText() {
													options = getOptions();
													return options.displayText;
												}

												function focusFirst() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														focusedIndex = 0;
														opts.eq(0).focus();
													}
												}

												// Selects the first element on
												// the list when the user
												// presses Enter inside the
												// search input
												function selectFromInput() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														var ngRepeatItem = opts
																.eq(0).scope();
														var item = ngRepeatItem[valueName];
														childScope
																.$apply(function() {
																	childScope
																			.select(item);
																});
														anchorElement
																.dropdown('toggle');
													}
												}

												function getMatches(searchTerm) {
													var locals = {
														$searchTerm : searchTerm
													};
													$q
															.when(
																	valuesFn(
																			scope,
																			locals))
															.then(
																	function(
																			matches) {
																		if (!matches)
																			return;

																		if (searchTerm === inputElement
																				.val()
																				.trim() /*
																						 * &&
																						 * hasFocus
																						 */) {
																			matchMap = {};
																			childScope.matches.length = 0;
																			for (var i = 0; i < matches.length; i++) {
																				locals[valueName] = matches[i];
																				var value = valueFn(
																						scope,
																						locals), label = displayFn(
																						scope,
																						locals);

																				matchMap[hashKey(value)] = {
																					value : value,
																					label : label /*
																									 * ,
																									 * model:
																									 * matches[i]
																									 */,
																				};

																				childScope.matches
																						.push(matches[i]);
																			}
																			// childScope.matches
																			// =
																			// matches;
																		}

																		if (needsDisplayText)
																			setDisplayText();
																	},
																	function() {
																		resetMatches();
																	});
												}

												function resetMatches() {
													childScope.matches = [];
													focusedIndex = -1;
												}

												function configChildScope() {
													childScope.addText = options.addText;
													childScope.emptySearchResultText = options.emptySearchResultText;
													childScope.emptyListText = options.emptyListText;

													childScope.select = function(
															item) {
														var locals = {};
														locals[valueName] = item;
														var value = valueFn(
																childScope,
																locals);
														// setDisplayText(displayFn(scope,
														// locals));
														childScope.displayText = displayFn(
																childScope,
																locals)
																|| options.displayText;
														controller
																.$setViewValue(value);

														anchorElement.focus();

														typeof options.onSelect === 'function'
																&& options
																		.onSelect(item);
													};

													childScope.add = function() {
														$q
																.when(
																		options
																				.onAdd(),
																		function(
																				item) {
																			if (!item)
																				return;

																			var locals = {};
																			locals[valueName] = item;
																			var value = valueFn(
																					scope,
																					locals), label = displayFn(
																					scope,
																					locals);

																			matchMap[hashKey(value)] = {
																				value : value,
																				label : label /*
																								 * ,
																								 * model:
																								 * matches[i]
																								 */,
																			};

																			childScope.matches
																					.push(item);
																			childScope
																					.select(item);
																		});
													};

													childScope.format = format;

													setDisplayText();
												}

												var current = 0;
												function hashKey(obj) {
													if (obj === undefined)
														return 'undefined';

													var objType = typeof obj, key;

													if (objType == 'object'
															&& obj !== null) {
														if (typeof (key = obj.$$hashKey) == 'function') {
															// must invoke on
															// object to keep
															// the right this
															key = obj
																	.$$hashKey();
														} else if (key === undefined) {
															key = obj.$$hashKey = 'cs-'
																	+ current++;
														}
													} else {
														key = obj;
													}

													return objType + ':' + key;
												}
											},
										};
									}, ]);

			controllers.directive('stopPropagation', function() {
				return {
					restrict : 'A',
					link : function(scope, elem, attrs, ctrl) {
						var events = attrs['stopPropagation'];
						elem.bind(events, function(event) {
							event.stopPropagation();
						});
					},
				};
			});
			
			
			function localSysChartAcctMapDescController($rootScope, $scope,
					$http, $timeout, GlobalService, $uibModalInstance,
					ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG,
					LocalDescGroupObjFactory, rowData, gridData) {

				var vm = this;
				vm.logged_in_user = USER_SETTINGS.user.sso_id;
				vm.accountsTableData = [];
				vm.sys_acc = {};
				vm.orderByField = 'FORM';
				vm.lookUpMapping = false;
				  $scope.crudLoading = false;
				vm.chartacct_data = {
					localacct : rowData.LOCAL_ACCT,
					localacctdesc : angular.copy(rowData.LOCAL_ACCT_DESC),
					localacctdesc_new : angular.copy(rowData.LOCAL_ACCT_DESC)

				}
				
				// Column names for the Audit tab, to be used in the view
				vm.viewColumnNames = ["Updated On", "Chart", "Local Account Description", "DML Type", "Old New Flag", "Updated By"];
				
				vm.reverse;
				
				// Initializing the sort_key object, which is used to track whether a column is being sorted in ascending or descending
				// order, or if it is not explicitly sorted
				// sort_key[i] = 0 means that it is not being sorted
				// sort_key[i] = 1 means that it is being sorted in ascending order
				// sort_key[i] = 2 means that it is being sorted in descending order
				vm.sort_key = {};
				for (var i = 1; i < 7; i++) {
					vm.sort_key[i] = 0;
				}
				
				vm.getAuditSummary = function() {
					var params = {
						"action_code": 'smi2fr',
						"sso_id": vm.logged_in_user,
						"local_acct_key": rowData.LOCAL_ACCT_KEY
					};
							
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
					   	vm.auditData = data.jsonObject;
					   	
					   	// Checking to see if any data was returned
					   	if (vm.auditData && vm.auditData.length) {
					   		vm.columnNames = Object.keys(vm.auditData[0]);
					   		vm.noRecords = false;
					   	} else {
					   		vm.noRecords = true;
					   	}
					});
				}
				
				// Function to handle sorting columns in Audit tab
				// colNum is the number of the column which was clicked
				// sortKey is the sort key which was passed from the HTML
				vm.sortBy = function(colNum, sortKey) {
					vm.column = vm.columnNames[colNum-1];
					if (sortKey === 0 || sortKey === 2) {
						vm.sort_key[colNum] = 1;
						vm.reverse = false;
					} else if (sortKey === 1) {
						vm.sort_key[colNum] = 2;
						vm.reverse = true;
					}
					
					// When one column is chosen to be sorted, we set the other columns to be labeled as
					// "neutral," that is, they are not being sorted on
					for (var i = 1; i < 9; i++) {
						if (i === Number(colNum)) {
							continue;
						} else {
							vm.sort_key[i] = 0;
						}
					}
				}

				// sys_acc_dropdown_data

				/**
				 * This function hits the DB and retrieves System as well as
				 * Local Data *
				 */
				//	 
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				vm.reset = function() {

					vm.chartacct_data = {
						localacct : rowData.LOCAL_ACCT,
						localacctdesc : angular.copy(rowData.LOCAL_ACCT_DESC),
						localacctdesc_new : angular.copy(rowData.LOCAL_ACCT_DESC)

					}
					 $scope.crudLoading = false;
				};

				vm.save = function() {
					
				 if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
			                return;
			            }
				
					$scope.crudLoading = true;
					var localAcountDescDetails = [];
					var returnObj = {};
					returnObj['type'] = 'E';
					returnObj["object_id"] = rowData.object_id;
					returnObj['local_acct_key'] = rowData.LOCAL_ACCT_KEY;
					returnObj['old_local_acct_desc'] = rowData.LOCAL_ACCT_DESC;
					returnObj["new_local_acct_desc"] = vm.chartacct_data.localacctdesc_new;
					returnObj['group_object_key'] = rowData.GROUP_OBJ_KEY;
					returnObj['tax_year'] = rowData.TAX_YEAR;
					returnObj['source_system'] = rowData.SOURCE_SYSTEM;
					localAcountDescDetails.push(returnObj);
					var message = "Local Acct Desc has been successfully save/updated";
					// objSettings.push(returnObj);
					sendDetails(returnObj,localAcountDescDetails,message)

					console.log(returnObj);

				};

				$scope.$on('sysForm.SYS_ACCT_CHANGED', function(event, data) {
					vm.getChartAcctTableData();
				});

				function sendDetails(returnObj, loaclacctdes, message) {
					LocalDescGroupObjFactory.saveLocalDesc(returnObj,
							loaclacctdes).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									 $scope.crudLoading = false;

								} else {
									AlertService.add("success", message, 4000);
									$uibModalInstance.dismiss('cancel');
									var args = {
											local_acct_key: returnObj.local_acct_key,
											sys_acct_key :  rowData.SYS_ACCT_KEY,
											chart_filter : rowData.LOCAL_CHART_KEY,
		                                    gridFilter: {
		                                    	local_acct_key: returnObj.local_acct_key,
		                                    	sys_acct_key :  rowData.SYS_ACCT_KEY,
		                                    	chart_filter : rowData.LOCAL_CHART_KEY
		                                    }
		                                };
		                                $rootScope.$emit('gridUpdate', args);
									/*vm.loadSystemAndLocalAccountData = angular
											.copy(loaclacctdes);*/
									// vm.init();

								}
							});

				}

			}
			return controllers;

		});
