
define([
    'angular',
    './ClientController',
    './ClientService',
    './ClientDirectives'


], function () {
    'use strict';
    return angular.module('app.client', ['app.ClientAdminController', 'app.clientAdminService','app.ClientAdminDirectives']);
});