define(
    ['angular'],
    function(){
        'use strict';
        let services =  angular.module('app.secondaryActionDashboardService',[])
            .factory('secondaryActionDashboardServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService', '$injector','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, $injector, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){

                let secondaryActionDashboardServiceFactory = {};
                secondaryActionDashboardServiceFactory.url = {};

                secondaryActionDashboardServiceFactory.getJsonData = function(key) {
                    var promise = $http({
                        method: "GET",
                        url: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=as2mqg&tracking_key=" + key
                    }).then(function(response) {
                        if(response.data.callSuccess === "1") {
                            return response.data;
                        } else {
                            AlertService.add("error", "No Data found", 4000);
                        }
                    });
                    return promise;
                }

                return secondaryActionDashboardServiceFactory;
            }])
        return services;
    }
);