define(["angular"], function () {
  "use strict";

  var controllers = angular
    .module("app.uploadPreformattedDiscCtrl", [])
    .controller("UploadPreformattedDiscUploadCtrl", [
      "GENERAL_CONFIG",
      "rowData",
      "USER_SETTINGS",
      "SERVER_CONFIG",
      "$rootScope",
      "JsonObjectFactory",
      "workspaceFactory",
      "AlertService",
      "$scope",
      uploadPreformattedDiscUploadCtrl,
    ]) 

  function uploadPreformattedDiscUploadCtrl(GENERAL_CONFIG, rowData, USER_SETTINGS,SERVER_CONFIG,$rootScope,JsonObjectFactory,workspaceFactory,AlertService,$scope) {
    const vm = this;

    vm.s3FileUploadInput = JSON.stringify({
      fileKey: null,
      baseUrl: GENERAL_CONFIG.admin_api_url,
      ssoId: USER_SETTINGS.user.sso_id,
      clientKey: USER_SETTINGS.user.client_key,
      path:'/gtw/dst/preformatteddisclosure/',
      readOnlyS3Paths: SERVER_CONFIG.configSettings.read_only_s3_paths,
      canEditPath:false,
      onlyCore:true
    });

    const cleanup = $rootScope.$on('on-s3-file-upload',function(event,data) {
      const response = JSON.parse(data);

      if(response.callSuccess === "1"){
        const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=mhe65v";

        const params = {
          sso_id: USER_SETTINGS.user.sso_id,
          tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
          file_name: response.fileName,
          file_path: response.filePath
        }
        JsonObjectFactory.saveJSON(url, params).then(function (data) {
            if (data.callSuccess === "1" && data.errorMessage == "null") {
                AlertService.add("success", "File uploaded and saved successfully", 4000);
                $rootScope.$broadcast('dataFilters:refreshGrid', {
                  "refresh": true
              });
            } else {
                console.log("error");
                AlertService.add("error",data.errorMessage,4000);
            }
        });
      }
    })


    $scope.$on('$destroy',function() {
      cleanup();
    })
  }


});
