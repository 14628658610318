const {VERSION} = require("lodash");

define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.formViewTabCtrl', [])
    .controller('FormViewDashboardCtrl', ['$scope', 'AlertService','NewProformaFactory','workspaceFactory', 
    '$timeout','GENERAL_CONFIG','dataFormatterFactory','GridFactory','NewProformaTabFactory','$state','$rootScope', FormViewDashboardCtrl])
    .controller('FormViewTabCtrl', ['$scope', 'dataFormatterFactory', 'NewProformaFactory','$stateParams', FormViewTabCtrl]);


    function FormViewDashboardCtrl($scope,AlertService,NewProformaFactory, workspaceFactory, 
        $timeout,GENERAL_CONFIG,dataFormatterFactory,GridFactory,NewProformaTabFactory,$state,$rootScope) {
        var vm = this;
        console.log('I am called');

        vm.isState = false;
        if ($state.current.name.includes('state-form-view')) {
            vm.isState = true;
        } else if ($state.current.name.includes('form-view')) {
            vm.isState = false;
        }

        $scope.$on("proformaView.addTab", function (event, data) {
            console.log('first');
            vm.addTabData(data);
        });
        if($state.current.name=='form-view.element-view'){
            $state.go('form-view.dashboard');
        }
        $scope.tabList = [];
        vm.tabList=[];
        vm.formReviewActive = true;
        vm.formReviewTab = function() {
           vm.formReviewActive = true; 
        }
        vm.addTabData = function (data) {
            var tooltip = data.leName + '-'+data.elementName//data.elementName;
            var isFound = _.find(vm.tabList, function (item) {
                return item.code === tooltip;
            }) 
            if (isFound != undefined) {
                AlertService.add("warn", "Tab is already open ", 1000);
                setTimeout(function() {
                    $state.transitionTo('form-view.element-view',{id: isFound.code})
                 });
                  vm.workflowTab(isFound);
            }  
            //combination_key + elementName
            else {
                var entity = {
                    name: data.leName,
                    code: data.leName + '-'+data.elementName,
                    data: data,
                    tooltip: tooltip,
                    loading_data:false,
                    link: 'form-view.element-view({'+'id:'+data.elementName+'})',
                    active:false ,
                    elementName: data.elementName 
                }
                vm.tabList.push(entity);
                NewProformaFactory.setTabData(vm.tabList);
                $scope.tabList = NewProformaFactory.tabList;
                $timeout(function() {
                    if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
                        vm.scrlTabsApi.init();
                    }
                });
                setTimeout(function() {
                    $state.transitionTo('form-view.element-view',{id: entity.code})
                 });
                  vm.workflowTab(entity);
            }
            
            console.log('tabList',vm.tabList);
               /*  setTimeout(function () {
                    $scope.$apply();
                }, 500); */
        }
        
       
        vm.updateFn = function (arg) {
            var obj = [];
             obj = vm.tabList.find(link => link.code === arg.tabScrollTitle);
           setTimeout(function() {
              $state.go('form-view.element-view',{id: arg.tabScrollTitle})
           });
            vm.workflowTab(obj);
        }
        if(NewProformaFactory.tabList && NewProformaFactory.tabList.length>0){
            vm.tabList = NewProformaFactory.tabList;
        }
     
        vm.cancel = function (tab) {
            var index = _.findIndex(vm.tabList, tab);
            vm.tabList.splice(index, 1);
            vm.tabId = '';
            // redirecting to formreview list page if no tabs
            if(vm.tabList.length == 0) {
                setTimeout(function() {
                    $state.transitionTo('form-view.dashboard');
                });
            }
            
            NewProformaFactory.setTabData(vm.tabList)

        }
        // used to clear data to avoid styling conflicts on workflow tab selections.
        vm.tabId = '';
        vm.workflowTab = function(tabData) {
            vm.formReviewActive = false;
            vm.tabId = tabData;
            var tabId = vm.tabId && vm.tabId.code;
            vm.tabList = _.each(vm.tabList,function(i){
                if(i.active){
                    i.active = !i.active;
                }
                if(i.code == tabData.code){
                    i.active = true;
                }
            })
            var _data = _.filter(NewProformaFactory.tabList,{code:tabId});
            _data.active = true;
            vm.leName = _data[0].data.leName;
            vm.schedule = _data[0].data.schedule;
            vm.line = _data[0].data.line;
            vm.colId = _data[0].data.colId;
            
            vm.lineTotal = dataFormatterFactory.getFormatter("Number").func(_data[0].data.lineTotal.split(',').join(''));
            vm.params = {
                sso_id: _data[0].data.sso,
                tax_year: _data[0].data.taxYear,
                scenario: _data[0].data.scenario,
                jcd_key: _data[0].data.jcdKey,
                source_system: _data[0].data.sourceSystem,
                summary_level: _data[0].data.summaryLevel,
                me_codes: _data[0].data.meCodes,
                combination_key:_data[0].data.combinationKeys,
                form: _data[0].data.form,
                schedule: _data[0].data.schedule,
                part_no: _data[0].data.partNo,
                line: _data[0].data.line,
                element: _data[0].data.elementName
            }
            $scope.display_filter_data = "";
            for (const key in vm.params) {
                $scope.display_filter_data += `&${key}=${vm.params[key]}`
            }
            $timeout(function() {
                getData(tabId);
            }, 5)
          $scope.action_key = _data[0].data.sourceSystem === "F" ? 32440 :32033;
          $scope.gridKey = _data[0].data.sourceSystem === "F" ? 5859: 5719;
        }
        // vm.api = GENERAL_CONFIG.base_url +  "/loadGridJson?action_id="+$scope.action_key+"&grid_id=5719"
        $scope.ctrlFn = function(_callName,_data,_type) {
        }
        var getData = function(tabId) {  
            vm.api = GENERAL_CONFIG.base_url +  "/loadGridJson?action_id="+$scope.action_key+"&grid_id="+$scope.gridKey         
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var data = {
                api: vm.api,
                params: vm.params,
                tabId: tabId
            }
            setTimeout(function(){  
                $rootScope.$broadcast("showLoader", true);           
                $rootScope.$apply();
            },500);
            NewProformaTabFactory.getTabGridData(data).then(function(response){
                if (response.gridData && response.gridData.callSuccess === "1") {
                    vm.data = response.gridData;
                    console.log('response',response); 
                    if(vm.tabId.code == response.code){
                        setTimeout(function(){  
                            $rootScope.$broadcast("dataLoaded", response.gridData);
                        },100); 
                        setTimeout(function(){  
                            $rootScope.$broadcast("showLoader", false);           
                            $rootScope.$apply();
                        },500);      

                    }
                             
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            })
        }
        $scope.openModal = function (modalObj,_data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
    }

    function FormViewTabCtrl($scope, dataFormatterFactory, NewProformaFactory,$stateParams) {
        const vm = this;
        var tabId = $stateParams.id;

        
        var _data = _.filter(NewProformaFactory.tabList,{code:tabId});
        $scope.actionKey = _data[0].data.sourceSystem === "F" ? 32440: 32033;
        $scope.gridKey = _data[0].data.sourceSystem === "F" ? 5859: 5719;
        $scope.tabId = tabId;
       
        vm.api = "/loadGridJson?action_id="+$scope.actionKey+"&grid_id="+$scope.gridKey;
        if(_data && _data.length>0){
            vm.leName = _data[0].data.leName;
            vm.schedule = _data[0].data.schedule;
            vm.line = _data[0].data.line;
            vm.colId = _data[0].data.colId;
            
            vm.lineTotal = dataFormatterFactory.getFormatter("Number").func(_data[0].data.lineTotal.split(',').join(''));
            vm.params = {
                sso_id: _data[0].data.sso,
                tax_year: _data[0].data.taxYear,
                scenario: _data[0].data.scenario,
                jcd_key: _data[0].data.jcdKey,
                source_system: _data[0].data.sourceSystem,
                summary_level: _data[0].data.summaryLevel,
                me_codes: _data[0].data.meCodes,
                combination_key:_data[0].data.combinationKeys,
                form: _data[0].data.form,
                schedule: _data[0].data.schedule,
                part_no: _data[0].data.partNo,
                line: _data[0].data.line,
                element: _data[0].data.elementName
            };

            $scope.display_filter_data = "";
            for (const key in vm.params) {
                $scope.display_filter_data += `&${key}=${vm.params[key]}`
            }
        }


        
    }


    return controllers;
});

