define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.epAdjustmentsController', [])
            .controller('EpAdjustmentsController', ['$rootScope','$scope', '$anchorScroll', '$uibModalInstance', 'AlertService', 'CodeComboFactory', 'rowData', 'JsonObjectFactory', 'EPTaxAdjustmentsFactory','GENERAL_CONFIG', epAdjustmentsController]);



    function epAdjustmentsController($rootScope, $scope, $anchorScroll, $uibModalInstance, AlertService, CodeComboFactory, rowData, JsonObjectFactory, EPTaxAdjustmentsFactory,GENERAL_CONFIG) {
        var vm = this;
        //call init() once all the function expressions used by the view model are defined
        
        vm.showDetails = function (_index, _boolean) {
            vm.existingAdjustments[_index].open = _boolean;
        };
        
        vm.getAccounts = function(val, acctFor) {
             
            var balSheetAccts = [1, 2, 3];
            var acctType = vm.sourceAcctType;
            var acct_type_str = "", parent_acct_key;
           
            if (balSheetAccts.indexOf(acctType) >= 0)  {
                acct_type_str = '1,2,3';
            } else {
                acct_type_str = '5,6,7';
            }           
           
            parent_acct_key= (acctFor === 'OFFSET' ? vm.parentObject.ACCT_KEY : 0);
           
            return  EPTaxAdjustmentsFactory.getAccounts(val, vm.mainScreenKey, vm.rowData.GE_GECS_ENTITY, vm.rowData.SOURCE_SYSTEM, acct_type_str,parent_acct_key);
           
        };
        
        vm.setAccount = function (account, acctFor) {
            
            //validateAcctLineNo(account.FROM_ACCT_TYPE, acctFor);
            if(acctFor === 'PARENT') {
                vm.parentObject["ACCT_KEY"] = account.SYSTEM_ACCOUNT_KEY;
                vm.parentObject["ACCT_TYPE"] = account.FROM_ACCT_TYPE;
            } else {
                vm.offsetObject["ACCT_KEY"] = account.SYSTEM_ACCOUNT_KEY;
                vm.offsetObject["ACCT_TYPE"] = account.FROM_ACCT_TYPE;
                vm.isOffSetAcctExists = true;
            }
            
        };
        
        vm.changeSchd = function(){
           
           vm.parentObject["SCHD_LINE_NO"] = vm.getLineDetails.selected.LINE_NO;
           //validateAcctLineNo(vm.parentObject["ACCT_TYPE"], 'PARENT');
        };
        
        vm.changeOffsetSchd = function(){
           vm.offsetObject["SCHD_LINE_NO"] = vm.getOffsetLineDetails.selected.LINE_NO;
        };
       
        
        vm.getTradingPartners = function (val) {
            return EPTaxAdjustmentsFactory.getTradingPartners($scope.taxYear, $scope.scenario, val).then(function(res) {
                return res;
            });            
        };
        
        vm.setTradingPartner = function (tp) {           
            vm.parentObject["TP_KEY"] = tp.TP_KEY;            
        };
        
        vm.changeDCSType = function() {
            vm.dcsHeaderObj["SCHDM_TYPE"] = vm.getDcsAdjsDetails.selected.ADJ_TYPE;
        };
        
        vm.changeProject = function() {
            if(vm.projectsList.selected !== null) {
                
                var unsortedProjectSteps;
                unsortedProjectSteps = _.filter(vm.allProjectsWithSteps,{'PROJECT_ID': vm.projectsList.selected.PROJECT_ID});

                vm.projectSteps.list = _.sortBy(unsortedProjectSteps, ['STEP_SHORT_DESCRIPTION']);
                vm.parentObject["PROJECT_ID"] = vm.projectsList.selected.PROJECT_ID;
            } else {
                vm.projectSteps.list = [];
                vm.projectSteps.selected = null;
                vm.parentObject["PROJECT_ID"] = 0;
                vm.parentObject["PROJECT_STEP_ID"] = 0;    
            }
        };
       
        
        vm.changeProjectStep = function() {
            if(vm.projectsList.selected !== null) {
                vm.parentObject["PROJECT_STEP_ID"] = vm.projectSteps.selected.PROJECT_STEP_ID;
            } else {
                vm.parentObject["PROJECT_STEP_ID"] = 0;
            }
        };
        
        vm.changePostingGLAcct = function() {
            vm.parentObject["POSTING_GL_ACCT_KEY"] = vm.getDCSPLAcctDetails.selected.GL_ACCT_KEY;
        };
        
        vm.save = function() {
            
            if ($scope.adjsForm.$invalid) {
                $scope.adjsForm.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            var headerSettings = [];
            var transactions =[];            
            var headerObj = {};

            headerObj.ADJ_DESC = vm.ADJ_DESC;
            
            headerObj.ADJ_ORIGIN_SOURCE = vm.rowData.SOURCE_SYSTEM;
            headerObj.ADJ_SOURCE_TYPE = (headerObj.ADJ_ORIGIN_SOURCE === 'DCS') ? "" : vm.ADJ_SOURCE_TYPE;
            headerObj.SUPPL_INFO = vm.SUPPL_INFO;
            headerObj.TYPE = (vm.TRANS_HEADER_KEY === 0) ? "S" : "U";
            headerObj.TRANS_HEADER_KEY = vm.TRANS_HEADER_KEY;
            
            headerObj.SOURCE_SYSTEM_FLAG = (headerObj.ADJ_ORIGIN_SOURCE === 'DCS') ? 'D':'F'; 
                        
            headerObj.CHART_KEY = vm.rowData.CHART_KEY;
            headerObj.COMBINATION_KEY = vm.rowData.COMBINATION_KEY;
            
            if(headerObj.ADJ_ORIGIN_SOURCE === 'DCS') {
                _.merge(headerObj, vm.dcsHeaderObj);
            }
            
            headerSettings.push(headerObj);
            var message = (headerObj.type === "U") ? "E&P Adjustments have been successfully updated" : "E&P Adjustments have been successfully created";
            
            //for main parent adjustment
            var adjModel = new adjustment_model(vm.parentObject["ACCT_KEY"], vm.parentObject["CHART_KEY"], vm.parentObject["ADJ_AMT"], 
                                                vm.parentObject["MULTIPLIER"], vm.parentObject["PROJECT_ID"], 
                                                vm.parentObject["PROJECT_STEP_ID"], vm.parentObject["AUTO_REVERSE_YN"], 
                                                vm.parentObject["SCHD_LINE_NO"], vm.parentObject["TP_KEY"], vm.parentObject["TRANS_DETAILS_KEY"],
                                                vm.parentObject["POSTING_GL_ACCT_KEY"]);
            transactions.push(adjModel);
            
            //for offset accounts
            if(vm.isOffSetAcctExists) {
                var adjModel = new adjustment_model(vm.offsetObject["ACCT_KEY"], vm.offsetObject["CHART_KEY"], vm.offsetObject["ADJ_AMT"], 
                                                    vm.offsetObject["MULTIPLIER"], vm.offsetObject["PROJECT_ID"], 
                                                    vm.offsetObject["PROJECT_STEP_ID"], vm.offsetObject["AUTO_REVERSE_YN"], 
                                                    vm.offsetObject["SCHD_LINE_NO"], vm.offsetObject["TP_KEY"], vm.offsetObject["TRANS_DETAILS_KEY"],"");

                transactions.push(adjModel);                
            }
            
            //in edit mode, if there are existing offset adjustments that needs to be updated
            if(vm.isEdit && vm.existingOffsetAdjustments.length) {
                 _.forEach(vm.existingOffsetAdjustments, function (value, key) {
                    var adjModel = new adjustment_model(value.ACCT_KEY, value.CHART_KEY, value.ADJ_AMT, value.MULTIPLIER, value.PROJECT_ID, value.PROJECT_STEP_ID, value.AUTO_REVERSE_YN, value.SCHD_LINE_NO, value.TP_KEY, value.TRANS_DETAILS_KEY,"");
                    transactions.push(adjModel);
                });
                
            }

            EPTaxAdjustmentsFactory.saveUpdateAdjustments(headerSettings, transactions).then(function (result) {
                   
                if (result.errorMessage && result.errorMessage !== 'null') {
                    AlertService.add("error", result.errorMessage, 4000);
                } else {
                    AlertService.add("success", message, 4000);
                    //BROADCAST CHANGE
                    //$uibModalInstance.close();
                    /*var args = {combination_keys: headerSettings.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: headerSettings.COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);*/
                    init();
                }
            });
        };
        
        vm.deleteAdjustment = function(adjs, deleteType) {
            var returnObj = {};
            var message = (deleteType==="PARENT")?"Adjustment has been successfully deleted": "Offset Adjustment has been successfully deleted";
            returnObj.trans_header_key = adjs.TRANS_HEADER_KEY;
            returnObj.delete_type = deleteType;
            if(deleteType === "OFFSET") {
                returnObj.trans_details_key = adjs.TRANS_DETAILS_KEY;
            }
            EPTaxAdjustmentsFactory.deleteAdjustment(returnObj, deleteType).then(function (result) {
                   
                if (result.errorMessage && result.errorMessage !== 'null') {
                    AlertService.add("error", result.errorMessage, 4000);
                } else {
                    AlertService.add("success", message, 4000);
                    //BROADCAST CHANGE
                    //$uibModalInstance.close();
                   /*
                    var args = {combination_keys: returnObj.combination_key, gridFilter: {COMBINATION_KEY: returnObj.combination_key}};
                    $rootScope.$emit('gridUpdate', args);*/
                    init();
                }
            });
        };
        
        vm.cancel = function() {
            var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
            
            //var args = {};
            $rootScope.$emit('gridUpdate', args);
            $uibModalInstance.dismiss('cancel');    
        };
        
        vm.reset = function () {
            init();
        };
        
        vm.loadAdjustment = function(_index) {
           
            var _data = vm.existingAdjustments[_index];
            
            var lineIndex = 0, projectIdIndex = 0, projectStepIdIndex = 0, postingGLIndex = 0, dcsAdjTypeIndex=0;
            vm.ADJ_DESC = _data.ADJ_DESC;
            vm.SUPPL_INFO = _data.SUPPL_INFO;
            vm.ADJ_SOURCE_TYPE = _data.ADJ_SOURCE_TYPE;
            vm.TRANS_HEADER_KEY = _data.TRANS_HEADER_KEY;
            vm.isEdit = true;
            vm.dcsHeaderObj["RECUR_FLAG"] = _data.ACCOUNTS[0].RECUR_FLAG;
            vm.projectsList.selected = null;
            vm.projectSteps.selected = null;
           
            for(var prop in vm.parentObject) {
                if (_data.ACCOUNTS[0].hasOwnProperty(prop)) {
                    vm.parentObject[prop] = _data.ACCOUNTS[0][prop];
                } 
            }
            
            if(_data.ACCOUNTS[0].TP_KEY !== 0) {
                    vm.tpObject = {
                    "TP_ENTITY": _data.ACCOUNTS[0].TP_LEID + "-" + _data.ACCOUNTS[0].TP_CDR_NO + "(Pd:" + _data.ACCOUNTS[0].TP_REP_PD + ")",
                    "TP_KEY": _data.ACCOUNTS[0].TP_KEY,
                    "TP_REP_PD": _data.ACCOUNTS[0].TP_REP_PD,
                    "TP_LEID": _data.ACCOUNTS[0].TP_LEID,
                    "TP_CDR_NO": _data.ACCOUNTS[0].TP_CDR_NO
                };

            }
            
          
            lineIndex = _.findIndex(vm.getLineDetails.list, {'LINE_NO': vm.parentObject.SCHD_LINE_NO});
            vm.getLineDetails.selected = vm.getLineDetails.list[lineIndex];

            if(vm.parentObject.PROJECT_ID !== 0) {
                projectIdIndex = _.findIndex(vm.projectsList.list, {'PROJECT_ID': vm.parentObject.PROJECT_ID});
                vm.projectsList.selected = vm.projectsList.list[projectIdIndex];
                vm.changeProject();
                projectStepIdIndex = _.findIndex(vm.projectSteps.list, {'PROJECT_STEP_ID': vm.parentObject.PROJECT_STEP_ID});
                vm.projectSteps.selected = vm.projectSteps.list[projectStepIdIndex];
            }
            
            if(vm.parentObject.POSTING_GL_ACCT_KEY) {
                postingGLIndex = _.findIndex(vm.getDCSPLAcctDetails.list, {'GL_ACCT_KEY': vm.parentObject.POSTING_GL_ACCT_KEY});
                vm.getDCSPLAcctDetails.selected = vm.getDCSPLAcctDetails.list[postingGLIndex];
            }
            
            
            dcsAdjTypeIndex = _.findIndex(vm.getDcsAdjsDetails.list, {'ADJ_TYPE': _data.ACCOUNTS[0].ADJ_TYPE});               
            vm.getDcsAdjsDetails.selected = vm.getDcsAdjsDetails.list[dcsAdjTypeIndex];
            
            
            if(_data.ACCOUNTS.length > 1) {
                
                vm.existingOffsetAdjustments = _.filter(_data.ACCOUNTS, function(adjObj){
                   return adjObj.ROW_INDICATOR > 2; 
                });
                
            }
            //scroll to top
            $anchorScroll('mainSection');
            
        };
        
        function adjustment_model(acct_key, chart_key, adj_amt, multiplier, project_id, project_step_id, auto_reverse_yn, schd_h_line_no, 
                                  tp_key, trans_details_key, posting_gl_acct_key) {
            this.ACCT_KEY = acct_key;
            this.CHART_KEY = chart_key;
            this.ADJ_AMT = adj_amt;
            this.MULTIPLIER = multiplier;
            this.PROJECT_ID = project_id;
            this.PROJECT_STEP_ID = project_step_id;
            this.AUTO_REVERSE_YN = auto_reverse_yn;
            this.SCHD_LINE_NO = schd_h_line_no;
            this.TP_KEY = tp_key;
            this.TRANS_DETAILS_KEY = trans_details_key;
            this.POSTING_GL_ACCT_KEY = posting_gl_acct_key;
           
        }
        
        function init() {
             
            vm.ADJ_DESC = "";
            vm.SUPPL_INFO = "";
            vm.offsetAcct = '';
            
            vm.rowData = rowData;
            vm.title = "Create/Edit E&P Adjustments";
            vm.combination_key = vm.rowData.COMBINATION_KEY;
            vm.le_tax_type = vm.rowData.LE_TAX_TYPE;
            vm.mainScreenKey = EPTaxAdjustmentsFactory.getActiveScreen();
            vm.ADJ_SOURCE_TYPE = 'MA';
            vm.isadjTypeDisabled = false;
            vm.showAdjsForm = false;
            vm.SOURCE_SYSTEM = vm.rowData.SOURCE_SYSTEM;
            vm.existingAdjustments = [];
            vm.existingOffsetAdjustments = [];
            vm.taxAdjustments = [];
            vm.sourceAcctType = rowData.TO_ACCT_TYPE;

            vm.TRANS_HEADER_KEY = 0;
            vm.crudLoading = false;
            vm.isOffSetAcctExists = false;
            vm.isEdit = false;
            
            vm.allProjectsWithSteps = [];
            vm.projectsList = {selected:null, list:[], disabled:true};
            vm.projectSteps = {selected:null, list:[], disabled:true}; 
            vm.getLineDetails = {selected:null, list:[], disabled:true};
            vm.getOffsetLineDetails = {selected:null, list:[], disabled:true};
            vm.getDcsAdjsDetails = {selected:null, list:[], disabled:true};
            vm.getDCSPLAcctDetails = {selected:null, list:[], disabled: true};
            vm.parentObject = {};  
            vm.offsetObject = {}; 
            vm.dcsHeaderObj = {};
          //dcs title
            if( vm.SOURCE_SYSTEM == 'DCS')
            	{
            	vm.title = "SCHDM Adjustments"
            	}

            if ($scope.adjsForm !== undefined) {
                $scope.adjsForm.$setPristine();
                $scope.adjsForm.$setUntouched();
            }
         
            if (vm.rowData.COMBINATION_KEY) {
                
                CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                CodeComboFactory.showFormBtn = false;
                
                initAdjustments();                
                loadExistingAdjustments();                
                getAllLineDetails();                
                getProjectsAndSteps();
                getDcsAdjsTypes();
                getDCSLineDetails();
                getDCSPLDetails();
            } else {

                console.log("nnnnnnnnnnnnnnnnn $scope.rowData.COMBINATION_KEY NOT FOUND ", vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = true;
                alert("yyyyyyyy $scope.rowData.COMBINATION_KEY not FOUND");
            }
        };
        
        init();
        
        $scope.$on('CCKeySet', function (event, data) {
            vm.showAdjsForm = true;
            
        });

        function initAdjustments() {
            
            vm.parentObject = {
                "CHART_KEY": vm.rowData.CHART_KEY,
                "ACCT_KEY": vm.rowData.SYSTEM_ACCOUNT_KEY,
                "ACCT_DD_DISPLAY": vm.rowData.ACCT + '-' + vm.rowData.ACCT_DESC,
                "ACCT_TYPE": vm.rowData.TO_ACCT_TYPE,
                "SCHD_LINE_NO":(vm.rowData.SOURCE_SYSTEM==='DCS') ? "" : "0", 
                "PRIOR_YEAR_AMT": vm.rowData.BOY_AMT,
                "CURR_YEAR_AMT": vm.rowData.BOOK_AMT,
                "ADJ_AMT": 0,
                "MULTIPLIER": (vm.rowData.SOURCE_SYSTEM==='DCS') ? "" : 0, 
                "TP_KEY": vm.rowData.TP_KEY,
                "PROJECT_ID": 0,
                "PROJECT_STEP_ID": 0,
                "AUTO_REVERSE_YN": (vm.rowData.SOURCE_SYSTEM==='DCS') ? "" : "N",
                "TRANS_DETAILS_KEY": 0,
                "IS_ACCT_EDITABLE": "N",
                "STANDARD_M1_DESC": "",
                "M3_FORM_LINE": "",
                "POSTING_GL_ACCT_KEY": ""
                
            };
            
            //Offset Object  does not require all properties for view model but needs to save null or 0 in database rows, so including them
            vm.offsetObject = {
                "CHART_KEY": vm.rowData.CHART_KEY,
                "ACCT_KEY": "",
                "ACCT_DD_DISPLAY": "",
                "ACCT_TYPE": "",
                "SCHD_LINE_NO":"0",  
                "PRIOR_YEAR_AMT": 0,
                "CURR_YEAR_AMT": 0,
                "ADJ_AMT": 0,
                "MULTIPLIER": 0,
                "TP_KEY": 0,
                "PROJECT_ID": 0,
                "PROJECT_STEP_ID": 0,
                "AUTO_REVERSE_YN": "N",
                "TRANS_DETAILS_KEY": 0,
                "IS_ACCT_EDITABLE": "Y"
            };
            
            //dcs specific header properties
             vm.dcsHeaderObj = {
                "DR8A_LINE_NO": "",
                "ZERO_BK_AMT_YN": (vm.rowData.BOOK_AMT === 0) ? 'Y' : 'N',
                "PROVISION_YN": "N",
                "RECUR_FLAG": "N",
                "SETT_CODE": "S",
                "METHOD_OF_CALC": "",
                "SCHDM_TEMPLATE_KEY": vm.rowData.SCHDM_TEMPLATE_KEY,
                "SCHDM_TYPE": ""
            };
            
            if(vm.rowData.TP_KEY !== 0) {
                vm.tpObject = {
                    "TP_ENTITY": vm.rowData.TP_LEID + "-" + vm.rowData.TP_CDR_NO + "(Pd:" + vm.rowData.TP_REP_PD + ")",
                    "TP_KEY": vm.rowData.TP_KEY,
                    "TP_REP_PD": vm.rowData.TP_REP_PD,
                    "TP_LEID": vm.rowData.TP_LEID,
                    "TP_CDR_NO": vm.rowData.TP_CDR_NO
                };
            }
            
        
        };
        
        function getAllLineDetails() {
            EPTaxAdjustmentsFactory.getLineDetails(vm.mainScreenKey).then(function(res) {
                vm.getLineDetails.list = res;
                vm.getOffsetLineDetails.list = res;
                vm.getLineDetails.selected = vm.getLineDetails.list[0];
                vm.getOffsetLineDetails.selected = vm.getOffsetLineDetails.list[0];
                
            });
        };
        
        function getDCSLineDetails() {
            var dcsAdjTypeIndex = 0;
            EPTaxAdjustmentsFactory.getDCSLineDetails(vm.rowData.ACCT, vm.rowData.SCHDM_TEMPLATE_KEY).then(function(res) {
               
                if(res.length) {
                    dcsAdjTypeIndex = _.findIndex(vm.getDcsAdjsDetails.list, {'ADJ_TYPE': res[0].SCHDM_TYPE});
               
                    vm.getDcsAdjsDetails.selected = vm.getDcsAdjsDetails.list[dcsAdjTypeIndex];
                    vm.parentObject["STANDARD_M1_DESC"] = res[0].SCHDM_DESC;
                    vm.parentObject["M3_FORM_LINE"] = res[0].FORM_LINE_1120_M3;

                    vm.dcsHeaderObj["DR8A_LINE_NO"] = res[0].DR8A_LINE_NO;
                    vm.dcsHeaderObj["METHOD_OF_CALC"] = res[0].METHOD_OF_CALC;
                    vm.dcsHeaderObj["SCHDM_TYPE"] = vm.getDcsAdjsDetails.selected.ADJ_TYPE;
                }
                
                
            });
        };
        
        function getDCSPLDetails() {
            EPTaxAdjustmentsFactory.getDCSPLDetails(vm.rowData.ACCOUNT_KEY, vm.rowData.LOCAL_CHART_KEY).then(function(res) {
                if(res.length) {
                    vm.getDCSPLAcctDetails.list = res;
                    vm.getDCSPLAcctDetails.selected = res[0];
                    vm.parentObject["POSTING_GL_ACCT_KEY"] = vm.getDCSPLAcctDetails.selected.GL_ACCT_KEY;
                }
                
            });
        };
        
        function getProjectsAndSteps() {
            EPTaxAdjustmentsFactory.getProjectsAndSteps($scope.taxYear).then(function(res) {
               
                vm.allProjectsWithSteps = res;
                
                vm.projectsList.list = _.map(_.uniqBy(res, 'PROJECT_ID'), function (item) {
                    return {
                        PROJECT_ID: item.PROJECT_ID,
                        PROJECT_NAME: item.PROJECT_NAME
                    };  
                });
                
               
            });
        };
        
        function getDcsAdjsTypes() {
          vm.getDcsAdjsDetails.list = EPTaxAdjustmentsFactory.getDcsAdjsTypes();
            
        };
        
        function validateAcctLineNo(selectedAcctType, acctFor) {
            
            var balSheetAccts = [1,2,3];
            var incomeAccts = [5,6,7];
            var lineNumber = vm.getLineDetails.selected.LINE_NO;
            
            //Balance sheet accounts should not have a line number
            if(balSheetAccts.indexOf(selectedAcctType) >= 0 && lineNumber !== "0") {
                //alert("Balance Sheet Statement Parent Account selected with 5471/8865 Line No");
                $scope.adjsForm.schdhClassification.$setValidity("invalidLineNo", false);
            } else {
                $scope.adjsForm.schdhClassification.$setValidity("invalidLineNo", true);
            }
            
            //Income accounts should have a line number
            if(incomeAccts.indexOf(selectedAcctType) >= 0 && lineNumber === "0") {
                $scope.adjsForm.schdhClassification.$setValidity("invalidIncomeLineNo", false);
            } else {
                $scope.adjsForm.schdhClassification.$setValidity("invalidIncomeLineNo", true);
            }
        };
        
        function loadExistingAdjustments() {
            vm.existingLoading = true;
            var params = {
                "action_id": 131,
                "screen_key": vm.mainScreenKey,
                "combination_key": vm.combination_key
            };
            
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                
                vm.existingAdjustments = formatAdjustmentData(data.jsonObject);
                 
                vm.existingLoading = false;
                
                if (vm.existingAdjustments.length === 0) {
                    vm.noRecords = true;
                }
            });
        }

        function formatAdjustmentData(_data) {
            var adjustments = [];
            var adjAccounts = [];
            _.forEach(_data, function (value, key) {

                if (value.ROW_INDICATOR === 1) {
                    
                    var adjObject = {};
                    adjObject = value;
                    adjObject.ACCOUNTS = [];
                    adjustments.push(adjObject); 
                   
                } else {
                    var AccountObj = {};
                    AccountObj = value;
                   
                    AccountObj.TYPE = "edit";
                    adjAccounts.push(AccountObj);

                }
            });
            
           
            _.forEach(adjustments, function (adjVal, adjKey) {
                adjustments[adjKey].ACCOUNTS = [];
                var row_one_trans_header_key = adjVal.TRANS_HEADER_KEY;

                _.forEach(adjAccounts, function (value, key) {

                    var account_TRANS_HEADER_KEY = value.TRANS_HEADER_KEY;
                    
                    if (value.ROW_INDICATOR !== 1 && row_one_trans_header_key === account_TRANS_HEADER_KEY) {
                      adjustments[adjKey].ACCOUNTS.push(value);
                    }

                });
            });

            adjAccounts = null;
            return adjustments;

        }
        
    }    
    
    ///////////////END CTRL
    
    return controllers;


}); 