define([
    'angular',
    './gridOnModalController',
    './ggModalDiretive'


], function () {
    'use strict';
    return angular.module('app.gridonModal', ['app.gridOnModalCtrl','app.gridOnModalDirective'])
    
});
