/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'moment','angular'

		],
		function(moment) {
			'use strict';

			var controllers = angular.module('app.entitydetailsController', [])
					.controller('entityDetailsController',
								['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData', 'JsonObjectFactory', 
								'EntityDetailsFactory', '$timeout', 'TbbsService', 'GENERAL_CONFIG', 'workspaceFactory', entityDetailsController ]);



			function entityDetailsController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory, EntityDetailsFactory, $timeout, GENERAL_CONFIG,workspaceFactory) {

				var vm = this;
				vm.rowData = rowData;
				vm.loading = true;
				vm.selected8858Entity;
				vm.entities_8858 = {};

				vm.selectedFormFiler;
				vm.filerIndices = {};
				vm.fseIndices = {};

				vm.selectedClassification;
				vm.entityClassification = {};
				
				vm.selectedDatsmApplicable;
				vm.datsmIndices = {};
				vm.selectedFSEFlag;
				
				vm.showInput = true;
				vm.showInputClass = true;
				vm.showInput8858 = true;
				vm.showInput5471 = true;
				vm.showInputGiltiTaxType = true;
				vm.showInputGiltiCGK = true;
				vm.showFSEFlag = false;

				vm.giltiTaxType = {};
				vm.selectedGiltiTaxType;

				vm.giltiConsolGroupKey = {};
				vm.selectedGiltiCGK;

				vm.load8858Entities = function() {
					console.log('rowData',rowData);
					
				
	
					EntityDetailsFactory.get8858Entities().then(
						function(data) {
							if (data.callSuccess !== "1") {
								AlertService.add("error", data.errorMessage, 4000);
								vm.loading = false;
							} else {				
								vm.entities_8858 = data.jsonObject;
								
								console.log(vm.entities_8858);
								//vm.loading = false;
							}
					});
				};

				vm.get8858Entities = function (val, index) {

					var params = {
						
						"search_string": val
					};

					return EntityDetailsFactory.get8858Entities(params).then(
						function(data) {
							if (data.callSuccess !== "1") {
								AlertService.add("error", data.errorMessage, 4000);
								vm.loading = false;
							} else {				
								vm.entities_8858 = data.jsonObject;
								return data.jsonObject;
							}
					});
					
				};

				vm.loadFormFiler = function() {
					vm.filerIndices = [
						{label: "Y", value: "Y"},
						{label: "N", value: "N"}
					 ];
				}

				vm.loadFSEFlag= function() {
					vm.fseIndices = [
						{label: "Y", value: "Y"},
						{label: "N", value: "N"}
					 ];
				}

				vm.loadEntityClassification = function() {
					vm.entityClassification = [
						{label: "CMPX", value: "CMPX"},
						{label: "DMNT", value: "DMNT"}
					 ];
				}

				vm.loadGiltiTaxType = function() {
					vm.giltiTaxType = [
						{label: "CFC", value: "CFC"},
						{label: "NCFC", value: "NCFC"}
					 ];
				}

				vm.loadGiltiCGK = function() {
					
					return EntityDetailsFactory.getGiltiCGK().then(
						function(data) {
							if (data.callSuccess !== "1") {
								AlertService.add("error", data.errorMessage, 4000);
								vm.loading = false;
							} else {				
								vm.giltiConsolGroupKey = data.jsonObject;
								vm.loading = false;
								return data.jsonObject;
							}
					});
				}
				
				vm.loadDatsmApplicable = function() {
					vm.selectedDatsmApplicable = vm.rowData.DATSM_APPLICABLE;
					vm.datsmIndices = [
						{label: "Y", value: "Y"},
						{label: "N", value: "N"}
					 ];
				}
				
	
				vm.resetDetails = function () {
					$scope.crudLoading = false;
					vm.selected8858Entity = null;
					vm.selectedFormFiler = null;
					vm.selectedClassification = null;
					vm.selectedFSEFlag = null;
					vm.selectedDatsmApplicable = vm.rowData.DATSM_APPLICABLE;
				};
	
				vm.cancelDetails = function () {
					$scope.crudLoading = false;
					console.log("in cancel");
					//var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
					//$rootScope.$emit('gridUpdate', args);  
					$uibModalInstance.dismiss('cancel');
				};

				function init() {					
					
					vm.load8858Entities();
					vm.loadFormFiler();
					vm.loadEntityClassification();
					vm.loadDatsmApplicable();
					vm.loadGiltiTaxType();
					vm.loadGiltiCGK();
					vm.loadFSEFlag();
					

					if (vm.rowData.TYPE === 'CCLE' || vm.rowData.TYPE === 'CCBR') {
						vm.showInput = false;
					}

					if (vm.rowData.TYPE === 'CCLE' || vm.rowData.TYPE === 'CCBR' /*|| vm.rowData.TYPE === 'BR'*/) {
						vm.showInputClass = false;
					}
					if (vm.rowData.TYPE === 'CCLE' || vm.rowData.TYPE === 'HO') {
						vm.showInput8858 = false;
					}
					if (vm.rowData.TAX_TYPE !== 'CFC' && vm.rowData.TAX_TYPE !== 'NCFC')  {
						vm.showInput5471 = false;
					} 

					if (vm.rowData.TAX_TYPE !== 'CFC' && vm.rowData.TAX_TYPE !== 'NCFC' && vm.rowData.TYPE === 'HO' )  {
						vm.showInputGiltiTaxType = false;
						vm.showInputGiltiCGK = false;					
					} 
					if ((vm.rowData.TAX_TYPE === 'CFC' || vm.rowData.TAX_TYPE == 'NCFC') && vm.rowData.TYPE === 'HO' ){
					
						vm.showFSEFlag=true;
					} 
					
				}

				init();

				$scope.saveDetails = function () { 

					console.log(vm.selected8858Entity);
					console.log(vm.selectedFormFiler);
					console.log(vm.selectedClassification);
					console.log(vm.selectedDatsmApplicable);

					console.log('LE Key being updated: ', vm.rowData.LE_KEY);

					if(((vm.selected8858Entity === undefined || vm.selected8858Entity === null || vm.selected8858Entity === '') &&
						(vm.selectedFormFiler === undefined || vm.selectedFormFiler === null)&&
						(vm.selectedClassification === undefined || vm.selectedClassification === null) && 
						(vm.selectedGiltiTaxType === undefined || vm.selectedGiltiTaxType === null)&&
						(vm.selectedGiltiCGK === undefined || vm.selectedGiltiCGK === null)
						) && (vm.showInput5471 === false)
					   ) {
						AlertService.add("error", "Select atleast one input");
						return;
					}

					var params = {
						le_key_8858: (vm.selected8858Entity) ? vm.selected8858Entity.DD_VALUE : "",
						form_filer: (vm.selectedFormFiler) ? vm.selectedFormFiler.value : "",
						entity_classification: (vm.selectedClassification) ? vm.selectedClassification.value : "",
						leid: vm.rowData.LEID,
						cdr_no: vm.rowData.CDR_NO,
						reporting_period: vm.rowData.REPORTING_PERIOD,
						le_key: vm.rowData.LE_KEY,
						consol_group_key: vm.rowData.CONSOL_GROUP_KEY,
						object_id: vm.rowData.object_id,
						datsm_applicable : (vm.selectedDatsmApplicable) ? vm.selectedDatsmApplicable :'N',
						gilti_tax_type_override : (vm.selectedGiltiTaxType) ? vm.selectedGiltiTaxType.value : null,
						gilti_consol_group_key_override : (vm.selectedGiltiCGK) ? vm.selectedGiltiCGK.VALUE : null,
						fse_flag : (vm.selectedFSEFlag) ? vm.selectedFSEFlag.value : ""
						}

					console.log(params);
					EntityDetailsFactory.updateEntityDetails(params).then(
						function(data) {
							if (data.callSuccess === "1") {
								
								//vm.rowData.SOURCE_OBJECT_ID = vm.selectedBasket.BASKET_ID;
								// vm.rowData.BASKET_NAME = vm.selectedBasket.BASKET_NAME;
								
								
								var args = {object_id:vm.rowData.object_id, gridFilter: {object_id:vm.rowData.object_id}};
								
								
								setTimeout(function() {
									AlertService.add("success", "Data saved Successfully", 1000);
									$uibModalInstance.dismiss('cancel');
									$rootScope.$emit('gridUpdate', args); 
								}, 1000);
							} else {
								console.log('error data' , data);
								AlertService.add("error", data.errorMessage);
							}
							//vm.loading = false;
							$scope.crudLoading = false;
						})
				};
			}
			return controllers;

		});