define([
    'angular',
    './WwniMController',
    './WwniMappingService'



], function () {
    'use strict';
    return angular.module('app.wwniMapping', ['app.wwnimappingController','app.wwnimappingService'] );


});
