define([
    'angular'

], function () {
    'use strict';


    var controllers = angular.module(
        'app.creditDetailsController', []).controller(
            'creditDetailsController',
            ['$scope', '$rootScope', '$http', 'GlobalService',
                '$uibModalInstance', 'ModalFactory',
                'AlertService', 'rowData', 'gridData',
                'workspaceFactory', 'JsonObjectFactory',
                '$timeout', 'GENERAL_CONFIG', 'USER_SETTINGS',
                'creditDetailsFactory',
                creditDetailsController])

    function creditDetailsController($scope, $rootScope,
        $http, GlobalService, $uibModalInstance, ModalFactory,
        AlertService, rowData, gridData, workspaceFactory,
        JsonObjectFactory, $timeout, GENERAL_CONFIG, USER_SETTINGS,
        creditDetailsFactory,
    ) {

        var vm = this;
        vm.modalData = [];
        vm.disable = true;
        vm.loading = false;
        vm.crudLoading = false;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        getModalData();
        function getModalData() {
            vm.loading = true;
            creditDetailsFactory.getModalData().then(response => {
                vm.modalData = response.jsonObject;
                if(vm.modalData && vm.modalData.length>0){
                    vm.modalData = vm.modalData.map(x => ({
                        ...x,
                        totalCYAmount: Number(x.GENERATED_AMT+x.ADJUSTMENT_AMT)
                    }));
                    vm.modalData.sort((a, b) => a.LINE_ORDER - b.LINE_ORDER);
                }
                else {
                    AlertService.add("error", "No data found!");
                }
                
                vm.loading = false;
            });
        }

        vm.valuechanged = function (item, index) {
            item.IS_CHANGED = 'Y';
            item.totalCYAmount = item.GENERATED_AMT+item.ADJUSTMENT_AMT;
            vm.disable = false;
        }

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.reset = function() {
            getModalData();
        }

        vm.save = function () {
            vm.clob_settings = [];
            var message = "Form Details are successfully updated."
            vm.crudLoading = true;
            vm.clob_settings = { tax_year: filterParams.tax_year, scenario: ""+filterParams.scenario, jcd_key: rowData.jcd_key ? rowData.jcd_key : "250", ta_key: vm.modalData[0].TA_KEY+"", ta_mast_key: vm.modalData[0].TA_MAST_KEY+"", consol_group_key: vm.modalData[0].CONSOL_GROUP_KEY+"", sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key };
            creditDetailsFactory.saveModal(vm.modalData, vm.clob_settings).then(data => {
                if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
                    setTimeout(() => {
                        var args = {};
                        $rootScope.$emit('gridUpdate', args);
                    }, 15000)
					
				}
            });
        }
    }
});