define([
    'angular',
    './batchOpenInAmendedController',
    './batchOpenInAmendedService'



], function () {
    'use strict';
    return angular.module('app.batchOpenInAmended', ['app.batchOpenInAmendedController','app.batchOpenInAmendedService'] );

});