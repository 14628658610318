define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.templateAssignService',[])

        .factory("TemplateAssignFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', 'USER_SETTINGS', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,JsonObjectFactory, GENERAL_CONFIG, USER_SETTINGS) {
          var templateAssignFactory = {};

            var URLS = {
                //FETCH: GENERAL_CONFIG.base_url + '/loadGridJson?action_code=7w37qb&grid_id=72',
                FETCH: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id=31674',
                FETCH_USERLIST: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id=31675',
                FETCH_MODULES: GENERAL_CONFIG.admin_api_url + '/getGtwModules',
                SET_ACCESS: GENERAL_CONFIG.admin_api_url+ '/setModuleAccess',
                REFRESH: GENERAL_CONFIG.common_api_url + '/refreshCachedObject',
                MULTI_TEMP_ASSIGN: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9wru7p",
                FETCH_USER_TEMPLATE_LIST: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id=33043',
                SAVE_USER_TEMPLATE_LIST: GENERAL_CONFIG.base_url + '/saveJsonObject?action_id=33044'
            }
            var tempParams = {
                "tax_year": 2019,
                "screen_key": "",
                "scenario": 32,
                "jcd_key": "",
                "jcd_ta_key": "",
                "activity_key": "",
                "screen_type": "",
                "is_locked": "N",
            };

            var params = {};

            templateAssignFactory.setModuleParams = function(data){
                params = data;
            }

            templateAssignFactory.getModuleParams = function(){
                return params;
            }


            templateAssignFactory.fetchCacheObjControlDetails = function(data) {
             	
            	var promise = $http({
                    method: "POST",
            		url: URLS.FETCH,
            		data: data
            	}).then(function (response) {
            		return response;
            	});   

            	return promise;   	   
            };

            templateAssignFactory.refreshCachedObject = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.REFRESH,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };
			
			templateAssignFactory.refreshAllCachedObject = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.REFRESHALL,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };
			
			templateAssignFactory.removeCachedObject = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.REMOVE,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };

            templateAssignFactory.getAllTemplates = function(client_key) {

                var promise = $http({
                    method: "GET",
                    url: URLS.FETCH + '&client_key=' + client_key,
                    data: tempParams
                }).then(function (response) {
                    return response;
                });

                return promise;
            };

            templateAssignFactory.getTemplateModules = function(client_key, template_key) {
                var promise = $http({
                    method: "POST",
                    url: URLS.FETCH_MODULES + '?jurisdiction_key=1' + '&client_key=' + client_key + '&row_primary_key=' + template_key,
                    data: tempParams
                }).then(function (response) {
                    return response;
                });

                return promise;
            };

            templateAssignFactory.getTemplateUsers = function(client_key, template_key) {
                var promise = $http({
                    method: "POST",
                    url: URLS.FETCH_USERLIST + '&client_key=' + client_key + '&template_keys=' + template_key,
                    data: tempParams
                }).then(function (response) {
                    return response;
                });
            
                return promise;
            };

            templateAssignFactory.saveMultiTempUser = function(clob_data, clientKey, globalParams) {
                var params = {
                    "clientKey": clientKey,
                    "tax_year": globalParams.tax_year,
                    "scenario": globalParams.scenario,
                    "jcd_key": globalParams.jcd_key,
					"clob_data": JSON.stringify(clob_data)
				};
                var promise = $http({
                    method: "POST",
                    url: URLS.MULTI_TEMP_ASSIGN,
                    data: params
                }).then(function (response) {
                    return response;
                });
            
                return promise;
            };
			
            templateAssignFactory.getUser = function (val) {
                return $http({
                    method: "POST",
                    url: GENERAL_CONFIG.base_url + '/userDetails',
                    data: {userName:val}
    
                }).then(function (response) {
                    //return response.data.userDetails
                    return response.data.userDetails.map(function(user) {
                        return { email : user.email,
                            name : user.first_name +' ' + user.last_name + ' ('+user.email+') ',
                            userName : user.first_name +' ' + user.last_name,
                            ssoId : user.sso_id
                            }
                    });
                });
            };

            templateAssignFactory.setAccessAll = function(nodes,user_id,rowPrimaryKey,client_key) {
                $rootScope.$broadcast('showLoader', true);
                var userSettings = USER_SETTINGS;
            	var params = templateAssignFactory.getModuleParams();
                //var templateId = (params.templateId && params.templateId.length > 0) ? true : false;
                var templateId = params.templateId.toString();
                var nodeObj = JSON.parse(nodes);
                nodeObj[0].user_id = (params.userId && params.userId.length > 0) ? params.userId.toString() : userSettings.user.sso_id;
            	var data =  _.extend({data : JSON.stringify(nodeObj) });

                // if(templateId){
                //     templateId = params.templateId.toString()
                // }else{
                //     $rootScope.$broadcast('showLoader', false);
                //     return promise;
                // }
                
                var promise = $http({
                    method: "POST",
                    url: URLS.SET_ACCESS + '?template_key=' + templateId,
                    data:data                    
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        $rootScope.$broadcast('showLoader', false);
                        AlertService.add("success", "Data saved successfully.", 4000);
                        return response.data;
                    }else {
                        $rootScope.$broadcast('showLoader', false);
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

			templateAssignFactory.downloadCachedObject = function(data) {

                var promise = $http({
                    method: "GET",
                    url: URLS.DOWNLOAD,
					responseType: 'arraybuffer',
					 observe: 'response',
                    params: { objectId: data.objectId }
                }).then(function (response) {					
					//response.headers = headers();
					//console.log();
                    return response;
                });

                return promise;
            };

            templateAssignFactory.enableDisableCaching = function(data) {

                var promise = $http({
                    method: "POST",
                    url: URLS.SWITCH,
                    data: data
                }).then(function (response) {
                    return response;
                });

                return promise;
            };

            templateAssignFactory.getDataFromCache = function(obj_name, params) {

                var promise = $http({
                    method: "GET",
                    url: URLS.OBTAIN + '?object_key=' + obj_name + '&proc_in_params=' + params + '&fetch_from_cache=Y'
                }).then(function (response) {
                    return response.data;
                });

                return promise;
            };

            templateAssignFactory.getDataFromDB = function(obj_name, params) {

                var promise = $http({
                    method: "GET",
                    url: URLS.OBTAIN + '?object_key=' + obj_name + '&proc_in_params=' + params + '&fetch_from_cache=N'
                }).then(function (response) {
                    return response.data;
                });

                return promise;
            };

            templateAssignFactory.getCacheDropdown = function(data) {
                var promise = $http({
                    method: "GET",
            		url: URLS.CACHE_DROPDOWN,
            		data: data
            	}).then(function (response) {
            		return response;
            	});   
            	return promise;   
            };

            templateAssignFactory.getSelectedCacheDropdown = function(obj_name, params) {
                var promise = $http({
                    method: "GET",
                    url: URLS.FETCH_CACHE_KEYS
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            templateAssignFactory.getCacheKeyJson = function(cache_name, cache_key) {
                var promise = $http({
                    method: "GET",
                    url: URLS.FETCH_KEY_JSON + '?cache_name=' + cache_name + '&cache_key=' + cache_key
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            templateAssignFactory.getUserTemplates = function(client_key, user_sso) {
                var promise = $http({
                    method: "POST",
                    url: URLS.FETCH_USER_TEMPLATE_LIST + '&client_key=' + client_key + '&user_sso=' + user_sso,
                    data: tempParams
                }).then(function (response) {
                    return response;
                });
            
                return promise;
            };

            templateAssignFactory.saveUserTemplates = function(clob_data, client_key, global_params) {
            	var data =  _.extend({clob_data : JSON.stringify(clob_data) });
                var promise = $http({
                    method: "POST",
                    url: URLS.SAVE_USER_TEMPLATE_LIST + '&client_key=' + client_key,
                    data: data
                }).then(function (response) {
                    return response;
                });
            
                return promise;
            };
            
            return templateAssignFactory;
        }])

    return services;
});