define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.retriggerGroupSyncController', [])

        .controller('retriggerGroupSyncController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter', 'retriggerGroupSyncServiceFactory', retriggerGroupSyncController]);
    	function retriggerGroupSyncController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter, retriggerGroupSyncServiceFactory) {
        var vm = this;

        vm.modalTitle = "Retrigger Group Sync";
		vm.saveloader = false;
		vm.groups = [];
		vm.taxYear;
		angular.forEach(gridData, function(value, key) {
			vm.groups.push(value.data.GROUP_NAME);
			vm.taxYear = value.data.TAX_YEAR;
		});

		vm.retriggerGroupSync = function() {
			vm.saveloader = true;
			vm.showJsonLoader = true;
            var params = {"client_key": USER_SETTINGS.user.client_key, "tax_year": vm.taxYear.toString(), "groups": vm.groups.toString()};

			return retriggerGroupSyncServiceFactory.saveretriggerGroupSync(params).then(function (data) {
				if(data.data.callSuccess == "1" ){
					vm.saveloader = false;
					AlertService.add("success", data.data.message , 4000);
					vm.cancel();
				}else{
					vm.saveloader = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };

		vm.retriggerSyncGroup = function(){
            vm.loading = true;
            var params = {
                "tax_year": GlobalService.globalParams.tax_year,
                "client_key": vm.client_key
            };

			return retriggerGroupSyncServiceFactory.retriggerSync(vm.taxYear, vm.groups.toString()).then(function (data) {
				if(data.data.callSuccess == "1" ){
					vm.loading = false;
					AlertService.add("success", "Re-sync Group Execute Successfully", 4000);
                    vm.slt_key_setup_data = data.data.jsonObject;
				}else{
					vm.loading = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };

		vm.removeTag = function (index) {
			vm.groups.splice(index, 1);
	  	};

		vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		
    }
});
