
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.gaap8858Service',[])
        .factory("GaapAmountDrilldownFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', 'AlertService','$log','$injector','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', 
        	function($q, $http, $stateParams,$timeout, $rootScope, AlertService,$log,$injector,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
            var factory = {};
            var returnObj = {};
         
         
           

            factory.ELIMINATION_SAVE = function(_callName , _data){
            	var gridData = workspaceFactory.activeScreen.data.data;  
            	
            	var totalAmt = 0;
            	angular.forEach(gridData, function(amtRow) {
            		if(amtRow.AMOUNT !== null && amtRow.AMOUNT !== undefined){
            			totalAmt = totalAmt + Number(amtRow.AMOUNT);
            		}
					
				});
            	
            	if (totalAmt !== 0){
            		AlertService.add("error", "*Elimination must net to 0. ",4000);
                }
            	
            	/*
            	
            	console.log("data", _data);
            		returnObj.TYPE = (_data.rowData.GROUP_TRANS_KEY === 0) ? "S" : "U";
                   // returnObj.ELIM_DESCRIPTION ="8858";
                    // _data.rowData.COMBINATION_KEY;
                    returnObj.ADJ_TYPE ="BR";
                    returnObj.GROUP_TRANS_KEY = (_data.rowData.GROUP_TRANS_KEY === 0) ? 0 : _data.rowData.GROUP_TRANS_KEY;
                    returnObj.ADJ_ORIGIN_SOURCE = "FIR";
                    returnObj.SOURCE_SYSTEM_FLAG = "F";

                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=dfiq21";
                    var jsonObj = JSON.stringify(_data.rowData);
                    var jsonSettings =  JSON.stringify(returnObj);
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = _.merge({}, GlobalService.globalParams, filterParams);
                    var params = _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    params.process_name =  "ELIMINATION_SAVE";

                   service.saveJSON(url, params).then(function(data){
                        if(data.callSuccess === "1"){
                            AlertService.add("success", "Data saved Successfully.",4000);
                        }else{
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                        }
                        
                        var args = {tax_year:_data.rowData.TAX_YEAR, scenario:_data.rowData.SCENARIO, jcd_key: _data.rowData.JCD_KEY, trans_details_key: _data.rowData.TRANS_DETAILS_KEY, gridFilter: {TAX_YEAR:_data.rowData.TAX_YEAR, SCENARIO:_data.rowData.SCENARIO, JCD_KEY: _data.rowData.JCD_KEY, TRANS_DETAILS_KEY: _data.rowData.TRANS_DETAILS_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                         
                    });

                  
                
            */}
            return factory;
        }])

    return services;
});
