define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.SectionDetailsService',[])
    .factory('SectionDetailsFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        
    		var factory = {};
    		factory.formConfigData = {};
    		factory.fetchSectionDetails=function(sectionNumber, sso_id, occur_index, tax_year, scenario, cc_key){               
                var params = { "action_code": '5apopf', "sso_id": sso_id, "tax_year": tax_year, "scenario": scenario, "jcd_key": 250, "combination_key": cc_key, form_no: "4797", prop_section: sectionNumber, occurrence: occur_index};                
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    console.log("Now in the Service after action call the data was ................:::::", data);
                    if(null != data.callSuccess && data.callSuccess == "1"){
                        factory.formConfigData = data;
                        console.log("form Data Now is::-----", factory.formConfigData);
                        return data;
                    }else{
                        AlertService.add("error", data.errorMessage);
                        return false;
                    }
                });
            }

            factory.saveSection = function(final_data, clob_settings){
                console.log("Clob_setting was done as:::::::::------------", clob_settings);
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=7w7k2j";
                var json_settings = JSON.stringify(clob_settings);
                var json_data = JSON.stringify(final_data);
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};                
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({json_data:json_data}, params);
                params =  _.extend({json_settings:json_settings}, params);
                params.process_name =  "form-4797-prop-section-save";                
                console.log('save params:::',params);
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            }
        
         return factory;
        
    }]);

    return services;

});
