define([
    'angular',
    './TbbsReclassController',
    './TbbsReclassService',
    './tbbsdiagnostics'



], function () {
    'use strict';
    return angular.module('app.tbbsReclass', ['app.tbbsreclassController','app.tbbsreclassService','app.tbbsdiagnostics'] );


});