
define([
    'angular',
    './GridBuilderController',
    './GridBuilderService'

], function () {
    'use strict';
    return angular.module('app.gridBuilder', ['app.GridBuilderController','app.gridBuilderServices'])
        .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider
                .state('admin.gridBuilder', {
                    url: '/admin-grid-builder',
                    templateUrl: 'app/components/admin/gridBuilder/grid-builder-tpl.html',
                    data:{},
                    access:"4"
                })


                .state('admin.grid-list', {
                    url: '/grid-list',
                    templateUrl: 'app/components/admin/gridBuilder/grid-list-tpl.html',
                    type:'custom',
                    "api": {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=89&grid_id=36","grid_id":"36"},
                    access: "4"
                });



        }]);

});


