define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.ecRefreshSetupActionsController', [])
    .controller('ecRefreshSetupCreateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecRefreshSetupActionsService', ecRefreshSetupCreateActionController])
    .controller('ecRefreshSetupUpdateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecRefreshSetupActionsService', ecRefreshSetupUpdateActionController]);
 
    function ecRefreshSetupCreateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,ecRefreshSetupActionsService){
        var vm = this;
        vm.modalTitle = "EC Refresh Setup";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {};

        vm.refreshEntity_bckup = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            GROUP_OBJ_KEY:'',
            REFRESH_STATUS:'',
            BATCH_START_DATE:'',
            BATCH_END_DATE:'',
            DEFAULT_TAX_YEAR_BEGIN:'',
            DEFAULT_TAX_YEAR_END:'',
            DEFAULT_FILING_CYCLE:'',
            SCENARIO_TYPE_CODE:'',
            SCENARIO_DESC:''
        };

        vm.refreshEntity = angular.copy(vm.refreshEntity_bckup);

        vm.refreshEntity.TAX_YEAR = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN = "01/01/"+vm.refreshEntity.TAX_YEAR;
        vm.refreshEntity.DEFAULT_TAX_YEAR_END = "12/31/"+vm.refreshEntity.TAX_YEAR;
        if(vm.refreshEntity.BATCH_END_DATE == null || vm.refreshEntity.BATCH_END_DATE == undefined || vm.refreshEntity.BATCH_END_DATE ==""){
            vm.refreshEntity.REFRESH_STATUS = "0";
        }

        let loadScenarioTypeCode = function(){
            
            ecRefreshSetupActionsService.loadScenarioTypeCode().then((scenarioTypeCode) => {
                console.log("load all Scenario Type Code", scenarioTypeCode);
                vm.allscenarioTypeCode = scenarioTypeCode;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadScenarioDesc = function(selectedValue){
            
            ecRefreshSetupActionsService.loadScenarioDesc(selectedValue).then((scenarioDesc) => {
                console.log("load all Scenario Desc", scenarioDesc);
                vm.allscenarioDesc = scenarioDesc;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadGroupObjKey = function(selectedVal1, selectedVal2){
            
            ecRefreshSetupActionsService.loadGroupObjKey(selectedVal1, selectedVal2).then((groupObjByYear) => {
                console.log("load all Group Obj", groupObjByYear);
                vm.allGroupObjByYear = groupObjByYear;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
            loadScenarioTypeCode();
            

        };
		vm.init();

        

        vm.onScenarioTypeCodeChange = function(){
            console.log(vm.refreshEntity.SCENARIO_TYPE_CODE);
            loadScenarioDesc(vm.refreshEntity.SCENARIO_TYPE_CODE);
            vm.refreshEntity.GROUP_OBJ_KEY = undefined;
            
        }; 
        vm.onPopulateGroupObjYear = function(){
            console.log(vm.refreshEntity.SCENARIO_TYPE_CODE+'............'+ vm.refreshEntity.SCENARIO_DESC);
            loadGroupObjKey(vm.refreshEntity.SCENARIO_TYPE_CODE, vm.refreshEntity.SCENARIO_DESC);
        };

        vm.onPopulateRefreshStatus = function(){
            console.log(vm.refreshEntity.BATCH_END_DATE);
            if(vm.refreshEntity.BATCH_END_DATE == null || vm.refreshEntity.BATCH_END_DATE == undefined || vm.refreshEntity.BATCH_END_DATE ==""){
                vm.refreshEntity.REFRESH_STATUS = "0";
            }else{
                vm.refreshEntity.REFRESH_STATUS = "40";
            }
        }

        vm.isValid = function(){
            if(vm.refreshEntity.TAX_YEAR == null || 
                vm.refreshEntity.TAX_YEAR == undefined || 
                vm.refreshEntity.TAX_YEAR == ""){
                     vm.refreshEntity.TAX_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.refreshEntity.SCENARIO_TYPE_CODE == null || 
                vm.refreshEntity.SCENARIO_TYPE_CODE == undefined || 
                vm.refreshEntity.SCENARIO_TYPE_CODE == ""){
                     vm.refreshEntity.SCENARIO_TYPE_CODE_NOT_VALID = true;
                     return false;
            }
            if(vm.refreshEntity.SCENARIO_DESC == null || 
                vm.refreshEntity.SCENARIO_DESC == undefined || 
                vm.refreshEntity.SCENARIO_DESC == ""){
                     vm.refreshEntity.SCENARIO_DESC_NOT_VALID = true;
                     return false;
            }
            if(vm.refreshEntity.GROUP_OBJ_KEY == null || vm.refreshEntity.GROUP_OBJ_KEY == undefined || vm.refreshEntity.GROUP_OBJ_KEY == ""){
                vm.refreshEntity.GROUP_OBJ_KEY_NOT_VALID = true;
                return false;
            }
            if(vm.refreshEntity.BATCH_START_DATE == null || vm.refreshEntity.BATCH_START_DATE == undefined || vm.refreshEntity.BATCH_START_DATE == ""){
                vm.refreshEntity.BATCH_START_DATE_NOT_VALID = true;
                return false;
            }

            if(vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN == null || vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN == undefined || vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN == ""){
                vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN_NOT_VALID = true;
                return false;
            }

            if(vm.refreshEntity.DEFAULT_TAX_YEAR_END == null || vm.refreshEntity.DEFAULT_TAX_YEAR_END == undefined || vm.refreshEntity.DEFAULT_TAX_YEAR_END == ""){
                vm.refreshEntity.DEFAULT_TAX_YEAR_END_NOT_VALID = true;
                return false;
            }

            if(vm.refreshEntity.DEFAULT_FILING_CYCLE == null || vm.refreshEntity.DEFAULT_FILING_CYCLE == undefined || vm.refreshEntity.DEFAULT_FILING_CYCLE == ""){
                vm.refreshEntity.DEFAULT_FILING_CYCLE_NOT_VALID = true;
                return false;
            }

            return true;
        }
        
        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.refreshEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            
            jsonObj = ecRefreshSetupActionsService.convertDateFormates(jsonObj);
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params.scenarioCode = vm.refreshEntity.SCENARIO_TYPE_CODE;
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=upobod";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Refresh Setup created successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.reset = function (){
			vm.refreshEntity = angular.copy(vm.refreshEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }

    function ecRefreshSetupUpdateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, ecRefreshSetupActionsService){
        var vm = this;

        vm.modalTitle = "Refresh Setup";
		vm.showFormLoader = true;


        vm.showEntityLoader = false;
        vm.isSavingUpdate = false;
        vm.isSavingDelete = false;
        vm.dateOptions = {};
 		
         vm.refreshEntity_bckup = gridData.rowData;

        vm.refreshEntity = angular.copy(vm.refreshEntity_bckup);

        if(vm.refreshEntity.BATCH_END_DATE == null || vm.refreshEntity.BATCH_END_DATE == undefined || vm.refreshEntity.BATCH_END_DATE ==""){
            vm.refreshEntity.REFRESH_STATUS = "0";
        }else{
            vm.refreshEntity.REFRESH_STATUS = "40";
        }

        vm.onPopulateRefreshStatus = function(){
            console.log(vm.refreshEntity);
            if(vm.refreshEntity.BATCH_END_DATE == null || vm.refreshEntity.BATCH_END_DATE == undefined || vm.refreshEntity.BATCH_END_DATE ==""){
                vm.refreshEntity.REFRESH_STATUS = "0";
            }else{
                vm.refreshEntity.REFRESH_STATUS = "40";
            }
        }
        
        vm.isValid = function(){
            if(vm.refreshEntity.TAX_YEAR == null || 
                vm.refreshEntity.TAX_YEAR == undefined || 
                vm.refreshEntity.TAX_YEAR == ""){
                     vm.refreshEntity.TAX_YEAR_NOT_VALID = true;
                     return false;
            }
            
            if(vm.refreshEntity.GROUP_OBJ_KEY == null || vm.refreshEntity.GROUP_OBJ_KEY == undefined || vm.refreshEntity.GROUP_OBJ_KEY == ""){
                vm.refreshEntity.GROUP_OBJ_KEY_NOT_VALID = true;
                return false;
            }
            if(vm.refreshEntity.BATCH_START_DATE == null || vm.refreshEntity.BATCH_START_DATE == undefined || vm.refreshEntity.BATCH_START_DATE == ""){
                vm.refreshEntity.BATCH_START_DATE_NOT_VALID = true;
                return false;
            }

            if(vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN == null || vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN == undefined || vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN == ""){
                vm.refreshEntity.DEFAULT_TAX_YEAR_BEGIN_NOT_VALID = true;
                return false;
            }

            if(vm.refreshEntity.DEFAULT_TAX_YEAR_END == null || vm.refreshEntity.DEFAULT_TAX_YEAR_END == undefined || vm.refreshEntity.DEFAULT_TAX_YEAR_END == ""){
                vm.refreshEntity.DEFAULT_TAX_YEAR_END_NOT_VALID = true;
                return false;
            }

            if(vm.refreshEntity.DEFAULT_FILING_CYCLE == null || vm.refreshEntity.DEFAULT_FILING_CYCLE == undefined || vm.refreshEntity.DEFAULT_FILING_CYCLE == ""){
                vm.refreshEntity.DEFAULT_FILING_CYCLE_NOT_VALID = true;
                return false;
            }

            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSavingUpdate = true;

            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            // let jsonSettings = {
            //     tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
            //     // "scenario": rowData.SCENARIO,
            //     // "jcd_key": rowData.JCD_KEY, 
            //     // "combination_key": rowData.COMBINATION_KEY, 
			// };
            //jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.refreshEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            //jsonObj.IS_UPDATE = 'True';
            jsonObj = ecRefreshSetupActionsService.convertDateFormates(jsonObj);
            
            jsonObj = JSON.stringify(jsonObj);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            // params = _.extend({
            //     jsonSettings: jsonSettings
			// }, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=rghm10";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Refresh Setup Updated successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        
            
        vm.delete = function(_tax_year, _group_obj_key){
            console.log("Delete function called");
            console.log(_tax_year+'............'+_group_obj_key);
            vm.isSavingDelete = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                tax_year: _tax_year,
                group_obj_key: _group_obj_key
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=yogrs0";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Refresh Setup deleted successfully", 4000);
                    console.log("deleted", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.reset = function (){
			vm.refreshEntity = angular.copy(vm.refreshEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    return controllers;
});
