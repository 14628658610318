define([
    'angular'
], function () {
    'use strict';
    let controllers = angular.module('app.configDataController', [])
        .controller('configDataController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'rowData', 'gridData', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'configDataService', configDataController]);


    function configDataController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, rowData, gridData, AlertService, GENERAL_CONFIG, USER_SETTINGS, configDataService) {
        let vm = this;

        vm.modalTitle = "851 Config Data Setup";
        vm.showFormLoader = true;


        vm.showEntityLoader = false;
        vm.isSavingUpdate = false;
        vm.isSavingDelete = false;
        vm.dateOptions = {};
        vm.isEdit = gridData !== "";
        console.log(rowData);

        let loadScenarioTypeCode = function () {

            configDataService.loadScenarioTypeCode().then((scenarioTypeCode) => {
                console.log("load all Scenario Type Code", scenarioTypeCode);
                vm.allscenarioTypeCode = scenarioTypeCode;

            });
        }

        vm.loadScenarioDesc = function () {

            configDataService.loadScenarioDesc(vm.configEntity.SCENARIO_CODE).then((scenarioDesc) => {
                console.log("load all Scenario Desc", scenarioDesc);
                vm.allscenarioDesc = scenarioDesc;
                vm.configEntity.SCENARIO_DESC = scenarioDesc[0].SCENARIO_DESC;
                vm.configEntity.SCENARIO = scenarioDesc[0].SCENARIO_VALUE;
            });
        }

        loadScenarioTypeCode();
        if (vm.isEdit) {
            vm.configEntity_bckup = gridData.rowData;
            vm.configEntity_bckup.BATCH_START_DT = new Date(gridData.rowData.BATCH_START_DT);
            vm.configEntity_bckup.BATCH_END_DT = vm.configEntity_bckup.SCHEDULE_IND == 'I' ? new Date(gridData.rowData.BATCH_END_DT) : "";

        } else {
            vm.configEntity_bckup = {
                TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                SCENARIO: '',
                JCD_KEY: GlobalService.globalParams.jcd_key,
                SCHEDULE_IND: 'A',
                BATCH_START_DT: '',
                BATCH_END_DT: '',
                SCENARIO_CODE: '',
            }
            vm.configEntity_bckup.BATCH_START_DT = new Date();
            vm.configEntity_bckup.BATCH_END_DT = "";

        }
        vm.configEntity = angular.copy(vm.configEntity_bckup);


        vm.isValid = function () {
            if (vm.configEntity.TAX_YEAR == null ||
                vm.configEntity.TAX_YEAR == undefined ||
                vm.configEntity.TAX_YEAR == "") {
                vm.configEntity.TAX_YEAR_NOT_VALID = true;
                return false;
            }
            if (vm.configEntity.SCENARIO == null ||
                vm.configEntity.SCENARIO == undefined ||
                vm.configEntity.SCENARIO == "") {
                vm.configEntity.SCENARIO_NOT_VALID = true;
                return false;
            }
            if(vm.configEntity.SCENARIO_CODE == null || 
                vm.configEntity.SCENARIO_CODE == undefined || 
                vm.configEntity.SCENARIO_CODE == ""){
                     vm.configEntity.SCENARIO_CODE_NOT_VALID = true;
                     return false;
            }
            if (vm.configEntity.JCD_KEY == null ||
                vm.configEntity.JCD_KEY == undefined ||
                vm.configEntity.JCD_KEY == "") {
                vm.configEntity.JCD_KEY_NOT_VALID = true;
                return false;
            }
            if (vm.configEntity.SCHEDULE_IND == null ||
                vm.configEntity.SCHEDULE_IND == undefined ||
                vm.configEntity.SCHEDULE_IND == "") {
                vm.configEntity.SCHEDULE_IND_NOT_VALID = true;
                return false;
            }
            if (vm.configEntity.BATCH_START_DT == null ||
                vm.configEntity.BATCH_START_DT == undefined ||
                vm.configEntity.BATCH_START_DT == "") {
                vm.configEntity.BATCH_START_DT_NOT_VALID = true;
                return false;
            }
            return true;
        }
        

        vm.onDateChange = function (type) {
        }

        vm.scheduleChanged = function () {
            if (vm.configEntity.SCHEDULE_IND == "A") {
                vm.configEntity.BATCH_END_DT = "";
            } else {
                vm.configEntity.BATCH_END_DT = new Date();
            }
        }
        function getFormattedDate(date) {
            let year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            return month + '/' + day + '/' + year;
        }

        vm.save = function () {
            if (!vm.isValid()) {
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSavingUpdate = true;


            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
            };
            jsonSettings = JSON.stringify(jsonSettings);
            if (vm.isEdit) {
                vm.configEntity.ACTION = 'UPDATE';
            } else {
                vm.configEntity.ACTION = 'INSERT'
            }
            vm.configEntity.BATCH_START_DT = getFormattedDate(vm.configEntity.BATCH_START_DT);
            vm.configEntity.BATCH_END_DT = vm.configEntity.BATCH_END_DT !== "" ? getFormattedDate(vm.configEntity.BATCH_END_DT) : "";
            let jsonObj = angular.copy(vm.configEntity);
            jsonObj = _.merge({}, jsonObj, filterParams);
            // jsonObj = configDataService.convertDateFormates(jsonObj);

            jsonObj = JSON.stringify(jsonObj);
            let filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let paramsMerge = _.merge({}, filterParams);
            let params = _.merge({}, GlobalService.globalParams, paramsMerge);
            params = _.extend({
                clob_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=3ou9ch";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    setTimeout(function(){
                        let args = {tax_year:workspaceFactory.activeScreen.filters.getFilterParams().tax_year, scenario:workspaceFactory.activeScreen.filters.getFilterParams().scenario};
                        $rootScope.$emit('gridUpdate', args);
                    },1000);  
                    AlertService.add("success", "Config Details Updated successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error", data.errorMessage, 4000);
                }
            });
        };


        vm.delete = function (_tax_year, _config) {
            vm.isSavingUpdate = true;


            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
            };
            jsonSettings = JSON.stringify(jsonSettings);
            vm.configEntity.ACTION = 'DELETE';
            vm.configEntity.BATCH_START_DT = getFormattedDate(vm.configEntity.BATCH_START_DT);
            vm.configEntity.BATCH_END_DT = vm.configEntity.BATCH_END_DT !== "" ? getFormattedDate(vm.configEntity.BATCH_END_DT) : "";
            let jsonObj = angular.copy(vm.configEntity);
            jsonObj = _.merge({}, jsonObj, filterParams);
            // jsonObj = configDataService.convertDateFormates(jsonObj);

            jsonObj = JSON.stringify(jsonObj);
            let filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let paramsMerge = _.merge({}, filterParams);
            let params = _.merge({}, GlobalService.globalParams, paramsMerge);
            params = _.extend({
                clob_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=3ou9ch";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    setTimeout(function(){
                        let args = {tax_year:workspaceFactory.activeScreen.filters.getFilterParams().tax_year, scenario:workspaceFactory.activeScreen.filters.getFilterParams().scenario};
                        $rootScope.$emit('gridUpdate', args);
                    },2000);  
                    $uibModalInstance.dismiss('cancel');
                    
                    
                    AlertService.add("success", "Deleted successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error", data.errorMessage, 4000);
                }
                vm.reset();
            });
        };

        vm.reset = function () {
            vm.configEntity = angular.copy(vm.configEntity_bckup);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
    return controllers;
});
