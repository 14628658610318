define([
	'angular'

], function () {
	'use strict';

	var services = angular.module('app.auditService', [])
				.factory("AuditDataFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS) {
					var auditDataFactory = {};	
					
					var URLS = {	
			                
			                getAllUserNames: GENERAL_CONFIG.base_url +'/loadJsonObject?action_code=652qui'		                
			            }	
					
					
		            
		            
		            //Get All user names
		            auditDataFactory.getAllUserNames = function(data){
		            	var promise = $http({
		                    method: "GET",
		                    url: URLS.getAllUserNames,
		                    data: data
		                }).then(function (response) {
		                    return response.data;
		                });
		            	
		            	return promise;
		            }
		            	
					
					return auditDataFactory;
		        }])

		    return services;
	
});