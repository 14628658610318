define([
    'angular'
], function () {
    'use strict';

    var services = angular.module('app.externalStateEntityService',[])
        .factory("externalStateEntityService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG) {

                var externalStateEntityService = {};

                var URLS = {
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj'
                }

                externalStateEntityService.saveAdjustmentsDetails = function (_callName, _data, isNew) {
                    var clob_setting = {};
                    var clob_data = [];
                    var params = {};
                    var defer = $q.defer();
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=mvttbv";
                    clob_data.push(_data.rowData);
                    clob_data[0].edited_column = _data.colData.map;
                    clob_data[0].edited_value = _data.rowData[_data.colData.map];
                    clob_setting = {tax_year: _data.rowData.TAX_YEAR};
                    
                    params.process_name = "State_Elimination_Adjustment_Details";
                    params = _.extend({
                        clob_data: JSON.stringify(clob_data)
                    }, params);
                    params = _.extend({
                        clob_setting: JSON.stringify(clob_setting)
                    }, params)
                    params = _.merge({},GlobalService.globalParams, params);
                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    params.scenario = filterParams.scenario;
                    params = _.merge({}, params,filterParams);

                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            //AlertService.add("success", "Data saved Successfully.", 4000);
                                var args = {
                                    tax_year: _data.rowData.TAX_YEAR
                                };
                                $rootScope.$emit('gridUpdate', args);
                                defer.resolve(data);
                        } else if(data.errorMessage && data.errorMessage != "") {
                            AlertService.add("error", data.errorMessage, 4000);
                            defer.reject(data);
                        }else {
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                            defer.reject(data);
                        }
                    });
                }

                return externalStateEntityService;

        }]);

    return services;

});