define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.taxSenAcctService',[])
       .factory("TaxGroupObjectFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector', '$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG',
         function($rootScope,$log,JsonObjectFactory,AlertService, $injector
 		    	  ,$q, $http,
 		           $stateParams, $timeout, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
          var returnObj = {};
          var taxGroupObjFactory ={};
         taxGroupObjFactory.url = {};
         
         taxGroupObjFactory.url.delete_tax_sensitive_details  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nbms3d";
         taxGroupObjFactory.url.save_tax_sensitive_details  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nbms3a";

//         taxGroupObjFactory.getAccntDetails = function(_data){
//               console.log("_data ::",_data);
//               var params= {
//                       "action_code": 'nbms3a',
//                       "OBJECT_ID": _data.OBJECT_ID
//               }
//
//               
//               
//                 return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
//                    
//
//                     if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
//                        AlertService.add("error", data.errorMessage);
//                        return false;
//                    }else{
//                        return data.jsonObject;
//                    }
//                });
//              
//             
//              }
           
           
         taxGroupObjFactory.saveTax  = function(_data,Details){

               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
               var jsonObj = JSON.stringify(Details);
               var jsonSettings =  JSON.stringify(_data);
               console.log(jsonSettings);
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
               params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
               params.process_name =  "Save_Tax_Sensitive_Details";
               console.log(params);
               var promise = $http({
                   method: "post",
                   url: taxGroupObjFactory.url.save_tax_sensitive_details,
                   data: params
               }).then(function (response) {
                   var status = response.status;
                   return response.data;
               });

               return promise;

           };
           
           taxGroupObjFactory.DeleteTax  = function(_data1,Details1){

               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
               var jsonObj = JSON.stringify(Details1);
               var jsonSettings =  JSON.stringify(_data1);
               console.log(jsonSettings);
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
               params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data1);
               params.process_name =  "Delete_Tax_Sensitive_Details";
               console.log(params);
               var promise = $http({
                   method: "post",
                   url: taxGroupObjFactory.url.save_tax_sensitive_details,
                   data: params
               }).then(function (response) {
            	   console.log("@@@@@@@@@@@@@@@@@   taxGroupObjFactory.DeleteTax response ::",response);
                   var status = response.status;
                   return response.data;
               });

               return promise;

           };
           
           	return taxGroupObjFactory;
       }]);
});



