define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.sec174Comments', [])
					.factory(
							"section174ServiceFactory",
							[
									'$rootScope',
									'$log',
									'JsonObjectFactory',
									'AlertService',
									'$injector',
									'$q',
									'$http',
									'$stateParams',
									'USER_SETTINGS',
									'$timeout',
									'GlobalService',
									'DataFiltersFactory',
									'GENERAL_CONFIG',
									'workspaceFactory'

									,
									function($rootScope, $log,
											JsonObjectFactory, AlertService,
											$injector, $q, $http, $stateParams,
											USER_SETTINGS, $timeout,
											GlobalService, DataFiltersFactory,
											GENERAL_CONFIG, workspaceFactory) {
										var factory = {};
										var returnObj = {};
										var userSettings = USER_SETTINGS;
										var logged_in_user = userSettings.user.sso_id;
										var service = $injector
												.get('JsonObjectFactory');
										var section174ServiceFactory = {};
										var url = {};
										// var url = GENERAL_CONFIG.base_url +
										// "/saveJsonObject?action_code=yqrl4y";
										factory.savesection174Comments = function(
												_callName, _data) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var rowData = _data.rowData;
											var jsonObj = JSON
													.stringify(_data.rowData);
											var combination_key = _data.rowData.COMBINATION_KEY
											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var params = _.merge({},
													GlobalService.globalParams,
													filterParams);
										var	 jsonSettings = [{
								                   
								                   "sso_id" : logged_in_user,
								                   "tax_year": GlobalService.globalParams.tax_year,
								                   "scenario": GlobalService.globalParams.scenario,
								                   "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
								                   "me_key" : _data.rowData.CY_ME_KEY
               		   
								                		   
								               }];
							               params = _.extend({jsonSettings: JSON.stringify(jsonSettings) }, params);
										   params = _.extend({
												jsonObj : jsonObj
											}, params);
											var url = GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=p0l6qy";

											service
													.saveJSON(url, params)
													.then(
															function(data) {
																if (data.callSuccess === "1") {
																	AlertService
																			.add(
																					"success",
																					"Data saved Successfully.",
																					4000);
																	var args = {
																		cc_key : combination_key,
																		gridFilter : {
																			cc_key : combination_key
																		}
																	};
																	$rootScope
																			.$emit(
																					'gridUpdate',
																					args);// $rootScope.$emit('gridUpdate',
																							// args);
																} else {
																	// rowData.ILLEGAL_PAYMENTS
																	// = null;
																	// rowData.SANCTIONED_COUNTRY
																	// = null;
																	AlertService
																			.add(
																					"error",
																					data.errorMessage,
																					4000);
																	$rootScope
																			.$broadcast(
																					'gridRefresh',
																					true);
																	// AlertService.add("error",
																	// "An error
																	// has
																	// occurred.
																	// If this
																	// error
																	// continues
																	// please go
																	// to the
																	// help page
																	// and
																	// contact
																	// support");
																}
															});
										}

										return factory;
									} ])

			return services;
		});
