define([
    'angular',
    './ScreenCtrl'
], function () {
    'use strict';
    return angular.module('app.adminScreens', ['app.screenCtrl'])

        .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
            $stateProvider
                .state('admin.screen-list', {
                    url: '/screen-list',
                    templateUrl: ' app/components/admin/screens/screen-list-tpl.html',
                    type:'custom',
                    "api": {"get":GENERAL_CONFIG.base_url+"/loadGridJson?action_id=77&grid_id=32","grid_id":"32"},
                    access: "4"
                });
        }]);


});