define([

    'angular'

], function () {
    'use strict';
    var services = angular.module('app.settlementMainService', [])
        .factory("settlementMainFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', '$injector', 'AlertService', function ($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG, USER_SETTINGS, $injector, AlertService) {


            var factory = {};
            factory.formConfigData = {};
            var URLS = {
                GET_ADN_BUC_CODES: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=yieemt",
                GET_SETTLEMENT_TYPES: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=ho2jiz",
                GET_MODAL_DATA: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=gc8tbu",
                SAVE_MODAL: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=2aymat",
                SAVE_SIGNOFF: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=u9d7ls",
                SAVE_BU: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=s6dauo",
            };
            var userSettings = USER_SETTINGS;
            var logged_in_user = userSettings.user.sso_id;

            factory.getAdnBucCodes = function () {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                    .getFilterParams()
                    : {};
                var params = filterParams;
                params.sso_id = logged_in_user;
                var promise = $http({
                    method: "get",
                    url: URLS.GET_ADN_BUC_CODES,
                    params: params

                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });
                return promise;
            }

            factory.getSettlementTypes = function () {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                    .getFilterParams()
                    : {};
                var params = filterParams;
                params.sso_id = logged_in_user;
                var promise = $http({
                    method: "get",
                    url: URLS.GET_SETTLEMENT_TYPES,
                    params: params

                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });
                return promise;
            }

            factory.fetchModalData = function (data, sso_id, scenario) {
                var params = { "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": data.COMBINATION_KEY, "jcd_ta_key": null, "issue_key": data.ISSUE_KEY, "form_no": "F_SETTLEMENT" };
                var promise = $http({
                    method: "get",
                    url: URLS.GET_MODAL_DATA,
                    params: params

                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });
                return promise;
            };

            factory.saveModal = function (final_data, clob_settings) {
                var clob_setting = JSON.stringify(clob_settings);
                var clob_data = JSON.stringify(final_data);
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = filterParams;
                params.tax_year = GlobalService.globalParams.tax_year;
                params.scenario = GlobalService.globalParams.scenario;
                params.jcd_key = GlobalService.globalParams.jcd_key;
                params.form_no = "F_SETTLEMENT";
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.extend({ clob_data: clob_data }, params);
                params = _.extend({ clob_settings: clob_setting }, params);
                params.process_name = "settlementMain-consol-amounts-save";
                var promise = $http({
                    method: "post",
                    url: URLS.SAVE_MODAL,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            }

            factory.saveSignOff = function (_callName, _data, isNew) {
                var defer = $q.defer();
                if (_data.rowData && _data.rowData.Federal_Method_of_Settlement_Remaining !== "0") {
                    AlertService.add("error", "Federal Method of Settlement Remaining amount should be 0.");
                    return;
                }
                var clob_setting = {};
                var clob_data = [];
                var params = {};
                clob_data.push(_data.rowData);
                clob_setting = [{ tax_year: GlobalService.globalParams.tax_year }];
                params.scenario = GlobalService.globalParams.scenario;
                params.process_name = "Settlement-save-signoff";
                params.tax_year = GlobalService.globalParams.tax_year;
                params.form_no = "F_SETTLEMENT";
                params.is_issue_key_enabled = 'N';
                params = _.extend({
                    clob_data: JSON.stringify(clob_data)
                }, params);
                params = _.extend({
                    clob_settings: JSON.stringify(clob_setting)
                }, params)
                var service = $injector.get('JsonObjectFactory');
                service.saveJSON(URLS.SAVE_SIGNOFF, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        AlertService.add("success", "Data saved Successfully.", 4000);

                        var args = {
                            combination_key: _data.rowData.COMBINATION_KEY,
                            gridFilter: {
                                combination_key: _data.rowData.COMBINATION_KEY
                            }
                        };
                        $rootScope.$emit('gridUpdate', args);

                        defer.resolve(data);
                    }
                    else {
                        AlertService.add("error", data.errorMessage, 4000);
                        defer.reject(data);
                    }
                }
                    , function (data) {
                        defer.reject(data);
                    });
                return defer.promise;
            };

            factory.BUSignOff = function (_callName, _data, isNew) {
                var defer = $q.defer();

                var clob_setting = {};
                var clob_data = [];
                var params = {};
                clob_data = [_data.rowData];
                clob_setting = [{ tax_year: GlobalService.globalParams.tax_year }];
                params.scenario = GlobalService.globalParams.scenario;
                params.process_name = "Settlement-BU-signoff";
                params.tax_year = GlobalService.globalParams.tax_year;
                params.form_name = "Tax Due Summary by HO";
                params.is_issue_key_enabled = 'N';
                params.jcd_key = GlobalService.globalParams.jcd_key;
                params = _.extend({
                    clob_data: JSON.stringify(clob_data)
                }, params);
                params = _.extend({
                    clob_settings: JSON.stringify(clob_setting)
                }, params)
                var service = $injector.get('JsonObjectFactory');
                service.saveJSON(URLS.SAVE_BU, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        AlertService.add("success", "Data saved Successfully.", 4000);

                        var args = {
                            combination_key: _data.rowData.COMBINATION_KEY,
                            gridFilter: {
                                combination_key: _data.rowData.COMBINATION_KEY
                            }
                        };
                        $rootScope.$emit('gridUpdate', args);

                        defer.resolve(data);
                    }
                    else {
                        AlertService.add("error", data.errorMessage, 4000);
                        defer.reject(data);
                    }
                }
                    , function (data) {
                        defer.reject(data);
                    });
                return defer.promise;
            };
            return factory;

        }]);

    return services;

});