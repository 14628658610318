define([
    'angular'
    
], function () {
    'use strict';
    
    var services =  angular.module('app.F-SchddService',[])
    .factory("FSchddFactory", ['$q', '$http', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory',
        'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG',
        function ($q, $http, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory,
            workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG) {
    	
    	var factory = {}; 
    	factory.formConfigData = {};
    	factory.fetchSchddDetails=function(data, sso_id, scenario){
    		var jcd_key = 0;
    		if(data.JCD_KEY == undefined){
    			jcd_key = 250;
    		}else jcd_key = data.JCD_KEY;
    		var params = { "action_code": 'll3gc9', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": scenario, "jcd_key": jcd_key, "combination_key": data.COMBINATION_KEY, "jcd_ta_key": null, "issue_key":data.ISSUE_KEY};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	};
    	
    	factory.deleteRepTransaction=function(rowData, trans_details_key, occurrence, line_no, scenario){
    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=1ydfdj";
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		
    		var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params.combination_key =  rowData.COMBINATION_KEY;
            params.schdd_detail_key = trans_details_key;
            params.occurrence = occurrence;
            params.line_no = line_no;
            params.issue_key = rowData.ISSUE_KEY;
            params.scenario = scenario;
            console.log(params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
    	}
    	
    	factory.saveSchddDetails=function(final_data, clob_settings){
    		console.log("Clob_setting was done as:::::::::------------", clob_settings);
    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=5gjh3w";
    		var json_settings = JSON.stringify(clob_settings);
    		var json_data = JSON.stringify(final_data);
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

    		
    		var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({json_data:json_data}, params);
            params =  _.extend({json_settings:json_settings}, params);
            params.process_name =  "Schedule-D-Form-save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
    	}
    	
    	factory.saveSchddReportableTransaction=function(data, repTrans_data, clob_settings){
    		console.log("Clob_setting was done as:::::::::------------", clob_settings);
    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vlae0g";
    		var json_settings = JSON.stringify(clob_settings);
    		var json_data = JSON.stringify(repTrans_data);
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

    		
    		var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({json_data:json_data}, params);
            params =  _.extend({json_settings:json_settings}, params);
            params.reportable_type =  "SCHDD_REPORTABLE_TRANS";
            params.process_name =  "Schedule-D-Reportable-Transaction-save";
            params.combination_key = data.COMBINATION_KEY;
            console.log(params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
    	}
        
        factory.fetchCategoryCodeDropdownValues = function(data, sso_id) {
            var jcd_key = 0;
    		if(data.JCD_KEY == undefined){
    			jcd_key = 250;
    		}else jcd_key = data.JCD_KEY;
    		var params = { "action_code": 'dg64rm', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO, "jcd_key": jcd_key};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			if(null != data.callSuccess && data.callSuccess == "1"){
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});

        }
    	
    	return factory;
    	
    }]);
    
    return services;
    
});