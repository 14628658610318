define([

    'angular'

], function () {
    'use strict';
    var services = angular.module('app.scheduleDService',[])
        .factory("ScheduleDFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG', function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG) {

        	var scheduleDFactory = {};

            var URLS = {
                FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj'
            }

            scheduleDFactory.getData = function (_url,_params) {
                console.log("TEST/////////////////////////////////////////////////");
            }

            scheduleDFactory.saveScheduleDadj = function(_data,_accounts) {

                var accounts = { jsonObj:JSON.stringify(_accounts) }
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , _data);
                _.merge({},  GlobalService.globalParams , params);
                _.extend(params, accounts);

                console.log("params:============================ " , params);

                var promise = $http({
                    method: "post",
                    url: URLS.UPDATE,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    console.log("response: ", response);
                    console.log("response.status: ", response.status);
                    return response.data;
                });

                return promise;

            }

            return scheduleDFactory;

        }]);

    return services;

});