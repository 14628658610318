define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F8916Ctrl', [])

    	.directive("limitToMax", function() {
		return {
			link: function(scope, element, attributes) {
				element.on("keydown keyup", function(e) {
					if (Number(element.val()) > Number(attributes.max) &&
					  e.keyCode != 46 // delete
					  &&
					  e.keyCode != 8 // backspace
					) {
						e.preventDefault();
						element.val(attributes.max);
					}
					});
				}
			};
		})

        .controller('F8916Controller', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','$filter',F8916Controller]);
    	function F8916Controller(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, $filter) {
            var vm = this;

            vm.modalTitle = "Reconciliation of Schedule M-3 Taxable Income With Tax Return Taxable Income for Mixed Groups ";
    		vm.originalData = [];
    		vm.userSettings = USER_SETTINGS;
    		vm.logged_in_user = vm.userSettings.user.sso_id;
    		vm.leId = rowData.LEID;
            vm.formName = '1120';
            vm.hgt = '96%';
            vm.cdr_no = rowData.MARS;
            vm.leType = rowData.LE_TYPE;

            vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
            $scope.entityList = {
    			"Tax Year": rowData.TAX_YEAR,
    			"LEID": rowData.LEID,
    			"LE Name": rowData.LE_NAME
    		};

 
    		var saveParamDtls = [];
    		var tempDataObj = {};
    		$scope.f8916FormView = {rows:[]};
    		vm.showFormLoader = true;
    		vm.corporationList = [];
    		vm.entityList = [];
    		vm.entitiesData = [];
    		vm.individualsList = [];
    		vm.codeMastMap = [];

            function fetch8916records() {
                var params = { "action_id": 31333, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY,"form_name": "8916", "schedule_name": "NA", "part_no" : "NA"};

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
    				for(var i=1;i<data.irsForm.formItemList.length;i++){
    					saveParamDtls = [];
    					tempDataObj = {};
    					saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
    					var rows = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
    					tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
                    	tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;
                    	tempDataObj.is_editable = data.irsForm.formItemList[i].dataRowList[0].dataCellList[2].property.is_editable_cell;
                    	if(rows > 2){
                    		iterateArray(rows, data.irsForm.formItemList[i].dataRowList[0].dataCellList);
                    	}
    					tempDataObj.line_order = saveParamDtls[8];
    					tempDataObj.saveParams = saveParamDtls;
    					$scope.f8916FormView.rows.push(tempDataObj);
    				}
		
    				vm.originalData = angular.copy($scope.f8916FormView);
    				initFormView($scope.f8916FormView);
    				vm.showFormLoader = false;				
                });
            };

            //check for 7
            var iterateArray = function(count, array){
            	if(count == 3){
    				tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    				tempDataObj.attrib_order_a = array[2].key.split('~')[2];
    				tempDataObj.formula = array[2].formula;
    				

					//if(tempDataObj.num == "1" || tempDataObj.num == "3" || tempDataObj.num == "5" || tempDataObj.num == '6' || tempDataObj.num == '8'){
						//tempDataObj.conditionalField = true;
					//}
					
					if(array[2].value == null){
						tempDataObj.value = 0;
					}else{
						tempDataObj.value = array[2].value;
					}
    				
    			}
    		};
  
    		vm.validateLines = function(line) {
    				var disabledLines = _.filter($scope.f8916FormView.rows, function(o) { 
						return (o.num == '3' || o.num == '5' || o.num == '6' || o.num == '8'); 
					})
    			
					//ENABLE ONCE UTILITY IS CHANGED
				/*	for(var i=0; i < disabledLines.length; i++){
						
						var txt = disabledLines[i].formula;
						var result = txt.match(/\((.*)\)/);
						if(result != null){
						  var finalRes = result[1].split(/[-+,/\\*\\]/);
						}
					
						for(var g=0; g < finalRes.length; g++){
							var apprVal12 = _.filter($scope.f8916FormView.rows, function(o) { 
								return o.num == finalRes[g].trim();
							})
							
							if(apprVal12[0].value == ''){
								apprVal12[0].value = 0;
							}
							result[1] = result[1].replace(apprVal12[0].num,apprVal12[0].value);
						}
						
						if(null!= eval(result[1]) && typeof eval(result[1]) !== 'undefined' && parseInt(eval(result[1])) === parseInt(eval(result[1]))){
							
							 disabledLines[i].value = eval(result[1]);
						}
						
						
//						var output = [];
//						 output = _(apprVal).groupBy('attrib_name_a')
//							    .map((objs, key) => ({
//							        'attrib_name_a': key,
//							        'value': _.sumBy(objs, 'value') })).value();
						 
					} */
					
					
					_.forEach($scope.f8916FormView.rows, function(a) {
						if((a.value == undefined || isNaN(a.value))){
							a.value=0;
						}
					});
					
					//line 3
					$scope.f8916FormView.rows[9].value = (parseInt($scope.f8916FormView.rows[0].value) + parseInt($scope.f8916FormView.rows[1].value) + parseInt($scope.f8916FormView.rows[2].value) + parseInt($scope.f8916FormView.rows[3].value) +
							parseInt($scope.f8916FormView.rows[4].value) + parseInt($scope.f8916FormView.rows[5].value) + parseInt($scope.f8916FormView.rows[6].value) + parseInt($scope.f8916FormView.rows[7].value)  + parseInt($scope.f8916FormView.rows[8].value));
    				
    				//line 5
					$scope.f8916FormView.rows[19].value = (parseInt($scope.f8916FormView.rows[10].value) + parseInt($scope.f8916FormView.rows[11].value) + parseInt($scope.f8916FormView.rows[12].value) + parseInt($scope.f8916FormView.rows[13].value) +
					parseInt($scope.f8916FormView.rows[14].value) + parseInt($scope.f8916FormView.rows[15].value) + parseInt($scope.f8916FormView.rows[16].value) + parseInt($scope.f8916FormView.rows[17].value)  + parseInt($scope.f8916FormView.rows[18].value));
					
					
					//line 6
					$scope.f8916FormView.rows[20].value = (parseInt($scope.f8916FormView.rows[9].value) - parseInt($scope.f8916FormView.rows[19].value));
					

					//line 8
					$scope.f8916FormView.rows[22].value = (parseInt($scope.f8916FormView.rows[21].value) + parseInt($scope.f8916FormView.rows[20].value));
    		}
    		
    		function init8916(){
    				fetch8916records();
    		};
    		init8916(); 
            

            vm.save8916Form = function (url, data){
                var jsonSettings = {
                	"tax_year": rowData.TAX_YEAR,
                	"scenario": rowData.SCENARIO,
                	"jcd_key": rowData.JCD_KEY,
                	"combination_key": rowData.COMBINATION_KEY,
                	"trans_type_key": data[0].editrows[0][0].trans_type_key
                };
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(data);
                var jsonSettings  = JSON.stringify(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                params.process_name =  'F1120_8916'; /*workspaceFactory.activeScreen.label;*/
                params.combination_key = rowData.COMBINATION_KEY;
                params.trans_type_key = data[0].editrows[0][0].trans_type_key;
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                    console.log("save8916Form() response.data.jsonObj ::",response.data.jsonObject);
    				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
    					$uibModalInstance.dismiss('cancel');
    					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
    					$rootScope.$emit('gridUpdate', args);
    					AlertService.add("success", "Transactions saved successfully", 4000);
    				}else{
    					if(response.data && response.data.errorMessage){
                            AlertService.add("error", response.data.errorMessage,4000);
                        }else{
                            AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                        }
    				}
                    return response.data;
                });
                console.log("------------At the end-----------", promise);
                return promise;
            };

            vm.save = function(){
    			var formRowDataA = {};
    			var formRowDataB = {};
    			var tempArray = [];
    			/*if(!vm.isValid()){
    				AlertService.add("error", "Please provide the mandatory fields.",4000);
    				return;
    			}*/
    			for(var i=0; i<$scope.f8916FormView.rows.length ;i++){
    				
    				formRowDataA = {
    					tax_year: rowData.TAX_YEAR,
    					trans_type_key: $scope.f8916FormView.rows[i].saveParams[1],
    					line_order: $scope.f8916FormView.rows[i].line_order,
                        attrib_order: $scope.f8916FormView.rows[i].attrib_order_a,
                        line_description: $scope.f8916FormView.rows[i].description,
    					combination_key: rowData.COMBINATION_KEY,
    					group_obj_key: rowData.GROUP_OBJ_KEY,
    					form_key: $scope.f8916FormView.rows[i].saveParams[2],
    					line_no: $scope.f8916FormView.rows[i].num,
    					occurence: $scope.f8916FormView.rows[i].saveParams[7],
    					line_attrib_key: $scope.f8916FormView.rows[i].lineAttrKey_a,
    					Attribute_Name: $scope.f8916FormView.rows[i].attrib_name_a,
//    					Attribute_Value: $scope.f8916FormView.rows[i].value ? $scope.f8916FormView.rows[i].value.toString() : '',
    					Attribute_Value: $scope.f8916FormView.rows[i].num == '1' ? null : $scope.f8916FormView.rows[i].value ? $scope.f8916FormView.rows[i].value.toString() : '',
    					trans_dtls_key: $scope.f8916FormView.rows[i].saveParams[3],
    					trans_status: $scope.f8916FormView.rows[i].saveParams[6],
    					is_dirty: ''
    				};
    				formRowDataA.JSON_DATA = [];
    				tempArray.push(formRowDataA);

    				
    			}
    			vm.formData.editrows.push(tempArray);
    			vm.mainData = [];
    			vm.mainData.push(vm.formData);
    			console.log(vm.mainData);
    			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=z9frws"; 
    			vm.save8916Form(url,vm.mainData);

                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function() {
                    $scope.$broadcast("showLoader", true);
                },100);
            };


    		vm.reset = function (){
    			$scope.f8916FormView = angular.copy(vm.originalData);
    		};

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            function initFormView(filteredFormData) {
    			$scope.f8916FormView = filteredFormData;
    		};

    		//Dynamic Tabset Code
    		vm.tabs = [
    			 {id: 1, name: "Form Entry", url: "app/components/F1120/F8916/F8916Entry.html", active: true }
    	    ];
        }
});