define([
    'angular'
], function () {
    'use strict';
	
    var controllers = angular.module('app.schedule-FCtrl', [])
		    .controller('scheduleFCtrl', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', 
			'scheduleFGroupObjFactory','GENERAL_CONFIG', scheduleFCtrl])
			
            .controller('scheduleFCtrlModal', ['GlobalService', 'AlertService', 'JsonObjectFactory','ModalFactory', '$parse', '$rootScope','$scope', 
            	'scheduleFGroupObjFactory', 'GENERAL_CONFIG', '$uibModalInstance', 'rowData', 'colData', 'gridData', scheduleFCtrlModal]);
    
    function scheduleFCtrl(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope, $scope,scheduleFGroupObjFactory, GENERAL_CONFIG) {
    	scheduleFCtrlModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope, scheduleFGroupObjFactory, GENERAL_CONFIG, null, null, null, null, this); 
	}
	
    	
            function scheduleFCtrlModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope, scheduleFGroupObjFactory, GENERAL_CONFIG, $uibModalInstance,
    				rowData, colData, gridData,thisObj  ) {
            	
            	var vm = this||thisObj;
				vm.rowData = rowData ||$scope.$parent.rowData; 
                $scope.irsF5471ScheduleFData = [];
                $scope.irsF5471ScheduleFOriginalData = [];
                $scope.irs5471ScheduleFNotes = {};
                vm.adjAmts = [];
                vm.isF5471ScheduleFSaveClicked = false;
            	//vm.scheduleFDetails.value = "";
                vm.modalTitle = "Schedule F: Balance Sheet";
                if(parseInt(GlobalService.globalParams.tax_year) < 2018)
                	vm.line_arr = ['2B','8B','9B','11D','21'];
                else
                	vm.line_arr = ['2B','9B','10B','12D','23'];
                vm.tempNotes = "";
                $scope.crudLoading = false;
               
                
                if(angular.isObject($uibModalInstance)) {
					vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
					vm.cancel = function () {
						var args = {combination_keys: vm.rowData.HO_COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: vm.rowData.HO_COMBINATION_KEY}};
						$rootScope.$emit('gridUpdate', args);
						$uibModalInstance.close();
						vm.modal_name = null;
					};  
				} else {
					vm.modal_name = null;
				}
                
                vm.cancel = function () {
                	var args = {combination_keys: vm.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.HO_COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);  
                    $uibModalInstance.dismiss('cancel');
                };
                
                vm.reset = function()
                {
                	 $scope.crudLoading = false;
                	angular.copy($scope.irsF5471ScheduleFOriginalData, $scope.irsF5471ScheduleFData);
                }
               
            		function loadIRSF5471ScheduleFData()
    		        {
            			vm.isF5471ScheduleFSaveClicked = false;
    		        	$scope.irsF5471ScheduleFData = [];
    		        	$scope.irsF5471ScheduleFOriginalData = [];
    		        	var params = { 
    		        			"action_code": 'iiprik', 
    		        			"irs_form_no":'F5471',
    	            			"combination_key":vm.rowData.HO_COMBINATION_KEY,//vm.rowData.COMBINATION_KEY,
    	            			//"tax_year":2018,
    	            			//"scenario":28,
    	            			"jcd_key" : 250
    		        };
    		        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    		        		if (data.callSuccess === "1") {
    		        			console.log("data.jsonObject---------------------", data.jsonObject);
    		        			var irsF5471ScheduleFDataTemp = data.jsonObject;
    		        			var i = 1;
    		        			
    		        			var previous_line_no = irsF5471ScheduleFDataTemp[i].line_no;
    		        			while( i < irsF5471ScheduleFDataTemp.length  )
    		        			{
    		        				var tempObj = {};
    		        				var j = i;
    		        				//console.log("irsF8858ScheduleMDataTemp[j]----------------", irsF8858ScheduleMDataTemp[j]);
    		        				
    		        				while(j <irsF5471ScheduleFDataTemp.length && previous_line_no == irsF5471ScheduleFDataTemp[j].line_no)
    		        				{
    		        					if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "LINE_NO")
    		        					{
    		        						/*if(irsF5471ScheduleFDataTemp[j].line_no == '13' || irsF5471ScheduleFDataTemp[j].line_no == '22')
    		        						{
    		        							tempObj.backgroudColor = {
    		        								"background": "#D6D6D6",
    		        								"font-weight": "bold"
    		        							 }
    		        					     }
    		        						else
    		        							tempObj.backgroudColor = {};*/
    		        					
    		        						tempObj.line_no = irsF5471ScheduleFDataTemp[j].line_no;
    		        						
    		        						//if(parseInt(irsF5471ScheduleFDataTemp[j].line_no) < )
    		        							tempObj.line_multiplier = null;
    		        							tempObj.startDelimiter = "";
    		        							tempObj.endDelimiter = "";
    		        						/*else
    		        							tempObj.line_multiplier = 1;*/
    		        						tempObj.saveParams = {
    		        								"tax_year":irsF5471ScheduleFDataTemp[j].object_id.split("~")[0],
    		        								"trans_type_key" : irsF5471ScheduleFDataTemp[j].object_id.split("~")[1],
    		        								"trans_Details_key" : irsF5471ScheduleFDataTemp[j].object_id.split("~")[3],
    		        								"occurrence" : irsF5471ScheduleFDataTemp[j].object_id.split("~")[4],
    		        								"form_key" : irsF5471ScheduleFDataTemp[j].object_id.split("~")[6]
    		        						}
    		        					}
    		        					else if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "ASSET" || irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "LIABILITIES_AND_STOCKHOLDER_EQUITY")
    		        					{
    		        						if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[3] === 'Total liabilities and shareholders equity' || irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[3] === 'Total assets')
    		        						{
    		        							tempObj.backgroudColor = {
        		        								"background": "#D6D6D6",
        		        								"font-weight": "bold"
        		        							 }
    		        						}
    		        						else
    		        							tempObj.backgroudColor = {};
    		        						
    		        						tempObj.line_Description = irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[3];
    		        						if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "ASSET")
    		        							tempObj.line_type = 'Assets';
    		        						
    		        						else
    		        							tempObj.line_type = 'Liabilities';
    		        						
    		        					}
    		        					else if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "NOTES" )
    		        					{
    		        						tempObj.VALUE = {
    		        								"VALUE": angular.isDefined(irsF5471ScheduleFDataTemp[j].VALUE) ? irsF5471ScheduleFDataTemp[j].VALUE : null,
		        								    "line_attrib_key":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[1],
		        								    "attrib_name":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0]
    		        						}
    		        						tempObj.line_type = 'NOTES';
    		        						tempObj.line_Description = irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[3];
    		        					}
    		        					else if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "ADJUSTMENT_TO_CURRENT_YEAR_BEGINNING_BALANCE")
    		        					{
    		        
    		        						tempObj.col_c = {
    		        											 "VALUE": angular.isDefined(irsF5471ScheduleFDataTemp[j].VALUE) ? parseInt(irsF5471ScheduleFDataTemp[j].VALUE) : null,
    		        								             "line_attrib_key":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[1],
    		        								             "attrib_name":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0],
    		        								             "is_editable": (irsF5471ScheduleFDataTemp[j].PROPERTY_TYPE == '9' ? true: false),
    		 			        								"is_Display" : (irsF5471ScheduleFDataTemp[j].PROPERTY_TYPE == '0' ? false: true)
    		        								            }
    		        					}
    		        					else if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "BEGINNING_OF_ANNUAL_ACCOUNTING")
    		        					{
    		        						tempObj.col_d = {
       											 "VALUE": angular.isDefined(irsF5471ScheduleFDataTemp[j].VALUE) ? parseInt(irsF5471ScheduleFDataTemp[j].VALUE) : null,
       								             "line_attrib_key":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[1],
       								             "attrib_name":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0],
       								             "is_editable": (irsF5471ScheduleFDataTemp[j].PROPERTY_TYPE == '9' ? true: false),
       								             "is_Display" : (irsF5471ScheduleFDataTemp[j].PROPERTY_TYPE == '0' ? false: true)
       								            }
    		        					}
    		        					else if(irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0] == "END_OF_ANNUAL_ACCOUNTING_PERIOD")
    		        					{
    		        						tempObj.col_e = {
       											 "VALUE": angular.isDefined(irsF5471ScheduleFDataTemp[j].VALUE) ? parseInt(irsF5471ScheduleFDataTemp[j].VALUE) : null,
       								             "line_attrib_key":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[1],
       								             "attrib_name":irsF5471ScheduleFDataTemp[j].attrib_value.split("~")[0],
       								             "is_editable": (irsF5471ScheduleFDataTemp[j].PROPERTY_TYPE == '9' ? true: false),
		        								"is_Display" : (irsF5471ScheduleFDataTemp[j].PROPERTY_TYPE == '0' ? false: true)
       								            };
    		        					}
    		        					
    		        					if(angular.isDefined(tempObj.col_c) && angular.isDefined(tempObj.col_d) && angular.isDefined(tempObj.col_e) && !tempObj.col_c.is_Display && !tempObj.col_d.is_Display && !tempObj.col_e.is_Display )
    		        					{

    		        						tempObj.col_a = {
         											 "VALUE": null ,
         											"is_editable": false,
    		        								"is_Display" : false
         											}
    		        						tempObj.col_b = {
        											 "VALUE": null,
        											 "is_editable": false,
     		        								"is_Display" : false
        								            }
    		        						
    		        					}
    		        					else
    		        					{
    		        						tempObj.col_a = {
        											 "VALUE": null ,
        											"is_editable": false,
   		        								"is_Display" : true
        											}
   		        						tempObj.col_b = {
       											 "VALUE": null,
       											 "is_editable": false,
    		        								"is_Display" : true
       								            }
    		        					}
    		        					
    		        				 previous_line_no = irsF5471ScheduleFDataTemp[j].line_no;
    		        					j++;
    		        				 }
    		        				if(j < irsF5471ScheduleFDataTemp.length)
    		        					previous_line_no = irsF5471ScheduleFDataTemp[j].line_no;
    		        				var tempIndex = $scope.irsF5471ScheduleFData.length;
    		        				$scope.irsF5471ScheduleFData[tempIndex] = tempObj;	
    	                			
    	                			i = j;
    		        			}
    		        			
    		        			console.log("$scope.irsF5471ScheduleFData--------------", $scope.irsF5471ScheduleFData);
    		        			var notesIndex = vm.getIndex('23','Notes');
    		        			$scope.irs5471ScheduleFNotes = $scope.irsF5471ScheduleFData[notesIndex];
    		        			console.log("$scope.irs5471ScheduleFNotes--------------", $scope.irs5471ScheduleFNotes);
    		        			//console.log("$scope.irsF8858ScheduleMOriginalData--------------", $scope.irsF8858ScheduleMOriginalData);
    		        			setAdjAmt();
    		        		}else{
    		        			if (data.errorMessage) {
    								AlertService.add("error", data.errorMessage, 4000);
    								vm.userMessage = "!! Unable to perform Selected Action...";
    							} else {
    								AlertService.add("error", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
    								vm.userMessage = "!! Unable to perform Selected Action...";
    							}
    		        		}
    		        	});
    		        }
            		loadIRSF5471ScheduleFData();
                ////////////////////////////////////////
                function setAdjAmt()
                {
                	vm.adjAmts = [];
                	//var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                	//console.log("filter params", filterParams);
                	//console.log("workspaceFactory.activeScreen================", workspaceFactory.activeScreen); 
                	var params = {
                			"tax_year":GlobalService.globalParams.tax_year,
                			"combination_key":vm.rowData.HO_COMBINATION_KEY,
                			"scenario":GlobalService.globalParams.scenario,
                			"jcd_key":250,
                			}
                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=icq6yn",params).then(function(data) {
                		if (data.callSuccess === "1") {
                			vm.adjAmts = data.jsonObject;
                			var line_20_adjAmt = {};
                			var line_20_index = 0;
                			//////////Will remove this block when, will get all lines invm.adjAmt/////////////
                			var temp_arr = [];
                			if(parseInt(GlobalService.globalParams.tax_year) < 2018)
                				temp_arr=["9A", "9B", "11B", "18A", "21", "1", "2A", "2B", "3", "4", "5", "6", "7", "8A", "8B", "10", "11A", "11C", "11D", "12", "14", "15", "16", "17", "18B", "19", "20"];
                			else
                				temp_arr =["10A", "10B", "11", "21", "1", "2A", "2B", "3", "4","5", "6", "7", "8", "9A", "9B", "12A","12B", "12C", "12D", "14", "15", "16", "17", "18", "19", "20A", "20B", "21", "22", "23", "24"];
                			console.log("vm.adjAmts------------", vm.adjAmts);
                			var k = vm.adjAmts.length;
                		if(vm.adjAmts.length == 0)
                		{
                			for(var j = 0; j<temp_arr.length; j++)
                			{
                				if(parseInt(temp_arr[j]) <13)
                				{
                					var temp_obj = {
                							"PY_BALANCE" : 0,
                							"CY_BALANCE" : 0,
                							"HO_BOY_ADJ_AMT" : 0,
                							"BOA_PERIOD" : 0,
                							"EOA_PERIOD" : 0,
                							"LINE_NO" : temp_arr[j],
                							"ACCT_TYPE" : 1
                							   };
                					vm.adjAmts.push(temp_obj);
                				}
                				else
                				{
                					var temp_obj = {
                							"PY_BALANCE" : 0,
                							"CY_BALANCE" : 0,
                							"HO_BOY_ADJ_AMT" : 0,
                							"BOA_PERIOD" : 0,
                							"EOA_PERIOD" : 0,
                							"LINE_NO" : temp_arr[j],
                							"ACCT_TYPE" : 2
                							   };
            					vm.adjAmts.push(temp_obj);
                				}
                			}
                		}
                		else
                		{
                			for(var j = 0 ; j<temp_arr.length ; j++)
                			{
                				var k = 0;
                				var count = 0;
                				while(k<vm.adjAmts.length)
                				{
                					if(temp_arr[j] == vm.adjAmts[k].LINE_NO)
                					{
                						count = 1;
                						break;
                					}
                					else
                						k++;
                				}
                				if(count == 0)
                				{
                					if(parseInt(temp_arr[j]) <13)
                    				{
                    					var temp_obj = {
                    							"PY_BALANCE" : 0,
                    							"CY_BALANCE" : 0,
                    							"HO_BOY_ADJ_AMT" : 0,
                    							"BOA_PERIOD" : 0,
                    							"EOA_PERIOD" : 0,
                    							"LINE_NO" : temp_arr[j],
                    							"ACCT_TYPE" : 1
                    							   };
                    					vm.adjAmts.push(temp_obj);
                    				}
                    				else
                    				{
                    					var temp_obj = {
                    							"PY_BALANCE" : 0,
                    							"CY_BALANCE" : 0,
                    							"HO_BOY_ADJ_AMT" : 0,
                    							"BOA_PERIOD" : 0,
                    							"EOA_PERIOD" : 0,
                    							"LINE_NO" : temp_arr[j],
                    							"ACCT_TYPE" : 2
                    							   };
                					vm.adjAmts.push(temp_obj);
                    				}
                				}	
                			}
                		}
                		///////////////////////////////////////////////////////////////////
                		console.log("vm.adjAmts-----", vm.adjAmts);
                			
                	    ///////////////////////////////////////////////////////////
                			
                			for(var i = 0; i < vm.adjAmts.length; i++ )
                			{
                				var line_multiplier = null;
                				/////////////TO GET MULTIPLIER FOR EACH LINE FROM ACCT_TYPE///////////////////
                				if(vm.adjAmts[i].ACCT_TYPE == 1)
                					line_multiplier = 1;
                				else
                					line_multiplier = -1;
                				//////////////////////////////////////////////////////////////////////////////////
                				var j = 0;
                				while( j < $scope.irsF5471ScheduleFData.length)
                				{
                					var line_no = vm.adjAmts[i].LINE_NO
                					if($scope.irsF5471ScheduleFData[j].line_no == vm.adjAmts[i].LINE_NO)
                					{
                						//console.log("j------------", j);
                						//////////UPDATEING THE LINES DETAILS(CY, PY, EOA) WITH MULTIPLIER  AND (-1) VALUES- FOR LINES IN THE LIST OF "vm.line_arr"///////////////////////
                						
                						if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[j].line_no) !== -1 )
                						{
	                						if(vm.adjAmts[i].PY_BALANCE != 0)
	                							$scope.irsF5471ScheduleFData[j].col_a.VALUE = (vm.adjAmts[i].PY_BALANCE * line_multiplier*-1);
	                						
	                					    ////ADDING MULTIPLIERS AND DELIMITERS 
	                						$scope.irsF5471ScheduleFData[j].multiplier = line_multiplier;
	                						$scope.irsF5471ScheduleFData[j].startDelimiter = "(";
	                						$scope.irsF5471ScheduleFData[j].endDelimiter = ")";
	                						
	                						if(vm.adjAmts[i].CY_BALANCE != 0)
	                							$scope.irsF5471ScheduleFData[j].col_b.VALUE = (vm.adjAmts[i].CY_BALANCE * line_multiplier *-1);
	                						
	                						if(vm.adjAmts[i].EOA_PERIOD != 0)
	                						{
	                							//vm.scheduleFDetails[j+4].disValue = vm.adjAmts[i].EOA_PERIOD * line_multiplier*-1;
	                							$scope.irsF5471ScheduleFData[j].col_e.VALUE = vm.adjAmts[i].EOA_PERIOD * -1;
	                						}
	                					    
	                						$scope.irsF5471ScheduleFData[j].col_c.VALUE = (($scope.irsF5471ScheduleFData[j].col_c.VALUE != null) ? $scope.irsF5471ScheduleFData[j].col_c.VALUE * -1 : null);
	                						//$scope.irsF5471ScheduleFData[j].col_d.VALUE = (($scope.irsF5471ScheduleFData[j].col_d.VALUE != null) ? $scope.irsF5471ScheduleFData[j].col_d.VALUE * -1 : null);
	                						vm.computeRowTotal($scope.irsF5471ScheduleFData[j].line_no);
		                					
                						}
                						else if( (parseInt(GlobalService.globalParams.tax_year) < 2018 && $scope.irsF5471ScheduleFData[j].line_no == '20') || (parseInt(GlobalService.globalParams.tax_year) >= 2018 && $scope.irsF5471ScheduleFData[j].line_no == '22'))
                						{
                							////////////// FOR RETAINED EARING LINE GETTING DETAILS AND MULTIPLIER NOT UPDATEING //////////////////////
                							var total_eoa = 0;
                							var total_py = 0;
                							var total_cy = 0;
                							for(var k = 0; k<vm.adjAmts.length; k++)
                							{
                								total_eoa = total_eoa + vm.adjAmts[k].EOA_PERIOD;
                								total_py = total_py + vm.adjAmts[k].PY_BALANCE;
                								total_cy = total_cy + vm.adjAmts[k].CY_BALANCE;
                							}
                							//console.log("total of eoa-- for retained earning line ", total_eoa);
                							$scope.irsF5471ScheduleFData[j].col_a.VALUE = ( ((total_py + vm.adjAmts[i].PY_BALANCE*line_multiplier) != 0) ? (total_py + vm.adjAmts[i].PY_BALANCE*line_multiplier) : null);
                							$scope.irsF5471ScheduleFData[j].col_b.VALUE = ( ((total_cy + vm.adjAmts[i].CY_BALANCE*line_multiplier) != 0) ? (total_cy + vm.adjAmts[i].CY_BALANCE*line_multiplier) : null);
                							$scope.irsF5471ScheduleFData[j].col_e.VALUE = ( ((total_eoa + vm.adjAmts[i].EOA_PERIOD*line_multiplier) != 0) ? (total_eoa + vm.adjAmts[i].EOA_PERIOD*line_multiplier) : null);
                							$scope.irsF5471ScheduleFData[j].col_c.VALUE = (($scope.irsF5471ScheduleFData[j].col_c.VALUE != null) ? ($scope.irsF5471ScheduleFData[j].col_c.VALUE * line_multiplier): null);
                							$scope.irsF5471ScheduleFData[j].col_d.VALUE = (($scope.irsF5471ScheduleFData[j].col_d.VALUE != null) ? ($scope.irsF5471ScheduleFData[j].col_d.VALUE * line_multiplier): null);
                							
                							$scope.irsF5471ScheduleFData[j].multiplier = line_multiplier;
                							
                							vm.computeRowTotal($scope.irsF5471ScheduleFData[j].line_no);
                							
                						}
                						else
                						{
                							///////////////UPDATEING LINES DETAILS(CY, PY, COA) WITH MULTIPLIER FRO LINES NOT IN "vm.line_arr" AND 20
                							$scope.irsF5471ScheduleFData[j].multiplier = line_multiplier;
                							
                							if(vm.adjAmts[i].PY_BALANCE != 0)
                								$scope.irsF5471ScheduleFData[j].col_a.VALUE = (vm.adjAmts[i].PY_BALANCE * line_multiplier);
	                						
	                						if(vm.adjAmts[i].CY_BALANCE != 0)
	                							$scope.irsF5471ScheduleFData[j].col_b.VALUE = (vm.adjAmts[i].CY_BALANCE * line_multiplier );
	                						
	                						if(vm.adjAmts[i].EOA_PERIOD != 0)
	                							$scope.irsF5471ScheduleFData[j].col_e.VALUE = (vm.adjAmts[i].EOA_PERIOD * line_multiplier );
	                						
	                						$scope.irsF5471ScheduleFData[j].col_c.VALUE  = (($scope.irsF5471ScheduleFData[j].col_c.VALUE != null) ? ($scope.irsF5471ScheduleFData[j].col_c.VALUE * line_multiplier) : null );
	                						//$scope.irsF5471ScheduleFData[j].col_d.VALUE  = (($scope.irsF5471ScheduleFData[j].col_d.VALUE != null) ? ($scope.irsF5471ScheduleFData[j].col_d.VALUE * line_multiplier) : null );
	                						vm.computeRowTotal($scope.irsF5471ScheduleFData[j].line_no);
                						}
                						break;
                					}
                					j=j+1;
                				}
                			}
                			computeColTotal('a', '1', "Assets");
                			computeColTotal('b', '1', "Assets");
                			computeColTotal('c', '1', "Assets");
                			computeColTotal('d', '1', "Assets");
                			computeColTotal('e', '1', "Assets");
                			computeColTotal('a', '15', "Liabilities");
                			computeColTotal('b', '15', "Liabilities");
                			computeColTotal('c', '15', "Liabilities");
                			computeColTotal('d', '15', "Liabilities");
                			computeColTotal('e', '15', "Liabilities");
                			console.log("Schedule F data after set adj-------",$scope.irsF5471ScheduleFData);
                			
                			angular.copy($scope.irsF5471ScheduleFData, $scope.irsF5471ScheduleFOriginalData);
                			//console.log("vm.priorCurrDetails---------------", vm.priorCurrDetails);
                		 }
                		 else {
							if (data.errorMessage === "no access") {
								AlertService.add("error","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"error",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						 }
                		
                	 });
                } 
                
               vm.getIndex = function(line_no, attrib_Description)
                {
                	var index = null;
                	if(attrib_Description == null)
                	{
	                	for(var i = 0; i<$scope.irsF5471ScheduleFData.length; i++)
	                	{
	                		if(line_no == $scope.irsF5471ScheduleFData[i].line_no)
	                		{
	                			index = i;
	                			break;
	                		}
	                	}
                	}
                	else
                	{
                		for(var i = 0; i<$scope.irsF5471ScheduleFData.length; i++)
	                	{
	                		if(attrib_Description == $scope.irsF5471ScheduleFData[i].line_Description)
	                		{
	                			index = i;
	                			break;
	                		}
	                	}
                	}
                	return index;
                }
               
                vm.computeRowTotal = function(line_no)
                {
                	var total = null;
                	var j = vm.getIndex(line_no, null);
                	if(vm.line_arr.indexOf(line_no) === -1)
                	{
                		if(angular.isDefined($scope.irsF5471ScheduleFData[j]) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_b) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_b.VALUE) && $scope.irsF5471ScheduleFData[j].col_b.VALUE && $scope.irsF5471ScheduleFData[j].col_b.VALUE != null )
                		{
                			total  = $scope.irsF5471ScheduleFData[j].col_b.VALUE.toString();
                		}
                		if(angular.isDefined($scope.irsF5471ScheduleFData[j]) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_c) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_c.VALUE) && $scope.irsF5471ScheduleFData[j].col_c.VALUE && $scope.irsF5471ScheduleFData[j].col_c.VALUE != null )
                		{
                			if(total != null)
                				total  = (parseInt(total) + parseInt($scope.irsF5471ScheduleFData[j].col_c.VALUE)).toString();
                			else 
                				total = ($scope.irsF5471ScheduleFData[j].col_c.VALUE).toString();
                		}
                		if(angular.isDefined($scope.irsF5471ScheduleFData[j]) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_d) )
                		{
                			if(total != null)
                				$scope.irsF5471ScheduleFData[j].col_d.VALUE = parseInt(total);
                			else
                				$scope.irsF5471ScheduleFData[j].col_d.VALUE = total;
                		}
                		
                	}
                	
                	else if (vm.line_arr.indexOf(line_no) !== -1 )
                	{
                		if(angular.isDefined($scope.irsF5471ScheduleFData[j]) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_b) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_b.VALUE) && $scope.irsF5471ScheduleFData[j].col_b.VALUE && $scope.irsF5471ScheduleFData[j].col_b.VALUE != null )
                		{
                			total  = (parseInt($scope.irsF5471ScheduleFData[j].col_b.VALUE) * -1).toString();
                		}
                		if(angular.isDefined($scope.irsF5471ScheduleFData[j]) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_c) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_c.VALUE) && $scope.irsF5471ScheduleFData[j].col_c.VALUE && $scope.irsF5471ScheduleFData[j].col_c.VALUE != null )
                		{
                			if(total != null)
                				total  = (parseInt(total) + (parseInt($scope.irsF5471ScheduleFData[j].col_c.VALUE) * -1) ).toString();
                			else 
                				total = ( parseInt($scope.irsF5471ScheduleFData[j].col_c.VALUE) *-1 ).toString();
                		}
                		if(angular.isDefined($scope.irsF5471ScheduleFData[j]) && angular.isDefined($scope.irsF5471ScheduleFData[j].col_d) )
                		{
                			if(total != null)
                				$scope.irsF5471ScheduleFData[j].col_d.VALUE = parseInt(total)*-1;
                			else
                				$scope.irsF5471ScheduleFData[j].col_d.VALUE = total;
                		}
                	}
                	//console.log("$scope.irsF5471ScheduleFData[j]------", $scope.irsF5471ScheduleFData[j]);
                	computeColTotal('d', $scope.irsF5471ScheduleFData[j].line_no, $scope.irsF5471ScheduleFData[j].line_type);
                	computeColTotal('c', $scope.irsF5471ScheduleFData[j].line_no, $scope.irsF5471ScheduleFData[j].line_type);
                }
                //////////////////////////////////////
               function computeColTotal(section, line_no, line_type)
                {
                	var total = null;
                	var totalLineIndex = null;
                	var i = null;
                	if(line_type == "Assets")
                	{
                		i = 0;
                		totalLineIndex = vm.getIndex(line_no, "Total assets");
                	}
                	else
                	{
                		for(var k = 0 ; k< $scope.irsF5471ScheduleFData.length-1; k++)
                		{
                			if($scope.irsF5471ScheduleFData[k].line_type == "Liabilities")
                			{
                				i = k;
                				totalLineIndex = vm.getIndex(line_no, "Total liabilities and shareholders equity");
                				break;
                			}
                		}
                	}
                	while(i < $scope.irsF5471ScheduleFData.length-1 && i <  totalLineIndex)
                	{
                		if(section == "a" && angular.isDefined($scope.irsF5471ScheduleFData[i]) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_a) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_a.VALUE) && $scope.irsF5471ScheduleFData[i].col_a.VALUE && $scope.irsF5471ScheduleFData[i].col_a.VALUE != null )
	                	{
	                			if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) !== -1)
	                		    {
	                				if(total == null)
	                					total = (parseInt($scope.irsF5471ScheduleFData[i].col_a.VALUE) * -1).toString();
	                				else
	                					total = ( parseInt(total) + (parseInt($scope.irsF5471ScheduleFData[i].col_a.VALUE) * -1) ).toString();
	                		    }
	                			else
	                			{
	                				if(total == null)
	                					total = $scope.irsF5471ScheduleFData[i].col_a.VALUE.toString();
	                				else
	                					total = ( parseInt(total) + parseInt($scope.irsF5471ScheduleFData[i].col_a.VALUE) ).toString();
	                			}
	                		}
	                		if(section == "b" && angular.isDefined($scope.irsF5471ScheduleFData[i]) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_b) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_b.VALUE) && $scope.irsF5471ScheduleFData[i].col_b.VALUE && $scope.irsF5471ScheduleFData[i].col_b.VALUE != null )
	                		{
	                			if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) !== -1)
	                		    {
	                				if(total == null)
	                					total = (parseInt($scope.irsF5471ScheduleFData[i].col_b.VALUE) * -1).toString();
	                				else
	                					total = ( parseInt(total) + (parseInt($scope.irsF5471ScheduleFData[i].col_b.VALUE) * -1) ).toString();
	                		    }
	                			else
	                			{
	                				if(total == null)
	                					total = $scope.irsF5471ScheduleFData[i].col_b.VALUE.toString();
	                				else
	                					total = ( parseInt(total) + parseInt($scope.irsF5471ScheduleFData[i].col_b.VALUE) ).toString();
	                			}
	                		}
	                		if(section == "c" && angular.isDefined($scope.irsF5471ScheduleFData[i]) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_c) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_c.VALUE) && $scope.irsF5471ScheduleFData[i].col_c.VALUE && $scope.irsF5471ScheduleFData[i].col_c.VALUE != null )
	                		{
	                			if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) !== -1)
	                		    {
	                				if(total == null)
	                					total = (parseInt($scope.irsF5471ScheduleFData[i].col_c.VALUE) * -1).toString();
	                				else
	                					total = ( parseInt(total) + (parseInt($scope.irsF5471ScheduleFData[i].col_c.VALUE) * -1) ).toString();
	                		    }
	                			else
	                			{
	                				if(total == null)
	                					total = $scope.irsF5471ScheduleFData[i].col_c.VALUE.toString();
	                				else
	                					total = ( parseInt(total) + parseInt($scope.irsF5471ScheduleFData[i].col_c.VALUE) ).toString();
	                			}
	                		}
	                		if(section == "d" && angular.isDefined($scope.irsF5471ScheduleFData[i]) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_d) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_d.VALUE) && $scope.irsF5471ScheduleFData[i].col_d.VALUE && $scope.irsF5471ScheduleFData[i].col_d.VALUE != null )
	                		{
	                			if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) !== -1)
	                		    {
	                				if(total == null)
	                					total = (parseInt($scope.irsF5471ScheduleFData[i].col_d.VALUE) * -1).toString();
	                				else
	                					total = ( parseInt(total) + (parseInt($scope.irsF5471ScheduleFData[i].col_d.VALUE) * -1) ).toString();
	                		    }
	                			else
	                			{
	                				if(total == null)
	                					total = $scope.irsF5471ScheduleFData[i].col_d.VALUE.toString();
	                				else
	                					total = ( parseInt(total) + parseInt($scope.irsF5471ScheduleFData[i].col_d.VALUE) ).toString();
	                			}
	                		}
	                		if(section == "e" && angular.isDefined($scope.irsF5471ScheduleFData[i]) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_e) && angular.isDefined($scope.irsF5471ScheduleFData[i].col_e.VALUE) && $scope.irsF5471ScheduleFData[i].col_e.VALUE && $scope.irsF5471ScheduleFData[i].col_e.VALUE != null )
	                		{
	                			if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) !== -1)
	                		    {
	                				if(total == null)
	                					total = (parseInt($scope.irsF5471ScheduleFData[i].col_e.VALUE) * -1).toString();
	                				else
	                					total = ( parseInt(total) + (parseInt($scope.irsF5471ScheduleFData[i].col_e.VALUE) * -1) ).toString();
	                		    }
	                			else
	                			{
	                				if(total == null)
	                					total = $scope.irsF5471ScheduleFData[i].col_e.VALUE.toString();
	                				else
	                					total = ( parseInt(total) + parseInt($scope.irsF5471ScheduleFData[i].col_e.VALUE) ).toString();
	                			}
	                			
	                	}
	                		i = i+1;
                	}
                	if(totalLineIndex != null )
                	{
                		switch(section)
		        		{
			        		case 'a' :
			        			$scope.irsF5471ScheduleFData[totalLineIndex].col_a.VALUE = parseInt(total);
			        			break;
			        		case 'b' :
			        			$scope.irsF5471ScheduleFData[totalLineIndex].col_b.VALUE = parseInt(total);
			        			break;
			        		case 'c' :
			        			$scope.irsF5471ScheduleFData[totalLineIndex].col_c.VALUE = parseInt(total);
			        			break;
			        		case 'd' :
			        			$scope.irsF5471ScheduleFData[totalLineIndex].col_d.VALUE = parseInt(total);
			        			break;
			        		case 'e' :
			        			$scope.irsF5471ScheduleFData[totalLineIndex].col_e.VALUE = parseInt(total);
			        			break;
		        		}
                	}
                }
               $scope.$on('workflowSave',function(event,data){
					vm.saveScheduleFDetails();
			   })
                ///////////////////////////not completed save function, some things are left////////////////////
                vm.saveScheduleFDetails = function()
                {
                	if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
			                return;
			            }
                	
                	$scope.crudLoading = true;
                	//console.log(vm.scheduleFDetails[136].VALUE);
                	var totalAssetsIndex =  vm.getIndex('13', "Total assets");
                	var totalLiabilitiesIndex = vm.getIndex('22', "Total liabilities and shareholders equity");
                	if(($scope.irsF5471ScheduleFData[totalAssetsIndex].col_c.VALUE == null && $scope.irsF5471ScheduleFDat[totalLiabilitiesIndex].col_c.VALUE == null) || parseInt($scope.irsF5471ScheduleFData[totalAssetsIndex].col_c.VALUE) == parseInt($scope.irsF5471ScheduleFData[totalLiabilitiesIndex].col_c.VALUE) )
                	{
                		vm.isF5471ScheduleFSaveClicked = true;
                		//console.log("GlobalService.globalParams----------------------------------------",GlobalService.globalParams);
                		var editRow = [];
	                	var addRow = [];
	                	var removeRow = [];
	                	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
	                	
	                	for(var i = 0; i < $scope.irsF5471ScheduleFData.length-1; i++)
	                	{
	                		var value = null;
	                		if($scope.irsF5471ScheduleFData[i].col_c.VALUE != null)
	    		    		 {
	                			 if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) != -1 || $scope.irsF5471ScheduleFData[i].line_type == 'Liabilities')
	                				 value = parseInt($scope.irsF5471ScheduleFData[i].col_c.VALUE)*-1 ;
	                			 else
	                				 value = $scope.irsF5471ScheduleFData[i].col_c.VALUE;
	                			 
	    		    			 var tempObj = [{
	    								"tax_year":$scope.irsF5471ScheduleFData[i].saveParams.tax_year,
	    								"trans_type_key":$scope.irsF5471ScheduleFData[i].saveParams.trans_type_key,
	    								"combination_key":vm.rowData.HO_COMBINATION_KEY, //vm.rowData.COMBINATION_KEY,
	    								"group_obj_key": vm.rowData.GROUP_OBJ_KEY,
	    								"form_key":$scope.irsF5471ScheduleFData[i].saveParams.form_key,
	    								"line_no":$scope.irsF5471ScheduleFData[i].line_no,
	    								"occurence":(($scope.irsF5471ScheduleFData[i].saveParams.occurrence == "0") ? null : $scope.irsF5471ScheduleFData[i].saveParams.occurrence),
	    								"line_attrib_key" : $scope.irsF5471ScheduleFData[i].col_c.line_attrib_key,
	    								"Attribute_Name": $scope.irsF5471ScheduleFData[i].col_c.attrib_name,
	    								"Attribute_Value": value,
	    								"trans_dtls_key" : (($scope.irsF5471ScheduleFData[i].saveParams.trans_Details_key == "0") ? null : $scope.irsF5471ScheduleFData[i].saveParams.trans_Details_key),
	    								"trans_status":"A"}];
	    	                		
	    	                			editRow.push(tempObj);
	    		    		 }
	                		 
	                		 /*if($scope.irsF5471ScheduleFData[i].col_d.VALUE != null)
	    		    		 {
	                			 if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) != -1 || $scope.irsF5471ScheduleFData[i].line_type == 'Liabilities')
	                				 value = parseInt($scope.irsF5471ScheduleFData[i].col_d.VALUE)*-1 ;
	                			 else
	                				 value = $scope.irsF5471ScheduleFData[i].col_d.VALUE;
	                			 
	    		    			 var tempObj = [{
	    								"tax_year":$scope.irsF5471ScheduleFData[i].saveParams.tax_year,
	    								"trans_type_key":$scope.irsF5471ScheduleFData[i].saveParams.trans_type_key,
	    								"combination_key":vm.rowData.HO_COMBINATION_KEY, //vm.rowData.COMBINATION_KEY,
	    								"group_obj_key": vm.rowData.GROUP_OBJ_KEY,
	    								"form_key":$scope.irsF5471ScheduleFData[i].saveParams.form_key,
	    								"line_no":$scope.irsF5471ScheduleFData[i].line_no,
	    								"occurence":(($scope.irsF5471ScheduleFData[i].saveParams.occurrence == "0") ? null : $scope.irsF5471ScheduleFData[i].saveParams.occurrence),
	    								"line_attrib_key" : $scope.irsF5471ScheduleFData[i].col_d.line_attrib_key,
	    								"Attribute_Name": $scope.irsF5471ScheduleFData[i].col_d.attrib_name,
	    								"Attribute_Value": value,
	    								"trans_dtls_key" : (($scope.irsF5471ScheduleFData[i].saveParams.trans_Details_key == "0") ? null : $scope.irsF5471ScheduleFData[i].saveParams.trans_Details_key),
	    								"trans_status":"A"}];
	    	                		
	    	                			editRow.push(tempObj);
	    		    		 }
	                		 
	                		 if($scope.irsF5471ScheduleFData[i].col_e.VALUE != null)
	    		    		 {
	                			 if(vm.line_arr.indexOf($scope.irsF5471ScheduleFData[i].line_no) != -1 || $scope.irsF5471ScheduleFData[i].line_type == 'Liabilities')
	                				 value = parseInt($scope.irsF5471ScheduleFData[i].col_e.VALUE)*-1 ;
	                			 else
	                				 value = $scope.irsF5471ScheduleFData[i].col_e.VALUE;
	                			 
	    		    			 var tempObj = [{
	    								"tax_year":$scope.irsF5471ScheduleFData[i].saveParams.tax_year,
	    								"trans_type_key":$scope.irsF5471ScheduleFData[i].saveParams.trans_type_key,
	    								"combination_key":vm.rowData.HO_COMBINATION_KEY, //vm.rowData.COMBINATION_KEY,
	    								"group_obj_key": vm.rowData.GROUP_OBJ_KEY,
	    								"form_key":$scope.irsF5471ScheduleFData[i].saveParams.form_key,
	    								"line_no":$scope.irsF5471ScheduleFData[i].line_no,
	    								"occurence":(($scope.irsF5471ScheduleFData[i].saveParams.occurrence == "0") ? null : $scope.irsF5471ScheduleFData[i].saveParams.occurrence),
	    								"line_attrib_key" : $scope.irsF5471ScheduleFData[i].col_e.line_attrib_key,
	    								"Attribute_Name": $scope.irsF5471ScheduleFData[i].col_e.attrib_name,
	    								"Attribute_Value": value,
	    								"trans_dtls_key" : (($scope.irsF5471ScheduleFData[i].saveParams.trans_Details_key == "0") ? null : $scope.irsF5471ScheduleFData[i].saveParams.trans_Details_key),
	    								"trans_status":"A"}];
	    	                		
	    	                			editRow.push(tempObj);
	    		    		 }*/
	                		
	                		
	                	}
	                	
	                	if($scope.irs5471ScheduleFNotes.VALUE.VALUE != null)
	   		    		 {
	   		    			 var tempObj = [{
	   								"tax_year":$scope.irs5471ScheduleFNotes.saveParams.tax_year,
	   								"trans_type_key":$scope.irs5471ScheduleFNotes.saveParams.trans_type_key,
	   								"combination_key":vm.rowData.HO_COMBINATION_KEY, //vm.rowData.COMBINATION_KEY,
	   								"group_obj_key": vm.rowData.GROUP_OBJ_KEY,
	   								"form_key":$scope.irsF5471ScheduleFData[i].saveParams.form_key,
	   								"line_no":$scope.irs5471ScheduleFNotes.line_no,
	   								"occurence":(($scope.irs5471ScheduleFNotes.saveParams.occurrence == "0") ? null : $scope.irs5471ScheduleFNotes.saveParams.occurrence),
	   								"line_attrib_key" : $scope.irs5471ScheduleFNotes.VALUE.line_attrib_key,
	   								"Attribute_Name": $scope.irs5471ScheduleFNotes.VALUE.attrib_name,
	   								"Attribute_Value": $scope.irs5471ScheduleFNotes.VALUE.VALUE,
	   								"trans_dtls_key" : (($scope.irs5471ScheduleFNotes.saveParams.trans_Details_key == "0") ? null : $scope.irs5471ScheduleFNotes.saveParams.trans_Details_key),
	   								"trans_status":"A"}];
	   	                		
	   	                			editRow.push(tempObj);
	   		    		 }
	                	var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":removeRow};
	                	console.log("ScheduleDetails---------------------", scheduleDetails);
	            		var message = "Schedule F details are sucessfully save/edit";
	            		
	            		scheduleFGroupObjFactory.saveScheduleF(scheduleSettings, scheduleDetails).then(function(result) {
	            			if (result.errorMessage
								&& result.errorMessage !== 'null') {
	            				$scope.crudLoading = false;
							AlertService.add("error",
								result.errorMessage, 4000);
							} else {
								$scope.crudLoading = false;
								AlertService.add("success", message, 4000);
								
							}
						});
	              }
                else
                	{
                		$scope.crudLoading = false;
                		var message = "Total Assets should be equal to Total Liabilities and Stockholders' equity, Please Adjust the Amount.";
                		AlertService.add("error", message, 4000);
                	}
                }
                
            }
 });