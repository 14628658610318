define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.alertController',[])
        .controller('AppAlertController',['$scope', '$rootScope', 'AlertService', alertController]);


    function alertController($scope,$rootScope,AlertService) {
        $scope.pageTitle = "Alerts / Notifications";
    }


    return controllers;

});
