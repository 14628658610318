define([
   'angular'

], function () {
   'use strict';

   var controllers = angular.module('app.MaxLoanHOBalanceCtrl', [])
      .controller('maxLoanHOCtrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
         'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', maxLoanHOCtrl
      ]);

   function maxLoanHOCtrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG) {
      var vm = this;
      vm.maxLoanTableData = [{
            "acc_code": "002031000",
            "acc_desc": "Borrowing from related U.S. companies (excluding trade payables)",
            "amount": "0",
            "maxBalance": "0"
         },
         {
            "acc_code": "002032000",
            "acc_desc": "Borrowing from related non-U.S. companies (excluding trade payables)",
            "amount": "94,991,359",
            "maxBalance": "94,991,359"
         },
         {
            "acc_code": "001061000",
            "acc_desc": "Loans and Advances to Related U.S. companies",
            "amount": "0",
            "maxBalance": "0"
         },
         {
            "acc_code": "001061001",
            "acc_desc": "Loans and Advances to Related Non-U.S. companies",
            "amount": "0",
            "maxBalance": "0"
         }
      ];
      /* save from workflow 5/14/2019 */
      $scope.$on('workflowSave', function (event, data) {
         vm.save();
      });
      vm.save = function () {
         AlertService.add("success", "Data save Successfully", 4000);
      }
   }
});