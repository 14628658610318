define([
	'angular'

], function () {
	'use strict';

	var controllers = angular.module('app.JdcManagerController', [])
		.controller('JdcController', ['$scope', '$state', '$uibModal', '$log', 'GridFactory', 'ModalFactory','GENERAL_CONFIG', jdcController])
		.controller('JdcManagerController', ['$scope', '$rootScope', '$uibModal', 'JdcManagerFactory', 'JsonObjectFactory', 'AlertService', 'GENERAL_CONFIG', 'GlobalService','USER_SETTINGS','WorkflowTCFactory', 'processMapFactory', '$filter','$timeout', jdcManagerController])
		.controller('SaveTaskController', ['$rootScope', '$scope', '$state', '$log', 'JsonObjectFactory', 'AlertService', '$timeout', '$uibModalInstance', 'rowData', 'JdcManagerFactory', 'JCDFactory','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','MessageFactory','FileAttachementFactory', saveTaskController])
		.controller('SaveScenarioController', ['$rootScope', '$scope', '$state', '$log', 'JsonObjectFactory', 'AlertService', '$timeout', '$uibModalInstance', 'rowData', 'JdcManagerFactory', 'JCDFactory','GENERAL_CONFIG','USER_SETTINGS', saveScenarioController])
		.controller('SaveActivityController', ['$rootScope', '$scope', '$filter', '$state', '$log', 'JsonObjectFactory', 'AlertService', '$timeout', '$uibModal', '$uibModalInstance', 'rowData', 'JdcManagerFactory', 'JCDFactory','GENERAL_CONFIG','USER_SETTINGS','WorkflowTCFactory', 'AdminFactory', 'TemplateAssignFactory', 'gridData', saveActivityController])
		.controller('SaveYearController', ['$rootScope', '$scope', '$state', '$log', 'JsonObjectFactory', 'AlertService', '$timeout', '$uibModal','$uibModalInstance', 'rowData', 'JdcManagerFactory', 'JCDFactory','GENERAL_CONFIG','USER_SETTINGS', saveYearController])
		.controller('reordertasksController', ['$rootScope', '$window', '$scope', 'tasks_list', 'channel', '$state', '$http', 'ModalFactory', 'AlertService', 'GlobalService', '$timeout', '$uibModalInstance', 'JdcManagerFactory', 'USER_SETTINGS', '$filter', reordertasksController])
		.controller('copyAccessController', ['$rootScope', '$window', '$scope', 'tasks_list', 'jcd_key', 'activity', '$state', '$http', 'ModalFactory', 'AlertService', 'GlobalService', '$timeout', '$uibModalInstance', 'JdcManagerFactory', 'USER_SETTINGS', '$filter', copyAccessController])
		.controller('getAcctivityUsersController', ['$rootScope', '$window', '$scope', 'tasks_list', 'jcd_key', 'activity', '$state', '$http', 'ModalFactory', 'AlertService', 'GlobalService', '$timeout', '$uibModalInstance', 'JdcManagerFactory', 'USER_SETTINGS', '$filter', getAcctivityUsersController])
		.controller('activityTemplateAssignController', ['$rootScope', '$scope', '$filter', '$state', '$log', 'JsonObjectFactory', 'AlertService', '$timeout', '$uibModal', '$uibModalInstance', 'JdcManagerFactory', 'JCDFactory','GENERAL_CONFIG','USER_SETTINGS','WorkflowTCFactory', 'AdminFactory', 'TemplateAssignFactory', 'modalData', 'GlobalService', activityTemplateAssignController])

		.directive('numberConverter', function() {
			return {
			  priority: 1,
			  restrict: 'A',
			  require: 'ngModel',
			  link: function(scope, element, attr, ngModel) {
				function toModel(value) {
					return "" + value; // convert to string
				  }
			
				  function toView(value) {
					return parseInt(value); // convert to number
				  }
			
				  ngModel.$formatters.push(toView);
				  ngModel.$parsers.push(toModel);
				
			  }
			};
		  })

		.directive('nodesList', function() {
		    return {
		      templateUrl: 'app/components/admin/jdcManager/nodes-listing.html'
		    };
		});

	function jdcController($scope, $state, $uibModal, $log, GridFactory, ModalFactory) {
		var vm = this;
		vm.pageTitle = "Admin";
		vm.gridData = [];

		vm.jdcLinks = [{
				"label": "JDC",
				"access": 5
			},
			{
				"label": "NEW JDC",
				"access": 3
			},

		];
	}

	function saveYearController($rootScope,$scope,$state,$log,JsonObjectFactory,AlertService,$timeout,$uibModal,$uibModalInstance,rowData,JdcManagerFactory,JCDFactory,GENERAL_CONFIG, USER_SETTINGS) {
		var vm = this;
		vm.mode =rowData.action;
		vm.crudLoading = false;
		vm.user_settings = USER_SETTINGS;
		vm.title = _.capitalize(vm.mode) + ' TaxYear';
		vm.taxyear = {
			jcd_tax_year_key: '',
			client: '',
			jurisdiction: '',
			domain: '',
			channel: '',
			tax_year: '',
			edit_level: '',
			visible: '',
			is_default: '',
			loading: false,
			yearLoading: false,
			is_locked: '',
		};

		vm.masterCopy = angular.copy(vm.taxyear);
		vm.editlevelList = {
			selected: null,
			list: [{
				name: "User",
				value: 1
			}, {
				name: "Editor",
				value: 2
			}, {
				name: "Admin",
				value: 3
			}, {
				name: "Developer",
				value: 4
			}, {
				name: "Super Admin",
				value: 5
			}]
		};

		vm.visibleList = {
			selected: null,
			list: [{
				name: "User",
				value: 1
			}, {
				name: "Editor",
				value: 2
			}, {
				name: "Admin",
				value: 3
			}, {
				name: "Developer",
				value: 4
			}, {
				name: "Super Admin",
				value: 5
			}]
		};

		vm.isdefaultList ={
			list:[{
				name: 'Yes',
				value: 1
			}, {
				name: 'No',
				value: 0
			}]
		} ;
		if (_.lowerCase(vm.mode) == _.lowerCase('edit')) {
			vm.taxyear.jcd_tax_year_key = rowData.taxYearObj.jcd_tax_year_key;
			vm.taxyear.tax_year = rowData.taxYearObj.tax_year;
			vm.taxyear.is_locked = rowData.taxYearObj.is_locked;
			vm.taxyear.edit_level = _.find(vm.editlevelList.list, function (obj) {
				return (obj.value == Number(rowData.taxYearObj.edit_level));
			})
			vm.taxyear.visible = _.find(vm.visibleList.list, function (obj) {
				return (obj.value == Number(rowData.taxYearObj.visible));
			});
			vm.taxyear.is_default = _.find(vm.isdefaultList.list, function (obj) {
				return (obj.value == Number(rowData.taxYearObj.is_default));
			});

		};


		vm.getInitInfo = function () {
			vm.taxyear.loading = true;
			vm.taxyear.client = vm.user_settings.user.client;
			// get the clientLIst initial load itself.
			if (vm.taxyear.client && vm.taxyear.client.client_key != null)
				JdcManagerFactory.getInitInfo({
					client: {
						value: vm.taxyear.client.client_key
					}
				}).then(function (data) {

					vm.channelList = data.data.jcdManage.channelList;
					if(vm.channelList.length === 0){
						AlertService.add("warning","you dont have App Key, please create the Channel & JCD or else create the JCD for related client",4000);
					}

					else if (angular.isDefined(rowData.channel) &&
						(rowData.channel != null) &&
						(rowData.channel != '')
					) {
						vm.taxyear.channel = _.find(vm.channelList, function (obj) {
							return (obj.channel_key == rowData.channel.channel_key);
						});
					}

					vm.taxyear.loading = false;

				}, function () {
					AlertService.add("error", "Problem getting Initialization info.",4000);
					vm.taxyear.loading = false;
				});
		};
		//get All CLients
		vm.getClients = function () {
			vm.clientList = JdcManagerFactory.clientList;
			if (angular.isDefined(rowData.client) &&
				(rowData.client != null) &&
				(rowData.client != '')) {
				vm.taxyear.client = _.find(vm.clientList, function (obj) {
					return (obj.client_key == rowData.client.client_key);
				});
				vm.getInitInfo();
			}
		}
		vm.getClients();

		vm.clearForm = function () {
			var jcdKey = vm.taxyear.jcd_key;
			vm.taxyear = angular.copy(vm.masterCopy);
			vm.taxyear.jcd_key = jcdKey;
			$scope.saveYearFrm.$setPristine();
		};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
			$rootScope.$broadcast('gtw.admin.jdc.yearModalClose');
		};


		vm.requiredFields = {
			//client: false,
			// jurisdiction: false,
			// domain: false,
			channel: false,
			tax_year: false,
			edit_level: false,
			visible: false,
			is_default: false
		};
		setTimeout(function () {
			var fieldNames = _.keys(vm.requiredFields); // _.keys--Creates a Minified list containing all property names of the specified object
			for (var i = 0; i < fieldNames.length; i++) {
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.taxyear.', fieldNames[i], 'requiredFields');
			}
		});
		vm.taxyear.yearLoading = false;
		$scope.saveButtonText = "Save";
		vm.save = function(){

			var filterParams = {
				client: {
					name: vm.taxyear.client.client_name,
					value: vm.taxyear.client.client_key
				},
				jurisdiction:{
					name:'US',
					value:1
				},
				domain:{
					name:'Direct',
					value:9
				},
				channel: {
					name: vm.taxyear.channel.channel_name,
					value: vm.taxyear.channel.channel_key
				},
				is_locked: vm.taxyear.is_locked
			};

			// validate the rquired fields are filled-out or not
			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveYearFrm', 'taxyear', vm.requiredFields)) {
				return;
			}


			var payLoadForValidation = {
				tax_year: vm.taxyear.tax_year+'',
				edit_level: vm.taxyear.edit_level.value+'',
				visible: vm.taxyear.visible.value+'',
				is_default: vm.taxyear.is_default.value+'',
				is_locked: vm.taxyear.is_locked+''
			};
			if(rowData.taxyearList && _.find(rowData.taxyearList,payLoadForValidation)){
				AlertService.add("error", "Duplicate Error: Please select different combination !",4000);
				return;
			}
			vm.taxyear.yearLoading = true;
			// validate the JCD combination
			JdcManagerFactory.getJCDKey(filterParams).then(function (response) {
				if (!response.data.jcdKey || response.data.jcdKey == 0) {
					AlertService.add("error", "Please select valid JCD combination !",4000);
				}

				// create and update taxyear screen
				var payLoadForSaving = [{
					JCD_KEY: response.data.jcdKey,
					TAX_YEAR: vm.taxyear.tax_year,
					EDIT_LEVEL: vm.taxyear.edit_level.value,
					VISIBLE: vm.taxyear.visible.value,
					IS_DEFAULT: vm.taxyear.is_default.value,
					IS_LOCKED: vm.taxyear.is_locked
				}];


				var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=8vygsp";

				if (vm.mode == 'edit') { //edit action code "
					payLoadForSaving[0]["JCD_TAX_YEAR_KEY"] = rowData.taxYearObj.jcd_tax_year_key;
					url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=56p5yf";
				}

				var jsonObj = JSON.stringify(payLoadForSaving);
				var jsonSettings = '{}';
				var params = _.extend({
					jsonObj: jsonObj
				}, params);
				params = _.extend({
					jsonSettings: jsonSettings
				}, params);

				var validateErr=[];
				if (vm.crudLoading) {
					AlertService.add("info", "Please wait while we save this request", 4000);
					return;
				  }

				vm.crudLoading = true;
				/* tax year Validation whether its already ther or not */
				if(_.filter(rowData.taxyearList.tax_year,{tax_year:vm.taxyear.tax_year}).length){
					validateErr.push("TaxYear Already exists");
				}
				if(validateErr.length){
					for(var i=0;i<validateErr.length;i++){
						AlertService.add("error",validateErr[i],4000);
						vm.crudLoading = false;
					}
					return;
				}

				JsonObjectFactory.saveJSON(url, params).then(function (data) {
					if (data.callSuccess === "1") {
						AlertService.add("success", "taxyear " + vm.taxyear.jcd_tax_year_key + " " + (vm.mode == 'edit' ? "edited" : "saved") + " successfully.",4000);
						vm.crudLoading = false;
						$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
						vm.cancel();
					} else {
						AlertService.add("error", " Problem saving taxYear: ",4000);
						vm.crudLoading = false;
					}

				});
			});
		}
	}

	function saveTaskController($rootScope, $scope, $state, $log, JsonObjectFactory, AlertService, $timeout, $uibModalInstance, rowData, JdcManagerFactory, JCDFactory,GENERAL_CONFIG, USER_SETTINGS,SERVER_CONFIG,MessageFactory,FileAttachementFactory) {

		var vm = this;
		vm.crudLoading = false;
		vm.user_settings = USER_SETTINGS;
		vm.mode = (angular.isDefined(rowData.task_key) && (rowData.task_key != null) && (rowData.task_key != '')) ?
			(function () {
				vm.task_key = rowData.task_key;
				return 'edit'
			}()) :'new';

		vm.title = _.capitalize(vm.mode) + ' Task';

		vm.task = {
			task_key: '',
			task_name: '',
			task_desc: '',
			task_priority: '',
			task_status: '',
			client: '',
			jurisdiction: '',
			domain: '',
			channel: '',
			loading: false,
			taskLoading: false,
			parentTask:''
		};


		vm.masterCopy = angular.copy(vm.task);

		vm.statusList = [{
			name: 'Active',
			value: 'A'
		}, {
			name: 'Inactive',
			value: 'I'
		}];

		vm.attachmentTO = {
			FILE_KEY: '',
			FILE_NAME: '',
			FILE_PATH: '',
			FILE_TYPE: '',
			FILE_SIZE: '',
			CONTENT_TYPE: '',
			ATTACHMENT_KEY: '',
			comment_attachment_change_callSuccess: false,
			saveClicked: false,
			logoFilesMarkedForDeletion: [],
			datasource_connection_msg: '',
			datasource_connection_status: '',
			flow: {},
			attachment_file: ''
	  	};

		if (_.lowerCase(vm.mode) == _.lowerCase('edit')) {
			vm.task.task_name = rowData.task_name;
			vm.task.task_priority = parseFloat(rowData.task_priority, 10);
			vm.task.task_key = rowData.task_key;
			vm.task.task_desc = rowData.task_desc;
			vm.task.task_status = _.find(vm.statusList, function (obj) {
				return (obj.value == rowData.task_status);
			});
			vm.task.parentTask = rowData.parent_task_key
		};

		vm.getInitInfo = function () {

			vm.task.loading = true;
			vm.task.client = vm.user_settings.user.client;
			//get the clientList "ng-model = ctrl.task.client"in intial load
			if (vm.task.client && vm.task.client.client_key !== undefined && vm.task.client.client_key !== null)
				JdcManagerFactory.getInitInfo({
					client: {
						value: vm.task.client.client_key
					}
				}).then(function (data) {
					vm.channelList = data.data.jcdManage.channelList;

					if(vm.channelList.length === 0){
						AlertService.add("warning","you dont have App Key, please create the Channel & JCD or else create the JCD for related client",4000);
					}
					else if (angular.isDefined(rowData.channel) &&
						(rowData.channel != null) &&
						(rowData.channel != '')
					) {
						vm.task.channel = _.find(vm.channelList, function (obj) {
							return (obj.channel_key == rowData.channel.channel_key);
						});
						vm.loadTasks();
					}
					vm.task.loading = false;

				}, function () {
					AlertService.add("error", "Problem getting Initialization info.",4000);
					vm.task.loading = false;
				});
		};


		//get all the clientLIst
		vm.getClients = function () {
			vm.clientList = JdcManagerFactory.clientList;
			if (angular.isDefined(rowData.client) &&
				(rowData.client != null) &&
				(rowData.client != '')) {
				vm.task.client = _.find(vm.clientList, function (obj) {
					return (obj.client_key == rowData.client.client_key);
				});
				vm.getInitInfo();
			}
		}
		vm.getClients();

		vm.clearForm = function () {
			var taskKey = vm.task.task_key;
			var taskClient = vm.task.client;
			vm.task = angular.copy(vm.masterCopy);
			vm.task.task_key = taskKey;
			vm.task.channel = vm.channelList.find(function(c) {
				return c.channel_name === rowData.channel.channel_name;
			});
			vm.task.client = taskClient;
			$scope.saveTaskFrm.$setPristine();
			vm.loadTasks();
		};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
			$rootScope.$broadcast('gtw.admin.jdc.taskModalClose');
		};

		// requiredFields for Task screen
		vm.requiredFields = {
			//client: false,
			channel: false,
			task_name: false,
			task_priority: false,
			task_status: false,
			task_desc: false
		};
		setTimeout(function () {
			var fieldNames = _.keys(vm.requiredFields); // _.keys--Creates a Minified list containing all property names of the specified object
			for (var i = 0; i < fieldNames.length; i++) {
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.task.', fieldNames[i], 'requiredFields');
			}
		});

		vm.isMissing = function (obj) {
			return (angular.isUndefined(obj) || (obj == null) || (obj == ''));
		};

		vm.taskList = [];
		vm.loadTasks=function(){
			if (!vm.isMissing(vm.task.client) &&
			!vm.isMissing(vm.task.channel)) {
				var filterParams = {
					client: {
						value: vm.task.client.client_key
					},
					jurisdiction : {
						value : 1
					},
					domain: {
						value: 9
					},
					channel: {
						value: vm.task.channel.channel_key
					}
				}
				if (vm.task.client && vm.task.client.client_key !== undefined && vm.task.client.client_key !== null)
					JdcManagerFactory.getTaskForJCD(filterParams,true).then(function (data) {
						vm.taskList = data;
						vm.task.parentTask = rowData.parent_task_key
						console.log("task list hierarchy",vm.taskList);
					}, function () {
						vm.taskList = [];
						if(vm.taskList.length === 0){
							AlertService.add("warning","you dont have any Key, please create the Tasks related client",4000);
						}
					});
			} else {
				vm.taskList = [];
			}
		}

		vm.SERVER_CONFIG = SERVER_CONFIG;
		$scope.upload_url =  GENERAL_CONFIG.base_url + '/uploadCommentAttachements';
		var URLS = {
			  saveAttachemnt: GENERAL_CONFIG.base_url + '/saveAttachmentDocuments'
		}
		vm.MessageFactory = MessageFactory;
		_.each(_.keys(FileAttachementFactory.fileUploadConfig), function (item) {
			  if (!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
					vm[item] = _.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
			  else vm[item] = FileAttachementFactory.fileUploadConfig[item];
		});

		//saveTask
		vm.save = function () {
			var validateErr=[];
			if (vm.crudLoading) {
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			  }

			vm.task.parentTask = vm.task.parentTask?vm.task.parentTask:'';

		   vm.crudLoading = true;

			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveTaskFrm', 'task', vm.requiredFields)) {
			///	AlertService.add("Please add the Requied Filelds")
				vm.crudLoading = false;
				return;
			}
			if(_.filter(vm.taskList,{task_name:vm.task.task_name}).length && vm.mode!=='edit'){
				validateErr.push("Task Already exists");
			}
			if(validateErr.length){
				for(var i=0;i<validateErr.length;i++){
					AlertService.add("error",validateErr[i],4000);
					vm.crudLoading = false;
				}
				return;
			}
			JdcManagerFactory.saveTask($scope, vm, 'task', vm.mode, function () {
				//$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
				AlertService.add("success", "New task " + vm.task.task_name + " added successfully", 4000);
				$rootScope.$broadcast('task-activity-update', {onLoad: false});
				vm.crudLoading = false;
				vm.cancel();
			}, rowData);
		}
	}

	function saveScenarioController($rootScope, $scope, $state, $log, JsonObjectFactory, AlertService, $timeout, $uibModalInstance, rowData, JdcManagerFactory, JCDFactory,GENERAL_CONFIG,USER_SETTINGS) {

		var vm = this;
		vm.crudLoading = false;
		vm.user_settings = USER_SETTINGS;
		$scope.date = new Date();
		vm.mode = (angular.isDefined(rowData.scenario) && (rowData.scenario != null) && (rowData.scenario != '')) ?
			(function () {
				vm.scenario = rowData.scenario;
				return 'edit'
			}()) :
			'new';

		vm.title = _.capitalize(vm.mode) + ' Scenario';

		vm.scenarios = {
			jcd_key: '',
			tax_year: '',
			scenario: '',
			scenario_desc: '',
			calendar_year: '',
			calendar_month: '',
			reporting_y_n: '',
			filing_y_n: '',
			is_visible : '',
			client: '',
			jurisdiction: '',
			domain: '',
			channel: '',
			default_scenario: '',
			loading: false,
			scenarioLoading: false,
			is_locked:''
		};
		vm.taxyearList = [];

		vm.masterCopy = angular.copy(vm.scenario);

		vm.reportingList = [{
			name: 'Yes',
			value: 'Y'
		}, {
			name: 'No',
			value: 'N'
		}];
		vm.filingList = [{
			name: 'Yes',
			value: 'Y'
		}, {
			name: 'No',
			value: 'N'
		}];
		vm.defaultScenarioList = [{
			name: 'Yes',
			value: 'Y'
		}, {
			name: 'No',
			value: 'N'
		}];
		vm.isVisibleList = [{
			name : 'Yes',
			value : 'Y'
		},{
			name : 'No',
			value : 'N'
		}]


		if (_.lowerCase(vm.mode) == _.lowerCase('edit')) {
			vm.scenarios.scenario = parseInt(rowData.scenario);
			vm.scenarios.scenario_desc = rowData.scenario_desc;
			vm.scenarios.calendar_year = parseInt(rowData.calendar_year);
			vm.scenarios.calendar_month = parseInt(rowData.calendar_month);
			vm.scenarios.is_locked = rowData.is_locked;
			vm.scenarios.reporting_y_n = _.find(vm.reportingList, function (obj) {
				return (obj.value == rowData.reporting_y_n);
			});
			vm.scenarios.filing_y_n = _.find(vm.filingList, function (obj) {
				return (obj.value == rowData.filing_y_n);
			});
			vm.scenarios.default_scenario = _.find(vm.defaultScenarioList, function (obj) {
				return (obj.value == rowData.default_scenario);
			});
			vm.scenarios.is_visible = _.find(vm.isVisibleList,function(obj){
				return(obj.value == rowData.is_visible);
			})

			vm.scenarios.jcd_key = rowData.jcd_key;
		};

		vm.getInitInfo = function () {
			vm.scenarios.loading = true;
			vm.scenarios.client = vm.user_settings.user.client;
			// get the clientLIst initial load itself.
			if (vm.scenarios.client && vm.scenarios.client.client_key != null)
				JdcManagerFactory.getInitInfo({
					client: {
						value: vm.scenarios.client.client_key
					}
				}).then(function (data) {
					vm.channelList = data.data.jcdManage.channelList;
					if(vm.channelList.length === 0){
						AlertService.add("warning","you dont have App Key, please create the Channel & JCD or else create the JCD for related client",4000);
					}
					else if (angular.isDefined(rowData.channel) &&(rowData.channel != null) && (rowData.channel != '')) {
						vm.scenarios.channel = _.find(vm.channelList, function (obj) {
							return (obj.channel_key == rowData.channel.channel_key);
						});
					}
					vm.getTaxYearList();
				}, function () {
					AlertService.add("error", "Problem getting Initialization info.",4000);
					vm.scenarios.loading = false;
				});
		};
		vm.isMissing = function (obj) {
			return (angular.isUndefined(obj) || (obj == null) || (obj == ''));
		};

		// get the task LIst based on the JCD combination.
		vm.getTaxYearList = function () {
			if (!vm.isMissing(vm.scenarios.client) &&
				!vm.isMissing(vm.scenarios.channel)) {
				vm.scenarios.scenarioLoading = true;
				var filterParams = {
					client: {
						value: vm.scenarios.client.client_key
					},
					jurisdiction :{
						value : 1
					},
					domain : {
						value : 9
					},
					channel: {
						value: vm.scenarios.channel.channel_key
					}
				}

				if (vm.scenarios.client && vm.scenarios.client.client_key!=null)
					JdcManagerFactory.getTaxYearForJCD(filterParams).then(function (data) {
						vm.taxyearList = data;
						console.log("taxYear List",vm.taxYearList);
						if (_.lowerCase(vm.mode) == _.lowerCase('edit')) {
							vm.scenarios.tax_year = _.find(vm.taxyearList,function(obj){
								return(obj.TAX_YEAR == rowData.tax_year);
							});
						}
						vm.scenarios.scenarioLoading = false;
						vm.scenarios.loading = false;
					}, function () {
						vm.taxyearList = [];
						vm.scenarios.scenarioLoading = false;
						vm.scenarios.loading = false;
						AlertService.add("error", "Problem getting tax year.",4000);
					});
			} else {
				vm.taxyearList = [];
				vm.scenarios.loading = false;
			}
		}
		//get All CLients
		vm.getClients = function () {
			vm.clientList = JdcManagerFactory.clientList;
			if (angular.isDefined(rowData.client) &&
				(rowData.client != null) &&
				(rowData.client != '')) {
				vm.scenarios.client = _.find(vm.clientList, function (obj) {
					return (obj.client_key == rowData.client.client_key);
				});
				vm.getInitInfo();
			}
		}
		vm.getClients();

		vm.clearForm = function () {
			var jcdKey = vm.scenarios.jcd_key;
			vm.scenarios = angular.copy(vm.masterCopy);
			vm.scenarios.jcd_key = jcdKey;
			$scope.saveScenarioFrm.$setPristine();
		};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
			$rootScope.$broadcast('gtw.admin.jdc.scenarioModalClose');
		};

		// required fields for Scenario Screen
		vm.requiredFields = {
			//client: false,
			// jurisdiction: false,
			// domain: false,
			channel: false,
			tax_year: false,
			scenario:false,
			scenario_desc: false,
			is_visible : false,
			default_scenario:false,
			//is_locked: false,

		};
		setTimeout(function () {
			var fieldNames = _.keys(vm.requiredFields); //Creates a Minified list containing all property names of the specified object()
			for (var i = 0; i < fieldNames.length; i++) {
				//call the cretewatchFUnction of scenrio screen
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.scenarios.', fieldNames[i], 'requiredFields');
			}
		});

		vm.save = function () {
			var filterParams = {
				client: {
					name: vm.scenarios.client.client_name,
					value: vm.scenarios.client.client_key
				},
				jurisdiction:{
					name:'US',
					value:1
				},
				domain:{
					name:'Direct',
					value:9
				},
				channel: {
					name: vm.scenarios.channel.channel_name,
					value: vm.scenarios.channel.channel_key
				}
			};

			// validate the rquired fields are filled-out or not
			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveScenarioFrm', 'scenarios', vm.requiredFields)) {
				return;
			}

			// validate the JCD combination
			JdcManagerFactory.getJCDKey(filterParams).then(function (response) {

				if (!response.data.jcdKey || response.data.jcdKey == 0) {
					AlertService.add("error", "Please select valid JCD combination !",4000);
					return;
				}

				// create and update screnario screen
				var payLoadForSaving = [{
					TAX_YEAR: vm.scenarios.tax_year.TAX_YEAR ? vm.scenarios.tax_year.TAX_YEAR : vm.scenarios.tax_year.tax_year ,
					SCENARIO : vm.scenarios.scenario,
					SCENARIO_DESC: vm.scenarios.scenario_desc,
					JCD_KEY: response.data.jcdKey,
					CALENDAR_YEAR: vm.scenarios.calendar_year,
					CALENDAR_MONTH: vm.scenarios.calendar_month,
					REPORTING_Y_N: vm.scenarios.reporting_y_n.value,
					FILING_Y_N: vm.scenarios.filing_y_n.value,
					DEFAULT_SCENARIO: vm.scenarios.default_scenario.value,
					IS_VISIBLE : vm.scenarios.is_visible.value,
					IS_LOCKED: vm.scenarios.is_locked
				}];

				var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=most83";

				if (vm.mode == 'edit') { //edit action code 4dk04a--- for update, here were are passing "scenario"
				//	payLoadForSaving[0]["SCENARIO"] = rowData.scenario;
					payLoadForSaving[0]["OLD_SCENARIO_KEY"] = rowData.scenario;
					payLoadForSaving[0]["OLD_TAX_YEAR"] = rowData.TAX_YEAR;

					url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9khx7b";
				}

				var jsonObj = JSON.stringify(payLoadForSaving);
				var jsonSettings = '{}';
				var params = _.extend({
					jsonObj: jsonObj
				}, params);
				params = _.extend({
					jsonSettings: jsonSettings
				}, params);


				if (vm.crudLoading) {
					AlertService.add("info", "Please wait while we save this request", 4000);
					return;
				}

				vm.crudLoading = true;

				if( ((vm.scenarios.scenario != Number(rowData.scenario) && vm.scenarios.tax_year.TAX_YEAR != Number(rowData.TAX_YEAR)) || vm.mode != 'edit') &&  (_.find(rowData.scenarioList.scenarios,{
						scenario:vm.scenarios.scenario.toString(),
						tax_year:vm.scenarios.tax_year.TAX_YEAR.toString()} )))
					{
						AlertService.add("error", "Scenario & TaxYear Combination already exist!",4000);
						vm.crudLoading = false;
						return;
					}

				JsonObjectFactory.saveJSON(url, params).then(function (data) {
					if (data.callSuccess === "1") {
						vm.crudLoading = false;
						AlertService.add("success", "scenario " + vm.scenarios.scenario + " " + (vm.mode == 'edit' ? "edited" : "saved") + " successfully.",4000);
						$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
						vm.cancel();
					} else {
						AlertService.add("error", " Problem saving scenario:",4000);
					 vm.crudLoading = false;
					}
				});
			});
		}
	}

	function saveActivityController($rootScope, $scope, $filter, $state, $log, JsonObjectFactory, AlertService, $timeout, $uibModal, $uibModalInstance, rowData, JdcManagerFactory, JCDFactory,GENERAL_CONFIG,USER_SETTINGS, WorkflowTCFactory, AdminFactory, TemplateAssignFactory, gridData) {

		var vm = this;
		vm.user_settings = USER_SETTINGS;
		var user = vm.user_settings.user;
		vm.mode = (angular.isDefined(rowData.activity_key) && (rowData.activity_key != null) && (rowData.activity_key != '')) ?
			(function () {
				vm.activity_key = rowData.activity_key;
				return 'edit'
			}()) :
			'new';

		vm.title = _.capitalize(vm.mode) + ' Activity';
        vm.taxTypeDetails = [];
		// activity screen fileds
		vm.activity = {
			newRoleName: '',
			newRoleDesc: '',
			newRoleAccessLevel: '',
			roles: [],
			taxTypes: [],
			start_date: '',
			end_date: '',
			addRoleClicked: false,
			saveRoleClicked: false,
			maxAllowedRoles: 20, // Limit the number of roles allowed
			showAddMoreRoles: true,
			jcdTaRoleKeysToBeDeleted: '',
			client: vm.user_settings.user.client,
			jurisdiction: '',
			domain: '',
			channel: '',
			task: '',
			loading: false,
			taskLoading: false
		};
		vm.crudLoading = false;
		//task fields
		vm.task = {
			task_key: '',
			task_name: '',
			task_desc: '',
			task_priority: '',
			task_status: '',
			loading: false,
			taskLoading: false,
			parentTask:''
		};
		vm.addedTags = [];
		vm.searchTagText = "";
		//Task Types and Date
		vm.taxTypes = [];
		vm.notifType = 1;

		vm.dateOptions = {
	            dateDisabled: false,
	            formatYear: 'yyyy',
	            startingDay: 1,
	            showWeeks: false
	        };
		vm.startDate = rowData.start_date;
		vm.endDate = rowData.end_date;

		console.log("start date pulled",vm.startDate );
		console.log("end date pulled",vm.endDate );

		if (!vm.startDate) {
			vm.startDate = new Date();
		}

		if (!vm.endDate) {
			vm.endDate = new Date("12/31/9999");
		}

		$scope.today = function() {
		    $scope.dt = new Date();
		  };
		$scope.today();

		$scope.startdt = {
		    opened: false
		};

		$scope.enddt = {
			opened: false
		};

		$scope.startdtopen = function() {
		    $scope.startdt.opened = true;
		  };

		$scope.enddtopen = function() {
		    $scope.enddt.opened = true;
		};

		vm.masterCopy = angular.copy(vm.activity);

		vm.statusList = [{
			name: 'Active',
			value: 'A'
		}, {
			name: 'Inactive',
			value: 'I'
		}];

		vm.loading = false;
		vm.roles = [];
		vm.screen_data = {};
		vm.screen_data.priority = 1;

		vm.jcdlist = {};
		vm.lockLevel = [{
			name: "Active",
			value: "A"
		}, {
			name: "Locked",
			value: "L"
		}, {
			name: "Maintenance",
			value: "M"
		}, {
			name: "Removed",
			value: "R"
		}];
		vm.editLevel = [{
			name: "Open All",
			value: "O"
		}];
		// vm.scenarioTypeCode = [];
		// vm.selectedScenarioCodes = [];
		
		// vm.getScenarioTypeCodes = function() {
		// 	JdcManagerFactory.getScenarioTypeCodes().then(function(response) {
		// 		vm.scenarioTypeCode = response.data;
		// 	});
		// };

		// vm.multiselDropdownSettings = {
        //     buttonClasses: 'mul-select-btn',
        //     showCheckAll: true,
        //     showUncheckAll: true,
        //     checkBoxes: true,
        //     displayProp: 'scenario_type_desc',
        //     smartButtonMaxItems: 1,
        //     scrollable: true,
        //     scrollableHeight: 'auto',
        // };

		// vm.mulSelectCustomTexts = {
        //     buttonDefaultText: 'Select scenario type',
        //     checkAll: 'Select all',
        //     uncheckAll: 'Unselect all'
        // };

		// vm.multiselectEvents = {

		// 	onItemSelect: function (item) {
        //         vm.selectedScenarioCodes.indexOf(item) === -1 ? 
		// 		vm.selectedScenarioCodes.push(item) : vm.selectedScenarioCodes;
        //     },
        //     onItemDeselect: function (item) {
        //         vm.selectedScenarioCodes.indexOf(item) !== -1 ? 
		// 		vm.selectedScenarioCodes.splice(vm.selectedScenarioCodes.indexOf(item), 1) : vm.selectedScenarioCodes;
        //     },
		// 	onSelectAll: function(){
		// 		vm.selectedScenarioCodes = [];
		// 		vm.scenarioTypeCode.forEach(i => vm.selectedScenarioCodes.push(i));
		// 	},
		// 	onDeselectAll: function(){
		// 		vm.selectedScenarioCodes = [];
		// 	}

        // };

		if (_.lowerCase(vm.mode) == _.lowerCase('edit')) {
			vm.screen_data = {
				screen_name: rowData.activity_name,
				priority: parseFloat(rowData.activity_priority, 10),
				activity_key: rowData.activity_key,
				edit_level: vm.editLevel[0].value,
				lock_level: rowData.activity_lock_level
			};

			if (rowData.roles.length > 0) {
				angular.forEach(rowData.roles, function (role) {
					vm.roles.push({
						jcd_ta_role_key: role.jcd_ta_role_key,
						role_name: role.role_name,
						role_desc: role.role_desc,
						access_level: parseFloat(role.access_level, 10)
					});
				});
			}
			vm.activity.task = (rowData.activity_task);
		}

		vm.getAllTemplates = function (clientKey){
            vm.showTemplateLoader = true;
            vm.loading = true;
            TemplateAssignFactory.getAllTemplates(clientKey).then(function (data) {
				vm.showTemplateLoader = false;
				if(data.data.callSuccess === "1" ){
					console.log(data.data.jsonObject);
					vm.allTemplates = data.data.jsonObject;
					//vm.templatesGroup = _.groupBy(vm.allTemplates, 'TEMPLATE_GROUP_KEY');
				}else {
					if(data.data.errorMessage === "no access"){
						AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
					}else{
						AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
					}
				}
			});
        };

        vm.getAllTemplates(user.client.client_key);

		// get the task List based on the JCD combination.
		vm.getTaskList = function () {
			if (!vm.isMissing(vm.activity.client) &&
				!vm.isMissing(vm.activity.channel)) {
				vm.activity.taskLoading = true;
				var filterParams = {
					client: {
						value: vm.activity.client.client_key
					},
					jurisdiction : {
						value : 1
					},
					domain: {
						value: 9
					},
					channel: {
						value: vm.activity.channel.channel_key
					}
				}

			if (vm.activity.client && vm.activity.client.client_key !== undefined && vm.activity.client.client_key !== null)
				JdcManagerFactory.getTaskForJCD(filterParams,true).then(function (data) {
						vm.taskList = data;
						//vm.activity.taskLoading = false;
					}, function () {
						vm.taskList = [];
						//vm.activity.taskLoading = false;
						if(vm.taskList.length === 0){
							AlertService.add("warning","you dont have any Key, please create the Tasks related client",4000);
						}
					});
			} else {
				vm.taskList = [];
			}
		}

		// get Master Task type
		vm.getTaxTypeData = function () {
            console.log("Inside getTaxTypeData------------: ");
            JdcManagerFactory.getTaxTypeDetails().then(function (data) {
				   vm.taxTypeDetails =  data.data.data;
				   console.log("taxtype data",vm.taxTypeDetails );
                vm.populateTaxTypeValues();
			   }, function () {
				   if(vm.taxTypeDetails.length === 0){
					   AlertService.add("warning","you dont have any Tax Types listed, please create the Task Types for related client",4000);
				   }
			});
		}

		//Prepare and check the saved Tax Types
        vm.taxTypeDetails2 = [];
		vm.populateTaxTypeValues = function() {
            angular.forEach(vm.taxTypeDetails, function (item, index) {
                angular.forEach(rowData.taxtypeList, function (item2, index2) {
                    if (item.id === item2.id) {
                        vm.taxTypeDetails2.push({
                            id: item.id,
                            label:  item.label,
                            selected:   true,
                        });
                        vm.taxTypes.push({
                            id: item.id,
                            label:  item.label,
                            selected:   true,
                        });
                    }
                });

                if(vm.taxTypeDetails2[index]){

                } else {
                    vm.taxTypeDetails2.push({
                        id: item.id,
                        label:  item.label,
                        selected:   false,
                    });
                }
            });
		}

		vm.getSelectedTaxTypes = function (id, label) {
			var typeIndex;

			for(var i = 0; i < vm.taxTypes.length; i++) {
		        if(vm.taxTypes[i].id === id) {
		        	typeIndex = i;
		        }
		    }

			//update the final list of tax types
			if (typeIndex > -1) {
				vm.taxTypes.splice(typeIndex, 1);
				if (vm.taxTypes.length == 0) {
					vm.taxtypeRequiredFields.taxtype = true;
				}
			} else {
				 vm.taxTypes.push({id, label});
				 vm.taxtypeRequiredFields.taxtype = false;
			}
			console.log("final list passed after this", vm.taxTypes);
		}

		vm.isMissing = function (obj) {
			return (angular.isUndefined(obj) || (obj === null) || (obj === ''));
		};

		vm.getInitInfo = function (callBackMethod) {
			vm.activity.loading = true;
			vm.activity.client = vm.user_settings.user.client;
			if (vm.activity.client && vm.activity.client.client_key !== undefined && vm.activity.client.client_key !== null)
				JdcManagerFactory.getInitInfo({
					client: {
						value: vm.activity.client.client_key
					}
				}).then(function (data) {
					vm.channelList = data.data.jcdManage.channelList;
					if(vm.channelList.length === 0){
						AlertService.add("warning","you dont have App Key, please create the Channel & JCD or else create the JCD for related client",4000);
					}

					else if (angular.isDefined(rowData.channel) &&
						(rowData.channel != null) &&
						(rowData.channel != '')) {
						vm.activity.channel = _.find(vm.channelList, function (obj) {
							return (obj.channel_key == rowData.channel.channel_key);
						});
					}

					// Fetch the task list based on Client, Jurisdiction, Domain, Channel
					if (!vm.isMissing(rowData.client.client_key) &&
						!vm.isMissing(rowData.channel.channel_key)
					) {

						var filterParams = {
							client: {
								name: vm.activity.client.client_name,
								value: vm.activity.client.client_key
							},
							jurisdiction: {
								name: 'US',
								value: 1
							},
							domain: {
								name: 'Direct',
								value: 9
							},
							channel: {
								name: vm.activity.channel.channel_name,
								value: vm.activity.channel.channel_key
							}
						};

						if(filterParams.channel.channel_name === undefined && filterParams.channel.channel_name ===null){
							AlertService.add("warning","no channel found so no tasks found",4000)
						}
                        vm.getTaxTypeData();
						vm.getTaskList();
						// JdcManagerFactory.getScenarioTypeCodes().then(function(response) {
						// 	vm.scenarioTypeCode = response.data;
						// 	vm.selectedScenarioCodes = [];
						// 	if(rowData.scenarioTypeCodes && rowData.scenarioTypeCodes.length > 0) {
						// 		vm.scenarioTypeCode.forEach(i => {
						// 			if(rowData.scenarioTypeCodes.indexOf(i.scenario_type_code) > -1){
						// 				vm.selectedScenarioCodes.push(i);
						// 			}
						// 		});
						// 	} 
						// });
						JdcManagerFactory.getTaskForJCD(filterParams,true).then(function (data) {
							vm.taskList = data;
							if (!rowData.roles || rowData.roles.length === 0)
								if (callBackMethod) callBackMethod();
							vm.activity.loading = false;
						}, function () {
							vm.activity.loading = false;
							AlertService.add("error", "Problem getting Task.",4000);
						});
					} else {
						vm.activity.loading = false;
						if (!rowData.roles || rowData.roles.length === 0)
							if (callBackMethod) callBackMethod();
					}
				}, function () {
					AlertService.add("error", "Problem getting Initialization info.",4000);
					vm.activity.loading = false;
				});
		};

		vm.getClients = function () {
			vm.clientList = JdcManagerFactory.clientList;
			if (angular.isDefined(rowData.client) &&
				(rowData.client != null) &&
				(rowData.client != '')) {
				vm.activity.client = _.find(vm.clientList, function (obj) {
					return (obj.client_key == rowData.client.client_key);
				});
				vm.getInitInfo(function () {
					vm.addRole();
				});
			} else {
				setTimeout(function () {
					vm.addRole();
				}, 100);
			}
		}
		vm.getClients();

		vm.checkIfEnter = function ($event) {
			// if "enter" key is pressed, then add the role
			if ($event.keyCode == 13) {
				vm.addRole();
			}
		}

		vm.clearForm = function () {
			var jcdTaRoleKeysToBeDeleted = '';
			if (angular.isDefined(vm.activity.jcd_ta_role_key) &&
				(vm.activity.jcd_ta_role_key != null) &&
				(vm.activity.jcd_ta_role_key != '')
			) {
				jcdTaRoleKeysToBeDeleted += "," + vm.activity.jcd_ta_role_key;
			}

			angular.forEach(vm.roles, function (role, index) {
				if (angular.isDefined(vm.roles[index].jcd_ta_role_key) &&
					(vm.roles[index].jcd_ta_role_key != null) &&
					(vm.roles[index].jcd_ta_role_key != '')
				) {
					jcdTaRoleKeysToBeDeleted += "," + vm.roles[index].jcd_ta_role_key;
				}
			});

			angular.forEach(vm.taxTypeDetails2, function (item, index) {
				vm.taxTypeDetails2[index].selected = false;
            });
			var activityClient = vm.activity.client;
			vm.activity = angular.copy(vm.masterCopy);
			vm.activity.channel = vm.channelList.find(function(c) {
				return c.channel_name === rowData.channel.channel_name;
			});
			vm.activity.client = activityClient;
			vm.activity.jcdTaRoleKeysToBeDeleted = jcdTaRoleKeysToBeDeleted;
			vm.screen_data = {};
			vm.roles = [];
			vm.taxTypes = [];
			vm.startDate = null;
			vm.endDate = null;
			$scope.modelDate = null;
			$scope.saveActivityFrm.$setPristine();
			vm.getTaskList();
		};

		var clearNewRoleFields = function () {
			vm.activity.jcd_ta_role_key = '';
			vm.activity.newRoleName = '';
			vm.activity.newRoleDesc = '';
			vm.activity.newRoleAccessLevel = '';
			angular.element('input[name="role_name"]').focus();
		}

		vm.addRole = function () {

			// Limit the number of roles allowed
			if (vm.roles.length == parseInt(vm.activity.maxAllowedRoles, 10)) {
				vm.activity.showAddMoreRoles = false;
				return;
			}

			vm.activity.addRoleClicked = true;
			vm.activity.saveRoleClicked = false;
			setPristineForSave();
			var jcdTaRoleKey = '';
			if (angular.isDefined(vm.activity.jcd_ta_role_key) &&
				(vm.activity.jcd_ta_role_key != null) &&
				(vm.activity.jcd_ta_role_key != '')
			) {
				jcdTaRoleKey = vm.activity.jcd_ta_role_key;
			}
			vm.roles.unshift({
				jcd_ta_role_key: jcdTaRoleKey,
				role_name: vm.activity.newRoleName,
				role_desc: vm.activity.newRoleDesc,
				access_level: vm.activity.newRoleAccessLevel
			});
			clearNewRoleFields();
		}

		vm.deleteRole = function (index) {
			if (index === undefined) {
				if (angular.isDefined(vm.activity.jcd_ta_role_key) &&
					(vm.activity.jcd_ta_role_key != null) &&
					(vm.activity.jcd_ta_role_key != '')
				) {
					vm.activity.jcdTaRoleKeysToBeDeleted += "," + vm.activity.jcd_ta_role_key;
				}
				clearNewRoleFields();
				return;
			}
			if (angular.isDefined(vm.roles[index].jcd_ta_role_key) &&
				(vm.roles[index].jcd_ta_role_key != null) &&
				(vm.roles[index].jcd_ta_role_key != '')
			) {
				vm.activity.jcdTaRoleKeysToBeDeleted += "," + vm.roles[index].jcd_ta_role_key;
			}

			vm.roles.splice(index, 1);
			vm.activity.showAddMoreRoles = true;
		}

		vm.removeActivity = function (activity_key) {
			JdcManagerFactory.deleteActivity(activity_key).then(function (data) {
				if (parseInt(data.data.result.value) == 1) {
					AlertService.add("success", "Activity removed successfully.",4000);
					//$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
					$rootScope.$broadcast('task-activity-update', {onLoad: false});
					vm.cancel();
				} else {
					AlertService.add("error", data.data.result.message);
				}
			}, function () {
				AlertService.add("error", "Problem removing activity.",4000);
			});
		};

		vm.isRoleNameMissing = function (index) {
			return $scope.saveActivityFrm['role_name_' + index].$dirty && vm.isMissing(vm.roles[index].role_name);
		};

		vm.isRoleDescMissing = function (index) {
			return $scope.saveActivityFrm['role_desc_' + index].$dirty && vm.isMissing(vm.roles[index].role_desc);
		};

		vm.isRoleAccessLevelMissing = function (index) {
			return $scope.saveActivityFrm['role_access_level_' + index].$dirty && vm.isMissing(vm.roles[index].access_level);
		};

		vm.isMissing = function (obj) {
			return (angular.isUndefined(obj) || (obj == null) || (obj === ''));
		};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
			$rootScope.$broadcast('gtw.admin.jdc.activityModalClose');
		};

		var setPristineForSave = function () {
			if (!$scope.saveActivityFrm['client']) {
				return;
			}
			angular.forEach(['client', 'channel', 'task', 'screen_name', 'edit_level','lock_level', 'priority',
			], function (frmElement) {
				$scope.saveActivityFrm[frmElement].$dirty = false;
			});

			angular.forEach(vm.roles, function (frmElement, index) {
				$scope.saveActivityFrm['role_name_' + index].$dirty = false;
				$scope.saveActivityFrm['role_desc_' + index].$dirty = false;
				$scope.saveActivityFrm['role_access_level_' + index].$dirty = false;
			});
		}

		// task related Mandatory fields
		vm.taskRequiredFields = {
			task_name: false,
			task_priority: false,
			task_status: false
		};

		// activity related Mandatory fields
		vm.requiredFields = {
			//client: false,
			channel: false,
		    task: false
		};

		// activity related Mandatory fields
		vm.screenDataReqFields = {
			screen_name: false,
			edit_level: false,
			lock_level: false,
			priority: false
		};

		//tax type related Mandatory fields
		vm.taxtypeRequiredFields = {
				start_date: false,
				end_date: false,
				taxtype: false
		};

		//vm.roleReqFileds={role_name:false,role_desc:false,role_access_level:false};

		setTimeout(function () {
			var fieldNames = _.keys(vm.requiredFields);
			var tfieldNames = _.keys(vm.taskRequiredFields);
			var sfieldNames = _.keys(vm.screenDataReqFields);
			var rfieldNames = _.keys(vm.roleReqFileds);
			//var ttfieldNames = _.keys(vm.taxtypeRequiredFields);

			for (var i = 0; i < fieldNames.length; i++)
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.activity.', fieldNames[i], 'requiredFields');
			for (var i = 0; i < tfieldNames.length; i++)
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.task.', tfieldNames[i], 'taskRequiredFields');
			for (var i = 0; i < sfieldNames.length; i++)
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.activity.', sfieldNames[i], 'screenDataReqFields');
		});

		//create new task if tasklist doesn't have realted task, "saveTask for activity screen"
		$scope.saveButtonText = "Save";
		//vm.crudLoading = true;
		vm.saveTask = function () {
			var reqFileds = {
				client: false,
				channel: false
			};

			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveActivityFrm', 'activity', reqFileds)) {
				_.each(reqFileds, function (v, k) {
					vm.requiredFields[k] = v;
				})
			}
			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveActivityFrm', 'task', vm.taskRequiredFields) ||
				JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveActivityFrm', 'activity', reqFileds)) {
				return;
			}
			JdcManagerFactory.saveTask($scope, vm, 'activity', 'add', vm.getTaskList, rowData);
			if (vm.crudLoading) {
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			  }
			  vm.crudLoading = false;
		};

		vm.isShowNewTask = false;
		// createtask toggle plus button
		vm.toggleAddNewTaskSection = function () {
			vm.isShowNewTask = !vm.isShowNewTask;
		}

		// Method to add searched tags..
		vm.addTags = function(inputTagText) {
			vm.addedTags.push({"name": inputTagText});
			vm.searchTagText = "";
		}

		vm.deleteTags = function(index) {
            vm.addedTags.splice(index, 1);
        }

		// vm.assignTemplate = function () {
		// 	var data = {};
		// 	data.rowData = {
		// 		client: vm.jdcInfo.selectedClient
		// 	};
		// 	var modalObj = {};
		// 	modalObj.template = "app/components/admin/jdcManager/jdc-save-activity-modal.html";
		// 	modalObj.controler = "SaveActivityController as ctrl";
		// 	modalObj.size = "md";
		// 	modalObj.backdrop = "static";
		// 	vm.openModal(modalObj, data);
		// };
		vm.assignTemplate = function (){
			var data = {};
			data.templatesData =  vm.allTemplates;
			data.roles = gridData.rowData.roles;
			data.activity = gridData.rowData;
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdc-assign-templates-modal.html";
			modalObj.controler = "activityTemplateAssignController as ctrl";
			modalObj.size = "md";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, data);
		}
		
		vm.openModal = function (modalObj, _data) {
			//vm.allTemplates = _data.templatesData;
		
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: modalObj.template,
				controller: modalObj.controler,
				size: modalObj.size,
				backdrop: modalObj.backdrop,
				resolve: {
					modalData: function () {
						return _data;
					}
				}
			});
		
			modalInstance.result.then(function (selectedItem) {
				$rootScope.$broadcast('gtw.admin.jdc.activityModalClose');
			}, function () {
				$rootScope.$broadcast('gtw.admin.jdc.activityModalClose');
			});
		};

		vm.save = function (new_activity) {
			var activity = {};

			var filterParams = {
				client: {
					name: vm.activity.client.client_name,
					value: vm.activity.client.client_key
				},
				jurisdiction:{
					name:'US',
					value:1
				},
				domain:{
					name:'Direct',
					value:9
				},
				channel: {
					name: vm.activity.channel.channel_name,
					value: vm.activity.channel.channel_key
				}
			};

			if(vm.roles.length==0){
				AlertService.add("warning","Please enter atleast one role to save",4000);
				return;
			}

			activity.start_date = $filter('date')(vm.startDate,'MM/dd/yyyy') ;
			activity.end_date = $filter('date')(vm.endDate,'MM/dd/yyyy') ;

			// Mandatory fields for Start and End Date
			if(vm.startDate == null || vm.startDate == ''){
				vm.taxtypeRequiredFields.start_date = true;
				return;
			} else {
				vm.taxtypeRequiredFields.start_date = false;
			}

			if(vm.endDate == null || vm.endDate == ''){
				vm.taxtypeRequiredFields.end_date = true;
				return;
			} else {
				vm.taxtypeRequiredFields.end_date = false;
			}
			vm.isValidDate = function(dateObject){
				return new Date(dateObject) == 'Invalid Date';
			};
			if((vm.startDate !== 'Invalid Date' && vm.isValidDate(new Date(vm.startDate))) || (vm.endDate !== 'Invalid Date' && vm.isValidDate(new Date(vm.endDate)))){
        		AlertService.add("error", "Invalid Date", 4000);
        		return;
        	};
			if(new Date(vm.startDate) > new Date(vm.endDate)){
        		AlertService.add("error", "End date cannot be before Start date", 4000);
        		return;
        	}

			if(vm.taxTypes.length==0){
				vm.taxtypeRequiredFields.taxtype = true;
				return;
			} else{
				vm.taxtypeRequiredFields.taxtype = false;
			}

			console.log("selected list", vm.taxTypeDetails2);
			console.log("final tax types passed ", vm.taxTypes);

			vm.activity.addRoleClicked = false;
			vm.activity.saveRoleClicked = true;
			var isAnyRoleNameMissing = false;
			var isAnyRoleDescMissing = false;
			var isAnyRoleAccessLevelMissing = false;
			angular.forEach(vm.roles, function (frmElement, index) {
				$scope.saveActivityFrm['role_name_' + index].$dirty = true;
				$scope.saveActivityFrm['role_desc_' + index].$dirty = true;
				$scope.saveActivityFrm['role_access_level_' + index].$dirty = true;

				isAnyRoleNameMissing = isAnyRoleNameMissing | vm.isRoleNameMissing(index);
				isAnyRoleDescMissing = isAnyRoleDescMissing | vm.isRoleDescMissing(index);
				isAnyRoleAccessLevelMissing = isAnyRoleAccessLevelMissing | vm.isRoleAccessLevelMissing(index);
			})

			var isRequiredFieldsMissing =
				JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveActivityFrm', 'activity', vm.requiredFields) ||
				JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveActivityFrm', 'screen_data', vm.screenDataReqFields) ||
				isAnyRoleNameMissing ||isAnyRoleDescMissing ||
				isAnyRoleAccessLevelMissing;
			if (isRequiredFieldsMissing) {
				return;
			}

			/* Activity Validation Whether it present or not */
			var validateErr=[];
			if(_.filter(rowData.activityList,{activity_name:vm.screen_data.screen_name}).length){
				validateErr.push("Activity Already exists");
			}
			if(validateErr.length){
				for(var i=0;i<validateErr.length;i++){
					AlertService.add("error",validateErr[i],4000);
					vm.crudLoading = false;
				}
				return;
			}

			/* Activity validation Ends here */
			JdcManagerFactory.getJCDKey(filterParams).then(function (response) {

				if (!response.data.jcdKey || response.data.jcdKey == 0) {
					AlertService.add("error", "Please select valid JCD combination !",4000);
					return;
				}

			//activity.jcd_key = rowData.jcd_key;
			activity.jcd_key = response.data.jcdKey;
			activity.activity_key = rowData.activity_key;
			activity.client_key = vm.activity.client.client_key;
			activity.activity_jurisdiction = 1;
			activity.activity_domain = 9;
			activity.activity_channel = vm.activity.channel.channel_key;
			activity.activity_task = vm.activity.task;
			activity.activity_name = vm.screen_data.screen_name;
			activity.activity_edit_level = vm.screen_data.edit_level;
			activity.activity_lock_level = vm.screen_data.lock_level;
			activity.activity_priority = vm.screen_data.priority;
			activity.roles = [].concat(vm.roles);
			activity.taxtypeList = [].concat(vm.taxTypes);
			// activity.scenarioTypeCodes = [].concat(vm.selectedScenarioCodes.map(i => i.scenario_type_code));
			console.log("Tax type data saved", activity.taxtypeList);
			console.log("start date selected", activity.start_date);
			console.log("end date selected", activity.end_date);
			if (angular.isDefined(vm.activity.newRoleName) &&
				(vm.activity.newRoleName != null) &&
				(vm.activity.newRoleName != '')
			) {
				var jcdTaRoleKey = '';
				if (angular.isDefined(vm.activity.jcd_ta_role_key) &&
					(vm.activity.jcd_ta_role_key != null) &&
					(vm.activity.jcd_ta_role_key != '')) {
					jcdTaRoleKey = vm.activity.jcd_ta_role_key;
				}
				activity.roles.unshift({
					jcd_ta_role_key: jcdTaRoleKey,
					role_name: vm.activity.newRoleName,
					role_desc: vm.activity.newRoleDesc,
					access_level: vm.activity.newRoleAccessLevel
				});
			}

			if (vm.activity.jcdTaRoleKeysToBeDeleted.startsWith(',')) {
				activity.jcdTaRoleKeysToBeDeleted = vm.activity.jcdTaRoleKeysToBeDeleted.substring(1);
			}

			if (vm.crudLoading) {
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
		  	}
		  	vm.crudLoading = true;
		  	console.log(activity);
			JdcManagerFactory.saveActivity(activity).then(function (data) {
				vm.crudLoading = false;
				if (parseInt(data.data.result.value) > 0) {
					AlertService.add("success", "Activity " + activity.activity_name + " saved successfully.",4000);
					// vm.selectedScenarioCodes = [];
					$rootScope.$broadcast('task-activity-update', {onLoad: false});
					//$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
					//vm.cancel();
				} else {
					AlertService.add("error", "Problem saving activity: " + data.data.result.message);
				}
			}, function () {
				AlertService.add("error", "Problem saving activity.",4000);
			});
		})
		}
	}

	function jdcManagerController($scope, $rootScope, $uibModal, JdcManagerFactory, JsonObjectFactory, AlertService, GENERAL_CONFIG, GlobalService, USER_SETTINGS, WorkflowTCFactory, processMapFactory, $filter, $timeout) {
		var vm = this;
		vm.pageTitle = "Admin - JDC Manager";
		vm.user_settings = USER_SETTINGS;
		vm.jcdTab = 1;
		vm.tab = 0;
		vm.tasksFilter = {
			task_status : 'A'
		}

		vm.trees = {};
		vm.treeOptions = {};
		vm.taskModel;
		vm.jcdKey =
		vm.jcdlist = {};
		
		vm.jdcInfo = {
			jcdKey: null,
			taxYear: {
				showTaxYears: true,
				taxYears: []
			},
			scenario: {
				showScenarios: true,
				scenarios: []
			},
			task: {
				showTasks: true,
				tasks: []
			},
			activity: {
				activities: [],
				isNewActivity: false,
				isDuplicate: false,
				newActivity: {
					activity_key: '',
					activity_name: '',
					activity_priority: ''
				},
				deleteActivity: {
					activity_key: '',
					activity_name: ''
				},
				showActivites: true
			},
			taskActivity: {
				taskActivities: []
			},
			filterResult: {
				client: '',
				jurisdiction: '',
				domain: '',
				channel: ''
			},
			selectedJurisdiction: {jurisdiction_code:'US',jurisdiction_key:1},
			selectedDomain: {domain_name:'Direct',domain_key:9},
			showResult: false,
			editModalLoading: false,
			resultLoading: false,
			loading: false
		};

		vm.masterCopy = angular.copy(vm.jdcInfo);

		vm.clientList = [];
		vm.jurisdictionList = [{jurisdiction_code:'US',jurisdiction_key:1}];
		vm.domainList = [{domain_name:'Direct',domain_key:9}];
		vm.channelList = [];
		vm.selectedTask = {};
		vm.previousTask = 0;
		vm.previousSubTask = null;
		var draggedActivityIndex;
		var subTask = {
			isSubTask : false,
			index : 0,
			parent : 0
		};
		vm.refreshTaskActivity = false;

		vm.requiredTaskFields = {
			task_name: false,
			task_desc: false
		};

		vm.changedAppParent = {
			channel: false,
			parentTask: false,
		};

		vm.disableParent = false;
		vm.saveTaskLoader = false;
		vm.isAppParentChanged = false;
		var initialFilterParams = {};
		vm.collapsed = {};
		var flattened = {};
		vm.nodeToMove = {};
		vm.reordertasks = {};
		vm.resetTaskList = [];
		vm.resetSelectedTask = {};
		var taskFound = false;
		vm.taxyear = {
			channel : "",
			tax_year : "",
			edit_level : "",
			visible : ""
		};

		vm.scenario = {
			tax_year : "",
			scenario : "",
			scenario_desc : "",
			calendar_year : "",
			calendar_month : ""
		}

		setTimeout(function () {
			var fieldNames = _.keys(vm.requiredTaskFields);
			for (var i = 0; i < fieldNames.length; i++)
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.selectedTask.', fieldNames[i], 'requiredTaskFields');
		});

		vm.getInitInfo = function (isonload) {
			vm.jdcInfo.resultLoading = true;
			var filterParams = {
				client: {
					name: vm.jdcInfo.selectedClient.client_name,
					value: vm.jdcInfo.selectedClient.client_key
				}
			};
			JdcManagerFactory.getInitInfo(filterParams).then(function (data) {
				vm.channelList = data.data.jcdManage.channelList;
				vm.taskChannelList = angular.copy(vm.channelList);

				vm.jdcInfo.resultLoading = false;
				if (isonload) {
					vm.jdcInfo.selectedChannel = vm.channelList.find(function(c) {
						return c.channel_name === 'Tax Return';
					});
				}
			}, function () {
				vm.jdcInfo.resultLoading = false;
				$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
				AlertService.add("error", "Problem getting Initialization info.",4000);
			});
		};

		vm.jdcInfo.selectedClient = vm.user_settings.user.client;
		vm.getInitInfo(true);

		var getJCDKey = function (filterParams) {
			vm.jdcInfo.resultLoading = true;
			JdcManagerFactory.getJCDKey(filterParams).then(function (response) {
				vm.jdcInfo.jcdKey = response.data.jcdKey;
			}, function () {
				vm.jdcInfo.resultLoading = false;
				AlertService.add("error", "Problem loading.",4000);
			});
		}

		vm.editlevelList = {
			selected: null,
			list: [{
				name: "User",
				value: 1
			}, {
				name: "Editor",
				value: 2
			}, {
				name: "Admin",
				value: 3
			}, {
				name: "Developer",
				value: 4
			}, {
				name: "Super Admin",
				value: 5
			}]
		};

		vm.visibleList = {
			selected: null,
			list: [{
				name: "User",
				value: 1
			}, {
				name: "Editor",
				value: 2
			}, {
				name: "Admin",
				value: 3
			}, {
				name: "Developer",
				value: 4
			}, {
				name: "Super Admin",
				value: 5
			}]
		};

		vm.isdefaultList ={
			list:[{
				name: 'Yes',
				value: 1
			}, {
				name: 'No',
				value: 0
			}]
		} ;

		//Function to get all the Tasks, Subtasks and their respective Activities on click of load button.
		var loadResultForJDC = function (filterParams) {
			vm.jdcInfo.resultLoading = true;
			JdcManagerFactory.loadResultForJDC(filterParams).then(function (data) {
				vm.jdcInfo.filterResult.client = filterParams.client.name;
				vm.jdcInfo.filterResult.jurisdiction = filterParams.jurisdiction.name;
				vm.jdcInfo.filterResult.domain = filterParams.domain.name;
				vm.jdcInfo.filterResult.channel = filterParams.channel.name;
				vm.jdcInfo.taskActivity.taskActivities = data.data.task_activity ? data.data.task_activity : [];
				vm.jdcInfo.scenario.scenarios = data.data.scenario;
				vm.jdcInfo.scenario.scenariosOriginal = data.data.scenario;
				vm.jdcInfo.taxYear.taxYears = data.data.tax_year;
				vm.jdcInfo.taxYear.taxYearsOriginal =  data.data.tax_year;
				if(vm.jdcInfo.taskActivity.taskActivities.length > 0){
					prepareDataForDisplay(vm.jdcInfo.taskActivity.taskActivities, true);
				}else{
					vm.refreshTaskActivity = false;
				}
				vm.jdcInfo.resultLoading = false;			
			}, function () {
				vm.jdcInfo.resultLoading = false;
				AlertService.add("error", "Problem loading.",4000);
			});
		};

		var getTasksHierarchyByClientKey = function(onLoad, type){
			JdcManagerFactory.getTasksHierarchy(initialFilterParams).then(function (data){
				if(data.task_activity.length>0){
					prepareDataForDisplay(data.task_activity, onLoad, type);
				}else{
					vm.defaultClient = false;
					AlertService.add("warning", "Tasks Hierarchy Data is not available", 4000); 
				}
			});
		};

		var prepareDataForDisplay = function(data, onLoad, type){
			angular.forEach(data, function(task, index){
				vm.modifyTasksActivities(task, undefined);
			});
			vm.original_tasksList = data;
			vm.defaultSelection(data, onLoad, type);
			vm.refreshTaskActivity = false;
		};

		vm.modifyTasksActivities = function(obj, parent_task){
			obj.toggle = false;
			obj.draggable = false;
			obj.blink = false;
			obj.parentTask = parent_task;
			obj.client = vm.user_settings.user.client;
			obj.channel = vm.taskChannelList.find(function(c) {
				return c.channel_name === vm.jdcInfo.selectedChannel.channel_name;
			});
			if(obj.children.length > 0){
				angular.forEach(obj.children, function(obj_child, index){
					if(obj_child.activity_key !== 0){
						obj_child.roles = obj_child.roles ? obj_child.roles.split(',') : [];
						obj_child.taxtypeList = obj_child.taxtypeList ? obj_child.taxtypeList.split(',') : [];
						obj_child.screens = obj_child.screens ? obj_child.screens.split(',') : [];
						obj_child.start_date = $filter('date')(new Date(obj_child.start_date), 'MM/dd/yyyy');
						obj_child.end_date = $filter('date')(new Date(obj_child.end_date), 'MM/dd/yyyy');
						obj_child.client_key = vm.user_settings.user.client.client_key;
						obj_child.roleTemplateUrl = 'rolesTemplate.html';
						obj_child.taxTemplateUrl = 'taxTemplate.html';
						obj_child.screenTemplateUrl = 'screenTemplate.html';
						if(obj_child.screens.length > 0){
							obj_child.deleteMsg = 'In order to delete this activity [' + obj_child.activity_name + '], please make sure to delete all the associated screens in this activity first';
							obj_child.deleteCallBack = function(){
								angular.noop();
							};
						}else{
							obj_child.deleteMsg = 'Are you sure you want to remove this activity [' + obj_child.activity_name + '] ?';
							obj_child.deleteCallBack = function(){
								vm.removeActivity(obj_child.activity_key, obj_child.activity_name, null, obj_child.client_key)
							};
						}
					}else{
						obj.children.splice(index, 1);
					}
				});
				if(obj.children.length > 0){
					obj.deleteMsg = 'In order to delete this task [' + obj.task_name + '], please make sure to delete all the associated activities in this task first';
					obj.deleteCallBack = function(){
						angular.noop();
					};
				}else{
					obj.deleteMsg = 'Are you sure you want to remove this Task [' + obj.task_name + '] ?';
					obj.deleteCallBack = function(){
						vm.removeTask(obj.task_key);
					};
				}
			}else{
				obj.deleteMsg = 'Are you sure you want to remove this Task [' + obj.task_name + '] ?';
				obj.deleteCallBack = function(){
					vm.removeTask(obj.task_key);
				};
			}
			angular.forEach(obj.sub_task, function(subtask, index){
            	vm.modifyTasksActivities(subtask, subtask.parent_task_key.toString());
        	});
		};

		//Function to get all the Tasks to display in the parent task dropdown on click of load button.
		var getTasksForParentDisplay = function(){
			var filterParams = {
				client: {
					value: vm.user_settings.user.client.client_key
				},
				jurisdiction : {
					value : 1
				},
				domain: {
					value: 9
				},
				channel: {
					value: vm.jdcInfo.selectedChannel.channel_key
				}
			}
			JdcManagerFactory.getTaskForJCD(filterParams, true).then(function (data) {
				vm.taskList = data;
				vm.resetTaskList = angular.copy(vm.taskList);
			}, function () {
				vm.taskList = [];
				if(vm.taskList.length === 0){
					AlertService.add("warning","you dont have any Key, please create the Tasks related client",4000);
				}
			});
		};
		vm.treeFilter = $filter('uiTreeFilter');

		vm.defaultSelection = function(data, onLoad, type){
			vm.tasksList = data;
			vm.trees.nodes = data;
			var treeScope = _getRootNodesScope('task-tree');
			var gotoKey;
			if(onLoad){
				vm.taskModel = vm.tasksList[0];
				vm.activitiesList = vm.tasksList[0].children;
				vm.selectedTask = angular.copy(data[0]);
				vm.selectedTask.channel = data[0].channel;
				vm.resetSelectedTask = angular.copy(data[0]);
			}else{
				if(type == 'default'){
					gotoKey = data[0].task_key;
					vm.taskModel = data[0];
					vm.taskModelObj(data, data[0].task_key);
				}else{
					gotoKey = vm.taskModel.task_key;
					vm.taskModelObj(data, vm.taskModel.task_key);
				}
				setTimeout(function () {
					vm.gotoTask(gotoKey);
				}, 200);
			}
			vm.refreshTaskActivity = false;
			vm.isAppParentChanged = false;
		};

		vm.taskModelObj = function(data, task_key){
			for(var i=0; i<data.length; i++){
				if(data[i].task_key == task_key){
					vm.taskSelect(data[i], 'task');
					taskFound = true;
					return;
				}
				if(data[i].sub_task && !taskFound){
					vm.taskModelObj(data[i].sub_task, task_key);
				}
			}
		};

		vm.gotoTask = function(x){
			var elmnt = document.getElementById("task" + x);
  			elmnt.scrollIntoView();
		}

		function toggleMoveIconsChildren(srcNode) {
			if(srcNode.sub_task){
				for (var i = 0; i < srcNode.sub_task.length; i++) {
	                srcNode.sub_task[i].nodeToMove = !srcNode.sub_task[i].nodeToMove
	                srcNode.sub_task[i].highlightNode = !srcNode.sub_task[i].highlightNode
	                toggleMoveIconsChildren(srcNode.sub_task[i])
	            }
			}
        }

        function toggleMoveIcons(srcNode, nodes) {
            for (var i = 0; i < nodes.length; i++) {
                if (srcNode.parent_task_key === nodes[i].task_key) {
                    nodes[i].nodeToMove = !nodes[i].nodeToMove
                    return true
                } else {
                    toggleMoveIcons(srcNode, nodes[i].sub_task)
                }
            }
        }

        function removeSameHierarchy(selectedTreeType, otherTreeType) {
            if (vm.trees[selectedTreeType].masterHierarchies.length > 0) {
                if (vm.trees[selectedTreeType].selectedAppCode === vm.trees[otherTreeType].selectedAppCode) {
                    if (vm.trees[otherTreeType].selectedHierarchyId) {
                        vm.trees[selectedTreeType].hierarchies.map(function (item) {
                            if (item.hierarchy_id === vm.trees[otherTreeType].selectedHierarchyId) {
                                item.existsInOtherTree = true
                            }
                            return item
                        })
                    }
                } else {
                    vm.trees[selectedTreeType].hierarchies = angular.copy(vm.trees[selectedTreeType].masterHierarchies)
                }
            }
        }

        function checkForParent(srcNodeParent, flattened) {
            var parentItem = _.filter(flattened, function (item) {
                return item.meCode === srcNodeParent.meCode
            })
            if (parentItem) {
                return true
            } else {
                return false
            }
        }

        vm.autoPush = function (srcNode, srcNodeScope, hierarchyId) {
            var srcNodeSafeCopy = angular.copy(srcNode)
            srcNodeSafeCopy.hierarchyId = vm.trees.selectedHierarchyId
            var selectedHierarchy = vm.trees.right.hierarchies.find(function (item) {
                return item.hierarchy_id === vm.trees.right.selectedHierarchyId
            })

            if (flattened[hierarchyId][srcNodeScope.$modelValue.meCode]) {
                AlertService.add('warning', "This BU already exists in '" + vm.trees.selectedHierarchyId + "'", 7000)
                return true
            }
            if (srcNodeSafeCopy.meParentKey !== null) {
                findImmediateParent(srcNodeSafeCopy, vm.trees.nodes)
                if (!vm.immediateParent) {
                    AlertService.add('warning', "This BU does not have a parent in '" + selectedHierarchy.hierarchy_name + "' Please add its parent first.", 7000)
                    return true
                } else {
                    srcNodeSafeCopy.meParentKey = vm.immediateParent.meKey
                    refreshHighlightHierarchy([srcNodeSafeCopy], 'absentInRightTree')
                    vm.immediateParent.children.push(srcNodeSafeCopy)
                }
            } else {
                vm.trees.nodes.push(srcNodeSafeCopy)
                refreshHighlightHierarchy(vm.trees.nodes, 'absentInRightTree')
            }

            presentInrightTree(srcNode)
            addOrUpdateNodeToFlattenedStore(srcNodeSafeCopy.hierarchyId, srcNode);

            BizzHierFactory.moveBusinessHierarchy(
                srcNodeSafeCopy.meKey,
                srcNodeSafeCopy.meParentKey ? srcNodeSafeCopy.meParentKey : '',
                vm.trees.selectedHierarchyId
            ).then(function (response) {
                console.log(response)
                srcNodeSafeCopy.parentMeCode = vm.immediateParent.meCode
                if (vm.treeCompared)
                    vm.compareHierarchies();
                AlertService.add("success", "Moved Business Unit Successfully", 3000);
            })
            vm.cancelNodeMove()
        }

		function findImmediateParent(srcNode, rightTreeNodes) {
            for (var i = 0; i < rightTreeNodes.length; i++) {
                if (srcNode.meParentKey === rightTreeNodes[i].meKey) {
                    vm.immediateParent = rightTreeNodes[i];
                } else {
                    findImmediateParent(srcNode, rightTreeNodes[i].children)
                }
            }
        }

        function refreshNodes(nodes) {
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].nodeToMove = false
                nodes[i].isSelected = false
                nodes[i].highlightNode = false
                if (nodes[i].sub_task) {
                    refreshNodes(nodes[i].sub_task)
                }
            }
        }

        function rootNodeWrapper(srcNode, srcNodeScope) {
            return function () {
                BizzHierFactory.moveBusinessHierarchy(
                    srcNode.meKey,
                    '',
                    vm.trees.selectedHierarchyId,
                    vm.trees.selectedHierarchyId
                ).then(function (response) {
                    console.log(response)
                    AlertService.add("success", "Moved Business Unit Successfully", 3000);
                })
                srcNode.meParentKey = null
                vm.trees.nodes.unshift(srcNode)
                srcNodeScope.remove()
                vm.cancelNodeMove()
            }
        }

        vm.cancelNodeMove = function () {
            vm.dropNode = null
            vm.showInBeginning = false
            vm.moveIcon = false
            refreshNodes(vm.trees.nodes)
        }

        function createDropNode(srcNode, srcNodeScope) {
            return function (destNode, destNodesScope) {
                console.log(vm.nodeToMove)
                vm.refreshTaskActivity = true;
                srcNode.parentTask = destNode.task_key.toString();
                srcNode.parent_task_key = destNode.task_key;
                destNode.sub_task.push(srcNode)

                vm.reordertasks.channel = vm.jdcInfo.selectedChannel;
                vm.reordertasks.client = USER_SETTINGS.user.client;
				vm.reordertasks.tasksList = vm.trees.nodes;

                JdcManagerFactory.saveTask($scope, vm, 'reordertasks', 'reorder', function () {
					AlertService.add("success", "Task moved successfully.",4000);
					vm.refreshTaskActivity = false;
				}, {});
                srcNodeScope.remove()
                vm.cancelNodeMove()
            }
        };

        function _getRootNodesScope(myTreeId) {
            var treeElement = angular.element(document.getElementById(myTreeId));
            if (treeElement) {
                var treeScope = (typeof treeElement.scope === 'function') ?
                    treeElement.scope() : undefined;
                return treeScope;
            }
            return undefined;
        };

        vm.moveNode = function (node, nodeScope) {
            vm.showInBeginning = true;
            vm.moveIcon = true;
            console.log(flattened);
            node.nodeToMove = true;
            node.isSelected = true;
            node.highlightNode = true;
            toggleMoveIcons(node, vm.trees.nodes);
            toggleMoveIconsChildren(node);

            var nodeScopeSafe = Object.create(nodeScope);

            //dropNode function is called when a node is moved to a different node.
            vm.dropNode = createDropNode(node, nodeScopeSafe);
            vm.makeRootNode = rootNodeWrapper(node, nodeScopeSafe);
        }

		$scope.$on('reorder-tasks', function(event, args) {
            vm.refreshTaskActivity = true;
        	getTasksHierarchyByClientKey(false, null);
        });

        $scope.$on('task-activity-update', function(event, args) {
        	vm.refreshTaskActivity = true;
        	if(args.onLoad){
        		getTasksHierarchyByClientKey(false, 'default');
        	}else{
        		getTasksHierarchyByClientKey(false, null);
        	}
        });

		vm.getResultForJDC = function () {
			var isRequiredFieldsMissing = vm.isChannelMissing();
			if (isRequiredFieldsMissing) {
				return;
			}
			vm.refreshTaskActivity = true;
			var filterParams = {
				client: {
					name: vm.jdcInfo.selectedClient.client_name,
					value: vm.jdcInfo.selectedClient.client_key
				},
				jurisdiction: {
					name: vm.jdcInfo.selectedJurisdiction.jurisdiction_code,
					value: vm.jdcInfo.selectedJurisdiction.jurisdiction_key
				},
				domain: {
					name: vm.jdcInfo.selectedDomain.domain_name,
					value: vm.jdcInfo.selectedDomain.domain_key
				},
				channel: {
					name: vm.jdcInfo.selectedChannel.channel_name,
					value: vm.jdcInfo.selectedChannel.channel_key
				}
			};
			initialFilterParams = angular.copy(filterParams);
			console.log("filterParams:  ", filterParams);
			getJCDKey(filterParams);
			loadResultForJDC(filterParams);
			getTasksForParentDisplay();

			vm.jdcInfo.showResult = true;
		}

		vm.taskSelect = function(data, type, index, parent){
			vm.isAppParentChanged = false;
			vm.selectedTask = angular.copy(data);
			vm.selectedTask.channel = data.channel;
			vm.selectedTask.parentTask = data.parentTask;
			vm.resetSelectedTask = angular.copy(vm.selectedTask);
			vm.taskList = angular.copy(vm.resetTaskList);
			vm.activitiesList = data.children;
			if(vm.activitiesList.length > 0){
				vm.selectedTask.deleteMsg = 'In order to delete this task [' + vm.selectedTask.task_name + '], please make sure to delete all the associated activities in this task first';
				vm.selectedTask.deleteCallBack = function(){
					angular.noop();
				};
			}
			if(type == 'task'){
				subTask.isSubTask = false;
				subTask.index = index;
			}else{
				subTask.isSubTask = true;
				subTask.index = index;
				subTask.parent = parent;
			}
		};

		vm.resetTaskDetails = function(){
			vm.taskList = angular.copy(vm.resetTaskList);
			vm.selectedTask = angular.copy(vm.resetSelectedTask);
			vm.selectedTask.channel = vm.taskChannelList.find(function(c) {
				return c.channel_name === vm.jdcInfo.selectedChannel.channel_name;
			});
			vm.isAppParentChanged = false;
		};

		vm.setPriority = function(data){
			angular.forEach(data, function(obj, i){
		        obj.activity_priority = i + 1;
		    });
		};

		vm.activityDrop = function(task, obj, index, sourceAct){
			var sourceActivities = angular.copy(sourceAct);
			var dropActivityKey = JdcManagerFactory.getDragKey();
			var activityIndex = _.findIndex(obj.children, function (act) {
                return act.activity_key == dropActivityKey;
            });
            sourceActivities.splice(draggedActivityIndex, 1);
            vm.setPriority(sourceActivities);
            vm.modifyActivity(obj, activityIndex, sourceActivities);
		};

		vm.modifyActivity = function(task, activityIndex, sourceActivities){
        	task.children[activityIndex].task_key = task.task_key;
        	task.blink = true;
        	function removeBlink() {
	            task.blink = false;
	        };
            $timeout(removeBlink, 1000);
            var allActivities = angular.copy(task.children);
			vm.setPriority(allActivities);
            $.merge(allActivities, sourceActivities);
            vm.saveShuffledActivities(allActivities, false);
        };

        vm.activitySplice = function(i){       	
        	vm.activitiesList.splice(i, 1);
        	vm.setPriority(vm.activitiesList);
			vm.saveShuffledActivities(vm.activitiesList, true);
			if(vm.activitiesList.length == 0){
				vm.selectedTask.deleteMsg = 'Are you sure you want to remove this Task [' + vm.selectedTask.task_name + '] ?';
				vm.selectedTask.deleteCallBack = function(){
					vm.removeTask(vm.selectedTask.task_key);
				};
			}
        };

		vm.draggedFromActivities = function(act, i){
			draggedActivityIndex = i;
			var dragOutKey = act.activity_key;
            JdcManagerFactory.setDragKey(dragOutKey);
		};

		vm.saveShuffledActivities = function(activities, reordered){
			var activitiesForSaving = [];
			angular.forEach(activities, function(act, i){
		        activitiesForSaving.push({
		        	"ACTIVITY_PRIORITY" : act.activity_priority,
		        	"JCD_KEY" : vm.jdcInfo.jcdKey,
			        "ACTIVITY_KEY" : act.activity_key,
			        "CLIENT_KEY" : act.client_key,
			        "TASK_KEY" : act.task_key,
			        "ACTIVITY_NAME" : act.activity_name
		    	});
		    });
			JdcManagerFactory.shuffleActivity(JSON.stringify(activitiesForSaving)).then(function (data) {
				if(data.result.value === "1"){
					if(reordered){
						AlertService.add("success","Activities Reordered Successfully",4000);
					}else{
						AlertService.add("success","Activity Moved Successfully",4000);
					}
				}
			});
		};

		vm.loadParentTasks=function(){
			vm.disableParent = true;
			var filterParams = {
				client: {
					value: vm.selectedTask.client.client_key
				},
				jurisdiction : {
					value : 1
				},
				domain: {
					value: 9
				},
				channel: {
					value: vm.selectedTask.channel.channel_key
				}
			}
			if (vm.selectedTask.client && vm.selectedTask.client.client_key !== undefined && vm.selectedTask.client.client_key !== null){
				JdcManagerFactory.getTaskForJCD(filterParams, true).then(function (data) {
					vm.disableParent = false;
					vm.taskList = data;
					vm.isAppParentChanged = true;
				}, function () {
					vm.taskList = [];
					if(vm.taskList.length === 0){
						AlertService.add("warning","you dont have any Key, please create the Tasks related client",4000);
					}
				});
			}
		}

		vm.ReorderTasks = function(){
			vm.allTasks = angular.copy(vm.tasksList);
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/admin/jdcManager/jdc-tasks-reorder-modal.html',
                controller: 'reordertasksController as ctrl',
                size: 'md',
                resolve: {
                    tasks_list: function () {
                        return vm.trees.nodes;
                    },
                    channel: function () {
                        return vm.jdcInfo.selectedChannel;
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {}, function () {});
		};

		vm.saveTaskDetails = function () {
			var onLoadCheck = false;
            var isRequiredFieldsMissing = JdcManagerFactory.validateRequiredFileds($scope, vm, 'jdcManageFrm', 'selectedTask', vm.requiredTaskFields);
			if (isRequiredFieldsMissing) {
				return;
			}
			vm.selectedTask.parentTask = vm.selectedTask.parentTask ? vm.selectedTask.parentTask : '';
			vm.saveTaskLoader = true;
			if(vm.resetSelectedTask.channel.channel_key != vm.selectedTask.channel.channel_key){
				onLoadCheck = true;
			}
			JdcManagerFactory.saveTask($scope, vm, 'selectedTask', 'edit', function () {
				vm.saveTaskLoader = false;
				AlertService.add("success", "Task " + vm.selectedTask.task_name + " saved successfully.",4000);
				vm.isAppParentChanged = false;
				vm.refreshTaskActivity = true;
    			$rootScope.$broadcast('task-activity-update', {onLoad: onLoadCheck});
			}, {task_key : vm.selectedTask.task_key});
		}

		$scope.$on('gtw.admin.jdc.loadResultForJDC', function (event, data) {

			var filterParams = {
				client: {
					name: vm.jdcInfo.selectedClient.client_name,
					value: vm.jdcInfo.selectedClient.client_key
				},
				jurisdiction: {
					name: vm.jdcInfo.selectedJurisdiction.jurisdiction_code,
					value: vm.jdcInfo.selectedJurisdiction.jurisdiction_key
				},
				domain: {
					name: vm.jdcInfo.selectedDomain.domain_name,
					value: vm.jdcInfo.selectedDomain.domain_key
				},
				channel: {
					name: vm.jdcInfo.selectedChannel.channel_name,
					value: vm.jdcInfo.selectedChannel.channel_key
				}
			};
			loadResultForJDC(filterParams);
			vm.jdcInfo.activity.showActivites = true;
		});

		vm.addNewYear = function () {
			vm.taxyear.editModalLoading = false;
			if(!!vm.taxyear.channel && vm.taxyear.tax_year !== 'null' && !!vm.taxyear.tax_year && !!vm.taxyear.edit_level && !!vm.taxyear.visible){
				if(vm.taxyear.tax_year.toString().length < 4){
					AlertService.add("error", "Tax Year Invalid, 4 digits expected", 4000);
					return;
				}else{
					vm.taxyear.editModalLoading = true;
					let rowData = {};
					rowData.taxYearObj = vm.taxyear;
					rowData.client = vm.jdcInfo.selectedClient;
					rowData.jurisdiction = vm.jdcInfo.selectedJurisdiction;
					rowData.domain = vm.jdcInfo.selectedDomain;
					rowData.channel = vm.jdcInfo.selectedChannel;
					rowData.taxyearList= vm.jdcInfo.taxYear.taxYearsOriginal;//IMP
					rowData.action = 'new';
					vm.saveTaxYearHelper(rowData);
				}
			}else{
				AlertService.add("error", "Required feilds are missing!",4000);
				return;
			}
		};

		vm.maxLengthCheck = function(val, obj, key, name, array , index){
			var limit = 0;
			if(name == 'year'){
				limit = 4;
			}else{
				limit = 2;
			}
			if (val.toString().length > limit){
				if(array){
					vm.jdcInfo.scenario.scenarios[index][key] = val.toString().slice(0, limit);
				}else{
					vm[obj][key] = val.toString().slice(0, limit);
				}
			}
		}

		vm.addNewScenario = function () {
			vm.scenario.addModalLoading = false;
			if(!!vm.scenario.tax_year && !!vm.scenario.scenario && !!vm.scenario.scenario_desc && vm.scenario.calendar_year !== 'null' && !!vm.scenario.calendar_year){	
				if(vm.scenario.calendar_year.toString().length < 4){
					AlertService.add("error", "Tax Year Invalid, 4 digits expected", 4000);
					return;
				}else{
					var rowData = vm.scenario;
					rowData.client = vm.jdcInfo.selectedClient;
					rowData.jurisdiction = vm.jdcInfo.selectedJurisdiction;
					rowData.domain = vm.jdcInfo.selectedDomain;
					rowData.channel = vm.jdcInfo.selectedChannel;
					rowData.taxyearList=vm.jdcInfo.taxYear.taxYearsOriginal;
					var taxYear = _.find(vm.jdcInfo.taxYear.taxYearsOriginal,{
						tax_year:vm.scenario.tax_year
					});
					rowData.tax_year = taxYear.tax_year;
					rowData.TAX_YEAR = taxYear;
					rowData.jcd_key = taxYear.jcd_key;
					rowData.scenarioList = vm.jdcInfo.scenario.scenariosOriginal ;
					rowData.action ='new';
					vm.saveScenarioHelper(rowData);
				}
			}else{
				AlertService.add("error", "Required feilds are missing!", 4000);
				return;
			}
		};

		vm.addNewTask = function () {
			var data = {};
			data.rowData = {
				client: vm.jdcInfo.selectedClient,
				jurisdiction: vm.jdcInfo.selectedJurisdiction,
				domain: vm.jdcInfo.selectedDomain,
				channel: vm.jdcInfo.selectedChannel
			};
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdc-save-task-modal.html";
			modalObj.controler = "SaveTaskController as ctrl";
			modalObj.size = "md";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, data);
		};

		vm.removeTask = function (task_key) {
			vm.jdcInfo.editModalLoading = true;
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ag0apb";
			var data = {taskKey: task_key, clientKey: vm.jdcInfo.selectedClient.client_key}
			JsonObjectFactory.saveJSON(url, data).then(function (data) {
				vm.jdcInfo.editModalLoading = false;
				if (data.callSuccess === "1") {
					AlertService.add("success", "Task removed successfully.",4000);
					$rootScope.$broadcast('task-activity-update', {onLoad: true});
				} else {
					AlertService.add("error",data.errorMessage ,4000);
				}
			});
		};

		vm.removeTaxYear = function(jcd_tax_year_key){
			vm.jdcInfo.editModalLoading = true;
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=t9j7xx";

			var payLoadForSaving = [{
				JCD_TAX_YEAR_KEY: jcd_tax_year_key
			}];

			var jsonObj = JSON.stringify(payLoadForSaving);
			var jsonSettings = '{}';
			var params = _.extend({
				jsonObj: jsonObj
			}, params);
			params = _.extend({
				jsonSettings: jsonSettings
			}, params);
			JsonObjectFactory.saveJSON(url, params).then(function (data) {
				vm.jdcInfo.editModalLoading = false;
				if (data.callSuccess === "1") {
					AlertService.add("success", "TaxYear removed successfully.",4000);
					//$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
					$rootScope.$broadcast('task-activity-update', {onLoad: true});
				} else {
					AlertService.add("error", "Task not removed.",4000);
				}
			});
		}

		vm.editTask = function (task,tasks) {
			vm.jdcInfo.editModalLoading = true;
			var info = {};
			info.rowData = task;
			info.rowData.client = vm.jdcInfo.selectedClient;
			info.rowData.jurisdiction = vm.selectedJurisdiction;
			info.rowData.domain = vm.selectedDomain;
			info.rowData.channel = vm.jdcInfo.selectedChannel;
			info.rowData.tasks=tasks;

			info.rowData.parentTask = _.find(vm.jdcInfo.task.tasks, {
				task_key: task.parent_task_key

			});
			console.log("parent taskKey from activity",info.rowData.parentTask)
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdc-save-task-modal.html";
			modalObj.controler = "SaveTaskController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, info);
			vm.jdcInfo.editModalLoading = false;
		};

		vm.saveTaxYearHelper = function (rowData){

			let self = this;
			self.mode = _.lowerCase(rowData.action);
			self.crudLoading = false;
			self.user_settings = USER_SETTINGS;
			var taxyear = {};
			
			if (_.lowerCase(self.mode) == _.lowerCase('edit')) {
				taxyear.jcd_tax_year_key = rowData.taxYearObj.jcd_tax_year_key;
			};
				taxyear.tax_year = rowData.taxYearObj.tax_year;
				taxyear.is_locked = rowData.taxYearObj.is_locked;
				taxyear.edit_level = _.find(vm.editlevelList.list, function (obj) {
					return (obj.value == Number(rowData.taxYearObj.edit_level));
				})
				taxyear.visible = _.find(vm.visibleList.list, function (obj) {
					return (obj.value == Number(rowData.taxYearObj.visible));
				});
				taxyear.is_default = _.find(vm.isdefaultList.list, function (obj) {
					return (obj.value == Number(rowData.taxYearObj.is_default));
				});

			self.getInitInfo = function () {
				taxyear.client = self.user_settings.user.client;
				// get the clientLIst initial load itself.
				if (taxyear.client && taxyear.client.client_key != null)
					JdcManagerFactory.getInitInfo({
						client: {
							value: taxyear.client.client_key
						}
					}).then(function (data) {
						let channelList = data.data.jcdManage.channelList;
						if(channelList.length === 0){
							AlertService.add("warning","you dont have App Key, please create the Channel & JCD or else create the JCD for related client",4000);
						} else if (angular.isDefined(rowData.channel) && (rowData.channel != null) &&	(rowData.channel != '')) {
							taxyear.channel = _.find(self.channelList, function (obj) {
								return (obj.channel_key == rowData.channel.channel_key);
							});

							self.save();
						}
					}, function () {
						rowData.taxYearObj.editModalLoading = false;
						AlertService.add("error", "Problem getting Initialization info.",4000);
					});
			};

			//get All CLients
			self.getClients = function () {
				self.clientList = JdcManagerFactory.clientList;
				if (angular.isDefined(rowData.client) && (rowData.client != null) && (rowData.client != '')) {
					taxyear.client = _.find(self.clientList, function (obj) {
						return (obj.client_key == rowData.client.client_key);
					});
					self.getInitInfo();
				}
			}
			self.getClients();
	
			self.save = function(){

				var filterParams = {
					client: {
						name: taxyear.client.client_name,
						value: taxyear.client.client_key
					},
					jurisdiction:{
						name:'US',
						value:1
					},
					domain:{
						name:'Direct',
						value:9
					},
					channel: {
						name: taxyear.channel.channel_name,
						value: taxyear.channel.channel_key
					},
					is_locked: taxyear.is_locked
				};

				// validate the rquired fields are filled-out or not
				if (JdcManagerFactory.validateRequiredFileds($scope, self, 'saveYearFrm', 'taxyear', vm.requiredFields)) {
					rowData.taxYearObj.editModalLoading = false;
					return;
				}

				var payLoadForValidation = {
					tax_year: taxyear.tax_year+'',
					edit_level: taxyear.edit_level.value+'',
					visible: taxyear.visible.value+'',
					is_default: taxyear.is_default.value+'',
					is_locked: taxyear.is_locked+''
				};
				if(rowData.taxyearList && _.find(rowData.taxyearList,payLoadForValidation)  && rowData.action === 'new'){
					rowData.taxYearObj.editModalLoading = false;
					AlertService.add("error", "Duplicate Error: Please select different combination !",4000);
					return;
				}
				
				// validate the JCD combination
				JdcManagerFactory.getJCDKey(filterParams).then(function (response) {
					if (!response.data.jcdKey || response.data.jcdKey == 0) {
						rowData.taxYearObj.editModalLoading = false;
						AlertService.add("error", "Please select valid JCD combination !",4000);
						return ;
					}
					// create and update taxyear screen
					var payLoadForSaving = [{
						JCD_KEY: response.data.jcdKey,
						TAX_YEAR: taxyear.tax_year,
						EDIT_LEVEL: taxyear.edit_level.value,
						VISIBLE: taxyear.visible.value,
						IS_DEFAULT: taxyear.is_default.value,
						IS_LOCKED: taxyear.is_locked
					}];

					var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=8vygsp";

					if (self.mode == 'edit') { // edit action code 
						payLoadForSaving[0]["JCD_TAX_YEAR_KEY"] = rowData.taxYearObj.jcd_tax_year_key;
						url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=56p5yf";
					}

					var jsonObj = JSON.stringify(payLoadForSaving);
					var jsonSettings = '{}';
					var params = _.extend({
						jsonObj: jsonObj
					}, params);
					params = _.extend({
						jsonSettings: jsonSettings
					}, params);

					var validateErr=[];
					
					/* tax year Validation whether its already ther or not */
					if(_.filter(rowData.taxyearList.tax_year,{tax_year:taxyear.tax_year}).length){
						validateErr.push("TaxYear Already exists");
					}
					if(validateErr.length){
						rowData.taxYearObj.editModalLoading = false;
						for(var i=0;i<validateErr.length;i++){
							AlertService.add("error",validateErr[i],4000);
						}
						return;
					}
					
					JsonObjectFactory.saveJSON(url, params).then(function (data) {
						rowData.taxYearObj.editModalLoading = false;
						if (data.callSuccess === "1") {
							AlertService.add("success", "taxyear " + taxyear.tax_year + " " + (self.mode == 'edit' ? "edited" : "saved") + " successfully.",4000);
							vm.crudLoading = false;
							vm.taxyear = {};
							//$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
							$rootScope.$broadcast('task-activity-update', {onLoad: true});
						} else {
							AlertService.add("error", " Problem saving taxYear: ",4000);
							vm.crudLoading = false;
						}
					});
				});
			}
		}

		vm.editTaxYear = function(taxyear){
			taxyear.editModalLoading = true;
			let rowData = {};
			rowData.taxYearObj=taxyear;
			rowData.client = vm.jdcInfo.selectedClient;
			rowData.jurisdiction = vm.jdcInfo.selectedJurisdiction;
			rowData.domain = vm.jdcInfo.selectedDomain;
			rowData.channel = vm.jdcInfo.selectedChannel;
			rowData.taxyearList= vm.jdcInfo.taxYear.taxYearsOriginal;
			rowData.action='edit';
			vm.saveTaxYearHelper(rowData);
		}
		vm.lockUnlockTaxYear= function(taxyear){
			taxyear.editModalLoading = true;
			let rowData = {};
			taxyear.is_locked = taxyear.is_locked === 'Y' ? 'N':'Y';
			rowData.taxYearObj = taxyear;
			rowData.client = vm.jdcInfo.selectedClient;
			rowData.jurisdiction = vm.jdcInfo.selectedJurisdiction;
			rowData.domain = vm.jdcInfo.selectedDomain;
			rowData.channel = vm.jdcInfo.selectedChannel;
			rowData.taxyearList= vm.jdcInfo.taxYear.taxYearsOriginal;
			rowData.action='edit';
			vm.saveTaxYearHelper(rowData);
		};
		vm.defaultTaxYear= function(taxyear){
			taxyear.editModalLoading = true;
			let rowData = {};
			taxyear.is_default = taxyear.is_default === '1' ? '0':'1'
			rowData.taxYearObj = taxyear;
			rowData.client = vm.jdcInfo.selectedClient;
			rowData.jurisdiction = vm.jdcInfo.selectedJurisdiction;
			rowData.domain = vm.jdcInfo.selectedDomain;
			rowData.channel = vm.jdcInfo.selectedChannel;
			rowData.taxyearList= vm.jdcInfo.taxYear.taxYearsOriginal;
			rowData.action='edit';
			vm.saveTaxYearHelper(rowData);
		};
		vm.editScenario = function (scenario, index) {
			scenario.editModalLoading = true;
			var rowData = scenario;
			rowData.client = vm.jdcInfo.selectedClient;
			rowData.jurisdiction = vm.jdcInfo.selectedJurisdiction;
			rowData.domain = vm.jdcInfo.selectedDomain;
			rowData.channel = vm.jdcInfo.selectedChannel;
			rowData.taxyearList=vm.jdcInfo.taxYear.taxYearsOriginal;
			var taxYear = _.find(vm.jdcInfo.taxYear.taxYearsOriginal,{
				tax_year:scenario.tax_year
			});
			rowData.tax_year = taxYear.tax_year;
			rowData.TAX_YEAR = vm.jdcInfo.scenario.scenariosOriginal[index]["tax_year"];
			rowData.jcd_key = taxYear.jcd_key;
			rowData.scenarioList = vm.jdcInfo.scenario.scenariosOriginal ;
			rowData.action ='edit';
			vm.saveScenarioHelper(rowData,scenario);
		};

		vm.removeScenario = function (scenarios) {
			vm.jdcInfo.editModalLoading = true;
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vobhrd";

			var payLoadForSaving = [{
				SCENARIO: scenarios
			}];

			var jsonObj = JSON.stringify(payLoadForSaving);
			var jsonSettings = '{}';
			var params = _.extend({
				jsonObj: jsonObj
			}, params);
			params = _.extend({
				jsonSettings: jsonSettings
			}, params);
			JsonObjectFactory.saveJSON(url, params).then(function (data) {
				vm.jdcInfo.editModalLoading = false;
				if (data.callSuccess === "1") {
					AlertService.add("success", "scenario removed successfully.",4000);
					$rootScope.$broadcast('task-activity-update', {onLoad: true});
					//$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
					//vm.cancel();
				} else {
					AlertService.add("error", "Scenario in Use : not removed.",4000);
				}
			});
		};

		vm.addNewActivity = function () {
			var data = {};
			data.rowData = {
				client: vm.jdcInfo.selectedClient,
				jurisdiction: vm.jdcInfo.selectedJurisdiction,
				domain: vm.jdcInfo.selectedDomain,
				channel: vm.jdcInfo.selectedChannel,
				activityList : vm.jdcInfo.activity.activities
			};
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdc-save-activity-modal.html";
			modalObj.controler = "SaveActivityController as ctrl";
			modalObj.size = "md";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, data);
		};

		vm.copyAccessRoles = function (activity) {
			vm.allTasks = angular.copy(vm.tasksList);
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: "app/components/admin/jdcManager/copy-roles-access-modal.html",
                controller: "copyAccessController as ctrl",
                size: 'md',
                resolve: {
                    tasks_list: function () {
                        return vm.trees.nodes;
                    },
                    activity: function () {
                        return activity;
                    },
					jcd_key: function () {
						return vm.jdcInfo.jcdKey;
					}
                }
            });
            modalInstance.result.then(function (selectedItem) {}, function () {});
		};

		vm.getActivityUsers = function (activity) {
			vm.allTasks = angular.copy(vm.tasksList);
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: "app/components/admin/jdcManager/activity-user-list-modal.html",
                controller: "getAcctivityUsersController as ctrl",
                size: 'md',
                resolve: {
                    tasks_list: function () {
                        return vm.trees.nodes;
                    },
                    activity: function () {
                        return activity;
                    },
					jcd_key: function () {
						return vm.jdcInfo.jcdKey;
					}
                }
            });
            modalInstance.result.then(function (selectedItem) {}, function () {});
		};

		vm.editActivity = function (a) {
			vm.jdcInfo.editModalLoading = true;
			JdcManagerFactory.getActivity(a.activity_key,a.client_key).then(function (data) {
				var activity = data.data.jcdActivity;
				var info = {};
				info.rowData = activity;
				info.rowData.client = vm.jdcInfo.selectedClient;
				info.rowData.jurisdiction = vm.jdcInfo.selectedJurisdiction;
				info.rowData.domain = vm.jdcInfo.selectedDomain;
				info.rowData.channel = vm.jdcInfo.selectedChannel;

				info.rowData.task = _.find(vm.jdcInfo.task.tasks, {
					task_key: activity.activity_task
				});
				var modalObj = {};
				modalObj.template = "app/components/admin/jdcManager/jdc-save-activity-modal.html";
				modalObj.controler = "SaveActivityController as ctrl";
				modalObj.size = "md";
				modalObj.backdrop = "static";
				vm.openModal(modalObj, info);
				vm.jdcInfo.editModalLoading = false;
				var taxtypeList = [];
				var taxtype = {};
			}, function () {
				vm.jdcInfo.editModalLoading = false;
				AlertService.add("error", "Problem editing activity.",4000);
			});
		};

		vm.openModal = function (modalObj, _data) {

			vm.selectedClient = vm.jdcInfo.selectedClient;
			vm.selectedJurisdiction = vm.jdcInfo.selectedJurisdiction;
			vm.selectedDomain = vm.jdcInfo.selectedDomain;
			vm.selectedChannel = vm.jdcInfo.selectedChannel;

			vm.rowData = _data.rowData;
			vm.gridData = _data;

			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: modalObj.template,
				controller: modalObj.controler,
				size: modalObj.size,
				backdrop: modalObj.backdrop,
				resolve: {
					rowData: function () {
						return vm.rowData;
					},
					gridData: function () {
						return vm.gridData;
					}
				}
			});

			modalInstance.result.then(function (selectedItem) {
				$rootScope.$broadcast('gtw.admin.jdc.activityModalClose');
			}, function () {
				$rootScope.$broadcast('gtw.admin.jdc.activityModalClose');
			});
		};

		vm.removeActivity = function (activity_key, activity_name,jcd_key) {
			JdcManagerFactory.deleteActivity(activity_key,jcd_key).then(function (data) {
				if (parseInt(data.data.result.value) == 1) {
					AlertService.add("success", "Activity " + activity_name + " removed successfully.",4000);
					$rootScope.$broadcast('task-activity-update', {onLoad: false});
				} else {
					AlertService.add("error", data.data.result.message);
				}
			}, function () {
				AlertService.add("error", "Problem removing activity " + activity_name + ".",4000);
			});
		}

		vm.toggleActivities = function () {
			vm.jdcInfo.activity.showActivites = !vm.jdcInfo.activity.showActivites;
		};

		vm.toggleTaxYears = function () {
			vm.jdcInfo.taxYear.showTaxYears = !vm.jdcInfo.taxYear.showTaxYears;
		};

		vm.toggleScenarios = function () {
			vm.jdcInfo.scenario.showScenarios = !vm.jdcInfo.scenario.showScenarios;
		};

		vm.toggleTasks = function () {
			vm.jdcInfo.task.showTasks = !vm.jdcInfo.task.showTasks;
		};

		vm.isClientMissing = function () {
			return $scope.jdcManageFrm['client'].$dirty && vm.isMissing(vm.jdcInfo.selectedClient);
		};

		vm.isJurisdictionMissing = function () {
			return $scope.jdcManageFrm['jurisdiction'].$dirty && vm.isMissing(vm.jdcInfo.selectedJurisdiction);
		};

		vm.isDomainMissing = function () {
			return $scope.jdcManageFrm['domain'].$dirty && vm.isMissing(vm.jdcInfo.selectedDomain);
		};

		vm.isChannelMissing = function () {
			return vm.isMissing(vm.jdcInfo.selectedChannel);
		};

		vm.isMissing = function (obj) {

			return (angular.isUndefined(obj) || (obj == null) || (obj == ''));
		};

		vm.isValidComponent = function (component) {

			var isValid = false;

			if (component === 'jdcInfo.activity.newActivity.activity_name') {
				return ((angular.isDefined(vm.jdcInfo.activity.newActivity.activity_name)) && (vm.jdcInfo.activity.newActivity.activity_name != '')) ? (true) : (false);
			} else if (component === 'jdcInfo.activity.newActivity.activity_priority') {
				return ((angular.isDefined(vm.jdcInfo.activity.newActivity.activity_priority)) && (vm.jdcInfo.activity.newActivity.activity_priority != '')) ? (true) : (false);
			}

			return isValid;
		};

		vm.isInvalidComponent = function (component) {

			var isDirty = $scope.jdcManageFrm[component].$dirty;
			var isInvalid = isDirty && !vm.isValidComponent(component);

			return isInvalid;
		};

		vm.saveScenarioHelper = function(rowData,existing_scenario) {
			let self = {};
			self.mode = _.lowerCase(rowData.action);
			self.user_settings = USER_SETTINGS;

			self.taxyearList = [];
			self.scenarios= {};

			self.reportingList = [{
				name: 'Yes',
				value: 'Y'
			}, {
				name: 'No',
				value: 'N'
			}];
			self.filingList = [{
				name: 'Yes',
				value: 'Y'
			}, {
				name: 'No',
				value: 'N'
			}];
			self.defaultScenarioList = [{
				name: 'Yes',
				value: 'Y'
			}, {
				name: 'No',
				value: 'N'
			}];
			self.isVisibleList = [{
				name : 'Yes',
				value : 'Y'
			},{
				name : 'No',
				value : 'N'
			}];

			// if (_.lowerCase(self.mode) == _.lowerCase('edit')) {
			self.scenarios.scenario = rowData.scenario ?parseInt(rowData.scenario):"";
			// }
			self.scenarios.jcd_key = rowData.jcd_key;
			self.scenarios.scenario_desc = rowData.scenario_desc;
			self.scenarios.calendar_year = rowData.calendar_year?parseInt(rowData.calendar_year):"";
			self.scenarios.calendar_month = rowData.calendar_month?parseInt(rowData.calendar_month):"";
			self.scenarios.is_locked = rowData.is_locked;
			self.scenarios.reporting_y_n = _.find(self.reportingList, function (obj) {
				return (obj.value == rowData.reporting_y_n);
			});
			self.scenarios.filing_y_n = _.find(self.filingList, function (obj) {
				return (obj.value == rowData.filing_y_n);
			});
			self.scenarios.default_scenario = _.find(self.defaultScenarioList, function (obj) {
				return (obj.value == rowData.default_scenario);
			});
			self.scenarios.is_visible = _.find(self.isVisibleList,function(obj){
				return(obj.value == rowData.is_visible);
			});
	
			console.log("scenarios ", self.scenarios);

			
			self.getInitInfo = function () {
				
				self.scenarios.client = self.user_settings.user.client;
				// get the clientLIst initial load itself.
				if (self.scenarios.client && self.scenarios.client.client_key != null)
					JdcManagerFactory.getInitInfo({
						client: {
							value: self.scenarios.client.client_key
						}
					}).then(function (data) {
						self.channelList = data.data.jcdManage.channelList;
						if(self.channelList.length === 0){
							AlertService.add("warning","you dont have App Key, please create the Channel & JCD or else create the JCD for related client",4000);
						} else if (angular.isDefined(rowData.channel) &&(rowData.channel != null) && (rowData.channel != '')) {
							self.scenarios.channel = _.find(self.channelList, function (obj) {
								return (obj.channel_key == rowData.channel.channel_key);
							});
						}
						self.getTaxYearList();
					}, function () {
						AlertService.add("error", "Problem getting Initialization info.",4000);
						self.scenarios.loading = false;
					});
			};
	
			self.isMissing = function (obj) {
				return (angular.isUndefined(obj) || (obj == null) || (obj == ''));
			};
	
			// get the task LIst based on the JCD combination.
			self.getTaxYearList = function () {
				if (!self.isMissing(self.scenarios.client) && !self.isMissing(self.scenarios.channel)) {
					self.scenarios.scenarioLoading = true;
					var filterParams = {
						client: {
							value: self.scenarios.client.client_key
						},
						jurisdiction :{
							value : 1
						},
						domain : {
							value : 9
						},
						channel: {
							value: self.scenarios.channel.channel_key
						}
					}
	
					if (self.scenarios.client && self.scenarios.client.client_key!=null){
						JdcManagerFactory.getTaxYearForJCD(filterParams).then(function (data) {
							var taxyearList = data;
							console.log("taxYear List", taxyearList);
							// if (_.lowerCase(self.mode) == _.lowerCase('edit')) {
								self.scenarios.tax_year = _.find(taxyearList,function(obj){
									return(obj.TAX_YEAR == rowData.tax_year);
								});
							// }
							self.save();
							
						}, function () {
							AlertService.add("error", "Problem getting tax year.",4000);
						});
					}
						
				} else {
					self.taxyearList = [];
					self.scenarios.loading = false;
				}
			}

			self.getClients = function () {
				self.clientList = JdcManagerFactory.clientList;
				if (angular.isDefined(rowData.client) && (rowData.client != null) && (rowData.client != '')) {
					self.scenarios.client = _.find(self.clientList, function (obj) {
						return (obj.client_key == rowData.client.client_key);
					});
					console.log(self.scenarios.client);
					self.getInitInfo();
				}
			}
			self.getClients();

			self.save = function () {

				var filterParams = {
					client: {
						name: self.scenarios.client.client_name,
						value: self.scenarios.client.client_key
					},
					jurisdiction:{
						name:'US',
						value:1
					},
					domain:{
						name:'Direct',
						value:9
					},
					channel: {
						name: self.scenarios.channel.channel_name,
						value: self.scenarios.channel.channel_key
					}
				};

				
				// validate the rquired fields are filled-out or not
				if (!self.scenarios["channel"] && !self.scenarios["tax_year"] && !self.scenarios["scenario"] && 
					!self.scenarios["scenario_desc"] && !self.scenarios["is_visible"] && !self.scenarios["default_scenario"]) {
						AlertService.add("error", "Required feilds are missing!",4000);
						return;
				}

				// validate the JCD combination
				JdcManagerFactory.getJCDKey(filterParams).then(function (response) {
	
					if (!response.data.jcdKey || response.data.jcdKey == 0) {
						AlertService.add("error", "Please select valid JCD combination !",4000);
						return;
					}
	
					// create and update screnario screen
					var payLoadForSaving = [{
						TAX_YEAR: self.scenarios.tax_year.TAX_YEAR ? self.scenarios.tax_year.TAX_YEAR : self.scenarios.tax_year.tax_year ,
						SCENARIO : self.scenarios.scenario,
						SCENARIO_DESC: self.scenarios.scenario_desc,
						JCD_KEY: response.data.jcdKey,
						CALENDAR_YEAR: self.scenarios.calendar_year||"",
						CALENDAR_MONTH: self.scenarios.calendar_month||"",
						REPORTING_Y_N: self.scenarios.reporting_y_n.value,
						FILING_Y_N: self.scenarios.filing_y_n.value,
						DEFAULT_SCENARIO: self.scenarios.default_scenario.value,
						IS_VISIBLE : self.scenarios.is_visible.value,
						IS_LOCKED: self.scenarios.is_locked
					}];
	
	
					var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=most83";
	
					if (self.mode == 'edit') { //edit action code 4dk04a--- for update, here were are passing "scenario"
					//	payLoadForSaving[0]["SCENARIO"] = rowData.scenario;
						payLoadForSaving[0]["OLD_SCENARIO_KEY"] = rowData.scenario;
						payLoadForSaving[0]["OLD_TAX_YEAR"] = rowData.TAX_YEAR;
	
						url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9khx7b";
					}
	
					var jsonObj = JSON.stringify(payLoadForSaving);
					var jsonSettings = '{}';
					var params = _.extend({
						jsonObj: jsonObj
					}, params);
					params = _.extend({
						jsonSettings: jsonSettings
					}, params);
	
					if( ((self.scenarios.scenario != Number(rowData.scenario) && self.scenarios.tax_year.TAX_YEAR != Number(rowData.TAX_YEAR)) || self.mode != 'edit') &&  
					(_.find(rowData.scenarioList,{scenario:self.scenarios.scenario.toString(), tax_year:self.scenarios.tax_year.TAX_YEAR.toString()} )) ) {
						vm.scenario.addModalLoading = false;
						AlertService.add("error", "Scenario & TaxYear Combination already exist!",4000);
						delete vm.scenario.TAX_YEAR;
						delete vm.scenario.action;
						delete vm.scenario.channel;
						delete vm.scenario.client;
						delete vm.scenario.domain;
						delete vm.scenario.jurisdiction;
						delete vm.scenario.scenarioList;
						delete vm.scenario.taxyearList;
						delete vm.scenario.jcd_key;
						return;
					}
					JsonObjectFactory.saveJSON(url, params).then(function (data) {
						if (data.callSuccess === "1") {
							vm.scenario ={};
							vm.scenario.addModalLoading  = false;
							AlertService.add("success", "scenario " + self.scenarios.scenario + " " + (self.mode == 'edit' ? "edited" : "saved") + " successfully.",4000);
							//$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
							$rootScope.$broadcast('task-activity-update', {onLoad: true});
						} else {
							vm.scenario.addModalLoading  = false;
							AlertService.add("error", " Problem saving scenario:",4000);
						}
						if(existing_scenario){
							existing_scenario.editModalLoading  = false;	
						}
					});
				});
			}
		};

		vm.saveScenarioInit = function () {
			if(vm.taxyearList && vm.taxyearList.length !==0){
				return ;
			}
			vm.scenario = {};
			vm.scenario.editModalLoading = true;
			var filterParams = {
				client: {
					value: vm.user_settings.user.client.client_key
				},
				jurisdiction :{
					value : 1
				},
				domain : {
					value : 9
				},
				channel: {
					value: vm.jdcInfo.selectedChannel.channel_key
				}
			}
			JdcManagerFactory.getTaxYearForJCD(filterParams).then(function (data) {
				console.log("tax years", data);
				vm.taxyearList = data;
				vm.scenario.editModalLoading = false;
				
			}, function () {
				vm.taxyearList = [];
				vm.scenario.editModalLoading = false;
				AlertService.add("error", "Problem getting tax year.",4000);
			});
		}
	}

	function reordertasksController($rootScope, $window, $scope, tasks_list, channel, $state, $http, ModalFactory, AlertService, GlobalService, $timeout, $uibModalInstance, JdcManagerFactory, USER_SETTINGS, $filter) {
    
        var vm = this;
        vm.subTasksList = [];
        vm.reorderMode = true;
        vm.models;
        vm.tasksList = angular.copy(tasks_list);
        vm.reordertasks = {};
        vm.reordertasks.channel = channel;
        vm.reorderTaskLoader = false;
        vm.originalTaskList = [];
        vm.treeOptions = {};
        vm.collapsed = {};
		var flattened = {};
		vm.nodeToMove = {};

        vm.title = 'Reorder Tasks / reshuffle subTasks';

        vm.treeFilter = $filter('uiTreeFilter');

        vm.selectedMode = function(state, onLoad){
        	angular.forEach(vm.tasksList, function(task, i){
		        task.draggable = state;
		    });
		    if(onLoad){
		    	vm.originalTaskList = angular.copy(vm.tasksList);
		    }
		    vm.taskModel = vm.tasksList[0];
		    vm.models = vm.tasksList[0];
            vm.subTasksList = vm.tasksList[0].sub_task;
        };

        vm.selectedMode(vm.reorderMode, true);

        vm.showSubTask = function(task){
        	vm.subTasksList = task.sub_task;
        };

        vm.gotoTask = function(x){
			var elmnt = document.getElementById("reorderTask" + x);
  			elmnt.scrollIntoView();
		}

        vm.clear = function(){
            vm.tasksList = angular.copy(vm.originalTaskList);
            vm.taskModel = vm.tasksList[0];
            vm.models = vm.tasksList[0];
            vm.subTasksList = vm.tasksList[0].sub_task;
            setTimeout(function () {
				vm.gotoTask(vm.tasksList[0].task_key);
			}, 200);
        };

        vm.cancel = function () {
            closeModal();
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.applyReorder = function(){
        	vm.reorderTaskLoader = true;
        	vm.reordertasks.client = USER_SETTINGS.user.client;
			vm.reordertasks.tasksList = vm.tasksList;
			JdcManagerFactory.saveTask($scope, vm, 'reordertasks', 'reorder', function () {
				vm.reorderTaskLoader = false;
				AlertService.add("success", "Task's reordered successfully.",4000);
				closeModal();
        		$rootScope.$broadcast('reorder-tasks', {});
			}, {});
        };
    };

	function copyAccessController($rootScope, $window, $scope, tasks_list, jcd_key, activity, $state, $http, ModalFactory, AlertService, GlobalService, $timeout, $uibModalInstance, JdcManagerFactory, USER_SETTINGS, $filter) {
    
        var vm = this;
		vm.sourceRolesList = [];
		vm.targetRolesList = activity.roles;
		vm.mappedRolesList = [];
		vm.targetActivityName = activity.activity_name;
		vm.selectedTargetRole = vm.targetRolesList[0];
        vm.tasksList = angular.copy(tasks_list);
		vm.activityList = angular.copy(activity);
		vm.jcdKey = angular.copy(jcd_key);
		// vm.sourceActivityName = vm.tasksList[1].children[0].activity_name;
		// vm.selectedSourceRole = vm.tasksList[1].children[0].roles;
		vm.sourceActivityName = "";
		vm.selectedSourceRole = [];
        vm.saveMappingLoader = false;
		vm.selectedSourceActivityKey;
		vm.user_settings = USER_SETTINGS;
		vm.globalParams = GlobalService.globalParams;

        vm.title = 'Copy Activity User Access';
        vm.treeFilter = $filter('uiTreeFilter');

		vm.addMapping = function(){
			if(vm.mappedRolesList.length < 1){
				vm.mappedRolesList.push({
					name : vm.selectedSourceRole + " - " + vm.selectedTargetRole,
					source : vm.selectedSourceRole,
					target : vm.selectedTargetRole
				});
			}else{
				if(_.find(vm.mappedRolesList, { source : vm.selectedSourceRole})){
					AlertService.add("warning", "Same source multiple mapping not possible. [" + vm.selectedSourceRole +  "] is already added",4000);
				}else{
					vm.mappedRolesList.push({
						name : vm.selectedSourceRole + " - " + vm.selectedTargetRole,
						source : vm.selectedSourceRole,
						target : vm.selectedTargetRole
					});
				}
			}
		};

		vm.removeMapping = function(index){
			vm.mappedRolesList.splice(index, 1);
		};

        vm.showActivityRoles = function(task){
			vm.sourceActivityName = task.activity_name;
			vm.mappedRolesList = [];
			vm.selectedSourceActivityKey = task.activity_key;
			if(task.roles){
				vm.sourceRolesList = task.roles;
				vm.selectedSourceRole = vm.sourceRolesList[0];
			}else{
				vm.sourceRolesList = [];
			}
        };

        vm.clear = function(){
            vm.sourceRolesList = [];
			vm.selectedTargetRole = "";
			vm.mappedRolesList = [];
        };

        vm.cancel = function () {
            closeModal();
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.saveMapping = function(){
			vm.saveMappingLoader = true;
			var mappedData = [];
			angular.forEach(vm.mappedRolesList, function(mappedRole, i){
		        mappedData.push({
					SOURCE_ROLE : mappedRole.source,
					TARGET_ROLE : mappedRole.target
				});
		    });

			JdcManagerFactory.saveCopyAccess(mappedData, vm.selectedSourceActivityKey, activity.activity_key, jcd_key, vm.user_settings.user.client.client_key, vm.globalParams.tax_year, vm.globalParams.scenario).then(function (data) {
				if (data.data.callSuccess == "1") {
					vm.saveMappingLoader = false;
					AlertService.add("success", "Copy roles access saved successfully.",4000);
				} else {
					AlertService.add("error", "Problem saving mapping: " + data.data.result.message);
				}
			}, function () {
				AlertService.add("error", "Problem saving activity.",4000);
			});
        };
    };

	function getAcctivityUsersController($rootScope, $window, $scope, tasks_list, jcd_key, activity, $state, $http, ModalFactory, AlertService, GlobalService, $timeout, $uibModalInstance, JdcManagerFactory, USER_SETTINGS, $filter) {
		var vm = this;
		vm.sourceRolesList = [];
		vm.targetRolesList = activity.roles;
		vm.mappedRolesList = [];
		vm.targetActivityName = activity.activity_name;
		vm.selectedTargetRole = vm.targetRolesList[0];
        vm.tasksList = angular.copy(tasks_list);
		vm.activityList = angular.copy(activity);
		vm.jcdKey = angular.copy(jcd_key);
		vm.sourceActivityName = vm.tasksList[0].children[0].activity_name;
		vm.selectedSourceRole = vm.tasksList[0].children[0].roles;
        vm.saveMappingLoader = false;
		vm.selectedSourceActivityKey = activity.activity_key;
		vm.user_settings = USER_SETTINGS;
		vm.globalParams = GlobalService.globalParams;
		vm.userList = [];
		vm.loading = true;

        vm.title = 'Activity Users List';

        vm.cancel = function () {
            closeModal();
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.getAllActivityUserList = function(){
			JdcManagerFactory.getActivityUsers(vm.selectedSourceActivityKey, jcd_key, vm.user_settings.user.client.client_key, vm.globalParams.tax_year, vm.globalParams.scenario).then(function (data) {
				if (data.data.callSuccess == "1") {
					console.log(data);
					vm.userList = data.data.jsonObject;
					vm.loading = false;
					//vm.saveMappingLoader = false;
					//AlertService.add("success", "Copy roles access saved successfully.",4000);
				} else {
					AlertService.add("error", "Problem saving mapping: " + data.data.result.message);
				}
			}, function () {
				AlertService.add("error", "Problem saving activity.",4000);
			});
        };

		vm.getAllActivityUserList();
	}

	function activityTemplateAssignController($rootScope, $scope, $filter, $state, $log, JsonObjectFactory, AlertService, $timeout, $uibModal, $uibModalInstance, JdcManagerFactory, JCDFactory,GENERAL_CONFIG,USER_SETTINGS, WorkflowTCFactory, AdminFactory, TemplateAssignFactory, modalData, GlobalService) {
		var vm = this;
		vm.user_settings = USER_SETTINGS;
		vm.globalParams = GlobalService.globalParams;
		vm.userList = [];
		vm.rolesTemplateMapping = [];
		vm.crudLoading = false;

		vm.allTemplates = modalData.templatesData;
		vm.allRoles = modalData.roles;
		vm.activity = modalData.activity;

		vm.selectedTemplate = vm.allTemplates[0];
		vm.selectedRole = vm.allRoles[0];

        vm.title = 'Activity Users List';

		vm.addMapping = function(){
			var tempMapping = {
				"template": vm.selectedTemplate,
				"role": vm.selectedRole
			}
			vm.rolesTemplateMapping.push(tempMapping);
		}

		vm.deleteMap = function(i){
			vm.rolesTemplateMapping.splice(i, 1);
		}

        vm.cancel = function () {
            closeModal();
        };

		vm.clearForm = function(){
			vm.rolesTemplateMapping = [];
		};

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.saveRolesTemplateMapping = function(){
			var template_role_map = [];
			var temp_obj = {
				template_key: "",
				jcd_ta_role_key: ""
			}
			angular.forEach(vm.rolesTemplateMapping, function(map){
				temp_obj.template_key = map.template.TEMPLATE_KEY;
				temp_obj.jcd_ta_role_key = map.role.jcd_ta_role_key;
                template_role_map.push(temp_obj);
				temp_obj = {};
            });

			JdcManagerFactory.saveMapping(vm.activity, template_role_map).then(function (data) {
				if (data.data.callSuccess == "1") {
					vm.crudLoading = false;
					AlertService.add("success", "Activity template mapping done successfully", 4000);
				} else {
					AlertService.add("error", "Problem saving mapping: " + data.data.result.message);
				}
			}, function () {
				AlertService.add("error", "Problem saving activity.", 4000);
			});
        };

		// vm.getAllActivityUserList();
	}

	return controllers;
});
