
define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.ReclassTriggerService', [])

        .factory('ReclassTriggerFactory', ['$q', '$http', '$log', '$rootScope', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'GENERAL_CONFIG', '$filter', function ($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, $filter) {
            var ReclassTriggerFactory = { url: {} };
            ReclassTriggerFactory.saveDetails = function (_data, Details) {
                ReclassTriggerFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ydu4pa";
                ReclassTriggerFactory.process_name = "Auto_reclass_163j";

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings = JSON.stringify(_data);
                var isIssueKeyEnabled = GlobalService.globalParams.is_issue_key_enabled;
                var scenarioCode = GlobalService.globalParams.scenarioCode;
            
                var params = filterParams;
                params.combination_key = _data.combination_key;
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.extend({ clob_data: jsonObj }, params);
                params = _.extend({ clob_settings: jsonSettings }, params);
                params.process_name = ReclassTriggerFactory.process_name;
                params.scenarioCode = scenarioCode;
                params.is_issue_key_enabled = isIssueKeyEnabled;
                var promise = $http({
                    method: "post",
                    url: ReclassTriggerFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };
            return ReclassTriggerFactory;
        }])

    return services;

});