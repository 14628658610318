
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.form1118ConfigServices',[])

        .factory("Form1118ConfigFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService,workspaceFactory, USER_SETTINGS,$rootScope) {
            var form1118ConfigFactory = {};
            form1118ConfigFactory.url ={};
            //form1118Factory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wotipj";
            form1118ConfigFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vb6ugk";            
            
            form1118ConfigFactory.save = function(_data,Details,otherInfo){
            	
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);                
            console.log(jsonSettings);
                
            var params =  {};
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params =  _.extend({sso_id:otherInfo.sso_id,tax_year:otherInfo.tax_year,
                	scenario:otherInfo.scenario,jcd_key:otherInfo.jcd_key,
                	screen_key:otherInfo.screen_key}, params);
            console.log(params);
            params.process_name =  "FORM1118_CONFIG_SAVE";
                
            var promise = $http({
                    method: "post",
                    url: form1118ConfigFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });
                return promise;
            };
            
            return form1118ConfigFactory;
        }])


    return services;


});