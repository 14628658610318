define([
    'angular',
    './IssuesCtrl',
    './IssuesService'
], function () {
    'use strict';
    return angular.module('app.adminIssues', ['app.issuesCtrl', 'app.issuesService'])
        .config(['$stateProvider', '$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider, $urlRouterProvider, GENERAL_CONFIG) {
            // $stateProvider.state('issue-mgmt', {
            //                   url: '/issue-management',
            //                   templateUrl: 'app/components/issueManagement/issue-add-edit-tpl.html',
            //                   type: '',
            //                   backdrop: 'static',
            //                   api: {"get": GENERAL_CONFIG.base_url + "/loadGridJson?action_id=30145&grid_id=5576", "grid_id": "5576"},
            //                   access: 3
            //               })
            //               .state("issue-mgmt.issue", {
            //                   params: {
            //                       data: null
            //                   },
            //                   onEnter: ['$rootScope', '$uibModal', '$state', function ($rootScope, $uibModal, $state) {
            //                       $uibModal.open({
            //                           templateUrl: 'app/components/issueManagement/issue-add-edit-tpl.html',
            //                           controller: 'issueEditCtrl as ctrl',
            //                           backdrop: 'static',
            //                           size: "lg",
            //                           windowClass: 'right fade'
            //                       }).result.then(function (refresh) {
            //                           $state.go('issue-mgmt').then(() => {
            //                               if (refresh) $rootScope.$broadcast('issuesGridUpdate');
            //                           });
            //                       });
            //                   }],
            //                   abstract: true
            //               })
            //               .state("issue-mgmt.issue.edit", {
            //                   parent: 'issue-mgmt.issue',
            //                   access: 3
            //               });
        }])
        // async validators
        .directive('isValidIssueShortDesc', ['$http', '$q', 'GENERAL_CONFIG', function ($http, $q, GENERAL_CONFIG) {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {
                    ngModel.$asyncValidators.isValidIssueShortDesc = function (modelValue, viewValue) {
                        var ctrl = scope.ctrl;
                        // skip this validation if dependent values are missing
                        // if (!ctrl.selectedTaxYear || !ctrl.selectedType) return $q.resolve();
                        // also skip validation if viewValue/modelValue is not changed
                        if (ctrl.rowData && (ctrl.rowData.issue_short_desc === viewValue)) return $q.resolve();
                        if (ctrl.data.filter(i => i.issue_short_desc === viewValue).length > 0) {
                            return $q.reject();
                        }
                        return $q.resolve();
                        // return $http.get(GENERAL_CONFIG.base_url + '/isValidIssueShortDesc', {
                        //     params: {
                        //         tax_year: ctrl.selectedTaxYear.value,
                        //         scenario: ctrl.selectedType.scenario,
                        //         issue_short_desc: viewValue
                        //     }
                        // }).then(
                        //     function (response) {
                        //         if (!response.data) {
                        //             return $q.reject();
                        //         }
                        //         return true;
                        //     }
                        // );
                    };
                }
            };
        }])
        .directive('isValidIssueDetailedDesc', ['$http', '$q', 'GENERAL_CONFIG', function ($http, $q, GENERAL_CONFIG) {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {
                    ngModel.$asyncValidators.isValidIssueDetailedDesc = function (modelValue, viewValue) {
                        var ctrl = scope.ctrl;
                        // skip this validation if dependent values are missing
                        //   if (!ctrl.selectedTaxYear || !ctrl.selectedType) return $q.resolve();
                        // also skip validation if viewValue/modelValue is not changed
                        if (ctrl.rowData && (ctrl.rowData.issue_desc === viewValue)) return $q.resolve();
                        if (ctrl.data.filter(i => i.issue_desc === viewValue).length > 0) {
                            return $q.reject();
                        }
                        return $q.resolve();
                        //   return $http.get(GENERAL_CONFIG.base_url + '/isValidIssueDesc', {
                        //       params: {
                        //           tax_year: ctrl.selectedTaxYear.value,
                        //           scenario: ctrl.selectedType.scenario,
                        //           issue_desc: viewValue
                        //       }
                        //   }).then(
                        //   function (response) {
                        //       if (!response.data) {
                        //           return $q.reject();
                        //       }
                        //       return true;
                        //   }
                        //   );
                    };
                }
            };
        }]);
});
