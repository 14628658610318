define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.FormScheduleMCtrl', [])
        .controller('F1120LSCHDMController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS',F1120LSCHDMController]);
    function F1120LSCHDMController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        vm.modalTitle = "Schedule M  Other Information (see instructions)";
		vm.formListOriginal = [];
        vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';
        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME,
            "LE Type": rowData.LE_TYPE,
            "ME": rowData.ME
        };

		var saveParamDtls = [];
		var tempDataObj = {};
		$scope.scheduleMFormView = {rows:[]};
        vm.showFormLoader = true;
        vm.batchLoader = false;
        vm.showBatchData = false;
        vm.showRequestLoader = true;

        function fetchSchMrecords() {
            var params = { "action_id": 603, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ "combination_key": rowData.COMBINATION_KEY}

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
				for(var i=1;i<data.irsForm.formItemList.length;i++){
					saveParamDtls = [];
					tempDataObj = {};
					saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
					for(var j=0;j<data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;j++){
						if (j==0){
							tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
						} else if (j==1){
							tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
						}else if (j==2){
							tempDataObj.lineAttrKey = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
							tempDataObj.attrib_name = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
						    tempDataObj.attrib_order_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null){
                                tempDataObj.value = 0;
                                if(tempDataObj.num == "1b" ||tempDataObj.num == "6" ||tempDataObj.num == "15"){
                                    tempDataObj.conditionalField = true;
                                }else{
                                    tempDataObj.conditionalField = false;
                                }
                            }else if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value.toLowerCase() === "true"){
                                tempDataObj.value = true;
                                if(tempDataObj.num == "1b" ||tempDataObj.num == "6" ||tempDataObj.num == "15"){
                                    tempDataObj.conditionalField = false;
                                }
                            }else if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value.toLowerCase() === "false"){
                                tempDataObj.value = false;
                                if(tempDataObj.num == "1b" ||tempDataObj.num == "6" ||tempDataObj.num == "15"){
                                    tempDataObj.conditionalField = true;
                                }
                            }else if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == 'NA'){
                                tempDataObj.value = 'NA';
                                if(tempDataObj.num == "1b" ||tempDataObj.num == "6" ||tempDataObj.num == "15"){
                                    tempDataObj.conditionalField = true;
                                }else {
                                    tempDataObj.conditionalField = false;
                                }
                            }else {
                                tempDataObj.value = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                            }
						} else if(j==3){
							tempDataObj.lineAttrKey2 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
							tempDataObj.attrib_name2 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            tempDataObj.value_user_input = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
						}else if(j==4){
							tempDataObj.lineAttrKey3 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
							tempDataObj.attrib_name3 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_c = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            tempDataObj.value_user_input3 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
						}else {
							tempDataObj.lineAttrKey4 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
							tempDataObj.attrib_name4 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_d = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            tempDataObj.value_user_input4 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
						}
					}
                    tempDataObj.line_order = saveParamDtls[8];
					tempDataObj.saveParams = saveParamDtls;
					$scope.scheduleMFormView.rows.push(tempDataObj);
				}
                if($scope.scheduleMFormView.rows[15].value == true){
                    toggleConditionalField([16,17,18], false);
                }else{
                    toggleConditionalField([16,17,18], true);
                }
                if($scope.scheduleMFormView.rows[20].value == true){
                    toggleConditionalField([21,22], false);
                }else{
                    toggleConditionalField([21,22], true);
                }
                if($scope.scheduleMFormView.rows[23].value == true){
                    toggleConditionalField([24,25,26], false);
                }else{
                    toggleConditionalField([24,25,26], true);
                }
                if($scope.scheduleMFormView.rows[40].value == true){
                    toggleConditionalField([40], false);
                }else{
                    toggleConditionalField([40], true);
                }
				vm.formListOriginal = angular.copy($scope.scheduleMFormView);
				initFormView($scope.scheduleMFormView);
                vm.showFormLoader = false;
            });
        };

        function toggleConditionalField(arr, flag){
            angular.forEach(arr, function (value, key) {
                $scope.scheduleMFormView.rows[value].conditionalField = flag;
                if(flag){
                    $scope.scheduleMFormView.rows[value].value_user_input = "";
                }
            });
        };

        function fetchSchMimports() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "status": 'Y'
            };

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=wwledr', params).then(function (data) {
                vm.BatchList = data.jsonObject;
                angular.forEach(vm.BatchList, function (value, key) {
                    vm.BatchList[key].TOGGLE = false;
                });
                vm.showRequestLoader = false;
            });
        };

        function fetchGoldData() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "status": 'Y'
            };

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=n887j5', params).then(function (data) {
                vm.GoldData = data.jsonObject;
            });
        };

        fetchSchMrecords();
        fetchSchMimports();
        fetchGoldData();

        function loadTransactions(index, trans_key){
            vm.batchLoader = true;
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "transaction_key": trans_key
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=l6gzle', params).then(function (data) {
                var tempData = data.jsonObject;
                var currLineNo;
                var tempBatchList = {};
                var tempBatchData = [];
                angular.forEach(tempData, function (value, key) {
                    if(key == 0){
                        currLineNo = tempData[key].LINE_NO;
                        tempBatchList = tempData[key];
                    }else{
                        if(tempData[key].LINE_NO == currLineNo){
                            tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                            tempBatchData.push(tempBatchList);
                            tempBatchList = {};
                        }else{
                            currLineNo = tempData[key].LINE_NO;
                            tempBatchData.push(tempBatchList);
                            tempBatchList = {};
                            tempBatchList = tempData[key];
                        }
                    }
                });
                vm.batchLoader = false;
                vm.showBatchData = true;
                vm.BatchListData = tempBatchData;
            });
        };
        var prev_index = null;
        vm.showUploads = function(index, trans_key){
            if(prev_index !== null){
                if(prev_index !== index){
                    vm.BatchList[prev_index].TOGGLE = false;
                    prev_index = index;
                    vm.showBatchData = false;
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    loadTransactions(index, trans_key);
                }else{
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    vm.showBatchData = true;
                }
            }else{
                prev_index = index;
                vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                loadTransactions(index, trans_key);
            }
        };

        $scope.goldNavigation = function (){
            vm.activeTab = 4;
            vm.tabs[3].disabled = false;
        };

        $scope.fieldVisibility = function (index, state, name){
            if(index == 1){
                if( $scope.scheduleMFormView.rows[index].value) {
                    $scope.scheduleMFormView.rows[2].conditionalField= true;
                    $scope.scheduleMFormView.rows[2].value=false;
                    $scope.scheduleMFormView.rows[2].value_user_input="";
                }
            }else if(index == 2 ){
                if( $scope.scheduleMFormView.rows[index].value) {
                    $scope.scheduleMFormView.rows[2].conditionalField= false;
                    $scope.scheduleMFormView.rows[1].value=false;
                }else{
                    $scope.scheduleMFormView.rows[2].conditionalField = !$scope.scheduleMFormView.rows[2].conditionalField;
                }
            } else if (index == 6 || index == 7){
                if(index == 6 && $scope.scheduleMFormView.rows[index].value){
                    $scope.scheduleMFormView.rows[7].value=false;
                } else {
                    $scope.scheduleMFormView.rows[6].value=false;
                }
            }else if (index == 9 || index == 10){
                if(index == 9 && $scope.scheduleMFormView.rows[index].value){
                    $scope.scheduleMFormView.rows[10].value=false;
                } else {
                    $scope.scheduleMFormView.rows[9].value=false;
                }
            }else if(index == 15){
                checkBoxToggle(index, name, state, [16,17,18], "value", "attrib_name");
            }else if(index == 19){
                checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
                $scope.scheduleMFormView.rows[index].value_user_input3 = "";
                $scope.scheduleMFormView.rows[index].value_user_input4 = "";
            }else if(index == 35){
                checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
            }else if(index == 20){
                checkBoxToggle(index, name, state, [21,22], "value", "attrib_name");
            }else if(index == 23){
                checkBoxToggle(index, name, state, [24,25,26], "value", "attrib_name");
            }else if(index == 40){
                checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
            }else {
                $scope.scheduleMFormView.rows[index].conditionalField = !$scope.scheduleMFormView.rows[index].conditionalField;
                if((name == 'yes' && !state) || (name == 'no' && state)){
                    $scope.scheduleMFormView.rows[index].value = 'NA';
                };
            }
        };

        function checkBoxToggle (index, name, state, arr, val, attr){
            if((name == 'yes' && !state) || (name == 'no' && state)){
                $scope.scheduleMFormView.rows[index].value = 'NA';
                if(name == 'yes' && !state){
                    angular.forEach(arr, function (value, key) {
                        $scope.scheduleMFormView.rows[value].conditionalField = true;
                    });
                }
            }else{
                toggleCondition(arr, state, val, attr);
            }
        };

        function toggleCondition (index, state, type, attr){
            angular.forEach(index, function (value, key) {
                if(($scope.scheduleMFormView.rows[value].conditionalField && !state) || (!$scope.scheduleMFormView.rows[value].conditionalField && state)){
                    return;
                }else {
                    $scope.scheduleMFormView.rows[value].conditionalField = !$scope.scheduleMFormView.rows[value].conditionalField;
                }
            });
            if(state){
                angular.forEach(index, function (value, key) {
                    $scope.scheduleMFormView.rows[value][type] = vm.GoldData[0][$scope.scheduleMFormView.rows[value][attr]];
                });
            }else{
                angular.forEach(index, function (value, key) {
                    $scope.scheduleMFormView.rows[value][type] = "";
                });
            }
        };

        vm.saveSchddForm = function (url, data){
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO,
                "jcd_key": rowData.JCD_KEY,
                "combination_key": rowData.COMBINATION_KEY,
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
					$rootScope.$emit('gridUpdate', args);
					AlertService.add("success", "Transactions saved successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                    }
				}
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function(){
            var formRowDataA = {};
            var formRowDataB = {};
            var formRowDataC = {};
            var formRowDataD = {};
            var tempArray = [];

            for(var i=0; i<$scope.scheduleMFormView.rows.length ;i++){
                formRowDataA = {
                    tax_year: rowData.TAX_YEAR,
                    trans_type_key: $scope.scheduleMFormView.rows[i].saveParams[1],
                    line_order: $scope.scheduleMFormView.rows[i].line_order,
                    attrib_order: $scope.scheduleMFormView.rows[i].attrib_order_a,
                    line_description: $scope.scheduleMFormView.rows[i].description,
                    combination_key: rowData.COMBINATION_KEY,
                    group_obj_key: rowData.GROUP_OBJ_KEY,
                    form_key: $scope.scheduleMFormView.rows[i].saveParams[2],
                    line_no: $scope.scheduleMFormView.rows[i].num,
                    occurence: $scope.scheduleMFormView.rows[i].saveParams[7],
                    line_attrib_key: $scope.scheduleMFormView.rows[i].lineAttrKey,
                    Attribute_Name: $scope.scheduleMFormView.rows[i].attrib_name,
                    Attribute_Value: $scope.scheduleMFormView.rows[i].value ? $scope.scheduleMFormView.rows[i].value.toString() : $scope.scheduleMFormView.rows[i].value == false ? $scope.scheduleMFormView.rows[i].value.toString() : '',
                    trans_dtls_key: $scope.scheduleMFormView.rows[i].saveParams[3],
                    trans_status: $scope.scheduleMFormView.rows[i].saveParams[6],
                    is_dirty: ''
                };
                tempArray.push(formRowDataA);
                if(typeof $scope.scheduleMFormView.rows[i].value_user_input !== "undefined"){
                    formRowDataB = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleMFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleMFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleMFormView.rows[i].attrib_order_b,
                        line_description: $scope.scheduleMFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleMFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleMFormView.rows[i].num,
                        occurence: $scope.scheduleMFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleMFormView.rows[i].lineAttrKey2,
                        Attribute_Name: $scope.scheduleMFormView.rows[i].attrib_name2,
                        Attribute_Value: $scope.scheduleMFormView.rows[i].value_user_input ? $scope.scheduleMFormView.rows[i].value_user_input.toString() : '0',
                        trans_dtls_key: $scope.scheduleMFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleMFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    tempArray.push(formRowDataB);
                }
                if(typeof $scope.scheduleMFormView.rows[i].value_user_input3 !== "undefined"){
                    formRowDataC = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleMFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleMFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleMFormView.rows[i].attrib_order_c,
                        line_description: $scope.scheduleMFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleMFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleMFormView.rows[i].num,
                        occurence: $scope.scheduleMFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleMFormView.rows[i].lineAttrKey3,
                        Attribute_Name: $scope.scheduleMFormView.rows[i].attrib_name3,
                        Attribute_Value: $scope.scheduleMFormView.rows[i].value_user_input3 ? $scope.scheduleMFormView.rows[i].value_user_input3.toString() : '',
                        trans_dtls_key: $scope.scheduleMFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleMFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    tempArray.push(formRowDataC);
                }
                if(typeof $scope.scheduleMFormView.rows[i].value_user_input4 !== "undefined"){
                    formRowDataD = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleMFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleMFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleMFormView.rows[i].attrib_order_d,
                        line_description: $scope.scheduleMFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleMFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleMFormView.rows[i].num,
                        occurence: $scope.scheduleMFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleMFormView.rows[i].lineAttrKey4,
                        Attribute_Name: $scope.scheduleMFormView.rows[i].attrib_name4,
                        Attribute_Value: $scope.scheduleMFormView.rows[i].value_user_input4 ? $scope.scheduleMFormView.rows[i].value_user_input4.toString() : '',
                        trans_dtls_key: $scope.scheduleMFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleMFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    tempArray.push(formRowDataD);
                }
            }
            vm.formData.editrows.push(tempArray);

            vm.mainData = [];
            vm.mainData.push(vm.formData);
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nkepph";
            vm.saveSchddForm(url,vm.mainData);

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        };

		vm.reset = function (){
			$scope.scheduleMFormView = angular.copy(vm.formListOriginal);
			$scope.scheduleMFormView1 = angular.copy(vm.formListOriginal.rows.slice(0,23));
            $scope.scheduleMFormView2 = angular.copy(vm.formListOriginal.rows.slice(23));
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function initFormView(filteredFormData) {
			$scope.scheduleMFormView = filteredFormData;
            $scope.scheduleMFormView1 = $scope.scheduleMFormView.rows.slice(0,23);
            $scope.scheduleMFormView2 = $scope.scheduleMFormView.rows.slice(23);
		};

        //Dynamic Tabset Code
        vm.tabs = [
            {id: 1, name: "Form Entry", url: "app/components/Forms/F1120L-SCH-M/Form-scheduleMEntry.html", active: true },
            {id: 2, name: "Form View", url: "app/components/Forms/F1120L-SCH-M/Form-scheduleMView.html", active: false},
            {id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-M/Form-scheduleMVersion.html", active: false},
            {id: 4, name: "GOLD", url: "app/components/Forms/F1120L-SCH-M/Form-scheduleMGold.html", active: true, disabled: true}
            // {id: 5, name: "PDF", url: "app/components/Forms/F1120L-SCH-M/Form-scheduleMPdf.html", active: false, disabled: true}
        ];
    }
});
