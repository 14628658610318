
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.ConsolidationLedgersService',[])

    .factory('ConsolidationLedgersServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	var ConsolidationLedgersServiceFactory = {};
        ConsolidationLedgersServiceFactory.url = {};
        ConsolidationLedgersServiceFactory.url.defaults_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fe5q9h";

        ConsolidationLedgersServiceFactory.saveConsLedgers = function(_data,Details){

        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        var jsonObj = JSON.stringify(Details);
        var jsonSettings =  JSON.stringify(_data);
        //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
        
        console.log(jsonSettings);
        var params = filterParams;
        params = _.merge({},  GlobalService.globalParams , params);
        params =  _.extend({clob_data:jsonObj}, params);
        params =  _.extend({clob_settings:jsonSettings}, params);
        params.process_name =  "SAVE_CONSOL_LEDGER_CATG";
        console.log(params);
        var promise = $http({
            method: "post",
            url: ConsolidationLedgersServiceFactory.url.defaults_save,
            data: params
        }).then(function (response) {
            var status = response.status;
            return response;
        });
        return promise;
    };
    
    return ConsolidationLedgersServiceFactory
    	
    }])

    return services;

});