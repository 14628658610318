/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './WorkflowController',
    './WorkflowService',
    'd3',
    'c3'


], function () {
    'use strict';
    var   $workflowState =null;
    var workflowModule = angular.module('app.Workflow', ['app.WorkflowController','app.WorkflowService'])
     //return angular.module('app.codeCombinations',[]);

        .provider('$workflowState', ['$stateProvider',function($stateProvider){
            this.$get = function(){

                return {
                    addState: function(stateName,url,controllerAs, templatePrefix,api,gridType,params,views) {
                        $workflowState.state('workflow.' + stateName, {
                            url: '/' + url,
                            type: gridType,
                            "api": api,
                            templateUrl: templatePrefix ? templatePrefix : null,
                            controller: controllerAs ? controllerAs : null,
                            params:params,
                            resolve: {
                            },
                            views:views
                        });
                    }
                }
            }
        }])
       .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
        $workflowState = $stateProvider;
           $stateProvider

               .state('workflow', {
                   url: '/workflow',
                   templateUrl: 'app/components/workflow/index.html',
                   access: ""
               })
            //    .state('workflow.search', {
            //        url: '/search',
            //        templateUrl: 'app/components/workflow/templates/search.html',
            //        "controller": "SearchEntityController as ctrl",
            //        access: ""
            //    }).state('workflow.guide', {
            //        url: '/guide',
            //        templateUrl: 'app/components/workflow/templates/guide-tpl.html',
            //        access: ""
            //    }).state('workflow.dashboard', {
            //        url: '/dashboard',
            //        templateUrl: 'app/components/workflow/templates/dashboard-tpl.html',
            //        access: ""
            //    })
               .state('workflow.list', {
                   url: '/list',
                   "templateUrl": "app/components/workflow/templates/list-tpl.html",
                   "controller": "EntityListCtrl as ctrl",
                   type:'grid',
                   "api": {"get":GENERAL_CONFIG.base_url+"/loadGridJson?action_id=30837&grid_id=4"},
                   "noFilters":true,
                   access: ""
               }).state('workflow.entity', {
                   url: '/entity/:id',
                  // data:'entity',
                   templateUrl: ' app/components/workflow/templates/entity/entity.html',
                   access: ""
               })

               .state('workflow.entity.workflow', {
                   url: '/workflow',
                //    url: '/workflow/:listview',
                //    params: {
                //        listview: 'false'
                //    },
                   templateUrl: 'app/components/workflow/templates/entity/workflow-entity-tpl.html',
                //    function($stateParams) {
                //         if ($stateParams && $stateParams.listview === "true") {
                //             return 'app/components/workflow/templates/entity/workflow-entity-tpl.html';
                //         }
                //         return 'app/components/workflow/templates/entity/workflow-steps-tpl.html';
                //    },
                   access: ""
               })

               .state('workflow.entity.workflow.details', {
                    url: '/:catKey/:compKey/:taxYear/:scenario?tab',
                   templateUrl: 'app/components/workflow/templates/entity/workflow-details-tpl.html',
                   access: '',
                   controller: 'workflowDetailsCtrl',
                   resolve: {
                   }

               })
               .state('workflow.entity.workflow.details.workspace', {
               // url: '/:catKey/:compKey/:workspaceName',
                url: '/:workspaceName',
                templateUrl: 'app/components/grid/grid-standard-tpl.html',
                access: '',
                controller: 'GirdStandardController as ctrl',
                type: 'grid-import',
              //  api:{get:"http://localhost:8085/gtw/loadGridJson?grid_id=106&action_id=383"},
               "api":'workspaceName==="workspace.trial-balance-batch-import"'?
                        {"get": GENERAL_CONFIG.base_url+"/emptyGridJson?grid_id=106","save":GENERAL_CONFIG.base_url+"/gridBatchImportValidation?action_id=382"}:
                        '{"get":"http://localhost:8085/gtw/loadGridJson?grid_id=109&action_id=383"}',
               // "api":'api',
                resolve: {
                }

            })

               .state('workflow.entity.entity_details', {
                   url: '/entity_details',
                   templateUrl: 'app/components/workflow/templates/entity/details-tpl.html',
                   access: ""
               })

               .state('workflow.entity.ownership', {
                   url: '/ownership',
                   templateUrl: 'app/components/workflow/templates/entity/ownership-tpl.html',
                   access: ""
               })
               .state('workflow.entity.documents', {
                   url: '/documents',
                   templateUrl: 'app/components/workflow/templates/entity/documents-tpl.html',
                   access: ""
               })


        //     .state('workflow.entity.comments', {
        //        url: '/comments',
        //        templateUrl: 'app/components/workflow/templates/entity/comments-tpl.html',
        //        access: ""
        //    })
        //    .state('workflow.entity.preview', {
        //         url: '/preview',
        //         templateUrl: 'app/components/workflow/templates/entity/preview-tpl.html',
        //         controller:"EntityPDFPreviewCtrl as ctrl",
        //         access: ""
        //     });
       }]);

       return workflowModule;
    }

    );