define(['angular',
        './notificationController',
        './notificationService',
       ], function(){
    "use strict";
    return angular.module('admin.notification', ['admin.notificationController','admin.notificationService'])
            .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
                $stateProvider
                .state('admin.notification', {
                    url: '/notification',
                    templateUrl: 'app/components/admin/notification/notification-tpl.html',
                    backdrop: 'static',
                    // controller: ' as ctrl',
                    type:'custom',
                    api:{},
                    access: 4
                })
                .state("admin.notification.payload-details", {
                    params: {
                        data: null
                    },
                    onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {
                        $uibModal.open({
                            templateUrl: 'app/components/admin/notification/process-console-details.html',
                            controller: 'payloadDetail as ctrl',
                            backdrop: 'static',
                            size:"lg",
                            windowClass: 'right fade'
                        }).result.finally(function() {
                            $state.go('admin.notification');
                            // $rootScope.$emit('gridRefresh');
                        });
                    }],
                     abstract: false
                 })
            }])
});
