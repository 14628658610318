define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.regenerationProcessController', [])

        .controller('regenerationProcessController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter', 'regenerationProcessServiceFactory', regenerationProcessController]);
    	function regenerationProcessController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter, regenerationProcessServiceFactory) {
        var vm = this;

        vm.modalTitle = "Regeneneration Process";
		vm.saveloader = false;
		vm.tags = [];
		vm.leKey = [];
		vm.dataLevel = [];
		vm.filingGroupKey = [];
		angular.forEach(gridData, function(value, key) {
			vm.tags.push(value.data.LEID);
			vm.leKey.push(value.data.LE_KEY);
			vm.dataLevel.push(value.data.DATA_LEVEL);
			vm.filingGroupKey.push(value.data.FILING_GROUP_KEY);
		});

		vm.regenerateProcess = function() {
			vm.saveloader = true;
			vm.showJsonLoader = true;
            var params = {"tax_year": rowData.TAX_YEAR, "le_key": vm.leKey.toString(), "data_level": vm.dataLevel.toString(), "filing_group_key": vm.filingGroupKey.toString() };

			return regenerationProcessServiceFactory.saveRegenerateProcess(params).then(function (data) {
				if(data.data.callSuccess == "1" ){
					vm.saveloader = false;
					AlertService.add("success", "Process Regenerated Successfully" , 4000);
					vm.cancel();
				}else{
					vm.saveloader = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        }

		vm.removeTag = function (index) {
			vm.tags.splice(index, 1);
	  	};

		vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		
    }
});