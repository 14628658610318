define(
		[ 'angular'
/*			,'./BeatService',*/
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.BeatCtrl', ['app.BeatService'])
					.directive("limitToMaxbeat", function() {
						return {
								link: function(scope, element, attributes) {
										var count = 0;
										element.bind('keypress', function(e) {
												console.log()
												if (!(e.charCode >= 46 && e.charCode <= 57) || e.charCode === 47) {
													e.preventDefault();
												}
												var itemValue = e.charCode !== 0 ? String.fromCharCode(e.charCode) : '';
												var actualValue = $(this).val().split('');
												var newVal = actualValue.splice(this.selectionStart, 0, itemValue).join();
												//$(this).val()+(e.charCode!==0?String.fromCharCode(e.charCode):'');

												if (String.fromCharCode(e.charCode) === ".") {
													if ($(this).val().indexOf(".") != -1) {
														e.preventDefault();
													}
												}

												if ($(this).val().search(/(.*)\.[0-9][0-9]/) === 0 && newVal.length > $(this).val().length) {
													e.preventDefault();
												}
											});
										
										element.on("keyup", function(e) {
												var decimalData = element[0].value; //element.val();
																					
												function countDecimalPlaces() {
													var decimalPos = String(decimalData).indexOf('.');
														if (decimalPos === -1) {
															return 0;
														} else {
															return String(decimalData).length - decimalPos - 1;
														}
												}



												// removeExtraDots();
												if (countDecimalPlaces() > 2) {
													element.val(Number(decimalData).toFixed(2))
												}
												if (Number(element.val()) > Number(attributes.max) 
														/*&&
                    									e.keyCode != 46 // delete
                    										&&
                    									e.keyCode != 8 // backspace */
													) {
													e.preventDefault();
													element.val(attributes.max);
												}
												
												if(decimalData <= 0 || decimalData > 100){
												 //	AlertService.add("error", "Please select between 1 to 100 only");
								                   // return;
												// element[0].value = 30;                    
											}

											});
										
										
											
											}
										};
									})  

					.controller('beatData',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
									'$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','USER_SETTINGS','workspaceFactory','$filter','InterestExpenseFactory','$http', beatCtrl ])

			function beatCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, $filter,InterestExpenseFactory,$http) {

				var vm = this;	
				vm.selectedBeatRow = [];
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				//Show the loading Spinner
				vm.loadingData = true;	
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//A edit modal
				vm.isEditMode = false;
				//A edit modal
				vm.isAssetEditMode = false;
				vm.page;
				vm.deleteAllFlag = false;
				//Adjustment Name
				vm.adjName;
				//Me Key
				vm.meKey;
				//Financial Corporation
				vm.finCorpKey;
				//Amount
				vm.amount;
				//Comments
				vm.comments;				
				vm.beatTypeList = [];
				vm.copyBeatTypeList = [];
				var object = {};
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;
				vm.issueID = '';
				vm.isRAF = true;
                
                vm.percentInfo = [];
                
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				
				//Populating Modal based on selected row on Grid
				if (gridData != null) {					
					var tempGridData = _.clone(gridData), groups = Object.create(null), result, finalKeyCheck = 0;
					vm.gridDataMultipleSelect = _.clone(tempGridData);	
					vm.issueID = tempGridData.rowData.ISSUE_KEY;
					vm.isRAF = tempGridData.rowData.SCENARIO_TYPE_CODE == undefined || tempGridData.rowData.SCENARIO_TYPE_CODE == '' ? true : false;
					if (vm.gridDataMultipleSelect != undefined) {
						if(vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'BEAT_BY_CONSOL'){
							vm.selectedBeatRow.push(vm.gridDataMultipleSelect.rowData);			
							vm.pshipFlag = vm.selectedBeatRow[0].IS_PSHIP_TIER;
						}
					}					
				}		
				
				//Get the Trading Partner data for dropdown				
				vm.getTradingPartnerData = function() {
					var params = {
							"action_code" : 'oh7ssv',
							'p_scenario':   vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	
			                	///vm.foreignTradingPartnerCodeData = data.jsonObject;
			                	/*vm.foreignTradingPartnerCodeData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['RELATED_FGN_BEAT_YES'], p.CODE_GROUP);  
			                		});  */   
			                	
			                	vm.beatTypeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BEAT_TYPE_CODE'], p.CODE_GROUP);  
			                		});		                	
			                	vm.countryData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
			                	});
	                		    vm.foreignTradingPartnerCodeData = _.filter(data.jsonObject, function(p){
		                		   return _.includes(['RELATED_FGN_BEAT_YES'], p.CODE_GROUP);  	  
	                		    });
		                	
	                		    vm.getPopUpData();
			                }
						});					
					
				}
				
				
				//Get the Trading Partner data for dropdown				
				/*vm.getIssueIDData = function() {
					var params = {
							"action_code" : 'yhet9q',
							'p_scenario':   vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{		                	  
			                	vm.issueIDData = data.jsonObject; 
			                }
						});					
					
				}
				
				vm.getIssueIDData();*/
				
				vm.getPopUpData = function() {
					var params = {
							"action_code" : '57rj34',
							'p_tax_year' : vm.curentyear,
							'p_scenario':   vm.scenario,
							'p_jcd_key' :vm.jcd_key					        							
						};			
					
					if(vm.selectedBeatRow && vm.selectedBeatRow.length > 0){
						params['combination_key'] = vm.selectedBeatRow[0].COMBINATION_KEY;
						params['gie_key'] = vm.selectedBeatRow[0].GIE_KEY;
						params['is_pship_tier'] = vm.selectedBeatRow[0].IS_PSHIP_TIER;
						params['issue_id'] = vm.selectedBeatRow[0].ISSUE_KEY;
					}
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.total = 0;
			                	vm.totalBeatPayment = 0;
			                	vm.beatCCDetails = [];
			                	vm.beatCCDetails = data.jsonObject;
			                	
			                	
			              
			                	if(vm.beatCCDetails && vm.beatCCDetails.length > 0 ){
									for(var t in vm.beatCCDetails){
										var myObject = {};
										var newTradingpartnerObject = {};
										myObject["amount"] = vm.beatCCDetails[t].ADJ_AMT;
										myObject["beat_payment"] = vm.beatCCDetails[t].BEAT_PAYMENT;
										myObject["beatWhPercent"] = vm.beatCCDetails[t].ADJ_PERCENT;										
										myObject["oldAmount"] = vm.beatCCDetails[t].ADJ_AMT;
										myObject["oldBeatWhPercent"] = vm.beatCCDetails[t].ADJ_PERCENT;
										myObject["selectedBeatTypeCode"] = vm.beatCCDetails[t].ADJ_TYPE_KEY ;
										/*var exists = _.find(vm.foreignTradingPartnerCodeData, ['COMBINATION_KEY', parseInt(vm.beatCCDetails[t].COMBINATION_KEY)]);
				                		if(exists != undefined){
				                			
				                		}*/
				                		myObject["selectedTradingPartnerCode"] = vm.beatCCDetails[t].COMBINATION_KEY;
				                		myObject["selectedTradingPartner"] = vm.beatCCDetails[t].LEID;
				                		
				                		
									////	myObject["selectedTradingPartnerCode"] = vm.beatCCDetails[t].LE_NAME ;
										myObject["adjKey"] = vm.beatCCDetails[t].ADJ_KEY;
										myObject["OPERATION_TYPE"] = 'U';	
										myObject["selectedCountryCode"] = vm.beatCCDetails[t].LE_COUNTRY;
										myObject["groupKey"] = vm.beatCCDetails[t].GROUP_KEY;
										myObject["adjKey"] = vm.beatCCDetails[t].ADJ_KEY;
										myObject["comments"] = vm.beatCCDetails[t].COMMENTS;
										myObject["issueID"] = vm.beatCCDetails[t].ISSUE_KEY;
										 
									///	myObject["selectedCountryCode"] = ''+vm.beatCCDetails[t].SOURCE_CODE ;
					                	vm.beatTypeList.push(myObject);
					                	
					                	vm.groupKey = vm.beatCCDetails[t].GROUP_KEY;
					                	vm.total = parseInt(vm.total) + parseInt(vm.beatCCDetails[t].ADJ_AMT);
					                	vm.totalBeatPayment = parseInt(vm.totalBeatPayment) + parseInt(vm.beatCCDetails[t].BEAT_PAYMENT);
					                	vm.isEditMode = true;
									}
									vm.copyBeatTypeList = angular.copy(vm.beatTypeList);
									vm.loadingData = false;
								}else{
									object.selectedBeatTypeCode='';
									object.selectedTradingPartnerCode='';
									object.selectedTradingPartner='';
									object.selectedCountryCode ='';
									object.amount = '';
									object.beat_payment = '';
									object.beatWhPercent = '';
									object.oldAmount = 0;
									object.oldBeatWhPercent = 0;
									object.comments = '';
				                	object["OPERATION_TYPE"] = 'I';	
				                	object.issueID = vm.issueID;
				                	vm.beatTypeList.push(object); 
				                	vm.loadingData = false;								
								}			               
			                }
						});
					
					
				}
				
				
				// This will populate the Country Dropdown with the COUNTRY of selected trading partner.			
				vm.changeTradingPartner = function(data){					
					var exists = _.find(vm.foreignTradingPartnerCodeData, ['LE_NAME', data.selectedTradingPartner]);
                	if(exists != undefined){
                		vm.selectedCountry = exists.LE_COUNTRY;
                		vm.selectedTradingPartnerLEID = exists.COMBINATION_KEY;
                	}
					
					var exists = _.find(vm.countryData, ['CODE_SHORT_DESC', vm.selectedCountry]);
					if(exists != undefined){
						data.selectedCountryCode = exists.CODE_KEY;
					}else{ 
						data.selectedCountryCode ='';
					}					
				}
				
				//Update the total for every amount entered in Modal
				vm.updateTotal = function(data) {	
					vm.total =  0;
					vm.beatTypeList.forEach(function(list) {
						if (list.OPERATION_TYPE && list.OPERATION_TYPE != 'D')
						vm.total += parseInt(list.amount  ? list.amount : 0);
					})					
				}	
				
				vm.updateBeatPaymentTotal =  function(data) {	
					vm.totalBeatPayment =  0;
					vm.beatTypeList.forEach(function(list) {
						if (list.OPERATION_TYPE && list.OPERATION_TYPE != 'D')
						vm.totalBeatPayment += parseInt(list.beat_payment  ? list.beat_payment : 0);
					})					
				}	
				
				vm.changeInPercent = function(index,beatWhPercent) {
					let count = 0;
					vm.beatTypeList.forEach(info => {
						if (info && (info.beatWhPercent < 0 || info.beatWhPercent > 100)) {							
							count++;
						}
					})
					
					if (count > 0) {
						AlertService.add("error", "Please select BEAT Withhold between 0 to 100 only",5000);
						vm.isSaveClicked = true;
					} else {
						vm.isSaveClicked = false;
					}
					
				}
	   
				//Adding a new row as a copy of Selected Row
				vm.addRow = function(){
					var tempObj = _.clone(vm.beatTypeList[0]);
					tempObj.amount = "";
					tempObj.beat_payment = "";
					tempObj.oldAmount = 0;
					tempObj.beatWhPercent = "";
					tempObj.oldBeatWhPercent = 0;					
					tempObj.selectedTradingPartnerCode = "";
					tempObj.selectedTradingPartner = "";
					tempObj.selectedCountryCode = "";
					tempObj.selectedBeatTypeCode = "";
					tempObj.comments = '';
					tempObj.OPERATION_TYPE = "I";
					tempObj.adjKey = "";
					tempObj.issueID = vm.issueID;
					vm.beatTypeList.push(tempObj);
					
				};
				
				
				//Delete the selected row 
				vm.deleteRow = function(selectedRowData){					
					var index = vm.beatTypeList.indexOf(selectedRowData);
					
					if(vm.beatTypeList[index].OPERATION_TYPE != undefined || vm.beatTypeList[index].OPERATION_TYPE != null || vm.beatTypeList[index].OPERATION_TYPE != "" ){					
						if(selectedRowData.OPERATION_TYPE == 'I'){
							vm.beatTypeList.splice(index, 1);
						}else {
							vm.beatTypeList[index].OPERATION_TYPE = 'D';
						}
						var countActiveRows = _.filter(vm.beatTypeList, function(eachRow) { if (eachRow.OPERATION_TYPE != 'D') return eachRow }).length;
						if(countActiveRows == 0){
							var newRow = {};
							newRow.amount = '';
							newRow.beat_payment = '';
							newRow.beatWhPercent = '';
							newRow.selectedTradingPartnerCode = "";
							newRow.selectedTradingPartner = "";
							newRow.selectedCountryCode = "";
							newRow.selectedBeatTypeCode = "";
							newRow.comments = "";
							newRow.OPERATION_TYPE = 'I';	
							tempObj.issueID = vm.issueID;
							vm.beatTypeList.push(newRow);
						}
					} 
										
				};
				
				//Get the Code Mast Data for Dropdown
				vm.getTradingPartnerData();
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
								
				//Save the Beat Type Details along with the percent and Amount
				vm.saveBeatType = function(){
					var totalSubAmount = 0;
					for( var row in vm.beatTypeList){
						totalSubAmount = totalSubAmount + vm.beatTypeList[row].amount;
						
						if((vm.beatTypeList[row].selectedBeatTypeCode == undefined  || vm.beatTypeList[row].selectedBeatTypeCode == null  || vm.beatTypeList[row].selectedBeatTypeCode == ''
							 ||vm.beatTypeList[row].beatWhPercent == undefined  || vm.beatTypeList[row].beatWhPercent == null  || vm.beatTypeList[row].beatWhPercent.toString() == ''
								||vm.beatTypeList[row].selectedTradingPartner == undefined  || vm.beatTypeList[row].selectedTradingPartner == null  || vm.beatTypeList[row].selectedTradingPartner == ''
									||vm.beatTypeList[row].selectedCountryCode == undefined  || vm.beatTypeList[row].selectedCountryCode == null  || vm.beatTypeList[row].selectedCountryCode == ''
										|| vm.beatTypeList[row].amount == undefined  || vm.beatTypeList[row].amount == null
										//|| vm.beatTypeList[row].beat_payment == undefined  || vm.beatTypeList[row].beat_payment == null 
											) && vm.beatTypeList[row].OPERATION_TYPE != 'D' 
											//&& (vm.isRAF == false && (vm.beatTypeList[row].issueID == undefined  || vm.beatTypeList[row].issueID == null)))
											//  && ((vm.isRAF == true ) || (vm.isRAF == false && (vm.beatTypeList[row].issueID == undefined  || vm.beatTypeList[row].issueID == null)))
												)
							{
								AlertService.add("error", "Please select/enter all the required fields.",4000);
								vm.isSaveClicked = false;
								return;
						}
						
					}
					
					if (vm.selectedBeatRow[0].SUB_TOTAL >= 0 && totalSubAmount > vm.selectedBeatRow[0].SUB_TOTAL){
						AlertService.add("error", "Please enter the BEAT benefit amount less than Sub Total.",4000);
						vm.isSaveClicked = false;
						return;
					}else if (vm.selectedBeatRow[0].SUB_TOTAL < 0 && totalSubAmount < vm.selectedBeatRow[0].SUB_TOTAL){
						AlertService.add("error", "Please enter the  BEAT benefit less than Sub Total.",4000);
						vm.isSaveClicked = false;
						return;
					}
						
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var key = 0;															
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;					
					returnClobSettingsObj['tax_year'] = vm.curentyear
					returnClobSettingsObj['SCREEN_KEY'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['object_id'] = vm.gridDataMultipleSelect.rowData!=undefined ? vm.gridDataMultipleSelect.rowData.object_id : null;
					
					var beatTypeDetails = [];
					for( var row in vm.beatTypeList){	
						
						var returnObj = {};	
						returnObj['ADJ_TYPE_KEY'] = vm.beatTypeList[row].selectedBeatTypeCode;
						returnObj['ADJ_PERCENT'] = vm.beatTypeList[row].beatWhPercent;
						returnObj['AMOUNT'] = vm.beatTypeList[row].amount;
						returnObj['BEAT_PAYMENT'] = vm.beatTypeList[row].beat_payment;
						returnObj['GIE_KEY'] = vm.selectedBeatRow[0].GIE_KEY;
						returnObj['COMBINATION_KEY'] = vm.selectedBeatRow[0].COMBINATION_KEY;
						returnObj["OPERATION_TYPE"] = vm.beatTypeList[row].OPERATION_TYPE;
						returnObj['IS_SINGLE'] = 'S';
						returnObj['GROUP_KEY'] = vm.beatTypeList[row].OPERATION_TYPE == 'I' ? null : vm.beatTypeList[row].groupKey;
						returnObj['ADJUSTMENT_KEY'] = vm.beatTypeList[row].adjKey;
						returnObj['COMMENTS'] = vm.beatTypeList[row].comments;
						returnObj['FDII_KEY'] = vm.beatTypeList[row].issueID == undefined || vm.beatTypeList[row].issueID == 0? 0 : vm.beatTypeList[row].issueID;
						///let LE_NAME = _.find(vm.foreignTradingPartnerCodeData, ['LE_NAME', vm.beatTypeList[row].selectedTradingPartnerCode]);
						
						var exists = _.find(vm.foreignTradingPartnerCodeData, ['LE_NAME', vm.beatTypeList[row].selectedTradingPartner]);
	                	if(exists != undefined){
	                		returnObj['ADJUSTMENT_NAME']= exists.COMBINATION_KEY;
	                	}
						///returnObj['ADJUSTMENT_NAME'] = vm.beatTypeList[row].selectedTradingPartnerCode; ////LE_NAME ? LE_NAME.LEID:null ;
						returnObj['TRANS_TYPE'] = 'BEAT_ADJ';
						
						var message = "Beat by Consol Amount has been successfully saved/updated";
						beatTypeDetails.push(returnObj);					
					}
					//send the complete details to Service
					sendDetails(returnClobSettingsObj,beatTypeDetails, message);
					
				}
				
				//Reset the fields in Modal
				vm.reset = function(){
					vm.selectedBeatTypeCode='';
					vm.selectedTradingPartnerCode='';
					vm.selectedTradingPartner='';
					vm.selectedCountryCode ='';
					vm.amount = '';
					vm.oldAmount = 0;
					vm.beatWhPercent = "";
					vm.oldBeatWhPercent = 0;
					vm.total = 0;
					vm.totalBeatPayment = 0;
					if(!vm.isEditMode){
						//vm.beatTypeList = [];
						//vm.beatTypeList.push(object);	
						while (vm.beatTypeList.length) {
							vm.beatTypeList.pop();
						}
						var emptiee= {};
						emptiee.selectedBeatTypeCode='';
						emptiee.selectedTradingPartnerCode='';
						emptiee.selectedTradingPartner='';
						emptiee.selectedCountryCode ='';
						emptiee.amount = '';
						emptiee.beatWhPercent = '';
						emptiee.oldAmount = 0;
						emptiee.oldBeatWhPercent = 0;
						emptiee.comments = "";
	                	vm.beatTypeList.push(emptiee); 
	                	
					}else {
						vm.beatTypeList = angular.copy(vm.copyBeatTypeList);						
					}
				}
				
				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, interestAndExpDetails, message) {
					InterestExpenseFactory.saveData(returnClobSettingsObj,interestAndExpDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id: returnClobSettingsObj.object_id,
	                                gridFilter: {
	                                	object_id: returnClobSettingsObj.object_id
	                                }
	                            };
							vm.loadingData = false;
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
	

			}			
			return controllers;

		});
	
