
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.SystemMappingService',[])

    .factory('SystemMappingFactory', ['$rootScope','$http','AlertService','GlobalService','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', 
    function($rootScope,$http,AlertService,GlobalService,workspaceFactory,JsonObjectFactory,GENERAL_CONFIG){
        var systemMappingFactory = {};
     // TODO:the hard coded jcd_key in this file will be removed once the global params bug is fixed
        systemMappingFactory.list = [
            {id:1,name:"Superman",powers:"All",age:1087,height:"6'1",weight:"1200"},
            {id:2,name:"The Incredible Hulk",powers:"Strength",age:54,height:"6'3",weight:"400"},
            {id:3,name:"Batman",powers:"Inventiveness",age:44,height:"6'1",weight:"210"}
        ]


        systemMappingFactory.getList = function(){
            return systemMappingFactory.list ;
        }

        systemMappingFactory.getWFEntityMapping = function(params) {

           var promise = $http({
               method: "post",
               url: GENERAL_CONFIG.base_url + '/getWorflowEntity',
               data: params
           }).then(function (response) {
               return response;
           });   

           return promise;   	   
       };
       systemMappingFactory.getEntityByID = function(params) {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/getEntityDetailsById',
            data: params
        }).then(function (response) {
            return response;
        });   

        return promise;   	   
    };

    systemMappingFactory.fillFormPorforma = function(formName, entity, s3_url) {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/fillFormPorforma?irs_form_no='+formName+"&s3_url="+s3_url+"&entity="+entity,
            responseType: 'arraybuffer'
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };
    
    systemMappingFactory.createNewSysAccount = function(_data) {

        console.log('_data passed to delete reclass function');
        console.log(_data);
        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        var jsonObj = JSON.stringify(_data);
        var params = filterParams;
        GlobalService.globalParams.jcd_key = 250;
        params = _.merge({},  GlobalService.globalParams , params);
        params =  _.extend({jsonObj:jsonObj}, params);
          console.log("SYSTEM FORM params to delete");
          console.log(params);
          var promise = $http({
              method: "post",
              url: GENERAL_CONFIG.admin_api_url + '/createSysAcct',
              data: params
          }).then(function (response) {
              var status = response.status;
              return response.data;
          });

          return promise;

      };
      
      systemMappingFactory.editSytemAccount = function(_data) {

    	    console.log('_data passed to delete reclass function');
    	    console.log(_data);
    	    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    	    var jsonObj = JSON.stringify(_data);
    	    var params = filterParams;
    	    GlobalService.globalParams.jcd_key = 250;
    	    params = _.merge({},  GlobalService.globalParams , params);
    	    params =  _.extend({jsonObj:jsonObj}, params);
    	        console.log("SYSTEM FORM params to delete");
    	        console.log(params);
    	        var promise = $http({
    	            method: "post",
    	            url: GENERAL_CONFIG.base_url + '/editSysAcct',
    	            data: params
    	        }).then(function (response) {
    	            var status = response.status;
    	            return response.data;
    	        });

    	        return promise;

      };

      systemMappingFactory.toggleClient = function(_data) {

        console.log('_data passed to delete reclass function');
        console.log(_data);
        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        var jsonObj = JSON.stringify(_data);
        var params = filterParams;
        GlobalService.globalParams.jcd_key = 250;
        params = _.merge({},  GlobalService.globalParams , params);
        params =  _.extend({jsonObj:jsonObj}, params);
            console.log("SYSTEM FORM params to delete");
            console.log(params);
            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.admin_api_url + '/updateSysAcctActiveFlag',
                data: params
            }).then(function (response) {
                var status = response.status;
                return response.data;
            });

            return promise;

  };


    systemMappingFactory.uploadPdf = function(pdfPath, formName) {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/uploadPdf?pdfPath='+ pdfPath+"&irs_form_no="+formName + "&tax_year=" + GlobalService.globalParams.tax_year,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };

    systemMappingFactory.doesTaxFormExist = function(formName) {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/doesTaxFormExist?irs_form_no='+formName + "&tax_year=" + GlobalService.globalParams.tax_year,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };

    systemMappingFactory.deleteTaxForm = function(formName) {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/deleteTaxForm?irs_form_no='+formName + "&tax_year=" + GlobalService.globalParams.tax_year,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };

    systemMappingFactory.populateConsolViewDropdown = function() {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/populateConsolViewDropdown',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };

    systemMappingFactory.populateEntityViewDropdown = function() {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/populateEntityViewDropdown',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };

    systemMappingFactory.populateDomesticViewDropdown = function() {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/populateDomesticViewDropdown',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };

    systemMappingFactory.populateForeignViewDropdown = function() {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/populateForeignViewDropdown',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };

    systemMappingFactory.getTaxForms = function(selectedView) {

        var promise = $http({
            method: "post",
            url: GENERAL_CONFIG.base_url + '/getTaxForms?selectedView='+selectedView,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.data;
        });   

        return promise;   	   
    };
       
        return systemMappingFactory


    }])



    return services;


});