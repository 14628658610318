
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.SystemSettingsFTCService',[])

    .factory('SysSettingsFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var sysSettingsFactory = {};
        sysSettingsFactory.url = {};
        sysSettingsFactory.url.parent_me_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=6ffgmj"
        sysSettingsFactory.url.filing_grp_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=sqt45b"
          
        sysSettingsFactory.saveParentMe = function(request){
        		console.log("sysSettingsFactory....Pl wait......Data::",request);       
               
        		var params = {};
        		var jsonObj = JSON.stringify(request.data);
        		var jsonSettings = JSON.stringify(request.data);
              
        		params = _.merge({},  GlobalService.globalParams , params);
        		params = _.extend({clob_data:jsonObj}, params);
        		params = _.extend({clob_settings:jsonSettings}, params);        		  
        		// params.tax_year = request.tax_year;
        		console.log("sysSettingsFactory......params::",params);             
        		
        		var promise = $http({
        			method: "POST",
        			url: sysSettingsFactory.url.parent_me_save,
        			data: params
        		}).then(function (response) {
        			console.log("response >>> :  >> ",response);
        			if(response.data.callSuccess === "1" ){        				
        				AlertService.add("success", "Data saved/updated Successfully.",4000);
        				return response.data;
        			}else {
        				if(response.data.errorMessage === "no access"){
        					AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
        				}else{
        					AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
        				}
        			}
        		});	
        	
        	return promise;
        } 
        
        sysSettingsFactory.saveFilingGrp = function(request){
    		console.log("sysSettingsFactory..saveFilingGrp..Pl wait......Data::",request);       
           
    		var params = {};
    		var jsonObj = JSON.stringify(request.data);
    		var jsonSettings = JSON.stringify(request.data);
          
    		params = _.merge({},  GlobalService.globalParams , params);
    		params =  _.extend({clob_data:jsonObj}, params);
    		params =  _.extend({clob_settings:jsonSettings}, params);    		  
    		params.tax_year = request.tax_year;
    		params.filing_grp = request.filing_grp;
    		console.log("::Params::",params);
    		
    		var promise = $http({
    			method: "POST",
    			url: sysSettingsFactory.url.filing_grp_save,
    			data: params
    		}).then(function (response) {
    			console.log("response >>> :  >> ",response);
    			if(response.data.callSuccess === "1" ){
    				AlertService.add("success", "Data saved Successfully.",4000);
    				return response.data;
    			}else {
    				if(response.data.errorMessage === "no access"){
    					AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
    				}else{
    					AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
    				}
    			}
    		});	
    	
    	return promise;
    } 	
        
               
         
        return sysSettingsFactory;
    }])

    return services;

});