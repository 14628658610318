define([
    'highstock',
    'angular',
    'lodash'
], function (Highcharts) {
    'use strict';

    // Load module after Highcharts is loaded
    require('highcharts-more')(Highcharts); 
    require('highcharts-3d')(Highcharts); 
    require('highcharts-exporting')(Highcharts); 
    require('highcharts-treemap')(Highcharts); 
    require('highcharts-variable-pie')(Highcharts); 
    require('highcharts-drilldown')(Highcharts); 
    
    Highcharts.setOptions({
        lang: {
            numericSymbols: ['K', 'M', 'B','T']
        }
    });
    
    
    var services = angular.module('app.dashboardServices', [])
        
        .factory('dashboardFactory', ['$rootScope',
            '$timeout',
            '$q',
            '$http',
            '$filter',
            'GENERAL_CONFIG',

            dashboardFactory
        ])
        
        .factory('strategyFactory', ['$rootScope',strategyFactory])
        
        .factory('componentFactory', ['strategyFactory',componentFactory])
        
        .factory('dataFormatterFactory', [dataFormatterFactory])


    function dashboardFactory($rootScope, $timeout, $q, $http, $filter, GENERAL_CONFIG) {
        var dashboardFactory = {};


        dashboardFactory.dashboardPool = [];


        dashboardFactory.getDashboardPool = function () {

            return dashboardFactory.dashboardPool;

        }

        dashboardFactory.getDashboardById = function (id) {

            var dashboard = _.find(dashboardFactory.dashboardPool, { 'id': id });


            return dashboard;

        }


        dashboardFactory.getDashboard = function (dashboard) {

            //return   dashboardFactory.dashboardPool;

        }


        dashboardFactory.getDashboard = function (dashboard) {

            //return   dashboardFactory.dashboardPool;

        }

        dashboardFactory.getDashboardComponent = function (url, dashboard) {
            var promise = $http({
                method: "post",
                url: url,
                data: dashboard
            }).then(function (response) {
                return response.data;
            });
            return promise;
        }


        dashboardFactory.destroyDashboard = function (_id) {

            var index = _.findIndex(dashboardFactory.dashboardPool, { 'id': _id });

            var removed = dashboardFactory.dashboardPool.splice(index, 1);

            console.log(" removed ", removed);

            console.log(" dashboardFactory.dashboardPool ", dashboardFactory.dashboardPool);



        }


        dashboardFactory.createOrUpdateDashboard = function (_name, _id, _dataSheets, _charts) {
            // var dashboard1 = dashboardFactory.getDashboardById(_id);
            // if (dashboard1) {
            //     return dashboard1;
            // } else {
                var dashboardIndex = dashboardFactory.dashboardPool.findIndex(function(item){ return item.id == _id});
                var dash = new dashboard(_name, _id, _dataSheets, _charts);
                if(dashboardIndex >= 0){
                    dash.dataLoaded = true;
                    dashboardFactory.dashboardPool[dashboardIndex] = dash;
                } else {
                    dashboardFactory.dashboardPool.push(dash);
                }
                // $timeout(function () {
                //     // dash.loadSheets();
                // }, 3);
                return dash;
            // }
        };

        dashboardFactory.createDashboardOld = function (_name, _id, _data_api, _dataSheets) {
            var dashboard1 = dashboardFactory.getDashboardById(_id);
            if (dashboard1) {
                return dashboard1;
            } else {
                var dash = new dashboard(_name, _id, _data_api, _dataSheets);
                dashboardFactory.dashboardPool.push(dash);
                $timeout(function () {
                    dash.loadSheetsOld();
                }, 3);
                return dash;
            }
        };




        function dashboard(_name, _id, _dataSheets, _components) {

            this.name = _name;
            this.id = _id;
            this.dataSheets = _dataSheets;
            this.safeCopyArr = [];
            this.params = [];
            this.isLoading = false;
            this.dataLoaded = false;
            this.dataLoading = false;
            this.filters = [];
            this.footerFilters = [];

            this.tableState = {
                sort: {},
                colSearch: [],
                pagination: {
                    start: 0
                }
            };

            this.components = _components;

        }

        dashboard.prototype.loadSheetsOld = function () {
            var that = this;
            var promises = [];
            $rootScope.$broadcast('dashboard data : loading', that.id);
            _.forEach(that.dataSheets, function (value, key) {
                var promise = $http.post(value.data_api);
                promises.push(promise);
            });
            $q.all(promises).then(function (data) {
                _.forEach(data, function (value, key) {
                    that.dataSheets[key].safeCopyArr = value.data;
                    that.dataSheets[key].data = value.data;
                    that.dataSheets[key].isLoaded = true;
                });
                that.dataLoaded = true;
                $rootScope.$broadcast('dashboard:loaded', 'Broadcast'); console.log('dashboard:loaded');
            }, function (error) {
                //This will be called if $q.all finds any of the requests erroring.
            });
        }
        dashboard.prototype.loadSheets = function (filterparams,activeScreen) {
            var that = this;
            var promises = [];
            var dataSheets = dataSheets || that.dataSheets;
            $rootScope.$broadcast('dashboard data : loading', that.id);
            /* set the current screen filters to workspace */
            that.clearData();
            //   var filterparams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            //filterparams.jcd_key = 250;
            if (!_.isEmpty(filterparams)) {
                console.log("filter loadsheet", filterparams)
                _.forEach(dataSheets, function (sheet, key) {
                    if (dataSheets[key].data && dataSheets[key].data.length > 0) {

                    } else {
                        dataSheets[key].isLoading = true;
                        $rootScope.$broadcast('sheet-' + sheet.name + ':loading', sheet.name);
                        var promise = $http.post(GENERAL_CONFIG.base_url + '/' + sheet.action_id, filterparams).then(function (responseData) {
                            dataSheets[key].safeCopyArr = responseData.data && responseData.data.jsonObject ? responseData.data.jsonObject : responseData.data;
                            dataSheets[key].data = responseData.data && responseData.data.jsonObject ? responseData.data.jsonObject : responseData.data;
                            dataSheets[key].isLoaded = true;
                            dataSheets[key].isLoading = false;
                            $rootScope.$broadcast('sheet-' + sheet.name + ':loaded', sheet.name);
                        });
                        promises.push(promise);
                    }
                });
            }




            $q.all(promises).then(function (data) {
                that.dataLoaded = true;
                activeScreen.loading_data = false;
                // workspaceFactory.setCache(activeScreen, data)
                $rootScope.$broadcast('dashboard:loaded', 'Broadcast');
            }, function (error) {
                //This will be called if $q.all finds any of the requests erroring.



            });



        }



        dashboard.prototype.clearData = function () {
            _.forEach(this.dataSheets, function (value, key) {
                value.data = [];
                value.safeCopyArr = [];
            });
        }


        dashboard.prototype.reloadData = function (data) {
            this.clearData();
            this.loadSheets();
        }



        dashboard.prototype.setFilters = function (filters) {
            this.filters = filters;
        }
        //Abdul: Need to discuss
        dashboard.prototype.setFooterFilters = function (filters) {
            this.footerFilters = filters;
        }
        dashboard.prototype.getFooterFilters = function () {
            return this.footerFilters;
        }

        dashboard.prototype.getFilters = function () {
            return this.filters;
        }



        dashboard.prototype.destroy = function (_id) {

            dashboardFactory.destroyDashboard(_id);
        }





        dashboard.prototype.groupBySum = function (_filter, _groupBy) {
            var result = _.chain(this.data).groupBy(_groupBy).map(function (v, i) {
                console.log("v", v)
                return {
                    name: i,
                    amount: _.map(v, 'AMOUNT'),
                    sum: _.sumBy(v, 'AMOUNT')
                };
            }).value();
        }



        dashboard.prototype.pipe = function () {
            var that = this;
            _.forEach(this.dataSheets, function (value, key) {
                if (!Array.isArray(value.safeCopyArr)) {
                    return;
                }
                var filtered = _.cloneDeep(value.safeCopyArr);
                value.data = that.filter(filtered, value.name);
                $rootScope.$broadcast('sheet:filtered', value.name);
            });
            $rootScope.$broadcast('dashboard:loaded', 'Broadcast');

        }



        dashboard.prototype.filter = function (filtered, name) {

            var that = this;


            console.log("this.tableState.colSearch ==================================== ", this.tableState.colSearch);


            if (this.tableState.colSearch.length) {


                _.each(this.tableState.colSearch, function (model) {

                    if (name && model.sheet && model.sheet.length) {
                        var n = model.sheet.includes(name);
                        if (!model.sheet.includes(name)) {
                            return filtered;
                        }
                    }


                    if (model.operator === "blank" || model.operator === "!blank") {

                        if (model.operator === "blank") {
                            var array = [];
                            array.push(undefined);
                            filtered = _.findByValues(filtered, model.predicate, array);
                        } else {
                            filtered = _.filter(filtered, function (o) {
                                if (typeof o[model.predicate] !== "undefined" && o[model.predicate] !== null && o[model.predicate] !== "") {
                                    return o;
                                }
                            });
                        }


                    } else if ((model.type === 'numeric' || model.type === 'number') && model.predicate.length) {
                        filtered = _.findNumByProperty(filtered, model.predicate, model.value, model.operator)
                    } else if (model.type === 'string' && model.predicate.length) {

                        if (model.operator === ':') {
                            filtered = _.findByKeyPartialValue(filtered, model.value, model.predicate);
                        } else if (model.operator === '!:') {
                            filtered = _.findNotByKeyPartialValue(filtered, model.value, model.predicate);
                        } else if (model.operator === '=') {
                            var obj = [];
                            obj[model.predicate] = model.value;
                            filtered = _.filter(filtered, { '+model[predicate]+': model.value });
                        } else if (model.operator === '!=') {

                        } else {
                            filtered = _.findByKeyPartialValue(filtered, model.value, model.predicate);
                        }

                    } else if (model.type === 'array' && model.value.length) {

                        if (model.reverse) {
                            filtered = _.findByValuesNot(filtered, model.predicate, model.value);
                        } else {
                            filtered = _.findByValues(filtered, model.predicate, model.value);
                        }
                    }
                });


                // console.log("filtered " , filtered);

                return filtered;

            }


        }

        dashboard.prototype.dataTableAmountFormatter = function (data) {
            var dataToShow = data;
            var cellData = data < 0 ? "<span style='color:red' class='datatable-revenue'>(" + $filter('convertAmount')(Math.abs(dataToShow), "$") + ")</span>" : "<span class='datatable-revenue'>" + $filter('convertAmount')(dataToShow, "$") + "</span>";
            return cellData
        }




        return dashboardFactory;


    }

    function strategyFactory($rootScope) {

        var strategyFactory = {
            runStrategy: _runStrategy,
            getAllStrategies: _getAllStrategies,
            getStrategyFunctionString: _getStrategyFunctionString,
            getStrategyInputs: _getStrategyInputs
        }

        function _getStrategyFunctionString(strategy) {
            var func = _strategyStore[strategy]["func"];

            if (func) {
                return func.toString();
            }
        }
        function _getAllStrategies() {
            return _strategyStore;
        }
        function _runStrategy(strategyName, sheetData, options) {
            var data = validation(strategyName, sheetData, options);
            if (data.errorMsgs.length > 0) {
                return data;
            }
            return _strategyStore[strategyName]["func"](sheetData, options);
        }

        function _getStrategyInputs(strategyName, actionKeys, strategySettings) {
            if(!_strategyStore[strategyName]){
                return [];
            }
            return _strategyStore[strategyName]["inputs"].map(function (input) {
                return {
                    name: input.name,
                    selected: strategySettings ? (Array.isArray(strategySettings[input.value]) ?
                        strategySettings[input.value].map(function (key) { return { name: key, value: key } }) : []) : [],
                    values: actionKeys.map(function (key) { return { name: key, value: key } }),
                    selectionMode: input.selectionMode,
                    key: input.value,
                    type:input.type
                }
            })
        }

        var _strategyStore = {
            aggregateByCategory: {
                inputs: [{ value: 'series_by', name: 'Series', selectionMode: 'single' },
                { value: 'categories_by', name: "Categories", selectionMode: 'single' },
                { value: 'values_by', name: 'Values By', selectionMode: 'single' }],
                displayName:"Plot data points by Category for mutiple series",
                value:"aggregateByCategory",
                func: _aggregateByCategory
            },
            aggregateByCategoryWithoutSeries: {
                inputs: [{ value: 'categories_by', name: "Categories", selectionMode: 'single' },
                { value: 'values_by', name: 'Values By', selectionMode: 'single' },
                { value: 'enable_drilldown', name: 'Enable Drilldown', selectionMode: 'toggle', optional: true }],
                displayName:"Plot data points by Category without Series",
                value:"aggregateByCategoryWithoutSeries",
                func: _aggregateByCategoryWithoutSeries
            },
            groupBusinessBy: {
                inputs: [{ value: 'businessNameAttr', name: 'Business Name Attribute', selectionMode: 'single' },
                { value: 'businessCodeAttr', name: 'Business Code Attribute', selectionMode: 'single' },
                { value: 'groupAmountsBy', name: 'Group Amounts By', selectionMode: 'single' },
                { value: 'amountsAttr', name: 'Amounts By', selectionMode: 'single' }],
                displayName:"Build Standard Business Group",
                value:"groupBusinessBy",
                func: _groupBusinessBy
            },
            rawDataToSeries: {
                inputs: [],
                displayName:"Plug raw data to series",
                value:"rawDataToSeries",
                func: _rawDataToSeries
            },
            showSheetData: {
                inputs: [],
                displayName:"Get Raw Data",
                value:"showSheetData",
                func: _showSheetData
            },
            singleSeriesChart: {
                inputs: [{ value: 'series_by', name: 'Series', selectionMode: 'single' },
                // { value: 'categories_by', name: "Categories", selectionMode: 'single' },
                { value: 'values_by', name: 'Values By', selectionMode: 'single' },
                { value: 'colors_by', name: 'Colors By', selectionMode: 'single', optional: true}
            ],
                displayName:"Plot data points in a single series with no categories",
                value:"singleSeriesChart",
                func: _singleSeriesChart
            },
            horizontalBizzTreeMap: {
                inputs: [{ value: 'series_by', name: 'Series', selectionMode: 'single' },
                { value: 'values_by', name: 'Values By', selectionMode: 'single' }],
                displayName:"Build Standard Business Tree Map",
                value:"horizontalBizzTreeMap",
                func: _horizontalBizzTreeMap
            },
            buildRegularTreeMap: {
                inputs: [{ value: 'series_by', name: 'Series', selectionMode: 'single' },
                { value: 'values_by', name: 'Values By', selectionMode: 'single' },
                { value: 'drilldown_series_by', name: 'Drill Down By', selectionMode: 'single' }],
                displayName:"Build Standard Highcharts Tree Map",
                value:"buildRegularTreeMap",
                func: _buildRegularTreeMap
            },
            buildCombinationChart: {
                inputs: [],
                func: _buildCombinationChart
            },
            aggregateByColumn: {
                inputs: [{ value: 'aggregateBy', name: 'Aggregate By', selectionMode: 'single' }],
                displayName:"Sum of Column",
                value:"aggregateByColumn",
                func: _aggregateByColumn
            },
            aggregatePercentByColumn: {
                inputs: [{ value: 'aggregateBy', name: 'Aggregate By', selectionMode: 'single' },
                { value: 'percentBy', name: 'Percent By', selectionMode: 'single' }],
                displayName:"Percentage of Column by Column",
                value:"aggregatePercentByColumn",
                func: _aggregatePercentByColumn
            },
            filterDataAndAggregate: {
                inputs: [
                    { value: 'filterColumn', name: 'Filter Column', selectionMode: 'single' },
                    { value: 'filterBy', name: 'Filter By',  type:'input' },
                    { value: 'aggregateBy', name: 'Aggregate By', selectionMode: 'single' },
                ],
                displayName:"Filter Data and Aggregate",
                value:"filterDataAndAggregate",
                func: _filterDataAndAggregate
            },
            applyCustomForumlaOnColumns: {
                inputs: [
                    { value: 'formula', name: 'Formula',  type:'input' }
                ],
                displayName:"Apply Custom Formula with Columns as placeholders",
                value:"applyCustomForumlaOnColumns",
                func: _applyCustomForumlaOnColumns
            },
            applyCustomForumlaOnRows: {
                inputs: [
                    { value: 'filterColumn', name: 'Filter Column', selectionMode: 'single' },
                    { value: 'valueBy', name: 'Value By', selectionMode: 'single' },
                    { value: 'formula', name: 'Formula',  type:'input' }
                ],
                displayName:"Apply Custom Formula with Row Data to Match as Placeholders",
                value:"applyCustomForumlaOnRows",
                func: _applyCustomForumlaOnRows
            },
            aggregateCategoriesByProps: {
                inputs: [{ value: 'series_by', name: 'Series', selectionMode: 'single' },
                { value: 'categories_by', name: 'Categories', selectionMode: 'multiple' }
                ],
                value:"aggregateCategoriesByProps",
                displayName:"Plot data points with columns as categories for multiple series",
                func: _aggregateCategoriesByProps
            },
            groupByWithChange: {
                inputs: [{ value: 'group_by', name: 'Group By', selectionMode: 'single' }],
                displayName:"Compare 2 groups in 2 rows with difference in 3rd Row",
                value:"groupByWithChange",
                func: _groupByWithChange
            },
            aggregateSeriesByProps: {
                inputs: [{ value: 'categories_by', name: 'Categories', selectionMode: 'single' },
                { value: 'series_by', name: 'Series', selectionMode: 'multiple' }],
                displayName:"Plot data points with columns as series for multiple categories",
                value:"aggregateSeriesByProps",
                func: _aggregateSeriesByProps
            },
            aggregateCategoriesByPropsWithoutSeries: {
                inputs: [
                    { value: 'categories_by', name: 'Categories', selectionMode: 'multiple' }
                ],
                displayName:"Plot data points with columns as categories without series",
                value:"aggregateCategoriesByPropsWithoutSeries",
                func: _aggregateCategoriesByPropsWithoutSeries
            },
            countAllRows: {
                inputs: [
                    { value: 'column_by', name: 'Column', selectionMode: 'single' }
                ],
                displayName:"Count Unique Rows by Column",
                value:"countAllRows",
                func: _countAllRows
            }
    
        }



        function _buildCombinationChart(sheetData, options) {
            var data = {
                errorMsgs: []
            }
        }


        function _buildRegularTreeMap(sheetData, options) {
            /**
            *
            * @param  sheetData
            * @param  options
            * @returns {
            *      topLevelData:[],
            *      errorMsgs: [],
            *      drilldown : {}
            * }
            */
            var data = {
                errorMsgs: []
            }

            data.topLevelData = _.chain(sheetData).groupBy(options.series_by).map(function (value, i) {
                var isNegative = _.sumBy(value, options.values_by) < 0 ? true : false;
                return {
                    name: i,
                    value: Math.abs(_.sumBy(value, options.values_by)),
                    level: 1,
                    drilldown: i,
                    isNegative: isNegative
                }
            }).value();

            if (options.drilldown_series_by) {

                data.drilldown = {}


                var drilldownCollection = {}
                _.chain(sheetData).groupBy(options.series_by).map(function (value, i) {
                    drilldownCollection[i] = _.groupBy(value, options.drilldown_series_by);
                }).value()

                data.drilldown.series = _.map(drilldownCollection, function (value, i) {
                    var data = [];

                    for (var key in value) {
                        var isNegative = _.sumBy(value[key], options.values_by) < 0 ? true : false;
                        data.push({
                            name: key,
                            value: Math.abs(_.sumBy(value[key], options.values_by)),
                            level: 2,
                            isNegative: isNegative
                        })
                    }
                    return {
                        type: "treemap",
                        id: i,
                        data: data
                    }

                })

            }
            return data
        }

        function _horizontalBizzTreeMap(sheetData, options) {
            var data = {
                errorMsgs: []
            }

            data.nodes = _.chain(sheetData).groupBy(options.series_by).map(function (value, i) {
                var isNegative;
                var color;

                let sum = _.sumBy(value, options.values_by);


                    if (sum < 0) {
                        isNegative = true;
                        color = '#e86153'//'red'//rgb(249, 63, 63)
                    }
                    else {
                        isNegative = false;
                        color = '#4eb523'//'green'//rgb(56, 146, 56)
                    }



                return {
                    name: i,
                    fullName: i,
                    value: Math.abs(sum),
                    isNegative: isNegative,
                    color: color
                }


            }).sortBy('value').reverse().value();

            return data
            // }
        }


        function _singleSeriesChart(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      series:[],
             *      errorMsgs: []
             * }
            */
            var data = {
                errorMsgs: [],
                sheetDataSafeCopy: sheetData,
                series: [
                    {
                        name: "",
                        data: null
                    }
                ]
            }
            var colorFlags = [];

            if(options.colors_by)
                colorFlags =_.chain(sheetData).map(options.colors_by).uniq().value();

            data.series[0].data = _.chain(sheetData).groupBy(options.series_by).map(function (seriesData, i) {
                var totalCurrentAmount = _.sumBy(seriesData, options.values_by);
                var isNegative = false;
                if (totalCurrentAmount < 0) {
                    isNegative = true;
                    totalCurrentAmount = Math.abs(totalCurrentAmount)
                }
                return {
                    name: i,
                    y: totalCurrentAmount,
                    value: totalCurrentAmount,
                    color: colorFlags.length > 0 && seriesData.length > 0
                        ? Highcharts.getOptions().colors[colorFlags.indexOf(seriesData[0][options.colors_by])]: null,//only 10 colors available
                    isNegative: isNegative,
                }
            }).value();

            // data.series[0].data[0].selected = true;
            // data.series[0].data[0].sliced = true;

            return data
        }


        function _aggregateByCategory(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      series:[],
             *      categories:[],
             *      errorMsgs: []
             * }
             */
            var data = {
                errorMsgs: [],
                sheetDataSafeCopy: sheetData
            };

            data.categories = _.chain(sheetData).groupBy(options.categories_by).keys().sortBy().value();


            data.series = _.chain(sheetData).groupBy(options.series_by).map(function (seriesData, i) {
                var groupByTotal = _.chain(seriesData).groupBy(options.categories_by).map(function (totalData, i) {
                    var sum = _.sumBy(totalData, options.values_by);
                    return {
                        name: i,
                        y: sum,
                        value: sum
                    };
                }).sortBy('name').value();//always sort by series name

                //adding 0 to non existing categories in same order as categories.
                var nonExistentCategories = _.difference(data.categories, _.map(groupByTotal, function (item) { return item.name }));

                _.each(nonExistentCategories, function (value, key) {

                    groupByTotal.splice(data.categories.indexOf(value), 0, {
                        name: value,
                        y: 0,
                        value: 0
                    });
                })


                return {
                    name: i,
                    data: groupByTotal
                }
            }).value();





            return data;
        }

        function _aggregateByCategoryWithoutSeries(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      series:[],
             *      categories:[],
             *      errorMsgs: []
             * }
             */
            var data = {
                errorMsgs: [],
                sheetDataSafeCopy: sheetData
            };

            

            data.series = [{name:options.values_by}];

            var groupByTotal = _.chain(sheetData).groupBy(options.categories_by).map(function (totalData, i) {
                var sum = _.sumBy(totalData, options.values_by);
                return {
                    name: i,
                    y: sum,
                    value: sum,
                    drilldown:options.enable_drilldown ? i : undefined         
                };
            }).sortBy('name').value();//always sort by series name

            //adding 0 to non existing categories in same order as categories.
            var nonExistentCategories = _.difference(data.categories, _.map(groupByTotal, function (item) { return item.name }));

            _.each(nonExistentCategories, function (value, key) {

                groupByTotal.splice(data.categories.indexOf(value), 0, {
                    name: value,
                    y: 0,
                    value: 0,
                    drilldown:options.enable_drilldown ? value : undefined
                });
            })

            data.series[0].data = groupByTotal;

            if(options.enable_drilldown){
                data.drilldown = {series : []};

                _.forEach(sheetData,level1Point =>{
                    try{
                        if(typeof level1Point.CHILDREN === "string"){level1Point.CHILDREN = JSON.parse(level1Point.CHILDREN).children}
                    }catch(e){}

                    let level2Data = _.chain(level1Point.CHILDREN).groupBy(options.categories_by).map(function (totalData, i) {
                        var sum = _.sumBy(totalData, options.values_by);
                        
                        return {
                            name: i,
                            y: sum
                        };
                    }).sortBy('name').value();
                    let series = { name:level1Point[options.categories_by],id:level1Point[options.categories_by],data:level2Data};
                    data.drilldown.series.push(series);
                })
            }else{
                data.categories = _.chain(sheetData).groupBy(options.categories_by).keys().sortBy().value();
            }

            return data;
        }

        function _aggregateCategoriesByProps(sheetData, options) {

            var data = {
                errorMsgs: [],
            };

            data.categories = options.categories_by;

            data.series = _.chain(sheetData).groupBy(options.series_by).map(function (groupedData, key) {

                var categoriesData = []

                _.each(data.categories, function (category, key) {
                    categoriesData.push(_.sumBy(groupedData, category));
                })

                return {
                    name: key,
                    data: categoriesData
                };
            }).value();

            return data;
        }

        function _aggregateCategoriesByPropsWithoutSeries(sheetData, options) {

            var data = {
                errorMsgs: [],
            };

            data.categories = options.categories_by;

            data.series = [{
                name:'',
                data:[]
            }]

            _.each(data.categories, function (category, key) {
                data.series[0].data.push(_.sumBy(sheetData, category));
            })

            return data;
        }
        function _countAllRows(sheetData,options){
            return _.uniqBy(sheetData, options.categories_by).length ;
        }

        function _countAllRows(sheetData,options){
            var uniqRecords =  _.uniqBy(sheetData, options.column_by) ;
           
            
            return uniqRecords && uniqRecords.length;
        }


        function _aggregateSeriesByProps(sheetData, options){
            var data = {
                errorMsgs: [],
            };

            data.categories = [];

            data.series = _.map(options.series_by,function(prop) {
                return {
                    name:prop,
                    data:[]
                }
            });


            var seriesData = _.chain(sheetData).groupBy(options.categories_by).value();

            for(var key in seriesData) {

                _.each(options.series_by, function (series_by, index) {
                    var series = _.find(data.series,["name",series_by]);
                    series.data.push(_.sumBy(seriesData[key], series_by));
                })

                data.categories.push(key);

            };

            return data;
        }


        function _groupByWithChange(sheetData, options) {
            var groupedDataTop2 = _.chain(sheetData).groupBy(options.group_by).map(function (groupedData, key) {
                var dataObj = {};
                dataObj[options.group_by] = key;

                _.keys(groupedData[0]).forEach(function (value, key) {
                    if (typeof groupedData[0][value] === "number" && value !== 'TAX_YEAR')
                        dataObj[value] = _.sumBy(groupedData, value);
                })

                return dataObj;
            }).sortBy(options.group_by).reverse().slice(0, 2).value();

            var changeRow = {};
            changeRow[options.group_by] = "change";

            _.forIn(groupedDataTop2[0], function (value, key) {
                if (typeof groupedDataTop2[0][key] === "number" && key !== 'TAX_YEAR') {
                    //changeRow[key] = groupedDataTop2[1][key] - groupedDataTop2[0][key];
                    if (groupedDataTop2 && groupedDataTop2[1] != undefined) {
                        changeRow[key] = groupedDataTop2[1][key] - groupedDataTop2[0][key];
                    } else {
                        changeRow[key] = groupedDataTop2[0][key];
                    }
                }
            })

            groupedDataTop2.push(changeRow);

            return groupedDataTop2;
        }

        function _rawDataToSeries (sheetData,options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
                *      series: [{
                *           data:[]
                *       }]
                * }
                */

                return {
                    series:[
                       { data: _.map(sheetData,function(obj){
                                    return   _.transform(obj, function (result, val, key) {
                                                result[key.toLowerCase()] = val;
                                            })
                                })
                        }
                    ]
                };
        }


        function _showSheetData(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      sheetData: []
             * }
             */
            return sheetData;
        }


        function _aggregateByColumn(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      value
             * }
             */
            if(!options.aggregateBy){
                return '';
            }
            return _.sumBy(sheetData, options.aggregateBy);
        }
        function _aggregatePercentByColumn(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      value
             * }
             */
            if(!options.aggregateBy){
                return '';
            }

            var numeratorAggregate = _.sumBy(sheetData, options.aggregateBy);
            var denominatorAggregate = _.sumBy(sheetData, options.percentBy);

            if(denominatorAggregate === 0){
                return 'Infinity';
            }

            return (numeratorAggregate/denominatorAggregate) * 100;
        }

        function _filterDataAndAggregate(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      value
             * }
             */
            if(!options.filterColumn || !options.filterBy || !options.aggregateBy){
                return '';
            }

            var filteredData = _.filter(sheetData,[options.filterColumn,options.filterBy]);

            var value = _.sumBy(filteredData,options.aggregateBy);

            
            return value;
        }

        function _applyCustomForumlaOnColumns(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
             *      value
             * }
             */
            
            if(!options.formula){
                return '';
            }

            var tokens = {}; 
            var formula = angular.copy(options.formula);

            formula.replace(/\{(.*?)}/g,function(tokenWithBrackets,token) {//find better way than replace

                tokens[token].token = token;
                return tokenWithBrackets;
            })

            for(var token in tokens){
                tokens[token].aggregatedValue = _.sumBy(sheetData,token);
            }

            formula = formula.replace(/\{(.*?)}/g,function(tokenWithBrackets,token) {
                return tokens[token].aggregatedValue;
            })

            try{
                return $rootScope.$eval(formula);
            }catch{
                return 'error';
            }
            
        }
        function _applyCustomForumlaOnRows(sheetData, options) {
            /**
             *
             * @param  sheetData
             * @param  options
             *
             * @returns {
                *      value
                * }
                */

            if(!options.formula || !options.filterColumn || !options.valueBy){
                return '';
            }

            var tokens = {}; 
            var formula = angular.copy(options.formula);

            formula.replace(/\{'(.*?)'}/g,function(tokenWithBrackets,token) {//find better way than replace
                tokens[token] = {};
                tokens[token].token = token;
                return tokenWithBrackets;
            })

            for(var token in tokens){

                var filteredData = _.filter(sheetData,[options.filterColumn,token])
                tokens[token].aggregatedValue = _.sumBy(filteredData,options.valueBy);
            }

            formula = formula.replace(/\{'(.*?)'}/g,function(tokenWithBrackets,token) {
                return tokens[token].aggregatedValue;
            })

            try{
                return $rootScope.$eval(formula);
            }catch{
                return 'error';
            }
            
        }

        function _groupBusinessBy(sheetData, options) {
        /**
         *
         * @param  sheetData
         * @param  options
         *
         * @returns {
                        id,
                        name,
                        value,
                        data:{
                            amount,
                            color,
                            isNegative
                        },
                        selected= false

            * }
            */
            var sheetData = addColorToFSIData(sheetData, options.groupAmountsBy);

            return _.chain(sheetData).groupBy(options.businessNameAttr).map(function (parent_me, parent_me_name) {
                var parent_me_revenue = {}
                _.chain(parent_me).groupBy(options.groupAmountsBy).map(function (basket_code, basket_code_name) {
                    if (!_.keys(parent_me_revenue).includes(basket_code_name)) {
                        parent_me_revenue[basket_code_name] = {};
                    }
                    parent_me_revenue[basket_code_name]['amount'] = Math.abs(_.sumBy(basket_code, options.amountsAttr));
                    parent_me_revenue[basket_code_name]['color'] = basket_code[0]['COLOR'];
                    parent_me_revenue[basket_code_name]['isNegative'] = _.sumBy(basket_code, options.amountsAttr) < 0 ? true : false;


                }).value()
                return {
                    id: parent_me[0][options.businessCodeAttr],
                    name: parent_me_name,
                    value: parent_me[0][options.businessCodeAttr],
                    data: parent_me_revenue,
                    selected: false
                }
            }).value()


            function addColorToFSIData(sheetData, groupBy) {
                var diamondColor = ['darkblue', 'green', 'orange','violet' , 'indigo' , 'yellow' , 'brown' , 'lightblue'];
                var counter = 0;
                var groupedData = _.groupBy(sheetData, groupBy);
                for (var key in groupedData) {

                    for (var i = 0; i < groupedData[key].length; i++) {
                        groupedData[key][i]['COLOR'] = diamondColor[counter];
                    }
                    counter++;
                }

                return sheetData;
            }
        }

        /**
         *
         * @param  sheetData
         * @param  options
         *
         * @returns {
         *      errorMsgs: []
         * }
         */
        function validation(strategyName, sheetData, options) {
            var data = {
                errorMsgs: []
            };
            if (!strategyName) {
                data.errorMsgs.push("Please select strategy");
            } else if (!_strategyStore[strategyName]) {
                data.errorMsgs.push("Invalid Strategy");
            } else if (!_strategyStore[strategyName].inputs) {
                data.errorMsgs.push("Inputs are not defined for strategy '" + strategyName + "' in strategy factory.");
            } else if (!_strategyStore[strategyName].func) {
                data.errorMsgs.push("No function is defined for strategy '" + strategyName + "' in strategy factory.");
            }
            else {
                _.each(_strategyStore[strategyName].inputs, function (input, key) {
                    if(input.optional){
                        return;
                    }

                    if (input.selectionMode === 'single' && !options[input.value]) {
                        data.errorMsgs.push("Please select '" + input.name + "' in strategy settings");
                    }

                    if (input.selectionMode === 'multiple' && (!options[input.value] || options[input.value].length === 0)) {
                        data.errorMsgs.push("Please select atleast one '" + input.name + "' in strategy settings");

                    }
                })
            }

            return data;
        }

        return strategyFactory;

    }


    // componentFactory.$inject = ['$templateCache']
    function componentFactory(strategyFactory) {

        // var projectReviewTemplate = $templateCache.get('project-review-tpl.html');

        var componentFactory = {
            getComponent: _getComponent,
            getDefinedComponentList: _getDefinedComponentList,
            getComponentStrategies: _getComponentStrategies,
            getAllComponents: _getAllComponents
        }

        function _getDefinedComponentList() {
            return _.chain(_componentStore).keys().filter(function (i) { return i != 'kpi' }).value();
        }

        function _getComponent(componentName) {
            return _componentStore[componentName] ? _componentStore[componentName].component : "";
        }

        function _getComponentStrategies(componentName) {
            var allStrategies = strategyFactory.getAllStrategies();
            return _.chain(allStrategies)
                    .pick(_componentStore[componentName].strategies)
                    .values()
                    .value();
        }

        function _getAllComponents() {
            return _componentStore
        }

        var _componentStore = {
            chart: {
                component: '<div gt-chart options="ctrl.options"><div>',
                strategies: ["aggregateByCategory","aggregateByCategoryWithoutSeries", "singleSeriesChart",
                "aggregateCategoriesByProps","aggregateSeriesByProps","aggregateCategoriesByPropsWithoutSeries","rawDataToSeries"]

            },
            datatable: {
                component: '<div gt-datatable2 options="ctrl.options"><div>',
                strategies: ["showSheetData", "groupByWithChange"]
            },
            horizontalBusinessGroup: {
                component: '<div gt-horizontal-bizz-group options="ctrl.options"></div>',
                strategies: ["groupBusinessBy"]
            },
            kpi: {
                component: '<div gt-kpi-box options="ctrl.options"></div>',
                strategies: ["aggregateByColumn","aggregatePercentByColumn","filterDataAndAggregate","applyCustomForumlaOnColumns","applyCustomForumlaOnRows","countAllRows"]
            },

            horizontalBusinessTreeMap: {
                component: '<div gt-horizontal-bizz-tree-map options="ctrl.options"></div>',
                strategies: ["horizontalBizzTreeMap"]
            },
            regularTreeMap: {
                component: '<div gt-regular-tree-map options="ctrl.options"></div>',
                strategies: ["buildRegularTreeMap"]
            },
            combinationChart: {
                component: '<div gt-combination-chart options="ctrl.options"></div>',
                strategies: []
            },
            projectReview: {
                component: '<div gt-project-review options="ctrl.options"></div>',
                strategies: ["showSheetData"]
            },
            materialSchDm: {
                component: '<div ng-include="\'app/components/admin/adminDashboards/component-settings-templates/material-schdm/material-schdm-component-tpl.html\'"></div>',
                strategies: ["showSheetData"]
            },
            dynamicHtml: {
                component: '<div gt-dynamic-html options="ctrl.options"></div>',
                strategies: ["showSheetData"]
            }
        }

        return componentFactory;

    }

    function dataFormatterFactory() {
        var formatterFactory = {
            getAllFormatters: _getAllFormatters,
            getFormatter: _getFormatter
        }
        function _getFormatter(name) {
            return _.find(_formatterStore, function(value,index) {
                return value.name === name; //&& (value.type === 'all' || value.type.includes(type));   
            });
        }
        function _getAllFormatters() {
            return _formatterStore;
            // .filter(function(value) {
            //     return value.type === 'all' || (!type || value.type.includes(type))
            // });
        }


        var _formatterStore = [
            {
                name:"None",//None is hardcoded at some places. Please refer them before changing here.
                func:undefined
            },
            {
                name:"Number",
                func:function(value,isNegative,skipHTML = false) {
                    var format = Highcharts.numberFormat(Math.abs(value),0,'.',',');
                    return (value < 0 || isNegative ?
                             skipHTML === true ?`(${format})`: `<span style="color:#e86153">(${format})</span>`
                             :format);
                }
            },
            {
                name:"Number with Label",
                func:function(value,isNegative,label) {
                    var format = Highcharts.numberFormat(Math.abs(value),0,'.',',');
                    return label + ': ' + (value < 0 || isNegative ? `<span style="color:#e86153">(${format})</span>`:format);
                }
            },
            {
                name:"Percent",
                func:function(value,isNegative,prefix) {
                    var format = Highcharts.numberFormat(Math.abs(value),2,'.',',');
                    return (prefix || '') + (value < 0 || isNegative? `<span style="color:#e86153">(${format}%)</span>`:format+'%');
                }
            },
            {
                name:"Amount",
                func:convertAmount
            },
            {
                name:"Amount with Label",
                func:convertAmountWithLabel
            }
        ]


        ////

        function convertAmount(labelValue,isNegative) {
            let abs = Math.abs(Number(labelValue));
            // Nine Zeroes for Billions
            let result =  abs >= 1.0e+9

                ?'$' + (abs / 1.0e+9).toFixed(2) + "B"
                // Six Zeroes for Millions 
                :  abs >= 1.0e+6

                    ? '$' + (abs / 1.0e+6).toFixed(2) + "M"
                    // Three Zeroes for Thousands
                    : abs >= 1.0e+3

                        ? '$' + (abs / 1.0e+3).toFixed(2) + "K"

                        : '$' + abs.toFixed(2);

            if(labelValue < 0 || isNegative){
                return `<span style="color:#e86153"">(${result})</span>`
            }

            return `${result}`;
        }
        function convertAmountWithLabel(value,isNegative,label) {
            // Nine Zeroes for Billions
            let result = Math.abs(Number(value)) >= 1.0e+9

                ?'$' + (Number(value) / 1.0e+9).toFixed(2) + "B"
                // Six Zeroes for Millions 
                :  Math.abs(Number(value)) >= 1.0e+6

                    ?  '$' + (Number(value) / 1.0e+6).toFixed(2) + "M"
                    // Three Zeroes for Thousands
                    : Math.abs(Number(value)) >= 1.0e+3

                        ? '$' + (Number(value) / 1.0e+3).toFixed(2) + "K"

                        : '$' + Number(value).toFixed(2);

            if(value < 0 || isNegative){
                return `${label}: <span style="color:#e86153"">(${result})</span>`
            }

            return `${label}: ${result}`;
        }


        return formatterFactory;
    }
    
    
    ////Add To Loash

    _.mixin({
        'qreAggregation':function(collection,params) {
            let count = params ? _.chain(params.dataObj).filter(obj=> obj.LABEL && obj.LABEL.toLowerCase() === 'total').reject([params.col,0]).value() : [];//if total is 0 dont use in calculating average.
            return _.sum(collection) / (count ? count.length : 1);
        }
    })
    _.mixin({
        'count':function(collection,params) {
            return collection.length;
        }
    })



    return services;

});
