define([
    'angular',
    './transactionCategory/TransactionCategoryCtrl',
    './transactionCategory/TransactionCategoryService'

], function () {
    'use strict';
   
    return angular.module('app.assets', [/*'app.InvestmentValidationService','app.InvestmentValidationCtrl'*/])
        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider;
    }]);
});