define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.giltiConsolidatedGridCtrl',  [])
		.controller('giltiConsolidatedGridCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','USER_SETTINGS','giltiConsolidatedGridFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', giltiConsolidatedGridCtrl]);


	function giltiConsolidatedGridCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,USER_SETTINGS,giltiConsolidatedGridFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		var vm = this;
		vm.rowData = rowData ||$scope.$parent.rowData;
        vm.colData = colData;
        vm.gridData = gridData;
        vm.globalParams = GlobalService.globalParams;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        console.log("Here is the starting point and the rowData was ::::::::", vm.rowData);
        console.log("Here is the starting point and the global Params was ::::::::", vm.globalParams);
        vm.ho_leid = vm.rowData.HO_LEID;
        vm.ho_cdr_no = vm.rowData.HO_CDR_NO;
        vm.ho_combination_key = vm.rowData.HO_COMBINATION_KEY;
		 vm.cancel = function () {
			$scope.crudLoading = false;
    		console.log("in cancel");
    		$uibModalInstance.dismiss('cancel');
        };
		vm.consolidatedJson = function(){
			 var consolidatedJson = [{
	            	"tax_year" 			: rowData.TAX_YEAR,       
	            	"ho_leid"  			: rowData.HO_LEID,      
	                "ho_cdr_no" 		: rowData.HO_CDR_NO,                                                             
	                "filing_group"		: rowData.FILING_GROUP,
	                "scenario"     		: rowData.SCENARIO,
	                "col_type"           : colData.MAP,
	                "col_value"          : rowData[colData.MAP],
	                "ho_combination_key" : rowData.HO_COMBINATION_KEY
	            }];
	            var clob_settings = [{sso_id: logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
	        	var success_message = "Amount is successfully saved";
	        	console.log("The save json data was :::::",consolidatedJson);
	        	giltiConsolidatedGridFactory.saveManualData(consolidatedJson, vm.clob_settings, vm.logged_in_user).then(function(data){
	        		console.log("Result from rep Trans save data was ---------------::::", data);
	        		if(null != data.data.errorMessage && data.data.errorMessage != "null"){
	        			console.log("There was the error message and the message is ", data.data.errorMessage);
	        			AlertService.add("error", data.data.errorMessage, 4000);
	        		}else {
	        			console.log("There wa no error message and the message was ", data.data.errorMessage);
	        			AlertService.add("success", success_message, 4000);
	        			$uibModalInstance.dismiss('cancel');
	        			var args = {combination_key: rowData.HO_COMBINATION_KEY, gridFilter: {combination_key: rowData.HO_COMBINATION_KEY}};
	        			$rootScope.$emit('gridUpdate', args);
	        		}
	        	});
	        }
		
		
        
	}
	  }); 