define([
	   'angular'

	], function () {
	   'use strict';

	   var services =  angular.module('app.partnershipCapitalAccountServiceTax',[])
	        .factory("partnershipCapitalFactoryTax", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
	    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
		           
	           var returnObj = {};
			   var partnershipCapitalFactoryTax ={};
			   partnershipCapitalFactoryTax.url ={};

			   partnershipCapitalFactoryTax.url.saveCapitalAmountsTax= GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=bapu69";

			   partnershipCapitalFactoryTax.saveCapitalAmountsTax = function(_callName ,_data){
				   console.log("data", _data);
				   console.log("callname",_callName);
				   var rowData = _data.rowData;
				   //$rootScope.$broadcast('gridRefresh', false);
				   returnObj.combination_key = _data.rowData.COMBINATION_KEY;

				   var service = $injector.get('JsonObjectFactory');
				   var defer = $q.defer();

				   var jsonObj = [{
					   combination_key: _data.rowData.COMBINATION_KEY,
					   trans_sub_type: _data.rowData[_data.colData.map+'_TRANS_TYPE'],
					   amount: _data.rowData[_data.colData.map],
					   ptr_combination_key:  _data.rowData.PTR_COMBINATION_KEY
				}];
				console.log(jsonObj);

				var jsonSettings = [{
					tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
					group_trans_key: _data.rowData.GROUP_TRANS_KEY
				}];



				   var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
				   var params = _.merge({}, GlobalService.globalParams, filterParams);
				   var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
				   var params = _.extend({jsonSettings: JSON.stringify(jsonSettings)}, params);

						/* var promise = $http({
							 method: "POST",
							 url: partnershipCapitalFactoryTax.url.saveCapitalAmountsTax,
							 data: params
						}). then(function (response){
								console.log(response.data);
								if(response.data.callSuccess === "1"){
									AlertService.add("success", "Data saved Successfully.",3000);
									var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
												 $rootScope.$emit('gridUpdate', args);
								}else{
									//AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");

									AlertService.add("error", response.data.errorMessage, 4000);
									$rootScope.$broadcast('gridRefresh', true);
								}

								return response.data;
								})

							return promise;
				}
			   */
				   service.saveJSON(partnershipCapitalFactoryTax.url.saveCapitalAmountsTax,params).then(function (data) {
					   //console.log(response.data);
					   if(data.callSuccess === "1"){
						   AlertService.add("success", "Data saved Successfully.",3000);
						   var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
							  $rootScope.$emit('gridUpdate', args);
									   defer.resolve(data);
					   }else {
						   AlertService.add("error", data.errorMessage);
						   defer.reject(data);
					   }
				   },function(data){
					   defer.reject(data);
				   });
				   return defer.promise;
   				}
		            
		   return partnershipCapitalFactoryTax;
		          }]);

		      
		      return services;
		   });


