define([
    'angular',
    './adminDashboardCtrl',
    './adminDashboardService.js',
    './component-settings-templates/datatable/datatablesSettingsCtrl.js',
    './component-settings-templates/chart/chartSettingsCtrl.js',
    // './directives/hc-directive',
    // './directives/work-bench.drv'
    //,'app.workBench','app.hcDirective'
    './component-settings-templates/regularTreeMap/regularTreeMapCtrl'

], function () {
    'use strict';
    return angular.module('app.adminDashboards', ['app.adminDashboardCtrl', 'app.adminDashboardService'])

    .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
        $stateProvider
            .state('admin.admin-dashboards', {
                url: '/admin-dashboards',
               // templateUrl: 'app/components/admin/adminDashboards/admin-dashboard.html',
              // controller: 'adminDashboardController as ctrl'
               templateUrl: 'app/components/admin/adminDashboards/admin-dashboard-list-tpl.html',
               controller: 'adminDashboardListCtrl as ctrl',
                type:'',
                // onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
                "api": {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=30156&grid_id=5121","grid_id":"5121"},
                access: 4
            });

    }]);

});