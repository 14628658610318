define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.schedule-OCtrl', [])
		.controller('scheduleOCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','scheduleOServiceFactory', 'JsonObjectFactory','GENERAL_CONFIG',  scheduleOCtrl])

		.controller('scheduleOCtrlModal', ['CommonUtilitiesFactory', '$filter','$scope','$rootScope',  'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','scheduleOServiceFactory', 'JsonObjectFactory','GENERAL_CONFIG', '$uibModalInstance',
			'rowData', 'colData', 'gridData',  scheduleOCtrlModal]);

	function scheduleOCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,scheduleOServiceFactory, JsonObjectFactory, GENERAL_CONFIG) {

		scheduleOCtrlModal(CommonUtilitiesFactory, $filter,$scope,$rootScope,ModalFactory, AlertService, $timeout,
			GlobalService,scheduleOServiceFactory, JsonObjectFactory, GENERAL_CONFIG, null,
			null, {}, {},this);
	}



	function scheduleOCtrlModal(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
								GlobalService,scheduleOServiceFactory, JsonObjectFactory, GENERAL_CONFIG, $uibModalInstance, rowData,colData,gridData,thisObj) {

		var  vm = this||thisObj;

		vm.a = "";
		vm.colData=colData;
		if(angular.isObject($uibModalInstance)) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				var args = {combination_keys: vm.rowData.HO_combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.HO_combination_key}};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};
		} else {
			vm.modal_name = null;
		}


		vm.onTabChange = function(e) {
			vm.selectedTabName = e;
			$timeout(function() {
				var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
				var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
				if (movingTab && selectedTab) {
					$(movingTab).width(selectedTab.offsetWidth);
					$(movingTab).height(selectedTab.offsetHeight)
						.css('line-height', selectedTab.offsetHeight + 'px')
						.css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
				}
			}, 50);
		};

		vm.loading = false;

		vm.modalTitle = "Schedule O";
		vm.rowData = rowData ||$scope.$parent.rowData;
		vm.colData=colData;
		vm.testing = 0;
		//vm.countries = CommonUtilitiesFactory.getCountriesFIR();
		
		 function getCountriesFIR() { 
         	
			 var params = {
						"tax_year": GlobalService.globalParams.tax_year,				                
		                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
		                
				};
				
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=kbuwei", params)
			.then(function(data) {
				if (data.callSuccess === "1") {
					vm.countries = data.jsonObject;
				}
     });
     }
	 vm.validateFileds=function() {
		 console.log('',vm)
	 }
     getCountriesFIR();
		vm.states = GlobalService.getStates();

		vm.scheduleODetails = [];
		vm.editFlag = false;
		vm.tabC_flag = false;
		vm.tabD_flag = false;
		vm.tabE_flag = false;
		vm.checked= false;
		$rootScope.activeScheduleTab = 0;

		vm.selectedPartOneData = {};
		vm.selectedPartTwoData = {};
		vm.selectedSuppleInfoData = {};

		vm.selectedPartOneDataCloned = {};
		vm.selectedPartTwoDataCloned = {};
		vm.selectedSuppleInfoDataCloned = {};

		vm.transferArray = [];
		vm.transferObj = [];
		vm.reportableTransferAttrib = [];
		vm.isReportableTransferDataPresent = false;

		vm.dispositionArray = [];
		vm.dispositionObj = [];
		vm.reportableDispositionAttrib = [];
		vm.isReportableDispositionDataPresent = false;

		vm.supplementalArray = [];
		vm.supplementalObj = [];
		vm.supplementalInformationAttrib = [];
		vm.isSupplementalInformationPresent = false;

		/*vm.typeOfProperty = CommonUtilitiesFactory.getTypeOfProperty();
		vm.partnerDetails = CommonUtilitiesFactory.getPartnerDetails();
		vm.allocationMethod = CommonUtilitiesFactory.getAllocationMethods();
		vm.gainRecognition = CommonUtilitiesFactory.getGainRecognition();
		vm.mannerOfDisposition = CommonUtilitiesFactory.getMannerOfDisposition();*/
		
		  function getTypeOfPerperty() {
            
			  var params = {
						"tax_year": GlobalService.globalParams.tax_year,				                
		                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
		                
				};

			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=i9l17s", params)
			.then(function(data) {
			if (data.callSuccess === "1") {
			vm.typeOfProperty = data.jsonObject;
			}
			});
				}
			getTypeOfPerperty();
		
			function getPartnerDetails() {
	            
				var params = {
						"tax_year": GlobalService.globalParams.tax_year,				                
		                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
		                
				};

				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=x4g3o2", params)
				.then(function(data) {
				if (data.callSuccess === "1") {
				vm.partnerDetails = _.filter(data.jsonObject,{COMBINATION_KEY:rowData.COMBINATION_KEY});
				}
				});
					}
				getPartnerDetails();
		

				function getAllocationMethod() {
	            
					var params = {
							"tax_year": GlobalService.globalParams.tax_year,				                
			                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
			                
					};

				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=jzx0gy", params)
				.then(function(data) {
				if (data.callSuccess === "1") {
				vm.allocationMethod = data.jsonObject;
				}
				});
					}
				getAllocationMethod();
			
				function getGainRecognition() {
	            
					var params = {
							"tax_year": GlobalService.globalParams.tax_year,				                
			                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
			                
					};

				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=0ovnb1", params)
				.then(function(data) {
				if (data.callSuccess === "1") {
				vm.gainRecognition = data.jsonObject;
				}
				});
					}
				getGainRecognition();
         
				function getMannerOfDisposition() {
		            
					var params = {
							"tax_year": GlobalService.globalParams.tax_year,				                
			                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
			                
					};

					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=pc6cco", params)
					.then(function(data) {
					if (data.callSuccess === "1") {
					vm.mannerOfDisposition = data.jsonObject;
					}
					});
						}
				getMannerOfDisposition();

        
		
		
		vm.date_flag_required = false;
		vm.date_flag_range = false;
		//vm.date_range_disposition1 = false;
		vm.partnerDetailsFlag = false;
		vm.partnerDetailsFlag1 = false;
		vm.typeOfPropertyFlag = false;
		vm.typeOfPropertyFlag1 = false;
		vm.numberOfItemTransferredFlag = false;
		vm.secAllocationMethodFlag = false;
		/*vm.gainRecognitionFlag = false;
        vm.mannerOfDispositionFlag = false;*/

		$scope.crudLoading = false;

		vm.formattedDate = "";
		vm.calendar = {
			opened: false
		};
		vm.calendar1 = {
			opened: false
		};

		vm.schedulenavmap =[
			{
				key: "8865",
				value : "8865",
				index  : 0,
				row: 0
			},
			{
				key: "C",
				value : "add_new_reportable_transfer",
				index  : "rept_trans",
				row: 1

			}, {
				key: "D",
				value : "add_new_reportable_disposition",
				index : "rept_disp",
				row: 2
			},
			{
				key: "E",
				value : "add_new_supplemental_information",
				index : "supple_info",
				row: 3
			}
		];

		/* vm.cancel = function () {
             $uibModalInstance.dismiss('cancel');
             var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY:vm.rowData.COMBINATION_KEY}};
             $rootScope.$emit('gridUpdate', args);
         };
         */




		///////////////////////////////////////////

		function loadDetails() {
			vm.testing = 1;
			var params = {
				"combination_key":vm.rowData.HO_COMBINATION_KEY,
				"irs_form_no":'F8865',
				"jcd_key" : GlobalService.globalParams.jcd_key == '' || GlobalService.globalParams.jcd_key == undefined ? 250 :  GlobalService.globalParams.jcd_key
			};
			console.log("rowdata", vm.rowData);
			console.log("VM for the schedule P :::::: ",vm);
			console.log("Combination key ::::::: ",vm.rowData.HO_COMBINATION_KEY);
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=i45e84", params).then(function(data) {
				console.log("Response Data x ::::::::", data.jsonObject);
				if (data.callSuccess == "1") {
					vm.isShow = 1;
					vm.scheduleODetails = data.jsonObject;
					//console.log("length", vm.scheduleODetails.length);
					vm.scheduleODetailsTemp = data.jsonObject;
					vm.arraySize = vm.scheduleODetailsTemp;
					console.log("check this out ::::::: ", vm.scheduleODetailsTemp);

					for(var i=1; i <data.jsonObject.length; i++) {
						var tempString = vm.scheduleODetails[i].object_id;
						var tempValue = vm.scheduleODetails[i].attrib_value;


						vm.scheduleODetails[i] = {
							i : vm.scheduleODetails[i],
							"tax_year": tempString.split('~')[0],
							"trans_type_key":tempString.split('~')[1],
							"attrib_name":tempValue.split('~')[0],
							"attrib_desc":tempValue.split('~')[3],
							"line_attrib_key":tempValue.split('~')[1],
							"trans_details_key":tempString.split('~')[3],
							"occurrence":tempString.split('~')[4],
							"VALUE":vm.scheduleODetails[i].VALUE,
							"form_key":tempString.split('~')[6],
							"line_no" :vm.scheduleODetails[i].line_no
						};

					}
					setAttribute();

				} else {
					if (data.errorMessage == "no access") {
						AlertService.add("","Sorry you do not have access to do the requested action.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
							"",
							"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
				}


				for(var i = 1; i<vm.scheduleODetails.length; i++) {
					var currentObj = vm.scheduleODetails[i];
					var lineNo;
					if(vm.scheduleODetails[i].attrib_name == "LINE_NO") {
						lineNo = currentObj["attrib_desc"];
						continue;
					}
					else {

						vm.scheduleODetails[i]["LINE_NO"] = lineNo;
					}
				}
				

				vm.transferObj = [];
				
				vm.transferArray =  _.filter(vm.scheduleODetails, function(o) { 
						return (o.line_no  == 'rept_trans' )
					});
					//vm.scheduleODetails.filter(entry => entry["LINE_NO"] === 'rept_trans'); 
				
				if(vm.transferArray.length > 0) {

					_.uniq(_.map(vm.transferArray,'occurrence')).forEach(function(p_occurrence){
					    var occList = _.filter(vm.transferArray,{occurrence:p_occurrence});
					    if(p_occurrence=='0'){
					    	return;
					    }
					    var temp={};
					    occList.forEach(function(currentObj){
					    	if(!currentObj['trans_details_key'] || currentObj['trans_details_key'] == '0'){
					    		return;
					    	}
					    	temp["LINE_NO"] = 'rept_trans';
						    temp["occurrence"] = p_occurrence;
					        temp["trans_details_key"] = currentObj['trans_details_key'];
					        var attrName = null;
					        if(currentObj['attrib_name'].indexOf("TRANSFER_DT") >= 0){
								attrName = "DATE_OF_TRANSFER";
								
							}else if(currentObj['attrib_name'].indexOf("PROPERTY_DESC") >= 0 ){
								attrName = "TYPE_OF_PROPERTY";
								
							}else if(currentObj['attrib_name'].indexOf("FAIR_MARKET_VALUE") >= 0 ){
								attrName = "FAIR_MARKET_VALUE";
								
							}else if(currentObj['attrib_name'].indexOf("COST_BASIS_AMT") >= 0){
								attrName = "COST_OR_OTHER_BASIS";
								
							}else if(currentObj['attrib_name'].indexOf("ALLOCATION_METHOD") >= 0){
								attrName = "ALLOCATION_METHOD";
								
							}else if(currentObj['attrib_name'].indexOf("GAIN_TRANSFER_AMT") >= 0){
								attrName ="GAIN_RECOGNIZED_ON_TRANSFER";
								
							}else{
								attrName = currentObj['attrib_name'];
							}
					        
					          temp[attrName] = currentObj['VALUE'];
					        
					    });
					    if(temp.LINE_NO){
					    	vm.transferObj.push(temp);
					    }
					});
				}
				
				vm.isReportableTransferDataPresent = vm.transferObj.length > 0;
				
				vm.dispositionObj = [];
				vm.dispositionArray =   _.filter(vm.scheduleODetails, function(o) { 
						return (o.line_no  == 'rept_disp' )
					}); //vm.scheduleODetails.filter(entry => entry["LINE_NO"] === 'rept_disp'); 
				
				if(vm.dispositionArray.length > 0 ) {
					_.uniq(_.map(vm.dispositionArray,'occurrence')).forEach(function(p_occurrence){
						if(p_occurrence=='0'){
					    	return;
					    }
					    var occList = _.filter(vm.dispositionArray,{occurrence:p_occurrence});
					    var temp={};
					    occList.forEach(function(currentObj){
					    	if(!currentObj['trans_details_key'] || currentObj['trans_details_key'] == '0'){
					    		return;
					    	}
					    	temp["LINE_NO"] = 'rept_disp';
						    temp["occurrence"] = p_occurrence;
					        temp["trans_details_key"] = currentObj['trans_details_key'];
					        var value = null;
					       
					            temp[currentObj['attrib_name']] = currentObj['VALUE'];
					       
					    });
					    vm.dispositionObj.push(temp);
					});
				}
															
			

				vm.isReportableDispositionDataPresent = vm.dispositionObj.length > 0;
				console.log("Check for the Data, Disposition", vm.isReportableDispositionDataPresent);

				vm.supplementalObj = [];
				vm.supplementalArray = _.filter(vm.scheduleODetails, function(o) { 
						return (o.line_no  == 'supple_info' )
					});
					// vm.scheduleODetails.filter(entry => entry["LINE_NO"] === 'supple_info'); 
				
				if(vm.supplementalArray.length > 0)
				{			
					_.uniq(_.map(vm.supplementalArray,'occurrence')).forEach(function(p_occurrence){
						if(p_occurrence=='0'){
					    	return;
					    }
					    var occList = _.filter(vm.supplementalArray,{occurrence:p_occurrence});
					    var temp={};
					    occList.forEach(function(currentObj){
					    	if(!currentObj['trans_details_key'] || currentObj['trans_details_key'] == '0'){
					    		return;
					    	}
					    	temp["LINE_NO"] = 'supple_info';
						    temp["occurrence"] = p_occurrence;
					        temp["trans_details_key"] = currentObj['trans_details_key'];
							temp[currentObj['attrib_name']] = currentObj['VALUE'];
					    });
					    vm.supplementalObj.push(temp);
					});
				}
				
				vm.isSupplementalInformationPresent = vm.scheduleODetails.filter(entry => entry["LINE_NO"] === 'supple_info' && entry.VALUE).length>0;
				
				console.log("Check for the Data, supple", vm.isSupplementalInformationPresent);

				console.log("Schedule p details +++++++++++++++++++",vm.scheduleODetails);
				console.log("Tab C data", vm.selectedPartOneData); // Tab C is Part I (Add new Acquisition)
				console.log("Tab D data", vm.selectedPartTwoData); // Tab D is Part II Add new Disposition
				console.log("Tab E data", vm.selectedSuppleInfoData); // Tab E is Part III Add Change in Proportional Interest



			});

		}


		vm.getTypeOfPropertyValue = function(propertyCode)
		{
			var tempPropertyCode = {};
			for(var i = 0; i<vm.typeOfProperty.length; i++)
			{
				if(vm.typeOfProperty[i].PROP_DESC ==  propertyCode)
				{
					tempPropertyCode = vm.typeOfProperty[i];
					break;
				}
			}
			return tempPropertyCode;
		};

		vm.getPartnerDetailsValue = function(partnerCode)
		{
			var tempPartnerCode = {};
			for(var i = 0; i<vm.partnerDetails.length; i++)
			{
				if(vm.partnerDetails[i].PARTNER_CODE ==  partnerCode)
				{
					tempPartnerCode = vm.partnerDetails[i];
					break;
				}
			}
			return tempPartnerCode;
		};

		vm.getAllocationMethodValue = function(partnerCode)
		{
			var tempAllocationCode = {};
			for(var i = 0; i<vm.allocationMethod.length; i++)
			{
				if(vm.allocationMethod[i].PROP_TYPE ==  partnerCode)
				{
					tempAllocationCode = vm.allocationMethod[i];
					break;
				}
			}
			return tempAllocationCode;
		};

		vm.getGainRecognitionValue = function(partnerCode)
		{
			var tempGainCode = {};
			for(var i = 0; i<vm.gainRecognition.length; i++)
			{
				if(vm.gainRecognition[i].PROP_TYPE == partnerCode)
				{
					tempGainCode = vm.gainRecognition[i];
					break;
				}
			}
			return tempGainCode;
		};

		vm.getMannerOfDispositionValue = function(partnerCode)
		{
			var tempDispositionCode = {};
			for(var i = 0; i<vm.mannerOfDisposition.length; i++)
			{
				if(vm.mannerOfDisposition[i].PROP_TYPE == partnerCode)
				{
					tempDispositionCode = vm.mannerOfDisposition[i];
					break;
				}
			}
			return tempDispositionCode;
		};
//

		vm.isDefinedOnSave = function(value) {
			vm.partnerDetailsFlag  = !(angular.isDefined(value)) || value == "";
			vm.partnerDetailsFlag1  = !(angular.isDefined(value)) || value == "";
			vm.typeOfPropertyFlag  = !(angular.isDefined(value)) || value == "";
			vm.typeOfPropertyFlag1  = !(angular.isDefined(value)) || value == "";
			vm.numberOfItemTransferredFlag  = !(angular.isDefined(value)) || value == "";
			vm.secAllocationMethodFlag  = !(angular.isDefined(value)) || value == "";
			vm.gainRecognitionFlag  = !(angular.isDefined(value)) || value == "";
			vm.mannerOfDispositionFlag  = !(angular.isDefined(value)) || value == "";
		};

		//////////Reset button functionality////////////
		vm.reset = function(tab,$root) {
			console.log(tab);
			switch(tab) {
				case 1:
					if(vm.editFlag) {
						vm.selectedPartOneData = {};
						vm.selectedPartOneData = angular.copy(vm.selectedPartOneDataCloned);
						vm.isDefinedOnSave(vm.selectedPartOneData.PARTNER_DETAILS);
					}
					else {
						vm.selectedPartOneData = {};
						vm.selectedPartOneData.PERCENTAGE_INTEREST = null;
					}
					vm.date_flag_transfer = false;
					vm.date_range_transfer = false;
					break;

				case 2:
					if(vm.editFlag) {
						vm.selectedPartTwoData = {};
						vm.selectedPartTwoData = angular.copy(vm.selectedPartTwoDataCloned);						
					} else {
						vm.selectedPartTwoData = {};
					}
					//vm.date_range_disposition1 = false;
					vm.date_flag_disposition=false;
					vm.partnerDetailsFlag1 = false;
					vm.date_range_disposition = false;
					break;

				case 3:
					if(vm.editFlag) {
						vm.selectedSuppleInfoData  = {};
						vm.selectedSuppleInfoData  = angular.copy(vm.selectedSuppleInfoDataCloned);
					}
					else {
						vm.selectedSuppleInfoData  = {};
					}
					break;
			}
		}


		loadDetails();

		///////////////Getting the attribute names from the JSON Data for all the tabs////////////
		function setAttribute()
		{
			var params = {
				"tax_year":GlobalService.globalParams.tax_year,
				"scenario":GlobalService.globalParams.scenario,
				"jcd_key":GlobalService.globalParams.jcd_key == '' || GlobalService.globalParams.jcd_key == undefined ? 250 :  GlobalService.globalParams.jcd_key,
				"irs_form_no":'F8865'
			};
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=1vuc30",params).then(function(res) {

				console.log("Response Data y ::", res.jsonObject);
				if (res.callSuccess == "1") {
					console.log("Response data : " , res.jsonObject);

					vm.reportableTransferAttrib = JSON.parse(res.jsonObject[0].LINE1_ATTRIB);
					vm.reportableDispositionAttrib = JSON.parse(res.jsonObject[0].LINE2_ATTRIB);
					vm.supplementalInformationAttrib = JSON.parse(res.jsonObject[0].LINE3_ATTRIB);

				}
				else {

					AlertService.add(
						"error",
						"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
						4000);
					vm.userMessage = "!! Unable to perform Selected Action...";
				}


			});

		}
		setAttribute();

		vm.changeDetails = function(tab)
		{
			if(tab == 1 ) {
				vm.selectedPartOneData.PARTNER_DETAILS = vm.selectedPartOneData.PARTNER_DETAILS != null ? vm.selectedPartOneData.PARTNER_DETAILS : "";
				vm.selectedPartOneData.TYPE_OF_PROPERTY = vm.selectedPartOneData.TYPE_OF_PROPERTY != null ? vm.selectedPartOneData.TYPE_OF_PROPERTY : "";
				vm.selectedPartOneData.ALLOCATION_METHOD = vm.selectedPartOneData.ALLOCATION_METHOD != null ? vm.selectedPartOneData.ALLOCATION_METHOD : "";
				vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION = vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION != null ? vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION : "";
			}
			else if(tab == 2){
				vm.selectedPartTwoData.PARTNER_DETAILS = vm.selectedPartTwoData.PARTNER_DETAILS != null ? vm.selectedPartTwoData.PARTNER_DETAILS : "";
				vm.selectedPartTwoData.TYPE_OF_PROPERTY = vm.selectedPartTwoData.TYPE_OF_PROPERTY != null ? vm.selectedPartTwoData.TYPE_OF_PROPERTY : "";
				vm.selectedPartTwoData.MANNER_OF_DISPOSITION = vm.selectedPartTwoData.MANNER_OF_DISPOSITION != null ? vm.selectedPartTwoData.MANNER_OF_DISPOSITION : "";
			}
			else
				vm.selectedSuppleInfoData.PARTNER_DETAILS = vm.selectedSuppleInfoData.PARTNER_DETAILS != null ? vm.selectedSuppleInfoData.PARTNER_DETAILS : "";
		};

		
			
			
		vm.openCalendar1 = function() {
			vm.calendar1.opened = true;
		};



		vm.openCalendar = function() {
			vm.calendar.opened = true;
		};

		vm.validateForm = function(value, tab,field){

			vm.isRequired = true;
			vm.errorFlagPartner = false;
			vm.errorFlagGain = false;
			vm.errorFlagManner = false;
			
			
			
			switch(value){
				case "NA":
					vm.errorFlagGain = true;
					break;
				case "0":
					vm.errorFlagManner= true;
					break;
				case "1":
					vm.selectedPartOneData.NUMBER_OF_ITEMS_TRANSFERRED = null;
					vm.selectedPartOneData.COST_OR_OTHER_BASIS = null;
					vm.selectedPartOneData.GAIN_RECOGNIZED_ON_TRANSFER = null;
					vm.selectedPartOneData.ALLOCATION_METHOD = null;
					vm.isRequired = false;
					break;
				case undefined:
					vm.errorFlagPartner=true;
					break;
				default:
					break;
			}

			vm.changeDetails(tab);
			var typeOfProperty;
            if(vm.selectedPartOneData && angular.isDefined(vm.selectedPartOneData.TYPE_OF_PROPERTY)){
                typeOfProperty = vm.selectedPartOneData.TYPE_OF_PROPERTY.PROP_TYPE;
            }
			switch(tab){
				case 1:{
					switch(field){
					case "PARTNER_DETAILS": {
						vm.partnerDetailsFlag  = !(angular.isDefined(vm.selectedPartOneData.PARTNER_DETAILS)) || vm.selectedPartOneData.PARTNER_DETAILS == "";
						break;
					}
					case "TYPE_OF_PROPERTY": {
						vm.typeOfPropertyFlag  = !(angular.isDefined(vm.selectedPartOneData.TYPE_OF_PROPERTY)) || vm.selectedPartOneData.TYPE_OF_PROPERTY == "";
						break;
					}
					case "ALLOCATION_METHOD":{
						if(!vm.typeOfPropertyFlag && typeOfProperty!='1'){
			                vm.secAllocationMethodFlag  = !(angular.isDefined(vm.selectedPartOneData.ALLOCATION_METHOD)) || vm.selectedPartOneData.ALLOCATION_METHOD == "";
			            }
						break;
					}
					case "NUMBER_OF_ITEMS_TRANSFERRED":{
						if(!vm.typeOfPropertyFlag && typeOfProperty!='1'){
							vm.numberOfItemTransferredFlag  = !(angular.isDefined(vm.selectedPartOneData.NUMBER_OF_ITEMS_TRANSFERRED)) || vm.selectedPartOneData.NUMBER_OF_ITEMS_TRANSFERRED == "";
						}
						break;
					}
					case "SUBJECT_TO_GAIN_RECOGNITION":{
						vm.gainRecognitionFlag  = !(angular.isDefined(vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION)) || vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION == "";
						break;
					}
					default:
						break
					}
					break;
				}
				case 2: {
					switch(field){
						case "PARTNER_DETAILS.PARTNER_CODE": {
							vm.partnerDetailsFlag1  = !(angular.isDefined(vm.selectedPartTwoData.PARTNER_DETAILS)) || vm.selectedPartTwoData.PARTNER_DETAILS == "";
							break;
						}
						case "TYPE_OF_PROPERTY": {
							vm.typeOfPropertyFlag1  = !(angular.isDefined(vm.selectedPartTwoData.TYPE_OF_PROPERTY)) || vm.selectedPartTwoData.TYPE_OF_PROPERTY == "";
							break;
						}
						default:
							break
					}
					break;
				}
				default:
					break;
			}

		};

		vm.formatDate = function(tab) {

			var dateInputData = "";
			var dateDispositionData = "";
			var start_date = new Date(vm.rowData.TAX_YEAR_BEGIN);
			var end_date = new Date(vm.rowData.TAX_YEAR_END);

			if(tab == 1) {
				dateInputData = vm.selectedPartOneData.DATE_OF_TRANSFER;
				//vm.selectedPartOneData.DATE_OF_TRANSFER = new Date(vm.selectedPartOneData.DATE_OF_TRANSFER);
				vm.date_flag_transfer = dateInputData == null || dateInputData == "";

				if( (dateInputData < start_date) || (dateInputData > end_date ) ) {
					vm.date_range_transfer = true;
				}
			else {
				vm.date_range_transfer = false;
				//vm.date_flag_transfer = false;
			}
			}
			else if(tab == 2) {
				dateDispositionData = vm.selectedPartTwoData.DATE_OF_DISPOSITION;
				vm.date_flag_disposition = dateDispositionData == null || dateDispositionData == "";

				if( (dateDispositionData < start_date) || (dateDispositionData > end_date ) )
				{
					vm.date_range_disposition = true;
				}
				else
				{
					vm.date_flag_disposition = false;
					vm.date_range_disposition = false;
				}
			}
		};

		vm.formatDate1 = function(tab) {
			var dateOriginalTransferData = "";
			var start_date = new Date(vm.rowData.TAX_YEAR_BEGIN);
			var end_date = new Date(vm.rowData.TAX_YEAR_END);

			if(tab == 2) {
				dateOriginalTransferData = vm.selectedPartTwoData.DATE_OF_ORIGINAL_TRANSFER;
				//vm.date_range_disposition1 = dateOriginalTransferData && (dateOriginalTransferData < start_date) || (dateOriginalTransferData > end_date);
			}
		};


		/////////////////////edit functionality//////////////////
		vm.edit8865ScheduleO = function(item) {
			vm.editFlag = true;
			vm.loading = true;
			var data = {};

			angular.forEach(vm.schedulenavmap, function (itemOfItems) {
				if (itemOfItems.index == item.LINE_NO){
					console.log("itemOfItems inside loop", itemOfItems.value);
					$rootScope.activeScheduleTab = itemOfItems.row;

					switch(item.LINE_NO.toString()) {
						case "rept_trans":
							vm.selectedPartOneData = item;

							if ((angular.isDefined(item.PARTNER_DETAILS)))
								vm.selectedPartOneData.PARTNER_DETAILS = vm.getPartnerDetailsValue(item.PARTNER_DETAILS);

							if ((angular.isDefined(item.TYPE_OF_PROPERTY)))
								vm.selectedPartOneData.TYPE_OF_PROPERTY = vm.getTypeOfPropertyValue(item.TYPE_OF_PROPERTY);

							if (item.DATE_OF_TRANSFER != null) {
								var dataSet = new Date(item.DATE_OF_TRANSFER);
								item.DATE_OF_TRANSFER = new Date(dataSet.getFullYear(), dataSet.getMonth(), dataSet.getDate());
							} else
								item.DATE_OF_TRANSFER = "";

							if ((angular.isDefined(item.NUMBER_OF_ITEMS_TRANSFERRED)))
								vm.selectedPartOneData.NUMBER_OF_ITEMS_TRANSFERRED = item.NUMBER_OF_ITEMS_TRANSFERRED;

							if ((angular.isDefined(item.FAIR_MARKET_VALUE)))
								vm.selectedPartOneData.FAIR_MARKET_VALUE = item.FAIR_MARKET_VALUE;

							if ((angular.isDefined(item.COST_OR_OTHER_BASIS)))
								vm.selectedPartOneData.COST_OR_OTHER_BASIS = item.COST_OR_OTHER_BASIS;

							if ((angular.isDefined(item.ALLOCATION_METHOD)))
								vm.selectedPartOneData.ALLOCATION_METHOD = vm.getAllocationMethodValue(item.ALLOCATION_METHOD);

							if ((angular.isDefined(item.GAIN_RECOGNIZED_ON_TRANSFER)))
								vm.selectedPartOneData.GAIN_RECOGNIZED_ON_TRANSFER = item.GAIN_RECOGNIZED_ON_TRANSFER;

							if ((angular.isDefined(item.PERCENTAGE_INTEREST)))
								vm.selectedPartOneData.PERCENTAGE_INTEREST = item.PERCENTAGE_INTEREST;

							if ((angular.isDefined(item.SUBJECT_TO_GAIN_RECOGNITION)))
								vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION = vm.getGainRecognitionValue(item.SUBJECT_TO_GAIN_RECOGNITION);

							vm.selectedPartOneDataCloned = angular.copy(vm.selectedPartOneData);

							break;

						case "rept_disp":
							vm.selectedPartTwoData = item;

							if(angular.isDefined(item.PARTNER_DETAILS))
								vm.selectedPartTwoData.PARTNER_DETAILS = vm.getPartnerDetailsValue(item.PARTNER_DETAILS);

							if(angular.isDefined(item.TYPE_OF_PROPERTY))
								vm.selectedPartTwoData.TYPE_OF_PROPERTY = vm.getTypeOfPropertyValue(item.TYPE_OF_PROPERTY);

							if(item.DATE_OF_ORIGINAL_TRANSFER != null)
							{
								var dataSet = new Date(item.DATE_OF_ORIGINAL_TRANSFER);
								item.DATE_OF_ORIGINAL_TRANSFER = new Date(dataSet.getFullYear(), dataSet.getMonth(), dataSet.getDate());
							}
							else
								item.DATE_OF_ORIGINAL_TRANSFER = "";

							if(item.DATE_OF_DISPOSITION != null)
							{
								var dataSet = new Date(item.DATE_OF_DISPOSITION);
								item.DATE_OF_DISPOSITION = new Date(dataSet.getFullYear(), dataSet.getMonth(), dataSet.getDate());
							}
							else
								item.DATE_OF_DISPOSITION = "";

							if((angular.isDefined(item.MANNER_OF_DISPOSITION)))
								vm.selectedPartTwoData.MANNER_OF_DISPOSITION = vm.getMannerOfDispositionValue(item.MANNER_OF_DISPOSITION);

							if((angular.isDefined(item.GAIN_RECOGNIZED_BY_PARTNERSHIP)))
								vm.selectedPartTwoData.GAIN_RECOGNIZED_BY_PARTNERSHIP = item.GAIN_RECOGNIZED_BY_PARTNERSHIP;

							if((angular.isDefined(item.DEPRECIATION_RECAPTURE_RECOGNIZED)))
								vm.selectedPartTwoData.DEPRECIATION_RECAPTURE_RECOGNIZED = item.DEPRECIATION_RECAPTURE_RECOGNIZED;

							if((angular.isDefined(item.GAIN_ALLOCATED_TO_PARTNER)))
								vm.selectedPartTwoData.GAIN_ALLOCATED_TO_PARTNER = item.GAIN_ALLOCATED_TO_PARTNER;

							if((angular.isDefined(item.DEPRECIATION_RECAPTURE_ALLOCATED)))
								vm.selectedPartTwoData.DEPRECIATION_RECAPTURE_ALLOCATED = item.DEPRECIATION_RECAPTURE_ALLOCATED;

							vm.selectedPartTwoDataCloned = angular.copy(vm.selectedPartTwoData);

							break;

						case "supple_info":

							vm.selectedSuppleInfoData = item;
							if((angular.isDefined(item.PARTNER_DETAILS)))
								vm.selectedSuppleInfoData.PARTNER_DETAILS = vm.getPartnerDetailsValue(item.PARTNER_DETAILS);

							if((angular.isDefined(item.SUPPLEMENTAL_INFORMATION)))
								vm.selectedSuppleInfoData.SUPPLEMENTAL_INFORMATION = item.SUPPLEMENTAL_INFORMATION;

							vm.selectedSuppleInfoDataCloned = angular.copy(vm.selectedSuppleInfoData);

							break;

						default:
							break;
					}
					return;
				}
			});

		};

		///////////////////////////////////Delete button functionality/////////////////////////////////////////

		vm.deleteSchedule = function(item)
		{
			var message = "Schedule has been deleted";
			var json_arrOBJ=[];
        	vm.scheduleODetails.filter(function(e){ return  e.occurrence == item.occurrence && e.i.line_no==item.LINE_NO; })
        		.forEach(function(e){
        							json_arrOBJ.push([{
        								occurence:e.occurrence,
        								"tax_year":GlobalService.globalParams.tax_year,
        								"trans_type_key":e.trans_type_key,
        								"trans_dtls_key":e.trans_details_key,
        								"trans_status":"A",
        								"line_no":item.LINE_NO	
        								}]);
        });
        	
			//console.log(json_arrOBJ);
        	var scheduleDetails = {"addrows":[], "editrows":[], "removerows":json_arrOBJ};
        	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
        	
        	scheduleOServiceFactory.saveReportableTransfer(scheduleSettings,
					scheduleDetails).then(
					function(result) {

                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        loadDetails();
                        AlertService.add("success", message, 4000);
                    }

			});
		};
		vm.checkValidation = function() {
			vm.partnerDetailsFlag  = !(angular.isDefined(vm.selectedPartOneData.PARTNER_DETAILS)) || vm.selectedPartOneData.PARTNER_DETAILS == "";
            vm.typeOfPropertyFlag  = !(angular.isDefined(vm.selectedPartOneData.TYPE_OF_PROPERTY)) || vm.selectedPartOneData.TYPE_OF_PROPERTY == "";
            var dateInputDataCheck = vm.selectedPartOneData.DATE_OF_TRANSFER;
            var start_date = new Date(vm.rowData.TAX_YEAR_BEGIN);
            var end_date = new Date(vm.rowData.TAX_YEAR_END);

            var typeOfProperty;
            if(angular.isDefined(vm.selectedPartOneData.TYPE_OF_PROPERTY)){
                typeOfProperty = vm.selectedPartOneData.TYPE_OF_PROPERTY.PROP_TYPE;
            }

            if(!vm.typeOfPropertyFlag && typeOfProperty!='1'){
                vm.secAllocationMethodFlag  = !(angular.isDefined(vm.selectedPartOneData.ALLOCATION_METHOD)) || vm.selectedPartOneData.ALLOCATION_METHOD == "";
                vm.numberOfItemTransferredFlag  = !(angular.isDefined(vm.selectedPartOneData.NUMBER_OF_ITEMS_TRANSFERRED)) || vm.selectedPartOneData.NUMBER_OF_ITEMS_TRANSFERRED == "";
            }
            //vm.secAllocationMethodFlag  = !(angular.isDefined(vm.selectedPartOneData.ALLOCATION_METHOD)) || vm.selectedPartOneData.ALLOCATION_METHOD == "";
            vm.gainRecognitionFlag  = !(angular.isDefined(vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION)) || vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION == "";

            console.log("Flags _ - - _ Partner flag", vm.partnerDetailsFlag, " Type of property", vm.typeOfPropertyFlag, " Number of items",vm.numberOfItemTransferredFlag, " Section 704",vm.secAllocationMethodFlag);
            console.log("type of property", vm.selectedPartOneData.TYPE_OF_PROPERTY);
            console.log("type of property", vm.selectedPartOneData.ALLOCATION_METHOD);

            
            vm.date_flag_transfer_error = dateInputDataCheck == null || dateInputDataCheck == "" || !(angular.isDefined(vm.selectedPartOneData.DATE_OF_TRANSFER));

            if(vm.date_flag_transfer_error){
                vm.date_flag_transfer = true;
            } else{
                vm.date_flag_transfer = false;
            }

            if(dateInputDataCheck < start_date || dateInputDataCheck > end_date ) {
                vm.date_flag_transfer_error = true;
            }
            return (vm.partnerDetailsFlag || vm.typeOfPropertyFlag || vm.date_flag_transfer_error || vm.gainRecognitionFlag
                    || (typeOfProperty != "1" && (vm.secAllocationMethodFlag || vm.numberOfItemTransferredFlag)));
		}

		///////////////Save function for tab "Add new Stock Acquisition"//////////
		vm.reportable_Transfer = function($root, tab) {
            var isNotValid = vm.checkValidation();

            if(isNotValid){
                AlertService.add("error", "Please fill out required fields.", 3000);
                $scope.crudLoading = false;
            }
			else {
				vm.date_flag_transfer= false;
				vm.date_range_transfer= false;
				vm.partnerDetailsFlag = false;
				vm.typeOfPropertyFlag = false;
				vm.numberOfItemTransferredFlag = false;
				vm.secAllocationMethodFlag = false;
				/*vm.gainRecognitionFlag = false;*/

				var editRow = [];
				var addRow = [];
				var deleteRow = [];
				var scheduleSettings = [{
					"tax_year":GlobalService.globalParams.tax_year,
					"scenario":GlobalService.globalParams.scenario,
					"jcd_key":GlobalService.globalParams.jcd_key == '' || GlobalService.globalParams.jcd_key == undefined ? 250 :  GlobalService.globalParams.jcd_key
				}];


				var trans_type_key = vm.transferArray.length > 0 ? vm.transferArray[0].trans_type_key : null;
				var currentOccurence = vm.selectedPartOneData.occurrence;
				var currentTransDetailsKey = vm.selectedPartOneData.trans_details_key;
				
				var validAttributes = ["PARTNER_DETAILS","PERCENTAGE_INTEREST","SUBJECT_TO_GAIN_RECOGNITION","NUMBER_OF_ITEMS_TRANSFERRED"];
				var variableAttr = ["DATE_OF_TRANSFER","TYPE_OF_PROPERTY","FAIR_MARKET_VALUE", "COST_OR_OTHER_BASIS","ALLOCATION_METHOD","GAIN_RECOGNIZED_ON_TRANSFER"];
				var attributes = ["8865_O_STOCK_NOTES_","8865_O_INVENTORY_","8865_O_TNGBL_PROP_TRADE_","8865_O_INTGBL_PROP_197F9_","8865_O_OTHER_PROP_"];
				var attrName = null;
				var attrKey = null;
				
				for(var i = 0; i< variableAttr.length;i++){
					if(vm.selectedPartOneData.TYPE_OF_PROPERTY.PROP_TYPE != 1){
						
						var str = attributes[vm.selectedPartOneData.TYPE_OF_PROPERTY.PROP_TYPE - 2];
							if(variableAttr[i] == "DATE_OF_TRANSFER"){
								attrName = str + "TRANSFER_DT";
								validAttributes.push(attrName);
							}else if(variableAttr[i] == "TYPE_OF_PROPERTY"){
								attrName = str + "PROPERTY_DESC";
								validAttributes.push(attrName);
							}else if(variableAttr[i] == "FAIR_MARKET_VALUE"){
								attrName = str + "FAIR_MARKET_VALUE";
								validAttributes.push(attrName);
							}else if(variableAttr[i] == "COST_OR_OTHER_BASIS"){
								attrName = str + "COST_BASIS_AMT";
								validAttributes.push(attrName);
							}else if(variableAttr[i] == "ALLOCATION_METHOD"){
								attrName = str 	+ "ALLOCATION_METHOD";
								validAttributes.push(attrName);
							}else if(variableAttr[i] == "GAIN_RECOGNIZED_ON_TRANSFER"){
								attrName = str + "GAIN_TRANSFER_AMT";
								validAttributes.push(attrName);
							}
							
					}else{
						validAttributes.push(variableAttr[i]);
					}
				}
			
				for(var i = 0; i < vm.reportableTransferAttrib.length; i++)
				{
					if(validAttributes.indexOf(vm.reportableTransferAttrib[i].attrib_name) >= 0){
						var currentTransDetailsKey = null;
						if(vm.editFlag){
							var temp = vm.transferArray;
							for(var j=0; j<temp.length;j++){
								for(var k=0;k<vm.reportableTransferAttrib.length;k++){
									if(vm.reportableTransferAttrib[k].line_attrib_key ==temp[j].line_attrib_key && 
											temp[j].occurrence  == currentOccurence){
										currentTransDetailsKey = temp[j].trans_details_key;
										temp[j].line_attrib_key = "";
										temp[j].occurrence = "";
										break;
									}
								}
								if(currentTransDetailsKey != null){
									break;
								}
							}
						}else{
							
							currentTransDetailsKey = null;
						}

	            		
						var value = null;
						if(vm.reportableTransferAttrib[i].attrib_name == 'PARTNER_DETAILS')
						{
							if(angular.isDefined(vm.selectedPartOneData.PARTNER_DETAILS) ) {
								value = vm.selectedPartOneData.PARTNER_DETAILS.PARTNER_CODE;
							}
						}
						else if(vm.reportableTransferAttrib[i].attrib_name == 'DATE_OF_TRANSFER' || 
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_STOCK_NOTES_TRANSFER_DT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INVENTORY_TRANSFER_DT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_TNGBL_PROP_TRADE_TRANSFER_DT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_197F9_TRANSFER_DT'||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_NOT_197F9_TRANSFER_DT' ||
								vm.reportableTransferAttrib[i].attrib_name ==  '8865_O_OTHER_PROP_TRANSFER_DT')
						{
							if(angular.isDefined(vm.selectedPartOneData.DATE_OF_TRANSFER) ) {
								value = $filter('date')(vm.selectedPartOneData.DATE_OF_TRANSFER, "MM/dd/yyyy");
							}
						}
						else if(vm.reportableTransferAttrib[i].attrib_name == 'TYPE_OF_PROPERTY' || 
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_STOCK_NOTES_PROPERTY_DESC' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INVENTORY_PROPERTY_DESC' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_TNGBL_PROP_TRADE_PROPERTY_DESC' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_197F9_PROPERTY_DESC'||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_NOT_197F9_PROPERTY_DESC' ||
								vm.reportableTransferAttrib[i].attrib_name ==  '8865_O_OTHER_PROP_PROPERTY_DESC')
						{
							if(angular.isDefined(vm.selectedPartOneData.TYPE_OF_PROPERTY) ) {
								value = vm.selectedPartOneData.TYPE_OF_PROPERTY.PROP_DESC;
							}
						}
						else if(vm.reportableTransferAttrib[i].attrib_name.indexOf('ALLOCATION_METHOD') >= 0)
						{
							if(angular.isDefined(vm.selectedPartOneData.ALLOCATION_METHOD) ) {
								value = vm.selectedPartOneData.ALLOCATION_METHOD.PROP_TYPE;
							}
						}
						else if(vm.reportableTransferAttrib[i].attrib_name.indexOf('FAIR_MARKET_VALUE') >= 0 )
						{
							if(angular.isDefined(vm.selectedPartOneData.FAIR_MARKET_VALUE) ) {
								value = vm.selectedPartOneData["FAIR_MARKET_VALUE"];
							}
						}
						else if(vm.reportableTransferAttrib[i].attrib_name == 'GAIN_RECOGNIZED_ON_TRANSFER' || 
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_STOCK_NOTES_GAIN_TRANSFER_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INVENTORY_GAIN_TRANSFER_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_TNGBL_PROP_TRADE_GAIN_TRANSFER_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_197F9_GAIN_TRANSFER_AMT'||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_NOT_197F9_GAIN_TRANSFER_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name ==  '8865_O_OTHER_PROP_GAIN_TRANSFER_AMT')
						{
							if(angular.isDefined(vm.selectedPartOneData.GAIN_RECOGNIZED_ON_TRANSFER) ) {
								value = vm.selectedPartOneData["GAIN_RECOGNIZED_ON_TRANSFER"];
							}
						}
						else if(vm.reportableTransferAttrib[i].attrib_name == 'COST_OR_OTHER_BASIS' || 
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_STOCK_NOTES_COST_BASIS_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INVENTORY_COST_BASIS_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_TNGBL_PROP_TRADE_COST_BASIS_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_197F9_COST_BASIS_AMT'||
								vm.reportableTransferAttrib[i].attrib_name == '8865_O_INTGBL_PROP_NOT_197F9_COST_BASIS_AMT' ||
								vm.reportableTransferAttrib[i].attrib_name ==  '8865_O_OTHER_PROP_COST_BASIS_AMT')
						{
							if(angular.isDefined(vm.selectedPartOneData.COST_OR_OTHER_BASIS) ) {
								value = vm.selectedPartOneData["COST_OR_OTHER_BASIS"];
							}
						}
						else if(vm.reportableTransferAttrib[i].attrib_name == 'SUBJECT_TO_GAIN_RECOGNITION')
						{
							if(angular.isDefined(vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION) ) {
								value = vm.selectedPartOneData.SUBJECT_TO_GAIN_RECOGNITION.PROP_TYPE	
							}
						}
						else
							value = vm.selectedPartOneData[vm.reportableTransferAttrib[i].attrib_name];
						
						
						
						
						var tempObj =[{
							"tax_year":GlobalService.globalParams.tax_year,
							"trans_type_key":trans_type_key,
							"combination_key":vm.rowData.HO_COMBINATION_KEY,
							"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
							"form_key":vm.transferArray[0].form_key,
							"line_no":'rept_trans',
							"occurence":vm.editFlag ? currentOccurence: null,
							"line_attrib_key" : vm.reportableTransferAttrib[i].line_attrib_key,
							"Attribute_Name": vm.reportableTransferAttrib[i].attrib_name,
							"Attribute_Value":value,
							"trans_dtls_key" : vm.editFlag ? currentTransDetailsKey: null,
							"trans_status":"A"}];
						
						editRow.push(tempObj);
					}


				}
				var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
				console.log("scheduleDetails-----------------------", scheduleDetails);
				var message = "New Reportable Transfer saved";

				scheduleOServiceFactory.saveReportableTransfer(scheduleSettings,
					scheduleDetails).then(
					function(result) {

						if (result.errorMessage
							&& result.errorMessage != 'null') {
							AlertService.add("error",
								result.errorMessage, 4000);
							$scope.crudLoading = false;

						} else {
							AlertService.add("success", message, 4000);
							$scope.crudLoading = false;
							if(!vm.editFlag)
							{
								loadDetails();
							}
						}

					});
			}
		} ;



		//////////////function to clear tab data
		vm.clearTabData = function(selectedTabIndex)
		{
			vm.editFlag = false;
			vm.date_range_transfer = false;
			vm.date_flag_transfer = false;
			vm.date_flag_disposition = false;
			vm.date_range_disposition = false;
			vm.partnerDetailsFlag = false;
			vm.partnerDetailsFlag1 = false;
			vm.typeOfPropertyFlag = false;
			vm.numberOfItemTransferredFlag = false;
			vm.secAllocationMethodFlag = false;
			/*vm.gainRecognitionFlag = false;
            vm.mannerOfDispositionFlag = false;*/

			if(selectedTabIndex == 0)
			{
				loadDetails();

			}

			if(selectedTabIndex == 1)
			{
				vm.selectedPartOneData = {};
				vm.tabC_flag = false;
				vm.selectedPartOneData.PERCENTAGE_INTEREST = null;

			}

			if(selectedTabIndex == 2)
			{
				vm.selectedPartTwoData = {};
				vm.tabD_flag = false;

			}

			if(selectedTabIndex == 3)
			{
				vm.selectedSuppleInfoData  = {};
				vm.tabE_flag = false;

			}


		}


		////// Function to save Add new Disposition//////
		vm.isNotValidDisposition=function(){
			vm.partnerDetailsFlag1  = !(angular.isDefined(vm.selectedPartTwoData.PARTNER_DETAILS)) || vm.selectedPartTwoData.PARTNER_DETAILS == "";
			vm.typeOfPropertyFlag1  = !(angular.isDefined(vm.selectedPartTwoData.TYPE_OF_PROPERTY)) || vm.selectedPartTwoData.TYPE_OF_PROPERTY == "";
			return ( vm.date_range_disposition || 
					(!(angular.isDefined(vm.selectedPartTwoData.DATE_OF_DISPOSITION)) || vm.selectedPartTwoData.DATE_OF_DISPOSITION == "")
				|| !(angular.isDefined(vm.selectedPartTwoData.PARTNER_DETAILS)) || vm.selectedPartTwoData.PARTNER_DETAILS == "" || !(angular.isDefined(vm.selectedPartTwoData.MANNER_OF_DISPOSITION))
				|| vm.selectedPartTwoData.MANNER_OF_DISPOSITION.PROP_TYPE=='0');
		}
		vm.reportable_Disposition = function($root, tab) {
			
			/*vm.mannerOfDisposition  = !(angular.isDefined(vm.selectedPartTwoData.MANNER_OF_DISPOSITION)) || vm.selectedPartTwoData.MANNER_OF_DISPOSITION == "";*/

			if(vm.isNotValidDisposition()) {
				vm.date_flag_disposition = !(angular.isDefined(vm.selectedPartTwoData.DATE_OF_DISPOSITION)) || vm.selectedPartTwoData.DATE_OF_DISPOSITION == "";
				$scope.crudLoading = false;
				AlertService.add("error", "Please fill out required fields. Partner Details, Date of Disposition & Manner of Disposition", 3000);
			} else {
				//$scope.schedulePForm.$submitted= false;
				vm.date_flag_disposition = false;
				vm.date_range_disposition = false;
				vm.partnerDetailsFlag1 = false;
				vm.typeOfPropertyFlag1 = false;
				/*vm.mannerOfDisposition = false;*/

				var editRow = [];
				var addRow = [];
				var deleteRow = [];
				var scheduleSettings = [{
					"tax_year":GlobalService.globalParams.tax_year,
					"scenario":GlobalService.globalParams.scenario,
					"jcd_key":GlobalService.globalParams.jcd_key == '' || GlobalService.globalParams.jcd_key == undefined ? 250 :  GlobalService.globalParams.jcd_key
				}];

				var trans_type_key = vm.dispositionArray.length > 0 ? vm.dispositionArray[0].trans_type_key : null;
				var currentOccurence = vm.selectedPartTwoData.occurrence;
				var currentTransDetailsKey = vm.selectedPartTwoData.trans_details_key;

				for(var i = 0; i < vm.reportableDispositionAttrib.length; i++)
				{	
					var currentTransDetailsKey = vm.editFlag?vm.scheduleODetails.find(function(e){
        			return e.line_attrib_key== vm.reportableDispositionAttrib[i].line_attrib_key && 
        			e.occurrence == currentOccurence;
					}).trans_details_key:null;
        		
					var value = null;
					if(vm.reportableDispositionAttrib[i].attrib_name == 'DATE_OF_ORIGINAL_TRANSFER'){
						if(angular.isDefined(vm.selectedPartTwoData.DATE_OF_ORIGINAL_TRANSFER) ) {
							value = $filter('date')(vm.selectedPartTwoData.DATE_OF_ORIGINAL_TRANSFER, "MM/dd/yyyy");
						}
					}
					else if(vm.reportableDispositionAttrib[i].attrib_name == 'PARTNER_DETAILS'){
						if(angular.isDefined(vm.selectedPartTwoData.PARTNER_DETAILS) ) {
							value = vm.selectedPartTwoData.PARTNER_DETAILS.PARTNER_CODE;
						}
					}
					else if(vm.reportableDispositionAttrib[i].attrib_name == 'TYPE_OF_PROPERTY'){
						if(angular.isDefined(vm.selectedPartTwoData.TYPE_OF_PROPERTY) ) {
							value = vm.selectedPartTwoData.TYPE_OF_PROPERTY.PROP_DESC;
						}
					}
					else if(vm.reportableDispositionAttrib[i].attrib_name == 'MANNER_OF_DISPOSITION'){
						if(angular.isDefined(vm.selectedPartTwoData.MANNER_OF_DISPOSITION) ) {
							value = vm.selectedPartTwoData.MANNER_OF_DISPOSITION.PROP_TYPE;
						}
					}
					else if(vm.reportableDispositionAttrib[i].attrib_name == 'DATE_OF_DISPOSITION'){
						if(angular.isDefined(vm.selectedPartTwoData.DATE_OF_DISPOSITION) ) {
							value = $filter('date')(vm.selectedPartTwoData.DATE_OF_DISPOSITION, "MM/dd/yyyy");
						}
					}
					else{
						value = vm.selectedPartTwoData[vm.reportableDispositionAttrib[i].attrib_name];
					}

					var tempObj =[{
						"tax_year":GlobalService.globalParams.tax_year,
						"trans_type_key":trans_type_key,
						"combination_key":vm.rowData.HO_COMBINATION_KEY,
						"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
						"form_key":vm.dispositionArray[0].form_key,
						"line_no":'rept_disp',
						"occurence":vm.editFlag ? currentOccurence: null,
						"line_attrib_key" : vm.reportableDispositionAttrib[i].line_attrib_key,
						"Attribute_Name": vm.reportableDispositionAttrib[i].attrib_name,
						"Attribute_Value":value,
						"trans_dtls_key" : vm.editFlag ? currentTransDetailsKey: null,
						"trans_status":"A"}];

					editRow.push(tempObj);

				}
				var scheduleDetailsDisposition = {"addrows":[], "editrows":editRow, "removerows":[]};
				console.log("scheduleDetails-----------------------", scheduleDetailsDisposition);
				var message = "New Stock Disposition is saved";

				scheduleOServiceFactory.saveReportableDisposition(scheduleSettings,
					scheduleDetailsDisposition).then(
					function(result) {

						if (result.errorMessage
							&& result.errorMessage != 'null') {
							AlertService.add("error",
								result.errorMessage, 4000);
							$scope.crudLoading = false;

						} else {
							AlertService.add("success", message, 4000);
							$scope.crudLoading = false;
							if(!vm.editFlag)
							{
								loadDetails();
							}
						}

					});
			}
		};


		////Add new Supplemental Information

		vm.supplemental_information= function($root, tab) {
			var editRow = [];
			var addRow = [];
			var deleteRow = [];
			var scheduleSettings = [{
				"tax_year":GlobalService.globalParams.tax_year,
				"scenario":GlobalService.globalParams.scenario,
				"jcd_key":GlobalService.globalParams.jcd_key == '' || GlobalService.globalParams.jcd_key == undefined ? 250 :  GlobalService.globalParams.jcd_key
			}];

			var trans_type_key = vm.supplementalArray.length > 0 ? vm.supplementalArray[0].trans_type_key : null;
        	var currentOccurence = (!vm.supplementalObj.length || !vm.supplementalObj[0].occurrence) ? null : vm.supplementalObj[0].occurrence;
			//var currentTransDetailsKey =  vm.supplementalArray[0].trans_details_key == 0 ? null : vm.supplementalArray[0].trans_details_key;
			//vm.partnerDetailsFlag  = !(angular.isDefined(vm.selectedSuppleInfoData.PARTNER_DETAILS)) || vm.selectedSuppleInfoData.PARTNER_DETAILS == "";

			//vm.partnerDetailsFlag = false;

			for(var i = 0; i < vm.supplementalInformationAttrib.length; i++)
			{	
				var currentTransDetailsKey = vm.supplementalObj.length>0?vm.scheduleODetails.find(function(e){
        			return e.line_attrib_key== vm.supplementalInformationAttrib[i].line_attrib_key && 
        			e.occurrence == currentOccurence;
					}).trans_details_key:null;
					
				var value = null;
				if(vm.reportableDispositionAttrib[i].attrib_name == 'PARTNER_DETAILS') {
					if (angular.isDefined(vm.selectedSuppleInfoData.PARTNER_DETAILS)) {
						value = vm.selectedSuppleInfoData.PARTNER_DETAILS.PARTNER_CODE;
					}
				}
				else{
					value = vm.selectedSuppleInfoData[vm.supplementalInformationAttrib[i].attrib_name];
				}

				var tempObj =[{
					"tax_year":GlobalService.globalParams.tax_year,
					"trans_type_key":trans_type_key,
					"combination_key":vm.rowData.HO_COMBINATION_KEY,
					"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
					"form_key":vm.supplementalArray[0].form_key,
					"line_no":'supple_info',
					"occurence":currentOccurence,
					"line_attrib_key" : vm.supplementalInformationAttrib[i].line_attrib_key,
					"Attribute_Name": vm.supplementalInformationAttrib[i].attrib_name,
					"Attribute_Value":value,
					"trans_dtls_key" : currentTransDetailsKey,
					"trans_status":"A"}];

				editRow.push(tempObj);

			}
			var scheduleSupplementalInformation = {"addrows":[], "editrows":editRow, "removerows":[]};
			console.log("scheduleDetails-----------------------", scheduleSupplementalInformation);
			var message = "New Supplemental information is saved";

			scheduleOServiceFactory.saveSupplementalInformation(scheduleSettings,
				scheduleSupplementalInformation).then(
				function(result) {

					if (result.errorMessage
						&& result.errorMessage != 'null') {
						AlertService.add("error",
							result.errorMessage, 4000);
						$scope.crudLoading = false;

					} else {
						AlertService.add("success", message, 4000);
						$scope.crudLoading = false;
						if(!vm.editFlag)
						{
							//console.log("loaDetails function called");
							//loadDetails();
						}
						loadDetails();
					}

				});

		};
		$scope.$on('workflowSave',function(event,data){
			vm.saveData();
		});
		vm.saveData = function()
		{
			vm.save(null, null);
		}
		vm.save = function(tab,$root) {

			var editRow = [];
			var addRow = [];
			var deleteRow = [];
			var scheduleSettings = [{
				"tax_year":GlobalService.globalParams.tax_year,
				"scenario":GlobalService.globalParams.scenario,
				"jcd_key":GlobalService.globalParams.jcd_key == '' || GlobalService.globalParams.jcd_key == undefined ? 250 :  GlobalService.globalParams.jcd_key}];

			if(tab == null)
				tab = $rootScope.activeScheduleTab;

			if ($scope.crudLoading) {
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			$scope.crudLoading = true;

			var data = {};
			console.log("tttttttttttt:::::::",$root);
			console.log(tab);
			vm.isSaveClicked = true;
			switch(tab) {
				case 0: vm.save1($root);
					break;
				case 1: vm.tabC_flag = true;
					vm.tabD_flag = false;
					vm.tabE_flag = false;
					vm.reportable_Transfer($root, tab);
					break;
				case 2: vm.tabC_flag = false;
					vm.tabD_flag = true;
					vm.tabE_flag = false;
					vm.reportable_Disposition($root,tab);
					break;
				case 3: vm.tabC_flag = false;
					vm.tabD_flag = false;
					vm.tabE_flag = true;
					vm.supplemental_information($root,tab);
					break;

			}
		};


	}
	return controllers;


});
