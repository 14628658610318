define([
    'angular',
    './schedule-FCtrl',
    './schedule-FService', 
    
], function () {
    'use strict';
    return angular.module('app.FIR_5471_Schedule_F', ['app.schedule-FCtrl', 'app.schedule-FService'] );
});

