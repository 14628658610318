define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartIV',[])
        .factory("K3PartIVFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K3PartIVFactory])
    function K3PartIVFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K3PartIVFactory.renderSK3PartIVDetails = function(ctrl) {

            //part
            ctrl.part_IV_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'N/A', ATTRIB_NAME: '1065_K3_IV_N/A'});

            ctrl.part_IV_s1_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'1', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_IV_1'});
            ctrl.part_IV_S1_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'1', ROW_TYPE: 'DT'});

            ctrl.part_IV_s2_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'2', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_IV_2'});
            ctrl.part_IV_S2_1065SK3Data_line_9_10_11 = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'2', ROW_TYPE: 'CH'});
            ctrl.part_IV_S2_1065SK3Data_line_9_10_11_Transposed = transpose_part_IV_Section(ctrl.part_IV_S2_1065SK3Data_line_9_10_11);
            ctrl.part_IV_S2_1065SK3Data_line12 = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'2', ROW_TYPE: 'DT', LINE_NO: '12'});

            ctrl.part_IV_s3_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'3', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_IV_3'});
            ctrl.part_IV_S3_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IV', SECTION_NAME:'3', ROW_TYPE: 'CH'});
            ctrl.part_IV_S3_1065SK3Data_Transposed = transpose_part_IV_Section(ctrl.part_IV_S3_1065SK3Data);

            return ctrl;
        }

        function transpose_part_IV_Section(sectionData) {
            let transposedSectionData = [];

            let o = sectionData.reduce((a, b) => {
                a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
                a[b.LINE_ORDER].push(b);
                return a;
            }, {});
            let a = Object.keys(o).map(function (k) {
                return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], DELETE_COLS: [],Line_data: o[k]};
            });
            transposedSectionData = a;

            let c = [];

            for (let i = 0; i < transposedSectionData.length; i++) {

                let z = transposedSectionData[i].Line_data.reduce( (a,b) => {
                    a[b.ROW_OCCURRENCE] = a[b.ROW_OCCURRENCE] || [];
                    a[b.ROW_OCCURRENCE].push(b);
                    return a;
                }, {});

                let y = Object.keys(z).map(function (k) {
                    return { Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED: "N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Row_Occurrence: /*row_occ*/ z[k][0].ROW_OCCURRENCE, Line_data_occ: z[k]};
                });

                if (i == 0) {


                    let m = angular.copy(y);


                    c = angular.copy(y[0]);
                    c.Line_Type = "HEADER_ROW";
                    c.Row_Occurrence = "";
                    c.Trans_details_key = "";
                    c.IS_EDITABLE = "Y";
                    for (var j=0; j < c.Line_data_occ.length; j++){
                        c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
                    }
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
                    }
                    else {
                        y.unshift(c);
                    }


                }
                else {
                    let key = Object.keys(z)[0];
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift({Line_Type: "SUB_ROW", Line_no: z[key][0].LINE_NO, Line_data_occ: []});
                    }
                }

                transposedSectionData[i].Line_data = angular.copy(y);

            }

            console.log('transposedSectionData = ' , transposedSectionData);

            return transposedSectionData;
        }

        K3PartIVFactory.addRowOccurrenceP4 = function(ctrl, line_no, transposedSectionData){

            let row_occurrence;

            for(let i = 0; i < transposedSectionData.length; i++) {
                if(transposedSectionData[i].ROW_TYPE == 'CH') {
                    row_occurrence = transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length -1].Row_Occurrence + 1;
                }

                let new_row = angular.copy(transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length - 1]);
                new_row.Line_Type = "DATA_ROW";
                new_row.Row_Occurrence = row_occurrence;
                new_row.IS_EDITTED = "Y";

                if(new_row.Line_no == line_no){

                    for (let j = 0; j < new_row.Line_data_occ.length; j++){
                        if ( (new_row.Line_data_occ[j].EDIT_TYPE === "currency" || new_row.Line_data_occ[j].EDIT_TYPE === "label") && new_row.Line_data_occ[j].DATA_TYPE === "number"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = 0;
                        } else if (new_row.Line_data_occ[j].EDIT_TYPE === "text" && new_row.Line_data_occ[j].DATA_TYPE === "string"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = null;
                        }
                        new_row.Line_data_occ[j].ROW_OCCURRENCE = row_occurrence;
                        new_row.Line_data_occ[j].COL_OCCURRENCE = 1;
                        //new_row.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
                    }
                    transposedSectionData[i].Line_data.push(new_row);
                }

            }

            console.log('transposedSectionData = ' , transposedSectionData);

            return ctrl;
        }

        K3PartIVFactory.removeRowOccurrenceP4 = function(ctrl, line_no, Occurrence, index, transposedSectionData) {

            console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);

            for (var i=0; i < transposedSectionData.length; i++){
                if(transposedSectionData[i].ROW_TYPE == 'CH'){
                    for(let j=0; j < transposedSectionData[i].Line_data.length; j++){
                        if(j == index){
                            if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && null != transposedSectionData[i].Line_data[j].Row_Occurrence && transposedSectionData[i].Line_data[j].Row_Occurrence != "" && transposedSectionData[i].Line_data[j].Row_Occurrence == Occurrence){

                                if(transposedSectionData[i].Line_data[j].IS_EDITTED == 'N') {
                                    transposedSectionData[i].DELETE_ROWS.push(transposedSectionData[i].Line_data[j]);
                                }
                                transposedSectionData[i].Line_data.splice(j,1);
                            }else if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
                                transposedSectionData[i].Line_data.splice(j,1);
                            }
                        }
                    }
                }
            }

            return ctrl;
        }

        K3PartIVFactory.calcAmtSK3P4 = function(ctrl,formulaObj, cell_name, cell_value, row, col) {

            console.log('in calcAmtSK3');
            console.log('cell_name = ', cell_name);
            console.log('cell_value = ', cell_value);

            let lineAmt = 0;
            let lineFormulaArray;
            let formulaOperandText;
            let targetCellObj;
            let formula;
            let matches;
            let originalValue;

            // find the related formula for the cell whose value is changed
            let formulaObjs = $filter("filter")(ctrl.allFormFormulaListSK3, {ATTRIB_CALC_FORMULA:'('+cell_name+')'});

            for(let k = 0; k < formulaObjs.length; k++) {
                lineAmt = 0;
                formula = formulaObjs[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }

                for(let i = 0; i<lineFormulaArray.length; i++) {
                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();
                    console.log('formulaOperandText = ', formulaOperandText);

                    if(k == 0) {

                        if($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText}).length > 1) {
                            lineAmt = 0;
                            for(let x = 0; x < $filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText}).length; x++) {

                                lineAmt = lineAmt + parseFloat($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText})[x].ATTRIBUTE_VALUE);
                            }

                        }
                        else
                            lineAmt = parseFloat($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText})[0].ATTRIBUTE_VALUE);
                    }


                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");

                    //console.log('In-step Formula (value replaced) : ' , formula);
                }
                //console.log('Final Formula (value replaced) : ' , formula);

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);
                    //console.log('formula_cell_name = ', formulaObjs[k].ATTRIB_NAME , ' , formula_evaluated_value = ', xyz);
                    if(k == 0) {
                        targetCellObj = $filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaObjs[k].ATTRIB_NAME})[0];

                        originalValue = targetCellObj.ATTRIBUTE_VALUE;
                        if(originalValue != xyz) {
                            ctrl.setChange(targetCellObj,row);
                        }
                        targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();

                    }

                }
            }

            return ctrl;

        }

        K3PartIVFactory.obtainChanges = function(ctrl) {

            let a;
            let b;
            let c;
            let p;
            let cells = [];

            ctrl.partIVS1Changes = [];
            ctrl.partIVS2Changes = [];
            ctrl.partIVS3Changes = [];

            console.log('ctrl.saveData = ' , ctrl.saveData);

            if(ctrl.saveData.length > 0) {

                let part4Data = _.filter(ctrl.saveData, {PART_NO: "IV"});
                if(part4Data !== undefined && part4Data.length > 0) {
                    ctrl.saveData = ctrl.saveData.filter(element => element.PART_NO === "IV");
                }

                for(let x = 0; x < ctrl.part_IV_S1_1065SK3Data.length; x++) {
                    let cell = ctrl.part_IV_S1_1065SK3Data[x];
                    if(cell.IS_CHANGED_FLAG == 'Y') {
                        ctrl.partIVS1Changes.push(cell);
                    }
                }

                if(ctrl.checkValueEnteredInSection(ctrl.part_IV_S2_1065SK3Data_line_9_10_11_Transposed)) {
                    for(let x = 0; x < ctrl.part_IV_S2_1065SK3Data_line_9_10_11_Transposed.length; x++) {
                        a = ctrl.part_IV_S2_1065SK3Data_line_9_10_11_Transposed[x];
                        b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                        for( let y = 0; y < b.length; y++) {
                            c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                            if (c.length > 0) {
                                b[y].Line_data_occ = c;
                                ctrl.partIVS2Changes.push(c);
                            }
                        }
                    }
                }

                let cell = ctrl.part_IV_S2_1065SK3Data_line12[0];
                if(cell.IS_CHANGED_FLAG == 'Y') {
                    ctrl.partIVS2Changes.push(cell);
                }

                if(ctrl.checkValueEnteredInSection(ctrl.part_IV_S3_1065SK3Data_Transposed)) {
                    for(let x = 0; x < ctrl.part_IV_S3_1065SK3Data_Transposed.length; x++) {
                        a = ctrl.part_IV_S3_1065SK3Data_Transposed[x];
                        b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                        for( let y = 0; y < b.length; y++) {
                            c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                            if (c.length > 0) {
                                b[y].Line_data_occ = c;
                                ctrl.partIVS3Changes.push(c);
                            }
                        }
                    }
                }

                //handle delete of row occurrence
                if(ctrl.checkValueEnteredInSection(ctrl.part_IV_S3_1065SK3Data_Transposed)) {
                    for(let x = 0; x < ctrl.part_IV_S3_1065SK3Data_Transposed.length; x++) {
                        p = ctrl.part_IV_S3_1065SK3Data_Transposed[x];
                        if(p.DELETE_ROWS.length > 0) {
                            for(let d = 0; d < p.DELETE_ROWS.length; d++) {
                                let lineData = p.DELETE_ROWS[d];
                                for(let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                    lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                                }
                                ctrl.partIVS3Changes.push(lineData.Line_data_occ);
                            }
                        }
                    }
                }

            }

            for(let row = 0; row < ctrl.partIVS1Changes.length; row++) {
                cells = ctrl.partIVS1Changes[row]
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }
            for(let row = 0; row < ctrl.partIVS2Changes.length; row++) {
                cells = ctrl.partIVS2Changes[row];
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }
            for(let row = 0; row < ctrl.partIVS3Changes.length; row++) {
                cells = ctrl.partIVS3Changes[row];
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }


            return ctrl;
        }

        K3PartIVFactory.validateSK3DataPartIV = function(ctrl, k3Data) {
            let cell;
            let line_no;
            let section_name;
            let countryCells;
            let sicCodeMatched = false;

            let lineObj;
            let lineData;
            let cellObj;
            let cellValue;
            let cellValues = [];
            let dupeMsg;
            let dupeCountries = [];

            if(k3Data != undefined) {
                for(let i = 0; i < k3Data.length; i++) {
                    cell = k3Data[i];
                    line_no = cell.LINE_NO;
                    if(line_no == '15' || line_no == '16') {
                        if(cell.IS_CHANGED_FLAG == 'Y') {
                            if(ctrl.checkValueEnteredInSection(ctrl.part_IV_S3_1065SK3Data_Transposed)) {
                                if(cell.ATTRIB_COL == '0') {
                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                        ctrl.ERROR_MESSAGE = 'SIC Code Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {
                                        sicCodeMatched = false;
                                        for(let k = 0; k < ctrl.SIC_codes.length; k++ ) {
                                            if(ctrl.SIC_codes[k].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                sicCodeMatched = true;
                                                break;
                                            }
                                        }
                                        if(sicCodeMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid SIC Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if(ctrl.ERROR_MESSAGE != null) {
                        ctrl.HAS_ERROR = true;
                        break;
                    }

                }
            }


            if(!ctrl.HAS_ERROR) {

                for(let x = 0; x < ctrl.part_IV_S3_1065SK3Data_Transposed.length; x++) {

                    cellValues = [];
                    lineObj = ctrl.part_IV_S3_1065SK3Data_Transposed[x];
                    lineData = $filter("filter")(lineObj.Line_data, {Line_Type:'DATA_ROW'});

                    if(lineData.length > 1) {

                        for(let y = 0; y < lineData.length; y++) {
                            cellObj = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'0'})[0];
                            cellValue = cellObj.ATTRIBUTE_VALUE;
                            cellValues.push(cellValue);
                        }
                        let duplicates = cellValues.reduce(function(acc, el, i, arr) {
                            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
                        }, []);
                        console.log('duplicates = ' , duplicates);
                        if(duplicates.length > 0) {
                            dupeMsg = 'Duplicate SIC Code(s) ~ ';
                            ctrl.ERROR_MESSAGE = dupeMsg + duplicates[0] + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' +  lineData[0].Line_no ;
                            ctrl.HAS_ERROR = true;
                            break;
                        }
                    }
                }

            }

            return ctrl;
        }

        return K3PartIVFactory;

    }

    return services;
});