define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.firSysFormChartAcctMapService',[])
       .factory("FirSysFormGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	  ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG'
    	   , function($rootScope, $log,JsonObjectFactory,AlertService, $injector
    		    	  ,$q, $http,
    		           $stateParams, $timeout, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
           var factory = {};
           var returnObj = {};
           var firSysFormGroupObjFactory ={};
           firSysFormGroupObjFactory.url = {};
           firSysFormGroupObjFactory.url.saveFirIRSForm  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=z98wka";
           //firSysFormGroupObjFactory.url.saveIRSForm  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hhba23";
   //        systemGroupObjFactory.url.deleteSystemForm = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=94";
           firSysFormGroupObjFactory.saveFirIRSFormDetails = function(_data, IRSFormDetails){

               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
               var jsonObj = JSON.stringify(IRSFormDetails);
               var jsonSettings =  JSON.stringify(_data);
               console.log(jsonSettings);
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
               params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
               params.process_name =  "FIR_SYS_IRS_FORM_MAP_SAVE";
               console.log(params);
               var promise = $http({
                   method: "post",
                   url: firSysFormGroupObjFactory.url.saveFirIRSForm,
                   data: params
               }).then(function (response) {
                   var status = response.status;
                   return response.data;
               });

               return promise;

           };
            
return firSysFormGroupObjFactory;
       }]);

   return services;
});