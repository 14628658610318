define(
	['angular'

	],
	function () {
		'use strict';

		var controllers = angular.module('app.taxAttributesCtrl', [])
			.controller('TaxAttributesData', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'TaxAttributesServiceFactory', 'rowData', 'gridData', TaxAttributesData])
			.controller('TaxAttributesPopUpData', ['$rootScope', '$scope', '$filter', '$state', 'GlobalService', '$log', '$uibModalInstance', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'TaxAttributesServiceFactory', 'AlertService', 'allTaxAttribList', 'rowData', 'scheduled_year', 'taxAttrib_year_data', 'current_tab', TaxAttributesPopUpData])
			.controller('TaxAttributesEntityData', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'TaxAttributesServiceFactory', 'rowData', 'gridData', TaxAttributesEntityData])


		function TaxAttributesPopUpData($rootScope, $scope, $filter, $state, GlobalService, $log, $uibModalInstance, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, TaxAttributesServiceFactory, AlertService, allTaxAttribList, rowData, scheduled_year, taxAttrib_year_data, current_tab) {
			var vmodal = this;
			vmodal.allTaxAttribList = angular.copy(allTaxAttribList);
			vmodal.origData = angular.copy(vmodal.allTaxAttribList);
			vmodal.rowData = angular.copy(rowData);
			vmodal.userSettings = USER_SETTINGS;
			vmodal.logged_in_user = vmodal.userSettings.user.sso_id;
			vmodal.scheduled_year = scheduled_year;
			vmodal.taxAttrib_year_data = taxAttrib_year_data;
			vmodal.current_tab = current_tab;
			vmodal.genValue = "Generated";
			vmodal.transValue = "1";
			vmodal.selectedType = 'G';
			vmodal.noRecords = false;
			vmodal.disRegTransType = false;
			vmodal.disQARTransType = false;
			//For second table we need trans type key to get trans type name and can use trans type name to filter
			vmodal.filteredTAName = $filter("filter")(vmodal.allTaxAttribList, { TRANS_TYPE_KEY: vmodal.current_tab });
			//					vmodal.showErrors = false;

			//package
			vmodal.fetchScenarioData = function () {

				var params = {
					"action_code": 'j9nm7t', "tax_year": GlobalService.globalParams.tax_year, "scenario": GlobalService.globalParams.scenario,
					"jcd_key": GlobalService.globalParams.jcd_key
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

					vmodal.scenarioData = data.jsonObject;
					if (vmodal.scenarioData != undefined) {
						if (vmodal.scenarioData[0].IS_LOCKED == 'Y') {
							vmodal.disRegTransType = true;
							vmodal.transValue = "2";
						} else {
							vmodal.disQARTransType = true;
							vmodal.transValue = "1";
						}
					}
				});
			}


			vmodal.changeTAAmount = function (changedTA) {
				changedTA['showErrors'] = false;
				if (changedTA.UTIL_TYPE == 'G') {
					changedTA.AMOUNT = Math.abs(changedTA.AMOUNT);
					changedTA.PREACQUI_AMT = Math.abs(changedTA.PREACQUI_AMT);
				} else {
					if (isNaN(changedTA.AMOUNT)) {
						changedTA.AMOUNT = 0;
					}
					if (isNaN(changedTA.PREACQUI_AMT)) {
						changedTA.PREACQUI_AMT = 0;
					}
					changedTA.AMOUNT = -Math.abs(changedTA.AMOUNT);
					changedTA.PREACQUI_AMT = -Math.abs(changedTA.PREACQUI_AMT);
					if (changedTA.ORIGINAL_AVAILABLE_AMT == null || changedTA.ORIGINAL_AVAILABLE_AMT == undefined) {
						changedTA['ORIGINAL_AVAILABLE_AMT'] = changedTA.AVAILABLE_AMT;
					}
					changedTA.AVAILABLE_AMT = (changedTA.ORIGINAL_AVAILABLE_AMT + changedTA.AMOUNT + changedTA.PREACQUI_AMT);
					if (changedTA.AVAILABLE_AMT < 0) {
						changedTA['showErrors'] = true;
					}
				}
				changedTA['taAmountChange'] = true;
				//IN future Confirm about QRE (NOL_TYPE == '2') validation
				if (changedTA.UTIL_TYPE == 'G' && changedTA.NOL_TYPE == '1') {
					vmodal.minAmount = Math.min(taxAttrib_year_data.AVAILABLE, taxAttrib_year_data.AVL_382_LIMIT);
					if (vmodal.minAmount < Math.abs(changedTA.AMOUNT)) {
						changedTA['showErrors'] = true;
					} else {
						changedTA['showErrors'] = false;
					}
				} else {
					//								if(vmodal.current_tab == 74){
					//									if(taxAttrib_year_data.AVAILABLE < Math.abs(changedTA.AMOUNT)){
					//										changedTA['showErrors'] =  true;
					//									}else{
					//										changedTA['showErrors'] =  false;
					//									}
					//								
					//							}
				}

			}

			//Get Utilized table data
			vmodal.fetchTaxYearsAmounts = function () {
				var params = {
					"action_code": 've2xn6', "sso_id": vmodal.logged_in_user, "tax_year": vmodal.scheduled_year, "scenario": GlobalService.globalParams.scenario,
					"jcd_key": GlobalService.globalParams.jcd_key, "ta_key": vmodal.allTaxAttribList[0].TA_KEY, //vmodal.rowData.TA_KEY,
					"trans_type_key": vmodal.current_tab
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vmodal.taxAttributeYearsData = data.jsonObject;
					//			            	
					//			             	if(vmodal.taxAttributeYearsData.length == 0){
					//			            		vmodal.noRecords = true;
					//			            	}

				});
			}

			//package
			vmodal.fetchTaxAttribEntityTxn = function () {
				var params = {
					"action_code": 'orruzh', "sso_id": vmodal.logged_in_user, "scheduled_tax_year": vmodal.scheduled_year, "trans_type_key": vmodal.current_tab, "leid": vmodal.rowData.LEID, "cdr_no": vmodal.rowData.CDR_NO,
					"reporting_period": vmodal.rowData.REPORTING_PERIOD
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					for (var i = 0; i < data.jsonObject.length; i++) {
						data.jsonObject[i]['UTIL_TYPE'] = data.jsonObject[i].T_TYPE.split('|')[0];
						data.jsonObject[i]['TRANS_TYPE'] = data.jsonObject[i].T_TYPE.split('|')[1];
					}
					vmodal.taxAttribEntityTxnData = data.jsonObject;
					/*			            	vmodal.filteredTaxAttrib = $filter("filter")(vmodal.allTaxAttribList, {UTIL_TYPE : vmodal.selectedType, NOL_TYPE : vmodal.transValue});
												if(vmodal.taxAttribEntityTxnData.length != 0){
													var keys = Object.keys(vmodal.taxAttribEntityTxnData[0]);
												}
												
												vmodal.newList = [];
												vmodal.dataPresent = false;
												//If Tax attributes are not used in table i'm  not adding it to new list 
												for(var i=0;i < vmodal.filteredTaxAttrib.length;i++){
													vmodal.active = _.find(keys, function(o) { 
														return o == vmodal.filteredTaxAttrib[i].TRANS_TYPE_NAME; 
														});
													 var z=0;
													_.map(vmodal.taxAttribEntityTxnData, function(x) { 
														if(z < vmodal.taxAttribEntityTxnData.length){
																vmodal.dataObj = {};
																//x[vmodal.active] == vmodal.active
																vmodal.dataObj[vmodal.active] = x[vmodal.active];
																if(!vmodal.dataPresent){
																	vmodal.newList.push(vmodal.dataObj);
																	vmodal.newList[z]['LEID'] =  vmodal.taxAttribEntityTxnData[z].LEID;
																	vmodal.newList[z]['CDR_NO'] =   vmodal.taxAttribEntityTxnData[z].CDR_NO;
																	vmodal.newList[z]['REPORTING_PERIOD'] =   vmodal.taxAttribEntityTxnData[z].REPORTING_PERIOD;
																	vmodal.newList[z]['UTIL_TYPE'] =  vmodal.taxAttribEntityTxnData[z].UTIL_TYPE;
																	vmodal.newList[z]['TRANS_TYPE'] =   vmodal.taxAttribEntityTxnData[z].TRANS_TYPE;
																}else{
																	//do something
																	vmodal.newList[z][vmodal.active] =  vmodal.dataObj[vmodal.active];
																	if(vmodal.newList != null){
																		vmodal.newList[z]['LEID'] =  vmodal.taxAttribEntityTxnData[z].LEID;
																		vmodal.newList[z]['CDR_NO'] =   vmodal.taxAttribEntityTxnData[z].CDR_NO;
																		vmodal.newList[z]['REPORTING_PERIOD'] =   vmodal.taxAttribEntityTxnData[z].REPORTING_PERIOD;
																		vmodal.newList[z]['UTIL_TYPE'] =  vmodal.taxAttribEntityTxnData[z].UTIL_TYPE;
																		vmodal.newList[z]['TRANS_TYPE'] =   vmodal.taxAttribEntityTxnData[z].TRANS_TYPE;
																		
																	}
																}
															}
															z++;
														});
													
													vmodal.dataPresent = true;
													
												}*/
					if (vmodal.taxAttribEntityTxnData.length == 0) {
						vmodal.noRecords = true;
					}
				});
			}

			vmodal.fetchTaxYearsAmounts();
			vmodal.fetchTaxAttribEntityTxn();
			vmodal.fetchScenarioData();

			vmodal.cancel = function () {
				$uibModalInstance.dismiss('cancel');
			};

			vmodal.reset = function () {
				vmodal.genValue = "Generated";
				vmodal.selectedType = 'G';
				vmodal.allTaxAttribList = angular.copy(vmodal.origData);
				if (vmodal.scenarioData != undefined) {
					if (vmodal.scenarioData[0].IS_LOCKED == 'Y') {
						vmodal.disRegTransType = true;
						vmodal.transValue = "2";
					} else {
						vmodal.disQARTransType = true;
						vmodal.transValue = "1";
					}
				}
				//vmodal.allTaxAttribList = vmodal.origData;
				//						for(var i=0;i < vmodal.allTaxAttribList.length;i++){
				//							vmodal.allTaxAttribList[i].AMOUNT = 0;
				//						}
			};

			vmodal.selectedGenUtil = function (selectedVal) {
				if (selectedVal == "Generated") {
					vmodal.selectedType = 'G';
				} else if (selectedVal == "Utilized") {
					vmodal.selectedType = 'U';
				}
			}



			vmodal.save = function () {
				vmodal.isSaveClicked = true;
				vmodal.saveData = [];
				//						vmodal.errorSaveData = $filter("filter")(vmodal.taxAttributeYearsData, {NOL_TYPE : vmodal.transValue, showErrors: true});
				vmodal.errorSaveData = $filter("filter")(vmodal.taxAttributeYearsData, { TRANS_TYPE: 'REG', showErrors: true });
				if (vmodal.errorSaveData.length > 0) {
					vmodal.isSaveClicked = false;
					AlertService.add("error", "Please make sure utilized amount can't exceed available amount");
					//							if(vmodal.current_tab == 74){
					//								AlertService.add("error", "Please make sure utilized amount can't exceed available amount");
					//							}else{
					//								AlertService.add("error", "Please make sure utilized amount should be min(available_amount,382_available_amount)");
					//							}
					return;
				}

				//Sum of Utilized amount cant be greater that 382 Limitation Amount Validation
				if (vmodal.current_tab == 73) {
					var totalSum = 0;
					for (var i = 0; i < vmodal.taxAttributeYearsData.length; i++) {
						if (vmodal.taxAttributeYearsData[i].AMOUNT != undefined) {
							totalSum += parseInt(vmodal.taxAttributeYearsData[i].AMOUNT);
						}
					}
					totalSum = Math.abs(totalSum);
					if (totalSum > vmodal.taxAttrib_year_data.ROLLING_ANNUAL_LIMIT) {
						AlertService.add("error", "Sum of Utilized amount can't be greater than 382 Limitation Amount");
						return;
					}
				}

				var returnClobSettingsObj = {};
				var taxAttributesSaveDtls = [];
				//						vmodal.saveData = $filter("filter")(vmodal.allTaxAttribList, {UTIL_TYPE: vmodal.selectedType, NOL_TYPE : vmodal.transValue, taAmountChange: true});
				vmodal.saveData1 = $filter("filter")(vmodal.allTaxAttribList, { NOL_TYPE: vmodal.transValue, taAmountChange: true });
				//						vmodal.saveData.push(saveData1);
				vmodal.saveData2 = $filter("filter")(vmodal.taxAttributeYearsData, { taAmountChange: true });
				vmodal.saveData = vmodal.saveData1.concat(vmodal.saveData2);
				if (vmodal.saveData.length == 0) {
					vmodal.isSaveClicked = false;
					AlertService.add("error", "No changes to save");
					return;
				}

				for (var i = 0; i < vmodal.saveData.length; i++) {
					var returnObj = {};


					//									returnObj['annual382Limit'] =  vmodal.saveData[i].RUNNING_TOTAL;
					//									returnObj['trans_desc'] =  vmodal.saveData[i].TRANS_TYPE_DESC;


					returnObj['ta_mast_key'] = vmodal.saveData[i].TA_MAST_KEY;
					returnObj['ta_key'] = vmodal.saveData[i].TA_KEY;
					returnObj['trans_type_key'] = vmodal.current_tab;// vmodal.saveData[i].TRANS_TYPE_KEY;
					returnObj['ref_trans_key'] = vmodal.saveData[i].REF_TRANS_KEY;
					returnObj['trans_key'] = vmodal.saveData[i].TRANS_KEY;
					if (vmodal.saveData[i].UTIL_TYPE == 'G') {
						returnObj['util_type'] = vmodal.saveData[i].UTIL_TYPE;//check
						returnObj['expire_year'] = vmodal.taxAttrib_year_data.NOL_EXPIRE_YEAR;
					} else {
						//										returnObj['trans_key'] = vmodal.saveData[i].TRANS_KEY;
						returnObj['util_type'] = 'U';//check
						returnObj['expire_year'] = vmodal.saveData[i].EXPIRE_YEAR;
					}
					returnObj['amount'] = vmodal.saveData[i].AMOUNT;
					returnObj['preacqui_amt'] = vmodal.saveData[i].PREACQUI_AMT;
					returnObj['reg_qar'] = vmodal.transValue;//trans type
					returnObj['comments'] = vmodal.saveData[i].DETAILREASON;

					var message = "Tax Attributes has been successfully saved/updated";
					taxAttributesSaveDtls.push(returnObj);
				}

				returnClobSettingsObj['sso_id'] = vmodal.logged_in_user;
				returnClobSettingsObj['tax_year'] = scheduled_year;
				//						returnClobSettingsObj['scenario'] =  vmodal.transValue;
				returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
				returnClobSettingsObj['leid'] = vmodal.rowData.LEID;
				returnClobSettingsObj['cdr_No'] = vmodal.rowData.CDR_NO;
				returnClobSettingsObj['ta_mast_key'] = vmodal.saveData[0].TA_MAST_KEY;
				returnClobSettingsObj['reporting_period'] = vmodal.rowData.REPORTING_PERIOD;
				//						returnClobSettingsObj['util_type'] =  vmodal.selectedType;
				returnClobSettingsObj['trans_type'] = vmodal.transValue;

				sendDetails(returnClobSettingsObj, taxAttributesSaveDtls, message);

			};

			function sendDetails(returnClobSettingsObj, taxAttributesSaveDtls, message) {
				TaxAttributesServiceFactory.saveTaxAttributes(returnClobSettingsObj, taxAttributesSaveDtls).then(function (result) {

					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vmodal.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						//vm.crudLoading = false;
						AlertService.add("success", message, 4000);
						var args = {
							leid: returnClobSettingsObj.leid,
							cdr_No: returnClobSettingsObj.cdr_No,
							reporting_period: returnClobSettingsObj.reporting_period,
							gridFilter: {
								leid: returnClobSettingsObj.leid,
								cdr_No: returnClobSettingsObj.cdr_No,
								reporting_period: returnClobSettingsObj.reporting_period
							}
						};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
						//										vmodal.fetchModalScheduledYearsData(vmodal.allTaxAttribList[0].TRANS_TYPE_KEY);
						vmodal.filteredTaxAttribTab = $filter("filter")(vmodal.allTaxAttribList, { TRANS_TYPE_KEY: vmodal.current_tab });
						$rootScope.$emit('updateTaxAttributesData', vmodal.filteredTaxAttribTab[0]);
					};

				});
			}

		}

		function TaxAttributesEntityData($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, TaxAttributesServiceFactory, rowData, gridData) {
			var vemodal = this;
			vemodal.gridRowData = gridData.rowData;
			vemodal.modalName = ModalFactory.response.data.modalScreen.modal_name;
			vemodal.userSettings = USER_SETTINGS;
			vemodal.logged_in_user = vemodal.userSettings.user.sso_id;
			var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
			vemodal.curentyear = filterParams.tax_year;
			vemodal.loadingEntities = true;
			//	                vemodal.disableOverrideDate = true;
			vemodal.taAnnualLimit = false;
			vemodal.overrideDateCheck = false;
			vemodal.activateEntityDropdown = false;
			vemodal.clickTabName = 'UpdateTab';
			vemodal.calendar = {
				opened: false
			};
			vemodal.cancel = function () {
				$uibModalInstance.dismiss('cancel');
			};
			vemodal.postingMESelected = false;

			vemodal.reset = function () {
				if (vemodal.gridRowData == null) {
					vemodal.fetchTaxAttribCreateEntityDtls();

					vemodal.leid = '';
					vemodal.cdrNo = '';
					vemodal.rp = '';
					vemodal.leName = '';
					vemodal.selectedEntity = '';
					vemodal.selectedPostingME = '';
					vemodal.taxAttribEntityMEList = '';
					vemodal.taAnnualLimit = false;
				} else {
					if (vemodal.gridRowData != null) {
						vemodal.fetchTaxAttribUpdateEntityDtls();
					}
				}
			};


			vemodal.fetchTaxAttribCreateEntityDtls = function () {
				var params = { "action_code": '8t0qhk', "sso_id": vemodal.logged_in_user, "tax_year": vemodal.curentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vemodal.taxAttribEntityData = data.jsonObject;
					vemodal.loadingEntities = false;
				});
			}

			vemodal.setEntityDtls = function (entity) {
				vemodal.selectedEntityObj = vemodal.taxAttribEntityData.filter(function (obj) {
					return obj.LEID_DTS === entity;
				});
				vemodal.leid = vemodal.selectedEntityObj[0].LEID;
				vemodal.cdrNo = vemodal.selectedEntityObj[0].CDR_NO;
				vemodal.rp = vemodal.selectedEntityObj[0].REPORTING_PERIOD;
				vemodal.acqDate = vemodal.selectedEntityObj[0].ACQ_DATE;
				vemodal.leName = vemodal.selectedEntityObj[0].LE_NAME;
				vemodal.schdStartYear = vemodal.selectedEntityObj[0].SCHD_START_YEAR;
				vemodal.selectedYear = (vemodal.overrideAcquisitionDate != null) ? vemodal.overrideAcquisitionDate : vemodal.selectedEntityObj[0].ACQ_DATE;
				vemodal.acqYear = vemodal.selectedYear.substring(vemodal.selectedYear.lastIndexOf("/") + 1);
				vemodal.loadingEntities = false;
				vemodal.selectedME = null

				vemodal.fetchTaxAttribMEEntityList();
				vemodal.fetchTaxAttribAnnualLimitList();
			}

			vemodal.setNewEntityDtls = function (entity) {
				vemodal.selectedEntityObj = vemodal.taxAttribEntityData.filter(function (obj) {
					return obj.LEID_DTS === entity;
				});
				vemodal.leid2 = vemodal.selectedEntityObj[0].LEID;
				vemodal.cdrNo2 = vemodal.selectedEntityObj[0].CDR_NO;
				vemodal.rp2 = vemodal.selectedEntityObj[0].REPORTING_PERIOD;
				vemodal.postingME2 = vemodal.selectedEntityObj[0].ME_CODE;
				vemodal.postingMESelected = true;
			}

			vemodal.checkAcquisitionDateNew = function (row) {
				var check = 0;
				if (row.trans_type_desc == 'NOL-REG') {
					vemodal.disableOverrideDate = !row.overrideDateCheck;
					row['disableOverrideDate'] = vemodal.disableOverrideDate;
					if (row.disableOverrideDate) {
						row.overrideAcquisitionDate = '';
						row.overridedate_date = '';
					}
				}
			}

			vemodal.fetchTaxAttribMEEntityList = function () {
				var params = {
					"action_code": 'b6v5lz', "sso_id": vemodal.logged_in_user, "tax_year": vemodal.curentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key,
					"leid": vemodal.leid, "cdr_no": vemodal.cdrNo, "reporting_period": vemodal.rp
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vemodal.taxAttribEntityMEList = data.jsonObject;
					//If we have only one ME than assign that ME in UI
					if (vemodal.taxAttribEntityMEList.length == 2) {
						vemodal.selectedPostingME = vemodal.taxAttribEntityMEList[0].ME_CODE;
					}
					//			            	vemodal.taAnnualLimit = true;
				});
			}

			vemodal.fetchTaxAttribAnnualLimitList = function () {
				var params = {
					"action_code": 'sln1dp', "sso_id": vemodal.logged_in_user, "tax_year": vemodal.curentyear, "acq_tax_year": vemodal.acqYear,
					"leid": vemodal.leid, "cdr_No": vemodal.cdrNo, "reporting_period": vemodal.rp
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vemodal.taxAttribAnnualLimitData = data.jsonObject;
					vemodal.taAnnualLimit = true;
					for (var i = 0; i < vemodal.taxAttribAnnualLimitData.length; i++) {
						if (vemodal.taxAttribAnnualLimitData[i].trans_type_desc == 'NOL-382') {
							vemodal.taxAttribAnnualLimitData[i]['disableSrly'] = true;
							vemodal.taxAttribAnnualLimitData[i]['disableOverrideDate'] = true;
							vemodal.taxAttribAnnualLimitData[i]['overrideDateCheck'] = false;
							vemodal.taxAttribAnnualLimitData[i]['calenderOpened'] = false;
							//			            			vemodal.taxAttribAnnualLimitData[i]['schdStartYear'] ='';
							vemodal.taxAttribAnnualLimitData[i]['srly_flag'] = false; //Send True if SRLY is applicable
							vemodal.taxAttribAnnualLimitData[i]['overridedate_flag'] = false; //Send True if Override ACQDATE is applicable
							vemodal.taxAttribAnnualLimitData[i]['acqDate'] = vemodal.acqDate;
						} else {
							vemodal.taxAttribAnnualLimitData[i]['disableSrly'] = false;
							vemodal.taxAttribAnnualLimitData[i]['calenderOpened'] = false;
							//			            			vemodal.taxAttribAnnualLimitData[i]['schdStartYear'] ='';
							vemodal.taxAttribAnnualLimitData[i]['srly_flag'] = true;  //Send True if SRLY is applicable
							vemodal.taxAttribAnnualLimitData[i]['overridedate_flag'] = true; //Send True if Override ACQDATE is applicable
							vemodal.taxAttribAnnualLimitData[i]['acqDate'] = vemodal.acqDate;
							vemodal.taxAttribAnnualLimitData[i]['disableAnnualLimit'] = true;

							if (vemodal.taxAttribAnnualLimitData[i].srly_value == 'Y') {
								vemodal.taxAttribAnnualLimitData[i]['overrideDateCheck'] = true;
								vemodal.taxAttribAnnualLimitData[i]['disableOverrideDate'] = false;
							} else {
								vemodal.taxAttribAnnualLimitData[i]['overrideDateCheck'] = false;
								vemodal.taxAttribAnnualLimitData[i]['disableOverrideDate'] = true;
							}

							if (vemodal.taxAttribAnnualLimitData[i]['overridedate_date'] != null) {
								vemodal.taxAttribAnnualLimitData[i]['overrideAcquisitionDate'] = new Date(vemodal.taxAttribAnnualLimitData[i].overridedate_date);
							} else {
								vemodal.taxAttribAnnualLimitData[i]['overridedate_date'] = '';
							}
						}
					}
				});
			}

			vemodal.changedAnnualLimit = function (taxAttrib) {
				taxAttrib['isChangedAttrib'] = true;
			}

			vemodal.clickCurrentTab = function (tabName) {
				if (tabName == 'update') {
					vemodal.reset();
					vemodal.clickTabName = 'UpdateTab';
				}
				else if (tabName == 'posting') {
					vemodal.clickTabName = 'PostingTab';
					if(vemodal.selectedEntity === '') {
						vemodal.activateEntityDropdown = false;
					}
					else {
						vemodal.fetchEntityList();
						vemodal.fetchPostingEntityDetails();
					}
				}
				else {
					vemodal.fetchTaxAttribTargetEntityDtls();
					vemodal.clickTabName = 'MergeTab';
				}

			}
			vemodal.fetchPostingEntityDetails = function () {
				var params = {
					"action_code": 'uzl0eb', "sso_id": vemodal.logged_in_user, "tax_year": vemodal.curentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key,
					"ta_mast_key": vemodal.gridRowData.TA_MAST_KEY
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					if(data.jsonObject.length > 0){
						vemodal.leid2 = data.jsonObject[0].LEID;
						vemodal.rp2 = data.jsonObject[0].REPORTING_PERIOD;
						vemodal.cdrNo2 = data.jsonObject[0].CDR_NO;
						vemodal.postingME2 = data.jsonObject[0].ME_CODE;
						vemodal.selectedEntity2 = data.jsonObject[0].LEID_DTS;
						if(vemodal.activateEntityDropdown) {
							vemodal.postingMESelected = true;
						}
						
					}
				});
			}

			vemodal.fetchTaxAttribTargetEntityDtls = function () {
				var params = {
					"action_code": 'df8fbt', "sso_id": vemodal.logged_in_user, "tax_year": vemodal.curentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key,
					"leid": vemodal.leid, "cdr_no": vemodal.cdrNo, "reporting_period": vemodal.rp
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vemodal.taxAttribTargetEntityData = data.jsonObject;
					//			            	vemodal.loadingEntities = false;
				});
			}

			vemodal.fetchEntityList = function () {
				vemodal.loadingData = true;
				var params = { "action_code": 'ejsuem', "sso_id": vemodal.logged_in_user, "tax_year": vemodal.curentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vemodal.loadingData = false;
					if (data.jsonObject.filter(e => e.LEID === vemodal.leid && e.CDR_NO === vemodal.cdrNo && e.REPORTING_PERIOD === vemodal.rp).length === 0) {
						vemodal.activateEntityDropdown = true;
						vemodal.taxAttribEntityData = data.jsonObject;
					}

				});
			}

			vemodal.formatDate = function (row) {
				row.overridedate_date = $filter('date')(row.overrideAcquisitionDate, 'MM/dd/yyyy');
			};

			vemodal.fetchTaxAttribUpdateEntityDtls = function () {
				var params = {
					"action_code": 'r51uuy', "sso_id": vemodal.logged_in_user, "tax_year": vemodal.curentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key,
					"leid": vemodal.gridRowData.LEID, "cdr_No": vemodal.gridRowData.CDR_NO, "reporting_period": vemodal.gridRowData.REPORTING_PERIOD
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vemodal.taxAttribUpdateEntityData = data.jsonObject;


					vemodal.leid = vemodal.taxAttribUpdateEntityData[0].LEID;
					vemodal.cdrNo = vemodal.taxAttribUpdateEntityData[0].CDR_NO;
					vemodal.rp = vemodal.taxAttribUpdateEntityData[0].REPORTING_PERIOD;
					vemodal.leName = vemodal.taxAttribUpdateEntityData[0].FULL_NAME;
					vemodal.selectedEntity = vemodal.taxAttribUpdateEntityData[0].FULL_NAME;
					vemodal.schdStartYear = vemodal.taxAttribUpdateEntityData[0].SCHD_START_YEAR;
					vemodal.acqDate = vemodal.taxAttribUpdateEntityData[0].GOLD_ACQDATE;
					vemodal.selectedPostingME = vemodal.taxAttribUpdateEntityData[0].ME_CODE;
					vemodal.selectedME = vemodal.taxAttribUpdateEntityData[0].ME_CODE;
					vemodal.selectedTargetEntity = vemodal.taxAttribUpdateEntityData[0].POSTING_ENTITY;
					vemodal.targetLeid = vemodal.taxAttribUpdateEntityData[0].POSTING_LEID;
					vemodal.targetCdrNo = vemodal.taxAttribUpdateEntityData[0].POSTING_CDR_NO;
					vemodal.targetRp = vemodal.taxAttribUpdateEntityData[0].POSTING_REPORTING_PERIOD;
					vemodal.targetleName = vemodal.taxAttribUpdateEntityData[0].POSTING_REPORTING_PERIOD;
					if (vemodal.taxAttribUpdateEntityData[0].OVERRIDE_ACQDATE_FLAG == 'Y') {
						vemodal.overrideDateCheck = true;
						vemodal.disableOverrideDate = false;
					} else {
						vemodal.overrideDateCheck = false;
					}
					vemodal.selectedYear = (vemodal.taxAttribUpdateEntityData[0].OVERRIDE_ACQDATE != null) ? vemodal.taxAttribUpdateEntityData[0].OVERRIDE_ACQDATE : vemodal.taxAttribUpdateEntityData[0].GOLD_ACQDATE;
					if (vemodal.selectedYear != null) {
						vemodal.acqYear = vemodal.selectedYear.substring(vemodal.selectedYear.lastIndexOf("/") + 1);
					}
					vemodal.overrideAcquisitionDate = new Date(vemodal.taxAttribUpdateEntityData[0].OVERRIDE_ACQDATE);
					vemodal.fetchTaxAttribAnnualLimitList();
					vemodal.fetchTaxAttribMEEntityList();
				});
			}

			vemodal.setTargetEntityDtls = function (targetEntity) {
				vemodal.selectedTargetEntityObj = vemodal.taxAttribTargetEntityData.filter(function (obj) {
					return obj.LEID_DTS === targetEntity;
				});
				vemodal.targetLeid = vemodal.selectedTargetEntityObj[0].LEID;
				vemodal.targetCdrNo = vemodal.selectedTargetEntityObj[0].CDR_NO;
				vemodal.targetRp = vemodal.selectedTargetEntityObj[0].REPORTING_PERIOD;
				vemodal.targetleName = vemodal.selectedTargetEntityObj[0].LE_NAME;
				//			            	vemodal.targetEntityName = vemodal.selectedTargetEntityObj[0].ENTITY_NAME ;

				//			            	vemodal.fetchTaxAttribMEEntityList();
				//			            	vemodal.fetchTaxAttribAnnualLimitList();
			}

			vemodal.fetchTaxAttribCreateEntityDtls();
			if (vemodal.gridRowData != null) {
				vemodal.fetchTaxAttribUpdateEntityDtls();
			}


			vemodal.cancel = function () {
				$uibModalInstance.dismiss('cancel');
			};

			vemodal.openCalendar = function (row) {
				//	vemodal.calendar.opened = true;
				if (!row.disableOverrideDate) {
					row.calenderOpened = true;
				}
			};

			vemodal.checkSchdStartYr = function (row) {
				vemodal.msg = undefined;
				var d = null;
				if (row.trans_type_desc == 'NOL-REG') {
					if (row.overrideDateCheck) {
						d = new Date(row.overridedate_date);
					} else {
						d = new Date(row.acqDate);
					}
					vemodal.acqYear = d.getFullYear();
					if (vemodal.schdStartYear != undefined) {
						//								console.log("schdStartYear-----" + vemodal.schdStartYear);
						if ((isNaN(vemodal.schdStartYear)) || vemodal.schdStartYear.toString().length < 4 || vemodal.schdStartYear <= 0 || (vemodal.schdStartYear > vemodal.acqYear)) {
							vemodal.msg = "Input Date";
						}
					}
				} else {
					//Always grab NOL-REG details
					vemodal.nolRegDtls = vemodal.taxAttribAnnualLimitData.filter(function (obj) {
						return obj.trans_type_desc == 'NOL-REG';
					});

					if (vemodal.nolRegDtls[0].overrideDateCheck) {
						d = new Date(vemodal.nolRegDtls[0].overridedate_date);
					} else {
						d = new Date(vemodal.nolRegDtls[0].acqDate);
					}
					vemodal.acqYear = d.getFullYear();
					if (vemodal.schdStartYear != undefined) {
						if ((isNaN(vemodal.schdStartYear)) || vemodal.schdStartYear.toString().length < 4 || vemodal.schdStartYear <= 0 || (vemodal.schdStartYear > vemodal.acqYear)) {
							vemodal.msg = "Input Date";
						}
					}

				}

			};

			vemodal.save = function () {
				//	                	vemodal.checkSchdStartYr();
				vemodal.isSaveClicked = true;

				for (var i = 0; i < vemodal.taxAttribAnnualLimitData.length; i++) {
					if (vemodal.taxAttribAnnualLimitData[i].overrideDateCheck == true) {
						vemodal.taxAttribAnnualLimitData[i]['srly_value'] = 'Y'
					} else if (vemodal.taxAttribAnnualLimitData[i].overrideDateCheck == false) {
						vemodal.taxAttribAnnualLimitData[i]['srly_value'] = 'N'
					}
				}

				if (vemodal.selectedPostingME == undefined) {
					AlertService.add("error", "Please select a Posting ME");
					vemodal.isSaveClicked = false;
					return;
				}

				if (vemodal.msg != undefined) {
					AlertService.add("error", "Please enter a valid Schedule Start Year");
					vemodal.isSaveClicked = false;
					return;
				}

				var returnClobSettingsObj = {};
				var taxAttributesEntitySaveDtls = [];
				//						vemodal.saveData = $filter("filter")(vemodal.taxAttribAnnualLimitData, {isChangedAttrib: true});

				var returnObj = {};
				returnObj['leid'] = vemodal.leid;
				returnObj['cdr_No'] = vemodal.cdrNo;
				returnObj['reporting_period'] = vemodal.rp;
				returnObj['schdStartYear'] = (vemodal.schdStartYear == undefined) ? null : vemodal.schdStartYear;
				//							returnObj['acqDate'] =  vemodal.acqDate;
				//							returnObj['overrideAcqDateBoolean'] = vemodal.overrideDateCheck;
				//							returnObj['overrideAcqDate'] = vemodal.formattedOverrideDate;
				returnObj['posting_meCode'] = vemodal.selectedPostingME;
				returnObj['posting_leid'] = vemodal.targetLeid;
				returnObj['posting_cdrNo'] = vemodal.targetCdrNo;
				returnObj['posting_rp'] = vemodal.targetRp;
				//							returnObj['posting_rp'] = vemodal.selectedTargetEntity;
				//returnObj["transType"] = vemodal.saveData;
				if (vemodal.gridRowData == null) {//For first time when we create entity always send all tax attributes to create schedule
					returnObj["transType"] = vemodal.taxAttribAnnualLimitData;
					returnObj['entity_dml_type'] = 'I';
				} else {
					returnObj["transType"] = vemodal.taxAttribAnnualLimitData;
					returnObj['entity_dml_type'] = 'U';
				}

				//							for(var i=0;i < vemodal.saveData.length;i++){
				//								var returnObj = {};
				//									returnObj['leid'] =  vemodal.leid;
				//									returnObj['cdr_No'] =  vemodal.cdrNo;
				//									returnObj['rp'] =  vemodal.rp;
				//									returnObj['acqDate'] =  vemodal.acqDate;
				//									returnObj['overrideAcqDateBoolean'] = vemodal.overrideDateCheck;
				//									returnObj['overrideAcqDate'] = vemodal.overrideAcquisitionDate;
				//									returnObj['posting_meCode'] =  vemodal.selectedPostingME;
				//									returnObj['posting_leid'] =  vemodal.targetLeid;
				//									returnObj['posting_cdrNo'] =  vemodal.targetCdrNo;
				//									returnObj['posting_rp'] =  vemodal.targetRp;

				var message = "Tax Attributes has been successfully saved/updated";
				taxAttributesEntitySaveDtls.push(returnObj);
				//							}

				returnClobSettingsObj['sso_id'] = vemodal.logged_in_user;
				returnClobSettingsObj['tax_year'] = vemodal.curentyear;
				returnClobSettingsObj['scenario'] = GlobalService.globalParams.scenario;
				returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
				returnClobSettingsObj['leid'] = vemodal.leid;
				returnClobSettingsObj['cdr_No'] = vemodal.cdrNo;
				returnClobSettingsObj['reporting_period'] = vemodal.rp;


				sendEntityDetails(returnClobSettingsObj, taxAttributesEntitySaveDtls, message);

			};

			function sendEntityDetails(returnClobSettingsObj, taxAttributesEntitySaveDtls, message) {
				TaxAttributesServiceFactory.saveTaxAttributesEntityDtls(returnClobSettingsObj, taxAttributesEntitySaveDtls).then(function (result) {

					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vemodal.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						AlertService.add("success", message, 4000);
						var args = {
							leid: returnClobSettingsObj.leid,
							cdr_No: returnClobSettingsObj.cdr_No,
							reporting_period: returnClobSettingsObj.reporting_period,
							gridFilter: {
								leid: returnClobSettingsObj.leid,
								cdr_No: returnClobSettingsObj.cdr_No,
								reporting_period: returnClobSettingsObj.reporting_period
							}
						};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
					};

				});
			}

			vemodal.savePostingEntity = function() {
				vemodal.crudLoading = true;
				var returnClobSettingsObj = {};
				var returnObj = {};
				returnClobSettingsObj['sso_id'] = vemodal.logged_in_user;
				returnClobSettingsObj['tax_year'] = vemodal.curentyear;
				returnClobSettingsObj['scenario'] = GlobalService.globalParams.scenario;
				returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
				returnObj['leid'] = vemodal.leid;
				returnObj['cdr_No'] = vemodal.cdrNo;
				returnObj['reporting_period'] = vemodal.rp;
				returnObj['posting_meCode'] = vemodal.selectedPostingME;
				returnObj['new_leid'] = vemodal.leid2;
				returnObj['new_cdr_No'] = vemodal.cdrNo2;
				returnObj['new_reporting_period'] = vemodal.rp2;
				returnObj['new_posting_meCode'] = vemodal.postingME2;
				TaxAttributesServiceFactory.savePostingEntity(returnClobSettingsObj, returnObj).then(function (result) {
					vemodal.crudLoading = false;
					if (result.callSuccess && result.callSuccess !== "1") {
						vemodal.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						AlertService.add("success", "Posting Entity Saved!", 4000);
						$uibModalInstance.dismiss('cancel');
					};

				});
			}


		}

		function TaxAttributesData($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, TaxAttributesServiceFactory, rowData, gridData) {

			var vm = this;
			vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
			vm.userSettings = USER_SETTINGS;
			vm.logged_in_user = vm.userSettings.user.sso_id;
			vm.defaultsMultipleSelected = [];
			//	vm.crudLoading = false;
			vm.gridData = {};
			var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
			vm.curentyear = filterParams.tax_year;
			vm.isSaveClicked = false;
			vm.formDataObj = {};
			vm.formCalcData = [];
			vm.list = [];
			vm.formList = [];
			vm.tempCheckBoxList = [];
			vm.tableHeader = [];
			vm.allTabsFormHeader = [];
			vm.allTabsTableHeader = [];
			vm.allTabsList = [];
			vm.allTablsOutterList = [];
			vm.originalTabsList = [];
			vm.originalTablsOutterList = [];
			vm.totalList = [];
			//				vm.activeTab = null;
			//				vm.outerList = [];
			vm.loadingData = true;
			vm.fm = null;
			vm.sn = null;
			vm.pn = null;
			vm.sm = null;
			vm.currentTabs = [];
			vm.taxAttrsLookUp = false;

			vm.cancel = function () {
				$uibModalInstance.dismiss('cancel');
			};

			vm.cancelForm = function () {
				$uibModalInstance.dismiss('cancel');
			};

			vm.taxAttrstoggleLookup = function () {
				vm.taxAttrsLookUp = !vm.taxAttrsLookUp;
			}

			//package
			//				vm.fetchPopUpData = function(row) {
			//					vm.taxAttrib_year_data = row;
			//					vm.scheduled_year = row.SCHD_TAX_YEAR;
			//					
			//		            var params = { "action_code": 'com87r', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "attrib_tax_year": vm.scheduled_year ,"scenario": GlobalService.globalParams.scenario , "jcd_key": GlobalService.globalParams.jcd_key, "leid": rowData.LEID, "cdr_no": rowData.CDR_NO, 
			//		            "reporting_period":rowData.REPORTING_PERIOD };
			//		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
			//			            	vm.generatedList = $filter("filter")(data.jsonObject, {UTIL_TYPE:"G"});
			//				        	vm.utilizedList = $filter("filter")(data.jsonObject, {UTIL_TYPE:"U"});
			//			            	vm.totalList =  data.jsonObject;
			//			            	vm.openPopUpWindow();
			////			            	vm.selectedType = 'G';
			//							vm.loadingData = false;
			//		            });
			//		        }

			//Get generated Amount
			vm.fetchPopUpData = function (row) {
				vm.taxAttrib_year_data = row;
				vm.scheduled_year = row.SCHD_TAX_YEAR;

				if (vm.taxAttrib_year_data.TAX_YEAR != null) {
					if (vm.taxAttrib_year_data.EXPIRE_YEAR == '-') {
						vm.taxAttrib_year_data.EXPIRE_YEAR = '';
						vm.exprire_Year = '';
					} else {
						vm.exprire_Year = vm.taxAttrib_year_data.EXPIRE_YEAR;
					}
				} else {
					vm.exprire_Year = vm.taxAttrib_year_data.DUMMY_EXPIRE_YEAR;
				}


				var params = {
					"action_code": 'com87r', "sso_id": vm.logged_in_user, "attrib_tax_year": vm.scheduled_year, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "ta_key": vm.taKey,//rowData.TA_KEY, 
					"expire_year": vm.exprire_Year, "trans_type_key": vm.activeTab
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vm.generatedList = $filter("filter")(data.jsonObject, { UTIL_TYPE: "G" });
					vm.utilizedList = $filter("filter")(data.jsonObject, { UTIL_TYPE: "U" });
					vm.totalList = data.jsonObject;
					vm.openPopUpWindow();
					//			            	vm.selectedType = 'G';
					vm.loadingData = false;
				});
			}

			vm.fetchModalTabsData = function () {
				var params = { "action_code": 'o482i5', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vm.taxAttribTabs = data.jsonObject;
					vm.fetchModalScheduledYearsData();
					//							vm.loadingData = false;
				});
			}

			//				updateTaxAttributesData
			$rootScope.$on('updateTaxAttributesData', function (event, taxAttribObj) {
				// profileObj contains; name, country and email from emitted event
				vm.fetchModalScheduledYearsData(taxAttribObj);
			});

			vm.fetchMergedEntitiesData = function (taxAttribObj) {
				if (taxAttribObj != null) {
					vm.trans_type_key = taxAttribObj.TRANS_TYPE_KEY;
				} else {
					vm.trans_type_key = vm.taxAttribTabs[0].TRANS_TYPE_KEY;
				}

				var params = {
					"action_code": '4sr7n4', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "trans_type_key": vm.trans_type_key, "leid": rowData.LEID, "cdr_no": rowData.CDR_NO,
					"reporting_period": rowData.REPORTING_PERIOD
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vm.mergedTaxAttribData = data.jsonObject;
					//							vm.loadingData = false;
				});
			}

			//OLD CODE
			/*	vm.fetchModalScheduledYearsData = function(taxAttribObj) {
					vm.fetchMergedEntitiesData(taxAttribObj);
					vm.loadingData = true;
					if(taxAttribObj != null){
						vm.trans_type_key = taxAttribObj.TRANS_TYPE_KEY;
					}else{
						vm.trans_type_key = vm.taxAttribTabs[0].TRANS_TYPE_KEY;
					}
					
					var params = { "action_code": 'ff619t', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "trans_type_key": vm.trans_type_key , "leid": rowData.LEID, "cdr_no": rowData.CDR_NO, 
							"reporting_period":rowData.REPORTING_PERIOD };
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						vm.taxAttribYearsData = data.jsonObject;
							vm.loadingData = false;
					});
				} */

			//Schedule Year all amounts
			vm.fetchModalScheduledYearsData = function (taxAttribObj) {
				vm.fetchMergedEntitiesData(taxAttribObj);
				vm.loadingData = true;
				if (taxAttribObj != null) {
					vm.trans_type_key = taxAttribObj.TRANS_TYPE_KEY;
				} else {
					vm.trans_type_key = vm.taxAttribTabs[0].TRANS_TYPE_KEY;
				}

				//					if(rowData.NOL_TYPE == 'NOL_382'){
				//						
				//					}

				var params = {
					"action_code": 'x1kh73', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": GlobalService.globalParams.scenario,
					"jcd_key": GlobalService.globalParams.jcd_key, "ta_mast_key": rowData.TA_MAST_KEY, "trans_type_key": vm.trans_type_key
				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
					vm.taxAttribYearsData = data.jsonObject;
					vm.taKey = vm.taxAttribYearsData[0].TA_KEY;//added 5/10
					vm.loadingData = false;
				});
			}

			//new code to open pop up for delete		
			vm.deleteUtilAmount = function (row) {
				var returnClobSettingsObj = {};
				returnClobSettingsObj['sso_id'] = vm.logged_in_user;
				returnClobSettingsObj['ta_key'] = row.TA_KEY;
				returnClobSettingsObj['trans_key'] = row.TRANS_KEY;
				returnClobSettingsObj['ref_trans_key'] = row.REF_TRANS_KEY;

				//					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);

				var returnObj = {};
				returnObj["ta_key"] = row.TA_KEY;
				returnObj['trans_key'] = row.TRANS_KEY;
				returnObj["ref_trans_key"] = row.REF_TRANS_KEY;

				var message = "Utilized amount has been successfully Deleted";
				var deleteDetails = [];
				deleteDetails.push(returnObj);
				//send the data to delete					
				sendDeleteDetails(returnClobSettingsObj, deleteDetails, message);

			};

			function sendDeleteDetails(returnClobSettingsObj, deleteDetails, message) {
				TaxAttributesServiceFactory.deleteUtilDtls(returnClobSettingsObj, deleteDetails).then(function (result) {

					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						//vm.crudLoading = false;
						AlertService.add("success", message, 4000);
						var args = {
							leid: rowData.LEID,
							cdr_No: rowData.CDR_NO,
							reporting_period: rowData.REPORTING_PERIOD,
							gridFilter: {
								leid: rowData.LEID,
								cdr_No: rowData.CDR_NO,
								reporting_period: rowData.REPORTING_PERIOD
							}
						};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
					};

				});
			}

			vm.openPopUpWindow = function () {
				//					vm.fetchPopUpData(scheduled_year);
				vm.saveFlag = true;
				setTimeout(function () { vm.loadingData = false; });
				var modalInstance = $uibModal.open({
					templateUrl: 'app/components/taxAttributes/taxAttributesEntry.html',
					controller: 'TaxAttributesPopUpData as ctrl',
					size: "md",
					resolve: {
						allTaxAttribList: function () {
							return vm.totalList;
						},
						rowData: function () {
							return rowData;
						},
						scheduled_year: function () {
							return vm.scheduled_year;
						},
						taxAttrib_year_data: function () {
							return vm.taxAttrib_year_data;
						},
						current_tab: function () {
							return vm.trans_type_key;
						}
					}
				});
			};


			if (gridData != null) {

				var tempGridData = _.clone(gridData), groups = Object
					.create(null), result, finalKeyCheck = 0;

				vm.gridDataMultipleSelect = _.clone(tempGridData);
				vm.batchSelect = (vm.gridDataMultipleSelect[0] && vm.gridDataMultipleSelect[0].row_id) ? true : false;

				if (vm.gridDataMultipleSelect != undefined && vm.gridDataMultipleSelect.rowData != null) {

					vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect);
					vm.formDataObj["leid"] = vm.defaultsMultipleSelected[0].rowData.LEID;
					vm.formDataObj["taxCode"] = vm.defaultsMultipleSelected[0].rowData.CDR_NO;
					vm.formDataObj["rp"] = vm.defaultsMultipleSelected[0].rowData.REPORTING_PERIOD;
					vm.formDataObj["taxYearEnd"] = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_END, "MM/dd/yyyy");
					var beginYear = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_END, "yyyy");
					vm.formDataObj["taxYearBeginDate"] = new Date("01/01/" + beginYear);
					vm.formDataObj["taxYearEndDate"] = new Date("12/31/" + beginYear);

				}

			}



			//					vm.fetchModalData();
			vm.fetchModalTabsData();
			//					vm.fetchModalScheduledYearsData();


			//reset the form
			vm.reset = function () {
				if (vm.batchSelect) {
					vm.originalDataCheck = [];
					vm.originalOutterDataCheck = [];
					//						var existingCheck = $filter("filter")(vm.allTabsList, {FORM_KEY:vm.formList[0].FORM_KEY});

					var existingDataCheck = $filter("filter")(vm.allTabsList, { FORM_NAME: vm.activeTab });
					vm.originalDataCheck = $filter("filter")(vm.originalTabsList, { FORM_NAME: vm.activeTab });
					//						vm.originalDataCheck = vm.originalTabsList.filter(function( obj ) {
					//		            	    return obj.FORM_NAME == vm.activeTab;
					//		            	});
					vm.originalOutterDataCheck = $filter("filter")(vm.originalTablsOutterList, { FORM_NAME: vm.activeTab });
					//						vm.originalOutterDataCheck = vm.originalTablsOutterList.filter(function( obj ) {
					//		            	    return obj.FORM_NAME == vm.activeTab;
					//		            	});

					for (var i = 0; i < vm.allTabsList.length; i++) {
						var z = vm.allTabsList[i];
						if (existingDataCheck != null && z != null) {
							if (z[0].FORM_KEY == existingDataCheck[0][0].FORM_KEY) {
								vm.formList = angular.copy(vm.originalDataCheck[0]);
								vm.outerList = angular.copy(vm.originalOutterDataCheck[0]);

								vm.allTabsList.splice(i, 1);
								vm.allTablsOutterList.splice(i, 1);

								vm.allTabsList.push(vm.originalDataCheck[0]);
								vm.allTablsOutterList.push(vm.originalOutterDataCheck[0]);
								break;
							}
						}
					}

				} else {
					vm.formList = angular.copy(vm.originalData);
					vm.outerList = angular.copy(vm.originalOutterData);

					vm.allTabsList = [];
					vm.allTablsOutterList = [];

					vm.allTabsList.push(vm.originalData);
					vm.allTablsOutterList.push(vm.originalOutterData);

					//						vm.allTabsList = angular.copy(vm.originalData);
					//						vm.allTablsOutterList = angular.copy(vm.outerList);
				}
			};


		}

		return controllers;
	});