define(["angular"], function () {
  "use strict";

  var controllers = angular
      .module("app.ClientAdminController", [])
      .controller("ClientAdminController", [
        "$scope",
        "$rootScope",
        "$http",
        "$state",
        "$uibModal",
        "$log",
        "$timeout",
        "SERVER_CONFIG",
        "USER_SETTINGS",
        "ClientAdminFactory",
        "AlertService",
        "GENERAL_CONFIG",
        "MessageService",
        clientAdminController
      ])
      .controller("SaveClientController", [
        "$rootScope",
        "$scope",
        "$state",
        "$parse",
        "$log",
        "$uibModal",
        "JsonObjectFactory",
        "AlertService",
        "$timeout",
        "$window",
        "$uibModalInstance",
        "rowData",
        "tempFolder",
        "SERVER_CONFIG",
        "ClientAdminFactory",
        "USER_MENU",
        "USER_SETTINGS",
       // "ENVIRONMENT_TYPE",
        "selectMenuService",
        "GENERAL_CONFIG",
        "FileSizeService",
        "defaultNavItems",
        '$filter',
        'AdminFactory',
        'TAX_YEARS',
        saveClientController
      ])
      .controller("clientWorkFlowSetupModalController", [
        "AlertService",
        "$parse",
        "$filter",
        "$uibModalInstance",
        "workflowSteps",
        "workflowSetup",
        "workflowModal",
        "GENERAL_CONFIG",
        clientWorkFlowSetupModalController
      ])
      .controller("clientWorkflowEditModalController", [
        "AlertService",
        "ClientAdminFactory",
        "$scope",
        "node",
        "compList",
        "$uibModalInstance",
        "$rootScope",
        "USER_MENU",
        "JsonObjectFactory",
        "GENERAL_CONFIG",
        "JdcManagerFactory",
        "menuDataService",

        clientWorkflowEditModalController
      ])
      .controller("clientWorkflowAddModalController", [
        "AlertService",
        "ClientAdminFactory",
        "$scope",
        "defaultTreeData",
        "clientTreeData",
        "flag",
        "origin",
        "$uibModalInstance",
        "$rootScope",
        "USER_MENU",
        "GENERAL_CONFIG",
        "JsonObjectFactory",
        "menuDataService",
        clientWorkflowAddModalController
      ])
      .controller("rolloverWorkflowEditModalController", [
        "AlertService",
        "ClientAdminFactory",
        "$scope",
        "$uibModalInstance",
        "$rootScope",
        "client_key",
        rolloverWorkflowEditModalController
      ]);

  function saveClientController(
      $rootScope,
      $scope,
      $state,
      $parse,
      $log,
      $uibModal,
      JsonObjectFactory,
      AlertService,
      $timeout,
      $window,
      $uibModalInstance,
      rowData,
      tempFolder,
      SERVER_CONFIG,
      ClientAdminFactory,
      USER_MENU,
      USER_SETTINGS,
     // ENVIRONMENT_TYPE,
      selectMenuService,
      GENERAL_CONFIG,
      FileSizeService,
      defaultNavItems,
      $filter,
      AdminFactory,
      TAX_YEARS
  ) {
    var vm = this;
    vm.menuData = angular.copy(defaultNavItems);
    vm.showPreview = false;
    var selectedItems = [];
    vm.setupdata = [];
    vm.taxYears = TAX_YEARS;
    vm.selectedTaxYear = vm.taxYears[0];
    vm.tyEngagementId = '';
    vm.engagementIds = [];
    vm.tyEngagementIdsList = [];
    vm.finalEngagementIds = {};
    vm.finalEngagementIds["2023"] = "";
    vm.tyList = [];
    vm.eIdList = [];

    vm.removeEntry = function(index) {
      vm.tyEngagementIdsList.splice(vm.tyEngagementIdsList.indexOf(index),1);
      if(vm.tyList.length > index) {
        vm.tyList.splice(vm.tyList.indexOf(index),1);
      }
      if(vm.eIdList.length > index) {
        vm.eIdList.splice(vm.eIdList.indexOf(index),1);
      }
    }

    vm.userLookUp = function(userName) {
      return AdminFactory.getuserDetails({userName:userName}).then(function(data) {
        return data.userDetails;
      });
    }
    vm.loadClientSetupMenu = function () {
      var data = {
        client_key: vm.client.client_key
      };
      vm.loading = true;
      data.jurisdiction_key = 1;
      data.user_id = 0;
      ClientAdminFactory.getEnvironmentType().then(function (data) {
        vm.setEnv=data;
      })

      ClientAdminFactory.getClientSetupData(data).then(function (data) {
        vm.loading = false;

        if (data.callSuccess === "1") {
          vm.setupdata = data.jsonObject;
          vm.initialSetupdata = angular.copy(vm.setupdata);
          copySelectionsToDefault(vm.setupdata);
          vm.treeViewLoaded = true;
          //  vm.menuData2 = data.jsonObject;
        } else {
          if (data.errorMessage === "no access") {
            AlertService.add(
                "error",
                "Sorry you do not have access to do the requested action.",
                4000
            );
          } else {
            AlertService.add(
                "error",
                "An error has occurred, If you continue to experience this issue please visit the help page to contact support.",
                4000
            );
          }
        }
      });
    };
    /* 05/03/2019 Client */
    vm.loadClientLadingDetails = function () {
      vm.url = '/getClientLandDtlsByKey';
      var data = {
        client_key: vm.client.client_key
      };
      ClientAdminFactory.clientLandingDataPost(data, vm.url).then(function (data) {
        if (data.callSuccess === "1") {
          vm.clientLandingDtls = data.clientLanDtls[0];
          initDates();
          console.log("land details", vm.clientLandingDtls);
        } else {
          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
        }
      })
    }
    function initDates(){
      if(vm.clientLandingDtls){
        vm.clientLandingDtls.est_filing_date = new Date(vm.clientLandingDtls.est_filing_date);
        vm.clientLandingDtls.ext_filing_date = new Date(vm.clientLandingDtls.ext_filing_date);
        vm.clientLandingDtls.filing_date = new Date(vm.clientLandingDtls.filing_date);
        vm.clientLandingDtls.start_date = new Date(vm.clientLandingDtls.start_date);

      }

    }
    function copySelectionsToDefault(treeData) {
      for (var index in treeData) {
        var defaultNode = vm.menuData.find(function (item) {
          return (
              item.child_id === treeData[index].child_id &&
              treeData[index].nav_level == item.nav_level
          );
        });
        if (defaultNode) defaultNode.is_selected = treeData[index].is_selected;
        else console.log("not found in default side nodes", treeData[index]);
        // searchNodeAndCopy(vm.menuData, treeData[index]);

        // if(treeData[index].children){
        // 	copySelectionsToDefault(treeData[index].children);
        // }
      }
    }

    function searchNodeAndCopy(treeData, node) {
      for (var index in treeData) {
        if (
            treeData[index].child_id === node.child_id &&
            treeData[index].nav_level == node.nav_level &&
            typeof node.is_selected !== "undefined"
        ) {
          treeData[index].is_selected = node.is_selected;
          treeData[index].has_new = node.has_new;

          return;
        } else {
          if (treeData[index].children)
            searchNodeAndCopy(treeData[index].children, node);
        }
      }
    }

    vm.preview = function () {
      vm.showPreview = true;
    };
    vm.supportEmail = "";
    vm.engagementContact = "";
    vm.clientDatasource = "";

    vm.setStepperData = function () {
      vm.stepperData = angular.copy(ClientAdminFactory.getSteppers()); //used copy to avoid persistent step selection.
    };

    function getDifference(arr1, arr2, origin) {
      if (origin === 'workflow') {
        var changes = arr1.filter(function (item) {
          return arr2.find(function (item2) {
            return (
                item2.child_id === item.child_id &&
                item2.nav_level === item.nav_level &&
                (item2.is_selected !== item.is_selected || item2.name !== item.name || item2.priority !== item.priority)
            );
          });
        });
        return changes;
      }
      var changes = arr1.filter(function (item) {
        return arr2.find(function (item2) {
          return (
              item2.child_id === item.child_id &&
              item2.nav_level === item.nav_level &&
              (item2.is_selected !== item.is_selected || item2.name !== item.name)
          );
        });
      });
      return changes;
    }

    vm.saveAppSetup = function () {
      // selectedItems = [];
      var origin = 'appstack';
      var changes = getDifference(vm.setupdata, vm.initialSetupdata, origin);

      if (changes.length === 0) {
        AlertService.add("warning", "No changes to save", 3000);
        return;
      }

      changes = changes.map(function (item) {
        return _.pick(item, ["child_id", "is_selected", "type", "name", "id"]);
      });
      // console.log(changes);
      vm.saving = true;
      ClientAdminFactory.saveAppSetup(vm.client.client_key, changes).then(
          function (response) {
            vm.saving = false;
            if (response.data.result.value > 0) {
              AlertService.add("success", response.data.result.message, 3000);
              // vm.cancel();
            } else {
              AlertService.add("error", "Problem saving app setup.", 3000);
              return;
            }
          }
      );
    };

    vm.mode =
        angular.isDefined(rowData.client_key) &&
        rowData.client_key != null &&
        rowData.client_key != "" ?
            (function () {
              vm.client_key = rowData.client_key;
              return "edit";
            })() :
            "new";

    vm.title = _.capitalize(vm.mode) + " Client";

    vm.client = {
      client_name: "",
      client_desc: "",
      client_size: "",
      client_active: "",
      support_email: "",
      engagement_contact: [""],
      client_contact: "",
      client_logo: "",
      client_css: "",
      client_san: "",
      database_options: "",
      client_key: "",
      client_logo_change_success: false,
      //instance_val: ENVIRONMENT_TYPE,
      saveClicked: false,
      logoFilesMarkedForDeletion: [],
      flow: {},
      base_url: GENERAL_CONFIG.admin_api_url +
          "/uploadClientLogo?clientKey=" +
          rowData.client_key,
      client_logo_file_name: "",
      txDatabaseName: "",
      dataSourceName: "",
      txSchemaName: "",
      fdmSchemaName: "",
      client_datasource: "",
      rds_inst_src_snapshot_id: "",
      tax_type: "",
      wfst_key: "",

    };
    vm.engagementContactsList = vm.client.engagement_contact;

    vm.clientLandingDtls = {
      // Client MasterData SetUp Variables Landing details
      client_dtls_key: "",
      start_date: "",
      ext_filing_date: "",
      filing_date: "",
      ext_filed: "",
      tax_return_filed_2018: "",
      business_sign_off: "",
      number_of_users: "",
      hour_spent: "",
      percentile_complete: "",
      engagement_contact_person: "",
      engagement_contact_number: "",
      engagement_contact_email: "",
      active_users: "",
      est_filing_date: ""

    }
    vm.masterCopy = angular.copy(vm.client);

    vm.activeOptionList = [{
      name: "Yes",
      value: "1"
    },
      {
        name: "No",
        value: "0"
      }
    ];

    if (_.lowerCase(vm.mode) == _.lowerCase("edit")) {
      vm.showPreview = true;
      vm.client.client_key = rowData.client_key;
      vm.client.client_name = rowData.client_name;
      vm.client.client_desc = rowData.client_desc;
      vm.client.client_active = rowData.client_active;
      vm.client.client_size = rowData.client_size;
      vm.client.support_email = rowData.support_email;
      vm.client.engagement_contact = rowData.engagement_contact ? rowData.engagement_contact.split(',') : '';
      if(rowData.engagement_id && rowData.engagement_id != null && rowData.engagement_id != '') {
        vm.client.engagement_id = rowData.engagement_id != null ? rowData.engagement_id : '';
        let engagmentIdsByTyList = vm.client.engagement_id.split(','); // [2023=1234, 2022=2345]
        engagmentIdsByTyList.forEach(i => {
          let data = i.split('=');
          vm.tyList.push(Object.values(vm.taxYears).filter(i => i.label === data[0])[0]);
          vm.eIdList.push(data[1]);
          vm.tyEngagementIdsList.push('');
        });
      } else {
        vm.tyEngagementIdsList.push({
          taxYer: "",
          value: ""
        });
      }

      vm.client.client_contact = rowData.client_contact;
      vm.client.client_dbtype=rowData.client_env;
      vm.client.client_logo = rowData.client_logo;
      vm.client.client_css = rowData.client_css;
      vm.client.client_san = rowData.client_san;
      vm.client.database_options = rowData.database_options;
      vm.client.txDatabaseName = rowData.txDatabaseName;
      vm.client.dataSourceName = rowData.dataSourceName;
      vm.client.txSchemaName = rowData.txSchemaName;
      vm.client.fdmSchemaName = rowData.fdmSchemaName;
      vm.client.client_logo_file_name = rowData.client_logo_file_name;
      vm.client.client_datasource = rowData.client_datasource;
      vm.client.client_gold_ds = rowData.client_gold_ds;
      vm.client.logo_client = vm.imageURl(vm.client)//GENERAL_CONFIG.admin_api_url+'/downloadClientLogo/'+vm.client.client_key;
      vm.supportEmail =
          vm.client.support_email != null && vm.client.support_email != "" ?
              vm.client.support_email.substr(
                  0,
                  vm.client.support_email.lastIndexOf("@")
              ) :
              "";
      // vm.engagementContact =
      //     vm.client.engagement_contact != null &&
          // vm.client.engagement_contact != "" ?
      vm.engagementContactsList = _.map(vm.client.engagement_contact,function(value,index){
          return value != "" ? value.substr(
            0,
            value.lastIndexOf("@")
        ) :
        ""
      });

      vm.engagementContactEmail =
          vm.client.engagement_contact_email != null &&
          vm.client.engagement_contact_email != "" ?
              vm.client.engagement_contact_email.substr(
                  0,
                  vm.client.engagement_contact_email.lastIndexOf("@")
              ) :
              "";
      vm.clientDatasource =
          vm.client.client_datasource != null &&
          vm.client.client_datasource != "" ?
              vm.client.client_datasource.substring(3, 7) :
              null;
    } else {
      vm.tyEngagementIdsList.push({
        taxYer: "",
        value: ""
      });
    }

    var defaultClient = angular.copy(vm.client);
    vm.isClientNameExit = false
    vm.checkClientName = function() {

      if(vm.client.client_name != '') {
        ClientAdminFactory.checkClientNameDuplicate(vm.client.client_name).then(function(data) {
          if(data.status==200){
            vm.isClientNameExit = data.data;
          }
          console.log(data);
        })

      }
      console.log("VM.client",vm.client)
    }
    vm.stepperChanged = function (item) {
      if (!vm.client.client_key) {
        AlertService.add(
            "warning",
            "Please complete the general information step", 3000
        );
        return;
      } else if (!vm.isMissing(vm.saveClientFrm)) {
        if (vm.saveClientFrm.$dirty) {
          AlertService.add(
              "warning",
              "Please save the changes made in the general information step", 3000
          );
          return;
        }
      }
      for (var index in vm.stepperData) {
        vm.stepperData[index].isCurrent = false;
        if (vm.stepperData[index].stepNo <= item.stepNo) {
          vm.stepperData[index].enabled = true;
        } else {
          vm.stepperData[index].enabled = false;
        }
      }
      item.isCurrent = true;
      vm.selectedStep = item;
      if (item.stepNo == 2) {
        vm.loadClientSetupMenu();
      }
      if (item.stepNo == 3) {
        vm.loadClientLadingDetails();
      }
    };
    vm.isEmptyObj = function (obj) {
      return Object.keys(obj).length == 0;
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss("cancel");
      $rootScope.$broadcast("gtw.admin.client.clientModalClose");
    };

    vm.isMissing = function (obj) {
      return angular.isUndefined(obj) || obj == null || obj == "";
    };

    vm.isClientNameMissing = function () {
      return (
          vm.saveClientFrm["client_name"].$dirty &&
          vm.isMissing(vm.client.client_name)
      );
    };

    vm.isClientDescMissing = function () {
      return (
          vm.saveClientFrm["client_desc"].$dirty &&
          vm.isMissing(vm.client.client_desc)
      );
    };

    vm.isClientSizeMissing = function () {
      return (
          vm.saveClientFrm["client_size"].$dirty &&
          vm.isMissing(vm.client.client_size)
      );
    };

    vm.isClientActiveMissing = function () {
      return (
          vm.saveClientFrm["client_active"].$dirty &&
          vm.isMissing(vm.client.client_active)
      );
    };

    vm.isLoggedInClient = function(client_key) {
      return client_key == USER_SETTINGS.user.client_key;
    };

    vm.isSupportEmailMissing = function () {
      return vm.client.saveClicked && (vm.isMissing(vm.supportEmail) || isEmailInvalid(vm.supportEmail));
    };

    vm.isEngagementContactMissing = function () {
      // console.log("email valide",isEmailInvalid(vm.engagementContact))
      // console.log("save clicked",vm.client.saveClicked)
      // console.log("email missing",vm.isMissing(vm.engagementContact))
      return vm.client.saveClicked &&
       (vm.engagementContactsList.filter(function(c){return c !== ''}).length === 0 ||
       _.uniq(vm.engagementContactsList).length !== vm.engagementContactsList.length)
      //  vm.engagementContactsList.some(isEmailInvalid));
    };

    vm.isInvalidEngagementId = function () {
        if(vm.isMissing(vm.client.engagement_id) || /^[a-zA-Z0-9]+$/.test(vm.client.engagement_id)) {
          return false;
        }
        return true;
    };

    vm.isClientLogoMissing = function () {
      return vm.client.saveClicked && vm.isMissing(vm.client.client_logo);
    };

    vm.isClientCssMissing = function () {
      return (
          vm.saveClientFrm["client_css"].$dirty &&
          vm.isMissing(vm.client.client_css)
      );
    };

    vm.isClientSanMissing = function () {
      return (
          vm.saveClientFrm["client_san"].$dirty &&
          vm.isMissing(vm.client.client_san)
      );
    };
    vm.isClientDatasourceMissing = function () {
      return vm.client.saveClicked && vm.isMissing(vm.clientDatasource);
    };
    vm.getDbEnv=function(){
      return $rootScope.db;
    }
    vm.isEngagementContactEmailMissing = function () {
      console.log("email valide",isEmailInvalid(vm.engagementContactEmail))
      console.log("save clicked",vm.client.saveClicked)
      console.log("email missing",vm.isMissing(vm.engagementContactEmail))
      return vm.client.saveClicked && vm.isMissing(vm.engagementContactEmail) && isEmailInvalid(vm.engagementContactEmail);
    };
    function isEmailInvalid(mail)
    {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail + '@email.com'))//appending @email.com as it is added later.
      {
        return false;
      }
        return true;
    }

    vm.myClick = function (node) {
      //alert('Clicked [' + node.name + '] state is [' + node.checked + ']');

      var confirm = dialog.confirm("Editar", node);
      confirm.result.then(function (btn) {});
    };

    vm.saveGI = function () {
      vm.client.saveClicked = true;
      vm.client.client_size =
          vm.client.client_size == "" ? "Large" : vm.client.client_size;
      vm.client.client_active =
          vm.client.client_active == "" ? "1" : vm.client.client_active;

      angular.forEach(["client_name", "client_desc"], function (frmElement) {
        vm.saveClientFrm[frmElement].$dirty = true;
      });
      vm.client.engagement_contact = vm.engagementContactsList.length > 0 ? vm.engagementContactsList.filter(function(item) {
        return item !== '';
      }).map(function(contact){
        return  contact + '@pwc.com';
      }).join(",") : "";


      var isRequiredFieldsMissing =
          vm.isClientNameMissing() ||
          vm.isClientDescMissing() ||
          // vm.isSupportEmailMissing() ||
          vm.isEngagementContactMissing() ||
          //vm.isClientLogoMissing() ||
          vm.isClientDatasourceMissing() ||
          vm.isClientNameExit;
      if (isRequiredFieldsMissing) {
        AlertService.add("error", "Please correct errors below", 3000);
        return;
      }

      vm.client.client_css = "Default";
      vm.client.client_san = "Default";
      vm.client.database_options = "Cloud";
      vm.client.support_email = vm.supportEmail + "@servicedesk.com";
      // vm.client.engagement_contact = vm.engagementContact + "@pwc.com";
      // vm.client.engagement_contact_email = vm.engagementContactEmail + "@pwc.com";
      vm.client.client_datasource = "GTW" + vm.clientDatasource + vm.getDbEnv();

      vm.clientEngagmentIdByTy = new Map();
      for(let i=0; i< vm.tyList.length; i++) {
        vm.clientEngagmentIdByTy.set(vm.tyList[i].label, vm.eIdList[i]);
      }
      vm.client.engagement_id = '';
      vm.clientEngagmentIdByTy.forEach((v,k) => {
        vm.client.engagement_id += k + '=' + v + ','; 
      });
      vm.client.engagement_id = vm.client.engagement_id.substr(0, vm.client.engagement_id.length-1);

      //         	console.log('saving client->' + JSON.stringify(vm.client));
      vm.saving = true;
      ClientAdminFactory.saveClient(vm.client).then(function (response) {
        vm.saving = false;
        if (parseInt(response.data.callSuccess) > 0) {
          //AlertService.add("success", "Client " + vm.client.client_name + " saved successfully.");
          $rootScope.$broadcast("gtw.admin.client.loadClients");

          if (vm.client.client_key) {
            AlertService.add("success", "Client saved successfully", 3000);
          }

          $rootScope.$broadcast('gtw.nav.refreshclients');
          vm.cancel();
        } else {
          AlertService.add(
              "error",
              "Problem saving client: " + response.data.errorMessage, 3000
          );
        }
      });
    };
    vm.saveClientLandingDtls = function () {
      vm.url = '/saveClientLandingDtls'
      vm.clientLandingDtls.est_filing_date = $filter('date')(vm.clientLandingDtls.est_filing_date, 'MM-dd-yyyy');
      vm.clientLandingDtls.filing_date = $filter('date')(vm.clientLandingDtls.filing_date, 'MM-dd-yyyy');
      vm.clientLandingDtls.ext_filing_date = $filter('date')(vm.clientLandingDtls.ext_filing_date, 'MM-dd-yyyy');
      vm.clientLandingDtls.start_date = $filter('date')(vm.clientLandingDtls.start_date, 'MM-dd-yyyy');
      vm.clientLandingDtls.client_key = vm.client_key;
      vm.clientLandingDtls.number_of_users = "1"; //220
      vm.clientLandingDtls.hour_spent = "1";//4000
      vm.clientLandingDtls.percentile_complete = "1";//35
      vm.clientLandingDtls.active_users = "1";
      vm.clientLandingDtls.engagmnt_contact_person = "heath.j.stein@pwc.com";
      vm.clientLandingDtls.engagmnt_contact_number = "999-888-0000";

      var data = {
        data: JSON.stringify(vm.clientLandingDtls),

      };
      initDates(); /*re-initializing dates to date objects*/
      ClientAdminFactory.clientLandingDataPost(data, vm.url).then(function (data) {
        if (data.callSuccess === "1") {
          AlertService.add("success", "Client Landing Details saved Successfully.", 4000);
          console.log("land details", vm.client);
        } else {
          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
        }
      })


    }

    // vm.removeClient = function(client_key) {
    //   ClientAdminFactory.deleteClient(client_key).then(
    //     function(data) {
    //       if (data.data.clientResult.errorMessage == "") {
    //         AlertService.add("success", "Client removed successfully.");
    //         $rootScope.$broadcast("gtw.admin.client.loadClients");
    //         $rootScope.$broadcast('gtw.nav.refreshclients');
    //         vm.cancel();
    //       } else {
    //         AlertService.add("error", "Problem removing client.");
    //         return;
    //       }
    //     },
    //     function() {
    //       AlertService.add("error", "Problem removing client.");
    //     }
    //   );
    // };

    // vm.removeClient = function(client_key) {
    //   ClientAdminFactory.deleteClient(client_key).then(
    //     function(data) {
    //       if (data.data.clientResult.errorMsg == "") {
    //         AlertService.add("success", "Client removed successfully.");
    //         $rootScope.$broadcast("gtw.admin.client.loadClients");
    //         vm.cancel();
    //       } else {
    //         AlertService.add("error", "Problem removing client.");
    //         return;
    //       }
    //     },
    //     function() {
    //       AlertService.add("error", "Problem removing client.");
    //     }
    //   );
    // };

    vm.clientWorkflowTree = [];
    vm.defaultWorkflowTree = [];
    vm.filterFlag = "false";
    vm.tax_type;

    vm.workflowTypes = [];
    vm.domesticWorkflowTypes = [];
    vm.foriegnWorkflowTypes = [];
    vm.selectedWFType;
    vm.scenariosByTaxyearArr = [];
    vm.taxyearsMap = [];
    vm.selectedTaxYear;
    vm.selectedScenario;

    var loadWorkflowTypes = function () {
      ClientAdminFactory.getWorkflowTypes(vm.client.client_key).then(function (
          data
      ) {
        console.log("Workflow Type data ---> ", data);
        vm.workflowTypes = data.data.workflowType;
        var index;
        for (index = 0; index < vm.workflowTypes.length; index++) {
          if (vm.workflowTypes[index].wfStep_Type == "D") {
            vm.domesticWorkflowTypes.push(vm.workflowTypes[index]);
          } else if (vm.workflowTypes[index].wfStep_Type == "F") {
            vm.foriegnWorkflowTypes.push(vm.workflowTypes[index]);
          } else {
            console.log(
                "WARN!! A new wfStep_Type other than D and F is encountered - " +
                vm.workflowTypes[index]
            );
          }
        }
      });
    };

    var loadScenariosByTaxyear = function () {
      ClientAdminFactory.getTaxYearAndScenarioFilters(
          vm.client.client_key
      ).then(function (data) {
        console.log("TaxYearAndScenarioFilters ---> ", data);
        vm.scenariosByTaxyearArr = data.data.ScenariosByTaxyearMap;
        vm.taxyearsMap = data.data.taxyearsMap;
      });
    };

    // Load Workflow type, Tax year and scenario
    loadWorkflowTypes();
    loadScenariosByTaxyear();

    vm.getWorkflowTree = function (wfst_key, tax_year, scenario, client_key, forceRerender) {
      ClientAdminFactory.getWorkflowTree(
          wfst_key,
          tax_year,
          scenario,
          client_key
      ).then(function (data) {
        if (data.data.errorMessage !== "") {
          AlertService.add("error", data.data.errorMessage, 3000);
        } else {
          if (client_key === "0" || client_key === "999") {
            vm.defaultWorkflowTree = data.data.workflowHierarchy;
          } else {
            vm.clientWorkflowTree = data.data.workflowHierarchy;
            vm.initialClientWorkflowTree = angular.copy(
                data.data.workflowHierarchy
            );
          }
          if (forceRerender)
            $rootScope.$broadcast('gtw.admin.client.forceRerenderTree', client_key);
          if (
              vm.defaultWorkflowTree.length > 0 &&
              vm.clientWorkflowTree.length > 0
          ) {
            copyWorkflowSelectionsToDefault(vm.clientWorkflowTree);
            vm.client.wfst_key = wfst_key;
          }

          vm.convertTaxType(wfst_key);
          vm.filterFlag = "true";
        }
      });
    };

    $scope.$on("gtw.admin.client.getWorkflowTree", function (event, data) {
      vm.getWorkflowTree(vm.client.wfst_key, data.tax_year, data.scenario, data.client_key, true);
    });

    $scope.$on("gtw.admin.client.getOnlyDefaultWorkflowTree", function (event, data) {
      vm.getOnlyDefaultWorkflowTree(vm.client.wfst_key, data.tax_year, data.scenario, data.client_key);
    });

    vm.getOnlyDefaultWorkflowTree = function (wfst_key, tax_year, scenario, client_key) {
      ClientAdminFactory.getOnlyDefaultWorkflowTree(
          wfst_key,
          tax_year,
          scenario,
          client_key
      ).then(function (data) {
        if (data.data.errorMessage !== "") {
          AlertService.add("error", data.data.errorMessage, 3000);
        } else {
          vm.defaultWorkflowTree = data.data.workflowHierarchy;
          vm.clientWorkflowTree = vm.defaultWorkflowTree;
          selectActiveNodesInDefault(vm.clientWorkflowTree);
        }
        vm.client.wfst_key = wfst_key;
        vm.convertTaxType(wfst_key);
        vm.filterFlag = "true";
      });
    };

    vm.rolloverWorkflow = function(){

      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/admin/client/templates/rollover-workflow-modal.html',
        controller: 'rolloverWorkflowEditModalController as ctrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          client_key: function() {
            return vm.client.client_key;
          }
        }
      });

    };


    function selectActiveNodesInDefault(treeData) {
      for (var index in treeData) {
        if (treeData[index].is_active === '1') {
          var defaultNode = vm.defaultWorkflowTree.find(function (item) {
            return (
                item.child_id === treeData[index].child_id &&
                treeData[index].nav_level == item.nav_level
            );
          });
          if (defaultNode) {
            if (treeData[index].type === 'category') {
              defaultNode.is_selected = 1;
            } else {
              defaultNode.is_selected = 2;
            }
          } else console.log("not found in default side nodes", treeData[index]);
        }
      }
      vm.initialClientWorkflowTree = angular.copy(vm.clientWorkflowTree);
    }

    function setDefaultNodesSelectedZero(treeData) {
      for (var index in treeData) {
        if (treeData[index].is_active === '1') {
          var defaultNode = vm.defaultWorkflowTree.find(function (item) {
            return (
                item.child_id === treeData[index].child_id &&
                treeData[index].nav_level == item.nav_level
            );
          });
          if (defaultNode) {
            defaultNode.is_selected = 0;
          } else console.log("not found in default side nodes", treeData[index]);
        }
      }
    }

    function copyWorkflowSelectionsToDefault(treeData) {
      for (var index in treeData) {
        var defaultNode = vm.defaultWorkflowTree.find(function (item) {
          return (
              item.child_id === treeData[index].child_id &&
              treeData[index].nav_level == item.nav_level
          );
        });
        if (defaultNode) {
          defaultNode.is_selected = treeData[index].is_selected;
          defaultNode.has_new = treeData[index].has_new;
          if (defaultNode.has_new === "1" && defaultNode.is_selected === 2) {
            defaultNode.showPushBtn = true;
          }
        } else console.log("not found in default side nodes", treeData[index]);
      }
    }

    $scope.$on("gtw.admin.client.isEditAllowed", function (event, data) {
      vm.isEditAllowed(data.node, data.editAllowed);
    });

    vm.isEditAllowed = function (node, editAllowed) {
      var changes = vm.initialClientWorkflowTree.find(function (item) {
        return (
            item.child_id === node.child_id &&
            item.nav_level === node.nav_level &&
            (item.is_selected !== 0)
        );
      });

      if (!changes) {
        $rootScope.isEditAllowed = false;
        return;
      }
    }

    vm.saveClientWorkflow = function () {
      var origin = 'workflow';
      var changes = getDifference(
          vm.clientWorkflowTree,
          vm.initialClientWorkflowTree, origin
      );

      if (changes.length === 0) {
        AlertService.add("warning", "No changes to save", 3000);
        return;
      }

      if (vm.client.client_key === '0' || vm.client.client_key === '999') {
        setDefaultNodesSelectedZero(vm.clientWorkflowTree);
      }

      vm.saving = true;
      ClientAdminFactory.saveClientWorkflow(
          vm.selectedWFType,
          vm.selectedTaxYear,
          vm.selectedScenario,
          vm.client.client_key,
          changes
      ).then(function (response) {
        vm.saving = false;
        if (response.data.result.value > 0) {
          if (vm.client.client_key === '0' || vm.client.client_key === '999') {
            vm.getOnlyDefaultWorkflowTree(vm.selectedWFType, vm.selectedTaxYear, vm.selectedScenario, vm.client.client_key);
          } else {
            vm.getWorkflowTree(vm.selectedWFType, vm.selectedTaxYear, vm.selectedScenario, vm.client.client_key);
          }
          AlertService.add("success", response.data.result.message, 3000);
        } else {
          AlertService.add("error", response.data.result.message, 3000);
          return;
        }
      });
    };

    vm.convertTaxType = function (wfst_key) {
      if (wfst_key === "1") {
        vm.client.tax_type = "Domestic Corporation";
      } else if (wfst_key === "2") {
        vm.client.tax_type = "Domestic Partnership";
      } else if (wfst_key === "3") {
        vm.client.tax_type = "Domestic Insurance";
      } else if (wfst_key === "4") {
        vm.client.tax_type = "Foreign Corporation";
      } else if (wfst_key === "5") {
        vm.client.tax_type = "Foreign Partnership";
      } else if (wfst_key === "6") {
        vm.client.tax_type = "Foreign Insurance";
      }
    };

    vm.testDataSourceConnection = function () {
      ClientAdminFactory.testClientDataSourceConn(
          vm.client.client_datasource
      ).then(
          function (data) {
            if (data.data.uploadStatus == "success") {
              vm.client.datasource_connection_msg =
                  "Connection successful. Tested at " +
                  moment().format("h:mm:ss a");
              vm.client.datasource_connection_status = "success";
            } else {
              vm.client.datasource_connection_msg =
                  "Connection failed. Tested at " + moment().format("h:mm:ss a");
              vm.client.datasource_connection_status = "failure";
            }
          },
          function () {
            vm.client.datasource_connection_msg =
                "Connection failed. Tested at " + moment().format("h:mm:ss a");
            vm.client.datasource_connection_status = "failure";
          }
      );
    };

    vm.clearForm = function () {
      var client_key = vm.client.client_key;
      vm.client = angular.copy(vm.masterCopy);
      vm.supportEmail = "";
      vm.engagementContact = "";
      if (_.lowerCase(vm.mode) != _.lowerCase("edit")) {
        vm.clientDatasource = "";
      }
      vm.client.client_key = client_key;
      vm.failCount = 0;
      vm.saveClientFrm.$setPristine();
    };

    vm.getClientLogoFileName = function () {
      if (
          angular.isUndefined(vm.client.client_logo) ||
          vm.client.client_logo == null ||
          vm.client.client_logo == ""
      ) {
        return "";
      }

      return vm.client.client_logo.substr(
          vm.client.client_logo.lastIndexOf("/") + 1
      );
    };

    vm.removeLogo = function () {
      if (
          vm.client.logoFilesMarkedForDeletion.indexOf(
              "," + vm.client.client_logo + ","
          ) < 0
      ) {
        vm.client.logoFilesMarkedForDeletion.push(vm.client.client_logo);
      }

      ClientAdminFactory.deleteClientLogo(
          vm.client.client_key,
          vm.client.client_logo
      ).then(
          function (data) {
            if (data.data.uploadStatus == "success") {
              vm.client.client_logo = "";
              // vm.client.client_logo_change_success = true;
              vm.client.client_logo_change_success = false;
              vm.client.client_logo = "";
              vm.client.client_logo_file_name = "";
            } else {
              return;
            }
          },
          function () {
            AlertService.add("error", "Problem removing client logo.", 3000);
          }
      );
    };

    vm.downloadLogo = function () {
      if (USER_SETTINGS.user.client_key.toString() !== vm.client.client_key) {
        AlertService.add("warning", "Downloading other client's image is not permitted from here.", 4000);
        return;
      }

     /*  var secondaryWindow = $window.open(
          GENERAL_CONFIG.admin_api_url +
          "/downloadClientLogo?data=" +
          vm.client.client_logo +
          "&client_key=" +
          vm.client.client_key
      ); */
      var secondaryWindow = $window.open(
        GENERAL_CONFIG.admin_api_url +
        "/downloadClientLogo/"+vm.client.client_key
    );

      if (secondaryWindow == null) {
        return;
      }

      // Check if there was a problem downloading the logo.
      secondaryWindow.onload = function () {
        if (secondaryWindow.document.body.innerHTML.length > 0) {
          $scope.$broadcast("gtw.admin.client.downloadLogo.failed");
          secondaryWindow.close();
        }
      };
    };

    $scope.$on("gtw.admin.client.downloadLogo.failed", function (event, data) {
      AlertService.add("error", "Problem downloading logo.", 3000);
    });

    $scope.$on("$destroy", function () {
      ClientAdminFactory.removeClientLogoTempFolder().then(
          function (data) {},
          function () {}
      );
    });

    /*************************** WoRKFLOW SETUP **************************/
    // var vm = this;

    vm.addWorkFlowSetup = function (workflow, index) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "app/components/admin/client/templates/user-workflow-setup.html",
        controller: "clientWorkFlowSetupModalController as ctrl",
        size: "md",
        backdrop: "static",
        resolve: {
          workflowSteps: function () {
            return angular.copy(vm.headers);
          },
          workflowSetup: function () {
            return angular.copy(vm.workflowSetup);
          },
          workflowModal: function () {
            return workflow ? angular.copy(workflow) : undefined;
          }
        }
      });

      modalInstance.result.then(
          function (result) {
            if (result) {
              if (result.workflowType === "Workflow") {
                !angular.isDefined(index) ?
                    pushWorkflow(result) :
                    updateWorkflow(index, result);
              }
            }
            console.log(result);
          },
          function (reason) {
            console.log(reason);
          }
      );
    };

    vm.getWorkFlowData = function () {
      var url = "app/json/entityList/entity-GC4912.json";
      JsonObjectFactory.getJSONObj(url).then(function (data) {
        vm.headers = data.workflow_flow.workflow_steps;
        vm.workFlowSubTypes = data.workflow_sub_types;
      });
    };
    vm.getWorkFlowData();

    vm.removeWorkflow = function (workflow, index) {
      vm.workflows.splice(index, 1);
    };

    vm.editWorkflow = function (workflow, index) {
      vm.addWorkFlowSetup(workflow, index);
    };

    function pushWorkflow(workflow) {
      vm.workflows.push(workflow);
    }

    function updateWorkflow(index, newWorkflow) {
      vm.workflows.splice(index, 1, newWorkflow);
    }

    /****************************************** start of callback functions used by flowjs ******************************************/

    vm.failCount = 0;

    vm.fileAdded = function (file, event, flow) {
      var fileExtension = file.getExtension().toLowerCase();
      var allowedFileExtensions = ",jpg,jpeg,png,svg,";
      var isAllowedFileExtension =
          allowedFileExtensions.indexOf("," + fileExtension + ",") >= 0;
      var isFileSizeWithinLimit =
          file.size <= SERVER_CONFIG.configSettings.fileUploadMaxSize;
      var isFileAdded = isAllowedFileExtension && isFileSizeWithinLimit;

      file.error = false;
      file.message = "";

      if (!isFileAdded) {
        if (!isAllowedFileExtension) {
          file.error = true;
          file.message = file.name + " is invalid.";
          flow.cancel();
        }

        if (!isFileSizeWithinLimit) {
          file.error = true;
          file.message =
              file.name +
              " exceeds the allowed maximum size of " +
              FileSizeService.readableFileSize(
                  SERVER_CONFIG.configSettings.fileUploadMaxSize
              );
          flow.cancel();
        }
      }

      return true;
    };

    vm.setFiles = function (files, flow) {
      if (files[0].error == false) {
        flow.upload();
      }
    };

    vm.setErrorFile = function (file, message, flow) {
      file.error = true;
      file.message =
          file.name +
          " is invalid or exceeds allowed maximum size of " +
          FileSizeService.readableFileSize(
              SERVER_CONFIG.configSettings.fileUploadMaxSize
          );
      flow.cancel();
    };

    vm.fileSuccess = function (file, message, flow) {
      var parsedMessage = JSON.parse(message);

      if (parsedMessage.uploadStatus == "success") {
        vm.client.logoFilesMarkedForDeletion.push(vm.client.client_logo);

        var fileName = parsedMessage.uploadedFilePath;
        vm.client.client_logo = fileName;
        // vm.client.client_logo = 'upload/' + tempFolder + '/' + parsedMessage.flowFilename;
        vm.client.client_logo_file_name = parsedMessage.flowFilename;
        vm.client.client_logo_change_success = true;

        vm.client.logoFilesMarkedForDeletion = _.without(
            vm.client.logoFilesMarkedForDeletion,
            vm.client.client_logo
        );
      } else if (parsedMessage.uploadStatus == "error") {
        file.error = true;
        file.message = parsedMessage.message;
      }
    };

    vm.dragEnter = function () {
      vm.style = {
        border: "2px solid #37d631"
      };
    };

    vm.dragLeave = function () {
      vm.style = {};
    };

    /****************************************** end of callback functions used by flowjs ******************************************/
  }

  function clientAdminController(
      $scope,
      $rootScope,
      $http,
      $state,
      $uibModal,
      $log,
      $timeout,
      SERVER_CONFIG,
      USER_SETTINGS,
      ClientAdminFactory,
      AlertService,
      GENERAL_CONFIG,
      MessageService
  ) {
    var vm = this;
    vm.title = "Clients";
    vm.refreshMoment = "";
    vm.isRefresh = false;
    vm.currentClient = USER_SETTINGS.user.client_key.toString();

    vm.clientList = [];
    vm.tempUploadFolder = "";
    var _defaultNavItems;

    MessageService.subscribeToChannelWithCallback(GENERAL_CONFIG.base_url + '/gtw-websockets','/notify/' + USER_SETTINGS.user.sso_id, function (payload) {
      var notification = JSON.parse(payload.body);
      notification.context = JSON.parse(notification.context);
      if (_.includes(notification.notificationMessage, "4/4")) {
        $rootScope.$broadcast("gtw.nav.refreshclients");
        var client = vm.clientList.find(function (item) {
          return item.client_key === notification.context.client_key
        });
        client.client_status = 'Available';
        client.current_step = '4';
        client.process_step_description = 'Send Notifications';
      }
      AlertService.add(notification.type, notification.notificationMessage,4000);
      $rootScope.$broadcast('pushNotifications', JSON.parse(payload.body));
      $rootScope.$apply();
    })

    var loadAllClients = function () {
      ClientAdminFactory.getAllClients().then(
          function (data) {
            vm.loading = false;
            if (data.data.clientResult.errorMsg == "") {
              vm.clientList = data.data.clientResult.clientList;
              vm.tempUploadFolder = data.data.clientResult.tempUploadFolder;
              vm.clientList.forEach(function(item) {
                item.domId = item.client_name.replace(/ /g,"").toLowerCase().trim();
                item.logo_client = vm.imageURl(vm.client)//GENERAL_CONFIG.admin_api_url+'/downloadClientLogo/'+item.client_key;

              })
              if (vm.isRefresh) {
                vm.refreshMoment = moment().format("h:mm:ss a");
                vm.isRefresh = false;
              } else {
                vm.refreshMoment = "";
              }
            } else {
              AlertService.add("error", "Problem getting clients.", 3000);
              return;
            }
          },
          function () {
            AlertService.add("error", "Problem getting clients.", 3000);
          }
      );
    };
    vm.imageURl = function(newItem){
      var config = {
        responseType: 'blob'
      };

        $http.get(GENERAL_CONFIG.admin_api_url + '/downloadClientLogo/'+newItem.client_key,config).then(function(response) {
          var blob = new Blob([response.data], {type: 'image/jpeg'});
          var urlCreator = window.URL || window.webkitURL;
          newItem.logo_client = urlCreator.createObjectURL(blob);
          return urlCreator.createObjectURL(blob);
      });
    }


    vm.loadDefaultNavItems = function () {
      var data = {
        client_key: 0
      };
      data.jurisdiction_key = 1;
      data.user_id = 0;
      vm.loading = true;
      return ClientAdminFactory.getClientSetupData(data).then(function (data) {
        if (data.callSuccess === "1") {
          //omiting is_seleted to avoid triggering watch on is_selected on init
          _defaultNavItems = _.map(data.jsonObject, function (item) {
            return _.omit(item, ["is_selected"]);
          });
          loadAllClients();
        } else {
          if (data.errorMessage === "no access") {
            AlertService.add(
                "error",
                "Sorry you do not have access to do the requested action.",
                4000
            );
          } else {
            AlertService.add(
                "error",
                "An error has occurred, If you continue to experience this issue please visit the help page to contact support.",
                4000
            );
          }
        }
      });
    };

    vm.loadDefaultNavItems();

    vm.openModal = function (modalObj, _data) {;
      // vm.envtype=_data.env_type? _data.env_type:_data.rowData
      _data.rowData['client_env'] ? $rootScope.db=_data.rowData['client_env']['env_type']   : '' ;
      vm.rowData = _data.env_type? _data.env_type:_data.rowData;
      vm.gridData = {}; // _data;

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: modalObj.template,
        controller: modalObj.controler,
        size: modalObj.size,
        backdrop: modalObj.backdrop,
        keyboard: modalObj.keyboard,
        resolve: {
          rowData: function () {
            return vm.rowData;
          },
          gridData: function () {
            return vm.gridData;
          },
          tempFolder: function () {
            return _data.temp_folder;
          },
          defaultNavItems: function () {
            return _defaultNavItems;
          }
        }
      });

      modalInstance.result.then(
          function (selectedItem) {
            $rootScope.$broadcast("gtw.admin.client.activityModalClose");
          },
          function () {
            $rootScope.$broadcast("gtw.admin.client.activityModalClose");
          }
      );
    };

    vm.addNewClient = function () {
      var data = {};
      ClientAdminFactory.getEnvironmentType().then(response=> {
        console.log(response.data);
        vm.envtype  = response.data;
        data.rowData = {
          client_key: "",
          client_env:response.data

        };
        data.temp_folder = vm.tempUploadFolder;
        var modalObj = {};
        modalObj.template = "app/components/admin/client/client-save-modal.html";
        modalObj.controler = "SaveClientController as ctrl";
        modalObj.size = "lg";
        modalObj.keyboard = false;
        modalObj.backdrop = "static";
        vm.openModal(modalObj, data);
      })
    };

    vm.push = function () {
      ClientAdminFactory.push().then(
          function (response) {
            if (response) {
              AlertService.add("success", "Push successfully.", 3000);
            }
          },
          function () {
            AlertService.add("error", "Problem with push.", 3000);
          }
      )
    };

    vm.editClient = function (client) {
      var data = {
        rowData: client,
        temp_folder: vm.tempUploadFolder
      };
      var modalObj = {};
      modalObj.template = "app/components/admin/client/client-save-modal.html";
      modalObj.controler = "SaveClientController as ctrl";
      modalObj.size = "lg";
      modalObj.keyboard = false;
      modalObj.backdrop = "static";
      vm.openModal(modalObj, data);
    };

    vm.removeClient = function (client_key) {
      ClientAdminFactory.deleteClient(client_key).then(
          function (data) {
            if (data.data.callSuccess === "1") {
              AlertService.add("success", "Client removed successfully.", 3000);
              $rootScope.$broadcast('gtw.nav.refreshclients');
              loadAllClients();
            } else {
              AlertService.add("error", data.data.errorMessage, 3000);
              return;
            }
          },
          function () {
            AlertService.add("error", "Problem removing client.", 3000);
          }
      );
    };

    vm.isLoggedInClient = function(client_key) {
      return angular.equals(client_key, vm.currentClient);
    };

    vm.toggleClientActive = function(client) {
      ClientAdminFactory.toggleClientActiveStatus(client.client_key, client.client_active).then(
        function (data) {
          if (data.data.callSuccess === "1") {
            AlertService.add("success", data.data.successMessage, 3000);
          } else {
            AlertService.add("error", "Problem toggling client active status.", 3000);
            return;
          }
        },
        function () {
          AlertService.add("error", "Problem toggling client active status.", 3000);
        }
      );
    };

    vm.refresh = function () {
      vm.isRefresh = true;
      loadAllClients();
    };

    $scope.$on("gtw.admin.client.loadClients", function (event, data) {
      loadAllClients();
    });
  }

  function clientWorkflowEditModalController(
      AlertService,
      ClientAdminFactory,
      $scope,
      node,
      compList,
      $uibModalInstance,
      $rootScope,
      USER_MENU,
      JsonObjectFactory,
      GENERAL_CONFIG,
      JdcManagerFactory,
      menuDataService
  ) {
    var vm = this;
    vm.name;
    vm.description;
    vm.isActive = node.is_active;
    vm.isSelected = node.is_selected.toString();
    var nodeToEdit = [];
    $scope.node = node;
    vm.compList = compList;
    vm.templatePath;
    vm.controllerPath;
    vm.selectedModalSize;
    vm.parentCssClass;
    vm.selectedActionType;
    $scope.level = node.nav_level;
    vm.title = "Edit ";
    vm.userMenu = USER_MENU;
    vm.filterDrillDown = vm.compList!=null? JSON.parse(JSON.stringify(eval("(" + (vm.compList[0].filter_json) + ")"))) :'';

    vm.editWorkflowForm;
    var nodeCopy = angular.copy(node);
    vm.filterJsonString = vm.filterDrillDown!=null?(vm.filterDrillDown) : '';
    vm.filterJson = vm.filterDrillDown!=null ? vm.filterDrillDown.screenFilters:'';
    vm.selectScreen=(vm.selectScreen== '' || vm.selectScreen == null) ? '':vm.selectScreen.screen_key;
    vm.selectActivity=(vm.selectActivity== '' || vm.selectActivity == null) ? '':vm.selectActivity.activity_key;
    vm.selecteChannel = (vm.selectChannel == ''|| vm.selectChannel == null) ? '': vm.selectChannel.channel_key


    vm.getChannel = function (channel_key) {
      console.log("USER_MENU", USER_MENU);
      if(channel_key){
        var x = _.cloneDeep(menuDataService.data);
        vm.Channels = x;
        vm.selectChannel = _.filter(vm.Channels, { id: channel_key })[0].id;
        var tasks = _.filter(vm.Channels, { id: channel_key })[0].children;

        vm.Channel = ClientAdminFactory.taskHierarchy(tasks)//mergeNonMatchedTaskHierachy(tasks)//_.filter(USER_MENU, { id: channel_key })[0].children;
        vm.selectTask = vm.filterDrillDown.task_key;
      }else{
        vm.Channels = vm.Channels
      }
    }
    vm.getActivites = function (task, activity_key) {
      vm.activities = [];
      _.filter(vm.Channel, function (item) {
          if (item.id == task) {
            vm.activities = _.filter(item.children,{type:"activity"});
          }
      })
      if (activity_key) {
          vm.selectActivity = activity_key;//$scope.componentSettings.drilldownFilterJson.activity_key
      }

  }

    vm.getScreens = function (activity, screen_key) {
        vm.screens = [];
        _.filter(vm.activities, function (item) {
            if (item.id == activity) {
              vm.screens = item.screens;
            }

        })
        if (vm.screens && vm.screens.length) {
          vm.selectScreen = _.find(vm.screens, { id: screen_key });
        }
    }
    if(  vm.compList && vm.compList[0] && vm.compList[0].selectedActionType == 'link' && vm.filterDrillDown){
        vm.getChannel(vm.filterDrillDown.channel_key);
        vm.getActivites(vm.filterDrillDown.task_key,vm.filterDrillDown.activity_key);
        vm.getScreens(vm.filterDrillDown.activity_key,vm.filterDrillDown.screen_key);
        vm.filterJsonString = JSON.stringify(vm.filterDrillDown);
    } else if(vm.filterDrillDown == null) {
       vm.getChannel();
    }
     // used to change and update filter key value based on index.
    vm.changeFilterObj1 = function(val, index) {
      var x = JSON.parse(vm.filterJsonString);
      x[index].filter_key = val;
      vm.filterJsonString = JSON.stringify(x);
    }

    // used to change and update filter name value based on index.
    vm.changeFilterObj2 = function(val, index) {
      var x = JSON.parse(vm.filterJsonString);
      x[index].name = val;
      vm.filterJsonString = JSON.stringify(x);
    }

    // used to change and update filter row data value based on index.
    vm.changeFilterObj3 = function(val, index) {
      var x = JSON.parse(vm.filterJsonString);
      x.screenFilters[index].row_data_key = val;
      vm.filterJsonString = JSON.stringify(x);
    }

    $scope.updateDrillDownJSON = function (index, value) {
      var filter = JSON.parse(vm.filterJsonString);
      _.each(filter.screenFilters, function (item, indexA) {
          if (index == indexA) {
              item.value = value;
              if (value == '' || value == undefined) {
                  delete item.value;
              }
          }
      });
      console.log("Adfasdf", filter)
      vm.filterJsonString = JSON.stringify(filter);
  }
      // Filter object creation
      vm.componentSettings = [];
      vm.filterJsonString={};
      vm.getFilters = function(selectedObj){
        vm.filterJson =[];

        if(selectedObj && selectedObj.filter_key!=null){
          vm.filterJsonString ="";
            var url = GENERAL_CONFIG.base_url + '/getFilters',
            params = {
                filter_group_key: selectedObj.filter_key
            };
            JsonObjectFactory.getJSONObj(url, params).then(function (data) {
                vm.componentSettings.drilldownFilterJson ={};
                if (data.callSuccess == '1') {
                    if(data.filterGroup && data.filterGroup.filters){
                        _.each(data.filterGroup.filters,function(item){
                            vm.filterJson.push({filter_key:item.filter_key,name:item.param_name,row_data_key:"",});

                        });
                        console.log("filter Json",vm.filterJson);
                        vm.componentSettings.drilldownFilterJson = JSON.stringify({
                              channel_key:vm.selectChannel,task_key:vm.selectTask,activity_key:vm.selectActivity,screen_key:selectedObj.id,filter_group_key:selectedObj.filter_key,
                              screenFilters:vm.filterJson
                        });
                        vm.filterJsonString = ( vm.componentSettings.drilldownFilterJson);
                  }
                }
            });
        }else{
          vm.filterJsonString = "";
        }

      //  vm.filterJsonString = ( vm.componentSettings.drilldownFilterJson);
        console.log("drilldownFilterJson",vm.componentSettings.drilldownFilterJson);
        console.log("vm.textareaObj", vm.textareaObj);
    }
      $scope.$watch('vm.filterJsonString', function (newValue, oldValue) {
        if (newValue !== undefined) {
            console.log(newValue);
            console.log("oldvlaue", oldValue);
            vm.filterJsonString = newValue;
        }else{
            vm.filterJsonString = JSON.stringify(vm.filterDrillDown);
        }
    });

    vm.onChangeChannelDropdown = function(selectedId) {
        var x = _.cloneDeep(menuDataService.data);
        var task = _.filter(x, { id: selectedId })[0].children;
      vm.Channel =ClientAdminFactory.taskHierarchy(task)//mergeNonMatchedTaskHierachy(task);
    }

    vm.isValid = true;
    vm.filterJsonString = JSON.stringify(vm.filterJsonString);
    vm.validJson = function() {
       vm.isValid = true;
            try {
                vm.filterJsonString = JSON.stringify(JSON.parse(vm.filterJsonString));
            } catch (e) {
                vm.isValid = false;
            }
        }
    var compListCopy = angular.copy(compList);
    vm.selectedActiveOptions = [{
      name: 'Yes',
      value: '1'
    },
      {
        name: 'No',
        value: '0'
      }
    ];

    getLevel();

    function getLevel() {
      if ($scope.level === 1) {
        vm.title += "Category";
      } else if ($scope.level === 2) {
        vm.title += "Step";
      }
    }

    vm.reset = function () {
      vm.disableAll = false;
      vm.name = node.name;
      vm.description = node.description;
      vm.isActive = node.is_active;
      vm.isSelected = node.is_selected.toString();
      vm.version_number = node.version_number;
      //Test the below code when more versions are added, reset the selected version radio btn
      for (var index = 0; index < vm.versionNumList.length; index++) {
        if (vm.versionNumList[index].is_active === 1) {
          vm.verNum = vm.versionNumList[index];
        }
      }
    }

    vm.onChangeActionType = function () {
      if (vm.selectedActionType === "link") {
        vm.templatePath = 'app/components/workflow/templates/entity/workflow-grid-tpl.html';
        vm.controllerPath = 'workflowGridLink as ctrl';
      } else {
        vm.templatePath = '';
        vm.controllerPath = '';
      }
    }

    $scope.closeModal = function () {
      vm.disableAll = false;
      vm.name = node.name;
      vm.description = node.description;
      vm.isActive = node.is_active;
      vm.isSelected = node.is_selected.toString();
      vm.version_number = node.version_number;
      $uibModalInstance.close();
    };

    vm.isMissing = function (obj) {
      return angular.isUndefined(obj) || obj == null || obj == "";
    };

    vm.isNumberMissing = function (obj) {
      return angular.isUndefined(obj) || obj == null;
    };


    vm.isNameMissing = function () {
      return vm.editWorkflowForm["name"].$dirty && vm.isMissing(vm.name);
    };


    // vm.isActiveMissing = function() {
    //   return vm.editWorkflowForm["active"].$dirty && vm.isNumberMissing(vm.isActive);
    // };

    $scope.save = function () {
      angular.forEach(["name"], function (frmElement) {
        vm.editWorkflowForm[frmElement].$dirty = true;
      });

      var isRequiredFieldsMissing =
          vm.isNameMissing();

      if (isRequiredFieldsMissing) {
        return;
      }

      var updInsType = 'I';
      if (vm.name === nodeCopy.name && vm.description === nodeCopy.description && vm.version_number === nodeCopy.version_number && vm.isSelected === nodeCopy.is_selected.toString() &&
          vm.isActive === nodeCopy.is_active && vm.templatePath === compListCopy[0].templatePath && vm.controllerPath === compListCopy[0].controllerPath &&
          vm.selectedModalSize === compListCopy[0].selectedModalSize && vm.parentCssClass === compListCopy[0].parentCssClass && vm.selectedActionType === compListCopy[0].selectedActionType
      ) {
        AlertService.add("warning", "No changes to update.", 3000);
        return;
      } else if (vm.disableAll || vm.isActive !== nodeCopy.is_active) {
        //        if (vm.isActive === '0') {
        //          vm.isSelected = '0';
        //        }
        var updInsType = 'U';
      } else {
        vm.existingVersion = vm.versionNumList.find(function (item) {
          return item.name === vm.name && item.description === vm.description && item.templatePath === vm.templatePath && item.controllerPath === vm.controllerPath &&
              item.selectedModalSize === vm.selectedModalSize && item.parentCssClass === vm.parentCssClass && item.selectedActionType === vm.selectedActionType
        })

        if (vm.existingVersion) {
          AlertService.add("warning", "There is an existing version.", 3000);
          return;
        }
      }
      if(!vm.isValid){
        AlertService.add("warning","Please Check The Filter JSON",4000);
        return
      }

      nodeToEdit.push(node);
      ClientAdminFactory.editWorkflow(
          vm.name,
          vm.description,
          vm.isActive,
          vm.isSelected,
          vm.version_number,
          vm.templatePath,
          vm.controllerPath,
          vm.selectedModalSize,
          vm.parentCssClass,
          vm.selectedActionType,
          updInsType,
          nodeToEdit,
          vm.selectActivity.activity_key,
          vm.selectScreen.screen_key,
          vm.filterJsonString
      ).then(function (response) {
        if (response.data.result.value > 0) {
          vm.disableAll = false;
          $scope.closeModal();
          if (node.client_key !== '0' && node.client_key !== '999') {
            $rootScope.$broadcast("gtw.admin.client.getWorkflowTree", {
              tax_year: node.tax_year,
              scenario: node.scenario,
              client_key: node.client_key
            });
          } else {
            $rootScope.$broadcast("gtw.admin.client.getOnlyDefaultWorkflowTree", {
              tax_year: node.tax_year,
              scenario: node.scenario,
              client_key: node.client_key
            });
          }
          AlertService.add(
              "success",
              response.data.result.message, 3000);
        } else {
          AlertService.add("error", response.data.result.message, 3000);
          return;
        }
      });
    };

    var wfscomp_key;
    var p_type;
    p_type = $scope.node.type;
    if (p_type == "component") {
      wfscomp_key = $scope.node.child_id.replace("$$", "");
    } else {
      wfscomp_key = $scope.node.child_id.replace("##", "");
    }

    ClientAdminFactory.getAllVersionNumbers(
        p_type,
        $scope.node.client_key,
        wfscomp_key,
        $scope.node.tax_year,
        $scope.node.scenario
    ).then(
        function (data) {
          if (data.data.versionNumList != "") {
            vm.versionNumList = data.data.versionNumList;
          } else {
            return;
          }
        },
        function () {
          AlertService.add("error", "Problem in getAllVersionNumbers().", 3000);
        }
    );

    $scope.changeVersion = function (verList) {
      vm.name = verList.name;
      vm.description = verList.description;
      vm.isActive = verList.is_Active;
      vm.version_number = verList.version_number;
      if (vm.isActive === nodeCopy.is_active) {
        vm.disableAll = false;
      } else {
        vm.disableAll = true;
      }
      if (p_type == "component") {
        vm.templatePath = verList.templatePath;
        vm.controllerPath = verList.controllerPath;
        vm.selectedModalSize = verList.selectedModalSize;
        vm.parentCssClass = verList.parentCssClass;
        vm.selectedActionType = verList.selectedActionType;
      }

      // nodeCopy = angular.copy(verList);
    };




  }

  function rolloverWorkflowEditModalController(
      AlertService,
      ClientAdminFactory,
      $scope,
      $uibModalInstance,
      $rootScope,
      client_key
  ){
    var vm = this;
    vm.title = "Roll Over ";

    vm.taxyearsMap = [];
    vm.scenariosByTaxyearArr = [];
    vm.workflowTypes = [];
    vm.domesticWorkflowTypes = [];
    vm.foriegnWorkflowTypes = [];
    vm.selectedTaxYearSrc;
    vm.selectedTaxYearDest;
    vm.filters = {};
    vm.filters.me = [];
    vm.filters.taxtype = [];
    vm.filters.scenario = [];
    vm.scenariosDropDown = [];
    vm.scenariosDropDownDest = [];
    vm.taxTypeDropDown = [];

    vm.filters.me_list = {};
    vm.filters.me_list.selected = [];
    vm.filters.me_list.name = "Scenario's"
    vm.filters.me_list.values = [];

    vm.filters.taxtype_list = {};
    vm.filters.taxtype_list.selected = [];
    vm.filters.taxtype_list.name = "Tax Type";
    vm.filters.taxtype_list.values = [];

    vm.filters.scenario_list = {};
    vm.filters.scenario_list.selected = [];
    vm.filters.scenario_list.name = "Scenario's"
    vm.filters.scenario_list.values = [];

    vm.compareObj1 = [];
    vm.compareObj2 = [];

    vm.taxYearChange = function(taxYear){
      vm.scenariosDropDown = [];
      for (var i = 0; i < vm.scenariosByTaxyearArr[taxYear].length; i++) {
        vm.scenariosDropDown.push({
          name : vm.scenariosByTaxyearArr[taxYear][i].scenario_desc,
          value : vm.scenariosByTaxyearArr[taxYear][i].scenario
        });
      }

      if(vm.scenariosDropDownDest.length!==0){
        vm.scenariosDropDownDest = [];
        for (var i = 0; i < vm.scenariosByTaxyearArr[vm.selectedTaxYearDest].length; i++) {
          vm.scenariosDropDownDest.push({
            name : vm.scenariosByTaxyearArr[vm.selectedTaxYearDest][i].scenario_desc,
            value : vm.scenariosByTaxyearArr[vm.selectedTaxYearDest][i].scenario
          });
        }

      }

      if(vm.scenariosDropDown.length>0 && vm.scenariosDropDownDest.length>0){
        _.remove(vm.scenariosDropDown,function(item){
          return !_.includes(vm.scenariosDropDownDest.map(function(item2){return item2.name}),item.name)
        });
      }
      if(vm.scenariosDropDown.length>0 && vm.scenariosDropDownDest.length>0){
        _.remove(vm.scenariosDropDownDest,function(item){
          return !_.includes(vm.scenariosDropDown.map(function(item2){return item2.name}),item.name)
        });
      }

      vm.filters.me_list.values = vm.scenariosDropDown;
      vm.filters.scenario_list.values = vm.scenariosDropDownDest;
    }

    vm.taxYearChangeDest = function(taxYear){
      vm.scenariosDropDownDest = [];
      for (var i = 0; i < vm.scenariosByTaxyearArr[taxYear].length; i++) {
        vm.scenariosDropDownDest.push({
          name : vm.scenariosByTaxyearArr[taxYear][i].scenario_desc,
          value : vm.scenariosByTaxyearArr[taxYear][i].scenario
        });
      }

      if(vm.scenariosDropDown.length!==0){
        vm.scenariosDropDown = [];
        for (var i = 0; i < vm.scenariosByTaxyearArr[vm.selectedTaxYearSrc].length; i++) {
          vm.scenariosDropDown.push({
            name : vm.scenariosByTaxyearArr[vm.selectedTaxYearSrc][i].scenario_desc,
            value : vm.scenariosByTaxyearArr[vm.selectedTaxYearSrc][i].scenario
          });
        }
      }

      if(vm.scenariosDropDown.length>0 && vm.scenariosDropDownDest.length>0){
        _.remove(vm.scenariosDropDown,function(item){
          return !_.includes(vm.scenariosDropDownDest.map(function(item2){return item2.name}),item.name)
        });
      }

      if(vm.scenariosDropDown.length>0 && vm.scenariosDropDownDest.length>0){
        _.remove(vm.scenariosDropDownDest,function(item){
          return !_.includes(vm.scenariosDropDown.map(function(item2){return item2.name}),item.name)
        });
      }

      vm.filters.me_list.values = vm.scenariosDropDown;
      vm.filters.scenario_list.values = vm.scenariosDropDownDest;

    }


    vm.clearFilters = function () {
      vm.filters.me = [];
      vm.filters.me_list.selected = [];
      vm.filters.taxtype = [];
      vm.filters.taxtype_list.selected = [];
      vm.filters.scenario = [];
      vm.filters.scenario_list.selected = [];

    };

    var loadWorkflowTypes = function () {
      ClientAdminFactory.getWorkflowTypes(client_key).then(function (data) {
        console.log("Workflow Type data ---> ", data);
        vm.workflowTypes = data.data.workflowType;
        vm.taxTypeDropDown = [];
        for (var i = 0; i < vm.workflowTypes.length; i++) {
          vm.taxTypeDropDown.push({
            name : vm.workflowTypes[i].name,
            value : vm.workflowTypes[i].wfst_Key
          });
        }
        vm.filters.taxtype_list.values = vm.taxTypeDropDown;
      });
    };

    var loadScenariosByTaxyear = function () {
      ClientAdminFactory.getTaxYearAndScenarioFilters(
          client_key
      ).then(function (data) {
        console.log("TaxYearAndScenarioFilters ---> ", data);
        vm.scenariosByTaxyearArr = data.data.ScenariosByTaxyearMap;
        vm.taxyearsMap = data.data.taxyearsMap;

      });
    };

    // Load Workflow type, Tax year and scenario
    loadWorkflowTypes();
    loadScenariosByTaxyear();


    $scope.compareTaxYears= function() {
      if(($scope.ctrl.selectedTaxYearSrc==undefined ||$scope.ctrl.selectedTaxYearSrc==null) || ($scope.ctrl.selectedTaxYearDest==undefined ||$scope.ctrl.selectedTaxYearDest==null)){
        return;
      }

      if($scope.ctrl.selectedTaxYearDest===$scope.ctrl.selectedTaxYearSrc){
        $scope.enableScenarioSingle=true;
        //$scope.MultienableWFTypeMultiTT=true;
        $scope.enableWFTypeMulti=false;
        $scope.MultienableWFTypeMultiSS=true;
        $scope.chkvisble=false;
        $scope.chkval=false;
      }
      else{
        $scope.enableScenarioSingle=false;
        $scope.chkvisble=true;
        $scope.chkval=true;
        //$scope.MultienableWFTypeMultiTT=false;
        $scope.MultienableWFTypeMultiSS=true;
        $scope.enableWFTypeMulti=true;
      }
    };

    $scope.CheckYears= function() {

      if($scope.chkval==true){

        $scope.enableScenarioSingle=false;
        $scope.chkvisble=true;
        $scope.chkval=true;
        //$scope.MultienableWFTypeMultiTT=false;
        $scope.MultienableWFTypeMultiSS=true;
        $scope.enableWFTypeMulti=true;
      }
      else{

        $scope.enableScenarioSingle=true;
        $scope.enableWFTypeMulti=false;
        //$scope.MultienableWFTypeMultiTT=true;
        $scope.MultienableWFTypeMultiSS=true;
        $scope.chkvisble=true;
        $scope.chkval=false;
      }
    };

    $scope.saveRollover = function(){

      var srcTaxYear;
      var destTaxYear;
      var srcScenario;
      var destScenario;
      var taxType;
      var ssMMScenario;


      srcScenario = $scope.ctrl.selectedScenarioSrc;
      destScenario = $scope.ctrl.selectedScenarioDest;




      if(!$scope.chkval){
        ssMMScenario = 'S';
        if($scope.ctrl.selectedScenarioSrc==undefined || $scope.ctrl.selectedScenarioSrc == null){
          AlertService.add("warning", "Please select source scenario's. ");
          return;
        }
        if($scope.ctrl.selectedScenarioDest==undefined || $scope.ctrl.selectedScenarioDest == null){
          AlertService.add("warning", "Please select destination scenario's. ");
          return;
        }

        if(($scope.ctrl.selectedTaxYearSrc===$scope.ctrl.selectedTaxYearDest)&& ($scope.ctrl.selectedScenarioSrc===$scope.ctrl.selectedScenarioDest)){
          AlertService.add("warning", "Scenarios can't be same. ");
          return;
        }
        srcScenario = $scope.ctrl.selectedScenarioSrc;
        destScenario = $scope.ctrl.selectedScenarioDest;

      }else{
        ssMMScenario = 'M';
        var scenarioList = vm.filters.me.join(',');
        var scenarioListDest = vm.filters.scenario.join(',');

        if(scenarioList==undefined || scenarioList === ""){
          AlertService.add("warning", "Please select source scenario's. ");
          return;
        }

        if(scenarioListDest==undefined || scenarioListDest === ""){
          AlertService.add("warning", "Please select destination scenario's. ");
          return;
        }

        if(vm.filters.me_list.selected.length !== vm.filters.scenario_list.selected.length){
          AlertService.add("warning", "Please make sure both source and destination scenario's are the same.");
          return;
        }

        vm.compareObj1 = [];
        vm.compareObj2 = [];
        for (var i = 0; i < vm.filters.me_list.selected.length; i++) {
          vm.compareObj1.push({
            name : vm.filters.me_list.selected[i].name
          });
        }
        for (var i = 0; i < vm.filters.scenario_list.selected.length; i++) {
          vm.compareObj2.push({
            name : vm.filters.scenario_list.selected[i].name
          });
        }

        if(_.intersectionWith(vm.compareObj1, vm.compareObj2, _.isEqual).length !== vm.filters.scenario_list.selected.length){
          AlertService.add("warning", "Please make sure both source and destination scenario's are the same.");
          return;
        }

//			if(scenarioList!==scenarioListDest){
//				AlertService.add("error", "Please make sure to Select both Source/Destination Scenario's as same.");
//			       return;
//			}


        srcScenario = scenarioList;
        destScenario = scenarioListDest;
      }

      var taxTypeList = vm.filters.taxtype.join(',');

      if(taxTypeList==undefined || taxTypeList===""){
        AlertService.add("warning", "Please select TaxType. ");
        return;
      }

      srcTaxYear = $scope.ctrl.selectedTaxYearSrc;
      destTaxYear = $scope.ctrl.selectedTaxYearDest;


      ClientAdminFactory.rolloverdefaultWorkflow(
          srcTaxYear,
          srcScenario,
          taxTypeList,
          destTaxYear,
          destScenario,
          ssMMScenario
      ).then(function (response) {
//			        vm.saving = false;
        if (response.data.result.value === "1") {
          AlertService.add("success", response.data.result.message, 3000);
        } else if (response.data.result.value === "2"){
          AlertService.add("warning", response.data.result.message, 3000);
        }else {
          AlertService.add("error", response.data.result.message, 3000);
          return;
        }
      });
    };

    vm.rolloverCloseModal = function() {
      $uibModalInstance.close();
      $uibModalInstance.dismiss("cancel");
    };

  }

  function clientWorkflowAddModalController(
      AlertService,
      ClientAdminFactory,
      $scope,
      defaultTreeData,
      clientTreeData,
      flag,
      origin,
      $uibModalInstance,
      $rootScope,
      USER_MENU,
      GENERAL_CONFIG,
      JsonObjectFactory,
      menuDataService
  ) {
    var vm = this;
    vm.name;
    vm.description;
    vm.priority;
    vm.templatePath;
    vm.controllerPath;
    vm.selectedModalSize;
    vm.parentCssClass;
    vm.selectedActionType;
    vm.selectedIsActive;
    vm.catLevel;
    vm.selectedCategory;
    vm.availableCategories = [];
    $scope.taxYear = defaultTreeData[0].tax_year;
    $scope.scenario = defaultTreeData[0].scenario;
    $scope.wfst_key = defaultTreeData[0].wfst_key;
    $scope.clientKey;
    $scope.enableCategoryInput = false;
    vm.title = "Add " + origin;
    vm.selectScreen=(vm.selectScreen== '' || vm.selectScreen == null) ? '':vm.selectScreen.screen_key;
    vm.selectActivity=(vm.selectActivity== '' || vm.selectActivity == null) ? '':vm.selectActivity.activity_key;
    vm.selecteChannel = (vm.selectChannel == ''|| vm.selectChannel == null) ? '': vm.selectChannel.channel_key
    vm.addWorkflowForm;
    vm.userMenu = USER_MENU;

    findProperClientKey();


    function findProperClientKey() {
      if (flag === "client") {
        $scope.clientKey = clientTreeData[0].client_key;
      } else {
        $scope.clientKey = defaultTreeData[0].client_key;
      }
    }
    vm.componentSettings = [];



   vm.onChangeChannelDropdown = function(selectedId) {
    var x = _.cloneDeep(menuDataService.data);
    var task = _.filter(x, { id: selectedId.id })[0].children;
    vm.Channel = ClientAdminFactory.taskHierarchy(task)//vm.mergeNonMatchedTaskHierachy(task);
    vm.task =     vm.Channel;
  }
    vm.filterJsonString={};
    vm.getFilters = function(selectedObj){
      vm.filterJson =[];

      if(selectedObj && selectedObj.filter_key!=null){
          vm.filterJsonString = "";
          var url = GENERAL_CONFIG.base_url + '/getFilters',
          params = {
              filter_group_key: selectedObj.filter_key
          };
          JsonObjectFactory.getJSONObj(url, params).then(function (data) {
              vm.componentSettings.drilldownFilterJson ={};
              if (data.callSuccess == '1') {
                  if(data.filterGroup && data.filterGroup.filters){
                      _.each(data.filterGroup.filters,function(item){
                          vm.filterJson.push({filter_key:item.filter_key,name:item.param_name,row_data_key:"",});

                      });
                  console.log("filter Json",vm.filterJson);
                  vm.componentSettings.drilldownFilterJson = JSON.stringify({
                      channel_key:vm.selectChannel.id,task_key:vm.selectTask.id,activity_key:vm.selectActivity.id,screen_key:selectedObj.id,filter_group_key:selectedObj.filter_key,
                      screenFilters:vm.filterJson
                  });
                 vm.filterJsonString = ( vm.componentSettings.drilldownFilterJson);
                  console.log("drilldownFilterJson",vm.componentSettings.drilldownFilterJson);
                  console.log("vm.textareaObj", vm.textareaObj);
              }
              }
          });
      }else{
          vm.filterJsonString = "";
      }
  }

    // used to change and update filter key value based on index.
    vm.changeFilterObj1 = function(val, index) {
      var x = JSON.parse(vm.filterJsonString);
      x[index].filter_key = val;
      vm.filterJsonString = JSON.stringify(x);
    }

    // used to change and update filter name value based on index.
    vm.changeFilterObj2 = function(val, index) {
      var x = JSON.parse(vm.filterJsonString);
      x[index].name = val;
      vm.filterJsonString = JSON.stringify(x);
    }

    // used to change and update filter row data value based on index.
    vm.changeFilterObj3 = function(val, index) {
      var x = JSON.parse(vm.filterJsonString);
      x.screenFilters[index].row_data_key = val;
      vm.filterJsonString = JSON.stringify(x);
    }

    $scope.updateDrillDownJSON = function (index, value) {
      var filter = JSON.parse(vm.filterJsonString);
      _.each(filter.screenFilters, function (item, indexA) {
          if (index == indexA) {
              item.value = value;
              if (value == '' || value == undefined) {
                  delete item.value;
              }
          }
      });
      console.log("Adfasdf", filter)
      vm.filterJsonString = JSON.stringify(filter);
  }
  vm.isValid = true;
  vm.filterJsonString = JSON.stringify(vm.filterJsonString);
  vm.validJson = function() {
     vm.isValid = true;
          try {
              vm.filterJsonString = JSON.stringify(JSON.parse(vm.filterJsonString));
          } catch (e) {
              vm.isValid = false;
          }
      }
    vm.onChangeActionType = function () {
      if (vm.selectedActionType === "link") {
        vm.templatePath = 'app/components/workflow/templates/entity/workflow-grid-tpl.html';
        vm.controllerPath = 'workflowGridLink as ctrl';
      } else {
        vm.templatePath = '';
        vm.controllerPath = '';
      }
    }

    $scope.closeModal = function () {
      resetModalValues();
      $uibModalInstance.close();
    };

    vm.isMissing = function (obj) {
      return angular.isUndefined(obj) || obj == null || obj == "";
    };

    vm.isNameMissing = function () {
      if (_.filter(clientTreeData, {
        name: vm.name
      }).length) {
        return true;
      }
      return vm.addWorkflowForm["name"].$dirty && vm.isMissing(vm.name);
    };

    vm.isSelectedIsActiveMissing = function () {
      return (
          vm.addWorkflowForm["selectedIsActive"].$dirty &&
          vm.isMissing(vm.selectedIsActive)
      );
    };

    // vm.isSelectedIsActionMissing = function(){
    //   return (
    //       vm.addWorkflowForm["selectedActionType"].$dirty &&
    //       vm.isMissing(vm.selectedActionType)
    //   );
    // }
    vm.isSelectedCategoryMissing = function () {
      if (origin === "Category") {
        return false;
      }
      return (
          vm.addWorkflowForm["selectedCategory"].$dirty &&
          vm.isMissing(vm.selectedCategory)
      );
    };

    vm.isPriorityMissing = function () {
      if (origin === "Category") {
        return false;
      }
      return vm.addWorkflowForm["priority"].$dirty && vm.isMissing(vm.priority);
    };

    // vm.isTemplatePathMissing = function () {
    //   if (origin === "Category") {
    //     return false;
    //   }
    //   return (
    //       vm.addWorkflowForm["templatePath"].$dirty &&
    //       vm.isMissing(vm.templatePath)
    //   );
    // };

    // vm.isControllerPathMissing = function () {
    //   if (origin === "Category") {
    //     return false;
    //   }
    //   return (
    //       vm.addWorkflowForm["controllerPath"].$dirty &&
    //       vm.isMissing(vm.controllerPath)
    //   );
    // };

    // vm.isSelectedModalSizeMissing = function () {
    //   if (origin === "Category") {
    //     return false;
    //   }
    //   return (
    //       vm.addWorkflowForm["selectedModalSize"].$dirty &&
    //       vm.isMissing(vm.selectedModalSize)
    //   );
    // };

    $scope.save = function () {

      if (origin === "Category") {
        angular.forEach(["name", "selectedIsActive"], function (frmElement) {
          vm.addWorkflowForm[frmElement].$dirty = true;
        });
      } else {
        angular.forEach(
            [
              "name",
              "selectedIsActive",
              "selectedCategory",
              "priority",
             /*  "templatePath",
              "controllerPath",
              "selectedModalSize", */
            ],
            function (frmElement) {
              vm.addWorkflowForm[frmElement].$dirty = true;
            }
        );
      }

      var isRequiredFieldsMissing =
          vm.isNameMissing() ||
          vm.isSelectedIsActiveMissing() ||
          vm.isSelectedCategoryMissing() ||
          vm.isPriorityMissing()
         /*  vm.isTemplatePathMissing() ||
          vm.isControllerPathMissing() ||
          vm.isSelectedModalSizeMissing() || */
          // ||vm.isSelectedIsActionMissing();

      if (isRequiredFieldsMissing) {
        return;
      }

      if(!vm.isValid){
        AlertService.add("warning","Please Check The Filter JSON",4000);
        return
      }

      ClientAdminFactory.addWorkflow(
          $scope.clientKey,
          vm.name,
          vm.description,
          vm.selectedCategory,
          vm.priority,
          vm.templatePath,
          vm.controllerPath,
          vm.selectedModalSize,
          vm.parentCssClass,
          vm.selectedActionType,
          vm.selectedIsActive,
          vm.selectScreen.activity_key,
          vm.selectScreen.screen_key,
          vm.filterJsonString,
          $scope.wfst_key,
          $scope.taxYear,
          $scope.scenario

      ).then(function (response) {
        if (response.data.result.value > 0) {
          $scope.closeModal();
          if ($scope.clientKey !== '0' && $scope.clientKey !=='999') {
            $rootScope.$broadcast("gtw.admin.client.getWorkflowTree", {
              tax_year: $scope.taxYear,
              scenario: $scope.scenario,
              client_key: $scope.clientKey
            });
          } else {
            $rootScope.$broadcast("gtw.admin.client.getOnlyDefaultWorkflowTree", {
              tax_year: $scope.taxYear,
              scenario: $scope.scenario,
              client_key: $scope.clientKey
            });
          }
          AlertService.add(
              "success",
              response.data.result.message, 3000);
        } else {
          AlertService.add("error", response.data.result.message, 3000);
          return;
        }
      });
    };

    /* ADD CATEGORY LOGIC BELOW */

    // On page load, populate the available categories for the add step feature
    loadCategoryItems();

    function loadCategoryItems() {
      vm.availableCategories = [];
      for (var index in clientTreeData) {
        var element = clientTreeData[index];
        if (element.is_selected !== 0 && element.nav_level === "1") {
          vm.availableCategories.push(element);
        }
      }
    }

    function resetModalValues() {
      vm.name = null;
      vm.selectedCategory = [];
    }
  }

  function clientWorkFlowSetupModalController(
      AlertService,
      $parse,
      $filter,
      $uibModalInstance,
      workflowSteps,
      workflowSetup,
      workflowModal,
      GENERAL_CONFIG,
      USER_MENU
  ) {
    var vm = this;
    vm.headers = workflowSteps;
    vm.title = "Workflow Setup";
    vm.workflow = {};

    vm.options = [{
      key: "Header",
      val: "Header"
    },
      {
        key: "Workflow Item",
        val: "Workflow"
      }
    ];

    vm.getActivityList = function(){
      let activityList;
      activityList = _.find(USER_MENU);
    }

    vm.getActivityList();





    vm.changeParent = function (parent) {
      vm.workFlowSteps = [];
      var children = $parse("workflow.parent.children")(vm.workflow);
      if (children && children.length) {
        vm.workFlowSteps = $filter("filter")(children, {
          type: vm.workflow.workflow.type
        });
      }
    };

    vm.editParent = function (parent) {
      vm.editParentIndex = -1;
      if (parent) {
        vm.editParentObj = angular.copy(parent);
        vm.editParentIndex = vm.headers.indexOf(parent);
      } else {
        vm.editParentObj = {
          name: ""
        };
      }
    };

    vm.saveNameEdit = function () {
      if (vm.editParentIndex === -1) {
        vm.editParentIndex = vm.headers.length;
      }
      vm.headers[vm.editParentIndex] = angular.copy(vm.editParentObj);
      vm.cancelNameEdit();
      $parse("workflow.parent").assign(
          vm.workflow,
          vm.headers[vm.editParentIndex]
      );
      vm.changeParent();
    };

    vm.cancelNameEdit = function () {
      delete vm.editParentObj;
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    vm.saveHeader = function () {
      if (vm.workflow.header != undefined) {
        delete vm.workflow.workflow;
        var header = angular.merge(vm.workflow, workflowSetup);
        // localStorage.setItem('workflow.header', JSON.stringify(header));
        AlertService.add("success", "Header saved Successfully.", 4000);
        $uibModalInstance.close(header);
      }
    };

    vm.saveWorkFlow = function () {
      if (vm.workflow.workflow != undefined) {
        delete vm.workflow.header;
        var workflow = angular.merge(vm.workflow, workflowSetup);
        // localStorage.setItem('workflow.workflow', JSON.stringify(workflow));
        AlertService.add("success", "Workflow saved Successfully.", 4000);
        $uibModalInstance.close(workflow);
      }
    };

    if (workflowModal) {
      vm.title = "Edit Workflow Setup";
      vm.workflow = workflowModal;
      vm.changeParent();
    }
  }

  return controllers;
});
