define([
    'angular'

], function () {
    'use strict';

    var services = angular.module('app.creditDetailsService', [])

        .factory('creditDetailsFactory', ['$q', '$http', '$timeout', '$rootScope', '$log', 'DataFiltersFactory', 'workspaceFactory', 'JsonObjectFactory', 'USER_SETTINGS', 'AlertService', 'GlobalService', 'GENERAL_CONFIG',
            function creditDetailsFactory($q, $http, $timeout, $rootScope, $log, DataFiltersFactory, workspaceFactory, JsonObjectFactory, USER_SETTINGS, AlertService, GlobalService, GENERAL_CONFIG) {
                var factory = {};
                factory.formConfigData = {};
                var URLS = {
                    GET_MODAL_DATA: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=207vw4",
                    SAVE_MODAL: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=t652fd",
                };
                var userSettings = USER_SETTINGS;
                var logged_in_user = userSettings.user.sso_id;

                factory.getModalData = function () {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params.sso_id = logged_in_user;
                    params.screen_key = GlobalService.globalParams.screen_key;
                    params.jcd_key = 250;
                    var promise = $http({
                        method: "get",
                        url: URLS.GET_MODAL_DATA,
                        params: params

                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });
                    return promise;
                }

                factory.saveModal = function(final_data, clob_settings){
                    var clob_setting = JSON.stringify(clob_settings);
                    var clob_data = JSON.stringify(final_data);
                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({clob_data:clob_data}, params);
                    params =  _.extend({clob_setting:clob_setting}, params);
                    params.process_name =  "credits-adjustment-save";
                    var promise = $http({
                        method: "post",
                        url: URLS.SAVE_MODAL,
                        data: params
                    }).then(function (response) {
                        var status = response.status;
                        return response;
                    });
                    return promise;
                }

                return factory;

            }]);

    return services;

});
