define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.SystemLockUnlockService', [])
                            .factory("SystemLockUnlockFactory", ['$http','GENERAL_CONFIG', 'GlobalService', '$q', '$timeout', function ($http, GENERAL_CONFIG, GlobalService, $q, $timeout) {

                                const systemLockUnlockFactory = {
                                    isLocked: undefined
                                };

                                systemLockUnlockFactory.getSystemLockUnlockStatus = function () {
                                    return $http.get(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=32639`);
                                }

                                systemLockUnlockFactory.getDSTSystemLockUnlockStatus = function () {
                                    const defer = $q.defer();

                                    if (systemLockUnlockFactory.isLocked === undefined) {
                                        systemLockUnlockFactory.getSystemLockUnlockStatus().then(
                                            (response) => {
                                                if (response.data && response.data.callSuccess === '1') {
                                                    systemLockUnlockFactory.isLocked = response.data.jsonObject[0].SYSTEMLOCK;
                                                    defer.resolve();
                                                } else {
                                                    throw new Error(response.data.errorMessage);
                                                }
                                            })
                                            .catch((err) => {
                                                console.error("Something went wrong while getting DST System Lock status, Error: " + err.toString());
                                            }
                                        );
                                    } else {
                                        $timeout(() => defer.resolve());
                                    }
                                    return defer.promise;
                                }

                                systemLockUnlockFactory.saveSystemLockUnlock = function (isLocked) {
                                    var params = {
                                        'isActive': isLocked,
                                        'tax_year':	GlobalService.globalParams.tax_year,
                                        'scenario': GlobalService.globalParams.scenario,
                                        'jcd_key': GlobalService.globalParams.jcd_key,
                                    };
                        
                                    var promise = $http({
                                        method: "post",
                                        url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32640",
                                        data: params
                                    }).then(function (response) {
                                        return response.data;
                                    });
                                    return promise;
                                }

                                return systemLockUnlockFactory;
                            }]);

    return services;
});
