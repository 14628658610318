define([
    'angular',
    './sltDataServiceDashboardCtrl',
    './sltDataServiceDashboardService'
], function () {
    'use strict';
    return angular.module('app.sltDataServiceDashboard', ['app.sltDataserviceDashboardCtrl','app.sltDataServiceDashboardService'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('slt-api-dashboard', {
                url: '/slt-api-dashboard',
                templateUrl: 'app/components/sltApiDashboard/slt-dataservice-dashboard.html',
                data:{},
                access:"",
                controller: 'sltDataserviceDashboardCtrl as ctrl',
            })
    }])
});