define([
    'angular'

], function () {
    'use strict';
    var controllers = angular.module('app.dashboard901MController', [])
        .controller('mdashboardCtrl', ['$scope', '$rootScope', '$state', '$http', 'ModalFactory', 'AlertService',
            'JsonObjectFactory', '$timeout', '$uibModalInstance', 'rowData', 'colData', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', '$injector', '$uibModal','Dashboard901Factory','$window',
            'SERVER_CONFIG','FileAttachementFactory','MessageFactory','GENERAL_CONFIG','USER_SETTINGS','gridData',mdashboardCtrl
        ])
        .controller('adjustmentScreenCtrl', ['$scope', '$rootScope', '$state','$stateParams', '$http', 'ModalFactory', 'AlertService',
            'JsonObjectFactory', '$timeout', '$uibModalInstance', 'rowData', 'colData', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', '$injector', '$uibModal','Dashboard901Factory','$window',
            'SERVER_CONFIG','FileAttachementFactory','MessageFactory','GENERAL_CONFIG','USER_SETTINGS','gridData','CodeComboFactory', adjustmentScreenCtrl
        ]);

    var URLS = {
            saveAttachemnt: '/saveAttachmentDocuments',
            uploadCommentAttachements: '/uploadCommentAttachements'
    }

    function mdashboardCtrl($scope, $rootScope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, $timeout, $uibModalInstance, rowData, colData, GlobalService,
        DataFiltersFactory, workspaceFactory, $injector, $uibModal,Dashboard901Factory,$window,SERVER_CONFIG,FileAttachementFactory,MessageFactory,GENERAL_CONFIG,USER_SETTINGS,gridData) {

        var vm = this;
        vm.rowData = rowData;
        vm.colData = colData;
        vm.title = '901(M) Calculation Reason';
        vm.AdjTitle = 'Adjustment Screen';
        vm.COMMENTS ='';
        //vm.Review = '';
        vm.OLD_COMMENTS=vm.COMMENTS;
        vm.allComments = []	
        vm.adjustmentArray = [];
        vm.adjustmentParentArray = [];
        vm.adjustmentChildArray = [];
        vm.CONVERTED_BASIS_AMT = vm.rowData.CONVERTED_BASIS_AMT;
        vm.$window=$window;
        vm.SERVER_CONFIG=SERVER_CONFIG;
        vm.GENERAL_CONFIG=GENERAL_CONFIG;

        //file remove
        vm.MessageFactory=MessageFactory;
        $scope.upload_url = GENERAL_CONFIG.base_url + '/uploadCommentAttachements';

        _.each(_.keys(FileAttachementFactory.fileUploadConfig),function(item){
            if(!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
                  vm[item]=_.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
            else vm[item]=FileAttachementFactory.fileUploadConfig[item];
        });
        
        var URLS = {
            saveAttachemnt: '/saveAttachmentDocuments',
            uploadCommentAttachements: '/uploadCommentAttachements'
        }

        function initFg() {

            console.log(vm.rowData);
            console.log(vm.colData);
            //get901Adjustments();
           if(gridData.colData.label === "901(m) Calculation Required"){
        	    getAllComments(vm.rowData);
            }
        };
        
        initFg();

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('gtw.dashboard901.assignModalClose');
        };
        
        function getAllComments(){
        	var params = {
        			"action_code": 'lb5ove',
        			tax_year:	vm.rowData.TAX_YEAR,
        			scenario: 	GlobalService.globalParams.scenario,
        			jcd_key: 	GlobalService.globalParams.jcd_key,
                    //combination_key:vm.rowData.COMBINATION_KEY,
                    combination_key:vm.rowData.LE_KEY,
        			sso_id:USER_SETTINGS.user.sso_id
			}
        	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
                console.log(data.jsonObject, "all comments");
                vm.allComments = data.jsonObject;
                vm.Review = vm.allComments[0].REVIEW_YN;
            });
        }
        
       

        /* vm.saveAttachment = function () {
            var objContext = {
                  parent_key: vm.parent_key,
                  parent_type: '901M_DB_ADJS',
                  attachments: vm.attachmentTO.attachmented_files.toString(),
                  oldFiles: _.map(vm.attachmentList,"file_name").toString()
            };
            Dashboard901Factory.saveAttachment(GENERAL_CONFIG.base_url+URLS.saveAttachemnt, objContext).then(function (data) {
                  console.log(data);
                  vm.crudLoading = false;
               //   if (data.success && data.unreadMessageCount === "success") { 
                if (data.unreadMessageCount === "success") { 
                    console.log("attachem ++++ data",data);
                    $uibModalInstance.dismiss('cancel');
                    AlertService.add("success", "attachment saved Successfully.", 4000);
                  } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                  }
            });
        }
 */
        vm.save = function () {
            
        	 if(vm.COMMENTS == undefined || vm.COMMENTS == null || vm.COMMENTS == ''){
					AlertService.add("error", "Please enter a valid comments",4000);
					return;
				}
	        	
	        	if(vm.COMMENTS.length > 2000){
					AlertService.add("error", "Please comments entered cannot be more than 2000.",4000);
					return;
				}
	        	
            console.log('vm', vm);
            if(workspaceFactory.activeScreen.screen_key==139){
                Dashboard901Factory.save901Adjustment(GENERAL_CONFIG.base_url,null,vm);
                return;
            }
            /*console.log('COMMENTS:::::', vm.COMMENTS);
            if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }*/
            vm.loading = true;
            
            var json_arrOBJ = [];
            json_arrOBJ = [{
                LE_KEY: vm.rowData.LE_KEY,
            	COMBINATION_KEY:vm.rowData.COMBINATION_KEY,
                IS_901M_CALC_REQD: vm.Review,
                COMMENTS: vm.COMMENTS,
            }];
            var jsonSettings = [{
                tax_year: vm.rowData.TAX_YEAR,
                TRANS_HEADER_KEY: vm.rowData.TRANS_HEADER_KEY
            }];
            var service = $injector.get('JsonObjectFactory');
            var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=k9hid6";
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);
            params = _.extend({
                jsonSettings: JSON.stringify(jsonSettings)
            }, params);
            params = _.extend({
                jsonObj: JSON.stringify(json_arrOBJ)
            }, params);
            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("message ++++ data",data);
                    AlertService.add("success", "Data saved Successfully.", 4000);
                    var args = {COMBINATION_KEYS: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEYS:vm.rowData.COMBINATION_KEY}};                     
                    $rootScope.$emit('gridUpdate', args);
                } else if (data.errorMessage && data.errorMessage != ""){
                    AlertService.add("error", data.errorMessage, 4000);
                } else {
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                }
                vm.loading = false;
                var args = {COMBINATION_KEYS: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEYS:vm.rowData.COMBINATION_KEY}};                     
                $rootScope.$emit('gridUpdate', args);
                
                $uibModalInstance.dismiss('cancel');
            });
        }
        vm.attachmentList=[];
        function get901Adjustments() {
            vm.attachmentList=[];
            vm.parent_key= vm.rowData.COMBINATION_KEY?vm.rowData.COMBINATION_KEY:vm.rowData.HO_COMBINATION_KEY;
            /* Dashboard901Factory.loadAttachments(GENERAL_CONFIG.base_url,vm.parent_key,'901M_DB_ADJS').then(function(res){
                console.log(res);
                vm.attachmentTO.attachmented_files=[];
             //   vm.attachmentTO.stored_files=[];
                   //vm.attachmentTO.attachmented_files = attachmentList;
                vm.attachmentList=res.comment.attachmentList;
                if (res.comment.attachmentList){
                    for (var i = 0; i < res.comment.attachmentList.length; i++) {
                            var attachment=res.comment.attachmentList[i];
                            vm.attachmentTO.attachmented_files.push(attachment.file_name.replace(res.comment.comment_key+"//",''));
                            //vm.attachmentTO.stored_files.push(attachment.file_name.replace(data.comment.comment_key+"//",''));
                    }
                    vm.attachmentTO.attachmented_files=_.uniq(vm.attachmentTO.attachmented_files);
                    vm.attachmentTO.stored_files=_.cloneDeep(vm.attachmentTO.attachmented_files);
                }
            },function(err){

            }); */
          
        };

      }


    function adjustmentScreenCtrl($scope, $rootScope, $state,$stateParams, $http, ModalFactory, AlertService, JsonObjectFactory, $timeout, $uibModalInstance, rowData, colData, GlobalService,
            DataFiltersFactory, workspaceFactory, $injector, $uibModal,Dashboard901Factory,$window,SERVER_CONFIG,FileAttachementFactory,MessageFactory,GENERAL_CONFIG,USER_SETTINGS,gridData,CodeComboFactory) {

        var vm = this;
        //vm.rowData = [];
        vm.gridData = [];
        vm.rowData = rowData;
        vm.colData = colData;
        vm.title = 'Adjustment Screen';
        vm.COMMENTS ='';
        vm.OLD_COMMENTS=vm.COMMENTS;
        vm.allComments = []	
        vm.GENERAL_CONFIG=GENERAL_CONFIG;
        $scope.upload_url = GENERAL_CONFIG.base_url + URLS.uploadCommentAttachements;
        
       
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('gtw.dashboard901.assignModalClose');
        };
        
        function getAllComments(){
        	var params = {
        			"action_code": '3k9awv',
        			tax_year:	vm.rowData.TAX_YEAR,
        			scenario: 	GlobalService.globalParams.scenario,
        			jcd_key: 	GlobalService.globalParams.jcd_key,
        			combination_key:vm.rowData.COMBINATION_KEY,
        			sso_id:USER_SETTINGS.user.sso_id
			}
        	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
                console.log(data.jsonObject, "all comments");
                vm.allComments = data.jsonObject;
            });
        }
        
        if(gridData.colData.label === "Comments"){
        	getAllComments(vm.rowData);
        }
        
        vm.save = function () {
            
       	 if(vm.COMMENTS == undefined || vm.COMMENTS == null || vm.COMMENTS == ''){
					AlertService.add("error", "Please enter a valid comments",4000);
					return;
				}
	        	
	        	if(vm.COMMENTS.length > 2000){
					AlertService.add("error", "Please comments entered cannot be more than 2000.",4000);
					return;
				}
	        	
           console.log('vm', vm);
           if(workspaceFactory.activeScreen.screen_key==139){
               Dashboard901Factory.save901Adjustment(GENERAL_CONFIG.base_url,vm);
               return;
           }
           
        };

        vm.post901Adjs = function() {
          /*   alert("working");
            console.log('rowData', vm.rowData);
            console.log('colData', vm.colData); */
            if(workspaceFactory.activeScreen.screen_key==139){
                Dashboard901Factory.save901Adjustment(GENERAL_CONFIG.base_url,vm);
                return;
            }
        }

        function init(){
            console.log("in adj controller");
            console.log($state);
            console.log($stateParams);
            if (vm.rowData.COMBINATION_KEY) {

                CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                CodeComboFactory.showFormBtn = false;
                
            } else {

                console.log("nnnnnnnnnnnnnnnnn $scope.rowData.COMBINATION_KEY NOT FOUND ", vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = true;
                alert("yyyyyyyy $scope.rowData.COMBINATION_KEY not FOUND");
            }
        }

        init();

    }
    return controllers;

});