define(
    [ 'angular',
        './pshipPtrDetailsService',
    ],
    function() {
        'use strict';     
       



        var controllers = angular
                .module('app.pshipPtrDetailsCtrl', ['app.pshipPtrDetailsService'])
               .directive('allowPositiveDecimals', function () {

return {
    restrict: 'A',
    link: function ($scope, element, attrs, ngModel) {

        element.bind('keydown', function (e, inputs) {

            var code = e.keyCode || e.which;
            var val = element[0].value;

            var current = document.activeElement;
            	/*if (code === 13) {
                	e.preventDefault();
                }*/
                
                // var charCode = (evt.which) ? evt.which : event.keyCode
			  if (code > 31 && code != 190 && (code < 48 || code > 57))
			   e.preventDefault();
        })
    }
  }
})
                .controller('pshipPtrDetailsCtrl',
                        [ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService','workspaceFactory',
                                '$timeout', 
                                '$uibModal','$uibModalInstance','rowData','gridData', 
                                'GENERAL_CONFIG','PshipPtrDetailsFactory', '$http', 'USER_SETTINGS','$filter', pshipPtrDetailsCtrl ])

        function pshipPtrDetailsCtrl($rootScope, $scope, $state, $log,
                JsonObjectFactory, AlertService, GlobalService, workspaceFactory, $timeout,
                $uibModal, $uibModalInstance,  rowData, gridData,
                GENERAL_CONFIG, PshipPtrDetailsFactory,$http,USER_SETTINGS, $filter) {

            var vm = this;	
            //Show the loading Spinner
            vm.loadingData = true;		
            vm.userSettings = USER_SETTINGS;
            vm.logged_in_user = vm.userSettings.user.sso_id;
            //Selected Row on the Grid passed to Modal/Popup to display in top table
            vm.selectedGridRow = [];
            vm.rowData = rowData;
            vm.pshipData = [];
            vm.sum_updated_ratio = 0;

            //A flag to enable and disable save button
            vm.isSaveClicked = false;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};	
            //Get the Grid filter Scenario
            vm.scenario = filterParams.scenario;  	
            //Get the jcd_key from left nav
            vm.jcd_key  = GlobalService.globalParams.jcd_key;
            vm.filteredTaxYearData = [];
            vm.selectedTaxYear = GlobalService.globalParams.tax_year;
            
            //cancel the pop-up
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
            
            $scope.$watch(function(){ return workspaceFactory.screens}, function(val){
	            vm.workspaces = workspaceFactory.getScreen();
	            $timeout(function() {
	                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
	                    vm.scrlTabsApi.init();
	                }
	            });
	        });
            vm.tabs = [];
	        vm.updateFn = function(arg){
	            var obj = [];
	            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
	            vm.setTab(obj);
	        }
	        
	        vm.getSumByKey = function(arr, key) {
	        	let sum = 0;
	        	arr.forEach(element => {
	        		if (element[key]) {
	        			sum += element[key];
	        		}
	        	});
	        	return sum.toFixed(2);
	        	//return arr.reduce((accumulator, current) => current[key] ? accumulator + Number(current[key]) : 0)
	        }
	        	
	        vm.calTotal = function() {
	        	vm.sum_updated_ratio = vm.getSumByKey(vm.pshipData, 'UPDATED_RATIO');
	        }
	                  
            //To get the Lock_Y_N flag from backend
	        vm.getActualData = function() {
	        	 var lockparams = {
	                     'action_code': 'xk8v7g',
	                     'p_ho_combination_key':vm.rowData.PSHIP_HO_COMBINATION_KEY,
	                     'p_pship_combination_key':vm.rowData.PTR_COMBINATION_KEY,
	                     'p_ptrcombination_key':vm.rowData.PTR_COMBINATION_KEY                                
	                     
	                 };				
	             JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
	                console.log(data,data.jsonObject);
	                vm.pshipData = data.jsonObject;
	                vm.pshipData.forEach(element => {	                	
	 	        		if (element.apply || element.IS_CHECKED == 'Y') {
	 	        			element['applyValue'] = true;
	 	        		} else {
	 	        			element['applyValue'] = false;
	 	        		}
	 	        	});
	                vm.calTotal();	                
	             });
	        }
           
	        vm.getActualData();
	        
	        vm.reset = function() {
	        	vm.getActualData();	        	
	        }
            
          //Get the Parent ME Drop-Down
			/*vm.getParentMeDropdown = function() {	
				if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
					AlertService.add("error", "Please select the Tax Year to run the Apportionment.",4000);
					return;
				}
				vm.parentMEDropDown = [];
				vm.selectedParentMes = '';
				
				//var params = {"action_code" : 'vxg30f', "sso_id" : vm.logged_in_user, "tax_year" : vm.selectedTaxYear };
				var params = {"action_code" : '07roqs', "sso_id" : vm.logged_in_user, "p_tax_year" : vm.selectedTaxYear, "p_scenario" : vm.selectedScenario };
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.parentMEDropDown =  $filter('filter')(data.jsonObject, function(data) {
		                       					return (data.LEVEL_GROUP === 'Parent ME');
		                   						});	
						vm.loadParentMEDropdown = true;
						vm.loading = false;
				});
			}
			// vm.getParentMeDropdown();	
			
			//Get the Tax Year Dropdown
			vm.getTaxYearDropDown = function() {	
				vm.selectedTaxYear = '';
				
				var params = {"action_code" : 'e83e1x',};					
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
					vm.taxYearData = _.filter(data.jsonObject, function(p){
                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
                	});							
					for(var indx in vm.taxYearData){
						if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
								&& (vm.taxYearData[indx].CODE_DISP_DESC <= (GlobalService.globalParams.tax_year) && vm.taxYearData[indx].CODE_DISP_DESC >= (GlobalService.globalParams.tax_year-5))){
							vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
						}
					}						
				});					
			};
			vm.getTaxYearDropDown();
			
			vm.getScenarioDropDown = function() {	
				if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
					AlertService.add("error", "Please select the Tax Year to get the Filing Group.",4000);
					return;
				}
		
				var params = {"action_code" : 'va45va',"p_tax_year" : vm.selectedTaxYear, 'p_jcd_key' :vm.jcd_key};	
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
					vm.scenarioDropDown =  data.jsonObject;							
					vm.scenarioRAF = _.filter(vm.scenarioDropDown, function(p){
                		  return _.includes(['Return As Filed'], p.LABEL);  
					});	
					vm.selectedScenario = (vm.scenarioRAF!=undefined && vm.scenarioRAF.length>0) ? vm.scenarioRAF[0].VALUE : undefined;		
					
					vm.getParentMeDropdown();
					vm.getFromDropdown();
					
				});					
			};
			
			vm.getFromDropdown = function() {	
				vm.selectedForm = '';
				
				var params = {"action_code" : 'abkcdl',};					
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
					vm.getForm = data.jsonObject;
					_.remove(vm.getForm, {PRIORITY_ORDER: 1}); 
										
				});	
			};
			
			vm.getPshipDropdown = function() {	
				vm.selectedPship = '';
				vm.showPship = false;
				
				var params = {"action_code" : 'kae9bk',"tax_year" : vm.selectedTaxYear, 'scenario' : vm.selectedScenario,'jcd_key' :vm.jcd_key,'p_form':vm.selectedForm,
							  'p_schedule': 'K2', 'parent_me_string' : vm.selectedParentMes};					
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
					vm.getPshipDtls = data.jsonObject;
					_.remove(vm.getPshipDtls, {VALUE: -1});
					vm.showPship = true;
										
				});	
				
			};*/
            

            /* //When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
             if (gridData != null) {					
                var tempGridData = _.clone(gridData), groups = Object
                        .create(null), result, finalKeyCheck = 0;					
                
                vm.gridDataMultipleSelect = _.clone(tempGridData);					
                
                if (vm.gridDataMultipleSelect != undefined) {						
                    vm.selectedGridRow.push( _.clone(vm.gridDataMultipleSelect.rowData));
                    vm.rowData = vm.selectedGridRow[0];
                    getAllComments();	
                }
            }

            //---------Comments

             //Get all the comments saved for this specific row
            function getAllComments(){
                var params = {
                        "action_code": '8n9er0',
                        screen_key: GlobalService.globalParams.screen_key,
                        object_id:	vm.rowData.OBJECT_ID,
                        tax_year:	filterParams.tax_year,
                        scenario: 	filterParams.scenario,
                        jcd_key: 	GlobalService.globalParams.jcd_key
                }
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
                    console.log(data.jsonObject, "all comments");
                    vm.allComments = data.jsonObject;
                });
            }
            
            // if(gridData.colData.label === "Comments"){
            //     getAllComments(vm.rowData);
            // }
            */
            
            /*vm.allowOnlyNumber = function(evt) {
			  var charCode = (evt.which) ? evt.which : event.keyCode
			  if (charCode > 31 && charCode != 46 && (charCode < 48 || charCode > 57))
			    return false;
			  return true;
			}*/
            
            vm.save = function(){
                /*if(vm.COMMENTS == undefined || vm.COMMENTS == null || vm.COMMENTS == ''){
                    AlertService.add("error", "Please enter a valid comments",4000);
                    return;
                }
                
                if(vm.COMMENTS.length > 4000){
                    AlertService.add("error", "Please comments entered cannot be more than 4000.",4000);
                    return;
                }*/
                
            	
                var returnClobSettingsObj = {},message ="Comment saved successfully",otherInfo = {};
                returnClobSettingsObj['sso_id']  = vm.logged_in_user;
                returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
                returnClobSettingsObj['scenario'] = vm.scenario;
                returnClobSettingsObj['object_id']  =  vm.rowData.object_id;
                returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
                returnClobSettingsObj['tax_year'] = filterParams.tax_year;
               // returnClobSettingsObj['OPERATION_TYPE'] = "I";
               // returnClobSettingsObj['object_id'] = vm.rowData.OBJECT_ID;
                
                console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
                
                otherInfo = returnClobSettingsObj;
                    
                // SAVE the Standard Reason and Non_Creditable Amount
                vm.pshipData.forEach(element => {
	        		if (element.applyValue) {
	        			element['apply'] = 'Y';
	        		} else {
	        			element['apply'] = 'N';
	        		}
	        	});
                
                var returnObj = {};		
                returnObj['object_id'] = vm.rowData.object_id;					
                returnObj['details'] = vm.pshipData;
                returnObj['p_ho_combination_key'] = vm.rowData.PSHIP_HO_COMBINATION_KEY;
                returnObj['p_pship_combination_key'] = vm.rowData.PTR_COMBINATION_KEY;
                returnObj['p_ptrcombination_key'] = vm.rowData.PTR_COMBINATION_KEY; 
                
                var defaultsChangeDetails = [];
                defaultsChangeDetails.push(returnObj);
                //invoking the send details
                sendDetails(returnClobSettingsObj, defaultsChangeDetails,otherInfo, message)
            }
                
            function sendDetails(returnClobSettingsObj, defaultsChangeDetails,otherInfo, message) {
                PshipPtrDetailsFactory.savePshipDetails(returnClobSettingsObj,defaultsChangeDetails,otherInfo).then(function(result) {
                    if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                        AlertService.add("error", result.data.errorMessage, 4000);
                        vm.isSaveClicked = false;
                    } else {
                            AlertService.add("success", message, 4000); 
                            $uibModalInstance.dismiss('cancel');
                            var args = {};
                            $rootScope.$emit('gridUpdate', args);                           
                        };
                    });
                }
        }
            
    }
);