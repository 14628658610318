define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.SyncFormIIIEightController', [])
					.controller('syncFormIIIEightCtrl', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', syncFormIIIEightCtrl])
					
			function syncFormIIIEightCtrl($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG) {
				
				var vm = this;	
				vm.filteredTaxYearData = [];	
				//Get the current filing Tax Year
				vm.curentyear = GlobalService.globalParams.tax_year;
				//Get the scenario
				vm.scenario = GlobalService.globalParams.scenario;
				//JCD Key for the given custom screen
				vm.jcd_key = GlobalService.globalParams.jcd_key;
				//JCD TA Key for the given custom screen
/*				vm.jcd_ta_key;*/
				vm.selectedFromAcctKey
				//Show Scenario dropdown
				vm.showscenario = false;
				vm.showFilingGroup = false;
				vm.showFilerEntity = false;
				
				vm.localAccts = [];
				vm.filingGroupDropDown = [];
				vm.filingAllDetails = [];
				vm.filingEnityDetails = [];
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"scenario": GlobalService.globalParams.scenario 
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				
					
				
				/*$scope.$watch(function() { return JCDFactory.JCDList },   function() {
					if(JCDFactory.JCDList!=undefined && JCDFactory.JCDList!=null && JCDFactory.JCDList.length > 0){											
						var b = _.find(JCDFactory.JCDList, function(o) { return o.ACTIVITY_NAME.includes(workspaceFactory.activity.label); });							
						//Get the JCD Core details
						vm.jcd_key = b.JCD_KEY;
						vm.jcd_ta_key = b.JCD_TA_KEY;
						vm.task_name = b.TASK_NAME;							
					}
				});*/
				
				//Redirect the URL to a sourcing screen after closing the Modal screen 
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
								
				//Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {	
					var params = {"action_code" : 'e83e1x',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});							
						for(var indx in vm.taxYearData){
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearData[indx].CODE_DISP_DESC <= (vm.curentyear) && vm.taxYearData[indx].CODE_DISP_DESC >= (vm.curentyear-5))){
								vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
							}
						}
					});					
				};
				vm.getTaxYearDropDown();
				
				vm.getFilingGroupDropDown = function() {
					vm.filingGroupDropDown = [];
					vm.selectedFilingGroup = "";
					vm.filingEnityDetails = [];
					vm.filerEntity = "";
					let params = {
			                "action_code": '0udsuc',
			                "scenario": vm.selectedScenario,
			                "tax_year":  vm.selectedTaxYear
			            };
			            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
			                .then(function(data) {
			                	console.log(data);
			                	vm.filingGroupDropDown = data.jsonObject;
			                	vm.showFilingGroup = true;
			                });
				}
				
				
				 vm.filingEnityDetailInfo = function() {
						vm.filingEnityDetails = [];
						vm.filerEntity = "";
			        	var params = {
								//"action_code": 'hmkia6',
			        			"action_code":"35gc5l",
			        			"filing_group_key":vm.selectedFilingGroup,
			        			 "scenario": vm.selectedScenario,
					                "tax_year":  vm.selectedTaxYear
						};
		            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
		                .then(function(data) {
		                	vm.filerEntity = null;
		                vm.filingAllDetails = Object.assign([],data.jsonObject);
		                vm.filingEnityDetails = Object.assign([],vm.filingAllDetails);
					    // vm.filingEnityDetails.unshift({"FILER_LE_KEY":"","FILER_LE":"SELECT"});	                   
					     //vm.showFilerEntity = true;
		                    
		                });
			        }
				 
				 vm.showSyncButton = function() {
			        	 vm.showFilerEntity = true;
			        }
				 
			        
			       // vm.filingEnityDetailInfo();
				
				//Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {	
					vm.scenarioDropDown = [];
					vm.selectedScenario = "";
					vm.filingGroupDropDown = [];
					vm.selectedFilingGroup = "";
					vm.filingEnityDetails = [];
					vm.filerEntity = "";
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
						AlertService.add("error", "Please select the Tax Year to get the Scenario.",4000);
						return;
					}
			
					var params = {"action_code" : '7q0wiy',"tax_year" : vm.selectedTaxYear};
                    params = _.merge({}, GlobalService.globalParams, params);					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;		
						vm.showscenario = true;
					});					
				};
					
				
				
				//Call the Sync process of 1118 Sync
				vm.sync1118Process = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					if(vm.selectedFilingGroup == undefined || vm.selectedFilingGroup == null || vm.selectedFilingGroup == ''){
						AlertService.add("error", "Please select a valid Filing Group.", 5000);
						return;
					}
					
					if(vm.filerEntity == undefined || vm.filerEntity == null || vm.filerEntity == ''){
						AlertService.add("error", "Please select a valid Filing Entity.", 5000);
						return;
					}
					vm.initiate1118Sync();
				}

				
				vm.initiate1118Sync = function () {
					var lockInfo = {},lockedParams = {};
					var params = {
					"sso_id":USER_SETTINGS.user.sso_id,
					"tax_year": vm.selectedTaxYear,
					"scenario" : vm.selectedScenario,
					"jcd_key": vm.jcd_key,
					"fg_Key":vm.selectedFilingGroup,
					"filer_le_key": vm.filerEntity,
					"filer_le_label": vm.filingEnityDetails.find(item => item.FILER_LE_KEY == vm.filerEntity).FILER_LE
					};
					lockInfo = {
					"tax_year": vm.selectedTaxYear,
					"scenario" : vm.selectedScenario,
					"jcd_key": vm.jcd_key,
					"consol_group_key":vm.selectedFilingGroup,
					"combination_key":vm.filingEnityDetails.find(item => item.FILER_LE_KEY == vm.filerEntity).FILER_COMBINATION_KEY
					};
					
					lockedParams = params;
					// lockedParams["action_code"] = 'n10cpv';
					lockedParams.checkLockedbj = JSON.stringify([lockInfo]);
					var promise = $http({
									   method: "POST",
									//   url: GENERAL_CONFIG.base_url+'/loadJsonObject',
									   url: GENERAL_CONFIG.pdf_engine_api_url + '/initiate1118Sync' ,
									   data: lockedParams
								   }).then(function (response) {
										if (response.data.callSuccess != 1) {
											AlertService.add("error", response.data.errorMessage, 4000);
											return;
										}
										AlertService.add("success", "Sync Process for Form 1118 is initiated. Email will be sent, after the process is complete.", 4000);
								});
				
			}
		}
			return controllers;
	
		});