define([
    'angular',
    './schedule-MCtrl',
    './schedule-MService', 
    
], function () {
    'use strict';
    return angular.module('app.FIR_5471_Schedule_M', ['app.schedule-MCtrl', 'app.schedule-MService'] );
});

