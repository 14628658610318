define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.F965ScheduleBCtrl', [])
        .controller('F965BController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$filter','$rootScope', '$timeout', 'rowData', 'colData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', F965BController]);


    function F965BController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $filter, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        
        // modalTitle shouldn't be hardcoded.
        vm.modalTitle = "Corporate and Real Estate Investment Trust (REIT) Report of Net 965 Tax Liability and Electing REIT Report of 965 Amounts";
        vm.userSettings = USER_SETTINGS;
        //getcodeMastDetails();
		vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.netReserves = 0;
        vm.batchLoader = false;
        vm.showBatchData = false;
        vm.showRequestLoader = true;
        vm.leId = rowData.LEID;
        vm.formName = '965';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';
        vm.codeMastMap = [];
        
        vm.formData = {
            "addrows": [],
            "editrows": [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME
        };

		$scope.unallocatedAmount = rowData.dig || {};

        var saveParamDtls = [];
        var tempDataObj = {};
        var tempDataObj2 = {};
        $scope.scheduleBFormView = {rows: []};
        vm.showFormLoader = true;
        $scope.partI = {
            header: [],
            rows: []
        };
        $scope.partII = {
            header: [],
            rows: []
        };
        $scope.partIII = {
            header: [],
            rows: []
        };
        var dynamic_attr = ['NA', 'NA', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'];

        function getmodalData() {
            var params = {
                "action_id": 31742,
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO,
                "jcd_key": rowData.JCD_KEY,
                "combination_key": rowData.COMBINATION_KEY, 
                "schedule_name": "B"
            };
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadPivotFormJson', params).then(function (data) {
                vm.modalTitle = data.irsForm.formItemList[0].dataRowList[0].dataCellList[1].name; // get the title from service
               for (var i = 1; i < data.irsForm.formItemList.length; i++) {
                    saveParamDtls = [];
                    tempDataObj = {};
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    var rows = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
                    tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
                    tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;
                    if(rows > 2){
                        iterateArray(rows, data.irsForm.formItemList[i].dataRowList[0].dataCellList, );
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    tempDataObj.saveParams = saveParamDtls;
                    $scope.scheduleBFormView.rows.push(tempDataObj);
                    
                }
               vm.formListOriginal = angular.copy($scope.scheduleBFormView);
               $scope.scheduleBFormView.rows[1].value = rowData.LE_NAME;
               $scope.scheduleBFormView.rows[2].value = rowData.EIN;
               $scope.scheduleBFormView.rows[3].value = rowData.TAX_YEAR;
               $scope.partI.header = $scope.scheduleBFormView.rows.slice(5, 7);
               $scope.partI.rows = $scope.scheduleBFormView.rows.slice(7, 15);
               $scope.partII.header = $scope.scheduleBFormView.rows.slice(15, 17);
               $scope.partII.rows = $scope.scheduleBFormView.rows.slice(17, 26);
               $scope.partIII.header = $scope.scheduleBFormView.rows.slice(26, 28);
               $scope.partIII.rows = $scope.scheduleBFormView.rows.slice(28, 37);
               vm.onLoadComputeTotals();
               initFormView($scope.scheduleBFormView);
               vm.showFormLoader = false;
            });
        }

        var iterateArray = function(count, array){
			if(count == 3){
                tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
				tempDataObj.attrib_order_a = array[2].key.split('~')[2];
                tempDataObj.value =  array[2].value == 'true' ? true : array[2].value == 'false' ? false : array[2].value;
            }else{
                tempDataObj.list = {};
                tempDataObj.attributeList = [];
                for (var i = 2; i < array.length; i++) {
                    tempDataObj["lineAttrKey_" + dynamic_attr[i]] = array[i].key.split('~')[1];
                    tempDataObj["attrib_name_" + dynamic_attr[i]] = array[i].key.split('~')[0];
                    tempDataObj["attrib_order_" + dynamic_attr[i]] = array[i].key.split('~')[2];
                    tempDataObj["attrib_value_" + dynamic_attr[i]] = array[i].value;
                    tempDataObj.list["attrib_value_" + dynamic_attr[i]] = array[i].value == 'true' ? true : array[i].value == 'false' ? false : array[i].value;
                    if(i == 2){
                        tempDataObj.isEditable = tempDataObj.attrib_value_a == null ? 'Y' : 'N'; 
                    }
                    tempDataObj.attributeList.push("attrib_value_" + dynamic_attr[i]);
                }
            }
        };

        function computeRow(part, i){
            $scope[part].rows[i].list.attrib_value_d = $scope[part].rows[i].list.attrib_value_c - $scope[part].rows[i].list.attrib_value_b;
            if($scope[part].rows[i].list.attrib_value_e){
                $scope[part].rows[i].list.attrib_value_f = 0;
                $scope[part].rows[i].list.attrib_value_g = $scope[part].rows[i].list.attrib_value_d;
            }else{
                $scope[part].rows[i].list.attrib_value_g = 0;
                $scope[part].rows[i].list.attrib_value_f = $scope[part].rows[i].list.attrib_value_d;
            }
        };

        vm.computeCalculationRows = function(part, rows, index, keyName, value){
            if(keyName == 'attrib_value_b' || keyName == 'attrib_value_c' || keyName == 'all'){
                if(rows > 1){
                    for (var i = 0; i < rows; i++) {
                        computeRow(part, i);
                    }
                }else{
                    computeRow(part, index);
                }
            }
        };
        
        vm.computeTotalValues = function(part, attr, totalIndex, totalAttr){
            var total = 0;
            for (var i = 0; i < $scope[part].rows.length; i++) {
                if(!!$scope[part].rows[i].list[attr]){
                    var value = parseInt($scope[part].rows[i].list[attr]);
                    total += value;
                }
            }
            $scope[part].rows[totalIndex].list[totalAttr] = total;  
        };

        vm.onLoadComputeTotals = function (){
            vm.computeCalculationRows('partI', 8, null, 'all');
            vm.computeTotalValues('partII', 'attrib_value_j', 8, 'attrib_value_a');
            vm.computeTotalValues('partII', 'attrib_value_k', 8, 'attrib_value_b');
            vm.computeTotalValues('partIII', 'attrib_value_k', 8, 'attrib_value_a');
            vm.computeTotalValues('partIII', 'attrib_value_l', 8, 'attrib_value_b');
        }

        $scope.checkBoxToggle =  function (index, keyName, type, state){
            if((type == 'yes' && !state) || (type == 'no' && state)){
                $scope.partI.rows[index].list[keyName] = 'NA';
                $scope.partI.rows[index].list.attrib_value_f = 0;
                $scope.partI.rows[index].list.attrib_value_g = 0;
            }else{
                if(state){
                    $scope.partI.rows[index].list.attrib_value_f = 0;
                    $scope.partI.rows[index].list.attrib_value_g = $scope.partI.rows[index].list.attrib_value_d;
                }else{
                    $scope.partI.rows[index].list.attrib_value_g = 0;
                    $scope.partI.rows[index].list.attrib_value_f = $scope.partI.rows[index].list.attrib_value_d;
                }
            }
        };

        getmodalData();// this is the main method which gets data to be displayed on the modal

        vm.save965B = function (url, data) {
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);
            params.process_name = workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
            	vm.showFormLoader = false;
                var status = response.status;
                console.log('------------------status is: --------------------------', status);
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage );
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };	 
        
        vm.save = function () { 
          	vm.showFormLoader = true;
        		var formRowDataA = {};
                var formRowDataB = {};
                var tempArray = [];
                for (var i = 0; i < $scope.scheduleFFormView.rows.length; i++) {
                    if($scope.scheduleFFormView.rows[i].num != 'NA'){
                        if($scope.scheduleFFormView.rows[i].list){
                            for (var j = 0; j < $scope.scheduleFFormView.rows[i].attributeList.length; j++) {
                                if($scope.scheduleFFormView.rows[i].list["attrib_value_" + dynamic_attr[j+2]] !== null){
                                    formRowDataA = {
                                        tax_year: rowData.TAX_YEAR,
                                        trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                                        line_order: $scope.scheduleFFormView.rows[i].line_order,
                                        attrib_order: $scope.scheduleFFormView.rows[i].list["attrib_order_" + dynamic_attr[j+2]],
                                        line_description: $scope.scheduleFFormView.rows[i].description,
                                        combination_key: rowData.COMBINATION_KEY,
                                        group_obj_key: rowData.GROUP_OBJ_KEY,
                                        form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                                        line_no: $scope.scheduleFFormView.rows[i].num,
                                        occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                                        line_attrib_key: $scope.scheduleFFormView.rows[i]["lineAttrKey_" + dynamic_attr[j+2]],
                                        Attribute_Name: $scope.scheduleFFormView.rows[i]["attrib_name_" + dynamic_attr[j+2]],
                                        Attribute_Value: $scope.scheduleFFormView.rows[i].list["attrib_value_" + dynamic_attr[j+2]],
                                        trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                                        trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                                        is_dirty: ''
                                    };
                                    tempArray.push(formRowDataA);
                                }
                            }
                        }else{
                            formRowDataB = {
                                tax_year: rowData.TAX_YEAR,
                                trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                                line_order: $scope.scheduleFFormView.rows[i].line_order,
                                attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_a,
                                line_description: $scope.scheduleFFormView.rows[i].description,
                                combination_key: rowData.COMBINATION_KEY,
                                group_obj_key: rowData.GROUP_OBJ_KEY,
                                form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                                line_no: $scope.scheduleFFormView.rows[i].num,
                                occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                                line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKey_a,
                                Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_a,
                                Attribute_Value: $scope.scheduleFFormView.rows[i].value,
                                trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                                trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                                is_dirty: ''
                            };
                            tempArray.push(formRowDataB);
                        }
                    }
                }
                vm.formData.editrows.push(tempArray);
                vm.mainData = [];
                vm.mainData.push(vm.formData);
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=gluccq";
                vm.save965B(url, vm.mainData);
                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function () {
                    $scope.$broadcast("showLoader", true);
                }, 100); 
        	};	 

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function () {
            $scope.partI.header = angular.copy(vm.formListOriginal.rows.slice(5, 7));
            $scope.partI.rows = angular.copy(vm.formListOriginal.rows.slice(7, 15));
            $scope.partII.header = angular.copy(vm.formListOriginal.rows.slice(15, 17));
            $scope.partII.rows = angular.copy(vm.formListOriginal.rows.slice(17, 26));
            $scope.partIII.header = angular.copy(vm.formListOriginal.rows.slice(26, 28));
            $scope.partIII.rows = angular.copy(vm.formListOriginal.rows.slice(28, 37));
            vm.onLoadComputeTotals();
        }
        
        function initFormView(filteredFormData) {
            $scope.scheduleFFormView = filteredFormData;
        }

        //Dynamic Tabset Code
        vm.tabs = [ 
            {id: 1, name: "Form Entry", url: "app/components/Forms/F965B/F965-ScheduleB-Entry.html", active: true }
            //{id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFVersion.html", active: false}
        ];
    }
});