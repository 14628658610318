define(
		[ 'angular',

		'./Qar2018Service',

		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.Qar2018Ctrl', [ 'app.Qar2018Service' ])
					.value('customSelectDefaultsqar', {
						displayText : 'Select...',
						emptyListText : 'There are no items to display',
						emptySearchResultText : 'No results match "$0"',
						addText : 'Add',
						searchDelay : 300,
					})
					.directive(
							'customSelectqar',
							[
									'$parse',
									'$compile',
									'$timeout',
									'$q',
									'customSelectDefaultsqar',
									function($parse, $compile, $timeout, $q,
											baseOptions) {
										var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

										return {
											restrict : 'A',
											require : 'ngModel',
											link : function(scope, elem, attrs,
													controller) {
												var customSelect = attrs.customSelectqar;
												if (!customSelect) {
													throw new ('Expected custom-select attribute value.');
												}
												scope
														.$watch(
																"ctrl.sys_acc.SYS_ACCT.SYS_ACCT",
																function() {
																	// scope.getChartAcctTableData();
																	scope
																			.$emit(
																					'SYS_ACCT_CHANGED',
																					'');
																});
												var match = customSelect
														.match(CS_OPTIONS_REGEXP);

												if (!match) {
													throw new ('Expected expression in form of '
															+ "'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'"
															+ " but got '"
															+ customSelect + "'.");
												}

												elem
														.addClass('dropdown custom-select');

												// Ng-Options break down
												var displayFn = $parse(match[2]
														|| match[1]), valueName = match[3], valueFn = $parse(match[2] ? match[1]
														: valueName), values = match[4], valuesFn = $parse(values), track = match[5], trackByExpr = track ? ' track by '
														+ track
														: '', dependsOn = attrs.csDependsOn;

												var options = getOptions(), timeoutHandle, lastSearch = '', focusedIndex = -1, matchMap = {};

												var itemTemplate = elem.html()
														.trim()
														|| '{{'
														+ (match[2] || match[1])
														+ '}}', dropdownTemplate = '<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">'
														+ '<span>{{displayText}}</span>'
														+ '<b></b>'
														+ '</a>'
														+ '<div class="dropdown-menu">'
														+ '<div stop-propagation="click" class="custom-select-search">'
														+ '<input class="'
														+ attrs.selectClass
														+ '" type="text" autocomplete="off" ng-model="searchTerm" />'
														+ '</div>'
														+ '<ul role="menu">'
														+ '<li role="presentation" ng-repeat="'
														+ valueName
														+ ' in matches'
														+ trackByExpr
														+ '">'
														+ '<a role="menuitem" tabindex="-1" href ng-click="select('
														+ valueName
														+ ')">'
														+ itemTemplate
														+ '</a>'
														+ '</li>'
														+ '<li ng-hide="matches.length" class="empty-result" stop-propagation="click">'
														+ '<em class="muted">'
														+ '<span ng-hide="searchTerm">{{emptyListText}}</span>'
														+ '<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>'
														+ '</em>'
														+ '</li>'
														+ '</ul>'
														+ '<div class="custom-select-action">'
														+ (typeof options.onAdd === 'function' ? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
																: '')
														+ '</div>' + '</div>';

												// Clear element contents
												elem.empty();

												// Create dropdown element
												var dropdownElement = angular
														.element(dropdownTemplate), anchorElement = dropdownElement
														.eq(0).dropdown(), inputElement = dropdownElement
														.eq(1).find(':text'), ulElement = dropdownElement
														.eq(1).find('ul');

												// Create child scope for input
												// and dropdown
												var childScope = scope
														.$new(true);
												configChildScope();

												// Click event handler to set
												// initial values and focus when
												// the dropdown is shown
												anchorElement
														.on(
																'click',
																function(event) {
																	if (childScope.disabled) {
																		return;
																	}
																	childScope
																			.$apply(function() {
																				lastSearch = '';
																				childScope.searchTerm = '';
																			});

																	focusedIndex = -1;
																	inputElement
																			.focus();

																	// If filter
																	// is not
																	// async,
																	// perform
																	// search in
																	// case
																	// model
																	// changed
																	if (!options.async) {
																		getMatches('');
																	}
																});

												if (dependsOn) {
													scope
															.$watch(
																	dependsOn,
																	function(
																			newVal,
																			oldVal) {
																		if (newVal !== oldVal) {
																			childScope.matches = [];
																			childScope
																					.select(undefined);
																		}
																	});
												}

												// Event handler for key press
												// (when the user types a
												// character while focus is on
												// the anchor element)
												anchorElement
														.on(
																'keypress',
																function(event) {
																	if (!(event.altKey || event.ctrlKey)) {
																		anchorElement
																				.click();
																	}
																});

												// Event handler for Esc, Enter,
												// Tab and Down keys on input
												// search
												inputElement
														.on(
																'keydown',
																function(event) {
																	if (!/(13|27|40|^9$)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	switch (event.keyCode) {
																	case 27: // Esc
																		anchorElement
																				.dropdown('toggle');
																		break;
																	case 13: // Enter
																		selectFromInput();
																		break;
																	case 40: // Down
																		focusFirst();
																		break;
																	case 9: // Tab
																		anchorElement
																				.dropdown('toggle');
																		break;
																	}
																});

												// Event handler for Up and Down
												// keys on dropdown menu
												ulElement
														.on(
																'keydown',
																function(event) {
																	if (!/(38|40)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	var items = ulElement
																			.find('li > a');

																	if (!items.length)
																		return;
																	if (event.keyCode == 38)
																		focusedIndex--; // up
																	if (event.keyCode == 40
																			&& focusedIndex < items.length - 1)
																		focusedIndex++; // down
																	if (focusedIndex >= 0) {
																		items
																				.eq(
																						focusedIndex)
																				.focus();
																	} else {
																		focusedIndex = -1;
																		inputElement
																				.focus();
																	}
																});

												resetMatches();

												// Compile template against
												// child scope
												$compile(dropdownElement)(
														childScope);
												elem.append(dropdownElement);

												// When model changes outside of
												// the control, update the
												// display text
												controller.$render = function() {
													setDisplayText();
												};

												// Watch for changes in the
												// default display text
												childScope.$watch(
														getDisplayText,
														setDisplayText);

												childScope
														.$watch(
																function() {
																	return elem
																			.attr('disabled');
																},
																function(value) {
																	childScope.disabled = value;
																});

												childScope
														.$watch(
																'searchTerm',
																function(
																		newValue) {
																	if (timeoutHandle) {
																		$timeout
																				.cancel(timeoutHandle);
																	}

																	var term = (newValue || '')
																			.trim();
																	timeoutHandle = $timeout(
																			function() {
																				getMatches(term);
																			},
																			// If
																			// empty
																			// string,
																			// do
																			// not
																			// delay
																			(term && options.searchDelay) || 0);
																});

												// Support for autofocus
												if ('autofocus' in attrs) {
													anchorElement.focus();
												}

												var needsDisplayText;
												function setDisplayText() {
													var locals = {};
													locals[valueName] = controller.$modelValue;
													var text = displayFn(scope,
															locals);
													if (text === undefined) {
														var map = matchMap[hashKey(controller.$modelValue)];
														if (map) {
															text = map.label;
														}
													}

													needsDisplayText = !text;
													childScope.displayText = text
															|| controller.$modelValue // text
												}

												function getOptions() {
													return angular
															.extend(
																	{},
																	baseOptions,
																	scope
																			.$eval(attrs.customSelectOptionsqar));
												}

												function getDisplayText() {
													options = getOptions();

													return options.displayText;
												}

												function focusFirst() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														focusedIndex = 0;
														opts.eq(0).focus();
													}
												}

												// Selects the first element on
												// the list when the user
												// presses Enter inside the
												// search input
												function selectFromInput() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														var ngRepeatItem = opts
																.eq(0).scope();
														var item = ngRepeatItem[valueName];
														childScope
																.$apply(function() {
																	childScope
																			.select(item);
																});
														anchorElement
																.dropdown('toggle');
													}
												}

												function getMatches(searchTerm) {
													var locals = {
														$searchTerm : searchTerm
													};
													$q
															.when(
																	valuesFn(
																			scope,
																			locals))
															.then(
																	function(
																			matches) {
																		if (!matches)
																			return;

																		if (searchTerm === inputElement
																				.val()
																				.trim() /*
																						 * &&
																						 * hasFocus
																						 */) {
																			matchMap = {};
																			childScope.matches.length = 0;
																			for (var i = 0; i < matches.length; i++) {
																				locals[valueName] = matches[i];
																				var value = valueFn(
																						scope,
																						locals), label = displayFn(
																						scope,
																						locals);

																				matchMap[hashKey(value)] = {
																					value : value,
																					label : label,
																				};

																				childScope.matches
																						.push(matches[i]);
																			}
																		}

																		if (needsDisplayText)
																			setDisplayText();
																	},
																	function() {
																		resetMatches();
																	});
												}

												function resetMatches() {
													childScope.matches = [];
													focusedIndex = -1;
												}

												function configChildScope() {
													childScope.addText = options.addText;
													childScope.emptySearchResultText = options.emptySearchResultText;
													childScope.emptyListText = options.emptyListText;

													childScope.select = function(
															item) {
														var locals = {};
														locals[valueName] = item;
														var value = valueFn(
																childScope,
																locals);
														childScope.displayText = displayFn(
																childScope,
																locals)
																|| options.displayText;
														controller
																.$setViewValue(value);

														anchorElement.focus();

														typeof options.onSelect === 'function'
																&& options
																		.onSelect(item);
													};

													childScope.add = function() {
														$q
																.when(
																		options
																				.onAdd(),
																		function(
																				item) {
																			if (!item)
																				return;

																			var locals = {};
																			locals[valueName] = item;
																			var value = valueFn(
																					scope,
																					locals), label = displayFn(
																					scope,
																					locals);

																			matchMap[hashKey(value)] = {
																				value : value,
																				label : label,
																			};

																			childScope.matches
																					.push(item);
																			childScope
																					.select(item);
																		});
													};

													// childScope.format =
													// format;

													setDisplayText();
												}

												var current = 0;
												function hashKey(obj) {
													if (obj === undefined)
														return 'undefined';

													var objType = typeof obj, key;

													if (objType == 'object'
															&& obj !== null) {
														if (typeof (key = obj.$$hashKey) == 'function') {
															// must invoke on
															// object to keep
															// the right this
															key = obj
																	.$$hashKey();
														} else if (key === undefined) {
															key = obj.$$hashKey = 'cs-'
																	+ current++;
														}
													} else {
														key = obj;
													}

													return objType + ':' + key;
												}
											},
										};
									}, ])

					.directive('stopPropagation', function() {
						return {
							restrict : 'A',
							link : function(scope, elem, attrs, ctrl) {
								var events = attrs['stopPropagation'];
								elem.bind(events, function(event) {
									event.stopPropagation();
								});
							},
						};
					})
					.directive(
							'allowDecimalNumbers',
							function() {
								return {
									restrict : 'A',
									link : function(scope, elm, attrs, ctrl) {
										elm
												.on(
														'keydown',
														function(event) {
															var $input = $(this);
															console
																	.log(
																			"-->  $input : ",
																			$input);
															var value = $input
																	.val();
															console
																	.log(
																			"-->  value : ",
																			value);
															value = value
																	.replace(
																			/[-+][^0-9\.]/g,
																			'')
															var findsDot = new RegExp(
																	/\./g)
															var containsDot = value
																	.match(findsDot)
															if (containsDot != null
																	&& ([
																			46,
																			110,
																			190 ]
																			.indexOf(event.which) > -1)) {
																event
																		.preventDefault();
																return false;
															}
															$input.val(value);
															if (event.which == 64
																	|| event.which == 16) {
																// numbers
																return false;
															}
															if ([ 8, 13, 27,
																	37, 38, 39,
																	40, 110 ]
																	.indexOf(event.which) > -1) {
																// backspace,
																// enter,
																// escape,
																// arrows
																return true;
															} else if (event.which >= 48
																	&& event.which <= 57) {
																// numbers
																return true;
															} else if (event.which >= 96
																	&& event.which <= 105) {
																// numpad number
																return true;
															} else if ([ 46,
																	110, 190 ]
																	.indexOf(event.which) > -1) {
																// dot and
																// numpad dot
																return true;
															} else {
																event
																		.preventDefault();
																return false;
															}
														});
									}
								}
							})

					.controller(
							'qar2018',
							[ '$rootScope', '$scope', '$state', '$log',
									'JsonObjectFactory', 'AlertService',
									'$timeout', '$uibModal',
									'$uibModalInstance', 'rowData', 'gridData',
									'GENERAL_CONFIG', 'Qar2018Factory',
									'$http', 'USER_SETTINGS', 'GlobalService',
									'workspaceFactory', '$filter', qar2018 ]);
			// Directives based on http://stackoverflow.com/a/24271309/3894163
			function qar2018($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, $timeout, $uibModal,
					$uibModalInstance, rowData, gridData, GENERAL_CONFIG,
					Qar2018Factory, $http, USER_SETTINGS, GlobalService,
					workspaceFactory, $filter) {

				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.isCreate = (gridData.length == 0 || gridData.colData.map == "Add") ? true
						: false;
				vm.rowData = rowData;
				vm.QARList = [];
				vm.basketDetails = [];
				vm.fdiiDetails = [];
				// A flag to enable and disable save button
				vm.isSaveClicked = false;
				var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
						.getFilterParams()
						: {};
				vm.curentyear = filterParams.tax_year; // GlobalService.globalParams.tax_year;
				// // //'' + new
				// Date().getFullYear();
				vm.scenario = filterParams.scenario;
				vm.jcd_key = GlobalService.globalParams.jcd_key;
				vm.grossIncExpData = [];
				// To get the Lock_Y_N flag from backend
				/*var lockparams = {
					"action_code" : 'n10cpv',
					'p_scenario' : vm.scenario,
					'p_jcd_key' : vm.jcd_key
				};*/
				/*JsonObjectFactory
						.getJSONObj(
								GENERAL_CONFIG.base_url + '/loadJsonObject',
								lockparams)
						.then(
								function(data) {
									vm.is_locked = data.jsonObject;
									// To Block user from Add/Edit based the
									// Default Tax year and
									// Filter Tax Year along with the Lock_Y_N
									// flag
									$scope
											.$watch(
													function() {
														return vm.is_locked
													},
													function() {
														if (vm.is_locked != undefined
																&& vm.is_locked != null
																&& vm.is_locked[0].LOCK_STATUS == 'Y') {
															vm.cancel();
															AlertService
																	.add(
																			"error",
																			"Tax Return has already been filed for tax year "
																					+ filterParams.tax_year
																					+ ".",
																			'4000');
															return;
														}
													});
								});*/

				vm.getCodeMastData = function() {
					var params = {
						"action_code" : 'dbty56',
						"p_scenario" : vm.scenario,
						"p_jcd_key" : vm.jcd_key,
						"cc_key" : vm.rowData.CODE_COMBINATION,
					};
					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.basketDetails = _
													.filter(
															data.jsonObject,
															function(p) {
																return _
																		.includes(
																				[ 'SOURCE_CODE' ],
																				p.CODE_GROUP);
															});
											vm.fdiiDetails = _
													.filter(
															data.jsonObject,
															function(p) {
																return _
																		.includes(
																				[ 'FDII_CODE' ],
																				p.CODE_GROUP);
															});
										}
									});
					params = {
						"action_code" : 'yenzpg',
						'p_tax_year' : vm.curentyear,
						"p_scenario" : vm.scenario,
						"p_jcd_key" : vm.jcd_key,
						'adj_key' : vm.rowData.ADJ_KEY,
						'group_key' : vm.rowData.GROUP_KEY

					}
					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											data.jsonObject.forEach(function(
													item) {
												item.OPERATION_TYPE = 'U';
												vm.QARList.push(item);
											});
											vm.updateTotal();
										}
									});
				}

				// Update the total for every amount entered in Modal
				vm.updateTotal = function() {
					vm.total = 0;
					vm.QARList.forEach(function(list) {
						if (list.OPERATION_TYPE && list.OPERATION_TYPE != 'D')
							vm.total += parseInt(list.ADJ_AMT ? list.ADJ_AMT
									: 0);
					})
				}

				// Adding a new row as a copy of Selected Row
				vm.addRow = function() {
					var tempObj = {};
					tempObj.SOURCE_KEY = 0;
					tempObj.FDII_KEY = 0;
					tempObj.ADJ_AMT = "";
					tempObj.OPERATION_TYPE = "I";
					vm.QARList.push(tempObj);

				};

				// Delete the selected row
				vm.deleteRow = function(selectedRowData) {
					var index = vm.QARList.indexOf(selectedRowData);

					if (vm.QARList[index].OPERATION_TYPE != undefined
							|| vm.QARList[index].OPERATION_TYPE != null
							|| vm.QARList[index].OPERATION_TYPE != "") {
						if (selectedRowData.OPERATION_TYPE == 'I') {
							vm.QARList.splice(index, 1);
						} else {
							vm.QARList[index].OPERATION_TYPE = 'D';
						}
						var countActiveRows = _.filter(vm.QARList, function(
								eachRow) {
							if (eachRow.OPERATION_TYPE != 'D')
								return eachRow
						}).length;
						if (countActiveRows == 0) {
							vm.addRow();
						}
					}

				};

				vm.autoLoadInfo = function() {
					vm.QARList = [];
					vm.getCodeMastData();
				}
				
				vm.autoLoadInfo();

				vm.reset = function() {
					vm.autoLoadInfo();
				}
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				}
				
				vm.removeDuplicates = function(originalArray,prop1, prop2) {
				     var newArray = [];
				     var lookupObject  = {};

				     for(var i in originalArray) {
				    	 if(originalArray[i].OPERATION_TYPE != 'D')
				        lookupObject[originalArray[i][prop1]+""+originalArray[i][prop2]] = originalArray[i];
				     }

				     for(i in lookupObject) {
				         newArray.push(lookupObject[i]);
				     }
				      return newArray;
				 }

				

				function sendDetails(returnObj, transactionDetails, message,
						otherInfo) {
					Qar2018Factory.save(returnObj, transactionDetails,
							otherInfo).then(
							function(result) {
								vm.isSaveClicked = false;
								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									var startIndex = result.errorMessage
											.indexOf("(") + 1;
									var lastIndex = result.errorMessage
											.indexOf(")");
									var errorKey = result.errorMessage
											.substring(startIndex, lastIndex);
									AlertService.add("error",
											result.errorMessage, 4000);
									vm.isSaveClicked = false;
								} else {
									AlertService.add("success", message, 4000);
									$uibModalInstance.dismiss('cancel');
									var args = {};
									$rootScope.$emit('gridUpdate', args);
								}
							});

				}
				vm.save = function() {
					var returnObj = {}, transactionDetails = [], message = "", otherInfo = {}, errorInfo = "";
					// Disable the Save button till the processing completes
					vm.isSaveClicked = true;

					var totalSubAmount = 0;
					var countActiveRows = _.filter(vm.QARList, function(
							eachRow) {
						if (eachRow.OPERATION_TYPE != 'D')
							return eachRow
					}).length;
					if (vm.removeDuplicates(vm.QARList, "SOURCE_KEY" ,"FDII_KEY").length !== countActiveRows) {
						AlertService
						.add(
								"error",
								"Duplicate Data is not allowed",
								4000);
							vm.isSaveClicked = false;
							return 0;
					}
					
					if (vm.total !== vm.rowData.ORG_AMT) {
						AlertService
						.add(
								"error",
								"Balance total with ORG AMT ",
								4000);
							vm.isSaveClicked = false;
							return 0;
					}
					for ( var row in vm.QARList) {

						if(vm.QARList[row].ADJ_AMT != undefined
								&& vm.QARList[row].ADJ_AMT != null && vm.QARList[row].ADJ_AMT != ""
									&& vm.QARList[row].ADJ_AMT % 1 !== 0) {
						AlertService
						.add(
								"error",
								"Please give number decimal not allowed.",
								4000);
						vm.isSaveClicked = false;
						return;
					}
						
						if ((vm.QARList[row].SOURCE_KEY == undefined
								|| vm.QARList[row].SOURCE_KEY == null
								|| vm.QARList[row].SOURCE_KEY === ""
								|| vm.QARList[row].SOURCE_KEY === 0
								|| vm.QARList[row].FDII_KEY == undefined
								|| vm.QARList[row].FDII_KEY == null
								|| vm.QARList[row].FDII_KEY == 0
								|| vm.QARList[row].FDII_KEY === ""
								|| vm.QARList[row].ADJ_AMT == undefined
								|| vm.QARList[row].ADJ_AMT == null || vm.QARList[row].ADJ_AMT === "")
								&& vm.QARList[row].OPERATION_TYPE != 'D') {
							AlertService
									.add(
											"error",
											"Please select/enter all the required fields.",
											4000);
							vm.isSaveClicked = false;
							return;
						} else {
							returnObj = {};
							returnObj["COMBINATION_KEY"] = vm.rowData.COMBINATION_KEY;
							returnObj["SOURCE_KEY"] = vm.QARList[row].SOURCE_KEY;
							returnObj["FDII_KEY"] = vm.QARList[row].FDII_KEY;
							returnObj["AMOUNT"] = vm.QARList[row].ADJ_AMT;
							returnObj["GROUP_KEY"] = vm.rowData.GROUP_KEY;
							returnObj["ISSUE_ID"] = vm.rowData.ISSUE_ID;
							returnObj["ISSUE_DESC"] = vm.rowData.ISSUE_DESC;
							returnObj["COMMENTS"] = vm.rowData.COMMENTS;
							returnObj["STAGE_KEY"] = vm.rowData.STAGE_KEY;
							returnObj["ADJUSTMENT_KEY"] =  vm.QARList[row].OPERATION_TYPE  === "I" ? null : vm.QARList[row].ADJ_KEY;                                                                                                                          
							returnObj["IS_SINGLE"] = "M";
							returnObj["OPERATION_TYPE"] = vm.QARList[row].OPERATION_TYPE;
							transactionDetails.push(returnObj);
						}                                                     

					}

					otherInfo['sso_id'] = vm.userSettings.user.sso_id;
					otherInfo['SCREEN_KEY'] = GlobalService.globalParams.screen_key;
					otherInfo['tax_year'] = GlobalService.globalParams.tax_year;
					otherInfo['scenario'] = GlobalService.globalParams.scenario;
					otherInfo['jcd_key'] = GlobalService.globalParams.jcd_key;

					/*
					 * returnObj["ENTITY"] = vm.entity.KEY;
					 * returnObj["COMBINATION_KEY"] = vm.entity.KEY;
					 * returnObj["GIE_KEY"] = vm.selectedGrossIncExp.GIE_KEY; //
					 * returnObj["SIC_CODE"] = vm.sicCode.CODE_KEY;
					 * returnObj["SOURCE_KEY"] = vm.basket.CODE_KEY;
					 * returnObj["FDII_KEY"] = vm.fdii.CODE_KEY;
					 * returnObj["AMOUNT"] = vm.adjustmentAmount;
					 * returnObj["ADJUSTMENT_NAME"] = vm.issueType;
					 * returnObj["IS_SINGLE"]= 'S'; returnObj["TRANS_TYPE"] =
					 * 'QAR_ADJ'; if(!vm.isCreate){ returnObj["ADJUSTMENT_KEY"]=
					 * vm.rowData.ADJ_KEY;
					 * returnObj["GROUP_KEY"]=vm.rowData.GROUP_KEY; }
					 * 
					 * returnObj["OPERATION_TYPE"]= vm.isCreate ? 'I':'U';
					 * transactionDetails.push(returnObj);
					 */
					message = "QAR Adjustment has been successfully saved/updated";
					if (vm.isSaveClicked)
						sendDetails(returnObj, transactionDetails, message,
								otherInfo);
				}

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				vm.settingAdjustmentAmount = function(event) {
					if (vm.adjustmentAmount) {
						vm.adjustmentAmount = vm.adjustmentAmount.replace(
								/(?!^-)[^0-9\.]/g, '')
						let amount = vm.adjustmentAmount;
						if (amount.indexOf('.') !== -1) {
							let amountSp = amount.split('.'), bPre = 0, aPre = 0, amt = 0;
							bPre = amountSp[0];
							aPre = amountSp[1];
							if (bPre && bPre.length > 20) {
								bPre = bPre.substring(0, 19)
							}
							if (aPre && aPre.length > 2) {
								aPre = aPre.substring(0, 2)
							}
							vm.adjustmentAmount = bPre + "." + aPre;
						} else if (amount.length > 20) {
							amount = amount.substring(0, amount.length - 1)
							vm.adjustmentAmount = amount;
						}
					}
					/*
					 * if(vm.adjustmentAmount){ let amount =
					 * vm.adjustmentAmount.toString(); if(amount.indexOf('.')
					 * !== -1){ if(amount.length > 18){ //return false; amount =
					 * amount.substring(0, amount.length - 1)
					 * vm.adjustmentAmount = amount; } }else if(amount.length >
					 * 17){ amount = amount.substring(0, amount.length - 1)
					 * vm.adjustmentAmount = parseInt(amount); } }
					 */
					// return event.keyCode !== 69;
				}

			}

			return controllers;

		});