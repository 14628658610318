define([
    'angular'



], function () {
    'use strict';

    var controllers =  angular.module('app.GridBuilderController',[])
    .controller('GridBuilderController', [ '$rootScope','AlertService','$timeout','GridBuilderFactory','$scope', 'USER_SETTINGS', 'UserFactory', '$uibModal', '$uibModalInstance','rowData','JsonObjectFactory','ClientAdminFactory','GENERAL_CONFIG', '$parse', gridBuilderController])
    .controller('BatchActionCtrl', ['$uibModalInstance', 'GridBuilderFactory', 'AlertService','$parse', '$filter','rowData', 'USER_SETTINGS', batchActionCtrl])
    .controller('GridListCtrl', ['$rootScope','$scope','$state', '$parse', 'ClientAdminFactory', '$log','GridFactory','AlertService','$timeout','$uibModal', 'USER_SETTINGS', gridListCtrl])



    function gridBuilderController($rootScope, AlertService, $timeout, GridBuilderFactory, $scope, USER_SETTINGS, UserFactory , $uibModal, $uibModalInstance,rowData,JsonObjectFactory,ClientAdminFactory,GENERAL_CONFIG, $parse) {

        var vm = this;
        vm.title = "Grid Builder";
        vm.new = true;
        vm.gridData = {};
        vm.showSave = false;
        vm.showActionKey = false;
        vm.action_on = false;
        vm.crudLoading = false;
        vm.userSettings = USER_SETTINGS;
        vm.client_key = vm.userSettings.user.client.client_key;
        vm.api_key = "";
        vm.client = {selected:null,list:[], disabled:true };
        vm.grid = {
            grid_key:null,
            action_key:null,
            grid_name: "",
            default_row_height: 30,
            fixed_columns: 2,
            header_height:30,
            headers:[],
            grid_type:"standard",
            max_xls_rows:1000,
            batch_select:0,
            expand_cols:0,
            comments:0,
            attachments:0,
            batch_select_rules:null,
            batch_rule_message:null,
            client_key:vm.client_key,
            doc_header_key:null,
            doc_header_type:null,
            screen_validation:0,
            rule_type:null,
            buffer_size:0,
            colMap:[],
            colAmount:[],
            audit_cells:0

        }

        vm.header = {
            "call_back":"",
          //  "client_key":(grid.client && grid.client.selected.client_key) ?  grid.client.selected.client_key : '',
            "col_class":"",
            "data_type":"string",
            "display":"y",
            "drilldown":"",
            "edit_type":"",
            "editable":"n",
            "new_row_editable":"0",
            "format":null,
            "full_label":null,
            "grid_header_key":"",
            "grid_key":"",
            "header_class":null,
            "label":"",
            "map":"",
            "validation":null,
            "width":90,
            "apply_rules":0,
            "edit_rules":"",
            "edit_rule_message":"",
            "edit_icon":"",
            "audit_cells":0,
            "cell_class":0,
            "drilldown_filter_refresh":0,
            "drilldown_rule":0,
            "documentation": 0,
            "pivot_unique_key":null,
            "pivot_group_by_key":null,
            "pivot_group_by_data":null,
            "description":"",
            "round_off":0
        };
        vm.screenRule = [
            {name:"colNetZero", value:"colNetZero"},
            {name:"groupNetZero", value:"groupNetZero"}
        ];
        vm.lookUpMapping =false;
        vm.screenValidation =false;
        console.log("vm.header data",vm.header);


        // get the clientList initail load of page

		/*vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.client.list = $parse('data.clientResult.clientList')(res);
                console.log("ALLLLLLCLIENTS",vm.client.list);
            });
        };
        vm.getClientList();*/
       // vm.validationRules = [];

        vm.validationRules = {};
        vm.validationRules.filters = [];

        var emptyValidObj = { rule_type: '',  colMap: [] ,colAmount:[],buffer_size:0};
        vm.selecValidObj = function (isNew, validObj) {
            vm.newFilter = {};
            if(vm.grid.screen_validation == 1){
                if (vm.grid.headers && vm.grid.headers.length) {
                    vm.validHeaders = vm.grid.headers.map(function (item) {
                        return {
                            name: item.label,
                            value: item.map,
                            data_type:item.data_type

                        }
                    });
                    if(isNew){
                        vm.newFilter = angular.copy(emptyValidObj);
                    }
                    else if(validObj){
                        vm.newFilter.id = validObj.id;
                    }

                    var coluHeader = _.cloneDeep(vm.validHeaders);
                    var selectedColMap = [];
                    vm.newFilter.headercolMapSelectObj = {};
                    vm.newFilter.headercolMapSelectObj.values = _.cloneDeep(vm.validHeaders);
                    vm.newFilter.headercolMapSelectObj.name = 'Valid ColMap';
                    if(validObj && validObj.colMap){
                          _.each(vm.newFilter.headercolMapSelectObj.values,function(item){
                            _.each(validObj.colMap,function(i){
                                if(item.value == i){
                                   // return item;
                                   selectedColMap.push(item);
                                }
                            })
                        })
                    }
                    vm.newFilter.headercolMapSelectObj.selected = (selectedColMap && selectedColMap.length>0)? selectedColMap: [];

                    var selectedColAmount = [];
                    vm.newFilter.headerColAmountSelecteObj = {};
                    vm.newFilter.headerColAmountSelecteObj.values = coluHeader;//_.filter(coluHeader,{data_type:'number'});
                    vm.newFilter.headerColAmountSelecteObj.name = 'Valid ColAmount';
                    if(validObj && validObj.colAmount){
                          _.each(vm.newFilter.headerColAmountSelecteObj.values,function(item){
                            _.each(validObj.colAmount,function(i){
                               if(item.value == i){
                                   //return item;
                                   selectedColAmount.push(item);
                               }
                            })
                        })
                    }
                    vm.newFilter.headerColAmountSelecteObj.selected = (selectedColAmount && selectedColAmount.length>0)?selectedColAmount: [];
                    vm.newFilter.rule_type = (validObj && validObj.rule_type)?validObj.rule_type:"";
                    vm.newFilter.buffer_size = (validObj && validObj.buffer_size)?validObj.buffer_size:0;
                    vm.newFilter.screenRule =  vm.screenRule;
                }
            }
           return vm.newFilter;
       }
        vm.addScreenValidation = function(index, isEdit,validObj){
            var isNew = false;
            if(!isEdit && vm.grid.screen_validation == 1){
                isNew = !isNew;
                var filter = vm.selecValidObj(isNew);
                filter.id = vm.validationRules.filters.length+1;
                vm.validationRules.filters.push(filter);
            }else{
                if(validObj && validObj.length>0){
                    var xx = []
                    _.each(validObj,function(item){
                        var filter = vm.selecValidObj(isNew,item);

                        xx.push(filter);
                    });
                    vm.validationRules.filters = xx;
                }
            }
        }
        vm.removeScreenValidation = function(rule,index){
            vm.validationRules.filters.splice(index, 1);
        }
        vm.copyClipboard = function(){
            var copyTextarea = document.querySelector('.js-copytextarea');
            copyTextarea.select();
            try {
                var successful = document.execCommand('copy');
                vm.copyMessage = successful ? 'API key was successful copied to your clipboard' : 'unsuccessful';
                $timeout( function(){
                    vm.copyMessage = null;
                }, 3000 );
            } catch (err) {
            }

        }



        vm.gridType = [
            {name:"Standard", value:"standard"},
            {name:"Dynamic", value:"dynamic"},
            {name:"Batch Save", value:"batch-save"},
            {name:"Pivot", value:"pivot"},
            {name:"Import", value:"import"},
            {name:"Custom", value:"custom"},
        ]


        vm.dataType = [
            {name:"string", value:"string"},
            {name:"number", value:"number"},
            {name:"date", value:"date"}
        ]
        vm.editType = [
            {name:"form", value:"form"},
            {name:"text", value:"text"},
            {name:"dropdown", value:"dropdown"}
        ]


        vm.iconType = [
            {name:"Edit", value:"edit"},
            {name:"Info", value:"info"},
            {name:"Remove", value:"remove"},
            {name:"Question", value:"question"},
            {name:"Eye", value:"eye"},
            {name:"Folder", value:"folder"},
            {name:"Download", value:"download"},
            {name:"Download File", value:"downloadFile"},
            {name:"File",value:"file"}
        ]

        vm.formatType = [
            {name:"String", value:""},
            {name:"Number with Commas", value:"numberWithCommas"},
            {name:"Float with Commas", value:"floatWithCommas"},
            {name:"Date", value:"date"},
            {name:"Checkbox", value:"checkbox"},
            {name:"Locked", value:"locked"},
            {name:"Color Dot", value:"color-dot"},
            {name:"Color Error", value:"color-error"},
        ]


        vm.validationType = [
            {name:"None", value:""},
            {name:"Number", value:"numeric"},
            {name:"Date", value:"date"},
            {name:"Uppercase", value:"uppercase"},
            {name:"Lowercase", value:"lowercase"}
        ]

        vm.transposeType = [
            {name:"None", value:""},
            {name:"Uppercase", value:"uppercase"},
            {name:"Lowercase", value:"lowercase"},
        ]

        vm.header_map_data = [];

        if(rowData){
            vm.grid = JsonObjectFactory.objToLower(rowData);
            vm.new = false;
            vm.action_on = false;
            vm.showSave = true;

            getHeaders(vm.grid.grid_key);
            generateApiKey();

        }

        vm.dataTypeToggle = function(index, type){
            if(type !== 'number'){
                vm.grid.headers[index].round_off = 0;
            }
        };

        vm.transposeToggle = function(index, type){
            vm.grid.headers[index].validation = type;
        };

        function generateApiKey(){
            vm.api_key = '';
            if(vm.grid.grid_key && vm.grid.action_key ){
                    if( vm.grid.grid_type === 'dynamic' ){ //Bhanu Kondaveti - 08/16/2019 - Added for Dynamic Grid
                        vm.api_key = '{"get":'+GENERAL_CONFIG.base_url + '"/loadDynamicGridJson?action_id='+vm.grid.action_key+'&grid_id='+vm.grid.grid_key+'"}';
                    }else if( vm.grid.grid_type !== 'pivot' ){
                        vm.api_key = '{"get":'+GENERAL_CONFIG.base_url + '"/loadGridJson?action_id='+vm.grid.action_key+'&grid_id='+vm.grid.grid_key+'"}';
                    }else{
                        vm.api_key = '{"get":'+GENERAL_CONFIG.base_url +'"/loadPivotGridJson?action_id='+vm.grid.action_key+'&grid_id='+vm.grid.grid_key+'"}';
                    }
            }
        }

          /* JSON VALIDATION FOR BATCH SELECT RUELS */
        vm.isValid = true;
        vm.batch_select_rules = JSON.stringify(vm.grid.batch_select_rules);
        $scope.updateJsonObject = function(header,innerForm) {
           vm.isValid = true;
            if(header){
                header.isValid=true;
                if(header.edit_rules !=""){
                    try {
                        header.edit_rules = JSON.stringify(JSON.parse(header.edit_rules));
                    } catch (e) {
                        header.isValid=false;
                    }
                }
                if(header.drilldown != ""){
                    header.isValidate=true;
                    try {
                        header.drilldown = JSON.stringify(JSON.parse(header.drilldown));
                    } catch (e) {
                        header.isValidate=false;
               }
           }

            }else{
                vm.isValid = true;
                try {
                    vm.batch_select_rules = JSON.stringify(JSON.parse(vm.grid.batch_select_rules));
                } catch (e) {
                    vm.isValid = false;
                  //  vm.showSave = false;
                }
            }
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.addHeader = function(){
            vm.grid.headers.splice(1, 0, _.clone(vm.header));
        };

        vm.deleteHeader = function(_index){
            vm.grid.headers.splice( _index, 1 );
        };

        vm.resetHeaders = function(){
            if(vm.new ){
                vm.grid.headers = [];
                vm.genHeaders();
            }else{
                vm.grid.headers = [];
                getHeaders(vm.grid.grid_key);
            }
        }



        function arraymove(arr, fromIndex, toIndex) {
            if( toIndex < 1 || toIndex >  arr.length){
                return false;
            }
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        }




        vm.moveUp = function(_index){
            arraymove(vm.grid.headers, _index , _index - 1);
        };

        vm.moveDown = function(_index){
            arraymove(vm.grid.headers, _index , _index + 1);
        };


        vm.genHeaders = function() {
                AlertService.add("info", "Generating headers" , 2000);
                vm.grid.headers = [];
                var formData =  {action_id: vm.grid.action_key};
                vm.showSave = true;
                GridBuilderFactory.genGridHeader(formData).then(
                    function(responseData) {
                        if(responseData && responseData.headerLabelMapList){
                            vm.grid.headers = responseData.headerLabelMapList;
                            vm.cloneGridHeaders();
                        }

                    }
                );
        }


        function getHeaders(_grid_key) {
            var data = {grid_key:_grid_key};
            vm.tempData = {};
            vm.header_map_data = [];
            GridBuilderFactory.getGridHeaderByKey(data).then(
                function(data) {
                    vm.grid.headers = data.jsonObject;
                    angular.forEach(vm.grid.headers, function(header, index) {
                        if(index > 0){
                            vm.tempData = {
                                "name": header.map,
                                "value": header.map
                            };
                            vm.header_map_data.push(vm.tempData);
                        }
                    });
                    vm.cloneGridHeaders();
                    vm.grid.client={}
                    vm.grid.client.selected =  _.find(vm.client.list, {client_key : rowData.CLIENT_KEY+''});

                    vm.getActionsNames();
                    if(vm.grid && vm.grid.screen_validation_rules != undefined){
                        var validObj  = JSON.parse(vm.grid.screen_validation_rules);
                        if(validObj && validObj.length>0){
                            vm.addScreenValidation(null,true,validObj);
                        }

                    }

                }
            );
        }

        vm.getActionsNames = function(){
            var modalObj = {
                client_key : vm.grid.client_key,
                grid_key : vm.grid.grid_key
            }
            GridBuilderFactory.getActionName(modalObj).then(function (response) {
                vm.ActionNames = response.data.gridActionData;
            	if (response.data.callSuccess === "1") {
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }

            });
        }

        // used to delete the action names.
        vm.removeActionName = function(value) {
            var delObj = {
                client_key : vm.grid.client_key,
                grid_action_key : value
            }
            GridBuilderFactory.removeActionName(delObj).then(function (response) {
                vm.delete = response.data.gridActionData;
            	if (response.data.result.value === "1") {

                    AlertService.add("success", "Grid Action Successfully Deleted",4000);
                    vm.getActionsNames();
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }

            });
        }

        /* edit Grid Actions 6/4/2019 */
        vm.editGridAction = function(value){
            var data = {
                client : vm.grid.client_key,
                grid_key : vm.grid.grid_key,
                getCall : vm.getActionsNames,
                actionData : value,

            }
            var modalObj = {};
				modalObj.template = "app/components/admin/gridBuilder/batch-action-temp-main.html";
				modalObj.controler = "BatchActionCtrl as ctrl";
                modalObj.size = "md";
                modalObj.keyboard = false;
                modalObj.backdrop = "static";
				vm.openModal(modalObj,data);
        }

        vm.documentMap = function(){
            if(vm.grid.batch_select == 1 && vm.grid.documentation == 1){
               // if( vm.grid.documentation == 1){
                    if(vm.grid && vm.grid.headers.length>0){
                        var docMap = _.filter(vm.grid.headers,function(item){
                                return item.map == 'edit_documentation' || item.map=='PARENT_KEY' || item.map == 'docu_comments' || item.map == 'PARENT_TYPE'
                            });
                            if(docMap && docMap.length ==0){
                                vm.grid.headers.push(
                                    {"call_back":"editAttachment", "label":"Document",  "map":"edit_documentation", "data_type":"string",  "display":"y","edit_type":"form","editable":"y",
                                    "edit_icon":"file","width":120 ,"apply_rules":"1","edit_rules":'[{"map":"DOC_BATCH_ID","value":null,"operator":"!="}]',"col_class":"icon-center",},

                                    // {"call_back":"", "label":"HASDOCUMENTS",  "map":"HASDOCUMENTS", "data_type":"string",  "display":"n","col_class":"icon-center",
                                    // "edit_type":"number","editable":"n","edit_icon":"","width":120,"apply_rules":"0"},

                                    {"call_back":"", "label":"Documnents Batch ID",  "map":"DOC_BATCH_ID", "data_type":"string",  "display":"y","col_class":"center-align",
                                        "edit_type":"text","editable":"n","edit_icon":"","width":120,"apply_rules":"1","edit_rules":'[{"map":"DOC_BATCH_ID","value":null,"operator":"!="}]'}
                                )}

                            if(vm.grid.grid_key!=null || vm.grid.grid_key != undefined){
                                vm.batchAction= [
                                    {
                                        grid_action_key: '',
                                        name: 'Add Document',
                                        icon: 'fa fa-file',
                                        row_data_required: 1,
                                        call_back: 'addAttachment',
                                        grid_key : vm.grid.grid_key,
                                        client_key : vm.grid.client_key
                                    },
                                    {
                                        grid_action_key: '',
                                        name: 'Remove Document',
                                        icon: 'fa fa-trash',
                                        row_data_required: 1,
                                        call_back: 'removeAttachment',
                                        grid_key : vm.grid.grid_key,
                                        client_key : vm.grid.client_key
                                    }
                                ];
                                if(vm.ActionNames && vm.ActionNames.length != 0){
                                    var newBatchAction = [];
                                    _.each(vm.batchAction, function(item){
                                        var isFound = false;
                                        _.each(vm.ActionNames, function(i){
                                            if(i.call_back == item.call_back){
                                                 isFound = true;
                                            }
                                        })
                                        if(isFound == false){
                                            newBatchAction.push(item);
                                             _.each(newBatchAction,function(item){
                                            GridBuilderFactory.saveGridAction(item);
                                        });
                                        }
                                    })
                                        if(_.find(vm.ActionNames,function(item){
                                            return item.call_back == 'addAttachment' || item.call_back == 'editAttachment' || item.call_back == 'removeAttachment'
                                            }));
                                   /*  else{
                                        _.each(vm.batchAction,function(item){
                                            GridBuilderFactory.saveGridAction(item);
                                        });
                                    }*/
                                }else{
                                    _.each(vm.batchAction,function(item){
                                        GridBuilderFactory.saveGridAction(item);
                                    });
                                    vm.getActionsNames();
                                }
                            }
                            }

                }
            else{
                _.remove(vm.ActionNames,function(item){
                    if(item.call_back == 'removeAttachment' || item.call_back=='addAttachment'){
                        vm.removeActionName(item.grid_action_key);
                    }
                    return item.call_back == 'removeAttachment' || item.call_back=='addAttachment'
                });
                _.remove(vm.grid.headers,function(item){
                    return (item.map==='edit_documentation' || item.map==='HASDOCUMENTS' || item.map==='doc_batch_id');
                });
            }

        };

        vm.saveGrid = function() {
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            console.log("vm.gridForm.$invalid " , vm.gridForm.$invalid);
            console.log("vm.gridForm " , vm.gridForm);
            if (vm.gridForm.$invalid) {
                vm.gridForm.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }
            if(vm.grid.headers){
                var hasAuditRecords = _.filter(vm.grid.headers,{audit_cells:"1"});
                if(hasAuditRecords && hasAuditRecords.length>0 && vm.grid.audit_cells ==0){
                    vm.grid.audit_cells = 1;
                }
            }
            if(vm.grid && (vm.grid.documentation==0 || vm.grid.documentation==null)){
                vm.grid.doc_header_key = null;
                vm.grid.doc_header_type = null;
            }
            if(vm.grid && (vm.grid.screen_validation == 0 || vm.grid.screen_validation == null)){
                vm.validationRules.filters = [];
                vm.grid.screen_validation_rules = [];
            }else{
                vm.grid.screen_validation_rules = [];
                console.log("rule",vm.validationRules.filters);
                _.each(vm.validationRules.filters,function(item){
                    vm.grid.screen_validation_rules.push({
                        id: item.id,
                        colAmount :item.headerColAmountSelecteObj.selected.map(a => a.value),//item.colAmount,
                        colMap: item.headercolMapSelectObj.selected.map(a => a.value),//item.colMap,
                        rule_type: item.rule_type,
                        buffer_size : item.buffer_size
                    })
                });

               vm.grid.screen_validation_rules = JSON.stringify(vm.grid.screen_validation_rules);
               console.log("screenValidation",vm.grid.screen_validation_rules);
            }
            vm.grid.headers = _.uniqBy(vm.grid.headers, v => [v.map, v.label].join());
            console.log('headers',vm.grid.headers);//_.uniqBy(vm.grid.headers, 'label');
            vm.crudLoading = true;
            GridBuilderFactory.saveGrid(vm.grid).then(
                function(responseData) {
                    vm.crudLoading = false;
                    $rootScope.$emit('gridUpdate', {});
                    if(responseData && responseData.grid_key_out && vm.new){
                        vm.new = false;
                        vm.grid.grid_key = responseData.grid_key_out;
                        vm.action_on = false;
                        vm.showSave = true;
                        generateApiKey();
                    }
                }
            );
        };

        vm.openModal = function (modalObj,_data) {

            vm.rowData = _data;

             var modalInstance = $uibModal.open({
                 animation: true,
                 templateUrl: modalObj.template,
                 controller: modalObj.controler,
                 size: modalObj.size,
                 backdrop: modalObj.backdrop,
                 keyboard: modalObj.keyboard,
                 resolve: {
                     rowData: function () {
                         return vm.rowData;
                     },
                 }
             });

             modalInstance.result.then(function () {
                 $rootScope.$broadcast('gtw.admin.gridBuilder.screenModalClose');
             }, function () {
                 $rootScope.$broadcast('gtw.admin.gridBuilder.screenModalClose');
             });
        };

        vm.batchAction = function(){
            var data = {
                client : vm.grid.client_key,
                grid_key : vm.grid.grid_key,
                getCall : vm.getActionsNames
            }
            var modalObj = {};
				modalObj.template = "app/components/admin/gridBuilder/batch-action-temp-main.html";
				modalObj.controler = "BatchActionCtrl as ctrl";
                modalObj.size = "md";
                modalObj.keyboard = false;
                modalObj.backdrop = "static";
				vm.openModal(modalObj,data);
        };
        // Drag and drop

        vm.isApplied = false;
        vm.rearrangeList ={ items: [], dragging: false};
        vm.applyNewOrder = function (){
            vm.grid.headers = [...vm.extraHeaders ,...vm.rearrangeList.items];
            vm.isApplied = true;
            // vm.grid.headers = [...vm.rearrangeList];
        }
        vm.cloneGridHeaders = function(){
            vm.rearrangeList.items = vm.grid.headers.filter(obj => {
                if(obj.map != 'object_id' && obj.map != 'OBJECT_ID'){
                    obj.selected = false;
                    return Object.create(obj);
                }
            });
            vm.extraHeaders = vm.grid.headers.filter(obj => obj.map == 'object_id' || obj.map == 'OBJECT_ID' );
        }
        vm.resetOldOrder = function () {
            if(vm.isApplied){
                AlertService.add("info", "Applied changes cann't be reverted. Please close the modal without saving and try again.",4000);
                return;
            }
            vm.cloneGridHeaders();
            // vm.rearrangeList= [...vm.grid.headers];
        }

        vm.getSelectedItemsIncluding  = function( item) {
            item.selected = true;
            console.log(" get selected items including ",vm.rearrangeList.items.filter(function(item) { return item.selected; }));
            return vm.rearrangeList.items.filter(function(item) { return item.selected; });
        };
        vm.onDragstart = function(event) {
            vm.rearrangeList.dragging = true;
            document.querySelector('li.dndPlaceholder').remove();
            // setTimeout(function(){debugger;}, 2000);
        };
        vm.onMoved = function() {
            vm.rearrangeList.items = vm.rearrangeList.items.filter(function(item) { return !item.selected; });
            console.log('on moved', vm.rearrangeList.items);
            vm.isApplied = false;
        };

        vm.onDrop = function( items, index) {
            angular.forEach(items, function(item) { item.selected = false; });
            vm.rearrangeList.items = vm.rearrangeList.items.slice(0, index)
                                                .concat(items)
                                                .concat(vm.rearrangeList.items.slice(index));
            console.log(vm.rearrangeList.items);
            return true;
        };

    }

    /****************************** START Batch select Action & screen Save *******************************/

    function batchActionCtrl($uibModalInstance, GridBuilderFactory, AlertService, $parse, $filter,rowData, USER_SETTINGS) {
        var vm = this;
        vm.data = rowData;
        console.log("batch rowData",vm.data);
        vm.call_back = {};
        vm.lazyLoadObj = {};
        vm.actionCallBackName = '';
        vm.userSettings = USER_SETTINGS;
        vm.gridScreenData = {};
        vm.gridScreenData.is_locked = "Y";
        vm.callBackDrpdownLoader = true;
        vm.getScreenModalNames = function(){
            var modalObj = {
                client_key : vm.userSettings.user.client.client_key
            }
            GridBuilderFactory.getScreenModalName(modalObj).then(function (response) {
                vm.callBackDrpdownLoader = false;
                vm.call_back = response.data.screenModalName;
                vm.lazyLoadObj.totalDrpList = vm.call_back;
                vm.lazyLoadObj.cachedDrpList = vm.call_back.slice(0, 10);
                if(vm.data.actionData && vm.data.actionData.call_back){
                    vm.gridActionData.callBackName = _.find(vm.call_back,{'modal_name':vm.data.actionData.call_back});
                    vm.actionCallBackName= _.find(vm.call_back,{'modal_name':vm.data.actionData.call_back});
                    // vm.gridActionData.callBackName = vm.actionCallBackName.modal_name;
                    vm.gridScreenData.modal_name = vm.actionCallBackName.modal_name;
                    vm.gridScreenData.controller_path = vm.actionCallBackName.modal_controler;
                    vm.gridScreenData.modal_size = vm.actionCallBackName.modal_size;
                    vm.gridScreenData.template_path = vm.actionCallBackName.modal_template;
                    vm.gridScreenData.modal_css_path = vm.actionCallBackName.modal_css_path;
                    vm.gridScreenData.modal_desc = vm.actionCallBackName.modal_desc;
                    vm.gridScreenData.screen_modal_key = vm.actionCallBackName.screen_modal_key;
                }
            	if (response.data.callSuccess === "1") {
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }

            });
        }

        console.log("callBack",vm.actionCallBackName);

         vm.getScreenModalNames();

        vm.gridActionData = {
            grid_action_key : (vm.data && vm.data.actionData) ? vm.data.actionData.grid_action_key : '',
            actionName: (vm.data && vm.data.actionData) ? vm.data.actionData.name : '',
            icon: (vm.data && vm.data.actionData) ? vm.data.actionData.icon : '',
            rawDataReq : (vm.data && vm.data.actionData) ? vm.data.actionData.row_data_required : '',
        }


        vm.title = (vm.data && vm.data.actionData && vm.data.actionData.grid_action_key != null) ? "Edit Batch Action" : "Add Batch Action";


        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function(tab){
			switch(tab){
                case 'gridAction':
                console.log("gridActiongridActiongridAction");
                    vm.gridActionSave();
					break;
				case 'screenModal':
                    console.log("screenModalscreenModalscreenModal");
                    vm.screenModalSave();
					break;
			}
		}


        vm.gridActionSave = function() {
            var modalObj = {
                grid_action_key: vm.gridActionData.grid_action_key,
                name: vm.gridActionData.actionName,
                icon: vm.gridActionData.icon,
                row_data_required: vm.gridActionData.rawDataReq,
                call_back: vm.gridActionData.callBackName.modal_name,
                grid_key : vm.data.grid_key,
                client_key : vm.data.client
            }

            GridBuilderFactory.saveGridAction(modalObj).then(function (response) {

            	if (response.data.callSuccess === "1") {
                    vm.data.getCall();
                    AlertService.add("success", "Grid Action saved successfully",4000);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }

            });
        }
        vm.screenModalSave = function(){
            var modalObj = {
                screen_modal_key : vm.gridScreenData.screen_modal_key,
                grid_action_key: vm.gridActionData.grid_action_key,
                modal_name : vm.gridScreenData.modal_name,
                modal_size : vm.gridScreenData.modal_size,
                modal_template : vm.gridScreenData.template_path,
                modal_controler : vm.gridScreenData.controller_path,
                modal_class : vm.gridScreenData.modal_css_path,
                modal_desc : vm.gridScreenData.modal_desc,
                client_key : vm.data.client,
                lock_required: vm.gridScreenData.is_locked

            }
            GridBuilderFactory.saveScreenModal(modalObj).then(function (response) {

            	if (response.data.callSuccess === "1") {
                    AlertService.add("success", "Grid Action saved successfully",4000);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }

            });
        }

    }
    /******************************ENDS Batch select Action & screen  *******************************/


    function gridListCtrl($rootScope,$scope,$state, $parse, ClientAdminFactory, $log,GridFactory,AlertService,$timeout,$uibModal,USER_SETTINGS) {

        var vm = this;
        vm.name = "Grid List";
        vm.gridData = {};
        vm.clients = [];
        vm.userSettings = USER_SETTINGS;
        vm.filter = {
            client_key:vm.userSettings.user.client.client_key
        };

        vm.addGrid = function(){
            var modalObj = {};
            modalObj.template = "app/components/admin/gridBuilder/grid-builder-tpl.html";
            modalObj.controler = "GridBuilderController as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.openModal(modalObj,{});
        };


        // get the clientList initail load of page
		/*vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
                // vm.clients = $parse('data.clientResult.clientList')(res);
            });
        };
        vm.getClientList();*/

        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            console.log("GRID WAS THIS DESTROYED ")
            gridUpdateListener();
        });

        vm.refresh = function(_params){
            getData();
        };

        vm.onClientChange = function() {
            getData();
        };

        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;

            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
                console.log("data grid Data: ",data);

                if (data.callSuccess === "1") {
                    console.log("data : ",data);
                    // vm.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", data.errorMessage);
                }
            });

        }



        var getUpDate = function(_params,_filterObj) {
            var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
            params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }








        $timeout(function() {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);




        $scope.ctrlFn = function(_callName,_data,_type) {


            var modalObj = {};
            modalObj.template = "app/components/admin/gridBuilder/grid-builder-tpl.html";
            modalObj.controler = "GridBuilderController as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";


            vm.openModal(modalObj,_data);
        };



        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };




    }




    return controllers;

});
