define([
    'angular'

], function () {
    'use strict';
    var services = angular.module('app.dashboard901MService', [])
        .factory("Dashboard901Factory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector', 'JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService,
                DataFiltersFactory, workspaceFactory, AlertService, $injector, JsonObjectFactory,GENERAL_CONFIG) {
                var dashboard901Factory = {};
                var returnObj = {};

                dashboard901Factory.getAdjustments = function (_baseURL,_data) {
                    var params = {
                        // "action_id": 287,
                        "action_code": "giagg2",
                        "combination_key": _data.combination_key
                    };

                    return JsonObjectFactory.getJSONObj(_baseURL+'/loadJsonObject', params).then(function (data) {
                        if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                            AlertService.add("error", data.errorMessage);
                            return false;
                        } else {

                            return data.jsonObject;
                        }
                    });
                };
               /*  dashboard901Factory.loadAttachments = function (_baseURL,parentKey, parentType) {
                    var params = {
                        parent_key: parentKey,
                        parent_type: parentType
                    };
                    return JsonObjectFactory.getJSONObj(_baseURL+'/loadFileAttachments', params);
                } */
                //

               /*  dashboard901Factory.saveAttachment = function (_url, _params) {
                    var promise = $http({
                        method: "post",
                        url: _url,
                        data: _params
                    }).then(function (response) {
                        return response.data;
                    });
                    return promise;
                };
 */
                dashboard901Factory.save901Adjustment = function (_callName, _data) {
                    console.log('printing data on 901 adjs save', _data);
                    
                    var json_arrOBJ = [{
                        combination_key: _data.rowData.COMBINATION_KEY,
                        adj_type: _data.colData.map,
                        adj_amt : (_data.colData.map === "POST_ADJ" ? 0 : _data.rowData[_data.colData.map]),
                        disallowed_ep_amt: _data.rowData.DISQUALIFIED_TAX_ADJUSTMENT,
                        posted_tax_adjustment: _data.rowData.REMAINING_901_ADJ,
                        signoff_y_n: _data.rowData.SIGNOFF_Y_N,
                        comments: _data.COMMENTS
                        
                    }];
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=908psr";
                    var jsonSettings = [{
                        group_trans_key: _data.rowData.GROUP_TRANS_KEY
                    }];
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = _.merge({}, GlobalService.globalParams, filterParams);
                    params = _.extend({ jsonSettings: JSON.stringify(jsonSettings)}, params);
                    params = _.extend({jsonObj: JSON.stringify(json_arrOBJ)}, params)

                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);

                           // if (!_data.rowData.GROUP_TRANS_KEY) {
                                var args = {
                                    COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY,
                                    gridFilter: {
                                        COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY
                                    }
                                };
                                $rootScope.$emit('gridUpdate', args);
                         //   }
                        } else{
                            if(data.errorMessage != null && data.callSuccess== 0)
                                AlertService.add("error",data.errorMessage,4000);
                            else
                                AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");

                            
                        }
                        
                    });

                }
                return dashboard901Factory;
            }
        ])
    return services;
});