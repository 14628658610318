define(
    ['angular'

    ],
    function () {
        'use strict';

        var controllers = angular.module(
            'app.ghostSchdmConfigCtrl', []).controller(
                'ghostSchdmConfigCtrl',
                ['$scope', '$rootScope', '$http', 'GlobalService',
                    '$uibModalInstance', 'ModalFactory',
                    'AlertService', 'rowData', 'gridData',
                    'workspaceFactory', 'JsonObjectFactory',
                    '$timeout', 'GENERAL_CONFIG', 'USER_SETTINGS',
                    'ghostSchdmConfigFactory',
                    ghostSchdmConfigCtrl])

        function ghostSchdmConfigCtrl($scope, $rootScope,
            $http, GlobalService, $uibModalInstance, ModalFactory,
            AlertService, rowData, gridData, workspaceFactory,
            JsonObjectFactory, $timeout, GENERAL_CONFIG, USER_SETTINGS,
            ghostSchdmConfigFactory,
        ) {

            var vm = this;
            vm.title = "Add Schdm Template ID";
            vm.formData = gridData;
            vm.selectedTemplate = "";
            vm.is_excluded = "N";

            ghostSchdmConfigFactory.getTemplatesData().then(response => {
                vm.templateData = response.jsonObject;
            })

            vm.cancel = function () {

                $uibModalInstance.dismiss('cancel');

            };

            vm.save = function () {
                vm.crudLoading = true;
                ghostSchdmConfigFactory.saveTemplate('Save_Schdm_Template_ID',{ rowData: { SCHDM_TEMPLATE_ID : vm.selectedTemplate.VALUE, IS_EXCLUDED : vm.is_excluded }}).then(
                     (result) => {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error",
                                result.errorMessage, 4000);
                            vm.crudLoading = false;

                        } else {
                            AlertService.add("success", "Update Success!", 4000);
                            vm.crudLoading = false;
                            $uibModalInstance.dismiss('cancel');
                            $rootScope
                                .$emit(
                                    'gridUpdate',
                                    {});
                        }
                    });

            }


    }
    return controllers;
});