define([
    'angular'
    
], function () {
    'use strict';
    
    var services =  angular.module('app.F-6252Service',[])
    .factory("F6252Factory", ['$q', '$http', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory',
        'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG',
        function ($q, $http, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory,
            workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG) {
    	
    	var factory = {};     	
    	factory.formConfigData = {};    	
    	factory.fetchF6252Details=function(data, sso_id){
    		var jcd_key = 0;
    		if(data.JCD_KEY == undefined){
    			jcd_key = 250;
    		}else jcd_key = data.JCD_KEY;
    		var params = { "action_code": 'de0fhr', "sso_id": sso_id, "tax_year": data.TAX_YEAR, "scenario": data.SCENARIO, "jcd_key": jcd_key, "combination_key": data.COMBINATION_KEY, "jcd_ta_key": null};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    		//	console.log("Now in the Service after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                //    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	
    	factory.saveForm6252 = function(final_data, clob_settings){
    	//	console.log("Clob_setting was done as:::::::::------------", clob_settings);
    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wjvmhl";
    		var json_settings = JSON.stringify(clob_settings);
    		var json_data = JSON.stringify(final_data);
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		
    		var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({json_data:json_data}, params);
            params =  _.extend({json_settings:json_settings}, params);
            params.process_name =  "form-6252-save";
          //  console.log(params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
    	}
    	
    	return factory;
    	
    }]);
    
    return services;
    
});