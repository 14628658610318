define([
    'angular',
    './maintainanceOwnerController',
    './maintainanceOwnerService'



], function () {
    'use strict';
    return angular.module('app.maintainanceOwner', ['app.maintainanceOwnerController','app.maintainanceOwnerService'] );

});