define([
    'angular',
    './Form8865SchdK1Controllers',
    './Form8865SchdK1Services'


], function () {
    'use strict';
    return angular.module('app.F8865SchdK1', ['app.Form8865SchdK1Controllers','app.Form8865SchdK1Services'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {


        }]);

});