define([
    'angular',
    './relatedPartyModalController',
    './relatedPartyModalServce'


], function () {
    'use strict';
    return angular.module('app.relatedPartyModal', ['app.relatedPartyModalController','app.relatedPartyModalServce'] );


});