/**
 * Created by 212544474 on 2/17/2016.
 */

define(
		[ 'angular'],
		function() {
			'use strict';

			var services = angular
					.module('app.entitydetailsService', [])

					.factory("EntityDetailsFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'AlertService', '$injector', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG','USER_SETTINGS',
						function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, AlertService, $injector, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,USER_SETTINGS) {
							var entityDetailsFactory = {};
							entityDetailsFactory.url = {};
							entityDetailsFactory.url.get8858Details = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=drhn7z";
							entityDetailsFactory.url.saveentityDetails = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=kxmkf2";
							entityDetailsFactory.url.getGiltiCGKDetails = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=00ya1v";

						
							entityDetailsFactory.getActiveScreen = function() {
								return workspaceFactory.activeScreen.screen_key;
							};

							entityDetailsFactory.get8858Entities = function(_data) {
								var params = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
								params =  _.extend({}, _data, params);
								console.log(params);
								var promise = $http({
									method: "post",
									url: entityDetailsFactory.url.get8858Details,
									data: params
								}).then(function (response) {
									//var status = response.status;
									return response.data;
								});
				
								return promise;
							};

							entityDetailsFactory.getGiltiCGK = function() {
								var params = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
								params =  _.extend({}, params);
								console.log(params);
								var promise = $http({
									method: "post",
									url: entityDetailsFactory.url.getGiltiCGKDetails,
									data: params
								}).then(function (response) {
									//var status = response.status;
									return response.data;
								});
				
								return promise;
							};
				
							entityDetailsFactory.updateEntityDetails = function(_data){
								console.log("data", _data);

								var defer = $q.defer();
								var service = $injector.get('JsonObjectFactory');								
								var clobData = _data;	
								var clobSettings = workspaceFactory.activeScreen.filters.getFilterParams();
								clobSettings.jcd_key =  GlobalService.globalParams.jcd_key;								
									
								var ssoId = USER_SETTINGS.user.sso_id;
								
								
								var params = {sso_id: ssoId, process_name: 'SAVE_ENTITY_DETAILS_8858_FILER_CLASS',
												jsonSettings: JSON.stringify(clobSettings), jsonObj: JSON.stringify(clobData)
											};
								params = _.merge({},  GlobalService.globalParams , params);
								console.log('save params', params);

								/* service.saveJSON(entityDetailsFactory.url.saveentityDetails,params).then(function (data) {
									if (data.callSuccess === "1") {						
										AlertService.add("success", "Data saved Successfully.",3000);
										var args = {tax_year:2020, scenario:40, jcd_key:250, filing_key:1, gridFilter: {object_id:clobData.object_id}};
										//var args = {};
										$rootScope.$broadcast('gridUpdate', args);
										
										defer.resolve(data);
										}
										else {
										AlertService.add("error", data.errorMessage, 4000);
										defer.reject(data);
									}},function(data){
									defer.reject(data);
								});  */
								var promise = $http({
									method: "post",
									url: entityDetailsFactory.url.saveentityDetails,
									data: params
								}).then(function (response) {
									return response.data;
								});
								//return defer.promise;
								return promise;
							}
							return entityDetailsFactory;
						}]);
			        
					return services;

		});