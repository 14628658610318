define([
    'angular',
    './batchMonitoringController',
    './batchMonitoringService'



], function () {
    'use strict';
    return angular.module('app.batchMonitoring', ['app.batchMonitoringController','app.batchMonitoringService'] );


});