const { element } = require("angular");

define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F1065ScheduleKCtrl', [])
		.controller('F1065ScheduleKController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','$filter',F1065ScheduleKController])
		.directive('onlyDigitsDecimal', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.replace(/[^0-9.-]/g, '');

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
							}
							let lhs="",rhs="";
							if(digits.length)
								[lhs,rhs] = digits.split('.');
							if(rhs)
								return parseFloat(digits).toFixed(rhs.length);
							else
								return parseFloat(digits);
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        })
    function F1065ScheduleKController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,$filter) {
		var vm = this;
		
		vm.modalTitle = "Schedule K1 ";

		vm.tax_year = rowData.TAX_YEAR;

		vm.entityList = {
			"Tax Year": rowData.TAX_YEAR,
			"LEID": rowData.LEID,
			"LE Name": rowData.LE_NAME
		};

		vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = vm.modalTitle + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';

		vm.formattedScheduleKData = {};
		vm.distinctLegalEntities = []; 
		vm.singleItemSetting = { 
			selectionLimit: 1,
			enableSearch: true,
            smartButtonMaxItems: 1, 
            smartButtonTextConverter: function(itemText, originalItem) { 
                return itemText; 
            } 
		};
		vm.arePartnerPresent = false;
		vm.totalForJ = {'PROFIT(B)':"",'LOSS(B)':"",'CAPITAL(B)':"",'PROFIT(E)':"",'LOSS(E)':"",'CAPITAL(E)':""};
		// vm.multipleItemSetting = { 
		// 	enableSearch: true,
        //     smartButtonMaxItems: 1, 
        //     smartButtonTextConverter: function(itemText, originalItem) { 
        //         return itemText; 
        //     } 
		// };

		// vm.dateOptions={}; -- varialble for calender options
		// comment

		vm.selectedGeneralPartner=[];
		vm.multiselectEvents = {
			onItemSelect: function (item) {
				vm.formattedScheduleKData[item.lineNumber].forEach(element => {
					if(item.id == element.LEID){
						element.ATTRIB_VALUE = "true";
					}else{
						element.ATTRIB_VALUE = "false";
					}
				});
               console.log(item);
			},
			onItemDeselect: function (item) {
				vm.formattedScheduleKData[item.lineNumber].forEach(element => {
					element.ATTRIB_VALUE = "false";
				});
			}
		};
		const init = function(){
			loadScheduleKData();
			loadScheduleKLine20();
		};

		// load the form data
		let loadScheduleKData = function (){
			vm.showFormLoader = true;
			var params = { 
				"action_code":"kdwdr5",
				"activity_key":"6170",
				"is_locked":"",
				"jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ 
				"jcd_ta_key": 5321,
				"me_by_entity":"",

				"scenario": rowData.SCENARIO_KEY, 
				"screen_type":"grid",
				"tax_year": rowData.TAX_YEAR, 
				
				// "action_id": 31274, 
				"combination_key": rowData.COMBINATION_KEY, 
				"schedule_name": "K1",
				
			};
			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
				.then(function (response) {
					vm.showFormLoader = false;
					let data = response.jsonObject;
					vm.original_API_DATA = angular.copy(data);
					transformData(data);
					
				});
		};
		// end form data -K

		let loadScheduleKLine20 = function (){
			vm.showLine20Loader = true;
			var params = { 
				"action_code":"aj0tnt",
				"activity_key":"31334",
				"is_locked":"",
				"jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ 
				"jcd_ta_key": 5321,
				"me_by_entity":"",

				"scenario": rowData.SCENARIO_KEY, 
				"screen_type":"grid",
				"tax_year": rowData.TAX_YEAR, 
				
				// "action_id": 31274, 
				"combination_key": rowData.COMBINATION_KEY, 
				"schedule_name": "K1",
				
			};

			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
			.then(function (response) {
				vm.showLine20Loader = false;
				vm.showLine14Loader = false;
				let data = response.jsonObject;
				let line14Data = $filter("filter")(data, {K1_ACCT:"1065_K1_14"});
				let line20Data = $filter("filter")(data, {K1_ACCT:"1065_K1_20"});

				//console.log('Line 14 data : '+ $filter("filter")(data, {K1_ACCT:"1065_K1_14"}));
				//console.log('Line 20 data : '+ $filter("filter")(data, {K1_ACCT:"1065_K1_20"}));
				vm.original_Line14_DATA = angular.copy(line14Data);
				vm.original_Line20_DATA = angular.copy(line20Data);

				console.log("line 14 Data", line14Data);
				transformLine14Data(line14Data);

				console.log("line 20 Data", line20Data);
				transformLine20Data(line20Data);
				
			}).catch(function(error){
				console.error(error);
				vm.showLine14Loader = false;
				vm.showLine20Loader = false;
			});

		}
		
		let transformData = function(data){
			console.log("JSONOBJECTFACTORY schedule k1", data);
			let lineNo = "";
			let lineData; 
			let generalSelectedLEID ;
			for(let i=0 ; i<data.length ; i++){
				lineNo = data[i].LINE_NO ;
				lineData = data[i];
				// if(lineNo == 'G'){
				// 	if(lineData.ATTRIB_VALUE == "true"){
				// 		// save leid 
				// 		generalSelectedLEID = lineData.LEID;
						
				// 	}else{
				// 		lineData.ATTRIB_VALUE = "false";
				// 	}
				// }
				if(lineNo == "J1"){
					lineData['DECREASE'] = (lineData['DECREASE'] == "true");
				}
				if(lineNo == 'K1'){
					lineData['K1_FINAL'] = lineData['K1_FINAL'] == "true" ;
					lineData['LIABILITY'] = lineData['LIABILITY'] == "true" ;
					//  d instanceof Date && !isNaN(d); -- valid date
					try {
						lineData['TAXYEARBEGIN'] = new Date(lineData['TAXYEARBEGIN']);
						if(!(lineData['TAXYEARBEGIN'] instanceof Date && !isNaN(lineData['TAXYEARBEGIN']))){
							lineData['TAXYEARBEGIN'] = null;
						}
					}catch(e){
						lineData['TAXYEARBEGIN'] = null;
					}
					try {
						lineData['TAXYEAREND'] = new Date(lineData['TAXYEAREND'] );
						if(!(lineData['TAXYEAREND'] instanceof Date && !isNaN(lineData['TAXYEAREND']))){
							lineData['TAXYEAREND'] = null;
						}
					}catch(e){
						lineData['TAXYEAREND'] = null;
					}

				}
				if(lineNo == "M" ){
					lineData['YES'] = lineData['YES'] == "true";
					lineData['NO'] = lineData['NO'] == "true";
				}
				if(vm.tax_year <= 2020) {
					if(lineNo == "21" || lineNo == "22" || lineNo == "D" || lineNo == "G" || lineNo == "G1"){
						lineData['ATTRIB_VALUE'] = (lineData['ATTRIB_VALUE'] == "true");
					}
				}
				else if(vm.tax_year > 2020) {
					if(lineNo == "16" || lineNo == "22" || lineNo == "23" || lineNo == "D" || lineNo == "G" || lineNo == "G1"){
						lineData['ATTRIB_VALUE'] = (lineData['ATTRIB_VALUE'] == "true");
					}
				}
				if(lineNo == "H1"){
					lineData['ATTRIB_VALUE'] = (lineData['DOMESTIC'] == "true");
					lineData['DOMESTIC_EDIT_FLAG'] = (lineData['DOMESTIC_EDIT_FLAG'] == "true");
				}
				if(lineNo == "H1(2)"){
					lineData['ATTRIB_VALUE'] = (lineData['FOREIGN'] == "true");
					lineData['FOREIGN_EDIT_FLAG'] = (lineData['FOREIGN_EDIT_FLAG'] == "true");
				}
				if(lineNo == "H2"){
					lineData['ATTRIB_VALUE'] = (lineData['DE'] == "true");
				}
				if(lineNo == "H2(1)"){
					lineData['ATTRIB_VALUE'] = lineData['TIN'];
				}
				if(lineNo == "H2(2)"){
					lineData['ATTRIB_VALUE'] = lineData['NAME'];
				}
				if(lineNo == "I1"){
					lineData['ATTRIB_VALUE'] = lineData['TYPE'] ;
				}
				if(lineNo == "I2"){
					lineData['ATTRIB_VALUE'] = (lineData['RETIREMENT'] == "true");
				}
				// if(lineNo == "J1"){
				// 	lineData['PROFIT(B)'] = parseFloat(lineData['PROFIT(B)'] );
				// 	lineData['LOSS(B)'] = parseFloat(lineData['LOSS(B)'] );
				// 	lineData['CAPITAL(B)'] = parseFloat(lineData['CAPITAL(B)'] );
				// 	lineData['PROFIT(E)'] = parseFloat(lineData['PROFIT(E)'] );
				// 	lineData['LOSS(E)'] = parseFloat(lineData['LOSS(E)'] );
				// 	lineData['CAPITAL(E)'] = parseFloat(lineData['CAPITAL(E)'] );
				// }
				if (vm.formattedScheduleKData[lineNo]){
					vm.formattedScheduleKData[lineNo].push(lineData);
				}else{
					vm.formattedScheduleKData[lineNo] = [lineData];
				}
			}
			console.log("formated vm.formattedScheduleKData", vm.formattedScheduleKData);
			vm.distinctLegalEntities = vm.getMultiselectOptionArray('G');
			vm.arePartnerPresent = (vm.distinctLegalEntities.length > 0 ) ? true :false;
			vm.distinctLegalEntities.forEach(le =>{
				if(le.id == generalSelectedLEID){
					vm.selectedGeneralPartner.push(le);
				}
				
			});
			['PROFIT(B)','LOSS(B)','CAPITAL(B)','PROFIT(E)','LOSS(E)','CAPITAL(E)'].forEach(key =>{
				sumJCol(key);
			});
		};

		let transformLine14Data = function(data){
			$scope.line14Data = {};
			$scope.distinctCodes_14 = [];

			if(!data || data.length == 0){
				// AlertService.add("info", "Line 20 is empty");
			}

			for(let i=0; i< data.length;i++){
				vm.first_leid_line14 = data[0]["LEID"];
				data[i]["ADJ_AMT_1"] = Math.round(data[i]["ADJ_AMT_1"]);
				if(data[i]["LEID"] == vm.first_leid_line14){
					$scope.distinctCodes_14.push(data[i]["ATTRIB_TEXT_VALUE"]);
				}
				if(!$scope.line14Data[data[i]["LEID"]]){
					$scope.line14Data[data[i]["LEID"]] = [data[i]];
				} else {
					$scope.line14Data[data[i]["LEID"]].push(data[i]);
				}

			}

			$scope.line14Data_original = angular.copy($scope.line14Data);
			vm.table_row_14 = 4 + Object.keys($scope.line14Data).length;
			vm.line14_valueChanges();


			console.log("transformed line 14 ", $scope.line14Data , $scope.distinctCodes_14, $scope.variances_14, $scope.line14_total);
		};

		let transformLine20Data = function(data){
			$scope.line20Data = {};
			$scope.distinctCodes_20 = [];
			let copyMapper = {};
			
			if(!data || data.length == 0){
				// AlertService.add("info", "Line 20 is empty");
			}
			let copyCounter = 0;
			for(let i=0; i< data.length;i++){
				vm.first_leid_line20 = data[0]["LEID"];	
				data[i]["ADJ_AMT_1"] = Math.round(data[i]["ADJ_AMT_1"]);
				if(data[i]["LEID"] == vm.first_leid_line20){
					$scope.distinctCodes_20.push(data[i]["ATTRIB_TEXT_VALUE"]);
				}
				if(!$scope.line20Data[data[i]["LEID"]]){
					$scope.line20Data[data[i]["LEID"]] = [data[i]];
				} else {
					// RM-24504
					if( copyMapper[data[i]["LE_NAME"]] && $scope.line20Data[copyMapper[data[i]["LE_NAME"]]][0]["LEID"] == data[i]["LEID"]) {
						$scope.line20Data[copyMapper[data[i]["LE_NAME"]]].push(data[i]);
					}
					else if (
						$scope.line20Data[data[i]["LEID"]][0]["LEID"] == data[i]["LEID"] && 
						$scope.line20Data[data[i]["LEID"]][0]["TP_KEY"] == data[i]["TP_KEY"] && 
						$scope.line20Data[data[i]["LEID"]][0]["LE_NAME"] == data[i]["LE_NAME"]
					) {
						$scope.line20Data[data[i]["LEID"]].push(data[i]);
					}
					else {
						$scope.line20Data[data[i]["LEID"]+`(${++copyCounter})`]=[data[i]];
						copyMapper[data[i].LE_NAME] = data[i]["LEID"]+`(${copyCounter})`;
					}
				}
				
			}

			$scope.line20Data_original = angular.copy($scope.line20Data);
			vm.table_row_20 = 4 + Object.keys($scope.line20Data).length;
			vm.line20_valueChanges();
			
			
			console.log("transformed line 20 ", $scope.line20Data , $scope.distinctCodes_20, $scope.variances_20, $scope.line20_total);
		};

		vm.line14_valueChanges = function (){

			$scope.variances_14 =  Array.from({length: $scope.distinctCodes_14.length}, (v, i) => 0) ;
			$scope.line14_total = Array.from({length: $scope.distinctCodes_14.length}, (v, i) => 0) ;

			for (const leid in $scope.line14Data) {

				for(let index =0; index < $scope.line14Data[leid].length;index++){

					$scope.line14_total[index] +=$scope.line14Data[leid][index].ADJ_AMT_1 ;
					$scope.variances_14[index] = $scope.line14_total[index] - $scope.line14Data[leid][index]["CODE_VALUE"] ;

					if($scope.line14Data[leid][index].ADJ_AMT_1 !=  $scope.line14Data_original[leid][index].ADJ_AMT_1
						&& $scope.line14Data[leid][index].ADJ_AMT_2 == 0){
						$scope.line14Data[leid][index].ADJ_AMT_2 = 1;
					}
				}
			}

		};

		vm.line20_valueChanges = function (){

			$scope.variances_20 =  Array.from({length: $scope.distinctCodes_20.length}, (v, i) => 0) ;
			$scope.line20_total = Array.from({length: $scope.distinctCodes_20.length}, (v, i) => 0) ;

			for (const leid in $scope.line20Data) {
				
				for(let index =0; index < $scope.line20Data[leid].length;index++){

					$scope.line20_total[index] +=$scope.line20Data[leid][index].ADJ_AMT_1 ;
					$scope.variances_20[index] = $scope.line20_total[index] - $scope.line20Data[leid][index]["CODE_VALUE"] ;

					if($scope.line20Data[leid][index].ADJ_AMT_1 !=  $scope.line20Data_original[leid][index].ADJ_AMT_1 
						&& $scope.line20Data[leid][index].ADJ_AMT_2 == 0){
							$scope.line20Data[leid][index].ADJ_AMT_2 = 1;
					}
				}
			}

		};

		let findLengthofDecimal = function(leid , index ){
			let lenghtOfDecimal = 0;
			let num = ""+$scope.line20Data[leid][i].ADJ_AMT_1;
			if(num.length >1 && num.split('.')[1].length>lenghtOfDecimal){
				lenghtOfDecimal =num.split('.')[1].length;
			}
			num = ""+line20_total[index];
			if(num.length >1 && num.split('.')[1].length>lenghtOfDecimal){
				lenghtOfDecimal =num.split('.')[1].length;
			}
			return lenghtOfDecimal;
		}

		vm.getMultiselectOptionArray = function(lineNumber){
			let multiselectArray = [];
			if(!vm.formattedScheduleKData || !vm.formattedScheduleKData[lineNumber])
				return multiselectArray;

			vm.formattedScheduleKData[lineNumber].forEach(element => {
				multiselectArray.push({
						"id":element.LEID,
						"lineNumber":lineNumber,
						"label":element.LEID+ " - " + element.LE_NAME
				});
			});
			console.log("distinct lE", multiselectArray);
			return multiselectArray;
		};

		vm.setLineValueForAll = function(key) {
			const value = vm.formattedScheduleKData[key][0].ATTRIB_VALUE;
			vm.formattedScheduleKData[key].forEach(element => {
				element.ATTRIB_VALUE = value;
			});

			console.log("after line set C", vm.formattedScheduleKData);
		};

		vm.toggleH1 = function(index, type){
			let otherType = "H1(2)";
			if(type !== 'H1'){
				otherType = "H1";
			}
			vm.formattedScheduleKData[type][index].ATTRIB_VALUE = 'Y';
			vm.formattedScheduleKData[otherType][index].ATTRIB_VALUE = 'N';

		}

		vm.unselectOther = function(index, type){
			if(type == 'YES'){
				vm.formattedScheduleKData['M'][index]['NO'] = false;
			}else {
				vm.formattedScheduleKData['M'][index]['YES'] = false;
			}
		};

		vm.unselectOtherG = function(lineNumber, index){
			if(lineNumber == 'G'){
				vm.formattedScheduleKData['G1'][index]['ATTRIB_VALUE'] = false;
				for(let i=0 ; i< vm.formattedScheduleKData['G1'].length ;i++){
					if(i!=index && !vm.formattedScheduleKData['G'][i]['ATTRIB_VALUE']){
						vm.formattedScheduleKData['G'][i]['ATTRIB_VALUE'] = false;
						vm.formattedScheduleKData['G1'][i]['ATTRIB_VALUE'] = true;	
					}
					
				}
			}else {
				vm.formattedScheduleKData['G'][index]['ATTRIB_VALUE'] = false;
			}
		};
		vm.unselectOtherH = function(lineNumber, index){
			if(lineNumber == 'H1'){
				vm.formattedScheduleKData['H1(2)'][index]['ATTRIB_VALUE'] = false;				
			}else {
				vm.formattedScheduleKData['H1'][index]['ATTRIB_VALUE'] = false;
			}
		};

		vm.validationForJ =  function (lineNumber, index, key){
			let lhs,rhs;
			// if(vm.formattedScheduleKData[lineNumber][index][key] >100 || vm.formattedScheduleKData[lineNumber][index][key] <0 ){
			// 	AlertService.add("error", "Value for "+ key + "cannot be less than 0 or greater than 100");
			// }
			[lhs,rhs] = (""+vm.formattedScheduleKData[lineNumber][index][key]).split(".");
			
			if( rhs ) {
				rhs = rhs.substring(0,6);				
				vm.formattedScheduleKData[lineNumber][index][key] = lhs + "." + rhs; 
			}
			
			sumJCol(key);
		}

		const sumJCol = function(key){
			let sum=0;
			for(let i=0 ; i < vm.formattedScheduleKData['J1'].length ;i++){
				sum += parseFloat(vm.formattedScheduleKData['J1'][i][key]?vm.formattedScheduleKData['J1'][i][key] : "0") * 1000000;
			}
			
			vm.totalForJ[key] = parseFloat(sum/1000000);
		}

		vm.checkK1Dates = function(lineNumber,index,dateType){
			
			if(vm.formattedScheduleKData[lineNumber][index]['TAXYEARBEGIN'] > vm.formattedScheduleKData[lineNumber][index]['TAXYEAREND'] && vm.formattedScheduleKData[lineNumber][index]['TAXYEAREND']){
				AlertService.add("error", "Value for Tax Year Begin cannot be greater than  Tax Year End");
			}
		}
		
		let converDateToString = function(date) {
			if(date== null) return "";
			date = new Date(date);
			var mm = date.getMonth() + 1; // getMonth() is zero-based
			var dd = date.getDate();
		  
			return [(mm>9 ? '' : '0') + mm +'/',
					(dd>9 ? '' : '0') + dd +'/',
					date.getFullYear()
				   ].join('');
		};

		vm.save = function(){
			console.log("sve is clicked for k1",  vm.formattedScheduleKData);

			if(!isValidSaveJson()){
				return ;

			}

			let jsonObj = JSON.stringify(prepareSaveJson());

			let jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY || rowData.SCENARIO,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                // "trans_type_key": data[0].editrows[0][0].trans_type_key
			};
			
			jsonSettings = JSON.stringify(jsonSettings);


			let params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
			params.process_name = 'FORM_SAVE_1065_SCHK1';//workspaceFactory.activeScreen.label;
			const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tkgaam";
			//call save service
			vm.isSaving = true;
			var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
				vm.isSaving = false;
                var status = response.status;
                console.log('------------------status is: --------------------------', status);
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage );
                }
                return response.data;
            });

		};

		const isValidSaveJson = function(){
			let isValid = true ;
			// ['PROFIT(B)','LOSS(B)','CAPITAL(B)','PROFIT(E)','LOSS(E)','CAPITAL(E)'].forEach(key =>{
			// 	let sum = 0;
			// 	for(let i=0 ; i < vm.formattedScheduleKData['J1'].length ;i++){
			// 		sum += parseInt(vm.formattedScheduleKData['J1'][i][key]);
			// 	}
			// 	if(sum != 100){
			// 		AlertService.add('error', "Sum of each column must be equal to 100 : "+ key );
			// 		isValid =false;
			// 	}
			// });
			vm.formattedScheduleKData['G'].forEach((value, index) => {
				if (value.ATTRIB_VALUE == false && vm.formattedScheduleKData['G1'][index].ATTRIB_VALUE == false) {
					AlertService.add('error', "Type of Partner (General or Limited) must be selected under Item G" );
					isValid =false;
				}
			})
			

			for (let key in vm.totalForJ){
				if (vm.totalForJ[key] != 100){
					AlertService.add('error', "Sum of each column must be equal to 100 : "+ key );
					isValid =false;
				}
			}

			for(let i=0 ; i<vm.formattedScheduleKData['K1'].length ; i++){
				
				let elem = vm.formattedScheduleKData['K1'][i];
				if(!elem['TAXYEARBEGIN'] || !elem['TAXYEAREND']){
					AlertService.add("error", "Date cannot be empty");
					isValid = false;
					return false;
				}

				if(elem['TAXYEARBEGIN'] > elem['TAXYEAREND']){
					AlertService.add("error", "Value for Tax Year Begin cannot be greater than  Tax Year End : " + elem.LEID);
					isValid =false;
					return false;
				}

			}

			if(!$scope.variances_14){
				AlertService.add('error', "Line 14 - variance is null ");
				return false;
			}
			for(let i=0 ;i<$scope.variances_14.length;i++){
				if($scope.variances_14[i] != 0 ){
					AlertService.add('error', "Line 14 - variance is not zero ");
					return false;
				}
			}

			if(!$scope.variances_20){
				AlertService.add('error', "Line 20 - variance is null ");
				return false;
			} 
			for(let i=0 ;i<$scope.variances_20.length;i++){
				if($scope.variances_20[i] != 0 ){
					AlertService.add('error', "Line 20 - variance is not zero ");
					return false;
				}
			}
			
			return isValid;

		};

		const prepareSaveJson = function(){
			let saveJSON = [];
			
			for(let i=0 ; i < vm.formattedScheduleKData['K1'].length ;i++){
				saveJSON.push({
					"COMBINATION_KEY": vm.formattedScheduleKData['K1'][i].COMBINATION_KEY,
					"LEID": vm.formattedScheduleKData['K1'][i].LEID,
					"LE_NAME": vm.formattedScheduleKData['K1'][i].LE_NAME,
					"VALUES": [],
					"ALLOC_JSON":getAllLineSaveArray(vm.formattedScheduleKData['K1'][i].LEID)
				});
				for (var lineNo in vm.formattedScheduleKData){
					let temp = angular.copy(vm.formattedScheduleKData[lineNo][i]);
					
					if(vm.tax_year > 2020) {
						if(lineNo == '16' || lineNo == '23' || lineNo == '22' || lineNo == "H1" || lineNo == "H1(2)" || lineNo == "H2" || lineNo == "I2" || lineNo == "G" || lineNo == "G1"){
							temp.ATTRIB_VALUE += "";
						}
					}
					else if(vm.tax_year <= 2020) {
						if(lineNo == '21' || lineNo == '22' || lineNo == "H1" || lineNo == "H1(2)" || lineNo == "H2" || lineNo == "I2" || lineNo == "G" || lineNo == "G1"){
							temp.ATTRIB_VALUE += "";
						}
					}

					if(lineNo == 'M'){

						let temp_yes = angular.copy(temp);
						temp_yes.ATTRIB_NAME = "YES";
						temp_yes.ATTRIB_VALUE = ""+temp_yes[temp_yes.ATTRIB_NAME];
						
						let temp_no = angular.copy(temp)
						temp_no.ATTRIB_NAME = "NO";
						temp_no.ATTRIB_VALUE = ""+temp_no[temp_no.ATTRIB_NAME] ;

						saveJSON[i].VALUES.push(temp_yes);
						saveJSON[i].VALUES.push(temp_no);
						continue;
					}
					if(lineNo == "N"){
						let temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "BEGINNING";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "ENDING";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);
						continue;
					}
					if(lineNo == "J1"){
						let temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "PROFIT(B)";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);
						
						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "LOSS(B)";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "CAPITAL(B)";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "PROFIT(E)";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "LOSS(E)";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "CAPITAL(E)";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "DECREASE";
						temp_1.ATTRIB_VALUE = ""+temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);
						
						continue;
					}
					if(lineNo == "K1"){
						temp['TAXYEARBEGIN'] = converDateToString( vm.formattedScheduleKData[lineNo][i]['TAXYEARBEGIN']);
						temp['TAXYEAREND'] = converDateToString( vm.formattedScheduleKData[lineNo][i]['TAXYEAREND']);
						
						let temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "NONRECOURSE";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "QUALIFIED";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "RECOURSE";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "NONRECOURSE_END";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "QUALIFIED_END";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "RECOURSE_END";
						temp_1.ATTRIB_VALUE = temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "LIABILITY";
						temp_1.ATTRIB_VALUE = ""+temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "K1_FINAL";
						temp_1.ATTRIB_VALUE = ""+temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "TAXYEARBEGIN";
						temp_1.ATTRIB_VALUE = ""+temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						 temp_1 = angular.copy(temp);
						temp_1.ATTRIB_NAME = "TAXYEAREND";
						temp_1.ATTRIB_VALUE = ""+temp_1[temp_1.ATTRIB_NAME];
						saveJSON[i].VALUES.push(temp_1);

						continue;
					}
					saveJSON[i].VALUES.push(temp);
				}
				
			}

			// saveJSON[0].ALLOC_JSON = getLine20SaveArray();

			console.log("Save JSON from prepareJSON", saveJSON);
			return saveJSON;
		};

		let getLine14SaveArray = function(leid){
			let line14Array = [];
			line14Array = line14Array.concat($scope.line14Data[leid]);
			return line14Array;
		};

		let getLine20SaveArray = function(leid){
			let line20Array = [];
			line20Array = line20Array.concat($scope.line20Data[leid]);
			return line20Array;
		};

		let getAllLineSaveArray = function(leid) {
			let allLineArray = [];

			let line14Values = getLine14SaveArray(leid);
			let line20Values = getLine20SaveArray(leid);

			for(let x = 0; x < line14Values.length; x++) {
				allLineArray.push(line14Values[x]);
			}
			for(let y = 0; y < line20Values.length; y++) {
				allLineArray.push(line20Values[y]);
			}
			return allLineArray;
		}

		vm.reset = function (){
			vm.formattedScheduleKData = {};
			vm.selectedGeneralPartner=[];
			vm.distinctLegalEntities = [];
			let data = angular.copy(vm.original_API_DATA);
			let line20Data = angular.copy(vm.original_Line20_DATA);
			transformData(data);
			transformData(line20Data);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		
		init();
		//Dynamic Tabset Code
		vm.tabs = [
	        {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Sch-K/F1065-scheduleKEntry.html", active: true },
	        // {id: 2, name: "Audit", url: "app/components/partnership/F1065-Sch-K/F1065-scheduleKVersion.html", active: false}
	    ];
    }
});