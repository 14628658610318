'use strict';

    var directives =  angular.module('app.efileDirectives',[])
    .directive('filesModel', ['$parse', function($parse) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var model = $parse(attrs.filesModel);
                var modelSetter = model.assign;
    
                element.bind('change', function() {
                    scope.$apply(function() {
                        modelSetter(scope, element[0].files);
                    })
                })
            }
        }
    }])
    .directive('ifHasAccess',["EfileFactory",function(EfileFactory) {


        return{
            restrict:'A',
            link:function(scope,element,attr){

                if(!EfileFactory.hasAccess(attr.ifHasAccess)){
                    element.remove();
                }
            }
        }
    }]);
