define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.trueUpTaxesService',[])

        .factory("TrueUpTaxesFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', '$parse','AlertService','$injector','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', 
        function($q, $http, $stateParams,$timeout, $rootScope, $log, $parse,AlertService,$injector,GlobalService,DataFiltersFactory,workspaceFactory,JsonObjectFactory,GENERAL_CONFIG) {
            var trueUpTaxesFactory = {};
           
            trueUpTaxesFactory.getEntities = function(tax_year, scenario, jcd_key, val) {
                var params = {
                    "action_code": "xmgv96",
                    "tax_year": tax_year,
                    "scenario": scenario,
                    "jcd_key": jcd_key,
                    "search_string": val
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            trueUpTaxesFactory.getTLCAmount = function(tax_year, combination_keys) {
                var params = {
                    "action_code": "yu78eh",
                    "tax_year": tax_year,
                    "combination_keys": combination_keys
                };
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            trueUpTaxesFactory.getFirTaxAmt = function(tax_year, combination_keys) {
                var params = {
                    "action_code": "ivwted",
                    "tax_year": tax_year,
                    "combination_keys": combination_keys
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            trueUpTaxesFactory.getCurrencies = function(tax_year)  {
	            var params = {
	            		"tax_year": tax_year
	            };
	            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3ucwjb", params)
	            .then(function(data) {
		            if (data.callSuccess === "1") {
		            	return data.jsonObject;
		            }
	             });
	         };
            
            trueUpTaxesFactory.getTaxyears = function(tax_year,leid,cdr_no) {
                var params = {
                    "action_code": "aeihoi",
                    "tax_year": tax_year,
                    "selectedEntity": leid,
                    "selectedCdr": cdr_no
                };
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            trueUpTaxesFactory.getTrueUpTaxes = function(tax_year,combination_key,trans_header_key,trans_details_key) {
                var params = {
                    "action_code": "29ylov",
                    "tax_year": tax_year,
                    "combination_key": combination_key,
                    "trans_header_key":trans_header_key,
                    "trans_details_key":trans_details_key
                };
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
             var returnObj = {};
            trueUpTaxesFactory.url ={};
            trueUpTaxesFactory.url.saveTaxTrueUpSignoff = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=l6zqof";
            trueUpTaxesFactory.saveTaxTrueUpSignoff = function(_callName ,_data){
                   var rowData = _data.rowData;
	               returnObj.combination_key = _data.rowData.COMBINATION_KEY;
				   var service = $injector.get('JsonObjectFactory');
	               var defer = $q.defer(); 
	               var jsonObj = [{
	            	 combination_key:_data.rowData.COMBINATION_KEY,
	            	 chart_key:_data.rowData.CHART_KEY,
	                trans_header_key:_data.rowData.SIGNOFF_TRANS_HEADER_KEY === undefined ? 0 : _data.rowData.SIGNOFF_TRANS_HEADER_KEY,
	           		signoff_y_n : _data.rowData.SIGNOFF_Y_N,
	           		currency:_data.rowData.PARENT_FUNCTIONAL_CURRENCY,
	           		amount: _data.rowData[_data.colData.map],//_data.rowData.ACTUAL_TAX_RETURN_AMOUNT,
	           		column_name:_data.colData.map,
	           		GROUP_TRANS_KEY:_data.rowData.GROUP_TRANS_KEY
	   			   }];
	           	 var jsonSettings = [{
	            	 combination_key:_data.rowData.COMBINATION_KEY,
	                tax_year:_data.rowData.TAX_YEAR
	   			   }];
	               var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var params = _.merge({}, GlobalService.globalParams, filterParams);
	               var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
	               var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
	   	           service.saveJSON(trueUpTaxesFactory.url.saveTaxTrueUpSignoff,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   AlertService.add("success", "Data saved Successfully", 3000);
		        		   var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
                              $rootScope.$emit('gridUpdate', args);
						}else {
							rowData.SIGNOFF_Y_N  = null;
	                     AlertService.add("error", data.errorMessage, 3000);
	                     defer.reject(data);
	                 }},function(data){
	                 defer.reject(data);
	             });
	             return defer.promise;			   
	            };
            
            trueUpTaxesFactory.save = function(_data,isDelete,lockObj){
            	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code="+(isDelete?"7pkdzh":"r1zyw7");
            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonSettings = [{
	                tax_year:_data.TAX_YEAR,
	                combination_keys:_data.COMBINATION_KEYS
	   			}];
                var jsonObj =  JSON.stringify(_data);
                var params = filterParams;
                
                if(lockObj && lockObj.modal_name == 'open905cAnalysisBox'){
                    GlobalService.modalLockFilters(lockObj.lockingObj); /*  for locking Object */
                }
                
                params = _.merge({},  GlobalService.globalParams, params);
                params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
                params = _.extend({jsonObj:jsonObj}, params);
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response){
         				console.log(response.data);
	          				if(response.data.callSuccess === "1"){
	                            AlertService.add("success", "Data "+(isDelete?"deleted":"saved")+" Successfully.",3000);
	                            /*var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
	                                         $rootScope.$emit('gridUpdate', args);*/
	                        }else{
	                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
	                        }
	          				//return response.data;
     				})

                return promise;	          	 
	        }
            return trueUpTaxesFactory;
        }]);


    return services;


});