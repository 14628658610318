/**
 * @author Haritha
 * 
 * 
 */
define(
		[ 'angular',
		    './BranchTaxService',
		    '../withholdTax/WithHoldTaxService',
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.BranchAndWithholdTaxCtrl', ['app.BranchTaxService','app.WithHoldTaxService'])
					.controller('branchAndWithholdTaxCtrl',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
									'$timeout', '$uibModal','$uibModalInstance','rowData',
									'gridData','GENERAL_CONFIG', 'workspaceFactory','BranchTaxFactory','$http','USER_SETTINGS', branchAndWithholdTaxCtrl ])

								
			function branchAndWithholdTaxCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, 
					$timeout, $uibModal, $uibModalInstance, rowData,
					gridData, GENERAL_CONFIG, workspaceFactory, BranchTaxFactory,$http,USER_SETTINGS) {

				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
		        vm.rowData = rowData;		        	      
		        vm.title = 'Comments';		            
		        vm.COMMENTS ='';
		        vm.OLD_COMMENTS=vm.COMMENTS;
		        vm.allComments = []		        	        		     
		        vm.GENERAL_CONFIG=GENERAL_CONFIG;
		        //To get the filter params
				var filterParams  = workspaceFactory.activeScreen.filters.getFilterParams();
				//Get the Filter Tax Year
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year;
				//Get the Filter Scenario Year
				vm.scenario = filterParams.scenario;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				
		        //Cancel
		        vm.cancel = function() {
	                $uibModalInstance.dismiss('cancel');
		        }
		        
		        //To Block user from Add/Edit based on the Lock_Y_N flag
				var lockparams = {"action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key	};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;		
					vm.ReturnAsFiled = ((vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y')?true : false);
				});	
				
		        //Get all the comments saved for this specific row
		        function getAllComments(data){
					let uniqueId = data.OBJECT_ID.split('_')[0]; // For summary screen, its combination_key and for details screen, its tag_key
		        	var params = {
		        			"action_code": '8n9er0',
		        			screen_key: GlobalService.globalParams.screen_key,
							object_id:	uniqueId,
							issue_key: data.ISSUE_KEY,
		        			tax_year:	vm.filterTaxYear,
		        			scenario: 	vm.scenario,
		        			jcd_key: 	GlobalService.globalParams.jcd_key
					}
		        	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
		                console.log(data.jsonObject, "all comments");
		                vm.allComments = data.jsonObject;
		            });
		        }
		        
		        if(gridData.colData.label === "Comments"){
		        	getAllComments(vm.rowData);
		        }
		        
				
		        vm.save = function(){
		        	if(vm.COMMENTS == undefined || vm.COMMENTS == null || vm.COMMENTS == ''){
						AlertService.add("error", "Please enter a valid comments",4000);
						return;
					}
		        	
		        	if(vm.COMMENTS.length > 4000){
						AlertService.add("error", "Please comments entered cannot be more than 4000.",4000);
						return;
					}
					let uniqueId = vm.rowData.OBJECT_ID.split('_')[0]; // For summary screen, its combination_key and for details screen, its tag_key
					
					var returnClobSettingsObj = {},message ="Comment saved successfully",otherInfo = {};
					returnClobSettingsObj['sso_id']  = vm.logged_in_user;
					returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					returnClobSettingsObj['scenario'] = vm.scenario;
					returnClobSettingsObj['tag_key']  =  uniqueId;
					returnClobSettingsObj['issue_key']  =  vm.rowData.ISSUE_KEY;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['tax_year'] = vm.filterTaxYear;
					returnClobSettingsObj['comments'] =  vm.COMMENTS.replace(/’/g, "'");
					returnClobSettingsObj['OPERATION_TYPE'] = "I";
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					otherInfo = returnClobSettingsObj;
						
					// SAVE the Standard Reason and Non_Creditable Amount
					var returnObj = {};		
					returnObj['tag_key'] = uniqueId;					
					returnObj['comments'] = vm.COMMENTS;
					returnObj['OPERATION_TYPE'] = "I";
					
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);
					//invoking the send details
					sendDetails(returnClobSettingsObj, defaultsChangeDetails,otherInfo, message)
		        }
					
				function sendDetails(returnClobSettingsObj, defaultsChangeDetails,otherInfo, message) {
					BranchTaxFactory.saveComments(returnClobSettingsObj,defaultsChangeDetails,otherInfo).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
								AlertService.add("success", message, 4000);
								
								var args = {
										object_id:returnClobSettingsObj.object_id,
	                                    gridFilter: {
	                                        object_id: returnClobSettingsObj.object_id
	                                    }
								};
								// $uibModalInstance.dismiss('cancel');
								$rootScope.$emit('gridUpdate', args);	
								$uibModalInstance.dismiss('cancel');
	                        };
						});
					}
				
			}
			
			return controllers;

		});
