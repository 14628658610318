
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.SyncProcessService',[])

    .factory('SyncServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        var syncServiceFactory = {};
	        syncServiceFactory.url = {};
	        syncServiceFactory.url.sync_save = GENERAL_CONFIG.base_url + "/saveJsonObject";
	
	
	        syncServiceFactory.saveAutoTag = function(_url,_params){

	               // getCommentByContext
				   var isIssueIdEnabled =  GlobalService.isIssueIDEnabled(_params.tax_year,_params.scenario);
				   _params = _.merge({}, isIssueIdEnabled, _params);  
	                var promise = $http({
	                    method: "post",
	                    url: _url,
	                    data: _params
	                }).then(function (response) {
	                    return response.data;
	                });



	                return promise;
	            };
        
        
        
        
        return syncServiceFactory
    }])

    return services;

});