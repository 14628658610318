define([
    'angular',
    './sourcingInputScreenCtrl',
    './sourcingInputScreenService', 
], function () {
    'use strict';
    return angular.module('app.FIR_Sourcing_Input', ['app.ghostSourcingInputCtrl', 'app.ghostSourcingInputService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('sourcing-input-screen', {
                url: '/sourcing-input-screen',
                templateUrl: 'app/components/FIR_Sourcing_Input/sourcingInputScreen.html',
                backdrop: 'static',
                data:{},
                access:""
            })
    }])

});