define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.editAdjustGTAPSAttributesCtrl', [])
            .controller('editAdjustGTAPSAttrsMainCtrl',
            		['GlobalService', 'workspaceFactory','AlertService', '$window', '$scope','JsonObjectFactory', '$uibModalInstance','$parse', '$rootScope', 'rowData', 'GENERAL_CONFIG', 'editAdjustGTAPSAttrsFactory', editAdjustGTAPSAttrsMainCtrl]);
    
		    /*function gotoDivID($scope, $anchorScroll, $location) {
				$scope.gotoDivID = function(divID) {
					var newHash = divID;
		            if ($location.hash() !== newHash) {
		              $location.hash(divID);
		            } else {
		              $anchorScroll();
		            }
				};
			}*/
		function editAdjustGTAPSAttrsMainCtrl(GlobalService,workspaceFactory, AlertService, $window, $scope, JsonObjectFactory, $uibModalInstance, $parse, $rootScope, rowData, GENERAL_CONFIG, editAdjustGTAPSAttrsFactory) {
			var vm = this;
			vm.loading = false;
			vm.name = "editAdjustGTAPSAttrsMainCtrl";
			vm.rowData = rowData;
			vm.modalTitle = "Show FIR: GTAPS Attribute Editor";
			vm.hoLeid = rowData.GTAPS_LEID;
			vm.taxYearBegin = "2016-1";
			vm.entityName = rowData.LE_NAME;
			vm.hoLeidEntityName = vm.hoLeid + " - " + vm.entityName;
			vm.hoTaxCode = rowData.GTAPS_CDR_NO;
			vm.scenario = "RAF/BH";
			vm.baseCurrency = "USD";
			vm.funcCurrency = rowData.FUNCTIONAL_CURRENCY;
			vm.funcCurrDescription = rowData.CURR_DESC;
			vm.funcCurrDisplay = vm.funcCurrency + " - " + vm.funcCurrDescription;
			vm.total959c3EPPoolFuncCurr = rowData.POST_1986C3EP_FC;
			vm.totalPtiFuncCurr = rowData.PTIFC;
			vm.total959c3EPPoolUSD = rowData.POST_1986C3EPUSD;
			vm.totalPtiUSD = rowData.PTIUSD;
			vm.totalTaxPool = rowData.POST_1986C3TAXES;
			vm.totalUSDBasisInPti = rowData.PTI_USD_BASIS_TOT;
			vm.effectiveRateOn959c3TotalEp = rowData.ETRONPOST_1986C3EP;
			vm.estimatedPtiExchangeGainLoss = rowData.PTI_EXCHG_GAIN_LOSS;
			vm.recaptureAccounts = "No";
			vm.qualifiedDeficits = "No";
			vm.examGhostScenario = null;
			vm.rafGhostScenario = null;

			//Adjustment Description
			vm.adjDesc = null;

			//G2G_Yorn checkbox
			vm.isG2G = 'Y';

			//labels
			vm.gtapsBalanceHeader = "Balance per GTAPS " + vm.taxYearEnd;
			vm.labelSaveAndUpdate = "Save and Update";
			vm.labelReset = "Reset";
			vm.labelCancel = "Cancel";
			vm.labelSummary = "Summary";
			vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
			vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
			vm.labelTotal959c3EPPoolUSD = "Total Section 959(c)(3) E&P Pool (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalPtiUSD = "Total PTI (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalTaxPool = "Total Tax Pool";
			vm.labelTotalUSDBasisInPti = "Total USD Basis in PTI";
			vm.labelEffectiveRateOn959c3TotalEp = "Effective Rate on 959(c)(3) Total E&P";
			vm.labelEstimatedPtiExchangeGainLoss = "Estimated PTI Exchange Gain/(Loss)";
			vm.labelRecaptureAccts = "Recapture Accounts";
			vm.labelQualDefs = "Qualified Deficits";
			vm.labelAdjDesc = "Adjustment Description";
			vm.labelThisProspectiveAdj = "This is a prospective adjustment";
			vm.labelprospectiveAdjTitle = "A prospective adjustment to GTAPS is an adjustment to your E&P and tax pools that is not reflected in FIR and GHOST Exam scenarios.  Prospective adjustments include adjustments to true up E&P and tax pools due to outsourced E&P studies.  Prospective adjustments should NOT include E&P changes for open QAR years, even if those changes are part of an E&P study.  These changes should be reported in FIR and GHOST Exam scenarios.  GTAPS adjustments for changes that are reported in FIR and GHOST Exam scenarios should not be tagged as prospective adjustments.  For questions, please contact Michael Steward or Michael Roussin.";
			vm.labelYear = "Year";
			vm.labelAdjType = "Adjustment Type";
			vm.labelTotal = "Total";
			vm.labelTotals = "Totals";
			vm.labelOwnSub = "Own/Sub";
			vm.labelPtiType = "PTI-Type";
			vm.labelPtiGenYr = "PTI Generated Year";
			vm.labelAdjRelToYr = "Adjustment Related to Year";
			
			//Section headers
			vm.foreignC3Header = "Foreign c3 (Post-1986 Pool, Foreign Source, Functional Currency = " + vm.funcCurrency + ")";
			vm.foreignC3TaxesHeader = "Foreign c3 Taxes (Foreign Source, Currency = " + vm.baseCurrency + ")";
			vm.hdInForeignC3Header = "Hovering Deficits included in Foreign c3 (Post-1986 Pool, Foreign Source, Functional Currency = " + vm.funcCurrency + ")";
			vm.hdInForeignC3TaxesHeader = "Hovering Deficits included in Foreign c3 Taxes (Foreign Source, Currency = " + vm.baseCurrency + ")";
			vm.ptiFCHeader = "PTI (Post - 1986 years, Functional Currency = " + vm.funcCurrency + ")";
			vm.ptiUSDHeader = "PTI USD Basis (Post-1986 years, Currency = " + vm.baseCurrency + ")";
			vm.recapAcctHeader = "Recapture Accounts (Functional Currency = " + vm.funcCurrency + ")";
			vm.qualDefHeader = "Qualified Deficits (Functional Currency = " + vm.funcCurrency + ")";
			vm.otherAttrsHeader = "Other Attributes -- US Source E&P (Post-1986 Pool, Functional Currency = " + vm.funcCurrency + ")";
			vm.gtapsAdjustmentTypes = {};
			vm.gtapsHeadings = {};

			//Error texts
			vm.adjDescError = "Please enter adjustment description to the changes made!";
			vm.noChangesError = "No Changes Made to the edit screen!";
			vm.selectTaxYearError = "Select Tax Year for ";
			vm.selectAdjTypeError = "Select Adjustment Type for ";
			vm.selectOwnSubError = "Select Own/Sub for ";
			vm.selectPtiTypeError = "Select PTI-Type for ";
			vm.totalHFC3NegativeError = "Total Hovering Foreign C3 should be negative!";
			vm.totalHC3TaxesPositiveError = "Total Hovering C3 Taxes should be positive!";
			vm.adjustmentError = " Adjustment ";
			vm.headerSectionForError = {'FOREIGN_C3': vm.foreignC3Header, 'FOREIGN_C3_TAXES': vm.foreignC3TaxesHeader, 'HDEFICIT_FOREIGN_C3': vm.hdInForeignC3Header, 
																	'HDEFICIT_FOREIGN_C3_TAXES': vm.hdInForeignC3TaxesHeader, 'PTI_FC': vm.ptiFCHeader, 'PTI_BC': vm.ptiUSDHeader, 
																	'RECAP_ACCT': vm.recapAcctHeader, 'QUAL_DEF': vm.qualDefHeader, 'OTHERS': vm.otherAttrsHeader};
			// vm.saveError = "Save Failed."; commenting this, as we need to take only the error msg thrown by response..
			vm.saveSuccess = "Data Saved Successfully.";

			//Drop down selection lists (SL)
			vm.taxYearSL = [];
			vm.adjTypeSL = [];
			vm.adjTypeTaxesSL = [];
			vm.ownSubSL = [{label:'OWN', value:'OWN'},{label:'SUB', value:'SUB'}];
			vm.ptiTypeSL = [{label:'C1A', value:'C1A'},{label:'C1B', value:'C1B'},{label:'C2', value:'C2'}];

			//Data Lists
			vm.allTotals = {};
			vm.editableTotals = {};

			//To store list of header key, row no., basket desc which are dirtied/changed in this session
			vm.changedHeaderRowBaskets = [];
			var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

			loadGTAPSEditAttrsData();
			function loadGTAPSEditAttrsData()
			{
				vm.loading = true;
				var params = {
						"ho_cdr_no":vm.rowData.GTAPS_CDR_NO,
						"ho_leid": vm.rowData.GTAPS_LEID,
						"ho_reporting_period":1
				}
				editAdjustGTAPSAttrsFactory.getEditAdjustAttrsData(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error",
									result.errorMessage, 4000);
							vm.loading = false;
						} else {
							vm.editAttrsData = data.jsonObject;
							vm.loading = false;
							if (vm.editAttrsData !== null && vm.editAttrsData !== undefined
								&& vm.editAttrsData.length > 0) {
								if (vm.editAttrsData[0].hasOwnProperty("TAX_YEAR_BEGIN_END_JSON")) {
									var taxYrBeginEndJson = JSON.parse(vm.editAttrsData[0].TAX_YEAR_BEGIN_END_JSON);
									if (taxYrBeginEndJson.length > 0) {
										if (taxYrBeginEndJson[0].hasOwnProperty("TAX_YEAR_BEGIN")) {
											vm.taxYearBegin = taxYrBeginEndJson[0].TAX_YEAR_BEGIN;
										}
										if (taxYrBeginEndJson[0].hasOwnProperty("TAX_YEAR_END")) {
											vm.taxYearEnd = taxYrBeginEndJson[0].TAX_YEAR_END;
															vm.gtapsBalanceHeader = "Balance per GTAPS " + vm.taxYearEnd;
										}
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("ADJ_TYPE_JSON")) {
									var adjTypeJson = JSON.parse(vm.editAttrsData[0].ADJ_TYPE_JSON);
									if (adjTypeJson.length > 0) {
										vm.gtapsAdjustmentTypes = adjTypeJson;
										//Populate adjTypeSL with filtered list of 905, ADJ and
										//adjTypeTaxesSL with filtered list of 905, 90M, ADJ
										angular.forEach(vm.gtapsAdjustmentTypes, function(adjType) {
											if (angular.isDefined(adjType.CODE_NAME) && angular.isDefined(adjType.CODE_NAME)) {
												if (adjType.CODE_NAME === '905' || adjType.CODE_NAME === 'ADJ') {
													vm.adjTypeSL.push({'CODE_NAME': adjType.CODE_NAME, 'CODE_DESC': adjType.CODE_DESC});
													vm.adjTypeTaxesSL.push({'CODE_NAME': adjType.CODE_NAME, 'CODE_DESC': adjType.CODE_DESC});
												} else if (adjType.CODE_NAME === '90M') {
													vm.adjTypeTaxesSL.push({'CODE_NAME': adjType.CODE_NAME, 'CODE_DESC': adjType.CODE_DESC});
												}
											}
										});
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("TAX_YEAR_LIST_JSON")) {
									var taxYearListJson = JSON.parse(vm.editAttrsData[0].TAX_YEAR_LIST_JSON);
									if (taxYearListJson.length > 0) {
										//Populate vm.taxYearSL with distinct tax years
										angular.forEach(taxYearListJson, function(taxYear) {
											if (angular.isDefined(taxYear.TAX_YEAR)) {
												var index = vm.taxYearSL.findIndex( taxYearRow => (taxYearRow.label === taxYear.TAX_YEAR && taxYearRow.value === taxYear.TAX_YEAR));
												//don't push it if it already exists
												if (index === -1) {
													vm.taxYearSL.push({label: taxYear.TAX_YEAR, value: taxYear.TAX_YEAR});
												}
											}
										});
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("GHOST_SCENARIOS_JSON")) {
									var ghostScenariosJson = JSON.parse(vm.editAttrsData[0].GHOST_SCENARIOS_JSON);
									if (ghostScenariosJson.length > 0) {
										//Populate vm.taxYearSL with distinct tax years
										angular.forEach(ghostScenariosJson, function(ghostScenarioRow) {
											if (angular.isDefined(ghostScenarioRow.SCENARIO_DESC) && ghostScenarioRow.SCENARIO_DESC.toUpperCase() === 'EXAM') {
												vm.examGhostScenario = ghostScenarioRow.GHOST_SCENARIO;
											} else if (angular.isDefined(ghostScenarioRow.SCENARIO_DESC) && ghostScenarioRow.SCENARIO_DESC.toUpperCase() === 'RETURN AS FILED') {
												vm.rafGhostScenario = ghostScenarioRow.GHOST_SCENARIO;
											}
										});
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("HEADING_JSON")) {
									var headingJson = JSON.parse(vm.editAttrsData[0].HEADING_JSON);
									if (headingJson.length > 0) {
										angular.forEach(headingJson, function(headingRow) {
											if (headingRow.hasOwnProperty("BASKET_CODE") && headingRow.hasOwnProperty("BASKET_DESC")) {
												vm.gtapsHeadings[headingRow.BASKET_CODE] = headingRow.BASKET_DESC;
											}
										});
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("GET_RECAPTURE_ACCT_JSON")) {
									var getRecaptureAcctJson = JSON.parse(vm.editAttrsData[0].GET_RECAPTURE_ACCT_JSON);
									if (getRecaptureAcctJson.length > 0
										&& getRecaptureAcctJson[0].hasOwnProperty("RECAPTURE_ACCT_COUNT")) {
										vm.recaptureAccounts = getRecaptureAcctJson[0].RECAPTURE_ACCT_COUNT > 0 ? "Yes" : "No";
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("GET_QUAL_DEF_JSON")) {
									var getQualDefJson = JSON.parse(vm.editAttrsData[0].GET_QUAL_DEF_JSON);
									if (getQualDefJson.length > 0
										&& getQualDefJson[0].hasOwnProperty("QUAL_DEF_COUNT")) {
										vm.qualifiedDeficits = getQualDefJson[0].QUAL_DEF_COUNT > 0 ? "Yes" : "No";
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("GET_PTI_BASIS_AMT_JSON")) {
									var getPTIBasisAmtJson = JSON.parse(vm.editAttrsData[0].GET_PTI_BASIS_AMT_JSON);
									if (getPTIBasisAmtJson.length > 0
										&& getPTIBasisAmtJson[0].hasOwnProperty("PTI_BASIS_TOTAL_AMT")) {
										vm.totalUSDBasisInPti = getPTIBasisAmtJson[0].PTI_BASIS_TOTAL_AMT;
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("EP_SUMMARY_INFO_JSON")) {
									var epSummaryInfoJson = JSON.parse(vm.editAttrsData[0].EP_SUMMARY_INFO_JSON);
									if (epSummaryInfoJson.length > 0) {
										if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EP_FC")) {
											vm.total959c3EPPoolFuncCurr = epSummaryInfoJson[0].POST_1986C3EP_FC;
														}
										if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EPUSD")) {
											vm.total959c3EPPoolUSD = epSummaryInfoJson[0].POST_1986C3EPUSD;
														}
										if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3TAXES")) {
											vm.totalTaxPool = epSummaryInfoJson[0].POST_1986C3TAXES;
														}
										if (epSummaryInfoJson[0].hasOwnProperty("ETRONPOST_1986C3EP")) {
											vm.effectiveRateOn959c3TotalEp = epSummaryInfoJson[0].ETRONPOST_1986C3EP;
														}
										if (epSummaryInfoJson[0].hasOwnProperty("PTIFC")) {
											vm.totalPtiFuncCurr = epSummaryInfoJson[0].PTIFC;
														}
										if (epSummaryInfoJson[0].hasOwnProperty("PTIUSD")) {
											vm.totalPtiUSD = epSummaryInfoJson[0].PTIUSD;
														}
									}
								}
								if (vm.editAttrsData[0].hasOwnProperty("AMOUNTS_JSON")) {
									var amountsJson = JSON.parse(vm.editAttrsData[0].AMOUNTS_JSON);
									if (amountsJson.length > 0) {
										//Sort Amounts Json based on SORT_ORDER, FTC_ID Ascending
										amountsJson.sort(function(a,b){
											if(a.hasOwnProperty("SORT_ORDER") && b.hasOwnProperty("SORT_ORDER") && a.SORT_ORDER == b.SORT_ORDER) {
												if(a.hasOwnProperty("FTC_ID") && b.hasOwnProperty("FTC_ID") && a.FTC_ID == b.FTC_ID) {
													return 0;
												} else if(a.hasOwnProperty("FTC_ID") && b.hasOwnProperty("FTC_ID") && a.FTC_ID < b.FTC_ID) {
														return -1;
												} else if(a.hasOwnProperty("FTC_ID") && b.hasOwnProperty("FTC_ID") && a.FTC_ID > b.FTC_ID) {
														return 1;
												}
											} else if(a.hasOwnProperty("SORT_ORDER") && b.hasOwnProperty("SORT_ORDER") && a.SORT_ORDER < b.SORT_ORDER) {
													return -1;
											} else if(a.hasOwnProperty("SORT_ORDER") && b.hasOwnProperty("SORT_ORDER") && a.SORT_ORDER > b.SORT_ORDER) {
													return 1;
											}
										});
										var basketCode;
										var basketDesc;
										var amt;
										var epTax;
										var convention;
										var adjCode;
										var adjYr;
										var epTaxType;
										var ptiType;
										var source;
										var hoveringDeficit;
										var nineohfourcode;
										var passivecode;
										var subfcode;
										var fphcicode;
										var qualDescription;
										var editedTotalAmt;
										var currentSortOrderTotals = [];
										var prevSortOrder;
										var headerKey;
										var i = 1;
										var amtsJsonLen = amountsJson.length;
										angular.forEach(amountsJson, function(amtRow) {
											if (prevSortOrder && amtRow.hasOwnProperty("SORT_ORDER") && prevSortOrder !== amtRow.SORT_ORDER) {
												//Based on previous record's ptiType, epTax
												headerKey = null;
												if (ptiType) {
													if (ptiType === 'C3') {
														if (hoveringDeficit && hoveringDeficit === 'Y') {
															headerKey = (epTax === 'E') ? "HDEFICIT_FOREIGN_C3" : "HDEFICIT_FOREIGN_C3_TAXES";
														} else {
															headerKey = (epTax === 'E') ? "FOREIGN_C3" : "FOREIGN_C3_TAXES";
														}
													} else if (ptiType === 'C2') {
														headerKey = "PTI_FC";
													} else if (ptiType === 'C1') {
														headerKey = "PTI_BC";
													} else if (ptiType === 'RR') {
														headerKey = "RECAP_ACCT";
													} else if (ptiType === 'QD') {
														headerKey = "QUAL_DEF";
													} else if (ptiType === 'P') {
														headerKey = "OTHERS";
													}
													vm.allTotals[headerKey] = currentSortOrderTotals;
													//Create two editable rows and one totals row for each header section
													var totalsRow = angular.copy(currentSortOrderTotals);
													var editableFirstRow = angular.copy(currentSortOrderTotals);
													var editableSecondRow = angular.copy(currentSortOrderTotals);
													vm.editableTotals[headerKey] = {0: {"ADJ_TYPE": adjCode, "ADJ_YEAR":  adjYr, "PTI_TYPE": null, "OWN_SUB": null, "AMOUNT_DATA": totalsRow}, 
																					1: {"ADJ_TYPE": adjCode, "ADJ_YEAR":  adjYr, "PTI_TYPE": null, "OWN_SUB": null, "AMOUNT_DATA": editableFirstRow},
																					2: {"ADJ_TYPE": adjCode, "ADJ_YEAR":  adjYr, "PTI_TYPE": null, "OWN_SUB": null, "AMOUNT_DATA": editableSecondRow}};
													//For Totals row, default EDITED_TOTAL_AMT value to TOTAL_AMT
													if (vm.editableTotals[headerKey][0]) {
														angular.forEach(vm.editableTotals[headerKey][0].AMOUNT_DATA, function(editableAmtRow) {
															editableAmtRow.EDITED_TOTAL_AMT = editableAmtRow.TOTAL_AMT;
														});
													} 
												}
												currentSortOrderTotals = [];
											}
											basketCode = vm.labelTotal;
											basketDesc = vm.labelTotal;
											amt = null;
											epTax = null;
											convention = null;
											epTaxType = null;
											ptiType = null;
											source = null;
											hoveringDeficit = null;
											editedTotalAmt = undefined;
											adjCode = null;
											adjYr = null;
											nineohfourcode = null;
											passivecode = null;
											subfcode = null;
											fphcicode = null;
											qualDescription = null;
											prevSortOrder = amtRow.SORT_ORDER;
											if (amtRow.hasOwnProperty("SORT_ORDER")) {//Store each sort order totals in allTotals array
												if (amtRow.hasOwnProperty("BASKET_CODE") && vm.gtapsHeadings && vm.gtapsHeadings[amtRow.BASKET_CODE]) {
													basketDesc = vm.gtapsHeadings[amtRow.BASKET_CODE];
													basketCode = amtRow.BASKET_CODE;
												}
												if (amtRow.hasOwnProperty("QUAL_DESCRIPTION") && vm.gtapsHeadings && vm.gtapsHeadings[amtRow.QUAL_DESCRIPTION]) {
													basketDesc = vm.gtapsHeadings[amtRow.QUAL_DESCRIPTION];
													basketCode = amtRow.QUAL_DESCRIPTION;
												}
												if (amtRow.hasOwnProperty("EP_TAX")) {
													epTax = amtRow.EP_TAX;
												}
												if (amtRow.hasOwnProperty("TOTAL_AMT")) {
													amt = amtRow.TOTAL_AMT;
												}
												if (amtRow.hasOwnProperty("SOURCE")) {
													source = amtRow.SOURCE;
												}
												if (amtRow.hasOwnProperty("PTI_TYPE")) {
													ptiType = amtRow.PTI_TYPE;
												}
												if (amtRow.hasOwnProperty("HDEFICIT")) {
													hoveringDeficit = amtRow.HDEFICIT;
												}
												if (amtRow.hasOwnProperty("NINEOHFOURDCODE")) {
													nineohfourcode = amtRow.NINEOHFOURDCODE;
												}
												if (amtRow.hasOwnProperty("PASSIVECODE")) {
													passivecode = amtRow.PASSIVECODE;
												}
												if (amtRow.hasOwnProperty("SUBFCODE")) {
													subfcode = amtRow.SUBFCODE;
												}
												if (amtRow.hasOwnProperty("FPHCICODE")) {
													fphcicode = amtRow.FPHCICODE;
												}
												if (amtRow.hasOwnProperty("QUAL_DESCRIPTION")) {
													qualDescription = amtRow.QUAL_DESCRIPTION;
												}
												if (ptiType) {
													epTaxType = (ptiType === "C3") ? "U" : "P";
													convention = (ptiType === "C3") ? "P" : "L";
													adjCode = (ptiType === "C1" || ptiType === "C2" || ptiType === "QD" || ptiType === "RR") ? 'ADJ' : adjCode;
													adjYr = (ptiType === "C1") ? 'Post-1986' : adjYr;
												}
												//For Basket Code - "Total" set EDITED_TOTAL_AMT default value as 0
												if (basketCode === vm.labelTotal) {
													editedTotalAmt = 0;
												}
												currentSortOrderTotals.push({"BASKET_CODE": basketCode, "BASKET_DESC":basketDesc, "EP_TAX":epTax, "TOTAL_AMT":amt, "EDITED_TOTAL_AMT":editedTotalAmt, 
																			 "NINEOHFOURDCODE":nineohfourcode, "PASSIVECODE":passivecode, "SUBFCODE":subfcode, "FPHCICODE":fphcicode, "QUAL_DESCRIPTION":qualDescription,
																			 "PTI_TYPE":ptiType, "SOURCE":source, "HDEFICIT":hoveringDeficit, "EP_TAX_TYPE":epTaxType, 
																			 "CONVENTION":convention});
											}
											if (i === amtsJsonLen) {
												//Based on previous record's ptiType, epTax
												headerKey = null;
												if (ptiType) {
													if (ptiType === 'C3') {
														if (hoveringDeficit && hoveringDeficit === 'Y') {
															headerKey = (epTax === 'E') ? "HDEFICIT_FOREIGN_C3" : "HDEFICIT_FOREIGN_C3_TAXES";
														} else {
															headerKey = (epTax === 'E') ? "FOREIGN_C3" : "FOREIGN_C3_TAXES";
														}
													} else if (ptiType === 'C2') {
														headerKey = "PTI_FC";
													} else if (ptiType === 'C1') {
														headerKey = "PTI_BC";
													} else if (ptiType === 'RR') {
														headerKey = "RECAP_ACCT";
													} else if (ptiType === 'QD') {
														headerKey = "QUAL_DEF";
													} else if (ptiType === 'P') {
														headerKey = "OTHERS";
													}
													vm.allTotals[headerKey] = currentSortOrderTotals;
													//Create two editable rows and one totals row for each header section
													var totalsRow = angular.copy(currentSortOrderTotals);
													var editableFirstRow = angular.copy(currentSortOrderTotals);
													var editableSecondRow = angular.copy(currentSortOrderTotals);
													vm.editableTotals[headerKey] = {0: {"ADJ_TYPE": null, "ADJ_YEAR":  null, "PTI_TYPE": null, "OWN_SUB": null, "AMOUNT_DATA": totalsRow}, 
																					1: {"ADJ_TYPE": null, "ADJ_YEAR":  null, "PTI_TYPE": null, "OWN_SUB": null, "AMOUNT_DATA": editableFirstRow},
																					2: {"ADJ_TYPE": null, "ADJ_YEAR":  null, "PTI_TYPE": null, "OWN_SUB": null, "AMOUNT_DATA": editableSecondRow}};
													//For Totals row, default EDITED_TOTAL_AMT value to TOTAL_AMT
													if (vm.editableTotals[headerKey][0]) {
														angular.forEach(vm.editableTotals[headerKey][0].AMOUNT_DATA, function(editableAmtRow) {
															editableAmtRow.EDITED_TOTAL_AMT = editableAmtRow.TOTAL_AMT;
														});
													}
												}
												currentSortOrderTotals = [];
											}
											i++;
										});
									}
								}
							}
						}
				});
			}
			
			vm.amountFieldOnChange = function(headerKey, value, rowNo, currentBasketDesc) {
				//Add this headerKey, rowNo, basketDesc combination to list of dirtied rows if value is not equal to 0
				if (value && value != 0) {
					if (angular.isUndefined(vm.changedHeaderRowBaskets)) {
						vm.changedHeaderRowBaskets = [];
					}
					var index = vm.changedHeaderRowBaskets.findIndex( changedHeaderRow => (changedHeaderRow.HEADER_KEY === headerKey && changedHeaderRow.ROW_NO === rowNo && changedHeaderRow.BASKET === basketDesc));
					//don't push it if it already exists
					if (index === -1) {
						vm.changedHeaderRowBaskets.push({"HEADER_KEY": headerKey, "ROW_NO": rowNo, "BASKET": currentBasketDesc});
					}
				} else {
					index = -1;
					//Remove this headerKey, rowNo, currentBasketDesc combination from changedHeaderRowBaskets
					if (angular.isDefined(vm.changedHeaderRowBaskets)) {
						index = vm.changedHeaderRowBaskets.findIndex( changedHeaderRow => (changedHeaderRow.HEADER_KEY === headerKey && changedHeaderRow.ROW_NO === rowNo && changedHeaderRow.BASKET === basketDesc));
						//don't push it if it already exists
						if (index !== -1) {
							vm.changedHeaderRowBaskets.splice(index, 1);
						}
					}
				}
				//Update all Totals related to the passed headerKey section
				if(angular.isDefined(vm.editableTotals[headerKey][rowNo]) && angular.isDefined(vm.editableTotals[headerKey][0])) {
					var index = 0;
					var basketColTotal = {};
					var rowTotal = {};
					var basketDesc;
					var allRowTotal = 0;
					angular.forEach(vm.editableTotals[headerKey], function(headerEditableRow) {
						if (index !== 0) {
							angular.forEach(headerEditableRow.AMOUNT_DATA, function(amtColumn) {
								basketDesc = amtColumn["BASKET_DESC"];
								if (basketDesc !== 'Total' && angular.isDefined(amtColumn["EDITED_TOTAL_AMT"])) {
									if (amtColumn.EDITED_TOTAL_AMT || amtColumn.EDITED_TOTAL_AMT === 0) {
										//For Column total
										if (angular.isUndefined(basketColTotal[basketDesc])) {
											basketColTotal[basketDesc] = parseInt(amtColumn.EDITED_TOTAL_AMT);
										} else{
											basketColTotal[basketDesc] = parseInt(basketColTotal[basketDesc]) + parseInt(amtColumn.EDITED_TOTAL_AMT);
										}
										//For row total
										if (angular.isUndefined(rowTotal[index])) {
											rowTotal[index] = parseInt(amtColumn.EDITED_TOTAL_AMT);
										} else {
											rowTotal[index] = parseInt(rowTotal[index]) + parseInt(amtColumn.EDITED_TOTAL_AMT);
										}
									}
								}
							});
							//Set Row level Total for each Row
							angular.forEach(headerEditableRow.AMOUNT_DATA, function(amtColumn) {
								basketDesc = amtColumn["BASKET_DESC"];
								if (basketDesc && basketDesc === 'Total' && angular.isDefined(rowTotal[index])) { //If Basket is Total then set the Row Total
									amtColumn.EDITED_TOTAL_AMT = parseInt(rowTotal[index]);
								}
							});
						}
						index++;
					});
					//Set it in the Column level Totals Row (i.e. index 0 of editableRows for current headerKey) for current basketDesc and the Total basketDesc
					angular.forEach(vm.editableTotals[headerKey][0].AMOUNT_DATA, function(totalAmtRow) {
						basketDesc = totalAmtRow["BASKET_DESC"];
						if (basketDesc && basketDesc === 'Total') { //If Basket is Total then set Sum of Row Totals
							angular.forEach(rowTotal, function(eachRowTotal) {
								allRowTotal = parseInt(allRowTotal) + parseInt(eachRowTotal);
							});
							totalAmtRow.EDITED_TOTAL_AMT = parseInt(totalAmtRow.TOTAL_AMT) + parseInt(allRowTotal);
						} else if (currentBasketDesc === basketDesc 
								&& angular.isDefined(totalAmtRow["TOTAL_AMT"]) && (basketColTotal[basketDesc] || basketColTotal[basketDesc] === 0)) {
							totalAmtRow.EDITED_TOTAL_AMT = parseInt(totalAmtRow.TOTAL_AMT) + parseInt(basketColTotal[basketDesc]);
							if (headerKey === 'HDEFICIT_FOREIGN_C3' && totalAmtRow.EDITED_TOTAL_AMT > 0) {
								AlertService.add("warning", vm.totalHFC3NegativeError);
							} else if (headerKey === 'HDEFICIT_FOREIGN_C3_TAXES' && totalAmtRow.EDITED_TOTAL_AMT < 0) {
								AlertService.add("warning", vm.totalHC3TaxesPositiveError);
							}
						}
					});
				}
			};

			vm.validateForm = function () {
				var errorText = null;
				if (!vm.changedHeaderRowBaskets || vm.changedHeaderRowBaskets.length === 0) {
					errorText = vm.noChangesError;
				} else if (!vm.adjDesc || vm.adjDesc.trim() === '') {
					errorText = vm.adjDescError;
				} else {
					var headerKey;
					var rowNo;
					for (var i = 0; i < vm.changedHeaderRowBaskets.length; i++) {
						headerKey = vm.changedHeaderRowBaskets[i].HEADER_KEY;
						rowNo = vm.changedHeaderRowBaskets[i].ROW_NO;
						if (angular.isDefined(vm.editableTotals[headerKey][rowNo].ADJ_YEAR) &&
								(!vm.editableTotals[headerKey][rowNo].ADJ_YEAR || vm.editableTotals[headerKey][rowNo].ADJ_YEAR === '')) {
							errorText = vm.selectTaxYearError + vm.headerSectionForError[headerKey] + vm.adjustmentError + rowNo;
							break;
						} else if (angular.isDefined(vm.editableTotals[headerKey][rowNo].ADJ_TYPE) &&
								(!vm.editableTotals[headerKey][rowNo].ADJ_TYPE || vm.editableTotals[headerKey][rowNo].ADJ_TYPE === '')) {
							errorText = vm.selectAdjTypeError + vm.headerSectionForError[headerKey] + vm.adjustmentError + rowNo;
							break;
						} else if (headerKey === 'PTI_FC'
								&& (angular.isDefined(vm.editableTotals[headerKey][rowNo].OWN_SUB) &&
								(!vm.editableTotals[headerKey][rowNo].OWN_SUB || vm.editableTotals[headerKey][rowNo].OWN_SUB === ''))) {
							errorText = vm.selectOwnSubError + vm.headerSectionForError[headerKey] + vm.adjustmentError + rowNo;
							break;
						} else if (headerKey === 'PTI_FC'
								&& (angular.isDefined(vm.editableTotals[headerKey][rowNo].PTI_TYPE) &&
								(!vm.editableTotals[headerKey][rowNo].PTI_TYPE || vm.editableTotals[headerKey][rowNo].PTI_TYPE === ''))) {
							errorText = vm.selectPtiTypeError + vm.headerSectionForError[headerKey] + vm.adjustmentError + rowNo;
							break;
						}
					}
				}
				if (errorText && errorText.trim() !== '') {
					AlertService.add("error", errorText);
					return false;
				}
				return true;
			};

			vm.reset = function () {
				$scope.crudLoading = false;
				//Reload the editableTotals list and 
				vm.adjDesc = null;
				vm.isG2G = 'Y';
				var ptiType = null;
				vm.changedHeaderRowBaskets = [];
				var adjCode;
				var adjYr;
				angular.forEach(vm.editableTotals, function(headerData, headerKey) {
					if (vm.allTotals[headerKey][0].PTI_TYPE) {
						ptiType = vm.allTotals[headerKey][0].PTI_TYPE;
					}
					angular.forEach(headerData, function(headerDataRow, childKey) {
						adjCode = null;
						adjYr = null;
						if (childKey !== 0 && ptiType) {
							adjCode = (ptiType === "C1" || ptiType === "C2" || ptiType === "QD" || ptiType === "RR") ? 'ADJ' : adjCode;
							adjYr = (ptiType === "C1") ? 'Post-1986' : adjYr;
						}
						vm.editableTotals[headerKey][childKey] = {"ADJ_TYPE": adjCode, "ADJ_YEAR":  adjYr, "PTI_TYPE": null, "OWN_SUB": null, "AMOUNT_DATA": angular.copy(vm.allTotals[headerKey])};
					});
					//For Totals row, default EDITED_TOTAL_AMT value to TOTAL_AMT
					if (vm.editableTotals[headerKey][0]) {
						angular.forEach(vm.editableTotals[headerKey][0].AMOUNT_DATA, function(editableAmtRow) {
							editableAmtRow.EDITED_TOTAL_AMT = editableAmtRow.TOTAL_AMT;
						});
					} 
				});
			};
			
			vm.saveForm = function() {
				console.log("in saveForm method");
				if ($scope.crudLoading) {
					AlertService.add("info", "Please wait while we save this request", 4000);
					return;
				}
				
				$scope.crudLoading = true;
				//Validate the Form before saving the data
				if (vm.validateForm()) {
					//Update the vm.allTotals TOTAL_AMT with vm.editableTotals related EDITED_TOTAL_AMT and then call reset method.
					//Populate the editAttributesJson records
					var editAttributesJson = {};
					var headerKey;
					var rowNo;
					var basket;
					var amtData;
					var basketCode;
					var basketDesc;
					var epTax;
					var amt;
					var nineohfourdcode;
					var passivecode;
					var subfcode;
					var fphcicode;
					var ptiType;
					var editedPtiType;
					var source;
					var hdeficit;
					var qualDescription;

					angular.forEach(vm.changedHeaderRowBaskets, function(changedHeaderRowBasket) {
						headerKey = changedHeaderRowBasket.HEADER_KEY;
						rowNo = changedHeaderRowBasket.ROW_NO;
						basket = changedHeaderRowBasket.BASKET;
						if (angular.isDefined(vm.editableTotals[headerKey][rowNo].AMOUNT_DATA)) {
							if (angular.isUndefined(editAttributesJson["EDIT_ROWS"])) {
								editAttributesJson["EDIT_ROWS"] = [];
							}
							amtData = vm.editableTotals[headerKey][rowNo].AMOUNT_DATA;
							for (var i = 0; i < amtData.length; i++) {
								basketCode = null;
								basketDesc = null;
								epTax = null;
								amt = null;
								nineohfourdcode = null;
								passivecode = null;
								subfcode = null;
								fphcicode = null;
								ptiType = null;
								editedPtiType = null;
								source = null;
								hdeficit = null;
								qualDescription = null;
								if (angular.isDefined(amtData[i].BASKET_DESC)
										&& amtData[i].BASKET_DESC === basket) {
									if (amtData[i].hasOwnProperty("BASKET_CODE")) {
										basketCode = amtData[i].BASKET_CODE;
									}
									if (amtData[i].hasOwnProperty("BASKET_DESC")) {
										basketDesc = vm.gtapsHeadings[amtData[i].BASKET_DESC];
									}
									if (amtData[i].hasOwnProperty("EP_TAX")) {
										epTax = amtData[i].EP_TAX;
									}
									if (amtData[i].hasOwnProperty("EDITED_TOTAL_AMT")) {
										amt = amtData[i].EDITED_TOTAL_AMT;
									}
									if (amtData[i].hasOwnProperty("SOURCE")) {
										source = amtData[i].SOURCE;
									}
									if (angular.isDefined(vm.editableTotals[headerKey][rowNo].PTI_TYPE)) {
										editedPtiType = vm.editableTotals[headerKey][rowNo].PTI_TYPE;
									}
									if (amtData[i].hasOwnProperty("PTI_TYPE")) {
										ptiType = amtData[i].PTI_TYPE;
									}
									if (amtData[i].hasOwnProperty("HDEFICIT")) {
										hdeficit = amtData[i].HDEFICIT;
									}
									if (amtData[i].hasOwnProperty("NINEOHFOURDCODE")) {
										nineohfourdcode = amtData[i].NINEOHFOURDCODE;
									}
									if (amtData[i].hasOwnProperty("PASSIVECODE")) {
										passivecode = amtData[i].PASSIVECODE;
									}
									if (amtData[i].hasOwnProperty("SUBFCODE")) {
										subfcode = amtData[i].SUBFCODE;
									}
									if (amtData[i].hasOwnProperty("FPHCICODE")) {
										fphcicode = amtData[i].FPHCICODE;
									}
									if (amtData[i].hasOwnProperty("QUAL_DESCRIPTION")) {
										qualDescription = amtData[i].QUAL_DESCRIPTION;
									}
									// editAttributesJson[headerKey].push({"ADJ_DESC": vm.adjDesc, "G2G_YORN":  vm.isG2G,
									// 													"ADJ_TYPE": vm.editableTotals[headerKey][rowNo].ADJ_TYPE, "ADJ_YEAR":  vm.editableTotals[headerKey][rowNo].ADJ_YEAR,
									// 													"PTI_TYPE": vm.editableTotals[headerKey][rowNo].PTI_TYPE, "OWN_SUB": vm.editableTotals[headerKey][rowNo].OWN_SUB,
									// 													"AMOUNT_DATA": amtData[i]
									// 												});
									editAttributesJson["EDIT_ROWS"].push({	"HEADER":headerKey, "ADJ_DESC": vm.adjDesc, "G2G_YORN":  vm.isG2G,
																						"ADJ_CODE": vm.editableTotals[headerKey][rowNo].ADJ_TYPE, "ADJ_YEAR":  vm.editableTotals[headerKey][rowNo].ADJ_YEAR,
																						"EDITED_PTI_TYPE": editedPtiType, "PTI_TYPE": ptiType, "OWN_SUB": vm.editableTotals[headerKey][rowNo].OWN_SUB,
																						"BASKET_CODE": basketCode,"BASKET_DESC": basketDesc,"EP_TAX": epTax,"AMT": amt,
																						"SOURCE": source,"HDEFICIT": hdeficit,"NINEOHFOURDCODE": nineohfourdcode,"PASSIVECODE": passivecode,
																						"SUBFCODE": subfcode,"FPHCICODE": fphcicode,"QUAL_DESCRIPTION": qualDescription
																					});
								}
							}
						}
					});
					//vm.loading = true;
					var jsonSettings = [{"ho_leid": vm.hoLeid, "ho_cdr_no": vm.hoTaxCode, "tax_year":GlobalService.globalParams.tax_year,
															 "ghost_scenario": vm.examGhostScenario, "scenario":filterParams.scenario,"jcd_key":GlobalService.globalParams.jcd_key}];
					editAdjustGTAPSAttrsFactory.saveGTAPSAttributes(jsonSettings, editAttributesJson).then(
						function(data) {
							if (data.callSuccess === "1") {
								angular.forEach(vm.editableTotals, function(headerData, headerKey) {
									if (angular.isDefined(headerData[0]) && angular.isDefined(headerData[0].AMOUNT_DATA)) {
											angular.forEach(headerData[0].AMOUNT_DATA, function(basketRelatedColumn, childKey) {
												if (angular.isDefined(basketRelatedColumn.EDITED_TOTAL_AMT)) {
													vm.allTotals[headerKey][childKey].TOTAL_AMT = basketRelatedColumn.EDITED_TOTAL_AMT;
												}
											});
									}
								});
								vm.reset();
								AlertService.add("success", vm.saveSuccess);
							} else {
								AlertService.add("error", data.errorMessage);
							}
							//vm.loading = false;
							$scope.crudLoading = false;
						}
					);
				} else {
					$scope.crudLoading = false;
				}
			};

			vm.cancel = function () {
				$scope.crudLoading = false;
				console.log("in cancel");
				var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
					$rootScope.$emit('gridUpdate', args);  
					$uibModalInstance.dismiss('cancel');
					
					
				/*if(vm.saveFlag)
				{
					var input_line_arr = ['1', '4', '5A', '5B'];
					for(var i = 0; i < input_line_arr.length; i++)
					{
						var line_no = input_line_arr[i];
						if( (vm.scheduleJDetails.length > 0 && vm.scheduleJDetailsOriginal.length > 0) && (angular.isDefined(vm.scheduleJDetails[line_no]) && angular.isDefined(vm.scheduleJDetailsOriginal[line_no]) )  )
							{
							for(var j = 1; j < vm.scheduleJDetails[line_no].length-1; j++ )
								{
								if( angular.isDefined(vm.scheduleJDetails[line_no][j].VALUE) &&  angular.isDefined(vm.scheduleJDetailsOriginal[line_no][j].VALUE) )
										{
											if(vm.scheduleJDetails[line_no][j].VALUE != vm.scheduleJDetailsOriginal[line_no][j].VALUE)
											{
												console.log("in if");
												vm.saveFlag = false;
												break;
											}
										}
								}
							}
						if(!vm.saveFlag)
							break;
					}
				}
				console.log("vm.saveFlag---", vm.saveFlag);
				//console.log("in cancel");
				if(vm.saveFlag)
				{
					var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
						$rootScope.$emit('gridUpdate', args);  
						$uibModalInstance.dismiss('cancel');
				}
				else
				{
					var return_value = $window.confirm('Changes has been made in the screen below. Do you want to continue without saving data? \n\n 1.To Continue, Click Ok \n 2.To reurn to the Screen, click Cancel.')
					if(return_value)
					{
						var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
								$rootScope.$emit('gridUpdate', args);  
								$uibModalInstance.dismiss('cancel');	
					}
					//})
					//alert('');
				}*/
			};
		}         
});
