define([
    'angular',
    'ui-tree',
    'ui-tree-filter',
    './limitationCtrl',
    './LimitationService',
], function () {
    'use strict';
    return angular.module('app.limitationScreen', ['app.limitationCtrl','app.LimitationService','ui.toggle', 'ngAnimate', 'ui.tree', 'ui.tree-filter'])
                  .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider) {

                  }]);
});


/*function () {
    'use strict';
    return angular.module('app.limitationScreen', ['app.limitationCtrl','app.LimitationService'])
});*/