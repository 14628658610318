define([
    'angular',
    './DataCollectReportController'
], function () {
    'use strict';
    var $dstDataCollectReportState = null;
    var module = angular.module('app.dstDataCollectReport', ['app.DataCollectReportControllers'])
        .provider('$dstDataCollectReportState', ['$stateProvider', function ($stateProvider) {
            this.$get = function () {
                return {
                    addState: function (stateName, url, controllerAs, templatePrefix, api, gridType, params, views) {
                        $entityMgmtAppState.state('data-collect-report.' + stateName, {
                            url: '/' + url,
                            type: gridType,
                            "api": api,
                            templateUrl: templatePrefix ? templatePrefix : null,
                            controller: controllerAs ? controllerAs : null,
                            params: params,
                            resolve: {},
                            views: views
                        });
                    }
                };
            }
        }])
        .config(['$stateProvider', function ($stateProvider) {
            $dstDataCollectReportState = $stateProvider;
            $stateProvider.state('data-collect-report', {
                url: '/data-collect-report',
                "templateUrl": "app/components/DST/diagnostics/data-collect-report/dataCollectReport.html",
                "controller": "DataCollectReportCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
