/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.qreentitydetailsController',
					[]).controller(
					'QreEntitydetailsController',
					[ '$rootScope', '$scope', '$http', 'GlobalService',
							'$uibModalInstance', 'ModalFactory',
							'AlertService', 'rowData', 'gridData',
							'JsonObjectFactory', 'QedetailsFactoryA',
							'$timeout', 'TbbsService', 'GENERAL_CONFIG',
							qreentitydetailsController ])

			function qreentitydetailsController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					QedetailsFactoryA, $timeout, tbbsService, GENERAL_CONFIG) {

				var vm = this || thisObj;
				$scope.rowData = rowData || $scope.$parent.rowData;
				vm.rowData = rowData || $scope.$parent.rowData;
				vm.current_year_tax_value = GlobalService.globalParams.tax_year;
				// workspaceFactory.activeScreen.filters.getFilterParamsFromCache()

				// qre summary titles;
				vm.c1 = vm.current_year_tax_value
						+ " - Current Year R&D Exp. (100%)";
				vm.c2 = vm.current_year_tax_value + " - Current Year QRE";
				vm.c3 = vm.current_year_tax_value - 1 + " - Base Year QRE";
				vm.c4 = vm.current_year_tax_value - 2 + " - Base Year QRE";
				vm.c5 = vm.current_year_tax_value - 3 + " - Base Year QRE";

				// qre tab titlese
				vm.t1 = vm.current_year_tax_value + " - Current Year"
				vm.t2 = vm.current_year_tax_value - 1 + " - Base Year"
				vm.t3 = vm.current_year_tax_value - 2 + " - Base Year"
				vm.t4 = vm.current_year_tax_value - 3 + " - Base Year"
				// 3rd tab titles
				vm.t3title_value = vm.current_year_tax_value - 1;
				vm.t3title = "Adj In " + vm.t3title_value;
				vm.t4title1_value = vm.current_year_tax_value - 2;
				vm.t4title1 = "Adj In " + vm.t4title1_value;
				vm.t4title2_value = vm.current_year_tax_value - 1;
				vm.t4title2 = "Adj In " + vm.t4title2_value;

				vm.template_data = [];
				vm.template_data = [ vm.t2, vm.t3, vm.t4 ];
				// cy adj total
				// cy applicable adj total
				vm.total_header_tab = vm.current_year_tax_value + " Adj Total";
				vm.total_applicable_header_tab = vm.current_year_tax_value
						+ " Applicable Adj Total";

				$scope.crudLoading = false;

				$scope.noRecords = false;
				// $scope.colData = colData;

				if ($uibModalInstance) {
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
							: null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				vm.changeSystemacct = function(value) {
					var copy_key = "";

					if (vm.tabindex_value == 1) {
						if (value == vm.t3) {
							// tab2 by1 copy from tab3 by 3
							vm.tab2array = [];

							vm.tab2array = JSON.parse(JSON
									.stringify(vm.tab3array));

							for ( var af in vm.tab2array) {
								vm.tab2array[af].display_dropdown_tab2 = false;
								// vm.tab2array[af].TAB_YEAR = "BY1";
								vm.tab2array[af].INCLUDE_IN_CALC_BY1 = vm.tab2array[af].INCLUDE_IN_CALC_BY2;
								vm.tab2array[af].TAB_YEAR_BY1 = "BY1";
								vm.tab2array[af].MAP_KEY_BY1 = "";
								vm.tab2array[af].LINE_DESC_BY1 = vm.tab2array[af].LINE_DESC_BY2;
								vm.tab2array[af].LINE_KEY_BY1 = vm.tab2array[af].LINE_KEY_BY2;
								vm.tab2array[af].APPLICABLE_PERCENT_BY1 = vm.tab2array[af].APPLICABLE_PERCENT_BY2;
								vm.tab2array[af].RAF_BY1 = vm.tab2array[af].RAF_BY2;
								vm.tab2array[af].ADJ_CY_1_BY1 = vm.tab2array[af].ADJ_CY_1_BY2;
								vm.tab2array[af].ADJ_CY_EXAM_BY1 = vm.tab2array[af].ADJ_CY_EXAM_BY2;
								vm.tab2array[af].ADJ_CY_CONSITENCY_BY1 = vm.tab2array[af].ADJ_CY_CONSITENCY_BY2;
								vm.tab2array[af].ADJ_CY_ACQUISITION_BY1 = vm.tab2array[af].ADJ_CY_ACQUISITION_BY2;
								vm.tab2array[af].ADJ_CY_DISPOSITION_BY1 = vm.tab2array[af].ADJ_CY_DISPOSITION_BY2;
								vm.tab2array[af].ADJ_CY_ANNUALIZATION_BY1 = vm.tab2array[af].ADJ_CY_ANNUALIZATION_BY2;
								vm.tab2array[af].ADJ_CY_PTP_BY1 = vm.tab2array[af].ADJ_CY_PTP_BY2;
								vm.tab2array[af].ADJ_TOTAL_BY1 = vm.tab2array[af].ADJ_TOTAL_BY2;
								vm.tab2array[af].ADJ_TOTAL_APPLICABLE_BY1 = vm.tab2array[af].ADJ_TOTAL_APPLICABLE_BY2;
								vm.tab2array[af].ADJ_TOTAL_SUMMARY_BY1 = vm.tab2array[af].ADJ_TOTAL_SUMMARY_BY2;
								vm.tab2array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1 = vm.tab2array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2;

								vm.tab2array[af].ADJ_CY_EXAM_D_BY1 = vm.tab2array[af].ADJ_CY_EXAM_D_BY2;
								vm.tab2array[af].ADJ_CY_CONSITENCY_D_BY1 = vm.tab2array[af].ADJ_CY_CONSITENCY_D_BY2;
								vm.tab2array[af].ADJ_CY_ACQUISITION_D_BY1 = vm.tab2array[af].ADJ_CY_ACQUISITION_D_BY2;
								vm.tab2array[af].ADJ_CY_DISPOSITION_D_BY1 = vm.tab2array[af].ADJ_CY_DISPOSITION_D_BY2;
								vm.tab2array[af].ADJ_CY_ANNUALIZATION_D_BY1 = vm.tab2array[af].ADJ_CY_ANNUALIZATION_D_BY2;
								vm.tab2array[af].ADJ_CY_PTP_D_BY1 = vm.tab2array[af].ADJ_CY_PTP_D_BY2;
								vm.tab2array[af].STATE_BY1 = vm.tab2array[af].STATE_BY2;

							}

						}
						if (value == vm.t4) {
							// tab2 by1 copy from tab4 by 3
							vm.tab2array = [];

							vm.tab2array = JSON.parse(JSON
									.stringify(vm.tab4array));

							for ( var af in vm.tab2array) {
								vm.tab2array[af].INCLUDE_IN_CALC_BY1 = vm.tab2array[af].INCLUDE_IN_CALC_BY3;
								vm.tab2array[af].display_dropdown_tab2 = false;
								vm.tab2array[af].TAB_YEAR_BY1 = "BY1";
								vm.tab2array[af].MAP_KEY_BY1 = "";
								vm.tab2array[af].LINE_DESC_BY1 = vm.tab2array[af].LINE_DESC_BY3;
								vm.tab2array[af].LINE_KEY_BY1 = vm.tab2array[af].LINE_KEY_BY3;
								vm.tab2array[af].APPLICABLE_PERCENT_BY1 = vm.tab2array[af].APPLICABLE_PERCENT_BY3;
								vm.tab2array[af].RAF_BY1 = vm.tab2array[af].RAF_BY3;
								vm.tab2array[af].ADJ_CY_1_BY1 = vm.tab2array[af].ADJ_CY_1_BY3;
								vm.tab2array[af].ADJ_CY_EXAM_BY1 = vm.tab2array[af].ADJ_CY_EXAM_BY3;
								vm.tab2array[af].ADJ_CY_CONSITENCY_BY1 = vm.tab2array[af].ADJ_CY_CONSITENCY_BY3;
								vm.tab2array[af].ADJ_CY_ACQUISITION_BY1 = vm.tab2array[af].ADJ_CY_ACQUISITION_BY3;
								vm.tab2array[af].ADJ_CY_DISPOSITION_BY1 = vm.tab2array[af].ADJ_CY_DISPOSITION_BY3;
								vm.tab2array[af].ADJ_CY_ANNUALIZATION_BY1 = vm.tab2array[af].ADJ_CY_ANNUALIZATION_BY3;
								vm.tab2array[af].ADJ_TOTAL_BY1 = vm.tab2array[af].ADJ_TOTAL_BY3;
								vm.tab2array[af].ADJ_TOTAL_APPLICABLE_BY1 = vm.tab2array[af].ADJ_TOTAL_APPLICABLE_BY3;
								vm.tab2array[af].ADJ_CY_PTP_BY1 = vm.tab2array[af].ADJ_CY_PTP_BY3;
								vm.tab2array[af].ADJ_TOTAL_SUMMARY_BY1 = vm.tab2array[af].ADJ_TOTAL_SUMMARY_BY3;
								vm.tab2array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1 = vm.tab2array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3;

								vm.tab2array[af].ADJ_CY_EXAM_D_BY1 = vm.tab2array[af].ADJ_CY_EXAM_D_BY3;
								vm.tab2array[af].ADJ_CY_CONSITENCY_D_BY1 = vm.tab2array[af].ADJ_CY_CONSITENCY_D_BY3;
								vm.tab2array[af].ADJ_CY_ACQUISITION_D_BY1 = vm.tab2array[af].ADJ_CY_ACQUISITION_D_BY3;
								vm.tab2array[af].ADJ_CY_DISPOSITION_D_BY1 = vm.tab2array[af].ADJ_CY_DISPOSITION_D_BY3;
								vm.tab2array[af].ADJ_CY_ANNUALIZATION_D_BY1 = vm.tab2array[af].ADJ_CY_ANNUALIZATION_D_BY3;
								vm.tab2array[af].ADJ_CY_PTP_D_BY1 = vm.tab2array[af].ADJ_CY_PTP_D_BY3;
								vm.tab2array[af].STATE_BY1 = vm.tab2array[af].STATE_BY3;

							}

						}
					}
					if (vm.tabindex_value == 2) {
						if (value == vm.t2) {
							vm.tab3array = [];

							vm.tab3array = JSON.parse(JSON
									.stringify(vm.tab2array));

							for ( var af in vm.tab3array) {
								vm.tab3array[af].INCLUDE_IN_CALC_BY2 = vm.tab3array[af].INCLUDE_IN_CALC_BY1;
								vm.tab3array[af].display_dropdown_tab3 = false;
								vm.tab3array[af].TAB_YEAR_BY2 = "BY2";
								vm.tab3array[af].MAP_KEY_BY2 = "";
								vm.tab3array[af].LINE_DESC_BY2 = vm.tab3array[af].LINE_DESC_BY1;
								vm.tab3array[af].LINE_KEY_BY2 = vm.tab3array[af].LINE_KEY_BY1;
								vm.tab3array[af].APPLICABLE_PERCENT_BY2 = vm.tab3array[af].APPLICABLE_PERCENT_BY1;
								vm.tab3array[af].RAF_BY2 = vm.tab3array[af].RAF_BY1;
								vm.tab3array[af].ADJ_CY_1_BY2 = vm.tab3array[af].ADJ_CY_1_BY1;
								vm.tab3array[af].ADJ_CY_EXAM_BY2 = vm.tab3array[af].ADJ_CY_EXAM_BY1;
								vm.tab3array[af].ADJ_CY_CONSITENCY_BY2 = vm.tab3array[af].ADJ_CY_CONSITENCY_BY1;
								vm.tab3array[af].ADJ_CY_ACQUISITION_BY2 = vm.tab3array[af].ADJ_CY_ACQUISITION_BY1;
								vm.tab3array[af].ADJ_CY_DISPOSITION_BY2 = vm.tab3array[af].ADJ_CY_DISPOSITION_BY1;
								vm.tab3array[af].ADJ_CY_ANNUALIZATION_BY2 = vm.tab3array[af].ADJ_CY_ANNUALIZATION_BY1;
								vm.tab3array[af].ADJ_CY_PTP_BY2 = vm.tab3array[af].ADJ_CY_PTP_BY1;
								vm.tab3array[af].ADJ_TOTAL_BY2 = vm.tab3array[af].ADJ_TOTAL_BY1;
								vm.tab3array[af].ADJ_TOTAL_APPLICABLE_BY2 = vm.tab3array[af].ADJ_TOTAL_APPLICABLE_BY1;
								vm.tab3array[af].ADJ_TOTAL_SUMMARY_BY2 = vm.tab3array[af].ADJ_TOTAL_SUMMARY_BY1;
								vm.tab3array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2 = vm.tab3array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1;

								vm.tab3array[af].ADJ_CY_EXAM_D_BY2 = vm.tab3array[af].ADJ_CY_EXAM_D_BY1;
								vm.tab3array[af].ADJ_CY_CONSITENCY_D_BY2 = vm.tab3array[af].ADJ_CY_CONSITENCY_D_BY1;
								vm.tab3array[af].ADJ_CY_ACQUISITION_D_BY2 = vm.tab3array[af].ADJ_CY_ACQUISITION_D_BY1;
								vm.tab3array[af].ADJ_CY_DISPOSITION_D_BY2 = vm.tab3array[af].ADJ_CY_DISPOSITION_D_BY1;
								vm.tab3array[af].ADJ_CY_ANNUALIZATION_D_BY2 = vm.tab3array[af].ADJ_CY_ANNUALIZATION_D_BY1;
								vm.tab3array[af].ADJ_CY_PTP_D_BY2 = vm.tab3array[af].ADJ_CY_PTP_D_BY1;
								vm.tab3array[af].STATE_BY2 = vm.tab3array[af].STATE_BY1;

							}

						}
						if (value == vm.t4) {
							vm.tab3array = [];

							vm.tab3array = JSON.parse(JSON
									.stringify(vm.tab4array));

							for ( var af in vm.tab3array) {
								vm.tab3array[af].INCLUDE_IN_CALC_BY2 = vm.tab3array[af].INCLUDE_IN_CALC_BY3;
								vm.tab3array[af].display_dropdown_tab3 = false;
								vm.tab3array[af].TAB_YEAR_BY2 = "BY2";
								vm.tab3array[af].MAP_KEY_BY2 = "";
								vm.tab3array[af].LINE_DESC_BY2 = vm.tab3array[af].LINE_DESC_BY3;
								vm.tab3array[af].LINE_KEY_BY2 = vm.tab3array[af].LINE_KEY_BY3;
								vm.tab3array[af].APPLICABLE_PERCENT_BY2 = vm.tab3array[af].APPLICABLE_PERCENT_BY3;
								vm.tab3array[af].RAF_BY2 = vm.tab3array[af].RAF_BY3;
								vm.tab3array[af].ADJ_CY_1_BY2 = vm.tab3array[af].ADJ_CY_1_BY3;
								vm.tab3array[af].ADJ_CY_EXAM_BY2 = vm.tab3array[af].ADJ_CY_EXAM_BY3;
								vm.tab3array[af].ADJ_CY_CONSITENCY_BY2 = vm.tab3array[af].ADJ_CY_CONSITENCY_BY3;
								vm.tab3array[af].ADJ_CY_ACQUISITION_BY2 = vm.tab3array[af].ADJ_CY_ACQUISITION_BY3;
								vm.tab3array[af].ADJ_CY_DISPOSITION_BY2 = vm.tab3array[af].ADJ_CY_DISPOSITION_BY3;
								vm.tab3array[af].ADJ_CY_ANNUALIZATION_BY2 = vm.tab3array[af].ADJ_CY_ANNUALIZATION_BY3;
								vm.tab3array[af].ADJ_TOTAL_BY2 = vm.tab3array[af].ADJ_TOTAL_BY3;
								vm.tab3array[af].ADJ_TOTAL_APPLICABLE_BY2 = vm.tab3array[af].ADJ_TOTAL_APPLICABLE_BY3;
								vm.tab3array[af].ADJ_CY_PTP_BY2 = vm.tab3array[af].ADJ_CY_PTP_BY3;
								vm.tab3array[af].ADJ_TOTAL_SUMMARY_BY2 = vm.tab3array[af].ADJ_TOTAL_SUMMARY_BY3;
								vm.tab3array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2 = vm.tab3array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3;

								vm.tab3array[af].ADJ_CY_EXAM_D_BY2 = vm.tab3array[af].ADJ_CY_EXAM_D_BY3;
								vm.tab3array[af].ADJ_CY_CONSITENCY_D_BY2 = vm.tab3array[af].ADJ_CY_CONSITENCY_D_BY3;
								vm.tab3array[af].ADJ_CY_ACQUISITION_D_BY2 = vm.tab3array[af].ADJ_CY_ACQUISITION_D_BY3;
								vm.tab3array[af].ADJ_CY_DISPOSITION_D_BY2 = vm.tab3array[af].ADJ_CY_DISPOSITION_D_BY3;
								vm.tab3array[af].ADJ_CY_ANNUALIZATION_D_BY2 = vm.tab3array[af].ADJ_CY_ANNUALIZATION_D_BY3;
								vm.tab3array[af].ADJ_CY_PTP_D_BY2 = vm.tab3array[af].ADJ_CY_PTP_D_BY3;
								vm.tab3array[af].STATE_BY2 = vm.tab3array[af].STATE_BY3;

							}
						}
					}
					if (vm.tabindex_value == 3) {
						if (value == vm.t2) {

							vm.tab4array = [];

							vm.tab4array = JSON.parse(JSON
									.stringify(vm.tab2array));

							for ( var af in vm.tab4array) {
								vm.tab4array[af].INCLUDE_IN_CALC_BY3 = vm.tab4array[af].INCLUDE_IN_CALC_BY1;
								vm.tab4array[af].display_dropdown_tab3 = false;
								vm.tab4array[af].TAB_YEAR_BY3 = "BY3";
								vm.tab4array[af].MAP_KEY_BY3 = "";
								vm.tab4array[af].LINE_DESC_BY3 = vm.tab4array[af].LINE_DESC_BY1;
								vm.tab4array[af].LINE_KEY_BY3 = vm.tab4array[af].LINE_KEY_BY1;
								vm.tab4array[af].APPLICABLE_PERCENT_BY3 = vm.tab4array[af].APPLICABLE_PERCENT_BY1;
								vm.tab4array[af].RAF_BY3 = vm.tab4array[af].RAF_BY1;
								vm.tab4array[af].ADJ_CY_1_BY3 = vm.tab4array[af].ADJ_CY_1_BY1;
								vm.tab4array[af].ADJ_CY_2_BY3 = 0;
								vm.tab4array[af].ADJ_CY_EXAM_BY3 = vm.tab4array[af].ADJ_CY_EXAM_BY1;
								vm.tab4array[af].ADJ_CY_CONSITENCY_BY3 = vm.tab4array[af].ADJ_CY_CONSITENCY_BY1;
								vm.tab4array[af].ADJ_CY_ACQUISITION_BY3 = vm.tab4array[af].ADJ_CY_ACQUISITION_BY1;
								vm.tab4array[af].ADJ_CY_DISPOSITION_BY3 = vm.tab4array[af].ADJ_CY_DISPOSITION_BY1;
								vm.tab4array[af].ADJ_CY_ANNUALIZATION_BY3 = vm.tab4array[af].ADJ_CY_ANNUALIZATION_BY1;
								vm.tab4array[af].ADJ_TOTAL_BY3 = vm.tab4array[af].ADJ_TOTAL_BY1;
								vm.tab4array[af].ADJ_TOTAL_APPLICABLE_BY3 = vm.tab4array[af].ADJ_TOTAL_APPLICABLE_BY1;
								vm.tab4array[af].ADJ_CY_PTP_BY3 = vm.tab4array[af].ADJ_CY_PTP_BY1;
								vm.tab4array[af].ADJ_TOTAL_SUMMARY_BY3 = vm.tab4array[af].ADJ_TOTAL_SUMMARY_BY1;
								vm.tab4array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3 = vm.tab4array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1;

								vm.tab4array[af].ADJ_CY_EXAM_D_BY3 = vm.tab4array[af].ADJ_CY_EXAM_D_BY1;
								vm.tab4array[af].ADJ_CY_CONSITENCY_D_BY3 = vm.tab4array[af].ADJ_CY_CONSITENCY_D_BY1;
								vm.tab4array[af].ADJ_CY_ACQUISITION_D_BY3 = vm.tab4array[af].ADJ_CY_ACQUISITION_D_BY1;
								vm.tab4array[af].ADJ_CY_DISPOSITION_D_BY3 = vm.tab4array[af].ADJ_CY_DISPOSITION_D_BY1;
								vm.tab4array[af].ADJ_CY_ANNUALIZATION_D_BY3 = vm.tab4array[af].ADJ_CY_ANNUALIZATION_D_BY1;
								vm.tab4array[af].ADJ_CY_PTP_D_BY3 = vm.tab4array[af].ADJ_CY_PTP_D_BY1;
								vm.tab4array[af].STATE_BY3 = vm.tab4array[af].STATE_BY1;

							}

						}
						if (value == vm.t3) {
							vm.tab4array = [];

							vm.tab4array = JSON.parse(JSON
									.stringify(vm.tab3array));

							for ( var af in vm.tab4array) {
								vm.tab4array[af].INCLUDE_IN_CALC_BY3 = vm.tab4array[af].INCLUDE_IN_CALC_BY2;
								vm.tab4array[af].display_dropdown_tab3 = false;
								vm.tab4array[af].TAB_YEAR_BY3 = "BY3";
								vm.tab4array[af].MAP_KEY_BY3 = "";
								vm.tab4array[af].LINE_DESC_BY3 = vm.tab4array[af].LINE_DESC_BY2;
								vm.tab4array[af].LINE_KEY_BY3 = vm.tab4array[af].LINE_KEY_BY2;
								vm.tab4array[af].APPLICABLE_PERCENT_BY3 = vm.tab4array[af].APPLICABLE_PERCENT_BY2;
								vm.tab4array[af].RAF_BY3 = vm.tab4array[af].RAF_BY2;
								vm.tab4array[af].ADJ_CY_1_BY3 = vm.tab4array[af].ADJ_CY_1_BY2;
								vm.tab4array[af].ADJ_CY_2_BY3 = vm.tab4array[af].ADJ_CY_1_BY2;
								vm.tab4array[af].ADJ_CY_EXAM_BY3 = vm.tab4array[af].ADJ_CY_EXAM_BY2;
								vm.tab4array[af].ADJ_CY_CONSITENCY_BY3 = vm.tab4array[af].ADJ_CY_CONSITENCY_BY2;
								vm.tab4array[af].ADJ_CY_ACQUISITION_BY3 = vm.tab4array[af].ADJ_CY_ACQUISITION_BY2;
								vm.tab4array[af].ADJ_CY_DISPOSITION_BY3 = vm.tab4array[af].ADJ_CY_DISPOSITION_BY2;
								vm.tab4array[af].ADJ_CY_ANNUALIZATION_BY3 = vm.tab4array[af].ADJ_CY_ANNUALIZATION_BY2;
								vm.tab4array[af].ADJ_TOTAL_BY3 = vm.tab4array[af].ADJ_TOTAL_BY2;
								vm.tab4array[af].ADJ_TOTAL_APPLICABLE_BY3 = vm.tab4array[af].ADJ_TOTAL_APPLICABLE_BY2;
								vm.tab4array[af].ADJ_CY_PTP_BY3 = vm.tab4array[af].ADJ_CY_PTP_BY2;
								vm.tab4array[af].ADJ_TOTAL_SUMMARY_BY3 = vm.tab4array[af].ADJ_TOTAL_SUMMARY_BY2;
								vm.tab4array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3 = vm.tab4array[af].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2;

								vm.tab4array[af].ADJ_CY_EXAM_D_BY3 = vm.tab4array[af].ADJ_CY_EXAM_D_BY2;
								vm.tab4array[af].ADJ_CY_CONSITENCY_D_BY3 = vm.tab4array[af].ADJ_CY_CONSITENCY_D_BY2;
								vm.tab4array[af].ADJ_CY_ACQUISITION_D_BY3 = vm.tab4array[af].ADJ_CY_ACQUISITION_D_BY2;
								vm.tab4array[af].ADJ_CY_DISPOSITION_D_BY3 = vm.tab4array[af].ADJ_CY_DISPOSITION_D_BY2;
								vm.tab4array[af].ADJ_CY_ANNUALIZATION_D_BY3 = vm.tab4array[af].ADJ_CY_ANNUALIZATION_D_BY2;
								vm.tab4array[af].ADJ_CY_PTP_D_BY3 = vm.tab4array[af].ADJ_CY_PTP_D_BY2;
								vm.tab4array[af].STATE_BY3 = vm.tab4array[af].STATE_BY2;

							}

						}
					}
					// / total calulations for qre summary

					// tab1
					/*
					 * var total_curr_cqre = 0; var total_curr_cqre_y = 0;
					 * 
					 * for ( var tab1_total in vm.tab1array) { total_curr_cqre +=
					 * vm.tab1array[tab1_total].RAF_CY; total_curr_cqre_y +=
					 * vm.tab1array[tab1_total].ADJ_TOTAL_CY; }
					 * vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y =
					 * total_curr_cqre_y;
					 * vm.displaySubcategory1[0].CURRENT_YEAR_CQRE =
					 * total_curr_cqre; // tab2 var total_current_one_qre = 0;
					 * var total_current_one_qre_y = 0; for ( var tab2_total in
					 * vm.tab2array) { total_current_one_qre +=
					 * vm.tab2array[tab2_total].ADJ_TOTAL_BY1;
					 * total_current_one_qre_y +=
					 * vm.tab2array[tab2_total].ADJ_TOTAL_APPLICABLE_BY1; }
					 * vm.displaySubcategory1[0].CURRENT_one_QRE =
					 * total_current_one_qre;
					 * vm.displaySubcategory1[0].CURRENT_one_QRE_Y =
					 * total_current_one_qre_y; // tab3 var
					 * total_current_two_qre = 0; var total_current_two_qre_y =
					 * 0;
					 * 
					 * for ( var tab3_total in vm.tab3array) {
					 * total_current_two_qre +=
					 * vm.tab3array[tab3_total].ADJ_TOTAL_BY2;
					 * total_current_two_qre_y +=
					 * vm.tab3array[tab3_total].ADJ_TOTAL_APPLICABLE_BY2; }
					 * vm.displaySubcategory1[0].CURRENT_two_QRE =
					 * total_current_two_qre;
					 * vm.displaySubcategory1[0].CURRENT_two_QRE_Y =
					 * total_current_two_qre_y; // tab4 var
					 * total_current_three_qre = 0; var
					 * total_current_threee_qre_y = 0;
					 * 
					 * for ( var tab4_total in vm.tab4array) {
					 * total_current_three_qre +=
					 * vm.tab4array[tab4_total].ADJ_TOTAL_BY3;
					 * total_current_threee_qre_y +=
					 * vm.tab4array[tab4_total].ADJ_TOTAL_APPLICABLE_BY3; }
					 * vm.displaySubcategory1[0].CURRENT_three_QRE =
					 * total_current_three_qre;
					 * vm.displaySubcategory1[0].CURRENT_three_QRE_Y =
					 * total_current_threee_qre_y; // total calculation :
					 * 
					 * vm.displaySubcategory1[0].CURRENT_TOTAL_QRE =
					 * vm.displaySubcategory1[0].CURRENT_YEAR_CQRE +
					 * vm.displaySubcategory1[0].CURRENT_one_QRE +
					 * vm.displaySubcategory1[0].CURRENT_two_QRE +
					 * vm.displaySubcategory1[0].CURRENT_three_QRE;
					 * vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y =
					 * vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y +
					 * vm.displaySubcategory1[0].CURRENT_one_QRE_Y +
					 * vm.displaySubcategory1[0].CURRENT_two_QRE_Y +
					 * vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
					 */

					// tab1
					var total_curr_cqre = 0;
					var total_curr_cqre_y = 0;

					for ( var tab1_total in vm.tab1array) {
						if (vm.tab1array[tab1_total].INCLUDE_IN_CALC_CY == 'Y') {
							total_curr_cqre += vm.tab1array[tab1_total].RAF_CY;
							total_curr_cqre_y += vm.tab1array[tab1_total].ADJ_TOTAL_CY;
						}
					}
					vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y = total_curr_cqre_y;
					vm.displaySubcategory1[0].CURRENT_YEAR_CQRE = total_curr_cqre;
					// tab2
					var total_current_one_qre = 0;
					var total_current_one_qre_y = 0;
					for ( var tab2_total in vm.tab2array) {
						if (vm.tab2array[tab2_total].INCLUDE_IN_CALC_BY1 == 'Y') {
							total_current_one_qre += vm.tab2array[tab2_total].ADJ_TOTAL_SUMMARY_BY1;
							total_current_one_qre_y += vm.tab2array[tab2_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1;
						}
					}
					vm.displaySubcategory1[0].CURRENT_one_QRE = total_current_one_qre;
					vm.displaySubcategory1[0].CURRENT_one_QRE_Y = total_current_one_qre_y;

					// tab3
					var total_current_two_qre = 0;
					var total_current_two_qre_y = 0;

					for ( var tab3_total in vm.tab3array) {
						if (vm.tab3array[tab3_total].INCLUDE_IN_CALC_BY2 == 'Y') {
							total_current_two_qre += vm.tab3array[tab3_total].ADJ_TOTAL_SUMMARY_BY2;
							total_current_two_qre_y += vm.tab3array[tab3_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2;
						}
					}
					vm.displaySubcategory1[0].CURRENT_two_QRE = total_current_two_qre;
					vm.displaySubcategory1[0].CURRENT_two_QRE_Y = total_current_two_qre_y;

					// tab4
					var total_current_three_qre = 0;
					var total_current_threee_qre_y = 0;

					for ( var tab4_total in vm.tab4array) {
						if (vm.tab4array[tab4_total].INCLUDE_IN_CALC_BY3 == 'Y') {
							total_current_three_qre += vm.tab4array[tab4_total].ADJ_TOTAL_SUMMARY_BY3;
							total_current_threee_qre_y += vm.tab4array[tab4_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3;
						}
					}
					vm.displaySubcategory1[0].CURRENT_three_QRE = total_current_three_qre;
					vm.displaySubcategory1[0].CURRENT_three_QRE_Y = total_current_threee_qre_y;

					// total calculation :

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
				}

				vm.tabindex = function(b) {

					vm.tabindex_value = b;
					if (b == 0) {
						vm.hidecopy = true;
					}
					// 2,3,4
					if (b == 1) {
						vm.template_data = [];
						vm.copy_value = "";
						vm.template_data = [ vm.t3, vm.t4 ];
						vm.hidecopy = false;

					}
					if (b == 2) {
						vm.copy_value = "";
						vm.template_data = [];
						vm.template_data = [ vm.t2, vm.t4 ];
						vm.hidecopy = false;

					}
					if (b == 3) {
						vm.copy_value = "";
						vm.template_data = [];
						vm.template_data = [ vm.t2, vm.t3 ];
						vm.hidecopy = false;

					}

				}
				function init() {

					$scope.crudLoading = false;
					vm.tabindex_value = 0;
					$scope.existingLoading = false;
					$scope.systemaLoading = false;
					$scope.templateLoading = false;
					$scope.noRecords = false;
					vm.hidecopy = true;
					vm.displaySubcategory1 = [];
					vm.delete_data = [];
					vm.displaySubcategory1.push({
						CURRENT_YEAR_CQRE : 0,
						CURRENT_one_QRE : 0,
						CURRENT_two_QRE : 0,
						CURRENT_three_QRE : 0,
						CURRENT_TOTAL_QRE : 0,
						CURRENT_YEAR_CQRE_Y : 0,
						CURRENT_one_QRE_Y : 0,
						CURRENT_two_QRE_Y : 0,
						CURRENT_three_QRE_Y : 0,
						CURRENT_TOTAL_QRE_Y : 0,

					});
					vm.templateError = false;
					vm.systemacctError = false;
					vm.tab1array = [];
					vm.tab2array = [];
					vm.tab3array = [];
					vm.tab4array = [];
					vm.all_line_data = [];
					vm.statedata = [];
					vm.all_state_data = [];
					vm.type = false;
					vm.adj_desc = '';
					// copy array creation

					loadExistingReclass();
					getLinedDetails();
					getstatelateData();
					// getYearDetails();
				}

				function getstatelateData() {
					$scope.stateLoading = true;
					var params = {
						"action_code" : 'g9d8eo',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.statedata = [];
											vm.all_state_data = data.jsonObject;
											for ( var a in data.jsonObject) {

												vm.statedata
														.push(data.jsonObject[a].STATE_NAME);
											}

											vm.statedata = _.uniq(vm.statedata,
													JSON.stringify);
											$scope.stateLoading = false;
										}
									})
				}

				init();

				$scope.reset = function() {
					init();

				};

				vm.save = function() {
					var senobjson = [];
					var tempobj = {};
					if ($scope.crudsaveLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudsaveLoading = true;
					tempobj['tax_year'] = GlobalService.globalParams.tax_year;
					tempobj['scenario'] = GlobalService.globalParams.scenario;
					tempobj['jcd_key'] = GlobalService.globalParams.jcd_key;
					tempobj['combination_key'] = rowData.CY_COMBINATION_KEY;

					for ( var tab1 in vm.tab1array) {
						var new_line_key = 0; // LINE_DESC_CY
						if (vm.tab1array[tab1].LINE_DESC_CY == undefined
								|| vm.tab1array[tab1].LINE_DESC_CY === "") {
							AlertService.add("error",
									"Please Select  Form 6765 Line", 4000);
							$scope.crudsaveLoading = false;
							return;
						}
						if (vm.tab1array[tab1].STATE_CY == undefined
								|| vm.tab1array[tab1].STATE_CY === "") {
							AlertService.add("error", "Please Select  State",
									4000);
							$scope.crudsaveLoading = false;
							return;
						}
						for ( var a1 in vm.all_line_data) {

							if (vm.tab1array[tab1].LINE_DESC_CY == vm.all_line_data[a1].LINE_DESCRIPTION) {
								new_line_key = vm.all_line_data[a1].LINE_ACCT_REFERENCE_KEY;
							}
						}

						var state_value = "";
						for ( var a1s in vm.all_state_data) {

							if (vm.tab1array[tab1].STATE_CY == vm.all_state_data[a1s].STATE_NAME) {
								state_value = vm.all_state_data[a1s].STATE;
							}
						}

						vm.tab1array[tab1].new_state = state_value;
						vm.tab1array[tab1].new_line_key = new_line_key;
						vm.tab1array[tab1].new_line_desc = vm.tab1array[tab1].LINE_DESC_CY;
						vm.tab1array[tab1].new_applicable_percent = vm.tab1array[tab1].APPLICABLE_PERCENT_CY;
						vm.tab1array[tab1].new_raf = vm.tab1array[tab1].RAF_CY;
						vm.tab1array[tab1].new_adj_cy_2 = vm.tab1array[tab1].ADJ_CY_2_CY;
						vm.tab1array[tab1].new_adj_cy_1 = vm.tab1array[tab1].ADJ_CY_1_CY;
						vm.tab1array[tab1].new_adj_cy_exam = vm.tab1array[tab1].ADJ_CY_EXAM_CY;
						vm.tab1array[tab1].new_adj_cy_consitency = vm.tab1array[tab1].ADJ_CY_CONSITENCY_CY;
						vm.tab1array[tab1].new_adj_cy_acquisition = vm.tab1array[tab1].ADJ_CY_ACQUISITION_CY;
						vm.tab1array[tab1].new_adj_cy_disposition = vm.tab1array[tab1].ADJ_CY_DISPOSITION_CY;
						vm.tab1array[tab1].new_adj_total = vm.tab1array[tab1].ADJ_TOTAL_CY;
						vm.tab1array[tab1].new_adj_total_applicable = vm.tab1array[tab1].ADJ_TOTAL_APPLICABLE_CY;

						//
						vm.tab1array[tab1].new_tab_year = vm.tab1array[tab1].TAB_YEAR_CY;
						vm.tab1array[tab1].new_map_key = vm.tab1array[tab1].MAP_KEY_CY;
						vm.tab1array[tab1].type = 'E';

					}

					for ( var tab2 in vm.tab2array) {

						var new_line_key = 0; // LINE_DESC_CY

						if (vm.tab2array[tab2].LINE_DESC_BY1 == undefined
								|| vm.tab2array[tab2].LINE_DESC_BY1 === "") {
							AlertService.add("error",
									"Please Select Form 6765 Line", 4000);
							$scope.crudsaveLoading = false;
							return;
						}

						for ( var a2 in vm.all_line_data) {
							if (vm.tab2array[tab2].LINE_DESC_BY1 == vm.all_line_data[a2].LINE_DESCRIPTION) {
								new_line_key = vm.all_line_data[a2].LINE_ACCT_REFERENCE_KEY;
							}
						}

						var state_value_tab2 = "";
						for ( var a2s in vm.all_state_data) {

							if (vm.tab2array[tab2].STATE_BY1 == vm.all_state_data[a2s].STATE_NAME) {
								state_value_tab2 = vm.all_state_data[a2s].STATE;
							}
						}

						vm.tab2array[tab2].new_line_key = new_line_key;
						vm.tab2array[tab2].new_line_desc = vm.tab2array[tab2].LINE_DESC_BY1;
						vm.tab2array[tab2].new_applicable_percent = vm.tab2array[tab2].APPLICABLE_PERCENT_BY1;
						vm.tab2array[tab2].new_raf = vm.tab2array[tab2].RAF_BY1;
						vm.tab2array[tab2].new_adj_cy_2 = vm.tab2array[tab2].ADJ_CY_2_BY1;
						vm.tab2array[tab2].new_adj_cy_1 = vm.tab2array[tab2].ADJ_CY_1_BY1;
						vm.tab2array[tab2].new_adj_cy_exam = vm.tab2array[tab2].ADJ_CY_EXAM_BY1;
						vm.tab2array[tab2].new_adj_cy_consitency = vm.tab2array[tab2].ADJ_CY_CONSITENCY_BY1;
						vm.tab2array[tab2].new_adj_cy_acquisition = vm.tab2array[tab2].ADJ_CY_ACQUISITION_BY1;
						vm.tab2array[tab2].new_adj_cy_disposition = vm.tab2array[tab2].ADJ_CY_DISPOSITION_BY1;
						vm.tab2array[tab2].new_adj_cy_ptp = vm.tab2array[tab2].ADJ_CY_PTP_BY1;
						vm.tab2array[tab2].new_adj_total = vm.tab2array[tab2].ADJ_TOTAL_BY1;
						vm.tab2array[tab2].new_adj_total_applicable = vm.tab2array[tab2].ADJ_TOTAL_APPLICABLE_BY1;
						// new_adj_cy_annualization
						vm.tab2array[tab2].new_adj_cy_annualization = vm.tab2array[tab2].ADJ_CY_ANNUALIZATION_BY1;
						vm.tab2array[tab2].new_tab_year = vm.tab2array[tab2].TAB_YEAR_BY1;
						vm.tab2array[tab2].new_map_key = vm.tab2array[tab2].MAP_KEY_BY1;

						vm.tab2array[tab2].new_adj_desc_cy_exam = vm.tab2array[tab2].ADJ_CY_EXAM_D_BY1;
						vm.tab2array[tab2].new_adj_desc_cy_consistency = vm.tab2array[tab2].ADJ_CY_CONSITENCY_D_BY1;
						vm.tab2array[tab2].new_adj_desc_cy_acquisition = vm.tab2array[tab2].ADJ_CY_ACQUISITION_D_BY1;
						vm.tab2array[tab2].new_adj_desc_cy_disposition = vm.tab2array[tab2].ADJ_CY_DISPOSITION_D_BY1;
						vm.tab2array[tab2].new_adj_desc_cy_annualization = vm.tab2array[tab2].ADJ_CY_ANNUALIZATION_D_BY1;
						vm.tab2array[tab2].new_adj_desc_cy_ptp = vm.tab2array[tab2].ADJ_CY_PTP_D_BY1;
						vm.tab2array[tab2].new_state = state_value_tab2;
						vm.tab2array[tab2].type = 'E';

					}

					for ( var tab3 in vm.tab3array) {
						var new_line_key = 0; // LINE_DESC_CY

						if (vm.tab3array[tab3].LINE_DESC_BY2 == undefined
								|| vm.tab3array[tab3].LINE_DESC_BY2 === "") {
							AlertService.add("error",
									"Please Select Form 6765 Line", 4000);
							$scope.crudsaveLoading = false;
							return;
						}
						for ( var a3 in vm.all_line_data) {
							if (vm.tab3array[tab3].LINE_DESC_BY2 == vm.all_line_data[a3].LINE_DESCRIPTION) {
								new_line_key = vm.all_line_data[a3].LINE_ACCT_REFERENCE_KEY;
							}
						}

						var state_value_tab3 = "";
						for ( var a3s in vm.all_state_data) {

							if (vm.tab3array[tab3].STATE_BY2 == vm.all_state_data[a3s].STATE_NAME) {
								state_value_tab3 = vm.all_state_data[a3s].STATE;
							}
						}

						vm.tab3array[tab3].new_state = state_value_tab3;
						vm.tab3array[tab3].new_line_key = new_line_key;
						vm.tab3array[tab3].new_line_desc = vm.tab3array[tab3].LINE_DESC_BY2;
						vm.tab3array[tab3].new_applicable_percent = vm.tab3array[tab3].APPLICABLE_PERCENT_BY2;
						vm.tab3array[tab3].new_raf = vm.tab3array[tab3].RAF_BY2;
						vm.tab3array[tab3].new_adj_cy_2 = vm.tab3array[tab3].ADJ_CY_2_BY2;
						vm.tab3array[tab3].new_adj_cy_1 = vm.tab3array[tab3].ADJ_CY_1_BY2;
						vm.tab3array[tab3].new_adj_cy_exam = vm.tab3array[tab3].ADJ_CY_EXAM_BY2;
						vm.tab3array[tab3].new_adj_cy_consitency = vm.tab3array[tab3].ADJ_CY_CONSITENCY_BY2;
						vm.tab3array[tab3].new_adj_cy_acquisition = vm.tab3array[tab3].ADJ_CY_ACQUISITION_BY2;
						vm.tab3array[tab3].new_adj_cy_disposition = vm.tab3array[tab3].ADJ_CY_DISPOSITION_BY2;
						vm.tab3array[tab3].new_adj_cy_ptp = vm.tab3array[tab3].ADJ_CY_PTP_BY2;
						vm.tab3array[tab3].new_adj_total = vm.tab3array[tab3].ADJ_TOTAL_BY2;
						vm.tab3array[tab3].new_adj_total_applicable = vm.tab3array[tab3].ADJ_TOTAL_APPLICABLE_BY2;
						vm.tab3array[tab3].new_adj_cy_annualization = vm.tab3array[tab3].ADJ_CY_ANNUALIZATION_BY2;
						vm.tab3array[tab3].new_tab_year = vm.tab3array[tab3].TAB_YEAR_BY2;
						vm.tab3array[tab3].new_map_key = vm.tab3array[tab3].MAP_KEY_BY2;

						vm.tab3array[tab3].new_adj_desc_cy_exam = vm.tab3array[tab3].ADJ_CY_EXAM_D_BY2;
						vm.tab3array[tab3].new_adj_desc_cy_consistency = vm.tab3array[tab3].ADJ_CY_CONSITENCY_D_BY2;
						vm.tab3array[tab3].new_adj_desc_cy_acquisition = vm.tab3array[tab3].ADJ_CY_ACQUISITION_D_BY2;
						vm.tab3array[tab3].new_adj_desc_cy_disposition = vm.tab3array[tab3].ADJ_CY_DISPOSITION_D_BY2;
						vm.tab3array[tab3].new_adj_desc_cy_annualization = vm.tab3array[tab3].ADJ_CY_ANNUALIZATION_D_BY2;
						vm.tab3array[tab3].new_adj_desc_cy_ptp = vm.tab3array[tab3].ADJ_CY_PTP_D_BY2;
						vm.tab3array[tab3].type = 'E';

					}
					for ( var tab4 in vm.tab4array) {
						var new_line_key = 0; // LINE_DESC_CY
						if (vm.tab4array[tab4].LINE_DESC_BY3 == undefined
								|| vm.tab4array[tab4].LINE_DESC_BY3 === "") {
							AlertService.add("error",
									"Please Select Form 6765 Line", 4000);
							$scope.crudsaveLoading = false;
							return;
						}
						for ( var a4 in vm.all_line_data) {
							if (vm.tab4array[tab4].LINE_DESC_BY3 == vm.all_line_data[a4].LINE_DESCRIPTION) {
								new_line_key = vm.all_line_data[a4].LINE_ACCT_REFERENCE_KEY;
							}
						}

						var state_value_tab4 = "";
						for ( var a4s in vm.all_state_data) {

							if (vm.tab4array[tab4].STATE_BY3 == vm.all_state_data[a4s].STATE_NAME) {
								state_value_tab4 = vm.all_state_data[a4s].STATE;
							}
						}
						vm.tab4array[tab4].new_state = state_value_tab4;

						vm.tab4array[tab4].new_line_key = new_line_key;
						vm.tab4array[tab4].new_line_desc = vm.tab4array[tab4].LINE_DESC_BY3;
						vm.tab4array[tab4].new_applicable_percent = vm.tab4array[tab4].APPLICABLE_PERCENT_BY3;
						vm.tab4array[tab4].new_raf = vm.tab4array[tab4].RAF_BY3;
						vm.tab4array[tab4].new_adj_cy_2 = vm.tab4array[tab4].ADJ_CY_2_BY3;
						vm.tab4array[tab4].new_adj_cy_1 = vm.tab4array[tab4].ADJ_CY_1_BY3;
						vm.tab4array[tab4].new_adj_cy_exam = vm.tab4array[tab4].ADJ_CY_EXAM_BY3;
						vm.tab4array[tab4].new_adj_cy_consitency = vm.tab4array[tab4].ADJ_CY_CONSITENCY_BY3;
						vm.tab4array[tab4].new_adj_cy_acquisition = vm.tab4array[tab4].ADJ_CY_ACQUISITION_BY3;
						vm.tab4array[tab4].new_adj_cy_disposition = vm.tab4array[tab4].ADJ_CY_DISPOSITION_BY3;
						vm.tab4array[tab4].new_adj_cy_ptp = vm.tab4array[tab4].ADJ_CY_PTP_BY3;
						vm.tab4array[tab4].new_adj_total = vm.tab4array[tab4].ADJ_TOTAL_BY3;
						vm.tab4array[tab4].new_adj_total_applicable = vm.tab4array[tab4].ADJ_TOTAL_APPLICABLE_BY3;
						vm.tab4array[tab4].new_adj_cy_annualization = vm.tab4array[tab4].ADJ_CY_ANNUALIZATION_BY3;

						vm.tab4array[tab4].new_tab_year = vm.tab4array[tab4].TAB_YEAR_BY3;
						vm.tab4array[tab4].new_map_key = vm.tab4array[tab4].MAP_KEY_BY3;

						vm.tab4array[tab4].new_adj_desc_cy_exam = vm.tab4array[tab4].ADJ_CY_EXAM_D_BY3;
						vm.tab4array[tab4].new_adj_desc_cy_consistency = vm.tab4array[tab4].ADJ_CY_CONSITENCY_D_BY3;
						vm.tab4array[tab4].new_adj_desc_cy_acquisition = vm.tab4array[tab4].ADJ_CY_ACQUISITION_D_BY3;
						vm.tab4array[tab4].new_adj_desc_cy_disposition = vm.tab4array[tab4].ADJ_CY_DISPOSITION_D_BY3;
						vm.tab4array[tab4].new_adj_desc_cy_annualization = vm.tab4array[tab4].ADJ_CY_ANNUALIZATION_D_BY3;
						vm.tab4array[tab4].new_adj_desc_cy_ptp = vm.tab4array[tab4].ADJ_CY_PTP_D_BY3;
						vm.tab4array[tab4].type = 'E';

					}

					senobjson = vm.tab1array.concat(vm.tab2array, vm.tab3array,
							vm.tab4array, vm.delete_data);
					var message = "Saved Successfully!"
					reclassCRUDHttp(tempobj, senobjson, message);

				};

				function reclassCRUDHttp(tempobj, accountArray, message) {

					QedetailsFactoryA
							.saveTBBSReclass(tempobj, accountArray)
							.then(
									function(result) {

										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudsaveLoading = false;
										} else {
											AlertService.add("success",
													message, 4000);
											// BROADCAST CHANGE
											$uibModalInstance.dismiss('cancel');

											var args = {

												combi_key : $scope.rowData.CY_COMBINATION_KEY,
												gridFilter : {

													CY_COMBINATION_KEY : $scope.rowData.CY_COMBINATION_KEY
												}
											};
											$rootScope
													.$emit('gridUpdate', args);

										}
									});

				}

				function loadExistingReclass() {
					$scope.crudLoading = true;
					vm.tab1array = [];
					vm.tab2array = [];
					vm.tab3array = [];
					vm.tab4array = [];

					var params = {
						"action_code" : 'rvrjpf',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"controlled_grp" : GlobalService.globalParams.controlled_grp,
						"filling_key" : GlobalService.globalParams.filing_key,
						"parent_me_string_modal" : rowData.ME_KEY,
						"combi_key" : rowData.CY_COMBINATION_KEY

					};

					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										vm.displaySubcategory = [];
										vm.displaySubcategory_ALL = data.jsonObject;
										/*
										 * for ( var e in data.jsonObject) { if
										 * (data.jsonObject[e].TYPE == 'EXIST') {
										 * vm.displaySubcategory
										 * .push(data.jsonObject[e]); } else {
										 * vm.displaySubcategory11
										 * .push(data.jsonObject[e].LINE_DESC) } }
										 */

										for ( var a1 in vm.displaySubcategory_ALL) {
											if (vm.displaySubcategory_ALL[a1].TAB_YEAR === "CY") {
												vm.tab1array
														.push(vm.displaySubcategory_ALL[a1]);
											}
											if (vm.displaySubcategory_ALL[a1].TAB_YEAR === "BY1") {
												vm.tab2array
														.push(vm.displaySubcategory_ALL[a1]);

											}
											if (vm.displaySubcategory_ALL[a1].TAB_YEAR === "BY2") {
												vm.tab3array
														.push(vm.displaySubcategory_ALL[a1]);

											}
											if (vm.displaySubcategory_ALL[a1].TAB_YEAR === "BY3") {
												vm.tab4array
														.push(vm.displaySubcategory_ALL[a1]);

											}
										}

										vm.display2017BaseYear = vm.displaySubcategory;
										for ( var aa in vm.tab1array) {
											vm.tab1array[aa].display_dropdown = false;
											vm.tab1array[aa].display_dropdown_state = false;
											vm.tab1array[aa].INCLUDE_IN_CALC_CY = vm.tab1array[aa].INCLUDE_IN_CALC;
											vm.tab1array[aa].old_line_key = vm.tab1array[aa].LINE_KEY;
											vm.tab1array[aa].old_line_desc = vm.tab1array[aa].LINE_DESC;

											vm.tab1array[aa].old_applicable_percent_cy = vm.tab1array[aa].APPLICABLE_PERCENT;
											vm.tab1array[aa].old_raf = vm.tab1array[aa].RAF;
											vm.tab1array[aa].old_adj_cy_2 = vm.tab1array[aa].ADJ_CY_2;
											vm.tab1array[aa].old_adj_cy_1 = vm.tab1array[aa].ADJ_CY_1;
											vm.tab1array[aa].old_adj_cy_exam = vm.tab1array[aa].ADJ_CY_EXAM;
											vm.tab1array[aa].old_adj_cy_consitency = vm.tab1array[aa].ADJ_CY_CONSITENCY;
											vm.tab1array[aa].old_adj_cy_acquisition = vm.tab1array[aa].ADJ_CY_ACQUISITION;
											vm.tab1array[aa].old_adj_cy_disposition = vm.tab1array[aa].ADJ_CY_DISPOSITION;
											vm.tab1array[aa].old_state = vm.tab1array[aa].STATE;
											vm.tab1array[aa].new_state = vm.tab1array[aa].STATE;

											// new values
											vm.tab1array[aa].new_line_key = vm.tab1array[aa].LINE_KEY;
											vm.tab1array[aa].new_line_desc = vm.tab1array[aa].LINE_DESC;

											vm.tab1array[aa].new_applicable_percent = vm.tab1array[aa].APPLICABLE_PERCENT;
											vm.tab1array[aa].new_raf = vm.tab1array[aa].RAF;
											vm.tab1array[aa].new_adj_cy_2 = vm.tab1array[aa].ADJ_CY_2;
											vm.tab1array[aa].new_adj_cy_1 = vm.tab1array[aa].ADJ_CY_1;
											vm.tab1array[aa].new_adj_cy_exam = vm.tab1array[aa].ADJ_CY_EXAM;
											vm.tab1array[aa].new_adj_cy_consitency = vm.tab1array[aa].ADJ_CY_CONSITENCY;
											vm.tab1array[aa].new_adj_cy_acquisition = vm.tab1array[aa].ADJ_CY_ACQUISITION;
											vm.tab1array[aa].new_adj_cy_disposition = vm.tab1array[aa].ADJ_CY_DISPOSITION;

											// htmlvalue set
											vm.tab1array[aa].STATE_CY = vm.tab1array[aa].STATE_NAME;
											vm.tab1array[aa].APPLICABLE_PERCENT_CY = vm.tab1array[aa].APPLICABLE_PERCENT;
											vm.tab1array[aa].LINE_DESC_CY = vm.tab1array[aa].LINE_DESC;
											vm.tab1array[aa].RAF_CY = vm.tab1array[aa].RAF;
											vm.tab1array[aa].ADJ_CY_2_CY = vm.tab1array[aa].ADJ_CY_2;
											vm.tab1array[aa].ADJ_CY_1_CY = vm.tab1array[aa].ADJ_CY_1;
											vm.tab1array[aa].ADJ_CY_EXAM_CY = vm.tab1array[aa].ADJ_CY_EXAM;
											vm.tab1array[aa].ADJ_CY_CONSITENCY_CY = vm.tab1array[aa].ADJ_CY_CONSITENCY;
											vm.tab1array[aa].ADJ_CY_ACQUISITION_CY = vm.tab1array[aa].ADJ_CY_ACQUISITION;
											vm.tab1array[aa].ADJ_CY_DISPOSITION_CY = vm.tab1array[aa].ADJ_CY_DISPOSITION;
											vm.tab1array[aa].ADJ_TOTAL_CY = (vm.tab1array[aa].RAF * vm.tab1array[aa].APPLICABLE_PERCENT) / 100;
											vm.tab1array[aa].ADJ_TOTAL_APPLICABLE_CY = vm.tab1array[aa].ADJ_TOTAL_CY;
											vm.tab1array[aa].old_adj_total = vm.tab1array[aa].ADJ_TOTAL_CY;
											vm.tab1array[aa].old_adj_total_applicable = vm.tab1array[aa].ADJ_TOTAL_APPLICABLE_CY;
											vm.tab1array[aa].new_adj_total = vm.tab1array[aa].ADJ_TOTAL_CY;
											vm.tab1array[aa].new_adj_total_applicable = vm.tab1array[aa].ADJ_TOTAL_APPLICABLE_CY;
											//
											vm.tab1array[aa].TAB_YEAR_CY = vm.tab1array[aa].TAB_YEAR;
											vm.tab1array[aa].MAP_KEY_CY = vm.tab1array[aa].MAP_KEY;

										}

										for ( var bb in vm.tab2array) {
											vm.tab2array[bb].display_dropdown_tab2 = false;
											vm.tab2array[bb].old_line_key = vm.tab2array[bb].LINE_KEY;
											vm.tab2array[bb].new_line_key = vm.tab2array[bb].LINE_KEY;
											vm.tab2array[bb].old_line_desc = vm.tab2array[bb].LINE_DESC;
											vm.tab2array[bb].new_line_desc = vm.tab2array[bb].LINE_DESC;
											vm.tab2array[bb].LINE_DESC_BY1 = vm.tab2array[bb].LINE_DESC;
											vm.tab2array[bb].INCLUDE_IN_CALC_BY1 = vm.tab2array[bb].INCLUDE_IN_CALC;

											vm.tab2array[bb].old_applicable_percent_by1 = vm.tab2array[bb].APPLICABLE_PERCENT;
											vm.tab2array[bb].old_raf_by1 = vm.tab2array[bb].RAF;
											/*
											 * vm.tab2array[bb].old_adj_cy_2_by1 =
											 * vm.tab2array[bb].ADJ_CY_EXAM;
											 * vm.tab2array[bb].old_adj_cy_1_by1 =
											 * vm.tab2array[bb].ADJ_CY_1_BY1;
											 */
											vm.tab2array[bb].old_adj_cy_exam = vm.tab2array[bb].ADJ_CY_EXAM;
											vm.tab2array[bb].old_adj_cy_consitency = vm.tab2array[bb].ADJ_CY_CONSITENCY;
											vm.tab2array[bb].old_adj_cy_acquisition = vm.tab2array[bb].ADJ_CY_ACQUISITION;
											vm.tab2array[bb].old_adj_cy_disposition = vm.tab2array[bb].ADJ_CY_DISPOSITION;
											vm.tab2array[bb].old_cy_annualization = vm.tab2array[bb].ADJ_CY_ANNUALIZATION;

											// new
											vm.tab2array[bb].old_line_key = vm.tab2array[bb].LINE_KEY;
											vm.tab2array[bb].old_applicable_percent_by1 = vm.tab2array[bb].APPLICABLE_PERCENT;
											vm.tab2array[bb].old_raf_by = vm.tab2array[bb].RAF;
											/*
											 * vm.tab2array[bb].old_adj_cy_2_by1 =
											 * vm.tab2array[bb].ADJ_CY_EXAM;
											 * vm.tab2array[bb].old_adj_cy_1_by1 =
											 * vm.tab2array[bb].ADJ_CY_1_BY1;
											 */
											vm.tab2array[bb].new_adj_cy_exam = vm.tab2array[bb].ADJ_CY_EXAM;
											vm.tab2array[bb].new_adj_cy_consitency = vm.tab2array[bb].ADJ_CY_CONSITENCY;
											vm.tab2array[bb].new_adj_cy_acquisition = vm.tab2array[bb].ADJ_CY_ACQUISITION;
											vm.tab2array[bb].new_adj_cy_disposition = vm.tab2array[bb].ADJ_CY_DISPOSITION;
											vm.tab2array[bb].new_cy_annualization = vm.tab2array[bb].ADJ_CY_ANNUALIZATION;

											// htmlvalues

											vm.tab2array[bb].LINE_KEY_BY1 = vm.tab2array[bb].LINE_KEY;
											vm.tab2array[bb].APPLICABLE_PERCENT_BY1 = vm.tab2array[bb].APPLICABLE_PERCENT;
											vm.tab2array[bb].RAF_BY1 = vm.tab2array[bb].RAF;
											vm.tab2array[bb].ADJ_CY_EXAM_BY1 = vm.tab2array[bb].ADJ_CY_EXAM;
											vm.tab2array[bb].ADJ_CY_CONSITENCY_BY1 = vm.tab2array[bb].ADJ_CY_CONSITENCY;
											vm.tab2array[bb].ADJ_CY_ACQUISITION_BY1 = vm.tab2array[bb].ADJ_CY_ACQUISITION;
											vm.tab2array[bb].ADJ_CY_DISPOSITION_BY1 = vm.tab2array[bb].ADJ_CY_DISPOSITION;
											vm.tab2array[bb].ADJ_CY_ANNUALIZATION_BY1 = vm.tab2array[bb].ADJ_CY_ANNUALIZATION;
											vm.tab2array[bb].old_cy_ptp = vm.tab2array[bb].ADJ_CY_PTP;
											vm.tab2array[bb].new_adj_cy_ptp = vm.tab2array[bb].ADJ_CY_PTP;
											vm.tab2array[bb].ADJ_CY_PTP_BY1 = vm.tab2array[bb].ADJ_CY_PTP;
											vm.tab2array[bb].ADJ_TOTAL_BY1 = vm.tab2array[bb].ADJ_CY_EXAM_BY1
													+ vm.tab2array[bb].ADJ_CY_ACQUISITION_BY1
													+ vm.tab2array[bb].ADJ_CY_CONSITENCY_BY1
													+ vm.tab2array[bb].ADJ_CY_DISPOSITION_BY1
													+ vm.tab2array[bb].ADJ_CY_ANNUALIZATION_BY1
													+ vm.tab2array[bb].ADJ_CY_PTP_BY1;

											vm.tab2array[bb].ADJ_TOTAL_APPLICABLE_BY1 = (vm.tab2array[bb].ADJ_TOTAL_BY1 * vm.tab2array[bb].APPLICABLE_PERCENT_BY1) / 100;

											vm.tab2array[bb].old_adj_total_applicable = vm.tab2array[bb].ADJ_TOTAL_APPLICABLE_BY1;
											vm.tab2array[bb].old_adj_total = vm.tab2array[bb].ADJ_TOTAL;
											vm.tab2array[bb].new_adj_total_applicable = vm.tab2array[bb].ADJ_TOTAL_APPLICABLE;
											vm.tab2array[bb].new_adj_total = vm.tab2array[bb].ADJ_TOTAL;
											vm.tab2array[bb].ADJ_TOTAL_SUMMARY_BY1 = vm.tab2array[bb].ADJ_TOTAL_BY1
													+ vm.tab2array[bb].RAF_BY1;
											vm.tab2array[bb].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1 = (vm.tab2array[bb].ADJ_TOTAL_SUMMARY_BY1 * vm.tab2array[bb].APPLICABLE_PERCENT_BY1) / 100;
											//
											vm.tab2array[bb].TAB_YEAR_BY1 = vm.tab2array[bb].TAB_YEAR;
											vm.tab2array[bb].MAP_KEY_BY1 = vm.tab2array[bb].MAP_KEY;

											// old new and display
											vm.tab2array[bb].ADJ_CY_EXAM_D_BY1 = vm.tab2array[bb].ADJ_DESC_CY_EXAM;
											vm.tab2array[bb].ADJ_CY_CONSITENCY_D_BY1 = vm.tab2array[bb].ADJ_DESC_CY_CONSITENCY;
											vm.tab2array[bb].ADJ_CY_ACQUISITION_D_BY1 = vm.tab2array[bb].ADJ_DESC_CY_ACQUISITION;
											vm.tab2array[bb].ADJ_CY_DISPOSITION_D_BY1 = vm.tab2array[bb].ADJ_DESC_CY_DISPOSITION;
											vm.tab2array[bb].ADJ_CY_ANNUALIZATION_D_BY1 = vm.tab2array[bb].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab2array[bb].ADJ_CY_PTP_D_BY1 = vm.tab2array[bb].ADJ_DESC_CY_PTP;

											// old and new

											vm.tab2array[bb].new_adj_desc_cy_exam = vm.tab2array[bb].ADJ_DESC_CY_EXAM;
											vm.tab2array[bb].new_adj_desc_cy_consistency = vm.tab2array[bb].ADJ_DESC_CY_CONSITENCY;
											vm.tab2array[bb].new_adj_desc_cy_acquisition = vm.tab2array[bb].ADJ_DESC_CY_ACQUISITION;
											vm.tab2array[bb].new_adj_desc_cy_disposition = vm.tab2array[bb].ADJ_DESC_CY_DISPOSITION;
											vm.tab2array[bb].new_adj_desc_cy_annualization = vm.tab2array[bb].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab2array[bb].new_adj_desc_cy_ptp = vm.tab2array[bb].ADJ_DESC_CY_PTP;

											vm.tab2array[bb].old_adj_desc_cy_exam = vm.tab2array[bb].ADJ_DESC_CY_EXAM;
											vm.tab2array[bb].old_adj_desc_cy_consistency = vm.tab2array[bb].ADJ_DESC_CY_CONSITENCY;
											vm.tab2array[bb].old_adj_desc_cy_acquisition = vm.tab2array[bb].ADJ_DESC_CY_ACQUISITION;
											vm.tab2array[bb].old_adj_desc_cy_disposition = vm.tab2array[bb].ADJ_DESC_CY_DISPOSITION;
											vm.tab2array[bb].old_adj_desc_cy_annualization = vm.tab2array[bb].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab2array[bb].old_adj_desc_cy_ptp = vm.tab2array[bb].ADJ_DESC_CY_PTP;

											vm.tab2array[bb].old_state = vm.tab2array[bb].STATE;
											vm.tab2array[bb].new_state = vm.tab2array[bb].STATE;
											vm.tab2array[bb].STATE_BY1 = vm.tab2array[bb].STATE_NAME;

										}

										for ( var dd in vm.tab3array) {
											vm.tab3array[dd].display_dropdown_tab3 = false;
											vm.tab3array[dd].old_line_key = vm.tab3array[dd].LINE_KEY;
											vm.tab3array[dd].new_line_key = vm.tab3array[dd].LINE_KEY;
											vm.tab3array[dd].old_line_desc = vm.tab3array[dd].LINE_DESC;
											vm.tab3array[dd].new_line_desc = vm.tab3array[dd].LINE_DESC;
											vm.tab3array[dd].LINE_DESC_BY2 = vm.tab3array[dd].LINE_DESC;
											vm.tab3array[dd].INCLUDE_IN_CALC_BY2 = vm.tab3array[dd].INCLUDE_IN_CALC;

											vm.tab3array[dd].old_applicable_percent = vm.tab3array[dd].APPLICABLE_PERCENT;
											vm.tab3array[dd].old_raf = vm.tab3array[dd].RAF;
											// vm.tab3array[dd].old_adj_cy_2_by1
											// = vm.tab3array[dd].ADJ_CY_2_BY2;
											vm.tab3array[dd].old_adj_cy_1 = vm.tab3array[dd].ADJ_CY_1;
											vm.tab3array[dd].old_adj_cy_exam = vm.tab3array[dd].ADJ_CY_EXAM;
											vm.tab3array[dd].old_adj_cy_consitency = vm.tab3array[dd].ADJ_CY_CONSITENCY;
											vm.tab3array[dd].old_adj_cy_acquisition = vm.tab3array[dd].ADJ_CY_ACQUISITION;
											vm.tab3array[dd].old_adj_cy_disposition = vm.tab3array[dd].ADJ_CY_DISPOSITION;
											vm.tab3array[dd].old_adj_cy_annualization = vm.tab3array[dd].ADJ_CY_ANNUALIZATION;

											// new

											vm.tab3array[dd].new_line_key = vm.tab3array[dd].LINE_KEY;
											vm.tab3array[dd].new_applicable_percent = vm.tab3array[dd].APPLICABLE_PERCENT;
											vm.tab3array[dd].new_raf = vm.tab3array[dd].RAF;
											// vm.tab3array[dd].old_adj_cy_2_by1
											// = vm.tab3array[dd].ADJ_CY_2_BY2;
											vm.tab3array[dd].new_adj_cy_1 = vm.tab3array[dd].ADJ_CY_1;
											vm.tab3array[dd].new_adj_cy_exam = vm.tab3array[dd].ADJ_CY_EXAM;
											vm.tab3array[dd].new_adj_cy_consitency = vm.tab3array[dd].ADJ_CY_CONSITENCY;
											vm.tab3array[dd].new_adj_cy_acquisition = vm.tab3array[dd].ADJ_CY_ACQUISITION;
											vm.tab3array[dd].new_adj_cy_disposition = vm.tab3array[dd].ADJ_CY_DISPOSITION;
											vm.tab3array[dd].new_adj_cy_annualization = vm.tab3array[dd].ADJ_CY_ANNUALIZATION;

											// htmlvalues
											vm.tab3array[dd].LINE_KEY_BY2 = vm.tab3array[dd].LINE_KEY;
											vm.tab3array[dd].APPLICABLE_PERCENT_BY2 = vm.tab3array[dd].APPLICABLE_PERCENT;
											vm.tab3array[dd].RAF_BY2 = vm.tab3array[dd].RAF;
											// vm.tab3array[dd].old_adj_cy_2_by1
											// = vm.tab3array[dd].ADJ_CY_2_BY2;
											vm.tab3array[dd].ADJ_CY_1_BY2 = vm.tab3array[dd].ADJ_CY_1;
											vm.tab3array[dd].ADJ_CY_EXAM_BY2 = vm.tab3array[dd].ADJ_CY_EXAM;
											vm.tab3array[dd].ADJ_CY_CONSITENCY_BY2 = vm.tab3array[dd].ADJ_CY_CONSITENCY;
											vm.tab3array[dd].ADJ_CY_ACQUISITION_BY2 = vm.tab3array[dd].ADJ_CY_ACQUISITION;
											vm.tab3array[dd].ADJ_CY_DISPOSITION_BY2 = vm.tab3array[dd].ADJ_CY_DISPOSITION;
											vm.tab3array[dd].ADJ_CY_ANNUALIZATION_BY2 = vm.tab3array[dd].ADJ_CY_ANNUALIZATION;

											vm.tab3array[dd].old_cy_ptp = vm.tab3array[dd].ADJ_CY_PTP;
											vm.tab3array[dd].new_adj_cy_ptp = vm.tab3array[dd].ADJ_CY_PTP;
											vm.tab3array[dd].ADJ_CY_PTP_BY2 = vm.tab3array[dd].ADJ_CY_PTP;
											vm.tab3array[dd].ADJ_TOTAL_BY2 = vm.tab3array[dd].ADJ_CY_EXAM_BY2
													+ vm.tab3array[dd].ADJ_CY_CONSITENCY_BY2
													+ vm.tab3array[dd].ADJ_CY_ACQUISITION_BY2
													+ vm.tab3array[dd].ADJ_CY_DISPOSITION_BY2
													+ vm.tab3array[dd].ADJ_CY_ANNUALIZATION_BY2
													+ vm.tab3array[dd].ADJ_CY_PTP_BY2;
											vm.tab3array[dd].ADJ_TOTAL_APPLICABLE_BY2 = (vm.tab3array[dd].ADJ_TOTAL_BY2 * vm.tab3array[dd].APPLICABLE_PERCENT_BY2) / 100;
											vm.tab3array[dd].old_adj_total = vm.tab3array[dd].ADJ_TOTAL_BY2;
											vm.tab3array[dd].old_adj_total_applicable = vm.tab3array[dd].ADJ_TOTAL_APPLICABLE_BY2;
											vm.tab3array[dd].new_adj_total = vm.tab3array[dd].ADJ_TOTAL_BY2;
											vm.tab3array[dd].new_adj_total_applicable = vm.tab3array[dd].ADJ_TOTAL_APPLICABLE_BY2;

											vm.tab3array[dd].ADJ_TOTAL_SUMMARY_BY2 = vm.tab3array[dd].ADJ_TOTAL_BY2
													+ vm.tab3array[dd].RAF_BY2
													+ vm.tab3array[dd].ADJ_CY_1_BY2;
											vm.tab3array[dd].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2 = (vm.tab3array[dd].ADJ_TOTAL_SUMMARY_BY2 * vm.tab3array[dd].APPLICABLE_PERCENT_BY2) / 100;
											//
											vm.tab3array[dd].TAB_YEAR_BY2 = vm.tab3array[dd].TAB_YEAR;
											vm.tab3array[dd].MAP_KEY_BY2 = vm.tab3array[dd].MAP_KEY;

											vm.tab3array[dd].ADJ_CY_EXAM_D_BY2 = vm.tab3array[dd].ADJ_DESC_CY_EXAM;
											vm.tab3array[dd].ADJ_CY_CONSITENCY_D_BY2 = vm.tab3array[dd].ADJ_DESC_CY_CONSITENCY;
											vm.tab3array[dd].ADJ_CY_ACQUISITION_D_BY2 = vm.tab3array[dd].ADJ_DESC_CY_ACQUISITION;
											vm.tab3array[dd].ADJ_CY_DISPOSITION_D_BY2 = vm.tab3array[dd].ADJ_DESC_CY_DISPOSITION;
											vm.tab3array[dd].ADJ_CY_ANNUALIZATION_D_BY2 = vm.tab3array[dd].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab3array[dd].ADJ_CY_PTP_D_BY2 = vm.tab3array[dd].ADJ_DESC_CY_PTP;

											// old and new

											vm.tab3array[dd].new_adj_desc_cy_exam = vm.tab3array[dd].ADJ_DESC_CY_EXAM;
											vm.tab3array[dd].new_adj_desc_cy_consistency = vm.tab3array[dd].ADJ_DESC_CY_CONSITENCY;
											vm.tab3array[dd].new_adj_desc_cy_acquisition = vm.tab3array[dd].ADJ_DESC_CY_ACQUISITION;
											vm.tab3array[dd].new_adj_desc_cy_disposition = vm.tab3array[dd].ADJ_DESC_CY_DISPOSITION;
											vm.tab3array[dd].new_adj_desc_cy_annualization = vm.tab3array[dd].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab3array[dd].new_adj_desc_cy_ptp = vm.tab3array[dd].ADJ_DESC_CY_PTP;

											vm.tab3array[dd].old_adj_desc_cy_exam = vm.tab3array[dd].ADJ_DESC_CY_EXAM;
											vm.tab3array[dd].old_adj_desc_cy_consistency = vm.tab3array[dd].ADJ_DESC_CY_CONSITENCY;
											vm.tab3array[dd].old_adj_desc_cy_acquisition = vm.tab3array[dd].ADJ_DESC_CY_ACQUISITION;
											vm.tab3array[dd].old_adj_desc_cy_disposition = vm.tab3array[dd].ADJ_DESC_CY_DISPOSITION;
											vm.tab3array[dd].old_adj_desc_cy_annualization = vm.tab3array[dd].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab3array[dd].old_adj_desc_cy_ptp = vm.tab3array[dd].ADJ_DESC_CY_PTP;

											vm.tab3array[dd].old_state = vm.tab3array[dd].STATE;
											vm.tab3array[dd].new_state = vm.tab3array[dd].STATE;
											vm.tab3array[dd].STATE_BY2 = vm.tab3array[dd].STATE_NAME;

										}
										for ( var cc in vm.tab4array) {
											vm.tab4array[cc].display_dropdown_tab4 = false;
											vm.tab4array[cc].old_line_key = vm.tab4array[cc].LINE_KEY;
											vm.tab4array[cc].new_line_key = vm.tab4array[cc].LINE_KEY;
											vm.tab4array[cc].old_line_desc = vm.tab4array[cc].LINE_DESC;
											vm.tab4array[cc].new_line_desc = vm.tab4array[cc].LINE_DESC;
											vm.tab4array[cc].LINE_DESC_BY3 = vm.tab4array[cc].LINE_DESC;
											vm.tab4array[cc].INCLUDE_IN_CALC_BY3 = vm.tab4array[cc].INCLUDE_IN_CALC;

											vm.tab4array[cc].old_applicable_percent = vm.tab4array[cc].APPLICABLE_PERCENT;
											vm.tab4array[cc].old_raf = vm.tab4array[cc].RAF;
											vm.tab4array[cc].old_adj_cy_2 = vm.tab4array[cc].ADJ_CY_2;
											vm.tab4array[cc].old_adj_cy_1 = vm.tab4array[cc].ADJ_CY_1;
											vm.tab4array[cc].old_adj_cy_exam = vm.tab4array[cc].ADJ_CY_EXAM;
											vm.tab4array[cc].old_adj_cy_consitency = vm.tab4array[cc].ADJ_CY_CONSITENCY;
											vm.tab4array[cc].old_adj_cy_acquisition = vm.tab4array[cc].ADJ_CY_ACQUISITION;
											vm.tab4array[cc].old_adj_cy_disposition = vm.tab4array[cc].ADJ_CY_DISPOSITION;
											vm.tab4array[cc].old_adj_cy_annualization = vm.tab4array[cc].ADJ_CY_ANNUALIZATION;

											// new values
											vm.tab4array[cc].new_line_key = vm.tab4array[cc].LINE_KEY;
											vm.tab4array[cc].new_applicable_percent = vm.tab4array[cc].APPLICABLE_PERCENT;
											vm.tab4array[cc].new_raf = vm.tab4array[cc].RAF;
											vm.tab4array[cc].new_adj_cy_2 = vm.tab4array[cc].ADJ_CY_2;
											vm.tab4array[cc].new_adj_cy_1 = vm.tab4array[cc].ADJ_CY_1;
											vm.tab4array[cc].new_adj_cy_exam = vm.tab4array[cc].ADJ_CY_EXAM;
											vm.tab4array[cc].new_adj_cy_consitency = vm.tab4array[cc].ADJ_CY_CONSITENCY;
											vm.tab4array[cc].new_adj_cy_acquisition = vm.tab4array[cc].ADJ_CY_ACQUISITION;
											vm.tab4array[cc].new_adj_cy_disposition = vm.tab4array[cc].ADJ_CY_DISPOSITION;
											vm.tab4array[cc].new_adj_cy_annualization = vm.tab4array[cc].ADJ_CY_ANNUALIZATION;
											vm.tab4array[cc].new_adj_total = vm.tab4array[cc].ADJ_TOTAL;
											vm.tab4array[cc].new_adj_total_applicable = vm.tab4array[cc].ADJ_TOTAL_APPLICABLE;

											// htmlvalues

											vm.tab4array[cc].LINE_KEY_BY3 = vm.tab4array[cc].LINE_KEY;
											vm.tab4array[cc].APPLICABLE_PERCENT_BY3 = vm.tab4array[cc].APPLICABLE_PERCENT;
											vm.tab4array[cc].RAF_BY3 = vm.tab4array[cc].RAF;
											vm.tab4array[cc].ADJ_CY_2_BY3 = vm.tab4array[cc].ADJ_CY_2;
											vm.tab4array[cc].ADJ_CY_1_BY3 = vm.tab4array[cc].ADJ_CY_1;
											vm.tab4array[cc].ADJ_CY_EXAM_BY3 = vm.tab4array[cc].ADJ_CY_EXAM;
											vm.tab4array[cc].ADJ_CY_CONSITENCY_BY3 = vm.tab4array[cc].ADJ_CY_CONSITENCY;
											vm.tab4array[cc].ADJ_CY_ACQUISITION_BY3 = vm.tab4array[cc].ADJ_CY_ACQUISITION;
											vm.tab4array[cc].ADJ_CY_DISPOSITION_BY3 = vm.tab4array[cc].ADJ_CY_DISPOSITION;
											vm.tab4array[cc].ADJ_CY_ANNUALIZATION_BY3 = vm.tab4array[cc].ADJ_CY_ANNUALIZATION;
											vm.tab4array[cc].old_cy_ptp = vm.tab4array[cc].ADJ_CY_PTP;
											vm.tab4array[cc].new_adj_cy_ptp = vm.tab4array[cc].ADJ_CY_PTP;
											vm.tab4array[cc].ADJ_CY_PTP_BY3 = vm.tab4array[cc].ADJ_CY_PTP;
											vm.tab4array[cc].ADJ_TOTAL_BY3 = vm.tab4array[cc].ADJ_CY_EXAM_BY3
													+ vm.tab4array[cc].ADJ_CY_CONSITENCY_BY3
													+ vm.tab4array[cc].ADJ_CY_DISPOSITION_BY3
													+ vm.tab4array[cc].ADJ_CY_ANNUALIZATION_BY3
													+ vm.tab4array[cc].ADJ_CY_ACQUISITION
													+ vm.tab4array[cc].ADJ_CY_PTP_BY3;
											vm.tab4array[cc].ADJ_TOTAL_APPLICABLE_BY3 = (vm.tab4array[cc].ADJ_TOTAL_BY3 * vm.tab4array[cc].APPLICABLE_PERCENT_BY3) / 100;
											vm.tab4array[cc].old_adj_total = vm.tab4array[cc].ADJ_TOTAL_BY3;
											vm.tab4array[cc].old_adj_total_applicable = vm.tab4array[cc].ADJ_TOTAL_APPLICABLE_BY3;
											vm.tab4array[cc].new_adj_total = vm.tab4array[cc].ADJ_TOTAL_BY3;
											vm.tab4array[cc].new_adj_total_applicable = vm.tab4array[cc].ADJ_TOTAL_APPLICABLE_BY3;

											vm.tab4array[cc].ADJ_TOTAL_SUMMARY_BY3 = vm.tab4array[cc].ADJ_TOTAL_BY3
													+ vm.tab4array[cc].RAF_BY3
													+ vm.tab4array[cc].ADJ_CY_1_BY3
													+ vm.tab4array[cc].ADJ_CY_2_BY3;
											vm.tab4array[cc].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3 = (vm.tab4array[cc].ADJ_TOTAL_SUMMARY_BY3 * vm.tab4array[cc].APPLICABLE_PERCENT_BY3) / 100;
											vm.tab4array[cc].TAB_YEAR_BY3 = vm.tab4array[cc].TAB_YEAR;
											vm.tab4array[cc].MAP_KEY_BY3 = vm.tab4array[cc].MAP_KEY;

											vm.tab4array[cc].ADJ_CY_EXAM_D_BY3 = vm.tab4array[cc].ADJ_DESC_CY_EXAM;
											vm.tab4array[cc].ADJ_CY_CONSITENCY_D_BY3 = vm.tab4array[cc].ADJ_DESC_CY_CONSITENCY;
											vm.tab4array[cc].ADJ_CY_ACQUISITION_D_BY3 = vm.tab4array[cc].ADJ_DESC_CY_ACQUISITION;
											vm.tab4array[cc].ADJ_CY_DISPOSITION_D_BY3 = vm.tab4array[cc].ADJ_DESC_CY_DISPOSITION;
											vm.tab4array[cc].ADJ_CY_ANNUALIZATION_D_BY3 = vm.tab4array[cc].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab4array[cc].ADJ_CY_PTP_D_BY3 = vm.tab4array[cc].ADJ_DESC_CY_PTP;

											// old and new

											vm.tab4array[cc].new_adj_desc_cy_exam = vm.tab4array[cc].ADJ_DESC_CY_EXAM;
											vm.tab4array[cc].new_adj_desc_cy_consistency = vm.tab4array[cc].ADJ_DESC_CY_CONSITENCY;
											vm.tab4array[cc].new_adj_desc_cy_acquisition = vm.tab4array[cc].ADJ_DESC_CY_ACQUISITION;
											vm.tab4array[cc].new_adj_desc_cy_disposition = vm.tab4array[cc].ADJ_DESC_CY_DISPOSITION;
											vm.tab4array[cc].new_adj_desc_cy_annualization = vm.tab4array[cc].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab4array[cc].new_adj_desc_cy_ptp = vm.tab4array[cc].ADJ_DESC_CY_PTP;

											vm.tab4array[cc].old_adj_desc_cy_exam = vm.tab4array[cc].ADJ_DESC_CY_EXAM;
											vm.tab4array[cc].old_adj_desc_cy_consistency = vm.tab4array[cc].ADJ_DESC_CY_CONSITENCY;
											vm.tab4array[cc].old_adj_desc_cy_acquisition = vm.tab4array[cc].ADJ_DESC_CY_ACQUISITION;
											vm.tab4array[cc].old_adj_desc_cy_disposition = vm.tab4array[cc].ADJ_DESC_CY_DISPOSITION;
											vm.tab4array[cc].old_adj_desc_cy_annualization = vm.tab4array[cc].ADJ_DESC_CY_ANNUALIZATION;
											vm.tab4array[cc].old_adj_desc_cy_ptp = vm.tab4array[cc].ADJ_DESC_CY_PTP;

											vm.tab4array[cc].old_state = vm.tab4array[cc].STATE;
											vm.tab4array[cc].new_state = vm.tab4array[cc].STATE;
											vm.tab4array[cc].STATE_BY3 = vm.tab4array[cc].STATE_NAME;

										}

										// tab1
										var total_curr_cqre = 0;
										var total_curr_cqre_y = 0;

										for ( var tab1_total in vm.tab1array) {
											if (vm.tab1array[tab1_total].INCLUDE_IN_CALC_CY == 'Y') {
												total_curr_cqre += vm.tab1array[tab1_total].RAF_CY;
												total_curr_cqre_y += vm.tab1array[tab1_total].ADJ_TOTAL_CY;
											}
										}
										vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y = total_curr_cqre_y;
										vm.displaySubcategory1[0].CURRENT_YEAR_CQRE = total_curr_cqre;
										// tab2
										var total_current_one_qre = 0;
										var total_current_one_qre_y = 0;
										for ( var tab2_total in vm.tab2array) {
											if (vm.tab2array[tab2_total].INCLUDE_IN_CALC_BY1 == 'Y') {
												total_current_one_qre += vm.tab2array[tab2_total].ADJ_TOTAL_SUMMARY_BY1;
												total_current_one_qre_y += vm.tab2array[tab2_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1;
											}
										}
										vm.displaySubcategory1[0].CURRENT_one_QRE = total_current_one_qre;
										vm.displaySubcategory1[0].CURRENT_one_QRE_Y = total_current_one_qre_y;

										// tab3
										var total_current_two_qre = 0;
										var total_current_two_qre_y = 0;

										for ( var tab3_total in vm.tab3array) {
											if (vm.tab3array[tab3_total].INCLUDE_IN_CALC_BY2 == 'Y') {
												total_current_two_qre += vm.tab3array[tab3_total].ADJ_TOTAL_SUMMARY_BY2;
												total_current_two_qre_y += vm.tab3array[tab3_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2;
											}
										}
										vm.displaySubcategory1[0].CURRENT_two_QRE = total_current_two_qre;
										vm.displaySubcategory1[0].CURRENT_two_QRE_Y = total_current_two_qre_y;

										// tab4
										var total_current_three_qre = 0;
										var total_current_threee_qre_y = 0;

										for ( var tab4_total in vm.tab4array) {
											if (vm.tab4array[tab4_total].INCLUDE_IN_CALC_BY3 == 'Y') {
												total_current_three_qre += vm.tab4array[tab4_total].ADJ_TOTAL_SUMMARY_BY3;
												total_current_threee_qre_y += vm.tab4array[tab4_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3;
											}
										}
										vm.displaySubcategory1[0].CURRENT_three_QRE = total_current_three_qre;
										vm.displaySubcategory1[0].CURRENT_three_QRE_Y = total_current_threee_qre_y;

										// total calculation :

										vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
												+ vm.displaySubcategory1[0].CURRENT_one_QRE
												+ vm.displaySubcategory1[0].CURRENT_two_QRE
												+ vm.displaySubcategory1[0].CURRENT_three_QRE;
										vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
												+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
												+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
												+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
										$scope.crudLoading = false;
										/*
										 * if ($scope.reclassArray.length === 0) {
										 * $scope.noRecords = true; }
										 */
									});
				}
				// xkk1gy

				function getLinedDetails() {
					$scope.systemaLoading = true;
					var params = {
						"action_code" : 'xkk1gy',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {
											vm.all_line_data = [];
											vm.all_line_data_dropdown = [];
											vm.all_line_data = data.jsonObject;

											for ( var a in vm.all_line_data) {
												vm.all_line_data_dropdown
														.push(data.jsonObject[a].LINE_DESCRIPTION);
											}

											vm.all_line_data_dropdown = _.uniq(
													vm.all_line_data_dropdown,
													JSON.stringify);
											// $scope.systemaLoading = false;
										}
									})
				}
				vm.returnasQRE = function(index, t) {

					var display_value = 0;

					display_value = (t.RAF_CY * t.APPLICABLE_PERCENT_CY) / 100;

					vm.tab1array[index].ADJ_TOTAL_CY = display_value;

					var total_2018Currennt = 0;
					var total_2018CurrnetQre = 0
					for ( var dd in vm.tab1array) {

						if (vm.tab1array[dd].RAF_CY == undefined
								|| vm.tab1array[dd].RAF_CY == NaN) {
							vm.tab1array[dd].RAF_CY = 0;
						}

						if (vm.tab1array[dd].ADJ_TOTAL_CY == undefined
								|| vm.tab1array[dd].ADJ_TOTAL_CY == NaN) {
							vm.tab1array[dd].ADJ_TOTAL_CY = 0;
						}
						if (vm.tab1array[dd].INCLUDE_IN_CALC_CY == 'Y') {
							total_2018Currennt += vm.tab1array[dd].RAF_CY;
							total_2018CurrnetQre += vm.tab1array[dd].ADJ_TOTAL_CY;
						}
					}
					vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y = total_2018CurrnetQre;
					vm.displaySubcategory1[0].CURRENT_YEAR_CQRE = total_2018Currennt;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;

				}

				vm.oneBaseYear = function(index, t) {
					var total_base_one = 0;
					var total_base_one_y = 0;

					vm.tab2array[index].ADJ_TOTAL_BY1 = vm.tab2array[index].ADJ_CY_EXAM_BY1
							+ vm.tab2array[index].ADJ_CY_ACQUISITION_BY1
							+ vm.tab2array[index].ADJ_CY_CONSITENCY_BY1
							+ vm.tab2array[index].ADJ_CY_DISPOSITION_BY1
							+ vm.tab2array[index].ADJ_CY_ANNUALIZATION_BY1
							+ vm.tab2array[index].ADJ_CY_PTP_BY1;
					vm.tab2array[index].ADJ_TOTAL_APPLICABLE_BY1 = (vm.tab2array[index].ADJ_TOTAL_BY1 * vm.tab2array[index].APPLICABLE_PERCENT_BY1) / 100;

					vm.tab2array[index].ADJ_TOTAL_SUMMARY_BY1 = vm.tab2array[index].ADJ_TOTAL_BY1
							+ vm.tab2array[index].RAF_BY1;
					vm.tab2array[index].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1 = (vm.tab2array[index].ADJ_TOTAL_SUMMARY_BY1 * vm.tab2array[index].APPLICABLE_PERCENT_BY1) / 100;
					for ( var gh in vm.tab2array) {

						if (vm.tab2array[gh].ADJ_TOTAL_SUMMARY_BY1 == undefined
								|| vm.tab2array[gh].ADJ_TOTAL_SUMMARY_BY1 == NaN) {
							vm.tab2array[gh].ADJ_TOTAL_SUMMARY_BY1 = 0;
						}

						if (vm.tab2array[gh].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1 == undefined
								|| vm.tab2array[gh].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1 == NaN) {
							vm.tab2array[gh].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1 = 0;
						}
						if (vm.tab2array[gh].INCLUDE_IN_CALC_BY1 == 'Y') {
							total_base_one += vm.tab2array[gh].ADJ_TOTAL_SUMMARY_BY1;
							total_base_one_y += vm.tab2array[gh].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1;
						}
					}
					vm.displaySubcategory1[0].CURRENT_one_QRE = total_base_one;
					vm.displaySubcategory1[0].CURRENT_one_QRE_Y = total_base_one_y;

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;

				}
				// tab2
				vm.removelinedesc_t2 = function(t) {

					vm.delete_data.push(t);
					for ( var dd in vm.delete_data) {
						if (t.$$hashKey === vm.delete_data[dd].$$hashKey) {
							vm.delete_data[dd].type = 'D';
							vm.delete_data[dd].new_tab_year = t.TAB_YEAR_BY1;
							vm.delete_data[dd].new_map_key = t.MAP_KEY_BY1;
							vm.delete_data[dd].new_raf = t.RAF_BY1;

						}
					}
					for ( var t2 in vm.tab2array) {
						if (t.$$hashKey === vm.tab2array[t2].$$hashKey) {
							vm.tab2array.splice(t2, 1);

						}
					}
					var total_current_one_qre = 0;
					var total_current_one_qre_y = 0;
					for ( var tab2_total in vm.tab2array) {
						if (vm.tab2array[tab2_total].INCLUDE_IN_CALC_BY1 == 'Y') {
							total_current_one_qre += vm.tab2array[tab2_total].ADJ_TOTAL_SUMMARY_BY1;
							total_current_one_qre_y += vm.tab2array[tab2_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY1;
						}
					}
					vm.displaySubcategory1[0].CURRENT_one_QRE = total_current_one_qre;
					vm.displaySubcategory1[0].CURRENT_one_QRE_Y = total_current_one_qre_y;

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
				}
				vm.twoBaseYear = function(index, t) {
					var total_base_two = 0;
					var total_base_two_y = 0;

					vm.tab3array[index].ADJ_TOTAL_BY2 = vm.tab3array[index].ADJ_CY_EXAM_BY2
							+ vm.tab3array[index].ADJ_CY_ACQUISITION_BY2
							+ vm.tab3array[index].ADJ_CY_CONSITENCY_BY2
							+ vm.tab3array[index].ADJ_CY_DISPOSITION_BY2
							+ vm.tab3array[index].ADJ_CY_ANNUALIZATION_BY2
							+ vm.tab3array[index].ADJ_CY_PTP_BY2;
					vm.tab3array[index].ADJ_TOTAL_APPLICABLE_BY2 = (vm.tab3array[index].ADJ_TOTAL_BY2 * vm.tab3array[index].APPLICABLE_PERCENT_BY2) / 100;

					if (vm.tab3array[index].ADJ_CY_1_BY2 == NaN
							|| vm.tab3array[index].ADJ_CY_1_BY2 == undefined
							|| vm.tab3array[index].ADJ_CY_1_BY2 == "") {
						vm.tab3array[index].ADJ_CY_1_BY2 = 0;
					}
					vm.tab3array[index].ADJ_TOTAL_SUMMARY_BY2 = vm.tab3array[index].ADJ_TOTAL_BY2
							+ vm.tab3array[index].RAF_BY2
							+ vm.tab3array[index].ADJ_CY_1_BY2;
					vm.tab3array[index].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2 = (vm.tab3array[index].ADJ_TOTAL_SUMMARY_BY2 * vm.tab3array[index].APPLICABLE_PERCENT_BY2) / 100;

					for ( var gi in vm.tab3array) {

						if (vm.tab3array[gi].ADJ_TOTAL_SUMMARY_BY2 == undefined
								|| vm.tab3array[gi].ADJ_TOTAL_SUMMARY_BY2 == NaN) {
							vm.tab3array[gi].ADJ_TOTAL_SUMMARY_BY2 = 0;
						}

						if (vm.tab3array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2 == undefined
								|| vm.tab3array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2 == NaN) {
							vm.tab3array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2 = 0;
						}
						if (vm.tab3array[gi].INCLUDE_IN_CALC_BY2 == 'Y') {
							total_base_two += vm.tab3array[gi].ADJ_TOTAL_SUMMARY_BY2;
							total_base_two_y += vm.tab3array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2;
						}
					}
					vm.displaySubcategory1[0].CURRENT_two_QRE = total_base_two;
					vm.displaySubcategory1[0].CURRENT_two_QRE_Y = total_base_two_y;

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;

				}

				vm.threeBaseYear = function(index, t) {
					var total_base_three = 0;
					var total_base_three_y = 0;

					vm.tab4array[index].ADJ_TOTAL_BY3 = vm.tab4array[index].ADJ_CY_EXAM_BY3
							+ vm.tab4array[index].ADJ_CY_CONSITENCY_BY3
							+ vm.tab4array[index].ADJ_CY_ACQUISITION_BY3
							+ vm.tab4array[index].ADJ_CY_DISPOSITION_BY3
							+ vm.tab4array[index].ADJ_CY_ANNUALIZATION_BY3
							+ vm.tab4array[index].ADJ_CY_PTP_BY3;
					vm.tab4array[index].ADJ_TOTAL_APPLICABLE_BY3 = (vm.tab4array[index].ADJ_TOTAL_BY3 * vm.tab4array[index].APPLICABLE_PERCENT_BY3) / 100;

					if (vm.tab4array[index].ADJ_CY_1_BY3 == NaN
							|| vm.tab4array[index].ADJ_CY_1_BY3 == undefined
							|| vm.tab4array[index].ADJ_CY_1_BY3 == "") {
						vm.tab4array[index].ADJ_CY_1_BY3 = 0;
					}

					if (vm.tab4array[index].ADJ_CY_2_BY3 == NaN
							|| vm.tab4array[index].ADJ_CY_2_BY3 == undefined
							|| vm.tab4array[index].ADJ_CY_2_BY3 == "") {
					}

					vm.tab4array[index].ADJ_TOTAL_SUMMARY_BY3 = vm.tab4array[index].ADJ_TOTAL_BY3
							+ vm.tab4array[index].RAF_BY3
							+ vm.tab4array[index].ADJ_CY_1_BY3
							+ vm.tab4array[index].ADJ_CY_2_BY3;
					vm.tab4array[index].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3 = (vm.tab4array[index].ADJ_TOTAL_SUMMARY_BY3 * vm.tab4array[index].APPLICABLE_PERCENT_BY3) / 100;
					for ( var gi in vm.tab4array) {

						if (vm.tab4array[gi].ADJ_TOTAL_SUMMARY_BY3 == undefined
								|| vm.tab4array[gi].ADJ_TOTAL_SUMMARY_BY3 == NaN) {
							vm.tab4array[gi].ADJ_TOTAL_SUMMARY_BY3 = 0;
						}

						if (vm.tab4array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3 == undefined
								|| vm.tab4array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3 == NaN) {
							vm.tab4array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3 = 0;
						}
						if (vm.tab4array[gi].INCLUDE_IN_CALC_BY3 == 'Y') {
							total_base_three += vm.tab4array[gi].ADJ_TOTAL_SUMMARY_BY3;
							total_base_three_y += vm.tab4array[gi].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3;
						}
					}
					vm.displaySubcategory1[0].CURRENT_three_QRE = total_base_three;
					vm.displaySubcategory1[0].CURRENT_three_QRE_Y = total_base_three_y;

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;

				}
				vm.removelinedesc_cy = function(t) {

					vm.delete_data.push(t);
					for ( var d1 in vm.delete_data) {
						if (t.$$hashKey === vm.delete_data[d1].$$hashKey) {
							vm.delete_data[d1].type = 'D';
							vm.delete_data[d1].new_tab_year = t.TAB_YEAR_CY;
							vm.delete_data[d1].new_map_key = t.MAP_KEY_CY;
							vm.delete_data[d1].new_raf = t.RAF_CY;

						}
					}
					for ( var re in vm.tab1array) {
						if (t.$$hashKey === vm.tab1array[re].$$hashKey) {
							vm.tab1array.splice(re, 1);

						}
					}

					var total_curr_cqre = 0;
					var total_curr_cqre_y = 0;

					for ( var tab1_total in vm.tab1array) {
						if (vm.tab1array[tab1_total].INCLUDE_IN_CALC_CY == 'Y') {
							total_curr_cqre += vm.tab1array[tab1_total].RAF_CY;
							total_curr_cqre_y += vm.tab1array[tab1_total].ADJ_TOTAL_CY;
						}
					}
					vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y = total_curr_cqre_y;
					vm.displaySubcategory1[0].CURRENT_YEAR_CQRE = total_curr_cqre;

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
				}
				vm.addlinedesc_cy = function() {
					vm.tab1array.push({
						TYPE : "NEW",
						TAB_YEAR_CY : "CY",
						INCLUDE_IN_CALC_CY : "",
						MAP_KEY_CY : "",
						LINE_DESC_CY : "",
						LINE_ACCT_KEY : 0,
						RAF_CY : 0,
						APPLICABLE_PERCENT_CY : 0,
						ADJ_TOTAL_CY : 0,
						old_applicable_percent_cy : 0,
						old_raf : 0,
						old_adj_cy_2 : 0,
						old_adj_cy_1 : 0,
						old_adj_cy_exam : 0,
						old_adj_cy_consitency : 0,
						old_adj_cy_acquisition : 0,
						old_adj_cy_disposition : 0,
						old_adj_total : 0,
						old_adj_total_applicable : 0,
						new_adj_total : 0,
						new_adj_total_applicable : 0,
						old_line_key : 0,
						old_line_desc : "",
						new_line_key : 0,
						new_line_desc : "",
						new_applicable_percent : 0,
						new_raf : 0,
						new_adj_cy_2 : 0,
						new_adj_cy_1 : 0,
						new_adj_cy_exam : 0,
						new_adj_cy_consitency : 0,
						new_adj_cy_acquisition : 0,
						new_adj_cy_disposition : 0,
						new_state : "",
						old_state : "",
						STATE_CY : "",
						display_dropdown : true,
						display_dropdown_state : true

					});
				}

				vm.addlinedesc_t2 = function() {
					vm.tab2array.push({
						TYPE : "NEW",
						TAB_YEAR_BY1 : "BY1",
						INCLUDE_IN_CALC_BY1 : "",
						MAP_KEY_BY1 : "",
						LINE_DESC_BY1 : "",
						LINE_ACCT_KEY : 0,
						RAF_BY1 : 0,
						ADJ_CY_EXAM_BY1 : 0,
						ADJ_CY_CONSITENCY_BY1 : 0,
						ADJ_CY_ACQUISITION_BY1 : 0,
						ADJ_CY_DISPOSITION_BY1 : 0,
						ADJ_CY_ANNUALIZATION_BY1 : 0,
						ADJ_CY_PTP_BY1 : 0,
						ADJ_CY_EXAM_D_BY1 : "",
						ADJ_CY_CONSITENCY_D_BY1 : "",
						ADJ_CY_ACQUISITION_D_BY1 : "",
						ADJ_CY_DISPOSITION_D_BY1 : "",
						ADJ_CY_ANNUALIZATION_D_BY1 : "",
						ADJ_CY_PTP_D_BY1 : "",
						ADJ_TOTAL_BY1 : 0,
						ADJ_TOTAL_APPLICABLE_BY1 : 0,
						APPLICABLE_PERCENT_BY1 : 0,
						old_applicable_percent_cy : 0,
						old_raf : 0,
						old_adj_cy_2 : 0,
						old_adj_cy_1 : 0,
						old_adj_cy_exam : 0,
						old_adj_cy_consitency : 0,
						old_adj_cy_acquisition : 0,
						old_adj_cy_disposition : 0,
						old_adj_total : 0,
						old_adj_total_applicable : 0,
						new_adj_total : 0,
						new_adj_total_applicable : 0,
						old_line_key : 0,
						old_line_desc : "",
						new_line_key : 0,
						new_line_desc : "",
						new_applicable_percent : 0,
						new_raf : 0,
						new_adj_cy_2 : 0,
						new_adj_cy_1 : 0,
						new_adj_cy_exam : 0,
						new_adj_cy_consitency : 0,
						new_adj_cy_acquisition : 0,
						new_adj_cy_disposition : 0,
						old_cy_ptp : 0,
						new_cy_ptp : 0,
						display_dropdown_tab2 : true,
						new_state : "",
						old_state : "",
						STATE_BY1 : "",

					});
				}
				// tab3
				vm.removelinedesc_t3 = function(t) {

					vm.delete_data.push(t);
					for ( var d3 in vm.delete_data) {
						if (t.$$hashKey === vm.delete_data[d3].$$hashKey) {
							vm.delete_data[d3].type = 'D';
							vm.delete_data[d3].new_tab_year = t.TAB_YEAR_BY2;
							vm.delete_data[d3].new_map_key = t.MAP_KEY_BY2;
							vm.delete_data[d3].new_raf = t.RAF_BY2;

						}
					}
					for ( var t3 in vm.tab3array) {
						if (t.$$hashKey === vm.tab3array[t3].$$hashKey) {
							vm.tab3array.splice(t3, 1);

						}
					}
					var total_current_two_qre = 0;
					var total_current_two_qre_y = 0;

					for ( var tab3_total in vm.tab3array) {
						if (vm.tab3array[tab3_total].INCLUDE_IN_CALC_BY2 == 'Y') {
							total_current_two_qre += vm.tab3array[tab3_total].ADJ_TOTAL_SUMMARY_BY2;
							total_current_two_qre_y += vm.tab3array[tab3_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY2;
						}
					}
					vm.displaySubcategory1[0].CURRENT_two_QRE = total_current_two_qre;
					vm.displaySubcategory1[0].CURRENT_two_QRE_Y = total_current_two_qre_y;

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
				}
				vm.addlinedesc_t3 = function() {
					vm.tab3array.push({
						TYPE : "NEW",
						TAB_YEAR_BY2 : "BY2",
						INCLUDE_IN_CALC_BY2 : "",
						LINE_DESC_BY2 : "",
						MAP_KEY_BY2 : "",
						LINE_ACCT_KEY : 0,
						RAF_BY2 : 0,
						ADJ_CY_1_BY2 : 0,
						ADJ_CY_EXAM_BY2 : 0,
						ADJ_CY_CONSITENCY_BY2 : 0,
						ADJ_CY_ACQUISITION_BY2 : 0,
						ADJ_CY_DISPOSITION_BY2 : 0,
						ADJ_CY_ANNUALIZATION_BY2 : 0,
						ADJ_CY_PTP_BY2 : 0,
						ADJ_CY_EXAM_D_BY2 : "",
						ADJ_CY_CONSITENCY_D_BY2 : "",
						ADJ_CY_ACQUISITION_D_BY2 : "",
						ADJ_CY_DISPOSITION_D_BY2 : "",
						ADJ_CY_ANNUALIZATION_D_BY2 : "",
						ADJ_CY_PTP_D_BY2 : "",
						ADJ_TOTAL_BY2 : 0,
						ADJ_TOTAL_APPLICABLE_BY2 : 0,
						APPLICABLE_PERCENT_BY2 : 0,
						old_applicable_percent_cy : 0,
						old_raf : 0,
						old_adj_cy_2 : 0,
						old_adj_cy_1 : 0,
						old_adj_cy_exam : 0,
						old_adj_cy_consitency : 0,
						old_adj_cy_acquisition : 0,
						old_adj_cy_disposition : 0,
						old_adj_total : 0,
						old_adj_total_applicable : 0,
						new_adj_total : 0,
						new_adj_total_applicable : 0,
						old_line_key : 0,
						old_line_desc : "",
						new_line_key : 0,
						new_line_desc : "",
						new_applicable_percent : 0,
						new_raf : 0,
						new_adj_cy_2 : 0,
						new_adj_cy_1 : 0,
						new_adj_cy_exam : 0,
						new_adj_cy_consitency : 0,
						new_adj_cy_acquisition : 0,
						new_adj_cy_disposition : 0,
						old_cy_ptp : 0,
						new_cy_ptp : 0,
						display_dropdown_tab3 : true,
						new_state : "",
						old_state : "",
						STATE_BY2 : ""

					});
				}
				// tab4

				vm.removelinedesc_t4 = function(t) {
					vm.delete_data.push(t);
					for ( var d4 in vm.delete_data) {
						if (t.$$hashKey === vm.delete_data[d4].$$hashKey) {
							vm.delete_data[d4].type = 'D';
							vm.delete_data[d4].new_tab_year = t.TAB_YEAR_BY3;
							vm.delete_data[d4].new_map_key = t.MAP_KEY_BY3;
							vm.delete_data[d4].new_raf = t.RAF_BY3;

						}
					}
					for ( var t4 in vm.tab4array) {
						if (t.$$hashKey === vm.tab4array[t4].$$hashKey) {
							vm.tab4array.splice(t4, 1);

						}
					}

					var total_current_three_qre = 0;
					var total_current_threee_qre_y = 0;

					for ( var tab4_total in vm.tab4array) {
						if (vm.tab4array[tab4_total].INCLUDE_IN_CALC_BY3 == 'Y') {
							total_current_three_qre += vm.tab4array[tab4_total].ADJ_TOTAL_SUMMARY_BY3;
							total_current_threee_qre_y += vm.tab4array[tab4_total].ADJ_TOTAL_APPLICABLE_SUMMARY_BY3;
						}
					}
					vm.displaySubcategory1[0].CURRENT_three_QRE = total_current_three_qre;
					vm.displaySubcategory1[0].CURRENT_three_QRE_Y = total_current_threee_qre_y;

					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE
							+ vm.displaySubcategory1[0].CURRENT_one_QRE
							+ vm.displaySubcategory1[0].CURRENT_two_QRE
							+ vm.displaySubcategory1[0].CURRENT_three_QRE;
					vm.displaySubcategory1[0].CURRENT_TOTAL_QRE_Y = vm.displaySubcategory1[0].CURRENT_YEAR_CQRE_Y
							+ vm.displaySubcategory1[0].CURRENT_one_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_two_QRE_Y
							+ vm.displaySubcategory1[0].CURRENT_three_QRE_Y;
				}
				vm.addlinedesc_t4 = function() {
					vm.tab4array.push({
						TYPE : "NEW",
						TAB_YEAR_BY3 : "BY3",
						INCLUDE_IN_CALC_BY3 : "",
						LINE_DESC_BY3 : "",
						LINE_ACCT_KEY : 0,
						MAP_KEY_BY3 : "",
						RAF_BY3 : 0,
						ADJ_CY_2_BY3 : 0,
						ADJ_CY_1_BY3 : 0,
						ADJ_CY_EXAM_BY3 : 0,
						ADJ_CY_CONSITENCY_BY3 : 0,
						ADJ_CY_ACQUISITION_BY3 : 0,
						ADJ_CY_DISPOSITION_BY3 : 0,
						ADJ_CY_ANNUALIZATION_BY3 : 0,
						ADJ_CY_PTP_BY3 : 0,
						ADJ_CY_EXAM_D_BY3 : 0,
						ADJ_CY_CONSITENCY_D_BY3 : 0,
						ADJ_CY_ACQUISITION_D_BY3 : 0,
						ADJ_CY_DISPOSITION_D_BY3 : 0,
						ADJ_CY_ANNUALIZATION_D_BY3 : 0,
						ADJ_CY_PTP_D_BY3 : 0,
						ADJ_TOTAL_BY3 : 0,
						ADJ_TOTAL_APPLICABLE_BY3 : 0,
						APPLICABLE_PERCENT_BY3 : 0,
						old_applicable_percent_cy : 0,
						old_raf : 0,
						old_adj_cy_2 : 0,
						old_adj_cy_1 : 0,
						old_adj_cy_exam : 0,
						old_adj_cy_consitency : 0,
						old_adj_cy_acquisition : 0,
						old_adj_cy_disposition : 0,
						old_adj_total : 0,
						old_adj_total_applicable : 0,
						new_adj_total : 0,
						new_adj_total_applicable : 0,
						old_line_key : 0,
						old_line_desc : "",
						new_line_key : 0,
						new_line_desc : "",
						new_applicable_percent : 0,
						new_raf : 0,
						new_adj_cy_2 : 0,
						new_adj_cy_1 : 0,
						new_adj_cy_exam : 0,
						new_adj_cy_consitency : 0,
						new_adj_cy_acquisition : 0,
						new_adj_cy_disposition : 0,
						old_cy_ptp : 0,
						new_cy_ptp : 0,
						display_dropdown_tab4 : true,
						new_state : "",
						old_state : "",
						STATE_BY3 : ""

					});
				}
				vm.applicablePer = function(index, t) {

					var applipercent = 0;
					var calc_i_flag = "";
					for ( var c in vm.all_line_data) {
						if (vm.all_line_data[c].LINE_DESCRIPTION == t.LINE_DESC_CY) {
							applipercent = vm.all_line_data[c].APPLICABLE_PERCENT;
							calc_i_flag = vm.all_line_data[c].INCLUDE_IN_CALC;
						}
					}

					for ( var re in vm.tab1array) {
						if (t.$$hashKey === vm.tab1array[re].$$hashKey) {
							vm.tab1array[re].APPLICABLE_PERCENT_CY = applipercent;
							vm.tab1array[re].INCLUDE_IN_CALC_CY = calc_i_flag;
						}
					}
					vm.returnasQRE(index, t);
				}

				// applicableTab2

				vm.applicableTab2 = function(index, t) {

					var applipercent = 0;
					var calc_i_flag = "";
					for ( var c1 in vm.all_line_data) {
						if (vm.all_line_data[c1].LINE_DESCRIPTION == t.LINE_DESC_BY1) {
							applipercent = vm.all_line_data[c1].APPLICABLE_PERCENT;
							calc_i_flag = vm.all_line_data[c1].INCLUDE_IN_CALC;

						}
					}

					for ( var re2 in vm.tab2array) {
						if (t.$$hashKey === vm.tab2array[re2].$$hashKey) {
							vm.tab2array[re2].APPLICABLE_PERCENT_BY1 = applipercent;
							vm.tab2array[re2].INCLUDE_IN_CALC_BY1 = calc_i_flag;
						}
					}
					vm.oneBaseYear(index, t);
				}
				vm.applicableTab3 = function(index, t) {

					var applipercent = 0;
					var calc_i_flag = "";
					for ( var c2 in vm.all_line_data) {
						if (vm.all_line_data[c2].LINE_DESCRIPTION == t.LINE_DESC_BY2) {
							applipercent = vm.all_line_data[c2].APPLICABLE_PERCENT;
							calc_i_flag = vm.all_line_data[c2].INCLUDE_IN_CALC;
						}
					}

					for ( var re3 in vm.tab3array) {
						if (t.$$hashKey === vm.tab3array[re3].$$hashKey) {
							vm.tab3array[re3].APPLICABLE_PERCENT_BY2 = applipercent;
							vm.tab3array[re3].INCLUDE_IN_CALC_BY2 = calc_i_flag;
						}
					}
					vm.twoBaseYear(index, t);
				}
				vm.applicableTab4 = function(index, t) {

					var applipercent = 0;
					var calc_i_flag = "";
					for ( var c3 in vm.all_line_data) {
						if (vm.all_line_data[c3].LINE_DESCRIPTION == t.LINE_DESC_BY3) {
							applipercent = vm.all_line_data[c3].APPLICABLE_PERCENT;
							calc_i_flag = vm.all_line_data[c3].INCLUDE_IN_CALC;

						}
					}

					for ( var re4 in vm.tab4array) {
						if (t.$$hashKey === vm.tab4array[re4].$$hashKey) {
							vm.tab4array[re4].APPLICABLE_PERCENT_BY3 = applipercent;
							vm.tab4array[re4].INCLUDE_IN_CALC_BY3 = calc_i_flag;

						}
					}
					vm.threeBaseYear(index, t);
				}
				$scope.showDetails = function(_index, _boolean) {
					vm.tab2array[_index].open = _boolean;

				};
				$scope.showDetails3 = function(_index, _boolean) {
					vm.tab3array[_index].open = _boolean;

				};
				$scope.showDetails4 = function(_index, _boolean) {
					vm.tab4array[_index].open = _boolean;

				};

			}
			return controllers;

		});