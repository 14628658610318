define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.editAdjustGTAPSAttributesService',[])
       .factory("editAdjustGTAPSAttrsFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   		,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var editAdjustGTAPSAttrsFactory ={};
	           editAdjustGTAPSAttrsFactory.url ={};
	           editAdjustGTAPSAttrsFactory.url.getEditAttrsData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=snqyk8";//Action id 881
	           editAdjustGTAPSAttrsFactory.url.saveAttributesData = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=90pbor";//Action id 1823
	           
		       editAdjustGTAPSAttrsFactory.getEditAdjustAttrsData = function(params) {
		           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		           params = _.merge({},  GlobalService.globalParams , params);
		           params = _.merge({},  params, filterParams);
		           params.process_name =  "GTAPS Get Edit Adjust Attributes Data";
		           var promise = $http({
		        	   method: "post",
		               url: editAdjustGTAPSAttrsFactory.url.getEditAttrsData,
		               data: params
		           }).then(function (response) {
		               //var status = response.status;
		               return response.data;
		           });
		
		           return promise;
	           };
           
              editAdjustGTAPSAttrsFactory.saveGTAPSAttributes = function(settings, editAttributesJson) {
				var jsonSettings =  JSON.stringify(settings);
				var jsonObj = JSON.stringify(editAttributesJson);
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				var params = GlobalService.globalParams;
	            params = _.merge({},  params, filterParams);
	            params =  _.extend({jsonObj:jsonObj}, params);
	            params =  _.extend({jsonSettings:jsonSettings}, params);
				params.process_name =  "GTAPS_EDIT_ATTRS_SAVE";
				var promise = $http({
					method: "post",
					url: editAdjustGTAPSAttrsFactory.url.saveAttributesData,
					data: params
				}).then(function (response) {
					//var status = response.status;
					return response.data;
				});
	
				return promise;
			};
           
	           return editAdjustGTAPSAttrsFactory;
       }]);

   	  return services;
});