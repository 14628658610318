define([
	'angular',
    './auditCtrl',
    './auditService'
    
    ],function () {
        'use strict';
        return angular.module('app.audit', ['app.auditCtrl','app.auditService'])
        
        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider
		        .state('admin.audit-engine', {
		     	   url: '/audit-engine',
		            templateUrl: 'app/components/admin/auditEngine/audit-list-tpl.html',
		            type:'',
		            onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
		            api: {},
		            access: 4
		            
		        });
		        
        }]);
});
