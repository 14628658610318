define([
    'angular'

], function () {
    'use strict';
    var controllers = angular.module('app.updateDirectOwnerController', [])
        .controller('directOwnerCtrl', ['GlobalService','$scope','$rootScope', '$state', '$http', 'ModalFactory', 'AlertService','workspaceFactory',
            'JsonObjectFactory', '$timeout', '$uibModalInstance','NonFinancialFactory','rowData','colData','GENERAL_CONFIG', directOwnerCtrl
        ])
         .controller('taxOwnerCtrl', ['GlobalService','$scope','$rootScope', '$state', '$http', 'ModalFactory', 'AlertService','workspaceFactory',
            'JsonObjectFactory', '$timeout', '$uibModalInstance','NonFinancialFactory','rowData','colData','GENERAL_CONFIG', taxOwnerCtrl
        ]);
        
    function directOwnerCtrl(GlobalService,$scope,$rootScope, $state, $http, ModalFactory, AlertService, workspaceFactory, JsonObjectFactory, $timeout, $uibModalInstance,NonFinancialFactory,rowData,colData,GENERAL_CONFIG) {
        var vm = this;
        vm.title = 'Assign Direct Owner';
        vm.assignOwnerList = {};
        vm.catergozation = []
        vm.rowData = rowData;
        vm.colData = colData;
        vm.owner ={selectedleid:{}};
        vm.isDirectOwnerDataPresent = false;
        vm.DirectOwnerCollapsed = true;
        
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('gtw.supplementaldata.assignModalClose');
        };
 
 
 
 			vm.initLoadData = function(){
                loadDetails();

        };
        vm.initLoadData();
 
 		 ///////////////////////////////////////////
		                
		                function loadDetails() { 
		                	vm.DirectOwnerCollapsed = true;
								 var params = {
							                    "action_code":'eoowhg',//'y5ypqx',
							                    "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
							                     "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
							                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
							                     "combination_key": vm.rowData.COMBINATION_KEY,
							                     "attrib_name":"direct_owner"
							                };
											console.log("rowdata", vm.rowData);
				                            console.log("Combination key ::::::: ",vm.rowData.COMBINATION_KEY);
											return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
												console.log("Response Data x ::::::::", data.jsonObject);
												if (data.callSuccess === "1") {
													vm.isShow = 1;
													vm.directOwnerDetails = data.jsonObject;
                                                    //console.log("length", vm.directOwnerDetails.length);
													vm.directOwnerDetailsTemp = data.jsonObject;
													vm.arraySize = vm.directOwnerDetailsTemp;
													console.log("check this out ::::::: ", vm.directOwnerDetailsTemp);

													for(var i=0; i <data.jsonObject.length; i++) {
													vm.directOwnerDetails[i] = {
														i : vm.directOwnerDetails[i],
														"attrib_name":vm.directOwnerDetails[i].ATTRIB_NAME,
														"line_attrib_key":vm.directOwnerDetails[i].LINE_ATTRIB_KEY,
														"occurrence":vm.directOwnerDetails[i].OCCURRENCE,														
														"VALUE":vm.directOwnerDetails[i].VALUE,
														"flag" :vm.directOwnerDetails[i].FLAG,
														"type": "DIRECT_OWNER"
													};

												}												
													
												} else {
                                                    if (data.errorMessage === "no access") {
                                                        AlertService.add("","Sorry you do not have access to do the requested action.",
                                                            4000);
                                                        vm.userMessage = "!! Unable to perform Selected Action...";
                                                    } else {
                                                        AlertService.add(
                                                            "",
                                                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                                                            4000);
                                                        vm.userMessage = "!! Unable to perform Selected Action...";
                                                    }
                                                }
													
												/*
												for(var i = 0; i<vm.directOwnerDetails.length; i++) {
													var currentObj = vm.directOwnerDetails[i];
													var lineNo = 4;
												}*/
												
												vm.directOwnerObj = [];
												vm.directOwnerArray =  vm.directOwnerDetails;
												if(vm.directOwnerArray.length > 0) {

													_.uniq(_.map(vm.directOwnerArray,'occurrence')).forEach(function(p_occurrence){
													    var occList = _.filter(vm.directOwnerArray,{occurrence:p_occurrence});
													    if(p_occurrence=='0'){
													    	return;
													    }
													    var temp={};
													    occList.forEach(function(currentObj){
													    	/*if(!currentObj['flag'] || currentObj['flag'] == 'D'){
													    		return;
													    	}*/	
												    	temp["LINE_NO"] = 4;
														    temp["occurrence"] = p_occurrence;
													        var value = null;
													        													        
													            temp[currentObj['attrib_name']] = currentObj['VALUE'];
													    });
													    if(temp.LINE_NO){
													    	vm.directOwnerObj.push(temp);
													    }
													});
												}
												vm.isDirectOwnerDataPresent = vm.directOwnerObj.length > 0;
												console.log("check directOwnerObj out ::::::: ", vm.directOwnerObj);
								                vm.DirectOwnerCollapsed = true;
												});
							
                				
						}
						
						Nonget8858Owner();
						//loadDetails();
						
						
						
						
 
        function Nonget8858Owner(){
                var params = {
                    "action_code": "2kw9yc",
                    "combination_key": vm.rowData.combination_key,
                    "leid" : vm.rowData.LEID
                };
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        AlertService.add("error", data.errorMessage);
                        return false;
                    } else {
                        console.log("RESULT",data.jsonObject);
                        vm.assignOwnerList = data.jsonObject;
                         //vm.assignOwnerList = Object.assign({}, ...vm.assignOwnerList);                         
                        console.log ("vm.assignOwnerLIst",vm.assignOwnerList);
                        _.each(vm.assignOwnerList,function(i){
                            var obj = {}  
                            obj.LABEL=i.LABEL;
                            obj.VALUE=i.ID;
                            vm.catergozation.push(obj);
                        });
                        vm.assignOwnerList ={
                            param_name: "assignOwnerList",
                            values: vm.catergozation,
                            label_name: "label",
                            value_name: "value",
                            label: "Direct Owner List",
                            selected:[]
                        }
                        console.log ("vm.catergozation",vm.catergozation);
                        return data.jsonObject;
                    }
                });
            };

        
        
        vm.save = function () {

                var editRow = [];
                var addRow = [];
                var deleteRow = [];
                var scheduleSettings = [{
                	"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                    "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                    "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
                }];
                
                var saveObject = [];
       
         	for (var loopIndex = 0; loopIndex < vm.assignOwnerList.selected.length; loopIndex++){   				
         		saveObject = [{
                        	"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                            "combination_key": vm.rowData.COMBINATION_KEY,
                            "Attribute_Value":vm.assignOwnerList.selected[loopIndex].VALUE,
                            "flag" :'O',
                            "type":"DIRECT_OWNER"
                        }];
                        editRow.push(saveObject);
                    }

                    var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                    var message = "Direct Owner details are successfully saved";


                   NonFinancialFactory.saveDirectOwner(scheduleSettings,
                      scheduleDetails).then(
                       function(result) {

                            if (result.errorMessage
                                && result.errorMessage !== 'null') {
                               AlertService.add("error", result.errorMessage, 4000);
                              
                           } else {
                               vm.saveFlag = true;
                               AlertService.add("success", message, 4000);
                               vm.cancel();
                           }
                        });
                
            };
           
         
                vm.deleteSchedule = function(item)
			            {
			            	//console.log("in delete function & items:-------------------", item); 
			            	var message = "DirectOwner has been deleted";
//			            	var json_arrOBJ = [{
//			            		trans_details_key:item['trans_details_key'], 
//			            		occurence : item['occurrence'],
//			            		
//								
//							}];
				            	var removeRow=[];
				            	vm.directOwnerDetails.filter(function(e){ return e.occurrence == item.occurrence && item.LINE_NO =='4' && e.attrib_name == '8858_NA_DIRECT_OWNER_LEID';})
				            		.forEach(function(e){
				            							removeRow.push([{
				            								occurence:e.occurrence,
				            								"tax_year":GlobalService.globalParams.tax_year,
				            								"leid":e.VALUE,
				            								"type":'DIRECT_OWNER',
				            								"flag":'D',
				            								"combination_key_8858": vm.rowData.COMBINATION_KEY
				            								}]);
				            });
			            	
			            	
							//console.log(json_arrOBJ);
			            	var scheduleDetails = {"addrows":[], "editrows":[], "removerows":removeRow};
			            	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
		                	
			            	 NonFinancialFactory.saveDirectOwner(scheduleSettings, scheduleDetails).then(
			                       function(result) {
			
			                            if (result.errorMessage
			                                && result.errorMessage !== 'null') {
			                               AlertService.add("error", result.errorMessage, 4000);
			                              
			                           } else {
			                               vm.saveFlag = true;
			                               AlertService.add("success", message, 4000);
			                               vm.initLoadData();
			                           }
	                        });
			            };
                
                
    }
    
    function taxOwnerCtrl(GlobalService,$scope,$rootScope, $state, $http, ModalFactory, AlertService, workspaceFactory, JsonObjectFactory, $timeout, $uibModalInstance,NonFinancialFactory,rowData,colData,GENERAL_CONFIG) {
        var vm = this;
        vm.title = 'Assign Tax Owner';
        vm.taxOwnerList = {};
        vm.taxOwner = []
        vm.rowData = rowData;
        vm.colData = colData;
        vm.isTaxOwnerDataPresent = false;
        vm.taxOwnerCollapsed = true;
        
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('gtw.supplementaldata.assignModalClose');
        };
 
 		vm.initLoadData = function(){
        loadDetails();

        };
        vm.initLoadData();
 
 		 ///////////////////////////////////////////
		                
		                function loadDetails() { 
		                	vm.taxOwnerCollapsed = true;
								 var params = {
							                    "action_code":'eoowhg',//'y5ypqx',
							                    "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
							                    "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
							                    "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
							                    "combination_key": vm.rowData.COMBINATION_KEY,
							                    "attrib_name":"tax_owner"
							                };
											console.log("rowdata", vm.rowData);
				                            console.log("Combination key ::::::: ",vm.rowData.COMBINATION_KEY);
											return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
												console.log("Response Data x ::::::::", data.jsonObject);
												if (data.callSuccess === "1") {
													vm.isShow = 1;
													vm.taxOwnerDetails = data.jsonObject;
                                                    //console.log("length", vm.taxOwnerDetails.length);
													vm.taxOwnerDetailsTemp = data.jsonObject;
													vm.arraySize = vm.taxOwnerDetailsTemp;
													console.log("check this out ::::::: ", vm.taxOwnerDetailsTemp);

													for(var i=0; i <data.jsonObject.length; i++) {														

													vm.taxOwnerDetails[i] = {
														i : vm.taxOwnerDetails[i],
														"attrib_name":vm.taxOwnerDetails[i].ATTRIB_NAME,
														"line_attrib_key":vm.taxOwnerDetails[i].LINE_ATTRIB_KEY,
														"occurrence":vm.taxOwnerDetails[i].OCCURRENCE,														
														"VALUE":vm.taxOwnerDetails[i].VALUE,
														"flag" :vm.taxOwnerDetails[i].FLAG
													};

												}												
													
												} else {
                                                    if (data.errorMessage === "no access") {
                                                        AlertService.add("","Sorry you do not have access to do the requested action.",
                                                            4000);
                                                        vm.userMessage = "!! Unable to perform Selected Action...";
                                                    } else {
                                                        AlertService.add(
                                                            "",
                                                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                                                            4000);
                                                        vm.userMessage = "!! Unable to perform Selected Action...";
                                                    }
                                                }
													
												console.log("check : ", vm.taxOwnerDetails);			
												for(var i = 1; i<vm.taxOwnerDetails.length; i++) {
													var currentObj = vm.taxOwnerDetails[i];
													var lineNo = 3;
												}
												
												vm.taxOwnerObj = [];
												
												vm.taxOwnerArray =  vm.taxOwnerDetails; 
												
												if(vm.taxOwnerArray.length > 0) {

													_.uniq(_.map(vm.taxOwnerArray,'occurrence')).forEach(function(p_occurrence){
													    var occList = _.filter(vm.taxOwnerArray,{occurrence:p_occurrence});
													    if(p_occurrence=='0'){
													    	return;
													    }
													    var temp={};
													    occList.forEach(function(currentObj){
													    	if(!currentObj['flag'] || currentObj['flag'] == 'D'){
													    		return;
													    	}
													    	temp["LINE_NO"] = 3;
														    temp["occurrence"] = p_occurrence;
													        var value = null;
													        													        
													            temp[currentObj['attrib_name']] = currentObj['VALUE'];
													    });
													    if(temp.LINE_NO){
													    	vm.taxOwnerObj.push(temp);
													    }
													});
												}
												vm.isTaxOwnerDataPresent = vm.taxOwnerObj.length > 0;
												vm.taxOwnerCollapsed = true;
												
												
												});

						}
						
						
						getTaxOwner();
						
						
						
 
         function getTaxOwner(){
                var params = {
                    "action_code": "2kw9yc",
                    "combination_key": vm.rowData.combination_key,
                    "leid" : "Tax_owner"
                };
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        AlertService.add("error", data.errorMessage);
                        return false;
                    } else {
                        console.log("RESULT",data.jsonObject);
                        vm.taxOwnerList = data.jsonObject;
                         //vm.assignOwnerList = Object.assign({}, ...vm.assignOwnerList);                         
                        console.log ("vm.taxOwnerList",vm.taxOwnerList);
                        _.each(vm.taxOwnerList,function(i){
                            var obj = {}  
                            obj.LABEL=i.LABEL;
                            obj.VALUE=i.ID;
                            vm.taxOwner.push(obj);
                        });
                        vm.taxOwnerList ={
                            param_name: "taxOwnerList",
                            values: vm.taxOwner,
                            label_name: "label",
                            value_name: "value",
                            label: "Tax Owner List",
                            selected:[]
                        }
                        console.log ("vm.taxOwner",vm.taxOwner);
                        return data.jsonObject;
                    }
                });
            };

       
        
        vm.save_tax_owner = function () {

                var editRow = [];
                var addRow = [];
                var deleteRow = [];
                var scheduleSettings = [{
                	"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                    "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                    "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
                }];
                
         
                    var saveObject = [];
         	for (var loopIndex = 0; loopIndex < vm.taxOwnerList.selected.length; loopIndex++){   				
         		saveObject = [{
                        	"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                            "combination_key": vm.rowData.COMBINATION_KEY,
                            "Attribute_Value":vm.taxOwnerList.selected[loopIndex].VALUE,
                            "flag" :'O',
                            "type":"TAX_OWNER"
                        }];
                        editRow.push(saveObject);
		}
               

                    var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                    var message = "Tax Owner details are successfully saved";


                  NonFinancialFactory.saveTaxOwner(scheduleSettings,
                      scheduleDetails).then(
                       function(result) {

                            if (result.errorMessage
                                && result.errorMessage !== 'null') {
                               AlertService.add("error", result.errorMessage, 4000);
                              
                           } else {
                               vm.saveFlag = true;
                               AlertService.add("success", message, 4000);
                               vm.cancel();
                           }
                        });
                
            };
           
           
          
                vm.deleteSchedule = function(item)
			            {
			            	//console.log("in delete function & items:-------------------", item); 
			            	var message = "TaxOwner has been deleted";
//			            	var json_arrOBJ = [{
//			            		trans_details_key:item['trans_details_key'], 
//			            		occurence : item['occurrence'],
//			            		
//								
//					
								var json_arrOBJ=[];
				            	vm.taxOwnerDetails.filter(function(e){ return e.occurrence == item.occurrence && item.LINE_NO =='3' && e.attrib_name == '8858_NA_TAX_OWNER_LEID';})
				            		.forEach(function(e){
				            							json_arrOBJ.push([{
				            								occurence:e.occurrence,
				            								"tax_year":GlobalService.globalParams.tax_year,
				            								"le_key":e.i.HO_LE_KEY,
				            								"type":'TAX_OWNER',
				            								"flag":'D',
				            								"combination_key_8858": vm.rowData.COMBINATION_KEY
				            								}]);
				            });
			            	
			            	
							//console.log(json_arrOBJ);
			            	var scheduleDetails = {"addrows":[], "editrows":[], "removerows":json_arrOBJ};
			            	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
		                	
			            	 NonFinancialFactory.saveTaxOwner(scheduleSettings, scheduleDetails).then(
			                       function(result) {
			
			                            if (result.errorMessage
			                                && result.errorMessage !== 'null') {
			                               AlertService.add("error", result.errorMessage, 4000);
			                              
			                           } else {
			                               vm.saveFlag = true;
			                               AlertService.add("success", message, 4000);
			                               vm.initLoadData();
			                           }
	                        });
			            };
                
                
    };
    return controllers;
});