define([
    'angular',
    './f8865SchM2K1SourcingController'



], function () {
    'use strict';
    return angular.module('app.f8865SchM2K1Sourcing', ['app.f8865SchM2K1SourcingController'] );

});
