define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.retainedEarningsCtrl', [])
            .controller('retainedCtrl', ['$scope','$state', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory','$timeout', '$uibModalInstance', retainedCtrl]);


    function retainedCtrl($scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, $timeout, $uibModalInstance) {
        // Created this Array of object, which is used by Retained Earnings Modal as a parent and child nodes.
        var vm = this;
        vm.modalTitle = "Retained Earnings Conciliation - Increase (Decrease) To Tax Income";
        vm.formEles = [
            {"label":"Balance at beginning of year","type":"text","numberKey":1, "value": "127929289"},
            {"label":"Net Income (loss) per books","type":"text","numberKey":2, "value":"-266098"},
            {"label":"Other Increases (Itemized):","type":"panel","numberKey":3, "childSum": true, "showChildSum": true,
                "childContent":[
                    {"label":"Liquidating Equity","type":"input"},
                    {"label":"Reclassify APIC","type":"input"},
                    {"label":"Pre-acquisition income","type":"input"},
                    {"label":"Purchased Retained Earnings","type":"input"},
                    {"label":"Prior-period Adjustment","type":"input"},
                    {"label":"Transfer of Retained Earnings","type":"input"},
                    {"label":"Unrealized fgn cur gain-FAS52","type":"text", "value": 1},
                    {"label":"Unrealized gain mktsec-FAS115","type":"text", "value": 1},
                    {"label":"Rounding","type":"input"},
                    {"label":"Other Increases","type":"input"}
                ]
            },
            {"label":"Add Lines 1,2 and 3", "numberKey":4, "type":"sum", "expression": "ctrl.getSum(ctrl.formEles, [0, 1, 2])"},
            {"label":"Distribution a cash","type":"panel","numberKey":5, "childSum": true, "showChildSum": false,
                "childContent":[
                    {"label":"b property","type":"input"}
                ]
            },
            {"label":"Other Decreases","type":"panel","numberKey":6, "childSum": true, "showChildSum": true,
                "childContent":[
                    {"label":"Liquidating Equity","type":"input"},
                    {"label":"Adjustment to Reserve Account","type":"input"},
                    {"label":"Prior-period Adjustment","type":"input"},
                    {"label":"Transfer of Retained Earnings","type":"input"},
                    {"label":"Unrealized fgn cur gain-FAS52","type":"text", "value": 1},
                    {"label":"Unrealized gain mktsec-FAS115","type":"text", "value": 1},
                    {"label":"Rounding","type":"input"},
                    {"label":"Other Increases","type":"input"}
                ]
            },
            {"label":"Add Lines 5 and 6","type":"sum","numberKey":7, "expression": "ctrl.getSum(ctrl.formEles, [4, 5])"},
            {"label":"Balance at End of Year (line 4 less line 7)","type":"sum","numberKey":8, "expression": "ctrl.getSum(ctrl.formEles, [0, 1, 2, 4, 5, 6])"}
        ];

        vm.toggleExpansion = function(formEle) {
            formEle.hideChilds = !formEle.hideChilds;
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function() {
            vm.cancel();
            AlertService.add("success", "Data saved Successfully.",4000);
        };

        vm.getSum = function(list, indexes) {
            var sum = 0;
            if (angular.isArray(list)) {
                for(var i=0; i<indexes.length; i++) {
                    var value = 0;
                    if (list[indexes[i]] && list[indexes[i]].value) {
                        value = parseFloat(list[indexes[i]].value);
                    }
                    sum += value;
                }
            }
            return sum;
        };

        vm.childSum = function(parent) {
            parent.value = vm.getSum(parent.childContent, Object.keys(parent.childContent));
            return parent.value;
        };
    }

    return controllers;

});