define([
    'moment',
    'angular'
], function (moment) {
    'use strict';

    var services = angular.module('app.referenceTableActionsService',[])
        .factory("referenceTableActionsService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG','JsonObjectFactory', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,JsonObjectFactory) {

                var referenceTableActionsService = {};

                var URLS = {
                    // GET_ENTITY_DETAILS: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32023",
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj',
                    GET_DIST_COL_NAMES: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32791",
                    GET_DIST_REF_CODE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32793",
                    // GET_REF_TAB:  GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32794",

                }

                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};


                // referenceTableActionsService.loadAllOverrideEntities = function(_data, _details){
                //     var  params = {
                //                    action_code: 'lvcm1n', 
                //                 };

                //     return JsonObjectFactory.getJSONObj(URLS.GET_ENTITY_DETAILS,params).then((response)=>{
                //         if(response.callSuccess === "1" ){

                //             let arr = response.jsonObject.map(elem => {
                //                 if(elem.START_DATE)
                //                     elem.START_DATE = moment(elem.START_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                //                 if(elem.END_DATE)
                //                     elem.END_DATE = moment(elem.END_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                //                 if(elem.ACQUISITION_DATE)
                //                     elem.ACQUISITION_DATE = moment(elem.ACQUISITION_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                //                 if(elem.DATE_INCORPORATED)
                //                     elem.DATE_INCORPORATED = moment(elem.DATE_INCORPORATED,'YYYY-MM-DD').format('MM/DD/YYYY');
                //                 if(elem.DATE_MERGED)
                //                     elem.DATE_MERGED = moment(elem.DATE_MERGED,'YYYY-MM-DD').format('MM/DD/YYYY');
                //                 if(elem.DATE_DISSOLVED)
                //                     elem.DATE_DISSOLVED = moment(elem.DATE_DISSOLVED,'YYYY-MM-DD').format('MM/DD/YYYY');
                //                 if(elem.DATE_SOLD)
                //                     elem.DATE_SOLD = moment(elem.DATE_SOLD,'YYYY-MM-DD').format('MM/DD/YYYY');
                //                 return elem;
                //             });

                //             return arr;
                //         }else{
                //             if(data.errorMessage === "no access"){
                //                 AlertService.add("error", "Sorry you do not have access to retrive Override Entities.", 4000);
                //             }else{
                //                 AlertService.add("error", "Unalbe to retrieve Override Entities. If you continue to experience this issue please visit the help page to contact support.", 4000);
                //             }
                //         }
                //         return [];
                //     })
                // };
                
                // referenceTableActionsService.save =function(){

                // };

                referenceTableActionsService.loadAllColumnNames  = function(){
                    var  params = {
                        action_code: 'uy8nox', 
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_DIST_COL_NAMES,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive distinct Column Names.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve distinct Column Names. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                referenceTableActionsService.loadAllRefCode  = function(_selectedValue){
                    var column_name = _selectedValue;
                    var  params = {
                        action_code: 'wvcqdd', 
                        column_name: column_name
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_DIST_REF_CODE,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive distinct Ref Code.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve distinct Ref Code. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                
                // referenceTableActionsService.loadAllRefTab  = function(_selectedValue1, _selectedValue2){
                //     var column_name = _selectedValue1;
                //     var ref_code = _selectedValue2;
                //     var  params = {
                //         action_code: 'iz9ko1', 
                //         column_name: column_name,
                //         ref_code:ref_code
                //     };

                //     return JsonObjectFactory.getJSONObj(URLS.GET_REF_TAB,params).then((response)=>{
                //         if(response.callSuccess === "1" ){
                //             console.log(response);
                //             return response.jsonObject;
                //         }else{
                //             if(data.errorMessage === "no access"){
                //                 AlertService.add("error", "Sorry you do not have access to retrive Ref Tab.", 4000);
                //             }else{
                //                 AlertService.add("error", "Unalbe to retrieve distinct Ref Tab. If you continue to experience this issue please visit the help page to contact support.", 4000);
                //             }
                //         }
                //         return [];
                //     })
                // };

                return referenceTableActionsService;

        }]);

    return services;

});