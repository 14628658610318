define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.entityClassificationCtrl', [])
					.controller('entityClassificationDtlsCtrl', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','EntityClassificationServiceFactory', 'rowData', 'gridData', entityClassificationDtlsCtrl])

			
			function entityClassificationDtlsCtrl($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,EntityClassificationServiceFactory,rowData, gridData) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.syncSaveDtls = [];
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year;
				vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
				vm.isSaveClicked = false;
				
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
								//vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect);
//								var syncSaveDtls = [];
								for(var key in vm.gridDataMultipleSelect){
									var gridObj = {};
									
									gridObj["object_id"] = vm.gridDataMultipleSelect[key].data.object_id;
									gridObj["status"] = vm.gridDataMultipleSelect[key].data.STATUS;
									gridObj["guid"] = vm.gridDataMultipleSelect[key].data.GUID;
									gridObj["leid"] = vm.gridDataMultipleSelect[key].data.ENTITY_ID;
									gridObj["cdr_number"] = vm.gridDataMultipleSelect[key].data.ALTERNATE_ENTITY_ID;
									gridObj["reporting_period"] = vm.gridDataMultipleSelect[key].data.REPORTING_PERIOD;
									gridObj["childId"] = vm.gridDataMultipleSelect[key].data.CHILD_ID;
									gridObj["stub"] = vm.gridDataMultipleSelect[key].data.STUB;
									gridObj["taxYear"] = vm.gridDataMultipleSelect[key].data.TAX_YEAR;
									gridObj["scenario"] = vm.gridDataMultipleSelect[key].data.SCENARIO;
									gridObj["startDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.START_DATE, "MM/dd");
									gridObj["EndDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.END_DATE, "MM/dd/yyyy");
									gridObj["taxType"] = vm.gridDataMultipleSelect[key].data.TAX_TYPE;
									gridObj["ho_leid"] = vm.gridDataMultipleSelect[key].data.HO_LEID;
									gridObj["ho_cdr_number"] = vm.gridDataMultipleSelect[key].data.HO_ALTERNATE_ENTITY_ID;
									gridObj["ho_reporting_period"] = vm.gridDataMultipleSelect[key].data.HO_REPORTING_PERIOD;
									gridObj["HoCountryIncorp"] = vm.gridDataMultipleSelect[key].data.HO_COUNTRY_INCORPORATED;
									gridObj["parent_leid"] = vm.gridDataMultipleSelect[key].data.PARENT_LEID;
									gridObj["parent_cdr_number"] = vm.gridDataMultipleSelect[key].data.PARENT_ALTERNATE_ENTITY_ID;
									gridObj["parent_reporting_period"] = vm.gridDataMultipleSelect[key].data.PARENT_REPORTING_PERIOD;
									gridObj["classDesc"] = vm.gridDataMultipleSelect[key].data.CLASSIFICATION_DESC;
									gridObj["classification"] = vm.gridDataMultipleSelect[key].data.CLASSIFICATION;
									gridObj["acqDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.ACQUISITION_DATE, "MM/dd");
									gridObj["incorpDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.DATE_INCORPORATED, "MM/dd/yyyy");
									gridObj["goldStartDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.GOLD_START_DATE, "MM/dd");
									gridObj["goldEndDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.GOLD_END_DATE, "MM/dd/yyyy");
									gridObj["dcsEntity"] = vm.gridDataMultipleSelect[key].data.DCS_ENTITY;
									gridObj["firEntity"] = vm.gridDataMultipleSelect[key].data.FIR_ENTITY;
									gridObj["stdReason"] = vm.gridDataMultipleSelect[key].data.STANDARD_REASON;
									gridObj["filingResp"] = vm.gridDataMultipleSelect[key].data.FILING_ENTITY;
//									gridObj["review"] = vm.gridDataMultipleSelect[key].data.REVIEW;
									gridObj["comments"] = vm.gridDataMultipleSelect[key].data.FILING_COMMENTS;
									gridObj["syncFlag"] = vm.gridDataMultipleSelect[key].data.GTW_SYNC_FLAG;
									gridObj["reviewFlag"] = vm.gridDataMultipleSelect[key].data.ACCEPT_FLAG;
									gridObj["RECORD_TYPE"] = vm.gridDataMultipleSelect[key].data.RECORD_TYPE;
									
									vm.syncSaveDtls.push(gridObj);
									
								}
					}

				} 
				
				
				///REVIEW AND SAVE
				vm.confirm_acceptSave = function(saveType) {
					 vm.isSaveClicked = true;
					
					 
					 vm.alreadySynced = _.filter(vm.syncSaveDtls, function(o){
							return o.status == 'SYNCED'
						});
						
					 
				 if(vm.alreadySynced.length > 0 ){
					 	$uibModalInstance.dismiss('cancel');
						AlertService.add("error", "Can't Save/Review the synced entities");
						return;
					}
					var returnClobSettingsObj = {};
					var ecAcceptSaveDtls = [];
					for(var key in vm.syncSaveDtls){
						var returnObj = {};
						
//						returnObj["object_id"] = gridData.rowData.object_id;
//						returnObj['combination_key'] = vm.syncSaveDtls[key].COMBINATION_KEY;
						returnObj['leid'] = vm.syncSaveDtls[key].leid;
						returnObj['cdr_no'] = vm.syncSaveDtls[key].cdr_number;
						returnObj['reporting_period'] = vm.syncSaveDtls[key].reporting_period;
						returnObj['ho_leid'] = vm.syncSaveDtls[key].ho_leid;
						returnObj['ho_cdr_no'] = vm.syncSaveDtls[key].ho_cdr_number;
						returnObj['ho_reporting_period'] = vm.syncSaveDtls[key].ho_reporting_period;
						returnObj['parent_leid'] = vm.syncSaveDtls[key].parent_leid;
						returnObj['parent_cdr_no'] = vm.syncSaveDtls[key].parent_cdr_number;
						returnObj['parent_reporting_period'] = vm.syncSaveDtls[key].parent_reporting_period;
						returnObj['guid'] = vm.syncSaveDtls[key].guid;
						returnObj['std_reason'] = vm.syncSaveDtls[key].stdReason;
						returnObj['comments'] = vm.syncSaveDtls[key].comments;
						returnObj['filing'] = vm.syncSaveDtls[key].filingResp;
						returnObj['review_flag'] = vm.syncSaveDtls[key].reviewFlag;
						returnObj['sync_flag'] = vm.syncSaveDtls[key].syncFlag;
						if(vm.syncSaveDtls[key].leid == vm.syncSaveDtls[key].ho_leid && vm.syncSaveDtls[key].cdr_number == vm.syncSaveDtls[key].ho_cdr_number && vm.syncSaveDtls[key].reporting_period == vm.syncSaveDtls[key].ho_reporting_period){
							returnObj['entity_type'] = 'HO';
						}else{
							if(vm.syncSaveDtls[key].RECORD_TYPE == 'BRANCH'){
								returnObj['entity_type'] = 'BRANCH';
							}if(vm.syncSaveDtls[key].RECORD_TYPE == 'COMPANY_CODE'){
								returnObj['entity_type'] = 'COMPANY CODE';
							}else{
								returnObj['entity_type'] = 'NONHO';
							}
						}
						
						var message = "Entity Classification has been successfully saved/updated";
						ecAcceptSaveDtls.push(returnObj);
						
					}
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['entry_path'] = workspaceFactory.activeScreen.label;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['save_type'] = saveType;
					//returnClobSettingsObj['scenario'] = 99;

					sendDetails(returnClobSettingsObj,ecAcceptSaveDtls, message);

				};
				

				//SYNC AND ACCEPT
				vm.confirm_sync = function(saveType) {
					 vm.isSaveClicked = true;
					 
//					 vm.alreadySynced = _.filter(vm.syncSaveDtls, function(o){
//							return o.status == 'SYNCED'
//						});
//						
//				 if(vm.alreadySynced.length > 0 ){
//					 	$uibModalInstance.dismiss('cancel');
//						AlertService.add("error", "Can't Save/Review the synced entities");
//						return;
//					}
					
					var returnClobSettingsObj = {};
					var ecSaveDtls = [];
					for(var key in vm.syncSaveDtls){
						var returnObj = {};
						
//						returnObj["object_id"] = gridData.rowData.object_id;
//						returnObj['combination_key'] = vm.syncSaveDtls[key].COMBINATION_KEY;
						returnObj['leid'] = vm.syncSaveDtls[key].leid;
						returnObj['cdr_no'] = vm.syncSaveDtls[key].cdr_number;
						returnObj['reporting_period'] = vm.syncSaveDtls[key].reporting_period;
						returnObj['ho_leid'] = vm.syncSaveDtls[key].ho_leid;
						returnObj['ho_cdr_no'] = vm.syncSaveDtls[key].ho_cdr_number;
						returnObj['ho_reporting_period'] = vm.syncSaveDtls[key].ho_reporting_period;
						returnObj['parent_leid'] = vm.syncSaveDtls[key].parent_leid;
						returnObj['parent_cdr_no'] = vm.syncSaveDtls[key].parent_cdr_number;
						returnObj['parent_reporting_period'] = vm.syncSaveDtls[key].parent_reporting_period;
						returnObj['guid'] = vm.syncSaveDtls[key].guid;
						returnObj['std_reason'] = vm.syncSaveDtls[key].stdReason;
						returnObj['comments'] = vm.syncSaveDtls[key].comments;
						returnObj['filing'] = vm.syncSaveDtls[key].filingResp;
						returnObj['review_flag'] = vm.syncSaveDtls[key].reviewFlag;
						returnObj['sync_flag'] = vm.syncSaveDtls[key].syncFlag;
						if(vm.syncSaveDtls[key].leid == vm.syncSaveDtls[key].ho_leid && vm.syncSaveDtls[key].cdr_number == vm.syncSaveDtls[key].ho_cdr_number && vm.syncSaveDtls[key].reporting_period == vm.syncSaveDtls[key].ho_reporting_period){
							returnObj['entity_type'] = 'HO';
						}else{
							if(vm.syncSaveDtls[key].RECORD_TYPE == 'BRANCH'){
								returnObj['entity_type'] = 'BRANCH';
							}if(vm.syncSaveDtls[key].RECORD_TYPE == 'COMPANY_CODE'){
								returnObj['entity_type'] = 'COMPANY CODE';
							}else{
								returnObj['entity_type'] = 'NONHO';
							}
						}
						var message = "Entity Classification has been successfully saved/updated";
						ecSaveDtls.push(returnObj);
						
					}
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['entry_path'] = workspaceFactory.activeScreen.label;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['save_type'] = saveType;

					sendDetails(returnClobSettingsObj,ecSaveDtls, message);

				};

				function sendDetails(returnClobSettingsObj, ecSaveDtls, message) {
					 $rootScope.$broadcast("showLoader", true);
					 $uibModalInstance.dismiss('cancel');
					EntityClassificationServiceFactory.saveEntityClassification(returnClobSettingsObj,ecSaveDtls).then(function(result) {

								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
									 vm.isSaveClicked = false;
									 $uibModalInstance.dismiss('cancel');
									AlertService.add("error", result.data.errorMessage, 4000);
								} else {
									//vm.crudLoading = false;
									$rootScope.$broadcast("showLoader", false);
									var args = {
											TAX_YEAR: vm.syncSaveDtls[0].taxYear,
		                                    gridFilter: {
		                                    	TAX_YEAR:  vm.syncSaveDtls[0].taxYear
		                                    }
		                                };
									$rootScope.$emit('gridUpdate', args);
									AlertService.add("success", message, 4000);
                            	};

							});
				}
			}
			return controllers;
		});