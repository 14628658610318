define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.section174adjController', [])
					.controller(
							'Section174AController',
							[ '$rootScope', '$scope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'JsonObjectFactory', '$timeout',
									'Section174adjFactory', 'GENERAL_CONFIG',
									section174adjController ])

			function section174adjController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					$timeout, Section174adjFactory, GENERAL_CONFIG) {

				var vm = this;
				 $scope.crudLoading = false;
				 vm.checksystemaccount = [];
				 
				 if (typeof rowData !== 'undefined') {
						vm.rowData = rowData;
					}else{
						validateSelectedRows();
					}
				 
				 function validateSelectedRows(){
						
						for ( var index_ in gridData) {
							vm.checksystemaccount
									.push(gridData[index_].data.COMBINATION_KEY);
						}

						vm.checksystemaccount = _.uniq(vm.checksystemaccount,
								JSON.stringify)
						// var paramssystemaccount= vm.checksystemaccount;
						if (vm.checksystemaccount.length > 1) {
							AlertService.add("",
									"Please Select unique CombinationKey.",
									400000);
							//vm.userMessage = "!! Unable to perform Selected Action...";
							throw new ("This is not a javascript error . This is just to abort javascript!");
						}
					
						
			 }

				// vm.changeColorvariance = false;
				function init() {
					 $scope.crudLoading = false;
					vm.entities = [];
					$scope.crudLoading = false;
					var sendobj = [];
					vm.section174List = [];
					vm.mappingList = [];
					vm.applyflag = "";
					var getwwniModalcallFlag = false;
					vm.tagetlistADD = false;
					vm.defaultvalue = 0
					vm.totalVariancea = 0;
					vm.totalVarianceRemaina = 0;
					vm.totalVarianceAllocateda = 0;
					vm.loading = false;
					vm.totalAmt = 0;
					vm.totalNetInc = 0;
					vm.totalVariance = 0;
					vm.netIncPerLoss = 0;
					vm.displayNdata = [];
					vm.displayYdata = [];
					vm.removeFlag = false;
					vm.lookUpVariance = false;
					vm.sourceAmt = 0;
					vm.sourceAmt = (rowData != undefined) ? rowData.NET_INC_LOSS_PER_FS
							: gridData[0].data.NET_INC_LOSS_PER_FS;
					vm.sourceAmtAllocated = (rowData != undefined) ? rowData.NET_INCOME_PER_SYSTEM
							: gridData[0].data.NET_INCOME_PER_SYSTEM;
					vm.netIncRemain = 0;
					// After Number vm.netIncRemain = vm.sourceAmt -
					vm.sysLeidDropdown = [];
					vm.sysLeidDropdownNdata = [];
					vm.sysLeidDropdownDefault = "";
					vm.varianceList = [];
					vm.varianceSubcategory = [];

					vm.entities = _.clone(gridData);
					vm.expla_code_dropdown = [];
					vm.sub_category_dropdown = [];
					vm.changeColorwwni = false;
					vm.changeColorVariance = false;
					vm.saveSwapFlag = true;
					vm.saveSwapvariance = false;
					vm.saveSwapnetInc = false;
					var source_combinationKey = "";
					var source_headerKey = "";
					var source_combinationKeyS = "";
					var source_headerKeyS = "";
					vm.tempListSwap = [];
					var tempobj = {};
					$scope.crudLoading = false;
					if (!_.isArray(gridData)) {

						var obj = {}
						obj.row_id = gridData.rowData.object_id;
						obj.data = gridData.rowData;
						gridData = [];
						gridData.push(obj)
					}
					vm.entities = _.clone(gridData);

					for ( var g in vm.entities)

					{

						tempobj["Book"] = vm.entities[g].data.BOOK_AMT;
						tempobj["Book_Adj"] = vm.entities[g].data.BOOK_ADJ;
						tempobj["Adjusted_Book"] = '';
						tempobj["Section_174_Book"] =vm.entities[g].data.BOOK_SEC_174;
						tempobj["OLD_Section_174_Book"] =vm.entities[g].data.BOOK_SEC_174;
						tempobj["Tax_Adj"] = vm.entities[g].data.TAX_ADJ;
						tempobj["Tax_Adj_174"] = vm.entities[g].data.TAX_ADJ_SEC_174;
						tempobj["OLD_Tax_Adj_174"] = vm.entities[g].data.TAX_ADJ_SEC_174;
						tempobj["LEID"] = vm.entities[g].data.LEID;
						tempobj["CDR_NO"] = vm.entities[g].data.CDR_NO;
						tempobj["CURR"] = vm.entities[g].data.FC;
						tempobj["ME"] = vm.entities[g].data.ME;
						tempobj["REPORTING_PERIOD"] = vm.entities[g].data.REPORTING_PERIOD;
						tempobj["LOCAL_ACCT"] = vm.entities[g].data.LOCAL_ACCT;
						tempobj["LOCAL_ACCT_DESC"] = vm.entities[g].data.LOCAL_ACCT_DESC;
						tempobj["SCHDM_DESC"] = vm.entities[g].data.SCHDM_DESC;
						tempobj["COMBINATION_KEY"] = vm.entities[g].data.COMBINATION_KEY;
						tempobj["LOCAL_ACCT_KEY"] = vm.entities[g].data.LOCAL_ACCT_KEY;
						tempobj["LOCAL_CHART_KEY"] = vm.entities[g].data.LOCAL_CHART_KEY;
						vm.section174List.push(tempobj);
						
						tempobj = {};
					}

				}

				$scope.showDetails = function(_index, _boolean) {
					vm.mappingList[_index].open = _boolean;

				};

				vm.applysection174book = function(index) {
					vm.section174List[index].Section_174_Book = 	vm.section174List[index].Book;

				}

				vm.apply174taxadj = function(index) {
					vm.section174List[index].Tax_Adj_174 = 	vm.section174List[index].Tax_Adj;
				}
				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}

					$scope.crudLoading = true;
					vm.sendobj = [];
					// vm.crudLoading = true;

					var dummyJson = {};
					// sendobj = [];
					for (var s = 0; s < vm.section174List.length; s++) {
						dummyJson = {};
						dummyJson.TAX_YEAR = GlobalService.globalParams.tax_year;
						dummyJson.SCENARIO = GlobalService.globalParams.scenario;
						dummyJson.JCD_KEY = GlobalService.globalParams.jcd_key;
						dummyJson.OLD_SECTION174_BOOK =vm.section174List[s].OLD_Section_174_Book;
						dummyJson.NEW_SECTION174_BOOK = vm.section174List[s].Section_174_Book;
						dummyJson.COMBINATION_KEY = vm.section174List[s].COMBINATION_KEY;
						dummyJson.LOCAL_ACCT_KEY = vm.section174List[s].LOCAL_ACCT_KEY;
						dummyJson.LOCAL_CHART_KEY = vm.section174List[s].LOCAL_CHART_KEY;
//						dummyJson.OLD_SECTION174_TAX_ADJ = vm.section174List[s].OLD_Tax_Adj_174;
//						dummyJson.NEW_SECTION174_TAX_ADJ = vm.section174List[s].Tax_Adj_174;
						vm.sendobj.push(dummyJson);
						
					}	

					Section174adjFactory
							.saveProjectTagW(vm.sendobj, vm.sendobj)
							.then(
									function(result) {

										if (result.callSuccess !== "1") {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoading = false;

										} else {
											AlertService
													.add(
															"success",
															"Data has been saved successfully ",
															4000);
											$uibModalInstance.dismiss('cancel');
											var args = {
											cc_key : (rowData != undefined) ? rowData.COMBINATION_KEY
													: gridData[0].data.COMBINATION_KEY,

											gridFilter : {
												cc_key : (rowData != undefined) ? rowData.COMBINATION_KEY
														: gridData[0].data.COMBINATION_KEY,

											}
										};
										$rootScope.$emit('gridUpdate',
												args);
											/*$timeout(function() {

												var args = {
													refresh_cc_key : (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
															: gridData[0].data.SOURCE_COMBINATION_KEY,

													gridFilter : {
														refresh_cc_key : (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
																: gridData[0].data.SOURCE_COMBINATION_KEY,

													}
												};
												$rootScope.$emit('gridUpdate',
														args);
											});
*/
										}
									});
				}

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				init();

				vm.reset = function() {
					init();
				}

				// 4bjt2k

				// getWWNImodalData();

			}
			return controllers;

		});
