define([
    'angular',
    './gridController',
    './GridService',
    './GridDirective',
    './GridModel',
    './GridBatchSaveController',
    './GridPivotService'



], function () {
    'use strict';
    return angular.module('app.grid', ['app.gridController','app.gridServices','app.gridDirective','app.gridModels','app.gridBatchSaveController','app.gridPivotServices'])
});