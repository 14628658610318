
define([
    'angular',
    './entityClassificationCtrl',
    './entityClassificationService',
    './entityClassificationAutomation',
], function () {
    'use strict';
    return angular.module('app.entityClassification', ['app.entityClassificationCtrl','app.entityClassificationService','app.entityClassificationAutomation' ])
});
