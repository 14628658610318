define([
    'angular'

 ], function () {
    'use strict';

    var controllers = angular.module('app.dataproduceController', [])
       .controller('dataProduce5471Ctrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
          'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG','$uibModal', dataProduce5471Ctrl
       ]);

    function dataProduce5471Ctrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG,$uibModal) {
       var vm = this;
       var leId = $scope.$parent.$parent.rowData.HO_LEID;
       var cdrNo = $scope.$parent.$parent.rowData.HO_CDR_NO;
       var rptPeriod = $scope.$parent.$parent.rowData.HO_REPORTING_PERIOD;
       var taxYear = $scope.$parent.$parent.rowData.TAX_YEAR;
       var leType=$scope.$parent.$parent.rowData.LE_TYPE;


       vm.report = [{"LEID":"000CH9","tax_code":"840","rpt_pd":"1","bsla":"CSXUKB-GE Capital EMEA Head Quarters","currency":"USD","legal_entity":"GE Capital Sigma Holding Ltd","work_in_progress":"E&P Adjustments"},
       {"LEID":"000CGM","tax_code":"840","rpt_pd":"1","bsla":"CSXUKB-GE Capital EMEA Head Quarters","currency":"USD","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Review Submission Summary and Sign-off(II)"},
       {"LEID":"000CGM","tax_code":"840","rpt_pd":"1","bsla":"CSXUKB-GE Capital EMEA Head Quarters","currency":"USD","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Review Submission Summary and Sign-off(II)"},
       {"LEID":"000CH9","tax_code":"840","rpt_pd":"1","bsla":"ADMINSTRATION DOMESTIC","currency":"USD","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Review Submission Summary and Sign-off(II)"},
       {"LEID":"000CH9","tax_code":"247","rpt_pd":"1","bsla":"ADMINSTRATION DOMESTIC","currency":"EUR","legal_entity":"GE Capital Sigma Holding Ltd","work_in_progress":"Review Submission Summary and Sign-off(II)"},
       {"LEID":"000CH9","tax_code":"247","rpt_pd":"1","bsla":"ADMINSTRATION DOMESTIC","currency":"EUR","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Review Submission Summary and Sign-off(II)"},
       {"LEID":"000CH9","tax_code":"247","rpt_pd":"1","bsla":"ADMINSTRATION DOMESTIC","currency":"EUR","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Adjust Prior Year Data/Set Beginning Balances"},
       {"LEID":"000CGM","tax_code":"247","rpt_pd":"1","bsla":"ADMINSTRATION DOMESTIC","currency":"EUR","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Adjust Prior Year Data/Set Beginning Balances"},
       {"LEID":"000CGM","tax_code":"247","rpt_pd":"1","bsla":"ADMUKL-LEEDS","currency":"AUS","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Review Submission Summary and Sign-off(II)"},
       {"LEID":"000CGM","tax_code":"247","rpt_pd":"1","bsla":"ADMUKL-LEEDS","currency":"AUS","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Review Submission Summary and Sign-off(II)"},
       {"LEID":"000CGM","tax_code":"247","rpt_pd":"1","bsla":"ADMUKL-LEEDS","currency":"AUS","legal_entity":"GE Capital Corporation(Holding)","work_in_progress":"Review Submission Summary and Sign-off(II)"}
       ];
       /* save from workflow 5/14/2019 */
       $scope.$on('workflowSave', function (event, data) {
          vm.save();
       });
       vm.openPdf = function() {
         $uibModal.open({
            animation: true,
            template: "<div class='modal-header'><div class='modal-title'>Form 5471<i class='fa fa-times pull-right' role='button' uib-tooltip='Close' ng-click='uibModalInstance.close()'></i></div></div><div class='modal-body'>"+
            "<pdf-preview le-id='leId' form-name='formName' div-height=\"'80vh'\" tax-year='taxYear' cdr-no='cdrNo' rpt-period='rptPeriod' le-type='leType'></pdf-preview></div>",
             controller: ['$scope','$uibModalInstance','leId','cdrNo','rptPeriod','taxYear', function($scope,$uibModalInstance,leId,cdrNo,rptPeriod,taxYear) {
                $scope.leId=leId;//"LZ0007";
                $scope.formName="5471";//"1120L";
                $scope.rptPeriod=rptPeriod;//"1120L";
                $scope.taxYear=taxYear;//"1120L";
                $scope.cdrNo=cdrNo;//"1120L";
                $scope.leType=leType;
                $scope.uibModalInstance = $uibModalInstance;
             }],
            size: 'lg',
            // backdrop: 'static',
            resolve: {
               leId: function() {
                  return leId;
               },
               cdrNo: function() {
                  return cdrNo;
               },
               rptPeriod: function() {
                  return rptPeriod;
               },
               taxYear: function() {
                  return taxYear;
               }
            }
        });
       }
       vm.save = function () {
          AlertService.add("success", "Data save Successfully", 4000);
       }
    }
 });
