define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.sourcing-DefaultCtrl', [])
            .controller('sourcingDefaultByMeCtrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',sourcingDefaultByMeCtrl]
            );

        function sourcingDefaultByMeCtrl($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.userSettings = USER_SETTINGS;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;            
            vm.acct_key = gridData.rowData.ACCT_KEY;
            vm.parent_me_key = gridData.rowData.PARENT_ME_KEY;
            vm.acct_type = gridData.rowData.ACCT_TYPE;
            var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
            vm.globalParams = JSON.stringify(tempObj);
            vm.checkLockeOdbj =[];



            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = filterParams.scenario;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.filing_key = filterParams.filing_key;
                    vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.checkLockeOdbj.push({"me_code":gridData.rowData.ME_CODE,"consol_group_key":vm.filing_key, "scenario_key":vm.scenario, "tax_year":vm.tax_year, "scenarioCode": vm.scenarioCode,
                    						"is_issue_key_enabled":'N'});

                  //  console.log(vm.tax_year , ' : ', vm.scenario, ' : ', vm.jcd_key, ' : ', vm.logged_in_user , ' : ', vm.screen_key , ' : ', vm.combination_key , ' : ', vm.checkLockeOdbj);

                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);

                    const html = `<gtw-sourcing-default-me-ce id="open-sourcing-info" base-urls="{{ctrl.baseURLs}}" global-params="{{ctrl.globalParams}}" 
                    				tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" screen-key="{{ctrl.screen_key}}" sso-id="{{ctrl.logged_in_user}}" 
                    				parent-me-key = "{{ctrl.parent_me_key}}" filing-key ="{{ctrl.filing_key}}" acct-key="{{ctrl.acct_key}}" acct-type="{{ctrl.acct_type}}"
                    				scenario-code = "{{ctrl.scenarioCode}}" check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" class="gtw-web-components"></gtw-sourcing-default-me-ce>`;
                    vm.webComponentHtml = html;

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {
                	$window.document.querySelector('gtw-sourcing-default-me-ce')
                           .addEventListener('open-sourcing-info', (event)=>{
                               console.log('event',event);
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {

                        $window.document.querySelector('gtw-sourcing-default-me-ce')
                            .addEventListener('save-sourcing-info', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-sourcing-info'){
									var args = {gridFilter: {ACCOUNT: gridData.rowData.ACCOUNT,ME_CODE :gridData.rowData.ME_CODE, object_id:gridData.rowData.object_id}};
                        			$rootScope.$emit('gridUpdate', args);
                                   /* $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });*/
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added ...');
                    },3000
                );

                $scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
);
