define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.scenarioSetupActionsController', [])
    .controller('scenarioSetupCreateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','scenarioSetupActionsService', '$filter', scenarioSetupCreateActionController])
    .controller('scenarioSetupUpdateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','scenarioSetupActionsService', scenarioSetupUpdateActionController]);
 
    function scenarioSetupCreateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,scenarioSetupActionsService, $filter){
        var vm = this;

        vm.modalTitle = "Scenario Setup";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {};
        vm.currentDate = new Date();
        vm.allMasterLeids = [];
        vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0] ? $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE : 'RAF';

		if (vm.isIssueKeyEnabled === undefined) {
			if (vm.scenarioCode !== undefined) {
				if (vm.scenarioCode === 'RAF') {
					vm.isIssueKeyEnabled = 'N';
				}
				else {
					vm.isIssueKeyEnabled = 'Y';
				}
			}
		}

 		
        vm.scenarioEntity_bckup = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            FILING_LE_ID:'',
            PARENT_LE_ID:'',
            GROUP_OBJ_KEY:'',
            GROUP_OBJ_KEY_898:'',
            TRADITIONAL_START_DATE:'',
            TAX_YEAR_START_DATE:'',
            TAX_YEAR_END_DATE:'',
            BATCH_START_DATE:'',
            BATCH_END_DATE:'',
            VIEW_START_DATE:'',
            VIEW_END_DATE:'',
            OWNERSHIP_TYPE:'',
            COMMENTS:'',
            IRS_FORM_TY_BEGIN_DATE:'', 
            IRS_FORM_TY_END_DATE:'',
            APP_SCENARIO:'',
            SCENARIO_EC:'',
            SCENARIO_TYPE_CODE:'',
            SCENARIO_DESC:'',
            CONFIG_VALUE:''

        };

        vm.scenarioEntity = angular.copy(vm.scenarioEntity_bckup);
        
        vm.scenarioEntity.TAX_YEAR = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.scenarioEntity.TAX_YEAR_START_DATE = "01/01/"+vm.scenarioEntity.TAX_YEAR;
        vm.scenarioEntity.TAX_YEAR_END_DATE = "12/31/"+vm.scenarioEntity.TAX_YEAR;
        vm.scenarioEntity.TRADITIONAL_START_DATE = "12/01/"+(vm.scenarioEntity.TAX_YEAR -1);
        vm.scenarioEntity.BATCH_START_DATE = vm.currentDate;
        vm.scenarioEntity.VIEW_START_DATE = vm.currentDate;
        vm.scenarioEntity.IRS_FORM_TY_BEGIN_DATE = "01/01/"+(vm.currentDate.getFullYear());
        vm.scenarioEntity.IRS_FORM_TY_END_DATE = "12/31/"+(vm.currentDate.getFullYear());
        vm.scenarioEntity.OWNERSHIP_TYPE = "VALUE";
        vm.scenarioEntity.SCENARIO_EC = "1";
        //vm.scenarioEntity.APP_SCENARIO = vm.scenarioEntity.APP_SCENARIO;

        let loadAllEntities =function(selectedVal1){
            scenarioSetupActionsService.loadAllEntities(selectedVal1).then((leids) => {
                console.log("load all entities Id", leids);
                vm.allLeids = leids;
                vm.leidsApiCompleted = true;
                //stopLoader();
            });
        }
        
        let loadGroupObjKey = function(selectedVal1, selectedVal2){
            
            scenarioSetupActionsService.loadGroupObjKey(selectedVal1, selectedVal2).then((groupObjByYear) => {
                console.log("load all Group Obj", groupObjByYear);
                vm.allGroupObjByYear = groupObjByYear;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadGroupObjKey898 = function(selectedVal1, selectedVal2){
            
            scenarioSetupActionsService.loadGroupObjKey898(selectedVal1, selectedVal2).then((groupObjByYear898) => {
                console.log("load all Group Obj", groupObjByYear898);
                vm.allGroupObjByYear898 = groupObjByYear898;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadScenarioByYear = function(){
            
            scenarioSetupActionsService.loadScenarioByYear().then((scenarioByYear) => {
                console.log("load all Scenario", scenarioByYear);
                vm.allScenarioByYear = scenarioByYear;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadScenarioTypeCode = function(){
            
            scenarioSetupActionsService.loadScenarioTypeCode().then((scenarioTypeCode) => {
                console.log("load all Scenario Type Code", scenarioTypeCode);
                vm.allscenarioTypeCode = scenarioTypeCode;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadScenarioDesc = function(selectedValue){
            
            scenarioSetupActionsService.loadScenarioDesc(selectedValue).then((scenarioDesc) => {
                console.log("load all Scenario Desc", scenarioDesc);
                vm.allscenarioDesc = scenarioDesc;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadClientId = function(){
            
            scenarioSetupActionsService.loadClientId().then((clientId) => {
                console.log("load Client Id", clientId);
                vm.allClientId = clientId;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
            //loadAllEntities();
            loadScenarioTypeCode();
            loadScenarioByYear();
            loadClientId();

        };
		vm.init();

        vm.isValid = function(){
            if(vm.scenarioEntity.TAX_YEAR == null || 
                vm.scenarioEntity.TAX_YEAR == undefined || 
                vm.scenarioEntity.TAX_YEAR == ""){
                     vm.scenarioEntity.TAX_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.SCENARIO_TYPE_CODE == null || 
                vm.scenarioEntity.SCENARIO_TYPE_CODE == undefined || 
                vm.scenarioEntity.SCENARIO_TYPE_CODE == ""){
                     vm.scenarioEntity.SCENARIO_TYPE_CODE_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.SCENARIO_DESC == null || 
                vm.scenarioEntity.SCENARIO_DESC == undefined || 
                vm.scenarioEntity.SCENARIO_DESC == ""){
                     vm.scenarioEntity.SCENARIO_DESC_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.CONFIG_VALUE == null || vm.scenarioEntity.CONFIG_VALUE == undefined || vm.scenarioEntity.CONFIG_VALUE == ""){
                vm.scenarioEntity.CONFIG_VALUE_NOT_VALID = true;
                return false;
            }
            if(vm.scenarioEntity.GROUP_OBJ_KEY == null || 
                vm.scenarioEntity.GROUP_OBJ_KEY == undefined || 
                vm.scenarioEntity.GROUP_OBJ_KEY == ""){
                        vm.scenarioEntity.GROUP_OBJ_KEY_NOT_VALID = true;
                        return false;
                }
            if(vm.scenarioEntity.PARENT_LE_ID == null || 
                vm.scenarioEntity.PARENT_LE_ID == undefined || 
                vm.scenarioEntity.PARENT_LE_ID == ""){
                     vm.scenarioEntity.PARENT_LE_ID_NOT_VALID = true;
                     return false;
            }
            
            if(vm.scenarioEntity.TRADITIONAL_START_DATE == null || 
            vm.scenarioEntity.TRADITIONAL_START_DATE == undefined || 
            vm.scenarioEntity.TRADITIONAL_START_DATE == ""){
                    vm.scenarioEntity.TRADITIONAL_START_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.scenarioEntity.TAX_YEAR_START_DATE == null || 
            vm.scenarioEntity.TAX_YEAR_START_DATE == undefined || 
            vm.scenarioEntity.TAX_YEAR_START_DATE == ""){
                    vm.scenarioEntity.TAX_YEAR_START_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.scenarioEntity.TAX_YEAR_END_DATE == null || 
            vm.scenarioEntity.TAX_YEAR_END_DATE == undefined || 
            vm.scenarioEntity.TAX_YEAR_END_DATE == ""){
                    vm.scenarioEntity.TAX_YEAR_END_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.scenarioEntity.SCENARIO_EC == null || 
                vm.scenarioEntity.SCENARIO_EC == undefined || 
                vm.scenarioEntity.SCENARIO_EC == ""){
                     vm.scenarioEntity.SCENARIO_EC_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.BATCH_START_DATE == null || 
                vm.scenarioEntity.BATCH_START_DATE == undefined || 
                vm.scenarioEntity.BATCH_START_DATE == ""){
                        vm.scenarioEntity.BATCH_START_DATE_NOT_VALID = true;
                        return false;
                }
            if(vm.scenarioEntity.VIEW_START_DATE == null || 
                vm.scenarioEntity.VIEW_START_DATE == undefined || 
                vm.scenarioEntity.VIEW_START_DATE == ""){
                    vm.scenarioEntity.VIEW_START_DATE_NOT_VALID = true;
                    return false;
            }
            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.scenarioEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            
            jsonObj = scenarioSetupActionsService.convertDateFormates(jsonObj);
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=3odusa";

            // $http({
            //     method: "post",
            //     url: url,
            //     data: params
            // }).then(function (response) {
			// 	vm.isSaving = false;
            //     if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
            //         $uibModalInstance.dismiss('cancel');
            //         $rootScope.$emit('gridUpdate', {});
            //         AlertService.add("success", "Override entity created successfully", 4000);
            //     } else {
            //         AlertService.add("error", response.data.errorMessage );
            //     }
            //     return response.data;
            // });

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Scenario Details created successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        // vm.onLeidChange = function(){
        //     console.log(vm.scenarioEntity);
        //     let selectedElem = null;
        //     for(let i=0 ; i < vm.allLeids.length-1; i++){
        //         //if((vm.allLeids[i].CHILD_ID == vm.overrideEntity.CHILD_ID) && (vm.allLeids[i].STUB == vm.overrideEntity.STUB)){
        //         if(vm.allMasterLeids[i].PARENT_LE_ID == vm.scenarioEntity.PARENT_LE_ID){
        //             selectedElem = vm.allMasterLeids[i];
        //             break;
        //         }
        //     }
        // };   
        
        vm.onPopulateOwnershipType = function(){
            console.log(vm.scenarioEntity.SCENARIO_EC);
            if(vm.scenarioEntity.SCENARIO_EC == "1"){
                vm.scenarioEntity.OWNERSHIP_TYPE = "VALUE";
            }else{
                vm.scenarioEntity.OWNERSHIP_TYPE = "VOTE";
            }
        };

        vm.onPopulateTraditionStartDate = function(){
            console.log(vm.scenarioEntity.TAX_YEAR_START_DATE);
            let traditional_start_date = new Date(vm.scenarioEntity.TAX_YEAR_START_DATE);
            traditional_start_date.setMonth(traditional_start_date.getMonth() - 1);
            vm.scenarioEntity.TRADITIONAL_START_DATE = traditional_start_date;

        }; 

        vm.onScenarioTypeCodeChange = function(){
            console.log(vm.scenarioEntity.SCENARIO_TYPE_CODE);
            loadScenarioDesc(vm.scenarioEntity.SCENARIO_TYPE_CODE);
            vm.scenarioEntity.GROUP_OBJ_KEY = undefined;
            vm.scenarioEntity.GROUP_OBJ_KEY_898 = undefined;
        }; 

        vm.onPopulateGroupObjYear = function(){
            console.log(vm.scenarioEntity.SCENARIO_TYPE_CODE+'............'+ vm.scenarioEntity.SCENARIO_DESC);
            loadGroupObjKey(vm.scenarioEntity.SCENARIO_TYPE_CODE, vm.scenarioEntity.SCENARIO_DESC);
            loadGroupObjKey898(vm.scenarioEntity.SCENARIO_TYPE_CODE, vm.scenarioEntity.SCENARIO_DESC);
            
        };

        vm.onPopulateFilingLEID = function(){
            console.log(vm.scenarioEntity.GROUP_OBJ_KEY);
            loadAllEntities(vm.scenarioEntity.GROUP_OBJ_KEY);
        };
        

        vm.reset = function (){
			vm.scenarioEntity = angular.copy(vm.scenarioEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function scenarioSetupUpdateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, scenarioSetupActionsService){
        var vm = this;

        vm.modalTitle = "Scenario Setup";
		vm.showFormLoader = true;


        vm.showEntityLoader = false;
        vm.isSavingUpdate = false;
        vm.isSavingDelete = false;
        vm.dateOptions = {};
 		
        vm.scenarioEntity_bckup = gridData.rowData;

        vm.scenarioEntity = angular.copy(vm.scenarioEntity_bckup);

        vm.scenarioEntity.OWNERSHIP_TYPE = vm.scenarioEntity.OWNERSHIP_TYPE+"";

        

        // vm.onPopulateFilingLEID = function(){
        //     console.log(vm.scenarioEntity.GROUP_OBJ_KEY);
        //     loadAllEntities(vm.scenarioEntity.GROUP_OBJ_KEY);
        // };
		
        vm.isValid = function(){
            if(vm.scenarioEntity.TAX_YEAR == null || 
                vm.scenarioEntity.TAX_YEAR == undefined || 
                vm.scenarioEntity.TAX_YEAR == ""){
                     vm.scenarioEntity.TAX_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.FILING_LE_ID == null || 
                vm.scenarioEntity.FILING_LE_ID == undefined || 
                vm.scenarioEntity.FILING_LE_ID == ""){
                     vm.scenarioEntity.FILING_LE_ID_NOT_VALID = true;
                     return false;
            }
            if(vm.scenarioEntity.GROUP_OBJ_KEY_898 == null || 
            vm.scenarioEntity.GROUP_OBJ_KEY_898 == undefined || 
            vm.scenarioEntity.GROUP_OBJ_KEY_898 == ""){
                    vm.scenarioEntity.GROUP_OBJ_KEY_898_NOT_VALID = true;
                    return false;
            }
            if(vm.scenarioEntity.TRADITIONAL_START_DATE == null || 
            vm.scenarioEntity.TRADITIONAL_START_DATE == undefined || 
            vm.scenarioEntity.TRADITIONAL_START_DATE == ""){
                    vm.scenarioEntity.TRADITIONAL_START_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.scenarioEntity.TAX_YEAR_START_DATE == null || 
            vm.scenarioEntity.TAX_YEAR_START_DATE == undefined || 
            vm.scenarioEntity.TAX_YEAR_START_DATE == ""){
                    vm.scenarioEntity.TAX_YEAR_START_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.scenarioEntity.TAX_YEAR_END_DATE == null || 
            vm.scenarioEntity.TAX_YEAR_END_DATE == undefined || 
            vm.scenarioEntity.TAX_YEAR_END_DATE == ""){
                    vm.scenarioEntity.TAX_YEAR_END_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.scenarioEntity.BATCH_START_DATE == null || 
                vm.scenarioEntity.BATCH_START_DATE == undefined || 
                vm.scenarioEntity.BATCH_START_DATE == ""){
                        vm.scenarioEntity.BATCH_START_DATE_NOT_VALID = true;
                        return false;
                }

                

            if(vm.scenarioEntity.VIEW_START_DATE == null || 
                vm.scenarioEntity.VIEW_START_DATE == undefined || 
                vm.scenarioEntity.VIEW_START_DATE == ""){
                    vm.scenarioEntity.VIEW_START_DATE_NOT_VALID = true;
                    return false;
            }
            // if(vm.scenarioEntity.IRS_FORM_TY_END_DATE == null || 
            // vm.scenarioEntity.IRS_FORM_TY_END_DATE == undefined || 
            // vm.scenarioEntity.IRS_FORM_TY_END_DATE == ""){
            //         vm.scenarioEntity.IRS_FORM_TY_END_DATE_NOT_VALID = true;
            //         return false;
            // }
            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSavingUpdate = true;

            //vm.scenarioEntity.BATCH_END_DATE = (vm.scenarioEntity.BATCH_END_DATE == undefined) ? null: vm.scenarioEntity.BATCH_END_DATE;
            
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.scenarioEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            //jsonObj.IS_UPDATE = 'True';
            jsonObj = scenarioSetupActionsService.convertDateFormates(jsonObj);
            
            jsonObj = JSON.stringify(jsonObj);
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};

            var paramsMerge = _.merge({}, filterParams);
            var params = _.merge({}, GlobalService.globalParams, paramsMerge);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=70fgx1";

            // $http({
            //     method: "post",
            //     url: url,
            //     data: params
            // }).then(function (response) {
			// 	vm.isSaving = false;
            //     if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
            //         $uibModalInstance.dismiss('cancel');
            //         $rootScope.$emit('gridUpdate', {});
            //         AlertService.add("success", "Scenario Details Updated successfully", 4000);
            //     } else {
            //         AlertService.add("error", response.data.errorMessage );
            //     }
            //     return response.data;
            // });

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Scenario Details Updated successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.onPopulateTraditionStartDate = function(){
            console.log(vm.scenarioEntity.TAX_YEAR_START_DATE);
            let traditional_start_date = new Date(vm.scenarioEntity.TAX_YEAR_START_DATE);
            traditional_start_date.setMonth(traditional_start_date.getMonth() - 1);
            vm.scenarioEntity.TRADITIONAL_START_DATE = traditional_start_date;

        }; 
            
        vm.delete = function(_tax_year, _scenario){
            console.log("Delete function called");
            console.log(_tax_year+'............'+_scenario);
            vm.isSavingDelete = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                tax_year: _tax_year,
                scenario_ec: _scenario
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=c9gpo5";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Scenario deleted successfully", 4000);
                    console.log("deleted", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.reset = function (){
			vm.scenarioEntity = angular.copy(vm.scenarioEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
    return controllers;
});
