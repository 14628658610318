define([
    'angular'
], function () {
    'use strict';

    return angular.module( 'app.filters',[])


//---------------------------------------------------------------------------------------------------
//---------------------------------------- secondsToTime --------------------------------------------
//---------------------------------------------------------------------------------------------------

        .filter('secondsToTime', function () {
            return function (s) {
                var timeString = "";
                var h  = Math.floor( s / ( 60 * 60 ) );
                s -= h * ( 60 * 60 );
                var m  = Math.floor( s / 60 );
                s -= m * 60;

                if(h > 0){
                    timeString = h + " hour(s) " + m + ' minute(s) ' + s + ' seconds';
                }else if ( m > 0 ){
                    timeString =  m + ' minute(s) ' + s + ' seconds';
                }else{
                    timeString =   s + ' seconds';
                }
                return timeString;
            };
        })

        .filter('cut', function () {
            return function (value, wordwise, max, tail) {
                if (!value) return '';

                max = parseInt(max, 10);
                if (!max) return value;
                if (value.length <= max) return value;

                value = value.substr(0, max);
                if (wordwise) {
                    var lastspace = value.lastIndexOf(' ');
                    if (lastspace != -1) {
                        value = value.substr(0, lastspace);
                    }
                }

                return value + (tail || ' …');
            };
        })

        .filter('num', function() {
            return function(input) {
                return parseInt(input, 10);
            };
        })



        .filter('numberWithCommas', function() {
            return function(input) {

                if( input != null && input != undefined && input != '' ){

                    var num = input.toString();
                    if (Math.abs(parseFloat(input)) >= 1) {
                        num = num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                    if( num.indexOf("-") >= 0 ){
                        num = "(" + num.replace("-","") + ")";
                    }
                    return num;
                }

                return input;
            };
        })




        .filter('picker', function($filter) {
            return function(value, filterName) {
                if( filterName != null && filterName != undefined && filterName != ''&&  value != undefined && value  != '' ){
                    return $filter(filterName)(value);
                }else{
                    return value;
                }
            };
        })



    .filter('propsFilter', function() {
        return function(items, props) {
            var out = [];

            if (angular.isArray(items)) {
                items.forEach(function(item) {
                    var itemMatches = false;

                    var keys = Object.keys(props);
                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        }
    })

    .filter('unique', function() {
        return function(arr, prop) {
            return _.uniqBy(arr, function(item) {
                return prop? item[prop]: item;
            });
        };
    })
    // For reverse ng-repeat
    .filter('reverse', function() {
        return function(items) {
            if(items)
                return items.slice().reverse();
        };
    });
});
