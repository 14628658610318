define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.goldSyncServices', []).factory("GoldSyncFactory", ['$http', 'GENERAL_CONFIG', function ($http, GENERAL_CONFIG) {
        const goldSyncFactory = {};

        const requestUrlBuilder = {
            url: '',
            isFirstParam: true,
            set: function (url) {
                this.url = url;
                this.isFirstParam = true;
                return this;
            },
            get: function () {
                return this.url;
            },
            appendEndpoint: function (endpoint) {
                this.url = this.url + endpoint;
                return this;
            },
            appendParam: function (paramName, paramVal) {
                if (!this.url || this.url.length === 0) throw Error('Bad Url');
                if (paramName !== undefined && paramVal !== undefined) {
                    this.url = this.url + (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                    this.isFirstParam = false;
                }
                return this;
            }
        };

        goldSyncFactory.getGoldSyncApiDetails = function () {
            const promise = $http({
                method: 'GET',
                url: GENERAL_CONFIG.gold_sync_api_url + '/getGoldSyncApiDetails'
            });
            return promise;
        };

        goldSyncFactory.getGoldSyncOpenScenarios = function (taxYear) {
            const promise = $http({
                method: 'GET',
                url: GENERAL_CONFIG.gold_sync_api_url + '/getGoldSyncScenarios?taxYear=' + taxYear
            });
            return promise;
        };

        goldSyncFactory.getGoldSyncObjectDataCount = function (objectName) {
            const promise = $http({
                method: 'GET',
                url: GENERAL_CONFIG.gold_sync_api_url + '/getGoldSyncObjectDataCount?objectName=' + objectName
            });
            return promise;
        };

        goldSyncFactory.addApiDetails = function (apiDetails) {
            const promise = $http.post(GENERAL_CONFIG.gold_sync_api_url + '/addApiDetails', JSON.stringify(apiDetails),
                {
                    headers: {'Content-Type': 'application/json'}
                });
            return promise;
        };

        goldSyncFactory.updateApiDetails = function (apiDetails) {
            const promise = $http.post(GENERAL_CONFIG.gold_sync_api_url + '/updateApiDetails', JSON.stringify(apiDetails),
                {
                    headers: {'Content-Type': 'application/json'}
                });
            return promise;
        };

        goldSyncFactory.syncApiData = function (taxYears, jobIds, sourceTypes, scenarios) {
            const promise = $http.post(GENERAL_CONFIG.gold_sync_api_url + '/syncApiData?taxYearList=' + taxYears + '&jobIdList=' + jobIds +
                '&sourceTypes=' + sourceTypes + '&scenarios=' + scenarios);
            return promise;
        };

        goldSyncFactory.syncEONativeApiData = function (jobIds, sourceTypes, selectedJobs, client_datasource) {
            const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gold_sync_api_url)
                .appendEndpoint('/syncEOObject')
                .appendParam('client_ds', client_datasource) // client_datasource 'GTW0SBH1'
                .appendParam('object_to_sync', selectedJobs[0].src_tbl_name)
                .appendParam('url', selectedJobs[0].src_details)
                .appendParam('gifcall', 'y')
                .get(),
                {
                    headers: {'client_id': 'bd62fd60-aa08-40fc-8b99-93447fd5940c', 'Ocp-Apim-Subscription-Key': '5e62198d80a2493fbbfbeca6e064a16b'}
                });
            return promise;
        };

        goldSyncFactory.syncEONativeRPData = function (jobIds, selectedJobs, client_datasource) {
            const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gold_sync_api_url)
                  .appendEndpoint('/processEOToRPT')
                  .appendParam('client', client_datasource)
                  .appendParam('object', selectedJobs[0].src_tbl_name)
                  .get(),
                  {
                    headers: {'client_id': 'bd62fd60-aa08-40fc-8b99-93447fd5940c', 'Ocp-Apim-Subscription-Key': '5e62198d80a2493fbbfbeca6e064a16b'}
                  });
            return promise;
        };

        goldSyncFactory.getDataSources = function () {
            const promise = $http.get(GENERAL_CONFIG.gold_sync_api_url + '/getDataSources');
            return promise;
        };

        goldSyncFactory.getLogHistory = function (taxYearList, jobIdList, page, size, sortBy, sortByDirection) {
            const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gold_sync_api_url)
                                                       .appendEndpoint('/getGoldSyncLogHistory')
                                                       .appendParam('taxYearList', taxYearList)
                                                       .appendParam('jobIdList', jobIdList)
                                                       .appendParam('page', page)
                                                       .appendParam('size', size)
                                                       .appendParam('sortBy', sortBy)
                                                       .appendParam('sortByDirection', sortByDirection)
                                                       .get());
            return promise;
        };

        return goldSyncFactory;
    }]);


    return services;
});