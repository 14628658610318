/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './FormScheduleM3Ctrl',
    './FormScheduleM3Service',

], function () {
    'use strict';

  
   return angular.module('app.Form-scheduleM3', ['app.FormScheduleM3Ctrl','app.FormScheduleM3Service'])

    });