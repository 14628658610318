define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.cbcReconciliationService',[])

        .factory("cbcReconciliationFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','AlertService','USER_SETTINGS','GENERAL_CONFIG','$injector', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,AlertService,USER_SETTINGS,GENERAL_CONFIG,$injector) {
            var factory = {};
            factory.url = {};
            var service = $injector.get('JsonObjectFactory');
            
            factory.url.getMEDataURL = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=vfv6h5";
            factory.url.saveDataURL = GENERAL_CONFIG.base_url+ "/saveJsonObject?action_code=5rex72";
            // PopUp-1
            factory.url.getFSAccountsURL = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=9jwcbi";
            factory.url.getEntryDataURL = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=7fyyl9";
            
            // PopUp-2
            factory.url.reconcFSAccountsURL = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=o492e4";
            //factory.url.getVarianceCategoryURL = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=88ckel";
            factory.url.getVarianceTypeURL = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=6abqlx";

            factory.url.deleteUserVarianceURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=1n9e3l";

            factory.getMEData = function() {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.getMEDataURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario,
                    jcd_key : 250
                };
                
                var promise = $http({
                    method: "get",
                    url: url,
                    params:params
                }).then(function (response) {
                    console.log(response)
                    if(response.data.callSuccess === "1" ){
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.getFSAccounts = function() {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.getFSAccountsURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario,
                    jcd_key : 250
                };
                
                var promise = $http({
                    method: "get",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.getEntryData = function(me_code, acct, type) {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.getEntryDataURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario,
                    me_code : me_code,
                    acct: acct,
                    var_type: type
                };
                
                var promise = $http({
                    method: "post",
                    url: url,
                    params:params
                }).then(function (response) {
                    console.log(response)
                    if(response.data.callSuccess === "1" ){
                        
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.saveData = function(clob_data) {
                var clobData = JSON.stringify(clob_data);
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.saveDataURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario,
                    jcd_key : 250,
                    clob_data: clobData
                    
                };
                
                var promise = $http({
                    method: "post",
                    url: url,
                    params:params
                }).then(function (response) {
                    console.log(response)
                    if(response.data.callSuccess === "1" ){
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": true
                        });
                        AlertService.add("success", "Data is successfully updated.", 4000);
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.reconcFSAccounts = function() {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.reconcFSAccountsURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario,
                    jcd_key : 250
                };
                
                var promise = $http({
                    method: "get",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.getVarianceCategory = function() {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.getVarianceCategoryURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario
                };
                
                var promise = $http({
                    method: "get",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.getVarianceType = function() {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.getVarianceTypeURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario
                };
                
                var promise = $http({
                    method: "get",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.deleteUserVariance = function(ids) {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                const url = factory.url.deleteUserVarianceURL;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year: filters.tax_year,
                    scenario: filters.scenario,
                    jcd_key : 250,
                    variance_key: ids
                };
                
                var promise = $http({
                    method: "post",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        console.log(response);
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": true
                        });
                        return response.data.jsonObject;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            return factory;

        }])
        return services;
    })
