/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'js.cookie',
    'angular'

], function (Cookies ) {
    'use strict';


    var services =  angular.module('app.MessageService',[])

        .factory("MessageFactory",['$window','GENERAL_CONFIG','$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory',
        "USER_SETTINGS",
        function( $window,GENERAL_CONFIG,$q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory, JsonObjectFactory,USER_SETTINGS) {
            var factory = {};
            factory.modalFromMessage = false;
            factory.clickedFromMessage = function(val){
                factory.modalFromMessage = val;
            }

            factory.CommentCrud = function(_url,_params){
                var promise = $http({
                    method: "post",
                    url: _url,
                    data: _params
                }).then(function (response) {

                    var type = (workspaceFactory.activeScreen.data &&  workspaceFactory.activeScreen.data.settings && workspaceFactory.activeScreen.data.settings.commentType) ? workspaceFactory.activeScreen.data.settings.commentType : 'all';
                    if(_params && _params.type == 'dashboard'){
                       // $rootScope.$broadcast('component-' + _params.row_key.split("-",3)[1] + ':reload',undefined,true,'dashboard');
                       $rootScope.$broadcast("loadDashboardMessages",_params.row_key.split("-",4)[1]);
                    }else if(_params && _params.type === 'grid'){
                     $rootScope.$broadcast("loadGridMessages", type);
                    }

                     return response.data;
                 });
                return promise;
            };

            factory.CommentReplyCrud = function(_url,_params){
                var promise = $http({
                    method: "post",
                    url: _url,
                    data: _params
                }).then(function (response) {

                    return response.data;

                });
                return promise;
            };

            factory.getComments = function(_url,_params){

               // getCommentByContext

                var promise = $http({
                    method: "post",
                    url: _url,
                    data: _params
                }).then(function (response) {
                    return response.data;
                });



                return promise;
            };
            factory.downloadMsgAttachment=function(fileName,commentKey,docKey){
                var secondaryWindow;
                var sso_id = USER_SETTINGS.user.sso_id;
                 $http({'method':"GET", url:GENERAL_CONFIG.base_url +'/getAPIAuthToken'}).then(function(res){

                if(res.data && res.data.apiToken){
                 if (!fileName) {
                    if (!commentKey) {
                        secondaryWindow = window.open(GENERAL_CONFIG.base_url + '/downloadCommentAttachement?docKey=' + docKey +"&client_key="+sessionStorage.getItem('client_key')
                        +'&apiToken='+encodeURIComponent(res.data.apiToken)+'&georaclehrid=' +sso_id, '_blank')
                        ;
                        // secondaryWindow.postMessage({fileName: fileName, commentKey: commentKey, docKey: docKey,
                        // 'Authorization': sessionStorage.getItem('Authorization')}, window.location.origin);
                    }
                    else{
                    secondaryWindow = window.open(GENERAL_CONFIG.base_url + '/downloadCommentAttachement?commentKey=' + commentKey
                        + "&docKey=" + docKey +"&client_key="+sessionStorage.getItem('client_key')
                        + '&apiToken=' + encodeURIComponent(res.data.apiToken) +'&georaclehrid=' +sso_id, '_blank')
                       ;
                    // secondaryWindow.postMessage({fileName: fileName, commentKey: commentKey, docKey: docKey,    
                        // 'Authorization': sessionStorage.getItem('Authorization')}, window.location.origin);
                    }
                }
                else {
                    secondaryWindow = window.open(GENERAL_CONFIG.base_url + '/downloadCommentAttachement?fileName=' + fileName + "&commentKey=" + commentKey
                        + "&docKey=" + docKey + "&client_key=" + sessionStorage.getItem('client_key') + '&apiToken=' + encodeURIComponent(res.data.apiToken) +'&georaclehrid=' +sso_id)
                       ;
                       // secondaryWindow.postMessage({fileName: fileName, commentKey: commentKey, docKey: docKey,'Authorization': sessionStorage.getItem('Authorization')}, window.location.origin);
                } 
                  if (secondaryWindow == null) {
                        return;
                  }
                }
             })
            
                  // Check if there was a problem downloading the attachment.
                  secondaryWindow.onload = function () {
                        if (secondaryWindow.document.body.innerHTML.length > 0) {
                              $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                              secondaryWindow.close();
                        }
                  };
                // fetch(GENERAL_CONFIG.base_url + '/downloadCommentAttachement?docKey=' + docKey +"&client_key="+sessionStorage.getItem('client_key'), {
                //     method: 'GET',
                //     headers: {
                //         'Authorization': sessionStorage.getItem('Authorization')
                //     }
                // })
                // .then(response => response.blob())
                // .then(blob => {
                //     const blobUrl = URL.createObjectURL(blob);
                //     const link = document.createElement('a');
                //     link.href = blobUrl;
                //     link.download = fileName;//'filename.ext';  // provide the file name here
                //     link.style.display = 'none';
                //     document.body.appendChild(link);
                //     link.click();
                //     document.body.removeChild(link);
                //    // window.open(blobUrl, '_blank');
                // })
                // .catch(error => console.error(error));
            }

            factory.downloadAllDocuments=function(fileName,commentKey,docKey){

                var secondaryWindow = window.open(GENERAL_CONFIG.base_url + '/downloadAllDocuments?fileName=' + fileName + "&commentKey=" + commentKey
                + "&docKey=" + docKey +"&client_key="+sessionStorage.getItem('client_key'));

                secondaryWindow.postMessage({fileName: fileName, commentKey: commentKey, docKey: docKey,
                    'Authorization': sessionStorage.getItem('Authorization')}, window.location.origin);    
                  if (secondaryWindow == null) {
                        return;
                  }
                  // Check if there was a problem downloading the attachment.
                  secondaryWindow.onload = function () {
                        if (secondaryWindow.document.body.innerHTML.length > 0) {
                              $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                              secondaryWindow.close();
                        }
                  };
            }

            factory.getData = function(_url,_params){
                var promise = $http({
                    method: "get",
                    url: _url,
                    data: _params
                }).then(function (response) {

                    return response.data;
                });
                return promise;
            };

            factory.saveAttachment = function (_url, _params) {
                var promise = $http({
                    method: "post",
                    url: _url,
                    data: _params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            factory.loadAttachments = function (_baseURL,parentKey, parentType) {
                var params = {
                    parent_key: parentKey,
                    parent_type: parentType
                };
                return JsonObjectFactory.getJSONObj(_baseURL+'/loadFileAttachments', params);
            }


            return factory;

        }])


    return services;


});