define([
    'angular'
    
], function () {
    'use strict';

    var controllers =  angular.module('app.sltApiResponseController',[])
    .controller('sltApiResponseController', ['$scope', '$rootScope', '$state', '$log', 'rowData', 'ObjectDiff', 'JsonObjectFactory', 'AlertService', '$timeout', '$parse', 'JCDFactory', 'ClientAdminFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', '$uibModalInstance', 'sltApiResponseFactory', 'GlobalService', sltApiResponseController])

    function sltApiResponseController($scope, $rootScope, $state, $log, rowData, ObjectDiff, JsonObjectFactory, AlertService, $timeout, $parse, JCDFactory, ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS, $uibModalInstance, entitySyncLogFactory, GlobalService) {

        var vm = this;
        vm.title = "SLT API Response";
        vm.loading = false;
        vm.slt_api_response_data = [];
        vm.sltParams = rowData;
        vm.syncParams = "";
        vm.yourObjectOneJsonView = [];
        vm.meCodeList = [];
        
        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        // vm.getSltResponse = function(){
        //     vm.loading = true;
		// 	return entitySyncLogFactory.getSltResponseData('STATE_API_RESPONSE', vm.sltParams.LOG_ID).then(function (data) {
		// 		if(data.callSuccess == "1" ){
        //             vm.yourObjectOneJsonView = ObjectDiff.objToJsonView(JSON.parse(data.jsonObject[0].GROUP_SYNC_MSG));
        //             var tempData = JSON.parse(data.jsonObject[0].RESPONSE);
        //             vm.slt_api_response_data = tempData.entities;
        //             vm.loading = false;
		// 		}else{
		// 			vm.loading = false;
		// 			AlertService.add("error", data.data.message, 4000);
		// 		}
        //     });
        // };
        
        vm.getMeCodesList = function(){
            vm.loading = true;
            var params = {
                "tax_year": vm.sltParams.TAX_YEAR
            };
			return entitySyncLogFactory.getMeCodesList(params).then(function (data) {
				if(data.data.callSuccess == "1" ){
                    vm.meCodeList = data.data.jsonObject;
					//vm.loading = false;
                    return entitySyncLogFactory.getSltResponseData('STATE_API_RESPONSE', vm.sltParams.LOG_ID).then(function (data) {
                        if(data.callSuccess == "1" ){
                            vm.yourObjectOneJsonView = ObjectDiff.objToJsonView(JSON.parse(data.jsonObject[0].GROUP_SYNC_MSG));
                            var tempData = JSON.parse(data.jsonObject[0].RESPONSE);
                            angular.forEach(tempData.entities, function(row){
                                function meCodeExists(meCode) {
                                    return vm.meCodeList.some(function(el) {
                                        return el.ME_CODE === meCode;
                                    }); 
                                }
                                row.isPresent = meCodeExists(row.me_code);
                                // var isPresent = _.find(vm.meCodeList, function (obj) {
                                //     return (obj.ME_CODE == Number(row.me_code));
                                // });
                                //console.log(isPresent);
                            });
                            console.log(tempData.entities);
                            vm.slt_api_response_data = tempData.entities;
                            vm.loading = false;
                        }else{
                            vm.loading = false;
                            AlertService.add("error", data.data.message, 4000);
                        }
                    });
				}else{
					vm.loading = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };
        //vm.getSltResponse();
        vm.getMeCodesList();
    }
    return controllers;
});
