/**
 * Created by 212544474 on 2/17/2016.
 */

define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.form851Service', [])

					.factory(
							"Form851Factory",
							[
									'$q',
									'$http',
									'$log',
									'$rootScope',
									'AlertService',
									'GlobalService',
									'USER_SETTINGS',
									'$injector',
									'JsonObjectFactory',
									'workspaceFactory',
									'GENERAL_CONFIG',
									function($q, $http, $log, $rootScope,
											AlertService, GlobalService,
											USER_SETTINGS, $injector,
											JsonObjectFactory,
											workspaceFactory, GENERAL_CONFIG) {
										var form851 = {};
										var userSettings = USER_SETTINGS;
										var logged_in_user = userSettings.user.sso_id;
										var service_table_form = $injector
												.get('JsonObjectFactory');
										var URLS_DELETE = {
											// FETCH: GENERAL_CONFIG.base_url +
											// '/getProjectTag', // we can use
											// this when we have more URL'S
											SAVE : GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=zo239e",
											SAVE_MODAL : GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=oqco9i"
										}

										// yfilxy

										form851.saveForm851 = function(
												_callName, _data) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											// var rowData = _data.rowData;

											console.log(_data);
											var jsonObj = [];
											var dummyjson = {};
											var dummyjsonsettings = {};
											var jsonSettings = [];
											dummyjson["leid"] = _data.rowData.LEID;
											dummyjson["corp_no"] = _data.rowData.CORP_NO;
											dummyjson["col_name"] = _data.col_id;
											dummyjson["col_value"] = _data.rowData[_data.col_id];
											dummyjsonsettings["tax_year"] = GlobalService.globalParams.tax_year;
											dummyjsonsettings["scenario"] = GlobalService.globalParams.scenario;
											dummyjsonsettings["jcd_key"] = GlobalService.globalParams.jcd_key;
											dummyjsonsettings["sso_id"] = logged_in_user;
											dummyjsonsettings["filing_key"] = filterParams.filing_key;
											jsonSettings
													.push(dummyjsonsettings);
											jsonObj.push(dummyjson);
											var objId = _data.rowData.OBJECT_ID;
											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var params = _.merge({},
													GlobalService.globalParams,
													filterParams);

											params = _
													.extend(
															{
																jsonSettings : JSON
																		.stringify(jsonSettings)
															}, params);
											params = _.extend({
												jsonObj : JSON
														.stringify(jsonObj)
											}, params);
											params.process_name = "851_SAVE_GRID_DATA";
											params.sso_id = logged_in_user;
											var url = GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=zo239e";

											service_table_form
													.saveJSON(url, params)
													.then(
															function(data) {
																if (data.callSuccess === "1") {
																	AlertService
																			.add(
																					"success",
																					"Data saved Successfully.",
																					4000);
																	var args = {
																		OBJECT_ID : objId,
																		gridFilter : {
																			gridFilter : objId
																		}
																	};
																	$rootScope
																			.$emit(
																					'gridUpdate',
																					args);// $rootScope.$emit('gridUpdate',
																	// args);
																} else {
																	// rowData.ILLEGAL_PAYMENTS
																	// = null;
																	// rowData.SANCTIONED_COUNTRY
																	// = null;
																	AlertService
																			.add(
																					"error",
																					data.errorMessage,
																					4000);
																	$rootScope
																			.$broadcast(
																					'gridRefresh',
																					true);

																}
															});
										}
										form851.saveform851modal = function(
												_callName, _data) {
													var checkLockedbj = [];
											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
													checkLockedbj.push({
														"consol_group_key": filterParams.filing_key,
													})
											var jsonObj = JSON.stringify(_data);
											var jsonSettings = JSON
													.stringify(_callName);

											var params = filterParams;
											params.checkLockedbj = JSON.stringify(checkLockedbj);
											params = _.merge({},
													GlobalService.globalParams,
													params);
											params = _.extend({
												jsonObj : jsonObj
											}, params);
											params = _.extend({
												jsonSettings : jsonSettings
											}, params);
											// params =
											// _.extend({jsonSettings:jsonSettings},
											// _data);
											params.process_name = "INSERT_FORM_851_DATA";
											params.sso_id = logged_in_user;
											console.log(params);
											var promise = $http({
												method : "post",
												url : URLS_DELETE.SAVE_MODAL,
												data : params
											}).then(function(response) {
												var status = response.status;
												return response.data;
											});

											return promise;

										}

										return form851;

									} ])

			return services;

		});
