define([
            'angular'

        ], function () {
            'use strict';

            var controllers = angular.module('app.SummaryTrialBalanceCtrl', [])
                .controller('summaryTrailCtrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
                    'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', summaryTrailCtrl
                ]);

            function summaryTrailCtrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG) {
                var vm = this;
                vm.trailBal=
                    [{
                            "assets": "assests",
                            "tria_bal_amnt": "-33,456",
                            "subreclass_adj": "0",
                            "prior_11_30": "0",
                            "curr_11_30":"0",
                            "review_relcass": "83,556",
                            "currnt_year_amt": "-33,676,878",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "76,090",
                            "subreclass_adj": "-33,456",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "44,898",
                            "subreclass_adj": "0",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "0",
                            "subreclass_adj": "0",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "-33,676,878",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "0",
                            "subreclass_adj": "0",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "-33,456",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "76,090",
                            "subreclass_adj": "-33,456",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "44,898",
                            "subreclass_adj": "45,334",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "0",
                            "subreclass_adj": "45,334",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "305,929,286",
                            "varia_amnt": "-305,929,285",
                            "variance": "-100%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "0",
                            "subreclass_adj": "0",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "-33,456",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "89,098",
                            "subreclass_adj": "0",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "-33,456",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "76,090",
                            "subreclass_adj": "45,334",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "83,556",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "0",
                            "subreclass_adj": "0",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "0",
                            "subreclass_adj": "-33,676,878",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "0",
                            "variance": "0%"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "6,696,030",
                            "subreclass_adj": "0",
                            "prior_11_30" :"0",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "6,696,030",
                            "prior_year": "0",
                            "varia_amnt": "6,696,030",
                            "variance": "Infinity"
                        },
                        {
                            "assets": "assests",
                            "tria_bal_amnt": "45,334",
                            "subreclass_adj": "0",
                            "prior_11_30" :"-33,456",
                            "curr_11_30":"0",
                            "review_relcass": "0",
                            "currnt_year_amt": "0",
                            "prior_year": "0",
                            "varia_amnt": "83,556",
                            "variance": "0%"
                        }
                    ];
                }
            });