define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.schedule-OService',[])
       .factory("scheduleOServiceFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http,
		           $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var scheduleOServiceFactory ={};
	           scheduleOServiceFactory.url ={};
	           var service = $injector.get('JsonObjectFactory');
			   var defer = $q.defer();
	           scheduleOServiceFactory.url.save_schedule_O = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qyicdf";
	           
	           scheduleOServiceFactory.url.saveReptTrans = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qyicdf";
	           scheduleOServiceFactory.url.saveReptDisp = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qyicdf";
	           scheduleOServiceFactory.url.saveSuppleInfo = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qyicdf";
	           
	           scheduleOServiceFactory.url.deleteSchdInfo = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=cqgp9x";
			   scheduleOServiceFactory.url.saveSchdOEnabledFlag = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qyicdf";


			   scheduleOServiceFactory.saveScheduleO = function(_data, Details) {
	
		           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		           var jsonSettings =  JSON.stringify(_data);
		           var jsonObj = JSON.stringify(Details);
		               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
		           var params = filterParams;
		           params = _.merge({},  GlobalService.globalParams , params);
		           params =  _.extend({jsonObj:jsonObj}, params);
		           params =  _.extend({jsonSettings:jsonSettings}, params);
		//               params =  _.extend({jsonSettings:jsonSettings}, _data);
		           params.process_name =  "Schedule_O_Save";
		           service.saveJSON(scheduleOServiceFactory.url.save_schedule_O,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   AlertService.add("success", "Data is successfully saved/edited.", 4000);
							defer.resolve(data);
						}else {
                           AlertService.add("error", data.errorMessage);
                           defer.reject(data);
                       }},function(data){
                       defer.reject(data);
                   });
                   return defer.promise;
			   };
		          
		         /*  var promise = $http({
		        	   method: "post",
		               url: scheduleOServiceFactory.url.save_schedule_O,
		               data: params
		           }).then(function (response) {
		               var status = response.status;
		               return response.data;
		           });
		
		           return promise;*/
          // };
           
           scheduleOServiceFactory.saveReportableDisposition = function(_data, Details) {
        		
	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "save_Reportable_Disposition";
	           var promise = $http({
	        	   method: "post",
	               url: scheduleOServiceFactory.url.saveReptDisp,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });
	
	           return promise;
       };
       
       
       scheduleOServiceFactory.saveReportableTransfer = function(_data, Details) {
   		
           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
           var jsonSettings =  JSON.stringify(_data);
           var jsonObj = JSON.stringify(Details);
               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
           var params = filterParams;
           params = _.merge({},  GlobalService.globalParams , params);
           params =  _.extend({jsonObj:jsonObj}, params);
           params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
           params.process_name =  "save_Reportable_Transfer";
           var promise = $http({
        	   method: "post",
               url: scheduleOServiceFactory.url.saveReptTrans,
               data: params
           }).then(function (response) {
               var status = response.status;
               return response.data;
           });

           return promise;
   };

   scheduleOServiceFactory.saveSchdOEnabledFlag = function(_callName , _data){
       console.log("data", _data);
       console.log("callname",_callName);
       
       $rootScope.$broadcast('gridRefresh', false); 
       if(_callName === 'save_schd_o')
       {           	
       	
       	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
       	var editRow = [];
       	var tempJsonObj = [{
				"tax_year":GlobalService.globalParams.tax_year,
				"trans_type_key":_data.rowData.SCHEDULE_O_8865_ENABLED_TRANS_TYPE_KEY,
				"combination_key":_data.rowData.COMBINATION_KEY,
				"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
				"form_key":_data.rowData.FORM_KEY,
				"line_no":_data.rowData.SCHEDULE_O_8865_ENABLED_LINE_NO,
				"occurence":((_data.rowData.SCHEDULE_O_8865_ENABLED_OCCURRENCE == "0") ? null : _data.rowData.SCHEDULE_O_8865_ENABLED_OCCURRENCE),
				"line_attrib_key" : _data.rowData.SCHEDULE_O_8865_ENABLED_LINE_ATTRIB_KEY,
				"Attribute_Name":_data.rowData.SCHEDULE_O_8865_ENABLED_ATTRIB_NAME,
				"Attribute_Value":((_data.rowData.SCHD_O_ENABLED == 0 ) ? 'N' : 'Y'),
				"trans_dtls_key" : ((_data.rowData.SCHEDULE_O_8865_ENABLED_TRANS_DETAILS_KEY== "0") ? null : _data.rowData.SCHEDULE_O_8865_ENABLED_TRANS_DETAILS_KEY),
				"trans_status":"A"
			}];
			
			editRow.push(tempJsonObj);
			var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
			console.log("scheduleDetails--------------", scheduleDetails);
			
			scheduleOServiceFactory.saveScheduleO(scheduleSettings, scheduleDetails).then(function(result) {
				if (result.errorMessage && result.errorMessage !== 'null') 
				{
					AlertService.add("error", result.errorMessage, 1000);
				} 
				else
				{
					///AlertService.add("success", "Data is successfully saved/edited", 1000);
					var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: _data.rowData.COMBINATION_KEY,object_id:_data.rowData.object_id}};
                   $rootScope.$emit('gridUpdate', args);
				}
			});
       }         	 
    }

   
   scheduleOServiceFactory.saveSupplementalInformation = function(_data, Details) {
  		
       var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
       var jsonSettings =  JSON.stringify(_data);
       var jsonObj = JSON.stringify(Details);
           //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
       var params = filterParams;
       params = _.merge({},  GlobalService.globalParams , params);
       params =  _.extend({jsonObj:jsonObj}, params);
       params =  _.extend({jsonSettings:jsonSettings}, params);
//           params =  _.extend({jsonSettings:jsonSettings}, _data);
       params.process_name =  "save_Supplemental_Information";
       var promise = $http({
    	   method: "post",
           url: scheduleOServiceFactory.url.saveSuppleInfo,
           data: params
       }).then(function (response) {
           var status = response.status;
           return response.data;
       });

       return promise;
};
           scheduleOServiceFactory.deleteSchedule = function(data){
      		 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
      		 var jsonObj = JSON.stringify(data);
      		 
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
      		 var promise = $http({
      			 method: "POST",
      			 url: scheduleOServiceFactory.url.deleteSchdInfo,
      			 data: params
      		}). then(function (response){
      				//console.log()
      				return response.data;
      				});
      			
      			return promise;	
      	 };
      	 
      	 
    	
           
return scheduleOServiceFactory;
       }]);

   
   return services;
});