define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartVIII',[])
        .factory("K3PartVIIIFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K3PartVIIIFactory])

    function K3PartVIIIFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K3PartVIIIFactory.renderSK3PartVIIIDetails = function(ctrl) {
            ctrl.part_VIII_1065SK3Data = [];
            let partOccurrenceArray = [];
            ctrl.part_VIII_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'VIII', SECTION_NAME:'NA', ROW_TYPE: 'PH', ATTRIB_NAME: '1065_K3_VIII_N/A_N/A'});
            ctrl.part_VIII_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'VIII', SECTION_NAME:'NA', ATTRIB_NAME: '1065_K3_VIII_NA_'});

            for(let x = 0; x < ctrl.part_VIII_1065SK3Data.length; x++) {
                ctrl.part_VIII_1065SK3Data[x]['IS_CHANGED_FLAG'] = 'Y';
                if(!partOccurrenceArray.includes(ctrl.part_VIII_1065SK3Data[x].PART_OCCURRENCE))
                    partOccurrenceArray.push(ctrl.part_VIII_1065SK3Data[x].PART_OCCURRENCE);
            }
            console.log('partOccurrenceArray.length = ' , partOccurrenceArray.length);
            ctrl.part_VIII_1065SK3DataTransposed = transpose_parts_VIII(ctrl.part_VIII_1065SK3Data, partOccurrenceArray);
            console.log('ctrl.part_VIII_1065SK3DataTransposed = ' , ctrl.part_VIII_1065SK3DataTransposed);

            return ctrl;
        }

        function transpose_parts_VIII(partsData, partOccurrences) {

            let transposedPartsData = [];
            let po;
            let aPartDTData;
            let aPartPLData;
            let aPartCHData;
            let transposedCHData;
            let transposedPartData;

            for(let p = 0; p < partOccurrences.length; p++) {

                let v;
                let partJson = {}
                transposedPartData = [];
                po = partOccurrences[p];
                partJson['PART_OCCURRENCE'] = po;
                partJson['DELETE_OCCURRENCE'] = 0;
                partJson['PART_DATA'] = [];
                partJson['IS_NEW_PART_ADDED'] = 'N';

                aPartDTData = _.filter(partsData,function(o) { return o.PART_OCCURRENCE == po && o.ROW_TYPE == 'DT'});
                aPartPLData = _.filter(partsData,function(o) { return o.PART_OCCURRENCE == po && o.ROW_TYPE == 'PL'});
                aPartCHData = _.filter(partsData,function(o) { return o.PART_OCCURRENCE == po && o.ROW_TYPE == 'CH'});

                transposedCHData = transposeCHData(aPartCHData);

                for(v = 0; v < aPartDTData.length; v++) {
                    transposedPartData.push(aPartDTData[v]);
                }
                for(let w = 0; w < aPartPLData.length; w++) {
                    transposedPartData.push(aPartPLData[w]);
                    v = v + 1;
                }
                for(let x = 0; x < transposedCHData.length; x++) {
                    transposedPartData.push(transposedCHData[x]);
                }

                partJson.PART_DATA = transposedPartData;

                transposedPartsData.push(angular.copy(partJson));

            }

            return transposedPartsData;
        }


        function transposeCHData(partCHData) {

            let o = partCHData.reduce((a, b) => {
                a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
                a[b.LINE_ORDER].push(b);
                return a;
            }, {});
            let a = Object.keys(o).map(function (k) {
                return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
            });
            let xposedPartData = a;
            let c = [];

            for (let i = 0; i < xposedPartData.length; i++) {

                let z = xposedPartData[i].Line_data.reduce( (a,b) => {
                    a[b.ROW_OCCURRENCE] = a[b.ROW_OCCURRENCE] || [];
                    a[b.ROW_OCCURRENCE].push(b);
                    return a;
                }, {});

                let y = Object.keys(z).map(function (k) {
                    return { Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED: "N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Row_Occurrence: z[k][0].ROW_OCCURRENCE, Line_data_occ: z[k]};
                });

                if (i == 0) {

                    let m = angular.copy(y);
                    c = angular.copy(y[0]);
                    c.Line_Type = "HEADER_ROW";
                    c.Row_Occurrence = "";
                    c.Trans_details_key = "";
                    c.IS_EDITABLE = "Y";
                    for (var j=0; j < c.Line_data_occ.length; j++){
                        c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
                    }
                    if (xposedPartData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
                    }
                    else {
                        y.unshift(c);
                    }

                }
                else {
                    let key = Object.keys(z)[0];
                    if (xposedPartData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift({Line_Type: "SUB_ROW", Line_no: z[key][0].LINE_NO, Line_data_occ: []});
                    }
                }
                xposedPartData[i].Line_data = angular.copy(y);
            }

            return xposedPartData;

        }

        K3PartVIIIFactory.calcAmtSK3P8 = function(ctrl, partCH, formulaObjList, cell_name, cell_value, row, col) {

            console.log('formulaObj = ', formulaObj);
            console.log('cell_name = ', cell_name);
            console.log('cell_value = ', cell_value);

            let lineAmt = 0;
            let lineFormulaArray;
            let formulaOperandText;
            let targetCellObj;
            let formula;
            let matches;
            let originalValue;
            let line;
            let lineDataList = [];
            let lineData;
            let cellList;
            let occAmt = 0;


            let formulaObj = $filter("filter")(formulaObjList, {ATTRIB_CALC_FORMULA:'('+cell_name+')'})[0];
            console.log('formulaObj = ', formulaObj);

            lineAmt = 0;
            formula = formulaObj.ATTRIB_CALC_FORMULA;
            matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
            console.log('------- formula ----------' , formula);

            if(matches ==  null){
                lineFormulaArray = formula.split(/[+-/\\*\\]/);
            }

            for(let i = 0; i<lineFormulaArray.length; i++) {

                lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                formulaOperandText = lineFormulaArray[i].trim();
                console.log('formulaOperandText = ', formulaOperandText);

                for(let x = 0; x < partCH.length; x++) {
                    line =  partCH[x];
                    occAmt = 0;
                    lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                    for(let y = 0; y < lineDataList.length; y++) {
                        lineData = lineDataList[y];
                        cellList = lineData.Line_data_occ;
                        for(let z = 0; z < cellList.length; z++) {
                            if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                occAmt = occAmt + parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                lineAmt = occAmt;
                                break;
                            }
                        }
                    }
                }

                if(parseFloat(lineAmt)>= 0){
                    formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                }
                else
                    formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");


            }

            if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                let xyz = eval(formula);


                for(let x = 0; x < partCH.length; x++) {
                    line =  partCH[x];
                    lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                    for(let y = 0; y < lineDataList.length; y++) {
                        lineData = lineDataList[y];
                        if(lineData.Line_no == '5') {
                            row =  lineData;
                            break;
                        }
                    }
                }

                targetCellObj = $filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaObj.ATTRIB_NAME})[0];

                originalValue = targetCellObj.ATTRIBUTE_VALUE;
                if(originalValue != xyz) {
                    ctrl.setChange(targetCellObj,row);
                }
                targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();

            }

            return ctrl;
        }

        K3PartVIIIFactory.addRowOccurrenceP8 = function(ctrl, line_no, transposedPartData){

            let row_occurrence;

            let indexOfPartOcc = ctrl.part_VIII_1065SK3DataTransposed.indexOf(transposedPartData);

            let transposedPartDataDT = $filter("filter")(transposedPartData.PART_DATA,{ROW_TYPE: 'DT'});

            let transposedPartDataPL = $filter("filter")(transposedPartData.PART_DATA,{ROW_TYPE: 'PL'});

            let transposedPartDataCH = $filter("filter")(transposedPartData.PART_DATA,{ROW_TYPE: 'CH'});

            for(let i = 0; i < transposedPartDataCH.length; i++) {
                if(transposedPartDataCH[i].ROW_TYPE == 'CH') {
                    row_occurrence = transposedPartDataCH[i].Line_data[transposedPartDataCH[i].Line_data.length -1].Row_Occurrence + 1;
                }

                let new_row = angular.copy(transposedPartDataCH[i].Line_data[transposedPartDataCH[i].Line_data.length - 1]);
                new_row.Line_Type = "DATA_ROW";
                new_row.Row_Occurrence = row_occurrence;
                new_row.IS_EDITTED = "Y";

                if(new_row.Line_no == line_no){

                    for (let j = 0; j < new_row.Line_data_occ.length; j++){
                        if ( (new_row.Line_data_occ[j].EDIT_TYPE === "currency" || new_row.Line_data_occ[j].EDIT_TYPE === "label") && new_row.Line_data_occ[j].DATA_TYPE === "number"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = 0;
                        } else if (new_row.Line_data_occ[j].EDIT_TYPE === "text" && new_row.Line_data_occ[j].DATA_TYPE === "string"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = null;
                        }
                        new_row.Line_data_occ[j].ROW_OCCURRENCE = row_occurrence;
                        new_row.Line_data_occ[j].COL_OCCURRENCE = 1;
                    }
                    transposedPartDataCH[i].Line_data.push(new_row);
                }

            }

            ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA =  [];

            for(let i = 0; i < transposedPartDataDT.length; i++) {
                ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA.push(transposedPartDataDT[i]);
            }
            for(let i = 0; i < transposedPartDataPL.length; i++) {
                ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA.push(transposedPartDataPL[i]);
            }
            for(let i = 0; i < transposedPartDataCH.length; i++) {
                ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA.push(transposedPartDataCH[i]);
            }

            console.log('ctrl.part_VIII_1065SK3DataTransposed = ' , ctrl.part_VIII_1065SK3DataTransposed);

            return ctrl;
        }

        K3PartVIIIFactory.removeRowOccurrenceP8 = function(ctrl, line_no, Occurrence, index, transposedPartData) {

            console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);

            let indexOfPartOcc = ctrl.part_VIII_1065SK3DataTransposed.indexOf(transposedPartData);

            let transposedPartDataDT = $filter("filter")(transposedPartData.PART_DATA,{ROW_TYPE: 'DT'});

            let transposedPartDataPL = $filter("filter")(transposedPartData.PART_DATA,{ROW_TYPE: 'PL'});

            let transposedPartDataCH = $filter("filter")(transposedPartData.PART_DATA,{ROW_TYPE: 'CH'});


            for (var i=0; i < transposedPartDataCH.length; i++){
                if(transposedPartDataCH[i].ROW_TYPE == 'CH'){
                    for(let j=0; j < transposedPartDataCH[i].Line_data.length; j++){
                        if(j == index){
                            if((transposedPartDataCH[i].Line_data[j].Line_Type == "DATA_ROW" || transposedPartDataCH[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedPartDataCH[i].Line_data[j].Line_no == line_no && null != transposedPartDataCH[i].Line_data[j].Row_Occurrence && transposedPartDataCH[i].Line_data[j].Row_Occurrence != "" && transposedPartDataCH[i].Line_data[j].Row_Occurrence == Occurrence){

                                if(transposedPartDataCH[i].Line_data[j].IS_EDITTED == 'N') {
                                    transposedPartDataCH[i].DELETE_ROWS.push(transposedPartDataCH[i].Line_data[j]);
                                }
                                transposedPartDataCH[i].Line_data.splice(j,1);
                            }else if((transposedPartDataCH[i].Line_data[j].Line_Type == "DATA_ROW" || transposedPartDataCH[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedPartDataCH[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
                                transposedPartDataCH[i].Line_data.splice(j,1);
                            }
                        }
                    }
                }
            }

            ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA =  [];

            for(let i = 0; i < transposedPartDataDT.length; i++) {
                ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA.push(transposedPartDataDT[i]);
            }
            for(let i = 0; i < transposedPartDataPL.length; i++) {
                ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA.push(transposedPartDataPL[i]);
            }
            for(let i = 0; i < transposedPartDataCH.length; i++) {
                ctrl.part_VIII_1065SK3DataTransposed[indexOfPartOcc].PART_DATA.push(transposedPartDataCH[i]);
            }

            reEvaluateFormulaForTotalLinesP8(ctrl, transposedPartData);

            return ctrl;
        }

        K3PartVIIIFactory.addPartOccurrenceP8 = function(ctrl) {

            let partOccurrenceArray = [];
            let maxOcc = 1;

            for(let x = 0; x < ctrl.part_VIII_1065SK3DataTransposed.length; x++) {
                if(!partOccurrenceArray.includes(ctrl.part_VIII_1065SK3DataTransposed[x].PART_OCCURRENCE)) {
                    partOccurrenceArray.push(ctrl.part_VIII_1065SK3DataTransposed[x].PART_OCCURRENCE);
                    maxOcc = ctrl.part_VIII_1065SK3DataTransposed[x].PART_OCCURRENCE;
                }

            }

            let newPart = angular.copy(ctrl.part_VIII_1065SK3DataTransposed[0]);

            newPart.PART_OCCURRENCE = maxOcc + 1;
            newPart.DELETE_OCCURRENCE = 0;
            newPart.IS_NEW_PART_ADDED = 'Y';

            for(let l = 0; l < newPart.PART_DATA.length; l++) {
                if(newPart.PART_DATA[l].ROW_TYPE === 'DT') {
                    newPart.PART_DATA[l].PART_OCCURRENCE = maxOcc + 1;
                    newPart.PART_DATA[l].ATTRIBUTE_VALUE = null;
                    newPart.PART_DATA[l].IS_CHANGED_FLAG = 'Y';
                }
                else if(newPart.PART_DATA[l].ROW_TYPE === 'PL') {
                    newPart.PART_DATA[l].PART_OCCURRENCE = maxOcc + 1;
                    newPart.PART_DATA[l].ATTRIBUTE_VALUE = '0';
                    newPart.PART_DATA[l].IS_CHANGED_FLAG = 'Y';
                }
                else if(newPart.PART_DATA[l].ROW_TYPE === 'CH') {

                    let lineDataList = $filter("filter")(newPart.PART_DATA[l].Line_data,{Line_Type:'DATA_ROW'});

                    if(lineDataList.length > 1) {
                        newPart.PART_DATA[l].Line_data.splice(2,newPart.PART_DATA[l].Line_data.length - 2);
                    }

                    if(newPart.PART_DATA[l].Line_data.length == 2) {

                        if(newPart.PART_DATA[l].Line_data[1].Is_Multi == 'N') {
                            for(let ldo = 0; ldo < newPart.PART_DATA[l].Line_data[1].Line_data_occ.length; ldo++) {
                                newPart.PART_DATA[l].Line_data[1].Line_data_occ[ldo].PART_OCCURRENCE = maxOcc + 1;
                                newPart.PART_DATA[l].Line_data[1].Line_data_occ[ldo].IS_CHANGED_FLAG = 'Y';
                            }
                        }
                        else if(newPart.PART_DATA[l].Line_data[1].Is_Multi == 'Y') {

                            for(let ldo = 0; ldo < newPart.PART_DATA[l].Line_data[1].Line_data_occ.length; ldo++) {
                                newPart.PART_DATA[l].Line_data[1].Line_data_occ[ldo].PART_OCCURRENCE = maxOcc + 1;
                                newPart.PART_DATA[l].Line_data[1].Line_data_occ[ldo].IS_CHANGED_FLAG = 'Y';
                            }

                            newPart.PART_DATA[l].Line_data[1].Line_data_occ[0].ATTRIBUTE_VALUE = null;
                            newPart.PART_DATA[l].Line_data[1].Line_data_occ[1].ATTRIBUTE_VALUE = null;
                            newPart.PART_DATA[l].Line_data[1].Line_data_occ[2].ATTRIBUTE_VALUE = 0;
                            newPart.PART_DATA[l].Line_data[1].Line_data_occ[3].ATTRIBUTE_VALUE = 0;
                            newPart.PART_DATA[l].Line_data[1].Line_data_occ[4].ATTRIBUTE_VALUE = null;
                        }

                    }
                    else if(newPart.PART_DATA[l].Line_data.length == 1) {

                        if(newPart.PART_DATA[l].Line_data[0].Is_Multi == 'N') {

                            for(let ldo = 0; ldo < newPart.PART_DATA[l].Line_data[0].Line_data_occ.length; ldo++) {
                                newPart.PART_DATA[l].Line_data[0].Line_data_occ[ldo].PART_OCCURRENCE = maxOcc + 1;
                                newPart.PART_DATA[l].Line_data[0].Line_data_occ[ldo].IS_CHANGED_FLAG = 'Y';
                            }
                            newPart.PART_DATA[l].Line_data[0].Line_data_occ[0].ATTRIBUTE_VALUE = null;
                            newPart.PART_DATA[l].Line_data[0].Line_data_occ[1].ATTRIBUTE_VALUE = null;
                            newPart.PART_DATA[l].Line_data[0].Line_data_occ[2].ATTRIBUTE_VALUE = 0;
                            newPart.PART_DATA[l].Line_data[0].Line_data_occ[3].ATTRIBUTE_VALUE = 0;
                            newPart.PART_DATA[l].Line_data[0].Line_data_occ[4].ATTRIBUTE_VALUE = null;
                        }
                    }

                }
            }

            ctrl.part_VIII_1065SK3DataTransposed.push(newPart);

            return ctrl;
        }

        K3PartVIIIFactory.removePartOccurrenceP8 = function(ctrl, part) {

            if(part.IS_NEW_PART_ADDED == 'N') {
                ctrl.part_VIII_1065SK3DataTransposed_deletePart.push(part);
            }

            let indexOfPartOcc = ctrl.part_VIII_1065SK3DataTransposed.indexOf(part);
            ctrl.part_VIII_1065SK3DataTransposed.splice(indexOfPartOcc,1);

            return ctrl;
        }

        K3PartVIIIFactory.obtainChanges = function(ctrl) {

            let part;
            let partDataArray;
            let lineObj;
            let dataRows;
            let fmrecs;
            let cells
            let partData;
            let partDataCH;
            let valuePresent = false;

            ctrl.partVIIIChanges = [];

            if(ctrl.part_VIII_1065SK3DataTransposed.length == 1) {

                partData = ctrl.part_VIII_1065SK3DataTransposed[0].PART_DATA;
                for(let x = 0; x < partData.length; x++) {
                    let line = partData[x];
                    if(line.ROW_TYPE == 'DT') {
                        if(line.hasOwnProperty('ATTRIBUTE_VALUE') == true && line.ATTRIBUTE_VALUE != null && line.ATTRIBUTE_VALUE != '' && line.ATTRIBUTE_VALUE != "") {
                            valuePresent = true;
                            break;
                        }
                    }
                    else if(line.ROW_TYPE == 'PL') {
                        if(line.hasOwnProperty('ATTRIBUTE_VALUE') == true && line.ATTRIBUTE_VALUE != null && line.ATTRIBUTE_VALUE != '0') {
                            valuePresent = true;
                            break;
                        }
                    }
                    else {
                        break;
                    }
                }
                if(valuePresent == false) {
                    partDataCH = $filter("filter")(partData,{ROW_TYPE:'CH'});
                    valuePresent = ctrl.checkValueEnteredInPart8(partDataCH);
                }

                if(valuePresent == false)
                    return ctrl;
            }

            for(let po = 0; po < ctrl.part_VIII_1065SK3DataTransposed.length; po++) {

                part = ctrl.part_VIII_1065SK3DataTransposed[po];
                partDataArray = part.PART_DATA;

                for(let pd = 0; pd < partDataArray.length; pd++) {
                    lineObj = partDataArray[pd];
                    if(lineObj.ROW_TYPE==='DT') {
                        ctrl.partVIIIChanges.push(lineObj);
                    }
                    else if(lineObj.ROW_TYPE==='PL') {
                        ctrl.partVIIIChanges.push(lineObj);
                    }
                    else if(lineObj.ROW_TYPE==='CH') {
                        dataRows = $filter("filter")(lineObj.Line_data, {Line_Type:'DATA_ROW'});
                        for( let y = 0; y < dataRows.length; y++) {
                            fmrecs = /*$filter("filter")(*/dataRows[y].Line_data_occ/*, {IS_CHANGED_FLAG:'Y'})*/;
                            ctrl.partVIIIChanges.push(fmrecs);
                        }
                    }
                }
            }

            // push row-occurrences to be deleted from DB (IS_CHANGED_FLAG = D )
            for(let po = 0; po < ctrl.part_VIII_1065SK3DataTransposed.length; po++) {
                part = ctrl.part_VIII_1065SK3DataTransposed[po];
                partDataArray = part.PART_DATA;
                for(let pd = 0; pd < partDataArray.length; pd++) {
                    lineObj = partDataArray[pd];
                    if(lineObj.ROW_TYPE==='CH') {
                        if(lineObj.DELETE_ROWS.length > 0) {
                            for(let d = 0; d < lineObj.DELETE_ROWS.length; d++) {
                                let lineData = lineObj.DELETE_ROWS[d];
                                for(let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                    lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                                }
                                ctrl.partVIIIChanges.push(lineData.Line_data_occ);
                            }
                        }
                    }

                }
            }


            // push all fmrecs for the part-occurrences to be deleted from DB (IS_CHANGED_FLAG = D )
            if(ctrl.part_VIII_1065SK3DataTransposed_deletePart.length > 0) {

                for(let x = 0; x < ctrl.part_VIII_1065SK3DataTransposed_deletePart.length; x++) {
                    part = ctrl.part_VIII_1065SK3DataTransposed_deletePart[x];
                    partDataArray = part.PART_DATA;

                    for(let pd = 0; pd < partDataArray.length; pd++) {
                        lineObj = partDataArray[pd];
                        if(lineObj.ROW_TYPE==='DT') {
                            lineObj.IS_CHANGED_FLAG = 'D';
                            ctrl.partVIIIChanges.push(lineObj);
                        }
                        else if(lineObj.ROW_TYPE==='PL') {
                            lineObj.IS_CHANGED_FLAG = 'D';
                            ctrl.partVIIIChanges.push(lineObj);
                        }
                        else if(lineObj.ROW_TYPE==='CH') {
                            dataRows = $filter("filter")(lineObj.Line_data, {Line_Type:'DATA_ROW'});
                            for( let y = 0; y < dataRows.length; y++) {
                                for(let v = 0; v < dataRows[y].Line_data_occ.length; v++) {
                                    dataRows[y].Line_data_occ[v].IS_CHANGED_FLAG = 'D';
                                }
                                ctrl.partVIIIChanges.push(dataRows[y].Line_data_occ);
                            }
                        }
                    }
                }
            }

            for(let row = 0; row < ctrl.partVIIIChanges.length; row++) {
                cells = ctrl.partVIIIChanges[row];
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }

            return ctrl;

        }

        K3PartVIIIFactory.validateSK3DataPartVIII = function(ctrl, k3Data) {

            let cell;
            let line_no;

            let part;
            let partDataArray;
            let lineObj;
            let cells
            let lineData;
            let cellObj;
            let cellValue;
            let dupeMsg;
            let cellValues = [];
            let countryMatched = false;

            let col_0;
            let col_a;
            let col_b_val;
            let col_c_val;
            let row_occ;



            if(k3Data != undefined && k3Data.length > 0) {

                console.log('k3Data part VIII : ', k3Data);

                for(let x = 0; x < k3Data.length; x++) {

                    cell = k3Data[x];
                    line_no = cell.LINE_NO;

                    if(cell.LINE_NO == 'A' && cell.ATTRIB_NAME == '1065_K3_VIII_NA_A_a') {
                        if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                            ctrl.ERROR_MESSAGE = 'EIN or Reference ID Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' , Line: ' +  line_no ;
                        }
                        else if(cell.ATTRIBUTE_VALUE.length < 9) {
                            ctrl.ERROR_MESSAGE = 'EIN or Reference ID Cannot be less than 9 literals ~ ' + ' in Part: ' + cell.PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' , Line: ' +  line_no ;
                        }
                    }
                    else if(cell.LINE_NO == 'B' && cell.ATTRIB_NAME == '1065_K3_VIII_NA_B_a') {
                        if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {
                            ctrl.ERROR_MESSAGE = 'Category Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' , Line: ' +  line_no ;
                        }
                    }
                    else if(cell.LINE_NO == 'C' && cell.ATTRIB_NAME == '1065_K3_VIII_NA_C_a') {
                        if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {
                            let cellOccurrence_C = cell.PART_OCCURRENCE;
                            let cell_B = $filter("filter")(k3Data, {PART_NO: 'VIII', PART_OCCURRENCE: cellOccurrence_C, LINE_NO: 'B', ATTRIB_NAME: '1065_K3_VIII_NA_B_a'})[0];
                            if(cell_B.ATTRIBUTE_VALUE == 'PAS') {
                                ctrl.ERROR_MESSAGE = 'Applicable Grouping Cannot be empty when Categroy is PAS ~ ' + ' in Part: ' + cell.PART_NO + ' ,  Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' , Line: ' +  line_no ;
                            }
                        }
                        else if(cell.ATTRIBUTE_VALUE != '(i)' && cell.ATTRIBUTE_VALUE != '(ii)' && cell.ATTRIBUTE_VALUE != '(iii)' && cell.ATTRIBUTE_VALUE != '(iv)') {
                            let cellOccurrence_C = cell.PART_OCCURRENCE;
                            let cell_B = $filter("filter")(k3Data, {PART_NO: 'VIII', PART_OCCURRENCE: cellOccurrence_C, LINE_NO: 'B', ATTRIB_NAME: '1065_K3_VIII_NA_B_a'})[0];
                            if(cell_B.ATTRIBUTE_VALUE == 'PAS') {
                                ctrl.ERROR_MESSAGE = 'Applicable Grouping must be either (i), or (ii) or (iii) or (iv) when Categroy is PAS ~ ' + ' in Part: ' + cell.PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' ,  Line: ' +  line_no ;
                            }
                            else if(cell_B.ATTRIBUTE_VALUE == 'GEN' || cell_B.ATTRIBUTE_VALUE == '90ij') {
                                ctrl.ERROR_MESSAGE = 'Applicable Grouping must be empty when Categroy is not PAS ~ ' + ' in Part: ' + cell.PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' , Line: ' +  line_no ;
                            }
                        }
                        else if(cell.ATTRIBUTE_VALUE == '(i)' || cell.ATTRIBUTE_VALUE == '(ii)' || cell.ATTRIBUTE_VALUE == '(iii)' || cell.ATTRIBUTE_VALUE == '(iv)') {

                            let cellOccurrence_C = cell.PART_OCCURRENCE;
                            let cell_B = $filter("filter")(k3Data, {PART_NO: 'VIII', PART_OCCURRENCE: cellOccurrence_C, LINE_NO: 'B', ATTRIB_NAME: '1065_K3_VIII_NA_B_a'})[0];
                            if(cell_B.ATTRIBUTE_VALUE == 'GEN' || cell_B.ATTRIBUTE_VALUE == '90ij') {
                                ctrl.ERROR_MESSAGE = 'Applicable Grouping must be empty when Categroy is not PAS ~ ' + ' in Part: ' + cell.PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' , Line: ' +  line_no ;
                            }
                        }
                    }
                    else if(cell.ROW_TYPE == 'CH'  && line_no != '1' && line_no != '5') {

                        if(cell.ATTRIB_COL == 'a') {

                            if(cell.ATTRIBUTE_VALUE != null && cell.ATTRIBUTE_VALUE != '' && cell.ATTRIBUTE_VALUE != "") {

                                countryMatched = false;
                                for(let j = 0; j < ctrl.countryCodeList.length; j++ ) {
                                    if(ctrl.countryCodeList[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                        countryMatched = true;
                                        break;
                                    }
                                }
                                if(countryMatched == false) {
                                    ctrl.ERROR_MESSAGE = 'Invalid Country Code ~ ' + ' in Part: ' + cell.PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(cell.PART_OCCURRENCE) +  ' , Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                }
                            }

                        }
                    }

                    if(ctrl.ERROR_MESSAGE != null) {
                        ctrl.HAS_ERROR = true;
                        break;
                    }
                }


                if(ctrl.HAS_ERROR == false) {

                for(let po = 0; po < ctrl.part_VIII_1065SK3DataTransposed.length; po++) {
                    part = ctrl.part_VIII_1065SK3DataTransposed[po];
                    partDataArray = part.PART_DATA;
                    for(let pd = 0; pd < partDataArray.length; pd++) {
                        lineObj = partDataArray[pd];
                        if(lineObj.ROW_TYPE==='CH') {
                            lineData = $filter("filter")(lineObj.Line_data, {Line_Type:'DATA_ROW'});
                            if(lineData[0].Is_Multi == 'Y') {
                                for(let y = 0; y < lineData.length; y++) {
                                    row_occ = y+1;
                                    col_0 = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'0'})[0];
                                    col_a = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'a'})[0];
                                    col_b_val = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'b'})[0].ATTRIBUTE_VALUE;
                                    col_c_val = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'c'})[0].ATTRIBUTE_VALUE;

                                    if(col_b_val != 0 || col_c_val != 0) {

                                        if(col_0.hasOwnProperty('ATTRIBUTE_VALUE') == false || col_0.ATTRIBUTE_VALUE == null || col_0.ATTRIBUTE_VALUE == '' || col_0.ATTRIBUTE_VALUE == "") {
                                            ctrl.ERROR_MESSAGE = 'Please provide Unit  ~ ' + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  lineData[y].Line_no + ' Row Occurrence: ' + row_occ;
                                        }
                                        else if(col_a.hasOwnProperty('ATTRIBUTE_VALUE') == false || col_a.ATTRIBUTE_VALUE == null || col_a.ATTRIBUTE_VALUE == '' || col_a.ATTRIBUTE_VALUE == "") {
                                            ctrl.ERROR_MESSAGE = 'Please provide Country Code   ~ ' + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  lineData[y].Line_no + ' Row Occurrence: ' + row_occ;
                                        }
                                    }
                                    else if(col_b_val == 0) {

                                        if( (col_0.hasOwnProperty('ATTRIBUTE_VALUE') == true && col_0.ATTRIBUTE_VALUE != null && col_0.ATTRIBUTE_VALUE != '' && col_0.ATTRIBUTE_VALUE != "") && ( col_a.hasOwnProperty('ATTRIBUTE_VALUE') == false || col_a.ATTRIBUTE_VALUE == null || col_a.ATTRIBUTE_VALUE == '' || col_a.ATTRIBUTE_VALUE == "" ) ) {
                                            ctrl.ERROR_MESSAGE = 'Please provide Country Code  ~ ' + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  lineData[y].Line_no + ' Row Occurrence: ' + row_occ;
                                        }
                                        else if((col_a.hasOwnProperty('ATTRIBUTE_VALUE') == true && col_a.ATTRIBUTE_VALUE != null && col_a.ATTRIBUTE_VALUE != '' && col_a.ATTRIBUTE_VALUE != "") && ( col_0.hasOwnProperty('ATTRIBUTE_VALUE') == false || col_0.ATTRIBUTE_VALUE == null || col_0.ATTRIBUTE_VALUE == '' || col_0.ATTRIBUTE_VALUE == "" ) ) {
                                            ctrl.ERROR_MESSAGE = 'Please provide Unit  ~ ' + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  lineData[y].Line_no + ' Row Occurrence: ' + row_occ;
                                        }


                                    }
                                    else if(col_c_val == 0) {

                                        if( (col_0.hasOwnProperty('ATTRIBUTE_VALUE') == true && col_0.ATTRIBUTE_VALUE != null && col_0.ATTRIBUTE_VALUE != '' && col_0.ATTRIBUTE_VALUE != "") && ( col_a.hasOwnProperty('ATTRIBUTE_VALUE') == false || col_a.ATTRIBUTE_VALUE == null || col_a.ATTRIBUTE_VALUE == '' || col_a.ATTRIBUTE_VALUE == "" ) ) {
                                            ctrl.ERROR_MESSAGE = 'Please provide Country Code  ~ ' + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  lineData[y].Line_no + ' Row Occurrence: ' + row_occ;
                                        }
                                        else if( (col_a.hasOwnProperty('ATTRIBUTE_VALUE') == true && col_a.ATTRIBUTE_VALUE != null && col_a.ATTRIBUTE_VALUE != '' && col_a.ATTRIBUTE_VALUE != "") && ( col_0.hasOwnProperty('ATTRIBUTE_VALUE') == false || col_0.ATTRIBUTE_VALUE == null || col_0.ATTRIBUTE_VALUE == '' || col_0.ATTRIBUTE_VALUE == "" ) ) {
                                            ctrl.ERROR_MESSAGE = 'Please provide Unit  ~ ' + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  lineData[y].Line_no + ' Row Occurrence: ' + row_occ;
                                        }

                                    }

                                }
                            }
                            else if(lineData[0].Is_Multi == 'N') {
                                for(let y = 0; y < lineData.length; y++) {
                                    if(lineData[y].Line_no != '1' && lineData[y].Line_no != '5') {
                                        col_a = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'a'})[0];
                                        col_b_val = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'b'})[0].ATTRIBUTE_VALUE;
                                        col_c_val = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'c'})[0].ATTRIBUTE_VALUE;

                                        if(col_b_val != 0 || col_c_val != 0) {
                                            if(col_a.hasOwnProperty('ATTRIBUTE_VALUE') == false || col_a.ATTRIBUTE_VALUE == null || col_a.ATTRIBUTE_VALUE == '' || col_a.ATTRIBUTE_VALUE == "") {
                                                ctrl.ERROR_MESSAGE = 'Please provide Country Code   ~ ' + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  lineData[y].Line_no;
                                            }
                                        }
                                    }
                                }
                            }

                            if( ctrl.ERROR_MESSAGE != null) {
                                break;
                            }

                        }
                    }
                    if( ctrl.ERROR_MESSAGE != null) {
                        ctrl.HAS_ERROR = true;
                        break;
                    }

                }
            }

                // checking for duplicate unit + country code
                if(ctrl.HAS_ERROR == false) {

                    for(let po = 0; po < ctrl.part_VIII_1065SK3DataTransposed.length; po++) {

                        part = ctrl.part_VIII_1065SK3DataTransposed[po];
                        partDataArray = part.PART_DATA;
                        for(let pd = 0; pd < partDataArray.length; pd++) {
                            lineObj = partDataArray[pd];
                            cellValues = [];
                            if(lineObj.ROW_TYPE==='CH') {
                                lineData = $filter("filter")(lineObj.Line_data, {Line_Type:'DATA_ROW'});
                                line_no = lineData[0].Line_no;
                                if(lineData.length > 1) {
                                    for(let y = 0; y < lineData.length; y++) {

                                        cellValue = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'0'})[0].ATTRIBUTE_VALUE + '' + $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'a'})[0].ATTRIBUTE_VALUE;
                                        cellValues.push(cellValue);
                                    }
                                    let duplicates = cellValues.reduce(function(acc, el, i, arr) {
                                        if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
                                    }, []);
                                    console.log('duplicates = ' , duplicates);
                                    if(duplicates.length > 0) {
                                        dupeMsg = 'Duplicate Unit and Country Code combination ';
                                        ctrl.ERROR_MESSAGE = dupeMsg + ' in Part: ' + ctrl.part_VIII_header_1065SK3Data[0].PART_NO + ' , Part Occurrence: ' + ctrl.calcAlpha(part.PART_OCCURRENCE) +  ' , Line: ' +  line_no ;
                                        break;
                                    }
                                }
                            }
                        }

                        if( ctrl.ERROR_MESSAGE != null) {
                            ctrl.HAS_ERROR = true;
                            break;
                        }

                    }
                }

            }

            return ctrl;
        }

        function reEvaluateFormulaForTotalLinesP8(ctrl, transposedPartData) {

            let lineAmt = 0;
            let lineFormulaArray;
            let formulaOperandText;
            let targetCellObj;
            let formula;
            let matches;
            let line;
            let lineDataList = [];
            let lineData;
            let cellList = [];
            let occAmt = 0;
            let originalValue;

            let formulaObjs5 = $filter("filter")(ctrl.allFormFormulaListSK3, {PART_NO:'VIII',LINE_NO:'5'});

            let transposedPartDataCH = $filter("filter")(transposedPartData.PART_DATA,{ROW_TYPE:'CH'});

            for(let k = 0; k < formulaObjs5.length; k++) {

                lineAmt = 0;
                formula = formulaObjs5[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                //console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }

                for(let i = 0; i<lineFormulaArray.length; i++) {

                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();

                    for(let x = 0; x < transposedPartDataCH.length; x++) {
                        occAmt = 0;
                        line = transposedPartDataCH[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            //console.log('Line No :', lineData.Line_no , ' Row Occurrence : ', lineData.Row_Occurrence);
                            cellList = lineData.Line_data_occ;
                            for(let z = 0; z < cellList.length; z++) {
                                if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                    occAmt = occAmt + parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                    lineAmt = occAmt;
                                }
                            }
                        }
                    }


                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");

                }


                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);
                    for(let x = 0; x < transposedPartDataCH.length; x++) {
                        line = transposedPartDataCH[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            if(lineData.Line_no == formulaObjs5[k].LINE_NO) {
                                targetCellObj =  $filter("filter")(lineData.Line_data_occ,{ATTRIB_NAME:formulaObjs5[k].ATTRIB_NAME})[0];
                                originalValue = targetCellObj.ATTRIBUTE_VALUE;
                                if(originalValue != xyz) {
                                    ctrl.setChange(targetCellObj,lineData);
                                }
                                targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();
                            }
                        }
                    }
                }

            }

        }

        return K3PartVIIIFactory;

    }
    return services;
});