define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.sltKeySetupService',[])

        .factory("sltKeySetupFactory",['GENERAL_CONFIG', 'AlertService', '$http', '$rootScope',
                function(GENERAL_CONFIG, AlertService, $http, $rootScope) {
          var sltKeySetupFactory = {};
          
            var URLS = {
                getKeySetup: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32711",
                reSyncGroup: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32737"
            }

            //Service call to Get all the user list.. 32653  
            sltKeySetupFactory.getKeySetupData = function(tax_year, group_key) {
                var promise = $http({
                    method: "GET",
                    url: URLS.getKeySetup + '&tax_year='+ tax_year +'&mef_group_key=' + group_key
                }).then(function(response) {
                    if(response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        AlertService.add("error", "No users list found", 4000);
                    }
                });
                return promise;
            }

            sltKeySetupFactory.reSyncGroup = function(tax_year, group_key) {
              
                var promise = $http({
                    method: "POST",
                    url: URLS.reSyncGroup + '&tax_year=' + tax_year + '&group_key=' + group_key,
                    //data: params
                }).then(function(response) {
                    return response;
                });
                return promise;
            }

            return sltKeySetupFactory;
        }])

    return services;
});