define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.processMapService',[])

        .factory("processMapFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,JsonObjectFactory, GENERAL_CONFIG) {
          var processMapFactory = {};

            var URLS = {
                PROCESSES: GENERAL_CONFIG.base_url + '/getTaxPlacematDetails',
                TASKS: GENERAL_CONFIG.admin_api_url + '/getTaskDpc',
                SAVE: GENERAL_CONFIG.admin_api_url + '/saveTaxPlacematDetails',
                DELETE : GENERAL_CONFIG.admin_api_url + '/removeTaxPMProcess',
                UPDATE : GENERAL_CONFIG.admin_api_url + '/updateTaxPMProcess',
                TAXPLACEMATS : GENERAL_CONFIG.base_url + '/getTaxPlacematList',
                DELETE_PLACEMATS : GENERAL_CONFIG.admin_api_url + '/deletePlacemat'
            }

            var dragKey;

            processMapFactory.setDragKey = function(key){
                dragKey = key;
            }

            processMapFactory.getDragKey = function(){
                return dragKey;
            }

            processMapFactory.getMappedProcesses = function(key, id){
                var promise = $http({
                    method: "GET",
                    url: URLS.PROCESSES + '?clientKey=' + key + '&placematId=' + id
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){                       
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            processMapFactory.getTasksHierarchy = function(key){
                var promise = $http({
                    method: "GET",
                    url: URLS.TASKS + '?clientKey=' + key
                }).then(function (response) {
                    if(response.data !== undefined){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };
            
            processMapFactory.saveMappedProcesses  = function(data, client_id, id, name){
            	var data = data;
            	var params =  _.extend({data :data});
                params.clientKey = client_id;
                params.placematId = id;
                params.placematName = name;
            	var promise = $http({
                    method: "POST",
                    url: URLS.SAVE,
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };
            
            processMapFactory.saveUpdatedProcesses  = function(data,placemat_id){
            	var data = data;
            	var params =  _.extend({data :data});
            	var promise = $http({
                    method: "POST",
                    url: URLS.UPDATE + '?placemat_id=' + placemat_id,
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            processMapFactory.deleteMappedProcesses  = function(proc_id, client_key,placemat_id){
                var promise = $http({
                    method: "POST",
                    url: URLS.DELETE + '?clientKey=' + client_key + '&process_id=' + proc_id + '&placemat_id='+ placemat_id
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            processMapFactory.deleteTaxPlacemat  = function(client_key, placemat_id){
                var promise = $http({
                    method: "DELETE",
                    url: URLS.DELETE_PLACEMATS + '?client_key=' + client_key + '&placemat_id='+ placemat_id
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            processMapFactory.fetchTaxPlacemats  = function(client_key){
                var promise = $http({
                    method: "GET",
                    url: URLS.TAXPLACEMATS + '?clientKey=' + client_key
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            return processMapFactory;
        }])

    return services;
});