define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.SystemToFormMappingCtrl', []);

    controllers
        .controller('SystemToFormMappingCtrl', ['TAX_YEARS', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal',
            'workspaceFactory', 'GlobalService', 'SystemMappingFactory', systemToFormMappingCtrl])
    
    function systemToFormMappingCtrl(TAX_YEARS, $rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal,
        workspaceFactory, GlobalService, SystemMappingFactory) {
        var vm = this;
        vm.name = "userListCtrl";
        vm.data = [];
        vm.api = $state.current.api.get;
        var custom_data_key = "AC1245671"
        vm.taxYears = [];
        vm.taxYears = TAX_YEARS;
        vm.scenairoList = [];
        var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

        vm.addMapping = function(){
            var data = {};
            var modalObj = {};
            modalObj.template = "app/components/admin/masterData/system-mapping/create-system-account.html";
            modalObj.controler = "CreateSytemAccountCtrl as ctrl";
            modalObj.size = "lg";
            vm.openModal(modalObj,data);
        }
     
        $scope.ctrlFn = function(_callName,_data,_type) {
            var modalObj = {};
            modalObj.template = "app/components/admin/masterData/system-mapping/edit-system-account.html";
            modalObj.controler = "EditSytemAccountCtrl as ctrl";
            modalObj.size = "lg";
            vm.openModal(modalObj,_data);
        };   
        
        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
        /* get the scenario List based on tax year selection 6/17/2019 */

        vm.getScenarioList = function (year) {
            vm.scenairoList = _.find(TAX_YEARS, {
                value: year.value
            }).scenarios;
            vm.defScenario = _.filter(vm.scenairoList, {
                default_scenario: 'Y'
            });
            vm.wfScenario = vm.defScenario[0].scenario;
        }

        if (cachedData && cachedData.data) {
            vm.taxYear = _.find(TAX_YEARS, {
                value: cachedData.data.params.tax_year
            }) //cachedData.data.params.tax_year;
            vm.wfScenario = cachedData.data.params.scenario;
            vm.scenairoList = _.find(TAX_YEARS, {
                value: vm.taxYear.label
            }).scenarios
        } else {
            vm.taxYear = GlobalService.inputs.tax_year.selected;
            vm.scenairoList = vm.taxYear.scenarios;
            vm.wfScenario = GlobalService.globalParams.scenario;
        }

        vm.refresh = function () {
            getData(true);
        };

        var gridUpdateListener = $rootScope.$on('gridUpdate', function (event, args) {
            getUpDate(args);
        });

        $scope.$on('$destroy', function () {
            $log.log("Unregistering listener");
            gridUpdateListener();
        });

        var getUpDate = function (args) {
            $scope.$broadcast("showDataRefresh", true);
            var params = args;
            var _filterObj = {};
            GridFactory.getDataUpdate(vm.api, params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {
                        filter: _filterObj,
                        data: data.data
                    });
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });

        }


        var getData = function (refresh) {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);


            //TODO: Finish CUSTOME DATA STORE

            if (cachedData && cachedData.data && !refresh) {
                vm.data = cachedData.data.data;
                $scope.$broadcast("dataLoaded", vm.data);
                $scope.$broadcast("showLoader", false);
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
               // console.log("cusomCache", workspaceFactory.customDataCache);
            }

            var params = {
                tax_year: vm.taxYear.value,
                scenario: vm.wfScenario,
                jcd_key: 250
            }

            GridFactory.getDataCustom($state.current.api.get, params).then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                    workspaceFactory.setCustomDataCache(custom_data_key, {
                        params: params,
                        data: vm.data
                    });
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        $timeout(function () {
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            if (cachedData && cachedData.data) {
                getData(false);
            }
        }, 5);

        /* pencil icon function */
   /*     $scope.ctrlFn = function (_callName, _data, _type) {

            console.log("_callName ", _callName)
            console.log("_data ", _data.rowData)
            console.log("_type ", _type);
            _data.params = {
                tax_year: vm.taxYear.value,
                scenario: vm.wfScenario
            }

            $rootScope.$broadcast("workflow.addTab", _data);
            $rootScope.$broadcast("workflow.entity", _data);

        };*/

        //FOR ADD USER
        $scope.openModal = function (modalObj, _data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });


        };

        /* workflow entity mapping */
        vm.restult = [];


    }

    return controllers;
});