define([
    'angular'

], function () {
    'use strict';

    var services = angular.module('app.entityUpdateService', [])

        .factory('entityUpdateFactory', ['$q', '$http', '$timeout', '$rootScope', '$log', 'DataFiltersFactory', 'workspaceFactory', 'JsonObjectFactory', 'USER_SETTINGS', 'AlertService', 'GlobalService', 'GENERAL_CONFIG',
            function entityUpdateFactory($q, $http, $timeout, $rootScope, $log, DataFiltersFactory, workspaceFactory, JsonObjectFactory, USER_SETTINGS, AlertService, GlobalService, GENERAL_CONFIG) {
                var factory = {};
                var URLS = {
                    GET_COLUMN_NAME: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=gdl671",
                    GET_TIER_DATA: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=fph6uq",
                    SAVE_EC_CHANGES: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0jag4h",
                    GET_TRANS_TYPE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=ki6cyg",
                    DELETE_CODE_COMBI: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=72e1gq",
                    GET_SYNC_DETAILS_DROPDOWN_DATA: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=wklkxd",
                    ENTITY_CHANGE_CLEANUP: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0yx5vx",
                }
                var userSettings = USER_SETTINGS;
                var logged_in_user = userSettings.user.sso_id;

                factory.getTierData = function () {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params.entity_type = params.entity_type_list;
                    params.sso_id = logged_in_user;
                    params.screen_key = GlobalService.globalParams.screen_key;
                    var promise = $http({
                        method: "get",
                        url: URLS.GET_TIER_DATA,
                        params: params

                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });
                    return promise;
                }

                factory.getColumnNames = function (_tier) {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params.tier = _tier;
                    params.entity_type = params.entity_type_list;
                    params.sso_id = logged_in_user;
                    var promise = $http({
                        method: "get",
                        url: URLS.GET_COLUMN_NAME,
                        params: params

                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });
                    return promise;
                }

                factory.saveEntity = function (_data, _settings, _tier) {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var jsonObj = JSON.stringify(_data);
                    _settings = filterParams;
                    _settings.jcd_key = GlobalService.globalParams.jcd_key;
                    var jsonSettings = JSON.stringify(_settings);

                    var params = filterParams;

                    params = _.merge({},
                        GlobalService.globalParams,
                        params);
                    params = _.extend({
                        clob_data: jsonObj
                    }, params);
                    params = _.extend({
                        clob_settings: jsonSettings
                    }, params);
                    params.entity_type = filterParams.entity_type_list;
                    params.tier = _tier;
                    params.process_name = "SAVE_EC_CHANGES";
                    params.sso_id = logged_in_user;

                    var promise = $http({
                        method: "POST",
                        url: URLS.SAVE_EC_CHANGES,
                        data: params
                    }).then(function (response) {
                        return response;
                    });

                    return promise;
                }

                factory.getTransType = function (combi_key) {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params.combination_key = combi_key;
                    params.jcd_key = GlobalService.globalParams.jcd_key;

                    params.sso_id = logged_in_user;
                    var promise = $http({
                        method: "get",
                        url: URLS.GET_TRANS_TYPE,
                        params: params

                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });
                    return promise;
                }

                factory.deleteCodeCombi = function (_data, _delType) {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var jsonObj = JSON.stringify(_data);
                    var _settings = filterParams;
                    _settings.jcd_key = GlobalService.globalParams.jcd_key;
                    var jsonSettings = JSON.stringify(_settings);

                    var params = filterParams;
                    params = _.merge({},
                        GlobalService.globalParams,
                        params);
                    params = _.extend({
                        clob_data: jsonObj
                    }, params);
                    params = _.extend({
                        clob_settings: jsonSettings
                    }, params);
                    params.process_name = "delete-transaction";
                    params.sso_id = logged_in_user;
                    params.delete_type = _delType;

                    var promise = $http({
                        method: "POST",
                        url: URLS.DELETE_CODE_COMBI,
                        data: params
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                }

                factory.getSyncDropdownData = function () {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params = _.merge({},GlobalService.globalParams, params);

                    params.sso_id = logged_in_user;
                    var promise = $http({
                        method: "get",
                        url: URLS.GET_SYNC_DETAILS_DROPDOWN_DATA,
                        params: params

                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });
                    return promise;
                }

                factory.entityChangeCleanup = function (cng_type, le_key) {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params = _.merge({},GlobalService.globalParams, params);
                    params.process_name = "entity-change-cleanup";
                    params.sso_id = logged_in_user;
                    params.cng_type = cng_type;
                    params.le_key = le_key;
                    params.old_fg = '';

                    var promise = $http({
                        method: "POST",
                        url: URLS.ENTITY_CHANGE_CLEANUP,
                        data: params
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                }

                return factory;

            }]);


    return services;


});