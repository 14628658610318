define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.SuplementService', [])

        .factory("SupplementFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,
                DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
            }
        ])
    return services;
});