define([
    'angular'
  ], function () {
    'use strict';

    var controllers = angular.module('app.dstLaunchCtrl', [])
    .controller('dstLaunchCtrl', ['USER_SETTINGS', dstLaunchCtrl]);

    function dstLaunchCtrl(USER_SETTINGS){
        var vm = this;

        vm.LaunchDst =function(){
            /* it should only for client- GE */
            if(USER_SETTINGS.user.client_key == 1){
                vm.link = "https://ist.pwcinternal.com/00a6d6/dst/defaultIndex.ge";
            }

        }

    }

    return controllers;
});