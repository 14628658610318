define([
    'angular',
    'vsRepeat',
    './newProformaGtwCtrl',
    './newProformaGtwTabService'
], function () {
    'use strict';
    return angular.module('app.newProformaGtw', ['app.newProformaGtwCtrl','app.newProformaTabServices'])

        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
            $stateProvider
                // .state('workspace.newproformagtw', {
                //     //parent: 'workspace.newproformagtw',
                //     // redirectTo: 'newproformagtw.gtwview12', 
                //     url: '/newproformagtw',
                //     templateUrl: 'app/components/newProforma/newProformaGtw/index.html',
                //     api:{},

                // })
                .state('workspace.newproformagtw.gtwview12', {
                    
                    url: '/gtwview12',
                    templateUrl: 'app/components/newProforma/new-proforma-gtw.html',
                    controller: 'NewProformaGtwViewCtrl as ctrl',
                    api:{},
                })
                .state('workspace.newproformagtw.formreview', {
                    url: '/formreview/:id',
                    templateUrl: 'app/components/newProforma/newProformaGtw/new-proforma-tab.html',
                    controller: 'newProformaPdfPopupCtrl as ctrl',
                    api:{},
                })
            }]);
        });
        