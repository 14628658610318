define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.F1065PageOneCtrl', [])
        .controller('F1065PageOneController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$filter','$rootScope', '$timeout', 'rowData', 'colData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', F1065PageOneController]);


    function F1065PageOneController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $filter, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        
        // modalTitle shouldn't be hardcoded.
        vm.modalTitle = "Schedule F Increase (Decrease) in Reserves (section 807) and Company/Policyholder Share Percentage (section 812) (see instructions)";
        vm.userSettings = USER_SETTINGS;
        getcodeMastDetails();
		vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.netReserves = 0;
        vm.batchLoader = false;
        vm.showBatchData = false;
        vm.showRequestLoader = true;
        vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';
        vm.codeMastMap = [];
        vm.tax_year = rowData.TAX_YEAR;
        vm.isSaving = false;

        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = '1065 Page 1' + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';
    
        vm.formData = {
            "addrows": [],
            "editrows": [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME
        };

		$scope.unallocatedAmount = rowData.dig || {};

        var saveParamDtls = [];
        var tempDataObj = {};
        $scope.scheduleFFormView = {rows: []};
        vm.showFormLoader = true;
        function getmodalData() {   
            var params = {
                "action_id": 31259,
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY,
                "jcd_key": rowData.JCD_KEY,
                "combination_key": rowData.COMBINATION_KEY, 
                "schedule_name": "NA",
                "part_no": "NA"
            };
			
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadPivotFormJson', params).then(function (data) {
                vm.modalTitle = data.irsForm.formItemList[0].dataRowList[0].dataCellList[1].name; // get the title from service
               for (var i = 0; i < data.irsForm.formItemList.length; i++) {
                    saveParamDtls = [];
                    tempDataObj = {};
                    
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    tempDataObj.saveParams = saveParamDtls;                   

                    for (var j = 0; j < data.irsForm.formItemList[i].dataRowList[0].dataCellList.length; j++) {
                        if (j == 0) {
                            tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 1) {
                            tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 2) {
                            tempDataObj.lineAttrKeyA = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
							}
                            if(i== 4 || i==7 || i==19 || (i>=25 && i<=58) ){
                            	 if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                     tempDataObj.value1 = 0;
                                 } else {
                                     tempDataObj.value1 = parseInt(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value);
                                 }
                            }else if(i== 0 || i==1 || i==6 ){
                            	if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value ==null || 
                            			data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value ==''){
                            		tempDataObj.value1 = '';
                            	}else {
                            		tempDataObj.value1 =  new Date(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value);
                            	}
                            } else{
                            	tempDataObj.value1 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                            }
                        } else{
                            tempDataObj.lineAttrKeyB = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            tempDataObj.value2 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                        }
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    $scope.scheduleFFormView.rows.push(tempDataObj);
                }
                $scope.scheduleFFormView.rows[5].value1 = rowData.EIN;
                if(vm.codeMastMap.length>1){
                    angular.forEach(vm.codeMastMap, function(obj,key){
                        if($scope.scheduleFFormView.rows[4].value1 == obj.CODE_NAME){
                            $scope.scheduleFFormView.rows[4].value1 = obj.CODE_NAME;
                        }
                    }) 
                    console.log('code list response success');
                }
                if(vm.tax_year == 2023){
                    if($scope.scheduleFFormView.rows[59].value1 === "true"){
                        tempDataObj.value1 = true;
                    }else if($scope.scheduleFFormView.rows[59].value2 === "true"){
                        tempDataObj.value1 = false;
                    }else{
                        tempDataObj.value1 = 'NA';
                    }
                }else{
                    if($scope.scheduleFFormView.rows[57].value1 === "true"){
                        tempDataObj.value1 = true;
                    }else if($scope.scheduleFFormView.rows[57].value2 === "true"){
                        tempDataObj.value1 = false;
                    }else{
                        tempDataObj.value1 = 'NA';
                    }
                }
                vm.formListOriginal = angular.copy($scope.scheduleFFormView);
                initFormView($scope.scheduleFFormView);
                vm.showFormLoader = false;
            });
        }
        
        	// get code mast details data
	        function getcodeMastDetails(){
	      	  var params = {
	     	   			"action_code": 'kdep1i',
	     	   			"sso_id": vm.logged_in_user 
	     	   		 } 
	   	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
	   	             if (data.callSuccess === "1" ) {
	   	            	vm.codeMastMap = data.jsonObject;
                        if($scope.scheduleFFormView && $scope.scheduleFFormView.rows.length>1){
                            angular.forEach(vm.codeMastMap, function(obj,key){
                                if($scope.scheduleFFormView.rows[4].value1 == obj.CODE_NAME){
                                    $scope.scheduleFFormView.rows[4].value1 = obj.CODE_NAME;
                                }
                            }) 
                            console.log('code list response success');
                        }
	   	             }
	   	             else
	   	               {
	   	            	 if(data.errorMessage === "no access"){
	   	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
	   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	   	               }
	   	            	 else
	   	            	 {
	   	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
	   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	   	                 }
	   	             }
	
	   	         });
   	     	};
   	     	
   	     	vm.updateColumnB = function(cVal){
   	     	if ( typeof(cVal) !== "undefined" && cVal !== null ) {
   	     	angular.forEach(vm.codeMastMap, function(obj,key){
	     			if(obj.CODE_NAME == cVal){
	     			$scope.scheduleFFormView.rows[3].value1 = obj.CODE_DESC;
	     			}
	     		})
	     		console.log(cVal);
   	     	}else{
   	     		$scope.scheduleFFormView.rows[3].value1 ='';
   	     	}
   	     		
   	     		
   	     	}
        
        $scope.fieldVisibility = function (index, state, name){
            if((name == 'yes' && !state) || (name == 'no' && state)){
                $scope.scheduleFFormView.rows[index].value1 = 'NA';
            };
        };

        //SECTION H
        vm.disableOtherH = function(num) { 
        	for(var i=15; i<18;i++){
        		if(num !=i){ 
        			$scope.scheduleFFormView.rows[i].value1 = 'N';
        		}
        	}
        }
        
        getmodalData();// this is the main method which gets data to be displayed on the modal

        vm.computeValues2019 = function(row) {
        	 $scope.scheduleFFormView.rows[53].value1 =  $scope.scheduleFFormView.rows[49].value1 + 
											        	 $scope.scheduleFFormView.rows[50].value1 +
											        	 $scope.scheduleFFormView.rows[51].value1 +
											        	 $scope.scheduleFFormView.rows[52].value1 ;
        	 
        	 if($scope.scheduleFFormView.rows[54].value1 < $scope.scheduleFFormView.rows[53].value1){
        		 $scope.scheduleFFormView.rows[55].value1 = $scope.scheduleFFormView.rows[53].value1 - 
        		 											$scope.scheduleFFormView.rows[54].value1;
        		 $scope.scheduleFFormView.rows[56].value1 = 0;  
        	 }else{
        		 
        		 $scope.scheduleFFormView.rows[56].value1 = $scope.scheduleFFormView.rows[54].value1 - 
															$scope.scheduleFFormView.rows[53].value1;
        		 	$scope.scheduleFFormView.rows[55].value1 = 0;  
        	 }
        	
        }

        vm.computeValues2023 = function() {
            //Add lines 24 through 27.
            $scope.scheduleFFormView.rows[54].value1 =  $scope.scheduleFFormView.rows[50].value1 + 
                                                        $scope.scheduleFFormView.rows[51].value1 +
                                                        $scope.scheduleFFormView.rows[52].value1 +
                                                        $scope.scheduleFFormView.rows[53].value1 ;
            
            //Sum of line 29 and 30
            var sum_of_line_29_30 = $scope.scheduleFFormView.rows[55].value1 + $scope.scheduleFFormView.rows[56].value1;
            
            //If the sum of line 29 and line 30 is smaller than line 28, enter amount owed
            if(sum_of_line_29_30 < $scope.scheduleFFormView.rows[54].value1){
                $scope.scheduleFFormView.rows[57].value1 = sum_of_line_29_30 - 
                                                            $scope.scheduleFFormView.rows[54].value1;
                //$scope.scheduleFFormView.rows[56].value1 = 0;  
            }else{
                $scope.scheduleFFormView.rows[58].value1 = $scope.scheduleFFormView.rows[54].value1 - 
                sum_of_line_29_30;
                //$scope.scheduleFFormView.rows[55].value1 = 0;  
            }
       }

        vm.save1065Page1 = function (url, data) {
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);
            params.process_name = workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
            	vm.showFormLoader = false;
                var status = response.status;
                console.log('------------------status is: --------------------------', status);
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage);
                }
                vm.isSaving = false;
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            
            return promise;
        };

        vm.save = function () {
          if($scope.scheduleFFormView.rows[0].value1 > $scope.scheduleFFormView.rows[1].value1){
        		      AlertService.add("error", "End date cannot be before Start date", 4000);  
          }else{
            vm.isSaving = true;
          	vm.showFormLoader = true;
        		var formRowDataA = {};
                var formRowDataB = {};
                var tempArray = [];
                $scope.scheduleFFormView.rows[0].value1 = $filter('date')($scope.scheduleFFormView.rows[0].value1,'MM/dd/yyyy');
                $scope.scheduleFFormView.rows[1].value1 = $filter('date')($scope.scheduleFFormView.rows[1].value1,'MM/dd/yyyy');
                $scope.scheduleFFormView.rows[6].value1 = $filter('date')($scope.scheduleFFormView.rows[6].value1,'MM/dd/yyyy');
                if($scope.scheduleFFormView.rows[17].value1 !='Y'){
                	$scope.scheduleFFormView.rows[18].value1 = '';
                }
                for (var i = 0; i < $scope.scheduleFFormView.rows.length; i++) {
                    formRowDataA = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleFFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_a,
                        line_description: $scope.scheduleFFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleFFormView.rows[i].num,
                        occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyA,
                        Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_a,
                        Attribute_Value: $scope.scheduleFFormView.rows[i].value1 === 'NA' ? 'false' : $scope.scheduleFFormView.rows[i].value1,
                        trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    tempArray.push(formRowDataA);
                    if ($scope.scheduleFFormView.rows[i].attrib_order_b !== undefined){
                        formRowDataB = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleFFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_b,
                        line_description: $scope.scheduleFFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleFFormView.rows[i].num,
                        occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyB,
                        Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_b,
                        Attribute_Value: $scope.scheduleFFormView.rows[i].value1 === true ? 'false' : $scope.scheduleFFormView.rows[i].value1 == 'NA' ? 'false' : $scope.scheduleFFormView.rows[i].value1 === false ? 'true' : 'true',
                        trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    tempArray.push(formRowDataB);
                    }
                }
                vm.formData.editrows.push(tempArray);
                vm.mainData = [];
                vm.mainData.push(vm.formData);
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tkgaam";
                vm.save1065Page1(url, vm.mainData);
                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function () {
                    $scope.$broadcast("showLoader", true);
                }, 100); 
        	}	 
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.reset = function () {
            $scope.scheduleFFormView = angular.copy(vm.formListOriginal);
        }
        
        function initFormView(filteredFormData) {
            $scope.scheduleFFormView = filteredFormData;
        }

        //Dynamic Tabset Code
        vm.tabs = [ 
            {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Page-One/F1065-Page-One-Entry.html", active: true }
            //{id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFVersion.html", active: false}
        ];
    }
});