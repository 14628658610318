
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.rerunPdfGenerationService',[])

    .factory('rerunPdfGenerationServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var rerunPdfGenerationServiceFactory = {};
        
        rerunPdfGenerationServiceFactory.saveRegenerateProcess = function(params){
            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=xozyvn',
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        
        return rerunPdfGenerationServiceFactory
    }])

    return services;

});