define(
    ['angular'],
    function () {
        'use strict';

        var services = angular.module('app.estRolloverService',[])
            .factory('estRolloverServiceFactory',
                ['$q', '$http', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG',estRolloverServiceFactory]);

        function estRolloverServiceFactory($q, $http, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG) 
        {
            var factory = {};
            factory.Quarters = [];
            factory.getRolloverData = function(_params){
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id=31543',
                    data: _params
                }).then(function (response) {
                    return response.data;
                });

                return promise;
            };
            factory.setLockedQuarter = function(lockedQuarters){
                factory.Quarters = lockedQuarters;
            }
            factory.getLockedQuarters = function(){
                return factory.Quarters;
            }
        return factory;
        }
        return services;
    }
);