define([
	'angular'
 
 ], function () {
	'use strict';
 
	var services =  angular.module('app.diagnosticDashboardService',[])
		.factory("diagnosticDashboardFactory",[			
			function() {
                var vm = this;
				var diagnosticDashboardFactory = {};
				
				diagnosticDashboardFactory.tableLinlksData = function(passedParams) {
					// vm.serviceData = [];
					this.data = passedParams;
                    // return passedParams;
				};
			
				return diagnosticDashboardFactory;
		}]);
 
		  return services;
 });