define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.LimitationService', [])

        .factory("LimitObjFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService','USER_SETTINGS',
            'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,USER_SETTINGS,
                DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
        	
        	var limitationsaveFactory = {};
        	limitationsaveFactory.url = {};
        	limitationsaveFactory.url.saveCharitabaleContribution  =  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zkiwtz";
        	limitationsaveFactory.url.savegainLoss  =  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=c7ivwh";
        	limitationsaveFactory.url.savegain1231  =  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qwxryb";
        	limitationsaveFactory.url.savedrdws  =  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=t5qeqa";

        	
        	limitationsaveFactory.saveCharitabaleContribution = function(_data,_accounts){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_accounts);
                var jsonSetting =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSetting:jsonSetting}, params);
                params.process_name =  "CHARITABLE_CONTRIBUTION_SAVE";
               // console.log("reclass params to save");
               // console.log(params);
                var promise = $http({
                    method: "post",
                    url: limitationsaveFactory.url.saveCharitabaleContribution,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            limitationsaveFactory.savecapitalgainloss = function(_data,_accounts){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_accounts);
                var jsonSetting =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSetting:jsonSetting}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "CAPITAL_GAIN_LOSS_SAVE";
               // console.log("reclass params to save");
               // console.log(params);
                var promise = $http({
                    method: "post",
                    url: limitationsaveFactory.url.savegainLoss,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            limitationsaveFactory.save1231gain = function(_data,_accounts){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_accounts);
                var jsonSetting =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSetting:jsonSetting}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "GAIN1231_SAVE";
               // console.log("reclass params to save");
               // console.log(params);
                var promise = $http({
                    method: "post",
                    url: limitationsaveFactory.url.savegain1231,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            limitationsaveFactory.saveDRDWS = function(_data,_accounts){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_accounts);
                var jsonSetting =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSetting:jsonSetting}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "SAVEDRDWS";
               // console.log("reclass params to save");
               // console.log(params);
                var promise = $http({
                    method: "post",
                    url: limitationsaveFactory.url.savedrdws,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            return limitationsaveFactory;
        }]);


    return services;


});