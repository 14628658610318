define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.localSysChartAcctMapService',[])
       .factory("LocalGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http,
		           $stateParams, $timeout, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
           var factory = {};
           var returnObj = {};
           var localGroupObjFactory ={};
           localGroupObjFactory.url ={};
           localGroupObjFactory.url.local_to_system_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hhba21"
           localGroupObjFactory.url.delete_tax_sensitive_details  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hhba21";
           localGroupObjFactory.url.save_tax_sensitive_details  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nbms3a";
           localGroupObjFactory.url.delete_chart_acct_map  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=6us3oe";

        	   localGroupObjFactory.getAccntDetails = function(_data){
               console.log("_data ::",_data);
               var params= {
                       "action_code": 'hhba21',
                       "OBJECT_ID": _data.OBJECT_ID
               }
               return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                     console.log("groupObjFactory.getAccntDetails() data::",data);

                     if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                        AlertService.add("error", data.errorMessage);
                        return false;
                    }else{
                        return data.jsonObject;
                    }
                });
              }
              localGroupObjFactory.saveIRSFormDetails = function(_data,Details){

	               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var jsonObj = JSON.stringify(Details);
	               var jsonSettings =  JSON.stringify(_data);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	               
	               var params = filterParams;
	               params = _.merge({},  GlobalService.globalParams , params);
	               params =  _.extend({jsonObj:jsonObj}, params);
	               params =  _.extend({jsonSettings:jsonSettings}, params);
	               params.process_name =  "LOCAL_TO_SYSTEM_SAVE";
	               var promise = $http({
	                   method: "post",
	                   url: localGroupObjFactory.url.local_to_system_save,
	                   data: params
	               }).then(function (response) {
	                   var status = response.status;
	                   return response.data;
	               });
	               return promise;
             };
           
           localGroupObjFactory.saveTax  = function(_data,Details){

	               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var jsonObj = JSON.stringify(Details);
	               var jsonSettings =  JSON.stringify(_data);
	               console.log(jsonSettings);
	               var params = filterParams;
	               params = _.merge({},  GlobalService.globalParams , params);
	               params =  _.extend({jsonObj:jsonObj}, params);
	               params =  _.extend({jsonSettings:jsonSettings}, params);
	               params.process_name =  "Save_Tax_Sensitive_Details";
	               var promise = $http({
	                   method: "post",
	                   url: localGroupObjFactory.url.save_tax_sensitive_details,
	                   data: params
	               }).then(function (response) {
	                   var status = response.status;
	                   return response.data;
	               });
	               return promise;
            };
           
           localGroupObjFactory.DeleteTax  = function(_data1,Details1){

	               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var jsonObj = JSON.stringify(Details1);
	               var jsonSettings =  JSON.stringify(_data1);
	               var params = filterParams;
	               params = _.merge({},  GlobalService.globalParams , params);
	               params =  _.extend({jsonObj:jsonObj}, params);
	               params =  _.extend({jsonSettings:jsonSettings}, params);
	               params.process_name =  "Delete_Tax_Sensitive_Details";
	               console.log(params);
	               var promise = $http({
	                   method: "post",
	                   url: localGroupObjFactory.url.delete_tax_sensitive_details,
	                   data: params
	               }).then(function (response) {
	                   var status = response.status;
	                   return response.data;
	               });
	               return promise;
           };
           localGroupObjFactory.DeleteChartAcctMap  = function(_data1,Details1){

               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
               var jsonObj = JSON.stringify(Details1);
               var jsonSettings =  JSON.stringify(_data1);
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
               params =  _.extend({jsonSettings:jsonSettings}, params);
               params.process_name =  "Delete_Chart_Acct_Map";
               console.log(params);
               var promise = $http({
                   method: "post",
                   url: localGroupObjFactory.url.delete_chart_acct_map,
                   data: params
               }).then(function (response) {
                   var status = response.status;
                   return response.data;
               });
               return promise;
       };
           return localGroupObjFactory;
       }]);

   return services;
});
