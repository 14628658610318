/**
 * Created by Mallesh Nagothi on 10/11/2018.
 */


define([
    'angular'

], function () {
    'use strict';

    var directives =  angular.module('app.genericFormScreenDirectives',[])
    .directive('gtFormScreen', ['$compile','$parse','$state','$injector','AlertService', function( $compile,$parse,$state,$injector,AlertService) {
        return {
            restrict: 'A',
            scope:{
                menuData: '=',
                callBackService: '='
            },
           controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                
			}],

            link:function ( scope, element, attrs, ctrl ) {
            	var mySelf = this;
                scope.callBackService    = attrs.callBackService;
                var proxyService         = $injector.get(scope.callBackService);
                console.log("proxyService ::",proxyService);
                console.log("Elements CTRL :",element);
                
                scope.linkDelinkAction = function(ruleActionKey,currStatus){
                    console.log("linkDelinkAction() ruleActionKey :",ruleActionKey);
                    console.log("linkDelinkAction() currStatus :",currStatus);
                    var data = {};
                    data.rule_action_key = ruleActionKey;
                    if(currStatus==="1"){
                    	data.lnk_or_d_lnk = 0;
                    }else{
                    	data.lnk_or_d_lnk = 1;
                    }
                    
                    proxyService.linkDelinkRuleAction(data).then(function (data) {
              		    var callStatus = parseInt(data.callSuccess);
                        if(callStatus === 1 ){
                            console.log("data", data);
                            AlertService.add("success", "Link/Delink Action Successfully Completed", 4000);
                        }else if(callStatus < 0){
                        	console.log("data", data);
                            AlertService.add("error", "Link/Delink Action Failed :", 4000);
                        }
                        else {
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                            }else{
                                AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                    });
                    
                };
            	
            	 var template= '' +
		            '<div data-ng-repeat="node in menuData" class="panel gt-dark-header"> ' +
					       '<div  class="row" data-ng-repeat="formElem in node.form_elements | orderBy : formElem.element_row_order "> '+
						       '<div   class="col-md-11 text-right"> '+
						       		'{{formElem.form_header_desc}} '	+
						       '</div> '+
						       '<div   class="col-md-1 text-left"> '+
						       		'<span>0</span> '	+
						       '</div> '+
					       '</div> '+
					       ' <div data-ng-repeat="aPartItem in node.part_items | orderBy : aPartItem.element_row_order "> '+
						       '<div  class="row"> '+
							       '<div   class="col-md-12 text-left"> '+
							       '<h4>{{aPartItem.part_item_desc}}</h4> '	+
							       '</div> '+	
							    '</div> '+
							    '<div  class="row"> '+
								    '<div class="col-md-12 text-left"> '+
									    '<div class="tree-container-body"> '+
									        '<table class="table table-striped table-sm" style="font-size: 9pt;"> '+
											        '<thead><tr"> '+
											        '<th  data-ng-repeat="aPartColHeader in aPartItem.part_col_headers  | orderBy : aPartColHeader.element_col_order"> '+
											        '{{aPartColHeader.col_description}} '+
											        '</th> '+
										        '</tr></thead> '+
										        '<tbody> '+
										            '<tr> '+
										            	'<td> '+
										            		'<i class="fa fa-plus-circle color-green fa-lg pull-left" style="cursor: pointer; margin-top: 4px;" uib-tooltip="Add a Line Item" tooltip-placement="right" tooltip-trigger="mouseenter" ></i> '+
											     		'</td> '+
											     		'<td colspan="{{aPartItem.part_col_headers.length-1}}"> '+
											     			'Add Gain or (Loss) Details '+
									            		'</td> '+
										            '</tr> '+
										        	'<tr> '+
												    /*'<tr data-ng-repeat="aPartColData in aPartItem.part_col_data | unique: aPartColData.occurance | orderBy : aPartColData.occurance"> '+*/
												     	'<td data-ng-repeat="aPartColValue in aPartItem.part_col_data | orderBy : aPartColValue.element_col_order"> '+
												        	'{{aPartColValue.line_attrib_val}}'+
												        '</td> ' +
											        '</tr> '+
											        '<tr data-ng-repeat="aPartItemDetail in aPartItem.part_item_details"> '+
												     	'<td data-ng-repeat="aPartItemColDetail in aPartItemDetail  | orderBy : aPartItemColDetail.element_col_order"> '+
												        	'{{aPartItemColDetail.part_item_details_line_desc}}'+
												        '</td> '+
												        '<td colspan="{{ (aPartItem.part_col_headers.length - aPartItem.part_item_details.length)- 1 }}"> '+
												        	'&nbsp; '+
												        '</td> '+
												        '<td> '+
												        	'0 '+
												        '</td> '+
											        '</tr> '+
											    '</tbody> '+
										     '</table> ' +  
										'</div> '+
								    '</div> '+
							    '</div> '+
							'</div> '+
					   '</div> ';
				    
            	 	 element.html('').append( $compile( template )( scope ) );
              
            }
        };
    }])
    
   
    
    return directives;


});

