define([
    'angular'

 ], function () {
    'use strict';
    
    
    var controllers = angular.module('app.F-6252Ctrl', [])
    .controller('F6252Controller', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F6252Factory', 'ModalFactory',F6252Controller])
    .controller('F6252ControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F6252Factory', 'ModalFactory','$uibModalInstance','rowData', 'colData', 'gridData',F6252ControllerModal])    ;

    function F6252Controller(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F6252Factory,ModalFactory) {
    	F6252ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F6252Factory,ModalFactory,{},{}, {}, {},this);
    }


	function F6252ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F6252Factory,ModalFactory,$uibModalInstance,
        rowData, colData, gridData,thisObj) {
		//console.log("------------At the start-----------");
		
		var vm = this;
		vm.rowData = rowData;
		vm.globalParams = GlobalService.globalParams;
		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		vm.curentyear = filterParams.tax_year;
		//console.log("The Global Params like tax_year, scenario and jcd are ::::::::::::", vm.globalParams);
		vm.f6252Header = [];
		vm.f6252List = [];
		vm.f6252OriginalData = [];
		vm.f6252FormData = [];
		//vm.f6252FormDataNew = [];//with no amount line
		//vm.f6252ColumnHeader = [];
		vm.f6252FinalData = [];
		//vm.unAllocatedAmt = 0;
		vm.crudLoading = false;
		vm.showAttributes = false;
		vm.initialLoad = false;
		vm.calendar = {
    			opened: false
    		};
		
		
		vm.cancel = function() {
			//console.log("Form 6252 data from DB is :::::::::::", vm.f6252FormData);
			//console.log("Form 6252 data from original Data is saved in :::::::::::", vm.f6252OriginalData);
			if(angular.equals(vm.f6252FormData, vm.f6252OriginalData)){
				$uibModalInstance.dismiss('cancel');
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
					vm.cancelModal();
				}
				else {
					$uibModalInstance.dismiss('cancel');
				}
			}
			
		};
		

        vm.formatDate = function(row) {
        	row["taxyearBE"] = $filter('date')(row.ATTRIB_VALUE, 'MM/dd/yyyy');
        	//vm.checkChangedValues(row);
        };
		
		vm.openCalendar = function(row) {
			row['isOpen'] = true;
		};
		
		vm.openCalendar1 = function(row) {
			//vm.calendar.opened = true;
			row['isOpen'] = true;
		};
		
        
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		//console.log(vm.logged_in_user);
		
		function getF6252Data(){
			F6252Factory.fetchF6252Details(rowData, vm.logged_in_user).then(function (data){
			//	console.log("Came back to controller from Service...............:::::::::", data);
				vm.f6252Header = data.jsonObject[0];
				vm.f6252List1 = data.jsonObject.slice(1);
				vm.tableHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"TH"})
				
				vm.f6252List = vm.f6252List1.filter(function( obj ) {
					return obj.ROW_TYPE !== 'TH';
				});
				
				//ROW_TYPE
				var m = vm.f6252List.reduce( (a,b) => {
					a[b.IS_MULTI] = a[b.IS_MULTI] || [];
					a[b.IS_MULTI].push(b);
					return a;
				}, {});
				
				var b = Object.keys(m).map(function(k){
					return {IS_MULTI: m[k][0].IS_MULTI, Row_data: m[k]};
				});
				
				vm.f6252RowData = b;
				//console.log("After first Reduce at IS_MULTI now the data is", vm.f6252RowData);
				

				
				for (var i=0; i < vm.f6252RowData.length; i++){
					var z = vm.f6252RowData[i].Row_data.reduce( (a,b) => {
						a[b.TRANS_OCCURRENCE] = a[b.TRANS_OCCURRENCE] || [];
						a[b.TRANS_OCCURRENCE].push(b);
						return a;
					}, {});
					
					var a = Object.keys(z).map(function(k){
						//return {TRANS_OCCURRENCE: z[k][0].TRANS_OCCURRENCE, DELETE_ROWS: [], Line_data: z[k],FORM_INPUT_DATA: z[k][0].FORM_INPUT_DATA};
						return {Line_Type: "INITIAL_ROW",  TRANS_OCCURRENCE: z[k][0].TRANS_OCCURRENCE,FORM_INPUT_DATA: z[k][0].FORM_INPUT_DATA, Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", IS_CURRENT_CHANGED_ROW:"N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Line_data: z[k],DELETE_ROWS: []};
					});
					
					vm.f6252RowData[i].Row_data = a ;//vm.f6252List;
					//console.log("After second Reduce at TRANS_OCCURRENCE now the data is", vm.f6252RowData[i].Row_data);
					
					var c = [];
					if (vm.f6252RowData[i].IS_MULTI != 'Y'){
						for (var j=0; j < vm.f6252RowData[i].Row_data.length; j++){
							
							if(vm.f6252RowData[i].Row_data[j].Line_data[0].TRANS_OCCURRENCE == null){
							  vm.f6252RowData[i].Row_data[j].TRANS_OCCURRENCE =1;
							}

								var o = vm.f6252RowData[i].Row_data[j].Line_data.reduce( (a,b) => {
									a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
									a[b.LINE_ORDER].push(b);
									return a;
								}, {});
								
								var a = Object.keys(o).map(function(k){
									return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data_occ: o[k]};
//									return {ROW_TYPE: o[k][0].ROW_TYPE};
								});
								
								vm.line29aDate = _.filter(a, function(o) { 
									for(var d=0; d < o.Line_data_occ.length; d++){
										return o.Line_data_occ[d].LINE_NO == '29(a)(ii)'
									}
								})
								
								//line 29 a
								vm.line29a = _.filter(a, function(o) {
									for(var d=0; d < o.Line_data_occ.length; d++){
										if(o.Line_data_occ[d].LINE_NO == '29(a)'){
											//checked
											if(o.Line_data_occ[d].ATTRIB_VALUE == 'true'){
												o.Line_data_occ[d].conditionalField = false;
												var formatDate = $filter('date')(vm.line29aDate[0].Line_data_occ[0].ATTRIB_VALUE, "MM/dd/yyyy");
												o.Line_data_occ[d].line29DateVal = new Date(formatDate);
												
											}else{
												o.Line_data_occ[d]['conditionalField'] = true;
												o.Line_data_occ[d]['line29DateVal'] = null;
												break;
											}
										}
										
									}
								});
								
								vm.f6252RowData[i].Row_data[j].Line_data = angular.copy(a);
//							}
								
						}
						
						
					} else{
						//FORM_INPUT_DATA
						c = angular.copy(a[0]);
						c.Line_Type = "HEADER_ROW";
						c.Occurrence = "";
						c.Trans_details_key = "";
						c.IS_EDITABLE = "Y";
						c.Is_Multi = "Y";
						
						for(var j=0; j < c.Line_data.length; j++){
							c.Line_data[j].ATTRIB_VALUE = c.Line_data[j].ATTRIB_DESCRIPTION;
							vm.line_No = c.Line_data[j].LINE_NO;
						}
						
						for (var m=0; m < vm.f6252RowData[i].Row_data.length; m++){
							//EXCISITNG OCCURRENCE
							if(vm.f6252RowData[i].Row_data[m].FORM_INPUT_DATA == 'Y'){
							
							vm.f6252RowData[i].Row_data[m].Line_Type = "DATA_ROW";
							for(var n=0; n < vm.f6252RowData[i].Row_data[m].Line_data.length; n++){
								if(vm.f6252RowData[i].Row_data[m].Line_data[n].ATTRIB_SHORT_CODE == "PROPERTY_DESC_1"){
									vm.selected_prop = _.filter(vm.formLOV, function(o) { 
											return o.CODE_NAME == vm.f6252RowData[i].Row_data[m].Line_data[n].ATTRIB_VALUE; 
									});
									vm.f6252RowData[i].Row_data[m].Line_data[n].ATTRIB_VALUE = vm.selected_prop[0].CODE_DESC ;
								}
							}
							vm.f6252RowData[i].Row_data[m]
//							
							}
							
						}
						
						vm.f6252RowData[i].Row_data.unshift(c, {Line_Type:"SUB_ROW", Line_no:vm.line_No, Line_data: []});
					}
						
				//	console.log("Final value of the json is ", vm.f6252RowData[i].Row_data);
				}
				
				
				vm.f6252FormData  = vm.f6252RowData;
				//Harika
				vm.outerList = [];
				vm.innerList = [];
				vm.radioLines = [];
				
				for(var i=0;i<vm.f6252FormData.length;i++){
					if(vm.f6252FormData[i].IS_MULTI == 'N'){
						for(var j=0;j<vm.f6252FormData[i].Row_data.length;j++){
							for(var m=0;m<vm.f6252FormData[i].Row_data[j].Line_data.length;m++){
								if(vm.f6252FormData[i].Row_data[j].Line_data[m].ROW_TYPE != 'FH'){
								for(var n=0;n<vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ.length;n++){
									if(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].FORM_LINE_KEY != null){
										vm.innerList.push(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n]);
										//radio
										if(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].EDIT_TYPE == "radio"){
											vm.radioLines.push(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n]);
										}//end radio
										if(vm.outerList != null && vm.outerList.length > 0){
											var tempCheck1 = $filter("filter")(vm.outerList, {TRANS_OCCURRENCE:vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].TRANS_OCCURRENCE, FORM_LINE_KEY:vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].FORM_LINE_KEY, IS_FORM_DISPLAY:'Y'});
										}
				
										if(tempCheck1 == undefined || tempCheck1.length==0){
											if (vm.initialLoad){
												vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].TRANS_OCCURRENCE = 1;
											}
											vm.outerList.push(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n]);
										}
				
									  }
							
								}
							}
						 }	
						
							
						}
					}
			
				}

				// END
				
				vm.currentOutterList = _.filter(vm.outerList, function(o) { 
					return o.TRANS_OCCURRENCE == "1"; 
				})
								
				vm.f6252OriginalData = angular.copy(vm.f6252FormData);
				
//				vm.fetchModalCalcData();
				//getUnallocatedAmt();
			});
			
		}
		
		vm.fetchLOV = function(order) {
			var params = { "action_code": 'sjh60s', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": vm.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "form_no":'6252' };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				vm.formLOV = data.jsonObject;
				getF6252Data();
	
			});
		}
		
		vm.fetchModalCalcData = function() {
			var params = { "action_code": '1ubsuu', "tax_year": vm.curentyear, "scenario": vm.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
					"form_no":'6252',"schedule_name":'N/A',"part_no":'',"section_name":'N/A' };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				vm.formCalcData =  data.jsonObject;
			});
		}
		
		vm.fetchLOV();
		vm.fetchModalCalcData();
		
		vm.checkBoxHandler = function(lineKey, attribKey, attribCol, occurrence){
			vm.checkedVal = [];
		    angular.forEach(vm.f6252FormData, function (location) {  
		    	 angular.forEach(location.Line_data, function (obj) { 
			        if (obj.FORM_LINE_KEY === lineKey && obj.ATTRIB_COL === attribCol) {
			        	vm.checkedVal.push(obj);
			        }
	    	     });   
		    });
		    
		    vm.checkedVal = $filter("filter")(vm.radioLines, {FORM_LINE_KEY:lineKey, ATTRIB_COL:attribCol, TRANS_OCCURRENCE : occurrence, IS_FORM_DISPLAY : 'Y'});

			_.forEach(vm.checkedVal,function(el,index){
				if (el.LINE_ATTRIB_KEY == attribKey) {
					if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
						el.ATTRIB_VALUE = undefined;
						el.ATTRIB_VALUE = 'true';
					}else{
						el["ATTRIB_VALUE"] = 'true';
					} 
					el.IS_CHANGED_FLAG = 'Y';
				}else{
					if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
						el.ATTRIB_VALUE = undefined;
						el.ATTRIB_VALUE = 'false';
					}else{
						el.ATTRIB_VALUE = undefined;
						el["ATTRIB_VALUE"] = 'false';
					} 
					el.IS_CHANGED_FLAG = 'Y';
				}
			});

		}
		/*
		 vm.openCalendar = function() {
	            vm.calendar.opened = true;
	        };*/
	        
	        vm.openCalendar = function(row) {
//				vm.calendar.opened = true;
    			row['isOpen'] = true;
			};
			
		    vm.formatDate = function(row) {
            	row["taxyearBE"] = $filter('date')(row.ATTRIB_VALUE, 'MM/dd/yyyy');
            };
		

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };
		
		vm.reset = function(){
			getF6252Data();
			vm.showAttributes = false;
		}
		
		
		vm.save = function(occurrence, type){
			vm.finalData = [];
			vm.clob_settings = [];
			//console.log(vm.f6252FormData );
			//console.log("Original Data was ::::::::==========", vm.f6252OriginalData);
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			vm.f6252FinalData = angular.copy(vm.f6252FormData);
			if(angular.equals(vm.f6252FormData, vm.f6252OriginalData)){
				vm.cancel();
			}else{
				updateFormDataValues();
				var returnValue = validateFormValues(vm.currentOccurrence);
				if(returnValue == 1){
					return false;
				}
				
				
				for(var x=0; x < vm.f6252FinalData.length; x++){
					if(vm.f6252FinalData[x].IS_MULTI == 'Y'){
						vm.f6252FinalData[x].Row_data.splice(0, 2);
						
						vm.finalLines = _.filter(vm.f6252FinalData[x].Row_data, function(o) { 
							return o.TRANS_OCCURRENCE == occurrence; 
						})
						
						if(type == 'save'){
								for(var k = 0; k < vm.finalLines[0].Line_data.length; k++){
									/*if(vm.finalLines[0].Line_Type != 'DATA_ROW'){
										if(vm.finalLines[0].Line_data[k].EDIT_TYPE == 'custom_date_various'){
											var str = $.datepicker.formatDate('mm/dd/yy', vm.finalLines[0].Line_data[k].ATTRIB_VALUE);
											vm.finalLines[0].Line_data[k].ATTRIB_VALUE = str;
											vm.finalLines[0].Line_data[k].FORM_INPUT_DATA = 'Y';
										}
									}*/
									if(vm.finalLines[0].Line_data[k].EDIT_TYPE == "dropdown" && vm.finalLines[0].saveEdittedRecord == true ){ //&& vm.finalLines[0].Line_data[k].IS_CHANGED_FLAG == 'Y'){
											vm.current_prop_desc = _.filter(vm.formLOV, function(o) { 
												return o.CODE_DESC == vm.finalLines[0].Line_data[k].ATTRIB_VALUE; 
											});
										vm.finalLines[0].Line_data[k].ATTRIB_VALUE = vm.current_prop_desc[0].CODE_NAME ;
									}
									//always will send line 1 details as changed
									vm.finalLines[0].Line_data[k].IS_CHANGED_FLAG = "Y";
									vm.finalData.push(vm.finalLines[0].Line_data[k]);
								}
//							}
//						}
						} else{
							//If deleted row
						if(vm.finalLines[0].DELETE_ROWS.length != null && vm.finalLines[0].DELETE_ROWS.length != undefined){
							for(var j=0; j < vm.finalLines[0].DELETE_ROWS.length; j++){
								for(var k = 0; k < vm.finalLines[0].DELETE_ROWS[j].Line_data.length; k++){
									
									if(vm.finalLines[0].DELETE_ROWS[j].Line_data[k].EDIT_TYPE == "dropdown"){
										//populate line 1 dropdown val
										vm.current_prop_desc = _.filter(vm.formLOV, function(o) { 
											return o.CODE_DESC == vm.finalLines[0].DELETE_ROWS[j].Line_data[k].ATTRIB_VALUE; 
										});
										vm.finalLines[0].DELETE_ROWS[j].Line_data[k].ATTRIB_VALUE = vm.current_prop_desc[0].CODE_NAME ;
									}
									
									vm.finalLines[0].DELETE_ROWS[j].Line_data[k].IS_CHANGED_FLAG = "D";
									vm.finalData.push(vm.finalLines[0].DELETE_ROWS[j].Line_data[k]);
								}
							}
						}
								
					 }
					}else {
						
						var row_data = vm.f6252FinalData[x].Row_data;
						vm.currentOccSaveData = $filter("filter")(row_data, {TRANS_OCCURRENCE:occurrence});

						for(var y=0; y < vm.currentOccSaveData.length; y++){
							for(var z=0; z < vm.currentOccSaveData[y].Line_data.length; z++){
								if(vm.currentOccSaveData[y].Line_data[z].ROW_TYPE == 'TH' || vm.currentOccSaveData[y].Line_data[z].ROW_TYPE == 'DT'){
									for(var j=0; j < vm.currentOccSaveData[y].Line_data[z].Line_data_occ.length; j++){
										vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].FORM_INPUT_DATA = 'Y';
										//line 29a Date
										if(vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].EDIT_TYPE == 'custom_date_various'){
											if(vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].IS_CHANGED_FLAG == 'Y'){
												var str = $.datepicker.formatDate('mm/dd/yy', vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].ATTRIB_VALUE);
												vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].ATTRIB_VALUE = str;
											}
										
											vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].FORM_INPUT_DATA = 'Y';
//											vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].IS_CHANGED_FLAG = 'Y';
										}
										if(type == 'delete'){
											vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j].IS_CHANGED_FLAG = "D";
										}
										vm.finalData.push(vm.currentOccSaveData[y].Line_data[z].Line_data_occ[j]);
									}
								}
							}
							
						}
						
					}
					
				}

				
				//console.log("After changes, the final data was :::::::::::=========", vm.f6252FinalData);
				//console.log("After changes, the manipulated data was :::::::::::=========", vm.f6252FormData);
				//console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
				vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
		    	sendDetails(vm.finalData, vm.clob_settings);
			}
		}
		
		function sendDetails(final_data, clob_settings){
			var message = "Form 6252 Details are successfully updated."
			vm.crudLoading = true;
			F6252Factory.saveForm6252(final_data, clob_settings).then(function (data){
			//	console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
				//	console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
				//	console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		}
		
		function getDateFormat(date_value){
		//	console.log("The value of the date for the row that needs to convert is ::::::", date_value);
			var date_selected = null;
			if(date_value != "VARIOUS" && date_value != null){
				date_selected = ((date_value.getMonth() > 8) ? (date_value.getMonth() + 1) : ('0' + (date_value.getMonth() + 1))) + '/' + ((date_value.getDate() > 9) ? date_value.getDate() : ('0' + date_value.getDate())) + '/' + date_value.getFullYear();
			};
			
			return date_selected;
		}
		
		function updateFormDataValues(){
			for (var i=0; i < vm.f6252FinalData.length; i++){
				if(vm.f6252FinalData[i].IS_MULTI == 'Y'){
					for(var j=0; j < vm.f6252FinalData[i].Row_data.length; j++){
						if(vm.f6252FinalData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW"){
							for(var l=0; l < vm.f6252FinalData[i].Row_data[j].Line_data.length; l++){
								if(vm.f6252FinalData[i].Row_data[j].Line_data[l].IS_EDITABLE_CELL == "Y" && vm.f6252FinalData[i].Row_data[j].Line_data[l].DATA_TYPE == "dateVarious" && vm.f6252FinalData[i].Row_data[j].Line_data[l].EDIT_TYPE == "custom_date_various"){
									vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = getDateFormat(vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE);
								} else if(vm.f6252FinalData[i].Row_data[j].Line_data[l].IS_EDITABLE_CELL == "Y" && vm.f6252FinalData[i].Row_data[j].Line_data[l].DATA_TYPE == "string" && vm.f6252FinalData[i].Row_data[j].Line_data[l].EDIT_TYPE == "checkbox"){
									if(vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE == null ||vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE == undefined){
										vm.f6252FinalData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = "N";
									}
								}
							}
						}
					}
				}
			}
		}
		
		function validateFormValues(currentOccurrence){
			var returnValue = 0;
			var dateSold = null;
			var dateAcquired = null;
			vm.propertyExcists = false;
			for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'Y'){
					for(var j=0; j < vm.f6252FormData[i].Row_data.length; j++){
						var line_no = vm.f6252FormData[i].Row_data[j].Line_no;
						//check if propert is already assigned
						vm.currentProperty = _.filter(vm.f6252FormData[i].Row_data, function(o) { 
							return o.TRANS_OCCURRENCE == currentOccurrence;
						})
						if((vm.f6252FormData[i].Row_data[j].Line_Type == "DATA_ROW" || vm.f6252FormData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW") && vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE != currentOccurrence){
							var property_desc_index1 = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "PROPERTY_DESC_"+line_no);
							vm.selectedVal = _.filter(vm.formLOV, function(o) { 
								return o.CODE_DESC == vm.f6252RowData[i].Row_data[j].Line_data[property_desc_index1].ATTRIB_VALUE; 
							});
							
							if(vm.selectedVal.length > 0 && vm.selectedVal[0].CODE_NAME == vm.currentProperty[0].Line_data[1].ATTRIB_VALUE){
								vm.propertyExcists = true;
							}
						}
						if(vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE == currentOccurrence){
							if(vm.f6252FormData[i].Row_data[j].Line_Type == "DATA_ROW" || vm.f6252FormData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW"){
								var property_desc_index = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "PROPERTY_DESC_"+line_no);
								var user_desc_index = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "DESCRIPTION_"+line_no);
								var date_acquired_index = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "DATE_ACQ_"+line_no);
								var date_sold_index = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "DATE_SOLD_"+line_no);
								var long_short_index = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "LONG_SHORT_TERM_"+line_no);
								var related_party_index = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "RELATED_PARTY_"+line_no);
								var marketable_security_index = vm.f6252FormData[i].Row_data[j].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "MARKETABLE_SECURITY_"+line_no);
								
								if(vm.propertyExcists){
									vm.f6252FormData[i].Row_data[j].Line_data[property_desc_index].HAS_ERROR = "Y";
									vm.f6252FormData[i].Row_data[j].Line_data[property_desc_index].ATTRIB_VALUE_ERROR = "Property excists, please select a different property.";
									returnValue = 1; 

								}
								if(vm.f6252FormData[i].Row_data[j].Line_data[property_desc_index].ATTRIB_VALUE == null || vm.f6252FormData[i].Row_data[j].Line_data[property_desc_index].ATTRIB_VALUE == undefined ||vm.f6252FormData[i].Row_data[j].Line_data[property_desc_index].ATTRIB_VALUE.length <=0){
									vm.f6252FormData[i].Row_data[j].Line_data[property_desc_index].HAS_ERROR = "Y";
									vm.f6252FormData[i].Row_data[j].Line_data[property_desc_index].ATTRIB_VALUE_ERROR = "Enter Description of Property.";
									returnValue = 1; 
								}
								//user description
								if(vm.f6252FormData[i].Row_data[j].Line_data[user_desc_index].ATTRIB_VALUE == null || vm.f6252FormData[i].Row_data[j].Line_data[user_desc_index].ATTRIB_VALUE == undefined ||vm.f6252FormData[i].Row_data[j].Line_data[user_desc_index].ATTRIB_VALUE.length <=0){
									vm.f6252FormData[i].Row_data[j].Line_data[user_desc_index].HAS_ERROR = "Y";
									vm.f6252FormData[i].Row_data[j].Line_data[user_desc_index].ATTRIB_VALUE_ERROR = "Enter User Description.";
									returnValue = 1; 
								}
								//Date acquired can be null
								if(vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE != "VARIOUS" && vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE != null){
									if(typeof vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE === 'object' && vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE.getYear() > (parseInt(vm.globalParams.tax_year)-1900)){
										vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].HAS_ERROR = "Y";
										vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired year has to be current tax year or prior year.";
										returnValue = 1;
									} else if(typeof vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE === 'string' && vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE.match(/\d{4}$/)[0] > parseInt(vm.globalParams.tax_year)){
										vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].HAS_ERROR = "Y";
										vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired year has to be current tax year or prior year.";
										returnValue = 1;
									}
								}
								if(vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE != "VARIOUS"){
									if(vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE == null){
										vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].HAS_ERROR = "Y";
										vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE_ERROR = "Select Date Sold.";
										returnValue = 1;
									} else if(vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE != null){
										 if(typeof vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE === 'object' && vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE.getYear() > (parseInt(vm.globalParams.tax_year)-1900)){
												vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].HAS_ERROR = "Y";
												vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE_ERROR = "The Date Sold year has to be same as tax year.";
												returnValue = 1;
										}else if(typeof vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE === 'string' && vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE.match(/\d{4}$/)[0] > parseInt(vm.globalParams.tax_year)){
											vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].HAS_ERROR = "Y";
											vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE_ERROR = "The Date Sold year has to be same as tax year.";
											returnValue = 1;
										}
									}
								}
								if(vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE != "VARIOUS" && vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE != "VARIOUS" &&  vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE != null){
									var date_qcuired =  Date.parse(vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE);
									var date_sold = Date.parse(vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE);
									if(date_qcuired > date_sold){
										vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].HAS_ERROR = "Y";
										vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired should be prior to Date Sold";
										returnValue = 1;
									}
									dateAcquired =  vm.f6252FormData[i].Row_data[j].Line_data[date_acquired_index].ATTRIB_VALUE;
									dateSold = vm.f6252FormData[i].Row_data[j].Line_data[date_sold_index].ATTRIB_VALUE;
									var diff_in_dates = getDiffDates(dateAcquired, dateSold);
									
								}
								if(vm.f6252FormData[i].Row_data[j].Line_data[long_short_index].ATTRIB_VALUE == null){
									vm.f6252FormData[i].Row_data[j].Line_data[long_short_index].HAS_ERROR = "Y";
									vm.f6252FormData[i].Row_data[j].Line_data[long_short_index].ATTRIB_VALUE_ERROR = "Please select Long/Short Term.";
									returnValue = 1; 
								}
								if(vm.f6252FormData[i].Row_data[j].Line_data[related_party_index].ATTRIB_VALUE == null){
									vm.f6252FormData[i].Row_data[j].Line_data[related_party_index].HAS_ERROR = "Y";
									vm.f6252FormData[i].Row_data[j].Line_data[related_party_index].ATTRIB_VALUE_ERROR = "Please select Property sold to a related party after May 14, 1980?";
									returnValue = 1;
								}
								if(vm.f6252FormData[i].Row_data[j].Line_data[marketable_security_index].ATTRIB_VALUE == null){
									vm.f6252FormData[i].Row_data[j].Line_data[marketable_security_index].HAS_ERROR = "Y";
									vm.f6252FormData[i].Row_data[j].Line_data[marketable_security_index].ATTRIB_VALUE_ERROR = "Please select Property you sold to a related party a marketable security?";
									returnValue = 1;
								}
							}
					  }
					}
				}
			}
			return returnValue;
		}
		
		function getDiffDates(dateAcquired, dateSold){
			if(typeof dateAcquired === 'string'){
				dateAcquired = convertToDate(dateAcquired);
				dateSold = convertToDate(dateSold);
			}
			
			return ((dateSold -  dateAcquired)/ (1000 * 3600 * 24));
		}
		
		function convertToDate(str)
		{
		   var dateArr = str.split("/");
		   return new Date(dateArr[2], dateArr[0], dateArr[1])
		}
		
		
		
		vm.AddNewRow = function(occurrence, line_no, $index){
			//console.log("The new line is created under :::::::", line_no);
			var occurrance = 1;
			var trans_details_key = null;
			for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'Y'){
					
					//Can't create more than 4 properties
					vm.propertyLines = _.filter(vm.f6252FormData[i].Row_data, function(o) { 
						return o.Line_Type == "INITIAL_ROW";
					})
					
					if(vm.propertyLines.length == 0 ){
						AlertService.add("error", "All properties are utilized. Can't add a new property.");
						return;
					}
					
					//To save excisting Property before we create new one
					vm.totalLines = _.filter(vm.f6252FormData[i].Row_data, function(o) { 
						//return o.Line_Type == "NEW_EDIT_ROW"; 
						return o.IS_EDITTED == "Y" || o.Line_Type == "NEW_EDIT_ROW";
					})
					if(vm.totalLines.length > 0 ){
						AlertService.add("error", "Please save the details of property " + vm.totalLines[0].Line_data[1].ATTRIB_VALUE + " before you create a new property");
						return;
					}
					
					if(null != vm.f6252FormData[i].Row_data[vm.f6252FormData[i].Row_data.length -1].Occurrence && vm.f6252FormData[i].Row_data[vm.f6252FormData[i].Row_data.length -1].Occurrence != "" && vm.f6252FormData[i].Row_data[vm.f6252FormData[i].Row_data.length -1].Occurrence != undefined){
						occurrance = vm.f6252FormData[i].Row_data[vm.f6252FormData[i].Row_data.length -1].Occurrence + 1;
						trans_details_key = vm.f6252FormData[i].Row_data[vm.f6252FormData[i].Row_data.length -1].Trans_details_key;
					}
					
					for(var j=0; j < vm.f6252FormData[i].Row_data.length; j++){
						if(vm.f6252FormData[i].Row_data[j].Line_Type == 'INITIAL_ROW'){
							var new_row = vm.f6252FormData[i].Row_data[j];
							new_row.Line_Type = "NEW_EDIT_ROW";
							new_row['saveEdittedRecord'] = false;
							//new_row.IS_EDITTED = "Y";
							break;
						}
					}
					
					if(new_row.Line_no == line_no){
						for (var j=0; j < new_row.Line_data.length; j++){
							if(new_row.Line_data[j].EDIT_TYPE == "custom_date_various" && new_row.Line_data[j].DATA_TYPE == "dateVarious"){
								new_row.Line_data[j].ATTRIB_VALUE = null;//new Date();
								//new_row.TRANS_OCCURRENCE = occurrence;
							} else if (new_row.Line_data[j].EDIT_TYPE == "currency" && new_row.Line_data[j].DATA_TYPE == "number"){
								new_row.Line_data[j].ATTRIB_VALUE = 0;
								//new_row.TRANS_OCCURRENCE = occurrence;
							} else if (new_row.Line_data[j].EDIT_TYPE == "label" && new_row.Line_data[j].DATA_TYPE == "string"){
							//	new_row.TRANS_OCCURRENCE = occurrence;
							} else {	
								new_row.Line_data[j].ATTRIB_VALUE = null;
							//	new_row.TRANS_OCCURRENCE = occurrance;
							}
							new_row.Line_data[j].TRANS_DETAILS_KEY = trans_details_key;
							new_row.Line_data[j].IS_CHANGED_FLAG = "Y";
						}
						vm.currentOccurrence = new_row.TRANS_OCCURRENCE; 
					}
					
				}
			}
			vm.showAttributes = true;
		//	console.log(vm.f6252FormData);
		}
		
		vm.editExistingRecord = function(line_no, occurrence, index, type){
			//console.log("These are the line_no and occurrence for the editting rows", line_no, occurrence, index);
			for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'Y'){
//					if(type == 'edit'){
					for(var j=0; j < vm.f6252FormData[i].Row_data.length; j++){
						if(j == index){
							
							if(vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE == occurrence){
								vm.editLines = [];
								if(vm.f6252FormData[i].Row_data[j].IS_CURRENT_CHANGED_ROW == "Y"){
									//DO NOTHING
								}else{
									vm.editLines = _.filter(vm.f6252FormData[i].Row_data, function(o) { 
										//return o.Line_Type == "NEW_EDIT_ROW"; 
										return (o.IS_EDITTED == "Y" && o.IS_CURRENT_CHANGED_ROW == "Y" ) || o.Line_Type == "NEW_EDIT_ROW";
									})
								}
							}
							
							if(vm.editLines.length > 0 ){
								AlertService.add("error", "Please save the details of the changed property before you Edit a property");
								return;
							}
							
							//vm.saveEdittedRecord = false;
							
							if(vm.f6252FormData[i].Row_data[j].Line_Type == "DATA_ROW" && vm.f6252FormData[i].Row_data[j].Line_no == line_no && vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE == occurrence){
								vm.f6252FormData[i].Row_data[j].Line_Type = "NEW_EDIT_ROW";
								
								for(var l=0; l < vm.f6252FormData[i].Row_data[j].Line_data.length; l++){

									if(vm.f6252FormData[i].Row_data[j].Line_data[l].EDIT_TYPE == "currency" && vm.f6252FormData[i].Row_data[j].Line_data[l].DATA_TYPE == "number"){
										vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = parseInt(vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE);
									} else if (vm.f6252FormData[i].Row_data[j].Line_data[l].EDIT_TYPE == "custom_date_various" && vm.f6252FormData[i].Row_data[j].Line_data[l].DATA_TYPE == "dateVarious"){
										if (vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE != "VARIOUS" && vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE != null){
											vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = new Date(vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE);
										}
									}else if(vm.f6252FormData[i].Row_data[j].Line_data[l].EDIT_TYPE == "dropdown"){
										vm.current_prop = _.filter(vm.formLOV, function(o) { 
											return o.CODE_DESC == vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE; 
										});
										vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = vm.current_prop[0].CODE_NAME ;
									}
									//vm.f6252FormData[i].Line_data[j].Line_data_occ[l].IS_CHANGED_FLAG = "Y";
								}
							} 
						}
					}
					
//				}
					
				}
			}
			vm.currentOccurrence = occurrence;
			vm.showAttributes = true;
			//console.log(vm.f6252FormData);
		}
		
		vm.removeExistingRecord = function(line_no, Occurrence, index){
		//	console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);
			for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'Y'){

						vm.editLines = _.filter(vm.f6252FormData[i].Row_data, function(o) { 
							return o.IS_EDITTED == "Y" && o.TRANS_OCCURRENCE != Occurrence;
						})
						if(vm.editLines.length > 0 ){
							if(vm.editLines[0].Line_Type == "DATA_ROW"){
								AlertService.add("error", "Please save the details of the changed property " + vm.editLines[0].Line_data[1].ATTRIB_VALUE + " before you delete a property");
							}else if(vm.editLines[0].Line_Type == "NEW_EDIT_ROW"){
								vm.editedProp = _.filter(vm.formLOV, function(o) { 
									return o.CODE_NAME == vm.editLines[0].Line_data[1].ATTRIB_VALUE; 
								});
								//vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = vm.current_prop[0].CODE_NAME ;
								AlertService.add("error", "Please save the details of the changed property " + vm.editedProp[0].CODE_DESC + " before you delete a property")
							}
							return;
						}

					for(var j=0; j < vm.f6252FormData[i].Row_data.length; j++){			
						
						if(j == index){
							if((vm.f6252FormData[i].Row_data[j].Line_Type == "DATA_ROW" || vm.f6252FormData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW") && vm.f6252FormData[i].Row_data[j].Line_no == line_no && null != vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE && vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE != "" && vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE == Occurrence){
								//never saved in to DB
								if(vm.f6252FormData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW" && vm.f6252FormData[i].Row_data[j].FORM_INPUT_DATA == 'N'){
									vm.f6252FormData[i].Row_data[j].Line_Type = 'INITIAL_ROW';
									vm.f6252FormData[i].Row_data[j].IS_EDITTED = 'N';
									vm.f6252FormData[i].Row_data[j].DELETE_ROWS = [];
									vm.showAttributes = false;
									vm.deleteSave = false;
									for(var l=0; l < vm.f6252FormData[i].Row_data[j].Line_data.length; l++){
										vm.f6252FormData[i].Row_data[j].Line_data[l].HAS_ERROR = 'N';
									}
									//Assign 0 and null values like new row
									vm.deleteValues = _.filter(vm.outerList, function(o) { 
										return  o.TRANS_OCCURRENCE == Occurrence ; 
									})

									for(var m=0; m < vm.deleteValues.length; m++){
										if(vm.deleteValues[m].EDIT_TYPE == "custom_date_various" && vm.deleteValues[m].DATA_TYPE == "dateVarious"){
											vm.deleteValues[m].ATTRIB_VALUE = null;//new Date();
											//new_row.TRANS_OCCURRENCE = occurrence;
										} else if (vm.deleteValues[m].EDIT_TYPE == "currency" && vm.deleteValues[m].DATA_TYPE == "number"){
											vm.deleteValues[m].ATTRIB_VALUE = 0;
											//new_row.TRANS_OCCURRENCE = occurrence;
										} else if (vm.deleteValues[m].EDIT_TYPE == "label" && vm.deleteValues[m].DATA_TYPE == "string"){
										//	new_row.TRANS_OCCURRENCE = occurrence;
										} else {	
											vm.deleteValues[m].ATTRIB_VALUE = null;
										//	new_row.TRANS_OCCURRENCE = occurrance;
										}
									}
									
								}else{
									vm.f6252FormData[i].Row_data[j].DELETE_ROWS.push(vm.f6252FormData[i].Row_data[j]);
									vm.deleteSave = true;
								}
//								vm.f6252FormData[i].Row_data[j]['IS_DELETE'] = 'Y';
//								vm.f6252FormData[i].Row_data.splice(j,2);
							}else if((vm.f6252FormData[i].Row_data[j].Line_Type == "DATA_ROW" || vm.f6252FormData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW") && vm.f6252FormData[i].Row_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
//								vm.f6252FormData[i].Row_data.splice(j,2);
							}
						}
					}
				}
			}
			//vm.calcFinalAmounts();
			vm.currentOccurrence = Occurrence;
			if(vm.deleteSave == true){
				vm.save(vm.currentOccurrence,'delete');
			}
		}
		
		vm.saveNewEdittedRecord = function(line_no, occurrence, index){
			for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'Y'){
					for(var j=0; j < vm.f6252FormData[i].Row_data.length; j++){
						if(j== index){
							
							//To fill the required fields
							var returnValue = validateFormValues(occurrence);
							if(returnValue == 1){
								return false;
							}
							
							if(vm.f6252FormData[i].Row_data[j].Line_Type == "NEW_EDIT_ROW" && vm.f6252FormData[i].Row_data[j].Line_no == line_no && vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE == occurrence){
								vm.f6252FormData[i].Row_data[j].Line_Type = "DATA_ROW";
								vm.f6252FormData[i].Row_data[j].saveEdittedRecord = true;
								if(vm.f6252FormData[i].Row_data[j].IS_EDITTED == "Y"){
									//This flag is to know which row is currently changes and we can edit that row multiple times
									vm.f6252FormData[i].Row_data[j].IS_CURRENT_CHANGED_ROW = "Y";
								}
								
							}
//						}
//						if(j== index){
							for(var l=0; l < vm.f6252FormData[i].Row_data[j].Line_data.length; l++){
								if(vm.f6252FormData[i].Row_data[j].Line_data[l].IS_EDITABLE_CELL == "Y" && vm.f6252FormData[i].Row_data[j].Line_data[l].DATA_TYPE == "dateVarious" && vm.f6252FormData[i].Row_data[j].Line_data[l].EDIT_TYPE == "custom_date_various"){
									vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = getDateFormat(vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE);
								} else if(vm.f6252FormData[i].Row_data[j].Line_data[l].IS_EDITABLE_CELL == "Y" && vm.f6252FormData[i].Row_data[j].Line_data[l].DATA_TYPE == "string" && vm.f6252FormData[i].Row_data[j].Line_data[l].EDIT_TYPE == "checkbox"){
									if(vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE == null ||vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE == undefined){
										vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = "N";
									}
								}else if (vm.f6252FormData[i].Row_data[j].Line_data[l].IS_EDITABLE_CELL == "Y" && vm.f6252FormData[i].Row_data[j].Line_data[l].EDIT_TYPE == "dropdown" ){
									vm.current_prop = _.filter(vm.formLOV, function(o) { 
										return o.CODE_NAME == vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE; 
									});
									vm.f6252FormData[i].Row_data[j].Line_data[l].ATTRIB_VALUE = vm.current_prop[0].CODE_DESC ;
									//vm.saveEdittedRecord = true;
									
								}
							}
						}
					}
				}
			}
//			vm.showAttributes = false;
		}
		
		
		vm.fetchModalCalcData = function(order) {

			var params = { "action_code": '1ubsuu', "tax_year": vm.curentyear, "scenario": vm.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
					"form_no":'6252',"schedule_name":'N/A',"part_no":'',"section_name":'N/A' };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				vm.formCalcData =  data.jsonObject;
			});
		}

		
		vm.valuechanged = function(parent_index, occurrence, line_no, index){
			for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'Y'){
					
					vm.totalLines = _.filter(vm.f6252FormData[i].Row_data, function(o) { 
						return o.Line_Type == "NEW_EDIT_ROW"; 
					})
					if(vm.f6252FormData[i].Row_data[parent_index] != null && vm.f6252FormData[i].Row_data[parent_index] != undefined){
						if(vm.f6252FormData[i].Row_data[parent_index].Line_no == line_no && vm.f6252FormData[i].Row_data[parent_index].TRANS_OCCURRENCE == occurrence){
								//To Default select Sch D / F6252 on Date sold
								if(vm.f6252FormData[i].Row_data[parent_index].Line_data[index+1].ATTRIB_DESCRIPTION == "Date sold (mm/dd/yyyy)"){
										var str = $.datepicker.formatDate('mm/dd/yy', vm.f6252FormData[i].Row_data[parent_index].Line_data[index+1].ATTRIB_VALUE);
										var today = new Date();
										today.setHours(0,0,0,0);
										/*vm.totalLines = _.filter(vm.f6252FormData[i].Row_data, function(o) { 
											return o.Line_Type == "NEW_EDIT_ROW"; 
										})*/
										
										vm.scheduleLine = _.filter(vm.totalLines[0].Line_data, function(o) { 
											return o.ATTRIB_DESCRIPTION =='Schedule'; 
										})
										if(vm.f6252FormData[i].Row_data[parent_index].Line_data[index+1].ATTRIB_VALUE > today){
											vm.scheduleLine[0].ATTRIB_VALUE = 'D';
										}else{
											vm.scheduleLine[0].ATTRIB_VALUE = '4797';
										}
//										vm.f6252FormData[i].Line_data[parent_index].Line_data_occ[index+1].ATTRIB_VALUE = str;
									
								}
								vm.f6252FormData[i].Row_data[parent_index].Line_data[index+1].IS_CHANGED_FLAG = "Y";
								vm.f6252FormData[i].Row_data[parent_index].Line_data[index+1].HAS_ERROR = "N";
							}
							
					}
					//to know if ant obj under that trans occ list is changed
					vm.totalLines[0].IS_EDITTED = "Y";
				}else{
					
				 if(vm.f6252FormData[i].IS_MULTI == 'N'){

/*					var row_data = vm.f6252FormData[i].Row_data;
					vm.currentOccData = $filter("filter")(row_data, {TRANS_OCCURRENCE:occurrence});

					//to know if ant obj under that trans occ list is changed
					vm.currentOccData[0].IS_EDITTED = "Y";
					*/	
					vm.outerListLine = _.filter(vm.outerList, function(o) { 
						return o.LINE_NO == line_no && o.TRANS_OCCURRENCE == occurrence ; 
					})
					
					
					if(vm.outerListLine.length !=0){
						if( vm.outerListLine[0].LINE_NO == line_no && vm.outerListLine[0] != undefined && vm.outerListLine[0] != null ){
							vm.outerListLine[0].IS_CHANGED_FLAG = "Y";
							
							if(vm.outerListLine[0].LINE_NO == '29(a)'){
								vm.line29aDate = _.filter(vm.outerList, function(o) { 
									return o.LINE_NO == '29(a)(ii)' && o.TRANS_OCCURRENCE == occurrence ; 
								})
								 if(vm.outerListLine[0].ATTRIB_VALUE == 'true'){
									 
									vm.outerListLine[0].conditionalField = false;
									 if(vm.outerListLine[0].line29DateVal != '0' || vm.outerListLine[0].line29DateVal != undefined){
										 vm.line29aDate[0].ATTRIB_VALUE = vm.outerListLine[0].line29DateVal ;
										 vm.line29aDate[0].IS_CHANGED_FLAG = 'Y';
									 }
								}else{
									vm.line29aDate[0].ATTRIB_VALUE = null;
									vm.outerListLine[0].line29DateVal = null;
									vm.outerListLine[0].conditionalField = true;
								}
							}
						}
					}
					
				 }
				 
				}
				
				//to know if ant obj under that trans occ list is changed
				//vm.f6252FormData[i].Row_data[parent_index].IS_EDITTED = "Y";
				
			}
		}
		
		
		vm.updateAmount = function(index,occurence, line_no){
		/*	for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'Y' && vm.f6252FormData[i].Row_data[0].Line_no == line_no){
					for(var j=0; j < vm.f6252FormData[i].Row_data[index].Line_data.length; j++ ){
						if(vm.f6252FormData[i].Row_data[index].Line_data[j].ATTRIB_CALC_FORMULA != null){
							var line_data_values = vm.f6252FormData[i].Row_data[index].Line_data;
							vm.f6252FormData[i].Row_data[index].Line_data[j].ATTRIB_VALUE = vm.calcLineFormula(vm.f6252FormData[i].Row_data[index].Line_data[j].ATTRIB_CALC_FORMULA, line_data_values);
							vm.f6252FormData[i].Row_data[index].Line_data[j].IS_CHANGED_FLAG = "Y";
							vm.f6252FormData[i].Row_data[index].Line_data[j].HAS_ERROR = "N";
						}
					}
				}
			} */
			vm.calcFinalAmounts(occurence);
		}
		
		//check if using or not harika
	/*	vm.calcLineFormula = function(formula, line_data_values){
			console.log("Given formula and index of the row are ", formula, line_data_values);
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					var currentTabAtrribVal = $filter("filter")(line_data_values, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var lineAmt = parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			} 
			
			console.log("Given formula and index of the row are ", formula, line_data_values);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		} */
		
		//console.log(vm.calcFormula((SALE_PRICE_1)-(COST_BASIS_1), 2));
		vm.calcFinalAmounts = function(occurence){
			for (var i=0; i < vm.f6252FormData.length; i++){
				if(vm.f6252FormData[i].IS_MULTI == 'N'){
					for(var j=0; j < vm.f6252FormData[i].Row_data.length; j++){
						if( vm.f6252FormData[i].Row_data[j].TRANS_OCCURRENCE == occurence){
							for(var m=0; m < vm.f6252FormData[i].Row_data[j].Line_data.length; m++){
								for(var n=0; n < vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ.length; n++){
									if(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].ATTRIB_CALC_FORMULA != null){
										vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].ATTRIB_VALUE = vm.calc_final_amt(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].ATTRIB_CALC_FORMULA,occurence);
										if(vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].IS_FORM_DISPLAY == 'Y'){
											vm.f6252FormData[i].Row_data[j].Line_data[m].Line_data_occ[n].IS_CHANGED_FLAG = "Y";
										}
									}
							}
						  }
							
						}
					}
				}
			}
			
		}
		
		vm.calc_final_amt = function(formula,occurence){	
//			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			
			var matches = formula.match(/\b[^\d\s]+\b/g);
			if(matches ==  null){
				var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			}else{
				var formulaLength = formula.length;
				var subFormula = "";
				subFormula = formula.substring(formula.indexOf("("),formulaLength-1);
				var lineFormulaArray = subFormula.split(/[+-,/\\*\\]/);
			}
			
			for(var i=0; i<lineFormulaArray.length; i++){
				var lineAmt = 0;
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					for(var k=0; k < vm.f6252FormData.length; k++){
						if(vm.f6252FormData[k].IS_MULTI == 'Y'){
							for(var j=0; j < vm.f6252FormData[k].Row_data.length; j++){
								if(vm.f6252FormData[k].Row_data[j].Line_Type == "DATA_ROW" || vm.f6252FormData[k].Row_data[j].Line_Type == "NEW_EDIT_ROW"){
									var row_data = vm.f6252FormData[k].Row_data[j].Line_data;
									var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
									if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
										lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
									}
								}
							}
							} else {
								var row_data = vm.f6252FormData[k].Row_data;
								vm.currentOccData = $filter("filter")(row_data, {TRANS_OCCURRENCE:occurence});
//							vm.currentOccLineData = $filter("filter")(vm.currentOccData, {TRANS_OCCURRENCE:occurence});
							
							for(var a=0; a < vm.currentOccData[0].Line_data.length; a++){
								for(var b=0; b < vm.currentOccData[0].Line_data[a].Line_data_occ.length; b++){
									
								//	var currentTabAtrribVal = $filter("filter")(vm.currentOccData[0].Line_data[a].Line_data_occ[b], {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
									
									if(vm.currentOccData[0].Line_data[a].Line_data_occ[b].ATTRIB_SHORT_CODE == lineFormulaArray[i].trim()){
										lineAmt =  (vm.currentOccData[0].Line_data[a].Line_data_occ[b].ATTRIB_VALUE);
										
										
										if(parseFloat(lineAmt)>= 0){
											formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
										}else{
											formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
										}
									}
									
//									if(null != vm.currentTabAtrribVal && vm.currentTabAtrribVal.length > 0){
//										lineAmt = lineAmt + parseInt(vm.currentTabAtrribVal[0].ATTRIB_VALUE);
//									}
									
									
								}
							}
						
						}
					}
					//console.log("Formula before Replacing with amount========", formula);
				/*	if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}*/
				}
			}
			
			//console.log("Given formula and index of the row are ", formula);
			if(formula.substring(0, 7) == 'toFixed'){
				var toFixedLength = 'toFixed'; 
				vm.charLength = toFixedLength.length;
				var formulaLength = formula.length;
				var str = formula.slice(vm.charLength, formulaLength);
				//formula = str.substring(0, str.indexOf(","));
				formula = str.split(/[,\\*\\]/);
				formula[0] = formula[0].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
				formula[1] = formula[1].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");

				var calculatedVal = eval(formula[0]);
				return calculatedVal.toFixed(formula[1]);

			}else{
				if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
					//console.log(eval(formula));
					var calculatedVal = eval(formula);
					return calculatedVal.toFixed();
				}else return 0;
				
			}
			// if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
			// 	console.log(eval(formula));
			// 	var calculatedVal = eval(formula);
			// 	return calculatedVal.toFixed();
			// }else return 0; 
		}
		
		
	}
});