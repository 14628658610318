/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.qreentityController', [])
					.controller(
							'QreEntityController',
							[ '$rootScope', '$scope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'JsonObjectFactory', 'QeFactoryA',
									'$timeout', 'TbbsService',
									'GENERAL_CONFIG', qreentityController ])

			function qreentityController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					QeFactoryA, $timeout, tbbsService, GENERAL_CONFIG) {

				var vm = this || thisObj;
				$scope.rowData = rowData || $scope.$parent.rowData;
				$scope.accounts = [];
				$scope.selectAccountData = [];
				$scope.sourceAcctType = null;
				$scope.reclassTotal = "";

				$scope.RECLASS_DESCRIPTION = "";
				$scope.TRANS_HEADER_KEY = "";
				$scope.reclassArray = [];
				$scope.showReclassForm = false;
				$scope.crudLoading = false;
				$scope.existingLoading = false;
				$scope.systemaLoading = false;
				$scope.templateLoading = false;
				$scope.noRecords = false;
				$scope.fromScreen = false;
				// $scope.colData = colData;

				vm.templateDataMain = [];
				vm.systemAcctMain = [];

				if ($uibModalInstance) {
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
							: null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				// 31jsr3
				// rjubnm

				function getSystmAcctDropDown() {
					$scope.systemaLoading = true;
					var params = {
						"action_code" : '31jsr3',
						"tax_year" : GlobalService.globalParams.tax_year,
						"chart_type" : $scope.rowData.CHART_TYPE,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {
											vm.sys_acc_dropdown_data = [];
											vm.systemAcctMain = data.jsonObject;
											for ( var a in data.jsonObject) {
												vm.sys_acc_dropdown_data
														.push(data.jsonObject[a].SYSTEM_ACCT_DESC);
											}

											vm.sys_acc_dropdown_data = _.uniq(
													vm.sys_acc_dropdown_data,
													JSON.stringify);
											$scope.systemaLoading = false;
										}
									})
				}

				function gettemplateData() {
					$scope.templateLoading = true;
					var params = {
						"action_code" : 'whxxzc',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.templatedata = [];
											vm.templateDataMain = data.jsonObject;
											for ( var a in data.jsonObject) {

												vm.templatedata
														.push(data.jsonObject[a].LABEL);
											}

											vm.templatedata = _.uniq(
													vm.templatedata,
													JSON.stringify);
											$scope.templateLoading = false;
										}
									})
				}

				function init() {
					$scope.accounts = [];
					$scope.accounts1 = [];

					$scope.accounts.push({
						base_entity1 : '',
						base_entity2 : '',
						base_entity3 : '',
						sys_acc_dropdown_data : [],
						templatedata : [],
						templatedata1 : []

					});

					$scope.crudLoading = false;

					$scope.existingLoading = false;
					$scope.systemaLoading = false;
					$scope.templateLoading = false;
					$scope.noRecords = false;

					vm.templateError = false;
					vm.systemacctError = false;

					vm.type = false;
					vm.adj_desc = '';
					getSystmAcctDropDown();
					gettemplateData();
					loadExistingReclass();

				}

				init();

				$scope.reset = function() {
					init();

				};
				/*
				 * vm.templateChange = function(index) { if (index ===
				 * undefined) { for ( var a in $scope.accounts) {
				 * $scope.accounts[a].templateError = ""; if
				 * ($scope.accounts[a].template === "" ||
				 * $scope.accounts[a].template === undefined) {
				 * $scope.accounts[a].templateError = true; vm.templateError =
				 * true; } else { vm.templateError = false; } } }
				 * 
				 * else { $scope.accounts[index].templateError = ""; if
				 * ($scope.accounts[index].template === "" ||
				 * $scope.accounts[index].template === undefined) {
				 * $scope.accounts[index].templateError = true; vm.templateError =
				 * true; } else { vm.templateError = false; } } }
				 */

				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoading = true;
					var dummyJson = {};
					var sendobj = [];
					
					/*if (vm.templateError || vm.desError || vm.systemacctError
							|| vm.amtError) {
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						$scope.crudLoading = false;
						return;
					}*/

					dummyJson = {};

					dummyJson["cy_le_key"] = $scope.rowData.CY_LE_KEY;

					for ( var a in $scope.reclassArray) {
						if ($scope.reclassArray[a].BASE1_ENTITY_DISPLAY == vm.base_entity1) {
							dummyJson["new_base_year_entity1"] = $scope.reclassArray[a].BASE1_LE_KEY;
						}
					}

					for ( var b in $scope.reclassArray) {
						if ($scope.reclassArray[b].BASE2_ENTITY_DISPLAY == vm.base_entity2) {
							dummyJson["new_base_year_entity2"] = $scope.reclassArray[b].BASE2_LE_KEY;
						}
					}
					for ( var c in $scope.reclassArray) {
						if ($scope.reclassArray[c].BASE1_ENTITY_DISPLAY == vm.base_entity3) {
							dummyJson["new_base_year_entity3"] = $scope.reclassArray[c].BASE3_LE_KEY;
						}
					}

				/*	dummyJson["new_base_year_entity1"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
							: $scope.accounts[s].systemacctid;

					dummyJson["old_base_year_entity1"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
							: $scope.accounts[s].systemacctid;
					dummyJson["new_base_year_entity2"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
							: $scope.accounts[s].systemacctid;

					dummyJson["old_base_year_entity2"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
							: $scope.accounts[s].systemacctid;
					dummyJson["new_base_year_entity3"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
							: $scope.accounts[s].systemacctid;

					dummyJson["old_base_year_entity3"] = ($scope.accounts[s].type === 'I') ? dummyJson["system_acct_new"]
							: $scope.accounts[s].systemacctid;*/
					sendobj.push(dummyJson);

					// usumyc

					var message = "successfully Added";
					reclassCRUDHttp(sendobj, sendobj, message);

				};

				function reclassCRUDHttp(returnObj, accountArray, message) {

					QeFactoryA
							.saveTBBSReclass(returnObj, accountArray)
							.then(
									function(result) {

										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoading = false;
										} else {
											AlertService.add("success",
													message, 4000);
											// BROADCAST CHANGE
											$uibModalInstance.dismiss('cancel');
											var args = {

												combi_key : $scope.rowData.COMBINATION_KEY,
												gridFilter : {

													combi_key : $scope.rowData.COMBINATION_KEY
												}
											};
											$rootScope
													.$emit('gridUpdate', args);

										}
									});

				}

				function loadExistingReclass() {
					$scope.existingLoading = true;
					vm.transHeaderkeys = [];
					var tempobj = {};
					vm.tempArray = [];
					$scope.tbbsArray = [];
					var params = {
						"action_code" : 'u1yqe7',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"cc_key" : $scope.rowData.COMBINATION_KEY,

					};

					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										$scope.reclassArray = data.jsonObject;
										vm.base_entity_1_all_data = [];
										vm.base_entity_2_all_data = [];
										vm.base_entity_3_all_data = [];
										vm.base_entity_1_display_data = [];
										vm.base_entity_2_display_data = [];
										vm.base_entity_3_display_data = [];
										/*
										 * for ( var e in data.jsonObject) { if
										 * (data.jsonObject[e].TYPE == 'EXIST') {
										 * $scope.accounts[0].base_entity1 =
										 * data.jsonObject[e].BASE1_ENTITY_DISPLAY;
										 * $scope.accounts[0].base_entity2 =
										 * data.jsonObject[e].BASE2_ENTITY_DISPLAY;
										 * $scope.accounts[0].base_entity3 =
										 * data.jsonObject[e].BASE3_ENTITY_DISPLAY; } }
										 */
										for ( var g in data.jsonObject) {

											vm.base_entity_1_display_data
													.push(data.jsonObject[g].BASE1_ENTITY_DISPLAY);
											vm.base_entity_2_display_data
													.push(data.jsonObject[g].BASE2_ENTITY_DISPLAY);
											vm.base_entity_3_display_data
													.push(data.jsonObject[g].BASE3_ENTITY_DISPLAY);
										}

										vm.base_entity_1_display_data = _.uniq(
												vm.base_entity_1_display_data,
												JSON.stringify);
										vm.base_entity_2_display_data = _.uniq(
												vm.base_entity_2_display_data,
												JSON.stringify);
										vm.base_entity_3_display_data = _.uniq(
												vm.base_entity_3_display_data,
												JSON.stringify);
										/*
										 * $scope.accounts[0].base1Entity =
										 * vm.base_entity_1_display_data;
										 * 
										 * $scope.accounts[0].base1EntityDropdown =
										 * vm.base_entity_1_display_data;
										 * $scope.accounts[0].base2EntityDropdown =
										 * vm.base_entity_2_display_data;
										 * $scope.accounts[0].base3EntityDropdown =
										 * vm.base_entity_3_display_data;
										 */

										$scope.existingLoading = false;
										if ($scope.reclassArray.length === 0) {
											$scope.noRecords = true;
										}
									});
				}

			}// /////////////END CTRL

			return controllers;

		});