define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.FormScheduleECtrl', [])
        .controller('F1120PCSCHDEController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS',F1120PCSCHDEController]);
    function F1120PCSCHDEController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG,USER_SETTINGS) {
        var vm = this;

        vm.modalTitle = "Schedule E Premiums Earned—Section 832 (see instructions)";
		vm.premiumsEarned = 0;
        vm.unallocatedAmount = 0;
        vm.batchLoader = false;
        vm.showBatchData = false;
        vm.showRequestLoader = true;
        vm.leId = rowData.LEID;
        vm.formName = '1120PC';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';

        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };

        $scope.entityList = {
			"Tax Year": rowData.TAX_YEAR,
			"LEID": rowData.LEID,
			"LE Name": rowData.LE_NAME,
			"LE TYPE": rowData.LE_TYPE,
			//"LEDGER": rowData.LEDGER,
			"ME": rowData.ME
		};

		$scope.unallocatedAmount = rowData.dig || {};

		var saveParamDtls = [];
		var tempDataObj = {};
		$scope.scheduleEFormView = {rows:[]};
		vm.showFormLoader = true;
        function fetchSchErecords() {
            var params = { "action_id": 603, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY};

			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
				var dataRows = data.irsForm.formItemList;
				for(var i=1;i<dataRows.length;i++){
					saveParamDtls = [];
					tempDataObj = {};
					saveParamDtls = dataRows[i].dataRowList[0].rowDimensions.object_id.split('~');
					for(var j=0;j<dataRows[i].dataRowList[0].dataCellList.length;j++){
						if (j==0){
							tempDataObj.num = dataRows[i].dataRowList[0].dataCellList[j].name;
						} else if (j==1){
							tempDataObj.description = dataRows[i].dataRowList[0].dataCellList[j].name;
						}else{
							tempDataObj.lineAttrKey = dataRows[i].dataRowList[0].dataCellList[j].key.split('~')[1];
							tempDataObj.attrib_name = dataRows[i].dataRowList[0].dataCellList[j].key.split('~')[0];
							tempDataObj.attrib_order = dataRows[i].dataRowList[0].dataCellList[j].key.split('~')[2];
							tempDataObj.is_editable = dataRows[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y' ? false : true;
							tempDataObj.is_display = dataRows[i].dataRowList[0].dataCellList[j].property.display == 'Y' ? true : false;
							tempDataObj.value = dataRows[i].dataRowList[0].dataCellList[j].value == null ? 0 : parseInt(dataRows[i].dataRowList[0].dataCellList[j].value);
						}
					}
					tempDataObj.line_order = saveParamDtls[8];
					tempDataObj.saveParams = saveParamDtls;
					$scope.scheduleEFormView.rows.push(tempDataObj);
				}
				vm.formListOriginal = angular.copy($scope.scheduleEFormView);
				initFormView($scope.scheduleEFormView);
				vm.showFormLoader = false;
            });
        }

        function fetchSchEimports() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
            	"process_name": workspaceFactory.activeScreen.label,
            	"status": 'Y'
            };

			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=wwledr', params).then(function (data) {
				vm.BatchList = data.jsonObject;
				angular.forEach(vm.BatchList, function (value, key) {
                	vm.BatchList[key].TOGGLE = false;
            	});
            	vm.showRequestLoader = false;
            });
        }

        fetchSchErecords();
        fetchSchEimports();

        function loadTransactions(index, trans_key){
        	vm.batchLoader = true;
        	var params = { "client_key": USER_SETTINGS.user.client_key,
            	"process_name": workspaceFactory.activeScreen.label,
            	"transaction_key": trans_key
            };
        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=l6gzle', params).then(function (data) {
				vm.batchLoader = false;
				vm.showBatchData = true;
				vm.BatchListData = data.jsonObject;
            });
        };
        var prev_index = null;
        vm.showUploads = function(index, trans_key){
        	if(prev_index !== null){
        		if(prev_index !== index){
        			vm.BatchList[prev_index].TOGGLE = false;
        			prev_index = index;
        			vm.showBatchData = false;
        			vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
        			loadTransactions(index, trans_key);
        		}else{
        			vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
        			vm.showBatchData = true;
        		}
        	}else{
        		prev_index = index;
        		vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
        		loadTransactions(index, trans_key);
        	}
        };

		vm.combinedComputeValues = function(row){
            switch (rowData.TAX_YEAR) {
                case 2017:
                    vm.computeValues(row);
                    break ;
                case 2018:
                    vm.computeValues(row);
                    break;
                case 2019:
                    vm.computeValues(row);
                    break;
                default:
                    vm.computeValues(row);
            }
        }

		vm.computeValues = function(){
			//Total. Add lines 2a through 2d
			$scope.scheduleEFormView.rows[6].value = Math.round(($scope.scheduleEFormView.rows[2].value || 0) + ($scope.scheduleEFormView.rows[3].value || 0) + ($scope.scheduleEFormView.rows[4].value || 0) + ($scope.scheduleEFormView.rows[5].value || 0));

			//Total. Add lines 1 and 2e
			$scope.scheduleEFormView.rows[7].value = Math.round(($scope.scheduleEFormView.rows[0].value || 0) + $scope.scheduleEFormView.rows[6].value);

			//Total. Add lines 4a through 4d
			$scope.scheduleEFormView.rows[13].value = Math.round(($scope.scheduleEFormView.rows[9].value || 0) + ($scope.scheduleEFormView.rows[10].value || 0) + ($scope.scheduleEFormView.rows[11].value || 0) + ($scope.scheduleEFormView.rows[12].value || 0));

			//Subtract line 4e from line 3
			$scope.scheduleEFormView.rows[14].value = $scope.scheduleEFormView.rows[7].value - $scope.scheduleEFormView.rows[13].value;

			//Premiums earned. Add lines 5 and 6. Enter here and on Schedule A, line 1
			$scope.scheduleEFormView.rows[16].value = $scope.scheduleEFormView.rows[14].value + $scope.scheduleEFormView.rows[15].value || 0;

			vm.premiumsEarned = $scope.scheduleEFormView.rows[16].value;
			//vm.unallocatedAmount = rowData.TB_AMT - vm.premiumsEarned;


			// TODO : change it to generic computation
			$scope.unallocatedAmount[0][0].value = $scope.unallocatedAmount[1][0].value = $scope.scheduleEFormView.rows[16].value;
			$scope.unallocatedAmount[0][2].value = $scope.unallocatedAmount[0][0].value - $scope.unallocatedAmount[0][1].value ;
			$scope.unallocatedAmount[1][2].value = $scope.unallocatedAmount[1][0].value - $scope.unallocatedAmount[1][1].value ;

		}

        vm.saveSchddForm = function (url, data){
            var jsonSettings = { "tax_year": rowData.TAX_YEAR,
            	"scenario": rowData.SCENARIO,
            	"jcd_key": rowData.JCD_KEY,
            	"combination_key": rowData.COMBINATION_KEY,
            	"trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);

            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchdEForm() response.data.jsonObj ::",response.data.jsonObject);

				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
					$rootScope.$emit('gridUpdate', args);
					AlertService.add("success", "Transactions saved successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                    }
				}
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        }

        vm.saveDiagnostic = function(url, diagnosticData){
            var params =  _.extend({diag_exception :diagnosticData});
            params.tax_year =  rowData.TAX_YEAR;
            params.screen_key =  workspaceFactory.activeScreen.screen_key;
			params.scenario = rowData.SCENARIO;
            params.jcd_key = rowData.JCD_KEY;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        vm.save = function(){
			var formRowData = {};
			var tempArray = [];

			for(var i=0; i<$scope.scheduleEFormView.rows.length ;i++){
				formRowData = {
					tax_year: rowData.TAX_YEAR,
					trans_type_key: $scope.scheduleEFormView.rows[i].saveParams[1],
					line_order: $scope.scheduleEFormView.rows[i].line_order,
					attrib_order: $scope.scheduleEFormView.rows[i].attrib_order,
					line_description: $scope.scheduleEFormView.rows[i].description,
					combination_key: rowData.COMBINATION_KEY,
					group_obj_key: rowData.GROUP_OBJ_KEY,
					form_key: $scope.scheduleEFormView.rows[i].saveParams[2],
					line_no: $scope.scheduleEFormView.rows[i].num,
					occurence: $scope.scheduleEFormView.rows[i].saveParams[7],
					line_attrib_key: $scope.scheduleEFormView.rows[i].lineAttrKey,
					Attribute_Name: $scope.scheduleEFormView.rows[i].attrib_name,
					Attribute_Value: $scope.scheduleEFormView.rows[i].value,
					trans_dtls_key: $scope.scheduleEFormView.rows[i].saveParams[3],
					trans_status: $scope.scheduleEFormView.rows[i].saveParams[6],

					is_dirty: ''
				};
				tempArray.push(formRowData);
			}
			vm.formData.editrows.push(tempArray);

			vm.mainData = [];
			vm.mainData.push(vm.formData);
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nkepph";
            var saveDiagURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ftmltp";
			vm.saveSchddForm(url,vm.mainData);
            var diagData = [];
            angular.forEach($scope.unallocatedAmount, function (array, key) {
                angular.forEach(array, function (obj, key) {
                    if(key === 3){
                        diagData.push(obj);
                    }
                });
            });
            vm.saveDiagnostic(saveDiagURL, JSON.stringify(diagData));
            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		vm.reset = function () {
            $scope.scheduleEFormView = angular.copy(vm.formListOriginal);
			vm.combinedComputeValues();
        }

        function initFormView(filteredFormData) {
			$scope.scheduleEFormView = filteredFormData;
			vm.combinedComputeValues();
		}

		//Dynamic Tabset Code
		vm.tabs = [
	        {id: 1, name: "Form Entry", url: "app/components/Forms/F1120PC-SCH-E/Form-scheduleEEntry.html", active: true },
	        {id: 2, name: "Form View", url: "app/components/Forms/F1120PC-SCH-E/Form-scheduleEView.html", active: false},
	        {id: 3, name: "Audit", url: "app/components/Forms/F1120PC-SCH-E/Form-scheduleEVersion.html", active: false}
	        // {id: 4, name: "PDF", url: "app/components/Forms/F1120PC-SCH-E/Form-scheduleEPdf.html", active: false}
	    ];
    }
});