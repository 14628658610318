define([
    'angular',
    'XLSX',
    'ExcelJS',
    'saveAs'
], function (XLSX, ExcelJS, saveAs) {
    'use strict';
    var controllers = angular.module('app.bizzHierViewCtrl', [])
    .controller('bizzHierViewTabsCtrl', ['$rootScope', "GlobalService", bizzHierViewTabsCtrl])
    .controller('bizzHierViewCtrl', ['bizzHierViewFactory', '$rootScope', '$scope', 'USER_SETTINGS', 'TAX_YEARS', 'ModalFactory', '$uibModal', "AlertService", "$filter", "GlobalService", "$state", "menuDataService", bizzHierViewCtrl])
    .controller('bizzHierImportCtrl', ['bizzHierViewFactory', 'BizzHierFactory', '$scope', 'USER_SETTINGS', 'TAX_YEARS', 'ModalFactory', '$uibModal', "AlertService", "$filter", "GlobalService", "$rootScope", 'SERVER_CONFIG', 'USER_MENU', bizzHierImportCtrl])
    .controller('redirectBuController', ['bizzHierViewFactory', '$rootScope', '$window', '$scope', 'business_hierarchy_list', 'export_bu_list','hierarchy_id', '$state', '$http', 'ModalFactory', 'AlertService', 'GlobalService', '$timeout', 'workspaceFactory', 'processMapFactory', '$uibModalInstance', redirectBuController])
    .directive('bhNodesEdit', function() {
        return {
          templateUrl: 'app/components/Custom/bizzHierView/bh-nodes-edit.html'
        };
    })

    .directive('bhNodesImport', function() {
        return {
          templateUrl: 'app/components/Custom/bizzHierView/bh-nodes-import.html'
        };
    })

    .directive('excelExport', function () {
        return {
            restrict: 'A',
            scope: {
                fileName: "@",
                data: "&exportData"
            },
            replace: true,
            template: '<i ng-click="download()"><img src="assets/img/excel-icon.jpg" uib-tooltip="Export Data" tooltip-placement="bottom" tooltip-trigger="mouseenter" alt="excel export" style="width: 65%;"/> </i>',
            link: function (scope, element) {
                scope.download = function() {
                    function datenum(v, date1904) {
                        if(date1904) v+=1462;
                        var epoch = Date.parse(v);
                        return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
                    };
                    
                    function getSheet(data, opts) {
                        var ws = {};
                        var range = {s: {c:10000000, r:10000000}, e: {c:0, r:0 }};
                        for(var R = 0; R != data.length; ++R) {
                            for(var C = 0; C != data[R].length; ++C) {
                                if(range.s.r > R) range.s.r = R;
                                if(range.s.c > C) range.s.c = C;
                                if(range.e.r < R) range.e.r = R;
                                if(range.e.c < C) range.e.c = C;
                                var cell = {v: data[R][C] };
                                if(cell.v == null) continue;
                                var cell_ref = ExcelJS.utils.encode_cell({c:C,r:R});
                                
                                if(typeof cell.v === 'number') cell.t = 'n';
                                else if(typeof cell.v === 'boolean') cell.t = 'b';
                                else if(cell.v instanceof Date) {
                                    cell.t = 'n'; cell.z = ExcelJS.SSF._table[14];
                                    cell.v = datenum(cell.v);
                                }
                                else cell.t = 's';
                                
                                ws[cell_ref] = cell;
                            }
                        }
                        if(range.s.c < 10000000) ws['!ref'] = ExcelJS.utils.encode_range(range);
                        return ws;
                    };
                    
                    function Workbook() {
                        if(!(this instanceof Workbook)) return new Workbook();
                        this.SheetNames = [];
                        this.Sheets = {};
                    }
                        
                    var wb = new Workbook(), ws = getSheet(scope.data());
                    /* add worksheet to workbook */
                    wb.SheetNames.push(scope.fileName);
                    wb.Sheets[scope.fileName] = ws;
                    var wbout = ExcelJS.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'});

                    function s2ab(s) {
                        var buf = new ArrayBuffer(s.length);
                        var view = new Uint8Array(buf);
                        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                        return buf;
                    }
                    
                    window.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), scope.fileName+'.xlsx');
                };
            }
        };
    })

    function bizzHierViewTabsCtrl($rootScope, GlobalService) {
        const vm = this;
        vm.showImport = GlobalService.globalParams.is_locked === 'Y' ? true : false;

        $rootScope.$on('GlobalParams is updated', function () {
            vm.showImport = GlobalService.globalParams.is_locked === 'Y' ? true : false;
        });
    }

    function bizzHierViewCtrl(BizzHierViewFactory, $rootScope, $scope, USER_SETTINGS, TAX_YEARS, ModalFactory, $uibModal, AlertService, $filter, GlobalService, $state, menuDataService) {
        const vm = this;
        vm.searchText = '';
        vm.trees = {};
        vm.trees.nodes = [];
        vm.trees.currentAlertMessage = '';
        vm.collapsed = false;
        vm.treeFilter = $filter('uiTreeFilter');
        vm.lineOfBizz = ['FIN', 'IND'];
        vm.hierarchyId = "";
        vm.showActions = "";
        var link = $state.current.name;
        var temp_Bu_list = [];

        $rootScope.$on('GlobalParams is updated', function () {
            vm.trees.nodes = [];
            setActiveScreen(menuDataService.data,'children',link);
            getBZHYUserView();
        });
        
        // Prepare Excel data:
        $scope.fileName = "Business_Hierarchy_List";
        $scope.exportData = [];
        vm.EntireBuList = [];

        function getBZHYUserView() {
            const taxYear = GlobalService.globalParams.tax_year;
            const scenario = GlobalService.globalParams.scenario;
            vm.showActions = GlobalService.globalParams.is_locked;
            vm.trees.loading = true;
            BizzHierViewFactory.getBZHYUserPrivileges(taxYear, scenario).then((response) => {
                if (response.data.callSuccess === '1') {
                    const nodes = response.data.jsonObject;
                    vm.hierarchyId = response.data.hierarchyId;
                    vm.trees.nodes = nodes;
                    $scope.exportData = [];
                    vm.EntireBuList = [];
                    // Headers for the Excel:
                    $scope.exportData.push(["BU Code", "BU Name", "Parent BU Code", "Line Of Business", "Detail BSLA Ind", "Tax Year"]);
                    prepareTree(vm.trees.nodes);
                    if (nodes.length === 0) {
                        vm.trees.currentAlertMessage = 'There are no businesses have assigned to you, please contact system administrator for access!';
                    } else {
                        vm.trees.currentAlertMessage = '';
                    }
                } else {
                    vm.trees.nodes = [];
                    AlertService.add('warning', 'No business hierarchy found!', 30000);
                }
            }).catch((error) => {
                vm.trees.nodes = [];
                AlertService.add('error', 'Something went wrong while fetching data!', 30000);
            }).finally(() => {
                vm.trees.loading = false;
            })
        }

        function prepareTree(nodes) {
            nodes.forEach((node) => {
                $scope.exportData.push([node.mekey, node.name, node.parentMeCode, node.line_of_business, node.detail_bsla_ind, node.taxyear]);
                vm.EntireBuList.push({"id":node.mekey,"label":node.name + "-" + node.mekey, "name":node.name, "taxyear":node.taxyear, "key":node.meCode});
                if (!node.children) {
                    node.children = [];
                } else {
                    prepareTree(node.children);
                }
            });
        }

        var setActiveScreen = function(_collection,_children,_name) {
            for (var i = 0; i < _collection.length; i++) {
                var thisChildren =  _collection[i][_children];

                if( thisChildren && thisChildren.length ){
                    setActiveScreen(thisChildren,_children,_name);
                }
                if( _collection[i].screens != undefined ){
                    if( _.find(_collection[i].screens, {'link':_name}) != undefined ){
                        var activeScreen =  _.find(_collection[i].screens, {'link':_name});
                        GlobalService.globalParams.jcd_key = activeScreen.jcd_key;
                        GlobalService.globalParams.activity_key = activeScreen.activity_key;
                    }
                }
            }
        }

        setActiveScreen(menuDataService.data,'children',link);
        getBZHYUserView();

        vm.refreshBH = function (){
            vm.trees.nodes = [];
            getBZHYUserView();
        }

        $scope.$on('reload-hierarchy', function(event, args) {
            vm.refreshBH();
        });

        vm.toggleCollapse = function () {
            vm.collapsed = !vm.collapsed;
            var treeScope = _getRootNodesScope();
            if (vm.collapsed) {
                treeScope.$broadcast('angular-ui-tree:collapse-all');
            } else {
                treeScope.$broadcast('angular-ui-tree:expand-all');
            }
        };

        function _getRootNodesScope() {
            var treeElement = angular.element(document.getElementById('view-tree'));
            if (treeElement) {
                var treeScope = (typeof treeElement.scope === 'function') ?
                                treeElement.scope() : undefined;
                return treeScope;
            }
            return undefined;
        }

        vm.filter = function (node, searchText) {
            $filter('uiTreeFilter')(node, searchText, ['name', 'mekey']);
        }

        vm.moveNode = function (node, nodeScope) {
            vm.showInBeginning = true;
            vm.moveIcon = true;
            node.nodeToMove = true;
            node.isSelected = true;
            node.highlightNode = true;
            toggleMoveIcons(node, vm.trees.nodes);
            toggleMoveIconsChildren(node);

            var nodeScopeSafe = Object.create(nodeScope);
            vm.dropNode = createDropNode(node, nodeScopeSafe);
            vm.makeRootNode = rootNodeWrapper(node, nodeScopeSafe);
        }

        function getBuList(nodes) {
            var temp_Bu_obj= {
                name : nodes.name,
                mekey : nodes.mekey,
                taxyear : nodes.taxyear,
                mecode : nodes.meCode
            };
            temp_Bu_list.push(temp_Bu_obj);
            if (nodes.children) {
                if (nodes.children.length > 0) {
                    for (var i = 0; i < nodes.children.length; i++) {
                        getBuList(nodes.children[i])
                    }
                }
            }
            return temp_Bu_list;
        }

        vm.RedirectNode = function (node) {
            temp_Bu_list = [];
            vm.buList = getBuList(node);
            console.log(vm.buList);
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/Custom/bizzHierView/bizz-hier-redirect.html',
                controller: 'redirectBuController as ctrl',
                size: 'lg',
                resolve: {
                    business_hierarchy_list: function () {
                        return vm.buList;
                    },
                    export_bu_list: function () {
                        return vm.EntireBuList;
                    },
                    hierarchy_id: function (){
                        return vm.hierarchyId;
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {}, function () {});
        };

        function toggleMoveIconsChildren(srcNode) {
            for (var i = 0; i < srcNode.children.length; i++) {
                srcNode.children[i].nodeToMove = !srcNode.children[i].nodeToMove;
                srcNode.children[i].highlightNode = !srcNode.children[i].highlightNode;
                toggleMoveIconsChildren(srcNode.children[i]);
            }
        }

        function toggleMoveIcons(srcNode, nodes) {
            for (var i = 0; i < nodes.length; i++) {
                if (srcNode.meParentKey === nodes[i].mekey) {
                    nodes[i].nodeToMove = !nodes[i].nodeToMove;
                    return true;
                } else {
                    toggleMoveIcons(srcNode, nodes[i].children);
                }
            }
        }

        function createDropNode(srcNode, srcNodeScope) {
            return function (destNode, destNodesScope) {
                console.log(vm.nodeToMove);
                srcNode.meParentKey = destNode.mekey;
                srcNode.parentMeCode = destNode.meCode;
                destNode.children.push(srcNode);
                BizzHierViewFactory.moveBusinessHierarchy(
                    srcNode.key,
                    destNode.key,
                    vm.hierarchyId,
                    vm.hierarchyId
                ).then(function (response) {
                    console.log(response);
                    AlertService.add("success", "Moved Business Unit Successfully", 30000);
                })
                srcNodeScope.remove();

                vm.cancelNodeMove();
            }
        }

        function rootNodeWrapper(srcNode, srcNodeScope) {
            return function () {
                BizzHierViewFactory.moveBusinessHierarchy(
                    srcNode.key,
                    '',
                    vm.hierarchyId,
                    vm.hierarchyId
                ).then(function (response) {
                    console.log(response)
                    AlertService.add("success", "Moved Business Unit Successfully", 30000);
                })
                srcNode.meParentKey = null;
                vm.trees.nodes.unshift(srcNode);
                srcNodeScope.remove();
                vm.cancelNodeMove();
            }
        }

        vm.cancelNodeMove = function () {
            vm.dropNode = null;
            vm.showInBeginning = false;
            vm.moveIcon = false;
            vm.RedirectIcon = false;
            refreshNodes(vm.trees.nodes);
        };

        function refreshNodes(nodes) {
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].nodeToMove = false;
                nodes[i].isSelected = false;
                nodes[i].highlightNode = false;
                if (nodes[i].children) {
                    refreshNodes(nodes[i].children);
                }
            }
        }

        vm.editNode = function (node) {
            var modalAddObj = {};
            modalAddObj.template = "app/components/Custom/bizzHierView/gold-create-hierarchy-model.html";
            modalAddObj.controller = function ($scope, $uibModalInstance) {
                $scope.title = 'EDIT';
                $scope.actionBtnLabel = 'Save';
                $scope.lineOfBizz = vm.lineOfBizz;
                $scope.buCode = node.mekey;
                $scope.buDesc = node.name;
                $scope.selectedLOB = node.line_of_business;
                $scope.detailBSLA = node.detail_bsla_ind;
                $scope.estDataCollection = node.EST_Data_coll_level;
                $scope.scenario_dec = GlobalService.inputs.tax_year.selected.scenario_desc;
                $scope.level = node.level > 2 ? true : false;
                $scope.display_detailBSLA = node.children.length > 0 ? false : true;

                $scope.ok = function () {
                    $uibModalInstance.close({
                        "buCode": $scope.buCode, "buDesc": $scope.buDesc, "line_of_business": $scope.selectedLOB, "detail_bsla_ind": $scope.detailBSLA, "estDataCollLvl": $scope.estDataCollection
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }

            modalAddObj.size = "sm";

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalAddObj.template,
                controller: ['$scope', '$uibModalInstance', modalAddObj.controller],
                size: modalAddObj.size
            });

            modalInstance.closed.then(function (result) {
                vm.treeLoading = false;
            });

            modalInstance.result.then(function (result) {
                if (result) {
                    vm.saveEditBH(node, result);
                }
            });
        }

        vm.saveEditBH = function (node, result) {
            vm.treeLoading = true;
            BizzHierViewFactory.editBusinessUnit(
                node.key,
                node.grpobjkey,
                result.buDesc,
                result.buCode,
                result.line_of_business,
                result.detail_bsla_ind,
                vm.hierarchyId,
                vm.hierarchyId, 
                node.taxyear,
                result.estDataCollLvl,
                GlobalService.globalParams.jcd_key,
                GlobalService.globalParams.scenario  
            ).then(function (response) {
                if (response.result.value === '1') {
                    node.name = result.buDesc;
                    node.meCode = result.buCode;
                    node.line_of_business = result.line_of_business;
                    node.detail_bsla_ind = result.detail_bsla_ind;
                    node.EST_Data_coll_level = result.EST_Data_coll_level;
                    AlertService.add("success", "Saved Business Unit Successfully", 30000);
                } else {
                    AlertService.add("error", "Something went wrong, please try again!",30000);
                }
            }).finally(() => {
                vm.treeLoading = false;
            })
        }

        vm.deleteBH = function (node, nodeScope) {
            vm.treeLoading = true;
            BizzHierViewFactory.deleteBusinessUnit(
            node.key,
            node.meParentKey ? node.meParentKey : '',
            node.grpobjkey,
            vm.hierarchyId,
            node.taxyear,
            GlobalService.globalParams.jcd_key,
            GlobalService.globalParams.scenario).then(function (response) {
                if(response.callSuccess === '0'){
                    AlertService.add("error", response.errorMessage, 30000);
                }else if (response.value === "2") {
                    vm.treeLoading = false;
                    AlertService.add("warning", "Cannot delete business as it is used in code combination.", 30000);
                } else {
                    vm.treeLoading = false;
                    console.log(response);
                    deleteNodes(node);
                    var deletedMeCodes = [];
                    deletedMeCodes.push(node.meCode);
                    getAllMeCodesFromNode(node.children, deletedMeCodes);
                    showAutoPushIcons(deletedMeCodes, vm.trees.nodes);
                    
                    nodeScope.remove();

                    console.log("Deleted node : ", node);
                    if (response.value === "1")
                        AlertService.add("success", "Deleted Business Unit Successfully", 30000);
                }
            })
        }

        function deleteNodes(node) {
            if (node.children.length > 0) {
                for (var i = 0; i < node.children.length; i++) {
                    deleteNodes(node.children[i]);
                }
            }
        }

        function getAllMeCodesFromNode(nodes, deletedMeCodes) {
            for (var i = 0; i < nodes.length; i++) {
                deletedMeCodes.push(nodes[i].meCode);

                if (nodes[i].children.length > 0) {
                    getAllMeCodesFromNode(nodes[i].children, deletedMeCodes);
                }
            }
        }

        function showAutoPushIcons(meCodes, leftNodes) {
            for (var i = 0; i < leftNodes.length; i++) {
                if (meCodes.includes(leftNodes[i].meCode)) {
                    leftNodes[i].presentInrightTree = !leftNodes[i].presentInrightTree
                    // return true
                }
                if (leftNodes[i].children) {
                    if (leftNodes[i].children.length > 0) {
                        showAutoPushIcons(meCodes, leftNodes[i].children)
                    }
                }
            }
        }

        vm.addBHModal = function (node, nodeScope) {
            var modalAddObj = {};
            modalAddObj.template = "app/components/Custom/bizzHierView/gold-create-hierarchy-model.html";
            modalAddObj.controller = function ($scope, $uibModalInstance) {
                $scope.title = 'CREATE';
                $scope.actionBtnLabel = 'Create';
                $scope.lineOfBizz = vm.lineOfBizz;
                $scope.scenario_dec = GlobalService.inputs.tax_year.selected.scenario_desc;

                $scope.ok = function () {
                    $uibModalInstance.close({
                        "buCode": $scope.buCode, "buDesc": $scope.buDesc, "line_of_business": $scope.selectedLOB, "detail_bsla_ind": $scope.detailBSLA, "estDataCollLvl": $scope.estDataCollection
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };   
            }
            modalAddObj.size = "sm";
            $scope.openAddModal(modalAddObj, node);
        }

        $scope.openAddModal = function (modalAddObj, node) {
            console.log(vm.trees)
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalAddObj.template,
                controller: ['$scope', '$uibModalInstance',modalAddObj.controller],
                size: modalAddObj.size,
            });

            modalInstance.closed.then(function (result) {
                vm.treeLoading = false
            })
            modalInstance.result.then(function (result) {
                vm.treeLoading = true;
                var parentMeKey = node === undefined ? '' : node.key;
                BizzHierViewFactory.addBusinessUnit(
                    result.buCode,
                    result.buDesc,
                    GlobalService.globalParams.scenario,
                    parentMeKey,
                    GlobalService.globalParams.tax_year,
                    GlobalService.globalParams.jcd_key,
                    vm.hierarchyId,
                    result.line_of_business,
                    result.detail_bsla_ind,
                    result.estDataCollLvl
                ).then(function (response) {
                    if (response.callSuccess === '0') {
                        AlertService.add("error", response.errorMessage.split(":").length > 2 ? response.errorMessage.split(":")[2] : response.errorMessage, 30000);
                    }else{
                        var newNode = {
                            key: response.p_me_key,
                            mekey: result.buCode,
                            name: result.buDesc,
                            meParentKey: parentMeKey,
                            meCode: result.buCode,
                            isEdit: true,
                            parentMeCode: node === undefined ? null : node.meCode,
                            children: [],
                            grpobjkey: parseInt(response.p_group_obj_key),
                            line_of_business: result.line_of_business,
                            detail_bsla_ind: result.detail_bsla_ind,
                            estDataCollLvl: result.estDataCollLvl
                        };
                        if (node) {
                            node.children.push(newNode);
                        } else {
                            vm.trees.nodes.unshift(newNode);
                        }
                        $scope.exportData = [];
                        vm.EntireBuList = [];
                        // Headers for the Excel:
                        $scope.exportData.push(["BU Code", "BU Name", "Parent BU Code", "Line Of Business", "Detail BSLA Ind", "Tax Year"]);
                        prepareTree(vm.trees.nodes);
                        vm.treeLoading = false;
                        AlertService.add("success", "Created business sccessfully", 30000);
                    }
                });
            }, function () {});
        };

        vm.addRootNode = function () {
            var modalAddObj = {};
            modalAddObj.template = "app/components/Custom/bizzHierView/gold-create-hierarchy-model.html";
            modalAddObj.controller = function ($scope, $uibModalInstance) {
                $scope.title = 'CREATE';
                $scope.actionBtnLabel = 'Create';
                $scope.visibleBH = false;
                $scope.lineOfBizz = vm.lineOfBizz;
                $scope.scenario_dec = GlobalService.inputs.tax_year.selected.scenario_desc;

                $scope.ok = function () {
                    $uibModalInstance.close({
                        "buCode": $scope.buCode, 
                        "buDesc": $scope.buDesc, 
                        "line_of_business": $scope.selectedLOB, 
                        "detail_bsla_ind": $scope.detailBSLA,
                        "estDataCollLvl": $scope.estDataCollection
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
               
                /**
                 * Check duplicate hierarchy name in vm.trees.
                 */
                // if(vm.trees.right.selectedHierarchyId === null){
                //     $scope.visibleBH = true;
                // }
                $scope.checkDuplicateNames = function (newHierarchyName) {
                    if (vm.trees['left'].selectedAppCode === vm.trees['right'].selectedAppCode) {
                        $scope.showErrorDupicateForSource = vm.trees['left'].hierarchies.find(function (h) {
                            return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                        });
                    } else {
                        $scope.showErrorDupicateForDest = vm.trees['right'].hierarchies.find(function (h) {
                            return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                        });
                    }
                }
            }
            modalAddObj.size = "sm";
            $scope.openAddModal(modalAddObj);
        }; 
    }

    function bizzHierImportCtrl(BizzHierViewFactory, BizzHierFactory, $scope, USER_SETTINGS, TAX_YEARS, ModalFactory, $uibModal, AlertService, $filter, GlobalService, $rootScope, SERVER_CONFIG, USER_MENU) {
        var vm = this;
        console.log("user settings ", USER_SETTINGS)
        var changeGlobalParams = $rootScope.$on('GlobalService.globalParams: changed', function (payload) {
            console.log("global params changed;");
            vm.selectedTaxYear = GlobalService.inputs.tax_year.selected.label;
            vm.selectedScenario = GlobalService.globalParams.scenario;
            vm.trees.left.selectedAppCode = null
            vm.trees.right.selectedAppCode = null
            vm.trees.left.selectedHierarchyId = null
            vm.trees.right.selectedHierarchyId = null
        })
        $scope.$on('$destroy', function () {
            changeGlobalParams()
        })
        vm.lineOfBizz = ['FIN', 'IND'];
        vm.isPrivilegedSSO = SERVER_CONFIG.configSettings.privileged_sso_list.includes(USER_SETTINGS.user.sso_id);
        vm.collapsed = {};
        vm.nodeToMove = {};
        vm.taxAndScenarios = TAX_YEARS
        vm.jcds = USER_MENU.slice(0);
        vm.jcds.push({
            id: '0',
            label: 'GOLD',
            jcd_key: '0'//TODO: MAKE DYNAMIC?
        })

        vm.jcdsWithoutGold = vm.jcds.filter(function (item) {
            return item.label !== 'GOLD'
        });

        vm.trees = {
            left: {
                selectedAppCode: null,
                selectedHierarchyId: 0,
                selectedJcdkey: null,
                masterHierarchies: [],
                hierarchies: [],
                apps: vm.jcds,
                nodes: [],
                currentAlertMessage: "No new import to display",
                loading: false
            },
            right: {
                selectedAppCode: null,
                selectedHierarchyId: null,
                selectedJcdkey: null,
                masterHierarchies: [],
                hierarchies: [],
                apps: vm.jcdsWithoutGold,
                nodes: [],
                currentAlertMessage: "Click get current tree",
                loading: false
            }
        }
        vm.clients = USER_SETTINGS.user.clients;
        vm.taxYears = _.filter(TAX_YEARS, function (item) {
            return typeof item !== 'undefined';
        });

        console.log("JCD's", vm.jcds)
        vm.hierarchyList = [];

        vm.selectedClient = vm.clients[0],
        vm.selectedTaxYear = GlobalService.inputs.tax_year.selected.label;//vm.taxYears[0].label;
        vm.selectedScenario = GlobalService.globalParams.scenario;
        vm.selectedJcd = 250;//TODO: Take from JCD Selection

        vm.hierarchyName = '';
        vm.showErrorDupicate = false;
        var flattened = {};
        vm.treeFilter = $filter('uiTreeFilter');

        vm.data = [];

        vm.goldAppCode = 'GOLD';
        vm.goldHierarchyId = 2;

        vm.openHierarchyExcelModel = function () {
            var modalObj = {};
            modalObj.template = "app/components/Custom/bizzHierView/gold-add-hierarchy-excel-model.html";
            modalObj.size = "sm";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            $scope.openModal(modalObj);
        }

        vm.addRootNode = function () {
            var modalAddObj = {};
            modalAddObj.template = "app/components/admin/bizzHier/templates/gold-create-hierarchy-model.html";
            modalAddObj.controller = function ($scope, $uibModalInstance) {
                $scope.title = 'CREATE';
                $scope.actionBtnLabel = 'Create';
                $scope.visibleBH = false;
                $scope.lineOfBizz = vm.lineOfBizz;

                $scope.ok = function () {
                    $uibModalInstance.close({
                        "hierarchyName" : $scope.newHzchName, "buCode": $scope.buCode, "buDesc": $scope.buDesc, "line_of_business": $scope.selectedLOB, "detail_bsla_ind": $scope.detailBSLA
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
               
                /**
                 * Check duplicate hierarchy name in vm.trees.
                 */
                if(vm.trees.right.selectedHierarchyId === null){
                    $scope.visibleBH = true;
                }
                $scope.checkDuplicateNames = function (newHierarchyName) {
                    if (vm.trees['left'].selectedAppCode === vm.trees['right'].selectedAppCode) {
                        $scope.showErrorDupicateForSource = vm.trees['left'].hierarchies.find(function (h) {
                            return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                        });
                    } else {
                        $scope.showErrorDupicateForDest = vm.trees['right'].hierarchies.find(function (h) {
                            return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                        });
                    }
                }
            }
            modalAddObj.size = "sm";
            $scope.openAddModal(modalAddObj);
        }

        $scope.openModal = function (modalObj) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: ['$scope', 'BizzHierFactory', '$uibModalInstance', 'inputData', function ($scope, BizzHierFactory, $uibModalInstance, inputData) {
                    $scope.trees = {
                        left: {
                            hierarchies: [],
                            loaded: true
                        },
                        right: {
                            hierarchies: [],
                            loaded: true
                        }
                    }

                    $scope.inputData = inputData;
                    $scope.creating = true;
                    $scope.enableMe = true;

                    $scope.createhierarchy = function () {
                        $scope.creating = true;
                        var srcHierarchyId = $scope.srcHierarchyId === undefined ? '' : $scope.srcHierarchyId;
                        if( $scope.srcHierarchyId !== ''){
                            $scope.enableMe = false;
                        }
                        $scope.createBtnText = 'Importing...';
                        BizzHierFactory.createHierarchy(
                            srcHierarchyId,
                            $scope.hierarchyName,
                            $scope.descAppCode,
                            inputData.selectedTaxYear,
                            inputData.selectedScenario,
                            $scope.descAppSelected.jcd_key).then(function (response) {
                                $scope.createBtnText = 'Import';
                                $scope.creating = false;
                                console.log(response);
                                if (response.p_retval == "1") {
                                    AlertService.add("success", "Hierarchy created successfully", 30000);
                                    $uibModalInstance.close();
                                } else {
                                    AlertService.add("error", "Hierarchy creation failed", 30000);
                                }
                            })
                    }

                    $scope.createHierarchyWithExcel = function () {
                        $scope.creating = true;
                        $scope.createBtnText = 'Importing...';
                        var file = $scope.excelFile;
                        
                        var ckheader = $scope.ckHeader;
                        
                        console.log(ckheader);

                        vm.loadTree('left', file, ckheader);
                        $uibModalInstance.close();
                    }

                    /**
                     * Check duplicate hierarchy name in $scope destination application tree
                     */
                    $scope.checkDuplicateNamesExcel = function (newHierarchyName) {
                        // temp fix for empty string response received from getHierachyList()
                        if ($scope.trees['right'].hierarchies === '') {
                            $scope.showErrorDupicateForDest = false;
                        } else {
                            $scope.showErrorDupicateForDest = $scope.trees['right'].hierarchies.find(function (h) {
                                return h.hierarchy_name.toUpperCase() === newHierarchyName.toUpperCase();
                            });
                        }
                    }

                    $scope.isRequiredFields = function () {
                        $scope.showErrorDupicateForSource = false
                        $scope.showErrorDupicateForDest = false
                        if ($scope.srcAppCode === $scope.descAppCode) {
                            $scope.isHierarchyPresentInSource = $scope.trees['left'].hierarchies.filter(function (item) {
                                return item.hierarchy_name === $scope.hierarchyName
                            });

                            if ($scope.isHierarchyPresentInSource.length > 0) {
                                $scope.creating = true;
                                $scope.showErrorDupicateForSource = true
                                $scope.showErrorDupicateForDest = false
                            }
                        } else {
                            $scope.isHierarchyPresentInDest = $scope.trees['right'].hierarchies.filter(function (item) {
                                return item.hierarchy_name === $scope.hierarchyName
                            });
                            if ($scope.isHierarchyPresentInDest.length > 0) {
                                $scope.creating = true;
                                $scope.showErrorDupicateForSource = false
                                $scope.showErrorDupicateForDest = true
                            }
                        }
                        if ($scope.isHierarchyPresentInDest) {
                            if ($scope.isHierarchyPresentInDest.length === 0) {
                                $scope.creating = false
                            }
                        } else if ($scope.isHierarchyPresentInSource) {
                            if ($scope.isHierarchyPresentInSource.length === 0) {
                                $scope.creating = false
                            }
                        }
                    }

                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                }],
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    inputData: function () {
                        console.log("Inpu.t Apps", vm.trees.left.apps)
                        return {
                            selectedTaxYear: vm.selectedTaxYear,
                            selectedScenario: vm.selectedScenario,
                            selectedJcd: vm.trees.right.selectedJcdkey,
                            selectedAppCode: vm.trees.right.selectedAppCode,
                            srcHierarchyId: vm.goldHierarchyId,//always create from
                            scrApps: vm.trees.left.apps,
                            descApps: vm.trees.right.apps
                        };
                    }
                }
            });

            modalInstance.result.then(function (newHierarchy) {
            }, function () {
            });
        };

        function findMovedNodes(rightNodes, flattenedLeftNodes, movedNodes, hasChildren) {
            for (var i = 0; i < rightNodes.length; i++) {
                if (flattenedLeftNodes[rightNodes[i].mekey]) {
                    if (hasChildren || rightNodes[i].parentMeCode !== flattenedLeftNodes[rightNodes[i].mekey].parentMeCode) {
                        movedNodes.push(rightNodes[i].mekey)
                        if (rightNodes[i].children.length > 0) {
                            findMovedNodes(rightNodes[i].children, flattenedLeftNodes, movedNodes , true)
                        }

                    }
                    else {
                        if (rightNodes[i].children.length > 0) {
                            findMovedNodes(rightNodes[i].children, flattenedLeftNodes, movedNodes , false)
                        }

                    }
                }
            }
        }

        vm.compareHierarchies = function () {
            vm.treeCompared = true;
            vm.movedNodes = []
            vm.onlyInGold = _.difference(Object.keys(flattened[0]), Object.keys(flattened[vm.trees.right.selectedHierarchyId]));
            vm.onlyInTax = _.difference(Object.keys(flattened[vm.trees.right.selectedHierarchyId]), Object.keys(flattened[0]));
            vm.edited = _.differenceBy(Object.values(flattened[vm.trees.right.selectedHierarchyId]), Object.values(flattened[0]), 'name').map(function (item) {
                return item.mekey;
            });

            findMovedNodes(vm.trees.right.nodes, flattened[0], vm.movedNodes , false)

            console.table(vm.movedNodes)
            //To remove the new nodes created from edited array
            vm.edited = _.difference(vm.edited, vm.onlyInTax);
            console.log(vm.edited);
            highlightHierarchy(vm.trees.left.nodes, vm.onlyInGold, "absentInRightTree");
            highlightHierarchy(vm.trees.right.nodes, vm.onlyInTax, "absentInLeftTree");
            highlightHierarchy(vm.trees.right.nodes, vm.edited, "nodeEdited");
            highlightHierarchy(vm.trees.right.nodes, vm.movedNodes, "nodeMoved")
        }

        function highlightHierarchy(tree, diff, flag) {
            for (var i = 0; i < tree.length; i++) {
                tree[i][flag] = false;
                if (diff.includes(tree[i].mekey)) {
                    tree[i][flag] = true;
                }
                highlightHierarchy(tree[i].children, diff, flag);
            }
        }

        function refreshHighlightHierarchy(nodes, flag) {
            for (var i = 0; i < nodes.length; i++) {
                nodes[i][flag] = false;
                if (nodes[i].children) {
                    if (nodes[i].children.length > 0) {
                        refreshHighlightHierarchy(nodes[i].children, flag)
                    }
                }
            }
        }

        vm.flattenHierarchy = function (arr, type) {
            for (var i = 0; i < arr.length; i++) {
                if (!arr[i].children) {
                    arr[i].children = [];
                }
                addOrUpdateNodeToFlattenedStore(type, arr[i]);

                vm.flattenHierarchy(arr[i].children, type);
            }
        }

        function addOrUpdateNodeToFlattenedStore(type, node) {
            if (flattened[type][node.mekey]) {
                console.log("Node Updated to :", node);
            } else {
                console.log("New Node Added :", node);
            }
            flattened[type][node.mekey] = {
                "meParentKey": node.meParentKey,
                "meCode": node.meCode,
                "meKey": node.meKey,
                "meName": node.meName,
                "path": node.path,
                "level": node.level,
                "hierarchyId": node.hierarchyId,
                "groupObjKey": node.groupObjKey,
                "taxYear": node.taxYear,
                "parentMeCode": node.parentMeCode,
                "line_of_business": node.line_of_business,
                "detail_bsla_ind": node.detail_bsla_ind
            };
            if (node.children){
                if (node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        addOrUpdateNodeToFlattenedStore(type, node.children[i]);
                    }
                }
            }
        }

        function singleLevelBU(nodes) {
            var tempBU = angular.copy(nodes);
            delete tempBU.children;
            vm.single_level_bu.push(tempBU);
            if (nodes.children) {
                if (nodes.children.length > 0) {
                    for (var i = 0; i < nodes.children.length; i++) {
                        singleLevelBU(nodes.children[i])
                    }
                }
            }
        }

        vm.loadTree = function (treeType, file, ckheader) {
            const taxYear = GlobalService.globalParams.tax_year;
            const scenario = GlobalService.globalParams.scenario;
            const jcdKey = GlobalService.globalParams.jcd_key;
            
            var otherTreeType = treeType === 'left' ? 'right' : 'left'
 
            removeSameHierarchy(otherTreeType, treeType)

            if (vm.trees.right.nodes.length > 0 && vm.trees.left.nodes.length > 0) {
                for (var i = 0; i < vm.trees.left.nodes.length; i++) {
                    refreshPresentInRightTree(vm.trees.left.nodes[i])
                }
            }
            refreshHighlightHierarchy(vm.trees.left.nodes, "absentInRightTree");
            refreshHighlightHierarchy(vm.trees.right.nodes, "absentInLeftTree");
            refreshHighlightHierarchy(vm.trees.right.nodes, "nodeEdited");
            refreshHighlightHierarchy(vm.trees.left.nodes, "nodeMoved");

            console.log("Enter Load Tree Function", treeType)
            vm.treeCompared = false;

            if (typeof vm.trees[treeType].selectedAppCode === "undefined" || typeof vm.trees[treeType].selectedHierarchyId === "undefined") {
                return;
            }

            vm.trees[treeType].loading = true;
            vm.trees[treeType].nodes.length = 0;
            if(treeType === 'right'){
                BizzHierViewFactory.getBZHYUserPrivileges(taxYear, scenario).then(function (response) {
                    if (response.data.callSuccess === '1') {
                        vm.trees[treeType].nodes = response.data.jsonObject;
                        vm.trees[treeType].selectedHierarchyId = response.data.hierarchyId;
                        flattened[vm.trees[treeType].selectedHierarchyId] = {};
                        vm.flattenHierarchy(vm.trees[treeType].nodes, vm.trees[treeType].selectedHierarchyId);
                        vm.trees[treeType].currentAlertMessage = "";
                    } else if (response.length === 0)
                        vm.trees[treeType].currentAlertMessage = "There is no data available";
                    else
                        AlertService.add("error", "Error while fetching data");
                    vm.trees[treeType].loading = false;
                    if (vm.trees.right.nodes.length > 0 && vm.trees.left.nodes.length > 0) {
                        for (var i = 0; i < vm.trees.left.nodes.length; i++) {
                            presentInrightTree(vm.trees.left.nodes[i])
                        }
                    }
                })
            }else{
                BizzHierViewFactory.uploadExcelBizzHierarchy(file, file.name, ckheader).then(function (response) {
                    $scope.createBtnText = 'Import';
                    $scope.creating = false;
                    if (response.data.callSuccess === '1') {
                        console.log(response.data);
                        AlertService.add("success", "Business Hierarchy imported successfully", 30000);
                        vm.trees[treeType].nodes = JSON.parse(response.data.jsonObject);
                        flattened[vm.trees[treeType].selectedHierarchyId] = {};
                        vm.flattenHierarchy(vm.trees[treeType].nodes, 0);
                        vm.trees[treeType].currentAlertMessage = "";
                        vm.trees[treeType].loading = false;
                        if (vm.trees.right.nodes.length > 0 && vm.trees.left.nodes.length > 0) {
                            for (var i = 0; i < vm.trees.left.nodes.length; i++) {
                                presentInrightTree(vm.trees.left.nodes[i])
                            }
                        }
                    } else {
                        $scope.createBtnText = 'Import';
                        $scope.creating = false;
                        vm.trees[treeType].loading = false;
                        AlertService.add("error", "Error during uploading the file! Cause: " + response.data.errorMessage, 30000);
                    }
                });
            }
        }

        $scope.openModalBH = function (modalObj, _data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });
        };

        function refreshPresentInRightTree(node) {
            if (!flattened[vm.trees.right.selectedHierarchyId][node.mekey]) {
                node.presentInrightTree = false
            }
            if (node.children) {
                if (node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        refreshPresentInRightTree(node.children[i])
                    }
                }
            }
        }

        function presentInrightTree(node) {
            if (!flattened[vm.trees.right.selectedHierarchyId][node.mekey]) {
                node.presentInrightTree = !node.presentInrightTree
            }
            if (node.children) {
                if (node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        presentInrightTree(node.children[i])
                    }
                }
            }
        }

        function removeSameHierarchy(selectedTreeType, otherTreeType) {
            if (vm.trees[selectedTreeType].masterHierarchies.length > 0) {
                if (vm.trees[selectedTreeType].selectedAppCode === vm.trees[otherTreeType].selectedAppCode) {
                    if (vm.trees[otherTreeType].selectedHierarchyId) {
                        vm.trees[selectedTreeType].hierarchies.map(function (item) {
                            if (item.hierarchy_id === vm.trees[otherTreeType].selectedHierarchyId) {
                                item.existsInOtherTree = true
                            }
                            return item
                        })
                    }
                } else {
                    vm.trees[selectedTreeType].hierarchies = angular.copy(vm.trees[selectedTreeType].masterHierarchies)
                }
            }
        }

        vm.autoPush = function (srcNode, srcNodeScope, hierarchyId) {
            var srcNodeSafeCopy = angular.copy(srcNode)
            srcNodeSafeCopy.hierarchyId = vm.hierarchyId

            if (flattened[hierarchyId][srcNodeScope.$modelValue.meCode]) {
                AlertService.add('warning', "This BU already exists in '" + vm.trees.right.selectedHierarchyId + "'", 30000)
                return true
            }
            if (srcNodeSafeCopy.parentMeCode !== undefined && srcNodeSafeCopy.parentMeCode !== null) {
                findImmediateParent(srcNodeSafeCopy, vm.trees.right.nodes)
                if (!vm.immediateParent) {
                    AlertService.add('warning', "This BU does not have a parent present in the right tree, Please add its parent first.", 30000)
                    return true
                } else {
                    srcNodeSafeCopy.meParentKey = vm.immediateParent.key
                    refreshHighlightHierarchy([srcNodeSafeCopy], 'absentInRightTree')
                    vm.immediateParent.children.push(srcNodeSafeCopy)
                }
            } else {
                vm.trees.right.nodes.push(srcNodeSafeCopy)
                refreshHighlightHierarchy(vm.trees.right.nodes, 'absentInRightTree')
            }

            presentInrightTree(srcNode)
            addOrUpdateNodeToFlattenedStore(0, srcNode);
            vm.single_level_bu = [];
            singleLevelBU(srcNode);
            BizzHierViewFactory.autoPushBusinessHierarchy(
                JSON.stringify(vm.single_level_bu),
                GlobalService.globalParams.tax_year,
                GlobalService.globalParams.scenario,
                GlobalService.globalParams.jcd_key,
                vm.trees.right.selectedHierarchyId
            ).then(function (response) {
                console.log(response)
                srcNodeSafeCopy.parentMeCode = vm.immediateParent.meCode
                if (vm.treeCompared)
                vm.compareHierarchies();
                AlertService.add("success", "Moved Business Unit Successfully", 30000);
            })
        }

        function findImmediateParent(srcNode, rightTreeNodes) {
            for (var i = 0; i < rightTreeNodes.length; i++) {
                if (srcNode.parentMeCode === rightTreeNodes[i].mekey) {
                    vm.immediateParent = rightTreeNodes[i];
                } else {
                    if(rightTreeNodes[i].children){
                        findImmediateParent(srcNode, rightTreeNodes[i].children)
                    }
                }
            }
        }

        vm.toggleCollapse = function (id) {
            vm.collapsed[id] = !vm.collapsed[id];
            var treeScope = _getRootNodesScope(id);
            if (vm.collapsed[id]) {
                treeScope.$broadcast('angular-ui-tree:collapse-all');
            } else {
                treeScope.$broadcast('angular-ui-tree:expand-all');

            }
        };

        function _getRootNodesScope(myTreeId) {
            var treeElement = angular.element(document.getElementById(myTreeId));
            if (treeElement) {
                var treeScope = (typeof treeElement.scope === 'function') ?
                    treeElement.scope() : undefined;
                return treeScope;
            }
            return undefined;
        };
        vm.filter = function (node, searchText) {
            $filter('uiTreeFilter')(node, searchText, ['name']);
        }
        vm.searchTree = function (listToSearch) {           
            var a = $.map(vm.searchText.match(/"[^"]+"|[^ ]+/g) || [''], function (word) {
                if (word.charAt(0) === '"') {
                    var m = word.match(/^"(.*)"$/);
                    word = m ? m[1] : word;
                }

                return word.replace('"', '');
            });

            var search = '^(?=.*?' + a.join(')(?=.*?') + ').*$';
            var regExp = new RegExp(search, 'i')
            for (var index = 0; index < listToSearch.length; index++) {
                var item = listToSearch[index];
                item.filteredOut = !regExp.test(item.name);
                if (item.children) {
                    var nextItem = vm.searchTree(item.children)
                    if (nextItem)
                        nextItem.filteredOut = !regExp.test(nextItem.name);
                }
            }
        }
    }

    function redirectBuController(BizzHierViewFactory, $rootScope, $window, $scope, business_hierarchy_list, export_bu_list, hierarchy_id, $state, $http, ModalFactory, AlertService, GlobalService, $timeout, workspaceFactory, processMapFactory, $uibModalInstance) {
        var vm = this;
        vm.sourceBuList = angular.copy(business_hierarchy_list);
        vm.targetBuList = [];
        vm.allBuList = angular.copy(export_bu_list);
        vm.redirectLoader = false;
        vm.redirectDeleteLoader = false;
        vm.redirectBtn = true;
        vm.redirectDeleteBtn = true;
        vm.title = 'Redirect Business';

        $scope.dropdownSetting ={
			enableSearch: true,
			selectionLimit: 1,
			scrollableHeight: '200px', 
			scrollable: true,
			showUncheckAll:false,
			showCheckAll:false,
			smartButtonTextConverter: function(itemText, originalItem) { 
                return itemText; 
			},
			smartButtonTextProvider:function(selectionArray) { return selectionArray[0].label || "Select"; },
		};

        function RenderSourceData(){
			angular.forEach(vm.sourceBuList, function (row, key) {
				var tempTargetData = {
					obj_id: key + 1,
                    name : "-",
                    mekey : "-",
                    taxyear : "-",
                    mecode : "-",
                    isTarget: false,
                    selectedBu : [],
                    loading: false,
					noRecords: false,
                    showList: false,
                    showCancelBtn: false
				};
				vm.targetBuList.push(tempTargetData);
			});
			vm.originalSourceBuData = angular.copy(vm.sourceBuList);
			vm.originalTargetBuData = angular.copy(vm.targetBuList);
			vm.showFormLoader = false;
        }

        $scope.multiselectEvents2 = {
			onItemSelect: function (item) {
                console.log(item);
                if(item.id === vm.sourceBuList[vm.selectedDropdown].mekey){
                    AlertService.add("warning", "Target BU cannot be same as source BU, please make a different selection.", 6000);
                }else{
                    vm.fillTargetRow(item, vm.selectedDropdown, true);
                }
			},
			onItemDeselect: function (item) {
                console.log('deselect',item);
                if(item.id !== vm.sourceBuList[vm.selectedDropdown].mekey){
                    vm.fillTargetRow(item, vm.selectedDropdown, false);
                }
			}
        };
        
        vm.getMeList = function (search_key, row) {
			vm.targetBuList[row].loading = true;
			vm.targetBuList[row].noRecords = false;
			
            var params = {};
            vm.targetDetails = [];
            if (!!search_key) {
                vm.searchBuList = angular.copy(vm.allBuList);
                vm.targetBuList[row].loading = false;
                vm.targetBuList[row].showList = true;
                vm.targetBuList[row].noRecords = false;
                vm.targetBuList[row].showCancelBtn = false;
                // params.search_text = search_key;
                // return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=90513b', params).then(function (data) {
				// 	if (data.jsonObject.length > 0) {
				// 		vm.searchBuList = vm.allBuList;
				// 		vm.targetBuList[row].loading = false;
				// 		vm.targetBuList[row].showList = true;
				// 		vm.targetBuList[row].noRecords = false;
				// 	} else {
				// 		vm.targetBuList[row].noRecords = true;
				// 		vm.targetBuList[row].loading = false;
				// 		vm.targetBuList[row].showList = false;
				// 	}
				// });
            } else {
                vm.searchBuList = [];
                vm.targetBuList[row].loading = false;
                vm.targetBuList[row].noRecords = false;
                vm.cancelSearchSelection(row);
            }
		};

        vm.fillTargetRow = function (searched_data, row, selected) {
            if(searched_data.id === vm.sourceBuList[row].mekey){
                AlertService.add("warning", "Target BU cannot be same as source BU, please make a different selection.", 6000);
                return;
            }
            vm.targetBuList[row].name = selected ? searched_data.name : "-";
            vm.targetBuList[row].mekey = selected ? searched_data.id : "-";
            vm.targetBuList[row].taxyear = selected ? searched_data.taxyear : "-";
            vm.targetBuList[row].mecode = selected ? searched_data.key : "-";
            vm.targetBuList[row].isTarget = selected ? true : false;
            vm.targetBuList[row].showList = false;
            vm.targetBuList[row].showCancelBtn = true;
            vm.targetBuList[row].targetSearchText = searched_data.name;
            var selectedTargetCount = vm.getSelectedTargetCount();
            if(selectedTargetCount > 0 && selectedTargetCount < vm.targetBuList.length){
                vm.redirectBtn = false;
                vm.redirectDeleteBtn = true;
            }else{
                if(selectedTargetCount === vm.targetBuList.length){
                    vm.redirectBtn = false;
                    vm.redirectDeleteBtn = false;
                }else{
                    vm.redirectBtn = true;
                    vm.redirectDeleteBtn = true;
                }
            }
        };

        vm.cancelSearchSelection = function(i){
            vm.targetBuList[i].name = "-";
            vm.targetBuList[i].mekey = "-";
            vm.targetBuList[i].taxyear = "-";
            vm.targetBuList[i].mecode = "-";
            vm.targetBuList[i].isTarget = false;
            vm.targetBuList[i].showList = false;
            vm.targetBuList[i].showCancelBtn = false;
            vm.targetBuList[i].targetSearchText = '';
            var selectedTargetCount = vm.getSelectedTargetCount();
            if(selectedTargetCount > 0 && selectedTargetCount < vm.targetBuList.length){
                vm.redirectBtn = false;
                vm.redirectDeleteBtn = true;
            }else{
                if(selectedTargetCount === vm.targetBuList.length){
                    vm.redirectBtn = false;
                    vm.redirectDeleteBtn = false;
                }else{
                    vm.redirectBtn = true;
                    vm.redirectDeleteBtn = true;
                }
            }
        };

        vm.getSelectedTargetCount = function(){
            var count = 0;
            angular.forEach(vm.targetBuList, function (value, key) {
                if(value.isTarget){
                    count++;
                }
            });
            return count;
        };

		vm.removeSourceTarget = function(i){
			if(vm.sourceBuList.length > 1){
				vm.sourceBuList.splice(i, 1);
				vm.targetBuList.splice(i, 1);
			}else{
				AlertService.add("warning", "Atleast one source entity must be present for save.", 6000);
			}
        };
        
        RenderSourceData();
		
        vm.save = function(type, deleteBu){
            vm[type] = true;
			var targetPresent = false;
			var targetPresentIndex = [];
			var sourceMeList = [];
            var targetMeList = [];

        	if(vm.targetBuList.length > 0){
				angular.forEach(vm.targetBuList, function (value, key) {
					if(value.isTarget){
						targetPresent = true;
						targetPresentIndex.push(key);
					}
				});
				if(targetPresent){
					for(var i = 0; i < targetPresentIndex.length; i++){
						sourceMeList.push(vm.sourceBuList[targetPresentIndex[i]].mecode);
                        targetMeList.push(vm.targetBuList[targetPresentIndex[i]].mecode);
					}
					if(targetPresentIndex.length !== vm.sourceBuList.length){
						AlertService.add("info", "NOTE : Only records with target selected would be saved, Rest all would be ignored", 4000);
					}
				}else{
					AlertService.add("warning", "No Data to save, as target is not selected for any of the source entities", 4000);
        			return;
				}
        	};
			BizzHierViewFactory.mergeBusinessHierarchy(
                sourceMeList.join(),
                targetMeList.join(),
                hierarchy_id,
                deleteBu ? true : false
            ).then(function (response) {
                vm[type] = false;
                console.log(response);
                if(response.callSuccess === '0'){
                    AlertService.add("error", response.errorMessage, 30000);
                }else{
                    AlertService.add("success", "Business redirection completed successfully", 30000);
                    if(deleteBu){
                        closeModal();
                        $rootScope.$broadcast('reload-hierarchy', {});
                    }else{
                        closeModal();
                    }
                }
            })
        };

        vm.reset = function(){
            vm.sourceBuList = angular.copy(business_hierarchy_list);
            vm.targetBuList = [];
        };

        vm.cancel = function () {
            closeModal();
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };
    };
    return controllers;
});