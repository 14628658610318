define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.summaryDiagnosticController', [])

    .controller('SummaryDiagnosticController', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout','workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', '$filter','TAX_YEARS', summaryDiagnosticController]);
    function summaryDiagnosticController(GlobalService, JsonObjectFactory, $parse, $scope, 
        $rootScope, $timeout , workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, 
        USER_SETTINGS, $filter,TAX_YEARS) {
        var vm = this;
        const currentUser = USER_SETTINGS.user;
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
        vm.clientKey = currentUser.client_key;
        vm.globalParams = GlobalService.globalParams;
        vm.taxYears = TAX_YEARS;

        vm.isEnable = false;
        vm.showBackdrop = true;
        vm.enableFilter = function(){
            vm.isEnable = !vm.isEnable;
        }
    }   
});