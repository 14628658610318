define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.mainTrailBalCtrl', [])
        .controller('mainTrailBalController', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
        'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', mainTrailBalController]);

    function mainTrailBalController(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG) {
    var vm= this;
    vm.trailData= [
        {
            "ac_code": "442001001",
            "des": "Loans from related Non U.S, Intrest bearing ",
            "curr_amt": "-375,019,111",
            "prior_amt": "-1,555,454,121",
            "var_amt": "1,116,121,111"
        },
        {
            "ac_code": "442001001",
            "des": "Loans from related U.S, Intrest bearing ",
            "curr_amt": "-375,019,111",
            "prior_amt": "-1,555,454,121",
            "var_amt": "1,999,121,787"
        },
        {
            "ac_code": "442001001",
            "des": "NonUS-NationalityTxPay-CyTx-CyAccured",
            "curr_amt": "-10,111",
            "prior_amt": "-12,458",
            "var_amt": "28,452"
        },
        {
            "ac_code": "442001001",
            "des": "NonUS-NationalityTxPay-CyTx-UnrG/L-F/Cur-CYchg",
            "curr_amt": "-375,019,111",
            "prior_amt": "-1,555,454,121",
            "var_amt": "1,116,121,111"
        },
        {
            "ac_code": "442001001",
            "des": "NonUS-NationalityTxPay-PYTx-1/1",
            "curr_amt": "-375,019,111",
            "prior_amt": "-1,789,454,121",
            "var_amt": "1,116,121,111"
        },
        {
            "ac_code": "442001001",
            "des": "NonUS-NationalityTxPay-PYtx-UnrG/L-F/Cur-CYChng",
            "curr_amt": "-111,454,444",
            "prior_amt": "-1,458,44",
            "var_amt": "1,454,889,666"
        },
        {
            "ac_code": "442001001",
            "des": "NonUS-NationalityTxPay-2ndPYTx-1/1",
            "curr_amt": "-444,019,111",
            "prior_amt": "-1,565,454,989",
            "var_amt": "1,685,121,448"
        },
        {
            "ac_code": "442001001",
            "des": "NonUS-NationalityTxPay-2ndPYTx-To/FrDefTx",
            "curr_amt": "-145,019,111",
            "prior_amt": "-1,555,454,121",
            "var_amt": "1,116,121,111"
        },
        {
            "ac_code": "442001001",
            "des": "NonUS-NationalityTxPay-2ndPYTx-IntrEntitySettlements",
            "curr_amt": "-147,019,111",
            "prior_amt": "-1,545,454,121",
            "var_amt": "1,787,121,877"
        }
];

    /* save from workflow 5/14/2019 */
    $scope.$on('workflowSave',function(event,data){
        vm.save();
    });
    vm.save = function(){
        AlertService.add("success","Data save Successfully",4000);
    }

}
});