define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.formViewServices', [])
                            .factory('requestBuilderFactory',[function(){
                                return {
                                    url: '',
                                    isFirstParam: true,
                                    set: function (url) {
                                        this.url = url;
                                        this.isFirstParam = true;
                                        return this;
                                    },
                                    get: function () {
                                        return this.url;
                                    },
                                    appendEndpoint: function (endpoint) {
                                        this.url = this.url + endpoint;
                                        return this;
                                    },
                                    appendParam: function (paramName, paramVal) {
                                        if (!this.url || this.url.length === 0) throw Error('Bad Url');
                                        if (paramName !== undefined && paramVal !== undefined) {
                                            this.url = this.url + (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                                            this.isFirstParam = false;
                                        }
                                        return this;
                                    }
                                }
                            }])
                            .factory("FormViewFactory", ['$http', '$q', 'GENERAL_CONFIG','USER_SETTINGS', 'GlobalService','requestBuilderFactory',
                             function ($http, $q, GENERAL_CONFIG,USER_SETTINGS, GlobalService,requestBuilderFactory) {
                                const formViewFactory = {
                                    formViewCache:{
                                        filters:{},
                                        entityTreeCopy:{
                                        //    FORM_:JSON.parse(localStorage.getItem('localTreeCache'))
                                        }
                                    },
                                    stateFormViewCache:{
                                        filters:{},
                                        entityTreeCopy:{
                                            // FORM_:JSON.parse(localStorage.getItem('localTreeCache'))
                                        }
                                    }
                                    
                                };

                                formViewFactory.pushPdfsToS3 = function (taxYear, scenario,jcdKey) {
                                    const promise = $http.get(requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                        .appendEndpoint('/getGtwS3View')
                                        .appendParam('leType', 'FORM')
                                        .appendParam('jcdKey', jcdKey)
                                        .appendParam('scenario', scenario)
                                        .appendParam('taxYear', taxYear)
                                        .appendParam('src_system_flag', 'D')
                                        .appendParam('formType', 'GTW')
                                        .appendParam('returnType', '')
                                        .get());
                                    return promise;
                                };
                                
                                formViewFactory.loadHierarchy = function (leType,entityType, taxYear, scenario,jcdKey,isState) {
                                    let apiMethod = isState ? 'getStateGroupView' : 'getGtwView';
                                    const promise = $http.get(requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                        .appendEndpoint('/'+apiMethod)
                                        .appendParam('leType', leType)
                                        .appendParam('entityType', entityType)
                                        .appendParam('jcdKey', jcdKey)
                                        .appendParam('scenario', scenario)
                                        .appendParam('taxYear', taxYear)
                                        .appendParam('src_system_flag', 'D')//to be removed
                                        .appendParam('formType', 'GTW')//to be removed
                                        .get());
                                    return promise;
                                };

                                formViewFactory.updateS3Status = function (taxYear,leid,leType, keyId, submissionId) {
                                    const promise = $http.post(requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                        .appendEndpoint('/updateEntityS3UploadStatus')
                                        .appendParam('taxYear', taxYear)
                                        .appendParam('leid', leid)
                                        .appendParam('leType', leType)
                                        .appendParam('key_id', keyId)
                                        .appendParam('submission_id', submissionId)
                                        .get());
                                    return promise;
                                };

                                formViewFactory.getTaxForms = function (selectedView, rptPeriod = '', leType, cdrNo = '', src_system_flag, businessType,
                                        entityType = '', selectedConsolGroup = '', taxYear, scenario, entityName = '',path, contSrc,
                                        fillingGroup = '', formType = '',key_id,submission_id,return_type,consolType, isAutoReload,jcdKey) {

                                        const promise = $http.post(requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                        .appendEndpoint('/getPdfTaxForms')
                                        .appendParam('leType', leType)
                                        .appendParam('jcdKey', jcdKey)
                                        .appendParam('scenario', scenario)
                                        .appendParam('taxYears', taxYear)//why s?
                                        .appendParam('src_system_flag',src_system_flag)
                                        .appendParam('selectedView', selectedView)
                                        .appendParam('cdrNo', cdrNo)
                                        .appendParam('reportingPeriod', rptPeriod)
                                        .appendParam('business_type', businessType)
                                        .appendParam('entity_type', entityType)
                                        .appendParam('entityName', encodeURIComponent(entityName))
                                        .appendParam('path', encodeURIComponent(path))
                                        .appendParam('contSrc', contSrc)
                                        .appendParam('filing_group', fillingGroup)
                                        .appendParam('formType', formType)
                                        .appendParam('cgkey', selectedConsolGroup)
                                        .appendParam('key_id', key_id)
                                        .appendParam('submission_id', submission_id)
                                        .appendParam('return_type', return_type)
                                        .appendParam('consol_type', consolType)
                                        .appendParam('isAutoReload', isAutoReload)
                                        .get());
                                        
                                        return promise;
                                    };

                                formViewFactory.mergePdf = function(params) {
                                    let body = 	
                                    {
                                        // "pdfMergeRequest":{
                                            "jobId":0,
                                            "irsParentFormList":params.wpIrsFormNo,
                                            "irsFormList":params.formName,
                                            "formSubList":params.formSub,
                                            "attachmentValues":params.is_attachment,
                                            "statementValues":params.is_statement,
                                            "entity":params.leId,
                                            "taxYear":params.taxYear,
                                            "cdrNo":params.cdrNo,
                                            "rptPeriod":params.rptPeriod,
                                            "leType":params.leType,
                                            "systemFlag":params.sourceSystemFlag,
                                            "scheduleList":params.schedule,
                                            "lineList": params.line,
                                            "partNoList":params.partNo,
                                            "jcdKey": params.jcdKey,
                                            "scenario":params.scenarioLocal,
                                            "leName":params.leName,
                                            "einList":params.ein,
                                            "srcType":params.srcType,
                                            "entity_type":params.selectedEntityType,
                                            "cgkey": params.selectedConsolGroup,
                                            "parentLeId":params.parentLeId,
                                            "consolType":params.consolType,
                                            "keyId": params.keyId,
                                            "submissionId": params.submission_id,
                                            "returnType":params.returnType,
                                            // "formType":,
                                            "batchId":params.batchId,
                                            "mergeAll":params.mergeAll
                                        // }
                                    }
                                    const promise =  $http({
                                        url: requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                .appendEndpoint('/mergeProforma').get(),
                                        method: 'POST',
                                        data: JSON.stringify(body),
                                        headers: {'Content-Type': 'application/json'},
                                        responseType:"arraybuffer"
                                    });

                                    return promise;
                                }

                                formViewFactory.getPdf = function (toMerge,params) {
                                    if(toMerge) return formViewFactory.mergePdf(params);
                                    // path = params.is_attachment === 1 ? 'getPdfS3AttachmentData' : path;
                                    const promise = $http.get(requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                    .appendEndpoint('/fillForm')
                                    .appendParam('irsFormNo', params.formName)
                                    // .appendParam('fileName', params.formName)
                                    .appendParam('wpIrsFormNo', params.wpIrsFormNo)
                                    .appendParam('s3Url', params.s3_url)
                                    .appendParam('formSubCat', params.formSubCat)
                                    .appendParam('formSub', params.formSub)
                                    .appendParam('leType', params.leType)
                                    .appendParam('jcdKey', params.jcdKey)
                                    .appendParam('scenario', params.scenarioLocal)
                                    .appendParam('taxYear', params.taxYear)
                                    .appendParam('systemFlag', params.sourceSystemFlag)
                                    .appendParam('leid', params.leId)
                                    .appendParam('parentLeId', params.parentLeId)
                                    .appendParam('cdrNo', params.cdrNo)
                                    .appendParam('rptPeriod', params.rptPeriod)
                                    .appendParam('schedule', params.schedule)
                                    .appendParam('line', params.line)
                                    .appendParam('partNo', params.partNo)
                                    .appendParam('entity_type', params.selectedEntityType)
                                    .appendParam('leName', encodeURIComponent(params.leName))
                                    .appendParam('contSrc', params.contSrc)
                                    .appendParam('ein', params.ein)
                                    .appendParam('consolType', params.consolType)
                                    .appendParam('cgKey', params.selectedConsolGroup)
                                    .appendParam('keyId', params.keyId)
                                    .appendParam('submissionId', params.submission_id)
                                    .appendParam('returnType', params.returnType)
                                    .appendParam('batchId', params.batchId)
                                    .appendParam('srcType', params.srcType)
                                    .appendParam('jobId', '0')
                                    .appendParam('mergeAll', params.mergeAll)
                                    .appendParam('withDrillDowns', params.withDrillDowns)
                                    .appendParam('is_attachment', params.is_attachment)
                                    .appendParam('is_statement', params.is_statement)
                                    .get(),{responseType:"arraybuffer"});
                                    
                                    return promise;
                                };

                                formViewFactory.getCombinationKeysByEntity = function (taxYear,scenario,jcdKey, leId,cdrNo,rptPeriod, leType) {
                                    return $http.post(requestBuilderFactory.set(GENERAL_CONFIG.base_url)
                                                .appendEndpoint('/loadJsonObject')
                                                .appendParam('sso_id', USER_SETTINGS.user.sso_id)
                                                .appendParam('tax_year', taxYear)
                                                .appendParam('scenario', scenario)
                                                .appendParam('jcd_key', jcdKey)
                                                .appendParam('action_id', '32036')
                                                .appendParam('leid', leId)
                                                .appendParam('cdrNo', cdrNo)
                                                .appendParam('rptPeriod', rptPeriod)
                                                .appendParam('le_type', leType)
                                                .get());
                                }
                                formViewFactory.getDomProcessList = function () {
                                    return $http.get(requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                .appendEndpoint('/getDomProcessingList')
                                                .get());
                                }

                                formViewFactory.getBulkExportForms = function (taxYear, returnType, srcType, taxPayer = '', submissionId = '', formType = '') {
                                    return $http.post(requestBuilderFactory.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                                       .appendEndpoint('/getBulkExportForms')
                                                                       .appendParam('taxYear', taxYear)
                                                                       .appendParam('submissionId', submissionId)
                                                                       .appendParam('returnType', returnType)
                                                                       .appendParam('srcType', srcType)
                                                                       .appendParam('parentLeid', taxPayer)
                                                                       .appendParam('formType', formType)
                                                                       .get());

                                }

                                formViewFactory.pdfsBulkExport = function (
                                    taxYear,
                                    scenario,
                                    jcdKey,
                                    format,
                                    mode,
                                    contSrc,
                                    body
                                ) {
                                    return $http({
                                        url: requestBuilderFactory.set(GENERAL_CONFIG.bulk_pdf_engine_api_url)
                                                              .appendEndpoint('/pdfS3BulkExport')
                                                              .appendParam('taxYear', taxYear)
                                                              .appendParam('scenario', scenario)
                                                              .appendParam('jcdKey', jcdKey)
                                                              .appendParam('mode', mode)
                                                              .appendParam('format', format)
                                                              .appendParam('contSrc', contSrc)
                                                              .get(),
                                        method: 'POST',
                                        data: JSON.stringify(body),
                                        headers: {'Content-Type': 'application/json'}
                                    });
                                };

                                formViewFactory.downloadXMLorZIP = function (
                                   submisionId,
                                   keyId,
                                   leType,
                                   fileType
                                ) {
                                    return $http({
                                        url: requestBuilderFactory.set(GENERAL_CONFIG.bulk_pdf_engine_api_url)
                                                              .appendEndpoint('/downloadXmlORZip')
                                                              .appendParam('submissionId', submisionId)
                                                              .appendParam('keyId', keyId)
                                                              .appendParam('leType', leType)
                                                              .appendParam('type', fileType)
                                                              .get(),
                                        method: 'GET',
                                        headers: {'Content-Type': 'application/json'},
                                        responseType:"arraybuffer"
                                    });
                                };

                                
                                formViewFactory.getRequestDetails = function (request_id, get_entities_forms) {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=31506&sso_id=${USER_SETTINGS.user.sso_id}&p_request_id=${request_id}&get_ents_frms=${get_entities_forms}`);
                                }

                                formViewFactory.getFormTemplate = function(rowData){
                                    return  $http({
                                        method: 'GET',
                                        url: `${GENERAL_CONFIG.bulk_pdf_engine_api_url}/getFormForS3Template`,
                                        params: {
                                            taxYear: rowData.TAX_YEAR, irsFormNo: rowData.IRS_FORM_NO, s3UrlSLTEntry: rowData.FILE_KEY,isDownloadRequest:rowData.isDownloadRequest
                                        },
                                        responseType: 'arraybuffer'
                                    })
                                }

                                formViewFactory.uploadPdf = function (taxYear,irsFormNo,fileKey,comment,file) {
                                    var fd = new FormData();
                                    fd.append('file', file);
                    
                                    const promise = $http.post(requestBuilderFactory.set(GENERAL_CONFIG.bulk_pdf_engine_api_url)
                                        .appendEndpoint('/uploadTemplate')
                                        .appendParam('tax_year', taxYear)
                                        .appendParam('irs_form_no', irsFormNo)
                                        .appendParam('fileKey', fileKey)
                                        .appendParam('comment', comment)
                                        .get(), fd, {
                                            transformRequest: angular.identity,
                                            headers: {
                                                'Content-Type': undefined
                                            }
                                        });
                                    return promise;
                                };

                                formViewFactory.exportMappingsToExcel = function(taxYear,irsFormNo) {
                                    return  $http({
                                        method: 'GET',
                                        url: `${GENERAL_CONFIG.bulk_pdf_engine_api_url}/ddToExcelExport`,
                                        params: {
                                            taxYear, irsFormNo
                                        },
                                        responseType: 'arraybuffer'
                                    })
                                }

                                 formViewFactory.createSnapshot = function(submissionId,taxYear, scenario, jcdKey) {
                                     return  $http({
                                         method: 'GET',
                                         url: `${GENERAL_CONFIG.bulk_pdf_engine_api_url}/createSnapshot`,
                                         params: {
                                             taxYear, scenario, jcdKey, submissionId
                                         }
                                     })
                                 }
                                return formViewFactory;
                            }]);
                        });
