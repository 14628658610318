/**
 * Created by 212544474 on 4/1/2016.
 * should be changed from project to Project
 */



define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.reportableController',[])
        .controller('ReportableController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'gridData' ,'JsonObjectFactory','$timeout','ReportableTransactionFactory', reportableController])
        .controller('reportableDController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'gridData' ,'JsonObjectFactory','$timeout','ReportableTransactionFactory', reportableDController])




        function reportableDController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, gridData, JsonObjectFactory,$timeout,ReportableTransactionFactory){

            var vm = this;
            vm.title = "Schedule D Reportable Transaction";
            vm.gridData =


            console.log("gridData : " , gridData);



            function init(){

                var groupKeyArr =  _.find(gridData.data, 'active');

                if( groupKeyArr.length){
                    console.log("WE HAVE A GROUP ID groupKeyArr" , groupKeyArr);
                    //IF MORE THEN ONE ERROR

                    //IF JUST ONE LOAD

                    //IF NO THEN NEW :)
                }

            }



            var reportableArray  = {

                REPT_DESC:null,
                REPT_TYPE:null,
                TAX_SHELTER:null,
                FEEDS: [{
                    LEID: null,
                    CDR_NO: null,
                    ME_CODE: null,
                    REPORTING_PERIOD: null,
                    FILING_GROUP: null,
                    LINE_NO:null,
                    SUB_LINE_NO:null,
                    DESCRIPTION:null,
                    ACCOUNTS: [

                        {
                            ACCOUNT: null,
                            BOOK_AMT: null,
                            REPT_AMT: null,
                            PENDING: 0,
                            REASON_0_AMT: null
                        },

                        {
                            ACCOUNT: null,
                            BOOK_AMT: null,
                            REPT_AMT: null,
                            PENDING: 0,
                            REASON_0_AMT: null
                        },

                        {
                            ACCOUNT: null,
                            BOOK_AMT: null,
                            REPT_AMT: null,
                            PENDING: 0,
                            REASON_0_AMT: null
                        }

                    ],

                    TOTAL_BOOK_AMT: null,
                    TOTAL_REPORTABLE_AMT: null,
                    TOTAL_SCHD: null,
                    TOTAL_REPORTABLE_SCHD: null

                }]

            };
        }



        function reportableController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, gridData, JsonObjectFactory,$timeout,ReportableTransactionFactory){

        var vm = this;
        vm.title = "Reportable Transaction";
        vm.entities = [];

        console.log("gridData --- " , gridData);

        vm.reportable = {
            "name":"",
            "key":"",
            "tax_shelter_num":"",
            "type":null,
            "total_book":0,
            "total_tax":0,
            "types":[{"name":"Loss Transactions", "value":"1"},{"name":"Listed Transactions", "value":"2"},{"name":"Confidential Transactions", "value":"3"},{"name":"Transactions of Interest", "value":"4"},{"name":"Transactions with Contractual Protection", "value":"5"}]
        }

        vm.accounts=[
            {"ACCOUNT":5797422, "ACCOUNT_DESC":"WORTHLESS STOCK DEDUCTION", "ACCOUNT_KEY" : 1 },
            {"ACCOUNT":9118000,	"ACCOUNT_DESC":"GAIN OR LOSS ON DISPOSITION OF OTHER INVESTMENTS" , "ACCOUNT_KEY" : 3},
            {"ACCOUNT":9610600,	"ACCOUNT_DESC":"GAIN/LOSS SALE OF LAND" , "ACCOUNT_KEY" : 3},
            {"ACCOUNT":9610614,	"ACCOUNT_DESC":"CAPITAL GAINS/LOSSES - PARTNERSHIPS" , "ACCOUNT_KEY" : 4},
            {"ACCOUNT":9610619,	"ACCOUNT_DESC":"CAPITAL GAINS/LOSSES - NON-PARTNERSHIPS" , "ACCOUNT_KEY" : 5},
            {"ACCOUNT":9610620,	"ACCOUNT_DESC":"LONG-TERM CAPITAL GAIN" , "ACCOUNT_KEY" : 6},
            {"ACCOUNT":9610622,	"ACCOUNT_DESC":"PARTNERSHIP-L.T. CAP GAIN" , "ACCOUNT_KEY" : 7},
            {"ACCOUNT":9610623,	"ACCOUNT_DESC":"PARTNERSHIP-L.T. CAP LOSS" , "ACCOUNT_KEY" : 8},
            {"ACCOUNT":9610650,	"ACCOUNT_DESC":"ORDINARY GAIN/LOSSES - NON-PARTNERSHIPS" , "ACCOUNT_KEY" : 9},
            {"ACCOUNT":9610651,	"ACCOUNT_DESC":"ORIDINARY GAIN/LOSSES - PARTNERSHIPS" , "ACCOUNT_KEY" : 10},
            {"ACCOUNT":9610652,	"ACCOUNT_DESC":"SEC 1231 NET LOSS" , "ACCOUNT_KEY" : 11},
            {"ACCOUNT":9610700,	"ACCOUNT_DESC":"GAIN/LOSS SALE OF PATENT OTHER INTANGIBLE ASSETS" , "ACCOUNT_KEY" : 12},
            {"ACCOUNT":9610900,	"ACCOUNT_DESC":"GAIN ON SALE OPERATING LEASE" , "ACCOUNT_KEY" : 13},
            {"ACCOUNT":9610901,	"ACCOUNT_DESC":"GAIN ON SALE SINGLE INVESTOR LEASE", "ACCOUNT_KEY" : 14},
            {"ACCOUNT":9610902,	"ACCOUNT_DESC":"GAIN ON SALE LEVERAGED  LEASE", "ACCOUNT_KEY" : 15},
            {"ACCOUNT":9610910,	"ACCOUNT_DESC":"BOOK GAIN ON SALE SECURITIZATION", "ACCOUNT_KEY" : 16},
            {"ACCOUNT":9610911,	"ACCOUNT_DESC":"AMORTIZTION OF BOOK GAIN SECURITIZATION", "ACCOUNT_KEY" : 17},
            {"ACCOUNT":9611200,	"ACCOUNT_DESC":"GAIN PURCHASE OF BONDS AND DEBENTURES", "ACCOUNT_KEY" : 18}
        ]


        vm.combined = function(account){
           return account.ACCOUNT + " - " + account.ACCOUNT_DESC;
        }


        function init(){


            //CHECK IF THERE IS MORE THEN ONE TRANSACTION_GROUP_KEY IN THE SELECTED TRANSACTION
            //IF SO CLOSE AND SEND BACK ERROR MESSAGE!



           //HERE WE NEED TO CHECK IF ANY OF THE SELECTED ELEMENT HAVE A REPORTABLE TRANSACTION_KEY
           //IF SO WE WILL NEED TO GET THERE DATA ALONG ANY OTHER TRANSACTIONS IN THE GROUP
           //

            _.forEach(gridData, function(value) {
                console.log("value " , value);
                value.data.ACCT_OBJ = null;
                vm.entities.push(_.cloneDeep(value.data));
            });


        }

        vm.cancel = function () {
            vm.entities = [];
            $uibModalInstance.dismiss('cancel');
        };


        vm.setSourceTotal = function(){
            vm.reportable.total_book = 0;
            vm.reportable.total_tax = 0;
            _.forEach( vm.entities, function(value) {
                vm.reportable.total_book += ( value.TRANS_BOOK_AMT && value.TRANS_BOOK_AMT !== "" ) ? value.TRANS_BOOK_AMT : 0  ;
                vm.reportable.total_tax +=  ( value.TRANS_ADJ_TAX && value.TRANS_ADJ_TAX !== "" ) ? value.TRANS_ADJ_TAX : 0  ;
            });
        }

        vm.removeAccount = function(_index){
            vm.entities.splice(_index, 1);
            vm.setSourceTotal();
            if( vm.entities.length < 1 ){
                vm.cancel();
            }
        }

        init();

        vm.save = function() {
            if (vm.reportableFrom.$invalid) {
                vm.reportableFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }
            if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            var data = [];
            //CREATE DATA
            _.forEach( vm.entities, function(value) {
                var obj = {};
                obj.name =  vm.reportable.name;
                obj.key =  vm.reportable.key;
                obj.tax_shelter_num =  vm.reportable.tax_shelter_num;
                obj.type =  vm.reportable.type.value;
                obj.new_account_key = value.ACCT_OBJ;
                obj.account_key = value.ACCOUNT_KEY;
                obj.combination_key = value.COMBINATION_KEY;
                obj.trans_book_amt = value.TRANS_BOOK_AMT;
                obj.zero_amount = (value.TRANS_BOOK_AMT === 0) ? value.ZERO_AMOUNT : null;
                obj.trans_adj_tax = value.TRANS_ADJ_TAX;
                data.push(obj);
            });

            reportableTransCRUD(data);
        }


        function reportableTransCRUD(_data){
            vm.crudLoading = true;
            ReportableTransactionFactory.saveReportableBatch(_data).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    AlertService.add("success", "Reportable Transaction has been updated" , 4000);
                    vm.crudLoading = false;
                    var args = {};
                    $rootScope.$emit('gridUpdate', args);
                    //$rootScope.$broadcast('gridRefresh', false);
                    vm.cancel();
                }
            });
        }


        function getTransactions(_data){

            ReportableTransactionFactory.getReportableBatch(_data).then(function (result) {
                if(result.callSuccess !== "1" ){

                    AlertService.add("error", "Error ", 4000);

                }else{

                   return _data;
                }
            });

        }


    }

    return controllers;


});