define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.stdDescService', [])
                          .factory("stdDescFactory", ['$http', 'GENERAL_CONFIG',
                              function ($http, GENERAL_CONFIG) {
                                  const stddescFactory = {};

                                  stddescFactory.getStdDesc = function () {
                                      return $http.get(GENERAL_CONFIG.base_url + '/getStdDesc');
                                  };

                                  stddescFactory.createStdDesc = function (stdDesc, systemFlag) {
                                      const promise = $http.post(GENERAL_CONFIG.base_url + '/createStdDesc', {
                                          std_desc: stdDesc,
                                          system_flag: systemFlag
                                      });

                                      return promise;
                                  };

                                  stddescFactory.updateStdDesc = function (stdDesc, systemFlag, stdDescId) {
                                    const promise = $http.post(GENERAL_CONFIG.base_url + '/updateStdDesc', {
                                        std_desc_id: stdDescId,
                                        std_desc: stdDesc,
                                        system_flag: systemFlag
                                    });
                                    return promise;
                                  };

                                  return stddescFactory;
                              }]);

    return services;
});
