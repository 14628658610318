define([
    'angular',
    './settlementMainController',
    './settlementMainService'

], function () {
    'use strict';
    return angular.module('app.settlementMain', ['app.settlementMainController','app.settlementMainService'] )


});