define([
    'angular',
    './stdDescCtrl',
    './stdDescService'
], function () {
    'use strict';
    return angular.module('app.QARStdDesc', ['app.stdDescCtrl', 'app.stdDescService'])
                  .directive('isValidStdDesc', ['$http', '$q', 'GENERAL_CONFIG', function ($http, $q, GENERAL_CONFIG) {
                    return {
                        require: 'ngModel',
                        link: function (scope, element, attrs, ngModel) {
                            ngModel.$asyncValidators.isValidStdDesc = function (modelValue, viewValue) {
                                var ctrl = scope.ctrl;
                                if (ctrl.rowData && (ctrl.rowData.QAR_DESC === viewValue)) return $q.resolve();
                                if(ctrl.data.filter(i => i.QAR_DESC === viewValue).length > 0) return $q.reject();
                                return $q.resolve();
                            };
                        }
                    };
                }])
});
