define([
    'angular'

], function () {
    'use strict';
    
    var controllers =  angular.module('app.cacheController',[])
        .controller('cacheController', ['$scope','$rootScope','$http','$state','$uibModal','$log','$timeout', 'SERVER_CONFIG','USER_SETTINGS','CacheFactory','AlertService','JsonObjectFactory','CacheFactory','GlobalService','GENERAL_CONFIG', cacheController])
        .controller('cacheModalCtrl', ['$scope','$rootScope','$state','$log','JsonObjectFactory','AlertService','$timeout','$uibModalInstance','rowData','$parse','JCDFactory','ClientAdminFactory', 'GENERAL_CONFIG','USER_SETTINGS','GlobalService', cacheModalCtrl])
        .controller('JsonDiffModalCtrl', ['$scope','$rootScope','$state','$log','JsonObjectFactory','AlertService','$timeout','$uibModalInstance','$parse','JCDFactory','ClientAdminFactory', 'GENERAL_CONFIG','USER_SETTINGS','GlobalService','CacheFactory','ObjectDiff','CacheFactory', JsonDiffModalCtrl])
        .controller('cacheViewCtrl', ['$scope','$rootScope','$state','$log','JsonObjectFactory','AlertService','$timeout','$parse','JCDFactory','ClientAdminFactory', 'GENERAL_CONFIG','USER_SETTINGS','GlobalService','CacheFactory','ObjectDiff','CacheFactory', cacheViewCtrl])
        .controller('indexDeletionCtrl', ['$scope','$rootScope','$state','$log','JsonObjectFactory','AlertService','$timeout','$parse','JCDFactory','ClientAdminFactory', 'GENERAL_CONFIG','USER_SETTINGS','GlobalService','CacheFactory','ObjectDiff','CacheFactory', indexDeletionCtrl])
        .directive('endOfRepeat', ['$timeout',endOfRepeat])
        function endOfRepeat($timeout) {
            return function(scope, element, attrs) {
                if (scope.$last) {
                    
                    $timeout(function(){
                        var cacheControlTable = $('#cacheControl').DataTable({})
                    }, 0);
                }
                
            }
        };
     
		
    function cacheModalCtrl($scope,$rootScope,$state,$log,JsonObjectFactory,AlertService,$timeout,$uibModalInstance,rowData,$parse,JCDFactory,ClientAdminFactory, GENERAL_CONFIG,USER_SETTINGS,GlobalService) {

        var vm = this;
        vm.userSettings = USER_SETTINGS;
        vm.title = "New Cache Definition";
        vm.loading = false;
        vm.cache_data = {};
        vm.validation_rule = "";
        vm.maxLength = 100;
        vm.isMaxLimitReached = false;
        vm.remainingCharacters = 100;
        vm.cacheControlTable = null;
        vm.selectedCache = '';
        //vm.client_key = 0;
        //vm.clients = [];
        
		vm.clients = GlobalService.getClients();
        vm.client = GlobalService.getClient();
		vm.client_key=vm.client.client_key
        vm.clientName = vm.client.client_name;
		
        if(vm.userSettings){
            var user = vm.userSettings.user;
            //vm.clients = user.clients;
            //vm.client_key = vm.clients[0].client_key;
            vm.logged_in_user = user.sso_id;
        }

        
        

        vm.setOutPut = function(_value){                
            vm.cache_data.proc_params_out = _value;
        }

        vm.setInPut = function(_value){            
            vm.cache_data.proc_params_in = _value;
        }

        vm.setProc = function(_value){           
            vm.cache_data.proc_call = _value;
        }
		
		vm.setFilters = function(_value){                
            vm.cache_data.filter_params = _value;
        }

        
        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.countCharacters = function(){
        	if(typeof vm.cache_data.filter_params === 'undefined'){
        		vm.remainingCharacters = 100;
        	}else {
        		vm.remainingCharacters = vm.maxLength - vm.cache_data.filter_params.length 
            	if(vm.cache_data.filter_params.length >= vm.maxLength){
            		vm.isMaxLimitReached = true;
            	}else {
            		vm.isMaxLimitReached = false;
            	}
        		
        		
        	}
        }
        

        
        vm.save = function(){

            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            if (vm.cacheFrom.$invalid) {
                vm.cacheFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }
			
			
            var data =  {};            
            vm.cache_data.client_key  = vm.client_key;            
            data.json_string = JSON.stringify(vm.cache_data);

            
            JsonObjectFactory.saveJSON(GENERAL_CONFIG.common_api_url + '/saveCache', data).then(function (data) {
                vm.loading = false;
                if (data.callSuccess !== "1"){
                    AlertService.add("error", data.errorMessage);
                } else {

                    AlertService.add("success", "Cache definition saved successfully.", 2000);					
                    $rootScope.$emit('gridUpdate', {});
                    vm.cancel();
                }
            });           
        };
		
		//Example listing
		
		vm.exampleOutList = [
            {name:"resultset:OracleTypes.CURSOR,retval:OracleTypes.VARCHAR,error_out:OracleTypes.VARCHAR"}
        ]
		
		vm.exampleInList = [
            {name:"tax_year:String:null"},
            {name:"tax_year:String:null,combination_key:String:null"},
            {name:"tax_year:String:null,jcd_key:String:null,scenario:String:null"},
            {name:"tax_year:String:null,map_type:String:null"}            
        ]
        vm.exampleProc = [
            {name:"begin gtwusr01.pkg_globaltax_data_cache.get_code_combinations(?,?,?,?);end;"},
            {name:"begin gtwusr01.pkg_globaltax_data_cache.get_all_code_combinations(?,?,?,?,?);end;"},
            {name:"begin pkg_gt_validation_rules.get_group_obj_details_data(?,?,?,?,?,?); end;"},
            {name:"begin pkg_globaltax_data_cache.get_chart_acct_map(?,?,?,?,?);end;"}
        ]
		vm.filterConditions = [
            {name:"tax_year:2016"},
            {name:"tax_year:2016,scenario:21,jcd_key:250"},
            {name:"tax_year:2016,scenario:21,jcd_key:250,source_sys:F"},
            {name:"tax_year:2016,scenario:21,jcd_key:250,source_sys:D"}            
        ]

    }

    
    function cacheController($scope, $rootScope, $http, $state, $uibModal, $log, $timeout, SERVER_CONFIG, USER_SETTINGS, CacheFactory, AlertService, JsonObjectFactory, cacheFactory,GlobalService,GENERAL_CONFIG) {

        var vm = this;
        vm.userSettings = USER_SETTINGS;
        vm.title = "Cache Control"
        vm.refreshMoment = '';
        vm.isRefresh = false;
        vm.loading = false;
        //vm.client_key = 0;
        //vm.clients = [];
        vm.cacheObjList = [];
        vm.cacheCodeComObjList = [];
        vm.tempUploadFolder = '';
        vm.switchVal = '';
        vm.use_cache='No';
        vm.enableDisable = false;
        vm.test = false;
		
		
        vm.client = GlobalService.getClient();
		vm.client_key=vm.client.client_key
        vm.clientName = vm.client.client_name;
		//console.log('vm.clientName'+vm.clientName);

        if(vm.userSettings){
            var user = vm.userSettings.user;
            //vm.clients = user.clients;
            //vm.client_key = vm.clients[0].client_key;
            vm.logged_in_user = user.sso_id;
        }        

        vm.showDiffModal = function(obj_name, params){
            cacheFactory.setCacheParams(obj_name, params);
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/admin/cacheControl/json-diff-modal.html',
                controller: 'JsonDiffModalCtrl as ctrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                }
            });

            modalInstance.result.then(
                function (selectedItem) {
                },
                function () {
                }
            );
        };

        vm.getAllCacheObj = function (clientKey) {
            if (clientKey > -1) {
                vm.loading = true;
                var data = {};
                data.client_key=clientKey;
                if(vm.cacheControlTable) {
                    vm.cacheControlTable.destroy()
                    vm.cacheControlTable = null;
                }
                cacheFactory.fetchCacheObjControlDetails(data).then(function (data) {
                    vm.loading = false;
                    if(data.data.callSuccess === "1" ){
                        if(data.data.cacheObjControlDetails){
                            vm.cacheObjList = data.data.cacheObjControlDetails;
                            vm.hasEvictAccess = data.data.hasEvictAccess;
                        }
                    }else {
                        if(data.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to retrieve Cache control details.", 4000);
                        }else{
                            AlertService.add("error", "Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
            }
        }

        $scope.$on('setEvictFilter', function(event, args) {
            vm.cacheDropdown = args.data;
        });

        vm.refreshCacheObjCall = function (objectId) {
            if (objectId > 0) {
                vm.loading = true;
                var data = {};
                data.objectId=objectId;
                cacheFactory.refreshCachedObject(data).then(function (data) {
                    vm.loading =false;
                    if(data.data.callSuccess === "1" ){
                    	vm.refresh();
                        AlertService.add("success", "Refresh operation completed successfully.", 2000);
                    }else {
                        if(data.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to perform refresh operation.", 4000);
                        }else{
                            AlertService.add("error", "Unable to perform this operation. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
            }
        }
		
		
		

        vm.enableDisableCall = function (objectId, switchVal) {
            if (objectId > 0) {
                vm.loading = true;
                vm.use_cache = 'N';
                if(switchVal) {
                    vm.use_cache = 'Y';
                }
                var data = {};
                data.objectId=objectId;
                data.useCache=vm.use_cache;
                cacheFactory.enableDisableCaching(data).then(function (data) {
                    vm.loading = false;
                    if(data.data.callSuccess === "1" ){
                        AlertService.add("success", "Enable/disable operation completed successfully.", 2000);
                    }else {
                        if(data.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to perform enable disable operation.", 4000);
                        }else{
                            AlertService.add("error", "Unable to perform this operation. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
            }
        }


        vm.getAllCacheObj(vm.client_key);

        vm.endOfRepeat= function(){
            vm.timeoutPromise = $timeout(function(){
                vm.cacheControlTable = vm.cacheControlTable || $('#cacheControl').DataTable({});
            });
            
        }

        vm.refresh = function() {
            $timeout.cancel(vm.timeoutPromise);
            if(!vm.loading)
                vm.getAllCacheObj(vm.client_key);
        };

        vm.refreshCacheObj = function(object_id) {
            vm.refreshCacheObjCall(object_id);
        };
		
		vm.removeCacheObj = function(object_id) {
            vm.removeCacheObjCall(object_id);
        };
		
		vm.downloadCacheObj = function(objectId,redisCacheKey) {
            vm.downloadCacheObjCall(objectId,redisCacheKey);
        };
		
		vm.refreshAllCacheObj = function(refreshSwitch) {
            vm.refreshAllCacheObjCall(refreshSwitch);
			vm.getAllCacheObj(vm.client_key);
        };
		
		

        vm.cacheSwitch = function(object_id, switchVal) {
            vm.enableDisableCall(object_id, switchVal);
        };

        vm.addCache = function(){
            var data = {};
            var modalObj = {};
            modalObj.template = "app/components/admin/cacheControl/cache-tpl.html";
            modalObj.controler = "cacheModalCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.openModal(modalObj,data);
			
        };
		
		

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
				
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
				vm.getAllCacheObj(vm.client_key);
            });
        };
		
		//----- Start Remove Cache ------------------//
		
		vm.removeCacheObjCall = function (objectId) {
            if (objectId > 0) {
                vm.loading = true;
                var data = {};
                data.objectId=objectId;
                cacheFactory.removeCachedObject(data).then(function (data) {
                    vm.loading = false;
                    if(data.data.callSuccess === "1" ){
                        AlertService.add("success", "Remove operation completed successfully.", 2000);
                    }else {
                        if(data.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to perform Remove operation.", 4000);
                        }else{
                            AlertService.add("error", "Unable to perform this operation. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
            }
        }
		
		//----- End Remove Cache ------------------//

		
		//----- Start Refresh All ------------------//
		vm.refreshAllCacheObjCall = function (refreshSwitch) {  
                vm.loading = true;
                var data = {};
                data.refreshSwitch=refreshSwitch;
                cacheFactory.refreshAllCachedObject(data).then(function (data) {
                    vm.loading = false;
                    if(data.data.callSuccess === "1" ){
                        AlertService.add("success", "Refresh operation completed successfully.", 2000);
                    }else {
                        if(data.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to perform Refresh operation.", 4000);
                        }else{
                            AlertService.add("error", "Unable to perform this operation. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
           
        }
		
		
		
		  
		  
		  //----- End Refresh All ------------------//
		  
		  
		  //----- Start Download Cache Object------------------//
		
		vm.downloadCacheObjCall = function (objectId,redisCacheKey) {
            if (objectId > 0) {
                vm.loading = true;
                var data = {};
                data.objectId=objectId;
                cacheFactory.downloadCachedObject(data).then(function (data) {    
					vm.loading = false;                
                    var fileObject = new Blob([data.data], { type: "text/plain" });
					//console.log(data.header.filename);
					var filename = data.headers('filename'); 
                    var downloadLink = angular.element('<a></a>');
                    downloadLink.attr('href',window.URL.createObjectURL(fileObject));
                    downloadLink.attr('target','_self');
                    downloadLink.attr('download', filename+'.txt');
                    downloadLink[0].click();
					
                });
            }
        }

        vm.evictAllCache = function (){
            console.log("evicted all cache");
            vm.evictCacheLoading = true;
            var url = "/evictAllCache";
            $http({
                method: "GET",
                url: GENERAL_CONFIG.common_api_url + url,
                data: {}
            }).then(function (response) {
                vm.evictCacheLoading = false;
                if(response.data.callSuccess === 1){
                    AlertService.add("success", "All cache evicted", 4000);
                }else {
                    if(response.data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            },function(e){
                vm.evictCacheLoading = false;
                AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
            }); 

        }
        
        vm.evictSelectedCache = function (selection){
            console.log("evicted selected cache", selection,'asdfa', vm.selectedCache);
            vm.evictCacheLoading = true;
            var url = "/evictCacheObj?cacheName=" + vm.selectedCache;
            $http({
                method: "GET",
                url: GENERAL_CONFIG.common_api_url + url,
                data: {}
            }).then(function (response) {
                vm.evictCacheLoading = false;
                if(response.data.callSuccess === 1){
                    AlertService.add("success", "All cache evicted", 4000);
                }else {
                    if(response.data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            },function(e){
                vm.evictCacheLoading = false;
                AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
            });
        }
		//----- End Download Cache Object ------------------//

       
    } 
	
	function JsonDiffModalCtrl($scope,$rootScope,$state,$log,JsonObjectFactory,AlertService,$timeout,$uibModalInstance,$parse,JCDFactory,ClientAdminFactory, GENERAL_CONFIG,USER_SETTINGS,GlobalService,CacheFactory,ObjectDiff,cacheFactory) {

        var vm = this;
        vm.jsonDiff = "JSON Difference Modal";
        vm.yourObjectOne = null;
        vm.yourObjectOneCount = 0;
        vm.yourObjectTwo = null;
        vm.yourObjectTwoCount = 0;
        vm.showJsonLoader = true;
        vm.data = cacheFactory.getCacheParams();
        getData(vm.data.obj_key, vm.data.params);

        function getData(obj_name, params){
            cacheFactory.getDataFromCache(obj_name, params).then(function (data) {
                vm.yourObjectOne = data;            
                vm.yourObjectOneCount = data.cachedData ? data.cachedData[0].CachedObjectSize : {};
                if(vm.yourObjectOne !== null && vm.yourObjectTwo !== null){
                    showDiff();
                }
            });

            cacheFactory.getDataFromDB(obj_name, params).then(function (data) {
                vm.yourObjectTwo = data;
                vm.yourObjectTwoCount = data.cachedData ? data.cachedData[0].CachedObjectSize : {};
                if(vm.yourObjectOne !== null && vm.yourObjectTwo !== null){
                    showDiff();
                }
            });
        };

         vm.cancel = function(){
             closeModal();
         }

         function closeModal(){
             $uibModalInstance.dismiss('cancel');
         }

         function showDiff (){
            vm.showJsonLoader = false;
            // This is required only if you want to show a JSON formatted view of your object without using a filter
            vm.yourObjectOneJsonView = ObjectDiff.objToJsonView(vm.yourObjectOne);
            vm.yourObjectTwoJsonView = ObjectDiff.objToJsonView(vm.yourObjectTwo);

            // you can directly diff your objects js now or parse a Json to object and diff
            var diff = ObjectDiff.diffOwnProperties(vm.yourObjectOne, vm.yourObjectTwo);
            
            // you can directly diff your objects including prototype properties and inherited properties using `diff` method
            var diffAll = ObjectDiff.diff(vm.yourObjectOne, vm.yourObjectTwo);

            // gives a full object view with Diff highlighted
            vm.diffValue = ObjectDiff.toJsonView(diff);
            
            // gives object view with onlys Diff highlighted
            vm.diffValueChanges = ObjectDiff.toJsonDiffView(diff);
        };
    }

    function cacheViewCtrl($scope,$rootScope,$state,$log,JsonObjectFactory,AlertService,$timeout,$parse,JCDFactory,ClientAdminFactory, GENERAL_CONFIG,USER_SETTINGS,GlobalService,CacheFactory,ObjectDiff,cacheFactory) {
        var vm = this;
        vm.clients = GlobalService.getClients();
        vm.client = GlobalService.getClient();
		vm.client_key=vm.client.client_key
        vm.clientName = vm.client.client_name;
        vm.cacheDropdownObj = {};
        vm.cacheDropdown = [];
        vm.keyData = [];
        vm.cacheKeyJson = {};
        vm.taskModel;
        vm.showJSON = false;
        vm.showJsonLoader = false;
        vm.showSelectMsg = true;

        function getCacheData(){
            var data = {};
            data.client_key = vm.client_key;
            cacheFactory.getSelectedCacheDropdown(data).then(function (data) {
                vm.cacheDropdownObj = data.CacheNameAllKeyName;
                angular.forEach(vm.cacheDropdownObj, function(value, key) {
                    vm.cacheDropdown.push(key);
                });
                $rootScope.$broadcast('setEvictFilter', {data : vm.cacheDropdown});
            });
        };

        vm.getAllCacheData = function(selectedDropdown){
            vm.keyData = vm.cacheDropdownObj[selectedDropdown];
            console.log(vm.keyData);
        };

        vm.getKeyJson = function(cache_name, key){
            vm.showSelectMsg = false;
            vm.showJSON = false;
            vm.showJsonLoader = true;
            cacheFactory.getCacheKeyJson(cache_name, vm.keyData[key]).then(function (data) {
                vm.cacheKeyJson = data.jsonObject;
                showDiff();
                vm.showJSON = true;
                vm.showJsonLoader = false;
            });
        };

        function showDiff (){
            vm.showJsonLoader = false;
            // This is required only if you want to show a JSON formatted view of your object without using a filter
            vm.cacheKeyJsonView = ObjectDiff.objToJsonView(vm.cacheKeyJson);
        };

        getCacheData();
    }

    function indexDeletionCtrl($scope,$rootScope,$state,$log,JsonObjectFactory,AlertService,$timeout,$parse,JCDFactory,ClientAdminFactory, GENERAL_CONFIG,USER_SETTINGS,GlobalService,CacheFactory,ObjectDiff,cacheFactory) {
        var vm = this;
        vm.clients = GlobalService.getClients();
        vm.client = GlobalService.getClient();
		vm.client_key=vm.client.client_key
        vm.clientName = vm.client.client_name;
        vm.deletionLoader = false;
        vm.indexDeletionDropdown = [{
            label : 'Delete All',
            value : true
        },{
            label : 'Delete None',
            value : false
        }];
        //vm.selectedType = vm.indexDeletionDropdown[1];

        vm.performDeletion = function(){
            vm.deletionLoader = true;
            cacheFactory.deleteIndexes(vm.selectedType).then(function (data) {
                vm.deletionLoader = false;
                // if (data.callSuccess !== "1"){
                //     AlertService.add("error", data.errorMessage);
                // } else {
                    AlertService.add("success", "Indexes deleted successfully.", 2000);
                //}
            });
        };
    }

    return controllers;

});