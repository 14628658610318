define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.schedule-EService',[])
        .factory("scheduleF5471EServiceFactory",['$q','$http','$rootScope','$log','JsonObjectFactory','AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams','GENERAL_CONFIG', function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
        	var scheduleF5471EServiceFactory = {};
            var returnObj = {};
            var logged_in_user = USER_SETTINGS.user.sso_id;
           // scheduleF5471EServiceFactory.url.save_schedule_E = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=av6zsu";
            
            
            scheduleF5471EServiceFactory.saveSchedule5471SchdE = function(_callName, _data){
            	var defer = $q.defer();
            	
            	console.log("data", _data);
            	console.log("call name", _callName);
            	$rootScope.$broadcast('gridRefresh', false); 
            	
            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				var jsonSettings = [{"tax_year":filterParams.tax_year,"scenario":filterParams.scenario,"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
				
				var editRow = [];
	        	var addRow = [];
	        	var removeRow = [];
	        	if(_data.col_id == "COL_G")
	        	{
	        		var tempObj = [{
	        			"tax_year": filterParams.tax_year,
						"trans_type_key":28,
						"combination_key":_data.rowData.COMBINATION_KEY, //vm.rowData.COMBINATION_KEY,
						"group_obj_key": _data.rowData.GROUP_OBJ_KEY,
						"form_key":_data.rowData.FORM_KEY,
						"line_no":_data.rowData.LINE_NO,
						"occurence":_data.rowData.OCCURRENCE,
						"line_attrib_key" : _data.rowData.CHECKBOX_LINE_ATTRIB_KEY,
						"Attribute_Name": _data.rowData.CHECKBOX_ATTRIB_NAME,
						"Attribute_Value": _data.rowData.COL_G,
						"override_y_n" :_data.rowData.CHECKBOX_OVERRIDE_Y_N,
						"trans_dtls_key" : ((_data.rowData.CHECKBOX_TRANS_DETAILS_KEY == 0) ? null : _data.rowData.CHECKBOX_TRANS_DETAILS_KEY),
						"trans_status":"A"}];
					editRow.push(tempObj);
	        	}
	        	else
	        	{
	        		if(_data.oldRowData.COL_F !== _data.rowData.COL_F)
	        			_data.rowData.AMT_OVERRIDE_Y_N = 'Y';
	        		else
	        			_data.rowData.AMT_OVERRIDE_Y_N = _data.rowData.AMT_OVERRIDE_Y_N;	
					var tempObj = [{
	        			"tax_year": filterParams.tax_year,
						"trans_type_key":28,
						"combination_key":_data.rowData.COMBINATION_KEY, //vm.rowData.COMBINATION_KEY,
						"group_obj_key": _data.rowData.GROUP_OBJ_KEY,
						"form_key":_data.rowData.FORM_KEY,
						"line_no":_data.rowData.LINE_NO,
						"occurence":_data.rowData.OCCURRENCE,
						"line_attrib_key" : _data.rowData.AMT_LINE_ATTRIB_KEY,
						"Attribute_Name": _data.rowData.AMT_ATTRIB_NAME,
						"Attribute_Value": _data.rowData.COL_F,
						"override_y_n" : _data.rowData.AMT_OVERRIDE_Y_N,
						"trans_dtls_key" : ((_data.rowData.AMT_TRANS_DETAILS_KEY == 0) ? null : _data.rowData.AMT_TRANS_DETAILS_KEY),
						"trans_status":"A"}];
					editRow.push(tempObj);
	        	}
        		
        			
        		var jsonObj = {"addrows":[], "editrows":editRow, "removerows":removeRow};
                
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
				params = _.extend({
					jsonSettings: JSON.stringify(jsonSettings)
				}, params);
				params =  _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
	         
	           params.combination_key = _data.rowData.COMBINATION_KEY;
	           params.jcd_key = (GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key;
	           params.process_name =  "Save_schd_E";
	          
	           var service = $injector.get('JsonObjectFactory');
	           console.log("params----", params);
	          var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=av6zsu";
	          
	         service.saveJSON(url , params).then(function(data){
	        	 if(data.callSuccess === "1"){
                     AlertService.add("success", "Data saved Successfully.",4000);
                     defer.resolve(data);
                 }else{
                     AlertService.add("error", data.errorMessage);
                     defer.reject(data);
                 }
	        	 var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
                 $rootScope.$emit('gridUpdate', args);
             },function(data){
                  defer.reject(data);
               });  
	         return defer.promise;
	         
             $rootScope.$emit('gridUpdate', {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}});
            }
            
            return scheduleF5471EServiceFactory;
        }]);

    return services;
});

//$rootScope.$broadcast('gridRefresh', false); 

 /*var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
 var jsonSettings =  JSON.stringify(_data);
 var jsonObj = JSON.stringify(Details);
     //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
 var params = filterParams;
 params = _.merge({},  GlobalService.globalParams , params);
 params =  _.extend({jsonObj:jsonObj}, params);
 params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
 params.process_name =  "Schedule_E_Save";
 var promise = $http({
	   method: "post",
     url: scheduleF5471EServiceFactory.url.save_schedule_E,
     data: params
 }).then(function (response) {
     var status = response.status;
     return response.data;
 });

 return promise;*/








