
define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.schPSaveService', [])

        .factory('schPSaveServiceFactory', ['$q', '$http', '$log', '$rootScope', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'GENERAL_CONFIG', '$filter', '$injector', function ($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, $filter, $injector) {
            var schPSaveServiceFactory = {};
            schPSaveServiceFactory.url = {};

            schPSaveServiceFactory.saveDetails = function (_data, Details) {
                schPSaveServiceFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=osd69j";
                schPSaveServiceFactory.process_name = "Save INS Details";

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings = JSON.stringify(_data);
                var isIssueKeyEnabled = GlobalService.inputs.tax_year.selected.is_issue_key_enabled;
                if (isIssueKeyEnabled === undefined) {
                    isIssueKeyEnabled = GlobalService.globalParams.is_issue_key_enabled;
                }
                var scenarioCode = 'RAF';
                if($filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0]) {
                    scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE;
                }
                if (isIssueKeyEnabled === undefined) {
                    if (scenarioCode !== undefined) {
                        if (scenarioCode === 'RAF') {
                            isIssueKeyEnabled = 'N';
                        }
                        else {
                            isIssueKeyEnabled = 'Y';
                        }
                    }
                }

                console.log(jsonSettings);
                var params = filterParams;
                params.combination_key = _data.combination_key;
                params = _.merge({}, GlobalService.globalParams, params);
                params = _.extend({ clob_data: jsonObj }, params);
                params = _.extend({ clob_settings: jsonSettings }, params);
                params.process_name = schPSaveServiceFactory.process_name;
                params.scenarioCode = scenarioCode;
                params.is_issue_key_enabled = isIssueKeyEnabled;
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: schPSaveServiceFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            schPSaveServiceFactory.updateSchPData = function (_callName, _data, isNew) {
                var clob_setting = {};
                var clob_data = [];
                var params = {};
                var defer = $q.defer();
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nak559";
                clob_data.push(_data.rowData);
                clob_setting = {tax_year: _data.rowData.TAX_YEAR};
                clob_setting  = _.merge({}, GlobalService.globalParams, clob_setting);
                clob_setting.grid_name = _data.rowData.GRID_NAME;
                
                params.process_name = "SCH_P_EDIT_SAVE";
                params = _.extend({
                    clob_data: JSON.stringify(clob_data)
                }, params);
                params = _.extend({
                    clob_setting: JSON.stringify(clob_setting)
                }, params)
                params = _.merge({},GlobalService.globalParams, params);
                
                service.saveJSON(url, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        AlertService.add("success", "Data saved Successfully.", 4000);
                            var args = {
                                tax_year: _data.rowData.TAX_YEAR
                            };
                            $rootScope.$emit('gridUpdate', args);
                            defer.resolve(data);
                    } else if(data.errorMessage && data.errorMessage != "") {
                        AlertService.add("error", data.errorMessage, 4000);
                        defer.reject(data);
                    }else {
                        AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                        defer.reject(data);
                    }
                });
            }

            return schPSaveServiceFactory
        }])

    return services;

});