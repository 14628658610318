define([
    'angular'

], function () {
    'use strict';



    var controllers =  angular.module('app.genericFormScreenControllers',[])
       .controller('formScreenCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','USER_SETTINGS','GenericFormServicesFactory','$uibModalInstance','rowData', formScreenCtrl])

       function formScreenCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,USER_SETTINGS,GenericFormServicesFactory,$uibModalInstance,rowData) {
    	 	var vm = this;
    	 	vm.formDataWithValues = [];
            console.log("Row Data :",rowData);
            
      	 	vm.getFormData = function(){
               console.log("insdie getFormData ::");
               vm.loading = true;
               var data = {};
               data.combination_key = "243563";
               data.group_obj_key= "201473";
               data.tax_year= "2014";
               data.trans_type_key = "7";
               data.irs_form_no = "F_4797";
               console.log("getFormData data ::",data);
               GenericFormServicesFactory.getFormScreenData(data).then(function (data) {
                   vm.loading = false;
                   vm.formDataWithValues = data.jsonObject;
                   console.log("formDataWithValues ::",vm.formDataWithValues);
                });
            }
    	 	vm.saveFormData = function(){
    	 		console.log("wait for sometime");
    	 	}
    	 	
    	 	vm.close = function(){
                $uibModalInstance.dismiss('cancel');
            }
    	 	
    	 	vm.getFormData();
    	 	
       }
    return controllers;

});
