/**
 * Created by 212544474 on 2/17/2016.
 */

define(
		[ 'angular'],
		function() {
			'use strict';

			var services = angular
					.module('app.triggerghostsourcingService', [])

					.factory("TriggerGhostSourcingFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'AlertService', '$injector', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG','USER_SETTINGS',
						function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, AlertService, $injector, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,USER_SETTINGS) {
							var triggerghostsourcingFactory = {};
							triggerghostsourcingFactory.url = {};
							triggerghostsourcingFactory.url.rerun = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=jx71so";

							triggerghostsourcingFactory.triggerSourcing = function(_data,Details){
									
								var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
								var jsonObj = JSON.stringify(Details);
								var jsonSettings =  JSON.stringify(_data);
								//read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
								
								console.log(jsonSettings);
								var params = filterParams;
								params.combination_key = _data.combination_key;
								params.sso_id = _data.sso_id;
								params = _.merge({},  GlobalService.globalParams , params);
								params =  _.extend({clob_data:jsonObj}, params);
								params =  _.extend({clob_settings:jsonSettings}, params);
								params.process_name =  "TRIGGER_GHOST_DEFAULT_SOURCING";
								console.log(params);
								var promise = $http({
									method: "post",
									url: triggerghostsourcingFactory.url.rerun,
									data: params
								}).then(function (response) {
									var status = response.status;
									return response;
								});
								return promise;
							};
							return triggerghostsourcingFactory;
						}]);
			        
					return services;

		});