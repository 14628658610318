define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.EpDrilldownService',[])
       .factory("EpDrilldownScreenFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   		,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var EpDrilldownScreenFactory ={};
	           EpDrilldownScreenFactory.url ={};
	           EpDrilldownScreenFactory.url.getEpDrilldownScreenData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=amx4gr";//Action id 1213
	          
	           EpDrilldownScreenFactory.getEpDrilldownScreenData = function(passedParams) {
					var params = {"process_name":"get EpDrilldown Screen Data"};
				   params = _.merge({},  passedParams);
		           var promise = $http({
		        	   method: "post",
		               url: EpDrilldownScreenFactory.url.getEpDrilldownScreenData,
		               data: params
		           }).then(function (response) {
		               //var status = response.status;
		               return response.data;
		           });
		
		           return promise;
	           };
           
			  
	           return EpDrilldownScreenFactory;
       }]);

   	  return services;
});