define(
    [ 'angular'

        ],
        function() {
        'use strict';

        var controllers = angular.module('app.controversyAuditsCtrl', [])
        .controller('controversyFederalCtrl', [ controversyFederalCtrl])


        function controversyFederalCtrl() {
            var vm = this;
            vm.showPage = "profile";

            vm.openPage = function(page) {
                vm.showPage = page;
            }

        }
        return controllers;
    })