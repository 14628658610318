define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.Form1118SchdJController', [])
					.controller('scheduleJCtrlNEW', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'Form1118SchdJFactory', 'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG','$timeout', scheduleJCtrlNEW])						    
			function scheduleJCtrlNEW($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, USER_SETTINGS, Form1118SchdJFactory, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG,$timeout) {
				var vm = this;
				
				//Get the Loggin USER settings 
				vm.userSettings = USER_SETTINGS;
				//Set the Loggin USER settings details
				vm.logged_in_user = vm.userSettings.user.sso_id;

				//Get the selected Current Year 
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {"tax_year":GlobalService.globalParams.tax_year};
				vm.curentyear = filterParams.tax_year;

				//Form Lines passed to the UI
				vm.groupedFormLines;
				//Form Lines passed to the UI
				vm.groupedFormLinesPartII;
				//Form Lines passed to the UI
				vm.groupedFormLinesPartIII;
				//Form Lines passed to the UI
				vm.groupedFormLinesPartIV;
				//JCD Key for the given custom screen
				vm.jcd_key;
				//JCD TA Key for the given custom screen
				vm.jcd_ta_key;
				//Hard-coded Basket value
				vm.sourceKey;
				//Show the spinner
				//vm.showSpinner = true;
				//Flag for Is_Admin check
				vm.isAdmin = false; 
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//Current screen's activity name
				vm.activity_name = workspaceFactory.activity.label;
				//Get the Grid filter Scenario
				vm.scenario = GlobalService.globalParams.scenario;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;

				vm.filteredTaxYearData = [];	
				vm.filterLoading = false;
				vm.selectedScenario;
				vm.showBanner = true;
				
				vm.partOnesubTotalLines = ['3', '6', '8', '11'];
				vm.subTotalLinesPartII = null;
				vm.subTotalLinesPartIII = ['5'];
				vm.subTotalLinesPartIV = ['4','6'];

				vm.recaptureLinesPartIII = ['4'];
				vm.recaptureLinesPartIV = ['5'];
				
				//Redirect the URL to a screen based on workspace url link
				vm.redirectUrl = function(selectedScreenLink){	
					var redirectLink = 'workspace.'+vm.activity_name+'-1118-'+selectedScreenLink;					
					$state.transitionTo(redirectLink.toLowerCase(), null, {'reload':true});					
				}
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
					"action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
				};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;						
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							//Set the fields to Readonly mode
							vm.isAdmin = false;
						}							
					});
				});

				//Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {	
					vm.scenarioLoading = true;
					//resetFilingGroup();
					var params = {"action_code" : '7q0wiy',"tax_year" : vm.selectedTaxYear.value};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;		
						vm.selectedScenario = vm.scenarioDropDown[0];	
						vm.getFilingGroupDropdown();	
						vm.scenarioLoading = false;
						vm.filterLoading = false;		
					});					
				};

				//Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {
					vm.filteredTaxYearData = GlobalService.inputs.tax_year.values;
					vm.selectedTaxYear = GlobalService.inputs.tax_year.selected;	
					vm.getScenarioDropDown();
					// var params = {"action_code" : 'e83e1x',};					
					// JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
					// 	let taxYearData = _.filter(data.jsonObject, function(p){
	                // 		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                // 	});							
					// 	for(var indx in taxYearData){
					// 		if(taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
					// 				&& (taxYearData[indx].CODE_DISP_DESC <= (vm.curentyear) && taxYearData[indx].CODE_DISP_DESC >= (vm.curentyear-5))){
					// 			vm.filteredTaxYearData.push(taxYearData[indx]);			
					// 		}
					// 	}
					// 	vm.filteredTaxYearData = vm.filteredTaxYearData.reverse();
					// 	vm.selectedTaxYear = vm.filteredTaxYearData[0];
					// 	vm.getScenarioDropDown();		
					// });					
				};
				vm.getTaxYearDropDown();

				$scope.$watch(function(){ return workspaceFactory.screens}, function(val){
		            vm.workspaces = workspaceFactory.getScreen();
		            $timeout(function() {
		                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
		                    vm.scrlTabsApi.init();
		                }
		            });
		        });
			
			  vm.tabs = [];
		        vm.updateFn = function(arg){
		            var obj = [];
		            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
		            vm.setTab(obj);
		        }

				//Get the Filing Group dropdown
				vm.getFilingGroupDropdown = function() {	
					if (vm.selectedTaxYear && vm.selectedScenario) {
						vm.filingGrpLoading = true;
						// Reset Filer entity first
						//resetFilerEntity();
						var params = {"action_code" : '0udsuc',"tax_year" : vm.selectedTaxYear.value,
						"scenario" : vm.selectedScenario.VALUE};					
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.filingGroupDropdown =  data.jsonObject;	
							//vm.filingGroupDropdown.unshift({"VALUE":"","LABEL":"Select"});	
							vm.selectedFG = vm.filingGroupDropdown[0];						
							vm.filingGrpLoading = false;
							vm.getFilerEntityDropdown();
					});	
					}			
				};

				//Get the Filer Entity dropdown
				vm.getFilerEntityDropdown = function() {		
					//resetFilerEntity();
					if (vm.selectedFG.VALUE != '') {
						vm.filerEntityLoading = true;
						var params = {"action_code" : '35gc5l',"tax_year" : vm.selectedTaxYear.value,
						"scenario" : vm.selectedScenario.VALUE, "filing_group_key" : vm.selectedFG.VALUE};					
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							let filerEntityArr = data.jsonObject;
							vm.filerEntityDropdown =  Object.assign([], _.filter(filerEntityArr, {CONSOL_GROUP_KEY: vm.selectedFG.VALUE}));	
							vm.selectedFE = vm.filerEntityDropdown[0];	
							//vm.filerEntityDropdown.unshift({"FILER_LE_KEY":"","FILER_LE":"Select"});
							vm.filerEntityLoading = false;
						});		
					}	
//					vm.selectedFE = vm.filerEntityDropdown[0];	
				};

				function resetFilingGroup() {
					resetFilerEntity();
					vm.filingGroupDropdown = [{"VALUE":"","LABEL":"Select"}];
					vm.selectedFG = vm.filingGroupDropdown[0];	
				}

				function resetFilerEntity() {
					vm.filerEntityDropdown = [{"FILER_LE_KEY":"","FILER_LE":"Select"}];
					vm.selectedFE = vm.filerEntityDropdown[0];	
				}
				
				
				vm.refresh = function() {
					console.log("filter clicked");
					if (vm.selectedFE == undefined || vm.selectedFE.FILER_LE_KEY == '') {
						AlertService.add("error", "Please select all the filters.",4000);
						return;
					}
					vm.showBanner = false;
					vm.showSpinner = true;
					vm.getFormColumnDescriptions();
					vm.getFormLinesAndDescriptions();
				}		
				
				//Get the Schd-J Column Descriptions
				vm.getFormColumnDescriptions = function() {
					vm.formColumnsPartI = null;
					vm.formColumnsPartII = null;
					vm.formColumnsPartIII = null;
					vm.formColumnsPartIV = null;
					var params = {"action_code" : 'v3uakt', 'tax_year' : vm.selectedTaxYear.value};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							let formAllColumns = data.jsonObject;
							let formColumnsByPart = _.groupBy(formAllColumns, 'CODE_GROUP');
							vm.formColumnsPartI = formColumnsByPart['F1118_JPI'];
							vm.formColumnsPartII = formColumnsByPart['F1118_JPII'];
							vm.formColumnsPartIII = formColumnsByPart['F1118_JPIII'];
							vm.formColumnsPartIV = formColumnsByPart['F1118_JPIV'];
							console.log(vm.formColumnsByPart);
					}); 
				}
				
				
				//Get the Schd-J Line Descriptions
				vm.getFormLinesAndDescriptions = function() {	

					vm.groupedFormLines = null;
					vm.groupedFormLinesPartII = null;
					vm.groupedFormLinesPartIII = null;
					vm.groupedFormLinesPartIV = null;
					// Always General in case of schedule J
					vm.basketToFetch = 'GENLIM';				
					var params = {"action_code" : 'uhmejk', "basket" : vm.basketToFetch, 
							"tax_year" : vm.selectedTaxYear.value,
							"scenario" : vm.selectedScenario.VALUE,  "filer_entity_key" : vm.selectedFE.FILER_LE_KEY};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						let formLinesAllPart = data.jsonObject;	
						let formLinesByPart = _.groupBy(formLinesAllPart, 'CODE_GROUP');
						let formLines = formLinesByPart['F1118_JPI'];	
						groupLineCols(formLines, 'I', vm.partOnesubTotalLines);						
						let formLinesPartII = formLinesByPart['F1118_JPII'];
						groupLineCols(formLinesPartII, 'II', vm.subTotalLinesPartII);	
						let formLinesPartIII = formLinesByPart['F1118_JPIII'];
						groupLineCols(formLinesPartIII, 'III', vm.subTotalLinesPartIII, vm.recaptureLinesPartIII);	
						let formLinesPartIV = formLinesByPart['F1118_JPIV'];
						groupLineCols(formLinesPartIV, 'IV', vm.subTotalLinesPartIV, vm.recaptureLinesPartIV);
					}); 
				}	

				$scope.$watchGroup(['filterLoading', 'filingGrpLoading', 'filerEntityLoading'], function(newValues, oldValues, scope) {
					if (vm.filterLoading == true || vm.scenarioLoading == true || vm.filingGrpLoading == true || vm.filerEntityLoading == true) {
						vm.showBanner = true;
					}
					//vm.showBanner = newValues.some(function(value) {return value == true;});
					console.log('watchgroup: ' + vm.showBanner);
				});
				
				//TODO: REVISIT
				$scope.$watch(function() { return JCDFactory.JCDList },   function() {
					if(JCDFactory.JCDList!=undefined && JCDFactory.JCDList!=null && JCDFactory.JCDList.length > 0){											
						var b = _.find(JCDFactory.JCDList, function(o) { return o.ACTIVITY_NAME.includes(workspaceFactory.activity.label); });							
						//Get the JCD Core details
						vm.jcd_key = b.JCD_KEY;
						vm.jcd_ta_key = b.JCD_TA_KEY;
						//vm.task_name = b.TASK_NAME;	
					}
				});
				
				//Load the System Account Details
				//$scope.$watch(function() { return formLines },   groupLineCols(vm.formLines));

				function groupLineCols(formLines, partNo, subTotalLines, recaptureLines) {
					
					let groupedFormLines;
					if(formLines!=undefined || formLines!=null){					
						//assign the is_admin flag
						if(formLines[0] && formLines[0].IS_ADMIN == 'Y'){
							vm.isAdmin = true;
						}
						if (partNo == 'I') {
							vm.groupedFormLines = groupLineAndColumns(formLines, partNo, subTotalLines, recaptureLines);
						} else if (partNo == 'II') {
							vm.groupedFormLinesPartII = groupLineAndColumns(formLines, partNo, subTotalLines, recaptureLines);
						} else if (partNo == 'III') {
							vm.groupedFormLinesPartIII = groupLineAndColumns(formLines, partNo, subTotalLines, recaptureLines);
						}	else if (partNo == 'IV') {
							vm.groupedFormLinesPartIV = groupLineAndColumns(formLines, partNo, subTotalLines, recaptureLines);
						}		
						
						// groupedFormLinesObj = groupLineAndColumns(formLines, partNo, subTotalLines);
						//console.log("groupedFormLines: ",groupedFormLines);
						
						//console.log("vm.sourceKey: - - > ",vm.sourceKey);
						vm.addTotals(partNo);
						vm.showSpinner = false;
						//console.log("vm.showSpinner: - - > ",vm.showSpinner);
					}	
					//return groupedFormLinesObj;				
				}

				function groupLineAndColumns(formLines, partNo, subTotalLines, recaptureLines) {
					var init = 0;	
					return _.reduce(
						formLines, 
						function(memo, property) {
							// Find the correct bucket for the property
							var bucket = _.find(memo, {
								SCHD_KEY: property.SCHD_KEY,	
								LINE_KEY: property.LINE_KEY, 
								LINE_NO: property.LINE_NO,
								LINE_DESCRIPTION: property.LINE_DESCRIPTION,
								LINE_ORDER: property.LINE_ORDER,//TODO: IS BUCKET DETAILS REQUIRED?							        	
								BASKET_KEY: property.BASKET_KEY,	
								BASKET_DESC: property.BASKET_DESC				        							        	
							});							        
							//console.log("bucket :", bucket);
							
							// Create a new bucket if needed.
							if (!bucket) {
								bucket = {
										SCHD_KEY: property.SCHD_KEY,	
										LINE_KEY: property.LINE_KEY,
										LINE_NO: property.LINE_NO,
										LINE_DESCRIPTION: property.LINE_DESCRIPTION,
										LINE_ORDER: property.LINE_ORDER,							        	
										BASKET_KEY: property.BASKET_KEY,//TODO: IS BUCKET DETAILS REQUIRED?
										BASKET_DESC: property.BASKET_DESC,									        	
										items: []};
								//if(bucket.LINE_NO != 'N/A'){
									memo.push(bucket);
								//}
							}
							//console.log("memo :", memo);
							
							var attribs = {
									SCHD_KEY: property.SCHD_KEY,	
									LINE_KEY: property.LINE_KEY, 
									BASKET_KEY: property.BASKET_KEY,
									COL_KEY: property.COL_KEY,
									COL_DESCRIPTION: property.COL_DESCRIPTION,
									COL_ORDER: property.COL_ORDER,
									IS_ENABLED: property.IS_DISPLAY,							        		
									ATTRIB_AMOUNT: property.AMOUNT
							};
							//console.log("attribs :", attribs);
							if( init == 0 && (attribs.BASKET_KEY != undefined && attribs.BASKET_KEY != null && attribs.BASKET_KEY != '')){
								init = 1;
								vm.sourceKey = attribs.BASKET_KEY;
							}
							if(attribs.ATTRIB_AMOUNT != null){
								attribs.OPERATION_TYPE = 'U';
							} else {
								attribs.OPERATION_TYPE = null;
							}	
							
							if (isSkipFromSaving(bucket, attribs, subTotalLines, recaptureLines)) {
								//attribs.IS_SUB_TOTAL = true;
								attribs.OPERATION_TYPE = 'N'; // no crud
							} 
							// Add the property to the correct bucket
							bucket.items.push(attribs);
							return memo;
						}, 
						[] // The starting memo
					);
				}

				function isSkipFromSaving(bucket, attribs, subTotalLines, recaptureLines) {
					if (attribs.IS_ENABLED == 'N' 
							|| (subTotalLines && _.findIndex(subTotalLines, { LINE_NO: bucket.LINE_NO }) != -1)
							|| (recaptureLines && _.findIndex(recaptureLines, { LINE_NO: bucket.LINE_NO }) != -1)) {
								//attribs.IS_SUB_TOTAL = true;
								return true;
					} 
					return false; 
				}
				
				//Reset the form
				vm.reset = function(){	
					resetSection(vm.groupedFormLines);
					resetSection(vm.groupedFormLinesPartII);
					resetSection(vm.groupedFormLinesPartIII);
					resetSection(vm.groupedFormLinesPartIV);
					vm.getFormLinesAndDescriptions();
				};

				function resetSection(groupedFormLines) {
					for(var d in groupedFormLines){						
						for(var a in groupedFormLines[d].items){						
							groupedFormLines[d].items[a].ATTRIB_AMOUNT = null;
						}
					}
				}
				
				function addLines(groupedFormLinesObj, totalsLineNo, ...inputLineNos) {
					// Loop through the lines
					// Loop through the attributes
					
					var totalsLineIndex = _.findIndex(groupedFormLinesObj, { LINE_NO: totalsLineNo + '.' });
					var isFirstLine = true;
					for (var inputLineNoIndex in inputLineNos){ // loop lines
						var currentLineIndex = _.findIndex(groupedFormLinesObj, { LINE_NO: inputLineNos[inputLineNoIndex] + '.' });
						var i;
						if (groupedFormLinesObj[currentLineIndex]) { 
							for (i = 0; i < groupedFormLinesObj[currentLineIndex].items.length ; i++) {// loop columns
								if (groupedFormLinesObj[totalsLineIndex].items[i]) {
									if (inputLineNoIndex == 0) { // for first line alone
										groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT = 0;
									}
									//TODO: REVISIT ENABLED FLAG
									if(groupedFormLinesObj[totalsLineIndex].items[i].IS_ENABLED == 'N') {
										groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT = groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT +
										((groupedFormLinesObj[currentLineIndex].items[i].ATTRIB_AMOUNT != undefined 
											|| groupedFormLinesObj[currentLineIndex].items[i].ATTRIB_AMOUNT != null) 
											? groupedFormLinesObj[currentLineIndex].items[i].ATTRIB_AMOUNT : 0);
									} else{
										groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT = null;
									}
									if (inputLineNoIndex == (inputLineNos.length - 1)) { // Last line
										if (groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT == 0) {
											groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT = null;
										}
									}
								}
							}
							
						}
					}
					
				}

				function subLines(groupedFormLinesObj, totalsLineNo, firstNo, secondNo) {
					// Loop through the lines
					// Loop through the attributes
					
					var totalsLineIndex = _.findIndex(groupedFormLinesObj, { LINE_NO: totalsLineNo + '.' });
					var firstNoIndex = _.findIndex(groupedFormLinesObj, { LINE_NO: firstNo + '.' });
					var secondNoIndex = _.findIndex(groupedFormLinesObj, { LINE_NO: secondNo + '.' });

					var firstAmount;
					var secondAmount;
					if (groupedFormLinesObj[totalsLineIndex]) {
						for (let i = 0; i < groupedFormLinesObj[totalsLineIndex].items.length ; i++) {// loop columns
							firstAmount = groupedFormLinesObj[firstNoIndex].items[i].ATTRIB_AMOUNT;
							secondAmount = groupedFormLinesObj[secondNoIndex].items[i].ATTRIB_AMOUNT;
							if (!firstAmount && !secondAmount) {
								groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT = null;
							} else {
								groupedFormLinesObj[totalsLineIndex].items[i].ATTRIB_AMOUNT = 
								(firstAmount ? firstAmount : 0)
											- (secondAmount ? secondAmount : 0);
							}
							
						}
					}
				}

				vm.addTotals = function(partNo){		
					if (partNo == 'I') {
						console.log('groupedFormLinesObj: ' + vm.groupedFormLines);	
						setRecaptureValuesAndAddLines();
						let revision = 2020;
						if (vm.selectedTaxYear.value >= revision) {
							addLines(vm.groupedFormLines, '3', '1', '2a', '2b', '2c', '2d', '2e1', '2e2');		
						} else {
							addLines(vm.groupedFormLines, '3', '1', '2a', '2b', '2c', '2d', '2e');	
						}
						
						addLines(vm.groupedFormLines, '6', '3', '4', '5');	
						addLines(vm.groupedFormLines, '8', '6', '7');	
						if (vm.selectedTaxYear.value >= revision) {
							addLines(vm.groupedFormLines, '11', '8', '9a', '9b', '9c', '9d', '9e1', '9e2', '10');	
						} else {
							addLines(vm.groupedFormLines, '11', '8', '9a', '9b', '9c', '9d', '9e', '10');	
						}
						
					} else if (partNo == 'II') {
						console.log('No totals calc for part II: ' + vm.groupedFormLinesPartII);	
					} else if (partNo == 'III') {
						setRecaptureValuesAndAddLines();
						console.log('groupedFormLinesObj III: ' + vm.groupedFormLinesPartIII);	
					} else if (partNo == 'IV') {
						setRecaptureValuesAndAddLines();
						console.log('groupedFormLinesObj IV: ' + vm.groupedFormLinesPartIV);	
					}
				};

				function setRecaptureValuesAndAddLines() {
					if (vm.groupedFormLines) {
						if (vm.groupedFormLinesPartIII) {
							var partOneLine7 = _.findIndex(vm.groupedFormLines, { LINE_NO: '7.' });
							var partThreeLine4 = _.findIndex(vm.groupedFormLinesPartIII, { LINE_NO: '4.' });
							for (let i = 0; i < vm.groupedFormLinesPartIII[partThreeLine4].items.length; i++) {
								vm.groupedFormLinesPartIII[partThreeLine4].items[i].ATTRIB_AMOUNT = vm.groupedFormLines[partOneLine7].items[i].ATTRIB_AMOUNT;
							}
							addLines(vm.groupedFormLinesPartIII, '5', '1', '2', '3', '4');	
						}
						if (vm.groupedFormLinesPartIV) {
							var partOneLine10 = _.findIndex(vm.groupedFormLines, { LINE_NO: '10.' });
							var partFourLine5 = _.findIndex(vm.groupedFormLinesPartIV, { LINE_NO: '5.' });
							// LENGHT-1 HERE TO EXCLUDE LAST COLUMN(US INCOME)
							for (let i = 0; i < vm.groupedFormLinesPartIV[partFourLine5].items.length - 1; i++) {
								vm.groupedFormLinesPartIV[partFourLine5].items[i].ATTRIB_AMOUNT = vm.groupedFormLines[partOneLine10].items[i].ATTRIB_AMOUNT;
							}
							addLines(vm.groupedFormLinesPartIV, '4', '1', '2', '3');
							subLines(vm.groupedFormLinesPartIV, '6', '4', '5');
						}
					}
				}
				
				//Save the Amounts
				vm.saveGeneralBasket = function(){			
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var dataToSave = [];								
					
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;	
					returnClobSettingsObj['jcd_key'] = vm.jcd_key;	
					returnClobSettingsObj['jcd_ta_key'] = vm.jcd_ta_key;
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);

					let groupedFormLinesObj = vm.groupedFormLines;
					pushToDataToSave(groupedFormLinesObj, dataToSave);
					pushToDataToSave(vm.groupedFormLinesPartII, dataToSave);
					pushToDataToSave(vm.groupedFormLinesPartIII, dataToSave);
					pushToDataToSave(vm.groupedFormLinesPartIV, dataToSave);
						
					//console.log("dataToSave-----" + dataToSave);
					var message = "Form 1118 Schedule-J Amount(s) have been saved/updated";	
					//send to save the Data					
					sendDetails(returnClobSettingsObj, dataToSave, message);
								
				}

				function pushToDataToSave(groupedFormLinesObj, dataToSave) {
					for(var d in groupedFormLinesObj){						
						for(var a in groupedFormLinesObj[d].items){							
							//console.log(" >  >  >  >  >  >  >  > ",groupedFormLinesObj[d].items[a]);
														
							var returnObj = {};		
							returnObj["AMOUNT"] = groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT;
							returnObj["REASON"] = null;							
							returnObj["SCHD_KEY"] = groupedFormLinesObj[d].items[a].SCHD_KEY;		
							returnObj["LINE_KEY"] = groupedFormLinesObj[d].items[a].LINE_KEY;
							returnObj["COL_KEY"] = groupedFormLinesObj[d].items[a].COL_KEY;	
							if(groupedFormLinesObj[d].items[a].BASKET_KEY == undefined || groupedFormLinesObj[d].items[a].BASKET_KEY == null || groupedFormLinesObj[d].items[a].BASKET_KEY == ''){
								returnObj["BASKET_KEY"] = vm.sourceKey;
							}else{
								returnObj["BASKET_KEY"] = groupedFormLinesObj[d].items[a].BASKET_KEY;
							}
							if(groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT != undefined 
									&& groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT !=null 
										&& groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT != 0 
											&& groupedFormLinesObj[d].items[a].OPERATION_TYPE == 'U'){								
								returnObj["OPERATION_TYPE"] = groupedFormLinesObj[d].items[a].OPERATION_TYPE;								
							}else if(groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT !=null && groupedFormLinesObj[d].items[a].OPERATION_TYPE == null){
								returnObj["OPERATION_TYPE"] = 'I';
								groupedFormLinesObj[d].items[a].OPERATION_TYPE = 'U';
							}else if((groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT == undefined 
										|| groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT == null 
											|| groupedFormLinesObj[d].items[a].ATTRIB_AMOUNT == 0) && groupedFormLinesObj[d].items[a].OPERATION_TYPE == 'U'){
								returnObj["OPERATION_TYPE"] = 'D';
								groupedFormLinesObj[d].items[a].OPERATION_TYPE = null;
							}else if(groupedFormLinesObj[d].items[a].OPERATION_TYPE == 'N') {
								returnObj.OPERATION_TYPE = null;
							}								
							//console.log("returnObj::FNL:: ",returnObj);	
							
							if(returnObj.OPERATION_TYPE!=null){
								dataToSave.push(returnObj);
							}
						}
					}	
				}
				
				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, returnClobDataObj, message) {
					Form1118SchdJFactory.saveBasketAmounts(returnClobSettingsObj, returnClobDataObj
						, vm.selectedTaxYear.value, vm.selectedScenario.VALUE, vm.selectedFE.FILER_LE_KEY
						, vm.selectedFE.FILER_COMBINATION_KEY, vm.jcd_key).then(function(result) {
						if (result.data.callSuccess != 1) {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							vm.isSaveClicked = false;
						};
					});
				}
			}
			return controllers;

		});