define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.CbcReportingController',[])


        .controller('CBCDashboardCtrl', ['$rootScope','$scope', '$http', 'GlobalService', 'AlertService' ,'JsonObjectFactory','$timeout' ,'TAX_YEARS', 'workspaceFactory', 'GENERAL_CONFIG', cbcDashboardCtrl])
        .controller('cbcReportingController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'gridData' ,'JsonObjectFactory','$timeout','ProjectTagFactory' ,'TAX_YEARS','cbcReportingFactory','GENERAL_CONFIG', taxesPaidCtrl])
        .controller('tbl2RefreshController',["AlertService","cbcReportingFactory","$uibModalInstance",tbl2RefreshController])
        .controller('cbyeRefreshController',["AlertService","cbcReportingFactory","$uibModalInstance",cbyeRefreshController])
        .controller('statelessRefreshController',["AlertService","cbcReportingFactory","$uibModalInstance",statelessRefreshController])
        .controller('statelessPushController',["AlertService","cbcReportingFactory","$uibModalInstance",statelessPushController])
        .controller('cbcAcctTaggingController', ['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'USER_SETTINGS', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','workspaceFactory','GENERAL_CONFIG', cbcAcctTaggingController])
        .controller('cbcSchdATbl3CreateController', ['$rootScope', '$scope', 'TAX_YEARS', 'GlobalService', '$uibModalInstance', 'USER_SETTINGS', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','workspaceFactory','GENERAL_CONFIG', cbcSchdATbl3CreateController])
        .controller('deleteCbcDataByMeController', ['$rootScope', 'TAX_YEARS', 'GlobalService', '$uibModalInstance', 'USER_SETTINGS', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', deleteCbcDataByMeController])
        .controller('cbcEfileDataPushController', ['$scope', '$uibModalInstance', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', cbcEfileDataPushController])
        .controller('CBCEditCountry',["GENERAL_CONFIG","USER_SETTINGS","$uibModalInstance","$rootScope","gridData","$scope","workspaceFactory",CbcEditCountry])
    function cbcDashboardCtrl($rootScope, $scope, $http, GlobalService,  AlertService, JsonObjectFactory, $timeout, TAX_YEARS, workspaceFactory, GENERAL_CONFIG) {
        var vm = this;
        $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
        vm.globalFilingOn = 0;


        vm.chartsLoaded = false;
        vm.chartArrays = [];
        vm.charts = {};
        vm.loadChartDataUrl = GENERAL_CONFIG.base_url + '/loadChartData';


        vm.getData = function(_object){
            loadData(_object);
        }



        $scope.$on('dataFilters:refreshGrid', function(event,data) {
            vm.loadAllCharts();
        });



        //----------------------(s) FIRST ROW OF CHARTS ------------------------------------------------


        if( workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.cachedData ){
                //CHECK IF DATA IS LOADED
                vm.charts = workspaceFactory.activeScreen.data;
                vm.chartsLoaded = true;
        }else {





            vm.charts.unRelatedLossChart = {
                settings: {
                    name: 'Country',
                    title: 'Countries with overall losses in Unrelated Revenues',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                    scaleHeight: false,

                },
                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=117",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "greater_than",
                            selected: "0",
                            list: [
                                {name: "1B", value: "1000000000"},
                                {name: "100M", value: "100000000"},
                                {name: "10M", value: "10000000"},
                                {name: "1M", value: "1000000"},
                                {name: "100K", value: "100000"},
                                {name: "10K", value: "10000"},
                                {name: "0", value: "0"}
                            ]
                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        }

                    ]
                },


                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','Tax_Residence']
                }]


            }








            vm.charts.relatedLossChart = {
                settings: {
                    name: 'Country',
                    title: 'Countries with overall losses in Related Revenues',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                    scaleHeight: false,

                },
                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=116",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "greater_than",
                            selected: "0",
                            list: [
                                {name: "1B", value: "1000000000"},
                                {name: "100M", value: "100000000"},
                                {name: "10M", value: "10000000"},
                                {name: "1M", value: "1000000"},
                                {name: "0", value: "0"}
                            ]
                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        }
                    ]
                },

                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','Tax_Residence']
                }]


            }


            //----------------------(e) FIRST ROW OF CHARTS ------------------------------------------------


                vm.charts.pbtChart = {


                    settings: {
                        name: 'Country',
                        title: 'Significant Jurisdictions (PBT Loss > $100 M)',
                        type: 'barChart',
                        barHeight: 20,
                        chartHeightPadding: 300,
                        numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                        toolTipFormat: 'numberShort',
                        loading: false,
                        drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                        dataLabels: true,
                        scaleHeight: true,

                    },
                    dataCall: {
                        action: vm.loadChartDataUrl + "?action_id=105",
                        useFilterParams: true,
                        returnObj: 'listOfArray',
                        filters: [
                            {
                                name: "pbt_amount",
                                selected: "100000000",
                                list: [
                                    {name: "10B", value: "10000000000"},
                                    {name: "1B", value: "1000000000"},
                                    {name: "500M", value: "500000000"},
                                    {name: "100M", value: "100000000"},
                                    {name: "50M", value: "50000000"},
                                    {name: "10M", value: "10000000"}
                                ]
                            },

                            {
                                name: "reporting_on",
                                selected: 0,
                                type: 'checkbox',
                            }
                        ]
                    },

                    series: [{
                        colorByPoint: true,
                        data: null,
                        keys:['name','y','Tax_Residence']
                    }]


                }


            vm.charts.pbtChartProfit = {


                settings: {
                    name: 'Country',
                    title: 'Significant Jurisdictions Profit (PBT > $100 M)',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                    scaleHeight: true,

                },
                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=119",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "pbt_amount",
                            selected: "100000000",
                            list: [
                                {name: "10B", value: "10000000000"},
                                {name: "1B", value: "1000000000"},
                                {name: "500M", value: "500000000"},
                                {name: "100M", value: "100000000"},
                                {name: "50M", value: "50000000"},
                                {name: "10M", value: "10000000"}
                            ]
                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',

                        }
                    ]
                },

                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','Tax_Residence']
                }]


            }


            /*

            vm.charts.sigJurChart = {
                settings: {
                    name: 'Country',
                    title: 'Significant jurisdictions with "high" PBT relative to assets or employees',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                    scaleHeight: false,
                },

                series: [{
                    colorByPoint: true,
                    data: [
                        ['Bermuda', 32749272],
                        ['Hong Kong', 1451821],
                        ['Ireland', 3337276],
                        ['Luxembourg', 16925926],
                        ['Mauritius', 79819959],
                        ['Netherlands', 14957871],
                        [' Sweden', 3650328],
                    ]
                }]


            }
            */

            vm.charts.totalCntrRevChart = {
                settings: {
                    name: 'Country',
                    title: 'Business with more than ?% of Total Revenue',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    yAxisFormat: 'percent',
                    loading: false,
                    drillDown: {"screen_key": "10437", "filters": ["Tax_Residence","Mainten_Owned_By"]},
                    dataLabels: true,
                    scaleHeight: true,
                },

                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=113",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "greater_than",
                            selected: "65",
                            type: 'select',
                            list: [
                                {name: "200%", value: "200"},
                                {name: "100%", value: "100"},
                                {name: "85%", value: "85"},
                                {name: "65%", value: "65"}
                            ]
                        },
                        {
                            name: "show_gof",
                            selected: "0",
                            type: 'checkbox',
                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        }


                    ]
                },


                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','Tax_Residence','Mainten_Owned_By']
                }]


            }

            vm.charts.unRelatedCntrRevChart = {
                settings: {
                    name: 'Country',
                    title: 'Business with more than ?% of Revenue (Unrelated Party) ',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    yAxisFormat: 'percent',
                    loading: false,
                    drillDown: {"screen_key": "10437", "filters": ["Tax_Residence","Mainten_Owned_By"]},
                    dataLabels: true,
                    scaleHeight: true,
                },

                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=114",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "greater_than",
                            selected: "65",
                            type: 'select',
                            list: [
                                {name: "200%", value: "200"},
                                {name: "100%", value: "100"},
                                {name: "85%", value: "85"},
                                {name: "65%", value: "65"}
                            ]
                        },
                        {
                            name: "show_gof",
                            selected: "0",
                            type: 'checkbox',
                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        }


                    ]
                },


                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','Tax_Residence','Mainten_Owned_By']
                }]


            }


            vm.charts.pBTIPerEmpChart = {
                settings: {
                    name: 'Country',
                    title: 'PBTI Relative to Employees > ? ( absolute value ) ',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                    scaleHeight: true,
                },

                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=115",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "greater_than",
                            selected: "500000",
                            type: 'select',
                            list: [
                                {name: "1M", value: "1000000"},
                                {name: "500k", value: "500000"},
                                {name: "100k", value: "100000"},
                                {name: "50k", value: "50000"},
                                {name: "10k", value: "10000"}
                            ]
                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        }
                    ]
                },


                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','pbt','Tax_Residence']
                }]


            }





            vm.charts.ETRCash = {
                settings: {
                    name: 'Country',
                    title: 'Significant Jurisdictions with ETR (Cash Taxes) ( <?% or >?%',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    yAxisFormat: 'percent',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                    scaleHeight: true,
                },

                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=133",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "greater_than",
                            selected: 60,
                            type:'input'
                        },
                        {
                            name: "less_than",
                            selected: -70,
                            type:'input'
                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        },
                        {
                            name: "significant",
                            selected: "1000000",
                            list: [
                                {name: "500M", value: "500000000"},
                                {name: "100M", value: "100000000"},
                                {name: "50M", value: "50000000"},
                                {name: "10M", value: "10000000"},
                                {name: "1M", value: "1000000"},
                                {name: "100K", value: "100000"}
                            ]
                        },

                    ]
                },


                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','pbt','Tax_Residence']
                }]


            }



            vm.charts.ETRChart = {
                settings: {
                    name: 'Country',
                    title: 'Significant Jurisdictions with ETR Accrued ( <?% or >?%',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    yAxisFormat: 'percent',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                    scaleHeight: true,
                },

                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=111",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "greater_than",
                            selected: 60,
                            type:'input'

                        },
                        {
                            name: "less_than",
                            selected: -70,
                            type:'input'

                        },

                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        },
                        {
                            name: "significant",
                            selected: "1000000",
                            list: [
                                {name: "500M", value: "500000000"},
                                {name: "100M", value: "100000000"},
                                {name: "50M", value: "50000000"},
                                {name: "10M", value: "10000000"},
                                {name: "1M", value: "1000000"},
                                {name: "100K", value: "100000"}
                            ]
                        },
                    ]
                },



                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','pbt','Tax_Residence']
                }]


            }



            vm.charts.statelessChart = {
                settings: {
                    name: 'Country',
                    title: 'Country Of Incorp <>  Tax Residence',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    loading: false,
                    drillDown: {"screen_key": "10437", "filters": ["Mainten_Owned_By","Tax_Residence","LE_Country_Description"]},
                    drillDownFunction: function (_value,_index,_data) {



                        ////console..log(_data, _data);




                        var newVal = "";
                        if(_index === 0 ){
                            newVal = _value.replace(/ *\([^)]*\) */g, "");
                        }else{
                            newVal = _value.match(/\(([^)]+)\)/)[1]
                        }
                        return newVal.trim();
                    },

                    dataLabels: true,
                    scaleHeight: true,



                },

                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=136",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: [
                        {
                            name: "pbt_amount",
                            selected: "50000000",
                            list: [
                                {name: "500M", value: "500000000"},
                                {name: "100M", value: "100000000"},
                                {name: "50M", value: "50000000"},
                                {name: "10M", value: "10000000"},
                                {name: "1M", value: "1000000"},
                                {name: "100K", value: "100000"}
                            ]
                        },
                        {
                            name: "reporting_on",
                            selected: 0,
                            type: 'checkbox',
                        }

                    ]
                },

                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','Mainten_Owned_By','Tax_Residence','LE_Country_Description']
                }]
        }



            vm.charts.tangibleChart = {
                settings: {
                    name: 'Country',
                    title: 'Negative Tangible Assets',
                    type: 'barChart',
                    barHeight: 20,
                    chartHeightPadding: 300,
                    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                    toolTipFormat: 'numberShort',
                    loading: false,
                    drillDown: {"screen_key": "10436", "filters": ["Tax_Residence"]},
                    dataLabels: true,
                },


                dataCall: {
                    action: vm.loadChartDataUrl + "?action_id=118",
                    useFilterParams: true,
                    returnObj: 'listOfArray',
                    filters: []
                },

                series: [{
                    colorByPoint: true,
                    data: null,
                    keys:['name','y','Tax_Residence']
                }]


            }


        }



        function loadData(chartObj,chartName){
            chartObj.settings.loading = true;

          var filters = {} ;

            var keys = chartObj.series[0].keys;


            _.forEach(chartObj.dataCall.filters, function(value) {
                filters[value.name] = value.selected;
            });

            return   JsonObjectFactory.getJSONObj(chartObj.dataCall.action, filters).then(function (data) {

                ////console..log("chartObj.series : "  , chartObj.series ) ;

                chartObj.settings.loading = false;


                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                } else {
                    var series = [{
                        colorByPoint: true,
                        data : data[chartObj.dataCall.returnObj],
                    }]






                    if(keys){

                        //console..log("chartObj.series : " , chartObj.series)
                        //console..log("keys : " , keys)

                        series[0].keys = keys
                    }



                    chartObj.series = series;
                }
            });



        }






        vm.globalFilingChange = function(){
            _.forEach(vm.charts, function(value,key) {
                var filterArray  = value.dataCall.filters;
                var index = _.findIndex(filterArray, { 'name': 'reporting_on'});
                if(index >  0){
                    filterArray[index].selected = vm.globalFilingOn;
                }
            });
            vm.loadAllCharts();
        }




        vm.loadAllCharts = function(){

            _.forEach(vm.charts, function(value,key) {
                loadData(value,key);
            });

            vm.chartsLoaded = true;
            workspaceFactory.activeScreen.cachedData = true;
            workspaceFactory.activeScreen.data = vm.charts;


            ////console..log(" workspaceFactory.activeScreen.data : " ,  workspaceFactory.activeScreen.data)


        }


    }

    function taxesPaidCtrl($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, gridData, JsonObjectFactory,$timeout,ProjectTagFactory,TAX_YEARS,cbcReportingFactory,GENERAL_CONFIG){
        var vm = this;
        vm.title = "Beps Taxes Paid";
        vm.crudLoading = false;
        vm.entities = [];
        vm.rows = [];
        vm.tax_years = _.map(TAX_YEARS, 'value');
        vm.types = [
            {LABEL:"Income Taxes", VALUE:"Income Taxes"},
            {LABEL:"W/H Taxes", VALUE:"W/H Taxes"},
            {LABEL:"Audit/Exam Payments", VALUE:"Audit/Exam Payments"}
        ]
        var row = {
            "OBJECT_ID": "",
            "PERIOD_NAME": "",
            "GOLD_ID": null,
            "MARS_ID": null,
            "IMPOSING_TAX": null,
            "TAX_YEAR":null,
            "IMPOSING_TAX_DESC":null,
            "TYPE": "",
            "TYPE_OBJECT":{
                loading:false,
                set:false,
                values:vm.types
            },
            "AMOUNT": 0,
            "EDIT_TYPE":"insert",
            "CREATED_BY": null,
            "TIME_STAMP":null,
            "MARS":{
                loading:false,
                selected:null,
                set:false,
                values:[],
            },
            "COUNTRY":{
                loading:false,
                selected:null,
                set:false
            },
            "GOLD":{
                loading:false,
                selected:null,
                set:false
            }
        }


        vm.setGOLD = function(gold,index){
            if(typeof vm.rows[index].GOLD == "undefined" ){
                vm.rows[index].GOLD={
                    loading:false,
                    selected:null,
                    set:false
                };
            }
            vm.rows[index].GOLD_ID = gold.VALUE;
            vm.rows[index].GOLD.selected = gold;
            vm.rows[index].GOLD.set = true;
            vm.getMarsIds(gold.VALUE,index);
        }

        vm.unSetGoldId = function(index){
            vm.rows[index].GOLD_ID = "";
            vm.rows[index].GOLD.selected = null;
            vm.rows[index].GOLD.set = false;
            vm.rows[index].MARS_ID = "";
            vm.rows[index].MARS.values = "";
        }



        vm.setCountry = function(country,index){
             if(typeof vm.rows[index].COUNTRY === "undefined" ){
                vm.rows[index].COUNTRY={
                    loading:false,
                    selected:null,
                    set:false
                }
            }
            vm.rows[index].IMPOSING_TAX = country.code;
            vm.rows[index].IMPOSING_TAX_DESC = country.name;
            vm.rows[index].COUNTRY.selected = country;
            vm.rows[index].COUNTRY.set = true;
        }

        vm.unSetCountry = function(index){
            vm.rows[index].IMPOSING_TAX = "";
            vm.rows[index].IMPOSING_TAX_DESC = "";
            vm.rows[index].COUNTRY.selected = null;
            vm.rows[index].COUNTRY.set = false;
        }


        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };


        vm.remove = function ($index) {
            vm.rows.splice($index,1);
        }


        vm.getGoldIds = function(search_string){
            var params = {
                "action_id": 60,
                "search_string":search_string
            }
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                } else {

                    return data.jsonObject;
                }

            });
        };

        vm.setMarsID = function(row){
            if(row.MARS.selected ){
                row.MARS_ID = row.MARS.selected.VALUE;
            }
        }

        vm.setupMars = function(values,index){
            vm.rows[index].MARS.values = values;
            if(vm.rows[index].MARS_ID){
                var _index = _.findIndex(values, { 'VALUE': vm.rows[index].MARS_ID});
                vm.rows[index].MARS.selected = values[_index];
            }else{
                vm.rows[index].MARS.selected = values[0]
            }
            vm.setMarsID(vm.rows[index]);

        }

        vm.getMarsIds = function(gold_id,index){
            vm.rows[index].MARS.loading = true;
            var params = {
                "action_id": 61,
                "gold_id":gold_id
            }

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                    vm.rows[index].MARS.loading = false;
                } else {
                    vm.setupMars(data.jsonObject,index);
                    vm.rows[index].MARS.loading = false;
                    return true;
                }

            });
        };


        vm.delete = function(row,$index){
            var tempCopy = row;
            vm.remove($index);
            cbcReportingFactory.deleteTaxesPaid(row.OBJECT_ID).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    vm.rows.splice($index, 0, tempCopy);
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    var args = [];
                    args.push({object_id:row.object_id})
                    $rootScope.$emit('gridDeleteRows', args);
                    //vm.cancel();
                }
            });
        }

        vm.typeChange = function(row){
            row.TYPE = row.TYPE_OBJECT.selected.VALUE;
        }

        vm.taxYearChange = function(row){
            row.PERIOD_NAME = getPeriodFrom(row.TAX_YEAR);
        }

        vm.getCountries = function(val){
            return GlobalService.getCountryByCode(val);
        }


        vm.save = function () {
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            if (vm.taxsPaidFrom.$invalid) {
                vm.taxsPaidFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }
            var clonedRows = _.cloneDeep(vm.rows);
            _.forEach(clonedRows, function(value, key) {
                delete value["TYPE_OBJECT"];
                delete value["COUNTRY"];
                delete value["$$hashKey"];
                delete value["batchSelect"];
                delete value["MARS"];
                delete value["GOLD"];
                vm.rows[key].MARS_ID = vm.rows[key].MARS.selected.VALUE;

            });
            taxesPaidCRUD(clonedRows);
        };

        function taxesPaidCRUD(_data){
            vm.crudLoading = true;
            cbcReportingFactory.saveTaxesPaid(_data).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    AlertService.add("success", "Taxes paid have been updated" , 4000);
                      vm.crudLoading = false;
                      var args = {};
                      $rootScope.$emit('gridUpdate', args);
                      vm.cancel();
                }
            });
        }




        function init(){
            if( !_.isArray(gridData)){//IF OBJECT
                var cloneObj = _.cloneDeep(gridData.rowData);
                cloneObj.MARS ={
                    loading:false,
                        selected:null,
                        set:false,
                        values:[],
                }
                cloneObj.EDIT_TYPE = "update";
                cloneObj.TYPE_OBJECT={
                    loading:false,
                    selected:null,
                    set:false,
                    values:vm.types
                };
                cloneObj.TAX_YEAR = getTaxYearFromPeriod(cloneObj.PERIOD_NAME);
                cloneObj.TYPE_OBJECT.selected = _.find(cloneObj.TYPE_OBJECT.values, {'VALUE':cloneObj.TYPE})
                var countries = vm.getCountries(cloneObj.IMPOSING_TAX);
                vm.rows.push(cloneObj);
                vm.setCountry(countries[0],0);
                if( cloneObj.GOLD_ID){
                    var gold = {"LABEL":cloneObj.GOLD_ID,"VALUE":cloneObj.GOLD_ID}
                    vm.setGOLD (gold,0);
                }
            }else{
                if( gridData.length ){//IF ARRAY OF OBJECTS
                    _.forEach(gridData, function(value, key) {
                        var cloneObj = _.cloneDeep(value.data);
                        cloneObj.EDIT_TYPE = "update";
                        cloneObj.MARS ={
                            loading:false,
                            selected:null,
                            set:false,
                            values:[],
                        }
                        cloneObj.TYPE_OBJECT={
                            loading:false,
                            selected:null,
                            set:false,
                            values:vm.types
                        };
                        cloneObj.TAX_YEAR = getTaxYearFromPeriod(cloneObj.PERIOD_NAME);
                        cloneObj.TYPE_OBJECT.selected = _.find(cloneObj.TYPE_OBJECT.values, {'VALUE':cloneObj.TYPE})
                        var countries = vm.getCountries(cloneObj.IMPOSING_TAX);
                        vm.rows.push(cloneObj);
                        vm.setCountry(countries[0],key);
                        if( cloneObj.GOLD_ID){
                            var gold = {"LABEL":cloneObj.GOLD_ID,"VALUE":cloneObj.GOLD_ID}
                            vm.setGOLD (gold,key);
                        }
                    });
                }else{
                    vm.addRow();
                }
            }



        }



        // WE ARE DOING THIS BECAUSE OF A BAD CONCATENATED TABLE DESIGN "Q4-16" instead of "Q4" in one column and "2016" in another ... BAD ... BAD ugh .....
        // Expecting period_name  = "Q4-16"  ...
        function  getTaxYearFromPeriod(period_name){
            if( period_name.length){
                return  "20"+ period_name.split("-")[1];
            }
            return "";
        }

        function getPeriodFrom(tax_year){
            if( tax_year.length){
                return  "Q4-"+ tax_year.substring(2);
            }
            return "";
        }



        vm.addRow = function(){
            var cloneObj = _.cloneDeep(row);
            cloneObj.OBJECT_ID = new Date().valueOf();
            vm.rows.push(cloneObj);
        }

        init();
    }

    function tbl2RefreshController(AlertService, cbcReportingFactory, $uibModalInstance) {
        let vm = this;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
        vm.refresh = function () {
            $uibModalInstance.close();
            AlertService.add('success', 'Data refresh is In-Progress.', 4000);
            cbcReportingFactory.refreshTable2Data().then(function (response) {
            });
        }
    }

    function cbyeRefreshController(AlertService, cbcReportingFactory, $uibModalInstance) {
        let vm = this;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
        vm.refresh = function () {
            $uibModalInstance.close();
            AlertService.add('success', 'Data refresh is In-Progress.', 4000);
            cbcReportingFactory.refreshCbyEData().then(function (response) {
            });
        }
    }

    function statelessRefreshController(AlertService, cbcReportingFactory, $uibModalInstance) {
        let vm = this;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
        vm.refresh = function () {
            $uibModalInstance.close();
            AlertService.add('success', 'Data refresh is In-Progress.', 4000);
            cbcReportingFactory.refreshStatelessData().then(function (response) {
            });
        }
    }

    function statelessPushController(AlertService, cbcReportingFactory, $uibModalInstance) {
        let vm = this;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
        vm.refresh = function () {
            $uibModalInstance.close();
            AlertService.add('success', 'Data push is In-Progress.', 4000);
            cbcReportingFactory.pushStatelessData().then(function (response) {
            });
        }
    }

    function cbcAcctTaggingController($rootScope, $scope, $http, GlobalService, $uibModalInstance, USER_SETTINGS, AlertService, rowData, gridData, JsonObjectFactory, $timeout, workspaceFactory, GENERAL_CONFIG) {
        let vm = this;
        vm.title = 'Create/Edit CbCr Account Mapping';

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function loadAccounts() {
            var params = {
                'action_code': 'a309q2',
                'sso_id': USER_SETTINGS.user.sso_id,
                'system_acct_key': rowData.SYSTEM_ACCT_KEY || '',
                'cbcr_acct_key': rowData.CBCR_ACCT_KEY || ''
            };

            vm.accountsLoading = true;
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                                    .then(function (data) {
                                        if (data.callSuccess !== "1") {
                                            AlertService.add("error", data.errorMessage);
                                        } else {
                                            vm.accounts = data.jsonObject;
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        AlertService.add('error', 'Something went wrong while loading accounts!');
                                    })
                                    .finally(() => {
                                        vm.accountsLoading = false;
                                    });
        }
        loadAccounts();

        vm.save = function () {
            vm.crudLoading = true;
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=b6w9y1';
            const clobSettings = [{
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                jcd_key: workspaceFactory.activeScreen.jcd_key
            }];
            const clobData = [{
                'CHART_KEY': rowData.CHART_KEY,
                'SYSTEM_ACCT_KEY': rowData.SYSTEM_ACCT_KEY,
                'CBCR_ACCT_KEY': rowData.CBCR_ACCT_KEY,
                'NEW_CBCR_ACCT_KEY': vm.selectedAccount.ACCT_KEY || null
            }];
            const filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params = _.merge({}, GlobalService.globalParams, filterParams);
            params = _.extend({'clob_data': JSON.stringify(clobData)}, params);
            params = _.extend({'clob_settings': JSON.stringify(clobSettings)}, params);
            params.sso_id = USER_SETTINGS.user.sso_id;
            JsonObjectFactory.saveJSON(url, params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data saved successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while saving your changes, please try again!');
                             })
                             .finally(() => {
                                 vm.crudLoading = false;
                             });
        };
    }



    function cbcSchdATbl3CreateController($rootScope, $scope, TAX_YEARS, GlobalService, $uibModalInstance, USER_SETTINGS, AlertService, rowData, gridData, JsonObjectFactory, $timeout, workspaceFactory, GENERAL_CONFIG) {
        let vm = this;
        vm.mode = rowData ? 'edit' : 'create';
        vm.title = vm.mode === 'create' ? 'Create Entry' : 'Edit Entry';
        vm.taxYears = TAX_YEARS;
        vm.selectedTaxYear = _.find(vm.taxYears, {value: GlobalService.globalParams.tax_year});
        vm.selectedJuris = [];
        vm.jurisdictions = [];
        vm.activate = true;
        vm.applyToAllJuris = false;
        vm.jurisDropdownSettings = {
            smartButtonMaxItems: 10,
            enableSearch: true,
            buttonClasses: 'mul-select-btn',
            scrollable: true,
            scrollableHeight: 300,
            displayProp: 'COUNTRY'
        };

        vm.mulSelectCustomTexts = {
            buttonDefaultText: 'Select jurisdictions',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };

        if (vm.mode === 'edit') {
            vm.selectedTaxYear = _.find(vm.taxYears, {value: rowData.TAX_YEAR.toString()});
            vm.rowDataElement = rowData.CBC_SUMMARY_ELEMENT;
            vm.summaryInput = rowData.CBC_SUMMARY_INPUT;
            vm.activate = rowData.ACTIVATE === 1;
        }

        $scope.$watch('ctrl.selectedElement', (newValue, oldValue) => {
            if (!oldValue && !newValue || vm.elementsLoading) return;
            vm.showCbcSummaryEleRequiredError = !newValue;
        });

        $scope.$watch('ctrl.selectedJuris.length', (newValue, oldValue) => {
            if (!oldValue && !newValue || vm.jurisLoading) return;
            vm.showAppliedJurisRequiredError = !newValue;
            vm.applyToAllJuris = newValue === vm.jurisdictions.length;
        });

        function getSummaryObj(summaryValue) {
            return vm.summaryElements.find((ele) => {
                const cbcSummaryValue = ele.CBC_SUMMARY_ELEMENT ? ele.CBC_SUMMARY_VALUE.split(' - ')[1] : ele.CBC_SUMMARY_VALUE;
                return cbcSummaryValue === summaryValue;
            });
        }

        function getJurisObjs(appliedJuris) {
            let jurisObjList = [];
            if (!appliedJuris || appliedJuris === 'ALL') return jurisObjList;

            const list = appliedJuris.split(',');
            list.forEach((countryId) => {
                const jurisObj = vm.jurisdictions.find((juris) => juris.COUNTRY_ID === countryId);
                jurisObjList.push(jurisObj);
            });
            return jurisObjList;
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.onTaxYearChanged = function () {
            vm.loadSummaryElements();
            vm.loadJurisdictions();
        };

        vm.loadSummaryElements = function() {
            var params = {
                'action_code': 't8xhy5',
                'sso_id': USER_SETTINGS.user.sso_id,
                'tax_year': vm.selectedTaxYear.value,
                'scenario': vm.selectedTaxYear.scenario
            };

            vm.summaryElements = [];
            vm.selectedElement = null;
            vm.elementsLoading = true;
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                                    .then(function (data) {
                                        if (data.callSuccess !== "1") {
                                            AlertService.add("error", data.errorMessage);
                                        } else {
                                            vm.summaryElements = data.jsonObject;
                                            if (vm.mode === 'edit') {
                                                vm.selectedElement = getSummaryObj(rowData.CBC_SUMMARY_VALUE);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        AlertService.add('error', 'Something went wrong while loading summary elements!');
                                    })
                                    .finally(() => {
                                        vm.elementsLoading = false;
                                    });
        };
        vm.loadSummaryElements();

        vm.loadJurisdictions = function() {
            var params = {
                'action_code': 'wo9we3',
                'sso_id': USER_SETTINGS.user.sso_id,
                'tax_year': vm.selectedTaxYear.value,
                'scenario': vm.selectedTaxYear.scenario
            };

            vm.applyToAllJuris = false;
            vm.hideJurisSelection = false;
            vm.selectedJuris = [];
            vm.jurisLoading = true;
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                                    .then(function (data) {
                                        if (data.callSuccess !== "1") {
                                            AlertService.add("error", data.errorMessage);
                                        } else {
                                            vm.jurisdictions = data.jsonObject;
                                            if (vm.mode === 'edit') {
                                                vm.selectedJuris = getJurisObjs(rowData.APPLIED_JURISDICTIONS);
                                                vm.applyToAllJuris = rowData.APPLY_TO_ALL_TAX_JURISDICTIONS === 1;
                                                vm.hideJurisSelection = vm.applyToAllJuris;
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        AlertService.add('error', 'Something went wrong while loading jurisdictions!');
                                    })
                                    .finally(() => {
                                        vm.jurisLoading = false;
                                    });
        };
        vm.loadJurisdictions();

        // extend ui-select with dynamic options input
        vm.refreshResults = function ($select) {
            let search = $select.search,
                list = angular.copy($select.items),
                userEntry = true;
            //remove last user input
            list = list.filter(function (item) {
                return !item.USER_ENTRY;
            });

            if (!search) {
                //use the predefined list
                $select.items = list;
            } else {
                //manually add user input and set selection
                var userInputItem = {
                    USER_ENTRY: userEntry,
                    USER_ENTRY_VALUE: 'Use new: ' + search,
                    CBC_SUMMARY_VALUE: search
                };
                $select.items = [userInputItem].concat(list);
                $select.selected = userInputItem;
            }
        };

        vm.onApplyToAllJurisCheckboxChecked = function (event) {
            vm.hideJurisSelection = event.target.checked;
        };

        function prepareActionCall(mode) {
            const CREATE_ACTION_CODE = 'lj1ccb';
            const UPDATE_ACTION_CODE = 'mo7l6h';
            const DELETE_ACTION_CODE = 'wr3gyp';
            let actionCode;
            if (mode === 'create') {
                actionCode = CREATE_ACTION_CODE;
            } else if (mode === 'edit') {
                actionCode = UPDATE_ACTION_CODE;
            } else {
                actionCode = DELETE_ACTION_CODE;
            }
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=' + actionCode;
            const appliedJurisIDs = vm.selectedJuris.map(juris => juris.COUNTRY_ID);
            const clobSettings = [{
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                jcd_key: workspaceFactory.activeScreen.jcd_key
            }];
            const clobData = [{
                'TAX_YEAR': vm.selectedTaxYear.value,
                'CBC_SUMMARY_ELEMENT': vm.selectedElement.CBC_SUMMARY_ELEMENT || null,
                'CBC_SUMMARY_VALUE': vm.selectedElement.CBC_SUMMARY_ELEMENT ? vm.selectedElement.CBC_SUMMARY_VALUE.split(' - ')[1] : vm.selectedElement.CBC_SUMMARY_VALUE,
                'ACTIVATE': vm.activate ? 'Y' : 'N',
                'CBC_SUMMARY_INPUT': vm.summaryInput || '',
                'APPLY_TO_ALL_TAX_JURISDICTIONS': vm.applyToAllJuris ? 'Y' : 'N',
                'APPLIED_JURISDICTIONS': vm.applyToAllJuris ? 'ALL' : appliedJurisIDs.toString()
            }];
            if (vm.mode === 'edit') clobData[0]['SNO'] = rowData.SNO;
            const filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params = _.merge({}, GlobalService.globalParams, filterParams);
            params = _.extend({'clob_data': JSON.stringify(clobData)}, params);
            params = _.extend({'clob_settings': JSON.stringify(clobSettings)}, params);
            params.sso_id = USER_SETTINGS.user.sso_id;

            return {url: url, params: params};
        }

        vm.save = function () {
            const urlParamsObj = prepareActionCall(vm.mode);
            vm.crudLoading = true;
            vm.saving = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data saved successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while saving data, please try again!');
                             })
                             .finally(() => {
                                 vm.saving = false;
                                 vm.crudLoading = false;
                             });
        };

        vm.delete = function () {
            const urlParamsObj = prepareActionCall('delete');
            vm.crudLoading = true;
            vm.deleting = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data deleted successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while deleting the data, please try again!');
                             })
                             .finally(() => {
                                 vm.deleting = true;
                                 vm.crudLoading = false;
                             });
        };

    }

    function deleteCbcDataByMeController($rootScope, TAX_YEARS, GlobalService, $uibModalInstance, USER_SETTINGS, AlertService, rowData, gridData, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG) {
        let vm = this;
        vm.title = 'Clear CbCr Data By ME';
        vm.rowData = rowData;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function prepareActionCall() {
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=4hedey';
            let ttKey;
            if (vm.clearTrialBalance && vm.clearManualAdj) {
                ttKey = '244,245';
            } else {
                ttKey = vm.clearTrialBalance ? '244' : '245'; // if neither checkbox is selected, delete button is expected to be disabled
            }
            let params = {
                'sso_id': USER_SETTINGS.user.sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'scenario': workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                'me_code': vm.rowData.MAINTENANCE_OWNER,
                'tt_key': ttKey
            };
            return {url: url, params: params};
        }

        vm.delete = function () {
            const urlParamsObj = prepareActionCall();
            vm.deleting = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data deleted successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage, 4000);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while deleting the data, please try again!', 4000);
                             })
                             .finally(() => {
                                 vm.deleting = false;
                             });
        };

    }

    function cbcEfileDataPushController($scope, $uibModalInstance, AlertService, JsonObjectFactory, GENERAL_CONFIG, USER_SETTINGS) {
        const vm = this;
        const sso_id = USER_SETTINGS.user.sso_id;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.pushData = function () {
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=zwibx4';
            var params = {
                'sso_id': sso_id
            };
            vm.crudLoading = true;
            vm.saving = true;
            AlertService.add('success', 'Data push to Efile has been initiated.', 4000);
            vm.cancel();
            JsonObjectFactory.saveJSON(url, params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data is pushed to Efile SuccessfullyBefore viewing the data in Efile, please contact E-File team (US_IST-Efile@pwc.com) to sync and validate the data.', 8000);
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while completing the data push, please try again!');
                             })
                             .finally(() => {
                                 vm.saving = false;
                                 vm.crudLoading = false;
                             });
        };
    }
    
        function CbcEditCountry(GENERAL_CONFIG,USER_SETTINGS,$uibModalInstance,$rootScope,gridData,$scope,workspaceFactory){

            const vm = this;
            vm.currentTheme = 'theme-white';
            const rowData = gridData.rowData? gridData.rowData : (gridData.length > 0 ? gridData[0].data : undefined);

            vm.input = JSON.stringify({
                baseUrl: GENERAL_CONFIG.base_url,
                ssoId: USER_SETTINGS.user.sso_id,
                clientKey: USER_SETTINGS.user.client_key,
                taxYear: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                rowData: rowData,
            });

            vm.close = function() {
                $uibModalInstance.close();
                $rootScope.$broadcast('dataFilters:refreshGrid', {
                    "refresh": true
                });
            }

            const listener = $rootScope.$on('close-modal', function() {
                vm.close();
            })

            $scope.$on('$destroy',function (){
                listener();
            })

        }
    return controllers;

});
