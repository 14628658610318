define([
    'angular',
    'vsRepeat',
    './newProformaDashboardCtrl',
    './newProformaFormReviewCtrl',
    './newProformaEfileViewCtrl',
    './newProformaGtwViewCtrl',
    './newProformaCbCrCtrl',
    './newProformaServices',
    './newProformaFormReviewServices',
    './newProformaEfileViewServices'
], function () {
    'use strict';
    return angular.module('app.New-Proforma', ['vs-repeat', 'app.newProformaDashboardCtrl', 'app.newProformaFormReviewCtrl', 'app.newProformaEfileViewCtrl',
                      'app.newProformaGtwViewCtrl', 'app.newProformaCbCrCtrl', 'app.newProformaServices', 'app.newProformaFormReviewServices',
                      'app.newProformaEfileViewServices'])
                  .config(['$stateProvider', function ($stateProvider) {
                      $stateProvider.state('new-proforma', {
                          url: '/new-proforma',
                          templateUrl: 'app/components/newProforma/new-proforma-dashboard.html',
                          data: {},
                          access: ""
                      })
                  }])
});
