define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3Page1',[])
        .factory("K3Page1Factory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K3Page1Factory])
    function K3Page1Factory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K3Page1Factory.renderSK3Page1Details = function(ctrl) {

            let lineNo;
            let lineDesc;
            let eline = [];

            ctrl.page1Header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {ATTRIB_NAME:"1065_K3_N/A_N/A_N/A"});

            ctrl.firstRow       = $filter("filter")(ctrl.all_1065SK3Data, {ATTRIB_NAME:"1065_K3_N/A_N/A_N/A_F"})[0];
            ctrl.tax_year_begin = ctrl.firstRow.OWNERSHIP_BEGIN_DATE;
            ctrl.tax_year_end   = ctrl.firstRow.OWNERSHIP_END_DATE;

            ctrl.pship_ein      = ctrl.firstRow.PSHIP_EIN;

            ctrl.pship_le_name  = ctrl.firstRow.PSHIP_LE_NAME;
            ctrl.pship_address  = ctrl.firstRow.PSHIP_ADDRESS;
            ctrl.pship_details = ctrl.pship_le_name + " , " + ctrl.pship_address;

            ctrl.ptr_ein        = ctrl.firstRow.PTR_EIN;

            ctrl.ptr_le_name    = ctrl.firstRow.PTR_LE_NAME;
            ctrl.ptr_address    = ctrl.firstRow.PTR_ADDRESS;
            ctrl.ptr_details    = ctrl.ptr_le_name + " , " + ctrl.ptr_address;

            ctrl.page1_E_lines_1065SK3Data = _.filter(ctrl.all_1065SK3Data,function(obj) {
                return obj.ATTRIB_NAME.indexOf('1065_K3_N/A_N/A_E') !== -1;
            });

            if(ctrl.all_1065SK3Data[0].FORM_TYPE === '8865') {
                for(let i = 0; i < ctrl.page1_E_lines_1065SK3Data.length - 5; i++) {
                    eline.push(ctrl.page1_E_lines_1065SK3Data[i]);
                }
                ctrl.page1_E_lines_1065SK3Data = eline;
            }

            for(let i = 0; i < ctrl.page1_E_lines_1065SK3Data.length; i++) {
                lineNo = ctrl.page1_E_lines_1065SK3Data[i].LINE_NO;
                lineDesc = ctrl.page1_E_lines_1065SK3Data[i].LINE_DESCRIPTION;

                if( !ctrl.E_line_Numbers.includes(lineNo)) {
                    ctrl.E_line_Numbers.push(lineNo);
                    ctrl.E_line_No_Desc_map.set(lineNo, lineDesc);
                }
            }

            return ctrl;

        }

        K3Page1Factory.obtainChanges = function(ctrl) {

            ctrl.page1HeaderChanges = $filter("filter")(ctrl.page1Header_1065SK3Data,{IS_CHANGED_FLAG:'Y'});
            ctrl.page1ELinesChanges = $filter("filter")(ctrl.page1_E_lines_1065SK3Data,{IS_CHANGED_FLAG:'Y'});

            for(let i = 0; i < ctrl.page1HeaderChanges.length; i++) {
                ctrl.saveData.push(ctrl.page1HeaderChanges[i]);
            }
            for(let i = 0; i < ctrl.page1ELinesChanges.length; i++) {
                ctrl.saveData.push(ctrl.page1ELinesChanges[i]);
            }

            return ctrl;
        }

        return K3Page1Factory;

    }

    return services;
});