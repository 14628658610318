define([
    'angular',
    './ScheduleDControllers',
    './ScheduleDService'

], function () {
    'use strict';
    return angular.module('app.scheduleD', ['app.scheduleDController','app.scheduleDService'] )


});