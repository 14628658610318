define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.PdfDataController', [])
                             .controller('PdfDataCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'PdfDdDataFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', PdfDataCtrl])
                             .controller('PdfFbCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'PdfFbDataFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', PdfFbCtrl])
                             .controller('PdfS3Ctrl', ['$rootScope', '$timeout', '$uibModalInstance', 'PdfS3DataFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', PdfS3Ctrl])
                             .controller('PdfRdCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'PdfRdDataFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', PdfRdCtrl]);

    function PdfDataCtrl($rootScope, $timeout, $uibModalInstance, PdfDdDataFactory, workspaceFactory, gridData, AlertService) {
        var vm = this;
        vm.delete = function () {
            var rows = gridData.map(({data}) => data);
            PdfDdDataFactory.deleteDdData(rows).then((data) => {
                if (data.callSuccess === '1') {
                    $timeout(function () {
                        var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                        var args = {};
                        var filterParams = { gridFilter: gridFilters };
                        args = _.merge({}, args, filterParams);
                        $rootScope.$emit('gridUpdate', args);
                    });
                    AlertService.add('success', 'Data deleted successfully!', 4000);
                } else {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            }).catch(() => {
                AlertService.add('error', 'Something went wrong, please try again!');
            }).finally(() => {
                $uibModalInstance.dismiss('cancel');
            });
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function PdfFbCtrl($rootScope, $timeout, $uibModalInstance, PdfFbDataFactory, workspaceFactory, gridData, AlertService) {
        var vm = this;
        vm.delete = function () {
            var rows = gridData.map(({data}) => data);
            PdfFbDataFactory.deleteFbData(rows).then((data) => {
                if (data.callSuccess === '1') {
                    $timeout(function () {
                        var tableFilters = workspaceFactory.activeScreen.data.tableState.colSearch;
                        var filters = [];
                        for (var i = 0; i < tableFilters.length; i++) {
                            filters.push({});
                            filters[i][tableFilters[i].predicate] = tableFilters[i].value;
                        }
                        var gridFilters = filters.length > 0 && Object.assign(...filters);
                        var filterParams = { gridFilter: gridFilters };
                        var args = {};
                        args = _.merge({}, args, filterParams);
                        $rootScope.$emit('gridUpdate', args);
                    });
                    AlertService.add('success', 'Data deleted successfully!', 4000);
                } else {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            }).catch(() => {
                AlertService.add('error', 'Something went wrong, please try again!');
            }).finally(() => {
                $uibModalInstance.dismiss('cancel');
            });
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function PdfS3Ctrl($rootScope, $timeout, $uibModalInstance, PdfS3DataFactory, workspaceFactory, gridData, AlertService) {
        var vm = this;
        vm.delete = function () {
            var rows = gridData.map(({data}) => data);
            PdfS3DataFactory.deleteS3Data(rows).then((data) => {
                if (data.callSuccess === '1') {
                    $timeout(function () {
                        var tableFilters = workspaceFactory.activeScreen.data.tableState.colSearch;
                        var filters = [];
                        for (var i = 0; i < tableFilters.length; i++) {
                            filters.push({});
                            filters[i][tableFilters[i].predicate] = tableFilters[i].value;
                        }
                        var gridFilters = filters.length > 0 && Object.assign(...filters);
                        var filterParams = { gridFilter: gridFilters };
                        var args = {};
                        args = _.merge({}, args, filterParams);
                        $rootScope.$emit('gridUpdate', args);
                    });
                    AlertService.add('success', 'Data deleted successfully!', 4000);
                } else {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            }).catch(() => {
                AlertService.add('error', 'Something went wrong, please try again!');
            }).finally(() => {
                $uibModalInstance.dismiss('cancel');
            });
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function PdfRdCtrl($rootScope, $timeout, $uibModalInstance, PdfRdDataFactory, workspaceFactory, gridData, AlertService) {
        var vm = this;
        vm.delete = function () {
            var rows = gridData.map(({data}) => data);
            PdfRdDataFactory.deleteRdData(rows).then((data) => {
                if (data.callSuccess === '1') {
                    $timeout(function () {
                        var tableFilters = workspaceFactory.activeScreen.data.tableState.colSearch;
                        var filters = [];
                        for (var i = 0; i < tableFilters.length; i++) {
                            filters.push({});
                            filters[i][tableFilters[i].predicate] = tableFilters[i].value;
                        }
                        var gridFilters = filters.length > 0 && Object.assign(...filters);
                        var filterParams = { gridFilter: gridFilters };
                        var args = {};
                        args = _.merge({}, args, filterParams);
                        $rootScope.$emit('gridUpdate', args);
                    });
                    AlertService.add('success', 'Data deleted successfully!', 4000);
                } else {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            }).catch(() => {
                AlertService.add('error', 'Something went wrong, please try again!');
            }).finally(() => {
                $uibModalInstance.dismiss('cancel');
            });
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    return controllers;
});
