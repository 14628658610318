define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.issuesCtrl', [])
        .controller('issuesListCtrl', ['$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', 'GENERAL_CONFIG', 'DTOptionsBuilder', '$uibModal', 'TAX_YEARS', 'GlobalService', 'workspaceFactory', 'IssuesFactory', issuesListCtrl])
        .controller('issueAddCtrl', ['$rootScope', '$scope', '$state', '$log', 'AlertService', '$timeout', '$uibModalInstance', 'GlobalService', 'IssuesFactory', 'taxYears', 'selectedTaxYear', 'selectedScenario', 'issuesData', 'multiYearDropDownOptions', 'adjTypeOptions', 'timingOptions', issueAddCtrl])
        .controller('issueEditCtrl', ['$rootScope', '$scope', '$state', '$log', 'AlertService', '$timeout', '$uibModalInstance', 'GlobalService', 'IssuesFactory', 'rowData', 'taxYears', 'issuesData', 'multiYearDropDownOptions', 'adjTypeOptions', 'timingOptions', issueEditCtrl])

    function issuesListCtrl($rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, GENERAL_CONFIG, DTOptionsBuilder, $uibModal, TAX_YEARS, GlobalService, workspaceFactory, IssuesFactory) {
        var vm = this;
        vm.filter = {};
        vm.taxYears = TAX_YEARS;
        vm.activeScreenLocal = workspaceFactory.activeScreen;
        vm.issuesMgmtCache = vm.activeScreenLocal.data || {};
        vm.data = vm.issuesMgmtCache.data || null;
        vm.scenarios = vm.issuesMgmtCache.scenarios || [];
        vm.filter.taxYear = vm.issuesMgmtCache.taxYear || GlobalService.inputs.tax_year.selected;
        vm.filter.scenario = vm.issuesMgmtCache.scenario || null;

        vm.multiYearDropDownOptions = [
            {
                value: 'Multi-year',
                desc: 'Multi-year'
            },
            {
                value: 'More than one line',
                desc: 'More than one line'
            }
        ];



        vm.adjTypeOptions = [];
        vm.timingOptions = [
            {
                value: 'Perm',
                desc: 'Perm'
            },
            {
                value: 'Short Term Temp (1-3)',
                desc: 'Short Term Temp (1-3)'
            },
            {
                value: 'Long term temp (3-10)',
                desc: 'Long term temp (3-10)'
            },
            {
                value: 'Others',
                desc: 'Others'
            }
        ];

        IssuesFactory.getAdjustmentTypes(vm.filter.taxYear.value)
            .then((response) => {
                if (response.data.callSuccess === '1') {
                    vm.adjTypeOptions = response.data.jsonObject;
                }
            })
            .catch((error) => {
                console.log(error);
                AlertService.add('error', 'Something went wrong while loading the scenarios for the given tax year, please try again!');
            })
            .finally(() => {

            });

        function datatableInit() {
            vm.dtOptions = {
                data: vm.data,
                extraOptions: {
                    componentSettings: {
                        tableOptions: {
                            order: [[1, 'asc']],
                            rowsGroup: [0, 1, 2, 3],
                            columns: [
                                {
                                    title: 'Issue Id', data: 'issue_id', width: 70, className: 'data-column', render: function (data, type, row) {
                                        if (type === 'display') {
                                            const template = `<span class="edit-issue">${data}
                                                                  <i class="fa fa-pencil edit"></i>
                                                              </span>`;
                                            return template;
                                        } else {
                                            return data;
                                        }
                                    },
                                    actions: [
                                        {
                                            event: 'click', targetSelector: '.edit', callback: function (rowData, event) {
                                                vm.edit(rowData);
                                            }
                                        }
                                    ]
                                },
                                { title: 'Issue Short Description', data: 'issue_short_desc', width: 70, className: 'data-column' },
                                { title: 'Issue Description', data: 'issue_desc', width: 150, className: 'data-column' },
                                { title: 'Standard Description', data: 'qar_desc', width: 150, className: 'data-column' },
                                { title: 'Tax Year', data: 'tax_year', width: 70, className: 'data-column text-center' },
                                { title: 'Scenario', data: 'scenario', width: 70, className: 'data-column' }
                            ],
                            columnDefs: [{
                                targets: [1, 2, 3, 4, 5],
                                orderable: false
                            }],
                            paging: false,
                            searching: true
                        }
                    }
                },
            };
            vm.dtInstance = {};
        }


        // vm.dtColumnDefs = [
        //     {orderable: true, className: 'reorder', targets: 0},
        //     {orderable: false, targets: '_all'},
        // ];


        // $scope.ctrlFn = function (_callName, _data, _type) {
        //     if (_callName === 'issueEdit') {
        //         _data = {rowData: _data.rowData, taxYears: vm.taxYears};
        //         $uibModal.open({
        //             templateUrl: 'app/components/issueManagement/issue-add-edit-tpl.html',
        //             controller: 'issueEditCtrl as ctrl',
        //             backdrop: 'static',
        //             size: "lg",
        //             windowClass: 'right fade',
        //             resolve: {
        //                 rowData: function () {
        //                     return _data.rowData;
        //                 },
        //                 taxYears: function () {
        //                     return vm.taxYears;
        //                 }
        //             }
        //         }).result.then(function (refresh) {
        //             if (refresh) {
        //                 vm.refresh();
        //             }
        //         });
        //     }
        // };

        vm.edit = function (data) {
            const _data = { rowData: data, taxYears: vm.taxYears };
            $uibModal.open({
                templateUrl: 'app/components/issueManagement/issue-add-edit-tpl.html',
                controller: 'issueEditCtrl as ctrl',
                backdrop: 'static',
                size: "lg",
                windowClass: 'right fade',
                resolve: {
                    rowData: function () {
                        return _data.rowData;
                    },
                    taxYears: function () {
                        return vm.taxYears;
                    },
                    issuesData: function () {
                        return vm.data;
                    },
                    multiYearDropDownOptions: function () {
                        return vm.multiYearDropDownOptions;
                    },
                    adjTypeOptions: function () {
                        return vm.adjTypeOptions;
                    },
                    timingOptions: function () {
                        return vm.timingOptions;
                    }
                }
            }).result.then(function (refresh) {
                if (refresh) {
                    vm.getData();
                }
            });
        };

        vm.loadScenarios = function () {
            vm.issuesMgmtCache.taxYear = vm.filter.taxYear;
            vm.filter.scenario = null;
            IssuesFactory.getScenarios(vm.filter.taxYear.value)
                .then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.scenarios = vm.issuesMgmtCache.scenarios = response.data.scenarioList;
                        vm.filter.scenario = vm.issuesMgmtCache.scenario = vm.scenarios[0];
                        workspaceFactory.setCache(vm.activeScreenLocal, vm.issuesMgmtCache);
                    } else {
                        vm.scenarios = vm.issuesMgmtCache.scenarios = [];
                        workspaceFactory.setCache(vm.activeScreenLocal, vm.issuesMgmtCache);
                        AlertService.add('error', response.data.errorMessage);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong while loading the scenarios for the given tax year, please try again!');
                })
                .finally(() => {

                });
        };

        !vm.filter.scenario && vm.loadScenarios();

        vm.onScenarioChange = function () {
            vm.issuesMgmtCache.scenario = vm.filter.scenario;
        };

        vm.addIssue = function () {
            var modalObj = {};
            modalObj.template = "app/components/issueManagement/issue-add-edit-tpl.html";
            modalObj.controller = "issueAddCtrl as ctrl";
            modalObj.size = "md";
            modalObj.backdrop = "static";
            modalObj.resolve = {
                taxYears: function () {
                    return vm.taxYears;
                },
                selectedTaxYear: function () {
                    return vm.filter.taxYear;
                },
                selectedScenario: function () {
                    return vm.filter.scenario;
                },
                issuesData: function () {
                    return vm.data;
                },
                multiYearDropDownOptions: function () {
                    return vm.multiYearDropDownOptions;
                },
                adjTypeOptions: function () {
                    return vm.adjTypeOptions;
                },
                timingOptions: function () {
                    return vm.timingOptions;
                }
            }
            $scope.openModal(modalObj);
        };

        // vm.refresh = function () {
        //     if (!vm.filter.taxYear || !vm.filter.scenario) {
        //         AlertService.add('warning', 'Tax year and scenario are required, please select and try again!', 4000);
        //         return;
        //     }
        //     getData();
        // };

        vm.getData = function () {
            vm.loading = true;
            IssuesFactory.getAllIssues().then(function (data) {
                if (data.data.callSuccess === "1") {
                    vm.data = vm.issuesMgmtCache.data = data.data.issue;
                    workspaceFactory.setCache(vm.activeScreenLocal, vm.issuesMgmtCache);
                    datatableInit();
                } else {
                    vm.issuesMgmtCache.data = [];
                    workspaceFactory.setCache(vm.activeScreenLocal, vm.issuesMgmtCache);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            }).catch((err) => {
                AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
            }).finally(() => {
                vm.loading = false;
            });
        };
        vm.getData();

        $scope.openModal = function (modalObj) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controller,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: modalObj.resolve
            });

            modalInstance.result.then(function (refresh) {
                if (refresh) {
                    vm.getData();
                }
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });
        };

    }

    function issueAddCtrl($rootScope, $scope, $state, $log, AlertService, $timeout, $uibModalInstance, GlobalService, IssuesFactory, taxYears, selectedTaxYear, selectedScenario, issuesData, multiYearDropDownOptions, adjTypeOptions, timingOptions) {
        var vm = this;
        vm.title = 'Create New Issue';
        vm.taxYears = taxYears;
        vm.data = issuesData;
        vm.selectedTaxYear = selectedTaxYear;
        vm.stdDescList = [];
        vm.taxYearScenarios = [];
        vm.selectdTYScenario = [];
        vm.invalidTaxYearScenarios = true;
        vm.multiYearDropDownOptions = multiYearDropDownOptions;
        vm.adjTypeOptions = adjTypeOptions;
        vm.timingOptions = timingOptions;
        vm.multiYear = false;
        vm.effectYrs = 'No';
        vm.loadingData = false;
        vm.multiselDropdownSettings = {
            buttonClasses: 'mul-select-btn',
            showCheckAll: true,
            showUncheckAll: true,
            checkBoxes: true,
            displayProp: 'scenario_desc',
            smartButtonMaxItems: 1,
            scrollable: true,
            scrollableHeight: 'auto',
        };

        vm.mulSelectCustomTexts = {
            buttonDefaultText: 'Select scenario',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };
        vm.multiselectEvents = {
            // onItemSelect: function (item) {
            //     vm.selectdTYScenario.indexOf(item) === -1 ? vm.selectdTYScenario.push(item) : vm.selectdTYScenario;
            // },
            // onItemDeselect: function (item) {
            //     vm.selectdTYScenario.indexOf(item) !== -1 ? vm.selectdTYScenario.splice(vm.selectdTYScenario.indexOf(item), 1) : vm.selectdTYScenario;
            // },
            onSelectionChanged: function () {
                vm.selectdTYScenario = [];
                vm.taxYearScenarios.forEach(i => i.selectedScenario.length > 0 ? vm.selectdTYScenario.push(i.selectedScenario) : vm.selectdTYScenario);
                if (vm.selectdTYScenario.length > 0) {
                    vm.invalidTaxYearScenarios = false;
                } else {
                    vm.invalidTaxYearScenarios = true;
                }
            },
            // onSelectAll : function(e){
            //     console.log(e);
            // },
            // onSelectAll() : function(){

            // },
        };
        vm.loadAdjustmentTypes = function () {
            vm.selectedType = null;
            IssuesFactory.getScenarios(vm.selectedTaxYear.value)
                .then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.adjustmentTypes = response.data.scenarioList;
                        vm.selectedType = vm.adjustmentTypes[0];
                    } else {
                        AlertService.add('error', response.data.errorMessage);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong while loading the scenarios for the given tax year, please try again!');
                })
                .finally(() => {

                });
        };

        vm.loadAdjustmentTypes();

        vm.getTYScenarios = function () {
            IssuesFactory.getTYScenarios()
                .then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.taxYearScenarios = response.data.taxyearScenarioList;
                        vm.taxYearScenarios.map(i => i.selectedScenario = []);
                    } else {
                        AlertService.add('error', 'Error while getting tax year and scenarios');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong while loading the tax year and scenarios!');
                })
                .finally(() => {

                });
        }
        vm.getTYScenarios();


        IssuesFactory.getStdDesc()
            .then((response) => {
                if (response.data.callSuccess === '1') {
                    vm.stdDescList = response.data.stdDescList;
                } else {
                    AlertService.add('error', response.data.errorMessage);
                }
            })
            .catch((error) => {
                console.log(error);
                AlertService.add('error', 'Something went wrong while loading the QAR standard descriptions!');
            })
            .finally(() => {

            });

        // watch changes to the scenario selection and manually trigger validations on shortDesc and detailedDesc
        $scope.$watch((scope) => {
            return vm.selectedType;
        }, (newValue, oldValue) => {
            if (!newValue || !vm.issueAddEditForm)
                return;
            vm.issueAddEditForm.shortDesc.$validate();
            vm.issueAddEditForm.detailedDesc.$validate();
        });

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function () {
            vm.shortDescription = null;
            vm.detailedDescription = null;
            if (vm.issueAddEditForm) {
                vm.issueAddEditForm.$setPristine();
            }
        };

        vm.save = function () {
            if (vm.issueAddEditForm.$invalid) {
                return;
            }
            vm.saving = true;
            // var txYrObj = vm.selectdTYScenario.reduce(function (obj, item) {
            //     obj[item.tax_year] = obj[item.tax_year] || [];
            //     obj[item.tax_year].push(item.scenario);
            //     return obj;
            // }, {});
            var txYrObj = {};
            vm.selectdTYScenario.forEach(i => i.forEach(j => {
                txYrObj[j.tax_year] = txYrObj[j.tax_year] || [];
                txYrObj[j.tax_year].push(j.scenario);
            }));
            var codeSeqMapStr = '';
            for (var obj in txYrObj) {
                codeSeqMapStr += `${obj}=${txYrObj[obj].toString()}&`;
            }
            codeSeqMapStr += `issue_desc=${encodeURIComponent(vm.detailedDescription)}&`;
            codeSeqMapStr += `issue_short_desc=${encodeURIComponent(vm.shortDescription)}&`;
            codeSeqMapStr += `std_desc_id=${vm.selectedStdDesc.std_desc_id}&`;
            codeSeqMapStr += `primary_citation=${vm.primaryCitation ? vm.primaryCitation : ''}&`;
            codeSeqMapStr += `multi_yr=${vm.multiYear == true ? vm.multiYearDropDown.value : 'NA'}&`;
            codeSeqMapStr += `multi_yr_check=${vm.multiYear}&`;
            codeSeqMapStr += `adj_type=${vm.adjType ? vm.adjType.CODE_DESC : ''}&`;
            codeSeqMapStr += `timing=${vm.timing ? vm.timing.value : ''}&`;
            codeSeqMapStr += `effect_other_yrs=${vm.effectYrs}&`;
            codeSeqMapStr += `explanation=${vm.explanation ? vm.explanation : ''}`;

            IssuesFactory.saveIssue(codeSeqMapStr)
                .then((response) => {
                    if (response.data.callSuccess === "0") {
                        AlertService.add("error", response.data.errorMessage);
                    } else {
                        AlertService.add("success", "New Issue Created Successfully!", 4000);
                        // const refresh = checkGridFilters();
                        $uibModalInstance.close(true); // refresh grid after issue creation if tax year and scenario selection matches grid filter selections
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong!');
                })
                .finally(() => {
                    vm.saving = false;
                });
        };

        function checkGridFilters() {
            return vm.selectedTaxYear === selectedTaxYear && vm.selectedType.scenario_type_code === selectedScenario.scenario_type_code;
        }

        vm.toggleMultiYear = function () {
            if (vm.multiYear) {
                vm.multiYearDropDown = vm.multiYearDropDownOptions[0];
            }
        }
    }

    function issueEditCtrl($rootScope, $scope, $state, $log, AlertService, $timeout, $uibModalInstance, GlobalService, IssuesFactory, rowData, taxYears, issuesData, multiYearDropDownOptions, adjTypeOptions, timingOptions) {
        var vm = this;
        vm.data = issuesData;
        vm.rowData = rowData;
        vm.taxYears = taxYears;
        vm.selectedTaxYear = _.find(vm.taxYears, { value: vm.rowData.tax_year.toString() });
        vm.shortDescription = vm.rowData.issue_short_desc;
        vm.detailedDescription = vm.rowData.issue_desc;
        vm.title = 'Edit Issue Details';
        vm.multiYearDropDownOptions = multiYearDropDownOptions;
        vm.adjTypeOptions = adjTypeOptions;
        vm.timingOptions = timingOptions;
        vm.multiYear = false;
        vm.effectYrs = 'No';
        vm.loadingData = false;

        vm.taxYearScenarios = [];
        vm.selectdTYScenario = [];
        vm.multiselDropdownSettings = {
            buttonClasses: 'mul-select-btn',
            showCheckAll: true,
            showUncheckAll: true,
            checkBoxes: true,
            displayProp: 'scenario_desc',
            smartButtonMaxItems: 1,
            scrollable: true,
            scrollableHeight: 'auto',
        };

        vm.mulSelectCustomTexts = {
            buttonDefaultText: 'Select scenario',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };
        vm.multiselectEvents = {
            // onItemSelect: function (item) {
            //     vm.selectdTYScenario.indexOf(item) === -1 ? vm.selectdTYScenario.push(item) : vm.selectdTYScenario;
            //     vm.saving = false;
            // },
            // onItemDeselect: function (item) {
            //     vm.saving = false;
            //     vm.selectdTYScenario.indexOf(item) !== -1 ? vm.selectdTYScenario.splice(vm.selectdTYScenario.indexOf(item), 1) : vm.selectdTYScenario;
            // },
            onSelectionChanged: function () {
                vm.saving = false;
                vm.selectdTYScenario = [];
                vm.taxYearScenarios.forEach(i => i.selectedScenario.length > 0 ? vm.selectdTYScenario.push(i.selectedScenario) : vm.selectdTYScenario);
            }
        };

        vm.loadAdjustmentTypes = function () {
            vm.selectedType = null;
            IssuesFactory.getScenarios(vm.selectedTaxYear.value)
                .then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.adjustmentTypes = response.data.scenarioList;
                        vm.selectedType = _.find(vm.adjustmentTypes, { scenario: vm.rowData.scenario.toString() });
                    } else {
                        AlertService.add('error', response.data.errorMessage);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong while loading the scenarios for the given tax year, please try again!');
                })
                .finally(() => {

                });
        };

        vm.loadAdjustmentTypes();

        vm.getTYScenarios = function () {
            vm.selectdTYScenario = [];
            vm.loadingData = true;
            IssuesFactory.getTYScenarios()
                .then((response) => {
                    if (response.data.callSuccess === '1') {
                        vm.taxYearScenarios = response.data.taxyearScenarioList;
                        IssuesFactory.getIssueDetails(vm.rowData.issue_id)
                            .then((res) => {
                                let scList = res.data.issue.issueTyScenarioList;
                                // vm.taxYearScenarios.map(i =>  { scList.map(j => { if(i.tax_year === j.tax_year){console.log(j);}})});
                                vm.taxYearScenarios.map(function (i) {
                                    scList.map(j => {
                                        if (i.tax_year === j.tax_year) {
                                            let tyScList = [];
                                            j.scenarioList.forEach(k => tyScList.push(k.scenario));
                                            i.selectedScenario = i.scenarioList.filter(k => tyScList.indexOf(k.scenario) > -1);
                                            return i;
                                        } else {
                                            i.selectedScenario = i.selectedScenario && i.selectedScenario.length > 0 ? i.selectedScenario : [];
                                        }
                                    });
                                    return i;
                                });
                                vm.taxYearScenariosCopy = angular.copy(vm.taxYearScenarios);
                                $scope.$watch('ctrl.taxYearScenarios', (newValue, oldValue) => {
                                    if (newValue) {
                                        if (angular.equals(newValue, vm.taxYearScenariosCopy)) {
                                            vm.issueAddEditForm.$setPristine();
                                            return;
                                        }
                                        checkSelectedScenario(newValue);
                                    }
                                }, true);

                                vm.primaryCitation = res.data.issue.primary_citation ? res.data.issue.primary_citation : '';
                                vm.multiYear = res.data.issue.multi_yr_check == "true" ? true : false;

                                if (vm.multiYear) {
                                    vm.multiYearDropDownOptions.forEach((val, idx) => {
                                        if (val.value == res.data.issue.multi_yr) {
                                            vm.multiYearDropDown = vm.multiYearDropDownOptions[idx];
                                        }
                                    });
                                }
                                vm.adjTypeOptions.forEach((val, idx) => {
                                    if (val.CODE_DESC == res.data.issue.adj_type) {
                                        vm.adjType = vm.adjTypeOptions[idx];
                                    }
                                });
                                vm.timingOptions.forEach((val, idx) => {
                                    if (val.value == res.data.issue.timing) {
                                        vm.timing = vm.timingOptions[idx];
                                    }
                                });
                                vm.effectYrs = res.data.issue.effect_other_yrs;
                                vm.explanation = res.data.issue.explanation ? res.data.issue.explanation : '';
                                vm.taxYearScenarios.forEach(i => i.selectedScenario && i.selectedScenario.length > 0 ? vm.selectdTYScenario.push(i.selectedScenario) : vm.selectdTYScenario);

                                vm.loadingData = false;

                            });
                    } else {
                        AlertService.add('error', 'Error while getting tax year and scenarios');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong while loading the tax year and scenarios!');
                })
                .finally(() => {

                });
        }
        vm.getTYScenarios();

        function checkSelectedScenario(taxYearScenarioList) {
            for (let i = 0; i < taxYearScenarioList.length; i++) {
                if (taxYearScenarioList[i].selectedScenario && taxYearScenarioList[i].selectedScenario.length) {
                    vm.invalidTaxYearScenarios = false;
                    vm.issueAddEditForm.$setDirty();
                    return;
                }
            }
            vm.invalidTaxYearScenarios = true;
        }

        IssuesFactory.getStdDesc()
            .then((response) => {
                if (response.data.callSuccess === '1') {
                    vm.stdDescList = response.data.stdDescList;
                    vm.selectedStdDesc = _.find(vm.stdDescList, { std_desc_id: vm.rowData.std_desc_id.toString() });
                } else {
                    AlertService.add('error', response.data.errorMessage);
                }
            })
            .catch((error) => {
                console.log(error);
                AlertService.add('error', 'Something went wrong while loading the QAR standard descriptions!');
            })
            .finally(() => {

            });

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function () {
            vm.shortDescription = vm.rowData.issue_short_desc;
            vm.detailedDescription = vm.rowData.issue_desc;
            vm.selectedStdDesc = _.find(vm.stdDescList, { std_desc_id: vm.rowData.std_desc_id.toString() });
            vm.taxYearScenarios = angular.copy(vm.taxYearScenariosCopy);
            if (vm.issueAddEditForm) {
                vm.issueAddEditForm.$setPristine();
            }
        };

        vm.save = function () {
            if (vm.issueAddEditForm.$invalid) {
                return;
            }
            vm.saving = true;
            // var txYrObj = vm.selectdTYScenario.reduce(function (obj, item) {
            //     obj[item.tax_year] = obj[item.tax_year] || [];
            //     obj[item.tax_year].push(item.scenario);
            //     return obj;
            // }, {});
            var txYrObj = {};
            vm.selectdTYScenario.forEach(i => i.forEach(j => {
                txYrObj[j.tax_year] = txYrObj[j.tax_year] || [];
                txYrObj[j.tax_year].push(j.scenario);
            }));
            var codeSeqMapStr = '';
            for (var obj in txYrObj) {
                codeSeqMapStr += `${obj}=${txYrObj[obj].toString()}&`;
            }
            codeSeqMapStr += `issue_desc=${vm.detailedDescription}&`;
            codeSeqMapStr += `issue_short_desc=${vm.shortDescription}&`;
            codeSeqMapStr += `std_desc_id=${vm.selectedStdDesc.std_desc_id}&`;
            codeSeqMapStr += `issue_id=${vm.rowData.issue_id}&`;
            codeSeqMapStr += `std_desc_id=${vm.selectedStdDesc.std_desc_id}&`;
            codeSeqMapStr += `primary_citation=${vm.primaryCitation ? vm.primaryCitation : ''}&`;
            codeSeqMapStr += `multi_yr=${vm.multiYear == true ? vm.multiYearDropDown.value : 'NA'}&`;
            codeSeqMapStr += `multi_yr_check=${vm.multiYear}&`;
            codeSeqMapStr += `adj_type=${vm.adjType ? vm.adjType.CODE_DESC : ''}&`;
            codeSeqMapStr += `timing=${vm.timing ? vm.timing.value : ''}&`;
            codeSeqMapStr += `effect_other_yrs=${vm.effectYrs}&`;
            codeSeqMapStr += `explanation=${vm.explanation ? vm.explanation : ''}`;
            IssuesFactory.updateIssue(codeSeqMapStr)
                .then((response) => {
                    if (response.data.callSuccess === "0") {
                        AlertService.add("error", response.data.errorMessage);
                    } else {
                        AlertService.add("success", "Issue Updated Successfully!", 4000);
                        $uibModalInstance.close(true); // refresh grid after issue update
                    }
                })
                .catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong!');
                })
                .finally(() => {
                    vm.saving = false;
                });
        };

        vm.toggleMultiYear = function () {
            if (vm.multiYear) {
                vm.multiYearDropDown = vm.multiYearDropDownOptions[0];
            }
        }
    }

    return controllers;
})
    ;
