define([

	'angular'

], function () {

	'use strict';

	var controllers = angular.module('app.settlementMainController', [])
		.controller('settlementMainController', ['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'JsonObjectFactory', 'settlementMainFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', settlementMainController]);

	function settlementMainController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, JsonObjectFactory, settlementMainFactory, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory) {
		var vm = this;
		vm.modalData = [];
		vm.disable = true;
		vm.loading = false;
		vm.crudLoading = false;
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		vm.rowData = rowData;
		vm.remFederalAmt = vm.rowData['Federal_Method_of_Settlement_Remaining'];
		vm.remStateAmt = vm.rowData['State_Method_of_Settlement_Remaining']
		vm.federalData = [];
		vm.stateData = [];
		vm.newRow = { federal: false, state: false };
		vm.amount = 0;
		vm.federalCodes = [];
		vm.stateCodes = [];

		var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
		getModalData();
		function getModalData() {
			vm.loading = true;
			settlementMainFactory.fetchModalData(rowData, vm.logged_in_user, filterParams.scenario).then(response => {
				vm.loading = false;
				vm.tableData = response.jsonObject;
				vm.tableData.map((value) => {
					if (value.LINE_DESCRIPTION === "Federal Method of Settlement - ADN Allocated") {
						vm.federalData.push({ ...value, IS_EDIT_MODE: 'N' });
					}
					if (value.LINE_DESCRIPTION === "State Method of Settlement - ADN Allocated") {
						vm.stateData.push({ ...value, IS_EDIT_MODE: 'N' });
					}
				})
				var getAdnBucCodes = settlementMainFactory.getAdnBucCodes();
				var getSettlementTypes = settlementMainFactory.getSettlementTypes();
				Promise.all([getAdnBucCodes, getSettlementTypes]).then(response => {
					vm.loading = false;
					vm.adnBucCodes = response[0].jsonObject;
					vm.typesData = response[1].jsonObject;

					vm.adnBucCodes.map((value) => {
						if (value.SETT_METHOD.toUpperCase() === 'ALL' || value.SETT_METHOD.toUpperCase() === 'FEDERAL') {
							vm.federalCodes.push(value);
						}
						if (value.SETT_METHOD.toUpperCase() === 'ALL' || value.SETT_METHOD.toUpperCase() === 'STATE') {
							vm.stateCodes.push(value);
						}
					})

				});
			});

		}

		vm.AddNewRow = function (type) {
			vm.disable = false;
			if (type === 'Federal') {

				vm.federalData.push({
					FORM_NAME: "F_SETTLEMENT",
					METHOD_OF_SETTLEMENT: "Federal Method of Settlement",
					LINE_DESCRIPTION: "Federal Method of Settlement - Allocated",
					IS_CHANGED_FLAG: "I",
					SETTLEMENT_TYPE: vm.typesData.length ? vm.typesData[0].LABEL : '',
					BUC_CODE: vm.federalCodes.length ? vm.federalCodes[0].BUC_CODE : '',
					ADN_CODE: vm.federalCodes.length ? vm.federalCodes[0].ADN_CODE : '',
					AMOUNT: 0,
					OCCURRENCE: vm.occurence > 0 ? vm.occurence : vm.federalData.length + 1,
					ADN_BUC_CODE_KEY: vm.federalCodes.length ? vm.federalCodes[0].ADN_BUC_CODE_KEY : '',
					COMBINATION_KEY: vm.rowData.COMBINATION_KEY,
					ATTRIB_NAME: 'SETTLE_FED_METHOD_ALLOC_ADN',
					TRANS_DETAILS_KEY: vm.transKey > 0 ? vm.TRANS_DETAILS_KEY : 0,
					IS_EDIT_MODE: 'Y',
					OTHER_DESC: '',
				})
				vm.amount = 0;
				vm.adn = vm.federalCodes[0];
				vm.buc = vm.federalCodes[0];
				vm.settlementType = vm.typesData[0];
				vm.occurence;
				vm.newRow.federal = true;


			}
			else {

				vm.stateData.push({
					FORM_NAME: "F_SETTLEMENT",
					METHOD_OF_SETTLEMENT: "State Method of Settlement",
					LINE_DESCRIPTION: "State Method of Settlement - Allocated",
					IS_CHANGED_FLAG: "I",
					SETTLEMENT_TYPE: vm.typesData.length ? vm.typesData[0].LABEL : '',
					BUC_CODE: vm.stateCodes.length ? vm.stateCodes[0].BUC_CODE : '',
					ADN_CODE: vm.stateCodes.length ? vm.stateCodes[0].ADN_CODE : '',
					AMOUNT: 0,
					OCCURRENCE: vm.occurence > 0 ? vm.occurence : vm.stateData.length + 1,
					ADN_BUC_CODE_KEY: vm.stateCodes.length ? vm.stateCodes[0].ADN_BUC_CODE_KEY : '',
					COMBINATION_KEY: vm.rowData.COMBINATION_KEY,
					ATTRIB_NAME: 'SETTLE_STATE_METHOD_ALLOC_ADN',
					TRANS_DETAILS_KEY: vm.transKey > 0 ? vm.TRANS_DETAILS_KEY : 0,
					IS_EDIT_MODE: 'Y',
					OTHER_DESC: ''
				})
				vm.amount2 = 0;
				vm.adn2 = vm.stateCodes[0];
				vm.buc2 = vm.stateCodes[0];
				vm.settlementType2 = vm.typesData[0];
				vm.occurence2;
				vm.newRow.state = true;

			}

		}

		vm.add = function (type, item) {
			if (type === 'Federal') {
				item.AMOUNT = parseFloat(item.AMOUNT) + parseFloat(vm.remFederalAmt);
			}
			else {
				item.AMOUNT = parseFloat(item.AMOUNT) + parseFloat(vm.remStateAmt);
			}

		}

		vm.overwrite = function (type, item) {
			if (type === 'Federal') {
				item.AMOUNT = vm.remFederalAmt;
			}
			else {
				item.AMOUNT = vm.remStateAmt;
			}
		}

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		}

		vm.reset = function () {
			getModalData();
		}

		vm.save = function () {

			vm.crudLoading = true;
			vm.clob_settings = [{ tax_year: GlobalService.globalParams.tax_year }];

			vm.finalData = [...vm.federalData, ...vm.stateData];
			if (vm.checkErrors()) {
				settlementMainFactory.saveModal(vm.finalData, vm.clob_settings).then((response) => {
					vm.crudLoading = false;
					if (response.data.errorMessage != 'null') {
						AlertService.add("error", response.data.errorMessage, 4000);
					} else {
						AlertService.add("success", "Save Successful", 4000);
						$uibModalInstance.dismiss('cancel');
						var args = {};
						$rootScope.$emit('gridUpdate', args);
					}

				})
			}


		}

		vm.checkErrors = function () {
			let error = '';
			vm.finalData.forEach(item => {
				if(item.IS_CHANGED_FLAG !== 'D') {
					if (item.SETTLEMENT_TYPE != 'IBS' && item.OTHER_DESC == '') {
						error += 'Other Code cannot be empty. ';
					}
					if (item.AMOUNT == 0) {
						error += 'Amount cannot be 0.';
					}
				}
			});
			if (error != '') {
				AlertService.add("error", error, 4000);
				return false;
			}
			return true;
		}

		vm.dismiss = function (type) {
			if (type === 'Federal') {
				vm.newRow.federal = false;
			}
			else {
				vm.newRow.state = false;
			}
		}

		vm.valuechanged = function (codeType, type, item) {
			if (item.SETTLEMENT_TYPE == 'IBS') {
				if (type === 'Federal') {
					if (codeType === 'buc') {
						vm.federalCodes.map((value, index) => {
							if (value.BUC_CODE === item.BUC_CODE) {
								item.ADN_CODE = vm.federalCodes[index].ADN_CODE;
								item.ADN_BUC_CODE_KEY = vm.federalCodes[index].ADN_BUC_CODE_KEY;
							}
						})
					}
					else {
						vm.federalCodes.map((value, index) => {
							if (value.ADN_CODE === item.ADN_CODE) {
								item.BUC_CODE = vm.federalCodes[index].BUC_CODE;
								item.ADN_BUC_CODE_KEY = vm.federalCodes[index].ADN_BUC_CODE_KEY;
							}
						})
					}
				}
				else {
					if (codeType === 'buc') {
						vm.stateCodes.map((value, index) => {
							if (value.BUC_CODE === item.BUC_CODE) {
								item.ADN_CODE = vm.stateCodes[index].ADN_CODE;
								item.ADN_BUC_CODE_KEY = vm.federalCodes[index].ADN_BUC_CODE_KEY;
							}
						})
					}
					else {
						vm.stateCodes.map((value, index) => {
							if (value.ADN_CODE === item.ADN_CODE) {
								item.BUC_CODE = vm.stateCodes[index].BUC_CODE;
								item.ADN_BUC_CODE_KEY = vm.federalCodes[index].ADN_BUC_CODE_KEY;
							}
						})
					}
				}
			}


		}

		vm.removeExistingRecord = function (index, type) {
			if (type === 'Federal') {
				vm.federalData.map((value, idx) => {
					if (value.OCCURRENCE === index) {
						vm.federalData[idx].IS_CHANGED_FLAG = 'D';
					}
				})
			}
			else if (type === 'State') {
				vm.stateData.map((value, idx) => {
					if (value.OCCURRENCE === index) {
						vm.stateData[idx].IS_CHANGED_FLAG = 'D';
					}
				})
			}
			vm.disable = false;
		}

		vm.editExistingRecord = function (item, type) {
			vm.disable = false;
			item.IS_EDIT_MODE = 'Y';
			item.IS_CHANGED_FLAG = vm.transKey > 0 ? 'U' : 'I';

		}

		vm.saveNewEdittedRecord = function (type, item) {
			let error = '';
			if (item.SETTLEMENT_TYPE != 'IBS' && item.OTHER_DESC == '') {
				error += 'Other Code cannot be empty. ';
			}
			if (item.AMOUNT == 0) {
				error += 'Amount cannot be 0.';
			}

			if (error == '') {
				item.IS_EDIT_MODE = 'N';
				item.IS_CHANGED_FLAG = vm.transKey > 0 ? 'U' : 'I';
			}
			else {
				AlertService.add("error", error, 4000);
			}



		}

	}

	return controllers;

});
