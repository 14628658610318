define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.retainedEarningCtrl', [])

    .controller('retainedEarnCtrl', ['CommonUtilitiesFactory','$scope','$rootScope', '$state', '$http', 'ModalFactory', 'AlertService', '$timeout', '$filter',
              'GlobalService', 'JsonObjectFactory', '$parse','$injector','workspaceFactory',
              'GENERAL_CONFIG', 'USER_SETTINGS','$uibModalInstance','rowData','colData', 'gridData',retainedEarnCtrl])

    .controller('retainedEarnCtrlModal', ['CommonUtilitiesFactory','$scope','$rootScope', '$state', '$http', 'ModalFactory', 'AlertService', '$timeout', '$filter',
              'GlobalService', 'JsonObjectFactory','$parse','$injector','workspaceFactory',
              'GENERAL_CONFIG', 'USER_SETTINGS',retainedEarnCtrlModal]);

  function retainedEarnCtrlModal(CommonUtilitiesFactory,$scope,$rootScope, $state, $http, ModalFactory, AlertService, $timeout,$filter,
    GlobalService, JsonObjectFactory, $parse,$injector,workspaceFactory,GENERAL_CONFIG,USER_SETTINGS) {
      retainedEarnCtrl(CommonUtilitiesFactory,$scope,$rootScope, $state, $http, ModalFactory, AlertService, $timeout,$filter,
        GlobalService, JsonObjectFactory, $parse,$injector,workspaceFactory,GENERAL_CONFIG,USER_SETTINGS, null, null, null, null, this);
  }

    function retainedEarnCtrl(CommonUtilitiesFactory,$scope,$rootScope, $state, $http, ModalFactory, AlertService, $timeout,$filter,
                	GlobalService, JsonObjectFactory, $parse,$injector,workspaceFactory,GENERAL_CONFIG,USER_SETTINGS, $uibModalInstance, rowData, colData, gridData, thisObj) {
           var  vm = this || thisObj;
              	vm.a = "";
                vm.loading = false;
                vm.modalTitle = "Retained Earnings / Capital Account Reconciliation";
               // vm.rowData=rowData;
               vm.rowData = (rowData != null) ? rowData : $scope.$parent.rowData;
                vm.testing = 0;
                vm.colData=colData;
                vm.filingGroup=CommonUtilitiesFactory.filingGroups;
                vm.cyopen=false;
                vm.pyopen=false;
                console.log(vm.filingGroup, "vm.filingGroupvm.filingGroupvm.filingGroup");
                console.log("FIlinggroups+++++",vm.filingGroup);
                console.log("rowData++++++",vm.rowData);
                console.log("colData+++++",vm.colData);

                if($uibModalInstance) {
                  vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
                  vm.cancel = function () {
                    var args = {combination_keys: vm.rowData.HO_combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.HO_combination_key}};
                    $rootScope.$emit('gridUpdate', args);
                    $uibModalInstance.close();
                    vm.modal_name = null;
                  };
                } else {
                  vm.init = function(){
                	  var vm = thisObj;
                      vm.loading = true;

                	  var params = {
                			  "combination_key":vm.rowData.COMBINATION_KEY,
                			  "tax_year":vm.rowData.TAX_YEAR,
                			  "scenario":GlobalService.globalParams.scenario,
                			  "jcd_key":250,
                			  "filing_key":vm.rowData.CONSOL_GROUP_KEY,

              			};
                	  console.log("Input Data x **********::", params);
                	  return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=ehcu0t", params).then(function(data) {

                		  console.log("Response Data x ::", data.jsonObject);
							if (data.callSuccess === "1") {
								vm.loading = false;
								vm.rowData = data.jsonObject[0];
								console.log("Response Data x ::", vm.rowData);
								 vm.rowData.PY_RE_AMT = vm.py_local_acct_amt;
			                     vm.rowData.CY_RE_AMT = vm.cy_local_acct_amt;
			                     vm.oldExplanation=vm.rowData.EXPLANATION;
							}
                	  });

                  }

                }



                vm.oldExplanation=vm.rowData.EXPLANATION;
                vm.newExplanation="";
                vm.username = "By "+USER_SETTINGS.user.first_name + " " + USER_SETTINGS.user.last_name + " On: "+ new Date+ "\n";

               /*  vm.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                }; */

                vm.showDetailsCY = function (_index, _boolean) {
                  vm.cyopen = _boolean;
                };

                vm.showDetailsPY = function (_index, _boolean) {
                  vm.pyopen = _boolean;
                };

                function loadDetails() {
                	vm.testing = 1;
                	vm.loading = true;
                	//alert("In Load Details ****");
                  var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                  var params = _.merge({}, GlobalService.globalParams, filterParams);
                  params.combination_key = vm.rowData.COMBINATION_KEY;
                  params.filing_key = vm.rowData.CONSOL_GROUP_KEY;
                //params.chart_key = 27829409;
                 // params.system_acct_key = 3564174;
					console.log("Input Data x **********::", params);
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=f8ovay", params).then(function(data) {
										console.log("Response Data x ::", data.jsonObject);
										if (data.callSuccess === "1") {

                      var cntcy = 0, cntpy=0;
                      vm.cy_local_acct_dtls = [];
                      vm.py_local_acct_dtls = [];
                      vm.cy_local_acct_amt = 0;
                      vm.py_local_acct_amt = 0;

						for (var item in data.jsonObject) {
                             var val = data.jsonObject[item];
                             if(val.CY_PY_FLAG === "CY")
                          {
                             vm.cy_local_acct_dtls[cntcy] = val;
                             vm.cy_local_acct_amt = vm.cy_local_acct_amt + val.AMT;
                             cntcy = cntcy + 1;
                          } else {
                            vm.py_local_acct_dtls[cntpy] = val;
                            vm.py_local_acct_amt = vm.py_local_acct_amt + val.AMT;
                            cntpy = cntpy + 1;
                          }

                      }
                     // vm.rowData.PY_RE_AMT = vm.py_local_acct_amt;
                      //vm.rowData.CY_RE_AMT = vm.cy_local_acct_amt;
                      vm.loading = false;

                      if($uibModalInstance) {
                    	  console.log("rowData++++++",vm.rowData)

                      } else {
                    	  vm.init();
                      }
										} else {
											if (data.errorMessage === "no access") {
												AlertService.add("","Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}

                loadDetails();


                vm.save = function() {
                	vm.loading = true;

                  var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=gpkcx2";
                  var affectedRow = vm.rowData;

                  var jsonObj = JSON.stringify(affectedRow);

                  var jsonSettings = '{}';

                  var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                  var params = _.merge({}, GlobalService.globalParams, filterParams);

                  params = _.extend({jsonObj:jsonObj}, params);
                  params =  _.extend({jsonSettings:jsonSettings}, params);
                  params.process_name =  "RetainedEarnSave";
                  if(vm.newExplanation === undefined || vm.newExplanation === "" ) {
                    params.newExplanation = vm.oldExplanation;
                  } else if(vm.oldExplanation === undefined || vm.oldExplanation === "" ) {
                    params.newExplanation = vm.username + " \n" + vm.newExplanation;
                  }  else {
                    params.newExplanation = vm.username + " \n" + vm.newExplanation + " \n" + vm.oldExplanation;
                  }



                  var service = $injector.get('JsonObjectFactory');
                  service.saveJSON(url , params).then(function(result){

                      if(result.callSuccess === "1"){
                        AlertService.add("success", "Data saved Successfully.", 4000);
                        vm.rowData.EXPLANATION = params.newExplanation;
                        vm.newExplanation = "";

                      }else{
                        if(result.errorMessage){
                          AlertService.add("error",result.errorMessage,4000);
                        }else{
                          AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");

                        }
                      }

                      vm.loading = false;
                      var args = {combination_keys: vm.rowData.HO_combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.HO_combination_key}};                      $rootScope.$emit('gridUpdate', args);
                      $uibModalInstance.dismiss('cancel');


                  });
              };


              /* save from workflow 5/14/2019 */
              $scope.$on('workflowSave',function(event,data){
                  vm.save();
                  vm.loading = false;
              })

            }
           return controllers;


});

