
define([
    'angular',
    './TasksController'


], function () {
    'use strict';
    return angular.module('app.tasks', ['app.TasksController'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider
                .state('tasks', {
                    url: '/tasks',
                    templateUrl: 'app/components/tasks/tasks.tpl.html',
                    data:{ncyBreadcrumbLabel: 'Tasks'},
                    access:""
                })
        }])
});