define([
    'angular',
    './sourcing-DefaultCtrl',
], function () {
    'use strict';
    return angular.module('app.FIR_Sourcing_Default_Me', ['app.sourcing-DefaultCtrl']) 
    /*.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider) {
        $stateProvider
            .state('FIR_Sourcing_Default_Me', {
                url: '/FIR_Sourcing_Default_Me',
                templateUrl: 'app\components\FIR_Sourcing_Default_Me\sourcing-Default.html',
                controller: 'sourcingDefaultByMeCtrl as ctrl',
                data: {},
                access: ""
            })
    	}]);*/
    });
