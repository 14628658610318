define(
    ['moment', 'angular'

    ],
    function (moment) {
        'use strict';

        var controllers = angular
            .module('app.EntityChartCleanupController', [])
            .controller('EntityChartCleanupController', ['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance',
                'ModalFactory', 'AlertService', 'rowData', 'gridData', 'JsonObjectFactory', 'EntityChartFactory', '$timeout',
                'GENERAL_CONFIG', '$window', 'USER_SETTINGS', 'workspaceFactory', EntityChartCleanupController])

        function EntityChartCleanupController($rootScope, $scope, $http,
            GlobalService, $uibModalInstance, ModalFactory,
            AlertService, rowData, gridData, JsonObjectFactory,
            EntityChartFactory, $timeout, GENERAL_CONFIG, $window, USER_SETTINGS, workspaceFactory) {

            var vm = this;
            var vm = this;
            vm.modalData = [];
            vm.disable = true;
            vm.loaded = false;
            vm.crudLoading = false;

            vm.baseURLs = {};

            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
            vm.gridData = JSON.stringify(gridData);
            vm.baseURLs = JSON.stringify(vm.baseURLs);
            vm.globalParams = JSON.stringify(GlobalService.globalParams);
            vm.transferData = {
                gridData: vm.gridData,
                baseURLs: vm.baseURLs,
                globalParams: vm.globalParams
            }
            vm.loaded = true;
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
            if ($uibModalInstance) {
                $timeout(() => {
                    $window.document.querySelector('gtw-entity-chart-map-clean-up')
                        .addEventListener('request-grid-update', (event) => {
                            if (event.detail == 'refresh') {
                                $timeout(function () {
                                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });
                                }, 3000);
                                
                            }

                            $uibModalInstance.close();
                        });
                }, 500);
            }
        }
        return controllers;

    }
);