define(
		[ 'angular'],
		function() {
			'use strict';

			var services = angular
					.module('app.sync163jtriggerservice', [])

					.factory("trigger163jsyncFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'AlertService', '$injector', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG','USER_SETTINGS',
						function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, AlertService, $injector, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,USER_SETTINGS) {
							var trigger163jsyncFactory = {};
							trigger163jsyncFactory.url = {};
							trigger163jsyncFactory.url.rerun = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vr35gl";
							trigger163jsyncFactory.url.amt_sync = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=r7zv3b";

							trigger163jsyncFactory.triggerSync = function(_data,Details, modalName){
									
								var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
								var jsonObj = JSON.stringify(Details);
								var jsonSettings =  JSON.stringify(_data);
								var url_val = (modalName == 'trigger163jamtsync' ? trigger163jsyncFactory.url.amt_sync : trigger163jsyncFactory.url.rerun);
								
								console.log(jsonSettings);
								var params = filterParams;
								params.combination_key = _data.combination_key;
								params.sso_id = _data.sso_id;
								params = _.merge({},  GlobalService.globalParams , params);
								params =  _.extend({clob_data:jsonObj}, params);
								params =  _.extend({clob_settings:jsonSettings}, params);
								params.process_name =  (modalName == 'trigger163jamtsync' ? "RUN_163J_TB_AMT_SYNC" : "TRIGGER_SYNC_163J_PROCESS"); 
								console.log(params);
								var promise = $http({
									method: "post",
									url: url_val,
									data: params
								}).then(function (response) {
									var status = response.status;
									return response;
								});
								return promise;
							};
							return trigger163jsyncFactory;
						}]);
			        
					return services;

		});