define(
    ['angular'],
    function () {
        'use strict';

        var services = angular.module('app.estqtrmaintenanceService',[])
            .factory('estqtrmaintenanceServiceFactory',
                ['$q', '$http', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG',estqtrmaintenanceServiceFactory]);

        function estqtrmaintenanceServiceFactory($q, $http, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG) 
        {
            var factory = {};
            factory.quarterList = [];
            factory.allQuarterList = [];
            factory.getMaintanceData = function(_params,action_id){
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id='+action_id,
                    data: _params
                }).then(function (response) {
                    return response.data;
                });

                return promise;
        };
        factory.setQuarterList = function(quarterList){
            factory.quarterList = quarterList;
        }
        factory.getQuarterList = function(){
            return factory.quarterList;
        }
        factory.setALLQuarterList = function(allQuarterList){
            factory.allQuarterList = allQuarterList;
        }
        factory.getALLQuarterList = function(){
            return factory.allQuarterList;
        }
          
        return factory;
        }
        return services;
    }
);