define([
   'angular'

], function () {
   'use strict';

   var controllers =  angular.module('app.notifCtrl',[])
     
       .controller('notifListCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','ModalFactory','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','GENERAL_CONFIG', notifListCtrl])
       .controller('notifCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','$uibModalInstance', 'JsonObjectFactory','GENERAL_CONFIG', notifCtrl])
       .controller('notifDetailCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','SERVER_CONFIG','GENERAL_CONFIG', notifDetailCtrl])
       .controller('notifPublishersCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','SERVER_CONFIG','GENERAL_CONFIG', notifPublishersCtrl])
       .controller('notifRenderCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','SERVER_CONFIG','GENERAL_CONFIG', notifRenderCtrl])
       .controller('notifWriterCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','SERVER_CONFIG','GENERAL_CONFIG', notifWriterCtrl])
       .controller('notifMaintCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','ModalFactory','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','GENERAL_CONFIG', notifMaintCtrl])
       
       function notifMaintCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG){
	   			var vm = this;
	   			vm.title = "Maintenance Notification";
	   			vm.loading = false;
	   			console.log("notifMaintCtrl controller fired");
                vm.close = function(){
		            $uibModalInstance.dismiss('cancel');
		        };
		        
		        vm.save = function(){
		        	console.log("Saving Maintenance Notification");
		        };
       }
       function notifCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,$uibModalInstance, JsonObjectFactory,GENERAL_CONFIG) {
		   var vm = this;
		   //vm.user_id = $state.params.data.user_id;
	       vm.title = "Notifications";
	       vm.loading = false;
	       
	       vm.parentString = 'admin.notif-engine';
	       
	        vm.cancel = function(){
	            $uibModalInstance.dismiss('cancel');
	        };
	        
	        vm.save = function(){
	        	$uibModalInstance.dismiss('cancel');
	        };
	        
	        vm.setTitle = function(title){
	            vm.title = title;
	        };
	        vm.refresh = function(){
	            $rootScope.$broadcast("gridRefresh", true);
	        };
		}
   
   function notifPublishersCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,SERVER_CONFIG,GENERAL_CONFIG) {
	   var vm = this;
	   vm.notif = $state.params.data.rowData;
	   
       vm.loading = false;
       
       $scope.$parent.ctrl.setTitle(vm.notif.NOTIFDETID);
	  
	   
	   vm.save = function(){
           var data = {};
           $scope.$parent.ctrl.cancel();
	   }
	   
	   vm.cancel = function(){
           $scope.$parent.ctrl.cancel();
       };
     
	}
   function notifRenderCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,SERVER_CONFIG,GENERAL_CONFIG) {
	   var vm = this;
	   vm.notif = $state.params.data.rowData;
	   
       vm.loading = false;
       
       $scope.$parent.ctrl.setTitle(vm.notif.NOTIFDETID);
	  
	   
	   vm.save = function(){
           var data = {};
           $scope.$parent.ctrl.cancel();
	   }
	   
	   vm.cancel = function(){
           $scope.$parent.ctrl.cancel();
       };
     
	}
   
   function notifWriterCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,SERVER_CONFIG,GENERAL_CONFIG) {
	   var vm = this;
	   vm.notif = $state.params.data.rowData;
	   
       vm.loading = false;
       
       $scope.$parent.ctrl.setTitle(vm.notif.NOTIFDETID);
	  
	   
	   vm.save = function(){
           var data = {};
           $scope.$parent.ctrl.cancel();
	   }
	   
	   vm.cancel = function(){
           $scope.$parent.ctrl.cancel();
       };
     
	}
   function notifDetailCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,SERVER_CONFIG,GENERAL_CONFIG) {
	   var vm = this;
	   vm.notif = $state.params.data.rowData;
	   
       vm.loading = false;
       
       $scope.$parent.ctrl.setTitle(vm.notif.NOTIFDETID);
	  
	   
	   vm.save = function(){
           var data = {};
           $scope.$parent.ctrl.cancel();
	   }
	   
	   vm.cancel = function(){
           $scope.$parent.ctrl.cancel();
       };
     
	}
      
   
   
   function notifListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG) {
       var vm = this;
       vm.name = "notifListCtrl";
       vm.api = $state.current.api.get;
       vm.data = [];
       vm.showGrid = true;
       vm.notifType = 1;
       
       vm.notifs = [    	  
               {label:"View All  Notifications" , value: 1 },
               {label:"View Master Data" , value: 2},
               {label:"Maintenance Notifications" , value: 3 }             
          
       ];
       
       vm.dateFilter = {
    		   value_name: "VALUE",
    		   label_name: "LABEL"
       }
       
       vm.dateFilter1 = {
    		   value_name: "VALUE",
    		   label_name: "LABEL"
       }
       
       vm.onNotifChange = function(){
    	   vm.showGrid = false;
       }
       
       vm.notifEngine = function(){
    	   
    	   
    	   if(vm.notifType ==1){
    		   var args = {
                      start_dt :  vm.dateFilter1.selected? vm.dateFilter1.selected.VALUE:"",
                      end_dt :  vm.dateFilter.selected? vm.dateFilter.selected.VALUE: ""
               }
    		   vm.viewAllNotifications(args);
    	   }
    	   else if(vm.notifType ==2){
    		   
    		   vm.viewMasterData();
    	   }
    	   else if(vm.notifType ==3){
    		   
    		   vm.viewMaintenance();
    	   }
       }
       
       

       var viewNotifURL = GENERAL_CONFIG.base_url + '/loadGridJson?action_code=nn30ik&grid_id=187';
       var viewNotifSetURL = GENERAL_CONFIG.base_url + '/loadGridJson?action_code=fuwfzt&grid_id=189';
        var maintenanceURL = GENERAL_CONFIG.base_url + '/loadGridJson?action_code=6zlcxh&grid_id=190';
        
        vm.viewAllNotifications = function(args){
        	$state.current.api.get = viewNotifURL;
           getData(args);
       };

       vm.viewMasterData = function(){
    	   $state.current.api.get = viewNotifSetURL;
           getData();
       };

       vm.viewMaintenance = function(){
    	   $state.current.api.get = maintenanceURL;
           getData();
       };
       
       var getData = function(args) {
           $scope.$broadcast("showLoader", true);
           vm.loading = true;
           vm.gridData = [];
           vm.gridData.DATA = [];
           vm.showGrid = true;

           GridFactory.getDataCustom($state.current.api.get, args).then(function (data) {
               if (data.callSuccess === "1") {
               	vm.data = data;
                   $scope.$broadcast("dataLoaded", data);
                   $scope.$broadcast("showLoader", false);
               } else {
                   AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
               }
           });
       }

      /* vm.viewAllNotifications();*/
       
       $scope.ctrlFn = function(_callName,_data,_type) {

           if(_type === 'columnTemplate'){
               GridSaveFactory.colTemplatesUpdate($state.current.api.grid_id,_callName,_data);
           }else if(_callName === 'notification'){
               _data = {rowData:_data.rowData }
               $state.go('admin.notif-engine.notif.details' ,  {data :_data });
           }else if(_callName === 'maintNotif'){
               _data = {rowData:_data.rowData }
               $state.go('admin.notif-engine.notif.maint' ,  {data :_data });
           }

       };

            

   }              
   return controllers;
 });



