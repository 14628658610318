define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.formViewTabServices', [])
        .factory("FormViewTabFactory", ['$http', 'GENERAL_CONFIG', 'GlobalService','GridFactory',
        'NewProformaFactory','$q',
             function ($http, GENERAL_CONFIG, GlobalService,GridFactory,NewProformaFactory,$q) {
                var FormViewTabFactory = {};
                FormViewTabFactory.getTabGridData = function(data){
                 var activeTabGrid = _.find(NewProformaFactory.tabList,{code:data.tabId})
                 activeTabGrid.loading_data = true;
                    var refresh = false ;
                    if (!refresh && activeTabGrid.gridData) {
                        var def = $q.defer();
                        def.resolve(activeTabGrid);
                        activeTabGrid.gridData.firstLoad = 0;
                        activeTabGrid.loading_data = false; // Grid is cached so mark as loaded
                        activeTabGrid.fromModal = false;
                       // workspaceFactory.checkFilters(workspaceFactory.activeScreen);
                        return def.promise;
                    }
                    var canceler = $q.defer();
                    var promise = GridFactory.getDataCustom(data.api,data.params,canceler.promise).then(function (response) {
                            var gridData = response;
                            activeTabGrid.gridData = gridData;
                            activeTabGrid.loading_data = false;
                            activeTabGrid.cachedData = true;
                            return activeTabGrid;
                        });
                    return promise;
                }
            return newProformaTabFactory;
        }]);
    return services;
});
