define([
    'angular',
    './controversyDashboardComponentCtrl'

], function () {
    'use strict';

  
   return angular.module('app.controversy-dashboard', ['app.controversyDashboardComponentCtrl'])
   .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
    $stateProvider
        .state('controversy-dashboard', {
            url: '/controversy/dashboard',
            templateUrl: 'app/components/ControversyApp/controversy-dashboard/dashboard.html',
            controller: 'controversyDashboadController as ctrl',
            type:'custom',
        });
    }]);
   
});