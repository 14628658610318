define([
    'angular'
], function () {
    'use strict';


    var controllers = angular.module('app.ghostOwnershipCtrl',  [])
	.controller('ghostOwnershipCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
		'GlobalService','USER_SETTINGS','ghostStatusSaveFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData','gridData', ghostOwnershipCtrl]);


    	function ghostOwnershipCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope, ModalFactory, AlertService, $timeout,
					   GlobalService,USER_SETTINGS,ghostStatusSaveFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {	
				
				
			}


});