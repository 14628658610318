define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.FormScheduleAService', [])

        .factory("Form1120LScheduleAFactory", ['$q','$rootScope','AlertService', '$injector','GENERAL_CONFIG','GlobalService',
            function ($q, $rootScope, AlertService, $injector,GENERAL_CONFIG,GlobalService) {
                var factory = {};
                    
                factory.saveF1120LScheduleADetails = function (_callName, _data, isNew) {
                    var clob_setting = {};
                    var clob_data = [];
                    var params = {};
                    var defer = $q.defer();
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=j3nkw2";
                    clob_data.push(_data.rowData);
                    clob_setting = {tax_year: _data.rowData.TAX_YEAR};
                    
                    params.process_name = "SCH_A_EDIT_SAVE";
                    params = _.extend({
                        clob_data: JSON.stringify(clob_data)
                    }, params);
                    params = _.extend({
                        clob_setting: JSON.stringify(clob_setting)
                    }, params)
                    params = _.merge({},GlobalService.globalParams, params);
                    
                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);
                                var args = {
                                    tax_year: _data.rowData.TAX_YEAR
                                };
                                $rootScope.$emit('gridUpdate', args);
                                defer.resolve(data);
                        } else if(data.errorMessage && data.errorMessage != "") {
                            AlertService.add("error", data.errorMessage, 4000);
                            defer.reject(data);
                        }else {
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                            defer.reject(data);
                        }
                    });
                }
                return factory;
            }
        ])
    return services;
});