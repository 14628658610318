define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.StatementTrackerService', [])
                          .factory('TrackerService', ['$rootScope', '$http', '$q', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'GENERAL_CONFIG', 'USER_SETTINGS',
                              function ($rootScope, $http, $q, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, USER_SETTINGS) {
                                  var TrackerFactory = {};
                                  return TrackerFactory;
                              }]);
    return services;
});
