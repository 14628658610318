define([
    'angular',
    './ghostOwnershipInfoScreenService', 
    './ghostOwnershipInfoScreenCtrl',
    './ghostOwnershipInfoDeleteCtrl',
    
], function () {
    'use strict';
    return angular.module('app.FIR_Ghost_Ownership_Info_Screen', ['app.ghostOwnershipInfoScreenService','app.ghostOwnershipInfoScreenCtrl','app.ghostOwnershipInfoDeleteCtrl'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        // $stateProvider
        //     .state('sourcing-input-screen', {
        //         url: '/sourcing-input-screen',
        //         templateUrl: 'app/components/FIR_Sourcing_Input/sourcingInputScreen.html',
        //         backdrop: 'static',
        //         data:{},
        //         access:""
        //     })
    }])

});