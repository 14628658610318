
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.relatedPartyModalServce',[])

        .factory('relatedPartyModalFactory',['$q','$http','$timeout','$rootScope','$log','DataFiltersFactory','workspaceFactory','JsonObjectFactory','AlertService','GlobalService','GENERAL_CONFIG',
        function relatedPartyModalFactory($q,$http,$timeout,$rootScope,$log,DataFiltersFactory,workspaceFactory,JsonObjectFactory,AlertService,GlobalService,GENERAL_CONFIG) {
            var factory = {};

            factory.saveNewExplanation = function(data, json_settings, optional_params){                
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=b67t0o";
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var json_data = JSON.stringify(data);     
                var json_settings = JSON.stringify(json_settings);              
                var params = filterParams;

                params = _.merge({},  GlobalService.globalParams , params);
                params = _.extend({json_data:json_data}, params);
                params = _.extend({json_settings:json_settings}, params);               
                params.code_desc = optional_params.code_desc;                
               
                console.log(params);
                
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            }

            factory.saveVarExplanation = function(data, json_settings, optional_params){                
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=otidof";
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var json_data = JSON.stringify(data);     
                var json_settings = JSON.stringify(json_settings);              
                var params = filterParams;

                params = _.merge({},  GlobalService.globalParams , params);
                params = _.extend({json_data:json_data}, params);
                params = _.extend({json_settings:json_settings}, params);               
                params.expln_text = optional_params.expln_text;    
                params.expln_type = optional_params.expln_type;              
               
                console.log(params);
                
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            }

            factory.removeVarExplanation = function(data, json_settings){                
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wr9xus";
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var json_data = JSON.stringify(data);     
                var json_settings = JSON.stringify(json_settings);              
                var params = filterParams;

                params = _.merge({},  GlobalService.globalParams , params);
                params = _.extend({json_data:json_data}, params);
                params = _.extend({json_settings:json_settings}, params);
               
                console.log(params);
                
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            }
            

            return factory;

        }]);


    return services;


});
