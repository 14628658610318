
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.cfcInvestmentAdjService',[])

        .factory("cfcInvestmentAdjFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, workspaceFactory, USER_SETTINGS, $rootScope) {
            var cfcInvestmentAdjFactory = {};           
            cfcInvestmentAdjFactory.url = {};
            cfcInvestmentAdjFactory.url.adj_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=5haw2j"           
            
            cfcInvestmentAdjFactory.saveData =  function(_data,Details){
            	console.log("===============     Calling Service using CFCInvestmentAdjFactory  =========================", _data);
                
            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                //params =  _.extend({ind:'U'}, params);
                console.log("Save params :",params);
            	
                var promise = $http({
                    method: "post",
                    url: cfcInvestmentAdjFactory.url.adj_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };
            
            return cfcInvestmentAdjFactory;
        }])


    return services;


});