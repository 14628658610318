define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.basketMaintenanceService', [])
     .factory("basketMaintenanceFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
    	 var basketMaintenanceFactory = {};
    	 basketMaintenanceFactory.formConfigData = {};
    	 var basketMaintenanceFactory = {};
    	 basketMaintenanceFactory.url = {};
    	 basketMaintenanceFactory.url.saveNewBasket  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=owfq9a";
    	 basketMaintenanceFactory.url.deleteBasket = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=obachr";

    	 
    	 basketMaintenanceFactory.getExistingBaskets = function(tax_year) {
              var params = {
            		  "action_code":"alx0go", "tax_year": tax_year
            		  };
              return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
            	  console.log("After action call the Response was::::::", data);
      	         if(null != data.callSuccess && data.callSuccess == "1"){
      	        	basketMaintenanceFactory.formConfigData = data;
      	        	 return data;
      	         }else{
      	        	 AlertService.add("error", data.errorMessage);
                       return false;
      	         }
      	     });
      	 }
    	 
          
            basketMaintenanceFactory.getSourceCodes = function (tax_year) {
              var params = {
                  "action_code":"7lsdkp", "tax_year": tax_year
                  };
              return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
            	  console.log("After action call the Response was::::::", data);
       	         if(null != data.callSuccess && data.callSuccess == "1"){
       	        	basketMaintenanceFactory.formConfigData = data;
       	        	 return data;
       	         }else{
       	        	 AlertService.add("error", data.errorMessage);
                        return false;
       	         }
       	     });
       	 }
    	 
          
          
          basketMaintenanceFactory.getFtcCodes = function (tax_year) {
              var params = {
                  "action_code":"8a85he" ,"tax_year": tax_year
                  };

              return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
            	  console.log("After action call the Response was::::::", data);
        	         if(null != data.callSuccess && data.callSuccess == "1"){
        	        	basketMaintenanceFactory.formConfigData = data;
        	        	 return data;
        	         }else{
        	        	 AlertService.add("error", data.errorMessage);
                         return false;
        	         }
        	     });
        	 }
    	 
          basketMaintenanceFactory.getPassiveCodes = function (tax_year) {
              var params = {
                  "action_code":"qhxqzz", "tax_year": tax_year
                	  };
              return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
            	  if(null != data.callSuccess && data.callSuccess == "1"){
      	        	basketMaintenanceFactory.formConfigData = data;
      	        	 return data;
      	         }else{
      	        	 AlertService.add("error", data.errorMessage);
                       return false;
      	         }
      	     });
      	 }
          
          basketMaintenanceFactory.getSubfCodes = function (tax_year) {
              var params = {
                  "action_code":"396a36", "tax_year": tax_year
                	  };
              return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
            	  if(null != data.callSuccess && data.callSuccess == "1"){
        	        	basketMaintenanceFactory.formConfigData = data;
        	        	 return data;
        	         }else{
        	        	 AlertService.add("error", data.errorMessage);
                         return false;
        	         }
        	     });
        	 }
          
          basketMaintenanceFactory.getFphciCodes = function (tax_year) {
              var params = {
                  "action_code":"h43mo3", "tax_year": tax_year
                	  };
              return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
            	  if(null != data.callSuccess && data.callSuccess == "1"){
      	        	basketMaintenanceFactory.formConfigData = data;
      	        	 return data;
      	         }else{
      	        	 AlertService.add("error", data.errorMessage);
                       return false;
      	         }
      	     });
      	 }
          
          basketMaintenanceFactory.get902Name = function (tax_year) {
              var params = {
                  "action_code":"oly2ic", "tax_year": tax_year
                	  };
              return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
            	  if(null != data.callSuccess && data.callSuccess == "1"){
        	        	basketMaintenanceFactory.formConfigData = data;
        	        	 return data;
        	         }else{
        	        	 AlertService.add("error", data.errorMessage);
                         return false;
        	         }
        	     });
        	 }
    	 
    	 basketMaintenanceFactory.addNewBasket = function(basketData, clob_settings, sso_id){
    		 var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=owfq9a";
    		 var jsonSettings = JSON.stringify(clob_settings);
    		 var jsonObj = JSON.stringify(basketData);
    		 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		 var params = filterParams;
    		 params = _.merge({},  GlobalService.globalParams , params);
    		 params =  _.extend({jsonObj:jsonObj}, params);
    		 params =  _.extend({jsonSettings:jsonSettings}, params);
    		 params.sso_id = sso_id;
    		console.log(params);
    		 var promise = $http({
    			 	method: "post",
    			 	url: url,
    		        data: params
    		     }).then(function (response) {
    		    	 var status = response.status;
    		         return response.data;
    		     });
    		 return promise;
    	 }
    	 
    	  
    	 
    	 basketMaintenanceFactory.deleteBasket = function(basketData, clob_settings, sso_id){
    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=obachr";
    		 var jsonSettings = JSON.stringify(clob_settings);
    		 var jsonObj = JSON.stringify(basketData);
             var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		 var params = filterParams;
    		 params = _.merge({},  GlobalService.globalParams , params);
    		 //params =  _.extend({jsonObj:jsonObj}, params);
    		 // params =  _.extend({jsonSettings:jsonSettings}, params);
    		 params.sso_id = sso_id;
    		 params.basket_id = basketData[0].basket_id;
    		 //params.filing_group =  1;
    		 console.log(params);
    		 var promise = $http({
    			 	method: "post",
    		        url: url,
    		        data: params
    		     }).then(function (response) {
    		    	 var status = response.status;
    		         return response.data;
    		     });
    		 return promise;
    	 }
     
    	 return basketMaintenanceFactory;
     }]);
    
    return services;
});

