
define([
    'angular',
    './OrgController.js'


], function () {
    'use strict';
    return angular.module('app.orgChart', ['app.OrgChartController'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {

            $stateProvider
                .state('orgchart', {
                    url: '/orgchart',
                    templateUrl: 'app/components/orgChart/org_chart_tpl.html',
                    data:{},
                    access:""
                })

        }])

});