define([
    'highstock',
    'angular',
    './dashboardExecutiveCtrl.js',
    './dashboardService.js',
    './dashboardDirectives.js',
    './dashboardFilters.js',
    './dashboardDirectives.js',
    './dashboardConfig.js',
    './dashboardProjectReview/projectReviewDirective.js',
    './dashboardProjectReview/ProjectReviewService.js',
    './dashboardDatatable/dashboardDatatable.js',
    './dashboardDatatable/dashboardDataTableNew.js',
    './dashboardDatatable/dashboardDirectivesNew.js'
], function (Highcharts) {
    'use strict';

    // Load module after Highcharts is loaded
    require('highcharts-more')(Highcharts); 
    require('highcharts-3d')(Highcharts); 
    require('highcharts-exporting')(Highcharts); 
    require('highcharts-treemap')(Highcharts); 
    require('highcharts-variable-pie')(Highcharts); 
    require('highcharts-drilldown')(Highcharts); 

    Highcharts.setOptions({
        lang: {
            numericSymbols: ['K', 'M', 'B','T']
        }
    });

    return angular.module('app.dashboardExecutive', ['app.dashboardExecutiveCtrl', 'app.projectReviewDirective', 'app.projectReviewService',
    'app.dashboardServices','app.dashboardDirectives',
     'app.dashboardFilters' , 'app.dashboardDatatable',
     'app.dashboardDirectivesNew','app.dashboardConfig',
     'app.dashboardDatatableNew'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider

                .state('executive-dashboards', {
                    url: '/executive-dashboards',
                    templateUrl: 'app/components/dashboardExecutive/index.html',
                    backdrop: 'static',
                    data:{},
                    access:""
                })
                .state('executive-dashboards-new', {
                    url: '/executive-dashboards-new',
                    templateUrl: 'app/components/dashboardExecutive/executive-dashboards.html',
                    controller:'StandardDashboardController as ctrl'
                })


                /*
                .state('executive-dashboards.summary', {
                    url: '/executive-summary',
                    templateUrl: 'app/components/dashboardExecutive/templates/dashboard_summry_tpl.html',
                    data:{},
                    access:""
                })
                */

                .state('executive-dashboards.wwni', {
                    url: '/executive-wwni',
                    templateUrl: 'app/components/dashboardExecutive/templates/dashboard-wwni-tpl.html',
                    backdrop: 'static',
                    data:{},
                    access:""
                })


                .state('executive-dashboards.ti', {
                    url: '/executive-ti',
                    templateUrl: 'app/components/dashboardExecutive/templates/dashboard-ti-tpl.html',
                    backdrop: 'static',
                    data:{},
                    access:""
                })

                // Not using this, we might need to delete its entire related files(NEED TO MAKE SURE WHO ARE USING THIS BEFORE DELETING).
                // .state('executive-dashboards.project-review', {
                //     url: '/executive-project-review',
                //     templateUrl: 'app/components/dashboardExecutive/templates/dashboard-project-review-tpl.html',
                //     data:{},
                //     access:""
                // })

                .state('executive-dashboards.ftc', {
                    url: '/executive-ftc',
                    templateUrl: 'app/components/dashboardExecutive/templates/dashboard-ftc-tpl.html',
                    backdrop: 'static',
                    data:{},
                    access:""
                })

                .state('executive-dashboards.m3', {
                    url: '/executive-m3',
                    templateUrl: 'app/components/dashboardExecutive/templates/dashboard-m3-tpl.html',
                    backdrop: 'static',
                    data:{},
                    access:""
                })

                // .state('project-review', {
                //     url: '/workspace/project-review',
                //     templateUrl: 'app/components/dashboardExecutive/templates/project-review-component-tpl.html',
                //     backdrop: 'static',
                //     data:{},
                //     access:""
                // })

		        // .state('executive-dashboards.project-review', {
                //     url: '/executive-project-review',
                //     templateUrl: 'app/components/dashboardExecutive/templates/project-review-component-tpl.html',
                //     backdrop: 'static',
                //     data:{},
                //     access:""
                // })

                // Material Schedule-M
                .state('executive-dashboards.material-schedule-m', {
                    url: '/material-schedule-m',
                    templateUrl: 'app/components/dashboardExecutive/templates/material-schecdule-M.html',
                    data:{},
                    controller: 'materialSchdMCtrl as ctrl',
                    backdrop: 'static',
                    access:""
                })

                // Business Sign-Off
                .state('executive-dashboards.business-signOff', {
                    url: '/business-signOff',
                    templateUrl: 'app/components/dashboardExecutive/templates/business-signOff.html',
                    data:{},
                    controller: 'businessSignOffCtrl as ctrl',
                    backdrop: 'static',
                    access:""
                })

                // FSI Dashboard
                .state('executive-dashboards.fsi', {
                    url: '/executive-fsi',
                    templateUrl: 'app/components/dashboardExecutive/templates/dashboard-fsi-tpl.html',
                    data:{},
                    access:""
                })

                // FDII Dashboard
                .state('executive-dashboards.fdii', {
                    url: '/executive-fdii',
                    templateUrl: 'app/components/dashboardExecutive/templates/dashboard-fdii-tpl.html',
                    data:{},
                    access:""
                })


            }]);

});

