/**
 * Created by 400371031 on 1/20/2021.
 */
define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.relatedPartyPaymentController',[])
        .controller('relatedPartyPaymentController', 
        ['$scope', '$rootScope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService',
         'rowData', 'gridData' , 'workspaceFactory', 'JsonObjectFactory','$timeout','GENERAL_CONFIG', 'USER_SETTINGS',
         'relatedPartyPaymentFactory', relatedPartyPaymentController])


    function relatedPartyPaymentController( $scope, $rootScope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, workspaceFactory, JsonObjectFactory,$timeout,GENERAL_CONFIG,USER_SETTINGS,relatedPartyPaymentFactory){

  
        var vm = this;
        
        console.log("=======relatedPartyPaymentData=======");
        console.log(gridData);
        console.log("=======rowData=======");
        console.log(rowData);
        var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        console.log(gridFilters);
		vm.is_allocate_access = false;
        
		vm.extPayorData			 = [];
		vm.allocatedPayors    	 = [];  
        vm.tp_pool_list       	 = [];
        vm.tp_dropdown_data      = [];
        vm.tp_me_dropdown_data   = [];
        vm.payorAccountData   	 = [];
		vm.entityOriginalData    = [];
		vm.le_key_list		     = [];
		vm.M3payorList           = [];
		vm.clob_settings		 = [];
		vm.cc_acct_clob			 = [];
		vm.le_issue_clob		 = [];
		vm.screen_list	 		 = [{"KEY": 10818, "LABEL": "Related Party Payments Reconciliation", "TYPE": "Reconciliation"}, 
								    {"KEY": 10805, "LABEL": "Related Party Payments - Sys Accts", "TYPE": "SYS"},
								    {"KEY": 10800, "LABEL": "Related Party Payments - Sys & Local Accts", "TYPE": "LOCAL"},
								    {"KEY": 10834, "LABEL": "M3 Payor Reconciliation Grid", "TYPE": "M3Payor"}];
		
		vm.active_screen_key = workspaceFactory.activeScreen.screen_key;
		vm.source_screen_type = '';
		vm.crudLoading = false;		
		vm.userSelectedData = gridData;
		vm.globalScenarioDesc	= workspaceFactory.activeScreen.dataCurFilters[1].valueLabel;
		vm.payment_type 		= gridFilters.category_filter;
		vm.classification_type 	= gridFilters.category_filter;
		vm.trans_type 			= vm.userSelectedData[0].data.TRANS_TYPE;
		console.log(vm.payment_type);
		console.log(vm.globalScenarioDesc);
		vm.le_keys			= "";
        vm.title 			= "Payor Allocation";       
        vm.taxYear  		= GlobalService.globalParams.tax_year;   
        vm.scenario 		= GlobalService.globalParams.scenario;
        vm.taxYear  		= GlobalService.globalParams.jcd_key;
        vm.glbl_issue_id  	= GlobalService.globalParams.issue_id;        
        vm.userSettings 	= USER_SETTINGS;
		vm.logged_in_user 	= vm.userSettings.user.sso_id;
		vm.M3_Payor_types 	= [{"PAYOR_TYPE": 'Foreign Corp', "TYPE": 'FC'},
			   				   {"PAYOR_TYPE": 'Domestic Corp', "TYPE": 'DC'},	
			   				   {"PAYOR_TYPE": 'Domestic Partnership', "TYPE": 'DP'},
			   				   {"PAYOR_TYPE": 'Foreign Partnership', "TYPE": 'FP'}];
		
  
        $scope.loadingAccounts = false;
        $scope.existingLoading = false;
        $scope.noRecords = false;
        $scope.fromScreen = false;                
        $scope.delTransDetails = [];
        $scope.local_acc_dropdown_data = [];
        $scope.tp_dropdown_data = [];
        $scope.taxYearBegin = "";
        $scope.taxYearEnd = "";
        $scope.lazyLoadObj = {};   
           
   
        
        vm.cancel = function () {
        	if(angular.equals(vm.entityData, vm.entityOriginalData)){
				$uibModalInstance.dismiss('cancel');
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
				}
				else if(confirm("Do you want to close Form without saving changes?")){
					$uibModalInstance.dismiss('cancel');
				}
			}
        };  
		
		vm.allocate_payor = function(param){
			var message = "Allocation process was success";
        	var o = vm.payorAccountData.reduce( (a,b) => {
				a[b.CC_KEY] = a[b.CC_KEY] || [];
				a[b.CC_KEY].push(b);
				return a;
			}, {});
			
			console.log("object o-------", o);
			
			if(param == 'A'){
				var a = [{CC_KEY: null, leid: null, cdr_number: null, reporting_period: null, me_code: null, LEDGER: null}];
			}else{
				var a = Object.keys(o).map(function(k){
					return { CC_KEY: o[k][0].CC_KEY, leid: o[k][0].LEID, cdr_number: o[k][0].CDR_NO, reporting_period: o[k][0].REPORTING_PERIOD, me_code:o[k][0].ME_CODE, LEDGER:o[k][0].LEDGER };
				});
			}
			
			
			console.log("object a-------", a);				
			vm.allocateprocessdata = a;
			vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
			vm.crudLoading = true;
			relatedPartyPaymentFactory.allocateProcess(vm.allocateprocessdata, vm.clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
        };
	    
	  	vm.save = function(){
			vm.finalData = [];
			vm.clob_settings = [];
			
			console.log("Original Data::::::::==========", vm.entityOriginalData);
			console.log("vm.entityData::::", vm.entityData);
			
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			
			vm.entityFinalData = angular.copy(vm.entityData);
			if(angular.equals(vm.entityFinalData, vm.entityOriginalData)){
				vm.cancel();
			} else {
				
				var returnValue = validateFormValues();
				if(returnValue == 1){
					return false;
				};
				
				for(var x=0; x < vm.entityFinalData.length; x++){				
					
					if(vm.entityFinalData[x].Account_data.length != null && vm.entityFinalData[x].Account_data.length != undefined){
						for(var j=0; j < vm.entityFinalData[x].Account_data.length; j++){
							if(angular.equals(vm.trans_type, "BOOK_AMT")){
								vm.entityFinalData[x].Account_data[j].sub_acct_data.splice(0, 1);
							}
							if(vm.entityFinalData[x].Account_data[j].sub_acct_data.length != null && vm.entityFinalData[x].Account_data[j].sub_acct_data.length != undefined){
								for(var k=0; k < vm.entityFinalData[x].Account_data[j].sub_acct_data.length; k++){
									if(vm.entityFinalData[x].Account_data[j].sub_acct_data[k].IS_CHANGED === 'Y' && vm.entityFinalData[x].Account_data[j].sub_acct_data[k].IS_OTHER_PAYOR == 'N'){
										if(!angular.equals(vm.classification_type, "M3PAYOR")){
											vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_LEID   		= vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_LEID;
											vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_CDR_NO 		= vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_CDR_NO;
											vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_REP_PD 		= vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_REP_PD;
											vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_ME_CODE 		= vm.entityFinalData[x].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_ME_CODE;
										}
										
										vm.finalData.push(vm.entityFinalData[x].Account_data[j].sub_acct_data[k]);
										
									}
									else{
										vm.finalData.push(vm.entityFinalData[x].Account_data[j].sub_acct_data[k]);
									}
								}
							}
							
							if(vm.entityFinalData[x].Account_data[j].DELETE_ROWS.length != null && vm.entityFinalData[x].Account_data[j].DELETE_ROWS.length != undefined){
								for(var k=0; k < vm.entityFinalData[x].Account_data[j].DELETE_ROWS.length; k++){
									vm.finalData.push(vm.entityFinalData[x].Account_data[j].DELETE_ROWS[k]);
								}
							}
							
						}
					}
				}
				
				console.log("After changes, the final data was :::::::::::=========", vm.entityFinalData);
				console.log("After changes, the manipulated data was :::::::::::=========", vm.entityData);
				console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
				console.log("Data for the first time from DB was :::::::::::=========", vm.payorAccountData);
				//vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
				//sendDetails(vm.finalData, vm.clob_settings);
				vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
				sendDetailsDB(vm.finalData, vm.clob_settings);
			}
			
		};
		
		function validateFormValues(){
			var returnValue = 0;
			for (var i=0; i < vm.entityData.length; i++){
				if(vm.entityData[i].Account_data.length != null && vm.entityData[i].Account_data.length != undefined){
					for (var j=0; j < vm.entityData[i].Account_data.length; j++){
						if(vm.entityData[i].Account_data[j].sub_acct_data.length != null && vm.entityData[i].Account_data[j].sub_acct_data.length != undefined){
							for (var k=0; k < vm.entityData[i].Account_data[j].sub_acct_data.length; k++){
								if(angular.equals(vm.trans_type, "BOOK_AMT")){
									if(vm.entityData[i].Account_data[j].sub_acct_data[k].ROW_TYPE == 'NEW'){
										if(vm.entityData[i].Account_data[j].sub_acct_data[k].hasOwnProperty('IS_OTHER_PAYOR') &&  vm.entityData[i].Account_data[j].sub_acct_data[k].IS_OTHER_PAYOR == 'Y'){
											if(vm.entityData[i].Account_data[j].sub_acct_data[k].PAYOR_TYPE == null){
												vm.entityData[i].Account_data[j].sub_acct_data[k].HAS_TYPE_ERROR = 'Y';
												vm.entityData[i].Account_data[j].sub_acct_data[k].PAYOR_TYPE_ERROR = 'Please select the Payor Type';
												returnValue = 1;
											}
										} else if(!vm.entityData[i].Account_data[j].sub_acct_data[k].hasOwnProperty('TP_DETAILS') || vm.entityData[i].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_LEID == undefined || vm.entityData[i].Account_data[j].sub_acct_data[k].TP_DETAILS.TP_LEID == null){
											vm.entityData[i].Account_data[j].sub_acct_data[k].HAS_ERROR = 'Y';
											vm.entityData[i].Account_data[j].sub_acct_data[k].TP_ERROR = 'Please assign the Payor';
											returnValue = 1;
										}
									}
								}else {
									if(angular.equals(vm.payment_type, "M3PAYOR") && vm.entityData[i].Account_data[j].sub_acct_data[k].hasOwnProperty('IS_OTHER_PAYOR') && vm.entityData[i].Account_data[j].sub_acct_data[k].IS_OTHER_PAYOR == 'Y'){
										if(vm.entityData[i].Account_data[j].sub_acct_data[k].PAYOR_TYPE == null){
											vm.entityData[i].Account_data[j].sub_acct_data[k].HAS_TYPE_ERROR = 'Y';
											vm.entityData[i].Account_data[j].sub_acct_data[k].PAYOR_TYPE_ERROR = 'Please select the Payor Type';
											returnValue = 1;
										}
									}
									
								}	
							}
						}
					}
				}
			}
			
			return returnValue;
		};
		
		function sendDetailsDB(final_data, clob_settings){
			var message = "Related Party Modal Data was Saved";
			vm.crudLoading = true;
			relatedPartyPaymentFactory.saveRPModal(final_data, clob_settings, vm.classification_type, vm.trans_type).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		};
		
		$scope.getTradingPartners = function (line, val) {
			console.log("Line_no selected is :::::::", line);
            var params = {
                "action_code": 'eosx7n',
                "tax_year": $scope.taxYear,
                "scenario": $scope.scenario,
                "search_string": val,
                "line_no": line
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };
        
        $scope.setTradingPartner = function (tp_detais, entity_index, acct_index, sub_index) {
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].HAS_ERROR = 'N';
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        	if(tp_detais.IS_VALID == 1 || tp_detais.TP_ENTITY != "Unrelated Payor"){
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'N';
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID   		= tp_detais.TP_LEID;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO 		= tp_detais.TP_CDR_NO;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD 		= tp_detais.TP_REP_PD;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE 		= tp_detais.TP_ME_CODE;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_DETAILS 		= {"TP_LEID":tp_detais.TP_LEID, "TP_CDR_NO":tp_detais.TP_CDR_NO, "TP_REP_PD":tp_detais.TP_REP_PD, "TP_ME_CODE":tp_detais.TP_ME_CODE, "TP_ENTITY":""};
    			console.log("vm.entityData:::::::", vm.entityData);
        	}else if (tp_detais.IS_VALID == 0){
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'Y';
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID   		= tp_detais.TP_LEID;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO 		= tp_detais.TP_CDR_NO;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD 		= tp_detais.TP_REP_PD;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE 		= tp_detais.TP_ME_CODE;
        	}
        	if(tp_detais.TP_ENTITY == "Unrelated Payor"){
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'Y';
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID   		= tp_detais.TP_LEID;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO 		= tp_detais.TP_CDR_NO;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD 		= tp_detais.TP_REP_PD;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE 		= tp_detais.TP_ME_CODE;
    			console.log("vm.entityData:::::::", vm.entityData);
        	}
        };
        
        vm.setPayorType = function (payor_type, entity_index, acct_index, sub_index){
        	console.log("payor_type selected is:::::::", payor_type);
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        	if(payor_type != null && payor_type != undefined){
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].HAS_TYPE_ERROR = 'N';
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].PAYOR_TYPE =  payor_type;
        	};
        	console.log("vm.entityData:::::::", vm.entityData);
        };
        
        vm.updatingPayor = function(entity_index, acct_index, sub_index, tp_detais){
        	console.log("updatingPayor the indexes are :::::::", entity_index, acct_index, sub_index);
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].HAS_ERROR = 'N';
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'N';
        	if(tp_detais.TP_ENTITY == "Unrelated Payor"){
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_OTHER_PAYOR = 'Y';
        		vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID   		= tp_detais.TP_LEID;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO 		= tp_detais.TP_CDR_NO;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD 		= tp_detais.TP_REP_PD;
    			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE 		= tp_detais.TP_ME_CODE;
    			console.log("vm.entityData:::::::", vm.entityData);
        	}
        };
        
        vm.updatedDetails = function(entity_index, acct_index, sub_index){
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'Y';
        };
		
		function getM3PayorList(){
			relatedPartyPaymentFactory.getM3PayorList().then(function (data){
        		
        		console.log("::::::getM3PayorList()::::");
        		console.log("M3 Payor List JSON    ::::", data);
        		console.log("M3 Payor List was data.jsonObject:    ::::", data.jsonObject);
        		
        		vm.M3payorList = data.jsonObject;
        	}); 
		};
	 
	    
		function setSelectedParams() {
		
			console.log("-----In setSelectedParams fn----");	
						
			var screen_index = vm.screen_list.findIndex(x => x.KEY == vm.active_screen_key);			
			vm.source_screen_type = (screen_index >= 0) ? vm.screen_list[screen_index].TYPE : '';
			
			console.log("vm.active_screen_key::", vm.active_screen_key);
			console.log("screen_index::", screen_index);
			console.log("vm.source_screen_type::", vm.source_screen_type);
			
			if(vm.source_screen_type != ''){
				if(angular.equals(vm.source_screen_type, "SYS")){
					for (var i=0; i < vm.userSelectedData.length; i++){
						vm.cc_acct_clob.push({"COMBINATION_KEY": vm.userSelectedData[i].data.COMBINATION_KEY,
											  "ACCT_KEY": vm.userSelectedData[i].data.ACCT_KEY,
											  "ACCT": vm.userSelectedData[i].data.ACCT,
											  "ISSUE_KEY": vm.userSelectedData[i].data.ISSUE_KEY});						
	        		}       		
				}else if(angular.equals(vm.source_screen_type, "LOCAL")){
					for (var i=0; i < vm.userSelectedData.length; i++){
						vm.cc_acct_clob.push({"COMBINATION_KEY": vm.userSelectedData[i].data.COMBINATION_KEY,
											  "ACCT_KEY": vm.userSelectedData[i].data.FROM_ACCT_KEY,
											  "ACCT": vm.userSelectedData[i].data.FROM_ACCT,
											  "ISSUE_KEY": vm.userSelectedData[i].data.ISSUE_KEY});				
	        		}       		
				}else{			
					for (var i=0; i < vm.userSelectedData.length; i++){				
						if(angular.equals(vm.payment_type, "M3PAYOR")){
							vm.le_key_list.push(vm.userSelectedData[i].data.COMBINATION_KEY);
						}else{								
							vm.le_issue_clob.push({"LE_KEY": vm.userSelectedData[i].data.LE_KEY,											 
											  	   "ISSUE_KEY": vm.userSelectedData[i].data.ISSUE_KEY});
						}			
		        	}
		        	
		        	vm.le_keys = vm.le_key_list.toString(); 
		        }	
		    }
        	
        	
        	console.log("userSelectedData:::::", vm.userSelectedData);
        	console.log("CC Clob::::::", vm.cc_acct_clob);       
        	console.log("LE Issue Clob::::::", vm.le_issue_clob); 	
		}
		
		function getPayorAccountData(){	
		
        	relatedPartyPaymentFactory.getPayorAccountDetails(vm.le_issue_clob, vm.payment_type, vm.classification_type, vm.trans_type, vm.logged_in_user).then(function (data){
        		
        		console.log("::::::getPayorAccountData()::::");
        		console.log("Payor Account Details:    ::::", data);
        		console.log("Payor data.jsonObject:    ::::", data.jsonObject);
        		
        		$scope.loadingAccounts = false;
        		vm.payorAccountData = data.jsonObject;
        		setPayorModalData();
        	});   
        }
        
        
        function getPayorModalDataByAcct(){	
		
        	relatedPartyPaymentFactory.getPayorModalByAcct(vm.cc_acct_clob, vm.source_screen_type, vm.payment_type, vm.classification_type, vm.trans_type, vm.logged_in_user).then(function (data){
        		
        		console.log("::::::getPayorAccountDataByAcct()::::");        		
        		console.log("data.jsonObject:    ::::", data.jsonObject);
        		
        		$scope.loadingAccounts = false;
        		vm.payorAccountData = data.jsonObject;
        		setPayorModalData();
        	});   
        }
        
        
        function setTradingPartnerDropdownList(){        	
        	for (var i=0; i < vm.payorAccountData.length; i++){	
        					
				var dup_index = vm.tp_pool_list.findIndex(x => x.TP_LEID    === vm.payorAccountData[i].LEID && 
															   x.TP_CDR_NO  === vm.payorAccountData[i].CDR_NO &&
															   x.TP_REP_PD  === vm.payorAccountData[i].REPORTING_PERIOD &&
															   x.TP_ME_CODE === vm.payorAccountData[i].ME_CODE);	
							
				if(dup_index < 0){
								
					 vm.tp_pool_list.push({"TP_LEID":  vm.payorAccountData[i].LEID,
										  "TP_CDR_NO": vm.payorAccountData[i].CDR_NO,
										  "TP_REP_PD": vm.payorAccountData[i].REPORTING_PERIOD,
										  "TP_ME_CODE":vm.payorAccountData[i].ME_CODE,
										  "TP_ENTITY": vm.payorAccountData[i].LEID + "-" 
										  			  		+ vm.payorAccountData[i].CDR_NO + "-" 
										  			  		+ vm.payorAccountData[i].REPORTING_PERIOD + "-" 
										  			  		+ vm.payorAccountData[i].ME_CODE });
					
					if(vm.payment_type.toUpperCase() !== "INTEREST"){
							vm.tp_pool_list.push({"TP_LEID":  vm.payorAccountData[i].LEID,
										  "TP_CDR_NO":  	  vm.payorAccountData[i].CDR_NO,
										  "TP_REP_PD":  	  vm.payorAccountData[i].REPORTING_PERIOD,
										  "TP_ME_CODE": 	  vm.payorAccountData[i].ME_CODE,
										  "TP_ENTITY":  	  vm.payorAccountData[i].LEID + "-" 
										  			  			+ vm.payorAccountData[i].CDR_NO + "-" 
										  			  			+ vm.payorAccountData[i].REPORTING_PERIOD });
					
					}					
				}
        	}   
        	
        	console.log(":::setTradingPartnerDropdownList:::");
        	console.log(":::vm.tp_pool_list:::", vm.tp_pool_list);     	
        }
        
        function setPayorModalData(){        
           
           console.log("---------setPayorModalData()---------");
           console.log(vm.payorAccountData);
           
           //call to set TP dropdown values including ME code
           setTradingPartnerDropdownList();
        	
    		var o = vm.payorAccountData.reduce( (a,b) => {
				a[b.CC_KEY] = a[b.CC_KEY] || [];
				a[b.CC_KEY].push(b);
				return a;
			}, {});
			
			console.log("object o-------", o);
			
			var a = Object.keys(o).map(function(k){
				var m = angular.copy(o[k]);
				if(angular.equals(m[0].IS_ALLOCATION_ACCESS, "Y")){
					vm.is_allocate_access = true;
				}
				return { CC_KEY:o[k][0].CC_KEY , LEID: o[k][0].LEID, CDR_NO: o[k][0].CDR_NO, REP_PD:o[k][0].REPORTING_PERIOD, ME_CODE:o[k][0].ME_CODE					  					  
					   , TP_ME_CODE: o[k][0].TP_ME_CODE || "", TOTAL_ALLOCATED_AMT: 0, UNALLOCATED_AMT: 0, LEDGER:o[k][0].LEDGER, SOURCE_SYSTEM_FLAG:o[k][0].SOURCE_SYSTEM_FLAG	
					   , URP_FLAG: o[k][0].URP_FLAG, ROW_TYPE: o[k][0].ROW_TYPE, ISSUE_ID: o[k][0].ISSUE_ID || "",  T_SCENARIO_TYPE_CODE: o[k][0].T_SCENARIO_TYPE_CODE, Account_data: o[k] };
			});
			
			console.log("object a-------", a);				
			vm.entityData = a;
			
			for (var i=0; i < vm.entityData.length; i++){
				
				if(vm.entityData[i].Account_data.length > 0 && vm.entityData[i].Account_data[0].FROM_ACCT_KEY != null){
				
					var z = vm.entityData[i].Account_data.reduce( (a,b) => {
						a[b.FROM_ACCT_KEY] = a[b.FROM_ACCT_KEY] || [];
						a[b.FROM_ACCT_KEY].push(b);
						return a;
					}, {});
					
					console.log("object z to test json data-------", z);
					
					var y = Object.keys(z).map(function(k){
						var c = angular.copy(z[k]);
						c[0].ROW_TYPE = "HEADER_ROW";
						if(angular.equals(vm.trans_type, "BOOK_AMT")){
							z[k].unshift(c[0]);
						}
						return {ROW_TYPE: z[k][0].ROW_TYPE, LEID: z[k][0].LEID, CDR_NO: z[k][0].CDR_NO, REP_PD:z[k][0].REPORTING_PERIOD, ME_CODE:z[k][0].ME_CODE
							  , FROM_ACCT: z[k][0].FROM_ACCT, FROM_ACCT_DESC: z[k][0].FROM_ACCT_DESC, FROM_ACCT_KEY: z[k][0].FROM_ACCT_KEY	
							  , SCHDM_TEMPLATE_KEY: z[k][0].SCHDM_TEMPLATE_KEY, ADJ_ORIGIN_SOURCE: z[k][0].ADJ_ORIGIN_SOURCE						
							  , BOOK_AMT: z[k][0].BOOK_AMT, TOTAL_ALLOCATED_AMT: z[k][0].TOTAL_ALLOCATED_AMT, UNALLOCATED_AMT: z[k][0].UNALLOCATED_AMT
							  , tp_dropdown_data: [], tpObject: {"TP_LEID":"", "TP_CDR_NO": "", "TP_REP_PD": 0, "TP_ME_CODE":"", "TP_ENTITY":""}
							  , tp_me_dropdown_data: [], TP_ME_CODE: z[k][0].TP_ME_CODE
							  , sub_acct_data: z[k]
							  , DELETE_ROWS: [] };
					});
					
					console.log("Account index is -------::::::", i);
					console.log("At this point, value of y is -------::::::", y);
					
					vm.entityData[i].Account_data = angular.copy(y);
				}
				
			}
			
			console.log("entityData -------::::::", vm.entityData);
			
			//console.log(":::set existing payor info to dropdown model:::");
			
			if(angular.equals(vm.trans_type, "M_ADJUSTMENT")){
				if(vm.payment_type == "INTEREST" ){
					getExtPayorList();
				}
				loadTPDropdownListForTaxAdj();
			}
			
			vm.entityOriginalData = angular.copy(vm.entityData);
			console.log("vm.entityData:::::::", vm.entityData);
			
        }
        
     function loadTPDropdownListForTaxAdj(){
     
     	var cur_tp_options = [];
     	
     	for (var i=0; i < vm.entityData.length; i++){
			for (var j=0; j < vm.entityData[i].Account_data.length; j++){
				for (var k=0; k < vm.entityData[i].Account_data[j].sub_acct_data.length; k++){
					
					//remove current LEID from TP dropdown list							
					cur_tp_options = vm.tp_pool_list.reduce( (a,b) => {
						if(b.TP_LEID != vm.entityData[i].LEID || b.TP_CDR_NO != vm.entityData[i].CDR_NO  || b.TP_REP_PD != vm.entityData[i].REP_PD || b.TP_ME_CODE != vm.entityData[i].ME_CODE){
							a.push(b);
						}
						return a;
					}, []);		
					
					if(vm.payment_type == "INTEREST" && vm.entityData[i].SOURCE_SYSTEM_FLAG == "D"){
						for(var t=0; t < vm.extPayorData.length; t++){
							cur_tp_options.push({"TP_LEID":  vm.extPayorData[t].TP_LEID,
								  				 "TP_CDR_NO": vm.extPayorData[t].TP_CDR_NO,
								  				 "TP_REP_PD": vm.extPayorData[t].TP_REP_PD,
								  				 "TP_ME_CODE": vm.extPayorData[t].TP_ME_CODE,
								  				 "TP_ENTITY": vm.extPayorData[t].TP_LEID + "-" 
								  			  			+ vm.extPayorData[t].TP_CDR_NO + "-" 
								  			  			+ vm.extPayorData[t].TP_REP_PD + "-" 
									  			  		+ vm.extPayorData[t].TP_ME_CODE });
						}
						
						cur_tp_options.push({"TP_LEID":  "",
			  				 				 "TP_CDR_NO": "",
			  				 				 "TP_REP_PD": "",
			  				 				 "TP_ME_CODE": "",
			  				 				 "TP_ENTITY": "Unrelated Payor" });
					};
					
					console.log("::cur_tp_options after creation is :::", cur_tp_options);
					
					vm.entityData[i].Account_data[j].sub_acct_data[k].tp_dropdown_data = cur_tp_options;
							
				}
			}
		}
     	
     }
        
        
     vm.addNewPayor = function(entity_index, acct_index, sub_index){           
		
			var new_row = angular.copy(vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index]);	
			
			console.log("::addNewPayor:::");
			console.log("::new_row:::", new_row);
			console.log("::entityData:::", vm.entityData[entity_index]);
			
			//remove current LEID|CDR|REP from TP dropdown list							
			var cur_tp_options = vm.tp_pool_list.reduce( (a,b) => {
				if(b.TP_LEID != new_row.LEID || b.TP_CDR_NO != new_row.CDR_NO  || b.TP_REP_PD != new_row.REPORTING_PERIOD || b.TP_ME_CODE != new_row.ME_CODE){
					a.push(b);
				}
				return a;
			}, []);
			
			console.log("::cur_tp_options after creation is :::", cur_tp_options);
			console.log("::vm.extPayorData after creation is :::", vm.extPayorData);
			if(vm.payment_type == "INTEREST" && vm.entityData[entity_index].SOURCE_SYSTEM_FLAG == "D"){
				for(var t=0; t < vm.extPayorData.length; t++){
					cur_tp_options.push({"TP_LEID":  vm.extPayorData[t].TP_LEID,
						  				 "TP_CDR_NO": vm.extPayorData[t].TP_CDR_NO,
						  				 "TP_REP_PD": vm.extPayorData[t].TP_REP_PD,
						  				 "TP_ME_CODE": vm.extPayorData[t].TP_ME_CODE,
						  				 "TP_ENTITY": vm.extPayorData[t].TP_LEID + "-" 
						  			  			+ vm.extPayorData[t].TP_CDR_NO + "-" 
						  			  			+ vm.extPayorData[t].TP_REP_PD + "-" 
							  			  		+ vm.extPayorData[t].TP_ME_CODE });
				}
			};
			
			console.log("::cur_tp_options after creation is :::", cur_tp_options);
			
			//add existing TP to list, if any.
		/*	if(vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID != null &&
				vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID != undefined){
				cur_tp_options.push({"TP_LEID": vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID,
					  "TP_CDR_NO":  vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO,
					  "TP_REP_PD":  vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD,
					  "TP_ME_CODE": vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_ME_CODE,
					  "TP_ENTITY":  vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_LEID + "-" 
					  			  + vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_CDR_NO + "-" 
					  			  + vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].TP_REP_PD});
			} */
			
			//set new row attribs & push to sub_acct array
			new_row.ROW_TYPE = "NEW";	
			new_row.IS_CHANGED = 'Y';			
			new_row.ALLOCATED_AMT = 0;
			new_row.tp_dropdown_data = cur_tp_options;	
			new_row.TP_DETAILS = "";
			new_row.TP_LEID = "";
			new_row.TP_CDR_NO = "";
			new_row.TP_REP_PD = "";
			new_row.TP_ME_CODE = "";
			new_row.TP_ENTITY = "";
			
			vm.entityData[entity_index].Account_data[acct_index].sub_acct_data.push(new_row);
			
			/* if(angular.equals(vm.payment_type, "M3PAYOR")){
				getM3PayorList();
			} */
		}
		
		
		vm.setTPMEList = function(tp, entity_index, acctIndex, index){
			
			var tp_me_dropdown_data   = [];				
			
			for(var i=0; i < vm.entityData.length; i++){				
				if(vm.entityData[i].LEID === tp.TP_LEID){
					tp_me_dropdown_data.push({"TP_ME_CODE": vm.entityData[i].ME_CODE});
				}				
			}			
			
			vm.entityData[entity_index].Account_data[acctIndex].sub_acct_data[index].tp_me_dropdown_data = tp_me_dropdown_data;
			
			console.log(":::::setTPMEList::::::");
			console.log(":::::entity data::::::", vm.entityData);			
			console.log(":::::entity_index::::::", entity_index);	
			console.log(":::::acct_index::::::", acctIndex);
			console.log(":::::subacct_index::::::", index);			
			console.log(":::::tp obj:::::", tp);			
					
		};	 
		
		vm.removePayor = function (entity_index, acct_index, sub_index){
			console.log("index::", entity_index);
			console.log("index::", acct_index);
			console.log("index::", sub_index);
			console.log("index::", vm.entityData);
			if(vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].ROW_TYPE == 'EX'){
				vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].IS_CHANGED = 'D';
				vm.entityData[entity_index].Account_data[acct_index].DELETE_ROWS.push(vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index]);
				vm.entityData[entity_index].Account_data[acct_index].sub_acct_data.splice(sub_index,1);
			}else if (vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[sub_index].ROW_TYPE == 'NEW'){
				vm.entityData[entity_index].Account_data[acct_index].sub_acct_data.splice(sub_index,1);
			}
			vm.updateAmount(entity_index, acct_index, sub_index, 'R');
        };
        
        vm.updateAmount = function (entity_index, acct_index, sub_index, flag){
        	console.log("index::", vm.entityData); 
        	var allocated_amt = 0
        	for (var k=1; k < vm.entityData[entity_index].Account_data[acct_index].sub_acct_data.length; k++){
        		if(null != vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[k].TP_LEID && undefined != vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[k].TP_LEID){
        			allocated_amt = allocated_amt + vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[k].ALLOCATED_AMT;
        		}
        	}
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[0].TOTAL_ALLOCATED_AMT = allocated_amt;
        	vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[0].UNALLOCATED_AMT = vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[0].BOOK_AMT - vm.entityData[entity_index].Account_data[acct_index].sub_acct_data[0].TOTAL_ALLOCATED_AMT;
        	
        }
        
        vm.checkEditScenario = function (s_type_code, t_scenario, t_issue){        	
        	if(vm.scenario == t_scenario && ((s_type_code == 'EXAM' && vm.glbl_issue_id == t_issue) || s_type_code != 'EXAM')){
        		return true;
        	}        	
        	return false;        	
        }
		
		
		function getPayorDetails(){
			 $scope.loadingAccounts = true;
			 setSelectedParams();			 
			 
			 if(angular.equals(vm.source_screen_type, "SYS") || angular.equals(vm.source_screen_type, "LOCAL")){
			 	getPayorModalDataByAcct();
			 }else{			 
			 	getPayorAccountData();	
			 }
		}
	    
	    if(vm.userSelectedData.length != 0){    
			getPayorDetails();
		}
	    
	    if(vm.payment_type == "INTEREST"){
	    	getExtPayorList();
	    }
	    
	    function getExtPayorList(){
	    	relatedPartyPaymentFactory.getExtPayorDetails().then(function (data){
        		
        		console.log("External Payors Details:    ::::", data);
        		console.log("External Payors Details json Object :::::", data.jsonObject);
        		
        		vm.extPayorData = data.jsonObject;
        	});   
	    }	    

    }

    return controllers;


});