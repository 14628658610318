define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.otherOverrideDeleteCtrl',  [])
		.controller('otherOverrideDeleteCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','USER_SETTINGS','firOtherOverrideScreenFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', otherOverrideDeleteCtrl]);


	function otherOverrideDeleteCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,USER_SETTINGS,firOtherOverrideScreenFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		var vm = this;
		vm.deletePayment = function(){

			var RP_SEQ_ID = vm.rowData.RP_SEQ_ID;
	            var paymentJson = {adj_key: vm.rowData.ADJ_KEY};
	        	//validateJson(paymentJson);
	        	vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key,adj_key: vm.rowData.ADJ_KEY}];
	        	var success_message = "The  Part and section Override was successfully deleted";
	        	console.log("The save json data was :::::",paymentJson);
	        	firOtherOverrideScreenFactory.deletePayment(paymentJson, vm.clob_settings, vm.logged_in_user).then(function(data){
	        		console.log("Result from rep Trans save data was ---------------::::", data);
	        		if(null != data.data.errorMessage && data.data.errorMessage != "null"){
	        			console.log("There was the error message and the message is ", data.data.errorMessage);
	        			AlertService.add("error", data.data.errorMessage, 4000);
	        		}else {
	        			console.log("There wa no error message and the message was ", data.data.errorMessage);
	        			AlertService.add("success", success_message, 4000);
	        			$uibModalInstance.dismiss('cancel');
	        			var args = {combination_key: rowData.HO_COMBINATION_KEY, gridFilter: {combination_key: rowData.HO_COMBINATION_KEY},object_id:vm.rowData.object_id,action:"delete"};
	        			$rootScope.$emit('gridUpdate', args);
	        		}
	        	});
	        }
		vm.deleteBatchPayment = function(){

			var RP_SEQ_ID = vm.rowData.RP_SEQ_ID;
	            var paymentJson = {stage_key: vm.rowData.STAGE_KEY};
	        	//validateJson(paymentJson);
	        	vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key,adj_key: vm.rowData.STAGE_KEY}];
	        	var success_message = "The  Part and section Override Batch Upload was successfully deleted";
	        	console.log("The save json data was :::::",paymentJson);
	        	firOtherOverrideScreenFactory.deleteBatchPayment(paymentJson, vm.clob_settings, vm.logged_in_user).then(function(data){
	        		console.log("Result from rep Trans save data was ---------------::::", data);
	        		if(null != data.data.errorMessage && data.data.errorMessage != "null"){
	        			console.log("There was the error message and the message is ", data.data.errorMessage);
	        			AlertService.add("error", data.data.errorMessage, 4000);
	        		}else {
	        			console.log("There wa no error message and the message was ", data.data.errorMessage);
	        			AlertService.add("success", success_message, 4000);
	        			$uibModalInstance.dismiss('cancel');
	        			var args = {combination_key: rowData.HO_COMBINATION_KEY, gridFilter: {combination_key: rowData.HO_COMBINATION_KEY},object_id:vm.rowData.object_id,action:"delete"};
	        			$rootScope.$emit('gridUpdate', args);
	        		}
	        	});
	        }
		
		
        vm.rowData = rowData ||$scope.$parent.rowData;
        vm.colData = colData;
        vm.gridData = gridData;
        vm.globalParams = GlobalService.globalParams;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        console.log("Here is the starting point and the rowData was ::::::::", vm.rowData);
        console.log("Here is the starting point and the global Params was ::::::::", vm.globalParams);
        vm.ho_leid = vm.rowData.HO_LEID;
        vm.ho_cdr_no = vm.rowData.HO_CDR_NO;
        vm.ho_combination_key = vm.rowData.HO_COMBINATION_KEY;
		vm.adjAmts = [];
        vm.isDelete = true;
        vm.modalTitle = "Delete Override";
        vm.cancel = function () {
			$scope.crudLoading = false;
    		console.log("in cancel");
    		$uibModalInstance.dismiss('cancel');
        };
	}
	  }); 
