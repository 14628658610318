define([
    'angular'

], function () {
    'use strict';


    var controllers =  angular.module('app.allLocalAdjustmentsController',[])
        .controller('AllLocalAdjustmentsController',  ['workspaceFactory','GlobalService','$document','$rootScope','$scope','$timeout','$uibModal','$uibModalInstance','$uibModalStack','$log','JsonObjectFactory','AlertService' ,'gridData','rowData', 'colData','CodeComboFactory','ModalFactory', allLocalAdjustmentsController]);



    function allLocalAdjustmentsController (workspaceFactory,GlobalService,$document,$rootScope, $scope,$timeout,$uibModal,$uibModalInstance,$uibModalStack,$log,JsonObjectFactory,AlertService,gridData,rowData,colData,CodeComboFactory,ModalFactory) {
        var vm = this;
        //vm.title = "Schedule B Questions";
        vm.loading = true;
        vm.rowData = rowData;
        vm.colData = colData;
        vm.body = "";
        // vm.openModal = function (modalObj,_data) {
        //     $scope.rowData = _data.rowData;
        //     $scope.gridData = _data;
        //     $scope.colData = _data.colData;
        //     $uibModalStack.close(1);
        //     console.log('uibModalStack', $uibModalStack.close(0));
        //      var modalInstance = $uibModal.open({
        //         animation: true,
        //         templateUrl: modalObj.template,
        //         controller: modalObj.controler,
        //         size: modalObj.size,
        //         backdrop: modalObj.backdrop,
        //         windowClass: modalObj.windowClass,
        //         resolve: {
        //             rowData: function () {
        //                 return vm.rowData;
        //             },
        //             colData: function () {
        //                 return vm.colData;
        //             }
        //         }
        //     });

        //     modalInstance.opened.then(function() {
        //         var modalLockProps = [];
        //         if(modalObj.modal_lock == "Y" && modalObj.lock_check_required == "Y") {
        //             if(workspaceFactory.activeScreen.data.settings.batch_select == 1){
        //                 _.each($scope.gridData,function(item){
        //                     if(item && item.data){
        //                         modalLockProps.push({
        //                             "leid": item.data.LEID,//"IF1139",
        //                             "me_code": item.data.ME_CODE ||  item.data.ME,
        //                             "cdr_number":item.data.CDR_NO ||  item.data.TAX_CODE,
        //                             "consol_group_key": item.data.CONSOL_GROUP_KEY,//1,
        //                             "reporting_period": item.data.REPORTING_PERIOD,
        //                             "combination_key":item.data.COMBINATION_KEY //1
        //                         })
        //                     }
                           
        //                 })
        //                 if($scope.gridData && $scope.gridData.rowData){
        //                     modalLockProps.push({
        //                         "leid":$scope.gridData.rowData.LEID,//"IF1139",
        //                         "me_code": $scope.gridData.rowData.ME_CODE || $scope.rowData.ME,
        //                         "cdr_number":$scope.gridData.rowData.CDR_NO || $scope.rowData.TAX_CODE,
        //                         "consol_group_key": $scope.gridData.rowData.CONSOL_GROUP_KEY,//1,
        //                         "reporting_period": $scope.gridData.rowData.REPORTING_PERIOD,
        //                         "combination_key":$scope.gridData.rowData.COMBINATION_KEY //1
        //                     })
        //                 }
        //             }else if($scope.rowData){
        //                 modalLockProps.push({
        //                     "leid":$scope.rowData.LEID,//"IF1139",
        //                     "me_code": $scope.rowData.ME_CODE || $scope.rowData.ME,
        //                     "cdr_number":$scope.rowData.CDR_NO || $scope.rowData.TAX_CODE,
        //                     "consol_group_key": $scope.rowData.CONSOL_GROUP_KEY,//1,
        //                     "reporting_period": $scope.rowData.REPORTING_PERIOD,
        //                     "combination_key":$scope.rowData.COMBINATION_KEY //1
        //                 })
        //             }

                   

        //             GlobalService.modalLockFilters(modalLockProps);
        //         }
        //     });
            

        //     modalInstance.rendered.then(function() {
        //         if(modalObj.modal_lock == "Y" && modalObj.lock_check_required == "Y") {
        //             var modalLockSelector = document.querySelector('.modal-window-lock-class .modal-dialog .modal-content');
        //             modalLockSelector.querySelector('.modal-header').classList.add("modal-lock-hdr-class");

        //             var modalLockSelectorFoot = modalLockSelector.querySelector('.modal-footer');
        //             if(modalLockSelectorFoot) {
        //                 _.forEach(modalLockSelectorFoot.querySelectorAll('[class*="btn"]'), function(eachBtn) {
        //                     eachBtn.disabled = true;
        //                 })
        //             }

        //             AlertService.add("error", modalObj.modal_lock_err_msg, 3000);
        //         }
        //     })

        //     modalInstance.result.then(function (selectedItem) {
        //         $scope.selected = selectedItem;
        //     }, function () {
        //         $log.info('Modal dismissed at: ' + new Date());
        //         $log.info('$scope.rowData', $scope.rowData);
        //     });
        // };

       vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');        
        };

        function init() {
            var openedModal = $uibModalStack.getTop();
            
            var _data = {};
            var callName;
            //var reclassArr = ['Local Book Adjustment','11/30 CY Book Adjustment','11/30 PY Book Adjustment'];
            var reclassArr = ['LOCAL_BOOK_ADJ'];
            CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
            CodeComboFactory.toggle = false;
            CodeComboFactory.showFormBtn = false;
            vm.showElimForm = true;

            _data = {
                rowData: vm.rowData,
                colData: vm.colData
            }

           
            if(vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_AMT') {
                vm.body = "This modal does not work for Book Amount.";
            }

            // else {
                // setTimeout(function() {
                //     vm.cancel();
                // }, 1000);
                // if(vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_ELIM') {
                //     callName = 'elimReclassAdjsByLocalChart';
                // } else if (vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ' || vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ_PY' || vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_BOOK_ADJ_CY') {
                //     callName = 'reclassBookByLocalChart';
                // } else if (vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ' || vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_GHOST' || vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_PERM' || vm.rowData.TRANS_TYPE_ACTUAL === 'LOCAL_TAX_ADJ_TEMP') {
                //     callName = 'epTaxAdjsLocalChart';
                // }
                // ModalFactory.getModal(callName,_data).then(function (data) {
                //     var modalObj = {};
                //     modalObj.template = data.modalScreen.modal_template;
                //     modalObj.controler = data.modalScreen.modal_controler;
                //     modalObj.size = data.modalScreen.modal_size;
                //     if(data.lockResult.is_locked && data.lockResult.is_locked == "Y" && data.modalScreen.lock_check_required == "Y") {
                //         modalObj.windowClass = "modal-window-lock-class";
                //         modalObj.modal_lock= data.lockResult.is_locked;
                //         modalObj.modal_lock_err_msg = data.lockResult.lock_message;
                //         modalObj.lock_check_required = data.modalScreen.lock_check_required;
                //     }
    
                //     console.log('modal object ', modalObj);
                //     vm.openModal(modalObj, _data);
                // })
            // }
            
        }

        init();
    }

    
    return controllers;

});
