/**
 * Created by 400371031 on 1/20/2021.
 */
define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module(
					'app.relatedPartyclassificationController', []).controller(
					'relatedPartyclassificationController',
					[ '$scope', '$rootScope', '$http', 'GlobalService',
							'$uibModalInstance', 'ModalFactory',
							'AlertService', 'rowData', 'gridData',
							'workspaceFactory', 'JsonObjectFactory',
							'$timeout', 'GENERAL_CONFIG', 'USER_SETTINGS',
							'relatedPartyClassificationFactory',
							relatedPartyclassificationController ])

			function relatedPartyclassificationController($scope, $rootScope,
					$http, GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, workspaceFactory,
					JsonObjectFactory, $timeout, GENERAL_CONFIG, USER_SETTINGS,
					relatedPartyClassificationFactory) {

				var vm = this;

				console.log("=======relatedPartyPaymentData=======");
				console.log(gridData);
				console.log("=======rowData=======");
				console.log(rowData);
				var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
						.getFilterParams()
						: {};
				console.log(gridFilters);
				vm.is_allocate_access = false;

				vm.crudLoading = false;
				vm.userSelectedData = gridData;

				vm.category_name = "";
				vm.title = "Related Party Classification";
				vm.taxYear = GlobalService.globalParams.tax_year;
				vm.scenario = GlobalService.globalParams.scenario;
				vm.taxYear = GlobalService.globalParams.jcd_key;
				vm.glbl_issue_id = GlobalService.globalParams.issue_id;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.tbl_list = [];
				vm.tbl_list_display = [];
				$scope.loadingAccounts = false;
				$scope.existingLoading = false;
				vm.category_change_name = "";
				vm.cancel = function() {

					$uibModalInstance.dismiss('cancel');

				};
                loadcatefory();
                function loadcatefory() {

					var params = {
						"action_code" : 'tykclb',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											
											vm.tbl_list = [];
											vm.tbl_list_display =[];
											vm.tbl_list =data.jsonObject;
											for ( var a1 in data.jsonObject) {
												
													vm.tbl_list_display
															.push(data.jsonObject[a1].LABEL);
											}
											
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.irsformdata = false;
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;
				vm.save = function() {
					vm.finalData = [];
					vm.clob_settings = [];
					
					console.log("Original Data::::::::==========",
							vm.entityOriginalData);
					console.log("vm.entityData::::", vm.entityData);
					
					if (vm.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					vm.crudLoading = true;
						vm.clob_settings = [ {
							CATEGORY : vm.category_name,
							
						} ];
						var msg = "Related Party Modal Data was Saved";
						saveCategory(vm.clob_settings, vm.clob_settings,
								msg);
					

				};
				//vm.save_categor relatedPartyPaymentFactory
				function saveCategory(returnObj, accountArray, message) {

					relatedPartyClassificationFactory.savecategorymodal(returnObj, accountArray).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									vm.crudLoading = false;
									
								} else {
									AlertService.add("success", message, 4000);
									vm.crudLoading = false;
									$uibModalInstance.dismiss('cancel');
									var args = {
											OBJECT_ID : objId,
											gridFilter : {
												gridFilter : objId
											}
										};
										$rootScope
												.$emit(
														'gridUpdate',
														args);
								}
							});

				}

				vm.save_category = function() {
					vm.finalData = [];
					vm.clob_settings = [];
					vm.clob_data = [];
					console.log("Original Data::::::::==========",
							vm.entityOriginalData);
					console.log("vm.entityData::::", vm.entityData);
					
					if (vm.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					
					
					vm.crudLoading = true;
					var category_key = "";
					/*for (var d in vm.tbl_list)
						{
						if (vm.tbl_list[d].LABEL == vm.category_change_name)
							{
							category_key =vm.tbl_list[d].VALUE;
							}
						}*/
						vm.clob_settings = [ {
							CATEGORY : vm.category_change_name,
							CHART_KEY:rowData.CHART_KEY,
							ACCT_KEY:rowData.ACCT_KEY
						} ];
						var msg = "Acct Category Updated";
						saveCategoryUpdate(vm.clob_settings, vm.clob_settings,
								msg);
					

				};
				function saveCategoryUpdate(returnObj, accountArray, message) {

					relatedPartyClassificationFactory.saveupdatecategorymodal(returnObj, accountArray).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									vm.crudLoading = false;
									
								} else {
									AlertService.add("success", message, 4000);
									vm.crudLoading = false;
									$uibModalInstance.dismiss('cancel');
									var args = {
											OBJECT_ID : objId,
											gridFilter : {
												gridFilter : objId
											}
										};
										$rootScope
												.$emit(
														'gridUpdate',
														args);
								}
							});

				}
			}

			return controllers;

		});