/**
 * Created by 212544474 on 8/16/2016.
 */
 define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.adminResetDsConnectionCtrl',[])
    .controller('adminResetDsConnectionCtrl', ['$scope','$rootScope','$state','$log', '$uibModalInstance', 'gridData', 'GridFactory','AlertService','$timeout','$uibModal', '$parse', 'ClientAdminFactory','GENERAL_CONFIG','USER_SETTINGS','GridSaveFactory', 'adminResetDsConnectionService', adminResetDsConnectionCtrl])

    function adminResetDsConnectionCtrl($scope,$rootScope,$state,$log, $uibModalInstance, gridData, GridFactory,AlertService,$timeout,$uibModal, $parse, ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS,GridSaveFactory, adminResetDsConnectionService) {

        var vm = this;
        vm.modalTitle = "Reset Data source connection";
		vm.saveloader = false;
		vm.tags = [];
        
        vm.apis = [{
            name:'API',
            value: 'gtw'
        },{
            name: 'ADMIN',
            value: 'admin'
        },{
            name: 'COMMON-API',
            value: 'common'
        },{
            name: 'CUSTOM-API',
            value: 'custom'
        },{
            name: 'SCHEDULER-API',
            value: 'scheduler'
        },{
            name: 'EFILE-API',
            value: 'efile'
        },{
            name: 'BULK-PDF-ENGINE',
            value: 'bulkpdf'
        },{
            name: 'PDF-ENGINE',
            value: 'pdf'
        }];
        vm.selectedApi = 'gtw';
        vm.refreshFromDb = false;
		angular.forEach(gridData, function(value, key) {
			vm.tags.push(value.data.CLIENT_DATASOURCE);
		});
		console.log(vm.tags);

        vm.onServiceChange = function() {
            console.log(vm.selectedApi);
        };

		vm.reTriggerMessage = function() {
            if(vm.selectedApi.length == 0) {
                alert('Please select the service to reset the connection.');
                return;
            }
			vm.saveloader = true;
			vm.showJsonLoader = true;
            var params = {"api": vm.selectedApi, "client_key": USER_SETTINGS.user.client_key, "datasource": vm.tags.toString(), "refreshFromDB": vm.refreshFromDb};

			return adminResetDsConnectionService.resetDsConnection(params).then(function (data) {
				if(data.data.callSuccess == "1" ){
					vm.saveloader = false;
					AlertService.add("success", "Successfully reset the connection." , 4000);
					vm.cancel();
				}else{
					vm.saveloader = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };

		vm.removeTag = function (index) {
			vm.tags.splice(index, 1);
	  	};

		vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
    }

    return controllers;
});
