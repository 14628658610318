
define([
    'angular',
    './CbcReportingController',
    './CbcReportingService',
    './cbc10KReconciliation/cbcReconciliationController',
    './cbc10KReconciliation/cbcReconciliationService',
], function () {
    'use strict';
    return angular.module('app.cbcReporting', ['app.CbcReportingController','app.cbcReportingService', 'app.cbcReconciliationController', 'app.cbcReconciliationService'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider
                .state('cbc_reporting', {
                    url: '/cbc-reporting',
                    templateUrl: 'app/components/cbcReporting/cbc-reporting-tpl.html',
                    controller:'cbcReportingController',
                    backdrop: 'static',
                    data:{},
                    access:""
                })
        }]);
});

