define([
	'angular'
 
 ], function () {
	'use strict';
 
	var services =  angular.module('app.jobDisplayService',[])
		.factory("jobDisplayFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG', 
			
			function($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG) {
				var jobDisplayFactory ={};
				var clob_setting = {};
				var clob_data = [];
				var params = {};
				var service = $injector.get('JsonObjectFactory');
				var defer = $q.defer();
				jobDisplayFactory.url ={};
				jobDisplayFactory.url.saveJobDisplay = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0pnshs";

				jobDisplayFactory.saveJobDisplay = function(_callName , _data){
					clob_data = [];
					params = {};
					console.log("data", _data);
					console.log("callname - ",_callName);
 
					$rootScope.$broadcast('gridRefresh', false);
					if(_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]){
						return;
					}

					clob_data.push(_data.rowData);
                    clob_setting = {
						tax_year: _data.rowData.TAX_YEAR,
						scenario: GlobalService.globalParams.scenario
					};
                    params = _.extend({
                        clob_data: JSON.stringify(clob_data)
                    }, params);
                    params = _.extend({
                        clob_setting: JSON.stringify(clob_setting)
                    }, params);

					service.saveJSON(jobDisplayFactory.url.saveJobDisplay, params).then(function (data) {
						if (data.callSuccess === "1") {						
							AlertService.add("success", "Data saved Successfully.",3000);
								var args = {tax_year: _data.rowData.TAX_YEAR, gridFilter: {TAX_YEAR: _data.rowData.TAX_YEAR}};
								$rootScope.$emit('gridUpdate', args);
								defer.resolve(data);
							}else {
								AlertService.add("error", data.errorMessage, 4000);
								defer.reject(data);
							}}, function(data){
								defer.reject(data);
							});
					  	return defer.promise;
					}
		return jobDisplayFactory;
			   }]);
	return services;
 });