define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.scheduleF8858-MService', [])
     .factory("scheduleMFormServiceFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
    	 var scheduleMFormServiceFactory = {};
         var returnObj = {};
         
         var scheduleMFormServiceFactory ={};
         scheduleMFormServiceFactory.url ={};
         scheduleMFormServiceFactory.url.save_schedule_M = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vc87d8";
         
         scheduleMFormServiceFactory.save8858ScheduleM = function(_data, Details){

         var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
         var jsonSettings =  JSON.stringify(_data);
         var jsonObj = JSON.stringify(Details);
             //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
         var params = filterParams;
         params = _.merge({},  GlobalService.globalParams , params);
         params =  _.extend({jsonObj:jsonObj}, params);
         params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
         params.process_name =  "8858_Schedule_M_Save";
         var promise = $http({
      	   method: "post",
             url: scheduleMFormServiceFactory.url.save_schedule_M,
             data: params
         }).then(function (response) {
             var status = response.status;
             return response.data;
         });

         return promise;
     };
         
         return scheduleMFormServiceFactory;
     }]);
    
    return services;
        	
     
});