define(["angular"], function () {
    "use strict";
    angular
        .module("app.regenerateFirFormController", ["ui.select"])
        .controller("regenerateFirFormController", [
            'GENERAL_CONFIG',
            'USER_SETTINGS',
            'SERVER_CONFIG',
            '$scope',
            'FirformFactory',
            'AlertService',
            'GlobalService',
            'MessageService',
            'TAX_YEARS',
            regenerateFirFormController
        ]);

    function regenerateFirFormController(GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG,$scope, FirformFactory, AlertService, GlobalService, MessageService, TAX_YEARS) {
        let vm = this;
        const DEFAULT_JCD_KEY = 250;

        vm.activeTab = 0;
        vm.canMigrate = SERVER_CONFIG.configSettings.can_regenrate_fir_form.includes(USER_SETTINGS.user.sso_id);
        vm.taxYears = angular.copy(TAX_YEARS);
        vm.selectedTaxYear = vm.taxYears[0];
        vm.selectedScenario = vm.selectedTaxYear.scenarios[0];
        vm.selectedTaxPayer = null;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.consolGroups = [];
        vm.taxPayers = [];
        vm.efiles = null;

        // load consolidated groups list
        vm.consolGroupsLoading = true;
        FirformFactory.getFilingGroups().then((data) => {
            if (data) {
                vm.consolGroups = data.jsonObject;
            }
            vm.consolGroupsLoading = false;
        });

        vm.changeTaxYear = function () {
            vm.selectedScenario = vm.selectedTaxYear.scenarios[0];
           
        };

        $scope.forms = ["5471", "8858", "8865", "8990", "8992"];
        
        vm.push = function(){
        	
        	var tax_year = vm.selectedTaxYear.value ;
        	var scenario = vm.selectedScenario.scenario;
        	var jcd_key = GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key;
        	var form = vm.selectedName ;
        	var sso_id = vm.logged_in_user;
        	var filing_key = vm.selectedConsolGroup.VALUE
        	
        	var success_message = "The  form has been successfully generated ";
        	vm.generating = true;
        	FirformFactory.regenerateFormData(tax_year, scenario, form ,filing_key,sso_id,jcd_key).then(function(data){
        		console.log("Result ---------------::::", data);
        		if(null != data.data.errorMessage && data.data.errorMessage != "null"){
        			AlertService.add("error", data.data.errorMessage, 4000);
        		}else {
        			AlertService.add("success", success_message, 4000);
        			vm.generating = false;
        		}
        	});
        }
        

       
       
    }
});