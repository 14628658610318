define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.dividendsController', [])
        .controller('DividendsController', ['$rootScope','$scope', '$filter', 'AlertService', 'CodeComboFactory', 'JsonObjectFactory','GlobalService',
            'DividendsFactory','GENERAL_CONFIG','ModalFactory','$uibModalInstance',  'rowData', 'colData', dividendsController])

            
            /*
			 * .controller('AddDividendsController', ['$rootScope','$scope',
			 * '$filter', 'AlertService', 'CodeComboFactory',
			 * 'JsonObjectFactory',
			 * 'DividendsFactory','GENERAL_CONFIG','ModalFactory','$uibModalInstance',
			 * 'rowData', 'colData','gridData', AddDividendsController])
			 * 
			 */
        .controller('DividendsControllerModal', ['$rootScope','$scope', '$filter', 'AlertService', 'CodeComboFactory', 'JsonObjectFactory',
        'DividendsFactory','GENERAL_CONFIG','ModalFactory','GlobalService',dividendsControllerModal]);

        function dividendsControllerModal($rootScope, $scope, $filter, AlertService, CodeComboFactory,JsonObjectFactory,
            DividendsFactory,GENERAL_CONFIG,ModalFactory,GlobalService) {
                dividendsController($rootScope, $scope, $filter, AlertService, CodeComboFactory,JsonObjectFactory,
                    DividendsFactory,GENERAL_CONFIG, ModalFactory,null,{}, null, this,GlobalService);
            }

    function dividendsController($rootScope, $scope, $filter, AlertService, CodeComboFactory,JsonObjectFactory,GlobalService,
        DividendsFactory,GENERAL_CONFIG,ModalFactory, $uibModalInstance,rowData, colData, thisObj) {
       // var vm = this;
        // vm.rowData = rowData;
        var vm = this||thisObj;
        $scope.form = { divForm: {} };
        vm.rowData= rowData;
        vm.colData= colData;
       // vm.gridData = gridData;
        vm.date_flag = false;
        vm.dividend_disabled = false;
        vm.openCalendar = function() {
          vm.calendar.opened = true;
        };

        if($uibModalInstance) {
            vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
            vm.cancel = function () {
                $uibModalInstance.close();
                vm.modal_name = null;
            };
        } else {
            vm.modal_name = null;
        }
        vm.formatDate = function() {
        	if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
                
                 vm.rowData = {};
        		 if(vm.payorFeeds.selected){
             		var start_date = new Date(vm.payorFeeds.selected.TAX_YEAR_BEGIN);
             		vm.rowData.TAX_YEAR_BEGIN = $filter('date')(vm.payorFeeds.selected.TAX_YEAR_BEGIN, 'MM/dd/yyyy');
             		vm.rowData.TAX_YEAR_END = $filter('date')(vm.payorFeeds.selected.TAX_YEAR_END, 'MM/dd/yyyy');
                 	var end_date = new Date(vm.payorFeeds.selected.TAX_YEAR_END);
                 	
                 	if( (vm.PAYMENT_DATE < start_date) || (vm.PAYMENT_DATE > end_date ) )
                 		{
                 			vm.date_flag = true;
                 		}
                 	else
                 	{
                 		vm.date_flag = false;
                 		vm.formattedDate = $filter('date')(vm.PAYMENT_DATE, 'MM/dd/yyyy');
                         vm.TRANS_DATE = vm.formattedDate;
                         
                 	}
             	}else{
             		vm.date_flag = false;
             		vm.formattedDate = $filter('date')(vm.PAYMENT_DATE, 'MM/dd/yyyy');
                     vm.TRANS_DATE = vm.formattedDate;
             	}
        		 
        	 }else{
        	
        		var start_date = new Date(rowData.TAX_YEAR_BEGIN);
             	var end_date = new Date(rowData.TAX_YEAR_END);
             	if( (vm.PAYMENT_DATE < start_date) || (vm.PAYMENT_DATE > end_date ) )
             		{
             			vm.date_flag = true;
             		}
             	else
             	{
             		vm.date_flag = false;
             		vm.formattedDate = $filter('date')(vm.PAYMENT_DATE, 'MM/dd/yyyy');
                     vm.TRANS_DATE = vm.formattedDate;
                     if(vm.colData.call_back === 'paidDividendAdjs')
                     	vm.dividendPaidObj.ADJ_SOURCE_TYPE = getAdjSourceType(vm.rowData.TAX_YEAR_BEGIN, vm.rowData.TAX_YEAR_END);
             	}
        	 }
        	
        };

        vm.changeDivType = function() {
        	
       	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
       		 vm.dividendReceivedObj["ADJ_TYPE"] = "";
                vm.trans304Obj["ADJ_TYPE"] = "";
                vm.dividendPaidObj.PAYOR_DISABLED = false;
                vm.dividendReceivedObj.PAYEE_DISABLED = false;

                if(vm.dividendTypes.selected !== null) {
                	vm.div_type_flag = false;
                    vm.TRANS_SUB_TYPE = vm.dividendTypes.selected.CODE_ID;
                    if(vm.dividendTypes.selected.CODE_ID === "2") {
                        vm.dividendReceivedObj["ADJ_TYPE"] = "S";
                        vm.trans304Obj["ADJ_TYPE"] = "P";
                        vm.trans304Obj["IS_TRANS_304"] = "Y";
                    }

                } else {
                    vm.TRANS_SUB_TYPE = "";
                }
           }else{
           	vm.dividendReceivedObj["ADJ_TYPE"] = "";
               vm.trans304Obj["ADJ_TYPE"] = "";
               
               if(vm.dividendTypes.selected !== null) {
               	vm.div_type_flag = false;
                   vm.TRANS_SUB_TYPE = vm.dividendTypes.selected.CODE_ID;

                   if(vm.colData.call_back === 'paidDividendAdjs'){
                   	vm.dividendReceivedObj.PAYEE_DISABLED = false;}
                   else
                   	vm.dividendPaidObj.PAYOR_DISABLED = false;

                   if(vm.dividendTypes.selected.CODE_ID === "2") {
                       vm.dividendReceivedObj["ADJ_TYPE"] = "S";
                       vm.trans304Obj["ADJ_TYPE"] = "P";
                       vm.trans304Obj["IS_TRANS_304"] = "Y";
                   }
               } else {
    	            vm.div_type_flag = true;     	            	
                   vm.TRANS_SUB_TYPE = "";
                   vm.dividendPaidObj.PAYOR_DISABLED = true;
               }
           }
         };

        vm.changeTransCurr = function() {
            vm.trans304Obj.TRANS_304_AMT_DISABLED = false;
            vm.trans304Obj.TARGET_ENTITY_304_DISABLED = false;
            vm.trans304Obj.TRANS_304_CURR = vm.transCurrencies.selected.CURR_CODE;
            vm.trans304Obj.TRANS_304_AMT = 0;
            vm.trans304Obj.TRANS_304_AMT_USD = 0;

        };

        vm.getEntities = function (val) {

            return DividendsFactory.getEntities($scope.taxYear, $scope.scenario, $scope.jcd_key, val).then(function(res) {
            	vm.payor = res;
            	return res;
            });
        };
        
        vm.getEntities = function (val) {

            return DividendsFactory.getEntities($scope.taxYear, $scope.scenario, $scope.jcd_key, val).then(function(res) {
            	vm.payor = res;
            	return res;
            });
        };
        
        
        vm.get304Entities = function (val) {

            return DividendsFactory.get304Entities($scope.taxYear, $scope.scenario, $scope.jcd_key, val).then(function(res) {
            	vm.payor = res;
            	return res;
            });
        };
        
        vm.INTL_TRANS_KEY = "";
        
        vm.getTransId = function (val) {

            return DividendsFactory.getTransId($scope.taxYear, val).then(function(res){
            	vm.trans = res 
						return res;
					});
		};
        
        
		
        vm.setEntity = function(entityObj, entityType) {

            if(entityType === 'PAYOR') {
            	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd')
            		 {vm.dividendPaidObj.PAYOR_AMT = 0;
                     vm.dividendPaidObj.PAYOR_TAX_AMT = 0;
                     vm.dividendPaidObj.PAYOR_AMT_USD = 0;
                     vm.dividendPaidObj.PAYOR_TAX_AMT_USD = 0;                     
                     vm.dividendReceivedObj.PAYOR_TP_KEY = entityObj.TP_KEY;
                     }else{             	 
                vm.dividendPaidObj.PAYOR_TB_DISABLED = false;
                vm.dividendReceivedObj.PAYOR_TP_KEY = entityObj.TP_KEY;
            	 }
            } else if(entityType === 'PAYEE') {
            	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
            		 vm.dividendReceivedObj.PAYEE_AMT = 0;
                     vm.dividendReceivedObj.PAYEE_TAX_AMT = 0;
                     vm.dividendReceivedObj.PAYEE_AMT_USD = 0;
                     vm.dividendReceivedObj.PAYEE_TAX_AMT_USD = 0;
                    vm.dividendPaidObj.PAYEE_TP_KEY = entityObj.TP_KEY; 
            	 }else{vm.dividendReceivedObj.PAYEE_TB_DISABLED = false;
                 vm.dividendPaidObj.PAYEE_TP_KEY = entityObj.TP_KEY;}
                
            } else {
                vm.trans304Obj.TRANS_304_TP_KEY = entityObj.TP_KEY;
            }

            if(entityType !== 'ENTITY304') {
                getEntityFeeds(entityType, entityObj.TP_KEY);
            }
        };

        vm.getPayeeEntities = function (val) {

            return DividendsFactory.getPayeeEntities($scope.taxYear, $scope.scenario, $scope.jcd_key, val).then(function(res) {
            	vm.receipeint = res;
            	return res;
                
            });
        };


        vm.changePayor = function() {
        	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
        		   vm.dividendPaidObj.PAYOR_AMT_DISABLED = false;
                   vm.dividendPaidObj.PAYOR_TAX_AMT_DISABLED = false;
                   vm.dividendPaidObj.PAYOR_AMT = 0;
                   vm.dividendPaidObj.PAYOR_TAX_AMT = 0;
                   vm.dividendPaidObj.PAYOR_AMT_USD = 0;
                   vm.dividendPaidObj.PAYOR_TAX_AMT_USD = 0;
                   vm.dividendPaidObj.COMBINATION_KEY = vm.payorFeeds.selected.COMBINATION_KEY;
                   vm.dividendPaidObj.ADJ_SOURCE_TYPE = getAdjSourceType(vm.payorFeeds.selected.TAX_YEAR_BEGIN, vm.payorFeeds.selected.TAX_YEAR_END);	 
                  
                }
        	 else{
        		 console.log('vm.payorFeeds.selected---------', vm.payorFeeds.selected);
        		 vm.dividendPaidObj.PAYOR_AMT_DISABLED = false;
                 vm.dividendPaidObj.PAYOR_TAX_AMT_DISABLED = false;
                 if(vm.colData.call_back !== 'receivedDividendAdjs')
                 	vm.dividendReceivedObj.PAYEE_DISABLED = false;
                 vm.dividendPaidObj.PAYOR_AMT = 0;
                 vm.dividendPaidObj.PAYOR_TAX_AMT = 0;
                 vm.dividendPaidObj.COMBINATION_KEY = vm.payorFeeds.selected.COMBINATION_KEY;
                 vm.dividendPaidObj.ADJ_SOURCE_TYPE = getAdjSourceType(vm.payorFeeds.selected.TAX_YEAR_BEGIN, vm.payorFeeds.selected.TAX_YEAR_END); 
        	 }
         };

        vm.changePayee = function() {
        	if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
        		 vm.dividendReceivedObj.PAYEE_AMT_DISABLED = false;
                 vm.dividendReceivedObj.PAYEE_TAX_AMT_DISABLED = false;
                 vm.dividendReceivedObj.PAYEE_AMT = 0;
                 vm.dividendReceivedObj.PAYEE_TAX_AMT = 0;
                 vm.dividendReceivedObj.PAYEE_AMT_USD = 0;
                 $scope.form.divForm['payee_amt'].$setValidity("invalidAmt", true);
                 vm.dividendReceivedObj.PAYEE_TAX_AMT_USD = 0;
                 vm.dividendReceivedObj.COMBINATION_KEY = vm.payeeFeeds.selected.COMBINATION_KEY;
                 vm.dividendReceivedObj.ADJ_SOURCE_TYPE = getAdjSourceType(vm.payeeFeeds.selected.TAX_YEAR_BEGIN, vm.payeeFeeds.selected.TAX_YEAR_END);
             
                
                }else{
        		 vm.dividendReceivedObj.PAYEE_AMT_DISABLED = false;
                 vm.dividendReceivedObj.PAYEE_TAX_AMT_DISABLED = false;
                 vm.dividendReceivedObj.PAYEE_AMT = 0;
                 vm.dividendReceivedObj.PAYEE_TAX_AMT = 0;
                 vm.dividendReceivedObj.COMBINATION_KEY = vm.payeeFeeds.selected.COMBINATION_KEY;
                 vm.dividendReceivedObj.ADJ_SOURCE_TYPE = getAdjSourceType(vm.payeeFeeds.selected.TAX_YEAR_BEGIN, vm.payeeFeeds.selected.TAX_YEAR_END);
           
        	}
            };

        vm.validateAmt = function(val, wtd_avg_rate, objType, fieldToUpdate, fieldName) {

            if(fieldName !== 'trans_304_amt' && parseInt(vm.TRANS_SUB_TYPE) !== 8) {
            	console.log("$scope---",$scope);
            	console.log("$rootScope---",$rootScope);
            	console.log("$scope.form.divForm-----", $scope.form.divForm);


                $scope.form.divForm[fieldName].$setValidity("invalidAmt", true);
                /*if(parseInt(vm.TRANS_SUB_TYPE) == 8 && fieldName == 'payee_amt'){
                	return;
                }*/
                if(fieldName == 'payee_amt'  && val > 0) {
                    console.log('invalid amt ' + fieldName);
                    $scope.form.divForm[fieldName].$setValidity("invalidAmt", false);
                    return;
                } else if (fieldName !== 'payee_amt' && val < 0) {
                    console.log('invalid amt ' + fieldName);
                    $scope.form.divForm[fieldName].$setValidity("invalidAmt", false);
                    return;
                }
            }

            convertToUS(val, wtd_avg_rate, objType, fieldToUpdate, fieldName);
        };

        vm.save = function() {
        	vm.dividendPaidObj.PAYOR_AMT_DISABLED = false;
            vm.dividendPaidObj.PAYOR_TAX_AMT_DISABLED = false;
            vm.dividendReceivedObj.PAYEE_AMT_DISABLED = false;
            vm.dividendReceivedObj.PAYEE_TAX_AMT_DISABLED = false;

            

            
        	 if(vm.dividendTypes.selected == null){
	            	vm.div_type_flag = true;
	        		 return;
	        		}
	            else{
	            	vm.div_type_flag = false;
	            	};
	            	
	            	if(vm.payorEntity  ){
	                	 if(!vm.dividendPaidObj.PAYOR_DISABLED){
	     	            	var payorEntity = typeof(vm.payorEntity) === 'object' ?  vm.payorEntity.LE_NAME : vm.payorEntity;
	     	            	 if(vm.payor){
	                           var obj = vm.payor.find(o => o.LE_NAME === payorEntity);
	                           if(!typeof(obj) && !obj.LE_NAME){
	                             AlertService.add("error", "Please enter Payor entity",4000);
	     	            	 	return;
	     	            	     }
	     	            	 } else {
	     	            	    AlertService.add("error", "Please enter Payor entity",4000);
	     	            	    return;
	     	            	 }
	                	 }
		            } else {
		            	AlertService.add("error", "Please enter Payor entity",4000)
		            	return;
		            }		            
		           
		            if(vm.payorFeeds.selected == null ||vm.payorFeeds.selected == 0 ||vm.payorFeeds.selected == undefined){
		            	
		            	AlertService.add("error", "Please enter Payor feeds",4000);
	   	        		return;
		            }
		            if(vm.payeeEntity  ){
		            	if(!vm.dividendReceivedObj.PAYEE_DISABLED){
			            	var payee = typeof(vm.payeeEntity) === 'object' ?  vm.payeeEntity.LE_NAME : vm.payeeEntity;
			            	if( vm.receipeint) {
			            		var obj = vm.receipeint.find(o => o.LE_NAME === payee);
				            	 if(!typeof(obj) && !obj.LE_NAME){
			                        AlertService.add("error", "Please enter Payee entity",4000);
				            	 	return;
				            	 }
				            	 
			            	}else {
				            	AlertService.add("error", "Please enter Payee entity",4000);
				            	return;
			            	}
		            	}
		            } else {
		            	AlertService.add("error", "Please enter Payee entity",4000);
		            	return;
		            }
		            
		            
		            if(vm.payeeFeeds.selected == null ||vm.payeeFeeds.selected == 0 ||vm.payeeFeeds.selected == undefined){
		            	
		            	AlertService.add("error", "Please enter Payee feeds",4000);
	   	        		return;
		            }
		            if( parseInt(vm.TRANS_SUB_TYPE) !== 8   ){
		            	if(!vm.dividendPaidObj.PAYOR_AMT && vm.dividendPaidObj.PAYOR_AMT == 0) {
		            		AlertService.add("error", "Please enter valid Payor amount",4000);
		   	        		return;
		            	}
		            }
		            
		            if( parseInt(vm.TRANS_SUB_TYPE) !== 8   ){
		            	if(!vm.dividendReceivedObj.PAYEE_AMT && vm.dividendReceivedObj.PAYEE_AMT == 0) {
		            		AlertService.add("error", "Please enter valid Payee amount",4000);
		   	        		return;
		            	}
		            }
		            
		            if(vm.dividendPaidObj.PAYOR_TAX_AMT == null || vm.dividendPaidObj.PAYOR_TAX_AMT == undefined ){
		            	AlertService.add("error", "Please enter numeric value in Payor Withholding Tax",4000);
	   	        		return;
		            	
		            }
		           
		           	            
		            if(vm.dividendReceivedObj.PAYEE_TAX_AMT== null  || vm.dividendReceivedObj.PAYEE_TAX_AMT== undefined){
		            	AlertService.add("error", "Please enter valid numeric value in Payee Withholding Tax",4000);
	   	        		return;
		            	
		            }
		            vm.INTL_TRANS_KEY = vm.INTL_TRANS_KEY ? vm.INTL_TRANS_KEY.ENTRY_ID: "";
        	
        	vm.formatDate();
        	// console.log("");
            if(vm.date_flag == true || !vm.TRANS_DATE || vm.TRANS_DATE == null){            
            	vm.date_flag =true;
            	 AlertService.add("error", "Please enter valid date",4000);            
            }else{            
	            var headerSettings = [];
	            var transactions = [];
	            var recipient_trans_type;
	            var trans_type_304;
	          	            
	            vm.filtered_dividend = _.filter(vm.dividendTypes.list, {'POST_DESC':'NONTAX'})   
	            vm.filtered_list = _.filter(vm.filtered_dividend, {'CODE_ID': vm.TRANS_SUB_TYPE.toString()});
	            	
	            if(angular.isDefined(vm.filtered_list[0]) && vm.filtered_list[0].CODE_ID == vm.TRANS_SUB_TYPE){
	            	recipient_trans_type = 21;
	            }
	            /*if(parseInt(vm.TRANS_SUB_TYPE)  === 1) {
	                recipient_trans_type = 21;
	            } */
	            else {
	                recipient_trans_type = 22;
	            };
	           
	            if(parseInt(vm.TRANS_SUB_TYPE)  === 2) {
	                trans_type_304 = 1010;
	            } 
	            var message = (vm.dividendHeader.GROUP_TRANS_KEY === 0) ? "Dividends have been successfully saved" : "Dividends have been successfully updated";

	            headerSettings.push(vm.dividendHeader);
	            console.log("dividendPaidObj------", vm.dividendPaidObj);
	            console.log("dividendReceivedObj------------", vm.dividendReceivedObj);
	            // add dividends paid object
	            var divPaidObj = new dividend_object(vm.dividendPaidObj["TRANS_HEADER_KEY"], vm.dividendPaidObj["COMBINATION_KEY"], 20,
	                                                vm.INTL_TRANS_KEY === "" ?0:vm.INTL_TRANS_KEY, vm.dividendPaidObj["ADJ_SOURCE_TYPE"],
	                                                parseInt(vm.TRANS_SUB_TYPE), vm.dividendPaidObj["TRANS_DETAILS_KEY"],
	                                                vm.TRANS_DATE, vm.dividendPaidObj["PAYOR_AMT"], vm.dividendPaidObj["PAYOR_TAX_AMT"],
	                                                vm.dividendReceivedObj["COMBINATION_KEY"], "", "N", "",vm.cashDist);
	            transactions.push(divPaidObj);

	            // add dividends paid obj
	            var divReceivedObj = new dividend_object(vm.dividendReceivedObj["TRANS_HEADER_KEY"], vm.dividendReceivedObj["COMBINATION_KEY"], recipient_trans_type,
	            									vm.INTL_TRANS_KEY === "" ?0:vm.INTL_TRANS_KEY, vm.dividendReceivedObj["ADJ_SOURCE_TYPE"],
	                                                parseInt(vm.TRANS_SUB_TYPE), vm.dividendReceivedObj["TRANS_DETAILS_KEY"],
	                                                vm.TRANS_DATE, vm.dividendReceivedObj["PAYEE_AMT"], vm.dividendReceivedObj["PAYEE_TAX_AMT"],
	                                                vm.dividendPaidObj["COMBINATION_KEY"], vm.dividendReceivedObj["ADJ_TYPE"],"N","",vm.cashDist);
	            transactions.push(divReceivedObj);

	            console.log('transactions---', transactions);

	            if(vm.dividendTypes.selected.CODE_ID === "2") {

	                var trans304Obj = new dividend_object(vm.trans304Obj["TRANS_HEADER_KEY"], vm.trans304Obj["TRANS_304_TP_KEY"], trans_type_304,
	                                                      0, "",
	                                                      vm.TRANS_SUB_TYPE, vm.trans304Obj["TRANS_DETAILS_KEY"],
	                                                      vm.TRANS_DATE, vm.trans304Obj["TRANS_304_AMT"], 0,
	                                                      "",vm.trans304Obj["ADJ_TYPE"], "Y", vm.trans304Obj["TRANS_304_CURR"],vm.cashDist);

	                transactions.push(trans304Obj);
	            }
	            if(vm.cashDist === 'N'){
	            	
	            	 var cashDistObj = new dividend_object(vm.cashDistObj["TRANS_HEADER_KEY"], vm.dividendPaidObj["COMBINATION_KEY"], 1177,
                             								0, "",
                             								vm.TRANS_SUB_TYPE,vm.cashDistObj["TRANS_DETAILS_KEY"],
                             								vm.TRANS_DATE, vm.cashDistObj["TAX_BASE_VAL"], 0,
                             								"","", "N", "",vm.cashDist);

				transactions.push(cashDistObj);
				
	            }
	           console.log("transactions-----------", transactions);
               console.log("headerSettings-----", headerSettings);
               var checkLockObj =[];
                 checkLockObj.push({combination_key:vm.dividendPaidObj.COMBINATION_KEY});
                 var LockObj = {};
                 LockObj.modal_name=ModalFactory.response.data.modalScreen.modal_name,
                 LockObj.lockingObj=checkLockObj;
                   

                  
	            DividendsFactory.saveUpdateDividends(headerSettings, transactions,LockObj).then(function(res) {
	                if (res.errorMessage && res.errorMessage !== 'null') {
	                    AlertService.add("error", res.errorMessage, 4000);
	                } else {
	                    AlertService.add("success", message, 4000);
	                    vm.cancel();
	                }

	            });
        	}
        };

        vm.deleteDividends = function() {
            var message = "Dividend has been deleted";
            var _data = {
                group_trans_key: vm.dividendHeader.GROUP_TRANS_KEY
            };

            DividendsFactory.deleteDividends(_data).then(function(res) {
                if (res.errorMessage && res.errorMessage !== 'null') {
                    AlertService.add("error", res.errorMessage, 4000);
                } else {
                    AlertService.add("success", message, 4000);
                    /*var args = {combination_keys: vm.rowData.PAYOR_COMBINATION_KEY, gridFilter: {combination_key: vm.rowData.PAYOR_COMBINATION_KEY,object_id:vm.rowData.OBJECT_ID,action:"delete"}};
                    $rootScope.$emit('gridUpdate', args);
                    $uibModalInstance.dismiss('cancel');*/
                    
				    var args = {combination_keys: vm.rowData.PAYOR_COMBINATION_KEY, gridFilter:{ 
					object_id:vm.rowData.OBJECT_ID//,action:"delete"
            		}};
            		$rootScope.$emit('gridUpdate', args);
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$broadcast('gridRefresh', true);
                    // var args = {};
                    // vm.cancel();
                }

            });

        };

        vm.reset = function() {
            init();
        };
      // $rootScope.$emit('gridUpdate', args);
        // $rootScope.$broadcast("gridRefresh", true);
        vm.cancel = function() {
            var args = {};
            // $rootScope.broadcast("gridRefresh", {});
            $rootScope.$emit('gridUpdate', args);
            $uibModalInstance.dismiss('cancel');
        };

       /*
		 * vm.addDividend = function() { console.log('-----------getting all
		 * rowobject properties --------------');
		 * Object.keys(vm.rowData).forEach(function(key) { console.log('Key : ' +
		 * key + ', Value : ' + vm.rowData[key] + ' typeof : ' +
		 * typeof(vm.rowData[key])); if(typeof(vm.rowData[key]) === 'string') {
		 * vm.rowData[key] = ""; } else { vm.rowData[key] = 0; }
		 * 
		 * });
		 * 
		 * init(); };
		 */

        function init() {
            vm.title = 'Dividend Input';
            vm.tableMinimise = true;
            
           // vm.rowData = rowData;

			vm.rowData = rowData||$scope.$parent.rowData;
            vm.calendar = {
                opened: false
            };
            
            vm.INTL_TRANS_KEY = "";
            vm.dividendTypes = {selected:null, list: []};
            vm.dividendPayment = {};
            vm.transCurrencies = {selected:null, list: []};
            
         
            if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd')
            {
            	vm.disableTable = true;
            	vm.payeeEntity = "";
                vm.payorEntity = "";
                vm.payorFeeds = {selected:null, list: []};
                vm.payeeFeeds = {selected:null, list: []};
                vm.dividendTypes = {selected:null, list: []};
                vm.dividendPayment = {};
                vm.transCurrencies = {selected:null, list: []};
                vm.show = true;
                
          
            }
            else if(vm.colData.call_back === 'paidDividendAdjs')
            {	vm.show = true;
            	vm.payorEntity = vm.rowData.LEID+'/'+vm.rowData.CDR_NO+'-'+vm.rowData.LE_NAME + '(' + vm.rowData.REPORTING_PERIOD + ')';
            	vm.payeeEntity = "";
            	vm.tableTitle = "Dividend Paid Details";
            	DividendsFactory.getDividendsPaid().then(function(divPaid) {
                    vm.payorTable = divPaid;
                    vm.payorTotal = 0;
                    for(var i=0;i<vm.payorTable.length;i++){
                    	if(vm.rowData.LEID === vm.payorTable[i].LEID && vm.rowData.COMBINATION_KEY === vm.payorTable[i].COMBINATION_KEY){
                    		vm.payorTotal +=  vm.payorTable[i].PAID_TB_AMT;
                    	}
                    	
                    }
                 });
               
            /*
			 * var tempListObj = { FEED_DISPLAY :
			 * vm.rowData.LEID+'/'+vm.rowData.CDR_NO + '/' + vm.rowData.ME_CODE +
			 * '/' + vm.rowData.CURR };
			 */
            	var tempSelectedObj = {
            			CDR_NO: vm.rowData.CDR_NO,
            		    COMBINATION_KEY: vm.rowData.COMBINATION_KEY,
            		    CURR: vm.rowData.CURR,
            		    FEED_DISPLAY: vm.rowData.LEID+'/'+vm.rowData.CDR_NO + '/' + vm.rowData.ME_CODE + '/' + vm.rowData.LEDGER + '/' + vm.rowData.CURR,
            			LEID: vm.rowData.LEID,
            			ME_CODE: vm.rowData.ME_CODE,
            			TAX_YEAR_BEGIN: vm.rowData.TAX_YEAR_BEGIN,
            			TAX_YEAR_END: vm.rowData.TAX_YEAR_END,
            			WTD_AVG_RATE: vm.rowData.WTD_AVG_RATE,
            	}
            	vm.payorFeeds = {selected:tempSelectedObj, list:[]};
            	vm.payorFeeds.list.push(tempSelectedObj);
            	vm.payeeFeeds = {selected:null, list:[]};
            	console.log(vm.payorFeeds);
            }
            else if (vm.colData.call_back === 'receivedDividendAdjs')
            {	vm.show = true;
            	vm.payeeEntity = vm.rowData.LEID+'/'+vm.rowData.CDR_NO+'-'+vm.rowData.LE_NAME + '(' + vm.rowData.REPORTING_PERIOD + ')';
            	vm.payorEntity = "";
            	vm.tableTitle = "Dividend Received Detail";
            	 DividendsFactory.getDividendsRecieved().then(function(divRec) {
                     vm.payeeTable = divRec;
                     vm.payeeTotal = 0;
                     for(var i=0;i<vm.payeeTable.length;i++){
                    	 if(vm.rowData.LEID === vm.payeeTable[i].LEID && vm.rowData.COMBINATION_KEY === vm.payeeTable[i].COMBINATION_KEY){
                    		 vm.payeeTotal +=  vm.payeeTable[i].RECEIVED_TB_AMT;
                    	 }
                     }
                  });
            	var tempSelectedObj = {
            			CDR_NO: vm.rowData.CDR_NO,
            		    COMBINATION_KEY: vm.rowData.COMBINATION_KEY,
            		    CURR: vm.rowData.CURR,
            		    FEED_DISPLAY: vm.rowData.LEID+'/'+vm.rowData.CDR_NO + '/' + vm.rowData.ME_CODE + '/' + vm.rowData.LEDGER + '/' + vm.rowData.CURR,
            			LEID: vm.rowData.LEID,
            			ME_CODE: vm.rowData.ME_CODE,
            			TAX_YEAR_BEGIN: vm.rowData.TAX_YEAR_BEGIN,
            			TAX_YEAR_END: vm.rowData.TAX_YEAR_END,
            			WTD_AVG_RATE: vm.rowData.WTD_AVG_RATE,
            	}
            	vm.payeeFeeds = {selected:tempSelectedObj, list:[]};
            	vm.payeeFeeds.list.push(tempSelectedObj);
            	vm.payorFeeds = {selected:null, list:[]};
            	console.log(vm.payeeFeeds);
            }
            else
            {
              vm.payeeEntity = "";
              vm.payorEntity = "";
              vm.payorFeeds = {selected:null, list:[]};
              vm.payeeFeeds = {selected:null, list:[]};
              vm.dividend_disabled = true;
            }
            vm.TRANS_SUB_TYPE = null;
            vm.TRANS_DATE = "";
            vm.PAYMENT_DATE = "";
            vm.dividendHeader = {
                "tax_year": DividendsFactory.getScreenFilters().tax_year,
                "GROUP_TRANS_KEY": 0,
                "ISSUE_KEY": GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)
            };
            
            if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
            	
            	vm.dividendPaidObj = {
                        "PAYOR_DISABLED": true,
                        "PAYOR_TB_DISABLED": true,
                        "PAYOR_AMT_DISABLED": true,
                        "PAYOR_TAX_AMT_DISABLED": true,
                        "TRANS_HEADER_KEY":0,
                        "COMBINATION_KEY":angular.isDefined(vm.colData) && vm.colData.call_back === 'paidDividendAdjs' ? vm.rowData.COMBINATION_KEY :0 ,
                        "INTL_TRANS_KEY": 0,
                        "ADJ_SOURCE_TYPE": "",
                        "TRANS_DETAILS_KEY": 0,
                        "PAYOR_AMT": 0,
                        "PAYOR_TAX_AMT": 0,
                        "PAYOR_AMT_USD": 0,
                        "PAYOR_TAX_AMT_USD": 0,
                        "PAYEE_TP_KEY": angular.isDefined(vm.colData) && vm.colData.call_back === 'receivedDividendAdjs' ? vm.rowData.TP_KEY : 0
                    };
            }
            else{
            	vm.dividendPaidObj = {
                         "PAYOR_DISABLED": true,
                         "PAYOR_TB_DISABLED": true,
                         "PAYOR_AMT_DISABLED": (vm.colData.call_back === 'paidDividendAdjs'? false: true),
                         "PAYOR_TAX_AMT_DISABLED": (vm.colData.call_back === 'paidDividendAdjs' ? false : true),
                         "TRANS_HEADER_KEY":0,
                         "COMBINATION_KEY":(vm.colData.call_back === 'paidDividendAdjs' ? vm.rowData.COMBINATION_KEY :0),
                         "INTL_TRANS_KEY": 0,
                         "ADJ_SOURCE_TYPE": "",
                         "TRANS_DETAILS_KEY": 0,
                         "PAYOR_AMT": 0,
                         "PAYOR_TAX_AMT": 0,
                         "PAYOR_AMT_USD": 0,
                         "PAYOR_TAX_AMT_USD": 0,
                         "PAYEE_TP_KEY": (vm.colData.call_back === 'receivedDividendAdjs' ? vm.rowData.TP_KEY : 0)
                     };
            }	
            	
           
            if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
            	 vm.dividendReceivedObj = {
                         "PAYEE_DISABLED": true,
                         "PAYEE_TB_DISABLED": true,
                         "PAYEE_AMT_DISABLED": angular.isDefined(vm.colData) && vm.colData.call_back === 'receivedDividendAdjs' ? false : true,
                         "PAYEE_TAX_AMT_DISABLED": angular.isDefined(vm.colData) && vm.colData.call_back === 'receivedDividendAdjs' ? false : true,
                         "TRANS_HEADER_KEY":0,
                         "COMBINATION_KEY":angular.isDefined(vm.colData) && vm.colData.call_back === 'receivedDividendAdjs' ? vm.rowData.COMBINATION_KEY :0,
                         "INTL_TRANS_KEY": 0,
                         "ADJ_SOURCE_TYPE": "",
                         "TRANS_DETAILS_KEY": 0,
                         "PAYEE_AMT": 0,
                         "PAYEE_TAX_AMT": 0,
                         "PAYEE_AMT_USD": 0,
                         "PAYEE_TAX_AMT_USD": 0,
                         "PAYOR_TP_KEY": angular.isDefined(vm.colData) && vm.colData.call_back === 'paidDividendAdjs' ? vm.rowData.TP_KEY : 0,
                         "ADJ_TYPE": null

                     };
            }else{
            	
            	 vm.dividendReceivedObj = {
                         "PAYEE_DISABLED": true,
                         "PAYEE_TB_DISABLED": true,
                         "PAYEE_AMT_DISABLED": (vm.colData.call_back === 'receivedDividendAdjs' ? false : true),
                         "PAYEE_TAX_AMT_DISABLED": (vm.colData.call_back === 'receivedDividendAdjs' ? false : true),
                         "TRANS_HEADER_KEY":0,
                         "COMBINATION_KEY":(vm.colData.call_back === 'receivedDividendAdjs' ? vm.rowData.COMBINATION_KEY :0),
                         "INTL_TRANS_KEY": 0,
                         "ADJ_SOURCE_TYPE": "",
                         "TRANS_DETAILS_KEY": 0,
                         "PAYEE_AMT": 0,
                         "PAYEE_TAX_AMT": 0,
                         "PAYEE_AMT_USD": 0,
                         "PAYEE_TAX_AMT_USD": 0,
                         "PAYOR_TP_KEY": (vm.colData.call_back === 'paidDividendAdjs' ? vm.rowData.TP_KEY : 0),
                         "ADJ_TYPE": null

                     };
            }
           
            vm.trans304Obj = {
                "TRANS_304_AMT_DISABLED": true,
                "TARGET_ENTITY_304_DISABLED": true,
                "TRANS_304_CURR":"",
                "TRANS_HEADER_KEY":0,
                "TRANS_DETAILS_KEY": 0,
                "TRANS_304_AMT":0,
                "TRANS_304_AMT_USD":0,
                "TRANS_304_TP_KEY":0,
                "IS_TRANS_304": 'N'
            };
            
            vm.cashDistObj = {
                    "TRANS_HEADER_KEY":0,
                    "TRANS_DETAILS_KEY":0,
                    "TAX_BASE_VAL":0,
                    "TAX_BASE_VAL_USD":0
                };
            
         
           if((angular.isDefined(vm.colData)&&vm.colData.call_back === 'paidDividendAdjs'&& angular.isDefined(vm.colData)&&vm.colData.call_back === 'receivedDividendAdjs')||angular.isDefined(vm.rowData)&& vm.rowData.GROUP_TRANS_KEY !== 0) {
                populatePayorPayeeObjects();
            }

            getDividendTypes();
            getTransCurrencies();
        }

        init();

        function getDividendTypes() {
            var dividendTypeIdx = 0;

            DividendsFactory.getDividendTypes().then(function(res) {
                if(res.length) {
                	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
                		 vm.dividendTypes.list = res; 
                	 }else{
                		 vm.dividendTypes.list = res;

                         if(vm.rowData.GROUP_TRANS_KEY !== 0) {
                             dividendTypeIdx = _.findIndex(vm.dividendTypes.list, {'CODE_ID': vm.TRANS_SUB_TYPE.toString()});
                             console.log('Dividend Type: ' + vm.TRANS_SUB_TYPE + 'tostring type: ' + vm.TRANS_SUB_TYPE.toString() + ' dividend type index: ' + dividendTypeIdx);
                             vm.dividendTypes.selected = vm.dividendTypes.list[dividendTypeIdx];
                         } 
                	 }
                   
                }

            });
        };
        function getTransCurrencies() {
            var transCurrIndex = 0;
            DividendsFactory.getTransCurrencies().then(function(res) {
                if(res.length) {
                	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
                		 vm.transCurrencies.list = res; 
                	 }else{
                    vm.transCurrencies.list = res;
                   if(vm.rowData.GROUP_TRANS_KEY !== 0) {
                        transCurrIndex = _.findIndex(vm.transCurrencies.list, {'CURR_CODE': vm.rowData.TRANS_304_CURR});
                        vm.transCurrencies.selected = vm.transCurrencies.list[transCurrIndex];
                   }
                	 }
                }

            });
        };

        function populatePayorPayeeObjects() {
            vm.dividendHeader.GROUP_TRANS_KEY = vm.rowData.GROUP_TRANS_KEY;
            var setDate = new Date(vm.rowData.PAYMENT_DATE);

            vm.TRANS_SUB_TYPE =  vm.rowData.DIVIDEND_TYPE_ID;
            vm.PAYMENT_DATE = new Date(setDate.getFullYear(), setDate.getMonth(), setDate.getDate());
            vm.formatDate();

            vm.payorEntity = vm.rowData.PAYOR_ENTITY_DTLS;
            getEntityFeeds('PAYOR', vm.rowData.PAYOR_TP_KEY);

            vm.payeeEntity = vm.rowData.PAYEE_ENTITY_DTLS;
            getEntityFeeds('PAYEE', vm.rowData.PAYEE_TP_KEY);

            vm.targetEntity304 = vm.rowData.TRANS_304_ENTITY_DTLS;     
      
            if(vm.rowData.INTL_TRANS_KEY === 0 && vm.rowData.TRANSACTION_NAME === undefined){
            	vm.INTL_TRANS_KEY = ''
            } else{
                vm.INTL_TRANS_KEY = vm.rowData.INTL_TRANS_KEY +'/'+ vm.rowData.TRANSACTION_NAME;

            }


            
            vm.cashDist = vm.rowData.DIV_CASH_DIST_YN;
            vm.base_val = vm.rowData.CASH_DIST_AMT;
            
        	vm.disableTable = true;
        	
            vm.dividendPaidObj = {
                "PAYOR_DISABLED": true,
                "PAYOR_TB_DISABLED": true,
                "PAYOR_AMT_DISABLED": false,
                "PAYOR_TAX_AMT_DISABLED": false,
                "TRANS_HEADER_KEY": vm.rowData.PAYOR_TRANS_HEADER_KEY,
                "COMBINATION_KEY":vm.rowData.PAYOR_COMBINATION_KEY,
                "INTL_TRANS_KEY": vm.rowData.INTL_TRANS_KEY,
                "ADJ_SOURCE_TYPE": vm.rowData.ADJ_SOURCE_TYPE,
                "TRANS_DETAILS_KEY": vm.rowData.PAYOR_TRANS_DETAILS_KEY,
                "PAYOR_AMT": vm.rowData.PAYOR_AMT,
                "PAYOR_TAX_AMT": vm.rowData.PAYOR_TAX_WITHOLD,
                "PAYOR_AMT_USD": vm.rowData.PAYOR_AMT_USD,
                "PAYOR_TAX_AMT_USD": vm.rowData.PAYOR_TAX_WITHOLD_USD,
                "PAYEE_TP_KEY": vm.rowData.PAYEE_TP_KEY
            };
            vm.dividendReceivedObj = {
                "PAYEE_DISABLED": true,
                "PAYEE_TB_DISABLED": true,
                "PAYEE_AMT_DISABLED": false,
                "PAYEE_TAX_AMT_DISABLED": false,
                "TRANS_HEADER_KEY":vm.rowData.PAYEE_TRANS_HEADER_KEY,
                "COMBINATION_KEY":vm.rowData.PAYEE_COMBINATION_KEY,
                "INTL_TRANS_KEY": vm.rowData.INTL_TRANS_KEY,
                "ADJ_SOURCE_TYPE": vm.rowData.ADJ_SOURCE_TYPE,
                "TRANS_DETAILS_KEY": vm.rowData.PAYEE_TRANS_DETAILS_KEY,
                "PAYEE_AMT": vm.rowData.PAYEE_AMT,
                "PAYEE_TAX_AMT": vm.rowData.PAYEE_TAX_WITHOLD,
                "PAYEE_AMT_USD": vm.rowData.PAYEE_AMT_USD,
                "PAYEE_TAX_AMT_USD": vm.rowData.PAYEE_TAX_WITHOLD_USD,
                "PAYOR_TP_KEY": vm.rowData.PAYOR_TP_KEY,
                "ADJ_TYPE": null

            };

            if(vm.TRANS_SUB_TYPE === 2) {
                getTransCurrencies();

                vm.trans304Obj = {
                    "TRANS_304_AMT_DISABLED": false,
                    "TARGET_ENTITY_304_DISABLED": (vm.colData.call_back === 'dividendAdjs' ? false : true),
                    "TRANS_304_CURR":vm.rowData.TRANS_304_CURR,
                    "TRANS_HEADER_KEY":vm.rowData.TRANS_304_HEADER_KEY,
                    "TRANS_DETAILS_KEY":vm.rowData.TRANS_304_DETAILS_KEY,
                    "TRANS_304_AMT":vm.rowData.TRANS_304_AMT,
                    "TRANS_304_AMT_USD": vm.rowData.TRANS_304_AMT_USD,
                    "TRANS_304_TP_KEY":vm.rowData.TRANS_304_TP_KEY,
                    "IS_TRANS_304": 'Y'
                };
            }
            if(vm.cashDist === 'N') {
                vm.cashDistObj = {
                    "TRANS_HEADER_KEY":vm.rowData.TRANS_CASH_DIST_HEADER_KEY,
                    "TRANS_DETAILS_KEY":vm.rowData.TRANS_CASH_DIST_DETAILS_KEY,
                    "TAX_BASE_VAL":vm.rowData.TAX_BASE_VAL,
                    "TAX_BASE_VAL_USD": vm.rowData.TAX_BASE_VAL_USD
                };
            }

        };

        function getEntityFeeds(entityType, tp_key) {
        	
            var payorIndex, payeeIndex = 0;
            if(entityType === 'PAYOR') {
            DividendsFactory.getEntityFeeds(tp_key).then(function(res) {
                if(res.length) {

                    
                    	
                    	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){                    		 
                    		 vm.payorFeeds.list = res;
                             vm.dividendPaidObj.PAYOR_TB_DISABLED = false; 
                             vm.payorFeeds.selected = "";
                    	 }
                    	 else{
                    		 vm.payorFeeds.list = res;                             
                             if(vm.rowData.GROUP_TRANS_KEY !== 0) {
                                 payorIndex = _.findIndex(vm.payorFeeds.list, {'COMBINATION_KEY': vm.rowData.PAYOR_COMBINATION_KEY});
                                 vm.payorFeeds.selected = vm.payorFeeds.list[payorIndex];
                             }
                             else
                             	vm.payorFeeds.selected = ""; 
                    		 
                    	 }
                       
                   
                   
                }
            })
            } 
            else {
           	 DividendsFactory.getPayeeEntityFeeds(tp_key).then(function(res) {
           	 if(ModalFactory.response.data.modalScreen.modal_name === 'newDividendAdd'){
           		 vm.payeeFeeds.list = res;
                    vm.dividendReceivedObj.PAYEE_TB_DISABLED = false; 
           	 }
           	 else{
           		 vm.payeeFeeds.list = res;
                    if(vm.rowData.GROUP_TRANS_KEY !== 0) {
                        payeeIndex = _.findIndex(vm.payeeFeeds.list, {'COMBINATION_KEY': vm.rowData.PAYEE_COMBINATION_KEY});
                        vm.payeeFeeds.selected = vm.payeeFeeds.list[payeeIndex];
                    } 
           	 }                       
           
           });
           console.log('vm.payorFeeds----', vm.payorFeeds);
           }
        };
        

        vm.cashDist = vm.cashDist === undefined ? 'Y' :vm.cashDist;
       
        function getAdjSourceType(begin_date, end_date) {
            var user_date = vm.formattedDate;
            if ((user_date >= begin_date) && (user_date <= end_date)){
                return "TB";
            }else{
                return "RECLASS";
            }
        };

        function dividend_object(trans_header_key, combination_key, trans_type_key, intl_trans_key, adj_source_type, trans_sub_type,
                                 trans_details_key, trans_date, div_amount, tax_amt, tp_key, adj_type, is_trans_304, trans_curr,cash_y_n) {
            this.TRANS_HEADER_KEY = trans_header_key;
            this.COMBINATION_KEY = combination_key;
            this.TRANS_TYPE_KEY = trans_type_key;
            this.INTL_TRANS_KEY = intl_trans_key;
            this.ADJ_SOURCE_TYPE = adj_source_type;
            this.TRANS_SUB_TYPE = trans_sub_type;
            this.TRANS_DETAILS_KEY = trans_details_key;
            this.TRANS_DATE = trans_date;
            this.DIV_AMOUNT = div_amount;
            this.TAX_AMT = tax_amt;
            this.TP_KEY = tp_key;
            this.ADJ_TYPE = adj_type;
            this.IS_TRANS_304 = is_trans_304;
            this.TRANS_CURR = trans_curr;
            this.CASH_Y_N = cash_y_n;
        };

        function convertToUS(val, wtd_avg_rate, objType, fieldToUpdate, fieldName) {

            if(objType === 'PAIDOBJ') {
                vm.dividendPaidObj[fieldToUpdate] = Math.round(val * wtd_avg_rate);
            } else if(objType === 'RECEIVEDOBJ') {
                vm.dividendReceivedObj[fieldToUpdate] = Math.round(val * wtd_avg_rate);
            } else if(objType === 'CASHDISTOBJ') {
                vm.cashDistObj[fieldToUpdate] = Math.round(val * wtd_avg_rate);
            } else {
                vm.trans304Obj[fieldToUpdate] = Math.round(val * wtd_avg_rate);
            }

        };

    }
    // /////////////END CTRL
    

    return controllers;


});