/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.section174adjService',[])

        .factory("Section174adjFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
        	 var tbbsServiceW = {};


            var URLS = {
               // FETCH: GENERAL_CONFIG.base_url + '/getProjectTag', // we can use this when we have more URL'S
                SAVE: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=mfnpur"
            }
        
            tbbsServiceW.saveProjectTagW = function(_data,_schdMs){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_schdMs);
                var jsonSettings =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "SAVE_SECTION174_DETAILS";
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: URLS.SAVE,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            
            }



            return tbbsServiceW;

        }])


    return services;


});
