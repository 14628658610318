define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.section965Service',[])

        .factory("Section965Factory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,JsonObjectFactory,workspaceFactory,GENERAL_CONFIG) {
            var section965Factory = {};
            section965Factory.url = {};
            section965Factory.url.saveAmtsComments  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=704";
            section965Factory.url.saveFilingGroup  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0t3r6y";
            section965Factory.url.saveNovDecAdjs = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=xo9aly";
            section965Factory.url.saveEP1231Adjs = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=17arek";

            section965Factory.getActiveScreen = function() {
                return workspaceFactory.activeScreen.screen_key;
            };
            
            section965Factory.getFilingGroups = function (_data) {
                var params = {
                    /* "action_id":703, */
                    "action_code":"pa5z49",
                    "scr_filing_group": _data.scr_filing_group
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                    if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                        AlertService.add("error", data.errorMessage);
                        return false;
                    }else{
                       
                        return data.jsonObject;
                    }
                });
               
            };

            section965Factory.saveAmtsComments = function(_data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);

                params.ghost_scenario = _data.ghost_scenario;
                params.leid = _data.leid;
                params.tax_code = _data.tax_code;
                params.filing_group = _data.filing_group;
                params.adj_amt = _data.adj_amt;
                params.comments = _data.comments;

               
                var promise = $http({
                    method: "post",
                    url: section965Factory.url.saveAmtsComments,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;


            };

            section965Factory.saveFilingGroup = function(_data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
               
                params.ghost_scenario = _data.ghost_scenario;
                params.leid = _data.leid;
                params.tax_code = _data.tax_code;
                params.filing_group = _data.filing_group;
                params.new_filing_group = _data.new_filing_group;

                var promise = $http({
                    method: "post",
                    url: section965Factory.url.saveFilingGroup,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;


            };

            section965Factory.saveNovDecAdjs = function(_data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);

                params.ghost_scenario = _data.ghost_scenario;
                params.leid = _data.leid;
                params.tax_code = _data.tax_code;
                params.filing_group = _data.filing_group;
                params.nov_adj_amt = _data.nov_adj_amt;
                params.nov_adj_comments = _data.nov_adj_comments;
                params.dec_adj_amt = _data.dec_adj_amt;
                params.dec_adj_comments = _data.dec_adj_comments;
                params.adj_type = _data.adj_type;

                var promise = $http({
                    method: "post",
                    url: section965Factory.url.saveNovDecAdjs,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;


            };

            section965Factory.saveEP1231Adjs = function(_data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);

               

                params.ghost_scenario = _data.ghost_scenario;
                params.leid = _data.leid;
                params.tax_code = _data.tax_code;
                params.filing_group = _data.filing_group;
                params.ep_1231adj_amt = _data.ep_1231adj_amt;
                params.ep_1231adj_comments = _data.ep_1231adj_comments;
               
                
                var promise = $http({
                    method: "post",
                    url: section965Factory.url.saveEP1231Adjs,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;


            };
            return section965Factory;
        }]);


    return services;


});