define(
		[ 'angular' ],
		function() {
			'use strict';

			var controllers = angular.module('app.limitationCtrl', [])
					.controller(
							'limitationController',
							[ 'bizzHierViewFactory', '$scope', "AlertService",
									"$filter", "GlobalService",
									"USER_SETTINGS", "entityLockingFactory",
									"$uibModal", "GENERAL_CONFIG",
									"JsonObjectFactory", "LimitObjFactory",
									"workspaceFactory", "$rootScope",
									limitationController ])

			function limitationController(bizzHierViewFactory, $scope,
					AlertService, $filter, GlobalService, USER_SETTINGS,
					entityLockingFactory, $uibModal, GENERAL_CONFIG,
					JsonObjectFactory, LimitObjFactory, workspaceFactory,
					$rootScope) {
				var vm = this;
				var activeScreen = workspaceFactory.activeScreen;
				$scope.crudLoading1231Gain = false;
				$scope.trees = {};
				vm.gainloss1231 = [];
				vm.gainloss1231_summary = [];
				$scope.crudLoadingcapitalanalysis = false;
				$scope.crudcharitablecontribution = false;
				workspaceFactory.checkFilters(workspaceFactory.activeScreen);
				vm.charitablecontribution = [];
				vm.charitablecontribution_summary = [];
				vm.capitallossanalysis = [];
				vm.limitationSummary = [];
				vm.drdWorkSheet_summary = [];
				var sendobj = [];
				var sendobj1 = [];
				var jsendobj = [];
				var jsendobj1 = [];
				var save1231Gainobj = [];
				var save1231Gainjsendobj = [];

				vm.sys_acc_dropdown_data = [ 'Life', 'Non-Life' ];
				vm.default_value = 'Life';
				vm.sys_acc_dropdown_data_1231 = [ 'REG', 'LI', 'NLI' ];
				vm.default_value_1231 = 'REG';
				vm.ovverrideamount1231gain1 = "";
				vm.ovverrideamount1231gain2 = "";
				vm.gain1231comment1 = "";
				vm.gain1231comment2 = "";
				vm.gain1231grp_type = "";
				vm.limitationSummaruGrpType = "";
				vm.is_calc = "";
				vm.grp_type_label = "Group Type";
				vm.capitallossgrp_type = "";
				vm.charitable_type = "";
				vm.reset1231gain = function() {
					fetch_1231_gainloss_data_summary();
				}
				vm.resetcapitalanalysis = function() {
					fetchGainLossanalysis();
				}
				vm.resetcharitablecontribution = function() {
					fetchcharitablecontribution();
				}
				vm.resetDRDWS = function(){
					fetchDRDWorksheet();
				}
				//
				$scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
				var activeScreen = workspaceFactory.activeScreen;
				if (activeScreen && activeScreen.data) {
					vm.showBackdrop = false;
					vm.showBackdrop_analysis = false;
					vm.showBackdrop_charitable = false;
					$scope.trees.currentAlertMessage = activeScreen.data.currentAlertMessage;
					$scope.trees.loading = activeScreen.data.loading;
					$scope.trees.nodes = activeScreen.data.nodes;
				} else {
					vm.showBackdrop = true;
					vm.showBackdrop_analysis = true;
					vm.showBackdrop_charitable = true;
				}
				$scope
						.$on(
								'dataFilters:refreshGrid',
								function(event, data) {

									vm.mainScreenKey = workspaceFactory.activeScreen.screen_key;
									if (vm.mainScreenKey == "10956") {

										fetch_1231_gainloss_data_summary();

									}
									if (vm.mainScreenKey == "11117") {
										fetchGainLossanalysis();
									}
									if (vm.mainScreenKey == "10955") {
										fetch_charitable_data_summary();
										
									}
									if (vm.mainScreenKey == "11159") {
										fetchDRDWorksheetSummary();
										
									}
									if (vm.mainScreenKey == "11203") {
										fetchlimitationSummary();
										
									}
								});

				workspaceFactory.checkFilters(workspaceFactory.activeScreen);
				
				function fetchlimitationSummary(){
					$scope.trees.loading_analysis = true;
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
					var params = {
						"action_code" : 'qg4nkb',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"filing_group" : filterParams.filing_group,
						"ho_leid_limitation" : filterParams.ho_leid_limitation
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.limitationSummary = [];
											vm.limitationSummary = data.jsonObject;
											if (null != vm.limitationSummary && vm.limitationSummary.length != 0) {
												vm.limitationSummaruGrpType = vm.limitationSummary[vm.limitationSummary.length - 1].GROUP_TYPE;
											} else {
												vm.limitationSummaruGrpType = null;
											}
											$scope.trees.loading_analysis = false;
											vm.showBackdrop_analysis = false;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});
				}

				function fetch_1231_gainloss_data_summary() {
					$scope.trees.loading = true;
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
					var params = {
						"action_code" : 'bcw60f',
						"sso_id" : USER_SETTINGS.user.sso_id,
						"tax_year" : filterParams.tax_year,
						"scenario" : filterParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"filing_key" : filterParams.filing_group,
						"ho_leid" : filterParams.ho_leid
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.gainloss1231_summary = [];
											vm.gainloss1231_summary = data.jsonObject;
											fetch_1231_gainloss_data();
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.irsformdata = false;
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;

				function fetch_1231_gainloss_data() {
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
					var params = {
						"action_code" : '0r3z9b',
						"sso_id" : USER_SETTINGS.user.sso_id,
						"tax_year" : filterParams.tax_year,
						"scenario" : filterParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"entity_type" : filterParams.entity_type,
						"filing_key" : filterParams.filing_group,
						"ho_leid" : filterParams.ho_leid
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.gainloss1231 = [];
											vm.gainloss1231 = data.jsonObject;
											vm.ovverrideamount1231gain1 = vm.gainloss1231[vm.gainloss1231.length - 1].LINE8_OVERRIDE_AMT;
											vm.ovverrideamount1231gain2 = vm.gainloss1231[vm.gainloss1231.length - 1].RECLASS_OVERRIDE_AMT;
											vm.gain1231comment1 = vm.gainloss1231[vm.gainloss1231.length - 1].LINE8_OVERRIDE_COMMENTS;
											vm.gain1231comment2 = vm.gainloss1231[vm.gainloss1231.length - 1].RECLASS_OVERRIDE_COMMENTS;
											vm.gain1231grp_type = vm.gainloss1231[vm.gainloss1231.length - 1].GROUP_TYPE;
											vm.showBackdrop = false;
											$scope.trees.loading = false;

										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;
				vm.save1231Gain = function() {

					if ($scope.crudLoading1231Gain) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoading1231Gain = true;
					save1231Gainobj = [];
					save1231Gainjsendobj = [];
					save1231Gainjsendobj = vm.gainloss1231;
					var message1 = "1231 Gain/Loss data has been saved";
					var dummyJson1231Gainobj = {};
					// clob,seetings
					dummyJson1231Gainobj["tax_year"] = GlobalService.globalParams.tax_year;
					dummyJson1231Gainobj["scenario"] = GlobalService.globalParams.scenario;
					dummyJson1231Gainobj["jcd_key"] = GlobalService.globalParams.jcd_key;
					dummyJson1231Gainobj["reclass_override_amt"] = vm.ovverrideamount1231gain2;
					dummyJson1231Gainobj["reclass_override_comments"] = vm.gain1231comment2;
					dummyJson1231Gainobj["line8_override_amt"] = vm.ovverrideamount1231gain1;
					dummyJson1231Gainobj["line8_override_comments"] = vm.gain1231comment1;
					save1231Gainobj.push(dummyJson1231Gainobj);
					gain1231Save(save1231Gainobj, save1231Gainjsendobj,
							message1);
				}

				function gain1231Save(returnObj, accountArray, message) {

					LimitObjFactory.save1231gain(returnObj, accountArray).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoading1231Gain = false;
									
								} else {
									AlertService.add("success", message, 4000);
									$scope.crudLoading1231Gain = false;
								}
							});

				}
				function fetch_charitable_data_summary() {
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
							$scope.trees.loading_charitable = true;
					var params = {
						"action_code" : 'em9o45',
						"sso_id" : USER_SETTINGS.user.sso_id,
						"tax_year" : filterParams.tax_year,
						"scenario" : filterParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"filing_key" : filterParams.filing_group,
						"ho_leid" : filterParams.ho_leid
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.charitablecontribution_summary = [];
											vm.charitablecontribution_summary = data.jsonObject;
											fetchcharitablecontribution();
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.irsformdata = false;
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;

				function fetchcharitablecontribution() {
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
							$scope.trees.loading_charitable = true;
					var params = {
						"action_code" : 'inj55i',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"entity_type" : filterParams.entity_type,
						"ho_leid" : filterParams.ho_leid,
						"filing_key" : filterParams.filing_group,

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.charitablecontribution = [];
											vm.charitablecontribution = data.jsonObject;
											vm.charitable_type = vm.charitablecontribution[vm.charitablecontribution.length - 1].GROUP_TYPE;
											vm.showBackdrop_charitable = false;
											$scope.trees.loading_charitable = false;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;
				// 3mcaxq
				function fetchGainLossanalysis() {
					$scope.trees.loading_analysis = true;
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
					var params = {
						"action_code" : '3mcaxq',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"filing_key" : filterParams.filing_group,
						"ho_leid" : filterParams.ho_leid
						
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.capitallossanalysis = [];
											vm.capitallossanalysis = data.jsonObject;
											vm.capitallossgrp_type = data.jsonObject[0].GROUP_TYPE;
											$scope.trees.loading_analysis = false;
											vm.showBackdrop_analysis = false;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;
				vm.savecapitalanalysis = function() {
					if ($scope.crudLoadingcapitalanalysis) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoadingcapitalanalysis = true;
					sendobj1 = [];
					jsendobj1 = [];
					sendobj1 = vm.capitallossanalysis;
					var message1 = "Capital Loss Analysis has been saved";
					var dummyJson1 = {};
					dummyJson1["tax_year"] = GlobalService.globalParams.tax_year;
					dummyJson1["scenario"] = GlobalService.globalParams.scenario;
					dummyJson1["jcd_key"] = GlobalService.globalParams.jcd_key;
					jsendobj1.push(dummyJson1);
					capitalgainlossSave(jsendobj1, sendobj1, message1);
				}

				function capitalgainlossSave(returnObj, accountArray, message) {

					LimitObjFactory
							.savecapitalgainloss(returnObj, accountArray)
							.then(
									function(result) {

										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoadingcapitalanalysis = false;
										} else {
											AlertService.add("success",
													message, 4000);
											$scope.crudLoadingcapitalanalysis = false;
										}
									});

				}
				
				function fetchDRDWorksheetSummary(){
					$scope.trees.loading_analysis = true;
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
					console.log("The filters in DRD Summary was :::::::", filterParams);
					var params = {
									"action_code" : 'yqpcnq',
									"tax_year" : GlobalService.globalParams.tax_year,
									"scenario" : GlobalService.globalParams.scenario,
									"jcd_key" : GlobalService.globalParams.jcd_key,
									"filing_group" : filterParams.filing_group,
									"ho_leid_limitation" : filterParams.ho_leid_limitation
								}
					
					return JsonObjectFactory
					.getJSONObj(
							GENERAL_CONFIG.base_url + '/loadJsonObject',
							params)
					.then(
							function(data) {

								if (data.callSuccess === "1") {
									vm.drdWorkSheet_summary = [];
									vm.drdWorkSheet_summary = data.jsonObject;
									fetchDRDWorksheet();
									$scope.trees.loading_analysis = false;
									vm.showBackdrop_analysis = false;
								} else {
									if (data.errorMessage === "no access") {
										AlertService
												.add(
														"",
														"Sorry you do not have access to do the requested action.",
														4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
										vm.irsformdata = false;
									} else {
										AlertService
												.add(
														"",
														"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
														4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									}
								}

							});
				}
				
				function fetchDRDWorksheet (){
					$scope.trees.loading_analysis = true;
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
							.getFilterParams()
							: {};
					console.log("The filters in DRD Details was :::::::", filterParams);
					var params = {
							"action_code" : 'fd9a4s',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"filing_group" : filterParams.filing_group,
							"ho_leid_limitation" : filterParams.ho_leid_limitation,
							"entity_type" : filterParams.entity_type
						}

						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url + '/loadJsonObject',
										params)
								.then(
										function(data) {

											if (data.callSuccess === "1") {
												vm.drdworksheetdata = [];
												vm.drdworksheetdata = data.jsonObject;
												console.log("Intial Data set for the Main Worksheet was", data.jsonObject);
												if(undefined != vm.drdworksheetdata[0].IS_CALC && null != vm.drdworksheetdata[0].IS_CALC){
													vm.is_calc = vm.drdworksheetdata[0].IS_CALC;
												}
												vm.wsHeader = vm.drdworksheetdata.shift();
												console.log("Data set for the DRD Header was", vm.wsHeader);
												vm.overrideamtRecord = vm.drdworksheetdata.pop();
												vm.DRDRecord = vm.drdworksheetdata.pop();
												console.log("Data set for the DRD Override record was", vm.overrideamtRecord);
												console.log("Data set for the DRD record was", vm.DRDRecord);
												$scope.trees.loading_analysis = false;
												vm.showBackdrop_analysis = false;
											} else {
												if (data.errorMessage === "no access") {
													AlertService
															.add(
																	"",
																	"Sorry you do not have access to do the requested action.",
																	4000);
													vm.userMessage = "!! Unable to perform Selected Action...";
													vm.irsformdata = false;
												} else {
													AlertService
															.add(
																	"",
																	"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																	4000);
													vm.userMessage = "!! Unable to perform Selected Action...";
												}
											}

										});
				}
				
				vm.updateAmount = function(line_no){
					console.log("We are updating the amounts for this line:::::: ", line_no);
					for (var i=0; i < vm.drdworksheetdata.length; i++){
						if(vm.drdworksheetdata[i].LINE_NO == line_no){
							vm.drdworksheetdata[i].IS_CHANGED_FLAG = 'Y';
						}
						if(vm.drdworksheetdata[i].ATTRIB_CALC_FORMULA != null){
							vm.drdworksheetdata[i].AMOUNT = vm.calcLineFormula(vm.drdworksheetdata[i].ATTRIB_CALC_FORMULA);
							vm.drdworksheetdata[i].IS_CHANGED_FLAG = 'Y';
						}
					}
					vm.overrideamtRecord.IS_CHANGED_FLAG = 'N';
					vm.overrideamtRecord.AMOUNT = 0;
					vm.overrideamtRecord.COMMENTS = null;
				}
				
				vm.calcLineFormula = function(formula){
					console.log("Given formula of the row are ", formula);
					if(formula.indexOf("Math.min") == -1){
						var lineFormulaArray = formula.split(/[+-/\\*\\]/);
						console.log("Given lineFormulaArray of the row are ", lineFormulaArray);
						for(var i=0; i<lineFormulaArray.length; i++){
							if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
								lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
								console.log("Given lineFormulaArray[i] of the row are ", lineFormulaArray[i]);
								console.log("Given vm.drdworksheetdata of the row are ", vm.drdworksheetdata);
								var currentTabAtrribVal = $filter("filter")(vm.drdworksheetdata, {ATTRIB_NAME:lineFormulaArray[i].trim()});
								console.log("Given currentTabAtrribVal of the row are ", currentTabAtrribVal);
								var lineAmt = parseInt(currentTabAtrribVal[0].AMOUNT);
								if(parseInt(lineAmt)> 0){
									formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
								}else{
									formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
								}
							}
						}
					}else{
						var Strlength = formula.length;
						var innerString = formula.substr(9, Strlength -1);
						console.log("Given Min formula inner String is ", innerString);
						var lineFormulaArray = innerString.split(',');
						console.log("Given lineFormulaArray of the row are ", lineFormulaArray);
						for(var i=0; i<lineFormulaArray.length; i++){
							if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
								lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
								console.log("Given lineFormulaArray[i] of the row are ", lineFormulaArray[i]);
								console.log("Given vm.drdworksheetdata of the row are ", vm.drdworksheetdata);
								var currentTabAtrribVal = $filter("filter")(vm.drdworksheetdata, {ATTRIB_NAME:lineFormulaArray[i].trim()});
								console.log("Given currentTabAtrribVal of the row are ", currentTabAtrribVal);
								var lineAmt = parseInt(currentTabAtrribVal[0].AMOUNT);
								if(parseInt(lineAmt)> 0){
									formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
								}else{
									formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
								}
							}
						}
					}
					
					console.log("Given formula of the row are ", formula);
					if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
						console.log(eval(formula));
						return eval(formula);
					}else return 0;
				}
				
				vm.override_amt_update = function(){
					vm.overrideamtRecord.IS_CHANGED_FLAG = 'Y';
					console.log("Data set for the Main Worksheet was", vm.drdworksheetdata);
				}
				
				vm.saveDRDWS = function (){
					for (var i=0; i < vm.drdworksheetdata.length; i++){
						vm.drdworksheetdata[i].IS_CALC = vm.is_calc;
					}
					if ($scope.crudLoadingDRDWS) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoadingDRDWS = true;
					sendobj = [];
					jsendobj = [];
					var dummyJson = {};
					dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
					dummyJson["scenario"] = GlobalService.globalParams.scenario;
					dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
					jsendobj.push(dummyJson);
					var message = "Charitable Contribution has been successfully Added";
					sendobj = angular.copy(vm.drdworksheetdata);
					sendobj.push(vm.DRDRecord);
					sendobj.push(vm.overrideamtRecord);
					
					saveDRDWorkSheet (jsendobj, sendobj, message);
					
				}
				
				function saveDRDWorkSheet (jsendobj, sendobj, message){
					console.log("Data set for the Main Worksheet was", sendobj);
					
					LimitObjFactory.saveDRDWS(jsendobj,
							sendobj).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoadingDRDWS = false;
								} else {
									AlertService.add("success", message, 4000);
									$scope.crudLoadingDRDWS = false;
								}
							});
				}

				vm.setSourceTotal = function(index) {
					vm.charitablecontribution[8].AMOUNT = (vm.charitablecontribution[6].AMOUNT * vm.charitablecontribution[7].AMOUNT) / 100;
					// Form 8916 Line 2d2 = Less - CY Deductions for Charitable
					// Contributions - TI for computation of limitation if > 0
					// show the amount otherwise 0
					var t = 0;
					t = (vm.charitablecontribution[1].AMOUNT - vm.charitablecontribution[8].AMOUNT);

					if (t < 0) {
						vm.charitablecontribution[9].AMOUNT = 0;
					} else {
						vm.charitablecontribution[9].AMOUNT = t;
					}
					// CY Carryforward amount = Less - CY Deductions for
					// Charitable Contributions - TI for computation of
					// limitation ;
					// Total Carryforward amount = CY Carryforward amount +PY
					// Carryforward amount
					vm.charitablecontribution[12].AMOUNT = vm.charitablecontribution[1].AMOUNT
							- vm.charitablecontribution[8].AMOUNT;
					vm.charitablecontribution[14].AMOUNT = vm.charitablecontribution[12].AMOUNT
							+ vm.charitablecontribution[13].AMOUNT;
				}

				vm.save = function() {
					if ($scope.crudcharitablecontribution) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudcharitablecontribution = true;
					sendobj = [];
					jsendobj = [];
					sendobj = vm.charitablecontribution;
					var dummyJson = {};
					dummyJson["tax_year"] = GlobalService.globalParams.tax_year;
					dummyJson["scenario"] = GlobalService.globalParams.scenario;
					dummyJson["jcd_key"] = GlobalService.globalParams.jcd_key;
					jsendobj.push(dummyJson);
					var message = "Charitable Contribution has been successfully Added";

					charitablecontributionSAVE(jsendobj, sendobj, message);
				}
				function charitablecontributionSAVE(returnObj, accountArray,
						message) {

					LimitObjFactory.saveCharitabaleContribution(returnObj,
							accountArray).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudcharitablecontribution = false;
								} else {
									AlertService.add("success", message, 4000);
									$scope.crudcharitablecontribution = false;
								}
							});

				}

			}

			return controllers;
		});