define([
    'angular'

], function () {
    'use strict';

    /* NOTE:  changed on 5/10/2019
	  Below two controllers functionalities are same, if we are using same modal in both places grid & workflow, we need to define the controllers like below
		Form4797Controller		: this controller is used on workflow Step
		Form4797ControllerModal	: this controller is used on Grid Modal
   */

    var controllers = angular.module('app.Form4797Ctrl', [])
  /*  .controller('Form4797Controller', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','Form4797Factory',
		'GENERAL_CONFIG', Form4797Controller])
      .controller('Form4797ControllerModal', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','Form4797Factory',
  		,'GENERAL_CONFIG', '$uibModalInstance', 'rowData', 'colData', 'gridData', Form4797ControllerModal]);*/

      .controller('Form4797Controller', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','$timeout',
		'Form4797Factory','GENERAL_CONFIG', Form4797Controller])
      .controller('Form4797ControllerModal', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','$timeout',
  		'Form4797Factory','GENERAL_CONFIG', '$uibModalInstance', 'rowData', 'colData', 'gridData', Form4797ControllerModal]);


  function Form4797Controller(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope, $scope,$http, CodeComboFactory,$timeout,Form4797Factory, GENERAL_CONFIG) {
	  Form4797ControllerModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope,$http, CodeComboFactory,$timeout,Form4797Factory, GENERAL_CONFIG, {}, {}, {}, {}, this);
	}

  function Form4797ControllerModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope,$http, CodeComboFactory,$timeout,Form4797Factory, GENERAL_CONFIG,
  		$uibModalInstance, rowData, colData, gridData,thisObj){

  		var vm = this||thisObj;
		vm.rowData = ( $scope.$parent.rowData == undefined ) ? rowData : $scope.$parent.rowData;
		var rowData = vm.rowData;

		if($uibModalInstance) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};
		} else {
			vm.modal_name = null;
		}
		vm.onTabChange = function(e) {
            vm.selectedTabName = e;
            $timeout(function() {
                var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            }, 50);
        };

        vm.jsonData = {};
        vm.form4797Data = [];
        vm.form4797Line2Records = [];
        vm.form4797Line10Records = []
        vm.form4797Line19Records = [];
        vm.form4797Line2RecordsOrig = [];
        vm.form4797Line10RecordsOrig = []
        vm.form4797Line19RecordsOrig = [];
        vm.form4797Amounts = [[],[],[]];
        vm.v_form_key = 1;
        vm.width = [];
        vm.temp02 = 0;
        vm.temp010 = 0;
        vm.temp019 = 0;
        vm.totalLine2Amt = 0;
        vm.totalLine2Amtintial = 0;
        vm.gainLossLine2 = 0;
        vm.totalLine10Amt = 0;
        vm.totalLine10Amtintial = 0;
        vm.gainLossLine10 = 0;
        vm.totalLine19Amt = 0;
        vm.totalLine19Amtintial = 0;
        vm.gainLossLine19 = 0;
        vm.line4Amt = 0;
        vm.line4AmtOrig = 0;
		vm.line5Amt = 0;
		vm.line5AmtOrig = 0;
		vm.line14Amt = 0;
		vm.line14AmtOrig = 0;
        vm.line15Amt = 0;
        vm.line15AmtOrig = 0;
        vm.line16Amt = 0;
        vm.line16AmtOrig = 0;
        vm.line2FormLineKey = 0;
        vm.line10FormLineKey = 0;
        vm.line19FormLineKey = 0;
        vm.temprow2_editdata = [];
        vm.temprow10_editdata = [];
        vm.temprow19_editdata = [];
        $scope.displayViewValue_2 = [];
        $scope.displayEditValue_2 = [];

        $scope.displayViewValue_10 = [];
        $scope.displayEditValue_10 = [];

        $scope.displayViewValue_19 = [];
        $scope.displayEditValue_19 = [];

        $scope.displayViewValueComment_2 = [];
        $scope.displayEditValueComment_2 = [];

        $scope.displayViewValueComment_10 = [];
        $scope.displayEditValueComment_10 = [];

        $scope.displayViewValueComment_19 = [];
        $scope.displayEditValueComment_19 = [];

        vm.dateOptions = {
                dateDisabled: false,
                formatYear: 'yyyy',
                startingDay: 1,
                showWeeks: false
        };

        vm.rowData= rowData;
        console.log("rowData:::::::::",vm.rowData);
        vm.modalTitle = "Form 4797: Sales of Business Property - Increase (Decrease) To Taxable Income";
        $scope.FormEntryData= {
            section1: {
            	singlerows:["Total Gain (Loss) per Tax Return", "0"],
                partITitle: "Part I: Sales or Exchanges of property Used in a Trade or Business and Involuntary Conversions From Other Than Casuality or Theft - Most Property Held More Than 1 Year",
                headers: ["4797 Line", "Description of Property", "Date Acquired", "Date Sold", "Gross Sales Price", "Depreciation Allowed", "Cost or Other Basis", "Gain or (Loss)", "Worthless Stock?", "Section 754?"],
                rows:[{
                    num: "4",
                    description: "Section 1231 gain from installment sales from Form 6252, line 26 or 37"
                }, {
                    num: "5",
                    description: "Section 1231 gain or (loss) from like-kind exchanges from Form 8824"
                }],
                emptyRows:[{}],
                totals: [{
                    description: 'Total Part I Gain or (Loss)'
                }]
            },
            section2: {
                partIITitle: "Part II: Ordinary Gains and Losses",
                rows: [{
                    num: "14",
                    description: "Net gain or (loss) from Form 4684, lines 31 and 38a"
                }, {
                    num: "15",
                    description: "Ordinary gain from installment sales from Form 6252, line 25 or 36"
                }, {
                    num: "16",
                    description: "Ordinary gain or (loss) from like-kind exchanges from Form 8824"
                }],
                emptyRows:[{}],
                totals: [{
                    description: "Total Part II Gain or (Loss)"
                }]
            },
            section3: {
                partIIITitle: "Part III: Gain From Disposition of Property Under Section 1245",
                emptyRows:[{}],
                totals: [{
                    description: "Total Part III Gain or (Loss)"
                }]
            },
            section4: {
                headers:["Description", "Gain or Loss"],
                rows: [{
                    description: "Total Worthless Stock Deduction - Ordinary per Tax Return"
                }, {
                    description: "Total Worthless Stock Deduction - Ordinary per Detail Lines"
                }, {
                    description: "Total Worthless Stock Deduction - Ordinary Unallocated"
                }, {
                    description: "Total Gain or (Loss) as per Form 4797"
                }, {
                    description: "Unallocated Amount"
                }],
            }
        }

        $scope.FormEntryData.section1.emptyRows.splice(0, 1);
        $scope.FormEntryData.section2.emptyRows.splice(0, 1);
        $scope.FormEntryData.section3.emptyRows.splice(0, 1);

        vm.formData_2 = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
        };

        vm.formData_10 = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
        };

        vm.formData_19 = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
        };

        vm.formData_2_errorMessages = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
        vm.formData_10_errorMessages = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
        vm.formData_19_errorMessages = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
        vm.formData_line4 = {
                "editrows"  : []
        };
        vm.formData_line5 = {
                "editrows"  : []
        };
        vm.formData_line14 = {
                "editrows"  : []
        };
        vm.formData_line15 = {
                "editrows"  : []
        };
        vm.formData_line16 = {
                "editrows"  : []
        };

        vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
        };

        //For Edit Data:
        vm.taxYear = parseInt(rowData.TAX_YEAR);
        vm.scenario = parseInt(rowData.SCENARIO);
        vm.jcd = 250//rowData.JCD_KEY;
        vm.combinationKey = parseInt(rowData.COMBINATION_KEY);
        vm.groupObjKey = parseInt(rowData.GROUP_OBJ_KEY);

        vm.colData_line4 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :4, "occurence" :1, "line_attrib_key" :4090, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData_line5 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :5, "occurence" :1, "line_attrib_key" :4094, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData_line14 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :14, "occurence" :1, "line_attrib_key" :4116, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData_line15 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :15, "occurence" :1, "line_attrib_key" :4120, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData_line16 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :16, "occurence" :1, "line_attrib_key" :4124, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.recordData_line4 = [vm.colData_line4];
        vm.recordData_line5 = [vm.colData_line5];
        vm.recordData_line14 = [vm.colData_line14];
        vm.recordData_line15 = [vm.colData_line15];
        vm.recordData_line16 = [vm.colData_line16];

        vm.colData1_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :1, "Attribute_Name": "PROPERTY_DESC", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData2_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :2, "Attribute_Name": "DATE_ACQ", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData3_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :3, "Attribute_Name": "DATE_SOLD", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData4_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :4, "Attribute_Name": "SALE_PRICE", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData5_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :5, "Attribute_Name": "DEPRECIATION_ALLOWED", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData6_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :6, "Attribute_Name": "COST_BASIS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData7_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :7, "Attribute_Name": "WORTHLESS_FLAG", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData8_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :8, "Attribute_Name": "IS_SEC_754", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData9_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :9, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData10_2 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :2, "occurence" :null, "line_attrib_key" :497, "Attribute_Name": "COMMENTS", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

        vm.recordData_2 = [vm.colData1_2, vm.colData2_2,vm.colData3_2, vm.colData4_2, vm.colData5_2, vm.colData6_2,vm.colData7_2, vm.colData8_2,vm.colData9_2,vm.colData10_2];

        vm.colData1_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :10, "Attribute_Name": "PROPERTY_DESC", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData2_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :11, "Attribute_Name": "DATE_ACQ", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData3_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :12, "Attribute_Name": "DATE_SOLD", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData4_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :13, "Attribute_Name": "SALE_PRICE", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData5_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :14, "Attribute_Name": "DEPRECIATION_ALLOWED", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData6_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :15, "Attribute_Name": "COST_BASIS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData7_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :16, "Attribute_Name": "WORTHLESS_FLAG", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData8_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :17, "Attribute_Name": "IS_SEC_754", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData9_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :18, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData10_10 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :10, "occurence" :null, "line_attrib_key" :498, "Attribute_Name": "COMMENTS", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

        vm.recordData_10 = [vm.colData1_10, vm.colData2_10,vm.colData3_10, vm.colData4_10,vm.colData5_10, vm.colData6_10,vm.colData7_10, vm.colData8_10,vm.colData9_10,vm.colData10_10];

        vm.colData1_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :22, "Attribute_Name": "PROPERTY_DESC", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData2_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :23, "Attribute_Name": "DATE_ACQ", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData3_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :24, "Attribute_Name": "DATE_SOLD", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData4_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :25, "Attribute_Name": "SALE_PRICE", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData5_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :26, "Attribute_Name": "DEPRECIATION_ALLOWED", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData6_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :27, "Attribute_Name": "COST_BASIS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData7_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :28, "Attribute_Name": "WORTHLESS_FLAG", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData8_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :29, "Attribute_Name": "IS_SEC_754", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData9_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :30, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData10_19 = {"tax_year":vm.taxYear, "trans_type_key" : 7, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :1, "line_no" :19, "occurence" :null, "line_attrib_key" :499, "Attribute_Name": "COMMENTS", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

        vm.recordData_19 = [vm.colData1_19, vm.colData2_19,vm.colData3_19, vm.colData4_19,vm.colData5_19, vm.colData6_19,vm.colData7_19, vm.colData8_19,vm.colData9_19,vm.colData10_19];
        vm.width = ["4%","15%","11%","11%","11%","11%","11%","8%","8%","10%"];

        var data = vm.rowData;
        function getForm4797Data() {
        	Form4797Factory.fetch4797Dtls(data).then(function (data){
        		console.log("Now ineer thing is executed and the return data is ::::.....", data);
        		findAdPopulateLineAttribKeys(vm.recordData_2,data,3);
                findAdPopulateLineAttribKeys(vm.recordData_10,data,8);
                findAdPopulateLineAttribKeys(vm.recordData_19,data,14);
                console.log('vm.recordData_2::',vm.recordData_2);
                console.log('vm.recordData_10::',vm.recordData_10);
                console.log('vm.recordData_19::',vm.recordData_19);
                findAdPopulateLineAttribKeys(vm.recordData_line4,data,4);
                findAdPopulateLineAttribKeys(vm.recordData_line5,data,5);
                findAdPopulateLineAttribKeys(vm.recordData_line14,data,9);
                findAdPopulateLineAttribKeys(vm.recordData_line15,data,10);
                findAdPopulateLineAttribKeys(vm.recordData_line16,data,11);
                console.log('vm.recordData_line4::',vm.recordData_line4);
                console.log('vm.recordData_line5::',vm.recordData_line5);
                console.log('vm.recordData_line14::',vm.recordData_line14);
                console.log('vm.recordData_line15::',vm.recordData_line15);
                console.log('vm.recordData_line16::',vm.recordData_line16);
        		if(data){
        			vm.jsonData = data;
        		}
        		console.log("The new JSON Object data is ::----------", vm.jsonData);
        		vm.form4797Data = vm.jsonData.irsForm.formItemList;

        		vm.line2HeadersAll = vm.form4797Data[3].dataRowList[0].dataCellList;
        		vm.line10HeadersAll = vm.form4797Data[8].dataRowList[0].dataCellList;
        		vm.line19HeadersAll = vm.form4797Data[14].dataRowList[0].dataCellList;
        		vm.clonedHeaders2 = angular.copy(vm.line2HeadersAll);
        		vm.clonedHeaders10 = angular.copy(vm.line10HeadersAll);
        		vm.clonedHeaders19 = angular.copy(vm.line19HeadersAll);
        		vm.line2Headers = vm.clonedHeaders2.splice(0, 10);
        		vm.line10Headers = vm.clonedHeaders10.splice(0, 10);
        		vm.line19Headers = vm.clonedHeaders19.splice(0, 10);
        		for(var i = 0; i<9; i++){
        			if(vm.line2Headers[i].property.width == null){
        				vm.line2Headers[i].property.width = vm.width[i];
        			}
        			if(vm.line10Headers[i].property.width == null){
        				vm.line10Headers[i].property.width = vm.width[i];
        			}
        			if(vm.line19Headers[i].property.width == null){
        				vm.line19Headers[i].property.width = vm.width[i];
        			}
        		}

        		var object_id_line2 = vm.form4797Data[3].dataRowList[0].rowDimensions.object_id;
                var l = object_id_line2.split("~");
                vm.line2FormLineKey = parseInt(l[2]);
                console.log('line2FormLineKey = ', vm.line2FormLineKey);

                var object_id_line10 = vm.form4797Data[8].dataRowList[0].rowDimensions.object_id;
                var l = object_id_line10.split("~");
                vm.line10FormLineKey = parseInt(l[2]);
                console.log('line10FormLineKey = ', vm.line10FormLineKey);

                var object_id_line19 = vm.form4797Data[14].dataRowList[0].rowDimensions.object_id;
                var l = object_id_line19.split("~");
                vm.line19FormLineKey = parseInt(l[2]);
                console.log('line19FormLineKey = ', vm.line19FormLineKey);

        		vm.Form4797Line2HeaderComment = data.irsForm.formItemList[3].dataRowList[0].dataCellList[10];
        		vm.Form4797Line10HeaderComment = data.irsForm.formItemList[8].dataRowList[0].dataCellList[10];
        		vm.Form4797Line19HeaderComment = data.irsForm.formItemList[14].dataRowList[0].dataCellList[10];
        		vm.form4797Line2RecordsSize = vm.form4797Data[3].dataRowList.length;
        		vm.form4797Line10RecordsSize = vm.form4797Data[8].dataRowList.length;
        		vm.form4797Line19RecordsSize = vm.form4797Data[14].dataRowList.length;

        		var obj_id_section_2 =  vm.form4797Data[3].dataRowList[0].rowDimensions.object_id;
        		var split_object2 = obj_id_section_2.split("~");
        		var occur2 = split_object2[4];
        		var endsWithZero_2 = false;
        		if(occur2 == 0){
        			endsWithZero_2 = true;
        		}else{
        			endsWithZero_2 = false;
        		}
        		var obj_id_section_10 =  vm.form4797Data[8].dataRowList[0].rowDimensions.object_id;
        		var split_object10 = obj_id_section_10.split("~");
        		var occur10 = split_object10[4];
        		var endsWithZero_10 = false;
        		if(occur10 == 0){
        			endsWithZero_10 = true;
        		}else{
        			endsWithZero_10 = false;
        		}
        		var obj_id_section_19 =  vm.form4797Data[14].dataRowList[0].rowDimensions.object_id;
        		var split_object19 = obj_id_section_19.split("~");
        		var occur19 = split_object19[4];
        		var endsWithZero_19 = false;
        		if(occur19 == 0){
        			endsWithZero_19 = true;
        		}else{
        			endsWithZero_19 = false;
        		}

        		if(vm.form4797Line2RecordsSize > 0 && !endsWithZero_2 ){
        			vm.form4797Line2Records = vm.form4797Data[3].dataRowList;
        			angular.forEach(vm.form4797Line2Records, function(value, key){
        				$scope.displayViewValue_2.push(true);
            			$scope.displayEditValue_2.push(false);
            			$scope.displayViewValueComment_2.push(true);
            			$scope.displayEditValueComment_2.push(false);
            			if(value.dataCellList[2].value != 'VARIOUS') {
                            value.dataCellList[2].value = new Date(value.dataCellList[2].value);
                        }
                        if(value.dataCellList[3].value != 'VARIOUS') {
                            value.dataCellList[3].value = new Date(value.dataCellList[3].value);
                        }
        				vm.gainLossLine2 = value.dataCellList[9].value;
        				vm.totalLine2Amt = vm.totalLine2Amt + parseInt(vm.gainLossLine2,0);

        				var clonedRecordData = angular.copy(vm.recordData_2);
        				var objId = value.rowDimensions.object_id;
        				var y = objId.split("~");
                        var occ = y[4];
                        var transDetailKey = y[3];
                        angular.forEach(clonedRecordData, function(value, key) {
                            value.trans_dtls_key = transDetailKey;
                            value.occurence = occ;
                        });
                        vm.formData_2.editrows.push(clonedRecordData);
        			})
        			vm.totalLine2Amtintial = vm.totalLine2Amt;
        			console.log('the toal amt for line 2 is ::::::::::-------->', vm.totalLine2Amt);
        			vm.form4797Line2RecordsOrig = angular.copy(vm.form4797Line2Records);
        		}

        		if(vm.form4797Line10RecordsSize > 0 && !endsWithZero_10 ){
        			vm.form4797Line10Records = vm.form4797Data[8].dataRowList;
        			angular.forEach(vm.form4797Line10Records, function(value, key){
            			$scope.displayViewValue_10.push(true);
            			$scope.displayEditValue_10.push(false);
            			$scope.displayViewValueComment_10.push(true);
            			$scope.displayEditValueComment_10.push(false);
            			if(value.dataCellList[2].value != 'VARIOUS') {
                            value.dataCellList[2].value = new Date(value.dataCellList[2].value);
                        }
                        if(value.dataCellList[3].value != 'VARIOUS') {
                            value.dataCellList[3].value = new Date(value.dataCellList[3].value);
                        }
        				vm.gainLossLine10 = value.dataCellList[9].value;
        				vm.totalLine10Amt = vm.totalLine10Amt + parseInt(vm.gainLossLine10,0);

        				var clonedRecordData = angular.copy(vm.recordData_10);
        				var objId = value.rowDimensions.object_id;
        				var y = objId.split("~");
                        var occ = y[4];
                        var transDetailKey = y[3];
                        angular.forEach(clonedRecordData, function(value, key) {
                            value.trans_dtls_key = transDetailKey;
                            value.occurence = occ;
                        });
                        vm.formData_10.editrows.push(clonedRecordData);
        			})
        			vm.totalLine10Amtintial = vm.totalLine10Amt;
        			console.log('the toal amt for line 10 is ::::::::::-------->', vm.totalLine10Amt);
        			vm.form4797Line10RecordsOrig = angular.copy(vm.form4797Line10Records);
        		}

        		if(vm.form4797Line19RecordsSize > 0 && !endsWithZero_19 ){
        			vm.form4797Line19Records = vm.form4797Data[14].dataRowList;
        			angular.forEach(vm.form4797Line19Records, function(value, key){
            			$scope.displayViewValue_19.push(true);
            			$scope.displayEditValue_19.push(false);
            			$scope.displayViewValueComment_19.push(true);
            			$scope.displayEditValueComment_19.push(false);
            			if(value.dataCellList[2].value != 'VARIOUS') {
                            value.dataCellList[2].value = new Date(value.dataCellList[2].value);
                        }
                        if(value.dataCellList[3].value != 'VARIOUS') {
                            value.dataCellList[3].value = new Date(value.dataCellList[3].value);
                        }
        				vm.gainLossLine19 = value.dataCellList[9].value;
        				vm.totalLine19Amt = vm.totalLine19Amt + parseInt(vm.gainLossLine19,0);

        				var clonedRecordData = angular.copy(vm.recordData_19);
        				var objId = value.rowDimensions.object_id;
        				var y = objId.split("~");
                        var occ = y[4];
                        var transDetailKey = y[3];
                        angular.forEach(clonedRecordData, function(value, key) {
                            value.trans_dtls_key = transDetailKey;
                            value.occurence = occ;
                        });
                        vm.formData_19.editrows.push(clonedRecordData);
        			})
        			vm.totalLine19Amtintial = vm.totalLine19Amt;
        			console.log('the toal amt for line 19 is ::::::::::-------->', vm.totalLine19Amt);
        			vm.form4797Line19RecordsOrig = angular.copy(vm.form4797Line19Records);
        		}

        		if(rowData.TB_AMT == undefined){
        			$scope.FormEntryData.section1.singlerows[1] = 0;
        		} else {
        			$scope.FormEntryData.section1.singlerows[1] = rowData.TB_AMT;
        		}


        		vm.line4Details = vm.form4797Data[4].dataRowList[0].dataCellList;
        		vm.line5Details = vm.form4797Data[5].dataRowList[0].dataCellList;
        		vm.line14Details = vm.form4797Data[9].dataRowList[0].dataCellList;
        		vm.line15Details = vm.form4797Data[10].dataRowList[0].dataCellList;
        		vm.line16Details = vm.form4797Data[11].dataRowList[0].dataCellList;

        		vm.line4Amt = vm.line4Details[2].value;
        		vm.line5Amt = vm.line5Details[2].value;
        		vm.line14Amt = vm.line14Details[2].value;
                vm.line15Amt = vm.line15Details[2].value;
                vm.line16Amt = vm.line16Details[2].value;

                if(vm.line4Amt == null){ vm.line4Amt = 0; }
                if(vm.line5Amt == null){ vm.line5Amt = 0; }
                if(vm.line14Amt == null){ vm.line14Amt = 0; }
                if(vm.line15Amt == null){ vm.line15Amt = 0; }
                if(vm.line16Amt == null){ vm.line16Amt = 0; }

                vm.line4Amt = parseInt(vm.line4Amt, 0);
                vm.line4AmtOrig = vm.line4Amt;

                vm.line5Amt = parseInt(vm.line5Amt, 0);
                vm.line5AmtOrig = vm.line5Amt;

                vm.line14Amt = parseInt(vm.line14Amt, 0);
                vm.line14AmtOrig = vm.line14Amt;

                vm.line15Amt = parseInt(vm.line15Amt, 0);
                vm.line15AmtOrig = vm.line15Amt;

                vm.line16Amt = parseInt(vm.line16Amt, 0);
                vm.line16AmtOrig = vm.line16Amt;

                vm.totalLine2Amt = vm.totalLine2Amt + vm.line4Amt + vm.line5Amt;
        		vm.totalLine10Amt = vm.totalLine10Amt + vm.line14Amt + vm.line15Amt + vm.line16Amt;
        		console.log(vm.totalLine2Amt, " and ", vm.totalLine10Amt);

                var object_id_4 = vm.form4797Data[4].dataRowList[0].rowDimensions.object_id;
                var row_4 = object_id_4.split("~");
                var transDetailKey_4 = row_4[3];
                vm.v_form_key = parseInt(row_4[5]);
                vm.recordData_line4[0].trans_dtls_key = transDetailKey_4;
                vm.formData_line4.editrows.push(vm.recordData_line4);

                var object_id_5 = vm.form4797Data[5].dataRowList[0].rowDimensions.object_id;
                var row_5 = object_id_5.split("~");
                var transDetailKey_5 = row_4[3];
                vm.recordData_line5[0].trans_dtls_key = transDetailKey_5;
                vm.formData_line5.editrows.push(vm.recordData_line5);

                var object_id_14 = vm.form4797Data[9].dataRowList[0].rowDimensions.object_id;
                var row_14 = object_id_14.split("~");
                var transDetailKey_14 = row_14[3];
                vm.recordData_line14[0].trans_dtls_key = transDetailKey_14;
                vm.formData_line14.editrows.push(vm.recordData_line14);

                var object_id_15 = vm.form4797Data[10].dataRowList[0].rowDimensions.object_id;
                var row_15 = object_id_15.split("~");
                var transDetailKey_15 = row_15[3];
                vm.recordData_line15[0].trans_dtls_key = transDetailKey_15;
                vm.formData_line15.editrows.push(vm.recordData_line15);

                var object_id_16 = vm.form4797Data[11].dataRowList[0].rowDimensions.object_id;
                var row_16 = object_id_16.split("~");
                var transDetailKey_16 = row_16[3];
                vm.recordData_line16[0].trans_dtls_key = transDetailKey_16;
                vm.formData_line16.editrows.push(vm.recordData_line16);

        		vm.totalPerTaxReturn = vm.form4797Data[16].dataRowList[0].dataCellList;
        		vm.totalPerDetailsLines = vm.form4797Data[17].dataRowList[0].dataCellList;
        		vm.totalPerUnallocated = vm.form4797Data[19].dataRowList[0].dataCellList;
        		vm.totalPerForm4797 = vm.form4797Data[20].dataRowList[0].dataCellList;
        		vm.totalUnallocatedAmt = vm.form4797Data[22].dataRowList[0].dataCellList;



        		vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
        		vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;



        		console.log('the toal amt for Unallocated AMT is ::::::::::-------->', vm.totalUnallocatedAmt[1].value);


        		vm.form4797Line2RecordsCopy = angular.copy(vm.form4797Line2Records);
        		vm.form4797Line10RecordsCopy = angular.copy(vm.form4797Line10Records);
        		vm.form4797Line19RecordsCopy = angular.copy(vm.form4797Line19Records);

        		//console.log('Line 4 data headers is :::-------------------->', vm.totalPerTaxReturn);
        		//console.log('Line 5 data headers is :::-------------------->', vm.totalPerDetailsLines);
        		//console.log('Line 14 data headers is :::-------------------->', vm.totalPerUnallocated);
        		//console.log('Line 15 data headers is :::-------------------->', vm.totalPerForm4797);
        		//console.log('Line 16 data headers is :::-------------------->', vm.totalUnallocatedAmt);
        	});
        }

        function findAdPopulateLineAttribKeys(staticData,serverData,rowNum){
       	 	var row1cellKey = [];
       	 	angular.forEach(serverData.irsForm.formItemList[rowNum].dataRowList[0].dataCellList, function(item1) {
       	 		angular.forEach(staticData, function(item2) {
       	 			row1cellKey = item1.key.split('~');
       	 			if(item2.Attribute_Name===row1cellKey[0]){
       	 				item2.line_attrib_key = parseInt(row1cellKey[1]);
       	 			}
       	 		});
       	 	});
        }

        getForm4797Data();


        vm.addSection = function(line_no){
        	console.log('Add a line to the passed line_no::::-------->>', line_no);
        	var clonedRcordData;

            if(line_no == 2) {
            	console.log('Add a row for Line 2........');
                $scope.FormEntryData.section1.emptyRows.push({});
                clonedRcordData = angular.copy(vm.recordData_2);
                vm.formData_2.addrows.push(clonedRcordData);
                console.log('formData_1 = ', vm.formData_2);
            }
            else if(line_no == 10) {
            	console.log('Add a row for Line 10........');
                $scope.FormEntryData.section2.emptyRows.push({});
                clonedRcordData = angular.copy(vm.recordData_10);
                vm.formData_10.addrows.push(clonedRcordData);
                console.log('formData = ', vm.formData_10);
            }
            else if(line_no == 19) {
            	console.log('Add a row for Line 19........');
                $scope.FormEntryData.section3.emptyRows.push({});
                clonedRcordData = angular.copy(vm.recordData_19);
                vm.formData_19.addrows.push(clonedRcordData);
                console.log('formData = ', vm.formData_19);
            }
        }

        vm.sectionRemove = function(line, index) {
            if(line == 2) {
                $scope.FormEntryData.section1.emptyRows.splice(index, 1);
                // TO DO
                var adjustAmount = vm.formData_2.addrows[index][8].Attribute_Value;
                vm.totalLine2Amt = vm.totalLine2Amt - adjustAmount;
                vm.totalPerForm4797[1].value = vm.totalPerForm4797[1].value - adjustAmount;
                vm.totalUnallocatedAmt[1].value = vm.totalUnallocatedAmt[1].value + adjustAmount;
                if (index in vm.form4797Amounts[0]) {
                    vm.form4797Amounts[0].splice(index, 1);
                }
                //vm.temp0 = vm.temp0 - adjustAmount;
                vm.formData_2.addrows.splice(index, 1);
            }
            else if(line == 10) {
                $scope.FormEntryData.section2.emptyRows.splice(index, 1);
                // TO DO
                var adjustAmount = vm.formData_10.addrows[index][8].Attribute_Value;
                vm.totalLine10Amt = vm.totalLine10Amt - adjustAmount;
                vm.totalPerForm4797[1].value = vm.totalPerForm4797[1].value - adjustAmount;
                vm.totalUnallocatedAmt[1].value = vm.totalUnallocatedAmt[1].value + adjustAmount;
                if (index in vm.form4797Amounts[1]) {
                    vm.form4797Amounts[1].splice(index, 1);
                }
                //vm.temp0 = vm.temp0 - adjustAmount;
                vm.formData_10.addrows.splice(index, 1);
            }
            else if(line == 19) {
                $scope.FormEntryData.section3.emptyRows.splice(index, 1);
                // TO DO
                var adjustAmount = vm.formData_19.addrows[index][8].Attribute_Value;
                vm.totalLine19Amt = vm.totalLine19Amt - adjustAmount;
                vm.totalPerForm4797[1].value = vm.totalPerForm4797[1].value - adjustAmount;
                vm.totalUnallocatedAmt[1].value = vm.totalUnallocatedAmt[1].value + adjustAmount;
                if (index in vm.form4797Amounts[2]) {
                    vm.form4797Amounts[2].splice(index, 1);
                }
                //vm.temp0 = vm.temp0 - adjustAmount;
                vm.formData_19.addrows.splice(index, 1);
            }
        }

        vm.lineItemChange = function(line_no){
        	console.log('The line that was changed is ::::---->', line_no);
        	if(line_no == 4){
        		vm.formData_line4.editrows[0][0].Attribute_Value = vm.line4Amt;
    			vm.formData_line4.editrows[0][0].is_dirty='Y';
    			console.log('--- line4Change() attribute value = ', vm.formData_line4.editrows[0][0].Attribute_Value);
        	}
        	if(line_no == 5){
        		vm.formData_line5.editrows[0][0].Attribute_Value = vm.line5Amt;
    			vm.formData_line5.editrows[0][0].is_dirty='Y';
    			console.log('--- line4Change() attribute value = ', vm.formData_line5.editrows[0][0].Attribute_Value);
        	}
        	if(line_no == 14){
        		vm.formData_line14.editrows[0][0].Attribute_Value = vm.line14Amt;
    			vm.formData_line14.editrows[0][0].is_dirty='Y';
    			console.log('--- line4Change() attribute value = ', vm.formData_line14.editrows[0][0].Attribute_Value);
        	}
        	if(line_no == 15){
        		vm.formData_line15.editrows[0][0].Attribute_Value = vm.line15Amt;
    			vm.formData_line15.editrows[0][0].is_dirty='Y';
    			console.log('--- line4Change() attribute value = ', vm.formData_line15.editrows[0][0].Attribute_Value);
        	}
        	if(line_no == 16){
        		vm.formData_line16.editrows[0][0].Attribute_Value = vm.line16Amt;
    			vm.formData_line16.editrows[0][0].is_dirty='Y';
    			console.log('--- line4Change() attribute value = ', vm.formData_line16.editrows[0][0].Attribute_Value);
        	}
        }

        vm.errorChange = function(line, rowNum, colNum){
            if(line == 2 && null!= vm.formData_2_errorMessages.addrows && vm.formData_2_errorMessages.addrows.length >0 ){
                vm.formData_2_errorMessages.addrows[rowNum][colNum] = null;
            }
            if(line == 10 && null!= vm.formData_10_errorMessages.addrows && vm.formData_10_errorMessages.addrows.length >0 ){
                vm.formData_10_errorMessages.addrows[rowNum][colNum] = null;
            }
            if(line == 19 && null!= vm.formData_19_errorMessages.addrows && vm.formData_19_errorMessages.addrows.length >0 ){
                vm.formData_19_errorMessages.addrows[rowNum][colNum] = null;
            }
        }

        vm.propChange = function(line, colNum, index) {

        	if(null!= vm.temprow2_editdata && vm.temprow2_editdata.length >0){
                vm.formData_2.editrows = vm.temprow2_editdata;
            }
            if(null!= vm.temprow10_editdata && vm.temprow10_editdata.length >0){
                vm.formData_10.editrows = vm.temprow10_editdata;
            }
            if(null!= vm.temprow19_editdata && vm.temprow19_editdata.length >0){
                vm.formData_19.editrows = vm.temprow19_editdata;
            }
        	if(line == 2) {

        		if(null != vm.form4797Line2Records[index].dataCellList[colNum+1].error_message && vm.form4797Line2Records[index].dataCellList[colNum+1].error_message.length > 0){
                    vm.form4797Line2Records[index].dataCellList[colNum+1].error_message = null;
                    console.log('This was changed , please verify');
                }

                if(colNum == 1 || colNum == 2) {
                    console.log('--- prop2Change() called for line, column & index = ', line, colNum, index);
                    if(vm.form4797Line2Records[index].dataCellList[colNum+1].value === 'VARIOUS')
                        vm.formData_2.editrows[index][colNum].Attribute_Value = vm.form4797Line2Records[index].dataCellList[colNum+1].value;
                    else {
                        var date = vm.form4797Line2Records[index].dataCellList[colNum+1].value  ;
                        vm.formData_2.editrows[index][colNum].Attribute_Value = vm.convertDate(date);
                    }
                    vm.formData_2.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop2Change() attribute value = ', vm.formData_2.editrows[index][colNum].Attribute_Value);
                }
                else {
                    console.log('--- prop1Change() called for line, column & index = ', line, colNum, index);
                    vm.formData_2.editrows[index][colNum].Attribute_Value = vm.form4797Line2Records[index].dataCellList[colNum+1].value;
                    vm.formData_2.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop1Change() attribute value = ', vm.formData_2.editrows[index][colNum].Attribute_Value);
                }
            }
        	if(line == 10) {

        		if(null != vm.form4797Line10Records[index].dataCellList[colNum+1].error_message && vm.form4797Line10Records[index].dataCellList[colNum+1].error_message.length > 0){
                    vm.form4797Line10Records[index].dataCellList[colNum+1].error_message = null;
                }

                if(colNum == 1 || colNum == 2) {
                    console.log('--- prop2Change() called for line, column & index = ', line, colNum, index);
                    if(vm.form4797Line10Records[index].dataCellList[colNum+1].value === 'VARIOUS')
                        vm.formData_10.editrows[index][colNum].Attribute_Value = vm.form4797Line10Records[index].dataCellList[colNum+1].value;
                    else {
                        var date = vm.form4797Line10Records[index].dataCellList[colNum+1].value  ;
                        vm.formData_10.editrows[index][colNum].Attribute_Value = vm.convertDate(date);
                    }
                    vm.formData_10.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop2Change() attribute value = ', vm.formData_10.editrows[index][colNum].Attribute_Value);
                }
                else {
                    console.log('--- prop1Change() called for line, column & index = ', line, colNum, index);
                    vm.formData_10.editrows[index][colNum].Attribute_Value = vm.form4797Line10Records[index].dataCellList[colNum+1].value;
                    vm.formData_10.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop1Change() attribute value = ', vm.formData_10.editrows[index][colNum].Attribute_Value);
                }
            }
        	if(line == 19) {

        		if(null != vm.form4797Line19Records[index].dataCellList[colNum+1].error_message && vm.form4797Line19Records[index].dataCellList[colNum+1].error_message.length > 0){
                    vm.form4797Line19Records[index].dataCellList[colNum+1].error_message = null;
                }

                if(colNum == 1 || colNum == 2) {
                    console.log('--- prop2Change() called for line, column & index = ', line, colNum, index);
                    if(vm.form4797Line19Records[index].dataCellList[colNum+1].value === 'VARIOUS')
                        vm.formData_19.editrows[index][colNum].Attribute_Value = vm.form4797Line19Records[index].dataCellList[colNum+1].value;
                    else {
                        var date = vm.form4797Line19Records[index].dataCellList[colNum+1].value  ;
                        vm.formData_19.editrows[index][colNum].Attribute_Value = vm.convertDate(date);
                    }
                    vm.formData_19.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop2Change() attribute value = ', vm.formData_19.editrows[index][colNum].Attribute_Value);
                }
                else {
                    console.log('--- prop1Change() called for line, column & index = ', line, colNum, index);
                    vm.formData_19.editrows[index][colNum].Attribute_Value = vm.form4797Line19Records[index].dataCellList[colNum+1].value;
                    vm.formData_19.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop1Change() attribute value = ', vm.formData_19.editrows[index][colNum].Attribute_Value);
                }
            }
        }


        vm.editExistingRecord = function(line, index, object_id) {
            console.log(' editExistingRecord called for line & index ', line, index, object_id);

            if(line == 2) {

                $scope.displayViewValue_2[index] = !$scope.displayViewValue_2[index];
                $scope.displayEditValue_2[index] = !$scope.displayEditValue_2[index];
                $scope.displayViewValueComment_2[index] = !$scope.displayViewValueComment_2[index];
                $scope.displayEditValueComment_2[index] = !$scope.displayEditValueComment_2[index];
                if($scope.displayViewValue_2[index] == false && $scope.displayEditValue_2[index] == true) {
                	console.log('original values for row ' , index , ' = ', vm.form4797Line2RecordsCopy[index].dataCellList);

                    vm.formData_2.editrows[index][0].Attribute_Value = vm.form4797Line2Records[index].dataCellList[1].value;
                    if(vm.form4797Line2Records[index].dataCellList[2].value === 'VARIOUS')
                        vm.formData_2.editrows[index][1].Attribute_Value = vm.form4797Line2Records[index].dataCellList[2].value;
                    else {
                        var date = vm.form4797Line2Records[index].dataCellList[2].value  ;
                        vm.formData_2.editrows[index][1].Attribute_Value = vm.convertDate(date);
                    }

                    if(vm.form4797Line2Records[index].dataCellList[3].value === 'VARIOUS')
                        vm.formData_2.editrows[index][2].Attribute_Value = vm.form4797Line2Records[index].dataCellList[3].value;
                    else {
                        var date = vm.form4797Line2Records[index].dataCellList[3].value  ;
                        vm.formData_2.editrows[index][2].Attribute_Value = vm.convertDate(date);
                    }

                    vm.formData_2.editrows[index][3].Attribute_Value = vm.form4797Line2Records[index].dataCellList[4].value;
                    vm.formData_2.editrows[index][4].Attribute_Value = vm.form4797Line2Records[index].dataCellList[5].value;
                    vm.formData_2.editrows[index][5].Attribute_Value = vm.form4797Line2Records[index].dataCellList[6].value;
                    vm.formData_2.editrows[index][6].Attribute_Value = vm.form4797Line2Records[index].dataCellList[7].value;
                    vm.formData_2.editrows[index][7].Attribute_Value = vm.form4797Line2Records[index].dataCellList[8].value;
                    vm.formData_2.editrows[index][8].Attribute_Value = vm.form4797Line2Records[index].dataCellList[9].value;
                    vm.formData_2.editrows[index][9].Attribute_Value = vm.form4797Line2Records[index].dataCellList[10].value;
                    console.log('The value for Sales when editted is::::::::::: ----------->', vm.form4797Line2Records[index].dataCellList[4].value);
                }

                if($scope.displayViewValue_2[index] == true && $scope.displayEditValue_2[index] == false) {

                    for (var i = 0; i <= 10; i++) {
                        vm.formData_2.editrows[index][i].is_dirty='N';
                    }

                    for (var i = 0; i <= 10; i++) {
                        vm.form4797Line2Records[index].dataCellList[i+1].value = vm.form4797Line2RecordsCopy[index].dataCellList[i+1].value;
                    }
                    vm.totalLine2Amt = vm.totalLine2Amt - vm.form4797Line2RecordsCopy[index].dataCellList[9].value + vm.formData_2.editrows[index][8].Attribute_Value;
                    vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
            		vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;


                    //vm.temp0E = vm.temp0E - (vm.formData_2.editrows[index][8].Attribute_Value - vm.form4797Line2RecordsCopy[index].dataCellList[9].value );
                    //vm.schdDSum = vm.schdDSumInitialValue + vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E;
                    //vm.unallocatedAmount = vm.unallocatedAmountF - (vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E );
                }

            }

            else if(line == 10) {

            	 $scope.displayViewValue_10[index] = !$scope.displayViewValue_10[index];
                 $scope.displayEditValue_10[index] = !$scope.displayEditValue_10[index];
                 $scope.displayViewValueComment_10[index] = !$scope.displayViewValueComment_10[index];
                 $scope.displayEditValueComment_10[index] = !$scope.displayEditValueComment_10[index];
                 if($scope.displayViewValue_10[index] == false && $scope.displayEditValue_10[index] == true) {
                     console.log('original values for row ' , index , ' = ', vm.form4797Line10RecordsCopy[index].dataCellList);

                     vm.formData_10.editrows[index][0].Attribute_Value = vm.form4797Line10Records[index].dataCellList[1].value;

                     if(vm.form4797Line10Records[index].dataCellList[2].value === 'VARIOUS')
                         vm.formData_10.editrows[index][1].Attribute_Value = vm.form4797Line10Records[index].dataCellList[2].value;
                     else {
                    	 var date = vm.form4797Line10Records[index].dataCellList[2].value  ;
                         vm.formData_10.editrows[index][1].Attribute_Value = vm.convertDate(date);
                     }

                     if(vm.form4797Line10Records[index].dataCellList[3].value === 'VARIOUS')
                         vm.formData_10.editrows[index][2].Attribute_Value = vm.form4797Line10Records[index].dataCellList[3].value;
                     else {
                    	 var date = vm.form4797Line10Records[index].dataCellList[3].value  ;
                         vm.formData_10.editrows[index][2].Attribute_Value = vm.convertDate(date);
                     }

                     vm.formData_10.editrows[index][3].Attribute_Value = vm.form4797Line10Records[index].dataCellList[4].value;
                     vm.formData_10.editrows[index][4].Attribute_Value = vm.form4797Line10Records[index].dataCellList[5].value;
                     vm.formData_10.editrows[index][5].Attribute_Value = vm.form4797Line10Records[index].dataCellList[6].value;
                     vm.formData_10.editrows[index][6].Attribute_Value = vm.form4797Line10Records[index].dataCellList[7].value;
                     vm.formData_10.editrows[index][7].Attribute_Value = vm.form4797Line10Records[index].dataCellList[8].value;
                     vm.formData_10.editrows[index][8].Attribute_Value = vm.form4797Line10Records[index].dataCellList[9].value;
                     vm.formData_10.editrows[index][9].Attribute_Value = vm.form4797Line10Records[index].dataCellList[10].value;
                 }

                 if($scope.displayViewValue_10[index] == true && $scope.displayEditValue_10[index] == false) {

                     for (var i = 0; i <= 10; i++) {
                         vm.formData_10.editrows[index][i].is_dirty='N';
                     }

                     for (var i = 0; i <= 10; i++) {
                         vm.form4797Line10Records[index].dataCellList[i+1].value = vm.form4797Line10RecordsCopy[index].dataCellList[i+1].value;
                     }
                     vm.totalLine10Amt = vm.totalLine10Amt - vm.form4797Line10RecordsCopy[index].dataCellList[9].value + vm.formData_10.editrows[index][8].Attribute_Value;
                     vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
             		 vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;


                     //vm.temp0E = vm.temp0E - (vm.formData_2.editrows[index][8].Attribute_Value - vm.form4797Line2RecordsCopy[index].dataCellList[9].value );
                     //vm.schdDSum = vm.schdDSumInitialValue + vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E;
                     //vm.unallocatedAmount = vm.unallocatedAmountF - (vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E );
                 }
            }
              else if(line == 19) {

                	 $scope.displayViewValue_19[index] = !$scope.displayViewValue_19[index];
                     $scope.displayEditValue_19[index] = !$scope.displayEditValue_19[index];
                     $scope.displayViewValueComment_19[index] = !$scope.displayViewValueComment_19[index];
                     $scope.displayEditValueComment_19[index] = !$scope.displayEditValueComment_19[index];
                     if($scope.displayViewValue_19[index] == false && $scope.displayEditValue_19[index] == true) {
                         console.log('original values for row ' , index , ' = ', vm.form4797Line19RecordsCopy[index].dataCellList);

                         vm.formData_19.editrows[index][0].Attribute_Value = vm.form4797Line19Records[index].dataCellList[1].value;
                         if(vm.form4797Line19Records[index].dataCellList[2].value === 'VARIOUS')
                             vm.formData_19.editrows[index][1].Attribute_Value = vm.form4797Line19Records[index].dataCellList[2].value;
                         else {
                             var date = vm.form4797Line19Records[index].dataCellList[2].value  ;
                             vm.formData_19.editrows[index][1].Attribute_Value = vm.convertDate(date);
                         }

                         if(vm.form4797Line19Records[index].dataCellList[3].value === 'VARIOUS')
                             vm.formData_19.editrows[index][2].Attribute_Value = vm.form4797Line19Records[index].dataCellList[3].value;
                         else {
                             var date = vm.form4797Line19Records[index].dataCellList[3].value  ;
                             vm.formData_19.editrows[index][2].Attribute_Value = vm.convertDate(date);
                         }
                         vm.formData_19.editrows[index][3].Attribute_Value = vm.form4797Line19Records[index].dataCellList[4].value;
                         vm.formData_19.editrows[index][4].Attribute_Value = vm.form4797Line19Records[index].dataCellList[5].value;
                         vm.formData_19.editrows[index][5].Attribute_Value = vm.form4797Line19Records[index].dataCellList[6].value;
                         vm.formData_19.editrows[index][6].Attribute_Value = vm.form4797Line19Records[index].dataCellList[7].value;
                         vm.formData_19.editrows[index][7].Attribute_Value = vm.form4797Line19Records[index].dataCellList[8].value;
                         vm.formData_19.editrows[index][8].Attribute_Value = vm.form4797Line19Records[index].dataCellList[9].value;
                         vm.formData_19.editrows[index][9].Attribute_Value = vm.form4797Line19Records[index].dataCellList[10].value;
                     }

                     if($scope.displayViewValue_19[index] == true && $scope.displayEditValue_19[index] == false) {

                         for (var i = 0; i <= 10; i++) {
                             vm.formData_19.editrows[index][i].is_dirty='N';
                         }

                         for (var i = 0; i <= 10; i++) {
                             vm.form4797Line19Records[index].dataCellList[i+1].value = vm.form4797Line19RecordsCopy[index].dataCellList[i+1].value;
                         }
                         vm.totalLine19Amt = vm.totalLine19Amt - vm.form4797Line19RecordsCopy[index].dataCellList[9].value + vm.formData_19.editrows[index][8].Attribute_Value;
                         vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
                 		 vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;


                         //vm.temp0E = vm.temp0E - (vm.formData_2.editrows[index][8].Attribute_Value - vm.form4797Line2RecordsCopy[index].dataCellList[9].value );
                         //vm.schdDSum = vm.schdDSumInitialValue + vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E;
                         //vm.unallocatedAmount = vm.unallocatedAmountF - (vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E );
                     }

            }

        }

        vm.adjustGainLoss = function(line_no) {
        	console.log("The amount for line item is changed and the line no is:---", line_no);
        	if(line_no == 4 || line_no == 5){
        		vm.totalLine2Amt = vm.totalLine2Amt + (vm.line4Amt - vm.line4AmtOrig) + (vm.line5Amt - vm.line5AmtOrig);
        		vm.totalPerForm4797[1].value = vm.totalPerForm4797[1].value + (vm.line4Amt - vm.line4AmtOrig) + (vm.line5Amt - vm.line5AmtOrig);
        		vm.line4AmtOrig = vm.line4Amt;
            	vm.line5AmtOrig = vm.line5Amt;
        	}
        	if(line_no == 14 || line_no == 15 || line_no == 16){
        		vm.totalLine10Amt = vm.totalLine10Amt + (vm.line14Amt - vm.line14AmtOrig) + (vm.line15Amt - vm.line15AmtOrig) + (vm.line16Amt - vm.line16AmtOrig);
        		vm.totalPerForm4797[1].value = vm.totalPerForm4797[1].value + (vm.line14Amt - vm.line14AmtOrig) + (vm.line15Amt - vm.line15AmtOrig) + (vm.line16Amt - vm.line16AmtOrig);
        		vm.line14AmtOrig = vm.line14Amt;
            	vm.line15AmtOrig = vm.line15Amt;
            	vm.line16AmtOrig = vm.line16Amt;
        	}
        	vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;
        }

        $scope.getTransDetlsKey = function(object_id){
        	var x = object_id.split("~");
        	return x[3];
        }

        $scope.setGainLoss = function (line, edit, index) {
        	var temp02 = 0;
        	var temp12 = 0;
        	var temp010 = 0;
        	var temp110 = 0;
        	var temp019 = 0;
        	var temp119 = 0;
        	var temp0E = 0;
        	var temp2E = 0;
        	var temp10E = 0;
        	var temp19E = 0;
        	var beforeEdit = 0;
        	var salePriceLine2 = 0;
            var depreciationLine2 = 0;
            var costPriceLine2 = 0;
            var gainLossPerLine2 = 0;
            var salePriceLine10 = 0;
            var depreciationLine10 = 0;
            var costPriceLine10 = 0;
            var gainLossPerLine10 = 0;
            var salePriceLine19 = 0;
            var depreciationLine19 = 0;
            var costPriceLine19 = 0;
            var gainLossPerLine19 = 0;
            vm.temp2E = 0;
            vm.temp10E = 0;
            vm.temp19E = 0;

        	if(line == 2){
        		if(edit == false){
        			salePriceLine2 = vm.formData_2.addrows[index][3].Attribute_Value;
        			depreciationLine2 = vm.formData_2.addrows[index][4].Attribute_Value;
        			costPriceLine2 = vm.formData_2.addrows[index][5].Attribute_Value;
        			if(salePriceLine2 == undefined) {salePriceLine2 = 0;}
                    if(depreciationLine2 == undefined) {depreciationLine2 = 0;}
                    if(costPriceLine2 == undefined) {costPriceLine2 = 0;}
        			gainLossPerLine2 = parseInt(salePriceLine2) + parseInt(depreciationLine2) - parseInt(costPriceLine2);
        			vm.formData_2.addrows[index][8].Attribute_Value = gainLossPerLine2;
        			if (index in vm.form4797Amounts[0]) {
        				vm.form4797Amounts[0].splice(index, 1);
                    }
        			vm.form4797Amounts[0].splice(index, 0, gainLossPerLine2);
        			angular.forEach(vm.form4797Amounts[0], function(value, key){
        				temp02 = temp02 + parseInt(value);
                    });

        			vm.temp02 = temp02;
        			//vm.totalLine2Amt = vm.totalLine2Amt + vm.formData_2.addrows[index][8].Attribute_Value;
        			console.log('The gain/Loss value is :::::::: ------>', gainLossPerLine2);
        		}
        		if(edit == true){
        			console.log('The value of index[3] is ::::---->>>>', vm.formData_2.editrows[index][3].Attribute_Value);
        			salePriceLine2 = vm.formData_2.editrows[index][3].Attribute_Value;
        			depreciationLine2 = vm.formData_2.editrows[index][4].Attribute_Value;
        			costPriceLine2 = vm.formData_2.editrows[index][5].Attribute_Value;
        			if(salePriceLine2 == undefined) {salePriceLine2 = 0;}
                    if(depreciationLine2 == undefined) {depreciationLine2 = 0;}
                    if(costPriceLine2 == undefined) {costPriceLine2 = 0;}
        			gainLossPerLine2 = parseInt(salePriceLine2) + parseInt(depreciationLine2) - parseInt(costPriceLine2);
        			beforeEdit =   vm.form4797Line2RecordsOrig[index].dataCellList[9].value;
                    vm.formData_2.editrows[index][8].Attribute_Value = gainLossPerLine2;
                    vm.form4797Line2Records[index].dataCellList[9].value = gainLossPerLine2;
                    console.log('The gain/Loss value  for Editted line is :::::::: ------>', vm.form4797Line2Records[index].dataCellList[9].value);
                    temp0E = gainLossPerLine2 - beforeEdit;

                    vm.temp2E = vm.temp2E + temp0E ;
        		}
        	}
        	else if(line == 10){
        		if(edit == false){
        			salePriceLine10 = vm.formData_10.addrows[index][3].Attribute_Value;
        			depreciationLine10 = vm.formData_10.addrows[index][4].Attribute_Value;
        			costPriceLine10 = vm.formData_10.addrows[index][5].Attribute_Value;
        			if(salePriceLine10 == undefined) {salePriceLine10 = 0;}
                    if(depreciationLine10 == undefined) {depreciationLine10 = 0;}
                    if(costPriceLine10 == undefined) {costPriceLine10 = 0;}
        			gainLossPerLine10 = parseInt(salePriceLine10) + parseInt(depreciationLine10) - parseInt(costPriceLine10);
        			vm.formData_10.addrows[index][8].Attribute_Value = gainLossPerLine10;
        			if (index in vm.form4797Amounts[1]) {
        				vm.form4797Amounts[1].splice(index, 1);
                    }
        			vm.form4797Amounts[1].splice(index, 0, gainLossPerLine10);
        			angular.forEach(vm.form4797Amounts[1], function(value, key){
        				temp010 = temp010 + parseInt(value);
                    });

        			vm.temp010 = temp010;
        			//vm.totalLine10Amt = vm.totalLine10Amt + vm.formData_10.addrows[index][8].Attribute_Value;
        		}
        		if(edit == true){
        			console.log('The value of index[3] is ::::---->>>>', vm.formData_10.editrows[index][3].Attribute_Value);
        			salePriceLine10 = vm.formData_10.editrows[index][3].Attribute_Value;
        			depreciationLine10 = vm.formData_10.editrows[index][4].Attribute_Value;
        			costPriceLine10 = vm.formData_10.editrows[index][5].Attribute_Value;
        			if(salePriceLine10 == undefined) {salePriceLine10 = 0;}
                    if(depreciationLine10 == undefined) {depreciationLine10 = 0;}
                    if(costPriceLine10 == undefined) {costPriceLine10 = 0;}
        			gainLossPerLine10 = parseInt(salePriceLine10) + parseInt(depreciationLine10) - parseInt(costPriceLine10);
        			beforeEdit =   vm.form4797Line10RecordsOrig[index].dataCellList[9].value;
                    vm.formData_10.editrows[index][8].Attribute_Value = gainLossPerLine10;
                    vm.form4797Line10Records[index].dataCellList[9].value = gainLossPerLine10;
                    console.log('The gain/Loss value  for Editted line is :::::::: ------>', vm.form4797Line10Records[index].dataCellList[9].value);
                    temp0E = gainLossPerLine10 - beforeEdit;

                    vm.temp10E = vm.temp10E + temp0E ;
        		}
        	}
        	else if(line == 19){
        		if(edit == false){
        			salePriceLine19 = vm.formData_19.addrows[index][3].Attribute_Value;
        			depreciationLine19 = vm.formData_19.addrows[index][4].Attribute_Value;
        			costPriceLine19 = vm.formData_19.addrows[index][5].Attribute_Value;
        			if(salePriceLine19 == undefined) {salePriceLine19 = 0;}
                    if(depreciationLine19 == undefined) {depreciationLine19 = 0;}
                    if(costPriceLine19 == undefined) {costPriceLine19 = 0;}
        			gainLossPerLine19 = parseInt(salePriceLine19) + parseInt(depreciationLine19) - parseInt(costPriceLine19);
        			vm.formData_19.addrows[index][8].Attribute_Value = gainLossPerLine19;
        			if (index in vm.form4797Amounts[2]) {
        				vm.form4797Amounts[2].splice(index, 1);
                    }
        			vm.form4797Amounts[2].splice(index, 0, gainLossPerLine19);
        			angular.forEach(vm.form4797Amounts[2], function(value, key){
        				temp019 = temp019 + parseInt(value);
                    });

        			vm.temp019 = temp019;
        			console.log('The gain/Loss value is :::::::: ------>', gainLossPerLine19);
        			//vm.totalLine19Amt = vm.totalLine19Amt + vm.formData_19.addrows[index][8].Attribute_Value;
        		}
        		if(edit == true){
        			console.log('The value of index[3] is ::::---->>>>', vm.formData_19.editrows[index][3].Attribute_Value);
        			salePriceLine19 = vm.formData_19.editrows[index][3].Attribute_Value;
        			depreciationLine19 = vm.formData_19.editrows[index][4].Attribute_Value;
        			costPriceLine19 = vm.formData_19.editrows[index][5].Attribute_Value;
        			if(salePriceLine19 == undefined) {salePriceLine19 = 0;}
                    if(depreciationLine19 == undefined) {depreciationLine19 = 0;}
                    if(costPriceLine19 == undefined) {costPriceLine19 = 0;}
        			gainLossPerLine19 = parseInt(salePriceLine19) + parseInt(depreciationLine19) - parseInt(costPriceLine19);
        			beforeEdit =   vm.form4797Line19RecordsOrig[index].dataCellList[9].value;
                    vm.formData_19.editrows[index][8].Attribute_Value = gainLossPerLine19;
                    vm.form4797Line19Records[index].dataCellList[9].value = gainLossPerLine19;
                    console.log('The gain/Loss value  for Editted line is :::::::: ------>', vm.form4797Line19Records[index].dataCellList[9].value);
                    temp0E = gainLossPerLine19 - beforeEdit;

                    vm.temp19E = vm.temp19E + temp0E ;
        		}
        	}
        	vm.totalLine2Amt = vm.totalLine2Amtintial + vm.temp02 + vm.temp2E;
        	vm.totalLine10Amt = vm.totalLine10Amtintial + vm.temp010 + vm.temp10E;
        	vm.totalLine19Amt = vm.totalLine19Amtintial + vm.temp019 + vm.temp19E;
        	vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
    		vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;
        }

        vm.removeExistingRecord = function(line, index, object_id) {

            console.log(' removeExistingRecord called for line & index ', line, index, object_id);
            var x = object_id.split("~");
            var occurrence =  x[4];
            var transDetailKey = x[3];
            var removeRow = {"tax_year": vm.taxYear, "trans_type_key": 7, "combination_key": vm.combinationKey, "group_obj_key": vm.groupObjKey, "form_key": 1, "line_no": line, "occurence": occurrence, "trans_dtls_key": transDetailKey, "trans_status": "A"};
            var gainLossAtIndex;

            if(line == 2) {

                vm.formData_2.removerows.push(removeRow);
                gainLossAtIndex = parseInt(vm.form4797Line2Records[index].dataCellList[9].value, 0);
                vm.totalLine2Amt = vm.totalLine2Amt - gainLossAtIndex;
                vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
        		vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;
                vm.form4797Line2Records.splice(index, 1);
            }
            else if(line == 10) {

                vm.formData_6.removerows.push(removeRow);
                gainLossAtIndex = parseInt(vm.form4797Line10Records[index].dataCellList[9].value, 0);
                vm.totalLine10Amt = vm.totalLine10Amt - gainLossAtIndex;
                vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
        		vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;
                vm.form4797Line10Records.splice(index, 1);
            }
            else if(line == 19) {

                vm.formData_6.removerows.push(removeRow);
                gainLossAtIndex = parseInt(vm.form4797Line19Records[index].dataCellList[9].value, 0);
                vm.totalLine19Amt = vm.totalLine19Amt - gainLossAtIndex;
                vm.totalPerForm4797[1].value = vm.totalLine2Amt+vm.totalLine10Amt+vm.totalLine19Amt;
        		vm.totalUnallocatedAmt[1].value = parseInt($scope.FormEntryData.section1.singlerows[1], 0)-vm.totalPerForm4797[1].value;
                vm.form4797Line19Records.splice(index, 1);
            }

            window.alert("Please click on 'Save and Proceed' to Delete this Transcation. Otherwise click on 'Cancel'");

        }

        /* save from workflow 5/14/2019 */

        $scope.$on('workflowSave',function(event,data){
			vm.save();
	   })


        vm.save = function(){

            var rowIsDirty = false;
            var singleRow;
            console.log('--- save function called ---');

            //console.log($scope.entryForm);
            var cleanIndices = [];

            //rowData.FORM_AMT = vm.schdDSum;  //4544;
            //rowData.UNALLOCATED_AMT = vm.unallocatedAmount;   //555555;

            console.log('rowData ==================== ', rowData );

            cleanIndices = [];

            angular.forEach(vm.formData_2.editrows, function(value, key){

                rowIsDirty = false;
                console.log(value[0]);

                if(value[0].is_dirty === "Y" || value[1].is_dirty === "Y" || value[2].is_dirty === "Y" || value[3].is_dirty === "Y" || value[4].is_dirty === "Y" || value[5].is_dirty === "Y" || value[6].is_dirty === "Y" || value[7].is_dirty === "Y" || value[8].is_dirty === "Y" || value[9].is_dirty === "Y")
                    rowIsDirty = true;

                if(!rowIsDirty) {
                    cleanIndices.push(key);
                }

            });

            vm.temprow2_editdata = angular.copy(vm.formData_2.editrows);

            // keep only dirty rows, by removing clean rows
            for (var i = cleanIndices.length -1; i >= 0; i--)
                vm.formData_2.editrows.splice(cleanIndices[i],1);


            cleanIndices = [];

            angular.forEach(vm.formData_10.editrows, function(value, key){

                rowIsDirty = false;
                console.log(value[0]);

                if(value[0].is_dirty === "Y" || value[1].is_dirty === "Y" || value[2].is_dirty === "Y" || value[3].is_dirty === "Y" || value[4].is_dirty === "Y" || value[5].is_dirty === "Y" || value[6].is_dirty === "Y" || value[7].is_dirty === "Y" || value[8].is_dirty === "Y" || value[9].is_dirty === "Y")
                    rowIsDirty = true;

                if(!rowIsDirty) {
                    cleanIndices.push(key);
                }

            });

            vm.temprow10_editdata = angular.copy(vm.formData_10.editrows);

            // keep only dirty rows, by removing clean rows
            for (var i = cleanIndices.length -1; i >= 0; i--)
                vm.formData_10.editrows.splice(cleanIndices[i],1);


            cleanIndices = [];

            angular.forEach(vm.formData_19.editrows, function(value, key){

                rowIsDirty = false;
                console.log(value[0]);

                if(value[0].is_dirty === "Y" || value[1].is_dirty === "Y" || value[2].is_dirty === "Y" || value[3].is_dirty === "Y" || value[4].is_dirty === "Y" || value[5].is_dirty === "Y" || value[6].is_dirty === "Y" || value[7].is_dirty === "Y" || value[8].is_dirty === "Y" || value[9].is_dirty === "Y")
                    rowIsDirty = true;

                if(!rowIsDirty) {
                    cleanIndices.push(key);
                }

            });

            vm.temprow19_editdata = angular.copy(vm.formData_19.editrows);

            // keep only dirty rows, by removing clean rows
            for (var i = cleanIndices.length -1; i >= 0; i--)
                vm.formData_19.editrows.splice(cleanIndices[i],1);

            console.log('formData_2 = ', vm.formData_2);
            console.log('formData_line4 = ', vm.formData_line4);
            console.log('formData_line5 = ', vm.formData_line5);
            console.log('formData_10 = ', vm.formData_10);
            console.log('formData_line14 = ', vm.formData_line14);
            console.log('formData_line15 = ', vm.formData_line15);
            console.log('formData_line16 = ', vm.formData_line16);
            console.log('formData_19 = ', vm.formData_19);

            //For Line 2 to push formData
            angular.forEach(vm.formData_2.addrows, function(value, key){

                singleRow = angular.copy(value);
                angular.forEach(singleRow, function(val, k) {
                    if(k == 1 || k == 2) {

                    	console.log("The  value of Date Acquired is :: ----", singleRow[k].Attribute_Value);

                        if(singleRow[k].Attribute_Value == undefined){
                            singleRow[k].Attribute_Value= "VARIOUS";
                        }
                        if(singleRow[k].Attribute_Value != "VARIOUS") {
                            var date = singleRow[k].Attribute_Value;
                            singleRow[k].Attribute_Value = vm.convertDate(date);
                        }
                    }
                });
                vm.formData.addrows.push(singleRow);
            });

            angular.forEach(vm.formData_2.editrows, function(value, key){
            	console.log("It is in the Editrows Loop. Please check it.........");
            	singleRow = angular.copy(value);
            	angular.forEach(singleRow, function(val, k) {
            		if(k == 3 || k == 4 || k == 5 || k == 8){
            			if(!angular.isNumber(singleRow[k].Attribute_Value)){
            				var number = singleRow[k].Attribute_Value;
            				singleRow[k].Attribute_Value = parseInt(number, 10);
            				console.log('The converted number is----', singleRow[k].Attribute_Value);
            			}
            		}
            	});

                vm.formData.editrows.push(singleRow);
            });
            angular.forEach(vm.formData_2.removerows, function(value, key){
                vm.formData.removerows.push(value);
            });

          //For Line 10 to push formData
            angular.forEach(vm.formData_10.addrows, function(value, key){

                singleRow = angular.copy(value);
                angular.forEach(singleRow, function(val, k) {
                    if(k == 1 || k == 2) {

                    	console.log("The  value of Date Acquired is :: ----", singleRow[k].Attribute_Value);

                        if(singleRow[k].Attribute_Value == undefined){
                            singleRow[k].Attribute_Value= "VARIOUS";
                        }
                        if(singleRow[k].Attribute_Value != "VARIOUS") {
                            var date = singleRow[k].Attribute_Value;
                            singleRow[k].Attribute_Value = vm.convertDate(date);
                        }
                    }
                });
                vm.formData.addrows.push(singleRow);
            });

            angular.forEach(vm.formData_10.editrows, function(value, key){
            	console.log("It is in the Editrows Loop. Please check it.........");
            	singleRow = angular.copy(value);
            	angular.forEach(singleRow, function(val, k) {
            		if(k == 3 || k == 4 || k == 5 || k == 8){
            			if(!angular.isNumber(singleRow[k].Attribute_Value)){
            				var number = singleRow[k].Attribute_Value;
            				singleRow[k].Attribute_Value = parseInt(number, 10);
            				console.log('The converted number is----', singleRow[k].Attribute_Value);
            			}
            		}
            	});

                vm.formData.editrows.push(singleRow);
            });
            angular.forEach(vm.formData_10.removerows, function(value, key){
                vm.formData.removerows.push(value);
            });


            //For Line 19 to push formData
            angular.forEach(vm.formData_19.addrows, function(value, key){

                singleRow = angular.copy(value);
                angular.forEach(singleRow, function(val, k) {
                    if(k == 1 || k == 2) {

                    	console.log("The  value of Date Acquired is :: ----", singleRow[k].Attribute_Value);

                        if(singleRow[k].Attribute_Value == undefined){
                            singleRow[k].Attribute_Value= "VARIOUS";
                        }
                        if(singleRow[k].Attribute_Value != "VARIOUS") {
                            var date = singleRow[k].Attribute_Value;
                            singleRow[k].Attribute_Value = vm.convertDate(date);
                        }
                    }
                });
                vm.formData.addrows.push(singleRow);
            });

            angular.forEach(vm.formData_19.editrows, function(value, key){
            	console.log("It is in the Editrows Loop. Please check it.........");
            	singleRow = angular.copy(value);
            	angular.forEach(singleRow, function(val, k) {
            		if(k == 3 || k == 4 || k == 5 || k == 8){
            			if(!angular.isNumber(singleRow[k].Attribute_Value)){
            				var number = singleRow[k].Attribute_Value;
            				singleRow[k].Attribute_Value = parseInt(number, 10);
            				console.log('The converted number is----', singleRow[k].Attribute_Value);
            			}
            		}
            	});

                vm.formData.editrows.push(singleRow);
            });
            angular.forEach(vm.formData_19.removerows, function(value, key){
                vm.formData.removerows.push(value);
            });

            if(vm.formData_line4.editrows[0][0].is_dirty == 'Y') {
                vm.formData.editrows.push(vm.formData_line4.editrows[0]);
            }

            if(vm.formData_line5.editrows[0][0].is_dirty == 'Y') {
                vm.formData.editrows.push(vm.formData_line5.editrows[0]);
            }

            if(vm.formData_line14.editrows[0][0].is_dirty == 'Y') {
                vm.formData.editrows.push(vm.formData_line14.editrows[0]);
            }

            if(vm.formData_line15.editrows[0][0].is_dirty == 'Y') {
                vm.formData.editrows.push(vm.formData_line15.editrows[0]);
            }

            if(vm.formData_line16.editrows[0][0].is_dirty == 'Y') {
                vm.formData.editrows.push(vm.formData_line16.editrows[0]);
            }

            console.log('-------------- formData -------------------- = ', vm.formData);

            vm.addrows= vm.formData.addrows;
            vm.editrows= vm.formData.editrows;
            vm.removerows= vm.formData.removerows;

            if((null!=vm.addrows && vm.addrows.length >0) || (null!= vm.editrows && vm.editrows.length >0) || (null!= vm.removerows && vm.removerows.length >0)){
            	console.log('The rows are not empty----------');

            	vm.formData.addrows.forEach((item) => item.forEach((childItem) => childItem.form_key = vm.v_form_key));
                vm.formData.editrows.forEach((item) => item.forEach((childItem) => childItem.form_key = vm.v_form_key));
                vm.formData.removerows.forEach((item) => item.form_key = vm.v_form_key);

            	vm.mainData = [];
                vm.mainData.push(vm.formData);
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=jqzx4y";
                Form4797Factory.save4797Form(url, vm.mainData, rowData).then(function (data){
                	console.log('The result for the save proc is :::------->',data);
                    if(null!=data.jsonObject && data.jsonObject.length > 0){
                    	AlertService.add("danger", "Please correct the errors below", 4000);
                    	vm.formData_2_errorMessages = data.jsonObject[0];
                        vm.formData_10_errorMessages = data.jsonObject[1];
                        vm.formData_19_errorMessages = data.jsonObject[2];
                    	//Need to add Row Error on top
                    	//Manage Validations
                        if(null!=vm.formData_2_errorMessages.editrows && vm.formData_2_errorMessages.editrows.length > 0){
                            //edit rows loop
                            angular.forEach(vm.formData_2_errorMessages.editrows, function(value, key){
                                vm.editTotalRows_2 = value;
                                //edit column loop
                                angular.forEach(vm.editTotalRows_2, function(value, key){
                                    vm.errorMessage_2 = value.column_error;
                                    vm.Line_attrib_key_2 = value.line_attrib_key;
                                    vm.object_id = value.tax_year + '~' + value.trans_type_key + '~' + vm.line2FormLineKey + '~' + value.trans_dtls_key + '~' + value.occurence + '~' + value.form_key;
                                    //Add data to errorMessage
                                    angular.forEach(vm.form4797Line2Records, function(value, key){
                                        vm.eachrowData_2 = value;
                                        if(value.rowDimensions.object_id == vm.object_id){
                                            angular.forEach(vm.eachrowData_2.dataCellList, function(value, key){
                                                var y = value.key.split("~");
                                                var LineAttribKey = y[1];
                                                if(LineAttribKey == vm.Line_attrib_key_2){
                                                    vm.eachrowData_2.dataCellList[key].error_message = vm.errorMessage_2;
                                                }
                                            })
                                        }

                                    })
                                    //console.log("The error Message that was populated for row was::---  ", vm.object_id);
                                })
                            });
                        }
                        if(null!=vm.formData_10_errorMessages.editrows && vm.formData_10_errorMessages.editrows.length > 0){
                            //edit rows loop
                            angular.forEach(vm.formData_10_errorMessages.editrows, function(value, key){
                                vm.editTotalRows_10 = value;
                                //edit column loop
                                angular.forEach(vm.editTotalRows_10, function(value, key){
                                    vm.errorMessage_10 = value.column_error;
                                    vm.Line_attrib_key_10 = value.line_attrib_key;
                                    vm.object_id = value.tax_year + '~' + value.trans_type_key + '~' + vm.line10FormLineKey + '~' + value.trans_dtls_key + '~' + value.occurence + '~' + value.form_key;
                                    //Add data to errorMessage
                                    angular.forEach(vm.form4797Line10Records, function(value, key){
                                        vm.eachrowData_10 = value;
                                        if(value.rowDimensions.object_id == vm.object_id){
                                            angular.forEach(vm.eachrowData_10.dataCellList, function(value, key){
                                                var y = value.key.split("~");
                                                var LineAttribKey = y[1];
                                                if(LineAttribKey == vm.Line_attrib_key_10){
                                                    vm.eachrowData_10.dataCellList[key].error_message = vm.errorMessage_10;
                                                }
                                            })
                                        }

                                    })
                                    //console.log("The error Message that was populated for row was::---  ", vm.object_id);
                                })
                            });
                        }
                        if(null!=vm.formData_19_errorMessages.editrows && vm.formData_19_errorMessages.editrows.length > 0){
                            //edit rows loop
                            angular.forEach(vm.formData_19_errorMessages.editrows, function(value, key){
                                vm.editTotalRows_19 = value;
                                //edit column loop
                                angular.forEach(vm.editTotalRows_19, function(value, key){
                                    vm.errorMessage_19 = value.column_error;
                                    vm.Line_attrib_key_19 = value.line_attrib_key;
                                    vm.object_id = value.tax_year + '~' + value.trans_type_key + '~' + vm.line19FormLineKey + '~' + value.trans_dtls_key + '~' + value.occurence + '~' + value.form_key;
                                    //Add data to errorMessage
                                    angular.forEach(vm.form4797Line19Records, function(value, key){
                                        vm.eachrowData_19 = value;
                                        if(value.rowDimensions.object_id == vm.object_id){
                                            angular.forEach(vm.eachrowData_19.dataCellList, function(value, key){
                                                var y = value.key.split("~");
                                                var LineAttribKey = y[1];
                                                if(LineAttribKey == vm.Line_attrib_key_19){
                                                    vm.eachrowData_19.dataCellList[key].error_message = vm.errorMessage_19;
                                                }
                                            })
                                        }

                                    })
                                    //console.log("The error Message that was populated for row was::---  ", vm.object_id);
                                })
                            });
                        }
                    	console.log('The Save Proc is not working please verify');
                    }else{
                    	if(data.callSuccess == "1" && data.errorMessage == "null"){
                    		vm.cancel();
                    		var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
                            $rootScope.$emit('gridUpdate', args);
                        	AlertService.add("success", "Transactions saved successfully", 4000);
                        }else{
                        	AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support");
                        }
                    }
                });

            }else{
            	vm.cancel();
            }

            vm.formData = {
                    "addrows"   : [],
                    "editrows"  : [],
                    "removerows": []
                };
            //$uibModalInstance.dismiss('cancel');
        }


        vm.convertDate = function (dateObject) {
            console.log('convertDate called');
            if (dateObject) {
                var month = (dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1);
                var date = dateObject.getDate() < 10 ? '0' +  dateObject.getDate() : dateObject.getDate();
                var year = dateObject.getFullYear();
                return month + '/' + date + '/' + year;
            } else {
                return null;
            }
        }





        $scope.data = {
            partI:{
                title: 'Part I: Sales or Exchanges of property Used in a Trade or Business and Involuntary Conversions From Other Than Casuality or Theft Property Held More Than 1 Year',
                cols: ['4797 Line', 'Description of Property', 'Date Acquired', 'Date Sold', 'Gross Sales Price', 'Depreciation allowed', 'Cost or Other Basis', 'Gain or (Loss)', 'Worthless Stock?', 'Section 754?'],
                rows: [{}, {}],
                rows2: [{
                    num: 3,
                    description: 'Gain, if any, from Form 4684, line 39'
                }, {
                    num: 4,
                    description: 'Section 1231 gain from installment sales from Form 6252, line 26 or 37'
                }, {
                    num: 5,
                    description: 'Section 1231 gain or (loss) from like-kind exchanges from Form 8824'
                }, {
                    num: 6,
                    description: 'Gain, if any, from line 32, from other than casualty or theft'
                }, {
                    num: 7,
                    description: 'Combine lines 2 through 6. Enter the gain or (loss) here and on the appropriate line as follows:'
                }, {
                    description: 'Partnerships (except electing large partnerships) and S corporations. Report the gain or (loss) following the instructions for Form 1065, Schedule K, line 10, or Form 1120S, Schedule K, line 9. Skip lines 8, 9, 11, and 12 below.'
                }, {
                    description: 'Individuals, partners, S corporation shareholders, and all others. If line 7 is zero or a loss, enter the amount from line 7 on line 11 below and skip lines 8 and 9. If line 7 is a gain and you didn’t have any prior year section 1231 losses, or they were recaptured in an earlier year, enter the gain from line 7 as a long-term capital gain on the Schedule D filed with your return and skip lines 8, 9, 11, and 12 below.'
                }, {
                    num: 8,
                    description: 'Non recaptured net section 1231 losses from prior years.'
                }, {
                    num: 9,
                    description: 'Subtract line 8 from line 7. If zero or less, enter -0-. If line 9 is zero, enter the gain from line 7 on line 12 below. If line 9 is more than zero, enter the amount from line 8 on line 12 below and enter the gain from line 9 as a long-term capital gain on the Schedule D filed with your return.'
                }],
                totals: [{
                    description: 'Total Part I Gain or (Loss)'
                }]
            },
            partII:{
                title: 'Part II: Ordinary Gains and Losses',
                cols: ['4797 Line', 'Description of Property', 'Date Acquired', 'Date Sold', 'Gross Sales Price', 'Depreciation allowed', 'Cost or Other Basis', 'Gain or (Loss)', 'Worthless Stock?', 'Section 754?'],
                rows: [{}, {}],
                rows2: [{
                    num: 11,
                    description: 'Loss, if any, from line 7'
                }, {
                    num: 12,
                    description: 'Gain, if any, from line 7 or amount from line 8, if applicable'
                }, {
                    num: 13,
                    description: 'Gain, if any, from line 31',
                }, {
                    num: 14,
                    description: 'Net gain or (loss) from Form 4684, lines 31 and 38a'
                }, {
                    num: 15,
                    description: 'Ordinary gain from installment sales from Form 6252, line 25 or 36'
                }, {
                    num: 16,
                    description: 'Ordinary gain or (loss) from like-kind exchanges from Form 8824'
                }, {
                    num: 17,
                    description: 'Combine lines 10 through 16'
                }, {
                    num: 18,
                    description: 'For all except individual returns, enter the amount from line 17 on the appropriate line of your return and skip lines a and b below. For individual returns, complete lines a and b below:'
                }, {
                    description: 'a. If the loss on line 11 includes a loss from Form 4684, line 35, column (b)(ii), enter that part of the loss here. Enter the part of the loss from income-producing property on Schedule A (Form 1040), line 28, and the part of the loss from property used as an employee on Schedule A (Form 1040), line 23. Identify as from “Form 4797, line 18a.” See instructions'
                }, {
                    description: 'b. Redetermine the gain or (loss) on line 17 excluding the loss, if any, on line 18a. Enter here and on Form 1040, line 14'
                }],
                totals: [{
                    description: 'Total Part II Gain or (Loss)'
                }]
            },
            partIII:{
                title:"Part III: Gain From Disposition of Property Under Section 1245",
                cols: ['4797 Line', 'Description of Property', 'Date Acquired', 'Date Sold', 'Gross Sales Price', 'Depreciation allowed', 'Cost or Other Basis', 'Gain or (Loss)', 'Worthless Stock?', 'Section 754?'],
                rows: [{}, {}],
                rows2: [{
                    num: '',
                    description: 'These columns relate to the properties on lines 19A through 19D',
                    propertyA:'Property A',
                    propertyB:'Property B',
                    propertyC:'Property C',
                    propertyD:'Property D'
                }, {
                    num: 20,
                    description: 'Gross sales price (Note: See line 1 before completing.)'
                }, {
                    num: 21,
                    description: 'Cost or other basis plus expense of sale',
                }, {
                    num: 22,
                    description: 'Depreciation (or depletion) allowed or allowable'
                }, {
                    num: 23,
                    description: 'Adjusted basis. Subtract line 22 from line 21'
                }, {
                    num: 24,
                    description: 'Total gain. Subtract line 23 from line 20'
                }, {
                    num: 25,
                    description: 'If section 1245 property:'
                }, {
                    num: '',
                    description: 'a. Depreciation allowed or allowable from line 22'
                },{
                    num: '',
                    description: 'b. Enter the smaller of line 24 or 25a'
                },{
                    num: 26,
                    description: 'If section 1250 property: If straight line depreciation was used, enter -0- on line 26g, except for a corporation subject to section 291'
                },{
                    num: '',
                    description: 'a. Additional depreciation after 1975. See instructions'
                },{
                    num: '',
                    description: 'b. Applicable percentage multiplied by the smaller of line 24 or line 26a. See instructions'
                },{
                    num: '',
                    description: 'c. Subtract line 26a from line 24. If residential rental property or line 24 isn’t more than line 26a, skip lines 26d and 26e'
                },{
                    num: '',
                    description: 'd. Additional depreciation after 1969 and before 1976'
                },{
                    num: '',
                    description: 'e. Enter the smaller of line 26c or 26d'
                },{
                    num: '',
                    description: 'f. Section 291 amount (corporations only)'
                },{
                    num: '',
                    description: 'g. Add lines 26b, 26e, and 26f'
                },{
                    num: '27',
                    description: 'If section 1252 property: Skip this section if you didn’t dispose of farmland or if this form is being completed for a partnership (other than an electing large partnership).'
                },{
                    num: '',
                    description: 'a. Soil, water, and land clearing expenses'
                },{
                    num: '',
                    description: 'b. Line 27a multiplied by applicable percentage. See instructions'
                },{
                    num: '',
                    description: 'c. Enter the smaller of line 24 or 27b'
                },{
                    num: '28',
                    description: 'If section 1254 property:'
                },{
                    num: '',
                    description: 'a. Intangible drilling and development costs, expenditures for development of mines and other natural deposits, mining exploration costs, and depletion. See instructions'
                },{
                    num: '',
                    description: 'b. Enter the smaller of line 24 or 28a'
                },{
                    num: '29',
                    description: 'If section 1255 property:'
                },{
                    num: '',
                    description: 'a. Applicable percentage of payments excluded from income under section 126. See instructions'
                },{
                    num: '',
                    description: 'b. Enter the smaller of line 24 or 29a. See instructions'
                }],
                rows3:[{
                    num: '',
                    description: 'Summary of Part III Gains. Complete property columns A through D through line 29b before going to line 30.'
                }],
                rows4:[{
                    num: '30',
                    description: 'Total gains for all properties. Add property columns A through D, line 24'
                },{
                    num: '31',
                    description: 'Add property columns A through D, lines 25b, 26g, 27c, 28b, and 29b. Enter here and on line 13'
                },{
                    num: '32',
                    description: 'Subtract line 31 from line 30. Enter the portion from casualty or theft on Form 4684, line 33. Enter the portion from other than casualty or theft on Form 4797, line 6'
                }],
                totals: [{
                    description: 'Total Part III Gain or (Loss)'
                }]
            },
            partIV:{
                title:"Part IV: Recapture Amounts Under Sections 179 and 280F(b)(2) When Business Use Drops to 50% or Less (see instructions)",
                cols:['Description of the Property','(a) Section 179','(b) Section 280F(b)(2)'],
                rows:[{
                    num:"33",
                    description: "Section 179 expense deduction or depreciation allowable in prior years"
                },{
                    num:"34",
                    description: "Recomputed depreciation. See instructions"
                },{
                    num:"35",
                    description: "Recapture amount. Subtract line 34 from line 33. See the instructions for where to report"
                }]
            }
        }



        vm.cancel = function () {
        	if($uibModalInstance) {
    			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
    			vm.cancel = function () {
    				var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
    				$rootScope.$emit('gridUpdate', args);
    				$uibModalInstance.close();
    				vm.modal_name = null;
    			};
    		} else {
    			vm.modal_name = null;
    		}
        };
    }
});