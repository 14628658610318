define([
    'angular',
    './aggRerunController',
    './aggRerunService'



], function () {
    'use strict';
    return angular.module('app.aggRerun', ['app.aggrerunController','app.aggrerunService'] );


});