const { forEach } = require("lodash");

define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.EfileDataAdminService', [])
    .factory("efileFactory", ['$http', 'GENERAL_CONFIG', 'JsonObjectFactory', 'AlertService', 'USER_SETTINGS',
        function ($http, GENERAL_CONFIG, JsonObjectFactory, AlertService, USER_SETTINGS) {
            let efileFactory = {};
            
            efileFactory.getTableMetaData = function () {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',
                {
                    "action_id": 32855,
                    "sso_id": USER_SETTINGS.user.sso_id,
                    "table_name": "TMEF_SOFTWARE_ID",
                    "table_schema": "GTWMEF01",
                    "is_core_table": 1
                });
            };

            efileFactory.getSoftwareRowInfo = function(schemaType) {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',
                {
                    "action_id": 32860,
                    "sso_id": USER_SETTINGS.user.sso_id,
                    "schematype": schemaType
                });
            };

            efileFactory.saveIrsSubmission = function(data) {
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.gtw_efile_api_url + "/saveIrsSubmission",
                    data: JSON.stringify(data),
                    headers: {'Content-Type': 'application/json; charset=utf-8'} 
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            return efileFactory;
        }]);
        
    return services;
});
