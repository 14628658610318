define([
    'angular',
    './QreEntityBusinessController',
    './QreEntityBusinessService'



], function () {
    'use strict';
    return angular.module('app.qreEntityBusiness', ['app.qreentityBusinessController','app.qreentityBusinessService'] );


});