define(
    ['angular'

    ],
    function () {
        'use strict';

        var controllers = angular.module(
            'app.exclusionListController', []).controller(
                'exclusionListController',
                ['bizzHierViewFactory', '$scope', "AlertService",
                    "$filter", "GlobalService",
                    "USER_SETTINGS", "entityLockingFactory",
                    "$uibModal", "GENERAL_CONFIG",
                    "JsonObjectFactory", "exclusionListFactory",
                    "workspaceFactory", "$rootScope", '$uibModalInstance', 'gridData',
                    exclusionListController])

        function exclusionListController(bizzHierViewFactory, $scope,
            AlertService, $filter, GlobalService, USER_SETTINGS,
            entityLockingFactory, $uibModal, GENERAL_CONFIG,
            JsonObjectFactory, exclusionListFactory, workspaceFactory,
            $rootScope, $uibModalInstance, gridData) {

                var vm = this;
                vm.isEdit = gridData === "" ? "INSERT" : "UPDATE";
                vm.modalTitle = "LE Overrides Setup";
                vm.showEntityLoader = false;
                vm.isSaving = false;
                vm.consolGroups = [];
                vm.filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                vm.dateOptions = {
                    dateDisabled: false,
                    formatYear: 'yyyy',
                    startingDay: 1,
                    minMode: 'year',
                    dateFormat: 'yyyy',
                    onClose: function(dateText, inst) {   
                    }
                };
                if(vm.isEdit == "INSERT") {
                    vm.exclusionEntity_bckup = {
                        LEGAL_ENTITY_ID:"",
                        FORM_CATEGORY:"851",
                        START_TAX_YEAR:"",
                        END_TAX_YEAR:"",
                        STATUS: "EXCLUDE",
                        STATUS_COMMENT: "",
                        FILING_KEY: ""
                    };
    
                    vm.exclusionEntity_bckup.START_TAX_YEAR = new Date(workspaceFactory.activeScreen.filters.getFilterParams().tax_year - 1, '0', '1');
                    vm.exclusionEntity_bckup.END_TAX_YEAR =new Date(9999+"", '0', '1');
                } else {
                    vm.exclusionEntity_bckup = gridData.rowData;
                    vm.exclusionEntity_bckup.START_TAX_YEAR = Object.prototype.toString.call(gridData.rowData.START_TAX_YEAR) === "[object Date]" ? gridData.rowData.START_TAX_YEAR : new Date(gridData.rowData.START_TAX_YEAR, '0', '1');
                    vm.exclusionEntity_bckup.END_TAX_YEAR = Object.prototype.toString.call(gridData.rowData.END_TAX_YEAR) === "[object Date]" ? gridData.rowData.END_TAX_YEAR : new Date(gridData.rowData.END_TAX_YEAR, '0', '1');
                } 
                vm.exclusionEntity_bckup.FILING_KEY = vm.exclusionEntity_bckup.FILING_KEY && vm.exclusionEntity_bckup.FILING_KEY != '' ? vm.exclusionEntity_bckup.FILING_KEY : vm.filterParams.filing_key;
                
                vm.exclusionEntity = angular.copy(vm.exclusionEntity_bckup);
        
                vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0] ? $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE : 'RAF';
        
        
                if (vm.isIssueKeyEnabled === undefined) {
                    if (vm.scenarioCode !== undefined) {
                        if (vm.scenarioCode === 'RAF') {
                            vm.isIssueKeyEnabled = 'N';
                        }
                        else {
                            vm.isIssueKeyEnabled = 'Y';
                        }
                    }
                }
        
        
                vm.init =function(){
                    exclusionListFactory.loadConsolGroups().then((data)=> {
                        vm.consolGroups = data;
                        vm.showEntityLoader = false;
                    })
                };
                vm.init();
        
                vm.isValid = function(){
                    if(vm.exclusionEntity.LEGAL_ENTITY_ID == null || 
                        vm.exclusionEntity.LEGAL_ENTITY_ID == undefined || 
                        vm.exclusionEntity.LEGAL_ENTITY_ID == ""){
                             vm.exclusionEntity.LEGAL_ENTITY_ID_NOT_VALID = true;
                             return false;
                    }  
                    if(vm.exclusionEntity.START_TAX_YEAR == null || 
                        vm.exclusionEntity.START_TAX_YEAR == undefined || 
                        vm.exclusionEntity.START_TAX_YEAR == ""){
                             vm.exclusionEntity.START_TAX_YEAR_NOT_VALID = true;
                             return false;
                    }
                    if(vm.exclusionEntity.END_TAX_YEAR == null || 
                        vm.exclusionEntity.END_TAX_YEAR == undefined || 
                        vm.exclusionEntity.END_TAX_YEAR == ""){
                             vm.exclusionEntity.END_TAX_YEAR_NOT_VALID = true;
                             return false;
                    }            
                    return true;
                }
        
                vm.save= function(){
                    if(!vm.isValid()){
                        AlertService.add("warn", "Please fill all the required fields", 1000);
                        return false;
                    }
                    vm.isSaving = true;
                    
                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        
                    let jsonSettings = {
                        tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                    };
                    jsonSettings = JSON.stringify(jsonSettings);
                    vm.exclusionEntity.ACTION = vm.isEdit;
                    let jsonObj = angular.copy(vm.exclusionEntity);
                    jsonObj.START_TAX_YEAR = jsonObj.START_TAX_YEAR.getFullYear().toString();
                    jsonObj.END_TAX_YEAR = jsonObj.END_TAX_YEAR.getFullYear().toString();
                    jsonObj = JSON.stringify([jsonObj]);
        
                    let params = filterParams;
                    params = _.merge({}, GlobalService.globalParams);
                    params = _.extend({
                        json_data: jsonObj
                    }, params);
                    params = _.extend({
                        jsonSettings: jsonSettings
                    }, params);
                    const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=08qlz6";
                    params.is_issue_key_enabled = vm.isIssueKeyEnabled;
                    params.scenarioCode = vm.scenarioCode;
                    JsonObjectFactory.saveJSON(url, params).then(function (data) {
                        vm.isSaving = false;
                        if (data.callSuccess === "1" && data.errorMessage == "null") {
                            $uibModalInstance.dismiss('cancel');
                            $rootScope.$emit('gridUpdate', {});
                            AlertService.add("success", "LE Override Updated Successfully", 4000);
                            console.log("saved", data);
                        } else {
                            console.log("error");
                            AlertService.add("error",data.errorMessage,4000);
                        }
                    });
                };
        
                vm.upper = function(configItem) {
                    vm.exclusionEntity.LEGAL_ENTITY_ID = configItem.toUpperCase();
                }
        
                vm.reset = function (){
                    vm.exclusionEntity = angular.copy(vm.exclusionEntity_bckup);
                };
        
                vm.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
        }
    });