define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.retainedEarningsService', [])
     .factory("retainedEarnServiceFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse){
    	 var retainedEarnServiceFactory = {};
    	 var URLS =
    		 {
    			 saveSchduleO				: 'saveJsonObject.ge?action_code=3kk6ql',
    			 saveStockAcquisition	 	: 'saveJsonObject.ge?action_code=3kk6ql',
    			 saveStockDiposition		: 'saveJsonObject.ge?action_code=3kk6ql', 
    			 saveReorganization			: 'saveJsonObject.ge?action_code=3kk6ql', 	
    			 
    				 
    		 }
    		 
    		 retainedEarnServiceFactory.saveSchduleO = function(data){
    		 data.json_string = JSON.stringify(data);
    		 var promise = $http({
    			 method: "POST",
    			 url: URLS.saveSchduleO,
    			 data: data
    		}). then(function (response){
    				console.log()
    				return response.data;
    				})
    			
    			return promise;	
    	 }
    	 
    	 
    	 retainedEarnServiceFactory.saveStockAcquisition = function(data){
    		 data.json_string = JSON.stringify(data);
    		 var promise = $http({
    			 method: "POST",
    			 url: URLS.saveStockAcquisition,
    			 data: data
    		}). then(function (response){
    				console.log()
    				return response.data;
    				})
    			
    			return promise;	
    	 }
    	
    	 
    	 retainedEarnServiceFactory.saveStockDiposition = function(data){
    		 data.json_string = JSON.stringify(data);
    		 var promise = $http({
    			 method: "POST",
    			 url: URLS.saveStockDiposition,
    			 data: data
    		}). then(function (response){
    				console.log()
    				return response.data;
    				})
    			
    			return promise;	

    	 
    	 }
    	 
    	 retainedEarnServiceFactory.saveReorganization = function(data){
    		 data.json_string = JSON.stringify(data);
    		 var promise = $http({
    			 method: "POST",
    			 url: URLS.saveReorganization,
    			 data: data
    		}). then(function (response){
    				console.log()
    				return response.data;
    				})
    			
    			return promise;	

    	 
    	 }
    	 
     
    	 return retainedEarnServiceFactory;
     }]);
    
    return services;
    
     	
     
});