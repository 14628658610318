define(
		[ 'angular' ],
		function() {
			'use strict';

			var controllers = angular
					.module('app.recurringMultiYearTaxAdjustmentController', [])
					.controller(
							'recurringMultiYearTaxAdjustmentController',
							[ '$rootScope', '$scope', '$anchorScroll',
									'AlertService', 'FileAttachementFactory',
									'CodeComboFactory', 'JsonObjectFactory',
									'recurringMultiYearTaxAdjustmentService',
									'SERVER_CONFIG', 'GENERAL_CONFIG',
									'ModalFactory', 'GlobalService',
									'$uibModal', 'USER_SETTINGS',
									'$uibModalInstance', 'rowData', 'gridData',
									recurringMultiYearTaxAdjustmentController ]);

			function recurringMultiYearTaxAdjustmentController($rootScope,
					$scope, $anchorScroll, AlertService,
					FileAttachementFactory, CodeComboFactory,
					JsonObjectFactory, recurringMultiYearTaxAdjustmentService,
					SERVER_CONFIG, GENERAL_CONFIG, ModalFactory, GlobalService,
					$uibModal, USER_SETTINGS, $uibModalInstance, rowData,
					gridData, thisObj) {

				var vm = this;
				vm.title = "Recurring Multiyear Tax Adjustment";
				vm.rowData = rowData || $scope.$parent.rowData;
				vm.saveDataArray = [];
				vm.TYPE = 'I';
				$scope.crudLoading = false;
				// vm.old_posting_gl_acct = vm.existingAdjustments[_index];
				// vm.oldtemplateval =
				// vm.existingAdjustments[_index].SCHDM_DESC;
				vm.userm1_desc = "";
				vm.old_userm1_desc = "";

				vm.old_posting_acct_key = "";
				vm.old_posting_tac_key = "";
				vm.old_template_id = "";
				vm.old_schdm_type = ""
				vm.old_tbbs_acct = "";
				vm.old_beginYear = "";
				vm.old_endYear = "";
				vm.old_schdmt = "";
				vm.old_annual_adjustment_amount = "";
				vm.noRecords = false;

				init();

				function init() {
					enableModal();
					initCodeCombination();
					initSection1();
					initMandatorySection();
					initViewAdjustmentScheduleSection();
					getExistingRecurringTaxadj();
					$scope.crudLoading = false;
					vm.readonlybeginyear = false;
					// loadexistingData();

				}
				;

				function enableModal() {
					if ($uibModalInstance) {
						vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
								: null;
						vm.cancel = function() {
							var args = {
								combination_keys : vm.rowData.COMBINATION_KEY,
								gridFilter : {
									COMBINATION_KEY : vm.rowData.COMBINATION_KEY
								}
							};
							$rootScope.$emit('gridUpdate', args);
							$uibModalInstance.close();
							vm.modal_name = null;
						};
					} else {
						vm.modal_name = null;
					}
				}
				;

				function initCodeCombination() {
					if (vm.rowData.COMBINATION_KEY) {

						CodeComboFactory
								.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
						CodeComboFactory.toggle = false;
						CodeComboFactory.showFormBtn = false;

					} else {
						console.log(
								"$scope.rowData.COMBINATION_KEY NOT FOUND ",
								vm.rowData.COMBINATION_KEY);
						CodeComboFactory.toggle = true;
					}
				}

				function initSection1() {

					vm.testflag = false;
					vm.triggerloading = false;
					vm.gloading = false;
					/*
					 * vm.postingGL = vm.rowData.LOCAL_ACCT + ' - ' +
					 * vm.rowData.LOCAL_ACCT_DESC;
					 */
					vm.postinggl_acct = []; // TODO
					/*
					 * vm.postingTax = vm.rowData.SYSTEM_ACCT + ' - ' +
					 * vm.rowData.SYSTEM_ACCT_DESC;
					 */
					/* vm.postingacct_key = vm.rowData.POSTING_TAC_KEY; */
					vm.postingTax_acct = [] // TODO

					vm.dcscurr = "USD";
					/*
					 * vm.bookamt = Number(vm.rowData.BOOK_AMT).toLocaleString(
					 * 'en'); vm.bookadj =
					 * Number(vm.rowData.BOOK_ADJ).toLocaleString( 'en');
					 */
					// loadtriggerdata();
					getglAccts();
				}
				;
				function loadtriggerdata() {
					vm.triggerloading = false;
					var params = {
						"action_code" : 'byc4kc',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"combi_key" : vm.rowData.COMBINATION_KEY,
						"posting_acct_key" : vm.rowData.POSTING_ACCT_KEY
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.triggerloading = true;
										} else {

											vm.triggerdata = data.jsonObject;
											if (vm.triggerdata.length > 0) {
												vm.postingGL = vm.triggerdata[0].POSTING_ACCT;
												vm.postingTax = vm.triggerdata[0].POSTING_TAC;
												vm.m3formline = vm.triggerdata[0].M3_ACCT;
												vm.bookamt = vm.triggerdata[0].BOOK_AMT;
												vm.bookadj = vm.triggerdata[0].BOOK_ADJ;
												vm.taxadj = vm.triggerdata[0].TAX_ADJ;
												vm.postingacct_key = vm.triggerdata[0].POSTING_TAC_KEY;
											}

										}
									});

				}
				;

				function getglAccts() {
					var params = {
						"action_code" : 'aj9z5t',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"chart_key" : vm.rowData.CHART_KEY

					}
					vm.triggerloading = true;
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.gloading = true;
										} else {

											vm.postinggl_acct_M = data.jsonObject;
											for ( var a in vm.postinggl_acct_M) {
												vm.postinggl_acct
														.push(vm.postinggl_acct_M[a].GL_ACCT);
											}

											vm.postinggl_acct = _.uniq(
													vm.postinggl_acct,
													JSON.stringify);

											vm.gloading = true;

										}

									});
				}
				;

				vm.callFun = function(row) {
					vm.triggerloading = false;
					var posting_acct_key = "";
					for ( var c in vm.postinggl_acct_M) {
						if (vm.postinggl_acct_M[c].GL_ACCT == row) {
							posting_acct_key = vm.postinggl_acct_M[c].GL_ACCT_KEY;
						}
					}

					var params = {
						"action_code" : 'byc4kc',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"combi_key" : vm.rowData.COMBINATION_KEY,
						"posting_acct_key" : posting_acct_key

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.triggerloading = true;
										} else {

											vm.triggerdata = data.jsonObject;

											if (vm.triggerdata.length > 0) {
												// vm.postingGL =
												// vm.triggerdata[0].POSTING_ACCT;

												vm.postingTax = vm.triggerdata[0].POSTING_TAC;
												vm.m3formline = vm.triggerdata[0].M3_ACCT;
												vm.bookamt = vm.triggerdata[0].BOOK_AMT;
												vm.bookadj = vm.triggerdata[0].BOOK_ADJ;
												vm.taxadj = vm.triggerdata[0].TAX_ADJ;
												vm.posting_tac_key = vm.triggerdata[0].POSTING_TAC_KEY;
												vm.posting_acct_key = vm.triggerdata[0].POSTING_ACCT_KEY;
												loadtemplatedata(
														vm.posting_tac_key,
														vm.posting_acct_key);
											}
											vm.triggerloading = true;
											vm.changePostingglerror();
										}
									});

				};

				function initMandatorySection() {

					vm.template = "";
					vm.templateLoading = false;
					vm.templateError = false;
					vm.tbbsError = false;
					vm.userm1 = "";
					vm.schdmt = "";

					// loadtemplatedata();

					vm.tempsystemaccount = [];
					vm.mainmodaldata = [];
					vm.tbbsacctdata = [];
					vm.schdmtypedata = [];
					vm.mainmodaldata_t = [];
					vm.tbbsacctdataMain = [];
					vm.mainExistingData = [];
					vm.currentTaxYear = GlobalService.globalParams.tax_year;
					vm.beginYear = '';
					vm.endYear = '';
					vm.beginYearError = false;
					vm.endYearError = false;
					vm.endYearErrormaxyear = false;
					vm.annual_adjustment_amount = 0;
					vm.annual_adjustment_amount_error = false;
					vm.posting_tac_key = "";
					vm.posting_acct_key = "";
					vm.schdmid = 0;

				}
				;

				function loadtemplatedata(posting_tac_key, posting_acct_key) {
					vm.mainmodaldata = [];
					vm.templateLoading = true;
					var params = {
						"action_code" : 'yz4utp',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"sys_acct" : rowData.CHART_KEY + "_" + posting_acct_key
								+ "-" + posting_tac_key

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.templateLoading = false;
										} else {
											vm.mainmodaldata_t = data.jsonObject;
											for ( var i in vm.mainmodaldata_t) {
												vm.tempsystemaccount
														.push(data.jsonObject[i].SCHDM_TEMPLATE);
											}
											vm.tempsystemaccount = _.uniq(
													vm.tempsystemaccount,
													JSON.stringify);
											vm.templateLoading = false;
										}
									});
				}
				;

				function getExistingRecurringTaxadj() {
					vm.mainExistingData = [];
					vm.main_existing_data_loading = true;
					var params = {
						"action_code" : 'i3i6d0',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"cc_key" : rowData.COMBINATION_KEY,
						"posting_acct_key" : rowData.POSTING_ACCT_KEY

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.main_existing_data_loading = false;
										} else {

											vm.mainExistingData = [];
											vm.mainExistingData = data.jsonObject;
											/*
											 * for ( var i in
											 * vm.mainmodaldata_t) {
											 * vm.tempsystemaccount
											 * .push(data.jsonObject[i].SCHDM_TEMPLATE); }
											 * vm.tempsystemaccount = _.uniq(
											 * vm.tempsystemaccount,
											 * JSON.stringify);
											 */
											vm.main_existing_data_loading = false;
											if (data.jsonObject.length == 0) {
												vm.noRecords = true;
											}
										}
									});
				}

				vm.loadAdjustment = function(_index) {
					vm.TYPE = 'E';
					var _data = [];
					var ref_key = 0;
					vm.posting_acct_key = _index.POSTING_ACCT_KEY;
					vm.posting_tac_key = _index.POSTING_TAC_KEY;

					loadtemplatedata(_index.POSTING_TAC_KEY,
							_index.POSTING_ACCT_KEY);
					loadschdmtype_temp();
					function loadschdmtype_temp() {
						var params = {
							"action_code" : 'jhsi7h',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"sys_acct" : rowData.CHART_KEY + "_"
									+ vm.posting_acct_key + "-"
									+ vm.posting_tac_key,
							"schdm_temp" : _index.SCHDM_TEMPLATE_ID,

						}

						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {
											if (data.callSuccess !== "1") {
												AlertService.add("error",
														data.errorMessage);
												vm.schdmloading = false;
												vm.hideschdmtype = false;
											} else {
												vm.schdmtypedata = data.jsonObject;
												vm.schdmtype = [];
												for ( var ij in data.jsonObject) {
													vm.schdmtype
															.push(data.jsonObject[ij].SCHDM_TYPE);
												}
												vm.schdmtype = _.uniq(
														vm.schdmtype,
														JSON.stringify)
												/*
												 * if (vm.type === false) {
												 * vm.schdmt =
												 * data.jsonObject[0].SCHDM_TYPE_DEFAULT; }
												 */
												vm.hideschdmtype = false;
												vm.schdmloading = false;

												loadtbbsdata_temp();
												function loadtbbsdata_temp() {
													var temp_schdm_type = "";
													for ( var ls in vm.schdmtypedata) {
														if (vm.schdmtypedata[ls].SCHDM_TYPE == _index.SCHDM_TYPE) {
															temp_schdm_type = vm.schdmtypedata[ls].SCHDM_TYPE_SAVE;
														}
													}
													var params = {
														"action_code" : '26k8ua',
														"tax_year" : GlobalService.globalParams.tax_year,
														"scenario" : GlobalService.globalParams.scenario,
														"jcd_key" : GlobalService.globalParams.jcd_key,
														"local_acct" : vm.posting_acct_key,
														"sys_acct" : vm.rowData.CHART_KEY,
														"schdm_temp" : _index.SCHDM_TEMPLATE_ID,
														'source_system' : 'D',
														"schdm_type" : temp_schdm_type,
														"combination_key" :vm.rowData.COMBINATION_KEY

													}

													return JsonObjectFactory
															.getJSONObj(
																	GENERAL_CONFIG.base_url
																			+ '/loadJsonObject',
																	params)
															.then(
																	function(
																			data) {

																		if (data.callSuccess !== "1") {
																			AlertService
																					.add(
																							"error",
																							data.errorMessage);
																			vm.hidetbbss = false;
																			vm.tbbsLoading = false;
																		} else {
																			vm.tbbsacct = [];
																			vm.tbbsacctdataMain = data.jsonObject;
																			vm.tbbsacctdata = data.jsonObject;
																			for ( var ii in vm.tbbsacctdata) {
																				vm.tbbsacct
																						.push(data.jsonObject[ii].TAX_STREAM_ACCT);
																			}
																			vm.tbbsacct = _
																					.uniq(
																							vm.tbbsacct,
																							JSON.stringify)
																			/*
																			 * if
																			 * (vm.type
																			 * ===
																			 * false) {
																			 * vm.tbbsacctval =
																			 * data.jsonObject[0].TAX_STREAM_ACCT_DEFAULT; }
																			 */
																			vm.hidetbbss = false;
																			vm.tbbsLoading = false;
																		}

																		if (vm.tbbsacct.length < 1) {
																			vm.hidetbbss = true;
																		}
																	});
												}
												if (vm.schdmtype.length < 1) {
													vm.hideschdmtype = true;
												}
											}
										});
					}

					for ( var e in vm.mainExistingData) {
						if (vm.mainExistingData[e].SCHDM_ID === _index.SCHDM_ID) {
							_data.push(vm.mainExistingData[e]);
						}
					}

					for ( var pgl in vm.postinggl_acct_M) {
						if (_index.POSTING_ACCT_KEY == vm.postinggl_acct_M[pgl].GL_ACCT_KEY) {
							vm.postingGL = vm.postinggl_acct_M[pgl].GL_ACCT;
							vm.callFun(vm.postingGL);
						}
					}
					vm.old_posting_acct_key = _index.POSTING_ACCT_KEY;
					vm.old_posting_tac_key = _index.POSTING_TAC_KEY;

					vm.old_template_id = _index.SCHDM_TEMPLATE_ID;
					vm.old_schdm_type = _index.SCHDM_TYPE;
					vm.old_tbbs_acct = _index.TBBS_ACCT_KEY
					vm.old_beginYear = _index.BEGIN_YEAR;
					vm.old_endYear = _index.END_YEAR;
					vm.schdmt = _index.SCHDM_TYPE;
					vm.old_schdmt = _index.SCHDM_TYPE;

					vm.beginYear = _index.BEGIN_YEAR;
					if (vm.beginYear < vm.currentTaxYear) {
						vm.readonlybeginyear = true;
					}
					vm.endYear = _index.END_YEAR;
					vm.annual_adjustment_amount = _index.ANNUAL_AMT;
					vm.old_annual_adjustment_amount = _index.ANNUAL_AMT
					vm.template = _index.SCHDM_DESC;
					vm.userm1 = _index.USER_DESC;
					vm.old_userm1_desc = vm.userm1;

					vm.tbbsacctval = _index.TBBS_ACCT + '-'
							+ _index.TBBS_ACCT_DESC;
					vm.schdmid = _index.SCHDM_ID;

					// vm.changeTemplate();
					/*
					 * for ( var tt in vm.mainmodaldata_t) { if
					 * (_index.SCHDM_TEMPLATE_ID ==
					 * vm.mainmodaldata_t[tt].SCHDM_TEMPLATE_ID) { vm.template =
					 * vm.mainmodaldata_t[tt].SCHDM_TEMPLATE; } }
					 */

					/*
					 * for ( var st in vm.schdmtypedata) { if (_index.SCHDM_TYPE ==
					 * vm.schdmtypedata[st].SCHDM_TYPE_SAVE) { vm.schdmt =
					 * vm.schdmtypedata[st].SCHDM_TYPE; } }
					 */
					/*
					 * for ( var tb in vm.tbbsacctdataMain) { if
					 * (_index.TBBS_ACCT_KEY ==
					 * vm.tbbsacctdataMain[tb].TAX_STREAM_ACCT_SAVE) {
					 * vm.tbbsacctval = vm.tbbsacctdataMain[tb].TAX_STREAM_ACCT; } }
					 */
					vm.viewAdjustmentScheduleArray = [];
					vm.canViewAdjustmentSchedule = true;
					for ( var ayear in _data) {
						vm.bottomyearreadonly = false;
						if (_data[ayear].ADJ_TAX_YEAR < vm.currentTaxYear) {
							vm.bottomyearreadonly = true;
						}
						vm.viewAdjustmentScheduleArray.push({
							"year" : _data[ayear].ADJ_TAX_YEAR,
							value : _data[ayear].ADJ_AMT,
							"old_year" : _data[ayear].ADJ_TAX_YEAR,
							"old_value" : _data[ayear].ADJ_AMT,
							"readonlyflag" : vm.bottomyearreadonly
						});

					}
					checktemplateError();
					checktypeError();
					vm.tbbsChange();
					vm.annual_adjustment_amount_change();
					if (_index.VALIDATION_CHECK_FLAG === 'N') {
						if (vm.beginYear && (vm.beginYear >= vm.currentTaxYear)) {
							vm.beginYearError = false;
						} else {
							vm.beginYearError = true;
						}
					}
					if (vm.endYear && (vm.endYear >= vm.beginYear)) {
						vm.endYearError = false;
						if(vm.endYear>vm.beginYear + 50)
						{
						vm.endYearErrormaxyear = true;
						}
					else
						{
						vm.endYearErrormaxyear = false;
						
						}
					} else {
						vm.endYearError = true;
						vm.endYearErrormaxyear = false;
					}
				}

				/*
				 * vm.changeTemplate = function() { vm.schdmt = "";
				 * vm.tbbsacctval = ""; loadschdmtype(); loadtbbsdata();
				 * vm.userm1 = vm.template; checktemplateError(); };
				 */
				function loadtbbsdata() {

					vm.hidetbbss = false;
					vm.tbbsLoading = true;
					var schdm_temp_tbbs = "";
					vm.tbbsacct = [];
					vm.tbbsacctdata = [];

					for ( var a in vm.mainmodaldata_t) {
						if (vm.mainmodaldata_t[a].SCHDM_TEMPLATE == vm.template) {
							schdm_temp_tbbs = vm.mainmodaldata_t[a].SCHDM_TEMPLATE_ID;
						}
					}

					var temp_schdm_type = "";
					for ( var ll in vm.schdmtypedata) {
						if (vm.schdmtypedata[ll].SCHDM_TYPE == vm.schdmt) {
							temp_schdm_type = vm.schdmtypedata[ll].SCHDM_TYPE_SAVE;
						}
					}

					var params = {
						"action_code" : '26k8ua',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"local_acct" : vm.posting_acct_key,
						"sys_acct" : vm.rowData.CHART_KEY,
						"schdm_temp" : schdm_temp_tbbs,
						'source_system' : 'D',
						"schdm_type" : temp_schdm_type,
						"combination_key":vm.rowData.COMBINATION_KEY

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.hidetbbss = false;
											vm.tbbsLoading = false;
										} else {
											vm.tbbsacctdataMain = data.jsonObject;
											vm.tbbsacctdata = data.jsonObject;
											for ( var i in vm.tbbsacctdata) {
												vm.tbbsacct
														.push(data.jsonObject[i].TAX_STREAM_ACCT);
											}
											vm.tbbsacct = _.uniq(vm.tbbsacct,
													JSON.stringify)
											/*
											 * if (vm.type === false) {
											 * vm.tbbsacctval =
											 * data.jsonObject[0].TAX_STREAM_ACCT_DEFAULT; }
											 */
											vm.hidetbbss = false;
											vm.tbbsLoading = false;
										}

										if (vm.tbbsacct.length < 1) {
											vm.hidetbbss = true;
										}
									});
				}
				;

				function loadschdmtype() {

					var schdm_temp = "";
					vm.hideschdmtype = false;
					vm.schdmloading = true;

					for ( var a in vm.mainmodaldata_t) {
						if (vm.mainmodaldata_t[a].SCHDM_TEMPLATE == vm.template) {
							schdm_temp = vm.mainmodaldata_t[a].SCHDM_TEMPLATE_ID;
						}
					}

					var params = {
						"action_code" : 'jhsi7h',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"sys_acct" : rowData.CHART_KEY + "_"
								+ vm.posting_acct_key + "-"
								+ vm.posting_tac_key,
						"schdm_temp" : schdm_temp,

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.schdmloading = false;
											vm.hideschdmtype = false;
										} else {
											vm.schdmtypedata = data.jsonObject;
											vm.schdmtype = [];
											for ( var i in data.jsonObject) {
												vm.schdmtype
														.push(data.jsonObject[i].SCHDM_TYPE);
											}
											vm.schdmtype = _.uniq(vm.schdmtype,
													JSON.stringify)
											/*
											 * if (vm.type === false) {
											 * vm.schdmt =
											 * data.jsonObject[0].SCHDM_TYPE_DEFAULT; }
											 */
											vm.hideschdmtype = false;
											vm.schdmloading = false;
											if (vm.schdmtype.length < 1) {
												vm.hideschdmtype = true;
											}
										}
									});
				}
				;

				vm.changeTemplate = function() {
					vm.schdmt = "";
					vm.tbbsacctval = "";
					loadtbbsdata();
					loadschdmtype();
					for ( var e in vm.mainmodaldata_t) {
						if (vm.mainmodaldata_t[e].SCHDM_TEMPLATE == vm.template) {
							vm.userm1 = vm.mainmodaldata_t[e].USER_DESC;
						}
					}
					checktemplateError();
				};

				vm.typeChange = function() {

					vm.tbbsacctval = "";
					loadtbbsdata();
					checktypeError();
				}

				function checktypeError() {
					if (vm.schdmt === "" || vm.schdmt === undefined) {
						vm.typeError = true;
					} else {
						vm.typeError = false;
					}

				}
				;

				vm.tbbsChange = function() {
					if (vm.tbbsacctval === "" || vm.tbbsacctval === undefined) {
						vm.tbbsError = true;
					} else {
						vm.tbbsError = false;
					}

				}
				function checktemplateError() {
					if (vm.template === "" || vm.template === undefined) {
						vm.templateError = true;
					} else {
						vm.templateError = false;
					}
					vm.m1Desc();
				}
				;

				vm.changePostingglerror = function() {
					if (vm.postingGL === "" || vm.postingGL === undefined) {
						vm.postingGLError = true;
					} else {
						vm.postingGLError = false;
					}
				}

				vm.m1Desc = function() {
					if (vm.userm1 === "" || vm.userm1 === undefined) {
						vm.userDescError = true;
					} else {
						vm.userDescError = false;
					}

				};
				vm.beginYearChange = function() {
					if (vm.beginYear && (vm.beginYear >= vm.currentTaxYear)) {
						vm.beginYearError = false;
					} else {
						vm.beginYearError = true;
					}

					setupViewAdjustmentSchedule();
				};
				vm.endYearChange = function() {
					if (vm.endYear && (vm.endYear >= vm.beginYear) ) {
						vm.endYearError = false;
						if(vm.endYear>vm.beginYear + 50)
							{
							vm.endYearErrormaxyear = true;
							}
						else
							{
							vm.endYearErrormaxyear = false;
							
							}
					} else {
						vm.endYearError = true;
						vm.endYearErrormaxyear = false;
					}

					setupViewAdjustmentSchedule();
				};

				vm.annual_adjustment_amount_change = function() {

					if (vm.annual_adjustment_amount === ""
							|| vm.annual_adjustment_amount === undefined) {
						vm.annual_adjustment_amount_error = true;
					} else {
						vm.annual_adjustment_amount_error = false;
					}

					/*
					 * for (var i = 0; i <= (vm.endYear - vm.beginYear); i++) {
					 * vm.viewAdjustmentScheduleArray[i].value =
					 * ((vm.viewAdjustmentScheduleArray[i].value !=
					 * vm.annual_adjustment_amount) &&
					 * !vm.viewAdjustmentScheduleArray[i].value) ?
					 * vm.annual_adjustment_amount :
					 * vm.viewAdjustmentScheduleArray[i].value; ; }
					 */
				};

				function initViewAdjustmentScheduleSection() {
					vm.canViewAdjustmentSchedule = false;
					vm.viewAdjustmentScheduleArray = [];
				}
				;

				function setupViewAdjustmentSchedule() {
					vm.canViewAdjustmentSchedule = (vm.endYear - vm.beginYear) >= 0 ? true
							: false;
					vm.viewAdjustmentScheduleArray = [];
					if (vm.canViewAdjustmentSchedule) {
						createViewAdjustmentScheduleArray();
					}
				}
				;
				function createViewAdjustmentScheduleArray() {
					for (var i = vm.beginYear; i <= vm.endYear; i++) {
						vm.bottomyearreadonly = false;
						if (i < vm.currentTaxYear) {
							vm.bottomyearreadonly = true;
						}
						vm.viewAdjustmentScheduleArray.push({
							"year" : i,
							value : vm.annual_adjustment_amount,
							"readonlyflag" : vm.bottomyearreadonly
						});
					}
				}
				;

				vm.applyButton = function(index) {
					console.log(index);
					vm.viewAdjustmentScheduleArray[index].value = vm.annual_adjustment_amount;
				};

				vm.reset = function() {
					init();
				};

				vm.save = function() {
					if (isValidated()) {
						prepareData(vm.TYPE);

						if ($scope.crudLoading) {
							AlertService.add("info",
									"Please wait while we save this request",
									4000);
							return;
						}

						$scope.crudLoading = true;

						recurringMultiYearTaxAdjustmentService
								.saveRecurringMultiYearTaxAdjustments(
										vm.saveDataArray)
								.then(
										function(response) {
											console.log(response);
											if (response.errorMessage
													&& response.errorMessage !== 'null') {
												AlertService.add("error",
														response.errorMessage,
														4000);
												$scope.crudLoading = false;
												return null;
											}
											AlertService
													.add(
															"success",
															"Data saved for Recurring Multiyear Tax Adjustments",
															4000);

											var args = {
												combination_key : vm.rowData.COMBINATION_KEY,
												gridFilter : {
													combination_key : vm.rowData.COMBINATION_KEY
												}
											};
											$rootScope
													.$emit('gridUpdate', args);
											$uibModalInstance.dismiss('cancel');
										},
										function(error) {
											console.log(error);
											AlertService
													.add(
															"error",
															'Service Failed: contact helpdesk ',
															4000);
											$scope.crudLoading = true;
										});
					}

				};

				function isValidated() {

					if (vm.postingGL) {
						vm.postingGLError = false;
					} else {
						vm.postingGLError = true;
					}
					if (vm.template) {
						vm.templateError = false;
					} else {
						vm.templateError = true;
					}
					if (vm.tbbsacctval) {
						vm.tbbsError = false;
					} else {
						vm.tbbsError = true;
					}
					if (vm.userm1) {
						vm.userDescError = false;
					} else {
						vm.userDescError = true;
					}
					if (vm.schdmt) {
						vm.typeError = false;
					} else {
						vm.typeError = true;
					}
					if (vm.beginYear) {
						vm.beginYearError = false;
					} else {
						vm.beginYearError = true;
					}
					if (vm.endYear) {
						vm.endYearError = false;
					} else {
						vm.endYearError = true;
					}
					if (vm.annual_adjustment_amount) {
						vm.annual_adjustment_amount_error = false;
					} else {
						vm.annual_adjustment_amount_error = true;
					}
					if (vm.templateError || vm.userDescError || vm.typeError
							|| vm.beginYearError || vm.endYearError
							|| vm.annual_adjustment_amount_error
							|| vm.tbbsError || vm.postingGLError
							|| vm.postingGLError) {
						return false;
					}

					return true;
				}

				function prepareData() {
					vm.saveDataArray = [];
					for (var i = 0; i <= (vm.endYear - vm.beginYear); i++) {
						vm.saveDataArray.push(getSaveDataObject(i));
					}
				}

				function getSaveDataObject(i) {
					if (vm.TYPE === 'I') {
						var modalT_key = "";
						var tbbs_key = "";
						var posting_acct_key = "";
						var schdm_type_key = "";
						for ( var t in vm.mainmodaldata_t) {
							if (vm.mainmodaldata_t[t].SCHDM_TEMPLATE == vm.template) {
								modalT_key = vm.mainmodaldata_t[t].SCHDM_TEMPLATE_ID;
							}
						}

						for ( var tb in vm.tbbsacctdataMain) {
							if (vm.tbbsacctdataMain[tb].TAX_STREAM_ACCT == vm.tbbsacctval) {
								tbbs_key = vm.tbbsacctdataMain[tb].TAX_STREAM_ACCT_SAVE;
							}
						}
						for ( var pt in vm.postinggl_acct_M) {
							if (vm.postinggl_acct_M[pt].GL_ACCT == vm.postingGL) {
								posting_acct_key = vm.postinggl_acct_M[pt].GL_ACCT_KEY;
							}
						}

						for ( var z in vm.schdmtypedata) {
							if (vm.schdmtypedata[z].SCHDM_TYPE == vm.schdmt) {
								schdm_type_key = vm.schdmtypedata[z].SCHDM_TYPE_SAVE
							}
						}
						return {
							"TYPE" : 'I',
							"REF_KEY" : vm.rowData.REFERENCE_KEY,
							"TAX_YEAR" : GlobalService.globalParams.tax_year,
							"SCENARIO" : GlobalService.globalParams.scenario,
							"JCD_KEY" : GlobalService.globalParams.jcd_key,

							"OLD_CHART_KEY" : vm.rowData.CHART_KEY,
							"NEW_CHART_KEY" : vm.rowData.CHART_KEY,
							"OLD_TEMPLATE" : modalT_key,
							"NEW_Template" : modalT_key,
							"OLD_USER_M1_DESCRIPTION" : vm.userm1,
							"NEW_USER_M1_DESCRIPTION" : vm.userm1,
							"OLD_SCHDM_TYPE" : schdm_type_key,
							"NEW_SCHDM_TYPE" : schdm_type_key,
							"OLD_TBBS_ACCT_KEY" : tbbs_key,
							"NEW_TBBS_ACCT_KEY" : tbbs_key,
							"SOURCE_SYSTEM_FLAG" : 'D',
							// "CHART_KEY" : vm.rowData.CHART_KEY,
							"LOCAL_ACCT_KEY" : vm.rowData.POSTING_ACCT_KEY,
							"NEW_COMBINATION_KEY" : vm.rowData.COMBINATION_KEY,
							"OLD_COMBINATION_KEY" : vm.rowData.COMBINATION_KEY,
							"OLD_BEGIN_YEAR" : vm.beginYear,
							"NEW_BEGIN_YEAR" : vm.beginYear,
							"OLD_END_YEAR" : vm.endYear,
							"NEW_END_YEAR" : vm.endYear,
							"OLD_TAX_YEAR" : vm.viewAdjustmentScheduleArray[i].year,
							"NEW_TAX_YEAR" : vm.viewAdjustmentScheduleArray[i].year,
							"OLD_ADJ_AMOUNT" : vm.viewAdjustmentScheduleArray[i].value,
							"NEW_ADJ_AMOUNT" : vm.viewAdjustmentScheduleArray[i].value,
							"OLD_ANNUAL_AMT" : vm.annual_adjustment_amount,
							"NEW_ANNUAL_AMT" : vm.annual_adjustment_amount,
							"OLD_POSTING_ACCT_KEY" : posting_acct_key,
							"NEW_POSTING_ACCT_KEY" : posting_acct_key,
							"NEW_POSTING_TAC_KEY" : vm.posting_tac_key,
							"OLD_POSTING_TAC_KEY" : vm.posting_tac_key,
							"SCHDM_ID" : vm.schdmid
						};
					} else if (vm.TYPE === 'E') {

						var modalT_key = "";
						var tbbs_key = "";
						var posting_acct_key = "";
						var schdm_type_key = "";
						var schdm_type_key_old = "";
						for ( var t in vm.mainmodaldata_t) {
							if (vm.mainmodaldata_t[t].SCHDM_TEMPLATE == vm.template) {
								modalT_key = vm.mainmodaldata_t[t].SCHDM_TEMPLATE_ID;
							}
						}

						for ( var tb in vm.tbbsacctdataMain) {
							if (vm.tbbsacctdataMain[tb].TAX_STREAM_ACCT == vm.tbbsacctval) {
								tbbs_key = vm.tbbsacctdataMain[tb].TAX_STREAM_ACCT_SAVE;
							}
						}
						for ( var pt in vm.postinggl_acct_M) {
							if (vm.postinggl_acct_M[pt].GL_ACCT == vm.postingGL) {
								posting_acct_key = vm.postinggl_acct_M[pt].GL_ACCT_KEY;
							}
						}

						for ( var z in vm.schdmtypedata) {
							if (vm.schdmtypedata[z].SCHDM_TYPE == vm.schdmt) {
								schdm_type_key = vm.schdmtypedata[z].SCHDM_TYPE_SAVE
							}
						}
						for ( var zz in vm.schdmtypedata) {
							if (vm.schdmtypedata[zz].SCHDM_TYPE == vm.old_schdmt) {
								schdm_type_key_old = vm.schdmtypedata[zz].SCHDM_TYPE_SAVE
							}
						}
						if (modalT_key == null || modalT_key == undefined
								|| tbbs_key == null || tbbs_key == undefined
								|| posting_acct_key == null
								|| posting_acct_key == undefined
								|| schdm_type_key == null || schdm_type_key == undefined ) {
							AlertService.add("danger",
									"Please correct the errors below", 4000);
							$scope.crudLoading = false;
							throw new ("This is not an error. This is just to abort javascript!");
						}
						return {

							"TYPE" : 'E',
							"REF_KEY" : vm.rowData.REFERENCE_KEY,
							"TAX_YEAR" : GlobalService.globalParams.tax_year,
							"SCENARIO" : GlobalService.globalParams.scenario,
							"JCD_KEY" : GlobalService.globalParams.jcd_key,
							"OLD_TEMPLATE" : vm.old_template_id,
							"NEW_Template" : modalT_key,
							/*
							 * "OLD_TEMPLATE_ID" :vm.old_template_id,
							 * "NEW_TEMPLATE_ID" : '',
							 */
							"OLD_USER_M1_DESCRIPTION" : vm.old_userm1_desc,
							"NEW_USER_M1_DESCRIPTION" : vm.userm1,
							"OLD_SCHDM_TYPE" : schdm_type_key_old,
							"NEW_SCHDM_TYPE" : schdm_type_key,
							/*
							 * "SOURCE_SYSTEM_FLAG" : (vm.rowData.SOURCE_SYSTEM
							 * === 'DCS') ? 'D' : 'F',
							 */
							"OLD_TBBS_ACCT_KEY" : vm.old_tbbs_acct,
							"NEW_TBBS_ACCT_KEY" : tbbs_key,
							"SOURCE_SYSTEM_FLAG" : 'D',
							"OLD_CHART_KEY" : vm.rowData.CHART_KEY,
							"NEW_CHART_KEY" : vm.rowData.CHART_KEY,
							"LOCAL_ACCT_KEY" : vm.rowData.POSTING_ACCT_KEY,
							"NEW_COMBINATION_KEY" : vm.rowData.COMBINATION_KEY,
							"OLD_COMBINATION_KEY" : vm.rowData.COMBINATION_KEY,
							"OLD_BEGIN_YEAR" : vm.old_beginYear,
							"NEW_BEGIN_YEAR" : vm.beginYear,
							"OLD_END_YEAR" : vm.old_endYear,
							"NEW_END_YEAR" : vm.endYear,
							"OLD_TAX_YEAR" : vm.viewAdjustmentScheduleArray[i].old_year,
							"NEW_TAX_YEAR" : vm.viewAdjustmentScheduleArray[i].year,
							"OLD_ADJ_AMOUNT" : vm.viewAdjustmentScheduleArray[i].old_value,
							"NEW_ADJ_AMOUNT" : vm.viewAdjustmentScheduleArray[i].value,
							"OLD_ANNUAL_AMT" : vm.old_annual_adjustment_amount,
							"NEW_ANNUAL_AMT" : vm.annual_adjustment_amount,
							"OLD_POSTING_ACCT_KEY" : vm.old_posting_acct_key,
							"NEW_POSTING_ACCT_KEY" : posting_acct_key,
							"NEW_POSTING_TAC_KEY" : vm.posting_tac_key,
							"OLD_POSTING_TAC_KEY" : vm.old_posting_tac_key,
							"SCHDM_ID" : vm.schdmid

						};
					}
				}
			}
			;
		});
