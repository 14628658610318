
define([
    'angular'

], function () {
    'use strict';
var controllers =  angular.module('app.wfTrailBalCtrl',[])
.controller('workfLowTrailBalCtrl', ['$scope','$rootScope','$state','$log','GridFactory','AlertService','$timeout','$parse', 'GENERAL_CONFIG', workfLowTrailBalCtrl])
function workfLowTrailBalCtrl($scope,$rootScope,$state,$log,GridFactory,AlertService,$timeout, $parse,GENERAL_CONFIG) {

    var vm = this;
    vm.refresh = function(){
        getData();
    };

    var getData = function() {
        $scope.$broadcast("showLoader", true);
        vm.loading = true;
        vm.gridData = [];
        vm.gridData.DATA = [];
      //  var url = {"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=79&grid_id=33","grid_id":"33"};
      var url = {"get": GENERAL_CONFIG.base_url+"/emptyGridJson?grid_id=106","save":GENERAL_CONFIG.base_url+"/gridBatchImportValidation?action_id=382"}


        GridFactory.getDataCustom(url.get, vm.filter).then(function (data) {
            if (data.callSuccess === "1") {
                $scope.$broadcast("dataLoaded", data);
                $scope.$broadcast("showLoader", false);
            } else {
                AlertService.add("error", data.errorMessage);
            }
        });
    }

    getData();
}

return controllers;

});
