define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.sltAddDataserviceController', [])
    .controller('sltAddDataserviceController', ['$rootScope', '$scope', '$state',
        '$timeout', '$uibModal', 'USER_SETTINGS', 'sltAddDataServiceFactory', 'GENERAL_CONFIG', 'GlobalService', '$uibModalInstance',
        'rowData', 'ObjectDiff', 'gridData', 'AlertService', 'JsonObjectFactory', 'ClientAdminFactory', sltAddDataserviceController])

    function sltAddDataserviceController($rootScope, $scope, $state, $timeout, $uibModal, USER_SETTINGS,
        sltAddDataServiceFactory, GENERAL_CONFIG, GlobalService, $uibModalInstance, rowData, ObjectDiff, gridData, 
        AlertService, JsonObjectFactory, ClientAdminFactory) {
        
        var vm = this;
        vm.row = rowData;
        vm.clients = [];//gridData.clients;
        // vm.clients.sort((a,b) => a.id - b.id);
        vm.selectedClients = [];
        vm.selectedClientKeys = [];
        vm.saving = false;
        vm.title = 'Add/Edit Data Service';
        vm.clientChanged = false;
        vm.options = [
            {name: '---SELECT---', value:''},
            {name: 'Procedure Call', value: 'proc_call'},
            {name: 'Stored/Fixed Output', value: 'use_stored_output'}
        ];
        vm.serviceData = {
            serviceKey : rowData ? rowData.SERVICE_KEY : 0,
            serviceName: rowData ? rowData.SERVICE_NAME: '',
            recordLimit: rowData ? rowData.RECORD_LIMIT: '100000',
            serviceOutputDef: rowData ? (rowData.USE_STORED_OUTPUT === 1 ? 'use_stored_output' : 'proc_call') : '',
            proc_call: rowData ? rowData.PROC_CALL: '',
            proc_input: rowData ? rowData.PROC_PARAMS_IN: '',
            proc_output: rowData ? rowData.PROC_PARAMS_OUT: 'resultset:OracleTypes.CURSOR,retval:OracleTypes.VARCHAR,error_out:OracleTypes.VARCHAR',
            service_output: rowData ? rowData.SERVICE_OUTPUT: '',
            service_access_sso : '',
            selectedClients : []
        };
        vm.multiselDropdownSettings = {
            buttonClasses: 'mul-select-btn',
            showCheckAll: true,
            showUncheckAll: true,
            checkBoxes: true,
            displayProp: 'name',
            smartButtonMaxItems: 1,
            scrollable: true,
            scrollableHeight: 'auto',
        };

        vm.mulSelectCustomTexts = {
            buttonDefaultText: 'Select client',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };
        vm.multiselectEvents = {
            onSelectionChanged: function () {
                vm.selectedClientKeys = [];
                vm.serviceData.selectedClients.forEach(i => vm.selectedClientKeys.push(parseInt(i.id)));
                vm.clientChanged = true;
                // if (vm.dataServiceForm) {
                //     vm.dataServiceForm.$setPristine();
                // }
            },
        };

        vm.isStoredOutput = function() {
            return vm.serviceData.serviceOutputDef === 'use_stored_output';
        }

        vm.isProcCall = function() {
            return vm.serviceData.serviceOutputDef === 'proc_call';
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function(){
            if (vm.dataServiceForm.$invalid) {
                return;
            }
            if(vm.selectedClientKeys.length === 0){
                if(rowData) {
                    vm.selectedClientKeys.push(parseInt(rowData.CLIENT_KEY));
                } else {
                    AlertService.add("error", 'Please select atleast one client to create a data service API.', 4000);
                    return;
                }
            }
            vm.saving = true;
            let params =  {
                serviceKey : vm.serviceData.serviceKey,
                serviceName: vm.serviceData.serviceName,
                recordLimit: vm.serviceData.recordLimit,
                proc_call: vm.serviceData.proc_call,
                proc_input: vm.serviceData.proc_input,
                proc_output: vm.isStoredOutput() ? '' : vm.serviceData.proc_output,
                use_stored_output: vm.isStoredOutput() ? 1 : 0,
                service_output: vm.serviceData.service_output,
                clients: Object.values(vm.selectedClientKeys).join(","),
                service_access_sso: vm.serviceData.service_access_sso
            };
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vzpu0u";
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data service saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                    // $uibModalInstance.close(true);
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };

        vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach(res.data.clientResult.clientList, function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
                vm.clients.sort((a,b) => a.id - b.id);
                if(rowData && rowData.SERVICE_KEY) {
                    vm.getDataServiceClients();
                }
            });
        };

        vm.reset = function () {
            vm.serviceData.serviceKey = rowData ? rowData.SERVICE_KEY : '',
            vm.serviceData.serviceName = rowData ? rowData.SERVICE_NAME: '',
            vm.serviceData.recordLimit = rowData ? rowData.RECORD_LIMIT: '100000',
            vm.serviceData.serviceOutputDef = rowData ? (rowData.USE_STORED_OUTPUT === 1 ? 'use_stored_output' : 'proc_call') : '',
            vm.serviceData.proc_call = rowData ? rowData.PROC_CALL: '',
            vm.serviceData.proc_input = rowData ? rowData.PROC_PARAMS_IN: '',
            vm.serviceData.proc_output = rowData ? rowData.PROC_PARAMS_OUT: '',
            vm.serviceData.service_output = rowData ? rowData.SERVICE_OUTPUT: '',
            vm.serviceData.service_access_sso = '',
            vm.serviceData.selectedClients = vm.selectedClients;
            if (vm.dataServiceForm) {
                vm.dataServiceForm.$setPristine();
            }
        };

        vm.getDataServiceClients = function(){
            vm.loading = true;
            return sltAddDataServiceFactory.getDataServiceClients(vm.row.SERVICE_KEY).then(function (data) {
                if(data.callSuccess == "1" ){
                    let responseClients = [];
                    if(vm.row.USE_STORED_OUTPUT == 1 && data.jsonObject && data.jsonObject.length > 0) {
                        vm.serviceData.service_output = data.jsonObject[0].SERVICE_OUTPUT;
                    }
                    data.jsonObject.forEach(i => responseClients.push(i.CLIENT_KEY));
                    responseClients.forEach(i => {
                        let c = vm.clients.filter(j => parseInt(j.id) === parseInt(i));
                        if(c && c.length > 0) {
                            vm.serviceData.selectedClients.push(c[0]);
                            vm.selectedClients.push(c[0]);
                            vm.selectedClientKeys.push(i);
                        }
                    });
                    vm.loading = false;
                }else{
                    vm.loading = false;
                    AlertService.add("error", data.data.message, 4000);
                }
            });
        };

        if(rowData && rowData.SERVICE_KEY) {
            vm.getClientList();
            // vm.getDataServiceClients();
        } else {
            vm.selectedClientKeys = [];
            vm.selectedClients = [];
            vm.serviceData.selectedClients = [];
            vm.getClientList();
        }
        
    }


    return controllers;
});
