define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.taxLiabilityDashboardController', [])
					.controller('taxLiabilityDashboardControllerModal', ['CommonUtilitiesFactory', '$filter','$scope','$rootScope',  'ModalFactory', 'AlertService', '$timeout',
						'GlobalService','TaxLiabilityDashboardFactory', 'JsonObjectFactory','GENERAL_CONFIG', '$uibModalInstance','workspaceFactory',
						'rowData', 'colData', 'gridData',  taxLiabilityDashboardControllerModal])


			function taxLiabilityDashboardControllerModal(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
			GlobalService,TaxLiabilityDashboardFactory, JsonObjectFactory, GENERAL_CONFIG, $uibModalInstance,workspaceFactory, rowData,colData,gridData,thisObj) {
				

				var vm = this || thisObj;
				$scope.form = {
						divForm : {}
					};
				vm.editRecords=[];
				vm.editTotalRecords= [];
				vm.selectedGroup= {};
				vm.gridData = [];
				vm.rowData = rowData;
				vm.colData = colData;
				$scope.objects = [];
				vm.taxesAmount = null;
				vm.description = null;
				vm.adjustment = null;
				vm.statusFlag = false;
				vm.radioUnblk ='Y';
				workspaceFactory.activeScreen.data.data;
				vm.modalTitle = "Change Status";
				vm.title = "Tax Liability Amount";
				vm.taxableType = '';
				vm.currencyType = 'FC';
			    vm.editList = [];
			    vm.addNewFlag = false;
				vm.ftrRelatesToList = null;
				vm.descriptionDateProvided = null;				
				if ($uibModalInstance) {
					vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name: null;
					vm.cancel = function() {
						var args = {};
			            // $rootScope.broadcast("gridRefresh", {});
			           // $rootScope.$emit('gridUpdate', args);
			            $uibModalInstance.dismiss('cancel');
						$uibModalInstance.close();
						vm.modal_name = null;
					};
				} else {
					vm.modal_name = null;
				}
			   
			    vm.reset = function () {
				vm.resetFlag = true;
                //init();
                getModelPopup();
                  };
					
					vm.calendar = {
	                        opened: [false]
	                };
	                
	                vm.editcalendar = {
	                        opened: [false]
	                };
                  //vm.openCalendar = function(index) {
						  // vm.calendar.opened = true;
						//};
					
					vm.openCalendar = function (index) {
							vm.calSet = $scope.objects.length+1;
					        for (var i = vm.calSet-1; i >= 0; i--) {
					            if (i === index) {
					            	 vm.calendar.opened[i] = true;
					            	 break;
					            } else {
					            	 vm.calendar.opened[i] = false;
					            }
					        }
					    }
					    
					vm.openEditCalendar = function (index) {
							vm.calSetEdit = vm.editRecords.length+1;
					        for (var i = vm.calSetEdit-1; i >= 0; i--) {
					            if (i === index) {
					            	 vm.editcalendar.opened[i] = true;
					            	 break;
					            } else {
					            	 vm.editcalendar.opened[i] = false;
					            }
					        }
					    }    
						 
				vm.changeStatus = function(status){
					vm.radioUnblk = status;
				}
				
				vm.saveStatus = function() {
					var finalData = [];
					if(vm.entities.length) {
						for(var i=0; i< vm.entities.length; i++) {
							finalData.push(
								{
									combination_key:vm.entities[i].data.COMBINATION_KEY,
									status: vm.radioUnblk
								}
							)
						}
					}
					var message = "Status is saved successfully";
					//console.log(finalData);
					return TaxLiabilityDashboardFactory.saveStatus(finalData).then(
							function(response) {
								if (response.errorMessage
										&& response.errorMessage !== 'null') {
									AlertService.add("error",
											response.errorMessage, 4000);
								} else {
									AlertService.add("success", message, 4000);	
								}
								var args = {TAX_YEAR: vm.entities[0].data.TAX_YEAR, gridFilter: {TAX_YEAR: vm.entities[0].data.TAX_YEAR}};
                                $rootScope.$emit('gridUpdate', args);
                                $uibModalInstance.dismiss('cancel');
							}
							)
				}
				
				function init() {
					vm.dataLoading = true;
					if(ModalFactory.response.data.modalScreen.modal_name === 'changeStatusTlc'){
						// vm.rowData = rowData || $scope.$parent.rowData;//{};
						  if (typeof rowData !== 'undefined') {
		                              vm.rowData = rowData;
		                  }
		                  vm.entities  = _.clone(gridData);
		                  
		                  for(var i=0; i <vm.entities.length; i++) {
							var tax_year = vm.entities[i].data.TAX_YEAR;
							var combination_key = vm.entities[i].data.COMBINATION_KEY;
							var status = vm.entities[i].data.STATUS;
							   };
							   
							//console.log('status',vm.statusData);
		                  //vm.radioUnblk = vm.entities.data.STATUS;
		                  return TaxLiabilityDashboardFactory.getStatus(tax_year, combination_key).then(
		  						function(result) {
		  							//console.log(result);
		  							vm.radioUnblk = status;
		  						});
					 }
					else{
					vm.title = 'Tax Liability Amount';
					vm.editRecords=[];
					vm.rowData = rowData || $scope.$parent.rowData;
					if(!rowData.STATUS||rowData.STATUS == 'Open'){
						rowData.STATUS = 'Open';
						vm.statusFlag = false;
					}else
						{
						vm.statusFlag = true;
						}
				    getModelPopup();
					
				    }
					
				}
				
				function getModelPopup(){
					var colMap = gridData.colData.map; 
                    colMap = gridData.rowData[gridData.colData.map + '_TRANS_TYPE_KEY'];
                    vm.showData = colMap
                    vm.currOverrideFlag = vm.rowData.CURR_OVERIDE_FLAG
					//getCurrencies();
					//getDescription();
					//getAdjustment();
					//vm.assignTaxable(gridData.colData);
					return TaxLiabilityDashboardFactory.getTaxLiability(rowData.TAX_YEAR, rowData.COMBINATION_KEY,rowData.GROUP_ID).then(
						function(response) {
							
							//console.log(response);
                            var resTransTypeKey = colMap/*gridData.colData.map*/;
                           
							vm.filteredEntities=_.filter(response,{TRANS_TYPE_KEY:resTransTypeKey});
							vm.filterLength = vm.filteredEntities === undefined?0:vm.filteredEntities.length;
                            //console.log("filter data",vm.filteredEntities)
							var minRows=5;
							if(response){
								vm.editRecords=response;
								getCurrencies();
								for(var i=0;i<response.length;i++){
									var res = response[i];
									res.TRANS_DATE = res.TYPE == 'STANDALONE'? new Date(res.TRANS_DATE) : res.TRANS_DATE
									res.EDIT_FLAG = true;							
									vm.filterLength = vm.filterLength+1;
									//=vm.addNew(res,res.FC_AMT,res.TRANS_DATE,res.DETAIL_COMMENTS,true,res.UPDATED_BY,res.UPDATED_ON.substring(0,10),res.TRANS_DETAILS_KEY);
									//minRows=minRows-1;
								}
								if($scope.objects.length && vm.resetFlag == true) {
								/*for(var i=0; i< $scope.objects.length; i++) {
											//$scope.objects[i].descriptionDesc.CODE_DESC = null;
											//$scope.objects[i].adjustmentDesc.CODE_DESC = null;
											$scope.objects[i].dateFiled = null;
											//$scope.objects[i].descriptionDesc.CODE = null;
											$scope.objects[i].taxesAmount = null;
											$scope.objects[i].usrcomments = null;
											$scope.objects[i].transDetailsKey = null;
											$scope.objects[i].invalidDateFlag = false;
											$scope.objects[i].invalidDateOpenFlag = false;
											$scope.objects[i].descFlag = false;
											$scope.objects[i].adjustFlag = false;
											$scope.objects[i].dateFlag = false;
									}*/
									$scope.objects = angular.copy(vm.OnLoadObjData);
								}
							}else
							{  
								vm.disableTable = true;
								if($scope.objects.length && vm.resetFlag == true) {
								/*for(var i=0; i< $scope.objects.length; i++) {
											//$scope.objects[i].descriptionDesc.CODE_DESC = null;
											//$scope.objects[i].adjustmentDesc.CODE_DESC = null;
											$scope.objects[i].dateFiled = null;
											//$scope.objects[i].descriptionDesc.CODE = null;
											$scope.objects[i].taxesAmount = null;
											$scope.objects[i].usrcomments = null;
											$scope.objects[i].transDetailsKey = null;
											$scope.objects[i].invalidDateFlag = false;
											$scope.objects[i].invalidDateOpenFlag = false;
											$scope.objects[i].descFlag = false;
											$scope.objects[i].adjustFlag = false;
											$scope.objects[i].dateFlag = false;
									}*/
									$scope.objects = angular.copy(vm.OnLoadObjData);
								}
								getCurrDefault();
								
								//vm.getcurrencyDefault();
		                	   		
							}
							if(vm.resetFlag == undefined){
								for(var i=0;i<minRows;i++) {
								vm.filterLength = vm.filterLength+1;
								vm.addNewFlag = true;
								vm.addNew({});
							  }
							 vm.OnLoadObjData = angular.copy($scope.objects);
							 
                            }
							
						});
				}
				
				function getCurrencies() {
		            
		            var params = {
		            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
		                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
		            };

		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3ucwjb", params)
		            .then(function(data) {
		            if (data.callSuccess === "1") {
		            vm.currencies = data.jsonObject;
		            getExisitngCurr();
		            getDescription();
					getFTRDescription();
					getDateProvidedDescription();
		            }
		               });
		               }
		               
		           function getCurrDefault() {
		            
		            var params = {
		            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
		                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
		            };

		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3ucwjb", params)
		            .then(function(data) {
		            if (data.callSuccess === "1") {
		            vm.currencies = data.jsonObject;
		            getcurrencyDefault();
		            getDescription();
					getFTRDescription();
					getDateProvidedDescription();
		            }
		               });
		               }
		       
				
				vm.removeEntity = function(_index){
                    vm.entities.splice(_index,1);
                    if( vm.entities.length === 0 ){
                        $uibModalInstance.dismiss('cancel');
                    }
                }
				
				vm.save = function() {
					 
				 }
				
				function getcurrencyDefault(){
					var defaultCurr = vm.rowData.DERIVED_CURR_LE;
				    angular.forEach(vm.currencies, function(mg) {
		                	   		   if (defaultCurr == mg.CURR_CODE) {
		                	   			   vm.selectedGroup = mg;
		                	   		   }
		                	   	   });
		           } 
		            	
		          function getExisitngCurr(){
					angular.forEach(vm.currencies, function(hg) {
		                	   		   if (vm.editRecords[0].CURRENCY == hg.CURR_CODE) {
		                	   			   vm.selectedGroup = hg;
		                	   		   }
		                	   	   });
		            return TaxLiabilityDashboardFactory.getTotalTaxLiability(rowData.TAX_YEAR, rowData.COMBINATION_KEY,rowData.GROUP_ID).then(
								function(result) {
									//console.log(result);
		                            if(result){
										vm.editTotalRecords=result;
									}
		                         });
		           } 
		           
		            vm.formatDate = function(dateIndex) {
			           for(var i=0; i< $scope.objects.length; i++) {
							 if(($filter('date')($scope.objects[i].dateFiled, "yyyy") < vm.rowData.END_TAX_YEAR && ((angular.isDefined($scope.objects[i].dateFiled)) && $scope.objects[i].dateFiled != null && $scope.objects[i].dateFiled != '') )
							  &&vm.statusFlag == true){
								    $scope.objects[i].invalidDateFlag = true;
							   }else if(($filter('date')($scope.objects[i].dateFiled, "yyyy") < vm.rowData.TAX_YEAR && ((angular.isDefined($scope.objects[i].dateFiled)) && $scope.objects[i].dateFiled != null && $scope.objects[i].dateFiled != '') )){
								          $scope.objects[i].invalidDateOpenFlag = true;
							        }
							   else{
								   $scope.objects[i].invalidDateFlag = false;
								   $scope.objects[i].invalidDateOpenFlag = false;
							  }
							}
							var dateCorrectVariable = dateIndex;
            		        $scope.objects[dateCorrectVariable].dateFlag = false;
			             };
			     
		          vm.validateAdjustment = function()
		          {
			         for(var i=0; i< $scope.objects.length; i++) {
				
				              if(
					              (((angular.isDefined($scope.objects[i].descriptionDesc)) && $scope.objects[i].descriptionDesc != null && $scope.objects[i].descriptionDesc!= "")||
									((angular.isDefined($scope.objects[i].dateFiled)) && $scope.objects[i].dateFiled != null && $scope.objects[i].dateFiled != '')||
									((angular.isDefined($scope.objects[i].taxesAmount)) && $scope.objects[i].taxesAmount != null && $scope.objects[i].taxesAmount != '') ||
									((angular.isDefined($scope.objects[i].descriptionDateProvidedDesc)) && $scope.objects[i].descriptionDateProvidedDesc != null && $scope.objects[i].descriptionDateProvidedDesc != '')||
									((angular.isDefined($scope.objects[i].ftrRelatesToDesc)) && $scope.objects[i].ftrRelatesToDesc != null && $scope.objects[i].ftrRelatesToDesc != ''))&& vm.rowData.STATUS == 'Closed' &&
									(!(angular.isDefined($scope.objects[i].adjustmentDesc)) || $scope.objects[i].adjustmentDesc == null || $scope.objects[i].adjustmentDesc == '')
									){
										$scope.objects[i].adjustFlag = true;
									}
			
		                }
		           }
		           
		           vm.validateDescription = function()
		          {
			         for(var i=0; i< $scope.objects.length; i++) {
				
				              if(
					              (((angular.isDefined($scope.objects[i].adjustmentDesc)) && $scope.objects[i].adjustmentDesc != null && $scope.objects[i].adjustmentDesc!= "")||
									((angular.isDefined($scope.objects[i].dateFiled)) && $scope.objects[i].dateFiled != null && $scope.objects[i].dateFiled != '')||
									((angular.isDefined($scope.objects[i].taxesAmount)) && $scope.objects[i].taxesAmount != null && $scope.objects[i].taxesAmount != '')||
									((angular.isDefined($scope.objects[i].descriptionDateProvidedDesc)) && $scope.objects[i].descriptionDateProvidedDesc != null && $scope.objects[i].descriptionDateProvidedDesc != '')||
									((angular.isDefined($scope.objects[i].ftrRelatesToDesc)) && $scope.objects[i].ftrRelatesToDesc != null && $scope.objects[i].ftrRelatesToDesc != ''))&& 
									(!(angular.isDefined($scope.objects[i].descriptionDesc)) || $scope.objects[i].descriptionDesc == null || $scope.objects[i].descriptionDesc == '')
									){
										$scope.objects[i].descFlag = true;
									}
				
		                }
		           }
		           
		            vm.validateDate = function()
		          {
			         for(var i=0; i< $scope.objects.length; i++) {
				
				               if(
					              (((angular.isDefined($scope.objects[i].adjustmentDesc)) && $scope.objects[i].adjustmentDesc != null && $scope.objects[i].adjustmentDesc!= "")||
									((angular.isDefined($scope.objects[i].descriptionDesc)) && $scope.objects[i].descriptionDesc != null && $scope.objects[i].descriptionDesc != '')||
									((angular.isDefined($scope.objects[i].taxesAmount)) && $scope.objects[i].taxesAmount != null && $scope.objects[i].taxesAmount != '')||
									((angular.isDefined($scope.objects[i].descriptionDateProvidedDesc)) && $scope.objects[i].descriptionDateProvidedDesc != null && $scope.objects[i].descriptionDateProvidedDesc != '')||
									((angular.isDefined($scope.objects[i].ftrRelatesToDesc)) && $scope.objects[i].ftrRelatesToDesc != null && $scope.objects[i].ftrRelatesToDesc != ''))&& vm.rowData.STATUS == 'Closed' &&
									(!(angular.isDefined($scope.objects[i].dateFiled)) || $scope.objects[i].dateFiled == null || $scope.objects[i].dateFiled == '')
									){
										$scope.objects[i].dateFlag = true;
									}
				
		                }
		           }
		            	   
				 vm.singleRowSave = function() {
					 var resObj=[];
					 var saveObject = [];					
					 vm.resetFlag = true;
					 vm.editList = [];
					 vm.edit_id = 100;
					 var scheduleSettings = [{
			                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
			                "scenario": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
			                "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
			            }];
					 vm.flag1  = !(angular.isDefined(vm.selectedGroup.CURR_CODE)) || vm.selectedGroup.CURR_CODE == ""|| vm.selectedGroup.CURR_CODE == null;
					 if(vm.flag1 == true){
						return;
					 }
					 
					 if(vm.editRecords.length){
					 vm.editRow = _.filter(vm.editRecords, function(p){
			                   return _.includes([false], p.EDIT_FLAG) ;
			         	});
			         	
			         for(var i = 0; i < vm.editRow.length; i++){
						 
		
						var tempObj ={
										           tax_year:vm.rowData.TAX_YEAR,
												   combination_key:vm.rowData.COMBINATION_KEY,
									               description:vm.editRow[i].DESCRIPTION,
												    adjustment:vm.editRow[i].ADJUSTMENT,
													dateFiled :(angular.isDefined(vm.editRow[i].TRANS_DATE != null) ? vm.editRow[i].TRANS_DATE  : null),
													trans_type_key:vm.editRow[i].TRANS_TYPE_KEY,
													taxesAmount:vm.editRow[i].FC_AMT,
													usrcomments:vm.editRow[i].DETAIL_COMMENTS,
													transDetailsKey:vm.editRow[i].TRANS_DETAILS_KEY,
													curr_desc:vm.editRow[i].CURRENCY,
													group_id :vm.rowData.GROUP_ID == 0 ? '' :vm.rowData.GROUP_ID,
													isEdit :true,
													id: vm.edit_id + i,
													ftrDescription : vm.editRow[i].FTRDESCRIPTION,
													ftrRelatesToDesc : (angular.isDefined(vm.editRow[i].FTRRELATESTODESC) ? vm.editRow[i].FTRRELATESTODESC : null),
													descriptionDateProvidedDesc : (angular.isDefined(vm.editRow[i].DESCRIPTIONDATEPROVIDEDDESC) ? vm.editRow[i].DESCRIPTIONDATEPROVIDEDDESC : null)
											};
				
							vm.editList.push(tempObj);
							console.log("Details-----------------------", vm.editList);	                		
						  }		
			         }
			                
					 
					 if($scope.objects.length) {
						vm.validateDescription();
						vm.validateAdjustment();
						vm.validateDate();
						vm.validateValues();
						    
						    for(var i=0; i< $scope.objects.length; i++){
							  if($scope.objects[i].descFlag == true){
								vm.descRowFlag = true;
								break;
							     }else
							     {
								      vm.descRowFlag = false;
							     }
						    }
						    
						    for(var i=0; i< $scope.objects.length; i++){
							  if($scope.objects[i].adjustFlag == true){
								vm.adjustRowFlag = true;
								break;
							     }else
							     {
								       vm.adjustRowFlag = false;
							     }
						    }
						    
						    for(var i=0; i< $scope.objects.length; i++){
							  if($scope.objects[i].dateFlag == true||$scope.objects[i].invalidDateFlag == true){
								vm.dateRowFlag = true;
								break;
							     }else
							     {
								    vm.dateRowFlag = false;
							      }
						    }
						    
						    for(var i=0; i< $scope.objects.length; i++){
							  if($scope.objects[i].invalidDateOpenFlag == true){
								vm.dateFlagOpen = true;
								break;
							     }else
							     {
								    vm.dateFlagOpen = false;
							      }
						    }
						    
						    
						    
						    if(vm.descRowFlag ==true||vm.adjustRowFlag == true||vm.dateRowFlag == true||vm.dateFlagOpen == true){
							return;
						    }
						    
						     vm.saveObjectData = angular.copy($scope.objects);
						     
						    if(vm.editList.length) {
						    vm.editList.forEach(function(currentObj){
									var index = _.findIndex($scope.objects, { 'id': currentObj.id });
									if(index == -1){
										vm.saveObjectData.push(currentObj);
									}
								})
								
							}
						    
						   //$scope.objects = $scope.objects.concat(vm.editList);
						   //$scope.objects = _.merge($scope.objects,vm.editList);
						   
						   
						    
						  for(var i=0; i< vm.saveObjectData.length; i++) {
							 
							saveObject = [{ 
									id:vm.saveObjectData[i].id,
									tax_year:vm.rowData.TAX_YEAR,
									combination_key:vm.rowData.COMBINATION_KEY,
									description:vm.saveObjectData[i].isEdit == false? vm.saveObjectData[i].descriptionDesc.CODE_DESC: vm.saveObjectData[i].description ,
									adjustment: vm.saveObjectData[i].isEdit == false? vm.saveObjectData[i].adjustmentDesc.CODE_DESC : vm.saveObjectData[i].adjustment,
									dateFiled :(angular.isDefined(vm.saveObjectData[i].dateFiled != null) ? $filter('date')(vm.saveObjectData[i].dateFiled, "MM/dd/yyyy")  : null),
									trans_type_key: vm.saveObjectData[i].isEdit == false? vm.saveObjectData[i].descriptionDesc.CODE: vm.saveObjectData[i].trans_type_key,
									amt:Number(vm.saveObjectData[i].taxesAmount),
									comments:vm.saveObjectData[i].usrcomments,
									trans_details_key:vm.saveObjectData[i].transDetailsKey,
									curr_desc:vm.selectedGroup.CURR_CODE,
									group_id :vm.rowData.GROUP_ID == 0 ? '' :vm.rowData.GROUP_ID,
									ftrDescription : vm.saveObjectData[i].ftrDescription,
									ftrRelatesToDesc : (angular.isDefined(vm.saveObjectData[i].ftrRelatesToDesc.CODE_DESC) ? vm.saveObjectData[i].ftrRelatesToDesc.CODE_DESC : vm.saveObjectData[i].ftrRelatesToDesc),
									descriptionDateProvidedDesc : (angular.isDefined(vm.saveObjectData[i].descriptionDateProvidedDesc.CODE_DESC) ? vm.saveObjectData[i].descriptionDateProvidedDesc.CODE_DESC : vm.saveObjectData[i].descriptionDateProvidedDesc)
							}];
							resObj.push(saveObject);
						}
				}	
					var scheduleDetails = { "editrows":resObj};	
					TaxLiabilityDashboardFactory.save(resObj,scheduleSettings).then(function(res){
							//console.log(res);
						 });
					 var args = {combination_key: vm.rowData.COMBINATION_KEY, gridFilter: {combination_key: vm.rowData.COMBINATION_KEY}};                     
					 $rootScope.$emit('gridUpdate', args);
                     $uibModalInstance.dismiss('cancel');
				 }
				 
				 
		vm.editLiab = function(editData,isDelete,index) {
				 vm.editRecords[index].EDIT_FLAG = false;
				 
		}		 
		
		
			                		

				 vm.singleRowDelete = function(response,isDelete,index) {
					 var response = [];
					 response = vm.editRecords;
					 var resDelObj={};
					 for(var i=0;i<response.length;i++){
							var res=response[i];
							if(i == index){
							        resDelObj.trans_details_key=res.TRANS_DETAILS_KEY;
									resDelObj.trans_header_key=res.TRANS_HEADER_KEY;
									resDelObj.combination_key = vm.rowData.COMBINATION_KEY;
									resDelObj.description =  res.DESCRIPTION;        
								 TaxLiabilityDashboardFactory.delete(resDelObj,isDelete).then(function(res){
									});
							}
							}
					 var args = {combination_key: vm.rowData.COMBINATION_KEY, gridFilter: {combination_key: vm.rowData.COMBINATION_KEY}};                     
					 $rootScope.$emit('gridUpdate', args);
                     $uibModalInstance.dismiss('cancel');
				     }
					
				
				 vm.updated = function(currencyValue) {
		                vm.selectedGroup = getCurrencyDisplayName(currencyValue);
		                };

		        function getCurrencyDisplayName(currencyValue) {
		        	var tempObj = {};
		            for(var i = 0; i < vm.currencies.length; i++)
		            {
		                if(vm.currencies[i].CURR_CODE === currencyValue)
		                {
		                	tempObj  = vm.currencies[i];
		                    break;
		                }
		            }
		            return tempObj;
		        }
				   
          		function getDescription() {
		            
		            var params = {
		            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
		            		"combination_key": vm.rowData.COMBINATION_KEY
		            };

		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=g9iudn", params)
		            .then(function(data) {
		            if (data.callSuccess === "1") {
		            vm.description = data.jsonObject;
		            getDescriptionDisplayName();
		            getAdjustment();
		            }
		            });
		        }
		       
		        

		        function getDescriptionDisplayName() {
		            for(var i = 0; i < vm.description.length; i++)
		            {
		                if(vm.description[i].CODE_DESC === vm.descriptionValue)
		                {
		                    vm.descriptionDesc  = vm.description[i].CODE_DESC;
		                    break;
		                }
		            }
		        }
				
		        vm.changeDescriptionLaws = function(value,value3){
            		vm.descriptionDesc = value;
            		var desctVariable = value3;
            		$scope.objects[desctVariable].descFlag = false;
           	
            	}
		        
         		function getAdjustment() {
		            
		            var params = {
		            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year
		            };

		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=172kn4", params)
		            .then(function(data) {
		            if (data.callSuccess === "1") {
		            vm.adjustment = data.jsonObject;
		            getAdjustmentDisplayName();
		            }
		               });
				}
		        
		        function getAdjustmentDisplayName() {
		            for(var i = 0; i < vm.adjustment.length; i++)
		            {
		                if(vm.adjustment[i].CODE_DESC === vm.adjustmentValue)
		                {
		                    vm.adjustmentDesc  = vm.adjustment[i].CODE_DESC;
		                    break;
		                }
		            }
		            vm.dataLoading = false;
		        }
				
		        vm.changeAdjustmentLaws = function(value1,value2){
            		vm.adjustmentDesc = value1;
            		var adjustVariable = value2;
            		$scope.objects[adjustVariable].adjustFlag = false;
           	        }
				
				vm.addNew = function(row,add,dateFiled,usrcomments,isEdit,userUpdate,dateUpdate,transDetailsKey) {
                    var row = {};
                    var rowFlag  = add;
                   //$scope.objects.length = vm.newSortOrder
					row.id =  rowFlag  == "New" ? $scope.objects[$scope.objects.length -1].id + 1 : vm.filterLength ;
					//row.name = "PL"+$scope.objects.length+1;
					row.tax_year = vm.rowData.TAX_YEAR;
					row.combination_key = vm.rowData.COMBINATION_KEY;
					row.descriptionDesc="";
					row.description=[];
					row.adjustmentDesc="";
					row.adjustment=[];
					row.dateFiled='';
					row.isEdit= isEdit||false;
					row.taxesAmount="";
					row.dateUpdate='';
					row.userUpdate='';
					row.usrcomments='';
					row.transDetailsKey = 0;
					row.adjustFlag= false;
					row.descFalg = false;
					row.dateFlag = false;
					row.invalidDateFlag = false;
					row.invalidDateOpenFlag = false;
					row.ftrRelatesToDesc="";
					row.descriptionDateProvidedDesc="";
					row.ftrRelatestodescFlag = false;
					row.descriptionDateProvidedFlag = false;
					$scope.objects.push(row);
					return row;
				};
				
				init();	

				function getFTRDescription() {
		            
		            var params = {
		            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year
		            		
		            };

		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=r7gm1q", params)
		            .then(function(data) {
		            if (data.callSuccess === "1") {
		            vm.ftrRelatesToList = data.jsonObject;
		            
		            }
		               });
		        }

				vm.changeFTRDescription = function(value,value3){
            		vm.ftrRelatesToDesc = value;
            		var desctVariable = value3;
            		$scope.objects[desctVariable].ftrRelatestodescFlag = false;
           	
            	}

				
				function getDateProvidedDescription() {
		            
		            var params = {
		            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year
		            		
		            };

		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=xri7tl", params)
		            .then(function(data) {
		            if (data.callSuccess === "1") {
		            vm.descriptionDateProvided = data.jsonObject;
		            
		            }
		               });
		        }

				vm.changeDateProvidedDescription = function(value,value3){
            		vm.descriptionDateProvidedDesc = value;
            		var desctVariable = value3;
            		$scope.objects[desctVariable].descriptionDateProvidedFlag = false;
           	
            	}

				vm.validateValues = function()
				{
					for(var i=0; i< $scope.objects.length; i++) {
						//dateFiled
						if( (((angular.isDefined($scope.objects[i].adjustmentDesc)) && $scope.objects[i].adjustmentDesc != null && $scope.objects[i].adjustmentDesc!= "")||
							((angular.isDefined($scope.objects[i].descriptionDesc)) && $scope.objects[i].descriptionDesc != null && $scope.objects[i].descriptionDesc != '')||
							((angular.isDefined($scope.objects[i].taxesAmount)) && $scope.objects[i].taxesAmount != null && $scope.objects[i].taxesAmount != '')||
							((angular.isDefined($scope.objects[i].dateFiled)) && $scope.objects[i].dateFiled != null && $scope.objects[i].dateFiled != '') ||
							((angular.isDefined($scope.objects[i].descriptionDateProvidedDesc)) && $scope.objects[i].descriptionDateProvidedDesc != null && $scope.objects[i].descriptionDateProvidedDesc != ''))
							 && (!(angular.isDefined($scope.objects[i].ftrRelatesToDesc)) || $scope.objects[i].ftrRelatesToDesc == null || $scope.objects[i].ftrRelatesToDesc == "") )
								$scope.objects[i].ftrRelatestodescFlag = true;
						if( (((angular.isDefined($scope.objects[i].adjustmentDesc)) && $scope.objects[i].adjustmentDesc != null && $scope.objects[i].adjustmentDesc!= "")||
						((angular.isDefined($scope.objects[i].descriptionDesc)) && $scope.objects[i].descriptionDesc != null && $scope.objects[i].descriptionDesc != '')||
						((angular.isDefined($scope.objects[i].taxesAmount)) && $scope.objects[i].taxesAmount != null && $scope.objects[i].taxesAmount != '')||
						((angular.isDefined($scope.objects[i].dateFiled)) && $scope.objects[i].dateFiled != null && $scope.objects[i].dateFiled != '') ||
						((angular.isDefined($scope.objects[i].ftrRelatesToDesc)) && $scope.objects[i].ftrRelatesToDesc != null && $scope.objects[i].ftrRelatesToDesc != ''))
						&&	(!(angular.isDefined($scope.objects[i].descriptionDateProvidedDesc) )|| $scope.objects[i].descriptionDateProvidedDesc == null || $scope.objects[i].descriptionDateProvidedDesc == "") )
								$scope.objects[i].descriptionDateProvidedFlag = true;
					}
				}
			}	
			
			return controllers;
		});