define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.newProformaEfileViewServices', [])
                            .factory("NewProformaEfileViewFactory", ['$http', 'GENERAL_CONFIG', 'GlobalService', function ($http, GENERAL_CONFIG, GlobalService) {
                                const newProformaEfileViewFactory = {};
                                const requestUrlBuilder = {
                                    url: '',
                                    isFirstParam: true,
                                    set: function (url) {
                                        this.url = url;
                                        this.isFirstParam = true;
                                        return this;
                                    },
                                    get: function () {
                                        return this.url;
                                    },
                                    appendEndpoint: function (endpoint) {
                                        this.url = this.url + endpoint;
                                        return this;
                                    },
                                    appendParam: function (paramName, paramVal) {
                                        if (!this.url || this.url.length === 0) throw Error('Bad Url');
                                        if (paramName !== undefined && paramVal !== undefined) {
                                            this.url = this.url + (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                                            this.isFirstParam = false;
                                        }
                                        return this;
                                    }
                                };

                                newProformaEfileViewFactory.cache = {
                                   FED:{
                                        flags:{},
                                        pdfBinary:{},
                                        efileViewPdf: {}
                                   },
                                   STATE:{
                                        flags:{},
                                        pdfBinary:{},
                                        efileViewPdf: {}
                                   } 
                                };
                                newProformaEfileViewFactory.cache.treeData = null;

                                newProformaEfileViewFactory.getIRSView = function (taxYear, leId,scenario,jcd_key,contentType, formType = '') {
                                    return $http.post(`${GENERAL_CONFIG.pdf_engine_api_url}/getIRSView?taxYear=${taxYear}&leId=${leId}&groupKey=${leId}&jcdKey=${jcd_key}&scenario=${scenario}&contentType=${contentType}&formType=${formType}`);
                                };

                                newProformaEfileViewFactory.getPDFs = function (
                                    taxYear,
                                    leId,
                                    subLeId,
                                    keyId,
                                    returnType,
                                    businessType,
                                    cdrNo = 'NA',
                                    rpPeriod = 'NA',
                                    contentType = 'FED',
                                    scenario = 'NA',
                                    jcdKey = 'NA',
                                    leType = 'NA',
                                    sysFlag = 'NA',
                                    dataSource = 'NA',
                                    formType = 'Efile') {
                                    return $http.post(requestUrlBuilder.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                                       .appendEndpoint('/getGenericTaxForms')
                                                                       .appendParam('taxYear', taxYear)
                                                                       .appendParam('scenario', scenario)
                                                                       .appendParam('jcdKey', jcdKey)
                                                                       .appendParam('selectedView', leId)
                                                                       .appendParam('cdrNo', cdrNo)
                                                                       .appendParam('reportingPeriod', rpPeriod)
                                                                       .appendParam('leType', leType)
                                                                       .appendParam('src_system_flag', sysFlag)
                                                                       .appendParam('dataSource', dataSource)
                                                                       .appendParam('subLeId', subLeId)
                                                                       .appendParam('keyId', keyId)
                                                                       .appendParam('submissionId', leId)
                                                                       .appendParam('returnType', returnType)
                                                                       .appendParam('businessType', businessType)
                                                                       .appendParam('contentType', contentType)
                                                                       .appendParam('formType', formType)
                                                                       .get());
                                };

                                // formType = 'CBCR' is used to determine whether user is entering from the CBC View
                                newProformaEfileViewFactory.fillNewProformaForm = function (
                                    irsFormNo,
                                    s3Url,
                                    formSubCat,
                                    formSub = '',
                                    leid,
                                    parentLeId = '',
                                    taxYear,
                                    cdrNo = '',
                                    rptPeriod = '',
                                    systemFlag,
                                    wpIrsFormNo = '',
                                    schedule = '',
                                    line = '',
                                    partNo = 'NA',
                                    leName = '',
                                    leType = 'HO',
                                    ein = '',
                                    jcdKey = '250',
                                    scenario,
                                    srcType = 'EFILE',
                                    jobId = '',
                                    entityType = '',
                                    cgKey = '',
                                    consolType = '',
                                    keyId = '',
                                    formType = ''
                                ) {
                                    if (entityType === 'ALL') entityType = '';
                                    return $http({
                                        method: 'get',
                                        url: requestUrlBuilder.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                              .appendEndpoint('/fillNewProformaForm2')
                                                              .appendParam('irsFormNo', irsFormNo)
                                                              .appendParam('s3Url', s3Url)
                                                              .appendParam('formSubCat', formSubCat)
                                                              .appendParam('formSub', formSub)
                                                              .appendParam('leid', leid)
                                                              .appendParam('parentLeId', parentLeId)
                                                              .appendParam('taxYear', taxYear)
                                                              .appendParam('cdrNo', cdrNo)
                                                              .appendParam('rptPeriod', rptPeriod)
                                                              .appendParam('systemFlag', systemFlag)
                                                              .appendParam('wpIrsFormNo', wpIrsFormNo)
                                                              .appendParam('schedule', schedule)
                                                              .appendParam('line', line)
                                                              .appendParam('partNo', partNo)
                                                              .appendParam('leName', encodeURIComponent(leName))
                                                              .appendParam('leType', leType)
                                                              .appendParam('ein', ein)
                                                              .appendParam('jcdKey', jcdKey)
                                                              .appendParam('scenario', scenario)
                                                              .appendParam('srcType', srcType)
                                                              .appendParam('jobId', jobId)
                                                              .appendParam('entity_type', entityType)
                                                              .appendParam('cgKey', cgKey)
                                                              .appendParam('consolType', consolType)
                                                              .appendParam('keyId', keyId)
                                                              .appendParam('formType', formType)
                                                              .get(),
                                        responseType: 'arraybuffer'
                                    });
                                };

                                newProformaEfileViewFactory.getPdfAttachmentData = function (taxYear, selectedView, fileName, formType = '') {
                                    return $http({
                                        method: 'get',
                                        url: requestUrlBuilder.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                              .appendEndpoint('/getPdfAttachmentData')
                                                              .appendParam('taxYear', taxYear)
                                                              .appendParam('selectedView', selectedView)
                                                              .appendParam('fileName', fileName)
                                                              .appendParam('formType', formType)
                                                              .get(),
                                        responseType: 'arraybuffer'
                                    });
                                };
                                newProformaEfileViewFactory.getFailedEntities = function (taxYear, requestId) {
                                    return $http({
                                        method: 'get',
                                        url: requestUrlBuilder.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                              .appendEndpoint('/getFailedEntitiesByRequest')
                                                              .appendParam('taxYear', taxYear)
                                                              .appendParam('requestId', requestId)
                                                              .get()
                                    });
                                };

                                return newProformaEfileViewFactory;
                            }]);
    return services;
});
