define([
  'angular'

], function () {
  'use strict';

  var services =  angular.module('app.firLocalAcctDescService',[])
      .factory("FirLocalAcctDescGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
          ,'$q', '$http',
          '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG'
         
          ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                  ,$q, $http,
                   $stateParams, $timeout, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
    	  var factory = {};
          var returnObj = {};
          var firLocalAcctDescGroupObjFactory ={};
          firLocalAcctDescGroupObjFactory.url ={};
          
          firLocalAcctDescGroupObjFactory.url.local_desc_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=f9a3p3";
          
          firLocalAcctDescGroupObjFactory.saveFirLocalAcctDesc = function(_data,Details){

              var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
              var jsonObj = JSON.stringify(Details);
              var jsonSettings =  JSON.stringify(_data);
              //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings

              console.log(jsonSettings);
              var params = filterParams;
              params = _.merge({},  GlobalService.globalParams , params);
              params =  _.extend({jsonObj:jsonObj}, params);
              params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
              params.process_name =  "FOREIGN_LOCAL_ACCT_DESC_SAVE";
              console.log(params);
              var promise = $http({
                  method: "post",
                  url: firLocalAcctDescGroupObjFactory.url.local_desc_save,
                  data: params
              }).then(function (response) {
                  var status = response.status;
                  return response.data;
              });

              return promise;

          };
          
    	  return firLocalAcctDescGroupObjFactory;
      }]);

  return services;
});