define([
    'angular',
    './reportModuleController',
    './reportModuleService'

], function () {
    'use strict';
    return angular.module('app.reportModule', ['app.reportHomeController','app.reportHomeService'] );


});