define([
      'moment',
      'angular'
], function (moment) {
      'use strict';

      var controllers = angular.module('app.messageController', [])
            .controller('MessageController', ['$scope', '$rootScope', 'AlertService', 'MessageFactory', '$state', '$window', 'SERVER_CONFIG', '$uibModal',
                  'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'orderByFilter', '$filter', 'FileAttachementFactory', 'GENERAL_CONFIG', messageController
            ])
            .controller('AddCommentController', ['$state', '$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance',
                  'ModalFactory', 'AlertService', 'gridData', 'workspaceFactory', '$timeout', 'MessageFactory',
                  '$uibModal', 'JsonObjectFactory', 'SERVER_CONFIG', '$window', 'FileAttachementFactory', 'GENERAL_CONFIG', '$filter', addCommentController
            ])
            .controller('EditCommentController', ['$state', '$rootScope', '$scope', '$http',
                  'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'gridData',
                  'workspaceFactory', '$timeout', 'MessageFactory', '$uibModal', 'SERVER_CONFIG',
                  'JsonObjectFactory', '$window', 'FileAttachementFactory', 'GENERAL_CONFIG', '$sce', '$filter', editCommentController
            ])

            .controller('addAttachmentController', ['$scope', 'GENERAL_CONFIG', '$state', '$rootScope', '$uibModalInstance', 'Dashboard901Factory',
            'FileAttachementFactory', 'SERVER_CONFIG', 'AlertService', 'rowData', 'gridData', 'colData', 'MessageFactory', 'workspaceFactory','ModalFactory', addAttachmentController])
            .controller('editAttachmentController', ['$scope', 'GENERAL_CONFIG', '$state', '$rootScope', '$uibModalInstance', 'Dashboard901Factory',
            'FileAttachementFactory', 'SERVER_CONFIG', 'AlertService', 'rowData', 'gridData', 'colData', 'MessageFactory', 'workspaceFactory', '$filter','ModalFactory',
            'USER_SETTINGS','$window','$uibModal',
             editAttachmentController])
            .controller('previewAttachmentController', ['GENERAL_CONFIG', '$state', '$rootScope', '$uibModalInstance', 'Dashboard901Factory', 'FileAttachementFactory', 'SERVER_CONFIG', 'AlertService', 'rowData', 'gridData', 'colData', 'MessageFactory', 'workspaceFactory', previewAttachmentController])
            .filter('trustAsHtml', ['$sce', function ($sce) {
                  return function (val) {
                        //return $sce.trustAsResourceUrl(val);
                        val = val.replace("watch?v=", "embed/");

                        return $sce.trustAsResourceUrl(val);
                  };
            }]);



      var URLS = {
            addComment: '/addComment',
            getComment: '/commentByKey',
            updateComment: '/updateComment',
            getComments: '/loadComments',
            commentReply: '/commentReply',
            resolveComment: '/resolveComment',
            updateMessageStatus: '/updateMessageReadStatus',
            updateMessageFlag: '/updateMessageFlag',
            uploadCommentAttachements: '/uploadCommentAttachements',
            addTag: '/saveNewTag'
      }


      function messageController($scope, $rootScope, AlertService, MessageFactory, $state, $window, SERVER_CONFIG, $uibModal,
            USER_SETTINGS, JsonObjectFactory, workspaceFactory, orderByFilter, $filter, FileAttachementFactory, GENERAL_CONFIG) {

            var vm = this;
            vm.title = "Messages";

            vm.minimise = true;
            vm.contactOpen = false;

            vm.SERVER_CONFIG = SERVER_CONFIG;
            vm.MessageFactory = MessageFactory;
            vm.$window = $window;
            _.each(_.keys(FileAttachementFactory.fileUploadConfig), function (item) {
                  if (!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
                        vm[item] = _.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
                  else vm[item] = FileAttachementFactory.fileUploadConfig[item];
            });
            $scope.comments = [];
            $scope.comment = {};
            vm.unreadMessageList = [];
            vm.active_comment = {};
            vm.loadingComment = false;
            $scope.messageFilterData = {
                  filterShowAll: '1',
                  'filterText': '',
                  lastFilterText: '-1'
            };
            vm.replyMessage = "";
            vm.comment = "";
            vm.isMessageClicked = false;
            vm.lastSubMenuComment = undefined;
            vm.lastReplySubMenuComment = undefined;
            $scope.userAvatarPath = USER_SETTINGS.user.avatar_path;
            vm.attachmentTO = {
                  FILE_KEY: '',
                  FILE_NAME: '',
                  FILE_PATH: '',
                  FILE_TYPE: '',
                  FILE_SIZE: '',
                  CONTENT_TYPE: '',
                  ATTACHMENT_KEY: '',
                  comment_attachment_change_callSuccess: false,
                  saveClicked: false,
                  logoFilesMarkedForDeletion: [],
                  datasource_connection_msg: '',
                  datasource_connection_status: '',
                  flow: {},
                  attachment_file: ''
            };
            $scope.getScrollHeight = function (elementClass, parentDiv) {
                  var top = angular.element(elementClass)[0].getBoundingClientRect().top;
                  return $window.innerHeight - top;
            }
            $scope.timeago = function (date) {
                  var date = new Date(date);
                  return moment(date).fromNow();
            }

            vm.previewModal = function (fileName) {
                  var ctrlString = "EditCommentController as ctrl";
                  vm.gridData = {};
                  vm.gridData.comment_key = $scope.comment.comment_key;
                  vm.gridData.previewURL = GENERAL_CONFIG.base_url + "/previewCommentAttachement?commentKey=" + $scope.comment.comment_key + "&fileName=" + fileName;
                  vm.rowData = [];
                  vm.colData = [];
                  var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: "app/components/messages/templates/file-preview-modal.html",
                        controller: ctrlString,
                        size: "md",
                        resolve: {
                              rowData: function () {
                                    return vm.rowData;
                              },
                              colData: function () {
                                    return vm.colData;
                              },
                              gridData: function () {
                                    return vm.gridData;
                              }
                        }
                  });
                  modalInstance.result.then(function (selectedItem) {
                        vm.selected = selectedItem;
                  }, function () {

                  });
            };
            $scope.doSearchFilter = function () {
                  $scope.comments = [];
                  setTimeout(function () {
                        $scope.filterMesssageByType1();
                        $scope.comments = $filter('filter')(_.cloneDeep($scope.comments), $scope.messageFilterData.filterText);
                        $scope.$apply();
                  }, 50);


            }
            vm.openModal = function (comment) {
                  var ctrlString = "AddCommentController as ctrl";
                  vm.gridData = {};
                  if (comment) {
                        ctrlString = "EditCommentController as ctrl";
                        vm.gridData.comment_key = comment.comment_key;
                  }
                  vm.rowData = [];
                  vm.colData = [];
                  MessageFactory.clickedFromMessage(true)
                  var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: "app/components/messages/templates/add-comment-modal-tpl.html",
                        controller: ctrlString,
                        size: "md",
                        resolve: {
                              rowData: function () {
                                    return vm.rowData;
                              },
                              colData: function () {
                                    return vm.colData;
                              },
                              gridData: function () {
                                    return vm.gridData;
                              }
                        }
                  });
                  modalInstance.result.then(function (selectedItem) {
                        vm.selected = selectedItem;
                  }, function () {
                        if (comment) {
                              if (isUpdateClicked)
                                    $scope.loadComment($scope._SelectedComment);
                              isUpdateClicked = false;
                        }
                  });
            };

            ///////////////////////////////////---- clear Filter----////////////////////////////////////

            $scope.commentsDeepClonedData = [];
            vm.clearMessages = function () {
                  $scope.comments = [];
            }
            $scope.filterMesssageByType = function () {
                  $scope.comments = [];
                  $scope.comment = {};
                  //vm.filterMesssageByType1();
                  setTimeout(function () {
                        $scope.filterMesssageByType1()
                  }, 10);
            }

            ////////////////////////////////////////----Filters----////////////////////////////////////////////

            $scope.filterMesssageByType1 = function () {
                  if ($scope.messageFilterData.lastFilterText == "-2" &&
                        $scope.messageFilterData.filterShowAll == "6") {
                        $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                              return item.notify_type == 'notify';
                        }));
                        return;
                  } else if ($scope.messageFilterData.lastFilterText == "-2" &&
                        $scope.messageFilterData.filterShowAll !== "6") {
                        $scope.messageFilterData.lastFilterText = $scope.messageFilterData.filterShowAll;
                        $scope.loading.showCommentsLoading = true;
                        $scope.loadComments();
                        return;
                  }
                  // if($scope.messageFilterData.lastFilterText == "6"){
                  //       //$scope.messageFilterData.lastFilterText=$scope.messageFilterData.filterShowAll;
                  //       $scope.messageFilterData.lastFilterText="-2";
                  //       $scope.loadComments();
                  //       return;
                  //      // $scope.messageFilterData.lastFilterText="1";
                  // }
                  $scope.messageFilterData.lastFilterText = $scope.messageFilterData.filterShowAll;
                  if ($scope.messageFilterData.filterShowAll == "1") { //show all
                        $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                              return item.status !== 'archive' && item.notify_type !== 'sent';
                        }));
                        if (_.keys($scope._SelectedComment).length == 0) {
                              $scope.loadComment($scope.comments[0]);
                        }
                  } else if ($scope.messageFilterData.filterShowAll == "2" || $scope.messageFilterData.filterShowAll == "3") { //Read && unread
                        if ($scope.commentsDeepClonedData && $scope.commentsDeepClonedData.length) {
                              if ((!vm.unreadMessageList || !vm.unreadMessageList.length) && $scope.messageFilterData.filterShowAll == "5") {
                                    //no unread messages, all are read messages
                                    $scope.comments = [];
                              } else {
                                    var unreadMessageList = _.map(vm.unreadMessageList, 'comment_key');
                                    $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                                          if ($scope.messageFilterData.filterShowAll == "3") //UnRead
                                                //if its present in unread message list,its new message
                                                return unreadMessageList.indexOf(item.comment_key + "") > -1 && item.status !== 'archive' && item.notify_type !== 'sent';
                                          else //New
                                                return unreadMessageList.indexOf(item.comment_key + "") === -1 && item.status !== 'archive' && item.notify_type !== 'sent';
                                    }));
                              }
                        }
                  } else if ($scope.messageFilterData.filterShowAll == "4") { //Sent
                        $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                              return item.notify_type == 'sent';
                        }));
                  } else if ($scope.messageFilterData.filterShowAll == "5") { //Archive
                        $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                              return item.status == 'archive';
                        }));
                  } else if ($scope.messageFilterData.filterShowAll == "6") { //Resolved
                        // $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                        //       return item.status == 'closed';
                        // }));
                        $scope.loading.showCommentsLoading = true;
                        setTimeout(function () {
                              $scope.$apply();
                              $scope.loading.showCommentsLoading = true;
                              $scope.loadComments(undefined, 'closed');
                              //$scope.messageFilterData.lastFilterText="6";
                              $scope.messageFilterData.lastFilterText = "-2";
                        }, 200);
                  } else if ($scope.messageFilterData.filterShowAll == "7") { //Follow Up
                        $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                              return item.flag == '1' && item.notify_type !== 'sent';
                        }));
                  }
                  //  console.log($scope.messageFilterData.filterShowAll, $scope.comments);
                  $scope.$apply();
            }
            vm.showSubMenu = function (comment) {
                  if (comment === undefined) {
                        comment = vm.lastSubMenuComment;
                        comment.isShowSubMenu = true;
                  } else {
                        vm.lastSubMenuComment = comment;
                  }
                  comment.isShowSubMenu = !comment.isShowSubMenu
                  if (comment.isShowSubMenu) {
                        $window.onclick = function (event) {
                              closeSearchWhenClickingElsewhere(event, vm.showSubMenu);
                        };
                  } else {
                        if (vm.lastSubMenuComment) {
                              vm.lastSubMenuComment.isShowSubMenu = false;
                        }
                        comment.isShowSubMenu = false;
                        $window.onclick = null;
                        // $scope.$apply(); //--> trigger digest cycle and make angular aware.
                        setTimeout(function () {
                              vm.lastSubMenuComment = comment;
                              $scope.$apply();
                        }, 300);
                  }
            }
            vm.showReplySubMenu = function (comment) {
                  if (comment === undefined) {
                        comment = vm.lastReplySubMenuComment;
                        comment.isShowReplySubMenu = true;
                  } else {
                        vm.lastReplySubMenuComment = comment;
                  }
                  comment.isShowReplySubMenu = !comment.isShowReplySubMenu
                  if (comment.isShowReplySubMenu) {
                        $window.onclick = function (event) {
                              closeSearchWhenClickingElsewhere(event, vm.showReplySubMenu);
                        };
                  } else {
                        if (vm.lastSubMenuColastReplySubMenuCommentmment) {
                              vm.lastReplySubMenuComment.isShowReplySubMenu = false;
                        }
                        comment.isShowReplySubMenu = false;
                        $window.onclick = null;
                        //   $scope.$apply(); //--> trigger digest cycle and make angular aware.
                        setTimeout(function () {
                              vm.lastReplySubMenuComment = comment;
                              $scope.$apply();
                        }, 300);
                  }
            }
            $scope._SelectedComment = {};
            $scope.loading = {
                  showCommentDetailLoading: false,
                  showCommentReplyLoading: false,
                  showCommentsLoading: false
            };
            vm.loadingComment = false;
            $scope.loadComment = function (_comment_key) {
                  $scope._SelectedComment = _comment_key;
                  $scope.comment = {};
                  vm.loadingComment = true;
                  $scope.loading.showCommentDetailLoading = true;
                  setTimeout(function () {
                        $scope.loadComment1(_comment_key)
                  }, 100);
            }
            $scope.loadComment1 = function (comment) {
                  vm.loadingComment = true;
                  $scope.loading.showCommentDetailLoading = true;
                  vm.active_comment.active = false;
                  comment.active = true;
                  vm.active_comment.isShowSubMenu = false;
                  vm.active_comment = comment;
                  vm.active_comment.isShowSubMenu = false;
                  vm.isShowReplyAttachment = false;
                  var data = {
                        comment_key: comment.comment_key,
                        status: $scope.messageFilterData.filterShowAll == 6 ? 'closed' : 'open'
                  };
                  //////////////////////updateMessageStatus in DB////////////////////////////////////
                  var objContext = {
                        comment_key: comment.comment_key,
                        comment_status: 'read'
                  };
                  var messageStatus = _.find(vm.unreadMessageList, {
                        comment_key: (comment.comment_key + "")
                  });
                  if (messageStatus) {
                        MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.updateMessageStatus, objContext).then(function (data) {
                              vm.unreadMessageList = _.without(vm.unreadMessageList, messageStatus);
                              if (vm.unreadMessageList)
                                    vm.messageCount = vm.unreadMessageList.length;
                              //vm.messageCount=isNaN(vm.messageCount-1)?0:Number(vm.messageCount-1);
                              $rootScope.updateMessageCount((vm.messageCount < 0) ? 0 : vm.messageCount);
                        });
                  }
                  /////////////////////////////////////////////////////////////////////////////////////
                  $rootScope.$broadcast('showLoader', true);

                  MessageFactory.getComments(GENERAL_CONFIG.base_url + URLS.getComment, data).then(function (data) {
                        console.log("======================", data);
                        vm.isMessageClicked = true;
                        if (data.callSuccess && data.callSuccess === "1") {
                              if (data.comment.replyList) {
                                    for (var i = 0; i < data.comment.replyList.length; i++) {
                                          data.comment.replyList[i].dateM = new Date(data.comment.replyList[i].date).getTime();
                                          data.comment.replyList[i].isSender = data.comment.replyList[i].sso_id == USER_SETTINGS.user.sso_id;
                                    }
                              }

                              var formatBytes = function bytesToSize(bytes) {
                                    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                                    if (bytes == 0) return '0 Byte';
                                    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                                    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
                              };
                              if (data.comment.attachmentList) {
                                    for (var i = 0; i < data.comment.attachmentList.length; i++) {
                                          var attachment = data.comment.attachmentList[i];
                                          var fileType = attachment.file_type;
                                          attachment.file_name = attachment.file_name.replace(data.comment.comment_key + "//", '');
                                          attachment.icon = ['doc', 'docx'].indexOf(fileType) > -1 ? 'fa-file-word-o' : ['xls', 'xlsx', 'ods'].indexOf(fileType) > -1 ? 'fa-file-excel-o' : ['pdf'].indexOf(fileType) > -1 ? 'fa-file-pdf-o' : ['jpg', 'jpeg', 'png', 'svg', 'PNG'].indexOf(fileType) > -1 ? 'fa-file-image-o' : ['ppt', 'pptx'].indexOf(fileType) > -1 ? 'fa-file-powerpoint-o' : ['wpd', 'rtf', 'txt'].indexOf(fileType) > -1 ? 'fa-file-text-o' : '';

                                          attachment.fS = formatBytes(attachment.file_size);


                                    }
                              }
                              $scope.comment = data.comment;
                              setTimeout(function () {
                                    $(window).trigger('resize');
                                    setTimeout(function () {
                                          vm.loadingComment = false;
                                          $scope.loading.showCommentDetailLoading = false;
                                          $scope.$apply();
                                    }, 5);
                              }, 200);
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                              vm.loadingComment = false;
                        }
                        //vm.loadingComment = false;
                  });
            };
            vm.isShowSubFilter = false;
            vm.toggleMessageFilter = function () {
                  vm.isShowSubFilter = !vm.isShowSubFilter;
                  setTimeout(function () {
                        $(window).trigger('resize');
                  }, 200);
            }
            $scope.loadComments = function (isNotClearComment, status) {
                  var data = {
                        status: status ? status : 'open'
                  };
                  vm.loadingComment = true;
                  if (!isNotClearComment) {
                        $scope.comment = {};
                  }
                  MessageFactory.getComments(GENERAL_CONFIG.base_url + URLS.getComments, data).then(function (data) {
                        //data.comments=[];


                        if (data.callSuccess && data.callSuccess === "1") {
                              if (!data.comments || data.comments.length == 0) {
                                    $scope.comments = [];
                                    $scope.commentsDeepClonedData = [];
                                    setTimeout(function () {
                                          $scope.loading.showCommentsLoading = false;
                                          $scope.$apply();
                                    }, 300);
                                    return;
                              }
                              for (var index in data.comments) {
                                    var cmt = data.comments[index];
                                    cmt.hasAttachment = false;
                                    cmt.dateM = new Date(cmt.date).getTime();
                                    /*  check the attachment is there or not */
                                    if (data.commentsAttachment) {
                                          cmt.hasAttachment = _.find(data.commentsAttachment, {
                                                parent_key: cmt.comment_key + ""
                                          }) ? true : false;
                                    }

                                    cmt.hasDatabuoy = (cmt.data && _.keys(JSON.parse(cmt.data)).length > 0) ? true : false;
                              }
                              data.comments = orderByFilter(data.comments, '-dateM');
                              if (!isNotClearComment) {
                                    $scope.commentsDeepClonedData = _.cloneDeep(data.comments);
                                    if (!status || status != 'closed') {
                                          $scope.filterMesssageByType();
                                    } else {
                                          $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                                                return item.notify_type == 'notify';
                                          }));
                                    }
                              } else {
                                    //// check the array get the for new message
                                    var newMessageList = _.difference(_.map(data.comments, 'comment_key'), _.map($scope.commentsDeepClonedData, 'comment_key'));
                                    if (newMessageList.length > 0) {
                                          $scope.commentsDeepClonedData = _.cloneDeep(data.comments);
                                          // AlertService.add("New Message",_.find($scope.commentsDeepClonedData,{comment_key:newMessageList[0]}).message);
                                          $scope.comments = _.cloneDeep(_.filter($scope.commentsDeepClonedData, function (item) {
                                                return item.notify_type == 'notify';
                                          }));
                                    }
                              }
                              vm.unreadMessageList = data.commentsNotify;
                              vm.loadingComment = false;
                              setTimeout(function () {
                                    $scope.loading.showCommentsLoading = false;
                                    $scope.$apply();
                              }, 300);
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                              $scope.loading.showCommentsLoading = false;
                        }
                  });
                  //scroll
                  $(window).on('resize', function () {
                        setTimeout(function () {
                              $('.slim-scroll-h').next().find('.slimScrollDiv,.messages-scr').height($('.slim-scroll-h').height() - 120);
                        }, 1000);
                  });
                  setTimeout(function () {
                        $(window).trigger('resize');
                  }, 500);
            };

            vm.getRandomColor = function (comment) {
                  if (['a', 'b', 's'].indexOf((comment.display_name.substring(0, 1)).toLowerCase()) > -1) {
                        return 'blue';
                  } else if (['r', 'n', 'h'].indexOf((comment.display_name.substring(0, 1)).toLowerCase()) > -1) {
                        return '#29c7f7';
                  } else if (['c', 'm', 'd'].indexOf((comment.display_name.substring(0, 1)).toLowerCase()) > -1) {
                        return 'red';
                  }
                  return 'green';
            }

            //////////////////////////////////// comment Reply ---"commentReply"///////////////////////////
            vm.commentReply = function ($event) {
                  if ($event && ($event.keyCode != 13 || $event.which != 13)) {
                        return true;
                  }
                  $scope.loading.showCommentReplyLoading = true;
                  var objContext = {
                        comment_key: $scope.comment.comment_key,
                        message: vm.replyMessage,
                        type: 'add',
                        //attachments: vm.attachmentTO.attachmented_files.toString()
                  };
                  MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.commentReply, objContext).then(function (data) {
                        console.log(data);
                        //$scope.loadComment($scope.comment);
                        vm.crudLoading = false;
                        //$scope.loadComment(vm.active_comment);
                        $scope.comment.replyList.push({
                              comment_key: data.commentKey,
                              data: null,
                              date: moment().format("MM-DD-YYYY HH:mm:ss"),
                              display_name: USER_SETTINGS.user.display_name, //$scope.comment.display_name,
                              message: vm.replyMessage,
                              parent_key: $scope.comment.comment_key,
                              profile_image: $scope.comment.profile_image,
                              sso_id: $scope.comment.sso_id
                        });
                        vm.replyMessage = "";
                        vm.isShowReplyAttachment = false;
                        if (data.callSuccess && data.callSuccess === "1") {
                              //  AlertService.add("callSuccess", "");
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                        }
                        setTimeout(function () {
                              $scope.loading.showCommentReplyLoading = false;
                              $scope.$apply();
                              window.scrollTo({
                                    left: 0,
                                    top: document.body.scrollHeight,
                                    behavior: 'smooth'
                              });
                        }, 200);
                        //vm.attachmentTO.attachmented_files=[];
                  });
            }

            /////////////////////////////////////CheckMessageStatus////////////////////////////
            vm.checkMessageStatus = function (message) {
                  var message = _.find(vm.unreadMessageList, {
                        comment_key: (message.comment_key + "")
                  });
                  if (message) {
                        return true;
                  } else {
                        return false;
                  }
            }
            vm.previewAttachmentFile = function (fileName, commentKey) {
                  vm.previewModal(fileName);
                  return;
            }
            /* below code is moved to servie layer */
            /* vm.downloadAttachmentFile = function (fileName, commentKey) {
                  var secondaryWindow = $window.open(GENERAL_CONFIG.base_url + '/downloadCommentAttachement?fileName=' + fileName + "&commentKey=" + commentKey);
                  if (secondaryWindow == null) {
                        return;
                  }
                  // Check if there was a problem downloading the attachment.
                  secondaryWindow.onload = function () {
                        if (secondaryWindow.document.body.innerHTML.length > 0) {
                              $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                              secondaryWindow.close();
                        }
                  };
            } */

            vm.downloadAttachmentFile = function (fileName, commentKey,docKey) {
                  MessageFactory.downloadMsgAttachment(fileName, commentKey,docKey);
            }



            /////////////////////////////////updateFlagStatus (followUp or not)////////////////////////////

            $scope.updateMessageFlag = function (comment) {
                  var comment_flag = "1";
                  if (comment.flag == "1")
                        comment_flag = "0";
                  var objContext = {
                        comment_key: comment.comment_key,
                        flag_status: comment_flag,
                        messageType: comment.notify_type
                  };
                  MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.updateMessageFlag, objContext).then(function (data) {
                        comment.flag = comment_flag;
                        _.each($scope.commentsDeepClonedData, function (item) {
                              if (item.comment_key == comment.comment_key) {
                                    item.flag = comment_flag;
                              }
                        });
                  });
            }


            vm.markAsUnread = function (comment, isToggle, markAsStatus) {
                  var comment_status = 'new';
                  if (isToggle) { //toggle false  - status should be new
                        comment_status = (_.find(vm.unreadMessageList, {
                              comment_key: (comment.comment_key + "")
                        })) ? 'read' : 'new';
                  }
                  if (markAsStatus) {
                        if (comment.status == "archive" || $scope._SelectedComment.status == "archive")
                              comment_status = 'read';
                        else comment_status = markAsStatus;
                  }
                  var objContext = {
                        comment_key: comment.comment_key,
                        comment_status: comment_status
                  };
                  if (markAsStatus == 'archive') {
                        objContext.messageType = $scope._SelectedComment.notify_type;
                  }

                  /// find the unread message, if it found then push to the unreadMessageList

                  if (!_.find(vm.unreadMessageList, {
                              comment_key: (comment.comment_key + "")
                        }))
                        vm.unreadMessageList.push({
                              comment_key: (comment.comment_key + '')
                        });

                  //ellipse markasunread
                  var commentObj = _.find(vm.unreadMessageList, {
                        comment_key: (comment.comment_key + "")
                  });
                  if (isToggle && comment_status == 'read' && commentObj) {
                        vm.unreadMessageList = _.without(vm.unreadMessageList, commentObj);
                  }
                  //end ellipse

                  if (markAsStatus == 'archive') {
                        vm.unreadMessageList = _.without(vm.unreadMessageList, commentObj);
                  } else {
                        if ($scope.messageFilterData.filterShowAll != 1 &&
                              $scope.messageFilterData.filterShowAll !== "6")
                              $scope.filterMesssageByType();
                  }
                  //////////////////-- updated the Message status (archive, new or read ) --///////////////////////

                  MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.updateMessageStatus, objContext).then(function (data) {
                        if (markAsStatus == 'archive') {
                              $scope.loadComments();
                        } else if ($scope.messageFilterData.filterShowAll !== "6") {
                              //$scope.updateMessageCount(vm.unreadMessageList.length);
                              //vm.getUnreadMessages();
                              if (comment_status == "new") {
                                    $rootScope.updateMessageCountPlus();
                              } else if (comment_status == "read") {
                                    $rootScope.updateMessageCountMinus();
                              }
                        }
                        //   $event.stopPropagation();
                  });
            }

            ////////////////////////////////Delete Replied Message/////////////////////////////////////////

            vm.deleteRepliedMessages = function (message) {
                  vm.crudLoading = true;
                  var objContext = {
                        comment_key: message.comment_key,
                        message: message.replyMessage,
                        type: 'delete'
                  };
                  MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.commentReply, objContext).then(function (data) {
                        // $scope.loadComment(vm.active_comment)
                        vm.crudLoading = false;
                        $scope.comment.replyList = _.without($scope.comment.replyList,
                              _.find($scope.comment.replyList, {
                                    comment_key: message.comment_key
                              }));
                        //vm.replyMessage = "";
                        if (data.callSuccess && data.callSuccess === "1") {
                              //  AlertService.add("callSuccess", "");
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                        }
                  });
            }

            ////////////////////updateRepliedMessage/////////////////////////////////

            vm.updateRepliedMessages = function (message) {
                  vm.crudLoading = true;
                  var objContext = {
                        comment_key: message.comment_key,
                        message: message.replyMessage,
                        //notify: notify,
                        type: 'update'
                  };
                  MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.commentReply, objContext).then(function (data) {
                        //$scope.loadComment(vm.active_comment);
                        message.isUpdate = false;
                        vm.crudLoading = false;
                        if (data.callSuccess && data.callSuccess === "1") {
                              // AlertService.add("callSuccess", "");
                              message.message = message.replyMessage;
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                        }
                  });
            }

            function closeSearchWhenClickingElsewhere(event, callbackOnClose) {
                  var clickedElement = event.target;
                  if (!clickedElement) return;
                  var elementClasses = clickedElement.classList;
                  var clickedOnSearchDrawer = elementClasses.contains('fa-ellipsis-v') ||
                        elementClasses.contains('gg-drop-down-menu') ||
                        (clickedElement.parentElement !== null &&
                              clickedElement.parentElement.classList.contains('gg-message-action'));
                  if ((elementClasses.length) && !clickedOnSearchDrawer) {
                        callbackOnClose();
                  }
            }

            vm.resolveComment = function (comment_key, status, comment) {
                  console.log("Resolve just send to DB:", comment_key);
                  var assign = _.map(comment.assgignList, 'sso_id').toString();
                  var notify = _.map(comment.notifyList, 'sso_id').toString();
                  var task_action_item;
                  var task_status;
                  var dataObj = {
                        "comment_key": comment_key,
                        status: status,
                        assign: notify,
                        notify: assign,
                        task_action_item: (comment.task_action_item != null || comment.task_action_item != undefined) ? comment.task_action_item : (task_action_item != undefined || task_action_item != null) ? task_action_item : '',
                        task_status: (comment.task_status != null || comment.task_status != undefined) ? comment.task_status : (task_status != null || task_status != undefined) ? task_status : ''

                  };
                  /******************check whether user have previlages or not 8/21/2019********************************  */
                  if (USER_SETTINGS.user.system_access_level >= 3) {
                        $scope.loading.showCommentsLoading = false;
                        $scope.comment = {};
                        $scope.commentsDeepClonedData = _.without($scope.commentsDeepClonedData, _.find($scope.commentsDeepClonedData, {
                              comment_key,
                              comment_key
                        }));
                        $scope.commentsDeepClonedData = _.without($scope.commentsDeepClonedData, _.find($scope.commentsDeepClonedData, {
                              comment_key,
                              comment_key
                        }));
                        $scope.comments = _.cloneDeep($scope.commentsDeepClonedData);
                        MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.resolveComment, dataObj).then(function (_data) {
                              if (_data.callSuccess != 1) {
                                    AlertService.add("alert-error", "Comment could not be updated!", 4000);
                              } else {
                                    AlertService.add("alert-callSuccess", "Comment was callSuccessfully " + (status == 'closed' ? 'Resolved' : 'Un Resolved'), 4000);
                                    $scope.comment = {};
                                    $scope.loading.showCommentsLoading = false;
                                    $scope.filterMesssageByType();
                                    setTimeout(function () {
                                          $scope.loading.showCommentsLoading = false;
                                    }, 200);
                              }
                        })
                  } else {
                        AlertService.add("warning", "You don't have an access to resolve this message", 4000);
                  }

            }

            vm.launchBuoy = function () {
                  var _data = {};
                  // console.log("vm.comment " , vm.comment);
                  var location_obj = {
                        col_key: $scope.comment.col_key,
                        row_key: $scope.comment.row_key
                  }
                  var filter_json = JSON.parse($scope.comment.filter_json);
                  var table_state = JSON.parse($scope.comment.table_state);
                  var actionTempalte;
                  if(table_state && table_state.action_template_key){
                        actionTempalte = table_state.action_template_key;
                        delete table_state.action_template_key;
                  }
                  var type = $scope.type == 'dashboard'? 'dashboard' : 'comment'

                  workspaceFactory.loadLink(type, $scope.comment, $scope.comment.activity_key, $scope.comment.screen_key, filter_json, table_state, location_obj,actionTempalte);
            }
            $scope.loading.showCommentsLoading = true;
            $scope.loadComments();
            // setInterval(function () {
            //       if ($rootScope.checkReloadComments()) {
            //             $scope.loadComments(true);
            //       }
            // }, 5000);
            vm.clearMessageFilter = function () {
                  //&& $scope.messageFilterData.filterShowAll=='4'
                  if ($scope.messageFilterData.filterShowAll == '6' || $scope.messageFilterData.filterShowAll == '4' ||
                        $scope.messageFilterData.filterShowAll == '5' || $scope.messageFilterData.filterShowAll == '3' ||
                        $scope.messageFilterData.filterShowAll == '2' || $scope.messageFilterData.filterShowAll == '7') {
                        $scope.messageFilterData.filterShowAll = "1";
                        $scope.loading.showCommentsLoading = true;
                        $scope.loadComments();
                  }
                  $scope.messageFilterData = {
                        filterShowAll: '1',
                        'filterText': '',
                        lastFilterText: '-1'
                  };

                  $scope.doSearchFilter();
            }
      }

      function editCommentController($state, $rootScope, $scope, $http,
            GlobalService, $uibModalInstance, ModalFactory,
            AlertService, gridData, workspaceFactory, $timeout, MessageFactory,
            $uibModal, SERVER_CONFIG, JsonObjectFactory, $window, FileAttachementFactory, GENERAL_CONFIG, $sce, $filter) {
            var vm = this;
            vm.title = "Edit Comment";
            vm.comment_key = gridData.comment_key;
            vm.previewURL = gridData.previewURL;
            vm.cellData = "";
            vm.comment = {};
            vm.crudLoading = false;
            vm.SERVER_CONFIG = SERVER_CONFIG;
            vm.MessageFactory = MessageFactory;
            vm.$window = $window;
            vm.GENERAL_CONFIG = GENERAL_CONFIG;
            $scope.upload_url = GENERAL_CONFIG.base_url + URLS.uploadCommentAttachements;

            vm.actionItem = {
                  tags: [],
                  action_tag: '',
                  assign: [],
                  target_date: '',
                  action_item: ''

            }
            /* vm.tags = [{
                        name: "review",
                        value: "Review Meetings"
                  },
                  {
                        name: "booktoTax",
                        value: "Book To Tax"
                  }
            ]; */
            vm.tags = [];
            vm.dateOptions = {
                  minDate: new Date()
            }
            vm.reviewMeeting = function () {
                  if (vm.actionItem.review_meeting == 1)
                        vm.comment.is_public = "1";
                  else
                        vm.comment.is_public = "0";
            }

            _.each(_.keys(FileAttachementFactory.fileUploadConfig), function (item) {
                  if (!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
                        vm[item] = _.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
                  else vm[item] = FileAttachementFactory.fileUploadConfig[item];
            });
            /* **********************7/30/2019************************* */
            vm.getTags = function () {
                  var objContext = {}
                  MessageFactory.getData(GENERAL_CONFIG.base_url + '/getTagsList', objContext).then(function (data) {
                        //vm.replyMessage = "";
                        if (data.callSuccess && data.callSuccess === "1") {
                              //  AlertService.add("callSuccess", "");
                              vm.tags = data.tags;
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                        }
                  });
            }
            vm.getTags();

            function getComment(_comment_key) {

                  var params = {
                        comment_key: _comment_key
                  };
                  MessageFactory.CommentCrud(GENERAL_CONFIG.base_url + URLS.getComment, params).then(function (data) {
                        vm.crudLoading = false;
                        if (data.callSuccess && data.callSuccess === "1") {
                              vm.comment = data.comment;
                              vm.cellData = data.comment.value;
                              vm.comment.notify = [];
                              _.forEach(data.comment.notifyList, function (value, key) {
                                    var obj = {
                                          "NAME": value.display_name + "- (" + value.sso_id + ")",
                                          "VALUE": value.sso_id
                                    };
                                    vm.comment.notify.push(obj);
                              });
                              _.forEach(data.comment.assignList, function (value, key) {
                                    var obj = {
                                          "NAME": value.display_name + "- (" + value.sso_id + ")",
                                          "VALUE": value.sso_id
                                    };
                                    vm.actionItem.assign.push(obj);


                              });
                              vm.comment.subject = data.comment.subject;
                              vm.actionItem.target_date = new Date(data.comment.task_target_date); //$filter('date')(data.comment.task_target_date, 'yyyy/MM/dd');
                              vm.actionItem.action_item = parseInt(data.comment.task_action_item);
                              vm.attachmentTO.attachmented_files = [];
                              vm.attachmentTO.stored_files = [];
                              if (data.comment.attachmentList) {
                                    for (var i = 0; i < data.comment.attachmentList.length; i++) {
                                          var attachment = data.comment.attachmentList[i];
                                          vm.attachmentTO.attachmented_files.push(attachment.file_name.replace(data.comment.comment_key + "//", ''));
                                    }
                                    vm.attachmentTO.attachmented_files = _.uniq(vm.attachmentTO.attachmented_files);
                                    vm.attachmentTO.stored_files = _.cloneDeep(vm.attachmentTO.attachmented_files);
                              }
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                        }
                  });
            }
            vm.addUser = function (user) {
                  vm.comment.notify.push(user);
            }
            vm.removeUser = function (index) {
                  vm.comment.notify.splice(index, 1);
            }
            vm.cancel = function () {
                  vm.entities = [];
                  $uibModalInstance.dismiss('cancel');
            };
            vm.save = function () {
                  if (vm.addCommentFrom.$invalid) {
                        vm.addCommentFrom.$submitted = true;
                        AlertService.add("danger", "Please correct the errors below", 4000);
                        return;
                  }
                  if (vm.crudLoading) {
                        AlertService.add("info", "Please wait while we save this request", 4000);
                        return;
                  }
                  vm.crudLoading = true;
                  var notify = _.map(vm.comment.notify, 'VALUE').toString();
                  var assign = _.map(vm.actionItem.assign, 'VALUE').toString();
                 // var OldFileMap = _.difference(_.map(vm.comment.attachmentList, "file_name"),vm.attachmentTO.attachmented_files)
                 var OldFiles = [];
                 if(!_.isEqual(vm.attachmentTO.attachmented_files.length, vm.comment.attachmentList.length)){

                  var index;
                   var removedList =[];
                        _.each(vm.comment.attachmentList,function(item,key){
                              var isMatch = false;
                              _.each(vm.attachmentTO.attachmented_files, function(i,key1){
                                    if(item.file_name == i){
                                          isMatch = true;
                                    }
                              })
                              if(isMatch == false){
                                         index = key;
                                         removedList.push(item);
                              }
                        });
                        if(index>=0 ){
                              vm.comment.attachmentList.splice(index, 1);
                        }

                        if(removedList){
                              _.each(removedList,function(item){
                                    OldFiles.push({parent_key:vm.comment.comment_key,parent_type:vm.comment.type,doc_key:item.attachment_key,file_name:item.file_name})
                              })
                        }
                  }

                 var objContext = {
                       comment_key: vm.comment.comment_key,
                       is_public: vm.comment.is_public,
                       message: vm.comment.message,
                       notify: notify,
                       attachments: vm.attachmentTO.attachmented_files.toString(),
                       //state: $state.current.name,
                       //tags: vm.comment.tags
                       tags: vm.comment.tags,
                       oldFiles: JSON.stringify(OldFiles),
                       task_action_item: vm.actionItem.action_item,
                       task_target_date: $filter('date')(vm.actionItem.target_date, 'yyyy/MM/dd'),
                       assign: assign,
                       status: vm.comment.status,
                       subject : vm.comment.subject,
                       type: (gridData && gridData.type != null) ? gridData.type : "grid",
                       row_key: gridData  && gridData.row_key
                 };
                  MessageFactory.CommentCrud(GENERAL_CONFIG.base_url + URLS.updateComment, objContext).then(function (data) {
                        console.log(data);
                        vm.crudLoading = false;
                        if (data.callSuccess && data.callSuccess === "1") {
                              //REFRESH GRID MESSAGE SYSTEM TO SHOW THIS MESSAGE
                              $uibModalInstance.dismiss('cancel');
                              isUpdateClicked = true;
                              $rootScope.setReloadCommentsFlag(true);
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                        }
                  });
            }

            vm.getUser = function (val) {
                  var params = {
                        "action_id": 76,
                        "search_string": val
                  }

                  return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                        if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                              AlertService.add("error", data.errorMessage);
                        } else {
                              if (data.jsonObject.length) {

                                    console.log(" data", data);
                                    return data.jsonObject;
                              }
                        }
                  });
            };

            function init() {
                  getComment(vm.comment_key);
            }

            init();

      }

      var isUpdateClicked = false;

      function addCommentController($state, $rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory,
            AlertService, gridData, workspaceFactory, $timeout, MessageFactory, $uibModal, JsonObjectFactory, SERVER_CONFIG, $window, FileAttachementFactory, GENERAL_CONFIG, $filter) {
            var vm = this;
            vm.SERVER_CONFIG = SERVER_CONFIG;
            vm.MessageFactory = MessageFactory;
            vm.$window = $window;
            vm.GENERAL_CONFIG = GENERAL_CONFIG;
            vm.title = "Add Comment";
            console.log("gridData :  ", gridData);

            vm.gridData = gridData;
            vm.cellData = "";
            $scope.upload_url = GENERAL_CONFIG.base_url + URLS.uploadCommentAttachements;

            if (gridData.selected)
                  vm.cellData = gridData.selected.value;

            else gridData.selected = {};
            vm.crudLoading = false;
            vm.comment = {
                  comment_key: null,
                  is_public: 0,
                  message: "",
                  notify: [],
                  attachments: [],
                  tags: [],
                  follow_up: 0,
                  follow_up_by: null,
                  attachment_file: '',
                  logoFilesMarkedForDeletion: [],
                  flow: {},
                  subject: ""
            }

            vm.actionItem = {
                  tags: [],
                  action_tag: '',
                  assign: [],
                  target_date: '',
                  action_item: ''

            }
            vm.tags = [];
            /* vm.tags = [{
                        name: "review",
                        value: "Review Meetings"
                  },
                  {
                        name: "booktoTax",
                        value: "Book To Tax"
                  }
            ]; */
            vm.dateOptions = {
                  minDate: new Date()
            }
            vm.reviewMeeting = function () {
                  if (vm.actionItem.review_meeting == 1)
                        vm.comment.is_public = "1";
                  else
                        vm.comment.is_public = "0";
            }
            vm.isShowTag = false;
            vm.toggleTag = function () {
                  vm.isShowTag = !vm.isShowTag;
            }
            vm.tagCancel = function () {
                  vm.isShowTag = !vm.isShowTag;
            }
            vm.SERVER_CONFIG = SERVER_CONFIG;
            vm.MessageFactory = MessageFactory;
            _.each(_.keys(FileAttachementFactory.fileUploadConfig), function (item) {
                  if (!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
                        vm[item] = _.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
                  else vm[item] = FileAttachementFactory.fileUploadConfig[item];
            });
            vm.removeUser = function (index) {
                  console.log(index);

                  vm.comment.notify.splice(index, 1);
            }

            /* 7/1/2019 assign user */
            vm.assignUser = function (user) {
                  vm.actionItem.assign.push(user);
            }

            vm.removeAssignUser = function (index) {
                  vm.actionItem.assign.splice(index, 1);
            }

            vm.updateTagsTO = function () {
                  vm.actionItem.tags.push(vm.actionItem.action_tag);
            }
            vm.removeTag = function (index) {
                  vm.actionItem.tags.splice(index, 1);
            }

            /*  */
            vm.addUser = function (user) {
                  console.log(user);
                  vm.comment.notify.push(user);
            }


            vm.cancel = function () {
                  vm.entities = [];
                  $uibModalInstance.dismiss('cancel');
            };
            /* **********************7/30/2019************************* */
            vm.getTags = function () {
                  var objContext = {}
                  MessageFactory.getData(GENERAL_CONFIG.base_url + '/getTagsList', objContext).then(function (data) {
                        //vm.replyMessage = "";
                        if (data.callSuccess && data.callSuccess === "1") {
                              //  AlertService.add("callSuccess", "");
                              vm.tags = data.tags;
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                        }
                  });
            }
            vm.getTags();
            vm.tagcrudLoading = false;
            vm.savetag = function () {
                  var params = {
                        tag_name: vm.tag.tag_name,
                        tag_desc: vm.tag.tag_desc
                  }
                  vm.tagcrudLoading = true;
                  MessageFactory.CommentCrud(GENERAL_CONFIG.base_url + URLS.addTag, params).then(function (data) {
                        console.log(data);

                        if (data.callSuccess && data.callSuccess === "1") {
                              AlertService.add("success", "Tag saved successfully", 4000);
                              vm.getTags();
                              vm.tagcrudLoading = false;
                              vm.tag.tag_name = '';
                              vm.tag.tag_desc = '';
                              vm.toggleTag();
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                              vm.tagcrudLoading = false;
                        }
                  });

            }
            vm.getUser = function (val) {
                  var params = {
                        "action_id": 76,
                        "search_string": val
                  }

                  return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                        if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                              AlertService.add("error", data.errorMessage);
                        } else {
                              if (data.jsonObject.length) {

                                    console.log(" data", data);
                                    return data.jsonObject;
                              }
                        }
                  });
            };

            vm.addNotifyUser = function () {
                  console.log("-----------------------------------------------")
                  var modalObj = {};
                  var _data = [];
                  modalObj = [];
                  vm.openModal(modalObj, _data);
            };


            vm.openModal = function (modalObj, _data) {
                  $scope.rowData = _data.rowData;
                  $scope.gridData = _data;
                  var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: modalObj.template,
                        controller: modalObj.controler,
                        size: modalObj.size,
                        resolve: {
                              rowData: function () {
                                    return $scope.rowData;
                              },
                              gridData: function () {
                                    return $scope.gridData;
                              }
                        }
                  });

                  modalInstance.result.then(function (selectedItem) {
                        $scope.selected = selectedItem;
                  }, function () {
                        $log.info('Modal dismissed at: ' + new Date());
                        $log.info('$scope.rowData', $scope.rowData);
                  });
            };

            vm.save = function () {


                  if (vm.addCommentFrom.$invalid) {
                        vm.addCommentFrom.$submitted = true;
                        AlertService.add("danger", "Please correct the errors below", 4000);
                        return;

                  }

                  if (vm.crudLoading) {
                        AlertService.add("info", "Please wait while we save this request", 4000);
                        return;
                  }

                  vm.crudLoading = true;

                  var filterCached = null;//= (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                  var row_key = "";
                  var col_key = "";
                  var tableState = {};
                  var element = "";
                  if (gridData.selected) {
                        row_key = gridData.selected.row_id;
                        col_key = gridData.selected.col_id;
                        tableState = gridData.tableState;
                        element = gridData.selected.select_type;
                  }
                  var notify = _.map(vm.comment.notify, 'VALUE').toString();
                  var assign = _.map(vm.actionItem.assign, 'VALUE').toString();
                 /*  var screenKey = workspaceFactory.activeScreen.screen_key ? workspaceFactory.activeScreen.screen_key : '-100',
                        activityKey = workspaceFactory.activeScreen.activity_key ? workspaceFactory.activeScreen.activity_key : '-102'; */
                  var screenKey = !MessageFactory.modalFromMessage ? workspaceFactory.activeScreen.screen_key : '-100',
                        activityKey = !MessageFactory.modalFromMessage ? workspaceFactory.activeScreen.activity_key : '-102';
                  var selectedFilterList = [];

                  // TODO: FUture Use
                  // _.each(_.filter(workspaceFactory.activeScreen.filters.filters, function (filter) {
                  //             return ['tax_year', 'scenario', 'parent_me_string'].indexOf(filter.param_name) > -1
                  //       }),
                  //       function (filter) {
                  //             if (filter.selected && _.isArray(filter.selected) && filter.selected.length) {
                  //                   var f = {};
                  //                   var x = {};
                  //                   if (filter.param_name === 'parent_me_string') {
                  //                         f[filter.param_name] = [];
                  //                         const pushToList = function (list) {
                  //                               _.each(list, function (selected) {
                  //                                     f[filter.param_name].push({
                  //                                           value: selected.mekey,
                  //                                           label: selected.name
                  //                                     });
                  //                                     if (selected.children && selected.children.length) {
                  //                                           pushToList(selected.children);
                  //                                     }
                  //                               });
                  //                         }
                  //                         x = _.filter(filter.values, function (val) {
                  //                               return filter.selected.indexOf(val.key) > -1
                  //                         });
                  //                         pushToList(x);
                  //                         selectedFilterList.push(f);
                  //                   } else {
                  //                         f[filter.param_name] = _.filter(filter.values, function (val) {
                  //                               return filter.selected.indexOf(val.key) > -1Me
                  //                   var f = {};
                  //                   f[filter.param_name] = [{
                  //                         label: filter.selected.label ? filter.selected.label : filter.selected.LABEL,
                  //                         value: filter.selected.value ? filter.selected.value : filter.selected.VALUE
                  //                   }];
                  //                   selectedFilterList.push(f);
                  //             }
                  //       });
                  console.log(JSON.stringify(selectedFilterList));
                  var action_template_key =[];
                  if(!MessageFactory.modalFromMessage){
                        if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.data.actionTemplates){
                              action_template_key = _.filter(workspaceFactory.activeScreen.data.actionTemplates,{selectedAction:true})
                        }
                        filterCached =  (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};

                  }



                  if(action_template_key && action_template_key.length>0){
                        tableState.action_template_key = action_template_key[0].action_template_key;
                  }

                  var objContext = {
                        state: $state.current.name,
                        filters: JSON.stringify(filterCached),
                        tableState: JSON.stringify(tableState),
                        data: JSON.stringify(_.omit(gridData.selected,'element')),
                        type: (gridData && gridData.type != null) ? gridData.type : "grid",
                        screen_key: screenKey,
                        activity_key: activityKey,
                        row_key: row_key,
                        col_key: col_key,
                        element: gridData.selected.select_type,
                        comment_key: vm.comment.comment_key,
                        is_public: vm.comment.is_public,
                        message: vm.comment.message,
                        notify: notify,
                        attachments: vm.attachmentTO.attachmented_files.toString(),
                        tags: JSON.stringify(selectedFilterList), //vm.comment.tags,
                        follow_up: vm.comment.follow_up,
                        follow_up_by: vm.comment.follow_up_by,
                        value: vm.cellData,
                        subject: vm.comment.subject,
                        task_action_item: vm.actionItem.action_item ? vm.actionItem.action_item : 0,
                        task_target_date: $filter('date')(vm.actionItem.target_date, 'yyyy/MM/dd'),
                        assign: assign
                  };



                  MessageFactory.CommentCrud(GENERAL_CONFIG.base_url + URLS.addComment, objContext).then(function (data) {
                        console.log(data);
                        vm.crudLoading = false;
                        if (data.callSuccess && data.callSuccess === "1") {
                              $uibModalInstance.dismiss('cancel');
                              vm.attachmentTO.attachmented_files = [];
                              $rootScope.setReloadCommentsFlag(true);
                              MessageFactory.clickedFromMessage(false);
                        } else {
                              AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                              vm.crudLoading = false;
                        }
                  });

            };

      }

      function addAttachmentController($scope, GENERAL_CONFIG, $state, $rootScope, $uibModalInstance, Dashboard901Factory,
            FileAttachementFactory, SERVER_CONFIG, AlertService, rowData, gridData, colData, MessageFactory, workspaceFactory,ModalFactory) {
                  console.log("ModalFactory",ModalFactory);
            var vm = this;
            vm.title = "Add Attachements";
            vm.entities = [];
            vm.crudLoading = false;
            vm.rowData = rowData;
            vm.colData = colData;
            vm.gridData = gridData;
            vm.cellData = "";
            vm.showUploadAttach = ModalFactory.response.data.modalScreen.modal_name? ModalFactory.response.data.modalScreen.modal_name : gridData.callName;
            vm.btnDisabled = false;

            if (gridData.selected)
                  vm.cellData = gridData.selected.value;
            else gridData.selected = {};
            console.log("ROWDATA FROM MESSAGE", vm.rowData);
            console.log("MY COLUMN data", vm.colData);
            console.log("Grid Data", gridData);
            vm.SERVER_CONFIG = SERVER_CONFIG;
            $scope.upload_url = GENERAL_CONFIG.base_url + '/uploadCommentAttachements';
            var URLS = {
                  saveAttachemnt: GENERAL_CONFIG.base_url + '/saveAttachmentDocuments'
            }
            vm.MessageFactory = MessageFactory;
            _.each(_.keys(FileAttachementFactory.fileUploadConfig), function (item) {
                  if (!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
                        vm[item] = _.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
                  else vm[item] = FileAttachementFactory.fileUploadConfig[item];
            });
            var trans_json = [];

           var OldFiles = [];
           if(vm.attachmentList){
                  vm.attachmentList.forEach(el => {
                        let index = _.findIndex(vm.attachmentTO.attachmented_files, e => e === el.file_name);
                        if (index >= 0) {
                        vm.attachmentList.splice(index, 1);
                        }
                  });if(vm.attachmentList){
                  _.each(vm.attachmentList,function(item){
                        OldFiles.push({parent_key:vm.comment.comment_key,parent_type:vm.comment.type,doc_key:item.attachment_key,file_name:item.file_name})
                  })
             }
           }

           function init(){
            $scope.crudLoading = false;
                  if( !_.isArray(gridData)){
                        var obj  = {}
                        obj.row_id = gridData.rowData.object_id;
                        obj.data = gridData.rowData;
                        gridData = [];

                        gridData.push(obj);

                  }
                  if (typeof rowData !== 'undefined') {
                              vm.rowData = rowData;
                  }
                  vm.entities  = _.clone(gridData);
            }
            vm.removeEntity = function(_index){
                  vm.entities.splice(_index,1);
                  if( vm.entities.length === 0 ){
                      $uibModalInstance.dismiss('cancel');
                  }
              }

              vm.saveAttachment = function () {
                  vm.btnDisabled = true;
                  //
                  if(vm.attachmentTO && vm.attachmentTO.attachmented_files.length==0){
                        AlertService.add("warning","Please add the documents before save",4000);

                        vm.btnDisabled = false;
                        return;
                  }
                    if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.data.settings.batch_select == 1  && workspaceFactory.activeScreen.data.settings.documentation == 1){
                          var gridFilters = {
                                colSearch :[]
                          }
                          if(rowData == null || rowData == undefined){
                                for ( var index_ in vm.entities) {
                                      if(index_ != "selected"){
                                                  var doc_header_key = vm.entities[index_].data[workspaceFactory.activeScreen.data.settings.doc_header_key];
                                                  var header_type = vm.entities[index_].data[workspaceFactory.activeScreen.data.settings.doc_header_type];
                                            trans_json.push({
                                                       parent_key: vm.entities[index_].row_id,
                                                        parent_type:workspaceFactory.activeScreen.label,
                                                        combination_keys:vm.entities[index_].data.COMBINATION_KEY,
                                                        object_id : vm.entities[index_].row_id,
                                                        combination_key:vm.entities[index_].data.COMBINATION_KEY,
                                                        me_key: ( vm.entities[index_].data.ME_KEY)!=null? vm.entities[index_].data.ME_KEY:"",
                                                        doc_header_key:doc_header_key,
                                                        header_type: header_type!= null ?header_type: workspaceFactory.activeScreen.label
                                            });
                                      }
                                }
                          }

                    }

                    var screenKey = workspaceFactory.activeScreen.screen_key ? workspaceFactory.activeScreen.screen_key : '-100',
                        activityKey = workspaceFactory.activeScreen.activity_key ? workspaceFactory.activeScreen.activity_key : '-102',
                        grid_key = workspaceFactory.activeScreen.data.settings.grid_key ? workspaceFactory.activeScreen.data.settings.grid_key : '-103';
                    var filterCached = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                    var row_key = "";
                    var col_key = "";
                    var tableState = {};
                    var element = "";
                    if (gridData.selected) {
                          row_key = gridData.selected.row_id;
                          col_key = gridData.selected.col_id;
                          tableState = gridData.tableState;
                          element = gridData.selected.select_type;
                    }
                    var filter_json = {"activity_key":activityKey,"screen_key":screenKey,filter_json:filterCached,filters:[workspaceFactory.activeScreen.data.settings.doc_header_key]}

                    var objContext = {
                          state: $state.current.name,
                        //  filters: JSON.stringify(filterCached),
                          tableState: JSON.stringify(tableState),
                          data: JSON.stringify(gridData.selected), //chaged to clob below 1/21/2019
                          type: "grid",
                          screen_key: screenKey,
                          activity_key: activityKey,
                          row_key: row_key,
                          col_key: col_key,
                          element: gridData.selected.select_type,
                          callNameA: vm.callName == null || vm.callName == undefined? ModalFactory.response.data.modalScreen.modal_name: gridData.callName,
                          value: vm.cellData,
                          trans_json: JSON.stringify(trans_json),
                          parent_key : vm.parent_key,
                      //    parent_type : vm.parent_type,
                          attachments: vm.attachmentTO.attachmented_files.toString(),
                          oldFiles: JSON.stringify(OldFiles),
                          docType: 'transaction',
                          filters:JSON.stringify(filter_json),
                          doc_purpose: vm.replyMessage,
                          doc_comment_key : '',
                          type: 'add',
                          grid_key: grid_key,
                          screen_name : workspaceFactory.activeScreen.label,
                          activity_name: workspaceFactory.activeScreen.activity_name

                    };
                    MessageFactory.saveAttachment(URLS.saveAttachemnt, objContext).then(function (data) {
                          console.log(data);
                          vm.crudLoading = false;
                          //   if (data.callSuccess && data.unreadMessageCount === "callSuccess") {
                          if (data.callSuccess === "1") {
                                console.log("attachem ++++ data", data);
                                $uibModalInstance.dismiss('cancel');
                                AlertService.add("success", "Documents Saved Successfully.", 4000);
                                /*2/18/2019 gridRefresh on Combination Keys*/
                                if(trans_json && _.isArray(trans_json) && trans_json.length<=10){
                                    var arg1 = _.uniqBy(trans_json,function(e){
                                          return e.combination_keys;
                                    })
                                    var arg2= (Array.prototype.map.call(arg1, s=> s.combination_keys).toString());
                                      var args = {
                                            combination_keys : arg2,
                                            gridFilter :{
                                                  COMBINATION_KEY : arg2
                                            }
                                      }
                                      $rootScope.$emit('gridUpdate', args);
                                }else{
                                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                                          "refresh": true
                                      });
                                }

                                vm.btnDisabled = false;

                          } else if(data.callSuccess == null || data.callSuccess) {
                                vm.btnDisabled = false;
                                var erroMessge =""
                                if(data.errorMessage && (data.errorMessage!= "" || data.errorMessage != undefined)){
                                    erroMessge = data.errorMessage
                                }else{
                                    erroMessge  = "Error occurred fetching data. If this continues please contact support"
                                }
                                AlertService.add("error",erroMessge , 4000);
                          }
                    });
              }
            vm.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
            };

            $scope.timeago = function (date) {
                  var date = new Date(date);
                  return moment(date).fromNow();
            }
            init();


      }

      function editAttachmentController($scope, GENERAL_CONFIG, $state, $rootScope, $uibModalInstance, Dashboard901Factory,
      FileAttachementFactory, SERVER_CONFIG, AlertService, rowData, gridData, colData, MessageFactory, workspaceFactory, $filter,ModalFactory,USER_SETTINGS,$window,$uibModal) {

            var vm = this;
            vm.title = "Edit Attachment";
            vm.SERVER_CONFIG = SERVER_CONFIG;
            vm.attach = {};
            vm.enableEditAttachBtn = false;
            vm.btnDisabled = false;


            $scope.upload_url = GENERAL_CONFIG.base_url + '/uploadCommentAttachements';
            vm.MessageFactory = MessageFactory;
            _.each(_.keys(FileAttachementFactory.fileUploadConfig), function (item) {
                  if (!_.isFunction(FileAttachementFactory.fileUploadConfig[item]))
                        vm[item] = _.cloneDeep(FileAttachementFactory.fileUploadConfig[item]);
                  else vm[item] = FileAttachementFactory.fileUploadConfig[item];
            });

            vm.cancel = function () {
                  vm.entities = [];
                  $uibModalInstance.dismiss('cancel');
            };
            function init(){
                  $scope.crudLoading = false;
                        if( !_.isArray(gridData)){
                              var obj  = {}
                              obj.row_id = gridData.rowData.object_id;
                              obj.data = gridData.rowData;
                              gridData = [];

                              gridData.push(obj);

                        }
                        if (typeof rowData !== 'undefined') {
                                    vm.rowData = rowData;
                        }
                        vm.entities  = _.clone(gridData);
                  }
                  vm.removeEntity = function(_index){
                        vm.entities.splice(_index,1);
                        if( vm.entities.length === 0 ){
                            $uibModalInstance.dismiss('cancel');
                        }
                    }

            function initFg() {
                  console.log(vm.rowData);
                  console.log(vm.colData);
                  init();
                  getData();
            };
            initFg();
            vm.existingFilesList = [];

            function getData() {
                  vm.attachmentList = [];
                        let parent_key = rowData.OBJECT_ID;
                        if(!parent_key) {
                              parent_key = rowData.object_id;
                        }
                        var params = {
                              parent_key: parent_key,
                              parent_type: workspaceFactory.activeScreen.label,
                              doc_keys: rowData.DOC_KEYS,
                              doc_header_key: rowData[workspaceFactory.activeScreen.data.settings.doc_header_key]//rowData.
                        }
                        MessageFactory.getComments(GENERAL_CONFIG.base_url+'/loadFileAttachments', params).then(function (res) {
                              console.log(res);
                              vm.attachmentTO.attachmented_files = [];
                              vm.attachmentTO.stored_files = [];
                              vm.attachmentTO.attachmented_files = vm.attachmentList;
                              vm.attachmentList = res.comment.attachmentList;

                              if (res.comment.attachmentList) {
                                    for (var i = 0; i < res.comment.attachmentList.length; i++) {
                                          var attachment = res.comment.attachmentList[i];
                                          attachment.fS = vm.formatBytes(attachment.file_size); // gets the files size with conversion val
                                          vm.attachmentTO.attachmented_files.push(attachment.file_name);
                                    }
                                    vm.attachmentTO.attachmented_files = _.uniq(vm.attachmentTO.attachmented_files);
                                    vm.attachmentTO.stored_files = _.cloneDeep(vm.attachmentTO.attachmented_files);
                                    vm.existingFilesList = _.cloneDeep(vm.attachmentList);
                              }
                              if(res.comment.docCommentsList){
                                    vm.docComments = res.comment.docCommentsList
                                    for (var i = 0; i < res.comment.replyList.length; i++) {
                                          vm.docComments.replyList[i].dateM = new Date(res.comment.replyList[i].date).getTime();
                                          vm.docComments.replyList[i].isSender = res.comment.replyList[i].sso_id == USER_SETTINGS.user.sso_id;
                                    }
                              }

                              // Used to enable the Edit/Add attachments button in the Add commnet modal, based on the user access level..
                              _.forEach(vm.attachmentList, function(item) {
                                    if((item.created_by && item.created_by == USER_SETTINGS.user.sso_id) || USER_SETTINGS.user.system_access_level >= 3) {
                                          vm.enableEditAttachBtn = true;
                                    }
                              });
                        }, function (err) {

                        });

            };

            // converts the fize to actual bytes..
            vm.formatBytes = function (bytes) {
                  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                  if (bytes == 0) return '0 Byte';
                  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            };
            var trans_json= [];
            vm.removedFileList = [];
            trans_json.push({
                  docType: 'transaction',
                  parent_key: rowData.OBJECT_ID,
                  parent_type:workspaceFactory.activeScreen.label,
                   object_id : rowData.OBJECT_ID,
                   combination_key:rowData.COMBINATION_KEY,
                   me_key: (rowData.ME_KEY)!=null?(rowData.ME_KEY):"",
                   doc_header_key: rowData[workspaceFactory.activeScreen.data.settings.doc_header_key],
                   header_type: rowData[workspaceFactory.activeScreen.data.settings.doc_header_type]!=null? rowData[workspaceFactory.activeScreen.data.settings.doc_header_type]: workspaceFactory.activeScreen.label

            });
            vm.saveAttachment = function () {
                  vm.btnDisabled = true;
                  vm.OldFiles = [];
                /*   if(USER_SETTINGS.user.cli) */
                  if(!_.isEqual(vm.attachmentTO.attachmented_files.length, vm.attachmentList.length)){

                        var index;
                        var removedList =[];
                        _.each(vm.attachmentList,function(item,key){
                              var isMatch = false;
                              _.each(vm.attachmentTO.attachmented_files, function(i,key1){
                                    if(item.file_name == i){
                                          isMatch = true;
                                         // index = key;
                                    }
                              })
                              if(isMatch == false){
                                         index = key;
                                         removedList.push(item);
                              }
                        });
                        if(index>=0 ){
                              vm.attachmentList.splice(index, 1);
                        }
                        if(removedList && vm.removedFileList){
                              var delteList = removedList || vm.removedFileList;
                              _.each(delteList,function(item){
                                    vm.OldFiles.push({
                                          parent_key:item.parent_key,
                                          parent_type:item.parent_type,
                                          doc_key:item.attachment_key,
                                          file_name:item.file_name,
                                          doc_batch_id:item.doc_batch_id,
                                          object_id: item.parent_key,
                                          combination_key:rowData.COMBINATION_KEY,
                                          me_key: (rowData.ME_KEY)!=null?(rowData.ME_KEY):"",
                                          doc_header_key: rowData[workspaceFactory.activeScreen.data.settings.doc_header_key],
                                          header_type: rowData[workspaceFactory.activeScreen.data.settings.doc_header_type]!=null? rowData[workspaceFactory.activeScreen.data.settings.doc_header_type]: workspaceFactory.activeScreen.label })
                              })
                        }
                  }
                  if(vm.removedFileList){
                        _.each(vm.removedFileList,function(item){
                              vm.OldFiles.push({
                                    parent_key:item.parent_key,
                                    parent_type:item.parent_type,
                                    doc_key:item.attachment_key,
                                    file_name:item.file_name,
                                    doc_batch_id:item.doc_batch_id,
                                    object_id: item.parent_key,
                                    combination_key:rowData.COMBINATION_KEY,
                                    me_key: (rowData.ME_KEY)!=null?(rowData.ME_KEY):"",
                                    doc_header_key: rowData[workspaceFactory.activeScreen.data.settings.doc_header_key],
                                    header_type: rowData[workspaceFactory.activeScreen.data.settings.doc_header_type]!=null? rowData[workspaceFactory.activeScreen.data.settings.doc_header_type]: workspaceFactory.activeScreen.label })
                        })

                  }
                  var screenKey = workspaceFactory.activeScreen.screen_key ? workspaceFactory.activeScreen.screen_key : '-100',
                        activityKey = workspaceFactory.activeScreen.activity_key ? workspaceFactory.activeScreen.activity_key : '-102',
                        grid_key = workspaceFactory.activeScreen.data.settings.grid_key ? workspaceFactory.activeScreen.data.settings.grid_key : '-103';;
                  var filterCached = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                  var objContext = {
                        docType: 'transaction',
                        parent_key: rowData.OBJECT_ID,
                        parent_type: workspaceFactory.activeScreen.label ,
                        attachments: vm.attachmentTO.attachmented_files.toString(),
                        oldFiles: JSON.stringify(vm.OldFiles),//_.map(vm.attachmentList, "file_name").toString()
                        callNameA: vm.callName == null || vm.callName == undefined? ModalFactory.response!=null?ModalFactory.response.data.modalScreen.modal_name:gridData.callName: '',
                        grid_key : grid_key,
                        screen_name: workspaceFactory.activeScreen.label,
                        trans_json: JSON.stringify(trans_json)

                  };
                  MessageFactory.saveAttachment(GENERAL_CONFIG.base_url + "/saveAttachmentDocuments", objContext).then(function (data) {
                        console.log(data);
                        vm.crudLoading = false;
                        if (data.callSuccess === "1") {

                              $rootScope.$broadcast("loadGridAttachments");
                              console.log("attachem ++++ data", data);
                              $uibModalInstance.dismiss('cancel');
                              AlertService.add("success", "attachment saved callSuccessfully.", 4000);
                               /*2/18/2019 gridRefresh on Combination Keys*/
                              var args = {
                                    combination_keys : rowData.COMBINATION_KEY,
                                    gridFilter :{
                                          COMBINATION_KEY : rowData.COMBINATION_KEY
                                    }
                              }
                              $rootScope.$emit('gridUpdate', args);
                              vm.btnDisabled = false;
                        } else {
                              vm.btnDisabled = false;
                              var erroMessge =""
                              if(data.errorMessage && (data.errorMessage!= "" || data.errorMessage != undefined)){
                                  erroMessge = data.errorMessage
                              }else{
                                  erroMessge  = "Error occurred fetching data. If this continues please contact support"
                              }
                              AlertService.add("error",erroMessge , 4000);
                        }
                  });
            }
            /* ************************download single file at a time************************* */
            vm.downloadAttachmentFile = function (fileName, commentKey,docKey) {
                  MessageFactory.downloadMsgAttachment(fileName, rowData.OBJECT_ID,docKey);
            }

            vm.revmoveFile = function(file,index){

                  if(_.filter(vm.attachmentList,{file_name:file}).length ==0){
                        vm.removeAttachment(vm,file,index);
                  }else{
                        _.each(vm.attachmentList,function(item,key){

                              var isMatch = false;
                              if(vm.attachmentTO.attachmented_files && vm.attachmentTO.attachmented_files.length>0){
                                    _.each(vm.attachmentTO.attachmented_files, function(i,key1){
                                          //  if(item.file_name == file){
                                            if(_.isEqual(item.file_name, file)){
                                                  if(((item.created_by && item.created_by == USER_SETTINGS.user.sso_id) || USER_SETTINGS.user.system_access_level >= 3)) {
                                                        isMatch = true;
                                                        vm.removeAttachment(vm,file,index);
                                                        vm.removedFileList.push(item);
                                                  // index = key;
                                                  }
                                                  else{
                                                  //  index = key;
                                                        if(isMatch == false && index== key1){
                                                              AlertService.add('warning','Can not delete the un-authorized files',4000);
                                                        }
                                                  }
                                            }
              
                                      });  
                              }
                              
                              if(isMatch == false){
                                    index = key;
                              }
      
                        });   
                  }
            }
            //Used to delete the Exisitng attachment list from the list..
            // vm.deleteAttachmentFile = function(index) {
            //       console.log(index);
            //       vm.attachmentList.splice(index, 1);
            // }

            //Used to enable Documents upload Div and delecte icons in Exisitng attachment lists..
            vm.enableDeleteIcn = false;
            vm.editAttachments = function() {
                  vm.enableDeleteIcn = true;
            }

            /**************download All attached files*********** */
            vm.downloadAll = function(fileList,commentKey,docKey){
                  var fileList = _.map(vm.attachmentList,"file_name").toString()
                  var docKeyList = _.map(vm.attachmentList,"attachment_key").toString()
                  MessageFactory.downloadAllDocuments(fileList, rowData.OBJECT_ID,docKeyList);
            }

            /* ******************************update/delete Document Comments************************* */
            vm.updateDocMessage = function(docComment,type,index){
                  var objContext = {
                        parent_key: rowData.OBJECT_ID,
                        parent_type: workspaceFactory.activeScreen.label,
                        doc_purpose: docComment.replyMessage != null ? docComment.replyMessage : docComment.message,
                        doc_comment_key : docComment.doc_comment_key,
                        type: type,
                        doc_header_key:  rowData[workspaceFactory.activeScreen.data.settings.doc_header_key]
                  };

                  MessageFactory.getComments(GENERAL_CONFIG.base_url+'/saveDocComment', objContext).then(function (res) {
                        console.log(res);
                        if(res.callSuccess == 1){
                              AlertService.add("success", "attachment saved callSuccessfully.", 4000);
                              if(objContext.type == 'delete'){
                                    vm.docComments.splice(index, 1);
                              }

                        }else{
                              AlertService.add("error", res.errorMessage, 4000);
                        }

                  }, function (err) {

                });
            }

            /* ******************************add Document Comments*********************************** */
            vm.addDocComment = function($event){
                  if ($event && ($event.keyCode != 13 || $event.which != 13)) {
                        return true;
                  }
                  var objContext = {
                        parent_key: rowData.OBJECT_ID,
                        parent_type: workspaceFactory.activeScreen.label,
                        doc_purpose: vm.replyMessage,
                        doc_comment_key : '',
                        type: 'add',
                        doc_header_key:  rowData[workspaceFactory.activeScreen.data.settings.doc_header_key]

                  };

                  MessageFactory.getComments(GENERAL_CONFIG.base_url+'/saveDocComment', objContext).then(function (res) {
                        console.log(res);
                        if(res.callSuccess == 1){
                              vm.docComments = res.replyList
                              vm.replyMessage = '';
                        }else{
                               AlertService.add("error", res.errorMessage, 4000);
                        }

                  }, function (err) {

                  });

            }

            vm.showReplySubMenu = function (comment) {
                  if (comment === undefined) {
                        comment = vm.lastReplySubMenuComment;
                        comment.isShowReplySubMenu = true;
                  } else {
                        vm.lastReplySubMenuComment = comment;
                  }
                  comment.isShowReplySubMenu = !comment.isShowReplySubMenu
                  if (comment.isShowReplySubMenu) {
                        $window.onclick = function (event) {
                              closeSearchWhenClickingElsewhere(event, vm.showReplySubMenu);
                        };
                  } else {
                        if (vm.lastSubMenuColastReplySubMenuCommentmment) {
                              vm.lastReplySubMenuComment.isShowReplySubMenu = false;
                        }
                        comment.isShowReplySubMenu = false;
                        $window.onclick = null;
                        //   $scope.$apply(); //--> trigger digest cycle and make angular aware.
                        setTimeout(function () {
                              vm.lastReplySubMenuComment = comment;
                              $scope.$apply();
                        }, 300);
                  }
            }
            function closeSearchWhenClickingElsewhere(event, callbackOnClose) {
                  var clickedElement = event.target;
                  if (!clickedElement) return;
                  var elementClasses = clickedElement.classList;
                  var clickedOnSearchDrawer = elementClasses.contains('fa-ellipsis-v') ||
                        elementClasses.contains('gg-drop-down-menu') ||
                        (clickedElement.parentElement !== null &&
                              clickedElement.parentElement.classList.contains('gg-message-action'));
                  if ((elementClasses.length) && !clickedOnSearchDrawer) {
                        callbackOnClose();
                  }
            }
            $scope.timeago = function (date) {
                  var date = new Date(date);
                  return moment(date).fromNow();
            }
            vm.previewModal = function (fileName,parent_key) {
                  var ctrlString = "EditCommentController as ctrl";
                  vm.gridData = {};
                  //vm.gridData.comment_key = $scope.comment.comment_key;
                  vm.gridData.previewURL = GENERAL_CONFIG.base_url + "/previewCommentAttachement?commentKey=" + parent_key + "&fileName=" + fileName;
                  vm.rowData = [];
                  vm.colData = [];
                  var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: "app/components/messages/templates/file-preview-modal.html",
                        controller: ctrlString,
                        size: "md",
                        resolve: {
                              rowData: function () {
                                    return vm.rowData;
                              },
                              colData: function () {
                                    return vm.colData;
                              },
                              gridData: function () {
                                    return vm.gridData;
                              }
                        }
                  });
                  modalInstance.result.then(function (selectedItem) {
                        vm.selected = selectedItem;
                  }, function () {

                  });
            };
            vm.previewAttachmentFile = function (fileName, commentKey,docKey) {
                  vm.previewModal(fileName);
                  return;
            }
      }

      function previewAttachmentController(GENERAL_CONFIG, $state, $rootScope, $uibModalInstance, Dashboard901Factory, FileAttachementFactory, SERVER_CONFIG, AlertService, rowData, gridData, colData, MessageFactory, workspaceFactory) {
            var vm = this;
            vm.previewURL = gridData.previewURL;
            vm.cancel = function () {
                  vm.entities = [];
                  $uibModalInstance.dismiss('cancel');
                  // $rootScope.$broadcast('gtw.dashboard901.assignModalClose');
            };

            vm.parent_key = gridData.parent_key;
            vm.file_name = gridData.file_name;
            console.log("Preview_________", vm.parent_key, vm.file_name);
            vm.previewURL = GENERAL_CONFIG.base_url + "/previewCommentAttachement?commentKey=" + vm.parent_key + "&fileName=" + vm.file_name;

            //GENERAL_CONFIG.base_url+"/previewCommentAttachement?commentKey="+$scope.comment.comment_key+"&fileName="+fileName;
      }

      return controllers;

});

