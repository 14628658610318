/**
 * Created by 212544474 on 2/17/2016.
 */

define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.tblMaintenanceService', [])

					.factory(
							"TblMaintenanceFactory",
							['$q', '$http', '$log','$rootScope','AlertService','GlobalService','$injector','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService,$injector, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
										var tblMaintenanceFactory = {};

								          var service_table = $injector.get('JsonObjectFactory');
								          var URLS_DELETE = {
								                  // FETCH: GENERAL_CONFIG.base_url + '/getProjectTag', // we can use this when we have more URL'S
								                   SAVE: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wymcxb"
								               }
								           //yfilxy
										tblMaintenanceFactory.saveTableMaintanance = function(
												_callName, _data) {

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											// var rowData = _data.rowData;
											var jsonObj = [];
											var jsonSettings = [];
											jsonObj = _data.rowData;
											jsonSettings = _data.rowData;
											var objId = _data.rowData.OBJECT_ID;
											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var params = _.merge({},
													GlobalService.globalParams,
													filterParams);

											params = _
													.extend(
															{
																jsonSettings : JSON
																		.stringify(jsonSettings)
															}, params);
											params = _.extend({
												jsonObj : JSON
														.stringify(jsonObj)
											}, params);

											var url = GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=wymcxb";

											service_table
													.saveJSON(url, params)
													.then(
															function(data) {
																if (data.callSuccess === "1") {
																	AlertService
																			.add(
																					"success",
																					"Data saved Successfully.",
																					4000);
																	var args = {
																		OBJECT_ID : objId,
																		gridFilter : {
																			gridFilter : objId
																		}
																	};
																	$rootScope
																			.$emit(
																					'gridUpdate',
																					args);// $rootScope.$emit('gridUpdate',
																	// args);
																} else {
																	// rowData.ILLEGAL_PAYMENTS
																	// = null;
																	// rowData.SANCTIONED_COUNTRY
																	// = null;
																	AlertService
																			.add(
																					"error",
																					data.errorMessage,
																					4000);
																	$rootScope
																			.$broadcast(
																					'gridRefresh',
																					true);

																}
															});
										}
								          tblMaintenanceFactory.saveTableMaintanancemodal = function(
													_callName, _data) {

								        	  var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
								                var jsonObj = JSON.stringify(_data);
								                var jsonSettings =  JSON.stringify(_callName);

								                var params = filterParams;
								                params = _.merge({},  GlobalService.globalParams , params);
								                params =  _.extend({jsonObj:jsonObj}, params);
								                params =  _.extend({jsonSettings:jsonSettings}, params);
								                //params =  _.extend({jsonSettings:jsonSettings}, _data);
								                params.process_name =  "INSERT_TBL_MAINTENANCE";
								                console.log(params);
								                var promise = $http({
								                    method: "post",
								                    url: URLS_DELETE.SAVE,
								                    data: params
								                }).then(function (response) {
								                    var status = response.status;
								                    return response.data;
								                });

								                return promise;

											}

										tblMaintenanceFactory.de_record = function(_data,_schdMs){

								                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
								                var jsonObj = JSON.stringify(_schdMs);
								                var jsonSettings =  JSON.stringify(_data);

								                var params = filterParams;
								                params = _.merge({},  GlobalService.globalParams , params);
								                params =  _.extend({jsonObj:jsonObj}, params);
								                params =  _.extend({jsonSettings:jsonSettings}, params);
								                //params =  _.extend({jsonSettings:jsonSettings}, _data);
								                params.process_name =  "DELETE_TBL_MAINTENANCE";
								                console.log(params);
								                var promise = $http({
								                    method: "post",
								                    url: URLS_DELETE.SAVE,
								                    data: params
								                }).then(function (response) {
								                    var status = response.status;
								                    return response.data;
								                });

								                return promise;

								            
								            }


										
										return tblMaintenanceFactory;

									} ])

			return services;

		});
