define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartVII',[])
        .factory("K3PartVIIAttachmentFactory",['$http', '$window','$filter','GENERAL_CONFIG','AlertService','PartnerAllocServicesNewFactory',K3PartVIIAttachmentFactory])
        .factory("K3PartVIIFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','K3PartVIIAttachmentFactory','workspaceFactory', '$filter',K3PartVIIFactory])

    function K3PartVIIAttachmentFactory($http,$window,$filter,GENERAL_CONFIG,AlertService,PartnerAllocServicesNewFactory){

        var fileUploadConfig={
            attachmentTO_VII : {
                FILE_KEY: '',
                FILE_NAME: '',
                FILE_PATH: '',
                FILE_TYPE: '',
                FILE_SIZE: '',
                CONTENT_TYPE: '',
                ATTACHMENT_KEY: '',
                isUpdateClicked:false,
                comment_attachment_change_success: false,
                saveClicked: false,logoFilesMarkedForDeletion: [],
                datasource_connection_msg: '',datasource_connection_status: '',flow: {},
                attachment_file: '',
                attachmented_files:[],
                stored_files:[],
                partNo: 'VII'
            },failCount : 0
            ,getClientLogoFileName : function (vm) {

                if (angular.isUndefined(vm.attachmentTO_VII.FILE_NAME) ||
                    (vm.attachmentTO_VII.FILE_NAME == null) ||
                    (vm.attachmentTO_VII.FILE_NAME == '')
                ) {
                    return '';
                }
                return vm.attachmentTO_VII.FILE_NAME.substr(vm.attachmentTO_VII.FILE_NAME.lastIndexOf('/') + 1);

            },removeAttachment : function (vm,fileName,index) {
                console.log('removeAttachment p7');
                console.log('vm.pVIIAttachmentList ---- ' , vm.pVIIAttachmentList);

                let file_key = $filter("filter")(vm.pVIIAttachmentList,{file_name:fileName.split(".")[0], file_type:fileName.split(".")[1]})[0].file_key;
                console.log('file_key = ' + file_key);

                var objContext={
                    destDir:"temp",
                    flowFilename: fileName,
                    fileKey:file_key
                };
                let promise = $http({method: "post", url: vm.remove_url, data: objContext
                }).then(function (response) {

                    if(response.data.p_retval == "1" && response.data.p_error_out == null) {

                        vm.attachmentTO_VII.attachment_file = '';
                        vm.attachmentTO_VII.comment_attachment_change_success = true;
                        vm.attachmentTO_VII.attachment_file = '';
                        try{
                            vm.attachmentTO_VII.flow.removeFile(_.find(vm.attachmentTO_VII.flow.files,{name:fileName}));
                        }catch(e){}
                        vm.attachmentTO_VII.attachmented_files = _.without(vm.attachmentTO_VII.attachmented_files, fileName);
                        if(vm.attachmentTO_VII.stored_files && vm.attachmentTO_VII.stored_files.length)
                            vm.attachmentTO_VII.stored_files=_.cloneDeep(vm.attachmentTO_VII.attachmented_files);
                    }
                    else if(response.data.p_retval == "0" && response.data.p_error_out != null) {
                        AlertService.add("error", response.data.p_error_out);
                        return;
                    }
                    else if(response.data.callSuccess == "0" && response.data.errorMessage != null) {
                        AlertService.add("error", response.data.errorMessage);
                        return;
                    }
                }, function () {
                        AlertService.add("error", "Problem removing attachment.");
                    }
                );


            },downloadAttachment :function (vm,fileName) {

                console.log('fileName=', fileName);
                console.log('vm.pVIIAttachmentList = ', vm.pVIIAttachmentList);

                let fileObj = $filter("filter")(vm.pVIIAttachmentList,{file_name:fileName.split(".")[0], file_type:fileName.split(".")[1]})[0];
                let fileKey = fileObj.file_key;

                console.log('fileKey = ' + fileKey);
                let downloadUrl = vm.download_url+'?fileName='+fileName+"&fileKey="+fileKey+"&client_key="+sessionStorage.getItem('client_key');
                console.log('downloadUrl = ', downloadUrl);
                //$window.open(downloadUrl,'_blank');

                window.location.href = downloadUrl;


            },fileAdded : function (vm,file, event, flow) {
                var fileExtension = file.getExtension().toLowerCase();
                var allowedFileExtensions = ',jpg,jpeg,png,svg,pdf,xls,xlsx,txt,doc,docx,ico,ppt,pptx,ods,wpd,rtf';
                var isAllowedFileExtension = (allowedFileExtensions.indexOf(',' + fileExtension + ',') >= 0);
                var isFileSizeWithinLimit = (file.size <= vm.SERVER_CONFIG.configSettings.fileUploadMaxSize);
                var isFileAdded = (isAllowedFileExtension && isFileSizeWithinLimit);
                file.error = false;
                file.message = '';
                vm.attachmentTO_VII.isError=false;
                vm.attachmentTO_VII.errorMessage='';
                file.name =  file.name.replace(/[^-a-zA-Z0-9\s.]/gi, '_');
                var num = file.name.match(/\./g) !=null ? file.name.match(/\./g).length: 0; // check the periods in file
                if(num == 1){
                    if (!isFileAdded ) {
                        if (!isAllowedFileExtension) {
                            file.error = true;
                            file.message = file.name + ' is invalid.';
                            flow.cancel();
                            vm.attachmentTO_VII.isError=true;
                            vm.attachmentTO_VII.errorMessage=file.name + ' is invalid.';
                            setTimeout(function(){vm.attachmentTO_VII.isError=false;
                                vm.attachmentTO_VII.errorMessage='';},1000);
                            return false;
                        }
                        if (!isFileSizeWithinLimit) {
                            file.error = true;
                            file.message = file.name + ' exceeds the allowed maximum size of ';
                            // console.log(file.name + ' exceeds the allowed maximum size of ' + file.size);
                            vm.attachmentTO_VII.isError=true;
                            vm.attachmentTO_VII.errorMessage=file.name + ' exceeds the allowed maximum size of ';
                            setTimeout(function(){vm.attachmentTO_VII.isError=false;
                                vm.attachmentTO_VII.errorMessage='';},6000);
                            flow.cancel();
                            return false;
                        }
                    }
                }else if(num ===0){
                    file.error = true;
                    vm.attachmentTO_VII.isError=true;
                    file.message = 'Unsupported File';
                }
                else{
                    file.error = true;
                    vm.attachmentTO_VII.isError=true;
                    file.message = file.name + ' is invalid, has multiple periods.';
                    //flow.cancel();
                }
            },setFiles : function (vm,files, flow) {
                var x = false;
                var xy = [];
                _.each(vm.attachmentTO_VII.attachmented_files,
                    function(item,key){
                        /* var fileName ="";
                        fileName =  (files[0] && files[0].length==0) ? files[0].name.replace(/[^a-zA-Z0-9\\+.]/gi, '_') :
                                        flow.files[0].file.name.replace(/[^a-zA-Z0-9\\+.]/gi, '_');
                        if(_.isEqual(item,fileName)){
                            x = true;
                            xy.push(item);
                        } */

                        if((files && files.length>0 || (flow  && flow.files && flow.files.length>0))){
                            var arr = files && files.length>0 ? files: flow.files;
                            _.each(arr,function(i){
                                var fn = i && i.name !=undefined ? i.name :i.file.name.replace(/[^-a-zA-Z0-9\s.]/gi, '_');
                                if(_.isEqual(item,fn)){
                                    x = true;
                                    xy.push(item);
                                }
                            })
                        }
                    })

                //if(vm.attachmentTO_VII && vm.attachmentTO_VII.attachmented_files.indexOf(flow.files[0].file.name)>-1){
                if(xy && xy.length>0){
                    AlertService.add("warning","Same file name exist, if the file has changes,please delete the exiting document and re-upload or else rename the file",4000);
                }else  if ((files[0] && files[0].error == false && files[0].error != undefined)) {
                    flow.upload();
                }else if((files[0] && files[0].error == true && files[0].message != undefined)) {
                    _.remove(flow.files,{name:files[0].name});
                    vm.attachmentTO_VII.stored_files= _.uniq(vm.attachmentTO_VII.attachmented_files);
                    AlertService.add("warning",files[0].message,4000);
                }
                //     else{
                //        AlertService.add("warning","You have already uploaded the same file name",4000);
                //   }
            },setErrorFile : function (vm,file, message, flow) {
                file.error = true;
                file.message = file.name + ' is invalid';
                AlertService.add("warning","You have already uploaded the same file name",4000);//or exceeds allowed maximum size of ' + FileSizeService.readableFileSize(SERVER_CONFIG.configSettings.fileUploadMaxSize);
                flow.cancel();
            },fileSuccess : function (vm,file, message, flow) {
                var parsedMessage = JSON.parse(message);

                console.log('parsedMessage = ', parsedMessage);

                console.log('pVIIAttachmentList =', vm.pVIIAttachmentList);

                if (parsedMessage.uploadStatus == 'success') {

                    PartnerAllocServicesNewFactory.loadSK3AttachmentDetails(vm).then(function(response){

                        let pVIIAttachmentMetadata = [];
                        let pVIIIFileKeySet = [];
                        let pVIISavedAttachmentList = [];
                        vm.all_1065SK3AttachmentData = response.jsonObject;

                        /*  _.each(_.keys(K3PartVIIAttachmentFactory.fileUploadConfig), function (item) {
                            if (!_.isFunction(K3PartVIIAttachmentFactory.fileUploadConfig[item]))
                                ctrl[item] = _.cloneDeep(K3PartVIIAttachmentFactory.fileUploadConfig[item]);
                            else ctrl[item] = K3PartVIIAttachmentFactory.fileUploadConfig[item];
                        });*/

                        pVIIAttachmentMetadata = $filter("filter")(vm.all_1065SK3AttachmentData, {PART_NO: 'VII', PART_ORDER: 7});
                        console.log(' pVIIAttachmentMetadata = ', pVIIAttachmentMetadata);

                        for(let x = 0; x < pVIIAttachmentMetadata.length; x++) {
                            pVIIIFileKeySet.push(pVIIAttachmentMetadata[x].FILE_KEY);
                        }

                        let fileKeySetString = pVIIIFileKeySet.join(",");
                        let  attachment_params = {fileKeySet : fileKeySetString };

                        $http({method: "post", url: vm.load_attachments_url, data: attachment_params}).then(function (response) {
                            console.log(response);
                            vm.attachmentTO_VII.attachmented_files = [];
                            vm.attachmentTO_VII.stored_files = [];
                            //vm.attachmentTO_VII.attachmented_files = vm.pVIIAttachmentList;
                            pVIISavedAttachmentList = response.data.p_cursor;
                            vm.pVIIAttachmentList = pVIISavedAttachmentList;

                            if(pVIISavedAttachmentList) {
                                for (let i = 0; i < pVIISavedAttachmentList.length; i++) {
                                    console.log('pVIISavedAttachmentList[',i,'] = ' , pVIISavedAttachmentList[i]);
                                    let attachment = pVIISavedAttachmentList[i];

                                    attachment.fS = vm.formatBytes(attachment.file_size); // gets the files size with conversion val
                                    vm.attachmentTO_VII.attachmented_files.push(attachment.file_name+"."+attachment.file_type);
                                }
                                vm.attachmentTO_VII.attachmented_files = _.uniq(vm.attachmentTO_VII.attachmented_files);
                                vm.attachmentTO_VII.stored_files = _.cloneDeep(vm.attachmentTO_VII.attachmented_files);
                                vm.existingFilesList_pVII = _.cloneDeep(vm.pVIIAttachmentList);
                            }
                            // existing code
                            if(vm.pVIIAttachmentList){
                                vm.pVIIAttachmentList.forEach(el => {
                                    let index = _.findIndex(vm.attachmentTO_VII.attachmented_files, e => e === el.file_name);
                                    if (index >= 0) {
                                        vm.pVIIAttachmentList.splice(index, 1);
                                    }
                                });
                            }

                            console.log('vm.pVIIAttachmentList ---- ' , vm.pVIIAttachmentList);

                            /*vm.attachmentTO_VII.logoFilesMarkedForDeletion.push(vm.attachmentTO_VII.attachment_file);
                            var fileName = parsedMessage.flowFilename;
                            var fileParam = {
                                name: fileName
                            };
                            vm.attachmentTO_VII.attachment_file = fileName;
                            if(vm.attachmentTO_VII.stored_files.length==0){
                                vm.attachmentTO_VII.attachmented_files= _.uniq(_.map(flow.files,"name"));
                            }else{
                                vm.attachmentTO_VII.attachmented_files= _.cloneDeep(vm.attachmentTO_VII.stored_files);
                                vm.attachmentTO_VII.attachmented_files = vm.attachmentTO_VII.attachmented_files.
                                concat(_.uniq(_.map(flow.files,"name")));
                                vm.attachmentTO_VII.attachmented_files= _.uniq(vm.attachmentTO_VII.attachmented_files);
                            }
                            vm.attachmentTO_VII.attachment_file_change_success = true;
                            vm.attachmentTO_VII.logoFilesMarkedForDeletion = _.without(vm.attachmentTO_VII.logoFilesMarkedForDeletion, vm.attachmentTO_VII.attachment_file);*/

                        });

                    });

                } else if (parsedMessage.callSuccess == '0') {
                    file.error = true;
                    file.message = parsedMessage.errorMessage;
                    AlertService.add("error",parsedMessage.errorMessage,4000);
                }
            },dragEnter : function (vm) {
                vm.style = {
                    border: '2px solid #37d631'
                };
            },
            dragLeave : function (vm) {
                vm.style = {};
            }
        };
        var K3PartVIIAttachmentFactory={};
        K3PartVIIAttachmentFactory.fileUploadConfig=fileUploadConfig;
        K3PartVIIAttachmentFactory.newAttachmentCopy=function(){
            K3PartVIIAttachmentFactory=angular.copy(fileUploadConfig);
        }
        return K3PartVIIAttachmentFactory;
    }

    function K3PartVIIFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,K3PartVIIAttachmentFactory,workspaceFactory,$filter) {


        K3PartVIIFactory.renderSK3PartVIIDetails = function(ctrl) {
            ctrl.pVIIAttachmentList = [];


            ctrl.userSettings = USER_SETTINGS;
            ctrl.userType = ctrl.userSettings.user.client.user_type_key;

            let pVIIAttachmentMetadata = [];
            let pVIIIFileKeySet = [];
            let pVIISavedAttachmentList = [];
            ctrl.attachmentTO_VII = {
                FILE_KEY: '',
                FILE_NAME: '',
                FILE_PATH: '',
                FILE_TYPE: '',
                FILE_SIZE: '',
                CONTENT_TYPE: '',
                ATTACHMENT_KEY: '',
                comment_attachment_change_callSuccess: false,
                saveClicked: false,
                logoFilesMarkedForDeletion: [],
                datasource_connection_msg: '',
                datasource_connection_status: '',
                flow: {},
                attachment_file: '',
                partNo:'VII',
                files_to_delete:[]
            };

            let OldFiles = [];

            ctrl.part_VII_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'VII', SECTION_NAME:'NA', ROW_TYPE: 'PH', ATTRIB_NAME: '1065_K3_VII_NA_NA'});

            _.each(_.keys(K3PartVIIAttachmentFactory.fileUploadConfig), function (item) {
                if (!_.isFunction(K3PartVIIAttachmentFactory.fileUploadConfig[item]))
                    ctrl[item] = _.cloneDeep(K3PartVIIAttachmentFactory.fileUploadConfig[item]);
                else ctrl[item] = K3PartVIIAttachmentFactory.fileUploadConfig[item];
            });

            pVIIAttachmentMetadata = $filter("filter")(ctrl.all_1065SK3AttachmentData, {PART_NO: 'VII', PART_ORDER: 7});

            console.log(' pVIIAttachmentMetadata = ', pVIIAttachmentMetadata);

            for(let x = 0; x < pVIIAttachmentMetadata.length; x++) {
                pVIIIFileKeySet.push(pVIIAttachmentMetadata[x].FILE_KEY);
            }

            let fileKeySetString = pVIIIFileKeySet.join(",");
            let  attachment_params = {fileKeySet : fileKeySetString };

            $http({method: "post", url: ctrl.load_attachments_url, data: attachment_params}).then(function (response) {
                console.log(response);
                ctrl.attachmentTO_VII.attachmented_files = [];
                ctrl.attachmentTO_VII.stored_files = [];
                ctrl.attachmentTO_VII.attachmented_files = ctrl.pVIIAttachmentList;
                pVIISavedAttachmentList = response.data.p_cursor;
                ctrl.pVIIAttachmentList = pVIISavedAttachmentList;

                if(pVIISavedAttachmentList) {
                    for (let i = 0; i < pVIISavedAttachmentList.length; i++) {
                        console.log('pVIISavedAttachmentList[',i,'] = ' , pVIISavedAttachmentList[i]);
                        let attachment = pVIISavedAttachmentList[i];

                        attachment.fS = ctrl.formatBytes(attachment.file_size); // gets the files size with conversion val
                        ctrl.attachmentTO_VII.attachmented_files.push(attachment.file_name+"."+attachment.file_type);
                    }
                    ctrl.attachmentTO_VII.attachmented_files = _.uniq(ctrl.attachmentTO_VII.attachmented_files);
                    ctrl.attachmentTO_VII.stored_files = _.cloneDeep(ctrl.attachmentTO_VII.attachmented_files);
                    ctrl.existingFilesList_pVII = _.cloneDeep(ctrl.pVIIAttachmentList);
                }
                // existing code
                if(ctrl.pVIIAttachmentList){
                    ctrl.pVIIAttachmentList.forEach(el => {
                        let index = _.findIndex(ctrl.attachmentTO_VII.attachmented_files, e => e === el.file_name);
                        if (index >= 0) {
                            ctrl.pVIIAttachmentList.splice(index, 1);
                        }
                    });
                }

            });
            return ctrl;
        }

        K3PartVIIFactory.removeFile = function(ctrl,file, index) {
            console.log('K3PartVIIFactory.removeFile called');
            ctrl.removeAttachment(ctrl,file,index);
        }

        K3PartVIIFactory.downloadAttachmentFile = function(ctrl,file) {
            console.log('K3PartVIIFactory.downloadAttachmentFile called');
            ctrl.downloadAttachment(ctrl,file);
        }

        return K3PartVIIFactory;

    }

    return services;
});