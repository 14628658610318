/**
 * @author Vijay Ramagiri This is to get and save Investment Validation 
 * 
 * 
 */
define(
		[ 'angular',
		    './InvestmentValidationService',
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.InvestmentValidationCtrl', ['app.InvestmentValidationService'])
					.controller('investValidationCtrl',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService','workspaceFactory',
									'$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','InvestmentValidationFactory','$http', investValidationCtrl ])

			function investValidationCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, workspaceFactory, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, InvestmentValidationFactory,$http) {

				var vm = this;	
				//Show the loading Spinner
				vm.loadingData = true;		
				//Selected Row on the Grid passed to Modal/Popup to display in top table
				vm.selectedGridRow = [];
				//All the Investment related Mapping Details for show on the UI			
				vm.sysAcctInvstMappingDtls = [];
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//Load the ATTRIB_MAST data to get the Pivot Keys
				//vm.attribMastData;
				//Initial Breakout Amount
				vm.totalBreakout = 0;
				//Initial Breakout Amount
				vm.totalTBBSBalanceAmt = 0;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};	
				//Get the Grid filter Scenario
				vm.scenario = filterParams.scenario;  	
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				vm.issueId = 0;
				
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
								vm.cancel();										
								AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
								return;
						}							
					});
				});
				//reset the form
				vm.reset = function(){					
					vm.investForm.selectedSysAcctKey = '';					
				};
				
				//Retrieve the investment types dropdown from FTC CODE MAST
				vm.getInvestmentTypes = function() {		
					var params = {"action_code" : 'ef4ghx', "tax_year": filterParams.tax_year,}; 					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.investmentTypeDropdown = data.jsonObject;
							
							vm.investmentTypeDropdownDisplay =  _.clone(data.jsonObject);
							_.remove(vm.investmentTypeDropdownDisplay, {IS_DISPLAY: 'N'});
							//console.log("vm.investmentTypeDropdown:: ", vm.investmentTypeDropdown);
					});					
				}
				vm.getInvestmentTypes();
				
				//Get the CODE_KEY for the given Investment Type CODE_DISP_DESC 
				function getInvestmentTypeKeys(investType) {
					var selectedInvestTypeKey;
					for(var indx in vm.investmentTypeDropdown){								
						if(vm.investmentTypeDropdown[indx].CODE_DISP_DESC == investType){
							selectedInvestTypeKey = vm.investmentTypeDropdown[indx].CODE_KEY;							
						}
					}
					return selectedInvestTypeKey;
				};				
				
				//Retrieve the Modal/Popup records saved for the given Code Combination Key 
				vm.getInvestmentTagDtls = function(cc_key) {					
					var params = {"action_code" : '7go09e',"cc_key":cc_key ,}; 
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.investmentTagDtls = data.jsonObject;
							//console.log("vm.investmentTagDtls:: ", vm.investmentTagDtls);
					});					
				}
					
				//Calculate the Total Breakout Amount
				vm.getTotalBreakout = function(data) {				    
				    if(vm.sysAcctInvstMappingDtls!=undefined || vm.sysAcctInvstMappingDtls!=null){
				    	vm.totalBreakout = 0;
				    	vm.totalInvAmount = 0;
				    	for(var i = 0; i < vm.sysAcctInvstMappingDtls.length; i++){	
				    		
				    	 if (data != undefined && data.ACCT == vm.sysAcctInvstMappingDtls[i].ACCT && vm.sysAcctInvstMappingDtls[i].IS_DEFAULT == 'N')
				    		 {
				    		 	vm.totalInvAmount = vm.totalInvAmount +  parseInt(vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT == null ? 0 :vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT);
				    		 }
				    	}
				    	
				    	for(var i = 0; i < vm.sysAcctInvstMappingDtls.length; i++){	
				    		
				    	 if (data != undefined && data.ACCT == vm.sysAcctInvstMappingDtls[i].ACCT && vm.sysAcctInvstMappingDtls[i].IS_DEFAULT == 'Y')
				    		 {
				    		 	vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT = parseInt(vm.sysAcctInvstMappingDtls[i].ORG_INVESTMENT_AMT) - vm.totalInvAmount;
				    		 }
				    	 
				    	 if(vm.sysAcctInvstMappingDtls[i].OPERATION_TYPE!='D'){
				    		 	console.log("Tag key: ", vm.sysAcctInvstMappingDtls[i].TAG_KEY);	
				    		 	console.log("inv amount: ", vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT);	
				    			vm.totalBreakout += parseFloat(vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT!=null?vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT:0);
				    			console.log("totalBreakout: ", vm.totalBreakout);
				    		}
				    	 
				    	}
				    }
				    if(isNaN(vm.totalBreakout)) vm.totalBreakout = 0;
				    return parseInt(vm.totalBreakout);
				}
				
				//Calculate the Total TBBS Balance Amount
				vm.getTotalTBBSBalanceAmt = function() {				    
				    if(vm.sysAcctInvstMappingDtls!=undefined || vm.sysAcctInvstMappingDtls!=null){
				    	vm.totalTBBSBalanceAmt = 0;
				    	for(var i = 0; i < vm.sysAcctInvstMappingDtls.length; i++){				    		
				    		vm.totalTBBSBalanceAmt += parseFloat(vm.sysAcctInvstMappingDtls[i].INV_BALANCE);
				    	}
				    }
				    return vm.totalTBBSBalanceAmt;
				}
				
				//Form a unique set of entries from a given array based on the Property passed.
				function getUniqueValues(array, prop) {
				    var result = new Set();
				    array.forEach(function(item) {
				        if (item.hasOwnProperty(prop)) {
				            result.add(item[prop]);
				        }
				    });
				    return result;
				}
				
				//Check whether the given array has duplicate - array like [1,2,1,3,4,5];
				function hasDuplicates(a) {
					return _.uniq(a).length !== a.length; 
				}
				
				//When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
				if (gridData != null) {					
					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;					
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);			
					vm.issueId = tempGridData.rowData.ISSUE_KEY;
					
					if (vm.gridDataMultipleSelect != undefined) {						
						//console.log("vm.gridDataMultipleSelect:: ", vm.gridDataMultipleSelect.rowData);	
						vm.selectedGridRow.push( _.clone(vm.gridDataMultipleSelect.rowData));	
						vm.getInvestmentTagDtls(vm.gridDataMultipleSelect.rowData.COMBINATION_KEY);	
					}
				}
				
				//Load the System Account Details
				$scope.$watch(function() { return vm.investmentTagDtls },   function() {					
					if(vm.investmentTagDtls!=undefined){
						vm.sysAcctInvstMappingDtls = [];						
						for ( var key in vm.investmentTagDtls) {	
							var newObj = angular.copy(vm.gridDataMultipleSelect.rowData);
							newObj["ACCT"] = vm.investmentTagDtls[key].ACCT;
							newObj["ACCT_DESC"] = vm.investmentTagDtls[key].ACCT_DESC;
							newObj["ACCT_KEY"] = vm.investmentTagDtls[key].ACCT_KEY;
							newObj["COMBINATION_KEY"] = vm.investmentTagDtls[key].DEF_COMBINATION_KEY;
							newObj["INVESTMENT_TYPE"] = (vm.investmentTagDtls[key].INV_TYPE_DESC == undefined ? '' : vm.investmentTagDtls[key].INV_TYPE_DESC );	
							newObj["INVESTMENT_AMT"] = ((vm.investmentTagDtls[key].INV_TYPE_DESC == undefined &&  vm.investmentTagDtls[key].INV_AMT == undefined) ? vm.investmentTagDtls[key].INV_BALANCE : vm.investmentTagDtls[key].INV_AMT);	
							newObj["INV_BALANCE"] = vm.investmentTagDtls[key].INV_BALANCE;		
							//newObj["INV_DTLS_KEY"] = (vm.investmentTagDtls[key].INV_DTLS_KEY == undefined || vm.investmentTagDtls[key].INV_DTLS_KEY == null)?null:vm.investmentTagDtls[key].INV_DTLS_KEY ;	
							newObj["INV_TYPE_KEY"] = ''+vm.investmentTagDtls[key].INV_TYPE;	
							newObj["ORG_INVESTMENT_AMT"] = (vm.investmentTagDtls[key].INV_TYPE_DESC == undefined ? vm.investmentTagDtls[key].INV_BALANCE : vm.investmentTagDtls[key].INV_AMT);								
							newObj["TAG_KEY"] = (vm.investmentTagDtls[key].TAG_KEY == undefined || vm.investmentTagDtls[key].TAG_KEY == null)?null:vm.investmentTagDtls[key].TAG_KEY ;
							newObj["IS_DEFAULT"] = vm.investmentTagDtls[key].IS_DEFAULT;
							if(vm.investmentTagDtls[key].TAG_KEY == undefined || vm.investmentTagDtls[key].TAG_KEY == null){
									//&& (vm.investmentTagDtls[key].INV_AMT==undefined || vm.investmentTagDtls[key].INV_AMT == null)){
								newObj["OPERATION_TYPE"] = 'I';							
							}else{
								newObj["OPERATION_TYPE"] = 'U';	
							}			
							newObj["IS_ENABLE"] = 'N';
							newObj["ALT_GIE_KEY"] = vm.investmentTagDtls[key].ALT_GIE_KEY;
							vm.totalBreakout += parseFloat(vm.investmentTagDtls[key].INV_AMT);
							//vm.totalTBBSBalanceAmt += parseFloat(vm.investmentTagDtls[key].INV_BALANCE);	
							vm.sysAcctInvstMappingDtls.push(newObj);
						}	
						//Sum up the Inv. Balance Amounts to get a Total TBBS Balance Amt
						var uniqBalanceAmt = Array.from(getUniqueValues(vm.sysAcctInvstMappingDtls, 'INV_BALANCE').values());
						for(var i in uniqBalanceAmt){
							vm.totalTBBSBalanceAmt += parseFloat(uniqBalanceAmt[i]);
						}
					}						
					if(isNaN(vm.totalBreakout)) vm.totalBreakout = 0;
					vm.loadingData = false;
					//console.log("vm.sysAcctInvstMappingDtls  :: ", vm.sysAcctInvstMappingDtls);
				});
				
				//Delete the selected row 
				vm.deleteRow = function(selectedRowData){					
					var index = vm.sysAcctInvstMappingDtls.indexOf(selectedRowData);
					if(vm.sysAcctInvstMappingDtls[index].OPERATION_TYPE != undefined && vm.sysAcctInvstMappingDtls[index].OPERATION_TYPE != null
							&& vm.sysAcctInvstMappingDtls[index].OPERATION_TYPE != 'I'){
						vm.sysAcctInvstMappingDtls[index].OPERATION_TYPE = 'D';						
					}else{
						vm.sysAcctInvstMappingDtls.splice(index, 1);						
					}					
					for(var i = 0; i < vm.sysAcctInvstMappingDtls.length; i++){	
			    	
			    	 if (selectedRowData.ACCT == vm.sysAcctInvstMappingDtls[i].ACCT && vm.sysAcctInvstMappingDtls[i].IS_DEFAULT == 'Y')
			    		 {
			    		 	vm.sysAcctInvstMappingDtls[i].ORG_INVESTMENT_AMT = vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT;
			    		 }
			    	}
					
					//Refresh the TotalBreakout Amount
					vm.getTotalBreakout();	
					
										
					var countAcct = _.filter(vm.sysAcctInvstMappingDtls, function(eachRow) { if (eachRow.OPERATION_TYPE != 'D') return eachRow }).length;
					if(countAcct == 0){
						var tempObj = _.clone(selectedRowData);	
						tempObj["OPERATION_TYPE"] = 'I';
						tempObj["INVESTMENT_TYPE"] = null;
						tempObj["INV_TYPE_KEY"] = null;
						tempObj["INVESTMENT_AMT"] = null;
						tempObj["TAG_KEY"] = null;						
						vm.sysAcctInvstMappingDtls.splice(index+1, 0, tempObj);							
					}
					
				};
				
				//Adding a new row as a copy of Selected Row
				vm.addRow = function(selectedRowData){
					
					var index = vm.sysAcctInvstMappingDtls.indexOf(selectedRowData);
					
					var tempObj = _.clone(selectedRowData);	
					tempObj["OPERATION_TYPE"] = 'I';
					tempObj["INVESTMENT_TYPE"] = undefined;
					tempObj["INV_TYPE_KEY"] = null;
					tempObj["INVESTMENT_AMT"] = null;
					tempObj["TAG_KEY"] = null;
					tempObj["IS_DEFAULT"] = 'N';
					tempObj["IS_ENABLE"] = 'Y';
						//vm.sysAcctInvstMappingDtls.splice(1, 0, tempObj);
					vm.sysAcctInvstMappingDtls.splice(index+1, 0, tempObj);					
										
					/*for(var i = 0; i < vm.sysAcctInvstMappingDtls.length; i++){	
				    	
				    	 if (selectedRowData.ACCT == vm.sysAcctInvstMappingDtls[i].ACCT && vm.sysAcctInvstMappingDtls[i].IS_DEFAULT == 'Y')
				    		 {
				    		 	vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT = parseInt(vm.sysAcctInvstMappingDtls[i].INVESTMENT_AMT)
				    		 													+ parseInt(selectedRowData.INVESTMENT_AMT == null ? 0 :selectedRowData.INVESTMENT_AMT);
				    		 }
					}	 */
					//Refresh the TotalBreakout Amount
					vm.getTotalBreakout();					
				};
				
				//Save the Investment Amt
				vm.save = function(){	
					console.log("received the savedTagAccts: ", vm.sysAcctInvstMappingDtls); 					
					vm.isSaveClicked = true;
					var dataToSave = [];
					
					for( var row in vm.sysAcctInvstMappingDtls){	
						if((vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY == undefined  || vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY == null  || vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY == ''
							|| vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT == undefined  || vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT == null 
							/* || vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT == '' || Math.round(vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT) == 0 */
						)
						&& vm.sysAcctInvstMappingDtls[row].IS_DEFAULT == 'N'){
							AlertService.add("error", "Please select/enter all the required fields.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}
					
					//var countAcct =  _.countBy(vm.sysAcctInvstMappingDtls, (eachRow) => eachRow.ACCT);
					/*var countAcct =  _.countBy(vm.sysAcctInvstMappingDtls, (eachRow) => { if(eachRow.OPERATION_TYPE != 'D') return eachRow.ACCT } );
					for(var currAcct in countAcct){	
						if(countAcct[currAcct] >= vm.investmentTypeDropdown.length + 1){
							AlertService.add("error", "Cannot have more than "+vm.investmentTypeDropdown.length+" different Investment Types for a given System Account - "+currAcct,4000);
							vm.isSaveClicked = false;
							return;
						}
					}*/
					
					//Get A Unique System Accounts
					var uniqAcctKeys = Array.from(getUniqueValues(vm.sysAcctInvstMappingDtls, 'ACCT').values());
					for(var i in uniqAcctKeys){	
						var allInvAmts = [];
						 for( var row in vm.sysAcctInvstMappingDtls){
							 if(vm.sysAcctInvstMappingDtls[row].ACCT == uniqAcctKeys[i] && vm.sysAcctInvstMappingDtls[row].OPERATION_TYPE != 'D'){
								 allInvAmts.push(vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY);
							 }
						 }
						 if(hasDuplicates(allInvAmts)){
							 AlertService.add("error", "Please select different Investment Types for System Account - "+uniqAcctKeys[i],4000);
							 vm.isSaveClicked = false;
							 return;
						 }
					}					
										
					for( var row in vm.sysAcctInvstMappingDtls){		
						console.log(vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY + "  :  "+vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT);
						
						/*if(vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY == undefined  || vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY == null  || vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY == ''
							|| vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT == undefined  || vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT == null  || vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT == ''){
							AlertService.add("error", "Please select/enter all the required fields.",4000);
							vm.isSaveClicked = false;
							return;
						}*/
						if(		vm.sysAcctInvstMappingDtls[row].IS_DEFAULT == 'N' 
							|| (vm.sysAcctInvstMappingDtls[row].IS_DEFAULT == 'Y' && vm.sysAcctInvstMappingDtls[row].INVESTMENT_TYPE == ''))
						{
							var returnObj = {};			
							returnObj["ACCT_KEY"] = vm.sysAcctInvstMappingDtls[row].ACCT_KEY;	
							returnObj["COMBINATION_KEY"] = vm.sysAcctInvstMappingDtls[row].COMBINATION_KEY;					
							/* returnObj["INVESTMENT_TYPE"] = vm.sysAcctInvstMappingDtls[row].INVESTMENT_TYPE;		
							   returnObj["INV_TYPE_KEY"] = vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY;	*/
							//returnObj["INV_DTLS_KEY"] = vm.sysAcctInvstMappingDtls[row].INV_DTLS_KEY;						
							returnObj["INVESTMENT_TYPE"] = (vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY == undefined ? null : vm.sysAcctInvstMappingDtls[row].INV_TYPE_KEY);	//Here INV_TYPE_KEY is sent for the INVESTMENT_TYPE
							returnObj["AMOUNT"] = parseInt(vm.sysAcctInvstMappingDtls[row].INVESTMENT_AMT);	
							returnObj["TAG_KEY"] = (vm.sysAcctInvstMappingDtls[row].TAG_KEY == undefined || vm.sysAcctInvstMappingDtls[row].TAG_KEY == null) ? null: vm.sysAcctInvstMappingDtls[row].TAG_KEY;
							returnObj["OPERATION_TYPE"] = vm.sysAcctInvstMappingDtls[row].OPERATION_TYPE;	
							returnObj["ALT_GIE_KEY"] = vm.sysAcctInvstMappingDtls[row].ALT_GIE_KEY;	
							
							console.log("returnObj::FNL:: ",returnObj);		
							dataToSave.push(returnObj);
						}
							
									
								
					}					
					console.log("dataToSave-----" + dataToSave);
																
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['object_id'] = Array.from(getUniqueValues(dataToSave, 'COMBINATION_KEY').values()).join();
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					var message = "Investment Amount(s) have been saved/updated";			
					//send to save the Data					
					sendDetails(returnClobSettingsObj, dataToSave, message);
								
				}
				
				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
					InvestmentValidationFactory.saveData(returnClobSettingsObj,defaultsChangeDetails,vm.issueId).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id: returnClobSettingsObj.object_id,
	                                gridFilter: {
	                                	object_id: returnClobSettingsObj.object_id
	                                }
	                            };
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}			
				
			}			
			return controllers;

		});
	