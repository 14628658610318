define([
    'angular',
    './dstStatementsFiledStatusController',
    './dstStatementsFiledStatusServices',
], function () {
    'use strict';
    return angular.module('app.dstStatementsFiledStatus', ['app.DstStatementsFiledStatusController', 'app.DstStatementsFiledStatusServices'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('dst-statements-filed-status', {
                url: '/dst-statements-filed-status',
                "templateUrl": "app/components/DST/statements-filed-status/dst-statements-filed-status.html",
                "controller": "DstStatementsFiledStatusCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);
}
);
