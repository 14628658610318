define([
    'angular'

], function () {
    'use strict';
    /* NOTE:  changed on 3/21/2019
	  Below two controllers functionalities are same, if we are using same modal in both places grid & workflow, we need to define the controllers like below
		F8858scheduleMCtrl		: this controller is used on workflow Step
		F8858scheduleMCtrlModal	: this controller is used on Grid Modal
	*/
	var controllers = angular.module('app.scheduleF8858-MCtrl', [])
	.controller('F8858scheduleMCtrl', [ 'GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope', '$scope', 
		'scheduleMFormServiceFactory', 'GENERAL_CONFIG', F8858scheduleMCtrl
	])
	
	.controller('F8858scheduleMCtrlModal', [ 'GlobalService','workspaceFactory','menuDataService','AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope', '$scope',
		'scheduleMFormServiceFactory', 'GENERAL_CONFIG', '$uibModalInstance', '$uibModal', 'rowData', 'colData', 'gridData', F8858scheduleMCtrlModal
	])
	
	function F8858scheduleMCtrl( GlobalService, AlertService, JsonObjectFactory, ModalFactory, $parse, $rootScope, $scope, scheduleMFormServiceFactory, GENERAL_CONFIG) {
		F8858scheduleMCtrlModal(GlobalService, AlertService, JsonObjectFactory, ModalFactory, $parse, $rootScope, $scope, scheduleMFormServiceFactory, GENERAL_CONFIG, null, null, null, null, null, this);
	}

  function F8858scheduleMCtrlModal(GlobalService,workspaceFactory,menuDataService,AlertService, JsonObjectFactory, ModalFactory, $parse, $rootScope, $scope, scheduleMFormServiceFactory, GENERAL_CONFIG, $uibModalInstance, $uibModal,
			rowData, colData, gridData, thisObj) {
	  			var vm = this || thisObj;
	  			vm.rowData = (rowData == null) ? $scope.$parent.rowData : rowData; 
            	vm.modalTitle = "8858 Schedule M: Transactions Between Foreign Disregarded Entity of a Foreign Tax Owner and the Filer or Other Related Entities";
            	$scope.irsF8858ScheduleMData = [];
            	$scope.irsF8858ScheduleMOriginalData = [];
            	vm.adjAmts = [];
            	$scope.crudLoading = false;
            	
            	if (angular.isObject($uibModalInstance)) {
        			vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;
        			vm.cancel = function () {
        				/*var args = {
        					combination_keys: vm.rowData.COMBINATION_KEY,
        					gridFilter: {
        						COMBINATION_KEY: vm.rowData.COMBINATION_KEY,object_id:vm.rowData.object_id
        					}
        				};
        				$rootScope.$emit('gridUpdate', args);*/
        				$uibModalInstance.close();
        				vm.modal_name = null;
        			};
        		} else {
        			vm.modal_name = null;
        		}
            	
            	
            	
     
//RESET Button Functionalities/////////////////////////////////////////////////////////////////////////////////////////////////////////		        
		        vm.reset = function() {
		        	vm.crudLoading = false;
        			angular.copy($scope.irsF8858ScheduleMOriginalData, $scope.irsF8858ScheduleMData);
		        }
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		        
//LOAD Details Functionalities/////////////////////////////////////////////////////////////////////////////////////////////////////////
		        function loadIRSF8858ScheduleMData()
		        {
		        	$scope.irsF8858ScheduleMData = [];
		        	var params = { 
		        			"action_code": 'atavd1', 
		        			"irs_form_no" : 'F8858',
		        			"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
		        			"combination_key":vm.rowData.COMBINATION_KEY// 672168
		        };
		        	vm.dataLoading = true;
		        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
		        		if (data.callSuccess === "1") {
		        			vm.dataLoading = false;
		        			//console.log("data.jsonObject---------------------", data.jsonObject);
		        			var irsF8858ScheduleMDataTemp = data.jsonObject;
		        		//	irsF8858ScheduleMDataTemp = _.sortBy(irsF8858ScheduleMDataTemp, 'line_no');
		        			console.log(irsF8858ScheduleMDataTemp , 'first');
		        			var i = 1;
		        			
		        			var previous_line_no = irsF8858ScheduleMDataTemp[i].line_no;
		        			while( i < irsF8858ScheduleMDataTemp.length )
		        			{
		        				var tempObj = {};
		        				var j = i;
		        				//console.log("irsF8858ScheduleMDataTemp[j]----------------", irsF8858ScheduleMDataTemp[j]);
		        				while(j <irsF8858ScheduleMDataTemp.length && previous_line_no == irsF8858ScheduleMDataTemp[j].line_no)
		        				{
		        					if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "LINE_NO")
		        					{
		        						if(irsF8858ScheduleMDataTemp[j].LINE_TYPE ==  'T1'|| irsF8858ScheduleMDataTemp[j].LINE_TYPE == 'T2'){
		        							tempObj.backgroudColor = {
		        								"background": "#D6D6D6",
		        								"font-weight": "bold"
		        							 }
		        					     }
		        						else
		        						{
		        							tempObj.backgroudColor = {}
		        					     }
		        					
		        						tempObj.line_no = irsF8858ScheduleMDataTemp[j].line_no;
		        						if(irsF8858ScheduleMDataTemp[j].LINE_TYPE ==  'T1'|| irsF8858ScheduleMDataTemp[j].LINE_TYPE == 'T2' ){
										    tempObj.line_type = 'total';
										}
										else {
											tempObj.line_type = irsF8858ScheduleMDataTemp[j].LINE_TYPE;
										}
										
										if(irsF8858ScheduleMDataTemp[j].LINE_TYPE ==  'T1'){
										    tempObj.total_line_type = 'T1';
										}
										else if(irsF8858ScheduleMDataTemp[j].LINE_TYPE ==  'T2'){
											tempObj.total_line_type = 'T2';
										}
										
										tempObj.line_multiplier = irsF8858ScheduleMDataTemp[j].MULTIPLIER;
										
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) < 10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							tempObj.line_multiplier = -1;
		        						else
		        							tempObj.line_multiplier = 1;*/
		        						
		        					
		        						tempObj.saveParams = {
			        							
		        								"tax_year":irsF8858ScheduleMDataTemp[j].object_id.split("~")[0],
		        								"trans_type_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[1],
		        								"form_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[6]
			        						}
		        					}
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "TRANSACTION_OF_FOREIGN_DISRGARDED_ENTITY"){
		        						tempObj.line_Description = irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[3];
		        					
	        						}
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "US_PERSON_FILING_THIS_RETURN_SYSTEM")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
		        					    value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;		 
		        						tempObj.col_b1 = {
		        											 "VALUE": value,
		        								             "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
		        								             "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
		        								             "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
		        								             "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
		        								             "is_Update":'N'
		        								            }
		        					}
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "US_PERSON_FILING_THIS_RETURN_OVERRIDE")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
		        					    value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;		 
		        						tempObj.col_b = {
		        											 "VALUE": value,
		        								             "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
		        								             "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
		        								             "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
		        								             "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
		        								             "is_Update":'N'
		        								            }
		        					}
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "DOMESTIC_CORPORATION_PARTNERSHIP_CONTROLLED_BY_FILER_SYSTEM")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
		        					  value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;		 
		        						tempObj.col_c1 = {
   											 "VALUE": value,
   								             "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
   								             "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
   								             "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
   								             "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
								             "is_Update":'N'
   								            }
		        					}
		        					
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "DOMESTIC_CORPORATION_PARTNERSHIP_CONTROLLED_BY_FILER_OVERRIDE")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
									value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						tempObj.col_c = {
   											 "VALUE": value,
   								             "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
   								             "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
   								             "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
   								             "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
								             "is_Update":'N'
   								            }
		        					}
		        					
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "FOREIGN_CORPORATION_PARTNERSHIP_CONTROLLED_BY_FILER_SYSTEM")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
		        						value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;	 
		        						tempObj.col_d1 = {
   											  "VALUE": value,
   								              "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
   								              "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
   								              "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
   								              "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
   								              "is_Update":'N'
   								            }
		        					}
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "FOREIGN_CORPORATION_PARTNERSHIP_CONTROLLED_BY_FILER_OVERRIDE")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
		        						value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;	 
		        						tempObj.col_d = {
   											  "VALUE": value,
   								              "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
   								              "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
   								              "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
   								              "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
   								              "is_Update":'N'
   								            }
		        					}
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "TEN_PERCENT_OR_MORE_US_SHAREHOLDER_ANY_CORPORATION_CONTROLLING_TAX_OWNER")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
		        							 value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						tempObj.col_e = {
   											 "VALUE": value,
   								             "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
   								             "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
   								             "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
   								             "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
								             "is_Update":'N'
   								            }
		        					}
		        					else if(irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0] == "TEN_PERCENT_OR_MORE_US_SHAREHOLDER_OR_OTHER_OWNER_OF_ENTITY_CONTROLLING_TAX_OWNER")
		        					{
		        						var value= null;
		        						/*if(parseInt(irsF8858ScheduleMDataTemp[j].line_no) <10 || parseInt(irsF8858ScheduleMDataTemp[j].line_no) == 18)
		        							value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (-1*parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						 else
		        							 value =angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? parseInt(irsF8858ScheduleMDataTemp[j].VALUE) : null; */
		        							 value = angular.isDefined(irsF8858ScheduleMDataTemp[j].VALUE) ? (irsF8858ScheduleMDataTemp[j].MULTIPLIER * parseInt(irsF8858ScheduleMDataTemp[j].VALUE)) : null;
		        						tempObj.col_f = {
   											 "VALUE": value,
   								             "line_attrib_key":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[1],
   								             "attrib_name":irsF8858ScheduleMDataTemp[j].attrib_value.split("~")[0],
   								             "trans_Details_key" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[3],
   								             "occurrence" : irsF8858ScheduleMDataTemp[j].object_id.split("~")[4],
								             "is_Update":'N'
   								            }
		        					}
		        				 previous_line_no = irsF8858ScheduleMDataTemp[j].line_no;
		        					j++;
		        				 }
		        				if(j < irsF8858ScheduleMDataTemp.length)
		        					previous_line_no = irsF8858ScheduleMDataTemp[j].line_no;
		        				var tempIndex = $scope.irsF8858ScheduleMData.length;
		        				$scope.irsF8858ScheduleMData[tempIndex] = tempObj;
		        				console.log(tempObj, 'tempObj')
	                			
	                			i = j;
		        			}
		        			for(var i=0;i<$scope.irsF8858ScheduleMData.length; i++) {
		        				$scope.irsF8858ScheduleMData[i].line_no = Number($scope.irsF8858ScheduleMData[i].line_no);
		        			}
		        			$scope.irsF8858ScheduleMData = _.sortBy($scope.irsF8858ScheduleMData, 'line_no')
		        			console.log("$scope.irsF8858ScheduleMData--------------", $scope.irsF8858ScheduleMData);
		        			getSchdMAdjAmt();
		        			
		        		}
		        		else
		        		{
		        			if (data.errorMessage === "no access") {
								AlertService.add("","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
		        		}
		        		console.log($scope.irsF8858ScheduleMData, 'last')
		        		
		        	});
		        }
		        loadIRSF8858ScheduleMData();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////		        

//GET ADJ_AMT Functionalities/////////////////////////////////////////////////////////////////////////////////////////////////////////
		        function getSchdMAdjAmt()
                {
	            	vm.adjAmts = [];
                	var params = {
                			"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                			"combination_key":vm.rowData.COMBINATION_KEY,
                			"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                			"jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                			}
                	vm.dataLoading = true;
                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=ph95ti",params).then(function(data) {
                		if (data.callSuccess === "1") {
                			vm.dataLoading = false;
                			var resposneTempData = data.jsonObject;
                			$scope.resposneTempData = data.jsonObject;
                			for(var i = 0; i < resposneTempData.length; i++ )
                			{
                				var index = resposneTempData[i].LINE_NO;
                				vm.adjAmts[index] = {
                						"LINE_NO":resposneTempData[i].LINE_NO,
                						"C_AMT" : resposneTempData[i].C_AMT,
                						"D_AMT" : resposneTempData[i].D_AMT,
                						"B_AMT" : resposneTempData[i].B_AMT
                				}
                			}
                			
                			//console.log("vm.adjAmts--------------", vm.adjAmts);
                			//setting adj_amt to schedule M array
                			for(var i = 0; i<$scope.irsF8858ScheduleMData.length; i++)
                			{
                				//console.log();
                				//if($scope.irsF8858ScheduleMData[i].line_no != 20  )
                				if($scope.irsF8858ScheduleMData[i].line_type != 'filer')
                				{
                					if(angular.isDefined($scope.irsF8858ScheduleMData[i]) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_b) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_b.VALUE)) 
                					{
										var start_2= _.find($scope.irsF8858ScheduleMData, {line_type:'start2'});
		                				var start_2_line_no = start_2.line_no;
		                				var total_2= _.find($scope.irsF8858ScheduleMData, {total_line_type:'T2'});
		                				var total_2_line_no = total_2.line_no;
		                				
                						var index = parseInt($scope.irsF8858ScheduleMData[i].line_no);
                						if(index < start_2_line_no || index === total_2_line_no)
                						{    
            								$scope.irsF8858ScheduleMData[i].col_b1.VALUE = (vm.adjAmts[index].B_AMT == undefined ? null :vm.adjAmts[index].B_AMT );
                							if($scope.irsF8858ScheduleMData[i].col_b.trans_Details_key == "0" )//&& $scope.irsF8858ScheduleMData[i].col_b.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_b.VALUE == null) && vm.adjAmts[index].B_AMT != 0 */
                								{
                								$scope.irsF8858ScheduleMData[i].col_b.VALUE = (vm.adjAmts[index].B_AMT == undefined ? null :vm.adjAmts[index].B_AMT);
                								}
                							else $scope.irsF8858ScheduleMData[i].col_b.VALUE = $scope.irsF8858ScheduleMData[i].col_b.VALUE;
                						}
                						else
                						{
           								 	$scope.irsF8858ScheduleMData[i].col_b1.VALUE =  (vm.adjAmts[index].B_AMT == undefined ? null :vm.adjAmts[index].B_AMT );	
                							if( $scope.irsF8858ScheduleMData[i].col_b.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_b.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_b.VALUE == null) && vm.adjAmts[index].B_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_b.VALUE =  (vm.adjAmts[index].B_AMT == undefined ? null :vm.adjAmts[index].B_AMT );
                								}
                							else $scope.irsF8858ScheduleMData[i].col_b.VALUE = $scope.irsF8858ScheduleMData[i].col_b.VALUE;
                						}
                					}
                					//console.log("vm.scheduleMDetails[i]-----------------------------", vm.scheduleMDetails[i]);
                					if(angular.isDefined($scope.irsF8858ScheduleMData[i]) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_c) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_c.VALUE) ) 
                					{	
										var start_2= _.find($scope.irsF8858ScheduleMData, {line_type:'start2'});
		                				var start_2_line_no = start_2.line_no;
		                				var total_2= _.find($scope.irsF8858ScheduleMData, {total_line_type:'T2'});
		                				var total_2_line_no = total_2.line_no;
		                				
                						var index = parseInt($scope.irsF8858ScheduleMData[i].line_no);
                						if(index < start_2_line_no || index === total_2_line_no)
                						{    $scope.irsF8858ScheduleMData[i].col_c1.VALUE =  (vm.adjAmts[index].C_AMT == undefined ? null : vm.adjAmts[index].C_AMT );
                							//$scope.irsF8858ScheduleMData[i].col_c.VALUE = -1 * vm.adjAmts[index].C_AMT;
                							if( $scope.irsF8858ScheduleMData[i].col_c.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_c.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_c.VALUE == null) && vm.adjAmts[index].C_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_c.VALUE = (vm.adjAmts[index].C_AMT == undefined ? null : vm.adjAmts[index].C_AMT );
                								}
                							else $scope.irsF8858ScheduleMData[i].col_c.VALUE = $scope.irsF8858ScheduleMData[i].col_c.VALUE;
                						}
                						else
                						{
                							$scope.irsF8858ScheduleMData[i].col_c1.VALUE =  (vm.adjAmts[index].C_AMT == undefined ? null : vm.adjAmts[index].C_AMT );
                							if( $scope.irsF8858ScheduleMData[i].col_c.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_c.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_c.VALUE == null) && vm.adjAmts[index].C_AMT  )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_c.VALUE =  (vm.adjAmts[index].C_AMT == undefined ? null : vm.adjAmts[index].C_AMT );
                								}
                							else $scope.irsF8858ScheduleMData[i].col_c.VALUE = $scope.irsF8858ScheduleMData[i].col_c.VALUE;
                						}
                					}
                					if(angular.isDefined($scope.irsF8858ScheduleMData[i]) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_d) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_d.VALUE)) 
                					{
                						var start_2= _.find($scope.irsF8858ScheduleMData, {line_type:'start2'});
		                				var start_2_line_no = start_2.line_no;
		                				var total_2= _.find($scope.irsF8858ScheduleMData, {total_line_type:'T2'});
		                				var total_2_line_no = total_2.line_no;
		                				
                						var index = parseInt($scope.irsF8858ScheduleMData[i].line_no);
                						if(index < start_2_line_no || index === total_2_line_no)
                						{
                							$scope.irsF8858ScheduleMData[i].col_d1.VALUE = (vm.adjAmts[index].D_AMT == undefined ? null :vm.adjAmts[index].D_AMT );
                							if( $scope.irsF8858ScheduleMData[i].col_d.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_d.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_d.VALUE == null) && vm.adjAmts[index].D_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_d.VALUE = (vm.adjAmts[index].D_AMT == undefined ? null :vm.adjAmts[index].D_AMT);
                								}
                							else $scope.irsF8858ScheduleMData[i].col_d.VALUE = $scope.irsF8858ScheduleMData[i].col_d.VALUE;
                						}
                						{
                							$scope.irsF8858ScheduleMData[i].col_d1.VALUE = (vm.adjAmts[index].D_AMT == undefined ? null :vm.adjAmts[index].D_AMT );
                							if( $scope.irsF8858ScheduleMData[i].col_d.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_d.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_d.VALUE == null) && vm.adjAmts[index].D_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_d.VALUE =  (vm.adjAmts[index].D_AMT == undefined ? null :vm.adjAmts[index].D_AMT);
                								}
                							else $scope.irsF8858ScheduleMData[i].col_d.VALUE = $scope.irsF8858ScheduleMData[i].col_d.VALUE;
                						}
                						
                					}
                					if(angular.isDefined($scope.irsF8858ScheduleMData[i]) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_e) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_e.VALUE)) 
                					{
                						var start_2= _.find($scope.irsF8858ScheduleMData, {line_type:'start2'});
		                				var start_2_line_no = start_2.line_no;
		                				var total_2= _.find($scope.irsF8858ScheduleMData, {total_line_type:'T2'});
		                				var total_2_line_no = total_2.line_no;
		                				
                						var index = parseInt($scope.irsF8858ScheduleMData[i].line_no);
                						if(index < start_2_line_no || index === total_2_line_no)
                						{
                							
                							if( $scope.irsF8858ScheduleMData[i].col_e.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_d.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_d.VALUE == null) && vm.adjAmts[index].D_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_e.VALUE = (vm.adjAmts[index].E_AMT == undefined ? null :vm.adjAmts[index].E_AMT );
                								}
                							else $scope.irsF8858ScheduleMData[i].col_e.VALUE = $scope.irsF8858ScheduleMData[i].col_e.VALUE;
                						}
                						{
                							
                							if( $scope.irsF8858ScheduleMData[i].col_e.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_d.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_d.VALUE == null) && vm.adjAmts[index].D_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_e.VALUE =  (vm.adjAmts[index].E_AMT == undefined ? null :vm.adjAmts[index].E_AMT);
                								}
                							else $scope.irsF8858ScheduleMData[i].col_e.VALUE = $scope.irsF8858ScheduleMData[i].col_e.VALUE;
                						}
                						
                					}
                					if(angular.isDefined($scope.irsF8858ScheduleMData[i]) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_f) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_f.VALUE)) 
                					{
                						var start_2= _.find($scope.irsF8858ScheduleMData, {line_type:'start2'});
		                				var start_2_line_no = start_2.line_no;
		                				var total_2= _.find($scope.irsF8858ScheduleMData, {total_line_type:'T2'});
		                				var total_2_line_no = total_2.line_no;
		                				
                						var index = parseInt($scope.irsF8858ScheduleMData[i].line_no);
                						if(index < start_2_line_no || index === total_2_line_no)
                						{
                							
                							if( $scope.irsF8858ScheduleMData[i].col_f.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_d.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_d.VALUE == null) && vm.adjAmts[index].D_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_f.VALUE = (vm.adjAmts[index].F_AMT == undefined ? null :vm.adjAmts[index].F_AMT );
                								}
                							else $scope.irsF8858ScheduleMData[i].col_f.VALUE = $scope.irsF8858ScheduleMData[i].col_f.VALUE;
                						}
                						{
                							
                							if( $scope.irsF8858ScheduleMData[i].col_f.trans_Details_key == "0")// && $scope.irsF8858ScheduleMData[i].col_d.VALUE == 0) /*|| $scope.irsF8858ScheduleMData[i].col_d.VALUE == null) && vm.adjAmts[index].D_AMT != 0 )*/
                								{
                								$scope.irsF8858ScheduleMData[i].col_f.VALUE =  (vm.adjAmts[index].F_AMT == undefined ? null :vm.adjAmts[index].F_AMT );
                								}
                							else $scope.irsF8858ScheduleMData[i].col_f.VALUE = $scope.irsF8858ScheduleMData[i].col_f.VALUE;
                						}
                						
                					}
                					
                						
                				}
                			}
                			
                			/////updating  total after override adj_amts
                			vm.computeColValues('b1',1);
                			vm.computeColValues('b',1);
                			vm.computeColValues('c1',1);
                			vm.computeColValues('c',1);
                			vm.computeColValues('d1',1);
                			vm.computeColValues('d',1);
                			vm.computeColValues('c1',11);
                			vm.computeColValues('c',11);
                			vm.computeColValues('d1',11);
                			vm.computeColValues('d',11);
                			vm.computeColValues('b',11);
                			vm.computeColValues('b1',11);
                			vm.computeColValues('e',11);
                			vm.computeColValues('f',11);
                			vm.computeColValues('e',1);
                			vm.computeColValues('f',1);
                			//console.log("$scope.irsF8858ScheduleMData--- after adj_amt------", $scope.irsF8858ScheduleMData);
                			angular.copy($scope.irsF8858ScheduleMData, $scope.irsF8858ScheduleMOriginalData);
                			
                		}
                		else {
							if (data.errorMessage === "no access") {
								AlertService.add("error","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"error",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						 }
                		
                	 });
                }
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	
		        vm.link = function()
                {
                	console.log("in link function------");
                	workspaceFactory.loadLink("workflow", null, "39", "67", [], {}, null);
                }
		        
		        
//COMPUTE COLUMN TOTAL Functionalities/////////////////////////////////////////////////////////////////////////////////////////////////////////
		        vm.computeColValues = function(col_name, v_line_no,value)
		        {
		            var line_T1 = _.find($scope.irsF8858ScheduleMData, {total_line_type:'T1'});
		            var total_line_T1 = line_T1.line_no;
	                var line_T2 = _.find($scope.irsF8858ScheduleMData, {total_line_type:'T2'});
		        	var total_line_T2 = line_T2.line_no;
		        	
		        	if (col_name!== undefined && v_line_no < total_line_T2){
	                	
	                	for(var i =0 ;i <$scope.resposneTempData.length; i++)
	        			{   
	                		if(v_line_no < total_line_T1 ){
	                		var total_line = _.find($scope.irsF8858ScheduleMData, {line_no:total_line_T1});
	                		}
	                		else if (v_line_no > total_line_T1 && v_line_no < total_line_T2 ) {
	                			total_line =_.find($scope.irsF8858ScheduleMData, {line_no:total_line_T2});
	                		}
	                		
	                		
	        				var temp_line_obj_update = _.find($scope.irsF8858ScheduleMData, {line_no:v_line_no});
	        				
	        				if(temp_line_obj_update !== undefined && parseInt(temp_line_obj_update.line_no) == parseInt($scope.resposneTempData[i].LINE_NO ))
	        				{
	        					var temp_col_obj1 = temp_line_obj_update.col_b;
	        					var temp_total_col_obj1 = total_line.col_b ;
	        					
	        					if(temp_col_obj1 !== undefined){
	        						var b_amt = $scope.resposneTempData[i].B_AMT == undefined ? null : (-1 * ($scope.resposneTempData[i].B_AMT)).toString()
	        					    temp_col_obj1.VALUE	= temp_col_obj1.VALUE == undefined ? null : (temp_col_obj1.VALUE).toString()	
	        					    		if (b_amt == null && temp_col_obj1.VALUE == null){
	    	            						temp_col_obj1.is_Update = 'N';
	    		        						temp_total_col_obj1.is_Update = 'Y';
	    	            					}			
	        						if (temp_col_obj1.VALUE !== undefined && (temp_col_obj1.VALUE)  !== b_amt ){
            				    		temp_col_obj1.is_Update = 'Y'; 
            							temp_total_col_obj1.is_Update = 'Y';
            				    	}
            				   	            					
            					   else if (temp_col_obj1.trans_Details_key!== '0'){
        						   temp_col_obj1.is_Update = 'Y'; 
        						   temp_total_col_obj1.is_Update = 'Y';
        					       }else{
        						   temp_col_obj1.is_Update = 'N';
        						   temp_total_col_obj1.is_Update = 'Y';
        					       }
            				     }
	        					var temp_col_obj2 = temp_line_obj_update.col_c;
	        					var temp_total_col_obj2= total_line.col_c ;
	        					
	        					if(temp_col_obj2 !== undefined){
	        						var c_amt = $scope.resposneTempData[i].C_AMT == undefined ? null : (-1 * ($scope.resposneTempData[i].C_AMT)).toString()
	        					    temp_col_obj2.VALUE	= temp_col_obj2.VALUE == undefined ? null : (temp_col_obj2.VALUE).toString()	
	        					    		if (c_amt == null && temp_col_obj2.VALUE == null){
	    	            						temp_col_obj2.is_Update = 'N';
	    		        						temp_total_col_obj2.is_Update = 'Y';
	    	            					}			
	        						if (temp_col_obj2.VALUE !== undefined && (temp_col_obj2.VALUE)  !== c_amt ){
            				    		temp_col_obj2.is_Update = 'Y'; 
            							temp_total_col_obj2.is_Update = 'Y';
            				    	}
            				   	            					
            					   else if (temp_col_obj2.trans_Details_key!== '0'){
		        						    temp_col_obj2.is_Update = 'Y'; 
		        						    temp_total_col_obj2.is_Update = 'Y';
        					       }else{
		        						   temp_col_obj2.is_Update = 'N';
		        						   temp_total_col_obj2.is_Update = 'Y';
        					       }
            				     }
	            				var temp_col_obj3 = temp_line_obj_update.col_d;
	            				var temp_total_col_obj3 = total_line.col_d;
	            				
	            				if(temp_col_obj3 !== undefined){
	            					var d_amt = $scope.resposneTempData[i].D_AMT == undefined ? null : (-1 * ($scope.resposneTempData[i].D_AMT)).toString()
	            					temp_col_obj3.VALUE = temp_col_obj3.VALUE == undefined ? null : (temp_col_obj3.VALUE).toString()
	            					if (d_amt == null && temp_col_obj3.VALUE == null){
	            						temp_col_obj3.is_Update = 'N';
		        						temp_total_col_obj3.is_Update = 'N';
	            					}	
	            					if (temp_col_obj3.VALUE !== undefined && (temp_col_obj3.VALUE)  !== d_amt ){
	            				    		temp_col_obj3.is_Update = 'Y'; 
	            							temp_total_col_obj3.is_Update = 'Y';
	            				    	}
	            				   	            					
	            					else if (temp_col_obj3.trans_Details_key!== '0'){
	        						temp_col_obj3.is_Update = 'Y'; 
	        						temp_total_col_obj3.is_Update = 'Y';
	        					}else{
	        						temp_col_obj3.is_Update = 'N';
	        						temp_total_col_obj3.is_Update = 'Y';
	        					}
	            				}
	            				var temp_col_obj4 = temp_line_obj_update.col_e;
	            				var temp_total_col_obj4 = total_line.col_e;
	            				temp_col_obj4.VALUE = temp_col_obj4.VALUE == undefined ? null : (temp_col_obj4.VALUE).toString()
	            				if(temp_col_obj4.VALUE !== undefined && (temp_col_obj4.VALUE !== null)){
	            				   temp_col_obj4.is_Update = 'Y';  
	            				   temp_total_col_obj4.is_Update = 'Y';
	        					} else if (temp_col_obj4.trans_Details_key!== '0'){
        						    temp_col_obj4.is_Update = 'Y'; 
        						    temp_total_col_obj4.is_Update = 'Y';
					            }else{
	        						temp_col_obj4.is_Update = 'N';
	        						temp_total_col_obj4.is_Update = 'Y';
	            				}
	            				var temp_col_obj5 = temp_line_obj_update.col_f;
	            				var temp_total_col_obj5 = total_line.col_f;
	            				temp_col_obj5.VALUE = temp_col_obj5.VALUE == undefined ? null : (temp_col_obj5.VALUE).toString()
	            				if(temp_col_obj5.VALUE !== undefined &&(temp_col_obj5.VALUE !== null)){
	            					temp_col_obj5.is_Update = 'Y'; 
	            					temp_total_col_obj5.is_Update = 'Y';
	        					} else if (temp_col_obj5.trans_Details_key!== '0'){
        						    temp_col_obj5.is_Update = 'Y'; 
        						    temp_total_col_obj5.is_Update = 'Y';
	        					}else{
	        						temp_col_obj5.is_Update = 'N';
	        						temp_total_col_obj5.is_Update = 'Y';
	        					}
	            				}
	        			}
	                	
	                }
		        	
		        	if (col_name!== undefined && v_line_no > total_line_T2){
	                	
	                	for(var i =0 ;i <$scope.resposneTempData.length; i++)
	        			{  
	                		
	        				var temp_line_obj_update = _.find($scope.irsF8858ScheduleMData, {line_no:v_line_no});
	        				
	        				if(temp_line_obj_update !== undefined && parseInt(temp_line_obj_update.line_no) == parseInt($scope.resposneTempData[i].LINE_NO ))
	        				{
	        					var temp_col_obj1 = temp_line_obj_update.col_b;
	        					
	        					if(temp_col_obj1 !== undefined){
	        						var b_amt = $scope.resposneTempData[i].B_AMT == undefined ? null : (-1 * ($scope.resposneTempData[i].B_AMT)).toString()
	        					    temp_col_obj1.VALUE	= temp_col_obj1.VALUE == undefined ? null : (temp_col_obj1.VALUE).toString()	
	        					    		if (b_amt == null && temp_col_obj1.VALUE == null){
	    	            						temp_col_obj1.is_Update = 'N';
	    	            					}			
	        						if (temp_col_obj1.VALUE !== undefined && (temp_col_obj1.VALUE)  !== b_amt ){
            				    		temp_col_obj1.is_Update = 'Y';
            				    	}
            				   	            					
            					   else if (temp_col_obj1.trans_Details_key!== '0'){
        						   temp_col_obj1.is_Update = 'Y';
        					       }else{
        						   temp_col_obj1.is_Update = 'N';
        					       }
            				     }
	        					var temp_col_obj2 = temp_line_obj_update.col_c;
	        					
	        					if(temp_col_obj2 !== undefined){
	        						var c_amt = $scope.resposneTempData[i].C_AMT == undefined ? null : (-1 * ($scope.resposneTempData[i].C_AMT)).toString()
	        					    temp_col_obj2.VALUE	= temp_col_obj2.VALUE == undefined ? null : (temp_col_obj2.VALUE).toString()	
	        					    		if (c_amt == null && temp_col_obj2.VALUE == null){
	    	            						temp_col_obj2.is_Update = 'N';
	    	            					}			
	        						if (temp_col_obj2.VALUE !== undefined && (temp_col_obj2.VALUE)  !== c_amt ){
            				    		temp_col_obj2.is_Update = 'Y';
            				    	}
            				   	            					
            					   else if (temp_col_obj2.trans_Details_key!== '0'){
		        						    temp_col_obj2.is_Update = 'Y'; 
        					       }else{
		        						   temp_col_obj2.is_Update = 'N';
        					       }
            				     }
	            				var temp_col_obj3 = temp_line_obj_update.col_d;
	            				
	            				if(temp_col_obj3 !== undefined){
	            					var d_amt = $scope.resposneTempData[i].D_AMT == undefined ? null : (-1 * ($scope.resposneTempData[i].D_AMT)).toString()
	            					temp_col_obj3.VALUE = temp_col_obj3.VALUE == undefined ? null : (temp_col_obj3.VALUE).toString()
	            					if (d_amt == null && temp_col_obj3.VALUE == null){
	            						temp_col_obj3.is_Update = 'N';
	            					}	
	            					if (temp_col_obj3.VALUE !== undefined && (temp_col_obj3.VALUE)  !== d_amt ){
	            				    		temp_col_obj3.is_Update = 'Y'; 
	            				    	}
	            				   	            					
	            					else if (temp_col_obj3.trans_Details_key!== '0'){
	        						temp_col_obj3.is_Update = 'Y'; 
	        					}else{
	        						temp_col_obj3.is_Update = 'N';
	        					}
	            				}
	            				var temp_col_obj4 = temp_line_obj_update.col_e;
	            				temp_col_obj4.VALUE = temp_col_obj4.VALUE == undefined ? null : (temp_col_obj4.VALUE).toString()
	            				if(temp_col_obj4.VALUE !== undefined && (temp_col_obj4.VALUE !== null)){
	            				   temp_col_obj4.is_Update = 'Y'; 
	        					}else if (temp_col_obj4.trans_Details_key!== '0'){
	        						temp_col_obj4.is_Update = 'Y'; 
	        					}else{
	        						temp_col_obj4.is_Update = 'N';
	            				}
	            				var temp_col_obj5 = temp_line_obj_update.col_f;
	            				temp_col_obj5.VALUE = temp_col_obj5.VALUE == undefined ? null : (temp_col_obj5.VALUE).toString()
	            				if(temp_col_obj5.VALUE !== undefined &&(temp_col_obj5.VALUE !== null)){
	            					temp_col_obj5.is_Update = 'Y';
	        					}else if (temp_col_obj5.trans_Details_key!== '0'){
	        						temp_col_obj5.is_Update = 'Y'; 
	        					}else{
	        						temp_col_obj5.is_Update = 'N';
	        					}
	            				}
	        			}
	                	
	                }
		        	var total = null;
		        	var endLine_no = null;
		        	var startLine_no = null;
		        
		        	
		        	if(parseInt(v_line_no) < total_line_T1)
		        	{
		        		var start_line1= _.find($scope.irsF8858ScheduleMData, {line_type:'start1'});
		                 startLine_no = start_line1.line_no;
		        		 endLine_no = total_line_T1;
		        	}
		        	else if(parseInt(v_line_no) > total_line_T1 )
		        	{
		        		var start_line2= _.find($scope.irsF8858ScheduleMData, {line_type:'start2'});
		                startLine_no = start_line2.line_no;
		        		endLine_no = total_line_T2;
		        	}
		        	
		        	var i = null;
		        	for(var k = 0 ; k < $scope.irsF8858ScheduleMData.length; k++)
		        	{
		        		if($scope.irsF8858ScheduleMData[k].line_no == startLine_no.toString())
		        		{
		        			i = k;
		        			break;
		        		}
		        		
		        	}
		        	while(i < $scope.irsF8858ScheduleMData.length && $scope.irsF8858ScheduleMData[i].line_no != endLine_no)
		        	{
		        		if(angular.isDefined($scope.irsF8858ScheduleMData) &&  angular.isDefined($scope.irsF8858ScheduleMData[i])) 
		        		{  
		        								
		        			if(col_name == 'b')
		        			{
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_b) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_b.VALUE) &&  $scope.irsF8858ScheduleMData[i].col_b.VALUE !=null /*&& $scope.irsF8858ScheduleMData[i].col_b.VALUE*/)
		        				{
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_b.VALUE);
		        				}
		        			}
		        			else if(col_name == 'b1')
		        			{
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_b1) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_b1.VALUE) && $scope.irsF8858ScheduleMData[i].col_b1.VALUE !=null)
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_b1.VALUE);
		        			}
		        			else if(col_name == 'c')
		        			{
		        				//if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_c) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_c.VALUE) && $scope.irsF8858ScheduleMData[i].col_c.VALUE != null && $scope.irsF8858ScheduleMData[i].col_c.VALUE)
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_c) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_c.VALUE) && $scope.irsF8858ScheduleMData[i].col_c.VALUE != null)
			        					
		        				{
		        					//console.log("in if-----$scope.irsF8858ScheduleMData[i].col_c.VALUE---------------", $scope.irsF8858ScheduleMData[i].col_c.VALUE);
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_c.VALUE);
		        					//console.log("total after each----", total);
		        					}
		        			}
		        			else if(col_name == 'c1')
		        			{
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_c1) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_c1.VALUE) && $scope.irsF8858ScheduleMData[i].col_c1.VALUE != null /*&& $scope.irsF8858ScheduleMData[i].col_c1.VALUE*/)
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_c1.VALUE);
		        			}
		        			else if(col_name == 'd1')
		        			{
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_d1) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_d1.VALUE) && $scope.irsF8858ScheduleMData[i].col_d1.VALUE != null /*&& $scope.irsF8858ScheduleMData[i].col_d1.VALUE*/)
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_d1.VALUE);
		        			}		        			
		        			else if(col_name == 'd')
		        			{
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_d) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_d.VALUE) && $scope.irsF8858ScheduleMData[i].col_d.VALUE != null/* && $scope.irsF8858ScheduleMData[i].col_d.VALUE*/)
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_d.VALUE);
		        			}
		        			else if(col_name == 'e')
		        			{
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_e) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_e.VALUE) && $scope.irsF8858ScheduleMData[i].col_e.VALUE != null /*&& $scope.irsF8858ScheduleMData[i].col_e.VALUE*/)
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_e.VALUE);
		        			}
		        			else if(col_name == 'f')
		        			{
		        				if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_f) && angular.isDefined($scope.irsF8858ScheduleMData[i].col_f.VALUE) && $scope.irsF8858ScheduleMData[i].col_f.VALUE!= null /*&& $scope.irsF8858ScheduleMData[i].col_f.VALUE */)
		        					total = total + parseInt($scope.irsF8858ScheduleMData[i].col_f.VALUE);
		        			}
		        		}
		        		i++;
		        	}
		        	
		        	if(i != null)
		        	{
		        		switch(col_name)
		        		{
			        		case 'b' :
			        			$scope.irsF8858ScheduleMData[i].col_b.VALUE = total == null ? total : parseInt(total);
			        			break;
			        		case 'c' :
			        			$scope.irsF8858ScheduleMData[i].col_c.VALUE = total == null ? total : parseInt(total);
			        			break;	
			        		case 'b1' :
			        			$scope.irsF8858ScheduleMData[i].col_b1.VALUE = total == null ? total : parseInt(total);
			        			break;	
			        		case 'c1' :
			        			$scope.irsF8858ScheduleMData[i].col_c1.VALUE = total == null ? total : parseInt(total);
			        			break;
			        		case 'd1' :
			        			$scope.irsF8858ScheduleMData[i].col_d1.VALUE = total == null ? total : parseInt(total);
			        			break;	
			        		case 'd' :
			        			$scope.irsF8858ScheduleMData[i].col_d.VALUE = total == null ? total : parseInt(total);
			        			break;
			        		case 'e' :
			        			$scope.irsF8858ScheduleMData[i].col_e.VALUE = total == null ? total : parseInt(total);
			        			break;
			        		case 'f' :
			        			$scope.irsF8858ScheduleMData[i].col_f.VALUE = total == null ? total : parseInt(total);
			        			break;
		        		}
		        	}
		        	
		        	
		        }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		        /* save from workflow 5/21/2019 */
				   $scope.$on('workflowSave',function(event,data){
						vm.save();
				   })  
//SAVE Button Functionalities/////////////////////////////////////////////////////////////////////////////////////////////////////////
		       vm.save = function()
		       {
		    	   if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
			                return;
			            }
               	
               		$scope.crudLoading = true;
		    	   var editRow = [];

		    	   var scheduleSettings = [{
	                	"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
	                    "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
	                    "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
	                }];
		    	   
		    	   console.log("$scope.irsF8858ScheduleMData-------- in save-----", $scope.irsF8858ScheduleMData);
		    	   
		    	   for(var i = 0; i<$scope.irsF8858ScheduleMData.length; i++)
		    	   {

		    		 if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_b) && $scope.irsF8858ScheduleMData[i].col_b.is_Update == 'Y')
		    		 {
		    			 var tempObj = [{
								"tax_year":$scope.irsF8858ScheduleMData[i].saveParams.tax_year,
								"trans_type_key":$scope.irsF8858ScheduleMData[i].saveParams.trans_type_key,
								"combination_key":vm.rowData.COMBINATION_KEY,
								"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
								"form_key":$scope.irsF8858ScheduleMData[i].saveParams.form_key,
								"line_no":$scope.irsF8858ScheduleMData[i].line_no,
								"occurence":(($scope.irsF8858ScheduleMData[i].col_b.occurrence == "0") ? null : $scope.irsF8858ScheduleMData[i].col_b.occurrence),
								"line_attrib_key" : $scope.irsF8858ScheduleMData[i].col_b.line_attrib_key,
								"Attribute_Name": $scope.irsF8858ScheduleMData[i].col_b.attrib_name,
								"Attribute_Value": ($scope.irsF8858ScheduleMData[i].col_b.VALUE != null ? $scope.irsF8858ScheduleMData[i].col_b.VALUE * $scope.irsF8858ScheduleMData[i].line_multiplier : ''),
								"trans_dtls_key" : (($scope.irsF8858ScheduleMData[i].col_b.trans_Details_key == "0") ? null : $scope.irsF8858ScheduleMData[i].col_b.trans_Details_key),
								"trans_status":"A"}];
	                		
	                			editRow.push(tempObj);
		    		 }
		    		 if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_c)&& $scope.irsF8858ScheduleMData[i].col_c.is_Update == 'Y')
		    		 {
		    			 var tempObj = [{
								"tax_year":$scope.irsF8858ScheduleMData[i].saveParams.tax_year,
								"trans_type_key":$scope.irsF8858ScheduleMData[i].saveParams.trans_type_key,
								"combination_key":vm.rowData.COMBINATION_KEY,
								"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
								"form_key":$scope.irsF8858ScheduleMData[i].saveParams.form_key,
								"line_no":$scope.irsF8858ScheduleMData[i].line_no,
								"occurence":(($scope.irsF8858ScheduleMData[i].col_c.occurrence == "0") ? null : $scope.irsF8858ScheduleMData[i].col_c.occurrence),
								"line_attrib_key" : $scope.irsF8858ScheduleMData[i].col_c.line_attrib_key,
								"Attribute_Name": $scope.irsF8858ScheduleMData[i].col_c.attrib_name,
								"Attribute_Value": ($scope.irsF8858ScheduleMData[i].col_c.VALUE != null ? $scope.irsF8858ScheduleMData[i].col_c.VALUE * $scope.irsF8858ScheduleMData[i].line_multiplier :''),
								"trans_dtls_key" : (($scope.irsF8858ScheduleMData[i].col_c.trans_Details_key == "0") ? null : $scope.irsF8858ScheduleMData[i].col_c.trans_Details_key),
								"trans_status":"A"}];
	                		
	                			editRow.push(tempObj);
		    		 }
		    		
		    		 if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_d)&& $scope.irsF8858ScheduleMData[i].col_d.is_Update == 'Y')
		    		 {
		    			 var tempObj = [{
								"tax_year":$scope.irsF8858ScheduleMData[i].saveParams.tax_year,
								"trans_type_key":$scope.irsF8858ScheduleMData[i].saveParams.trans_type_key,
								"combination_key":vm.rowData.COMBINATION_KEY,
								"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
								"form_key":$scope.irsF8858ScheduleMData[i].saveParams.form_key,
								"line_no":$scope.irsF8858ScheduleMData[i].line_no,
								"occurence":(($scope.irsF8858ScheduleMData[i].col_d.occurrence == "0") ? null : $scope.irsF8858ScheduleMData[i].col_d.occurrence),
								"line_attrib_key" : $scope.irsF8858ScheduleMData[i].col_d.line_attrib_key,
								"Attribute_Name": $scope.irsF8858ScheduleMData[i].col_d.attrib_name,
								"Attribute_Value": ($scope.irsF8858ScheduleMData[i].col_d.VALUE != null ? $scope.irsF8858ScheduleMData[i].col_d.VALUE * $scope.irsF8858ScheduleMData[i].line_multiplier : ''),
								"trans_dtls_key" : (($scope.irsF8858ScheduleMData[i].col_d.trans_Details_key == "0") ? null : $scope.irsF8858ScheduleMData[i].col_d.trans_Details_key),
								"trans_status":"A"}];
	                		
	                			editRow.push(tempObj);
		    		 }
		    		
		    		 if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_e)&& $scope.irsF8858ScheduleMData[i].col_e.is_Update == 'Y')
		    		 {
		    			 var tempObj = [{
								"tax_year":$scope.irsF8858ScheduleMData[i].saveParams.tax_year,
								"trans_type_key":$scope.irsF8858ScheduleMData[i].saveParams.trans_type_key,
								"combination_key":vm.rowData.COMBINATION_KEY,
								"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
								"form_key":$scope.irsF8858ScheduleMData[i].saveParams.form_key,
								"line_no":$scope.irsF8858ScheduleMData[i].line_no,
								"occurence":(($scope.irsF8858ScheduleMData[i].col_e.occurrence == "0") ? null : $scope.irsF8858ScheduleMData[i].col_e.occurrence),
								"line_attrib_key" : $scope.irsF8858ScheduleMData[i].col_e.line_attrib_key,
								"Attribute_Name": $scope.irsF8858ScheduleMData[i].col_e.attrib_name,
								"Attribute_Value": ($scope.irsF8858ScheduleMData[i].col_e.VALUE != null ? $scope.irsF8858ScheduleMData[i].col_e.VALUE * $scope.irsF8858ScheduleMData[i].line_multiplier : ''),
								"trans_dtls_key" : (($scope.irsF8858ScheduleMData[i].col_e.trans_Details_key == "0") ? null : $scope.irsF8858ScheduleMData[i].col_e.trans_Details_key),
								"trans_status":"A"}];
	                		
	                			editRow.push(tempObj);
		    		 }
		    		 if(angular.isDefined($scope.irsF8858ScheduleMData[i].col_f)&& $scope.irsF8858ScheduleMData[i].col_f.is_Update == 'Y')
		    		 {
		    			 var tempObj = [{
								"tax_year":$scope.irsF8858ScheduleMData[i].saveParams.tax_year,
								"trans_type_key":$scope.irsF8858ScheduleMData[i].saveParams.trans_type_key,
								"combination_key":vm.rowData.COMBINATION_KEY,
								"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
								"form_key":$scope.irsF8858ScheduleMData[i].saveParams.form_key,
								"line_no":$scope.irsF8858ScheduleMData[i].line_no,
								"occurence":(($scope.irsF8858ScheduleMData[i].col_f.occurrence == "0") ? null : $scope.irsF8858ScheduleMData[i].col_f.occurrence),
								"line_attrib_key" : $scope.irsF8858ScheduleMData[i].col_f.line_attrib_key,
								"Attribute_Name": $scope.irsF8858ScheduleMData[i].col_f.attrib_name,
								"Attribute_Value": ($scope.irsF8858ScheduleMData[i].col_f.VALUE != null ? $scope.irsF8858ScheduleMData[i].col_f.VALUE * $scope.irsF8858ScheduleMData[i].line_multiplier : ''),
								"trans_dtls_key" : (($scope.irsF8858ScheduleMData[i].col_f.trans_Details_key == "0") ? null : $scope.irsF8858ScheduleMData[i].col_f.trans_Details_key),
								"trans_status":"A"}];
	                		
	                			editRow.push(tempObj);
		    		 }
		    	   }
		    	   
		    	   var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                	console.log("ScheduleDetails---------------------", scheduleDetails);
            		var message = "Schedule M details are sucessfully save/edit";
            		
            		scheduleMFormServiceFactory.save8858ScheduleM(scheduleSettings, scheduleDetails).then(function(result) {
            			if (result.errorMessage
							&& result.errorMessage !== 'null') {
            				$scope.crudLoading = false;
						AlertService.add("error",
							result.errorMessage, 4000);
						} else {
							$scope.crudLoading = false;
							loadIRSF8858ScheduleMData();
							AlertService.add("success", message, 4000);

						}
					});
		    	   
		       }
            }
            return controllers;
});