define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module(
					'app.tblMaintenanceController', []).controller(
					'TblMaintenanceController',
					[ '$rootScope', '$scope', '$http', 'GlobalService',
							'$uibModalInstance', 'ModalFactory',
							'AlertService', 'rowData', 'gridData',
							'JsonObjectFactory','USER_SETTINGS', '$timeout',
							'TblMaintenanceFactory', 'GENERAL_CONFIG',
							tblMaintenanceController ])

			function tblMaintenanceController($rootScope, $scope,
					$http, GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,USER_SETTINGS,
					$timeout, TblMaintenanceFactory, GENERAL_CONFIG) {

				var vm = this;
				$scope.crudLoading = false;
				$scope.rowData = rowData || $scope.$parent.rowData;
				vm.tbl_row_list =[];
				vm.tbl_cl_list =[];
				vm.src_col_list =[];
       			vm.tar_col_list =[];
       			vm.match_col_list =[];
       			vm.saveinsertobj = [];
				vm.saveinsertobjclob = [];
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				vm.ObjectIDLIST = [];
				vm.show_tbl = false;
				vm.showWhereClauseField = false;
				fetch_tbl_list_data();
				vm.s_tbl_list = ['Archive','Purge','Adhoc']
				// c95czh
				vm.changes_name = function (){
					if (vm.r_s_tbl == 'Archive')
						{
						vm.show_tbl = true;
						vm.showWhereClauseField = false;
						}
					else if(vm.r_s_tbl === 'Adhoc') {
						vm.showWhereClauseField = true;
						vm.show_tbl = false;
					}
					else{
						vm.show_tbl = false;
						vm.showWhereClauseField = false;
						vm.r_archive_tbl_name ="";
						vm.tbl_cl_list = [];
						vm.src_col_list =[];
		       			vm.tar_col_list =[];
		       			vm.match_col_list =[]
					}
				}
				vm.changeTable = function (){
					fetch_tbl_col_data();
				}
				vm.addTablerow = function() {
					$timeout(function() {
						var tjson={};
						tjson.SRC_COL_NAME = "";
						tjson.ARC_COL_NAME = "";
						tjson.MATCH_YN ="";
						tjson.COLUMN_ID = "";
						tjson.INCLUDE_YN = true;
						tjson.src_dropdown = true; 
						vm.match_col_list
								.push(tjson);
					});

				}

				function fetch_tbl_list_data() {

					var params = {
						"action_code" : 'c95czh',
						"sso_id" : USER_SETTINGS.user.sso_id,
						"owner":"GTWDBA01"
						
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											
											vm.tbl_list = [];
											for ( var a1 in data.jsonObject) {
												
													vm.tbl_list
															.push(data.jsonObject[a1].TABLE_NAME);
											}
											
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.irsformdata = false;
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;
				// e7vrtt
				// SRC_COL_NAME: "JOB_KEY",MATCH_YN:
				// "Y",sso_id:String:null,owner:String:null,src_tbl:String:null,arc_tbl:String:null
				// ARC_COL_NAME: "JOB_KEY"COLUMN_ID: 1INCLUDE_YN: "Y"
				
				
				//
				function fetch_tbl_col_data() {

					var params = {
						"action_code" : 'e7vrtt',
						"sso_id" : USER_SETTINGS.user.sso_id,
						"owner":"GTWDBA01",
						"src_tbl":vm.r_src_tbl,
						"arc_tbl":vm.r_archive_tbl_name
						
					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.tbl_cl_list = [];
											vm.tbl_cl_list = data.jsonObject;
											vm.src_col_list =[];
							       			vm.tar_col_list =[];
							       			vm.match_col_list =[]
							       			for (var d in vm.tbl_cl_list)
							       				{
							       				vm.src_col_list
												.push(vm.tbl_cl_list[d].SRC_COL_NAME);
							       				vm.tar_col_list
												.push(vm.tbl_cl_list[d].ARC_COL_NAME);
							       					
							       				if (vm.tbl_cl_list[d].MATCH_YN == 'Y')
							       					{
							       					var tempjson = {};
							       					tempjson.SRC_COL_NAME = vm.tbl_cl_list[d].SRC_COL_NAME;
							       					tempjson.ARC_COL_NAME = vm.tbl_cl_list[d].ARC_COL_NAME;
							       					tempjson.MATCH_YN = vm.tbl_cl_list[d].MATCH_YN;
							       					tempjson.COLUMN_ID = vm.tbl_cl_list[d].COLUMN_ID;
							       					tempjson.INCLUDE_YN = (vm.tbl_cl_list[d].INCLUDE_YN == 'TRUE') ? true
							    							: false ;
							       					tempjson.src_dropdown = false; 
							       					vm.match_col_list.push(tempjson);
							       					}
							       				if (vm.tbl_cl_list[d].MATCH_YN ==  "N" && vm.tbl_cl_list[d].SRC_COL_NAME == undefined )
							       					{
							       					var nottempjson = {};
							       					nottempjson.ARC_COL_NAME = vm.tbl_cl_list[d].ARC_COL_NAME;
							       					nottempjson.COLUMN_ID = vm.tbl_cl_list[d].COLUMN_ID;
							       					nottempjson.src_dropdown = true; 
							       					nottempjson.INCLUDE_YN = (vm.tbl_cl_list[d].INCLUDE_YN == 'TRUE') ? true
							    							: false ;
							       					vm.match_col_list.push(nottempjson);
							       					}

							       				/*else 
							       					{
							       					var notmatchjson = {};
							       					notmatchjson.SRC_COL_NAME = vm.tbl_cl_list[d].SRC_COL_NAME;
							       					notmatchjson.ARC_COL_NAME = vm.tbl_cl_list[d].ARC_COL_NAME;
							       					notmatchjson.MATCH_YN = vm.tbl_cl_list[d].MATCH_YN;
							       					notmatchjson.COLUMN_ID = vm.tbl_cl_list[d].COLUMN_ID;
							       					notmatchjson.INCLUDE_YN = (vm.tbl_cl_list[d].INCLUDE_YN == 'TRUE') ? true
							    							: false ;
							       					notmatchjson.src_dropdown = false; 
							       					vm.match_col_list.push(notmatchjson);
							       					}*/
							       				}
							       			
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.irsformdata = false;
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});

				}
				;
				
				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoading = true;
					vm.saveinsertobj = [];
					vm.saveinsertobjclob = [];
					var message = "Data has been saved";
					
					var dummyJsonsavenobj = {};
					dummyJsonsavenobj["ARC_TBL_NAME"] = vm.r_archive_tbl_name;
					dummyJsonsavenobj["DATE_COL_NAME"] = "CREATED_ON";
					dummyJsonsavenobj["DATE_COL_TRANSFORM"] = "";
					dummyJsonsavenobj["NUM_DAYS"] = 180;
					dummyJsonsavenobj["OWNER"] = "GTWDBA01";
					dummyJsonsavenobj["REC_TYPE"] = vm.r_s_tbl;
					dummyJsonsavenobj["ROW_LIMIT"] = 200000;
					dummyJsonsavenobj["RUN_FRI_YN"] = (vm.run_fri_yes == 'Yes') ? 'Y'
							: 'N';
					dummyJsonsavenobj["RUN_MON_YN"] = (vm.run_mon_yes == 'Yes') ? 'Y'
							: 'N';
					dummyJsonsavenobj["RUN_SAT_YN"] = (vm.run_sat_yes == 'Yes') ? 'Y'
							: 'N';
					dummyJsonsavenobj["RUN_SUN_YN"] = (vm.run_sun_yes == 'Yes') ? 'Y'
							: 'N';
					dummyJsonsavenobj["RUN_THU_YN"] = (vm.run_thu_yes == 'Yes') ? 'Y'
							: 'N';
					dummyJsonsavenobj["RUN_TUE_YN"] = (vm.run_tue_yes == 'Yes') ? 'Y'
							: 'N';
					dummyJsonsavenobj["RUN_WED_YN"] = (vm.run_wen_yes == 'Yes') ? 'Y'
							: 'N';
					dummyJsonsavenobj["SOURCE_SYSTEM_FLAG"] = 'D';
					dummyJsonsavenobj["SRC_TBL_NAME"] =  vm.r_src_tbl;
					dummyJsonsavenobj["TBL_SEQUENCE_KEY"] = "";
					dummyJsonsavenobj["DELETE_YN"] = "false";
					if(vm.showWhereClauseField) {
						dummyJsonsavenobj["WHERE_CLAUSE"] = vm.r_where_clause;
						dummyJsonsavenobj["DATE_COL_NAME"] = null;
						dummyJsonsavenobj["DATE_COL_TRANSFORM"] = null;
						dummyJsonsavenobj["ROW_LIMIT"] = null;
						dummyJsonsavenobj["NUM_DAYS"] = null;
					}
					vm.saveinsertobj.push(dummyJsonsavenobj);
					vm.saveinsertobjclob= vm.match_col_list;
					insertMaintananceSave(vm.saveinsertobj, vm.saveinsertobjclob,
							message);
				}
				function insertMaintananceSave(returnObj, accountArray, message) {

					TblMaintenanceFactory.saveTableMaintanancemodal(returnObj, accountArray).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoading = false;
									
								} else {
									AlertService.add("success", message, 4000);
									$scope.crudLoading = false;
									$uibModalInstance.dismiss('cancel');
									if( vm.ObjectIDLIST && _.isArray(vm.ObjectIDLIST) && vm.ObjectIDLIST.length<=10){
										
										var arg3= (Array.prototype.map.call(vm.ObjectIDLIST, s=> s.OBJECT_ID).toString());
										  var args1 = {
												OBJECT_ID : arg3,
												gridFilter : vm.ObjectIDLIST
										  }
										  $rootScope.$emit('gridUpdate', args1);
									}else{
										$rootScope.$broadcast('dataFilters:refreshGrid', {
											  "refresh": true
										  });
									}
								}
							});

				}
				vm.confirm = function() {
					vm.sendpopupData = [];
					var tmp_json = {};
					var all_object_id = gridData[0].data.OBJECT_ID;
					for ( var e in gridData) {
						tmp_json = {};
						tmp_json.ARC_TBL_NAME = gridData[e].data.ARC_TBL_NAME;
						tmp_json.DATE_COL_NAME = gridData[e].data.DATE_COL_NAME;
						tmp_json.DATE_COL_TRANSFORM = gridData[e].data.DATE_COL_TRANSFORM;
						tmp_json.NUM_DAYS = gridData[e].data.NUM_DAYS;
						tmp_json.OWNER = gridData[e].data.OWNER;
						tmp_json.REC_TYPE = gridData[e].data.REC_TYPE;
						tmp_json.ROW_LIMIT = gridData[e].data.ROW_LIMIT;
						tmp_json.RUN_FRI_YN = gridData[e].data.RUN_FRI_YN;
						tmp_json.RUN_MON_YN = gridData[e].data.RUN_MON_YN;
						tmp_json.RUN_SAT_YN = gridData[e].data.RUN_SAT_YN;
						tmp_json.RUN_SUN_YN = gridData[e].data.RUN_SUN_YN;
						tmp_json.RUN_THU_YN = gridData[e].data.RUN_THU_YN;
						tmp_json.RUN_WED_YN = gridData[e].data.RUN_WED_YN;
						tmp_json.RUN_MON_YN = gridData[e].data.RUN_MON_YN;
						tmp_json.SOURCE_SYSTEM_FLAG = gridData[e].data.SOURCE_SYSTEM_FLAG;
						tmp_json.SRC_TBL_NAME = gridData[e].data.SRC_TBL_NAME;
						tmp_json.TBL_SEQUENCE_KEY = gridData[e].data.TBL_SEQUENCE_KEY;
						if(tmp_json.REC_TYPE === "ADHOC"){
							tmp_json.WHERE_CLAUSE = gridData[e].data.WHERE_CLAUSE;
						}
					// tmp_json.WHERE_CLAUSE = gridData[e].data.WHERE_CLAUSE;
						tmp_json.DELETE_YN = "true";
						vm.sendpopupData.push(tmp_json);
						all_object_id += '_' + gridData[e].data.OBJECT_ID;
						vm.ObjectIDLIST.push({OBJECT_ID:gridData[e].data.OBJECT_ID});

					}
					$scope.crudLoading = true;
					TblMaintenanceFactory
							.de_record(vm.sendpopupData, vm.sendpopupData)
							.then(
									function(result) {

										if (result.callSuccess !== "1") {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoading = false;

										} else {
											AlertService
													.add(
															"success",
															"Data has been Deleted Successfully ",
															4000);
											$uibModalInstance.dismiss('cancel');
											
											if( vm.ObjectIDLIST && _.isArray(vm.ObjectIDLIST) && vm.ObjectIDLIST.length<=10){
												
												var arg2= (Array.prototype.map.call(vm.ObjectIDLIST, s=> s.OBJECT_ID).toString());
												  var args = {
														OBJECT_ID : arg2,
														gridFilter : vm.ObjectIDLIST
												  }
												  $rootScope.$emit('gridUpdate', args);
											}else{
												$rootScope.$broadcast('dataFilters:refreshGrid', {
													  "refresh": true
												  });
											}
			

										}
									});
				}
				
			}
			return controllers;

		});

		