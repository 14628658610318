define([
    'angular',
    './AggreDashboardController',
    './AggreDashboardService'



], function () {
    'use strict';
    return angular.module('app.aggreDashboard', ['app.aggredashboardController','app.aggredashboardService'] );


});