define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.systemMessageService',[])

        .factory("systemMessageFactory",['GENERAL_CONFIG','AlertService','$http','$rootScope',
                function(GENERAL_CONFIG, AlertService, $http,$rootScope) {
          var systemMessageFactory = {};

          systemMessageFactory.selectedNotifData = [];
          systemMessageFactory.NotifListData = []
          
            var URLS = {
                getAllSystemNotif: GENERAL_CONFIG.admin_api_url + "/getAllSystemNotif",
                getAllUserNames: GENERAL_CONFIG.base_url +'/loadJsonObject?action_id=30815',
                createSysNotif: GENERAL_CONFIG.admin_api_url + '/saveSysNotif',
                saveSysNotifConfig: GENERAL_CONFIG.admin_api_url + '/saveDontShowSysMsg'
            }

            //Service call to Get all the user list..
            systemMessageFactory.getAllUsers = function() {
                var promise = $http({
                    method: "GET",
                    url: URLS.getAllUserNames
                }).then(function(response) {
                    if(response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        AlertService.add("error", "No users list found", 4000);
                    }
                });
                return promise;
            }
            
            // Service call to Get all the system messsage/notif list..
            systemMessageFactory.getAllSystemNotif = function() {
                var promise = $http({
                    method: "GET",
                    url: URLS.getAllSystemNotif
                }).then(function(response) {
                    if(response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        AlertService.add("error", "No system notification list data found", 4000);
                    }
                });
                return promise;
            }

            // Storing the selected list in service for future use..
            systemMessageFactory.storeSelectedNotifData = function(data,notifList) {
                systemMessageFactory.selectedNotifData = data;
                systemMessageFactory.NotifListData = notifList;
            }
            systemMessageFactory.saveSysMsg = function(params) {
                var _data = {
                    data : JSON.stringify(params)
                }
                var promise = $http({
                    method: "POST",
                    url: URLS.createSysNotif,
                    data: _data
                }).then(function(response) {
                    return response.data;
                });
                return promise;
            }

            systemMessageFactory.getSystemNotifByUser = function(_url,params) {
              
                var promise = $http({
                    method: "POST",
                    url: _url,
                    data:params.data
                }).then(function(response) {
                    return response.data;
                });
                return promise;
            }

            systemMessageFactory.saveSystemDontShowMsg = function(_data) {
              
                var promise = $http({
                    method: "POST",
                    url: URLS.saveSysNotifConfig,
                    data: _data
                }).then(function(response) {
                    return response.data;
                });
                return promise;
            }


            return systemMessageFactory;
        }])

    return services;
});