define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.externalStateEntityController', [])
    .controller('externalStateEntityController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'gridData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','externalStateEntityService', externalStateEntityController])
    .controller('externalStateEntityCreateController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','externalStateEntityService', externalStateEntityCreateController])
    .controller('DeleteExternalEntityController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'gridData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','externalStateEntityService', DeleteExternalEntityController]);
    
    function externalStateEntityController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, gridData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,externalStateEntityService){
        var vm = this;

        vm.modalTitle = "Edit State Entity";
		vm.showFormLoader = true;
		vm.passgriddata = _.clone(gridData);
		var taxYear = GlobalService.globalParams.tax_year;
		vm.template_selection;
		vm.selectedTemplate;
		vm.display_template;
		vm.showFormLoader = true;
		vm.selectedRowIndex;
		vm.selectedRow = {};
		vm.combination_key_List = [];
		vm.external_entity_list = [];
		vm.saveBtn = true;
		vm.saveloader = false;
        vm.showEntityLoader = true;
        var temp_row_data = {
            le_key: "NEW",
            leid: "",
            le_name: "",
            ein: "",
            // address: "",
            address_line1:"",
            address_line2:"", 
            address_city:"", 
            address_state:"", 
            address_zip:"",
            le_type: "",
            me_key: [],
            ho_leid: [],
            ho_cdr_no: [],
            ho_reporting_period: [],
            state_domicile: "",
            is_me_visible: true
        };

        vm.meList = [];
        vm.hoList = [];
        vm.leidList = [];
        vm.cdrList = [];
        vm.reportingPeriodList = [];
        var leid_cdr_available = false;
        var rptr_available = false;
        vm.loaded_entity_list = [];
        vm.leType = [
        {
            id : 'M',
            name : 'Modification' 
        },{
            id : 'E',
            name : 'Elimination' 
        }];

        $scope.dropdownSetting ={
			enableSearch: true,
			selectionLimit: 1,
			scrollableHeight: '200px', 
			scrollable: true,
			showUncheckAll:false,
			showCheckAll:false,
			smartButtonTextConverter: function(itemText, originalItem) { 
                return itemText; 
			},
			smartButtonTextProvider:function(selectionArray) { return selectionArray[0].label || "Select"; },
		};

		$scope.multiselectEvents1 = {
			onItemSelect: function (item) {
                vm.selected
                vm.cdrList =[];
                item.cdr.forEach(function(data){
					vm.cdrList.push({"id": data, "label": data});
				});
                vm.external_entity_list[vm.selectedRowIndex].ho_cdr_no = [];
                vm.external_entity_list[vm.selectedRowIndex].cdr_list = vm.cdrList;
                vm.external_entity_list[vm.selectedRowIndex].ho_reporting_period = [];
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = [];
                vm.external_entity_list[vm.selectedRowIndex].me_key = [];
                vm.external_entity_list[vm.selectedRowIndex].is_me_visible = false;
			},
			onItemDeselect: function (item) {
                vm.external_entity_list[vm.selectedRowIndex].ho_cdr_no = [];
                vm.external_entity_list[vm.selectedRowIndex].cdr_list = [];
                vm.external_entity_list[vm.selectedRowIndex].ho_reporting_period = [];
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = [];
                vm.external_entity_list[vm.selectedRowIndex].is_me_visible = true;
			}
		};

        $scope.multiselectEvents2 = {
			onItemSelect: function (item) {
                vm.reportingPeriodList =[];
                vm.hoList.forEach(function(data){
					if(data.HO_LEID == vm.external_entity_list[vm.selectedRowIndex].ho_leid[0].id  && data.HO_CDR_NO == item.id){
                        var temp_reporting_period = data.REPORTING_PERIOD.split(',');
                        temp_reporting_period.forEach(function(data){
                            vm.reportingPeriodList.push({"id": data, "label": data});
                        });
                    }
				});
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = vm.reportingPeriodList;
			},
			onItemDeselect: function (item) {
                vm.external_entity_list[vm.selectedRowIndex].ho_reporting_period = "";
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = [];
			}
		};

        $scope.entitySelection = function(k){
			var index = _.findIndex(vm.external_entity_list, {occurance: k})
			vm.selectedRowIndex = index;
		};

		vm.prepareModalData = function(){
            angular.forEach(vm.passgriddata, function (row, key) {
                var temp_cdr = [];
                var LeidIndex = _.findIndex(vm.leidList , {id: row.data.HO_LEID});
                if(LeidIndex !== -1){
                    vm.leidList[LeidIndex].cdr.forEach(function(data){
                        temp_cdr.push({"id": data, "label": data});
                    });
                }

                var reportingPeriodList = [];
                vm.hoList.forEach(function(data, key){
					if(data.HO_LEID == row.data.HO_LEID  && data.HO_CDR_NO == row.data.HO_CDR_NO){
                        var temp_reporting_period = data.REPORTING_PERIOD.split(',');
                        temp_reporting_period.forEach(function(data){
                            reportingPeriodList.push({"id": data, "label": data});
                        });
                    }
				});

                var meIndex = _.findIndex(vm.meList , {id: row.data.ME_KEY});
                var cdrIndex = _.findIndex(temp_cdr , {id: row.data.HO_CDR_NO});
                var reportingPeriodIndex = _.findIndex(reportingPeriodList , {id: row.data.HO_REPORTING_PERIOD ? row.data.HO_REPORTING_PERIOD.toString() : ""});
                
                var temp_data = {
                    occurance: key,
                    le_key: row.data.LE_KEY,
                    leid: row.data.LEID,
                    le_name: row.data.LE_NAME,
                    ein: row.data.EIN,
                    // address: row.data.ADDRESS,
                    address_line1: row.data.ADDRESS_LINE1,
                    address_line2: row.data.ADDRESS_LINE2,
                    address_city: row.data.ADDRESS_CITY,
                    address_state: row.data.ADDRESS_STATE,
                    address_zip: row.data.ADDRESS_ZIP,
                    le_type: row.data.LE_TYPE,
                    me_key: meIndex !== -1 ? [vm.meList[meIndex]] : [],
                    ho_leid: LeidIndex !== -1 ? [vm.leidList[LeidIndex]] : [],
                    ho_cdr_no: cdrIndex !== -1 ? [temp_cdr[cdrIndex]] : [],
                    ho_reporting_period: reportingPeriodIndex !== -1 ? [reportingPeriodList[reportingPeriodIndex]] : [],
                    state_domicile: row.data.STATE_DOMICILE,
                    cdr_list: temp_cdr,
                    reporting_period_list: reportingPeriodList,
                    is_me_visible: LeidIndex !== -1 ? false : true
                };
                vm.external_entity_list.push(temp_data);
            });
            vm.loaded_entity_list = angular.copy(vm.external_entity_list);
        };

		vm.addRows = function(){
            var temp_entity = angular.copy(temp_row_data);
            temp_entity.occurance = vm.external_entity_list.length;
			vm.external_entity_list.push(temp_entity);
		};

        function fetchMe(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_id=31741').then(function (data) {
				vm.meList = [];
                data.jsonObject.forEach(function(data){
					vm.meList.push({"id": data.ME_KEY, "label": data.ME_NAME + " - " + data.ME_CODE, "me_code" : data.ME_CODE, "me_key" : data.ME_KEY });
				});
            });
        };

        function fetchLeidCdr(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_id=31730').then(function (data) {
                leid_cdr_available = true;
				vm.leidList =[];
                vm.tempUSStates = [];
                vm.tempCdrList = {};
                data.jsonObject.forEach(function(data){
					vm.leidList.push({"id": data.HO_LEID, "label": data.HO_LEID, "cdr" : data.HO_CDR.split(','), "internal_flag" : data.HO_INTERNAL_YN, "me_code" : data.ME_CODE, "me_key" : data.ME_KEY});
				});
                if(leid_cdr_available && rptr_available){
                    vm.prepareModalData();
                    vm.showEntityLoader = false;
                }
            });
		};

        function fetchReportingPeriod(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_id=31731').then(function (data) {
                rptr_available = true;
				vm.hoList = data.jsonObject;
                if(leid_cdr_available && rptr_available){
                    vm.prepareModalData();
                    vm.showEntityLoader = false;
                }
            });
		};

        function fetchUSStateList(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=oppj8b').then(function (data) {
				vm.allUSStates = data.jsonObject;
            });
		}

        fetchMe();
        fetchLeidCdr();
        fetchUSStateList();
        fetchReportingPeriod();
        
        vm.saveSchdIForm = function (url, data){
            var jsonSettings = {
            	"tax_year": vm.passgriddata[0].data.TAX_YEAR,
            	"scenario": vm.passgriddata[0].data.SCENARIO,
            	"jcd_key": vm.passgriddata[0].data.JCD_KEY,
            	"combination_key": vm.passgriddata[0].data.COMBINATION_KEY
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
            	vm.saveloader = false;
                var status = response.status;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchdIForm() response.data.jsonObj ::",response.data.jsonObject);
				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					var args = {combination_key: vm.combination_key_List[0], gridFilter: {combination_key: vm.combination_key_List[0]}};
					$rootScope.$emit('gridUpdate', args);
					AlertService.add("success", "Template Assigned successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                    }
				}
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function(){
			vm.mainData = [];
        	vm.saveloader = true;
            var errorFields = [];

            angular.forEach(vm.external_entity_list, function (row, key) {
                vm.mandatoryFields = ["leid", "ein"];
                var tempRowErrorFields = [];
                if(row.ho_leid.length > 0){
                    vm.mandatoryFields.push("ho_cdr_no");
                    vm.mandatoryFields.push("ho_reporting_period");
                }else{
                    vm.mandatoryFields.push("me_key");
                }
                angular.forEach(vm.mandatoryFields, function (val, key) {
                    if(row[val] == undefined){
                        tempRowErrorFields.push(val.toUpperCase());
                    }else if(Array.isArray(row[val])){
                        if(row[val].length == 0){
                            var tempValue = val.split('_');
                            tempRowErrorFields.push(tempValue.join(" ").toUpperCase());
                        }
                    }else{
                        if(!row[val]){
                            tempRowErrorFields.push(val.toUpperCase());
                        }
                    }
                });

                if(tempRowErrorFields.length > 0){
                    tempRowErrorFields.unshift(key + 1);
                    errorFields.push(tempRowErrorFields);
                }
            });

            if(errorFields.length > 0){
                console.log("Error Fields");
                var row = errorFields[0][0];
                var firstIndexErrorField = errorFields[0].slice(1);
                AlertService.add("error", "On row " + row + ", following fields are Mandatory : " + firstIndexErrorField.join(', '), 4000);
                return;
            }

			angular.forEach(vm.external_entity_list, function (row, key) {
                var tempSaveData = {
                    "LE_KEY": row.le_key == "NEW" ? 0 : row.le_key,
                    "LE_ID": row.leid ,
                    "LE_NAME": row.le_name,
                    "EIN": row.ein,
                    // "ADDRESS": row.address,
                    "ADDRESS_LINE1": row.address_line1,
                    "ADDRESS_LINE2": row.address_line2,
                    "ADDRESS_CITY": row.address_city,
                    "ADDRESS_STATE": row.address_state,
                    "ADDRESS_ZIP": row.address_zip,
                    "LE_TYPE": row.le_type,
                    "ME_CODE": row.ho_leid.length > 0 ? row.ho_leid[0].me_code : row.me_key[0].me_code,
                    "ME_KEY": row.ho_leid.length > 0 ? row.ho_leid[0].me_key : row.me_key[0].me_key,
                    "HO_LEID": row.ho_leid.length > 0 ? row.ho_leid[0].id : "",
                    "HO_CDR_NO": row.ho_cdr_no.length > 0 ? row.ho_cdr_no[0].id : "",
                    "HO_REPORTING_PERIOD": row.ho_reporting_period.length > 0 ? row.ho_reporting_period[0].id : "",
                    "STATE_DOMICILE": row.state_domicile,
                    "HO_INTERNAL_YN": row.ho_leid.length > 0 ? row.ho_leid[0].internal_flag : "",
                };
                vm.mainData.push(tempSaveData);
			});
            console.log(vm.mainData);
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31721";
			vm.saveSchdIForm(url, vm.mainData);

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        };

        vm.reset = function (){
			vm.external_entity_list = angular.copy(vm.loaded_entity_list);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function externalStateEntityCreateController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, externalStateEntityCreateController){
        var vm = this;

        vm.modalTitle = "Create State Entity";
		vm.showFormLoader = true;
		vm.selectedRowIndex;
		vm.selectedRow = {};
		vm.combination_key_List = [];
		vm.external_entity_list = [];
		vm.saveBtn = true;
		vm.saveloader = false;
        vm.showEntityLoader = true;
        var temp_row_data = {
            le_key: "NEW",
            leid: "",
            le_name: "",
            ein: "",
            // address: "",
            address_line1:"",
            address_line2:"", 
            address_city:"", 
            address_state:"", 
            address_zip:"",
            le_type: "",
            me_key: [],
            ho_leid: [],
            ho_cdr_no: [],
            ho_reporting_period: [],
            state_domicile: "",
            is_me_visible: true
        };

        vm.meList = [];
        vm.hoList = [];
        vm.leidList = [];
        vm.cdrList = [];
        vm.reportingPeriodList = [];
        var leid_cdr_available = false;
        var rptr_available = false;
        vm.leType = [
        {
            id : 'M',
            name : 'Modification' 
        },{
            id : 'E',
            name : 'Elimination' 
        }];

        $scope.dropdownSetting ={
			enableSearch: true,
			selectionLimit: 1,
			scrollableHeight: '200px', 
			scrollable: true,
			showUncheckAll:false,
			showCheckAll:false,
			smartButtonTextConverter: function(itemText, originalItem) { 
                return itemText; 
			},
			smartButtonTextProvider:function(selectionArray) { return selectionArray[0].label || "Select"; },
		};

		$scope.multiselectEvents1 = {
			onItemSelect: function (item) {
                vm.selected
                vm.cdrList =[];
                item.cdr.forEach(function(data){
					vm.cdrList.push({"id": data, "label": data});
				});
                vm.external_entity_list[vm.selectedRowIndex].ho_cdr_no = [];
                vm.external_entity_list[vm.selectedRowIndex].cdr_list = vm.cdrList;
                vm.external_entity_list[vm.selectedRowIndex].ho_reporting_period = [];
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = [];
                vm.external_entity_list[vm.selectedRowIndex].me_key = [];
                vm.external_entity_list[vm.selectedRowIndex].is_me_visible = false;
			},
			onItemDeselect: function (item) {
                vm.external_entity_list[vm.selectedRowIndex].ho_cdr_no = [];
                vm.external_entity_list[vm.selectedRowIndex].cdr_list = [];
                vm.external_entity_list[vm.selectedRowIndex].ho_reporting_period = [];
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = [];
                vm.external_entity_list[vm.selectedRowIndex].is_me_visible = true;
			}
		};

        $scope.multiselectEvents2 = {
			onItemSelect: function (item) {
                vm.reportingPeriodList =[];
                vm.hoList.forEach(function(data){
					if(data.HO_LEID == vm.external_entity_list[vm.selectedRowIndex].ho_leid[0].id  && data.HO_CDR_NO == item.id){
                        var temp_reporting_period = data.REPORTING_PERIOD.split(',');
                        temp_reporting_period.forEach(function(data){
                            vm.reportingPeriodList.push({"id": data, "label": data});
                        });
                    }
				});
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = vm.reportingPeriodList;
			},
			onItemDeselect: function (item) {
                vm.external_entity_list[vm.selectedRowIndex].ho_reporting_period = "";
                vm.external_entity_list[vm.selectedRowIndex].reporting_period_list = [];
			}
		};

        $scope.entitySelection = function(k){
			var index = _.findIndex(vm.external_entity_list, {occurance: k})
			vm.selectedRowIndex = index;
		};

		vm.addRows = function(){
            var temp_entity = angular.copy(temp_row_data);
            temp_entity.occurance = vm.external_entity_list.length;
			vm.external_entity_list.push(temp_entity);
		};

        function fetchMe(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_id=31741').then(function (data) {
				vm.meList = [];
                data.jsonObject.forEach(function(data){
					vm.meList.push({"id": data.ME_KEY, "label": data.ME_NAME + " - " + data.ME_CODE, "me_code" : data.ME_CODE, "me_key" : data.ME_KEY });
				});
            });
        };

        function fetchLeidCdr(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_id=31730').then(function (data) {
                leid_cdr_available = true;
				vm.leidList =[];
                vm.tempUSStates = [];
                vm.tempCdrList = {};
                data.jsonObject.forEach(function(data){
					vm.leidList.push({"id": data.HO_LEID, "label": data.HO_LEID, "cdr" : data.HO_CDR.split(','), "internal_flag" : data.HO_INTERNAL_YN, "me_code" : data.ME_CODE, "me_key" : data.ME_KEY});
				});
                if(leid_cdr_available && rptr_available){
                    vm.showEntityLoader = false;
                }
            });
		};

        function fetchReportingPeriod(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_id=31731').then(function (data) {
                rptr_available = true;
				vm.hoList = data.jsonObject;
                if(leid_cdr_available && rptr_available){
                    vm.showEntityLoader = false;
                }
            });
		};

        function fetchUSStateList(){
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=oppj8b').then(function (data) {
				vm.allUSStates = data.jsonObject;
            });
		}

        fetchMe();
        fetchLeidCdr();
        fetchUSStateList();
        fetchReportingPeriod();

        vm.saveSchdIForm = function (url, data){
            var jsonSettings = {};
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
            	vm.saveloader = false;
                var status = response.status;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchdIForm() response.data.jsonObj ::",response.data.jsonObject);
				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
					AlertService.add("success", "Groups created successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in creating the group. If this continues please contact support",4000);
                    }
				}
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function(){
			vm.mainData = [];
        	vm.saveloader = true;
            var errorFields = [];

            angular.forEach(vm.external_entity_list, function (row, key) {
                vm.mandatoryFields = ["leid", "ein"];
                var tempRowErrorFields = [];
                if(row.ho_leid.length > 0){
                    vm.mandatoryFields.push("ho_cdr_no");
                    vm.mandatoryFields.push("ho_reporting_period");
                }else{
                    vm.mandatoryFields.push("me_key");
                }
                angular.forEach(vm.mandatoryFields, function (val, key) {
                    if(row[val] == undefined){
                        tempRowErrorFields.push(val.toUpperCase());
                    }else if(Array.isArray(row[val])){
                        if(row[val].length == 0){
                            var tempValue = val.split('_');
                            tempRowErrorFields.push(tempValue.join(" ").toUpperCase());
                        }
                    }else{
                        if(!row[val]){
                            tempRowErrorFields.push(val.toUpperCase());
                        }
                    }
                });

                if(tempRowErrorFields.length > 0){
                    tempRowErrorFields.unshift(key + 1);
                    errorFields.push(tempRowErrorFields);
                }
            });

            if(errorFields.length > 0){
                console.log("Error Fields");
                var row = errorFields[0][0];
                var firstIndexErrorField = errorFields[0].slice(1);
                AlertService.add("error", "On row " + row + ", following fields are Mandatory : " + firstIndexErrorField.join(', '), 4000);
                return;
            }

			angular.forEach(vm.external_entity_list, function (row, key) {
                var tempSaveData = {
                    "LE_KEY": 0,
                    "LE_ID": row.leid ,
                    "LE_NAME": row.le_name,
                    "EIN": row.ein,
                    // "ADDRESS": row.address,
                    "ADDRESS_LINE1": row.address_line1,
                    "ADDRESS_LINE2": row.address_line2,
                    "ADDRESS_CITY": row.address_city,
                    "ADDRESS_STATE": row.address_state,
                    "ADDRESS_ZIP": row.address_zip,
                    "LE_TYPE": row.le_type,
                    "ME_CODE": row.ho_leid.length > 0 ? row.ho_leid[0].me_code : row.me_key[0].me_code,
                    "ME_KEY": row.ho_leid.length > 0 ? row.ho_leid[0].me_key : row.me_key[0].me_key,
                    "HO_LEID": row.ho_leid.length > 0 ? row.ho_leid[0].id : "",
                    "HO_CDR_NO": row.ho_cdr_no.length > 0 ? row.ho_cdr_no[0].id : "",
                    "HO_REPORTING_PERIOD": row.ho_reporting_period.length > 0 ? row.ho_reporting_period[0].id : "",
                    "STATE_DOMICILE": row.state_domicile,
                    "HO_INTERNAL_YN": row.ho_leid.length > 0 ? row.ho_leid[0].internal_flag : "",
                };
                vm.mainData.push(tempSaveData);
			});
            console.log(vm.mainData);
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31721";
			vm.saveSchdIForm(url, vm.mainData);

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        };

        vm.reset = function (){
			vm.external_entity_list = [];
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function DeleteExternalEntityController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, gridData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,externalStateEntityService){
        var vm = this;

        vm.modalTitle = "Delete State Entity";
		vm.showFormLoader = true;
		vm.passgriddata = _.clone(gridData);
		var taxYear = GlobalService.globalParams.tax_year;
        vm.combination_key_List = [];
		vm.showFormLoader = true;

        $rootScope.$on('newEntityCreation', function(event, args) {
        	console.log("New Entity creation call!!");
        });

        vm.deleteEntity = function (i){
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31722";            
            
            var le_keys = ""; //vm.passgriddata[i].data.LE_KEY;
            vm.passgriddata.forEach(le => {
                le_keys += le.data.LE_KEY+',';
            });
            var params = _.merge({},  GlobalService.globalParams , params);
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            params.scenario = filterParams.scenario;
            params =  _.extend({le_keys: le_keys}, params);
            params = _.merge({}, params,filterParams);

            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isLoading = false;
                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
                    // vm.passgriddata.splice(i, 1);
                    vm.cancel();
					AlertService.add("success", "State entity deleted successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Entity cannot be deleted",4000);
                    }
				}
            }).catch(function(error){
                vm.isLoading = false;
                AlertService.add("error",error);
                console.log('This is the error', error);
            })
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            var args = {combination_key: vm.combination_key_List[0], gridFilter: {combination_key: vm.combination_key_List[0]}};
			$rootScope.$emit('gridUpdate', args);
        };
    } 
    return controllers;
});
