define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.firReviewAutomationService',[])
        .factory("FirReviewAutomationFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$q', '$injector', 'workspaceFactory','GENERAL_CONFIG', 'GlobalService', 
        	function($rootScope, $log,JsonObjectFactory,AlertService, $q, $injector, workspaceFactory,GENERAL_CONFIG, GlobalService) {
            var firReviewAutomationFactory = {};
            
            firReviewAutomationFactory.updateFirTBAutomation = function(_callName, _data){
            	console.log('_data------', _data);
            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            	console.log('filterParams;---', filterParams);
            	var jsonSettings = {'tax_year':filterParams.tax_year, 'scenario':filterParams.scenario, 'jcd_key':(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key};
            	var jsonObj = [];
            	
            	var tempObj = {
						'EP_TRANSACTION' : _data.rowData.EP_TRANSACTION,
						'EXCLUDED_YEAR' : _data.rowData.EXCLUDED_YEAR,
            			'COMBINATION_KEY' : _data.rowData.COMBINATION_KEY,
            			'TRANS_HEADER_KEY' : _data.rowData.TRANS_HEADER_KEY,
            			'TRANS_DETAILS_KEY' : _data.rowData.TRANS_DETAILS_KEY,
            			'LOCAL_CHART_KEY' : _data.rowData.CHART_KEY,
            			'TRIGGER_LOCAL_ACCT_KEY' : _data.rowData.TRIGGER_LOCAL_ACCT_KEY,
            			'TRIGGER_SYSTEM_ACCT' :_data.rowData.TRIGGER_SYSTEM_ACCT,
            			'POSTING_LOCAL_ACCT_KEY' : _data.rowData.POSTING_ACCT_KEY,
            			'POSTING_SYSTEM_ACCT' : (_data.rowData.POSTING_SYSTEM_ACCT == undefined) ? '' : _data.rowData.POSTING_SYSTEM_ACCT, 
            			'EP_AUTO_KEY' : _data.rowData.EP_AUTO_KEY,
            			'TB_TRANS_HEADER_KEY' :_data.rowData.TB_TRANS_HEADER_KEY,
            			'TB_TRANS_DETAILS_KEY' :_data.rowData.TB_TRANS_DETAILS_KEY,
            			'EP_TYPE' : _data.rowData.EP_TYPE_CODE,
            			'TP_KEY' : _data.rowData.TP_KEY,
            			'ISSUE_KEY':_data.rowData.ISSUE_KEY
            	}
            	
            	jsonObj.push(tempObj);
            	
            	var params = filterParams;
            	jsonObj = JSON.stringify(jsonObj);
            	jsonSettings = JSON.stringify(jsonSettings);
            	
	 	        params = _.merge({},  GlobalService.globalParams , params);
	 	        params =  _.extend({jsonObj:jsonObj}, params);
	            params =  _.extend({jsonSettings:jsonSettings}, params);
	            
	            
	            params.process_name =  "FIR TB AUTOMATION";
	            var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=1m57xp";
                
                console.log("params----", params);
              service.saveJSON(url , params).then(function(data){
                    if(data.callSuccess === "1"){
                        AlertService.add("success", "Data saved Successfully.",4000);
                        
                        var args = {
              					 chart: _data.rowData.CHART_KEY,
              					 acct: _data.rowData.ACCT_KEY,
              					 combi: _data.rowData.COMBINATION_KEY,
              					 issue_key:_data.rowData.ISSUE_KEY,
       						
       						gridFilter : {
       							chart: _data.rowData.CHART_KEY,
       	       					 acct: _data.rowData.ACCT_KEY,
       	       					 combi: _data.rowData.COMBINATION_KEY,
       	       					 issue_key:_data.rowData.ISSUE_KEY,
       	       					 object_id:_data.rowData.object_id,
       						}
                           };
                           $rootScope.$emit('gridUpdate', args);
                        
                    }else{
                        AlertService.add("", data.errorMessage );
                        
                    }
                });
            	
            }
            
            return firReviewAutomationFactory;
        }]) 

    return services;
});
