define([
    'angular'
    
], function () {
    'use strict';

    var controllers =  angular.module('app.sltSyncErrorController',[])
    .controller('sltSyncErrorController', ['$scope', '$rootScope', '$state', '$log', 'rowData', 'ObjectDiff', 'JsonObjectFactory', 'AlertService', '$timeout', '$parse', 'JCDFactory', 'ClientAdminFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', '$uibModalInstance', 'sltSyncErrorFactory', 'GlobalService', sltSyncErrorController])

    function sltSyncErrorController($scope, $rootScope, $state, $log, rowData, ObjectDiff, JsonObjectFactory, AlertService, $timeout, $parse, JCDFactory, ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS, $uibModalInstance, entitySyncLogFactory, GlobalService) {

        var vm = this;
        vm.title = "Group Sync Error";
        vm.loading = false;
        vm.sltParams = rowData;
        vm.syncParams = "";

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.getLogJSON = function(){
            vm.loading = true;
			return entitySyncLogFactory.getSltSyncData('GROUP_SYNC_ERROR', vm.sltParams.LOG_ID).then(function (data) {
				if(data.callSuccess == "1" ){
					vm.yourObjectOneJsonView = ObjectDiff.objToJsonView(data.jsonObject[0].RESPONSE);
                    vm.loading = false;
				}else{
					vm.loading = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };
        
        vm.getLogJSON();
    }
    return controllers;
});
