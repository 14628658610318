define([
    'angular'

], function () {
    'use strict';


var models =  angular.module('app.gridModels',[])

    .factory('Grid', ['$http', function($http) {

         /**
         * Constructor, with class name
         */

           function Grid(grid_key, api, state, data) {
                // Public properties, assigned to the instance ('this')
                this.grid_key = grid_key;
                this.api = api;
                this.state = state;
                this.data = data;
            }

            /**
             * Public method, assigned to prototype
             */

            Grid.prototype.getAPI = function () {
                return this.api;
            };


            Grid.prototype.getData = function () {
                return this.data;
            };


            Grid.prototype.setData = function () {
                return this.data;
            };



            /**
             * Private property
             */
            var possibleStates = ['cached', 'loading', 'empty'];

            /**
             * Private function
             */
            function getState(state) {
                return possibleStates.indexOf(state) !== -1;
            }

            /**
             * Static property
             * Using copy to prevent modifications to private property
             */
            Grid.possibleStates = angular.copy(possibleStates);

            /**
             * Static method, assigned to class
             * Instance ('this') is not available in static context

            User.build = function (data) {
                if (!checkRole(data.role)) {
                    return;
                }
                return new User(
                    data.first_name,
                    data.last_name,
                    data.role,
                    gridData.build() // another model
                );
            };
        */
            /**
             * Return the constructor function
             */

            return Grid;
        }])


    return models;


});