define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.sltDataRequestService', [])
    .factory("sltDataRequestFactory", ['$http', 'GENERAL_CONFIG', function ($http, GENERAL_CONFIG) {
        const sltDataRequestFactory = {};

        var URLS = {
            getSltDataRequestUrl: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32777",
        }

        sltDataRequestFactory.getRequestJson = function(logId) {
            var promise = $http({
                method: "GET",
                url: URLS.getSltDataRequestUrl + '&log_id='+ logId
            }).then(function(response) {
                if(response.data.callSuccess === "1") {
                    return response.data;
                } else {
                    AlertService.add("error", "No slt api data list found", 4000);
                }
            });
            return promise;
        }

        return sltDataRequestFactory;
    }]);


    return services;
});