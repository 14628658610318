define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.post86EpHistoryReportCtrlNew', []);
    var controllers = module.controller('post86EpHistoryReportCtrlNew',
                    ['$element','menuDataService','workspaceFactory','gtapsCommonTemplateFactory',
                    'WorkflowTCFactory','$uibModal','GlobalService', 'AlertService', '$timeout', 
                    '$window', '$scope','JsonObjectFactory', '$parse', '$rootScope', 
                    'GENERAL_CONFIG', 'post86EpHistoryReportFactory', 
                    post86EpHistoryReportCtrlNew]);

    function post86EpHistoryReportCtrlNew(element,menuDataService,workspaceFactory,
        gtapsCommonTemplateFactory, WorkflowTCFactory, $uibModal, GlobalService, 
        AlertService, $timeout, $window, $scope,JsonObjectFactory, $parse, $rootScope, 
        GENERAL_CONFIG, post86EpHistoryReportFactory) {

        var vm = this;
        vm.loading = false;
        $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
        vm.pageTitle = "Post-86 E&P History Report - Income/(Deficit)";
        var activeScreenEp = workspaceFactory.activeScreen;
        vm.cacheAllActiveData = {};
        vm.labelYear = "Year";
			vm.labelTotalEPTaxes = "Total E&P / Taxes";
			vm.labelNonHoveringEPTaxes = "Non-Hovering E&P / Taxes";
			vm.labelTotalSection959c3TaxPool = "Total Section 959(c)(3) Tax Pool";
			vm.labelHovering959c3Ep = "Hovering 959(c)(3) E&P";
			vm.labelHovering959c3Taxes = "Hovering 959(c)(3) Taxes";
			vm.labelDescription = "Description";
			vm.labelCite =  "Cite";
			vm.labelDocument = "Document";
			vm.labelTotalAmount = "Total Amount";

			//Data Lists
            vm.dataLoading = [];
            vm.taxYearSL = [];
            vm.taxYearData = [];
            vm.otherTaxYearsData = [];
            vm.epHistoryOptions = [];
            vm.columns = [];
            vm.columnDefs = [];

        function loadDataForGTTable(selectedTaxYear,fromCache) {
            var epHistoryData = [];
            if (vm.columns.length <= 0 && vm.columnNames && vm.columnNames.length > 0) {
                //vm.columns.push({title:"Tax Year", data: "Tax Year"});
                vm.columns.push({title:"Description", data: "Description"});
                vm.columns.push({title:"Cite", data: "Cite"});
                vm.columns.push({title:"Total Amount", data: "Total Amount"});
                vm.columnDefs.push({
                        targets: 2,
                        render: $.fn.dataTable.render.number(','),
                        sClass: "rightAlign"
                    }); 
                var i = 3;
                angular.forEach(vm.columnNames, function(columnName) {
                    vm.columns.push({title: columnName, data: columnName});
                    vm.columnDefs.push({
                            targets: i,
                            render: $.fn.dataTable.render.number(','),
                            sClass: "rightAlign"
                        }); 
                    i++;
                });
            }
            if (vm.lineDescs && vm.lineDescs.length > 0) {
                var i = 0;
                angular.forEach(vm.lineDescs, function(lineDesc) {
                    epHistoryData.push({});
                    var columnName = '';
                    angular.forEach(vm.columns, function(column) {
                        columnName = column.title;
                        // if (columnName && columnName.toUpperCase() === 'TAX YEAR') {
                        // 	epHistoryData[i][columnName] = selectedTaxYear;
                        // } else 
                        if (columnName && columnName.toUpperCase() === 'DESCRIPTION') {
                            epHistoryData[i][columnName] = lineDesc;
                        } else if (columnName && columnName.toUpperCase() === 'CITE') {
                            epHistoryData[i][columnName] = angular.isDefined(vm.lineDescsCite[lineDesc]) ? vm.lineDescsCite[lineDesc] : '';
                        } else if (columnName && columnName.toUpperCase() === 'TOTAL AMOUNT') {
                            epHistoryData[i][columnName] = angular.isDefined(vm.epTotalAmts[lineDesc]) ? vm.epTotalAmts[lineDesc] : '';
                        } else if (columnName && columnName.toUpperCase().startsWith('TOTAL')) {//For PTI Type totals
                            epHistoryData[i][columnName] = angular.isDefined(vm.ptiTotalAmts[lineDesc][columnName]) ? vm.ptiTotalAmts[lineDesc][columnName] : '';
                        } else {
                            epHistoryData[i][columnName] = angular.isDefined(vm.lineDescData[lineDesc][columnName]) ? vm.lineDescData[lineDesc][columnName] : '';
                        }
                    });
                    i++;
                });
            }
            vm.taxYearData[selectedTaxYear] = epHistoryData;
            vm.epHistoryOptions[selectedTaxYear] = {
                data: vm.taxYearData[selectedTaxYear],
                columns: vm.columns,
                groupBy: "Tax Year",
                columnDefs: vm.columnDefs,
                fixedHeader: true,
                paging: false,
                scroller: false,
                searching: false,
                bSort: false,
                "order": false,

            };
            
            if(fromCache == undefined){
                vm.cacheAllActiveData.commonPtiData = gtapsCommonTemplateFactory.statusPti.jsonObject;
                console.log("|||||||| cmmon pti data||||||||||" , vm.cacheAllActiveData.commonPtiData)
              vm.cacheAllActiveData.commonPtepData = gtapsCommonTemplateFactory.statusPtep.jsonObject;
              activeScreenEp.loading_data = false;
              workspaceFactory.setCache(activeScreenEp, vm.cacheAllActiveData);
              workspaceFactory.setCurrentFilters(activeScreenEp);
            }
           
        }

        registerEvents();

			function registerEvents() {
				//On Drilldown
				$(element).on('click', 'td.details-control', function (e) {
					if (angular.isUndefined(vm.selectedHoLeidParams) || vm.selectedHoLeidParams === null) {
						return;
					}
					var rowId = $(this)[0].getAttribute('rowId');//$(this).attr('rowId');
					vm.dataLoading[rowId] = true;
					var tr = $(this).closest('tr');
					console.log(tr)
					//var row = $scope.table.row(tr);
					var row;
					try {
						row = $("#epHistoryMainTable").DataTable().row(tr);
					} catch (e) {
						console.log('Error while initializing dataTable');
					}
					if (angular.isUndefined(vm.taxYearData[rowId]) || (vm.taxYearData[rowId]).length <= 0) {
						vm.selectedHoLeidParams.tax_year = rowId;
						var index = vm.otherTaxYearsData.findIndex(otherTaxYearRow => (otherTaxYearRow.TAX_YEAR == rowId));
						if (index >= 0) {
							vm.selectedHoLeidParams.scenario = vm.otherTaxYearsData[index].SCENARIO;
							vm.selectedHoLeidParams.parent_me_string = vm.otherTaxYearsData[index].ME_KEY;
						}
						post86EpHistoryReportFactory.getGtapsPost86EpHistoryData(vm.selectedHoLeidParams).then(
							function(data) {
								if (data.callSuccess !== "1") {
									AlertService.add("error", data.errorMessage, 4000);
									vm.displayTable = false;
								} else {
									vm.post86EpHistoryData = data.jsonObject;
									vm.loadHistoryDataForTaxYear(rowId);
									var temp = "<div class='wrapper'><table id='table-" + rowId + "' class='display nowrap table table-bordered table-striped sticky-header table-condensed' style='width:100%'></table></div>"
									if (row.child.isShown()) {
										row.child.hide();
										tr.removeClass('shown');
										$("#epHistoryMainTable").DataTable().draw();
										console.log('table redrawn');
									}
									else {
										row.child(temp).show();
										tr.addClass('shown');
									}
									var dataTableOptions = Object.assign({}, vm.epHistoryOptions[rowId]);
									dataTableOptions.searching = false;//scope.searchable;
									//dataTableOptions.responsive = true;
									dataTableOptions.dom = 't';
									dataTableOptions.order = false;
									dataTableOptions.scrollY = '35vh';
									//dataTableOptions.scrollX = true;
									$("#table-" + rowId).DataTable(dataTableOptions);
									vm.dataLoading[rowId] = false;
								}
								
								
						});
					} else {
						var temp = "<div class='wrapper'><table id='table-" + rowId + "' class='display nowrap table table-bordered table-striped sticky-header table-condensed' style='width:100%'></table></div>"
						if (row.child.isShown()) {
							row.child.hide();
							tr.removeClass('shown');
							$("#epHistoryMainTable").DataTable().draw();
							console.log('table redrawn');
						}
						else {
							row.child(temp).show();
							tr.addClass('shown');
						}
						var dataTableOptions = Object.assign({}, vm.epHistoryOptions[rowId]);
						dataTableOptions.searching = false;//scope.searchable;
						dataTableOptions.order = false;
						dataTableOptions.dom = 't';
						dataTableOptions.scrollY = '35vh';
						$("#table-" + rowId).DataTable(dataTableOptions);
						vm.dataLoading[rowId] = false;
                      
						 
					}
				});
			}
			$scope.$watch(function() { 
					return ($.fn.dataTable.isDataTable("#epHistoryMainTable")) ? $("#epHistoryMainTable").DataTable().data().count() : $("#epHistoryMainTable").DataTable({info: false, sort: false, scrollCollapse: true,searching:false, paging: false, scroller: false,order: false}).data().count();
				}, function(newValue, oldValue) {
				$("#epHistoryMainTable").DataTable().rows().every(function(rowIdx, tableLoop, rowLoop){
					if (rowIdx == 0 && !this.child.isShown()){
					//trigger the click event for the details
					$('td.details-control', this.node()).trigger('click');
					}
				});
			},true);
        
        vm.loadHistoryDataForTaxYear = function(selectedTaxYear,fromCache){
                vm.columnNames = [];
				vm.epTotalAmts = [];
				vm.ptiTotalAmts = [];
				vm.lineDescData = [];
				vm.lineDescs = [];
				vm.lineDescsCite = [];
                vm.loading = false;
                if (vm.taxYearSL && vm.taxYearSL.length > 0) {
					//Do Nothing
				} else if (vm.post86EpHistoryData[0].hasOwnProperty("TAX_YEAR_LIST_JSON")) {
					var taxYearListJson = JSON.parse(vm.post86EpHistoryData[0].TAX_YEAR_LIST_JSON);
					if (taxYearListJson.length > 0) {
						//Populate vm.taxYearSL with distinct tax years
						angular.forEach(taxYearListJson, function(taxYear) {
							if (angular.isDefined(taxYear.TAX_YEAR)) {
								var index = vm.taxYearSL.findIndex( taxYearRow => (taxYearRow.label === taxYear.TAX_YEAR && taxYearRow.value === taxYear.TAX_YEAR));
								//don't push it if it already exists
								if (index === -1) {
									vm.taxYearSL.push({label: taxYear.TAX_YEAR, value: taxYear.TAX_YEAR});
								}
							}
						});
					} else {
						vm.displayTable = false;
						return;
					}
				}

				if (vm.otherTaxYearsData && vm.otherTaxYearsData.length > 0) {
					//Do Nothing
				} else if (vm.post86EpHistoryData[0].hasOwnProperty("OTHER_TAX_YEARS_DATA_JSON")) {
					vm.otherTaxYearsData = JSON.parse(vm.post86EpHistoryData[0].OTHER_TAX_YEARS_DATA_JSON);
				}
				//Calculate estimatedPtiExchangeGainLoss
				if (angular.isDefined(vm.totalPtiUSD) && angular.isDefined(vm.totalUSDBasisInPti)) {
					vm.estimatedPtiExchangeGainLoss = vm.totalPtiUSD - vm.totalUSDBasisInPti;
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("GET_EP_CATEGORY_JSON")) {
					var epCategoryJson = JSON.parse(vm.post86EpHistoryData[0].GET_EP_CATEGORY_JSON);
					var previousPtiType = vm.totalUSDBasisInPti;
					angular.forEach(epCategoryJson, function(epCategory) {
						if (epCategory.hasOwnProperty("PTI_TYPE")) {
							if (previousPtiType === "" || epCategory.PTI_TYPE !== previousPtiType) {
								vm.columnNames.push("Total " + epCategory.PTI_TYPE);
							}
							previousPtiType = epCategory.PTI_TYPE;
						}
						if (epCategory.hasOwnProperty("CATEGORY_NAME")) {
							vm.columnNames.push(epCategory.CATEGORY_NAME);
						}
					});
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("GET_EP_TOTAL_AMTS_JSON")) {
					var epTotalAmtsJson = JSON.parse(vm.post86EpHistoryData[0].GET_EP_TOTAL_AMTS_JSON);
					var previousPtiType = "";
					angular.forEach(epTotalAmtsJson, function(epTotalAmt) {
						if (epTotalAmt.hasOwnProperty("LINE_DESC") && epTotalAmt.hasOwnProperty("TOTAL_AMT")) {
							vm.epTotalAmts[epTotalAmt.LINE_DESC] = epTotalAmt.TOTAL_AMT;
						}
					});
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("GET_PTI_TOTAL_AMTS_JSON")) {
					var ptiTotalAmtsJson = JSON.parse(vm.post86EpHistoryData[0].GET_PTI_TOTAL_AMTS_JSON);
					var previousPtiType = "";
					var ptiTypeDataPerColumn;
					angular.forEach(ptiTotalAmtsJson, function(ptiTotalAmt) {
						if (ptiTotalAmt.hasOwnProperty("LINE_DESC") && ptiTotalAmt.hasOwnProperty("PTI_TYPE") &&
							ptiTotalAmt.hasOwnProperty("TOTAL_AMT")) {
							ptiTypeDataPerColumn = {};
							//ptiTypeDataPerColumn["Total " + ptiTotalAmt.PTI_TYPE] = ptiTotalAmt.TOTAL_AMT;
							if(!angular.isDefined(vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]))
								{
								  vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]= [];
								}
							vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]["Total " + ptiTotalAmt.PTI_TYPE]=ptiTotalAmt.TOTAL_AMT;
							//vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]= (ptiTypeDataPerColumn);
						}
					});
				}
				var index;
				var cite;
				var prevLineDesc = "";
				var lineDescDataPerColumn = {};
				var amtsLen = 0;
				angular.forEach(vm.post86EpHistoryData, function(lineDescRow) {
					amtsLen++;
					cite = "";
					if (angular.isDefined(lineDescRow.LINE_DESC)) {
						index = -1;
						index = vm.lineDescs.findIndex( lineDesc => (lineDescRow.LINE_DESC === lineDesc));
						//don't push it if it already exists
						if (index === -1) {
							if (angular.isDefined(lineDescRow.CITE)) {
								cite = lineDescRow.CITE;
							}
							vm.lineDescs.push(lineDescRow.LINE_DESC);
							vm.lineDescsCite[lineDescRow.LINE_DESC] = cite;
							vm.lineDescData[lineDescRow.LINE_DESC] = {};
						}
						if (prevLineDesc === "") {
							prevLineDesc = lineDescRow.LINE_DESC;
						} else if (prevLineDesc !== lineDescRow.LINE_DESC || amtsLen === vm.post86EpHistoryData.length) {
							vm.lineDescData[prevLineDesc] = lineDescDataPerColumn;
							lineDescDataPerColumn = {};
							prevLineDesc = lineDescRow.LINE_DESC;
						}
						if (angular.isDefined(lineDescRow.CATEGORY_NAME) && angular.isDefined(lineDescRow.AMT)) {
							lineDescDataPerColumn[lineDescRow.CATEGORY_NAME] = lineDescRow.AMT;
						}
					}
				});
				//Check if table has to be displayed or not
				if (vm.columnNames && vm.columnNames.length > 1 &&
					vm.lineDescs && vm.lineDescs.length > 0) {
					vm.displayTable = true;
				} else{
					vm.displayTable = false;
				}
				loadDataForGTTable(selectedTaxYear,fromCache);
        }
        
        function loadGTAPSPost86EpHistoryData(selectedHoLeidParams,fromCache){
            vm.loading = true;
            if(fromCache && fromCache.fromCache){ 
                vm.post86EpHistoryData =activeScreenEp.data.post86EpHistoryData;
                vm.loadHistoryDataForTaxYear(selectedHoLeidParams.tax_year,fromCache);
                vm.loading = false;
            }else{
                post86EpHistoryReportFactory.getGtapsPost86EpHistoryData(selectedHoLeidParams).then(
                    function(data) {
                        if (data.callSuccess !== "1") {
                            AlertService.add("error", data.errorMessage, 4000);
                            vm.loading = false;
                            vm.displayTable = false;
                        } else {				
                            vm.post86EpHistoryData = data.jsonObject;
                            vm.cacheAllActiveData.post86EpHistoryData = data.jsonObject;
                            vm.loadHistoryDataForTaxYear(selectedHoLeidParams.tax_year);
                            vm.loading = false;
                            
                        }
                        
                });
            }
           
        }
        function getTabData(){
            vm.showData = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            
			
              // var filterHoLeidKey = JSON.parse(filterParams.fir_ho_leid_key);
                vm.selectedHoLeidParams =  {"fir_ho_leid":filterParams.fir_ho_leid,
                                                "filing_key":filterParams.filing_key, "tax_year":filterParams.tax_year
                                                ,"scenario":filterParams.scenario
                                            }
               // vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + filterHoLeidKey.HO_FUNCTIONAL_CURRENCY + ")";
               // vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + filterHoLeidKey.HO_FUNCTIONAL_CURRENCY + ")";
                loadGTAPSPost86EpHistoryData(vm.selectedHoLeidParams);
        }

        $scope.$on('dataFilters:refreshGrid', function (event, args) {
            getTabData();
        });
        workspaceFactory.checkFilters(workspaceFactory.activeScreen);

        if(activeScreenEp && activeScreenEp.data){
            vm.showData = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
           // var filterHoLeidKey = JSON.parse(filterParams.fir_ho_leid_key);
                vm.selectedHoLeidParams =  {"fir_ho_leid":filterParams.fir_ho_leid,
                                                "filing_key":filterParams.filing_key, "tax_year":filterParams.tax_year
                                                ,"scenario":filterParams.scenario
                                            }
            loadGTAPSPost86EpHistoryData(vm.selectedHoLeidParams,{fromCache:true});
        }
    }
    return controllers;
});
		