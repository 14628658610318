define(
		[ 'angular' ],
		function() {
			'use strict';
			var controllers = angular.module('app.trialBalanceController', [])
					.controller(
							'trialBalController',
							[ '$rootScope', '$scope', '$filter', '$state',
									'$http', 'ModalFactory', 'AlertService',
									'JsonObjectFactory', 'TbFactory',
									'$timeout', 'rowData', 'colData',
									'gridData', 'GENERAL_CONFIG',
									'workspaceFactory', '$uibModalInstance',
									'CodeComboFactory', 'GlobalService',
									'USER_SETTINGS', trialBalController ])
					.controller('PDFPreviewCtrl',
							[ 'rowData', '$uibModalInstance', PDFPreviewCtrl ])

			function trialBalController($rootScope, $scope, $filter, $state,
					$http, ModalFactory, AlertService, JsonObjectFactory,
					TbFactory, $timeout, rowData, colData, gridData,
					GENERAL_CONFIG, workspaceFactory, $uibModalInstance,
					CodeComboFactory, GlobalService, USER_SETTINGS) {

				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.rowData = rowData;
				vm.curr = vm.rowData.FC;
				vm.colData = colData;
				vm.taxYearBegin = $filter('date')(vm.rowData.TAX_YEAR_BEGIN,
						"MM/dd");
				vm.taxYearEnd = $filter('date')(vm.rowData.TAX_YEAR_END,
						"MM/dd/yyyy");
				$scope.rowData = rowData;
				$scope.grid_type = $scope.rowData.GRID_TYPE;
				$scope.SELECTED_SYSTEM_ACCT = $scope.rowData.SYSTEM_ACCT;
				$scope.SELECTED_SYSTEM_ACCT_DESC = $scope.rowData.SYSTEM_ACCT_DESC;
				$scope.SELECTED_SYSTEM_BOOK_AMT = $scope.rowData.BOOK_AMT;
				$scope.SELECTED_BOOK_ADJS = $scope.rowData.BOOK_ADJ;
				$scope.accounts = [];
				// $scope.enableEdit = $scope.rowData.SCENARIO === GlobalService.selected_scenario_desc && $scope.rowData.TAX_YEAR.toString() === GlobalService.globalParams.tax_year;
				// vm.getAccounts = [];
				$scope.selectAccountData = [];
				$scope.sourceAcctType = null;
				$scope.reclassTotal = "";
				$scope.title = "Book Adjustment Reclass";
				vm.RECLASS_DESCRIPTION = "";
				vm.r_std_desc = "";
				vm.r_cmmt = "";
				$scope.TRANS_HEADER_KEY = "";
				$scope.reclassArray = [];
				$scope.showReclassForm = false;
				$scope.crudLoading = false;
				$scope.existingLoading = false;
				$scope.noRecords = false;
				$scope.fromScreen = false;
				$scope.colData = colData;
				$scope.RECLASS_TYPE = "";
				$scope.scenario = GlobalService.globalParams.scenario;
				$scope.taxYear = GlobalService.globalParams.tax_year;
				$scope.localChartKey = "";
				$scope.mainScreenKey = 18;// ReclassLocalFactory.getActiveScreen();
				$scope.showReclassType = true;
				$scope.showNovYrReclassType = true;
				$scope.delTransDetails = [];
				vm.local_acc_dropdown_data = [];
				vm.tp_dropdown_data = [];
		        vm.viewOnly = false;
		        vm.reclass_desc_dropdown_data = [];
		        vm.comments_desc_dropdown_data = [];
		        vm.reclass_desc_dropdown_data_d = [];
		        vm.comments_desc_dropdown_data_d = [];
				$scope.addTargetAccount = function() {
					var targetAcct = {
						"ACCTTP" : "",
						"LOCAL_ACCOUNT_KEY" : "",
						"SYSTEM_ACCT_KEY" : "",
						"SYSTEM_ACCT" : "",
						"SYSTEM_ACCT_DESC" : "",
						"LOCAL_ACCT" : "",
						"LOCAL_ACCT_DESC" : "",
						"TP_ENTITY" : "",
						"TP_KEY" : null,
						"TP_REP_PD" : 0,
						"IS_TP_EXISTS" : false,
						"BOOK_AMT" : null,
						"PREV_ADJ_AMT" : null,
						"CUR_ADJ_AMT" : null,
						"TYPE" : "blank",
						"IS_DELETE" : "NO",
						"TRANS_DETAILS_KEY" : 0,
						"ISBOOKAMTLOADING" : false,
						"ISBOOKADJLOADING" : false,
						"INVALIDACCTTP" : false
						//"ISSUE_ID" : 0

					};

					$scope.accounts.push(targetAcct);
				};


				vm.setTradingPartner = function(tp, index, form) {
					var currAcct = $scope.accounts[index];

					currAcct["TP_ENTITY"] = tp.TP_LEID + "-" + tp.TP_CDR_NO
							+ "(Pd:" + tp.TP_REP_PD + ")";
					currAcct["TP_KEY"] = tp.TP_KEY;
					currAcct["TP_REP_PD"] = tp.TP_REP_PD;
					currAcct["IS_TP_SELECTED"] = true;
					currAcct["ACCTTP"] = "" + currAcct["LOCAL_ACCT_KEY"]
							+ tp.TP_KEY + tp.TP_REP_PD;
					currAcct["ISBOOKAMTLOADING"] = true;
					currAcct["ISBOOKADJLOADING"] = true;

					if (tp.TP_KEY !== 0) {
						$scope.validateAcctTp(index, form);
						// $scope.validateAccountTPKey(currAcct["LOCAL_ACCT_KEY"],
						// tp.TP_KEY, tp.TP_REP_PD, index);
						var AcctTpKey = "" + currAcct["LOCAL_ACCT_KEY"]
								+ currAcct["TP_KEY"];
						// $scope.getAccounts(AcctTpKey, 'TP', index);

						var params = {
							"action_code" : 'unmge8',
							"screen_key" : $scope.mainScreenKey,
							"combination_key" : $scope.rowData.COMBINATION_KEY,
							"chart_key" : $scope.rowData.LOCAL_CHART_KEY,
							"source_type" : ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? 'D'
									: 'F'),
							"search_type" : 'TP',
							"search_string" : AcctTpKey
						};

						JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {
											if (data.errorMessage
													&& data.errorMessage.length > 0
													&& data.errorMessage !== 'null') {
												AlertService.add("error",
														data.errorMessage);
											} else {
												currAcct["ISBOOKAMTLOADING"] = false;
												currAcct["ISBOOKADJLOADING"] = false;
												if (data.jsonObject.length) {

													var bookAmt = data.jsonObject[0].BOOK_AMT;
													var bookAdj = data.jsonObject[0].BOOK_ADJ;

													$scope.accounts[index]["BOOK_AMT"] = bookAmt;
													$scope.accounts[index]["PREV_ADJ_AMT"] = bookAdj;

												} else {
													$scope.accounts[index]["BOOK_AMT"] = 0;
													$scope.accounts[index]["PREV_ADJ_AMT"] = 0;
												}
											}
										});
					}

				};
				function init() {
				//	getTradingPartners();
					$scope.accounts = [];
			        vm.viewOnly = false;
					$scope.delTransDetails = [];
					$scope.crudLoading = false;
					vm.RECLASS_DESCRIPTION = "";
					vm.r_std_desc = "";
					vm.r_cmmt = "";
					$scope.reclassArray = [];
					$scope.TRANS_HEADER_KEY = "";
					$scope.existingLoading = false;
					$scope.noRecords = false;
					$scope.RECLASS_TYPE = "";
					vm.loadingTpAcct = true;
					vm.loadingSysAcct = true;
					$scope.showReclassType = ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? false
							: true);
					$scope.showNovYrReclassType = ($scope.rowData.NOV_YEAR_END === 'N' ? false
							: true);
					getAccounts();
					if (rowData.SOURCE_SYSTEM == 'DCS'){
					getstdreclass();
					getcmtreclass();
					}
					switch ($scope.colData.map) {
					case 'BOOK_ADJ':
						$scope.RECLASS_TYPE = 'RCY';
						break;
					case 'CY_DECEMBER':
						$scope.RECLASS_TYPE = 'CYD';
						break;
					case 'PY_DECEMBER':
						$scope.RECLASS_TYPE = 'PYD';
						break;
					default:
						$scope.RECLASS_TYPE = 'RCY';
					}

					   if ($scope.reclassFrom !== undefined) {
			                $scope.reclassFrom.$setPristine();
			                $scope.reclassFrom.$setUntouched();
			            }

					if ($scope.rowData.COMBINATION_KEY) {

						CodeComboFactory
								.setCombinationCodeByKey($scope.rowData.COMBINATION_KEY);
						CodeComboFactory.toggle = false;
						$scope.fromScreen = true;
						CodeComboFactory.showFormBtn = false;

						loadExistingReclass();
						$scope.sourceAcctType = $scope.rowData.SYSTEM_ACCT_TYPE;
						if ($scope.grid_type === 'LOCAL_BOOK_TO_TAX_SUMMARY') {
							getAccountData($scope.rowData, 0);
						} else {
							$scope.addTargetAccount();
						}

					} else {
						CodeComboFactory.toggle = true;
					}
				}

				init();

				 function getstdreclass () {
		            // alert(val);
		            var params = {
		                "action_code": 'akjjtr',
						"tax_year" : GlobalService.globalParams.tax_year,
		                "scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
		            };

		            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

		                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
		                    AlertService.add("error", data.errorMessage);
		                } else {
		                    if (data.jsonObject.length) {
		                    	vm.reclass_desc_dropdown_data = data.jsonObject;
		                    	for (var r in vm.reclass_desc_dropdown_data)
		                    		{
		                    		vm.reclass_desc_dropdown_data_d.push(vm.reclass_desc_dropdown_data[r].RECLASSSTDDESC)
		                    		}
		                    	vm.reclass_desc_dropdown_data_d = _.uniq(
										vm.reclass_desc_dropdown_data_d,
										JSON.stringify)
		                    	
		                    }
		                }
		            });
		        };
		        function getcmtreclass () {
		            // alert(val);
		            var params = {
		                "action_code": 'jo8asp',
						"tax_year" : GlobalService.globalParams.tax_year,
		                "scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
		            };

		            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

		                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
		                    AlertService.add("error", data.errorMessage);
		                } else {
		                    if (data.jsonObject.length) {
		                    	vm.comments_desc_dropdown_data = data.jsonObject;
		                    	for (var c in vm.comments_desc_dropdown_data)
		                    		{
		                    		vm.comments_desc_dropdown_data_d.push(vm.comments_desc_dropdown_data[c].RECLASSSTDCOMMENTS)
		                    		}
		                    	vm.comments_desc_dropdown_data_d = _.uniq(
										vm.comments_desc_dropdown_data_d,
										JSON.stringify)
		                    	
		                    }
		                }
		            });
		        };
				$scope.$on('CCKeySet', function(event, data) {

					$scope.localChartKey = CodeComboFactory.chartKey;
					$scope.showReclassForm = true;

					if (!$scope.accounts.length && !$scope.fromScreen) {
						$scope.addTargetAccount();
					}

				});

				$scope.updateReclassType = function(val) {
					$scope.RECLASS_TYPE = val;
				}

				$scope.setSourceTotal = function(_index) {
					var account = $scope.accounts[_index];
					console.log('account at index ' + _index, account)
					account.TOTAL = parseFloat(account.BOOK_AMT)
							+ parseFloat(account.PREV_ADJ_AMT);
					if (account.CUR_ADJ_AMT !== undefined) {
						account.TOTAL += parseFloat(account.CUR_ADJ_AMT);
					}
					setReclassTotal();
				};

				var setExistingReclassTotal = function(array) {
					angular.forEach(array, function(value, key) {
						angular.forEach(value.ACCOUNTS, function(value, key) {
							value.TOTAL = parseFloat(value.BOOK_AMT)
									+ parseFloat(value.PREV_ADJ_AMT);
							if (value.CUR_ADJ_AMT !== undefined) {
								value.TOTAL += parseFloat(value.CUR_ADJ_AMT);
							}
						});
					});
				};

				$scope.removeAccount = function(_index) {

					var getTransType = $scope.accounts[_index]["TYPE"];
					if (getTransType === 'edit') {

						$scope.accounts[_index]["IS_DELETE"] = 'YES';
						$scope.delTransDetails.push($scope.accounts[_index]);
					}
					$scope.accounts.splice(_index, 1);
					setReclassTotal();
				};

				var setReclassTotal = function() {
					var total = 0;
					angular.forEach($scope.accounts, function(value, key) {
						if (value.CUR_ADJ_AMT && !isNaN(value.CUR_ADJ_AMT)) {
							total += parseFloat(value.CUR_ADJ_AMT);
						}
					});
					$scope.reclassTotal = total;
				};

				// $scope.getAccounts = function (val, _index) {
				//
				// console.log('index in getAccounts : ', _index);
				//
				// var balSheetAccts = [1, 2, 3];
				//
				// var acctType = $scope.sourceAcctType;
				// var acct_type_str = "", is_system_acct_search = 'N';
				//
				// if (acctType in balSheetAccts) {
				// acct_type_str = '1,2,3';
				// } else {
				// acct_type_str = '5,6,7';
				// }
				//
				// if ($scope.grid_type == 'SYSTEM_BOOK_TO_TAX_SUMMARY' &&
				// _index === 0) {
				// is_system_acct_search = 'Y';
				// }
				// var params = {
				// "action_code": 'unmge8',
				// "screen_key": $scope.mainScreenKey,
				// "system_acct_search": is_system_acct_search,
				// "combination_key": $scope.rowData.COMBINATION_KEY,
				// "ge_gecs_entity": $scope.rowData.GE_GECS_ENTITY,
				// "chart_key": $scope.rowData.LOCAL_CHART_KEY,
				// "parent_system_acct_key": $scope.rowData.SYSTEM_ACCT_KEY,
				// "source_type": ($scope.rowData.SOURCE_SYSTEM === 'DCS' ?
				// 'D' : 'F'),
				// "search_type": 'ACCT',
				// "search_string": val,
				// "acct_type_str": acct_type_str
				// };
				//
				// return
				// JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +
				// '/loadJsonObject', params).then(function (data) {
				// if (data.errorMessage && data.errorMessage.length > 0 &&
				// data.errorMessage !== 'null') {
				// AlertService.add("error", data.errorMessage);
				// } else {
				// if (data.jsonObject.length) {
				// return data.jsonObject;
				// }
				// }
				// });
				// };

				function getAccounts(val, _index) {

					console.log('index in getAccounts : ', _index);

					var balSheetAccts = [ 1, 2, 3 ];

					var acctType = $scope.sourceAcctType;
					var acct_type_str = "", is_system_acct_search = 'N';

					// if (acctType in balSheetAccts) {
					if (balSheetAccts.includes(acctType)) {
						acct_type_str = '1,2,3';
					} else {
						acct_type_str = '5,6,7';
					}

					if ($scope.grid_type == 'SYSTEM_BOOK_TO_TAX_SUMMARY'
							&& _index === 0) {
						is_system_acct_search = 'Y';
					}
					var params = {
						"action_code" : 'unmge8',
						"screen_key" : $scope.mainScreenKey,
						"system_acct_search" : is_system_acct_search,
						"combination_key" : $scope.rowData.COMBINATION_KEY,
						"ge_gecs_entity" : $scope.rowData.GE_GECS_ENTITY,
						"chart_key" : $scope.rowData.LOCAL_CHART_KEY,
						"parent_system_acct_key" : $scope.rowData.SYSTEM_ACCT_KEY,
						"source_type" : ($scope.rowData.SOURCE_SYSTEM === 'DCS' ? 'D'
								: 'F'),
						"search_type" : 'ACCT',
						"search_string" : val,
						"acct_type_str" : acct_type_str
					};

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage
												&& data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
										} else {
											if (data.jsonObject.length) {
												vm.local_acc_dropdown_data = data.jsonObject;
												vm.loadingSysAcct = false;
												// return data.jsonObject;
											}
										}
									});
				}
				;

				// $scope.getTradingPartners = function (val) {
			/*	function getTradingPartners(val) {
					// alert(val);
					var params = {
						"action_code" : 'sckfcq', // '50swfi',
						"tax_year" : $scope.taxYear,
						"scenario" : $scope.scenario
					// "search_string": val
					};

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.errorMessage
												&& data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
										} else {
											if (data.jsonObject.length) {
												vm.tp_dropdown_data = data.jsonObject;
												vm.loadingTpAcct = false;
												// return data.jsonObject;
											}
										}
									});
				}
				;*/

				
		        $scope.getTradingPartners_fir = function (val) {
		            // alert(val);
		            var params = {
		                "action_code": '50swfi',
		                "tax_year": $scope.taxYear,
		                "scenario": $scope.scenario,
		                "search_string": val
		            };

		            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

		                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
		                    AlertService.add("error", data.errorMessage);
		                } else {
		                    if (data.jsonObject.length) {
		                        return data.jsonObject;
		                    }
		                }
		            });
		        };
				vm.setAccount = function(account, index) {
					console
							.log("--selected account object is below and selected account index is --- "
									+ index);
					console.log(account);

					account.COMBINATION_KEY = $scope.rowData.COMBINATION_KEY;
					if ($scope.accounts.length === 1
							&& $scope.rowData.ACCOUNT_KEY === undefined
							&& !$scope.fromScreen) {
						$scope.rowData.ACCOUNT_KEY = account.ACCOUNT_KEY;
						alert("load existing reclass 1");
						loadExistingReclass();
					}
					getAccountData(account, index);
					//                
					// for ( var a in $scope.accounts) {
					// var duplicateLocalAccts = _.filter($scope.accounts,
					// function(o){
					// return o.LOCAL_ACCT == account.LOCAL_ACCT
					// })
					// if (duplicateLocalAccts.length > 1) {
					// $scope.accounts[index].SAME_ACCTS = true;
					// form.$invalid = false;
					// // $scope.accounts[index].TYPE = 'blank';
					// } else {
					// $scope.accounts[index].SAME_ACCTS = false;
					// }
					//
					// }
				};

				$scope.validateAcctTp = function(_index, form) {
		            
		            var currAcct = $scope.accounts[_index];
		            var _accountKey =  currAcct["LOCAL_ACCT_KEY"];
		           if(currAcct.tpObject == undefined || currAcct.tpObject == ""){	           
		            var _tpKey = currAcct["TP_KEY"];
		            var _tpRepPd = currAcct["TP_REP_PD"];
		           }
		           else{
		           var _tpKey = currAcct.tpObject["TP_KEY"];
		            var _tpRepPd = currAcct.tpObject["TP_REP_PD"];
		           }
		            var getIndexFoundAt = _.findIndex($scope.accounts, {'ACCTTP': "" + _accountKey + _tpKey + _tpRepPd});
		            
//		            var duplicateLocalAccts = _.filter($scope.accounts, function(o){
//						return o.LOCAL_ACCT_KEY == _accountKey;
//					})
		            if(getIndexFoundAt < 0 || _index === getIndexFoundAt) {
//					if(duplicateLocalAccts.length < 1 || duplicateLocalAccts.length == 1 ){
		            	form.innerForm.tradingPartner.$setValidity("invalidAcctTPCombo", true);
		                currAcct["INVALIDACCTTP"] = false;
		            } else {
		            	form.innerForm.tradingPartner.$setValidity("invalidAcctTPCombo", false);
		                //AlertService.add("error", "The Account/Trading Partner combination has already been used for this adjustment. Please correct the errors.", 4000);
		                
		                $timeout(function () {
		                    //$scope.accounts.splice(_index, 1);
		                    currAcct["TP_ENTITY"] =  "";
		                    currAcct["TP_KEY"] = 0;
		                    currAcct["TP_REP_PD"] = 0,
		                    currAcct["IS_TP_EXISTS"] = false;
		                    currAcct["ACCTTP"] = "";
		                    currAcct["ISBOOKAMTLOADING"] = false;
		                    currAcct["ISBOOKADJLOADING"] = false;
		                    currAcct["INVALIDACCTTP"] = true;
//		                    currAcct.tpObject = {
//		                        "TP_ENTITY": "",
//		                        "TP_KEY": 0,
//		                        "TP_REP_PD": 0,
//		                        "TP_LEID": "",
//		                        "TP_CDR_NO": ""
//		                    };
		                }); 
		            }
		        };

				function getAccountData(_data, _index) {

					var tp_exists = ((_data.TP_KEY === 0) ? false : true);
					var tp_rep_pd = ((_data.TP_KEY === 0) ? 0 : _data.TP_REP_PD);
					if (_data.TP_KEY == undefined) {
						_data.TP_KEY = 0;
					}
					var tp_entity = ((_data.TP_KEY === 0) ? "" : _data.TP_LEID
							+ "-" + _data.TP_CDR_NO + "(Pd:" + tp_rep_pd + ")");
					var type = ((_index < 1) ? "source" : "target");

					if (_index < 1
							&& $scope.grid_type === 'LOCAL_BOOK_TO_TAX_SUMMARY') {
						var tot_book_adj = (_data.BOOK_ADJ == undefined ? 0
								: _data.BOOK_ADJ)
								+ (_data.PY_DECEMBER == undefined ? 0
										: _data.PY_DECEMBER)
								+ (_data.CY_DECEMBER == undefined ? 0
										: _data.CY_DECEMBER);
						;
					} else {
						tot_book_adj = _data.BOOK_ADJ;
					}
					var record = {
						"ACCTTP" : "" + _data.LOCAL_ACCT_KEY + _data.TP_KEY
								+ tp_rep_pd,
						"LOCAL_ACCT_KEY" : _data.LOCAL_ACCT_KEY,
						"SYSTEM_ACCT_KEY" : _data.SYSTEM_ACCT_KEY,
						"SYSTEM_ACCT" : _data.SYSTEM_ACCT,
						"SYSTEM_ACCT_DESC" : _data.SYSTEM_ACCT_DESC,
						"LOCAL_ACCT" : _data.LOCAL_ACCT,
						"LOCAL_ACCT_DESC" : _data.LOCAL_ACCT_DESC,
						"TP_ENTITY" : tp_entity,
						"TP_KEY" : _data.TP_KEY,
						"TP_REP_PD" : tp_rep_pd,
						"IS_TP_EXISTS" : tp_exists,
						"BOOK_AMT" : _data.BOOK_AMT,
						"PREV_ADJ_AMT" : tot_book_adj,
						"CUR_ADJ_AMT" : 0,
						"TYPE" : type,
						"TRANS_DETAILS_KEY" : 0,
						"IS_DELETE" : "NO",
						"ISSUE_ID" : _data.ISSUE_ID
					};

					console.log('record', record);

					// prepopulate trading partner and it should be editable
					if (_data.TP_KEY !== 0) {
						record.tpObject = {
							"TP_ENTITY" : tp_entity,
							"TP_KEY" : _data.TP_KEY,
							"TP_REP_PD" : tp_rep_pd,
							"TP_LEID" : _data.TP_LEID,
							"TP_CDR_NO" : _data.TP_CDR_NO
						};

					}

					if (typeof $scope.accounts[_index] === 'undefined') {
						record.TYPE = "source";

						$scope.accounts.push(record);
						$scope.addTargetAccount();
						// console.log("source acct type is : " +
						// $scope.sourceAcctType);
					} else {

						/*
						 * if( _.find($scope.accounts, {'ACCTTP':
						 * record.ACCOUNT_KEY + record.TP_KEY}) !== undefined ){
						 * AlertService.add("error", "The Account AND TP Key
						 * combination "+record.ACCOUNT+ "/ " + record.TP_ENTITY + "
						 * has already been used in this reclass", 4000);
						 * $scope.accounts.splice(_index,1);
						 * //$scope.addTargetAccount(); return false; }
						 */
						/*
						 * console.log('prnting separate attributes');
						 * console.log(record.ACCOUNT_KEY + "," + record.TP_KEY +
						 * "," + record.TP_REP_PD);
						 */
						// $scope.validateAccountTPKey(record.ACCOUNT_KEY,
						// record.TP_KEY, record.TP_REP_PD, _index);
						record.TYPE = "target";
						if (_index === 0) {
							if ($scope.grid_type === 'LOCAL_BOOK_TO_TAX_SUMMARY') {
								record.TYPE = "source";
							}

							$scope.addTargetAccount();
						}
						$scope.accounts[_index] = record;
					}
					// $scope.setSourceTotal($scope.accounts.length - 1);

					/*
					 * var params = { "action_id":21,
					 * "combination_key":_data.COMBINATION_KEY,
					 * "LOCAL_ACCT_KEY": _data.ACCOUNT_KEY };
					 * 
					 * if(typeof $scope.accounts[_index] !== 'undefined') {
					 * $scope.accounts[_index].TYPE = 'loading'; }
					 * JsonObjectFactory.getJSONObj('loadJsonObject.ge',params).then(function(data) {
					 * 
					 * if( data.errorMessage && data.errorMessage.length > 0 &&
					 * data.errorMessage !== 'null' ){ AlertService.add("error",
					 * data.errorMessage, 4000); }else{
					 * 
					 * 
					 * if(data.jsonObject && data.jsonObject.length &&
					 * data.jsonObject[0].ACCOUNT_KEY ){ var record =
					 * data.jsonObject[0];
					 * 
					 * console.log("----------printing record 1 ----------");
					 * console.log(record); record.CUR_ADJ_AMT = null;
					 * 
					 * if(typeof $scope.accounts[_index] === 'undefined') {
					 * record.TYPE = "source";
					 * 
					 * $scope.accounts.push(record); $scope.addTargetAccount();
					 * }else{ if( _.find($scope.accounts, {'ACCOUNT_KEY':
					 * record.ACCOUNT_KEY}) && _.find($scope.accounts,
					 * {'TP_KEY': record.TP_KEY}) !== undefined ){
					 * AlertService.add("error", "The Account AND TP Key
					 * combination "+record.ACCOUNT+ "/ " + record.TP_ENTITY + "
					 * has already been used in this reclass", 4000);
					 * $scope.accounts.splice(_index,1);
					 * $scope.addTargetAccount(); return false; } record.TYPE =
					 * "target"; if(_index === 0 ){ record.TYPE = "source";
					 * $scope.addTargetAccount(); } $scope.accounts[_index] =
					 * record; } $scope.setSourceTotal($scope.accounts.length -
					 * 1); }else{ $scope.accounts.splice(_index,1);
					 * $scope.addTargetAccount(); AlertService.add("error", "The
					 * selected Account did not return a book amount", 4000); } }
					 * });
					 */

				}

				$scope.showDetails = function(_index, _boolean) {
					$scope.reclassArray[_index].open = _boolean;
				};

				$scope.deleteReclass = function(reclass) {
					var returnObj = {};
					var deleteObj = [];
					$scope.existingLoading = true;
					// returnObj.reclass_description =
					// $scope.RECLASS_DESCRIPTION;
					returnObj.trans_header_key = reclass.TRANS_HEADER_KEY;
					returnObj.type = "D";
					returnObj.combination_key = $scope.rowData.COMBINATION_KEY;
					returnObj.chart_key = $scope.rowData.CHART_KEY;
					// returnObj.screen_key = 23;
					returnObj.object_id = $scope.rowData.OBJECT_ID;
					_.forEach(reclass.ACCOUNTS, function(value, key) {

						var tempObj = {
							COMBINATION_KEY : $scope.rowData.COMBINATION_KEY,
							ACCT_KEY : value.LOCAL_ACCT_KEY,
							CHART_KEY : $scope.rowData.LOCAL_CHART_KEY,
							TP_KEY : value.TP_KEY
						}
						deleteObj.push(tempObj);
					});
					// var accountArray = [];
					var message = "Reclass has been successfully deleted";
					reclassCRUDHttp(returnObj, '', message, {}, deleteObj);
				};

				$scope.reset = function() {
					init();
				};

				$scope.save1 = function(form) {
					console.log('form---', form);
					// alert("the adjustment type selected is : " +
					// $scope.RECLASS_TYPE);
					if (form.$invalid) {
						form.$submitted = true;
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						return;
					}

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}

					$scope.crudLoading = true;
					var objSettings = [];
					var returnObj = {};
					var reclassMainType, reclassSubtype;
					returnObj.RECLASS_DESCRIPTION = vm.RECLASS_DESCRIPTION;
					var txIssueId = GlobalService.globalParams.issue_id_for_tx_save;
					returnObj.ISSUE_KEY = txIssueId === undefined || txIssueId === '' ? 
						(GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)) 
						: txIssueId;
					// returnObj.ISSUE_KEY = GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id);
					if (rowData.SOURCE_SYSTEM == 'DCS')
						{
						var reclass_std_desc = "";
						for (var g in vm.reclass_desc_dropdown_data)
							{
							if (vm.reclass_desc_dropdown_data[g].RECLASSSTDDESC == vm.r_std_desc)
								{
								reclass_std_desc = vm.reclass_desc_dropdown_data[g].RECLASS_STD_DESC_KEY;
								}
							}
						var reclass_cmt = "";
						for (var h in vm.comments_desc_dropdown_data)
							{
							if (vm.comments_desc_dropdown_data[h].RECLASSSTDCOMMENTS == vm.r_cmmt)
								{
								reclass_cmt = vm.comments_desc_dropdown_data[h].RECLASS_STD_COMMENTS_KEY;
								}
							}
						returnObj.reclassStdDesc =reclass_std_desc;
						returnObj.reclassStdComments = reclass_cmt;
						}
					// returnObj.TRANS_HEADER_KEY = $scope.TRANS_HEADER_KEY;
					returnObj.TYPE = ($scope.TRANS_HEADER_KEY !== "") ? "U"
							: "C";
					returnObj.TRANS_HEADER_KEY = ($scope.TRANS_HEADER_KEY !== "") ? $scope.TRANS_HEADER_KEY
							: 0;

					switch ($scope.RECLASS_TYPE) {
					case 'RCY':
						reclassMainType = 'R';
						reclassSubtype = 'M';
						break;
					case 'CYD':
						reclassMainType = 'R';
						reclassSubtype = 'C';
						break;
					case 'PYD':
						reclassMainType = 'R';
						reclassSubtype = 'P';
						break;
					case 'SCY':
						reclassMainType = 'S';
						reclassSubtype = 'M';
						break;
					}

					returnObj.RECLASSMAINTYPE = reclassMainType;
					returnObj.RECLASSSUBTYPE = reclassSubtype;
					returnObj.ADJ_ORIGIN_SOURCE = $scope.rowData.SOURCE_SYSTEM;
					returnObj.SOURCE_SYSTEM_FLAG = (returnObj.ADJ_ORIGIN_SOURCE === 'DCS') ? 'D'
							: 'F';
					var message = (returnObj.type === "U") ? "Reclass has been successfully updated"
							: "Reclass has been successfully created";
					returnObj.COMBINATION_KEY = $scope.rowData.COMBINATION_KEY;
					returnObj.CHART_KEY = $scope.rowData.LOCAL_CHART_KEY;
					// returnObj.SCREEN_KEY = 23;
					returnObj.TRANS_DETAILS_KEY = {};

					var accountArray = [];
					var accounts = $scope.accounts;

					/*
					 * console.log('-----------accounts for saving
					 * ---------------'); console.log(accounts);
					 */
				
				    _.forEach($scope.accounts, function (value, key) {
				    	
				    	if(value.tpObject == undefined ||value.tpObject == "" )
				    		{
				    		 var accountM = new accountModel(value.LOCAL_ACCT, value.LOCAL_ACCT_KEY, value.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
				                accountArray.push(accountM);
				    		}
				    	else{
		                var accountM = new accountModel(value.LOCAL_ACCT, value.LOCAL_ACCT_KEY, value.tpObject.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
		                accountArray.push(accountM);
				    	}
		            });
		            
					// alert($scope.delTransDetails.length);
				    if($scope.delTransDetails.length) {
		                 _.forEach($scope.delTransDetails, function (value, key) {
		                	 
		                	 
		                	 if(value.tpObject == undefined ||value.tpObject == "" )
					    		{
		                		  var accountM = new accountModel(value.LOCAL_ACCT, value.LOCAL_ACCT_KEY, value.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
				                    accountArray.push(accountM);
					    		}
		                	 else{
		                    var accountM = new accountModel(value.LOCAL_ACCT, value.LOCAL_ACCT_KEY, value.tpObject.TP_KEY, value.CUR_ADJ_AMT, value.TRANS_DETAILS_KEY, value.IS_DELETE);
		                    accountArray.push(accountM);
		                	 }
		                });
		            
		            }

					objSettings.push(returnObj);
					reclassCRUDHttp(objSettings, accountArray, message, form,
							{});

				};

				function reclassCRUDHttp(returnObj, accountArray, message,
						form, deleteObj) {

					if (returnObj.type === 'D') {
						TbFactory
								.deleteReclass(returnObj, deleteObj)
								.then(
										function(result) {
											GlobalService.globalParams.issue_id_for_tx_save = '';

											if (result.errorMessage
													&& result.errorMessage !== 'null') {
												AlertService.add("error",
														result.errorMessage,
														4000);
											} else {

												AlertService.add("success",
														message, 4000);
												// BROADCAST CHANGE
												// $uibModalInstance.close();
												vm.is_data_saved = true;
												var args = {
													OBJECT_ID : returnObj.object_id,
													COMBINATION_KEY : returnObj.combination_key,
													gridFilter : {
														OBJECT_ID : returnObj.object_id,
														COMBINATION_KEY : returnObj.combination_key
													}
												};
												$rootScope.$emit('gridUpdate',
														args);
												init();
											}
										});
					} else {

						TbFactory
								.saveReclass(returnObj, accountArray)
								.then(
										function(result) {
											GlobalService.globalParams.issue_id_for_tx_save = '';

											if (result.errorMessage
													&& result.errorMessage !== 'null') {
												AlertService.add("error",
														result.errorMessage,
														4000);
											} else {
												AlertService.add("success",
														message, 4000);
												// BROADCAST CHANGE
												// $uibModalInstance.close();
												vm.is_data_saved = true;
												var args = {
													COMBINATION_KEY : returnObj[0].COMBINATION_KEY,
													gridFilter : {
														COMBINATION_KEY : returnObj[0].COMBINATION_KEY
													}
												};
												$uibModalInstance
														.dismiss('cancel');
												$rootScope.$emit('gridUpdate',
														args);
												form.RECLASS_DESCRIPTION_NAME.$dirty = false;
												// fixes for save
												form.$submitted = false;
												form.$dirty = false;
												// init();
											}
										});
					}

				}

				function accountModel(accountCode, acctKey, tp_key, amount,
						trans_dtls_key, is_delete) {
					this.ACCOUNT_CODE = accountCode;
					this.ACCT_KEY = acctKey;
					this.TP_KEY = tp_key;
					this.AMOUNT = amount;
					this.TRANS_DETAILS_KEY = trans_dtls_key;
					this.IS_DELETE = is_delete;
				}

				accountModel.prototype.addAccount = function() {

				};

				$scope.cancel = function() {
					// var args = { combination_keys:
					// vm.rowData.combination_key, gridFilter: {
					// COMBINATION_KEY: vm.rowData.combination_key } };
					// var args = {
					// OBJECT_ID: $scope.rowData.OBJECT_ID,
					// COMBINATION_KEY: $scope.rowData.COMBINATION_KEY,
					// gridFilter: {
					// OBJECT_ID: $scope.rowData.OBJECT_ID,
					// COMBINATION_KEY: $scope.rowData.COMBINATION_KEY
					// }
					// };
					// $rootScope.$emit('gridUpdate', args);
					$uibModalInstance.dismiss('cancel');

				};

				$scope.loadReclass = function(_index) {
					$scope.delTransDetails = [];
					var data = $scope.reclassArray[_index];

					vm.RECLASS_DESCRIPTION = data.ADJ_DESC;
					if (rowData.SOURCE_SYSTEM == 'DCS')
					{
					vm.r_std_desc = data.RECLASS_STD_DESC;
					vm.r_cmmt = data.RECLASS_STD_COMMENTS;
					}
					$scope.TRANS_HEADER_KEY = data.TRANS_HEADER_KEY;
					$scope.accounts = data.ACCOUNTS;
					$scope.RECLASS_TYPE = data.SHORT_ADJ_TYPE;
					  if (data.SHORT_ADJ_TYPE== 'A')
		            	{
		            	vm.viewOnly = true;
		            	}
		            else{
		            	vm.viewOnly = false;

		            }
					_.forEach($scope.accounts, function(value, key) {
						$scope.setSourceTotal(key);
					});

					setReclassTotal();
					$rootScope.$broadcast('table.edit', data);
				};

				function loadExistingReclass() {
					$scope.existingLoading = true;
					var params = {
						"action_code" : 'clgdjl',
						"screen_key" : $scope.mainScreenKey,
						"combination_key" : $scope.rowData.COMBINATION_KEY,
						"chart_key" : $scope.rowData.LOCAL_CHART_KEY,
						"account_key" : $scope.rowData.SYSTEM_ACCT_KEY
					};

					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										$scope.reclassArray = formatReclassData(data.jsonObject);

										setExistingReclassTotal($scope.reclassArray);
										$scope.existingLoading = false;
										if ($scope.reclassArray.length === 0) {
											$scope.noRecords = true;
										}
									})
				}

				function formatReclassData(_data) {
					var reclasses = [];
					var accounts = [];
					_.forEach(_data, function(value, key) {

						if (value.ROW_INDICATOR === 1) {

							var ReclassObj = {};
							ReclassObj = value;
							ReclassObj.ACCOUNTS = [];
							reclasses.push(ReclassObj); // JUST USE VALUE

						} else {
							var AccountObj = {};
							AccountObj = value;
							/*
							 * if (AccountObj.ACCOUNT ===
							 * $scope.rowData.LOCAL_ACCT) { AccountObj.TYPE =
							 * "source"; }
							 */

							AccountObj.TYPE = "edit";
							accounts.push(AccountObj);

						}
					});

					_
							.forEach(
									reclasses,
									function(reclassVal, reclassKey) {
										reclasses[reclassKey].ACCOUNTS = [];
										var row_one_trans_header_key = reclassVal.TRANS_HEADER_KEY;

										_
												.forEach(
														accounts,
														function(value, key) {

															var account_trans_header_key = value.TRANS_HEADER_KEY;

															if (value.ROW_INDICATOR !== 1
																	&& row_one_trans_header_key === account_trans_header_key) {
																reclasses[reclassKey].ACCOUNTS
																		.push(value);

															}

														});
									});

					accounts = null;
					return reclasses;

				}

				vm.viewColumnNames = [ "Updated On", "Repoint Desc",
						"System Acct", "Amount", "DML Type", "Old New Flag",
						"Updated By" ];
				vm.reverse;

				// Initializing the sort_key object, which is used to track
				// whether a column is being sorted in ascending or descending
				// order, or if it is not explicitly sorted
				// sort_key[i] = 0 means that it is not being sorted
				// sort_key[i] = 1 means that it is being sorted in ascending
				// order
				// sort_key[i] = 2 means that it is being sorted in descending
				// order
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				}
				vm.trialBalanceCall = function() {
					var vm = this;
					vm.userSettings = USER_SETTINGS;
					vm.logged_in_user = vm.userSettings.user.sso_id;
					var a = 1;
					vm.showLookUP = false;
					vm.acctzeroflag = false;
					vm.rowData = rowData;
					vm.curr = vm.rowData.FC;
					vm.colData = colData;
					vm.showRepointingError = false;
					vm.showRepointingDescError = false;
					vm.sys_acc = {};
					$scope.ACCT_TYPE = rowData.ACCT_TYPE
					$scope.CHART_DISPLAY = rowData.CHART_DISPLAY
					$scope.LOCAL_ACCT_DISPLAY = rowData.LOCAL_ACCT
					$scope.SYSTEM_ACC_DISPLAY = rowData.SYSTEM_ACCT
					vm.RECLASS_DESCRIPTION = "";
					$scope.crudLoading = false;
					vm.repointingAmount = 0;
					vm.cyAmount = vm.rowData.BOOK_AMT;
					vm.pyAmount = vm.rowData.BOY_AMT;
					vm.is_data_saved = false;
					vm.remainingCyAmount = vm.cyAmount - vm.repointingAmount;
					vm.taxYearBegin = $filter('date')(
							vm.rowData.TAX_YEAR_BEGIN, "MM/dd");
					vm.taxYearEnd = $filter('date')(vm.rowData.TAX_YEAR_END,
							"MM/dd/yyyy");

					var returnObjD = {};
					var sendobj = [];
					var lookupData = [];
					var lookupData1 = [];
					var lookupData2 = [];
					var lookupData222 = [];
					var lookupData333 = [];
					var lookupData3 = [];
					var lookupData4 = [];

					vm.Repoint = {
						SYSC_ACCT : '',
						SYS_ACCT_KEY : '',
						TRANS_HEADER_KEY : '',
						TRANS_DETAILS_KEY : '',
						IRS_Form : '',
						IRS_SCHEDULE : '',
						CHECK_SECTION : '',
						CHECK_PART : '',
						CHECK_COL : '',
						CHECK_SYS_MAP_ACCT : '',
						LOOK_UP_BTN : '',
						AMMOUNT : '',
						newadded : '',
						ADJUSTMENT_DESC : ''
					}
					vm.sys_acc_dropdown_data = [];
					vm.sys_acc_lookup_data = [];
					vm.sys_acc_default_data = [];
					vm.loadLookupForm = [];
					vm.loadLookupSchedule = [];
					vm.loadLookupSection = [];
					vm.loadLookupPart = [];
					vm.loadLookupLine = [];
					vm.loadLookupColumn = [];
					vm.loadLookupSysMapAcct = [];
					vm.repointList = [];
					vm.tempDeletedRepointList = [];
					var sendobj = [];
					var uniquesendobj = [];
					vm.addRepointClicked = false;
					vm.lookUpMapping = false;

					vm.applyflag = "";
					vm.sort_key = {
						'1' : 0,
						'2' : 0,
						'3' : 0,
						'4' : 0,
						'5' : 0,
						'6' : 0,
						'7' : 0
					};
					vm.sort_key1 = {
						'1' : 0,
						'2' : 0,
						'3' : 0,
						'4' : 0,
						'5' : 0,
						'6' : 0,
						'7' : 0,
						'8' : 0,
						'9' : 0
					};

					if (vm.rowData.COMBINATION_KEY) {
						CodeComboFactory
								.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
						CodeComboFactory.toggle = false;
						$scope.fromScreen = true;
						CodeComboFactory.showFormBtn = false;
					} else {
						CodeComboFactory.toggle = true;
					}

					vm.cancel = function() {
						$uibModalInstance.dismiss('cancel');
						sendobj = [];
						returnObjD = {};
					}

					function init() {
						$scope.crudLoading = false;
						getSystemAcctLookUP();
						getSystmAcctDropDown();
						fetchSystemAcctData();
						getDefaultSystemAcctValues();
					}
					init();
					
					vm.reset = function() {
						$scope.crudLoading = false;
						// vm.OLD_DESC = Repoint.ADJUSTMENT_DESC;
						/*
						 * vm.Repoint.ADJUSTMENT_DESC = ""; for (var i = 0 ; i<vm.repointList.length;i++){
						 * vm.repointList[i].SYSC_ACCT = '';
						 * vm.repointList[i].AMMOUNT = ''; vm.repointAmt(); }
						 */
						/*
						 * if (vm.sys_acc_default_data.length > 0) {
						 * //vm.Repoint.ADJUSTMENT_DESC = vm.OLD_DESC;
						 * 
						 * 
						 * getSystemAcctLookUP(); getSystmAcctDropDown();
						 * fetchSystemAcctData(); getDefaultSystemAcctValues(); }
						 */
						vm.Repoint.ADJUSTMENT_DESC = "";
						vm.showRepointingDescError = false;
						vm.repointList = [];
						getSystemAcctLookUP();
						getSystmAcctDropDown();
						fetchSystemAcctData();
						getDefaultSystemAcctValues();
						vm.changeSystemacct();
						vm.repointAmt();
					}

					vm.sortBy = function(key, value) {
						$timeout(function() {
							switch (value) {
							case 0:
								vm.sort_key[key] = 1;
								vm.reverseSort = false;
								break;
							case 1:
								vm.sort_key[key] = 2;
								vm.reverseSort = true;
								break;
							case 2:
								vm.sort_key[key] = 0;
								vm.reverseSort = "";
								break;
							}
						});
					}

					vm.sortBy1 = function(key, value) {
						$timeout(function() {
							switch (value) {
							case 0:
								vm.sort_key1[key] = 1;
								vm.reverseSort1 = false;
								break;
							case 1:
								vm.sort_key1[key] = 2;
								vm.reverseSort1 = true;
								break;
							case 2:
								vm.sort_key1[key] = 0;
								vm.reverseSort1 = "";
								break;
							}
						});
					}
					vm.toggleLookup = function() {
						vm.lookUpMapping = !vm.lookUpMapping;
					};

					vm.resetLookup = function(row) {
						vm.lookupresethaskey = row.$$hashKey;
						for (var i = 0; i < vm.repointList.length; i++) {
							if (vm.repointList[i].$$hashKey == vm.lookupresethaskey) {
								vm.repointList[i].IRS_Form = "";
								vm.repointList[i].IRS_SCHEDULE = "";
								vm.repointList[i].CHECK_SECTION = "";
								vm.repointList[i].CHECK_PART = "";
								vm.repointList[i].CHECK_LINE = "";
								vm.repointList[i].CHECK_COL = "";
								vm.repointList[i].CHECK_SYS_MAP_ACCT = "";
								vm.repointList[i].loadLookupSection = [];
								vm.repointList[i].loadLookupPart = [];
								vm.repointList[i].loadLookupLine = [];
								vm.repointList[i].loadLookupColumn = [];
								vm.repointList[i].loadLookupSysMapAcct = [];

							}
						}
					}

					vm.applyChanges = function(row) {
						vm.sysacctvalue = row.CHECK_SYS_MAP_ACCT;
						vm.haskey = row.$$hashKey;
						for (var i = 0; i < vm.repointList.length; i++) {
							if (vm.repointList[i].$$hashKey == vm.haskey) {
								vm.repointList[i].SYSC_ACCT = vm.sysacctvalue;
							}
						}
						vm.applyflag = true;
						vm.changeSystemacct();
					}

					vm.toggleLookUPSection = function(repointObj) {
						repointObj.showLookUP = !repointObj.showLookUP;
					}

					vm.adjDescChanged = function() {
						if (vm.Repoint.ADJUSTMENT_DESC == ''
								|| typeof vm.Repoint.ADJUSTMENT_DESC == 'undefined') {
							vm.showRepointingDescError = true;
						} else {
							vm.showRepointingDescError = false;
						}
						return vm.showRepointingDescError;
					}

					vm.addRepoint = function() {
						$timeout(function() {
							vm.addRepointClicked = true;
							vm.applyflag = false;
							console.log(vm.Repoint.OBJ)

							vm.repointList
									.push({
										SYSC_ACCT : vm.Repoint.SYSC_ACCT,
										SYS_ACCT_KEY : vm.Repoint.SYSC_ACCT_KEY,
										IRS_Form : vm.Repoint.IRS_Form,
										IRS_SCHEDULE : vm.Repoint.IRS_SCHEDULE,
										CHECK_SECTION : vm.Repoint.IRS_SCHEDULE,
										CHECK_PART : vm.Repoint.CHECK_PART,
										CHECK_COL : vm.Repoint.CHECK_COL,
										CHECK_SYS_MAP_ACCT : vm.Repoint.CHECK_SYS_MAP_ACCT,
										LOOK_UP_BTN : vm.repointList.LOOK_UP_BTN, 
										TP_ENTITY:vm.Repoint.TP_ENTITY,// need
										// to
										// makesure
										AMMOUNT : vm.Repoint.AMMOUNT,
										TYPE_VIEW: 'NEW'
									});
						});

					}

					vm.changeSystemacct = function() {
						vm.sysacctFlag = false;
						for ( var a in vm.repointList) {
							if (vm.repointList[a].SYSC_ACCT === ""
									|| vm.repointList[a].SYSC_ACCT === undefined) {
								vm.repointList[a].sysacctflag = true;
								vm.sysacctFlag = true;
							} else {
								vm.repointList[a].sysacctflag = false;
							}
						}
						if (vm.repointList.length > 1) {
							var counter = 0;
							for ( var a in vm.repointList) {
								counter = 0;
								for ( var b in vm.repointList) {
									if (vm.repointList[a].SYSC_ACCT == vm.repointList[b].SYSC_ACCT
											&& a != b) {
										counter++;
									}
									if (counter >= 1) {
										// vm.repointList[b].sameacctFlag =
										// true;
										vm.repointList[a].sameacctFlag = true;
										vm.sysacctFlag = true;
									} else {
										// vm.repointList[b].sameacctFlag =
										// false;
										vm.repointList[a].sameacctFlag = false;
									}

								}
							}
						}

					}

					vm.addToJson = function(repointObj, isDefaultRecord) {
						var returnObjD = {};

						if (isDefaultRecord) {
							returnObjD["TYPE"] = 'E';
						} else {
							returnObjD["TYPE"] = 'I';
						}

						returnObjD['COMBINATION_KEY'] = rowData.COMBINATION_KEY;
						returnObjD['TAX_YEAR'] = rowData.TAX_YEAR;
						returnObjD['SCENARIO'] = GlobalService.globalParams.scenario;
						returnObjD['JCD_KEY'] = GlobalService.globalParams.jcd_key;
						returnObjD['LOCAL_CHART_KEY'] = rowData.LOCAL_CHART_KEY;
						returnObjD['LOCAL_ACCT_KEY'] = rowData.LOCAL_ACCT_KEY;
						returnObjD['SYSTEM_ACCT_KEY'] = rowData.SYSTEM_ACCT_KEY;
						returnObjD['OLD_SYSTEM_ACCT'] = repointObj.SYS_ACCT_KEY;
						returnObjD['NEW_SYSTEM_ACCT'] = repointObj.SYS_ACCT_KEY;
						returnObjD['OLD_ADJ_AMT'] = repointObj.AMMOUNT;
						returnObjD['NEW_ADJ_AMT'] = repointObj.AMMOUNT;
						returnObjD['TRANS_HEADER_KEY'] = repointObj.TRANS_HEADER_KEY != '' ? repointObj.TRANS_HEADER_KEY
								: '';
						returnObjD['TRANS_DETAILS_KEY'] = repointObj.TRANS_DETAILS_KEY != '' ? repointObj.TRANS_DETAILS_KEY
								: '';
						returnObjD['OLD_ADJUSTMENT_DESC'] = vm.OLD_DESC;
						returnObjD['NEW_ADJUSTMENT_DESC'] = vm.Repoint.ADJUSTMENT_DESC;
						returnObjD['SOURCE_SYSTEM'] = vm.rowData.SOURCE_SYSTEM;
						returnObjD['TP_KEY'] = (repointObj.tpObject == undefined )  ? '': repointObj.tpObject.TP_KEY;
						returnObjD['TP_ENTITY'] = (repointObj.TP_ENTITY== undefined )  ? '': repointObj.TP_ENTITY;


						sendobj.push(returnObjD);
					}
					vm.applycyamt = function(index) {
						vm.repointList[index].AMMOUNT = vm.remainingCyAmount;
						vm.repointAmt();
					}

					vm.removeRepointAmount = function(index) {
						if (sendobj.length > index) {
							sendobj[index].TYPE = "D";
							vm.tempDeletedRepointList.push(sendobj[index]);
							sendobj.splice(index, 1);
						}
						vm.repointList.splice(index, 1);
						vm.repointAmt();
						vm.adjDescChanged();
						vm.changeSystemacct();

					}
					vm.repointAmt = function() {
						vm.repointingAmount = 0;
						vm.acctzeroflag = false;
						angular.forEach(vm.repointList, function(repoint, key) {
							if (repoint.AMMOUNT != null
									&& repoint.AMMOUNT != "") {
								var x = parseInt(repoint.AMMOUNT, 10);
								vm.repointingAmount = vm.repointingAmount + x;
							}
							/*
							 * if(vm.repointingAmount > vm.cyAmount){
							 * vm.showRepointingError = true; }else {
							 * vm.showRepointingError = false; }
							 */

						});
						vm.remainingCyAmount = vm.cyAmount
								- vm.repointingAmount;

						for ( var d in vm.repointList) {

							vm.repointList[d].acctzeroflag = "";

							if (vm.repointList[d].AMMOUNT == 0
									|| vm.repointList[d].AMMOUNT == undefined) {
								vm.repointList[d].acctzeroflag = true;
								vm.acctzeroflag = true;
							}
						}

					}
					vm.save = function() {

						if ($scope.crudLoading) {
							AlertService.add("info",
									"Please wait while we save this request",
									4000);
							return;
						}

						vm.changeSystemacct();

						$scope.crudLoading = true;

						if (vm.Repoint.ADJUSTMENT_DESC == ''
								|| typeof vm.Repoint.ADJUSTMENT_DESC == 'undefined') {
							AlertService.add("danger",
									"Please correct the errors below", 4000);
							vm.showRepointingDescError = true;
							$scope.crudLoading = false;
							throw new ("This is not an error. This is just to abort javascript!");
						}

						vm.acctzeroflag = false;
						if (vm.sysacctFlag === true) {
							AlertService.add("danger",
									"Please correct the errors below", 4000);
							$scope.crudLoading = false;
							throw new ("This is not an error. This is just to abort javascript!");
						} else {
							angular
									.forEach(
											vm.repointList,
											function(repointObj, key) {
												angular
														.forEach(
																vm.sys_acc_dropdown_data,
																function(
																		sys_acc,
																		key) {
																	if (repointObj.SYSC_ACCT == sys_acc.SYS_DROPDOWN) {
																		repointObj.SYS_ACCT_KEY = sys_acc.SYS_ACCT_KEY;
																	}
																});
											});

							var finalRepointList = JSON.parse(JSON
									.stringify(vm.repointList));
							console.log(vm.adjDescChanged());

							for ( var d in vm.repointList) {

								vm.repointList[d].acctzeroflag = "";

								if (vm.repointList[d].AMMOUNT == 0
										|| vm.repointList[d].AMMOUNT == undefined) {
									vm.repointList[d].acctzeroflag = true;
									vm.acctzeroflag = true;
								}
							}

							if (!vm.showRepointingError
									&& !vm.showRepointingDescError
									&& !vm.acctzeroflag) {

								for (var i = 0; i < finalRepointList.length; i++) {
									var repointSpliced = false;
									if (sendobj.length > i) {
										sendobj[i].NEW_ADJUSTMENT_DESC = vm.Repoint.ADJUSTMENT_DESC;
										if (sendobj[i].TYPE == "E") {
											sendobj[i].NEW_SYSTEM_ACCT = finalRepointList[i].SYS_ACCT_KEY;
											sendobj[i].NEW_ADJ_AMT = finalRepointList[i].AMMOUNT;
											
											sendobj[i].TP_KEY = (finalRepointList[i].tpObject == undefined )  ? " ": finalRepointList[i].tpObject.TP_KEY;

											// The below IF condition makes sure
											// that there are changes in either
											// system account or adjustment
											// amount
											// or description.
											// If neither of those values are
											// changed, we are splicing them
											// from
											// SendObj ARRAY
											if (sendobj[i].OLD_SYSTEM_ACCT == sendobj[i].NEW_SYSTEM_ACCT
													&& sendobj[i].OLD_ADJ_AMT == sendobj[i].NEW_ADJ_AMT
													&& sendobj[i].NEW_ADJUSTMENT_DESC == sendobj[i].OLD_ADJUSTMENT_DESC) {
												sendobj.splice(i, 1);
												finalRepointList.splice(i, 1);
												i--;
											}
										}
									} else {
										vm
												.addToJson(finalRepointList[i],
														false);
									}
								}
								var finalSendObj = sendobj
										.concat(vm.tempDeletedRepointList);

								var message = "Data has been saved successfully";
								saveTrialBalanceData(returnObjD, finalSendObj,
										message);
							} else {
								$scope.crudLoading = false;
							}
						}
					}

					function saveTrialBalanceData(returnObjD, uniquesendobj,
							message) {
						TbFactory
								.saveTb(returnObjD, uniquesendobj,
										vm.rowData.SOURCE_SYSTEM)
								.then(
										function(result) {
											if (result.errorMessage
													&& result.errorMessage !== 'null') {
												AlertService.add("error",
														result.errorMessage,
														4000);
												$scope.crudLoading = false;

											} else {
												AlertService.add("success",
														message, 4000);
												$scope.crudLoading = false;
												vm.is_data_saved = true;
												$uibModalInstance
														.dismiss('cancel');
												var update_combination_key = rowData.COMBINATION_KEY
												var update_local_acct_key = rowData.LOCAL_ACCT
												var args = {
													combination_key : update_combination_key,
													local_acct_key : update_local_acct_key,

													gridFilter : {
														combination_key : update_combination_key,
														local_acct_key : update_local_acct_key,
													}
												};

												$scope
														.$emit('gridUpdate',
																args);
												// init();
											}
										});

					}

					function getSystmAcctDropDown() {
						var params = {
							"action_code" : (vm.rowData.SOURCE_SYSTEM == 'FIR') ? 'cftzzy'
									: 'qsbz14',
							"screen_key" : rowData.SCREEN_KEY,
							"acct_type" : rowData.SYSTEM_ACCT_TYPE,
							'source_system' : rowData.SOURCE_SYSTEM,
							"sys_acct_key" : rowData.SYSTEM_ACCT_KEY
						}
						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {
											if (data.errorMessage.length > 0
													&& data.errorMessage !== 'null') {
												AlertService.add("error",
														data.errorMessage);
												return false;
											} else {
												vm.sys_acc_dropdown_data = data.jsonObject;
												return data.jsonObject;
											}
										})
					}

					function fetchSystemAcctData() {
						var params = {
							"action_code" : (vm.rowData.SOURCE_SYSTEM == 'FIR') ? '4fmxwf'
									: '5hsimn',
							"tax_year" : rowData.TAX_YEAR,
							"local_acct" : rowData.LOCAL_ACCT_KEY,
							"sys_acct" : rowData.SYSTEM_ACCT_KEY
						}
						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {
											if (data.callSuccess === "1") {
												vm.loadSystemAndLocalAccountData = data.jsonObject;
											} else {
												if (data.errorMessage === "no access") {
													AlertService
															.add(
																	"",
																	"Sorry you do not have access to do the requested action.",
																	4000);
													vm.userMessage = "!! Unable to perform Selected Action...";
												} else {
													AlertService
															.add(
																	"",
																	"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																	4000);
													vm.userMessage = "!! Unable to perform Selected Action...";
												}
											}

										});
					}

					function getDefaultSystemAcctValues() {
						var params = {
							"action_code" : (vm.rowData.SOURCE_SYSTEM == 'FIR') ? 'uoezbr'
									: '4cpmap',
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"tax_year" : GlobalService.globalParams.tax_year,
							"source_sys" : vm.rowData.SOURCE_SYSTEM,
							"combination_key" : vm.rowData.COMBINATION_KEY,
							"local_acct" : vm.rowData.LOCAL_ACCT,
							"local_chart_key" : vm.rowData.LOCAL_CHART_KEY

						}
						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {
											if (data.errorMessage !== 'null'
													&& data.errorMessage.length > 0) {
												AlertService.add("error",
														data.errorMessage);
												return false;
											} else {
												vm.sys_acc_default_data = data.jsonObject;
												if (vm.sys_acc_default_data.length > 0) {
													vm.repointList = [];

													angular
															.forEach(
																	vm.sys_acc_default_data,
																	function(
																			sys_acc_data,
																			key) {
																		vm.Repoint.SYSC_ACCT = sys_acc_data.SYS_ACCT;
																		vm.Repoint.AMMOUNT = Number(sys_acc_data.AMOUNT);
																		vm.Repoint.ADJUSTMENT_DESC = sys_acc_data.ADJUSTMENT_DESC;
																		vm.Repoint.SYS_ACCT_KEY = sys_acc_data.SYS_ACCT_KEY;
																		vm.Repoint.TRANS_HEADER_KEY = sys_acc_data.TRANS_HEADER_KEY;
																		vm.Repoint.TRANS_DETAILS_KEY = sys_acc_data.TRANS_DETAILS_KEY;
																		vm.OLD_DESC = vm.Repoint.ADJUSTMENT_DESC;
																		vm.Repoint.TP_ENTITY=sys_acc_data.TP_ENTITY,

																		vm.repointList
																				.push({
																					SYSC_ACCT : vm.Repoint.SYSC_ACCT,
																					SYS_ACCT_KEY : vm.Repoint.SYS_ACCT_KEY,
																					TRANS_DETAILS_KEY : vm.Repoint.TRANS_DETAILS_KEY,
																					IRS_Form : vm.Repoint.IRS_Form,
																					IRS_SCHEDULE : vm.Repoint.IRS_SCHEDULE,
																					CHECK_SECTION : vm.Repoint.IRS_SCHEDULE,
																					CHECK_PART : vm.Repoint.CHECK_PART,
																					CHECK_COL : vm.Repoint.CHECK_COL,
																					CHECK_SYS_MAP_ACCT : vm.Repoint.CHECK_SYS_MAP_ACCT,
																					LOOK_UP_BTN : vm.repointList.LOOK_UP_BTN,
																					TP_ENTITY:vm.Repoint.TP_ENTITY,
																					AMMOUNT : vm.Repoint.AMMOUNT,
																					TYPE_VIEW :(vm.Repoint.TP_ENTITY == undefined || vm.Repoint.TP_ENTITY == '') ? "NEW"
																							:"EDIT"
																					
																				});

																		vm
																				.addToJson(
																						vm.Repoint,
																						true);
																	});
													vm.Repoint.SYSC_ACCT = '';
													vm.Repoint.AMMOUNT = '';
													vm.Repoint.SYS_ACCT_KEY = '';
													vm.Repoint.TRANS_HEADER_KEY = '';
													vm.Repoint.TP_ENTITY = '';// need

													vm.repointAmt();
												}
											}
										})
					}

					function getSystemAcctLookUP() {
						var params = {
							"action_code" : (vm.rowData.SOURCE_SYSTEM == 'FIR') ? 'zdfsb6'
									: 'aenjj8',
							"screen_key" : rowData.SCREEN_KEY,
							"acct_type" : rowData.SYSTEM_ACCT_TYPE
						}
						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {
											if (data.errorMessage.length > 0
													&& data.errorMessage !== 'null') {
												AlertService.add("error",
														data.errorMessage);
												return false;
											} else {
												vm.sys_acc_lookup_data = data.jsonObject;
												for ( var index_ in data.jsonObject) {
													vm.loadLookupForm
															.push(data.jsonObject[index_].FORM);
												}
												vm.loadLookupForm = _.uniq(
														vm.loadLookupForm,
														JSON.stringify);
												return data.jsonObject;
											}
										})

					}

					vm.getLookupNext = function(key, nextLookupKey, repointObj) {
						var lookupData = angular.copy(vm.sys_acc_lookup_data);
						var a = 'SYS_ACCT';
						switch (key) {
						/*
						 * case 1: repointObj.loadLookupSchedule =
						 * filterDataByKey("FORM", nextLookupKey, lookupData,
						 * repointObj.IRS_Form); break; case 2:
						 * repointObj.loadLookupLine =
						 * filterDataByKey("SCHEDULE", nextLookupKey,
						 * lookupData, repointObj.IRS_SCHEDULE); break; case 3:
						 * repointObj.loadLookupColumn = filterDataByKey("LINE",
						 * nextLookupKey, lookupData, repointObj.CHECK_LINE);
						 * break; case 4: repointObj.loadLookupSysMapAcct =
						 * filterDataByKey("COL", nextLookupKey, lookupData,
						 * repointObj.CHECK_COL);
						 * 
						 * break; case 5: repointObj.loadLookupSysMapAcct =
						 * filterDataByKey("LINE", nextLookupKey, lookupData,
						 * repointObj.CHECK_COL); break;
						 */

						case 1:
							repointObj.loadLookupSchedule = filterDataByKey(
									"FORM", nextLookupKey, lookupData,
									repointObj.IRS_Form);
							break;
						case 2:
							repointObj.loadLookupSection = filterDataByKey222(
									"SCHEDULE", nextLookupKey, lookupData222,
									repointObj.IRS_SCHEDULE);

							break;
						case 3:
							repointObj.loadLookupPart = filterDataByKey333(
									"FORM_SECTION_NAME", nextLookupKey,
									lookupData333, repointObj.CHECK_SECTION);

							break;
						case 4:
							repointObj.loadLookupLine = filterDataByKey1(
									"FORM_PART_NO", nextLookupKey, lookupData1,
									repointObj.CHECK_PART);
							break;
						case 5:
							repointObj.loadLookupColumn = filterDataByKey2(
									"LINE", nextLookupKey, lookupData2,
									repointObj.CHECK_LINE);
							repointObj.loadLookupSysMapAcct = filterDataByKey22(
									"LINE", a, lookupData2,
									repointObj.CHECK_LINE);
							break;

						case 6:
							repointObj.loadLookupSysMapAcct = filterDataByKey3(
									"COL", nextLookupKey, lookupData3,
									repointObj.CHECK_COL);
							break;
						case 7:
							repointObj.loadLookupSysMapAcct = filterDataByKey4(
									"LINE", nextLookupKey, lookupData2,
									repointObj.CHECK_LINE);
							break;

						}

					}
					/*
					 * function filterDataByKey(currentLookupKey, nextLookupKey,
					 * lookupData, currentLookupValue) { var nextLookupData =
					 * [];
					 * 
					 * var currentLookupFilter = lookupData.filter(function
					 * (object, index) { if (object[currentLookupKey] ==
					 * currentLookupValue) { return true; } });
					 * 
					 * currentLookupFilter.forEach(function (object, index) {
					 * nextLookupData.push(object[nextLookupKey]); });
					 * nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					 * lookupData = currentLookupFilter; return nextLookupData; }
					 */
					function filterDataByKey(currentLookupKey, nextLookupKey,
							lookupData, currentLookupValue) {
						var nextLookupData = [];

						var currentLookupFilter = lookupData
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[nextLookupKey]);
						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);
						lookupData222 = currentLookupFilter;

						return nextLookupData;

					}
					function filterDataByKey222(currentLookupKey,
							nextLookupKey, lookupData222, currentLookupValue) {
						var nextLookupData = [];

						var currentLookupFilter = lookupData222
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[nextLookupKey]);
						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);
						lookupData333 = currentLookupFilter;

						return nextLookupData;
					}
					function filterDataByKey333(currentLookupKey,
							nextLookupKey, lookupData333, currentLookupValue) {
						var nextLookupData = [];

						var currentLookupFilter = lookupData333
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[nextLookupKey]);
						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);
						lookupData1 = currentLookupFilter;

						return nextLookupData;
					}
					function filterDataByKey1(currentLookupKey, nextLookupKey,
							lookupData1, currentLookupValue) {
						var nextLookupData = [];
						var currentLookupFilter = lookupData1
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[nextLookupKey]);
						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);
						lookupData2 = currentLookupFilter;

						return nextLookupData;
					}

					function filterDataByKey2(currentLookupKey, nextLookupKey,
							lookupData2, currentLookupValue) {
						var nextLookupData = [];
						var nextLookupData1 = [];
						var currentLookupFilter = lookupData2
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[nextLookupKey]);

						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);
						lookupData3 = currentLookupFilter;

						return nextLookupData;
					}

					function filterDataByKey22(currentLookupKey, a,
							lookupData2, currentLookupValue) {
						var nextLookupData = [];

						var currentLookupFilter = lookupData2
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[a]);

						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);

						return nextLookupData;
					}

					function filterDataByKey3(currentLookupKey, nextLookupKey,
							lookupData3, currentLookupValue) {
						var nextLookupData = [];
						var currentLookupFilter = lookupData3
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[nextLookupKey]);
						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);
						lookupData4 = currentLookupFilter;
						return nextLookupData;
					}

					function filterDataByKey4(currentLookupKey, nextLookupKey,
							lookupData4, currentLookupValue) {
						var nextLookupData = [];
						var currentLookupFilter = lookupData4
								.filter(function(object, index) {
									console.log(object, index)
									if (object[currentLookupKey] == currentLookupValue) {
										return true;
									}
								});

						currentLookupFilter.forEach(function(object, index) {
							nextLookupData.push(object[nextLookupKey]);
						});
						nextLookupData = _.uniq(nextLookupData, JSON.stringify);
						return nextLookupData;
					}
					vm.formchanged = function(_item) {

						for ( var c in vm.repointList) {
							if (vm.repointList[c].$$hashKey == _item.$$hashKey) {
								vm.repointList[c].IRS_SCHEDULE = null;
								vm.repointList[c].CHECK_SECTION = null;
								vm.repointList[c].CHECK_PART = null;
								vm.repointList[c].CHECK_LINE = null;
								vm.repointList[c].CHECK_COL = null;
								vm.repointList[c].CHECK_SYS_MAP_ACCT = null;
								vm.repointList[c].loadLookupSection = [];
								vm.repointList[c].loadLookupPart = [];
								vm.repointList[c].loadLookupLine = [];
								vm.repointList[c].loadLookupColumn = [];
								vm.repointList[c].loadLookupSysMapAcct = [];
								if (vm.repointList[c].loadLookupSchedule.length == 1) {
									vm.repointList[c].IRS_SCHEDULE = vm.repointList[c].loadLookupSchedule[0];
									vm.getLookupNext(2, 'FORM_SECTION_NAME',
											vm.repointList[c]);
									vm.schedulechanged(vm.repointList[c]);
								}
								;

							}
						}

					};

					vm.schedulechanged = function(_item) {

						for ( var d in vm.repointList) {
							if (vm.repointList[d].$$hashKey == _item.$$hashKey) {
								vm.repointList[d].CHECK_SECTION = null;
								vm.repointList[d].CHECK_PART = null;
								vm.repointList[d].CHECK_LINE = null;
								vm.repointList[d].CHECK_COL = null;
								vm.repointList[d].CHECK_SYS_MAP_ACCT = null;
								vm.repointList[d].loadLookupPart = [];
								vm.repointList[d].loadLookupLine = [];
								vm.repointList[d].loadLookupColumn = [];
								vm.repointList[d].loadLookupSysMapAcct = [];
								if (vm.repointList[d].loadLookupSection.length == 1) {
									vm.repointList[d].CHECK_SECTION = vm.repointList[d].loadLookupSection[0];
									vm.getLookupNext(3, 'FORM_PART_NO',
											vm.repointList[d]);
									vm.sectionchanged(vm.repointList[d]);
								}
								;
							}
						}

					};

					vm.sectionchanged = function(_item) {

						for ( var e in vm.repointList) {
							if (vm.repointList[e].$$hashKey == _item.$$hashKey) {
								vm.repointList[e].CHECK_PART = null;
								vm.repointList[e].CHECK_LINE = null;
								vm.repointList[e].CHECK_COL = null;
								vm.repointList[e].CHECK_SYS_MAP_ACCT = null;
								vm.repointList[e].loadLookupLine = [];
								vm.repointList[e].loadLookupColumn = [];
								vm.repointList[e].loadLookupSysMapAcct = [];
								if (vm.repointList[e].loadLookupPart.length == 1) {
									vm.repointList[e].CHECK_PART = vm.repointList[e].loadLookupPart[0];
									vm.getLookupNext(4, 'LINE',
											vm.repointList[e]);
									vm.partchanged(vm.repointList[e]);
								}
								;
							}
						}

					}
					vm.partchanged = function(_item) {

						for ( var g in vm.repointList) {
							if (vm.repointList[g].$$hashKey == _item.$$hashKey) {
								vm.repointList[g].CHECK_LINE = null;
								vm.repointList[g].CHECK_COL = null;
								vm.repointList[g].CHECK_SYS_MAP_ACCT = null;

								vm.repointList[g].loadLookupColumn = [];
								vm.repointList[g].loadLookupSysMapAcct = [];
								if (vm.repointList[g].loadLookupLine.length == 1) {
									vm.repointList[g].CHECK_LINE = vm.repointList[g].loadLookupLine[0];
									vm.getLookupNext(5, 'COL',
											vm.repointList[g]);
									vm.linechanged(vm.repointList[g]);
								}
								;
							}
						}
					}

					vm.linechanged = function(_item) {

						for ( var h in vm.repointList) {
							if (vm.repointList[h].$$hashKey == _item.$$hashKey) {
								vm.repointList[h].CHECK_COL = null;
								vm.repointList[h].CHECK_SYS_MAP_ACCT = null;
								vm.repointList[h].loadLookupSysMapAcct = [];
								if (vm.repointList[h].loadLookupColumn.length == 1) {
									vm.repointList[h].CHECK_COL = vm.repointList[h].loadLookupColumn[0];
									vm.getLookupNext(6, 'SYS_ACCT',
											vm.repointList[h]);
									vm.columnchanged(vm.repointList[h]);
								}
								;
							}
						}
					};
					vm.columnchanged = function(_item) {

						for ( var i in vm.repointList) {
							if (vm.repointList[i].$$hashKey == _item.$$hashKey) {
								vm.repointList[i].CHECK_SYS_MAP_ACCT = null;

								if (vm.repointList[i].loadLookupSysMapAcct.length == 1) {
									vm.repointList[i].CHECK_SYS_MAP_ACCT = vm.repointList[i].loadLookupSysMapAcct[0];
									// vm.getLookupNext(6,'SYS_ACCT',vm.CHECK_COL);
								}
								;
							}
						}
					};

				}
				vm.sort_key = {};
				for (var i = 1; i < 7; i++) {
					vm.sort_key[i] = 0;
				}

				vm.getAuditSummary = function() {
					var params = {
						"action_code" : 'ssg8w0',
						"sso_id" : vm.logged_in_user,
						"local_acct_key" : rowData.LOCAL_ACCT_KEY,
						"cc_key" : rowData.COMBINATION_KEY

					};

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										vm.auditData = data.jsonObject;

										// Checking to see if any data was
										// returned
										if (vm.auditData && vm.auditData.length) {
											vm.columnNames = Object
													.keys(vm.auditData[0]);
											vm.noRecords = false;
										} else {
											vm.noRecords = true;
										}
									});
				}
			}

			function PDFPreviewCtrl(rowData, $uibModalInstance) {
				var vm = this;
				vm.leId = rowData.HO_LEID;
				vm.cdrNo = rowData.HO_CDR_NO;
				vm.rptPeriod = rowData.HO_REPORTING_PERIOD || rowData.HO_RPT_PD
						|| 1;
				vm.taxYear = rowData.TAX_YEAR;
				vm.leId = rowData.LEID;
				vm.formName = "5471";
				vm.uibModalInstance = $uibModalInstance;

			}
			return controllers;

		});
