define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.schedule-PCtrl', [])
    .controller('schedulePCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
		'GlobalService','SchedulePServiceFactory', 'JsonObjectFactory','GENERAL_CONFIG',  schedulePCtrl])
    	
	.controller('schedulePCtrlModal', ['CommonUtilitiesFactory', '$filter','$scope','$rootScope',  'ModalFactory', 'AlertService', '$timeout',
		'GlobalService','SchedulePServiceFactory', 'JsonObjectFactory','GENERAL_CONFIG', '$uibModalInstance',
		'rowData', 'colData', 'gridData',  schedulePCtrlModal]);

	function schedulePCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
			GlobalService,SchedulePServiceFactory, JsonObjectFactory, GENERAL_CONFIG) {
		
		schedulePCtrlModal(CommonUtilitiesFactory, $filter,$scope,$rootScope,ModalFactory, AlertService, $timeout,
			GlobalService,SchedulePServiceFactory, JsonObjectFactory, GENERAL_CONFIG, null,
			null, {}, {},this);
	}


	function schedulePCtrlModal(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
			GlobalService,SchedulePServiceFactory, JsonObjectFactory, GENERAL_CONFIG, $uibModalInstance, rowData,colData,gridData,thisObj) {
		
		var  vm = this||thisObj;

    			
    			vm.a = "";
    			vm.colData=colData;
    			if(angular.isObject($uibModalInstance)) {
					vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
					vm.cancel = function () {
						var args = {combination_keys: vm.rowData.HO_combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.HO_combination_key}};
						$rootScope.$emit('gridUpdate', args);
						$uibModalInstance.close();
						vm.modal_name = null;
					};
				} else {
					vm.modal_name = null;
				}

    			
    			 vm.onTabChange = function(e) {
					   vm.selectedTabName = e;
					   $timeout(function() {
						   var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
						   var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
						   if (movingTab && selectedTab) {
							   $(movingTab).width(selectedTab.offsetWidth);
							   $(movingTab).height(selectedTab.offsetHeight)
								   .css('line-height', selectedTab.offsetHeight + 'px')
								   .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
						   }
					   }, 50);
				   };
    			
                vm.loading = false;
                
                vm.modalTitle = "Schedule P";
                vm.rowData = rowData ||$scope.$parent.rowData;
                vm.colData=colData;
                vm.testing = 0;
                //vm.countries = CommonUtilitiesFactory.getCountriesFIR(); 
                
                function getCountriesFIR() { 
                	
						var params = {
								"tax_year": GlobalService.globalParams.tax_year,				                
				                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
				                
						};
						
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=kbuwei", params)
					.then(function(data) {
						if (data.callSuccess === "1") {
							vm.countries = data.jsonObject;
						}
                });
                }
                getCountriesFIR();
                
                function getStates() { 
                	
					var params = {
							"tax_year": GlobalService.globalParams.tax_year,				                
			                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
			       
					};
					
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=6eyqnz", params)
				.then(function(data) {
					if (data.callSuccess === "1") {
						vm.states = data.jsonObject;
					}
				});
                	}
                getStates();
            
                
            
                vm.states = GlobalService.getStates();
                
                vm.editFlag = false;
                vm.tabC_flag = false;
                vm.tabD_flag = false;
                vm.tabE_flag = false;
                vm.checked= false;
                
               
                
                $rootScope.activeScheduleTab = 0;
                vm.selectedTabCData = {};
                vm.selectedTabDData = {};
                vm.selectedTabEData = {};
                vm.selectedTabFData = {};
                
                vm.selectedTabCDataCloned = {};
                vm.selectedTabDDataCloned = {};
                vm.selectedTabEDataCloned = {};
                vm.selectedTabFDataCloned = {};
                               
                vm.acquistionArray = [];
                vm.acquistionObj = [];
                vm.stockAcquisitionAttrib = [];
                vm.isStockAquistionDataPresent = false;
                
                vm.dispositionArray = [];
                vm.dispositionObj = [];
                vm.stockDispositionAttrib = [];
                vm.isStockDispositionDataPresent = false;
                
                vm.proportionalInterestArray=[];
                vm.proportionalInterestObj = [];
                vm.stockProportionalAttrib = [];                
                vm.isProportionalInterestPresent = false;
                vm.desc_of_change = false;
                
                
                vm.supplementalArray = [];
                vm.supplementalObj = [];
                vm.stocksupplementalAttrib = [];
                vm.isSupplementalInformationPresent = false;
                
                vm.date_flag_required = false;
                vm.date_flag_range = false;
                
                
                $scope.crudLoading = false;
                
               // vm.aquisitionBeforePattern = "/^(100|[0-9][0-9]?)+(\.\d{1,6})?$/";
                
				console.log("RRRRRRRRRRRRRRRRRRRR ----------", vm);

                vm.formattedDate = "";

                vm.calendar = {
                        opened: false
                    };
                
                vm.schedulenavmap =[
                	{
                        key: "8865",
                        value : "8865",
                        index  : 0
                       
                      },
                    {
                        key: "C",
                        value : "add_new_acquisition",
                        index  : 1
                       
                      }, {
                    	  key: "D",
                          value : "add_new_disposition",
                          index : 2
                      },
                      {
                    	  key: "E",
                          value : "add_new_change_proportional_interest",
                          index : 3
                      },
					  {
                    	  key: "F",
                          value : "add_new_supplemental_information",
                          index : 4
                      }
                    ];
                
		            
						 ///////////////////////////////////////////
		                
		                function loadDetails() { 
		                	vm.testing = 1;
								var params = {
                                    "combination_key":vm.rowData.HO_COMBINATION_KEY,
                                    "irs_form_no":'F8865'/*,
                                    "jcd_key" : 250*/
								};
								console.log("rowdata", vm.rowData);                   
                            console.log("VM for the schedule P :::::: ",vm);
                            console.log("Combination key ::::::: ",vm.rowData.HO_COMBINATION_KEY);
							return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=726swn", params).then(function(data) {
												console.log("Response Data x ::::::::", data.jsonObject);
												if (data.callSuccess === "1") {
													vm.isShow = 1;
													vm.schedulePDetails = data.jsonObject;
                                                    //console.log("length", vm.schedulePDetails.length);
													vm.schedulePDetailsTemp = data.jsonObject;
													vm.arraySize = vm.schedulePDetailsTemp;
													console.log("check this out ::::::: ", vm.schedulePDetailsTemp);

													for(var i=1; i <data.jsonObject.length; i++) {
														var tempString = vm.schedulePDetails[i].object_id;
														var tempValue = vm.schedulePDetails[i].attrib_value;
														

													vm.schedulePDetails[i] = {
														i : vm.schedulePDetails[i],
                                                        "tax_year": tempString.split('~')[0],
														"trans_type_key":tempString.split('~')[1],
														"attrib_name":tempValue.split('~')[0],
                                                        "attrib_desc":tempValue.split('~')[3],
														"line_attrib_key":tempValue.split('~')[1],
														"trans_details_key":tempString.split('~')[3],
														"occurrence":tempString.split('~')[4],														
														"VALUE":vm.schedulePDetails[i].VALUE,
														"form_key":tempString.split('~')[6],
														"line_no" :vm.schedulePDetails[i].line_no
													};

												}
													setAttribute();
													
												} else {
                                                    if (data.errorMessage === "no access") {
                                                        AlertService.add("","Sorry you do not have access to do the requested action.",
                                                            4000);
                                                        vm.userMessage = "!! Unable to perform Selected Action...";
                                                    } else {
                                                        AlertService.add(
                                                            "",
                                                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                                                            4000);
                                                        vm.userMessage = "!! Unable to perform Selected Action...";
                                                    }
                                                }
													

												for(var i = 1; i<vm.schedulePDetails.length; i++) {
													var currentObj = vm.schedulePDetails[i];
													var lineNo;
													if(vm.schedulePDetails[i].attrib_name === "LINE_NO") {
														lineNo = currentObj["attrib_desc"];
														continue;
													}
													else {
														vm.schedulePDetails[i]["LINE_NO"] = lineNo;
													}
												}
												vm.acquistionObj = [];
												
												vm.acquistionArray =  _.filter(vm.schedulePDetails, function(o) { 
															return (o.line_no  == '1' )
														});
												//vm.schedulePDetails.filter(entry => entry["LINE_NO"] === '1'); 
												
												if(vm.acquistionArray.length > 0) {

													_.uniq(_.map(vm.acquistionArray,'occurrence')).forEach(function(p_occurrence){
													    var occList = _.filter(vm.acquistionArray,{occurrence:p_occurrence});
													    if(p_occurrence=='0'){
													    	return;
													    }
													    var temp={};
													    occList.forEach(function(currentObj){
													    	if(!currentObj['trans_details_key'] || currentObj['trans_details_key'] == '0'){
													    		return;
													    	}
													    	temp["LINE_NO"] = 1;
														    temp["occurrence"] = p_occurrence;
													        temp["trans_details_key"] = currentObj['trans_details_key'];
													        var value = null;
													        if(currentObj['attrib_name'] === "IDENTIFYING_NO") {
													            if(angular.isDefined(currentObj['VALUE'])) {
													                if(currentObj['VALUE'] == 'APPLD FOR' || currentObj['VALUE'] == 'FOREIGNUS') {
													                    temp['VALUE']  = currentObj['VALUE'];
													                    temp['DISVALUE']  = null;
													                }
													                else {
													                	temp['DISVALUE']  =  vm.changeIdentifyingTexttoDashed(currentObj['VALUE']);
													                    temp['VALUE']  = temp['DISVALUE'];
													                    temp['DISVALUE'] = temp['DISVALUE'].replace('-', '');
													                }
													            } else {
													                temp['VALUE']  = temp['DISVALUE']  = null;
													            }
													            temp[currentObj['attrib_name']] = value = currentObj['VALUE'];
													        } else {
													            temp[currentObj['attrib_name']] = currentObj['VALUE'];
													        }
													    });
													    if(temp.LINE_NO){
													    	vm.acquistionObj.push(temp);
													    }
													});
												}
												
												vm.isStockAquistionDataPresent = vm.acquistionObj.length > 0;
												vm.stockAquistionCollapsed = true;
												
												vm.dispositionObj = [];
												vm.dispositionArray =  _.filter(vm.schedulePDetails, function(o) { 
															return (o.line_no  == '2' )
														});
														//vm.schedulePDetails.filter(entry => entry["LINE_NO"] === '2'); 
												
												if(vm.dispositionArray.length > 0 ) {
													// && vm.dispositionArray[0]['occurrence'] !=null && vm.dispositionArray[0]['occurrence'] !=0 
													// && vm.dispositionArray[0]['trans_details_key'] != null && vm.dispositionArray[0]['trans_details_key'] != 0
													_.uniq(_.map(vm.dispositionArray,'occurrence')).forEach(function(p_occurrence){
														if(p_occurrence=='0'){
													    	return;
													    }
													    var occList = _.filter(vm.dispositionArray,{occurrence:p_occurrence});
													    var temp={};
													    occList.forEach(function(currentObj){
													    	if(!currentObj['trans_details_key'] || currentObj['trans_details_key'] == '0'){
													    		return;
													    	}
													    	temp["LINE_NO"] = 2;
														    temp["occurrence"] = p_occurrence;
													        temp["trans_details_key"] = currentObj['trans_details_key'];
													        var value = null;
													        if(currentObj['attrib_name'] === "IDENTIFYING_NO") {
													            if(angular.isDefined(currentObj['VALUE'])) {
													                if(currentObj['VALUE'] == 'APPLD FOR' || currentObj['VALUE'] == 'FOREIGNUS') {
													                    temp['VALUE']  = currentObj['VALUE'];
													                    temp['DISVALUE']  = null;
													                }
													                else {
													                    temp['DISVALUE']  =  vm.changeIdentifyingTexttoDashed(currentObj['VALUE']);
													                    temp['VALUE']  = temp['DISVALUE'];
													                    temp['DISVALUE'] = temp['DISVALUE'].replace('-', '');
													                }
													            } else {
													                temp['VALUE']  = temp['DISVALUE']  = null;
													               
													            }
													            temp[currentObj['attrib_name']] = value = currentObj['VALUE'];
													        } else {
													            temp[currentObj['attrib_name']] = currentObj['VALUE'];
													        }
													    });
													    vm.dispositionObj.push(temp);
													});
												}
																							
												vm.isStockDispositionDataPresent = vm.dispositionObj.length > 0;
												vm.stockDispositionCollapsed = true;
												
												vm.proportionalInterestObj = [];
												vm.proportionalInterestArray =  _.filter(vm.schedulePDetails, function(o) { 
															return (o.line_no  == '3' )
														});
														//vm.schedulePDetails.filter(entry => entry["LINE_NO"] === '3'); 
												
												if(vm.proportionalInterestArray.length > 0)									
														 {													
													_.uniq(_.map(vm.proportionalInterestArray,'occurrence')).forEach(function(p_occurrence){
														if(p_occurrence=='0'){
													    	return;
													    }
													    var occList = _.filter(vm.proportionalInterestArray,{occurrence:p_occurrence});
													    var temp={};
													    occList.forEach(function(currentObj){
													    	if(!currentObj['trans_details_key'] || currentObj['trans_details_key'] == '0'){
													    		return;
													    	}
													    	temp["LINE_NO"] = 3;
														    temp["occurrence"] = p_occurrence;
													        temp["trans_details_key"] = currentObj['trans_details_key'];
															temp[currentObj['attrib_name']] = currentObj['VALUE'];
													    });
													    vm.proportionalInterestObj.push(temp);
													});
													
												}
												
												vm.isProportionalInterestPresent = vm.proportionalInterestObj.length > 0;
												vm.ProportionalInterestPresentCollapsed = true;
												
												vm.supplementalObj = [];
												vm.supplementalArray =  _.filter(vm.schedulePDetails, function(o) { 
															return (o.line_no  == '4' )
														});
														//vm.schedulePDetails.filter(entry => entry["LINE_NO"] === '4'); 
												
												if(vm.supplementalArray.length > 0)
												{			
													_.uniq(_.map(vm.supplementalArray,'occurrence')).forEach(function(p_occurrence){
														if(p_occurrence=='0'){
													    	return;
													    }
													    var occList = _.filter(vm.supplementalArray,{occurrence:p_occurrence});
													    var temp={};
													    occList.forEach(function(currentObj){
													    	if(!currentObj['trans_details_key'] || currentObj['trans_details_key'] == '0'){
													    		return;
													    	}
													    	temp["LINE_NO"] = 4;
														    temp["occurrence"] = p_occurrence;
													        temp["trans_details_key"] = currentObj['trans_details_key'];
															temp[currentObj['attrib_name']] = currentObj['VALUE'];
													    });
													    vm.supplementalObj.push(temp);
													});
												}
												//vm.isSupplementalInformationPresent = vm.supplementalObj.length > 0;
												
												//vm.isSupplementalInformationPresent = angular.isDefined(vm.selectedTabFData.SUPPLEMENTAL_INFORMATION) && vm.selectedTabFData.SUPPLEMENTAL_INFORMATION != '';
												
												vm.isSupplementalInformationPresent = vm.schedulePDetails.filter(entry => entry["LINE_NO"] === '4' && entry.VALUE).length>0;
												vm.SupplementalInformationPresentcollapsed = true;
												
												console.log("Schedule p details +++++++++++++++++++",vm.schedulePDetails);
												console.log("Tab C data", vm.selectedTabCData); // Tab C is Part I (Add new Acquisition)
												console.log("Tab D data", vm.selectedTabDData); // Tab D is Part II Add new Disposition
												console.log("Tab E data", vm.selectedTabEData); // Tab E is Part III Add Change in Proportional Interest
												console.log("Tab F data", vm.selectedTabFData); // Tab F is Part IV Add new Supplemental information
											
												
												
												
												});

						}
		                
//		               
		                //////////Reset button functionality////////////
		                vm.reset = function(tab,$root) {
 		                	console.log(tab);
 		                    switch(tab) {
 		                        case 1: 
 		                        	if(vm.editFlag) {
 		                        		vm.selectedTabCData = {};
 		                        		vm.selectedTabCData = angular.copy(vm.selectedTabCDataCloned);
 		                        	}
 		                        	else {
 		                        		vm.selectedTabCData = {};
 										vm.selectedTabCData.NAME_OF_PERSON_OR_BUSINESS = "B";
 										vm.selectedTabCData.PERCENT_OF_INTEREST_BEFORE_ACQUISITION = null;
 										vm.selectedTabCData.PERCENT_OF_INTEREST_AFTER_ACQUISITION = null;
 		                        	}
 		                        break;
 		                        
 		                        case 2: 
 		                        	if(vm.editFlag) {
 		                        		vm.selectedTabDData = {};
 		                        		vm.selectedTabDData = angular.copy(vm.selectedTabDDataCloned);
 		                        	}
 		                        	else {
 		                        		vm.selectedTabDData = {};
 		                        		vm.selectedTabDData.NAME_OF_PERSON_OR_BUSINESS = "B";
 		                        		vm.selectedTabDData.PERCENT_OF_INTEREST_BEFORE_DISPOSITION = null;
 										vm.selectedTabDData.PERCENT_OF_INTEREST_AFTER_DISPOSITION = null;
 		                        	}
 		                        break;
 		                        
 		                        case 3: 
 		                        	if(vm.editFlag) {
 		                        		vm.selectedTabEData = {};
 		                        		vm.selectedTabEData = angular.copy(vm.selectedTabEDataCloned);
 		                        	}
 		                        	else {
 		                        		vm.selectedTabEData = {};
 		                        		vm.selectedTabEData.PERCENT_OF_INTEREST_BEFORE_CHANGE = null;
 										vm.selectedTabEData.PERCENT_OF_INTEREST_AFTER_CHANGE = null;
 		                        	}
 		                        break;
 		                        
 		                        case 4:
 		                        	if(vm.editFlag) {
 		                        		vm.selectedTabFData = {};
 		                        		vm.selectedTabFData = angular.copy(vm.selectedTabFDataCloned);
 		                        	}
 		                        	else {
 		                        		vm.selectedTabFData = {};
 		                        	}
 		                        break;
 		                    }
 		                }
		                
		               
		                loadDetails();
		               
		                ///////////////Getting the attribute names from the JSON Data for all the tabs////////////
		                function setAttribute()
		                {
		                	var params = {
		                			"tax_year":GlobalService.globalParams.tax_year,		                			
		                			"scenario":GlobalService.globalParams.scenario,
		                			"jcd_key":250,
		                			"irs_form_no":'F8865'
		                	};
		                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=p2kz2b",params).then(function(res) {		                		
		                		
		                		console.log("Response Data y ::", res.jsonObject);
		                		if (res.callSuccess === "1") {
		                			console.log("Response data : " , res.jsonObject);
		                			
		                			vm.stockAcquisitionAttrib = JSON.parse(res.jsonObject[0].LINE1_ATTRIB);
		                			vm.stockDispositionAttrib = JSON.parse(res.jsonObject[0].LINE2_ATTRIB);
		                			vm.stockProportionalAttrib = JSON.parse(res.jsonObject[0].LINE3_ATTRIB);
		                			vm.stocksupplementalAttrib = JSON.parse(res.jsonObject[0].LINE4_ATTRIB);
		                			
		                		}
		                		else {
									
										AlertService.add(
														"error",
														"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
														4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									}
								
		                		
		                	});
		                	
		                }
		                setAttribute();
		                
		                vm.radioClicked = function(tab, index)
		                {
		                	if(tab == 'C')
		                	{
		                		if(vm.selectedTabCData.VALUE == 'APPLD FOR' || vm.selectedTabCData.VALUE == 'FOREIGNUS' )
	                			{
	                				vm.selectedTabCData.DISVALUE = null;
	                				vm.selectedTabCData.IDENTIFYING_NO = vm.selectedTabCData.VALUE;
	                			}
	                			else
	                			{
	                				vm.selectedTabCData.VALUE = vm.selectedTabCData.DISVALUE;
	                				vm.selectedTabCData.IDENTIFYING_NO = vm.selectedTabCData.DISVALUE;
	                			}
		                			
		                	}
		                	else if(tab == 'D')
		                	{
		                		if(vm.selectedTabDData.VALUE == 'APPLD FOR' || vm.selectedTabDData.VALUE == 'FOREIGNUS' )
	                			{
	                				vm.selectedTabDData.DISVALUE = null;
	                				vm.selectedTabDData.IDENTIFYING_NO = vm.selectedTabDData.VALUE;
	                			}
	                			else
	                			{
	                				vm.selectedTabDData.VALUE = vm.selectedTabDData.DISVALUE;
	                				vm.selectedTabDData.IDENTIFYING_NO = vm.selectedTabDData.DISVALUE;
	                			}
		                			
		                	}
		                }
		                
	
		                vm.uncheckedRadio = function(tab, index)
		                {
		                	console.log("inchange---------")
		                	if(tab == 'C') {
//		                		if(angular.isDefined(vm.selectedTabCData.VALUE) && angular.isDefined(vm.selectedTabCData.DISVALUE))
//		                		{
//		                			if(vm.selectedTabCData.DISVALUE != null)
//		                			{
//		                				vm.selectedTabCData.IDENTIFYING_NO = vm.selectedTabCData.VALUE = vm.selectedTabCData.DISVALUE;
//		                				//vm.selectedTabCData.IDENTIFYING_NO = vm.changeIdentifyingTexttoDashed(vm.selectedTabCData.DISVALUE);
//		                			}
//		                		}
		                		if(vm.selectedTabCData.DISVALUE != null) {
		                			vm.selectedTabCData.IDENTIFYING_NO = vm.selectedTabCData.VALUE = vm.selectedTabCData.DISVALUE;
		                		} else {
		                			vm.selectedTabCData.IDENTIFYING_NO = '';
		                		}
		                	}
		                	else if(tab == 'D') {
//		                		if(angular.isDefined(vm.selectedTabDData.VALUE) && angular.isDefined(vm.selectedTabDData.DISVALUE))
//		                		{
//		                			if(vm.selectedTabDData.DISVALUE != null)
//		                			{
//		                				vm.selectedTabDData.IDENTIFYING_NO = vm.selectedTabDData.VALUE = vm.selectedTabDData.DISVALUE;
//		                				//vm.selectedTabDData.IDENTIFYING_NO = vm.changeIdentifyingTexttoDashed(vm.selectedTabDData.DISVALUE);
//		                			}
//		                		}
		                		if(vm.selectedTabDData.DISVALUE != null) {
		                			vm.selectedTabDData.IDENTIFYING_NO = vm.selectedTabDData.VALUE = vm.selectedTabDData.DISVALUE;
		                		} else {
		                			vm.selectedTabDData.IDENTIFYING_NO = '';
		                		}
		                	}
		                }
		                
		                vm.changeIdentifyingTexttoDashed = function(value) {
		                	if(value && value.length > 2 && value.indexOf('-')===-1) {
		                		return value.substr(0, 2) + '-' + value.substr(2, value.length); 
		                	}
		                	return value;
		                }
		                
//		                vm.removeIdentifyingTexttoDashed = function(value) {
//		                	if(value && value.length > 2) {
//		                		return value.substr(0, 2) + value.substr(2, value.length); 
//		                	}
//								
//		                	return value;
//		                }
		               
		                vm.openCalendar = function() {
	                 		vm.calendar.opened = true;  
	                 	};
	                 
	                 	vm.formatDate = function(tab) { 
	                 		
	                 		var dateInputData = "";
	                 		var start_date = new Date(vm.rowData.TAX_YEAR_BEGIN);
	                    	var end_date = new Date(vm.rowData.TAX_YEAR_END);
	                    	
	                 		if(tab == 1) {
	                 			dateInputData = vm.selectedTabCData.DATE_OF_ACQUISITION;
	                 			vm.date_flag_acquisition = dateInputData == null || dateInputData == "";
		                 		
		                    	if( (dateInputData < start_date) || (dateInputData > end_date ) )
		                    		{
		                    			vm.date_range_acquisition = true;
		                    		}
		                    	else
		                    	{
		                    		vm.date_range_acquisition = false;
		                    		vm.date_flag_acquisition = false;
		                    	}
	                 		}
	                 		else if(tab == 2) {
	                 			dateInputData = vm.selectedTabDData.DATE_OF_DISPOSITION;
	                 			vm.date_flag_disposition = dateInputData == null || dateInputData == "";
		                 		
		                    	if( (dateInputData < start_date) || (dateInputData > end_date ) )
		                    		{
		                    			vm.date_range_disposition = true;
		                    		}
		                    	else
		                    	{
		                    		vm.date_flag_disposition = false;
		                    		vm.date_range_disposition = false;
		                    	}
	                 		}
	                 		else if(tab == 3) {
	                 			dateInputData = vm.selectedTabEData.DATE_OF_CHANGE;
	                 			vm.date_flag_change = dateInputData == null || dateInputData == "";
		                 		
		                    	if( (dateInputData < start_date) || (dateInputData > end_date ) )
		                    		{
		                    			vm.date_range_change = true;
		                    		}
		                    	else
		                    	{
		                    		vm.date_flag_change = false;
		                    		vm.date_range_change = false;
		                    	}
	                 		}
	                    };
	                    
	                    
	                    
	                 	
	                 	/////////////////////edit functionality//////////////////
		                vm.editSchedule = function(item) {
		                	vm.editFlag = true;
		                	vm.loading = true;
		                  	var data = {};
		                   
		                    angular.forEach(vm.schedulenavmap, function(schedulenavitem){
		                    
		                    	 if(schedulenavitem.index.toString() === item.LINE_NO.toString())
		                        	{
		                        		$rootScope.activeScheduleTab = schedulenavitem.index;
		                        		
		                        		//console.log("items before-----", item);
			                        	switch(item.LINE_NO.toString()) {
				                            case "1":
				                            	vm.selectedTabCData = item;
				                            	
				                            	//console.log($rootScope.activeScheduleTab);
				                        		if(item.DATE_OF_ACQUISITION != null)
				                        		{
				                        			var dataSet = new Date(item.DATE_OF_ACQUISITION);
				                        			item.DATE_OF_ACQUISITION = new Date(dataSet.getFullYear(), dataSet.getMonth(), dataSet.getDate());
				                        		}
				                        		else
				                        			item.DATE_OF_ACQUISITION = "";
				                        						                            	
				                            	vm.selectedTabCData.NAME_OF_PERSON_OR_BUSINESS = item.NAME_OF_PERSON_OR_BUSINESS;
				                            	
				                            	if(vm.selectedTabCData.NAME_OF_PERSON_OR_BUSINESS == "P" && angular.isDefined(item.NAME_LINE_2))
				                            		vm.selectedTabCData.NAME_LINE_2 = "";
				                            	
				                            	if((angular.isDefined(item.COUNTRY)))
				                            		vm.selectedTabCData.COUNTRYCODE = vm.getCountryInfo(item.COUNTRY);
				                            	
				                            	if((angular.isDefined(item.ADDRESS_LINE_1)))
				                            		vm.selectedTabCData.ADDRESS_LINE_1 = item.ADDRESS_LINE_1;
				                            	
				                            	if((angular.isDefined(item.ADDRESS_LINE_2)))
				                            		vm.selectedTabCData.ADDRESS_LINE_2 = item.ADDRESS_LINE_2;
				                            	
				                            	if((angular.isDefined(item.CITY)))
				                            		vm.selectedTabCData.CITY = item.CITY;
				                            	
				                            	if((angular.isDefined(item.STATE)))
				                            		vm.selectedTabCData.STATEOBJ = vm.getStateInfo(item.STATE);				                            	
				                            	
				                            	if((angular.isDefined(item.ZIP_OR_POSTAL_CODE)))
				                            		vm.selectedTabCData.ZIP_OR_POSTAL_CODE = item.ZIP_OR_POSTAL_CODE;
				                            	
				                            	if((angular.isDefined(item.IDENTIFYING_NO)))
				                            		vm.selectedTabCData.IDENTIFYING_NO = item.IDENTIFYING_NO;
				                            	
				                            	if((angular.isDefined(item.FMV_OF_INTEREST_ACQUIRED)))
				                            		vm.selectedTabCData.FMV_OF_INTEREST_ACQUIRED = item.FMV_OF_INTEREST_ACQUIRED;
				                            	
				                            	if((angular.isDefined(item.BASIS_IN_INTEREST_ACQUIRED)))
				                            		vm.selectedTabCData.BASIS_IN_INTEREST_ACQUIRED = item.BASIS_IN_INTEREST_ACQUIRED;
				                            	
				                            	if((angular.isDefined(item.PERCENT_OF_INTEREST_BEFORE_ACQUISITION)))
				                            		vm.selectedTabCData.PERCENT_OF_INTEREST_BEFORE_ACQUISITION = item.PERCENT_OF_INTEREST_BEFORE_ACQUISITION;
				                            	
				                            	if((angular.isDefined(item.PERCENT_OF_INTEREST_AFTER_ACQUISITION)))
				                            		vm.selectedTabCData.PERCENT_OF_INTEREST_AFTER_ACQUISITION = item.PERCENT_OF_INTEREST_AFTER_ACQUISITION;
				                            	
				                            	vm.selectedTabCDataCloned = angular.copy(vm.selectedTabCData);
				                            	
				                            	
				                            break;
				                            ;
				                            case "2":
				                            	vm.selectedTabDData = item;
				                            	
				                            	
				                            	//console.log($rootScope.activeScheduleTab);
				                        		if(item.DATE_OF_DISPOSITION != null)
				                        		{
				                        			var dataSet = new Date(item.DATE_OF_DISPOSITION);
				                        			item.DATE_OF_DISPOSITION = new Date(dataSet.getFullYear(), dataSet.getMonth(), dataSet.getDate());
				                        		}
				                        		else
				                        			item.DATE_OF_DISPOSITION = "";
				                        						                            	
				                            	vm.selectedTabDData.NAME_OF_PERSON_OR_BUSINESS = item.NAME_OF_PERSON_OR_BUSINESS;
				                            	
				                            	if(vm.selectedTabDData.NAME_OF_PERSON_OR_BUSINESS == "P" && angular.isDefined(item.NAME_LINE_2))
				                            		vm.selectedTabDData.NAME_LINE_2 = "";
				                            	
				                            	if((angular.isDefined(item.COUNTRY)))
				                            		vm.selectedTabDData.COUNTRYCODE = vm.getCountryInfo(item.COUNTRY);
				                            	
				                            	if((angular.isDefined(item.ADDRESS_LINE_1)))
				                            		vm.selectedTabDData.ADDRESS_LINE_1 = item.ADDRESS_LINE_1;
				                            	
				                            	if((angular.isDefined(item.ADDRESS_LINE_2)))
				                            		vm.selectedTabDData.ADDRESS_LINE_2 = item.ADDRESS_LINE_2;
				                            	
				                            	if((angular.isDefined(item.CITY)))
				                            		vm.selectedTabDData.CITY = item.CITY;
				                            	
				                            	if((angular.isDefined(item.STATE)))
				                            		vm.selectedTabDData.STATEOBJ = vm.getStateInfo(item.STATE);	                            	
				                            	
				                            	
				                            	if((angular.isDefined(item.ZIP_OR_POSTAL_CODE)))
				                            		vm.selectedTabDData.ZIP_OR_POSTAL_CODE = item.ZIP_OR_POSTAL_CODE;
				                            	
				                            	if((angular.isDefined(item.IDENTIFYING_NO)))
				                            		vm.selectedTabDData.IDENTIFYING_NO = item.IDENTIFYING_NO;
				                            	
				                            	if((angular.isDefined(item.FMV_OF_INTEREST_DISPOSED)))
				                            		vm.selectedTabDData.FMV_OF_INTEREST_DISPOSED = item.FMV_OF_INTEREST_DISPOSED;
				                            	
				                            	if((angular.isDefined(item.BASIS_IN_INTEREST_DISPOSED)))
				                            		vm.selectedTabDData.BASIS_IN_INTEREST_DISPOSED = item.BASIS_IN_INTEREST_DISPOSED;
				                            	
				                            	if((angular.isDefined(item.PERCENT_OF_INTEREST_BEFORE_DISPOSITION)))
				                            		vm.selectedTabDData.PERCENT_OF_INTEREST_BEFORE_DISPOSITION = item.PERCENT_OF_INTEREST_BEFORE_DISPOSITION;
				                            	
				                            	if((angular.isDefined(item.PERCENT_OF_INTEREST_AFTER_DISPOSITION)))
				                            		vm.selectedTabDData.PERCENT_OF_INTEREST_AFTER_DISPOSITION = item.PERCENT_OF_INTEREST_AFTER_DISPOSITION;
				                            	
				                            	vm.selectedTabDDataCloned = angular.copy(vm.selectedTabDData);
				                            	
				                            break;
				                            ;
				                            case "3": 
				                            	vm.selectedTabEData = item;
				                            	vm.selectedTaECDataCloned = angular.copy(item);
				                            	
				                            	//console.log($rootScope.activeScheduleTab);
				                        		if(item.DATE_OF_CHANGE != null)
				                        		{
				                        			var dataSet = new Date(item.DATE_OF_CHANGE);
				                        			item.DATE_OF_CHANGE = new Date(dataSet.getFullYear(), dataSet.getMonth(), dataSet.getDate());
				                        		}
				                        		else
				                        			item.DATE_OF_CHANGE = "";				                        						                            	
				                            	
				                        		if((angular.isDefined(item.DESCRIPTION_OF_CHANGE)))
				                            		vm.selectedTabEData.DESCRIPTION_OF_CHANGE = item.DESCRIPTION_OF_CHANGE;
				                            	
				                            	if((angular.isDefined(item.FMV_OF_INTEREST)))
				                            		vm.selectedTabEData.FMV_OF_INTEREST = item.FMV_OF_INTEREST;
				                            	
				                            	if((angular.isDefined(item.BASIS_IN_INTEREST)))
				                            		vm.selectedTabEData.BASIS_IN_INTEREST = item.BASIS_IN_INTEREST;
				                            	
				                            	if((angular.isDefined(item.PERCENT_OF_INTEREST_BEFORE_CHANGE)))
				                            		vm.selectedTabEData.PERCENT_OF_INTEREST_BEFORE_CHANGE = item.PERCENT_OF_INTEREST_BEFORE_CHANGE;
				                            	
				                            	if((angular.isDefined(item.PERCENT_OF_INTEREST_AFTER_CHANGE)))
				                            		vm.selectedTabEData.PERCENT_OF_INTEREST_AFTER_CHANGE = item.PERCENT_OF_INTEREST_AFTER_CHANGE;
				                            	
				                            	vm.selectedTabEDataCloned = angular.copy(vm.selectedTabEData);
				                            	
				                            break;
				                            ;
				                            
				                            case "4": 
				                            	vm.selectedTabFData = item;
				                            	vm.selectedTabFDataCloned = angular.copy(item);
				                            	
				                            	
				                        		if((angular.isDefined(item.SUPPLEMENTAL_INFORMATION)))
				                            		vm.selectedTabFData.SUPPLEMENTAL_INFORMATION = item.SUPPLEMENTAL_INFORMATION;
				                            	
				                        		vm.selectedTabFDataCloned = angular.copy(vm.selectedTabFData);
				                            	
				                            break;
				                            ;
			                        	}
		                        		return;
		                        	}
		                    });
		               };

		               	///////////////////////////////////Delete button functionality///////////////////////////////////////// 
		                
		               vm.deleteSchedule = function(item)
			            {
			            	//console.log("in delete function & items:-------------------", item); 
			            	var message = "Schedule has been deleted";
//			            	var json_arrOBJ = [{
//			            		trans_details_key:item['trans_details_key'], 
//			            		occurence : item['occurrence'],
//			            		
//								
//							}];
			            	var json_arrOBJ=[];
			            	vm.schedulePDetails.filter(function(e){ return  e.occurrence == item.occurrence && e.i.line_no==item.LINE_NO; })
			            		.forEach(function(e){
			            							json_arrOBJ.push([{
			            								occurence:e.occurrence,
			            								"tax_year":GlobalService.globalParams.tax_year,
			            								"trans_type_key":e.trans_type_key,
			            								"trans_dtls_key":e.trans_details_key,
			            								"trans_status":"A",
			            								"line_no":item.LINE_NO
			            								}]);
			            });
			            	
			            	
							//console.log(json_arrOBJ);
			            	var scheduleDetails = {"addrows":[], "editrows":[], "removerows":json_arrOBJ};
			            	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
		                	
			            	SchedulePServiceFactory.saveScheduleP(scheduleSettings,scheduleDetails).then(function(res) {
			                        if (res.errorMessage && res.errorMessage !== 'null') {
			                            AlertService.add("error", res.errorMessage, 4000);
			                        } else {
			                            loadDetails();
			                            AlertService.add("success", message, 4000);
			                        }

			                    });    
			            };
			            
			            
			            ///////////////
			            
			            
			           
		               
		  ///////////////Save function for tab "Add new Stock Acquisition"//////////
						vm.stock_acqisition = function($root, tab) {
							
							if(vm.date_range_acquisition || (!(angular.isDefined(vm.selectedTabCData.DATE_OF_ACQUISITION)) || vm.selectedTabCData.DATE_OF_ACQUISITION == ""))
							{
								vm.date_flag_acquisition= !(angular.isDefined(vm.selectedTabCData.DATE_OF_ACQUISITION)) || vm.selectedTabCData.DATE_OF_ACQUISITION == "";
								//vm.date_flag_acquisition= true;
								 $scope.crudLoading = false;
								AlertService.add("error", "Please fill out required fields.", 3000);
							}
							
							else {
								//$scope.schedulePForm.$submitted= false;
								vm.date_flag_acquisition= false;
								vm.date_range_acquisition= false;
								
								var editRow = [];
			                	var addRow = [];
			                	var deleteRow = [];
			                	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
			                	
			                	if(angular.isDefined(vm.selectedTabCData.DATE_OF_ACQUISITION) ) {
			                		vm.selectedTabCData.DATE_OF_ACQUISITION = $filter('date')(vm.selectedTabCData.DATE_OF_ACQUISITION, "MM/dd/yyyy");
			                	}
			                	
			                	var trans_type_key = vm.acquistionArray.length > 0 ? vm.acquistionArray[0].trans_type_key : null;
			                	var currentOccurence = vm.selectedTabCData.occurrence;
			                	// var currentTransDetailsKey = vm.selectedTabCData.trans_details_key;
			                	if(vm.selectedTabCData.IDENTIFYING_NO){
			                		vm.selectedTabCData.IDENTIFYING_NO=vm.selectedTabCData.IDENTIFYING_NO.replace('-','');
			                	}
			                	var mping = {
			                			'8865_P_STOCK_ACQUISITION_F_ADDR_1':'ADDRESS_LINE_1',
			                			'8865_P_STOCK_ACQUISITION_F_ADDR_2':'ADDRESS_LINE_2',
			                			'8865_P_STOCK_ACQUISITION_F_CITY':'CITY',
			                			'8865_P_STOCK_ACQUISITION_F_STATE':'STATE',
			                			'8865_P_STOCK_ACQUISITION_F_ZIP':'ZIP_OR_POSTAL_CODE',
			                			'ZIP_OR_POSTAL_CODE':'8865_P_STOCK_ACQUISITION_F_ZIP',
			                			'STATE':'8865_P_STOCK_ACQUISITION_F_STATE',
			                			'CITY':'8865_P_STOCK_ACQUISITION_F_CITY',
			                			'ADDRESS_LINE_2':'8865_P_STOCK_ACQUISITION_F_ADDR_2',
			                			'ADDRESS_LINE_1':'8865_P_STOCK_ACQUISITION_F_ADDR_1',
			                	};
			                	for(var i = 0; i < vm.stockAcquisitionAttrib.length; i++) {
			                		// Non-US skipping this properties.
			                		if(vm.selectedTabCData.COUNTRYCODE && vm.selectedTabCData.COUNTRYCODE.COUNTRY_CODE !== 'US' &&
			                				['ADDRESS_LINE_1','ADDRESS_LINE_2','CITY','STATE','ZIP_OR_POSTAL_CODE']
			                		.indexOf(vm.stockAcquisitionAttrib[i].attrib_name)>-1 ) {
			                			continue;
			                		} else if(vm.selectedTabCData.COUNTRYCODE && vm.selectedTabCData.COUNTRYCODE.COUNTRY_CODE === 'US' &&
			                				vm.stockAcquisitionAttrib[i].attrib_name.indexOf('8865_P_STOCK_ACQUISITION_F')>-1){
			                			continue;
			                		} /*else if(!vm.selectedTabCData.COUNTRYCODE && (vm.stockAcquisitionAttrib[i].attrib_name.indexOf('8865_P_STOCK_ACQUISITION_F')>-1 || 
			                				['ADDRESS_LINE_1','ADDRESS_LINE_2','CITY','STATE','ZIP_OR_POSTAL_CODE']
			                		.indexOf(vm.stockAcquisitionAttrib[i].attrib_name)>-1)) {
			                			continue;
			                		}*/
			                		var currentTransDetailsKey = vm.editFlag?vm.schedulePDetails.find(function(e){
			                			return e.line_attrib_key== vm.stockAcquisitionAttrib[i].line_attrib_key && 
			                			e.occurrence == currentOccurence;
			                		}):null;
			                		if (!currentTransDetailsKey && vm.editFlag) {
			                			var tline_attrib_key = vm.stockAcquisitionAttrib.find(function(stkAcq){
			                				return stkAcq.attrib_name=== mping[vm.stockAcquisitionAttrib[i].attrib_name]
			                			});
			                			if(tline_attrib_key){
			                				currentTransDetailsKey = vm.schedulePDetails.find(function(e){
					                			return e.line_attrib_key== tline_attrib_key.line_attrib_key && 
					                			e.occurrence == currentOccurence;
					                		});
			                			}
			                		}
			                		var tempObj =[{
										"tax_year":GlobalService.globalParams.tax_year,
										"trans_type_key":trans_type_key,
										"combination_key":vm.rowData.HO_COMBINATION_KEY,
										"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
										"form_key":vm.acquistionArray[0].form_key,
										"line_no":tab,
										"occurence":vm.editFlag ? currentOccurence: null,
										"line_attrib_key" : vm.stockAcquisitionAttrib[i].line_attrib_key,
										"Attribute_Name": vm.stockAcquisitionAttrib[i].attrib_name,
										"Attribute_Value": vm.selectedTabCData[vm.stockAcquisitionAttrib[i].attrib_name],
										"trans_dtls_key" : vm.editFlag ? (currentTransDetailsKey?currentTransDetailsKey.trans_details_key:null): null,
										"trans_status":"A"}];
			                		
			                		editRow.push(tempObj);
			                		
			                	}
			                	var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
			                	console.log("scheduleDetails-----------------------", scheduleDetails);
			            		var message = "New Stock Acquisition is saved";
			            		
			            		SchedulePServiceFactory.saveScheduleP(scheduleSettings,
										scheduleDetails).then(
										function(result) {

											if (result.errorMessage
													&& result.errorMessage !== 'null') {
												AlertService.add("error",
														result.errorMessage, 4000);
												 $scope.crudLoading = false;
											} else {
												AlertService.add("success", message, 4000);	
												$scope.crudLoading = false;
												vm.selectedTabCData.DATE_OF_ACQUISITION = new Date(vm.selectedTabCData.DATE_OF_ACQUISITION);
												if(!vm.editFlag)
				                            	{
				                            	 //console.log("loaDetails function called");
				                            	 loadDetails();
				                            	 }
											}
										
										});
							}
	                 } 
						
						
						vm.getCountryInfo = function(countryCode)
		                {
		                	var tempCountry = {};
		                	for(var i = 0; i<vm.countries.length; i++)
		                	{
		                		if(vm.countries[i].COUNTRY_CODE ==  countryCode)
		                		{
		                			tempCountry = vm.countries[i];
		                			break;
		                		}
		                	}
		                	return tempCountry;
		                }
		                
		                vm.getStateInfo = function(stateCode)
		                {
		                	var tempState = {};
		                	for(var i = 0; i<vm.states.length; i++)
		                	{
		                		if(vm.states[i].CODE ==  stateCode)
		                		{
		                			tempState = vm.states[i];
		                			break;
		                		}
		                	}
		                	return tempState;
		                }
		                
		                vm.changeCountry = function(country_code, tab)
		                {
		                	if(country_code != 'US')
		                	{
		                		if(tab == "c" ) {
		                			//vm.selectedTabCData.STATE = '';
		                			vm.selectedTabCData.COUNTRY = vm.selectedTabCData.COUNTRYCODE !== null ? vm.selectedTabCData.COUNTRYCODE.COUNTRY_CODE : "";
		                		}
		                		else if(tab == "d"){
		                			//vm.selectedTabDData.STATE = '';
		                			vm.selectedTabDData.COUNTRY = vm.selectedTabDData.COUNTRYCODE !== null ? vm.selectedTabDData.COUNTRYCODE.COUNTRY_CODE : "";
		                		}
		                		else
		                			vm.selectedTabEData.STATE = '';
		                			
		                	}
		                }
		                
		                		                
		                
		                vm.changeState = function(stateObj, tab)
		                {
		                	if(tab == "c" ) {
	                			vm.selectedTabCData.STATE = vm.selectedTabCData.STATEOBJ.CODE;
	                		}
	                		else if(tab == "d"){
	                			vm.selectedTabDData.STATE = vm.selectedTabDData.STATEOBJ.CODE;
	                		}
	                		else
	                			
		                		vm.selectedTabEData.STATE = '';
		                }
		                
		                vm.changeRadioButton = function(value, tab) {
		                	if(tab == "c" ) {
		                		vm.selectedTabCData.NAME_LINE_1 = vm.selectedTabCData.NAME_LINE_2 = "";
	                		}
	                		else if(tab == "d"){
	                			vm.selectedTabDData.NAME_LINE_1 = vm.selectedTabDData.NAME_LINE_2 = "";
	                		}
		                	
		                }
		                
		              
		                vm.formatNumberForDecimal = function(value, tab) 
		                {
		                	if(tab == "c" ) {
		                	vm.selectedTabCData.FMV_OF_INTEREST_ACQUIRED = parseInt(Number(value));
		                }
		                	else if(tab == "d"){
		                		vm.selectedTabDData.FMV_OF_INTEREST_DISPOSED = parseInt(Number(value));
		                		
                		}
		                	else if(tab == "e"){		                		
		                		vm.selectedTabEData.FMV_OF_INTEREST = parseInt(Number(value));
		                	}
	                	
	                }
		                
		                /////////////
		                vm.formatNumber = function(value, tab) {
		                	if(tab == "c" ) {
		                		vm.selectedTabCData.BASIS_IN_INTEREST_ACQUIRED = parseInt(Number(value));
			                }
			                	else if(tab == "d"){
			                		
	                			vm.selectedTabDData.BASIS_IN_INTEREST_DISPOSED = parseInt(Number(value));
	                		}
			                	else if(tab == "e"){		                		
			                		vm.selectedTabEData.BASIS_IN_INTEREST = parseInt(Number(value));
			                	}
		                }
		                
		                
		                vm.formatIdentifyingTextUI = function(val, tab) {
							 switch(tab) {
		                        case 'C': 
		                        	if(!('APPLD FOR'.includes(val) || 'FOREIGNUS'.includes(val))) {		                        	
										 if (val && val.length > 2) {
											 vm.selectedTabCData.IDENTIFYING_NO =  val.substr(0, 2) + '-' + val.substr(2, val.length);
										 } else {
											 vm.selectedTabCData.IDENTIFYING_NO =  val;
										 } 
									 }
									 else {
										 vm.selectedTabCData.IDENTIFYING_NO =  val;
									 }
		                        	 break;
		                        	
		                        case 'D': 
		                        	if(!('APPLD FOR'.includes(val) || 'FOREIGNUS'.includes(val))) {		
										 if (val && val.length > 2) {
											 vm.selectedTabDData.IDENTIFYING_NO =  val.substr(0, 2) + '-' + val.substr(2, val.length);
										 } else {
											 vm.selectedTabDData.IDENTIFYING_NO =  val;
										 } 
									 }
									 else {
										 vm.selectedTabDData.IDENTIFYING_NO =  val;
									 }
		                        	 break;
							 }
						 };
		                
		                //////////////function to clear tab data
		                vm.clearTabData = function(selectedTabIndex)
		                {
		                	vm.editFlag = false;
		                	vm.date_flag_acquisition = false;
		                	vm.date_range_acquisition = false;
		                	vm.date_flag_disposition = false;
		                	vm.date_range_disposition = false;
		                	vm.date_flag_change = false;
		                	vm.date_range_change= false;
		                	vm.desc_of_change = false;
		                	
		                	
		                	if(selectedTabIndex == 0)
		                	{
		                		 loadDetails();
		                		 
		                	}
		                	
		                	if(selectedTabIndex == 1)
		                	{
		                		vm.selectedTabCData = {};		                		
		                		vm.tabC_flag = false;
								vm.selectedTabCData.NAME_OF_PERSON_OR_BUSINESS = "B";
								vm.selectedTabCData.PERCENT_OF_INTEREST_BEFORE_ACQUISITION = null;
								vm.selectedTabCData.PERCENT_OF_INTEREST_AFTER_ACQUISITION = null;
		                	}
		                	
		                	if(selectedTabIndex == 2)
		                	{
		                		vm.selectedTabDData = {};		                		
		                        vm.tabD_flag = false;
		                        vm.selectedTabDData.NAME_OF_PERSON_OR_BUSINESS = "B";
		                        vm.selectedTabDData.PERCENT_OF_INTEREST_BEFORE_DISPOSITION = null;
		                        vm.selectedTabDData.PERCENT_OF_INTEREST_AFTER_DISPOSITION = null;
		                	}
		                	
		                	if(selectedTabIndex == 3)
		                	{
		                		vm.selectedTabEData = {};		                		
		                		vm.tabE_flag = false;
		                		vm.selectedTabEData.PERCENT_OF_INTEREST_BEFORE_CHANGE = null;
								vm.selectedTabEData.PERCENT_OF_INTEREST_AFTER_CHANGE = null;
		                		
		                	}

		                	if(selectedTabIndex == 4)
		                	{
		                		vm.selectedTabFData = {};		                		
		                		vm.tabF_flag = false;
		                		
		                	}
		                	
		                }
		                	
		                	
		                	////// Function to save Add new Disposition//////
		                	
		                	vm.stock_disposition = function($root, tab) {
		                			if(vm.date_range_disposition || (!(angular.isDefined(vm.selectedTabDData.DATE_OF_DISPOSITION)) || vm.selectedTabDData.DATE_OF_DISPOSITION == ""))
								{
									vm.date_flag_disposition= !(angular.isDefined(vm.selectedTabDData.DATE_OF_DISPOSITION)) || vm.selectedTabDData.DATE_OF_DISPOSITION == "";
									//vm.date_flag_acquisition= true;
									AlertService.add("error", "Please fill out required fields.", 3000);
									 $scope.crudLoading = false;
								}
								else {
									//$scope.schedulePForm.$submitted= false;
									vm.date_flag_disposition = false;
									vm.date_range_disposition = false;
									
											
									var editRow = [];
				                	var addRow = [];
				                	var deleteRow = [];
				                	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
				                	
				                	if(angular.isDefined(vm.selectedTabDData.DATE_OF_DISPOSITION) ) {
				                		vm.selectedTabDData.DATE_OF_DISPOSITION = $filter('date')(vm.selectedTabDData.DATE_OF_DISPOSITION, "MM/dd/yyyy");
				                	}
				                	
				                	var trans_type_key = vm.dispositionArray.length > 0 ? vm.dispositionArray[0].trans_type_key : null;
				                	var currentOccurence = vm.selectedTabDData.occurrence;
				                	/*var currentTransDetailsKey = vm.selectedTabDData.trans_details_key;
				                	
				                	for(var i = 0; i < vm.stockDispositionAttrib.length; i++)
				                	{
				                		var currentTransDetailsKey = vm.editFlag?vm.schedulePDetails.find(function(e){
				                			return e.line_attrib_key== vm.stockDispositionAttrib[i].line_attrib_key && 
				                			e.occurrence == currentOccurence;
				                		}).trans_details_key:null;
				                		*/
				                	if(vm.selectedTabDData.IDENTIFYING_NO){
				                		vm.selectedTabDData.IDENTIFYING_NO=vm.selectedTabDData.IDENTIFYING_NO.replace('-','');
				                	}
				                	var mping = {
				                			'8865_P_STOCK_DISPOSITION_F_ADDR_1':'ADDRESS_LINE_1',
				                			'8865_P_STOCK_DISPOSITION_F_ADDR_2':'ADDRESS_LINE_2',
				                			'8865_P_STOCK_DISPOSITION_F_CITY':'CITY',
				                			'8865_P_STOCK_DISPOSITION_F_STATE':'STATE',
				                			'8865_P_STOCK_DISPOSITION_F_ZIP':'ZIP_OR_POSTAL_CODE',
				                			'ZIP_OR_POSTAL_CODE':'8865_P_STOCK_DISPOSITION_F_ZIP',
				                			'STATE':'8865_P_STOCK_DISPOSITION_F_STATE',
				                			'CITY':'8865_P_STOCK_DISPOSITION_F_CITY',
				                			'ADDRESS_LINE_2':'8865_P_STOCK_DISPOSITION_F_ADDR_2',
				                			'ADDRESS_LINE_1':'8865_P_STOCK_DISPOSITION_F_ADDR_1',
				                	};
				                	for(var i = 0; i < vm.stockDispositionAttrib.length; i++) {
				                		// Non-US skipping this properties.
				                		if(vm.selectedTabDData.COUNTRYCODE && vm.selectedTabDData.COUNTRYCODE.COUNTRY_CODE !== 'US' &&
				                				['ADDRESS_LINE_1','ADDRESS_LINE_2','CITY','STATE','ZIP_OR_POSTAL_CODE']
				                		.indexOf(vm.stockDispositionAttrib[i].attrib_name)>-1 ) {
				                			continue;
				                		} else if(vm.selectedTabDData.COUNTRYCODE && vm.selectedTabDData.COUNTRYCODE.COUNTRY_CODE === 'US' &&
				                				vm.stockDispositionAttrib[i].attrib_name.indexOf('8865_P_STOCK_DISPOSITION_F')>-1){
				                			continue;
				                		} /*else if(!vm.selectedTabDData.COUNTRYCODE && (vm.stockDispositionAttrib[i].attrib_name.indexOf('8865_P_STOCK_DISPOSITION_F')>-1 || 
				                				['ADDRESS_LINE_1','ADDRESS_LINE_2','CITY','STATE','ZIP_OR_POSTAL_CODE']
				                		.indexOf(vm.stockDispositionAttrib[i].attrib_name)>-1)) {
				                			continue;
				                		}*/
				                		var currentTransDetailsKey = vm.editFlag?vm.schedulePDetails.find(function(e){
				                			return e.line_attrib_key== vm.stockDispositionAttrib[i].line_attrib_key && 
				                			e.occurrence == currentOccurence;
				                		}):null;
				                		if (!currentTransDetailsKey && vm.editFlag) {
				                			var tline_attrib_key = vm.stockDispositionAttrib.find(function(stkDisp){
				                				return stkDisp.attrib_name=== mping[vm.stockDispositionAttrib[i].attrib_name]
				                			});
				                			if(tline_attrib_key){
				                				currentTransDetailsKey = vm.schedulePDetails.find(function(e){
						                			return e.line_attrib_key== tline_attrib_key.line_attrib_key && 
						                			e.occurrence == currentOccurence;
						                		});
				                			}
				                		}
				                		
				                		var tempObj =[{
											"tax_year":GlobalService.globalParams.tax_year,
											"trans_type_key":trans_type_key,
											"combination_key":vm.rowData.HO_COMBINATION_KEY,
											"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
											"form_key":vm.dispositionArray[0].form_key,
											"line_no":tab,
											"occurence":vm.editFlag ? currentOccurence: null,
											"line_attrib_key" : vm.stockDispositionAttrib[i].line_attrib_key,
											"Attribute_Name": vm.stockDispositionAttrib[i].attrib_name,
											"Attribute_Value":vm.selectedTabDData[vm.stockDispositionAttrib[i].attrib_name],
											"trans_dtls_key" : vm.editFlag ? (currentTransDetailsKey?currentTransDetailsKey.trans_details_key:null): null,
											"trans_status":"A"}];
				                		
				                		editRow.push(tempObj);
				                		
				                	}
				                	var scheduleDetailsDisposition = {"addrows":[], "editrows":editRow, "removerows":[]};
				                	console.log("scheduleDetails-----------------------", scheduleDetailsDisposition);
				            		var message = "New Stock Disposition is saved";
				            		
				            		SchedulePServiceFactory.saveStockDiposition(scheduleSettings,
				            				scheduleDetailsDisposition).then(
											function(result) {

												if (result.errorMessage
														&& result.errorMessage !== 'null') {
													AlertService.add("error",
															result.errorMessage, 4000);
													 $scope.crudLoading = false;
												} else {
													AlertService.add("success", message, 4000);
													 $scope.crudLoading = false;
													vm.selectedTabDData.DATE_OF_DISPOSITION= new Date(vm.selectedTabDData.DATE_OF_DISPOSITION);
													if(!vm.editFlag)
					                            	{
					                            	 //console.log("loaDetails function called");
					                            	 loadDetails();
					                            	 }
												}
											
											});
								}
		                 } 
							
		                	
		                	
		                	
		                	
		                	vm.changeDescription = function(value) {
		                		vm.desc_of_change  = !(angular.isDefined(value)) || value == "";
		                	}
		                	
		                	
                               ////// Function to save Add new Change in Proportional Interest//////
		                	
                    		vm.proportional_interest= function($root, tab) {
                    			vm.desc_of_change  = !(angular.isDefined(vm.selectedTabEData.DESCRIPTION_OF_CHANGE)) || vm.selectedTabEData.DESCRIPTION_OF_CHANGE == "";
                    			vm.date_flag_change= !(angular.isDefined(vm.selectedTabEData.DATE_OF_CHANGE)) || vm.selectedTabEData.DATE_OF_CHANGE == "";
                    				
								if(vm.desc_of_change || vm.date_range_change || vm.date_flag_change)
								{
									AlertService.add("error", "Please fill out required fields.", 3000);
									 $scope.crudLoading = false;
								}
								else {
									//$scope.schedulePForm.$submitted= false;
									vm.date_flag_change = false;
									vm.date_range_change = false;	
									vm.desc_of_change = false;
									
									
									var editRow = [];
				                	var addRow = [];
				                	var deleteRow = [];
				                	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
				                	
				                	if(angular.isDefined(vm.selectedTabEData.DATE_OF_CHANGE) ) {
				                		vm.selectedTabEData.DATE_OF_CHANGE = $filter('date')(vm.selectedTabEData.DATE_OF_CHANGE, "MM/dd/yyyy");
				                	}
				                	
				                	var trans_type_key = vm.proportionalInterestArray.length > 0 ? vm.proportionalInterestArray[0].trans_type_key : null;
				                	var currentOccurence = vm.selectedTabEData.occurrence;
				                	var currentTransDetailsKey = vm.selectedTabEData.trans_details_key;
				                	
				                	for(var i = 0; i < vm.stockProportionalAttrib.length; i++)
				                	{
				                		var currentTransDetailsKey = vm.editFlag?vm.schedulePDetails.find(function(e){
				                			return e.line_attrib_key== vm.stockProportionalAttrib[i].line_attrib_key && 
				                			e.occurrence == currentOccurence;
				                		}).trans_details_key:null;
				                		
				                		var tempObj =[{
											"tax_year":GlobalService.globalParams.tax_year,
											"trans_type_key":trans_type_key,
											"combination_key":vm.rowData.HO_COMBINATION_KEY,
											"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
											"form_key":vm.proportionalInterestArray[0].form_key,
											"line_no":tab,
											"occurence":vm.editFlag ? currentOccurence: null,
											"line_attrib_key" : vm.stockProportionalAttrib[i].line_attrib_key,
											"Attribute_Name": vm.stockProportionalAttrib[i].attrib_name,
											"Attribute_Value":vm.selectedTabEData[vm.stockProportionalAttrib[i].attrib_name],
											"trans_dtls_key" : vm.editFlag ? currentTransDetailsKey: null,
											"trans_status":"A"}];
				                		
				                		editRow.push(tempObj);
				                		
				                	}
				                	var scheduleDetailsProportionalInterest = {"addrows":[], "editrows":editRow, "removerows":[]};
				                	console.log("scheduleDetails-----------------------", scheduleDetailsProportionalInterest);
				            		var message = "Change in Proportional Interest is saved";
				            		
				            		SchedulePServiceFactory.saveProportionalInterest(scheduleSettings,
				            				scheduleDetailsProportionalInterest).then(
											function(result) {

												if (result.errorMessage
														&& result.errorMessage !== 'null') {
													AlertService.add("error",
															result.errorMessage, 4000);
													 $scope.crudLoading = false;
												} else {
													AlertService.add("success", message, 4000);
													 $scope.crudLoading = false;
													vm.selectedTabEData.DATE_OF_CHANGE= new Date(vm.selectedTabEData.DATE_OF_CHANGE);
													if(!vm.editFlag)
					                            	{
					                            	 //console.log("loaDetails function called");
					                            	 loadDetails();
					                            	 }
												}
											
											});
								}
		                 } 
							
		                	
                    		
                    		
                    		
                    		////Add new Supplemental Information
                    		
                    		vm.supplemental_information= function($root, tab) {	
                    				var editRow = [];
				                	var addRow = [];
				                	var deleteRow = [];
				                	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
				                	
				                
				                	var trans_type_key = vm.supplementalArray.length > 0 ? vm.supplementalArray[0].trans_type_key : null;
				                	var currentOccurence = (!vm.supplementalObj.length || !vm.supplementalObj[0].occurrence) ? null : vm.supplementalObj[0].occurrence;
				                	//var currentTransDetailsKey =  (!vm.supplementalObj.length || !vm.supplementalObj[0].trans_details_key)?null : vm.supplementalObj[0].trans_details_key;
				                	for(var i = 0; i < vm.stocksupplementalAttrib.length; i++) {
				                		
				                		var currentTransDetailsKey = vm.supplementalObj.length>0?vm.schedulePDetails.find(function(e){
				                			return e.line_attrib_key== vm.stocksupplementalAttrib[i].line_attrib_key && 
				                			e.occurrence == currentOccurence;
				                		}).trans_details_key:null;
				                		
				                		var tempObj =[{
											"tax_year":GlobalService.globalParams.tax_year,
											"trans_type_key":trans_type_key,
											"combination_key":vm.rowData.HO_COMBINATION_KEY,
											"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
											"form_key":vm.supplementalArray[0].form_key,
											"line_no":tab,
											"occurence":currentOccurence,
											"line_attrib_key" : vm.stocksupplementalAttrib[i].line_attrib_key,
											"Attribute_Name": vm.stocksupplementalAttrib[i].attrib_name,
											"Attribute_Value": vm.selectedTabFData[vm.stocksupplementalAttrib[i].attrib_name],
											"trans_dtls_key" : currentTransDetailsKey,
											"trans_status":"A"}];
				                		editRow.push(tempObj);
				                	}
				                	var scheduleSupplementalInformation = {"addrows":[], "editrows":editRow, "removerows":[]};
				                	console.log("scheduleDetails-----------------------", scheduleSupplementalInformation);
				            		var message = "New Supplemental information is saved";
				            		
				            		SchedulePServiceFactory.savesupplementalInformation(scheduleSettings,
				            				scheduleSupplementalInformation).then(
											function(result) {

												if (result.errorMessage
														&& result.errorMessage !== 'null') {
													AlertService.add("error",
															result.errorMessage, 4000);
													 $scope.crudLoading = false;
												} else {
													AlertService.add("success", message, 4000);		
													 $scope.crudLoading = false;
													if(!vm.editFlag)
					                            	{
					                            	 //console.log("loaDetails function called");
					                            	 //loadDetails();
					                            	 }
													 loadDetails();
												}
											
											});
								
		                 } 
                    		$scope.$on('workflowSave',function(event,data){
                                //console.log();
                                vm.saveData();
                           });
                            vm.saveData = function()
                            {
                                vm.save(null, null);
                            }
                    		 vm.save = function(tab,$root) {

     							var editRow = [];
     							var addRow = [];
     							var deleteRow = [];
     							var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":250}];
     							
     							if(tab == null)
     		                		tab = $rootScope.activeScheduleTab;


     							if ($scope.crudLoading) {
                	                AlertService.add("info", "Please wait while we save this request", 4000);
                		                return;
                		            }
                			  $scope.crudLoading = true;        		                  	
                			  var data = {};
     							console.log("tttttttttttt:::::::",$root);
     							console.log(tab);
     							vm.isSaveClicked = true;
     		                    switch(tab) {
     		                        case 0: vm.save1($root);
     		                        		
     		                                    break;
     		                        case 1: vm.tabC_flag = true;
     		                        		vm.tabD_flag = false;
     		                        		vm.tabE_flag = false;
     		                        		vm.stock_acqisition($root, tab);//console.log("from add_stock_acqisition"); 
     		                        		
     		                        break;
     		                        case 2: vm.tabC_flag = false;
     		                        		vm.tabD_flag = true;
     		                        		vm.tabE_flag = false;
     		                        		vm.stock_disposition($root,tab); 
     		                        		//console.log("from add_stock_disposition"); 
     		                        break;
     		                        case 3: vm.tabC_flag = false;
     		                        		vm.tabD_flag = false;
     		                        		vm.tabE_flag = true;
     		                        		vm.proportional_interest($root,tab);
     				                       //console.log("from add_proportional_interest");
     		                        break;
     		                        case 4: vm.tabC_flag = false;
     		                        		vm.tabD_flag = false;
     		                        		vm.tabE_flag = false;
     		                        		vm.tabF_flag = true;
     		                        		vm.supplemental_information($root,tab);
     		                       //console.log("from add_proportional_interest");
                             break;
     		                    }
     		                };
     		               
		                
            }
           return controllers;
            
     	
});

