/**
 * @author Vijay Ramagiri This is to get and save Creditable Tax Amount and Non-Creditable Tax Amount
 * 
 * 
 */
define(
		[ 'angular',
		    './BranchTaxService',
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.BranchTaxDefaultsCtrl', ['app.BranchTaxService'])
					.controller('branchTaxDefaultsCtrl',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
									'$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','workspaceFactory','BranchTaxFactory','$http', branchTaxDefaultsCtrl ])

			function branchTaxDefaultsCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, workspaceFactory, BranchTaxFactory,$http) {

				console.log(rowData,gridData,"asda");
				var vm = this;		
				vm.loading = true;
				vm.formData = {};
				vm.defaultsMultipleSelected = [];				
				vm.generatedLeidDropDown = [];
				vm.multipleGeneratedLeids = [];
				vm.basketCodeDefault = [];
				vm.basketCatgData;
				vm.countryData;	
				vm.results = [];
				//To get the Grid Filter Params 
				var filterParams = workspaceFactory.activeScreen.filters.getFilterParams();
				console.log(" filterParams : ", filterParams);
				//Get the Grid filter Tax Year 
				vm.formData.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.formData.scenario = filterParams.scenario; 
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');	    		
				};
				
				//To get the Lock_Y_N flag from backend			
				var lockparams = {"action_code": 'n10cpv',
						"tax_year": filterParams.tax_year, 
						"p_scenario" : filterParams.scenario,  //GlobalService.globalParams.scenario, 
						"p_jcd_key": GlobalService.globalParams.jcd_key, 
						"p_consol_group_key":filterParams.filing_key,   
						};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;		
					console.log("vm.is_locked :", vm.is_locked);
				});	
				
				//Get the Creditable Tax Amount Dropdown
				vm.getCreditableDropDown = function() {				
					var params = {"action_code" : 'e83e1x',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.basketCatgData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
	                	});
						vm.basketCodeDefault = _.filter(vm.basketCatgData, function(p){
	                		  return _.includes(['BRANCH'], p.CODE_SHORT_DESC);  
						});
						vm.countryData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
	                	});	
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});	
					});					
				};
				vm.getCreditableDropDown();
				
				//Get the CODE_KEY for the given COUNTRY CODE_DISP_DESC 
				function getCountryKey(countryDesc) {
					var selectedCountryKey;
					for(var indx in vm.countryData){						
						if(vm.countryData[indx].CODE_DISP_DESC.includes(countryDesc)){
							selectedCountryKey = ''+vm.countryData[indx].CODE_KEY;							
						}
					}
					return selectedCountryKey;
				};
				
				//Get the CODE_KEY for the given TAX_YEAR CODE_DISP_DESC 
				function getTaxYearKey(taxYearDesc) {
					var selectedTaxYearKey;
					for(var indx in vm.taxYearData){								
						if(vm.taxYearData[indx].CODE_DISP_DESC == taxYearDesc){
							selectedTaxYearKey = ''+vm.taxYearData[indx].CODE_KEY;							
						}
					}
					return selectedTaxYearKey;
				};
				
				//Get the Creditable Tax Amount Dropdown
				vm.getGeneratedLeidDropDown = function(selectedRowData) {					
					var params = {
									"action_code" : 'yxpce4',	
									"scenario" : vm.formData.scenario,
									"ho_leid" : selectedRowData.HO_LEID,
									"ho_cdr_no" : selectedRowData.HO_CDR_NO,
									"ho_reporting_pd" : selectedRowData.HO_REPORTING_PERIOD, 
								};					 
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.generatedLeidDropDown = data.jsonObject;	
					});						
				};	
				
				//Get the Generated LEIDs for a list of HO_LEID, HO_CDR and HO_Reporting Periods passed
				vm.getMultipleGeneratedLeid = function(multipleRowData) {					
					var params = {
									"action_code" : '19x2qd',
									"scenario" : vm.formData.scenario,
								};	
					var jsonObj = JSON.stringify(multipleRowData);
					params =  _.extend({clob_data:jsonObj}, params);					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.multipleGeneratedLeids = data.jsonObject;
					});						
				};	
				
				//Retreive the Attrib Mast data
				vm.getAttribMast = function() {
					var params = {"action_code" : 'e0vg4g', };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage,4000);
			                    return false;
			                }else{
			                	vm.attribMastData = data.jsonObject;
			                }							
					});					
				}								
				//Load the Data from FTC_CODE_MAST and Attrib Mast				
				vm.getAttribMast();
								
				//Save the Default Value to Persisting Tables
				vm.save = function() {	
					//To Block user from Adding Entities for Return filed year
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
						vm.cancel();										
						AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
						return;
					}	
					
					if(vm.defaultsMultipleSelected.length == 0 ){
						AlertService.add("error", "Please select atleast one Branch Tax Default Mapping",4000);
						return;
					}					
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['scenario'] = vm.formData.scenario; //GlobalService.globalParams.scenario;
					
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
					
					var defaultsChangeDetails = [];
					for(var key in vm.defaultsMultipleSelected){
						var returnObj = {};
						returnObj["TAG_KEY"] = '';
						returnObj['COMBINATION_KEY'] = vm.defaultsMultipleSelected[key].COMBINATION_KEY;
						returnObj["OPERATION_TYPE"] = 'S';
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = vm.defaultsMultipleSelected[key].BASKET_KEY;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'GEN_LEID']).ATTRIB_KEY +'_0'] = vm.defaultsMultipleSelected[key].LEID; 
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = getCountryKey(vm.defaultsMultipleSelected[key].LE_COUNTRY_NAME); 
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'APPLICABLE_YEAR']).ATTRIB_KEY +'_0'] = getTaxYearKey(vm.defaultsMultipleSelected[key].CURRENT_TAX_YEAR);
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'EXPLANATION_CREDITABLE_US_ENTITY']).ATTRIB_KEY +'_0'] = 'Not Applicable';
												
						returnObj["IS_CREDITABLE"] = 'Y';
						returnObj["AMOUNT"] =  (vm.defaultsMultipleSelected[key].CREDIT_AMT != null) ? vm.defaultsMultipleSelected[key].CREDIT_AMT : 0;						
						returnObj['GROUP_ID'] = 1;
						
						console.log("returnObj::FNL:: ",returnObj);
						
						var message = "Defaults have been applied and saved/updated";
						
						defaultsChangeDetails.push(returnObj);						
					}
					//Save the data 
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);				
				};
					
				//Send Details
				function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
					BranchTaxFactory.savePivotedData(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
								   //parent_me_string: filterParams.parent_me_string,
                                   //gridFilter: {
								   //   parent_me_string: filterParams.parent_me_string
                                   //}
                               };
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);							
                        };
					});
				}
				
				//Remove the Entry from Pop-up
				vm.removeDefaultsRow = function(name){					
					var index = vm.defaultsMultipleSelected.indexOf(name);
					vm.defaultsMultipleSelected[index].CREDIT_AMT = vm.defaultsMultipleSelected[index].ORIG_CREDIT_AMT;
					vm.defaultsMultipleSelected.splice(index, 1);					
				};	
							
				//For a selected row from Grid Data of Branch Tax  Screen - apply the default values
				if (gridData != null) {
					//console.log("::: ->  -> gridData:: ", gridData);
					//console.log("::: ->  ->  rowData:: ", rowData);
					var tempGridData = _.clone(gridData), groups = Object
						.create(null), result, finalKeyCheck = 0;					
					//console.log("tempGridData:: ", tempGridData);
				
					vm.gridDataMultipleSelect = _.clone(tempGridData);	
					
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							vm.loading = false;		
							return;
						}
					});
					
					
					if (vm.gridDataMultipleSelect != undefined) {										
						for ( var data in vm.gridDataMultipleSelect) {							
							if (vm.gridDataMultipleSelect[data].data != undefined) {
								vm.defaultsMultipleSelected.push(angular.copy(vm.gridDataMultipleSelect[data].data));	
																						
								vm.defaultsMultipleSelected[data]["progress"] = "Save_Defaults";
								vm.defaultsMultipleSelected[data]["OPERATION_TYPE"] = "S";
								if(vm.defaultsMultipleSelected[data]["ORIG_CREDIT_AMT"]!=undefined){
									vm.defaultsMultipleSelected[data].CREDIT_AMT = vm.defaultsMultipleSelected[data]["ORIG_CREDIT_AMT"];
								}
								
								//Set the Text Highlighting Color and Status_Action
								if(vm.defaultsMultipleSelected[data].IS_DEFAULT_TAG == 'Y'){
									vm.defaultsMultipleSelected[data]["BACKGROUND_COLOR"] = 'warning';
									vm.defaultsMultipleSelected[data]["STATUS_TEXT"] = 'Defaults Already Applied';									
								}else if(vm.defaultsMultipleSelected[data].IS_DEFAULT_TAG == 'N' 
									&& (vm.defaultsMultipleSelected[data].CREDIT_AMT == undefined || vm.defaultsMultipleSelected[data].CREDIT_AMT == null || vm.defaultsMultipleSelected[data].CREDIT_AMT == 0)
										&& (vm.defaultsMultipleSelected[data].NON_CREDIT_AMT == undefined || vm.defaultsMultipleSelected[data].NON_CREDIT_AMT == null || vm.defaultsMultipleSelected[data].NON_CREDIT_AMT == 0)){
									vm.defaultsMultipleSelected[data]["BACKGROUND_COLOR"] = '';
									vm.defaultsMultipleSelected[data]["STATUS_TEXT"] = '';									
								}else if(vm.defaultsMultipleSelected[data].IS_DEFAULT_TAG === 'N' 
									&& (vm.defaultsMultipleSelected[data].CREDIT_AMT != undefined  || vm.defaultsMultipleSelected[data].CREDIT_AMT != null|| vm.defaultsMultipleSelected[data].CREDIT_AMT != 0)
										|| (vm.defaultsMultipleSelected[data].NON_CREDIT_AMT != undefined || vm.defaultsMultipleSelected[data].NON_CREDIT_AMT != null || vm.defaultsMultipleSelected[data].NON_CREDIT_AMT != 0)){
									vm.defaultsMultipleSelected[data]["BACKGROUND_COLOR"] = 'danger';
									vm.defaultsMultipleSelected[data]["STATUS_TEXT"] = 'Override Existing Sourcing ?';																	
								}
								
								//Applying the Default Values
								vm.defaultsMultipleSelected[data]["ORIG_CREDIT_AMT"] = parseInt(''+vm.defaultsMultipleSelected[data].CREDIT_AMT).toLocaleString(undefined, {maximumFractionDigits:0});
								vm.defaultsMultipleSelected[data].TB_AMT = vm.defaultsMultipleSelected[data].TB_AMT.toLocaleString(undefined, {maximumFractionDigits:0}).replace(/\,/g,'');
								vm.defaultsMultipleSelected[data].CREDIT_AMT = vm.defaultsMultipleSelected[data].TB_AMT;
								vm.defaultsMultipleSelected[data]["BASKET"] = '';
								vm.defaultsMultipleSelected[data]["BASKET_KEY"] = '';
								//Use this current tax_year to save also
								vm.defaultsMultipleSelected[data]["CURRENT_TAX_YEAR"] = vm.formData.filterTaxYear; //GlobalService.globalParams.tax_year;								
							}							
						}						
					}					
					vm.loading = false;					
				}
				
				//Set the Basket Description and Basket Key while applying the defaults
				$scope.$watch(function() { return vm.basketCodeDefault }, function() {
					if(vm.basketCodeDefault != undefined && vm.basketCodeDefault.length > 0){
						for ( var t in vm.defaultsMultipleSelected) {
							vm.defaultsMultipleSelected[t]["BASKET"] = vm.basketCodeDefault[0].CODE_DISP_DESC;
							vm.defaultsMultipleSelected[t]["BASKET_KEY"] = vm.basketCodeDefault[0].CODE_KEY;							
						}
					}
				});	
			       
			}
			return controllers;

		});
