define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartI',[])
        .factory("K3PartIAttachmentFactory",['$http', '$window','$filter','GENERAL_CONFIG','AlertService','PartnerAllocServicesNewFactory',K3PartIAttachmentFactory])
        .factory("K3PartIFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','K3PartIAttachmentFactory','workspaceFactory', '$filter',K3PartIFactory])

    function K3PartIAttachmentFactory($http,$window,$filter,GENERAL_CONFIG,AlertService,PartnerAllocServicesNewFactory){

        var fileUploadConfigI={
            attachmentTO_I : {
                FILE_KEY: '',
                FILE_NAME: '',
                FILE_PATH: '',
                FILE_TYPE: '',
                FILE_SIZE: '',
                CONTENT_TYPE: '',
                ATTACHMENT_KEY: '',
                isUpdateClicked:false,
                comment_attachment_change_success: false,
                saveClicked: false,logoFilesMarkedForDeletion: [],
                datasource_connection_msg: '',datasource_connection_status: '',flow: {},
                attachment_file: '',
                attachmented_files:[],
                stored_files:[],
                partNo: 'I'
            },failCount : 0
            ,getClientLogoFileName : function (vm) {

                if (angular.isUndefined(vm.attachmentTO_I.FILE_NAME) ||
                    (vm.attachmentTO_I.FILE_NAME == null) ||
                    (vm.attachmentTO_I.FILE_NAME == '')
                ) {
                    return '';
                }
                return vm.attachmentTO_I.FILE_NAME.substr(vm.attachmentTO_I.FILE_NAME.lastIndexOf('/') + 1);

            },removeAttachment_I : function (vm,fileName,index) {
                console.log('removeAttachment p1');
                console.log('vm.pIAttachmentList ---- ' , vm.pIAttachmentList);

                let file_key = $filter("filter")(vm.pIAttachmentList,{file_name:fileName.split(".")[0], file_type:fileName.split(".")[1]})[0].file_key;
                console.log('file_key = ' + file_key);

                var objContext={
                    destDir:"temp",
                    flowFilename: fileName,
                    fileKey:file_key
                };
                let promise = $http({method: "post", url: vm.remove_url, data: objContext
                }).then(function (response) {

                        if(response.data.p_retval == "1" && response.data.p_error_out == null) {

                            vm.attachmentTO_I.attachment_file = '';
                            vm.attachmentTO_I.comment_attachment_change_success = true;
                            vm.attachmentTO_I.attachment_file = '';
                            try{
                                vm.attachmentTO_I.flow.removeFile(_.find(vm.attachmentTO_I.flow.files,{name:fileName}));
                            }catch(e){}
                            vm.attachmentTO_I.attachmented_files = _.without(vm.attachmentTO_I.attachmented_files, fileName);
                            if(vm.attachmentTO_I.stored_files && vm.attachmentTO_I.stored_files.length)
                                vm.attachmentTO_I.stored_files=_.cloneDeep(vm.attachmentTO_I.attachmented_files);
                        }
                        else if(response.data.p_retval == "0" && response.data.p_error_out != null) {
                            AlertService.add("error", response.data.p_error_out);
                            return;
                        }
                        else if(response.data.callSuccess == "0" && response.data.errorMessage != null) {
                            AlertService.add("error", response.data.errorMessage);
                            return;
                        }
                    }, function () {
                        AlertService.add("error", "Problem removing attachment.");
                    }
                );


            },downloadAttachment_I :function (vm,fileName) {

                console.log('fileName=', fileName);
                console.log('vm.pIAttachmentList = ', vm.pIAttachmentList);

                let fileObj = $filter("filter")(vm.pIAttachmentList,{file_name:fileName.split(".")[0], file_type:fileName.split(".")[1]})[0];
                let fileKey = fileObj.file_key;

                console.log('fileKey = ' + fileKey);
                let downloadUrl = vm.download_url+'?fileName='+fileName+"&fileKey="+fileKey+"&client_key="+sessionStorage.getItem('client_key');
                console.log('downloadUrl = ', downloadUrl);
                //$window.open(downloadUrl,'_blank');

                window.location.href = downloadUrl;


            },fileAdded_I : function (vm,file, event, flow) {
                var fileExtension = file.getExtension().toLowerCase();
                var allowedFileExtensions = ',jpg,jpeg,png,svg,pdf,xls,xlsx,txt,doc,docx,ico,ppt,pptx,ods,wpd,rtf';
                var isAllowedFileExtension = (allowedFileExtensions.indexOf(',' + fileExtension + ',') >= 0);
                var isFileSizeWithinLimit = (file.size <= vm.SERVER_CONFIG.configSettings.fileUploadMaxSize);
                var isFileAdded = (isAllowedFileExtension && isFileSizeWithinLimit);
                file.error = false;
                file.message = '';
                vm.attachmentTO_I.isError=false;
                vm.attachmentTO_I.errorMessage='';
                file.name =  file.name.replace(/[^-a-zA-Z0-9\s.]/gi, '_');
                var num = file.name.match(/\./g) !=null ? file.name.match(/\./g).length: 0; // check the periods in file
                if(num == 1){
                    if (!isFileAdded ) {
                        if (!isAllowedFileExtension) {
                            file.error = true;
                            file.message = file.name + ' is invalid.';
                            flow.cancel();
                            vm.attachmentTO_I.isError=true;
                            vm.attachmentTO_I.errorMessage=file.name + ' is invalid.';
                            setTimeout(function(){vm.attachmentTO_I.isError=false;
                                vm.attachmentTO_I.errorMessage='';},1000);
                            return false;
                        }
                        if (!isFileSizeWithinLimit) {
                            file.error = true;
                            file.message = file.name + ' exceeds the allowed maximum size of ';
                            // console.log(file.name + ' exceeds the allowed maximum size of ' + file.size);
                            vm.attachmentTO_I.isError=true;
                            vm.attachmentTO_I.errorMessage=file.name + ' exceeds the allowed maximum size of ';
                            setTimeout(function(){vm.attachmentTO_I.isError=false;
                                vm.attachmentTO_I.errorMessage='';},6000);
                            flow.cancel();
                            return false;
                        }
                    }
                }else if(num ===0){
                    file.error = true;
                    vm.attachmentTO_I.isError=true;
                    file.message = 'Unsupported File';
                }
                else{
                    file.error = true;
                    vm.attachmentTO_I.isError=true;
                    file.message = file.name + ' is invalid, has multiple periods.';
                    //flow.cancel();
                }
            },setFiles_I : function (vm,files, flow) {
                var x = false;
                var xy = [];
                _.each(vm.attachmentTO_I.attachmented_files,
                    function(item,key){
                        /* var fileName ="";
                        fileName =  (files[0] && files[0].length==0) ? files[0].name.replace(/[^a-zA-Z0-9\\+.]/gi, '_') :
                                        flow.files[0].file.name.replace(/[^a-zA-Z0-9\\+.]/gi, '_');
                        if(_.isEqual(item,fileName)){
                            x = true;
                            xy.push(item);
                        } */

                        if((files && files.length>0 || (flow  && flow.files && flow.files.length>0))){
                            var arr = files && files.length>0 ? files: flow.files;
                            _.each(arr,function(i){
                                var fn = i && i.name !=undefined ? i.name :i.file.name.replace(/[^-a-zA-Z0-9\s.]/gi, '_');
                                if(_.isEqual(item,fn)){
                                    x = true;
                                    xy.push(item);
                                }
                            })
                        }
                    })

                //if(vm.attachmentTO_I && vm.attachmentTO_I.attachmented_files.indexOf(flow.files[0].file.name)>-1){
                if(xy && xy.length>0){
                    AlertService.add("warning","Same file name exist, if the file has changes,please delete the exiting document and re-upload or else rename the file",4000);
                }else  if ((files[0] && files[0].error == false && files[0].error != undefined)) {
                    flow.upload();
                }else if((files[0] && files[0].error == true && files[0].message != undefined)) {
                    _.remove(flow.files,{name:files[0].name});
                    vm.attachmentTO_I.stored_files= _.uniq(vm.attachmentTO_I.attachmented_files);
                    AlertService.add("warning",files[0].message,4000);
                }
                //     else{
                //        AlertService.add("warning","You have already uploaded the same file name",4000);
                //   }
            },setErrorFile_I : function (vm,file, message, flow) {
                file.error = true;
                file.message = file.name + ' is invalid';
                AlertService.add("warning","You have already uploaded the same file name",4000);//or exceeds allowed maximum size of ' + FileSizeService.readableFileSize(SERVER_CONFIG.configSettings.fileUploadMaxSize);
                flow.cancel();
            },fileSuccess_I : function (vm,file, message, flow) {
                var parsedMessage = JSON.parse(message);

                console.log('parsedMessage = ', parsedMessage);

                console.log('pIAttachmentList =', vm.pIAttachmentList);

                if (parsedMessage.uploadStatus == 'success') {

                    PartnerAllocServicesNewFactory.loadSK3AttachmentDetails(vm).then(function(response){

                        let pIAttachmentMetadata = [];
                        let pIFileKeySet = [];
                        let pISavedAttachmentList = [];
                        vm.all_1065SK3AttachmentData = response.jsonObject;

                        /*  _.each(_.keys(K3PartIAttachmentFactory.fileUploadConfigI), function (item) {
                            if (!_.isFunction(K3PartIAttachmentFactory.fileUploadConfigI[item]))
                                ctrl[item] = _.cloneDeep(K3PartIAttachmentFactory.fileUploadConfigI[item]);
                            else ctrl[item] = K3PartIAttachmentFactory.fileUploadConfigI[item];
                        });*/

                        //pIAttachmentMetadata = $filter("filter")(vm.all_1065SK3AttachmentData, {PART_NO: 'I', PART_ORDER: 1});

                        pIAttachmentMetadata = _.filter(vm.all_1065SK3AttachmentData, {PART_NO: 'I', PART_ORDER: 1});

                        console.log(' pIAttachmentMetadata = ', pIAttachmentMetadata);

                        for(let x = 0; x < pIAttachmentMetadata.length; x++) {
                            pIFileKeySet.push(pIAttachmentMetadata[x].FILE_KEY);
                        }

                        let fileKeySetString = pIFileKeySet.join(",");
                        let  attachment_params = {fileKeySet : fileKeySetString };

                        $http({method: "post", url: vm.load_attachments_url, data: attachment_params}).then(function (response) {
                            console.log(response);
                            vm.attachmentTO_I.attachmented_files = [];
                            vm.attachmentTO_I.stored_files = [];
                            //vm.attachmentTO_I.attachmented_files = vm.pIAttachmentList;
                            pISavedAttachmentList = response.data.p_cursor;
                            vm.pIAttachmentList = pISavedAttachmentList;

                            if(pISavedAttachmentList) {
                                for (let i = 0; i < pISavedAttachmentList.length; i++) {
                                    console.log('pISavedAttachmentList[',i,'] = ' , pISavedAttachmentList[i]);
                                    let attachment = pISavedAttachmentList[i];

                                    attachment.fS = vm.formatBytes(attachment.file_size); // gets the files size with conversion val
                                    vm.attachmentTO_I.attachmented_files.push(attachment.file_name+"."+attachment.file_type);
                                }
                                vm.attachmentTO_I.attachmented_files = _.uniq(vm.attachmentTO_I.attachmented_files);
                                vm.attachmentTO_I.stored_files = _.cloneDeep(vm.attachmentTO_I.attachmented_files);
                                vm.existingFilesList_pI = _.cloneDeep(vm.pIAttachmentList);
                            }
                            // existing code
                            if(vm.pIAttachmentList){
                                vm.pIAttachmentList.forEach(el => {
                                    let index = _.findIndex(vm.attachmentTO_I.attachmented_files, e => e === el.file_name);
                                    if (index >= 0) {
                                        vm.pIAttachmentList.splice(index, 1);
                                    }
                                });
                            }

                            console.log('vm.pIAttachmentList ---- ' , vm.pIAttachmentList);

                            /*vm.attachmentTO_I.logoFilesMarkedForDeletion.push(vm.attachmentTO_I.attachment_file);
                            var fileName = parsedMessage.flowFilename;
                            var fileParam = {
                                name: fileName
                            };
                            vm.attachmentTO_I.attachment_file = fileName;
                            if(vm.attachmentTO_I.stored_files.length==0){
                                vm.attachmentTO_I.attachmented_files= _.uniq(_.map(flow.files,"name"));
                            }else{
                                vm.attachmentTO_I.attachmented_files= _.cloneDeep(vm.attachmentTO_I.stored_files);
                                vm.attachmentTO_I.attachmented_files = vm.attachmentTO_I.attachmented_files.
                                concat(_.uniq(_.map(flow.files,"name")));
                                vm.attachmentTO_I.attachmented_files= _.uniq(vm.attachmentTO_I.attachmented_files);
                            }
                            vm.attachmentTO_I.attachment_file_change_success = true;
                            vm.attachmentTO_I.logoFilesMarkedForDeletion = _.without(vm.attachmentTO_I.logoFilesMarkedForDeletion, vm.attachmentTO_I.attachment_file);*/

                        });

                    });

                } else if (parsedMessage.callSuccess == '0') {
                    file.error = true;
                    file.message = parsedMessage.errorMessage;
                    AlertService.add("error",parsedMessage.errorMessage,4000);
                }
            },dragEnter_I : function (vm) {
                vm.style = {
                    border: '2px solid #37d631'
                };
            },
            dragLeave_I : function (vm) {
                vm.style = {};
            }
        };
        var K3PartIAttachmentFactory={};
        K3PartIAttachmentFactory.fileUploadConfigI=fileUploadConfigI;
        K3PartIAttachmentFactory.newAttachmentCopy=function(){
            K3PartIAttachmentFactory=angular.copy(fileUploadConfigI);
        }
        return K3PartIAttachmentFactory;
    }

    function K3PartIFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,K3PartIAttachmentFactory,workspaceFactory,$filter) {


        K3PartIFactory.renderSK3PartIDetails = function(ctrl) {

            ctrl.part_I_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'I', SECTION_NAME:'N/A', ROW_TYPE: 'PH', ATTRIB_NAME: '1065_K3_I_N/A'});
            ctrl.part_I_1065SK3Data     = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'I',  SECTION_NAME:'N/A' , ATTRIB_NAME: '1065_K3_I_N/A'});
            ctrl.part_I_1065Sk3Attachment = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'I',  SECTION_NAME:'NA' , ROW_TYPE: 'AH', ATTRIB_NAME: '1065_K3_I_NA_NA'});

            ctrl.part_I_1065SK3DataCheckbox = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'I',  SECTION_NAME:'N/A' , ATTRIB_NAME: '1065_K3_I_N/A', EDIT_TYPE:'checkbox'});


            /*for(let x = 0; x < ctrl.part_I_1065SK3Data.length; x++) {
                let fmrec = ctrl.part_I_1065SK3Data[x];
                if(fmrec.LINE_NO == 12) {
                    if(fmrec.ATTRIBUTE_VALUE !== undefined) {
                       if(fmrec.ATTRIBUTE_VALUE == 1) {
                           ctrl.checkbox_12_value = true;
                       }
                       else if(fmrec.ATTRIBUTE_VALUE == 0) {
                           ctrl.checkbox_12_value = false;
                       }
                    }
                    else {
                        ctrl.checkbox_12_value = false;
                    }
                    break;
                }
            }*/

            ctrl.pIAttachmentList = [];
            ctrl.userSettings = USER_SETTINGS;
            ctrl.userType = ctrl.userSettings.user.client.user_type_key;
            let pIAttachmentMetadata = [];
            let pIFileKeySet = [];
            let pISavedAttachmentList = [];
            ctrl.attachmentTO_I = {
                FILE_KEY: '',
                FILE_NAME: '',
                FILE_PATH: '',
                FILE_TYPE: '',
                FILE_SIZE: '',
                CONTENT_TYPE: '',
                ATTACHMENT_KEY: '',
                comment_attachment_change_callSuccess: false,
                saveClicked: false,
                logoFilesMarkedForDeletion: [],
                datasource_connection_msg: '',
                datasource_connection_status: '',
                flow: {},
                attachment_file: '',
                partNo:'I',
                files_to_delete:[]
            };
            let OldFiles = [];

            _.each(_.keys(K3PartIAttachmentFactory.fileUploadConfigI), function (item) {
                if (!_.isFunction(K3PartIAttachmentFactory.fileUploadConfigI[item]))
                    ctrl[item] = _.cloneDeep(K3PartIAttachmentFactory.fileUploadConfigI[item]);
                else ctrl[item] = K3PartIAttachmentFactory.fileUploadConfigI[item];
            });

            //pIAttachmentMetadata = $filter("filter")(ctrl.all_1065SK3AttachmentData, {PART_NO: 'I', PART_ORDER: 1});


            pIAttachmentMetadata = _.filter(ctrl.all_1065SK3AttachmentData, {PART_NO: 'I', PART_ORDER: 1});



            console.log(' pIAttachmentMetadata = ', pIAttachmentMetadata);

            for(let x = 0; x < pIAttachmentMetadata.length; x++) {
                pIFileKeySet.push(pIAttachmentMetadata[x].FILE_KEY);
            }

            let fileKeySetString = pIFileKeySet.join(",");
            let  attachment_params = {fileKeySet : fileKeySetString };

            $http({method: "post", url: ctrl.load_attachments_url, data: attachment_params}).then(function (response) {
                console.log(response);
                ctrl.attachmentTO_I.attachmented_files = [];
                ctrl.attachmentTO_I.stored_files = [];
                ctrl.attachmentTO_I.attachmented_files = ctrl.pIAttachmentList;
                pISavedAttachmentList = response.data.p_cursor;
                ctrl.pIAttachmentList = pISavedAttachmentList;

                if(pISavedAttachmentList) {
                    for (let i = 0; i < pISavedAttachmentList.length; i++) {
                        console.log('pISavedAttachmentList[',i,'] = ' , pISavedAttachmentList[i]);
                        let attachment = pISavedAttachmentList[i];

                        attachment.fS = ctrl.formatBytes(attachment.file_size); // gets the files size with conversion val
                        ctrl.attachmentTO_I.attachmented_files.push(attachment.file_name+"."+attachment.file_type);
                    }
                    ctrl.attachmentTO_I.attachmented_files = _.uniq(ctrl.attachmentTO_I.attachmented_files);
                    ctrl.attachmentTO_I.stored_files = _.cloneDeep(ctrl.attachmentTO_I.attachmented_files);
                    ctrl.existingFilesList_pI = _.cloneDeep(ctrl.pIAttachmentList);
                }
                // existing code
                if(ctrl.pIAttachmentList){
                    ctrl.pIAttachmentList.forEach(el => {
                        let index = _.findIndex(ctrl.attachmentTO_I.attachmented_files, e => e === el.file_name);
                        if (index >= 0) {
                            ctrl.pIAttachmentList.splice(index, 1);
                        }
                    });
                }

            });

            return ctrl;
        }

        K3PartIFactory.removeFile = function(ctrl,file, index) {
            console.log('K3PartIFactory.removeFile called');
            ctrl.removeAttachment_I(ctrl,file,index);
        }

        K3PartIFactory.downloadAttachmentFile = function(ctrl,file) {
            console.log('K3PartIFactory.downloadAttachmentFile called');
            ctrl.downloadAttachment_I(ctrl,file);
        }

        K3PartIFactory.validateSK3DataPartI = function(ctrl, k3Data){

            //console.log('k3Data = ' , k3Data);
            let checkedCounter = 0;

            if(k3Data != undefined) {
                for(let i = 0; i < k3Data.length; i++) {
                    console.log('part 1 data : ' , k3Data[i]);
                }
                //console.log(ctrl.attachmentTO_I.attachmented_files);
                //console.log(ctrl.pIAttachmentList);


                for(let x = 0; x < ctrl.part_I_1065SK3DataCheckbox.length; x++) {
                    if(ctrl.part_I_1065SK3DataCheckbox[x].ATTRIBUTE_VALUE == '1') {
                        checkedCounter = checkedCounter + 1;
                    }
                }
                if(checkedCounter == 0 &&  ctrl.attachmentTO_I.attachmented_files.length == 0) {
                    ctrl.ERROR_MESSAGE = null;
                    ctrl.HAS_ERROR = false;
                }
                else if(checkedCounter > 0 &&  ctrl.attachmentTO_I.attachmented_files.length > 0) {
                    ctrl.ERROR_MESSAGE = null;
                    ctrl.HAS_ERROR = false;
                }
                else {
                    ctrl.ERROR_MESSAGE = "At least one attachment for at least one checked-entry is required " + ' in Part: I ';
                    ctrl.HAS_ERROR = true;
                }

            }

            return ctrl;
        }

        K3PartIFactory.obtainChanges = function(ctrl) {
            ctrl.partIChanges = $filter("filter")(ctrl.part_I_1065SK3Data,{IS_CHANGED_FLAG:'Y'});
            ctrl.partIChanges.push(ctrl.part_I_1065Sk3Attachment[0]);
            for(let i = 0; i < ctrl.partIChanges.length; i++) {
                ctrl.saveData.push(ctrl.partIChanges[i]);
            }
            return ctrl;
        }



        return K3PartIFactory;

    }

    return services;
});