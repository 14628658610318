define([
    'angular'

], function () {
    'use strict';

    let controllers =  angular.module('app.PshipCodeMastControllers',[])
        .controller('PshipCodeMastController', ['GlobalService','$rootScope','$scope','$state','$log','GridFactory','JsonObjectFactory','$filter','AlertService','$timeout','$uibModal','GENERAL_CONFIG','USER_SETTINGS', 'workspaceFactory','PshipCodeMastServicesFactory','$uibModalInstance','rowData',PshipCodeMastController]);

    function PshipCodeMastController(GlobalService,$rootScope,$scope,$state,$log,GridFactory,JsonObjectFactory,$filter,AlertService,$timeout,$uibModal,GENERAL_CONFIG,USER_SETTINGS,workspaceFactory,PshipCodeMastServicesFactory,$uibModalInstance,rowData) {

        let vm = this;

        vm.title = 'Edit Partnership Code';

        vm.crudLoading = false;
        vm.isSaveClicked = false;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;

        vm.vaildationErrMsg = null;

        vm.code_key        = rowData.CODE_KEY;
        vm.code_desc       = rowData.CODE_DESC;
        vm.code_short_desc = rowData.CODE_SHORT_DESC;
        vm.code_group      = rowData.CODE_GROUP;
        vm.from_year       = rowData.FROM_YEAR;
        vm.to_year         = rowData.TO_YEAR;
        vm.priority_order  = rowData.PRIORITY_ORDER;
        vm.created_by      = rowData.CREATED_BY;
        vm.created_on      = rowData.CREATED_ON;
        vm.updated_by      = rowData.UPDATED_BY;
        vm.updated_on      = rowData.UPDATED_ON;
        vm.is_display      = rowData.IS_DISPLAY;
        vm.code_disp_desc  = rowData.CODE_DISP_DESC;
        vm.is_default      = rowData.IS_DEFAULT;
        vm.code_long_desc  = rowData.CODE_LONG_DESC;
        vm.row_type        = rowData.ROW_TYPE;


        vm.save = function() {
            vm.isSaveClicked = true;
            vm.processSave();
        }

        function validateFormData(){
            let validationStatus = true;

            if(vm.from_year > vm.to_year) {
                vm.vaildationErrMsg = 'From Year cannot be greater then To Year.';
                validationStatus = false;
            }

            return validationStatus;
        }

        vm.processSave = function (){

            let clobSettingsObj = {};
            let clobDataObj = {};
            let validationStat = validateFormData();
            let message = null;

            if(validationStat) {

                console.log('Validation passed... sending details to service layer for saving in the DB');
                clobSettingsObj['sso_id'] = vm.logged_in_user;

                clobDataObj['code_key'] = vm.code_key;
                clobDataObj['code_desc'] = vm.code_desc;
                clobDataObj['code_short_desc'] = vm.code_short_desc;
                clobDataObj['from_year'] = vm.from_year;
                clobDataObj['to_year'] = vm.to_year;
                clobDataObj['priority_order'] = vm.priority_order;
                clobDataObj['updated_by'] = vm.logged_in_user;
                clobDataObj['is_display'] = vm.is_display;
                clobDataObj['code_disp_desc'] = vm.code_disp_desc;
                clobDataObj['is_default'] = vm.is_default;
                clobDataObj['code_long_desc'] = vm.code_long_desc;
                clobDataObj['row_type'] = vm.row_type;

                message = "Partnership Code Master Details Saved Successfully";
                sendDetails(clobSettingsObj,clobDataObj,message);

            }
            else {
                AlertService.add("danger", vm.vaildationErrMsg, 4000);
                vm.isSaveClicked = false;
            }

        }

        function sendDetails(clobSettings, clobData, msg){
            PshipCodeMastServicesFactory.saveACodeMastRecord(clobSettings,clobData).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {

                    AlertService.add("success", msg, 4000);
                    var args = {
                        code_key: vm.CODE_KEY,
                        gridFilter: {
                            code_key: vm.CODE_KEY
                        }
                    };

                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);

                };

            });
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

    }

});