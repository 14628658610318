define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.aggrerunController', [])
					.controller('aggrerunDtlsController', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','AggRerunServiceFactory', 'rowData', 'gridData', aggrerunDtlsController])

			
			function aggrerunDtlsController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,AggRerunServiceFactory,rowData, gridData) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.syncSaveDtls = [];
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year;
				vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
				vm.isSaveClicked = false;
				
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
								for(var key in vm.gridDataMultipleSelect){
									var gridObj = {};
									
									gridObj["object_id"] = vm.gridDataMultipleSelect[key].data.object_id;
									gridObj["combination_key"] = vm.gridDataMultipleSelect[key].data.COMBINATION_KEY;
									gridObj["consol_group_key"] = vm.gridDataMultipleSelect[key].data.CONSOL_GROUP_KEY;
									gridObj["leid"] = vm.gridDataMultipleSelect[key].data.LEID;
									gridObj["cdr_number"] = vm.gridDataMultipleSelect[key].data.CDR_NO;
									gridObj["me_code"] = vm.gridDataMultipleSelect[key].data.ME_CODE;
									gridObj["reporting_period"] = vm.gridDataMultipleSelect[key].data.REPORTING_PERIOD;
									gridObj["taxYear"] = vm.gridDataMultipleSelect[key].data.TAX_YEAR;
									gridObj["scenario"] = vm.gridDataMultipleSelect[key].data.SCENARIO;
									gridObj["startDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.TAX_YEAR_BEGIN, "MM/dd/yyyy");
									gridObj["EndDate"] = $filter('date')(vm.gridDataMultipleSelect[key].data.TAX_YEAR_END, "MM/dd/yyyy");
									gridObj["taxType"] = vm.gridDataMultipleSelect[key].data.TAX_TYPE;
									gridObj["ho_leid"] = vm.gridDataMultipleSelect[key].data.HO_LEID;
									gridObj["ho_cdr_number"] = vm.gridDataMultipleSelect[key].data.HO_CDR_NO;
									gridObj["ho_reporting_period"] = vm.gridDataMultipleSelect[key].data.HO_REPORTING_PERIOD;
									gridObj["ho_leName"] = vm.gridDataMultipleSelect[key].data.HO_LE_NAME;
									gridObj["parent_meCode"] = vm.gridDataMultipleSelect[key].data.PARENT_ME_CODE;
									
									vm.syncSaveDtls.push(gridObj);
									
								}
					}

				} 
				
				
				  vm.fetchMaxCountEntities = function() {//GlobalService.globalParams.screen_key
			            var params = { "action_code": 'mysfkb', "screen_key": GlobalService.globalParams.screen_key};
			            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
							if(data.jsonObject.length != 0){
								vm.aggLimit = data.jsonObject[0].ATTRIB_VALUE;
							}
			            });
			        }
				
				  vm.fetchMaxCountEntities();

				//Rerun selected Entities For aggregation
				vm.confirm_sync = function() {
						if(vm.syncSaveDtls.length > parseInt(vm.aggLimit)){
							AlertService.add("error", "Can't select more than " + vm.aggLimit + " entities");
							return;
						}
					 
					var returnClobSettingsObj = {};
					var ecSaveDtls = [];
					var returnObj = {};
					
					var ccKeys = vm.syncSaveDtls.map(item => item.combination_key)
					var ccKeysString = ccKeys.join(',');
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['combination_key'] = ccKeysString;

					var message = "Submitted for Aggregation Reran";
					sendDetails(returnClobSettingsObj,ecSaveDtls, message, 'aggRerun');

				};

				function sendDetails(returnClobSettingsObj, ecSaveDtls, message, type) {
					 $rootScope.$broadcast("showLoader", true);
					 $uibModalInstance.dismiss('cancel');
					 
					 $rootScope.$broadcast("showLoader", false);
					 AlertService.add("success", message, 4000);
					 AggRerunServiceFactory.reRunAggEntities(returnClobSettingsObj,ecSaveDtls, type).then(function(result) {

						 
//								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
//									 vm.isSaveClicked = false;
//									 $uibModalInstance.dismiss('cancel');
//									AlertService.add("error", result.data.errorMessage, 4000);
//								} else {
//									$rootScope.$broadcast("showLoader", false);
//									AlertService.add("success", message, 4000);
//                            	};

							});
				}

				//To rerun Cosol credits and Sch J
				vm.sync_data = function(saveType) {
					 
					var returnClobSettingsObj = {};
					var ecSaveDtls = [];
					var returnObj = {};
				
				
				/*	returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['leid'] = vm.syncSaveDtls[0].leid;					
					returnClobSettingsObj['cdr_no'] = vm.syncSaveDtls[0].cdr_number;					
					returnClobSettingsObj['reporting_period'] = vm.syncSaveDtls[0].reporting_period;
					returnClobSettingsObj['combination_key'] = vm.syncSaveDtls[0].combination_key; */
					
					var ccKeys = vm.syncSaveDtls.map(item => item.combination_key)
					var ccKeysString = ccKeys.join(',');
				
						if (saveType == 'consolCalcSchJ'){
							//returnClobSettingsObj['source_system'] = '3800';
							var message = "Submitted for Consol Calc Sch J Reran";
						}else{
							//returnClobSettingsObj['source_system'] = '8916';
							var message = "Submitted for Consol Calc Credits Reran";
						}

					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['combination_key'] = ccKeysString;

					
					sendConsolCalcDetails(returnClobSettingsObj,ecSaveDtls, message, saveType);

				};

				
				function sendConsolCalcDetails(returnClobSettingsObj, ecSaveDtls, message, saveType) {
					$rootScope.$broadcast("showLoader", true);
					$uibModalInstance.dismiss('cancel');
					
					AggRerunServiceFactory.reRunAggEntities(returnClobSettingsObj,ecSaveDtls, saveType).then(function(result) {

						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							vm.isSaveClicked = false;
						   AlertService.add("error", result.data.errorMessage, 4000);
					   } else {
						   //vm.crudLoading = false;
						   $rootScope.$broadcast("showLoader", false);							   
						 //  $rootScope.$emit('gridUpdate', args);
						   AlertService.add("success", message, 4000);
					   };

				   });
			   }


			}
			return controllers;
		});