define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.sltAddDataService', [])
    .factory("sltAddDataServiceFactory", ['$http', 'GENERAL_CONFIG', function ($http, GENERAL_CONFIG) {
        const sltAddDataServiceFactory = {};

        var URLS = {
            getDataserviceClients: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32804",
        }

        sltAddDataServiceFactory.getDataServiceClients = function(serviceKey) {
            var promise = $http({
                method: "GET",
                url: URLS.getDataserviceClients + '&service_key='+ serviceKey
            }).then(function(response) {
                if(response.data.callSuccess === "1") {
                    return response.data;
                } else {
                    AlertService.add("error", "No slt api data list found", 4000);
                }
            });
            return promise;
        }

        return sltAddDataServiceFactory;
    }]);


    return services;
});