
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.CalcsFilingGroupStatusService',[])

        .factory("CalcsFilingGroupStatusFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', 
        function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
            var CalcsFilingGroupStatusFactory = {};
        
            CalcsFilingGroupStatusFactory.getFromScenario = function(params){
                var params = params;
                var promise = $http({
                    method: "get",
                    url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=7q0wiy&jcd_key=' + params.jcd_key + '&scenario=' + params.scenario + '&tax_year=' + params.tax_year,
                    data: params
                }).then(function (response) {
                    return response;
                });
                return promise;
            };

            CalcsFilingGroupStatusFactory.getFromFilingGroup = function(params){
                var params = params;
                var promise = $http({
                    method: "get",
                    url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id=762&jcd_key=' + params.jcd_key + '&scenario=' + params.scenario + '&tax_year=' + params.tax_year,
                    data: params
                }).then(function (response) {
                    return response;
                });
                return promise;
            };

            CalcsFilingGroupStatusFactory.saveCopyChanges = function (params) {
				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=0betp4' + '&tax_year=' + params.tax_year + '&scenario=' + params.scenario + '&jcd_key=' + params.jcd_key,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};
            
            return CalcsFilingGroupStatusFactory
        }]);

        return services;

    });

