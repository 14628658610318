define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.entityClassificationActionsController', [])
    .controller('entityClassificationCreateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','entityClassificationActionsService', entityClassificationCreateActionController])
    .controller('entityClassificationUpdateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','entityClassificationActionsService', entityClassificationUpdateActionController]);
 
    function entityClassificationCreateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,entityClassificationActionsService){
        var vm = this;

        vm.modalTitle = "Create New Override Entity";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {};
        vm.tempOverrideEntity = {};
        vm.overrideStub;
        vm.allMasterLeids = [];
 		
        vm.overrideEntity_bckup = {
                "EDIT_TYPE":"REPLACE",
                "EDIT_COMMENTS":"",
                "CHILD_ID":"",
                "STUB":"",
                "TAX_YEAR":"",
                "SCENARIO":"",
                "START_DATE":"",
                "END_DATE":"",
                "LE_SHORT_NAME":"",
                "GECC_ENTITY_ID":"",
                "TAX_TYPE":"",
                "HO_LEIDS":"",
                "HO_GECC_ENTITY_ID":"",
                "HO_TAX_TYPE":"",
                "HO_COUNTRY_INCORPORATED":"",
                "CLASSIFICATION_DESC":"",
                "CLASSIFICATION":"",
                "ACQUISITION_DATE":"",
                "DATE_INCORPORATED":"",
                "DATE_SPAWNED_CONSUMED":"",
                "DATE_DISSOLVED":"",
                "DATE_LIQUIDATED":"",
                "DATE_SOLD":"",
                "TAX_CONSOLIDATION_GRP":"",
                "TAX_CONSOLIDATION_GRP_DESC":"",
                "COUNTRY_INCORPORATED":"",
                "RETURN_PREPARED_BY":"",
                "GOLD_START_DATE":"",
                "GOLD_END_DATE":"",
                "DCS_ENTITY":"",
                "FIR_ENTITY":"",
                "SUB_CONSOL":"",
                "TECH_TERM":"",
                "SINGLE_OWNER_PSHIP":"",
                "PROCESS_NOTES":"",
                "UPDATED":"",
                "DATA_HASH":"",
                "FILING_REQUIREMENT":"",
                "ROLL_FORWARD":"",
                "US_HO_LEID":"",
                "US_HO_REPORTING_PERIOD":"",
            };

            vm.defaultStubs = [
                { label: '1', value: 1},
                { label: '2', value: 2},
                { label: '3', value: 3},
                { label: '4', value: 4},
                { label: '5', value: 5},
                { label: '6', value: 6}
            ];

        vm.overrideEntity = angular.copy(vm.overrideEntity_bckup);

        vm.onLeidSelect = function(elem){
            vm.tempOverrideEntity =  elem;
        };
        
        vm.onLeidChange = function(){
            //if(vm.overrideEntity.CHILD_ID){
                console.log(vm.overrideStub);
                let selectedElem = null;
                for(let i=0 ; i < vm.allLeids.length-1; i++){
                    //if((vm.allLeids[i].CHILD_ID == vm.overrideEntity.CHILD_ID) && (vm.allLeids[i].STUB == vm.overrideEntity.STUB)){
                    if(vm.allMasterLeids[i].CHILD_ID == vm.overrideEntity.CHILD_ID){
                        selectedElem = vm.allMasterLeids[i];
                        break;
                    }
                }
                if(selectedElem && selectedElem.CHILD_ID == vm.overrideEntity.CHILD_ID){
                    vm.overrideEntity =  selectedElem;
                    vm.overrideStub = vm.overrideEntity.STUB;
                    if(vm.overrideEntity.US_HO_REPORTING_PERIOD == null || 
                        vm.overrideEntity.US_HO_REPORTING_PERIOD == undefined || 
                        vm.overrideEntity.US_HO_REPORTING_PERIOD == ""){
                             vm.overrideEntity.US_HO_REPORTING_PERIOD = "";
                        }else{
                            vm.overrideEntity.US_HO_REPORTING_PERIOD = vm.overrideEntity.US_HO_REPORTING_PERIOD+"";
                        }
                    if(vm.overrideEntity.RETURN_PREPARED_BY == "C" ){
                        vm.overrideEntity.RETURN_PREPARED_BY = "Corporate";
                    }else{
                        vm.overrideEntity.RETURN_PREPARED_BY = "External";
                    }
    
                    if(vm.overrideEntity.DCS_ENTITY == "Y" ){
                        vm.overrideEntity.ENTITY_TYPE = "D";
                    }else{
                        vm.overrideEntity.ENTITY_TYPE = "F";
                    }
                 }
                //else{
                //     vm.overrideEntity =  angular.copy(vm.overrideEntity_bckup);
                // }
            // }else{
            //     vm.overrideEntity = angular.copy(vm.overrideEntity_bckup);
            // }
        };

        vm.onReportingSelection = function(id){
            console.log(id);
            var hasAnotherStub = false;
            let selectedElem = null;
            for(let i=0 ; i < vm.allMasterLeids.length-1; i++){
                //if((vm.allLeids[i].CHILD_ID == vm.overrideEntity.CHILD_ID) && (vm.allLeids[i].STUB == vm.overrideEntity.STUB)){
                if((vm.allMasterLeids[i].CHILD_ID == vm.overrideEntity.CHILD_ID) && (vm.allMasterLeids[i].STUB == id)){
                    selectedElem = vm.allMasterLeids[i];
                    hasAnotherStub = true;
                    break;
                }
            }
            if(hasAnotherStub){
                vm.overrideEntity =  selectedElem;
                vm.overrideStub = vm.overrideEntity.STUB;
            }else{
                let tempChildId = angular.copy(vm.overrideEntity.CHILD_ID);
                let tempStubId = id;
                vm.overrideEntity = angular.copy(vm.overrideEntity_bckup);
                vm.overrideEntity.CHILD_ID = tempChildId;
                vm.overrideEntity.STUB = tempStubId;
            }

        };

        let loadAllEntities =function(){
            entityClassificationActionsService.loadAllEntities().then((leids) => {
                console.log("load all entities Id", leids);
                vm.allLeids = leids;
                vm.leidsApiCompleted = true;
                stopLoader();
            });
        }

        let loadAllOverrideEntities =function(){
            entityClassificationActionsService.loadAllOverrideEntities().then((leids) => {
                console.log("load all entities", leids);
                vm.allMasterLeids = leids;
                //vm.leidsApiCompleted = true;
                stopLoader();
            });
        }

        let loadAllClassification = function(){
            
            entityClassificationActionsService.loadClassifications().then((classifications) => {
                console.log("load all entities", classifications);
                vm.allClassifications = classifications;
                vm.classificationsApiCompleted = true;
                stopLoader();
            });
        }

        let loadTaxConsoleGroups = function(){
            
            entityClassificationActionsService.loadTaxConsoleGroup().then((taxConsoleGroups) => {
                console.log("load all entities", taxConsoleGroups);
                vm.allTaxConsoleGroups = taxConsoleGroups;
                vm.taxConsoleGroupsApiCompleted = true;
                stopLoader();
            });
        }
        

        const stopLoader = function() {
            if(vm.leidsApiCompleted && vm.classificationsApiCompleted && vm.taxConsoleGroupsApiCompleted){
                vm.showEntityLoader = false;
            }
        }

        vm.setClassificationDesc = function(){
            for(let i=0; i < vm.allClassifications.length-1 ; i++){
                if(vm.allClassifications[i].CLASSIFICATION == vm.overrideEntity.CLASSIFICATION){
                    vm.overrideEntity.CLASSIFICATION_DESC = vm.allClassifications[i].CLASSIFICATION_DESC;
                }
            }
        }

        vm.setTaxConsoleGroupDescription = function(){
            for(let i=0; i < vm.allTaxConsoleGroups.length-1 ; i++){
                if(vm.allTaxConsoleGroups[i].TAX_CONSOLIDATION_GRP == vm.overrideEntity.TAX_CONSOLIDATION_GRP){
                    vm.overrideEntity.TAX_CONSOLIDATION_GRP_DESC = vm.allTaxConsoleGroups[i].TAX_CONSOLIDATION_GRP_DESC;
                }
            }
        }

        vm.init =function(){
            vm.showEntityLoader = true;
            loadAllEntities();
            loadAllOverrideEntities();
            loadAllClassification();
            loadTaxConsoleGroups();
        };
        
		vm.init();

        vm.isValid = function(){

            if(vm.overrideEntity.CHILD_ID == null || 
               vm.overrideEntity.CHILD_ID == undefined || 
               vm.overrideEntity.CHILD_ID == ""){
                    vm.overrideEntity.CHILD_ID_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.STUB == null || 
                vm.overrideEntity.STUB == undefined || 
                vm.overrideEntity.STUB == ""){
                    vm.overrideEntity.STUB_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.START_DATE == null || 
                vm.overrideEntity.START_DATE == undefined || 
                vm.overrideEntity.START_DATE == ""){
                    vm.overrideEntity.START_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.END_DATE == null || 
                vm.overrideEntity.END_DATE == undefined || 
                vm.overrideEntity.END_DATE == ""){
                    vm.overrideEntity.END_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.TAX_TYPE == null || 
                vm.overrideEntity.TAX_TYPE == undefined || 
                vm.overrideEntity.TAX_TYPE == ""){
                    vm.overrideEntity.TAX_TYPE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.CLASSIFICATION == null || 
                vm.overrideEntity.CLASSIFICATION == undefined || 
                vm.overrideEntity.CLASSIFICATION == ""){
                    vm.overrideEntity.CLASSIFICATION_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.DATE_INCORPORATED == null || 
                vm.overrideEntity.DATE_INCORPORATED == undefined || 
                vm.overrideEntity.DATE_INCORPORATED == ""){
                    vm.overrideEntity.DATE_INCORPORATED_NOT_VALID = true;
                    return false;
            }
            // if(vm.overrideEntity.DATE_DISSOLVED == null || 
            //     vm.overrideEntity.DATE_DISSOLVED == undefined || 
            //     vm.overrideEntity.DATE_DISSOLVED == ""){
            //         vm.overrideEntity.DATE_DISSOLVED_NOT_VALID = true;
            //         return false;
            // }
            if(vm.overrideEntity.TAX_CONSOLIDATION_GRP == null || 
                vm.overrideEntity.TAX_CONSOLIDATION_GRP == undefined || 
                vm.overrideEntity.TAX_CONSOLIDATION_GRP == ""){
                    vm.overrideEntity.TAX_CONSOLIDATION_GRP_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.COUNTRY_INCORPORATED == null || 
                vm.overrideEntity.COUNTRY_INCORPORATED == undefined || 
                vm.overrideEntity.COUNTRY_INCORPORATED == ""){
                    vm.overrideEntity.COUNTRY_INCORPORATED_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.RETURN_PREPARED_BY == null || 
                vm.overrideEntity.RETURN_PREPARED_BY == undefined || 
                vm.overrideEntity.RETURN_PREPARED_BY == ""){
                    vm.overrideEntity.RETURN_PREPARED_BY_NOT_VALID = true;
                    return false;
            }
            // if(vm.overrideEntity.OVERRIDE_REASON == null || 
            //     vm.overrideEntity.OVERRIDE_REASON == undefined || 
            //     vm.overrideEntity.OVERRIDE_REASON == ""){
            //         vm.overrideEntity.OVERRIDE_REASON_NOT_VALID = true;
            //         return false;
            // }
            if(vm.overrideEntity.ENTITY_TYPE == null || 
                vm.overrideEntity.ENTITY_TYPE == undefined || 
                vm.overrideEntity.ENTITY_TYPE == ""){
                    vm.overrideEntity.ENTITY_TYPE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.FILING_REQUIREMENT == null || 
                vm.overrideEntity.FILING_REQUIREMENT == undefined || 
                vm.overrideEntity.FILING_REQUIREMENT == ""){
                    vm.overrideEntity.FILING_REQUIREMENT_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.ROLL_FORWARD == null || 
                vm.overrideEntity.ROLL_FORWARD == undefined || 
                vm.overrideEntity.ROLL_FORWARD == ""){
                    vm.overrideEntity.ROLL_FORWARD_NOT_VALID = true;
                    return false;
            }
            
            return true;
        };

        vm.save = function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            vm.setClassificationDesc();
            vm.setTaxConsoleGroupDescription();
            vm.overrideEntity = entityClassificationActionsService.formatDateFeilds(vm.overrideEntity);
            vm.scenarioCode = workspaceFactory.activeScreen.filters.filters[0].selected.scenario_type_code ? workspaceFactory.activeScreen.filters.filters[0].selected.scenario_type_code : 'RAF';
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);
            vm.overrideEntity.SCENARIO = filterParams?.scenario;
            let jsonObj = JSON.stringify(vm.overrideEntity);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params.scenarioCode = vm.scenarioCode;
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=lrcue9";
            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Override entity created successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });

        };

        vm.reset = function (){
			vm.overrideEntity = angular.copy(vm.overrideEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function entityClassificationUpdateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, entityClassificationActionsService){
        var vm = this;

        vm.modalTitle = "Update Override Entity";
		vm.showFormLoader = true;
        
        console.log({gridData});

        vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {};
 		
        vm.overrideEntity_bckup = gridData.rowData;

        vm.overrideEntity = angular.copy(vm.overrideEntity_bckup);
        vm.overrideEntity.STUB = vm.overrideEntity.STUB+"";
        if(vm.overrideEntity.US_HO_REPORTING_PERIOD == null || 
            vm.overrideEntity.US_HO_REPORTING_PERIOD == undefined || 
            vm.overrideEntity.US_HO_REPORTING_PERIOD == ""){
                 vm.overrideEntity.US_HO_REPORTING_PERIOD = "";
            }else{
                vm.overrideEntity.US_HO_REPORTING_PERIOD = vm.overrideEntity.US_HO_REPORTING_PERIOD+"";
            }
        
        let loadAllOverrideEntities =function(){
            entityClassificationActionsService.loadAllOverrideEntities().then((leids) => {
                console.log("load all entities", leids);
                vm.allLeids = leids;
                vm.leidsApiCompleted = true;
                stopLoader();
            });
        }

        let loadAllClassification = function(){
            
            entityClassificationActionsService.loadClassifications().then((classifications) => {
                console.log("load all entities", classifications);
                vm.allClassifications = classifications;
                vm.classificationsApiCompleted = true;
                stopLoader();
            });
        }

        let loadTaxConsoleGroups = function(){
            
            entityClassificationActionsService.loadTaxConsoleGroup().then((taxConsoleGroups) => {
                console.log("load all entities", taxConsoleGroups);
                vm.allTaxConsoleGroups = taxConsoleGroups;
                vm.taxConsoleGroupsApiCompleted = true;
                stopLoader();
            });
        }

        vm.setClassificationDesc = function(){
            for(let i=0; i < vm.allClassifications.length-1 ; i++){
                if(vm.allClassifications[i].CLASSIFICATION == vm.overrideEntity.CLASSIFICATION){
                    vm.overrideEntity.CLASSIFICATION_DESC = vm.allClassifications[i].CLASSIFICATION_DESC;
                }
            }
        }
        
        // vm.setTaxConsoleGroupDescription = function(){
        //     for(let i=0; i < vm.allTaxConsoleGroups.length-1 ; i++){
        //         if(vm.allTaxConsoleGroups[i].TAX_CONSOLIDATION_GRP == vm.overrideEntity.TAX_CONSOLIDATION_GRP){
        //             vm.overrideEntity.TAX_CONSOLIDATION_GRP_DESC = vm.allTaxConsoleGroups[i].TAX_CONSOLIDATION_GRP_DESC;
        //         }
        //     }
        // }

        vm.onOptionsSelected = function(selectedValue){
            console.log(selectedValue);
            var selectedClassification = _.find(vm.allTaxConsoleGroups, {TAX_CONSOLIDATION_GRP: selectedValue});
            vm.overrideEntity.TAX_CONSOLIDATION_GRP_DESC = selectedClassification.TAX_CONSOLIDATION_GRP_DESC;
        }

        const stopLoader = function() {
            if(vm.leidsApiCompleted && vm.classificationsApiCompleted && vm.taxConsoleGroupsApiCompleted){
                vm.showEntityLoader = false;
            }
        }

        vm.init =function(){
            vm.showEntityLoader = true;
            loadAllOverrideEntities();
            loadAllClassification();
            loadTaxConsoleGroups();

        };

        vm.init();

		
        vm.isValid = function(){

            if(vm.overrideEntity.CHILD_ID == null || 
               vm.overrideEntity.CHILD_ID == undefined || 
               vm.overrideEntity.CHILD_ID == ""){
                    vm.overrideEntity.CHILD_ID_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.STUB == null || 
                vm.overrideEntity.STUB == undefined || 
                vm.overrideEntity.STUB == ""){
                    vm.overrideEntity.STUB_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.START_DATE == null || 
                vm.overrideEntity.START_DATE == undefined || 
                vm.overrideEntity.START_DATE == ""){
                    vm.overrideEntity.START_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.END_DATE == null || 
                vm.overrideEntity.END_DATE == undefined || 
                vm.overrideEntity.END_DATE == ""){
                    vm.overrideEntity.END_DATE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.TAX_TYPE == null || 
                vm.overrideEntity.TAX_TYPE == undefined || 
                vm.overrideEntity.TAX_TYPE == ""){
                    vm.overrideEntity.TAX_TYPE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.CLASSIFICATION == null || 
                vm.overrideEntity.CLASSIFICATION == undefined || 
                vm.overrideEntity.CLASSIFICATION == ""){
                    vm.overrideEntity.CLASSIFICATION_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.DATE_INCORPORATED == null || 
                vm.overrideEntity.DATE_INCORPORATED == undefined || 
                vm.overrideEntity.DATE_INCORPORATED == ""){
                    vm.overrideEntity.DATE_INCORPORATED_NOT_VALID = true;
                    return false;
            }
            // if(vm.overrideEntity.DATE_DISSOLVED == null || 
            //     vm.overrideEntity.DATE_DISSOLVED == undefined || 
            //     vm.overrideEntity.DATE_DISSOLVED == ""){
            //         vm.overrideEntity.DATE_DISSOLVED_NOT_VALID = true;
            //         return false;
            // }
            if(vm.overrideEntity.TAX_CONSOLIDATION_GRP == null || 
                vm.overrideEntity.TAX_CONSOLIDATION_GRP == undefined || 
                vm.overrideEntity.TAX_CONSOLIDATION_GRP == ""){
                    vm.overrideEntity.TAX_CONSOLIDATION_GRP_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.COUNTRY_INCORPORATED == null || 
                vm.overrideEntity.COUNTRY_INCORPORATED == undefined || 
                vm.overrideEntity.COUNTRY_INCORPORATED == ""){
                    vm.overrideEntity.COUNTRY_INCORPORATED_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.RETURN_PREPARED_BY == null || 
                vm.overrideEntity.RETURN_PREPARED_BY == undefined || 
                vm.overrideEntity.RETURN_PREPARED_BY == ""){
                    vm.overrideEntity.RETURN_PREPARED_BY_NOT_VALID = true;
                    return false;
            }
            // if(vm.overrideEntity.OVERRIDE_REASON == null || 
            //     vm.overrideEntity.OVERRIDE_REASON == undefined || 
            //     vm.overrideEntity.OVERRIDE_REASON == ""){
            //         vm.overrideEntity.OVERRIDE_REASON_NOT_VALID = true;
            //         return false;
            // }
            if(vm.overrideEntity.ENTITY_TYPE == null || 
                vm.overrideEntity.ENTITY_TYPE == undefined || 
                vm.overrideEntity.ENTITY_TYPE == ""){
                    vm.overrideEntity.ENTITY_TYPE_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.FILING_REQUIREMENT == null || 
                vm.overrideEntity.FILING_REQUIREMENT == undefined || 
                vm.overrideEntity.FILING_REQUIREMENT == ""){
                    vm.overrideEntity.FILING_REQUIREMENT_NOT_VALID = true;
                    return false;
            }
            if(vm.overrideEntity.ROLL_FORWARD == null || 
                vm.overrideEntity.ROLL_FORWARD == undefined || 
                vm.overrideEntity.ROLL_FORWARD == ""){
                    vm.overrideEntity.ROLL_FORWARD_NOT_VALID = true;
                    return false;
            }
            
            return true;
        };

        vm.save = function(isDelete){
            if(!isDelete && !vm.isValid() ){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.setClassificationDesc();
            //vm.setTaxConsoleGroupDescription();
            vm.overrideEntity = entityClassificationActionsService.formatDateFeilds(vm.overrideEntity);
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);
            if(isDelete){
                vm.overrideEntity.EDIT_TYPE = "DELETE";
                vm.isSavingDelete =true;
            }else{
                vm.overrideEntity.EDIT_TYPE = "REPLACE";
                vm.isSavingUpdate =true;
            }

            let jsonObj = JSON.stringify(vm.overrideEntity);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=pzl4ye";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                vm.isSavingDelete =false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Override entity created successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });


        };

        vm.delete = function(entity_id, stub_id){
            console.log("Delete function called");
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: vm.overrideEntity.TAX_YEAR,
                child_id: entity_id,
                stub: stub_id
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = JSON.stringify(vm.overrideEntity);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            params = _.extend({
                tax_year: vm.overrideEntity.TAX_YEAR,
                child_id: entity_id,
                stub: stub_id
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=sq9z1i";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate =false;
                vm.isSavingDelete =false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Override entity deleted successfully", 4000);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });

        };

        vm.reset = function (){
			vm.overrideEntity = angular.copy(vm.overrideEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
    return controllers;
});
