define([
    'angular'

 ], function () {
    'use strict';
    
    
    var controllers = angular.module('app.F-4626Ctrl', [])
    .controller('F4626Controller', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4626Factory', 'ModalFactory',F4626Controller])
    .controller('F4626ControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4626Factory', 'ModalFactory','$uibModalInstance','rowData', 'colData', 'gridData',F4626ControllerModal])    ;

    function F4626Controller(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4626Factory,ModalFactory) {
    	F4626ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4626Factory,ModalFactory,{},{}, {}, {},this);
    }


	function F4626ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4626Factory,ModalFactory,$uibModalInstance,
        rowData, colData, gridData,thisObj) {
		console.log("------------At the start-----------");
		
		var vm = this;
		vm.rowData = rowData;
		console.log(rowData);
		vm.globalParams = GlobalService.globalParams;
		console.log("The Global Params like tax_year, scenario and jcd are ::::::::::::", vm.globalParams);
		vm.f4626Header = [];
		vm.f4626List = [];
		vm.f4626OriginalData = [];
		vm.f4626FormData = [];
		vm.f4626ColumnHeader = [];
		vm.f4626FinalData = [];
		vm.unAllocatedAmt = 0;
		vm.crudLoading = false;
		
		vm.cancel = function() {
			console.log("Form 4626 data from DB is :::::::::::", vm.f4626FormData);
			console.log("Form 4626 data from original Data is saved in :::::::::::", vm.f4626OriginalData);
			if(angular.equals(vm.f4626FormData, vm.f4626OriginalData)){
				$uibModalInstance.dismiss('cancel');
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
					vm.cancelModal();
				}
				else if(confirm("Sales of Business Property Information is not save. Do you want to close Form without saving changes?")){
					$uibModalInstance.dismiss('cancel');
				}
			}
			
		};
		
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		console.log(vm.logged_in_user);
		function getF4626Data(){
			F4626Factory.fetchF4626Details(rowData, vm.logged_in_user).then(function (data){
				console.log("Came back to controller from Service...............:::::::::", data);
				vm.f4626Header = data.jsonObject[0];
				vm.f4626List = data.jsonObject.slice(1);
				
				var o = vm.f4626List.reduce( (a,b) => {
					a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
					a[b.LINE_ORDER].push(b);
					return a;
				}, {});
				
				var a = Object.keys(o).map(function(k){
					return {ROW_TYPE: o[k][0].ROW_TYPE, LINE_NO: o[k][0].LINE_NO, DELETE_ROWS: [], Line_data: o[k]};
				});
				
				vm.f4626FormData = a;
				console.log(vm.f4626FormData);
				
				var c = [];
				
				for (var i=0; i < vm.f4626FormData.length; i++){
					if(vm.f4626FormData[i].ROW_TYPE == 'DT'){
						for(var j=0; j < vm.f4626FormData[i].Line_data.length; j++){
							if(vm.f4626FormData[i].Line_data[j].IS_EDITABLE == 'Y' && vm.f4626FormData[i].Line_data[j].EDIT_TYPE == 'currency' && vm.f4626FormData[i].Line_data[j].DATA_TYPE == 'number'){
								vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = parseInt(vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE);
							}
						}
					}
				}
				
				console.log(vm.f4626FormData);
				vm.f4626OriginalData = angular.copy(vm.f4626FormData);
			});
			
		}
		getF4626Data();
		vm.reset = function(){
			getF4626Data();
		}
		
		vm.save = function(){
			vm.finalData = [];
			vm.clob_settings = [];
			console.log(vm.f4626FormData );
			console.log("Original Data was ::::::::==========", vm.f4626OriginalData);
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			vm.f4626FinalData = angular.copy(vm.f4626FormData);
			if(angular.equals(vm.f4626FormData, vm.f4626OriginalData)){
				vm.cancel();
			}else{
				for(var x=0; x < vm.f4626FinalData.length; x++){
					if(vm.f4626FinalData[x].Line_data.length != null && vm.f4626FinalData[x].Line_data.length != undefined){
						for(var j=0; j < vm.f4626FinalData[x].Line_data.length; j++){
							vm.finalData.push(vm.f4626FinalData[x].Line_data[j]);
						}
					}
				}
				console.log("After changes, the final data was :::::::::::=========", vm.f4626FinalData);
				console.log("After changes, the manipulated data was :::::::::::=========", vm.f4626FormData);
				console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
				vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
				sendDetails(vm.finalData, vm.clob_settings);
			}
		}
		
		function sendDetails(final_data, clob_settings){
			var message = "Form 4626 Details are successfully updated."
			vm.crudLoading = true;
			F4626Factory.saveForm4626(final_data, clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		}
		
		vm.valuechanged = function(parent_index, occurrence, line_no, index){
			console.log("These are the parent_index, occurrence and index of the editted field", parent_index, occurrence, line_no, index);
			for (var i=0; i < vm.f4626FormData.length; i++){
				if(i == parent_index && vm.f4626FormData[i].Line_data[index] != null && vm.f4626FormData[i].Line_data[index] != undefined){
					vm.f4626FormData[i].Line_data[index].IS_CHANGED_FLAG = "Y";
				}
			}
			console.log(vm.f4626FormData);
		}
		
		vm.updateAmount = function(index, line_no){
			console.log("We are updating the amounts for this column:::::: ", index);
			if((vm.f4626FormData[index].ROW_TYPE == 'DT' || vm.f4626FormData[index].ROW_TYPE == 'DD' || vm.f4626FormData[index].ROW_TYPE == 'LH') && vm.f4626FormData[index].LINE_NO == line_no){
				for(var j=0; j < vm.f4626FormData[index].Line_data.length; j++){
					if(vm.f4626FormData[index].Line_data[j].ATTRIB_CALC_FORMULA != null){
						var line_data_values = vm.f4626FormData[index].Line_data;
						vm.f4626FormData[index].Line_data[j].ATTRIB_VALUE = vm.calcLineFormula(vm.f4626FormData[index].Line_data[j].ATTRIB_CALC_FORMULA, line_data_values);
						vm.f4626FormData[index].Line_data[j].IS_CHANGED_FLAG = "Y";
						vm.f4626FormData[index].Line_data[j].HAS_ERROR = "N";
					}
				}
			}
			vm.calcFinalAmounts();
		}
		
		vm.calcLineFormula = function(formula, line_data_values){
			console.log("Given formula and index of the row are ", formula, line_data_values);
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					var currentTabAtrribVal = $filter("filter")(line_data_values, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var lineAmt = parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			console.log("Given formula and index of the row are ", formula, line_data_values);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		}
		
		//console.log(vm.calcFormula((SALE_PRICE_1)-(COST_BASIS_1), 2));
		vm.calcFinalAmounts = function(){
			for (var i=0; i < vm.f4626FormData.length; i++){
				for(var j=0; j < vm.f4626FormData[i].Line_data.length; j++){
					if(vm.f4626FormData[i].Line_data[j].ATTRIB_CALC_FORMULA != null){
						var final_amt = vm.calc_final_amt(vm.f4626FormData[i].Line_data[j].ATTRIB_CALC_FORMULA);
						if(vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE != final_amt){
							vm.f4626FormData[i].Line_data[j].ATTRIB_VALUE = final_amt;
							vm.f4626FormData[i].Line_data[j].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}
		}
		
		vm.calc_final_amt = function(formula){	
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				var lineAmt = 0;
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					for(var k=0; k < vm.f4626FormData.length; k++){
						var row_data = vm.f4626FormData[k].Line_data;
						var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
						if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
							lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
						}
					}
					//console.log("Formula before Replacing with amount========", formula);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			//console.log("Given formula and index of the row are ", formula);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				var result_val = eval(formula);
				result_val = Math.round(result_val);
				console.log(result_val);
				return result_val;
			}else return 0;
		}
	}
});