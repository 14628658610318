define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F1125ACtrl', [])

    	.directive("limitToMax", function() {
		return {
			link: function(scope, element, attributes) {
				element.on("keydown keyup", function(e) {
					if (Number(element.val()) > Number(attributes.max) &&
					  e.keyCode != 46 // delete
					  &&
					  e.keyCode != 8 // backspace
					) {
						e.preventDefault();
						element.val(attributes.max);
					}
					});
				}
			};
		})

        .controller('F1125AController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','$filter',F1125AController]);
    	function F1125AController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, $filter) {
            var vm = this;

            vm.modalTitle = "Cost of Goods Sold";
    		vm.originalData = [];
    		vm.userSettings = USER_SETTINGS;
    		vm.logged_in_user = vm.userSettings.user.sso_id;
    		vm.leId = rowData.LEID;
            vm.formName = '1120';
            vm.hgt = '96%';
            vm.cdr_no = rowData.MARS;
            vm.leType = rowData.LE_TYPE;
            vm.systemFlag = 'D';

            vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
            $scope.entityList = {
    			"Tax Year": rowData.TAX_YEAR,
    			"LEID": rowData.LEID,
    			"LE Name": rowData.LE_NAME
    		};

    		var saveParamDtls = [];
    		var tempDataObj = {};
    		$scope.form1125A = {rows:[]};
    		vm.showFormLoader = true;
    		vm.corporationList = [];
    		vm.entityList = [];
    		vm.entitiesData = [];
    		vm.individualsList = [];
    		vm.codeMastMap = [];

            function fetch1125Arecords() {
                var params = { "action_id": 31333, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY, "form_name": "1125", "schedule_name": "A", "part_no" : "NA"};

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
                
    				for(var i=1;i<data.irsForm.formItemList.length;i++){
    					saveParamDtls = [];
    					tempDataObj = {};
    					saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
    					var rows = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
    					tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
                    	tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;
                    	if(rows > 2){
                    		iterateArray(rows, data.irsForm.formItemList[i].dataRowList[0].dataCellList);
                    	}
    					tempDataObj.line_order = saveParamDtls[8];
    					tempDataObj.saveParams = saveParamDtls;
    					$scope.form1125A.rows.push(tempDataObj);
    				}
  
    				vm.tableHeader = $filter("filter")($scope.form1125A.rows, {num:"NA"});
                	$scope.form1125AView = $scope.form1125A.rows.filter(function( obj ) {
						return obj.num !== 'NA';
					});
                	
                	vm.tableHeader[0].value = rowData.LE_NAME; 
                	vm.tableHeader[1].value= rowData.EIN;
                      
    				vm.originalData = angular.copy($scope.form1125AView);
    				initFormView($scope.form1125AView);
    				vm.showFormLoader = false; 
                });
            };

            //check for 7
            var iterateArray = function(count, array){
            	if(count == 3){
    				tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    				tempDataObj.attrib_order_a = array[2].key.split('~')[2];
    				tempDataObj.is_form_display = saveParamDtls[9];
    				
    				tempDataObj.edit_type = array[2].property.edit_type;
    				tempDataObj.data_type = array[2].property.data_type;
    				
    				
    				if(array[2].value == null){
    					if(tempDataObj.num == "9a(i)" || tempDataObj.num == "9a(ii)" || tempDataObj.num == "9a(iii)" || tempDataObj.num == "9b" || tempDataObj.num == "9c"){
    						tempDataObj.value = false;
    					}else {
    							tempDataObj.value = '';
    						}
    					if(tempDataObj.num == "9d"){
    						tempDataObj.conditionalField = false;
    					}else{
    						tempDataObj.conditionalField = true;
    					}
    				}else if(array[2].value === "true"){
    					if(tempDataObj.num == "9a(i)" || tempDataObj.num == "9a(ii)" || tempDataObj.num == "9a(iii)" || tempDataObj.num == "9b" || tempDataObj.num == "9c"){
    						tempDataObj.value = true;
    					}else {
    						tempDataObj.value = '';
    					}
    				}else if (array[2].value === "false"){
    					if(tempDataObj.num == "9a(i)" || tempDataObj.num == "9a(ii)" || tempDataObj.num == "9a(iii)" || tempDataObj.num == "9b" || tempDataObj.num == "9c"){
    						tempDataObj.value = false;
    					}else {
    						tempDataObj.value = '';
    					}
    					if(tempDataObj.num == "9d"){
    						tempDataObj.conditionalField = false;
    					}else{
    						tempDataObj.conditionalField = true;
    					}
    				}else if (array[2].value === "0"){
    					if(tempDataObj.num == "9a(i)" || tempDataObj.num == "9a(ii)" || tempDataObj.num == "9a(iii)" || tempDataObj.num == "9b" || tempDataObj.num == "9c"){
    						tempDataObj.value = false;
    					}else {
    						tempDataObj.value = '';
    					}
    					if(tempDataObj.num == "9d"){
    						tempDataObj.conditionalField = false;
    					}else{
    						tempDataObj.conditionalField = true;
    					}
    				}else if (array[2].value == 'NA'){
    					if(tempDataObj.num == "9a(i)" || tempDataObj.num == "9a(ii)" || tempDataObj.num == "9a(iii)" || tempDataObj.num == "9b" || tempDataObj.num == "9c"){
    						tempDataObj.value = false;
    					}else {
    						tempDataObj.value = '';
    					}
    					if(tempDataObj.num == "9d"){
    						tempDataObj.conditionalField = false;
    					}else{
    						tempDataObj.conditionalField = true;
    					}
    				}else {
    					tempDataObj.value = array[2].value;
    					if(tempDataObj.num == "9d"){
    						tempDataObj.conditionalField = false;
    					}else{
    						tempDataObj.conditionalField = true;
    					}
    				}
    			}else if(count == 4){
    				if(tempDataObj.num == "9a(iii)"){
    					tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    					tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    					tempDataObj.attrib_order_a = array[2].key.split('~')[2];
    					tempDataObj.edit_type = array[2].property.edit_type;
        				tempDataObj.data_type = array[2].property.data_type;
    					tempDataObj.value = array[2].value;
    					
    					tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
    					tempDataObj.attrib_name_b = array[3].key.split('~')[0];
    					tempDataObj.attrib_order_b = array[3].key.split('~')[2];
    					tempDataObj.edit_type = array[3].property.edit_type;
        				tempDataObj.data_type = array[3].property.data_type;
    					tempDataObj.value_user_input = array[3].value;
    					if(tempDataObj.value == null){
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}else if(tempDataObj.value === "true"){
    						tempDataObj.value = true;
    						tempDataObj.conditionalField = false;
    					}else if (array[2].value === "false"){
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}else if (array[2].value === "0"){
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}else {
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}
    				}else{
    					tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    					tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    					tempDataObj.attrib_order_a = array[2].key.split('~')[2];
    					tempDataObj.edit_type = array[2].property.edit_type;
        				tempDataObj.data_type = array[2].property.data_type;

    					tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
    					tempDataObj.attrib_name_b = array[3].key.split('~')[0];
    					tempDataObj.attrib_order_b = array[3].key.split('~')[2];
    					tempDataObj.edit_type = array[3].property.edit_type;
        				tempDataObj.data_type = array[3].property.data_type;

    					if(array[2].value === "true"){
    						tempDataObj.value = true;
    					}else if(array[3].value === "true"){
    						tempDataObj.value = false;
    					}else{
    						tempDataObj.value = 'NA';
    					}

    				}
    			}
    		};
    		
    		vm.limitDecimal = function(val, line, i, attr){
                var index = _.findIndex($scope.form1125AView.rows, {num: line});
                if (Number(val) > 100){
                    $scope.form1125AView.rows[index].JSON_DATA[i][attr] = 100;
                }
            };

            
    		function initSchK(){
    				fetch1125Arecords();
    		};
    		initSchK(); 
            
    		
    		$scope.fieldVisibility = function (index, state, name, rowNum){
    			if(rowNum == '9a(iii)'){
    					setValue([1,2], [index], !$scope.form1125AView[index].conditionalField);
    			}else {
    				$scope.form1125AView[index].conditionalField = !$scope.form1125AView[index].conditionalField;
    				if((name == 'yes' && !state) || (name == 'no' && state)){
    					$scope.form1125AView[index].value = 'NA';
    				};
    			}
    		};

    		function setValue(arr, toggle_i, state){
    			/*angular.forEach(arr, function (value, key) {
    				$scope.form1125AView[value].value = false;
    			});*/
    			$scope.form1125AView[toggle_i].conditionalField = state;
    			$scope.form1125AView[toggle_i].value_user_input = '';
    		};



            vm.save1125AForm = function (url, data){
                var jsonSettings = {
                	"tax_year": rowData.TAX_YEAR,
                	"scenario": rowData.SCENARIO,
                	"jcd_key": rowData.JCD_KEY,
                	"combination_key": rowData.COMBINATION_KEY,
                	"trans_type_key": data[0].editrows[0][0].trans_type_key
                };
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(data);
                var jsonSettings  = JSON.stringify(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                params.process_name =  'F1120_COGS';
                params.combination_key = rowData.COMBINATION_KEY;
                params.trans_type_key = data[0].editrows[0][0].trans_type_key;
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                    console.log("save1125AForm() response.data.jsonObj ::",response.data.jsonObject);
    				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
    					$uibModalInstance.dismiss('cancel');
//    					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
//    					$rootScope.$emit('gridUpdate', args);
    					AlertService.add("success", "Transactions saved successfully", 4000);
    				}else{
    					if(response.data && response.data.errorMessage){
                            AlertService.add("error", response.data.errorMessage,4000);
                        }else{
                            AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                        }
    				}
                    return response.data;
                });
                console.log("------------At the end-----------", promise);
                return promise;
            };

            vm.save = function(){
    			var formRowDataA = {};
    			var formRowDataB = {};
    			var tempArray = [];
    			/*if(!vm.isValid()){
    				AlertService.add("error", "Please provide the mandatory fields.",4000);
    				return;
    			}*/
    			for(var i=0; i<$scope.form1125AView.length ;i++){
    				
    				formRowDataA = {
    					tax_year: rowData.TAX_YEAR,
    					trans_type_key: $scope.form1125AView[i].saveParams[1],
    					line_order: $scope.form1125AView[i].line_order,
                        attrib_order: $scope.form1125AView[i].attrib_order_a,
                        line_description: $scope.form1125AView[i].description,
    					combination_key: rowData.COMBINATION_KEY,
    					group_obj_key: rowData.GROUP_OBJ_KEY,
    					form_key: $scope.form1125AView[i].saveParams[2],
    					line_no: $scope.form1125AView[i].num,
    					occurence: $scope.form1125AView[i].saveParams[7],
    					line_attrib_key: $scope.form1125AView[i].lineAttrKey_a,
    					Attribute_Name: $scope.form1125AView[i].attrib_name_a,
    					Attribute_Value: $scope.form1125AView[i].value === true ? 'true' : $scope.form1125AView[i].value == 'NA' ? 'false' : $scope.form1125AView[i].value === false ? 'false' :  $scope.form1125AView[i].value ? $scope.form1125AView[i].value.toString() : '',
    					trans_dtls_key: $scope.form1125AView[i].saveParams[3],
    					trans_status: $scope.form1125AView[i].saveParams[6],
    					is_dirty: ''
    				};
    				formRowDataA.JSON_DATA = [];
    				tempArray.push(formRowDataA);

    				if(typeof $scope.form1125AView[i].attrib_name_b !== "undefined"){
    					if($scope.form1125AView[i].num == '9a(iii)'){
    						formRowDataB = {
    							tax_year: rowData.TAX_YEAR,
    							trans_type_key: $scope.form1125AView[i].saveParams[1],
    							line_order: $scope.form1125AView[i].line_order,
    	                    	attrib_order: $scope.form1125AView[i].attrib_order_b,
    	                    	line_description: $scope.form1125AView[i].description,
    							combination_key: rowData.COMBINATION_KEY,
    							group_obj_key: rowData.GROUP_OBJ_KEY,
    							form_key: $scope.form1125AView[i].saveParams[2],
    							line_no: $scope.form1125AView[i].num,
    							occurence: $scope.form1125AView[i].saveParams[7],
    							line_attrib_key: $scope.form1125AView[i].lineAttrKey_b,
    							Attribute_Name: $scope.form1125AView[i].attrib_name_b,
    							Attribute_Value: $scope.form1125AView[i].value_user_input,
    							trans_dtls_key: $scope.form1125AView[i].saveParams[3],
    							trans_status: $scope.form1125AView[i].saveParams[6],
    							is_dirty: ''
    						};
    						tempArray.push(formRowDataB);
    					}else{
    						formRowDataB = {
    							tax_year: rowData.TAX_YEAR,
    							trans_type_key: $scope.form1125AView[i].saveParams[1],
    							line_order: $scope.form1125AView[i].line_order,
    	                    	attrib_order: $scope.form1125AView[i].attrib_order_b,
    	                    	line_description: $scope.form1125AView[i].description,
    							combination_key: rowData.COMBINATION_KEY,
    							group_obj_key: rowData.GROUP_OBJ_KEY,
    							form_key: $scope.form1125AView[i].saveParams[2],
    							line_no: $scope.form1125AView[i].num,
    							occurence: $scope.form1125AView[i].saveParams[7],
    							line_attrib_key: $scope.form1125AView[i].lineAttrKey_b,
    							Attribute_Name: $scope.form1125AView[i].attrib_name_b,
    							Attribute_Value: $scope.form1125AView[i].value === true ? 'false' : $scope.form1125AView[i].value == 'NA' ? 'false' : $scope.form1125AView[i].value === false ? 'true' : $scope.form1125AView[i].value ? $scope.form1125AView[i].value.toString() : '',
    							trans_dtls_key: $scope.form1125AView[i].saveParams[3],
    							trans_status: $scope.form1125AView[i].saveParams[6],
    							is_dirty: ''
    						};
    						tempArray.push(formRowDataB);
    					}
    				}

    			}
    			vm.formData.editrows.push(tempArray);
    			vm.mainData = [];
    			vm.mainData.push(vm.formData);
    			console.log(vm.mainData);
    			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=z9frws"; 
    			vm.save1125AForm(url,vm.mainData);

                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function() {
                    $scope.$broadcast("showLoader", true);
                },100);
            };


    		vm.reset = function (){
    			$scope.form1125AView = angular.copy(vm.originalData);
    		};

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            function initFormView(filteredFormData) {
    			$scope.form1125AView = filteredFormData;
    		};

    		//Dynamic Tabset Code
    		vm.tabs = [
    			 {id: 1, name: "Form Entry", url: "app/components/F1120/F1125-Sch-A/F1125AEntry.html", active: true }
    	    ];
        }
});