define([
    'angular',
    './giltiConsolidatedGridService',
    './giltiConsolidatedGridController'

], function () {
    'use strict';
    return angular.module('app.giltiConsolidatedGridData', ['app.giltiConsolidatedGridCtrl','app.giltiConsolidatedGridService'] )

});






