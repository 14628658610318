
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.CreditsPartIIIConsolService',[])

    .factory('CreditsPartIIIConsolServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', '$injector', 
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG , $injector){
	        var creditsPartIIIConsolServiceFactory = {};
	        creditsPartIIIConsolServiceFactory.url = {};
	
	       creditsPartIIIConsolServiceFactory.saveF3800IIIConsolAmounts = function (_callName, _data, isNew) {
            var clob_setting = {};
            var clob_data = [];
            var params = {};
            var defer = $q.defer();
            var service = $injector.get('JsonObjectFactory');
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=xhphao";
            
            
            //Check for utilized amount can't be greater than total Remaining Amount.
            if(parseInt(_data.rowData[_data.col_id]) > parseInt(_data.rowData['HIDDEN_TOTAL_REMAINING'])){
            	AlertService.add("error", "Please make sure utilized Amount can't be greater that Total Remaining Amount");
         	   return;
            }
            
            var cons  = 'CarryForward';
            var utilizedTaxYr = _data.col_id.substring(0, _data.col_id.indexOf('_'));
            
            var carryForwardAmts = _.pickBy(_data.rowData, function(value, key) {
            	  return _.endsWith(key, cons);
            	});
            //clob data acct key, schd tax year, amount
            var currentObj = {};
            for (const property in carryForwardAmts) {
            	  var caryrForwardTaxYear = property.substring(0, property.indexOf('_'));
            	  if(caryrForwardTaxYear < utilizedTaxYr){
            		  var carryAmt = _.pick(_data.rowData, [property]);
            		  currentObj[property] = parseInt(carryAmt[property]);//Object.values(carryAmt);
            	  }
            }
            var sumCarryForwardAmts = sumAmounts(currentObj);
            
            if(sumCarryForwardAmts != 0){
            	AlertService.add("error", "Please make sure previous year CarryForward Amount should be exhaused ");
          	   return;
            }
            //_data.rowData[_data.col_id]
            //If it's current tax year
            if(utilizedTaxYr == _data.rowData.TAX_YEAR ){
            	 var subsetCarryOver = _.pick(_data.rowData, [_data.rowData.TAX_YEAR+'_Generated']);
            }else{
            	var subsetCarryOver = _.pick(_data.rowData, [utilizedTaxYr+'_CarryOver']);
            	var carryOverAmt = Object.values(subsetCarryOver);
            } 
            
          /*  var subsetCarryForward = _.pick(_data.rowData, [utilizedTaxYr+'-CarryForward']);
        	var carryForwardAmt = Object.values(subsetCarryForward); */
        	
            //Check for utilized amount can't be greater than Generated/CarryOver Amount.
            if(parseInt(_data.rowData[_data.col_id]) >  parseInt(Object.values(subsetCarryOver)[0])){
            	AlertService.add("error", "Please make sure utilized Amount can't be greater that Generated/CarryOver Amount");
            	return;
            }
           
           var returnObj = {};
           
           	returnObj['acct_key'] =  _data.rowData.ACCT_KEY;
//           	returnObj['combination_key'] = _data.rowData.COMBINATION_KEY;
			returnObj['part_no'] =  _data.rowData.PART_NO;
			returnObj['schd_tax_year'] =  utilizedTaxYr; //_data.rowData.SCHD_TAX_YEAR;
			if(parseInt(_data.rowData[_data.col_id]) < 0){
				returnObj['u_amount'] = (parseInt(_data.rowData[_data.col_id]));
			}else{
				returnObj['u_amount'] = -(parseInt(_data.rowData[_data.col_id]));
			}
			
           clob_data.push(returnObj);
           clob_setting = {tax_year: _data.rowData.TAX_YEAR, scenario: _data.rowData.SCENARIO, jcd_key: _data.rowData.JCD, ta_key : _data.rowData.TA_KEY};
            params.process_name = "CREDITPIII-CONSOL-GRID-SAVE";
            params.tax_year = _data.rowData.TAX_YEAR;
            params.scenario = _data.rowData.SCENARIO;
            params.jcd_key = _data.rowData.JCD_KEY;
            
            params = _.extend({clob_data: JSON.stringify(clob_data)}, params);
            params = _.extend({clob_setting: JSON.stringify(clob_setting)}, params)
            params = _.merge({},GlobalService.globalParams, params);
            
            console.log(params);
            
            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved Successfully.", 4000);
                        var args = {
                            tax_year: _data.rowData.TAX_YEAR
                        };
                        $rootScope.$emit('gridUpdate', args);
                        defer.resolve(data);
                } else {
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                    defer.reject(data);
                }
            });
        };
        
        
        
        function sumAmounts(subsetGenNCO) {
        	  return Object.values(subsetGenNCO).reduce((a, b) => a + b, 0) // 650
        	};
        	
	  creditsPartIIIConsolServiceFactory.saveSchJConsolAmounts = function (_callName, _data, isNew) {
            $rootScope.$broadcast("showLoader", true);
            AlertService.add("warning", "Please wait while the data is being saved!", 4000);
            var clob_setting = {};
            var clob_data = [];
            var params = {};
            var defer = $q.defer();
            var service = $injector.get('JsonObjectFactory');
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9qa2sc";
            clob_data.push(_data.rowData);
            clob_setting = {tax_year: _data.rowData.TAX_YEAR};
            params.process_name = "SCH_J_CONSOL_SAVE";
            params.tax_year = _data.rowData.TAX_YEAR;
            params.scenario = _data.rowData.SCENARIO;
            params.jcd_key = _data.rowData.JCD_KEY;
            params.combination_key = _data.rowData.COMBINATION_KEY;
            params = _.extend({clob_data: JSON.stringify(clob_data)}, params);
            params = _.extend({clob_setting: JSON.stringify(clob_setting)}, params)
            service.saveJSON(url, params).then(function (data) {
                $rootScope.$broadcast("showLoader", false);

                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved Successfully.", 4000);
                        var args = {
                            tax_year: _data.rowData.TAX_YEAR
                        };
                        $rootScope.$emit('gridUpdate', args);
                        defer.resolve(data);
                } else {
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                    defer.reject(data);
                }
            });
        }  	
        
        return creditsPartIIIConsolServiceFactory
    }])

    return services;

});