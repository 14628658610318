define(
    ['angular'],
    function() {
        'use strict';

        var controllers = angular.module('app.estqtrmaintenancectrl',[])
            .controller('estqtrmaintenancectrl',['GlobalService', 'AlertService', 'JsonObjectFactory',  '$parse', 
                        '$rootScope','$scope','$http','$filter','$timeout','USER_SETTINGS',
                        'GENERAL_CONFIG', 'workspaceFactory','estqtrmaintenanceServiceFactory',
                        estqtrmaintenancectrl]
                         );

    function estqtrmaintenancectrl(GlobalService, AlertService, JsonObjectFactory,$parse, $rootScope,$scope,
         $http, $filter, $timeout,USER_SETTINGS,GENERAL_CONFIG, workspaceFactory,estqtrmaintenanceServiceFactory
        ) {
            $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
            var vm = this;
            vm.rollover = {
                tax_year: "",
                out_tax_year : "",
                quarter:"",
                locked_status: 0
            };
            vm.lockedqtrlist = [];
            vm.quaterList = [];

            var activeScreen = workspaceFactory.activeScreen;
             
            workspaceFactory.checkFilters(workspaceFactory.activeScreen);
            if(activeScreen && activeScreen.data){
                vm.showBackdrop = false;
                vm.lockedqtrlist = [];
                vm.result = activeScreen.data;
                    vm.quaterList= estqtrmaintenanceServiceFactory.getQuarterList();
                    angular.forEach(vm.result, function (role,index) {
                        vm.lockedqtrlist.push({
                            tax_year: role.tax_year,//_.find(vm.taxYearList,{VALUE:role.tax_year}),
                            out_tax_year: role.out_year,// _.find(vm.outTaxYearList,{VALUE:role.out_year}),
                            quarter: _.find(vm.quaterList,{VALUE:role.qtr}),
                            locked_status:role.locked_status == 'Y'? 1: 0
                        });
                    });
                     workspaceFactory.checkFilters(workspaceFactory.activeScreen);
            } else {
                vm.showBackdrop = true;
            }
/* getQuarters */
vm.getToQuarters = function(params,data){
    var action_id = 31592;
    estqtrmaintenanceServiceFactory.getMaintanceData(params,action_id).then((response) => {
        console.log("response quartereList",response);
        vm.lockedqtrlist = [];
        vm.quaterList = response.jsonObject;
        estqtrmaintenanceServiceFactory.setQuarterList(vm.quaterList);
        if (data.length > 0) {
            
            angular.forEach(data, function (role,index) {
                vm.lockedqtrlist.push({
                    tax_year: role.tax_year,
                    out_tax_year: role.out_year,
                    quarter: _.find(vm.quaterList,{VALUE:role.qtr}),
                    locked_status:role.locked_status == 'Y'? 1: 0
                });
            });
        } 
         workspaceFactory.setCache(activeScreen, data);
        workspaceFactory.setCurrentFilters(activeScreen);
        vm.showBackdrop = false;
        $scope.loading = false; 
        
        vm.showBackdrop = false;
        $scope.loading = false;
    })
}

/* get Maintance Data */
function getquarterMaintancedataView(fromSave){
    if(!fromSave){
        $scope.loading = true;
    }
    console.log("on the way");
    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
    var params = {
        tax_year: filterParams.tax_year,
        scenario :filterParams.scenario,
        jcd_key :GlobalService.globalParams.jcd_key,
        out_year: filterParams.out_year,
        workflow:filterParams.wf_name
    }          
    var action_id = 31590; 
    estqtrmaintenanceServiceFactory.getMaintanceData(params,action_id).then((response) => {
        console.log("response data",response);
        vm.lockedqtrlist = [];
        vm.result = JsonObjectFactory.arrObjToLower(response.jsonObject);//response.jsonObject;
        if(!fromSave){
            vm.getToQuarters(params,vm.result);
        }else {
            vm.quaterList =estqtrmaintenanceServiceFactory.getQuarterList();
            angular.forEach(vm.result, function (role,index) {
                vm.lockedqtrlist.push({
                tax_year: role.tax_year,
                out_tax_year: role.out_year,
                quarter: _.find(vm.quaterList,{VALUE:role.qtr}),
                locked_status:role.locked_status == 'Y'? 1: 0
            });
        });}
       
    })
}
vm.addMaintenance = function(){
    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
   
  
    vm.lockedqtrlist.push({
        tax_year: filterParams.tax_year,
        out_tax_year: filterParams.out_year,
        quarter: vm.rollover.quarter,
        locked_status: vm.rollover.locked_status
    });
  //  vm.getToQuarters(vm.rollover.from_quarter)
}
$scope.$on('dataFilters:refreshGrid', function (event, data) {
    getquarterMaintancedataView();
});
vm.isMissing = function (obj) {
    return (angular.isUndefined(obj) || (obj == null) || (obj === ''));
};
vm.QuarterMissing = function(index){
    return $scope.saveMaintenanceFrm['quarter_' + index].$dirty && vm.isMissing(vm.lockedqtrlist[index].quarter);
}
vm.saveLock = function(obj,index){
     var isFromQuarterMissing = false;
    angular.forEach(vm.rollovers, function (frmElement, index) {
        $scope.saveMaintenanceFrm['quarter_' + index].$dirty = true;

        isFromQuarterMissing = isFromQuarterMissing | vm.QuarterMissing(index);
    })
    vm.isFromQuarterMissing = vm.QuarterMissing(index);
    var isRequiredFieldsMissing =   isFromQuarterMissing ;
    if(isRequiredFieldsMissing){
        vm.lockedqtrlist[index].locked_status = 0;
        return;
    }                        
    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    var params = filterParams;
    params = _.merge({},  GlobalService.globalParams , params);
    params.tax_year = obj.tax_year;
    params.out_year = obj.out_tax_year;
    params.scenario = filterParams.scenario;//33
    params.qtr = obj.quarter.VALUE;
    params.workflow = filterParams.wf_name;
   var clob_data = {};
   params.lock_y_n = obj.locked_status == 1 ?'Y' :'N';
    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31589";
    JsonObjectFactory.saveJSON(url, params).then(function (data) {
        if (data.callSuccess === "1") {
            getquarterMaintancedataView({fromSave:true});
            AlertService.add("success", "Data saved Successfully.", 4000);
        } else {
            AlertService.add("error", data.errorMessage, 4000);
        }
    }
    );
}
vm.cancelModel = function(index){
    vm.rollovers[$index].rollover_status =0;
    console.log("clicked");
}
vm.deletelockedrow = function(index){
    vm.lockedqtrlist.splice(index, 1);
    //vm.toQuarterList.splice(index, 1);
}
}
}
)
;