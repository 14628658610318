define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.SampleAppController',[])
        .controller('SampleAppController', ['$scope','$state', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory', SampleController])
        .controller('SampleAppListController', ['$rootScope','$scope','$stateParams','$state' ,'$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory','SampleAppFactory', SampleListController]);


    function SampleController($scope,$state,$http, ModalFactory, AlertService, JsonObjectFactory) {
        var vm = this;
        vm.name = "Sample Application";
        vm.$state = $state;
        console.log("$state" , $state);


        vm.tabs = [{name:"test1"},{name:"test2"},{name:"test1"}]

        vm.includes = function(_link){
            if( vm.$state.current.name.indexOf(_link) >= 0){
                return true;
            }else{
                return false;
            }
        }
    }


    function SampleListController($rootScope,$scope, $stateParams, $state, $http, ModalFactory, AlertService, JsonObjectFactory,SampleAppFactory) {
        var vm = this;
        vm.name = "SuperHero List";
        vm.buttonLable = "Add SuperHero"
        vm.formTitle = "Add Super Hero";
        vm.id = $stateParams.id;
        vm.list = SampleAppFactory.getList();
        vm.tempId = vm.list.length;
        vm.selected = {
            id:"",
            name:"",
            powers:"",
            age:"",
            height:"",
            weight:""
        };



        var loadForm = function(_id){
            console.log("Should of been called")
            if( $stateParams.id != undefined  ) {
                if(vm.list[$stateParams.id] ){
                   vm.selected = _.clone(vm.list[$stateParams.id]);
                   vm.buttonLable = "Update: " + vm.selected.name;
                   vm.formTitle = "Update SuperHero";
               }else{
                  $state.go('sample-application.list.add');
               }
            }else{
                vm.name = "SuperHero List";
                vm.buttonLable = "Add SuperHero"
                vm.formTitle = "Add Super Hero";
                clearSelected();
            }
        }


        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {loadForm($stateParams.id);});
        loadForm($stateParams.id);

        function clearSelected(){
            vm.selected = {
                id:"",
                name:"",
                powers:"",
                age:"",
                height:"",
                weight:""
            };
        };


        vm.clearSuperHero = function(){
            if( $stateParams.id !== undefined ){
                $state.go('sampleApplication.list');
                return true;
            }
            clearSelected();
        }


        vm.deleteSuperHero = function(_item){
            var index = _.findIndex(vm.list, { 'id': _item.id });
            console.log("index" , index);
            //HERE WE WOULD CALL SERVICE DELETE
            AlertService.add("success", _item.name + " has been successfully deleted", 4000);
            vm.list.splice(index,1);

        }


        vm.copySuperhero = function(_item){

            vm.list.push(_item)
        }



        vm.addSuperHero = function(){
           if( vm.selected.id === ""){
               vm.selected.id = ++vm.tempId;
               vm.list.push(vm.selected);
               AlertService.add("success", vm.selected.name  + " has been successfully added", 4000);
               clearSelected();
           }else{
               var index = _.findIndex(vm.list, { 'id': vm.selected.id });
               vm.list[index] = vm.selected;
               AlertService.add("success", vm.selected.name  + " has been successfully updated", 4000);
           }
        }

    }

    return controllers;
});