define([ 'angular'

], function() {
	'use strict';

	var controllers = angular.module('app.Irs4562Controller', []).controller(
			'Irs4562Controller',
			[ '$rootScope', '$scope', '$http', 'GlobalService',
					'$uibModalInstance', 'ModalFactory', 'AlertService',
					'CodeComboFactory', 'rowData', 'JsonObjectFactory',
					'Irs4562Factory', irs4562Controller ]);

	function irs4562Controller($rootScope, $scope, $http, GlobalService,
			$uibModalInstance, ModalFactory, AlertService, CodeComboFactory,
			rowData, JsonObjectFactory, Irs4562Factory) {

		console.log("Inside 4562Controller");
		var vm = this;
		vm.title = "Irs4562 Adjustments";

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
		
		
	}// /////////////END CTRL

	return controllers;

});