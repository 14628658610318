define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.deatiledDiagnosticService',[])

        .factory("detailedDiagnosticFacoty",['$q', '$http', 'GlobalService',
        function($q, $http,GlobalService) {
            var detailedDiagFactory = {
                drillDownParams:{}
            };
            detailedDiagFactory.setSeltedDrillDownFilterValues=function(params){
                detailedDiagFactory['drillDownParams'] = params;
            }
            detailedDiagFactory.getSeltedDrillDownFilterValues=function(){
                if(!_.isEmpty(detailedDiagFactory.drillDownParams)){
                    return JSON.stringify(detailedDiagFactory.drillDownParams);

                }else{
                    return detailedDiagFactory.drillDownParams;
                }
            }
            return detailedDiagFactory;
        
        }]);


        return services;

    
    });