
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.ftcSyncConfigService',[])

        .factory("ftcSyncConfigFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, workspaceFactory, USER_SETTINGS, $rootScope) {
            var ftcSyncConfigFactory = {};           
            ftcSyncConfigFactory.url = {};
            ftcSyncConfigFactory.url.update_action = GENERAL_CONFIG.pdf_engine_api_url + '/updateFTCSyncConfigDetails'            
            
            ftcSyncConfigFactory.saveData =  function(_data,Details){
            	console.log("===============     Calling Service using ftcSyncConfigFactory  =========================", _data);
                
            	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
               


                params = _.merge({},  GlobalService.globalParams , params);
                params = _.extend({checkLockedbj:Details.checkLockedbj}, params);

                params = _.extend({sync_id:Details.SYNC_ID}, params);
                params = _.extend({sync_name:Details.SYNC_NAME}, params);
                params = _.extend({cron_exp:Details.CRON_EXP}, params);
                params = _.extend({is_active:Details.IS_ACTIVE}, params);
                params = _.extend({start_date_str:Details.START_DATE}, params);
                params = _.extend({end_date_str:Details.END_DATE}, params);

                // params.sync_id = Details.sync_id;
                // params.sync_name = Details.sync_name;
                // params.start_date = Details.start_date;
                // params.end_date = Details.end_date;
                // params.cron_exp = Details.cron_exp;
                // params.is_active = Details.is_active;

                //params =  _.extend({syncConfigDtls:jsonObj}, params);
                //params =  _.extend({clob_settings:jsonSettings}, params);
                //params =  _.extend({ind:'U'}, params);
                console.log("Save params :",params);
            	
                var promise = $http({
                    method: "post",
                    url: ftcSyncConfigFactory.url.update_action,
                    data: JSON.stringify(params),
                    headers: {'Content-Type': 'application/json'}
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };
            
            return ftcSyncConfigFactory;
        }])


    return services;


});