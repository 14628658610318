define([
    'angular'
], function () {
    'use strict';
    
    var controllers = angular.module('app.firLocalAcctDescController', [])
    .controller('firLocalAccDesctCtrl', ['GlobalService','$scope', '$rootScope', 'JsonObjectFactory', 'FirLocalAcctDescGroupObjFactory', '$parse', 'workspaceFactory', '$state', '$window',
			   'AlertService', 'GENERAL_CONFIG', 'ModalFactory', '$uibModalInstance', 'rowData', 'colData', 'gridData',firLocalAccDesctCtrl]);
    
    function firLocalAccDesctCtrl(GlobalService,$scope, $rootScope, JsonObjectFactory, FirLocalAcctDescGroupObjFactory, $parse, workspaceFactory, $state, $window,
			AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance, rowData, colData, gridData) {
    			
    			var vm = this;
    			vm.rowData = rowData;
    			console.log("rowData----", vm.rowData);
    			vm.firLcoalAcctDetail = {
    				LOCAL_ACCT : vm.rowData.LOCAL_ACCT,
    				LOCAL_ACCT_DESC : vm.rowData.LOCAL_ACCT_DESC,
    				LOCAL_ACCT_DESC_NEW : vm.rowData.LOCAL_ACCT_DESC
    			};
    			
    			//save function
    			
    			vm.save = function()
    			{
    				if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
			                return;
			            }
				
						$scope.crudLoading = true;
						var firLocalAcctDescDetails = [];
						var returnObj = {};
						//returnObj['type'] = 'E';
						returnObj["object_id"] = rowData.object_id;
						returnObj['local_acct_key'] = rowData.LOCAL_ACCT_KEY;
						returnObj['old_local_acct_desc'] = rowData.LOCAL_ACCT_DESC;
						returnObj["new_local_acct_desc"] = vm.firLcoalAcctDetail.LOCAL_ACCT_DESC_NEW;
						returnObj['group_object_key'] = rowData.GROUP_OBJ_KEY;
						returnObj['tax_year'] = rowData.TAX_YEAR;
						returnObj['source_system'] = 'F';
						firLocalAcctDescDetails.push(returnObj);
						var message = "Local Acct Desc has been successfully save/updated";
					//objSettings.push(returnObj);
					sendDetails(returnObj,firLocalAcctDescDetails,message)

					console.log(returnObj);
    			}
    			
    			function sendDetails(returnObj, firloaclacctdes, message) {
    				FirLocalAcctDescGroupObjFactory.saveFirLocalAcctDesc(returnObj, firloaclacctdes).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									 $scope.crudLoading = false;

								} else {
									AlertService.add("success", message, 4000);
									$uibModalInstance.dismiss('cancel');
									var args = {
											local_acct_key: returnObj.local_acct_key,
											sys_acct_key :  rowData.SYS_ACCT_KEY,
											chart_filter : rowData.LOCAL_CHART_KEY,
		                                    gridFilter: {
		                                    	local_acct_key: returnObj.local_acct_key,
		                                    	sys_acct_key :  rowData.SYS_ACCT_KEY,
		                                    	chart_filter : rowData.LOCAL_CHART_KEY
		                                    }
		                                };
		                                $rootScope.$emit('gridUpdate', args);
									/*vm.loadSystemAndLocalAccountData = angular
											.copy(loaclacctdes);*/
									// vm.init();

								}
							});

				}
    			
    			//reset function
    			vm.reset = function()
    			{
    				vm.firLcoalAcctDetail = {
    	    				LOCAL_ACCT : vm.rowData.LOCAL_ACCT,
    	    				LOCAL_ACCT_DESC : vm.rowData.LOCAL_ACCT_DESC,
    	    				LOCAL_ACCT_DESC_NEW : vm.rowData.LOCAL_ACCT_DESC
    	    			};
    			}
    			
    			//cancel function
    			vm.cancel = function()
    			{
    				$uibModalInstance.dismiss('cancel');
    			}
    			
			}
   });