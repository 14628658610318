define([
    'angular'

], function () {
    'use strict';


    var controllers = angular.module('app.form8082Controller', [])
        .controller('form8082Controller', ['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'JsonObjectFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', '$window', '$timeout', '$filter', form8082Controller])

    function form8082Controller($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, JsonObjectFactory, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, $window, $timeout, $filter) {
        var vm = this;
        vm.modalData = [];
        vm.disable = true;
        vm.loaded = false;
        vm.crudLoading = false;

        vm.baseURLs = {};

        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
        vm.rowData = JSON.stringify(rowData);
        vm.baseURLs = JSON.stringify(vm.baseURLs);
        vm.globalParams = JSON.stringify(GlobalService.globalParams);
        vm.isIssueKeyEnabled = GlobalService.inputs.tax_year.selected.is_issue_key_enabled;
        if (vm.isIssueKeyEnabled === undefined) {
            vm.isIssueKeyEnabled = GlobalService.globalParams.is_issue_key_enabled;
        }

        vm.issueKey = rowData.ISSUE_KEY;

        vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE;

        if (vm.isIssueKeyEnabled === undefined) {
            if (vm.scenarioCode !== undefined) {
                if (vm.scenarioCode === 'RAF') {
                    vm.isIssueKeyEnabled = 'N';
                }
                else {
                    vm.isIssueKeyEnabled = 'Y';
                }
            }
        }
        this.rowData = {
            rowData: this.rowData,
            isIssueKeyEnabled: this.isIssueKeyEnabled,
            issueKey: this.issueKey,
            scenarioCode: this.scenarioCode,
            baseURLs : this.baseURLs,
            globalParams : this.globalParams,
            sso_id: USER_SETTINGS.user.sso_id,
            
        }

        vm.loaded = true;
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
        if ($uibModalInstance) {
            $timeout(() => {
                $window.document.querySelector('gtw-form-8082')
                    .addEventListener('request-grid-update', (event) => {
                        if (event.detail.type == 'save-success') {
                            $rootScope.$broadcast('dataFilters:refreshGrid', {
                                "refresh": true
                            });
                            console.log("There was no error message and the message was ", event.detail.message);
                            AlertService.add("success", event.detail.message, 4000);
                            $uibModalInstance.dismiss('cancel');
                            var args = {};
                            $rootScope.$emit('gridUpdate', args);
                        }
                        else if(event.detail.type == 'save-error'){
                            console.log("There was an error message and the message was ", event.detail.errorMessage);
                            AlertService.add("error", event.detail.message);
                        }
                        else {
                            $uibModalInstance.dismiss('cancel');
                        }

                    });
            }, 500);
        }

    }

    return controllers;

});