const { filter } = require("lodash");

define(
    [ 'angular'

    ],
    function() {
        'use strict';
        var controllers = angular.module('app.F1118HPIIAttachSchdCtrl', [])
                .controller(
                        'f1118HPIIAttachSchedule',
                        [ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'GlobalService', 'AlertService','$timeout','workspaceFactory', 'USER_SETTINGS', 'GENERAL_CONFIG','$uibModal',
                            '$uibModalInstance', 'gridData', f1118HPIIAttachSchedule ])

        /***************************************
         * 1118 SCHD HPII ATTACHMENT DETAILS SCREEN
         * *************************************/
        function f1118HPIIAttachSchedule($rootScope, $scope, $state, $log,
            JsonObjectFactory, GlobalService, AlertService, $timeout, workspaceFactory, USER_SETTINGS, GENERAL_CONFIG,$uibModal,
            $uibModalInstance, gridData) {

                var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                vm.taxYear = filterParams.tax_year;
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;
                vm.filerEntityKey = filterParams.filer_entity_key;
                vm.gridDataCloned;
                vm.resultJson = [];
                vm.loadingData = false;
                vm.generalInfo = {};
                //Current screen's activity name
				vm.activity_name = workspaceFactory.activity.label;

                if (gridData != null) {
					vm.gridDataCloned = _.clone(gridData);
                    if(vm.gridDataCloned.colData){
						var rowData = vm.gridDataCloned.rowData; 
                        vm.generalInfo.basketName = vm.activity_name;
                        vm.generalInfo.schdName = workspaceFactory.activeScreen.label;
                        vm.generalInfo.lineNumber = rowData.ELEMENT_NAME.split("-")[0];
                    }
                   console.log("active_screen" + workspaceFactory.activeScreen.label);
				}

                vm.getAltGIESplitupAmt = function() {
                    vm.loadingData = true;
					if(vm.gridDataCloned.colData){
						var rowData = vm.gridDataCloned.rowData; 
                        console.log(vm.gridDataCloned.colData);
                        var basketKey = rowData.BASKET_KEY;
                        // for now used in schd a only. If any other schd is included, 
                        //workspaceFactory.activeScreen.label can be used to configure
                        var schdName = 'F1118_HPII';
                        var lineNumber = rowData.ELEMENT_NAME.split("-")[0].trim();
                        var colNumber = vm.gridDataCloned.colData.map.split("_")[0];
                        vm.generalInfo.colNumber = colNumber;

                var params = {
                        "action_code" : 'yb1qae',
                        "tax_year" : vm.tax_year,
                        "scenario" : vm.scenario,
                        "jcd_key" : vm.jcd_key,
                        "filer_entity_key" : vm.filerEntityKey,
                        "basket_key" : basketKey,
                        "schd_name" : schdName,
                        "line_number" : lineNumber,
                        "col_number" : colNumber
                    };
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                        vm.resultJson = [];
                        vm.loadingData = false;
                        if (data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                            AlertService.add("error", data.errorMessage);
                            return false;
                        } else {
                            vm.resultJson = data.jsonObject;
                            var amtWithoutComma;
                            vm.resultJson.forEach(function (item) {
                                amtWithoutComma = item.AMOUNT;
                                amtWithoutComma = parseInt(amtWithoutComma.replace(/,/g, ''));
                                console.log(amtWithoutComma);
                                if (amtWithoutComma < 0) {
                                    item.AMOUNT_FORMATTED = item.AMOUNT.replace('-','(') + ')';
                                }
                                else {
                                    item.AMOUNT_FORMATTED = item.AMOUNT;
                                }
                            });
                        }
                    });
					
				}
            };
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
            vm.getAltGIESplitupAmt();
        }
        return controllers;
    });