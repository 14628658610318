define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.sltDataserviceRequestController', [])
    .controller('sltDataserviceRequestController', ['$rootScope', '$scope',
        '$timeout', '$uibModal', 'USER_SETTINGS', 'sltDataRequestFactory', 'GENERAL_CONFIG', 'GlobalService', '$uibModalInstance',
        'rowData', 'ObjectDiff', sltDataserviceRequestController])

    function sltDataserviceRequestController($rootScope, $scope, $timeout, $uibModal, USER_SETTINGS,
        sltDataRequestFactory, GENERAL_CONFIG, GlobalService, $uibModalInstance, rowData, ObjectDiff) {
        
        var vm = this;
        vm.row = rowData;
        vm.title = 'Data Service Request';

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.getRequestJson = function(){
            vm.loading = true;
            return sltDataRequestFactory.getRequestJson(vm.row.LOG_ID).then(function (data) {
                if(data.callSuccess == "1" ){
                    vm.requestJson = ObjectDiff.objToJsonView(JSON.parse(data.jsonObject[0].REQUEST));
                    vm.loading = false;
                }else{
                    vm.loading = false;
                    AlertService.add("error", data.data.message, 4000);
                }
            });
        };
        vm.getRequestJson();
    }

    return controllers;
});
