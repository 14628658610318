
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.ruleEngineConfigService',[])

        .factory("RuleEngineConfigFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory) {
            var ruleEngineConfigFactory = {};
            var URLS = {
                getRuleByKeyTemplateJson: 'app/json/rule_engine_config_sidd.json',
                getRuleByKey: GENERAL_CONFIG.base_url + '/xxxx',
                getRulesAllData: GENERAL_CONFIG.common_api_url + '/getDroolsRulesConfigDataByClient',
                genericActionUrl: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code='
            }
           
            ruleEngineConfigFactory.getRulesData = function(data){
            	console.log("Request Payload ::",data);
            	var promise = $http({
                    method: "GET",
                    url: URLS.getRuleByKey,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            }
            
            ruleEngineConfigFactory.getRulesAllData = function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.getRulesAllData,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            }
            
            ruleEngineConfigFactory.linkDelinkRuleAction = function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.genericActionUrl+data.url,
                    data: data
                }).then(function (response) {
                	if(response && response.data && response.data.jsonObject){
                		response.data.jsonObject = JsonObjectFactory.arrObjToLower(response.data.jsonObject);
                	}
                    return response.data;
                });
            	
            	return promise;
            }
            
            return ruleEngineConfigFactory;
        }])

    return services;
});