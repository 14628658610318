define([
    'angular'
], function () {
    'use strict';
    angular.module('app.DataPushToEfileController', [])
        .controller('DataPushToEfileCtrl', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'PushToEfileFactory', 'AlertService', 'EfileFactory', DataPushToEfileCtrl]);

    function DataPushToEfileCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, PushToEfileFactory, AlertService, EfileFactory) {
        var vm = this;

        vm.taxYears = [];
        vm.dcnNames = [];
        vm.taxPayers = [];
        vm.selectedTaxYear = '';
        vm.selectedDcnName = '';
        vm.selectedTaxPayer = '';
        vm.pushingDataToEfile = false;

        vm.changeTaxYear = function () {
            vm.dcnNames = [];
            vm.taxPayers = [];
            vm.loadDcnName();
        };

        vm.changeDcnName = function () {
            vm.loadTaxPayers();
        };

        vm.loadTaxyears = function () {
            PushToEfileFactory.getDstEfilePushTaxYears().then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        vm.taxYears = response.data.jsonObject;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            );
        }

        vm.loadDcnName = function () {
            PushToEfileFactory.getDstEfilePushDcns(vm.selectedTaxYear.id).then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        vm.dcnNames = response.data.jsonObject;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            );
        }

        vm.loadTaxPayers = function () {
            PushToEfileFactory.getDstEfilePushTaxPayers(vm.selectedTaxYear.id, vm.selectedDcnName.id).then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        vm.taxPayers = response.data.jsonObject;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            );
        }

        vm.pushDataToEfile = function () {
            if (!vm.selectedTaxYear.text || !vm.selectedDcnName.id || !vm.selectedTaxPayer.id) {
                return;
            }
            vm.pushingDataToEfile = true;
            PushToEfileFactory.pushDstDataToEfile(vm.selectedTaxYear.text, vm.selectedDcnName.id, vm.selectedTaxPayer.id).then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        AlertService.add('success', 'Data Pushed to Efile Successfully!', 4000);
                        vm.pushingDataToEfile = false;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', err.message === undefined ?'Something went wrong, please try again!' : err.message);
                    vm.pushingDataToEfile = false;
                }
            );
        }

        vm.pushPDF = function () {
            if (!vm.selectedTaxYear.text || !vm.selectedDcnName.id || !vm.selectedTaxPayer.id) {
                return;
            }
            vm.showPDFPush = true;
            vm.loadingForms = true;
            PushToEfileFactory.getPDFPushData(vm.selectedTaxYear.text, vm.selectedDcnName.id, vm.selectedTaxPayer.id).then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        vm.formsList = response.data.jsonObject;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    AlertService.add('error', 'Something went wrong while loading the pdf forms, please try again!');
                }
                )
                .finally(() => {
                    vm.loadingForms = false;
                }
            );
        };

        vm.goBack = function () {
            vm.formsList = [];
            vm.showPDFPush = false;
        }

        vm.uploadPdfToEfile = function (rowData) {
            if(!rowData.ATTACHMENT_NAME && !rowData.ATTACHMENT_NAME_EDIT) {
                AlertService.add('error', "Please enter file name before uploading!");
                return;
            }
            rowData.uploadingPdfToEfile = true;
            PushToEfileFactory.uploadPdfToEfile(vm.selectedTaxYear.text, vm.selectedDcnName.id, vm.selectedTaxPayer.id
                , rowData.FORM_ID, rowData.ATTACHMENT_NAME || rowData.ATTACHMENT_NAME_EDIT).then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        AlertService.add('success', 'PDF Uploaded to Efile Successfully!', 4000);
                        rowData.uploadingPdfToEfile = false;
                        vm.pushPDF();
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', err.message === undefined ?'Something went wrong, please try again!' : err.message);
                    rowData.uploadingPdfToEfile = false;
                }
            );
        }
        
        vm.viewPdf = function (rowData) {
            EfileFactory.viewPdf(vm.selectedTaxPayer.id, rowData.ATTACHMENT_NAME).then((res) => {
                var file = new Blob([res.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL,"_blank", 'toolbar=0,location=0,menubar=0,height='+window.outerHeight);
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
            });
        }

        vm.deleteForm = function (rowData) {
            rowData.deletingPdf = true;
            PushToEfileFactory.deleteForm(vm.selectedTaxPayer.id, rowData.ATTACHMENT_NAME, rowData.FORM_SUB, vm.selectedTaxYear.text).then(
                (data) => {
                    if (data && data.callSuccess === '1') {
                        AlertService.add('success', 'Form Deleted Successfully!', 4000);
                        rowData.deletingPdf = false;
                        vm.pushPDF();
                    } else {
                        throw new Error(data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', err.message === undefined ?'Something went wrong, please try again!' : err.message);
                    rowData.deletingPdf = false;
                }
            );
        }

        vm.loadTaxyears();

    }
}
);
