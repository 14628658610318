define([
    'angular',
    './QreCodeCombinationController',
    './QreCodeCombinationService'



], function () {
    'use strict';
    return angular.module('app.qreCodeCombination', ['app.qreCodeCombinationController','app.qreCodeCombinationService'] );


});