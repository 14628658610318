define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.submissionSummaryCtrl', [])
        .controller('submissionSummaryController', [submissionSummaryController]);

    function submissionSummaryController() {
    var vm= this;
    vm.sub_sum_data= [
        {"acc_no": "980100001", "acc_des": "ProvCurrFedIncTx-CYProv", "curr_gaap": "11,233", "gaap_reclass": "11,231", "curr_gaap_tax": "23,231", "ep_adj": "0", "curr_ep": "44,040", "curr_ep_tax": "44,040,333", "pri_year": "4,040,223" },
        {"acc_no": "980100002", "acc_des": "ProvCurrFedIncTx-CYAdjPrProv", "curr_gaap": "22,223", "gaap_reclass": "11,343,222", "curr_gaap_tax": "21,222", "ep_adj": "0", "curr_ep": "22,111", "curr_ep_tax": "33,232", "pri_year": "0" },
        {"acc_no": "980100003", "acc_des": "ProvCurrFedIncTx-CYReserves", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980200002", "acc_des": "ProvCurFedIncTx-FSource-PrProv", "curr_gaap": "76,776,323", "gaap_reclass": "0", "curr_gaap_tax": "-44,009,002", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "-33,456,776" },
        {"acc_no": "980400001", "acc_des": "ProvCurFedIncTx-FAffNonFSCCYPr", "curr_gaap": "0", "gaap_reclass": "76,776,323", "curr_gaap_tax": "-44,009,002", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980400002", "acc_des": "ProvCurrFedIncTx-FAffNonFSCCYA", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "-44,009,002", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981100001", "acc_des": "ProvDefFedIncTx-CYProv", "curr_gaap": "76,776,323", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "-33,456,776" },
        {"acc_no": "981100002", "acc_des": "ProvDefFedIncTx-CYAdjPrProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "76,776,323" },
        {"acc_no": "981100003", "acc_des": "ProvDefFedIncTx-CYReserves", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981400001", "acc_des": "ProvDefFedIncTx-FAffNonFSCCYPr", "curr_gaap": "33,676", "gaap_reclass": "0", "curr_gaap_tax": "98,456", "ep_adj": "0", "curr_ep": "-33,456,776", "curr_ep_tax": "44,009,991", "pri_year": "0" },
        {"acc_no": "981400002", "acc_des": "ProvDefFedIncTx-FAffNonFSCCYAd", "curr_gaap": "33,676", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "44,009,991", "pri_year": "0" },
        {"acc_no": "984100001", "acc_des": "ProvDefFedIncTax-For NonCon JV", "curr_gaap": "33,676", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "44,009,991", "pri_year": "0" },
        {"acc_no": "980100001", "acc_des": "ProvCurrFedIncTx-CYProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "-33,456,776", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "76,776,323" },
        {"acc_no": "980100002", "acc_des": "ProvCurrFedIncTx-CYAdjPrProv", "curr_gaap": "98,456", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "76,776,323", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980100003", "acc_des": "ProvCurrFedIncTx-CYReserves", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "98,456", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980200002", "acc_des": "ProvCurFedIncTx-FSource-PrProv", "curr_gaap": "45,678,999", "gaap_reclass": "48,334", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "44,232", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980400001", "acc_des": "ProvCurFedIncTx-FAffNonFSCCYPr", "curr_gaap": "45,678,999", "gaap_reclass": "48,334", "curr_gaap_tax": "76,776,323", "ep_adj": "0", "curr_ep": "44,232", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980400002", "acc_des": "ProvCurrFedIncTx-FAffNonFSCCYA", "curr_gaap": "45,678,999", "gaap_reclass": "48,334", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "44,232", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981100001", "acc_des": "ProvDefFedIncTx-CYProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "-33,456,776", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981100002", "acc_des": "ProvDefFedIncTx-CYAdjPrProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981100003", "acc_des": "ProvDefFedIncTx-CYReserves", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981400001", "acc_des": "ProvDefFedIncTx-FAffNonFSCCYPr", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "2,332,443", "ep_adj": "0", "curr_ep": "98,456", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981400002", "acc_des": "ProvDefFedIncTx-FAffNonFSCCYAd", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "2,332,443", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "984100001", "acc_des": "ProvDefFedIncTax-For NonCon JV", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "2,332,443", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980100001", "acc_des": "ProvCurrFedIncTx-CYProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980100002", "acc_des": "ProvCurrFedIncTx-CYAdjPrProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "76,776,323", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980100003", "acc_des": "ProvCurrFedIncTx-CYReserves", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "980200002", "acc_des": "ProvCurFedIncTx-FSource-PrProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "90,999", "ep_adj": "0", "curr_ep": "98,456", "curr_ep_tax": "-23,333", "pri_year": "0" },
        {"acc_no": "980400001", "acc_des": "ProvCurFedIncTx-FAffNonFSCCYPr", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "90,999", "ep_adj": "", "curr_ep": "0", "curr_ep_tax": "-23,333", "pri_year": "0" },
        {"acc_no": "980400002", "acc_des": "ProvCurrFedIncTx-FAffNonFSCCYA", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "90,999", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "-23,333", "pri_year": "0" },
        {"acc_no": "981100001", "acc_des": "ProvDefFedIncTx-CYProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981100002", "acc_des": "ProvDefFedIncTx-CYAdjPrProv", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981100003", "acc_des": "ProvDefFedIncTx-CYReserves", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "0", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981400001", "acc_des": "ProvDefFedIncTx-FAffNonFSCCYPr", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "-4,343", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "981400002", "acc_des": "ProvDefFedIncTx-FAffNonFSCCYAd", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "-4,343", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
        {"acc_no": "984100001", "acc_des": "ProvDefFedIncTax-For NonCon JV", "curr_gaap": "0", "gaap_reclass": "0", "curr_gaap_tax": "-4,343", "ep_adj": "0", "curr_ep": "0", "curr_ep_tax": "0", "pri_year": "0" },
]
    
    }
});