define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.cbcReconciliationController', [])
        .controller('cbcAddFsElimDataController', ['cbcReconciliationFactory', '$uibModalInstance', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', cbcAddFsElimDataController])
        .controller('cbcAddRecDataController', ['cbcReconciliationFactory', '$uibModalInstance', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', cbcAddRecDataController])
        .controller('cbcDeleteVarianceDataController', ['cbcReconciliationFactory', '$uibModalInstance', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', 'gridData', cbcDeleteVarianceDataController])
        .directive('format', ['$filter', function ($filter) {
            return {
                require: '?ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    if (!ctrl) return;

                    ctrl.$formatters.unshift(function (a) {
                        return $filter(attrs.format)(ctrl.$modelValue)
                    });

                    elem.bind('blur', function (event) {
                        var plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, '');
                        elem.val($filter(attrs.format)(plainNumber));
                    });
                }
            };
        }]);
    function cbcAddFsElimDataController(cbcReconciliationFactory, $uibModalInstance, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory) {
        var vm = this;
        vm.title = 'Financial Statements - Add/Edit Entry'
        vm.ME = [];
        vm.amountTypeOptions = [{
            value: 0,
            name: ''
        },
        {
            value: 100,
            name: 'Hundreds'
        },
        {
            value: 1000,
            name: 'Thousands'
        },
        {
            value: 1000000,
            name: 'Millions'
        }]
        vm.amountTypeSelected = false;
        vm.selectedAmountType = 0;
        vm.fsAccounts = []
        vm.meSelected = false;
        vm.fsAcc = false;
        vm.onMESelect = function () {
            vm.meSelected = true;
            if (vm.fsAcc) {
                cbcReconciliationFactory.getEntryData(vm.selectedME, vm.selectedFSAcc, null, null).then(function (response) {
                    if (response.length > 0) {
                        vm.amount1 = response[0].AMOUNT_1204;
                        vm.amount2 = response[0].AMOUNT_1205;
                        vm.amount3 = response[0].AMOUNT_1206;
                    }
                    else {
                        vm.amount1 = vm.amount2 = vm.amount3 = 0;
                    }
                })
            }
        }

        cbcReconciliationFactory.getMEData().then(function (response) {
            if (response.length) {
            vm.ME = response;
            var filters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var me_key_array = filters.parent_me_string.split(',');
                if (me_key_array.length !== vm.ME.length) {
                    vm.ME.forEach(element => {
                        for (let index = 0; index < me_key_array.length; index++) {
                            if (element.ME_KEY + '' === me_key_array[index]) {
                                vm.selectedME = element.ME_CODE;
                                return;
                            }
                        }
                        return;
                    });
                }
                else {
            var me_key = me_key_array.length > 1 ? me_key_array[1] : me_key_array[0];
                    var findME = vm.ME.find(elem => elem.ME_KEY + '' === me_key);
                    if (findME) {
            vm.selectedME = vm.ME.find(elem => elem.ME_KEY + '' === me_key).ME_CODE;
                    }
                    else if(vm.ME.length) {
                        vm.selectedME = vm.ME.ME_CODE;
                    }
                }

            vm.onMESelect();
            cbcReconciliationFactory.getFSAccounts().then(function (response) {
                vm.fsAccounts = response;
                vm.selectedFSAcc = response[0].ACCT;
                vm.onFSAccountSelect();
            });
            }

        });

        vm.amountTypeChange = function () {
            vm.amountTypeSelected = true;
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.onFSAccountSelect = function () {
            vm.fsAcc = true;
            cbcReconciliationFactory.getEntryData(vm.selectedME, vm.selectedFSAcc, null, null).then(function (response) {
                if (response.length > 0) {
                    vm.amount1 = response[0].AMOUNT_1204;
                    vm.amount2 = response[0].AMOUNT_1205;
                    vm.amount3 = response[0].AMOUNT_1206;
                }
                else {
                    vm.amount1 = vm.amount2 = vm.amount3 = 0;
                }
            })
        }

        vm.save = function () {
            var clobData = [];
            clobData.push({
                ME_CODE: vm.selectedME,
                ACCT: vm.selectedFSAcc,
                AMOUNT_1204: vm.amountTypeSelected ? vm.amount1 * vm.selectedAmountType : vm.amount1,
                AMOUNT_1205: vm.amount2,
                AMOUNT_1206: vm.amount3,
                AMOUNT_1207: null,
                COMMENTS: null,
                CATEGORY: null,
                VARIANCE_TYPE: null,
                POPUP_KEY: 1
            })
            cbcReconciliationFactory.saveData(clobData).then(function (response) {
                console.log(response);
            });
        }
    }
    function cbcAddRecDataController(cbcReconciliationFactory, $uibModalInstance, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory) {
        var vm = this;
        vm.title = "Reconciling Item - Add/Edit Entry";

        vm.variancetype = {};
        vm.variancetype.selected = [];
        vm.variancetype.name = "types";
        vm.variancetype.values = [];
        vm.dropdownSettings = {
            selectionLimit: 1,
            enableSearch:true,
            displayProp: 'name',
            idProperty: 'value',
            scrollable: true,
            closeOnSelect:true,
            smartButtonTextProvider(selectionArray) 
            { 
                return selectionArray[0].value; 
            }
        };
        vm.dropdownEvents = {
            onSelectionChanged:function(){
                vm.onVarianceTypeSelect()
            }
        };

        vm.meSelected = false;
        vm.fsAcc = false;
        vm.TypeSel = false;
        cbcReconciliationFactory.getMEData().then(function (response) {
            if(response.length) {
            vm.ME = response;
            var filters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var me_key_array = filters.parent_me_string.split(',');
                if (me_key_array.length !== vm.ME.length) {
                    vm.ME.forEach(element => {
                        for (let index = 0; index < me_key_array.length; index++) {
                            if (element.ME_KEY + '' === me_key_array[index]) {
                                vm.selectedME = element.ME_CODE;
                                return;
                            }
                        }
                        return;
                    });
                }
                else {
            var me_key = me_key_array.length > 1 ? me_key_array[1] : me_key_array[0];
                    var findME = vm.ME.find(elem => elem.ME_KEY + '' === me_key);
                    if (findME) {
            vm.selectedME = vm.ME.find(elem => elem.ME_KEY + '' === me_key).ME_CODE;
                    }
                    else if(vm.ME.length) {
                        vm.selectedME = vm.ME.ME_CODE;
                    }
                }
            vm.onMESelect();
            vm.loadVarianceTypes();
            }
            
        });

        vm.onFSAccountSelect = function () {
            vm.fsAcc = true;
            if (vm.TypeSel && vm.fsAcc && vm.meSelected) {
                cbcReconciliationFactory.getEntryData(vm.selectedME, vm.selectedFSAcc, vm.variancetype.selected[0].value).then(function (response) {
                    if (response.length > 0) {
                        vm.comments = response[0].COMMENTS;
                        vm.amount1 = response[0].AMOUNT_1207;
                    } else {
                        vm.comments = '';
                        vm.amount1 = 0;
                    }
                })
            }
        }
        vm.loadVarianceTypes = function () {
            cbcReconciliationFactory.getVarianceType().then(function (response) {
                console.log(response);
                vm.variancetype.values = response.map((value) => { return { 'name': value.VARIANCE_TYPE, 'value': value.VARIANCE_TYPE } });;
                vm.variancetype.selected = [];
                vm.variancetype.selected.push(vm.variancetype.values[0]);
                vm.onVarianceTypeSelect();
                cbcReconciliationFactory.reconcFSAccounts().then(function (response) {
                    console.log(response);
                    vm.fsAccounts = response;
                    vm.selectedFSAcc = response[0].ACCT;
                    vm.onFSAccountSelect();
                });
            })
        }
        vm.onVarianceTypeSelect = function () {
            vm.TypeSel = true;
            if (vm.TypeSel && vm.fsAcc && vm.meSelected) {
            cbcReconciliationFactory.getEntryData(vm.selectedME, vm.selectedFSAcc, vm.variancetype.selected[0].value).then(function (response) {
                if (response.length > 0) {
                    vm.comments = response[0].COMMENTS;
                    vm.amount1 = response[0].AMOUNT_1207;
                } else {
                    vm.comments = '';
                    vm.amount1 = 0;
                }
            })
            }
        }
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.onMESelect = function () {
            vm.meSelected = true;
            if (vm.TypeSel && vm.fsAcc && vm.meSelected) {
                cbcReconciliationFactory.getEntryData(vm.selectedME, vm.selectedFSAcc, vm.variancetype.selected[0].value).then(function (response) {
                    if (response.length > 0) {
                        vm.comments = response[0].COMMENTS;
                        vm.amount1 = response[0].AMOUNT_1207;
                    } else {
                        vm.comments = '';
                        vm.amount1 = 0;
                    }
                })
            }

        }

        vm.save = function () {
            var clobData = [];
            clobData.push({
                ME_CODE: vm.selectedME,
                ACCT: vm.selectedFSAcc,
                AMOUNT_1204: null,
                AMOUNT_1205: null,
                AMOUNT_1206: null,
                AMOUNT_1207: vm.amount1,
                COMMENTS: vm.comments,
                VARIANCE_TYPE: vm.variancetype.selected[0].value,
                POPUP_KEY: 2
            })
            cbcReconciliationFactory.saveData(clobData).then(function (response) {
                console.log(response);
            });
        }

    }
    function cbcDeleteVarianceDataController(cbcReconciliationFactory, $uibModalInstance, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, gridData) {
        var vm = this;
        console.log(gridData);
        vm.ids = "" + gridData[0].data.VARIANCE_KEY;
        if (gridData.length > 1) {

            for (var i = 1; i < gridData.length; i++) {
                vm.ids += "," + gridData[i].data.VARIANCE_KEY;
            }
        }

        vm.delete = function () {
            cbcReconciliationFactory.deleteUserVariance(vm.ids).then(function (response) {
                console.log(response);
                $uibModalInstance.dismiss('cancel');
            });
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    return controllers;

});
