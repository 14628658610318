define([
    'angular',
    './reviewAdjController',
    './reviewAdjService'



], function () {
    'use strict';
    return angular.module('app.reviewAdjAutomation', ['app.reviewadjController','app.reviewadjService'] );


});