/**
 * Created by 212544474 on 12/2/2015.
 */


define([
    'angular'

], function () {
    'use strict';

    var directives =  angular.module('app.dataFiltersDirective',[])


        .directive('filterDirectives',['$compile', '$interpolate' , function($compile, $interpolate) {
            return {
                template: '',
                link: function($scope, element, attributes) {
                    console.log("attributes",attributes.filterObj)
                    element.replaceWith($compile('<div ' + attributes.filterDirectives + ' filter="'+attributes.filterObj+'"></div>')($scope));
                }
            };
        }])

        .directive('clickOutSide',['$document','$timeout', function($document,$timeout){
            return {
                restrict: 'AE',
                require: '^dfMeDropDown',
                scope: {
                    showMenu: '='
                },

                replace: true,
                link: function(scope, element, attr, ctrl){
                    $document.bind('click', checkShowHide);
                    function checkShowHide(event){
                        var isClickedElementChildOfPopup = element.find(event.target).length > 0;
                        if (isClickedElementChildOfPopup ){
                            return;
                        }else if( ctrl.showMenu === true ){
                            ctrl.showMenu = false;
                            scope.$apply();
                        }
                    }
                    scope.$on('$destroy', function () {
                        $($document).unbind('click',checkShowHide);
                    });
                }
            };
        }])

        .directive('dfMeDropDown', ['$window','$log', 'DataFiltersFactory','JsonObjectFactory','AlertService', 'workspaceFactory', function($window, $log, DataFiltersFactory,JsonObjectFactory,AlertService,workspaceFactory) {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                templateUrl: 'app/components/dataFilters/templates/me-drop-tlp.html',
                scope: {
                    filter: '='
                },
                controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                    var vm = this;
                    vm.menuObj = [];
                    vm.childMenuObj = [];
                    vm.totalSelected = 0;
                    vm.selected  = [];
                    vm.selectedArray  = [];
                    vm.showMenu = false;
                    vm.showRightMenu = false;


                    var applyFilter = function (filters,selectedList) {
                        filters = _.each(filters, function (itemObj) {
                            var iterateChildren = function (item) {
                                var selectedChildCount=0;
                                _.each(item, function (value) {
                                    value.active = selectedList.indexOf(value.key) > -1 ? true : value.KEY ? selectedList.indexOf(value.key.toString()) > -1 :
                                    selectedList.indexOf(value.mekey.toString()) > -1 ? true : false;
                                    value.selected = value.active;
                                    value.check = value.active;
                                    if(value.selected){
                                        selectedChildCount++;
                                    }
                                    if (value.children && value.children.length > 0) {
                                        value.selected = iterateChildren(value.children)===value.children.length;
                                        value.check =value.selected;
                                        value.active  = value.selected;
                                    }
                                });
                                return selectedChildCount;
                            }
                            if (itemObj.children && itemObj.children.length > 0) {
                                itemObj.selected = iterateChildren(itemObj.children)===itemObj.children.length;
                                itemObj.check = itemObj.selected;
                                itemObj.active  = itemObj.selected;
                            }else{
                                // Converting (itemObj) to array, since iterateChildren method is expecting array of object.
                                var convParentToArr = [];
                                convParentToArr.push(itemObj)
                                iterateChildren(convParentToArr);
                            }
                        });
                        return filters;
                    }
                    $scope.$on('leftMenuMes', function(event, data) {
                  //   if(DataFiltersFactory.globalMe){
                        //vm.menuObj = data.values;
                        setTimeout(function(){
                        applyFilter(vm.menuObj,_.map(data.selected,'parnet'));

                        },50)
                        $scope.filter.selected = data.selected;
                        vm.selected = data.selected;
                        vm.selectedArray =data.selected;
                        getTotalSelected();
//                     }

                    });

                    $scope.$watch('filter.values', function(newVal, oldVal){
                        if( typeof newVal !== "undefined" && newVal !== null){
                            reset();
                            setTreeParent(newVal,'children' );
                            vm.menuObj = newVal;
                        }
                        getTotalSelected();
                    });
                    $scope.$watch('filter.selected', function (newVal, oldVal) {
                        vm.selected = newVal;
                        vm.selectedArray = newVal;
                        /* if (vm.selected && vm.selected.length && vm.selected.length > 0) {
                            iterateChildren($scope.filter.values);
                        } */
                    }, true);



                    function setTreeParent(_collection,_children){
                        for (var i = 0; i < _collection.length; i++) {
                            var thisChildren =  _collection[i][_children];
                            if( thisChildren && thisChildren.length ){
                                setTreeParent(thisChildren,_children);
                            }

                            for(var n in thisChildren){
                                thisChildren[n].parent = _collection[i];
                            }
                        }
                    }



                    function reset(){
                        vm.totalSelected = 0;
                        vm.childMenuObj = [];
                        vm.selected  = [];
                        vm.selectedArray  = [];
                        vm.showMenu = false;
                        vm.showRightMenu = false;
                    }




                    vm.getScrollHeight = function(){
                        var copyRightFooterHeight = document.getElementsByClassName('copyright')[0] ? document.getElementsByClassName('copyright')[0].clientHeight 
                        :document.getElementsByClassName('cookieAcceptance')[0]? document.getElementsByClassName('cookieAcceptance')[0].clientHeight 
                        : 50;
                        if(angular.element('.gt-nav').length>0){
                            var top = angular.element('.gt-nav')[0].getBoundingClientRect().top;
                            return $window.innerHeight - top;
                        }
                        // if(document.getElementsByClassName('main-panel')[0]!=undefined){
                        //     return window.innerHeight- document.getElementsByClassName('main-panel')[0].getBoundingClientRect().bottom + copyRightFooterHeight;

                        // }
                        // if(copyRightFooterHeight == 0){
                        //     return $window.innerHeight - 88;
                        //  }else{
                            return $window.innerHeight - 98 - copyRightFooterHeight;
                       // }
                    }


                    vm.change = function () {
                        //HERE WE WILL CHECK FOR SPLIT CALL
                      if( workspaceFactory.activeScreen.split_call_param ){
                          $scope.filter.selected = splitProcessArray(vm.menuObj);
                      }else{
                          createdSelectedArray();
                          $scope.filter.selected = vm.selectedArray;
                      }
                        DataFiltersFactory.setGlobalMeVar(false);
                        DataFiltersFactory.onChange($scope.filter);
                    }






                    function splitProcessArray(_collection){
                        var selectedArray = [];
                        var mergedArray = [];


                      //LOOP OVER MENU
                        for (var i = 0; i < _collection.length; i++) {
                            if(_collection[i].selected){
                                mergedArray.push( _collection[i].key);
                            }
                            if(_collection[i].children && _collection[i].children.length){
                                var items = buildSelection( _collection[i].children)
                                if(items.length ){
                                    $.merge(mergedArray, items);
                                }
                            }
                        }

                       function buildSelection(_collection){
                            var selected = [];
                           //console.log("buildSelection: " , _collection);
                           for (var i = 0; i < _collection.length; i++) {
                               if(_collection[i].children &&  _collection[i].children.length){
                                   var children = getSelectedChildren(_collection[i].children);
                                   if(children.length){
                                       if (_collection[i].selected) {children.unshift(_collection[i].key);}
                                       selected.push(children.toString());
                                   }
                               }else{
                                   if (_collection[i].selected) {
                                       selected.push(_collection[i].key);
                                   }
                               }
                           }

                           function getSelectedChildren(_collection){
                               var selectedG = [];
                               for (var i = 0; i < _collection.length; i++) {
                                    if(_collection[i].children &&  _collection[i].children.length){
                                        var subSelect = getSelectedChildren(_collection[i].children);
                                        if (_collection[i].selected) {subSelect.unshift(_collection[i].key);}
                                        if(subSelect.length ){
                                            selectedG.push(subSelect);
                                        }
                                    }else{
                                        if(_collection[i].selected ){
                                            selectedG.push(_collection[i].key);
                                        }
                                    }
                               }
                                   return selectedG;
                           }

                           return selected;
                       }

                        return mergedArray;
                    }




                    function createdSelectedArray(){
                        vm.selectedArray  = [];
                        for (var i = 0; i < vm.selected.length; i++) {
                            var newObj = {}
                            newObj[$scope.filter.value_name] = vm.selected[i];
                            newObj.parnet = vm.selected[i];
                            newObj.level = vm.selected[i];
                            //console.log("vm.selected[i] :" ,  vm.selected[i] );
                            //console.log("$scope.filter.value_name] : " ,  $scope.filter.value_name );
                            vm.selectedArray.push(newObj);
                        }
                    }



                    var setTreeAttribute = function(_collection,_predicate,_children,value){
                        for (var i = 0; i < _collection.length; i++) {
                            var  n = _collection[i][_predicate];
                            var children = _collection[i][_children];
                            if(children && children.length ){
                                setTreeAttribute(children,_predicate,_children,value );
                            }
                            _collection[i][_predicate] = value;
                        }
                    }


                    vm.selectParentMe = function(item){
                        setTreeAttribute(vm.menuObj,'active','children',false);
                        item.active = true;
                        vm.childMenuObj = item.children;
                        vm.showRightMenu = true;
                    };

                    vm.toggleMenu = function(){
                        vm.showMenu = !vm.showMenu;
                        if(!vm.showMenu ){
                            vm.showRightMenu = false;
                        }
                    }

                    vm.selectAll = function(){
                        setTreeAttribute(vm.menuObj,'selected','children',true);
                        getSelectedAll();
                        vm.change();

                    }

                    function getSelectedAll(){
                        vm.selected  = [];
                        var selected = 0;

                        _.each(vm.menuObj, function(item){
                            if(item.selected ){
                                selected++;
                                vm.selected.push(item.key);
                            }
                            if(item.children && item.children.length > 0) {
                                item.numSelected = recursiveSelected(item);
                            }
                        })

                        function recursiveSelected(collection){
                            var selected = 0;
                            function recursiveSelectedInner(collection) {
                                _.each(collection.children, function(item){
                                    if(item.selected ){
                                        vm.selected.push(item.key);
                                        selected++;
                                    }
                                    if(item.children && item.children.length > 0) {
                                        recursiveSelectedInner(item);
                                    }
                                });
                            }
                            recursiveSelectedInner(collection)
                            return selected;
                        }
                        return selected;
                    }

                    vm.unselectAll = function(){
                        setTreeAttribute(vm.menuObj,'selected','children',false);
                        getSelected();
                        vm.change();
                    }


                    function getSelected(){
                        vm.selected  = [];
                        var selected = 0;

                        _.each(vm.menuObj, function(item){
                            if(item.selected ){
                                selected++;
                                vm.selected.push(item.key);
                            }
                            if(item.children && item.children.length > 0) {
                                item.numSelected = recursiveSelected(item);
                            }
                        })

                        function recursiveSelected(collection){
                            var selected = 0;
                            function recursiveSelectedInner(collection) {
                                _.each(collection.children, function(item){
                                    if(item.selected ){
                                        vm.selected.push(item.key);
                                        selected++;
                                    }
                                    if(item.children && item.children.length > 0) {
                                        recursiveSelectedInner(item);
                                    }
                                });
                            }
                            recursiveSelectedInner(collection)
                            return selected;
                        }
                        return selected;
                    }

                   function checkParent(_item){
                      var allSelected = true;
                       if( _item.parent ){
                           _.each(_item.parent.children, function(item){
                               if(!item.selected ){
                                   allSelected = false;
                               }
                           });
                           _item.parent.selected = allSelected;

                           if( _item.parent.parent ){
                               checkParent(_item.parent);
                           }
                       }
                    }


                    vm.setChildren= function(collection){
                        var selected = collection.selected;
                        function setEm(_collection){
                            _.each(_collection.children, function(item){
                                item.selected = selected;
                                if(item.children && item.children.length > 0){
                                    setEm(item);
                                }
                            });
                        }

                        setEm(collection);
                        checkParent(collection);
                        getSelected();
                        vm.change();
                    };

                   function getTotalSelected(){
                       vm.totalSelected = getSelected(vm.menuObj);
                   }

                }],

                link: function($scope, element, attrs) {

                }
            };
        }])

        .directive('dfSelectFilterBy', ['$log', 'DataFiltersFactory','GlobalService', function($log, DataFiltersFactory, GlobalService) {
            return {
                     restrict: 'EA',
                     transclude: true,
                     controllerAs: 'ctrl',
                     scope: {
                         filter:'='
                     },
                     controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                         var vm = this;
                         var value = $scope.filter.value_name;
                         vm.model =  $scope.filter.selected;
                         vm.values = $scope.filter.values;
                         var label = $scope.filter.label_name;
                         vm.labelName ='Select ' +  $scope.filter.label;
                         vm.selectOptions = "item as item." + label + " for item in ctrl.values "
     
                         $scope.$watch('filter.selected', function (newVal, oldVal) {
                             vm.model = newVal
                             console.log(" vm.model", vm.model);
                             console.log("$scope.filter", $scope.filter);
                         }, true);
     
                         $scope.$watch('filter.values', function(newVal, oldVal){
                             if( typeof newVal !== "undefined" && newVal != null){
                                 /*  var array =  $scope.filter.values;*/
                                   var xObj = $scope.filter.values;
                                   var array = Object.keys(xObj).map(function(k){
                                       return xObj[k]
                                     });
                                 vm.values = array.slice(0, 2000);
                                 if($scope.filter.selected === null ){
                                     $scope.filter.selected = $scope.filter.values[0];
                                 }
                                 vm.model = $scope.filter.selected;
                             }
                         });
     
                         vm.change = function () {
                             $scope.filter.selected =  vm.model;
                             DataFiltersFactory.onChange($scope.filter);
                         };
                     }],
     
                   /*   style="float: left; background-color: #0a0a0a;border: 1px solid #4c4f4f; height: 25px;'+
                         'padding: 5px 10px;font-size: 12px; line-height: 1.25;border-radius: 3px; color: #cbc8c8;  template: '<div>' +
                     '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                     '<select ng-show="!filter.loading" placeholder="Select System"  ng-change="ctrl.change()"'+
                     'ng-options="{{ctrl.selectOptions}}" ng-model="ctrl.model">'+
                     '<option value="">{{ctrl.labelName}}</option></select>'+
                     '</div>', */
                     template: '<div>' +
                     '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                     '<div class="input-group"style="background:black; border-radius: 3px">'+
                         '<span class="input-group-addon"style="color: #cbc8c8;">Filter By:</span>'+
                         '<select ng-show="!filter.loading"  placeholder = "Select Filter" ng-change="ctrl.change()" ng-options="{{ctrl.selectOptions}}" ng-model="ctrl.model">'+
                         '</select>'+
                     '</div>',
     
     
     
                     link: function($scope, element, attrs) {
     
                     }
                 };
         
         }])
 
 


        .directive('dfSelect', ['$log', 'DataFiltersFactory','GlobalService','workspaceFactory', function($log, DataFiltersFactory, GlobalService,workspaceFactory) {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    filter:'='
                },
                controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                    var vm = this;
                    var value = $scope.filter.value_name;
                    vm.model =  $scope.filter.selected;
                    vm.values = $scope.filter.values;
                    var label = $scope.filter.label_name;
                    vm.selectOptions = "item as item." + label + " for item in ctrl.values "
                    // vm.disableComponent = $scope.filter.match_global_filters === 1 && ($scope.filter.param_name === 'tax_year' || $scope.filter.param_name === 'scenario');
                    if($scope.filter.param_name === 'scenario' && !GlobalService.isDrillDown ) {
                        vm.model = $scope.filter.values.filter(i => parseInt(i.VALUE) === parseInt(GlobalService.getScenario(GlobalService.globalParams.tax_year)))[0];
                        if(vm.model == null) {
                            if($scope.filter.selected){
                                vm.model = $scope.filter.selected;
                            } else {
                                vm.model = $scope.filter.values[0];
                            }
                        }
                        $scope.filter.selected = vm.model;
                        console.log('worskapce',workspaceFactory.activeScreen)
                        workspaceFactory.activeScreen.filters.filterParams['scenario'] = $scope.filter.selected['VALUE'];
                    }

                    $scope.$watch('filter.selected', function (newVal, oldVal) {
                        vm.model = newVal
                        console.log(" vm.model", vm.model);
                        console.log("$scope.filter", $scope.filter);
                    }, true);

                    $scope.$watch('filter.values', function(newVal, oldVal){
                        if( typeof newVal !== "undefined" && newVal != null){
                            /*  var array =  $scope.filter.values;*/
                              var xObj = $scope.filter.values;
                              var array = Object.keys(xObj).map(function(k){
                                  return xObj[k]
                                });
                            vm.values = array.slice(0, 2000);
                            if($scope.filter.selected === null ){
                                $scope.filter.selected = $scope.filter.values[0];
                            }
                            vm.model = $scope.filter.selected;
                        }
                    });

                    vm.change = function () {
                        $scope.filter.selected =  vm.model;
                        var group_key = '';
                        if($scope.filter.filter_key ==30 || $scope.filter.filter_key ==31 || $scope.filter.filter_key ==32){
                            group_key = 25;
                            if($scope.filter.filter_key ==32){
                                $rootScope.$broadcast("showSaveButton", { showBtn: false });
                            } else {
                                $rootScope.$broadcast("showSaveButton", { showBtn: true });
                            }
                        }else if(GlobalService.globalParams.screen_key == 237 || GlobalService.globalParams.screen_key == 11471){
                            group_key = 37;
                            if($scope.filter.filter_key ==51 && $scope.filter.selected !== null){
                                $rootScope.$broadcast("showSaveButton", { showBtn: false });
                            } else {
                                $rootScope.$broadcast("showSaveButton", { showBtn: true });
                            }
                        } else if(GlobalService.globalParams.screen_key == 11287 || 
                                GlobalService.globalParams.screen_key == 11294 ||
                                GlobalService.globalParams.screen_key == 11324 ||
                                GlobalService.globalParams.screen_key == 11327) {
                            group_key = 1525;
                            if (($scope.filter.filter_key ==1467 || $scope.filter.filter_key ==1468) && $scope.filter.selected !== null) {
                                $rootScope.$broadcast("showSaveButton", { showBtn: false });
                            } else {
                                $rootScope.$broadcast("showSaveButton", { showBtn: true });
                            }
                        }

                        DataFiltersFactory.onChange($scope.filter, group_key);
                    };
                }],


                template: '<div>' +
                '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                '<select ng-show="!filter.loading"  ng-change="ctrl.change()" ng-options="{{ctrl.selectOptions}}" ng-model="ctrl.model">'+
                '</select>' +
                '</div>',


                link: function($scope, element, attrs) {

                }
            };
        }])

        .directive('dfSelectDefault', ['$log', 'DataFiltersFactory','GlobalService', function($log, DataFiltersFactory, GlobalService) {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    filter:'='
                },
                controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                    var vm = this;
                    var value = $scope.filter.value_name;
                    vm.model =  $scope.filter.selected;
                    vm.values = $scope.filter.values;
                    var label = $scope.filter.label_name;
                    vm.labelName ='Select ' +  $scope.filter.label;
                    vm.selectOptions = "item as item." + label + " for item in ctrl.values "

                    $scope.$watch('filter.selected', function (newVal, oldVal) {
                        vm.model = newVal
                        console.log(" vm.model", vm.model);
                        console.log("$scope.filter", $scope.filter);
                    }, true);

                    $scope.$watch('filter.values', function(newVal, oldVal){
                        if( typeof newVal !== "undefined" && newVal != null){
                            /*  var array =  $scope.filter.values;*/
                              var xObj = $scope.filter.values;
                              var array = Object.keys(xObj).map(function(k){
                                  return xObj[k]
                                });
                            vm.values = array.slice(0, 2000);
                            if($scope.filter.selected === null ){
                                $scope.filter.selected = $scope.filter.values[0];
                            }
                            vm.model = $scope.filter.selected;
                        }
                    });

                    vm.change = function () {
                        $scope.filter.selected =  vm.model;
                        DataFiltersFactory.onChange($scope.filter);
                    };
                }],

                template: '<div>' +
                '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                '<select ng-show="!filter.loading" placeholder="Select System"  ng-change="ctrl.change()"'+
                'ng-options="{{ctrl.selectOptions}}" ng-model="ctrl.model">'+
                '<option value="">{{ctrl.labelName}}</option></select>'+
                '</div>',


                link: function($scope, element, attrs) {

                }
            };
        }])





        .directive('dfTypeHead', ['$log', 'DataFiltersFactory','JsonObjectFactory','AlertService', function($log, DataFiltersFactory,JsonObjectFactory,AlertService) {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: { filterName: '='},
                controller: ['$scope',  function ($scope) {
                    var vm = this;
                    var index = _.findIndex(DataFiltersFactory.filters, { name: $scope.filterName});

                    //$scope.model = [];
                    $scope.model =  $scope.filter.selected;
                    if( index > -1 ) {
                        var filter = DataFiltersFactory.filters[index];

                        var value = filter.value_name;
                        var label = filter.label_name;
                        vm.placeholder = filter.place_holder;

                        vm.change = function () {
                            DataFiltersFactory.filters[index].selected = $scope.model;
                            DataFiltersFactory.onChange($scope.filter);
                        }


                        vm.loadingFilters = false;
                        vm.disabled = false;
                        vm.clearSelection = function(){
                            vm.disabled = false;
                            $scope.model = null;
                            DataFiltersFactory.filters[index].selected = null;
                        }

                        vm.onSelect = function(_model){
                            DataFiltersFactory.filters[index].selected = $scope.model;
                            vm.disabled = true;
                        }

                        vm.getData = function(val) {
                            vm.loadingFilters = true;
                            var url  = filter.data_url;
                            var  params = {"search_string" :val};

                            return  JsonObjectFactory.getJSONObj(url,params).then(function(data) {
                                if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                                    AlertService.add("error", data.errorMessage);
                                    return false;
                                }else{
                                    vm.loadingFilters = false;
                                    return data.jsonObject;
                                }
                            });
                        };
                    }

                }],

              //  template: '<div ng-dropdown-multiselect ng-click="ctrl.change()"  search-filter="customFilter" options="ctrl.values" selected-model="model" extra-settings="ctrl.settings"></div>',
                 template: ' <div class="input-group" style="width:150px" > '+
                '<input placeholder="{{ctrl.placeholder}}" class="form-control input-sm" typeahead-wait-ms="500" typeahead-no-results="noResults" ng-disabled="ctrl.disabled" type="text" ng-model="model" typeahead-min-length="3"  typeahead-editable="false" typeahead-loading="loadingFilters" typeahead-on-select="ctrl.onSelect($item, $model, $label)" typeahead="leid for leid in ctrl.getData($viewValue)" typeahead-template-url="codeComboTypeHead.html"> '+
                '<span class="input-group-addon" > '+
                '<span ng-show="ctrl.loadingFilters == true"><i  class="fa fa-spinner fa-pulse"></i></span> '+
                '<span ng-show="ctrl.loadingFilters != true && !ctrl.disabled"><i class="fa fa-search"></i></span> '+
                '<span ng-show="ctrl.disabled" style="cursor: pointer;" ng-click="ctrl.clearSelection()" ><i class="fa fa-times" ></i></span> '+
                '</span> '+
                 '<div ng-show="noResults"> '+
                 '<i class="glyphicon glyphicon-remove"></i> No Results Found'+
                 '</div>'+
                 '</div>' ,


                link: function($scope, element, attrs) {

                }
            };
        }])
        // .directive('dfSelectMultiple', ['$log', 'DataFiltersFactory', function($log, DataFiltersFactory) {
        //     return {
        //         restrict: 'EA',
        //         transclude: true,
        //         controllerAs: 'ctrl',
        //         scope: { filter:'=' },
        //         controller: function ($scope) {
        //             var vm = this;
        //             vm.model = [];
        //             vm.values = [];
        //           //  $scope.model = [];

        //             $scope.model =  $scope.filter.selected;
        //             var value = $scope.filter.value_name;
        //             var label = $scope.filter.label_name;
        //             vm.model = _.filter($scope.filter.values,{active:true});//$scope.filter.selected;
        //             setTimeout(function(){
        //                 $scope.model = vm.model;
        //             },200);
        //             $scope.$watch('filter.values', function(newVal, oldVal){
        //                 vm.model = [];
        //                 $scope.model = vm.model;
        //                 if( typeof newVal !== "undefined" && newVal != null){
        //                     var array =  $scope.filter.values;
        //                     vm.values = array;
        //                     vm.model = _.filter($scope.filter.values,{active:true});// $scope.filter.selected;
        //                    // vm.model = [];
        //                     $scope.model = vm.model;
        //                 }
        //             });
        //             vm.change = function () {
        //                 $scope.filter.selected = $scope.model;
        //                 DataFiltersFactory.onChange($scope.filter);
        //             }
        //             vm.settings = {
        //                 enableSearch: true,
        //                 displayProp: label,
        //                 idProp: value,
        //                 externalIdProp: '',
        //                 //selectionLimit: 2,
        //                 scrollableHeight: '400px',
        //                 scrollable: true
        //             };
        //             var customTexts = "Select " + $scope.filter.label;
        //             vm.example5customTexts =  {buttonDefaultText: customTexts};
        //         },

        //         template: '<div>' +
        //         '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
        //         '<div ng-show="!filter.loading" ng-dropdown-multiselect ng-click="ctrl.change()"  search-filter="customFilter" options="ctrl.values" selected-model="model" extra-settings="ctrl.settings" translation-texts="ctrl.example5customTexts"></div>' +
        //         '</div>',




        //         link: function($scope, element, attrs) {

        //         }
        //     };
        // }])
        /* changed on 4/29/2019 */
        // .directive('dfSelectMultiple', ['$log', 'DataFiltersFactory','workspaceFactory', function($log, DataFiltersFactory, workspaceFactory) {
        //     return {
        //         restrict: 'EA',
        //         transclude: true,
        //         controllerAs: 'ctrl',
        //         scope: { filter:'=' },
        //         controller: ['$scope', function ($scope) {
        //             var vm = this;
        //             vm.model = [];
        //             vm.values = [];
        //             var selected = [];
        //             var value = $scope.filter.value_name;
        //             var label = $scope.filter.label_name;
        //             $scope.$watch('filter.selected', function (newVal, oldVal) {
        //                 if(newVal && _.isArray(newVal)){
        //                     vm.model = [];
        //                     var fList = _.map(newVal,value);
        //                     vm.model = _.filter($scope.filter.values,function(item){
        //                         return fList.indexOf(item[value])>-1;
        //                     });
        //                 } else {
        //                     vm.model = newVal;
        //                 }
        //                 console.log(" vm.model" ,  vm.model );
        //                 console.log("$scope.filter" ,  $scope.filter );
        //             }, true);
        //             $scope.$watch('filter.values', function(newVal, oldVal){
        //                 if( typeof newVal !== "undefined" && newVal != null){
        //                     var array =  $scope.filter.values;
        //                     vm.values = array;
        //                 }
        //             });
        //             vm.change = function () {
        //                 $scope.filter.selected = vm.model;
        //                 DataFiltersFactory.onChange($scope.filter);
        //             }
        //             vm.settings = {
        //                 enableSearch: true,
        //                 displayProp: label,
        //                 idProp: value,
        //                 externalIdProp: '',
        //                 //selectionLimit: 2,
        //                 scrollableHeight: '400px',
        //                 scrollable: true
        //             };
        //             var customTexts = "Select " + $scope.filter.label;
        //             vm.example5customTexts =  {buttonDefaultText: customTexts};
        //         }],

        //         template: '<div>' +
        //         '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
        //         '<div ng-show="!filter.loading" ng-dropdown-multiselect ng-click="ctrl.change()"  search-filter="customFilter" options="ctrl.values" selected-model="ctrl.model" extra-settings="ctrl.settings" translation-texts="ctrl.example5customTexts"></div>' +
        //         '</div>',




        //         link: function($scope, element, attrs) {

        //         }
        //     };
        // }]) 
        /* changed 2/23/2021 lazyloading on df slect multple*/
        // Virtual scroller/Lazy Load dropdwon List directive..
        .directive('dfSelectMultiple', [function () {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    filter: '=',
                    
                },
                template:
                    '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                    '<div class="df-select" ng-show="!filter.loading">' +
                    '    <div class="df-gt-lazy-load-drp-btn dropdown-toggle" data-toggle="dropdown" required>' +
                    '        <span class="caret" style="float: right; margin: 7px 0px 0px 2px;"></span>' +
                    '        <div ng-if="filter.selected.length > 0" style="display: inline-block;"><span ng-bind="filter.selected.length"></span> Checked</div>' +
                    '        <div ng-if="!filter.selected.length > 0" style=" text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><span ng-bind="ctrl.filterLabel"></span></div>' +
                    '    </div>' +
                    '    <div class="df-gt-lazy-dropdown-list dropdown-menu">' +
                    '        <div class="hdr" style="padding: 0 20px;">' +
                    '            <li ng-click="ctrl.dfCheckAll($event);" ><i class="fa fa-check"></i><span style="font-size: 11px; padding-left: 3px; cursor: pointer;">Check All</span></li>' +
                    '            <li ng-click="ctrl.dfUnCheckAll($event);"><i class="fa fa-times"></i><span style="font-size: 11px; padding-left: 3px; cursor: pointer;">UnCheck All</span></li>' +
                    '        </div>' +
                    '        <div class="sticky-search-bar">' +
                    '            <input type="text" class="form-control" placeholder="Search..." ng-model="searchItem" style="height: 26px;" ng-change="ctrl.dfSearching(searchItem)" />' +
                    '        </div>' +
                    '        <ul class="list-ul" gt-lazy-load-df>' +
                    '            <li ng-if="ctrl.filterCachedDrpList && ctrl.filterCachedDrpList.length > 0"  class="df-gt-lazy-load-list-trunc" ng-repeat="filterList in ctrl.filterCachedDrpList track by $index" ng-click="ctrl.dfSelectedListItem(filterList, $index, $event, filterList.isChecked);ctrl.change();"><i class="fa fa-check" ng-if="filterList.isChecked == true"></i> {{ filterList.LABEL }}</li>' +
                    '            <li ng-if="!(ctrl.filterCachedDrpList && ctrl.filterCachedDrpList.length > 0)" style="padding: 4px 8px; font-size: 13px; text-align: center;"><i>No search results found for \"{{searchItem}}\"</i></li>' +
                    '        </ul>' +
                    '    </div>' +
                    '</div>',

                controller: 'lazyLoadCtrlMultiple'
            };
        }])
        .controller('lazyLoadCtrlMultiple', ['$scope', '$window','DataFiltersFactory', function ($scope, $window,DataFiltersFactory) {
            var vm = this;
            vm.searchValue = "";
            var listIndx = 0;

            vm.checkedList = [];
            vm.filterList = $scope.filter;
            vm.filterLabel = $scope.filter.label;
          
            
            vm.filterTotalDrpList = $scope.filter.values;
            vm.filterCachedDrpList = $scope.filter.values.slice(0, 20);

            $scope.$watch('filter.selected', function (newVal, oldVal) {
                _.each(newVal,function(i){
                    _.filter(vm.filterTotalDrpList,function(y){
                        if(i.VALUE == y.VALUE){ 
                            y.isChecked = true;
                        }})
                })
            }, true);

            $scope.$watch('filter.values', function(newVal, oldVal){              
                if( typeof newVal !== "undefined" && newVal != null){
                    $scope.searchItem ="";
                    vm.filterTotalDrpList = $scope.filter.values;
                    vm.filterCachedDrpList = $scope.filter.values.slice(0, 20);
                    // $scope.filter.selected = vm.checkedList;

                }
            });

            vm.dfSelectedListItem = function(selectedDfList, index, event, CheckedFlag) {
                event.stopPropagation();
                
                if(CheckedFlag == true){
                    vm.filterCachedDrpList[index].isChecked = false;
                } else {
                    vm.filterCachedDrpList[index].isChecked = true;
                }

                vm.checkedList = _.filter(vm.filterCachedDrpList, function(item) {
                    if(item.isChecked == true) {
                        var isFound = _.filter($scope.filter.selected,{VALUE:item.VALUE})
                        if(isFound && isFound.length==0){
                            $scope.filter.selected.push(item);
                        }                      
                        return item;
                    }
                    else {
                       var xy = _.find($scope.filter.selected,{VALUE:item.VALUE});
                       if(xy){
                           _.remove($scope.filter.selected,{VALUE:xy.VALUE});
                       }
                    }   
                });
              //  $scope.filter.selected = _.clone(vm.checkedList);
                DataFiltersFactory.onChange($scope.filter);
            }

            //un Checks all the list items from dropdown..
            vm.dfUnCheckAll = function(event) {
                event.stopPropagation();
                _.forEach(vm.filterTotalDrpList, function(eachItem) {
                    if(eachItem.isChecked && eachItem.isChecked == true) {
                        eachItem.isChecked = false;
                    }
                });
                vm.checkedList = [];
                $scope.filter.selected = [];
            }

            //checks all the list items from dropdown..
            vm.dfCheckAll = function(event) {
                event.stopPropagation();
                _.forEach(vm.filterTotalDrpList, function(eachItem) {
                    if(eachItem.isChecked == undefined || eachItem.isChecked == false) {
                        eachItem.isChecked = true;
                        vm.checkedList.push(eachItem);
                        $scope.filter.selected.push(eachItem);
                    }
                });
            }

            // Clears Out the dropdown list..
            vm.resetList = function(){
                listIndx = 0
                vm.filterCachedDrpList = vm.filterTotalDrpList.slice(0, 20);
            }

            // Dropdown list will generated based on the search creteria..
            vm.dfSearching = function(string) {
                vm.searchValue = string.toLowerCase();
                if(vm.searchValue && vm.searchValue.length > 0) {
                    vm.searchResults = _.filter(vm.filterTotalDrpList, function(itr) {
                        var label = itr["LABEL"].toLowerCase();
                        return label.indexOf(vm.searchValue)!== -1;
                    });
                    vm.filterCachedDrpList = vm.searchResults.slice(0, 20);
                } else {
                    vm.resetList();
                }
            
            }

            // Called to load more list after reaching present content list..
            $scope.fetchMoreDfSelectList = function() {
                listIndx = vm.filterCachedDrpList.length;
                var r = 20;
                if(vm.searchValue && vm.searchValue.length > 0) {
                    vm.filterCachedDrpList = _.concat(vm.filterCachedDrpList, (vm.searchResults.slice(listIndx, r + listIndx)));
                } else {
                    // used to show the list when ddropdown intial fetched..
                    vm.filterCachedDrpList = _.concat(vm.filterCachedDrpList, (vm.filterTotalDrpList.slice(listIndx, r + listIndx)));
                }
            }

        }])
        //End of Virtual scroller/Lazy Load dropdwon List directive..


        /* dvalaboju001 11/6/2019 */
        /* commented on 2/23/2021 bcz implemented the virtual scroll */
        // .directive('dfSelectSingle', ['$log', 'DataFiltersFactory','workspaceFactory','$compile', function($log, DataFiltersFactory, workspaceFactory,$compile) {
        //     return {
        //         restrict: 'EA',
        //         transclude: true,
        //         controllerAs: 'ctrl',
        //         scope: { filter:'=' },
        //         controller: ['$scope', function ($scope) {
        //             var vm = this;
        //             vm.model = [];
        //             vm.values = [];
        //             var selected = [];
        //             var value = $scope.filter.value_name;
        //             var label = $scope.filter.label_name;
        //            $scope.extraOptions = JSON.parse(JSON.stringify(eval("(" + ($scope.filter.extra_options) + ")")));
        //             $scope.settings = {
        //                 enableSearch: true,
        //                 displayProp: label,
        //                 idProp: value,
        //                 externalIdProp: '',
        //                 selectionLimit: ($scope.extraOptions && $scope.extraOptions.showCheckAll == false)? 
        //                                 $scope.filter.values.length >0 ? $scope.filter.values.length -1 : 1: 1,//1,
        //                  //selectionLimit: 1,
        //                 scrollableHeight: '400px',
        //                 scrollable: true
        //             };
                    
        //             $scope.$watch('filter.selected', function (newVal, oldVal) {

        //                 vm.model = newVal
        //                 console.log(" vm.model" ,  vm.model );
        //                 console.log("$scope.filter" ,  $scope.filter );
                        
        //             }, true);
                   
        //             vm.change = function () {
        //                 $scope.filter.selected = vm.model;
        //                 DataFiltersFactory.onChange($scope.filter);
        //             }
                    
                   
        //             var customTexts = "Select " + $scope.filter.label;
        //             vm.example5customTexts =  {buttonDefaultText: customTexts};
        //         }],

        //         template: '<div>' +
        //         '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
        //         '<div ng-show="!filter.loading" ng-dropdown-multiselect ng-click="ctrl.change()"  search-filter="customFilter"'+
        //         'options="values" selected-model="ctrl.model" extra-settings="settings"'+
        //         ' translation-texts="ctrl.example5customTexts"></div>' +
        //         '</div>',




        //         link: function($scope, element, attrs) {
                    
                   
        //             $scope.$watch('filter.values', function(newVal, oldVal){
        //                 if( typeof newVal !== "undefined" && newVal != null){
        //                     var array =  $scope.filter.values;
        //                     $scope.values = array;
                          
        //                 }
        //             });
        //             /* conditionally to show the checkAll options on directive 02/04/2021 */
        //             $scope.$watch('filter.values.length', function(newVal, oldVal){
        //                 if(($scope.extraOptions && $scope.extraOptions.showCheckAll == false)){
        //                     $scope.settings.selectionLimit =  $scope.filter.values.length >0 ? $scope.filter.values.length -1 : 1;
        //                     $compile(element.contents())($scope);
        //                 }
        //             })
        //         }
        //     };
        // }])

        //LazyLoad directive to load more content based on scroller height..
        // .directive('gtLazyLoadDf', function() {
        //     return {
        //       restrict: 'A',
        //       link: function(scope, elem) {
        //         var scroller = elem[0]
        //         $(scroller).bind('scroll', function() {
        //           if (scroller.scrollTop + scroller.clientHeight >= scroller.scrollHeight) {
        //             setTimeout(function() {
        //                 scope.$apply('fetchMoreDfSelectList()');
        //             }, 10);
        //           }
        //         })
        //       }
        //     }
        // })

        .directive('gtLazyLoadDf', function() {
            return {
              restrict: 'A',
              link: function(scope, elem) {
                var scroller = elem[0]
                $(scroller).bind('scroll', function(ev) {
                    var sel = $(this);
                    var lasto = sel.find('li:last');
                    var s = sel.position().top + sel.height();
                    var o = lasto.height() + lasto.position().top - 4;
                    //setTimeout(function(){
                        if(o < s){
                            scope.$apply('fetchMoreDfSelectList()')
                        }
                   // }, 2200);
                })
              }
            }
        })


        // Virtual scroller/Lazy Load dropdwon List directive..
        .directive('dfSelectVirtualScroll', [function () {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    filter: '=',
                    
                },
                template:
                    '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                    '<div class="df-select" ng-show="!filter.loading">' +
                    '    <div class="df-gt-lazy-load-drp-btn dropdown-toggle" data-toggle="dropdown" required>' +
                    '        <span class="caret" style="float: right; margin: 7px 0px 0px 2px;"></span>' +
                    '        <div ng-if="filter.selected.length > 0" style="display: inline-block;"><span ng-bind="filter.selected.length"></span> Checked</div>' +
                    '        <div ng-if="!filter.selected.length > 0" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><span ng-bind="ctrl.filterLabel"></span></div>' +
                    '    </div>' +
                    '    <div class="df-gt-lazy-dropdown-list dropdown-menu">' +
                    '        <div style="padding: 0 20px;">' +
                    '            <li ng-click="ctrl.dfCheckAll($event);" ng-if="ctrl.showCheckAll "><i class="fa fa-check"></i><span style="font-size: 11px; padding-left: 3px; cursor: pointer;">Check All</span></li>' +
                    '            <li ng-click="ctrl.dfUnCheckAll($event);"><i class="fa fa-times"></i><span style="font-size: 11px; padding-left: 3px; cursor: pointer;">UnCheck All</span></li>' +
                    '        </div>' +
                    '        <div class="sticky-search-bar">' +
                    '            <input type="text" class="form-control" placeholder="Search..." ng-model="searchItem" style="height: 26px;" ng-change="ctrl.dfSearching(searchItem)" />' +
                    '        </div>' +
                    '        <ul class="list-ul" gt-lazy-load-df>' +
                    '            <li ng-if="ctrl.filterCachedDrpList && ctrl.filterCachedDrpList.length > 0" class="df-gt-lazy-load-list-trunc" ng-repeat="filterList in ctrl.filterCachedDrpList track by $index" ng-click="ctrl.dfSelectedListItem(filterList, $index, $event, filterList.isChecked);"><i class="fa fa-check" ng-if="filterList.isChecked == true"></i> {{ filterList.LABEL }}</li>' +
                    '            <li ng-if="!(ctrl.filterCachedDrpList && ctrl.filterCachedDrpList.length > 0)" style="padding: 4px 8px; font-size: 13px; text-align: center;"><i>No search results found for \"{{searchItem}}\"</i></li>' +
                    '        </ul>' +
                    '    </div>' +
                    '</div>',

                controller: 'dfSelectVirtual'
            };
        }])
        .controller('dfSelectVirtual', ['$scope', '$window','DataFiltersFactory', function ($scope, $window,DataFiltersFactory) {
            var vm = this;
            vm.searchValue = "";
            var listIndx = 0;

            vm.checkedList = [];
            vm.filterList = $scope.filter;
            vm.filterLabel = $scope.filter.label;
            var extraOptions = JSON.parse(JSON.stringify(eval("(" + ($scope.filter.extra_options) + ")")));
            vm.showCheckAll = extraOptions && extraOptions.showCheckAll == undefined ? true : extraOptions.showCheckAll;

            vm.filterTotalDrpList = $scope.filter.values;
            vm.filterCachedDrpList = $scope.filter.values.slice(0, 20);
            $scope.$watch('filter.selected', function (newVal, oldVal) {
                _.each(newVal,function(i){
                    _.filter(vm.filterTotalDrpList,function(y){
                        if(i.VALUE == y.VALUE){ 
                            y.isChecked = true;
                        }})
                })
            }, true);

            $scope.$watch('filter.values', function(newVal, oldVal){              
                if( typeof newVal !== "undefined" && newVal != null){
                    $scope.searchItem ="";
                    vm.filterTotalDrpList = $scope.filter.values;
                    vm.filterCachedDrpList = $scope.filter.values.slice(0, 20);
                    // $scope.filter.selected = vm.checkedList;

                }
            });

            

            

            vm.dfSelectedListItem = function(selectedDfList, index, event, CheckedFlag) {
                event.stopPropagation();
                // $scope.callBackNgModel = selectedDfList;
                // vm.selectedItem = selectedDfList[$scope.drpKeyName];
                // $scope.callBackMethod({arg1: selectedDfList});
                
                if(CheckedFlag == true){
                    vm.filterCachedDrpList[index].isChecked = false;
                } else {
                    vm.filterCachedDrpList[index].isChecked = true;
                }

                vm.checkedList = _.filter(vm.filterCachedDrpList, function(item) {
                    if(item.isChecked == true) {
                        var isFound = _.filter($scope.filter.selected,{VALUE:item.VALUE})
                        if(isFound && isFound.length==0){
                            $scope.filter.selected.push(item);
                        }                      
                        return item;
                    }
                    else {
                       var xy = _.find($scope.filter.selected,{VALUE:item.VALUE});
                       if(xy){
                           _.remove($scope.filter.selected,{VALUE:xy.VALUE});
                       }
                    }   
                });
                DataFiltersFactory.onChange($scope.filter);
            }

            //un Checks all the list items from dropdown..
            vm.dfUnCheckAll = function(event) {
                event.stopPropagation();
                _.forEach(vm.filterTotalDrpList, function(eachItem) {
                    if(eachItem.isChecked && eachItem.isChecked == true) {
                        eachItem.isChecked = false;
                    }
                });
                vm.checkedList = [];
                $scope.filter.selected = [];
            }

            //checks all the list items from dropdown..
            vm.dfCheckAll = function(event) {
                event.stopPropagation();
                _.forEach(vm.filterTotalDrpList, function(eachItem) {
                    if(eachItem.isChecked == undefined || eachItem.isChecked == false) {
                        eachItem.isChecked = true;
                        vm.checkedList.push(eachItem);
                        $scope.filter.selected.push(eachItem);
                    }
                });
            }

             // Clears Out the dropdown list..
             vm.resetList = function(){
                listIndx = 0
                vm.filterCachedDrpList = vm.filterTotalDrpList.slice(0, 20);
            }
            // Dropdown list will generated based on the search creteria..
            vm.dfSearching = function(string) {
                vm.searchValue = string.toLowerCase();
                if(vm.searchValue && vm.searchValue.length > 0) {
                    vm.searchResults = _.filter(vm.filterTotalDrpList, function(itr) {
                        var label = itr["LABEL"].toLowerCase();
                        return label.indexOf(vm.searchValue)!== -1;
                    });
                    vm.filterCachedDrpList = vm.searchResults.slice(0, 20);
                } else {
                    vm.resetList();
                }
            
            }

            // Called to load more list after reaching present content list..
            $scope.fetchMoreDfSelectList = function() {
                listIndx = vm.filterCachedDrpList.length;
                var r = 20;
                if(vm.searchValue && vm.searchValue.length > 0) {
                    vm.filterCachedDrpList = _.concat(vm.filterCachedDrpList, (vm.searchResults.slice(listIndx, r + listIndx)));
                } else {
                    // used to show the list when ddropdown intial fetched..
                    vm.filterCachedDrpList = _.concat(vm.filterCachedDrpList, (vm.filterTotalDrpList.slice(listIndx, r + listIndx)));
                }
            }

        }])
        //End of Virtual scroller/Lazy Load dropdwon List directive..

        /* dfSelect virtual Scroll */
         // Virtual scroller/Lazy Load dropdwon List directive..
         .directive('dfSelectSingle', [function () {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    filter: '=',
                    
                },
                template:
                    '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                    '<div class="df-select" ng-show="!filter.loading">' +
                    '    <div class="df-gt-lazy-load-drp-btn dropdown-toggle" data-toggle="dropdown" required>' +
                    '        <span class="caret" style="float: right; margin: 7px 0px 0px 2px;"></span>' +
                    '        <div ng-if="filter.selected.length > 0" style="display: inline-block;"><span ng-bind="filter.selected.length"></span> Checked</div>' +
                    '        <div ng-if="!filter.selected.length > 0" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><span ng-bind="ctrl.filterLabel"></span></div>' +
                    '    </div>' +
                    '    <div class="df-gt-lazy-dropdown-list dropdown-menu">' +
                    '        <div style="padding: 0 20px;">' +
                    // '            <li ng-click="ctrl.dfCheckAll($event);" ng-if="ctrl.showCheckAll "><i class="fa fa-check"></i><span style="font-size: 11px; padding-left: 3px; cursor: pointer;">Check All</span></li>' +
                    '            <li ng-click="ctrl.dfUnCheckAll($event);"><i class="fa fa-times"></i><span style="font-size: 11px; padding-left: 3px; cursor: pointer;">UnCheck All</span></li>' +
                    '        </div>' +
                    '        <div class="sticky-search-bar">' +
                    '            <input type="text" class="form-control" placeholder="Search..." ng-model="searchItem" style="height: 26px;" ng-change="ctrl.dfSearching(searchItem)" />' +
                    '        </div>' +
                    '        <ul class="list-ul" gt-lazy-load-df>' +
                    '            <li ng-if="ctrl.filterCachedDrpList && ctrl.filterCachedDrpList.length > 0" class="df-gt-lazy-load-list-trunc" ng-repeat="filterList in ctrl.filterCachedDrpList track by $index" ng-click="ctrl.dfSelectedListItem(filterList, $index, $event, filterList.isChecked);"><i class="fa fa-check" ng-if="filterList.isChecked == true"></i> {{ filterList.LABEL }}</li>' +
                    '            <li ng-if="!(ctrl.filterCachedDrpList && ctrl.filterCachedDrpList.length > 0)" style="padding: 4px 8px; font-size: 13px; text-align: center;"><i>No search results found for \"{{searchItem}}\"</i></li>' +
                    '        </ul>' +
                    '    </div>' +
                    '</div>',

                controller: 'dfSelectSingleVRScrolling'
            };
        }])
        .controller('dfSelectSingleVRScrolling', ['$scope', '$window','DataFiltersFactory', function ($scope, $window,DataFiltersFactory) {
            var vm = this;
            vm.searchValue = "";
            var listIndx = 0;

            vm.checkedList = [];
            vm.filterList = $scope.filter;
            vm.filterLabel = $scope.filter.label;
          
            vm.filterTotalDrpList = $scope.filter.values;
            vm.filterCachedDrpList = $scope.filter.values.slice(0, 20);

            $scope.$watch('filter.selected', function (newVal, oldVal) {
                _.each(newVal,function(i){
                    _.filter(vm.filterTotalDrpList,function(y){
                        if(i.VALUE == y.VALUE){ 
                            y.isChecked = true;
                        } else {
                            y.isChecked = false;
                        }})
                })
            }, true);

            $scope.$watch('filter.values', function(newVal, oldVal){            
                if( typeof newVal !== "undefined" && newVal != null){
                    $scope.searchItem ="";
                    vm.filterTotalDrpList = $scope.filter.values;
                    vm.filterCachedDrpList = $scope.filter.values.slice(0, 20);
                    // $scope.filter.selected = vm.checkedList;

                }
            });

            vm.dfSelectedListItem = function(selectedDfList, index, event, CheckedFlag) {
                event.stopPropagation();
                // $scope.callBackNgModel = selectedDfList;
                // vm.selectedItem = selectedDfList[$scope.drpKeyName];
                // $scope.callBackMethod({arg1: selectedDfList});
                
                if(CheckedFlag == true){
                    vm.filterCachedDrpList[index].isChecked = false;
                } else {
                    _.filter(vm.filterTotalDrpList,function(i){
                        if(i.isChecked){
                            i.isChecked = false;
                            _.remove($scope.filter.selected,{VALUE:i.VALUE});
                        }
                    })
                    vm.filterCachedDrpList[index].isChecked = true;
                }

                vm.checkedList = _.filter(vm.filterCachedDrpList, function(item) {
                    if(item.isChecked == true) {
                        var isFound = _.filter($scope.filter.selected,{VALUE:item.VALUE})
                        if(isFound && isFound.length==0){
                            $scope.filter.selected.push(item);
                        }                      
                        return item;
                    }
                    else {
                       var xy = _.find($scope.filter.selected,{VALUE:item.VALUE});
                       if(xy){
                           _.remove($scope.filter.selected,{VALUE:xy.VALUE});
                       }
                    }   
                });
                DataFiltersFactory.onChange($scope.filter);
            }

            //un Checks all the list items from dropdown..
            vm.dfUnCheckAll = function(event) {
                event.stopPropagation();
                _.forEach(vm.filterTotalDrpList, function(eachItem) {
                    if(eachItem.isChecked && eachItem.isChecked == true) {
                        eachItem.isChecked = false;
                    }
                });
                vm.checkedList = [];
                $scope.filter.selected = [];
            }

            //checks all the list items from dropdown..
            vm.dfCheckAll = function(event) {
                event.stopPropagation();
                _.forEach(vm.filterTotalDrpList, function(eachItem) {
                    if(eachItem.isChecked == undefined || eachItem.isChecked == false) {
                        eachItem.isChecked = true;
                        vm.checkedList.push(eachItem);
                        $scope.filter.selected.push(eachItem);
                    }
                });
            }

             // Clears Out the dropdown list..
             vm.resetList = function(){
                listIndx = 0
                vm.filterCachedDrpList = vm.filterTotalDrpList.slice(0, 20);
            }

            // Dropdown list will generated based on the search creteria..
            vm.dfSearching = function(string) {
                vm.searchValue = string.toLowerCase();
                if(vm.searchValue && vm.searchValue.length > 0) {
                    vm.searchResults = _.filter(vm.filterTotalDrpList, function(itr) {
                        var label = itr["LABEL"].toLowerCase();
                        return label.indexOf(vm.searchValue)!== -1;
                    });
                    vm.filterCachedDrpList = vm.searchResults.slice(0, 20);
                } else {
                    vm.resetList();
                }
            
            }

            // Called to load more list after reaching present content list..
            $scope.fetchMoreDfSelectList = function() {
                listIndx = vm.filterCachedDrpList.length;
                var r = 20;
                if(vm.searchValue && vm.searchValue.length > 0) {
                    vm.filterCachedDrpList = _.concat(vm.filterCachedDrpList, (vm.searchResults.slice(listIndx, r + listIndx)));
                } else {
                    // used to show the list when ddropdown intial fetched..
                    vm.filterCachedDrpList = _.concat(vm.filterCachedDrpList, (vm.filterTotalDrpList.slice(listIndx, r + listIndx)));
                }
            }

        }])
        //End of Virtual scroller/Lazy Load dropdwon List directive..


        // Used to filter the date.
        .directive('dateFilterInput', [function() {
            return {
                require: "ngModel",
                scope: {
                    ngModel: '=',
                    options: '=?',
                    onChange: '&'
                },
                templateUrl: 'app/components/dataFilters/templates/date-filter-input.html',
                link: function(scope, elem, attrs, ngModel) {
                    scope.$watch('options', function() {
                        function getRanges(type, count) {
                            var date = new Date();
                            date.setHours(0);
                            date.setMinutes(0);
                            date.setSeconds(0);
                            date.setMilliseconds(0);
                            var prevDate = new Date();
                            if (type === 'day') {
                                prevDate = new Date(date.getTime() - (count * 24 * 60 * 60 * 1000));
                            } else if (type === 'month') {
                                prevDate = new Date(date.getTime() - (count * 30 * 24 * 60 * 60 * 1000));
                            }
                            return { from: prevDate, to: date };
                        }

                        // Added a set of options to choose from dateRange dropdown.
                        scope.dropdownOptions = [
                            { key: 'Today', value: getRanges(null, 0) },
                            { key: 'Yesterday', value: getRanges('day', 1) },
                            { key: 'Last 7 days', value: getRanges('day', 7) },
                            { key: 'Last 30 days', value: getRanges('day', 30) },
                            { key: 'Last 6 months', value: getRanges('month', 6) },
                            { key: 'Last 12 months', value: getRanges('month', 12) },
                            { key: 'Last 18 months', value: getRanges('month', 18) }
                        ]
                    });

                    scope.onCustomDateRange = angular.noop;
                    scope.onSelectCustomRange = function() {
                        // e.preventDefault();
                        // e.stopImmediatePropagation();
                        scope.onCustomDateRange = onCustomDateRange;
                        scope.placeholder = 'My own dates (mm/dd/yyyy)';
                    };

                    // Used to filter the custom date ranges "From" and "To" inside the Existin transaction modal.
                    function onCustomDateRange(open) {
                        if(!open) {
                            scope.onCustomDateRange = angular.noop;
                            scope.placeholder = 'My own dates (mm/dd/yyyy)';
                            ngModel.$setViewValue({
                                from: new Date(scope.custom.fromDate),
                                to: new Date(scope.custom.toDate)
                            });
                            scope.onChange();
                        }
                    }

                    scope.selectDateRange = function(opt) {
                        scope.onCustomDateRange = angular.noop;
                        ngModel.$setViewValue(opt.value);
                        scope.placeholder = opt.key;
                        scope.onChange();
                    };
                }
            }
        }])

        .directive('gtCalFilterFrom', ['$compile', '$parse', '$state', '$filter', 'AlertService', 'DataFiltersFactory', 'workspaceFactory', function ($compile, $parse, $state, $filter, AlertService, DataFiltersFactory, WorkspaceFactory) {
            return {
                restrict: 'A',
                controllerAs: 'ctrl',
                scope: {
                    filter: '='
                },

                controller: ['$scope', function ($scope) {
                    var vm = this;
                    var value = $scope.filter.value_name;
                    vm.modelDate = $scope.filter.selected;
                    vm.values = $scope.filter.values;
                    vm.dateOptions = {
                        dateDisabled: false,
                        formatYear: 'yyyy',
                        startingDay: 1,
                        showWeeks: false
                    }
                    vm.change = function () {
                        $scope.filter.selected = {
                            VALUE: $filter('date')(vm.modelDate, 'MM/dd/yyyy')
                        };
                        DataFiltersFactory.onChange($scope.filter);
                        WorkspaceFactory.activeScreen.filters.setFilterParams();
                        //console.log($scope.filter);
                    };
                }],

                link: function (scope, element, attrs, ctrl) {

                    var template = '' +
                        '   <p class="input-group" style="margin:0px">' +
                        '       <input type="text" placeholder="From Date" class="form-control" uib-datepicker-popup="MM/dd/yyyy" ng-change="ctrl.change()" ng-model="ctrl.modelDate" is-open="startDate" datepicker-options="ctrl.dateOptions" close-text="Close" />' +
                        '       <span class="input-group-addon" ng-click="startDate = !startDate">' +
                        '           <i class="glyphicon glyphicon-calendar"></i>' +
                        '       </span>' +
                        '   </p>';
                    element.html('').append($compile(template)(scope));

                }
            };
        }])

        .directive('gtCalFilterTo', ['$compile', '$filter', '$parse', '$state', 'AlertService', 'DataFiltersFactory', 'workspaceFactory', function ($compile, $filter, $parse, $state, AlertService, DataFiltersFactory, WorkspaceFactory) {
            return {
                restrict: 'A',
                controllerAs: 'ctrl',
                scope: {
                    filter: '='
                },
                controller: ['$scope', function ($scope) {
                    var vm = this;
                    var value = $scope.filter.value_name;
                    vm.modelDate = $scope.filter.selected;
                    vm.values = $scope.filter.values;

                    vm.dateOptions = {
                        dateDisabled: false,
                        formatYear: 'yyyy',
                        startingDay: 1,
                        showWeeks: false
                    }
                    vm.change = function () {
                        $scope.filter.selected = {
                            VALUE: $filter('date')(vm.modelDate, 'MM/dd/yyyy')
                        };
                        DataFiltersFactory.onChange($scope.filter);
                        WorkspaceFactory.activeScreen.filters.setFilterParams();
                        //console.log($scope.filter);
                    };
                }],

                link: function (scope, element, attrs, ctrl) {
                    var template = '' +
                        '   <p class="input-group"  style="margin:0px">' +
                        '       <input type="text" placeholder="To Date" class="form-control" uib-datepicker-popup="MM/dd/yyyy" ng-change="ctrl.change()" ng-model="ctrl.modelDate" is-open="startDate" datepicker-options="ctrl.dateOptions" close-text="Close" />' +
                        '       <span class="input-group-addon" ng-click="startDate = !startDate">' +
                        '           <i class="glyphicon glyphicon-calendar"></i>' +
                        '       </span>' +
                        '   </p>';

                    element.html('').append($compile(template)(scope));



            }
        };
        }])

        .directive('dfText',['$compile',function($compile){
            return {
                require: "?ngModel",
                scope: {
                    filter:'='
                },
                
                controllerAs: 'ctrl',
                template: "<div><input ng-model='value' style='height: 25px !important;width:120px!important'"+
                           "placeholder={{filter.label}} ng-change='onChange();ctrl.change()' class='form-control'></div>",
                controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                    var vm = this;
                    console.log('filter',$scope.filter);
                    vm.change = function () {
                        console.log('ngmodel',$scope.value);
                        console.log('filter',$scope.filter);
                        let  value = $scope.value.replace(/[^a-zA-Z0-9\-_\s]/g,"");     
                        $scope.value = value;      
                        vm.model = $scope.value;
                        let obj = {}
                        obj.VALUE = vm.model;
                        $scope.filter.selected = obj;
                    };
                }],
                link: function(scope, element, attrs, ngModel){
                   /*  if (!ngModel) return;
                        console.log('Im filter ')
                    scope.onChange = function(){
                        ngModel.$setViewValue(scope.value);
                        console.log('text',scope.value);
                    };
                
                    ngModel.$render = function(){
                        scope.value = ngModel.$modelValue;
                    }; */
                }
              };

        }])

    return directives;
});
