define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.logHistoryCtrl', []).controller('logHistoryCtrl',
        ['$scope', 'GoldSyncFactory', 'GENERAL_CONFIG', 'DTOptionsBuilder', 'DTColumnBuilder', '$window', '$timeout', logHistoryCtrl]);

    function logHistoryCtrl($scope, GoldSyncFactory, GENERAL_CONFIG, DTOptionsBuilder, DTColumnBuilder, $window, $timeout) {
        $scope.page = 1;
        $scope.pageLength = 10;
        $scope.pageLengthOptions = [10, 25, 50, 100];
        $scope.tableSearchTerm = '';
        $scope.taxYearList = [];
        $scope.jobIdList = [];
        $scope.logHistoryData = [];
        $scope.searchTerm = {};
        $scope.dtOptions = DTOptionsBuilder.newOptions()
                                           .withOption('autoWidth', false)
                                           .withOption('paging', false)
                                           .withOption('searching', false)
                                           .withOption('ordering', false)
                                           .withOption('info', false);
        $scope.dtColumns = [
            DTColumnBuilder.newColumn('job_name').withTitle('Job Name'),
            DTColumnBuilder.newColumn('tax_year').withTitle('Tax Year')
        ];

        // clear cached objects selection status
        $scope.goldSyncJobs.forEach((job) => {
            job.selected = false;
        });
        $scope.goldSyncTaxYears.forEach((year) => {
            year.selected = false;
        });

        $scope.loadData = function (paramListObj) {
            $scope.logHistoryData = [];
            $scope.logHistoryDataLoading = true;
            GoldSyncFactory.getLogHistory(paramListObj.taxYearList, paramListObj.jobIdList, paramListObj.page, paramListObj.size,
                paramListObj.sortBy, paramListObj.sortByDirection).then((response) => {
                if (response.data.callSuccess === '0') {
                    throw new Error(response.data.errorMessage);
                }
                $scope.logHistoryData = response.data.goldSyncLogHistory;
                $timeout(() => {
                    $scope.logHistoryDataLoading = false;
                }, 100);
            }).catch((err) => {
                $scope.logHistoryData = [];
                $timeout(() => {
                    $scope.logHistoryDataLoading = false;
                }, 100);
                const errMsg = err.toString().substring(0, 5) === 'Error' ? err.toString() : 'Something went wrong when fetching table data!';
                AlertService.add('error', errMsg);
            });
        };
        $scope.loadData({page: $scope.page, size: $scope.pageLength});

        $scope.onPageLengthSelected = function () {
            $scope.loadData({taxYearList: $scope.taxYearList, jobIdList: $scope.jobIdList, page: $scope.page, size: $scope.pageLength});
        };

        $scope.logHistoryFilter = function (data) {
            if (data && $scope.tableSearchTerm.length > 0) {
                const searchTerm = $scope.tableSearchTerm.toLowerCase();

                return (data.job_name !== null && data.job_name.toLowerCase().includes(searchTerm)) ||
                    (data.tax_year !== null && data.tax_year.toString() === searchTerm) ||
                    (data.sync_count !== null && data.sync_count.toString() === searchTerm) ||
                    (data.description !== null && data.description.includes(searchTerm));
            }
            return true;
        };

        $scope.openJobNameFilter = function ($event) {
            const filterMenuRef = $('#job-name-filter-menu');
            filterMenuRef.css('left', $event.pageX - 220);
            filterMenuRef.show();
            $scope.closeFilters = true;
        };

        $scope.onFiltersClicked = function () {
            $scope.closeFilters = false;
        };

        $scope.jobNamesFilterSelectAll = function ($event) {
            $scope.goldSyncJobs.forEach((job) => {
                job.selected = $event.target.checked;
            });
        };

        $scope.searchJobName = function (job) {
            if (job.selected) return true;
            const termVal = $scope.searchTerm.jobNameSearchTerm;
            return termVal ? job.job_name.toLowerCase().includes(termVal.toLowerCase()) : true;
        };

        $scope.filterByJobName = function () {
            $scope.closeFilters = true;

            $scope.jobIdList = [];
            $scope.goldSyncJobs.forEach((job) => {
                if (job.selected) $scope.jobIdList.push(job.job_id);
            });

            // clear tax years filter
            $scope.goldSyncTaxYears.forEach((taxYear) => {
                taxYear.selected = false;
            });
            $scope.taxYearSelectAll = false;
            $scope.taxYearList = [];
            $scope.page = 1;
            $scope.loadData({jobIdList: $scope.jobIdList, page: $scope.page, size: $scope.pageLength});
        };

        $scope.openTaxYearFilter = function ($event) {
            const filterMenuRef = $('#tax-year-filter-menu');
            filterMenuRef.css('left', $event.pageX - 220);
            filterMenuRef.show();
            $scope.closeFilters = true;
        };

        $scope.taxYearFilterSelectAll = function ($event) {
            $scope.goldSyncTaxYears.forEach((year) => {
                year.selected = $event.target.checked;
            });
        };

        $scope.searchTaxYear = function (year) {
            if (year.selected) return true;
            const termVal = $scope.searchTerm.taxYearSearchTerm;
            return termVal ? year.tax_year.toLowerCase().includes(termVal.toLowerCase()) : true;
        };

        $scope.filterByTaxYear = function () {
            $scope.closeFilters = true;

            $scope.taxYearList = [];
            $scope.goldSyncTaxYears.forEach((year) => {
                if (year.selected) $scope.taxYearList.push(year.tax_year);
            });

            // clear job names filter
            $scope.goldSyncJobs.forEach((job) => {
                job.selected = false;
            });
            $scope.jobNamesSelectAll = false;
            $scope.jobIdList = [];
            $scope.page = 1;
            $scope.loadData({taxYearList: $scope.taxYearList, page: $scope.page, size: $scope.pageLength});
        };

        $scope.closeFiltersFn = function () {
            const filterMenusRef = $('.log-hist-filter');
            filterMenusRef.hide();
        };

        $scope.flipPage = function (direction) {
            if (direction === -1 && $scope.page === 1) return;
            $scope.page += direction;
            $scope.loadData({taxYearList: $scope.taxYearList, jobIdList: $scope.jobIdList, page: $scope.page, size: $scope.pageLength});
        };

        $window.onmousedown = function () {
            if (!$scope.closeFilters) {
                $scope.closeFilters = true;
                return;
            }
            $scope.closeFiltersFn();
        };
    }

    return controllers;
});
