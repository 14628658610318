const { VERSION } = require("lodash");

define(['moment','angular'], function (moment) {
    "use strict";
    var controllers = angular
        .module("app.EfileDataAdminController", [])
        .controller("EfileDataAdminController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory',
            'AlertService','GlobalService','rowData','TAX_YEARS','$uibModalInstance','JsonObjectFactory', 'workspaceFactory',
            EfileDataAdminController
        ])
        .controller("EfileAddtionalElementsController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', EfileAddtionalElementsController])
        .controller("EfileAddEditObjController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', EfileAddEditObjController])
        .controller("EfilePropertiesController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', EfilePropertiesController])
        .controller("EfileSchemaSetController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', 'workspaceFactory', EfileSchemaSetController])
        .controller("EfileDataDefinitionController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', 'workspaceFactory', EfileDataDefinitionController])
        .controller("EfileConfigController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', 'workspaceFactory', EfileConfigController])
        .controller("EfileEntityMapController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', 'workspaceFactory', EfileEntityMapController])
        .controller("EfileIrsSubmissionController", ['$rootScope','GENERAL_CONFIG','USER_SETTINGS','SERVER_CONFIG','efileFactory','AlertService','GlobalService','rowData','$uibModalInstance', 'JsonObjectFactory', 'workspaceFactory', EfileIrsSubmissionController])
        .controller("BulkXmlSyncController",["EfileFactory","AlertService","$uibModalInstance","workspaceFactory","GlobalService", BulkXmlSyncController])
        .controller("XmlVsElementsController",["EfileFactory","AlertService","$uibModalInstance","workspaceFactory","$rootScope", XmlVsElementsController]);

    function EfileDataAdminController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, TAX_YEARS, $uibModalInstance, JsonObjectFactory, workspaceFactory) {
        let vm = this;
        vm.isEdit = rowData ? true : false;
        vm.row = rowData ? rowData : {};
        vm.title = 'Add/Edit Efile Software Setting';
        vm.addNew = rowData ? false : true;
        
        vm.softwareIdObj = {
            software_id: rowData ? rowData.SOFTWARE_ID : 0,
            tax_year : rowData ? rowData.TAX_YEAR : '',
            schematype : rowData ? rowData.SCHEMATYPE : '',
            selectedTaxYears : []
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.saving = false;
        vm.save = function(){
            if (vm.dataServiceForm.$invalid) {
                return;
            }
            vm.saving = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params =  {
                software_id: vm.softwareIdObj.software_id,
                tax_year: filterParams.tax_year,
                schema_type: vm.softwareIdObj.schematype
            };
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32861";
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfileAddtionalElementsController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory) {
        let vm = this;
        vm.title = 'Add/Edit Additional Elements';
        vm.row = rowData ? rowData : {};
        vm.addNew = rowData ? false : true;
        
        vm.rowDataCopy = rowData;
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {};
        };

        vm.saving = false;

        vm.save = function(){
            if (vm.dataServiceForm.$invalid) {
                return;
            }
            
            vm.saving = true;
            
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32868";
            JsonObjectFactory.saveJSON(url, vm.row).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfileAddEditObjController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory) {
        let vm = this;
        vm.title = 'Add/Edit Object';
        vm.addNew = rowData ? false : true;
        vm.row = rowData ? rowData : {ID : 0};

        vm.rowDataCopy = rowData;
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {ID : 0};
        };

        vm.saving = false;
        vm.save = function(){
            if (vm.dataServiceForm.$invalid) {
                return;
            }
            vm.saving = true;            
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32867";
            JsonObjectFactory.saveJSON(url, vm.row).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfilePropertiesController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory) {
        let vm = this;
        vm.title = 'Add/Edit Efile Properties';
        vm.addNew = rowData ? false : true;
        vm.row = rowData ? rowData : {};
        
        vm.rowDataCopy = rowData;
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {};
        };

        vm.saving = false;
        vm.save = function(){
            if (vm.dataServiceForm.$invalid) {
                return;
            }
            vm.saving = true;
            
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32869";
            JsonObjectFactory.saveJSON(url, vm.row).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfileSchemaSetController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory, workspaceFactory) {
        let vm = this;
        vm.title = 'Add/Edit Efile Schema Set';
        vm.addNew = rowData ? false : true;
        vm.row = rowData ? rowData : {SCHEMA_SET_ID : 0};

        vm.rowDataCopy = rowData;
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {SCHEMA_SET_ID : 0};
        };

        vm.saving = false;
        vm.save = function(){
            if (vm.dataServiceForm.$invalid) {
                return;
            }
            
            vm.saving = true;
            var dataset = {};
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            vm.row.TAX_YEAR = filterParams.tax_year;
            dataset.request_json = JSON.stringify(vm.row);
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32870";
            JsonObjectFactory.saveJSON(url, dataset).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfileDataDefinitionController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory, workspaceFactory) {
        let vm = this;
        vm.addNew = rowData ? false : true;
        vm.title = 'Add/Edit Efile Data Definition';
        vm.row = rowData ? rowData : {};

        vm.rowDataCopy = rowData;
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {};
        };

        var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.row.FORM = filterParams.form;
        // vm.row.FORM = '';
        vm.saving = false;
        vm.save = function(){
            if (vm.dataServiceForm.$invalid) {
                return;
            }
            
            vm.saving = true;

            var data = {};
            // var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            // vm.row.FORM = filterParams.tax_year;
            data.request_json = JSON.stringify(vm.row);
            
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32871";
            JsonObjectFactory.saveJSON(url, data).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfileConfigController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory) {
        let vm = this;
        vm.title = 'Add/Edit Configs';
        vm.row = rowData ? rowData : {};
        vm.addNew = rowData ? false : true;

        vm.rowDataCopy = rowData;
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {};
        };

        vm.saving = false;

        vm.save = function(){
            if (vm.tmefConfigForm.$invalid) {
                return;
            }
            
            vm.saving = true;
            var data = {};
            data.request_json = JSON.stringify(vm.row);
            
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32894";
            JsonObjectFactory.saveJSON(url, data).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfileEntityMapController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory) {
        let vm = this;
        vm.title = 'Add/Edit Entity Mappings';
        vm.row = rowData ? rowData : {};
        vm.addNew = rowData ? false : true;

        vm.rowDataCopy = rowData;
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {};
        };

        vm.saving = false;

        vm.save = function(){
            if (vm.tmefConfigForm.$invalid) {
                return;
            }
            
            vm.saving = true;
            var data = {};
            data.request_json = JSON.stringify(vm.row);
            
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32895";
            JsonObjectFactory.saveJSON(url, data).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };
    }

    function EfileIrsSubmissionController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, efileFactory, AlertService, 
        GlobalService, rowData, $uibModalInstance, JsonObjectFactory) {
        let vm = this;
        vm.title = 'Edit IRS Submission';
        vm.row = rowData ? Object.assign({}, rowData) : {};
        vm.addNew = rowData ? false : true;
        vm.errorIndicator = false;
        vm.errorAccepted = false;
        if(vm.row && vm.row.CREATED_TS) {
            vm.row.CREATED_TS = vm.row.CREATED_TS.replaceAll("+00:00", "");
        }
        vm.selectedDate = vm.row ? new Date(new Date(vm.row.CREATED_TS).toLocaleDateString()) : new Date();
        vm.mytime = vm.row ? new Date(vm.row.CREATED_TS) : new Date();

        vm.rowDataCopy = Object.assign({}, rowData);
        vm.cancel = function(){
            this.reset();
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
            vm.row = vm.rowDataCopy ? vm.rowDataCopy : {};
            rowData = vm.rowDataCopy;
        };

        vm.openCalendar = false;

        vm.openDatePicker = function() {
            vm.openCalendar = true;
            console.log("Calendar is open");
        };

        vm.hstep = 1;
        vm.mstep = 15;

        vm.options = {
            hstep: [1, 2, 3],
            mstep: [1, 5, 10, 15, 25, 30]
        };

        vm.ismeridian = false;
        // vm.toggleMode = function() {
        //     vm.ismeridian = ! vm.ismeridian;
        // };

        vm.update = function() {
            var d = new Date();
            d.setHours( 14 );
            d.setMinutes( 0 );
            vm.mytime = d;
        };
    
        vm.changed = function () {
            console.log('Time changed to: ' + vm.mytime);
        };
    
        vm.clear = function() {
            vm.mytime = null;
        };

        vm.startDateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false,
            minDate: new Date()
        };

        vm.saving = false;

        vm.save = function(){
            if (vm.irsSubForm.$invalid) {
                return;
            }
            
            vm.saving = true;
            vm.row.CREATED_TS = vm.selectedDate.toLocaleDateString() + ' ' + vm.mytime.toLocaleTimeString();
            var data = {
                "id" : vm.row.ID,
                "submission_id" : vm.row.SUBMISSION_ID,
                "indicator" : vm.row.INDICATOR.toUpperCase(),
                "accepted" : vm.row.ACCEPTED.toUpperCase(),
                "created_ts" : vm.row.CREATED_TS,
                "acknowledgement": vm.row.ACKNOWLEDGEMENT,
                "submission_receipt" : vm.row.SUBMISSION_RECEIPT
            };
            // data.request_json = JSON.stringify(vm.row);
            console.log(vm.row);
            console.log(data.request_json);
            // var url = GENERAL_CONFIG.gtw_efile_api_url + "/saveIrsSubmission";
            efileFactory.saveIrsSubmission(data).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Data saved successfully.",4000);
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
					$rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                } else {
                    AlertService.add("error", data.errorMessage,4000);
                    vm.saving = false;
                }
            });
        };

        vm.validateIndicator = function() {
            if(vm.row.INDICATOR.toUpperCase() === 'P' || vm.row.INDICATOR.toUpperCase() === 'T'){
                vm.errorIndicator = false;
            } else {
                vm.errorIndicator = true;
            }
        };

        vm.validateAccepted = function() {
            if(vm.row.ACCEPTED === 'Y' || vm.row.ACCEPTED === 'N'){
                vm.errorAccepted = false;
            } else {
                vm.errorAccepted = true;
            }
        };
    }
    function BulkXmlSyncController(EfileFactory,AlertService, $uibModalInstance,workspaceFactory,GlobalService){

        const vm = this;
        const JCD_KEY = 250;
        const filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        const selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:filterParams.tax_year});
        const scenario = selectedTaxYear.scenarios.find(function(s) {
            return s.jcd_key == JCD_KEY && s.default_scenario === 'Y';
        })?.scenario;


        vm.cancel = function(){
            $uibModalInstance.dismiss();
        }

        vm.confirm = function(){
            EfileFactory.bulkSyncXML(filterParams.tax_year, scenario, JCD_KEY).then(response => {
                if(response.data && response.data.callSuccess === "1"){
                    AlertService.add("success","Bulk XML Sync was Completed!");
                    vm.cancel();
                }else{
                    AlertService.add("error",response.data.errorMessage);
                }
            });
        }
    }

    function XmlVsElementsController(EfileFactory,AlertService, $uibModalInstance,workspaceFactory,$rootScope){

        const vm = this;
        const filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};

        if(!filterParams.irs_id){
            AlertService.add("warning","Please select an irs submission to extract elements from.");
            vm.cancel();
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss();
        }

        vm.confirm = function(){
            vm.running = true;
            EfileFactory.xmlVsElementsCompare(filterParams.irs_id).then(response => {
                vm.running = false;
                if(response.data && response.data.callSuccess === "1"){
                    AlertService.add("success","XML Elements Extraction was Completed!");

                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                    vm.cancel();
                }else{
                    AlertService.add("error",response.data.errorMessage);
                }
            });
        }
    }


    return controllers;
});
