
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.userAdminServices',[])

        .factory("UserAdminFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS) {
            var userAdminFactory = {};

            var URLS = {
                updateUser: GENERAL_CONFIG.admin_api_url+'/adminUpdateUser',
                getUserAssign: GENERAL_CONFIG.admin_api_url+'/getUserAssign',
                setUserAccess: GENERAL_CONFIG.admin_api_url+'/setUserAccess',
                setUserAccessAll: GENERAL_CONFIG.admin_api_url+'/setAllUserAccess',
                findUser: GENERAL_CONFIG.admin_api_url +'/getUserList',
                getUserDetails:GENERAL_CONFIG.admin_api_url+'/getUserDetails',
                addUser: GENERAL_CONFIG.admin_api_url+'/addNewUser',
                removeAllAccessURL: GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=qfeqhn',
                fetchClients: GENERAL_CONFIG.admin_api_url + '/fetchClients',
                FETCHUSERCLIENTS:GENERAL_CONFIG.admin_api_url+'/fetchUserClients',
                getAllActiveBusinessesURL: GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=8g8maf',
                getJurisByClientKeyURL: GENERAL_CONFIG.base_url +'/loadJsonObject?action_code=x5hvpy',
                getTemplatesByJurisByClientKeyURL: GENERAL_CONFIG.base_url +'/loadJsonObject',
                validateUserTeamplatesModsURL:GENERAL_CONFIG.base_url +'/saveJsonObject',
                applyUserTemplateModulesURL: GENERAL_CONFIG.base_url +'/loadJsonObject',
                getUserTemplateDetailsBySsoURL: GENERAL_CONFIG.base_url +'/loadJsonObject',
                getDiffUserTemplateDetURL: GENERAL_CONFIG.admin_api_url+ '/getDiffUserAndTemplateAssign',
                getBizzHeirarchy: GENERAL_CONFIG.admin_api_url + '/getBizzHierarchy',
                saveBizHeirarchy: GENERAL_CONFIG.base_url+'/saveBizHeirarchy',
                startNotificationEngine: GENERAL_CONFIG.base_url+'/notification/addNewUser',  /** Remove this before delivering this code to develop branch**/
                notMathedTaskList: GENERAL_CONFIG.base_url+'/nonMatchTaskList',
                getAuditData: GENERAL_CONFIG.admin_api_url+'/getUserAudit',
                getUserList : GENERAL_CONFIG.base_url + '/loadGridJson?action_id=40&grid_id=3',
                saveUserExpiryData : GENERAL_CONFIG.base_url +'/saveJsonObject?action_code=pvccel'
            }


            userAdminFactory.nonMatchedTaskList = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.notMathedTaskList,
                    data:data
                }).then(function(response){
                        return response;
                });
                return promise;
            }


            userAdminFactory.getBizzHierarchyBySsoByGroupObjKey = function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.getBizzHeirarchy, //"previewBizzHierarchy.ge", //URLS.getBizHeirarchy,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };


            userAdminFactory.updateUser = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.updateUser,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                         if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 8000);
                        }else{
                            AlertService.add("error", "An error has occurred : " +  response.data.errorMessage + ", If you continue to experience this issue please visit the help page to contact support.", 8000);
                        }
                    }
                });
                return promise;
            };

            userAdminFactory.removeAllAccess = function(data) {
            	var promise = $http({
                    method: "POST",
                    url: URLS.removeAllAccessURL, //"previewBizzHierarchy.ge", //URLS.getBizHeirarchy,
                    data: data

                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to remove all the accesses.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };




            userAdminFactory.findUser = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.findUser,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        AlertService.add("error", "User could not be found.", 4000);
                    }
                });
                return promise;
            };


            /** Remove this before delivering this code to develop branch**/
            userAdminFactory.startNotificationEngine = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.startNotificationEngine,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        AlertService.add("error", "User could not be found.", 4000);
                    }
                });
                return promise;
            };




            userAdminFactory.addUser = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.addUser,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };



            userAdminFactory.getUserDetails = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.findUser,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            userAdminFactory.getAllUserList = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.getUserList,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            userAdminFactory.getUserClients = function(params) {

                var promise = $http({
                    method: "POST",
                    url: URLS.FETCHUSERCLIENTS,
                    data: params
                }).then(function (response) {
                    return response;
                });

                return promise;
            }

            userAdminFactory.getUserAssign = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.getUserAssign,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };



            /**
             * Refer New method
             * To be deprecated - Mallesh**/
            userAdminFactory.getUserBusiness = function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.getBizzHeirarchy,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };


            userAdminFactory.saveBizHeirarchy = function(data){
            	var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.base_url + '/saveBizHeirarchy',
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            }

            userAdminFactory.getUserEntity = function(data){
                var promise = $http({
                    method: "POST",
                    //url: URLS.getUserAssign,
                    url: 'app/json/entity-list.json',
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };




            userAdminFactory.setAccess = function(node,user_id,rowPrimaryKey,client_key) {
            	var params = {};
                params.jcd_ta_key = node.jcd_ta_key;
                params.jcd_ta_role_key = node.role.value;
                params.user_id = user_id;
                params.client_key = client_key;
                /** not required to use rowPrimaryKey param. **/

                var promise = $http({
                    method: "POST",
                    url: URLS.setUserAccess,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        AlertService.add("success", "Data saved successfully.", 4000);
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            userAdminFactory.setAccessAll = function(nodes,user_id,rowPrimaryKey,client_key) {
                var promise = $http({
                    method: "POST",
                    url: URLS.setUserAccessAll,
                    data: {
                        "user_access_list":nodes
                    }
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        AlertService.add("success", "Data saved successfully.", 4000);
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            /** Added Supporting service methods for User Template Modules **/
            userAdminFactory.getAllActiveBusinesses = function(data){

                var promise = $http({
                    method: "POST",
                    url: URLS.getAllActiveBusinessesURL,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };


            userAdminFactory.getJurisByBusiness = function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.getJurisByClientKeyURL,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            userAdminFactory.getTemplatesByJAndClient= function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.getTemplatesByJurisByClientKeyURL,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            userAdminFactory.getUserTemplateDetails = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.getUserTemplateDetailsBySsoURL,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });

                return promise;
            };

            userAdminFactory.getDiffUserTemplateDetails = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.getDiffUserTemplateDetURL,
                    data: data
                }).then(function (response) {
                	if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };


            userAdminFactory.validateUserTeamplatesMods= function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.validateUserTeamplatesModsURL,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ||  response.data.callSuccess === "0"){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            userAdminFactory.applyUserTemplateMods= function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.applyUserTemplateModulesURL,
                    data: data
                }).then(function (response) {
                	console.log("applyUserTemplateMods() data ::",data);
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            /** End of User Template Modules**/

            userAdminFactory.userAssignedList = function(data, urls){
                var promise = $http({
                    method: "POST",
                    url: urls,
                    data:data
                }).then(function(response){
                    if(response.data.callSuccess === "1") {
                        return response.data;
                    }
                });
                return promise;
            };

            userAdminFactory.getAuditData = function(auditType, userSso, clientKey) {

                var promise = $http({
                    method: "GET",
                    url: URLS.getAuditData + '?audit_type=' + encodeURIComponent(auditType) + '&user_sso=' + userSso + '&client_key=' + clientKey
                    //url: URLS.getAuditData + '?user_sso=' + userSso + '&client_key=' + clientKey
                }).then(function (response) {
                    return response;
                });

                return promise;
            }

            userAdminFactory.saveUserExpiryReportData= function(data){
                var params = {
					"clob_data": JSON.stringify(data)
				};
            	var promise = $http({
                    method: "POST",
                    url: URLS.saveUserExpiryData,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ||  response.data.callSuccess === "0"){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };


            return userAdminFactory;
        }])







    return services;


});
