define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.settingsController', [])
        .controller('SettingsController', ['Dashboard901Factory','JsonObjectFactory', 'USER_SETTINGS', '$scope', '$rootScope', 'AlertService', 'MessageFactory', '$state', '$window', 'SERVER_CONFIG', '$uibModal','GENERAL_CONFIG', settingsController]);


    function settingsController(Dashboard901Factory,JsonObjectFactory, USER_SETTINGS, $scope, $rootScope, AlertService, MessageFactory, $state, $window, SERVER_CONFIG, $uibModal,GENERAL_CONFIG) {
        $scope.action = 'home';
       $scope.upload_url = GENERAL_CONFIG.base_url + '/uploadCommentAttachements';

       var vm =this;

        $scope.changeAction = function (action) {
            $scope.action = action;
        }
        $scope.attachmentTO = {
            FILE_KEY: '',
            FILE_NAME: '',
            FILE_PATH: '',
            FILE_TYPE: '',
            FILE_SIZE: '',
            CONTENT_TYPE: '',
            ATTACHMENT_KEY: '',
            comment_attachment_change_success: false,
            saveClicked: false,
            logoFilesMarkedForDeletion: [],
            datasource_connection_msg: '',
            datasource_connection_status: '',
            flow: {},
            attachment_file: ''
        };

        /****************************************** start of callback functions used by flowjs ******************************************/
        $scope.getProfileImageFileName = function () {

            if (angular.isUndefined($scope.attachmentTO.FILE_NAME) ||
                ($scope.attachmentTO.FILE_NAME == null) ||
                ($scope.attachmentTO.FILE_NAME == '')
            ) {
                return '';
            }

            return $scope.attachmentTO.FILE_NAME.substr($scope.attachmentTO.FILE_NAME.lastIndexOf('/') + 1);
        };
        $scope.removeProfileImage=function(){
           /*  JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/removeUserAvatar', {}).then(function (data) {
                AlertService.add("success", "Image Removed!");
                window.location.reload(true);
            }, function (err) {
                AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
            }); */
            var params = {
                fileKey : 377
            }

            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/removeUserAvatar', params).then(function (data) {
                AlertService.add("success", "Image Removed!");
             //   window.location.reload(true);
            }, function (err) {
                AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
            });
        }

        $scope.failCount = 0;
        $scope.fileAdded = function (file, event, flow) {
            var fileExtension = file.getExtension().toLowerCase();
            var allowedFileExtensions = ',jpg,jpeg,png,svg';
            var isAllowedFileExtension = (allowedFileExtensions.indexOf(',' + fileExtension + ',') >= 0);
            var isFileSizeWithinLimit = (file.size <= SERVER_CONFIG.configSettings.fileUploadMaxSize);
            var isFileAdded = (isAllowedFileExtension && isFileSizeWithinLimit);
            file.error = false;
            file.message = '';
            if (!isFileAdded) {
                if (!isAllowedFileExtension) {
                    file.error = true;
                    file.message = file.name + ' is invalid.';
                    flow.cancel();
                }
                if (!isFileSizeWithinLimit) {
                    file.error = true;
                    file.message = file.name + ' exceeds the allowed maximum size of ';
                    flow.cancel();
                }
            }
            return true;
        }
        $scope.setFiles = function (files, flow) {
            if (files[0].error == false) {
                flow.upload();
            }
        };
        $scope.setErrorFile = function (file, message, flow) {
            file.error = true;
            // file.message = file.name + ' is invalid or exceeds allowed maximum size of ' + FileSizeService.readableFileSize(SERVER_CONFIG.configSettings.fileUploadMaxSize);
            flow.cancel();
        };
        $scope.tempFolder = "";
        $scope.fileSuccess = function (file, message, flow) {
            var parsedMessage = JSON.parse(message);
            if (parsedMessage.uploadStatus == 'success') {
                $scope.attachmentTO.logoFilesMarkedForDeletion.push($scope.attachmentTO.attachment_file);
                var fileName = parsedMessage.flowFilename;
                var fileParam = {
                    name: fileName
                };
                var objContext = {
                    parent_key: USER_SETTINGS.user.sso_id,//vm.rowData.COMBINATION_KEY,
                    parent_type: 'profile_image',
                    attachments: file.name,//$scope.attachmentTO.attachmented_file.toString(),
                    oldFiles: _.map($scope.attachmentList, "file_name","attachment_key").toString()
              };
              MessageFactory.saveAttachment(GENERAL_CONFIG.base_url + '/saveProfileImage', objContext).then(function (data) {
                    console.log(data);
                    if (data.callSuccess === "1") {
                          console.log("attachem ++++ data", data);
                          AlertService.add("success", "Profile Image saved Successfully.", 4000);
                    } else {
                          AlertService.add("error",data.errorMessage, 4000);
                    }
              });
            } else if (parsedMessage.uploadStatus == 'success') {
                file.error = true;
                file.message = parsedMessage.message;
            }
        };
        $scope.dragEnter = function () {
            $scope.style = {
                border: '2px solid #37d631'
            };
        };
        $scope.dragLeave = function () {
            $scope.style = {};
        };
        /****************************************** end of callback functions used by flowjs ******************************************/

        $scope.pageTitle = "User Settings";
        $scope.saveImage = function (ssoId, path) {
            // var service = JsonObjectFactory;
            // var url = "saveJsonObject.ge?action_code=kl6ppp";
            var jsonObj = {
                sso_id: ssoId,
                avatar_path: path
            };
            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveUserAvatar', jsonObj).then(function (data) {
                AlertService.add("success", "Image Updated!");
                $rootScope.donotLogoutUser =true; // shouldn't logout on reload flag used in GlobalController
                window.location.reload(true);
            }, function (err) {
                AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
            });

        }

    }


    return controllers;

});