define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.EntityMgmtAppService', [])
                          .factory('EntityMgmtAppService', ['$rootScope', '$http', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'GENERAL_CONFIG', 'USER_SETTINGS',
                              function ($rootScope, $http, AlertService, GlobalService, JsonObjectFactory, GENERAL_CONFIG, USER_SETTINGS) {
                                  var EntityMgmtFactory = {};
                                  EntityMgmtFactory.entityDetailsLoading = {};
                                  EntityMgmtFactory.entityDetailsCache = {};

                                  // used to store the state params id in service level.
                                  EntityMgmtFactory.selectedId = [];
                                  EntityMgmtFactory.settingId = function (id) {
                                      EntityMgmtFactory.selectedId = id;
                                  };

                                  EntityMgmtFactory.allowGlobalMes = true;
                                  EntityMgmtFactory.workflowMes = [];
                                  EntityMgmtFactory.setWorkflowMes = function (mes) {
                                      EntityMgmtFactory.workflowMes = mes;
                                  };

                                  EntityMgmtFactory.states = {};
                                  EntityMgmtFactory.saveState = function (state) {
                                      return EntityMgmtFactory.states = state;
                                  };

                                  EntityMgmtFactory.getGOKs = function (taxYear) {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=sj4iti&tax_year=' + taxYear
                                      });
                                  };

                                  EntityMgmtFactory.getFilingGroup = function (params) {
                                      var promise = $http({
                                          method: "GET",
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id=762&tax_year=' + params.tax_year + '&scenario=' + params.scenario + '&jcd_key=' + params.jcd_key,
                                          data: params
                                      }).then(function (response) {
                                          return response;
                                      });

                                      return promise;
                                  };

                                  EntityMgmtFactory.getEntityList = function () {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.custom_api_url + '/getEntityList'
                                      });
                                  };

                                  EntityMgmtFactory.getEntityDetails = function (taxYear, legalEntityId) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.custom_api_url + '/getEntityDetails?taxYear=' + taxYear + '&legalEntityId=' + legalEntityId
                                      });
                                  };

                                  EntityMgmtFactory.saveEntityDetails = function (entityDetails) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.custom_api_url + '/editEntityDetailsJson',
                                          data: JSON.stringify(entityDetails),
                                          headers: {
                                              'Content-Type': 'application/json',
                                          }
                                      });
                                  };

                                  EntityMgmtFactory.saveAddressDetails = function (taxYear, legalEntityId, addressDetails) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.custom_api_url + '/editAddressDetails?taxYear=' + taxYear + '&legalEntityId=' + legalEntityId,
                                          data: JSON.stringify(addressDetails),
                                          headers: {
                                              'Content-Type': 'application/json',
                                          }
                                      });
                                  };

                                  EntityMgmtFactory.saveOwnershipDetails = function (taxYear, legalEntityId, ownershipDetails) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.custom_api_url + '/editOwnerShipDetails?taxYear=' + taxYear + '&legalEntityId=' + legalEntityId,
                                          data: JSON.stringify(ownershipDetails),
                                          headers: {
                                              'Content-Type': 'application/json',
                                          }
                                      });
                                  };

                                  EntityMgmtFactory.reloadAddressDetails = function (taxYear, legalEntityId) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.custom_api_url + '/reloadAddress?taxYear=' + taxYear + '&legalEntityId=' + legalEntityId
                                      });
                                  };

                                  EntityMgmtFactory.reloadOwnershipDetails = function (taxYear, legalEntityId) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.custom_api_url + '/reloadOwnerShipDetails?taxYear=' + taxYear + '&legalEntityId=' + legalEntityId
                                      });
                                  };

                                  EntityMgmtFactory.deleteAddressDetails = function (taxYear, legalEntityId, rowId) {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.custom_api_url + '/deleteAddressDetails?taxYear=' + taxYear + '&legalEntityId=' + legalEntityId + '&regAddressId=' + rowId
                                      });
                                  };

                                  EntityMgmtFactory.deleteOwnershipDetails = function (legalEntityId, rowId) {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.custom_api_url + '/deleteOwnerShipDetails?legalEntityId=' + legalEntityId + '&ownerShipId=' + rowId
                                      });
                                  };

                                  EntityMgmtFactory.getDiagList = function (taxYear, scenario, parentMeString) {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=zz9no5&tax_year=' + taxYear + '&scenario=' + scenario + '&parent_me_string=' + parentMeString + '&sso_id=' + USER_SETTINGS.user.sso_id
                                      });
                                  };

                                  EntityMgmtFactory.toggleDiagStatus = function (taxYear, scenario, parentMeString, diagKey, diagEnabled) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=7nk5lt',
                                          data: {
                                              sso_id: USER_SETTINGS.user.sso_id,
                                              tax_year: taxYear,
                                              scenario: scenario,
                                              parent_me_string: parentMeString,
                                              diag_key: diagKey,
                                              diag_switch: diagEnabled
                                          }
                                      });
                                  };

                                  EntityMgmtFactory.getCategoryList = function () {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=5v72qy&sso_id=' + USER_SETTINGS.user.sso_id
                                      });
                                  };

                                  EntityMgmtFactory.getSubcategoryList = function () {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=kclpwq&sso_id=' + USER_SETTINGS.user.sso_id
                                      });
                                  };

                                  EntityMgmtFactory.getMessageList = function () {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=u480j2&sso_id=' + USER_SETTINGS.user.sso_id
                                      });
                                  };

                                  EntityMgmtFactory.getDisabledLEs = function (taxYear, scenario, parentMeString, diagKey) {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=dlzi5f&tax_year=' + taxYear + '&scenario=' + scenario + '&parent_me_string=' + parentMeString + '&diag_key=' + diagKey + '&sso_id=' + USER_SETTINGS.user.sso_id
                                      });
                                  };

                                  EntityMgmtFactory.getAllEntities = function (taxYear, scenario, parentMeString, diagKey) {
                                      return $http({
                                          method: 'GET',
                                          url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=3fxza8&tax_year=' + taxYear + '&scenario=' + scenario + '&parent_me_string=' + parentMeString + '&diag_key=' + diagKey + '&sso_id=' + USER_SETTINGS.user.sso_id
                                      });
                                  };

                                  EntityMgmtFactory.editDiagnostic = function (taxYear, scenario, parentMeString, diagKey, catKey, subcatKey, diagMsg, leId) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=jtjpzv',
                                          data: {
                                              tax_year: taxYear,
                                              scenario: scenario,
                                              parent_me_string: parentMeString,
                                              diag_key: diagKey,
                                              cat_key: catKey,
                                              subcat_key: subcatKey,
                                              diag_msg: diagMsg,
                                              leid: leId,
                                              sso_id: USER_SETTINGS.user.sso_id
                                          }
                                      });
                                  };

                                  EntityMgmtFactory.runDiagnostics = function (taxYear, scenario) {
                                      return $http({
                                          method: 'POST',
                                          url: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=0spzys',
                                          data: {
                                              tax_year: taxYear,
                                              scenario: scenario,
                                              sso_id: USER_SETTINGS.user.sso_id
                                          }
                                      });
                                  };

                                  return EntityMgmtFactory;
                              }]);
    return services;
});
