define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.relatedPartyPaymentScreenService', [])
     .factory("firRelatedPartyPaymentScreenFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
    	 var firRelatedPartyPaymentScreenFactory = {};
    	 firRelatedPartyPaymentScreenFactory.formConfigData = {};
    	 var firRelatedPartyPaymentScreenFactory = {};
    	 firRelatedPartyPaymentScreenFactory.url = {};
    	 firRelatedPartyPaymentScreenFactory.url.saveNewPayment  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=7hra73";
    	 firRelatedPartyPaymentScreenFactory.getRecipients = function(rowData){
    		 rowData = rowData || {}
    		 var params = { "action_code": 'o2nttw', "tax_year": rowData.tax_year};
    	     return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    	    	 console.log("After action call the Response was::::::", data);
    	         if(null != data.callSuccess && data.callSuccess == "1"){
    	        	 firRelatedPartyPaymentScreenFactory.formConfigData = data;
    	        	 return data;
    	         }else{
    	        	 AlertService.add("error", data.errorMessage,4000);
                     return false;
    	         }
    	     });
    	 }

    	 firRelatedPartyPaymentScreenFactory.getPayor = function(rowData){
    		 rowData = rowData || {}
    		 var params = { "action_code": '3pqapp', "tax_year": rowData.tax_year};
    	     return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    	    	 console.log("After action call the Response was::::::", data);
    	         if(null != data.callSuccess && data.callSuccess == "1"){
    	        	 firRelatedPartyPaymentScreenFactory.formConfigData = data;
    	        	 return data;
    	         }else{
    	        	 AlertService.add("error", data.errorMessage);
                     return false;
    	         }
    	     });
    	 }


    	 firRelatedPartyPaymentScreenFactory.getPaymentType = function(rowData){
    		 rowData = rowData || {}
    		 var params = { "action_code": '479nmu', "payment_type": rowData.PAYMENT_TYPE};
    	     return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    	    	 console.log("After action call the Response was::::::", data);
    	         if(null != data.callSuccess && data.callSuccess == "1"){
    	        	 firRelatedPartyPaymentScreenFactory.formConfigData = data;
    	        	 return data;
    	         }else{
    	        	 AlertService.add("error", data.errorMessage);
                     return false;
    	         }
    	     });
    	 }

    	 firRelatedPartyPaymentScreenFactory.addNewPayment = function(paymentData, clob_settings, sso_id,checkLockObj){
    		 var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=7hra73";
    		 var jsonSettings = JSON.stringify(clob_settings);
    		 var jsonObj = JSON.stringify(paymentData);
    		 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

			 GlobalService.modalLockFilters(checkLockObj); 
    		 var params = filterParams;
    		 params = _.merge({},  GlobalService.globalParams , params);
    		 params =  _.extend({jsonObj:jsonObj}, params);
    		 params =  _.extend({jsonSettings:jsonSettings}, params);
    		 params.sso_id = sso_id;
    		 params.filing_group =  1;
    		 console.log(params);
    		 var promise = $http({
    			 	method: "post",
    		        url: url,
    		        data: params
    		     }).then(function (response) {
    		    	 var status = response.status;
    		         return response;
    		     });
    		 return promise;
    	 }

    	 firRelatedPartyPaymentScreenFactory.deletePayment = function(paymentData, clob_settings, sso_id){
    		 var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ipq04o";
    		 var jsonSettings = JSON.stringify(clob_settings);
    		 var jsonObj = JSON.stringify(paymentData);
			 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		 var params = filterParams;

    		 params = _.merge({},  GlobalService.globalParams , params);
    		 //params =  _.extend({jsonObj:jsonObj}, params);
    		 // params =  _.extend({jsonSettings:jsonSettings}, params);
			 params.filing_key = workspaceFactory.activeScreen.filters.filterParams.filing_key;
    		 params.sso_id = sso_id;
    		 params.rp_seq_id = paymentData[0].rp_seq_id;
    		 //params.filing_group =  1;
    		 console.log(params);
    		 var promise = $http({
    			 	method: "post",
    		        url: url,
    		        data: params
    		     }).then(function (response) {
    		    	 var status = response.status;
    		         return response;
    		     });
    		 return promise;
    	 }

    	 return firRelatedPartyPaymentScreenFactory;
     }]);

    return services;
});



