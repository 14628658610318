define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.FormScheduleLFCtrl', [])
        .controller('F1120LSCHDFController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$rootScope', '$timeout', 'rowData', 'colData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', F1120LSCHDFController]);


    function F1120LSCHDFController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        
        // modalTitle shouldn't be hardcoded.
        vm.modalTitle = "Schedule F Increase (Decrease) in Reserves (section 807) and Company/Policyholder Share Percentage (section 812) (see instructions)";

        vm.netReserves = 0;
        vm.batchLoader = false;
        vm.showBatchData = false;
        vm.showRequestLoader = true;
        vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';
        
        vm.formData = {
            "addrows": [],
            "editrows": [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME,
            "LE TYPE": rowData.LE_TYPE,
            //"LEDGER": rowData.LEDGER,
            "ME": rowData.ME
        };

		$scope.unallocatedAmount = rowData.dig || {};

        var saveParamDtls = [];
        var tempDataObj = {};
        $scope.scheduleFFormView = {rows: []};
        vm.showFormLoader = true;
        function fetchSchFrecords() {
            var params = {
                "action_id": 603,
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO,
                "jcd_key": rowData.JCD_KEY,
                "combination_key": rowData.COMBINATION_KEY
            };
			
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadPivotFormJson', params).then(function (data) {
                vm.modalTitle = data.irsForm.formItemList[0].dataRowList[0].dataCellList[1].name; // get the title from service
               for (var i = 2; i < data.irsForm.formItemList.length; i++) {
                    saveParamDtls = [];
                    tempDataObj = {};
                    
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    tempDataObj.saveParams = saveParamDtls;                   

                    for (var j = 0; j < data.irsForm.formItemList[i].dataRowList[0].dataCellList.length; j++) {
                        if (j == 0) {
                            tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 1) {
                            tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 2) {
                            tempDataObj.lineAttrKeyA = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
							}
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                tempDataObj.value1 = 0;
                            } else {
                                tempDataObj.value1 = parseInt(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value);
                            }
                        } else if (j == 3){
                            tempDataObj.lineAttrKeyB = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
							}
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                tempDataObj.value2 = 0;
                            } else {
                                tempDataObj.value2 = parseInt(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value);
                            }
                        }else {
                            tempDataObj.lineAttrKeyC = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_c = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_c = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable3 = false;
							}else {
								tempDataObj.is_editable3 = true;
							}
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                tempDataObj.value3 = 0;
                            } else {
                                tempDataObj.value3 = parseInt(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value);
                            }
                        }
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    $scope.scheduleFFormView.rows.push(tempDataObj);
                }
                vm.formListOriginal = angular.copy($scope.scheduleFFormView);
                initFormView($scope.scheduleFFormView);
                vm.showFormLoader = false;
            });
        }

        function fetchSchFimports() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "status": 'Y'
            };
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=wwledr', params).then(function (data) {
                vm.BatchList = data.jsonObject;
                angular.forEach(vm.BatchList, function (value, key) { 
                    vm.BatchList[key].TOGGLE = false; 
                });
                vm.showRequestLoader = false;
            });
        }

        fetchSchFrecords();
        fetchSchFimports();
        
        function loadTransactions(index, trans_key){
            vm.batchLoader = true;
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "transaction_key": trans_key
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=l6gzle', params).then(function (data) {
                var tempData = data.jsonObject;
                var currLineNo;
                var tempBatchList = {};
                var tempBatchData = [];
                angular.forEach(tempData, function (value, key) {
                    if(key == 0){
                        currLineNo = tempData[key].LINE_NO;
                        tempBatchList = tempData[key];
                    }else{
                        if(tempData[key].LINE_NO == currLineNo){
                            tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                            tempBatchData.push(tempBatchList);
                        }else{
                            currLineNo = tempData[key].LINE_NO;
                            tempBatchList = {};
                            tempBatchList = tempData[key];
                        }
                    }
                });
                vm.batchLoader = false;
                vm.showBatchData = true;
                vm.BatchListData = tempBatchData;
            });
        };

        var prev_index = null;
        vm.showUploads = function(index, trans_key){
            if(prev_index !== null){
                if(prev_index !== index){
                    vm.BatchList[prev_index].TOGGLE = false;
                    prev_index = index;
                    vm.showBatchData = false;
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    loadTransactions(index, trans_key);
                }else{
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    vm.showBatchData = true;
                }
            }else{
                prev_index = index;
                vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                loadTransactions(index, trans_key);
            }
        };

        vm.computeValues = function (row) {
            switch (rowData.TAX_YEAR) {
                case 2017:
                    vm.computeValues2017(row);
                    break ;
                case 2018:
                    vm.computeValues2018(row);
                    break;
                case 2019:
                    vm.computeValues2019(row);
                    break;    
                default: 
                    vm.computeValues2019(row);
            }
        }

        vm.computeValues2019 = function(row) {
            
            // for NaN issue
            for(let i=0; i<12; i++){
                $scope.scheduleFFormView.rows[i].value1 = $scope.scheduleFFormView.rows[i].value1 || 0;
                $scope.scheduleFFormView.rows[i].value2 = $scope.scheduleFFormView.rows[i].value2 || 0;
            }

            // Line 7 a & b: add lines 1 through 6
            $scope.scheduleFFormView.rows[6].value1 =0;
            $scope.scheduleFFormView.rows[6].value2=0;
            for(let i =0; i< 6; i++){
                $scope.scheduleFFormView.rows[6].value1 += Math.round($scope.scheduleFFormView.rows[i].value1);
                $scope.scheduleFFormView.rows[6].value2 += Math.round($scope.scheduleFFormView.rows[i].value2);
            }

            // Line 8
            //a
            $scope.scheduleFFormView.rows[7].value1 = "";
            //b
            $scope.scheduleFFormView.rows[7].value2 =
                $scope.scheduleFFormView.rows[6].value2 - $scope.scheduleFFormView.rows[6].value1;
            
            //Line 11
            //a
            $scope.scheduleFFormView.rows[10].value1 = "";
            //b
            $scope.scheduleFFormView.rows[10].value2 = 
                Math.round($scope.scheduleFFormView.rows[8].value1 * 0.3);
            
            //Line 12
            $scope.scheduleFFormView.rows[11].value2 =
                $scope.scheduleFFormView.rows[7].value2 - ($scope.scheduleFFormView.rows[10].value2 + $scope.scheduleFFormView.rows[10].value3);

            vm.netReserves=$scope.scheduleFFormView.rows[11].value2;
            // vm.unallocatedAmount = rowData.TB_AMT - vm.netReserves;

            // TODO : change it to generic computation
            $scope.unallocatedAmount[0][0].value = $scope.unallocatedAmount[1][0].value = $scope.scheduleFFormView.rows[11].value2;
            $scope.unallocatedAmount[0][2].value = $scope.unallocatedAmount[0][0].value - $scope.unallocatedAmount[0][1].value;
            $scope.unallocatedAmount[1][2].value = $scope.unallocatedAmount[1][0].value - $scope.unallocatedAmount[1][1].value;

            
        }


        vm.computeValues2018 = function(row) {
            
            // for NaN issue
            for(let i=0; i<12; i++){
                $scope.scheduleFFormView.rows[i].value1 = $scope.scheduleFFormView.rows[i].value1 || 0;
                $scope.scheduleFFormView.rows[i].value2 = $scope.scheduleFFormView.rows[i].value2 || 0;
            }

            // Line 7 a & b: add lines 1 through 6
            $scope.scheduleFFormView.rows[6].value1 =0;
            $scope.scheduleFFormView.rows[6].value2=0;
            for(let i =0; i< 6; i++){
                $scope.scheduleFFormView.rows[6].value1 += Math.round($scope.scheduleFFormView.rows[i].value1);
                $scope.scheduleFFormView.rows[6].value2 += Math.round($scope.scheduleFFormView.rows[i].value2);
            }

            // Line 8
            //a
            $scope.scheduleFFormView.rows[7].value1 = "";
            //b
            $scope.scheduleFFormView.rows[7].value2 =
                $scope.scheduleFFormView.rows[6].value2 - $scope.scheduleFFormView.rows[6].value1;
            
            //Line 11
            //a
            $scope.scheduleFFormView.rows[10].value1 = "";
            //b
            $scope.scheduleFFormView.rows[10].value2 = 
                Math.round($scope.scheduleFFormView.rows[8].value1 * 0.3);
            
            //Line 12
            $scope.scheduleFFormView.rows[11].value2 =
                $scope.scheduleFFormView.rows[7].value2 - ($scope.scheduleFFormView.rows[10].value2 + $scope.scheduleFFormView.rows[10].value3);

            vm.netReserves=$scope.scheduleFFormView.rows[11].value2;
            // vm.unallocatedAmount = rowData.TB_AMT - vm.netReserves;

            // TODO : change it to generic computation
            $scope.unallocatedAmount[0][0].value = $scope.unallocatedAmount[1][0].value = $scope.scheduleFFormView.rows[11].value2;
            $scope.unallocatedAmount[0][2].value = $scope.unallocatedAmount[0][0].value - $scope.unallocatedAmount[0][1].value;
            $scope.unallocatedAmount[1][2].value = $scope.unallocatedAmount[1][0].value - $scope.unallocatedAmount[1][1].value;

            
        }

        vm.computeValues2017 = function(row) {
            $scope.scheduleFFormView.rows[6].value1 = $scope.scheduleFFormView.rows[0].value1 + $scope.scheduleFFormView.rows[1].value1 +
                $scope.scheduleFFormView.rows[2].value1 + $scope.scheduleFFormView.rows[3].value1 +
                $scope.scheduleFFormView.rows[4].value1 + $scope.scheduleFFormView.rows[5].value1;
            //Line 7 b (add line 1b to line 6b )
            $scope.scheduleFFormView.rows[6].value2 = $scope.scheduleFFormView.rows[0].value2 + $scope.scheduleFFormView.rows[1].value2 +
                $scope.scheduleFFormView.rows[2].value2 + $scope.scheduleFFormView.rows[3].value2 +
                $scope.scheduleFFormView.rows[4].value2 + $scope.scheduleFFormView.rows[5].value2;

            //Line 8 (Line 7b- 7a)
            $scope.scheduleFFormView.rows[7].value2 = $scope.scheduleFFormView.rows[6].value2 - $scope.scheduleFFormView.rows[6].value1;
            // Line 10f F (Line )
            $scope.scheduleFFormView.rows[14].value2 = $scope.scheduleFFormView.rows[9].value1 + $scope.scheduleFFormView.rows[10].value1 +
                $scope.scheduleFFormView.rows[11].value1 + $scope.scheduleFFormView.rows[12].value1 +
                $scope.scheduleFFormView.rows[13].value1;
            // Line 11
            $scope.scheduleFFormView.rows[15].value2 = $scope.scheduleFFormView.rows[8].value2 - $scope.scheduleFFormView.rows[14].value2;
            // Line 14
            $scope.scheduleFFormView.rows[18].value2 = $scope.scheduleFFormView.rows[16].value1 + $scope.scheduleFFormView.rows[17].value1;
            //Line 15
            if ($scope.scheduleFFormView.rows[7].value2 < 0) {
                $scope.scheduleFFormView.rows[19].value2 = 0;
            } else {
                $scope.scheduleFFormView.rows[19].value2 = $scope.scheduleFFormView.rows[7].value2;
            }
            //Line 16            
            $scope.scheduleFFormView.rows[20].value2 = $scope.scheduleFFormView.rows[18].value2 - $scope.scheduleFFormView.rows[19].value2;
            // line 17 
            var temp = $scope.scheduleFFormView.rows[15].value2 / $scope.scheduleFFormView.rows[20].value2;
            if (temp < 0) {
                $scope.scheduleFFormView.rows[21].value2 = 0;
            } else {
                $scope.scheduleFFormView.rows[21].value2 = temp;
            }
            //Line 18b
            $scope.scheduleFFormView.rows[23].value1 = $scope.scheduleFFormView.rows[10].value1;

            // Line 18e (Line )
            $scope.scheduleFFormView.rows[26].value2 = $scope.scheduleFFormView.rows[22].value1 + $scope.scheduleFFormView.rows[23].value1 +
                $scope.scheduleFFormView.rows[24].value1 + $scope.scheduleFFormView.rows[25].value1;
            //Line 19
            $scope.scheduleFFormView.rows[27].value1 = $scope.scheduleFFormView.rows[10].value1;

         // Line 23 (Line )
            $scope.scheduleFFormView.rows[31].value2 = $scope.scheduleFFormView.rows[27].value1 + $scope.scheduleFFormView.rows[28].value1 +
                $scope.scheduleFFormView.rows[29].value1 + $scope.scheduleFFormView.rows[30].value1;
            //Line 24            
            $scope.scheduleFFormView.rows[32].value2 = $scope.scheduleFFormView.rows[26].value2 - $scope.scheduleFFormView.rows[31].value2;

            //Line 25            
            $scope.scheduleFFormView.rows[33].value2 = $scope.scheduleFFormView.rows[21].value2 * $scope.scheduleFFormView.rows[32].value2;
            //Line 26            
            $scope.scheduleFFormView.rows[34].value2 = $scope.scheduleFFormView.rows[14].value2;
            //Line 27            
            $scope.scheduleFFormView.rows[35].value2 = $scope.scheduleFFormView.rows[34].value2 + $scope.scheduleFFormView.rows[33].value2;
            // Line 29
            $scope.scheduleFFormView.rows[37].value2 = $scope.scheduleFFormView.rows[35].value2;
            //Line 30            
            $scope.scheduleFFormView.rows[38].value2 = $scope.scheduleFFormView.rows[36].value2 - $scope.scheduleFFormView.rows[37].value2;
            //Line 31
            $scope.scheduleFFormView.rows[39].value2 = 100;
            //Line 32      
            if (Number($scope.scheduleFFormView.rows[36].value2) == 0) {
                $scope.scheduleFFormView.rows[40].value2 = 0;
            } else {
                $scope.scheduleFFormView.rows[40].value2 = $scope.scheduleFFormView.rows[38].value2 / $scope.scheduleFFormView.rows[36].value2;
            }
            //Line 33            
            $scope.scheduleFFormView.rows[41].value2 = $scope.scheduleFFormView.rows[39].value2 - $scope.scheduleFFormView.rows[40].value2;

            //Line 34            
            $scope.scheduleFFormView.rows[42].value2 = $scope.scheduleFFormView.rows[41].value2 + $scope.scheduleFFormView.rows[17].value2;
            //Line 35            
            $scope.scheduleFFormView.rows[43].value2 = $scope.scheduleFFormView.rows[7].value2 + $scope.scheduleFFormView.rows[42].value2;
            vm.netReserves=$scope.scheduleFFormView.rows[43].value2;
            // vm.unallocatedAmount = rowData.UNALLOCATED_AMT - vm.netReserves;					
        }

        vm.saveSchddForm = function (url, data) {
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);
            params.process_name = workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                console.log('--------------------------------------------', response.data, '----------------------------------------------');
                console.log("saveSchddForm() response.data.jsonObj ::", response.data.jsonObject);
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage);
                    
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.saveDiagnostic = function(url, diagnosticData){
            var params =  _.extend({diag_exception :diagnosticData});
            params.tax_year =  rowData.TAX_YEAR;
            params.screen_key =  workspaceFactory.activeScreen.screen_key;
            params.scenario = rowData.SCENARIO;
            params.jcd_key = rowData.JCD_KEY;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        vm.save = function () {
            var formRowDataA = {};
            var formRowDataB = {};
            var formRowDataC = {};
            var tempArray = [];

            for (var i = 0; i < $scope.scheduleFFormView.rows.length; i++) {
                formRowDataA = {
                    tax_year: rowData.TAX_YEAR,
                    trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                    line_order: $scope.scheduleFFormView.rows[i].line_order,
                    attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_a,
                    line_description: $scope.scheduleFFormView.rows[i].description,
                    combination_key: rowData.COMBINATION_KEY,
                    group_obj_key: rowData.GROUP_OBJ_KEY,
                    form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                    line_no: $scope.scheduleFFormView.rows[i].num,
                    occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                    line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyA,
                    Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_a,
                    Attribute_Value: $scope.scheduleFFormView.rows[i].value1,
                    trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                    trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                    is_dirty: ''
                };
                formRowDataB = {
                    tax_year: rowData.TAX_YEAR,
                    trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                    line_order: $scope.scheduleFFormView.rows[i].line_order,
                    attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_b,
                    line_description: $scope.scheduleFFormView.rows[i].description,
                    combination_key: rowData.COMBINATION_KEY,
                    group_obj_key: rowData.GROUP_OBJ_KEY,
                    form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                    line_no: $scope.scheduleFFormView.rows[i].num,
                    occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                    line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyB,
                    Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_b,
                    Attribute_Value: $scope.scheduleFFormView.rows[i].value2,
                    trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                    trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                    is_dirty: ''
                };
                if(typeof $scope.scheduleFFormView.rows[i].value3 !== "undefined"){
					formRowDataC = {
						tax_year: rowData.TAX_YEAR,
						trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
						line_order: $scope.scheduleFFormView.rows[i].line_order,
                    	attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_c,
                    	line_description: $scope.scheduleFFormView.rows[i].description,
						combination_key: rowData.COMBINATION_KEY,
						group_obj_key: rowData.GROUP_OBJ_KEY,
						form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
						line_no: $scope.scheduleFFormView.rows[i].num,
						occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
						line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyC,
						Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_c,
						Attribute_Value: $scope.scheduleFFormView.rows[i].value3,
						trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
						trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
						is_dirty: ''
					};
                };
                tempArray.push(formRowDataA);
                tempArray.push(formRowDataB);
                tempArray.push(formRowDataC);
            }
            vm.formData.editrows.push(tempArray);
            vm.mainData = [];
            vm.mainData.push(vm.formData);
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nkepph";
            var saveDiagURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ftmltp";
            vm.saveSchddForm(url, vm.mainData);
            var diagData = [];
            angular.forEach($scope.unallocatedAmount, function (array, key) {
                angular.forEach(array, function (obj, key) {
                    if(key === 3){
                        diagData.push(obj);
                    }
                });
            });
            vm.saveDiagnostic(saveDiagURL, JSON.stringify(diagData));
            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function () {
                $scope.$broadcast("showLoader", true);
            }, 100);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.reset = function () {
            $scope.scheduleFFormView = angular.copy(vm.formListOriginal);
			vm.computeValues();
        }
        
        function initFormView(filteredFormData) {
            $scope.scheduleFFormView = filteredFormData;
            vm.computeValues();
        }

        //Dynamic Tabset Code
        vm.tabs = [
            {id: 1, name: "Form Entry", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFEntry.html", active: true },
            {id: 2, name: "Form View", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFView.html", active: false},
            {id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFVersion.html", active: false}
            // {id: 4, name: "PDF", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFPdf.html", active: false}
        ];
    }
});