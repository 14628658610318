/**
 * Created by 212544474 on 4/1/2016.
 */
define([
    'angular'

], function () {
    'use strict';


    //console.log("_dcsSchdBCController ==============================================================================================================");

    var controllers =  angular.module('app.ProjectTagCtrl',[])
        .controller('ProjectTagController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'gridData' ,'JsonObjectFactory','$timeout','ProjectTaggingFactory','GENERAL_CONFIG', projectTagController])


    function projectTagController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, gridData, JsonObjectFactory,$timeout,ProjectTaggingFactory,GENERAL_CONFIG){


        var vm = this;
        vm.title = "Project Tag";
        vm.entities = [];
        vm.projectList = [];
        vm.projectStepList = [];
        vm.project = null;
        vm.projectStep = null;
        vm.projectLoading = true;
        vm.projectStepLoading = false;
        vm.hideProjectSteps = false;


        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };


        function init(){

            if( !_.isArray(gridData)){

                var obj  = {}
                obj.row_id = gridData.rowData.object_id;
                obj.data = gridData.rowData;
                gridData = [];
                gridData.push(obj)
            }

            loadProjects();
            vm.entities  = _.clone(gridData);
        }

       function loadProjects() {
            var params = {
                "action_id": 31,
            }

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                   if (data.callSuccess !== "1") {
                       AlertService.add("error", data.errorMessage);
                       vm.projectLoading = false;
                   } else {
                      if(vm){
                          vm.projectList = data.jsonObject;
                          vm.projectLoading = false;
                          var untag = {PROJECT_ID:null,PROJECT_NAME:"Un TAG Project"}
                          vm.projectList.unshift(untag);
                          setProject();
                          vm.loadProjectSteps();
                      }else{
                          //console.log("element closed before ajax call")
                      }


                   }


            });
        };


       function setProject(){
           try {
               var projectArray = [];
               _.forEach(vm.entities, function (value, key) {
                   var project_id = value.data.PROJECT_ID;
                   if (!_.includes(projectArray, project_id)) {
                       projectArray.push(project_id);
                   }
               });
               if (projectArray.length === 1) {
                   var project = projectArray[0];
                   vm.project = _.find(vm.projectList, {'PROJECT_ID': project});
               }
           }
           catch (e) {
               // statements to handle any exceptions
               //console.info(e); // pass exception object to error handler
           }
       }


        function setProjectStep(){

            try {
                var projectStepArray = [];
                _.forEach(vm.entities, function (value, key) {
                    var step_id = value.data.STEP_ID;

                    if (!_.includes(projectStepArray, step_id)) {
                        projectStepArray.push(step_id);
                    }



                });

                if (projectStepArray.length === 1) {
                    var projectStep = parseInt(projectStepArray[0]);
                    vm.projectStep = _.find(vm.projectStepList, {'PROJECT_STEP_ID': projectStep});




                }
            }
            catch (e) {
                // statements to handle any exceptions
                //console.info(e); // pass exception object to error handler
            }


        }


        vm.loadProjectSteps = function() {

           if( vm.project == null || typeof vm.project == "undefined" || typeof vm.project.PROJECT_ID == "undefined" ||  vm.project.PROJECT_ID === "" ||  vm.project.PROJECT_ID == null){
               vm.hideProjectSteps = true;
               vm.projectStep = null;
               return false;
           }

           vm.hideProjectSteps = false;
           vm.projectStepLoading = true;
            var params = {
                "action_id": 32,
                "project_id":vm.project.PROJECT_ID,
            }
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                    vm.projectStepLoading = false;
                } else {

                    if(vm) {

                        vm.projectStepList = data.jsonObject;
                        vm.projectStepLoading = false;

                        ////console.log("vm.projectStepList.length :" , vm.projectStepList.length);
                        ////console.log("vm.projectStepList :" , vm.projectStepList);


                        if (vm.projectStepList.length === 0) {
                            vm.hideProjectSteps = true;
                            vm.projectStep = null;

                        } else {
                            vm.hideProjectSteps = false;
                            setProjectStep();

                        }


                    }

                }
            });
        };




        function projectTagModel(key,leid,cdr_no,reporting_period,bsla_code,reporting_curr,schdm_id,schdm_source,project_id,step_id,posting_subtdc){
            this.key = key;
            this.leid = leid;
            this.cdr_no = cdr_no;
            this.reporting_period = reporting_period;
            this.bsla_code = bsla_code;
            this.reporting_curr = reporting_curr;
            this.schdm_id = schdm_id;
            this.schdm_source = schdm_source;
            this.project_id = project_id;
            this.step_id = step_id;
            this.posting_subtdc = posting_subtdc;
        }


        vm.save = function(){

            if (vm.projectTagFrom.$invalid) {
                vm.projectTagFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            var projectTagArr = [];

            _.forEach(vm.entities, function(value, key) {



                var project_id = (  vm.project == null || typeof vm.project.PROJECT_ID == 'undefined' || vm.project.PROJECT_ID == null ) ? 0 : vm.project.PROJECT_ID;
                var project_step_id = (  vm.projectStep == null || typeof vm.projectStep.PROJECT_STEP_ID == 'undefined') ? 0 : vm.projectStep.PROJECT_STEP_ID;

                ////console.log("======================   project_id   ==========================");
                ////console.log(" project_id:  " , project_id);
                ////console.log(" project_step_id:  " , project_step_id);
                ////console.log("=========================   project_id   =======================");

                var object = value.data;
                var tagModel = new projectTagModel(object.SYS_ID,object.LEID,object.CDR_NO,object.REPORTING_PERIOD,object.BSLA_CODE,object.CURRENCY,object.SCHDM_ID,object.SCHDM_SOURCE,project_id,project_step_id,object.POSTING_SUBTDC);
                projectTagArr.push(tagModel);

            })





            vm.crudLoading = true;


            ProjectTaggingFactory.saveProjectTag(projectTagArr).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{


                   if( vm.project.PROJECT_ID == null ){
                        AlertService.add("success", "Project was successfully Untagged " , 4000);
                    }else{
                       AlertService.add("success", "Project was successfully tagged " , 4000);
                    }


                    var key_string = buildKeys();
                    var args = {key_string:key_string,parent_me_string:null};
                    $rootScope.$emit('gridUpdate', args);
                    vm.crudLoading = false;
                }
            });

             /*
             var key_string = buildKeys();
             var args = {key_string:key_string,parent_me_string:null};
             $rootScope.$emit('gridUpdate', args);
             vm.crudLoading = false;
             */

        }





        function buildKeys(){
            var keyString = "";
            var loopNum = 0;
            _.forEach(vm.entities, function(_value, key) {
                if( loopNum > 0){
                    keyString += ",";
                }
                var value = _value.data;
                ////console.log(" value " , value)
                keyString += value.LEID + "_" +value.CDR_NO + "_" +value.REPORTING_PERIOD + "_" +value.BSLA_CODE + "_" +value.CURRENCY;
                loopNum++;
            });

            return keyString;
        }


        vm.removeEntity = function(_index){
            vm.entities.splice(_index,1);
            if( vm.entities.length === 0 ){
                $uibModalInstance.dismiss('cancel');
            }
        }


        init();

        $scope.$on("$destroy", function() {
            ////console.log("DESTROY ME")
            vm = null;
        })


    }///////////////END CTRL

    return controllers;


});