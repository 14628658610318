define([
   'angular'

], function () {
   'use strict';

   var controllers = angular.module('app.griCellAuditCtrl', [])
      .controller('cellAuditCtrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
         'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', '$uibModalInstance', 'rowData', 'gridData', 'colData', 'GridCellAuditFactory', 'GridSaveFactory', cellAuditCtrl
      ]);

   function cellAuditCtrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory,
      GENERAL_CONFIG, $uibModalInstance, rowData, gridData, colData, GridCellAuditFactory, GridSaveFactory) {
      var vm = this;
      vm.rowData = rowData;
      vm.colData = colData;
      vm.gridData = gridData;
      vm.undoData = [];
      vm.undoDataCloneDeep = [];
      vm.toDate = {
         value_name: "VALUE",
         label_name: "LABEL"
      }
      vm.fromDate = {
         value_name: "VALUE",
         label_name: "LABEL"
      }
      vm.clearSerach = function () {
         $scope.searchText = "";
      }

      /* Date Filters */
      vm.applyFilter = function () {
         var fromDate = vm.fromDate.selected != undefined ? vm.fromDate.selected.VALUE.replace(/\//g, '-') : '';
         var toDate = vm.toDate.selected != undefined ? vm.toDate.selected.VALUE.replace(/\//g, '-') : '';
         vm.undoData = _.cloneDeep(_.filter(vm.undoDataCloneDeep, function (item) {
            if(moment(fromDate, 'MM-DD-YYYY', true).isValid() && moment(toDate, 'MM-DD-YYYY', true).isValid()){
               return (item.updated_on >= fromDate && item.updated_on <= toDate);
            }else if(moment(fromDate, 'MM-DD-YYYY', true).isValid() ){
               return item.updated_on >= fromDate;
            }
            else if(moment(toDate, 'MM-DD-YYYY', true).isValid() ){
                   return item.updated_on <= toDate;
            }
            else{
               return item;
            }

         }));
      }

      var filterCached = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
      vm.getCellAuditData = function () {

         var url = GENERAL_CONFIG.base_url + '/getAuditCellByContext';
         var data = {
            filters: JSON.stringify(filterCached),
            screen_key: GlobalService.globalParams.screen_key, //_data.screen_key,
            row_key: vm.gridData.selected.row_id,
            col_key: vm.gridData.selected.col_id
         };

         GridCellAuditFactory.getCellAuditByContext(url, data).then(function (data) {
            console.log(data);
            if (data.callSuccess && data.callSuccess === "1") {
               console.log("get audit cell Data", data);
               /* if cellAuditData length is 0 which means previously the cell doesn't have any audit records then insert the record selected cell data into the audit table 8/6/2019  */
               if(data.CellAuditData.length==0){
                  var _callName = vm.gridData.selected.colData.call_back;
                  var _data = vm.gridData.selected;
                  var auditData = {
                                    col_key:_data.col_id,
                                    row_key:_data.row_id,
                                    location_element:_data.select_type,
                                }
                  //GridSaveFactory.saveAuditCellData(_data,auditData)
                //  vm.getCellAuditData();
               }

               vm.undoData = data.CellAuditData;
               vm.undoDataCloneDeep = vm.undoData;
            } else {
               AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
            }
         });
      }

      vm.revert = function (detail) {
         var _callName = vm.gridData.selected.colData.call_back;
         var _data = vm.gridData.selected;
         _data.rowData[_data.colData.map] = detail.audit_cell_data;
         GridSaveFactory.saveSwitch(_callName, _data);
         vm.cancel();
      }

      $scope.timeago = function (date) {
         var date = new Date(date);
         return moment(date).fromNow();
      }
      vm.getCellAuditData();
      vm.cancel = function () {
         $uibModalInstance.dismiss('cancel');
      }
   }
});