define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.F1125ScheduleACtrl', [])
        .controller('F1125ScheduleAController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$filter','$rootScope', '$timeout', 'rowData', 'colData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', F1125ScheduleAController]);


    function F1125ScheduleAController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $filter, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        
        // modalTitle shouldn't be hardcoded.
        vm.modalTitle = "Schedule F Increase (Decrease) in Reserves (section 807) and Company/Policyholder Share Percentage (section 812) (see instructions)";
        vm.userSettings = USER_SETTINGS;
        //getcodeMastDetails();
		vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.netReserves = 0;
        vm.batchLoader = false;
        vm.showBatchData = false;
        vm.showRequestLoader = true;
        vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';
        vm.codeMastMap = [];
        vm.isSaving = false;
        
        vm.formData = {
            "addrows": [],
            "editrows": [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME
        };

        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = 'Cost of Goods Sold' + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';

		$scope.unallocatedAmount = rowData.dig || {};

        var saveParamDtls = [];
        var tempDataObj = {};
        var tempDataObj2 = {};
        $scope.scheduleFFormView = {rows: []};
        vm.showFormLoader = true;
        function getmodalData() {
            var params = {
                "action_id": 31286,
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY,
                "jcd_key": rowData.JCD_KEY,
                "combination_key": rowData.COMBINATION_KEY, 
                "schedule_name": "A"
            };
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadPivotFormJson', params).then(function (data) {
                vm.modalTitle = data.irsForm.formItemList[0].dataRowList[0].dataCellList[1].name; // get the title from service
               for (var i = 0; i < data.irsForm.formItemList.length; i++) {
                    saveParamDtls = [];
                    tempDataObj = {};
                    tempDataObj2 = {};
                    
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    tempDataObj.saveParams = saveParamDtls;                   
                    tempDataObj2.saveParams = saveParamDtls;

                    for (var j = 0; j < data.irsForm.formItemList[i].dataRowList[0].dataCellList.length; j++) {
                        if (j == 0) {
                            tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                            tempDataObj2.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 1) {
                            tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                            tempDataObj2.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 2) {
                            tempDataObj.lineAttrKeyA = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(i== 17 ){
                            	 if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                     tempDataObj.value1 = 'false';
                                 } else {
                                     tempDataObj.value1 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                                 }
                            }else{
                           	  tempDataObj.value1 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                            }
                        } else {
                            tempDataObj2.lineAttrKeyB = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj2.attrib_name_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj2.attrib_order_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(i== 17 ){
                                if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                    tempDataObj2.value1 = 'false';
                                } else {
                                    tempDataObj2.value1 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                                }
                           }else{
                                tempDataObj2.value1 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                           }
                        }  
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    tempDataObj2.line_order = saveParamDtls[8];
                    $scope.scheduleFFormView.rows.push(tempDataObj);
                    if(tempDataObj2?.attrib_name_b){
                        $scope.scheduleFFormView.rows.push(tempDataObj2);
                    }
                }
               $scope.scheduleFFormView.rows[1].value1 = rowData.LE_NAME; 
               $scope.scheduleFFormView.rows[2].value1 = rowData.EIN;
               vm.formListOriginal = angular.copy($scope.scheduleFFormView);
               initFormView($scope.scheduleFFormView);
               vm.showFormLoader = false;
            });
        }
        
        getmodalData();// this is the main method which gets data to be displayed on the modal

        vm.save1065Page1 = function (url, data) {
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);
            params.process_name = workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
            	vm.showFormLoader = false;
                var status = response.status;
                console.log('------------------status is: --------------------------', status);
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage );
                }
                vm.isSaving = false;
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };
        
       /* vm.computeValuesFor6 = function() {
       	 $scope.scheduleFFormView.rows[8].value1 =  $scope.scheduleFFormView.rows[3].value1 + 
											         $scope.scheduleFFormView.rows[4].value1 +
											         $scope.scheduleFFormView.rows[5].value1 +
											         $scope.scheduleFFormView.rows[6].value1 +
											         $scope.scheduleFFormView.rows[7].value1 ;
       	vm.computeValuesFor8();
        }
        
        vm.computeValuesFor8 = function() {
          	 $scope.scheduleFFormView.rows[10].value1 =  $scope.scheduleFFormView.rows[8].value1 - $scope.scheduleFFormView.rows[9].value1  ;
          	 
        }*/

        vm.clickLine17 = function(){
            if($scope.scheduleFFormView.rows[17].value1 == 'false'){
                $scope.scheduleFFormView.rows[18].value1 = 0;
            }
        }
        
        vm.computeValuesForEandF1 = function() {
        	if( $scope.scheduleFFormView.rows[19].value1 =='true'){
        		$scope.scheduleFFormView.rows[20].value1 ='false';
        	}
        }
        
        vm.computeValuesForEandF2 = function() {
        	if( $scope.scheduleFFormView.rows[20].value1 =='true'){
        		$scope.scheduleFFormView.rows[19].value1 ='false';
        	}
        }
        
        vm.computeValuesForEandF3 = function() {
        	if( $scope.scheduleFFormView.rows[21].value1 =='true'){
        		$scope.scheduleFFormView.rows[22].value1 ='false';
        	}
        }
        
        vm.computeValuesForEandF4 = function() {
        	if( $scope.scheduleFFormView.rows[22].value1 =='true'){
        		$scope.scheduleFFormView.rows[21].value1 ='false';
        	}
        }
			 
        
        vm.save = function () { 
        	if($scope.scheduleFFormView.rows[14].value1=='false'){
        		$scope.scheduleFFormView.rows[15].value1='';
        	}
            vm.isSaving = true;
          	vm.showFormLoader = true;
        		var formRowDataA = {};
                var formRowDataB = {};
                var tempArray = [];
                for (var i = 0; i < $scope.scheduleFFormView.rows.length; i++) {
                    formRowDataA = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleFFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_a || $scope.scheduleFFormView.rows[i].attrib_order_b,
                        line_description: $scope.scheduleFFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleFFormView.rows[i].num,
                        occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyA || $scope.scheduleFFormView.rows[i].lineAttrKeyB,
                        Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_a || $scope.scheduleFFormView.rows[i].attrib_name_b,
                        Attribute_Value: $scope.scheduleFFormView.rows[i].value1,
                        trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    tempArray.push(formRowDataA);
                }
                vm.formData.editrows.push(tempArray);
                vm.mainData = [];
                vm.mainData.push(vm.formData);
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=jmz70s";
                vm.save1065Page1(url, vm.mainData);
                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function () {
                    $scope.$broadcast("showLoader", true);
                }, 100); 
        	};	 

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.reset = function () {
            $scope.scheduleFFormView = angular.copy(vm.formListOriginal);
        }
        
        function initFormView(filteredFormData) {
            $scope.scheduleFFormView = filteredFormData;
        }

        //Dynamic Tabset Code
        vm.tabs = [ 
            {id: 1, name: "Form Entry", url: "app/components/partnership/F1125-Sch-A/F1125-ScheduleA-Entry.html", active: true }
            //{id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFVersion.html", active: false}
        ];
    }
});