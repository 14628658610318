define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.tbbsdiagnostics', [])
					.factory(
							"TbReclassDiaFactoryA",
							[
									'$q',
									'$http',
									'$stateParams',
									'$timeout',
									'$rootScope',
									'$log',
									'AlertService',
									'$injector',
									'GlobalService',
									'DataFiltersFactory',
									'JsonObjectFactory',
									'workspaceFactory',
									'GENERAL_CONFIG',
									function($q, $http, $stateParams, $timeout,
											$rootScope, $log, AlertService,$injector,
											GlobalService, DataFiltersFactory,
											JsonObjectFactory,
											workspaceFactory, GENERAL_CONFIG) {
										var tbreclassdiafactoryA = {};
								        var service = $injector.get('JsonObjectFactory');

										tbreclassdiafactoryA.saveTBBS_Diagnostics = function(
												_callName, _data) {
											var checked = '';
											if (_data.rowData.IS_EXCLUDED === 1) {
												checked = true;
											} else {
												checked = false;
											}

											/*var params = {
												"action_code" : 'vl20dz',
												"process_name": 'Save_TBBS_DIAGNOSTICS'

											}
*/
											var jsonObj = JSON
													.stringify(_data.rowData);
											var combination_key = _data.rowData.COMBINATION_KEY
											/*params = _.extend({
												jsonObj : jsonObj
											}, params);*/
											let jsonSettings = {
												tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
												scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
												jcd_key: GlobalService.globalParams.jcd_key == "" ? 250 : GlobalService.globalParams.jcd_key
											};
											jsonSettings = JSON.stringify(jsonSettings);
											 var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
								               var params = _.merge({}, GlobalService.globalParams, filterParams);
								               params = _.extend({jsonObj : jsonObj}, params);
											   params = _.extend({jsonSettings : jsonSettings}, params);
								               params.process_name =  "Save_TBBS_DIAGNOSTICS";							  	           
								  	         var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vl20dz";
								  	           
								  	          
								  	          	  service.saveJSON(url , params).then(function(data){
											
																if (data.callSuccess === "1") {

																	AlertService
																			.add(
																					"success",
																					"Data has been Save",
																					3000);

																	var args = {

																		combi_key : combination_key,

																		gridFilter : {
																			COMBINATION_KEY : combination_key
																		}
																	};

																	$rootScope
																			.$emit(
																					'gridUpdate',
																					args);
																} else {
																	if (data.errorMessage
																			&& data.errorMessage.length > 0
																			&& data.errorMessage !== 'null') {
																		AlertService.add("error",
																				data.errorMessage);
																	} 
																else{
																	AlertService
																			.add(
																					"error",
																					"Data retrieval failed",
																					3000);
																}
																}

															});


										};
										return tbreclassdiafactoryA;
									} ]);

			return services;

		});