define([
    'angular',
    'vsRepeat',
    './formViewCtrl',
    './formViewServices',
    './formViewDirectives',
], function () {
    'use strict';
    return angular.module('app.formView', ['app.formViewCtrl',  'app.formViewServices','app.formViewDirectives', 'vs-repeat'])
        // .config(['$stateProvider','$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        //     $stateProvider
        //         .state('form-view', {
        //             url: '/form-view',
        //             templateUrl: 'app/components/formView/form-view.html',
        //             data:{},
        //             access:""
        //         })
        // }])

});
