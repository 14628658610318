define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.secondaryActionDashboardController', [])
            .controller('secondaryActionDashboardController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'secondaryActionDashboardServiceFactory',
                'rowData', 'gridData', 'ObjectDiff', secondaryActionDashboardController])
            
        function secondaryActionDashboardController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, secondaryActionDashboardServiceFactory, rowData, gridData, ObjectDiff) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.userSettings = USER_SETTINGS;
            vm.tax_year = filterParams.tax_year;
            vm.scenario = filterParams.scenario;
            vm.jcd_key = GlobalService.globalParams.jcd_key;
            vm.logged_in_user = vm.userSettings.user.sso_id;
            vm.currentyear = filterParams.tax_year;
            vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
            vm.isSaveClicked = false;
            vm.syncSaveDtls = [];
            vm.secondaryActionDashboard = [];
            vm.showJsonLoader = true;
            vm.jsonDisplayData = [];
            vm.showJSON = false;

            vm.getJsonData = function(key){
                secondaryActionDashboardServiceFactory.getJsonData(key).then(function(data) {
                    vm.jsonDisplayData = data.jsonObject[0];
                    //vm.jsonDisplayData.REQUEST_PARAM = JSON.parse(vm.jsonDisplayData.REQUEST_PARAM);
                    showDiff();
                    vm.showJSON = true;
                    vm.showJsonLoader = false;
                });
            };

            function showDiff (){
                vm.showJsonLoader = false;
                // This is required only if you want to show a JSON formatted view of your object without using a filter
                vm.jsonDisplayDataView = ObjectDiff.objToJsonView(vm.jsonDisplayData);
            };

            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            vm.getJsonData(rowData.TRACKING_KEY);
        }

        return controllers;
    }
);