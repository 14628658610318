/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './CreditsPartIIIConsolCtrl',
    './CreditsPartIIIConsolService',

], function () {
    'use strict';

  
   return angular.module('app.CreditsPartIIIConsol', ['app.CreditsPartIIIConsolCtrl','app.CreditsPartIIIConsolService'])

    });