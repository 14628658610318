
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.BranchTaxService',[])

        .factory("BranchTaxFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','workspaceFactory','USER_SETTINGS','$rootScope'
        	, function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService,workspaceFactory, USER_SETTINGS,$rootScope) {
            
        	var branchTaxFactory = {};           
            branchTaxFactory.url ={};            
            branchTaxFactory.url.pivoted_save = GENERAL_CONFIG.base_url + "/saveJsonAsPivotObject?action_code=brk8nt";
            branchTaxFactory.url.comment_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9q2et1";
          
            //Save the Modal data            
            branchTaxFactory.savePivotedData = function(_data,Details){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params = _.extend({clob_data:jsonObj}, params);
                params = _.extend({clob_settings:jsonSettings}, params);
                
                console.log("Save params :",params);
                                
                var promise = $http({
                    method: "post",
                    url: branchTaxFactory.url.pivoted_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };
            
            // Save the comments
            branchTaxFactory.saveComments = function(_data,Details,otherInfo){

                var filterParams = workspaceFactory.activeScreen.filters.getFilterParams();
                var jsonObj = JSON.stringify(Details);
                var jsonSettings =  JSON.stringify(_data);
                //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                
                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params =  _.extend({
                						sso_id:otherInfo.sso_id,
                						tax_year:otherInfo.tax_year,
                						scenario:otherInfo.scenario,
                						jcd_key:otherInfo.jcd_key,
                						screen_key:otherInfo.screen_key,
                						tag_key:otherInfo.tag_key,
                                        comments:otherInfo.comments,
                                        issue_key:otherInfo.issue_key,
                						OPERATION_TYPE:otherInfo.OPERATION_TYPE
                				   }, params);
                
                console.log("Save params :",params);
                                
                var promise = $http({
                    method: "post",
                    url: branchTaxFactory.url.comment_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };
            
            return branchTaxFactory;
        }])


    return services;


});