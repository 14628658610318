/**
 * Created by 212544474 on 8/16/2016.
 */
define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.sltDataserviceDashboardCtrl',[])
        .controller('sltDataserviceDashboardCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', 'SERVER_CONFIG', sltDataserviceDashboardCtrl])

    //--- PARENT CONTROLLER USE  $scope.$parent.ctrl.[method / var]
    function sltDataserviceDashboardCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, SERVER_CONFIG) {
        var vm = this;
        // vm.user_id = $state.params.data.user_id;
        vm.title = "Loading Details ";
        vm.ssoErrorMessage = false;

        vm.is_priv_user = SERVER_CONFIG.configSettings.privileged_sso_list.includes(USER_SETTINGS.user.sso_id);
        vm.parentString = 'admin.users-list';
        if( $state.current.parent === 'admin.users-list-module.user'){
            vm.parentString = 'admin.users-list-module';
        }


        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
        vm.setTitle = function(title){
            vm.title = title;
        };

        vm.refresh = function(){
            $rootScope.$broadcast("gridRefresh", true);
        };

        vm.getClientDe = function (val) {
            // alert(val);
            var params = {
                "client_name": vm.client_name,
                "user_type": vm.user_type,
                "client_active": vm.client_active,
                "updated_by": vm.updated_by,
                "updated_on": vm.updated_on,
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.admin_api_url + '/fetchClients', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };
    // }


    // function sltServiceListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS) {
        // var vm = this;
        vm.name = "userListCtrl";
        vm.data = [];
        vm.api = '';//$state.current.api.get;
        vm.filter = USER_SETTINGS.user.client
        
        vm.clients = [];
        vm.clients = USER_SETTINGS.user.clients;
        vm.selectedClient = _.find(vm.clients, {client_key : USER_SETTINGS.user.client.client_key});
        console.log(vm.filter, "vm.filter");
        vm.clientModalObj = [];
        vm.clients.forEach(c => {
            vm.clientModalObj.push({
                id: c.client_key,
                name: c.client_name
            });
        });

        console.log(USER_SETTINGS, "USER_SETTINGS");

        vm.addEditDataService = function(){
            var modalObj = {};
            modalObj.template = "app/components/sltApiDashboard/sltAddDataService/slt-add-data-service.html";
            modalObj.controler = "sltAddDataserviceController as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.data.clients = vm.clientModalObj;
            $scope.openModal(modalObj, vm.data);
        };

        vm.editDataService = function(data){
            var modalObj = {};
            modalObj.template = "app/components/sltApiDashboard/sltAddDataService/slt-add-data-service.html";
            modalObj.controler = "sltAddDataserviceController as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            data.clients = vm.clientModalObj;
            $scope.openModal(modalObj, data);
        };

        vm.testSltAPI = function(data) {
            var modalObj = {};
            modalObj.template = "app/components/sltApiDashboard/sltDataService/slt-data-service.html";
            modalObj.controler = "sltDataserviceController as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            $scope.openModal(modalObj, data);
        };

        vm.refresh = function(){
            getData();
        };

         // get the clientList initail load of page
		vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        client_key: client.client_key,
                        name: client.client_name
                    });
                });
                // vm.filter.client_key = USER_SETTINGS.user.client.client_key;
            });
        };
    //    vm.getClientList();

        vm.onClientChange = function() {
            getData();
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            getUpDate(args);
        });

        $scope.$on('$destroy', function () {
            $log.log("Unregistering listener");
            gridUpdateListener();
        });

        var getUpDate = function(args) {
            $scope.$broadcast("showDataRefresh", true);
            var params = args;
            var _filterObj = {};
            GridFactory.getDataUpdate(vm.api,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?action_id=32772&grid_id=5953", {client_filter:vm.selectedClient.client_key}).then(function (data) {
            if (data.callSuccess === "1") {
                    vm.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        $timeout(function() {
            getData();
        }, 5);


        $scope.ctrlFn = function(_callName,_data,_type) {
            if(_type === 'form' && _callName === 'slt-api-test-modal') {
                _data = {service_key:_data.rowData.SERVICE_KEY,client_filter:_data.rowData.CLIENT_KEY, rowData:_data.rowData}
                vm.testSltAPI(_data);
            } else if(_type === 'drillDown'){
                vm._data = _data;
                vm.callDrillDown(_data.colData.drilldown);
            } else if(_callName === 'editServiceModal') {
                vm.editDataService(_data);
            }
        };

        vm.callDrillDown = function(drillDownObject) {
            var gridFilters = {
                gridFilters:[],
                drillDownRefresh:0,
                filterObj:{},
                screenFilter:[]
            };
            var dataObj = JSON.parse(JSON.stringify(eval("(" + (drillDownObject) + ")")));
            if (dataObj.filters && dataObj.filters.length) {
                _.forEach(dataObj.filters, function (value, key) {
                    var _value;
                    var _data = vm._data;
                    if(_data && _data.rowData && _data.rowData.FROMDOCUMENT == 'document'){
                        _value = Number(_data.rowData.HEADER_KEY);
                    }else{
                        _value = _data.rowData[value]
                    }

                    if (_value && (_value.length || _.isNumber(_value))) {
                        var obj = {};
                        obj.predicate = value;
                        obj.value = [];
                        obj.type = "array";
                        obj.reverse = false;
                        obj.operator = "[]";
                        obj.value.push(_value);
                        gridFilters.gridFilters.push(obj);
                    }
                });
                gridFilters.drillDownRefresh = _data.colData.drilldown_filter_refresh == null ? gridFilters.drillDownRefresh : _data.colData.drilldown_filter_refresh
            }
            var gridFilterObj = [];
            var screenFilters = function(screenFilters){
                var _data = vm._data;
                _.forEach(screenFilters, function (value, key) {
                    var _value= _data.rowData[value.row_data_key];
                    if (_value && (_value.length || _.isNumber(_value))) {
                        var obj = {};
                        obj.value = [];
                        obj.type = "array";
                        obj.reverse = false;
                        obj.operator = "[]";
                        obj.value.push(_value);
                        if(value.row_data_key=='TAX_YEAR'){
                            value.value = _value+'';
                        }else{
                            value.value = _value;
                        }
                        gridFilters.screenFilter.push(obj);
                    }
                });
                    gridFilters.screenFilter.forEach(item=>{
                    gridFilterObj.push({name:item.predicate,value:item.value.join('')});
                });
            }
            var action_template = dataObj.action_template_key !=null ? dataObj.action_template_key: null;
                /*********************************************************************************************************
                 *   dirlldown from the one activity screen to another activity screen without filter inherit 9/5/2019
                 ********************************************************************************************************/
            if(dataObj.activity_key && dataObj.screen_key && dataObj.screenFilter ||dataObj.filter_json){
                if (dataObj.screenFilter && dataObj.screenFilter.length) {
                    screenFilters(dataObj.screenFilter)
                }

                gridFilters.filterObj=(gridFilterObj);
                workspaceFactory.loadLink("drillDown",null,dataObj.activity_key,dataObj.screen_key,dataObj.screenFilter||dataObj.filter_json,gridFilters.gridFilters,null,action_template);
            //   _type,_comment,_activity_key,_screen_key,_filters,_table_state,_locationObj,_actionGrid,_noReload
                    /*********************************************************************************************************
                 *   dirlldown from the one screen to another screen without filter inherit 9/5/2019
                     ********************************************************************************************************/

                }else if(dataObj.activity_key && dataObj.screen_key){
                        workspaceFactory.loadLink("drillDown",null,dataObj.activity_key,dataObj.screen_key,{},{},null,action_template);
                }
                else if (dataObj.screen_key && dataObj.screenFilter){
                    screenFilters(dataObj.screenFilter);
                    gridFilters.filterObj=(gridFilterObj);
                    workspaceFactory.loadLink("drillDown","{}",workspaceFactory.activeScreen.activity_key,dataObj.screen_key,(dataObj.screenFilter||dataObj.filter_json),
                    gridFilters.gridFilters,null,action_template);
                }
                    /*********************************************************************************************************
                 *   dirlldown from the one screen to another screen with filters inherit
                     ********************************************************************************************************/
                else{
                    var index = _.findIndex(workspaceFactory.screens, {
                                    'screen_key': dataObj.screen_key
                                });
                if (index >= 0) {
                    workspaceFactory.loadDrillDown(workspaceFactory.screens[index], gridFilters);
                }
            }
        }

        $scope.openModal = function (modalObj,_data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                if(selectedItem){
                    getData();
                }
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
    }
    return controllers;
});
