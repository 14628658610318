/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'moment','angular'

		],
		function(moment) {
			'use strict';

			var controllers = angular
					.module('app.entitychartmapController', [])
					.controller('EntityChartMapController',[ '$rootScope', '$scope', '$http', 'GlobalService','$uibModalInstance', 'ModalFactory','AlertService', 'rowData', 'gridData','JsonObjectFactory', 'EntityChartFactory','$timeout', 'TbbsService','GENERAL_CONFIG', '$window', 'USER_SETTINGS', 'workspaceFactory', entitychartmapController ])

					.controller('EntityEditController', [ '$rootScope', '$scope', '$http', 'GlobalService','$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData', 'JsonObjectFactory', 'EntityChartFactory', '$timeout',  'GENERAL_CONFIG', entityEditController ])
					.controller('EntityUpdateController', [ '$rootScope', '$scope', '$http', 'GlobalService','$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData', 'JsonObjectFactory', 'EntityChartFactory', '$timeout', '$window',  'GENERAL_CONFIG','USER_SETTINGS','workspaceFactory', entityUpdateController ])




			function entityEditController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory, EntityChartFactory, $timeout, GENERAL_CONFIG) {

				var vm = this || thisObj
				let updateData = {REPORTING_PERIOD:'',
								LE_NAME:'',
								DATE_INCORPORATED:'',
								ACQUISITION_DATE:'',
								DATE_REINCORPORATED:'',
								DATE_DISSOLVED:'',
								DATE_LIQUIDATED:'',
								DATE_SPAWNED_CONSUMED:'',
								DATE_SOLD:'',
								CONSUMED_BY:'',
								MAINTENANCE_OWNER:'',
								COUNTRY:'',
								GEIS_CODE:'',
								FUNCTIONAL_CURRENCY:'',
								REFRESH_Y_N:'',
								REASON:'',
								TAX_TYPE:'',
								PREPARE_Y_N:'',
								ENTITY_CLASSIFICATION:'',
								FSE_Y_N:'',
								TAX_YEAR_BEGIN:'',
								TAX_YEAR_END:'',
								NOV_YEAR_END:''
							};
				let cloneUpdateData = Object.assign({},updateData);
				
				vm.dateOptions = {
					dateDisabled: false,
					formatYear: 'yyyy',
					startingDay: 1,
					showWeeks: false
				};

				if ($uibModalInstance) {
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
						: null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				if(!gridData.length) {
					vm.gridData=[{data : {}}];
					vm.gridData[0].data = _.cloneDeep(gridData.rowData);
				}else{
					vm.gridData = _.cloneDeep(gridData);
				}
				vm.gridData = vm.gridData.map(obj => {
					// if(obj.data['REFRESH_Y_N'] === 'N'){
						obj.data.DATE_INCORPORATED = obj.data.DATE_INCORPORATED && new Date(obj.data.DATE_INCORPORATED);
						obj.data.ACQUISITION_DATE = obj.data.ACQUISITION_DATE &&  new Date(obj.data.ACQUISITION_DATE);
						obj.data.DATE_REINCORPORATED = obj.data.DATE_REINCORPORATED &&  new Date(obj.data.DATE_REINCORPORATED);
						obj.data.DATE_DISSOLVED = obj.data.DATE_DISSOLVED &&  new Date(obj.data.DATE_DISSOLVED);
						obj.data.DATE_LIQUIDATED = obj.data.DATE_LIQUIDATED &&  new Date(obj.data.DATE_LIQUIDATED);
						obj.data.DATE_SPAWNED_CONSUMED = obj.data.DATE_SPAWNED_CONSUMED &&  new Date(obj.data.DATE_SPAWNED_CONSUMED);
						obj.data.DATE_SOLD = obj.data.DATE_SOLD &&  new Date(obj.data.DATE_SOLD);
						obj.data.TAX_YEAR_BEGIN = obj.data.TAX_YEAR_BEGIN &&  new Date(obj.data.TAX_YEAR_BEGIN);
						obj.data.TAX_YEAR_END = obj.data.TAX_YEAR_END &&  new Date(obj.data.TAX_YEAR_END);

						return obj;
					// }
				});
				// vm.gridData  = vm.gridData.filter(obj =>  obj.data['REFRESH_Y_N'] === 'N');

				console.log("vm.gridData " , vm.gridData);

				vm.applyValues = function (feild, value){
					console.log('this is update data', feild, value);
					vm.gridData = vm.gridData.map((obj) => {
						obj.data[feild] = value;
						return obj;
					})
				}

				vm.save = function (){
					let data = vm.gridData.map(obj => Object.assign({},cloneUpdateData,obj.data));
					console.log('thjis is the data for update api',data);
					data.forEach(element => {
						element.DATE_INCORPORATED  =  element.DATE_INCORPORATED  ? moment(element.DATE_INCORPORATED ).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.ACQUISITION_DATE  =  element.ACQUISITION_DATE  ? moment(element.ACQUISITION_DATE ).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.DATE_REINCORPORATED  =  element.DATE_REINCORPORATED  ? moment(element.DATE_REINCORPORATED ).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.DATE_DISSOLVED  =  element.DATE_DISSOLVED  ? moment(element.DATE_DISSOLVED ).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.DATE_LIQUIDATED =  element.DATE_LIQUIDATED ? moment(element.DATE_LIQUIDATED).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.DATE_SPAWNED_CONSUMED =  element.DATE_SPAWNED_CONSUMED ? moment(element.DATE_SPAWNED_CONSUMED).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.DATE_SOLD  =  element.DATE_SOLD  ? moment(element.DATE_SOLD ).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.TAX_YEAR_BEGIN  =  element.TAX_YEAR_BEGIN  ? moment(element.TAX_YEAR_BEGIN ).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
						element.TAX_YEAR_END  =  element.TAX_YEAR_END  ? moment(element.TAX_YEAR_END ).format('YYYY-MM-DDThh:mm:ss.sssZ') : "";
					});
					EntityChartFactory.updateEntityChartDetails(data).then(function (response){
						console.log('this is update entity respose0',response);
						
						if(response.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",4000);
							var args = {};
							$rootScope.$emit('gridUpdate', args);
                        }else{
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                        }
                        
						// AlertService.add("success", "Entity has been saved", 2000);
						// $rootScope.$emit("gridUpdate", GlobalService.globalParams.tax_year);
						// $uibModalInstance.close();
					});
				}


				// vm.systemType=[{label:"Domestic",value:"D"},{label:"Foreign",value:"F"}]

				// vm.removeEntity = function (_index) {
				// 	vm.gridData.splice(_index, 1);
				// };




			}// /////////////END CTRL


			//ME
			function entitychartmapController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					EntityChartFactory, $timeout, tbbsService, GENERAL_CONFIG, $window, USER_SETTINGS,workspaceFactory) {

				var vm = this || thisObj;
				vm.gridDataList = [];
				vm.gridDataList1 = [];
				$scope.rowData = rowData || $scope.$parent.rowData;			
				var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
				
				vm.globalParams = JSON.stringify(tempObj);
				

				vm.entityTypeChange = 'taxCode';
				 for ( var a in gridData) {

					 if(gridData[a].data.SOURCE_SYSTEM_FLAG == 'D'){
						gridData[a].data['CURR_EDIT'] = 'USD';
						gridData[a].data['IS_DISABLE'] = false;
					 }else{
						gridData[a].data['CURR_EDIT'] = ''
						gridData[a].data['IS_DISABLE'] = false;
					 }
					 gridData[a].data['LEDGER_EDIT'] = 'DEFAULT';
					 gridData[a].data['TaxCODE_EDIT'] = '';
					 gridData[a].data['ME_EDIT'] = '';
					 gridData[a].data['CHARTCODE_EDIT'] = '';
					 gridData[a].data['taxCode_Error'] = false;
					 gridData[a].data['ledger_Error'] = false;
					 gridData[a].data['me_Error'] = false;
					 gridData[a].data['curr_Error'] = false;
					 gridData[a].data['chart_Error'] = false;
					vm.gridDataList1.push(gridData[a].data);
				}

				//This will get only unique LE Key Data
				vm.gridDataList =_.uniqBy(vm.gridDataList1, function (e) {
					return e.LE_KEY;
				  });

			

				vm.reset = function(){
					for ( var a in vm.gridDataList) {
						if(vm.gridDataList[a].SOURCE_SYSTEM_FLAG == 'D'){
							vm.gridDataList[a].CURR_EDIT = 'USD'
						}else{
							vm.gridDataList[a].CURR_EDIT = ''
						}
						vm.gridDataList[a].LEDGER_EDIT = 'DEFAULT';
						vm.gridDataList[a].TaxCODE_EDIT = '';
						vm.gridDataList[a].ME_EDIT = '';
						vm.gridDataList[a].CHARTCODE_EDIT = '';
						vm.gridDataList[a].taxCode_Error = false;
						vm.gridDataList[a].ledger_Error = false;
						vm.gridDataList[a].me_Error = false;
						vm.gridDataList[a].curr_Error = false;
						vm.gridDataList[a].chart_Error = false;
					  // vm.gridDataList.push(gridData[a].data);
				   }
				}

				if ($uibModalInstance) {
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name: null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				// 31jsr3
				// rjubnm

				function getMecode() {
					vm.meLoading = true;
					var params = {
						"action_code" : 'pk25zj', //'z3qttl',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key
					//	"le_key" : rowData.LE_KEY


					}
					return JsonObjectFactory
						.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
						.then(
							function(data) {
								if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
									AlertService.add("error",data.errorMessage);
									vm.meLoading = false;
									return false;
								} else {
									vm.me_code_data = data.jsonObject;
									for ( var a in vm.me_code_data) {
										vm.medisplay.push(vm.me_code_data[a].ME_CODE);
									}
									vm.medisplay = _.uniq(vm.medisplay,JSON.stringify)
									vm.meLoading = false;
								}
							})
				}

				function getChartList() {
					vm.chartLoading = true;
					var params = {
						"action_code" : '096e9s', 
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key

					}
					return JsonObjectFactory
							.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
											AlertService.add("error",data.errorMessage);
											vm.chartLoading = false;
											return false;
										} else {
											vm.chart_data = data.jsonObject;
											//Filter DCS
											vm.dcsChart= _.filter(vm.chart_data, function(data){
												if(data.SOURCE_SYSTEM == 'DCS'){
													return data;
												}				  
											});
											//Filter FIR
											vm.firChart= _.filter(vm.chart_data, function(data){
												if(data.SOURCE_SYSTEM == 'FIR'){
													return data;
												}				  
											});
											for ( var a in vm.chart_data) {
												vm.chartdisplay.push(vm.chart_data[a]);
											}
											vm.chartdisplay = _.uniq(vm.chartdisplay,JSON.stringify)
											vm.chartLoading = false;
										}
									})
				}

				function getCurrency() {
					vm.currLoading = true;
					var params = {
						"action_code" : 'gug95n',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					}
					return JsonObjectFactory
							.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
											AlertService.add("error",data.errorMessage);
											vm.currLoading = false;
											return false;
										} else {
											vm.curr_data = data.jsonObject;
											for ( var a in vm.curr_data) {
												vm.currdisplay.push(vm.curr_data[a].FROM_CURRENCY);
											}
											vm.currdisplay = _.uniq(vm.currdisplay,JSON.stringify)
											vm.currLoading = false;
										}
									})
				}

			/*	function getChart() {
					vm.chartLoading = true;
					var params = {
						"action_code" : 'j6ctuc',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

					}
					return JsonObjectFactory
							.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
											AlertService.add("error", data.errorMessage);
											vm.chartLoading = false;
											return false;
										} else {
											vm.chart_data = data.jsonObject;
											for ( var a in vm.chart_data) {
												vm.chartdisplay.push(vm.chart_data[a].FROM_CHART_CODE);
											}
											vm.chartdisplay = _.uniq(vm.chartdisplay,JSON.stringify)
											vm.chartLoading = false;
										}
									})
				}
*/
				function init() {
					vm.ME_EDIT = '';
					vm.LEDGER_EDIT = '';
					vm.CURR_EDIT = '';
					vm.CHARTCODE_EDIT = '';
					$scope.crudLoading = false;
					// vm.entity = rowData.LEID + '-' + rowData.CDR_NO + '-'
					// 		+ rowData.REPORTING_PERIOD;

					vm.existing_chart_data = [];
					vm.chart_data = [];
					vm.type = 'I';
					vm.chartdisplay = [];
					vm.medisplay = [];
					vm.currdisplay = [];
					vm.entityLookUpinit = false;
					getMecode();
					getCurrency();
					getChartList();
					getexistingEntityData();
					//vm.chartUpdate();
				}

				init();

				// $scope.reset = function() {
				// 	init();
				// };

				vm.entitytoggleLookup = function() {
					vm.entitylookUpMapping = !vm.entitylookUpMapping;
				}

				// wvhg22
				//Will Trigger On change
				vm.validateChangedFields = function(index, type){
					for(var j=0; j < vm.gridDataList.length; j++){
						if(j == index){
							if(type == 'LEDGER'){
								if (vm.gridDataList[j].LEDGER_EDIT === "" || vm.gridDataList[j].LEDGER_EDIT === undefined) {
									vm.gridDataList[j].ledger_Error = true;
									//returnValue = 1; 
								} else {
									vm.gridDataList[j].ledger_Error = false;
								}
							}else if(type == 'ME_CODE'){
								if (vm.gridDataList[j].ME_EDIT === "" || vm.gridDataList[j].ME_EDIT === undefined) {
									vm.gridDataList[j].me_Error = true;
									//returnValue = 1; 
								} else {
									vm.gridDataList[j].me_Error = false;
								}
							}else if(type == 'CURR'){
								if (vm.gridDataList[j].CURR_EDIT === "" || vm.gridDataList[j].CURR_EDIT === undefined) {
									vm.gridDataList[j].curr_Error = true;
									//returnValue = 1; 
								} else {
									vm.gridDataList[j].curr_Error = false;
								}
							}else if(type == 'CHART'){
								if (vm.gridDataList[j].CHARTCODE_EDIT === "" || vm.gridDataList[j].CHARTCODE_EDIT === undefined) {
									vm.gridDataList[j].chart_Error = true;
									//returnValue = 1; 
								} else {
									vm.gridDataList[j].chart_Error = false;
								}
							}else if(type == 'TAX_CODE'){
								if (vm.gridDataList[j].TaxCODE_EDIT === "" || vm.gridDataList[j].TaxCODE_EDIT === undefined) {
									vm.gridDataList[j].taxCode_Error = true;
									//returnValue = 1; 
								} else {
									vm.gridDataList[j].taxCode_Error = false;
								}
							}
						 break;
							
						}
					}
				}

				//Will trigger on Save
				vm.validateRequiredFields = function(type){
					var returnValue = 0;
				 for(var j=0; j < vm.gridDataList.length; j++){
				//	if(j == index){
						//if(type == 'LEDGER'){
							if (vm.gridDataList[j].LEDGER_EDIT === "" || vm.gridDataList[j].LEDGER_EDIT === undefined) {
								vm.gridDataList[j].ledger_Error = true;
								returnValue = 1; 
							} else {
								vm.gridDataList[j].ledger_Error = false;
							}
					//	}else if(type == 'ME_CODE'){
							if (vm.gridDataList[j].ME_EDIT === "" || vm.gridDataList[j].ME_EDIT === undefined) {
								vm.gridDataList[j].me_Error = true;
								returnValue = 1; 
							} else {
								vm.gridDataList[j].me_Error = false;
							}
					//	}else if(type == 'CURR'){
							if (vm.gridDataList[j].CURR_EDIT === "" || vm.gridDataList[j].CURR_EDIT === undefined) {
								vm.gridDataList[j].curr_Error = true;
								returnValue = 1; 
							} else {
								vm.gridDataList[j].curr_Error = false;
							}
					//	}else if(type == 'CHART'){
							if (vm.gridDataList[j].CHARTCODE_EDIT === "" || vm.gridDataList[j].CHARTCODE_EDIT === undefined) {
								vm.gridDataList[j].chart_Error = true;
								returnValue = 1; 
							} else {
								vm.gridDataList[j].chart_Error = false;
							}
					//	}else if(type == 'TAX_CODE'){
						if(type == 'taxCode'){
							if (vm.gridDataList[j].TaxCODE_EDIT === "" || vm.gridDataList[j].TaxCODE_EDIT === undefined) {
								vm.gridDataList[j].taxCode_Error = true;
								returnValue = 1; 
							} else {
								vm.gridDataList[j].taxCode_Error = false;
							}
						}
							
					//	}
					//  break;
					}	
					return returnValue;

				}

			/*	vm.ledger_change = function(index) {
					if (vm.LEDGER_EDIT === "" || vm.LEDGER_EDIT === undefined) {
						vm.ledger_Error = true;
					} else {
						vm.ledger_Error = false;
					}
				}

				vm.me_change = function(index) {
					if (vm.ME_EDIT === "" || vm.ME_EDIT === undefined) {
						vm.me_Error = true;
					} else {
						vm.me_Error = false;
					}
				}
				vm.curr_change = function(index) {
					if (vm.CURR_EDIT === "" || vm.CURR_EDIT === undefined) {
						vm.curr_Error = true;
					} else {
						vm.curr_Error = false;
					}
				}
				vm.chart_change = function(index) {
					if (vm.CHARTCODE_EDIT === ""
							|| vm.CHARTCODE_EDIT === undefined) {
						vm.chart_Error = true;
					} else {
						vm.chart_Error = false;
					}
				} */

				//LAST TABLE
				function getexistingEntityData() {
					vm.existingchartLoading = true;	
					var le_Key_data = Array.prototype.map.call(vm.gridDataList, function(item) { return item.LE_KEY; }).join(",");
					var params = {
						"action_code" : 'wvhg22',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"le_key" : le_Key_data

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
											AlertService.add("error",data.errorMessage);
											vm.existingchartLoading = false;
											return false;
										} else {
											vm.existing_chart_data = data.jsonObject;

											if (vm.existing_chart_data.length == 0) {
												vm.noRecords = true;
											}else{
												vm.entityLookUpinit = true;
											}
											vm.existingchartLoading = false;
										}
									})
				}

				vm.loadAdjustment = function(index) {
					vm.type = 'E';
					var data_edit = vm.existing_chart_data[index];
					vm.oldme = vm.existing_chart_data[index].ME_KEY;
					vm.oldcurr = vm.existing_chart_data[index].CURR;
				//	vm.oldchart = vm.existing_chart_data[index].CHART_KEY;
				//	vm.oldledger = vm.existing_chart_data[index].LEDGER;
					vm.combi_key = vm.existing_chart_data[index].COMBINATION_KEY;
					vm.oldlekey = vm.existing_chart_data[index].LE_KEY;
					vm.entity = vm.existing_chart_data[index].ENTITY;
					vm.ME_EDIT = vm.existing_chart_data[index].ME_CODE;
					vm.CURR_EDIT = vm.existing_chart_data[index].CURR;
				//	vm.CHARTCODE_EDIT = vm.existing_chart_data[index].CHART_CODE;
				//	vm.LEDGER_EDIT = vm.existing_chart_data[index].LEDGER;
				//	vm.ledger_change();

				}

				vm.save = function() {
				 
					if ($scope.crudLoading) {
						AlertService.add("info", "Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoading = true;
					//To fill the required fields
					var returnValue = vm.validateRequiredFields(vm.entityTypeChange);
					if(returnValue == 1){
						$scope.crudLoading = false;
						AlertService.add("danger","Please correct the errors below", 4000);
						return false;
					}

					//vm.chart_change(); vm.chart_Error || 
					// if (vm.ledger_Error || vm.curr_Error || vm.me_Error){
					// 	AlertService.add("danger","Please correct the errors below", 4000);
					// 	$scope.crudLoading = false;
					// 	throw new ("This is not an error. This is just to abort javascript!");
					// }

					var returnClobSettingsObj = {};
					var sendobj = [];
					for(var i=0;i < vm.gridDataList.length;i++){
					var dummyJson = {};

					dummyJson.TYPE = vm.type;
					dummyJson.TAX_YEAR = GlobalService.globalParams.tax_year;
					dummyJson.SCENARIO = GlobalService.globalParams.scenario;
					dummyJson.JCD_KEY = GlobalService.globalParams.jcd_key;
					dummyJson.LE_KEY = vm.gridDataList[i].LE_KEY;
					dummyJson.COMBINATION_KEY = vm.gridDataList[i].COMBINATION_KEY;
					dummyJson.LEID = vm.gridDataList[i].LEID;
					dummyJson.REPORTING_PERIOD = vm.gridDataList[i].REPORTING_PERIOD;
					dummyJson.TAXCODE_NAME = 'Tax Code Name';

					for ( var b in vm.me_code_data) {
						if (vm.me_code_data[b].ME_CODE == vm.gridDataList[i].ME_EDIT) {
							dummyJson.ME_KEY = vm.me_code_data[b].ME_KEY;
							dummyJson.ME_CODE = vm.me_code_data[b].ME;
						}
					}

					for ( var a in vm.chart_data) {
						if (vm.chart_data[a].CHART_CODE == vm.gridDataList[i].CHARTCODE_EDIT)
							dummyJson.CHART_KEY = vm.chart_data[a].CHART_KEY;
					}
					if(vm.entityTypeChange == 'taxCode'){
						dummyJson.TAX_CODE = vm.gridDataList[i].TaxCODE_EDIT;
					}else{
						dummyJson.TAX_CODE = vm.gridDataList[i].CDR_NO;
					}
					
					dummyJson.CURR = vm.gridDataList[i].CURR_EDIT;
					dummyJson.LEDGER = vm.gridDataList[i].LEDGER_EDIT;

					sendobj.push(dummyJson);
					}	

					
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['save_type'] = vm.entityTypeChange;

					var message = "Entity Chart Map has been successfully save";

					sendDetails(returnClobSettingsObj,sendobj, message);

				};
				function sendDetails(returnObj, loaclacctdes, message) {
					EntityChartFactory.saveentitychartmap(returnObj,loaclacctdes).then(function(result) {

								if (result.errorMessage && result.errorMessage !== 'null') {
									$scope.crudLoading = false;
									vm.errorKey = [];
									vm.errorList = [];
									 var s = result.errorMessage.substring(result.errorMessage.lastIndexOf("\n") + 1);
									 vm.errorKey = s.split(", ") ;
									 //$scope.crudLoading = false;

									 for(var d=0; d<vm.errorKey.length; d++){
										vm.errorList = _.filter(vm.gridDataList, function(o) { 
											if(o.LE_KEY == vm.errorKey[d]){
												o.taxCode_Error = true;
												o.me_Error = true;
												return o; 
											}
										})
									}
									AlertService.add("error",'Please correct the highlighted Company Codes,they alreday exist', 4000);
									

								} else {
									AlertService.add("success", message, 4000);
									$scope.crudLoading = false;
									$uibModalInstance.dismiss('cancel');

									var args = {
										le_key : rowData.LE_KEY,

										gridFilter : {
											le_key : rowData.LE_KEY,
										}
									};
									$rootScope.$emit('gridUpdate', args);

									// $uibModalInstance.dismiss('cancel');
									/*
									 * var args = { local_acct_key:
									 * returnObj.local_acct_key, sys_acct_key :
									 * rowData.SYS_ACCT_KEY, chart_filter :
									 * rowData.LOCAL_CHART_KEY, gridFilter: {
									 * local_acct_key: returnObj.local_acct_key,
									 * sys_acct_key : rowData.SYS_ACCT_KEY,
									 * chart_filter : rowData.LOCAL_CHART_KEY } };
									 * $rootScope.$emit('gridUpdate', args);
									 */

								}
							});

				}

				//UPDATE CHART
				vm.chartUpdate = ()=>{

					const currentUser = USER_SETTINGS.user;
					vm.checkLockedbj =[];
					vm.customBaseURL = GENERAL_CONFIG.custom_api_url;
					vm.gtwBaseURL = GENERAL_CONFIG.base_url;
					// vm.clientKey = currentUser.client_key;
					vm.taxYear = GlobalService.globalParams.tax_year;
					vm.scenario = GlobalService.globalParams.scenario;
					vm.jcdKey = GlobalService.globalParams.jcd_key;
					vm.sso_id = vm.logged_in_user;
					vm.scenarioCode = GlobalService.globalParams.scenarioCode;
					vm.is_issue_key_enabled = GlobalService.inputs.tax_year.selected.is_issue_key_enabled;
					vm.issue_id = GlobalService.globalParams.issue_id;
					vm.ccList = gridData.map((row) => {
						vm.checkLockedbj.push({
							"leid": row.data.LEID || row.data.SOURCE_LEID,//"IF1139",
							"me_code": row.data.ME_CODE || row.data.ME || row.data.SOURCE_ME_CODE,
							"cdr_number":row.data.CDR_NO || gridData.TAX_CODE || row.data.SOURCE_CDR_NO,
							"consol_group_key": row.data.CONSOL_GROUP_KEY || row.data.FILING_GROUP,//1,
							"reporting_period": row.data.REPORTING_PERIOD || row.data.SOURCE_REPORTING_PERIOD,
							"combination_key":row.data.COMBINATION_KEY || row.data.COMPL_COMBINATION_KEY || row.data.HO_COMBINATION_KEY, //1,
						
						})
						return row.data.COMBINATION_KEY}).join(',');	
					vm.filingGroup = workspaceFactory.activeScreen.filters.filterParams.filing_key;
					vm.processName = "UPDATE_ENTITY_CHART_PROCESS";
					
					if ($uibModalInstance) { $timeout(() => {
							document.querySelector('div[uib-modal-backdrop="modal-backdrop"]').setAttribute("style", "z-index: 800;");
							document.querySelector('.modal-window-lock-class').setAttribute("style", "z-index: 810; display: block;");
							$window.document.querySelector('gtw-entity-chart-update-ce')
								.addEventListener('cancel-chart-update', (event)=>{
									console.log('event',event);
									if(event?.detail == 'refresh-chart-update'){
										// $rootScope.$emit('gridUpdate', {});
										$rootScope.$broadcast('dataFilters:refreshGrid', {
											"refresh": true
										});
									}
									$uibModalInstance.close();
								});
						},500);					
						$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;
						$scope.cancel = function() {
							$uibModalInstance.close();
							$scope.modal_name = null;
						};
					} else {
						$scope.modal_name = null;
					}
				}
				vm.chartUpdate();

			}// /////////////END ME CTRL

			//CHART
			function entityUpdateController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory, EntityChartFactory, $timeout, $window, GENERAL_CONFIG, USER_SETTINGS,workspaceFactory) {
				console.log({gridData});

				const vm = this || thisObj;

				const currentUser = USER_SETTINGS.user;
				vm.checkLockedbj =[];
        		vm.customBaseURL = GENERAL_CONFIG.custom_api_url;
				vm.gtwBaseURL = GENERAL_CONFIG.base_url;
        		// vm.clientKey = currentUser.client_key;
				vm.taxYear = GlobalService.globalParams.tax_year;
				vm.scenario = GlobalService.globalParams.scenario;
				vm.jcdKey = GlobalService.globalParams.jcd_key;
				vm.ccList = gridData.map((row) => {
					vm.checkLockedbj.push({
						"leid": row.data.LEID || row.data.SOURCE_LEID,//"IF1139",
						"me_code": row.data.ME_CODE || row.data.ME || row.data.SOURCE_ME_CODE,
						"cdr_number":row.data.CDR_NO || gridData.TAX_CODE || row.data.SOURCE_CDR_NO,
						"consol_group_key": row.data.CONSOL_GROUP_KEY || row.data.FILING_GROUP,//1,
						"reporting_period": row.data.REPORTING_PERIOD || row.data.SOURCE_REPORTING_PERIOD,
						"combination_key":row.data.COMBINATION_KEY || row.data.COMPL_COMBINATION_KEY || row.data.HO_COMBINATION_KEY, //1,
					   
					})
					return row.data.COMBINATION_KEY}).join(',');	
				vm.filingGroup = workspaceFactory.activeScreen.filters.filterParams.filing_key;
				vm.processName = "UPDATE_ENTITY_CHART_PROCESS";
				
				if ($uibModalInstance) {
					$timeout(() => {
						document.querySelector('div[uib-modal-backdrop="modal-backdrop"]').setAttribute("style", "z-index: 800;");
						document.querySelector('.modal-window-lock-class').setAttribute("style", "z-index: 810; display: block;");
						$window.document.querySelector('gtw-entity-chart-update-ce')
							.addEventListener('cancel-chart-update', (event)=>{
								console.log('event',event);
								if(event?.detail == 'refresh-chart-update'){
									// $rootScope.$emit('gridUpdate', {});
									$rootScope.$broadcast('dataFilters:refreshGrid', {
										"refresh": true
									});
								}
								$uibModalInstance.close();
							});
					},500);					
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				
			}
			return controllers;

		});