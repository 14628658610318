define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.FormScheduleLCtrl', [])
        .controller('F1120LSCHDLController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS',F1120LSCHDLController]);
    function F1120LSCHDLController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG,USER_SETTINGS) {
        var vm = this;
        vm.modalTitle = "Schedule L Total Assets";
        vm.showFormLoader = true;
        vm.showDiagLoader = true;
        var tempDataObj = {};
        var saveParamDtls = [];
        vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
        $scope.entityList = {
                "Tax Year": rowData.TAX_YEAR,
                "LEID": rowData.LEID,
                "LE Name": rowData.LE_NAME,
                "LE TYPE": rowData.LE_TYPE,
                "ME": rowData.ME
            };

        vm.scheduleLFormView = {rows:[]};
        vm.form_counter=0;
        vm.tb_counter=0;
		vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';

        function fetchSchLrecords() {
            var params = { "action_id": 30177, "tax_year": rowData.TAX_YEAR, "form_no": rowData.FORM_NO,"schd_name": rowData.SCHD_NAME, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY,"combination_key": rowData.COMBINATION_KEY};
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadPivotFormJson', params).then(function (data) {
               for (var i = 0; i < data.irsForm.formItemList.length; i++) {
                    saveParamDtls = [];
                    tempDataObj = {};
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    tempDataObj.saveParams = saveParamDtls;

                    for (var j = 0; j < data.irsForm.formItemList[i].dataRowList[0].dataCellList.length; j++) {
                        if (j == 0) {
                            tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 1) {
                            tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 2) {
                            tempDataObj.lineAttrKeyA = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
							}
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                tempDataObj.value1 = 0;
                            } else {
                                tempDataObj.value1 = parseInt(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value);
                            }
                        } else if (j == 3) {
                            tempDataObj.lineAttrKeyB = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
							}
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                tempDataObj.value2 = 0;
                            } else {
                                tempDataObj.value2 = parseInt(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value);
                            }
                        }
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    vm.scheduleLFormView.rows.push(tempDataObj);
                }
                vm.formListOriginal = angular.copy($scope.scheduleLFormView);
                initFormView(vm.scheduleLFormView);
                vm.showFormLoader = false;
            });
        }

        function fetchSchFimports() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "status": 'Y'
            };

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=wwledr', params).then(function (data) {
                vm.BatchList = data.jsonObject;
                angular.forEach(vm.BatchList, function (value, key) {
                    vm.BatchList[key].TOGGLE = false;
                });
                vm.showRequestLoader = false;
            });
        }

        fetchSchLrecords();
        fetchSchFimports();

        function loadTransactions(index, trans_key){
            vm.batchLoader = true;
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "transaction_key": trans_key
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=l6gzle', params).then(function (data) {
                var tempData = data.jsonObject;
                var currLineNo;
                var tempBatchList = {};
                var tempBatchData = [];
                angular.forEach(tempData, function (value, key) {
                    if(key == 0){
                        currLineNo = tempData[key].LINE_NO;
                        tempBatchList = tempData[key];

                        if ('BEGIN_A' === tempData[key].ATTRIBUTE_NAME) {
                        	tempBatchList.ATTRIBUTE_VALUE_A = tempData[key].ATTRIBUTE_VALUE;
                    	} else if ('END_B' === tempData[key].ATTRIBUTE_NAME) {
                    		tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                    	}
                    }else{
                        if(tempData[key].LINE_NO == currLineNo){
                        	if ('BEGIN_A' === tempData[key].ATTRIBUTE_NAME) {
                            	tempBatchList.ATTRIBUTE_VALUE_A = tempData[key].ATTRIBUTE_VALUE;
                        	} else if ('END_B' === tempData[key].ATTRIBUTE_NAME) {
                        		tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                        	}
                            tempBatchData.push(tempBatchList);
                        }else{
                            currLineNo = tempData[key].LINE_NO;
                            tempBatchList = {};
                            tempBatchList = tempData[key];
                            if ('BEGIN_A' === tempData[key].ATTRIBUTE_NAME) {
                            	tempBatchList.ATTRIBUTE_VALUE_A = tempData[key].ATTRIBUTE_VALUE;
                        	} else if ('END_B' === tempData[key].ATTRIBUTE_NAME) {
                        		tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                        	}
                        }
                    }
                });
                vm.batchLoader = false;
                vm.showBatchData = true;
                vm.BatchListData = tempBatchData;
            });
        };

        var prev_index = null;
        vm.showUploads = function(index, trans_key){
            if(prev_index !== null){
                if(prev_index !== index){
                    vm.BatchList[prev_index].TOGGLE = false;
                    prev_index = index;
                    vm.showBatchData = false;
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    loadTransactions(index, trans_key);
                }else{
                    vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                    vm.showBatchData = true;
                }
            }else{
                prev_index = index;
                vm.BatchList[index].TOGGLE = !vm.BatchList[index].TOGGLE;
                loadTransactions(index, trans_key);
            }
        };

		   vm.setDefault = function(){
			 vm.scheduleLFormView.rows[8].value1 = vm.scheduleLFormView.rows[5].value1;
	         vm.scheduleLFormView.rows[8].value2 = vm.scheduleLFormView.rows[5].value2;
        }


		   vm.computeValues2018 = function(row) {
	            // for NaN issue
	            for(let i=0; i<3; i++){
	                $scope.scheduleLFormView.rows[i].value1 = $scope.scheduleLFormView.rows[i].value1 || 0;
	                $scope.scheduleLFormView.rows[i].value2 = $scope.scheduleLFormView.rows[i].value2 || 0;
	            }
	        }

	        vm.computeValues2017 = function(row) {
	        	 // for NaN issue
	        	 for(let i=0; i<3; i++){
		                $scope.scheduleLFormView.rows[i].value1 = $scope.scheduleLFormView.rows[i].value1 || 0;
		                $scope.scheduleLFormView.rows[i].value2 = $scope.scheduleLFormView.rows[i].value2 || 0;
		            }
	        }

		   vm.saveSchddForm = function (url, data){
	            var jsonSettings = { "tax_year": rowData.TAX_YEAR,
		            "scenario": rowData.SCENARIO,
		            "jcd_key": rowData.JCD_KEY,
		            "combination_key": rowData.COMBINATION_KEY,
		            "trans_type_key": data[0].editrows[0][0].trans_type_key
		        };

	            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	            var jsonObj = JSON.stringify(data);
	            var jsonSettings  = JSON.stringify(jsonSettings);

	            var params = filterParams;
	            params = _.merge({},  GlobalService.globalParams , params);
	            params =  _.extend({jsonObj:jsonObj}, params);
	            params =  _.extend({jsonSettings:jsonSettings}, params);
	            params.process_name =  workspaceFactory.activeScreen.label;
	            params.combination_key = rowData.COMBINATION_KEY;
	            params.lineAttrKey = data[0].editrows[0][0].LINE_ATTRIB_KEY;

	            var promise = $http({
	                method: "post",
	                url: url,
	                data: params
	            }).then(function (response) {
	                var status = response.status;
	                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
	                console.log("saveSchdFForm() response.data.jsonObj ::",response.data.jsonObject);
	                if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
	                	    vm.scheduleLFormView.rows[9].value1 = vm.scheduleLFormView.rows[8].value1;
			        	    vm.scheduleLFormView.rows[9].value2 = vm.scheduleLFormView.rows[8].value2;
						$uibModalInstance.dismiss('cancel');
						var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
						$rootScope.$emit('gridUpdate', args);
						AlertService.add("success", "Transactions saved successfully", 4000);

					}else{
						if(response.data && response.data.errorMessage){
                            AlertService.add("error", response.data.errorMessage,4000);
                        }else{
                            AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                        }
					}
	                return response.data;
	            });
	            console.log("------------At the end-----------", promise);
	            return promise;
			}

		   vm.save = function(){
			    var formRowDataA = {};
	            var formRowDataB = {};
				var tempArray = [];
				var count = vm.scheduleLFormView.rows.length ;

				for(var i=0; i<count ;i++){

					if (i == 5 || i == 8 || i==9 ) {

					formRowDataA = {
							   tax_year: rowData.TAX_YEAR,
			            	   form_no: rowData.FORM_NO,
			            	    schd_name: rowData.SCHD_NAME,
			            	    line_order: vm.scheduleLFormView.rows[i].line_order,
			                    attrib_order: vm.scheduleLFormView.rows[i].attrib_order_a,
			                    line_description: vm.scheduleLFormView.rows[i].description,
			            		scenario: rowData.SCENARIO,
			            		jcd_key: rowData.JCD_KEY,
			            		combination_key: rowData.COMBINATION_KEY ,
								trans_type_key: rowData.TRANS_TYPE_KEY,
								group_obj_key: rowData.GROUP_OBJ_KEY,
								form_key: rowData.FORM_KEY,
								line_no: vm.scheduleLFormView.rows[i].num,
								occurence: vm.scheduleLFormView.rows[i].occurrence,
								line_attrib_key: vm.scheduleLFormView.rows[i].lineAttrKeyA,
								Attribute_Name: 'BEGIN_A',
								Attribute_Value:(i ==9 ? vm.scheduleLFormView.rows[i-1].value1 : vm.scheduleLFormView.rows[i].value1),
								trans_dtls_key: vm.scheduleLFormView.rows[i].transkey,
								is_dirty: ''
							};


						formRowDataB = {
								tax_year: rowData.TAX_YEAR,
				            	   form_no: rowData.FORM_NO,
				            	    schd_name: rowData.SCHD_NAME,
				            	    line_order: vm.scheduleLFormView.rows[i].line_order,
				                    attrib_order: vm.scheduleLFormView.rows[i].attrib_order_b,
				                    line_description: vm.scheduleLFormView.rows[i].description,
				            		scenario: rowData.SCENARIO,
				            		jcd_key: rowData.JCD_KEY,
				            		combination_key: rowData.COMBINATION_KEY ,
									trans_type_key: rowData.TRANS_TYPE_KEY,
									group_obj_key: rowData.GROUP_OBJ_KEY,
									form_key: rowData.FORM_KEY,
									line_no: vm.scheduleLFormView.rows[i].num,
									occurence: vm.scheduleLFormView.rows[i].occurrence,
									line_attrib_key: vm.scheduleLFormView.rows[i].lineAttrKeyB,
									Attribute_Name: 'END_B',
									Attribute_Value:(i ==9 ? vm.scheduleLFormView.rows[i-1].value2 : vm.scheduleLFormView.rows[i].value2),
									trans_dtls_key: vm.scheduleLFormView.rows[i].transkey,
		                            is_dirty: ''
			                };

		                tempArray.push(formRowDataA);
		                tempArray.push(formRowDataB);
					}

				}
				vm.formData.editrows.push(tempArray);
				vm.mainData = [];
				vm.mainData.push(vm.formData);
				var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nkepph";
				vm.saveSchddForm(url,vm.mainData);

	            $rootScope.$broadcast("gridRefresh", true);

	            $timeout(function() {
	                $scope.$broadcast("showLoader", true);
	            },100);
	        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.computeValues = function (row) {
            switch (rowData.TAX_YEAR) {
                case 2017:
                    vm.computeValues2017(row);
                    break ;
                case 2018:
                    vm.computeValues2018(row);
                    break;
                default:
                    vm.computeValues2017(row);
            }

        }

        vm.reset = function () {
            $scope.scheduleLFormView = angular.copy(vm.formListOriginal);
			vm.computeValues();
        }

        function initFormView(filteredFormData) {
            $scope.scheduleLFormView = filteredFormData;
            vm.computeValues();
        }
    }
});