define([
    'angular',
    './trueUpTaxesController',
    './trueUpTaxesService'

], function () {
    'use strict';
    return angular.module('app.FIR_TrueUp_Taxes', ['app.trueUpTaxesController','app.trueUpTaxesService'] );
});



