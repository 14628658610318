define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.workspaceController',[])
    .controller('WorkspaceController', ['$rootScope','$scope','$state','$log','workspaceFactory','ModalFactory','DataFiltersFactory','$uibModal','GlobalService', '$timeout','USER_MENU', workspaceController])
    .controller('WorkspaceMemoryController', ['$rootScope','$scope','$state','$log','workspaceFactory',workspaceMemory])


    function workspaceController($rootScope,$scope,$state,$log,workspaceFactory,ModalFactory,DataFiltersFactory,$uibModal, GlobalService, $timeout,USER_MENU) {
       // $templateCache.removeAll();
        var vm = this;

        vm.name = "Main Controller";
        vm.showFilters = false;
        vm.isEnable = false;
        vm.backMessage = "Back To Previous "




        // Enables the back button/msg label..
        if(workspaceFactory.goback.workspace && workspaceFactory.workflowObj == false) {
            vm.backMessage = workspaceFactory.goback.backMessage;
            vm.isEnable = true;
        } else if(workspaceFactory.routes.prevoiusDashboard && workspaceFactory.workflowObj == true) {
            vm.backMessage = "Back to workflow";
            vm.isEnable = true;
        }

        var gridUpdateListener = $rootScope.$on('backButtonEnabled', function (event, args) {
            console.log("wehn was this heard")
            vm.backMessage = workspaceFactory.goback.backMessage;
            vm.isEnable = true;
        });

        vm.backToPreviousScr = function () {
            if(workspaceFactory.goback.workspace && workspaceFactory.workflowObj == false){
                    workspaceFactory.loadLink("link", "{}", workspaceFactory.goback.workspace.activity_key, workspaceFactory.goback.workspace.screen_key,
                    {},{}, {}, {}, null, true);
            } else if(workspaceFactory.routes.prevoiusDashboard && workspaceFactory.workflowObj == true) { // prevoiusDashboard has "workflow entity details" state..
                $state.go(workspaceFactory.routes.prevoiusDashboard);
                workspaceFactory.setRoutes("");
                vm.isEnable = false;
            }
        }


        vm.toggleFilters = function () {
            angular.element('body').trigger('resizeGrid', {});
            workspaceFactory.activeScreen.filters.toggleFilters();
        }

        vm.doneRendering = function(){

        }



        function onWorkspaceLoad(){
            workspaceFactory.onLoad();
        }

        onWorkspaceLoad();

        $scope.openModal = function (_modalName,_data) {
            var modalObj = ModalFactory.getModal(_modalName);
            $scope.rowData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: 'lg',
                backdrop: modalObj.backdrop,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
            });

        };



        $scope.$watch(function(){ return workspaceFactory.screens}, function(val){
            vm.workspaces = workspaceFactory.getScreen();
            $timeout(function() {
                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
                    vm.scrlTabsApi.init();
                }
            });
        });



        vm.clearScreenCache = function($event,workspace){
            $event.stopPropagation();
            workspaceFactory.clearCache(workspace);
        }


        vm.toggleNavPanel = function(){
            if( $rootScope.editedSettings.navPanel ===  'closed'){
                $rootScope.editedSettings.navPanel = 'open';
            }else{
                $rootScope.editedSettings.navPanel = 'closed';
            }
        }

        //CHANGE THE TABS
        vm.setTab = function(_workspace,onBackbutton){
           // $templateCache.removeAll();
            workspaceFactory.loadTab(_workspace,{},onBackbutton);

            let breadcrumbArray = []; 
            function buildBreadCrumbData(val) {
                breadcrumbArray.push(val.label);
                if (val.parent) {
                    buildBreadCrumbData(val.parent)
                } else {
                    breadcrumbArray.reverse();
                }
            } 
            buildBreadCrumbData(workspaceFactory.activeScreen);
            console.log('breadcrumbArray',breadcrumbArray);
            $('gtw-header-ce').attr('bread-crumb', JSON.stringify(breadcrumbArray));

            if(workspaceFactory.goback.workspace){
                console.log("ENABLE BUTTON");
                vm.isEnable = true;
            }


        }

        var removeStateListener = $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            //Open the modal
            var toWorkspace = _.find(vm.workspaces,['link',toState.name]);
            if(toWorkspace && (toWorkspace.link === fromState.name)){
                return;
            }

           if(toWorkspace){
               vm.setTab(toWorkspace,true);
           }
        });

        $scope.$on('$destroy', function() {
            removeStateListener();
        });

        vm.tabs = [];
        vm.updateFn = function(arg){
            var obj = [];
            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
            vm.setTab(obj);
        }
        
        vm.listOfActiveScreens = []
        vm.activeScreensList=[];
        function getScreens(_collection,_children){
            
            for (var i = 0; i < _collection.length; i++) {
                var thisChildren =  _collection[i][_children];
                if( thisChildren && thisChildren.length ){
                    getScreens(thisChildren,_children);
                }
                for(var n in thisChildren){
                   _.each(thisChildren[n].screens,function(ss,kk){
                        if(ss.data && ss.data.data_size){
                           //var obj = {"screen_key":ss.screen_key,"record_count":ss.data.data_size};
                            vm.activeScreensList.push(ss);
                        }
                   })
                }
            }
        }
        
        vm.visitedScreenList =function(){
            vm.activeScreensList =[];
            getScreens(USER_MENU,'children')
           vm.listOfActiveScreens = JSON.stringify(vm.activeScreensList);
        }
        
        
    }


    function workspaceMemory($rootScope,$scope,$state,$log,workspaceFactory) {

        var vm = this;
        vm.name = "Workspace Memory"

        vm.toggleNavPanel = function(){
            if( $rootScope.editedSettings.navPanel ===  'closed'){
                $rootScope.editedSettings.navPanel = 'open';
            }else{
                $rootScope.editedSettings.navPanel = 'closed';
            }
        }


    }


    return controllers;

});


