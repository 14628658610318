/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'moment','angular'

		],
		function(moment) {
			'use strict';

			var controllers = angular.module('app.triggerghostsourcingController', [])
					.controller('triggerghostsourcingController',
								['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'gridData', 'JsonObjectFactory','USER_SETTINGS' ,
								'TriggerGhostSourcingFactory', '$timeout', 'TbbsService', 'GENERAL_CONFIG', 'workspaceFactory', triggerghostsourcingController ]);



			function triggerghostsourcingController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, JsonObjectFactory, USER_SETTINGS, TriggerGhostSourcingFactory, $timeout, GENERAL_CONFIG,workspaceFactory) {

				
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.syncSaveDtls = [];
				/* var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year; */
				vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
				vm.isSaveClicked = false;
				
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
								for(var key in vm.gridDataMultipleSelect){
									var gridObj = {};
									
									gridObj["object_id"] = vm.gridDataMultipleSelect[key].data.object_id;
									gridObj["combination_key"] = vm.gridDataMultipleSelect[key].data.COMBINATION_KEY;
									gridObj["leid"] = vm.gridDataMultipleSelect[key].data.LEID;
									gridObj["cdr_number"] = vm.gridDataMultipleSelect[key].data.CDR_NO;

									vm.syncSaveDtls.push(gridObj);
									
								}
					}

				}
				vm.confirm_sync = function() {
					
					 
					var returnClobSettingsObj = {};
					var ecSaveDtls = [];
					var returnObj = {};
					
					var ccKeys = vm.syncSaveDtls.map(item => item.combination_key)
					var ccKeysString = ccKeys.join(',');
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['combination_key'] = ccKeysString;

					var message = "Default Sourcing will be regenerated for selected entities";
					sendDetails(returnClobSettingsObj,ecSaveDtls, message);

				};

				function sendDetails(returnClobSettingsObj, ecSaveDtls, message) {
					$rootScope.$broadcast("showLoader", true);
					$uibModalInstance.dismiss('cancel');
					
					$rootScope.$broadcast("showLoader", false);
					AlertService.add("success", message, 4000);
					TriggerGhostSourcingFactory.triggerSourcing(returnClobSettingsObj,ecSaveDtls).then(function(result) {

						
						/* if (result.data.errorMessage && result.data.errorMessage !== 'null') {
								$uibModalInstance.dismiss('cancel');
							AlertService.add("error", result.data.errorMessage, 4000);
						} else {
							$rootScope.$broadcast("showLoader", false);
							AlertService.add("success", message, 4000);
						};
 */
					});
			   }
		   
			}
			return controllers;

		});