define(
    [ 'cronstrue',
      'angular',
        './ftcSyncConfigService',
    ],
    function(cronstrue) {
        'use strict';

        var controllers = angular
                .module('app.ftcSyncConfigCtrl', ['app.ftcSyncConfigService'])
                .controller('ftcSyncConfigCtrl',
                        [ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService','workspaceFactory',
                                '$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','ftcSyncConfigFactory', 'FTCCommentsFactory', '$http', ftcSyncConfigCtrl ])

        function ftcSyncConfigCtrl($rootScope, $scope, $state, $log,
                JsonObjectFactory, AlertService, GlobalService, workspaceFactory, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, ftcSyncConfigFactory,FTCCommentsFactory,$http) {

            var vm = this;	
            //Show the loading Spinner
            vm.loadingData = true;		
           
            //Selected Row on the Grid passed to Modal/Popup to display in top table
            vm.selectedGridRow = [];

            vm.sync_id = 0;

            vm.configObj = {};
            vm.dateOptions = {};

            //A flag to enable and disable save button
            vm.isSaveClicked = false;
            var savedOnce = false;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};	
            //Get the Grid filter Scenario
            vm.scenario = filterParams.scenario;  	
            //Get the jcd_key from left nav
            vm.jcd_key  = GlobalService.globalParams.jcd_key;
            
            //cancel the pop-up
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
                if (savedOnce == true) {
                    $rootScope.$emit('gridUpdate', vm.args);
                }
            };
            
            //To get the Lock_Y_N flag from backend
            var lockparams = {
                    "action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
                };				
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
                vm.is_locked = data.jsonObject;				
                //To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
                $scope.$watch(function() { return vm.is_locked }, function() {
                    if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
                            vm.cancel();										
                            AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
                            return;
                    }							
                });
            });

            //Retrieve the existing config details of the selected sync
            vm.getConfigDtls = function() {			
                vm.loadingAdjList = true;		
                var params = {"action_code" : '92aeer',"sync_id":vm.sync_id}; 
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                    if (data.jsonObject != null) {
                        vm.configObj = data.jsonObject[0];
                        $scope.translateCron();
                        if (vm.configObj.START_DATE != undefined || vm.configObj.START_DATE != '') {
                            vm.configObj.startDateObj = new Date(vm.configObj.START_DATE);
                        }
                        if (vm.configObj.END_DATE != undefined || vm.configObj.END_DATE != '') {
                            vm.configObj.endDateObj = new Date(vm.configObj.END_DATE);
                        }
                        
                    }    
                    vm.loadingData = false;
                });					
            }

             //When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
             if (gridData != null) {					
                var tempGridData = _.clone(gridData), groups = Object
                        .create(null), result, finalKeyCheck = 0;					
                
                vm.gridDataMultipleSelect = _.clone(tempGridData);					
                
                if (vm.gridDataMultipleSelect != undefined) {						
                    vm.selectedGridRow.push( _.clone(vm.gridDataMultipleSelect.rowData));
                    vm.sync_id = vm.selectedGridRow[0].SYNC_ID;
                    vm.getConfigDtls();	
                }
            }

            $scope.translateCron = function () {
                if (vm.configObj.CRON_EXP) {
                    try {
                        const readableCron = cronstrue.toString(vm.configObj.CRON_EXP);
                        vm.configObj.CRON_DESC = readableCron.includes('undefined') ||
                            readableCron.includes('NaN') ||
                            (readableCron.includes('only in') && $scope.isYearTokenNaN(readableCron)) ?
                            'Error: Invalid tokens found!' : readableCron;
                    } catch (e) {
                        vm.configObj.CRON_DESC = e;
                    }
                } else {
                    vm.configObj.CRON_DESC = 'Error: Enter a valid cron expression!';
                }
            };

            vm.save = function() {	
                console.log("received the configObj: ", vm.configObj); 					
                 vm.isSaveClicked = true;
                
                if (vm.configObj.CRON_EXP == undefined) {
                    AlertService.add("error", "Please enter all the required fields.",4000);
                    vm.isSaveClicked = false;
                    return;
                }
                prepAndSendDetails();
            }

            function prepAndSendDetails() {
                var returnClobSettingsObj = {};
                 returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                 returnClobSettingsObj['tax_year'] = filterParams.tax_year;
                 returnClobSettingsObj['scenario'] = vm.scenario;
                 returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                 returnClobSettingsObj['object_id'] = vm.sync_id;

                 let configObjToSave = _.clone(vm.configObj);
                 configObjToSave.SYNC_ID = vm.sync_id;
                 configObjToSave.START_DATE = getDateString(vm.configObj.startDateObj);
                 configObjToSave.END_DATE = getDateString(vm.configObj.endDateObj);
                 configObjToSave.IS_ACTIVE = vm.configObj.IS_ACTIVE;
                 configObjToSave.UPDATED_ON = getDateString(new Date());

                 //vm.adjObj.adj_date = vm.adjObj.adj_date.getDate() + '-' + (+vm.adjObj.adj_date.getMonth() + 1) + '-' + vm.adjObj.adj_date.getFullYear();
                 console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
                 console.log("configObj-----" + configObjToSave);
                 var message = "FTC Sync Config have been updated";			
                 //send to save the Data			
                 var lockInfo = {};
                 var lockedParams = {};

                 lockInfo = {
                    "tax_year": filterParams.tax_year,
                    "scenario" :  vm.scenario,
                    "jcd_key": vm.jcd_key
                    };
                    
                    lockedParams = configObjToSave;
                    // lockedParams["action_code"] = 'n10cpv';
                    lockedParams.checkLockedbj = JSON.stringify([lockInfo]);

                 sendDetails(returnClobSettingsObj, lockedParams, message);  
            }

            function getDateString(tempDate) {
               if (tempDate != undefined && tempDate != null && !Number.isNaN(tempDate.getTime())) {
                return  (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + '-' + tempDate.getFullYear();
               }
               return '';
            }
           
            //Send Details to the Service Layer for persisting the data
            function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
                ftcSyncConfigFactory.saveData(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {
                    if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                        AlertService.add("error", result.data.errorMessage, 4000);
                        vm.isSaveClicked = false;
                    } else {
                        AlertService.add("success", message, 4000);
                        vm.args = {
                                object_id: returnClobSettingsObj.object_id,
                                gridFilter: {
                                    object_id: returnClobSettingsObj.object_id
                                }
                            };
                        $uibModalInstance.dismiss('cancel');
                        $rootScope.$emit('gridUpdate', vm.args);
                        vm.isSaveClicked = false;
                        };
                });
            }	
            

        }
    }
);