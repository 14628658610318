define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.gridBatchSaveController',[])

        .controller('GridBatchSaveController', ['$location','$document','$rootScope','$scope','$state','$uibModal','$log','GlobalService','workspaceFactory','GridFactory','ModalFactory','AlertService','$timeout','JsonObjectFactory','DataFiltersFactory','GridSaveFactory','MessageFactory', girdBatchSaveController])
        .controller('GridActionCtrl', ['$location','$document','$rootScope','$scope','$state','$uibModalInstance','$log','GlobalService','workspaceFactory','GridFactory','ModalFactory','AlertService','$timeout','JsonObjectFactory','DataFiltersFactory','GridSaveFactory','MessageFactory','gridData','GENERAL_CONFIG','USER_SETTINGS', gridActionCtrl]);


    function gridActionCtrl($location,$document,$rootScope,$scope,$state,$uibModalInstance,$log,GlobalService,workspaceFactory,GridFactory,ModalFactory,AlertService,$timeout,JsonObjectFactory,DataFiltersFactory,GridSaveFactory,MessageFactory,gridData,GENERAL_CONFIG,USER_SETTINGS) {
        var vm = this;
        vm.name = "Grid Action Manager";
        vm.saveObj = {};
        vm.allowSave = false;
        vm.editNum = 0;
        vm.deleteNum = 0;

        vm.action = {};
        vm.action.name = "";
        vm.action.type = "";
        vm.action.loading = false;
        vm.selectedAction = null;
        vm.action.system = 0;
        vm.action.sign_off = 0;

        var URLS =  {
            save: GENERAL_CONFIG.base_url+'/saveGridActionTemplate',
        }

        vm.userAccessLevel = USER_SETTINGS.user.system_access_level;

        vm.actionList = [];




        function setDropDown(){



           var actionTemplates = (workspaceFactory.activeScreen.data.actionTemplates) ? workspaceFactory.activeScreen.data.actionTemplates : [];

            if( !_.isEmpty(actionTemplates) ) {
                vm.actionList = actionTemplates;
            }else{

            }


        }

        setDropDown();


        vm.setType = function (_type) {
            //used to enable "create new action manager" when the pivot options are enabled.
            var pivotGroupObj = workspaceFactory.activeScreen.data.pivotGroup;

            if( _type === "edit" ){
                if(vm.selectedAction){
                    console.log("vm.selectedAction " , vm.selectedAction);
                    vm.action = vm.selectedAction;
                    vm.action.type = "edit";
                }else{
                    AlertService.add("warn ", "Please select a Grid Action  " , 3000);
                }
            }else{


                if(!checkForFilters(gridData.tableState) && !(pivotGroupObj && pivotGroupObj.groupRows.length > 0 || pivotGroupObj && pivotGroupObj.columnToRows.length > 0 )){
                    AlertService.add("warning", "You do not have any filters set with this action. You must apply one or more filters to set an action template  ");
                    return false;
                }


                vm.action = {};
                vm.action.type = "new";
                vm.action.name = "";
                vm.action.loading = false;
                vm.selectedAction = null;
                vm.action.system = 0;
                vm.action.sign_off = 0;

            }
        };



        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.dateOptions = {
            initDate: new Date(),
            dateFormat: 'mm-dd-yy'
        };



        function checkForFilters(_obj){
            var val = false;
            if( !_.isEmpty(_obj.colSearch)  ||  !_.isEmpty(_obj.search) || !_.isEmpty(_obj.sort) ){
                return true;
            }else{
                return false;
            }

        }



        vm.deleteAction = function(){
            vm.action.type = 'delete';
            vm.save();
        }



        vm.save = function(){
            let formatChecker = /[<>]/;
            var checkedFlag = formatChecker.test(vm.action.name);
            if(!checkedFlag) {
                if( vm.action.loading){
                    AlertService.add("info", "Form has already been submitted. ");
                    return false;
                }

                if (vm.gridActionFrom.$invalid) {
                    vm.gridActionFrom.$submitted = true;
                    AlertService.add("danger", "Please correct the errors below", 4000);
                    return;
                }

                vm.action.loading = true;


                console.log("vm.action =============== "  ,vm.action);
                console.log("gridData.sign_offDate =============== "  ,vm.action.sign_offDate);
                console.log("workspaceFactory.activeScreen.data.settings.grid_key =============== "  ,workspaceFactory.activeScreen.data.settings.grid_key);
                console.log("gridData =============== "  ,gridData);
                console.log("------------------------------------------" ,  vm.action );


                var params = vm.action;
                params.grid_key = (workspaceFactory.activeScreen.data.settings.grid_key) ? workspaceFactory.activeScreen.data.settings.grid_key : null;
                    
                if(workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data.pivotGroup 
                    && ((workspaceFactory.activeScreen.data.pivotGroup.columnToRows && workspaceFactory.activeScreen.data.pivotGroup.columnToRows.length>0) || 
                    (workspaceFactory.activeScreen.data.pivotGroup.groupRows && workspaceFactory.activeScreen.data.pivotGroup.groupRows.length>0))){
                        params.action_type = "pivot";
                        params.gridData = JSON.stringify(workspaceFactory.activeScreen.data.pivotGroup );
                }else{
                params.gridData = JSON.stringify(gridData);
        
                }
            
                console.log("params =============== "  ,params);



                JsonObjectFactory.saveJSONWParams(URLS.save,params).then(function (response) {
                console.log("response " , response);

                    if(response.callSuccess != "1"){
                        AlertService.add("error", "Error occurred saving template data. If this continues please contact support" , 3000);
                    }else{

                    console.log("response.actionTemplates " , response.actionTemplates);

                        AlertService.add("success", "Action template was saved" , 3000);
                        workspaceFactory.activeScreen.data.actionTemplates = response.actionTemplates;
                        $rootScope.$broadcast('loadActonTemplates', workspaceFactory.activeScreen.data.actionTemplates);
                        $uibModalInstance.dismiss('cancel');
                    }

                    vm.action.loading = false;
                });
            } else {
                AlertService.add("warning", "Do not use special characters like <, > for Action Name field", 4000);
            }



        };






    }



    function girdBatchSaveController($location,$document,$rootScope,$scope,$state,$uibModal,$log,GlobalService,workspaceFactory,GridFactory,ModalFactory,AlertService,$timeout,JsonObjectFactory,DataFiltersFactory,GridSaveFactory,MessageFactory) {
        var vm = this;
        vm.name = "Batch Save";
        vm.saveObj = {};
        vm.allowSave = false;
        vm.editNum = 0;
        vm.deleteNum = 0;
        vm.addNum = 0;


        //IF THIS IS THE FIRST LOAD THEN ADD IMPORT SCREEN OPTIONS
        if( typeof workspaceFactory.activeScreen.batchSave === "undefined" ){
            createBatchSave()
        }



        function createBatchSave(){

            workspaceFactory.activeScreen.batchSave = {
                headersAdded:false,
                dirty:false,
                submitted:false,
                times_submitted:0,
                job_number:null,
                saving:false,
                toDelete:[],
                toModify:[],
                toAdd:[],
                undoRedo:[],
                rowsSaved:[],
                errors:[],
                undoRedoIndex:0,
                btns : {
                    "undo":false,
                    "redo":false,
                    "save":false,
                    "cancel":false
                }
            }

            vm.saveObj = workspaceFactory.activeScreen.batchSave;


        }

        vm.saveObj = workspaceFactory.activeScreen.batchSave;
        vm.btns = workspaceFactory.activeScreen.batchSave.btns;

        function showHideBtns(){

            var data = (workspaceFactory.activeScreen.data) ? workspaceFactory.activeScreen.data.data : [] ;
            vm.saveObj.changed = _.filter(data, function(row) {
                return row.gridBatchStatus === 'edit' || row.gridBatchStatus === 'delete' || row.gridBatchStatus === 'new';
            });


            console.log("vm.saveObj.changed =========================== " , vm.saveObj.changed)




            if( vm.saveObj.undoRedo.length > 0 &&  vm.saveObj.undoRedoIndex > 0 ){
                vm.btns.undo = true;
            }else{
                vm.btns.undo = false;
            }

            if( vm.saveObj.undoRedo.length > 0 &&  vm.saveObj.undoRedoIndex <  vm.saveObj.undoRedo.length  ){
                vm.btns.redo = true;
            }else{
                vm.btns.redo = false;
            }

            if( vm.saveObj.changed.length ){
                vm.btns.save = true;
                vm.btns.cancel = true;
            }else{
                vm.btns.save = false;
                vm.btns.cancel = false;
            }


            //console.log("vm.saveObj.undoRedo " , vm.saveObj.undoRedo );

            //TODO: this needs to be moved to saveObj.toModify so we do not need to loop the whole data set twice
            vm.editNum = _.filter(data, { 'gridBatchStatus': 'edit' }).length;
            vm.deleteNum = _.filter(data, { 'gridBatchStatus': 'delete' }).length;
            vm.addNum = _.filter(data, { 'gridBatchStatus': 'new' }).length;

        }

        showHideBtns();



        if(vm.saveObj.saving){
            $timeout(function() {
                $rootScope.$broadcast('showLoader', true);
            },1000)
        }



        vm.save = function(){




            console.log("---------_--------------------------------------" );
            console.log("---------_---------------------------------------");


            var data = (workspaceFactory.activeScreen.data) ? workspaceFactory.activeScreen.data.data : [] ;


            vm.saveObj.changed = _.filter(data, function(row) {
                return row.gridBatchStatus === 'edit' || row.gridBatchStatus === 'delete' || row.gridBatchStatus === 'new';
            });


            //TODO ADD ANOTHER CHECK HERE THAT ALL REQUIRED FIELDS ARE VALID
            var headers = workspaceFactory.activeScreen.data.header;
            var requiredError = false;
            _.forEach(vm.saveObj.changed, function(value, key) {

                var rowErrorMessage = "The following field(s) are required: ";
                var rowError = false;

                _.forEach(headers, function(hValue, hKey) {



                    console.log("---------_",  value );
                    console.log("---------_",  value[hValue.map] );






                    if( hValue.required && hValue.required === "1" &&  !value[hValue.map]  ){
                        console.log("Required: " ,  hValue.map , " : " ,  value[hValue.map]);
                        rowErrorMessage += " " + hValue.map
                        rowError = true;
                        requiredError = true;
                    }
                });

                if(rowError ){
                    console.log(" rowErrorMessage : " , rowErrorMessage)
                    console.log(" value : " , value)
                    value.gridBatchErrorMSG = rowErrorMessage;
                    value.row_class += " " + "gg-error-row";
                }
            });




            if(requiredError){
                $timeout(function() {
                    AlertService.add("warning", "Please fill out all the required fields ");
                    $rootScope.$broadcast('gridRefreshPipe', {});
                },10)

                return false;
            }


            _.filter(data, function(row) {
                if(row.row_class && row.row_class === 'gg-error-row' ){row.row_class = null;}
            });


            params = _.merge({},  GlobalService.globalParams , params);
            vm.saveObj.submitted = true;
            vm.saveObj.btns.save = false;
            vm.saveObj.btns.cancel = false;

            $rootScope.$broadcast('showLoader', true);

            var jsonString = "";
            var url = $state.current.api.save;

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);

            //TODO: GET THIS FROM SCREEN
            params.process_name =  "SAVE_TGDC_BSLA_MAST";
            params.clob_settings = JSON.stringify(_.cloneDeep(params));
            params.clob_data =  JSON.stringify(vm.saveObj.changed);


            if(typeof url === 'undefined' ){
                $rootScope.$broadcast('showLoader', false);
                AlertService.add("warning", "Save call is not set in the screen. Please contact support to correct the issue");
                return false;
            }

            var errorArray = [];
            var insertArray = [];
            JsonObjectFactory.saveJSON(url,params).then(function (response) {
                if(response.callSuccess != "1"){
                    AlertService.add("error", response.errorMessage,4000);
                    $rootScope.$broadcast('showLoader', false);
                }else{
                    errorArray =  response.errorList;
                    insertArray  = response.insertList;
                    modifyData(data,errorArray,insertArray);
                }
            });

            return true;
        }



        function modifyData(data,error_object,insert_array){

            vm.editNum = 0;
            vm.deleteNum = 0;
            vm.addNum = 0;
            vm.saveObj.errors = [];


            clearErrorMessages(data);

            _.forEach(vm.saveObj.changed, function(value, key) {
                value.row_class = null;

                //IF DELETE
                if( value.gridBatchStatus === 'delete' ) {
                    var obj = _.find(error_object, {'object_id': value.object_id});
                    if (typeof obj === 'undefined') {
                        removeRow(value.object_id, data);
                    } else {
                        value.gridBatchErrorMSG = obj.error_message;
                        value.row_class = "gg-error-row";
                        vm.saveObj.errors.push(value);
                    }
                    //IF EDIT
                }else if( value.gridBatchStatus === 'edit'  ){
                    var obj = _.find(error_object, {'object_id': value.object_id});
                    if (typeof obj !== 'undefined') {
                        value.gridBatchErrorMSG = obj.error_message;
                        value.row_class = "gg-error-row";
                        vm.saveObj.errors.push(value);
                    }else{
                        delete  value.gridBatchStatus;
                    }


                }else if( value.gridBatchStatus === 'new'  ){

                    var obj = _.find(error_object, {'object_id': value.object_id});
                    var objInsert = _.find(insert_array, {'object_id': value.object_id});

                    if (typeof obj !== 'undefined') {
                        value.gridBatchErrorMSG = obj.error_message;
                        value.row_class = "gg-error-row";
                        vm.saveObj.errors.push(value);
                    }

                    if (typeof objInsert !== 'undefined') {
                        //WE WILL REMOVE THIS ID FROM THE NEW ROW ARRAY
                        $rootScope.$broadcast('removeNewRow' , value.object_id );
                        value.object_id = objInsert.new_object_id;
                        value.OBJECT_ID = objInsert.new_object_id;
                        value.row_class = "gg-success-row";
                        delete  value.gridBatchStatus;
                    }

                }


                vm.saveObj.undoRedo = [];
                vm.saveObj.undoRedoIndex = 0;
                showHideBtns();
                ++vm.saveObj.times_submitted;

            });


            $rootScope.$broadcast('hideLoader');
            $rootScope.$broadcast('dataUpdate', workspaceFactory.activeScreen.data);

            if(error_object.length ){
                AlertService.add("warning", "Not all of you modifications were saved please view below", 2000);
            }else{
                AlertService.add("success", "All of the modifications were successfully saved", 2000);
            }

            return true;
        }

        function removeRow(_object_id,_data){
            var objIndex = _.findIndex(_data, { 'object_id': _object_id });
            if(objIndex > -1){
                _data.splice(objIndex, 1);
            }
        }


        function clearErrorMessages(_data){
            var errorRows = _.filter(_data, function(row) {
                return  typeof row.gridBatchErrorMSG !== 'undefined' && row.gridBatchErrorMSG !== null ;
            });
            _.forEach(errorRows, function(value, key) {
                delete  value.gridBatchErrorMSG;
            })
        }


        vm.cancel = function(){
            $rootScope.$broadcast(' dataFilters:refreshGrid', {});
        }


        $scope.$on('clearBatchSave', function (event) {
            delete  workspaceFactory.activeScreen.batchSave;
            createBatchSave();
        });

        $scope.$on('batchSaveLoaded', function (event, data) {
            addSaveHeaders(data);
            $timeout(function() {
                showHideBtns();
            },10)
        })



        vm.dumpData = function(){

            showHideBtns();
            var undoObject = vm.saveObj.undoRedo[vm.saveObj.undoRedoIndex -1];

            //console.log("vm.saveObj.undoRedoIndex " , vm.saveObj.undoRedoIndex);
            //console.log("vm.saveObj.undoRedo " , vm.saveObj.undoRedo);



            //console.log("undoObject " , undoObject);
            //console.log("vm.saveObj.undoRedo " , vm.saveObj.undoRedo);

        }



        vm.redo = function(){
            if( vm.saveObj.undoRedoIndex < vm.saveObj.undoRedo.length ){
                ++vm.saveObj.undoRedoIndex;
                redoUndo(vm.saveObj.undoRedoIndex,"forward")
                showHideBtns();
            }
        }


        vm.undo = function() {
            if( vm.saveObj.undoRedoIndex > 0 ){
                redoUndo(vm.saveObj.undoRedoIndex,"back")
                --vm.saveObj.undoRedoIndex;
                showHideBtns();
            }
        }



        function getRowClass(status){
            if(status === 'edit'){
                return 'gg-edit-row'
            }else if(status === 'delete' ){
                return 'gg-delete-row'
            }else if(status === 'new' ){
                return 'gg-new-row'
            }else{
                return null;
            }
        }



        function redoUndo (_index,_direction){
            var data = workspaceFactory.activeScreen.data.data;
            var undoObject = vm.saveObj.undoRedo[vm.saveObj.undoRedoIndex -1];
            var object_id = undoObject.object_id
            var objIndex = _.findIndex(data, { 'object_id': object_id });

            if( undoObject.type === 'edit' ){
                if(objIndex > -1 ){
                    var obj = data[objIndex];
                    if( undoObject.status == null  && obj.gridBatchStatus ){
                        delete  obj.gridBatchStatus;
                    }else if( _direction === 'forward'){
                        obj.gridBatchStatus = undoObject.newStatus;
                    }else if( _direction === 'back'){
                        obj.gridBatchStatus = undoObject.status;
                    }

                    var objectName = undoObject.map;
                    obj[objectName] = (_direction === 'back' ) ?  undoObject.value : undoObject.newValue;

                    if( _direction === 'back' ){
                        obj.row_class = getRowClass( undoObject.status);
                    }else{
                        obj.row_class = getRowClass( undoObject.newStatus);

                    }
                }
            }else if( undoObject.type === 'delete') {

                _.forEach(undoObject.objectArray, function(value, key) {

                    var objIndex = _.findIndex(data, { 'object_id': value.object_id });
                    if(objIndex > -1 ){
                        var obj = data[objIndex];
                        //console.log(obj);
                        var status = ( _direction === 'back' ) ? value.oldStatus : value.newStatus;
                        if(status !== null ){
                            obj.gridBatchStatus = status;
                        }else{
                            delete  obj.gridBatchStatus;
                        }

                        if( _direction === 'back' ){
                            //console.log(value.oldStatus);
                            if( value.oldStatus === null){
                                obj.row_class = null;
                            }else{
                                obj.row_class = getRowClass( value.oldStatus);
                            }

                        }else{
                            obj.row_class = "gg-delete-row";

                        }


                    }

                })


            }else if( undoObject.type === 'new') {
                if( _direction === 'forward'){
                    var newRow = {object_id: undoObject.object_id, gridBatchStatus: "new", row_class: "gg-new-row"}
                    data.splice(undoObject.index,0,undoObject.row);
                }else if( _direction === 'back' && objIndex > 0 ){
                    data.splice(objIndex,1);
                }
            }

            $rootScope.$broadcast('gridRefreshPipe', {});
        }


        //TODO: Fix the error that happens when you remove a row
        $scope.$on('rowAdded', function (event, _data) {
            if(_data.type === "add"){
                ++vm.saveObj.undoRedoIndex;
                var undoObj = {object_id: _data.data.object_id ,  row:_data.data,   map: 'object_id', value: null , status: null, row_class:"", new_row_class:"gg-new-row" , type:"new", newValue:null, newStatus: _data.data.gridBatchStatus, index:_data.index}
                vm.saveObj.undoRedo.push(undoObj);
                _data.data.row_class = "gg-new-row";
                removeUndosToIndex(vm.saveObj.undoRedo,vm.saveObj.undoRedoIndex);
            }
            $timeout(function() {
                showHideBtns();
            },10)

        });


        $scope.$on('dataChanged', function (event, data) {

            if( data.oldRowData.gridBatchStatus && data.oldRowData.gridBatchStatus === 'new'){
                removeUndosToIndex(vm.saveObj.undoRedo,vm.saveObj.undoRedoIndex);
                ++vm.saveObj.undoRedoIndex;
                var status = (data.oldRowData.gridBatchStatus ) ? data.oldRowData.gridBatchStatus : null;
                var newStatus = (data.rowData.gridBatchStatus ) ? data.rowData.gridBatchStatus : null;
                var undoObj = {object_id: data.rowData.object_id , map: data.colData.map, value: data.oldRowData[data.colData.map], status: status, type:"edit", newValue:data.rowData[data.colData.map], newStatus:"new"}
                vm.saveObj.undoRedo.push(undoObj);
                removeUndosToIndex(vm.saveObj.undoRedo,vm.saveObj.undoRedoIndex);

            }else{

                data.rowData.row_class = "gg-edit-row"
                removeUndosToIndex(vm.saveObj.undoRedo,vm.saveObj.undoRedoIndex);
                ++vm.saveObj.undoRedoIndex;
                var status = (data.oldRowData.gridBatchStatus ) ? data.oldRowData.gridBatchStatus : null;
                var newStatus = (data.rowData.gridBatchStatus ) ? data.rowData.gridBatchStatus : null;
                var undoObj = {object_id: data.rowData.object_id , map: data.colData.map, value: data.oldRowData[data.colData.map], status: status, type:"edit", newValue:data.rowData[data.colData.map], newStatus:"edit"}
                vm.saveObj.undoRedo.push(undoObj);
                removeUndosToIndex(vm.saveObj.undoRedo,vm.saveObj.undoRedoIndex);
                data.rowData.gridBatchStatus = "edit";

            }

            $timeout(function() {
                showHideBtns();
            },10)

        });


        // Function:  removeUndosToIndex
        // Purpose: After we have moved back through history of undoObject if a change is made we will need to remove history that is ahead of edit
        // Example We have an array of 7 objects we move back to position 4 and make a change. We need to remove 4 - 7 of the array and the new change will be position 5
        function removeUndosToIndex(array,index){
            array.length = index;
        }


        $scope.$on('batchSaveDelete', function (event, data) {
            var objectArray = [];
            _.forEach(data, function(value, key) {
                if(value.data.gridBatchStatus !== "new"){
                    var oldStatus = (value.data.gridBatchStatus) ? value.data.gridBatchStatus : null;
                    value.data.gridBatchStatus = "delete";
                    var obj = { object_id:value.data.object_id , oldStatus:oldStatus, newStatus:value.data.gridBatchStatus }
                    objectArray.push(obj);
                    value.data.row_class = "gg-delete-row";
                }
            })

            ++vm.saveObj.undoRedoIndex;
            var undoObj = {object_id: null , map: null, value: null , status: null, type:"delete", newValue:null,
                            newStatus:"edit", objectArray:objectArray}
            vm.saveObj.undoRedo.push(undoObj);

            $timeout(function() {
                showHideBtns();
            },10)

            $rootScope.$broadcast('gridRefresh', {});
        })

        $scope.$on('batchSaveUnDelete', function (event, data) {
            _.forEach(data, function(value, key) {
                if(value.data.gridBatchStatus !== "new") {
                    delete  value.data["gridBatchStatus"];
                    value.data.row_class = null;
                }
            });

            vm.allowSave = true;
            $rootScope.$broadcast('gridRefresh', {});
            $timeout(function() {
                showHideBtns();
            },10)

        })



        function reloadData(data){
            $rootScope.$broadcast('dataLoaded', data);
        }


        function addSaveHeaders(data){

            //Make sure we only call this once
            if(vm.saveObj.headersAdded){
                return true;
            }

            var errorHeader = {
                call_back: null,
                col_class: "",
                data_type: "string",
                display: "y",
                drilldown: null,
                edit_type: "text",
                editable: "n",
                format: null,
                full_label: null,
                grid_header_key: "168",
                grid_key: "10",
                header_class: null,
                label: "Message",
                map: "gridBatchErrorMSG",
                object_id: "gridBatchErrorMSG",
                priority: 2,
                validation: null,
                width: 120
            }
            var statusHeader = {
                call_back: null,
                col_class: "",
                data_type: "string",
                display: "y",
                drilldown: null,
                edit_type: "text",
                editable: "n",
                format: null,
                full_label: null,
                grid_header_key: "168",
                grid_key: "10",
                header_class: null,
                label: "Status",
                map: "gridBatchStatus",
                object_id: "gridBatchStatus",
                priority: 2,
                validation: null,
                width: 80
            }



            //ADD BATCH ACTIONS
            if( typeof  data.settings.grid_action === 'undefined' ){
                data.settings.grid_action = [];
            }
            var deleteAction = {call_back:"batchSaveDelete",grid_action_key:"null",grid_key:data.settings.grid_key,icon:"fa fa-times",name:"Remove Row(s)",row_data_required:1}
            var unDeleteAction = {call_back:"batchSaveUnDelete",grid_action_key:"null",grid_key:data.settings.grid_key,icon:"fa fa-times",name:"Un Remove Row(s)",row_data_required:1}
            data.settings.grid_action.push(deleteAction);
            data.settings.grid_action.push(unDeleteAction);


            data.header.splice(2, 0, errorHeader);
            data.header.splice(3, 0, statusHeader);


            vm.saveObj.headersAdded = true;

            reloadData(data);
        }
    }

   
    return controllers;

});