define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module(
					'app.recommendedAutomationsController', []).controller(
					'RecommendedAutomationsController',
					[ '$rootScope', '$scope', '$http', 'GlobalService',
							'$uibModalInstance', 'ModalFactory',
							'AlertService', 'rowData', 'gridData',
							'JsonObjectFactory', '$timeout',
							'RecommendedAutomationsFactory', 'GENERAL_CONFIG',
							recommendedAutomationsController ])

			function recommendedAutomationsController($rootScope, $scope,
					$http, GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					$timeout, RecommendedAutomationsFactory, GENERAL_CONFIG) {

				var vm = this;
				$scope.crudLoading = false;
				$scope.rowData = rowData || $scope.$parent.rowData;
				vm.checksystemaccount = [];

				// vm.changeColorvariance = false;
				function init() {
					$scope.crudLoading = false;
					if (rowData != null) {
						var sendobj = [];
						vm.tempsystemaccount = [];
						vm.mainmodaldata = [];
						vm.template = rowData.SCHDM_TEMPLATE_ID + " - "
								+ rowData.SCHDM_STD_DESC;
						vm.userm1 = rowData.SCHDM_USER_DESC;
						vm.multiplier = rowData.MULTIPLIER;
						vm.schdmt = rowData.SCHDM_TYPE_DESC;
						vm.tbbsacctval = rowData.TBBS_ACCT + "-"
								+ rowData.TBBS_ACCT_DESC;
						vm.schdmtype = [];
						vm.schdmtypedata = [];
						vm.tbbsacct = [];
						vm.tbbsacctdata = [];
						loadtemplatedata();
					}

				}
				function loadtemplatedata() {
					vm.mainmodaldata = [];
					vm.templateLoading = true;
					var params = {
						"action_code" : 'yz4utp',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"sys_acct" : rowData.TRIGGER_CHART_KEY + "_"
								+ rowData.POSTING_ACCT_KEY + "-"
								+ rowData.POSTING_TAC_KEY

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.templateLoading = false;
										} else {
											vm.mainmodaldata = data.jsonObject;
											for ( var i in vm.mainmodaldata) {
												vm.tempsystemaccount
														.push(data.jsonObject[i].SCHDM_TEMPLATE);
											}
											vm.tempsystemaccount = _.uniq(
													vm.tempsystemaccount,
													JSON.stringify)
											vm.templateLoading = false;
											loadschdmtype_inside();
											function loadschdmtype_inside() {

												var schdm_temp = "";
												vm.schdmtype = [];
												vm.hideschdmtype = false;
												vm.schdmloading = true;
												vm.schdmtypedata = [];
												for ( var a in vm.mainmodaldata) {
													if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
														schdm_temp = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
													}
												}

												var params = {
													"action_code" : 'jhsi7h',
													"tax_year" : GlobalService.globalParams.tax_year,
													"scenario" : GlobalService.globalParams.scenario,
													"jcd_key" : GlobalService.globalParams.jcd_key,

													"sys_acct" : rowData.LOCAL_CHART_KEY
															+ "_"
															+ rowData.LOCAL_ACCT_KEY
															+ "-"
															+ rowData.SYSTEM_ACCT_KEY,
													// "local_accts":
													// rowData.SYS_ACCT_KEY,
													// "schdm_temp" :
													// _item.SCHDM_TEMPLATE_DEFAULT,
													"schdm_temp" : schdm_temp,

												}

												return JsonObjectFactory
														.getJSONObj(
																GENERAL_CONFIG.base_url
																		+ '/loadJsonObject',
																params)
														.then(
																function(data) {

																	if (data.callSuccess !== "1") {
																		AlertService
																				.add(
																						"error",
																						data.errorMessage);
																		vm.schdmloading = false;
																		vm.hideschdmtype = false;
																	} else {
																		vm.schdmtypedata = data.jsonObject;
																		// vm.schdmt
																		// =
																		// vm.schdmtypedata[0].SCHDM_TYPE_DEFAULT;
																		for ( var i in data.jsonObject) {
																			vm.schdmtype
																					.push(vm.schdmtypedata[i].SCHDM_TYPE);

																		}
																		vm.schdmtype = _
																				.uniq(
																						vm.schdmtype,
																						JSON.stringify)

																		vm.hideschdmtype = false;
																		vm.schdmloading = false;
																		vm
																				.checktypeError();
																		loadtbbsdata_temp_inside();
																		function loadtbbsdata_temp_inside() {

																			vm.hidetbbss = false;
																			vm.tbbsLoading = true;
																			var schdm_temp_tbbs = "";
																			vm.tbbsacct = [];
																			vm.tbbsacctdata = [];
																			// vm.tbbsacctval
																			// ="";
																			var temp_schdm_key = "";
																			for ( var a in vm.mainmodaldata) {
																				if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
																					schdm_temp_tbbs = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
																				}
																			}
																			for ( var lst in vm.schdmtypedata) {
																				if (vm.schdmt == vm.schdmtypedata[lst].SCHDM_TYPE) {
																					temp_schdm_key = vm.schdmtypedata[lst].SCHDM_TYPE_SAVE;
																				}

																			}

																			var params = {
																				/*"action_code" : '26k8ua',
																				"tax_year" : GlobalService.globalParams.tax_year,
																				"scenario" : GlobalService.globalParams.scenario,
																				"jcd_key" : GlobalService.globalParams.jcd_key,
																				"local_acct" : rowData.TRIGGER_CHART_KEY,
																				"sys_acct" : rowData.TRIGGER_CHART_KEY,
																				"schdm_temp" : schdm_temp_tbbs,
																				'source_system' : 'D',
																				"schdm_type" : temp_schdm_key*/
																					"action_code" : 'wfk6zt',
																					"tax_year" : GlobalService.globalParams.tax_year,
																					"scenario" : GlobalService.globalParams.scenario,
																					"jcd_key" : GlobalService.globalParams.jcd_key,
																					"sys_acct" : rowData.TRIGGER_CHART_KEY
																					+ "_"
																					+ rowData.TRIGGER_ACCT_KEY
																					+ "-"
																					+ rowData.POSTING_TAC_KEY,
																					"local_accts" : rowData.TRIGGER_ACCT_KEY,
																					"schdm_temp" : schdm_temp_tbbs,
																					"schdm_type" : temp_schdm_key,
																					"schdm_method" : rowData.METHOD_OF_CALC,
																					'source_system' : 'D'
																			}

																			return JsonObjectFactory
																					.getJSONObj(
																							GENERAL_CONFIG.base_url
																									+ '/loadJsonObject',
																							params)
																					.then(
																							function(
																									data) {

																								if (data.callSuccess !== "1") {
																									AlertService
																											.add(
																													"error",
																													data.errorMessage);
																									vm.hidetbbss = false;
																									vm.tbbsLoading = false;
																								} else {
																									vm.tbbsacctdata = data.jsonObject;
																									for ( var i in vm.tbbsacctdata) {
																										vm.tbbsacct
																												.push(vm.tbbsacctdata[i].TAX_STREAM_ACCT);
																									}
																									vm.tbbsacct = _
																											.uniq(
																													vm.tbbsacct,
																													JSON.stringify)

																									vm.hidetbbss = false;
																									vm.tbbsLoading = false;
																								}

																							});
																		}
																		;
																	}

																});

											}

										}

									});
				}
				;

				vm.changeTemplate = function() {

					// loadtbbsdata();
					loadschdmtype();

					for ( var ag in vm.mainmodaldata) {
						if (vm.mainmodaldata[ag].SCHDM_TEMPLATE == vm.template) {
							vm.userm1 = vm.mainmodaldata[ag].USER_DESC;
						}
					}
					vm.checktemplateError();

				}
				function loadschdmtype() {

					var schdm_temp = "";
					vm.schdmtype = [];
					vm.hideschdmtype = false;
					vm.schdmloading = true;
					vm.schdmtypedata = [];
					vm.schdmt = "";
					for ( var a in vm.mainmodaldata) {
						if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
							schdm_temp = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
						}
					}

					var params = {
						"action_code" : 'jhsi7h',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,

						"sys_acct" : rowData.LOCAL_CHART_KEY + "_"
								+ rowData.LOCAL_ACCT_KEY + "-"
								+ rowData.SYSTEM_ACCT_KEY,
						// "local_accts": rowData.SYS_ACCT_KEY,
						// "schdm_temp" : _item.SCHDM_TEMPLATE_DEFAULT,
						"schdm_temp" : schdm_temp,

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess !== "1") {
											AlertService.add("error",
													data.errorMessage);
											vm.schdmloading = false;
											vm.hideschdmtype = false;
										} else {
											vm.schdmtypedata = data.jsonObject;
											// vm.schdmt =
											// vm.schdmtypedata[0].SCHDM_TYPE_DEFAULT;
											for ( var i in data.jsonObject) {
												vm.schdmtype
														.push(vm.schdmtypedata[i].SCHDM_TYPE);

											}
											vm.schdmtype = _.uniq(vm.schdmtype,
													JSON.stringify)
											if (vm.schdmtype.length > 0) {
												vm.schdmt = vm.schdmtypedata[0].SCHDM_TYPE_DEFAULT;
											}
											vm.hideschdmtype = false;
											vm.schdmloading = false;
											vm.checktypeError();
											loadtbbsdata_temp();
											function loadtbbsdata_temp() {

												vm.hidetbbss = false;
												vm.tbbsLoading = true;
												var schdm_temp_tbbs = "";
												vm.tbbsacct = [];
												vm.tbbsacctdata = [];
												 vm.tbbsacctval ="";
												var temp_schdm_key = "";
												for ( var a in vm.mainmodaldata) {
													if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
														schdm_temp_tbbs = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
													}
												}
												for ( var lst in vm.schdmtypedata) {
													if (vm.schdmt == vm.schdmtypedata[lst].SCHDM_TYPE) {
														temp_schdm_key = vm.schdmtypedata[lst].SCHDM_TYPE_SAVE;
													}

												}

												var params = {
													/*"action_code" : '26k8ua',
													"tax_year" : GlobalService.globalParams.tax_year,
													"scenario" : GlobalService.globalParams.scenario,
													"jcd_key" : GlobalService.globalParams.jcd_key,
													"local_acct" : rowData.TRIGGER_CHART_KEY,
													"sys_acct" : rowData.TRIGGER_CHART_KEY,
													"schdm_temp" : schdm_temp_tbbs,
													'source_system' : 'D',
													"schdm_type" : temp_schdm_key*/
														"action_code" : 'wfk6zt',
														"tax_year" : GlobalService.globalParams.tax_year,
														"scenario" : GlobalService.globalParams.scenario,
														"jcd_key" : GlobalService.globalParams.jcd_key,
														"sys_acct" : rowData.TRIGGER_CHART_KEY
														+ "_"
														+ rowData.TRIGGER_ACCT_KEY
														+ "-"
														+ rowData.POSTING_TAC_KEY,
														"local_accts" : rowData.TRIGGER_ACCT_KEY,
														"schdm_temp" : schdm_temp_tbbs,
														"schdm_type" : temp_schdm_key,
														"schdm_method" : rowData.METHOD_OF_CALC,
														'source_system' : 'D'
													

												}

												return JsonObjectFactory
														.getJSONObj(
																GENERAL_CONFIG.base_url
																		+ '/loadJsonObject',
																params)
														.then(
																function(data) {

																	if (data.callSuccess !== "1") {
																		AlertService
																				.add(
																						"error",
																						data.errorMessage);
																		vm.hidetbbss = false;
																		vm.tbbsLoading = false;
																	} else {
																		vm.tbbsacctdata = data.jsonObject;
																		for ( var i in vm.tbbsacctdata) {
																			vm.tbbsacct
																					.push(vm.tbbsacctdata[i].TAX_STREAM_ACCT);
																		}
																		vm.tbbsacct = _
																				.uniq(
																						vm.tbbsacct,
																						JSON.stringify)

																		if (vm.tbbsacct.length > 0) {
																			vm.tbbsacctval = vm.tbbsacctdata[0].TAX_STREAM_ACCT;
																		}
																		vm.hidetbbss = false;
																		vm.tbbsLoading = false;
																	}

																});
											}
											;
										}

									});

				}
				;
				vm.checktypeError = function() {
					if (vm.schdmt === "" || vm.schdmt === undefined) {
						vm.typeError = true;
					} else {
						vm.typeError = false;
					}

				}
				vm.checktemplateError = function() {
					if (vm.template === "" || vm.template === undefined) {
						vm.templateError = true;
					} else {
						vm.templateError = false;
					}

					vm.m1Desc();

				}
				vm.m1Desc = function() {
					if (vm.userm1 === "" || vm.userm1 === undefined) {
						vm.userDescError = true;
					} else {
						vm.userDescError = false;
					}

				}

				vm.tbbsChange = function() {

					if (vm.tbbsacctval === "" || vm.tbbsacctval === undefined) {
						vm.tbbsError = true;
					} else {
						vm.tbbsError = false;
					}

				}
				vm.typeChange = function() {

					vm.checktypeError();
					vm.tbbsacctval = "";

					loadtbbsdata();
					function loadtbbsdata() {

						vm.hidetbbss = false;
						vm.tbbsLoading = true;
						var schdm_temp_tbbs = "";
						vm.tbbsacct = [];
						vm.tbbsacctdata = [];
						vm.tbbsacctval = "";
						var temp_schdm_key = "";
						for ( var a in vm.mainmodaldata) {
							if (vm.mainmodaldata[a].SCHDM_TEMPLATE == vm.template) {
								schdm_temp_tbbs = vm.mainmodaldata[a].SCHDM_TEMPLATE_ID;
							}
						}
						for ( var lst in vm.schdmtypedata) {
							if (vm.schdmt == vm.schdmtypedata[lst].SCHDM_TYPE) {
								temp_schdm_key = vm.schdmtypedata[lst].SCHDM_TYPE_SAVE;
							}

						}

						var params = {
							/*"action_code" : '26k8ua',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"local_acct" : rowData.TRIGGER_CHART_KEY,
							"sys_acct" : rowData.TRIGGER_CHART_KEY,
							"schdm_temp" : schdm_temp_tbbs,
							'source_system' : 'D',
							"schdm_type" : temp_schdm_key*/
								"action_code" : 'wfk6zt',
								"tax_year" : GlobalService.globalParams.tax_year,
								"scenario" : GlobalService.globalParams.scenario,
								"jcd_key" : GlobalService.globalParams.jcd_key,
								"sys_acct" : rowData.TRIGGER_CHART_KEY
								+ "_"
								+ rowData.TRIGGER_ACCT_KEY
								+ "-"
								+ rowData.POSTING_TAC_KEY,
								"local_accts" : rowData.TRIGGER_ACCT_KEY,
								"schdm_temp" : schdm_temp_tbbs,
								"schdm_type" : temp_schdm_key,
								"schdm_method" : rowData.METHOD_OF_CALC,
								'source_system' : 'D'
						}

						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {

											if (data.callSuccess !== "1") {
												AlertService.add("error",
														data.errorMessage);
												vm.hidetbbss = false;
												vm.tbbsLoading = false;
											} else {
												vm.tbbsacctdata = data.jsonObject;
												for ( var i in vm.tbbsacctdata) {
													vm.tbbsacct
															.push(vm.tbbsacctdata[i].TAX_STREAM_ACCT);
												}
												vm.tbbsacct = _.uniq(
														vm.tbbsacct,
														JSON.stringify)

												if (vm.tbbsacct.length > 0) {
													vm.tbbsacctval = vm.tbbsacctdata[0].TAX_STREAM_ACCT;
												}
												vm.hidetbbss = false;
												vm.tbbsLoading = false;
											}

										});
					}
					;

				}

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				vm.ObjectIDLIST = [];
				vm.confirm = function() {
					vm.sendpopupData = [];
					var tmp_json = {};
					var all_object_id = gridData[0].data.OBJECT_ID;
					for ( var e in gridData) {
						tmp_json = {};
						tmp_json.TAX_YEAR = GlobalService.globalParams.tax_year;
						tmp_json.SCENARIO = GlobalService.globalParams.scenario;
						tmp_json.JCD_KEY = GlobalService.globalParams.jcd_key;
						tmp_json.TRIGGER_CHART_KEY = gridData[e].data.TRIGGER_CHART_KEY;
						tmp_json.TRIGGER_ACCT_KEY = gridData[e].data.TRIGGER_ACCT_KEY;
						tmp_json.TEMPLATE_ID = gridData[e].data.SCHDM_TEMPLATE_ID;
						tmp_json.SCHDM_TYPE = gridData[e].data.SCHDM_TYPE;
						tmp_json.TBBS_ACCT_KEY = gridData[e].data.TBBS_ACCT_KEY;
						tmp_json.MULTIPLIER = gridData[e].data.MULTIPLIER;
						tmp_json.USER_DESC = gridData[e].data.SCHDM_USER_DESC;
						tmp_json.POSTING_TAC_KEY = gridData[e].data.POSTING_TAC_KEY;
						tmp_json.POSTING_ACCT_KEY = gridData[e].data.POSTING_ACCT_KEY;
						tmp_json.METHOD_OF_CALC = gridData[e].data.METHOD_OF_CALC;
						tmp_json.PROVISION_YN = gridData[e].data.PROVISION_YN;
						vm.sendpopupData.push(tmp_json);
						all_object_id += '_' + gridData[e].data.OBJECT_ID;
						vm.ObjectIDLIST.push({OBJECT_ID:gridData[e].data.OBJECT_ID});

					}
					RecommendedAutomationsFactory
							.saverecommAto(vm.sendpopupData, vm.sendpopupData)
							.then(
									function(result) {

										if (result.callSuccess !== "1") {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoading = false;

										} else {
											AlertService
													.add(
															"success",
															"Data has been saved successfully ",
															4000);
											$uibModalInstance.dismiss('cancel');
											/* var allObjectIDs = all_object_id.replace('_',',');//_.replace(all_object_id,'_',',');
											var args = {
													p_grid_refresh : allObjectIDs,
													//OBJECT_ID:rowData.OBJECT_ID,
													
													gridFilter : {
														//p_grid_refresh : rowData.OBJECT_ID,
														object_id:allObjectIDs,
														OBJECT_ID:allObjectIDs,

													}
												};
											$rootScope
											.$emit('gridUpdate', args); */
											if( vm.ObjectIDLIST && _.isArray(vm.ObjectIDLIST) && vm.ObjectIDLIST.length<=10){
												
												var arg2= (Array.prototype.map.call(vm.ObjectIDLIST, s=> s.OBJECT_ID).toString());
												  var args = {
														OBJECT_ID : arg2,
														gridFilter : vm.ObjectIDLIST
												  }
												  $rootScope.$emit('gridUpdate', args);
											}else{
												$rootScope.$broadcast('dataFilters:refreshGrid', {
													  "refresh": true
												  });
											}
			

										}
									});
				}
				vm.save = function() {
					var oldtemplate_key = "";
					var newtemplate_key = "";
					var new_tbbs_acct_key = "";
					var new_schdm_key = "";
					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}

					$scope.crudLoading = true;
					if (vm.templateError || vm.userDescError || vm.cyamtError
							|| vm.typeError || vm.tbbsError
							|| vm.schdmReviewedError
							|| vm.postingtaxaccountcodeError) {
						AlertService.add("danger",
								"Please correct the errors below", 4000);
						$scope.crudLoading = false;
						throw new ("This is not an error. This is just to abort javascript!");
					}
					vm.sendobj = [];
					// vm.crudLoading = true;

					var dummyJson = {};
					// sendobj = [];

					for ( var k in vm.mainmodaldata) {
						if (vm.template == vm.mainmodaldata[k].SCHDM_TEMPLATE) {
							newtemplate_key = vm.mainmodaldata[k].SCHDM_TEMPLATE_ID;
						}
					}

					for ( var m in vm.schdmtypedata) {
						if (vm.schdmt == vm.schdmtypedata[m].SCHDM_TYPE) {
							new_schdm_key = vm.schdmtypedata[m].SCHDM_TYPE_SAVE;
						}

					}

					for ( var n in vm.tbbsacctdata) {
						if (vm.tbbsacctval == vm.tbbsacctdata[n].TAX_STREAM_ACCT) {
							new_tbbs_acct_key = vm.tbbsacctdata[n].TAX_STREAM_ACCT_SAVE
						}
					}
					dummyJson.TAX_YEAR = GlobalService.globalParams.tax_year;
					dummyJson.SCENARIO = GlobalService.globalParams.scenario;
					dummyJson.JCD_KEY = GlobalService.globalParams.jcd_key;
					dummyJson.TRIGGER_CHART_KEY = rowData.TRIGGER_CHART_KEY;
					dummyJson.TRIGGER_ACCT_KEY = rowData.TRIGGER_ACCT_KEY;
					dummyJson.TEMPLATE_ID = (newtemplate_key != undefined) ? newtemplate_key
							: rowData.SCHDM_TEMPLATE_ID;
					dummyJson.SCHDM_TYPE = (new_schdm_key != undefined) ? new_schdm_key
							: rowData.SCHDM_TYPE;
					dummyJson.TBBS_ACCT_KEY = (new_tbbs_acct_key != undefined) ? new_tbbs_acct_key
							: rowData.TBBS_ACCT_KEY;
					dummyJson.MULTIPLIER = vm.multiplier;
					dummyJson.USER_DESC = vm.userm1;
					dummyJson.POSTING_TAC_KEY = rowData.POSTING_TAC_KEY;
					dummyJson.POSTING_ACCT_KEY = rowData.POSTING_ACCT_KEY;
					dummyJson.METHOD_OF_CALC = rowData.METHOD_OF_CALC;
					dummyJson.PROVISION_YN = rowData.PROVISION_YN;

					vm.sendobj.push(dummyJson);

					RecommendedAutomationsFactory
							.saverecommAto(vm.sendobj, vm.sendobj)
							.then(
									function(result) {

										if (result.callSuccess !== "1") {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoading = false;

										} else {
											AlertService
													.add(
															"success",
															"Data has been saved successfully ",
															4000);
											$uibModalInstance.dismiss('cancel');
											var args = {
													p_grid_refresh : rowData.OBJECT_ID,
													//OBJECT_ID:rowData.OBJECT_ID,
													
													gridFilter : {
														//p_grid_refresh : rowData.OBJECT_ID,
														object_id:rowData.OBJECT_ID,
														OBJECT_ID:rowData.OBJECT_ID,

													}
												};


											$rootScope
													.$emit('gridUpdate', args);

										}
									});
				}

				init();

				vm.reset = function() {
					init();
				}

			}
			return controllers;

		});
