define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.f8865SchM2K1SourcingController', [])
            .controller('f8865SchM2K1SourcingController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',f8865SchM2K1SourcingController]
            );

        function f8865SchM2K1SourcingController($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.userSettings = USER_SETTINGS;

            vm.customBaseURL = GENERAL_CONFIG.custom_api_url;
            vm.gtwBaseURL = GENERAL_CONFIG.base_url;

            console.log('vm.customBaseURL = ', vm.customBaseURL , 'vm.gtwBaseURL = ', vm.gtwBaseURL);
            vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;
            vm.title = 'Tax Year : ' + filterParams.tax_year + ' - ' + 'Scenario : ' + vm.scenarioLabel

            vm.checkLockeOdbj =[];



            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = filterParams.scenario;

                    vm.isIssueKeyEnabled = GlobalService.inputs.tax_year.selected.is_issue_key_enabled;
                    if(vm.isIssueKeyEnabled === undefined) {
                        vm.isIssueKeyEnabled = GlobalService.globalParams.is_issue_key_enabled;
                    }

                    vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.CODE;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.ho_combination_key = rowData.COMBINATION_KEY;
                    vm.acct_type = rowData.ACCT_TYPE;
                    vm.checkLockeOdbj.push({"leid": rowData.LEID,"cdr_number":rowData.CDR_NO, "reporting_period": rowData.REPORTING_PERIOD, "combination_key":rowData.COMBINATION_KEY, "scenario_key":vm.scenario});

                    console.log(vm.tax_year , ' : ', vm.scenario, ' : ', vm.jcd_key, ' : ', vm.logged_in_user , ' : ', vm.screen_key , ' : ', vm.ho_combination_key , ' : ', vm.acct_type, ':' , vm.checkLockeOdbj);

                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);

                    const html = `<gtw-f8865-sch-m2-k1-sourcing id="open-sourcing-basket-list" base-url="{{ctrl.customBaseURL}}" base-url-gtw-api="{{ctrl.gtwBaseURL}}" tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" screen-key="{{ctrl.screen_key}}" sso-id="{{ctrl.logged_in_user}}" ho-combination-key="{{ctrl.ho_combination_key}}"  scenario-code="{{ctrl.scenarioCode}}"  is-issue-key-enabled="{{ctrl.isIssueKeyEnabled}}" acct-type="{{ctrl.acct_type}}" check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" class="gtw-web-components"></gtw-f8865-sch-m2-k1-sourcing>`;
                    vm.webComponentHtml = html;

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {
                        //document.querySelector('div[uib-modal-backdrop="modal-backdrop"]').setAttribute("style", "z-index: 800;");
                        //document.querySelector('.modal-window-lock-class').setAttribute("style", "z-index: 810; display: block;");

                        $window.document.querySelector('gtw-f8865-sch-m2-k1-sourcing')
                           .addEventListener('open-sourcing-basket-list', (event)=>{
                               console.log('event',event);
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {
                        //document.querySelector('div[uib-modal-backdrop="modal-backdrop"]').setAttribute("style", "z-index: 800;");
                        //document.querySelector('.modal-window-lock-class').setAttribute("style", "z-index: 810; display: block;");

                        $window.document.querySelector('gtw-f8865-sch-m2-k1-sourcing')
                            .addEventListener('save-partnership-sourcing', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-partnership-sourcing'){
                                    // $rootScope.$emit('gridUpdate', {});
                                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added ...');
                    },3000
                );

                $scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
);
