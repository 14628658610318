define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartII',[])
        .factory("K3PartIIFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K3PartIIFactory])
    function K3PartIIFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K3PartIIFactory.loadCountryList = function(taxYear, sso_id) {
            let countryCodeList;
            let  params = {
                "action_code" : '7mswb0', "tax_year" : taxYear, "sso_id": sso_id
            };

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(response) {
                    countryCodeList = response.jsonObject;
                    console.log('countryCodeList = ', countryCodeList);
                    return countryCodeList;
                }
            );

            return promise;

        }

        K3PartIIFactory.loadSIC_Codes = function(taxYear) {
            let SIC_codes;
            let params = {
                "action_code" : 'yzpcur', "tax_year" : taxYear
            };

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(response) {
                    SIC_codes = response.jsonObject;
                    console.log('SIC_codes = ', SIC_codes);
                    return SIC_codes;
                }
            );

            return promise;
        }

        K3PartIIFactory.getDefaultCategory = function(ctrl,taxYear) {

            let defaultCategoryParams = {"action_code" : 'gmxuns', "tax_year" : taxYear};

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', defaultCategoryParams).then(
                function (response) {
                    if(response.jsonObject.length) {
                        ctrl.category_key =  response.jsonObject[0].CODE_KEY;
                        ctrl.category_code = response.jsonObject[0].CODE_SHORT_DESC;
                    }
                    
                    return ctrl;

                }
            );

            return promise;
        }

        K3PartIIFactory.getDefaultCountryCode = function(taxYear) {

            let defaultCountryCodeParams = {"action_code" : '7rjgms', "tax_year" : taxYear};

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', defaultCountryCodeParams).then(
                function (response) {
                    let default_country_code = response.jsonObject[0].CODE_SHORT_DESC;
                    return default_country_code;
                }
            );

            return promise;
        }

        K3PartIIFactory.renderSK3PartIIDetails = function(ctrl) {

            let line;
            let lineData;
            let dataRows;
            let dataRow;
            let lineDataOcc;
            let countryCol;

            ctrl.part_II_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'II', SECTION_NAME:'N/A', ATTRIB_NAME: '1065_K3_II_N/A'});

            ctrl.part_II_s1_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'II', SECTION_NAME:'1', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_II_1'});
            ctrl.part_II_S1_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'II', SECTION_NAME:1, ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_II_1'});
            ctrl.part_II_S1_1065SK3DataTransposed = transpose_part_II_Section(ctrl.part_II_S1_1065SK3Data);

            ctrl.part_II_s2_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'II', SECTION_NAME:'2', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_II_2'});
            ctrl.part_II_S2_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'II', SECTION_NAME:2, ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_II_2'});
            ctrl.part_II_S2_1065SK3DataTransposed = transpose_part_II_Section(ctrl.part_II_S2_1065SK3Data);


            for(let lineIndex = 0; lineIndex < ctrl.part_II_S2_1065SK3DataTransposed.length; lineIndex++) {
                line = ctrl.part_II_S2_1065SK3DataTransposed[lineIndex];

                lineData = line.Line_data;
                dataRows = $filter("filter")(lineData,{Line_Type:'DATA_ROW'});

                for(let dataRowIndex = 0; dataRowIndex < dataRows.length; dataRowIndex++) {
                    dataRow = dataRows[dataRowIndex];
                    if(dataRow.Is_Multi === 'Y' && dataRow.Line_no != '32') {
                        lineDataOcc = dataRow.Line_data_occ;
                        countryCol = lineDataOcc[0];
                        if(countryCol.ATTRIBUTE_VALUE === undefined) {
                            countryCol['ATTRIBUTE_VALUE'] = ctrl.defaultCountryCode; // "OC"
                        }
                    }
                }

            }

            reEvaluateFormulaForTotalLinesP2(ctrl);
            return ctrl;

        }

        K3PartIIFactory.calcAmtSK3P2 = function(ctrl,formulaObj, cell_name, cell_value, row, col) {

            console.log('in calcAmtSK3');
            console.log('cell_name = ', cell_name);
            console.log('cell_value = ', cell_value);

            let lineAmt = 0;
            let lineFormulaArray;
            let formulaOperandText;
            let targetCellObj;
            let formula;
            let matches;
            let line;
            let lineDataList = [];
            let lineData;
            let cellList = [];
            let cell;
            let occAmt = 0;
            let originalValue;

            // find the related formula for the cell whose value is changed
            let formulaObjs = $filter("filter")(ctrl.allFormFormulaListSK3, {ATTRIB_CALC_FORMULA:'('+cell_name+')'});

            for(let k = 0; k < formulaObjs.length; k++) {
                lineAmt = 0;
                formula = formulaObjs[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }

                for(let i = 0; i<lineFormulaArray.length; i++) {
                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();
                    console.log('formulaOperandText = ', formulaOperandText);

                    if(k == 0) {
                        lineAmt = parseFloat($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText})[0].ATTRIBUTE_VALUE);
                    }
                    else if (k == 1) {
                        if(col.SECTION_NAME == '1') {
                            for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                                occAmt = 0;
                                line = ctrl.part_II_S1_1065SK3DataTransposed[x];
                                lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                                for(let y = 0; y < lineDataList.length; y++) {
                                    lineData = lineDataList[y];
                                    //console.log('Line No :', lineData.Line_no , ' Row Occurrence : ', lineData.Row_Occurrence);
                                    cellList = lineData.Line_data_occ;
                                    for(let z = 0; z < cellList.length; z++) {
                                        if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                            occAmt = occAmt + parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                            lineAmt = occAmt;
                                        }
                                    }
                                }
                            }
                        }
                        else if(col.SECTION_NAME == '2') {
                            for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                                occAmt = 0;
                                line = ctrl.part_II_S2_1065SK3DataTransposed[x];
                                lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                                for(let y = 0; y < lineDataList.length; y++) {
                                    lineData = lineDataList[y];
                                    //console.log('Line No :', lineData.Line_no , ' Row Occurrence : ', lineData.Row_Occurrence);
                                    cellList = lineData.Line_data_occ;
                                    for(let z = 0; z < cellList.length; z++) {
                                        if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                            occAmt = occAmt + parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                            lineAmt = occAmt;
                                        }
                                    }
                                }
                            }
                        }

                    }

                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");

                    //console.log('In-step Formula (value replaced) : ' , formula);
                }
                //console.log('Final Formula (value replaced) : ' , formula);

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);
                    //console.log('formula_cell_name = ', formulaObjs[k].ATTRIB_NAME , ' , formula_evaluated_value = ', xyz);
                    if(k == 0) {
                        targetCellObj = $filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaObjs[k].ATTRIB_NAME})[0];

                        originalValue = targetCellObj.ATTRIBUTE_VALUE;
                        if(originalValue != xyz) {
                            ctrl.setChange(targetCellObj,row);
                        }
                        targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();

                    }
                    else if(k == 1) {
                        if(col.SECTION_NAME == '1') {
                            for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                                line = ctrl.part_II_S1_1065SK3DataTransposed[x];
                                lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                                for(let y = 0; y < lineDataList.length; y++) {
                                    lineData = lineDataList[y];
                                    if(lineData.Line_no == formulaObjs[k].LINE_NO) {
                                        targetCellObj =  $filter("filter")(lineData.Line_data_occ,{ATTRIB_NAME:formulaObjs[k].ATTRIB_NAME})[0];
                                        originalValue = targetCellObj.ATTRIBUTE_VALUE;
                                        if(lineData.Line_no != '24h') {
                                            if(originalValue != xyz) {
                                                ctrl.setChange(targetCellObj,lineData);
                                            }
                                        }
                                        targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();
                                    }
                                }
                            }
                        }
                        else if(col.SECTION_NAME == '2') {
                            for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                                line = ctrl.part_II_S2_1065SK3DataTransposed[x];
                                lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                                for(let y = 0; y < lineDataList.length; y++) {
                                    lineData = lineDataList[y];
                                    if(lineData.Line_no == formulaObjs[k].LINE_NO) {
                                        targetCellObj =  $filter("filter")(lineData.Line_data_occ,{ATTRIB_NAME:formulaObjs[k].ATTRIB_NAME})[0];
                                        originalValue = targetCellObj.ATTRIBUTE_VALUE;
                                        if(originalValue != xyz) {
                                            ctrl.setChange(targetCellObj,lineData);
                                        }
                                        targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();
                                    }
                                }
                            }
                        }
                    }
                }
            }


            reEvaluateFormulaForTotalLinesP2(ctrl);

            return ctrl;

        }

        K3PartIIFactory.addRowOccurrenceP2 = function(ctrl, line_no, transposedSectionData){

            let row_occurrence;

            for(let i = 0; i < transposedSectionData.length; i++) {
                if(transposedSectionData[i].ROW_TYPE == 'CH') {
                    row_occurrence = transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length -1].Row_Occurrence + 1;
                }

                let new_row = angular.copy(transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length - 1]);
                new_row.Line_Type = "DATA_ROW";
                new_row.Row_Occurrence = row_occurrence;
                new_row.IS_EDITTED = "Y";

                if(new_row.Line_no == line_no){

                    for (let j = 0; j < new_row.Line_data_occ.length; j++){
                        if ( (new_row.Line_data_occ[j].EDIT_TYPE === "currency" || new_row.Line_data_occ[j].EDIT_TYPE === "label") && new_row.Line_data_occ[j].DATA_TYPE === "number"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = 0;
                        } else if (new_row.Line_data_occ[j].EDIT_TYPE === "text" && new_row.Line_data_occ[j].DATA_TYPE === "string"){

                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = null;

                            if(new_row.Line_data_occ[j].SECTION_NAME === '2' && new_row.Line_data_occ[j].ATTRIB_COL === '0' && new_row.Line_data_occ[j].LINE_NO !== '32') {
                                new_row.Line_data_occ[j].ATTRIBUTE_VALUE= ctrl.defaultCountryCode; //"OC"
                            }

                        }
                        new_row.Line_data_occ[j].ROW_OCCURRENCE = row_occurrence;
                        new_row.Line_data_occ[j].COL_OCCURRENCE = 1;
                        //new_row.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
                    }
                    transposedSectionData[i].Line_data.push(new_row);
                }

            }


            if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '1') {
                ctrl.part_II_S1_1065SK3DataTransposed =  transposedSectionData;
            }
            else if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '2') {
                ctrl.part_II_S2_1065SK3DataTransposed =  transposedSectionData;
            }

            console.log('transposedSectionData = ' , transposedSectionData);

            return ctrl;
        }

        K3PartIIFactory.removeRowOccurrenceP2 = function(ctrl, line_no, Occurrence, index, transposedSectionData) {

            console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);

            for (var i=0; i < transposedSectionData.length; i++){
                if(transposedSectionData[i].ROW_TYPE == 'CH'){
                    for(let j=0; j < transposedSectionData[i].Line_data.length; j++){
                        if(j == index){
                            if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && null != transposedSectionData[i].Line_data[j].Row_Occurrence && transposedSectionData[i].Line_data[j].Row_Occurrence != "" && transposedSectionData[i].Line_data[j].Row_Occurrence == Occurrence){

                                if(transposedSectionData[i].Line_data[j].IS_EDITTED == 'N') {
                                    transposedSectionData[i].DELETE_ROWS.push(transposedSectionData[i].Line_data[j]);
                                }
                                transposedSectionData[i].Line_data.splice(j,1);
                            }else if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
                                transposedSectionData[i].Line_data.splice(j,1);
                            }
                        }
                    }
                }
            }

            if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '1') {
                ctrl.part_II_S1_1065SK3DataTransposed =  transposedSectionData;
            }
            else if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '2') {
                ctrl.part_II_S2_1065SK3DataTransposed =  transposedSectionData;
            }

            if(line_no == '1' || line_no == '2' || line_no == '3' || line_no == '4' || line_no == '6' || line_no == '7' || line_no == '8' || line_no == '10' || line_no == '11' || line_no == '12' || line_no == '13' || line_no == '14' || line_no == '15' || line_no == '19' || line_no == '20' || line_no == '32')
                reEvaluateFormulaForTotalLinesP2(ctrl);

            return ctrl;
        }

        K3PartIIFactory.validateSK3DataPartII = function(ctrl, k3Data) {
            let cell;
            let countryMatched = false;
            let sicCodeMatched = false;
            let line_no;
            let lineObj;
            let lineData;
            let cellObj;
            let cellValue;
            let cellValues = [];
            let dupeMsg;
            let us;
            let f_fb;
            let f_pas;
            let f_gen;
            let f_oth;

            if(k3Data != undefined) {

                for(let i = 0; i < k3Data.length; i++) {
                    cell = k3Data[i];

                    line_no = cell.LINE_NO;

                    if(cell.IS_CHANGED_FLAG == 'Y') {

                        if(line_no == '1' || line_no == '2' || line_no == '3' || line_no == '4' || line_no == '6' || line_no == '7' || line_no == '8' || line_no == '10' || line_no == '11' ||
                            line_no == '12' || line_no == '13' || line_no == '14' || line_no == '15' || line_no == '19' || line_no == '20'

                        ) {
                            if(ctrl.checkValueEnteredInSection(ctrl.part_II_S1_1065SK3DataTransposed)) {
                                if (cell.ATTRIB_COL == '0') {
                                    ctrl.ERROR_MESSAGE = null;
                                    if (cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                        ctrl.ERROR_MESSAGE = 'Country Code Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: ' + cell.SECTION_NAME + ' Line: ' + line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    } else {
                                        countryMatched = false;
                                        for (let j = 0; j < ctrl.countryCodeList.length; j++) {
                                            if (ctrl.countryCodeList[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                countryMatched = true;
                                                break;
                                            }
                                        }
                                        if (countryMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid Country Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: ' + cell.SECTION_NAME + ' Line: ' + line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }
                                }
                            }
                        }
                        else if ( line_no == '25' || line_no == '26' || line_no == '27' || line_no == '28' || line_no == '29' || line_no == '30'
                        || line_no == '31' || line_no == '33' || line_no == '34' || line_no == '35' || line_no == '36' || line_no == '37'
                        || line_no == '39' || line_no == '40' || line_no == '44' || line_no == '45' || line_no == '46' || line_no == '47'
                        || line_no == '48' || line_no == '49' || line_no == '50') {
                            if(ctrl.checkValueEnteredInSection(ctrl.part_II_S2_1065SK3DataTransposed)) {
                                if (cell.ATTRIB_COL == '0' && cell.EDIT_TYPE !== 'label') {
                                    ctrl.ERROR_MESSAGE = null;
                                    if (cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                        ctrl.ERROR_MESSAGE = 'Country Code Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: ' + cell.SECTION_NAME + ' Line: ' + line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    } else {
                                        countryMatched = false;
                                        for (let j = 0; j < ctrl.countryCodeList.length; j++) {
                                            if (ctrl.countryCodeList[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                countryMatched = true;
                                                break;
                                            }
                                        }
                                        if (countryMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid Country Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: ' + cell.SECTION_NAME + ' Line: ' + line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                        if(countryMatched == true) {

                                            us    = $filter("filter")(k3Data, {LINE_NO:cell.LINE_NO, ROW_OCCURRENCE:cell.ROW_OCCURRENCE, ATTRIB_COL:'a'})[0].ATTRIBUTE_VALUE+'';
                                            f_fb  = $filter("filter")(k3Data, {LINE_NO:cell.LINE_NO, ROW_OCCURRENCE:cell.ROW_OCCURRENCE, ATTRIB_COL:'b'})[0].ATTRIBUTE_VALUE+'';
                                            f_pas = $filter("filter")(k3Data, {LINE_NO:cell.LINE_NO, ROW_OCCURRENCE:cell.ROW_OCCURRENCE, ATTRIB_COL:'c'})[0].ATTRIBUTE_VALUE+'';
                                            f_gen = $filter("filter")(k3Data, {LINE_NO:cell.LINE_NO, ROW_OCCURRENCE:cell.ROW_OCCURRENCE, ATTRIB_COL:'d'})[0].ATTRIBUTE_VALUE+'';
                                            f_oth = $filter("filter")(k3Data, {LINE_NO:cell.LINE_NO, ROW_OCCURRENCE:cell.ROW_OCCURRENCE, ATTRIB_COL:'e'})[0].ATTRIBUTE_VALUE+'';

                                            if(cell.ATTRIBUTE_VALUE === "US") {
                                                console.log('Inside cell.ATTRIBUTE_VALUE === US');
                                                if(f_fb !== "0" || f_pas !== "0" || f_gen !== "0" || f_oth !== "0") {
                                                    ctrl.ERROR_MESSAGE = 'If Country is US, Foreign Source amounts must be zero ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: ' + cell.SECTION_NAME + ' Line: ' + line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                                }
                                            }
                                            else if(cell.ATTRIBUTE_VALUE !== "US") {
                                                console.log('Inside cell.ATTRIBUTE_VALUE !== US');
                                                if(us !== "0") {
                                                    ctrl.ERROR_MESSAGE = 'If Country is Not US, US Amount must be zero ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: ' + cell.SECTION_NAME + ' Line: ' + line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                        else if (line_no == '32') {
                            if(ctrl.checkValueEnteredInSection(ctrl.part_II_S2_1065SK3DataTransposed)) {
                                if(cell.ATTRIB_COL == '0') {
                                    ctrl.ERROR_MESSAGE = null;

                                    if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                        ctrl.ERROR_MESSAGE = 'SIC Code Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {
                                        sicCodeMatched = false;
                                        for(let k = 0; k < ctrl.SIC_codes.length; k++ ) {
                                            if(ctrl.SIC_codes[k].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                sicCodeMatched = true;
                                                break;
                                            }
                                        }
                                        if(sicCodeMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid SIC Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }

                                }
                            }
                        }

                        if(ctrl.ERROR_MESSAGE != null) {
                            ctrl.HAS_ERROR = true;
                            break;
                        }
                    }
                }
            }


            if(!ctrl.HAS_ERROR) {

                let part_II_S1_S2_TxData = [];
                if(ctrl.checkValueEnteredInSection(ctrl.part_II_S1_1065SK3DataTransposed)) {
                    for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                        part_II_S1_S2_TxData.push(ctrl.part_II_S1_1065SK3DataTransposed[x]);
                    }
                }
                if(ctrl.checkValueEnteredInSection(ctrl.part_II_S2_1065SK3DataTransposed)) {
                    for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                        part_II_S1_S2_TxData.push(ctrl.part_II_S2_1065SK3DataTransposed[x]);
                    }
                }

                for(let x = 0; x < part_II_S1_S2_TxData.length; x++) {

                    cellValues = [];
                    lineObj = part_II_S1_S2_TxData[x];
                    lineData = $filter("filter")(lineObj.Line_data, {Line_Type:'DATA_ROW'});

                    if(lineData.length > 1) {

                        for(let y = 0; y < lineData.length; y++) {
                            cellObj = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'0'})[0];
                            cellValue = cellObj.ATTRIBUTE_VALUE;
                            cellValues.push(cellValue);
                        }
                        let duplicates = cellValues.reduce(function(acc, el, i, arr) {
                            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
                        }, []);
                        console.log('duplicates = ' , duplicates);
                        if(duplicates.length > 0) {
                            if(lineData[0].Line_no == '32') {
                                dupeMsg = 'Duplicate SIC Code(s) ~ ';
                            }
                            else {
                                dupeMsg = 'Duplicate Country Code(s) ~ ';
                            }
                            ctrl.ERROR_MESSAGE = dupeMsg + duplicates[0] + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' +  lineData[0].Line_no ;
                            ctrl.HAS_ERROR = true;
                            break;
                        }
                    }
                }

            }


            if(!ctrl.HAS_ERROR) {
                if(ctrl.checkValueEnteredInSection(ctrl.part_II_S1_1065SK3DataTransposed)) {
                    ctrl.HAS_ERROR = validatePartIIS1Line24(ctrl,ctrl.part_II_S1_1065SK3DataTransposed);
                }
            }

            return ctrl;
        }

        K3PartIIFactory.obtainChanges = function(ctrl) {

            let a;
            let b;
            let c;
            let cells = [];
            let p;

            ctrl.partIIS1Changes = [];
            ctrl.partIIS2Changes = [];

            console.log('ctrl.saveData = ' , ctrl.saveData);

            if(ctrl.saveData.length > 0) {

                let part2Data = _.filter(ctrl.saveData, {PART_NO: "II"});
                if(part2Data !== undefined && part2Data.length > 0) {
                    ctrl.saveData = ctrl.saveData.filter(element => element.PART_NO === "II");
                }

            }
            if(ctrl.checkValueEnteredInSection(ctrl.part_II_S1_1065SK3DataTransposed)) {
                for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_II_S1_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIIS1Changes.push(c);
                        }
                    }
                }
            }
            if(ctrl.checkValueEnteredInSection(ctrl.part_II_S2_1065SK3DataTransposed)) {
                for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_II_S2_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIIS2Changes.push(c);
                        }
                    }
                }
            }


            if(ctrl.checkValueEnteredInSection(ctrl.part_II_S1_1065SK3DataTransposed)) {
                for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_II_S1_1065SK3DataTransposed[x];
                    if(p.DELETE_ROWS.length > 0) {
                        for(let d = 0; d < p.DELETE_ROWS.length; d++) {
                            let lineData = p.DELETE_ROWS[d];
                            for(let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                            }
                            ctrl.partIIS1Changes.push(lineData.Line_data_occ);
                        }
                    }
                }
            }

            if(ctrl.checkValueEnteredInSection(ctrl.part_II_S2_1065SK3DataTransposed)) {

                for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_II_S2_1065SK3DataTransposed[x];
                    if(p.DELETE_ROWS.length > 0) {
                        for(let d = 0; d < p.DELETE_ROWS.length; d++) {
                            let lineData = p.DELETE_ROWS[d];
                            for(let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                            }
                            ctrl.partIIS2Changes.push(lineData.Line_data_occ);
                        }
                    }
                }
            }

            for(let row = 0; row < ctrl.partIIS1Changes.length; row++) {
                cells = ctrl.partIIS1Changes[row]
                for(let c = 0; c < cells.length; c++) {
                    ctrl.saveData.push(cells[c]);
                }
            }
            for(let row = 0; row < ctrl.partIIS2Changes.length; row++) {
                cells = ctrl.partIIS2Changes[row];
                for(let c = 0; c < cells.length; c++) {
                    ctrl.saveData.push(cells[c]);
                }
            }

            return ctrl;
        }



        function transpose_part_II_Section(sectionData) {

            let transposedSectionData = [];

            let o = sectionData.reduce((a, b) => {
                a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
                a[b.LINE_ORDER].push(b);
                return a;
            }, {});
            let a = Object.keys(o).map(function (k) {
                return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
            });
            transposedSectionData = a;
            let c = [];
            for (let i = 0; i < transposedSectionData.length; i++) {
                let z = transposedSectionData[i].Line_data.reduce( (a,b) => {
                    a[b.ROW_OCCURRENCE] = a[b.ROW_OCCURRENCE] || [];
                    a[b.ROW_OCCURRENCE].push(b);
                    return a;
                }, {});

                let y = Object.keys(z).map(function (k) {
                    return { Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED: "N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Row_Occurrence: /*row_occ*/ z[k][0].ROW_OCCURRENCE, Line_data_occ: z[k]};
                });

                if (i == 0) {
                    let m = angular.copy(y);
                    c = angular.copy(y[0]);
                    c.Line_Type = "HEADER_ROW";
                    c.Row_Occurrence = "";
                    c.Trans_details_key = "";
                    c.IS_EDITABLE = "Y";
                    for (var j=0; j < c.Line_data_occ.length; j++){
                        c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
                    }
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
                    }
                    else {
                        y.unshift(c);
                    }

                }
                else {
                    let key = Object.keys(z)[0];
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift({Line_Type: "SUB_ROW", Line_no: z[key][0].LINE_NO, Line_data_occ: []});
                    }
                }
                transposedSectionData[i].Line_data = angular.copy(y);
            }
            return transposedSectionData;
        }

        function reEvaluateFormulaForTotalLinesP2(ctrl) {
            let lineAmt = 0;
            let lineFormulaArray;
            let formulaOperandText;
            let targetCellObj;
            let formula;
            let matches;
            let line;
            let lineDataList = [];
            let lineData;
            let cellList = [];
            let cell;
            let occAmt = 0;
            let originalValue;

            let formulaObjs24h = $filter("filter")(ctrl.allFormFormulaListSK3, {LINE_NO:'24h'});

            for(let k = 0; k < formulaObjs24h.length; k++) {
                lineAmt = 0;
                formula = formulaObjs24h[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                //console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }
                for(let i = 0; i<lineFormulaArray.length; i++) {

                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();
                    //console.log('formulaOperandText = ', formulaOperandText);
                    for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                        occAmt = 0;
                        line = ctrl.part_II_S1_1065SK3DataTransposed[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            //console.log('Line No :', lineData.Line_no , ' Row Occurrence : ', lineData.Row_Occurrence);
                            cellList = lineData.Line_data_occ;
                            for(let z = 0; z < cellList.length; z++) {
                                if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                    occAmt = occAmt + parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                    lineAmt = occAmt;
                                }
                            }
                        }
                    }


                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");

                    //console.log('In-step Formula (value replaced) : ' , formula);
                }

                //console.log('Final Formula (value replaced) : ' , formula);

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);
                    //console.log('formula_cell_name = ', formulaObjs24h[k].ATTRIB_NAME , ' , formula_evaluated_value = ', xyz);

                    for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                        line = ctrl.part_II_S1_1065SK3DataTransposed[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            if(lineData.Line_no == formulaObjs24h[k].LINE_NO) {
                                targetCellObj =  $filter("filter")(lineData.Line_data_occ,{ATTRIB_NAME:formulaObjs24h[k].ATTRIB_NAME})[0];
                                originalValue = targetCellObj.ATTRIBUTE_VALUE;
                                // As 24h line is not saved to db, no point to mark it as changed
                                /*if(originalValue != xyz) {
                                    ctrl.setChange(targetCellObj,lineData);
                                }*/
                                targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();
                            }
                        }
                    }
                }

            }

            lineAmt = 0;
            lineDataList = [];
            cellList = [];
            occAmt = 0;

            let formulaObjs54 = $filter("filter")(ctrl.allFormFormulaListSK3, {LINE_NO:'54'});

            for(let k = 0; k < formulaObjs54.length; k++) {
                lineAmt = 0;
                formula = formulaObjs54[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                //console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }
                for(let i = 0; i<lineFormulaArray.length; i++) {

                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();
                    //console.log('formulaOperandText = ', formulaOperandText);
                    for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                        occAmt = 0;
                        line = ctrl.part_II_S2_1065SK3DataTransposed[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            //console.log('Line No :', lineData.Line_no , ' Row Occurrence : ', lineData.Row_Occurrence);
                            cellList = lineData.Line_data_occ;
                            for(let z = 0; z < cellList.length; z++) {
                                if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                    occAmt = occAmt + parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                    lineAmt = occAmt;
                                }
                            }
                        }
                    }


                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");

                    //console.log('In-step Formula (value replaced) : ' , formula);
                }

                //console.log('Final Formula (value replaced) : ' , formula);

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);
                    //console.log('formula_cell_name = ', formulaObjs54[k].ATTRIB_NAME , ' , formula_evaluated_value = ', xyz);

                    for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                        line = ctrl.part_II_S2_1065SK3DataTransposed[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            if(lineData.Line_no == formulaObjs54[k].LINE_NO) {
                                targetCellObj =  $filter("filter")(lineData.Line_data_occ,{ATTRIB_NAME:formulaObjs54[k].ATTRIB_NAME})[0];
                                originalValue = targetCellObj.ATTRIBUTE_VALUE;
                                if(originalValue != xyz) {
                                    ctrl.setChange(targetCellObj,lineData);
                                }
                                targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();
                            }
                        }
                    }
                }

            }

            lineAmt = 0;
            lineDataList = [];
            cellList = [];
            occAmt = 0;

            for(let x = 0; x < ctrl.part_II_S1_1065SK3DataTransposed.length; x++) {
                lineData = $filter("filter")(ctrl.part_II_S1_1065SK3DataTransposed[x].Line_data, {Line_Type: 'DATA_ROW', Is_Multi: 'N', Line_no : '24h'});
                if(lineData.length > 0) {
                    ctrl.part_II_s1_24h_n_s2_54_1065SK3DataTransposed.push(ctrl.part_II_S1_1065SK3DataTransposed[x]);
                    break;
                }
            }
            for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                lineData = $filter("filter")(ctrl.part_II_S2_1065SK3DataTransposed[x].Line_data, {Line_Type: 'DATA_ROW', Is_Multi: 'N', Line_no : '54'});
                if(lineData.length > 0) {
                    ctrl.part_II_s1_24h_n_s2_54_1065SK3DataTransposed.push(ctrl.part_II_S2_1065SK3DataTransposed[x]);
                    break;
                }
            }
            for(let x = 0; x < ctrl.part_II_S2_1065SK3DataTransposed.length; x++) {
                lineData = $filter("filter")(ctrl.part_II_S2_1065SK3DataTransposed[x].Line_data, {Line_Type: 'DATA_ROW', Is_Multi: 'N', Line_no : '55'});
                if(lineData.length > 0) {
                    ctrl.part_II_s2_55_1065SK3DataTransposed =  ctrl.part_II_S2_1065SK3DataTransposed[x];
                    break;
                }
            }

            let formulaObjs55 = $filter("filter")(ctrl.allFormFormulaListSK3, {LINE_NO:'55'});

            for(let k = 0; k < formulaObjs55.length; k++) {
                lineAmt = 0;
                formula = formulaObjs55[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                //console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }
                for(let i = 0; i<lineFormulaArray.length; i++) {

                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();
                    //console.log('formulaOperandText = ', formulaOperandText);
                    for(let x = 0; x < ctrl.part_II_s1_24h_n_s2_54_1065SK3DataTransposed.length; x++) {
                        occAmt = 0;
                        line = ctrl.part_II_s1_24h_n_s2_54_1065SK3DataTransposed[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            //console.log('Line No :', lineData.Line_no , ' Row Occurrence : ', lineData.Row_Occurrence);
                            cellList = lineData.Line_data_occ;
                            for(let z = 0; z < cellList.length; z++) {
                                if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                    occAmt = occAmt + parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                    lineAmt = occAmt;
                                }
                            }
                        }
                    }


                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");

                    //console.log('In-step Formula (value replaced) : ' , formula);
                }

                //console.log('Final Formula (value replaced) : ' , formula);

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);
                    //console.log('formula_cell_name = ', formulaObjs55[k].ATTRIB_NAME , ' , formula_evaluated_value = ', xyz);

                    //for(let x = 0; x < ctrl.part_II_s2_55_1065SK3DataTransposed.length; x++) {
                    line = ctrl.part_II_s2_55_1065SK3DataTransposed;
                    lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                    for(let y = 0; y < lineDataList.length; y++) {
                        lineData = lineDataList[y];
                        if(lineData.Line_no == formulaObjs55[k].LINE_NO) {
                            targetCellObj =  $filter("filter")(lineData.Line_data_occ,{ATTRIB_NAME:formulaObjs55[k].ATTRIB_NAME})[0];
                            originalValue = targetCellObj.ATTRIBUTE_VALUE;
                            if(originalValue != xyz) {
                                ctrl.setChange(targetCellObj,lineData);
                            }
                            targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();
                        }
                    }
                    //}
                }
            }
        }

        function validatePartIIS1Line24(ctrl,partIIS1DataTsp) {
            let countryCodeListAll = [];
            let countryCodeList24 = [];
            let lineData = [];
            let line_no;
            let cellObjs;
            let cellObj;
            let countryObj;
            let countryCode;
            let hasError = false;
            let headerName;
            let countryCodes = [];
            let amtObj = {};
            let allCountryWiseAmount = new Map();
            let ln24CountryWiseAmount = new Map();

            for(let x = 0; x < partIIS1DataTsp.length; x++) {
                lineData = $filter("filter")(partIIS1DataTsp[x].Line_data,{Line_Type:'DATA_ROW', Is_Multi:'Y'});
                if(lineData.length > 0) {
                    line_no = lineData[0].Line_no;
                    if(line_no != '24') {
                        for(let y = 0; y < lineData.length; y ++) {
                            cellObjs = lineData[y].Line_data_occ;
                            countryObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'0'})[0];
                            countryCode = countryObj.ATTRIBUTE_VALUE;
                            if(countryCode !== undefined) {
                                if(!countryCodeListAll.includes(countryCode))
                                    countryCodeListAll.push(countryCode);
                            }

                        }
                    }
                }
            }

            if(countryCodeListAll.length > 1) {
                countryCodeListAll.sort();
            }

            console.log('countryCodeListAll = ', countryCodeListAll);
            for(let x = 0; x < partIIS1DataTsp.length; x++) {
                lineData = $filter("filter")(partIIS1DataTsp[x].Line_data,{Line_Type:'DATA_ROW', Is_Multi:'Y'});
                if(lineData.length > 0) {
                    line_no = lineData[0].Line_no;
                    if(line_no == '24') {
                        for(let y = 0; y < lineData.length; y ++) {
                            cellObjs = lineData[y].Line_data_occ;
                            countryObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'0'})[0];
                            countryCode = countryObj.ATTRIBUTE_VALUE;
                            if(countryCode !== undefined) {
                                countryCodeList24.push(countryCode);
                            }
                        }
                    }
                }
            }
            if(countryCodeList24.length > 1) {
                countryCodeList24.sort();
            }
            console.log('countryCodeList24 = ', countryCodeList24);

            if(countryCodeListAll.length != countryCodeList24.length ) {
                ctrl.ERROR_MESSAGE = 'Number of Country Codes for line (1 - 23) do not match Number of Country Codes on Line 24';
                hasError = true;
            }
            else if(countryCodeListAll.length === countryCodeList24.length) {
                for(let x = 0; x < countryCodeListAll.length; x++) {
                    if(countryCodeListAll[x] != countryCodeList24[x]) {
                        ctrl.ERROR_MESSAGE = 'All Country Codes for line (1 - 23) do not match Country Codes on Line 24';
                        hasError = true;
                        break;
                    }
                }
            }

            if(!hasError) {

                for(let x = 0; x <  partIIS1DataTsp.length; x++) {
                    lineData = $filter("filter")(partIIS1DataTsp[x].Line_data,{Line_Type:'DATA_ROW', Is_Multi:'Y'});
                    if(lineData.length > 0) {
                        line_no = lineData[0].Line_no;
                        if(line_no != '24') {
                            for(let y = 0; y < lineData.length; y ++) {
                                cellObjs = lineData[y].Line_data_occ;
                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'0'})[0];
                                countryCode = cellObj.ATTRIBUTE_VALUE;

                                if(countryCode != undefined) {

                                    if(countryCodes.includes(countryCode)) {

                                        amtObj = allCountryWiseAmount.get(countryCode);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'a'})[0];
                                        amtObj['a'] = parseInt(amtObj['a']) + parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'b'})[0];
                                        amtObj['b'] = parseInt(amtObj['b']) + parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'c'})[0];
                                        amtObj['c'] = parseInt(amtObj['c']) + parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'d'})[0];
                                        amtObj['d'] = parseInt(amtObj['d']) + parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'e'})[0];
                                        amtObj['e'] = parseInt(amtObj['e']) + parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'f'})[0];
                                        amtObj['f'] = parseInt(amtObj['f']) + parseInt(cellObj.ATTRIBUTE_VALUE);

                                        allCountryWiseAmount.set(countryCode, angular.copy(amtObj));
                                    }
                                    else {
                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'a'})[0];
                                        amtObj['a'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'b'})[0];
                                        amtObj['b'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'c'})[0];
                                        amtObj['c'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'d'})[0];
                                        amtObj['d'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'e'})[0];
                                        amtObj['e'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                        cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'f'})[0];
                                        amtObj['f'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                        allCountryWiseAmount.set(countryCode, angular.copy(amtObj));

                                        if(!countryCodes.includes(countryCode)) {
                                            countryCodes.push(countryCode);
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

                for(let x = 0; x <  partIIS1DataTsp.length; x++) {

                    lineData = $filter("filter")(partIIS1DataTsp[x].Line_data, {Line_Type: 'DATA_ROW', Is_Multi: 'Y'});

                    if (lineData.length > 0) {
                        line_no = lineData[0].Line_no;
                        if(line_no === '24') {
                            for(let y = 0; y < lineData.length; y ++) {
                                cellObjs = lineData[y].Line_data_occ;

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'0'})[0];
                                countryCode = cellObj.ATTRIBUTE_VALUE;

                                amtObj = {};
                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'a'})[0];
                                amtObj['a'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'b'})[0];
                                amtObj['b'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'c'})[0];
                                amtObj['c'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'d'})[0];
                                amtObj['d'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'e'})[0];
                                amtObj['e'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'f'})[0];
                                amtObj['f'] = parseInt(cellObj.ATTRIBUTE_VALUE);

                                ln24CountryWiseAmount.set(countryCode, angular.copy(amtObj));
                            }
                        }
                    }
                }

                for(let c = 0; c < countryCodeListAll.length; c++) {
                    let countryCode = countryCodeListAll[c];
                    let amtObjAllByCountry = allCountryWiseAmount.get(countryCode);
                    let amtObjln24ByCountry = ln24CountryWiseAmount.get(countryCode);

                    if(amtObjAllByCountry.a != amtObjln24ByCountry.a) {
                        headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'a'})[0].ATTRIB_DESCRIPTION;
                        ctrl.ERROR_MESSAGE = 'Total of ' + headerName +  ' for country code: ' + countryCode +  ' in Part: ' + 'II' + ' Section: '+ '1' +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' for country code:' + countryCode + ' on Line 24';
                        hasError = true;
                        break;
                    }
                    else {
                        if(amtObjAllByCountry.b != amtObjln24ByCountry.b) {
                            headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'b'})[0].ATTRIB_DESCRIPTION;
                            ctrl.ERROR_MESSAGE = 'Total of ' + headerName +  ' for country code: ' + countryCode +  ' in Part: ' + 'II' + ' Section: '+ '1' +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' for country code:' + countryCode + ' on Line 24';
                            hasError = true;
                            break;
                        }
                        else {
                            if(amtObjAllByCountry.c != amtObjln24ByCountry.c) {
                                headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'c'})[0].ATTRIB_DESCRIPTION;
                                ctrl.ERROR_MESSAGE = 'Total of ' + headerName +  ' for country code: ' + countryCode +  ' in Part: ' + 'II' + ' Section: '+ '1' +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' for country code:' + countryCode + ' on Line 24';
                                hasError = true;
                                break;
                            }
                            else {
                                if(amtObjAllByCountry.d != amtObjln24ByCountry.d) {
                                    headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'d'})[0].ATTRIB_DESCRIPTION;
                                    ctrl.ERROR_MESSAGE = 'Total of ' + headerName +  ' for country code: ' + countryCode +  ' in Part: ' + 'II' + ' Section: '+ '1' +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' for country code:' + countryCode + ' on Line 24';
                                    hasError = true;
                                    break;
                                }
                                else {
                                    if(amtObjAllByCountry.e != amtObjln24ByCountry.e) {
                                        headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'e'})[0].ATTRIB_DESCRIPTION;
                                        ctrl.ERROR_MESSAGE = 'Total of ' + headerName +  ' for country code: ' + countryCode +  ' in Part: ' + 'II' + ' Section: '+ '1' +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' for country code:' + countryCode + ' on Line 24';
                                        hasError = true;
                                        break;
                                    }
                                    else {
                                        if(amtObjAllByCountry.f != amtObjln24ByCountry.f) {
                                            headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'f'})[0].ATTRIB_DESCRIPTION;
                                            ctrl.ERROR_MESSAGE = 'Total of ' + headerName +  ' for country code: ' + countryCode +  ' in Part: ' + 'II' + ' Section: '+ '1' +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' for country code:' + countryCode + ' on Line 24';
                                            hasError = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }


            if(!hasError) {

                amtObj = {'a':0, 'b':0, 'c':0, 'd':0,'e':0,'f':0};

                for(let x = 0; x <  partIIS1DataTsp.length; x++) {
                    lineData = $filter("filter")(partIIS1DataTsp[x].Line_data,{Line_Type:'DATA_ROW', Is_Multi:'N'});
                    if(lineData.length > 0) {
                        line_no = lineData[0].Line_no;
                        if(line_no != '24s' && line_no != '24h') {
                            for(let y = 0; y < lineData.length; y ++) {
                                cellObjs = lineData[y].Line_data_occ;
                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'a'})[0];
                                amtObj['a'] = amtObj['a'] + parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'b'})[0];
                                amtObj['b'] = amtObj['b'] + parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'c'})[0];
                                amtObj['c'] = amtObj['c'] + parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'d'})[0];
                                amtObj['d'] = amtObj['d'] + parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'e'})[0];
                                amtObj['e'] = amtObj['e'] + parseInt(cellObj.ATTRIBUTE_VALUE);

                                cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'f'})[0];
                                amtObj['f'] = amtObj['f'] + parseInt(cellObj.ATTRIBUTE_VALUE);
                            }
                        }
                    }
                }
                for(let x = 0; x <  partIIS1DataTsp.length; x++) {
                    lineData = $filter("filter")(partIIS1DataTsp[x].Line_data, {Line_Type: 'DATA_ROW', Is_Multi: 'N', Line_no : '24s'});
                    if (lineData.length > 0) {
                        line_no = lineData[0].Line_no;
                        for(let y = 0; y < lineData.length; y ++) {
                            cellObjs = lineData[y].Line_data_occ;
                            cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'a'})[0];
                            if(amtObj['a'] != parseInt(cellObj.ATTRIBUTE_VALUE)) {
                                headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'a'})[0].ATTRIB_DESCRIPTION;
                                ctrl.ERROR_MESSAGE = 'Total of country agnostic amounts of ' + headerName + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' on Line ' + line_no;
                                hasError = true;
                                break;
                            }
                            cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'b'})[0];
                            if(amtObj['b'] != parseInt(cellObj.ATTRIBUTE_VALUE)) {
                                headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'b'})[0].ATTRIB_DESCRIPTION;
                                ctrl.ERROR_MESSAGE = 'Total of country agnostic amounts of ' + headerName + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' on Line ' + line_no;
                                hasError = true;
                                break;
                            }
                            cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'c'})[0];
                            if(amtObj['c'] != parseInt(cellObj.ATTRIBUTE_VALUE)) {
                                headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'c'})[0].ATTRIB_DESCRIPTION;
                                ctrl.ERROR_MESSAGE = 'Total of country agnostic amounts of ' + headerName + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' on Line ' + line_no;
                                hasError = true;
                                break;
                            }
                            cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'d'})[0];
                            if(amtObj['d'] != parseInt(cellObj.ATTRIBUTE_VALUE)) {
                                headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'d'})[0].ATTRIB_DESCRIPTION;
                                ctrl.ERROR_MESSAGE = 'Total of country agnostic amounts of ' + headerName + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' on Line ' + line_no;
                                hasError = true;
                                break;
                            }
                            cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'e'})[0];
                            if(amtObj['e'] != parseInt(cellObj.ATTRIBUTE_VALUE)) {
                                headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'e'})[0].ATTRIB_DESCRIPTION;
                                ctrl.ERROR_MESSAGE = 'Total of country agnostic amounts of ' + headerName + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' on Line ' + line_no;
                                hasError = true;
                                break;
                            }
                            cellObj = $filter("filter")(lineData[y].Line_data_occ, {ATTRIB_COL:'f'})[0];
                            if(amtObj['f'] != parseInt(cellObj.ATTRIBUTE_VALUE)) {
                                headerName = $filter("filter")(partIIS1DataTsp[0].Line_data[0].Line_data_occ,{ATTRIB_COL:'f'})[0].ATTRIB_DESCRIPTION;
                                ctrl.ERROR_MESSAGE = 'Total of country agnostic amounts of ' + headerName + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' + ' (1 - 23) does not match Total of ' + headerName + ' on Line ' + line_no;
                                hasError = true;
                                break;
                            }
                        }
                        break;
                    }
                }

            }

            return hasError;
        }

        return K3PartIIFactory;
    }

    return services;
});
