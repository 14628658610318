
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.IC-DiagService',[])
    .factory('ICDiagFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        
    	var factory = {};
    	factory.formConfigData = {};
    	
    	factory.getEntityDetails=function(sso_id){
    		var params = { "action_code": 'xsivl3', "sso_id": sso_id};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
        };
        
        factory.getSourceDetails=function(sso_id){
    		var params = { "action_code": '9qlq82', "sso_id": sso_id};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
        };
        
        factory.getCPFormDetails=function(combination_key, form_name, schedule_name, sso_id){
    		var params = { "action_code": '1d50rl', "sso_id": sso_id, "combination_key": combination_key, "form_name": form_name, "schedule_name": schedule_name};
    		
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Service - getCPFormDetails after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	};
    	
    	factory.getICOutsideAcctDetails=function(combination_key, sso_id){
    		var params = { "action_code": 'zjm7kq', "sso_id": sso_id, "combination_key": combination_key, "form_no": 'IC_DIAG'};
    		
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Service - getCPFormDetails after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	};
    	
    	factory.getPayorDetails=function(sso_id, combination_key, form_no, schedule, line_no){
    		var params = { "action_code": 'u8rrpy', "sso_id": sso_id, "combination_key": combination_key, "form_name": form_no, "schedule_name": schedule, "classification": '', "line_no": line_no};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.getBSLADetails=function(sso_id, combination_key){
    		var params = { "action_code": '33qzsa', "sso_id": sso_id, "combination_key": combination_key};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.getIncomeCategoryDetails=function(){
    		var params = { "action_code": 'zg307b'};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.getCPEntityTBAmt=function(sso_id, combination_key, form_no, schedule, line_no, column){
    		var params = { "action_code": 'lxged7', "sso_id": sso_id, "combination_key": combination_key, "form_name": form_no, "schedule": schedule, "line_no": line_no, "column": column};
    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
    			console.log("Now in the Servie after action call the data was ................:::::", data);
    			if(null != data.callSuccess && data.callSuccess == "1"){
    				factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
    			}else{
    				AlertService.add("error", data.errorMessage);
                    return false;
    			}
    		});
    	}
    	
    	factory.saveICForm=function(final_data, clob_settings, form_no, schedule){
    		console.log("Clob_setting was done as:::::::::------------", clob_settings);
    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=8t0ocw";
    		var json_settings = JSON.stringify(clob_settings);
    		var json_data = JSON.stringify(final_data);
    		
    		console.log("json_settings:::::::::------------", json_settings);
    		console.log("json_data:::::::::------------", json_data);
    		
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		
    		var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({json_data:json_data}, params);
            params =  _.extend({json_settings:json_settings}, params);
            params.process_name =  "IC-BU-Allocation-Save";
            params.form_name = form_no;
            params.schd_name = schedule;
           
            console.log(params);
            
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
    	}
        
        return factory;
        
    }]);

    return services;

});