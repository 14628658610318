/**
 * Created by 212544474 on 8/16/2016.
 */
define([
    'angular'

], function () {
    'use strict';




    var controllers =  angular.module('app.actionCtrl',[])
    .controller('actionListCtrl', ['$scope','$rootScope','$state','$log','GridFactory','AlertService','$timeout','$uibModal', '$parse', 'ClientAdminFactory','GENERAL_CONFIG','USER_SETTINGS', actionListCtrl])
    .controller('actionCtrl', ['$scope','$rootScope','$state','$log','JsonObjectFactory','AlertService','$timeout','$uibModalInstance','rowData','$parse','JCDFactory','ClientAdminFactory', 'GENERAL_CONFIG','USER_SETTINGS', actionCtrl])

    function actionCtrl($scope,$rootScope,$state,$log,JsonObjectFactory,AlertService,$timeout,$uibModalInstance,rowData,$parse,JCDFactory,ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS) {

        var vm = this;
        vm.title = "Action";
        vm.loading = false;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        var client_key = user.client.client_key;
        vm.action_data = {};
        vm.cachingObjList = [];
        vm.selectedCachingObjects = [];
        vm.jcd_required = 0;
        vm.version_num = 0;
        vm.validationRulesList = {};
        vm.selectedDroolsRuleObj = {};
        vm.validation_rule = "";
        vm.action_data.drools_validation_id = 0;
        vm.action_data.datasource_id = vm.userSettings.user.client.client_datasource;
        vm.mode =  rowData && rowData.ACTION_KEY ? 'edit' : 'new';
        vm.jcd_required = (rowData && rowData.JCD_TA_KEY) ? 1:0;
        vm.action_data.jcd_ta_key = rowData && rowData.JCD_TA_KEY ? rowData.JCD_TA_KEY : null;
        vm.jcdlist = {};
        getCachingObjList();
        vm.roles = {selected:null, list:[], loading:false, disabled:true };
        vm.datasource = {selected:null, list:[], disabled:true };
        vm.client = {selected:null, list:[], disabled:true };
        vm.override={value:0,isShow:false};
        vm.permission = {selected:null, list:[{name: "User", value: 1},{name: "Editor", value: 2},{name: "Admin", value: 3},
                        {name: "Developer", value: 4},{name: "Super Admin", value: 5}], disabled:false};
        vm.cachedObj = false;
        vm.secondaryAction = false;
        vm.noActions = true;
        //vm.action_data.is_locked = "Y";
        vm.action_check = [
            {name:"admin",value:"admin"},
            {name:"trans",value:"trans"}]

        vm.methods = [
            {name:"Before Action", value:"pre"},
            {name:"After Action", value:"post"}
        ];

        vm.newActionObject = function(){
            vm.action_data.secondary_actions.push({
                action_key: "",
                enabled: 0,
                order: 0,
                pre_or_post: "post",
                threshold: 30
            });
        };

        vm.addActions = function(){
            if(vm.action_data.secondary_actions == undefined){
                vm.action_data.secondary_actions = [];
                vm.newActionObject();
            }else{
                vm.newActionObject();
            }
            vm.noActions = false;
        };

        vm.deleteAction = function (index) {
            vm.action_data.secondary_actions.splice(index, 1);
            if(vm.action_data.secondary_actions.length == 0){
                vm.noActions = true;
            }
        }

        if( vm.mode === 'new' ){
            JCDFactory.setObj(0,null);
            vm.action_data.action_fetch_size = 100;
            vm.action_data.use_cache = "N";
            vm.action_data.system_core = 0;
            setDataSource();
        }else{
            JCDFactory.setObj(0,vm.action_data.jcd_ta_key);
            setAction();
        }
        
       function getCachingObjList() {
            vm.loading = true;
            var params = {
                    "action_code": '12xqcg',
                    "client_key": client_key 
                } 
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                	 // vm.cachingObjList = data.jsonObject;
                	  
                	  for (var i = 0; i < data.jsonObject.length; i++) {
          					var displayCacheObj = {}
          					displayCacheObj["id"] = data.jsonObject[i].ID;
          					displayCacheObj["label"] = data.jsonObject[i].LABEL;
          					vm.cachingObjList.push(displayCacheObj);
          			}
                } else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }
                    else
                    {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }
                }

            });
        }

        $scope.updateJsonObject = function(json) {
            if(json){
                vm.isJsonValid = true;
                try {
                    JSON.parse(json);
                } catch (e) {
                    vm.isJsonValid = false;
                }
            }
        };
        
        vm.taxTypeDDsettings = {
                scrollableHeight: '200px',
                left: '94px',
                scrollable: true,
                enableSearch: true
        };
        
     	$scope.yourEvents = {onItemSelect: function(item) {
    		if(vm.selectedCachingObjects.length>0){
    			console.log('item selected')
    		}
    		
    		},onItemDeselect: function(item) {
    			if(vm.selectedCachingObjects.length>0){
        			console.log('item de - selected')
        		}
    		}
    	};



        vm.onSelectChange = function(num){
            JCDFactory.setObj(num,null);
           /*  vm.jcdlist.jurisdiction = JCDFactory.lists.jurisdiction;
            vm.jcdlist.domain = JCDFactory.lists.domain; */
            vm.jcdlist.jurisdiction = 1;
            vm.jcdlist.domain = 5;
            vm.jcdlist.channel = JCDFactory.lists.channel;
            vm.jcdlist.task = JCDFactory.lists.task;
            vm.jcdlist.activity = JCDFactory.lists.activity;
            vm.action_data.jcd_ta_key =  JCDFactory.jcd_ta_key;
            vm.roles = [];
            setRoles(vm.action_data.jcd_ta_key,null);
            console.log("task list from action",vm.jcdlist.task);
        };



        vm.exampleOutList = [
            {name:"resultset:OracleTypes.CURSOR,retval:OracleTypes.VARCHAR,error_out:OracleTypes.VARCHAR"},
            {name:"retval:OracleTypes.VARCHAR,error_out:OracleTypes.VARCHAR"},
            {name:"resultset:OracleTypes.CURSOR,resultset_RD:OracleTypes.CURSOR,retval:OracleTypes.VARCHAR,error_out:OracleTypes.VARCHAR"},
        ]


        vm.exampleInList = [
            {name:"sso_id:String:null"},
            {name:"sso_id:String:null,tax_year:String:2016"},
            {name:"sso_id:String:null,tax_year:String:null"},
            {name:"sso_id:String:null,tax_year:String:2015,quarter:String:4"},
            {name:"sso_id:String:null,tax_year:String:null,scenario:String:18,jcd_key:String:250,combination_keys:String:0,parent_me_string:String:0,screen_key:String:18"},
        ]

        vm.exampleProc = [
            {name:"begin PKG_GLOBALTAX_SETTINGS.[SOMENAME](?,?,?,?);end;"},
            {name:"begin PKG_GLOBALTAX_SETTINGS.[SOMENAME](?,?,?,?,?,?,?);end;"},
            {name:"begin PKG_GLOBALTAX_SETTINGS.[SOMENAME](?,);end;"}
        ]

        vm.setOutPut = function(_value){
                console.log(_value);
                vm.action_data.proc_params_out = _value;
        }

        vm.setInPut = function(_value){
            console.log(_value);
            vm.action_data.proc_params_in = _value;
        }

        vm.setProc = function(_value){
            console.log(_value);
            vm.action_data.proc_call = _value;
        }

        vm.setActionType = function(){
            vm.action_data.action_type = vm.action_type.selected.value;
        }
        vm.setActionCheck = function(){
            vm.action_data.action_check = vm.action_check_type.value;
        }


        vm.setDatasource = function(){
           // vm.datasource_name= vm.action_data.datasource_id;

            if(vm.action_data.system_core === 1){
                vm.action_data.datasource_id = "globalTaxDS";
                vm.action_data.override = 0;
             //   vm.action_check_type = _.filter(vm.action_check,{value:'admin'})?_.filter(vm.action_check,{value:'admin'})[0].value:""
            }else if(vm.action_data.override  === 1) {


             console.log("vm.datasource.selected---------------" , vm.datasource.selected);

                vm.action_data.datasource_id = vm.datasource.selected ? vm.datasource.selected.datasource_name : null;
                vm.action_check_type =  _.filter(vm.action_check,{value:'trans'})?_.filter(vm.action_check,{value:'trans'})[0].value:""

            }else{
                //vm.action_data.datasource_id =   vm.action_data.client.client_datasource;
                vm.action_data.datasource_id = vm.userSettings.user.client.client_datasource;
                vm.action_check_type = _.filter(vm.action_check,{value:'trans'})?_.filter(vm.action_check,{value:'trans'})[0].value:""
            }



            console.log("vm.datasource.selected " , vm.datasource.selected);

            vm.selected ="";
            for (let i=0;i<vm.selectedCachingObjects;i++){
            	if(vm.selectedCachingObjects[i].id === vm.action_data.cacheObjKeys.id){
            		vm.selected =  vm.selectedCachingObjects[i];
            		break;
            	}
            }
                  
          //  vm.override.isShow=vm.action_data.datasource_id !== "globalTaxDS";
          //  vm.override.value=vm.override.isShow?1:0;

          //  data_scource


        }

        vm.setDatasource();

        vm.changePermission = function(){
            vm.action_data.action_user_type_key   = vm.permission.selected.value;
            if( vm.action_data.action_user_type_key <= 2  ){
                vm.jcd_required = 1;
            }
        }


        var setRoles = function(_jdc_ta_key,_role_key){
            if(_jdc_ta_key === null || _jdc_ta_key === undefined){
                vm.roles.disabled = true;
                return false;
           }

            vm.roles.loading = true;
            var  params = {action_id:81, jcd_ta_key:_jdc_ta_key};
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1" && data.jsonObject && data.jsonObject[0]){
                    AlertService.add("error", data.errorMessage);
                } else {
                    vm.roles.disabled = false;
                    vm.roles.loading = false;
                    vm.roles.list = JsonObjectFactory.arrObjToLower(data.jsonObject);
                    if( _role_key ){ vm.roles.selected = _.find(vm.roles.list,{jcd_ta_role_key:_role_key}); }
                }
            });
        }

        vm.updateVersion = function(verNum){
    	   vm.version_num = verNum;
    	   console.log("version ::",vm.version_num);
    	   vm.action_data.action_version_num = vm.version_num;
    	   setAction(rowData.ACTION_KEY);
       }


       function setAction(action_key){
            vm.loading = true;
            vm.roles.disabled = false;
            var actionCode = 'mmcfpw';
            var  params = {action_code: actionCode, action_key: rowData.ACTION_KEY,version_num: vm.version_num,client_key: rowData.CLIENT_KEY};
            console.log("SetAction:: params ::",params);
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                vm.loading = false;


               console.log(" setAction data " , data.jsonObject[0]);


                if (data.callSuccess !== "1" && data.jsonObject && data.jsonObject[0]){
                    AlertService.add("error", data.errorMessage);
                } else {
                    setDataSource();
                    vm.loading = false;
                    vm.action_data = JsonObjectFactory.arrObjToLower(data.jsonObject)[0];
                    vm.roles = [];
                    vm.jcd_required = (rowData && rowData.JCD_TA_KEY) ? 1:0;
                    setRoles(vm.action_data.jcd_ta_key,vm.action_data.jcd_ta_role_key);
                    vm.action_type.selected = _.find(vm.action_type.list,{value:vm.action_data.action_type});
                    vm.permission.selected = _.find(vm.permission.list,{value:vm.action_data.action_user_type_key});
                    vm.action_data.client = _.find(vm.client.list,{client_key:vm.action_data.client_key+""});
                    vm.action_check_type = _.filter(vm.action_check,{value:vm.action_data.action_check})?_.filter(vm.action_check,{value:vm.action_data.action_check})[0].value:""
                    if(vm.action_data.secondary_actions !== undefined){
                        vm.noActions = false;
                        vm.action_data.secondary_actions = JSON.parse(vm.action_data.secondary_actions);
                    }else{
                        vm.action_data.secondary_actions = [];
                        vm.noActions = true;
                    }
                    if(data.actionCacheMap && data.actionCacheMap[0]){
                    	angular.forEach(data.actionCacheMap, function(cacheMapObj, key) {
                            for(var v =0; v< vm.cachingObjList.length; v++){
                                if(vm.cachingObjList[v].id == cacheMapObj.id){
                                    var tempCacheObj = {};
                                    tempCacheObj = vm.cachingObjList[v];
                                    tempCacheObj.delay_refresh = cacheMapObj.delay_refresh;
                                    tempCacheObj.enabled = cacheMapObj.enabled;
                                    tempCacheObj.refresh_order = cacheMapObj.refresh_order;
                                    vm.selectedCachingObjects.push(tempCacheObj);
                                }
                            }
                        });
                    }
                    console.log(" vm.selectedCachingObjects : " , vm.selectedCachingObjects);

                    if (data.validationRules && data.validationRules[0]){
                        vm.validationRulesList = data.validationRules;
                        for (var v = 0; v < vm.validationRulesList.length; v++){
                            if (vm.validationRulesList[v].selected_rule == true) {
                                vm.selectedDroolsRuleObj = vm.validationRulesList[v];
                                break;
                            }
                        }
                    } else {
                        console.log("No Validation Rules Found");
                    }

                 // vm.action_data.datasource = _.find(vm.datasource.list,{datasource_id:vm.action_data.datasource_id+""});

                }
            });
        }

        function setDataSource(){
            var  params = {action_id:83};
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                vm.loading = false;
                if (data.callSuccess !== "1" && data.jsonObject && data.jsonObject[0]){
                    AlertService.add("error", data.errorMessage);
                } else {
                    vm.datasource.list = JsonObjectFactory.arrObjToLower(data.jsonObject);
                    vm.datasource.selected = _.find(vm.datasource.list,{datasource_name:vm.action_data.datasource_id});


                    console.log("vm.datasource.list:"  , vm.datasource.list  );
                    console.log("vm.action_data.datasource_id:"  , vm.action_data.datasource_id   );
                    console.log("vm.datasource.selected:"  , vm.datasource.selected   );
                    vm.setDatasource();
                }
            });
        }

        vm.jcdlist.jurisdiction = JCDFactory.lists.jurisdiction;
        vm.jcdlist.domain = JCDFactory.lists.domain;
        vm.jcdlist.channel = JCDFactory.lists.channel;
        vm.jcdlist.task = JCDFactory.lists.task;
        vm.jcdlist.activity = JCDFactory.lists.activity;



        vm.action_type = {
            selected: null,
            list: [
                {name: "grid", value: "grid"},
                {name: "list", value: "list"},
                {name: "chart", value: "chart"},
                {name: "object", value: "object"},
                {name: "static json", value: "static"},
                {name: "update", value: "update"},
                {name: "delete", value: "delete"}
            ]
        }


        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.reset = function(){
			console.log(" Need to reset action fields. ");
			
			vm.action_data = '';
	        vm.cachingObjList = '';
	        vm.selectedCachingObjects = '';
	        vm.jcd_required = '';
	        vm.version_num = '';
	        vm.validationRulesList = '';
	        vm.selectedDroolsRuleObj = '';
	        
		};
        
        vm.save = function(){
        	var cacheObjKeysArray = [];
        	var cacheObjKeysString = '';
            if(!vm.isJsonValid){
                vm.action_data.response_settings = "";
            }
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            if (vm.actionFrom.$invalid) {
                vm.actionFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }
           
            if(vm.selectedCachingObjects.length>0){
            	for (var i = 0; i < vm.selectedCachingObjects.length; i++) {
  					cacheObjKeysArray.push(vm.selectedCachingObjects[i].id);
  			}
            	cacheObjKeysString = cacheObjKeysArray.toString()
            	 console.log("selectedCachingObjects :::" , cacheObjKeysString);
            }
            //vm.crudLoading = true;
            if(vm.jcd_required == 1){
                vm.action_data.jcd_ta_role_key = vm.roles.selected.jcd_ta_role_key;
            }else{
                vm.action_data.jcd_ta_key = null;
                vm.action_data.jcd_ta_role_key = null;
            }
            console.log(" ------------------- vm.action_data.system_core -------------------- " , vm.action_data.system_core);

            console.log(" ------------------- SAVE -------------------- " , vm.action_data);





            var data =  {};
            data.action_key  = (vm.action_data.action_key) ?  vm.action_data.action_key : "";
            vm.action_data.client_key  = (vm.action_data.client && vm.action_data.client.client_key) ?  vm.action_data.client.client_key : vm.userSettings.user.client.client_key;
            if(vm.action_data.override == undefined){
                vm.action_data.override = vm.override.value;
            }
            //vm.action_data.datasource_id = vm.userSettings.user.client.client_datasource;
            if(vm.selectedDroolsRuleObj != undefined && vm.selectedDroolsRuleObj != null){
                vm.action_data.drools_validation_id =  vm.selectedDroolsRuleObj.validation_key;
            }else{
                vm.action_data.drools_validation_id = 0;
            }
            vm.action_data.action_check = vm.action_check_type;
            vm.action_data.cacheObjKeys = vm.selectedCachingObjects;
            data.json_string = JSON.stringify(vm.action_data);

            console.log("data : " , data);

            JsonObjectFactory.saveJSON(GENERAL_CONFIG.admin_api_url + '/saveAction', data).then(function (data) {
                vm.loading = false;
                if (data.callSuccess !== "1"){
                    AlertService.add("error", data.errorMessage);
                } else {

                    AlertService.add("success", "Action has been saved", 2000);
                    $rootScope.$emit('gridUpdate', {});
                    vm.cancel();

                }


            });

        };




        /*vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.client.list = $parse('data.clientResult.clientList')(res);
            });
        };


        vm.getClientList();*/



    }

    function actionListCtrl($scope,$rootScope,$state,$log,GridFactory,AlertService,$timeout,$uibModal, $parse, ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS) {

        var vm = this;
        vm.name = "Action List";
        vm.userSettings = USER_SETTINGS;
        vm.filter = {
            client_key:vm.userSettings.user.client.client_key
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            console.log("SCREEN WAS THIS DESTROYED ")
            gridUpdateListener();
        });

        vm.refresh = function(){
            getData();
        };

        // get the clientList initail load of page
		/*vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
            });
        };
        vm.getClientList();*/

        vm.selected = null;


        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];

            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", data.errorMessage);
                }
            });
        }

        vm.onClientChange = function() {
            getData();
        };

        var getUpDate = function(_params,_filterObj) {
            var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
                params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }







        $timeout(function() {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);



        vm.addAction = function(){
            var data = {};

            var modalObj = {};
            modalObj.template = "app/components/admin/actions/action-tpl.html";
            modalObj.controler = "actionCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,data);
        }


        $scope.ctrlFn = function(_callName,_data,_type) {
            var modalObj = {};
            modalObj.template = "app/components/admin/actions/action-tpl.html";
            modalObj.controler = "actionCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,_data);
        };

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };






    }




    return controllers;

});
