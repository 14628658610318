define([
    'angular'
], function () {
    'use strict';
    let controllers = angular.module('app.MigrationHistController', [])
                             .controller('MigrationHistController', ['$scope', 'EfileFactory', 'AlertService', 'DTOptionsBuilder', 'DTColumnBuilder', 'GlobalService', MigrationHistController]);

    function MigrationHistController($scope, EfileFactory, AlertService, DTOptionsBuilder, DTColumnBuilder, GlobalService) {
        let vm = this;
        vm.histTableData = [];
        vm.dtOptions = DTOptionsBuilder.newOptions()
                                       .withOption('paging', true)
                                       .withOption('pageLength', 10)
                                       .withOption('lengthMenu', [10, 25, 50, 100])
                                       .withOption('processing', true)
                                       .withOption('autoWidth', false)
                                       .withOption('order', [[6, 'desc']])
                                       .withOption('search', {'smart': true})
                                       .withOption('info', true);
        vm.dtColumns = [
            DTColumnBuilder.newColumn('migration_id').withOption('orderable', true),
            DTColumnBuilder.newColumn('tax_year').withOption('orderable', false),
            DTColumnBuilder.newColumn('tax_payer').withOption('orderable', false),
            DTColumnBuilder.newColumn('system_id').withOption('orderable', false),
            DTColumnBuilder.newColumn('status').withOption('orderable', false),
            DTColumnBuilder.newColumn('migrated_by').withOption('orderable', false),
            DTColumnBuilder.newColumn('migrated_on').withOption('orderable', true).withOption('type', 'date')
        ];

        function loadData() {
            vm.histTableDataLoading = true;
            EfileFactory.getMigrationHistory(GlobalService.globalParams.tax_year).then((response) => {
                if (response.data.callSuccess) {
                    vm.histTableData = response.data.eFileMigrationList;
                } else {
                    throw new Error(response.data.errorMessage);
                }
            }).catch((error) => {
                AlertService.add('error', error.toString());
            }).finally(() => {
                vm.histTableDataLoading = false;
            });
        }

        loadData();

        $scope.$on('GlobalParams is updated', function () {
           loadData();
        });
    }

    return controllers;
});
