define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.reviewBookToEpCtrl', [])
        .controller('reviewBookToEpController', [reviewBookToEpController]);

    function reviewBookToEpController() {
    var vm= this;
    vm.review_book_data=[
        {"acc_det": "111601133 - FMV-Asset-Internal-IRS", "count_party": "DE3 (Pd: 1)", "prior_year": "0", "curr_year": "6,696,030", "ep_adj": "0", "us_ep": "6,696,030"},
        {"acc_det": "111601133 - FMV-Asset-Internal-IRS", "count_party": "", "prior_year": "0", "curr_year": "0", "ep_adj": "0", "us_ep": "0"},
        {"acc_det": "444101001 - InvEquitySubs-GECC-1stTier", "count_party": "RYX", "prior_year": "-38,199", "curr_year": "-10,080", "ep_adj": "0", "us_ep": "-10,080"},
        {"acc_det": "444101001 - InvEquitySubs-GECC-1stTier", "count_party": "", "prior_year": "0", "curr_year": "0", "ep_adj": "0", "us_ep": "0"},
        {"acc_det": "442001001 - Loans from Related Non U.S.-Interest Bearing", "count_party": "1,937,555", "prior_year": "0", "curr_year": "-11,223", "ep_adj": "0", "us_ep": "-6,712,519"},
        {"acc_det": "442001001 - Loans from Related Non U.S.-Interest Bearing", "count_party": "", "prior_year": "1,937,555", "curr_year": "-43,221", "ep_adj": "0", "us_ep": ""},
        {"acc_det": "442001002 - Loans from Related U.S.-Interest Bearing", "count_party": "B08", "prior_year": "1,937,555", "curr_year": "-375,019,144", "ep_adj": "0", "us_ep": "-49,432"},
        {"acc_det": "442001002 - Loans from Related U.S.-Interest Bearing", "count_party": "", "prior_year": "0", "curr_year": "", "ep_adj": "0", "us_ep": "323,180"},
        {"acc_det": "302001002 - NonUS-NationalIncTxPay-CYTx-CYAccrued", "count_party": "B08", "prior_year": "1,937,555", "curr_year": "-6,712,519", "ep_adj": "0", "us_ep": "323,180"},
        {"acc_det": "302001777 - NonUS-NationalIncTxPay-CYTx-UnrG/L-F/Cur-CYChg", "count_party": "1,937,555", "prior_year": "0", "curr_year": "1,937,556", "ep_adj": "0", "us_ep": "-6,712,519"},
        {"acc_det": "302003001 - NonUS-NationalIncTxPay-2ndPYTx-1/1", "count_party": "", "prior_year": "0", "curr_year": "", "ep_adj": "0", "us_ep": "323,180"},
        {"acc_det": "302003001 - NonUS-NationalIncTxPay-2ndPYTx-1/1", "count_party": "B08", "prior_year": "1,937,555", "curr_year": "", "ep_adj": "0", "us_ep": "-49,432"},
    ];
    }
});