define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.sourcingAmountsMainController', [])
            .controller('sourcingAmountsMainController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',sourcingAmountsMainController]
            );

        function sourcingAmountsMainController($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.checkLockeOdbj =[];
            vm.userSettings = USER_SETTINGS;
            vm.gtwBaseURL = GENERAL_CONFIG.base_url;

            vm.tax_year = filterParams.tax_year;
            vm.scenario = filterParams.scenario;
            vm.jcd_key = GlobalService.globalParams.jcd_key;
            vm.logged_in_user = vm.userSettings.user.sso_id;

            vm.isIssueKeyEnabled = GlobalService.inputs.tax_year.selected.is_issue_key_enabled;
            if(vm.isIssueKeyEnabled === undefined) {
                vm.isIssueKeyEnabled = GlobalService.globalParams.is_issue_key_enabled;
            }

            vm.issueKey = rowData.ISSUE_KEY;

            vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.CODE;

            if(vm.isIssueKeyEnabled === undefined) {
                if(vm.scenarioCode !== undefined) {
                    if(vm.scenarioCode === 'RAF') {
                        vm.isIssueKeyEnabled = 'N';
                    }
                    else {
                        vm.isIssueKeyEnabled = 'Y';
                    }
                }
            }

            console.log('----------- isIssueKeyEnabled = ', vm.isIssueKeyEnabled , '  scenarioCode = ', vm.scenarioCode , '  --------------------------');

            vm.screen_key = workspaceFactory.activeScreen.screen_key;
            vm.combination_key = rowData.COMBINATION_KEY;

            vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;


            vm.isLocked = ModalFactory.response.data.lockResult.is_locked;

            vm.title = 'Tax Year : ' + vm.tax_year + ' - ' + 'Scenario : ' + vm.scenarioLabel

            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isSystemLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;
                    vm.checkLockeOdbj.push({"leid": rowData.LEID,"cdr_number":rowData.CDR_NO, "reporting_period": rowData.REPORTING_PERIOD, "combination_key":rowData.COMBINATION_KEY, "scenario_key":vm.scenario});
                    console.log(vm.tax_year , ' : ', vm.scenario, ' : ', vm.jcd_key, ' : ', vm.logged_in_user , ' : ', vm.screen_key , ' : ', vm.combination_key , ':' , vm.checkLockeOdbj, ':', vm.isIssueKeyEnabled, ':', vm.scenarioCode);
                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);

                    const html = `<gtw-sourcing-amounts base-url="{{ctrl.gtwBaseURL}}" tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" sso-id="{{ctrl.logged_in_user}}" combination-key="{{ctrl.combination_key}}" 
                                    check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" scenario-code="{{ctrl.scenarioCode}}"  is-issue-key-enabled="{{ctrl.isIssueKeyEnabled}}" is-locked="{{ctrl.isLocked}}" issue-key="{{ctrl.issueKey}}" class="gtw-web-components"></gtw-sourcing-amounts>`;

                    vm.webComponentHtml = html;

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {

                        $window.document.querySelector('gtw-sourcing-amounts')
                            .addEventListener('cancel-sourcing-amts', (event)=>{
                                console.log('event',event);
                                $uibModalInstance.close();
                            });
                        console.log('...event listener cancel-sourcing-amts added ...');
                    },3000
                );

                $timeout(() => {

                        $window.document.querySelector('gtw-sourcing-amounts')
                            .addEventListener('save-sourcing-amts', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-sourcing-amts'){
                                    var args = {
                                        combination_key: rowData.COMBINATION_KEY,
                                        gridFilter: {
                                            combination_key: rowData.COMBINATION_KEY
                                        }
                                    };
                                    $rootScope.$emit('gridUpdate', args);

                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener save-sourcing-amts added ...');
                    },3000
                );

                $scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
);
