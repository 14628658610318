define([
    'angular'

], function () {
    'use strict';

    var services = angular.module('app.ghostSchdmConfigService', [])

        .factory('ghostSchdmConfigFactory', ['$q', '$http', '$timeout', '$rootScope', '$log', 'DataFiltersFactory', 'workspaceFactory', 'JsonObjectFactory', 'USER_SETTINGS', 'AlertService', 'GlobalService', 'GENERAL_CONFIG',
            function ghostSchdmConfigFactory($q, $http, $timeout, $rootScope, $log, DataFiltersFactory, workspaceFactory, JsonObjectFactory, USER_SETTINGS, AlertService, GlobalService, GENERAL_CONFIG) {
                var factory = {};
                var URLS = {
                    GET_TEMPLATES: GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3v1v6n",
                    SAVE_TEMPLATE: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ivb9cb",
                }
                var userSettings = USER_SETTINGS;
                var logged_in_user = userSettings.user.sso_id;

                factory.getTemplatesData = function () {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params.sso_id = logged_in_user;
                    params.screen_key = GlobalService.globalParams.screen_key;
                    params.jcd_key = GlobalService.globalParams.jcd_key;
                    var promise = $http({
                        method: "get",
                        url: URLS.GET_TEMPLATES,
                        params: params

                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });
                    return promise;
                }

                factory.saveTemplate = function (_callName, _data, isNew) {
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams()
                        : {};
                    var params = filterParams;
                    params.sso_id = logged_in_user;
                    params.jcd_key = GlobalService.globalParams.jcd_key;
                    params.screen_key = GlobalService.globalParams.screen_key;
                    console.log(_callName, _data)
                    params.template_id = _data.rowData.SCHDM_TEMPLATE_ID;
                    params.is_excluded = _data.rowData.IS_EXCLUDED === 1 || _data.rowData.IS_EXCLUDED === 'Y' ? 'Y' : 'N' ;
                    var promise = $http({
                        method: "POST",
                        url: URLS.SAVE_TEMPLATE,
                        data: params
                    }).then(function (response) {
                        return response;
                    });

                    return promise;
                }

                return factory;

            }]);


    return services;


});
