define([
    'angular',
    './businessSignOffDescCtrl',
    './businessSignOffDescService'


], function () {
    'use strict';
    return angular.module('app.business-signOff-desc', ['app.businessSignOffDescCtrl', 'app.businessSignOffDescService']);
});
