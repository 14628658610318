

define([
    'angular',
    './ghostSystemRunService',
    './ghostSystemRunController'

], function () {
    'use strict';
    return angular.module('app.ghostSystemRunStatus', ['app.ghostSystemRunCtrl','app.ghostSystemRunService'] )

});
