/**
 * Created by 400371031 on 1/20/2021.
 */

define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.relatedPartyPaymntServce',[])

        .factory('relatedPartyPaymentFactory',['$q','$http','$timeout','$rootScope','$log','DataFiltersFactory','workspaceFactory','JsonObjectFactory','AlertService','GlobalService','GENERAL_CONFIG',
        function relatedPartyPaymentFactory($q,$http,$timeout,$rootScope,$log,DataFiltersFactory,workspaceFactory,JsonObjectFactory,AlertService,GlobalService,GENERAL_CONFIG) {
            var factory = {};
            
            factory.getPayorAccountDetails = function(le_issue_clob, payment_type, classification_type, trans_type, sso_id){  
            	var cc_clob = JSON.stringify(le_issue_clob);          	
	    		var params = { "action_code": 'q7a4cr',
	    					   "sso_id": sso_id, 
	    					   "tax_year": GlobalService.globalParams.tax_year, 
	    					   "scenario": GlobalService.globalParams.scenario, 
	    					   "jcd_key":  GlobalService.globalParams.jcd_key, 
	    					   "cc_clob":  cc_clob,
	    					   "payment_type": payment_type,
	    					   "classification_type": classification_type,
	    					   "trans_type": trans_type
	    					  };
	    					  
	    		console.log("service:::::getPayorAccountDetails:::::");
	    		console.log("screen key:::", workspaceFactory.activeScreen.screen_key);
	    		console.log("label:::", workspaceFactory.activeScreen.label);
	    		console.log(params);		
	    			  
	    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
	    			console.log("Now in the Servie after action call the data was ................:::::", data);
	    			if(null != data.callSuccess && data.callSuccess == "1"){	    				
	                    console.log("callSuccess:::: Data Now is::-----", data);
	                    return data;
	    			}else{
	    				AlertService.add("error", data.errorMessage);
	                    return false;
	    			}
	    		});
	        };
	        
	        factory.getExtPayorDetails = function(){            	
	    		var params = { "action_code": 'kgwkdu',
	    					   "tax_year": GlobalService.globalParams.tax_year, 
	    					   "scenario": GlobalService.globalParams.scenario, 
	    					   "jcd_key":  GlobalService.globalParams.jcd_key
	    					  };
	    					  
	    		console.log("service:::::getPayorAccountDetails:::::");
	    		console.log("screen key:::", workspaceFactory.activeScreen.screen_key);
	    		console.log("label:::", workspaceFactory.activeScreen.label);
	    		console.log(params);		
	    			  
	    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
	    			console.log("Now in the Servie after action call the data was ................:::::", data);
	    			if(null != data.callSuccess && data.callSuccess == "1"){	    				
	                    console.log("callSuccess:::: Data Now is::-----", data);
	                    return data;
	    			}else{
	    				AlertService.add("error", data.errorMessage);
	                    return false;
	    			}
	    		});
	        };
	        
	        factory.getPayorModalByAcct = function(cc_acct_clob, acct_type, payment_type, classification_type, trans_type, sso_id){
            	var cc_clob = JSON.stringify(cc_acct_clob);
	    		var params = { "action_code": 'bzsfqi',
	    					   "sso_id": sso_id, 
	    					   "tax_year": GlobalService.globalParams.tax_year, 
	    					   "scenario": GlobalService.globalParams.scenario, 
	    					   "jcd_key":  GlobalService.globalParams.jcd_key, 
	    					   "cc_clob":  cc_clob,
	    					   "acct_type": acct_type,
	    					   "payment_type": payment_type,
	    					   "classification_type": classification_type,
	    					   "trans_type": trans_type
	    					  };
	    					  
	    		console.log("service:::::getPayorModalDataByAcctType:::::");
	    		console.log(params);		
	    			  
	    		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
	    			console.log("Now in the Servie after action call the data was ................:::::", data);
	    			if(null != data.callSuccess && data.callSuccess == "1"){	    				
	                    console.log("callSuccess:::: Data Now is::-----", data);
	                    return data;
	    			}else{
	    				AlertService.add("error", data.errorMessage);
	                    return false;
	    			}
	    		});
	        };
	        
	        factory.getM3PayorList = function(){
	        	var params = { "action_code": 'eosx7n',
 					   "tax_year": GlobalService.globalParams.tax_year, 
 					   "scenario": GlobalService.globalParams.scenario, 
 					   "jcd_key":  GlobalService.globalParams.jcd_key
 					  };
	        	console.log(params);
	        	
	        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
	    			console.log("Now in the Servie after action call the data was ................:::::", data);
	    			if(null != data.callSuccess && data.callSuccess == "1"){	    				
	                    console.log("callSuccess:::: Data Now is::-----", data);
	                    return data;
	    			}else{
	    				AlertService.add("error", data.errorMessage);
	                    return false;
	    			}
	    		});
	        };
	        
	        factory.saveRPModal = function(final_data, clob_settings, classification_type, trans_type){
	    		console.log("Clob_data was done as:::::::::------------", final_data);
	    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=y0onfe";
	    		var json_settings = JSON.stringify(clob_settings);
	    		var json_data = JSON.stringify(final_data);
	    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	    		
	    		var params = filterParams;
	            params = _.merge({},  GlobalService.globalParams , params);
	            params =  _.extend({json_data:json_data}, params);
	            params =  _.extend({json_settings:json_settings}, params);
	            params.classification_type =  classification_type;
	            params.trans_type =  trans_type;
	            console.log(params);
	            var promise = $http({
	                method: "post",
	                url: url,
	                data: params
	            }).then(function (response) {
	                var status = response.status;
	                return response;
	            });
	            return promise;
	    	};
			
			factory.allocateProcess = function(final_data, clob_settings){
	    		console.log("Clob_data was done as:::::::::------------", final_data);
	    		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=oexyp0";
	    		var json_settings = JSON.stringify(clob_settings);
	    		var json_data = JSON.stringify(final_data);
	    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	    		
	    		var params = filterParams;
	            params = _.merge({},  GlobalService.globalParams , params);
	            params =  _.extend({json_data:json_data}, params);
	            params =  _.extend({json_settings:json_settings}, params);
	            console.log(params);
	            var promise = $http({
	                method: "post",
	                url: url,
	                data: params
	            }).then(function (response) {
	                var status = response.status;
	                return response;
	            });
	            return promise;
	    	};


            return factory;

        }]);


    return services;


});
