define([
   'angular'

], function () {
   'use strict';

   var controllers =  angular.module('app.taxSensitiveAController',[]);
   
   controllers.controller('TaxSensitiveAcctController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory',
       'AlertService','JsonObjectFactory','TaxGroupObjectFactory','rowData','gridData','GENERAL_CONFIG' ,taxSensitiveAcctController
   ])

  function format(value, replace) {
		if (!value) {
			return value;
		}
		var target = value.toString();
		if (replace === undefined) {
			return target;
		}
		if (!angular.isArray(replace) && !angular.isObject(replace)) {
			return target.split('$0').join(replace);
		}
		var token = (angular.isArray(replace) && '$') || ':';

		angular.forEach(replace, function(value, key) {
			target = target.split(token + key).join(value);
		});
		return target;
	}

   controllers.value('customSelectDefaultsTaxsensitive', {
		displayText: 'Select...',
		emptyListText: 'There are no items to display',
		emptySearchResultText: 'No results match "$0"',
		addText: 'Add',
		searchDelay: 300,
	});

   controllers.directive('customSelectTaxsensitive', [
		'$parse',
		'$compile',
		'$timeout',
		'$q',
		'customSelectDefaultsTaxsensitive',
		function($parse, $compile, $timeout, $q, baseOptions) {
			var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

			return {
				restrict: 'A',
				require: 'ngModel',
				link: function(scope, elem, attrs, controller) {
					var customSelect = attrs.customSelectTaxsensitive;
					if (!customSelect) {
						throw new Error('Expected custom-select attribute value.');
					}
					scope.$watch("ctrl.sys_acc.SYS_ACCT.SYS_ACCT",function(){
						//scope.getChartAcctTableData();
						scope.$emit('SYS_ACCT_CHANGED', '');
					});
					var match = customSelect.match(CS_OPTIONS_REGEXP);

					if (!match) {
						throw new Error(
							'Expected expression in form of ' +
								"'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'" +
								" but got '" +
								customSelect +
								"'."
						);
					}

					elem.addClass('dropdown custom-select');

					// Ng-Options break down
					var displayFn = $parse(match[2] || match[1]),
						valueName = match[3],
						valueFn = $parse(match[2] ? match[1] : valueName),
						values = match[4],
						valuesFn = $parse(values),
						track = match[5],
						trackByExpr = track ? ' track by ' + track : '',
						dependsOn = attrs.csDependsOn;

					var options = getOptions(),
						timeoutHandle,
						lastSearch = '',
						focusedIndex = -1,
						matchMap = {};

					var itemTemplate =
							elem.html().trim() || '{{' + (match[2] || match[1]) + '}}',
						dropdownTemplate =
							'<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">' +
							'<span>{{displayText}}</span>' +
							'<b></b>' +
							'</a>' +
							'<div class="dropdown-menu">' +
							'<div stop-propagation="click" class="custom-select-search">' +
							'<input class="' +
							attrs.selectClass +
							'" type="text" autocomplete="off" ng-model="searchTerm" />' +
							'</div>' +
							'<ul role="menu">' +
							'<li role="presentation" ng-repeat="' +
							valueName +
							' in matches' +
							trackByExpr +
							'">' +
							'<a role="menuitem" tabindex="-1" href ng-click="select(' +
							valueName +
							')">' +
							itemTemplate +
							'</a>' +
							'</li>' +
							'<li ng-hide="matches.length" class="empty-result" stop-propagation="click">' +
							'<em class="muted">' +
							'<span ng-hide="searchTerm">{{emptyListText}}</span>' +
							'<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>' +
							'</em>' +
							'</li>' +
							'</ul>' +
							'<div class="custom-select-action">' +
							(typeof options.onAdd === 'function'
								? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
								: '') +
							'</div>' +
							'</div>';

					// Clear element contents
					elem.empty();

					// Create dropdown element
					var dropdownElement = angular.element(dropdownTemplate),
						anchorElement = dropdownElement.eq(0).dropdown(),
						inputElement = dropdownElement.eq(1).find(':text'),
						ulElement = dropdownElement.eq(1).find('ul');

					// Create child scope for input and dropdown
					var childScope = scope.$new(true);
					configChildScope();

					// Click event handler to set initial values and focus when the dropdown is shown
					anchorElement.on('click', function(event) {
						if (childScope.disabled) {
							return;
						}
						childScope.$apply(function() {
							lastSearch = '';
							childScope.searchTerm = '';
						});

						focusedIndex = -1;
						inputElement.focus();

						// If filter is not async, perform search in case model changed
						if (!options.async) {
							getMatches('');
						}
					});

					if (dependsOn) {
						scope.$watch(dependsOn, function(newVal, oldVal) {
							if (newVal !== oldVal) {
								childScope.matches = [];
								childScope.select(undefined);
							}
						});
					}

					// Event handler for key press (when the user types a character while focus is on the anchor element)
					anchorElement.on('keypress', function(event) {
						if (!(event.altKey || event.ctrlKey)) {
							anchorElement.click();
						}
					});

					// Event handler for Esc, Enter, Tab and Down keys on input search
					inputElement.on('keydown', function(event) {
						if (!/(13|27|40|^9$)/.test(event.keyCode)) return;
						event.preventDefault();
						event.stopPropagation();

						switch (event.keyCode) {
							case 27: // Esc
								anchorElement.dropdown('toggle');
								break;
							case 13: // Enter
								selectFromInput();
								break;
							case 40: // Down
								focusFirst();
								break;
							case 9: // Tab
								anchorElement.dropdown('toggle');
								break;
						}
					});

					// Event handler for Up and Down keys on dropdown menu
					ulElement.on('keydown', function(event) {
						if (!/(38|40)/.test(event.keyCode)) return;
						event.preventDefault();
						event.stopPropagation();

						var items = ulElement.find('li > a');

						if (!items.length) return;
						if (event.keyCode == 38) focusedIndex--; // up
						if (event.keyCode == 40 && focusedIndex < items.length - 1)
							focusedIndex++; // down
						//if (!~focusedIndex) focusedIndex = 0;

						if (focusedIndex >= 0) {
							items.eq(focusedIndex).focus();
						} else {
							focusedIndex = -1;
							inputElement.focus();
						}
					});

					resetMatches();

					// Compile template against child scope
					$compile(dropdownElement)(childScope);
					elem.append(dropdownElement);

					// When model changes outside of the control, update the display text
					controller.$render = function() {
						setDisplayText();
					};

					// Watch for changes in the default display text
					childScope.$watch(getDisplayText, setDisplayText);

					childScope.$watch(
						function() {
							return elem.attr('disabled');
						},
						function(value) {
							childScope.disabled = value;
						}
					);

					childScope.$watch('searchTerm', function(newValue) {
						if (timeoutHandle) {
							$timeout.cancel(timeoutHandle);
						}

						var term = (newValue || '').trim();
						timeoutHandle = $timeout(
							function() {
								getMatches(term);
							},
							// If empty string, do not delay
							(term && options.searchDelay) || 0
						);
					});

					// Support for autofocus
					if ('autofocus' in attrs) {
						anchorElement.focus();
					}

					var needsDisplayText;
					function setDisplayText() {
						console.log(controller,"controller");
						var locals = {};
						locals[valueName] = controller.$modelValue;
						var text = displayFn(scope, locals);
						console.log(text,"text",options);
						if (text === undefined) {
							var map = matchMap[hashKey(controller.$modelValue)];
							if (map) {
								text = map.label;
							}
						}

						needsDisplayText = !text;
						childScope.displayText = text || controller.$modelValue // text || options.displayText;
					}

					function getOptions() {
						return angular.extend(
							{},
							baseOptions,
							scope.$eval(attrs.customSelectOptionsTaxsensitive)
						);
					}

					function getDisplayText() {
						options = getOptions();
						return options.displayText;
					}

					function focusFirst() {
						var opts = ulElement.find('li > a');
						if (opts.length > 0) {
							focusedIndex = 0;
							opts.eq(0).focus();
						}
					}

					// Selects the first element on the list when the user presses Enter inside the search input
					function selectFromInput() {
						var opts = ulElement.find('li > a');
						if (opts.length > 0) {
							var ngRepeatItem = opts.eq(0).scope();
							var item = ngRepeatItem[valueName];
							childScope.$apply(function() {
								childScope.select(item);
							});
							anchorElement.dropdown('toggle');
						}
					}

					function getMatches(searchTerm) {
						var locals = { $searchTerm: searchTerm };
						$q.when(valuesFn(scope, locals)).then(
							function(matches) {
								if (!matches) return;

								if (searchTerm === inputElement.val().trim() /* && hasFocus*/) {
									matchMap = {};
									childScope.matches.length = 0;
									for (var i = 0; i < matches.length; i++) {
										locals[valueName] = matches[i];
										var value = valueFn(scope, locals),
											label = displayFn(scope, locals);

										matchMap[hashKey(value)] = {
											value: value,
											label: label /*,
									model: matches[i]*/,
										};

										childScope.matches.push(matches[i]);
									}
									//childScope.matches = matches;
								}

								if (needsDisplayText) setDisplayText();
							},
							function() {
								resetMatches();
							}
						);
					}

					function resetMatches() {
						childScope.matches = [];
						focusedIndex = -1;
					}

					function configChildScope() {
						childScope.addText = options.addText;
						childScope.emptySearchResultText = options.emptySearchResultText;
						childScope.emptyListText = options.emptyListText;

						childScope.select = function(item) {
							var locals = {};
							locals[valueName] = item;
							var value = valueFn(childScope, locals);
							//setDisplayText(displayFn(scope, locals));
							childScope.displayText =
								displayFn(childScope, locals) || options.displayText;
							controller.$setViewValue(value);

							anchorElement.focus();

							typeof options.onSelect === 'function' && options.onSelect(item);
						};

						childScope.add = function() {
							$q.when(options.onAdd(), function(item) {
								if (!item) return;

								var locals = {};
								locals[valueName] = item;
								var value = valueFn(scope, locals),
									label = displayFn(scope, locals);

								matchMap[hashKey(value)] = {
									value: value,
									label: label /*,
									model: matches[i]*/,
								};

								childScope.matches.push(item);
								childScope.select(item);
							});
						};

						childScope.format = format;

						setDisplayText();
					}

					var current = 0;
					function hashKey(obj) {
						if (obj === undefined) return 'undefined';

						var objType = typeof obj,
							key;

						if (objType == 'object' && obj !== null) {
							if (typeof (key = obj.$$hashKey) == 'function') {
								// must invoke on object to keep the right this
								key = obj.$$hashKey();
							} else if (key === undefined) {
								key = obj.$$hashKey = 'cs-' + current++;
							}
						} else {
							key = obj;
						}

						return objType + ':' + key;
					}
				},
			};
		},
	]);

   controllers.directive('stopPropagation', function() {
		return {
			restrict: 'A',
			link: function(scope, elem, attrs, ctrl) {
				var events = attrs['stopPropagation'];
				elem.bind(events, function(event) {
					event.stopPropagation();
				});
			},
		};
	});
 //  taxSensitiveAcctController
   function taxSensitiveAcctController ($rootScope,$scope,$http,$timeout,GlobalService,$uibModalInstance,ModalFactory,AlertService,JsonObjectFactory,TaxGroupObjectFactory,rowData,gridData,GENERAL_CONFIG)
   {

	   
	   var vm = this;
	   vm.tempsystemaccount =[];
	   vm.templocalaccount =[];
	   vm.templocalaccount1 =[];
	   vm.localaccounts =[];
	   vm.systemaccpunts = [];
	   vm.showDetails = {text:'lookup'};
	   vm.tempGridData =_.clone(gridData);
	   
	   
	   
	   if(rowData == null){
	   for(var index_ in gridData )		
	     {		
	    	  vm.localaccounts.push(gridData[index_].data.LOCAL_CHART_ACCT_KEY);	
	    	
	       }     	
	   
	   
	   vm.localaccounts =_.uniq(vm.localaccounts, JSON.stringify)	
	   
	   var paramlocalaccounts = vm.localaccounts.join();
	   console.log(vm.localaccounts);
	   console.log(paramlocalaccounts);
	   }
	   
	   
	   
	   
	   
	   vm.orderByField = 'FORM';
	     vm.reverseSort = false;
	     vm.sort_key = {'1':0,'2':0,'3':0,'4':0,'5':0};
	
	   vm.cancel = function(){
		     $uibModalInstance.dismiss('cancel');
			 	};
			 	
			 	
			 	 vm.passgriddata = _.clone(gridData);
				 
			 	
		 	     vm.checksystemaccount = [];	
		 vm.checksensitiveaccount=[];
		 	    		
		 	     if(rowData == null){		
		 	     		
		 	     for(var index_ in gridData )		
		 	     {		
		 	    	  vm.checksystemaccount.push(gridData[index_].data.SYS_ACCT_KEY);
		 	    	 vm.checksensitiveaccount.push(gridData[index_].data.SENSITIVE_ACCT);
		 	       }     		
		 	     vm.checksystemaccount = _.uniq(vm.checksystemaccount, JSON.stringify)	
		 	     vm.checksensitiveaccount = _.uniq(vm.checksensitiveaccount, JSON.stringify)
		 	     var b = vm.checksensitiveaccount.indexOf("Y");
		 	     
		 	     if(b >=0)
		 	    	 {
		 	    	 
		 	    	AlertService.add("error", "Please Select TaxSensitive System Account.", 400000);
	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	                throw new Error("This is not an error. This is just to abort javascript!");
		 	    	 }
		 	     
		 	     
		 	     
		 	     console.log(vm.checksensitiveaccount);

		 	     var paramssystemaccount = vm.checksystemaccount.join();
		 	     		
		 	     if(vm.checksystemaccount.length> 1)		
		 	    	 {		
		 	    	stopSystemaccountSelection();
		 	    	
		 	    	
		 	    	 		
		 	    	 }		
		 	     		
		 	     console.log(vm.checksystemaccount,"outside If");		
		 	     		
		 	     }		
		 	     
		 	     if(rowData != null)
  
		 	    	 {

			 	     vm.checksensitiveaccount.push(rowData.SENSITIVE_ACCT);
			 	     vm.checksensitiveaccount = _.uniq(vm.checksensitiveaccount, JSON.stringify)
			 	     var b = vm.checksensitiveaccount.indexOf("Y");
			 	     
			 	     if(b >=0)
			 	    	 {
			 	    	 
			 	    	AlertService.add("error", "Please Select TaxSensitive System Account.", 400000);
		                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
		                throw new Error("This is not an error. This is just to abort javascript!");
			 	    	 }
			 	     
			 	     
			 	     
			 	     console.log(vm.checksensitiveaccount);

			 	     var paramssystemaccount = vm.checksystemaccount.join();
			 	     		
			 	     if(vm.checksystemaccount.length> 1)		
			 	    	 {		
			 	    	stopSystemaccountSelection();
			 	    	
			 	    	
			 	    	 		
			 	    	 }		
			 	     		
			 	     console.log(vm.checksystemaccount,"outside If");		
		 	    	 
		 	    	 
		 	    	 
		 	    	 
		 	    	 
		 	    	 }
		 	     function stopSystemaccountSelection(){
		 	    	
		 	    	// alert("Please select only one System Account :");
		 	    	 
		 	    	 
		 	    	AlertService.add("error", "Please Select Only One System Account.", 400000);
	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	                throw new Error("This is not an error. This is just to abort javascript!");
		 	    	 
		 	    	 
		 	    	
		 	     }
		 	     
		 	 //  vm.chartacct_data.rows = 5;
		 		 vm.sortBy = function(key,value){
		 	                  	switch(value){
		 	                  		case 0:
		 	                  		vm.sort_key[key] = 1;
		 	                  		vm.reverseSort = false;
		 	                  		break;
		 	                  		case 1:
		 	                  		vm.sort_key[key] = 2;
		 	                  		vm.reverseSort = true;
		 	                  		break;
		 	                  		case 2:
		 	                  		vm.sort_key[key] = 0;
		 	                  		vm.reverseSort = "";
		 	                  		break;
		 	                  	}
		 	     }
		 		 
		 		 
		 		 
		 		 
	   
   function fetchtaxsensitivedata(){
	     	
    	   
	   if(rowData != null){
    	   var params = {
	   			"action_code": '5rvabb',
	   			"tax_year": rowData.TAX_YEAR,
	   			"sys_acct":  rowData.LOCAL_CHART_ACCT_KEY,
	   			"local_accts":  rowData.SYS_ACCT_KEY,
	   			
	   			 }
	   }
	   else{
		   
		   var params = {
		   			"action_code": '5rvabb',
		   			"tax_year": gridData[0].data.TAX_YEAR,
		   			"sys_acct":  paramlocalaccounts,
		   			"local_accts":  paramssystemaccount,
		   			 }
		   
		   
	   }
    	  
	     	console.log("Input Data fetchSystemAcctData**********::",params);
	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
	        	 console.log("Response Data ::",data);
	             if (data.callSuccess === "1" ) {
	            	 vm.loadSystemTaxsensitivitydata = data.jsonObject;
	            	 
	            	 
	            	
	            	 
	            	 
	            	 for(var i in data.jsonObject)
	            		 {
	            		 data.jsonObject[i].isEditable = false;
	            		 data.jsonObject[i].saveFlag = false;
	            		 data.jsonObject[i].oldtemplatevalue = "";
	            		 data.jsonObject[i].olduserdescvalue = "";
	            		 vm.tempsystemaccount.push(data.jsonObject[i].SCHDM_TEMPLATE_TABLE);	
	            		 }
	            	 
	            	 vm.tempsystemaccount = _.uniq(vm.tempsystemaccount, JSON.stringify)	
	            	 var tempdropdown = vm.tempsystemaccount;
	            	 for(var b in tempdropdown)
	            	
	            		 
	            		{
	            		 for(var j in data.jsonObject)
	            			
	            		 {
	            			 console.log(tempdropdown[b]);
                               if( tempdropdown[b] == data.jsonObject[j].SCHDM_TEMPLATE_TABLE )	            			
	            			{
                            	   vm.templocalaccount.push(data.jsonObject[j].SCHDM_TEMPLATE_TABLE);
                            	   vm.templocalaccount1.push(data.jsonObject[j].TAX_STREAM_ACCT);
                            	   
	            			}
	            			}
	            		}
	            	 vm.templocalaccount =_.uniq(vm.templocalaccount, JSON.stringify)
	            	 vm.templocalaccount1 =_.uniq(vm.templocalaccount1, JSON.stringify)

	            
                     console.log(vm.templocalaccount,"Check Here Y1");
	             }
	             else
	               {
	            	 if(data.errorMessage === "no access"){
	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	               }
	            	 else
	            	 {
	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	                 }
	             }
	             
	         });
	     };
	   
	   
	   
	     fetchtaxsensitivedata();
	     
	     vm.formDelete =  function(_item){
	       
	    	  var index = _.findIndex(vm.loadSystemTaxsensitivitydata, { '$$hashKey': _item.$$hashKey });
	         vm.loadSystemTaxsensitivitydata.splice(index,1);
	      
	         vm.triggeraccount = _item.TRIGGER_ACCT;
	    	 vm.templatevalue = _item.SCHDM_TEMPLATE_TABLE;
	         vm.userdesc = _item.USER_DESC;
	         vm.scdhmvalue = _item.SCHDM_TYPE;
	         vm.postingtac = _item.POSTING_TAC;
	         vm.postingacct = _item.POSTING_ACCT;
	         vm.methodofcal = _item.METHOD_OF_CALC;
	         vm.multiplier = _item.MULTIPLIER;
	         vm.scdhmid  = _item.SCHDM_ID;

	         
	         
	         var returnObjD = {};
	         var taxsensitiveDeletedata = [];
	         returnObjD.type =  'D';
             returnObjD["object_id"]= (rowData != undefined)?rowData.object_id:gridData[0].data.object_id;
             returnObjD['tax_year'] = (rowData != undefined)?rowData.TAX_YEAR:gridData[0].data.TAX_YEAR;
             returnObjD['scenario'] = GlobalService.globalParams.scenario;
             returnObjD['trigger_acct'] = vm.triggeraccount;
             returnObjD['posting_tac'] = vm.postingtac;
             returnObjD['postint_acct'] = vm.postingacct;
             returnObjD['scdhmid'] = vm.scdhmid;

	         
             taxsensitiveDeletedata.push(returnObjD);
         	var message = "Data has been deleted successfully";
			// objSettings.push(returnObj);
			sendDeleteDetails(returnObjD,taxsensitiveDeletedata,message)

	}
	     
	
	     function sendDeleteDetails(returnObjD, taxsensitiveDeletedata, message) {
	    	 TaxGroupObjectFactory.DeleteTax(returnObjD,
	    			 taxsensitiveDeletedata).then(
						function(result) {

							if (result.errorMessage
									&& result.errorMessage !== 'null') {
								AlertService.add("error",
										result.errorMessage, 4000);
							} else {
								AlertService.add("success", message, 4000);
								$uibModalInstance.dismiss('cancel');

								
						    	 
								     

							}
						});

			}
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     
	     vm.editflag = false;
	     
	     
	     vm.currentRow =  [];
	     
	   
	    	  vm.formEdit =  function(row){
	 	    	 
	 	    	 var rowindex = _.findIndex(vm.loadSystemTaxsensitivitydata, { '$$hashKey': row.$$hashKey });
	 	    	 
	 	    	 for(var index = 0;index <vm.loadSystemTaxsensitivitydata.length;index++ ){
	 	    		 
	 	    		 //vm.loadSystemTaxsensitivitydata[index].isEditable = false;
	 	    		 if(rowindex == index && vm.loadSystemTaxsensitivitydata[index].saveFlag != true)
	 	    	{
	 	    		 vm.loadSystemTaxsensitivitydata[index].saveFlag= true;
	 	    		 
	 	    	}
	 	    	
	 	    	 }
	 	    	 
	 	    	 
	 	    		 
	 	    		 row.isEditable = true;
	 	    		 vm.loadSystemTaxsensitivitydata[rowindex].oldtemplatevalue = row.SCHDM_TEMPLATE_TABLE;
	 	    		 vm.loadSystemTaxsensitivitydata[rowindex].olduserdescvalue = row.USER_DESC;
	 		    	 
	 	    		 console.log(vm.loadSystemTaxsensitivitydata);
	 	    		
	 	    		 vm.showDetails.text = 'edit';
	 	    		 
	 	    		
	 	    	 
	 	    
	 	     }
	    
	    	
	    
	    	  vm.changeTemplate = function(_item){
	    		
	    		
	    		  // _item = {dropdowndata: "128- sdhasjkdh"}
	    	//	  var strObject = JSON.parse('{"dropdowndata":"a"}');
	    		  
	    		  
	    		  _item.updated = true;
	    
	        };

	     vm.save = function(){
	    	 
	    	 var data = vm.loadSystemTaxsensitivitydata;
	    	 
			

	    	
	    	
	    	 console.log(data);
	    	
	    	 var taxsensitiveDetails = [];
	    	 
	    	 for(var index in vm.loadSystemTaxsensitivitydata )
	    		 {
	    		 
	    		 if(vm.loadSystemTaxsensitivitydata[index].saveFlag == true)
	    			 {
	    			 
//	    			 taxsensitiveDetails.push(vm.loadSystemTaxsensitivitydata[index].TRIGGER_ACCT);
//	    			 taxsensitiveDetails.push(vm.loadSystemTaxsensitivitydata[index].POSTING_ACCT);
//	    			 taxsensitiveDetails.push(vm.loadSystemTaxsensitivitydata[index].POSTING_TAC);
//	    			 taxsensitiveDetails.push(vm.loadSystemTaxsensitivitydata[index].dropdowndata);
//	    			 taxsensitiveDetails.push(vm.loadSystemTaxsensitivitydata[index].oldtemplatevalue);
//	    			 taxsensitiveDetails.push(vm.loadSystemTaxsensitivitydata[index].olduserdescvalue);
//	    			 taxsensitiveDetails.push(vm.loadSystemTaxsensitivitydata[index].USER_DESC);
//	    			 
	    			 
	    			 
	    			 var tempobj = {};
	    			 
	    			 
	    			 tempobj.type =  'E';
	    			 tempobj["object_id"]= (rowData != undefined)?rowData.object_id:gridData[0].data.object_id;
	    			 tempobj['tax_year'] = (rowData != undefined)?rowData.TAX_YEAR:gridData[0].data.TAX_YEAR;
	    			 tempobj['scenario'] = GlobalService.globalParams.scenario;
	    			 
	    			 
	    			 
	    			 tempobj["triggerchart"] = vm.loadSystemTaxsensitivitydata[index].TRIGGER_CHART;
	    			 tempobj["triggeracct"] = vm.loadSystemTaxsensitivitydata[index].TRIGGER_ACCT;
	    			 tempobj["postingacct"] = vm.loadSystemTaxsensitivitydata[index].POSTING_ACCT;
	    			 tempobj["postingtac"] = vm.loadSystemTaxsensitivitydata[index].POSTING_TAC;
	    			 tempobj["newtemplatevalue"]= (vm.loadSystemTaxsensitivitydata[index].dropdowndata != undefined)?vm.loadSystemTaxsensitivitydata[index].dropdowndata:vm.loadSystemTaxsensitivitydata[index].oldtemplatevalue;
	    			 tempobj["oldtemplatevalue"] = vm.loadSystemTaxsensitivitydata[index].oldtemplatevalue;
	    			 tempobj["olduserdescvalue"] = vm.loadSystemTaxsensitivitydata[index].olduserdescvalue;
	    			 tempobj["newuserdescvalue"] = vm.loadSystemTaxsensitivitydata[index].USER_DESC;
	    			 tempobj["scdhmid"] = vm.loadSystemTaxsensitivitydata[index].SCHDM_ID;

	    			 taxsensitiveDetails.push(tempobj);
	    			 }
	    		 
	    		 }
	    	 console.log(taxsensitiveDetails);
	    	 
	    	 
	    	 
//	    var returnObj ={};
//           	  
//	    returnObj.type =  'E';
//	    returnObj["object_id"]= (rowData != undefined)?rowData.object_id:gridData[0].data.object_id;
//	    returnObj['tax_year'] = (rowData != undefined)?rowData.TAX_YEAR:gridData[0].data.TAX_YEAR;
//	    returnObj['scenario'] = GlobalService.globalParams.scenario;
//                 
                 
                 
            //    taxsensitiveDetails.push(returnObj);
                var message = "Data has been successfully save";
                console.log(taxsensitiveDetails);
                console.log(message);
                taxsensitiveDetailssend(tempobj,taxsensitiveDetails,message)
                console.log(taxsensitiveDetails);
                console.log(message);
                
               
                
           	  
       	 	}
  
	     function taxsensitiveDetailssend(tempobj, loaclacctdes, message) {
	    	 TaxGroupObjectFactory.saveTax(tempobj,
						loaclacctdes).then(
						function(result) {

							if (result.errorMessage
									&& result.errorMessage !== 'null') {
								AlertService.add("error",
										result.errorMessage, 4000);
							} else {
								AlertService.add("success", message, 4000);
								
								 for(var index = 0;index <vm.loadSystemTaxsensitivitydata.length;index++ ){
						    		
						    		 vm.loadSystemTaxsensitivitydata[index].isEditable = false;
						    		 vm.loadSystemTaxsensitivitydata[index].saveFlag = false;
						    	 }
						    	 
								     

							}
						});

			}
       }
   return controllers;

});
