define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.qrevarianceYoYAutomation',[])
       .factory("qreYOYServiceFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
           ,'$q', '$http','$stateParams', 'USER_SETTINGS' ,'$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                   ,$q, $http,$stateParams,USER_SETTINGS, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var userSettings = USER_SETTINGS;
			   var logged_in_user = userSettings.user.sso_id;
	           var service = $injector.get('JsonObjectFactory');
	           var qreYOYServiceFactory ={};
			   var url ={};
			 //  var url = GENERAL_CONFIG.base_url  + "/saveJsonObject?action_code=yqrl4y";
	           
			   
			   factory.saveQreSignOff = function(_callName , _data){
	               
	               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                   var rowData = _data.rowData;
	               var jsonObj = [];
	               var jsonSettings = [];

	               jsonObj = [{
	            	   sso_id : logged_in_user,
	                //   tax_year: GlobalService.globalParams.tax_year,
	                  // scenario: GlobalService.globalParams.scenario,
	                  // jcd_key: GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
	                   me_key : _data.rowData.CY_ME_KEY,
	            	   sign_off: _data.rowData.SIGNOFF  === 0 ? 'N' :  'Y'
	            	   //comments : _data.rowData.EXPLANATION
	                  // illegal_payments:_data.rowData.ILLEGAL_PAYMENTS
	               }];
	               jsonSettings = [{
	                   
	                   "sso_id" : logged_in_user,
	                   "tax_year": GlobalService.globalParams.tax_year,
	                   "scenario": GlobalService.globalParams.scenario,
	                   "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
	                   "me_key" : _data.rowData.CY_ME_KEY
//	                   "sign_off" : _data.rowData.SIGNOFF  === 0 ? 'N' :  'Y',
//	                   "sign_off" : _data.rowData.SIGNOFF  === 0 ? 'N' :  'Y',
	                		   
	                		   
	               }];
	  	           
	               var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var params = _.merge({}, GlobalService.globalParams, filterParams);

	               params = _.extend({jsonSettings: JSON.stringify(jsonSettings) }, params);
	               params = _.extend({ jsonObj: JSON.stringify(jsonObj)}, params);
	  	           
	  	         var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=yqrl4y";
	  	           
	  	          
	  	          	  service.saveJSON(url , params).then(function(data){
	                  if(data.callSuccess === "1"){
	                      AlertService.add("success", "Data saved Successfully.",4000);
	                      var args = {
	                    		  cy_me_key: _data.rowData.CY_ME_KEY,
                                  gridFilter: {
                                	  cy_me_key: _data.rowData.CY_ME_KEY
                                  }
                              };
                              $rootScope.$emit('gridUpdate', args);//$rootScope.$emit('gridUpdate', args);
	                  }else{
//                          rowData.ILLEGAL_PAYMENTS = null;
//                          rowData.SANCTIONED_COUNTRY = null;
                          AlertService.add("error", data.errorMessage, 4000);
                          $rootScope.$broadcast('gridRefresh', true);
	                      //AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
	                  }
	              });
	            }
			   
			   
			   
			   factory.saveQreComments = function(_callName , _data){
	               
	               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                   var rowData = _data.rowData;
	               var jsonObj = [];
	               var jsonSettings = [];

	               jsonObj = [{
	            	   sso_id : logged_in_user,
		               me_key : _data.rowData.CY_ME_KEY,
	            	   //sign_off: _data.rowData.SIGNOFF  === 0 ? 'N' :  'Y'
	            	   comments : _data.rowData.EXPLANATION
	                  // illegal_payments:_data.rowData.ILLEGAL_PAYMENTS
	               }];
	               jsonSettings = [{
	                   
	                   "sso_id" : logged_in_user,
	                   "tax_year": GlobalService.globalParams.tax_year,
	                   "scenario": GlobalService.globalParams.scenario,
	                   "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
	                   "me_key" : _data.rowData.CY_ME_KEY
//	                   "sign_off" : _data.rowData.SIGNOFF  === 0 ? 'N' :  'Y',
//	                   "sign_off" : _data.rowData.SIGNOFF  === 0 ? 'N' :  'Y',
	                		   
	                		   
	               }];
	  	           
	               var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var params = _.merge({}, GlobalService.globalParams, filterParams);

	               params = _.extend({jsonSettings: JSON.stringify(jsonSettings) }, params);
	               params = _.extend({ jsonObj: JSON.stringify(jsonObj)}, params);
	  	           
	  	          var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ogj5w1";
	  	           
	  	          
	  	          	  service.saveJSON(url , params).then(function(data){
	                  if(data.callSuccess === "1"){
	                      AlertService.add("success", "Data saved Successfully.",4000);
	                      var args = {
	                    		  cy_me_key: _data.rowData.CY_ME_KEY,
                                  gridFilter: {
                                	  cy_me_key: _data.rowData.CY_ME_KEY
                                  }
                              };
                              $rootScope.$emit('gridUpdate', args);//$rootScope.$emit('gridUpdate', args);
	                  }else{
//                          rowData.ILLEGAL_PAYMENTS = null;
//                          rowData.SANCTIONED_COUNTRY = null;
                          AlertService.add("error", data.errorMessage, 4000);
                          $rootScope.$broadcast('gridRefresh', true);
	                      //AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
	                  }
	              });
	            }


	            return factory;
	        }])

	    return services;
	});

    	 