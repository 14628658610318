define([
    'angular'
], function () {
    'use strict';
    angular.module('app.CopyLegacyFilesController', [])
        .controller('CopyLegacyFilesCtrl', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'CopyLegacyFilesFactory', 'AlertService', CopyLegacyFilesCtrl]);

    function CopyLegacyFilesCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, CopyLegacyFilesFactory, AlertService) {
        var vm = this;

        vm.taxYears = [];
        vm.selectedTaxYear = '';
        vm.copyingLegacyFiles = false;

        vm.loadTaxyears = function () {
            CopyLegacyFilesFactory.getTaxYears().then(
                (response) => {
                    if (response.data && response.data.callSuccess === '1') {
                        vm.taxYears = response.data.jsonObject;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }
            );
        }

        vm.moveLegacyFilestoS3 = function () {
            if (!vm.selectedTaxYear.text) {
                return;
            }
            vm.copyingLegacyFiles = true;
            CopyLegacyFilesFactory.moveLegacyFilestoS3(vm.selectedTaxYear.text).then(
                (response) => {
                    if (response && response.data && response.data.callSuccess === '1') {
                        AlertService.add('success', 'Legacy Files Copied Successfully!', 4000);
                        vm.copyingLegacyFiles = false;
                    } else {
                        throw new Error(response.data.errorMessage);
                    }
                })
                .catch((err) => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                    vm.copyingLegacyFiles = false;
                }
            );
        }

        vm.loadTaxyears();

    }
}
);
