
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.DocumentationService',[])

    .factory('DocumentationFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var documentationFactory = {};
        documentationFactory.url = {};
        documentationFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=6s61vm";       
          
        documentationFactory.saveData = function(_settings, _data){
        		var params = {};
        		var jsonObj = JSON.stringify(_data);
                var jsonSettings =  JSON.stringify(_settings);
              
        		params = _.merge({},  _settings, params);
        		params = _.merge({},  _data[0], params);
        		params = _.extend({clob_data:jsonObj}, params);
        		params = _.extend({clob_settings:jsonSettings}, params);
        		console.log("saveConfig......params::",params);        
        		
        		var promise = $http({
                    method: "post",
                    url: documentationFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
        	
        	return promise;
        } 
        
        return documentationFactory;
    }])

    return services;

});