define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.irs4797Controller',[])
        .controller('Irs4797adjController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData' ,'JsonObjectFactory','GENERAL_CONFIG','Irs4797Factory', irs4797adjController]);



    function irs4797adjController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, JsonObjectFactory,GENERAL_CONFIG,Irs4797Factory){

    	console.log("Inside 4797Controller");
    	$scope.title = "Irs4797 Adjustments";
    	$scope.rowData = rowData;
    	$scope.accounts = [];
    	$scope.existingRowsArray = [];
    	$scope.existingRowsArrayL10 = [];
    	$scope.existingRowsArrayL19 = [];
    	
    	$scope.dataLoadAllLines = [];

    	
    	$scope.part1gainLossTotal = 0;
    	$scope.part2gainLossTotal = 0;
    	$scope.part3gainLossTotal = 0;
    	$scope.gainLossTotal = 0;
    	$scope.show4797Form = true;
    	//CodeComboFactory.toggle=false;
    	$scope.showRow = false;
    	$scope.showExistingRow=false;
    	$scope.showExistingRowL10 = false;
    	$scope.showExistingRowL19 = false;
    	
    	$scope.scenario = GlobalService.globalParams.SCENARIO;
    	$scope.taxYear = GlobalService.globalParams.TAX_YEAR;
    	$scope.jcd = GlobalService.globalParams.JCD_KEY;

        function init(){
        	
        	if( $scope.form4797 != undefined ){
                $scope.form4797.$setPristine();
                $scope.form4797.$setUntouched();
            }
        	
           	$scope.accounts = [];

           	if( $scope.rowData.COMBINATION_KEY  ) {

                console.log("yyyyyyyy $scope.rowData.COMBINATION_KEY  FOUND " , $scope.rowData.COMBINATION_KEY );

                CodeComboFactory.setCombinationCodeByKey($scope.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                CodeComboFactory.showFormBtn = false;
                console.log( $scope.rowData.COMBINATION_KEY );

           	}else{

                console.log("nnnnnnnnnnnnnnnnn $scope.rowData.COMBINATION_KEY NOT FOUND " , $scope.rowData.COMBINATION_KEY );
                CodeComboFactory.toggle = true;
            }
           	
           	loadFormData();
           	console.log("XXXXXXXXXXXX$scope.existingRowsArray",$scope.existingRowsArray);
           	$scope.showExistingRow = true;
           	
        }//end init
        
        init();
       
        
        
        $scope.removeAccount = function(_index){
            $scope.accounts.splice(_index, 1);
            $scope.set4797GLTotal();
            $scope.showRow= showRowFlag();
        }
        
        $scope.set4797GLTotal = function(){
        	console.log("Setting  4797GLTotal");
            var total = 0;
            angular.forEach($scope.existingRowsArray, function(value, key) {
                if( value.GAIN_LOSS && !isNaN(value.GAIN_LOSS)){
                    total +=  parseFloat(value.GAIN_LOSS);
                }
            });
            
            $scope.part1gainLossTotal = total;
            total = 0;
            angular.forEach($scope.existingRowsArrayL10, function(value, key) {
                if( value.GAIN_LOSS && !isNaN(value.GAIN_LOSS)){
                    total +=  parseFloat(value.GAIN_LOSS);
                }
            });//should have just single item in list.
            $scope.part2gainLossTotal = total;
            
            total = 0;
            angular.forEach($scope.existingRowsArrayL19, function(value, key) {
                if( value.GAIN_LOSS && !isNaN(value.GAIN_LOSS)){
                    total +=  parseFloat(value.GAIN_LOSS);
                }
            });//should have just single item in list.
            $scope.part3gainLossTotal = total;
            
            
            $scope.gainLossTotal = $scope.part1gainLossTotal+$scope.part2gainLossTotal+$scope.part3gainLossTotal;
            console.log ("Gain Loss Total P1 ="+$scope.part1gainLossTotal);
            console.log ("Gain Loss Total P2 = "+$scope.part2gainLossTotal);
            console.log ("Gain Loss Total P3 = "+$scope.part3gainLossTotal);
            console.log ("Gain Loss Total="+$scope.gainLossTotal);
        }
        
        function showRowFlag () {
        	
        	if ($scope.accounts.length > 0)
        		return true;
        	else
        		return false;
        	
        }//end showRowFlag
        
        
 function showExistingRowFlag () {
        	
        	if ($scope.existingRowsArray.length > 0)
        		return true;
        	else
        		return false;
        	
        }//end showExistingRowFlag
        
        function showExistingRowL10Flag() {
        	
        	if ($scope.existingRowsArrayL10.length > 0)
        		return true;
        	else
        		return false;
        	
        }//end showExistingRowL10Flag
        
        function showExistingRowL19Flag() {
        	
        	if ($scope.existingRowsArrayL19.length > 0)
        		return true;
        	else
        		return false;
        	
        }//end showExistingRowL19Flag
        

        $scope.addTargetAccount = function(){
            var targetAcct = {             
                "PROPERTY_DESC": null,
                "DATE_ACQ": null,
                "DATE_SOLD": null,
                "SALE_PRICE": null,
                "DEPRECIATION_ALLOWED": null,
                "COST_BASIS": null,
                "GAIN_LOSS": null,
                "WORTHLESS_FLAG": null,
                "IS_SEC_754": null,
                "LINE_NO" : 2,//Line 2 Default
                "TYPE":"blank"
            }
            $scope.accounts = []; //reinitialize
                $scope.accounts.push(targetAcct);
            $scope.showRow=showRowFlag();
            console.log ("Added Target Account");
        }//end addTargetAccount
        
        
        $scope.addTargetAccountL10 = function(){
            var targetAcct = {             
                "PROPERTY_DESC": null,
                "DATE_ACQ": null,
                "DATE_SOLD": null,
                "SALE_PRICE": null,
                "DEPRECIATION_ALLOWED": null,
                "COST_BASIS": null,
                "GAIN_LOSS": null,
                "WORTHLESS_FLAG": null,
                "IS_SEC_754": null,
                "LINE_NO" : 10,
                "TYPE":"blank"
            }
            $scope.accounts = []; //reinitialize
                $scope.accounts.push(targetAcct);
                $scope.showRow=showRowFlag();
        }//end addTargetAccountL10
        
        $scope.addTargetAccountL19 = function(){
            var targetAcct = {             
                "PROPERTY_DESC": null,
                "DATE_ACQ": null,
                "DATE_SOLD": null,
                "SALE_PRICE": null,
                "DEPRECIATION_ALLOWED": null,
                "COST_BASIS": null,
                "GAIN_LOSS": null,
                "WORTHLESS_FLAG": null,
                "IS_SEC_754": null,
                "LINE_NO" : 19,
                "TYPE":"blank"
            }
            $scope.accounts = []; //reinitialize
                $scope.accounts.push(targetAcct);
                $scope.showRow=showRowFlag();
        }//end addTargetAccountL19
        
        $scope.loadExistingRowsArray = function () {
        	angular.forEach($scope.accounts, function(value, key) {
        		console.log("value.LINE_NO"+value.LINE_NO);
            	console.log("value.TYPE"+value.TYPE);
            	
        		if(value.LINE_NO == 2){//default
        			$scope.loadExistingRowsArrayL2(value);
        		}
        		else if(value.LINE_NO == 10){
        			$scope.loadExistingRowsArrayL10(value);
        		}
        		else if(value.LINE_NO == 19){
        			$scope.loadExistingRowsArrayL19(value);
        		}
        	});
        	
        }//end loadExistingRowsArray
        
        
        $scope.loadExistingRowsFromDB = function () {
        	$scope.existingRowsArray =[]; //reset Line 2 existing
        	angular.forEach($scope.dataLoadAllLines, function(value, key) {
        		console.log("value.LINE_NO"+value.LINE_NO);
            	console.log("value.TYPE"+value.TYPE);
            	var line2cnt=0;
        		if(value.LINE_NO == 2){//default
        			$scope.loadExistingRowsFromDBL2(value,line2cnt);
        			line2cnt++;
        		}
        		else if(value.LINE_NO == 10){
        			//$scope.loadExistingRowsArrayL10(value);
        		}
        		else if(value.LINE_NO == 19){
        			//$scope.loadExistingRowsArrayL19(value);
        		}
        		 $scope.set4797GLTotal();
        	});
        	
        }//end loadExistingRowsFromDB
        
        $scope.loadExistingRowsFromDBL2 = function (value,_index) {            	
        	console.log("loadExistingRowsFromDBL2 ^^^^^ value.GAIN_LOSS"+value.GAIN_LOSS)
                	var newRow = {             
                			"PROPERTY_DESC": value.PROPERTY_DESC,
                            "DATE_ACQ": value.DATE_ACQ,
                            "DATE_SOLD": value.DATE_SOLD,
                            "SALE_PRICE": value.SALE_PRICE,
                            "DEPRECIATION_ALLOWED": value.DEPRECIATION_ALLOWED,
                            "COST_BASIS": value.COST_BASIS,
                            "GAIN_LOSS": value.GAIN_LOSS,
                            "WORTHLESS_FLAG": value.WORTHLESS_FLAG=="Y"?true:false,
                            "IS_SEC_754": value.IS_SEC_754=="Y"?true:false,
                            "LINE_NO" : 2,
                            "COMBINATION_KEY" : value.COMBINATION_KEY,
                            "TRANS_DETAILS_KEY" : value.TRANS_DETAILS_KEY,
                            "OCCURENCE" : value.OCCURENCE,
                            "TYPE":"U", //?
                            "INDEX": _index
                	}        				
                		$scope.existingRowsArray.push(newRow);  
            
                	$scope.showExistingRow = showExistingRowFlag();
        }//end loadExistingRowsFromDBL2
        
        $scope.loadExistingRowsArrayL2 = function (value) {            	
        	console.log("loadExistingRowsArrayL2 ^^^^^ value.GAIN_LOSS"+value.GAIN_LOSS);
        	console.log("loadExistingRowsArrayL2 ^^^^^ value.WORTHLESS_FLAG"+value.WORTHLESS_FLAG);
        	console.log("loadExistingRowsArrayL2 ^^^^^ value.IS_SEC_754"+value.IS_SEC_754);
                	var newRow = {             
                			"PROPERTY_DESC": value.PROPERTY_DESC,
                            "DATE_ACQ": value.DATE_ACQ,
                            "DATE_SOLD": value.DATE_SOLD,
                            "SALE_PRICE": value.SALE_PRICE,
                            "DEPRECIATION_ALLOWED": value.DEPRECIATION_ALLOWED,
                            "COST_BASIS": value.COST_BASIS,
                            "GAIN_LOSS": value.GAIN_LOSS,
                            "WORTHLESS_FLAG": value.WORTHLESS_FLAG,
                            "IS_SEC_754": value.IS_SEC_754,
                            "LINE_NO" : 2,
                            "COMBINATION_KEY" : value.COMBINATION_KEY,
                            "TRANS_DETAILS_KEY" : value.TRANS_DETAILS_KEY,
                            "OCCURENCE" : value.OCCURENCE,
                            "TYPE":value.TYPE, //?
                            "INDEX": value.TYPE == 'blank'? $scope.existingRowsArray.length: value.INDEX,
                	}
                	if(value.TYPE != 'U'){
                		$scope.existingRowsArray.push(newRow);
                	}
            	else {
            			$scope.existingRowsArray[value.INDEX] = newRow;
            		}  
            
                	$scope.showExistingRow = showExistingRowFlag();
        }//end loadExistingRowsArrayL2
        
        $scope.loadExistingRowsArrayL10 = function (value) {
        	
                        	var newRow = {             
                			"PROPERTY_DESC": value.PROPERTY_DESC,
                            "DATE_ACQ": value.DATE_ACQ,
                            "DATE_SOLD": value.DATE_SOLD,
                            "SALE_PRICE": value.SALE_PRICE,
                            "DEPRECIATION_ALLOWED": value.DEPRECIATION_ALLOWED,
                            "COST_BASIS": value.COST_BASIS,
                            "GAIN_LOSS": value.GAIN_LOSS,
                            "WORTHLESS_FLAG": value.WORTHLESS_FLAG,
                            "IS_SEC_754": value.IS_SEC_754,
                            "LINE_NO" : 10,
                            "TYPE":value.TYPE, //?
                            "UPDATED_ON": "AUG 8 2016",
                            "INDEX": value.TYPE == 'blank'? $scope.existingRowsArrayL10.length: value.INDEX,
                            "UPDATED_BY": "MS"
                	}
                	if(value.TYPE != 'U'){
                		$scope.existingRowsArrayL10.push(newRow);
                	}
            	else {
            			$scope.existingRowsArrayL10[value.INDEX] = newRow;
            		}  
          $scope.showExistingRowL10 = showExistingRowL10Flag();
        }//end loadExistingRowsArrayL10
        
        $scope.loadExistingRowsArrayL19 = function (value) {
        	
        	var newRow = {             
			"PROPERTY_DESC": value.PROPERTY_DESC,
            "DATE_ACQ": value.DATE_ACQ,
            "DATE_SOLD": value.DATE_SOLD,
            "SALE_PRICE": value.SALE_PRICE,
            "DEPRECIATION_ALLOWED": value.DEPRECIATION_ALLOWED,
            "COST_BASIS": value.COST_BASIS,
            "GAIN_LOSS": value.GAIN_LOSS,
            "WORTHLESS_FLAG": value.WORTHLESS_FLAG,
            "IS_SEC_754": value.IS_SEC_754,
            "LINE_NO" : 19,
            "TYPE":value.TYPE, //?
            "UPDATED_ON": "AUG 8 2016",
            "INDEX": value.TYPE == 'blank'? $scope.existingRowsArrayL19.length: value.INDEX,
            "UPDATED_BY": "MS"
	}
	if(value.TYPE != 'U'){
		$scope.existingRowsArrayL19.push(newRow);
	}
else {
		$scope.existingRowsArrayL19[value.INDEX] = newRow;
	}  
$scope.showExistingRowL19 = showExistingRowL19Flag();
}//end loadExistingRowsArrayL19

        $scope.reset = function () {
            init();
        }


        $scope.load4797Row = function(_index){        	
        	//init();
        	showExistingRowFlag();
            var data = $scope.existingRowsArray[_index];
            console.log("load4797Row ^^^^^ data.GAIN_LOSS"+data.GAIN_LOSS)
            
            var targetRow = {             
                    "PROPERTY_DESC": data.PROPERTY_DESC,
                    "DATE_ACQ": data.DATE_ACQ,
                    "DATE_SOLD": data.DATE_SOLD,
                    "SALE_PRICE": data.SALE_PRICE,
                    "DEPRECIATION_ALLOWED": data.DEPRECIATION_ALLOWED,
                    "COST_BASIS": data.COST_BASIS,
                    "GAIN_LOSS": data.GAIN_LOSS,
                    "WORTHLESS_FLAG": data.WORTHLESS_FLAG,
                    "IS_SEC_754": data.IS_SEC_754,
                    "LINE_NO" : data.LINE_NO,
                    "COMBINATION_KEY" : data.COMBINATION_KEY,
                    "TRANS_DETAILS_KEY" : data.TRANS_DETAILS_KEY,
                    "OCCURENCE" : data.OCCURENCE,
                    "TYPE":'U', //set up row for edit
                    "INDEX" : _index
                }
                $scope.accounts = []; //reinitialize
                    $scope.accounts.push(targetRow);
                $scope.showRow=showRowFlag();     
                $scope.set4797GLTotal();
        }//end load4797Row
        
        $scope.load4797RowL10 = function(_index){
        	init();
            var data = $scope.existingRowsArrayL10[_index];
            
            var targetRow = {             
                    "PROPERTY_DESC": data.PROPERTY_DESC,
                    "DATE_ACQ": data.DATE_ACQ,
                    "DATE_SOLD": data.DATE_SOLD,
                    "SALE_PRICE": data.SALE_PRICE,
                    "DEPRECIATION_ALLOWED": data.DEPRECIATION_ALLOWED,
                    "COST_BASIS": data.COST_BASIS,
                    "GAIN_LOSS": data.GAIN_LOSS,
                    "WORTHLESS_FLAG": data.WORTHLESS_FLAG,
                    "IS_SEC_754": data.IS_SEC_754,
                    "LINE_NO" : data.LINE_NO,
                    "TYPE":'U', //set up row for edit
                    "INDEX" : data.INDEX
                }
                $scope.accounts = []; //reinitialize
                    $scope.accounts.push(targetRow);
                    $scope.showRow=showRowFlag();       
                $scope.set4797GLTotal();
        }//end load4797RowL10
        
        $scope.load4797RowL19 = function(_index){
        	init();
            var data = $scope.existingRowsArrayL19[_index];
            
            var targetRow = {             
                    "PROPERTY_DESC": data.PROPERTY_DESC,
                    "DATE_ACQ": data.DATE_ACQ,
                    "DATE_SOLD": data.DATE_SOLD,
                    "SALE_PRICE": data.SALE_PRICE,
                    "DEPRECIATION_ALLOWED": data.DEPRECIATION_ALLOWED,
                    "COST_BASIS": data.COST_BASIS,
                    "GAIN_LOSS": data.GAIN_LOSS,
                    "WORTHLESS_FLAG": data.WORTHLESS_FLAG,
                    "IS_SEC_754": data.IS_SEC_754,
                    "LINE_NO" : data.LINE_NO,
                    "TYPE":'U', //set up row for edit
                    "INDEX" : data.INDEX
                }
                $scope.accounts = []; //reinitialize
                    $scope.accounts.push(targetRow);
                    $scope.showRow=showRowFlag();       
                $scope.set4797GLTotal();
        }//end load4797RowL19
        
        $scope.showDetails = function(_index,_boolean){
            $scope.existingRowsArray[_index].open = _boolean;
        }
        
        $scope.showDetailsL10 = function(_index,_boolean){
            $scope.existingRowsArrayL10[_index].open = _boolean;
        }
        
        $scope.showDetailsL19 = function(_index,_boolean){
            $scope.existingRowsArrayL19[_index].open = _boolean;
        }
        
        $scope.delete4797Row = function(_index){
        	$scope.existingRowsArray.splice(_index, 1);
        	$scope.set4797GLTotal();
        	$scope.showExistingRow = showExistingRowFlag();
        }
        
        $scope.delete4797RowL10 = function(_index){
        	$scope.existingRowsArrayL10.splice(_index, 1);
        	$scope.set4797GLTotal();
        	$scope.showExistingRowL10 = showExistingRowL10Flag();
        }
        
        $scope.delete4797RowL19 = function(_index){
        	$scope.existingRowsArrayL19.splice(_index, 1);
        	$scope.set4797GLTotal();
        	$scope.showExistingRowL19 = showExistingRowL19Flag();
        }
        
        $scope.save = function () {
        	  if ($scope.form4797.$invalid) {
                  $scope.form4797.$submitted = true;
                  AlertService.add("danger", "Please correct the errors below", 4000);
                  return;
              }

              if ( $scope.crudLoading) {
                  AlertService.add("info", "Please wait while we save this request", 4000);
                  return;
              }
              
            
              AlertService.add("info", "Please wait while we save this request", 4000);
              
              $scope.loadExistingRowsArray();
              
              //INSERT\UPDATE TO DB
              //First collate all Arrays..
              var existingRowsArrayAllLcl = $scope.existingRowsArray;
              //existingRowsArrayAllLcl = existingRowsArrayAllLcl.concat($scope.existingRowsArrayL10).concat($scope.existingRowsArrayL19); TODO --fix!
              console.log ("existingRowsArrayAllLcl "+existingRowsArrayAllLcl);
              $scope.form4797Save(existingRowsArrayAllLcl);
              
              $scope.set4797GLTotal();
              
              //$scope.accounts = [];
              $scope.addTargetAccount(); //add empty Row on top.
              $scope.showRow= showRowFlag();
             		
        };
        
        $scope.form4797Save = function (_data) {
        	
            var url = GENERAL_CONFIG.base_url + "/saveFedForm?process_name=F4797Save";
             //unpivot
            var fin_data = unpivotData(_data);
            
               JsonObjectFactory.saveJSONObj(url,fin_data).then(function (response) {
            	   if(response == 1)
            		   {
            		   		//success..
            		   		loadFormData();
            		   }
            	   else
            		   {
            		   		//failed
            		   
            		   }
               })
               
        	//alert("Saved!");
       };
       
       function unpivotData(data)
       {   
    	   console.log("$scope.taxYear"+$scope.taxYear);
    	   console.log("$scope.scenario"+$scope.scenario);
    	   console.log("$scope.jcd"+$scope.jcd);
    	   //check why not showing..else hardcode for now.
    	   
    	   var unpivotedDataArr = []; 
    	   angular.forEach(data, function(value, key) {
    		   
    		   //figure out whether the row is for update or insert.
    		   var trans_details_key = value.TRANS_DETAILS_KEY;
    		   var occurence = value.OCCURENCE;
    		   angular.forEach(value, function(value1, key1) {  
    			   
    			  var value2 = prepareForSave(key1,value1);
    			   //console.log("&&&&&&&&&&&&&&&& value2: "+value2);
    			   var targetRow = {    					   
    					  // "row_change_indicator" : 1,
    					   "tax_year":"2014", //$scope.taxYear,
    					   "scenario":"17", //$scope.scenario,
    					   "jcd_key" :"250", // $scope.jcd,
    					   "trans_type_key" : 7,
    					   "combination_key" :$scope.rowData.COMBINATION_KEY,
    					   "group_obj_key" :201447,
    					   "form_key" :1,
    					   "line_no" :2,//TODO
    					   "occurence" :occurence,
    					   "Attribute_Name" : key1,
    					   "Attribute_Value" : value2,
    					   "edit_status" : "I",
    					   "trans_dtls_key" : trans_details_key,
    					   "trans_status" : "A"    					 
    			   		}
    			   	unpivotedDataArr.push(targetRow);
    		   		}//end iter fn
    		      );    	        
    		   	 //unpivotedDataArr.push(unpivotedRowArr);
    		   	}//end iter fn
    		  );//end forEach
    	   return unpivotedDataArr; 
       }//end unpivotData
       
       function prepareForSave(_key,_value){
    	   var val = _value;
    	   //checkboxes need to be processed
    	   if(_key == "WORTHLESS_FLAG" || _key == "IS_SEC_754")
    		   {
    		   		//console.log("&&&&&&&&&&&&&&&& _key: "+_key+" ^^^^^^^^^_value: "+_value);
    		   		val = _value == true ? "Y":"N";
    		   }
    	   return val;
       }//end prepareForSave
       
       function loadFormData() {
    	   
         var _url = GENERAL_CONFIG.base_url+"/loadJsonPivotList";
         var params = {
                 "combination_key":$scope.rowData.COMBINATION_KEY,
                 "action_id": "46",
                 "tax_year":GlobalService.globalParams.TAX_YEAR,
                 "scenario":GlobalService.globalParams.SCENARIO,
                 "jcd_key":GlobalService.globalParams.JCD_KEY,
                 "bsla_keys":"0",
                 "form_no":"F4797"
             };

         console.log("Within loadFormData..",_url);
         //var canceler = $q.defer();

           var promise = $http({
               method: "post",
               url: _url,
               data: params
               //timeout: canceler.promise
           }).then(function (response) {
        	  console.log("load pivot data",JSON.stringify(response.data.pivotedJsonObj[1].DATA));
        	  //$scope.existingRowsArray =  response.data.pivotedJsonObj[1].DATA;
        	  $scope.dataLoadAllLines = response.data.pivotedJsonObj[1].DATA;
        	  $scope.loadExistingRowsFromDB();        	  

        	   showExistingRowFlag();
        	   
           });  
           
           return promise;   	   
       }//end loadFormData  

       $scope.isChecked = function (flag) {
           if(flag == true || flag == 'Y')
        	   return true;
           //else
           return false;
       };
       

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        
        $scope.addTargetAccount();

    }///////////////END CTRL

    return controllers;


});