define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.FormScheduleM2Ctrl', [])
					.controller('F1120RetainEarningsCtrl', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','FormScheduleM2ServiceFactory', 'rowData', 'gridData', F1120RetainEarningsCtrl])

			
			function F1120RetainEarningsCtrl($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,FormScheduleM2ServiceFactory,rowData, gridData) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.retainEarningsObj = {
						otherIncreases:[],
						otherDecreases:[]
				};
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year;
				vm.scenario  = GlobalService.globalParams.scenario;
				vm.globalScenarioDesc	= workspaceFactory.activeScreen.dataCurFilters[1].valueLabel;
				vm.glbl_issue_short_desc = GlobalService.globalParams.issue_short_desc;
				vm.glbl_issue_id  = GlobalService.globalParams.issue_id;
				vm.local_issue_id  = '';
				vm.isValidScenario = false;
				vm.isSaveClicked = false;
				vm.scheduleM2List = [];
				vm.scheduleM2Through4List = [];
				vm.scheduleM2Through8List = [];
				vm.scheduleM2OtherList = [];
				vm.scheduleM2HiddenList = [];
				
				vm.tabName = "ScheduleM2";
				
				// When set to true, a loading icon will show for these
				vm.showDiagLoader = true;
				vm.batchLoader = true;
				vm.showBatchLoader = true;
				vm.loading = true;
				vm.saving = false;
				
				// When false, transactions will not be shown as a table in the Audit tab
				vm.showBatchData = false;
				
				vm.clickedDiagTab = false;
				
				vm.noRecords = false;
				

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				// Function to get the list of transactions in the Audit tab
				function getAuditSummary() {
					var params = {
						"action_code": "rq79be",
						"tax_year": vm.currentyear,
						"scenario": filterParams.scenario,
						"jcd_key": GlobalService.globalParams.jcd_key,
						"form_name": null,
						"combination_key": rowData.COMBINATION_KEY
					}
					
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						vm.batchList = data.jsonObject;
						
						// Checking if batch list is empty or does not exist
						if (!Array.isArray(vm.batchList) || !vm.batchList.length) {
							vm.noRecords = true;
						}
						
						// Looping through all the transactions to get just the year, month, and day from the date,
						// as well as setting a TOGGLE value to each transaction, initially set to false.
						// When TOGGLE is true, then it means we should show that transaction table
						for (var i = 0; i < vm.batchList.length; i++) {
							var cr_date = vm.batchList[i].CR_DATE;
							var actual_date = cr_date.substring(0, 10);
							vm.batchList[i].CR_DATE = actual_date;
							vm.batchList[i].TOGGLE = false;
						}
						
						// Reversing the list so that the latest transactions appear first
						vm.batchList.reverse();
						
						// Data has been loaded, so the loading icon is not needed
						vm.showBatchLoader = false;
					});
				}
				
				// When the "Schedule M2" tab is clicked
				vm.switchTab = function(input) {
					vm.showDiagLoader = true;
					//vm.batchItem = undefined;
					if (input === 0) {
						vm.toggleTotal = false;
						vm.tabName = "ScheduleM2";
					}
					if (input === 1) {
					    vm.clickedDiagTab = true;
					    vm.tabName = "Details";

					} else if (input === 2) {
						vm.showBatchData = false;
						vm.tabName = "Audit";
						getAuditSummary();
					}
				}
				
				// Function that is called when an info icon is clicked
				// "input" is the line attribute key taken from the view - it will be used in the if statements below
				vm.goToTab2 = function(acct_ref_key, desc, line_no, attrib_value) {
					vm.clickedDiagTab = false;
					
					// This sets the view to the second tab
					vm.active = 1;
					vm.desc = desc;
					vm.line_no = line_no;
					vm.value = attrib_value;
					vm.tabName = "Details";
					
					// Parameters to pass in
					var params = {
							"action_code": 'ena68k',
							"tax_year": vm.currentyear,
							"scenario":  filterParams.scenario,
							"jcd_key": GlobalService.globalParams.jcd_key,
							"acct_ref_key": acct_ref_key,
							"combination_key": rowData.COMBINATION_KEY	
					};
					
					// Getting data here
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					
						var accountData = data.jsonObject;
						
						var formAccount = accountData[0];
						
						// We loop through all the rows and put the system accounts into one array
						// and the local accounts into another array
						var systemAccts = [];
						var localAccts = [];
						for (var i = 0; i < accountData.length; i++) {
							var line = accountData[i];
							if (line.ACCT_LEVEL === 2) {
								systemAccts.push(accountData[i]);
								line.TOGGLE = false;
							} else if (line.ACCT_LEVEL === 3) {
								localAccts.push(accountData[i]);
							}
						}
						
						vm.systemAccts = systemAccts;
						vm.localAccts = localAccts;
						
						vm.showDiagLoader = false;
					});
				}
				
				// Function to show the lines changed in the transaction specified by the "stage_key"
				// "stage_key" is taken from the view when the user clicks "View Transactions"
				vm.showUploads = function(stage_key) {					
					var params = {
						"action_code": "fgiz9j",
						"tax_year": vm.currentyear,
						"scenario":  filterParams.scenario,
						"jcd_key": GlobalService.globalParams.jcd_key,
						"form_name": null,
						"trans_key": stage_key
					}
					
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						var batchItem;
						
						// Looping through the batch list to find the transaction we are interested in
						for (var i = 0; i < vm.batchList.length; i++) {
							if (vm.batchList[i].STAGE_KEY === stage_key) {
								batchItem = vm.batchList[i];
							}
							// We set the TOGGLE property of all of the items to false initially to close other tables
							// that may have been open.
							vm.batchList[i].TOGGLE = false;
						}
						
						// If this is true, then a table was previously open
						if (vm.showBatchData === true) {
							// If this is true, then the table that was previously open is the same as the one
							// that was just clicked. In that case, we set "vm.showBatchData" to false to close
							// the table and then return.
							if (vm.batchItem.STAGE_KEY === batchItem.STAGE_KEY) {
								vm.showBatchData = false;
								return;
							}
						}
						
						// Setting variables to be used in the view
						vm.batchItem = batchItem;
						vm.batchItemData = data.jsonObject;
						
						// Setting a IS_GREYED_OUT property to each line in vm.batchItemData
						// which is true when the property is not an editable cell in the form,
						// and false otherwise.
						for (i = 0; i < vm.batchItemData.length; i++) {
							var line = vm.batchItemData[i];
							var attrib_desc = line.ATTRIB_DESCRIPTION;
							for (var j = 0; j < vm.scheduleM2List.length; j++) {
								var scheduleM2Line = vm.scheduleM2List[j];
								var list_attrib_desc = scheduleM2Line.ATTRIB_DESCRIPTION;
								var is_editable_cell = scheduleM2Line.IS_EDITABLE_CELL;
								
								if (attrib_desc === list_attrib_desc) {
									if (is_editable_cell === 'N') {
										line.IS_GREYED_OUT = true;
									} else {
										line.IS_GREYED_OUT = false;
									}
								}
							}
						}
						
						// Setting these to true so that the respective elements will be shown in the view
						vm.showBatchData = true;
						vm.batchItem.TOGGLE = true;
						
						vm.batchLoader = false;
					});
				}
				
		        function fetchSchM2records() {
		        			        	
		            var params = { 
		            		"action_code": 'qm7vc2', 
		            		"sso_id": vm.logged_in_user, 
		            		"tax_year": vm.currentyear, 
		            		"scenario": filterParams.scenario, 
							"jcd_key": GlobalService.globalParams.jcd_key, 
		            		"combination_key": rowData.COMBINATION_KEY,
//		            		"jcd_ta_key": null
							"issue_key": rowData.ISSUE_KEY
		            		};
		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
		            	
		            	vm.scheduleM2Header = data.jsonObject[0];
		            	vm.scheduleM2List =  data.jsonObject.slice(1);

		            	if(vm.scheduleM2Header.FORM_NAME == 'F1120'){
		            		for(var i=0;i<vm.scheduleM2List.length;i++){
								if(!vm.isValidScenario){
									vm.isValidScenario = vm.checkEditScenario(vm.scheduleM2List[i].T_SCENARIO_TYPE_CODE,  vm.scheduleM2List[i].T_SCENARIO,  vm.scheduleM2List[i].ISSUE_KEY);
								}
								
								if(vm.scheduleM2List[i].LINE_NO <= '4'){
			        				vm.scheduleM2Through4List.push(vm.scheduleM2List[i]);	            
			        			}else if(vm.scheduleM2List[i].LINE_NO == 'N/A'){
			        				vm.scheduleM2OtherList.push(vm.scheduleM2List[i]);
			        			}else{
			        				if(vm.scheduleM2List[i].ATTRIB_SHORT_CODE != 'CASH_AUTOCALC'){
			        					vm.scheduleM2Through8List.push(vm.scheduleM2List[i]);
			        				}else{
			        					vm.scheduleM2HiddenList.push(vm.scheduleM2List[i]);
			        				}
			        			}
			        		}
		            	}else{
		            		for(var i=0;i<vm.scheduleM2List.length;i++){
								if(!vm.isValidScenario){
									vm.isValidScenario = vm.checkEditScenario(vm.scheduleM2List[i].T_SCENARIO_TYPE_CODE,  vm.scheduleM2List[i].T_SCENARIO,  vm.scheduleM2List[i].ISSUE_KEY);
								}
			        			if(vm.scheduleM2List[i].LINE_NO <= '5'){
			        				vm.scheduleM2Through4List.push(vm.scheduleM2List[i]);
			        			}else if(vm.scheduleM2List[i].LINE_NO == 'N/A'){
			        				vm.scheduleM2OtherList.push(vm.scheduleM2List[i]);
			        			}else{
			        				if(vm.scheduleM2List[i].ATTRIB_SHORT_CODE != 'CASH_AUTOCALC'){
			        					vm.scheduleM2Through8List.push(vm.scheduleM2List[i]);
			        				}else{
			        					vm.scheduleM2HiddenList.push(vm.scheduleM2List[i]);
			        				}
			        			}
			        		}
		            	}
		        		
						vm.formListOriginal = angular.copy(vm.scheduleM2List);
						//vm.updateOtherIncreases(vm.retainEarningsObj);
						vm.loading = false;
		            });
		        }

		        fetchSchM2records();

				vm.checkEditScenario = function (s_type_code, t_scenario, t_issue){        	
					if(vm.scenario == t_scenario && ((s_type_code == 'EXAM' && vm.glbl_issue_id == t_issue) || s_type_code != 'EXAM')){
						return true;
					}        	
					return false;        	
				}	
		        
		        
		        vm.change704 = function(row) {
		        	vm.checked704 = row.ATTRIB_VALUE;
		        }
				
				vm.updateOtherIncreases = function(retainEarnings) {
					_.forEach(vm.scheduleM2Through4List, function(a) {
						if((a.ATTRIB_VALUE == undefined || isNaN(a.ATTRIB_VALUE)) && a.IS_EDITABLE_CELL == "Y" && a.DATA_TYPE == "number"){
							a.ATTRIB_VALUE=0;
						}
					});
				
					_.forEach(vm.scheduleM2Through8List, function(a) {
						if((a.ATTRIB_VALUE == undefined || isNaN(a.ATTRIB_VALUE)) && a.IS_EDITABLE_CELL == "Y" && a.DATA_TYPE == "number"){
							a.ATTRIB_VALUE=0;
						}
					});
				
					if(retainEarnings.FORM_NAME == 'F1120'){
						
					
					// Total Increases Line 3
					if(vm.scheduleM2Through4List != null){
						console.log("vm.scheduleM2Through4List:", vm.scheduleM2Through4List);
//						vm.scheduleM2Through4List[13].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[3].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[12].ATTRIB_VALUE));
//						
//						//Total For Subline(Sum only input fields)
//						vm.scheduleM2Through4List[14].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[3].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[12].ATTRIB_VALUE));
//						
						
						vm.scheduleM2Through4List[15].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[3].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[12].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[14].ATTRIB_VALUE));
						
						//Total For Subline(Sum only input fields)
						vm.scheduleM2Through4List[16].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[3].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[12].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[14].ATTRIB_VALUE));
					}
					

					
					// Total  Line 4
//					vm.scheduleM2Through4List[15].ATTRIB_VALUE =  parseInt(vm.scheduleM2Through4List[0].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[1].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[13].ATTRIB_VALUE);
					vm.scheduleM2Through4List[17].ATTRIB_VALUE =  parseInt(vm.scheduleM2Through4List[0].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[1].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[15].ATTRIB_VALUE);
					
					// Total Decreases Line 6
					if(vm.scheduleM2Through8List != null){
//					vm.scheduleM2Through8List[12].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through8List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[6].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through8List[7].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[11].ATTRIB_VALUE));
//						
//					//Total For Subline(Sum only input fields)
//					vm.scheduleM2Through8List[13].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through8List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[6].ATTRIB_VALUE) +
//							parseInt(vm.scheduleM2Through8List[7].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[11].ATTRIB_VALUE));
					
					vm.scheduleM2Through8List[14].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through8List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[6].ATTRIB_VALUE) +
							parseInt(vm.scheduleM2Through8List[7].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[12].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[13].ATTRIB_VALUE));
					
					//Total For Subline(Sum only input fields)
					vm.scheduleM2Through8List[15].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through8List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[5].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[6].ATTRIB_VALUE) +
						parseInt(vm.scheduleM2Through8List[7].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[12].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[13].ATTRIB_VALUE));

					}
					
					// Total Line 7
//					if(type == 'Cash'){
//					vm.scheduleM2Through8List[14].ATTRIB_VALUE = parseInt(vm.scheduleM2Through8List[1].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[2].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[12].ATTRIB_VALUE);
					vm.scheduleM2Through8List[16].ATTRIB_VALUE = parseInt(vm.scheduleM2Through8List[1].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[2].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[14].ATTRIB_VALUE);
//					}
					
					// Total Line 8
//					vm.scheduleM2Through8List[15].ATTRIB_VALUE  = vm.scheduleM2Through4List[15].ATTRIB_VALUE  -  vm.scheduleM2Through8List[14].ATTRIB_VALUE;
					vm.scheduleM2Through8List[17].ATTRIB_VALUE  = vm.scheduleM2Through4List[17].ATTRIB_VALUE  -  vm.scheduleM2Through8List[16].ATTRIB_VALUE;
					
//					vm.scheduleM2OtherList[5].ATTRIB_VALUE  = vm.scheduleM2Through8List[15].ATTRIB_VALUE;
					vm.scheduleM2OtherList[5].ATTRIB_VALUE  = vm.scheduleM2Through8List[17].ATTRIB_VALUE;
					
					vm.scheduleM2OtherList[3].ATTRIB_VALUE  = parseInt(vm.scheduleM2OtherList[1].ATTRIB_VALUE) + parseInt(vm.scheduleM2OtherList[2].ATTRIB_VALUE);
					
					vm.scheduleM2OtherList[6].ATTRIB_VALUE  = parseInt(vm.scheduleM2OtherList[3].ATTRIB_VALUE) - parseInt(vm.scheduleM2OtherList[5].ATTRIB_VALUE);
					
					}else{
						
					//ADDED FOR F1065
					// Total Increases Line 4
					if(vm.scheduleM2Through4List != null){
						console.log("vm.scheduleM2Through4List:", vm.scheduleM2Through4List);
//						vm.scheduleM2Through4List[16].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[12].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through4List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[14].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[15].ATTRIB_VALUE));
//						
//						//Total For Subline(Sum only input fields)
//						vm.scheduleM2Through4List[17].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) +
//								 parseInt(vm.scheduleM2Through4List[14].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[15].ATTRIB_VALUE));
						
						vm.scheduleM2Through4List[18].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[12].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through4List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[14].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[15].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[16].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[17].ATTRIB_VALUE));
						
						//Total For Subline(Sum only input fields)
						vm.scheduleM2Through4List[19].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[6].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[7].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through4List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[11].ATTRIB_VALUE) +
								 parseInt(vm.scheduleM2Through4List[14].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[15].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[16].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[17].ATTRIB_VALUE));
					}
						
					
					// Total  Line 5
//					vm.scheduleM2Through4List[18].ATTRIB_VALUE =  parseInt(vm.scheduleM2Through4List[0].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[2].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[3].ATTRIB_VALUE) +
//					 											  parseInt(vm.scheduleM2Through4List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[16].ATTRIB_VALUE);
					vm.scheduleM2Through4List[20].ATTRIB_VALUE =  parseInt(vm.scheduleM2Through4List[0].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[2].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[3].ATTRIB_VALUE) +
					  											  parseInt(vm.scheduleM2Through4List[4].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through4List[18].ATTRIB_VALUE);
					
					// Total Decreases Line 7
					if(vm.scheduleM2Through8List != null){
//						vm.scheduleM2Through8List[15].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through8List[5].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through8List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[12].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through8List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[14].ATTRIB_VALUE));
//						
//					//Total For Subline(Sum only input fields)
//						vm.scheduleM2Through8List[16].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[5].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through8List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) +
//								parseInt(vm.scheduleM2Through8List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[14].ATTRIB_VALUE));
						
						vm.scheduleM2Through8List[17].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through8List[5].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through8List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[11].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[12].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through8List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[14].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[15].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[16].ATTRIB_VALUE));
						
					//Total For Subline(Sum only input fields)
						vm.scheduleM2Through8List[18].ATTRIB_VALUE = (parseInt(vm.scheduleM2Through4List[5].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through8List[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[9].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[10].ATTRIB_VALUE) +
								parseInt(vm.scheduleM2Through8List[13].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[14].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[15].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[16].ATTRIB_VALUE));

					}
					
					// Total Line 8
//					if(type == 'Cash'){
//					vm.scheduleM2Through8List[17].ATTRIB_VALUE = parseInt(vm.scheduleM2Through8List[1].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[2].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[15].ATTRIB_VALUE);
					vm.scheduleM2Through8List[19].ATTRIB_VALUE = parseInt(vm.scheduleM2Through8List[1].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[2].ATTRIB_VALUE) + parseInt(vm.scheduleM2Through8List[17].ATTRIB_VALUE);
//					}
					
					// Total Line 9
//					vm.scheduleM2Through8List[18].ATTRIB_VALUE  = vm.scheduleM2Through4List[18].ATTRIB_VALUE  -  vm.scheduleM2Through8List[17].ATTRIB_VALUE;
					vm.scheduleM2Through8List[20].ATTRIB_VALUE  = vm.scheduleM2Through4List[20].ATTRIB_VALUE  -  vm.scheduleM2Through8List[19].ATTRIB_VALUE;
					
					
					//Variance beginning year
					vm.scheduleM2OtherList[4].ATTRIB_VALUE  = parseInt(vm.scheduleM2OtherList[2].ATTRIB_VALUE) - parseInt(vm.scheduleM2OtherList[3].ATTRIB_VALUE);
					
					//ADjusted RE per Tax return
//					vm.scheduleM2OtherList[11].ATTRIB_VALUE  = vm.scheduleM2Through8List[18].ATTRIB_VALUE;
					vm.scheduleM2OtherList[11].ATTRIB_VALUE  = vm.scheduleM2Through8List[20].ATTRIB_VALUE;
					
					//ADjusted RE per GL
					vm.scheduleM2OtherList[9].ATTRIB_VALUE  = parseInt(vm.scheduleM2OtherList[8].ATTRIB_VALUE) + parseInt(vm.scheduleM2OtherList[7].ATTRIB_VALUE);
					
					vm.scheduleM2OtherList[12].ATTRIB_VALUE  = parseInt(vm.scheduleM2OtherList[9].ATTRIB_VALUE) - parseInt(vm.scheduleM2OtherList[11].ATTRIB_VALUE);
					
					//704 unallocated variance
					//vm.scheduleM2OtherList[19].ATTRIB_VALUE  = parseInt(vm.scheduleM2OtherList[15].ATTRIB_VALUE) +  parseInt(vm.scheduleM2OtherList[16].ATTRIB_VALUE) +
														//	  parseInt(vm.scheduleM2OtherList[17].ATTRIB_VALUE) + parseInt(vm.scheduleM2OtherList[18].ATTRIB_VALUE);
						
					}
					
				}
				
				//On click of Apply button we are applying Auto CALC Cash Amount
				vm.updateDistributions = function() {
						if(vm.scheduleM2Through8List[1].ATTRIB_DESCRIPTION == 'Cash'){
							vm.scheduleM2Through8List[1].ATTRIB_VALUE = vm.scheduleM2HiddenList[0].ATTRIB_VALUE;
						}
						vm.updateOtherIncreases();
				}
				
				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
								vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect);
								
								vm.retainEarningsObj["taxYear"] = vm.defaultsMultipleSelected[0].rowData.BOY_BALANCE;
								vm.retainEarningsObj["entityName"] = vm.defaultsMultipleSelected[0].rowData.LE_NAME;
								vm.retainEarningsObj["taxCode"] = vm.defaultsMultipleSelected[0].rowData.CDR_NO;
								vm.retainEarningsObj["meName"] = vm.defaultsMultipleSelected[0].rowData.ME_NAME;
								vm.retainEarningsObj["taxYearBegin"] = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_BEGIN, "MM/dd");
								vm.retainEarningsObj["taxYearEnd"] = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_END, "MM/dd/yyyy");
								vm.retainEarningsObj["meCode"] = vm.defaultsMultipleSelected[0].rowData.ME_CODE;
					}

				} 
				
				
				//reset the form
				vm.reset = function(){
					vm.scheduleM2List = angular.copy(vm.formListOriginal);
					vm.scheduleM2Through4List = [];
					vm.scheduleM2Through8List = [];
					vm.scheduleM2OtherList = [];
					vm.scheduleM2HiddenList = [];
					
					if(vm.scheduleM2Header.FORM_NAME == 'F1120'){
						for(var i=0;i<vm.scheduleM2List.length;i++){
		        			if(vm.scheduleM2List[i].LINE_NO <= 4){
		        				vm.scheduleM2Through4List.push(vm.scheduleM2List[i]);
		        			}else if(vm.scheduleM2List[i].LINE_NO == 'N/A'){
		        				vm.scheduleM2OtherList.push(vm.scheduleM2List[i]);
		        			}else{
		        				if(vm.scheduleM2List[i].ATTRIB_SHORT_CODE != 'CASH_AUTOCALC'){
		        					vm.scheduleM2Through8List.push(vm.scheduleM2List[i]);
		        				}else{
		        					vm.scheduleM2HiddenList.push(vm.scheduleM2List[i]);
		        				}
		        			}
		        		}
						vm.scheduleM2OtherList[6].ATTRIB_VALUE  = vm.scheduleM2OtherList[3].ATTRIB_VALUE - vm.scheduleM2OtherList[5].ATTRIB_VALUE;
					}else{
	            		for(var i=0;i<vm.scheduleM2List.length;i++){
		        			if(vm.scheduleM2List[i].LINE_NO <= '5'){
		        				vm.scheduleM2Through4List.push(vm.scheduleM2List[i]);
		        			}else if(vm.scheduleM2List[i].LINE_NO == 'N/A'){
		        				vm.scheduleM2OtherList.push(vm.scheduleM2List[i]);
		        			}else{
		        				if(vm.scheduleM2List[i].ATTRIB_SHORT_CODE != 'CASH_AUTOCALC'){
		        					vm.scheduleM2Through8List.push(vm.scheduleM2List[i]);
		        				}else{
		        					vm.scheduleM2HiddenList.push(vm.scheduleM2List[i]);
		        				}
		        			}
		        		}
	            		vm.scheduleM2OtherList[12].ATTRIB_VALUE  = vm.scheduleM2OtherList[9].ATTRIB_VALUE - vm.scheduleM2OtherList[11].ATTRIB_VALUE;
	            	}
				};
				

				vm.save = function() {
					vm.saving = true;
					vm.isSaveClicked = true;
					
					var returnClobSettingsObj = {};
					var scheduleM2SaveDtls = [];
					vm.scheduleM2List = _.filter(vm.scheduleM2List, function(o) { 
					    return o.IS_EDITABLE_CELL == 'Y'; 
					 })
					for(var key in vm.scheduleM2List){
						var returnObj = {};
						
						returnObj["object_id"] = gridData.rowData.object_id;
						returnObj["issue_key"] = vm.scheduleM2List[key].ISSUE_KEY;
						returnObj['combination_key'] = vm.scheduleM2List[key].COMBINATION_KEY;
						returnObj['form_key'] = vm.scheduleM2List[key].FORM_KEY;
						returnObj['form_line_key'] = vm.scheduleM2List[key].FORM_LINE_KEY;
						returnObj['line_attrib_key'] = vm.scheduleM2List[key].LINE_ATTRIB_KEY;
						returnObj['trans_type_key'] = vm.scheduleM2List[key].TRANS_TYPE_KEY;
						returnObj['attrib_type'] = vm.scheduleM2List[key].ATTRIB_TYPE;
						returnObj['attrib_prop_key'] = vm.scheduleM2List[key].ATTRIB_PROP_KEY;
						returnObj['acct_ref_key'] = vm.scheduleM2List[key].ACCT_REF_KEY;
						returnObj['trans_details_key'] = vm.scheduleM2List[key].TRANS_DETAILS_KEY;
						returnObj['attrib_value'] = vm.scheduleM2List[key].ATTRIB_VALUE;
						returnObj['occurrence'] = (vm.scheduleM2List[key].OCCURRENCE != null) ? parseInt(vm.scheduleM2List[key].OCCURRENCE) : 1;
						var message = "Defaults has been successfully saved/updated";
						scheduleM2SaveDtls.push(returnObj);
						
					}
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['form_name'] = "F1120M-2";
					returnClobSettingsObj['me_code'] = rowData.ME_CODE;

					sendDetails(returnClobSettingsObj,scheduleM2SaveDtls, message);

				};

				function sendDetails(returnClobSettingsObj, scheduleM2SaveDtls, message) {
					FormScheduleM2ServiceFactory.saveSchdM2(returnClobSettingsObj,scheduleM2SaveDtls).then(function(result) {

								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
									 vm.isSaveClicked = false;
									AlertService.add("error", result.data.errorMessage, 4000);
								} else {
									//vm.crudLoading = false;
									AlertService.add("success", message, 4000);
									var args = {
											me_code: returnClobSettingsObj.me_code,
											issue_key:rowData.ISSUE_KEY,
		                                    gridFilter: {
		                                    	me_code: returnClobSettingsObj.me_code,
												issue_key:rowData.ISSUE_KEY,
		                                    }
		                                };
									$uibModalInstance.dismiss('cancel');
									$rootScope.$emit('gridUpdate', args);
                            	};

							});
				}
			}
			return controllers;
		});
