define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.processMapController',[])
        
    .controller('processMapController', ['$scope','$filter','$state','$log','AlertService','$timeout','UserAdminFactory','$window', 'USER_SETTINGS','JsonObjectFactory','GlobalService','SERVER_CONFIG','GENERAL_CONFIG','processMapFactory','$uibModal', processMapController])
    .controller('createTPController', ['$rootScope', '$window', '$scope', 'client_key', '$state', '$http', 'ModalFactory', 'AlertService', 'GlobalService', '$timeout', 'workspaceFactory', 'processMapFactory', '$uibModalInstance', createTPController])
    .controller('createDummyActivityController', ['$rootScope', '$window', '$scope', 'tax_placemats', '$state', '$http', 'ModalFactory', 'AlertService', 'GlobalService', '$timeout', 'workspaceFactory', 'processMapFactory', '$uibModalInstance', createDummyActivityController])
    .controller('reorderProcessController', ['$rootScope', '$window', '$scope', 'mapped_processes', '$state', '$http', 'ModalFactory', 'AlertService', 'GlobalService', '$timeout', 'workspaceFactory', 'processMapFactory', '$uibModalInstance', reorderProcessController])
    function processMapController($scope,$filter,$state,$log,AlertService,$timeout,UserAdminFactory,$window,USER_SETTINGS,JsonObjectFactory,GlobalService,SERVER_CONFIG,GENERAL_CONFIG,processMapFactory,$uibModal) {
    
        var vm = this;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
        vm.client_key = user.client.client_key;
        vm.mappedProcessesList = [];
        vm.ActivityList = [];
        vm.original_tasksList = [];
        vm.original_mappProcessedList = [];
        vm.current_process = '';
        vm.defaultClient = false;
        vm.showBlock = false;
        vm.showProcLoader = true;
        vm.savebtn = true;
        vm.saveloader = false;
        vm.createProcBlock = false;
        vm.prevIndex = '';
        vm.processName = '';
        vm.taxPlacements = [];
        vm.taxIdLoader = true;
        vm.newTaxPlacemat = false;
        vm.is_dummy = false;
        vm.emptyProcess = '';
        
        function init(){
            if(vm.client_key != 'undefined' || vm.client_key !=null){
                vm.getTaxPlacematsByClientKey(vm.client_key);
            };
        };

        vm.getTaxPlacematsByClientKey = function(client_key, tx_name){
            processMapFactory.fetchTaxPlacemats(client_key).then(function (data) {
                if(data.callSuccess === "1" ){
                    vm.taxIdLoader = false;
                    vm.taxPlacements = data.data;
                    if(tx_name !== undefined){
                        angular.forEach(vm.taxPlacements, function(taxId, index) { 
                            if(taxId.placematName == tx_name){
                                vm.tax_id = vm.taxPlacements[index];
                            }
                        });
                        vm.getData();
                    }else{
                        vm.tax_id = vm.taxPlacements[0];
                        vm.getMappedProcessesByClientKey(vm.client_key, vm.tax_id.placematId);
                        vm.getTasksHierarchyByClientKey(vm.client_key);
                    } 
                }
            });
        };
        
        vm.getData = function(){
            vm.newTaxPlacemat = false;
            vm.defaultClient = false;
            vm.showBlock = false;
            vm.showProcLoader = true;
            vm.getMappedProcessesByClientKey(vm.client_key, vm.tax_id.placematId);
        };

        vm.createType = function(type){
            vm[type] = true;
            vm.defaultClient = false;
        };

        vm.cancelType = function (type, arr) {
            vm[type] = false;
            angular.forEach(arr, function(prop, index){
                vm[arr[index]] = '';
            });
        };

        vm.addProc = function(procName){
            if (vm.createProcess.$invalid) {
                vm.createProcess.$submitted = true;
                return;
            }
            vm.setSelection(procName);
            vm.previousProcess(vm.prevIndex);
        };

        vm.openModal = function (clientId) {
            vm.client_id = clientId;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/admin/processMap/create-tax-placemat.html',
                controller: 'createTPController as ctrl',
                size: 'sm',
                resolve: {
                    client_key: function () {
                        return vm.client_id;
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {}, function () {});
        };

        vm.addDummyActivity = function (){
            vm.client_id = clientId;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/admin/processMap/add-dummy-activity.html',
                controller: 'createDummyActivityController as ctrl',
                size: 'sm',
                resolve: {
                    tax_placemats: function(){
                        return {tax_placemats: vm.taxPlacements, selected_id: vm.tax_id};
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {}, function () {});
        };
        
        vm.reorderProcessModal = function () {
            vm.processes = angular.copy(vm.mappedProcessesList);
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/admin/processMap/reorder-processes.html',
                controller: 'reorderProcessController as ctrl',
                size: 'sm',
                resolve: {
                    mapped_processes: function () {
                        return vm.processes;
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {}, function () {});
        };



        $scope.$on('load-new-tp', function(event, args) {
            vm.tax_id = args.txName;
            vm.taxPlacements.push(vm.tax_id);
            vm.mappedProcessesList = [];
            vm.showBlock = false;
            vm.defaultClient = true;
            vm.newTaxPlacemat = true;
        });

        $scope.$on('reorder-processes', function(event, args) {
            vm.mappedProcessesList = args.data;
            vm.defaultSelection();
        });

        $scope.$on('add-dummy-activity', function(event, args) {
            vm.dummy_activity = args.dummy_activity;
            vm.mappedProcessesList[vm.current_process].children.push(vm.dummy_activity);
        });

        vm.deleteTaxPlacemat = function(){
            vm.showBlock = false;
            vm.showProcLoader = true;
            processMapFactory.deleteTaxPlacemat(vm.client_key, vm.tax_id.placematId).then(function (data){
                if(data.callSuccess === "1" ){
                    AlertService.add("success", "Tax Placemat Deleted Successfully.",4000);
                    vm.showProcLoader = false;
                    vm.defaultClient = true;
                    angular.forEach(vm.taxPlacements, function(data, index){
                        if(data.placematId == vm.tax_id.placematId){
                            vm.taxPlacements.splice(index, 1);
                        }
                    });
                    vm.tax_id = {};
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        };

        vm.setSelection = function(procName){
            vm.createProcBlock = false;
            vm.processName = '';
            vm.prevIndex = vm.current_process;
            var newProc = {
                process_id: 0,
                label: procName,
                num: vm.mappedProcessesList.length + 1,
                process_from : vm.tax_year_begin,
                process_to : vm.tax_year_end,
                noActivity: true,
                edit_delete: true,
                children: []
            }
            vm.current_process = vm.mappedProcessesList.length;
            vm.mappedProcessesList.push(newProc);
            vm.selectedProc = vm.current_process;          
            vm.tasksList = angular.copy(vm.original_tasksList);
            vm.tax_year_begin = '';
            vm.tax_year_end = '';
        };
        
        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.previousProcess = function(index){
            if(vm.mappedProcessesList.length > 1){
                vm.mappedProcessesList[index].edit_delete = false;
                vm.mappedProcessesList[index].edit = false;
                vm.mappedProcessesList[index].selected = false;
            }
            vm.showBlock = true;
            vm.defaultClient = false;
        };

        vm.editProcess = function(index){
            vm.mappedProcessesList[index].edit = !vm.mappedProcessesList[index].edit;
        };

        vm.cancelEdit = function(index){
            vm.mappedProcessesList[index].edit = !vm.mappedProcessesList[index].edit;
        };

        vm.saveProcess = function(process , index){
            var savingData = {
                client_id : vm.client_key,
                process_id: process.process_id,
                label: process.label, 
                process_from :$filter('date')(process.process_from,'MM/dd/yyyy'),
                process_to : $filter('date')(process.process_to,'MM/dd/yyyy') 
            }
            processMapFactory.saveUpdatedProcesses(JSON.stringify(savingData),vm.tax_id.placematId).then(function (data) {
                if(data.callSuccess === 1 ){
                     AlertService.add("success", "Data saved Successfully.",4000);
                }else{
                    if(data.errorMessage === "no access"){
                         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                         AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            }); 
            vm.editProcess(index) ;
        };

        vm.selectedProcess = function (process, index){
            var prevProc = vm.current_process;
            if(index == 0 || !!index){
              vm.current_process = index;
            };
            if(prevProc !== vm.current_process){
              vm.previousProcess(prevProc);
            }
            if(index == undefined){
                vm.mappedProcessesList[vm.current_process].edit_delete = true;    
            }else{
                vm.mappedProcessesList[vm.current_process].edit_delete = !vm.mappedProcessesList[vm.current_process].edit_delete;    
            }
            vm.tasksList = vm.checkActivities(process);  
            vm.checkParent(vm.tasksList);
        };

        vm.checkActivities = function (process){
            vm.duplicate_list = angular.copy(vm.original_tasksList);
            angular.forEach(process.children, function(process, index){
                var mapped_activity = process.activity_key;
                angular.forEach(vm.duplicate_list, function(activity, index){
                    angular.forEach(activity.children, function(task, index){
                        if(task.activity_key == mapped_activity){
                            task.selected = true;
                        }
                        if(task.subtask){
                            angular.forEach(task.children, function(subchild, index){ 
                                if(mapped_activity == subchild.activity_key){
                                    subchild.selected = true;
                                }
                            }); 
                        }
                    });
                });
            });
            return vm.duplicate_list;
        };

        vm.checkParent = function (tasksList){
            var childCount = 0;
            var subChildCount = 0;
            angular.forEach(tasksList, function(task, index){
                childCount = 0;
                task.selected = true;
                angular.forEach(task.children, function(child, index){
                    subChildCount = 0;
                    if(child.subtask){
                        child.selected = true;
                        angular.forEach(child.children, function(subchild, index){
                            if(!subchild.selected) {
                                child.selected = false;
                                task.selected = false;
                            }else{
                                ++subChildCount;
                            }
                        });
                        child.selectActivityCount = subChildCount;
                    }
                    if(!child.selected) {
                        task.selected = false;
                    }else{
                        ++childCount;
                    }
                });
                task.selectedTaskCount = childCount;               
            });
        };

        vm.mappedChecklist = function(activities){
            var newActivity = '';
            var uniqueMappedList = _.uniqBy(vm.mappedProcessesList[vm.current_process].children, 'activity_key');
            vm.mappedProcessesList[vm.current_process].children = uniqueMappedList;
            angular.forEach(activities, function(activity, index){ 
                if(!activity.selected){
                    activity.selected = true;
                    newActivity = activity.activity_key;
                    angular.forEach(vm.tasksList, function(task, index){
                        angular.forEach(task.children, function(activities, index){ 
                            if(newActivity == activities.activity_key){
                                activities.selected = true;
                            }
                            if(activities.subtask){
                                angular.forEach(activities.children, function(subchild, index){ 
                                    if(newActivity == subchild.activity_key){
                                        subchild.selected = true;
                                    }
                                }); 
                            }
                        });
                    });
                }
            });
            vm.noActivityCheck();
            vm.checkParent(vm.tasksList);
        };

        vm.masterChecklist = function(){
            var dropActivityKey = processMapFactory.getDragKey();
            if(vm.is_dummy){
                vm.removeActFromMapped(dropActivityKey);
                vm.is_dummy = false;
                vm.noActivityCheck();
            }else{
                angular.forEach(vm.tasksList, function(task, index) {
                    angular.forEach(task.children, function(activity, index){ 
                        if(dropActivityKey == activity.activity_key){
                            activity.selected = false;
                            vm.removeActFromMapped(dropActivityKey);
                        }else if(activity.subtask){
                            angular.forEach(activity.children, function(subchild, index){
                                if(dropActivityKey == subchild.activity_key){
                                    subchild.selected = false;
                                    vm.removeActFromMapped(dropActivityKey);
                                };
                            });
                        }
                    });
                });           
                vm.noActivityCheck();
                vm.checkParent(vm.tasksList);
            }
        };

        vm.removeActFromMapped = function(key){
            angular.forEach(vm.mappedProcessesList, function(process, index){
                angular.forEach(process.children, function(activity, index){ 
                    if(key == activity.activity_key){
                        process.children.splice(index, 1);
                    }
                });
            });
        };

        vm.noActivityCheck = function(){
            if(vm.mappedProcessesList[vm.current_process].children.length == 0){
                vm.mappedProcessesList[vm.current_process].noActivity = true;
            }else{
                vm.mappedProcessesList[vm.current_process].noActivity = false;
            }
        };

        vm.draggedFromMapped = function(act){
            var dragOutKey = act.activity_key;
            processMapFactory.setDragKey(dragOutKey);
            if(act.to_placemat_name !== null){
                vm.is_dummy = true;
            }
        };

        vm.updateSelected = function(node, rel, activity, subactivity){
            vm.savebtn = false;
            event.stopPropagation();
            if(rel === 'parent'){
                vm.selectBranch(node, (node.selected) ? true : false );
            }else if(rel === 'child'){
                if(activity.selected){
                    activity.status = 'on';
                    if(activity.subtask){
                        angular.forEach(activity.children, function(subchild, index){ 
                            subchild.status = 'on';
                            subchild.selected = true;
                            vm.mappedProcessesList[vm.current_process].children.push(subchild);
                        });
                    }else {
                        vm.mappedProcessesList[vm.current_process].children.push(activity);
                    }
                    if(vm.mappedProcessesList[vm.current_process].children.length == 1){
                        vm.mappedProcessesList[vm.current_process].noActivity = false;
                    }
                } else {
                    if(activity.subtask){
                        angular.forEach(activity.children, function(subchild, index){
                            var subKey = subchild.activity_key;
                            subchild.selected = false;
                            vm.mappedProcessesList[vm.current_process].children = vm.removeActivities(vm.mappedProcessesList[vm.current_process].children, subKey);
                        });
                    }else{
                        angular.forEach(vm.mappedProcessesList[vm.current_process].children, function(children, index){ 
                            if(children.activity_key == activity.activity_key){
                                vm.mappedProcessesList[vm.current_process].children.splice(index, 1);
                            }
                        });
                    }
                    if(vm.mappedProcessesList[vm.current_process].children.length == 0){
                        vm.mappedProcessesList[vm.current_process].noActivity = true;
                    }
                }                
            }else {
                if(subactivity.selected){
                    subactivity.status = 'on';
                    vm.mappedProcessesList[vm.current_process].children.push(subactivity);
                    if(vm.mappedProcessesList[vm.current_process].children.length == 1){
                        vm.mappedProcessesList[vm.current_process].noActivity = false;
                    }
                }else{
                    angular.forEach(vm.mappedProcessesList[vm.current_process].children, function(children, index){ 
                        if(children.activity_key == subactivity.activity_key){
                            vm.mappedProcessesList[vm.current_process].children.splice(index, 1);
                        }
                    });
                }
            }
            vm.checkParent(vm.tasksList);
        };

        vm.selectBranch = function(node,value){
            vm.setTreeAttribute(node.children,'selected','children',value);
        };


        vm.setTreeAttribute = function(_collection,_predicate,_children,value){
            var dup_mappedProcList = angular.copy(vm.mappedProcessesList[vm.current_process].children);
            angular.forEach(_collection, function(child, index) { 
                child[_predicate] = value;
                if(child.subtask){
                    angular.forEach(child.children, function(subchild, index){ 
                        subchild[_predicate] = value;
                    });
                }
            });
            if(value){
                angular.forEach(_collection, function(child, index){ 
                    child.status = 'on';
                    if(child.subtask){
                        angular.forEach(child.children, function(subchild, index){ 
                            subchild.status = 'on';
                            dup_mappedProcList.push(subchild);
                        });
                    }else {
                        dup_mappedProcList.push(child); 
                    }
                });
                var uniqueMappedProcList = _.uniqBy(dup_mappedProcList, 'activity_key');
                vm.mappedProcessesList[vm.current_process].children = uniqueMappedProcList;
                vm.mappedProcessesList[vm.current_process].noActivity = false;
            }else{
                angular.forEach(_collection, function(child, index) { 
                    var key = child.activity_key;
                    dup_mappedProcList = vm.removeActivities(dup_mappedProcList, key);
                    if(child.subtask){
                        angular.forEach(child.children, function(subchild, index) {
                            var subKey = subchild.activity_key;
                            dup_mappedProcList = vm.removeActivities(dup_mappedProcList, subKey);
                        });
                    }
                });
                vm.mappedProcessesList[vm.current_process].children = dup_mappedProcList;
                if(vm.mappedProcessesList[vm.current_process].children.length == 0){
                    vm.mappedProcessesList[vm.current_process].noActivity = true;
                }
            }
        };

        vm.removeActivities = function (arr, key){
            angular.forEach(arr, function(act, i){ 
                if(act.activity_key == key){
                    arr.splice(i, 1);
                }
            });
            return arr;
        };

        vm.getMappedProcessesByClientKey = function (key, id){
            processMapFactory.getMappedProcesses(key, id).then(function (data){
                if(data.callSuccess === "1" ){
                    if(data.data.length>0){
                        angular.forEach(data.data, function(mapped_act, index){
                            mapped_act.process_from = new Date(mapped_act.process_from);
                            mapped_act.process_to =  new Date(mapped_act.process_to);
                            mapped_act.children = mapped_act.values;
                            mapped_act.edit_delete = false;
                            mapped_act.edit = false;             
                            angular.forEach(mapped_act.children, function(children, index){ 
                               children.selected = true;
                               children.activity_name = children.name;
                               children.activity_key = parseInt(children.children[0].activity_key);
                            });
                        });
                        vm.original_mappProcessedList = data.data;
                        vm.mappedProcessesList = angular.copy(vm.original_mappProcessedList);
                        if(!!vm.tasksList && !!vm.mappedProcessesList){
                            vm.defaultSelection();
                        }
                    }else{
                        vm.showProcLoader = false;
                        vm.defaultClient = true;
                        vm.mappedProcessesList = [];
                        AlertService.add("warning", "No data available for selected client", 4000); 
                    }
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        };

        vm.defaultSelection = function(){
            vm.current_process = 0;
            vm.selectedProc = vm.current_process;
            vm.selectedProcess(vm.mappedProcessesList[vm.current_process]);
            vm.showBlock = true;
            vm.showProcLoader = false;
        };

        vm.toggleActivities = function (index){
            vm.tasksList[index].toggle = !vm.tasksList[index].toggle;
        };

        vm.getTasksHierarchyByClientKey = function(key){
            vm.loading = true;
            var data = {};
            var tempArray = [];
            var finalArray = [];
                processMapFactory.getTasksHierarchy(key).then(function (data){
                vm.loading = false;
                if(data.length>0){
                    angular.forEach(data, function(task, index){
                        task.toggle = false;
                        task.selected = false;
                        task.selectedTaskCount = 0;
                        if(task.children){
                            angular.forEach(task.children, function(activity, index){
                                activity.toggle = false;
                                activity.selected = false;
                                activity.draggable = true;
                                activity.subtask = false;                              
                            });
                        }
                        if(task.sub_task){
                            angular.forEach(task.sub_task, function(subtask, index){
                                subtask.activity_name = subtask.task_name;
                                subtask.toggle = false;
                                subtask.selected = false;
                                subtask.draggable = false;
                                subtask.subtask = true;
                                subtask.selectActivityCount = 0;
                                angular.forEach(subtask.children, function(subactivity, index){
                                    subactivity.toggle = false;
                                    subactivity.selected = false;
                                    subactivity.draggable = true;                             
                                });
                                task.children.unshift(subtask);
                            });
                        };
                    });
                    vm.original_tasksList = data;
                    vm.tasksList = data;
                    if(!!vm.mappedProcessesList && !!vm.tasksList){
                        vm.defaultSelection();
                    }
                }else{
                    vm.defaultClient = false;
                    AlertService.add("warning", "Tasks Hierarchy Data is not available", 4000); 
                }
            });
        };

        vm.saveTasks = function(){
            vm.savebtn = true;
            vm.saveloader = true;
            vm.process = {};
            vm.modifiedProcList = [];
            vm.isEmpty = false;
            vm.emptyProcessList = [];
            
            angular.forEach(vm.mappedProcessesList, function(element, index){
                vm.process = {
                    process_id: element.process_id,
                    client_id: vm.client_key,
                    label: element.label, 
                    num : index + 1,
                    process_from : $filter('date')(element.process_from,'MM/dd/yyyy'),
                    process_to : $filter('date')(element.process_to,'MM/dd/yyyy'), 
                    values : []
                };
                if(element.children.length > 0){
                    angular.forEach(element.children, function(child, index){
                        if(child.dummy_activity){
                            vm.process.values.push ({
                                activity_key: child.activity_key,
                                activity_order: index + 1,
                                activity_name: child.activity_name,
                                placemat_id: child.placemat_id
                            });
                        }else{
                            vm.process.values.push ({
                                activity_key: child.activity_key,
                                activity_order: index + 1
                            });
                        }
                    });
                }else{
                    vm.isEmpty = true;
                    vm.emptyProcessList.push(" " + element.label);
                }               
                vm.modifiedProcList.push(vm.process);
                vm.emptyProcess = vm.emptyProcessList.toString();
            });

            if(vm.isEmpty){
                vm.saveloader = false;
                AlertService.add("warning", "Please add atleast one activity in these processes to save : " + vm.emptyProcess, 4000);
                return; 
            }

            processMapFactory.saveMappedProcesses(JSON.stringify(vm.modifiedProcList), vm.client_key, vm.tax_id.placematId, vm.tax_id.placematName).then(function (data) {
                if(data.callSuccess === 1 ){
                    AlertService.add("success", "Data saved Successfully.",4000);
                    vm.tax_id.placematId = data.data;
                    vm.taxPlacements[vm.taxPlacements.length - 1].placematId = data.data;
                    vm.saveloader = false;
                    vm.showBlock = false;
                    vm.showProcLoader = true;
                    vm.newTaxPlacemat = false;
                    vm.createProcBlock = false;
                    vm.processName = '';
                    vm.tax_year_begin = '';
                    vm.tax_year_end = '';
                    vm.mappedProcessesList = [];
                    vm.getMappedProcessesByClientKey(vm.client_key, vm.tax_id.placematId);
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });  
        };

        vm.clearTasks = function (){
            vm.current_process = 0;
            vm.mappedProcessesList = angular.copy(vm.original_mappProcessedList);
            vm.selectedProc = vm.current_process;
            vm.mappedProcessesList[vm.current_process].edit_delete = true;
            vm.tasksList = vm.checkActivities(vm.mappedProcessesList[vm.current_process]);
            vm.checkParent(vm.tasksList); 
        };

        vm.deleteProc = function (proc_id, index){
            processMapFactory.deleteMappedProcesses(proc_id, vm.client_key,vm.tax_id.placematId).then(function (data){
                if(data.callSuccess === "1" ){
                    AlertService.add("success", "Process Deleted Successfully.",4000);
                    vm.mappedProcessesList.splice(index, 1);
                    if(vm.mappedProcessesList.length > 0){
                        vm.mappedProcessesList[0].edit_delete = true;
                        vm.mappedProcessesList[0].selected = true;
                        vm.selectedProc = 0;
                        vm.current_process = vm.selectedProc;

                    }else{
                        vm.showBlock = false;
                        vm.defaultClient = true;
                    }
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            }); 
        };
        
        vm.resetData = function (argClientKey){
            vm.ActivityList = [];
            vm.original_tasksList = [];
            vm.original_mappProcessedList = [];
            vm.current_process = '';
            vm.defaultClient = false;
            vm.showBlock = false;
            vm.showProcLoader = false;
            vm.savebtn = true;
            vm.saveloader = false;
            vm.createProcBlock = false;
            vm.prevIndex = '';
            vm.processName = '';
            vm.mappedProcessesList = [];
            vm.tasksList = [];
             init();
        };
        init();
    };

    function createTPController($rootScope, $window, $scope, client_key, $state, $http, ModalFactory, AlertService, GlobalService, $timeout, workspaceFactory, processMapFactory, $uibModalInstance) {
        var vm = this;
        vm.client_key = client_key;
        vm.title = 'Create Tax Placemat';
        vm.taxPlacematName = '';
        var tx_id;
        var tx_name;

        vm.clear = function(){
            vm.taxPlacematName = '';
        };

        vm.cancel = function () {
            closeModal();
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.createTaxPlacemat = function(){
            tx_id = 0;
            tx_name = vm.taxPlacematName;
            vm.mappedProcessesList = [];
            vm.taxPlacematName = '';
            closeModal();
            $rootScope.$broadcast('load-new-tp', { txName : {placematId:0, placematName:tx_name} });
        };
    };

    function createDummyActivityController($rootScope, $window, $scope, tax_placemats, $state, $http, ModalFactory, AlertService, GlobalService, $timeout, workspaceFactory, processMapFactory, $uibModalInstance) {
        var vm = this;
        vm.title = 'Add Dummy Activity';
        vm.tax_data = tax_placemats;
        vm.taxPlacements = vm.tax_data.tax_placemats;
        vm.tax_id = vm.tax_data.selected_id;
        vm.dummyActivityName = '';

        vm.clear = function(){
            vm.dummyActivityName = '';
        };

        vm.cancel = function () {
            closeModal();
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.createDummyActivity = function(){
            var dummy_activity = {
                task_key: 0,
                activity_key: 0,
                activity_name: vm.dummyActivityName,
                toggle: false,
                selected: true,
                draggable: true,
                subtask: false,
                dummy_activity: true,
                placemat_id: vm.tax_id.placematId,
                placemat_name: vm.tax_id.placematName
            };
            vm.dummyActivityName = '';
            closeModal();
            $rootScope.$broadcast('add-dummy-activity', { dummy_activity });
        };
    };
    
    function reorderProcessController($rootScope, $window, $scope, mapped_processes, $state, $http, ModalFactory, AlertService, GlobalService, $timeout, workspaceFactory, processMapFactory, $uibModalInstance) {
        var vm = this;
        vm.allProcesses = angular.copy(mapped_processes);
        vm.title = 'Reorder Processes';

        vm.clear = function(){
            vm.allProcesses = angular.copy(mapped_processes);
        };

        vm.cancel = function () {
            closeModal();
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.applyReorder = function(){
            closeModal();
            $rootScope.$broadcast('reorder-processes', { data :  vm.allProcesses});
        };
    };

    return controllers;
});
