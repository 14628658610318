define([
    'angular'

], function () {
    'use strict';
    'use strict';
	/* NOTE:  changed on 5/10/2019
	  Below two controllers functionalities are same, if we are using same modal in both places grid & workflow, we need to define the controllers like below
		Form4562Controller		: this controller is used on workflow Step
		Form4562ControllerModal	: this controller is used on Grid Modal
*/

    var controllers = angular.module('app.Form4562Ctrl', [])
    .controller('Form4562Controller', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','$timeout',
		'Form4562Factory','GENERAL_CONFIG', Form4562Controller])
      .controller('Form4562ControllerModal', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','$timeout',
  		'Form4562Factory','GENERAL_CONFIG', '$uibModalInstance', 'rowData', 'colData', 'gridData', Form4562ControllerModal]);


  function Form4562Controller(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope, $scope,$http, CodeComboFactory, $timeout, Form4562Factory, GENERAL_CONFIG) {
	  Form4562ControllerModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope,$http, CodeComboFactory,$timeout, Form4562Factory, GENERAL_CONFIG, null, null, null, null, this);
	}

  function Form4562ControllerModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope,$http, CodeComboFactory,$timeout, Form4562Factory, GENERAL_CONFIG,
  		$uibModalInstance, rowData, colData, gridData,thisObj){

  		var vm = this||thisObj;
		vm.rowData = ( $scope.$parent.rowData == undefined ) ? rowData : $scope.$parent.rowData;
		var rowData = vm.rowData;


		if($uibModalInstance) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};
		} else {
			vm.modal_name = null;
		}

		vm.onTabChange = function(e) {
            vm.selectedTabName = e;
            $timeout(function() {
                var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            }, 50);
        };

        vm.form4562Data = [];
        vm.line19AllDetails = [];
        vm.line20AllDetails = [];
        vm.serDuringCurrYear =  null;
        vm.AssetsCurrentYear = true;
        vm.IsBonus = null;
        vm.MethodAdopted = null;
        $scope.displayViewValue_26 = [];
        $scope.displayEditValue_26 = [];
        vm.form4562Amounts = [[],[]];
        vm.v_form_key = 3;
        $scope.displayViewValue_42 = [];
        $scope.displayEditValue_42 = [];
        vm.totAmortAmtPerTaxRet = [];
        vm.depreDed26 = 0;
        vm.line14Amt = 0;
		vm.line14AmtOrig = 0;
		vm.line16Amt = 0;
		vm.line16AmtOrig = 0;
		vm.line17Amt = 0;
		vm.line17AmtOrig = 0;

		vm.line19DeprOrig = [];
		vm.line20DeprOrig = [];

		vm.line25Amt = 0;
		vm.line25AmtOrig = 0;
		vm.line43Amt = 0;
		vm.line43AmtOrig = 0;

		vm.line19DepDed = 0;
		vm.line20DepDed = 0;


		vm.totalDeprOriginal = 0;
		vm.unallocatedAmtOrig = 0;

        vm.rowData= rowData;
        console.log("rowData:::::::::",vm.rowData);

        $scope.displayDeleteIcon_26 = [];
		$scope.displayDeleteIcon_42 = [];

		vm.temp02 = 0;

		vm.temp2E = 0;
		vm.temp10E = 0;
		vm.temp19E = 0;


		vm.form4562Line26Records1 = [];



        vm.modalTitle = "Form 4562: Depreciation & Amortization - Debit (Credit) Format";
			$scope.data ={
				partII:{
					title:"Special Depreciation Allowance and Other Depreciation",
					tableTitle:"Special depreciation allowance for qualified property (other than listed property) placed in service during the tax year",
					tableData:[
						{num:"14",value:"Special depreciation allowance for qualified property (other than listed property) placed in service during the tax year"},
						{num:"16",value:"Other depreciation (including ACRS)"}
					]
				},
				partIII:{
					title:"MACRS Depreciation",
					table:{
						num:"17",
						value:"MACRS deductions for assets placed in service in tax years beginning before 2017"
					},
					convensions: ["HY", "MM", "MQ", "S/L"],
					methods: ["150 DB", "200 DB", "DB", "S/L"],
					sections: [{
						title: "Section B- Assets Placed in Service During Current Tax Year Using the General Depreciation System",
						columns: ["(a) Classification of property","(b) Month and year placed in service","(c) Basis for depreciation (business/investment use only--see instructions)",
						"(d) Recovery period","(e) Convention","(f) Method","(g) Depreciation deduction"],
						rows: [{
							num:19,
							classification: "a 3-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "150 DB"},
							depricationDeduction: ""
						}, {
							classification: "b 5-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "150 DB"},
							depricationDeduction: ""
						}, {
							classification: "c 7-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "150 DB"},
							depricationDeduction: ""
						}, {
							classification: "d 10-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "150 DB"},
							depricationDeduction: ""
						},{
							classification: "e 15-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "150 DB"},
							depricationDeduction: ""
						}, {
							classification: "f 20-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "150 DB"},
							depricationDeduction: ""
						}, {
							classification: "g 25-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "S/L", disabled: true},
							depricationDeduction: ""
						},{
							classification: "h Residential rental property",
							placedInService: {val: 'VARIOUS'},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "MM", disabled: true},
							method: {val: "S/L", disabled: true},
							depricationDeduction: ""
						},{
							classification: "i Nonresidential real property",
							placedInService: {val: 'VARIOUS'},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "MM", disabled: true},
							method: {val: "S/L", disabled: true},
							depricationDeduction: ""
						}]
					}, {
						title: "Section C- Assets Placed in Service During Current Tax Year Using the Alternative Depreciation System",
						columns: [
							"(a) Classification of property","(b) Month and year placed in service","(c) Basis for depreciation (business/investment use only--see instructions)",
							"(d) Recovery period","(e) Convention","(f) Method","(g) Depreciation deduction"
						],
						rows: [{
							num:20,
							classification: "a Class life",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: {val: "HY"},
							method: {val: "S/L", disabled: true},
							depricationDeduction: ""
						}, {
							classification: "b 12-year",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: {val: '12 Yrs', disabled: true},
							convension: {val: "HY"},
							method: {val: "S/L", disabled: true},
							depricationDeduction: ""
						}, {
							classification: "c 40-year",
							placedInService: {val: 'VARIOUS'},
							basisForDeprication: "",
							recoveryPeriod: {val: "40 Yrs", disabled: true},
							convension: {val: "MM", disabled: true},
							method: {val: "S/L", disabled: true},
							depricationDeduction: ""
						}]
					}]
				},
				partV:{
					title:" Listed Property",
					tableTitle:"Section A-Depreciation and Other Information",
					tableHeaders:[
						"(a) Type of property (list vehicles first)","(b) Date placed in service","(c) Business/ investment use percentage","(d) Cost or other basis",
						"(e) Basis for depreciation (business/investment use only)","(f) Recovery period","	(g) Method/ Convention","(h) Depreciation deduction"
					],
					tableData:[
						{num:"25",value:"Special depreciation allowance for qualified listed property placed in service during the tax year and used more than 50% in a qualified business use (see instructions)."},
						{num:"26",value:"Property used more than 50% in a qualified business use:"}
					],
					rows: [{}]
				},
				partVI:{
					title:"Amortization",
					tableHeaders:[
						"(a) Description of costs",
						"(b) Date amortization begins",
						"(c) Amortizable amount",
						"(d) Code section",
						"(e) Amortization period or percentage",
						"(f) Amortization for this year"
					],
					tableData:[{
						num:"42",value:"Amortization of costs that begins during your current tax year"
					}, {
						num:"43",value:"Amortization of costs that began before your current tax year"
					}],
					rows: [{}]
				},
				selectPeriod:["3","5","7","10","15","20","25","27.5","39","50"],
				selectPeriodStr:["3 Yrs","5 Yrs","7 Yrs","10 Yrs","15 Yrs","20 Yrs","25 Yrs","27.5 Yrs","39 Yrs","50 Yrs"],
				selectMethod:["DB","S/L","150 DB","200 DB"],
				selectConvention:["HY","MM","MQ","S/L"]
            };

			$scope.data.partV.rows.splice(0, 1);
	        $scope.data.partVI.rows.splice(0, 1);

	        vm.formData_26 = {
	                "addrows"   : [],
	                "editrows"  : [],
	                "removerows": []
	        };

	        vm.formData_42 = {
	                "addrows"   : [],
	                "editrows"  : [],
	                "removerows": []
	        };

	        vm.formData_line19a = {
	                "editrows"  : []
	        };
	        vm.formData_line19b = {
	                "editrows"  : []
	        };
	        vm.formData_line19c = {
	                "editrows"  : []
	        };
	        vm.formData_line19d = {
	                "editrows"  : []
	        };
	        vm.formData_line19e = {
	                "editrows"  : []
	        };
	        vm.formData_line19f = {
	                "editrows"  : []
	        };
	        vm.formData_line19g = {
	                "editrows"  : []
	        };
	        vm.formData_line19h = {
	                "editrows"  : []
	        };
	        vm.formData_line19i = {
	                "editrows"  : []
	        };
	        vm.formData_line20a = {
	                "editrows"  : []
	        };
	        vm.formData_line20b = {
	                "editrows"  : []
	        };
	        vm.formData_line20c = {
	                "editrows"  : []
	        };

	        vm.formData_line14 = {
	                "editrows"  : []
	        };
	        vm.formData_line16 = {
	                "editrows"  : []
	        };
	        vm.formData_line17 = {
	                "editrows"  : []
	        };
	        vm.formData_line25 = {
	                "editrows"  : []
	        };
	        vm.formData_line43 = {
	                "editrows"  : []
	        };


	        vm.formData_19 = [];
	        vm.formData_20 = [];

	        vm.formData = {
	                "addrows"   : [],
	                "editrows"  : [],
	                "removerows": []
	        };

			var data = vm.rowData;

			vm.taxYear = parseInt(rowData.TAX_YEAR);
			console.log('The current tax year is :::----------', vm.taxYear);
			if(vm.taxYear < 2016) { vm.AssetsCurrentYear = false ;}
	        vm.scenario = parseInt(rowData.SCENARIO);
	        vm.jcd = 250//rowData.JCD_KEY;
	        vm.combinationKey = parseInt(rowData.COMBINATION_KEY);
	        vm.groupObjKey = parseInt(rowData.GROUP_OBJ_KEY);

	        vm.colData_line14 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"14", "occurence" :1, "line_attrib_key" :298, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData_line16 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"16", "occurence" :1, "line_attrib_key" :301, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData_line17 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"17", "occurence" :1, "line_attrib_key" :305, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData_line25 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"25", "occurence" :1, "line_attrib_key" :442, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData_line43 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"43", "occurence" :1, "line_attrib_key" :476, "Attribute_Name": "AMORTIZATION_FOR_THIS_YEAR", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.recordData_line14 = [vm.colData_line14];
	        vm.recordData_line16 = [vm.colData_line16];
	        vm.recordData_line17 = [vm.colData_line17];
	        vm.recordData_line25 = [vm.colData_line25];
	        vm.recordData_line43 = [vm.colData_line43];


	        vm.colData1_19a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19a", "occurence" :null, "line_attrib_key" :321, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19a", "occurence" :null, "line_attrib_key" :322, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19a", "occurence" :null, "line_attrib_key" :323, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_19a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19a", "occurence" :null, "line_attrib_key" :324, "Attribute_Name": "METHOD", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_19a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19a", "occurence" :null, "line_attrib_key" :325, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19a = [vm.colData1_19a, vm.colData2_19a, vm.colData3_19a, vm.colData4_19a, vm.colData5_19a];


	        vm.colData1_19b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19b", "occurence" :null, "line_attrib_key" :331, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19b", "occurence" :null, "line_attrib_key" :332, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19b", "occurence" :null, "line_attrib_key" :333, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_19b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19b", "occurence" :null, "line_attrib_key" :334, "Attribute_Name": "METHOD", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_19b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19b", "occurence" :null, "line_attrib_key" :335, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19b = [vm.colData1_19b, vm.colData2_19b, vm.colData3_19b, vm.colData4_19b, vm.colData5_19b];


	        vm.colData1_19c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19c", "occurence" :null, "line_attrib_key" :341, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19c", "occurence" :null, "line_attrib_key" :342, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19c", "occurence" :null, "line_attrib_key" :343, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_19c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19c", "occurence" :null, "line_attrib_key" :344, "Attribute_Name": "METHOD", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_19c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19c", "occurence" :null, "line_attrib_key" :345, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19c = [vm.colData1_19c, vm.colData2_19c, vm.colData3_19c, vm.colData4_19c, vm.colData5_19c];


	        vm.colData1_19d = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19d", "occurence" :null, "line_attrib_key" :351, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19d = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19d", "occurence" :null, "line_attrib_key" :352, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19d = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19d", "occurence" :null, "line_attrib_key" :353, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_19d = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19d", "occurence" :null, "line_attrib_key" :354, "Attribute_Name": "METHOD", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_19d = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19d", "occurence" :null, "line_attrib_key" :355, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19d = [vm.colData1_19d, vm.colData2_19d, vm.colData3_19d, vm.colData4_19d, vm.colData5_19d];


	        vm.colData1_19e = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19e", "occurence" :null, "line_attrib_key" :361, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19e = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19e", "occurence" :null, "line_attrib_key" :362, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19e = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19e", "occurence" :null, "line_attrib_key" :363, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_19e = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19e", "occurence" :null, "line_attrib_key" :364, "Attribute_Name": "METHOD", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_19e = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19e", "occurence" :null, "line_attrib_key" :365, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19e = [vm.colData1_19e, vm.colData2_19e, vm.colData3_19e, vm.colData4_19e, vm.colData5_19e];


	        vm.colData1_19f = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19f", "occurence" :null, "line_attrib_key" :371, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19f = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19f", "occurence" :null, "line_attrib_key" :372, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19f = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19f", "occurence" :null, "line_attrib_key" :373, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_19f = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19f", "occurence" :null, "line_attrib_key" :374, "Attribute_Name": "METHOD", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_19f = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19f", "occurence" :null, "line_attrib_key" :375, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19f = [vm.colData1_19f, vm.colData2_19f, vm.colData3_19f, vm.colData4_19f, vm.colData5_19f];


	        vm.colData1_19g = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19g", "occurence" :null, "line_attrib_key" :381, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19g = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19g", "occurence" :null, "line_attrib_key" :383, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19g = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19g", "occurence" :null, "line_attrib_key" :385, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19g = [vm.colData1_19g, vm.colData2_19g, vm.colData3_19g];

	        vm.colData1_19h = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19h", "occurence" :null, "line_attrib_key" :390, "Attribute_Name": "DATE_PLACED_SERVICE", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19h = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19h", "occurence" :null, "line_attrib_key" :391, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19h = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19h", "occurence" :null, "line_attrib_key" :395, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19h = [vm.colData1_19h, vm.colData2_19h, vm.colData3_19h];

	        vm.colData1_19i = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19i", "occurence" :null, "line_attrib_key" :400, "Attribute_Name": "DATE_PLACED_SERVICE", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_19i = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19i", "occurence" :null, "line_attrib_key" :401, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_19i = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"19i", "occurence" :null, "line_attrib_key" :405, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_19i = [vm.colData1_19i, vm.colData2_19i, vm.colData3_19i];

	        vm.colData1_20a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20a", "occurence" :null, "line_attrib_key" :413, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_20a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20a", "occurence" :null, "line_attrib_key" :414, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_20a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20a", "occurence" :null, "line_attrib_key" :415, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_20a = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20a", "occurence" :null, "line_attrib_key" :417, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_20a = [vm.colData1_20a, vm.colData2_20a, vm.colData3_20a, vm.colData4_20a];

	        vm.colData1_20b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20b", "occurence" :null, "line_attrib_key" :423, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_20b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20b", "occurence" :null, "line_attrib_key" :425, "Attribute_Name": "CONVENTION", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_20b = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20b", "occurence" :null, "line_attrib_key" :427, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_20b = [vm.colData1_20b, vm.colData2_20b, vm.colData3_20b];

	        vm.colData1_20c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20c", "occurence" :null, "line_attrib_key" :432, "Attribute_Name": "DATE_PLACED_SERVICE", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_20c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20c", "occurence" :null, "line_attrib_key" :433, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_20c = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :"20c", "occurence" :null, "line_attrib_key" :437, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_20c = [vm.colData1_20c, vm.colData2_20c, vm.colData3_20c];

			vm.colData1_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :444, "Attribute_Name": "PROPERTY_DESC", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :445, "Attribute_Name": "DATE_PLACED_SERVICE", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :446, "Attribute_Name": "BUSINESS_PERCENT", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :447, "Attribute_Name": "COST_BASIS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :448, "Attribute_Name": "BASIS_DEPRECIATION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData6_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :449, "Attribute_Name": "RECOVERY_PERIOD", "Attribute_Value" : "3", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData7_1_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :450, "Attribute_Name": "METHOD_OR_CONVENTION", "Attribute_Value" : "DB", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
            vm.colData7_2_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :450, "Attribute_Name": "METHOD_OR_CONVENTION", "Attribute_Value" : "HY", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData8_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :2180, "Attribute_Name": "SEC_179_COST", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData9_26 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :26, "occurence" :null, "line_attrib_key" :451, "Attribute_Name": "DEPRECIATION_DEDUCTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_26 = [vm.colData1_26, vm.colData2_26, vm.colData3_26, vm.colData4_26, vm.colData5_26, vm.colData6_26, vm.colData7_1_26, vm.colData7_2_26, vm.colData8_26, vm.colData9_26];

	        vm.colData1_42 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :42, "occurence" :null, "line_attrib_key" :468, "Attribute_Name": "DESCRIPTION_COSTS", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData2_42 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :42, "occurence" :null, "line_attrib_key" :469, "Attribute_Name": "DATE_AMORTIZATION_BEGINS", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData3_42 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :42, "occurence" :null, "line_attrib_key" :470, "Attribute_Name": "AMORTIZATION_AMOUNT", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData4_42 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :42, "occurence" :null, "line_attrib_key" :471, "Attribute_Name": "CODE_SECTION", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData5_42 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :42, "occurence" :null, "line_attrib_key" :472, "Attribute_Name": "AMORTIZATION_PERIOD_OR_PTG", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
	        vm.colData6_42 = {"tax_year":vm.taxYear, "trans_type_key" : 9, "combination_key" :vm.combinationKey, "group_obj_key" :201473, "form_key" :3, "line_no" :42, "occurence" :null, "line_attrib_key" :473, "Attribute_Name": "AMORTIZATION_FOR_THIS_YEAR", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

	        vm.recordData_42 = [vm.colData1_42, vm.colData2_42, vm.colData3_42, vm.colData4_42, vm.colData5_42, vm.colData6_42];


			function getForm4562Data() {
	        	Form4562Factory.fetch4562Dtls(data).then(function (data){
	        		console.log("Now inner thing is executed and the return data is ::::.....", data);
	        		if(data){
	        			vm.jsonData = data;
	        		}
	        		console.log("The new JSON Object data is ::----------", vm.jsonData);
	        		findAdPopulateLineAttribKeys(vm.recordData_26,data,27);
	                findAdPopulateLineAttribKeys(vm.recordData_42,data,34);
	                console.log('vm.recordData_26::',vm.recordData_26);
	                console.log('vm.recordData_42::',vm.recordData_42);
	                findAdPopulateLineAttribKeys(vm.recordData_19a,data,12);
	                findAdPopulateLineAttribKeys(vm.recordData_19b,data,13);
	                findAdPopulateLineAttribKeys(vm.recordData_19c,data,14);
	                findAdPopulateLineAttribKeys(vm.recordData_19d,data,15);
	                findAdPopulateLineAttribKeys(vm.recordData_19e,data,16);
	                findAdPopulateLineAttribKeys(vm.recordData_19f,data,17);
	                findAdPopulateLineAttribKeys(vm.recordData_19g,data,18);
	                findAdPopulateLineAttribKeys(vm.recordData_19h,data,19);
	                findAdPopulateLineAttribKeys(vm.recordData_19i,data,20);
	                findAdPopulateLineAttribKeys(vm.recordData_20a,data,22);
	                findAdPopulateLineAttribKeys(vm.recordData_20b,data,23);
	                findAdPopulateLineAttribKeys(vm.recordData_20c,data,24);
	                console.log('vm.recordData_19a::',vm.recordData_19a);
	                console.log('vm.recordData_19b::',vm.recordData_19b);
	                console.log('vm.recordData_19c::',vm.recordData_19c);
	                console.log('vm.recordData_19d::',vm.recordData_19d);
	                console.log('vm.recordData_19e::',vm.recordData_19e);
	                console.log('vm.recordData_19f::',vm.recordData_19f);
	                console.log('vm.recordData_19g::',vm.recordData_19g);
	                console.log('vm.recordData_19h::',vm.recordData_19h);
	                console.log('vm.recordData_19i::',vm.recordData_19i);
	                console.log('vm.recordData_20a::',vm.recordData_20a);
	                console.log('vm.recordData_20b::',vm.recordData_20b);
	                console.log('vm.recordData_20c::',vm.recordData_20c);

	                findAdPopulateLineAttribKeys(vm.recordData_line14,data,6);
	                findAdPopulateLineAttribKeys(vm.recordData_line16,data,7);
	                findAdPopulateLineAttribKeys(vm.recordData_line17,data,9);
	                findAdPopulateLineAttribKeys(vm.recordData_line25,data,26);
	                findAdPopulateLineAttribKeys(vm.recordData_line43,data,35);
	                console.log('vm.recordData_line14::',vm.recordData_line14);
	                console.log('vm.recordData_line16::',vm.recordData_line16);
	                console.log('vm.recordData_line17::',vm.recordData_line17);
	                console.log('vm.recordData_line25::',vm.recordData_line25);
	                console.log('vm.recordData_line43::',vm.recordData_line43);

	        		vm.form4562Data = vm.jsonData.irsForm.formItemList;

	        		vm.assetsPlacedService = vm.form4562Data[1].dataRowList[0].dataCellList;
	        		vm.bonusApplied = vm.form4562Data[2].dataRowList[0].dataCellList;
	        		vm.methodAdopted = vm.form4562Data[3].dataRowList[0].dataCellList;
	        		vm.form4562DepExpPerTaxRet = vm.form4562Data[4].dataRowList[0].dataCellList;
	        		if(rowData.TB_AMT == undefined){
	        			vm.form4562Data[4].dataRowList[0].dataCellList[1].value = 0;
	        		}else {
	        			vm.form4562Data[4].dataRowList[0].dataCellList[1].value = rowData.TB_AMT;
	        		}
	        		vm.form4562line14 = vm.form4562Data[6].dataRowList[0].dataCellList;
	        		vm.form4562line16 = vm.form4562Data[7].dataRowList[0].dataCellList;
	        		vm.form4562line17 = vm.form4562Data[9].dataRowList[0].dataCellList;

	        		vm.line14Amt = vm.form4562line14[2].value;
	        		vm.line16Amt = vm.form4562line16[2].value;
	        		vm.line17Amt = vm.form4562line17[2].value;

	        		if(vm.line14Amt == null){ vm.line14Amt = 0; }
	                if(vm.line16Amt == null){ vm.line16Amt = 0; }
	                if(vm.line17Amt == null){ vm.line17Amt = 0; }

	        		vm.line19HeadersAll = vm.form4562Data[10].dataRowList[0].dataCellList;
	        		vm.line26HeadersAll = vm.form4562Data[27].dataRowList[0].dataCellList;
	        		vm.line42HeadersAll = vm.form4562Data[34].dataRowList[0].dataCellList;
	        		vm.clonedHeaders19 = angular.copy(vm.line19HeadersAll);
	        		vm.clonedHeaders26 = angular.copy(vm.line26HeadersAll);
	        		vm.clonedHeaders42 = angular.copy(vm.line42HeadersAll);
	        		vm.line19Headers = vm.clonedHeaders19.splice(0, 8);
	        		vm.line26Headers = vm.clonedHeaders26.splice(0, 10);
	        		vm.line42Headers = vm.clonedHeaders42.splice(0, 7);

	        		vm.line19DetHeader = vm.form4562Data[11].dataRowList[0].dataCellList;
	        		console.log('Testing the commit');

	        		vm.form4562Data[18].dataRowList[0].dataCellList[5].value = 25;
	        		vm.form4562Data[18].dataRowList[0].dataCellList[7].value = "S/L";
	        		vm.form4562Data[19].dataRowList[0].dataCellList[5].value = 27.5;
	        		vm.form4562Data[19].dataRowList[0].dataCellList[6].value = "MM";
	        		vm.form4562Data[19].dataRowList[0].dataCellList[7].value = "S/L";
	        		vm.form4562Data[20].dataRowList[0].dataCellList[5].value = 39;
	        		vm.form4562Data[20].dataRowList[0].dataCellList[6].value = "MM";
	        		vm.form4562Data[20].dataRowList[0].dataCellList[7].value = "S/L";
	        		vm.form4562Data[22].dataRowList[0].dataCellList[7].value = "S/L";
	        		vm.form4562Data[23].dataRowList[0].dataCellList[5].value = 12;
	        		vm.form4562Data[23].dataRowList[0].dataCellList[7].value = "S/L";
	        		vm.form4562Data[24].dataRowList[0].dataCellList[5].value = 40;
	        		vm.form4562Data[24].dataRowList[0].dataCellList[6].value = "MM";
	        		vm.form4562Data[24].dataRowList[0].dataCellList[7].value = "S/L";

	        		for(var i=12; i<21; i++){
	        			var lineDetails = [];
	        			lineDetails = vm.form4562Data[i].dataRowList[0].dataCellList;

						lineDetails[4].value = (lineDetails[4].value == null) ? 0: parseInt(lineDetails[4].value);
						lineDetails[5].value = (lineDetails[5].value == null) ? 0: parseInt(lineDetails[5].value);

                        lineDetails[6].value = (lineDetails[6].value == null) ? "HY": lineDetails[6].value;
                        lineDetails[7].value = (lineDetails[7].value == null) ? "150 DB": lineDetails[7].value;

						lineDetails[8].value = (lineDetails[8].value == null) ? 0: parseInt(lineDetails[8].value);

						vm.line19DeprOrig.push(lineDetails[8].value);

	        			vm.line19AllDetails.push(lineDetails);
	        			vm.line19DepDed = vm.line19DepDed + parseInt(lineDetails[8].value);
	        		}

	        		vm.line20DetHeader = vm.form4562Data[21].dataRowList[0].dataCellList;

	        		for(var i=22; i<25; i++){
	        			var lineDetails = [];
	        			lineDetails = vm.form4562Data[i].dataRowList[0].dataCellList;

						lineDetails[4].value = (lineDetails[4].value == null) ? 0: parseInt(lineDetails[4].value);
						lineDetails[5].value = (lineDetails[5].value == null) ? 0: parseInt(lineDetails[5].value);

                        lineDetails[6].value = (lineDetails[6].value == null) ? "HY": lineDetails[6].value;

						lineDetails[8].value = (lineDetails[8].value == null) ? 0: parseInt(lineDetails[8].value);

						vm.line20DeprOrig.push(lineDetails[8].value);

						vm.line20AllDetails.push(lineDetails);
	        			vm.line20DepDed = vm.line20DepDed + parseInt(lineDetails[8].value);
	        		}

	        		vm.totAmortAmtPerTaxRet = vm.form4562Data[32].dataRowList[0].dataCellList;
	        		vm.form4562line25 = vm.form4562Data[26].dataRowList[0].dataCellList;
	        		vm.line25Amt = vm.form4562line25[2].value;
	        		if(vm.line25Amt == null){ vm.line25Amt = 0; }
	        		vm.totalDepExpPer4562 = vm.form4562Data[28].dataRowList[0].dataCellList;
	        		vm.unallocatedAmt = vm.form4562Data[30].dataRowList[0].dataCellList;


	        		vm.form4562line43 = vm.form4562Data[35].dataRowList[0].dataCellList;
	        		vm.line43Amt = vm.form4562line43[2].value;
	        		if(vm.line43Amt == null){ vm.line43Amt = 0; }

	        		vm.line14Amt = parseInt(vm.line14Amt, 0);
	                vm.line14AmtOrig = vm.line14Amt;

	                vm.line16Amt = parseInt(vm.line16Amt, 0);
	                vm.line16AmtOrig = vm.line16Amt;

	                vm.line17Amt = parseInt(vm.line17Amt, 0);
	                vm.line17AmtOrig = vm.line17Amt;

	                vm.line25Amt = parseInt(vm.line25Amt, 0);
	                vm.line25AmtOrig = vm.line25Amt;

	                vm.line43Amt = parseInt(vm.line43Amt, 0);
	                vm.line43AmtOrig = vm.line43Amt;

	        		vm.form4562Line26RecordsSize = vm.form4562Data[27].dataRowList.length;
	        		vm.form4562Line42RecordsSize = vm.form4562Data[34].dataRowList.length;

	        		var obj_id_section_26 =  vm.form4562Data[27].dataRowList[0].rowDimensions.object_id;
	        		var split_object26 = obj_id_section_26.split("~");
	        		var occur26 = split_object26[4];
	        		var endsWithZero_26 = false;
	        		if(occur26 == 0){
	        			endsWithZero_26 = true;
	        		}else{
	        			endsWithZero_26 = false;
	        		}
	        		var obj_id_section_42 =  vm.form4562Data[34].dataRowList[0].rowDimensions.object_id;
	        		var split_object42 = obj_id_section_42.split("~");
	        		var occur42 = split_object42[4];
	        		var endsWithZero_42 = false;
	        		if(occur42 == 0){
	        			endsWithZero_42 = true;
	        		}else{
	        			endsWithZero_42 = false;
	        		}

	        		if(vm.form4562Line26RecordsSize > 0 && !endsWithZero_26){

	        		    vm.form4562Line26Records = vm.modifyPropertyObjects(vm.form4562Data[27].dataRowList);

						vm.form4562Line26RecordsCopy = angular.copy(vm.form4562Line26Records);

	        			angular.forEach(vm.form4562Line26Records, function(value, key){
	        				$scope.displayViewValue_26.push(true);
	            			$scope.displayEditValue_26.push(false);

							$scope.displayDeleteIcon_26.push(true);

	            			if(value.dataCellList[2].value != 'VARIOUS') {
	                            value.dataCellList[2].value = new Date(value.dataCellList[2].value);
	                        }
	        				vm.depreDed26 = parseInt(vm.depreDed26) + parseInt(value.dataCellList[10].value);

	        				var clonedRecordData = angular.copy(vm.recordData_26);
	        				var objId = value.rowDimensions.object_id;
	        				var y = objId.split("~");
	                        var occ = y[4];
	                        var transDetailKey = y[3];

	                        angular.forEach(clonedRecordData, function(value, key) {
	                            value.trans_dtls_key = transDetailKey;
	                            value.occurence = occ;
	                        });

	                        console.log('line 26', clonedRecordData);

	                        vm.formData_26.editrows.push(clonedRecordData);
	        			})


	        			vm.form4562Line26RecordsOrig = angular.copy(vm.form4562Line26Records);
	        		}

	        		if(vm.form4562Line42RecordsSize > 0 && !endsWithZero_42){

	        			vm.form4562Line42Records = vm.form4562Data[34].dataRowList;
						vm.form4562Line42RecordsCopy = angular.copy(vm.form4562Line42Records);

						angular.forEach(vm.form4562Line42Records, function(value, key){

	        				$scope.displayViewValue_42.push(true);
	            			$scope.displayEditValue_42.push(false);

							$scope.displayDeleteIcon_42.push(true);

	            			if(value.dataCellList[2].value != 'VARIOUS') {
	                            value.dataCellList[2].value = new Date(value.dataCellList[2].value);
	                        }
	        				vm.depreDed42 = value.dataCellList[6].value;

	        				var clonedRecordData = angular.copy(vm.recordData_42);
	        				var objId = value.rowDimensions.object_id;
	        				var y = objId.split("~");
	                        var occ = y[4];
	                        var transDetailKey = y[3];
	                        angular.forEach(clonedRecordData, function(value, key) {
	                            value.trans_dtls_key = transDetailKey;
	                            value.occurence = occ;
	                        });
	                        vm.formData_42.editrows.push(clonedRecordData);
	        			})
	        			vm.form4562Line42RecordsOrig = angular.copy(vm.form4562Line42Records);
	        		}

	        		var object_id_14 = vm.form4562Data[6].dataRowList[0].rowDimensions.object_id;
	                var row_14 = object_id_14.split("~");
	                var transDetailKey_14 = row_14[3];
	                vm.v_form_key = parseInt(row_14[5]);
	                vm.recordData_line14[0].trans_dtls_key = transDetailKey_14;
	                vm.formData_line14.editrows.push(vm.recordData_line14);

	                var object_id_16 = vm.form4562Data[7].dataRowList[0].rowDimensions.object_id;
	                var row_16 = object_id_16.split("~");
	                var transDetailKey_16 = row_16[3];
	                vm.recordData_line16[0].trans_dtls_key = transDetailKey_16;
	                vm.formData_line16.editrows.push(vm.recordData_line16);

	                var object_id_17 = vm.form4562Data[9].dataRowList[0].rowDimensions.object_id;
	                var row_17 = object_id_17.split("~");
	                var transDetailKey_17 = row_17[3];
	                vm.recordData_line17[0].trans_dtls_key = transDetailKey_17;
	                vm.formData_line17.editrows.push(vm.recordData_line17);

	                var object_id_25 = vm.form4562Data[26].dataRowList[0].rowDimensions.object_id;
	                var row_25 = object_id_25.split("~");
	                var transDetailKey_25 = row_25[3];
	                vm.recordData_line25[0].trans_dtls_key = transDetailKey_25;
	                vm.formData_line25.editrows.push(vm.recordData_line25);

	                var object_id_43 = vm.form4562Data[35].dataRowList[0].rowDimensions.object_id;
	                var row_43 = object_id_43.split("~");
	                var transDetailKey_43 = row_43[3];
	                vm.recordData_line43[0].trans_dtls_key = transDetailKey_43;
	                vm.formData_line43.editrows.push(vm.recordData_line43);

	                var object_id_19a = vm.form4562Data[12].dataRowList[0].rowDimensions.object_id;
	                var row_19a = object_id_19a.split("~");
	                var transDetailKey_19a = row_19a[3];
	                for(var i=0; i<5; i++){
	                	vm.recordData_19a[i].trans_dtls_key = transDetailKey_19a;
	                }
	                vm.formData_line19a.editrows.push(vm.recordData_19a);

	                var object_id_19b = vm.form4562Data[13].dataRowList[0].rowDimensions.object_id;
	                var row_19b = object_id_19b.split("~");
	                var transDetailKey_19b = row_19b[3];
	                for(var i=0; i<5; i++){
	                	vm.recordData_19b[i].trans_dtls_key = transDetailKey_19b;
	                }
	                vm.formData_line19b.editrows.push(vm.recordData_19b);

	                var object_id_19c = vm.form4562Data[14].dataRowList[0].rowDimensions.object_id;
	                var row_19c = object_id_19c.split("~");
	                var transDetailKey_19c = row_19c[3];
	                for(var i=0; i<5; i++){
	                	vm.recordData_19c[i].trans_dtls_key = transDetailKey_19c;
	                }
	                vm.formData_line19c.editrows.push(vm.recordData_19c);

	                var object_id_19d = vm.form4562Data[15].dataRowList[0].rowDimensions.object_id;
	                var row_19d = object_id_19d.split("~");
	                var transDetailKey_19d = row_19d[3];
	                for(var i=0; i<5; i++){
	                	vm.recordData_19d[i].trans_dtls_key = transDetailKey_19d;
	                }
	                vm.formData_line19d.editrows.push(vm.recordData_19d);

	                var object_id_19e = vm.form4562Data[16].dataRowList[0].rowDimensions.object_id;
	                var row_19e = object_id_19e.split("~");
	                var transDetailKey_19e = row_19e[3];
	                for(var i=0; i<5; i++){
	                	vm.recordData_19e[i].trans_dtls_key = transDetailKey_19e;
	                }
	                vm.formData_line19e.editrows.push(vm.recordData_19e);

	                var object_id_19f = vm.form4562Data[17].dataRowList[0].rowDimensions.object_id;
	                var row_19f = object_id_19f.split("~");
	                var transDetailKey_19f = row_19f[3];
	                for(var i=0; i<5; i++){
	                	vm.recordData_19f[i].trans_dtls_key = transDetailKey_19f;
	                }
	                vm.formData_line19f.editrows.push(vm.recordData_19f);

	                var object_id_19g = vm.form4562Data[18].dataRowList[0].rowDimensions.object_id;
	                var row_19g = object_id_19g.split("~");
	                var transDetailKey_19g = row_19g[3];
	                for(var i=0; i<3; i++){
	                	vm.recordData_19g[i].trans_dtls_key = transDetailKey_19g;
	                }
	                vm.formData_line19g.editrows.push(vm.recordData_19g);

	                var object_id_19h = vm.form4562Data[19].dataRowList[0].rowDimensions.object_id;
	                var row_19h = object_id_19h.split("~");
	                var transDetailKey_19h = row_19h[3];
	                for(var i=0; i<3; i++){
	                	vm.recordData_19h[i].trans_dtls_key = transDetailKey_19h;
	                }
	                vm.formData_line19h.editrows.push(vm.recordData_19h);

	                var object_id_19i = vm.form4562Data[20].dataRowList[0].rowDimensions.object_id;
	                var row_19i = object_id_19i.split("~");
	                var transDetailKey_19i = row_19i[3];
	                for(var i=0; i<3; i++){
	                	vm.recordData_19i[i].trans_dtls_key = transDetailKey_19i;
	                }
	                vm.formData_line19i.editrows.push(vm.recordData_19i);

	                var object_id_20a = vm.form4562Data[22].dataRowList[0].rowDimensions.object_id;
	                var row_20a = object_id_20a.split("~");
	                var transDetailKey_20a = row_20a[3];
	                for(var i=0; i<4; i++){
	                	vm.recordData_20a[i].trans_dtls_key = transDetailKey_20a;
	                }
	                vm.formData_line20a.editrows.push(vm.recordData_20a);

	                var object_id_20b = vm.form4562Data[23].dataRowList[0].rowDimensions.object_id;
	                var row_20b = object_id_20b.split("~");
	                var transDetailKey_20b = row_20b[3];
	                for(var i=0; i<3; i++){
	                	vm.recordData_20b[i].trans_dtls_key = transDetailKey_20b;
	                }
	                vm.formData_line20b.editrows.push(vm.recordData_20b);

	                var object_id_20c = vm.form4562Data[24].dataRowList[0].rowDimensions.object_id;
	                var row_20c = object_id_20c.split("~");
	                var transDetailKey_20c = row_20c[3];
	                for(var i=0; i<3; i++){
	                	vm.recordData_20c[i].trans_dtls_key = transDetailKey_20c;
	                }
	                vm.formData_line20c.editrows.push(vm.recordData_20c);

	        		vm.totalDepExpPer4562[1].value = parseInt(vm.line14Amt) + parseInt(vm.line16Amt) + parseInt(vm.line17Amt) + parseInt(vm.line19DepDed) + parseInt(vm.line20DepDed) + parseInt(vm.line25Amt) + parseInt(vm.depreDed26);

					vm.totalDeprOriginal = vm.totalDepExpPer4562[1].value;

	        		vm.unallocatedAmt[1].value = parseInt(vm.form4562Data[4].dataRowList[0].dataCellList[1].value) - parseInt(vm.totalDepExpPer4562[1].value);

					vm.unallocatedAmtOrig =  vm.unallocatedAmt[1].value;

					if (vm.totAmortAmtPerTaxRet[1].value == null) { vm.totAmortAmtPerTaxRet[1].value = 0; }

	        	});
	        }

			function findAdPopulateLineAttribKeys(staticData,serverData,rowNum){
	       	 	var row1cellKey = [];
	       	 	angular.forEach(serverData.irsForm.formItemList[rowNum].dataRowList[0].dataCellList, function(item1) {
	       	 		angular.forEach(staticData, function(item2) {
	       	 			row1cellKey = item1.key.split('~');
	       	 			if(item2.Attribute_Name===row1cellKey[0]){
	       	 				item2.line_attrib_key = parseInt(row1cellKey[1]);
	       	 			}
	       	 		});
	       	 	});
	        }

			getForm4562Data();

			vm.addSection = function(line_no){
	        	console.log('Add a line to the passed line_no::::-------->>', line_no);
	        	var clonedRcordData;

	            if(line_no == 26) {
	            	console.log('Add a row for Line 26........');
	                $scope.data.partV.rows.push({});
	                clonedRcordData = angular.copy(vm.recordData_26);
	                vm.formData_26.addrows.push(clonedRcordData);
	                console.log('formData_26 = ', vm.formData_26);
	            }
	            else if(line_no == 42) {
	            	console.log('Add a row for Line 42........');
	                $scope.data.partVI.rows.push({});
	                clonedRcordData = angular.copy(vm.recordData_42);
	                vm.formData_42.addrows.push(clonedRcordData);
	                console.log('formData_42 = ', vm.formData_42);
	            }
	        }

			vm.sectionRemove = function(line, index) {
	            if(line == 26) {
	                $scope.data.partV.rows.splice(index, 1);
	                // TO DO
	                var depDeduction = vm.formData_26.addrows[index][9].Attribute_Value;
	                vm.totalDepExpPer4562[1].value = parseInt(vm.totalDepExpPer4562[1].value) - parseInt(depDeduction);
	                vm.unallocatedAmt[1].value = parseInt(vm.unallocatedAmt[1].value) + parseInt(depDeduction);
	                if (index in vm.form4562Amounts[0]) {
	                    vm.form4562Amounts[0].splice(index, 1);
	                }
	                //vm.temp0 = vm.temp0 - adjustAmount;
	                vm.formData_26.addrows.splice(index, 1);
	            }
	            else if(line == 42) {
	                $scope.data.partVI.rows.splice(index, 1);
	                // TO DO
	                var depDeduction = vm.formData_42.addrows[index][5].Attribute_Value;
	                vm.totalDepExpPer4562[1].value = vm.totalDepExpPer4562[1].value - parseInt(depDeduction);
	                vm.unallocatedAmt[1].value = vm.unallocatedAmt[1].value + parseInt(depDeduction);
	                if (index in vm.form4562Amounts[1]) {
	                    vm.form4562Amounts[1].splice(index, 1);
	                }
	                //vm.temp0 = vm.temp0 - adjustAmount;
	                vm.formData_42.addrows.splice(index, 1);
	            }
	        }


			vm.lineItemChange = function( line_no, rowIndex, colIndex){

				if(line_no == '14'){

	        		vm.formData_line14.editrows[rowIndex][colIndex].Attribute_Value = vm.line14Amt;
					console.log('--- line14Change() attribute value = ', vm.formData_line14.editrows[0][colIndex].Attribute_Value);
	    			vm.formData_line14.editrows[rowIndex][colIndex].is_dirty='Y';
					vm.formData_line14.editrows[rowIndex][colIndex].occurence = 1;
	        	}
	        	if(line_no == '16'){
	        		vm.formData_line16.editrows[rowIndex][colIndex].Attribute_Value = vm.line16Amt;
					console.log('--- line16Change() attribute value = ', vm.formData_line16.editrows[0][colIndex].Attribute_Value);
					vm.formData_line16.editrows[rowIndex][colIndex].is_dirty='Y';
					vm.formData_line16.editrows[rowIndex][colIndex].occurence = 1;
	        	}
	        	if(line_no == '17'){
	        		vm.formData_line17.editrows[rowIndex][colIndex].Attribute_Value = vm.line17Amt;
					console.log('--- line17Change() attribute value = ', vm.formData_line17.editrows[0][colIndex].Attribute_Value);
					vm.formData_line17.editrows[rowIndex][colIndex].is_dirty='Y';
					vm.formData_line17.editrows[rowIndex][colIndex].occurence = 1;
	        	}

	        	// 19 ------------------------------------------------------------------------------------------------------------------------------
	        	if(line_no == '19a' ) {
					vm.formData_line19a.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line19AllDetails[0][colIndex].value;
					console.log('--- line19a Change() attribute value = ', vm.formData_line19a.editrows[0][colIndex - 4].Attribute_Value);
					vm.formData_line19a.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
					vm.formData_line19a.editrows[rowIndex][colIndex - 4].occurence = 1;
				}
				if(line_no == '19b' ) {
					vm.formData_line19b.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line19AllDetails[1][colIndex].value;
					console.log('--- line19b Change() attribute value = ', vm.formData_line19b.editrows[0][colIndex - 4].Attribute_Value);
					vm.formData_line19b.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
					vm.formData_line19b.editrows[rowIndex][colIndex - 4].occurence = 1;
				}
				if(line_no == '19c' ) {
					vm.formData_line19c.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line19AllDetails[2][colIndex].value;
					console.log('--- line19c Change() attribute value = ', vm.formData_line19c.editrows[0][colIndex - 4].Attribute_Value);
					vm.formData_line19c.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
					vm.formData_line19c.editrows[rowIndex][colIndex - 4].occurence = 1;
				}
				if(line_no == '19d' ) {
					vm.formData_line19d.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line19AllDetails[3][colIndex].value;
					console.log('--- line19d Change() attribute value = ', vm.formData_line19d.editrows[0][colIndex - 4].Attribute_Value);
					vm.formData_line19d.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
					vm.formData_line19d.editrows[rowIndex][colIndex - 4].occurence = 1;
				}
				if(line_no == '19e' ) {
					vm.formData_line19e.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line19AllDetails[4][colIndex].value;
					console.log('--- line19e Change() attribute value = ', vm.formData_line19e.editrows[0][colIndex - 4].Attribute_Value);
					vm.formData_line19e.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
					vm.formData_line19e.editrows[rowIndex][colIndex - 4].occurence = 1;
				}
				if(line_no == '19f' ) {
					vm.formData_line19f.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line19AllDetails[5][colIndex].value;
					console.log('--- line19f Change() attribute value = ', vm.formData_line19f.editrows[0][colIndex - 4].Attribute_Value);
					vm.formData_line19f.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
					vm.formData_line19f.editrows[rowIndex][colIndex - 4].occurence = 1;
				}
				if(line_no == '19g' ) {
					if(colIndex == 4) {
						vm.formData_line19g.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line19AllDetails[6][colIndex].value;
						console.log('--- line19g Change() attribute value = ', vm.formData_line19g.editrows[0][colIndex - 4].Attribute_Value);
						vm.formData_line19g.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
						vm.formData_line19g.editrows[rowIndex][colIndex - 4].occurence = 1;
					}
					if(colIndex == 6) {
						vm.formData_line19g.editrows[rowIndex][colIndex - 5].Attribute_Value = vm.line19AllDetails[6][colIndex].value;
						console.log('--- line19g Change() attribute value = ', vm.formData_line19g.editrows[0][colIndex - 5].Attribute_Value);
						vm.formData_line19g.editrows[rowIndex][colIndex - 5].is_dirty = 'Y';
						vm.formData_line19g.editrows[0][colIndex - 5].occurence = 1;
					}
					if(colIndex == 8) {
						vm.formData_line19g.editrows[rowIndex][colIndex - 6].Attribute_Value = vm.line19AllDetails[6][colIndex].value;
						console.log('--- line19g Change() attribute value = ', vm.formData_line19g.editrows[0][colIndex - 6].Attribute_Value);
						vm.formData_line19g.editrows[rowIndex][colIndex - 6].is_dirty = 'Y';
						vm.formData_line19g.editrows[rowIndex][colIndex - 6].occurence = 1;
					}

				}
				if(line_no == '19h' ) {
					if(colIndex == 3 || colIndex == 4) {
						vm.formData_line19h.editrows[rowIndex][colIndex - 3].Attribute_Value = vm.line19AllDetails[7][colIndex].value;
						console.log('--- line19h Change() attribute value = ', vm.formData_line19h.editrows[0][colIndex - 3].Attribute_Value);
						vm.formData_line19h.editrows[rowIndex][colIndex - 3].is_dirty = 'Y';
						vm.formData_line19h.editrows[rowIndex][colIndex - 3].occurence = 1;
					}
					if(colIndex == 8) {
						vm.formData_line19h.editrows[rowIndex][colIndex - 6].Attribute_Value = vm.line19AllDetails[7][colIndex].value;
						console.log('--- line19h Change() attribute value = ', vm.formData_line19h.editrows[0][colIndex - 6].Attribute_Value);
						vm.formData_line19h.editrows[rowIndex][colIndex - 6].is_dirty = 'Y';
						vm.formData_line19h.editrows[rowIndex][colIndex - 6].occurence = 1;
					}

				}
				if(line_no == '19i' ) {
					if(colIndex == 3 || colIndex == 4) {
						vm.formData_line19i.editrows[rowIndex][colIndex - 3].Attribute_Value = vm.line19AllDetails[8][colIndex].value;
						console.log('--- line19i Change() attribute value = ', vm.formData_line19i.editrows[0][colIndex - 3].Attribute_Value);
						vm.formData_line19i.editrows[rowIndex][colIndex - 3].is_dirty = 'Y';
						vm.formData_line19i.editrows[rowIndex][colIndex - 3].occurence = 1;
					}
					if(colIndex == 8) {
						vm.formData_line19i.editrows[rowIndex][colIndex - 6].Attribute_Value = vm.line19AllDetails[8][colIndex].value;
						console.log('--- line19i Change() attribute value = ', vm.formData_line19i.editrows[0][colIndex - 6].Attribute_Value);
						vm.formData_line19i.editrows[rowIndex][colIndex - 6].is_dirty = 'Y';
						vm.formData_line19i.editrows[rowIndex][colIndex - 6].occurence = 1;
					}

				}

				// 20 ----------------------------------------------------------------------------------------------------------------------------------------
				if(line_no == '20a' ) {
					if(colIndex == 4 || colIndex == 5 || colIndex == 6) {
						vm.formData_line20a.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line20AllDetails[0][colIndex].value;
						console.log('--- line120a Change() attribute value = ', vm.formData_line20a.editrows[0][colIndex - 4].Attribute_Value);
						vm.formData_line20a.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
						vm.formData_line20a.editrows[rowIndex][colIndex - 4].occurence = 1;
					}
					if(colIndex == 8) {
						vm.formData_line20a.editrows[rowIndex][colIndex - 5].Attribute_Value = vm.line20AllDetails[0][colIndex].value;
						console.log('--- line120a Change() attribute value = ', vm.formData_line20a.editrows[0][colIndex - 5].Attribute_Value);
						vm.formData_line20a.editrows[rowIndex][colIndex - 5].is_dirty = 'Y';
						vm.formData_line20a.editrows[rowIndex][colIndex - 5].occurence = 1;
					}
				}

				if(line_no == '20b' ) {
					if(colIndex == 4 ) {
						vm.formData_line20b.editrows[rowIndex][colIndex - 4].Attribute_Value = vm.line20AllDetails[1][colIndex].value;
						console.log('--- line20b Change() attribute value = ', vm.formData_line20b.editrows[0][colIndex - 4].Attribute_Value);
						vm.formData_line20b.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
						vm.formData_line20b.editrows[rowIndex][colIndex - 4].occurence = 1;
					}
					if(colIndex == 6 ) {
						vm.formData_line20b.editrows[rowIndex][colIndex - 5].Attribute_Value = vm.line20AllDetails[1][colIndex].value;
						console.log('--- line20b Change() attribute value = ', vm.formData_line20b.editrows[0][colIndex - 5].Attribute_Value);
						vm.formData_line20b.editrows[rowIndex][colIndex - 4].is_dirty = 'Y';
						vm.formData_line20b.editrows[rowIndex][colIndex - 4].occurence = 1;
					}
					if(colIndex == 8) {
						vm.formData_line20b.editrows[rowIndex][colIndex - 6].Attribute_Value = vm.line20AllDetails[1][colIndex].value;
						console.log('--- line20b Change() attribute value = ', vm.formData_line20b.editrows[0][colIndex - 6].Attribute_Value);
						vm.formData_line20b.editrows[rowIndex][colIndex - 6].is_dirty = 'Y';
						vm.formData_line20b.editrows[rowIndex][colIndex - 6].occurence = 1;
					}
				}

				if(line_no == '20c' ) {
					if(colIndex == 3 || colIndex == 4) {
						vm.formData_line20c.editrows[rowIndex][colIndex - 3].Attribute_Value = vm.line20AllDetails[2][colIndex].value;
						console.log('--- line20c Change() attribute value = ', vm.formData_line20c.editrows[0][colIndex - 3].Attribute_Value);
						vm.formData_line20c.editrows[rowIndex][colIndex - 3].is_dirty = 'Y';
						vm.formData_line20c.editrows[rowIndex][colIndex - 3].occurence = 1;
					}
					if(colIndex == 8) {
						vm.formData_line20c.editrows[rowIndex][colIndex - 6].Attribute_Value = vm.line20AllDetails[2][colIndex].value;
						console.log('--- line20c Change() attribute value = ', vm.formData_line20c.editrows[0][colIndex - 6].Attribute_Value);
						vm.formData_line20c.editrows[rowIndex][colIndex - 6].is_dirty = 'Y';
						vm.formData_line20c.editrows[rowIndex][colIndex - 6].occurence = 1;
					}
				}

				//----------------------------------------------------------------------------------------------------------------------------------------

	        	if(line_no == '25'){
	        		vm.formData_line25.editrows[rowIndex][colIndex].Attribute_Value = vm.line25Amt;
					console.log('--- line25Change() attribute value = ', vm.formData_line25.editrows[0][colIndex].Attribute_Value);
	    			vm.formData_line25.editrows[rowIndex][colIndex].is_dirty='Y';
					vm.formData_line25.editrows[rowIndex][colIndex].occurence = 1;
	        	}

	        	if(line_no == '26') {

                    console.log('colIndex = ', colIndex, ' dataCellList[' , colIndex+1 , '].value = ' , vm.form4562Line26Records[rowIndex].dataCellList[colIndex+1].value);

                    if(colIndex == 1) {

                        if(vm.form4562Line26Records[rowIndex].dataCellList[colIndex+1].value === 'VARIOUS')
                            vm.formData_26.editrows[rowIndex][colIndex].Attribute_Value = 'VARIOUS';
                        else {
                            var date = vm.form4562Line26Records[rowIndex].dataCellList[colIndex+1].value  ;
                            vm.formData_26.editrows[rowIndex][colIndex].Attribute_Value = vm.convertDate(date);
                        }
                        vm.formData_26.editrows[rowIndex][colIndex].is_dirty='Y';

                    }

                    else {

                        vm.formData_26.editrows[rowIndex][colIndex].Attribute_Value = vm.form4562Line26Records[rowIndex].dataCellList[colIndex+1].value;
                        vm.formData_26.editrows[rowIndex][colIndex].is_dirty='Y';
                    }

                }

                if(line_no == '42') {

                    if(colIndex == 1) {

                        if(vm.form4562Line42Records[rowIndex].dataCellList[colIndex+1].value == 'VARIOUS')
                            vm.formData_42.editrows[rowIndex][colIndex].Attribute_Value = 'VARIOUS';
                        else {
                            var date = vm.form4562Line42Records[rowIndex].dataCellList[colIndex+1].value  ;
                            vm.formData_42.editrows[rowIndex][colIndex].Attribute_Value = vm.convertDate(date);
                        }
                        vm.formData_42.editrows[rowIndex][colIndex].is_dirty='Y';

                    }
                    else {
                        vm.formData_42.editrows[rowIndex][colIndex].Attribute_Value = vm.form4562Line42Records[rowIndex].dataCellList[colIndex+1].value;
                        vm.formData_42.editrows[rowIndex][colIndex].is_dirty='Y';
                    }

                }

	        	if(line_no == '43'){
	        		vm.formData_line43.editrows[rowIndex][colIndex].Attribute_Value = vm.line43Amt;
					console.log('--- line43Change() attribute value = ', vm.formData_line43.editrows[0][colIndex].Attribute_Value);
	    			vm.formData_line43.editrows[rowIndex][colIndex].is_dirty='Y';
					vm.formData_line43.editrows[rowIndex][colIndex].occurence = 1;
	        	}
	        }

			vm.removeExistingRecord = function(line, index, object_id) {

	            console.log(' removeExistingRecord called for line & index ', line, index, object_id);
	            var x = object_id.split("~");
	            var occurrence =  x[4];
	            var transDetailKey = x[3];
	            var removeRow = {"tax_year": vm.taxYear, "trans_type_key": 9, "combination_key": vm.combinationKey, "group_obj_key": 201473, "form_key": 3, "line_no": line, "occurence": occurrence, "trans_dtls_key": transDetailKey, "trans_status": "A"};
	            var gainLossAtIndex;

	            if(line == 26) {

	                vm.formData_26.removerows.push(removeRow);
					var depDeduction = vm.form4562Line26Records[index].dataCellList[10].value;
					vm.totalDepExpPer4562[1].value = vm.totalDepExpPer4562[1].value - parseInt(depDeduction);
					vm.unallocatedAmt[1].value = vm.unallocatedAmt[1].value + parseInt(depDeduction);
					vm.form4562Line26Records.splice(index, 1);
	            }
	            else if(line == 42) {

	                vm.formData_42.removerows.push(removeRow);
	                vm.form4562Line42Records.splice(index, 1);
	            }

	            window.alert("Please click on 'Save and Proceed' to Delete this Transcation. Otherwise click on 'Cancel'");

	        }

			vm.pencilButtonClicked = function(line, index, object_id) {
	            console.log(' pencilButtonClicked called for line & index ', line, index, object_id);

	            if(line == 26) {

	                $scope.displayViewValue_26[index] = !$scope.displayViewValue_26[index];
	                $scope.displayEditValue_26[index] = !$scope.displayEditValue_26[index];

					console.log('original values for row ' , index , ' = ', vm.form4562Line26RecordsCopy[index].dataCellList);


	                if($scope.displayViewValue_26[index] == false && $scope.displayEditValue_26[index] == true) {

						//disable delete button
						$scope.displayDeleteIcon_26[index] = false;

	                	vm.formData_26.editrows[index][0].Attribute_Value = vm.form4562Line26Records[index].dataCellList[1].value;

	                    if(vm.form4562Line26Records[index].dataCellList[2].value === 'VARIOUS')
	                        vm.formData_26.editrows[index][1].Attribute_Value = vm.form4562Line26Records[index].dataCellList[2].value;
	                    else {
	                        var date = vm.form4562Line26Records[index].dataCellList[2].value  ;
	                        vm.formData_26.editrows[index][1].Attribute_Value = vm.convertDate(date);
	                    }
	                    vm.formData_26.editrows[index][2].Attribute_Value = vm.form4562Line26Records[index].dataCellList[3].value;
	                    vm.formData_26.editrows[index][3].Attribute_Value = vm.form4562Line26Records[index].dataCellList[4].value;
	                    vm.formData_26.editrows[index][4].Attribute_Value = vm.form4562Line26Records[index].dataCellList[5].value;
	                    vm.formData_26.editrows[index][5].Attribute_Value = vm.form4562Line26Records[index].dataCellList[6].value;
	                    vm.formData_26.editrows[index][6].Attribute_Value = vm.form4562Line26Records[index].dataCellList[7].value;
                        vm.formData_26.editrows[index][7].Attribute_Value = vm.form4562Line26Records[index].dataCellList[8].value;
                        vm.formData_26.editrows[index][8].Attribute_Value = vm.form4562Line26Records[index].dataCellList[9].value;
	                    vm.formData_26.editrows[index][9].Attribute_Value = vm.form4562Line26Records[index].dataCellList[10].value;

	                }

	                if($scope.displayViewValue_26[index] == true && $scope.displayEditValue_26[index] == false) {

						//enable delete button
	                	$scope.displayDeleteIcon_26[index] = true;

	                    for (var i = 0; i <= 9; i++) {
	                        vm.formData_26.editrows[index][i].is_dirty='N';
	                    }

	                    for (var i = 0; i <= 9; i++) {

	                    	if(i == 1) {
	                    		if(vm.form4562Line26RecordsCopy[index].dataCellList[i+1].value == 'VARIOUS') {
									vm.form4562Line26Records[index].dataCellList[i+1].value = vm.form4562Line26RecordsCopy[index].dataCellList[i+1].value;
								}
	                    		else {
									vm.form4562Line26Records[index].dataCellList[i+1].value = new Date(vm.form4562Line26RecordsCopy[index].dataCellList[i+1].value);
								}
							}
	                    	else {
								vm.form4562Line26Records[index].dataCellList[i+1].value = vm.form4562Line26RecordsCopy[index].dataCellList[i+1].value;
							}

	                    }

						vm.setDepDeduct('26', false, index);

	                }

	            }

	            else if(line == 42) {

	            	 $scope.displayViewValue_42[index] = !$scope.displayViewValue_42[index];
	                 $scope.displayEditValue_42[index] = !$scope.displayEditValue_42[index];
	                 if($scope.displayViewValue_42[index] == false && $scope.displayEditValue_42[index] == true) {

	                 	//console.log('original values for row ' , index , ' = ', vm.form4562Line42RecordsCopy[index].dataCellList);

						 //disable delete button
						 $scope.displayDeleteIcon_42[index] = false;

	                     vm.formData_42.editrows[index][0].Attribute_Value = vm.form4562Line42Records[index].dataCellList[1].value;

	                     if(vm.form4562Line42Records[index].dataCellList[2].value === 'VARIOUS')
	                         vm.formData_42.editrows[index][1].Attribute_Value = vm.form4562Line42Records[index].dataCellList[2].value;
	                     else {
	                    	 var date = vm.form4562Line42Records[index].dataCellList[2].value  ;
	                         vm.formData_42.editrows[index][1].Attribute_Value = vm.convertDate(date);
	                     }

	                     vm.formData_42.editrows[index][2].Attribute_Value = vm.form4562Line42Records[index].dataCellList[3].value;
	                     vm.formData_42.editrows[index][3].Attribute_Value = vm.form4562Line42Records[index].dataCellList[4].value;
	                     vm.formData_42.editrows[index][4].Attribute_Value = vm.form4562Line42Records[index].dataCellList[5].value;
	                     vm.formData_42.editrows[index][5].Attribute_Value = vm.form4562Line42Records[index].dataCellList[6].value;

	                 }

	                 if($scope.displayViewValue_42[index] == true && $scope.displayEditValue_42[index] == false) {

						 //enable delete button
						 $scope.displayDeleteIcon_42[index] = true;

	                     for (var i = 0; i <= 5; i++) {
	                         vm.formData_42.editrows[index][i].is_dirty='N';
	                     }

	                     for (var i = 0; i <= 5; i++) {
	                     	if(i == 1) {
								if(vm.form4562Line42RecordsCopy[index].dataCellList[i+1].value == 'VARIOUS') {
									vm.form4562Line42Records[index].dataCellList[i+1].value = vm.form4562Line42RecordsCopy[index].dataCellList[i+1].value;
								}
								else {
									vm.form4562Line42Records[index].dataCellList[i+1].value = new Date(vm.form4562Line42RecordsCopy[index].dataCellList[i+1].value);
								}
							}
	                     	else {
								vm.form4562Line42Records[index].dataCellList[i+1].value = vm.form4562Line42RecordsCopy[index].dataCellList[i+1].value;
							}

	                     }

	                 }
	            }
			}

		vm.setDepDeduct = function (line_no, edit, index) {
			var temp02 = 0;
			var temp0E = 0;
			var temp2E = 0;
			var beforeEdit = 0;
			var depDeduction26 = 0;
			var amortization42 = 0;

			//console.log("The amount for line item is changed and the line no is:---", line_no);

			if(line_no == '14' || line_no == '16' || line_no == '17' ||
				line_no == '19a' || line_no == '19b' || line_no == '19c' || line_no == '19d' || line_no == '19e' || line_no == '19f' || line_no == '19g' || line_no == '19h' || line_no == '19i' ||
				line_no == '20a' || line_no == '20b' || line_no == '20c' ||
				line_no == '25' || line_no == '26'
			)
			{

				var deltaDepr = vm.line14Amt + vm.line16Amt + vm.line17Amt +

					vm.line19AllDetails[0][8].value  + vm.line19AllDetails[1][8].value  + vm.line19AllDetails[2][8].value  +
					vm.line19AllDetails[3][8].value  + vm.line19AllDetails[4][8].value  + vm.line19AllDetails[5][8].value  +
					vm.line19AllDetails[6][8].value  + vm.line19AllDetails[7][8].value  + vm.line19AllDetails[8][8].value  +

					vm.line20AllDetails[0][8].value  + vm.line20AllDetails[1][8].value  + vm.line20AllDetails[2][8].value +

					vm.line25Amt ;


				//if(line_no == '26') {

					if(vm.formData_26.addrows.length > 0)	{

						depDeduction26 = vm.formData_26.addrows[index][9].Attribute_Value;

						if(depDeduction26 == undefined) {depDeduction26 = 0;}

						if (index in vm.form4562Amounts[0]) {
							vm.form4562Amounts[0].splice(index, 1);
						}
						vm.form4562Amounts[0].splice(index, 0, depDeduction26);

						angular.forEach(vm.form4562Amounts[0], function(value, key){
							temp02 = temp02 + parseInt(value);
						});
					}

					vm.temp02 = temp02;
					angular.forEach(vm.form4562Line26Records, function(value, key){
						angular.forEach(value.dataCellList, function(value1, key1){
							if(key1 == 10)
								temp2E = temp2E + parseInt(value1.value);
						})
					});
					vm.temp2E = temp2E;

				//}

				vm.totalDepExpPer4562[1].value = deltaDepr + vm.temp02 + vm.temp2E;

				vm.unallocatedAmt[1].value =  vm.form4562DepExpPerTaxRet[1].value - (deltaDepr + vm.temp02 + vm.temp2E);
			}
			else if(line_no == '42'){

				if(edit == false){
					amortization42 = vm.formData_42.addrows[index][5].Attribute_Value;
					if(amortization42 == undefined) {amortization42 = 0;}
					if (index in vm.form4562Amounts[1]) {
						vm.form4562Amounts[1].splice(index, 1);
					}
					vm.form4562Amounts[1].splice(index, 0, amortization42);
					angular.forEach(vm.form4562Amounts[1], function(value, key){
						temp02 = temp02 + parseInt(value);
					});

					vm.temp02 = temp02;
					console.log('The Amortization value is :::::::: ------>', amortization42);
				}
				if(edit == true){
					amortization42 = vm.formData_42.editrows[index][6].Attribute_Value;
					if(amortization42 == undefined) {amortization42 = 0;}
					beforeEdit =   vm.form4562Line42RecordsOrig[index].dataCellList[6].value;
					vm.formData_42.editrows[index][6].Attribute_Value = amortization42;
					vm.form4562Line42Records[index].dataCellList[6].value = amortization42;
					console.log('The Amortization value  for Editted line is :::::::: ------>', vm.form4562Line42Records[index].dataCellList[6].value);
					temp0E = amortization42 - beforeEdit;

					vm.temp10E = vm.temp10E + temp0E ;
				}
			}

	    }

		$scope.$on('workflowSave',function(event,data){
			vm.save();
	   })

		vm.save = function(){

			var singlerow = [];
            var method_convention;

			console.log('--- save function called ---');

			if(vm.formData_line14.editrows[0][0].is_dirty =='Y')
				vm.formData.editrows.push(vm.formData_line14.editrows[0]);

			if(vm.formData_line16.editrows[0][0].is_dirty =='Y')
				vm.formData.editrows.push(vm.formData_line16.editrows[0]);

			if(vm.formData_line17.editrows[0][0].is_dirty =='Y')
				vm.formData.editrows.push(vm.formData_line17.editrows[0]);

			if(vm.formData_line25.editrows[0][0].is_dirty =='Y')
				vm.formData.editrows.push(vm.formData_line25.editrows[0]);

			if(vm.formData_line43.editrows[0][0].is_dirty =='Y')
				vm.formData.editrows.push(vm.formData_line43.editrows[0]);

            // -------------------------- line 19 ------------------------------------------
            if(vm.row_is_dirty(vm.formData_line19a.editrows))
                vm.formData_19.push(vm.formData_line19a.editrows);

            if(vm.row_is_dirty(vm.formData_line19b.editrows))
                vm.formData_19.push(vm.formData_line19b.editrows);

            if(vm.row_is_dirty(vm.formData_line19c.editrows))
                vm.formData_19.push(vm.formData_line19c.editrows);

            if(vm.row_is_dirty(vm.formData_line19d.editrows))
                vm.formData_19.push(vm.formData_line19d.editrows);

            if(vm.row_is_dirty(vm.formData_line19e.editrows))
                vm.formData_19.push(vm.formData_line19e.editrows);

            if(vm.row_is_dirty(vm.formData_line19f.editrows))
                vm.formData_19.push(vm.formData_line19f.editrows);

            if(vm.row_is_dirty(vm.formData_line19g.editrows))
                vm.formData_19.push(vm.formData_line19g.editrows);

            if(vm.row_is_dirty(vm.formData_line19h.editrows))
                vm.formData_19.push(vm.formData_line19h.editrows);

            if(vm.row_is_dirty(vm.formData_line19i.editrows))
                vm.formData_19.push(vm.formData_line19i.editrows);

            angular.forEach(vm.formData_19, function(value, key) {

                angular.forEach(value, function(value1, key1){

                    angular.forEach(value1, function(value2, key2) {
                        if(value2.is_dirty == 'Y') {
                            console.log(key2, value2);
                            singlerow.push(value2);
                        }
                    });

                    if(singlerow.length > 0)
                        vm.formData.editrows.push(singlerow);

                });

                singlerow = [];

            });

            // -------------------------------- line 20 -------------------------------------

            if(vm.row_is_dirty(vm.formData_line20a.editrows))
                vm.formData_20.push(vm.formData_line20a.editrows);

            if(vm.row_is_dirty(vm.formData_line20b.editrows))
                vm.formData_20.push(vm.formData_line20b.editrows);

            if(vm.row_is_dirty(vm.formData_line20c.editrows))
                vm.formData_20.push(vm.formData_line20c.editrows);

            angular.forEach(vm.formData_20, function(value, key) {

                angular.forEach(value, function(value1, key1){

                    angular.forEach(value1, function(value2, key2) {
                        if(value2.is_dirty == 'Y') {
                            console.log(key2, value2);
                            singlerow.push(value2);
                        }

                    });

                    if(singlerow.length > 0) {
                        vm.formData.editrows.push(singlerow);

                    }
                });

                singlerow = [];
            });


            console.log('formData_26 = ', vm.formData_26);
            console.log('formData_42 = ', vm.formData_42);

            // -------------------------- line 26 ---------------------------------

            angular.forEach(vm.formData_26.addrows, function(value, key) {

                if(value[1].Attribute_Value != 'VARIOUS') {
                    value[1].Attribute_Value = vm.convertDate(value[1].Attribute_Value);
                }

                // concat method & convention
                method_convention = value[6].Attribute_Value + "~" + value[7].Attribute_Value;
                value.splice(6, 1);
                value[6].Attribute_Value = method_convention;

                vm.formData.addrows.push(value);
            });

            singlerow = [];

            angular.forEach(vm.formData_26.editrows, function(value, key) {

                if(vm.isDirty(value)) {
                    angular.forEach(value, function(value1, key1){
                        singlerow.push(value1);

                    });
                }

                if(singlerow.length > 0) {

                    // concat method & convention
                    method_convention  = singlerow[6].Attribute_Value + "~" + singlerow[7].Attribute_Value;
                    singlerow.splice(6,1);
                    singlerow[6].Attribute_Value = method_convention;

                    vm.formData.editrows.push(singlerow);
                }

                singlerow = [];
            });

            angular.forEach(vm.formData_26.removerows, function(value, key) {
                vm.formData.removerows.push(value);
            });


            // -------------------------- line 42 ---------------------------------
            angular.forEach(vm.formData_42.addrows, function(value, key) {

                if(value[1].Attribute_Value != 'VARIOUS') {
                    value[1].Attribute_Value = vm.convertDate(value[1].Attribute_Value);
                }
                vm.formData.addrows.push(value);
            });

            singlerow = [];


            angular.forEach(vm.formData_42.editrows, function(value, key) {

                if(vm.isDirty(value)) {
                    angular.forEach(value, function(value1, key1){
                        singlerow.push(value1);
                    });
                }

                if(singlerow.length > 0) {
                    vm.formData.editrows.push(singlerow);
                }

                singlerow = [];
            });

            angular.forEach(vm.formData_42.removerows, function(value, key) {
                vm.formData.removerows.push(value);
            });


            console.log('-------------- formData -------------------- = ', vm.formData);

            if((null!=vm.formData.addrows && vm.formData.addrows.length > 0) || (null!= vm.formData.editrows && vm.formData.editrows.length > 0) || (null!= vm.formData.removerows && vm.formData.removerows.length >0 )){

            	vm.formData.addrows.forEach((item) => item.forEach((childItem) => childItem.form_key = vm.v_form_key));
                vm.formData.editrows.forEach((item) => item.forEach((childItem) => childItem.form_key = vm.v_form_key));
                vm.formData.removerows.forEach((item) => item.form_key = vm.v_form_key);

                vm.mainData = [];
                vm.mainData.push(vm.formData);

                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=70l3gn";
                Form4562Factory.save4562Form(url,vm.mainData, rowData).then(function (data) {
					console.log('The result for the save proc is :::------->',data);
					if(null!=data.jsonObject && data.jsonObject.length > 0){
						AlertService.add("danger", "Please correct the errors below", 4000);
					}
					else {
						if(data.callSuccess == "1" && data.errorMessage == "null"){
							vm.cancel();
							var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
							$rootScope.$emit('gridUpdate', args);
							AlertService.add("success", "Transactions saved successfully", 4000);
						}else{
							AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support");
						}
					}

				});

            }else{
            	if($uibModalInstance) {
        			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
        			vm.cancel = function () {
        				var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
        				$rootScope.$emit('gridUpdate', args);
        				$uibModalInstance.close();
        				vm.modal_name = null;
        			};
        		} else {
        			vm.modal_name = null;
        		}
            }

            vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
            //$uibModalInstance.dismiss('cancel');

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);

		}

        vm.cancel = function () {
        	if($uibModalInstance) {
    			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
    			vm.cancel = function () {
    				var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
    				$rootScope.$emit('gridUpdate', args);
    				$uibModalInstance.close();
    				vm.modal_name = null;
    			};
    		} else {
    			vm.modal_name = null;
    		}
        };

		vm.modifyPropertyObjects = function(propertyObjects) {

            var clonedPropertyObjects = angular.copy(propertyObjects);

            angular.forEach(clonedPropertyObjects, function(value, key){

                var clonedCellData = angular.copy(value.dataCellList[7]);
                value.dataCellList.splice(8, 0, clonedCellData);

                var methodConventionValue = value.dataCellList[7].value;

                value.dataCellList[7].value = methodConventionValue.split('~')[0];  // method part
                value.dataCellList[8].value = methodConventionValue.split('~')[1];  // convention part

            });

            console.log('clonedPropertyObjects ================================================== ', clonedPropertyObjects);

		    return clonedPropertyObjects;
        }


		vm.convertDate = function (dateObject) {
			if (dateObject) {
				var month = (dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1);
				var date = dateObject.getDate() < 10 ? '0' +  dateObject.getDate() : dateObject.getDate();
				var year = dateObject.getFullYear();
				return month + '/' + date + '/' + year;
			} else {
				return null;
			}
		}

		vm.row_is_dirty = function(row) {
            var isDirtyRow = false;
            angular.forEach(row, function(value, key){
                angular.forEach(value, function(value1, key1){
                    if(!isDirtyRow) {
                        if(value1.is_dirty == 'Y') {
                            isDirtyRow = true;
                        }
                    }
                });
            });

            return isDirtyRow;
        }


        vm.isDirty = function(row) {
            var isDirtyRow = false;
            angular.forEach(row, function(value, key){
                    if(!isDirtyRow) {
                        if(value.is_dirty == 'Y') {
                            isDirtyRow = true;
                        }
                    }

            });

            return isDirtyRow;
        }


		// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		// Form view data object..
		$scope.FormViewData = {
			partI:{
				title:"Election To Expense Certain Property Under Section 179",
				columns:["Form 4562 Line","Description","Amount"],
				// columns1:["","(a) Description of property","(b) Cost (business use only)","(c) Elected cost"],
				rows:[{
					num:"1",
					descriptionOfProperty:"Maximum amount (see instructions)"
				},{
					num:"2",
					descriptionOfProperty:"Total cost of section 179 property placed in service (see instructions)"
				},{
					num:"3",
					descriptionOfProperty:"Threshold cost of section 179 property before reduction in limitation (see instructions)"
				},{
					num:"4",
					descriptionOfProperty:"Reduction in limitation. Subtract line 3 from line 2. If zero or less, enter -0-"
				},{
					num:"5",
					descriptionOfProperty:"Dollar limitation for tax year. Subtract line 4 from line 1. If zero or less, enter -0-. If married filing separately, see    instructions"
				},{
					num:"7",
					descriptionOfProperty:"Listed property. Enter the amount from line 29"
				},{
					num:"8",
					descriptionOfProperty:"Total elected cost of section 179 property. Add amounts in column (c), lines 6 and 7"
				},{
					num:"9",
					descriptionOfProperty:"Tentative deduction. Enter the smaller of line 5 or line 8"
				},{
					num:"10",
					descriptionOfProperty:"Carryover of disallowed deduction from line 13 of your 2016 Form 4562"
				},{
					num:"11",
					descriptionOfProperty:"Business income limitation. Enter the smaller of business income (not less than zero) or line 5 (see instructions)"
				},{
					num:"12",
					descriptionOfProperty:"	Section 179 expense deduction. Add lines 9 and 10, but don’t enter more than line 11"
				},{
					num:"13",
					descriptionOfProperty:"Carryover of disallowed deduction to 2018. Add lines 9 and 10, less line 12"
				}],
			},
			partII:{
				title:"Special Depreciation Allowance and Other Depreciation",
				rows:[
					{num:"14",value:"Special depreciation allowance for qualified property (other than listed property) placed in service during the tax year",},
					{num:"15",value:"Property subject to section 168(f)(1) election"},
					{num:"16",value:"Other depreciation (including ACRS)"}
				]
			},partIII:{
				title:"MACRS Depreciation",
				table:[{num:"17",value:"MACRS deductions for assets placed in service in tax years beginning before 2017"},
					{num:"18",value:"If you are electing to group any assets placed in service during the tax year into one or more general asset accounts, check here"}
				],
				sections: [{
					title: "Section B- Assets Placed in Service During 2017 Tax Year Using the General Depreciation System",
					columns: ["(a) Classification of property","(b) Month and year placed in service","(c) Basis for depreciation (business/investment use only--see instructions)","(d) Recovery period","(e) Convention","(f) Method","(g) Depreciation deduction"],
					rows: [
						{
							num:19,
							classification: "a 3-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: "",
							method: "",
							depricationDeduction: ""
						}, {
							classification: "b 5-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: "",
							method: "",
							depricationDeduction: ""
						}, {
							classification: "c 7-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension:"",
							method: "",
							depricationDeduction: ""
						}, {
							classification: "d 10-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: "",
							method: "",
							depricationDeduction: ""
						},{
							classification: "e 15-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: "",
							method: "",
							depricationDeduction: ""
						}, {
							classification: "f 20-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "",
							convension: "",
							method: "",
							depricationDeduction: ""
						}, {
							classification: "g 25-year property",
							placedInService: {disabled: true},
							basisForDeprication: "",
							recoveryPeriod: "25 Yrs",
							convension: "",
							method:"S/L",
							depricationDeduction: ""
						},{
							classification: "h Residential rental property",
							placedInService: {val: 'VARIOUS'},
							basisForDeprication: "",
							recoveryPeriod: "27.5 Yrs",
							convension: "MM",
							method: "S/L",
							depricationDeduction: ""
						},{
							classification: "i Nonresidential real property",
							placedInService: {val: 'VARIOUS'},
							basisForDeprication: "",
							recoveryPeriod: "39 Yrs",
							convension:"MM",
							method: "S/L",
							depricationDeduction: ""
						}]
				}, {
					title: "Section C- Assets Placed in Service During 2017 Tax Year Using the Alternative Depreciation System",
					columns: [
						"(a) Classification of property","(b) Month and year placed in service","(c) Basis for depreciation (business/investment use only--see instructions)",
						"(d) Recovery period","(e) Convention","(f) Method","(g) Depreciation deduction"
					],
					rows: [{
						num:20,
						classification: "a Class life",
						placedInService: {disabled: true},
						basisForDeprication: "",
						recoveryPeriod: "",
						convension: "",
						method: "S/L",
						depricationDeduction: ""
					}, {
						classification: "b 12-year",
						placedInService: {disabled: true},
						basisForDeprication: "",
						recoveryPeriod: '12 Yrs',
						convension: "",
						method: "S/L",
						depricationDeduction: ""
					}, {
						classification: "c 40-year",
						placedInService: {val: 'VARIOUS'},
						basisForDeprication: "",
						recoveryPeriod: "40 Yrs",
						convension: "MM",
						method:"S/L",
						depricationDeduction: ""
					}]
				}]
			},
			partV:{
				title:"Listed Property",
				rows:[{
					num:"24",
					value:"a Do you have evidence to support the business/investment use claimed?",
					yes:"Yes",
					no:"No"
				},
					{
						num:"",
						value:"b If “Yes,” is the evidence written?",
						yes:"Yes",
						no:"No"
					}],
				section1:{
					columns:["(a) Type of property (list vehicles first)",
						"(b) Date placed in service",
						"(c) Business/ investment use percentage",
						"(d) Cost or other basis",
						"(e) Basis for depreciation (business/investment use only)",
						"(f) Recovery period",
						"(g) Method/ Convention",
						"(h) Depreciation deduction",
						"(i) Elected section 179 cost"
					],
					rows:[[
						{text: 25},
						{size: 7, text: "Special depreciation allowance for qualified listed property placed in service during the tax year and used more than 50% in a qualified business use (see instructions)."},
						{},
						{}
					], [
						{text: 26},
						{size: 9, text: "Property used more than 50% in a qualified business use:"}
					], [
						{}, {}, {}, {units: '%'}, {}, {}, {}, {}, {}, {}
					], [
						{}, {}, {}, {units: '%'}, {}, {}, {}, {}, {}, {}
					], [
						{}, {}, {}, {units: '%'}, {}, {}, {}, {}, {}, {}
					], [
						{text: 27},
						{size: 9, text: "Property used 50% or less than in a qualified business use:"}
					], [
						{}, {}, {}, {units: '%'}, {}, {}, {}, {units: 'S/L'}, {}, {}
					], [
						{}, {}, {}, {units: '%'}, {}, {}, {}, {units: 'S/L'}, {}, {}
					], [
						{}, {}, {}, {units: '%'}, {}, {}, {}, {units: 'S/L'}, {}, {}
					], [
						{text: 28},
						{size: 7, text: "Add amounts in column (h), lines 25 through 27. Enter here and on line 21, page 1"},
						{},
						{}
					], [
						{text: 29},
						{size: 9, text: "Add amounts in column (i), line 26. Enter here and on line 7, page 1"}
					]]
				},
				sections:[{
					title:"Section B—Information on Use of Vehicles",
					columns:["(a) Vehicle 1","(b) Vehicle 2","(c) Vehicle 3","(d) Vehicle 4","(e) Vehicle 5","(f) Vehicle 6"],
					rows:[{
						num:"30",
						value:"Total business/investment miles driven during the year (don’t include commuting miles)",
						vehicle1:"",
						vehicle2:"",
						vehicle3:"",
						vehicle4:"",
						vehicle5:"",
						vehicle6:""
					},
						{
							num:"31",
							value:"Total commuting miles driven during the year",
							vehicle1:"",
							vehicle2:"",
							vehicle3:"",
							vehicle4:"",
							vehicle5:"",
							vehicle6:""
						},
						{
							num:"32",
							value:"	Total other personal (non-commuting) miles driven",
							vehicle1:"",
							vehicle2:"",
							vehicle3:"",
							vehicle4:"",
							vehicle5:"",
							vehicle6:""
						},
						{
							num:"33",
							value:"Total miles driven during the year. Add lines 30 through 32",
							vehicle1:"",
							vehicle2:"",
							vehicle3:"",
							vehicle4:"",
							vehicle5:"",
							vehicle6:""
						},
						{
							num:"34",
							value:"Was the vehicle available for personal use during off-duty hours?",
							vehicle1:["YES", "No"],
							vehicle2:["YES", "No"],
							vehicle3:["YES", "No"],
							vehicle4:["YES", "No"],
							vehicle5:["YES", "No"],
							vehicle6:["YES", "No"]
						},
						{
							num:"35",
							value:"Was the vehicle used primarily by a more than 5% owner or related person?",
							vehicle1:"",
							vehicle2:"",
							vehicle3:"",
							vehicle4:"",
							vehicle5:"",
							vehicle6:""
						},
						{
							num:"36",
							value:"Is another vehicle available for personal use?",
							vehicle1:"",
							vehicle2:"",
							vehicle3:"",
							vehicle4:"",
							vehicle5:"",
							vehicle6:""
						}]
				}],
				sectionB2: {
					title: 'Section B—Information on Use of Vehicles',
					columns: ['', '', 'Yes', 'No'],
					rows: [{
						num: '37',
						text: 'Do you maintain a written policy statement that prohibits all personal use of vehicles, including commuting, by your employees?',
						yes: '',
						no: ''
					}, {
						num: '38',
						text: 'Do you maintain a written policy statement that prohibits personal use of vehicles, except commuting, by your employees?',
						yes: '',
						no: ''
					}, {
						num: '39',
						text: 'Do you treat all use of vehicles by employees as personal use?',
						yes: '',
						no: ''
					}, {
						num: '40',
						text: 'Do you provide more than five vehicles to your employees, obtain information from your employees about theuse of the vehicles, and retain the information received?',
						yes: '',
						no: ''
					}, {
						num: '41',
						text: 'Do you meet the requirements concerning qualified automobile demonstration use?',
						yes: '',
						no: ''
					}]
				}
			},
			partIV:{
				title:"Summary",
				rows:[
					{num:"21",value:"Listed property. Enter amount from line 28",},
					{num:"22",value:"	Total. Add amounts from line 12, lines 14 through 17, lines 19 and 20 in column (g), and line 21. Enter here and on the appropriate lines of your return. Partnerships and S corporations—see instructions"},
					{num:"23",value:"For assets shown above and placed in service during the current year, enter theportion of the basis attributable to section 263A costs"}
				]
			},
			partVI:{
				title:"Amortization",
				columns:[
					"(a) Description of costs",
					"(b) Date amortization begins",
					"(c) Amortizable amount",
					"(d) Code section",
					"(e) Amortization period or percentage",
					"(f) Amortization for this year"
				],
				rows:[{
					num:"42",value:"Amortization of costs that begins during your 2017 tax year"
				}, {
					num:"43",value:"Amortization of costs that began before your 2017 tax year"
				},{
					num:"44",value:"Total. Add amounts in column (f). See the instructions for where to report"
				}],
			},
		};
		// end of Form view data object...

		$scope.partVAdd = function() {
			$scope.data.partV.rows.push({});
		};

		$scope.partVRemove = function(index) {
			$scope.data.partV.rows.splice(index, 1);
		};

		$scope.partVIAdd = function() {
			$scope.data.partVI.rows.push({});
		};

		$scope.partVIRemove = function(index) {
			$scope.data.partVI.rows.splice(index, 1);
		};




    }

});