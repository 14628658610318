define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.CounterPartyCtrl', [])
    .controller('CounterPartyController', ['$uibModal','$uibModalInstance','$scope','$rootScope','rowData','USER_SETTINGS','GlobalService','AlertService','ModalFactory','CounterPartyFactory',CounterPartyController])
			
	function CounterPartyController($uibModal, $uibModalInstance, $scope, $rootScope, rowData, USER_SETTINGS, GlobalService, AlertService, ModalFactory, CounterPartyFactory) {
    	
    	var vm = this;
    	vm.cancel = function(){
    		if(vm.crudLoading){
				AlertService.add("info", "The transaction is still in progress", 4000);
			}
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.rowData = rowData;
        console.log(vm.rowData);
        console.log(ModalFactory);
        vm.modalCalled = ModalFactory.response.data.modalScreen.modal_name;
        vm.userSettings = USER_SETTINGS;
        console.log(vm.userSettings);
		vm.logged_in_user = vm.userSettings.user.sso_id;
		vm.displayName = vm.userSettings.user.display_name;
		vm.entitySelected = [];
		vm.col_id = '';
		vm.cpFormData = [];
		vm.cpFormOriginalData = [];
		vm.cpFormFinalData = [];
		vm.fianlSaveData = [];
		vm.clob_settings = [];
		
		vm.entityTbAmt = 0;
		vm.tb_amt = 0;
		vm.total_payor_alloc = 0;
		vm.unallaocated = 0;
		vm.allocate_reconcile_amt = 0;
		vm.allocate_pending_amt = 0;
		vm.pending_amt = 0;
		vm.variance = 0;
		
		vm.line_no_enable = false;
		vm.us_passive_block = false;
		vm.editMode = false;
		vm.editMe = false;
		vm.editCountry = true;
		
		vm.cpSubFormList = [];
		vm.rowSysAcctSelected = 0;
		
		vm.F8916A_line_no = [];
		vm.selectedLine_no = '';
		vm.entityDetails = [];
		vm.selectedEntity = '';
		vm.cpSourceDetails = [];
		vm.countryDetails = [];
		vm.selectedSource = '';
        vm.selectedLine_no = '';
        vm.selectedColumn = '';
        vm.column_list = ['A', 'B', 'C'];        
        
		vm.searchBy = 'L';
        vm.selectedPayor = '';
		vm.selectedBSLA  = '';		
		vm.selectedCatType = '';
        vm.selectedIncomeType = '';
        vm.selectedCountry = '';
        vm.alloc_flag = 'Allocable';
        vm.payor_amount = 0;
        vm.withholding_taxes = 0;
        vm.withholding_tax_rate = 0;
        vm.applicable_year = vm.rowData.TAX_YEAR;
        vm.active_financing = 'No';
        vm.us_passive = 'No';
        vm.M3_Payor_types = [];
        if(vm.rowData.FORM != '1065'){
        	vm.M3_Payor_types = [{"PAYOR_TYPE": 'Foreign Corp', "LINE_NO": '1'},
				   {"PAYOR_TYPE": 'Domestic Corp', "LINE_NO": '6'},	
				   {"PAYOR_TYPE": 'Domestic Partnership', "LINE_NO": '9'},
				   {"PAYOR_TYPE": 'Foreign Partnership', "LINE_NO": '10'},];
        }else {
        	vm.M3_Payor_types = [{"PAYOR_TYPE": 'Foreign Corp', "LINE_NO": '1'},
				   {"PAYOR_TYPE": 'Domestic Corp', "LINE_NO": '5'},	
				   {"PAYOR_TYPE": 'Domestic Partnership', "LINE_NO": '7'},
				   {"PAYOR_TYPE": 'Foreign Partnership', "LINE_NO": '8'},];
        }
        
        vm.M3PayorTypeSelected = '';
        vm.M3PayorName = '';
        vm.payor_Type_Selected = [];
        vm.reclass_line_no = '';
        vm.payorDetails = [];
        vm.componentDetails = [];
        vm.incomeCategoryDetails = [];
        vm.payorSelected = [];
        vm.componentSelected = [];
        vm.enable_ui_intinc = false;
        vm.enable_ui_intexp = false;
        vm.enable_ui_m3p2l1 = false;
        vm.enable_ui_m3p2other = false;
        vm.enable_m3p2l1_payor_name = false;
        vm.enable_m3p2l1_payor_type = false;
        vm.enable_m3p2other_payor_name = false;
        vm.CUR_M3_PAYOR = '';
        vm.currentLine = vm.rowData.LINE_NO;
        vm.edit_record_index = null;
        vm.edit_record_parentindex = null;
        vm.edit_record_occurrence = null;
        vm.dummyApprovedRecord = {
        		"ROW_STATUS": 	"A",
        		"ROW_TYPE":		"CH",
        		"ROW_SOURCE":	"R",
        		"DELETE_ROWS":	[],
        		"Line_data":	[]
        };
        
        vm.combination_key = 0;
        vm.cp_form_no = 'CPFORM';
  	   	vm.cp_schedule = '';
  	   	vm.line_no = '';
  	   	vm.column = '';
  	   	vm.form_selected = '';
  	   	vm.schedule_selected = '';
  	   	vm.part_selected = 'NA';
  	   	vm.crudLoading = false;
        
        if(vm.modalCalled != 'CounterPartyModalNew'){
			vm.source_enable = false;
			vm.entity_load_enable = false;
			vm.column_value_enable = false;
			vm.entitySelected = vm.rowData;
			vm.col_id = vm.modalCalled.slice(vm.modalCalled.length - 1);
			getAllVariableDetails();
		}else{
			vm.source_enable = true;
			vm.entity_load_enable = true;
			vm.column_value_enable = true;
			getEntityDetails();
	        getCpSourceDetails();
	        getCountryDetails();
		}
        console.log('Modal called - ' + vm.modalCalled);
        
        function getEntityDetails(){
        	CounterPartyFactory.getEntityDetails(vm.logged_in_user).then(function (data){
				console.log("Here are the list of Entities available for the user:    ::::", data);
				vm.entityDetails = data.jsonObject;
			});
        }
        
        function getCpSourceDetails(){
        	CounterPartyFactory.getSourceDetails(vm.logged_in_user).then(function (data){
				console.log("Here are the list of Counter Party sources available:    ::::", data);
				vm.cpSourceDetails = data.jsonObject;
			});
        }
        
        function getCountryDetails(){
        	CounterPartyFactory.getCountryDetails(vm.logged_in_user).then(function (data){
        		console.log("Here are the list of Country's available:    ::::", data);
        		vm.countryDetails = data.jsonObject;
        	});
        }
        
        vm.afterEntitySelected = function(){
        	console.log("The Entity selected for Counter party addition was  ::::-------------", vm.selectedEntity);
        	var selectedEntityIndex = vm.entityDetails.findIndex(x => x.ENTITY_DETAILS == vm.selectedEntity);
        	vm.entitySelected = vm.entityDetails[selectedEntityIndex];
        	vm.selectedSource = '';
        	vm.line_no_enable = false;
        	vm.selectedColumn = '';
        	resetPageUI();
        }
        
        vm.sourceUpdate = function(){
        	console.log("The value of selected source in sourceUpdate ::::-------------", vm.selectedSource);
        	vm.selectedLine_no = '';
        	if(vm.selectedSource == "F8916A-PIII"){
        		vm.line_no_enable = true;
        		vm.F8916A_line_no = ['1', '2', '3a', '3b', '4'];
        	}else if (vm.selectedSource == "F8916A-PII"){
        		vm.line_no_enable = true;
        		vm.F8916A_line_no = ['2', '3', '4a', '4b', '5'];
        	}else{
        		vm.line_no_enable = false;
        	}
        	vm.selectedColumn = '';
        	resetPageUI();
        }
        
        vm.linenoUpdated = function(){
        	console.log("The value of selected line is ::::-------------", vm.selectedLine_no);
        	vm.selectedColumn = '';
        	resetPageUI();
        }
        
        vm.columnUpdated = function(){
        	getAllVariableDetails();
        }
        
        function getAllVariableDetails(){
        	if(vm.modalCalled == 'CounterPartyModalNew'){
      	   		vm.combination_key = vm.entitySelected.COMBINATION_KEY;
      	   		vm.line_no = vm.selectedLine_no;
      	   		vm.column = vm.selectedColumn;
      	   		var a = vm.selectedSource.split("-");
      	   		vm.form_selected = a[0];
      	   		vm.schedule_selected = a[1];
      	   			if(vm.schedule_selected == 'PII'){
      	   				vm.cp_schedule = 'INTINC';
      	   			}else vm.cp_schedule = 'INTEXP';
      	   	}else{
      	   		vm.combination_key = vm.rowData.COMBINATION_KEY;
      	   		vm.form_selected = vm.rowData.FORM;
      	   		vm.schedule_selected = vm.rowData.SCHEDULE;
      	   		vm.part_selected = vm.rowData.PART_NO;
      	   		vm.line_no = vm.rowData.LINE_NO;
      	   		vm.column = vm.col_id;
      	   		if((vm.rowData.FORM == 'F1120' || vm.rowData.FORM == '1120PC' || vm.rowData.FORM == '1120L' || vm.rowData.FORM == '1065') && vm.rowData.SCHEDULE == 'M3' && vm.rowData.PART_NO == 'P2'){
      	   			if(vm.rowData.LINE_NO == '1'){
      	   				vm.cp_schedule = 'M3P2L1';
      	   			}else vm.cp_schedule = 'M3P2OTHER';
         		}else{
         			if(vm.rowData.SCHEDULE == 'PII'){
         				vm.cp_schedule = 'INTINC';
    	    		}else vm.cp_schedule = 'INTEXP';
         		}
      	   		if(vm.line_no == '5' || vm.line_no == '4'){
      	   			vm.editCountry = false;
      	   		}
      	   	}
        	getCPSubFormData();
        	getPayorDetails();
        	getCountryDetails();
        }
        
        function getCPSubFormData(){
      	   
      	   CounterPartyFactory.getCPSubFormDetails(vm.combination_key, vm.cp_form_no, vm.cp_schedule, vm.line_no, vm.column, vm.form_selected, vm.schedule_selected, vm.part_selected,  vm.logged_in_user).then(function (data){
      		   console.log("After the CPFORM pull the Sub form data that was pulled at system account level was ::::::::::", data);
      		   vm.cpSubFormList = data.jsonObject;
      	   });
         }
        
        vm.rowAcctChanged = function(row_index, mainIndex){
        	console.log("The index and mainIndex that are selected for the row was :::::::::::", row_index, mainIndex);
        	angular.forEach(vm.cpSubFormList, function(row, index){
        		if(row_index != index){
        			row.checked = false;
        		}
        		vm.rowSysAcctSelected = vm.cpSubFormList[row_index].ACCOUNT_KEY;
        		vm.tb_amt = vm.cpSubFormList[row_index].BOOK_ADJ_AMT;
        	});
        	getCPFormData();
        	getUIModals();
        }
        
        function getUIModals(){
        	if(vm.modalCalled != 'CounterPartyModalNew'){
            	vm.enable_ui_intinc 	= vm.rowData.CLASIFICATION == "INTINC"  ? true : false; 
            	vm.enable_ui_intexp 	= vm.rowData.CLASIFICATION == "INTEXP"  ? true : false;
            	vm.enable_ui_m3p2l1 	= vm.rowData.CLASIFICATION == "1120M3P21"  ? true : false;     	
            	vm.enable_ui_m3p2other 	= vm.rowData.CLASIFICATION == "1120M3P22" || vm.rowData.CLASIFICATION == "1120M3P27" || vm.rowData.CLASIFICATION == "1120M3P28" || vm.rowData.CLASIFICATION == "1120M3P211" ? true : false;
            	
    		}else{	
    			if(vm.selectedSource == "F8916A-PII"){	
    				vm.enable_ui_intinc 	= true;
            		vm.enable_ui_intexp 	= false;
            		vm.enable_ui_m3p2l1 	= false;     	
            		vm.enable_ui_m3p2other 	= false;  
            	}
            	else if(vm.selectedSource == "F8916A-PIII"){
            		vm.enable_ui_intinc 	= false;
            		vm.enable_ui_intexp 	= true;
            		vm.enable_ui_m3p2l1 	= false; 	
            		vm.enable_ui_m3p2other 	= false;          	
            	}        
            	else{
            		vm.enable_ui_intinc 	= false;
            		vm.enable_ui_intexp 	= false;
            		vm.enable_ui_m3p2l1 	= false;     	
            		vm.enable_ui_m3p2other 	= false;        		
            	}
    			
    		}
        }
        
        function getPayorDetails(){
        	
        	CounterPartyFactory.getPayorDetails(vm.logged_in_user, vm.combination_key, vm.form_selected, vm.schedule_selected, vm.line_no).then(function (data){
				console.log("Here are the list of Payors available for this line:    ::::", data);
				vm.payorDetails = data.jsonObject;
			});
        }
        
        vm.afterPayorSelected = function(){
        	console.log("The Payor selected for Counter party addition was  ::::-------------", vm.selectedPayor);
        	var selectedPayorIndex = vm.payorDetails.findIndex(x => x.PAYOR_DETAILS == vm.selectedPayor);
        	vm.payorSelected = vm.payorDetails[selectedPayorIndex];
        	console.log("After selecting payor the json of payor is ::::::::::::", vm.payorSelected);
        	vm.selectedCatType = vm.payorSelected.CATEGORY_TYPE;
            //vm.selectedIncomeType = vm.incomeCategoryDetails[0].CODE_DESC;
            vm.us_passive_block = true;
            vm.selectedCountry = vm.payorSelected.LE_COUNTRY;
        	getComponentDetails();
        }
        
        function getComponentDetails(){
        	var payor_selected_details = vm.payorSelected.PAYOR_DETAILS.split("/");
        	var payor_leid = payor_selected_details[0];
        	var payor_cdr_no = payor_selected_details[1];
        	var payor_rp_period = payor_selected_details[2];
        	CounterPartyFactory.getBSLADetails(vm.logged_in_user, vm.combination_key, payor_leid, payor_cdr_no, payor_rp_period).then(function (data){
				console.log("Here are the list of Components/BSLAs available for this line:    ::::", data);
				vm.componentDetails = data.jsonObject;
			});
        }
        
        vm.afterBSLASelected = function(){
        	var selectedbslaIndex = vm.componentDetails.findIndex(x => x.BSLA_DETAILS == vm.selectedBSLA);
        	vm.componentSelected = vm.componentDetails[selectedbslaIndex];
        	console.log("After selecting Component the json of BSLA is ::::::::::::", vm.componentSelected);
        }
        
        /*vm.incomeCategoryUpdate = function(){
        	var selectedICIndex = vm.incomeCategoryDetails.findIndex(x => x.CODE_DESC == vm.selectedIncomeType);
        	if(vm.selectedIncomeType != 'U.S.'){
        		vm.us_passive_block = false;
        	}
        }*/
        
        /*function getIncomeCategoryDetails(){
        	CounterPartyFactory.getIncomeCategoryDetails().then(function (data){
				console.log("Here are the list of Income Catgory / Baskets available:::::::    ::::", data);
				vm.incomeCategoryDetails = data.jsonObject;
			});
        }*/
        
        function getEntityTbAmt(){
        	if(vm.modalCalled != 'CounterPartyModalNew'){
        		
        	}else{
        		
        	}
        }
        
        vm.afterM3PayorSelected = function(){
        	console.log("The Payor selected for Counter party addition was  ::::-------------", vm.selectedPayor);
        	var selectedPayorIndex = vm.payorDetails.findIndex(x => x.PAYOR_DETAILS == vm.selectedPayor);
        	vm.payorSelected = vm.payorDetails[selectedPayorIndex];
        	if(vm.payorSelected.RC_LINE_NO != 'FIND'){
        		vm.reclass_line_no = vm.payorSelected.RC_LINE_NO;
        	}
        	console.log("After selecting payor the json of payor is ::::::::::::", vm.payorSelected);
        	
        	if(vm.selectedPayor == "Other - Third Party owned less than 20%" || vm.selectedPayor == "Other - Third Party (including those owned less than 20% and trusts)"){
        		if(vm.enable_ui_m3p2l1){
        			vm.enable_m3p2l1_payor_name = true;
        			vm.enable_m3p2l1_payor_type = true;
        			vm.enable_m3p2other_payor_name = false; 
        		}
        		else if(vm.enable_ui_m3p2other){
        			vm.enable_m3p2l1_payor_name = false;
        			vm.enable_m3p2l1_payor_type = false;
        			vm.enable_m3p2other_payor_name = true;        			
        		}
        		else{
        			vm.enable_m3p2l1_payor_name = false;
        			vm.enable_m3p2l1_payor_type = false;
        			vm.enable_m3p2other_payor_name = false;
        		}
        	}
        	else{
        		 	vm.enable_m3p2l1_payor_name = false;
        			vm.enable_m3p2l1_payor_type = false;
        			vm.enable_m3p2other_payor_name = false;
        	}
        }
        
        vm.m3_payor_type_update = function(){
        	console.log("The Payor type selected for M3 Counter party addition was  ::::-------------", vm.M3PayorTypeSelected);
        	var selectedPayorTypeIndex = vm.M3_Payor_types.findIndex(x => x.PAYOR_TYPE == vm.M3PayorTypeSelected);
        	vm.payor_Type_Selected = vm.M3_Payor_types[selectedPayorTypeIndex];
        	vm.reclass_line_no = vm.payor_Type_Selected.LINE_NO;
        }
        
        function getCPFormData(){
        	CounterPartyFactory.getCPFormDetails(vm.combination_key, vm.cp_form_no, vm.cp_schedule, vm.line_no, vm.column, vm.form_selected, vm.schedule_selected, vm.part_selected, vm.rowSysAcctSelected, vm.logged_in_user).then(function (data){
        		console.log("After the CPFORM pull the data was ::::::::::", data);
				vm.cpList = data.jsonObject;
				
				var o = vm.cpList.reduce( (a,b) => {
					a[b.GROUP_ORDER] = a[b.GROUP_ORDER] || [];
					a[b.GROUP_ORDER].push(b);
					return a;
				}, {});
				
				var a = Object.keys(o).map(function(k){
					return {ROW_STATUS: o[k][0].PAYOR_STATUS, ROW_TYPE: o[k][0].ROW_TYPE, ROW_SOURCE: o[k][0].PAYOR_ROW_SOURCE, DELETE_ROWS: [], Line_data: o[k]};
				});
				vm.cpFormData = angular.copy(a);
				console.log(vm.cpFormData);
				
				var c = [];
				for (var i=0; i < vm.cpFormData.length; i++){
					if(vm.cpFormData[i].ROW_TYPE == 'CH'){
						
						if(vm.cpFormData[i].Line_data[0].OCCURRENCE != null){
							var z = vm.cpFormData[i].Line_data.reduce( (a,b) => {
								a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
								a[b.OCCURRENCE].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Lock_status: z[k][0].LOCK_STATUS, Line_no: z[k][0].LINE_NO, Payor_Type: z[k][0].PAYOR_ROW_SOURCE, Payor_Status: z[k][0].PAYOR_STATUS, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Occurrence: z[k][0].OCCURRENCE, Line_data_occ: z[k]};
							});
							
							console.log("At this point of the value of y is -------::::::", y);
							var m = angular.copy(y);
							console.log("At this point of the value of m is -------::::::", m);	
							c = angular.copy(y[0]);
							console.log("At this point of the value of c is -------::::::", c);
							var tab_header = '';
							var tab_length = c.Line_data_occ.length;
							var tab_color = '';
							tab_header = 'Amount allocated with counter party.';
							tab_color = 'green';
							/*if(vm.form_selected == 'F8916A' && (vm.line_no == '3b' || vm.line_no == '4b')){
								if(c.Payor_Type == 'O' && c.Payor_Status == 'P'){
									tab_header = 'Amount allocated but pending Approval from counter party.';
									tab_color = 'orange';
								}else if(c.Payor_Type == 'R' && c.Payor_Status == 'P'){
									tab_header = 'Amount pending/waiting for Approval.';
									tab_color = 'red';
								}else if(c.Payor_Status == 'A'){
									tab_header = 'Amount allocated and reconciled with counter party.';
									tab_color = 'green';
								}
							}else if(vm.form_selected == 'F8916A' && (vm.line_no != '3b' && vm.line_no != '4b')){
								tab_header = 'Amount allocated and reconciled with counter party.';
								tab_color = 'green';
							}else {
								tab_header = 'Amount allocated with counter party.';
								tab_color = 'green';
							}*/
							var m3_reclass_line_no = "";
							for(var x=0; x < y.length; x++){
								for (var z=0; z < y[x].Line_data_occ.length; z++){
									if(y[x].Line_data_occ[z].ATTRIB_SHORT_CODE == "SOURCE_RECLASS_LINE"){
										m3_reclass_line_no = y[x].Line_data_occ[z].ATTRIB_VALUE;
									}
								}
								for (var z=0; z < y[x].Line_data_occ.length; z++){
									y[x].Line_data_occ[z].RECLASS_LINE_NO = m3_reclass_line_no;
								}
							}
							
							c.Line_Type = "HEADER_ROW";
							c.Lock_status = 0;
							c.Occurrence = "";
							c.Trans_details_key = "";
							c.Payor_Type = 'O';
							c.Payor_Status = 'P';
							c.IS_EDITABLE = "N";
							for (var j=0; j < c.Line_data_occ.length; j++){
								c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
								c.Line_data_occ[j].OCCURRENCE = 0;
								c.Line_data_occ[j].TRANS_DETAILS_KEY = 0;
								c.Line_data_occ[j].PARENT_DETAIL_KEY = null;
								c.Line_data_occ[j].REP_TRANS_ID = null;
								c.Line_data_occ[j].TRANS_ID = null;
							}
							
							y.unshift({Line_Type: "TABLE_HEADER", Line_Desc: tab_header, colLength: tab_length, Line_color: tab_color}, c);
							vm.cpFormData[i].Line_data = angular.copy(y);
							
						} else {
							var z = vm.cpFormData[i].Line_data.reduce( (a,b) => {
								a[b.LINE_NO] = a[b.LINE_NO] || [];
								a[b.LINE_NO].push(b);
								return a;
							}, {});
							
							console.log("At this point of the value of z is -------::::::", z);
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "HEADER_ROW", Is_Multi: z[k][0].IS_MULTI, Lock_status: z[k][0].LOCK_STATUS, Line_no: z[k][0].LINE_NO, Payor_Type: 'O', Payor_Status: 'P', IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: "", Occurrence: "", Line_data_occ: z[k]};
							});
							
							console.log("At this point of the value of y is -------:::::: line 157", y);
							
							for(var j=0; j < y[0].Line_data_occ.length; j++){
								y[0].Line_data_occ[j].ATTRIB_VALUE = y[0].Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							var tab_header = '';
							var tab_length = y[0].Line_data_occ.length;
							var tab_color = '';
							tab_header = 'Amount allocated with counter party.';
							tab_color = 'green';
							/*if(vm.form_selected == 'F8916A' && (vm.line_no == '3b' || vm.line_no == '4b')){
								tab_header = 'Amount allocated but pending Approval from counter party.';
								tab_color = 'orange';
							}else if(vm.form_selected == 'F8916A' && (vm.line_no != '3b' && vm.line_no != '4b')){
								tab_header = 'Amount allocated and reconciled with counter party.';
								tab_color = 'green';
							}else{
								tab_header = 'Amount allocated with counter party.';
								tab_color = 'green';
							}*/
							console.log("At this point of the value of y[0] is -------::::::", y[0]);
							
							y.unshift({Line_Type: "TABLE_HEADER", Line_Desc: tab_header, colLength: tab_length, Line_color: tab_color});
							vm.cpFormData[i].Line_data = y;
						}
					}else if(vm.cpFormData[i].ROW_TYPE == 'TH'){
						if(vm.form_selected == 'F1120' || vm.form_selected == '1120PC' || vm.form_selected == '1120L' || vm.form_selected == '1065'){
							if(vm.column == 'A'){
								if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'ADJUSTED_BOOK'){
									vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.tb_amt;
								}
								if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'ADJUSTED_M1'){
									vm.cpFormData.splice(i, 1);
								}
							}else{
								if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'ADJUSTED_M1'){
									vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.tb_amt;
								}
								if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'ADJUSTED_BOOK'){
									vm.cpFormData.splice(i, 1);
								}
							}
						}else{
							if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'TOTAL_AMOUNT_TB'){
								vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.tb_amt;
							}
						}
					}
				}
				putTotalLineAmounts();
				console.log(vm.cpFormData);
				vm.cpFormOriginalData = angular.copy(vm.cpFormData);
			});
			
		}
        
        function getRequestedAmount(index){
        	var amount = 0;
        	for(var y=0; y < vm.cpFormData[index].Line_data.length; y++){
				if(vm.cpFormData[index].Line_data[y].Line_Type == 'DATA_ROW'){
					for(var z=0; z < vm.cpFormData[index].Line_data[y].Line_data_occ.length; z++){
						if(vm.cpFormData[index].Line_data[y].Line_data_occ[z].ATTRIB_SHORT_CODE == 'AMOUNT'){
							amount = amount + parseFloat(vm.cpFormData[index].Line_data[y].Line_data_occ[z].ATTRIB_VALUE);
						}
					}
				}
			}
        	return amount;
        }
        
        function getCalculatedAmt(){
        	var form_selected = 'F8916A';
        	var column = '';
        	if(vm.modalCalled != 'CounterPartyModalNew'){
        		form_selected = vm.rowData.FORM;
        		column = vm.modalCalled.slice(vm.modalCalled.length - 1);
        	}else column = vm.selectedColumn;
        	if(form_selected == 'F8916A'){
        		for (var i=0; i < vm.cpFormData.length; i++){
        			if(vm.cpFormData[i].ROW_TYPE == 'CH'){
        				console.log("The value of the row of the approved record is ::::::::::::", getRequestedAmount(i));
            			vm.allocate_reconcile_amt = vm.allocate_reconcile_amt + getRequestedAmount(i);
        			}
        			
            		/*if(vm.cpFormData[i].ROW_TYPE == 'CH' && vm.cpFormData[i].ROW_STATUS == 'A'){
            			console.log("The value of the row of the approved record is ::::::::::::", getRequestedAmount(i));
            			vm.allocate_reconcile_amt = vm.allocate_reconcile_amt + getRequestedAmount(i);
            		}else if(vm.cpFormData[i].ROW_TYPE == 'CH' && vm.cpFormData[i].ROW_STATUS == 'P' && vm.cpFormData[i].ROW_SOURCE == 'O'){
            			console.log("The value of the row of the allocated still pending record is ::::::::::::", getRequestedAmount(i));
            			vm.allocate_pending_amt = vm.allocate_pending_amt + getRequestedAmount(i);
            		}else if(vm.cpFormData[i].ROW_TYPE == 'CH' && vm.cpFormData[i].ROW_STATUS == 'P' && vm.cpFormData[i].ROW_SOURCE == 'R'){
            			console.log("The value of the row of the pending record is ::::::::::::", getRequestedAmount(i));
            			vm.pending_amt = vm.pending_amt + getRequestedAmount(i);
            		}*/
            	}
            	vm.variance = vm.tb_amt - vm.allocate_reconcile_amt;
        	}else{
        		for (var i=0; i < vm.cpFormData.length; i++){
        			if(vm.cpFormData[i].ROW_TYPE == 'CH'){
        				vm.total_payor_alloc = vm.total_payor_alloc + getRequestedAmount(i);
        			}
        		}
        		vm.unallaocated = vm.total_payor_alloc - vm.tb_amt;
        	}
        }
        
        function putTotalLineAmounts(){
        	vm.total_payor_alloc = 0;
    		vm.unallaocated = 0;
    		vm.allocate_reconcile_amt = 0;
    		vm.allocate_pending_amt = 0;
    		vm.pending_amt = 0;
    		vm.variance = 0;
        	getCalculatedAmt();
        	for(var i=0; i < vm.cpFormData.length; i++){
        		if(vm.cpFormData[i].ROW_TYPE == 'TH'){
        			if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'AMT_ALLOC_RECONCILED'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.allocate_reconcile_amt;
					}else if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'AMT_ALLOC_PENDING'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.allocate_pending_amt;
					}else if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'AMT_PENDING_APPROVAL'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.pending_amt;
					}else if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'VARIANCE'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.variance;
					}else if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'TOTAL_PAYOR_ALLOCATED'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.total_payor_alloc;
					}else if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'UNALLOC'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.unallaocated;
					}
        		}
        	}
        }
        
        
        function cleanFormData(){
        	vm.searchBy = 'L';
            vm.selectedPayor = '';
    		vm.selectedBSLA  = '';		
    		vm.selectedCatType = '';
            vm.selectedIncomeType = '';
            vm.selectedCountry = '';
			vm.M3PayorName = '';
            vm.M3PayorTypeSelected = '';
            vm.alloc_flag = 'Allocable';
            vm.payor_amount = 0;
            vm.withholding_taxes = 0;
            vm.withholding_tax_rate = 0;
            vm.applicable_year = vm.rowData.TAX_YEAR;
            vm.active_financing = 'No';
            vm.us_passive = 'No';
            vm.reclass_line_no = '';
            vm.enable_m3p2l1_payor_name = false;
            vm.enable_m3p2l1_payor_type = false;
            vm.us_passive_block = false;
        }
        
        vm.reset = function(){
        	cleanFormData();
        }
        
        function resetPageUI(){
        	vm.enable_ui_intinc 	= false;
    		vm.enable_ui_intexp 	= false;
    		vm.enable_ui_m3p2l1 	= false;     	
    		vm.enable_ui_m3p2other 	= false;
        }
        
        vm.changeStatus = function(Occurrence, Trans_details_key, index, parentIndex, action_param){
        	console.log("The action of the change status along with indexes are ::::::::::::", action_param, index, parentIndex);
        	if(action_param == 'R'){
        		vm.cpFormData[parentIndex].Line_data[index].Payor_Status = 'R';
        		for(var i=0; i < vm.cpFormData[parentIndex].Line_data[index].Line_data_occ.length; i++){
        			vm.cpFormData[parentIndex].Line_data[index].Line_data_occ[i].PAYOR_STATUS = 'R';
        		}
        		vm.cpFormData[parentIndex].DELETE_ROWS.push(vm.cpFormData[parentIndex].Line_data[index]);
        		vm.cpFormData[parentIndex].Line_data.splice(index, 1);
        	}else if(action_param == 'A'){
        		//vm.cpFormData[0].Line_data[index].Payor_Status = 'A'; -- Need to build logic for Approved
        		vm.cpFormData[parentIndex].Line_data[index].Payor_Status = 'A';
        		var approvedTableIndex = vm.cpFormData.findIndex(x => x.ROW_STATUS == 'A');
        		if(approvedTableIndex != null && approvedTableIndex != undefined && approvedTableIndex >= 0){
        			if(vm.cpFormData[approvedTableIndex].ROW_TYPE == 'CH' && vm.cpFormData[approvedTableIndex].ROW_STATUS == 'A'){
        				vm.cpFormData[approvedTableIndex].Line_data.push(vm.cpFormData[parentIndex].Line_data[index]);
        			}
        		}else{
        			var tab_header = 'Amount allocated and reconciled with counter party.';
        			var tab_length = vm.cpFormData[parentIndex].Line_data[1].Line_data_occ.length;
        			vm.dummyApprovedRecord.Line_data.push({Line_Type: "TABLE_HEADER", Line_Desc: tab_header, colLength: tab_length});
        			vm.dummyApprovedRecord.Line_data.push(vm.cpFormData[parentIndex].Line_data[1]);
        			vm.dummyApprovedRecord.Line_data.push(vm.cpFormData[parentIndex].Line_data[index]);
    				vm.cpFormData.unshift(vm.dummyApprovedRecord);
    				vm.cpFormData[parentIndex+1].Line_data.splice(index, 1);
        		}
        	}
        	putTotalLineAmounts();
        }
        
        vm.submit = function(){
        	var occArray = [];
        	var form_selected = 'F8916A';
			var line_no = '1';
        	if(vm.modalCalled != 'CounterPartyModalNew'){
        		form_selected = vm.rowData.FORM;
				line_no = vm.rowData.LINE;
        	}
        	console.log("The new line is created with values of  :::::::", vm.payorSelected, vm.payor_amount);
			var occurrance = 1;
			for(var m=0; m < vm.cpFormData.length; m++){
				if(vm.cpFormData[m].ROW_TYPE == 'CH'){
					if(null != vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence && vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence != "" && vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence != undefined){
						occArray.push(vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence + 1);
					}else{
						occArray.push(1);
					}
				}
			}
			occurrance = Math.max.apply(null, occArray);
			if(vm.editMode == true){
				for(var i=0; i < vm.cpFormData[vm.edit_record_parentindex].Line_data.length; i++){
					if(vm.edit_record_index == i && vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Occurrence == vm.edit_record_occurrence){
						for(var j=0; j < vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ.length; j++){
							if(vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'AMOUNT'){
								vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE = vm.payor_amount;
								vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
							}else if(vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'WITHHOLDING_TAXES'){
        						vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE = vm.withholding_taxes;
        						vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
        					}else if(vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'WITHHOLDING_TAX_RATE'){
        						vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE = vm.withholding_tax_rate;
        						vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
        					}else if(vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'COUNTRY'){
        						if(vm.selectedCountry != null){
        							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE = vm.selectedCountry;
        							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
        						}
        					}
							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].RECLASS_LINE_NO = vm.reclass_line_no;
						}
					}
				}
			} else if(vm.editMe == true){
				for(var i=0; i < vm.cpFormData[vm.edit_record_parentindex].Line_data.length; i++){
					if(vm.edit_record_index == i && vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Occurrence == vm.edit_record_occurrence){
						for(var j=0; j < vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ.length; j++){
							if(vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'PAYOR_DETAILS'){
								if(vm.selectedBSLA != null){
									vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE = vm.payorSelected.PAYOR_DETAILS + '/-/' + vm.selectedBSLA;
								}
							}
							if(vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'COUNTRY'){
								if(vm.selectedCountry != null){
									vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE = vm.selectedCountry;
									vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].PAYOR_LE_COUNTRY = vm.selectedCountry;
								}
							}
							var pyr_details = vm.payorSelected.PAYOR_DETAILS.split("/");
							var me_details = [];
							if(vm.selectedBSLA != null){
								me_details = vm.selectedBSLA.split("/");
							}
							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].PAYOR_LEID = pyr_details[0];
							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].PAYOR_CDR_NO = pyr_details[1];
							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].PAYOR_REPORTING_PERIOD = pyr_details[2];
							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].PAYOR_ME_CODE = me_details[0];
							vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}else{
				for(var x=0; x < vm.cpFormData.length; x++){
					if((form_selected == 'F8916A' && vm.cpFormData[x].ROW_TYPE == 'CH' && vm.cpFormData[x].ROW_STATUS == 'P' && vm.cpFormData[x].ROW_SOURCE == 'O') || ((form_selected == 'F1120' || form_selected == '1120PC' || form_selected == '1120L' || form_selected == '1065') && vm.cpFormData[x].ROW_TYPE == 'CH')){
						var new_row = angular.copy(vm.cpFormData[x].Line_data[1]);
						new_row.Line_Type = "DATA_ROW";
						new_row.Occurrence = occurrance;
						new_row.IS_EDITTED = "Y";
						for(var i=0; i < new_row.Line_data_occ.length; i++){
							if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'AMOUNT'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.payor_amount;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'PAYOR_DETAILS'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.payorSelected.PAYOR_DETAILS + '/-/' + vm.selectedBSLA;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'PAYOR_ID'){
								if((form_selected == 'F1120' || form_selected == '1120PC' || form_selected == '1120L' || form_selected == '1065') && (vm.payorSelected.LE_NAME == 'Other - Third Party owned less than 20%' || vm.payorSelected.LE_NAME == 'Other - Third Party (including those owned less than 20% and trusts)')){
									new_row.Line_data_occ[i].ATTRIB_VALUE = vm.M3PayorName;
								}else{
									new_row.Line_data_occ[i].ATTRIB_VALUE = vm.payorSelected.LEID;
								}
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'PAYOR_NAME'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.payorSelected.LE_NAME;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'SOURCE_RECLASS_LINE'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.reclass_line_no;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'CATEGORY_TYPE'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.selectedCatType;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'INCOME_CATEGORY'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.selectedIncomeType;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'US_PASSIVE'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.us_passive;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'COUNTRY'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.selectedCountry;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'ORIGINATED_APPROVED'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.displayName;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'WITHHOLDING_TAXES'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.withholding_taxes;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'WITHHOLDING_TAX_RATE'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.withholding_tax_rate;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'APPLICABLE_YEAR'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.applicable_year;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'ACTIVE_FINANCING'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.active_financing;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'ALLOC_APPOR'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.alloc_flag;
							}
							new_row.Line_data_occ[i].OCCURRENCE = occurrance;
							new_row.Line_data_occ[i].IS_CHANGED_FLAG = "Y";
							new_row.Line_data_occ[i].RECLASS_LINE_NO = vm.reclass_line_no;
							new_row.Line_data_occ[i].SYS_ACCT_SELECTED = vm.rowSysAcctSelected;
							if(vm.payorSelected != null && vm.payorSelected != undefined){
								new_row.Line_data_occ[i].PAYOR_LEID = vm.payorSelected.LEID;
								new_row.Line_data_occ[i].PAYOR_CDR_NO = vm.payorSelected.CDR_NO;
								new_row.Line_data_occ[i].PAYOR_REPORTING_PERIOD = vm.payorSelected.REPORTING_PERIOD;
								new_row.Line_data_occ[i].PAYOR_LE_COUNTRY = vm.payorSelected.LE_COUNTRY;
							}
							if(vm.componentSelected != null && vm.componentSelected != undefined){
								new_row.Line_data_occ[i].PAYOR_ME_CODE = vm.componentSelected.ME_CODE;
							}
						}
						vm.cpFormData[x].Line_data.push(new_row);
					}
				}
			}
			putTotalLineAmounts();
			console.log("After adding the new row in Data the data was :::::::::::", vm.cpFormData);
			cleanFormData();
			vm.editMode = false;
			vm.editMe = false;
        }
        
        vm.removeExistingRecord = function(Occurrence, Trans_details_key, index, parentIndex){
			vm.reclass_line_no = "";
        	if(Trans_details_key == null || Trans_details_key == undefined || Trans_details_key == '' || Trans_details_key.length <= 0 ){
        		vm.cpFormData[parentIndex].Line_data.splice(index, 1);
        	}else{
        		vm.cpFormData[parentIndex].Line_data[index].Payor_Status = 'D';
        		for(var i=0; i < vm.cpFormData[parentIndex].Line_data[index].Line_data_occ.length; i++){
        			vm.cpFormData[parentIndex].Line_data[index].Line_data_occ[i].PAYOR_STATUS = 'D';
        			if(vm.cpFormData[parentIndex].Line_data[index].Line_data_occ[i].ATTRIB_SHORT_CODE == "SOURCE_RECLASS_LINE"){
        				vm.reclass_line_no = vm.cpFormData[parentIndex].Line_data[index].Line_data_occ[i].ATTRIB_VALUE;
        			}
        		}
        		for(var i=0; i < vm.cpFormData[parentIndex].Line_data[index].Line_data_occ.length; i++){
        			vm.cpFormData[parentIndex].Line_data[index].Line_data_occ[i].RECLASS_LINE_NO = vm.reclass_line_no;
        		}
        		vm.cpFormData[parentIndex].DELETE_ROWS.push(vm.cpFormData[parentIndex].Line_data[index]);
        		vm.cpFormData[parentIndex].Line_data.splice(index, 1);
        	}
        	putTotalLineAmounts();
        	console.log("After removing the record the data was :::::::::::", vm.cpFormData);
        }
        
        vm.editExistingRecord = function(Occurrence, Trans_details_key, index, parentIndex, lock_status){
        	vm.edit_record_index = index;
        	vm.edit_record_parentindex = parentIndex;
            vm.edit_record_occurrence = Occurrence;
        	if(vm.cpFormData[parentIndex].ROW_TYPE == 'CH'){
        		for(var i=0; i < vm.cpFormData[parentIndex].Line_data.length; i++){
        			if(i == index && vm.cpFormData[parentIndex].Line_data[i].Occurrence == Occurrence){
        				for(var j=0; j < vm.cpFormData[parentIndex].Line_data[i].Line_data_occ.length; j++){
        					if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'AMOUNT'){
        						vm.payor_amount = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'PAYOR_DETAILS'){
        						var payor = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE.split('/-/');
        						vm.selectedPayor = payor[0];
        						vm.payorSelected.PAYOR_DETAILS = payor[0];
        						vm.selectedBSLA = payor[1];
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'PAYOR_ID'){
        						vm.selectedPayor = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'PAYOR_NAME'){
        						vm.selectedPayor = vm.selectedPayor + ' - ' + vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'SOURCE_RECLASS_LINE'){
        						vm.reclass_line_no = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'CATEGORY_TYPE'){
        						vm.selectedCatType = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'INCOME_CATEGORY'){
        						vm.selectedIncomeType = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'US_PASSIVE'){
        						vm.us_passive = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'COUNTRY'){
        						vm.selectedCountry = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'WITHHOLDING_TAXES'){
        						vm.withholding_taxes = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'WITHHOLDING_TAX_RATE'){
        						vm.withholding_tax_rate= vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'APPLICABLE_YEAR'){
        						vm.applicable_year = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'ACTIVE_FINANCING'){
        						vm.active_financing= vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'ALLOC_APPOR'){
        						vm.alloc_flag = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}
        				}
        			}
        		}
        	}
        	if(lock_status == 1){
        		vm.editMe = true;
        		getComponentDetails();
        	}else {
        		vm.editMode = true;
        	}
        	if(vm.line_no == '5' || vm.line_no == '4'){
        		vm.editCountry = false;
        	}
        }
        
        vm.save = function(){
        	vm.fianlSaveData = [];
        	if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
        	vm.cpFormFinalData = angular.copy(vm.cpFormData);
        	if(angular.equals(vm.cpFormData, vm.cpFormOriginalData)){
        		vm.cancel();
        	}else{
        		manipulateData();
         	   	var cp_form_no = 'CPFORM';
         	   	var cp_schedule = '';
         	   	var line_no = '';
         	   	var column = '';
         	   	var form_selected = '';
         	   	var schedule_selected = '';
         	   	var part_selected = 'NA';
         	   	if(vm.modalCalled == 'CounterPartyModalNew'){
         	   		line_no = vm.selectedLine_no;
         	   		column = vm.selectedColumn;
         	   		var a = vm.selectedSource.split("-");
         	   		form_selected = a[0];
         	   		schedule_selected = a[1];
            		if(schedule_selected == 'PII'){
            			cp_schedule = 'INTINC';
            		}else cp_schedule = 'INTEXP';
         	   	}else{
         	   		form_selected = vm.rowData.FORM;
         	   		schedule_selected = vm.rowData.SCHEDULE;
         	   		part_selected = vm.rowData.PART_NO;
         	   		line_no = vm.rowData.LINE_NO;
         	   		column = vm.col_id;
         	   		if((vm.rowData.FORM == 'F1120' || vm.rowData.FORM == '1120PC' || vm.rowData.FORM == '1120L' || vm.rowData.FORM == '1065') && vm.rowData.SCHEDULE == 'M3' && vm.rowData.PART_NO == 'P2'){
         	   			if(vm.rowData.LINE_NO == '1'){
         	   				cp_schedule = 'M3P2L1';
            			}else cp_schedule = 'M3P2OTHER';
            		}else{
            			if(vm.rowData.SCHEDULE == 'PII'){
            				cp_schedule = 'INTINC';
     	    		   	}else cp_schedule = 'INTEXP';
            		}
         	   	}
        		console.log("After changes, the final data was :::::::::::=========", vm.cpFormFinalData);
    			console.log("After changes, the manipulated data was :::::::::::=========", vm.cpFormData);
    			console.log("After changes, the final data to send DB was :::::::::::=========", vm.fianlSaveData);
        		vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
        		sendsaveDetails(vm.fianlSaveData, vm.clob_settings, cp_form_no, cp_schedule, form_selected, schedule_selected, part_selected, line_no, column);
        	}
        }
        
        function sendsaveDetails(final_data, clob_settings, cp_form_no, cp_schedule, form_selected, schedule_selected, part_selected, line_no, column){
			var schdSave = null;
			var message = "Counter Party Details are successfully updated.";
			vm.crudLoading = true;
			CounterPartyFactory.saveCPForm(final_data, clob_settings, cp_form_no, cp_schedule, form_selected, schedule_selected, part_selected, line_no, column).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(null != data.data.errorMessage && data.data.errorMessage != "null"){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There wa no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					vm.cancel();
					var args = {"refresh_param": "N"};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		}
        
        function manipulateData(){
        	for(var i=0; i < vm.cpFormFinalData.length; i++){
        		if(vm.cpFormFinalData[i].ROW_TYPE == 'CH'){
        			for(var j=0; j < vm.cpFormFinalData[i].Line_data.length; j++){
        				if(vm.cpFormFinalData[i].Line_data[j].Line_Type == 'DATA_ROW'){
        					for(var k=0; k < vm.cpFormFinalData[i].Line_data[j].Line_data_occ.length; k++){
        						vm.fianlSaveData.push(vm.cpFormFinalData[i].Line_data[j].Line_data_occ[k]);
        					}
        				}
        			}
        			if(vm.cpFormFinalData[i].DELETE_ROWS.length != null && vm.cpFormFinalData[i].DELETE_ROWS.length != undefined && vm.cpFormFinalData[i].DELETE_ROWS.length > 0){
        				for(var m=0; m < vm.cpFormFinalData[i].DELETE_ROWS.length; m++){
            				if(vm.cpFormFinalData[i].DELETE_ROWS[m].Line_Type == 'DATA_ROW'){
            					for(var n=0; n < vm.cpFormFinalData[i].DELETE_ROWS[m].Line_data_occ.length; n++){
            						vm.fianlSaveData.push(vm.cpFormFinalData[i].DELETE_ROWS[m].Line_data_occ[n]);
            					}
            				}
            			}
        			}
        		}else if(vm.cpFormFinalData[i].ROW_TYPE == 'TH'){
        			for(var j=0; j < vm.cpFormFinalData[i].Line_data.length; j++){
        				vm.fianlSaveData.push(vm.cpFormFinalData[i].Line_data[j]);
        			}
        		}
        	}
        }
    };	
	return controllers;

});