/**
 * Created by 400379979 on 10/15/2015.
 */





define([
    'angular',
    './taxAttributesCtrl',
    './taxAttributesService',

], function () {
    'use strict';

  
   return angular.module('app.taxAttributes', ['app.taxAttributesCtrl','app.taxAttributesService'])

    });