define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.elimReclassLocalController', [])
            .controller('ElimReclassLocalController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'AlertService', 'CodeComboFactory', 'rowData', 'colData', 'JsonObjectFactory', 'EliminationLocalFactory','GENERAL_CONFIG', elimReclassLocalController]);



    function elimReclassLocalController($rootScope, $scope, $http, $timeout, GlobalService, $uibModalInstance, AlertService, CodeComboFactory, rowData, colData, JsonObjectFactory, EliminationLocalFactory,GENERAL_CONFIG) {
        var vm = this;
        vm.rowData = rowData;
        vm.accounts = [];
        vm.feedDtls = [];
        vm.title = 'Elimination Reclass Summary View';
        vm.showElimForm = false;
        vm.homeOffice = rowData.HO_LEID + "/" + rowData.HO_CDR_NO + "/" + rowData.HO_RPT_PD + "/" + rowData.HO_CURR;
        vm.ELIM_TYPE = 'B';
        vm.mainScreenKey = EliminationLocalFactory.getActiveScreen();
        vm.sourceAcctType = vm.rowData.SYSTEM_ACCT_TYPE;
        vm.elimReclassTotal = 0;
        vm.ho_combination_key = rowData.HO_COMBINATION_KEY;
        vm.enableFeed = false;
        vm.TRANS_HEADER_KEY = 0;
        vm.GROUP_TRANS_KEY = 0;
        vm.reclassArray = [];
        vm.existingLoading = false;
        vm.noRecords = false;
        vm.crudLoading = false;
        vm.iselimTypeDisabled = false;
        vm.trans_keys_to_del = [];
        vm.transStatus = 'new';
        vm.ELIM_DESCRIPTION = "";
        $scope.addTargetAccount = function () {

            var targetAcct = {
                "LOCAL_ACCT_KEY":"",
                "SYSTEM_ACCT_KEY": "",
                "LOCAL_ACCT": "",
                "LOCAL_ACCT_DESC": "",
                "ACCT_DD_DISPLAY": "",
                "PRE_ELIM_GAAP_AMT_HO_CURR": 0,
                "CUR_ELIM_AMT": 0,
                "OTHER_ELIMS_AMT": 0,
                "POST_ELIM_GAAP_AMT_HO_CURR": 0,
                "EP_AMT": 0,
                "POST_ELIM_EP_AMT_HO_CURR": 0,
                "TYPE": "blank",
                "TP_KEY": 0,
                "TP_ENTITY": "",
                "TP_REP_PD": 0,
                "LE_COMBINATION_KEY":"",
                "FEED_DISPLAY":"",
                "ENABLEFEED": false,
                "PREELIMGAAPLOADING": false,
                "OTHERELIMSLOADING": false,
                "POSTGAAPLOADING": false,
                "POSTEPLOADING": false,
                "TRANS_DETAILS_KEY": 0,
                "TRANS_HEADER_KEY": 0,
                "INVALID_OBJECT": false,
                "ACCT_LE_TP": 0
            };

            vm.accounts.push(targetAcct);
            if ($scope.elimForm !== undefined) {
                $scope.elimForm.$submitted = false;
            }
        };

        $scope.removeAccount = function (_index) {
            if(vm.transStatus === 'edit') {
                vm.trans_keys_to_del.push({"TRANS_DETAILS_KEY" : vm.accounts[_index]["TRANS_DETAILS_KEY"], "TRANS_HEADER_KEY" : vm.accounts[_index]["TRANS_HEADER_KEY"]});

            }

            vm.accounts.splice(_index, 1);
            setElimReclassTotal();
        };

        $scope.validateDesc = function() {
            if(vm.ELIM_DESCRIPTION.length === 250) {
                vm.isMaxLimitReached = true;
            } else {
                vm.isMaxLimitReached = false;
            }
        };

        $scope.removeMessage = function() {
            vm.isMaxLimitReached = false;
        };

        $scope.setTPValid = function(index) {

            var setFormName = "innerForm_" + index;
            var getInnerForm = $scope.elimForm[setFormName];

            if(getInnerForm.tradingPartner) {

                getInnerForm.tradingPartner.$setValidity("invalidAcctTPCombo", true);
                vm.accounts[index]["INVALID_OBJECT"] = false;
            }
        };

        $scope.getTradingPartners = function (val, index) {
            // alert(val);


            var params = {
                "action_code": "50swfi",
                "tax_year": $scope.taxYear,
                "scenario": $scope.scenario,
                "search_string": val
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };

        $scope.cancel = function () {
           /*  var args = {object_id: vm.rowData.combination_key, gridFilter: {COMBINATION_KEY: vm.rowData.combination_key}};
            $rootScope.$emit('gridUpdate', args); */
            CodeComboFactory.resetValues(); /* 2/10/2020 */
            $uibModalInstance.dismiss('cancel');
        };

        $scope.feedSelected = function($item, $model,_index) {
           getElimDetails($item, _index, 'target', 'FEED');
        };

        $scope.getAccounts = function (val, index) {

            var balSheetAccts = [1,2,3];
            var acctType = vm.sourceAcctType;
            var acct_type_str = "";
            var chart_key = vm.accounts[index]["FEED_CHART_KEY"];

            console.log('row data  feed: ' +  vm.rowData.COMBINATION_KEY + ', curr index combination key: ' + vm.accounts[index]["LE_COMBINATION_KEY"] );
            if (acctType in balSheetAccts) {
                acct_type_str = '1,2,3';
            } else {
                acct_type_str = '5,6,7';
            }

            var params = {
                "action_code": "98ec1q",
                "screen_key": vm.mainScreenKey,
                "combination_key": vm.accounts[index]["LE_COMBINATION_KEY"],
                "source_type": 'F',
                "chart_key":chart_key,
                "search_type": 'ACCT',
                "search_string": val,
                "acct_type_str": acct_type_str
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };

        $scope.setAccount = function (account, index) {
            console.log('selected acct', account);
            getElimDetails(account, index, 'target', 'ACCT');

        };

        $scope.setTradingPartner = function (tp, index) {
            if(tp.TP_KEY !== 0) {
               getElimDetails(tp, index, 'target', 'TP');

            }

        };

        $scope.setPostElimTotal = function (_index) {
           var account = vm.accounts[_index];

           account.POST_ELIM_GAAP_AMT_HO_CURR = parseFloat(account.PRE_ELIM_GAAP_AMT_HO_CURR) + parseFloat(account.OTHER_ELIMS_AMT);
           account.POST_ELIM_EP_AMT_HO_CURR = parseFloat(account.PRE_ELIM_GAAP_AMT_HO_CURR) + parseFloat(account.OTHER_ELIMS_AMT) + parseFloat(account.EP_AMT);
           if (account.CUR_ELIM_AMT !== undefined) {
               account.POST_ELIM_GAAP_AMT_HO_CURR += parseFloat(account.CUR_ELIM_AMT);
               account.POST_ELIM_EP_AMT_HO_CURR += parseFloat(account.CUR_ELIM_AMT);
           }
           setElimReclassTotal();
        };

        $scope.showDetails = function (_index, _boolean) {
            vm.reclassArray[_index].open = _boolean;
        };

        $scope.loadReclass = function (_index) {
            var data = vm.reclassArray[_index];

            vm.ELIM_DESCRIPTION = data.ADJ_DESC;
            vm.GROUP_TRANS_KEY = data.GROUP_TRANS_KEY;
            vm.TRANS_HEADER_KEY = data.TRANS_HEADER_KEY;
            vm.ELIM_TYPE = data.ADJ_TYPE;
            vm.accounts = data.ACCOUNTS;
            vm.iselimTypeDisabled = true;
            vm.transStatus = 'edit';
            //$scope.RECLASS_TYPE = data.SHORT_ADJ_TYPE;

            _.forEach(vm.accounts, function (value, key) {
                $scope.setPostElimTotal(key);
            });

            setElimReclassTotal();
            $rootScope.$broadcast('table.edit', data);
        };

        var setElimReclassTotal = function () {

            var total = 0;
            angular.forEach(vm.accounts, function (value, key) {
                if (value.CUR_ELIM_AMT && !isNaN(value.CUR_ELIM_AMT)) {
                    total += parseFloat(value.CUR_ELIM_AMT);
                }
            });
            vm.elimReclassTotal = total;
        };

        $scope.reset = function () {
            init();
        };


        $scope.save = function () {
            //alert("the adjustment type selected is : " + $scope.RECLASS_TYPE);
            validateData();

            if ($scope.elimForm.$invalid) {
                $scope.elimForm.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            vm.crudLoading = true;
            var objSettings = [];
            var returnObj = {};

            returnObj.ELIM_DESCRIPTION = vm.ELIM_DESCRIPTION;
            returnObj.tax_year= EliminationLocalFactory.getScreenFilters().tax_year;
            var txIssueId = GlobalService.globalParams.issue_id_for_tx_save;
            returnObj.ISSUE_KEY = txIssueId === undefined || txIssueId === '' ? 
                (GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)) 
                : txIssueId;
            // returnObj.ISSUE_KEY = GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id);
            returnObj.ADJ_TYPE = vm.ELIM_TYPE;
            returnObj.TYPE = (vm.GROUP_TRANS_KEY === 0) ? "S" : "U";
            returnObj.GROUP_TRANS_KEY = (vm.GROUP_TRANS_KEY === 0) ? 0 : vm.GROUP_TRANS_KEY;
            returnObj.ADJ_ORIGIN_SOURCE = vm.rowData.SOURCE_SYSTEM;
            returnObj.SOURCE_SYSTEM_FLAG = (returnObj.ADJ_ORIGIN_SOURCE === 'DCS') ? 'D':'F';
            returnObj.TRANS_KEYS_TO_DEL = vm.trans_keys_to_del;
            var message = (returnObj.type === "U") ? "Eliminations have been successfully updated" : "Eliminations have been successfully created";
            //returnObj.CHART_KEY = vm.rowData.LOCAL_CHART_KEY;

            var accountArray = [];
            var accounts = vm.accounts;
            var le_combo_key, trans_header_key;

            _.forEach(accounts, function (value, key) {
                //alert(vm.ELIM_TYPE);
                le_combo_key = value.LE_COMBINATION_KEY;
                trans_header_key = value.TRANS_HEADER_KEY;
                var accountM = new accountModel(trans_header_key, value.LOCAL_ACCT, le_combo_key, value.LOCAL_ACCT_KEY, value.TP_KEY, value.CUR_ELIM_AMT, value.TRANS_DETAILS_KEY, value.FEED_CHART_KEY);
                accountArray.push(accountM);
            });


            objSettings.push(returnObj);

           reclassCRUDHttp(objSettings, accountArray, message);

        };

         $scope.deleteReclass = function (reclass) {

            var returnObj = {};
            vm.existingLoading = true;
            returnObj.group_trans_key = reclass.GROUP_TRANS_KEY;
            returnObj.issue_key = reclass.ISSUE_ID;
            returnObj.type = "D";
            returnObj.combination_key = vm.rowData.COMBINATION_KEY;
            returnObj.chart_key = vm.rowData.CHART_KEY;
            var message = "Reclass has been successfully deleted";
            reclassCRUDHttp(returnObj, '', message);
        };

        function validateData() {
                var elimLevel = vm.ELIM_TYPE;
                var object_to_validate, formName, getInnerForm;


                if(vm.accounts.length === 1) {
                    AlertService.add("danger", "There should be more than one account to add Eliminations", 4000);
                    return;
                }

                _.forEach(vm.accounts, function (value, key) {

                    formName = "innerForm_" + key;
                    getInnerForm = $scope.elimForm[formName];
                    console.log(getInnerForm);

                    object_to_validate = "" + value.LOCAL_ACCT_KEY + value.LE_COMBINATION_KEY + value.TP_KEY;
                    var getIndexFoundAt = _.findIndex(vm.accounts, {'ACCT_LE_TP': "" + object_to_validate});


                    if(getIndexFoundAt < 0 || key === getIndexFoundAt) {
                        //$scope.elimForm.innerForm.tradingPartner.$setValidity("invalidAcctTPCombo", true);
                        if(getInnerForm.tradingPartner) {
                            getInnerForm.tradingPartner.$setValidity("invalidAcctTPCombo", true);
                        }
                        if(/* elimLevel === 'F' && */ getInnerForm.entity) {
                            getInnerForm.entity.$setValidity("invalidAcctTPCombo", true);
                        }

                        if(/* elimLevel === 'F' && */ getInnerForm.account) {
                            getInnerForm.account.$setValidity("invalidAcctTPCombo", true);
                        }
                        value["INVALID_OBJECT"] = false;
                    } else {
                        if(getInnerForm.tradingPartner) {
                            getInnerForm.tradingPartner.$setValidity("invalidAcctTPCombo", false);

                             value["TP_KEY"] = 0;
                             value["TP_ENTITY"] = "";
                             value["TP_REP_PD"] = 0;

                             value.tpObject = {
                                "TP_ENTITY": "",
                                "TP_KEY": 0,
                                "TP_REP_PD": 0,
                                "TP_LEID": "",
                                "TP_CDR_NO": ""
                            };
                        }
                        if(/* elimLevel === 'F' &&  */getInnerForm.entity) {
                            getInnerForm.entity.$setValidity("invalidAcctTPCombo", false);

                        }
                        if(/* elimLevel === 'F' && */ getInnerForm.account) {
                            getInnerForm.account.$setValidity("invalidAcctTPCombo", false);
                        }

                        value["INVALID_OBJECT"] = true;

                    }

               });



        };


        function reclassCRUDHttp(returnObj, accountArray, message) {

            if(returnObj.type === 'D') {
                EliminationLocalFactory.deleteElims(returnObj).then(function (result) {
                    GlobalService.globalParams.issue_id_for_tx_save = '';
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                        //BROADCAST CHANGE
                        var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                        //init();
                        $uibModalInstance.close();
                    }
                });
            } else {


                EliminationLocalFactory.saveUpdateEliminations(returnObj, accountArray).then(function (result) {
                    GlobalService.globalParams.issue_id_for_tx_save = '';
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                        //BROADCAST CHANGE
                        //$uibModalInstance.close();
                        var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                        //init();
                        $uibModalInstance.close();
                    }
                });
            }
        }

        function accountModel(trans_header_key, accountCode, combo_key, acctKey, tp_key, amount, trans_dtls_key, feed_chart_key) {
            this.TRANS_HEADER_KEY = trans_header_key;
            this.ACCOUNT_CODE = accountCode;
            this.COMBINATION_KEY = combo_key;
            this.ACCT_KEY = acctKey;
            this.TP_KEY = tp_key;
            this.AMOUNT = amount;
            this.TRANS_DETAILS_KEY = trans_dtls_key;
            this.FEED_CHART_KEY = feed_chart_key;
        }

        accountModel.prototype.addAccount = function () {

        };



        function getFeeds() {

            var params = {
                "action_code": "nsh8fs",
                "tax_year": $scope.taxYear,
                "scenario": $scope.scenario,
                "ho_combination_key": rowData.HO_COMBINATION_KEY
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        _.forEach(data.jsonObject, function (value, key) {
                            vm.feedDtls.push(value);
                        });
                    };
                };
            });
        };

        function getAmounts(index) {
            var currAccount = vm.accounts[index];

            console.log('get Amounts: ', currAccount);
            currAccount["PREELIMGAAPLOADING"]=true;
            currAccount["OTHERELIMSLOADING"]=true;
            currAccount["POSTGAAPLOADING"]=true;
            currAccount["POSTEPLOADING"]=true;

            var combination_key = currAccount["LE_COMBINATION_KEY"];
            var acct_key = currAccount["LOCAL_ACCT_KEY"];
            var tp_key = currAccount["TP_KEY"];
            var ho_combination_key = vm.rowData.HO_COMBINATION_KEY;

            var params = {
                "action_code": "wi4d93",
                "combination_key": combination_key,
                "ho_combination_key": ho_combination_key,
                "acct_key": acct_key,
                "tp_key": tp_key
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {

                    if (data.jsonObject.length) {
                        currAccount["PREELIMGAAPLOADING"]=false;
                        currAccount["OTHERELIMSLOADING"]=false;
                        currAccount["POSTGAAPLOADING"]=false;
                        currAccount["POSTEPLOADING"]=false;
                        currAccount["PRE_ELIM_GAAP_AMT_HO_CURR"] = data.jsonObject[0]["PRE_ELIM_GAAP_AMT_HO_CURR"];
                        currAccount["CUR_ELIM_AMT"] = data.jsonObject[0]["CUR_ELIM_ADJ"];
                        currAccount["OTHER_ELIMS_AMT"] = data.jsonObject[0]["OTHER_ELIM_ADJS"];
                        currAccount["POST_ELIM_GAAP_AMT_HO_CURR"] = data.jsonObject[0]["POST_ELIM_GAAP_AMT_HO_CURR"];
                        currAccount["EP_AMT"] = data.jsonObject[0]["PRE_ELIM_TAX_AMT_HO_CURR"];
                        currAccount["POST_ELIM_EP_AMT_HO_CURR"] = data.jsonObject[0]["POST_ELIM_EP_AMT_HO_CURR"];
                    };
                    setElimReclassTotal();
                };
            });
        }

        function init() {
            vm.accounts = [];
            vm.crudLoading = false;
            vm.ELIM_DESCRIPTION = "";
            vm.reclassArray = [];
            vm.GROUP_TRANS_KEY = "";
            vm.existingLoading = false;
            vm.noRecords = false;
            vm.iselimTypeDisabled = false;
            vm.transStatus = 'new';
            vm.isMaxLimitReached = false;
            if ($scope.elimForm !== undefined) {
                $scope.elimForm.$setPristine();
                $scope.elimForm.$setUntouched();
            }

            if (vm.rowData.COMBINATION_KEY) {

                CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                CodeComboFactory.showFormBtn = false;
                getFeeds();

                getElimDetails(vm.rowData, 0, 'source', 'INIT');

                loadExistingElims();
            } else {

                console.log("nnnnnnnnnnnnnnnnn $scope.rowData.COMBINATION_KEY NOT FOUND ", vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = true;
                alert("yyyyyyyy $scope.rowData.COMBINATION_KEY not FOUND");
            }
        };

        init();

        $scope.$on('CCKeySet', function (event, data) {
            vm.showElimForm = true;

        });

        function getElimDetails(_data, _index, type, calledBy) {

            var acct_le_tp;

            if(_index === 0) {

                acct_le_tp = "" + _data.LOCAL_ACCT_KEY + _data.COMBINATION_KEY + _data.TP_KEY;

                // index 0 is the selected rowObject from the grid.
                if(_data.hasOwnProperty('ME_CODE')){
                    var feedDisplay = _data.LEID + " - " + _data.MARS + " / " + _data.ME_CODE + " / " + _data.FC;
                } else {
                    var feedDisplay = _data.LEID + " - " + _data.MARS + " / " + _data.ME + " / " + _data.FC;
                }
                var record = {
                    "LOCAL_ACCT_KEY": _data.LOCAL_ACCT_KEY,
                    "SYSTEM_ACCT_KEY": _data.SYSTEM_ACCT_KEY,
                    "LOCAL_ACCT": _data.LOCAL_ACCT,
                    "LOCAL_ACCT_DESC": _data.LOCAL_ACCT_DESC,
                    "ACCT_DD_DISPLAY": _data.LOCAL_ACCT + '-' + _data.LOCAL_ACCT_DESC,
                    "TP_KEY": _data.TP_KEY,
                    "TP_ENTITY": (_data.TP_KEY === 0? '' :_data.TP_LEID + "-" + _data.TP_CDR_NO + "(Pd:" + _data.TP_REP_PD + ")"),
                    "TP_REP_PD": (_data.TP_KEY === 0? 0 : _data.TP_REP_PD),
                    "TYPE": type,
                    "LE_COMBINATION_KEY": _data.COMBINATION_KEY,
                    "FEED_DISPLAY": feedDisplay,
                    "ENABLEFEED": true,
                    "TRANS_HEADER_KEY":0,
                    "TRANS_DETAILS_KEY": 0,
                    "INVALID_OBJECT": false,
                    "ACCT_LE_TP": acct_le_tp,
                    "FEED_CHART_KEY": _data.LOCAL_CHART_KEY
                };
                vm.accounts[_index] = record;
                $scope.addTargetAccount();

            } else {
                var currAccount = vm.accounts[_index];
                if(calledBy==='ACCT') {

                    acct_le_tp = "" + _data.LOCAL_ACCT_KEY + currAccount["LE_COMBINATION_KEY"] + _data.TP_KEY;

                    currAccount["LOCAL_ACCT_KEY"] = _data.LOCAL_ACCT_KEY;
                    currAccount["SYSTEM_ACCT_KEY"] = _data.SYSTEM_ACCT_KEY;
                    currAccount["LOCAL_ACCT"] = _data.LOCAL_ACCT;
                    currAccount["LOCAL_ACCT_DESC"] = _data.LOCAL_ACCT_DESC;
                    currAccount["TP_KEY"] = _data.TP_KEY;
                    currAccount["TYPE"] = type;
                    currAccount["ENABLEFEED"]=true;
                    currAccount["ACCT_LE_TP"] = acct_le_tp;


                    if(_data.TP_KEY !== 0) {
                            currAccount.tpObject = {
                            "TP_ENTITY": _data.TP_LEID + "-" + _data.TP_CDR_NO + "(Pd:" + _data.TP_REP_PD + ")",
                            "TP_KEY": _data.TP_KEY,
                            "TP_REP_PD": _data.TP_REP_PD,
                            "TP_LEID": _data.TP_LEID,
                            "TP_CDR_NO": _data.TP_CDR_NO
                        };

                    }

                } else if (calledBy==='FEED') {

                    acct_le_tp = "" + currAccount["LOCAL_ACCT_KEY"] + _data.COMBINATION_KEY + currAccount["TP_KEY"];

                    currAccount["LE_COMBINATION_KEY"] = _data.COMBINATION_KEY;
                    currAccount["FEED_DISPLAY"] = _data.LEID + " - " + _data.CDR_NO + " / " + _data.ME_CODE + " / " + _data.CURR;
                    currAccount["ACCT_LE_TP"] = acct_le_tp;
                    currAccount["FEED_CHART_KEY"] = _data.LOCAL_CHART_KEY;

                } else if(calledBy==='TP') {

                    acct_le_tp = "" + currAccount["LOCAL_ACCT_KEY"] + currAccount["LE_COMBINATION_KEY"] + _data.TP_KEY;

                    currAccount["TP_ENTITY"] = _data.TP_LEID + "-" + _data.TP_CDR_NO + "(Pd:" + _data.TP_REP_PD + ")";
                    currAccount["TP_KEY"] = _data.TP_KEY;
                    currAccount["TP_REP_PD"] = _data.TP_REP_PD;
                    currAccount["ACCT_LE_TP"] = acct_le_tp;

                }

                currAccount["INVALID_OBJECT"] = false;

            }

            if(calledBy !== 'FEED') {
                getAmounts(_index);
            }

        };

        function loadExistingElims() {
            vm.existingLoading = true;
            var params = {
                "action_code": "mk7ss9",
                "screen_key": vm.mainScreenKey,
                "chart_key": vm.rowData.LOCAL_CHART_KEY,
                "ho_combination_key": vm.ho_combination_key
            };

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {


                vm.reclassArray = formatReclassData(data.jsonObject);

                setElimReclassTotal(vm.reclassArray);


                vm.existingLoading = false;
                if (vm.reclassArray.length === 0) {
                    vm.noRecords = true;
                }
            });
        }

        function formatReclassData(_data) {
            var reclasses = [];
            var accounts = [];
            _.forEach(_data, function (value, key) {

                if (value.ROW_INDICATOR === 1) {

                    var ReclassObj = {};
                    ReclassObj = value;
                    ReclassObj.ACCOUNTS = [];
                    reclasses.push(ReclassObj); // JUST USE VALUE

                } else {
                    var AccountObj = {};
                    AccountObj = value;

                    AccountObj.TYPE = "source";
                    accounts.push(AccountObj);

                }
            });


            _.forEach(reclasses, function (reclassVal, reclassKey) {
                reclasses[reclassKey].ACCOUNTS = [];
                var row_one_group_trans_key = reclassVal.GROUP_TRANS_KEY;

                _.forEach(accounts, function (value, key) {

                    var account_GROUP_TRANS_key = value.GROUP_TRANS_KEY;

                    if (value.ROW_INDICATOR !== 1 && row_one_group_trans_key === account_GROUP_TRANS_key) {
                      reclasses[reclassKey].ACCOUNTS.push(value);

                    }

                });
            });

            accounts = null;
            return reclasses;

        }
    }///////////////END CTRL

    return controllers;


});