define(
    ['angular',

        './ExpenseTransServices',

    ],
    function() {
        'use strict';

        var controllers = angular
            .module('app.ExpenseTransCtrl', ['app.expenseTransServices'])

            .directive("limitToMax", function() {
                return {
                    link: function(scope, element, attributes) {
                        //var count = 0;
                        element.bind('keypress', function(e) {
                            if (!(e.charCode >= 46 && e.charCode <= 57) || e.charCode === 47) {
                                e.preventDefault();
                            }
                            var newVal = $(this).val() + (e.charCode !== 0 ? String.fromCharCode(e.charCode) : '');
                            if (String.fromCharCode(e.charCode) === ".") {

                                if ($(this).val().indexOf(".") != -1) {
                                    e.preventDefault();
                                }
                            }

                            if ($(this).val().search(/(.*)\.[0-9][0-9]/) === 0 && newVal.length > $(this).val().length) {
                                e.preventDefault();
                            }
                        });
                        element.on("keydown keyup", function(e) {
                            var decimalData = element[0].value
                            if(decimalData<0 || decimalData>100){
                                console.log(" Its not in the range ..  so revert back ");
                                element[0].value=100;
                                
                           }

                            function countDecimalPlaces() {
                                var decimalPos = String(decimalData).indexOf('.');
                                if (decimalPos === -1) {
                                    return 0;
                                } else {
                                    return String(decimalData).length - decimalPos - 1;
                                }
                            }




                            // removeExtraDots();
                            if (countDecimalPlaces() > 2) {
                                element.val(Number(decimalData).toFixed(2));
                            }
                            if (Number(element.val()) > Number(attributes.max) &&
                                e.keyCode != 46 && e.keyCode != 8
                            ) {
                                e.preventDefault();
                                element.val(attributes.max);
                            }

                        });
                    }
                };
            })

            .controller(
                'expenseTransCtrl',
                ['$rootScope', '$scope', '$state', '$log',
                    'JsonObjectFactory', 'AlertService',
                    '$timeout', '$uibModal', '$uibModalInstance', 'rowData',
                    'gridData', 'GENERAL_CONFIG', 'ExpenseTransFactory', '$http', 'USER_SETTINGS', 'GlobalService', 'workspaceFactory', '$filter', expenseTransCtrl
                ]);
        // Directives based on http://stackoverflow.com/a/24271309/3894163
        function expenseTransCtrl($rootScope, $scope, $state, $log,
            JsonObjectFactory, AlertService, $timeout, $uibModal, $uibModalInstance, rowData, gridData,
            GENERAL_CONFIG, ExpenseTransFactory, $http, USER_SETTINGS, GlobalService, workspaceFactory, $filter) {

            // console.log(rowData,gridData,"asda");
            var vm = this;
            var temp = null;
            vm.showAtLast = true;
            //var CHECK_FORM1 = false;
            vm.userSettings = USER_SETTINGS;
            vm.isCreate = (gridData.length == 0 || gridData.colData.map == "Add") ? true : false;
            vm.isLoadingData = true;
            vm.counter = 0;
            vm.showScheduleM = false;
            vm.selectedAcctKey = null;
            vm.rowData = rowData;
            vm.levelDetails = [];
            vm.grossIncomeDetails = [];
            vm.mapDetails = [];
            vm.mapCopyDetails = [];
            vm.systemAccountDetails = [];
            vm.systemAccount = [];
            vm.localAccounts = [];
            vm.systemAccountStr = "";
            vm.localAccountStr = "";
            vm.schedule = [];
            vm.showLocalAccountDrop = false;
            vm.isINC = false;
            vm.systemAccountData = [{
                count: 0
            }];
            vm.dataReset = false;
            vm.isSaveClicked = false;
            vm.isFirstEdit = true;
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
                // $rootScope.$emit('gridUpdate', args);
            };
            vm.account_scheduleData = [];
            vm.selectedBaseFdiiCodes = [];
            vm.baseFDIIData = [];
            
            vm.selectedBaseBasketCode = [];
			vm.baseBasketData = [];
			
			vm.selectedBaseTypeCode = [];
			vm.baseTypeCodeData = [];
			
			vm.selectedBaseLevelCode = [];
			vm.baseLevelData = [];
			
			vm.baseEntityData = [];
			vm.newArray = [];
			vm.baseFDIIEdit = [];
			vm.baseBasketEdit = [];
			vm.baseTypeEdit = [];
			
			vm.codeMashDataJSONData = null;
			
			vm.checkInGrossExpenseCollection = ['Interest Expense', 'Research', 'Expenses A&A','Asset Exp'];
			vm.newArrayInfo = [];
			vm.resetEditInfo = false;
			vm.selectedBaseTypeCodeChanged = 0;
			vm.isDelete = 'N';
			vm.isChanged = false;

            //already filed tax year lock
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var lockparams = {
                "action_code": 'n10cpv',
              //  "tax_year": filterParams.tax_year,
              //  "scenario": GlobalService.globalParams.scenario,
              //  "jcd_key": GlobalService.globalParams.jcd_key,
                "p_consol_group_key": "1",
            };
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', lockparams).then(function(data) {
                vm.is_locked = data.jsonObject;
            });




            //	vm.selectedAcctKey; 
            if (rowData != null) {
                //					if(rowData.Level = "Filing Group"){
                vm.levelType = rowData.LEVEL1;
                vm.mapType = rowData.MAPTO;
                vm.percentage = rowData.PERCENTAGE;
                vm.isDelete = rowData.IS_DELETE;
                //					}
                vm.methodKey = (vm.isCreate == false) ? {CODE_KEY: vm.rowData.METHOD_KEY} : {};

            }

            //To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
            $scope.$watch(function() { return vm.is_locked }, function() {
                if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
                    vm.cancel();                                        
                    AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
                    return;
                }else if((vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].ADMIN_STATUS == 'N') && vm.isCreate){
                    vm.cancel();
                    AlertService.add("error", "Admin and Preparer can only create a Transaction Category.",'4000');
                    return;
                }  
            });
            
            if(vm.isCreate){
            	  vm.percentage = 100;
            }
         

            vm.level_1_show = false;
            vm.level_2_show = false;
            vm.Maccount_scheduleData = [];



            //vm.getDropdownDetails = function() {
            //if(gridData.colData.map === 'LOCAL_ACCT_KEY'){
            var action_code = "";
            if (vm.rowData && vm.rowData.MAP_DESC == 'by Account') {
                action_code = '30cny0';
            } else if (vm.rowData && vm.rowData.MAP_DESC == 'by Schedule M') {
                action_code = 'lrs0ke';
            }

            var params = {
                "action_code": action_code,
                //	'tax_year': GlobalService.globalParams.tax_year,
                'trans_catg_key': (vm.rowData) ? vm.rowData.TRANS_CATG_KEY : 0
            };
            //JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                .then(function(data) {
                    console.log(data.jsonObject);
                    if (vm.rowData && vm.rowData.MAP_DESC == 'by Account') {
                        vm.accountInforamtion = data.jsonObject;
                    } else if (vm.rowData && vm.rowData.MAP_DESC == 'by Schedule M') {
                        vm.scheduleMInforamtion = data.jsonObject;
                    }


                });
            //}

            params = {
                "action_code": '0gc5cq',
            };
            //JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                .then(function(data) {
                    vm.levelDetails = $filter('filter')(data.jsonObject, function(data) {
                        return (data.CODE_GROUP === 'FTC_LEVEL');
                    });

                    vm.level = (vm.isCreate == false) ? {
                        CODE_KEY: vm.rowData.LEVEL_KEY,
                        CODE_DESCRIPTION: vm.rowData.LEVEL_DESC
                    } : {};

                    vm.mapDetails = $filter('filter')(data.jsonObject, function(data) {
                        return (data.CODE_GROUP === 'FTC_TRANS_CATG_MAP');
                    });
                    vm.mapCopyDetails = angular.copy(vm.mapDetails);
                    vm.mapTo = (vm.isCreate == false) ? {
                        CODE_KEY: vm.rowData.MAP_KEY,
                        CODE_DESCRIPTION: vm.rowData.MAP_DESC
                    } : {};

                    vm.methodKeys = $filter('filter')(data.jsonObject, function(data) {
                        return (data.CODE_GROUP === 'METHOD_CODE');
                    });
                    
                    
                   /* _.remove(vm.methodKeys, {
                        CODE_DESCRIPTION: "Allocate"
                    });*/
                    vm.methodKey = (vm.isCreate == false) ? {
                        CODE_KEY: vm.rowData.METHOD_KEY
                    } : {};

                    // vm.openNextLevel_1();

                });
            /* params = {"action_code": '0gc5cq',};
//					params = {"action_code": '35apvi',};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
					.then(function(data) {
							vm.mapDetails = data.jsonObject;
							vm.mapTo =   (vm.isCreate == false)?{CODE_KEY:vm.rowData.MAP_KEY,CODE_DESCRIPTION:vm.rowData.MAP_DESC}:{};
							vm.onChangeMapDetails();
    	  
					});*/

             vm.baseEditInfoDetails = function(label) {
         
				vm.label = label;
				let params_info = {'sso_id':vm.userSettings.user.sso_id,
						'tax_year':filterParams.tax_year,
						'scenario':GlobalService.globalParams.scenario,
						'trans_catg_key': gridData.rowData.TRANS_CATG_KEY
				};
				let action = null;

				
				switch(label){
				case 'Base Basket':
					action = 'z6htpi';
					vm.title = 'Base Basket';
					break;
				case 'Base Type':
					action = '6kzmd7';
					vm.title = 'Base Type';
					break;
				case 'Base FDII':
					action = 'a8rd93';
					vm.title = 'Base FDII';
					break;
				
				}
				if (action){
					
				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code='+action, params_info).then(function(data) {
					vm.baseDataInfo = data.jsonObject;	
					console.log("vm.baseData	:: ",vm.baseData);
		    	});
				}
            }
            
            if(!vm.isCreate && gridData  && gridData.colData && gridData.colData.label !== undefined) {
            	vm.baseEditInfoDetails(gridData.colData.label);
            }
            
            params = {
                "action_code": 'hmkia6',
            };
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                .then(function(data) {
                	
                	
		           let curr_data = data.jsonObject;
		           vm.parentDetails = $filter('filter')(data.jsonObject, function(data) {
                       return (data.LEVEL_GROUP === 'Parent ME');
                   });
		           
		           vm.filingDetails = $filter('filter')(data.jsonObject, function(data) {
                       return (data.LEVEL_GROUP === 'Filing Group');
                   });
		           
		           vm.meDetails = $filter('filter')(data.jsonObject, function(data) {
                       return (data.LEVEL_GROUP === 'ME');
                   });
                    
                    vm.parentME = (vm.isCreate == false) ? {
                        PARENT_ME_KEY: vm.rowData.ME_KEY
                    } : {};
                    
                    vm.meInfo = (vm.isCreate == false) ? {
                        PARENT_ME_KEY: vm.rowData.ME_KEY
                    } : {};
                    
                    vm.filingGroup = (vm.isCreate == false) ? {
                        PARENT_ME_KEY: vm.rowData.ME_KEY
                    } : {};
                    
                    
                    if (vm.isCreate == false && vm.parentME) {
                        vm.level_1_show = (vm.parentME && vm.parentME.PARENT_ME_KEY !== undefined) ? true : false;
                    }
                    /*if (vm.isCreate == false && vm.filingGroup) {
                        vm.level_1_show = (vm.filingGroup && vm.filingGroup.PARENT_ME_KEY !== undefined) ? true : false;
                    }*/
                    
                    if (vm.isCreate == false && vm.meInfo) {
                        vm.level_1_show = (vm.meInfo && vm.meInfo.PARENT_ME_KEY !== undefined) ? true : false;
                        vm.meInfo = {
                            PARENT_ME_KEY: vm.rowData.ME_KEY
                        };
                    }
                    // vm.openNextLevel_1();

                });




            vm.transactionCategory = (!vm.isCreate) ? vm.rowData.TRANS_CATG_NAME : "";
            /*vm.schedule.push({
            	'account' : '',
            	'schedule' : '',
            	'account_schedule' : vm.rowData.ACCOUNT_SCHEDULE_M
            });*/
            //}
            
          
        	vm.getAttribMast = function() {
				var params = {
						"action_code" : '2nk5nz',
					};
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
		                	vm.attribMastData = data.jsonObject;
		                	console.log(vm.attribMastData,"vm.attribMastData");
		                }
					});
			}
        	
        	vm.getAttribMast();
            vm.getCodeMastData = function() {
				var params = {
					"action_code" : '31hizx',
					"cc_key" : null,
					'p_scenario':GlobalService.globalParams.scenario,
					'p_jcd_key' :GlobalService.globalParams.jcd_key
					
				};
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
		                	
		                	vm.codeMashDataJSONData = data.jsonObject;
		                	
		                	vm.basketCodeAllocateData = _.filter(data.jsonObject, function(p){
		                		return _.includes(['SOURCE_CODE_ALLOCATE'], p.CODE_GROUP);  
		                		});
		                	_.remove(vm.basketCodeAllocateData, {CODE_DESCRIPTION: 'Default'});
		                	
		                	vm.baseFDIIData = _.filter(data.jsonObject, function(p){
		                		  return _.includes(['FDII_CODE'], p.CODE_GROUP);  
		                		});
		                	
		                	
		                	vm.baseBasketData = _.filter(data.jsonObject, function(p){
		                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
		                		});
		                	_.remove(vm.baseBasketData, {CODE_DESCRIPTION: 'Default'});
		                	
		                	
		                	vm.baseLevelData = _.filter(data.jsonObject, function(p){
		                		  return _.includes(['BASE_LEVEL_CODE'], p.CODE_GROUP);  
		                	});
		                	
		                	
		                	vm.baseEntityData = _.filter(data.jsonObject, function(p){
		                		  return _.includes(['BASE_ENTITY_CODE'], p.CODE_GROUP);  
		                		});

		                	
		                	
		                	vm.baseTypeCodeData = _.filter(data.jsonObject, function(p){
		                		  return _.includes(['BASE_TYPE_CODE'], p.CODE_GROUP);  
	                		});
		                	//vm.selectedBaseTypeCode = vm.baseTypeCodeData;
		                	
		                	vm.baseBasketTypeData = _.filter(data.jsonObject, function(p){
		                		  return _.includes(['BASE_BASKET_CODE'], p.CODE_GROUP);  
	                		});
		                	
		                	vm.basefdiiSingleMultiData = _.filter(data.jsonObject, function(p){
		                		  return _.includes(['BASE_FDII_CODE'], p.CODE_GROUP);  
	                		});
		                	
		                	vm.selectedBaseFdiiCodes = [];
		                	vm.selectedBaseBasketCode = [];
		                	vm.selectedBaseLevelCode = null;
		                	console.log(vm.selectedBaseLevelCode );
		                	vm.selectedBaseEntityLevelCode = null;
		                	
							$scope.$applyAsync(function () {
								
								//setTimeout(function(){ 
								if(vm.isCreate){
									vm.selectedBaseFdiiCodes = vm.baseFDIIData;
							    	vm.selectedBaseBasketCode = vm.baseBasketData;
							    	vm.selectedBaseLevelCode = vm.baseLevelData[0].CODE_KEY;
							    	console.log(vm.selectedBaseLevelCode );
							    	vm.selectedBaseEntityLevelCode = vm.baseEntityData[0].CODE_KEY;
								} else {
									
									let params_info = {'sso_id':vm.userSettings.user.sso_id,
											'tax_year':filterParams.tax_year,
											'scenario':GlobalService.globalParams.scenario,
											'trans_catg_key': gridData.rowData.TRANS_CATG_KEY
									};
									
									
									var exists = _.find(vm.baseLevelData, ['CODE_DESCRIPTION', vm.rowData.BASE_LEVEL]);
				                	if(exists != undefined)
				                		vm.selectedBaseLevelCode = exists.CODE_KEY;
				                	
				                	var exists = _.find(vm.baseEntityData, ['CODE_DESCRIPTION', vm.rowData.BASE_ENTITY_LEVEL]);
				                	if(exists != undefined)
				                		vm.selectedBaseEntityLevelCode = exists.CODE_KEY;
				                	
				                	if(vm.makeAll) {
				                		var exists = _.find(vm.baseLevelData, ['CODE_DESCRIPTION', 'ME']);
					                	if(exists != undefined)
					                		vm.selectedBaseLevelCode = exists.CODE_KEY;
					                	
					                	var exists = _.find(vm.baseEntityData, ['CODE_DESCRIPTION', 'COMPONENT']);
					                	if(exists != undefined)
					                		vm.selectedBaseEntityLevelCode = exists.CODE_KEY;
				                	}
									
									//BASE FDII
									if(vm.rowData.BASE_FDII === 'ALL' ||  vm.makeAll){
										vm.selectedBaseFdiiCodes = vm.baseFDIIData;
									} else {
									
									JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=a8rd93', params_info).then(function(data) {
										vm.baseFDIIEdit = data.jsonObject;
										let tempFilterInfo = [];
										vm.baseFDIIEdit.forEach((baseFDiiSplit) => {
											 let tempFilter = _.find(vm.baseFDIIData, ['CODE_DESC', baseFDiiSplit.CODE_DESCRIPTION]); 
											 if(tempFilter){
												 tempFilterInfo.push(tempFilter);
											 }
										 })
										 
										 vm.selectedBaseFdiiCodes = tempFilterInfo;
									});

									}
									
									//BASE BASKET
									if(vm.rowData.BASE_BASKET === 'ALL'  ||  vm.makeAll){
										vm.selectedBaseBasketCode = vm.baseBasketData;
									} else {
										
										JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=z6htpi', params_info).then(function(data) {
											vm.baseBasketEdit = data.jsonObject;
											let tempFilterInfo = [];
											vm.baseBasketEdit.forEach((baseBasketSplit) => {
												 let tempFilter = _.find(vm.baseBasketData, ['CODE_DESC', baseBasketSplit.CODE_DESCRIPTION]); 
												 if(tempFilter){
													 tempFilterInfo.push(tempFilter);
												 }
											 })
											 vm.selectedBaseBasketCode = tempFilterInfo;
										});
									}
									
									
									/*setTimeout(function(){ 
										vm.editbaseType();
									},200);*/
									
									//vm.getBaseType();
									
									
								}
								//},100);
							    	
							});
		                	
		                }
					});
			}
            
            vm.getBaseType = function() {
				var params = {
						"action_code" : '6xgttx',
						"gie_name" : vm.rowData ? vm.rowData.DISP_ACCT_CODE:vm.grossIncome.ACCT_DESC,
						'p_scenario' : GlobalService.globalParams.scenario,
						'p_jcd_key' :GlobalService.globalParams.jcd_key
						
					};
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
		                	vm.baseTypeData = data.jsonObject;
		                	if (vm.isCreate) 
		                	vm.selectedBaseTypeCode =  vm.baseTypeData;
		                	vm.getAllTransCatgExceptExp();
		                	console.log(vm.baseTypeData,"asd");
		                	//vm.editbaseType();
		                }
					});
			}
            
            if (rowData != null) {
            	vm.getBaseType();
            }
            
            vm.editbaseType = function() {
            	if (!vm.isCreate) {
            		vm.selectedBaseTypeCode = [];
            		//BASE TYPE
					if(vm.rowData.BASE_TYPE === 'ALL' || vm.makeAll){
						
						var checkInGrossExpenseCollectionLevel = ['INTEREST EXPENSE', 'RESEARCH', 'EXPENSES A&A','ASSET EXP'];
		                
		            	if (vm.grossIncome && checkInGrossExpenseCollectionLevel.indexOf(vm.grossIncome.DISP_ACCT_CODE.toUpperCase()) != -1){
		            		//vm.getAllTransCatgExceptExp();
		            		if (vm.methodKey &&  vm.methodKey.CODE_DESCRIPTION ==  'Gross Income') {
		            			vm.baseTypeData = vm.allTransCategoryData;            			
		            		} else {
		            			vm.baseTypeData = vm.allGPTransCategoryData;
		            		}
		            	}
		            	
						vm.selectedBaseTypeCode = vm.baseTypeData;
					} else {
						// vm.getAllBaseTypeByMethodChnage();
						let params_info = {'sso_id':vm.userSettings.user.sso_id,
								'tax_year':filterParams.tax_year,
								'scenario':GlobalService.globalParams.scenario,
								'trans_catg_key': gridData.rowData.TRANS_CATG_KEY
						};
						 
						 JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=6kzmd7', params_info).then(function(data) {
								/*vm.baseTypeEdit = data.jsonObject;
								console.log(vm.baseTypeEdit,"vm.baseTypeEdit data");*/
								vm.selectedBaseTypeCode =  data.jsonObject;
								vm.baseTypeData =  angular.copy(vm.selectedBaseTypeCode);
								 /*let tempFilterInfo = [];
								 vm.selectedBaseTypeCodeTemp = [];
								 vm.baseTypeEdit.forEach((baseTypeSplit) => {
									 let tempFilter = _.find(vm.baseTypeData, ['CODE_DESCRIPTION', baseTypeSplit.CODE_DESCRIPTION]); 
									 if(tempFilter){
										 vm.selectedBaseTypeCodeTemp.push(tempFilter);
									 }
								 });
								 vm.selectedBaseTypeCodeCopy = angular.copy(vm.selectedBaseTypeCodeTemp);
								// setTimeout(() => {
				        				$scope.$applyAsync(function() {
				        						vm.selectedBaseTypeCode = [];
				        						vm.selectedBaseTypeCode =  angular.copy(vm.selectedBaseTypeCodeCopy);
				        						vm.selectedBaseTypeCodeChanged = 2;
				        				})*/
				        		 //},500);
								// vm.selectedBaseTypeCodeChanged = 2;
								 //tempFilterInfo;
							});
					}
					
					
            	}
            }
            
            vm.showHideNewArray = function() {
            	//vm.newArray
            	vm.newArray = [];
            	var checkInGrossExpenseCollection = ['Interest Expense', 'Research', 'Expenses A&A','Asset Exp'];
            	
                if (vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE != undefined) {
                    if(checkInGrossExpenseCollection.indexOf(vm.grossIncome.DISP_ACCT_CODE) >= 0) {
                    	vm.newArray.push('method');
                    }
                } else if (vm.rowData) {
                    if(checkInGrossExpenseCollection.indexOf(vm.rowData.DISP_ACCT_CODE) >= 0) {
                    	vm.newArray.push('method');
                    }

                }
                
                if (vm.mapTo && vm.mapTo.CODE_DESCRIPTION !== 'by Amount' 
                    && vm.grossIncome && (vm.grossIncome.DISP_ACCT_CODE == 'Expenses A&A' || vm.grossIncome.DISP_ACCT_CODE == 'Asset Exp') &&
                    vm.methodKey && vm.methodKey.CODE_DESCRIPTION != 'Allocate') {
                	vm.newArray.push("selectedBaseFdiiCodes");
                	vm.newArray.push("selectedBaseBasketCode");
                	vm.newArray.push("selectedBaseTypeCode");
                	vm.newArray.push("selectedBaseLevelCode");
                	vm.newArray.push("selectedBaseEntityLevelCode");
                }
                
                if (vm.mapTo && vm.mapTo.CODE_DESCRIPTION !== 'by Amount' 
                    && vm.grossIncome && (vm.grossIncome.DISP_ACCT_CODE == 'Interest Expense' || vm.grossIncome.DISP_ACCT_CODE == 'Expenses A&A' || 
                            vm.grossIncome.DISP_ACCT_CODE == 'Asset Exp')
                            && vm.methodKey && vm.methodKey.CODE_DESCRIPTION == 'Allocate') {
                	vm.newArray.push("selectedBaseFdiiCodes_single");
                	vm.newArray.push("selectedBaseBasketCode__single");
                }
                
              
                
            	vm.newArray = [... new Set(vm.newArray)];
            	console.log(vm.newArray,"check------------------");
            }
            vm.getAllTransCatgExceptExp = function() {
				var params = {
						"action_code" : '9aszao',
						'p_scenario' : GlobalService.globalParams.scenario,
						'p_jcd_key' :GlobalService.globalParams.jcd_key
					};
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
		                	vm.allTransCategoryData = data.jsonObject;//TRANS_CATG_NAME
		                	
		                	var exists = _.find(vm.allTransCategoryData, ['GIE_NAME', 'GPROFIT'])
		                	if(exists != undefined)
		                		vm.allGPTransCategoryData = exists;
		                	
		                	vm.allGPTransCategoryData = _.filter(vm.allTransCategoryData, function(p){
		                		return _.includes(['GPROFIT'],  p.GIE_NAME);  
		                	})
		                	vm.baseTypeData = vm.allGPTransCategoryData;
		                	if (vm.isCreate) 
		                	vm.selectedBaseTypeCode =  vm.baseTypeData;
		                	
		                	//vm.editbaseType();
		                }
					});
			}	
            
            vm.changeBaseLevel = function() {
            	var exists = _.find(vm.baseLevelData, ['CODE_KEY', vm.selectedBaseLevelCode]);
            	if(exists)
            	vm.selectedBaseLevelCodeDESC = exists.CODE_DESCRIPTION;
            }
            
           /* vm.addAndRemoveElement = function() {
            	
            }*/
            vm.changeMethodToAllocate = function() {
            	if (vm.methodKey && vm.methodKey.CODE_DESCRIPTION == 'Allocate') {
            		let isPre =  _.find(vm.baseFDIIData, ['CODE_DESCRIPTION', 'US Derived DEI']);
        			let isPreBC =  _.find(vm.basketCodeAllocateData, ['CODE_DESCRIPTION', 'U.S.']);
        			
        			vm.selectedBaseFdiiCodes =  isPre ? isPre.CODE_KEY+"" : null;
        			vm.selectedBaseBasketCode = isPreBC ? isPreBC.CODE_KEY:null;
        			
        			if(!vm.isCreate && vm.isFirstEdit && vm.rowData.METHOD_CODE === 'Allocate' ) {
        				let isPre =  _.find(vm.baseFDIIData, ['CODE_DESCRIPTION', vm.rowData.BASE_FDII || 'US Derived DEI' ]);
            			let isPreBC =  _.find(vm.basketCodeAllocateData, ['CODE_DESCRIPTION',  vm.rowData.BASE_BASKET ||  'U.S.']);
            			
            			vm.selectedBaseFdiiCodes =  isPre ? isPre.CODE_KEY+"" : null;
            			vm.selectedBaseBasketCode = isPreBC ? isPreBC.CODE_KEY:null;
        				vm.isFirstEdit = false;
        			}
            	} else {
            		//if (!vm.isCreate) {
            			if(vm.isCreate || vm.rowData.METHOD_CODE === 'Allocate' || (!vm.isCreate && !vm.resetEditInfo && vm.methodKey.CODE_DESCRIPTION && vm.rowData.METHOD_CODE != vm.methodKey.CODE_DESCRIPTION) ) {
                			vm.makeAll = true; 
                		} else {
                			vm.makeAll = false; 
                		}
                		vm.getCodeMastData();
                		vm.editbaseType();
                		
                		
	                	
            		//}
            		
            	}
            	
            	if (vm.isCreate)
            	vm.getAllBaseTypeByMethodChnage();
            	
            }
            
            vm.getAllBaseTypeByMethodChnage = function() {
            	var checkInGrossExpenseCollectionLevel = ['INTEREST EXPENSE', 'RESEARCH', 'EXPENSES A&A','ASSET EXP'];
                
            	if (vm.grossIncome && checkInGrossExpenseCollectionLevel.indexOf(vm.grossIncome.DISP_ACCT_CODE.toUpperCase()) != -1){
            		//vm.getAllTransCatgExceptExp();
            		if (vm.methodKey &&  vm.methodKey.CODE_DESCRIPTION ==  'Gross Income') {
            			vm.baseTypeData = vm.allTransCategoryData;            			
            		} else {
            			vm.baseTypeData = vm.allGPTransCategoryData;
            		}
            		
            		
            		setTimeout(() => {
        				$scope.$applyAsync(function() {
        						vm.selectedBaseTypeCode = [];
        						vm.selectedBaseTypeCode = vm.baseTypeData;
        						vm.selectedBaseTypeCodeChanged = 1;
        				})
        			},1000);			
				} 
            }
          //Getting the Method as it is not coming from FTC Code Mast Table		
			vm.getMethod = function() {
				var params = {
						"action_code" : 'lwtsx1',
						"gie_key" : vm.grossIncome.ACCT_KEY,
						'p_scenario' : GlobalService.globalParams.scenario
					};
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
		                	vm.methodKeys = data.jsonObject;
		                	if(vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE == 'Expenses A&A'){
								var exists = _.find(vm.methodKeys, ['CODE_DESCRIPTION', 'Best Method - FDII']);
								if(exists){
									vm.methodKey = exists;
								}
							}
		                	
		                	if(vm.rowData) {
		                		var exists = _.find(vm.methodKeys, ['CODE_DESCRIPTION', vm.rowData.METHOD_CODE]);
		                		if(exists)
		                			vm.methodKey = exists;
		                	}
		                		
		                	
		                	if (!vm.isCreate && exists) {
		                		vm.methodKey = exists; //exists.CODE_KEY
		                		if (exists.CODE_DESCRIPTION == 'Allocate') {
		                			vm.selectedBaseFdiiCodes = "";
		                			let isPre =  _.find(vm.baseFDIIData, ['CODE_DESCRIPTION', vm.rowData.BASE_FDII]);
		                			let isPreBC =  _.find(vm.basketCodeAllocateData, ['CODE_DESCRIPTION', vm.rowData.BASE_BASKET]);
		                			
		                			setTimeout(() => {
		                				$scope.$applyAsync(function() {
		                					if(document.getElementById('FTC_AM_CD_24') && 
		                							(document.getElementById('FTC_AM_CD_24').options[0].value == "? undefined:undefined ?" ||
		                									document.getElementById('FTC_AM_CD_24').options[0].value == "? number:404 ?" ||
		                									document.getElementById('FTC_AM_CD_24').options[0].value == "?"))
		                        				document.getElementById('FTC_AM_CD_24').options[0].remove();
		                					
		                					//vm.selectedBaseFdiiCodesEdit = isPre ? isPre.CODE_KEY+"" : null;
		                				});
		                			},4000)
		                			vm.selectedBaseFdiiCodes =  isPre ? isPre.CODE_KEY+"" : null;
		                			vm.selectedBaseBasketCode = isPreBC ? isPreBC.CODE_KEY:null;
		                			
		                		}
		                	}
		                		 /*vm.methodKey = (vm.isCreate == false) ? {
		                             CODE_KEY: exists.CODE_KEY
		                         } : {};*/
		                         
		                         if (vm.isCreate) {
		                         	var exist = _.find(vm.mapDetails, ['CODE_DESCRIPTION', 'by Amount']);
		                             vm.mapTo = {
		                                 'CODE_KEY': exist.CODE_KEY,
		                                 'CODE_DESCRIPTION': 'by Amount'
		                             };
		                         }
		                   
		                	
		                	//vm.getAllTransCatgExceptExp();
		                	//vm.getDefaultsPopulateData();
		                }
					});
			}
            vm.getCodeMastData();
            
            vm.grossIncomeDropdownDetails = function() {
                var checkWithData = (vm.level !== undefined) ? vm.level.CODE_DESCRIPTION : (vm.rowData) ? vm.rowData.LEVEL_DESC : "";

                var params = {
                    "action_code": 'ug1a8d',
                };
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                    .then(function(data) {
                    	vm.grossIncomeDetails = data.jsonObject;
                       /* if (checkWithData == 'Filing Group') {
                            vm.grossIncomeDetails = data.jsonObject;
                        } else {
                            vm.grossIncomeDetails = $filter('filter')(data.jsonObject, function(data) {
                                return (data.ACCT_SUB_CATG === 'EXP' || data.ACCT_SUB_CATG === 'OTH_EXP' || data.ACCT_SUB_CATG === 'ASTEXP');
                            });
                        }*/
						
                        vm.grossIncome = (vm.isCreate == false) ? {
                            ACCT_KEY: vm.rowData.GIE_KEY,
                            DISP_ACCT_CODE: vm.rowData.DISP_ACCT_CODE
                        } : {};
                        
                        
                        if (!vm.isCreate) {
                            temp = vm.grossIncomeDetails && vm.grossIncomeDetails.map(function(details) {
                                if (vm.rowData.ACCT_KEY === details.ACCT_KEY) {

                                    if (vm.level.CODE_DESCRIPTION === 'Filing Group') {
                                        if (details.ACCT_SUB_CATG === 'INC' || details.ACCT_SUB_CATG === 'GP') {
											var exist = _.find(vm.mapDetails, ['CODE_DESCRIPTION', 'by Amount']);
                                            vm.mapTo = {
                                                'CODE_KEY': exist.CODE_KEY,
                                                'CODE_DESCRIPTION': 'by Amount'
                                            };
                                            vm.isINC = true;
                                        } else {
                                            vm.isINC = false;
                                            vm.mapTo = null;
                                        }
                                    }
                                    if (vm.level.CODE_DESCRIPTION === 'Parent ME') {
                                        vm.isINC = false;
                                        vm.mapTo = null;
                                    }
                                }
                            });

                            vm.transactionCategory = vm.rowData.TRANS_CATG_NAME;
                            if (vm.mapTo == null || vm.mapTo.CODE_DESCRIPTION == 'by Account') {
                                vm.mapTo = {
                                    CODE_KEY: vm.rowData.MAP_KEY,
                                    CODE_DESCRIPTION: vm.rowData.MAP_DESC
                                };
                                vm.openNextLevel_2();
                                vm.getSystemAccountValues();
								// vm.getLocalAccountDetails();
                            }
                            if (vm.rowData && vm.rowData.MAP_DESC == 'by Schedule M') {
                                vm.getScheduleMDetailsAll();
                            }
                            if ((vm.mapTo && vm.mapTo.CODE_DESCRIPTION && vm.mapTo.CODE_DESCRIPTION == 'by Amount') 
								|| vm.rowData && vm.rowData.MAP_DESC === 'by Amount') {
                                vm.isLoadingData = false;
                            }
                            var checkInGrossExpenseCollectionLevel = ['INTEREST EXPENSE', 'RESEARCH', 'EXPENSES A&A'];
//4	INTEXP	INTEREST EXPENSE
//10	RESEARCH	RESEARCH
//11	EXPAA	EXPENSES A&A

                            if (vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE != undefined) {
                                vm.checkInGroupLevel1 = !(checkInGrossExpenseCollectionLevel.indexOf(vm.grossIncome.DISP_ACCT_CODE) >= 0);
                                vm.getMethod();
                            }
                            vm.showHideNewArray();
                            // vm.changeMapValueByINC(); 
                        }

                    });
            };



            
            vm.checkDulicates = function(arr, checkWith) {
                var newArr = [];
                angular.forEach(arr, function(value, key) {
                    var exists = false;
                    angular.forEach(newArr, function(val2, key) {
                        if (angular.equals(value[checkWith], val2[checkWith])) {
                            exists = true;
                        }
                    });
                    if (exists == false && value[checkWith] != "") {
                        newArr.push(value);
                    }
                });
                return newArr;
            };
            vm.onChangeMapDetails = function() {
                var checkWithData = (vm.mapTo !== undefined) ? vm.mapTo.CODE_DESCRIPTION : vm.rowData.MAP_DESC;
                // console.log("checkWithData ?>>>>>>>>>>>>>>" + checkWithData);
                vm.systemAccountDetails = null;
                vm.systemAccount = [];
                vm.localAccounts = [];
                if (checkWithData == 'by Schedule M') {

                    vm.showScheduleM = true;
                    vm.showColumns = false;
                    vm.getScheduleMDetailsAll();
                    /*vm.getScheduleM();*/
                } else {
                    vm.showScheduleM = false;
                    vm.showColumns = true;
                    vm.getSystemAccountValues();
                }
                if (checkWithData === 'by Amount') {
                    vm.showColumns = false;
                    vm.isLoadingData = false;
                }
                
                if(checkWithData === 'by Account' && vm.isCreate && vm.methodKeys ) {
                	var exists = _.find(vm.methodKeys, ['CODE_DESCRIPTION', 'Best Method - FDII']);
					if(exists){
						vm.methodKey = exists;
					}
                }
            };

            vm.getSystemAccountValues = function() {
                if (vm.grossIncome != undefined) {
                    var params = {
                        "action_code": '05r78u',
                        "acct_key": vm.grossIncome.ACCT_KEY
                    }
					if(vm.grossIncome.DISP_ACCT_CODE === 'Asset Exp'){
						params['gr_flag'] = 'A';
					}
						else{
							params['gr_flag'] = 'E';
						}
                          
					
			
                    // console.log("selectedAcctKey >>>>>>>>>>" + vm.selectedAcctKey)
                    vm.systemAccountDetails = [];
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                        .then(function(data) {
                            vm.systemAccountDetails = data.jsonObject;
                            if (vm.systemAccountDetails && vm.systemAccountDetails.length === 0) {
                                vm.systemAccountDetails = [];
                                vm.systemAccountDetails.push({
                                    'ACCT': 'All',
                                    'DESCRIPTION': 'ALL'
                                });

                            }

                        });
                }
            };
			
			vm.getLocalAccountDetails = function(isEdit) {
				if(vm.systemAccount.length > 0 && vm.grossIncome //&& vm.grossIncome.DISP_ACCT_CODE !== 'Asset Exp'
						){
			
                //	vm.systemAccountStr  = vm.systemAccount.map(a => a.ACCT_KEY).join(",");
				console.log("getLocalAccountDetails");
                var arrayToPush = [];
                //vm.systemAccount.map(a => arrayToPush.push(a.ACCT_KEY));
                vm.systemAccount.map(function(a) {
                    arrayToPush.push(a.ACCT_KEY);
                });
                vm.systemAccountStr = arrayToPush.toString();
                vm.localAccounts = [];
                vm.showLocalAccountDrop = false;
                var params = {
                    "action_code": 'n1ez0t',
                    "acct_key": vm.systemAccountStr,
                    "me_key": vm.parentME ? vm.parentME.PARENT_ME_KEY : null
                };
				if(isEdit ==  'edit'){
					vm.isLoadingLocalAccounts = true;
				}else{
					vm.isLoadingLocalAccounts = false;
				}
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                    .then(function(data) {
						vm.localAccounts = [];
                        vm.account_scheduleData = data.jsonObject;
                        vm.showLocalAccountDrop = true;
						if(isEdit === "" && vm.account_scheduleData != null && vm.account_scheduleData.length > 0 && vm.accountInforamtion === undefined){
							vm.account_scheduleData.unshift({
                                'ACCT_KEY': 'All',
                                'ACCT_DESC': 'ALL'
                            });
						}
						if (vm.account_scheduleData != null && vm.account_scheduleData.length > 0 && vm.accountInforamtion && vm.accountInforamtion.length > 0) {
                            vm.account_scheduleData.unshift({
                                'ACCT_KEY': 'All',
                                'ACCT_DESC': 'ALL'
                            });
                            
                            if (!vm.isCreate ) {
								
                                temp = vm.accountInforamtion && vm.accountInforamtion.map(function(account) {
                                    vm.account_scheduleData.map(function(obj) {
                                        if (obj.ACCT_KEY === account.P_LOCAL_ACCT_KEY) {
                                            vm.localAccounts.push(obj);
                                        }
                                    });

                                });
								var checkALocalAccounts  = vm.checkDulicates(vm.localAccounts, 'ACCT_KEY');
								if(checkALocalAccounts && checkALocalAccounts.length > 0){
								
                                    vm.localAccounts = checkALocalAccounts;
                                    vm.localAccountCopy = angular.copy(vm.localAccounts);
								
									if(isEdit ==  'edit'){
										vm.localAccounts = [];
										$scope.$applyAsync(function() {
											
											//setTimeout(function(){
												vm.localAccounts = checkALocalAccounts;
												vm.localAccountCopy = angular.copy(vm.localAccounts);
												vm.isLoadingLocalAccounts= false;
											//},2);
												 
										});
										
									}										
                                vm.isLoadingData = false;
								}
                               

                            }
                        }
                         if(vm.account_scheduleData.length === 0) {
                            vm.account_scheduleData = [];
                            vm.account_scheduleData.push({
                                'FROM_ACCT_KEY': 'All',
                                'ACCT_DESC': 'ALL'
                            });
							vm.showLocalAccountDrop = false;
                            /* vm.account_scheduleData.push({'FROM_ACCT_KEY':'1','ACCT_DESC':'DESC1'});
                             vm.account_scheduleData.push({'FROM_ACCT_KEY':'2','ACCT_DESC':'DESC2'});*/
                        }

                        // console.log(vm.account_scheduleData[index],'vm.account_scheduleData[index]');

                    });
				}else{
					vm.showLocalAccountDrop = false;
				}
            };

            vm.getScheduleMDetailsAll = function() {
                if (vm.grossIncome != undefined) {
                    var params = {
                        "action_code": 'mcf9nk'
                    };
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                        .then(function(data) {
                            vm.scheduleMOptions = data.jsonObject;
                            /*if (vm.scheduleMOptions && vm.scheduleMOptions.length === 0) {
                                vm.scheduleMOptions = [];
                                vm.scheduleMOptions.push({
                                    'SCHDM_TEMPLATE_ID': 'All',
                                    'SCHDM_DESC': 'ALL'
                                });

                            }*/
                            // console.log(vm.scheduleMOptions,'scheduleMOptions');

                        });
                }
            };

        
            
            
            $scope.$watchCollection(
                    function getValue() {
                        return ([vm.systemAccount,vm.localAccounts,vm.scheduleMDetails, vm.percentage,vm.isLoadingData,vm.transactionCategory]);
                    },
                    function collectionChanged(newValue, oldValue, $scope) {
                    	if(!vm.isCreate && !vm.isLoadingData ) {
                    		if((_.differenceBy(vm.systemAccount,vm.systemAccountCopy,'ACCT_KEY').length > 0 ) 
                    				|| (vm.percentageCopy != vm.percentage) 
                    				|| (_.differenceBy(vm.systemAccountCopy,vm.systemAccount,'ACCT_KEY').length > 0 )
                    				|| (_.differenceBy(vm.localAccounts,vm.localAccountCopy,'ACCT_DESC').length > 0)
                    				|| (_.differenceBy(vm.localAccountCopy,vm.localAccounts,'ACCT_DESC').length > 0)
                    				|| (_.differenceBy(vm.scheduleMDetails,vm.scheduleMDetailsCopy,'SCHDM_TEMPLATE_ID').length > 0) || 
                    				(_.differenceBy(vm.scheduleMDetailsCopy,vm.scheduleMDetails,'SCHDM_TEMPLATE_ID').length > 0)) {
                    			vm.isChanged = true;
                    		} else {
                    			vm.isChanged = false;
                    		}
                    		
                    	} else {
                    		vm.systemAccountCopy = vm.systemAccount;
                    		vm.percentageCopy = vm.percentage;
                    		vm.localAccountCopy = vm.localAccounts;
                    		vm.scheduleMDetailsCopy = vm.scheduleMDetails;
                    	}
     
              });
            
            //
            $scope.$watchCollection(
                    function getValue() {
                        return ([vm.selectedBaseLevelCodeDESC]);
                    },
                    function collectionChanged(newValue, oldValue, $scope) {
                    	console.log(newValue,"newValue---bbbb");
                    	if(newValue && newValue[0] === "ALL ME" && vm.baseTypeData && vm.selectedBaseTypeCode && vm.baseTypeData.length !== vm.selectedBaseTypeCode.length) {
                    		vm.selectedBaseTypeCode = [];
                    		$scope.$applyAsync(function() {
                    			vm.selectedBaseTypeCode = vm.baseTypeData;
                    		});
                    	}
     
              });
            
            $scope.$watchCollection(
                    function getValue() {
                        return ([vm.isLoadingData]);
                    },
                    function collectionChanged(newValue, oldValue, $scope) {
                    	/*if(!vm.isCreate && document.getElementById('FTC_AM_CD_24') && document.getElementById('FTC_AM_CD_24').options[0].value === '?')
            				document.getElementById('FTC_AM_CD_24').options[0].remove();
            			if(!vm.isCreate && document.getElementById('FTC_AM_CD_25') && document.getElementById('FTC_AM_CD_25').options[0].value === '?')
            				document.getElementById('FTC_AM_CD_25').options[0].remove();*/
            			
                    	if(!vm.isCreate) {
                    		
                    		if(vm.baseTypeData && vm.baseTypeData.length > 0)
                    		vm.editbaseType();
                    		
                    		vm.changeBaseLevel();
                    	} 
                    	
                    	vm.showHideNewArray();
     
              });
            
            
            
            $scope.$watchCollection(
                function getValue() {
                    return ([vm.level, vm.parentME,vm.filingGroup,vm.meInfo]);
                },
                function collectionChanged(newValue, oldValue, $scope) {
                    vm.openNextLevel_1();
                });
            
            
            
            $scope.$watchCollection(
                    function getValue() {
                        return ([vm.selectedBaseTypeCodeChanged]);
                    },
                    function collectionChanged(newValue, oldValue, $scope) {
                    	if(!vm.isLoadingData && !vm.isCreate && newValue == 2) {
                    		vm.baseTypeDataCopy = angular.copy(vm.baseTypeData);
                    		vm.baseTypeData = [];
                    		//setTimeout(() => {
                    			vm.baseTypeData = vm.baseTypeDataCopy;
                			//},200);	
                    		
                    	}
                        console.log(vm.selectedBaseTypeCode,"vm.selectedBaseTypeCode----changed",vm.isLoadingData);
                    });

            $scope.$watchCollection(
                function getValue() {
                    return ([vm.mapTo, vm.grossIncome]);
                },
                function collectionChanged(newValue, oldValue, $scope) {
                    vm.openNextLevel_2();
                    vm.showHideNewArray();
                });
				$scope.$watchCollection(
                function getValue() {
                    return ([vm.systemAccount,vm.localAccounts,vm.account_scheduleData]);
                },
                function collectionChanged(newValue, oldValue, $scope) {
                  if (vm.localAccounts && vm.localAccounts.length > 0 && vm.account_scheduleData && vm.account_scheduleData.length >0 && vm.systemAccount.length > 0) {
                           // vm.getLocalAccountDetails();
						 /*  $scope.$applyAsync(function() {
								
                                    vm.localAccounts = vm.localAccounts
									console.log(vm.localAccounts,"vm.localAccounts watch",vm.account_scheduleData);
                             });
						   vm.showLocalAccountDrop = true;*/
				  }
                });
				
				

            $scope.$watchCollection(
                function getValue() {
                    return ([vm.systemAccountDetails, vm.accountInforamtion]);
                },
                function collectionChanged(newValue, oldValue, $scope) {
                    if (vm.systemAccountDetails != null && vm.systemAccountDetails.length > 0) {
                        // vm.systemAccountDetails = uniqueData(vm.systemAccountDetails,'FROM_ACCT_KEY')
                        vm.systemAccountDetails.unshift({
                            'ACCT_KEY': 'All',
                            'DESCRIPTION': 'ALL'
                        });
                        vm.systemAccount = [];
                        if (!vm.isCreate) {
                            temp = vm.accountInforamtion && vm.accountInforamtion.map(function(account) {
                                vm.systemAccountDetails.map(function(obj) {
                                    //	console.log(obj.ACCT_KEY === account.P_SYS_ACCT_KEY,obj.ACCT_KEY,account.P_SYS_ACCT_KEY,'check');
                                    if (obj.ACCT_KEY === account.P_SYS_ACCT_KEY) {
                                        vm.systemAccount.push(obj);
                                    }
                                });

                            });
                            $scope.$applyAsync(function() {
                                vm.systemAccount = vm.checkDulicates(vm.systemAccount, 'ACCT_KEY');
								vm.getLocalAccountDetails();
                                vm.isLoadingData = false;
                            });

                        }
                    }
                }
            );


            $scope.$watchCollection(
                function getValue() {
                    return ([vm.grossIncome, vm.scheduleMOptions, vm.scheduleMInforamtion, vm.dataReset]);
                },
                function collectionChanged(newValue, oldValue, $scope) {
                    if (vm.grossIncome && vm.scheduleMOptions != null && vm.scheduleMOptions.length > 0) {
                        /*vm.scheduleMOptions.unshift({
                            'SCHDM_TEMPLATE_ID': 'All',
                            'SCHDM_DESC': 'ALL'
                        });*/
                        var scheduleMDetailsTemp = [];
                        vm.scheduleMDetails = [];
                        if (!vm.isCreate) {
                            temp = vm.scheduleMInforamtion && vm.scheduleMInforamtion.map(function(schObj) {
                                vm.scheduleMOptions.map(function(obj) {
                                    if (obj.SCHDM_TEMPLATE_ID + "" === schObj.M_TEMPLATE_KEY) {
                                        scheduleMDetailsTemp.push(obj);
                                    }
                                });

                            });
                            scheduleMDetailsTemp = vm.checkDulicates(scheduleMDetailsTemp, 'SCHDM_TEMPLATE_ID');
                            $scope.$applyAsync(function() {
                                vm.scheduleMDetails = scheduleMDetailsTemp;
                                vm.scheduleMDetailsCopy = scheduleMDetailsTemp;
                                vm.isLoadingData = false;
                            });
                        }

                    }
                }
            );

            vm.changeMapValueByINC = function() {
                if (vm.level.CODE_DESCRIPTION === 'Filing Group') {
                   /// if (vm.grossIncome.ACCT_SUB_CATG === 'INC' || vm.grossIncome.ACCT_SUB_CATG === 'GP') {
					if (vm.grossIncome.ACCT_SUB_CATG) {
						var exist = _.find(vm.mapDetails, ['CODE_DESCRIPTION', 'by Amount']);
                        vm.mapTo = {
                            'CODE_KEY': exist.CODE_KEY,
                            'CODE_DESCRIPTION': 'by Amount'
                        };
                        vm.isINC = true;
                    } else {
                        vm.isINC = false;
                        vm.mapTo = null;
                    }
                }
                if (vm.level.CODE_DESCRIPTION === 'Parent Me' || vm.level.CODE_DESCRIPTION === 'Me') {
                    vm.isINC = false;
                    vm.mapTo = null;
                    if (vm.isCreate) {
                    	var exist = _.find(vm.mapDetails, ['CODE_DESCRIPTION', 'by Amount']);
                        vm.mapTo = {
                            'CODE_KEY': exist.CODE_KEY,
                            'CODE_DESCRIPTION': 'by Amount'
                        };
                    }
                }
            };

            vm.count = 1;
            vm.addSystemAccounts = function() {
                vm.systemAccountData.push({
                    count: vm.count++
                });
            };
            vm.removeSystemAccounts = function(index) {
                vm.systemAccountData.splice(index, 1);
            };

            //Reset the fields to a default load 
            vm.resetAdd = function() {
                vm.level = {};
                //vm.level.CODE_KEY = "";
                //vm.level.CODE_DESCRIPTION = "";
                vm.level_1_show = false;
                vm.parentME = null;
                vm.filingGroup = null;
                vm.meInfo = null;
                vm.grossIncome = null;
                vm.transactionCategory = "";
                vm.mapTo = null;
                vm.isINC = false;
                vm.grossIncome = null;
                vm.transactionCategory = "";
                vm.mapTo = null;
                vm.level_2_show = false;
                vm.percentage = 100;
            };

            //Reset the fields to a default load 
            vm.resetEdit = function() {
                vm.isLoadingData = true;
                vm.systemAccount = [];
                vm.localAccounts = [];
                vm.scheduleMDetails = [];
                vm.dataReset = true;
                vm.resetEditInfo = true;
                vm.percentage = rowData.PERCENTAGE;
                vm.isSaveClicked = false;
                setTimeout(function() {
                    vm.openNextLevel_1();
                    vm.grossIncomeDropdownDetails();
                    vm.changeMethodToAllocate();
                   // vm.getCodeMastData();
                    vm.resetEditInfo = false;
                }, 100);
            };

            vm.openNextLevel_1 = function() {
                var codeKey = (vm.level != undefined) ? vm.level.CODE_KEY : vm.rowData ? vm.rowData.LEVEL_KEY : null;
                switch (codeKey) {
                    case 1:
                        vm.level_1_show = (vm.filingGroup && vm.filingGroup.PARENT_ME_KEY !== undefined) ? true : false;;
                    //    vm.parentME = null;
                        vm.grossIncome = null;
                        vm.transactionCategory = "";
                        vm.mapTo = null;
                        vm.isINC = false;
                        break;
                    case 2:
                        vm.level_1_show = (vm.parentME && vm.parentME.PARENT_ME_KEY !== undefined) ? true : false;
                        vm.grossIncome = null;
                        vm.transactionCategory = "";
                        vm.mapTo = null;
						vm.isINC = false;
                        break;
                   
                       
                    case 8689: 
                    	 vm.level_1_show = (vm.meInfo && vm.meInfo.PARENT_ME_KEY !== undefined) ? true : false;
                    	 vm.grossIncome = null;
                         vm.transactionCategory = "";
                         vm.mapTo = null;
 						vm.isINC = false;
                         break;
                }
                vm.openNextLevel_2();
            };

            vm.openNextLevel_2 = function() {
                var checkInGrossExpenseCollection = ['INTEREST EXPENSE', 'RESEARCH', 'EXPENSES A&A'];

                if (vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE && vm.grossIncome.DISP_ACCT_CODE.length > 0 &&
                    vm.mapTo && vm.mapTo.CODE_DESCRIPTION && vm.mapTo.CODE_DESCRIPTION.length > 0 && vm.mapTo.CODE_DESCRIPTION !== 'by Amount') {
                    vm.level_2_show = true;
                } else {
                    vm.level_2_show = false;
                }

                /*if (vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE && vm.grossIncome.DISP_ACCT_CODE.length > 0 && (vm.grossIncome.ACCT_SUB_CATG === 'INC' || vm.grossIncome.ACCT_SUB_CATG === 'GP')) {
                    vm.level_2_show = false;
                }*/
				

                if (vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE != undefined) {
                    vm.checkInGroup = checkInGrossExpenseCollection.indexOf(vm.grossIncome.DISP_ACCT_CODE) >= 0;
                } else if (vm.rowData) {
                    vm.checkInGroup = checkInGrossExpenseCollection.indexOf(vm.rowData.DISP_ACCT_CODE) >= 0;

                }
				
				if(vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE == 'Expenses A&A'){
							var exists = _.find(vm.methodKeys, ['IS_DEFAULT','Y']);
							if(exists && vm.methodKey.CODE_KEY === undefined){
								vm.methodKey = exists;
							}
				}
				/*else{
					vm.methodKey = {}
				}*/



            };
            
            vm.removeTrans = function(){
            	vm.save(true);
            }
            
            vm.save = function(type) {
            	vm.isSaveClicked = true;
            	/*if (vm.crudloading){
					AlertService.add("info", "Please wait while we save this request" , 1000);
					return;
				}
				vm.crudloading = true;
				if(vm.level && vm.level.CODE_DESCRIPTION === undefined ){ 
					AlertService.add("error", "Please select atleast one Level");
					vm.crudLoading = false;
					return;
				}*/
                var transactionDetails = [],
                    returnObjs = [];
                var returnObj = {},
                    //detailObj = {},
                    otherInfo = {};
                var isError = false,
                    errorInfo = "";
                // console.log('rowData',rowData,vm)
                if(vm.level && vm.level.CODE_DESCRIPTION === undefined){
                	isError = true;
                    errorInfo = "Please select Level ";
                	vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }
               /* if(vm.parentME && vm.parentME.PARENT_ME_NAME === undefined ){
                	isError = true;
                    errorInfo = "Please select one Parent ME ";
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }*/
                if(vm.parentME && vm.parentME.PARENT_ME_KEY === undefined &&  vm.level &&  vm.level.CODE_DESCRIPTION === "Parent ME"){
                	isError = true;
                    errorInfo = "Please select required fields ";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }

                if (!(vm.grossIncome && vm.grossIncome.ACCT_KEY && vm.grossIncome.DISP_ACCT_CODE && vm.grossIncome.DISP_ACCT_CODE.length > 0)) {
                    isError = true;
                    errorInfo = "Please select required fields ";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }

                if (!(vm.transactionCategory && vm.transactionCategory.length > 0)) {
                    isError = true;
                    errorInfo = "Please provide Transaction Category ";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }

                if (!(vm.mapTo && vm.mapTo.CODE_DESCRIPTION && vm.mapTo.CODE_DESCRIPTION.length > 0)) {
                    isError = true;
                    errorInfo = "Please select Map ";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }
                if (!(vm.mapTo && vm.mapTo.CODE_DESCRIPTION && vm.mapTo.CODE_DESCRIPTION.length > 0)) {
                    isError = true;
                    errorInfo = "Please select Map ";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }
                otherInfo['sso_id'] = vm.userSettings.user.sso_id;
                otherInfo['screen_key'] = GlobalService.globalParams.screen_key;

                if(type) {
                	returnObj["type"] = 'D';
                } else {
                	returnObj["type"] = vm.isCreate ? 'I' : 'U';
                }
               // returnObj["type"] = vm.isCreate ? 'I' : 'U';
                returnObj['trans_catg_key'] = vm.isCreate ? null : vm.rowData.TRANS_CATG_KEY;
                returnObj['trans_catg_name'] = vm.transactionCategory;
                returnObj['gross_acct_key'] = vm.grossIncome.ACCT_KEY;
                returnObj['level'] = vm.level.CODE_KEY;
                returnObj['map_key'] = vm.mapTo.CODE_KEY;
               
                returnObj['GIE_KEY'] = vm.grossIncome.GIE_KEY;
                
                returnObj['is_core'] = 'N';
                returnObj['is_ftc'] = 'Y';
                
                if(!vm.isCreate && vm.isChanged) {
                	returnObj['is_changed'] = 'Y';
                } else {
                	returnObj['is_changed'] = 'N';
                }
                returnObj['map_key'] = vm.mapTo.CODE_KEY;
              
                if (vm.level.CODE_DESCRIPTION == 'Parent ME') {
                    returnObj['me_key'] = vm.parentME.PARENT_ME_KEY;
                }
                if (vm.level.CODE_DESCRIPTION == 'Filing Group') {
                    returnObj['me_key'] = vm.filingGroup.PARENT_ME_KEY;
                }
                if (vm.level.CODE_DESCRIPTION == 'ME') {
                    returnObj['me_key'] = vm.meInfo.PARENT_ME_KEY;
                }
                
                returnObj['method_key'] = (vm.methodKey && vm.methodKey.CODE_KEY != 0) ? vm.methodKey.CODE_KEY : null;
               /* if(!(vm.parentME && vm.parentME.PARENT_ME_NAME.length > 0)){
                	isError = true;
                    errorInfo = "Please select one Parent ME ";
                    AlertService.add("error", errorInfo, 4000);
                    return 0;
                }*/

                // By Amount
                if (vm.mapTo.CODE_DESCRIPTION === 'by Amount') {
                    // returnObj = {}
                    returnObj['is_all_sys_acct'] = null;
                    returnObj["sys_acct_key"] = null;
                    returnObj['is_all_local_acct'] = null;
                    returnObj['local_acct_key'] = null;
                    returnObj['is_all_m_temp'] = null;
                    returnObj['m_template_key'] = null;
                    returnObjs.push(returnObj);
                    // returnMultipleObj.push(detailObj);
                }
                // Schedule M
                if (vm.mapTo.CODE_DESCRIPTION === 'by Schedule M') {
                    var scheduleMDetailInfo = [];
                    //detailObj = {}
                    if (vm.scheduleMDetails !== 'All') {
                        for (var ii in vm.scheduleMDetails) {
                            if (vm.scheduleMDetails[ii])
                                scheduleMDetailInfo.push(vm.scheduleMDetails[ii].SCHDM_TEMPLATE_ID);
                        }
                    }
                    if (vm.scheduleMDetails.length == 0) {
                        isError = true;
                        errorInfo = "Please select Schedule M ";
                        vm.isSaveClicked = false;
                        AlertService.add("error", errorInfo, 4000);
                    }
                    returnObj['is_all_sys_acct'] = null;
                    returnObj["sys_acct_key"] = null;
                    returnObj['is_all_local_acct'] = null;
                    returnObj['local_acct_key'] = null;
                    returnObj['is_all_m_temp'] = (vm.scheduleMDetails.length > 0 && vm.scheduleMDetails[0].SCHDM_TEMPLATE_ID !== 'All') ? 'N' : 'Y';

                    if (vm.scheduleMDetails.length > 0 && vm.scheduleMDetails[0].SCHDM_TEMPLATE_ID !== 'All') {

                        returnObj['m_template_key'] = scheduleMDetailInfo.join();
                    } else {
                        returnObj['m_template_key'] = null;
                    }                  
                  

                }

                if (vm.mapTo.CODE_DESCRIPTION === 'by Account') {
                    var isSANull = 0,
                        isLAnull = 0;
                    // detailObj = {}
                    var systemAct = (vm.systemAccount.length > 0) ? vm.systemAccount : null;
                    var locaAct = (vm.localAccounts.length > 0) ? vm.localAccounts : null;


                    if (systemAct == null) {
                        isSANull++;
                    }
                    if (locaAct == null || locaAct.length == 0) {
                        isLAnull++;
                    }

                    var localAccts = [],
                        systemActs = [];
                    if (locaAct != null && locaAct[0] !== 'All') {
                        for (var ij in locaAct) {
                            if (locaAct[ij])
                                localAccts.push(locaAct[ij].ACCT_KEY);
                        }
                    }
                    if (systemAct != null && systemAct[0] !== 'All') {
                        for (var ik in systemAct) {
                            if (systemAct[ik])
                                systemActs.push(systemAct[ik].ACCT_KEY);
                        }
                    }
                    returnObj['percentage'] = vm.percentage && vm.percentage > 100 ? 100 : vm.percentage;
               
                    if( returnObj['percentage'] === undefined || parseInt(returnObj['percentage']) === NaN){
						isError = true;
						vm.isSaveClicked = false;
                        errorInfo = "Please provide percentage between 1 to 100 ";
                        AlertService.add("error", errorInfo, 4000);
					}
					if((returnObj['percentage'] !== undefined && parseInt(returnObj['percentage']) < 1) ||  returnObj['percentage'] === ""){
						isError = true;
						vm.isSaveClicked = false;
                        errorInfo = "Please provide percentage between 1 to 100 ";
                        AlertService.add("error", errorInfo, 4000);
					}
                    returnObj['is_all_sys_acct'] = (systemAct != null && systemAct[0].ACCT_KEY !== 'All') ? 'N' : 'Y';
                    if (returnObj['is_all_sys_acct'] === 'Y') {
                        returnObj["sys_acct_key"] = null;
                        returnObj['is_all_local_acct'] = null;
                        returnObj['local_acct_key'] = null;
                        returnObj['is_all_m_temp'] = null;
                        returnObj['m_template_key'] = null;
                    } else {
                        returnObj['is_all_local_acct'] = (locaAct != null && locaAct[0].ACCT_KEY !== 'All') ? 'N' : 'Y';
                        returnObj['is_all_m_temp'] = null;
                        returnObj['m_template_key'] = null;

                        if ((locaAct != null && locaAct[0].ACCT_KEY === 'All')) {
                            returnObj['local_acct_key'] = null;
                        }

                        if (systemAct != null && systemAct[0].ACCT_KEY === 'All') {
                            returnObj['sys_acct_key'] = null;
                        }
                        if ((locaAct != null && locaAct[0].ACCT_KEY !== 'All') && (systemAct != null && systemAct[0].ACCT_KEY !== 'All')) {
                            returnObj['local_acct_key'] = localAccts.join();
                         //   returnObj['sys_acct_key'] =  systemActs.join();
                            returnObj['sys_acct_key'] =  null;
                        } else if ((locaAct != null && locaAct[0].ACCT_KEY === 'All') 
							&& (systemAct != null && systemAct[0].ACCT_KEY !== 'All')) {


                            returnObj['sys_acct_key'] = systemActs.join();

                        }else if(locaAct === null && systemAct != null && systemAct[0].ACCT_KEY !== 'All' 
						&& vm.grossIncome.DISP_ACCT_CODE === 'Asset Exp' ){
							returnObj['sys_acct_key'] = systemActs.join();
						}

                    }
                    if (isSANull > 0) {
                        isError = true;
                        vm.isSaveClicked = false;
                        errorInfo = "Please select System Account ";
                        AlertService.add("error", errorInfo, 4000);
                    }



                    if (vm.systemAccount && vm.systemAccount.length == 1 && vm.systemAccount[0].ACCT_KEY != 'All' &&
                        vm.localAccounts && vm.localAccounts.length == 0 && vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE !== 'Asset Exp') {
                        isError = true;
                        vm.isSaveClicked = false;
                        errorInfo = "Please select Local Account ";
                        AlertService.add("error", errorInfo, 4000);
                    } else if (vm.localAccounts && vm.localAccounts.length == 0 && vm.systemAccount && vm.systemAccount[0].ACCT_KEY != 'All' 
					&& vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE !== 'Asset Exp') {
                        isError = true;
                        vm.isSaveClicked = false;
                        errorInfo = "Please select Local Account ";
                        AlertService.add("error", errorInfo, 4000);
                    }

                    if (vm.systemAccount && vm.systemAccount.length > 1 && vm.systemAccount[0].ACCT_KEY == 'All' ) {
                        isError = true;
                        vm.isSaveClicked = false;
                        errorInfo = "Please select either ALL or specific System Accounts ";
                        AlertService.add("error", errorInfo, 4000);
                    } else if (vm.localAccounts && vm.localAccounts.length > 1 && vm.localAccounts[0].ACCT_KEY == 'All') {
                        isError = true;
                        vm.isSaveClicked = false;
                        errorInfo = "Please select either ALL or specific Local Accounts ";
                        AlertService.add("error", errorInfo, 4000);
                    }

                }
       
                /*if (vm.grossIncome.DISP_ACCT_CODE == 'Interest Expense' && vm.mapTo.CODE_DESCRIPTION !== 'by Amount' && vm.methodKey && vm.methodKey.CODE_KEY === undefined) {
                    isError = true;
                    errorInfo = "Please select Method Desc";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                }
            
                if (vm.grossIncome.DISP_ACCT_CODE == 'Research' && vm.mapTo.CODE_DESCRIPTION !== 'by Amount' && vm.methodKey && vm.methodKey.CODE_KEY === undefined) {
                    isError = true;
                    errorInfo = "Please select Method Desc";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
                }*/
            if ((vm.grossIncome.DISP_ACCT_CODE == 'Research'  || vm.grossIncome.DISP_ACCT_CODE == 'Interest Expense') 
            		&& vm.mapTo.CODE_DESCRIPTION !== 'by Amount' && vm.methodKey && vm.methodKey.CODE_KEY === undefined ) {
               // if ( vm.mapTo.CODE_DESCRIPTION !== 'by Amount' && vm.methodKey && vm.methodKey.CODE_KEY === undefined) {
                    isError = true;
                    errorInfo = "Please select Method Desc";
                    vm.isSaveClicked = false;
                    AlertService.add("error", errorInfo, 4000);
               // }
            }
					if(vm.grossIncome && vm.grossIncome.DISP_ACCT_CODE === 'Asset Exp'){
						returnObj["is_all_local_acct"] = null;
					}
					
					
					/* "attrib_value": "'25_444,26_440,27_435,28_438,30_7976,35_191,37_452,42_21,46_469,48_400,60_418,61_57945'",
                     
	                    "BASE_FDII_DATA": "405",
	                    "BASE_TYPE_DATA": "",
	                    "BASE_BASKET_DATA": "96,91,93"*/
					
					returnObj['attrib_value'] = [];
					if(!_.isEmpty(vm.methodKey) && vm.methodKey != undefined && vm.methodKey.CODE_KEY && vm.mapTo.CODE_DESCRIPTION !== 'by Amount')
						returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'METHOD']).ATTRIB_KEY +'_' +vm.methodKey.CODE_KEY);
					
					if(vm.newArray.indexOf("selectedBaseFdiiCodes_single") > -1 && vm.methodKey && vm.methodKey.CODE_DESCRIPTION == 'Allocate' && vm.methodKey.CODE_DESCRIPTION !== 'Research Expense' && vm.mapTo.CODE_DESCRIPTION !== 'by Amount' && vm.methodKey.CODE_DESCRIPTION !== "Interest Expense"){
						
						if(vm.selectedBaseFdiiCodes != '' && vm.selectedBaseFdiiCodes != undefined ){ 
							returnObj['BASE_FDII_DATA'] = vm.selectedBaseFdiiCodes;
							var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','SINGLE']);
		                	if(exists != undefined)
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
						} else {
								 isError = true;
			                     vm.isSaveClicked = false;
			                     errorInfo = "Please select Base FDII ";
			                     AlertService.add("error", errorInfo, 4000);
						}
						
						if(vm.selectedBaseBasketCode != '' && vm.selectedBaseBasketCode != undefined ){
							returnObj['BASE_BASKET_DATA'] =	vm.selectedBaseBasketCode;
							var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','SINGLE']);
		                	if(exists != undefined)
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
						} else {
							 isError = true;
		                     vm.isSaveClicked = false;
		                     errorInfo = "Please select Base BASKET ";
		                     AlertService.add("error", errorInfo, 4000);
						}
						
						
					}

					if(vm.mapTo && vm.mapTo.CODE_DESCRIPTION !== 'by Amount' && 
							vm.methodKey && vm.methodKey.CODE_DESCRIPTION !== 'Research Expense'
								&& vm.methodKey.CODE_DESCRIPTION !== "Interest Expense" 
									&& document.getElementsByClassName('single') 
									&& document.getElementsByClassName('single').length 
									&& document.getElementsByClassName('single')[0].classList.contains('ng-hide')) {
					
					if(vm.newArray.indexOf("selectedBaseFdiiCodes") > -1 && vm.selectedBaseFdiiCodes){
						if(vm.selectedBaseFdiiCodes.length === 0) {
							 isError = true;
		                     vm.isSaveClicked = false;
		                     errorInfo = "Please select Base FDII ";
		                     AlertService.add("error", errorInfo, 4000);
						}
						var baseFdiiSelected ='';
						for ( var data in vm.selectedBaseFdiiCodes) {
							if (vm.selectedBaseFdiiCodes[data] != undefined && vm.selectedBaseFdiiCodes[data].CODE_KEY != undefined) {
								baseFdiiSelected = baseFdiiSelected.concat(vm.selectedBaseFdiiCodes[data].CODE_KEY);
								baseFdiiSelected = baseFdiiSelected.concat(",");
							}
						}
						returnObj['BASE_FDII_DATA'] = baseFdiiSelected.substring(0,(baseFdiiSelected.length -1));
						

						if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length == 1){
							var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','SINGLE']);
		                	if(exists != undefined)
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
		                		//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
		                 // }else if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length > 1){
		                	// adding this for All in future which we have to uncomment
						}else if(vm.selectedBaseFdiiCodes != undefined && vm.baseFDIIData && vm.selectedBaseFdiiCodes.length === vm.baseFDIIData.length){
							var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','ALL']);
		                	if(exists != undefined) {
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
		                		returnObj['BASE_FDII_DATA'] =  null;
		                	}
		                		// returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}else  if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length > 1){
							var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','MULTI']);
		                	if(exists != undefined )
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
		                		//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}
					
					} 
					
					if( vm.newArray.indexOf("selectedBaseBasketCode") > -1 &&  vm.selectedBaseBasketCode){
						if(vm.selectedBaseBasketCode.length == 0) {
							 isError = true;
		                     vm.isSaveClicked = false;
		                     errorInfo = "Please select Base Basket ";
		                     AlertService.add("error", errorInfo, 4000);
						}
						var baseBasketSelected ='';
						for ( var data in vm.selectedBaseBasketCode) {
							if (vm.selectedBaseBasketCode[data] != undefined && vm.selectedBaseBasketCode[data].CODE_KEY != undefined) {
								baseBasketSelected = baseBasketSelected.concat(vm.selectedBaseBasketCode[data].CODE_KEY);
								baseBasketSelected = baseBasketSelected.concat(",");
							}
						}
						returnObj['BASE_BASKET_DATA'] = baseBasketSelected.substring(0,(baseBasketSelected.length -1));

						if(vm.selectedBaseBasketCode != undefined && vm.selectedBaseBasketCode.length == 1){
							var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','SINGLE']);
		                	if(exists != undefined)
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
		                		//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
		                	// adding this for All in future which we have to uncomment
						}else if(vm.selectedBaseBasketCode != undefined && vm.baseBasketData && vm.selectedBaseBasketCode.length === vm.baseBasketData.length){
							var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','ALL']);
		                	if(exists != undefined)  {
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
		                		returnObj['BASE_BASKET_DATA'] = null;
							}//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}else if(vm.selectedBaseBasketCode != undefined && vm.selectedBaseBasketCode.length > 1){
							var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','MULTI']);
		                	if(exists != undefined)
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
		                		//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}
					
					}  
					
					
					if(vm.selectedBaseLevelCode) {
						if( vm.newArray.indexOf("selectedBaseLevelCode") > -1)
						returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_LEVEL']).ATTRIB_KEY +'_'+ vm.selectedBaseLevelCode);
					} else {
						 if(vm.newArray && vm.newArray.indexOf("selectedBaseLevelCode") > -1) {
							 isError = true;
		                     vm.isSaveClicked = false;
		                     errorInfo = "Please select Base Level ";
		                     AlertService.add("error", errorInfo, 4000);
						 }
					}
						
						
					
					if(vm.selectedBaseEntityLevelCode) {
						if( vm.newArray.indexOf("selectedBaseEntityLevelCode") > -1)
						returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_ENTITY_LEVEL']).ATTRIB_KEY +'_'+ vm.selectedBaseEntityLevelCode);
					} else {
						if(vm.newArray && vm.newArray.indexOf("selectedBaseEntityLevelCode") > -1) {
							 isError = true;
		                     vm.isSaveClicked = false;
		                     errorInfo = "Please select Base Entity Level ";
		                     AlertService.add("error", errorInfo, 4000);
						}
					}
					
					if(vm.newArray.indexOf("selectedBaseTypeCode") > -1 && vm.selectedBaseTypeCode){
						if (vm.selectedBaseTypeCode == undefined || (vm.selectedBaseTypeCode && vm.selectedBaseTypeCode.length === 0)) {
								 isError = true;
			                     vm.isSaveClicked = false;
			                     errorInfo = "Please select Base Type ";
			                     AlertService.add("error", errorInfo, 4000);
						}
						var baseTypeSelected ='';
						for ( var data in vm.selectedBaseTypeCode) {
							if (vm.selectedBaseTypeCode[data] != undefined && vm.selectedBaseTypeCode[data].CODE_KEY != undefined) {
								baseTypeSelected = baseTypeSelected.concat(vm.selectedBaseTypeCode[data].CODE_KEY);
								baseTypeSelected = baseTypeSelected.concat(",");
							}
						}
						returnObj['BASE_TYPE_DATA'] = baseTypeSelected.substring(0,(baseTypeSelected.length -1));
						
						
						if(vm.selectedBaseTypeCode != undefined && vm.selectedBaseTypeCode.length == 1){
							var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','SINGLE']);

		                	if(exists != undefined)
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
						//}else if(vm.selectedBaseTypeCode != undefined &&  vm.selectedBaseTypeCode.length > 1){
							// adding this for All in future which we have to uncomment
						}else if(vm.selectedBaseTypeCode != undefined && vm.baseTypeData && vm.selectedBaseTypeCode.length === vm.baseTypeData.length){
							var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','ALL']);
		                	if(exists != undefined) {
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
		                		returnObj['BASE_TYPE_DATA'] = null;
		                	}
						}else  if(vm.selectedBaseTypeCode != undefined &&  vm.selectedBaseTypeCode.length > 1){
							var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','MULTI']);
		                	if(exists != undefined)
		                		returnObj['attrib_value'].push(_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_'+ exists.CODE_KEY);
						}
						
					}  
				
					// adding this for All in future which we have to uncomment
					/*if(vm.methodKey && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_FDII_DATA'] && vm.baseFDIIData 
					&& returnObj['BASE_FDII_DATA'].split(",").length === vm.baseFDIIData.length){
						 returnObj['BASE_FDII_DATA'] = "";
					}
					
					if(vm.methodKey && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_BASKET_DATA'] && vm.baseBasketData 
					&& returnObj['BASE_BASKET_DATA'].split(",").length === vm.baseBasketData.length){
						 returnObj['BASE_BASKET_DATA'] = "";
					}
					
					if(vm.methodKey && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_TYPE_DATA'] && vm.baseTypeData 
					&& returnObj['BASE_TYPE_DATA'].split(",").length === vm.baseTypeData.length){
						 returnObj['BASE_TYPE_DATA'] = "";
					}*/
					} 
					
					
					
					
				returnObj['attrib_value'] = returnObj['attrib_value'].toString();
				console.log(returnObj,"returnObj");
                transactionDetails.push(returnObj);
                var message = "Transaction Category has been successfully saved/updated";
                if (isError == false) {
                    sendDetails(returnObj, transactionDetails, message, otherInfo);
                }




            };


            function sendDetails(returnObj, transactionDetails, message, otherInfo) {
                ExpenseTransFactory.save(returnObj,
                    transactionDetails, otherInfo).then(
                    function(result) {
                    	vm.isSaveClicked = false;
                        if (result.errorMessage &&
                            result.errorMessage !== 'null') {
                            var startIndex = result.errorMessage.indexOf("(") + 1;
                            var lastIndex = result.errorMessage.indexOf(")");
                            var errorKey = result.errorMessage.substring(startIndex, lastIndex);
                            if (errorKey === "GTWDBA01.IND1_FTC_TRANS_CATG_DETAILS") {
                                AlertService.add("error", "Trans Category already exists", 4000);
                            } else {
                                AlertService.add("error",
                                    result.errorMessage, 4000);
                            }
                        } else {
                            AlertService.add("success", message, 4000);
                            // $uibModalInstance.dismiss('cancel');
                            var args = {};
                            if (returnObj.type === "D") 
                            args = {
									object_id: vm.rowData.OBJECT_ID,
	                                gridFilter: {
	                                	object_id: vm.rowData.OBJECT_ID
	                                }
	                            };
                            
                            $uibModalInstance.dismiss('cancel');
                            $rootScope.$emit('gridUpdate', args);
                        }
                    });

            }

            function uniqueData(arrayData, filterBy) {
                var elementId = [];

                var newArr = arrayData.filter(function(el) {
                    if (elementId.indexOf(el[filterBy]) === -1) {
                        // If not present in array, then add it
                        elementId.push(el[filterBy]);
                        return true;
                    } else {
                        // Already present in array, don't add it
                        return false;
                    }
                });


                return newArr;
            }



            vm.openModal = function(modalObj, _data) {
                $scope.rowData = _data.rowData;
                $scope.gridData = _data;
                $scope.colData = _data.colData;
                $uibModalStack.close(1);
                // console.log('uibModalStack', $uibModalStack.close(0));
                $scope.schedule = $rootScope.schedule;
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: modalObj.template,
                    controller: modalObj.controler,
                    size: modalObj.size,
                    resolve: {
                        rowData: function() {
                            return vm.rowData;
                        },
                        colData: function() {
                            return vm.colData;
                        }
                    }
                });

                modalInstance.result
                    .then(
                        function() {
                            alert('modal Instance');
                            // console.log('// console from all adjustments has been opened');

                        },
                        function() {
                            $log.info('Modal dismissed at: ' +
                                new Date());
                            $log.info('$scope.rowData', vm.rowData);
                        });
            };
            
           
            
            
            $scope.$watchCollection(
                    function getValue() {
                            return( [ vm.grossIncome ] );
                    },
                    function collectionChanged( newValue, oldValue ,$scope) {
                    	if (vm.level /*&& vm.level.CODE_DESCRIPTION === 'Parent ME'*/ && newValue && newValue[0] && newValue[0].DISP_ACCT_CODE === 'Asset Exp') {                    		
                    		//var exist = _.find(vm.grossIncomeDetails, ['DISP_ACCT_CODE', 'Asset Exp']);
                    		vm.mapDetails = vm.mapCopyDetails.filter(function(details) {
                    			  return details.CODE_DESCRIPTION  !==  'by Schedule M';
                    		});
                    		                    		
                    	}else{
                    		vm.mapDetails = angular.copy(vm.mapCopyDetails);
                    	}
                    });

            if (!vm.isCreate) {
                setTimeout(function() {
                    vm.openNextLevel_1();
                    vm.grossIncomeDropdownDetails();
                    //vm.changeMapValueByINC(); 
                }, 2000);

            } else {
                vm.grossIncomeDropdownDetails();
            }
        }
    
        return controllers;

    });