define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.uploadTrainingVideosController',[])
        .controller('uploadTrainingVideosController',['$scope','$rootScope','AlertService','$sce','$http','USER_SETTINGS','GENERAL_CONFIG','FileAttachementFactory','SERVER_CONFIG', uploadTrainingVideosController])
        .directive('fileModel', ['$parse', function ($parse) {
            return {
               restrict: 'A',
               link: function(scope, element, attrs) {
                  var model = $parse(attrs.fileModel);
                  var modelSetter = model.assign;
                  
                  element.bind('change', function() {
                     scope.$apply(function() {
                        modelSetter(scope, element[0].files[0]);
                     });
                  });
               }
            };
         }]);

        function uploadTrainingVideosController($scope,$rootScope,AlertService,$sce,$http,USER_SETTINGS,GENERAL_CONFIG,FileAttachementFactory,SERVER_CONFIG) {
            $scope.pageTitle = "Upload Training Videos";
            $scope.isLoading = false;
            $scope.upload_url = GENERAL_CONFIG.admin_api_url + '/uploadVideoFiles';
            $scope.uploadSettingsUrl = GENERAL_CONFIG.base_url + '/v1/dataservices/upload';
            $scope.isTrainingVideoListLoading = true;
            $scope.indexSelectedForDelete = -1;
            $scope.loggedInUser = USER_SETTINGS.user.sso_id;
            $scope.uploadLoader = false;
            $scope.request = {
                service_key : '',
                req_params : '',
                data_format : ''
            };
            
            this.$onInit = function() {
                console.log("Hello From Upload Training videos controller");    
                $scope.listTrainingVideos();
            }

            $scope.uploadFile = function() {
                var file = $scope.myFile;
                $scope.uploadFileToUrl(file, $scope.upload_url);
            }

            $scope.uploadSettingFile = function(file) {
                var type = file.name.split('.')[1];
                if(type === 'xml' || type === 'pdf' || type === 'zip'){
                    $scope.uploadSettingsToUrl(file, $scope.uploadSettingsUrl, $scope.request, $scope.loggedInUser);
                }else{
                    AlertService.add("error", "File upload now allowed. Only allowed types are ['pdf', 'xml', 'zip']",4000);
                    document.querySelector('.upload_settings_file').value = "";
                    return;
                }
            }

            $scope.uploadFileToUrl = function(file, uploadUrl) {
                var fd = new FormData();
                fd.append('file', file);
                if(file.type.indexOf('video/') !== 0 ){
                    AlertService.add("error", "File upload failed. Not a video file",4000);
                    return ;
                }
                $scope.isLoading = true ;
                $http({
                    url: uploadUrl,
                    method: 'POST',
                    data: fd,
                    headers: { 'Content-Type': undefined},
                    transformRequest: angular.identity
                }).success(function(response) {
                    $scope.isLoading = false;
                    document.querySelector('.upload_file').value = "";

                    if(response.callSuccess != "1"){
                        AlertService.add("error", response.errorMessage, 4000);
                        return;
                    }

                    AlertService.add("success", "File upload successfull.", 4000);
                    $scope.listTrainingVideos();
                })
                .error(function(error) {
                    $scope.isLoading = false;
                    AlertService.add("error", "File upload failed.",4000);
                });
                
            }

            $scope.uploadSettingsToUrl = function(file, uploadUrl, params, sso) {
                var fd = new FormData();
                fd.append('file', file);
                fd.append('service_key', params.service_key);
                fd.append('request_params', params.req_params);
                fd.append('data_format', params.data_format);
                fd.append('sso_id', sso);
                $scope.uploadLoader = true;

                $http({
                    url: uploadUrl,
                    method: 'POST',
                    data: fd,
                    headers: { 'Content-Type': undefined},
                    transformRequest: angular.identity
                }).success(function(response) {
                    $scope.uploadLoader = false;
                    document.querySelector('.upload_settings_file').value = "";
                    $scope.request.service_key = '';
                    $scope.request.req_params = '';
                    $scope.request.data_format = '';

                    if(response.callSuccess != "1"){
                        AlertService.add("error", response.errorMessage, 4000);
                        return;
                    }
                    AlertService.add("success", "File upload successfull.", 4000);
                })
                .error(function(error) {
                    $scope.uploadLoader = false;
                    AlertService.add("error", "File upload failed.",4000);
                });
            }

            $scope.listTrainingVideos = function() {
                $scope.isTrainingVideoListLoading = true;
                $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/getHelpVideos'
                }).then(function (response) {
                    if(response.data.callSuccess != "1"){
                        AlertService.add("error", response.data.errorMessage, 4000);
                        return;
                    }
                    $scope.isTrainingVideoListLoading = false;
                    $scope.videoList= response.data.data;
                    
                });
            }

            $scope.deleteVideo = (id,index) => {
                $scope.indexSelectedForDelete = index;
                $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/deleteVideo?video_id='+id,
                }).then(function (response) {
                    console.log(response);
                    $scope.indexSelectedForDelete = -1;
                    if(response.data.callSuccess != "1"){
                        AlertService.add("error", response.data.errorMessage, 4000);
                        return;
                    }
                    $scope.videoList.splice(index,1);
                }).catch(function (error){
                    $scope.indexSelectedForDelete = -1;
                });
            }
        }

        return controllers;

});
