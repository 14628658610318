/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'moment',
    'angular'

], function (moment) {
    'use strict';


    var services =  angular.module('app.methodSettlementService',[])

        .factory("MethodSettlementFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','AlertService','USER_SETTINGS','GENERAL_CONFIG', 'JsonObjectFactory', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,AlertService,USER_SETTINGS,GENERAL_CONFIG, JsonObjectFactory) {
            var factory = {};


            factory.url = {
                put: GENERAL_CONFIG.base_url + "/saveMethodOfSettlement",
                get: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=33349",
                getBanker: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=33347",
                setBanker: GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=33348",
                validate: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=33350",
                signOff: GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=33351",
                targetDate: GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=33352",
                saveComment: GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=33353",
                entityManger:  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hrcv1p",
                setSDCode:  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=48f3i8",
            }


            factory.setSDCode = function(_callName , _data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.leid = _data.rowData.LEID;
                params.division = _data.rowData.DIVISION;
                params.tax_year =_data.rowData.TAX_YEAR;
                params.sd_code =_data.rowData.SD_Code_0;
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";


                JsonObjectFactory.saveJSON(factory.url.setSDCode, params).then(function (response) {
                    if(response.callSuccess === "1" ){
                        AlertService.add("success", "Success", 3000);
                    }else{
                        AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
                        _.forEach(_data.rowData, function(value, key) {
                            _data.rowData[key] = ( _data.oldRowData[key] ) ? _data.oldRowData[key] : "" ;
                        });
                        $rootScope.$broadcast('gridRefresh', false);
                    }

                });


            }





            factory.setEntityAdmin = function(_callName , _data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.tax_year = (_data.rowData.TAX_YEAR ) ? _data.rowData.TAX_YEAR  : "";
                params.leid = (_data.rowData.LEID ) ? _data.rowData.LEID  : "";
                params.cdr_no = (_data.rowData.CDR_NO ) ? _data.rowData.CDR_NO  : "";
                params.bsla_code = (_data.rowData.BSLA_CODE ) ? _data.rowData.BSLA_CODE  : "";
                params.le_type = (_data.rowData.LE_TYPE ) ? _data.rowData.LE_TYPE  : ""
                params.in_out_code = (_data.rowData.IN_OUT_CODE ) ? _data.rowData.IN_OUT_CODE  : "";
                params.locked_entity = (_data.rowData.LOCKED_ENTITY ) ? _data.rowData.LOCKED_ENTITY  : "";
                params.category = (_data.rowData.CATEGORY ) ? _data.rowData.CATEGORY : "";
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";

                return JsonObjectFactory.saveJSON(factory.url.entityManger, params).then(function (response) {
                    if(response.callSuccess === "1" ){
                        AlertService.add("success", "Success", 3000);
                    }else{
                        AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
                        _.forEach(_data.rowData, function(value, key) {
                            _data.rowData[key] = ( _data.oldRowData[key] ) ? _data.oldRowData[key] : "" ;
                        });
                        $rootScope.$broadcast('gridRefresh', false);
                    }

                });

            }



            factory.checkLocked  = function(_data){


                if( _data.rowData && _data.rowData.Is_Locked_0 && _data.rowData.Is_Locked_0 === 1 ){

                   console.log("YEP WE ARE IN HERE   " , _data.rowData.Is_Locked_0)


                    $timeout(function() {
                        AlertService.add("warning", "This LEID / Division combination is locked", 4000);
                        _.forEach(_data.rowData, function(value, key) {
                            _data.rowData[key] = ( _data.oldRowData[key] ) ? _data.oldRowData[key] : "" ;
                        });
                        $rootScope.$broadcast('gridRefresh', false);
                    },30);
                    return true;
                }else{
                    return false;
                }
            }



            factory.saveTargetDate = function(_callName , _data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.object_id = _data.rowData.object_id;
                params.target_date = _data.rowData[_data.colData.map];
                params[_data.colData.map] =  _data.rowData[_data.colData.map];
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";

                if( this.checkLocked(_data)){
                    return false;
                }

                JsonObjectFactory.saveJSON(factory.url.targetDate, params).then(function (response) {
                    if(response.callSuccess === "1" ){
                       //console.log(" response.callSuccess: " ,  response.callSuccess);
                       AlertService.add("success", "Success", 3000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        _data.rowData[_data.colData.map] = "";
                        $rootScope.$broadcast('gridRefresh', false);
                    }

                });







            }


            factory.saveComment = function(_callName , _data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.object_id = _data.rowData.object_id;
                params.comment = _data.rowData[_data.colData.map];
                params[_data.colData.map] =  _data.rowData[_data.colData.map];
                params.tax_year = _data.rowData.TAX_YEAR;
                params.division = _data.rowData.DIVISION;
                params.leid = _data.rowData.LEID;
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";

                JsonObjectFactory.saveJSON(factory.url.saveComment, params).then(function (response) {
                    if(response.callSuccess === "1" ){
                        AlertService.add("success", "Comment has been saved", 3000);
                    }else{
                      if(response.errorMessage != ""){
                        AlertService.add("error", response.errorMessage, 4000);
                        _data.rowData[_data.colData.map] = "";
                        $rootScope.$broadcast('gridRefresh', false);
                      }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                            _data.rowData[_data.colData.map] = "";
                            $rootScope.$broadcast('gridRefresh', false);
                      }

                    }

                });


            }




            factory.signOff = function(_callName , _data){
                var rowData =_data.rowData;
                var type = 'BUSINESS';
                var onOff = '0';
                var checkBoxObj = null;
                var signedOffBy = null;
                var amount = "Remaining_Tax_Payable_Post_Settlement_0";


                if( this.checkLocked(_data)){
                    return false;
                }


                if( _data.rowData.Ready_to_Settle_0 === "No" ){
                    if( (_callName === 'settlement_bus_signoff' &&   _data.rowData.Bus_Sign_Off_0 === 1) || (_callName === 'settlement_corp_signoff' &&   _data.rowData.Settled_SignOff_0 === 1)  ){
                            $timeout(function() {
                            AlertService.add("warn", "Ready To Settle must be set to 'Yes' before you can sign off ", 4000);
                                _data.rowData.Bus__Sign_Off_0 = ( onOff === 0 ) ? 1 : 0;
                                _data.rowData.Settled_SignOff_0 = ( onOff === 0 ) ? 1 : 0;
                                $rootScope.$broadcast('gridRefresh', false);
                            }, 30);
                            return false;
                    }

                }


                if(_callName === 'settlement_bus_signoff' ){
                    //console.log("rowData[amount] " , rowData[amount]);
                    type =  'BUSINESS';
                    onOff = (rowData.Bus_Sign_Off_0 === 1 ) ? "1" : "0";
                    checkBoxObj = "Bus__Sign_Off_0";
                    signedOffBy = "Bus__SignOff_By_0";

                    if(rowData[amount] != 0 && rowData.Bus_Sign_Off_0 == 1 ){
                        $timeout(function() {
                            AlertService.add("warn", "Remaining Tax Payable Post Settlement must be 0", 4000);
                        }, 30);
                        rowData[checkBoxObj] = ( onOff == 0 ) ? 1 : 0;
                        $rootScope.$broadcast('gridRefresh', false);
                        return false;
                    }

                }else{
                    type =  'CORPORATE';
                    onOff = (rowData.Settled_SignOff_0 === 1  ) ? "1" : "0";
                    checkBoxObj = "Settled_SignOff_0";
                    signedOffBy = "Settled_SignOff_By_0";


                    if( rowData.Settled_SignOff_0 === 1 && (rowData[amount] != 0 || rowData.Bus_Sign_Off_0 != 1) ){
                        $timeout(function() {
                            AlertService.add("warn", "Remaining Tax Payable Post Settlement must be 0 and the Business must be signed off ", 4000);
                        }, 30);
                        rowData[checkBoxObj] = ( onOff == 0 ) ? 1 : 0;
                        $rootScope.$broadcast('gridRefresh', false);
                        return false;
                    }


                }


                var username = USER_SETTINGS.user.first_name + " " + USER_SETTINGS.user.last_name;
                var signOffString = username + " " +  moment().format('l');

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.leid = rowData.LEID;
                params.division = rowData.DIVISION;
                params.type = type;
                params.on_off = onOff;
                params.user_name = username;
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";



                JsonObjectFactory.saveJSON(factory.url.signOff, params).then(function (response) {
                    if(response.callSuccess === "1" ){
                        if( onOff === "1"){
                            rowData[signedOffBy] = signOffString
                        }else{
                            rowData[signedOffBy]  = null;
                        }
                        AlertService.add("success", "Success", 3000);
                    }else{
                        if(response.errorMessage != ""){
                            AlertService.add("error",response. data.errorMessage, 4000);

                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                        rowData[checkBoxObj] = ( onOff == 0 ) ? 1 : 0;

                    }
                    //TELL THE GRID TO REPAINT
                    $rootScope.$broadcast('gridRefresh', false);
                });

            }



            factory.validateBucAdn  = function(_params){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , _params);
                _.merge({},  GlobalService.globalParams , params);
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";

                return JsonObjectFactory.saveJSON(factory.url.validate, params).then(function (response) {
                    var status = response.status;
                    if(response.callSuccess == "1"){
                        AlertService.add("success", "Success", 3000);
                    }
                    //console.log("response: " , response)
                    //console.log("response.status: " , response.status)
                    return response;
                });
                return promise;
            }



            factory.getBankerArray = function(_params){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , _params);
                _.merge({},  GlobalService.globalParams , params);


                var promise = $http({
                    method: "post",
                    url: factory.url.getBanker,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    //console.log("response: " , response)
                    //console.log("response.status: " , response.status)
                    return response.data;
                });


                return promise;


            }



            factory.setBanker = function(_params){
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , _params);
                _.merge({},  GlobalService.globalParams , params);
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";
                if(!params.scenario) {
                    params.scenario = "1";
                }
                return JsonObjectFactory.saveJSON(factory.url.setBanker, params).then(function (response) {
                    var status = response.status;
                    if (response.callSuccess == "1"){
                        AlertService.add("success", "Success", 3000);
                    }
                    return response;
                });

                return promise;
            }




            factory.saveSettlementArray = function(_returnObj,_settlementArray){
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , _returnObj);
                params.is_issue_key_enabled = "N";
                params.scenarioCode = "RAF";
                if(!params.scenario) {
                    params.scenario = "1";
                }


                var jsonObj = JSON.stringify(_settlementArray);




                _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params.username = USER_SETTINGS.user.first_name + " " + USER_SETTINGS.user.last_name;


                return JsonObjectFactory.saveJSON(factory.url.put, params).then(function (response) {
                    var status = response.status;
                    if(response.callSuccess == "1") {
                        AlertService.add("success", "Success", 3000);
                    }
                    //console.log("response: " , response)
                    //console.log("response.status: " , response.status)
                    return response;
                });


                return promise;


                //console.log("-------------saveSettlementArray-----------------------");
                //console.log("params " , params);

            }


            factory.getSettlementArray = function(_params){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , _params);
                _.merge({},  GlobalService.globalParams , params);
                //console.log("params " , params);

                var promise = $http({
                    method: "post",
                    url: factory.url.get,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    //console.log("response: " , response)
                    //console.log("response.status: " , response.status)
                    return response.data;
                });


                return promise;


            }




            return factory;


        }])


    return services;


});