define([

	'angular'

], function () {

	'use strict';

	var controllers = angular.module('app.mefEfileMainController', [])

		.controller('mefEfileMainController', ['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'JsonObjectFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', '$window', '$timeout', '$filter', mefEfileMainController]);

	function mefEfileMainController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, JsonObjectFactory, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, $window, $timeout, $filter) {
		var vm = this;
		vm.modalData = [];
		vm.disable = true;
		vm.loaded = false;
		vm.crudLoading = false;

		vm.baseURLs = {};

		vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
		vm.baseURLs.api = GENERAL_CONFIG.base_url;
		vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
		vm.rowData = JSON.stringify(rowData);
		vm.baseURLs = JSON.stringify(vm.baseURLs);
		vm.globalParams = JSON.stringify(GlobalService.globalParams);
		vm.isIssueKeyEnabled = GlobalService.inputs.tax_year.selected.is_issue_key_enabled;
		if (vm.isIssueKeyEnabled === undefined) {
			vm.isIssueKeyEnabled = GlobalService.globalParams.is_issue_key_enabled;
		}

		vm.issueKey = rowData.ISSUE_KEY;

		vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE;

		if (vm.isIssueKeyEnabled === undefined) {
			if (vm.scenarioCode !== undefined) {
				if (vm.scenarioCode === 'RAF') {
					vm.isIssueKeyEnabled = 'N';
				}
				else {
					vm.isIssueKeyEnabled = 'Y';
				}
			}
		}

		vm.loaded = true;
		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		}
		if ($uibModalInstance) {
			$timeout(() => {
				$window.document.querySelector('gtw-mef-efile-changes')
					.addEventListener('request-grid-update', (event) => {
						if (event.detail == 'refresh') {
							$rootScope.$broadcast('dataFilters:refreshGrid', {
								"refresh": true
							});
						}

						$uibModalInstance.close();
					});
			}, 500);
		}

	}

	return controllers;

});