define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.dividendsPaymentCtrl', [])
        .controller('dividendsPaymentController', [dividendsPaymentController]);

    function dividendsPaymentController() {
    var vm= this;
    vm.dividends_data=[
        {"adj_des": "Reclass Description New", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "06/10/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "06/11/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "06/13/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description New", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "06/13/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description ", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "06/17/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description ", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "06/17/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description ", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "07/14/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "07/14/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description New", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "07/15/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Heath Test 34", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "08/11/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description New", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "08/13/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "08/15/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description New", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "09/01/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "09/14/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description ", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "09/21/2019", "upd_by": "Heath Stein"},
        {"adj_des": "Reclass Description New", "adj_type": "Current Tax Year (Reviewer)", "last_upd": "09/21/2019", "upd_by": "Heath Stein"}

    ];
    }
});