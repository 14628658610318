/**
 * Created by 212544474 on 4/1/2016.
 */
 define(
	[ 'moment','angular'

	],
	function(moment) {
		'use strict';

		var controllers = angular.module('app.consolgroupEditController', [])
				.controller('consolgroupEditController',
							['$rootScope', '$scope', '$http', 'GlobalService', '$filter','$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'colData', 'gridData', 'JsonObjectFactory','USER_SETTINGS' ,
							'ConsolGroupEditFactory', '$timeout', 'TbbsService', 'GENERAL_CONFIG', 'workspaceFactory', consolgroupEditController ]);



		function consolgroupEditController($rootScope, $scope, $http, GlobalService, $filter,$uibModalInstance, ModalFactory, AlertService, rowData,colData, gridData, JsonObjectFactory, USER_SETTINGS, ConsolGroupEditFactory, $timeout, GENERAL_CONFIG,workspaceFactory) {

			
			var vm = this;
			var taxYearStartInd, taxYearEndInd, goldheadLeidInd, currencyInd, taxOwnerInd, taxOwnerStartInd, taxOwnerEndInd = 0;  
			var addMemberInd, removeMemberInd, countryInd = 0;
			vm.userSettings = USER_SETTINGS;
			vm.logged_in_user = vm.userSettings.user.sso_id;
			vm.defaultsMultipleSelected = [];
			vm.gridData = {};
			vm.syncSaveDtls = [];
		
			vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
			vm.isSaveClicked = false;
			vm.colData = colData;
			vm.rowData = rowData;
			vm.tax_year_list = {};	
			vm.gold_head_leid_list = {};	
			vm.currency_list = {};	
			vm.taxOwnersList = {};
			vm.selectedFiscalStart;
			vm.selectedFiscalEnd;
			vm.selectedHeadLeid;
			vm.selectedCurrency;
			vm.selectedTaxOwner;
			vm.selectedTaxOwnerStart;
			vm.selectedTaxOwnerEnd;
			vm.GROUP_TYPE = "";
			vm.GROUP_NAME = "";
			vm.GROUP_CURRENCY = "";
			vm.GROUP_ID = 0;
			vm.colDataMap = (vm.colData ? vm.colData.map : 'ADD_GROUP');
			vm.non_member_list_count = 0;
			vm.member_list_count = 0;
			vm.disabled = (vm.colDataMap==='GROUP_EDIT' ? true:false);
			vm.showConsolGroupForm = (vm.colDataMap==='GROUP_EDIT' || vm.colDataMap==='ADD_GROUP' ? true:false);
			vm.showTaxOwnerForm = (vm.colDataMap==='US_TAX_OWNER' ? true:false);
			vm.selectedmembersList = [];
			vm.nonMembersList;
			vm.membersList;
			vm.countryList;
			vm.selectedGroupCountry;
			vm.membersAmountList;
			vm.memberDeleteList = [];
			vm.credit_us_amt = {};
			vm.non_credit_us_amt = {};
			vm.non_credit_us_amt_total = 0;
			vm.credit_us_amt_total = 0;
			vm.nonMemerLoading = false;
			vm.raf_scenario;
			vm.filter_tax_year;
			
			vm.calendar = {
                        opened: false
                    };
                    
             vm.openCalendar = function() {
	                 		vm.calendar.opened = true;  
	                 	};
	                 	
			          vm.dateOptions = {
		            dateDisabled: false,
		            formatYear: 'yyyy',
		            startingDay: 1,
		            showWeeks: false
		        			};       	
	                 	     
			vm.cancel = function() {
				$uibModalInstance.dismiss('cancel');
			};

			vm.reset = function () {
            	init();
        	};

			vm.changeFiscalStart = function() {
				loadGoldHeadLeid(parseInt(vm.selectedFiscalStart.TAX_YEAR))
			};

			vm.changeFiscalEnd = function() {
				console.log('changed atx year', vm.selectedFiscalEnd);
				console.log($scope.ftr_consol_form);
				if (vm.selectedFiscalEnd && (vm.selectedFiscalEnd.TAX_YEAR < vm.selectedFiscalStart.TAX_YEAR)) {
					$scope.ftr_consol_form.fiscal_end.$setValidity(
						"invalidFiscalEndYear", false);
				} else {
					$scope.ftr_consol_form.fiscal_end.$setValidity(
						"invalidFiscalEndYear", true);
				}
                 if(vm.isSaveClicked == false){
	                      vm.selectedTaxOwnerEnd = vm.selectedFiscalEnd;
                    }
			}

			
			vm.changeTaxOwnerEnd = function() {
				console.log('changed atx year', vm.selectedTaxOwnerEnd);
				
				if (vm.selectedTaxOwnerEnd && (vm.selectedTaxOwnerEnd.TAX_YEAR < vm.selectedTaxOwnerStart.TAX_YEAR)) {
					$scope.ftr_consol_form.us_tax_owner_end_year.$setValidity(
						"invalidTaxOwnerEndYear", false);
				} else {
					$scope.ftr_consol_form.us_tax_owner_end_year.$setValidity(
						"invalidTaxOwnerEndYear", true);
				}
			}

			vm.changeCountry = function() {
				loadNonMembersList(vm.selectedGroupCountry.VALUE);
			}

			vm.setTotal = function() {
				
				var total_credit_amt=0;
				var total_non_credit_amt = 0;
				_.forEach(vm.credit_us_amt, function(value,key) {
					total_credit_amt += value;
				});

				vm.credit_us_amt_total = total_credit_amt; 

				_.forEach(vm.non_credit_us_amt, function(value,key) {
					total_non_credit_amt += value;
				});
				vm.non_credit_us_amt_total = total_non_credit_amt; 

			}
			
			function init() {
				vm.GROUP_TYPE = (vm.rowData) ? vm.rowData.GROUP_TYPE : 'CH';
				vm.GROUP_NAME = (vm.rowData) ? vm.rowData.GROUP_NAME : '';
				vm.GROUP_CURRENCY = (vm.rowData) ? vm.rowData.CURRENCY : '';
				vm.GROUP_ID = (vm.rowData) ? vm.rowData.GROUP_ID : 0;
				vm.credit_us_amt_total =0;
				vm.non_credit_us_amt_total =0;
				vm.nonMemerLoading = false;
				vm.filter_tax_year = parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year);
				getRAFScenario();
				if(vm.colDataMap==='GROUP_EDIT' || vm.colDataMap==='ADD_GROUP') {
					loadTaxYears();
					loadGoldHeadLeid(parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year));
					loadCurrencies();
					loadTaxOwners();
				}

				if(vm.colDataMap === 'MEMBERS_EDIT') {
					//loadNonMembersList();
					loadMembersList();
					loadCountryList();
				}
				
				if(vm.colDataMap ==='ALLOCATE_LIABILITY') {
					loadMembersAmtList();
				}

				if(vm.colDataMap ==='US_TAX_OWNER') {
					loadTaxOwners();
				}

				console.log($scope.ftr_consol_form);
				if ($scope.ftr_consol_form !== undefined) {
					$scope.ftr_consol_form.$setPristine();
					$scope.ftr_consol_form.$setUntouched();
				}
				
			}

			init();

			function loadTaxYears() {
				console.log('rowData',rowData);
				console.log('filter', ConsolGroupEditFactory.getScreenFilters());
			

				ConsolGroupEditFactory.getTaxYears().then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.tax_year_list = data.jsonObject;
							if(vm.colDataMap==='GROUP_EDIT') {
								taxYearStartInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : vm.rowData.FISCAL_START
								});
								vm.selectedFiscalStart = vm.tax_year_list[taxYearStartInd];

								taxYearEndInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : vm.rowData.FISCAL_END
								});

								vm.selectedFiscalEnd = vm.tax_year_list[taxYearEndInd];

								taxOwnerStartInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : vm.rowData.TAX_OWNER_START
								});
								vm.selectedTaxOwnerStart = vm.tax_year_list[taxOwnerStartInd];

								taxOwnerEndInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : vm.rowData.TAX_OWNER_END
								});
								vm.selectedTaxOwnerEnd = vm.tax_year_list[taxOwnerEndInd];
								
							} else {
								/* vm.selectedFiscalStart = vm.tax_year_list[0];
								vm.selectedFiscalEnd = vm.tax_year_list[0]; */
								
								taxYearStartInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year)
								});

								vm.selectedFiscalStart = vm.tax_year_list[taxYearStartInd];

								taxYearEndInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year)
								});

								vm.selectedFiscalEnd = vm.tax_year_list[taxYearEndInd];

								taxOwnerStartInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year)
								});
								vm.selectedTaxOwnerStart = vm.tax_year_list[taxOwnerStartInd];

								taxOwnerEndInd = _.findIndex(vm.tax_year_list, {
									'TAX_YEAR' : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year)
								});
								vm.selectedTaxOwnerEnd = vm.tax_year_list[taxOwnerEndInd];
							}
							
							
						}
				});
			};

			function getRAFScenario() {
				console.log('rowData',rowData);
				console.log('filter', ConsolGroupEditFactory.getScreenFilters());
			
				var params = {
					"tax_year" : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year)
				};

				ConsolGroupEditFactory.getRAFScenario(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.raf_scenario = data.jsonObject[0].SCENARIO;
							console.log('scenario  raf', vm.raf_scenario);								
						}
				});
			};

			function loadGoldHeadLeid(tax_year_val) {
				console.log('rowData',rowData);
				console.log('global', GlobalService.globalParams);
				console.log('screen tax year', ConsolGroupEditFactory.getScreenFilters());
			
				var params = {
					"tax_year" : tax_year_val, // change it to screen
					"scenario" : vm.raf_scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key,
				};

				ConsolGroupEditFactory.getHeadleid(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.gold_head_leid_list = data.jsonObject;
							if(vm.colDataMap==='GROUP_EDIT') {
								goldheadLeidInd = _.findIndex(vm.gold_head_leid_list, {
									'LEID' : vm.rowData.GROUP_LEID
								});
								//vm.selectedHeadLeid = vm.gold_head_leid_list[goldheadLeidInd];
								vm.selectedHeadLeid = [];
								vm.selectedHeadLeid.push({"LEID":vm.rowData.GROUP_OWNER});
							}
							console.log(vm.gold_head_leid_list);
						}
				});

				
			};

			function loadCurrencies() {
				console.log('rowData',rowData);
				console.log('global', GlobalService.globalParams);
				console.log('screen tax year', ConsolGroupEditFactory.getScreenFilters());
			
				var params = {
					"tax_year" : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year)
				};

				ConsolGroupEditFactory.getCurrencies(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.currency_list = data.jsonObject;
							if(vm.colDataMap==='GROUP_EDIT') {
								currencyInd = _.findIndex(vm.currency_list, {
									'CURR_CODE' : vm.rowData.CURRENCY
								});
								vm.selectedCurrency = vm.currency_list[currencyInd];
							}
						}
				});

				
			};

			function loadTaxOwners() {
				console.log('rowData',rowData);
				console.log('filter', ConsolGroupEditFactory.getScreenFilters());
			

				ConsolGroupEditFactory.getTaxOwners().then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.taxOwnersList = data.jsonObject;
							vm.selectedTaxOwner = vm.taxOwnersList[0];

							if(vm.colDataMap==='GROUP_EDIT' || vm.colDataMap==='US_TAX_OWNER') {
								taxOwnerInd = _.findIndex(vm.taxOwnersList, {
									'SSO_ID' : vm.rowData.GROUP_OWNER
								});
								vm.selectedTaxOwner = vm.taxOwnersList[taxOwnerInd];
							}

							console.log(vm.taxOwnersList); 
						}
				});
			};

			function loadNonMembersList(group_country) {
				vm.nonMemerLoading = true;
				var params = {
					"tax_year" : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					//"tax_year" : vm.rowData.FISCAL_START,
					"group_id": vm.rowData.GROUP_ID,
					"country":group_country
				};

				ConsolGroupEditFactory.getNonMembersList(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.nonMemerLoading = false;
						} else {				
							vm.nonMembersList = data.jsonObject;
							vm.non_member_list_count = vm.nonMembersList.length;
							console.log(vm.nonMembersList);
							vm.nonMemerLoading = false;
						}
				});

				
			};

			function loadMembersList() {
				
				var params = {
					"tax_year" : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					//"tax_year" : vm.rowData.FISCAL_START,
					"group_id" : vm.rowData.GROUP_ID
				};

				ConsolGroupEditFactory.getMembersList(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.membersList = data.jsonObject;
							vm.member_list_count = vm.membersList.length;
							console.log(vm.membersList);
						}
				});

				
			};

			function loadCountryList() {
				
				var params = {
					"tax_year" : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year)
				};

				ConsolGroupEditFactory.getCountryList(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.countryList = data.jsonObject;
							countryInd = _.findIndex(vm.countryList, {
								'VALUE' : vm.rowData.COUNTRY
							});
							vm.selectedGroupCountry = vm.countryList[countryInd];
							loadNonMembersList(vm.selectedGroupCountry.VALUE);
						}
				});

				
			};

			function loadMembersAmtList() {
				
				var params = {
					//"tax_year" : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					"tax_year" : parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					"group_id" : vm.rowData.GROUP_ID
				};

				ConsolGroupEditFactory.getMembersConsolAmts(params).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
						} else {				
							vm.membersAmountList = data.jsonObject;
							vm.dateFiled = new Date(vm.membersAmountList[0].DATEFILED);
							console.log('members amount',vm.membersAmountList);
							vm.credit_us_amt = {};
							_.forEach(vm.membersAmountList, function(value,key) {
								console.log('key', key);
								console.log('value', value);
								
								vm.credit_us_amt[value.COMBINATION_KEY] = value.CREDIT_AMT;
								vm.non_credit_us_amt[value.COMBINATION_KEY] = value.NON_CREDIT_AMT;
								
							});
							vm.setTotal();							
							console.log(vm.dateFiled);
							console.log(vm.credit_us_amt);
							console.log(vm.non_credit_us_amt);
						}
				});

				
			};

			vm.addMembers = function(item) {

				/* if($event.currentTarget.checked) {
					vm.selectedmembersList.push(item);
				} else {
					vm.selectedmembersList.pop(item);
				}
				
				console.log(vm.selectedmembersList); */
				console.log(item);

				addMemberInd = _.findIndex(vm.nonMembersList, {
					'COMBINATION_KEY' : item.COMBINATION_KEY});
				vm.nonMembersList.splice(addMemberInd,1);
				vm.membersList.push(item);

				//also remove from delete list if item being readded
				removeMemberInd = _.findIndex(vm.memberDeleteList, {
					'COMBINATION_KEY' : item.COMBINATION_KEY});
				vm.memberDeleteList.splice(removeMemberInd,1);
				
			}

			vm.removeMembers = function(item) {
				/* if($event.currentTarget.checked) {
					vm.selectedmembersList.push(item);
				} else {
					vm.selectedmembersList.pop(item);
				}
				
				console.log(vm.selectedmembersList); */
				console.log(item);
				removeMemberInd = _.findIndex(vm.membersList, {
					'COMBINATION_KEY' : item.COMBINATION_KEY});
				
				if(item.COMBINATION_KEY === vm.rowData.GROUP_LEID_CC_KEY) {
					AlertService.add("danger", "You cannot delete " + item.LEID + " as it is head of the group", 4000);
					return;
				}
				if("RECORD_TYPE" in item) {
					vm.memberDeleteList.push(item);
				}
				vm.membersList.splice(removeMemberInd,1);				
				vm.nonMembersList.push(item);

			}

			vm.save = function(type) {
				
				if(type === 'GROUP_EDIT' || type === 'ADD_GROUP') {
					vm.isSaveClicked = true;
					vm.changeFiscalEnd();
					//vm.changeTaxOwnerEnd();

					if ($scope.ftr_consol_form.$invalid) {
						$scope.ftr_consol_form.$submitted = true;
						AlertService.add("danger", "Please correct the errors below", 4000);
						return;
					}
				}

				console.log('save type', type);
				if(type === 'GROUP_EDIT' || type === 'ADD_GROUP') {
					vm.saveGroup();
				}

				if(type === 'MEMBERS_EDIT') {
					vm.saveUpdateMembers();
				}

				if(type === 'ALLOCATE_LIABILITY') {
					vm.saveUpdateAllocations();
				}


				if(type === 'US_TAX_OWNER') {
					vm.saveUpdateTaxOwners();
				}
				
			}

			vm.saveGroup = function() {
				var paramsArray = [];
				var reqParams = {
					"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					"scenario": vm.raf_scenario,
					"jcd_key":250
				}
				var params = {
					"group_id": vm.GROUP_ID,
					"fiscal_start": vm.selectedFiscalStart.TAX_YEAR,
					"fiscal_end": vm.selectedFiscalEnd.TAX_YEAR,
					"group_leid_cc_key": (vm.colDataMap==='GROUP_EDIT' ? vm.rowData.GROUP_LEID_CC_KEY : vm.selectedHeadLeid.COMBINATION_KEY),
					"group_name": vm.GROUP_NAME,
					"group_type": vm.GROUP_TYPE,
					"currency": vm.selectedCurrency.CURR_CODE,
					"tax_owner": vm.selectedTaxOwner.SSO_ID,
					/* "tax_owner_start": vm.selectedTaxOwnerStart.TAX_YEAR,
					"tax_owner_end": vm.selectedTaxOwnerEnd.TAX_YEAR, */
					"scenario":vm.raf_scenario
				}

				paramsArray.push(params);

				ConsolGroupEditFactory.saveUpdateGroups(paramsArray, reqParams).then(function (result) {

                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", "New Group has been added successfully", 4000);
                       
                        $uibModalInstance.close();
						var args = {};
                        $rootScope.$emit('gridUpdate', args);
                    }
                });
			}

			vm.saveUpdateMembers = function() {
				var paramsArray = [];

				console.log('screen filter', ConsolGroupEditFactory.getScreenFilters());

				var reqParams = {
					"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					"scenario": vm.raf_scenario,
					"jcd_key":250
				}

				_.forEach(vm.membersList, function(value,key) {
					var params = {
						"group_id": vm.GROUP_ID,
						"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
						"combination_key": value["COMBINATION_KEY"],
						"record_type": "A",
						"fiscal_start": vm.rowData.FISCAL_START,
						"fiscal_end": vm.rowData.FISCAL_END
					}
	
					paramsArray.push(params);
					
				});
				
				
				console.log('delete list',vm.memberDeleteList);
				_.forEach(vm.memberDeleteList, function(value,key) {
					var params = {
						"group_id": vm.GROUP_ID,
						"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
						"combination_key": value["COMBINATION_KEY"],
						"record_type": "D"
					}
	
					paramsArray.push(params);
					
				});

				console.log(paramsArray);
				ConsolGroupEditFactory.saveUpdateConsolMembers(paramsArray, reqParams).then(function (result) {

                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", "New Members have been added successfully", 4000);
                       
                        $uibModalInstance.close();
						var args = {};
                        $rootScope.$emit('gridUpdate', args);
                    }
                });
			}

			vm.saveUpdateAllocations = function() {
				/* console.log('credit amt:', vm.credit_us_amt);
				console.log('non credit amt', vm.non_credit_us_amt); */
				var paramsArray = [];
				var reqParams = {
					"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					"scenario": vm.raf_scenario,
					"jcd_key":250
				}
				_.forEach(vm.credit_us_amt, function(value,key) {
					console.log(key + ', ' + value)
					var params = {
						"group_id": vm.GROUP_ID,
						"group_currency": vm.GROUP_CURRENCY,
						"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
						"combination_key": key,
						"amt": value,
						"trans_type": "CA",
						"scenario":vm.raf_scenario,
						"dateFiled":$filter('date')(vm.dateFiled, "MM/dd/yyyy") 
					}

					paramsArray.push(params);
				});

				_.forEach(vm.non_credit_us_amt, function(value,key) {
					console.log(key + ', ' + value)
					var params = {
						"group_id": vm.GROUP_ID,
						"group_currency": vm.GROUP_CURRENCY,
						"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
						"combination_key": key,
						"amt": value,
						"trans_type": "NCA",
						"scenario":vm.raf_scenario,
						"dateFiled":$filter('date')(vm.dateFiled, "MM/dd/yyyy") 
					}

					paramsArray.push(params);
				});

				console.log(paramsArray); 

				ConsolGroupEditFactory.saveUpdateConsolGroupAmts(paramsArray, reqParams).then(function (result) {

                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", "Amounts have been added successfully", 4000);
                       
                        $uibModalInstance.close();
						var args = {};
                        $rootScope.$emit('gridUpdate', args);
                    }
                });

			}

			vm.saveUpdateTaxOwners = function() {
				/* console.log('credit amt:', vm.credit_us_amt);
				console.log('non credit amt', vm.non_credit_us_amt); */
				var paramsArray = [];
				var reqParams = {
					"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					"scenario": vm.raf_scenario,
					"jcd_key":250
				}
				
				var params = {
					"tax_year": parseInt(ConsolGroupEditFactory.getScreenFilters().tax_year),
					"group_id": vm.GROUP_ID,
					"tax_owner": vm.selectedTaxOwner.SSO_ID					
				}

				paramsArray.push(params);

				ConsolGroupEditFactory.saveUpdateTaxOwners(paramsArray, reqParams).then(function (result) {

                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", "Amounts have been added successfully", 4000);
                       
                        $uibModalInstance.close();
						var args = {};
                        $rootScope.$emit('gridUpdate', args);
                    }
                });

			}
		}
		return controllers;

	});