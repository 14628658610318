/**
 * Created by 212544474 on 8/16/2016.
 */
define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.screenCtrl',[])
        .controller('screenListCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','$uibModal','$parse','ClientAdminFactory','GENERAL_CONFIG','USER_SETTINGS', screenListCtrl])
        .controller('screenCtrl', ['$http','JdcManagerFactory','$rootScope','$scope','$state','$log','JsonObjectFactory','ClientAdminFactory','AlertService','$timeout','$uibModalInstance','rowData','$parse','JCDFactory','GENERAL_CONFIG','USER_SETTINGS','USER_MENU', screenCtrl])

    function screenCtrl($http,JdcManagerFactory,$rootScope,$scope,$state,$log,JsonObjectFactory,ClientAdminFactory,AlertService,$timeout,$uibModalInstance,rowData,$parse,JCDFactory,GENERAL_CONFIG,USER_SETTINGS,USER_MENU) {

    	console.log('inside screenCtrl');
        var vm = this;
        vm.title = "Screen";
        vm.loading = false;
        vm.screen_data = {};
        vm.mode =  rowData && rowData.SCREEN_KEY ? 'edit' : 'new';
        vm.jcdlist = {};
        vm.crudLoading = false;
        vm.tagBtnLoading = false;
        vm.roles = {selected:null, list:[], loading:false, disabled:true };
        vm.client = {selected:null,list:[], disabled:true };
        vm.screenStatus = [{name:"Active",value:"A"},{name:"Locked",value:"L"},{name:"Maintenance",value:"M"},{name:"Removed",value:"R"}]
        vm.filter_group = [];
        vm.userSettings = USER_SETTINGS;
        vm.client_key = vm.userSettings.user.client.client_key;
        vm.tax_placemat = {selected:null, list:[], disabled:true };
        vm.userMenu = USER_MENU;
        vm.screenList = [];

        console.log("vm.userMenu",vm.userMenu);

        vm.filters = {};
        vm.filters.me = [];
        vm.search;
        vm.filters.me_list = {};
        vm.filters.me_list.selected = [];
        vm.filters.me_list.values = [];
        vm.screen_data.screens_refresh_list =[]
        vm.addedTags = [];
        vm.searchTagText = "";
        vm.tagSearchResults = [];
        vm.allTagsList = [];
        vm.getfilte = function(){
            if(vm.screen_data.filter_refresh === 1){

                vm.filters.me_list.name = "Channel(s)";
                _.each(USER_MENU,function(item){
                    item.name = item.label; item.value = item.id;
                })
                vm.filters.me_list.values = USER_MENU;
            }

        }
        vm.getScreens = function(){
            var collection = USER_MENU;
            var children = 'children';
            vm.getScreen(collection,children);
        }

        vm.getScreen = function(_collection,children){
            for (var i = 0; i < _collection.length; i++) {
                var thisChildren = _collection[i][children];
                if (thisChildren && thisChildren.length) {
                    vm.getScreen(thisChildren, children);
                }
                for (var n in thisChildren) {
                    thisChildren[n].parent = _collection[i];
                }

                //SET SCREEN PARENT
                if (_collection[i].type === 'activity' && parseInt(_collection[i].activity_key) == vm.jcdlist.activity.selected.value && _collection[i].screens.length) {
                    var screens = _collection[i].screens;
                    vm.screensByActivityList = [];
                    for (var n in screens) {
                        screens[n].parent = _collection[i];
                    }
                    vm.screensByActivityList = screens;
                    var refresh_list = vm.screen_data.screens_refresh_list;
                    vm.screen_data.screens_refresh_list = [];
                    if(refresh_list != null) {
                        var refresLIst = refresh_list.split(',');
                        console.log("refresLIst,",refresLIst);
                        _.each(refresLIst,function(item,key){
                            _.each(vm.screensByActivityList,function(sc){
                                sc.item_check;
                                if(sc.id == item){
                                    sc.item_check = true;
                                    vm.updateRefreshList(sc);
                                }
                            })

                        })
                        console.log("Screens",vm.screensByActivityList);
                    }
                }
            }
        }

        vm.scenarioTypeCode = [];
		vm.selectedScenarioCodes = [];

		vm.multiselDropdownSettings = {
            buttonClasses: 'mul-select-btn',
            showCheckAll: true,
            showUncheckAll: true,
            checkBoxes: true,
            displayProp: 'scenario_type_desc',
            smartButtonMaxItems: 1,
            scrollable: true,
            scrollableHeight: 'auto',
        };

		vm.mulSelectCustomTexts = {
            buttonDefaultText: 'Select scenario type',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };

		vm.multiselectEvents = {

			onItemSelect: function (item) {
                vm.selectedScenarioCodes.indexOf(item) === -1 ? 
				vm.selectedScenarioCodes.push(item) : vm.selectedScenarioCodes;
            },
            onItemDeselect: function (item) {
                vm.selectedScenarioCodes.indexOf(item) !== -1 ? 
				vm.selectedScenarioCodes.splice(vm.selectedScenarioCodes.indexOf(item), 1) : vm.selectedScenarioCodes;
            },
			onSelectAll: function(){
				vm.selectedScenarioCodes = [];
				vm.scenarioTypeCode.forEach(i => vm.selectedScenarioCodes.push(i));
			},
			onDeselectAll: function(){
				vm.selectedScenarioCodes = [];
			}

        };
        vm.getScenarioTypeCodes = function(){
            JdcManagerFactory.getScenarioTypeCodes().then(function(response) {
              vm.scenarioTypeCode = response.data;
            });
        }
        vm.getScenarioTypeCodes();


        vm.updateRefreshList = function(data){

            if(data.item_check == true) {
                vm.screen_data.screens_refresh_list.push(data.id)
                console.log("adfasdf",vm.screen_data.screen_refresh);
            } else {
                _.pull(vm.screen_data.screens_refresh_list,data.id)
                console.log("remove",vm.screen_data.screens_refresh_list);
            }

        }


        getFilterGroup();
        getTaxPlacematList();

        vm.generateApiKey=function(){
            var jsonURL = vm.screen_data.screen_type == 'grid'? 'loadGridJson':
                          vm.screen_data.screen_type == 'grid-dynamic'? 'loadDynamicGridJson':
                          vm.screen_data.screen_type =='pivot'? 'loadPivotGridJson' :
                          vm.screen_data.screen_type == 'grid-import'? 'emptyGridJson' :
						  vm.screen_data.screen_type == 'grid-import-td'?'emptyGridJson' :
                          vm.screen_data.screen_type == 'grid-import-validation'? 'emptryGridJson':
                          vm.screen_data.screen_type == 'grid-import-pivot'? 'emptyGridJson':
                          vm.screen_data.screen_type == 'grid-standard-pivot'? 'loadGridJsonPivotWithoutHeaders':
                          vm.screen_data.screen_type == 'custom'? '':
                          vm.screen_data.screen_type == 'form'? '':
                          vm.screen_data.screen_type == 'dashboard'? 'getDashboardByKey':
                          vm.screen_data.screen_type == 'custom-tab'? '' : 'loadGridJson';
            vm.screen_data.api = {'get':''};
            if(vm.screen_data.grid_key)
                vm.screen_data.api.get= jsonURL+'?grid_id='+vm.screen_data.grid_key
            if(vm.screen_data.screen_type !=='batch-save' && vm.screen_data.screen_type !== 'grid-import'  && vm.screen_data.screen_type !== 'grid-import-td' && vm.screen_data.screen_type !== 'grid-import-validation' && vm.screen_data.screen_type !== 'grid-import-pivot'){
                vm.screen_data.batch_save_action_key='';
            }
            if(vm.screen_data.screen_type == 'grid-import' && vm.screen_data.screen_type == 'grid-import-validation'){
               vm.screen_data.action_key='';
            }
			if(vm.screen_data.screen_type == 'grid-import-td'){
                vm.screen_data.action_key='';
            }
            if(vm.screen_data.dashboard_id){
                vm.screen_data.api.get=jsonURL+'?dashboard_id='+vm.screen_data.dashboard_id
            }
            if(vm.screen_data.action_key){
                vm.screen_data.api.get=vm.screen_data.api.get+'&action_id='+vm.screen_data.action_key;
            }
            if(vm.screen_data.batch_save_action_key && vm.screen_data.screen_type == 'batch-save'){
                // vm.screen_data.api['save']=(vm.screen_data.screen_type == 'grid-import-validation'?'gridBatchImportValidation.ge':'batchSave.ge')+
                // ('&action_id='+vm.screen_data.batch_save_action_key);
                vm.screen_data.api['save']= 'batchSave?action_id='+vm.screen_data.batch_save_action_key;
            } else if(vm.screen_data.batch_save_action_key){
                // vm.screen_data.api['save']=(vm.screen_data.screen_type == 'grid-import-validation'?'gridBatchImportValidation.ge':'batchSave.ge')+
                // ('&action_id='+vm.screen_data.batch_save_action_key);
                vm.screen_data.api['save']= 'gridBatchImportValidation?action_id='+vm.screen_data.batch_save_action_key;
            }

           vm.screen_data.api=JSON.stringify(vm.screen_data.api);

           if(vm.screen_data.screen_type == 'dashboard'){
                vm.screen_data.screen_controller = 'StandardDashboardController as ctrl';
                vm.screen_data.screen_template = 'app/components/dashboardExecutive/executive-dashboards.html';
            }else if(vm.screen_data.screen_type == 'custom-tab'){
                vm.screen_data.screen_controller = '';
                vm.screen_data.screen_template = '';
            }
            else{
                vm.screen_data.screen_controller = 'GirdStandardController as ctrl';
                vm.screen_data.screen_template = 'app/components/grid/grid-standard-tpl.html';
            }
        }

        vm.filter_type = [
            {name: "required", value: "required"},
            {name: "open", value: "open"},
            {name: "closed", value: "closed"}
        ];

        vm.screen_type =  [
            {name:"Grid Standard ",value:"grid"},
            {name:"Grid Dynamic ",value:"grid-dynamic"},
            {name:"Grid Batch Save",value:"batch-save"},
            {name:"Grid Import",value:"grid-import"},
			{name:"Grid Import Tab Delimited",value:"grid-import-td"},
            {name:"Dashboard",value:"dashboard"},
            {name:"Pivot",value:"pivot"},
            {name:"Grid Import Pivot",value:"grid-import-pivot"},
            {name:"Grid Standard Pivot",value:"grid-standard-pivot"},
            {name:"Form",value:"form"},
            {name:"Custom",value:"custom"},
            {name:"Grid Tax Placemat",value:"grid-tax-placemat"},
            {name:"Grid Import Validation",value:"grid-import-validation"},
            {name:"Custom With Tab",value:"custom-tab"}
        ];

        vm.clearValues = function(name, value){
            if(name == 'all' && !value){
                vm.screen_data.enable_fetch = 0;
                vm.others_access = '';
                vm.screen_data.enable_reprocess = 0;
                vm.allow_reprocess = '';
                vm.screen_data.enable_delete = 0;
                vm.delete_upload = '';
                vm.screen_data.delete_action_key = '';
            }else if(name == 'others' && !value){
                vm.others_access = '';
            }else if(name == 'reprocess' && !value){
                vm.allow_reprocess = '';
            }else if(name == 'delete' && !value){
                vm.delete_upload = '';
                vm.screen_data.delete_action_key = '';
            }
        };

        vm.onSelectChange = function(num){
            JCDFactory.setObj(num,null);
         /*      vm.jcdlist.jurisdiction = JCDFactory.lists.jurisdiction;
            vm.jcdlist.domain = JCDFactory.lists.domain; */
            vm.jcdlist.jurisdiction = 1;
            vm.jcdlist.domain = 5;
            vm.jcdlist.channel = JCDFactory.lists.channel;
            vm.jcdlist.task = JCDFactory.lists.task;
            vm.jcdlist.activity = JCDFactory.lists.activity;
            vm.screen_data.jcd_ta_key =  JCDFactory.jcd_ta_key;
            vm.roles = [];
            setRoles(vm.screen_data.jcd_ta_key,null);
            console.log("task list from action",vm.jcdlist.task);
        };

       /*  vm.jcdlist.jurisdiction = JCDFactory.lists.jurisdiction;
        vm.jcdlist.domain = JCDFactory.lists.domain;
        vm.jcdlist.channel = JCDFactory.lists.channel;
        vm.jcdlist.task = JCDFactory.lists.task;
        vm.jcdlist.activity = JCDFactory.lists.activity; */

        var setRoles = function(_jdc_ta_key,_role_key){
            if(_jdc_ta_key === null || _jdc_ta_key === undefined){
                vm.roles.disabled = true;
                return false;
            }
            vm.roles.loading = true;
            var  params = {action_id:81, jcd_ta_key:_jdc_ta_key, client_key: vm.CLIENT_KEY};
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1" && data.jsonObject && data.jsonObject[0]){
                    AlertService.add("error", data.errorMessage);
                } else {
                    vm.roles.disabled = false;
                    vm.roles.loading = false;
                    vm.roles.list = JsonObjectFactory.arrObjToLower(data.jsonObject);
                    if( _role_key ){
                        vm.roles.selected = _.find(vm.roles.list,{jcd_ta_role_key:_role_key});
                        vm.others_access = _.find(vm.roles.list,{jcd_ta_role_key:vm.screen_data.fetch_role_key});
                        vm.allow_reprocess = _.find(vm.roles.list,{jcd_ta_role_key:vm.screen_data.reprocess_role_key});
                        vm.delete_upload = _.find(vm.roles.list,{jcd_ta_role_key:vm.screen_data.delete_role_key});
                    }
                }
            });
        }

        if(vm.mode === 'edit'){
            getData(rowData.VERSION_NUM);
        }else{
            vm.screen_data.allow_paste_xls = 1;
            vm.screen_data.allow_upload_xls = 1;
            vm.screen_data.allow_partial_save = 0;
            vm.screen_data.max_paste_rows = 2000;
            vm.screen_data.filter_inherit = 0;
            vm.screen_data.screen_priority = 1;
            vm.screen_data.enable_fetch = 0;
            vm.screen_data.enable_reprocess = 0;
            vm.screen_data.enable_delete = 0;
            vm.screen_data.display_issue_columns = 0;
            vm.screen_data.screen_template = "app/components/grid/grid-standard-tpl.html";
            vm.screen_data.screen_controller = "GirdStandardController as ctrl";
            vm.onSelectChange(0,null);
        }

        vm.createScreenLink = function(_val){
            vm.screen_data.screen_safe_link = (_val) ?  _val.replace(/[^0-9A-Za-z-_]/g, '-').toLowerCase() : "";
        }

        vm.checkScreenLink = function(){
            console.log("check unique screen");
        }

        vm.loadScreen = function(_screen_num){
            console.log("_screen_num: " , _screen_num);
            getData(_screen_num)
        }
        vm.getScreensTags = function(screen_key,client_key){
            var params = {
                screen_key: screen_key,
                client_key:client_key,
                //action_id: 31619
            }
            var promise = $http({
                method: "POST",
                url: GENERAL_CONFIG.admin_api_url + '/getScreenTagging',
                params:params
            }).then(function(response) {
                var data = response.data;
                if(data && data.callSuccess == 1){

                    vm.tagSearchResults = JsonObjectFactory.arrObjToLower(data.tags_list);
                     vm.allTagsList = angular.copy(vm.tagSearchResults);
                    vm.addedTags = JsonObjectFactory.arrObjToLower(data.selected_tags_list);
                    return;
                }
                else {
                    AlertService.add("error", "No users list found", 4000);
                }
               
            })
            return promise;
        }

        vm.taxTypeDetails = [];
        vm.taxType = {};
        vm.category = {};
        vm.catergozation = []
        vm.getTaxTypeData = function (selectedTaxList,selectedCategoryList) {
            console.log("Inside getTaxTypeData------------: ");
            var params = {};
            var promise = $http({
                method: "POST",
                url: GENERAL_CONFIG.admin_api_url + '/getScreenTaxCategories'
            }).then(function(response) {
                if(response.data && response.data.callSuccess == "1") {
                    var data = response.data;
                    //if(data && data.callSuccess == 1){
                        if(data.tax_types_list){
                            _.each(data.tax_types_list,function(i){
                                var obj = {}
                                obj.LABEL=i.label;
                                obj.VALUE=i.id;
                                vm.taxTypeDetails.push(obj);
                            });
                            vm.taxType ={
                                param_name: "Tax Type",
                                values: vm.taxTypeDetails,
                                label_name: "label",
                                value_name: "value",
                                label: "Tax Type",
                                selected:[]
                            }
                            if(selectedTaxList){
                                var x = selectedTaxList.split(',');
                                if(vm.taxType.values && vm.taxType.values.length>0){
                                   _.each(x,function(i){
                                       _.each(vm.taxType.values,function(y){
                                           if(y.VALUE == i){
                                               vm.taxType.selected.push(y);
                                           }
                                       })
                                   })
                                }
                            }
                        }
                        if(data.categories_list){
                            _.each(data.categories_list,function(i){
                                var obj = {}
                                obj.LABEL=i.label;
                                obj.VALUE=i.id;
                                vm.catergozation.push(obj);
                            });
                            vm.category ={
                                param_name: "Category",
                                values: vm.catergozation,
                                label_name: "label",
                                value_name: "value",
                                label: "Category",
                                selected:[]
                            }
                            if(selectedCategoryList){
                                var x = selectedCategoryList.split(',');
                                if(vm.category.values && vm.category.values.length>0){
                                   _.each(x,function(i){
                                       _.each(vm.category.values,function(y){
                                           if(y.VALUE == i){
                                               vm.category.selected.push(y);
                                           }
                                       })
                                   })
                                }
                            }
                       // }
                       
                    }   
                       console.log("category data",vm.catergozation );
                       return;
                } else {
                    AlertService.add("error", "No users list found", 4000);
                }
            });
            return promise;
            // JsonObjectFactory.saveJSON(GENERAL_CONFIG.admin_api_url + '/getScreenTaxCategories', params).then(function (data) {
            //     console.log('data data',data);
            //    /*  vm.addedTags = JsonObjectFactory.arrObjToLower(data.jsonObject);
            //     vm.tagSearchResults = vm.addedTags; */
              
                
                
            // });
            // JdcManagerFactory.getTaxTypeDetails().then(function (data) {
            //     if(data && data.data.data){
            //         _.each(data.data.data,function(i){
            //             var obj = {}
            //             obj.LABEL=i.label;
            //             obj.VALUE=i.id;
            //             vm.taxTypeDetails.push(obj);
            //         });
            //         vm.taxType ={
            //             param_name: "Tax Type",
            //             values: vm.taxTypeDetails,
            //             label_name: "label",
            //             value_name: "value",
            //             label: "Tax Type",
            //             selected:[]
            //         }
            //         if(selectedList){
            //             var x = selectedList.split(',');
            //             if(vm.taxType.values && vm.taxType.values.length>0){
            //                _.each(x,function(i){
            //                    _.each(vm.taxType.values,function(y){
            //                        if(y.VALUE == i){
            //                            vm.taxType.selected.push(y);
            //                        }
            //                    })
            //                })
            //             }
            //         }
            //     }   
			// 	   console.log("taxtype data",vm.taxTypeDetails );
			//    });
		}

        /* vm.catergozation = [];
        vm.category = {
            
                param_name: "Category",
                values: vm.catergozation = [
                    {"LABEL":"Reports","VALUE":"report"},
                    {"LABEL":"Data Ingestion","VALUE":"data_ingestion"},
                    {"LABEL":"Forms","VALUE":"forms"},
                    {"LABEL":"Schedules","VALUE":"schedules"},
                    {"LABEL":"Automation","VALUE":"automation"},
                    {"LABEL":"Mapping","VALUE":"mapping"},
                    {"LABEL":"Diagnostics","VALUE":"diagnostics"}
                ] ,
                label_name: "label",
                value_name: "value",
                label: "Category",
                selected:[]
           
        } */
        

        function getData(_version_num){
            vm.loading = true;
            var params = {};
            params.screen_key =  rowData.SCREEN_KEY;
            params.client_key = rowData.CLIENT_KEY;
            params.action_id =  78;
            params.version_num = _version_num;
            console.log("rowData Screen",rowData);
            
            return  JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                vm.loading = false;
                
                vm.getScreensTags(params.screen_key,params.client_key);
                if (data.callSuccess !== "1" && data.jsonObject && data.jsonObject[0]){
                    AlertService.add("error", data.errorMessage);
                } else {
                    
                    vm.screen_data = JsonObjectFactory.objToLower(data.jsonObject[0]);
                    vm.getTaxTypeData(vm.screen_data.screens_tax_types_list,vm.screen_data.screens_category_list);
                    if(vm.screen_data.screens_category_list){
                        var x = vm.screen_data.screens_category_list.split(',');
                        if(vm.category.values && vm.category.values.length>0){
                           _.each(x,function(i){
                               _.each(vm.category.values,function(y){
                                   if(y.VALUE == i){
                                       vm.category.selected.push(y);
                                   }
                               })
                           })
                        }
                    }

                    JdcManagerFactory.getScenarioTypeCodes().then(function(response) {
                        vm.scenarioTypeCode = response.data;
                        vm.selectedScenarioCodes = [];
                        if(vm.screen_data.scenario_type_codes){
                            var x = vm.screen_data.scenario_type_codes.split(',');
                                vm.scenarioTypeCode.forEach(i => {
                                    if(x.indexOf(i.scenario_type_code) > -1){
                                        vm.selectedScenarioCodes.push(i);
                                    }
                                });
                        }
                    });
                    
                    /* if(vm.screen_data.screens_tax_types_list){
                        var x = vm.screen_data.screens_tax_types_list.split(',');
                        if(vm.taxType.values && vm.taxType.values.length>0){
                           _.each(x,function(i){
                               _.each(vm.taxType.values,function(y){
                                   if(y.value == i){
                                       vm.taxType.selected.push(y);
                                   }
                               })
                           })
                        }
                    } */
                    JCDFactory.setObj(0,vm.screen_data.jcd_ta_key);
                    if(vm.screen_data.enable_fetch || vm.screen_data.enable_reprocess || vm.screen_data.enable_delete){
                        vm.batch_settings = true;
                    }
                    vm.jcdlist.jurisdiction = JCDFactory.lists.jurisdiction;
                    vm.jcdlist.domain = JCDFactory.lists.domain;
                    vm.jcdlist.channel = JCDFactory.lists.channel;
                    vm.jcdlist.task = JCDFactory.lists.task;
                    vm.jcdlist.activity = JCDFactory.lists.activity;
                    vm.screen_data.client={}
                    vm.screen_data.client.selected =  _.find(vm.client.list, {client_key : rowData.CLIENT_KEY+''});
                    vm.screen_data.screen_safe_link = (vm.screen_data.screen_link) ? vm.screen_data.screen_link.replace("workspace.", "") : "";
                    setRoles(vm.screen_data.jcd_ta_key,vm.screen_data.jcd_ta_role_key);
                    
                   
                    vm.selected ="";
                    for (let i=0;i<vm.tax_placemat.length;i++){
                    	if(vm.tax_placemat[i].placematId === vm.screen_data.placemat_id){
                    		vm.selected =  vm.tax_placemat[i];
                    		break;
                    	}
                    }
                }
            });
        }

       

        function  getFilterGroup(){
            var params = {};
            params.action_id =  1102;
            params.client_key = vm.client_key;
            return  JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess !== "1" && data.jsonObject && data.jsonObject[0]){
                    AlertService.add("error", data.errorMessage);
                } else {
                    vm.filter_group = JsonObjectFactory.arrObjToLower(data.jsonObject);
                    vm.filter_group.unshift({filter_group_name:"none",filter_group_key:""})
                }
            });
        }

        function  getTaxPlacematList(){
        	console.log("getTaxPlacematList");
            var params = {};
           // params.action_id =  30187;
            params.clientKey = vm.client_key;
            return  JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/getTaxPlacematList', params).then(function (data) {
            	 console.log("getTaxPlacematList data < "  , data)
                if (data.callSuccess !== "1"){
                    AlertService.add("error", data.errorMessage);
                } else {
                    vm.tax_placemat = data.data;
                }
            });
        }

        function IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        vm.cancel = function(){
            vm.screen_data.screens_refresh_list = [];
            _.filter(vm.screensByActivityList, function(item) {
                item.item_check= "";
            });
            $uibModalInstance.dismiss('cancel');
        };

        // Dropdown list will generated based on the search creteria..
        vm.tagSearch = function(string) {
            vm.searchTagText = string.toLowerCase();
            if(vm.searchTagText && vm.searchTagText.length > 0) {
                vm.tagSearchResults = _.filter(vm.allTagsList, function(itr) {
                    var label = itr["tag_name"].toLowerCase();
                    return label.indexOf(vm.searchTagText)!== -1;
                });
            } else {
                vm.tagSearchResults = vm.allTagsList;
            }
        
        }

        // Tag Items selected from tag search dropdown list..
        vm.selectTagItem = function(selectedItem) {
            vm.searchTagText = selectedItem.tag_name;
        }


        // Method to add searched tags.. 31623
		vm.addTags = function(inputTagText) {
            vm.hasSameTag = false;
            if(vm.addedTags && vm.addedTags.length > 0) {
                _.forEach(vm.addedTags, function(eachTagSearch) {
                    if(inputTagText == eachTagSearch.tag_name) {
                        vm.hasSameTag = true;
                    }
                });
            }

            if(vm.hasSameTag != true) {
                vm.tagBtnLoading = true;
                vm.addedTags.push({"tag_name": inputTagText,"tag_desc": ""});
                vm.searchTagText = "";
                var url = GENERAL_CONFIG.base_url +"/saveJsonObject?action_id=31623"
                var params = {
                    screen_key: vm.screen_data.screen_key,
                    data: JSON.stringify(vm.addedTags),
                    client_key: USER_SETTINGS.user.client.client_key
                }
            JsonObjectFactory.saveJSON(url,params).then(function(data){
               if(data && data.callSuccess == 1){
                vm.getScreensTags(params.screen_key, params.client_key);
                vm.tagBtnLoading = false;
                   AlertService.add("success","Tag Saved Successfully",4000);
               }else{
                   AlertService.add("error",data.errorMessage,4000);
               }
            });
            } else {
                AlertService.add("warning", "Searched/Selected Tag already exist",4000);
            }
            
        }
        
        vm.deleteTags = function(index,tag) {
            vm.removeTags = [];
            vm.addedTags.splice(index, 1);
            vm.removeTags.push({"tag_key": tag.tag_key,"tag_name":tag.tag_name,status:0});
            vm.searchTagText = "";
            var url = GENERAL_CONFIG.base_url +"/saveJsonObject?action_id=31623"
            var params = {
                screen_key: vm.screen_data.screen_key,
                data: JSON.stringify(vm.removeTags),
                client_key: USER_SETTINGS.user.client.client_key
            }
            JsonObjectFactory.saveJSON(url,params).then(function(data){
               if(data && data.callSuccess == 1){
                   AlertService.add("success","Tag Removed Successfully",4000);
               }else{
                   AlertService.add("error",data.errorMessage,4000);
               }
            });
           
        }

        vm.save = function(){
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            //CHECK THAT API IS VALID JSON
            if (vm.screen_data.api && vm.screen_data.api.length && !IsJsonString(vm.screen_data.api)) {
                AlertService.add("error", "The Field 'API Calls' doesn't contain valid json", 4000);
                return false;
            }

            if (vm.screenFrom.$invalid) {
                vm.screenFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            vm.crudLoading = true;
            var data =  {};
            data.screen_key  = (vm.screen_data.screen_key) ?  vm.screen_data.screen_key : "";
            vm.screen_data.client_key  = vm.client_key;
            vm.screen_data.screens_refresh_list = _.map(vm.screen_data.screens_refresh_list).join(',');
            data.activity_key  =  vm.jcdlist.activity.selected.value;
            vm.screen_data.jcd_ta_role_key = vm.roles.selected.jcd_ta_role_key;
            vm.screen_data.screen_url = "/"+ vm.screen_data.screen_safe_link;
            vm.screen_data.fetch_role_key = !!vm.others_access ? vm.others_access.jcd_ta_role_key : null;
            vm.screen_data.reprocess_role_key = !!vm.allow_reprocess ? vm.allow_reprocess.jcd_ta_role_key : null;
            vm.screen_data.delete_role_key = !!vm.delete_upload ? vm.delete_upload.jcd_ta_role_key : null;
            if(vm.selectedScenarioCodes.length === 0){
                vm.screen_data.scenarioTypeCodes = 'RAF';
            } else {
                vm.screen_data.scenarioTypeCodes = [].concat(vm.selectedScenarioCodes.map(i => i.scenario_type_code)).join(",");
            }

            if(vm.selected==null || vm.selected==""){
           	 vm.screen_data.placematid= "";
           }else{
           	 vm.screen_data.placematid= vm.selected.placematId;
           }
           console.log("vm.screen_data :::>>>>>>" , vm.screen_data);

            if( vm.screen_data.screen_type !== 'custom'){
                vm.screen_data.screen_link = "workspace."+ vm.screen_data.screen_safe_link;
            }else{
                vm.screen_data.screen_link = vm.screen_data.screen_safe_link;
            }
            vm.screen_data.screens_tax_types_list = [];
            vm.screen_data.screens_category_list = [];
            if(vm.taxType){
                vm.screen_data.screens_tax_types_list = _.map(vm.taxType.selected,'VALUE').join(',');
            }
            if(vm.category){
                vm.screen_data.screens_category_list = _.map(vm.category.selected,'VALUE').join(',');
                
            }

            console.log(" vm.screen_data.screen_link  " ,  vm.screen_data.screen_link );

            data.json_string = JSON.stringify(vm.screen_data);

            JsonObjectFactory.saveJSON(GENERAL_CONFIG.admin_api_url + '/saveScreen', data).then(function (data) {
                console.log("data < "  , data)
                vm.crudLoading = false;
                if (data.callSuccess == "0"){
                    vm.crudLoading = false;
                    AlertService.add("error", data.errorMessage);
                } else {
                    AlertService.add("success", "Screen has been saved", 2000);
                    $rootScope.$emit('gridUpdate', {});
                    vm.selectedScenarioCodes = [];
                    vm.cancel();
                }
            });
        };
    }

    function screenListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,$parse,ClientAdminFactory,GENERAL_CONFIG,USER_SETTINGS) {

    	console.log('inside screenListCtrl');
        var vm = this;
        vm.userSettings = USER_SETTINGS;
        vm.client_key = vm.userSettings.user.client.client_key;
        vm.name = "Screen List";
        vm.filter = {
            client_key:vm.client_key
        };

        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });

        $scope.$on('$destroy', function iVeBeenDismissed() {
            console.log("SCREEN WAS THIS DESTROYED ")
            gridUpdateListener();
        });

        vm.refresh = function(_params){
            getData();
        };

         // get the clientList initail load of page
		/*vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
                // vm.clients = $parse('data.clientResult.clientList')(res);
            });
        };
        vm.getClientList();

        vm.onClientChange = function() {
            getData();
        };*/

        vm.addScreen = function(){
            var data = {};
            var modalObj = {};
            modalObj.template = "app/components/admin/screens/screen-tpl.html";
            modalObj.controler = "screenCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,data);
        }
        

        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("data : ",data);
                    $scope.$broadcast("dataLoaded", data);

                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        var getUpDate = function(_params,_filterObj) {
            var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
            params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("data" , data);
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $timeout(function() {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);

        $scope.ctrlFn = function(_callName,_data,_type) {
            var modalObj = {};
            modalObj.template = "app/components/admin/screens/screen-tpl.html";
            modalObj.controler = "screenCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,_data);
        };

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
    };

    return controllers;
});
