define([
    'angular',
    './extEntityMaintenanceControllers',
    './extEntityMaintenanceServices'


], function () {
    'use strict';
    return angular.module('app.extEntity', ['app.extEntityMaintenanceControllers','app.extEntityMaintenanceServices'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {


        }]);

});