define([
    'angular'

], function () {
    'use strict';

    var directives =  angular.module('app.gridOnModalDirective',[])
    .directive('dfGgGridModal', ['$window','$log', 'DataFiltersFactory','JsonObjectFactory','AlertService', 'workspaceFactory', function($window, $log, DataFiltersFactory,JsonObjectFactory,AlertService,workspaceFactory) {
        return {
            restrict: 'EA',
            transclude: true,
            controllerAs: 'ctrl',
           templateUrl: 'app/components/grid/gridOnModal/grids-onModaltpl.html',
            scope: {
                params: '=',
                url: '='
            },
            controller: ['$scope', '$rootScope','GridFactory','GENERAL_CONFIG','$timeout', function ($scope, $rootScope,GridFactory,GENERAL_CONFIG,$timeout) {
                var vm = this;
                $timeout(function() {
                    getData();
                }, 5),
        
                vm.api = GENERAL_CONFIG.base_url + $scope.url
                $scope.ctrlFn = function(_callName,_data,_type) {
        
                    if(_type === 'columnTemplate'){
                        GridSaveFactory.colTemplatesUpdate($state.current.api.grid_id,_callName,_data);
                    }else if(_callName === 'userDetail'){
                        _data = {user_id:_data.rowData.SSO_ID,selected_client_key:_data.rowData.CLIENT_KEY }
                        $state.go('admin.users-list.user.details' ,  {data :_data });
                    }else if( _callName === 'userDetailModule' ) {
                        _data = {user_id:_data.rowData.SSO_ID }
                        $state.go('admin.users-list-module.user.details' ,  {data :_data });
                    }
        
                }
                var getData = function() {
                    $scope.$broadcast("showLoader", {isLoader:true,isFromModal:true});
                    vm.loading = true;
                    vm.gridData = [];
                    vm.gridData.DATA = [];
                    GridFactory.getDataCustom(vm.api, $scope.params).then(function (data) {
                    if (data.callSuccess === "1") {
                            vm.data = data;
                            data.fromModal = true;
                            $scope.$broadcast("dataLoaded", data);
                            $scope.$broadcast("showLoader", {isLoader:false,isFromModal:true});
                        } else {
                            AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                        }
                    });
                }
                $scope.openModal = function (modalObj,_data) {
                    $scope.rowData = _data.rowData;
                    $scope.gridData = _data;
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: modalObj.template,
                        controller: modalObj.controler,
                        size: modalObj.size,
                        backdrop: modalObj.backdrop,
                        keyboard: modalObj.keyboard,
                        resolve: {
                            rowData: function () {
                                return $scope.rowData;
                            },
                            gridData: function () {
                                return $scope.gridData;
                            }
                        }
                    });
        
                    modalInstance.result.then(function (selectedItem) {
                        $scope.selected = selectedItem;
                    }, function () {
                        $log.info('Modal dismissed at: ' + new Date());
                        $log.info('$scope.rowData' , $scope.rowData);
                    });
                };
                /* $scope.$on('$destroy', function () {
                        $log.log("Unregistering listener");
                        gridUpdateListener();
                 }); */
            }],
         

            link: function($scope, element, attrs) {

            }
        };
    }])
    return directives;

});
