
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.ApprConfigService',[])

    .factory('ApprConfigFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var apprConfigFactory = {};
        apprConfigFactory.url = {};
        apprConfigFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=451s6n";       
          
        apprConfigFactory.saveAppConfig = function(_settings, _data){
        	 	var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
             
        		//var params = {};
        	 	var params = filterParams;
        		var jsonObj = JSON.stringify(_data);
                var jsonSettings =  JSON.stringify(_settings);
              
        		params = _.merge({},  GlobalService.globalParams , params);
        		params = _.extend({clob_data:jsonObj}, params);
        		params = _.extend({clob_settings:jsonSettings}, params);		  
        		
        		console.log("apprConfigFactory......params::",params);        
        		
        		var promise = $http({
                    method: "post",
                    url: apprConfigFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
        		
        	
        	
        	return promise;
        } 
        
        return apprConfigFactory;
    }])

    return services;

});