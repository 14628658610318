define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.reportHomeController',[])
        .directive('captureCustomEvents', ['$document', '$timeout', '$window', 'workspaceFactory', function ($document, $timeout, $window, workspaceFactory) {
            return {
            restrict: 'A',
            link: function (scope, $element, attr) {
                $element.bind('load-screen', function(event) {
                    let screen = event.originalEvent.detail;
                    workspaceFactory.loadLink("link", "{}",screen.activity_key+'',screen.screen_key+'');
                       let breadcrumbArray = []; 
                            function buildBreadCrumbData(val) {
                                breadcrumbArray.push(val.label);
                                if (val.parent) {
                                    buildBreadCrumbData(val.parent)
                                } else {
                                    breadcrumbArray.reverse();
                                }
                            } 
                            buildBreadCrumbData(workspaceFactory.activeScreen);
                            console.log('breadcrumbArray',breadcrumbArray);
                             $('gtw-header-ce').attr('bread-crumb', JSON.stringify(breadcrumbArray));

                    event.stopPropagation();
                });
            }
            };
        }])
        .controller('reportModuleController', ['USER_MENU','$rootScope','$scope', '$http', 'GlobalService', 'ModalFactory', 'AlertService','JsonObjectFactory','$timeout','ReportableTransactionFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory', reportModuleController])


        function reportModuleController(USER_MENU,$rootScope, $scope, $http, GlobalService, ModalFactory, AlertService, JsonObjectFactory,$timeout,ReportableTransactionFactory,USER_SETTINGS,GENERAL_CONFIG,workspaceFactory){
            
            var vm =this;
            const currentUser = USER_SETTINGS.user;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
            vm.clientKey = currentUser.client_key;
            var activeScreen = workspaceFactory.activeScreen;
            vm.globalParams = GlobalService.globalParams.jcd_key
            vm.activeScreensList = [];
            vm.listOfActiveScreens = []

            vm.tax_year = GlobalService.globalParams.tax_year;
			vm.scenario = GlobalService.globalParams.scenario;
            
            function getScreens(_collection,_children){
                for (var i = 0; i < _collection.length; i++) {
                    var thisChildren =  _collection[i][_children];
                    if( thisChildren && thisChildren.length ){
                        getScreens(thisChildren,_children);
                    }
                    for(var n in thisChildren){
                       _.each(thisChildren[n].screens,function(ss,kk){
                            if(ss.data && ss.data.data_size){
                               var obj = {"screen_key":ss.screen_key,"record_count":ss.data.data_size};
                                vm.activeScreensList.push(obj);
                            }
                       })
                    }
                }

               
            }
            
           
            getScreens(USER_MENU,'children');
            vm.listOfActiveScreens = JSON.stringify(vm.activeScreensList);

            vm.user = JSON.stringify({sso:currentUser.sso_id,role:currentUser.user_type});


        }
        return controllers;
    })