define([
        'angular'
    ], function () {
        'use strict';
        var app = angular.module('app.Section351ReportController', []);
        app.controller('Section351ReportCtrl', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'SERVER_CONFIG', 'workspaceFactory', 'SystemLockUnlockFactory', section351ReportCtrl])

        function section351ReportCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, workspaceFactory, SystemLockUnlockFactory) {
            var vm = this;
            vm.webComponentHtml = '';
            vm.clientKey = GlobalService.getClient().client_key;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
            vm.baseURLs.pdfEngine = GENERAL_CONFIG.pdf_engine_api_url;
            vm.baseURLs.bulkPdfEngine = GENERAL_CONFIG.bulk_pdf_engine_api_url;
            vm.s3uploadButtonAccessUsers = SERVER_CONFIG.configSettings.dst_pdf_gen_users ? SERVER_CONFIG.configSettings.dst_pdf_gen_users.split(',') : [];
            //get system lock status
            SystemLockUnlockFactory.getDSTSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;
                    
                    vm.inputParam = {
                        clientKey: vm.clientKey,
                        baseURLs: vm.baseURLs,
                        ssoId: USER_SETTINGS.user.sso_id,
                        roleLevel: workspaceFactory.activeScreen.parent ? workspaceFactory.activeScreen.parent.access_level : undefined,
                        systemLockStatus: vm.isLocked,
                        taxYear: GlobalService.globalParams.tax_year,
                        scenario: GlobalService.globalParams.scenario,
                        s3uploadButtonAccessUsers: vm.s3uploadButtonAccessUsers
                    };
                    const html = `<gtw-sec351-report gtw-param="{{ctrl.inputParam}}" class="gtw-web-components"></gtw-sec351-report>`;
                    vm.webComponentHtml = html;
                }
            );
        }

        return app;
    }
);
