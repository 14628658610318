define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.OrgChartController',[])
        .controller('OrgChartController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData' ,'JsonObjectFactory','$timeout', orgChartController])

    function orgChartController($rootScope,$scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData ,JsonObjectFactory,$timeout) {


        var vm = this;
        vm.title = "(" + rowData.LEGAL_ENTITY_ID + ")" + " - " + rowData.LEGAL_ENTITY_NAME;

        console.log("rowData: " , rowData);

                //LEGAL_ENTITY_ID: "AB0003"


    }


    return controllers;

});
