
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.retriggerGroupSyncService',[])

    .factory('retriggerGroupSyncServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var retriggerGroupSyncServiceFactory = {};
        
        retriggerGroupSyncServiceFactory.saveretriggerGroupSync = function(params){
            var params = params;
            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + '/retriggerGroupSync?client_key=' + params.client_key + '&tax_year=' + params.tax_year + '&groups=' + params.groups,
                //data: params
            }).then(function (response) {
                return response;
            });
            return promise;
        };

        retriggerGroupSyncServiceFactory.retriggerSync = function(tax_year, groups) {
              
            var promise = $http({
                method: "POST",
                url: GENERAL_CONFIG.base_url + '/reTriggerGroupSync?tax_year=' + tax_year + '&groups=' + groups ,
                //data: params
            }).then(function(response) {
                return response;
            });
            return promise;
        }
        
        return retriggerGroupSyncServiceFactory
    }])

    return services;

});