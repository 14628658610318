define([
    'angular',
    './ReclassTrigger163jController',
    './ReclassTriggerService'



], function () {
    'use strict';
    return angular.module('app.163jCalcReclassTrigger', ['app.ReclassTrigger163jController','app.ReclassTriggerService'] );


});