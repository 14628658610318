
define([
    'angular'

], function() {
    'use strict';

    angular.module('app.cbcBepsAdmin', [])
        .controller('cbcBepsAdminCategories', ['GENERAL_CONFIG', 'gridData', 'USER_SETTINGS', '$uibModalInstance', 'ModalFactory', '$rootScope','$scope', cbcBepsAdminCategories])
        .controller('cbcBepsAdminQuarters', ['GENERAL_CONFIG', 'gridData', 'USER_SETTINGS', '$uibModalInstance', 'ModalFactory', '$rootScope', 'TAX_YEARS','$scope', cbcBepsAdminQuarters])

    function cbcBepsAdminCategories(GENERAL_CONFIG, gridData, USER_SETTINGS, $uibModalInstance, modalFactory, $rootScope, $scope) {

        const vm = this;
        vm.currentTheme = 'theme-white';
        const rowData = gridData.length > 0 ? gridData[0].data : undefined;

        vm.input = JSON.stringify({
            baseUrl: GENERAL_CONFIG.base_url,
            ssoId: USER_SETTINGS.user.sso_id,
            clientKey: rowData ?
                ((rowData.S3_CLIENT_KEY === null || rowData.S3_CLIENT_KEY === undefined) ? '' : rowData.S3_CLIENT_KEY)
                : USER_SETTINGS.user.client_key,
            data: rowData,
            type: modalFactory.response.data.modalScreen.modal_name === 'deleteBepsCategory' ? 'delete' : 'add'
        });

        vm.close = function() {
            $uibModalInstance.close();
            $rootScope.$broadcast('dataFilters:refreshGrid', {
                "refresh": true
            });
        }

        const listener = $rootScope.$on('close-modal', function() {
            vm.close();
        })

        $scope.$on('$destroy',function (){
            listener();
        })
    }
    function cbcBepsAdminQuarters(GENERAL_CONFIG, gridData, USER_SETTINGS, $uibModalInstance, modalFactory, $rootScope, TAX_YEARS,$scope) {

        const vm = this;
        vm.currentTheme = 'theme-white';
        const rowData = gridData.length > 0 ? gridData[0].data : undefined;

        vm.input = JSON.stringify({
            baseUrl: GENERAL_CONFIG.base_url,
            ssoId: USER_SETTINGS.user.sso_id,
            clientKey: rowData ?
                ((rowData.S3_CLIENT_KEY === null || rowData.S3_CLIENT_KEY === undefined) ? '' : rowData.S3_CLIENT_KEY)
                : USER_SETTINGS.user.client_key,
            data: rowData,
            type: modalFactory.response.data.modalScreen.modal_name === 'updateBepsAdminQuarter' ? 'edit' : 'add',
            taxYears: Object.values(TAX_YEARS).map(item => { return { label: item.label, value: item.value } })
        });

        vm.close = function() {
            $uibModalInstance.close();
            $rootScope.$broadcast('dataFilters:refreshGrid', {
                "refresh": true
            });
        }

        const listener = $rootScope.$on('close-modal', function() {
            vm.close();
        })

        $scope.$on('$destroy',function (){
            listener();
        })
    }
})
