define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.consolidationTrialBalanceCtrl', []);
    var controllers = module.controller('consolidationTrialBalanceMainCtrl',
    		['$state','GridFactory','ModalFactory','$uibModalInstance','menuDataService','workspaceFactory','WorkflowTCFactory','$uibModal','GlobalService', 'AlertService', '$timeout', '$window', '$scope','JsonObjectFactory','rowData','$parse', '$rootScope', 'GENERAL_CONFIG', consolidationTrialBalanceMainCtrl]);
		function consolidationTrialBalanceMainCtrl($state,GridFactory,ModalFactory,$uibModalInstance,menuDataService,workspaceFactory, WorkflowTCFactory, $uibModal, GlobalService, AlertService, $timeout, $window, $scope,JsonObjectFactory,rowData, $parse, $rootScope, GENERAL_CONFIG) {
			var vm = this;
			vm.rowData= rowData;
			vm.loading = false;
			vm.showData = false;
			vm.name = "consolidationTrialBalanceMainCtrl";
			//$scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
			$scope.grid_template = 'app/components/grid/grid-standard-tpl.html';
			vm.showGrid = true;
			vm.loading = false;			
		    vm.api = $state.current.api.get;
		    vm.data = [];
		    vm.showGrid = true;	       
		       
		    vm.viewGrid = GENERAL_CONFIG.base_url + '/loadGridJson?action_code=jktdjh&grid_id=293';
		    refreshData();
			
				
		    vm.cancel = function () {
			        $uibModalInstance.dismiss('cancel');	
			        };
			
		

			$scope.$on('dataFilters:refreshGrid', function (event, args) {
				refreshData();
			});
		
			function refreshData() {
				vm.loading = true;
		           $scope.$broadcast("showLoader", true);
		           vm.loading = true;
		           vm.gridData = [];
		           vm.gridData.DATA = [];
		           vm.showGrid = true;
		           var firHoLeidFilter = {};
		           firHoLeidFilter.HO_LEID = vm.rowData.HO_LEID;
		           firHoLeidFilter.HO_CDR_NO = vm.rowData.HO_CDR_NO;
		           firHoLeidFilter.HO_REPORTING_PERIOD = vm.rowData.HO_REPORTING_PERIOD;
		           firHoLeidFilter = JSON.stringify(firHoLeidFilter);
		           var args = { "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
		              		  "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
		              			"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
            					"screen_key":GlobalService.globalParams.screen_key,
		        		       "filing_key":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.filing_key :workspaceFactory.activeScreen.filters.filterParams.filing_key,
		        		       "fir_ho_leid":workspaceFactory.activeScreen.filters === undefined 
		        		       ? "" : firHoLeidFilter
		           }
		           GridFactory.getDataCustom(vm.viewGrid,args).then(function (data) {
		               if (data.callSuccess === "1") {
		            	   vm.loading = false;
		            	vm.data = data.data;
		            	var dynamic_columns = (vm.data[0].DYNAMIC).split(',');
		            	var headerLength = data.header.length;
		            	var maxHeaderKey = parseInt(data.header[headerLength-1]['grid_header_key']);
		            	var gridKey = parseInt(data.header[headerLength-1]['grid_key']);
		            	var priority = data.header[headerLength-1]['priority'];
		                for(var i=0; i <dynamic_columns.length; i++) {
		                	data.header.push({apply_rules: "0", audit_cells: null, call_back: null, col_class: "right-align", data_api: null, data_type: "string",
		                						display: "y", drilldown: null, edit_icon: null, edit_rule_message: null, edit_rules: null, edit_type: null,
		                						editable: "n", format:"numberWithCommas", full_label: null, grid_header_key: maxHeaderKey+1, grid_key: gridKey, header_class: "right-align", label: dynamic_columns[i].replace(/_/g,'/'),
		                						map: dynamic_columns[i], new_row_editable: "0",  priority: priority+1, required: null, static_values: null, validation: null, width: 210});

						}
		                   $scope.$broadcast("dataLoaded", data);
		                   $scope.$broadcast("showLoader", false);
		               } else {
		                   AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
		               }
		           });
		       }

		
			
			/////////////////////////////////////////
			
			 
		}
});
