/**
 * Created by Mallesh Nagothi on 10/11/2018.
 */


define([
    'angular'

], function () {
    'use strict';

    var directives =  angular.module('app.ruleEngineConfigDirectives',[])
    .directive('gtRuleEngineConfigTree', ['$compile','$parse','$state','$injector','AlertService', function( $compile,$parse,$state,$injector,AlertService) {
        return {
            restrict: 'A',
            scope:{
                menuData: '=',
                userId: '=',
                loading: '=',
                clientKey: '=',
                callBackService: '='
            },
           controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

			}],

            link:function ( scope, element, attrs, ctrl ) {
            	var mySelf = this;
            	scope.isAddNewAction = false;
                scope.callBackService    = attrs.callBackService;
                var proxyService         = $injector.get(scope.callBackService);
                console.log("proxyService ::",proxyService);
                console.log("Elements CTRL :",element);

                scope.callDatabase = function(data){
                	console.log("my data @@@@@@@@@ :",data);
                	var success_msg;
                	var fail_msg;
                	if(data.success_msg){
                		success_msg = data.success_msg;
                	}
                	if(data.fail_msg){
                		fail_msg = data.fail_msg;
                	}

                	 var promise = proxyService.linkDelinkRuleAction(data).then(function (data) {
               		    var callStatus = parseInt(data.callSuccess);
                         if(callStatus === 1 ){
                             console.log("data", data);
                             if(success_msg){
                            	 AlertService.add("success", success_msg, 4000);
                             }
                             return data;
                         }else if(callStatus === 0){
                         	console.log("data", data);
                         	if(fail_msg){
                         		AlertService.add("error", fail_msg, 4000);
                         	}
                         	return data;
                         }
                         else {
                             if(data.errorMessage === "no access"){
                                 AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                             }else{
                                 AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                             }
                         }
                     });
                	 return promise;
                }


                scope.addNewAction = function(node){
                    console.log("isAddNewAction() node :",node);
                    if(node.is_new_action_clicked === 1){
                    	node.is_new_action_clicked = 0;
                    }else{
                    	node.is_new_action_clicked = 1;
                    	node.is_new_cache_clicked = 0;
                    }
                };

                scope.addNewCache = function(node){
                    console.log("addNewCache() node :",node);
                    if(node.is_new_cache_clicked === 1){
                    	node.is_new_cache_clicked = 0;
                    }else{
                    	node.is_new_cache_clicked = 1;
                    	node.is_new_action_clicked = 0;
                    }
                };


                /**Links or DeLinks Valiation Action Rule **/
                scope.linkDelinkAction = function(ruleAction,node,indx){
                    console.log("linkDelinkAction() ruleActionKey :",ruleAction.action_validation_key);
                    console.log("linkDelinkAction() currStatus :",ruleAction.action_validation_status);
                    var data = {};
                    data.rule_action_key = ruleAction.action_validation_key;
                    if(parseInt(ruleAction.action_validation_status)===1){
                    	data.lnk_or_d_lnk = 0;
                    }else{
                    	data.lnk_or_d_lnk = 1;
                    }
                    data.url = "nrvl3o";
                    data.success_msg = "Link/Delink Action Successfully Completed";
                    data.fail_msg = "Link/Delink Action Failed ";

                    scope.callDatabase(data).then(function (data) {
                        console.log("data ::",data);
                     	if(data){
                     		node.validation_rule_actions[indx] = data.jsonObject[0];

                     	}
                     });
                };


                /**Links or DeLinks Validation Rule  -----------------siddartha**/
                scope.linkDelinkValidationRule = function(node,indx){
                	console.log("linkDelinkValidationRule Node++++++" ,node)
                    console.log("linkDelinkValidationRule() validation_rule_key :",node.validation_rule_key);
                    console.log("linkDelinkValidationRule() currStatus :",node.validation_rule_status);
                    var data = {};
                    data.url = "ivvzah";
					//data.client_key = "1";
					data.client_key = scope.clientKey;
                    data.validation_key = node.validation_rule_key;
                    if(node.validation_rule_status == "Yes"){
                    	data.lnk_or_d_lnk = 0;
                    }else{
                    	data.lnk_or_d_lnk = 1;
                    }
                    data.success_msg = "Link/Delink Action Successfully Completed";
                    data.fail_msg = "Link/Delink Action Failed ";

                    scope.callDatabase(data).then(function (data) {
                        console.log("data :::::::::::::::",data);
                     	if(data){
                     		node[indx] = data.jsonObject[0]; // need to check
                     		//To change the status
                     		node.validation_rule_status = data.jsonObject[0].validation_rule_status;
                     	}
                     });
                };


                //
                /**Deletes Cache Obj Mapping **/
                scope.removeCacheObjMpng = function(cahceObj,node,indx){
                    console.log("removeCacheObjMpng() cahceObj.drools_cache_key :",cahceObj.drools_cache_key);
                    console.log("removeCacheObjMpng() node.validation_rule_key :",node.validation_rule_key);
                    var data = {};
                    data.drools_cache_key = cahceObj.drools_cache_key;
                    data.validation_rule_key = node.validation_rule_key;
                    data.url = "cl2s8u";
                    data.success_msg = "Cache Obj Mapping Successfully Removed";
                    data.fail_msg = "Cache Obj Mapping Failed to Remove ";

                    scope.callDatabase(data).then(function (data) {
                        console.log("data ::",data);
                     	if(data){
                 			node.validation_rule_cache_objects.splice(indx,1);
                     	}
                     });
                };

                scope.searchAction = function(node){
                	console.log("searchAction()  :",node);
                    var data = {};
                    data.new_action_key = node.new_action_key;
                    data.url = "kxbm2n";
                    data.action_key = node.new_action_key;
				  //  data.client_key = 1;
				  data.client_key = scope.clientKey;
                    data.validation_rule_key = node.validation_rule_key;
                    if(angular.isUndefined(node.validation_rule_actions)){
                    	node.validation_rule_actions = [];
                    }

                    scope.callDatabase(data).then(function (data) {
                        console.log("data ::",data);
                        if(data && data.callSuccess==="1"){
                        	if(data.jsonObject){
                        		if(data.jsonObject[0].action_validation_key>0){
                         			node.validation_rule_actions.push(data.jsonObject[0]);
                         			console.log("jsonobj-------------------------",data);
                         			node.is_new_action_clicked = 0;
                         		}
                        	}
                     	}else if(data && data.callSuccess==="0"){
                     		AlertService.add("warn", data.errorMessage, 4000);
                     	}
                     });
                }

                scope.searchCache = function(node){
                	console.log("searchCache()  :",node);
                    var data = {};
                    data.new_cache_key = node.new_cache_key;
                    data.url = "g1pp14";
                    data.cache_key = node.new_cache_key;
				   // data.client_key = 1;
				   data.client_key = scope.clientKey;
                    data.validation_rule_key = node.validation_rule_key;
                    if(angular.isUndefined(node.validation_rule_cache_objects)){
                    	node.validation_rule_cache_objects = [];
                    	}
                    scope.callDatabase(data).then(function (data) {
                        console.log("searchCache() response data ::",data);
                        if(data && data.callSuccess==="1"){
                        	if(data.jsonObject){
                        		if(data.jsonObject[0].cache_key>0){
                         			node.validation_rule_cache_objects.push(data.jsonObject[0]);
                         			node.is_new_cache_clicked = 0;
                         		}
                        	}
                     	}else if(data && data.callSuccess==="0"){
                     		AlertService.add("warn", data.errorMessage, 4000);
                     	}
                     });
                }

                /////******** Create new Rule***********/////

                scope.createNewRule = function(node,menuData){
                	console.log("createNewRule()  :",node);
                    var data = {};
                    data.url = "dszxm0";
					//data.client_key = "1";
					data.client_key = scope.clientKey;
                    data.clobdata = [];
                    if((angular.isDefined(node.new_rule_name) && node.new_rule_name != '')  ) {

                      if ((angular.isDefined(node.new_rule_path) && node.new_rule_path != '')) {

                    var payLoadForSaving = [{RULE_NAME:node.new_rule_name,RULE_DESC:node.new_rule_desc,RULE_PATH:node.new_rule_path,RULE_ACTIVE_FLAG:"1"}];

    	            data.clob_data = JSON.stringify(payLoadForSaving);

                    scope.callDatabase(data).then(function (data) {
                        console.log("createNewRule() response data ::",data);
                        if(data && data.callSuccess==="1"){
                        	if(data.jsonObject){
                        		menuData.unshift(data.jsonObject[0]);
                         		node.is_new_rule_clicked = 0;

                        	}
                        	AlertService.add("success","New Validation Rule Created Successfully", 4000);
                     	}else if(data && data.callSuccess==="0"){
                     		AlertService.add("error", "Unable to Create New Validation Rule", 4000);
                     	}
                     });
                    } else {
                    	AlertService.add("error", "Please enter Rule Path ", 4000);
                      }
                    }
                    else {
                    		AlertService.add("error", "Please enter Rule Name ", 4000);
                    	}
                    	console.log("Node", node)

                }
                /////********* Update rule*************////////////

                scope.updateRule = function(node, menuData){
                	console.log("updateRule()  :",node);
                    var data = {};
                    data.url = "yublv5";
				  //  data.client_key = "1";
				  data.client_key = scope.clientKey;
                    data.clobdata = [];
                    if((angular.isDefined(node.new_rule_name) && node.new_rule_name != '')  ) {

                        if ((angular.isDefined(node.new_rule_path) && node.new_rule_path != '')) {

                    var payLoadForSaving = [{RULE_KEY: node.validation_rule_key,RULE_NAME:node.new_rule_name,
                    						RULE_DESC:node.new_rule_desc,RULE_PATH:node.new_rule_path,RULE_ACTIVE_FLAG:"1"}];

    	            data.clob_data = JSON.stringify(payLoadForSaving);

                    scope.callDatabase(data).then(function (data) {
                        console.log("updateRule() response data ::",data);
                        if(data && data.callSuccess==="1"){
                        	angular.forEach(menuData, function(value, key) {
                  			  if(value.validation_rule_key === node.validation_rule_key){
                  				value.validation_rule_name = node.new_rule_name;
                  				value.validation_rule_desc = node.new_rule_desc;
                  				value.validation_rule_package_path = node.new_rule_path;
                  			  }
                  		});
                        	AlertService.add("success","Validation Rule updated", 4000);
                        	scope.enableNewRule(node);
                     	}else if(data && data.callSuccess==="0"){
                     		AlertService.add("error", "Unable to update Validation Rule", 4000);
                     	}
                     });
                    }
                    else {
                        	AlertService.add("error", "Please enter Rule Path ", 4000);
                          }
                        }
                        else {
                        		AlertService.add("error", "Please enter Rule Name ", 4000);
                        	}
                        	console.log("Node", node)
                        }



                scope.enableNewRule = function(node, editMode){
                	if (editMode || node.editMode) {
                		console.log()
                		node.editMode = !node.editMode || false;
                		node.new_rule_name = node.validation_rule_name;
                    	node.new_rule_desc = node.validation_rule_desc;
                    	node.new_rule_path = node.validation_rule_package_path;
                	} else {
                		node.editMode = false;
                		node.new_rule_name = '';
                		node.new_rule_desc = '';
                		node.new_rule_path = '';
                	}
                	node.is_in_edit_mode = editMode;
                	if(node.is_new_rule_clicked===1){
                		node.is_new_rule_clicked = 0;
                	}else{
                		node.is_new_rule_clicked = 1;
                	}
                }



           	    var template= '' +
		            '<div class="panel-group" id="accordion"> ' +
				    '<div data-ng-repeat="node in menuData" class="panel gt-dark-header"> ' +
				        '<div class="tree-container-heading"> '+
					        '<h5> '+
					        '<a data-toggle="collapse" data-parent="#accordion" href="#{{node.validation_rule_key}}"> '+
					         '<b>{{node.validation_rule_name}}</b> '+
					         '</a> '+
					        '</h5> '+
				        '</div><hr> '+
				        '<div id="{{node.validation_rule_key}}" class="panel-collapse collapse in"> ' +
					        '<div class="tree-container-body"> '+
						        '<table class="table table-striped table-sm" style="font-size: 9pt;"> '+
								        '<thead><tr> '+
								        '<th> '+'Rule Key '+'</th> '+
								        '<th> '+'Rule Name '+'</th> '+
								        '<th> '+'Rule Description '+'</th> '+
								        '<th> '+'Rule Path '+'</th> '+
								        '<th> '+'Authored By '+'</th> '+
								        '<th> '+ 'Is Active ? '+'</th> '+
								        '<th> '+'Authored On '+'</th> '+
								        '<th> '+'Actions '+'</th> '+
							        '</tr></thead> '+
							        '<tbody> '+

								    '<tr> '+
							        '<td> '+'{{node.validation_rule_key}}'+'</td> '+
							        '<td> '+'{{node.validation_rule_name}}'+'</td> '+
							        '<td> '+ '{{node.validation_rule_desc}}'+'</td> '+
							        '<td> '+ '{{node.validation_rule_package_path}}'+ '</td> '+
							        '<td> '+'{{node.validation_rule_author}}'+'</td> '+
							        '<td> '+'{{node.validation_rule_status}}'+'</td> '+
							        '<td> '+ '{{node.validation_rule_authored_on}}'+
							      							        '<td> '+
							        /*'<i class="fa fa-eye fa-xs" uib-tooltip="View Rules" tooltip-placement="left" tooltip-trigger="mouseenter"></i>&nbsp;&nbsp; '+*/
							        /*'<i class="fa fa-bullseye fa-xs" uib-tooltip="Delete Rule" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+*/
							        '<i ng-if="node.validation_rule_status==\'Yes\'" class="glyphicon glyphicon-remove-sign text-danger" ng-click="linkDelinkValidationRule(node,$index)" uib-tooltip="Inactive Rule" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+
				                    '<i ng-if="node.validation_rule_status==\'No\'" class="glyphicon glyphicon-plus-sign text-success" ng-click="linkDelinkValidationRule(node,$index)" uib-tooltip="Active Rule" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+
				                    '<span ng-if="!node.is_new_rule_clicked"> '+
							        '<i class="glyphicon glyphicon-pencil text-success" ng-click="enableNewRule(node, true)" uib-tooltip="Edit Rule" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+

				                    '</span> '+


							        '</td> '+
								    '</tr> '+
								    '<tr> '+
								        '<td  width="5%"> '+
								        '&nbsp; '+
								        '</td> '+
								        '<td> '+
								        '<span ng-if="node.is_new_rule_clicked"> '+
					                    '<input   type="text" size="24" ng-model="node.new_rule_name" uib-tooltip="Enter New Rule Name" tooltip-placement="left" tooltip-trigger="mouseenter"> ' +
					                    '</span> '+
						    	        '</td> '+
								        '<td> '+
								        '<span ng-if="node.is_new_rule_clicked"> '+
					                    '<input type="text" size="34"  ng-model="node.new_rule_desc" uib-tooltip="Enter New Rule Description" tooltip-placement="left" tooltip-trigger="mouseenter"> ' +
					                    '</span> '+
								        '</td> '+
								        '<td> '+
								        '<span ng-if="node.is_new_rule_clicked"> '+
					                    '<input type="text" size="40"  ng-model="node.new_rule_path" uib-tooltip="Copy/Paste KSession value from resources/META-INF/kmodule.xml" tooltip-placement="left" tooltip-trigger="mouseenter"> ' +
					                    '</span> '+
								        '</td> '+
								        '<td> '+
								        '<span ng-if="!node.is_in_edit_mode && node.is_new_rule_clicked"> '+
								        '<button class="btn btn-success btn-xs"  ng-click="createNewRule(node,menuData)">Save New Rule</button> '+
					                    '<button class="btn btn-warning btn-xs" ng-click="enableNewRule(node)">Cancel</button> '+
					                    '</span> '+
					                    '<span ng-if="node.is_in_edit_mode"> '+
								        '<button class="btn btn-success btn-xs" ng-click="updateRule(node, menuData)">Save changes </button> '+
								        '<button class="btn btn-warning btn-xs" ng-click="enableNewRule(node)">Cancel</button> '+
					                    '</span> '+

								        '</td> '+
								        '<td> '+
								        '&nbsp; '+
								        '</td> '+
								        '<td> '+
								        '&nbsp; '+
								        '</td> '+
								        '<td width="10%"> '+
								        '<span ng-if="!node.is_new_rule_clicked"> '+
								        '<i class="glyphicon glyphicon-plus-sign text-success"  ng-click="enableNewRule(node)" uib-tooltip="Create New Rule" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+
					                    'Create Rule '+


					                    '</span> '+
								        '</td> '+
							        '</tr> '+
							    '</tbody></table>'+
					        '<ul class="list"> '+
					        '<li> '+
						        '<span>Action Key</span> '+
			                    '<span>Action Code</span> '+
			                    '<span>Action Name</span> '+
			                    '<span>Action DS</span> '+
			                    '<span>Action Proc</span> '+
			                    '<span>Active ?</span> '+
			                    '<span>Actions</span> '+
		                    '</li> '+
		                    '<li> '+
			                    '<span> '+
			                    '</span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span ng-if="!node.is_new_action_clicked"> '+
			                    '<i class="glyphicon glyphicon-plus-sign text-success" ng-click="addNewAction(node)" uib-tooltip="Create Link to Action" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+
			                    'Link Action '+
			                    '</span> '+
			                    '<span ng-if="node.is_new_action_clicked"> '+
				                    '<input type="text" size="4" ng-model="node.new_action_key" uib-tooltip="Enter Action Key" tooltip-placement="left" tooltip-trigger="mouseenter"> ' +
				                    '<button class="btn btn-success btn-xs" ng-click="searchAction(node)"><i class="fa fa-search tex-success fa-sm"></i>&nbsp;Go</button> '+
				                    '<button class="btn btn-warning btn-xs" ng-click="addNewAction(node)">Cancel</button> '+
			                    '</span> '+

		                    '</li> '+
			                '<li data-ng-repeat="action in node.validation_rule_actions"> '+
			                    '<span>{{action.action_key}}</span> '+
			                    '<span>{{action.action_code}}</span> '+
			                    '<span>{{action.action_name}}</span> '+
			                    '<span>{{action.action_datasource}}</span> '+
			                    '<span>{{action.action_proc}}</span> '+
			                    '<span ng-if="action.action_validation_status==1"> '+
			                    'Yes '+
			                    '</span> '+
			                    '<span ng-if="action.action_validation_status==0"> '+
			                    'No '+
			                    '</span> '+
			                    '<span> '+
			                    '<i ng-if="action.action_validation_status==1" class="glyphicon glyphicon-remove-sign text-danger" ng-click="linkDelinkAction(action,node,$index)" uib-tooltip="Delink Action" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+
			                    '<i ng-if="action.action_validation_status==0" class="glyphicon glyphicon-plus-sign text-success" ng-click="linkDelinkAction(action,node,$index)" uib-tooltip="Link Action" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+
			                    '</span> '+
			                '</li> '+
			              '</ul><br> '+
					        '<ul class="list"> '+
					        '<li> '+
					        '<span>Cache Key</span> '+
		                    '<span>Object Key</span> '+
		                    '<span>Cache Proc</span> '+
		                    '<span>In Parameters</span> '+
		                    '<span>Is Cached ?</span> '+
		                    '<span>Is Stale ?</span> '+
		                    '<span>Is Active ?</span> '+
		                    '<span>Last Updated</span> '+
		                    '<span>Actions</span> '+
		                    '</li> '+
		                    '<li> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span></span> '+
			                    '<span ng-if="!node.is_new_cache_clicked"> '+
			                    '<i class="glyphicon glyphicon-plus-sign text-success" ng-click="addNewCache(node)" uib-tooltip="Create Link to Cache Object" tooltip-placement="left" tooltip-trigger="mouseenter"></i> '+
			                    'Link Cache Object '+
			                    '</span> '+
			                    '<span ng-if="node.is_new_cache_clicked"> '+
				                    '<input type="text" size="4" ng-model="node.new_cache_key" uib-tooltip="Enter Cache Key" tooltip-placement="left" tooltip-trigger="mouseenter"> ' +
				                    '<button class="btn btn-success btn-xs" ng-click="searchCache(node)"><i class="fa fa-search tex-success fa-sm"></i>&nbsp;Go</button> '+
				                    '<button class="btn btn-warning btn-xs" ng-click="addNewCache(node)">Cancel</button> '+
			                    '</span> '+
		                    '</li> '+
			                '<li data-ng-repeat="cacheObj in node.validation_rule_cache_objects"> '+
			                    '<span>{{cacheObj.cache_key}}</span> '+
			                    '<span>{{cacheObj.object_key}}</span> '+
			                    '<span>{{cacheObj.cache_proc_call}}</span> '+
			                    '<span>{{cacheObj.object_filter_condition}}</span> '+
			                    '<span>{{cacheObj.is_cached}}</span> '+
			                    '<span>{{cacheObj.is_stale}}</span> '+
			                    '<span>{{cacheObj.cache_status}}</span> '+
			                    '<span>{{cacheObj.last_updated}}</span> '+
			                    '<span> '+
			                    '<i class="glyphicon glyphicon-remove-sign text-danger" ng-click="removeCacheObjMpng(cacheObj,node,$index)" uib-tooltip="Remove Cache Obj Mapping" tooltip-placement="left" tooltip-trigger="mouseenter"></i>&nbsp;&nbsp; '+
			                    '<i class="fa fa-eye fa-xs" uib-tooltip="View Cache Data" tooltip-placement="left" tooltip-trigger="mouseenter"></i> &nbsp; &nbsp;'+
			                    '</span> '+
			                '</li> '+
			              '</ul><br>  '+
		              '</div> '+
		          '</div> '+
			    '</div> '+
			'</div>';
			 element.html('').append( $compile( template )( scope ) );

            }
        };
    }])



    return directives;


});

