define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.changeLogMonitorController', [])

        .controller('changeLogMonitorController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter', 'changeLogMonitorServiceFactory', 'ObjectDiff', changeLogMonitorController]);
    	function changeLogMonitorController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter, changeLogMonitorServiceFactory, ObjectDiff) {
        var vm = this;

        vm.modalTitle = "View Message";
		vm.showJsonLoader = false;
		vm.monitoringData = {};

		function fetchMonitoringData() {
			vm.showJsonLoader = true;
            var params = {"tax_year": GlobalService.globalParams.tax_year, "client_key": USER_SETTINGS.user.client_key, "le_key": rowData.LE_KEY, "le_type": rowData.LE_TYPE, "submissionId": rowData.SUBMISSION_ID };

			return changeLogMonitorServiceFactory.getMonitoringData(params).then(function (data) {
				if(data.data.callSuccess == "1" ){
					if(data.data.snsDetails){
						var tempMonitoringData = JSON.parse(data.data.snsDetails.additional_DATA);
						vm.monitoringData = ObjectDiff.objToJsonView(tempMonitoringData);
						vm.showJsonLoader = false;
					}else{
						vm.showJsonLoader = false;
						AlertService.add("warning", "No sns msg found", 4000);
					}
				}else{
					vm.showJsonLoader = false;
                    AlertService.add("error", "Error loading SNS message", 4000);
					vm.cancel();
				}
            });
        }

		fetchMonitoringData();

		vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		
    }
});