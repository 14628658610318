define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.referenceTableActionsController', [])
    .controller('referenceTableCreateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','referenceTableActionsService', '$filter', referenceTableCreateActionController])
    .controller('referenceTableUpdateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','referenceTableActionsService', referenceTableUpdateActionController])
 
    function referenceTableCreateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,referenceTableActionsService, $filter){
        var vm = this;

        vm.modalTitle = "Entity Reference Table";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        
        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy',
            onClose: function(dateText, inst) { 
                
            }
        };
        vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0] ? $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE : 'RAF';

		if (vm.isIssueKeyEnabled === undefined) {
			if (vm.scenarioCode !== undefined) {
				if (vm.scenarioCode === 'RAF') {
					vm.isIssueKeyEnabled = 'N';
				}
				else {
					vm.isIssueKeyEnabled = 'Y';
				}
			}
		}
        vm.referenceTableEntity_bckup = {
            COLUMN_NAME:"",
            REF_CODE:"",
            REF_VALUE:"",
            FROM_YEAR:"",
            TO_YEAR:"",
            };
        
        vm.referenceTableEntity_bckup.FROM_YEAR = new Date(workspaceFactory.activeScreen.filters.getFilterParams().tax_year - 1, '0', '1');
        vm.referenceTableEntity_bckup.TO_YEAR =new Date(9999+"", '0', '1');    
        
        vm.referenceTableEntity = angular.copy(vm.referenceTableEntity_bckup);

        let loadAllColumnNames =function(){
            referenceTableActionsService.loadAllColumnNames().then((ColumnNames) => {
                console.log("load all Column Names : ", ColumnNames);
                vm.allColumnNames = ColumnNames;
            });
        }
        

        let loadAllRefCode =function(selectedVal1){
            referenceTableActionsService.loadAllRefCode(selectedVal1).then((refCodes) => {
                console.log("load all Ref Codes", refCodes);
                vm.allRefCodes = refCodes;
            });
        }

        
        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
            loadAllColumnNames();

        };
		vm.init();
        vm.isValid = function(){
            if(vm.referenceTableEntity.COLUMN_NAME == null || 
                vm.referenceTableEntity.COLUMN_NAME == undefined || 
                vm.referenceTableEntity.COLUMN_NAME == ""){
                     vm.referenceTableEntity.COLUMN_NAME_NOT_VALID = true;
                     return false;
            }
            if(vm.referenceTableEntity.REF_CODE == null || 
                vm.referenceTableEntity.REF_CODE == undefined || 
                vm.referenceTableEntity.REF_CODE == ""){
                     vm.referenceTableEntity.REF_CODE_NOT_VALID = true;
                     return false;
            }
            if(vm.referenceTableEntity.REF_VALUE == null || 
                vm.referenceTableEntity.REF_VALUE == undefined || 
                vm.referenceTableEntity.REF_VALUE == ""){
                     vm.referenceTableEntity.REF_VALUE_NOT_VALID = true;
                     return false;
            }
            if(vm.referenceTableEntity.FROM_YEAR == null || 
                vm.referenceTableEntity.FROM_YEAR == undefined || 
                vm.referenceTableEntity.FROM_YEAR == ""){
                     vm.referenceTableEntity.FROM_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.referenceTableEntity.TO_YEAR == null || 
                vm.referenceTableEntity.TO_YEAR == undefined || 
                vm.referenceTableEntity.TO_YEAR == ""){
                     vm.referenceTableEntity.TO_YEAR_NOT_VALID = true;
                     return false;
            }
            
            return true;
        }
        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
             var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);
            let jsonObj = angular.copy(vm.referenceTableEntity);
            // jsonObj = _.merge({}, jsonObj , filterParams);
            jsonObj.FROM_YEAR = jsonObj.FROM_YEAR.getFullYear().toString();
            jsonObj.TO_YEAR = jsonObj.TO_YEAR.getFullYear().toString();
            jsonObj = JSON.stringify([jsonObj]);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=55rzd5";

            params.is_issue_key_enabled = vm.isIssueKeyEnabled;
            params.scenarioCode = vm.scenarioCode;
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Reference Tab created successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });

        };

        vm.onColumnNameChange = function(){
            console.log(vm.referenceTableEntity.COLUMN_NAME);
            vm.referenceTableEntity.REF_CODE = '';
            loadAllRefCode(vm.referenceTableEntity.COLUMN_NAME);
        }; 

        vm.upper = function(colName) {
            vm.referenceTableEntity.COLUMN_NAME = colName.toUpperCase();
        }
        vm.upperRefCode = function(colName) {
            vm.referenceTableEntity.REF_CODE = colName.toUpperCase();
        }
        vm.upperRefValue = function(colName) {
            vm.referenceTableEntity.REF_VALUE = colName.toUpperCase();
        }

        vm.reset = function (){
			vm.referenceTableEntity = angular.copy(vm.referenceTableEntity_bckup);
		};

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };
    }

    function referenceTableUpdateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, referenceTableActionsService){
        var vm = this;

        vm.modalTitle = "Reference Table";
		vm.showFormLoader = true;


        vm.showEntityLoader = false;
        vm.isSavingUpdate = false;
        
        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy'
        };
 		
        vm.referenceTableEntity_bckup = gridData.rowData;
        
        vm.referenceTableEntity_bckup.FROM_YEAR = new Date(gridData.rowData.FROM_YEAR,'0','1');
        vm.referenceTableEntity_bckup.TO_YEAR = new Date(gridData.rowData.TO_YEAR,'0','1');
        vm.referenceTableEntity = angular.copy(vm.referenceTableEntity_bckup);



        

        vm.isValid = function(){
            if(vm.referenceTableEntity.REF_VALUE == null || 
                vm.referenceTableEntity.REF_VALUE == undefined || 
                vm.referenceTableEntity.REF_VALUE == ""){
                     vm.referenceTableEntity.REF_VALUE_NOT_VALID = true;
                     return false;
            }
            if(vm.referenceTableEntity.FROM_YEAR == null || 
                vm.referenceTableEntity.FROM_YEAR == undefined || 
                vm.referenceTableEntity.FROM_YEAR == ""){
                     vm.referenceTableEntity.FROM_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.referenceTableEntity.TO_YEAR == null || 
                vm.referenceTableEntity.TO_YEAR == undefined || 
                vm.referenceTableEntity.TO_YEAR == ""){
                     vm.referenceTableEntity.TO_YEAR_NOT_VALID = true;
                     return false;
            }
            
            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSavingUpdate = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.referenceTableEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            jsonObj.FROM_YEAR = jsonObj.FROM_YEAR.getFullYear().toString();
            jsonObj.TO_YEAR = jsonObj.TO_YEAR.getFullYear().toString();
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ji0byt";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Reference Tab Updated successfully", 4000);
                    console.log("Updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });

        };
		
        vm.delete = function(_column_name, _ref_code){
            console.log("Delete function called");
            console.log(_column_name+'............'+_ref_code);
            vm.isSavingDelete = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                column_name: _column_name,
                ref_code: _ref_code
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qfe2kq";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Reference Tab Setup deleted successfully", 4000);
                    console.log("deleted", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.upperRefValue = function(colName) {
            vm.referenceTableEntity.REF_VALUE = colName.toUpperCase();
        }

        vm.reset = function (){
			vm.referenceTableEntity = angular.copy(vm.referenceTableEntity_bckup);
		};

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };
    }
    return controllers;
});
