define([
    'angular',
    './TemplatesCtrl',
    './TemplateServices'
    
], function () {
    'use strict';
    return angular.module('app.adminTemplates', ['app.templateServices','app.templatesCtrl','ui.toggle','ngAnimate'])
    	
        .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
            $stateProvider
                .state('admin.templates-list', {
                    url: '/templates-list',
                    templateUrl: ' app/components/admin/templates/templates-list-tpl.html',
                    type:'custom',
                    "api": {"get":GENERAL_CONFIG.base_url+"/loadGridJson?action_code=7w37qb&grid_id=72","grid_id":"72"},
                    access: "4"
                })
                .state('admin.templates-type-list', {
                    url: '/templates-type-list',
                    templateUrl: ' app/components/admin/templates/templates-type-list-tpl.html',
                    type:'custom',
                    "api": {"get":GENERAL_CONFIG.base_url+"/loadGridJson?action_code=12jct6&grid_id=73","grid_id":"73"},
                    access: "4"
                });
        }]);


});