define([
    'angular'
], function () {
    'use strict';

    var services = angular.module('app.stateGroupDetailsService',[])
        .factory("stateGroupDetailsService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG', 'JsonObjectFactory', 'AlertService',
            function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG, JsonObjectFactory, AlertService) {

                var stateGroupDetailsService = {};

                var URLS = {
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj'
                }

                stateGroupDetailsService.updateGroupsDetail = function(){
                    console.log("updateGroupsDetail");
                }

                stateGroupDetailsService.enableDisableGroupNotification = function (_callName, _data, isNew) {
                    var defer = $q.defer();
                    console.log("enableDisableGroupNotification"+_callName+"_"+_data);
                    var params = {
                        tax_year: _data.rowData.TAX_YEAR,
                        scenario: _data.rowData.SCENARIO,
                        group_key: _data.rowData.MEF_GROUP_KEY,
                        jcd_key: GlobalService.globalParams.jcd_key,
                        enable: _data.rowData.ENABLE_FOR_DIAG_NOTIFICATION
                    }
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32951";
                    JsonObjectFactory.saveJSON(url,params).then(function(data){
                        if(data.callSuccess == "1"){
                            AlertService.add("success","Succesfully updated group for notification.",4000);                            
                        }else{
                            AlertService.add("error", data.errorMessage);
                        }
                    },function(data){
                            defer.reject(data);
                    });
                    return defer.promise;
                }

                return stateGroupDetailsService;

        }]);

    return services;

});