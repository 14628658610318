
define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.relatedPartyModalController',[])
        .controller('RelatedPartyModalCtrl', 
        ['$scope', '$rootScope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService',
         'rowData', 'gridData' , 'workspaceFactory', 'JsonObjectFactory','$timeout','GENERAL_CONFIG', 'USER_SETTINGS',
         'relatedPartyModalFactory', RelatedPartyModalCtrl])


    function RelatedPartyModalCtrl( $scope, $rootScope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, gridData, workspaceFactory, JsonObjectFactory,$timeout,GENERAL_CONFIG,USER_SETTINGS,relatedPartyModalFactory){
  
        var vm = this;      
        vm.relPartyFormData = [];
        vm.userSettings     = USER_SETTINGS;
        vm.logged_in_user   = vm.userSettings.user.sso_id;
        vm.client_key       = vm.userSettings.user.client.client_key;        
        vm.tax_year  		= GlobalService.globalParams.tax_year;   
        vm.scenario 		= GlobalService.globalParams.scenario;
        vm.jcd_key  		= GlobalService.globalParams.jcd_key;       
        
        vm.expln_input_clob = [];
        vm.explainText      = ''; 
        vm.cur_expl_desc = '';
        vm.otherExplainText = '';
        vm.modalTitle = 'Explain Variance';
        vm.explType = 'STD';
        vm.varianceExpln    = {};
        vm.showOtherExpln   = false;
        vm.explDataLoaded   = false;
        vm.showRemoveBtn    = false;
        $scope.lazyLoadObj = {};
        $scope.expl_dropdown_data = [];


        vm.getVarExplanation = function(){            
            vm.expln_input_clob = [];
            vm.expln_input_clob.push({"LEID": rowData.LEID,											 
                                   "CDR_NO": rowData.CDR_NO,
                                   "REPORTING_PERIOD": rowData.REPORTING_PERIOD,
                                   "ME_CODE": rowData.ME_CODE,
                                   "CURR": rowData.CURR,
                                   "PAYOR_LEID": rowData.PAYOR_LEID,
                                   "PAYOR_CDR_NO": rowData.PAYOR_CDR_NO,
                                   "PAYOR_REPORTING_PERIOD": rowData.PAYOR_REPORTING_PERIOD,
                                   "PAYOR_ME_CODE": rowData.PAYOR_ME_CODE,
                                   "PAYOR_CURR": rowData.PAYOR_CURR
                                });
            var params = {
                "action_code": 'aca72h',
                "sso_id":  vm.logged_in_user,
                "tax_year":vm.tax_year,
                "scenario":vm.scenario,
                "jcd_key": vm.jcd_key,
                "json_data": JSON.stringify(vm.expln_input_clob)
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (response) {
                console.log(response);
                if (response.errorMessage && response.errorMessage.length > 0 && response.errorMessage !== 'null') {
                    AlertService.add("error", response.errorMessage);
                } else {
                    if (response.jsonObject.length) {   
                       if(angular.equals(response.jsonObject[0].EXPLN_TYPE,'OTH')){    
                            vm.cur_expl_desc    = 'Other';                       
                            vm.otherExplainText = response.jsonObject[0].EXPLN_TEXT;
                            vm.showOtherExpln   = true;
                       } 
                       else{
                            vm.cur_expl_desc =  response.jsonObject[0].EXPLN_TEXT;
                            vm.showOtherExpln = false;
                       }                     
                    }
                }

                //Get all std explanations
                vm.getExplanations();

            });

        };       

       vm.getExplanations = function(){           
            var params = {
                "action_code": 'tg9rwv',
                "sso_id":  vm.logged_in_user,
                "tax_year":vm.tax_year,
                "scenario":vm.scenario,
                "jcd_key": vm.jcd_key
            };       
            
            vm.varianceExpln = {};
            vm.showRemoveBtn = false;

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (response) {
                console.log(response);
                if (response.errorMessage && response.errorMessage.length > 0 && response.errorMessage !== 'null') {
                    AlertService.add("error", response.errorMessage);
                } else {
                    if (response.jsonObject.length) {   
                        $scope.expl_dropdown_data = response.jsonObject;                        
                        $scope.lazyLoadObj.totalDrpList = $scope.expl_dropdown_data;                        
                        vm.explDataLoaded = true;
                        $scope.lazyLoadObj.cachedDrpList = $scope.expl_dropdown_data.slice(0, 10);                                              
                        //set current expln
                        if(vm.cur_expl_desc !== ''){
                            vm.showRemoveBtn = true;
                            angular.forEach(response.jsonObject, function(obj) {                               
                                if (vm.cur_expl_desc == obj.EXPL_DESC) {
                                    vm.varianceExpln = obj; 
                                }
                            });
                        }
                    }
                }
            });            

        };
       

        vm.selected = function(arg1){
           console.log("this is arg::::::: ", arg1);         
           vm.varianceExpln = arg1;
           if(angular.equals(vm.varianceExpln.EXPL_DESC, 'Other')){
                vm.showOtherExpln = true;
           }
           else{
                vm.showOtherExpln = false;
           }
        }

        vm.saveNewExplanation = function(){
            var successMessage = "New explanation has been saved";            

            if(vm.explainText == ''){
                AlertService.add("error", "Please enter a valid explanation", 4000);
                return;
            }           

            if(vm.explainText.length > 500){
                AlertService.add("error", "Explanation is too long. Please shorten to 500 characters or less.", 4000);
                return;
            }  

            vm.jsonSettings = {"sso_id": vm.logged_in_user};
            vm.explnParams  = {"code_desc": vm.explainText};
            vm.explDataLoaded = false;
            vm.showOtherExpln = false;            
           
            relatedPartyModalFactory.saveNewExplanation(vm.relPartyFormData, vm.jsonSettings, vm.explnParams).then(function(data){
                if(data.data.errorMessage != 'null'){
                    console.log("Error occured! ", data.data.errorMessage);
                    AlertService.add("error", data.data.errorMessage, 4000);
                    vm.explDataLoaded = true;
                }
                else{
                    console.log("Call success!!!");
                    //refresh explanation dropdown            
                    vm.getVarExplanation();
                    AlertService.add("success", successMessage, 4000);                    
                }
            });        	

        };

        vm.saveVarianceExpln = function(){ 
            var successMessage = "Variance explanation has been saved";            

            if(vm.varianceExpln.EXPL_DESC == undefined){
                AlertService.add("error", "Please select an explanation.", 4000);
                return;
            }

            if(angular.equals(vm.varianceExpln.EXPL_DESC, 'Other')){
                if(vm.otherExplainText == ''){
                    AlertService.add("error", "Please enter other explanation", 4000);
                    return;
                }    
                if(vm.otherExplainText.length > 500){
                    AlertService.add("error", "Other explanation is too long. Please shorten to 500 characters or less.", 4000);
                    return;
                }
                vm.explnParams  = {"expln_text": vm.otherExplainText, "expln_type": 'OTH'};               
            }
            else{                
                vm.explnParams  = {"expln_text": vm.varianceExpln.EXPL_DESC, "expln_type": 'STD'};
            }         
                         
            vm.expln_input_clob = [];
            vm.jsonSettings = {"sso_id": vm.logged_in_user};
            vm.expln_input_clob.push({"LEID": rowData.LEID,											 
                                   "CDR_NO": rowData.CDR_NO,
                                   "REPORTING_PERIOD": rowData.REPORTING_PERIOD,
                                   "ME_CODE": rowData.ME_CODE,
                                   "CURR": rowData.CURR,
                                   "PAYOR_LEID": rowData.PAYOR_LEID,
                                   "PAYOR_CDR_NO": rowData.PAYOR_CDR_NO,
                                   "PAYOR_REPORTING_PERIOD": rowData.PAYOR_REPORTING_PERIOD,
                                   "PAYOR_ME_CODE": rowData.PAYOR_ME_CODE,
                                   "PAYOR_CURR": rowData.PAYOR_CURR
                                });
            
            relatedPartyModalFactory.saveVarExplanation(vm.expln_input_clob, vm.jsonSettings, vm.explnParams).then(function(data){
                if(data.data.errorMessage != 'null'){
                    console.log("Error occured! ", data.data.errorMessage);
                    AlertService.add("error", data.data.errorMessage, 4000);                    
                }
                else{
                    console.log("Call success!!!");                  
                    AlertService.add("success", successMessage, 4000);      
                    $uibModalInstance.dismiss('cancel');    
                    var args = {};
					$rootScope.$emit('gridUpdate', args);          
                }
            });       
            
        };

        vm.removeVarianceExpln = function(){ 
            var successMessage = "Variance explanation has been removed";            

            if(vm.varianceExpln.EXPL_DESC == undefined){
                AlertService.add("error", "Please select an explanation.", 4000);
                return;
            }
                         
            vm.expln_input_clob = [];
            vm.jsonSettings = {"sso_id": vm.logged_in_user};
            vm.expln_input_clob.push({"LEID": rowData.LEID,											 
                                   "CDR_NO": rowData.CDR_NO,
                                   "REPORTING_PERIOD": rowData.REPORTING_PERIOD,
                                   "ME_CODE": rowData.ME_CODE,
                                   "CURR": rowData.CURR,
                                   "PAYOR_LEID": rowData.PAYOR_LEID,
                                   "PAYOR_CDR_NO": rowData.PAYOR_CDR_NO,
                                   "PAYOR_REPORTING_PERIOD": rowData.PAYOR_REPORTING_PERIOD,
                                   "PAYOR_ME_CODE": rowData.PAYOR_ME_CODE,
                                   "PAYOR_CURR": rowData.PAYOR_CURR
                                });
            
            relatedPartyModalFactory.removeVarExplanation(vm.expln_input_clob, vm.jsonSettings).then(function(data){
                if(data.data.errorMessage != 'null'){
                    console.log("Error occured! ", data.data.errorMessage);
                    AlertService.add("error", data.data.errorMessage, 4000);                    
                }
                else{
                    console.log("Call success!!!");                  
                    AlertService.add("success", successMessage, 4000);      
                    $uibModalInstance.dismiss('cancel');    
                    var args = {};
					$rootScope.$emit('gridUpdate', args);                    
                }
            });       
            
        };
               
        vm.save = function (activeTab) {            
            if(activeTab == 'setupExplanation'){               
                vm.saveNewExplanation();                
            }
            else{
                vm.saveVarianceExpln();                
            }           
        }; 

        vm.reset = function(activeTab){
            if(activeTab == 'setupExplanation'){                         
                vm.explainText = '';             
            }
            else{
               vm.explDataLoaded = false;
               //refresh explanation dropdown               
               vm.getVarExplanation();
            }            
        }; 

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }; 

        //load explanation dropdown
        vm.getVarExplanation();
	   
    }

    return controllers;


});