define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.workspaceServices',[])

        .factory('workspaceFactory', ['$rootScope','$state','USER_MENU','$timeout','menuDataService','GlobalService','AlertService','dashboardFactory',
        function($rootScope,$state,USER_MENU,$timeout,menuDataService,GlobalService,AlertService,dashboardFactory){
            var workspaceFactory = {};
            workspaceFactory.activeScreen = {};
            workspaceFactory.activity = {};
            workspaceFactory.screens = [];
            workspaceFactory.customDataCache = [];
            workspaceFactory.lastActiveScreen = [];
            workspaceFactory.DataCache = {};
            workspaceFactory.goback = {};

            //getting and storing the clicked workflow link name for adding css effect.
            workspaceFactory.compTitle = {};
            workspaceFactory.setCompName = function(name) {
                workspaceFactory.compTitle = name;
            }

            //getting and storing the clicked workflow link Y position to scroll to that perticular on landing from different route.
            workspaceFactory.positionY = [];
            workspaceFactory.setPositionY = function(posY) {
                workspaceFactory.positionY = posY;
            }

            //getting and storing the clicked workflow link parma id for adding css effect/checking with previoous stored id.
            workspaceFactory.stateId = {};
            workspaceFactory.setParamsId = function(id) {
                workspaceFactory.stateId = id;
            }

           // setting up the routes.
            workspaceFactory.routes = {};

            workspaceFactory.setRoutes = function(_previousDashboard){
                workspaceFactory.routes = _previousDashboard;
                if((workspaceFactory.routes.prevoiusDashboard == 'workflow.entity.workflow.details') && (workspaceFactory.positionY > window.innerHeight)) {
                    setTimeout(function() {
                        window.scrollTo({ left: 0, top: workspaceFactory.positionY, behavior: 'smooth'});
                    }, 500);
                } else {
                    setTimeout(function() {
                        window.scrollTo({ left: 0, top: 0, behavior: 'smooth'});
                    }, 500);
                }
            }

            workspaceFactory.setActivity = function(_activity,_changeState,_screen){

                // RESET WORKSPACE
                workspaceFactory.activeScreen = {};
                workspaceFactory.activity = {};
                workspaceFactory.screens = [];

                // SET WORKSPACE
                workspaceFactory.activity = _activity;
                workspaceFactory.screens =  _activity.screens;

                //console.log("workspaceFactory " , workspaceFactory)
                //console.log("_activity " , _activity)



                //console.log("TO DO: =================================" , "make sure last active screen gets set for page url loads ");


                var findLastActive = _.find(workspaceFactory.lastActiveScreen, { 'activity_key': _activity.activity_key});



                if( _screen !== undefined && _screen !== null){
                    workspaceFactory.activeScreen = _screen;
                }else{


                    if(findLastActive){

                     var index =  _.findIndex(_activity.screens, { 'screen_key': findLastActive.screen_key });
                    // var index =  _.findIndex(_activity.screens, function(item) {
                    //    return item.id == findLastActive.screen_key;
                    // });

                        workspaceFactory.activeScreen = _activity.screens[index];

                    }else{
                        if(!_activity.screens && _activity.children){
                            _activity.screens = _activity.children;
                        }
                        workspaceFactory.activeScreen = _activity.screens[0];
                    }





                }

                 if( _changeState !== false ){
                     this.loadTab(workspaceFactory.activeScreen,{});
                 }else{
                     GlobalService.setFilters(workspaceFactory.activeScreen);
                 }
            }

            workspaceFactory.loadTab = function(workspace,_gridFilters,onBackbutton){

                console.log("|||||||| WE ARE IN TAB LOADED ---------------------------------- ||||||||||||||" , workspace)
                console.log("|||||||| Active Screen ||||||||||||||" , workspace)
                console.log("|||||||| Workspace Factory ||||||||||" , workspaceFactory)


                //Heath 2/5/2020
                // Enable Back Button
                workspaceFactory.setBackButton(workspace,_gridFilters);



                var findLastActive = _.find(workspaceFactory.lastActiveScreen, { 'activity_key': workspace.activity_key});



                //console.log("findLastActive " , findLastActive);

                if(findLastActive){
                    findLastActive.screen_key = workspace.id;
                }else{
                    workspaceFactory.lastActiveScreen.push({activity_key : workspace.activity_key, screen_key : workspace.id })
                }

                //console.log("workspaceFactory.lastActiveScreen 2" , workspaceFactory.lastActiveScreen)



                GlobalService.setFilters(workspace);
                var gridFilters = (_gridFilters) ? _gridFilters : {};
                workspaceFactory.activeScreen =  workspace;


                /*dvalaboju001 11/4/2019*/
                /* if(workspaceFactory.activeScreen.commentLink && workspaceFactory.activeScreen.commentLink!=null){
                    workspaceFactory.activeScreen.commentLink = [];
                } */

                if( workspace.screen_staus && workspace.screen_staus === 'M') {
                    $state.go('workspace.maintenance', {}, {});
                }else if(!onBackbutton){

                    //console.log("workspace.link" , workspace.link, gridFilters );
                    $state.go(workspace.link, gridFilters, {inherit: true});
                }
            };



            workspaceFactory.setBackButton = function(workspace,_gridFilters){


                //Heath Stein
                //1/24/2019
                //added to make back button work from standard screens

                console.log(" workspace.screen_key" ,workspace.screen_key );

               if(workspace.screen_key == "10246" || workspace.screen_key == "10191" || workspace.screen_key == '10233' 
                    || (workspace.parent && workspace.parent.activity_key == '544') || workspace.screen_key == "10906"){
                    workspaceFactory.goback = {
                        type:"standard",
                        workspace:workspace,
                        gridFilters:_gridFilters,
                        backMessage: 'Back to ' + workspace.label
                    };
                    $rootScope.$broadcast("backButtonEnabled", true);
                }



            }





            workspaceFactory.loadTabNoFilters = function(workspace){
                workspaceFactory.activeScreen =  workspace;
                if( workspace.screen_staus && workspace.screen_staus === 'M') {
                    $state.go('workspace.maintenance', {}, {});
                }else{
                    $state.go(workspace.link, {}, {inherit: true});
                }
            };

           function findActivity(_activity_key){
               var activity = null;
               function search(value2){
                 if(value2.type === "activity" && value2.activity_key === _activity_key ){
                      activity = value2;
                     return false;
                 }
                  if(value2.children){
                      _.forEach(value2.children, function (_value,_key) {
                          search(_value);
                      });
                  }
               };
               _.forEach(USER_MENU, function (value,key) {
                   if(value.children) {
                        search(value);
                   }
               });
               return activity;
            }

            function findScreen(_activity,_screen_key){

                //console.log("_activity " , _activity)
                //console.log("_screen_key " , _screen_key)

             var  screen = _.find(_activity.screens, {screen_key:_screen_key } );
             return screen;
            }

            function createLinkFilters(){
                var filterCached =  (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                return filterCached;
            }

           // workspaceFactory.checkTypeLink = ['comment','taxCycle','dashBoardTI','dashBoardPR','projectReview'];
           workspaceFactory.checkTypeLink = ['comment','link'];
           workspaceFactory.workflowObj = false;

            workspaceFactory.loadLink = function(_type,_comment,_activity_key,_screen_key,_filters,_table_state,_locationObj,_actionGrid,_noReload){

                var activity = null, screen = null, reload = true;
                activity = findActivity(_activity_key);
                if(activity){
                    menuDataService.removeSelected();
                    activity.selected = 'selected';
                    menuDataService.setSelected(activity);
                    screen = findScreen(activity,_screen_key);
                }


                //TODO: Remove it is not used
                if(_noReload && _noReload === true){
                    reload = false;
                }

                /* Check to enable workflow route on setBackButton - added by OM */
                if(_type == 'workflow'){
                    workspaceFactory.workflowObj = true;
                }else{
                    workspaceFactory.workflowObj = false;
                    //Heath 2/5/2020
                    // Enable Back Button
                    workspaceFactory.setBackButton(workspaceFactory.activeScreen,[]);
                }

                console.log("activity ", activity);
                console.log("_activity_key ", _activity_key);
                console.log("_screen_key ", _screen_key);


                    if(activity && screen) {
                        workspaceFactory.setActivity(activity, true, screen);
                        if(!_.isEmpty(_filters)){  //divya 1/24/2020
                            workspaceFactory.activeScreen.commentLink = {type:_type,filters:_filters,table_state:_table_state,locationObj:_locationObj,action_template_key:_actionGrid};
                            if (_actionGrid!=null){
                                workspaceFactory.activeScreen.actionGrid = _actionGrid;
                                //console.log("workspaceFactory.activeScreen ---------------------------------------------------- " , workspaceFactory.activeScreen);
                            }else{
                                workspaceFactory.activeScreen.actionGrid = null;
                            }

                            var filterCached = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                            var filters = (workspaceFactory.activeScreen.commentLink) ? workspaceFactory.activeScreen.commentLink.filters : {};
                            /*  dvalaboju001 11/8/2019
                            filters sorting bcz filterCached and filters values are in different order so it makes both are not euqual  */
                            var sortfilters = [];
                            /* if block is only for inherited filter from screen to screen when the loaded screen filterChached is empty  */
                            if(_.isEmpty(filterCached)){
                                if (workspaceFactory.activeScreen && workspaceFactory.activeScreen.filter_inherit === 1) {
                                    var parent = workspaceFactory.activeScreen.parent;
                                    var filter_key = workspaceFactory.activeScreen.filter_key;
                                    for (var i = 0; i < parent.screens.length; i++) {
                                        var screen = parent.screens[i];
                                        if (screen.filters && filter_key === screen.filter_key) {
                                            workspaceFactory.activeScreen.filters = screen.filters;
                                            return true;
                                        }
                                    }
                                }
                                filterCached = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                            }
                            if(filters && filterCached){
                                _.each(filters, function (item) {
                                    if(item!=undefined && item.value != undefined  && item.value != "undefined"){
                                        if (_.isString(item.value)) {
                                            sortfilters.push({
                                                'filter_key': item.filter_key,
                                                'name': item.name,
                                                'value': item.value.split(',').sort().join()
                                            });
                                        } else {
                                            sortfilters.push({
                                                'filter_key': item.filter_key,
                                                'name': item.name,
                                                'value':item.value
                                            })
                                        }
                                    }

                                });
                                var sortfilterCached = [];
                                _.each(filterCached, function (item) {
                                    if(item!=undefined && item.value  != undefined  && item.value != "undefined"){
                                        if (_.isString(item.value)) {
                                            sortfilterCached.push({
                                                'filter_key': item.filter_key,
                                                'name': item.name,
                                                'value': item.value.split(',').sort().join()
                                            });
                                        } else {
                                            sortfilterCached.push({
                                                'filter_key': item.filter_key,
                                                'name': item.name,
                                                'value': item.value
                                            })
                                        }
                                    }

                                })
                            }
                            /* order the sortfilterCached based on sortFilters */
                            var orderKeys = sortfilters.map(function (el) { return el.filter_key; });
                            sortfilterCached = orderKeys.map(function(key) {
                                for(var i=0; i<sortfilterCached.length; ++i) {
                                    if(key == sortfilterCached[i].filter_key) return sortfilterCached[i];
                                }
                            });
                
                            if (filters && filterCached && !_.isEqual(sortfilters,sortfilterCached)) {
                                workspaceFactory.clearCache(  workspaceFactory.activeScreen);
                            }
                        }

                    }else{
                        AlertService.add("error", "Error could not find link. If this continues please contact support", 4000);
                    }

            }

            workspaceFactory.loadScreen = function(_activity_key,_screen_key){

                var activity =  findActivity(_activity_key);
                var screen =  findScreen(activity,_screen_key)
                this.loadTabNoFilters(screen);
            }

            workspaceFactory.loadDrillDown = function(workspace,gridFilters){

                 var x=  workspaceFactory.checkFilters(workspace);

                workspaceFactory.activeScreen =  workspace;
                /*dvalaboju001 10/29/2019*/
                if(!x){
                    workspaceFactory.clearCache( workspaceFactory.activeScreen);
                 }
                this.loadTab(workspace,{gridFilters:gridFilters,drillDown:true,drillRefresh:1});
            }

            workspaceFactory.clearCache = function(screen){
                    screen.data = null;
                this.clearCurrentFilters(screen);
                screen.cachedData = false;
                 // screen.parent.cachedData = false; old
                  /* dvalboju001 2/25/2020 -- if any screen one of the screen has cachedData we don't want to remove the parent(activity) cache */
                _.filter(screen.parent.screens,{cachedData:true}).length == 0 ? screen.parent.cachedData = false : screen.parent.cachedData = true;

            }

            workspaceFactory.setCache = function(screen,data){
                screen.data = data;
                screen.cachedData = true;
                screen.parent && (screen.parent.cachedData = true);
            }

            //TODO: Set filters as selected filter object do not match the values: that way we can handel values of arrays

            workspaceFactory.setCurrentFilters = function(screen){
                var filters = [];
               //console.log(" screen.filters " , screen.filters)
                if(screen.filters &&  screen.filters.filters ) {
                    _.forEach(screen.filters.filters, function (value) {
                        var selected = {};
                        if(value.selected && _.isArray(value.selected)){
                            selected.label = value.label;
                            selected.filter_key = value.filter_key
                            selected.value = value.selected;
                            //console.log( selected.value,  selected.value)
                            filters.push(selected);
                        }else{
                            //console.log("value  " , value )
                            //console.log("value.value_name  " , value.value_name )
                                /*  4/15/2019 added value.selected */
                                selected.label = value.label;
                                selected.filter_key = value.filter_key;
                                selected.value = value.selected && value.selected[value.value_name];
                                selected.valueLabel = value.selected && value.selected[value.label_name];
                                filters.push(selected);
                        }

                    });
                    screen.dataCurFilters = _.cloneDeep(filters);
                }
            }
            workspaceFactory.clearCurrentFilters = function(screen){
                if(screen.dataCurFilters){
                    delete screen.dataCurFilters;
                }
            }

            workspaceFactory.checkFilters = function(screen){
                var check = true;
                if(screen.dataCurFilters){
                    _.forEach(screen.filters.filters, function(value) {
                        var find =  _.find(screen.dataCurFilters, { 'filter_key': value.filter_key});
                        if(typeof find === 'undefined' ){
                            return false;
                        }
                      // old code-- var curFilterVal = ( value.selected) ? value.selected[value.value_name] : "";
                      //var curFilterVal = ( value.selected) ? (value.selected[value.value_name]||value.selected[value.value_name.toLowerCase()]) : ""; // added on 4/9/2019
                      var curFilterVal = ( value.selected) ?   (typeof value.selected[value.value_name] ? value.selected[value.value_name] : value.selected[value.value_name.toLowerCase()]) : "";
                      var dataFilterVal = find.value;
                        if(value && value.selected && _.isArray(value.selected)){
                            curFilterVal =  value.selected;
                             if(_.isArray(curFilterVal) &&_.isArray(dataFilterVal)){
                               /* if( !_.isEqual(curFilterVal.sort(), dataFilterVal.sort())){
                                    check = false;
                                }
                            } */
                            var sortfilterCached =[],dataFilterVal1=[];
                            if(curFilterVal!=undefined && dataFilterVal != undefined){
                                _.each(curFilterVal, function (item) {
                                    if(item){
                                        if (value!= undefined && _.isString(item.value)) {
                                            sortfilterCached.push({
                                                'name': item.name ||item.LABEL,
                                                'value': item.value.split(',').sort().join()
                                            });
                                        } else {
                                            sortfilterCached.push({
                                                'name': item.LABEL,
                                                'value': item.VALUE
                                            })
                                        }
                                    }                                   
                                });
                                _.each(dataFilterVal, function (item) {
                                    if(item){
                                        if (value != undefined && _.isString(item.value)) {
                                            dataFilterVal1.push({
                                                'name': item.name ||item.LABEL,
                                                'value': item.value.split(',').sort().join()
                                            });
                                        } else {
                                            dataFilterVal1.push({
                                                'name': item.LABEL,
                                                'value': item.VALUE
                                            })
                                        }
                                    }                                    
                                });
                                if( !_.isEqual(sortfilterCached.sort(), dataFilterVal1.sort())){
                                    check = false;
                                }
                            }

                        }

                        }else{
                            if(find && curFilterVal !== dataFilterVal ){
                                check = false;
                            }
                        }
                    });
                }
                return check;
            }

            workspaceFactory.loadActiveActivity = function(){
                this.loadTab(workspaceFactory.activeScreen,{});
            }

            workspaceFactory.onLoad = function(){
                //////console.log(" ============= AM I A WORKSPACE INTECPTOR ???? ================")
                menuDataService.removeSelected();

                    var link = $state.current.name;
                    var defaultActivity = findNode(USER_MENU, 'Y', 'default_activity', 'children');

                    if( !defaultActivity ){ //IF THERE IS NOT A DEFAULT ACTIVITY
                       defaultActivity = findNode(USER_MENU, 'activity', 'type', 'children');
                    }

                //CHECK IF NO ACTIVITIES HAVE BEEN SET
                if(menuDataService.data === "" || menuDataService.data.length === undefined ){
                    //////console.log("menuDataService.data " , menuDataService.data);
                    $state.go('workspace.notAssigned', {}, {});
                }

                if( link !== undefined && link !== ""  ){
                    if( link === "workspace" && defaultActivity ){
                        // defaultActivity.selected = 'selected'
                        // workspaceFactory.setActivity(defaultActivity,true,null);
                        // menuDataService.setSelected(defaultActivity);

                        // This will get the first available user menu object and activates the first activity link by passing the object to menuDataService(Added by Om)..
                        var firstAvailActivity = USER_MENU[0].children[0].children[0];
                        firstAvailActivity.selected = 'selected'
                        workspaceFactory.setActivity(firstAvailActivity,true,null);
                        menuDataService.setSelected(firstAvailActivity);
                    }else if (  link.indexOf("workspace") > -1 ){
                        setActiveScreen(menuDataService.data,'children',link);
                    }else{
                        workspaceFactory.setActivity(defaultActivity,false,null);
                        defaultActivity.selected = 'selected'
                        menuDataService.setSelected(defaultActivity);
                    }
                }
                /*if($state.params && $state.params.launchBuoy ){
                //////console.log("AM I A DATA BUOY" , $state.params.launchBuoy)
                if(workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getLoaded()){
                    workspaceFactory.activeScreen.filters.setFiltersToValue($state.params.filters);
                }
                return true;
                }*/
            }

            var setActiveScreen = function(_collection,_children,_name) {
                var node = null;
                for (var i = 0; i < _collection.length; i++) {
                    var thisChildren =  _collection[i][_children];

                    if( thisChildren && thisChildren.length ){
                        setActiveScreen(thisChildren,_children,_name);
                    }
                    if( _collection[i].screens != undefined ){
                        if( _.find(_collection[i].screens, {'link':_name}) != undefined ){
                            var activeScreen =  _.find(_collection[i].screens, {'link':_name});
                           _collection[i].selected = 'selected';
                            workspaceFactory.setActivity( _collection[i],false,activeScreen);
                            menuDataService.setSelected(_collection[i]);
                       }
                    }
                }
                 let breadcrumbArray = []; 
                function buildBreadCrumbData(val) {
                    breadcrumbArray.push(val.label);
                    if (val.parent) {
                        buildBreadCrumbData(val.parent)
                    } else {
                        breadcrumbArray.reverse();
                    }
                } 
                buildBreadCrumbData(workspaceFactory.activeScreen);
                $('gtw-header-ce').attr('bread-crumb', JSON.stringify(breadcrumbArray));
            }

            workspaceFactory.getScreen = function(){
                return workspaceFactory.screens;
            }

            //MOVE TO LODASH MIXINs
            function findNode(collection, value, predicate, children) {
                var node = null
                //console.log("collection  value " , value , " predicate "  , predicate  , " children  " , children  );
                function findNodeSearch(collection, value, predicate, children){
                    loop1: for (var i = 0; i < collection.length; i++) {
                        var  n = collection[i][predicate];
                        if(n && _.includes(n.toLowerCase(), value.toLowerCase()) ){
                            node = collection[i];
                            break loop1;
                        }

                        if ( collection[i][children] && collection[i][children].length  ) {
                            findNodeSearch(collection[i][children], value, predicate, children )
                        }
                    }
                }
                findNodeSearch(collection, value, predicate, children);
                return node;
            }

            workspaceFactory.setCustomDataCache= function(_data_key,_data){
                //  /* 6/18/2019 */
                //  if(_data_key === "AC1245678"){
                //     if(workspaceFactory.customDataCache.length){
                //             workspaceFactory.customDataCache =[];
                //     }
                // }
                // /* ends here */
                workspaceFactory.customDataCache.push({data_key:_data_key, data:_data});
                //console.log("workspaceFactory.setCustomDataCache ================ ", workspaceFactory.customDataCache);
                return true;
            }

            workspaceFactory.getCustomDataCache = function(data_key){
                var data = _.find(workspaceFactory.customDataCache, {data_key:data_key } );
                //console.log("workspaceFactory.customDataCache ", workspaceFactory.customDataCache);
                //console.log("getCustomDataCache ", data);
                return data;
            }

            workspaceFactory.setDataCache= function(_data_key,_data){
                workspaceFactory.DataCache[_data_key] = _data;
            }

            workspaceFactory.getDataCache = function(data_key){
                return workspaceFactory.DataCache[data_key];
            }

            workspaceFactory.prepareBreadCumb = function(){
                scope.breadcrumbArray = [];
                scope.$watch(function () {
                    return workspaceFactory.activeScreen
                }, function (val) {
                    scope.breadcrumbArray = [];
                    buildBreadCrumbData(val)
                })
                function buildBreadCrumbData(val) {
                    scope.breadcrumbArray.push(val.label);
                    if (val.parent) {
                        buildBreadCrumbData(val.parent)
                    } else {
                        scope.breadcrumbArray.reverse();
                    }
                }
                console.log('breadcrumbArraybreadcrumbArray',scope.breadcrumbArray);
            }
            
            return workspaceFactory;
        }])
    return services;
});