define(
    [ 'angular',
        './cfcSplitOnOwnerPercentService',
    ],
    function() {
        'use strict';

        var controllers = angular
                .module('app.cfcSplitOnOwnerPercentCtrl', ['app.cfcSplitOnOwnerPercentService'])
                .controller('cfcSplitOnOwnerPercentCtrl',
                        [ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService','workspaceFactory',
                                '$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','cfcSplitOnOwnerPercentFactory','$http', cfcSplitOnOwnerPercentCtrl ])

        function cfcSplitOnOwnerPercentCtrl($rootScope, $scope, $state, $log,
                JsonObjectFactory, AlertService, GlobalService, workspaceFactory, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, cfcSplitOnOwnerPercentFactory,$http) {

            var vm = this;	
            //Show the loading Spinner
            vm.loadingData = true;	
            vm.eoyAdjmentsLoading = false;		
            //Selected Row on the Grid passed to Modal/Popup to display in top table
            vm.selectedGridRow = [];

            vm.cfc_combination_key = 0;

            vm.adjustmentDtls = [];
            vm.originalEoYAdjAmt = 0;
            vm.isSplitOnOwnership = '';
            vm.isOwnershipChanged = '';
            vm.splitOnOwnershipOld = '';
            
            //A flag to enable and disable save button
            vm.isSaveClicked = false;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};	
            //Get the Grid filter Scenario
            vm.scenario = filterParams.scenario;  	
            //Get the jcd_key from left nav
            vm.jcd_key  = GlobalService.globalParams.jcd_key;
            
            //cancel the pop-up
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
            
            //To get the Lock_Y_N flag from backend
            var lockparams = {
                    "action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
                };				
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
                vm.is_locked = data.jsonObject;				
                //To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
                $scope.$watch(function() { return vm.is_locked }, function() {
                    if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
                            vm.cancel();										
                            AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
                            return;
                    }							
                });
            });

            //Retrieve the existing adjustment records saved for the given Code Combination Key 
            vm.getEoYAdjustmentDtls = function() {		
                var splitOwnerFlag = '';
                vm.eoyAdjmentsLoading = true;
                if (vm.isSplitOnOwnership != null && vm.isSplitOnOwnership != '') {
                    splitOwnerFlag = vm.isSplitOnOwnership == 'Yes'? 'Y' : 'N';
                }
                var params = {"action_code" : 'y3tal3',"p_cfc_combination_key":vm.cfc_combination_key, "p_split_on_ownership": splitOwnerFlag}; 
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                        vm.adjustmentDtls = data.jsonObject;
                        vm.loadingData = false;
                        vm.eoyAdjmentsLoading = false;
                });					
            }

             //When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
             if (gridData != null) {					
                var tempGridData = _.clone(gridData), groups = Object
                        .create(null), result, finalKeyCheck = 0;					
                
                vm.gridDataMultipleSelect = _.clone(tempGridData);					
                
                if (vm.gridDataMultipleSelect != undefined) {						
                    vm.selectedGridRow.push( _.clone(vm.gridDataMultipleSelect.rowData));
                    vm.cfc_combination_key = vm.selectedGridRow[0].CFC_COMBINATION_KEY;
                    vm.originalEoYAdjAmt = vm.selectedGridRow[0].BOY_BASIS_AMT + vm.selectedGridRow[0].ADJ_BASIS_AMT;
                    vm.isOwnershipChanged = vm.selectedGridRow[0].IS_OWNERSHIP_CHANGED;
                    vm.splitOnOwnershipOld = vm.selectedGridRow[0].IS_SPLIT_OWNERSHIP;
                    vm.getEoYAdjustmentDtls();	
                    vm.isSplitOnOwnership = vm.selectedGridRow[0].IS_SPLIT_OWNERSHIP;
                }
            }

            vm.save = function() {	
                console.log("received the EOY Adjustment details: ", vm.adjustmentDtls); 					
                 vm.isSaveClicked = true;
                
                 let enteredTotal = 0;
                 for (var row in vm.adjustmentDtls) {
                    if (vm.isSplitOnOwnership == undefined  || vm.isSplitOnOwnership == null  || vm.isSplitOnOwnership == ""  
                        || vm.adjustmentDtls[row].AMOUNT == undefined  || vm.adjustmentDtls[row].AMOUNT == null  
                        ) {
                            AlertService.add("error", "Please select/enter all the required fields.",4000);
                            vm.isSaveClicked = false;
                            return;
                        }
                        enteredTotal = enteredTotal + vm.adjustmentDtls[row].AMOUNT;
                 }

                if (enteredTotal.toFixed(2) == vm.originalEoYAdjAmt.toFixed(2)) {
                    //continue;
                } else {
                    AlertService.add("error", "Total of the entered EoY adjustment should be equal to (BoY amount and Adjustment amount)",4000);
                    vm.isSaveClicked = false;
                    return;
                }

                if (vm.isSplitOnOwnership != vm.splitOnOwnershipOld) {
                    
                }
                
                prepAndSendDetails();
            }

            function prepAndSendDetails() {
                var returnClobSettingsObj = {};
                 returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                 returnClobSettingsObj['object_id'] = vm.cfc_combination_key;
                 console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
                 var message = "EoY Adjustment Amount(s) have been saved/updated";		
                 var dataToSave = [];

                 for (var row in vm.adjustmentDtls) {
                    var tempObj = {};
                    tempObj["cfc_combination_key"] = vm.adjustmentDtls[row].CFC_COMBINATION_KEY;
                    tempObj["us_sh_combination_key"] = vm.adjustmentDtls[row].US_SH_COMBINATION_KEY;
                    tempObj["adj_key"] = vm.adjustmentDtls[row].ADJ_KEY;
                    tempObj["ownership_ratio"] = vm.adjustmentDtls[row].CURR_RATIO;
                    tempObj["amount"] = vm.adjustmentDtls[row].AMOUNT;

                    if (vm.isSplitOnOwnership == 'Yes') {
                        tempObj["cfc_combination_key"] = vm.adjustmentDtls[row].CFC_COMBINATION_KEY;
                        tempObj["us_sh_combination_key"] = vm.adjustmentDtls[row].US_SH_COMBINATION_KEY;
                        tempObj["operation_type"] = 'D';
                    } else {
                        if (vm.adjustmentDtls[row].ADJ_KEY == null || vm.adjustmentDtls[row].ADJ_KEY == 0) {
                            tempObj["operation_type"] = 'I';
                        }
                        else {
                            tempObj["operation_type"] = 'U';
                        }
                    }
                    dataToSave.push(tempObj);
                 }
                 
                 //send to save the Data					
                 sendDetails(returnClobSettingsObj, dataToSave, message);  
            }
           
            //Send Details to the Service Layer for persisting the data
            function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
                cfcSplitOnOwnerPercentFactory.saveData(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {
                    if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                        AlertService.add("error", result.data.errorMessage, 4000);
                        vm.isSaveClicked = false;
                    } else {
                        AlertService.add("success", message, 4000);
                        var args = {
                                object_id: returnClobSettingsObj.object_id,
                                gridFilter: {
                                    object_id: returnClobSettingsObj.object_id
                                }
                            };
                        //vm.getEoYAdjustmentDtls();
                        $uibModalInstance.dismiss('cancel');
                        $rootScope.$emit('gridUpdate', args);
                        };
                });
            }

        }
    }
);