define(['highstock',"angular"], function (Highcharts) {
    "use strict";

    // Load module after Highcharts is loaded
    require('highcharts-more')(Highcharts);
    require('highcharts-3d')(Highcharts);
    require('highcharts-exporting')(Highcharts);
    require('highcharts-treemap')(Highcharts);
    require('highcharts-variable-pie')(Highcharts);
    require('highcharts-drilldown')(Highcharts);
    require('highcharts-sunburst')(Highcharts);

    Highcharts.setOptions({
        lang: {
            numericSymbols: ['K', 'M', 'B','T']
        }
    });

    var directives = angular
        .module("app.dashboardDirectivesNew", [])

        .directive('gtDashboard', ['workspaceFactory', 'dashboardFactory','USER_SETTINGS', gtDashboard])

        .directive('gtComponentResolver', ['$rootScope', 'componentFactory', 'strategyFactory', 'dashboardFactory', '$compile', 'workspaceFactory', gtComponentResolver])

        .directive('gtChart', ['defaultChartConfig', '$rootScope', '$timeout','darkThemeHighcharts','USER_SETTINGS', gtChart])

        .directive('gtHorizontalBizzGroup', gtHorizontalBizzGroup)

        .directive('gtKpiBox', gtKpiBox)

        .directive('gtHorizontalBizzTreeMap', gtHorizontalBizzTreeMap)

        .directive('gtRegularTreeMap', ['defaultChartConfig', '$timeout', 'dataFormatterFactory', gtRegularTreeMap])

        .directive('gtCombinationChart', ['defaultChartConfig', '$rootScope', 'componentFactory', '$compile', gtCombinationChart])

        .directive('gtDynamicHtml', gtDynamicHtml)

    function gtDashboard(workspaceFactory, dashboardFactory,USER_SETTINGS) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            controller: ['dashboardFactory', '$scope', '$rootScope', '$uibModal', 'workspaceFactory','MessageFactory','GENERAL_CONFIG','$timeout','$state', DashboardController],
            controllerAs: 'ctrl',
            link: link,
            // restrict: 'A',
            scope: {
                //  options: '='
                dashboardApi: '='
            },
            // template:'<h1>Direct</h1>'
            templateUrl: 'app/components/dashboardExecutive/templates/dashboard-directive.html'
        };
        return directive;

        function link(scope, element, attrs, ctrl) {
            console.log(workspaceFactory);
            ctrl.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';

            // var lastSelectedFilterList = [];
            var activeScreen = workspaceFactory.activeScreen;
            if (activeScreen && activeScreen.data) {
                scope.filtersSelected = true;
            }


        }
    }
    DashboardController.$inject= ['dashboardFactory', '$scope', '$rootScope', '$uibModal', 'workspaceFactory','MessageFactory','GENERAL_CONFIG','$timeout','$state','USER_SETTINGS'];
    function DashboardController(dashboardFactory, $scope, $rootScope, $uibModal, workspaceFactory,MessageFactory,GENERAL_CONFIG,$timeout,$state,USER_SETTINGS) {
        var vm = this;
        var listener = {};
        vm.options = {};
        var isSheetCache = true;
        $scope.filtersSelected = false;
        vm.filters = [];
        var activeScreen = workspaceFactory.activeScreen;
        if (activeScreen && activeScreen.data) {
            vm.options.dashboard_name = activeScreen.data.dashboard_name || activeScreen.data.name;
            vm.options.dashboard_id = activeScreen.data.dashboard_id || activeScreen.data.id;
            vm.options.data_sheets = activeScreen.data.data_sheets || activeScreen.data.dataSheets;
            vm.options.components = activeScreen.data.components || activeScreen.data.components;
            vm.options.filters = activeScreen.data.filters || activeScreen.data.filters;
            isSheetCache = false;
         //:?   activeScreen.commentLink = null;
            activeScreen.data.firstLoad = 0;
            init();
        }

        $rootScope.$on('currentTheme',function(ev,theme) {
            vm.currentTheme = theme;
        })

        function viewRawData(component){
            console.log("actionOnClick", component);

            var sheet = vm.dashboard.dataSheets.find(function(sheet){
                return sheet.name == component.srcSheet
            })
            var data;
            if(sheet){
                data = sheet.data
            }
            // Open Modal
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/dashboardExecutive/templates/raw-data.html',
                controller: 'gtViewRawDataCtrl',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    component: function(){
                        return {
                            title: component.title,
                            data: data,
                            component_id: component.component_id
                        }
                    }
                }
            });

            modalInstance.result.then(function () {

            }, function () {

            });
        }

        function init() {
            vm.dashboard = dashboardFactory.createOrUpdateDashboard(vm.options.dashboard_name, vm.options.dashboard_id, vm.options.data_sheets, vm.options.components);
            vm.dashboardLoaded = vm.dashboard.dataLoaded;
            vm.filters = vm.dashboard.getFilters();
            vm.footerFiltersLists = vm.dashboard.getFooterFilters();

            vm.actions = [
                { title: "View raw data", icon: "fa fa-table", onClick: viewRawData },
                { title: "Edit Component", icon: "fa fa-edit", onClick: editComponentPopup, adminOnly:true }
            ]

            console.log(workspaceFactory, vm, vm.dashboard);
            $scope.filtersSelected = true;
            var filterparams = (activeScreen.filters) ? activeScreen.filters.getFilterParams() : {};
                //workspaceFactory.setCurrentFilters(activeScreen);
                workspaceFactory.checkFilters(workspaceFactory.activeScreen);

            if (isSheetCache) { /*call the sheet when filter click event other wise take from the sheets cache */
                // activeScreen.loading_data = true;
                vm.dashboard.loadSheets(filterparams/*, ctrl.options.data_sheets*/, activeScreen);

                for (var key in vm.dashboard.dataSheets) {
                    if (listener['sheet-' + vm.dashboard.dataSheets[key].name + ':loaded'])
                        listener['sheet-' + vm.dashboard.dataSheets[key].name + ':loaded']();
                    // } else {
                    // each sheets loaded,
                    listener['sheet-' + vm.dashboard.dataSheets[key].name + ':loaded'] = $scope.$on('sheet-' + vm.dashboard.dataSheets[key].name + ':loaded',
                        function (e, sheetName) {
                            filterSetup(sheetName);
                            console.log("filters", vm.filters);

                            vm.dashboardLoaded = true;
                            workspaceFactory.setCache(activeScreen, vm.dashboard);
                        });
                    //}
                }
            } else {
                if (vm.filters.length === 0) {//filters not cached
                    for (var key in vm.dashboard.dataSheets) {
                        filterSetup(vm.dashboard.dataSheets[key].name);
                    }
                }
            }

        }

        if (!vm.options) {
            vm.errorMessage = "No options provided.";
        }

        $scope.filtersSelected = false;

        $rootScope.$broadcast('dataFilters:waitingForFilters');

        var refreshGridListener = $scope.$on('dataFilters:refreshGrid', function (event, data) {

            if (vm.dashboardApi) {
                activeScreen.loading_data = true;
                $scope.dashboardLayoutLoading = true;
                dashboardFactory.getDashboardComponent(vm.dashboardApi).then(function (data) {
                    $scope.dashboardLayoutLoading = false;
                    if (data != null) {
                        vm.options = data.dashboard;
                        // activeScreen.data = vm.options;
                        workspaceFactory.setCache(activeScreen, vm.options);
                        workspaceFactory.setCurrentFilters(activeScreen);
                        isSheetCache = true;
                        init(isSheetCache);

                    }
                });

            }
            //commented 2nd param as it is not being used anywhere
        });
        //  var dashboard = dashboardFactory.createOrUpdateDashboard(vm.options.dashboard_name, vm.options.dashboard_id, vm.options.data_sheets, vm.options.components);




        if (listener['dashboard:meSelected']) {
            listener['dashboard:meSelected']();
            console.log('destroyed listener', 'dashboard:meSelected');
        }
        listener['dashboard:meSelected'] = $scope.$on('dashboard:meSelected', function (e, node,cntrlKeyDown) {
            var meFilter = vm.filters.find(function (item) {
                return item.name === 'ME'//Hardcode ME?
            })
            if(!cntrlKeyDown){
                meFilter.selected = [];
                meFilter.model = [];
            }
            if (meFilter != undefined) {
                meFilter.model = meFilter.model || [];
                if (!meFilter.model.includes(node.name)) {
                    meFilter.model.push(node.name);
                    meFilter.selected.push({
                        name: node.name,
                        value: node.name
                    });
                } else {
                    meFilter.selected.splice(meFilter.selected.indexOf(node), 1);
                    meFilter.model.splice(meFilter.model.indexOf(node.name), 1);
                }
            }
            filterChange();
        });
        listener['dashboard:loaded'] = $scope.$on('dashboard:loaded', function () {
            vm.dashboardLoaded = true;
        });
        if (listener['dashboard:loaded']) {
            listener['dashboard:loaded']();
            console.log('destroyed listener', 'dashboard:loaded');
        }


        /**
         * Methods
         */
        function editComponentPopup(component) {
            var editComponentPopupInstance = $uibModal.open({
                templateUrl: 'app/components/admin/adminDashboards/add-chart-component-tpl.html',
                controller: 'addChartComponentCtrl as ctrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    createComponentData: function () {
                        return {
                            editObj: component,
                            sheetsObj: angular.copy(vm.dashboard.dataSheets),
                            fullData:true,
                            dashboard_id:component.dashboard_id,
                            rowIndex:component.rowIndex,
                            columns:component.columns
                        }
                    }
                }
            });

            editComponentPopupInstance.result.then(function (componentWithPreviewData) {
                // AlertService.add("success", "Component updated succesfully", 4000);
                var row = vm.options.components[(+componentWithPreviewData.rowIndex)-1];

                var componentToEdit = _.find(row,["component_id",componentWithPreviewData.component_id]);

                componentToEdit.component_id= componentWithPreviewData.component_id;
                componentToEdit.comp_name= componentWithPreviewData.comp_name;
                componentToEdit.title= componentWithPreviewData.title;
                componentToEdit.columns= componentWithPreviewData.columns;
                componentToEdit.strategy= componentWithPreviewData.strategy;
                componentToEdit.height= componentWithPreviewData.height;
                componentToEdit.filterable= componentWithPreviewData.filterable ;
                componentToEdit.srcSheet= componentWithPreviewData.srcSheet;
                componentToEdit.component_type= componentWithPreviewData.component_type;
                componentToEdit.dashboard_id= componentWithPreviewData.dashboard_id;
                componentToEdit.extraOptions= componentWithPreviewData.extraOptions;
                componentToEdit.rowIndex= componentWithPreviewData.rowIndex;
                componentToEdit.row_title= componentWithPreviewData.row_title;
                $scope.$broadcast('component-' + componentToEdit.component_id + ':reload',componentToEdit,true);
            })
        }
        function filterChange() {
            for (var key in vm.filters) {
                if (vm.filters.hasOwnProperty(key)) {
                    var filter = vm.filters[key];
                    var colSearch = vm.dashboard.tableState.colSearch.find(function (item) {
                        return item.name == filter.name
                    })
                    if (colSearch && filter.model)
                        colSearch.value = filter.model;
                    $rootScope.$broadcast('dashboard:filter:' + filter.name.toLowerCase() + '-changed', filter);
                }
            }
            vm.dashboard.pipe();
            vm.footerFiltersLists = [];
            for (var key in vm.filters) {
                if (!Array.isArray(vm.filters[key])) {
                    if (vm.filters[key].selected.length > 0) {
                        vm.filters[key].tooltip = vm.filters[key].selected.map(function (item) {
                            return item.name
                        }).join(', ');
                        vm.footerFiltersLists.push(vm.filters[key]);
                    }
                }
            }
            vm.dashboard.setFilters(vm.filters);
            vm.dashboard.setFooterFilters(vm.footerFiltersLists);
        }
        function filterSetup(sheetName) {
            // if (dashboard.tableState.colSearch.length === 0) {
            if (vm.options && vm.options.filters != undefined) {
                var filters = vm.options.filters.filter(function (filter) {
                    return filter.sheet ? filter.sheet.includes(sheetName) : false;
                });
                for (var key in filters) {
                    var filter = filters[key];
                    filter.value = [];
                    var filterIndex = _.findIndex(vm.filters, ['name', filter.name]);
                    if (filterIndex === -1) {
                        vm.filters.push(buildFilter(filter));
                        console.log('pushed filter', filter.name);
                    } else {
                        vm.filters[filterIndex] = buildFilter(filter);
                        console.log('updated filter', filter.name);
                    }
                    _.remove(vm.dashboard.tableState.colSearch, ['name', filter.name])//remove oudated object
                    vm.dashboard.tableState.colSearch.push(filter);
                }
            }
        }
        function buildFilter(filter) {
            var filterObj = filter;
            filterObj.selected = [];
            filterObj.values = [];
            filterObj.name = filter.name;
            filterObj.order = filter.order;
            filter.sheet = _.uniq(filter.sheet);//Temp solution by removing duplicates which is causing due to an issue in Back end.
            var filterparams = (activeScreen.filters) ? activeScreen.filters.getFilterParams() : {};

            var sheets = vm.dashboard.dataSheets.filter(function (item) {
                return filter.sheet.includes(item.name) && item.isLoaded;
            })

            if (sheets.length !== filter.sheet.length) {
                return filterObj;//returning as we only want to do the concatination when all the sheets are loaded.
            }

            _.each(sheets, function (sheet, key) {
                filterObj.values = _.unionBy(filterObj.values, _.chain(sheet.data).filter(function (item) {
                    return item["TAX_YEAR"] ? (item["TAX_YEAR"].toString() === filterparams["tax_year"].toString()) : true
                }).groupBy(filter.predicate).map(function (v, key) {
                    return {
                        name: key,
                        value: key
                    };
                }).sortBy('name').reject(['value','undefined']).value(), 'value');
            })

            return filterObj;
        }
        function clearFilters() {
            for (var key in vm.filters) {
                if (vm.filters.hasOwnProperty(key)) {
                    var filter = vm.filters[key];
                    filter.model = [];
                    filter.selected = [];
                }
            }
            filterChange();
        };
        function clearFilter(filter) {
            var filterToClear = vm.filters.find(function (item) {
                return item.name === filter.name;
            });
            filterToClear.model = [];
            filterToClear.selected = [];
            filterChange(filterToClear);
        };
        vm.filterChange = filterChange;
        vm.clearFilters = clearFilters;
        vm.clearFilter = clearFilter;
        vm.editComponentPopup = editComponentPopup;

        $scope.$on('$destroy',function() {

            for(var key in listener){
                listener[key]();
            }
            if(activeScreen && activeScreen.commentLink!=undefined && activeScreen.filters && activeScreen.filters.filters.length>0){
              //  $rootScope.$broadcast("dataFilters:dataBuoyLoad", true);
                dataBuoyload();
            }
           //
        })
    }


    ///////Component Resolver///////////////

    //Decides which compoent to render
    //Call the strategy function and get the right data for the component.
    // gtComponentResolver.$inject = [];
    function gtComponentResolver($rootScope, componentFactory, strategyFactory, dashboardFactory, $compile, workspaceFactory) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            controller: ComponentResolverController,
            controllerAs: 'ctrl',
            link: link,
            scope: {
                config: '=',
                hideLoader: '='
            },
            // templateUrl: 'app/components/dashboardExecutive/templates/component-resolver.html'
        };
        return directive;

        function link(scope, element, attrs, ctrl) {
            ctrl.loading = false;
            var listeners = {};
            var sheet;
            var filterparams = {};
            var activeScreen = workspaceFactory.activeScreen;
            var template = componentFactory.getComponent(ctrl.config.component_type);
            //If Admin Screen Adding/Editing Component
            if (ctrl.config.previewMode) {
                sheet = angular.copy(ctrl.config.previewSheetData);
            }
            else {

                if (!ctrl.hideLoader)
                    $(element).append($compile('<div modal-loader loading="ctrl.loading"></div>')(scope));

                var dashboard = dashboardFactory.getDashboardById(ctrl.config.dashboard_id);
                var noDataMsg = $(element).find('.component-error-message');


                if (dashboard) {
                    sheet = angular.copy(dashboard.dataSheets.find(function (item) {
                        return item.name === ctrl.config.srcSheet;
                    }));

                    if (!ctrl.config.filterable) {
                        sheet.data = sheet.safeCopyArr;
                    }
                }

            }



            if (sheet) {
                if (sheet.isLoaded) {
                    renderComponent(sheet);
                } else {
                    ctrl.loading = true;
                }
            }
            else {
                ctrl.loading = false;
                element.append($compile(template)(scope));
            }

            if (listeners['sheet:filtered']) {
                listeners['sheet:filtered']();
                console.log('destroyed listener', 'sheet:filtered');
            }


            // scope.$root.$on('dataFilters:refreshGrid', function (event, data) {
            //     //  if(data && data.refresh){

            //     filterparams = (activeScreen.filters) ? activeScreen.filters.getFilterParams() : {};
            //     workspaceFactory.setCurrentFilters(activeScreen);


            //     // }
            // });
            if (listeners['component-' + ctrl.config.component_id + ':reload']) {
                listeners['component-' + ctrl.config.component_id + ':reload']();
            }
            listeners['component-' + ctrl.config.component_id + ':reload'] =
                scope.$on('component-' + ctrl.config.component_id + ':reload', function (e,newComponentObj,doUpdate) {
                    if (dashboard && doUpdate) {
                        var sheetData = dashboard.dataSheets.find(function (item) {
                            return item.name === ctrl.config.srcSheet;
                        });
                        ctrl.config = newComponentObj || ctrl.config;
                        renderComponent(sheetData);
                    } else {
                        if (ctrl.config.previewSheetData) {
                            // element[0].childNodes[element[0].childNodes.length - 1].remove();//remove old component

                            renderComponent(ctrl.config.previewSheetData);
                        }
                    }
                })



            listeners['sheet:filtered'] = scope.$on('sheet:filtered', function (e, sheetName) {
                if (ctrl.config.srcSheet !== sheetName || !ctrl.config.filterable) {
                    return;
                }
                if (dashboard) {
                    sheet = angular.copy(dashboard.dataSheets.find(function (item) {
                        return item.name === sheetName;
                    }));

                    if (sheet) {
                        // element[0].childNodes[element[0].childNodes.length - 1].remove();//remove old component

                        renderComponent(sheet);
                    }
                }
                else {
                    if (ctrl.config.previewSheetData) {
                        // element[0].childNodes[element[0].childNodes.length - 1].remove();//remove old component

                        renderComponent(ctrl.config.previewSheetData);
                    }
                }
            })

            function checkFilterParams(sheet) {
                filterparams = (activeScreen.filters) ? activeScreen.filters.getFilterParams() : {};
                console.log("Filter Params", filterparams);

                ctrl.config.extraOptions.componentSettings = ctrl.config.extraOptions.componentSettings || {}
                if (ctrl.config.extraOptions.strategySettings && ctrl.config.extraOptions.strategySettings.default_filter_by && _.keys(filterparams).includes(ctrl.config.extraOptions.strategySettings.default_filter_by.toLowerCase())) {



                    // ctrl.config.extraOptions.strategySettings.default_filter_by = 'TAX_YEAR'
                    ctrl.config.extraOptions.strategySettings.default_filter_value = filterparams[ctrl.config.extraOptions.strategySettings.default_filter_by.toLowerCase()]
                }
            }
            function defaultComponentFilter(sheet) {

                if (ctrl.config.extraOptions && ctrl.config.extraOptions.strategySettings && ctrl.config.extraOptions.strategySettings.is_default_filter && ctrl.config.extraOptions.componentSettings && ctrl.config.extraOptions.strategySettings.default_filter_by) {
                    if (sheet) {
                        if (ctrl.config.extraOptions.strategySettings.default_filter_value) {
                            sheet.data = _.chain(sheet.data).groupBy(ctrl.config.extraOptions.strategySettings.default_filter_by).find(function (item, name) {
                                return name === ctrl.config.extraOptions.strategySettings.default_filter_value
                            }).value();

                            sheet.safeCopyArr = angular.copy(sheet.data);
                        }

                        else {
                            sheet.data = _.chain(sheet.data).groupBy(ctrl.config.extraOptions.strategySettings.default_filter_by).values().first().value()
                        }
                    }
                }
            }

            function renderComponent(sheet, filteredSheet) {
                ctrl.loading = sheet.isLoading;
                if (ctrl.loading) {
                    return;
                }

                var noDataMsg = $(element).find('.component-error-message');

                if (noDataMsg.length > 0) {
                    $(noDataMsg).remove();
                }
                if (dashboard) {
                    var filtersApplied = _.chain(dashboard.tableState.colSearch).filter(function (item) {
                        return item.sheet.includes(ctrl.config.srcSheet) && item.value.length > 0;
                    }).map(function (item) {
                        return item.name;
                    }).join(',').value();

                    if (ctrl.config.title) {
                        if (sheet.safeCopyArr.length !== sheet.data.length) {
                            if (filtersApplied) {
                                ctrl.config.title = ctrl.config.title.split('(filtered')[0];
                                ctrl.config.title = ctrl.config.title + ' (filtered by ' + filtersApplied + ')';
                            }
                        } else {
                            ctrl.config.title = ctrl.config.title.split('(filtered')[0];
                        }
                    }
                }

                if (!ctrl.config.component_type) {
                    ctrl.loading = false;
                    $(element).append('<h4 class="component-error-message" >Please select component type.</h4>');
                    return;
                }



                $(element).find('.component-container').remove();



                if (!Array.isArray(sheet.data)) {
                    ctrl.loading = false;
                    $(element).append('<h4 class="component-error-message" >Error occured while fetching data</h4>');
                    return;
                }

                if (ctrl.config && ctrl.config.extraOptions && _.isString(ctrl.config.extraOptions)) {
                    ctrl.config.extraOptions = JSON.parse(JSON.stringify(eval("(" + (ctrl.config.extraOptions) + ")")))
                }

                checkFilterParams(sheet)
                defaultComponentFilter(sheet)


                if ((!sheet.data || sheet.data.length === 0) && !ctrl.loading) {
                    ctrl.loading = false;
                    $(element).append('<h4 class="component-error-message" >No Data Available</h4>');
                    return;
                }



                if (!ctrl.config.strategy && ctrl.config.component_type !== 'combinationChart') {
                    $(element).append('<h4 class="component-error-message" >Please select Strategy Type.</h4>');
                    return;
                }
                if (ctrl.config.component_type !== 'combinationChart') {
                    var componentData = strategyFactory.runStrategy(ctrl.config.strategy, sheet.data, ctrl.config.extraOptions.strategySettings);
                    // if(ctrl.config.extraOptions.componentSettings.componentData && ctrl.config.extraOptions.componentSettings.componentData.series){
                    //     // componentData = _.merge(componentData,ctrl.config.extraOptions.componentSettings.componentData)
                    // }
                    if (componentData.errorMsgs && componentData.errorMsgs.length > 0) {
                        $(element).append('<h4 class="component-error-message"><ul><li class="mt-10">' + componentData.errorMsgs.join('</li><li>') + '</li></ul></h4>');
                        ctrl.loading = false;
                        return;
                    }

                    ctrl.config.data = componentData;
                }


                if(!template){
                    template = componentFactory.getComponent(ctrl.config.component_type);
                }

                ctrl.loading = false;
                element.append($compile('<div class="component-container">' + template + '</div>')(scope));
            }

            if (listeners['sheet-' + ctrl.config.srcSheet + ':loaded']) {
                listeners['sheet-' + ctrl.config.srcSheet + ':loaded']();
                console.log('destroyed listener', 'sheet-' + ctrl.config.srcSheet + ':loaded');
            }
            listeners['sheet-' + ctrl.config.srcSheet + ':loaded'] = scope.$on('sheet-' + ctrl.config.srcSheet + ':loaded', function () {
                var dashboard = dashboardFactory.getDashboardById(ctrl.config.dashboard_id);
                var sheet = angular.copy(dashboard.dataSheets.find(function (item) {
                    return item.name === ctrl.config.srcSheet;
                }));

                renderComponent(sheet);

            })

            if (listeners['sheet-' + ctrl.config.srcSheet + ':loading']) {
                listeners['sheet-' + ctrl.config.srcSheet + ':loading']();
                console.log('destroyed listener', 'sheet-' + ctrl.config.srcSheet + ':loading');
            }
            listeners['sheet-' + ctrl.config.srcSheet + ':loading'] = scope.$on('sheet-' + ctrl.config.srcSheet + ':loading', function () {
                $(element).find('.component-container').remove();
                var noDataMsg = $(element).find('.component-error-message');

                if (noDataMsg.length > 0) {
                    $(noDataMsg).remove();
                }
                ctrl.loading = true;

            })

            scope.$on('$destroy',function() {
                for(var key in listeners){
                    listeners[key]();
                }

            })
        }
    }
    /* @ngInject */
    function ComponentResolverController() {
        var vm = this;
        var jsonValidString;
        /* converting string to map dvalaboju001 */
        if (vm.config && vm.config.extraOptions) {
            jsonValidString = JSON.stringify(eval("(" + JSON.stringify(vm.config.extraOptions) + ")"));//Remove inner Json.stringify when using data from DB
            vm.config.extraOptions = JSON.parse(jsonValidString);
        }

        vm.options = vm.config;

        console.log("in component resolver:", vm.options);

    }


    /////////Chart///////////////

    // gtComponentResolver.$inject = [];
    function gtChart(defaultChartConfig, $rootScope, $timeout,darkThemeHighcharts,USER_SETTINGS) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            controller: ChartController,
            controllerAs: 'ctrl',
            link: link,
            scope: {
                options: '='
            },
            // templateUrl: 'app/components/dashboardExecutive/templates/chart.html'
        };
        return directive;

        function link(scope, element, attrs, ctrl) {
            /*  */
            var elementId = 'component-' + ctrl.options.component_id;
            // if(document.getElementById(elementId)){
            //     document.getElementById(elementId).remove(); // to clear existing chart
            // }
            element[0].id = elementId;//'component-' + ctrl.options.component_id;
            /* var container = null;
            var chart = null, resizerSensor = null; commented divya*/
            ctrl.chartConfig.chart.events = ctrl.chartConfig.events || {};
            ctrl.chartConfig.chart.events.load = function () {
                ctrl.resizerSensor = new ResizeSensor(ctrl.container, resizeChart);
            }
            ctrl.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';

            ctrl.defaultThemeConfig = angular.copy(ctrl.chartConfig);
            if(ctrl.currentTheme === 'theme-black'){
                ctrl.chartConfig = Highcharts.merge(ctrl.chartConfig,darkThemeHighcharts);
            }

            scope.$on('currentTheme',function(ev,theme) {
                ctrl.currentTheme = theme;
                if(ctrl.currentTheme === 'theme-black'){
                    ctrl.chartConfig = Highcharts.merge(ctrl.chartConfig,darkThemeHighcharts);
                }else{
                    ctrl.chartConfig = ctrl.defaultThemeConfig;
                }

                // ctrl.chart && ctrl.chart.update(ctrl.chartConfig);
                if(ctrl.chart){
                    ctrl.chart.destroy();
                    ctrl.chart = Highcharts.chart(element[0], ctrl.chartConfig);
                }
            })

            $timeout(function () {

                ctrl.container = element.closest('.gt-dashboard-ex-body');
                ctrl.chartConfig.chart.height = ctrl.container.height();
                // ctrl.chartConfig.plotOptions.packedbubble.maxSize = `${(ctrl.chartConfig.chart.height / 2) - 50}px`;

                ctrl.chart = Highcharts.chart(element[0], ctrl.chartConfig);

                if(ctrl.chartConfig.chart.type == 'waterfall'){
                    ctrl.sortChartData('sort-desc');
                }


                //Added timeout as the element needs to appended to the dom in component resolver
            }, 100);



            scope.$on('$destroy', function () {
                if (ctrl.chart) ctrl.chart.destroy();
                ctrl.chart = null;
                element.remove();
                if (ctrl.resizerSensor)
                    ctrl.resizerSensor.detach();
            })

            /**
             * Methods
             */

            var timeout = null;

            function resizeChart() {
                if(timeout)
                    clearTimeout(timeout);

                var width;
                var height
                width = $(ctrl.container).width();
                height = $(ctrl.container).height();

                timeout = setTimeout(doneResizing, 50);//This is to avoid calling the setsize on each animation frame


                if (ctrl.chart && !ctrl.options.extraOptions.componentSettings.disableSmoothScale) {
                    setSize();
                }

                function doneResizing(){
                    if(ctrl.chartConfig.disableSmoothScale)
                        setSize();
                }

                function setSize(){
                    if (!ctrl.chartConfig.skipWidthResize)
                    ctrl.chart.setSize(width, height, false);
                    else
                    ctrl.chart.setSize(ctrl.chart.chartWidth, height, false);
                    // ctrl.chartConfig.plotOptions.packedbubble.maxSize = `${height - 50}px`;
                }

            }
        }
    }
    ChartController.$inject = ['defaultChartConfig', '$rootScope','$timeout', 'dataFormatterFactory'];
    function ChartController(defaultChartConfig, $rootScope, $timeout, dataFormatterFactory) {
        var vm = this;
        // var chartTypes = [
        //     { name: "separator" },
        //     { name: 'Line' },
        //     { name: 'Area' },
        //     { name: 'Bar' },
        //     { name: 'Column' },
        //     { name: 'Pie' },
        // ]

        var singleSeriesChartTypes = [
            "separator",
            'pie',
            'donut',
            'packedbubble',
            'waterfall'
        ]

        var multiSeriesChartTypes = [
            "separator",
            'line',
            'spline',
            'area',
            'bar',
            'column',
        ]

        //console.log("in Chart component:", vm.options);

        init();
        setupFormatters();
        setupChangeChartTypeOptions();
        setupSeries();
        if(vm.chartConfig.series.length === 1 && vm.chartConfig.customColors && vm.chartConfig.customColors.enabled){
            setupCustomColors();
        }

        // Setup sorting options only for multi series charts
        if(vm.chartConfig.chart.type && multiSeriesChartTypes.includes(vm.chartConfig.chart.type.toLowerCase()))
            setupSortingOptions();

        function init() {
            vm.chartConfig = _.merge(angular.copy(defaultChartConfig), vm.options.extraOptions.componentSettings);
            //console.log(vm.chartConfig)
            // vm.chartConfig =angular.copy(defaultChartConfig);
            // vm.chartConfig.chart.type = vm.options.extraOptions.type;
            if (vm.options.extraOptions.componentSettings.isCombinationPie) {
                vm.options.data.series[0].data[0].selected = true;
                vm.options.data.series[0].data[0].sliced = true;
            }
            vm.chartConfig.xAxis.categories = vm.options.data.categories;
            if(!vm.chartConfig.xAxis.categories){
                vm.chartConfig.xAxis.type = 'category';
            }
            vm.chartConfig.series = vm.options.data.series;
            vm.options.data.drilldown && (vm.chartConfig.drilldown = vm.options.data.drilldown);
            vm.chartConfig.plotOptions.series.point = {};
            vm.chartConfig.plotOptions.series.point.events = {};
            vm.chartConfig.plotOptions.series.point.events.click = function () {
                $rootScope.$emit(vm.options.component_id, this);
                if(vm.options.extraOptions.componentSettings.eventMessage)
                    $rootScope.$emit(vm.options.extraOptions.componentSettings.eventMessage, this);
            };

            vm.chartConfig.exporting.chartOptions ={
                title:{
                    text:vm.options.title
                }
            }
        }


        function setupSeries() {
            if (vm.options.extraOptions.componentSettings.dataOverrides &&
                vm.options.extraOptions.componentSettings.dataOverrides.series) {
                _.each(vm.options.data.series, function (series) {
                    var editedSeries = _.find(vm.options.extraOptions.componentSettings.dataOverrides.series, ['name', series.name]) ||
                                            _.get(vm.options.extraOptions.componentSettings.dataOverrides.series,0);
                    if (editedSeries) {
                        if(editedSeries.color || series.color)
                            series.color = editedSeries.color || series.color;
                        if(editedSeries.stacking)
                            series.stacking = editedSeries.stacking;
                        series.name = editedSeries.displayName || series.name;
                        series.allowDrillToNode = _.isUndefined(editedSeries.allowDrillToNode) ? series.allowDrillToNode : editedSeries.allowDrillToNode;
                        series.colorByPoint = _.isUndefined(editedSeries.colorByPoint) ? series.colorByPoint : editedSeries.colorByPoint;
                    }
                });
            }
        }

        function setupChangeChartTypeOptions() {
            if(!vm.chartConfig.chart.type) return;

            var chartTypes = singleSeriesChartTypes.includes(vm.chartConfig.chart.type.toLowerCase()) ? singleSeriesChartTypes : multiSeriesChartTypes;
            for (var i = 0; i < chartTypes.length; i++) {
                vm.chartConfig.exporting.menuItemDefinitions[chartTypes[i]] = {
                    text: chartTypes[i],
                    onclick: changeChartType
                };
                vm.chartConfig.exporting.buttons.contextButton.menuItems.unshift(chartTypes[i]);
            }
        }

        function setupSortingOptions() {
            [
                { name: 'separator', text: 'separator' },
                { name: 'sort-desc', text: 'Sort Descending' },
                { name: 'sort-asc', text: 'Sort Ascending' },
            ].forEach(function (item) {
                vm.chartConfig.exporting.menuItemDefinitions[item.name] = {
                    text: item.text,
                    onclick: function () {
                        vm.sortChartData(item.name);
                    }
                };
                vm.chartConfig.exporting.buttons.contextButton.menuItems.unshift(item.name);
            })
        }

        function setupCustomColors () {
            vm.chartConfig.series[0].data = _.chain(vm.chartConfig.series[0].data).sortBy('y').reverse().value();
            if(vm.chartConfig.series[0].data[0] && vm.chartConfig.series[0].data[0].y !== 0)
            vm.chartConfig.series[0].data[0].color = Highcharts.getOptions().colors[1];
            if(vm.chartConfig.series[0].data[1] && vm.chartConfig.series[0].data[1].y !== 0)
            vm.chartConfig.series[0].data[1].color = Highcharts.getOptions().colors[2];
            if(vm.chartConfig.series[0].data[2] && vm.chartConfig.series[0].data[2].y !== 0)
            vm.chartConfig.series[0].data[2].color = Highcharts.getOptions().colors[3];
        }

        function setupFormatters() {
            var dataLabels = vm.chartConfig.plotOptions.series.dataLabels || { enabled: false };
            vm.currentDatalabelFormatter = angular.copy(dataLabels.formatter);
            if (dataLabels.enabled &&
                typeof dataLabels.formatter !== "function") {
                dataLabels.formatter = dataLabelFormatter;

            }
            var seriesLess = ['pie','variablepie'];
            if(seriesLess.includes(vm.chartConfig.chart.type) && vm.chartConfig.plotOptions.series.dataLabels.enabled){
                vm.chartConfig.plotOptions[vm.chartConfig.chart.type].dataLabels = vm.chartConfig.plotOptions.series.dataLabels;
            }
            var stackLabels = vm.chartConfig.yAxis.stackLabels || { enabled: false };
            vm.currentStackLabelFormatter = angular.copy(stackLabels.formatter);
            if (stackLabels.enabled && stackLabels.formatter && !stackLabels.format &&
                typeof stackLabels.formatter !== "function") {
                stackLabels.formatter = stackLabelsFormatter;

            }

            console.log("stacklabels rendered", stackLabels);
            var tooltip = vm.chartConfig.tooltip || { enabled: false };
            vm.currentTooltipFormatter = angular.copy(tooltip.formatter);

            if (tooltip.enabled &&
                typeof tooltip.formatter !== "function") {
                tooltip.pointFormatter = tooltipFormatter;
                tooltip.useHTML = true;
                delete tooltip.formatter;
            }
        }

        function dataLabelFormatter(e, ops) {
            var formatter = dataFormatterFactory.getFormatter(vm.currentDatalabelFormatter);
            if (!formatter) {
                return this.y;
            }

            let format = (formatter.func ? formatter.func(this.y,this.point.isNegative,this.point.name) : this.y);
            if(vm.chartConfig.plotOptions.series.dataLabels.seriesAsLabel){
                format = this.point.series.name;
            }

            // var positiveValue = "<span class='text-center' style='margin: 0px !important;font-size : 12px;'>" + format + ' </span>';
            // var negativeValue = "<span class='text-center' style='margin: 0px !important;font-size : 12px;color:red'>" + format + ' </span>';

            if (this.y === 0) {
                return null;
            }
            else {
                // return (this.y < 0 ? negativeValue : positiveValue);
                return format;
            }
        }

        function stackLabelsFormatter(e, ops) {
            var formatter = dataFormatterFactory.getFormatter(vm.currentStackLabelFormatter);
            if (!formatter) {
                return this.total;
            }
            var percent;
            if (formatter.name === 'Percent') {
                percent = (this.points['0'][0] / this.points['0'][1]) * 100;
            }
            var positiveValue = "<span class='text-center' style='margin: 0px !important;font-size : 12px;'>" +
                (formatter.func ?
                    (
                        formatter.name === 'Percent' ? formatter.func(Math.abs(percent)) :
                            formatter.func(Math.abs(this.total))
                    )
                    : Math.abs(this.total)) + ' </span>';
            var negativeValue = "<span class='text-center' style='margin: 0px !important;font-size : 12px;color:red'>" +
                (formatter.func ?
                    (
                        formatter.name === 'Percent' ? formatter.func(Math.abs(percent)) :
                            formatter.func(Math.abs(this.total))
                    )
                    : Math.abs(this.total)) + ' </span>';

            return ((this.total < 0 || this.percent < 0) ? negativeValue : positiveValue);

        }
        function tooltipFormatter(e) {
            var formatter = dataFormatterFactory.getFormatter(vm.currentTooltipFormatter);
            if (!formatter || formatter.name === 'None') {
                return this.tooltipFormatter(e);
            }
            var legend = '<span style="color:' + this.color + '">●</span>';
            var seriesName = '<span class="text-center" style="margin: 0px !important;font-size : 12px;">' +
                this.series.name + ":</span>";
            var positiveValue = "<span class='text-center' style='margin: 0px !important;font-size : 12px;font-weight:bold'>" +
                (formatter.func ? formatter.func(Math.abs(this.y),this.isNegative,this.name) : Math.abs(this.y)) + ' </span></br>';
            var negativeValue = "<span class='text-center' style='margin: 0px !important;font-size : 12px;color:red';font-weight:bold>" +
                (formatter.func ? formatter.func(Math.abs(this.y),this.isNegative,this.name) : Math.abs(this.y)) + ' </span></br>';

            // if (this.y === 0) {
            //     return null;
            // }
            // else {
                return '<div>' + legend + (this.series.name && this.category ? seriesName : "") + (this.y < 0 ? negativeValue : positiveValue) + '</div>';
            // }
        }

        function changeChartType(e) {
            var newChartType;
            if (vm.chartConfig.series.length > 0) {

                if (e.target.innerText.toLowerCase() === 'donut') {
                    newChartType = 'variablepie';
                }
                else {
                    newChartType = e.target.innerText.toLowerCase()
                }
                vm.options.extraOptions.componentSettings.chart.type = newChartType;
                $rootScope.$broadcast('component-' + vm.options.component_id + ':reload',undefined,true);
                $rootScope.$apply()


            }
        }

        vm.sortChartData = function(sort) {
            var order = sort.split("-")[1];
            var arrayToSort = [];

            /**
             * Interchanging x & y axis of 2d array and calculate sum of each row
             * (rotate 2D arary by 90deg clockwise)
             */
            var isObject = typeof vm.chart.options.series[0].data[0] == 'object';
            vm.chart.options.series[0].data.forEach(function (x, i) {
                let sum = 0;
                vm.chart.options.series.forEach(function (y) {
                    sum += isObject ? y.data[i].y : y.data[i];

                    if (!arrayToSort[i]) {
                        arrayToSort[i] = [];
                    }
                    arrayToSort[i].push(y.data[i]);
                });
                arrayToSort[i].unshift(vm.chart.options.xAxis[0].categories[i]);
                arrayToSort[i].unshift(sum);
            })

            // Sorting array based on first element (sum)
            arrayToSort.sort(function (a, b) {
                return order == 'asc' ? a[0] - b[0] : b[0] - a[0];
            })

            // rotate sortedArray by 90deg anti-clockwise
            for (let i = 2; i < arrayToSort[0].length; i++) {
                let temp = [];
                let categories = [];
                arrayToSort.forEach(function (y, j) {
                    // temp.push([arrayToSort[j][1], y[i]])
                    temp.push(isObject ? y[i].y : y[i])
                    categories.push(arrayToSort[j][1])
                })

                vm.chart.series[i - 2].setData(temp);
                vm.chart.xAxis[0].setCategories(categories)
            }

            // vm.chart.series.forEach(function (series, i) {
            //     series.setData(_.orderBy(vm.chart.options.series[i].data, [], [order]))
            // })

            // vm.chart.options.series.forEach(function (item) {
            //     item.dataSorting = { enabled: enable }
            // })

            // vm.chart = Highcharts.chart(vm.chart.renderTo, vm.chart.options)
        }

    }

    function gtCombinationChart(defaultChartConfig, $rootScope, componentFactory, $compile) {
        var directive = {
            link: link,
            scope: {
                options: "="
            },
            bindToController: true,
            controller: combinationChartController,
            controllerAs: 'ctrl',//TODO:Rename
            templateUrl: "app/components/dashboardExecutive/templates/combinationChart.html",//TODO:Renane file
        }

        return directive;

        function link(scope, element, attrs, ctrl) {


            $rootScope.$on(ctrl.parentConfig.component_id, function (event, data) {
                var name = data.series.name || data.name
                ctrl.childConfig.extraOptions.strategySettings.default_filter_value = name
                ctrl.childConfig.extraOptions.componentSettings.chart.height = $(element).closest('.gt-dashboard-ex-body').height()

                $(element[0].children[0]).find(".col-md-6")[1].remove()
                $(element[0].firstChild.children).append($compile('<div class="col-md-6"><gt-component-resolver config="ctrl.childConfig"></gt-component-resolver></div>')(scope))


            })

        }

        function combinationChartController() {
            var vm = this
            vm.parentConfig = vm.options.extraOptions.componentSettings.parentChartOptions
            vm.childConfig = vm.options.extraOptions.componentSettings.childChartOptions



            vm.parentConfig.extraOptions.componentSettings.skipWidthResize = true
            vm.childConfig.extraOptions.componentSettings.skipWidthResize = true

            vm.parentConfig.extraOptions.componentSettings.isCombinationPie = true;


            console.log("vm", vm)

        }
    }

    function gtHorizontalBizzGroup() {
        var directive = {
            link: link,
            scope: {
                options: "="
            },
            bindToController: true,
            controller: horizontalBizzGroupController,
            controllerAs: 'horizontalBizzGroupCtrl',//TODO:Rename
            templateUrl: "app/components/dashboardExecutive/templates/horizontalBizzGroup.html",//TODO:Renane file
        }



        function link(scope, element, attrs, horizontalBizzGroupCtrl) {

            /* if(element){
                element.remove(); // to clear existing chart
            } */

            horizontalBizzGroupCtrl.options.selectedItems = {
                selected: []
            }
            console.log(horizontalBizzGroupCtrl.options.selectedItems)

            scope.$on('$destroy', function () {
                element.remove();
            })

            horizontalBizzGroupCtrl.moveToSelectedEntity = moveToSelectedEntity;

            function moveToSelectedEntity(selectedMe) {
                var selectedNode = $(element).find("#" + selectedMe.id);
                $(element).find(".comparision-chart").animate({
                    scrollLeft: $(element).find(".comparision-chart").scrollLeft()
                        + selectedNode.position().left
                        - selectedNode.width() / 2
                        - $(element).find(".comparision-chart").width() / 4
                }, 200)
                console.log(selectedNode)
            }
        }
        horizontalBizzGroupController.$inject = ['$rootScope', '$scope', 'dashboardFactory'];
        function horizontalBizzGroupController($rootScope, $scope, dashboardFactory) {
            var vm = this;
            var selectedMes = [];
            vm.initialHighlighted = true;
            var meFilterName = 'me';

            getCachedSelections();

            function getCachedSelections() {
                var dashboard = dashboardFactory.getDashboardById(vm.options.dashboard_id);
                var filters = dashboard.filters;
                console.log(filters);

                setFilter(null, _.find(filters, ["name", "ME"]));
            }
            vm.nodeClicked = function (node) {
                selectOrUnselectMe(node);

                vm.initialHighlighted = selectedMes.length === 0;

                $rootScope.$broadcast('dashboard:meSelected', node);
            }
            function selectOrUnselectMe(node) {
                if (!selectedMes.includes(node)) {
                    selectedMes.push(node);
                    node.selected = true;
                } else {
                    node.selected = false;
                    selectedMes.splice(selectedMes.indexOf(node), 1);
                }
            }





            $scope.$on('dashboard:filter:' + meFilterName + '-changed', setFilter);

            function setFilter(e, filter) {

                var deletedMes = _.difference(_.map(selectedMes, 'value'), filter.model);
                var newMes = _.difference(filter.model, _.map(selectedMes, 'value'));

                if (deletedMes.length > 0) {
                    _.remove(selectedMes, function (item) {
                        return deletedMes.includes(item.value);
                    });
                }

                if (newMes.length > 0) {
                    selectedMes = _.concat(selectedMes, _.filter(vm.options.data, function (item) {
                        return newMes.includes(item.value);
                    }))
                }

                for (var i = 0; i < vm.options.data.length; i++) {
                    var me = vm.options.data[i];

                    if (deletedMes.includes(me.value)) {
                        me.selected = false;
                    } else if (newMes.includes(me.value)) {
                        me.selected = true;
                        if (vm.moveToSelectedEntity)
                            vm.moveToSelectedEntity(me)
                    }

                }

                vm.initialHighlighted = selectedMes.length === 0;
            }

        }
        return directive;

    }

    gtKpiBox.$inject = [];
    function gtKpiBox() {
        var directive = {
            link: link,
            scope: {
                options: '='
            },
            controller: kpiController,
            // bindToController: true,
            // controllerAs: 'ctrl',
            templateUrl: "app/components/dashboardExecutive/templates/gtKpiBox.html",
        }


        function link(scope, attrs, element) {

        }
        kpiController.$inject = ['$scope', 'dataFormatterFactory','$sce'];
        function kpiController($scope, dataFormatterFactory,$sce) {
            activate();

            // $scope.$on('component-' + $scope.options.component_id + ':reload', function () {
            //     activate();
            // })

            function activate() {
            console.log("activate called")

                if($scope.options.extraOptions.componentSettings.alwaysNegative){
                    $scope.options.data = Math.abs($scope.options.data) * -1;
                }
                if ($scope.options.extraOptions.componentSettings.format) {
                    var formatter = dataFormatterFactory.getFormatter($scope.options.extraOptions.componentSettings.format, $scope.options.component_type);
                    if (formatter && formatter.func) {
                        $scope.numberData = dataFormatterFactory.getFormatter("Number").func($scope.options.data,false,true);
                        $scope.options.data = $sce.trustAsHtml(formatter.func($scope.options.data));

                    }

                    // if($scope.options.extraOptions.componentSettings.percentBy){

                    // }
                }
            }
        }
        return directive;
    }

    function gtHorizontalBizzTreeMap() {
        var directive = {
            link: link,
            scope: {
                options: "=",
            },
            bindToController: true,
            controller: horizontalBizzTreeMapController,
            controllerAs: 'horizontalBizzTreeMapCtrl',
            templateUrl: "app/components/dashboardExecutive/templates/horizontalBizzTreeMapTemplate.html",
        }

        function link(scope, element, attrs, horizontalBizzTreeMapCtrl) {

            console.log(horizontalBizzTreeMapCtrl)
            calculateNodeWidths(horizontalBizzTreeMapCtrl.options.extraOptions.componentSettings.areaWidth, horizontalBizzTreeMapCtrl.options.data.nodes, parseFloat(horizontalBizzTreeMapCtrl.options.extraOptions.componentSettings.minWidthPercentage), true)
            var CNTRL_KEY = 17;
            var container = $(element).find('.nodes');
            container.on("mousewheel", scrollHorizontally);//chrome, IE
            container.on("DOMMouseScroll", scrollHorizontally);//firefox
            var $body = $('body');
            $body.on('keydown',bodyKeyDownHandler);
            $body.on('keyup',bodyKeyUpHandler);

            console.log("body events",$._data($('body')[0]).events);
            function bodyKeyDownHandler(e) {
                if(e.keyCode = CNTRL_KEY){
                    horizontalBizzTreeMapCtrl.cntrlKeyDown = true;
                }
            }

            function bodyKeyUpHandler(e) {
                if(e.keyCode = CNTRL_KEY){
                    horizontalBizzTreeMapCtrl.cntrlKeyDown = false;
                }
            }
            scope.$on('$destroy',function() {
                $body.unbind('keydown',bodyKeyDownHandler);
                $body.unbind('keyup',bodyKeyUpHandler);
                console.log("body events",$._data($('body')[0]).events);
            })

            function scrollHorizontally(e) {

                e = window.event || e.originalEvent;
                var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                console.log("delta", delta)
                container[0].scrollLeft -= delta * 100;
                e.preventDefault();
            }

            horizontalBizzTreeMapCtrl.toggleTreeChart = function (event) {
                horizontalBizzTreeMapCtrl.collapse = !horizontalBizzTreeMapCtrl.collapse
                var icon = $(element).find('.icon');
                if (horizontalBizzTreeMapCtrl.collapse) {
                    icon.addClass('icon-compressed');
                    // icon.find('i').addClass('rotate');
                    var containerWidth = event.currentTarget.parentElement.clientWidth
                }
                else {
                    var containerWidth = horizontalBizzTreeMapCtrl.options.extraOptions.componentSettings.areaWidth
                    icon.removeClass('icon-compressed');
                    icon.children().addClass('streching');
                    setTimeout(function () {
                        icon.children().removeClass('streching');
                        // icon.find('i').removeClass('rotate');
                    }, 500)

                }

                calculateNodeWidths(containerWidth, horizontalBizzTreeMapCtrl.options.data.nodes, parseFloat(horizontalBizzTreeMapCtrl.options.extraOptions.componentSettings.minWidthPercentage), !horizontalBizzTreeMapCtrl.collapse)
                // vm.options.extraOptions.areaWidth =
            }

            horizontalBizzTreeMapCtrl.moveToSelectedEntity = moveToSelectedEntity;

            function moveToSelectedEntity(id) {
                var selectedNode = $(element).find("#node-" + id);
                $(element).find(".nodes").animate({
                    scrollLeft: $(element).find(".nodes").scrollLeft()
                        + selectedNode.position().left
                        - selectedNode.width() / 2
                        - $(element).find(".nodes").width() / 4
                }, 200)
                console.log(selectedNode)
            }



        }


        horizontalBizzTreeMapController.$inject = ['$scope', '$rootScope', 'dashboardFactory'];
        function horizontalBizzTreeMapController($scope, $rootScope, dashboardFactory) {
            var vm = this
            vm.collapse = false
            vm.options.extraOptions.componentSettings.minWidthPercentage = "5%";
            vm.options.extraOptions.componentSettings.areaWidth = 2000;
            
            var meFilterName = 'me';
            var selectedMes = [];
            vm.initialHighlighted = true;
            getCachedSelections();

            vm.options.extraOptions.componentSettings.invertColors && invertColors();

            function invertColors(){
                vm.options.data.nodes.forEach(node => {
                    node.color === "#4eb523" ? node.color = "#e86153" : node.color = "#4eb523";
                });
            }
            vm.nodeClicked = function (node) {
                if(!vm.cntrlKeyDown){
                    selectedMes = [];
                    for(var i = 0;i<vm.options.data.nodes.length;i++){
                        vm.options.data.nodes[i].selected = false;
                    }
                }

                selectOrUnselectMe(node);

                vm.initialHighlighted = selectedMes.length === 0;

                $rootScope.$broadcast('dashboard:meSelected', node,vm.cntrlKeyDown);
            }
            function selectOrUnselectMe(node) {
                if (!selectedMes.includes(node)) {
                    selectedMes.push(node);
                    node.selected = true;
                } else {
                    node.selected = false;
                    selectedMes.splice(selectedMes.indexOf(node), 1);
                }
            }

            function getCachedSelections() {
                var dashboard = dashboardFactory.getDashboardById(vm.options.dashboard_id);

                if(dashboard){
                    var filters = dashboard.filters;
                    console.log(filters);


                    setFilter(null, _.find(filters, ["name", "ME"]));
                }
            }
            $scope.$on('dashboard:filter:' + meFilterName + '-changed', setFilter)

            function setFilter(e, filter) {

                if(!filter){
                    return;
                }
                var deletedMes = _.difference(_.map(selectedMes, 'name'), filter.model);
                var newMes = _.difference(filter.model, _.map(selectedMes, 'name'));

                if (deletedMes.length > 0) {
                    _.remove(selectedMes, function (item) {
                        return deletedMes.includes(item.name);
                    });
                }

                if (newMes.length > 0) {
                    selectedMes = _.concat(selectedMes, _.filter(vm.options.data.nodes, function (item) {
                        return newMes.includes(item.name);
                    }))
                }

                for (var i = 0; i < vm.options.data.nodes.length; i++) {
                    var me = vm.options.data.nodes[i];

                    if (deletedMes.includes(me.name)) {
                        me.selected = false;
                    } else if (newMes.includes(me.name)) {
                        me.selected = true;
                        if (vm.moveToSelectedEntity && newMes.length === 1)
                            vm.moveToSelectedEntity(i)
                    }

                }

                vm.initialHighlighted = selectedMes.length === 0;
            }

        }

        return directive;

        /**
            * Function Description : Calculates the node widths based on container width and minimum percentage
            * @param {*} areaWidth
            * @param {*} nodes
            * @param {*} minWidth
        */
        function calculateNodeWidths(areaWidth, nodes, minWidth, flag) {
            var totalSum = _.sumBy(nodes, 'value')
            var minWidthPercentage = (areaWidth * minWidth) / 100
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].width = (nodes[i].value * areaWidth) / totalSum;
                if (nodes[i].width < 10) {
                    nodes[i].extraWidth = 10 - nodes[i].width;
                    nodes[i].width = 10;
                }
                if (flag) {
                    if (nodes[i].width < minWidthPercentage) {
                        nodes[i].width = minWidthPercentage
                    }
                }
            }

            var totalExtraWidth = _.sumBy(nodes, 'extraWidth');
            var largerNodes = nodes.filter(function (item) {
                return item.width > 10;
            })
            var extraWidthToDistribute = totalExtraWidth / largerNodes.length;
            nodes.map(function (item) {
                if (!flag) {
                    if (item.width > 10) {
                        item.width = item.width - extraWidthToDistribute
                    }
                }
                return item;
            })
            console.log(totalExtraWidth)
        }
    }

    function gtRegularTreeMap(defaultChartConfig, $timeout, dataFormatterFactory) {
        var directive = {
            link: link,
            scope: {
                options: "=",
            },
            bindToController: true,
            controller: regularTreeMapController,
            controllerAs: 'ctrl',
        }



        function link(scope, element, attrs, ctrl) {



            ctrl.chartConfig.series = []
            ctrl.chartConfig.series.push(ctrl.options.extraOptions.componentSettings)
            ctrl.chartConfig.series[0].data = ctrl.options.data.topLevelData
            ctrl.chartConfig.drilldown = ctrl.options.data.drilldown
            ctrl.chartConfig.plotOptions.series.dataLabels.enabled = true;
            element[0].id = 'component-' + ctrl.options.component_id;


            var container = null;
            var chart = null, resizerSensor = null;
            ctrl.chartConfig.chart.events = ctrl.chartConfig.events || {};
            ctrl.chartConfig.chart.events.load = function () {
                resizerSensor = new ResizeSensor(container, resizeChart);
            }


            $timeout(function () {
                container = element[0]
                chart = Highcharts.chart(container, ctrl.chartConfig)
                //Added timeout as the element needs to appended to the dom in component resolver
            }, 100);

            scope.$on('$destroy', function () {
                if (chart) chart.destroy();
                chart = null;
                element.remove();
                if (resizerSensor)
                    resizerSensor.detach();
            })

            /**
             * Methods
             */

            function resizeChart() {
                var width;
                var height
                width = $(container).width();
                height = $(container).height();

                if (chart)
                    chart.setSize(width, height, false);
            }
        }
        regularTreeMapController.$inject = ['defaultChartConfig', 'dataFormatterFactory'];
        function regularTreeMapController(defaultChartConfig, dataFormatterFactory) {
            var vm = this;
            vm.chartConfig = {}

            vm.chartConfig = _.merge(angular.copy(defaultChartConfig), vm.options.extraOptions.componentSettings);
            //delete vm.chartConfig.plotOptions
            setupFormatters();
            function setupFormatters() {

                var tooltip = vm.chartConfig.tooltip || { enabled: true };
                vm.currentTooltipFormatter = angular.copy(tooltip.formatter);
                // tooltip.enabled = true;
                if (tooltip.enabled &&
                    typeof tooltip.formatter !== "function") {
                    tooltip.pointFormatter = tooltipFormatter;
                    tooltip.useHTML = true;
                    delete tooltip.formatter;
                }

                function tooltipFormatter(e) {
                    var formatter = dataFormatterFactory.getFormatter(vm.currentTooltipFormatter);
                    if (!formatter) {
                        return this.tooltipFormatter(e);
                    }
                    var legend = '<span style="color:' + this.color + '">●</span>';
                    var seriesName = '<span class="text-center" style="margin: 0px !important;font-size : 12px;">' +
                        this.name + ":</span>";
                    var positiveValue = "<span class='text-center' style='margin: 0px !important;font-size : 12px;font-weight:bold'>" +
                        (formatter.func ? formatter.func(Math.abs(this.value)) : Math.abs(this.value)) + ' </span></br>';
                    var negativeValue = "<span class='text-center' stvaluele='margin: 0px !important;font-size : 12px;color:red';font-weight:bold>(" +
                        (formatter.func ? formatter.func(Math.abs(this.value)) : Math.abs(this.value)) + ' )</span></br>';

                    if (this.y === 0) {
                        return null;
                    }
                    else {
                        return '<div>' + legend + seriesName + (this.y < 0 ? negativeValue : positiveValue) + '</div>';
                    }
                }
            }
        }
        return directive;
    }

    function gtDynamicHtml(){
        var directive = {
            link: link,
            scope: {
                options: "=",
            },
            bindToController: true,
            controller: dynamicHtmlController,
            controllerAs: 'dynamicHtmlCtrl',
            templateUrl: "app/components/dashboardExecutive/templates/dashboard-dynamic-html-tpl.html",
        }

        function link(scope, element, attrs, ctrl) {

        }
        
        dynamicHtmlController.$inject = ['$scope', '$sce'];
        function dynamicHtmlController($scope, $sce) {
            var vm = this;
            vm.htmlContent = vm.options.data[0].HTMLSTATICTEXT;
            vm.html = $sce.trustAsHtml(vm.htmlContent);

        }

        return directive;
    }


});