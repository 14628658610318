
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.templateServices',[])

        .factory("TemplateAdminFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope) {
            var templateAdminFactory = {};
            var totalModulesAssigned = 0;
            
            var URLS = {
                updateTemplate: 'xxxxxxxxxxx',
                getGtwModules:GENERAL_CONFIG.admin_api_url+ '/getGtwModules',
                setAccess:GENERAL_CONFIG.admin_api_url+ '/setModuleAccess?template_key=',
                findTemplate: 'xxxxxxxxxxxxx',
                addTemplate: 'xxxxxxxxxxxxxxxxx',
                forceFetchTotalAssignedModules: GENERAL_CONFIG.admin_api_url+'/getTemplateModuleCount',
                setUserAccessAll: GENERAL_CONFIG.admin_api_url+'/setAllUserAccess'
            }
            
            templateAdminFactory.forceFetchTotalAssignedModules = function(data){
            	var promise = $http({
                    method: "POST",
                    url: URLS.forceFetchTotalAssignedModules,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            }
            
            templateAdminFactory.updateTemplate = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.updateUser,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                    	console.log("response.data ::",response.data);
                        return response.data;
                    }else {
                         if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };
            
            templateAdminFactory.getGtwModules = function(data){
                console.log("templateAdminFactory.getGtwModules()");
            	var promise = $http({
                    method: "POST",
                    url: URLS.getGtwModules,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        AlertService.add("error", "User could not be found.", 4000);
                    }
                });
                return promise;
            };

            templateAdminFactory.setAccess = function(data,_userId,rowPrimaryKey,client_key){
                var params = {};
                params.jcd_ta_key = data.jcd_ta_key;
                params.jcd_ta_role_key = data.role.value;
                params.template_key = rowPrimaryKey;
                params.logged_in_user_sso_id = _userId;
                params.client_key = client_key;
            	var promise = $http({
                    method: "POST",
                    url: URLS.setAccess,
                    data: params
                }).then(function (response) {
                	totalModulesAssigned = response.data.callSuccess;
                	$rootScope.$broadcast('totalmods:changed',totalModulesAssigned);
                    // AlertService.add("success", "Data saved successfully.", 4000);
                    return response.data;
                });
                return promise;
            };
            
            templateAdminFactory.setAccessAll = function(nodes,user_id,rowPrimaryKey,client_key) {
            	var nodeObj = JSON.parse(nodes);
            	var data =  _.extend({data : JSON.stringify(nodeObj) });
            	
                
                var promise = $http({
                    method: "POST",
                    url: URLS.setAccess + rowPrimaryKey,
                    data:data                    
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        AlertService.add("success", "Data saved successfully.", 4000);
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };


            templateAdminFactory.findTemplate = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.findUser,
                    data: data
                }).then(function (response) {
                        return response.data;
                });
                return promise;
            };


            templateAdminFactory.addTemplate = function(data){
                var promise = $http({
                    method: "POST",
                    url: URLS.getUserAssign,
                    data: data
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){


                        return response.data;


                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

            return templateAdminFactory;
        }])


    return services;


});