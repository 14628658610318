
define([
    'angular',
    './trialBalanceController',
    './trailBalanceService',
    './reviewTrialBalanceAutomation',
], function () {
    'use strict';
    return angular.module('app.trailBalanceAmount', ['app.trialBalanceController','app.trialBalanceService', 'app.reviewTrialBalanceAutomation' ])
});
