
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.SampleAppServices',[])

    .factory('SampleAppFactory', ['$rootScope','$http','AlertService','GlobalService','JsonObjectFactory', function($rootScope,$http,AlertService,GlobalService,JsonObjectFactory){
        var SampleAppFactory = {};

        SampleAppFactory.list = [
            {id:1,name:"Superman",powers:"All",age:1087,height:"6'1",weight:"1200"},
            {id:2,name:"The Incredible Hulk",powers:"Strength",age:54,height:"6'3",weight:"400"},
            {id:3,name:"Batman",powers:"Inventiveness",age:44,height:"6'1",weight:"210"}
        ]


        SampleAppFactory.getList = function(){
            return SampleAppFactory.list ;
        }


        return SampleAppFactory


    }])



    return services;


});