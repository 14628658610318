
define([
    'angular',
    './WorkspaceController',
    './WorkspaceServices'


   
], function () {
    'use strict';
    return angular.module('app.workspace', ['app.workspaceController','app.workspaceServices'])


        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider

            .state('workspace', {
                url: '/workspace',
                "templateUrl": "app/components/workspace/workspace-tpl.html",
                "controller": "WorkspaceController as ctrl",
                backdrop: 'static',
                 access: ""
            })


            .state('workspace.maintenance', {
                url: '/workspace-maintenance',
                templateUrl: 'app/templates/maintenance-screen-tpl.html',
                backdrop: 'static',
                access: ""
            })


            .state('workspace.notAssigned', {
                url: '/workspace-not-assigned',
                templateUrl: 'app/templates/workspace-not-assigned-tpl.html',
                backdrop: 'static',
                access: ""
            })



            .state('workspace.404', {
                url: '/workspace-404',
                "templateUrl": "app/components/workspace/workspace-404-tpl.html",
                "controller": "WorkspaceController as ctrl",
                backdrop: 'static',
                access: ""
            })


            .state('workspace-memory', {
                url: '/workspace-memory',
                "templateUrl": "app/components/workspace/workspace-memory-tpl.html",
                "controller": "WorkspaceMemoryController as ctrl",
                backdrop: 'static',
                 access: ""
            });




        }]);




});