/**
 * 
 */
define([
    'angular'
    
], function () {
    'use strict';
    
    var services =  angular.module('app.Form4562Service',[])
    
    .factory("Form4562Factory", ['$q', '$http', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory',
            'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG',
            function ($q, $http, $rootScope, $log, GlobalService, DataFiltersFactory,
                workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG) {
                
    	var factory = {}; 
    	factory.formConfigData = {};
    	factory.fetch4562Dtls=function(rowData){
    		var jcd_key = 0;
    		if(rowData.JCD_KEY == undefined){
    			jcd_key = 250;
    		}else jcd_key = rowData.JCD_KEY;
    		var params = { "action_id": 598, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": jcd_key, /*"group_obj_key": 201473*/ "combination_key": /*245596*/rowData.COMBINATION_KEY};
         	 return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
         		 console.log("++++++++++++", data);
                if (data.irsForm.callSuccess && data.irsForm.callSuccess.length > 0 && data.irsForm.callSuccess !== '1') {
                    AlertService.add("error", data.errorMessage);
                    return false;
                } else {
                    factory.formConfigData = data;
                    console.log("form Data Now is::-----", factory.formConfigData);
                    return data;
                }
            });
        
         	
         }


         factory.save4562Form = function (url, data, rowData) {
        	 var jcd_key = 0;
     		 if(rowData.JCD_KEY == undefined){
     			 jcd_key = 250;
     		 }else jcd_key = rowData.JCD_KEY;

             var jsonSettings = { "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": jcd_key };
             var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
             var jsonObj = JSON.stringify(data);
             var jsonSettings  = JSON.stringify(jsonSettings);
             console.log("Json Data::",jsonObj);

             var params = filterParams;
             params = _.merge({},  GlobalService.globalParams , params);
             params =  _.extend({jsonObj:jsonObj}, params);
             params =  _.extend({jsonSettings:jsonSettings}, params);
             console.log("Params::::----------->>>>>>>>>", params);

             params.process_name =  workspaceFactory.activeScreen.label;
             if(params.process_name == undefined){
             	params.process_name = "Form 4562 Save";
             }
             console.log(params);

             var promise = $http({
                 method: "post",
                 url: url,
                 data: params
             }).then(function (response) {

                 //response.data.callSuccess = "1";
                 //response.data.errorMessage = "null"

                 console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                 console.log(response.data);
                 console.log("save4562Form() response.data.jsonObj ::",response.data.jsonObject);

                 return response.data;

             });

             return promise;
         }

    	return factory;     
    }]);
    return services;           
});