define([
    'angular',
    './copyLegacyFilesController',
    './copyLegacyFilesService',
], function () {
    'use strict';
    return angular.module('app.copyLegacyFiles', ['app.CopyLegacyFilesController', 'app.CopyLegacyFilesService'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('copy-legacy-files', {
                url: '/copy-legacy-files',
                "templateUrl": "app/components/DST/copyLegacyFiles/copyLegacyFiles.html",
                "controller": "CopyLegacyFilesCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);
}
);
