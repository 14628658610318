define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.sltIsDuplicateService',[])

        .factory("sltIsDuplicateFactory",['GENERAL_CONFIG', 'AlertService', '$http', '$rootScope',
                function(GENERAL_CONFIG, AlertService, $http, $rootScope) {
          var sltIsDuplicateFactory = {};
          
            var URLS = {
                getSltResponse: GENERAL_CONFIG.base_url + "/getStateGroupDupData"
            }

            //Service call to Get all the user list.. 32653  
            sltIsDuplicateFactory.getSltResponseData = function(tax_year, key, id, flag) { 
                var promise = $http({
                    method: "GET",
                    url: URLS.getSltResponse + '?tax_year='+ tax_year + '&le_key=' + key + '&submission_id=' + id + '&isConsolDupData=' + flag
                }).then(function(response) {
                    if(response.data.callSuccess == "1") {
                        return response.data;
                    } else {
                        AlertService.add("error", "No users list found", 4000);
                    }
                });
                return promise;
            }

            return sltIsDuplicateFactory;
        }])

    return services;
});