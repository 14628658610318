
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.codeCombinationsServices',[])



    .factory('CodeComboFactory', ['$rootScope','$http','AlertService','GlobalService','JsonObjectFactory','GENERAL_CONFIG', function($rootScope,$http,AlertService,GlobalService,JsonObjectFactory,GENERAL_CONFIG){

        var CodeComboFactory = {};
        CodeComboFactory.codeCombo1 = "";
        CodeComboFactory.comboCodeArray = [];
        CodeComboFactory.uniqueMeCodes = [];
        CodeComboFactory.uniqueLedgers=[];
        CodeComboFactory.codeCombinationKey = "";
        CodeComboFactory.toggle = true; //codeForm or codeText
        CodeComboFactory.showForm = false;
        CodeComboFactory.showFormBtn = true;

        CodeComboFactory.disabled = {
            "LeCdrRpt": false,
            "ME": true,
            "Ledger": true
        }


        CodeComboFactory.resetValues = function(){
            CodeComboFactory.codeCombo1 = "";
            CodeComboFactory.codeCombinationKey = "";
            CodeComboFactory.chartKey = "";
            CodeComboFactory.comboCodeArray = [];
            CodeComboFactory.uniqueMeCodes = [];
            CodeComboFactory.uniqueLedgers=[];
            CodeComboFactory.showForm = false;
            CodeComboFactory.showFormBtn = true;
            CodeComboFactory.disabled = {
                "LeCdrRpt": false,
                "ME": true,
                "Ledger": true
            }
        }

        CodeComboFactory.getCombinationsKeys = function (_model) {
            var params = {
                "leid":_model.LEID,
                "action_id": "8",
                "cdr_no":_model.CDR_NO,
                "reporting_period":_model.REPORTING_PERIOD
            }

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ){
                    AlertService.add("error", data.errorMessage);
                }else{
                    CodeComboFactory.disabled.ME = false;
                    CodeComboFactory.comboCodeArray = data.jsonObject;
                    CodeComboFactory.uniqueMeCodes = setUniqueMeCodes(data.jsonObject);
                }
            });
        };

        CodeComboFactory.getCodeCombo = function (val) {
            var params = {
                "action_id": "7",
                "search_string": val
            }

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                 if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                    AlertService.add("error", data.errorMessage);
                    return false;
                }else{
                    return data.jsonObject;
                }
            });
        }

        CodeComboFactory.meSelected = function($model){
            CodeComboFactory.comboCodeArray.selected = null;
            CodeComboFactory.uniqueLedgers =  setLedgerCodes($model);
            CodeComboFactory.disabled.Ledger =  false;

            if(CodeComboFactory.uniqueLedgers.length == 2 ){
                CodeComboFactory.uniqueLedgers.selected = CodeComboFactory.uniqueLedgers[1];
                CodeComboFactory.disabled.Ledger =  true;
                this.setCombinationCode();
            }else{
                CodeComboFactory.uniqueLedgers.selected = CodeComboFactory.uniqueLedgers[0];
            }
        }


        CodeComboFactory.setCombinationCode = function(){
            CodeComboFactory.comboCodeArray.selected =  _.find( CodeComboFactory.comboCodeArray, { 'ME_CODE': CodeComboFactory.uniqueMeCodes.selected.ME_CODE, 'LEDGER': CodeComboFactory.uniqueLedgers.selected.LEDGER });


            CodeComboFactory.codeCombinationKey = CodeComboFactory.comboCodeArray.selected.COMBINATION_KEY;
            CodeComboFactory.chartKey = CodeComboFactory.comboCodeArray.selected.CHART_KEY;
            CodeComboFactory.showForm = true;
            $rootScope.$broadcast('CCKeySet', CodeComboFactory);

        }


        CodeComboFactory.setCombinationCodeByKey = function (_code) {

            var params = {
                "action_id": "9",
                "combination_key": _code
            }

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {

                if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                    AlertService.add("error", data.errorMessage);
                    return false;
                }else{

                    CodeComboFactory.comboCodeArray.selected = data.jsonObject[0];
                    CodeComboFactory.codeCombinationKey = CodeComboFactory.comboCodeArray.selected.COMBINATION_KEY;
                    CodeComboFactory.chartKey = CodeComboFactory.comboCodeArray.selected.CHART_KEY;
                    CodeComboFactory.showForm = true;
                    CodeComboFactory.showFormBtn = false;
                    $rootScope.$broadcast('CCKeySet', CodeComboFactory);

                }




            });
        }



        ///////////////////////// PRIVATE FUNCTIONS ////////////////////////

        function setUniqueMeCodes(_data){
            var uniqueVals = [];
            _.each(_data, function(model){
                if( model.ME_CODE != undefined && model.ME_CODE != ""  ) {
                    var obj = {"ME_CODE": model.ME_CODE, "ME_NAME": model.ME_NAME};
                    if(!_.some(uniqueVals, obj) ){
                        uniqueVals.push(obj)
                    }
                }
            });

            var sortedValues = _.sortBy(uniqueVals, "ME_CODE");
            return  sortedValues;
        }


        function setLedgerCodes($model){

            var _ledgerList =  _.filter( CodeComboFactory.comboCodeArray, { 'ME_CODE': $model.ME_CODE });
            var uniqueVals = [];
            _.each(_ledgerList, function(model){
                var obj = {"LEDGER": model.LEDGER, "LABEL": model.LEDGER};
                if( model.LEDGER != undefined && model.LEDGER != "" ){
                    uniqueVals.push(obj)
                }
            })

            var obj = {"LEDGER": "", "LABEL": "Select Ledger..."};
            uniqueVals.unshift(obj)

            return  uniqueVals;
        }


        return CodeComboFactory



    }])




    return services;


});