define([
    'angular',
    './TransfersReviewReportController'
], function () {
    'use strict';
    var $dstTransfersReviewReportState = null;
    var module = angular.module('app.dstTransfersReviewReport', ['app.TransfersReviewReportControllers'])
        .provider('$dstTransfersReviewReportState', ['$stateProvider', function ($stateProvider) {
            this.$get = function () {
                return {
                    addState: function (stateName, url, controllerAs, templatePrefix, api, gridType, params, views) {
                        $entityMgmtAppState.state('transfers-review-report.' + stateName, {
                            url: '/' + url,
                            type: gridType,
                            "api": api,
                            templateUrl: templatePrefix ? templatePrefix : null,
                            controller: controllerAs ? controllerAs : null,
                            params: params,
                            resolve: {},
                            views: views
                        });
                    }
                };
            }
        }])
        .config(['$stateProvider', function ($stateProvider) {
            $dstTransfersReviewReportState = $stateProvider;
            $stateProvider.state('transfers-review-report', {
                url: '/transfers-review-report',
                "templateUrl": "app/components/DST/diagnostics/transfers-review-report/transfersReviewReport.html",
                "controller": "TransfersReviewReportCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);

    return module;
}
);
