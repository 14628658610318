/**
 * Created by 212544474 on 4/1/2016.
 */
define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.aggredashboardController', [])
					.controller(
							'AggreDashboardController',
							[ '$rootScope', '$scope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'JsonObjectFactory', 'TbReclassFactory',
									'$timeout', 'TbbsService',
									'GENERAL_CONFIG', aggredashboardController ])

			function aggredashboardController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					TbReclassFactory, $timeout, tbbsService, GENERAL_CONFIG) {

				var vm = this || thisObj;

				if ($uibModalInstance) {
					$scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
							: null;
					$scope.cancel = function() {
						$uibModalInstance.close();
						$scope.modal_name = null;
					};
				} else {
					$scope.modal_name = null;
				}

				// 31jsr3
				// rjubnm
				var returnobj = {};
				var message = "";
				var sendjson = [];

				var job_data = (rowData == null) ? _.clone(gridData) : rowData;

				vm.start = function() {
					returnobj = {};
					message = "";
					sendjson = [];

					for ( var e in gridData) {
						returnobj = {};
						returnobj['job_key'] = gridData[e].data.JOB_KEY;
						returnobj['type'] = 'start';
						// returnobj['local_chart_key'] =
						// gridData[e].data.LOCAL_CHART_KEY;
						returnobj['tax_year'] = GlobalService.globalParams.tax_year;
						;
						sendjson.push(returnobj);
					}

					message = 'Aggregation Restarted Successfully';
					returnobj.type = 'start';
					sendDetails(returnobj, sendjson, message);
				};

				vm.conti = function() {
					var returnobj = {};
					var message = "";
					var sendjson = [];
					message = 'Aggregation continued';
					returnobj.type = 'continue';
					sendDetails(returnobj, sendjson, message);
				};

				vm.end = function() {
					returnobj = {};
					message = "";
					sendjson = [];

					for ( var e in gridData) {
						returnobj = {};
						returnobj['job_key'] = gridData[e].data.JOB_KEY;
						returnobj['type'] = 'end';
						// returnobj['local_chart_key'] =
						// gridData[e].data.LOCAL_CHART_KEY;
						returnobj['tax_year'] = GlobalService.globalParams.tax_year;
						;
						sendjson.push(returnobj);
					}

					message = 'Aggregation Ended Successfully';
					returnobj.type = 'start';
					sendDetails(returnobj, sendjson, message);
				};
				function sendDetails(returnobj, sendjson, message) {
					TbReclassFactory.saveaggregation(returnobj, sendjson).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoading = false;

								} else {
									AlertService.add("success", message, 4000);
									$scope.crudLoading = false;
									$uibModalInstance.dismiss('cancel');

									var args = {
										le_key : rowData.LE_KEY,

										gridFilter : {
											le_key : rowData.LE_KEY,
										}
									};
									$rootScope.$emit('gridUpdate', args);

									// $uibModalInstance.dismiss('cancel');
									/*
									 * var args = { local_acct_key:
									 * returnObj.local_acct_key, sys_acct_key :
									 * rowData.SYS_ACCT_KEY, chart_filter :
									 * rowData.LOCAL_CHART_KEY, gridFilter: {
									 * local_acct_key: returnObj.local_acct_key,
									 * sys_acct_key : rowData.SYS_ACCT_KEY,
									 * chart_filter : rowData.LOCAL_CHART_KEY } };
									 * $rootScope.$emit('gridUpdate', args);
									 */

								}
							});

				}

			}// /////////////END CTRL

			return controllers;

		});