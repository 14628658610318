define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.adminDashboardCtrl', [])
        .controller('adminDashboardController', ['$uibModal', adminDashboardController])
        .controller('dashComponentCtrl', ['$uibModalInstance', dashComponentCtrl])
        .controller('adminDashComponentCtrl', ['$uibModalInstance', '$uibModal', 'rowData', 'dashboardFactory', 'GENERAL_CONFIG', 'USER_SETTINGS',
            'GlobalService', 'adminDdashboardFactory', '$q', 'AlertService', '$rootScope', 'JdcManagerFactory', '$scope', adminDashComponentCtrl])
        .controller('addChartComponentCtrl', ['$uibModalInstance', '$uibModal', 'dataFormatterFactory', 'strategyFactory', 'componentFactory',
            'GENERAL_CONFIG', 'adminDdashboardFactory', 'GlobalService', 'createComponentData', '$scope', '$rootScope', '$timeout', 'AlertService','USER_SETTINGS', addChartComponentCtrl])
        .controller('adminDashboardListCtrl', ['$scope', '$rootScope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal',
            '$parse', 'ClientAdminFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', adminDashboardListCtrl])
        .controller('mergeChartComponentCtrl', ['$uibModalInstance', '$uibModal', 'strategyFactory', 'componentFactory',
            'GENERAL_CONFIG', 'adminDdashboardFactory', 'GlobalService', 'mergeComponentData', '$scope', '$rootScope', '$timeout', 'AlertService', mergeChartComponentCtrl])


    function adminDashboardController($uibModal) {
        var vm = this;
        vm.isShowTag1 = true;
        vm.isShowTag2 = true;
        vm.isShowTag3 = true;
        vm.menuOpen = true;
        vm.availCompMenuOpen = true;
        vm.leftPanelItems = [
            {
                "name": "Executive Dashboard",
                children: [
                    { "name": "taxable Income" },
                    { "name": "WWNI" },
                    { "name": "FTC" },
                    { "name": "Project Menu" },
                    { "name": "Project desc" },
                ]
            },
            {
                "name": "Diagnostic Dashboard",
                children: [
                    { "name": "Diagnostic1" },
                    { "name": "Diagnostic2" },
                    { "name": "Diagnostic3" },
                    { "name": "Diagnostic4" },
                    { "name": "Diagnostic5" },
                ]
            }
        ];

        vm.chartCompData = [
            {
                outerBox: [{ box: "box1" }, { box: "box2" }]
            },
            {
                innerBox: [{ "name": "Pie Chart" },
                { "name": "High Chart" }]
            }

        ];

        // vm.addCompList = [{"name": "Pie Chart"},
        // {"name": "High Chart"}];

        vm.availCompList = [
            { "name": "Pie Chart" },
            { "name": "High Chart" },
            { "name": "Horizantal bizz Chart", "class": "hor" },
            { "name": "Circular Chart" },
            { "name": "Bar Chart" }
        ];




        vm.onDrop = function (srcList, srcIndex, targetList, targetIndex) {
            // Copy the item from source to target.
            targetList.splice(targetIndex, 0, srcList[srcIndex]);
            return true;
        };

        vm.toggleMenu = () => vm.menuOpen = !vm.menuOpen;
        vm.availCompToggleMenu = () => vm.availCompMenuOpen = !vm.availCompMenuOpen;


        vm.addDashboardComponent = function () {
            $uibModal.open({
                templateUrl: 'app/components/admin/adminDashboards/admin-dashboard-component.html',
                controller: 'adminDashComponentCtrl as ctrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                }
            });
        }

        vm.openComponent = function () {
            $uibModal.open({
                templateUrl: 'app/components/admin/adminDashboards/dashboard-component-tpl.html',
                controller: 'dashComponentCtrl as ctrl',
                size: 'md',
                backdrop: 'static',
                resolve: {
                }
            });
        }

        vm.addCompRow = function () {
            vm.chartCompData[0].outerBox.push({});
            setTimeout(function () {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            });
        }

        vm.removeBox = function (index) {
            vm.chartCompData[0].outerBox.splice(index, 1)
        }
    }

    function dashComponentCtrl($uibModalInstance) {
        var vm = this;
        vm.title = "Edit Component";




        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function adminDashComponentCtrl($uibModalInstance, $uibModal, rowData, dashboardFactory, GENERAL_CONFIG, USER_SETTINGS, GlobalService,
        adminDdashboardFactory, $q, AlertService, $rootScope, JdcManagerFactory, $scope) {
        var vm = this;
        vm.mode = rowData && rowData.DASHBOARD_KEY ? 'Edit ' : 'New ';
        vm.title = vm.mode + " " + "Admin Dashboard Component";
        vm.dashboard = {};
        vm.selectedColMd = "";
        vm.filters = [];
        vm.actionDataCache = {};
        vm.dashboard = {
            data_sheets: [],
            filters: []
        }
        var COMBINATION_CHART_TYPE = "combinationChart";

        var URLS = {
            removeSheet: '/deleteSheet',
            removeComponent: '/deleteComponentByKey',
            removeFilter: '/deleteDashFilterByKey',
            createComponent: '/createComponent',
            createDashboard: '/createDashboard',
            mergeUnmergeComponent: '/mergeUnmergeComponent'
        }

        // filter.sheetMultiSelectObj.predicates = [];
        var emptyFilterObj = { name: '', predicate: "", filter_key: "", type: "array", operator: "[]", reverse: false, sheet: [] };
        vm.clonedDashboardFilters = [];
        vm.getDashboardByKey = function (dashboard_id) {
            var params = {
                dashboard_id: (rowData != null || rowData != undefined) ? rowData.DASHBOARD_ID : dashboard_id
            }
            //  $scope.loaded = false;
            vm.dashboardLoading = true;
            dashboardFactory.getDashboardComponent(GENERAL_CONFIG.base_url + '/getDashboardByKey', params).then(function (data) {
                vm.dashboardLoading = false;
                if (data != null) {
                    vm.dashboard = data.dashboard;
                    if (data.dashboard && data.dashboard.data_sheets) {
                        vm.allSheets = data.dashboard.data_sheets.map(function (item) {
                            return {
                                name: item.name,
                                value: item.name,
                                action: item.sheet_key != '' ? GENERAL_CONFIG.base_url + '/' + item.action_id : GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=' + item.action_id
                            }
                        })
                    }
                    if (data.dashboard && data.dashboard.filters) {
                        vm.clonedDashboardFilters = _.cloneDeep(data.dashboard.filters);
                        _.each(vm.dashboard.filters, function (value, key) {
                            value.sheetMultiSelectObj = {};
                            value.sheetMultiSelectObj.values = vm.allSheets;
                            value.sheetMultiSelectObj.name = 'Sheets';
                            value.sheetMultiSelectObj.predicate = [];

                            value.sheetMultiSelectObj.selected = data.dashboard.data_sheets.filter(function (ds) {
                                return value.sheet.includes(ds.name);
                            }).map(function (item) {
                                return {
                                    name: item.name,
                                    value: item.name,
                                    action: GENERAL_CONFIG.base_url + '/' + item.action_id//item.action_id
                                }
                            })

                            vm.sheetChange(value);

                        })
                    }

                    console.log('dahboarda dafa', vm.dashboard);
                    // $scope.loaded = true;

                    setupCombinationCharts();
                }
            });
        }
        if (rowData && rowData.DASHBOARD_ID != null) {
            vm.getDashboardByKey();
        }
        function setupCombinationCharts() {
            _.each(vm.dashboard.components, function (row, key) {
                if (row[0].component_type === COMBINATION_CHART_TYPE) {
                    try {
                        var extraOptions = JSON.parse(row[0].extraOptions);
                        row[0].hide = true;
                        extraOptions.componentSettings.parentChartOptions.merged = true;
                        row.push(extraOptions.componentSettings.parentChartOptions);
                        extraOptions.componentSettings.childChartOptions.merged = true;
                        row.push(extraOptions.componentSettings.childChartOptions);
                        // row.splice(0, 1);
                    }
                    catch (ex) {
                        console.log("Failed tp setup combination chart", row[0].component_id)
                    }
                }
            })
        }
        vm.sheetChange = function (filter) {
            // vm.action_code;loadJsonObject?action_code=owhbxl
            vm.loading = false;
            var params = {};
            params.is_admin = 'Y';
            params = _.merge({}, GlobalService.globalParams, params);
            filter.sheetMultiSelectObj.predicates = [];

            var action_code_selected = _.difference(_.map(filter.sheetMultiSelectObj.selected, 'action'), _.keys(vm.actionDataCache));
            var action_code_removed = _.difference(_.keys(vm.actionDataCache), _.map(filter.sheetMultiSelectObj.selected, 'action_id'));

            if (action_code_selected.length > 0) {
                // vm.actionIdsPredicated.push(action_code_selected.value);
                filter.sheetMultiSelectObj.loadingPredicate = true;
                var promises = [];
                _.each(action_code_selected, function (action, key) {
                    var promise = adminDdashboardFactory.getActionData(action, params).then(function (data) {
                        if (data != null) {

                            // filter.sheetMultiSelectObj.predicates = filter.sheetMultiSelectObj.predicates.concat(Object.keys(data.jsonObject[0]));//data.jsonObject;
                            vm.actionDataCache[action] = Array.isArray(data) ? data[0] : data.jsonObject[0];

                            populatePredicate(filter, action_code_selected);

                            vm.loading = true;
                        } else {

                        }

                    });

                    promises.push(promise);

                    $q.all(promises).then(function () {
                        filter.sheetMultiSelectObj.loadingPredicate = false;
                    })
                })

            } else if (action_code_removed.length > 0) {
                _.each(action_code_removed, function (action, key) {
                    var keysToRemove = Object.keys(vm.actionDataCache[action]);
                    //  var index = vm.actionIdsPredicated.indexOf(action_code_removed[0].value);
                    // vm.actionIdsPredicated.slice(index,1);

                    _.remove(filter.sheetMultiSelectObj.predicates, function (item) {
                        return keysToRemove.includes(item);
                    })

                })
            }

            populatePredicate(filter, action_code_selected);

        }

        function populatePredicate(filter, action_code_selected) {
            var actionsNotLoaded = _.difference(action_code_selected, Object.keys(vm.actionDataCache))
            if (actionsNotLoaded.length > 0) {
                return;
            }

            filter.sheetMultiSelectObj.disablePredicates = false;

            if (filter.sheetMultiSelectObj.selected.length === 1) {
                filter.sheetMultiSelectObj.predicates = vm.actionDataCache[filter.sheetMultiSelectObj.selected[0].action] ?
                    Object.keys(vm.actionDataCache[filter.sheetMultiSelectObj.selected[0].action]) : [];
            } else if (filter.sheetMultiSelectObj.selected.length > 0) {
                var predicateCollection = [];

                _.each(filter.sheetMultiSelectObj.selected, function (value, key) {
                    predicateCollection.push(Object.keys(vm.actionDataCache[value.action]));
                })

                filter.sheetMultiSelectObj.predicates = _.intersection.apply(null, predicateCollection);

                if (filter.sheetMultiSelectObj.predicates.length === 0) {
                    filter.sheetMultiSelectObj.predicates.push("No Common Predicates found between sheets");
                    filter.sheetMultiSelectObj.disablePredicates = true;
                    filter.predicate = "No Common Predicates found between sheets";
                }

            }
        }
        vm.sheetObj = [
            { shtNameValue: "", keyNameValue: "", shtIdValue: "" }
        ];

        vm.newFilter = [];
        vm.selectSheetObj = function (sheetName) {
            if (vm.dashboard.data_sheets && vm.dashboard.data_sheets) {
                vm.allSheets = vm.dashboard.data_sheets.map(function (item) {
                    return {
                        name: item.name,
                        value: item.name,
                        action: GENERAL_CONFIG.base_url + '/' + item.data_api
                    }
                });
            }
            vm.newFilter = angular.copy(emptyFilterObj);
            vm.newFilter.sheetMultiSelectObj = {};
            vm.newFilter.sheetMultiSelectObj.values = vm.allSheets;
            vm.newFilter.sheetMultiSelectObj.name = 'Sheets';
            vm.newFilter.sheetMultiSelectObj.predicate = [];
            vm.newFilter.sheetMultiSelectObj.selected = [];
            // filter sheetObj values data will get renedered from sheets values
            if (vm.dashboard && vm.dashboard.filters) {
                _.forEach(vm.dashboard.filters, function (item) {
                    item.sheetMultiSelectObj.values = vm.newFilter.sheetMultiSelectObj.values;
                });
            }
        }

        vm.addFilterRow = function () {
            setTimeout(function () {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            });
            vm.selectSheetObj();
            if (vm.dashboard && vm.dashboard.filters) {
                vm.dashboard.filters.push(vm.newFilter);
            } else {
                vm.dashboard.filters = [];
                vm.dashboard.filters.push(vm.newFilter);

            }
        }

        vm.removeFilterRow = function (index, filter) {
            var params = {
                filter_name: filter.name,
                dashboard_id: vm.dashboard.dashboard_id
            }
            if (filter && filter.filter_key != '') {
                adminDdashboardFactory.postData(URLS.removeFilter, params).then(function (data) {
                    if (data && data.callSuccess == "1") {
                        vm.dashboard.filters.splice(index, 1);
                        vm.clonedDashboardFilters = _.cloneDeep(vm.dashboard.filters);
                        AlertService.add("success", "Filter is Succesfully Deleted", 4000);
                    } else {
                        AlertService.add("error", "Filter not deleted", 4000);
                    }
                });
            } else {
                vm.dashboard.filters.splice(index, 1);
                vm.clonedDashboardFilters = _.cloneDeep(vm.dashboard.filters);
            }
        }

        vm.addSheetRow = function () {
            setTimeout(function () {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            });
            // vm.dashboard.data_sheets.push({ shtNameValue: "", keyNameValue: "", shtIdValue: "" });
            if (vm.dashboard && vm.dashboard.data_sheets) {
                vm.dashboard.data_sheets.push({ name: "", sheet_key: "", action_id: "", action_code: "" });


            } else {
                vm.dashboard.data_sheets = [];
                vm.dashboard.data_sheets.push({ name: "", sheet_key: "", action_id: "", action_code: "" });
            }

        }

        vm.removeSheetRow = function (index, sheet, dashboard) {
            var params = {
                sheet_key: sheet.sheet_key,
                sheet_name: sheet.name
            }
            if (sheet && sheet.sheet_key != '') {
                adminDdashboardFactory.postData(URLS.removeSheet, params).then(function (data) {
                    if (data && data.callSuccess == "1") {
                        vm.dashboard.data_sheets.splice(index, 1);
                        AlertService.add("success", "Sheet is Succesfully Deleted", 4000);
                    } else {
                        AlertService.add("error", data.errorMessage, 4000);
                    }
                });
            } else {
                vm.dashboard.data_sheets.splice(index, 1);
            }
        }

        vm.createComponent = function (dashboard, rowIndex, box, isCreateNew, actualRowIndex, index) {
            vm.editchartObj = isCreateNew ? {} : vm.editchartObj
            vm.sheetObj = dashboard.data_sheets
            vm.rowIndex = !_.isEmpty(vm.editchartObj) ? vm.editchartObj.rowIndex : rowIndex;
            vm.columns = !_.isEmpty(vm.editchartObj) ? vm.editchartObj.columns : box.columns
            var createComponentInstance = $uibModal.open({
                templateUrl: 'app/components/admin/adminDashboards/add-chart-component-tpl.html',
                controller: 'addChartComponentCtrl as ctrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    createComponentData: function () {
                        return {
                            editObj: vm.editchartObj,
                            sheetsObj: vm.sheetObj,
                            dashboard_id: dashboard.dashboard_id,
                            reloadBackground: vm.getDashboardByKey,
                            rowIndex: vm.rowIndex,
                            columns: vm.columns,
                            rowObj: dashboard.components,
                            rowObj_actualRowIndex: actualRowIndex,
                            name: dashboard.dashboard_name,
                            key: dashboard.dashboard_key,
                            type: dashboard.dashboard_type,
                            comp_index: index
                        }
                    }

                },

            });

            createComponentInstance.result.then(function (response) {
                vm.save(true);
                console.log(vm.dashboard)
                console.log(response)
            })
        }



        vm.editAddedBoxComp = function (box, actualRowIndex) {
            vm.editchartObj = box;
            if (typeof vm.editchartObj.extraOptions === 'string') {
                vm.editchartObj.extraOptions = JSON.parse(JSON.stringify(eval("(" + (vm.editchartObj.extraOptions) + ")")))
            }
            vm.createComponent(vm.dashboard, box.rowIndex, box, false, actualRowIndex);
            console.log(vm.editchartObj, "objjjjjjjjjjj");
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.addChartBox = function () {
            var component = [{ columns: 12 }]
            if (vm.dashboard && vm.dashboard.components) {
                vm.dashboard.components.push(component);
            } else {
                vm.dashboard.components = [];
                vm.dashboard.components.push(component);
            }

        };

        function adjustColMd(chartBox) {
            var chartBoxIndex = vm.dashboard.components.indexOf(chartBox);
            if (chartBox[0] && chartBox[0].component_type === COMBINATION_CHART_TYPE) {
                return;
            }
            if (chartBox.length === 0) {
                vm.dashboard.components.splice(chartBoxIndex, 1);
            } else {
                chartBox.forEach(function (box) {
                    box.columns = 12 / chartBox.length;
                });
            }
        }

        vm.addBox = function (chartBox) {
            chartBox.push({});
            adjustColMd(chartBox);
        }

        vm.deleteItem = function (chartBox, index, doNotAlert) {
            var params = {
                comp_key: chartBox[index].component_id
            }
            console.log("deleting index: " + index + " of -->", chartBox);
            if (chartBox && chartBox[index].component_id != undefined) {
                chartBox[index].deleting = true;
                return adminDdashboardFactory.postData(URLS.removeComponent, params).then(function (data) {
                    chartBox[index].deleting = false;
                    if (data && data.callSuccess == "1") {
                        // vm.dashboard.data_sheets.splice(index, 1);
                        chartBox.splice(index, 1);
                        adjustColMd(chartBox);
                        vm.save(true)
                        // vm.getDashboardByKey(vm.dashboard.dashboard_id);

                        if (!doNotAlert)
                            AlertService.add("success", "Component is Succesfully Deleted", 4000);
                    } else {
                        AlertService.add("error", data.errorMessage, 4000);
                    }
                });
            } else {
                // vm.dashboard.data_sheets.splice(index, 1);
                chartBox.splice(index, 1);
                adjustColMd(chartBox);
            }

        }

        // Drags only each single row..
        vm.dropListRow = function (dropRowList, index) {
            dropRowList.splice(index, 1);
        }

        // Drags chartbox component..
        vm.componentDrag = function (dropCompList, index) {
            dropCompList.splice(index, 1);
            angular.forEach(vm.dashboard.components, (item, index) => {
                if (item && item.length) {
                    adjustColMd(item);
                }
            });
            removeEmpty();
        }

        function removeEmpty() {
            angular.forEach(vm.dashboard.components, (item, index) => {
                if (item && !item.length) {
                    vm.dashboard.components.splice(index, 1);
                }
            });
        }

        vm.requiredFields = {
            dashboard_name: false,
            dashboard_type: false,
        }

        vm.isSheetNameMissing = function (index) {
            return $scope.saveDashboardFrm['name_' + index].$dirty && vm.isMissing(vm.dashboard.data_sheets[index].name);
        };
        vm.isSheetActionMissing = function (index) {
            return $scope.saveDashboardFrm['action_code_' + index].$dirty && vm.isMissing(vm.dashboard.data_sheets[index].action_code);
        };
        vm.isFiltertNameMissing = function (index) {
            return $scope.saveDashboardFrm['filtername_' + index].$dirty && vm.isMissing(vm.dashboard.filters[index].name);
        }
        vm.isSheetFilterMissing = function (index) {
            return $scope.saveDashboardFrm['sheet_' + index].$dirty && vm.isMissing(vm.dashboard.filters[index].sheet);
        }
        vm.isFilterPredicateMissing = function (index) {
            return $scope.saveDashboardFrm['predicate_' + index].$dirty && vm.isMissing(vm.dashboard.filters[index].predicate);
        }
        vm.isMissing = function (obj) {
            return (angular.isUndefined(obj) || (obj == null) || (obj === ''));
        };

        setTimeout(function () {
            var fieldNames = _.keys(vm.requiredFields); //Creates a Minified list containing all property names of the specified object()
            for (var i = 0; i < fieldNames.length; i++) {
                JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.dashboard.', fieldNames[i], 'requiredFields');
            }
        });
        vm.save = function (doNotAlert) {
            console.log(vm.dashboard);
            var isAnySheetNameMissing = false;
            var isAnyActionMissing = false;
            var isAnyFilterNameMissing = false;
            var isAnyFilterPredicateMissing = false;
            var isAnySheetFilterMissing = false;
            angular.forEach(vm.dashboard.data_sheets, function (frmElement, index) {
                $scope.saveDashboardFrm['name_' + index].$dirty = true;
                $scope.saveDashboardFrm['action_code_' + index].$dirty = true;
                isAnySheetNameMissing = isAnySheetNameMissing | vm.isSheetNameMissing(index);
                isAnyActionMissing = isAnyActionMissing | vm.isSheetActionMissing(index);

            })
            angular.forEach(vm.dashboard.filters, function (frmElement, index) {
                $scope.saveDashboardFrm['filtername_' + index].$dirty = true;
                //   $scope.saveDashboardFrm['sheet_'+index].$dirty = true;
                $scope.saveDashboardFrm['predicate_' + index].$dirty = true;
                isAnyFilterNameMissing = isAnyFilterNameMissing | vm.isFiltertNameMissing(index);
                //  isAnySheetFilterMissing = isAnySheetFilterMissing |  vm.isSheetFilterMissing(index)
                isAnyFilterPredicateMissing = isAnyFilterPredicateMissing | vm.isFilterPredicateMissing(index);
            })
            var isRequiredFieldsMissing = JdcManagerFactory.validateRequiredFileds($scope, vm, null, 'dashboard', vm.requiredFields) || isAnySheetNameMissing || isAnyActionMissing ||
                isAnyFilterNameMissing || isAnyFilterPredicateMissing || isAnySheetFilterMissing;
            if (isRequiredFieldsMissing) {
                return;
            }
            var dashFilters = []; var components = [];
            if (vm.dashboard && vm.dashboard.filters) {
                vm.differenceFilters = [];
                _.each(vm.clonedDashboardFilters, function (filter, index) {
                    _.each(filter.sheet, function (key) {
                        vm.differenceFilters.push({
                            name: filter.name,
                            type: filter.type,
                            sheet: key,
                            predicate: filter.predicate,
                            filter_key: filter.filter_key,
                            filter_order: index,
                            isDeleted: "N"
                        })
                    })
                });


                _.each(vm.dashboard.filters, function (filter, index) {
                    _.each(filter.sheet, function (key) {
                        dashFilters.push({
                            name: filter.name,
                            type: filter.type,
                            sheet: key,
                            predicate: filter.predicate,
                            filter_key: filter.filter_key,
                            filter_order: index,
                            isDeleted:"N"
                        })
                    })
                });

                var diff = _.differenceWith(vm.differenceFilters,dashFilters,_.isEqual);
                _.each(diff,function(item){
                    item.isDeleted = "Y";
                });
               var filterArray =  _.concat(diff,dashFilters);
            }

            if (vm.dashboard && vm.dashboard.components) {
                _.each(vm.dashboard.components, function (comp, index) {
                    _.each(comp, function (c, i) {
                        components.push({
                            component_key: c.component_id,
                            rowindex: index + 1,
                            comp_columns: c.columns,
                            comp_order: i
                        })
                    })

                })
            }
            var params = {
                data_sheets: JSON.stringify(vm.dashboard.data_sheets),
               // filters: JSON.stringify(dashFilters),
               filters: JSON.stringify(filterArray),
                // comp_data: JSON.stringify(components),
                dashboard_id: vm.dashboard.dashboard_id,
                dashboard_name: vm.dashboard.dashboard_name,
                dashboard_key: vm.dashboard.dashboard_key,
                dashboard_type: vm.dashboard.dashboard_type,
                dashboard_desc: vm.dashboard.dashboard_desc,
                dashboard_alert_text: vm.dashboard.dashboard_alert_text
            }
            if (components && components.length > 0) {
                params.comp_data = JSON.stringify(components)
            }
            vm.dashboardLoading = true;
            adminDdashboardFactory.postData(URLS.createDashboard, params).then(function (data) {
                if (data && data.callSuccess == "1") {
                    vm.getDashboardByKey(data.dashboard_id);
                    $rootScope.$emit('gridUpdate', {});

                    if (!doNotAlert) {
                        if (!vm.dashboard.dashboard_id)
                            AlertService.add("success", "Dashboard created succesfully", 4000);
                        else
                            AlertService.add("success", "Dashboard updated succesfully", 4000);

                    }
                }
                else {
                    AlertService.add("error", "Error creating Dashboard", 4000);
                    vm.dashboardLoading = false;
                }

            });
        }
        vm.canMerge = function (chartBox, $index) {

            if (typeof chartBox[$index + 1] === "undefined") {
                return false;
            }
            if (chartBox[$index].component_type === 'combinationChart') {
                return true
            }
            if (chartBox[$index].component_type !== "chart" || chartBox[$index + 1].component_type !== "chart") {
                return false;
            }
            if (chartBox[$index].srcSheet !== chartBox[$index + 1].srcSheet) {
                return false;
            }

            // if(chartBox[$index].extraOptions.componentSettings.series_by !== chartBox[$index + 1].extraOptions.componentSettings.series_by){
            //     return false;
            // }



            return true;
        }
        vm.buildComponentObj = function (componentToSave, mergeComponentData) {
            console.log(vm.dashboard);
            var sheet;


            if (mergeComponentData.sheetsObj && mergeComponentData.sheetsObj.length > 0) {
                sheet = _.find(mergeComponentData.sheetsObj, function (item) { return item.name == componentToSave.srcSheet });

            }

            var extraOptions = angular.copy(componentToSave.extraOptions);
            if (componentToSave && componentToSave.drilldownFilterJson != null) {
                extraOptions.componentSettings.drilldownFilterJson = {};
                extraOptions.componentSettings.drilldownFilterJson = componentToSave.drilldownFilterJson;
            }


            if (typeof componentToSave.previewMode !== 'undefined')
                delete componentToSave.previewMode;
            if (typeof componentToSave.previewSheetData !== 'undefined')
                delete componentToSave.previewSheetData;
            if (typeof componentToSave.data !== 'undefined')
                delete componentToSave.data;

            var component = {
                // component_key: componentToSave.component_id,
                comp_name: componentToSave.comp_name,
                comp_title: componentToSave.title,
                comp_columns: componentToSave.columns,
                comp_strategy: componentToSave.strategy,
                height: componentToSave.height,
                filterable: componentToSave.filterable == true ? 'Y' : 'N',
                comp_src_sheet_index: componentToSave.srcSheet,
                sheet_key: sheet.sheet_key,
                comp_type: componentToSave.component_type,
                dashboard_id: mergeComponentData.dashboard_id,
                comp_extra_options: JSON.stringify(extraOptions),
                rowindex: mergeComponentData.rowIndex,
                comp_columns: 6,
                row_title: componentToSave.row_title

            };

            return component;
        }
        vm.unMergeComponents = function (dashboard, components) {

            var mergeComponentData = {
                components: components,
                sheetsObj: dashboard.data_sheets,
                dashboard_id: dashboard.dashboard_id,
                reloadBackground: vm.getDashboardByKey,
                rowIndex: components[0].rowIndex,
                columns: 6,
                dashboard_key: dashboard.dashboard_key
            }


            var params = {
                dashboard_id: dashboard.dashboard_id,
                new_component: JSON.stringify([vm.buildComponentObj(components[1], mergeComponentData),
                vm.buildComponentObj(components[2], mergeComponentData)]),
                remove_component_list: JSON.stringify([_.pick(components[0], 'component_id')])
            }
            console.log(params);
            adminDdashboardFactory.postData(URLS.mergeUnmergeComponent, params).then(function (data) {
                if (data && data.callSuccess == "1") {
                    mergeComponentData.reloadBackground(data.dashboard_id)
                    AlertService.add("success", "Un-Merge Successfull.", 4000);
                } else {
                    AlertService.add("error", "Un Merge Failed", 4000);
                }
            });

        }
        vm.mergeComponents = function (dashboard, components) {

            // console.log("Merge", dashboard)
            // console.log("Components" , components)
            vm.sheetObj = dashboard.data_sheets
            vm.rowIndex = components[0].rowIndex
            vm.columns = components[0].columns;
            var mergeModal = $uibModal.open({
                templateUrl: 'app/components/admin/adminDashboards/merge-chart-component-tpl.html',
                controller: 'mergeChartComponentCtrl as ctrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    mergeComponentData: function () {
                        return {
                            components: components,
                            sheetsObj: vm.sheetObj,
                            dashboard_id: dashboard.dashboard_id,
                            reloadBackground: vm.getDashboardByKey,
                            rowIndex: vm.rowIndex,
                            columns: vm.columns,
                            dashboard_key: dashboard.dashboard_key
                        }
                    }

                }
            });

            // mergeModal.result.then(function () {
            //     vm.deleteItem(components, 0, true);//delete old comps
            //     vm.deleteItem(components, 1, true);
            // })


        }
    }


    function mergeChartComponentCtrl($uibModalInstance, $uibModal, strategyFactory, componentFactory, GENERAL_CONFIG, adminDdashboardFactory,
        GlobalService, mergeComponentData, $scope, $rootScope, $timeout, AlertService) {

        var vm = this;
        vm.leftComponent = angular.copy(mergeComponentData.components[0]);
        vm.rightComponent = angular.copy(mergeComponentData.components[1]);

        var URLS = {
            createComponent: '/createComponent',
            mergeUnmergeComponent: '/mergeUnmergeComponent'
        }

        vm.leftComponent.extraOptions = typeof (vm.leftComponent.extraOptions) === 'string' ? JSON.parse(vm.leftComponent.extraOptions) : vm.leftComponent.extraOptions;
        vm.rightComponent.extraOptions = typeof (vm.rightComponent.extraOptions) === 'string' ? JSON.parse(vm.rightComponent.extraOptions) : vm.rightComponent.extraOptions;

        vm.leftComponent.previewMode = true;
        vm.rightComponent.previewMode = true
        // componentToSave = vm.leftComponent;
        vm.templateUrl = 'app/components/admin/adminDashboards/component-settings-templates/chart-view-tpl.html?ticks=' + Date.now();

        vm.components = {
            left: {
                previewMode: true,
                previewSheetData: {},
                extraOptions: {
                    componentSettings: {},
                    strategySettings: {}
                },
                filterable: true
            },

            right: {
                previewMode: true,
                previewSheetData: {},
                extraOptions: {
                    componentSettings: {},
                    strategySettings: {}
                },
                filterable: true
            },

        };

        vm.components.left = _.merge(vm.components.left, angular.copy(vm.leftComponent))
        vm.components.right = _.merge(vm.components.right, angular.copy(vm.rightComponent))

        // vm.combinationChartView = true;

        function setComponentObj() {
            vm.component = {
                title: vm.leftComponent.title + ' & ' + vm.rightComponent.title,
                component_id: undefined,
                dashboard_id: mergeComponentData.dashboard_id,
                component_type: 'combinationChart',
                columns: 12,
                srcSheet: null,
                strategy: null,
                height: '450px',
                filterable: true,
                previewMode: true,
                previewSheetData: {},
                extraOptions: {
                    componentSettings: {
                        parentChartOptions: vm.leftComponent,
                        childChartOptions: vm.rightComponent
                    }

                }
            }
        }

        setComponentObj();

        console.log("Component registerd");
        console.log(vm.component)


        vm.getActionData = function (sheetName) {
            // vm.action_code;loadJsonObject?action_code=owhbxl
            vm.actionLoading = true;
            var params = {};
            params.is_admin = 'Y';
            params = _.merge({}, GlobalService.globalParams, params);

            var sheet = mergeComponentData.sheetsObj.find(function (item, index) {
                return (item.name === sheetName) || (item.name === vm.components.left.srcSheet);
            })

            return adminDdashboardFactory.getActionData(GENERAL_CONFIG.base_url + '/' + sheet.action_id, params).then(function (data) {
                if (data != null) {
                    var sheetData = Array.isArray(data) ? data : data.jsonObject
                    vm.actionData = Object.keys(sheetData[0])//data.jsonObject;
                    vm.actionDataSafeCopy = vm.actionData.slice()
                    vm.loadChart = true;
                    vm.component.previewSheetData = {
                        safeCopyArr: sheetData.slice(0, 50),//only use top 50 records for quick component preview
                        data: sheetData.slice(0, 50),
                        isLoaded: true
                    }
                    vm.leftComponent.previewSheetData = {
                        safeCopyArr: sheetData.slice(0, 50),//only use top 50 records for quick component preview
                        data: sheetData.slice(0, 50),
                        isLoaded: true
                    }

                    vm.rightComponent.previewSheetData = {
                        safeCopyArr: sheetData.slice(0, 50),//only use top 50 records for quick component preview
                        data: sheetData.slice(0, 50),
                        isLoaded: true
                    }


                    vm.actionLoading = false;
                    //if (createComponentData.editObj) {
                    vm.chartView = true;
                    vm.loadChart = true;
                    vm.component.extraOptions.componentSettings.childChartOptions.extraOptions.componentSettings.default_filter_by
                        = vm.leftComponent.extraOptions.strategySettings.series_by;
                    //}

                } else {

                }

                $scope.$broadcast('component-object:loaded', vm.component);
            });

        }

        if (vm.leftComponent.srcSheet) {
            vm.getActionData(vm.leftComponent.srcSheet)
        }

        vm.reRenderComponent = function () {
            // setCompone ntObj();
            vm.loadChart = false;
            vm.component.extraOptions.componentSettings.childChartOptions.extraOptions.componentSettings.default_filter_by
                = vm.rightComponent.extraOptions.componentSettings.default_filter_by;
            $timeout(function () {
                vm.loadChart = true;
            })
            // vm.templateUrl =  'app/components/admin/adminDashboards/component-settings-templates/chart-view-tpl.html?ticks='+ Date.now();
            // $rootScope.$broadcast('sheet:filtered', vm.leftComponent.srcSheet)
        }

        vm.merge = function () {
            console.log(vm.dashboard);
            var sheet;
            if (mergeComponentData.sheetsObj && mergeComponentData.sheetsObj.length > 0) {
                sheet = _.find(mergeComponentData.sheetsObj, function (item) { return item.name == vm.component.extraOptions.componentSettings.parentChartOptions.srcSheet });

            }




            if (typeof vm.component.previewMode !== 'undefined')
                delete vm.component.previewMode;
            if (typeof vm.component.previewSheetData !== 'undefined')
                delete vm.component.previewSheetData;
            if (typeof vm.component.data !== 'undefined')
                delete vm.component.data;

            if (typeof vm.component.extraOptions.componentSettings.parentChartOptions.previewMode !== 'undefined')
                delete vm.component.extraOptions.componentSettings.parentChartOptions.previewMode;
            if (typeof vm.component.extraOptions.componentSettings.parentChartOptions.previewSheetData !== 'undefined')
                delete vm.component.extraOptions.componentSettings.parentChartOptions.previewSheetData;
            if (typeof vm.component.extraOptions.componentSettings.parentChartOptions.data !== 'undefined')
                delete vm.component.extraOptions.componentSettings.parentChartOptions.data;

            if (typeof vm.component.extraOptions.componentSettings.childChartOptions.previewMode !== 'undefined')
                delete vm.component.extraOptions.componentSettings.childChartOptions.previewMode;
            if (typeof vm.component.extraOptions.componentSettings.childChartOptions.previewSheetData !== 'undefined')
                delete vm.component.extraOptions.componentSettings.childChartOptions.previewSheetData;
            if (typeof vm.component.extraOptions.componentSettings.childChartOptions.data !== 'undefined')
                delete vm.component.extraOptions.componentSettings.childChartOptions.data;


            var extraOptions = angular.copy(vm.component.extraOptions);
            if (vm.component && vm.component.drilldownFilterJson != null) {
                extraOptions.componentSettings.drilldownFilterJson = {};
                extraOptions.componentSettings.drilldownFilterJson = vm.component.drilldownFilterJson;
            }
            var component = {
                component_key: vm.component.component_id,
                comp_name: vm.component.comp_name,
                comp_title: vm.component.title,
                comp_columns: vm.component.columns,
                comp_strategy: vm.component.strategy,
                height: vm.component.height,
                filterable: vm.component.filterable == true ? 'Y' : 'N',
                comp_src_sheet_index: vm.component.extraOptions.componentSettings.parentChartOptions.srcSheet,
                sheet_key: sheet.sheet_key,
                comp_type: vm.component.component_type,
                dashboard_id: mergeComponentData.dashboard_id,
                comp_extra_options: JSON.stringify(extraOptions),
                rowindex: mergeComponentData.rowIndex,
                comp_columns: 12,
                row_title: vm.component.row_title

            };

            var params = {
                dashboard_id: component.dashboard_id,
                new_component: JSON.stringify([component]),
                remove_component_list: JSON.stringify(_.map(mergeComponentData.components, _.partialRight(_.pick, 'component_id')))
            }
            console.log(params);
            adminDdashboardFactory.postData(URLS.mergeUnmergeComponent, params).then(function (data) {
                if (data && data.callSuccess == "1") {
                    mergeComponentData.reloadBackground(data.dashboard_id)
                    AlertService.add("success", "Merge Successfull.", 4000);
                } else {
                    AlertService.add("error", "Merge Failed", 4000);
                }

                $uibModalInstance.close();
            });
        }
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
    function addChartComponentCtrl($uibModalInstance, $uibModal, dataFormatterFactory, strategyFactory, componentFactory, GENERAL_CONFIG, adminDdashboardFactory,
        GlobalService, createComponentData, $scope, $rootScope, $timeout, AlertService,USER_SETTINGS) {
        var vm = this;
        vm.theme = USER_SETTINGS.user.selected_theme || 'theme-black';
        vm.title = "Chart component";
        vm.selectedChartDropdownValue = [];
        vm.selectedChartTypeValue = [];
        vm.selectedStrategyDropdownValue = [];
        vm.fullData = createComponentData.fullData;
        vm.accordions = {
            generalSettings: true
        };
        var URLS = {
            createComponent: '/createComponent',
            createDashboard: '/createDashboard'
        }
        vm.component = {
            previewMode: true,
            previewSheetData: {},
            extraOptions: {
                componentSettings: {},
                strategySettings: {}
            },
            filterable: true
        };

        var allComponents = componentFactory.getAllComponents();
        vm.kpiStrategies = componentFactory.getComponentStrategies('kpi')
        vm.formatters = dataFormatterFactory.getAllFormatters();
        // vm.kpiFormatter = dataFormatterFactory.getAllFormatters('kpi');
        // vm.component.extraOptions = {};
        vm.component.extraOptions.componentSettings.chart = vm.component.extraOptions.componentSettings.chart || {}
        vm.component = _.merge(vm.component, angular.copy(createComponentData.editObj))
        vm.component.columns = vm.component.columns ? parseInt(vm.component.columns) : vm.component.columns
        vm.sheetData = createComponentData.sheetsObj;

        $scope.$on('component-object:changed', function (e, componentSettings) {
            vm.component.extraOptions.componentSettings = componentSettings;
            vm.reRenderComponent();
        })

        vm.defaultFilterByChanged = function(){
            if(!vm.component.previewSheetData && !vm.component.previewSheetData.data){
                return;
            }
            vm.defaultFilterValues = vm.component.previewSheetData.data.map(function(item){
                if(_.keys(item).includes(vm.component.extraOptions.strategySettings.default_filter_by)){
                    return item[vm.component.extraOptions.strategySettings.default_filter_by]
                }
            })

            vm.defaultFilterValues = _.uniq(vm.defaultFilterValues)
        }

        var emptyKpiObj = {
            title: '',
            dashboard_id: vm.component ? vm.component.dashboard_id : '',
            component_type: 'kpi',
            component_id: vm.component.component_id,
            strategy: null,
            filterable: true,
            srcSheet: vm.component ? vm.component.srcSheet : '',
            extraOptions: { label: null, strategySettings: { aggregateBy: null } }
        };


        console.log(vm.editAddedCompObj)

        vm.changeStrategy = function (strategyName) {
            if (strategyName) {
                vm.strategyInputs = strategyFactory.getStrategyInputs(vm.component.strategy, vm.actionData);
                console.log(vm.strategyInputs)
                vm.reRenderComponent()
            }
        }
        vm.initKpiStrategyInputs = function (kpi) {
            // var kpi = angular.copy(p_kpi);
            if (!kpi.strategy)
                return;
            kpi.kpiStrategyInputs = strategyFactory.getStrategyInputs(kpi.strategy, vm.actionData);
            // vm.reRenderComponent()
        }

        vm.componentSettingsTemplates = {
            chart: 'app/components/admin/adminDashboards/component-settings-templates/chart-component-tpl.html',
            horizontalBusinessGroup: 'app/components/admin/adminDashboards/component-settings-templates/horizontal-business-group-tpl.html',
            horizontalBusinessTreeMap: 'app/components/admin/adminDashboards/component-settings-templates/horizontal-business-tree-map-tpl.html',
            regularTreeMap: 'app/components/admin/adminDashboards/component-settings-templates/regularTreeMap/regular-tree-map-tpl.html',
            datatable: 'app/components/admin/adminDashboards/component-settings-templates/datatable/datatables-settings-tpl.html'
        }


        vm.strategies = strategyFactory.getAllStrategies();
        vm.strategyFunctions
        vm.definedComponentTypes = componentFactory.getDefinedComponentList();
        if (vm.component.component_type)
            vm.component.supportedStrategies = getSupportedStrategies()

        function getSupportedStrategies() {
            return componentFactory.getComponentStrategies(vm.component.component_type);
            //     _.chain(allComponents).find(function (component, componentName) {
            //     if (componentName === vm.component.component_type) {
            //         return component
            //     }
            // }).value().strategies
        }

        /////////////////////////

        vm.addKpiRow = function () {
            setTimeout(function () {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            });

            var newKpi = angular.copy(emptyKpiObj);
            if (!vm.component.extraOptions.kpis) {
                vm.component.extraOptions.kpis = [];
            }

            newKpi.srcSheet = vm.component.srcSheet;
            newKpi.dashboard_id = vm.component.dashboard_id;


            vm.component.extraOptions.kpis.push(newKpi);
        }

        vm.removeKpiRow = function (index) {
            vm.component.extraOptions.kpis.splice(index, 1);
        }

        vm.changeComponentType = function () {
            vm.component.extraOptions.componentSettings = {}
            vm.component.supportedStrategies = getSupportedStrategies()
            vm.component.strategy = null;
            vm.reRenderComponent()
        }



        vm.chart = [
            { name: "line" },
            { name: "chart" },
            { name: "area" },
            { name: "bar" },
            { name: "column" },
            { name: "pie" },
            { name: "scatter" },
            { name: "bubble" }
        ]

        vm.componentTypeChanged = function () {
            vm.component.extraOptions.componentSettings = {};
            vm.component.strategy = null;
            vm.reRenderComponent();
        }

        vm.componentTemplateLoaded = function () {
            console.log("template loaded");
            $timeout(function () {
                vm.triggerComponentLoadedEvent();
            })
        }
        vm.triggerComponentLoadedEvent = function() {
            $scope.$broadcast('component-object:loaded', vm.component);
        }

        vm.reRenderComponent = function () {
            if(!vm.sheetDataSafeCopy){
                return;
            }
            vm.component.previewSheetData = {
                safeCopyArr: vm.fullData ? vm.sheetDataSafeCopy : vm.sheetDataSafeCopy.slice(0, 50),//only use top 50 records for quick component preview
                data: vm.fullData ? vm.sheetDataSafeCopy : vm.sheetDataSafeCopy.slice(0, 50),
                isLoaded: true
            }
            $rootScope.$broadcast('component-' + vm.component.component_id + ':reload');
        }

        vm.save = function () {
            console.log(vm.dashboard);

            if(vm.chartForm.$invalid){
                return;
            }
            var sheet;
            if (createComponentData.sheetsObj && createComponentData.sheetsObj.length > 0) {
                sheet = _.find(createComponentData.sheetsObj, function (item) { return item.name == vm.component.srcSheet });

            }
            var extraOptions = angular.copy(vm.component.extraOptions);

            // var extraOptions = angular.copy(vm.component.extraOptions);
            if (vm.component && vm.component.drilldownFilterJson != null) {
                extraOptions.componentSettings.drilldownFilterJson = {};
                extraOptions.componentSettings.drilldownFilterJson = vm.component.drilldownFilterJson;
            }

            // _.each(vm.component.extraOptions.kpis, function (value, key) {
            //     if (typeof value.previewMode !== 'undefined')
            //         delete value.previewMode;
            //     if (typeof value.previewSheetData !== 'undefined')
            //         delete value.previewSheetData;
            //     if (typeof value.data !== 'undefined')
            //         delete value.data;

            //     if (!value.dashboard_id)
            //         value.dashboard_id = createComponentData.dashboard_id;

            //     value.component_id = vm.component.component_id;
            // })
            extraOptions.kpis = _.map(extraOptions.kpis,function(kpi) {
                kpi.component_id = vm.component.component_id;
                if (!kpi.dashboard_id)
                 kpi.dashboard_id = createComponentData.dashboard_id;
                return _.omit(kpi,["previewMode","previewSheetData","data","kpiStrategyInputs"]);   
            })




            //if saving a chart from combination chart.
            var COMBINATION_CHART_TYPE = "combinationChart";
            if (vm.component.merged && vm.component.component_id) {
                var combinationChart = createComponentData.rowObj[vm.component.rowIndex - 1][0];

                if (combinationChart.component_type === COMBINATION_CHART_TYPE) {
                    var chartToSave = angular.copy(vm.component);
                    if (chartToSave.previewMode)
                        delete chartToSave.previewMode;
                    if (chartToSave.previewSheetData)
                        delete chartToSave.previewSheetData;

                    if (typeof combinationChart.extraOptions === "string")
                        combinationChart.extraOptions = JSON.parse(combinationChart.extraOptions);

                    if (combinationChart.extraOptions.componentSettings.parentChartOptions.component_id === chartToSave.component_id)
                        combinationChart.extraOptions.componentSettings.parentChartOptions = chartToSave;
                    else
                        combinationChart.extraOptions.componentSettings.childChartOptions = chartToSave;

                    vm.component = combinationChart;

                }
            }
            var component = {
                component_key: vm.component.component_id,
                comp_name: vm.component.comp_name,
                comp_title: vm.component.title,
                comp_columns: vm.component.columns,
                comp_strategy: vm.component.strategy,
                height: vm.component.height,
                filterable: vm.component.filterable == true ? 'Y' : 'N',
                comp_src_sheet_index: vm.component.srcSheet,
                sheet_key: sheet.sheet_key,
                comp_type: vm.component.component_type,
                dashboard_id: createComponentData.dashboard_id,
                comp_extra_options: JSON.stringify(extraOptions),
                rowindex: createComponentData.rowIndex,
                comp_columns: createComponentData.columns,
                row_title: vm.component.row_title
            };

            var modifiedRowObj = [];
            // creating this object for adjusting the colums on each row while saving..
            if(createComponentData.rowObj)
                _.forEach(createComponentData.rowObj[createComponentData.rowObj_actualRowIndex], function (item) {
                    modifiedRowObj.push({
                        component_key: item.component_id,
                        rowindex: createComponentData.rowIndex,
                        comp_columns: item.columns
                    })
                });

            //Preparing component rowObj..
            vm.compRowObj = {
                data_sheets: "",
                filters: "",
                dashboard_id: createComponentData.dashboard_id,
                dashboard_name: createComponentData.name,
                dashboard_key: createComponentData.key,
                dashboard_type: createComponentData.type,
                comp_data: JSON.stringify(modifiedRowObj),
                comp_order: createComponentData.comp_index
            }

            var params = {
                comp_data: JSON.stringify(component)
            }
            adminDdashboardFactory.postData(URLS.createComponent, params).then(function (data) {
                if (data && data.callSuccess == "1") {
                    createComponentData.reloadBackground && createComponentData.reloadBackground(data.dashboard_id);
                    AlertService.add("success", "Chart component succesfully created", 4000);
                    $uibModalInstance.close(vm.component);
                } else {
                    AlertService.add("error", "Error creating chart component", 4000);
                }
            });

            //Making createdashboard call to save modified rowObj..
            if (modifiedRowObj.length > 0) {
                adminDdashboardFactory.postData(URLS.createDashboard, vm.compRowObj).then(function (data) {
                    if (data && data.callSuccess == "1") {
                    } else {
                        AlertService.add("error", "Error updating Dashboard", 4000);
                    }
                });
            }


        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.showFunction = function (strategy) {
            $uibModal.open({
                templateUrl: 'app/components/admin/adminDashboards/view-strategy-function-tpl.html',
                controller: ["$scope","func","$uibModalInstance",function ($scope, func, $uibModalInstance) {
                    $scope.func = func;

                    $scope.cancel = function () {
                        $uibModalInstance.dismiss();
                    }
                }],
                size: 'md',
                backdrop: 'static',
                resolve: {
                    func: function () {
                        return strategyFactory.getStrategyFunctionString(strategy);
                    }
                }
            });
        }

        /* get the action code response */
        vm.getActionData = function (sheetName) {
            // vm.action_code;loadJsonObject?action_code=owhbxl
            vm.actionLoading = true;
            var params = {};
            params.is_admin = 'Y';
            params = _.merge({}, GlobalService.globalParams, params);

            var sheet = createComponentData.sheetsObj.find(function (item, index) {
                return (item.name === sheetName) || (item.name === vm.component.srcSheet);
            })
            if(sheet.data.length === 0){
                adminDdashboardFactory.getActionData(GENERAL_CONFIG.base_url + '/' + sheet.action_id, params).then(resolveData);
            }else{
                resolveData({
                    jsonObject:sheet.data
                })
            }

        }

        function resolveData(data) {
            if (data != null) {
                var sheetData = (Array.isArray(data) ? data : data.jsonObject) || [];
                vm.sheetDataSafeCopy = sheetData;

                vm.actionData = sheetData.length > 0 ? Object.keys(sheetData[0]) : [];//data.jsonObject;

                if (vm.component && vm.component.strategy) {
                    vm.strategyInputs = strategyFactory.getStrategyInputs(vm.component.strategy, vm.actionData, vm.component.extraOptions.strategySettings);
                }
                vm.actionDataSafeCopy = vm.actionData.slice()
                vm.component.previewSheetData = {
                    safeCopyArr: vm.fullData ? sheetData : sheetData.slice(0, 50),//only use top 50 records for quick component preview
                    data: vm.fullData ? sheetData : sheetData.slice(0, 50),
                    isLoaded: true
                }
                emptyKpiObj.previewMode = true;
                emptyKpiObj.previewSheetData = vm.component.previewSheetData;

                _.each(vm.component.extraOptions.kpis, function (value, key) {
                    value.previewMode = true;
                    value.previewSheetData = vm.component.previewSheetData;
                    vm.initKpiStrategyInputs(value)
                })
                $timeout(()=>{vm.actionLoading = false;vm.chartView = true},1500);//1500ms delay to finish the modal animation
                
                //if (createComponentData.editObj) {
                
                //}

            } else {

            }

            $scope.$broadcast('component-object:loaded', vm.component);
        }
        if (vm.component.srcSheet) {
            vm.getActionData(vm.component.srcSheet)
        }

        if (vm.component.component_id) {
            // remove specific fields from object for new compoent(title, dashboard id, component id).
            vm.removeFields = _.omit(vm.component, ['title', 'dashboard_id', 'component_id', 'rowIndex']);
        }

        vm.CompJsonObj = JSON.stringify(vm.removeFields, null, 2);
        vm.applyJsonObj = function (chartCompObj) {
            var parsedObj = JSON.parse(chartCompObj);
            // passes the exisitng values((title, dashboard id, component id).
            parsedObj.title = vm.component.title;
            parsedObj.dashboard_id = vm.component.dashboard_id;
            parsedObj.component_id = vm.component.component_id;
            parsedObj.rowIndex = vm.component.rowIndex;
            if (angular.isObject(parsedObj)) {
                vm.component = parsedObj;
                AlertService.add("success", "Successfully saved/applied Json Object", 4000);
                vm.Obj = false;
                vm.getActionData(vm.component.srcSheet).then(function () {
                    vm.reRenderComponent();
                })
            } else {
                AlertService.add("error", "Please paste a valid json format", 4000);
            }
        }


    }
    function adminDashboardListCtrl($scope, $rootScope, $state, $log, GridFactory, AlertService, $timeout, $uibModal, $parse, ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS) {

        var vm = this;
        vm.name = "Dashbaord List";
        vm.userSettings = USER_SETTINGS;
        vm.filter = {
            client_key: vm.userSettings.user.client.client_key
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function (event, args) {
            var filterObj = (typeof args.gridFilter !== 'undefined') ? args.gridFilter : null;
            delete args['gridFilter'];
            getUpDate(args, filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            console.log("SCREEN WAS THIS DESTROYED ")
            gridUpdateListener();
        });

        vm.refresh = function () {
            getData();
        };


        vm.selected = null;


        var getData = function () {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];

            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", data.errorMessage, 4000);
                }
            });
        }

        vm.onClientChange = function () {
            getData();
        };

        var getUpDate = function (_params, _filterObj) {
            var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
            params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get, params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", { filter: _filterObj, data: data.data });
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                }
            });
        }

        $timeout(function () {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);



        vm.addDashboard = function () {
            var data = {};

            var modalObj = {};
            modalObj.template = 'app/components/admin/adminDashboards/admin-dashboard-component.html';
            modalObj.controler = 'adminDashComponentCtrl as ctrl';
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj, data);
        }


        $scope.ctrlFn = function (_callName, _data, _type) {
            var modalObj = {};
            modalObj.template = "app/components/admin/adminDashboards/admin-dashboard-component.html";
            modalObj.controler = "adminDashComponentCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj, _data);
        };

        vm.openModal = function (modalObj, _data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });
        };
    }


    return controllers;
});
