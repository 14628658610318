define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.sltDataServiceDashboardService',[])

        .factory("sltDataServiceDashboardService",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS) {
            var sltDataServiceDashboardService = {};

            return sltDataServiceDashboardService;
        }])

    return services;


});
