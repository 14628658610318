define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.ReportableTransactionCtrl', [])
    .controller('ReportableTransactionCtrl', ['GlobalService', 'AlertService','$uibModal','$uibModalInstance','$scope','$rootScope','dataRow','rowData','repTransaction', 'isValidScenario', 'ReportableTransactionFactory','USER_SETTINGS',ReportableTransactionCtrl])
			
	function ReportableTransactionCtrl(GlobalService, AlertService, $uibModal, $uibModalInstance, $scope, $rootScope, dataRow, rowData, repTransaction, isValidScenario, ReportableTransactionFactory, USER_SETTINGS) {
    	
    	var vm = this;
    	vm.cancel = function(){
    		if(vm.crudLoading){
				AlertService.add("info", "The transaction is still in progress", 4000);
			}
            $uibModalInstance.dismiss('cancel');
        };
        
        console.log(dataRow);
        console.log(rowData);
        console.log(repTransaction);
        vm.dataRow = dataRow;
        vm.rowData = rowData;
		vm.isValidScenario = isValidScenario;
        vm.finalRepTransaction = [];
        vm.ReporatableActions = [];
        vm.ReporatableReasonTypes = [];
        vm.AccountList = [];
        vm.AccountAmtData = [];
        vm.repTransaction = repTransaction;
        vm.originalRepTransaction = [];
        vm.originalRepHeader = [];
        vm.deletedRepTransaction = [];
        vm.enableAddRow = true;
        vm.priorReportableAdjustment = [{"EARLY_ADJ_AMT": 0}];
        vm.rep_trans_id = 0;
        vm.rpt_tax_amt = 0;
        vm.gain_loss_amt = 0;
        vm.rep_line_attrib_key = 0;
        vm.crudLoading = false;
        vm.rptdescError = false;
        vm.typeofrptError = false;
        vm.reportabletaxamountError = false;
        vm.reasonforzeroError = false;
        vm.ACCT_KEY_ERROR = false;
        vm.RECLASS_ADJ_AMT_ERROR = false;
        var attrib_short_code = "REP_TRANS_ID_"+dataRow.Line_no;
        var gain_loss_attrib_short_code = "GAIN_LOSS_"+dataRow.Line_no;
		for(var i=0; i < dataRow.Line_data_occ.length; i++){
			if(dataRow.Line_data_occ[i].ATTRIB_SHORT_CODE == attrib_short_code){
				if(null != dataRow.Line_data_occ[i].ATTRIB_VALUE){
					vm.rep_trans_id = dataRow.Line_data_occ[i].ATTRIB_VALUE;
				}
				vm.rep_line_attrib_key = dataRow.Line_data_occ[i].LINE_ATTRIB_KEY;
			} else if(dataRow.Line_data_occ[i].ATTRIB_SHORT_CODE == gain_loss_attrib_short_code && null != dataRow.Line_data_occ[i].ATTRIB_VALUE){
				vm.gain_loss_amt = dataRow.Line_data_occ[i].ATTRIB_VALUE;
			}
		}
		
		vm.repTransDetails = [];
		vm.repTransHeader = 
			{
				"COMBINATION_KEY"	: rowData.COMBINATION_KEY,
				"ADJ_SOURCE"		: '',
				"ADJ_TYPE"			: '',
				"ADJ_DESC"			: '',
				"ADJ_ORIGIN_SOURCE"	: '',
				"REP_TAX_AMT"		: vm.rpt_tax_amt,
				"HEADER_COMMENTS"	: '',
				"REASON_REPORTABLE"	: '',
				"REPORTABLE_ACTION"	: '',
				"OCCURRENCE"		: dataRow.Occurrence,
	        	"SCHDD_DETAILS_KEY": dataRow.Trans_details_key,
	        	"TAX_YEAR"			: rowData.TAX_YEAR,
	        	"SCHDD_LINE_NO"		: dataRow.Line_no,
	        	"REP_LINE_ATTRIB_KEY": vm.rep_line_attrib_key,
	        	"IS_CHANGED_FLAG"	: 'N',
				"ISSUE_KEY"			: rowData.ISSUE_KEY
			};
		
        vm.dummyRepTransaction = [{
        	"COMBINATION_KEY"	: rowData.COMBINATION_KEY,
        	"REP_TRANS_AMT"		: 0,
        	"CY_AMOUNT"			: 0,
        	"EARLY_ADJ_AMT"		: 0,
        	"ADJUSTED_CY_AMOUNT": 0,
        	"ACCT_KEY"			: null,
        	"ACCT"				: '',
        	"ACCT_DESC"			: '',
        	"GL_ACCT_DESC"		: '',
        	"OCCURRENCE"		: 0,
        	"PARENT_OCCURRENCE"	: dataRow.Occurrence,
        	"SCHDD_DETAILS_KEY"	: dataRow.Trans_details_key,
        	"TAX_YEAR"			: rowData.TAX_YEAR,
        	"IS_CHANGED_FLAG"	: 'N',
        	"SCENARIO"			: rowData.SCENARIO_KEY,
			"ISSUE_KEY"			: rowData.ISSUE_KEY
        }];
        
        vm.targetAcct = "5555555 - Reportable Transactions";
        vm.targetAdjustmentAmt = 0;
        vm.targetCYAmount = 0;
        vm.targetAdjCYAmount = 0;
        
        vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		
		
		
		
		function getReportableTransTypes(){
			ReportableTransactionFactory.getReportableTransactionTypes(rowData, vm.logged_in_user).then(function (data){
				console.log("Here are the Trans Types:    ::::", data);
				vm.ReporatableActions = data.jsonObject;
			});
		}
		
		function getReportableTransReasonTypes(){
			ReportableTransactionFactory.getReportableTransReasonTypes(rowData, vm.logged_in_user).then(function (data){
				console.log("Here are the Trans Reason Types:    ::::", data);
				vm.ReporatableReasonTypes = data.jsonObject;
			});
		}
		
		function getAccountList(){
			ReportableTransactionFactory.getLocalAccountList(rowData, vm.logged_in_user, vm.rep_trans_id).then(function (data){
				console.log("Here are the Account List for Book Reclass:    ::::", data);
				vm.AccountList = data.jsonObject;
			});
			//isEnableAddRow("I");
			getPriorReportableAdjustment();
		}
		
		function getPriorReportableAdjustment(){
			ReportableTransactionFactory.getPriorReportableAdjustment(rowData, vm.rep_trans_id, vm.logged_in_user).then(function(data){
				console.log("The Prior Reportable Transaction Adjustment was:    ::::", data);
				if(data.jsonObject[0] != null && data.jsonObject[0].length > 0){
					vm.priorReportableAdjustment = data.jsonObject;
				}
				if(null != vm.priorReportableAdjustment[0].EARLY_ADJ_AMT && vm.priorReportableAdjustment[0].EARLY_ADJ_AMT != undefined){
					vm.targetAdjCYAmount = vm.targetAdjustmentAmt + vm.priorReportableAdjustment[0].EARLY_ADJ_AMT;
				}
			});
		}
		
		function getRepTransHeader(){
			ReportableTransactionFactory.getRepTransHeader(dataRow, rowData, vm.rep_trans_id, vm.logged_in_user).then(function(data){
				console.log("The Schedule D Reportable Transaction Header was:    ::::", data);
				if(null != data.jsonObject && data.jsonObject.length > 0){
					vm.repTransHeader = data.jsonObject[0];
				}
				vm.originalRepHeader = angular.copy(vm.repTransDetails);
			});
		}
		
		function getRepTransDetails(){
			ReportableTransactionFactory.getRepTransDetails(dataRow, rowData, vm.rep_trans_id, vm.logged_in_user).then(function(data){
				console.log("The Schedule D Reportable Transaction Details was:    ::::", data);
				if(null != data.jsonObject && data.jsonObject.length > 0){
					vm.repTransDetails = data.jsonObject;
				}
				vm.originalRepTransaction = angular.copy(vm.repTransDetails);
				
				for(var i=0; i < vm.repTransDetails.length; i++){
					vm.targetAdjustmentAmt = vm.targetAdjustmentAmt - vm.repTransDetails[i].REP_TRANS_AMT;
				}
			});
			
			getAccountList();
		}
		
		/*function getRepTransactions(){
			ReportableTransactionFactory.getRepTransaction(rowData, vm.rep_trans_id, vm.logged_in_user).then(function(data){
				console.log("The Reportable Transaction was:    ::::", data);
				if(null == vm.repTransaction || vm.repTransaction.length <= 0){
					if(null != data.jsonObject && data.jsonObject.length > 0){
						vm.repTransaction = data.jsonObject;
						if(vm.repTransaction[0].ADJ_SOURCE != null && vm.repTransaction[0].ADJ_TYPE != null){
							vm.dummyRepTransaction[0].ADJ_SOURCE = vm.repTransaction[0].ADJ_SOURCE;
							vm.dummyRepTransaction[0].ADJ_TYPE = vm.repTransaction[0].ADJ_TYPE;
							vm.dummyRepTransaction[0].DETAIL_COMMENTS = vm.repTransaction[0].DETAIL_COMMENTS;
							vm.dummyRepTransaction[0].GROUP_OBJ_KEY = vm.repTransaction[0].GROUP_OBJ_KEY;
							vm.dummyRepTransaction[0].REASON_REPORTABLE = vm.repTransaction[0].REASON_REPORTABLE;
							vm.dummyRepTransaction[0].REPORTABLE_ACTION = vm.repTransaction[0].REPORTABLE_ACTION;
							vm.dummyRepTransaction[0].REP_TRANS_DESC = vm.repTransaction[0].REP_TRANS_DESC;
						}
						for(var i=0; i < vm.repTransaction.length; i++){
							vm.repTransaction[i].SCHDD_TRANS_DETAILS_KEY = dataRow.Trans_details_key;
							vm.repTransaction[i].SCHDD_LINE_ATTRIB_KEY = vm.rep_line_attrib_key;
							vm.repTransaction[i].SCHDD_LINE_NO = dataRow.Line_no;
						}
						var dummyRecord = angular.copy(vm.dummyRepTransaction[0]);
						vm.repTransaction.push(dummyRecord);
					}else {
						vm.repTransaction = angular.copy(vm.dummyRepTransaction);
					}
					vm.originalRepTransaction = angular.copy(vm.repTransaction);
				}
				
				
				for(var i=0; i < vm.repTransaction.length; i++){
					vm.targetAdjustmentAmt = vm.targetAdjustmentAmt - vm.repTransaction[i].RECLASS_ADJ_AMT;
				}
			});
			getAccountList();
		}*/
		
		function isEnableAddRow(param){
			console.log("The length of accounts and rep transactions was ::::::::", vm.AccountList.length, vm.repTransDetails.length);
			if(vm.AccountList.length <= vm.repTransDetails.length){
				vm.enableAddRow = false;
			}else{
				if(param == "I" && vm.AccountList.length == vm.repTransDetails.length -1 ){
					var dummyRecord = angular.copy(vm.dummyRepTransaction[0]);
					vm.repTransDetails.push(dummyRecord);
					vm.enableAddRow = false;
				}else if(param == "I" && vm.AccountList.length < vm.repTransDetails.length -1 ){
					var dummyRecord = angular.copy(vm.dummyRepTransaction[0]);
					vm.repTransDetails.push(dummyRecord);
				}else{
					vm.enableAddRow = true;
				}
			}
		}
		
		function getTargetCYAmount(){
			if(null != vm.priorReportableAdjustment[0].EARLY_ADJ_AMT && vm.priorReportableAdjustment[0].EARLY_ADJ_AMT != undefined){
				vm.targetAdjCYAmount = vm.targetAdjustmentAmt + vm.priorReportableAdjustment[0].EARLY_ADJ_AMT;
			}
		}
		
		getRepTransHeader();
		getRepTransDetails();
		//getRepTransactions();
		getReportableTransTypes();
		getReportableTransReasonTypes();
		
		vm.applyTaxacct = function(){
			vm.repTransHeader.REP_TAX_AMT = vm.gain_loss_amt;
		}
		
		vm.reportableactionchange = function(){
			console.log("After changing the action the action was :::::::::", vm.ReporatableActions, vm.repTransHeader.REPORTABLE_ACTION);
			var selectedAcctIndex = vm.ReporatableActions.findIndex(x => x.CODE_DESC == vm.repTransHeader.REPORTABLE_ACTION);
			vm.repTransHeader.ADJ_SOURCE = vm.ReporatableActions[selectedAcctIndex].CODE_NAME;
			console.log("The Reportable Transaction was:    ::::", vm.repTransHeader);
			vm.repTransHeader.IS_CHANGED_FLAG = 'Y';
		}
		
		vm.getAccountAmtData = function(index, oldValue){
			vm.ACCT_KEY_ERROR = false;
			vm.repTransDetails[index].IS_CHANGED_FLAG = 'Y';
			console.log("The selection done for the account was in index at ::::::::::::::", index);
			console.log("The account selected was ::::::", vm.repTransDetails[index].GL_ACCT_DESC);
			console.log("The account originally selected was ::::::", oldValue);
			var oldAcctIndex = vm.AccountList.findIndex(x => x.GL_ACCT == oldValue);
			var selectedAcctIndex = vm.AccountList.findIndex(x => x.GL_ACCT == vm.repTransDetails[index].GL_ACCT_DESC);
			console.log("The acct that was selected at index was :::::::::::", selectedAcctIndex);
			console.log("The acct that was Originally selected at index was :::::::::::", oldAcctIndex);
			vm.AccountList[selectedAcctIndex].SELECTEDACCT = 1;
			vm.repTransDetails[index].ACCT_KEY = vm.AccountList[selectedAcctIndex].GL_ACCT_KEY;
			vm.repTransDetails[index].IS_CHANGED_FLAG = "Y";
			if(null != oldValue && oldValue != undefined && oldAcctIndex > -1){
				vm.AccountList[oldAcctIndex].SELECTEDACCT = 0;
			}
			console.log("Here are the Account List after acct selection was ::::    ::::", vm.AccountList);
			ReportableTransactionFactory.getLocalAccountAmtData(rowData, vm.logged_in_user, vm.repTransDetails[index].ACCT_KEY, vm.rep_trans_id).then(function (data){
				console.log("Here are the amounts for the account selected for the CC Key:    ::::", data);
				vm.AccountAmtData = data.jsonObject;
				if(null != vm.AccountAmtData[0] && vm.AccountAmtData[0] != undefined && vm.AccountAmtData[0] != ""){
					if(vm.AccountAmtData[0].CY_AMOUNT != undefined){
						vm.repTransDetails[index].CY_AMOUNT = vm.AccountAmtData[0].CY_AMOUNT;
					}else vm.repTransDetails[index].CY_AMOUNT = 0;
					if(vm.AccountAmtData[0].EARLY_ADJ_AMT != undefined){
						vm.repTransDetails[index].EARLY_ADJ_AMT = vm.AccountAmtData[0].EARLY_ADJ_AMT;
					}else vm.repTransDetails[index].EARLY_ADJ_AMT = 0;
					vm.repTransDetails[index].AMT_LOADING = false;
				}else{
					vm.repTransDetails[index].CY_AMOUNT = 0;
					vm.repTransDetails[index].EARLY_ADJ_AMT = 0;
					vm.repTransDetails[index].AMT_LOADING = false;
				}
				
			});
		}
		
		vm.addReportable = function(){
			var dummyRecord = angular.copy(vm.dummyRepTransaction[0]);
			var detail_length = vm.repTransDetails.length;
			dummyRecord.OCCURRENCE = detail_length;
			dummyRecord.IS_CHANGED_FLAG = 'Y';
			vm.repTransDetails.push(dummyRecord);
			console.log("After addign the new dummy record, the transaction data was :::::::", vm.repTransDetails);
			isEnableAddRow("A");
		}
		
		vm.removeportable = function(parentIndex){
			var deletedAcctIndex = vm.AccountList.findIndex(x => x.GL_ACCT == vm.repTransDetails[parentIndex].GL_ACCT_DESC);
			if(null != deletedAcctIndex && deletedAcctIndex != undefined && deletedAcctIndex > -1){
				vm.AccountList[deletedAcctIndex].SELECTEDACCT = 0;
			}
			if(vm.repTransDetails[parentIndex].TRANS_DETAILS_KEY != null && vm.repTransDetails[parentIndex].TRANS_DETAILS_KEY != undefined){
				vm.repTransDetails[parentIndex].IS_CHANGED_FLAG = "D";
				vm.deletedRepTransaction.push(vm.repTransDetails[parentIndex]);
			}
			vm.repTransDetails.splice(parentIndex, 1);
			isEnableAddRow("D");
			vm.targetAdjustmentAmt = 0;
			for(var i = 0; i < vm.repTransDetails.length; i++){
				vm.targetAdjustmentAmt = vm.targetAdjustmentAmt - vm.repTransDetails[i].REP_TRANS_AMT;
			}
			getTargetCYAmount();
		}
		
		vm.updateRepType = function(){
			vm.typeofrptError = false;
			vm.repTransHeader.IS_CHANGED_FLAG = 'Y';
			console.log("The type of Reason for Reportable Transaction is :::::::", vm.repTransHeader.REASON_REPORTABLE);
			var selectedAcctIndex = vm.ReporatableReasonTypes.findIndex(x => x.CODE_DESC == vm.repTransHeader.REASON_REPORTABLE);
			vm.repTransHeader.ADJ_TYPE = vm.ReporatableReasonTypes[selectedAcctIndex].CODE_NAME;
			console.log("The Reportable Transaction was:    ::::", vm.repTransHeader);
		}
		
		vm.updateAmount = function(parentIndex){
			vm.RECLASS_ADJ_AMT_ERROR = false;
			vm.targetAdjustmentAmt = 0;
			console.log("The values of the Target amounts are ::::::::", vm.targetAdjustmentAmt, vm.repTransDetails[parentIndex].REP_TRANS_AMT );
			vm.repTransDetails[parentIndex].ADJUSTED_CY_AMOUNT = vm.repTransDetails[parentIndex].CY_AMOUNT + vm.repTransDetails[parentIndex].EARLY_ADJ_AMT + vm.repTransDetails[parentIndex].REP_TRANS_AMT;
			vm.repTransDetails[parentIndex].IS_CHANGED_FLAG = "Y";
			for(var i = 0; i < vm.repTransDetails.length; i++){
				vm.targetAdjustmentAmt = vm.targetAdjustmentAmt - vm.repTransDetails[i].REP_TRANS_AMT;
			}
			getTargetCYAmount();
		}
		
		vm.reportabletaxamountchange = function(){
			vm.repTransHeader.IS_CHANGED_FLAG = 'Y';
			vm.reportabletaxamountError = false;
		}
		
		vm.updatereasonforZBA = function(){
			vm.repTransHeader.IS_CHANGED_FLAG = 'Y';
			vm.reasonforzeroError = false;
		}
		
		vm.updateTransDesc = function(){
			vm.repTransHeader.IS_CHANGED_FLAG = 'Y';
			vm.rptdescError = false;
		}
		
		vm.save = function(){
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			vm.finalRepTransHeader = angular.copy(vm.repTransHeader);
			vm.finalRepTransDetails = angular.copy(vm.repTransDetails);
			vm.finalRepTransDetails = vm.finalRepTransDetails.concat(vm.deletedRepTransaction);
			var rep_message = "Reportable Transaction Saved Successfully.";
			var message = "Please save this form and re-open to see the saved Reportable Transaction!"; 
			var screen_key = GlobalService.globalParams.screen_key;
			console.log("The final output for the Reportable Transaction was ::::::::", vm.finalRepTransDetails);
			if((vm.finalRepTransHeader != vm.originalRepHeader ) || (vm.finalRepTransDetails != vm.originalRepTransaction)){
				var validated = vm.validateRepTransData(vm.finalRepTransHeader, vm.finalRepTransDetails);
				if(validated){
					//ReportableTransactionFactory.sendFinalRepData(vm.finalRepTransaction);
					vm.crudLoading = true;
					ReportableTransactionFactory.saveSchddReportableTransaction(rowData, vm.logged_in_user, vm.finalRepTransHeader, vm.finalRepTransDetails, vm.rep_trans_id, screen_key).then(function (data){
						console.log("Result from rep Trans save data was ---------------::::", data);
						if(null != data.data.errorMessage && data.data.errorMessage != "null"){
							vm.crudLoading = false;
							console.log("There was the error message and the message is ", data.data.errorMessage);
							AlertService.add("error", data.data.errorMessage, 4000);
						}else {
							console.log("There wa no error message and the message was ", data.data.errorMessage);
							AlertService.add("success", rep_message, 4000);
							AlertService.add("info", message, 20000);
							vm.crudLoading = false;
							vm.cancel();
						}
					});
				}else{
					return false;
				}
			}
			/*$rootScope.$emit("repTransData", vm.finalRepTransaction);
			$uibModalInstance.dismiss('cancel');*/
		}
		
		vm.validateRepTransData = function(repTransHeader, repTransDetails){
			var passed = true;
			if(repTransHeader.ADJ_DESC == null || repTransHeader.ADJ_DESC == undefined || repTransHeader.ADJ_DESC.length <= 0 ){
				vm.rptdescError = true;
				passed = false;
			}
			if(repTransHeader.REASON_REPORTABLE == null || repTransHeader.REASON_REPORTABLE == undefined || repTransHeader.REASON_REPORTABLE.length <= 0){
				vm.typeofrptError = true;
				passed = false;
			}
			if(repTransHeader.REP_TAX_AMT == null || repTransHeader.REP_TAX_AMT == undefined || repTransHeader.REP_TAX_AMT == 0){
				vm.reportabletaxamountError = true;
				passed = false;
			}
			
			if(repTransHeader.ADJ_SOURCE == "ZBA"){
				if(repTransHeader.HEADER_COMMENTS == null || repTransHeader.HEADER_COMMENTS == undefined || repTransHeader.HEADER_COMMENTS.length <= 0){
					vm.reasonforzeroError = true;
					passed = false;
				}
			} else if(repTransHeader.ADJ_SOURCE == "BAA"){
				for(var i=0; i < repTransDetails.length; i ++){
					if(repTransDetails[i].GL_ACCT_DESC == null || repTransDetails[i].GL_ACCT_DESC == undefined || repTransDetails[i].GL_ACCT_DESC.length <= 0 ){
						vm.ACCT_KEY_ERROR = true;
						passed = false;
					}
					if(repTransDetails[i].REP_TRANS_AMT == null || repTransDetails[i].REP_TRANS_AMT == undefined || repTransDetails[i].REP_TRANS_AMT == 0){
						vm.RECLASS_ADJ_AMT_ERROR = true;
						passed = false;
					}
				}
			}
			return passed;
		}
    }

	return controllers;

});