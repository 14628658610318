define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.DefaultsFTCService',[])

    .factory('DefaultsFTCObjFactory', ['$rootScope','$http','AlertService','GlobalService','JsonObjectFactory', function($rootScope,$http,AlertService,GlobalService,JsonObjectFactory){
        
    	 var defaultsFTCObjFactory ={};
    	 defaultsFTCObjFactory.url ={};
    	defaultsFTCObjFactory.url.local_to_system_save = "saveJsonObject.ge?action_code=hhba21"
        defaultsFTCObjFactory.url.delete_tax_sensitive_details  = "saveJsonObject.ge?action_code=nbms3d";
   	 defaultsFTCObjFactory.url.save_tax_sensitive_details  = "saveJsonObject.ge?action_code=nbms3a";

    	 defaultsFTCObjFactory.getDefaultsDetails = function(){
             console.log("_data ::",_data);
             var params= {
                     "action_code": 'p6lqtn',
             }
             
               return defaultsFTCObjFactory.getJSONObj('loadJsonObject.ge',params).then(function(data) {
                   if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                      AlertService.add("error", data.errorMessage);
                      return false;
                  }else{
                      return data.jsonObject;
                  }
              });
            
           
            }
    	 
    	 return defaultsFTCObjFactory;


    }])



    return services;


});
