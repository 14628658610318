define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.recurringEPController', [])
            .controller('RecurringEPController', ['$rootScope', '$scope', '$filter', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData', 'colData', 'JsonObjectFactory', 'RecurringEPFactory','GENERAL_CONFIG', recurringEPController]);



    function recurringEPController($rootScope, $scope, $filter, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, colData, JsonObjectFactory, RecurringEPFactory,GENERAL_CONFIG) {

    	var vm = this;
        vm.rowData = rowData;
        vm.grid_type = vm.rowData.GRID_TYPE;
        
        vm.title = "Recurring E&P Adjustments";
        
        vm.showEPForm = false;
        vm.crudLoading = false;
        vm.existingLoading = false;
        vm.noRecords = false;
        vm.colData = colData;
        vm.scenario = GlobalService.globalParams.scenario;
        vm.taxYear = GlobalService.globalParams.tax_year;
        vm.mainScreenKey = RecurringEPFactory.getActiveScreen();
        vm.LEID = vm.rowData.LEID;
        vm.CDR_NO = vm.rowData.CDR_NO;
        vm.REPORTING_PERIOD = vm.rowData.REPORTING_PERIOD;
        vm.ME_CODE = vm.rowData.ME_CODE;
        vm.CURR = vm.rowData.CURR;
        vm.COMBINATION_KEY = vm.rowData.COMBINATION_KEY;
        vm.line_flag = true;
        if(vm.rowData.LE_TAX_TYPE == 'PTSP' || vm.rowData.LE_TAX_TYPE == 'BRPTSP' || vm.rowData.LE_TAX_TYPE == 'BRPTP')
        	vm.line_flag = false;
        
        
        $scope.reset = function () {
            init();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.getAccounts = function(val, acctFor) {

            var acct_type_str = (acctFor === "OFFSET")? '1,2,3' : '5,6,7';
            var is_system_acct_search = 'N';
            var parent_acct_key;


            parent_acct_key = (acctFor === 'OFFSET' ? vm.parentObject.LOCAL_ACCT_KEY: 0);

            return RecurringEPFactory.getAccounts(val, 
                                                vm.mainScreenKey, 
                                                vm.parentObject.CHART_KEY,
                                                vm.parentObject.LOCAL_ACCT_KEY,
                                                acct_type_str
                                                );

        };

        vm.changeSchd = function() {

            vm.parentObject["SCHD_H_LINE_NO"] = vm.getLineDetails.selected.LINE_NO;
        };
/*
        vm.changeOffsetSchd = function(index, model) {
            
            vm.addOffsetRows[index]["SCHD_H_LINE_NO"] = 0;
            vm.addOffsetRows[index]["ACCT_LINE_NO"] = ""
                    + vm.addOffsetRows[index]["LOCAL_ACCT_KEY"]
                    + model.LINE_NO;
            vm.INVALID_OBJECT = "N";
         
        };
*/
        vm.setAccount = function(account, acctFor, index) {
            var balSheetAccts = [1, 2, 3];
            console.log(account.TO_ACCT_TYPE);
        
            // validateAcctLineNo(account.FROM_ACCT_TYPE, acctFor);
            if (acctFor === 'PARENT') {
                vm.parentObject["LOCAL_ACCT_KEY"] = account.LOCAL_ACCT_KEY;
                vm.parentObject["LOCAL_ACCT"] = account.ACCT;
                if(balSheetAccts.includes(account.TO_ACCT_TYPE)) {
                    vm.isSchdHDisabled=true;
                    vm.getLineDetails.selected = vm.getLineDetails.list[0];
                    vm.parentObject["SCHD_H_LINE_NO"] = vm.getLineDetails.selected.LINE_NO;
                } else {
                    vm.isSchdHDisabled=false;
                }
            } else {
                vm.offsetObject["LOCAL_ACCT_KEY"] = account.LOCAL_ACCT_KEY;
                vm.offsetObject["LOCAL_ACCT"] = account.ACCT;
                vm.offsetObject["LOCAL_ACCT_TYPE"] = account.LOCAL_ACCT_TYPE;
                vm.offsetObject["ACCT_LINE_NO"] = ""
                        + account.LOCAL_ACCT_KEY
                        + vm.offsetObject["SCHD_H_LINE_NO"];
                vm.isOffSetAcctExists = true;

                vm.INVALID_OBJECT = "N"; 
                if(balSheetAccts.includes(account.TO_ACCT_TYPE)) {
                    vm.offsetObject["ISOFFSETSCHDHDISABLED"]=true;
                    vm.offsetObject["selectedSchdH"] = vm.getOffsetLineDetails.list[0];
                    vm.offsetObject["SCHD_H_LINE_NO"] = vm.getOffsetLineDetails.selected.LINE_NO;
                } else {
                    vm.offsetObject["ISOFFSETSCHDHDISABLED"]=false;
                }
            }

        };

        vm.setAdjyear = function(_index, yearType) {
            vm.total_loops_header_arr = [{"HEADER_NAME": "YEAR", "HEADER_INDEX": "YEAR"}, {"HEADER_NAME":"Income Statement-"+vm.parentObject["LOCAL_ACCT"], "HEADER_INDEX": "CUR_ADJ_AMT"}];
            vm.total_loops_arr = [];
            var begin_year = vm.parentObject["BEGIN_YEAR"];
            var end_year = vm.parentObject["END_YEAR"];
            var total_loops = end_year - begin_year + 1;
            var currentYear = 0;
            var adjYearObj = {};

            
            if (yearType === 'ENDYEAR') {
                vm.showAdjamtByYear = true;

                if (parseInt(end_year) < parseInt(begin_year)) {
                    vm.recurringEPadjsForm.endYear.$setValidity(
                        "invalidEndYear", false);
                } else {
                    vm.recurringEPadjsForm.endYear.$setValidity(
                        "invalidEndYear", true);
                }
                for (var i=0; i<total_loops;i++) {

                    currentYear = parseInt(begin_year) + i;
                        vm.total_loops_arr.push({
                            YEAR: currentYear,
                            CUR_ADJ_AMT: vm.parentObject["ADJ_AMOUNT"]
                        })
                } 
                vm.setOffsetAdjAmount();
            } else {
                if (parseInt(begin_year) !== parseInt(vm.taxYear)) {
                    vm.recurringEPadjsForm.beginYear.$setValidity(
                        "invalidBeginYear", false);
                } else {
                    vm.recurringEPadjsForm.beginYear.$setValidity(
                        "invalidBeginYear", true);
                }
            }
            console.log('Form valid?', vm.recurringEPadjsForm.$invalid);
        };

        vm.setAdjAmount = function(type) {
        	if(vm.total_loops_header_arr !== undefined)
        	{
	        	if(type == 'INCOME')
	        	{
		            angular.forEach(vm.total_loops_arr, function (value, _index) {
		
		                vm.total_loops_arr[_index]["CUR_ADJ_AMT"] = vm.parentObject["ADJ_AMOUNT"];
		            });
	        	}
	        	else
	        	{
	        		var nextIndex = vm.total_loops_header_arr.length;
	        		angular.forEach(vm.total_loops_arr, function (value, _index) {
	        			
		                vm.total_loops_arr[_index]["OFFSET_AMT_" + nextIndex] = vm.offsetObject["ADJ_AMT"];
		            });
	        	}
        	}
        };

        vm.setOffsetAdjAmount = function() {
            vm.total_loops_header_arr.splice(0 + 2);
            //vm.total_loops_arr.splice(_index + 2);
            var nextIndex = vm.total_loops_header_arr.length + 1;
            vm.total_loops_header_arr.push({"HEADER_NAME":"Balance Sheet-"+vm.offsetObject["LOCAL_ACCT"], "HEADER_INDEX": "OFFSET_AMT_" + nextIndex});
            var begin_year = vm.parentObject["BEGIN_YEAR"];
            var end_year = vm.parentObject["END_YEAR"];
            var total_loops = end_year - begin_year + 1;
            var currentYear = 0;
            for (var i=0; i<total_loops;i++) {
                 currentYear = parseInt(begin_year) + i;
                    vm.total_loops_arr[i]["OFFSET_AMT_" + nextIndex] = vm.offsetObject["ADJ_AMT"];
                    vm.total_loops_arr[i]["RECORD_TYPE"] = "O";
                    
                    
            } 
            
        };


        vm.save = function () {
        	vm.checkAcct();
            if (vm.recurringEPadjsForm.$invalid) {
                vm.recurringEPadjsForm.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            /*if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            } */

            var headerSettings = [];
            var transactions = [];
            
            var headerObj = {};
            var message = 'Changes have been saved successfully';

            headerObj.tax_year = RecurringEPFactory.getScreenFilters().tax_year;
            headerObj.COMBINATION_KEY = vm.rowData.COMBINATION_KEY;
            headerObj.TRANS_HEADER_KEY = 0;
            headerObj.EP_AUTO_KEY = vm.parentObject["EP_AUTO_KEY"];
            headerObj.ADJ_DESC = vm.parentObject["ADJ_DESC"];
            headerObj.APPLY_SAME_ADJ = vm.parentObject["APPLY_SAME_ADJ"];
            headerObj.SUPPL_INFO = vm.parentObject["SUPPL_INFO"];
            headerObj.BEGIN_YEAR = vm.parentObject["BEGIN_YEAR"];
            headerObj.END_YEAR = vm.parentObject["END_YEAR"];
            headerObj.TOTAL_LOOPS = vm.parentObject["END_YEAR"] - vm.parentObject["BEGIN_YEAR"] + 1;
           
            
            headerSettings.push(headerObj);
            
            angular.forEach(vm.total_loops_arr, function (value, _index) {
                    var adjModel = new adjustment_model(
                                            vm.LEID,
                                            vm.CDR_NO,
                                            vm.REPORTING_PERIOD,
                                            vm.ME_CODE,
                                            vm.CURR,
                                            vm.COMBINATION_KEY,
                                            vm.parentObject["LOCAL_ACCT"],
                                            vm.parentObject["LOCAL_ACCT_KEY"],
                                            vm.parentObject["SCHD_H_LINE_NO"],
                                            vm.parentObject["CHART_KEY"],
                                            vm.parentObject["ADJ_AMOUNT"],
                                            value.YEAR,
                                            value.CUR_ADJ_AMT,
                                            "P");
                    transactions.push(adjModel);
               
            });

            if (vm.isOffSetAcctExists) {
                // if (vm.addOffsetRows.length > 1) {
                //     validateData();
                // }

                // _.forEach(vm.addOffsetRows, function(value, key) {
                //     var adjModel = new adjustment_model(
                //             0,
                //             vm.LEID,
                //             vm.CDR_NO,
                //             vm.REPORTING_PERIOD,
                //             vm.ME_CODE,
                //             vm.CURR,
                //             value.LOCAL_ACCT, 
                //             value.LOCAL_ACCT_KEY, 
                //             value.SCHD_H_LINE_NO,
                //             value.CHART_KEY,
                //             0,
                //             vm.taxYear,
                //             value.ADJ_AMT,
                //             value.RECORD_TYPE);
                // }

               // _.forEach(vm.addOffsetRows, function(mainVal, key) {
            	console.log("offsetObject", vm.offsetObject);
                    angular.forEach(vm.total_loops_arr, function (value, _index) {
                        var offsetIndex =  3;
                        
                        if(value.hasOwnProperty("OFFSET_AMT_" + offsetIndex)){
                        	console.log("offsetObject", vm.offsetObject);
                        	console.log("offet_actc_key---", value);
                            var adjModel = new adjustment_model(
                                                    vm.LEID,
                                                    vm.CDR_NO,
                                                    vm.REPORTING_PERIOD,
                                                    vm.ME_CODE,
                                                    vm.CURR,
                                                    vm.COMBINATION_KEY,
                                                    vm.offsetObject.LOCAL_ACCT, 
                                                    vm.offsetObject.LOCAL_ACCT_KEY, 
                                                    /*vm.offsetObject.SCHD_H_LINE_NO*/'0',
                                                    vm.offsetObject.CHART_KEY,
                                                    0,
                                                    value.YEAR,
                                                    value["OFFSET_AMT_" + offsetIndex],
                                                    "O");
                                transactions.push(adjModel);
                        }
                    });
               // });
            }

            
            RecurringEPFactory.saveAdjs(headerSettings, transactions).then(function (result) {
                   
                if (result.errorMessage && result.errorMessage !== 'null') {
                    AlertService.add("error", result.errorMessage, 4000);
                } else {
                    
                    AlertService.add("success", message, 4000);
                    //BROADCAST CHANGE
                    var args = {combination_keys:vm.rowData.COMBINATION_KEY, gridFilter: {combination_keys: vm.rowData.COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);
                    $uibModalInstance.close();
                    
                    //init();
                }
            });

        };

       /* vm.addOffsetAccount = function() {

            var offsetObject = {
                "CHART_KEY" : vm.rowData.CHART_KEY,
                "LOCAL_ACCT_KEY" : "",
                "LOCAL_ACCT": "",
                "ACCT_DD_DISPLAY" : "",
                "LOCAL_ACCT_TYPE" : "",
                "SCHD_H_LINE_NO" : "0",
                "ISOFFSETSCHDHDISABLED": true,
                "ADJ_AMT" : 0,
                "TRANS_DETAILS_KEY" : 0,
                "IS_ACCT_EDITABLE" : "Y",
                "ACCT_LINE_NO" : "",
                "selectedSchdH" : vm.getOffsetLineDetails.list[0],
                "RECORD_TYPE": 'O'
            };

            vm.addOffsetRows.push(offsetObject);
        };*/

        vm.loadEPRecurData = function(_index) {
            var EP_AUTO_KEY = vm.existingAdjustments[_index].EP_AUTO_KEY;
            getExistingData('EDIT', vm.existingAdjustments[_index].EP_AUTO_KEY);
            vm.editLoadType = 'E';
          
           

        };

        vm.deleteAdjs = function (adj) {
            var returnObj = [];
            vm.existingLoading = true;

            returnObj.push({"EP_AUTO_KEY":adj.EP_AUTO_KEY,"combination_key":vm.rowData.COMBINATION_KEY});
           
            var message = "Tax Adjustment has been successfully deleted";
            RecurringEPFactory.deleteAdjs(returnObj).then(function (result) {
                   
                if (result.errorMessage && result.errorMessage !== 'null') {
                    AlertService.add("error", result.errorMessage, 4000);
                } else {
                    AlertService.add("success", message, 4000);
                    //BROADCAST CHANGE
                    //$uibModalInstance.close();
                    var args = {combination_keys:vm.rowData.COMBINATION_KEY, gridFilter: {combination_keys: vm.rowData.COMBINATION_KEY}};
                    $rootScope.$emit('gridUpdate', args);
                    $uibModalInstance.close();
                }
            });
        };

        function init() {
            vm.crudLoading = false;
            vm.existingLoading = false;
            vm.noRecords = false;
            vm.total_loops_arr = [];
            vm.getLineDetails = {
                selected : null,
                list : [],
                disabled : true
            };
            vm.getOffsetLineDetails = {
                selected : null,
                list : [],
                disabled : true
            };
            vm.offset_acct = null;
            vm.parentAcct = null;
            vm.parentObject = {
                "LOCAL_ACCT_KEY": "",
                "LOCAL_ACCT": "",
                "SCHD_H_LINE_NO" : "0",
                "CHART_KEY" : vm.rowData.CHART_KEY,
                "ADJ_DESC": "",
                "BEGIN_YEAR": "",
                "END_YEAR": "",
                "APPLY_SAME_ADJ": "N",
                "ADJ_AMOUNT": 0,
                "SUPPL_INFO": "",
                "RECORD_TYPE": 'P',
                "EP_AUTO_KEY":0,
                "ACCT_DD_DISPLAY" : ''
            }
            vm.showAdjamtByYear = false;
            vm.editLoadType = 'L';
            vm.existingAdjustments = [];
            vm.editExistingAdj = [];
            vm.offsetObject = {
                    "CHART_KEY" : vm.rowData.CHART_KEY,
                    "LOCAL_ACCT_KEY" : "",
                    "LOCAL_ACCT": "",
                    "ACCT_DD_DISPLAY" : "",
                    "LOCAL_ACCT_TYPE" : "",
                    "SCHD_H_LINE_NO" : "0",
                    "ISOFFSETSCHDHDISABLED": true,
                    "ADJ_AMT" : 0,
                    "TRANS_DETAILS_KEY" : 0,
                    "IS_ACCT_EDITABLE" : "Y",
                    "ACCT_LINE_NO" : "",
                    "selectedSchdH" : vm.getOffsetLineDetails.list[0],
                    "RECORD_TYPE": 'O',
                    "ACCT_DD_DISPLAY" : ''
                };
            //vm.addOffsetRows = [];
            vm.editHeaderArr = [];
            vm.isSchdHDisabled = true;

            if (vm.rowData.COMBINATION_KEY) {
                
                CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                CodeComboFactory.showFormBtn = false;

            
            }

            if (vm.recurringEPadjsForm !== undefined) {
                vm.recurringEPadjsForm.$setPristine();
                vm.recurringEPadjsForm.$setUntouched();
            }
            //vm.addOffsetAccount();
            getAllLineDetails();
            getExistingData('LOAD',null);
        }
        init();


        $scope.$on('CCKeySet', function (event, data) {

            vm.showEPForm = true;
            console.log('init Form invalid?', vm.recurringEPadjsForm.$invalid);

         
        });
         vm.checkAcct = function(){
        	if(vm.parentObject.APPLY_SAME_ADJ =='Y' && (vm.parentObject.LOCAL_ACCT_KEY == null || vm.parentObject.LOCAL_ACCT_KEY == undefined || vm.parentObject.LOCAL_ACCT_KEY == ''))
        	{
        		vm.recurringEPadjsForm.account.$dirty = true;
        		vm.recurringEPadjsForm.account.$invalid = true;
        		vm.parentObject.APPLY_SAME_ADJ ='N';
        	}
        	if((vm.offsetObject.LOCAL_ACCT_KEY == null || vm.offsetObject.LOCAL_ACCT_KEY == undefined || vm.offsetObject.LOCAL_ACCT_KEY == ''))
        	{
        		vm.recurringEPadjsForm.offsetAccount.$dirty = true;
        		vm.recurringEPadjsForm.offsetAccount.$invalid = true;
        		//vm.parentObject.APPLY_SAME_ADJ ='N';
        	}
         }
        function getAllLineDetails() {
            RecurringEPFactory.getLineDetails(vm.mainScreenKey, vm.rowData.LE_TAX_TYPE)
                    .then(function(res) {
                                vm.getLineDetails.list = res;
                                vm.getOffsetLineDetails.list = res;
                                vm.getLineDetails.selected = null;
                                vm.getOffsetLineDetails.selected = vm.getOffsetLineDetails.list[0];
                                vm.offsetObject["selectedSchdH"] = vm.getOffsetLineDetails.list[0];
                                vm.parentObject["SCHD_H_LINE_NO"] = 'N/A';

                        });
        };
        
        function getExistingData(type,EP_AUTO_KEY) {
            vm.existingLoading = true;
            RecurringEPFactory.getExistingData(vm.COMBINATION_KEY, vm.rowData.CHART_KEY, type, EP_AUTO_KEY)
                              .then(function(res) {
                                vm.existingLoading = false; 
                                if(type==='LOAD') {
                                    vm.existingAdjustments = res;
                                    
                                    if (vm.existingAdjustments.length === 0) {
                                        vm.noRecords = true;
                                    } 
                                } else {
                                    
                                    formatAdjData(res);
                                }
                              })
        }

        function adjustment_model(leid, cdr_no, reporting_period, me_code, curr, combination_key, local_acct,
                                  local_acct_key, schd_h_line_no, chart_key, adj_amount, year, year_adj_amt, record_type) {
            this.LEID = leid;
            this.CDR_NO = cdr_no;
            this.REPORTING_PERIOD = reporting_period;
            this.ME_CODE = me_code
            this.CURR = curr;
            this.LOCAL_ACCT = local_acct;
            this.LOCAL_ACCT_KEY = local_acct_key;
            this.SCHD_H_LINE_NO = schd_h_line_no;
            this.CHART_KEY = chart_key;
            this.ADJ_AMOUNT = adj_amount;
            this.YEAR = year;
            this.YEAR_ADJ_AMT = year_adj_amt;
            this.RECORD_TYPE = record_type;
            this.COMBINATION_KEY = combination_key;
            
        }

        function formatAdjData(_data) {
            vm.total_loops_header_arr = [{"HEADER_NAME": "Tax Year", "HEADER_INDEX":"YEAR"}];
            vm.total_loops_arr = [];
            //vm.addOffsetRows = [];
            var taxYear = 0;
            var nextIndex = 0;
            var lineIndex = 0;
            //vm.addOffsetRows.length=0;
            console.log('format data', _data);
            _.forEach(_data, function (value, key) {
                if(value.TAXYEAR_RANK === 0 && value.OFFSET_COUNT > 0) {
                    vm.isOffSetAcctExists = true;
                }
                
                if(value.PARENT_HEADER_VALUE !== 'NA' && value.ROWNUMBER === 1) {

                    vm.total_loops_header_arr.push({"HEADER_NAME": value.PARENT_HEADER_VALUE, "HEADER_INDEX":"PARENT"});
                }
                
                if(vm.isOffSetAcctExists) {
                    if(value.OFFSET_HEADER_VALUE !== 'NA') {
                        nextIndex = 2 + value.ROWNUMBER;

                        vm.total_loops_header_arr.push({"HEADER_NAME": value.OFFSET_HEADER_VALUE, "HEADER_INDEX": "OFFSET_AMT_" + nextIndex});
                    }
                }
               
            });

            //angular.forEach(vm.editHeaderArr, function (value, _index) {
               
                _.forEach(_data, function (value, key) {
                   
                     if(value.ROWNUMBER === 1) {
                        vm.total_loops_arr[value.TAXYEAR_RANK] = {};
                        nextIndex = 3;
                       
                        vm.total_loops_arr[value.TAXYEAR_RANK]["YEAR"] = value.TAX_YEAR;
                        vm.total_loops_arr[value.TAXYEAR_RANK]["CUR_ADJ_AMT"] = value.PARENT_AMT;
                        vm.total_loops_arr[value.TAXYEAR_RANK]["OFFSET_AMT_" + nextIndex] = value.OFFSET_AMT;
                        vm.parentObject["LOCAL_ACCT"] = value.PARENT_ACCT;
                        vm.parentObject["ACCT_DD_DISPLAY"] = value.PARENT_ACCT_DD;
                        vm.parentObject["LOCAL_ACCT_KEY"] = value.PARENT_ACCT_KEY;
                        vm.parentObject["SCHD_H_LINE_NO"] = value.PARENT_LINE_NO;
                        vm.parentObject["ADJ_AMOUNT"] = value.PARENT_AMT;
                        vm.parentObject["ADJ_DESC"] = value.EP_DESC;
                        vm.parentObject["EP_AUTO_KEY"] = value.EP_AUTO_KEY;
                        vm.parentObject["SUPPL_INFO"] = value.EP_SUPPL_INFO;
                        lineIndex = _.findIndex(vm.getLineDetails.list, {
							'LINE_NO' : vm.parentObject.SCHD_H_LINE_NO
						});
						vm.getLineDetails.selected = vm.getLineDetails.list[lineIndex];

                        if(vm.isOffSetAcctExists) {
                        

                            vm.offsetObject["CHART_KEY"] = vm.rowData.CHART_KEY;
                            vm.offsetObject["LOCAL_ACCT_KEY"] = value.OFFSET_ACCT_KEY;
                            vm.offsetObject["LOCAL_ACCT"] = value.OFFSET_ACCT;
                            vm.offsetObject["ACCT_DD_DISPLAY"] = "";
                            vm.offsetObject["LOCAL_ACCT_TYPE"] = "";
                            vm.offsetObject["SCHD_H_LINE_NO"] = value.OFFSET_LINE_NO;
                            vm.offsetObject["ADJ_AMT"] = 0;
                            vm.offsetObject["TRANS_DETAILS_KEY"] = 0;
                            vm.offsetObject["IS_ACCT_EDITABLE"] = "Y";
                            vm.offsetObject["ACCT_LINE_NO"] = "";
                            vm.offsetObject["selectedSchdH"] = vm.getOffsetLineDetails.list[0];
                            vm.offsetObject["RECORD_TYPE"]= 'O';
                           
                
                            //vm.addOffsetRows.push(offsetObject);
                        }
                    }
                    
                    if(value.ROWNUMBER > 1) {
                        nextIndex = 3 + 1;
                        vm.total_loops_arr[value.TAXYEAR_RANK]["OFFSET_AMT_" + nextIndex] = value.OFFSET_AMT;
                       /* var offsetObject = {
                            "CHART_KEY" : vm.rowData.CHART_KEY,
                            "LOCAL_ACCT_KEY" : value.OFFSET_ACCT_KEY,
                            "LOCAL_ACCT": value.OFFSET_ACCT,
                            "ACCT_DD_DISPLAY" : "",
                            "LOCAL_ACCT_TYPE" : "",
                            "SCHD_H_LINE_NO" : value.OFFSET_LINE_NO,
                        
                            "ADJ_AMT" : 0,
                            "TRANS_DETAILS_KEY" : 0,
                            "IS_ACCT_EDITABLE" : "Y",
                            "ACCT_LINE_NO" : "",
                            "selectedSchdH" : vm.getOffsetLineDetails.list[0],
                            "RECORD_TYPE": 'O'
                        };*/
                        vm.offsetObject["CHART_KEY"] = vm.rowData.CHART_KEY;
                        vm.offsetObject["LOCAL_ACCT_KEY"] = value.OFFSET_ACCT_KEY;
                        vm.offsetObject["LOCAL_ACCT"] = value.OFFSET_ACCT;
                        vm.offsetObject["ACCT_DD_DISPLAY"] = "";
                        vm.offsetObject["LOCAL_ACCT_TYPE"] = "";
                        vm.offsetObject["SCHD_H_LINE_NO"] = value.OFFSET_LINE_NO;
                        vm.offsetObject["ADJ_AMT"] = 0;
                        vm.offsetObject["TRANS_DETAILS_KEY"] = 0;
                        vm.offsetObject["IS_ACCT_EDITABLE"] = "Y";
                        vm.offsetObject["ACCT_LINE_NO"] = "";
                        vm.offsetObject["selectedSchdH"] = vm.getOffsetLineDetails.list[0];
                        vm.offsetObject["RECORD_TYPE"]= 'O';
                        //vm.addOffsetRows.push(offsetObject);
                    }

                });
                console.log("Parent_object-----", vm.parentObject);
               console.log("offset_object-----", vm.addOffsetRows);
           // });

 

        };


    }///////////////END CTRL

    return controllers;


}); 