define([
    'angular'
], 
function () {
    'use strict';
    var controllers = angular.module('app.tbPdfPreviewController', [])
        .controller('tbPdfPreviewController', ['$scope', '$state', '$http', 'ModalFactory', 'AlertService',
            'JsonObjectFactory', 'TbFactory', '$timeout', 'rowData', 'colData', 'gridData', 'GENERAL_CONFIG', 'workspaceFactory', '$uibModalInstance', 'CodeComboFactory', 'GlobalService', tbPdfPreviewController])
    .controller('PDFPreviewCtrl', ['rowData','colData','$scope','$uibModalInstance','workspaceFactory', PDFPreviewCtrl])

    function tbPdfPreviewController($scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, TbFactory, $timeout, rowData, colData, gridData, GENERAL_CONFIG, workspaceFactory, $uibModalInstance, CodeComboFactory,
        GlobalService) {

        var vm = this;
        vm.rowData = rowData;
        vm.colData = colData;
    }
    function PDFPreviewCtrl(rowData,colData,$scope,$uibModalInstance,workspaceFactory) {
        if(colData.map == 'Pdf_Preview' ){
            var vm = this;
            if(rowData.TAX_TYPE === 'CFC' || rowData.TAX_TYPE === 'NCFC' || rowData.TAX_TYPE === 'PTSP' ||rowData.TAX_TYPE === 'NCPTSP' || rowData.TAX_TYPE === 'NCPTSPNF') {
                vm.leId = rowData.HO_LEID;
                vm.cdrNo = rowData.HO_CDR_NO;
                vm.rptPeriod = rowData.HO_REPORTING_PERIOD || rowData.HO_RPT_PD || 1;
                vm.taxYear = rowData.TAX_YEAR;        
                vm.formName = (rowData.TAX_TYPE === 'PTSP'||rowData.TAX_TYPE === 'NCPTSP' || rowData.TAX_TYPE === 'NCPTSPNF') ? "8865" : "5471";
            
                 
            } else if(rowData.TAX_TYPE === 'BRCFC' || rowData.TAX_TYPE === 'BRPTP' || rowData.TAX_TYPE === 'BRUS') {
                vm.leId = rowData.LEID;
                vm.cdrNo = rowData.CDR_NO || rowData.MARS;
                vm.rptPeriod = rowData.REPORTING_PERIOD || 1;
                vm.taxYear = rowData.TAX_YEAR;        
                vm.formName = "8858";
            } else {
                vm.leId = rowData.HO_LEID;
                vm.cdrNo = rowData.HO_CDR_NO;
                vm.rptPeriod = rowData.HO_REPORTING_PERIOD || rowData.HO_RPT_PD || 1;
                vm.taxYear = rowData.TAX_YEAR; 
                vm.systemFlag = rowData.SOURCE_SYSTEM ? rowData.SOURCE_SYSTEM[0] : 'F';
                vm.formName = "1120";
            }
           
            vm.uibModalInstance = $uibModalInstance;
        }else if(colData.map == '8858_Pdf_Preview'){
             var vm = this;
            vm.leId = rowData.LEID_8858;
            vm.cdrNo = rowData.CDR_NO_8858;
            vm.rptPeriod = rowData.REPORTING_PERIOD_8858 || rowData.RPT_PD_8858 || 1;
            vm.taxYear = rowData.TAX_YEAR;        
            vm.formName = "8858";
            vm.uibModalInstance = $uibModalInstance; 


        };

        

        vm.form_sub_cat = 'SAME_PDF';

        vm.systemFlag = vm.systemFlag || 'F';

        let filters = workspaceFactory.activeScreen.filters.getFilterParams();        
        vm.scenarioLocal = filters.scenario;
        vm.taxYear = vm.taxYear || filters.tax_year;
    }
    return controllers;

});
