define([
    'angular'
    
], function () {
    'use strict';

    var controllers =  angular.module('app.sltIsDuplicateController',[])
    .controller('sltIsDuplicateController', ['$scope', '$rootScope', '$state', '$log', 'rowData', 'ObjectDiff', 'JsonObjectFactory', 'AlertService', '$timeout', '$parse', 'JCDFactory', 'ClientAdminFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', '$uibModalInstance', 'sltIsDuplicateFactory', 'GlobalService', 'workspaceFactory', sltIsDuplicateController])

    function sltIsDuplicateController($scope, $rootScope, $state, $log, rowData, ObjectDiff, JsonObjectFactory, AlertService, $timeout, $parse, JCDFactory, ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS, $uibModalInstance, entitySyncLogFactory, GlobalService, workspaceFactory) {

        var vm = this;
        vm.title = "Duplicate Data";
        vm.loading = false;
        vm.showTables = false;
        vm.noSubmissionId = false;
        vm.noRecords = false;
        vm.sltParams = rowData;
        vm.syncParams = "";
        console.log(workspaceFactory.activeScreen);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.getLogJSON = function(){
            if(vm.sltParams.SUBMISSION_ID){
                vm.loading = true;
                return entitySyncLogFactory.getSltResponseData(vm.sltParams.TAX_YEAR, vm.sltParams.LE_KEY, vm.sltParams.SUBMISSION_ID, workspaceFactory.activeScreen.filters.filters.length > 1 ? 'N' : 'Y').then(function (data) {
                    if(data.callSuccess == "1" ){
                        vm.slt_is_duplicate_data = data;
                        vm.loading = false;
                        if(data && data.P_S3_URL_DUP_DATA && data.P_S3_URL_DUP_DATA.length == 0 && data.P_CONSOL_DUP_DATA &&
                        data.P_CONSOL_DUP_DATA.length == 0 && data.P_CORP_DUP_DATA && data.P_CORP_DUP_DATA.length == 0
                        && data.P_MISSING_XML_DATA && data.P_MISSING_XML_DATA.length == 0 && data.P_PSHIP_DUP_DATA &&
                        data.P_PSHIP_DUP_DATA.length == 0){
                            vm.showTables = false;
                            vm.noRecords = true;
                        } else {
                            vm.showTables = true;
                            vm.noRecords = false;
                        }
                    }else{
                        vm.loading = false;
                        AlertService.add("error", data.data.message, 4000);
                    }
                });
            }else{
                vm.loading = false;
                vm.noSubmissionId = true;
            }
        };
        
        vm.getLogJSON();
    }
    return controllers;
});
