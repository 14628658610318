define(
	['angular'

	],
	function () {
		'use strict';

		var controllers = angular.module(
			'app.form851Controller', []).controller(
				'Form851Controller',
				['bizzHierViewFactory', '$scope', "AlertService",
					"$filter", "GlobalService",
					"USER_SETTINGS", "entityLockingFactory",
					"$uibModal", "GENERAL_CONFIG",
					"JsonObjectFactory", "Form851Factory",
					"workspaceFactory", "$rootScope",
					Form851Controller])

		function Form851Controller(bizzHierViewFactory, $scope,
			AlertService, $filter, GlobalService, USER_SETTINGS,
			entityLockingFactory, $uibModal, GENERAL_CONFIG,
			JsonObjectFactory, Form851Factory, workspaceFactory,
			$rootScope) {

			var vm = this;
			$scope.crudLoading = false;
			$scope.trees = {};
			// $scope.rowData = rowData || $scope.$parent.rowData;
			vm.tbl_row_list = [];
			vm.tbl_cl_list = [];
			vm.src_col_list = [];
			vm.tar_col_list = [];
			vm.match_col_list = [];
			vm.saveinsertobj = [];
			vm.saveinsertobjclob = [];
			vm.sublist = [];
			vm.sublist1 = [];
			$scope.crudLoading = false;

			vm.ObjectIDLIST = [];
			vm.saveinsertobj = [];
			vm.saveinsertobjclob = [];
			vm.show_tbl = false;
			$scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
			var activeScreen = workspaceFactory.activeScreen;
			if (activeScreen && activeScreen.data) {
				vm.showBackdrop = false;
				vm.showBackdrop_analysis = false;
				vm.showBackdrop_charitable = false;
				$scope.trees.currentAlertMessage = activeScreen.data.currentAlertMessage;
				$scope.trees.loading = activeScreen.data.loading;
				$scope.trees.nodes = activeScreen.data.nodes;
			} else {
				vm.showBackdrop = true;
				vm.showBackdrop_analysis = true;
				vm.showBackdrop_charitable = true;
			}
			$scope
				.$on(
					'dataFilters:refreshGrid',
					function (event, data) {

						vm.mainScreenKey = workspaceFactory.activeScreen.screen_key;
						if (vm.mainScreenKey == "11217") {

							fetch_tbl_list_data();

						}

					});
			var logged_in_user = USER_SETTINGS.user.sso_id;

			vm.cancel = function () {
				fetch_tbl_list_data();
			}

			function fetch_tbl_list_data() {
				$scope.trees.loading_analysis = true;
				var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
						.getFilterParams()
						: {};
				var params = {
					"action_code": '32s561',
					"tax_year": filterParams.tax_year,
					"scenario": filterParams.scenario,
					"jcd_key": GlobalService.globalParams.jcd_key,
					"filing_group" : filterParams.filing_group,
				}

				return JsonObjectFactory
					.getJSONObj(
						GENERAL_CONFIG.base_url + '/loadJsonObject',
						params)
					.then(
						function (data) {

							if (data.callSuccess === "1") {

								vm.tbl_list = [];
								vm.tbl_list = data.jsonObject;
								vm.sublist = [];
								vm.sublist1 = [];
								for (var a in vm.tbl_list)

									if (vm.tbl_list[a].FIELD_TYPE == 'CHECKBOX') {
										var c = false;
										c = vm.tbl_list[a].ATTRIB_VALUE == 'N' ? 'No'
											: 'Yes';
										vm.tbl_list[a].ATTRIB_VALUE = c;
									}
								for (var b in vm.tbl_list) {
									if (vm.tbl_list[b].FIELD_TYPE == 'TEXT' && vm.tbl_list[b].LINE_NO == '(e)') {
										var tempjson = {};
										tempjson.ATTRIB_VALUE = vm.tbl_list[b].ATTRIB_VALUE;
										tempjson.line = vm.tbl_list[b].LINE_NO;
										tempjson.ATTRIB_NAME = vm.tbl_list[b].ATTRIB_NAME;
										vm.sublist.push(tempjson);
									}
									if (vm.tbl_list[b].FIELD_TYPE == 'TEXT' && vm.tbl_list[b].LINE_NO == '(f)') {
										var tempjson = {};
										tempjson.ATTRIB_VALUE = vm.tbl_list[b].ATTRIB_VALUE;
										tempjson.line = vm.tbl_list[b].LINE_NO;
										tempjson.ATTRIB_NAME = vm.tbl_list[b].ATTRIB_NAME;
										vm.sublist1.push(tempjson);
									}

								}
								$scope.trees.loading_analysis = false;
								vm.showBackdrop_analysis = false;
							} else {
								if (data.errorMessage === "no access") {
									AlertService
										.add(
											"",
											"Sorry you do not have access to do the requested action.",
											4000);
									vm.userMessage = "!! Unable to perform Selected Action...";
									vm.irsformdata = false;
								} else {
									AlertService
										.add(
											"",
											"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
											4000);
									vm.userMessage = "!! Unable to perform Selected Action...";
								}
							}

						});

			}
			;
			vm.addE = function () {
				var tjson = {};
				tjson.LINE = '(e)';
				tjson.ATTRIB_VALUE = '';
				tjson.ATTRIB_NAME = 'F851_PIII_LINE_E'
				vm.sublist.push(tjson);
			}
			vm.removeE = function (index) {
				vm.sublist.splice(index, 1);
			}
			vm.addF = function () {
				var ejson = {};
				ejson.LINE = '(f)';
				ejson.ATTRIB_VALUE = '';
				ejson.ATTRIB_NAME = 'F851_PIII_LINE_F'
				vm.sublist1.push(ejson);
			}
			vm.removeF = function (index) {
				vm.sublist1.splice(index, 1);
			}
			vm.save = function () {
				var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
				.getFilterParams()
				: {};
				if ($scope.crudLoading) {
					AlertService.add("info",
						"Please wait while we save this request", 4000);
					return;
				}
				$scope.crudLoading = true;
				vm.saveinsertobj = [];
				vm.saveinsertobjclob = [];
				var message = "Data has been saved";

				var dummyJsonsavenobj = {};
				for (var aa in vm.tbl_list) {
					if (vm.tbl_list[aa].FIELD_TYPE == 'CHECKBOX') {
						dummyJsonsavenobj = {};
						dummyJsonsavenobj.ATTRIB_NAME = vm.tbl_list[aa].ATTRIB_NAME;
						dummyJsonsavenobj.ATTRIB_VALUE = vm.tbl_list[aa].ATTRIB_VALUE == 'No' ? 'N'
							: 'Y';
						dummyJsonsavenobj.SNO = "";
						vm.saveinsertobj.push(dummyJsonsavenobj);
					}
				}
				for (var dd in vm.sublist) {
					dummyJsonsavenobj = {};
					dummyJsonsavenobj.ATTRIB_NAME = vm.sublist[dd].ATTRIB_NAME;
					dummyJsonsavenobj.ATTRIB_VALUE = vm.sublist[dd].ATTRIB_VALUE;
					dummyJsonsavenobj.SNO = parseInt(dd) + 1;
					vm.saveinsertobj.push(dummyJsonsavenobj);
				}
				for (var ee in vm.sublist1) {

					dummyJsonsavenobj = {};
					dummyJsonsavenobj.ATTRIB_NAME = vm.sublist1[ee].ATTRIB_NAME;
					dummyJsonsavenobj.ATTRIB_VALUE = vm.sublist1[ee].ATTRIB_VALUE;
					dummyJsonsavenobj.SNO = parseInt(ee) + 1;
					vm.saveinsertobj.push(dummyJsonsavenobj);
				}

				var dummyjsonsettings = {};
				dummyjsonsettings["tax_year"] = filterParams.tax_year;
				dummyjsonsettings["scenario"] = filterParams.scenario;
				dummyjsonsettings["jcd_key"] = GlobalService.globalParams.jcd_key;
				dummyjsonsettings["sso_id"] = logged_in_user;
				dummyjsonsettings["filing_group"] = filterParams.filing_key;
				vm.saveinsertobjclob
					.push(dummyjsonsettings);
				saveForm851(vm.saveinsertobjclob, vm.saveinsertobj,
					message);
			}
			function saveForm851(returnObj, accountArray, message) {

				Form851Factory.saveform851modal(returnObj, accountArray).then(
					function (result) {

						if (result.errorMessage
							&& result.errorMessage !== 'null') {
							AlertService.add("error",
								result.errorMessage, 4000);
							$scope.crudLoading = false;

						} else {
							AlertService.add("success", message, 4000);
							$scope.crudLoading = false;
							fetch_tbl_list_data();
						}
					});

			}

		}
		return controllers;

	});
