define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.firfdiiQBAIPTRService',[])
        .factory("firfdiiQBAIPTRServiceFactory",['$q','$http','$rootScope','$log','JsonObjectFactory','AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams','GENERAL_CONFIG', function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
        	var firfdiiQBAIPTRServiceFactory = {};
            var returnObj = {};
            var logged_in_user = USER_SETTINGS.user.sso_id;
            firfdiiQBAIPTRServiceFactory.saveFdiiQbaiPtrAmt = function(_callName ,_data, columnName){
            	 console.log("data", _data);
            	 
            if(!angular.isDefined(_data.rowData[_data.col_id]))
            {
            	var intr=setInterval(function(){ 
                    AlertService.add("error", "Please enter valid values");
                   clearInterval(intr);
                   $rootScope.$apply();
                },1000);
            }
           
            else
            {
                $rootScope.$broadcast('gridRefresh', false); 
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=96icfq";
                //var jsonSettings = JSON.stringify(clob_settings);
       		 	//var jsonObj = JSON.stringify(_callName);
       		 	var jsonObj = [{
	            	"tax_year" 			: _data.rowData.TAX_YEAR,   
	                "pship_cc_key" : _data.rowData.COMBINATION_KEY,
	                "override_amt" : _data.rowData.OVERRIDE,
	                "override_alct_amt" : _data.rowData.OVERRIDE_ALCT_AMT,
	                "pre_alloc_amt" :_data.rowData.PRE_ALLOC_AMT,
	                "ptr_cc_key"   : _data.rowData.PTR_CC_KEY
	                
	            }];
	            var jsonSettings = [{tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,sso_id: logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
                var saveData = true;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params =  _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
       		 	params =  _.extend({jsonSettings:JSON.stringify(jsonSettings)}, params);
                params.object_id = _data.rowData.object_id;
                params.sso_id = logged_in_user;
                var defer = $q.defer();
                //params.tax_year = rowData.TAX_YEAR;
                params.process_name =  "saveFDIIQBAIPTRAmt";
               
                service.saveJSON(url, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        AlertService.add("success", "Data saved Successfully.", 4000);
                         var args = {
                                combination_key: _data.rowData.COMBINATION_KEY,
                                gridFilter: {
                                    combination_key: _data.rowData.COMBINATION_KEY
                                }
                            };
                            $rootScope.$emit('gridUpdate', args);
                        defer.resolve(data);
                    } else {
                        AlertService.add("error", data.errorMessage);
                        var args = {
                                combination_key: _data.rowData.COMBINATION_KEY,
                                gridFilter: {
                                    combination_key: _data.rowData.COMBINATION_KEY
                                }
                            };
                            $rootScope.$emit('gridUpdate', args);
                        defer.reject(data);
                    }
                },function(data){
                    defer.reject(data);
                });
                return defer.promise;
            }
            }
            return firfdiiQBAIPTRServiceFactory;
        }]);

    return services;
});










