define([
    'angular'

], function () {
    'use strict';
    
    /* NOTE:  changed on 5/10/2019
	  Below two controllers functionalities are same, if we are using same modal in both places grid & workflow, we need to define the controllers like below
		Form4626Controller		: this controller is used on workflow Step 
		Form4626ControllerModal	: this controller is used on Grid Modal
     */
    
    var controllers = angular.module('app.Form4626Ctrl', [])
     .controller('Form4626Controller', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','$timeout',
		'GENERAL_CONFIG', Form4626Controller])
      .controller('Form4626ControllerModal', ['GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope','$scope', '$http','CodeComboFactory','$timeout',
  		,'GENERAL_CONFIG', '$uibModalInstance', 'rowData', 'colData', 'gridData', Form4626ControllerModal]);


  function Form4626Controller(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope, $scope,$http, CodeComboFactory, $timeout, GENERAL_CONFIG) {
	  Form4626ControllerModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope,$http, CodeComboFactory,$timeout, GENERAL_CONFIG, null, null, null, null, this); 
	}

  function Form4626ControllerModal(GlobalService,AlertService,JsonObjectFactory, ModalFactory, $parse,$rootScope,$scope,$http, CodeComboFactory,$timeout, GENERAL_CONFIG,
  		$uibModalInstance, rowData, colData, gridData,thisOb){
  		
  	var vm = this||thisObj;
		vm.rowData = (rowData == null ) ? $scope.$parent.rowData : rowData;
		
		if($uibModalInstance) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};  
		} else {
			vm.modal_name = null;
		}
           
        vm.modalTitle= "Form 4626: AMT/ACE Worksheet - Increase (Decrease) To Taxable Income";
        $scope.FormEntryData = {
            section1: {
                section1Headers: ["4626 Line","Alternative Minimum Tax (AMT)","INPUT","PSHIP K1","GBS","TOTAL","US","General Limitation","Passive","Treaty"],
                rows: [{
                    num:"1",
                    description:"Taxable Income or (Loss) before NOL"
                }],
                rows1: [{
                    num: "2",
                    description: "Adjustments and preferences"
                }],
                rows2: [{
                        description:"a. Depreciation of post-1986 property",
                        input: {show: true, val: 0}
                    },{
                        description:"b. Amortization of certified pollution control facilities",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"c. Amortization of mining exploration and development costs",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"d. Amortization of circulation expenditures (personal holding companies only)",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"e. Adjusted gain or loss",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"f. Long-term contracts",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"g. Merchant marine capital construction funds",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"h. Section 833(b) deduction (Blue Cross, Blue Shield, and similar type organizations only)",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"i. Tax shelter farm activities (personal service corporations only)",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"j. Passive activities (closely held corporations and personal service corporations only)",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"k. Loss limitations",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"l. Depletion",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"m. Tax-exempt interest income from specified private activity bonds",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"n. Intangible drilling costs",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        description:"o. Other adjustments and preferences",
                        input: {show: true, val: 0},
                        generalLimitation: {show: true, val: 0},
                        passive: {show: true, val: 0},
                        treaty: {show: true, val: 0}
                    },{
                        num:"3",
                        description:"Pre-adjustment AMTI"
                    }],
                
            },
            section2: {
                section2Headers: ["4626 Line","Adjusted Current Earnings (ACE)","INPUT","PSHIP K1","GBS","TOTAL","US","General Limitation","Passive","Treaty"],
                rows:[{
                    num:"1",
                    description:"Pre-adjustment AMTI. Enter the amount from line 3 of Form 4626"
                }],
                rows1:[{
                    num:"2",
                    description:"ACE depreciation adjustment"
                }],
                rows2: [{
                        description:"a. AMT depreciation"
                    }, {
                        description:"b. ACE depreciation"
                    }, {
                        input:{show: true, val:"0"},
                        description:"1. Post-1993 property"
                    }, {
                        input:{show: true, val:"0"},
                        description:"2. Post-1989, pre-1994 property"
                    }, {
                        input:{show: true, val:"0"},
                        description:"3. Pre-1990 MACRS property"
                    }, {
                        input:{show: true, val:"0"},
                        description:"4. Pre-1990 original ACRS property"
                    }, {
                        input:{show: true, val:"0"},
                        description:"5. Property described in sections 168(f)(1) through (4)"
                    }, {
                        input:{show: true, val:"0"},
                        description:"6. Other property"
                    }, {
                        num:"3",
                        description:"Inclusion in ACE of items included in earnings and profits (E&P)"
                    }, {
                        description:"a. Tax-exempt interest income",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        description:"b. Death benefits from life insurance contracts",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        description:"c. All other distributions from life insurance contracts (including surrenders)",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        description:"d. Inside buildup of undistributed income in life insurance contracts",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        description:"e. Other items (see Regulations sections 1.56(g)-1(c)(6)(iii) through (ix) for a partial list)",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        num:"4",
                        description:"Disallowance of items not deductible from E&P"
                    }, {
                        description:"a. Certain dividends received",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        description:"b. Dividends paid on certain preferred stock of public utilities that are deductible under section 247",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        description:"c. Dividends paid to an ESOP that are deductible under section 404(k)"
                    }, {
                        description:"d. Non patronage dividends that are paid and deductible under section 1382(c)",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    }, {
                        description:"e. Other items (see Regulations sections 1.56(g)-1(d)(3)(i) and (ii) for a partial list)"
                    }, {
                        num:"5",
                        description:"Other adjustments based on rules for figuring E&P"
                    },{
                        description:"a. Intangible drilling costs",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        description:"b. Circulation expenditures",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        description:"c. Organizational expenditures",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        description:"d. LIFO inventory adjustments",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        description:"e. Installment sales",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        num:"6",
                        description:"Disallowance of loss on exchange of debt pools",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        num:"7",
                        description:"Acquisition expenses of life insurance companies for qualified foreign contracts",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        num:"8",
                        description:"Depletion",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        num:"9",
                        description:"Basis adjustments in determining gain or loss from sale or exchange of pre-1994 property",
                        input:{show: true, val:"0"},
                        generalLimitation:{show:true, val:"0"},
                        passive:{show: true, val: "0"},
                        treaty:{show: true, val: "0"}
                    },{
                        num:"10",
                        description:"Adjusted Current Earnings"
                }]
            },
            section3: {
                rows: [{
                    num:"4",
                    description:"Adjusted current earnings (ACE) adjustment:"
                }, {
                    description:"a. ACE from line 10 (ACE Worksheet)"
                }, {
                    description:"b. Subtract line 3 from line 4a."
                }, {
                    description:"c. Multiply line 4b by 75% (.75)"
                }, {
                    num:"5",
                    description:"Combine lines 3 and 4c. AMTI before NOL"
                }]
            }
        }





        $scope.FormViewData = {
            FormViewHeaders: ["Form 4626 Line","Description","Amount"],
            FormViewBody:{
                rows:[{
                    num:"1",
                    description:"Taxable income or (loss) before net operating loss deduction",
                    amount:""
                }, {
                    num:"2",
                    description:"Adjustments and preferences:",
                    amount:""
                }, {
                    description:"a. Depreciation of post-1986 property",
                    amount:""
                }, {
                    description:"b. Amortization of certified pollution control facilities",
                    amount:""
                }, {
                    description:"c. Amortization of mining exploration and development costs",
                    amount:""
                }, {
                    description:"d. Amortization of circulation expenditures (personal holding companies only)",
                    amount:""
                }, {
                    description:"e. Adjusted gain or loss",
                    amount:""
                }, {
                    description:"f. Long-term contracts",
                    amount:""
                }, {
                    description:"g. Merchant marine capital construction funds",
                    amount:""
                }, {
                    description:"h. Section 833(b) deduction (Blue Cross, Blue Shield, and similar type organizations only)",
                    amount:""
                }, {
                    description:"i. Tax shelter farm activities (personal service corporations only)",
                    amount:""
                }, {
                    description:"j. Passive activities (closely held corporations and personal service corporations only)",
                    amount:""
                }, {
                    description:"k. Loss limitations",
                    amount:""
                }, {
                    description:"l. Depletion",
                    amount:""
                }, {
                    description:"m. Tax-exempt interest income from specified private activity bonds",
                    amount:""
                }, {
                    description:"n. Intangible drilling costs",
                    amount:""
                }, {
                    description:"o. Other adjustments and preferences",
                    amount:""
                }, {
                    num:"3",
                    description:"Pre-adjustment alternative minimum taxable income (AMTI). Combine lines 1 through 2o",
                    amount:""
                }, {
                    num:"4",
                    description:"Adjusted current earnings (ACE) adjustment:",
                    amount:""
                }, {
                    description:"a. ACE from line 10 of the ACE worksheet in the instructions",
                    amount:""
                }, {
                    description:"b. Subtract line 3 from line 4a. If line 3 exceeds line 4a, enter the difference as a negative amount. See instructions",
                    amount:""
                }, {
                    description:"c. Multiply line 4b by 75% (0.75). Enter the result as a positive amount",
                    amount:""
                }, {
                    description:"d. Enter the excess, if any, of the corporation’s total increases in AMTI from prior year ACE adjustments over its total reductions in AMTI from prior year ACE adjustments. See instructions. Note: You must enter an amount on line 4d (even if line 4b is positive)",
                    amount:""
                }, {
                    description:"e. ACE adjustment.",
                    amount:""
                }, {
                    description:"• If line 4b is zero or more, enter the amount from line 4c",
                    amount:""
                }, {
                    description:"• If line 4b is less than zero, enter the smaller of line 4c or line 4d as a negative amount",
                    amount:""
                }, {
                    num:"5",
                    description:"Combine lines 3 and 4e. If zero or less, stop here; the corporation does not owe any AMT",
                    amount:""
                }, {
                    num:"6",
                    description:"Alternative tax net operating loss deduction. See instructions",
                    amount:""
                }, {
                    num:"7",
                    description:"Alternative minimum taxable income. Subtract line 6 from line 5. If the corporation held a residual interest in a REMIC, see instructions",
                    amount:""
                }, {
                    num:"8",
                    description:"Exemption phase-out (if line 7 is $310,000 or more, skip lines 8a and 8b and enter -0- on line 8c):",
                    amount:""
                }, {
                    description:"a. Subtract $150,000 from line 7. If completing this line for a member of a controlled group, see instructions. If zero or less, enter -0-",
                    amount:""
                }, {
                    description:"b. Multiply line 8a by 25% (0.25)",
                    amount:""
                }, {
                    description:"c. Exemption. Subtract line 8b from $40,000. If completing this line for a member of a controlled group, see instructions. If zero or less, enter -0-",
                    amount:""
                }, {
                    num:"9",
                    description:"Subtract line 8c from line 7. If zero or less, enter -0-",
                    amount:""
                }, {
                    num:"9",
                    description:"Subtract line 8c from line 7. If zero or less, enter -0-",
                    amount:""
                }, {
                    num:"10",
                    description:"Multiply line 9 by 20% (0.20)",
                    amount:""
                }, {
                    num:"11",
                    description:"Alternative minimum tax foreign tax credit (AMTFTC). See instructions",
                    amount:""
                }, {
                    num:"12",
                    description:"Tentative minimum tax. Subtract line 11 from line 10",
                    amount:""
                }, {
                    num:"13",
                    description:"Regular tax liability before applying all credits except the foreign tax credit",
                    amount:""
                }, {
                    num:"14",
                    description:"Alternative minimum tax. Subtract line 13 from line 12. If zero or less, enter -0-. Enter here and on Form 1120, Schedule J, line 3, or the appropriate line of the corporation’s income tax return",
                    amount:""
                }]
            }



        }













        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

});
