define(
		[ 'angular', './Form1118ConfigService' ],
		function() {
			'use strict';
			var controllers = angular.module('app.Form1118ConfigCtrl', [])

			.controller(
					'form1118Config',
					[ '$rootScope', '$scope', '$state', '$log',
							'JsonObjectFactory', 'AlertService', '$timeout',

							'GENERAL_CONFIG', 'Form1118ConfigFactory', '$http',
							'USER_SETTINGS', 'GlobalService',
							'workspaceFactory', '$filter','gridData','rowData','$uibModalInstance','ModalFactory',
							formIIIEightConfigCtrl ]);
			function formIIIEightConfigCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, $timeout, GENERAL_CONFIG,
					Form1118ConfigFactory, $http, USER_SETTINGS, GlobalService,
					workspaceFactory, $filter,gridData,rowData,$uibModalInstance,ModalFactory) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				vm.gl_filing_key = filterParams.filing_group_key;
				vm.curentyear = GlobalService.globalParams.tax_year;
				//Get the scenario
				vm.scenario = GlobalService.globalParams.scenario;
				//JCD Key for the given custom screen
				vm.jcd_key = GlobalService.globalParams.jcd_key;
				vm.isCreate = (gridData.length == 0 || gridData.colData.map == "Add") ? true : false;
				vm.rowData = rowData;
				vm.filingDetails = [];
				vm.filingEnityDetails = [];
				vm.filingAllDetails = [];
				vm.filerEntityEdit = {"FILER_LE_KEY":"","FILER_LE_NAME":"SELECT"};
				vm.filerEntity = [];
				vm.configNameDetails = [];
				vm.configValueDetails = [];
				vm.configName = {};
				vm.configValue = {};
				vm.filteredTaxYearData = [];
				vm.checkboxOrSelectValue = !vm.isCreate && vm.rowData.CONFIG_VALUE === "Yes" ? true: false;
				vm.gotExistingData = null;
				
				//cancel the modal
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				vm.configNameInfo = function() {
				var params = {
					"action_code" : 'xxgk31',
				};
				JsonObjectFactory.getJSONObj(
						GENERAL_CONFIG.base_url + '/loadJsonObject', params)
						.then(function(data) {
							let nameDetails = data.jsonObject;
							nameDetails.forEach(item => {
								if(item.CONFIG_VALUE && item.CONFIG_VALUE === "Yes" /*&& item.TRANS_TYPE != "EFILE_COMPANY_NAME"*/) {
									item.checkboxOrSelectValue = true;
								} 
								
								/*if(item.CONFIG_VALUE  && item.TRANS_TYPE == "EFILE_COMPANY_NAME") {
									item.checkboxOrSelectValue = _.find(vm.configValueDetails, ['CODE_KEY', item.CONFIG_VALUE]); 
								} */
								
							});
							vm.configNameDetails = nameDetails;
							vm.configNameDetailsCopy = nameDetails;
							
							if (vm.isCreate == false) {
								 _.filter(vm.configNameDetails, function(p){
			                		  if(p.MAP_KEY === vm.rowData.MAP_KEY) {
			                			  vm.configName = p;
			                		  }
				                });
							}
						});
				}
				 vm.configNameInfo();
                
		        vm.getFillingGroupInfo = function() {
		        	var params = {
							//"action_code": 'hmkia6',
		        			"action_code":"35gc5l"
					};
	            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
	                .then(function(data) {
	                vm.filingAllDetails = Object.assign([],data.jsonObject);
	                vm.filingDetails = Object.assign([],data.jsonObject);
	                vm.filingDetails = _.uniqBy(vm.filingDetails, 'CONSOL_GROUP_KEY');
	                vm.filingEnityDetailInfo();
			       
	                    
	                });
		        }
		        
		        
		        vm.filingEnityDetailInfo = function() {
		        	 vm.filingEnityDetails = Object.assign([],vm.filingAllDetails);
				     vm.filingEnityDetails.unshift({"FILER_LE_KEY":"","FILER_LE":"SELECT"});
		                    if (vm.isCreate == false) {
		                    	   _.filter(vm.filingEnityDetails, function(p){
			                		  if(p.FILER_LE_KEY === vm.rowData.FILER_LE_KEY) {
			                			  vm.filerEntityEdit = p;
			                		  }
				                	});
		                    } else {
		                    	 vm.filerEntity = [];
		                    }
		        }
		        
		        vm.getFillingGroupInfo();
		        
		      //Get the saved data 
				vm.getSavedConfigData = function(){
					var params = {"action_code" : 'hislex' };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.gotExistingData = data.jsonObject;
						console.log("vm.gotExistingData : ", vm.gotExistingData );
					});
				}
				
				
				vm.getSavedConfigData();
		        
		        vm.reset = function(){		
					//console.log(" Reset the Modal ");
					if(vm.isCreate) {
						 vm.filingEnityDetails = Object.assign([],vm.filingAllDetails);
					     vm.filingEnityDetails.unshift({"FILER_LE_KEY":"","FILER_LE":"SELECT"});
						 vm.filerEntity = [];
						 vm.configName = {};
						 vm.checkboxOrSelectValue = false;
					} else {
						 _.filter(vm.filingEnityDetails, function(p){
	                		  if(p.FILER_LE_KEY === vm.rowData.FILER_LE_KEY) {
	                			  vm.filerEntityEdit = p;
	                		  }
		                	});
						 
						 _.filter(vm.configNameDetails, function(p){
	                		  if(p.MAP_KEY === vm.rowData.MAP_KEY) {
	                			  vm.configName = p;
	                		  }
						 });
						 
						 vm.checkboxOrSelectValue = vm.rowData.CONFIG_VALUE === "Yes" ? true: false;
						 
					}
				}

				// Perform the Save operations for data entered in the fields of
				// Modal
				vm.save = function(item) {
					vm.isSaveClicked = true;
					if ((vm.isCreate && vm.filerEntity && (vm.filerEntity.length == 0 || 
							vm.configName.MAP_KEY === undefined || vm.configName.MAP_KEY === null )) || 
							(!vm.isCreate &&  vm.filerEntityEdit && (vm.filerEntityEdit.FILER_LE_KEY == "" 
								|| vm.filerEntityEdit.FILER_LE_KEY === undefined || vm.filerEntityEdit.FILER_LE_KEY == null || 
								vm.configName.MAP_KEY === undefined || vm.configName.MAP_KEY === null))) {
						AlertService.add("error",
								"Please select/enter all the required fields.",
								4000);
						vm.isSaveClicked = false;
						return;
					}
					
					if (vm.isCreate){

						let flag1 = false;
						vm.filerEntity.forEach(function(fEntityInfo) {
							if(fEntityInfo.FILER_LE == 'SELECT') {
								flag1 = true;
							}
						});	
						
						if(flag1){
							AlertService.add("error", "Please select/enter all the required fields.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}
					
					if (vm.isCreate){
						let flag = false;
						vm.filerEntity.forEach(function(fEntityInfo) {
							if(_.find(vm.gotExistingData, {'MAP_KEY': vm.configName.MAP_KEY,
								'FILER_LE':fEntityInfo.FILER_LE,
								//'CONFIG_VALUE': vm.checkboxOrSelectValue ? "Yes" : "No"
								})) {
								flag = true;
							}
						});
							if(flag){
								AlertService.add("error", "Form 1118 Config setup already exist.",4000);
								vm.isSaveClicked = false;
								return;
							}
						
						
					}
					else {
						let flag = false;
							if(_.find(vm.gotExistingData, {'MAP_KEY': vm.configName.MAP_KEY,
								'FILER_LE':vm.filerEntity.FILER_LE,
								'CONFIG_VALUE': vm.checkboxOrSelectValue ? "Yes" : "No"})) {
								flag = true;
							}
						
						if(flag){
							AlertService.add("error", "Form 1118 Config setup already exist.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}

					var clobSettingsObj = {};
					clobSettingsObj['sso_id'] = vm.userSettings.user.sso_id;
					clobSettingsObj["TAX_YEAR"] = vm.filterTaxYear;

					var returnObj = {};
					returnObj["TAX_YEAR"] = vm.filterTaxYear;
					returnObj['ADJ_KEY'] = vm.isCreate ? null : vm.rowData.ADJ_KEY;
					returnObj['FILER_LE_KEY'] =   vm.isCreate ? _.map(vm.filerEntity,'FILER_LE_KEY').join() : vm.filerEntityEdit.FILER_LE_KEY;
					
					returnObj['MAP_KEY'] = vm.configName.MAP_KEY;
					returnObj['CONFIG_VALUE'] = vm.checkboxOrSelectValue ? "Yes" : "No";
					returnObj["OPERATION_TYPE"] =  vm.isCreate ?  "I" : "U";
					returnObj['FILING_GROUP_KEY'] = vm.gl_filing_key;
					
					
					 returnObj['TRANS_TYPE'] = vm.configName.TRANS_TYPE;
					
					var clobDataDetails = [];
					clobDataDetails.push(returnObj);
					console.log("saveDataDetails  -- ", clobDataDetails);

					var message = "Form 1118 Config have been saved/updated";
					// send to save the Data
					if (vm.isSaveClicked)
					sendDetails(clobSettingsObj, clobDataDetails, message,clobSettingsObj);
					
				}
				
				//Send the details to the Service Layer
	            function sendDetails(returnObj, details, message, otherInfo) {
	            	Form1118ConfigFactory.save(returnObj, details, otherInfo).then(function(result) {
	                    console.log(" result", result);
	                    if (result.errorMessage && result.errorMessage !== 'null') {
	                        AlertService.add("error", result.errorMessage, 4000);
	                        vm.isSaveClicked = false;
	                    } else {
	                        AlertService.add("success", message, 4000);
	                        var args = {};
	                        if (returnObj.object_id) {
	                            args = {
	                                object_id: returnObj.object_id,
	                                gridFilter: {
	                                    object_id: returnObj.object_id
	                                }
	                            };
	                        }
	                        $uibModalInstance.dismiss('cancel');
	                        $rootScope.$emit('gridUpdate', args);
	                        vm.isSaveClicked = false;
	                    };
	                });
	            }

			}
			return controllers;

		});