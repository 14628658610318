const {VERSION} = require("lodash");

define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.newProformaCbCrCtrl', [])
                             .controller('NewProformaCbCrCtrl', ['$rootScope', '$filter', '$scope', 'USER_SETTINGS', 'NewProformaFactory', 'NewProformaFormReviewFactory', 'GlobalService', 'TAX_YEARS', '$uibModal', 'AlertService', 'workspaceFactory', 'MessageService', 'GENERAL_CONFIG', '$state', newProformaCbCrCtrl]);

    function newProformaCbCrCtrl($rootScope, $filter, $scope, USER_SETTINGS, NewProformaFactory, NewProformaFormReviewFactory, GlobalService, TAX_YEARS, $uibModal, AlertService, workspaceFactory, MessageService, GENERAL_CONFIG, $state) {
        var vm = this;

        $state.current.name === 'workspace.state-group-forms' ? vm.state = true : vm.state = false;
        vm.store = 'cbcrViewStore';

        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });
        vm.expandAll = false;
        vm.expandAllTxt = 'Collapse All';
        vm.selectedFormIdent = NewProformaFormReviewFactory[vm.store].gtwViewSelectedForm || {};

        vm.efileFilters = {};
        vm.efileFiltersSelected = {};

        vm.efileFilters.taxYears = TAX_YEARS;
        vm.efileFiltersSelected.taxYear = NewProformaFormReviewFactory[vm.store].gtwViewFilter.taxYear = NewProformaFormReviewFactory[vm.store].gtwViewFilter.taxYear || GlobalService.inputs.tax_year.selected;
        vm.efileFilters.scenarios = vm.efileFiltersSelected.taxYear.scenarios.filter(function (s) {
            return s.jcd_key === (vm.state ? "253" : "250");
        })
        vm.efileFiltersSelected.scenario =
            NewProformaFormReviewFactory[vm.store].gtwViewFilter.scenario =
                NewProformaFormReviewFactory[vm.store].gtwViewFilter.scenario ||
                vm.efileFilters.scenarios.find(scenario => scenario.default_scenario === 'Y') ||
                vm.efileFilters.scenarios[0] || {};

        vm.activeScreenLocal = workspaceFactory.activeScreen;
        vm.filterCatMapping = {
            'C': 'Corporation',
            'P': 'Partnership'
        };
        vm.filterLocMapping = {
            'F': 'Foreign',
            'D': 'Domestic'
        };

        vm.gtwViewContainer = {
            gtwViewFilter: NewProformaFormReviewFactory[vm.store].gtwViewFilter,
            selectedEntityCopy: NewProformaFormReviewFactory[vm.store].gtwViewSelectedEntity || null,
            filterLocations: [
                {name: 'Domestic', value: 'D'},
                {name: 'Foreign', value: 'F'}
            ],
            filterLeTypes: [
                {name: 'CBCR', value: 'CBCR', businessType: 'C', disableSystemFlag: true, location: 'D'}
            ],
            filterEntityTypes: [
                {name: 'ALL', leType: 'BSLA', location: 'D'},
                {name: 'REG', leType: 'BSLA', location: 'D'},
                {name: 'LI', leType: 'BSLA', location: 'D'},
                {name: 'NLI', leType: 'BSLA', location: 'D'}
            ],
            recordsCount: 0,
            searchTerm: '',
            cache: {...NewProformaFormReviewFactory[vm.store].gtwViewCache} || [],
            initialize: () => {
                vm.getEntities(true);
            },
            locationChanged: (location) => {
                // if (vm.isProdEnv() && vm.filter.category === 'P') return;
                vm.pdfsContainer.loadingForms = vm.pdfsContainer.entityExistsInJobTrackingConfig = false;
                vm.pdfsContainer.refreshTimeStampMessage = null;
                vm.gtwViewContainer.selectedEntity = vm.selectedForm = vm.irs_form_name = '';
                vm.entitiesContainer.noDataMsg = 'Please select an entity from the list!';
                vm.gtwViewContainer.selectedEntityCopy = null;
                vm.entitiesContainer.expandPdfView = true;
                vm.selectedFormIdent = {};
                vm.filter.location = location;
                vm.getEntities(false, true);
            },
            typeChanged: (lEType) => {
                vm.pdfsContainer.loadingForms = vm.pdfsContainer.entityExistsInJobTrackingConfig = false;
                vm.pdfsContainer.refreshTimeStampMessage = null;
                vm.gtwViewContainer.selectedEntity = vm.selectedForm = vm.irs_form_name = '';
                vm.entitiesContainer.noDataMsg = 'Please select an entity from the list!';
                vm.gtwViewContainer.selectedEntityCopy = null;
                vm.entitiesContainer.expandPdfView = true;
                vm.selectedFormIdent = {};
                vm.filter.lEType = lEType.value;
                vm.filter.category = lEType.businessType;
                vm.filter.disableSystemFlags = lEType.disableSystemFlag;
                if (vm.filter.disableSystemFlags === true)
                    vm.filter.location = lEType.location;
                else
                    vm.filter.location = vm.filter.location;
                vm.filter.lETypeName = vm.gtwViewContainer.filterLeTypes.find(leType => leType.value == lEType.value).name;
                vm.filter.entityType = 'ALL';
                vm.getEntities(false, true);
            },
            entityTypeChanged: (lEType, entityType) => {
                vm.pdfsContainer.loadingForms = vm.pdfsContainer.entityExistsInJobTrackingConfig = false;
                vm.pdfsContainer.refreshTimeStampMessage = null;
                vm.gtwViewContainer.selectedEntity = vm.selectedForm = vm.irs_form_name = '';
                vm.entitiesContainer.noDataMsg = 'Please select an entity from the list!';
                vm.gtwViewContainer.selectedEntityCopy = null;
                vm.selectedFormIdent = {};
                vm.filter.lEType = lEType;
                vm.filter.lETypeName = vm.gtwViewContainer.filterLeTypes.find(leType => leType.value == lEType).name;
                vm.filter.entityType = entityType;
                vm.getEntities(false, true);
            },
        };

        vm.entitiesContainer = {
            loadingList: false,
            expandPdfView: true,
            treeData: [],
            noDataMsg: '',
            treeDataFiltered: [],
            showBulkExport: false,
            loaded: false,
            selectedSubsidiary: '',
            selectedCatLocal: null,
            selectedLocLocal: null,
            selectedLETypeLocal: null,
            selectedEntityTypeLocal: null,
            selectedViewLocal: null,
            consolDomesticView: [
                {parent: 'Consolidated', entityType: 'ALL', leType: 'CONSOL'},
                {parent: 'Sub Consolidation', entityType: 'REG', leType: 'CONSOL'},
                {parent: 'Life Sub Consolidation', entityType: 'LI', leType: 'CONSOL'},
                {parent: 'Non-Life Sub Consolidation', entityType: 'NLI', leType: 'CONSOL'},
                {parent: 'C Eliminations', entityType: 'ALL', leType: 'HO'},
                {parent: 'Life Eliminations', entityType: 'ALL', leType: 'HO'},
                {parent: 'Non-Life Eliminations', entityType: 'ALL', leType: 'HO'},
            ],
            setExtraParamsForConsolDomesticView: (item) => {
                const parent = vm.entitiesContainer.consolDomesticView.find(ele => ele.parent === item.parent);
                if (parent !== undefined) {
                    item.le_type = parent.leType;
                    item.entity_type = parent.entityType;
                    return item;
                } else
                    return item;
            },
            clearCache: (entity, type) => {
                var leType = vm.state ? (entity.path.includes('/ Subsidiaries') ? vm.gtwViewContainer.gtwViewFilter.HO_lEType
                                                                                : vm.gtwViewContainer.gtwViewFilter.lEType) : entity.le_type;
                var entityType = entity.entity_type;

                if ((vm.filter.lEType === 'CONSOL' || vm.filter.lEType === 'NONCONSOL') && vm.filter.location === 'D' && !vm.state) {
                    entity = vm.entitiesContainer.setExtraParamsForConsolDomesticView(entity);
                    leType = type || entity.le_type;
                    entityType = vm.filter.entityType = entity.entity_type || '';
                } else if (vm.filter.lEType === 'BSLA') {
                    entityType = vm.filter.entityType || '';
                }

                delete vm.pdfsContainer.formsPerEntity[vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario
                + '_' + vm.filter.category + '_' + vm.filter.location + '_' + leType + '_' + entityType
                + '_' + (vm.filter.lEType === 'BSLA' ? entity.me_code : entity.leid) + '_' + entity.cdr_no + '_' + entity.reporting_period
                + '_' + entity.mef_group_key];

                var clearSubsCache = false;
                if (entity.path.includes('/ Subsidiaries') && vm.filter.location === 'D' && !vm.state) {
                    var ho = vm.pdfsContainer.formsPerEntity[vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario
                    + '_' + vm.filter.category + '_' + vm.filter.location + '_' + 'HO' + '_' + entityType
                    + '_' + (vm.filter.lEType === 'BSLA' ? entity.me_code : entity.leid) + '_' + entity.cdr_no + '_' + entity.reporting_period
                    + '_' + entity.mef_group_key];
                    var hoo = vm.pdfsContainer.formsPerEntity[vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario
                    + '_' + vm.filter.category + '_' + vm.filter.location + '_' + 'HOO' + '_' + entityType
                    + '_' + (vm.filter.lEType === 'BSLA' ? entity.me_code : entity.leid) + '_' + entity.cdr_no + '_' + entity.reporting_period
                    + '_' + entity.mef_group_key];

                    if (!ho && !hoo) {
                        clearSubsCache = true;
                    }
                }
                vm.updateEntitiesCache(entity, false, type, clearSubsCache);
            }
        };

        vm.pdfsContainer = {
            pdfs: [],
            loadingForms: false,
            formsPerEntity: NewProformaFormReviewFactory[vm.store].gtwViewFormsCache || {},
            formSearchTerm: '',
            showSelectAll: false,
            isSelectAll: false,
            enableMerge: false,
            showMerge: false,
            showExpand: false,
            refreshTimeStamp: null,
            refreshTimeStampMessage: null,
            enableRefresh: false,
            isRefresh: false,
            entityExistsInJobTrackingConfig: false,
            refresh: () => {
                vm.pdfsContainer.isRefresh = true;
                vm.pdfsContainer.isRefreshing = true;
                var entity = vm.entitiesContainer.treeDataFiltered.find(item => item.path === vm.gtwViewContainer.selectedEntityCopy.path);
                vm.updateEntitiesCache(entity, false);
                vm.entitiesContainer.clearCache(entity);
                getTaxForms(entity, false, true);
            }
        }

        vm.filter = vm.gtwViewContainer.gtwViewFilter;
        vm.filter.lETypeName = vm.gtwViewContainer.filterLeTypes.find(leType => leType.value == vm.filter.lEType).name;
        vm.filter.disableSystemFlags = vm.gtwViewContainer.filterLeTypes.find(leType => leType.value == vm.filter.lEType).disableSystemFlag;
        vm.entitiesContainer.treeDataFiltered = angular.copy(vm.entitiesContainer.treeData);
        let treeDataSet = new Set;
        let parentNodesAhead = [];
        vm.selectedForm = '';

        // refresh list on global params change
        //$rootScope.$on('GlobalParams is updated', function () {
        vm.changeTaxYear = function () {
            vm.efileFilters.scenarios = vm.efileFiltersSelected.taxYear.scenarios.filter(function (s) {
                return s.jcd_key === (vm.state ? "253" : "250");
            })
            vm.efileFiltersSelected.scenario = vm.efileFilters.scenarios.find(scenario => scenario.default_scenario === 'Y') || vm.efileFilters.scenarios[0] || {};
        }

        vm.entitySelected = (item, sequentialCall = false, forceExec, subsidiaryType = '', useCache = false) => {
            if ((item.path.includes('/ Subsidiaries') && vm.filter.location === 'D' && !forceExec && !vm.state)
                || (item.is_leaf === 0 && (vm.filter.lEType !== 'BSLA'))) {
                vm.toggleGTWView(item);
                return;
            }
            if (!useCache) {
                vm.entitiesContainer.expandPdfView = true;
                vm.selectedFormIdent = {};
            }
            vm.pdfsContainer.refreshTimeStampMessage = null;
            vm.pdfsContainer.entityExistsInJobTrackingConfig = vm.pdfsContainer.isRefresh = vm.isSelectAll = false;
            vm.pdfsContainer.showSelectAll = vm.pdfsContainer.showMerge = vm.pdfsContainer.showExpand = false;

            vm.leName = item.le_name;
            vm.leEin = item.ein;
            vm.leType = item.le_type = vm.state ?
                                       (item.path.includes('/ Subsidiaries') ? vm.gtwViewContainer.gtwViewFilter.HO_lEType : vm.gtwViewContainer.gtwViewFilter.lEType)
                                                : (
                                           item.path.includes('/ Subsidiaries') && vm.filter.location === 'D' ? (
                                               (subsidiaryType === 'HO Only') ? 'HOO' : 'HO'
                                           ) : item.le_type
                                       );
            // vm.gtwViewContainer.selectedEntity = vm.filter.lEType === 'CONSOL' && vm.filter.location === 'D' ?  item.path.replaceAll('/ ', '-> '): item.path.replaceAll('/ ', '-> ').replace(vm.filter.lEType === 'BSLA' ? 
            //     item.me_label : item.entity, vm.filter.lEType === 'BSLA' ? item.me_label + ' (' + item.me_code + ')' : item.name);
            vm.gtwViewContainer.selectedEntity = vm.filter.lEType === 'BSLA' ? item.path.replaceAll('/ ', '-> ').replaceAll(item.me_label, item.me_label + ' (' + item.me_code + ')')
                                                                             : (vm.state ? item.path.replaceAll('/ ', '-> ').replaceAll(item.entity, item.name) : item.path.replaceAll('/ ', '-> '));
            vm.gtwViewContainer.selectedEntity = subsidiaryType !== '' ? vm.gtwViewContainer.selectedEntity.replace(item.name, subsidiaryType + '-> ' + item.name)
                                                                       : vm.gtwViewContainer.selectedEntity; //added HO/HOO in breadcrumb
            vm.gtwViewContainer.selectedView = vm.filter.lEType === 'BSLA' ? item.me_code : item.leid;
            vm.filter.lEType === 'BSLA' ? item.me_label : item.name;
            vm.gtwViewContainer.selectedEntityCopy = angular.copy(item);
            vm.gtwViewContainer.cdrNo = item.cdr_no;
            vm.gtwViewContainer.rpPeriod = item.reporting_period;
            vm.gtwViewContainer.mef_group_key = item.mef_group_key;
            vm.leName = vm.filter.lEType === 'BSLA' ? item.me_label : item.entity;
            vm.entityType = item.entity_type;
            vm.entityName = vm.filter.lEType === 'BSLA' ? item.me_label : item.name;

            if ((vm.filter.lEType === 'CONSOL' || vm.filter.lEType === 'NONCONSOL') && vm.filter.location === 'D' && !vm.state) {
                item = vm.entitiesContainer.setExtraParamsForConsolDomesticView(item);
                vm.leType = ((subsidiaryType === 'HO Only') ? 'HOO' : item.le_type);
                vm.entityType = vm.filter.entityType = item.entity_type || '';
            } else if (vm.filter.lEType === 'BSLA') {
                vm.entityType = vm.filter.entityType || '';
            }

            vm.entitiesContainer.selectedCatLocal = vm.filter.category;
            vm.entitiesContainer.selectedLocLocal = vm.filter.location;
            vm.entitiesContainer.selectedLETypeLocal = vm.leType;
            vm.entitiesContainer.selectedEntityTypeLocal = vm.entityType;
            vm.entitiesContainer.selectedViewLocal = vm.gtwViewContainer.selectedView; // entity id (le_id/me_code)

            NewProformaFactory.resetStates();

            // NewProformaFormReviewFactory.getDiagStatus(
            //     vm.entitiesContainer.selectedCatLocal,
            //     vm.efileFiltersSelected.taxYear.label,
            //     vm.efileFiltersSelected.scenario.scenario,
            //     vm.entitiesContainer.selectedLocLocal,
            //     vm.entitiesContainer.selectedViewLocal,
            //     vm.gtwViewContainer.cdrNo,
            //     vm.gtwViewContainer.rpPeriod,
            //     vm.leType,
            //     vm.entitiesContainer.selectedLocLocal === 'F' ? '' : vm.entityType).then((response) => {
            //     if (response.data.callSuccess === '1') {
            //         let diagList = response.data.viewList;
            //         showEntityDiagModal(diagList);
            //     }
            // });

            // read forms from cache
            if (getFormsFromCache(vm.entitiesContainer.selectedCatLocal, vm.entitiesContainer.selectedLocLocal, vm.entitiesContainer.selectedLETypeLocal, vm.entitiesContainer.selectedEntityTypeLocal, vm.entitiesContainer.selectedViewLocal)) return;

            getTaxForms(item, sequentialCall);
        };

        vm.loadPDF = (pdf) => {
            vm.pdfsContainer.showExpand = true;
            vm.entitiesContainer.expandPdfView = vm.isMerge = false;
            vm.s3UrlList = [];
            vm.taxYear = vm.efileFiltersSelected.taxYear.label;
            vm.selectedFormIdent = pdf;//.label;//pdf.form_name + (pdf.subform && pdf.form_sub_cat === "DIFF_PDF" ? '-' + pdf.subform : '');
            vm.selectedForm = pdf.form_name;
            vm.irs_form_name = pdf.irs_form;
            vm.jobId = getJobId(vm.filter.category, vm.filter.location, vm.leType, vm.entityType, vm.gtwViewContainer.selectedView);
            vm.schedule = pdf.schedule;
            vm.le_type = vm.leType;
            vm.line = pdf.line_no;
            vm.partNo = pdf.part_no;
            vm.form_sub = pdf.subform || '';
            vm.form_sub_cat = pdf.form_sub_cat;
            vm.isLoadPdf = true;
            vm.le_name = pdf.le_name;
            vm.le_ein = pdf.ein;
            vm.scenarioLocal = vm.efileFiltersSelected.scenario.scenario;
        };

        vm.loadEntities = function (useCache) {
            if (!useCache) {
                // workspaceFactory.clearCache(vm.activeScreenLocal);
                // NewProformaFormReviewFactory.gtwViewCache = {};
                vm.gtwViewContainer.cache = {};
                vm.gtwViewContainer.selectedEntityCopy = null;
                vm.pdfsContainer.isRefreshing = false;
                vm.pdfsContainer.entityExistsInJobTrackingConfig = false;
                vm.pdfsContainer.formsPerEntity = {};
                vm.pdfsContainer.refreshTimeStampMessage = null;
                vm.entitiesContainer.expandPdfView = true;

            } else {
                // if(vm.gtwViewContainer.selectedEntityCopy.parent.includes('Subsidaries')){
                //     vm.subsidarySelected(vm.gtwViewContainer.selectedEntityCopy,
                //     vm.gtwViewContainer.selectedEntityCopy.le_type ==='HOO' ? 'HO Only':'All HO');
                // }else{
                // }
                !vm.selectedFormIdent.mergeLabel && (vm.entitiesContainer.expandPdfView = NewProformaFormReviewFactory[vm.store].gtwViewPdfExpandView || true);
            }
            vm.usePdfFromCache = useCache;
            vm.gtwViewContainer.selectedEntity = null;
            vm.selectedForm = '';
            vm.irs_form_name = null;

            vm.getEntities(false, useCache);
            useCache && vm.gtwViewContainer.selectedEntityCopy !== null &&
            vm.entitySelected(vm.gtwViewContainer.selectedEntityCopy, false,
                (vm.gtwViewContainer.selectedEntityCopy.path.includes('/ Subsidiaries') && vm.filter.location === 'D') ? true : false
                , vm.gtwViewContainer.selectedEntityCopy.le_type, useCache);
            useCache && !angular.equals(vm.selectedFormIdent, {}) && !vm.selectedFormIdent.mergeLabel && vm.loadPDF(vm.selectedFormIdent);

        }
        //});

        vm.isProdEnv = function () {
            //return window.location.hostname.startsWith('gtw');
            return false;
        };

        vm.expandCollapseAll = function () {
            vm.expandAll = !vm.expandAll;
            if (vm.expandAll) {
                vm.expandAllTxt = 'Expand All';
                if (vm.entitiesContainer && vm.entitiesContainer.treeDataFiltered) {
                    vm.entitiesContainer.treeDataFiltered.forEach((row) => {
                        row.childrenHidden = true;
                        row.lvl !== 0 && (row.hidden = true);
                    });
                }
            } else {
                vm.expandAllTxt = 'Collapse All';
                if (vm.entitiesContainer && vm.entitiesContainer.treeDataFiltered) {
                    vm.entitiesContainer.treeDataFiltered.forEach((row) => {
                        row.childrenHidden = false;
                        row.hidden = false;
                    });
                }
            }
        };

        vm.getRecordsCount = (parentName) => {
            var rowsCount = 0;
            var list = vm.entitiesContainer.treeDataFiltered;
            var indexOfParent = list.findIndex(item => item.name === parentName);
            if (list.length > 0 && !list[0].noDataFlag) {
                for (var i = indexOfParent + 1; i < list.length; i++) {
                    if (list[i].lvl <= list[indexOfParent].lvl) {
                        break;
                    }
                    if (list[i].lvl === list[indexOfParent].lvl + 1) {
                        rowsCount++;
                    }
                }
            }
            return rowsCount;
        };

        // make sequential calls to initialize the screen with default selected entity
        vm.getEntities = function (sequentialCall = false, useCache) {
            vm.gtwViewContainer.recordsCount = 0;
            vm.entitiesContainer.loadingList = vm.pdfsContainer.loadingForms = true;
            vm.entitiesContainer.showBulkExport = vm.pdfsContainer.loadingForms = false;
            vm.pdfsContainer.showSelectAll = vm.pdfsContainer.showMerge = vm.pdfsContainer.showExpand = false;
            vm.entitiesContainer.treeData = vm.entitiesContainer.treeDataFiltered = vm.pdfsContainer.pdfs = [];
            vm.gtwViewContainer.searchTerm = vm.pdfsContainer.formSearchTerm = '';
            NewProformaFormReviewFactory[vm.store].gtwViewFilter.taxYear = vm.efileFiltersSelected.taxYear;
            NewProformaFormReviewFactory[vm.store].gtwViewFilter.scenario = vm.efileFiltersSelected.scenario;
            vm.entitiesContainer.selectedCatLocal = vm.entitiesContainer.selectedLocLocal = vm.entitiesContainer.selectedLETypeLocal
                = vm.entitiesContainer.selectedEntityTypeLocal = vm.entitiesContainer.selectedViewLocal = null;

            const selectedTaxYear = vm.efileFiltersSelected.taxYear.label;
            const selectedScenario = vm.efileFiltersSelected.scenario.scenario;
            // const selectedCategory = vm.filter.category;
            const selectedLocation = vm.filter.location;
            const selectedType = vm.filter.lEType;

            NewProformaFactory.resetStates();

            if ((vm.entitiesContainer.treeData = vm.getEntitiesFromCache()).length === 0 || !useCache) {
                vm.entitiesContainer.noDataMsg = '';
                NewProformaFactory.getGtwView(selectedLocation, selectedType, selectedTaxYear, selectedScenario, vm.state, 'CBCR').then(
                    (response) => {
                        const list = response.data.viewList;
                        // ignore the response if previous filter selection when api call is made
                        // does not match the current selection
                        if (!vm.filterMatch(selectedTaxYear, selectedScenario, selectedLocation, selectedType)) {
                            return;
                        }
                        if (list && list.length > 0) {
                            list.forEach((item) => {
                                item.hidden = false;
                                item.isCached = false;
                                (vm.filter.lEType === 'BSLA') && (item.BSLACached = {});
                                (vm.filter.lEType === 'BSLA') && (vm.gtwViewContainer.filterEntityTypes.forEach((ele) => {
                                    item.BSLACached[ele.name] = false;
                                }));
                            });
                            const cacheParams = selectedLocation + '_' + selectedType + '_' + vm.efileFiltersSelected.taxYear.label + '_'
                                + vm.efileFiltersSelected.scenario.scenario;
                            vm.entitiesContainer.treeData = vm.gtwViewContainer.cache[cacheParams] =
                                NewProformaFormReviewFactory[vm.store].gtwViewCache[cacheParams] = list;
                            vm.entitiesContainer.noDataMsg = 'Please select an entity from the list!';
                            vm.entitiesContainer.showBulkExport = true;
                        } else {
                            vm.entitiesContainer.treeData = [{
                                me_label: 'No Data Available!',
                                noDataFlag: true
                            }];
                        }
                        vm.recordsCount = vm.entitiesContainer.treeData.length - 2;
                        // vm.entitiesContainer.treeData = vm.entitiesContainer.treeData.filter((item) => (vm.getRecordsCount(item.entity, item.parent, item.consol_group_key) > 0 || (item.is_leaf && item.leid != null)));
                        // vm.entitiesContainer.treeData = vm.entitiesContainer.treeData.filter((item) => (vm.getRecordsCount(item.entity, item.parent, item.consol_group_key) > 0 || (item.is_leaf && item.leid != null)));//second time for upper levels
                        // vm.entitiesContainer.treeData = vm.entitiesContainer.treeData.filter((item) => (vm.getRecordsCount(item.entity, item.parent, item.consol_group_key) > 0 || (item.is_leaf && item.leid != null)));//second time for upper levels

                        vm.entitiesContainer.treeDataFiltered = angular.copy(vm.entitiesContainer.treeData);
                        vm.entitiesContainer.loaded = true;
                        vm.entitiesContainer.loadingList = false;
                        workspaceFactory.setCache(vm.activeScreenLocal, vm.entitiesContainer.treeData);
                    },
                    (err) => {
                        vm.recordsCount = 0;
                        vm.entitiesContainer.loaded = true;
                        vm.entitiesContainer.loadingList = false;
                    }
                );
            } else {
                vm.entitiesContainer.loaded = true;
                // vm.entitiesContainer.treeData = vm.entitiesContainer.treeData.filter((item) => (vm.getRecordsCount(item.entity, item.parent, item.consol_group_key) > 0 || (item.is_leaf && item.leid != null)));
                // vm.entitiesContainer.treeData = vm.entitiesContainer.treeData.filter((item) => (vm.getRecordsCount(item.entity, item.parent, item.consol_group_key) > 0 || (item.is_leaf && item.leid != null)));
                // vm.entitiesContainer.treeData = vm.entitiesContainer.treeData.filter((item) => (vm.getRecordsCount(item.entity, item.parent, item.consol_group_key) > 0 || (item.is_leaf && item.leid != null)));

                vm.entitiesContainer.treeDataFiltered = angular.copy(vm.entitiesContainer.treeData);
                vm.recordsCount = vm.entitiesContainer.treeData.length - 2;
                vm.entitiesContainer.loadingList = false;
                vm.entitiesContainer.noDataMsg = 'Please select an entity from the list!';
            }
        };

        vm.filterMatch = function (selectedTaxYear, selectedScenario, selectedLocation, selectedType) {
            return selectedTaxYear === vm.efileFiltersSelected.taxYear.label && selectedScenario === vm.efileFiltersSelected.scenario.scenario &&
                selectedLocation === this.filter.location && selectedType === this.filter.lEType;
        };

        vm.getEntitiesFromCache = function () {
            return vm.gtwViewContainer.cache[vm.filter.location + '_' + vm.filter.lEType + '_'
            + vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario] || [];
        };

        vm.updateEntitiesCache = (entity, isCached = false, type, clearSubsCache) => {
            var cachedEntity = vm.gtwViewContainer.cache[vm.filter.location + '_' + vm.filter.lEType + '_' + vm.efileFiltersSelected.taxYear.label
            + '_' + vm.efileFiltersSelected.scenario.scenario].find(item => item.path === entity.path && item.leid === entity.leid
                && item.cdr_no === entity.cdr_no && item.reporting_period === entity.reporting_period)
            cachedEntity && (cachedEntity.isCached = isCached);
            cachedEntity.path.includes('/ Subsidiaries') && vm.filter.location === 'D' && !vm.state &&
            (cachedEntity.isSubsCached = cachedEntity.isSubsCached || {}, cachedEntity.isSubsCached[type/**clearing */ || entity.le_type /**setting */] = isCached);
            cachedEntity && vm.filter.lEType === 'BSLA' && (cachedEntity.BSLACached[vm.filter.entityType] = isCached);

            entity && (entity.isCached = isCached);
            vm.filter.lEType === 'BSLA' && (entity.BSLACached[vm.filter.entityType] = isCached);

            if (entity.path.includes('/ Subsidiaries') && vm.filter.location === 'D' && !vm.state) {
                entity.isSubsCached = entity.isSubsCached || {}, entity.isSubsCached[type || entity.le_type] = isCached;

                if (!clearSubsCache) {
                    entity.isCached = true;
                    cachedEntity.isCached = true;
                }
            }
        }

        if (vm.getEntitiesFromCache().length > 0) {
            // vm.entitiesContainer.loaded = true;
            vm.loadEntities(true);
        }

        vm.formFilter = (pdf) => {
            if (this.pdfsContainer.formSearchTerm.length > 0) {
                pdf.isDisplay = pdf.checked || pdf.label.toString().toLowerCase().includes(this.pdfsContainer.formSearchTerm.toString().toLowerCase());
                return pdf.isDisplay;
            }
            pdf.isDisplay = true;
            return pdf.isDisplay;
        };

        vm.treeFilter = (item) => {
            // search based on the filtered result
            if (this.gtwViewContainer.searchTerm.length > 0) {
                return item.me_label.toString().concat(item.me_code.toString()).toLowerCase().includes(this.gtwViewContainer.searchTerm.toString().toLowerCase());
            } else {
                return !item.hidden;
            }
        };

        vm.hideNodes = function (node) {
            return !node.hidden;
        };

        vm.filterTreeData = function () {
            let searchLabel;
            if (vm.filter.lEType === 'BSLA')
                searchLabel = 'me_label';
            else
                searchLabel = 'name';

            if (vm.gtwViewContainer.searchTerm.length === 0) {
                vm.entitiesContainer.treeDataFiltered = angular.copy(vm.entitiesContainer.treeData);
                vm.entitiesContainer.treeDataFiltered.forEach(node => clearHiddenFlag(node));
                return;
            }

            treeDataSet.clear();
            parentNodesAhead = vm.entitiesContainer.treeData.filter(item => !item.is_leaf);

            for (let i = 0; i < vm.entitiesContainer.treeData.length; i++) {
                let node = vm.entitiesContainer.treeData[i];
                clearHiddenFlag(node);
                // if (!node.is_leaf) {
                //     parentNodesAhead.push(node);
                // }
                if (node[searchLabel].toLowerCase().includes(vm.gtwViewContainer.searchTerm.toLowerCase())) {
                    findParents(node.path, searchLabel); // add parents
                    treeDataSet.add(node); // add itself
                    if (!node.is_leaf) {
                        i = findChildren(node.lvl, i + 1); // add children and update index
                    }
                }
            }

            vm.entitiesContainer.treeDataFiltered = Array.from(treeDataSet);
        };

        function clearHiddenFlag(node) {
            node.hidden = false;
            node.childrenHidden = false;
        }

        function findParents(path, searchLabel) {
            let nodes = path.split('/');
            if (nodes.length > 2) {
                nodes = nodes.slice(1, -1);
                nodes.forEach(node => {
                    let parentNode = parentNodesAhead.find((item) => item[searchLabel].includes(node.trim()));
                    parentNode !== undefined && (treeDataSet.add(parentNode));
                });
            }
        }

        function findChildren(parentLvl, index) {
            while (index < vm.entitiesContainer.treeData.length) {
                let node = vm.entitiesContainer.treeData[index];
                if (node.lvl > parentLvl) {
                    treeDataSet.add(node);
                    index++;
                } else {
                    break;
                }
            }
            return index;
        }

        vm.toggleGTWView = (item) => {
            vm.toggle(vm.entitiesContainer.treeDataFiltered, item);
        };

        vm.toggle = function (tree, item) {
            if (item.is_leaf === 1)
                return;

            let index = tree.indexOf(item) + 1;
            // Collapse
            if (!item.childrenHidden) {
                item.childrenHidden = true;
                for (let i = index; i < tree.length; i++) {
                    let prev = i - 1;
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else {
                        tree[i].hidden = true;
                        // tree[i].childrenHidden = false;
                    }
                    // collapse folder icon if children are hidden
                    if (tree[prev].lvl < tree[i].lvl) {
                        tree[prev].childrenHidden = true;
                    }
                }
            }
            // Expand
            else {
                item.childrenHidden = false;
                for (let i = index; i < tree.length; i++) {
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else if (tree[i].lvl - 1 === item.lvl) {
                        tree[i].hidden = false;
                    } else {
                        tree[i].hidden = true;
                    }
                }
            }
        };

        vm.treeLoaded = () => {
            this.loading = false;
        };

        vm.isSelected = (item) => {
            return item.name === vm.gtwViewContainer.selectedEntity;
        };

        vm.subsidarySelected = function (item, type) {
            vm.entitiesContainer.selectedSubsidiary = type + item.ein;
            // let itemToSend = angular.copy(item);
            // (type === 'HO Only') && (itemToSend.le_type = 'HOO');

            vm.entitySelected(item, false, true, type);
        }

        function getTaxForms(selectedEntity, sequentialCall, isRefresh = false, isWebSocketRefresh = false) {
            (!vm.pdfsContainer.entityExistsInJobTrackingConfig || isWebSocketRefresh) && (vm.pdfsContainer.loadingForms = true);
            (!vm.pdfsContainer.entityExistsInJobTrackingConfig || isWebSocketRefresh) && (vm.pdfsContainer.pdfs = []);
            vm.pdfsContainer.isRefreshing = (!isRefresh) ? false : true;

            if (vm.selectedForm !== undefined) vm.entitiesContainer.expandPdfView = true;
            vm.entitiesContainer.noDataMsg = vm.selectedForm = vm.irs_form_name = vm.pdfsContainer.formSearchTerm = '';
            vm.selectedFormIdent = {};
            vm.isLoadPdf = false;

            var requestedEntity = {};
            requestedEntity.selectedView = angular.copy(vm.gtwViewContainer.selectedView);
            requestedEntity.cdrNo = angular.copy(vm.gtwViewContainer.cdrNo);
            requestedEntity.rpPeriod = angular.copy(vm.gtwViewContainer.rpPeriod);
            requestedEntity.selectedLETypeLocal = angular.copy(vm.entitiesContainer.selectedLETypeLocal);
            NewProformaFormReviewFactory.getTaxForms(vm.gtwViewContainer.selectedView, vm.gtwViewContainer.rpPeriod
                                            , vm.leType || vm.gtwViewContainer.gtwViewFilter.lEType, vm.gtwViewContainer.cdrNo,/* vm.filter.location,*/ vm.filter.category, vm.entityType || vm.gtwViewContainer.gtwViewFilter.entityType, ''
                                            , vm.efileFiltersSelected.taxYear.label, vm.efileFiltersSelected.scenario.scenario, vm.entityName, vm.gtwViewContainer.gtwViewFilter.contSrc, vm.gtwViewContainer.mef_group_key, 'CBCR')
                                        .then((response) => {
                                                if (response.data.message && response.data.message !== '') {
                                                    throw new Error(response.data.message);
                                                }
                                                if (vm.entitiesContainer.selectedCatLocal !== vm.filter.category || vm.entitiesContainer.selectedLocLocal !== vm.filter.location ||
                                                    vm.entitiesContainer.selectedLETypeLocal !== vm.leType || vm.entitiesContainer.selectedEntityTypeLocal !== vm.entityType
                                                    || vm.entitiesContainer.selectedViewLocal !== vm.gtwViewContainer.selectedView)
                                                    return; // ignore the response
                                                if (requestedEntity.selectedView === vm.gtwViewContainer.selectedView && requestedEntity.selectedLETypeLocal === vm.entitiesContainer.selectedLETypeLocal
                                                    && requestedEntity.rpPeriod === vm.gtwViewContainer.rpPeriod && requestedEntity.cdrNo === vm.gtwViewContainer.cdrNo) { //checking entity to avoid cross entity pdf data population
                                                    selectedEntity && (vm.updateEntitiesCache(selectedEntity, true));
                                                    handleForms(response, sequentialCall, vm.entitiesContainer.selectedCatLocal,
                                                        vm.entitiesContainer.selectedLocLocal, vm.entitiesContainer.selectedLETypeLocal,
                                                        vm.entitiesContainer.selectedEntityTypeLocal, vm.entitiesContainer.selectedViewLocal, isRefresh);
                                                }
                                            }
                                        ).catch((err) => {
                if (vm.entitiesContainer.selectedCatLocal !== vm.filter.category || vm.entitiesContainer.selectedLocLocal !== vm.filter.location ||
                    vm.entitiesContainer.selectedLETypeLocal !== vm.leType || vm.entitiesContainer.selectedEntityTypeLocal !== vm.entityType
                    || vm.entitiesContainer.selectedViewLocal !== vm.gtwViewContainer.selectedView)
                    return; // ignore the response
                vm.pdfsContainer.pdfs = [];
                vm.entitiesContainer.noDataMsg = 'Something went wrong, please contact support!';
            }).finally(() => {
                if (requestedEntity.selectedView === vm.gtwViewContainer.selectedView && requestedEntity.selectedLETypeLocal === vm.entitiesContainer.selectedLETypeLocal
                    && requestedEntity.rpPeriod === vm.gtwViewContainer.rpPeriod && requestedEntity.cdrNo === vm.gtwViewContainer.cdrNo) {
                    vm.pdfsContainer.loadingForms = false;
                }
            });
        }

        function getFormsFromCache(selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView) {
            const entityCacheParams = vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario + '_' + selectedCat
                + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView + '_' + vm.gtwViewContainer.cdrNo
                + '_' + vm.gtwViewContainer.rpPeriod + '_' + vm.gtwViewContainer.mef_group_key;
            if (vm.pdfsContainer.formsPerEntity[entityCacheParams] && (Array.isArray(vm.pdfsContainer.formsPerEntity[entityCacheParams].pdfs)
                && !vm.pdfsContainer.formsPerEntity[entityCacheParams].pdfs.length)) {
                return false;
            } else if (vm.pdfsContainer.formsPerEntity[entityCacheParams]) {
                vm.entitiesContainer.noDataMsg = '';
                vm.jobId = vm.pdfsContainer.formsPerEntity[entityCacheParams].jobId || '';
                vm.pdfsContainer.pdfs = vm.pdfsContainer.formsPerEntity[entityCacheParams].pdfs || [];
                unCheckPdfs();
                vm.pdfsContainer.loadingForms = false;
                vm.pdfsContainer.entityExistsInJobTrackingConfig = vm.pdfsContainer.formsPerEntity[entityCacheParams].entityExistsInJobTrackingConfig;
                vm.pdfsContainer.refreshTimeStamp = vm.pdfsContainer.formsPerEntity[entityCacheParams].refreshTimeStamp;
                vm.pdfsContainer.isRefresh = vm.pdfsContainer.formsPerEntity[entityCacheParams].isRefresh;
                vm.pdfsContainer.isRefreshing = vm.pdfsContainer.formsPerEntity[entityCacheParams].isRefreshing;
                vm.pdfsContainer.refreshTimeStampMessage = vm.pdfsContainer.formsPerEntity[entityCacheParams].refreshTimeStampMessage;
                vm.pdfsContainer.showSelectAll = vm.pdfsContainer.showMerge = true;
                return true;
            } else {
                return false;
            }
        }

        function handleForms(formsResponse, sequentialCall, selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView, jobId, isRefresh = false) {
            vm.pdfsContainer.loadingForms = vm.pdfsContainer.entityExistsInJobTrackingConfig = false;
            vm.entitiesContainer.showBulkExport = true;
            vm.pdfsContainer.refreshTimeStampMessage = null;

            if (formsResponse.data.entityExistsInJobTrackingConfig !== "0") {
                // vm.pdfsContainer.enableRefresh = true;
                vm.pdfsContainer.entityExistsInJobTrackingConfig = true;
            }

            if (formsResponse.data.lastRefreshedTS !== null) {
                vm.pdfsContainer.refreshTimeStamp = formsResponse.data.lastRefreshedTS;
                vm.pdfsContainer.refreshTimeStampMessage = "Last refreshed at " + $filter('date')(formsResponse.data.lastRefreshedTS, "dd MMM yyyy '@' h:mm a");
            } else
                vm.pdfsContainer.refreshTimeStamp = null;

            const entityCacheParams = vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario + '_' + selectedCat
                + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView + '_' + vm.gtwViewContainer.cdrNo
                + '_' + vm.gtwViewContainer.rpPeriod + '_' + vm.gtwViewContainer.mef_group_key;
            if (formsResponse.data.taxForms && formsResponse.data.taxForms.length > 0) {
                vm.pdfsContainer.showSelectAll = vm.pdfsContainer.showMerge = true;
                vm.pdfsContainer.pdfs = [];
                var formTypes = JSON.parse(formsResponse.data.taxForms[0].jsonString);
                formTypes.forEach((formType) => {
                    const typeName = Object.keys(formType)[0];
                    const subTypes = formType[typeName];
                    subTypes.forEach((subType) => {
                        const forms = subType['attachment_form'];
                        forms.forEach((form) => {
                            form.checked = false;
                            form.label = form.irs_form && form.bucket_sub !== '5471' ? form.irs_form + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form_name : (form.form_name.toLowerCase().includes('schedule') ? form.form_name.replace('Schedule', '-') : form.form_name);
                            form.label = form.label.toLowerCase().startsWith('f') ? form.label.substring(1) : (form.label.toLowerCase().startsWith('irs') ? form.label.substring(3) : form.label);
                            form.label = form.subform && form.form_sub_cat === 'DIFF_PDF' ? form.label + '-' + form.subform : form.label;
                            form.le_name = vm.leName;
                            form.ein = vm.leEin;
                            form.form_sub_cat === 'SAME_PDF' && (form.subform = null);
                            vm.pdfsContainer.pdfs.push(form);
                        })
                    });
                });
                // sort forms based on form_order field
                vm.pdfsContainer.pdfs = _.chain(vm.pdfsContainer.pdfs).uniqBy('label').sortBy('form_order').value();
                !vm.pdfsContainer.entityExistsInJobTrackingConfig && (vm.pdfsContainer.isRefreshing = false);

                // getting job id on the forms
                jobId = vm.pdfsContainer.pdfs[0].job_id || jobId;

                // set forms cache
                vm.pdfsContainer.formsPerEntity[entityCacheParams] = {};
                vm.pdfsContainer.formsPerEntity[entityCacheParams].pdfs = angular.copy(vm.pdfsContainer.pdfs);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].jobId = angular.copy(jobId);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].entityExistsInJobTrackingConfig = angular.copy(vm.pdfsContainer.entityExistsInJobTrackingConfig);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].isRefresh = angular.copy(vm.pdfsContainer.isRefresh);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].isRefreshing = angular.copy(vm.pdfsContainer.isRefreshing);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].refreshTimeStamp = angular.copy(vm.pdfsContainer.refreshTimeStamp);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].refreshTimeStampMessage = angular.copy(vm.pdfsContainer.refreshTimeStampMessage);

                // make sequential call
                if (sequentialCall) {
                    vm.loadPDF(vm.pdfsContainer.pdfs[0]);
                }
                unCheckPdfs();
            } else {
                vm.pdfsContainer.pdfs = [];
                !vm.pdfsContainer.entityExistsInJobTrackingConfig && (vm.pdfsContainer.isRefreshing = false);

                // set forms cache
                vm.pdfsContainer.formsPerEntity[entityCacheParams] = {};
                vm.pdfsContainer.formsPerEntity[entityCacheParams].pdfs = angular.copy(vm.pdfsContainer.pdfs);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].jobId = null;
                vm.pdfsContainer.formsPerEntity[entityCacheParams].entityExistsInJobTrackingConfig = angular.copy(vm.pdfsContainer.entityExistsInJobTrackingConfig);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].isRefresh = angular.copy(vm.pdfsContainer.isRefresh);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].isRefreshing = angular.copy(vm.pdfsContainer.isRefreshing);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].refreshTimeStamp = angular.copy(vm.pdfsContainer.refreshTimeStamp);
                vm.pdfsContainer.formsPerEntity[entityCacheParams].refreshTimeStampMessage = angular.copy(vm.pdfsContainer.refreshTimeStampMessage);

                if (formsResponse.data.taxForms !== null)
                    vm.entitiesContainer.noDataMsg = 'No Forms Available!';
            }
        }

        $scope.$on('$destroy', function () {
            NewProformaFormReviewFactory[vm.store].gtwViewFormsCache = angular.copy(vm.pdfsContainer.formsPerEntity);
            NewProformaFormReviewFactory[vm.store].gtwViewSelectedEntity = angular.copy(vm.gtwViewContainer.selectedEntityCopy);
            NewProformaFormReviewFactory[vm.store].gtwViewSelectedForm = angular.copy(vm.selectedFormIdent);
            NewProformaFormReviewFactory[vm.store].gtwViewPdfExpandView = angular.copy(vm.entitiesContainer.expandPdfView);
        })

        function getJobId(selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView) {
            if (vm.pdfsContainer.formsPerEntity[vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView + '_' + vm.gtwViewContainer.cdrNo + '_' + vm.gtwViewContainer.rpPeriod + '_' + vm.gtwViewContainer.mef_group_key])
                return vm.pdfsContainer.formsPerEntity[vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView + '_' + vm.gtwViewContainer.cdrNo + '_' + vm.gtwViewContainer.rpPeriod + '_' + vm.gtwViewContainer.mef_group_key].jobId || '';
            else return '';
        }

        function unCheckPdfs() {
            if (vm.pdfsContainer.pdfs && vm.pdfsContainer.pdfs.length > 0) {
                vm.pdfsContainer.pdfs.forEach((pdf) => {
                    pdf.checked = false;
                });
            }
        }

        // function showEntityDiagModal(diagList) {
        //     if (diagList.length === 0) return;
        //     $uibModal.open({
        //         animation: true,
        //         templateUrl: 'app/components/newProforma/entity-diagnostics-modal.html',
        //         windowClass: 'entity-diag-modal',
        //         controller: ['$scope', '$uibModalInstance', 'workspaceFactory', function ($scope, $uibModalInstance, workspaceFactory) {
        //             $scope.dtInstance = {};
        //             $scope.entityDiagTable = {};
        //             // configure datatable settings
        //             $scope.entityDiagTable.options = {
        //                 data: diagList,
        //                 extraOptions: {
        //                     componentSettings: {
        //                         rowGrouping: true,
        //                         rowGroupingBy: 'group_desc',
        //                         tableOptions: {
        //                             columns: [
        //                                 // {title: 'Diagnostic Key', data: 'diag_key', width: 70, orderable: false, className: 'data-column'},
        //                                 // {title: 'Diagnostic Code', data: 'diag_name', width: 70, orderable: false, className: 'data-column'},
        //                                 {
        //                                     title: 'Group Description',
        //                                     data: 'group_desc',
        //                                     width: 200,
        //                                     orderable: false,
        //                                     className: 'data-column',
        //                                     rowGrouping: true,
        //                                     visible: false
        //                                 },
        //                                 {title: 'Diagnostic Description', data: 'diag_desc', width: 200, orderable: false, className: 'data-column'},
        //                                 {title: 'Resolved Count', data: 'recolved_count', width: 200, orderable: false, className: 'data-column'},
        //                                 {title: 'Unresolved Count', data: 'unresolved_count', width: 200, orderable: false, className: 'data-column text-red'},
        //                                 // {
        //                                 //     title: 'Diagnostic Status',
        //                                 //     data: 'diag_status',
        //                                 //     width: 70,
        //                                 //     orderable: false,
        //                                 //     className: 'data-column text-center',
        //                                 //     render: function (data, type) {
        //                                 //         if (type !== 'display') return data;
        //                                 //         let color = '';
        //                                 //         if (data === '0') {
        //                                 //             color = 'style="color: red;"';
        //                                 //             return `<span ${color}>FAILED</span>`;
        //                                 //         } else {
        //                                 //             color = 'style="color: #008700;"';
        //                                 //             return `<span ${color}>PASSED</span>`;
        //                                 //         }
        //                                 //     }
        //                                 // },
        //                                 // {
        //                                 //     title: 'Count',
        //                                 //     data: null,
        //                                 //     width: 50,
        //                                 //     orderable: false,
        //                                 //     className: 'data-column text-center',
        //                                 //     render: function (data, type) {
        //                                 //         if (type === 'display') {
        //                                 //             let numLink = Math.floor(Math.random() * 10) + 1;
        //                                 //             return `<a href="#">${numLink}</a>`;
        //                                 //         }
        //                                 //     }
        //                                 // },
        //                             ],
        //                             paging: false
        //                         }
        //                     }
        //                 }
        //             };
        //
        //             $scope.goToSubDashboard = function () {
        //                 workspaceFactory.loadLink('Grid Standard', '', '1024', '10246', {}, {}, {}, '30227');
        //                 $uibModalInstance.close();
        //             };
        //         }]
        //     })
        // }

        vm.selectForm = function () {
            vm.pdfsContainer.enableMerge = vm.getSelectedPDFs().length > 1;
        };

        vm.selectAllPDFs = () => {
            vm.pdfsContainer.pdfs.forEach((pdf) => {
                if (pdf.isDisplay) {
                    pdf.checked = !vm.isSelectAll;
                }
                pdf.checked = !vm.isSelectAll;
            });
            vm.isSelectAll = !vm.isSelectAll;
            vm.pdfsContainer.enableMerge = vm.isSelectAll;
        };

        vm.getSelectedPDFs = () => {
            let selectedPDFs = [];
            vm.pdfsContainer.pdfs.forEach((pdf) => {
                if (pdf.checked) {
                    selectedPDFs.push({'form_name': pdf.form_name, 'subform': pdf.subform && pdf.form_sub_cat === "DIFF_PDF" ? '-' + pdf.subform : ''});
                }
            });
            return selectedPDFs;
        };

        vm.loadPDFs = (irsFormNoList, irsFormNoListIdent, s3UrlList) => {
            vm.pdfsContainer.showExpand = vm.isMerge = true;
            vm.entitiesContainer.expandPdfView = false;
            vm.s3UrlList = s3UrlList;
            vm.taxYear = vm.efileFiltersSelected.taxYear.label;
            vm.scenarioLocal = vm.efileFiltersSelected.scenario.scenario;
            vm.selectedForm = irsFormNoList;
            if (vm.selectedFormIdent === '') {
                vm.selectedFormIdent = {};
            }
            vm.selectedFormIdent.mergeLabel = irsFormNoListIdent;
            vm.le_name = vm.leName;
            vm.le_ein = vm.leEin;
            vm.le_type = vm.leType;
            vm.jobId = getJobId(vm.filter.category, vm.filter.location, vm.leType, vm.entityType, vm.gtwViewContainer.selectedView);
            vm.irs_form_name = vm.pdfsContainer.pdfs.filter(i => irsFormNoList.includes(i.form_name)).map(({irs_form}) => {
                return irs_form
            });
            vm.schedule = vm.pdfsContainer.pdfs.filter(i => irsFormNoList.includes(i.form_name)).map(({schedule}) => {
                return schedule
            });
            vm.line = vm.pdfsContainer.pdfs.filter(i => irsFormNoList.includes(i.form_name)).map(({line_no}) => {
                return line_no
            });
            vm.partNo = vm.pdfsContainer.pdfs.filter(i => irsFormNoList.includes(i.form_name)).map(({part_no}) => {
                return part_no
            });
            vm.form_sub = vm.pdfsContainer.pdfs.filter(i => irsFormNoList.includes(i.form_name)).map(({subform}) => {
                return subform
            });
            vm.form_sub_cat = vm.pdfsContainer.pdfs.filter(i => irsFormNoList.includes(i.form_name)).map(({form_sub_cat}) => {
                return form_sub_cat
            });
            vm.isLoadPdf = true;
        };

        vm.getSelectedForms = () => {
            return vm.selectedForm ? vm.selectedForm.toString() : '';
        };

        vm.merge = () => {
            const formNames = vm.getSelectedPDFs();
            const mergePopupRef = $uibModal.open({
                animation: true,
                template: `
                <div class="modal-header">
                  <div class="modal-title">Merge PDFs
                    <i ng-click="uibModalInstance.close()" class="fa fa-times pull-right"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div style="font-weight: bolder;">
                    The following forms will be merged into one PDF, are you sure you want to continue?
                  </div>
                  <div style="padding: 20px 20px;">
                    <span ng-if="isLoading">Please wait while we retrieve the data...</span>
                    <span ng-if="hasLoadErr" style="color: red;">Data not available, please try again!</span>
                    <div ng-if="!isLoading" ng-repeat="formName in formNames" style="margin-bottom: 5px;">
                      <span style="margin-right: 5px;">{{formName.form_name + formName.subform}}:</span>
                      <span><i ng-class="formName.exist ? 'fa-check' : 'fa-times'" class="fa" ng-style="{color: formName.exist ? 'green' : 'red'}"></i></span>
                    </div>
                  </div>
                  <div ng-if="hasInvalidForms">Note: forms marked with x do not exist, and will be excluded from merge.</div>
                </div>
                <div class="modal-footer">
                  <span ng-if="!isLoading && !isMergeValid" style="color: red; margin-right: 10px;">
                    Selection contains less than two valid forms!
                  </span>
                  <button class="btn btn-info btn-sm" data-ng-disabled="isLoading || !isMergeValid" ng-click="merge()">
                    Merge
                  </button>
                  <button class="btn btn-warning btn-sm" ng-click="uibModalInstance.close()">Cancel</button>
                </div>
                `,
                controller: ['$scope', '$uibModalInstance', 'formNames', 'WorkflowService', function ($scope, $uibModalInstance, formNames, WorkflowService) {
                    $scope.isLoading = true;
                    $scope.formNames = formNames;
                    $scope.uibModalInstance = $uibModalInstance;
                    var formNoList = formNames.map(formName => formName.form_name);

                    WorkflowService.getPdfpFormS3UrlList(formNoList,'CBCR').then((response) => {
                        if (response && response.callSuccess && response.result) {
                            $scope.s3List = response.result.filter(s3 => s3 !== null);
                            $scope.s3List.forEach((s3Url) => {
                                let existingFormList = formNames.filter((formName) => {
                                    return s3Url && formName.form_name === s3Url.irs_form_no;
                                });
                                if (existingFormList) {
                                    existingFormList.forEach((existingForm) => {
                                        existingForm.exist = true;
                                    });
                                }
                            });
                            $scope.hasInvalidForms = formNames.length > $scope.s3List.length;
                            $scope.isMergeValid = $scope.s3List.length >= 2;
                            $scope.isLoading = false;
                        } else {
                            $scope.isLoading = false;
                            $scope.hasLoadErr = true;
                        }
                    });

                    $scope.merge = function () {
                        $uibModalInstance.close($scope.s3List);
                    };
                }],
                resolve: {
                    formNames: () => formNames
                }
            });

            mergePopupRef.result.then((s3List) => {
                if (s3List) {
                    s3List = s3List.filter(s3 => s3 !== null);
                    const irsFormNoList = s3List.map(({irs_form_no}) => {
                        return irs_form_no;
                    });
                    const irsFormNoListIdent = s3List.map(({irs_form_no, subform}) => {
                        return irs_form_no + (subform && form_sub_cat === "DIFF_PDF" ? '-' + subform : '');
                    });
                    const s3UrlList = s3List.map(({s3_url}) => {
                        return s3_url;
                    });
                    vm.loadPDFs(irsFormNoList, irsFormNoListIdent, s3UrlList);
                }
            })
        };

        vm.export = function () {
            $uibModal.open({
                animation: true,
                windowClass: 'pdf-bulk-export-modal',
                templateUrl: 'app/components/newProforma/pdf-bulk-export-modal.html',
                controller: ['$scope', 'NewProformaFormReviewFactory', 'AlertService', 'USER_SETTINGS', '$uibModalInstance',
                    function ($scope, NewProformaFormReviewFactory, AlertService, USER_SETTINGS, $uibModalInstance) {
                        let treeDataSet = new Set();
                        let parentNodesAhead = [];
                        $scope.clientName = USER_SETTINGS.user.client.client_name;
                        if (vm.filter.lEType === 'BSLA') {
                            $scope.isBSLAView = true;
                            $scope.entities = angular.copy(vm.treeData);
                        } else {
                            $scope.isBSLAView = false;
                            $scope.entities = angular.copy(vm.entitiesContainer.treeData);
                        }
                        $scope.entitiesFiltered = $scope.entities;
                        $scope.selectedFilterType = 'C';
                        $scope.zippedExport = true;
                        $scope.exportDisable = true;
                        $scope.noFormsMsg = '';

                        $scope.toggleBSLAView = function (view, $event) {
                            vm.toggle($scope.entitiesFiltered, view);
                            $event.preventDefault();
                        };

                        $scope.toggleForm = function (form, $event) {
                            vm.toggle($scope.formsFiltered, form);
                            $event.preventDefault();
                        };

                        if (!NewProformaFactory.modalFormsCache) {
                            $scope.formLoading = true;
                            NewProformaFactory.getBulkExportForms(
                                vm.efileFiltersSelected.taxYear.label,
                                vm.filter.category === 'P' ? '1065' : '1120',
                                'Dom',
                                'CBCR'
                            ).then((response) => {
                                if (response.data.callSuccess === '1') {
                                    $scope.forms = NewProformaFactory.modalFormsCache = response.data.viewList;
                                    $scope.forms.forEach((form) => {
                                        form.label = form.irs_form_no && form.bucket_sub !== '5471' ? form.irs_form_no + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form
                                                                                                    : (form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form);
                                        // form.label = form.label.toLowerCase().startsWith('f') ? form.label.substring(1) : (form.label.toLowerCase().startsWith('irs') ? form.label.substring(3) : form.label);
                                        form.label = form.form_sub && form.form_sub_cat === 'DIFF_PDF' ? form.label + '-' + form.form_sub : form.label;

                                        form.hidden = form.lvl > 1;
                                        form.childrenHidden = form.lvl === 1;
                                        if (form.path.includes('Corporate Domestic')) {
                                            form.type = 'D';
                                        } else if (form.path.includes('Corporate Foreign')) {
                                            form.type = 'F';
                                        }
                                    });
                                    $scope.formsFiltered = $scope.forms;
                                }
                            }).catch(() => {
                                $scope.noFormsMsg = 'Forms unavailable.';
                            }).finally(() => {
                                $scope.formLoading = false;
                            });
                        } else {
                            // reset form states
                            NewProformaFactory.modalFormsCache.forEach((form) => {
                                form.childrenHidden = form.lvl >= 1;
                                form.hidden = form.lvl >= 2;
                                form.selected = false;
                            });
                            $scope.formsFiltered = $scope.forms = NewProformaFactory.modalFormsCache || [];
                        }

                        $scope.searchEntity = function () {
                            if ($scope.entitySearchTerm.length === 0) {
                                $scope.entitiesFiltered = $scope.entities;
                                return;
                            }
                            // if ($scope.isBSLAView)
                            filterTreeData($scope.entities, $scope.entitySearchTerm, 'entity');
                            // else
                            //     filterFlatData();
                        };

                        $scope.searchForm = function () {
                            if ($scope.formSearchTerm.length === 0) {
                                $scope.formsFiltered = $scope.forms;
                                return;
                            }
                            filterTreeData($scope.forms, $scope.formSearchTerm, 'form');
                        };

                        // function filterFlatData() {
                        //     $scope.entitiesFiltered = $scope.entitiesFiltered.filter((item) => {
                        //         return item.name.toString().toLowerCase().includes($scope.entitySearchTerm.toString().toLowerCase());
                        //     });
                        // }

                        function filterTreeData(treeData, searchTerm, type) {
                            const labelKey = type === 'entity' ? 'me_label' : 'form';
                            const labelKey2 = type === 'entity' ? 'me_code' : '';
                            treeDataSet.clear();
                            ``
                            parentNodesAhead = [];
                            for (let i = 0; i < treeData.length; i++) {
                                let node = treeData[i];
                                if (!node.is_leaf) {
                                    parentNodesAhead.push(node);
                                }
                                if (node[labelKey].toLowerCase().includes(searchTerm.toLowerCase()) || (type === 'entity' && node[labelKey2].toLowerCase().includes(searchTerm.toLowerCase()))) {
                                    findParents(node.path, labelKey); // add parents
                                    node.hidden = false; // reveal the item after search
                                    treeDataSet.add(node); // add itself
                                    if (!node.is_leaf) {
                                        i = findChildren(treeData, node.lvl, i + 1); // add children and update index
                                    }
                                }
                            }

                            if (type === 'entity') {
                                $scope.entitiesFiltered = Array.from(treeDataSet);
                            } else {
                                $scope.formsFiltered = Array.from(treeDataSet);
                            }
                        }

                        function findParents(path, labelKey) {
                            let nodes = path.split('/');
                            if (nodes.length > 2) {
                                nodes = nodes.slice(1, -1);
                                nodes.forEach(node => {
                                    let parentNode = parentNodesAhead.find((parent) => parent[labelKey] === node.trim());
                                    parentNode.childrenHidden = false;
                                    treeDataSet.add(parentNode);
                                });
                            }
                        }

                        function findChildren(treeData, parentLvl, index) {
                            while (index < treeData.length) {
                                let node = treeData[index];
                                if (node.lvl > parentLvl) {
                                    node.hidden = false;
                                    treeDataSet.add(node);
                                    index++;
                                } else {
                                    break;
                                }
                            }
                            return index;
                        }

                        $scope.selectEntity = function (item, $event) {
                            item.selected = !item.selected;
                            if ($scope.isBSLAView && !item.is_leaf) {
                                const index = $scope.entitiesFiltered.indexOf(item);
                                for (let i = index + 1; i < $scope.entitiesFiltered.length; i++) {
                                    if ($scope.entitiesFiltered[i].lvl <= item.lvl) {
                                        break;
                                    }
                                    $scope.entitiesFiltered[i].selected = item.selected;
                                }
                            }
                            enableDisableExport();
                            $event.preventDefault();
                        };

                        $scope.selectForm = function (item, $event) {
                            item.selected = !item.selected;

                            if (!item.is_leaf) {
                                const index = $scope.formsFiltered.indexOf(item);
                                for (let i = index + 1; i < $scope.formsFiltered.length; i++) {
                                    if ($scope.formsFiltered[i].lvl <= item.lvl) {
                                        break;
                                    }
                                    $scope.formsFiltered[i].selected = item.selected;
                                }
                            }

                            if (item.selected) {
                                if (item.type === 'D') {
                                    $scope.domFormSelected = true;
                                } else {
                                    $scope.forFormSelected = true;
                                }
                                if ($scope.domFormSelected && $scope.forFormSelected) {
                                    $scope.zippedExport = true;
                                }
                            } else {
                                checkUnselectedForms(item.type);
                            }
                            enableDisableExport();

                            $event.preventDefault();
                        };

                        function enableDisableExport() {
                            if ($scope.entities.length === 0 || $scope.forms.length === 0)
                                return;
                            const selectedEntities = $scope.entities.filter(item => item.selected && item.is_leaf);
                            const selectedForms = $scope.forms.filter(item => item.selected && item.is_leaf);
                            if (selectedEntities.length == 0 || selectedForms.length == 0)
                                $scope.exportDisable = true;
                            else
                                $scope.exportDisable = false;
                        }

                        function checkUnselectedForms(type) {
                            const hasCheckedForms = $scope.forms.some((form) => {
                                return form.type === type && form.selected;
                            })
                            if (hasCheckedForms) {
                                return;
                            } else if (type === 'D') {
                                $scope.domFormSelected = false;
                            } else if (type === 'F') {
                                $scope.forFormSelected = false;
                            }
                        }

                        $scope.export = function () {
                            $scope.exportLoading = true;
                            const selectedEntities = $scope.entities.filter(item => item.selected && item.is_leaf);
                            const selectedForms = $scope.forms.filter(item => item.selected && item.is_leaf);

                            let entities = [];
                            let forms = [];

                            selectedEntities.forEach((entity) => {
                                let entityObj = {};
                                entityObj.leid = entity.leid;
                                entityObj.cdr_no = entity.cdr_no;
                                entityObj.reporting_period = entity.reporting_period;
                                entityObj.system_flag = entity.SOURCE_SYSTEM_FLAG;
                                entityObj.le_type = vm.filter.lEType;
                                entityObj.le_name = entity.le_name;
                                entityObj.ein = entity.ein;
                                entityObj.entity_type = entity.entity_type;
                                entityObj.cd_key = '1';
                                entityObj.path = `/${vm.filterCatMapping[vm.filter.category]}/${vm.filterLocMapping[vm.filter.location]}/${vm.filter.lEType}/${entity.leid}`;
                                // entityObj.path = `/${vm.filterCatMapping[vm.filter.category]}/${vm.gtwViewContainer.filterLocations.find(loc => loc.value == vm.filter.location).name}/${vm.filter.lEType}/${entity.leid}`;
                                entities.push(entityObj);
                            });

                            selectedForms.forEach((form) => {
                                let formObj = {};
                                formObj.irs_form = form.form;
                                formObj.form_sub = form.form_sub ? form.form_sub.split('_')[0] : ''; // removes reporting period
                                formObj.form_sub_cat = form.form_sub_cat;
                                formObj.wpirs_form_no = form.is_whitepaper ? (form.irs_form_no.toLowerCase().startsWith('f') ? form.irs_form_no.substring(1)
                                                                                                                             : (form.irs_form_no.toLowerCase().startsWith('irs') ? form.irs_form_no.substring(3) : form.irs_form_no)) : ''; //removing F and IRS from formName
                                formObj.schedule = form.schedule;
                                formObj.line = form.line;
                                formObj.part_no = form.part_no;
                                forms.push(formObj);
                            });

                            let payload = {
                                entities: entities,
                                forms: forms
                            }

                            NewProformaFactory.pdfsBulkExport(
                                vm.efileFiltersSelected.taxYear.label,
                                vm.efileFiltersSelected.scenario.scenario,
                                '',//parentLeId
                                $scope.zippedExport ? 'Zip' : 'Merged',
                                'Dom',
                                vm.filter.category === 'C' ? '1120' : '1065',
                                vm.filter.category,
                                '250',
                                payload,
                                'CBCR'
                            ).then((response) => {
                                                  AlertService.add('success', 'Your Bulk Export Request is submitted!', 4000);
                                                  console.log(response);
                                              })
                                              .catch((err) => {
                                                  AlertService.add('warning', 'Something went wrong while sending Bulk Request!');
                                                  console.log(err.toString());
                                              })
                                              .finally(() => {
                                                  $scope.exportLoading = false;
                                                  $uibModalInstance.close();
                                              });
                        };
                    }]
            });
        };

        function establishWebSocketConnection() {
            // establish pdf engine websocket connection
            MessageService.establishConnection(GENERAL_CONFIG.pdf_engine_api_url + '/pdf-engine-websockets', (client) => {
                MessageService.subscribeToChannelWithCallback(GENERAL_CONFIG.pdf_engine_api_url + '/pdf-engine-websockets', '/notify/gtw-view/' + USER_SETTINGS.user.sso_id, function (payload) {
                    $rootScope.$broadcast("form-review:websocket-message", payload);
                },client);
            });
        }

        NewProformaFormReviewFactory[vm.store].listener && NewProformaFormReviewFactory[vm.store].listener();
        NewProformaFormReviewFactory[vm.store].listener = $rootScope.$on('form-review:websocket-message', function (ev, payload) {
            handleMessage(payload);
        })

        function handleMessage(payload) {
            var notification = JSON.parse(payload.body);
            console.log("notification payload", notification);
            console.log("isRefresh:", vm.pdfsContainer.isRefresh);


            if ((vm.pdfsContainer.entityExistsInJobTrackingConfig && !vm.pdfsContainer.refreshTimeStamp
                    && !vm.pdfsContainer.loadingForms) || //first time run
                (vm.pdfsContainer.formsPerEntity[notification.cacheParams] && vm.pdfsContainer.formsPerEntity[notification.cacheParams].isRefresh)) {
                AlertService.add(notification.status, notification.message, 4000);
                console.log("after alert and apply");

                vm.pdfsContainer.formsPerEntity[notification.cacheParams] && (vm.pdfsContainer.formsPerEntity[notification.cacheParams].isRefreshing = false);
                vm.pdfsContainer.formsPerEntity[notification.cacheParams] && (vm.pdfsContainer.formsPerEntity[notification.cacheParams].isRefresh = false);

                if (notification.taxYear === vm.efileFiltersSelected.taxYear.label && notification.entity === vm.gtwViewContainer.selectedView
                    && notification.leType === vm.leType && ($state.current.name === "workspace.gtw-view" || $state.current.name === 'workspace.state-group-forms')
                    && (notification.status === "success")) {
                    console.log('set refresh to false and recalling getTaxForms');
                    getTaxForms(null, false, true, true);
                    vm.pdfsContainer.isRefresh = false;
                    vm.pdfsContainer.isRefreshing = false;
                } else {
                    console.log('cleared cache for ' + notification.cacheParams);
                    vm.pdfsContainer.formsPerEntity[notification.cacheParams] = null;
                }

                console.log("updated refresh flags for " + vm.efileFiltersSelected.taxYear.label + '_' + vm.efileFiltersSelected.scenario.scenario + '_' + vm.entitiesContainer.selectedCatLocal + '_' + vm.entitiesContainer.selectedLocLocal + '_' + vm.entitiesContainer.selectedLETypeLocal + '_' + vm.entitiesContainer.selectedEntityTypeLocal + '_' + vm.entitiesContainer.selectedViewLocal);

                $rootScope.$apply();

                NewProformaFormReviewFactory[vm.store].gtwViewFormsCache = angular.copy(vm.pdfsContainer.formsPerEntity);
            }
        }

        if (!window.location.origin.includes("localhost")) {
            establishWebSocketConnection();
        }
    }

    return controllers;
});
