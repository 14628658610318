define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.issuesService', [])
                          .factory("IssuesFactory", ['$http', 'GENERAL_CONFIG',
                              function ($http, GENERAL_CONFIG) {
                                  const issuesFactory = {};

                                  issuesFactory.getScenarios = function (taxYear) {
                                      return $http.get(GENERAL_CONFIG.base_url + '/getScenarios?tax_year=' + taxYear + '&jcd_key=250');
                                  };
                                  
                                    issuesFactory.getAllIssues = function () {
                                    return $http.get(GENERAL_CONFIG.base_url + '/getAllIssues');
                                };

                                  issuesFactory.getIssueDetails = function (issueId) {
                                    return $http.get(GENERAL_CONFIG.base_url + '/getIssueData/' + issueId);
                                  };

                                  issuesFactory.getTYScenarios = function () {
                                    return $http.get(GENERAL_CONFIG.base_url + '/getTYScenarios');
                                };

                                  issuesFactory.getStdDesc = function () {
                                      return $http.get(GENERAL_CONFIG.base_url + '/getStdDesc');
                                  };

                                  issuesFactory.createIssue = function (issueDesc, issueShortDesc, stdDescId, taxYear, assignmentType) {
                                      const promise = $http.post(GENERAL_CONFIG.base_url + '/createIssue', {
                                          issue_desc: encodeURIComponent(issueDesc),
                                          issue_short_desc: encodeURIComponent(issueShortDesc),
                                          std_desc_id: stdDescId,
                                          tax_year: taxYear,
                                          scenario: assignmentType
                                      });

                                      return promise;
                                  };

                                  issuesFactory.saveIssue = function (issueParams) {
                                    const promise = $http.post(GENERAL_CONFIG.base_url + '/saveIssue', issueParams);
                                    return promise;
                                };

                                  issuesFactory.updateIssue = function (issueParams) {
                                    const promise = $http.post(GENERAL_CONFIG.base_url + '/updateIssue', issueParams);
                                    return promise;
                                  };

                                issuesFactory.getAdjustmentTypes = function (taxYear) {
                                  var promise = $http({
                                    method: "get",
                                    url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=ibz5e4&tax_year=' + taxYear + '&jcd_key=250',

                                  });
                                  return promise;

                                };

                                  return issuesFactory;
                              }]);

    return services;
});
