define([
    'moment',
    'angular'
], function (moment) {
    'use strict';

    var services = angular.module('app.ecRefreshSetupActionsService',[])
        .factory("ecRefreshSetupActionsService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG','JsonObjectFactory', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,JsonObjectFactory) {

                var ecRefreshSetupActionsService = {};

                var URLS = {
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj',
                    GET_SCENARIO_TYPE_CODE: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_SCENARIO_DESC: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                    GET_GROUP_OBJ_BY_YEAR: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32735",
                }

                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                ecRefreshSetupActionsService.loadScenarioTypeCode = function(_data, _details){
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : null,
                        scenario_desc : null
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_SCENARIO_TYPE_CODE,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario Type Code.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve  Scenario Type Code. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                ecRefreshSetupActionsService.loadScenarioDesc = function(_selectedValue){
                    console.log(_selectedValue);
                    var scenario_type_code = _selectedValue;
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : scenario_type_code,
                        scenario_desc : null
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_SCENARIO_DESC,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Scenario Type Code.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve  Scenario Type Code. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                ecRefreshSetupActionsService.loadGroupObjKey = function(_selectedVal1, _selectedVal2){
                    console.log(_selectedVal1+'--------'+_selectedVal2);
                    var scenario_type_code = _selectedVal1;
                    var scenario_desc = _selectedVal2;
                    var  params = {
                        action_code: '73pnl0', 
                        tax_year : workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        scenario_type_code : scenario_type_code,
                        scenario_desc : scenario_desc
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_GROUP_OBJ_BY_YEAR,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Group Obj Key.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Group Obj key. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                ecRefreshSetupActionsService.convertDateFormates=function(jsonObj){
                    jsonObj.BATCH_START_DATE= moment(jsonObj.BATCH_START_DATE).format('MM/DD/YYYY');
                    jsonObj.BATCH_END_DATE= (jsonObj.BATCH_END_DATE == undefined || jsonObj.BATCH_END_DATE == 'Invalid date' || jsonObj.BATCH_END_DATE == '')  ? null: moment(jsonObj.BATCH_END_DATE).format('MM/DD/YYYY');
                    jsonObj.DEFAULT_TAX_YEAR_BEGIN= (jsonObj.DEFAULT_TAX_YEAR_BEGIN == undefined) ? null: moment(jsonObj.DEFAULT_TAX_YEAR_BEGIN).format('MM/DD/YYYY');
                    jsonObj.DEFAULT_TAX_YEAR_END= (jsonObj.DEFAULT_TAX_YEAR_BEGIN == undefined) ? null: moment(jsonObj.DEFAULT_TAX_YEAR_END).format('MM/DD/YYYY');
                    return jsonObj;
                }

               
                return ecRefreshSetupActionsService;

        }]);

    return services;

});