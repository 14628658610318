define(
		[ 'angular'

		],
		function() {
			'use strict';
			var controllers = angular.module('app.ComponentDefaultsBatchCtrl', [])
			.controller(
							'componentLockUnlockData',
							[ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'GlobalService', 'AlertService','$timeout', 'USER_SETTINGS', 'workspaceFactory','GENERAL_CONFIG','$uibModalInstance', 'DefaultBatchServiceFactory', 'gridData',
								componentLockUnlockData ])
			
		function componentLockUnlockData($rootScope, $scope, $state, $log,JsonObjectFactory, GlobalService, AlertService, $timeout, USER_SETTINGS, workspaceFactory, GENERAL_CONFIG,$uibModalInstance, DefaultBatchServiceFactory, gridData) {

				var vm = this;
				vm.loadingData = true;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;			
				vm.lockUnlockSelected = [];
				vm.isLockedWarningMsgs;
				vm.isUnlockedWarningMsgs;			
				vm.isErrorExists = false; 				
                vm.is_return_filed;
                vm.is_locked;
                vm.isSaveClicked = false;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                //Get the Default Current Year
                vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;
                
                
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				vm.checkProperties = function(obj) {
					const objValues = Object.values(obj);
				if ((obj.BASKET_0 == null || obj.BASKET_0 == '')) {
						return false;
					} else {
						return true;
					}
					 /* if (_.includes(objValues, "") || _.includes(objValues, null)) {
					    return false;
					  } else {
					    return true
					  }*/
				}
				
				// On Load of modal.. it will put it in modal Table
				if (gridData != null) {
				
					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;

					vm.gridDataMultipleSelect = _.clone(tempGridData);					
					if (vm.gridDataMultipleSelect != undefined) {						
													
						//If the Tax Return is filed then block user from proceeding further
						vm.is_return_filed = vm.gridDataMultipleSelect[0].data.IS_RETURN_FILED;		
						if(vm.is_return_filed != undefined && vm.is_return_filed !=null && vm.is_return_filed == 'Y'){																	
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							throw new Error("You cannot perform this action as Tax return is already filed.");
							return;
						}	
						
						
						
						for ( var data in vm.gridDataMultipleSelect) {  
							
							if(!vm.checkProperties(vm.gridDataMultipleSelect[data].data) ){
								AlertService.add("error", "Please select defaults which are Locked/Unlocked");
								throw new Error("Please select components !");
								return;
							}
						}
						
                        //Logic to Displaying the Warning Messages while LOCK'ing and UNLOCK'ing of the Entity
						vm.isLockedWarningExists = false;
						vm.isUnLockedWarningExists = false;
                        for ( var data in vm.gridDataMultipleSelect) {                                    
                            if(vm.gridDataMultipleSelect[data].data.STATUS_FLAG == 'Locked') {                             
                            	 vm.gridDataMultipleSelect[data].data["LOCK_STATUS"] = 'Already Locked';
                            	 vm.isLockedWarningExists = true;
                            }else{
                           	 	vm.gridDataMultipleSelect[data].data["LOCK_STATUS"] = '';
                            }
                            
                            if(vm.gridDataMultipleSelect[data].data.STATUS_FLAG == 'Unlocked') {   
                            	 vm.gridDataMultipleSelect[data].data["UNLOCK_STATUS"] = 'Already Unlocked';
                            	 vm.isUnLockedWarningExists = true;
                            }else{
                            	 vm.gridDataMultipleSelect[data].data["UNLOCK_STATUS"] = '';
                            }
                            vm.lockUnlockSelected.push(vm.gridDataMultipleSelect[data].data);                     	 
                             console.log(vm.lockUnlockSelected,"lockUnlockSelected");
                        } 
                        if(vm.isLockedWarningExists){
                        	vm.isLockedWarningMsgs = "Please resolve the warnings before Save";  
                        }
                        if(vm.isUnLockedWarningExists){
                        	vm.isUnlockedWarningMsgs = "Please resolve the warnings before Save";
                        }
                        
					}//end of grid selected row
			}
				
				
			
			//Remove the Entry from Pop-up
			vm.removeLockUnLockRow = function(name){					
				var index = vm.lockUnlockSelected.indexOf(name);					
				vm.lockUnlockSelected.splice(index, 1);	
				
				vm.isLockedWarningExists = false;
				vm.isUnLockedWarningExists = false;
				vm.isLockedWarningMsgs = null;
				vm.isUnlockedWarningMsgs = null;
				for ( var data in vm.lockUnlockSelected) {                                    
					if(vm.lockUnlockSelected[data].STATUS_FLAG == 'Locked') {                             
						vm.isLockedWarningExists = true;
                   	}
                   	if(vm.lockUnlockSelected[data].STATUS_FLAG == 'Unlocked') {   
                   	 	vm.isUnLockedWarningExists = true;
                   	}
               } 
               if(vm.isLockedWarningExists){
               	vm.isLockedWarningMsgs = "Please resolve the warnings before Save";  
               }             
               if(vm.isUnLockedWarningExists){
               	vm.isUnlockedWarningMsgs = "Please resolve the warnings before Save";
               } 
			}
		
			//Save the selected Entitys that get Locked
			vm.saveCCToGetLocked = function() {
				vm.isSaveClicked = true;
				if(vm.lockUnlockSelected.length == 0 ){
					vm.isSaveClicked = false;
					AlertService.add("error", "Please select atleast one row for Locking");
					return;
				}	
				
				console.log("Logged in user: ",vm.logged_in_user); 
				vm.recordsToNotify = [];
				var allCCKeys = '';
				for ( var item in vm.lockUnlockSelected) {
					vm.recordsToNotify.push(vm.lockUnlockSelected[item]);
					vm.recordsToNotify["CURRENT_USER"] = vm.logged_in_user;
					vm.recordsToNotify["NOTIFY_USER"] = vm.lockUnlockSelected[item].UPDATED_BY;
					if(vm.lockUnlockSelected[item].STATUS_FLAG != 'Locked'){
						if(allCCKeys == ''){
							allCCKeys = allCCKeys + vm.lockUnlockSelected[item].COMBINATION_KEY;								
						}else{
							allCCKeys = allCCKeys +","+ vm.lockUnlockSelected[item].COMBINATION_KEY;			
						}
					}
				}				
				console.log("vm.recordsToSave : ", vm.recordsToNotify);
				
				var message = "Selected Entity has been locked.";					
				//Save the data 
				sendDetailsForLockUnLock(allCCKeys,'L', message,vm.recordsToNotify);
			}
			
			//Save the selected Entity that get Locked
			vm.saveCCToGetUnLocked = function() {
				vm.isSaveClicked = true;
				if(vm.lockUnlockSelected.length == 0 ){
					vm.isSaveClicked = false;
					AlertService.add("error", "Please select atleast one row for Locking");
					return;
				}	
				vm.recordsToNotify = [];
				var allCCKeys = '';
				for ( var item in vm.lockUnlockSelected) {					
					vm.lockUnlockSelected[item]["EDITING_USER"] = vm.logged_in_user;
					vm.lockUnlockSelected[item]["NOTIFY_USER"] = vm.lockUnlockSelected[item].UPDATED_BY;
					vm.recordsToNotify.push(vm.lockUnlockSelected[item]);
					
					
					if(vm.lockUnlockSelected[item].STATUS_FLAG != 'Unlocked'){
						if(allCCKeys == ''){						
							allCCKeys = allCCKeys + vm.lockUnlockSelected[item].COMBINATION_KEY;								
						}else{
							allCCKeys = allCCKeys +","+ vm.lockUnlockSelected[item].COMBINATION_KEY;			
						}
					}
				}
				console.log("vm.recordsToSave : ", vm.recordsToNotify);
				
				var message = "Selected CC has been unlocked.";					
				//Save the data 
				sendDetailsForLockUnLock(allCCKeys,'U',  message,vm.recordsToNotify);
			}
			
			//Send Details to Service to Save the data
			function sendDetailsForLockUnLock(allCCKeys,lockFlag, message, _notificationData) {
				DefaultBatchServiceFactory.saveLockAndUnLocking(allCCKeys,lockFlag).then(function(result) {
					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
							AlertService.add("success", message, 4000);
							var args = {}; //(workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};							
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
														
							//if(lockFlag == 'U'){
							//	AttributeBatchServiceFactory.sendNotification(_notificationData);
							//}
                     	};
				});
			}
				
		  }	

		return controllers;

	});