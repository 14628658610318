define([
    'angular'

 ], function () {
    'use strict';

    var controllers = angular.module('app.8858dataproduceController', [])
       .controller('dataProduce8858Ctrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory',
          'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG','$uibModal', dataProduce8858Ctrl
       ]);

    function dataProduce8858Ctrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG,$uibModal) {
       var vm = this;
       var leId = $scope.$parent.$parent.rowData.LEID;
       var cdrNo = $scope.$parent.$parent.rowData.CDR_NO;
       var rptPeriod = $scope.$parent.$parent.rowData.REPORTING_PERIOD;
       var taxYear = $scope.$parent.$parent.rowData.TAX_YEAR;
       var leType=$scope.$parent.$parent.rowData.LE_TYPE;


       /* save from workflow 5/14/2019 */
       $scope.$on('workflowSave', function (event, data) {
          vm.save();
       });
       vm.openPdf = function() {
         $uibModal.open({
            animation: true,
            template: "<div class='modal-header'><div class='modal-title'>Form 8858<i class='fa fa-times pull-right' role='button' uib-tooltip='Close' ng-click='uibModalInstance.close()'></i></div></div><div class='modal-body'>"+
            "<pdf-preview le-id='leId' form-name='formName' div-height=\"'80vh'\" tax-year='taxYear' cdr-no='cdrNo' rpt-period='rptPeriod' le-type='leType' ></pdf-preview></div>",
             controller: ['$scope','$uibModalInstance','leId','cdrNo','rptPeriod','taxYear', function($scope,$uibModalInstance,leId,cdrNo,rptPeriod,taxYear) {
                $scope.leId=leId;//"LZ0007";
                $scope.formName="8858";//"1120L";
                $scope.rptPeriod=rptPeriod;//"1120L";
                $scope.taxYear=taxYear;//"1120L";
                $scope.cdrNo=cdrNo;//"1120L";
                $scope.leType=leType;
                $scope.uibModalInstance = $uibModalInstance;
             }],
            size: 'lg',
            // backdrop: 'static',
            resolve: {
               leId: function() {
                  return leId;
               },
               cdrNo: function() {
                  return cdrNo;
               },
               rptPeriod: function() {
                  return rptPeriod;
               },
               taxYear: function() {
                  return taxYear;
               }
            }
        });
       }
       vm.save = function () {
          AlertService.add("success", "Data save Successfully", 4000);
       }
    }
 });
