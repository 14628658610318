define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.schedule-GService',[])
       .factory("scheduleGServiceFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG', 
    	   
    	   function($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG) {
	           var factory = {};
	           var returnObj = {};
	           var scheduleGServiceFactory ={};
	           scheduleGServiceFactory.url ={};
	           scheduleGServiceFactory.url.save_schedule_G = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=chgxp7";
	          /* scheduleGServiceFactory.save_schedule_G= function(_data,Details){
	        	   console.log(_data,Details);
	           }*/
	          
	           scheduleGServiceFactory.saveScheduleG  = function(_callName ,_data){
	               console.log("data", _data);
	               console.log("callname",_callName);
	               var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var tax_year = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
	           if(tax_year < 2020)
	            {
                   var rowData = _data.rowData;
	               //$rootScope.$broadcast('gridRefresh', false); 
	               returnObj.combination_key = _data.rowData.HO_COMBINATION_KEY;

	               var service = $injector.get('JsonObjectFactory');
	              
	               var jsonObj = [{
	            	   ho_combination_key: _data.rowData.HO_COMBINATION_KEY,
	            	   ho_leid : _data.rowData.LEID,
	            	   ho_cdr_no: _data.rowData.CDR_NO,
	            	   ho_reporting_period : _data.rowData.REPORTING_PERIOD,
	            	   job_id:_data.rowData.JOB_ID,
	            	   filing_key:_data.rowData.FILING_KEY,
                       adj_amt : _data.rowData[_data.colData.map],
	               	   column_name :_data.colData.map,
	   			}];
	               
	               
	           	console.log(jsonObj);
	           	
	           	var jsonSettings = [{
	            	 tax_year: _data.rowData.TAX_YEAR
	   			}];
	           	
	            
	               var params = _.merge({}, GlobalService.globalParams, filterParams);
	               var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
	               var params = _.extend({jsonSettings: JSON.stringify(jsonSettings)}, params);
	               var service = $injector.get('JsonObjectFactory');
	                var defer = $q.defer(); 	
	   	            	/* var promise = $http({
	   	          			 method: "POST",
	   	          			 url: scheduleGServiceFactory.url.save_schedule_G ,
	   	          			 data: params
	   	          		}). then(function (response){
	   	          				console.log(response.data);
	   	          				if(response.data.callSuccess === "1"){
	   	                            AlertService.add("success", "Data saved Successfully.",3000);
	   	                            var args = {COMBINATION_KEYS: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
	   	                                         $rootScope.$emit('gridUpdate', args);
	   	                        }else{
	   	                            //AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                                   
                                    AlertService.add("error", response.data.errorMessage, 4000);
                                    $rootScope.$broadcast('gridRefresh', true);
	   	                        }
	   	          			
	   	          				return response.data;
	   	          				})
	   	          			
	   	          			return promise;		       */   
	               service.saveJSON(scheduleGServiceFactory.url.save_schedule_G,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   AlertService.add("success", "Changes have been saved", 3000);
	                        var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY,gridFilter: {combination_keys: _data.rowData.HO_COMBINATION_KEY}};
	                        $rootScope.$emit('gridUpdate', args);
							defer.resolve(data);
						}else {
	                     AlertService.add("error", data.errorMessage, 3000);
	                     defer.reject(data);
	                 }},function(data){
	                 defer.reject(data);
	             });
	             return defer.promise;
	            }
	            else
	        	{
	            	 $rootScope.$broadcast('gridRefresh', false); 
	                 var service = $injector.get('JsonObjectFactory');
	                 var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=fjaaz7";
	                 var line_attrib_key = 0;
	                 var attrib_name = '';
	                 var trans_details_key = 0;
	                 var amt = 0;
					 if(_data.col_id == 'DM_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.DM_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.DM_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.DM_TRANS_DETAILS_KEY) ? _data.rowData.DM_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.DM_ITMS_AMT == "" ? null :parseInt(_data.rowData.DM_ITMS_AMT);
	                 }
					 if(_data.col_id == 'HT_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.HT_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.HT_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.HT_TRANS_DETAILS_KEY) ? _data.rowData.HT_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.HT_ITMS_AMT == "" ? null :parseInt(_data.rowData.HT_ITMS_AMT);
	                 }
					 if(_data.col_id == 'DED_ITMS_AMT')
	                 {
						if(!angular.isDefined(_data.rowData.DM_ITMS_AMT)  || _data.rowData.DM_ITMS_AMT == null )
					    {
							line_attrib_key = _data.rowData.DED_LINE_ATTRIB_KEY;
	                	 	attrib_name = _data.rowData.DED_ATTRIB_NAME;
	                     	trans_details_key = ( angular.isDefined(_data.rowData.DED_TRANS_DETAILS_KEY) ? _data.rowData.DED_TRANS_DETAILS_KEY : 0);
	                     	amt = _data.rowData.DED_ITMS_AMT == "" ? null : parseInt(_data.rowData.DED_ITMS_AMT);
						}
						else
						{
							AlertService.add("error", "Cannot enter Deduction taken into account(3 DED), as De Minimis(1 DM) value is not null.");
							var args = {
									combination_key: _data.rowData.HO_COMBINATION_KEY,
									gridFilter: {
										combination_key: _data.rowData.HO_COMBINATION_KEY
									}
								};
								$rootScope.$emit('gridUpdate', args);
							return false;
						}
					  }
	                 if(_data.col_id == 'AHC_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.AHC_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.AHC_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.AHC_TRANS_DETAILS_KEY) ? _data.rowData.AHC_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.AHC_ITMS_AMT == "" ? null : parseInt(_data.rowData.AHC_ITMS_AMT)
	                 }
					 if(_data.col_id == 'BN_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.BN_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.BN_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.BN_TRANS_DETAILS_KEY) ? _data.rowData.BN_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.BN_ITMS_AMT == "" ? null : parseInt(_data.rowData.BN_ITMS_AMT);
	                 }
					 if(_data.col_id == 'ARR_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.ARR_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.ARR_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.ARR_TRANS_DETAILS_KEY) ? _data.rowData.ARR_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.ARR_ITMS_AMT == "" ? null : parseInt(_data.rowData.ARR_ITMS_AMT);
	                 }
					 if(_data.col_id == 'RD_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.RD_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.RD_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.RD_TRANS_DETAILS_KEY) ? _data.rowData.RD_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.RD_ITMS_AMT == "" ? null : parseInt(_data.rowData.RD_ITMS_AMT);
	                 }
					 if(_data.col_id == 'SD_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.SD_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.SD_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.SD_TRANS_DETAILS_KEY) ? _data.rowData.SD_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.SD_ITMS_AMT == "" ? null : parseInt(_data.rowData.SD_ITMS_AMT);
	                 }
					 if(_data.col_id == 'SCDI_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.SCDI_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.SCDI_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.SCDI_TRANS_DETAILS_KEY) ? _data.rowData.SCDI_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.SCDI_ITMS_AMT == "" ? null : parseInt(_data.rowData.SCDI_ITMS_AMT);
	                 }
					 if(_data.col_id == 'SCRR_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.SCRR_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.SCRR_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.SCRR_TRANS_DETAILS_KEY) ? _data.rowData.SCRR_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.SCRR_ITMS_AMT == "" ? null : parseInt(_data.rowData.SCRR_ITMS_AMT);
	                 }
					 if(_data.col_id == 'LT_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.LT_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.LT_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.LT_TRANS_DETAILS_KEY) ? _data.rowData.LT_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.LT_ITMS_AMT == "" ? null : parseInt(_data.rowData.LT_ITMS_AMT);
	                 }
					 if(_data.col_id == 'AC_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.AC_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.AC_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.AC_TRANS_DETAILS_KEY) ? _data.rowData.AC_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.AC_ITMS_AMT == "" ? null : parseInt(_data.rowData.AC_ITMS_AMT);
	                 }
					 if(_data.col_id == 'SCM_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.SCM_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.SCM_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.SCM_TRANS_DETAILS_KEY) ? _data.rowData.SCM_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.SCM_ITMS_AMT == ""? null : parseInt(_data.rowData.SCM_ITMS_AMT);
	                 }
					 if(_data.col_id == 'SCSU_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.SCSU_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.SCSU_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.SCSU_TRANS_DETAILS_KEY) ? _data.rowData.SCSU_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.SCSU_ITMS_AMT == ""?null : parseInt(_data.rowData.SCSU_ITMS_AMT);
	                 }
					 if(_data.col_id == 'PM_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.PM_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.PM_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.PM_TRANS_DETAILS_KEY) ? _data.rowData.PM_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.PM_ITMS_AMT == "" ? null : parseInt(_data.rowData.PM_ITMS_AMT);
	                 }
					 if(_data.col_id == 'SC_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.SC_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.SC_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.SC_TRANS_DETAILS_KEY) ? _data.rowData.SC_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.SC_ITMS_AMT == "" ? null : parseInt(_data.rowData.SC_ITMS_AMT);
	                 }
					 if(_data.col_id == 'BR_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.BR_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.BR_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.BR_TRANS_DETAILS_KEY) ? _data.rowData.BR_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.BR_ITMS_AMT == "" ? null : parseInt(_data.rowData.BR_ITMS_AMT);
	                 }
	                 if(_data.col_id == 'AF_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.AF_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.AF_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.AF_TRANS_DETAILS_KEY) ? _data.rowData.AF_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.AF_ITMS_AMT == ""? null : parseInt(_data.rowData.AF_ITMS_AMT);
	                 }
	                 if(_data.col_id == 'EF_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.EF_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.EF_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.EF_TRANS_DETAILS_KEY) ? _data.rowData.EF_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.EF_ITMS_AMT == "" ? null : parseInt(_data.rowData.EF_ITMS_AMT);
	                 }
					 if(_data.col_id == 'AI_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.AI_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.AI_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.AI_TRANS_DETAILS_KEY) ? _data.rowData.AI_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.AI_ITMS_AMT == "" ? null : parseInt(_data.rowData.AI_ITMS_AMT);
	                 }
					 if(_data.col_id == 'EP_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.EP_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.EP_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.EP_TRANS_DETAILS_KEY) ? _data.rowData.EP_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.EP_ITMS_AMT == "" ? null : parseInt(_data.rowData.EP_ITMS_AMT);
	                 }
	                 if(_data.col_id == 'XX_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.XX_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.XX_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.XX_TRANS_DETAILS_KEY) ? _data.rowData.XX_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.XX_ITMS_AMT == "" ? null : parseInt(_data.rowData.XX_ITMS_AMT);
	                 }
	                 if(_data.col_id == 'SCRR_ITMS_AMT')
	                 {
	                	 line_attrib_key = _data.rowData.SCRR_LINE_ATTRIB_KEY;
	                	 attrib_name = _data.rowData.SCRR_ATTRIB_NAME;
	                     trans_details_key = ( angular.isDefined(_data.rowData.SCRR_TRANS_DETAILS_KEY) ? _data.rowData.SCRR_TRANS_DETAILS_KEY : 0);
	                     amt = _data.rowData.SCRR_ITMS_AMT == "" ? null : parseInt(_data.rowData.SCRR_ITMS_AMT);
	                 }
					 
	                 var editRow = [];
					 var removeRow = [];
					
	                 var returnObj = [{
							"tax_year":tax_year,
							"trans_type_key":tax_year < 2020 ? 28 : 1229,
							"combination_key":_data.rowData.HO_COMBINATION_KEY,
							"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
							"form_key":_data.rowData.FORM_KEY,
							"line_no":'1',
							"occurence":1,
							"line_attrib_key" : line_attrib_key,
							"Attribute_Name":attrib_name,
							"Attribute_Value":amt,
							"trans_dtls_key" : trans_details_key,
							"trans_status":"A"}];
						
					if(amt != null)
					{
						editRow.push(returnObj);
					 }
					 else
					 {
						removeRow.push(returnObj);
					 }
					if(_data.col_id == 'DM_ITMS_AMT' && angular.isDefined(_data.rowData.DM_ITMS_AMT) && _data.rowData.DM_ITMS_AMT != null &&  (angular.isDefined(_data.rowData.DED_ITMS_AMT) || _data.rowData.DED_ITMS_AMT != null))
	                 {
						 var returnObj = [{
							"tax_year":tax_year,
							"trans_type_key":tax_year < 2020 ? 28 : 1229,
							"combination_key":_data.rowData.HO_COMBINATION_KEY,
							"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
							"form_key":_data.rowData.FORM_KEY,
							"line_no":'1',
							"occurence":1,
							"line_attrib_key" : _data.rowData.DED_LINE_ATTRIB_KEY,
							"Attribute_Name":_data.rowData.DED_ATTRIB_NAME,
							"Attribute_Value":null,
							"trans_dtls_key" : _data.rowData.DED_TRANS_DETAILS_KEY,
							"trans_status":"A"}];
							removeRow.push(returnObj);
	                 }
				
				     var jsonObj = {"addrows":[], "editrows":editRow, "removerows":removeRow};
						console.log('scheduleDetails-------------', jsonObj);
					 var JSONSettings = [{"tax_year":workspaceFactory.activeScreen.filters.getFilterParams().tax_year,"scenario":workspaceFactory.activeScreen.filters.getFilterParams().scenario,"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
		
	 	            var params = _.merge({}, GlobalService.globalParams, filterParams);
	                 params =  _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
	        		 	params =  _.extend({jsonSettings:JSON.stringify(JSONSettings)}, params);
	                 
	                 var defer = $q.defer();
	                 //params.tax_year = rowData.TAX_YEAR;
	                 params.process_name =  "save_5471_G19";
	                 console.log("params-----", params);
	                
	                 service.saveJSON(url, params).then(function (data) {
	                     if (data.callSuccess === "1") {
	                         AlertService.add("success", "Data saved Successfully.", 4000);
	                          var args = {
	                                 combination_key: _data.rowData.HO_COMBINATION_KEY,
	                                 gridFilter: {
	                                     combination_key: _data.rowData.HO_COMBINATION_KEY
	                                 }
	                             };
	                             $rootScope.$emit('gridUpdate', args);
	                         defer.resolve(data);
	                     } else {
	                         AlertService.add("error", data.errorMessage);
	                         var args = {
	                                 combination_key: _data.rowData.HO_COMBINATION_KEY,
	                                 gridFilter: {
	                                     combination_key: _data.rowData.HO_COMBINATION_KEY
	                                 }
	                             };
	                             $rootScope.$emit('gridUpdate', args);
	                         defer.reject(data);
	                     }
	                 },function(data){
	                     defer.reject(data);
	                 });
	                 return defer.promise;
	        	}
	            }
	           
	            
	   return scheduleGServiceFactory;
	          }]);
   return services;
});