define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.wwniSubCategoryController', [])
					.controller(
							'WwniSubCategoryController',
							[ '$rootScope', '$scope', '$http', 'GlobalService',
									'$uibModalInstance', 'ModalFactory',
									'AlertService', 'rowData', 'gridData',
									'JsonObjectFactory', '$timeout',
									'WWniGroupObjFactorySub', 'GENERAL_CONFIG',
									wwniSubCategoryController ])

			function wwniSubCategoryController($rootScope, $scope, $http,
					GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					$timeout, WWniGroupObjFactorySub, GENERAL_CONFIG) {
				
				var vm = this;
				vm.displaySubcategory = [];
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				$scope.crudLoading = true;
				vm.noRecords = false;
				
				init();
				
				function init() {
				getSubCategory();
				}
				function getSubCategory() {
					var params = {
						"action_code" : 'm4hj9g',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						'source_combination_key' : (rowData != undefined) ? rowData.SOURCE_COMBINATION_KEY
								: gridData[index].data.SOURCE_COMBINATION_KEY,

						'target_combination_key' : (rowData != undefined) ? rowData.COMPL_COMBINATION_KEY
								: gridData[index].data.COMPL_COMBINATION_KEY,
						'catg_acct_key' :0,
						'me_key' : (rowData != undefined) ? rowData.SOURCE_ME_KEY
								: gridData[index].data.SOURCE_ME_KEY
					}
					
					
					
					
					
					$scope.crudLoading = true;
					vm.noRecords = false
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											$scope.crudLoading = false;
											return false;
										} else {

											vm.displaySubcategory = data.jsonObject;
											$scope.crudLoading = false;
											if(vm.displaySubcategory.length == 0)
												{
												vm.noRecords = true;
												}
											
											// vm.sub_category_dropdown =
											// _.uniq(vm.sub_category_dropdown,JSON.stringify)

										}

									})
				}

				
				
			}
			return controllers;

		});
