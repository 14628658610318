define(
		[ 'angular'

		],
		function() {
			'use strict';
			var controllers = angular.module('app.PshipPtrCtrl', [])
					.controller(
							'pshipPtrCtrl',
							[ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'workspaceFactory', 'GlobalService', 'AlertService','$timeout', 'USER_SETTINGS', 'GENERAL_CONFIG','$uibModal',
								'$uibModalInstance', 'PshipPtrServiceFactory', 'gridData', pshipPtrCtrl ])	
			/**
			 * @Author: Abhiruchi Sharma
			 */
			
            
			
			function pshipPtrCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, workspaceFactory, GlobalService, AlertService, $timeout, USER_SETTINGS, GENERAL_CONFIG,$uibModal,
					$uibModalInstance, PshipPtrServiceFactory, gridData) {
				
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.selectedTagSourcingRow = [];
				vm.total_allocation_ratio = 0 ;
				vm.total_allocation_amount = 0;
				vm.totalActualRatio = 0;
				vm.isSaveClicked = false;
				vm.ratioNotEqualOne = false;
				vm.ptr_actual_ratio = 0;
        		vm.ptr_actual_amount = 0;
        		vm.ptr_special_actual_ratio = 0;
        		vm.ptr_special_actual_amount = 0;
        		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        		vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;
        		//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
								vm.cancel();										
								AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
								return;
						}							
					});
				});
				
			if (gridData != null) {
				
					var tempGridData = _.clone(gridData), groups = Object.create(null), result, finalKeyCheck = 0;

					vm.gridDataMultipleSelect = _.clone(tempGridData);
			
					if (vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.rowData) {
							vm.pshipLeid = vm.gridDataMultipleSelect.rowData.PTRSHIP_LEID;
							vm.pshipCdrNo = vm.gridDataMultipleSelect.rowData.PTRSHIP_CDR_NO;
							vm.pshipReportingPeriod = vm.gridDataMultipleSelect.rowData.PTRSHIP_REPORTING_PERIOD;
							vm.pshipMeCode = vm.gridDataMultipleSelect.rowData.PTRSHIP_ME_CODE;
							vm.pshipAmount = vm.gridDataMultipleSelect.rowData.PTRSHIP_AMT;
							vm.pshipCCKey = vm.gridDataMultipleSelect.rowData.PTRSHIP_COMBINATION_KEY;
							vm.pshipTagKey =  vm.gridDataMultipleSelect.rowData.PTRSHIP_TAG_KEY;
					}
					
					if(gridData.colData.call_back == 'editPshipPtrBeatRatio')
						{
						
						}
				}
			
			vm.cancel = function() {
				$uibModalInstance.dismiss('cancel');
			};
			
			vm.reset = function(){
				vm.partnerDetailsData.forEach( function (record)
            	{
					record.NEW_ALLOCATION_RATIO = 0;
            		record.NEW_ALLOCATION_AMOUNT = 0;
            	});
				vm.total_allocation_ratio = 0;
				vm.total_allocation_amount = 0;
			};
			
		
			vm.getPartnerDetails = function() {
			
				var params = {
						"action_code" : 'qxwr80',
						"p_ptrship_cc_key" : vm.pshipCCKey,
						"p_ptrship_tag_key" : vm.pshipTagKey,
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key						
					};
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
		                	vm.partnerDetailsData = data.jsonObject;
		                	
		                	vm.partnerDetailsData.forEach( function (record)
		                	{
		                		vm.totalActualRatio += record.PTR_ACTUAL_RATIO;
		                		vm.pshipAmount = record.PSHIP_AMT;
		                		vm.ptr_actual_ratio = vm.ptr_actual_ratio + record.PTR_ACTUAL_RATIO;
		                		vm.ptr_actual_amount += record.PTR_ACTUAL_AMOUNT;
		                		vm.ptr_special_actual_ratio = vm.ptr_special_actual_ratio + record.PTR_SPECIAL_ALLOCATION_RATIO;
		                		vm.ptr_special_actual_amount += record.PTR_SPECIAL_ALLOCATION_AMOUNT;
		                		
	                		});
		                }
					});
			}
			
			vm.getPartnerDetails();
			
			
			
			vm.updateAllocationAmount = function(data) {
				
				if(data.NEW_ALLOCATION_RATIO.length > 12 )
					data.NEW_ALLOCATION_RATIO = parseFloat(data.NEW_ALLOCATION_RATIO).toFixed(10);
				
				if (data.NEW_ALLOCATION_RATIO  > 2) {
					data.NEW_ALLOCATION_RATIO = 1;
				}
				
				vm.total_allocation_ratio = 0;
				vm.total_allocation_amount = 0;
				data.NEW_ALLOCATION_AMOUNT = ((vm.pshipAmount =='' || vm.pshipAmount == null ? 0 : vm.pshipAmount) * parseFloat((data.NEW_ALLOCATION_RATIO == null || data.NEW_ALLOCATION_RATIO == '')? 0 : data.NEW_ALLOCATION_RATIO)) ;
				
				vm.partnerDetailsData.forEach( function (record)
            	{
					vm.total_allocation_ratio += parseFloat((record.NEW_ALLOCATION_RATIO == '' || record.NEW_ALLOCATION_RATIO == null ) ? 0 : record.NEW_ALLOCATION_RATIO) ;
					record.NEW_ALLOCATION_AMOUNT = ((vm.pshipAmount =='' || vm.pshipAmount == null ? 0 : vm.pshipAmount) * parseFloat((record.NEW_ALLOCATION_RATIO == null || record.NEW_ALLOCATION_RATIO == '')? 0 : record.NEW_ALLOCATION_RATIO)) ;
            		vm.total_allocation_amount+= parseFloat((record.NEW_ALLOCATION_AMOUNT == '' || record.NEW_ALLOCATION_AMOUNT == null ) ? 0 : record.NEW_ALLOCATION_AMOUNT) ;
            	});
			}
			
			
			vm.save = function() {
				var returnClobSettingsObj = {};
				var allPshipPtrDetails = [];
				vm.isSaveClicked = true;
				
				if(vm.total_allocation_ratio != vm.totalActualRatio){
					vm.isSaveClicked = false;
					AlertService.add("error", "The Special Allocation ratio should be equal to Total Actual Ratio.");
					return;
				}else{
					for(var key in vm.partnerDetailsData){
						var returnObj = {};
						
						returnObj["pship_tag_key"] = vm.pshipTagKey;
						returnObj["ptr_tag_key"] = vm.partnerDetailsData[key].PTR_TAG_KEY;
						returnObj['special_allocation_ratio'] =  vm.partnerDetailsData[key].NEW_ALLOCATION_RATIO;
						
						allPshipPtrDetails.push(returnObj);
					}	
	
					var message = "Data has been successfully saved/updated";
					
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['p_tax_year'] = vm.curentyear;
					returnClobSettingsObj['p_scenario'] = vm.scenario;
					returnClobSettingsObj['p_jcd_key'] = vm.jcd_key;
					
					sendDetails(returnClobSettingsObj,allPshipPtrDetails, message);
				}			
			};
			
			
			function sendDetails(returnClobSettingsObj, allPshipPtrDetails, message) {
				PshipPtrServiceFactory.saveDefaults(returnClobSettingsObj,allPshipPtrDetails).then(function(result) {

							if (result.data.errorMessage && result.data.errorMessage !== 'null') {
								vm.isSaveClicked = false;
								AlertService.add("error", result.data.errorMessage, 4000);
							} else {
								AlertService.add("success", message, 4000);
								var args = {};
								$uibModalInstance.dismiss('cancel');
								$rootScope.$emit('gridUpdate', args);
                        	}

						});
			}		
			
			
			}
			
			return controllers;

		});
