define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.SchdA163JService', [])

        .factory("SchdA163JFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,
                DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
                var factory = {};
                var returnObj = {};
                factory.fieldConfigData = {};

                factory.saveSchdA163J = function (_callName, _data, isNew) {

                    var json_arrOBJ = {};
                    var defer = $q.defer();
                    
                    var v_LN_43E = Number(_data.rowData.LN_43C) + Number(_data.rowData.LN_43D);
                    var v_LN_43_F_G = Number(_data.rowData.LN_43F) + Number(_data.rowData.LN_43G);
                    var v_LN_43_H = Math.min(v_LN_43E, v_LN_43_F_G);
                    var v_LN_43_I = v_LN_43E - v_LN_43_H;

                    json_arrOBJ = [
                        {"amount":_data.rowData.LN_43C,"acct_key":_data.rowData['LN_43C_TO_ACCT'],"pship_combination_key":_data.rowData.PSHIP_COMBINATION_KEY,"ptr_combination_key":_data.rowData.PTR_COMBINATION_KEY},
                        {"amount":_data.rowData.LN_43D,"acct_key":_data.rowData['LN_43D_TO_ACCT'],"pship_combination_key":_data.rowData.PSHIP_COMBINATION_KEY,"ptr_combination_key":_data.rowData.PTR_COMBINATION_KEY},
                        {"amount":_data.rowData.LN_43F,"acct_key":_data.rowData['LN_43F_TO_ACCT'],"pship_combination_key":_data.rowData.PSHIP_COMBINATION_KEY,"ptr_combination_key":_data.rowData.PTR_COMBINATION_KEY},
                        {"amount":_data.rowData.LN_43G,"acct_key":_data.rowData['LN_43G_TO_ACCT'],"pship_combination_key":_data.rowData.PSHIP_COMBINATION_KEY,"ptr_combination_key":_data.rowData.PTR_COMBINATION_KEY},

                        {"amount":/*_data.rowData.LN_43E*/v_LN_43E,"acct_key":_data.rowData['LN_43E_TO_ACCT'],"pship_combination_key":_data.rowData.PSHIP_COMBINATION_KEY,"ptr_combination_key":_data.rowData.PTR_COMBINATION_KEY},
                        {"amount":/*_data.rowData.LN_43H*/v_LN_43_H,"acct_key":_data.rowData['LN_43H_TO_ACCT'],"pship_combination_key":_data.rowData.PSHIP_COMBINATION_KEY,"ptr_combination_key":_data.rowData.PTR_COMBINATION_KEY},
                        {"amount":/*_data.rowData.LN_43I*/v_LN_43_I,"acct_key":_data.rowData['LN_43I_TO_ACCT'],"pship_combination_key":_data.rowData.PSHIP_COMBINATION_KEY,"ptr_combination_key":_data.rowData.PTR_COMBINATION_KEY}];
                                       
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=rcbfz0";
                    var jsonSettings = [{
                        tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                        group_trans_key: _data.rowData.GROUP_TRANS_KEY
                    }];
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = _.merge({}, GlobalService.globalParams, filterParams);
                    params = _.extend({
                        jsonSettings: JSON.stringify(jsonSettings)
                    }, params);
                    params = _.extend({
                        jsonObj: JSON.stringify(json_arrOBJ)
                    }, params);

                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);
                            
                                var args = {
                                    combination_key: _data.rowData.COMBINATION_KEY,
                                    gridFilter: {
                                        combination_key: _data.rowData.COMBINATION_KEY
                                    }
                                };
                                $rootScope.$emit('gridUpdate', args);
                       
                            defer.resolve(data);
                            }
                            else{
                                AlertService.add("error", data.errorMessage, 4000);  
                                defer.reject(data);                              
                            }
                    }
                    ,function(data){
                        defer.reject(data);
                    }
                );
                    return defer.promise;
                }
                return factory;

            }
        ])
    return services;
});