define(
[ 'angular'



],
function() {
'use strict';



var controllers = angular.module('app.firEpBumpSummaryCtrl', [])
.controller('firEpBumpSummaryCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout', 'workspaceFactory',
'GlobalService','USER_SETTINGS','firEpBumpSummaryServiceFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', firEpBumpSummaryCtrl]);

function firEpBumpSummaryCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope, ModalFactory, AlertService, $timeout, workspaceFactory,
						GlobalService,USER_SETTINGS,firEpBumpSummaryServiceFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
						var vm = this;
						vm.rowData = rowData ||$scope.$parent.rowData;
						vm.colData = colData;
						vm.gridData = gridData;
						vm.globalParams = GlobalService.globalParams;
						vm.userSettings = USER_SETTINGS;
						vm.selectedData = [];
						$scope.crudLoading = false;
						
						
						vm.cancel = function() {
						$uibModalInstance.dismiss('cancel');
						};
						
						vm.modal_name = ModalFactory.response.data.modalScreen.modal_name;
						
						
						
						if (gridData != null) {
						
						
						
						var tempGridData = _.clone(gridData), groups = Object
						.create(null), result, finalKeyCheck = 0;
						
						vm.gridDataMultipleSelect = _.clone(tempGridData);
						
						if (vm.gridDataMultipleSelect != undefined) {
						for(var key in vm.gridDataMultipleSelect){
						var gridObj = {};
						
						gridObj["TIER_1_CFC_COMBINATION_KEY"] = vm.gridDataMultipleSelect[key].data.COMBINATION_KEY;
						gridObj["US_SH_COMBINATION_KEY"] = vm.gridDataMultipleSelect[key].data.US_SH_COMBINATION_KEY;
						gridObj["COL_VALUE"] = vm.modal_name == 'epBumpIndUpdateNo' ? 0 : 1;
						vm.selectedData.push(gridObj);
					}
						}
						}
						
						//Update the indicator for selected rows
						vm.confirm_save = function() {						
						var returnClobSettingsObj = {};
						var returnObj = {};						
						returnClobSettingsObj['sso_id'] = vm.logged_in_user;	
						var message = "First tier CFC 965 Deficit indicator has been changed.";
						sendDetails(returnClobSettingsObj,vm.selectedData, message);
						};
						
						
						
						function sendDetails(returnClobSettingsObj, saveDtls, message) {
						$rootScope.$broadcast("showLoader", true);
						$uibModalInstance.dismiss('cancel');
						
						$rootScope.$broadcast("showLoader", false);
						/*AlertService.add("success", message, 4000);*/
						firEpBumpSummaryServiceFactory.updateIndicator(saveDtls).then(function(result) {
						
						
						
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
						} else {
						AlertService.add("success", message, 4000);
						var args = {}; //(workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
						};
						});
						}
						}
						return controllers;
						});