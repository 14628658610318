define([
    'angular',
    'vsRepeat',
    'datatables',
    './EfileController',
    './efileElements/EfileElementsController',
    './EfileDirectives',
    './EfileService',
    './MigrationHistController',
], function () {
    'use strict';
    return angular.module('app.efile', ['app.EfileAdminController','app.EfileElementsController','app.efileDirectives', 'app.efileService', 'app.MigrationHistController', 'datatables', 'vs-repeat'])

    .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider, $urlRouterProvider,GENERAL_CONFIG) {
        $stateProvider
            .state('efile-data-push', {
                url: '/efile-data-push',
                templateUrl: 'app/components/efile/efile.html',
                data:{},
                access:""
            })
            .state('submissions-dasboard', {
                url: '/submissions-dasboard',
                templateUrl: 'app/components/efile/efileElements/submissionDashboard.html',
                data:{},
                access:"",
                controller:"SubmissionsDashboardController as ctrl"
            })
            .state('submissions-dasboard.list', {
                url: '/list',
                "templateUrl": "app/components/efile/efileElements/list-tpl.html",
                "controller": "SubmissionListCtrl as ctrl",
                type: 'grid',
                "api": {"get": GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=5746&action_id=32096"},
                "noFilters": true,
                access: ""
            })
            .state('submissions-dasboard.submission', {
                url: '/submission/:id',
                templateUrl: 'app/components/efile/efileElements/submission.html',
                data:{},
                access:"",
                controller:"SubmissionController as ctrl",
                resolve:{
                    submissionLoaded:['$stateParams','EfileFactory','JsonObjectFactory','$q','$state',function($stateParams,EfileFactory,JsonObjectFactory,$q,$state) {
                        
                        var deferred = $q.defer();
                        

                        if(EfileFactory.selectedSubmission){
                            deferred.resolve(true);
                        }else{
                            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=nvc2sx',
                                {submission_id:$stateParams.id}
                            ).then(function(response) {
                                if(!response.jsonObject || response.jsonObject.length === 0){
                                    $state.go("submissions-dasboard.list");
                                }
                                EfileFactory.selectedSubmission = response.jsonObject[0];
                                var tooltip = EfileFactory.selectedSubmission.TAX_PAYER_NAME;
                                let tab = {
                                    name: EfileFactory.selectedSubmission.TAX_YEAR + '-' + EfileFactory.selectedSubmission.TAX_PAYER,
                                    code: EfileFactory.selectedSubmission.SUBMISSION_ID,
                                    tooltip: tooltip,
                                    data:{
                                        rowData:EfileFactory.selectedSubmission
                                    }
                                };
                                EfileFactory.submissionTabs.push(tab);
                                deferred.resolve(true);
                            }).catch(function(e) {
                                $state.go("submissions-dasboard.list");
                            });
                        }


                        return deferred.promise;
                    }]
                }
            })
            .state('submissions-dasboard.submission.submit', {
                url: '/submit',
                templateUrl: 'app/components/efile/efileElements/submit.html',
                data:{},
                access:"",
                controller:"SubmitController as ctrl"
            })
            .state('submissions-dasboard.submission.submit.history', {
                url: '/',
                template: `
                    <div class="gt-dashboard-ex-body">
                        <div gt-datatable2 options="ctrl.options" ng-if="ctrl.options.data"></div>
                
                        <div ng-if="ctrl.loading" class="loader">
                            <i class="fa fa-spinner fa-spin fa-4x"></i>
                        </div>
                    </div>
                `,
                data:{},
                access:"",
                resolve:{
                    api_url:function(){
                        return '/loadJsonObject?action_code=81j7mv';
                    },
                    params:['EfileFactory','USER_SETTINGS',function(EfileFactory,USER_SETTINGS) {
                        return {
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                            sso_id:USER_SETTINGS.user.sso_id
                        };
                    }],
                    columns:['EfileFactory',function(EfileFactory) {
                        return EfileFactory.submissionListColumns;
                    }],
                    title:function() {
                        return "IRS Submissions Status";
                    }
                },
                controller:"EfileDashboardPopupController as ctrl"
            })
            .state('submissions-dasboard.submission.dashboard', {
                url: '/dashboard',
                templateUrl: 'app/components/efile/efileElements/dashboard.html',
                data:{},
                access:"",
                controller:"EfileDashboardController as ctrl"
            })
            .state('submissions-dasboard.submission.pdfupload', {
                url: '/pdf-upload',
                templateUrl: 'app/components/efile/efileElements/pdf-upload.html',
                data:{},
                access:"",
                controller:"PDFUploadController as ctrl"
            })
            .state('submissions-dasboard.submission.data', {
                url: '/data',
                templateUrl: 'app/components/efile/efileElements/data.html',
                data:{},
                access:"",
                controller:"DataController as ctrl"
            })
            .state('submissions-dasboard.submission.errors', {
                url: '/errors',
                templateUrl: 'app/components/efile/efileElements/data/errors.html',
                data:{},
                access:"",
                controller:"DataErrorsController as ctrl"
            })
            .state('submissions-dasboard.submission.data.addEdit', {
                url: '/add-edit',
                templateUrl: 'app/components/efile/efileElements/data/addEdit.html',
                data:{},
                access:"",
                controller:"AddEditController as ctrl"
            })
            .state('submissions-dasboard.submission.xmlUploadKeyId', {
                url: '/xml-upload',
                templateUrl: 'app/components/efile/efileElements/xmlConsolUpload.html',
                data:{},
                access:"",
                controller:"XMLConsolUploadController as ctrl"
            })
            .state('submissions-dasboard.submission.overrides', {
                url: '/overrides',
                templateUrl: 'app/components/efile/efileElements/overrides/overrides-grid-list.html',
                data:{},
                access:"",
                type: 'grid',               
                 api:{"get":GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=5886&action_id=32546"},
                controller:"overridesController as ctrl"
            })
            .state('submissions-dasboard.submission.deleteForm', {
                url: '/delete-form',
                templateUrl: 'app/components/efile/efileElements/delete-form.html',
                data:{},
                access:"",
                controller:"DeleteFormController as ctrl"
            })
            .state('submissions-dasboard.submission.lockUnlock', {
                url: '/lock-unlock',
                templateUrl: 'app/components/efile/efileElements/lock-unlock.html',
                data:{},
                access:"",
                controller:"LockUnlockController as ctrl"
            })
            .state('submissions-dasboard.submission.enabledDisabled', {
                url: '/enabled-disabled',
                templateUrl: 'app/components/efile/efileElements/enabled-disabled.html',
                data:{},
                access:"",
                controller:"EnabledDisabledController as ctrl"
            })
            .state('submissions-dasboard.submission.xmlSync', {
                url: '/xml-sync',
                templateUrl: 'app/components/efile/efileElements/xml-sync.html',
                data:{},
                access:"",
                controller:"XMLSyncController as ctrl"
            })
            .state('submissions-dasboard.submission.unlockValidation', {
                url: '/unlock-validation',
                templateUrl: 'app/components/efile/efileElements/unlock-validation.html',
                data:{},
                access:"",
                controller:"UnlockValidationController as ctrl"
            })
            .state('submissions-dasboard.submission.formElementsReport', {
                url: '/formelements-report',
                templateUrl: 'app/components/efile/efileElements/formelements-report.html',
                data:{},
                access:"",
                controller:"FormElementsReportController as ctrl"
            })
            .state('submissions-dasboard.submission.amendSupersededReturns', {
                url: '/amendsuperseded-returns',
                templateUrl: 'app/components/efile/efileElements/amendsuperseded-returns.html',
                data:{},
                access:"",
                controller:"AmendSupersededReturnsController as ctrl"
            })
            .state('submissions-dasboard.submission.efileScrubbing', {
                url: '/efile-scrubbing',
                templateUrl: 'app/components/efile/efileElements/efile-scrubbing.html',
                data:{},
                access:"",
                controller:"EfileScrubbingController as ctrl"
            })
            .state('submissions-dasboard.submission.keyXmlGen', {
                url: '/key-xml-gen',
                templateUrl: 'app/components/efile/efileElements/key-xml-gen.html',
                data:{},
                access:"",
                controller:"EfileKeyXmlGenController as ctrl"
            })
                
    }])

});
