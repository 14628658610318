/* Modified changes on 5/14/2019  */
/* Modified -- commented all settimeouts 1/30/2020 */

define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.dataFiltersService', [])

        .factory('FileSizeService', ['$log', function ($log) {

            return {
                readableFileSize: function (size) {
                    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                    var i = 0;
                    size = parseInt(size);
                    while (size >= 1024) {
                        size /= 1024;
                        ++i;
                    }
                    return size.toFixed(1) + ' ' + units[i];
                }

            };
        }])


        .factory('DataFiltersFactory', ['$rootScope', '$http', '$q', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', '$timeout', '$state', 'GENERAL_CONFIG', 'WorkflowService', function ($rootScope, $http, $q, AlertService, GlobalService, JsonObjectFactory, WorkspaceFactory, $timeout, $state, GENERAL_CONFIG, WorkflowService) {

            var dataFiltersFactory = {};
            var getHttpFilters = function (filter_group_key, filters) {
                var url = GENERAL_CONFIG.base_url + '/getFilters',
                    params = {
                        filter_group_key: filter_group_key
                    };
                return new Promise(function (resolve, reject) {
                    JsonObjectFactory.getJSONObj(url, params).then(function (data) {
                        if (data.callSuccess !== '1') {
                            AlertService.add("error", data.errorMessage, 4000);
                            filters.setloading(false);
                        } else {
                            GlobalService.setFilterGroup(data.filterGroup.filters);
                            filters.setFilterGroup(data.filterGroup);
                            setFilterValues(data.filterGroup).then(function (data1) {
                                filters.setFilters(data.filterGroup.filters);
                                /* creating the toggleFilters array 2/21/2021 */
                                var toggleFilters = _.filter(data.filterGroup.filters,{is_or_conditional_filter:true});
                                filters.setToggleFilterGroup(toggleFilters);

                                filters.setloading(false);
                                resolve(data);
                            });
                        }
                    }, reject);
                });
            }
            var getApiData = function (_url, filter, grp_key, params) {
                //var params = {};
                if (!params) params = {};
                _url = GENERAL_CONFIG.base_url + '/' + _url;
                return JsonObjectFactory.getJSONObj(_url, params, filter, grp_key).then(function (data) {
                    if (data.callSuccess !== '1') {
                        AlertService.add("error", "Error: Filter could not be loaded. ", 4000);
                        return false;
                    } else {
                        return data;
                    }
                });
            }
            var setFilterValues = function (_filters) {
                var params = {};
                //console.log("=========================================================_filters  ", _filters);

                return new Promise(function (resolve, reject) {
                    var apiCallFiltersList = [],
                        apiCallPromisesList = [];
                    //  set static and global data filter values
                    _.each(_filters.filters, function (filter, index) {
                        if (filter.data_type === 'inherit') {
                            filter.values = _.clone(GlobalService.inputs[filter.param_name].values);
                            var name = filter.value_name;
                            var findObj = {};

                            /* added 5/20/2019 */
                            if (WorkspaceFactory.activeScreen.commentLink && WorkspaceFactory.activeScreen.commentLink.filters &&
                                WorkspaceFactory.activeScreen.commentLink.filters.length > 0) {
                                // filter.selected ={};
                                var x = _.find(WorkspaceFactory.activeScreen.commentLink.filters, {
                                    name: filter.param_name
                                })
                                filter.selected = _.find(filter.values, function (item) {
                                    return item.value == x.value;
                                });
                                //console.log("filter databuoy", filter.selected);
                                params[filter.param_name] = filter.selected.value;
                                ////console.log("smalle", params);
                            }
                            /*  ************/
                            else {
                                findObj[name] = GlobalService.globalParams[filter.param_name];
                                filter.selected = _.find(filter.values, findObj);
                            }
                        } else if (filter.data_type === 'static' && filter.static_values && filter.static_values.length > 0) {
                            try {
                                filter.values = JSON.parse(filter.static_values);
                                // filter.selected = filter.values[0];
                                if (WorkspaceFactory.activeScreen.commentLink && WorkspaceFactory.activeScreen.commentLink.filters &&
                                    WorkspaceFactory.activeScreen.commentLink.filters.length > 0) {
                                    // filter.selected ={};
                                    var x = _.find(WorkspaceFactory.activeScreen.commentLink.filters, function (f) {
                                        return f.name == filter.param_name
                                    });
                                    filter.selected = _.find(filter.values, function (item) {
                                        return (item.VALUE || item.value) == x.value;
                                    });
                                    params[filter.param_name] = filter.selected.VALUE;
                                    //console.log("smalle", params);
                                } else {
                                    filter.selected = filter.values[0];
                                }
                            } catch (err) {
                                AlertService.add("error", "Error: filter not configured properly " + err + " ", 3000);
                            }
                        }
                        if (filter.data_type === 'load') {
                            if (WorkspaceFactory.activeScreen.commentLink && WorkspaceFactory.activeScreen.commentLink.filters &&
                                WorkspaceFactory.activeScreen.commentLink.filters.length > 0) {
                                var x = _.find(WorkspaceFactory.activeScreen.commentLink.filters, {
                                    name: filter.param_name
                                });
                                params[filter.param_name] = x.VALUE || x.value;
                            }
                            if(GlobalService.global_selected_me.fromGlobalME){ ///2/13/2022 
                                let hasParentMe = _.find(WorkspaceFactory.activeScreen.filters.filterGroup.filters,{param_name:'parent_me_string'});
                                if(hasParentMe != undefined){
                                    if (_.includes(filter.refresh_list, hasParentMe.filter_key)){
                                        params['parent_me_string'] =  _.findKey(GlobalService.global_selected_me.selected, 'value')?_.map(GlobalService.global_selected_me.selected, value).join(',') : _.map(GlobalService.global_selected_me.selected, 'parnet').join(',');
                                    }
                                }
                            } 
                            apiCallFiltersList.push(filter);
                        }
                    });
                    if (apiCallFiltersList.length === 0) {
                        resolve('fulfilled');
                    }
                    //calls API
                    //calls API 4/14/2020 all api are success then filters are loaded
                    if(apiCallFiltersList.length){
                        var apicallsCount=0;
                        var callApi=function(){
                            var filter=apiCallFiltersList[apicallsCount];
                            console.log('params',params);
                            callGetAPIData(null, filter, apicallsCount, _filters.filter_group_key, _filters.filters, params).then(function(res){
                                if(apiCallFiltersList.length-1!==apicallsCount){
                                    apicallsCount++;
                                    callApi();
                                } else {
                                    resolve('fulfilled');
                                }
                            });
                        }
                        callApi();
                    }
                    /* below code commented on 4/14/2020 have some isues timeout issue */
                    // _.each(apiCallFiltersList, function (filter, index) {
                    //     //CHECK IF REQUIRED FILTERS HAVE A VALUE. IF THEY ALL DO THEN RUN FILTER ON LOAD
                    //     var checkHasRequiredFilter = function (filter) {
                    //         var requiredFilter = null;
                    //         if (filter && filter.refresh_list && filter.refresh_list.length) {
                    //             var runFilter = true;
                    //             var refresh_array = filter.refresh_list.split(',');

                    //             for (var i = 0; i < refresh_array.length; i++) {
                    //                 requiredFilter = _.find(_filters.filters, {
                    //                     'filter_key': refresh_array[i]
                    //                 });
                    //                 //OLD: ---- var requiredFilter = _.find(  _filters.filters, { 'filter_key':  filter.refresh_list[i] });
                    //                 //  $rootScope.$broadcast("showLoader", true);
                    //                 if (typeof requiredFilter === 'undefined' || requiredFilter.selected === null ||
                    //                     _.isEmpty(requiredFilter.selected)) {
                    //                     runFilter = false;
                    //                 }
                    //             }
                    //             if (runFilter === false) {
                    //                 return requiredFilter;
                    //             }
                    //         }
                    //         return null;
                    //     }
                    //     //console.log("stataic filtesr", params);
                    //     if (checkHasRequiredFilter(filter) === null) {
                    //         if ((apiCallFiltersList.length - index + 1) >= 1) {
                    //             var nextFilterRequired = checkHasRequiredFilter(apiCallFiltersList[index + 1]);
                    //             if (nextFilterRequired) {
                    //                 setTimeout(function () {
                    //                     callGetAPIData(null, filter, index, _filters.filter_group_key, _filters.filters, params)
                    //                 }, 50);
                    //             }
                    //         }
                    //         //apiCallPromisesList.push(callGetAPIData(null, filter, index, _filters.filter_group_key, _filters.filters, params));//commented on 1/28/2020
                    //     }
                    //     apiCallPromisesList.push(callGetAPIData(null, filter, index, _filters.filter_group_key, _filters.filters, params));
                    // });

                    // $q.all(apiCallPromisesList).then(function (results) {
                    //     results.forEach(function (data, status, headers, config) {
                    //         //console.log("SUCCESS---->>>>>>>>>>", data, status, headers, config);
                    //     });
                    //     resolve('fulfilled');
                    // }, function (results) {
                    //     results.forEach(function (data, status, headers, config) {
                    //         //console.log("ERR---->>>>>>>>>>", data, status, headers, config);
                    //     });
                    //     reject(data);
                    // });
                });
            }

            function callGetAPIData(screen_key, filter, index, group_key, filters, params) {
                //////console.log("DO WE MAKE IT HERE runFilter " , runFilter)
                filter.loading = true;
                return getApiData(filter.data_url, filter, group_key, params).then(function (data) {
                    filter.values = data.jsonObject;
                    //   $rootScope.$broadcast('dataFilters:filtersLoaded', filter.values);
                    if (filter.default_value) {
                        try {
                            filter.values.unshift(JSON.parse(filter.default_value));
                        } catch (err) {
                            //////console.error(err);
                        }
                    } else {
                        if (filter.ui_type === "df-select" && data.jsonObject && data.jsonObject.length) {

                                filter.selected = data.jsonObject[0];
                                if(filter.param_name === 'scenario' && !GlobalService.isDrillDown ) {
                                   var model = filter.values.filter(i => parseInt(i.VALUE) === parseInt(GlobalService.getScenario(GlobalService.globalParams.tax_year)))[0];
                                    if(model != null) {
                                        if(filter.selected){
                                            filter.selected  = model;
                                        } else {
                                            filter.selected = data.jsonObject[0];
                                        }
                                    }
                                }
        
                            /* 4/14/2020 */
                            if(filter && filter.param_name!=undefined && params!=undefined){
                                params[filter.param_name] = filter.selected.VALUE||filter.selected.value;
                            }
                        }
                    }
                    filter.loading = false;
                    dataFiltersFactory.onChange(filter, group_key, screen_key, null);
                });
            }

            function filterObj(_showFilters, _required, _loading, _splitCallParam) {
                this.filterGroup = {};
                this.filters = [];
                this.toggleFilterGroup = [];
                this.toggleSelectedFilter = [];
                this.filterParams = {};
                this.showFilters = _showFilters;
                this.required = _required;
                this.loading = _loading;
                this.splitCallParam = _splitCallParam;
                this.loaded = false;
                this.formatValuesOnlod = false;

                if (!(this instanceof filterObj)) {
                    return new filterObj(_filters, _showFilters, _required, _splitCallParam);
                }

                this.getLoaded = function () {
                    return this.loaded;
                }

                this.setLoaded = function (_loaded) {
                    this.loaded = _loaded;
                }


                this.getFilters = function () {
                    return this.filters;
                }
                this.getFilterGroup = function(){
                    return this.filterGroup;
                }

                this.setFilterGroup = function (_filterGroup) {
                    this.filterGroup = _filterGroup;
                }
                this.setFilters = function (_filters) {
                    this.filters = _filters;
                    this.setFilterParams();
                }

                this.getToggleFilterGroup = function(){
                    return this.toggleFilterGroup;
                }
                this.getSelectedToggleFilter = function(){
                    return this.toggleSelectedFilter;
                }

                this.setToggleFilterGroup = function(_toggleFilterGroup){
                    this.toggleFilterGroup = _toggleFilterGroup;     
                    this.toggleSelectedFilter = _.filter(this.toggleFilterGroup,{filter_key:this.filterParams.filter_by});               
                }

                this.getShowFilters = function () {
                    return this.showFilters;
                }

                this.setShowFilters = function (_showFilters) {
                    this.showFilters = _showFilters;
                }

                this.setloading = function (_boolean) {
                    this.loading = _boolean;
                }


                this.getRequired = function () {
                    return this.required;
                }

                this.setRequired = function (_required) {
                    this.required = _required;
                }


                this.getFilterParams = function () {
                    //  this.filters = [];
                    this.filterParams = {};
                    this.setFilterParams();
                    return this.filterParams;
                }

                this.setFilterParams = function () {
                    var that = this;
                    _.each(this.filters, function (filter) {
                        var value = filter.value_name;
                        if (filter.selected ) {
                            // if(filter.multiple === 1 && that.splitCallParam === filter.param_name  && _.isArray(filter.selected)) {
                            if (filter.multiple === 1 && that.splitCallParam === filter.param_name) {
                                ////////console.log("filter.selected ========== : " , filter.selected);
                                that.filterParams[filter.param_name] = filter.selected.indexOf(',') > -1 ? filter.selected.join() : filter.selected;
                            } else if (filter.multiple === 1) {
                                if (WorkspaceFactory.activeScreen.commentLink && (filter.ui_type.trim() === "df-select-multiple" 
                                || filter.ui_type.trim() === "df-select-single") || filter.ui_type.trim() === 'df-select-virtual-scroll') {
                                    var selected = [];
                                    var selectedCopy = [];
                                    _.forEach(filter.selected, function (value, key) {
                                        var value = _.isObject(value) ? value.VALUE || value.value : value;
                                        if (value !=undefined &&!isNaN(value)) {
                                            selected.push(parseInt(value));
                                            selectedCopy.push(value);
                                        }
                                    });
                                    if (selected.length) {
                                        filter.selected = [];
                                        _.forEach(filter.values, function (value, key) {
                                            if ((_.includes(selected, value[filter.value_name])) || (_.includes(selectedCopy, value[filter.value_name]))) {
                                                filter.selected.push(value);
                                            }
                                        });
                                    }
                               
                                } else {
                                    that.filterParams[filter.param_name] =  filter.selected && filter.selected.indexOf(',') > -1 ? filter.selected.join() : filter.selected;
                                }
                                if (filter.selected.length && filter.selected[0] != undefined && _.isObject(filter.selected[0])) {
                                  //  that.filterParams[filter.param_name] = (dataFiltersFactory.meVar == 0 || !dataFiltersFactory.meVar) ? _.map(filter.selected, value).join(',') : _.map(filter.selected, 'parnet').join(','); // Added this check to check the parameters('parent_me_string') is been passed from global me..
                                  // 4/23/2020
                                  /* if(dataFiltersFactory.meVar == 0 || !dataFiltersFactory.meVar && filter.param_name == 'parent_me_string'){
                                        that.filterParams[filter.param_name] =  _.findKey(filter.selected, 'value')?_.map(filter.selected, value).join(',') : _.map(filter.selected, 'parnet').join(',')// _.map(filter.selected, 'parnet').join(',');
                                    }else if(dataFiltersFactory.meVar == 0 || dataFiltersFactory.meVar && filter.param_name == 'parent_me_string'){
                                        that.filterParams[filter.param_name] = _.map(filter.selected, 'parnet').join(',');
                                    }else{
                                        that.filterParams[filter.param_name] =  _.map(filter.selected, value).join(',')
                                    } */
                                    if(filter.param_name == 'parent_me_string'){
                                        that.filterParams[filter.param_name] =  _.findKey(filter.selected, 'value')?_.map(filter.selected, value).join(',') : _.map(filter.selected, 'parnet').join(',')// _.map(filter.selected, 'parnet').join(',');
                                    }
                                    else{
                                        that.filterParams[filter.param_name] =  _.findKey(filter.selected, 'value')?_.map(filter.selected, value).join(',') : _.findKey(filter.selected, 'VALUE')?_.map(filter.selected, 'VALUE').join(','):'';//_.map(filter.selected, value).join(',')
                                    }

                                } else if (filter.selected.length>1) {
                                    that.filterParams[filter.param_name] = _.isArray(filter.selected) ? filter.selected.join(',') : filter.selected;
                                }else if(filter.selected.length == 1){
                                    if(filter.selected[0] != undefined){
                                        that.filterParams[filter.param_name] = _.isArray(filter.selected) ? filter.selected.join(',') : filter.selected;
                                    }else{
                                        filter.selected = [];
                                    }
                                   
                                }
                            } else {
                                that.filterParams[filter.param_name] = filter.selected[value];
                            }
                        } else {
                            filter.selected = [];
                        }
                    });
                    return true;
                }

                ////////////////////////////////////////////////////////////////////////////////////////////
                //getFilterParamsFromCache : Gets the Filter that as set with the cached data on the screen. Is used for messaging.
                // We use the cached data instead og the current filter in case user has changed the filters but not reloaded the grid
                ////////////////////////////////////////////////////////////////////////////////////////////

                this.getFilterParamsFromCache = function () {
                    var that = this;
                    var filterArr = [];
                    _.each(this.filters, function (filter) {
                        var value = filter.value_name;
                        var curFilter = "";

                        var filterObj = {};
                        filterObj.filter_key = filter.filter_key;
                        filterObj.name = filter.param_name;
                        filterObj.value = filter.selected.value //""
                        curFilter = _.find(WorkspaceFactory.activeScreen.dataCurFilters, {
                            'filter_key': filter.filter_key
                        });




                        if (filter.multiple === 1 && that.splitCallParam === filter.param_name) {
                            if (curFilter && curFilter.value) {
                                filterObj.value = curFilter.value.join();
                            }
                        } else if (filter.multiple === 1) {

                            if (curFilter && curFilter.value && _.isArray(curFilter.value) && curFilter.value.length) {
                                if (_.isObject(curFilter.value[0])) {
                                    filterObj.value = _.map(curFilter.value, value).join(',');
                                } else {
                                    filterObj.value = _.map(curFilter.value).join(',');
                                }
                            }
                        } else {


                            //Heath Stein 11/4/2019
                            // old code:   if (curFilter && curFilter.value ) {  // with this check 0 is evaluated as false
                            if (curFilter && typeof curFilter.value !== "undefined" && curFilter.value !== null) {
                                filterObj.value = curFilter.value;
                            }
                        }

                        filterArr.push(filterObj);
                    });


                    return filterArr;



                }
                this.toggleFilters = function () {
                    this.setShowFilters(!this.getShowFilters());
                }

                this.checkDataRefresh = function (_filter, group_key, screen_key, filterList, _filters) {
                    var that = this;
                    var params = {};
                    // if (screen_key && filterList) { // added by divya - batch import on COA mapping 7/15/2019
                    if (screen_key && filterList) {
                        that = {
                            filters: filterList
                        };
                    }
                    return new Promise(function (resolve, reject) {
                        var filter_id = _filter.filter_key;
                        var sfilters = [];
                        var filterss = (that.filters && that.filters.length) ? that.filters : _filters
                        _.each(filterss ? filterss : [], function (filter, index) {
                            if (filter.data_type === 'load' && filter.refresh_list && filter.refresh_list.length > 0) {
                                var refresh_list = filter.refresh_list.split(',');
                                if (_.includes(refresh_list, filter_id)) {
                                    filter.loading = true;
                                    //   if (!screen_key && !filterList) { //added by divya- if filter has selected object in other screens,dont clear selected after batch import on COA mapping 7/15/2019
                                    if (!screen_key && !filterList) {
                                        filter.selected = null;
                                    }
                                    /* commented  below api call on 2/23/2020 dvalaboju001 because its causing multiple api calls which is not requried when we change the filter */
                                    // getApiData(filter.data_url, filter, group_key, params).then(function (data) {
                                    //     filter.values = data.jsonObject;
                                    //     //$rootScope.$broadcast('dataFilters:filtersLoaded',filter.values);/* added Divay */
                                    //     filter.loading = false;
                                    // });
                                    // null, filter, apicallsCount, _filters.filter_group_key, _filters.filters, params
                                    sfilters.push(callGetAPIData(screen_key, filter, index, group_key, that.filters));
                                }
                            }
                        });
                        if (sfilters.length) {
                            resolve('fulfilled');
                        } else {
                            $q.all(sfilters).then(function (results) {
                                results.forEach(function (data, status, headers, config) {
                                    //console.log("checkDataRefreshd SUCCESS---->>>>>>>>>>", data, status, headers, config);
                                });
                                resolve('fulfilled');
                            }, function (results) {
                                results.forEach(function (data, status, headers, config) {
                                    //console.log("checkDataRefreshd ERR---->>>>>>>>>>", data, status, headers, config);
                                });
                                reject(data);
                            });
                        }
                    });
                }
                /////////////////////////////////// NEW CODE ///////////////////////////////////////////////
                this.setCopyValue = function (filter, copyVal) {
                    var changed = false;
                    var thisKey = filter.value_name;
                    if (filter.multiple) {
                        var currentVal = filter.selected;
                        var newVal = _.isString(copyVal.value) && copyVal.value.indexOf(',') > -1 ? copyVal.value.split(",") : copyVal.value;
                        if ((currentVal && newVal) && (currentVal.length && newVal.length) && _.isEqual(currentVal, newVal)) {
                            if (_.isArray(currentVal) && _.isArray(newVal)) {
                                currentVal.sort();
                                newVal.sort();
                            }
                            ////console.log(" WE ARE THE SAME ================================" );
                        } else if (newVal  ) {
                            /* 1/22/2020 Divya */
                            if(_.isArray(newVal)){
                                filter.selected = [];
                                if (filter.param_name != 'parent_me_string') {
                                    _.find(filter.values, function (item) {
                                        _.each(newVal, function (itemVal) {
                                            if (item.VALUE == itemVal) {
                                                filter.selected.push(item);
                                            }
                                        })
                                    })
                                } else {
                                    filter.selected = newVal;
                                }
                                changed = true;
                            }
                            
                            //console.log("filter selected",filter.selected);
                            else{
                                    var setObj = _.find(filter.values, function (item) {
                                        if (item.VALUE == newVal) {
                                            return item;
                                        }
                                    })
                                    filter.selected = [];
                                    var meSelectedList = [];
                                    /* if setObj is undefined and active screen has commentLink */
                                    if (setObj == undefined && WorkspaceFactory.activeScreen.commentLink && filter.param_name == 'parent_me_string') {
                                        var itechildren = function (item) {
                                            _.each(item, function (value) {
                                                if (value.key == newVal) {
                                                    meSelectedList.push(value.key);
                                                }
                                                if (value.children) {
                                                    itechildren(value.children)
                                                }
                                            })
                                        };
                                        itechildren(filter.values);
                                        if (filter.selected != setObj) {
                                            filter.selected = meSelectedList;
                                            changed = true;
                                        }
                                    } else {
                                        if (filter.selected != setObj) {
                                            filter.selected.push(setObj);
                                            changed = true;
                                        }
                                    }
                                }
                        } else if(newVal == undefined || newVal == ''){
                            filter.selected = [];
                            changed = true;
                        }
                    } else {
                        var objKey = {};
                        objKey[thisKey] = copyVal[filter.value_name] || copyVal[filter.value_name.toLowerCase()];
                        if (objKey[thisKey] === undefined) {
                            objKey[thisKey] = copyVal.value;
                        }
                        var setObj = _.find(filter.values, objKey);
                        if (filter.selected != setObj) {
                            filter.selected = setObj;
                            changed = true;
                        }
                    }
                    return changed;
                }
                /////////////////////////////////// NEW CODE ///////////////////////////////////////////////
                this.setMultipleValues = function (data) {
                    _.each(data, function (node) {
                        ////console.log("node : " , node);
                    });
                }
                // screen_key,filterList,filter, group_key
                this.setFiltersToValue = function (_valueArr) {
                    //console.log("=========================================================_valueArr  ", _valueArr);
                    var changedArray = [];
                    var that = this;
                    var ajaxCalls = {
                        called: 0,
                        returned: 0,
                    };
                    var processFilter = function (filter) {
                        var copyVal = _.find(_valueArr, {
                            'filter_key': filter.filter_key
                        });
                        if (copyVal) {
                            var changed = that.setCopyValue(filter, copyVal);
                            if (changed) {
                                changedArray.push(filter.filter_key);
                            }
                        }
                    }
                    _.each(this.filters, function (filter) {
                        var params = {};
                        var refresh = false;
                        if (filter.refresh_list) {
                            var refreshArray = filter.refresh_list.split(",");
                            _.each(refreshArray, function (value) {
                                if (changedArray.indexOf(value) > -1) {
                                    refresh = true;
                                }
                            });
                            if (refresh && filter.data_type === "load") {
                                filter.loading = true;
                                ajaxCalls.called++;
                                getApiData(filter.data_url, params).then(function (data) {
                                    filter.values = data.jsonObject;
                                    // $rootScope.$broadcast('dataFilters:filtersLoaded', filter.values);
                                    filter.loading = false;
                                    var copyVal = _.find((_valueArr || _valueArr.filters), {
                                        'filter_key': filter.filter_key
                                    })
                                    filter.selected = (copyVal != null && copyVal != undefined) ? copyVal : filter.values[0];
                                    processFilter(filter);
                                    //console.log("================================== filter : ", filter);
                                    ajaxCalls.returned++;
                                    if (ajaxCalls.called === ajaxCalls.returned) {
                                        // $rootScope.$broadcast('dataFilters:filtersLoaded', {
                                        //     "refresh": true
                                        // });
                                        $rootScope.$broadcast("dataFilters:dataBuoyLoad", true);
                                      
                                    }
                                });
                            }
                        }
                        processFilter(filter); /* 5/2/2018 */
                    });

                    if (ajaxCalls.called === 0 && ajaxCalls.called === ajaxCalls.returned) {
                        
                      $rootScope.$broadcast("dataFilters:dataBuoyLoad", true);
                            
                    }
                }
                /////////////////////////////////// NEW CODE ///////////////////////////////////////////////
                this.checkRequiredFilters = function () {
                    //////console.log(" =============== checkRequiredFilters =================");
                    var returnVal = true;
                    /**filterGroup = 905
                     * 902--  
                     * 582-- either_Filters
                     */
                    var eitherFilter = [];
                    var either = this.getFilterGroup();
                    var checkEitherFilter = either.filters_or_group_setup;//"[902,582]";
                    _.each(this.filters, function (filter) {
                        if(checkEitherFilter!=null){
                            var x = JSON.parse(checkEitherFilter);
                            if(x && x.length>0){
                                _.each(x,function(i){
                                    if(i == filter.filter_key){
                                        if (typeof filter.selected == "undefined" || _.isEmpty(filter.selected) || filter.selected.length <= 0) {
    
                                           eitherFilter.push({"label":filter.label,"isSlected":false})
                                            filter.required =0;
                                        }else{
                                            eitherFilter.push({"label":filter.label,"isSlected":true})
                                            filter.required = 0;
                                        }
                                    }
                                })
                            }
                        }
                     
                        
                        if (filter.required && filter.required === 1) {
                            if (filter.multiple === 1) {
                                if (typeof filter.selected == "undefined" || _.isEmpty(filter.selected) || filter.selected.length <= 0) {
                                    AlertService.add("warning", filter.label + " is required. Please select a value", 3000);
                                    returnVal = false;
                                }
                            } else if (typeof filter.selected == "undefined" || _.isEmpty(filter.selected) || filter.selected[filter.value_name] === "" || filter.selected.length ==0) {
                                AlertService.add("warning", filter.label + " is required. Please select a value", 3000);
                                returnVal = false;
                            }
                        }
                        
                    });
                    if(eitherFilter && !_.isEmpty(eitherFilter)){
                        var lableee = "";
                        var isFound = false;
                        _.each(eitherFilter,function(it){
                            if(lableee == ""){
                                lableee += it.label;
                            }else{
                                lableee += ' (OR) ' + it.label;
                            }
                            
                        });
                        
                        
                        if(_.filter(eitherFilter,function(i){return i.isSlected}).length>0){
                            returnVal = true;
                        }else{
                            AlertService.add("warning",lableee + " is required. Please select a value", 3000);
                            returnVal = false;
                        }
                        
                    }

                    return returnVal;
                }

            }
            //endOf filterObj Class

            // stores the me selection directive boolean values in service level..
            dataFiltersFactory.meVar = false;
            dataFiltersFactory.setGlobalMeVar = function (globalMeVar) {
                dataFiltersFactory.meVar = globalMeVar;
            }
            function unselectedMeFilter(filter){
           
                var selected = "";
                if(filter.selected && filter.selected.length){
                    selected = _.find(filter.selected, 'VALUE') !=undefined?_.map(filter.selected, 'VALUE').join(','):filter.selected.join();
                } 

                var iterateChildren = function (item) {
                    _.each(item, function (value) {
                        if(selected.indexOf(value.key)>-1){
                            if(value.selected){
                                value.active = false ;
                                value.selected = value.active;
                                value.check = value.active;
                            }
                            if(value.numSelected) {
                                value.numSelected = 0;
                            }
                        }
                        if (value.children) {
                            if(value.numSelected) {
                                value.numSelected = 0;
                            }
                            iterateChildren(value.children);
                        }
                        if(item.numSelected){
                            item.numSelected = 0;
                        }
                    });
                }
                if (!_.isEmpty(selected) && selected !=null) {
                    iterateChildren(filter.values);
                }
           
            }
            function unselectFilter (filter){
                if(filter && filter.selected && filter.selected.length){
                    _.each(filter.values,function(i){
                        if(i.isChecked){
                            i.isChecked =false;
                        }
                    });
                }
            }
            dataFiltersFactory.onChange = function (_filter, group_key, screensArray, filters) {
                if(WorkspaceFactory.activeScreen.filters && WorkspaceFactory.activeScreen.filters.filterParams){ // used to check the screen level all filters exisitance - null check - Divya findings
                    //Used to set global level selected Me's to active screen Me's..
                    if((GlobalService.inputs.tax_year.selected.label == WorkspaceFactory.activeScreen.filters.filterParams.tax_year) && 
                    (GlobalService.inputs.tax_year.selected.scenario == WorkspaceFactory.activeScreen.filters.filterParams.scenario)){
                        if (_filter && _filter.values && _filter.values.length > 0 && WorkspaceFactory.activeScreen.filters && dataFiltersFactory.meVar == true) {
                            var findPMStr = _.find(WorkspaceFactory.activeScreen.filters.filters, {
                                param_name: 'parent_me_string'
                            });
                            if ((!_.isEmpty(findPMStr) && _filter.param_name == 'parent_me_string')) {
                                     var globalMes = GlobalService.global_selected_me;

                                    $rootScope.$broadcast("leftMenuMes", globalMes);
                                    if(_filter.fromGlobalME){
                                        _filter = findPMStr;
                                    }else if(GlobalService.global_selected_me.selected.length == 0){
                                        _filter.filter_key = findPMStr.filter_key;
                                    }
                            }
                                
                            }
                    }
                    /* if condition is only for COA screens-- added by divya 7/13/2019 -- else condition for existing flow */
                    if (screensArray && screensArray.length > 0 && _filter && _filter.filters && _filter.filters.length > 0) {
                        for (var j = 0; j < _filter.filters.length; j++) {
                            var filter = _filter.filters[j];
                            for (var i = 0; i < screensArray.length; i++) {
                                if (screensArray && screensArray[i].filters) {
                                    screensArray[i].filters.checkDataRefresh(filter, group_key, null, screensArray[i].filters)
                                    setTimeout(function () {
                                        $rootScope.$broadcast('filter:updated', _filter);
                                        $rootScope.$apply();
                                    }, 10);
                                }
                            }
                        }
                    } else if (WorkspaceFactory.activeScreen && WorkspaceFactory.activeScreen.filters) {
                            var checkEitherFilter = WorkspaceFactory.activeScreen.filters.filterGroup.filters_or_group_setup;//"[902,582]";
                           if(checkEitherFilter){
                            var x = JSON.parse(checkEitherFilter);
                            if(x && x.length>0){
                                if(_filter && _filter.param_name=='filter_by'){
                                    var xy =  _.find(WorkspaceFactory.activeScreen.filters.filters,{param_name:'filter_by'});
                                    function resetTheFilter(isFilterFound){
                                        var hasSelectedValues = _.find(WorkspaceFactory.activeScreen.filters.filters,{filter_key:isFilterFound})
                                        if(isFilterFound && !_.isEmpty(hasSelectedValues.selected)){
                                                _.each(WorkspaceFactory.activeScreen.filters.filters,function(z){
                                                    if(z && z.filter_key == isFilterFound){
                                                        if(z.param_name == 'parent_me_string'){
                                                            unselectedMeFilter(z);
                                                        }
                                                        else if(z.ui_type == 'df-select-single' || 'df-select-multiple' || 'df-select-virtual-scroll'){
                                                            unselectFilter(z);
                                                        }
                                                        z.selected = [];
                                                    }
                                                });
                                            }
                                    }
                                    _.each(x,function(i){
                                        if(i != xy.selected.VALUE){
                                            resetTheFilter(i);
                                        }
                                    });
                                }
                           }
                        }

                            WorkspaceFactory.activeScreen.filters.checkDataRefresh(_filter, group_key, null, null, filters).then(function () {
                            $rootScope.$broadcast('filter:updated', _filter);
                        });
                    } else if (WorkflowService.allowGlobalMes == true) {
                        $rootScope.$broadcast("leftMenuMes", _filter);
                    }
                }
            }

            //dataFiltersFactory.onLoad = function (screen_key, filters) {
            dataFiltersFactory.onLoad = function () {
                if (typeof WorkspaceFactory.activeScreen.filters == "undefined") {
                    //ACTIVITY LEVEL FILTERS: Copy existing record.
                    //If filter_inherit === 1 then copy existing filters of open screen in this activity
                    if (WorkspaceFactory.activeScreen && WorkspaceFactory.activeScreen.filter_inherit === 1) {
                        var parent = WorkspaceFactory.activeScreen.parent;
                        var filter_key = WorkspaceFactory.activeScreen.filter_key;
                        for (var i = 0; i < parent.screens.length; i++) {
                            var screen = parent.screens[i];
                            if (screen.filters && filter_key === screen.filter_key) {
                                WorkspaceFactory.activeScreen.filters = screen.filters;
                                return true;
                            }
                        }
                    }
                    //SCREEN LEVEL FILTERS: create new record.
                    var splitCallParam = (WorkspaceFactory.activeScreen.split_call_param) ? WorkspaceFactory.activeScreen.split_call_param : null;
                    var filters = new filterObj(true, true, false, splitCallParam);
                    WorkspaceFactory.activeScreen.filters = filters;
                    if (WorkspaceFactory.activeScreen.filter_key) {
                        filters.setloading(true);
                        getHttpFilters(WorkspaceFactory.activeScreen.filter_key, filters).then(function (data) {

                            setTimeout(function () {
                                filters.setLoaded(true);
                                $rootScope.$apply();
                            });
                            if (!WorkspaceFactory.activeScreen.commentLink) {
                                setTimeout(function () {
                                    if (GlobalService.global_selected_me.values.length > 0) {
                                        if((GlobalService.inputs.tax_year.selected.label == WorkspaceFactory.activeScreen.filters.filterParams.tax_year) && 
                                        (GlobalService.inputs.tax_year.selected.scenario == WorkspaceFactory.activeScreen.filters.filterParams.scenario)){

                                            dataFiltersFactory.meVar == true;
                                            $rootScope.$broadcast("leftMenuMes", GlobalService.global_selected_me);
                                            $rootScope.$apply();
                                        }
                                       
                                    }
                                }, 1000);
                            }
                            //console.log("------------------------------ FILTERS LOADED------------------------");
                            /********************************* THIS IS WHERE WE CALL SET FILTERS FROM A COMMENT LINK (DATA BUOY) ****************/
                            if (WorkspaceFactory.activeScreen.commentLink) {
                                WorkspaceFactory.activeScreen.filters.setFiltersToValue(WorkspaceFactory.activeScreen.commentLink.filters);
                           
                            }
                        });
                    }
                    // IF WE HAVE A LINK THEN LOADED IT
                } else if (WorkspaceFactory.activeScreen.commentLink) {
                    /********************************* THIS IS WHERE WE CALL SET FILTERS FROM A COMMENT LINK (DATA BUOY) ****************/
                    WorkspaceFactory.activeScreen.filters.setFiltersToValue(WorkspaceFactory.activeScreen.commentLink.filters);
                }
                // This will check if multiple screens are already loaded, then assingning the Global level selected Me's value list to its dropdown..
                // else if(GlobalService.global_selected_me && GlobalService.global_selected_me.values.length > 0) {
                //     var globalMes = GlobalService.global_selected_me;
                //     $rootScope.$broadcast("leftMenuMes", globalMes);
                // };
                return true;
            }

            dataFiltersFactory.searchService  = function(arr, txt){
                var params =  _.extend({stage_keys :arr});
                params.search_text = txt;
                var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=o6zwbh',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

           
            return dataFiltersFactory;
        }])


    return services;

});