define([
    'cronstrue',
    'angular'
], function (cronstrue) {
    'use strict';
    var controllers = angular.module('app.goldSyncCtrl', []).controller('goldSyncCtrl', ['$rootScope', '$scope',
        'AlertService', '$timeout', '$uibModal', 'USER_SETTINGS', 'GoldSyncFactory', 'GENERAL_CONFIG',
        'DTOptionsBuilder', 'MessageService', goldSyncCtrl]);

    function goldSyncCtrl($rootScope, $scope, AlertService, $timeout, $uibModal, USER_SETTINGS,
        GoldSyncFactory, GENERAL_CONFIG, DTOptionsBuilder, MessageService) {
        // multi-select dropdown configurations
        $scope.selectedTaxYears = [];
        $scope.selectedGroupType = [];
        $scope.selectedScenario = [];
        $scope.openTaxYears = [];
        $scope.groupTypes = [];
        $scope.openScenarios = [];
        $scope.selectedTY = "";
        $scope.groupTypeSelected = "";
        $scope.enableScenarioSelection = false;
        $scope.enableTaxYearSelection = false;
        $scope.openScenariosByTaxYear = [];
        $scope.mulSelectCustomTexts = {
            buttonDefaultText: 'Select open tax year'
        };

        $scope.scenarioMulSelectCustomTexts = {
            buttonDefaultText: 'Select open scenario',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };
        $scope.taxYearsDropdownSettings = {
            smartButtonMaxItems: 3,
            enableSearch: true,
            buttonClasses: 'mul-select-btn',
            scrollable: true,
            scrollableHeight: 250,
            displayProp: 'tax_year',
            closeDropDownOnSelection: true,
            singleSelection: true,
            limitSelection: 1
        };

        $scope.scenariosDropdownSettings = {
            smartButtonMaxItems: 5,
            enableSearch: true,
            buttonClasses: 'mul-select-btn',
            scrollable: true,
            scrollableHeight: 250,
            displayProp: 'scenario_desc'
        };

        $scope.groupTypeChanged = function (grpTypeObj) {
            $scope.groupTypeSelected = grpTypeObj.grp_type;
            if ($scope.groupTypeSelected === 'GOLD') {
                $scope.enableScenarioSelection = true;
                $scope.enableTaxYearSelection = true;
                $scope.openScenarios = [];
                if ($scope.selectedTY && $scope.selectedTY.length > 0) {
                    const tyObj = { tax_year: $scope.selectedTY };
                    $scope.taxYearChanged(tyObj);
                }
            } else if ($scope.groupTypeSelected === 'EO_NATIVE') {
                $scope.enableScenarioSelection = false;
                $scope.enableTaxYearSelection = false;
            }
            else {
                $scope.enableScenarioSelection = false;
                $scope.enableTaxYearSelection = true;
            }

            $scope.goldSyncData = $scope.goldSyncDataCopy.filter(i => i.grp_type === grpTypeObj.grp_type);
        }

        $scope.taxYearChanged = function (tyObj) {
            $scope.selectedTY = tyObj.tax_year;
            if ($scope.groupTypeSelected === 'GOLD') {
                $scope.enableScenarioSelection = true;
                $scope.selectedScenario = [];
                $scope.openScenarios = $scope.openScenariosByTaxYear[$scope.selectedTY];
            } else {
                $scope.enableScenarioSelection = false;
            }
        }

        // datatable configurations
        $scope.goldSyncData = [];
        $scope.goldSyncDataCopy = [];
        $scope.goldSyncDataLoading = false;
        // on noticeable slowdown of the table performance when size of the table
        // grows significantly, use ajax/promise to fetch data instead of the 'Angular way',
        // see http://l-lin.github.io/angular-datatables/archives/#!/angularWay
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('paging', true)
            .withOption('pageLength', 10)
            .withOption('lengthMenu', [10, 25, 50, 100])
            .withOption('rowReorder', true)
            .withOption('processing', true)
            .withOption('autoWidth', false)
            .withOption('search', { 'smart': true })
            .withOption('info', true);
        $scope.dtColumnDefs = [
            { orderable: true, className: 'reorder', targets: 0 },
            { orderable: false, targets: '_all' }
        ];
        $scope.dtInstance = {};
        $scope.rowEditorEnabled = null;
        $scope.readableCron = 'Enter a cron expression in the box!';
        $scope.disableSyncAll = true;
        $scope.isSelectAll = false;
        $scope.srcTypeMappings = [{ 'fromData': 'API', 'detail': 'API' }, { 'fromData': 'DS', 'detail': 'Data Source' }];
        $scope.grpTypeMappings = ['GOLD', 'CARS', 'EO_NATIVE'];
        $scope.loadData = function () {
            $scope.goldSyncJobs = [];
            $scope.selectedTaxYears = [];
            $scope.selectedGroupType = [];
            $scope.selectedScenario = [];
            $scope.selectedTY = "";
            $scope.groupTypeSelected = "";
            $scope.enableScenarioSelection = false;
            $scope.groupTypes = [];
            $scope.openScenarios = [];
            $scope.openScenariosByTaxYear = [];
            $scope.goldSyncTaxYears = [];
            $scope.goldSyncDataLoading = true;
            GoldSyncFactory.getGoldSyncApiDetails().then((response) => {
                if (response.data.callSuccess === '0') {
                    throw new Error(response.data.errorMessage);
                }
                $scope.goldSyncData = response.data.goldSyncUI.goldSyncApiDetails;
                $scope.goldSyncDataCopy = response.data.goldSyncUI.goldSyncApiDetails;
                $scope.openTaxYears = response.data.goldSyncUI.goldSyncOpenTaxYear;
                $scope.openScenariosByTaxYear = response.data.goldSyncUI.scenariosByTaxYear;
                $scope.goldSyncData.forEach((data) => {
                    $scope.checkCron(data, true);
                    data.selectedGrpType = data.grp_type;
                    $scope.srcTypeMappings.forEach((srcType) => {
                        if (srcType.fromData === data.src_type) {
                            data.selectedSrcType = srcType;
                            data.srcType = srcType;
                            if (data.src_type === 'DS') {
                                data.noSrcQuery = data.src_query === null || data.src_query === undefined || data.src_query === '';
                                // truncate the query if it exceeds 100 characters
                                data.src_details = data.src_query_trunc = truncateQuery(data);
                            } else {
                                data.src_details = data.api_url;
                            }
                        }
                    });
                    $scope.goldSyncJobs.push({ 'job_id': data.job_id, 'job_name': data.dest_tbl_name });
                    // $scope.groupTypes.push({'grp_type': data.selectedGrpType});
                    $scope.groupTypes.push({ 'grp_type': data.selectedGrpType });
                });
                $scope.openTaxYears.forEach((year) => {
                    $scope.goldSyncTaxYears.push({ 'tax_year': year.tax_year });
                });
                $scope.groupTypes = $scope.groupTypes.filter((value, index, arr) => arr.findIndex(t => (t.grp_type === value.grp_type)) === index);
                $scope.groupTypes.sort();
                $timeout(() => {
                    $scope.goldSyncDataLoading = false;
                }, 100);
            }).catch((err) => {
                $scope.goldSyncData = [];
                $scope.goldSyncDataCopy = [];
                $scope.openTaxYears = [];
                $scope.selectedScenario = [];
                $scope.groupTypes = [];
                $scope.openScenarios = [];
                $scope.enableScenarioSelection = false;
                $scope.openScenariosByTaxYear = [];
                $timeout(() => {
                    $scope.goldSyncDataLoading = false;
                }, 100);
                const errMsg = err.toString().substring(0, 5) === 'Error' ? err.toString() : 'Something went wrong when fetching table data!';
                AlertService.add('error', errMsg);
            });
        };
        $scope.loadData();

        function truncateQuery(row) {
            if (row.src_query.length <= 100) return row.src_query;
            row.showTruncatedSrcQuery = true;
            row.showMoreNLess = true;
            return row.src_query.substring(0, 101).concat('...');
        }

        $scope.onTableNameClick = function (tableName) {
            let modalObj = {};
            modalObj.template = 'app/components/admin/goldSync/templates/show-table-details-model.html';
            modalObj.size = 'sm';
            modalObj.controller = ['$scope', function ($scope) {
                $scope.tableName = tableName;
                $scope.dataLoading = true;
                GoldSyncFactory.getGoldSyncObjectDataCount($scope.tableName).then((response) => {
                    if (response.data.callSuccess) {
                        $scope.displayTaxYear = response.data.displayTaxYear === undefined ? true : response.data.displayTaxYear;
                        $scope.objectDataCount = response.data.goldSyncObjDataCount || [];
                        // reorder data by tax_year
                        $scope.objectDataCount.sort((a, b) => (parseInt(a.tax_year) > parseInt(b.tax_year)) ? -1 : 1);
                        // display simple layout when tax_year is not available
                        if ($scope.objectDataCount.length === 1 && $scope.objectDataCount[0].tax_year === null) {
                            $scope.simpleLayout = true;
                        }
                        $scope.dataLoading = false;
                    } else {
                        throw new Error('callSuccess 0');
                    }
                }).catch((err) => {
                    $scope.objectDataCount = [];
                    $scope.dataLoading = false;
                });
            }];

            $scope.openModal(modalObj);
        };

        $scope.openModal = function (modalObj) {
            $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controller,
                size: modalObj.size
            });
        };

        $scope.srcTypeChanged = function (row) {
            if (row.selectedSrcType.fromData === 'DS') {
                row.noSrcQuery = row.src_query === null || row.src_query === undefined || row.src_query.trim() === '';
                row.src_details = row.showTruncatedSrcQuery ? row.src_query_trunc : row.src_query;
                row.showMoreNLess = true;
            } else {
                row.src_details = row.api_url;
                row.showMoreNLess = false;
                row.noSrcQuery = false;
            }
            $scope.checkSelection();
        };

        $scope.showHide = function (row) {
            row.showTruncatedSrcQuery = !row.showTruncatedSrcQuery;
            if (row.showTruncatedSrcQuery) row.src_details = row.src_query_trunc;
            else row.src_details = row.src_query;
        };

        $scope.updateApiDetails = function (row) {
            const apiDetails = (
                ({
                    job_id, src_tbl_name, dest_tbl_name, api_url, cron_expr, last_run_by, last_run_dt, updated_by, updated_dt, job_status, src_type,
                    src_ds_name, src_query, updated_by_name, jobStatusFlag, grp_type, job_desc
                }) => ({
                    job_id, src_tbl_name, dest_tbl_name, api_url, cron_expr, last_run_by, last_run_dt, updated_by, updated_dt, job_status, src_type,
                    src_ds_name, src_query, updated_by_name, jobStatusFlag, grp_type, job_desc
                })
            )(row);

            apiDetails.job_status = apiDetails.jobStatusFlag ? 'A' : 'I';
            apiDetails.job_desc = apiDetails.job_desc ? apiDetails.job_desc : '';
            GoldSyncFactory.updateApiDetails(apiDetails).then((response) => {
                if (response.data.callSuccess && response.data.updateApiDetails) {
                    AlertService.add('success', 'Api Details updated successfully!', 4000);
                } else {
                    throw new Error('callSuccess 0');
                }
            }).catch((error) => {
                AlertService.add('error', 'Error processing your request, please try again!', 4000);
                $scope.loadData();
            });

            $scope.checkSelection();
        };

        $scope.onCronHelpBtnClick = function () {
            let modalObj = {};
            modalObj.template = 'app/components/admin/goldSync/templates/cron-expr-explains.html';
            modalObj.size = 'sm';
            modalObj.controller = ['$scope', function ($scope) { }];

            $scope.openModal(modalObj);
        };

        // on focus of the cron editor, display value of the cron expression
        $scope.onCronEditorFocused = function (job) {
            job.useDisplayVal = true;
            job.cronDisplay = job.cron_expr;
        };

        // display the translated version of the cron expression when editor loses focus
        $scope.onCronEditorBlur = function (job) {
            $scope.checkCron(job, false);
            job.useDisplayVal = false;
            // update only when cron is valid or revert back the change
            if (!job.invalidCron) {
                job.cron_expr = job.cronDisplay;
                job.cronDisplay = job.readableCron;
            } else {
                job.doShake = true;
                $timeout(() => {
                    job.doShake = false;
                }, 350);
                $scope.checkCron(job, true);
            }
        };

        $scope.checkCron = function (job, override) {
            $scope.translateCron(job, override);
            job.invalidCron = job.readableCron.includes('Error');
        };

        $scope.translateCron = function (job, override) {
            if (job && job.cron_expr) {
                try {
                    const readableCron = cronstrue.toString(job.useDisplayVal ? job.cronDisplay : job.cron_expr);
                    job.readableCron = readableCron.includes('undefined') ||
                        readableCron.includes('NaN') ||
                        (readableCron.includes('only in') && $scope.isYearTokenNaN(readableCron)) ?
                        'Error: Invalid tokens found!' : readableCron;
                } catch (e) {
                    job.readableCron = e;
                }
            } else {
                job.readableCron = 'Error: Enter a valid cron expression!';
            }
            // set display value to readableCron
            if (override) job.cronDisplay = job.readableCron;
        };

        // determine whether the year token of a cron expression is NaN
        $scope.isYearTokenNaN = function (readableCron) {
            const pieces = readableCron.split('only in');
            const yearToken = pieces[pieces.length - 1].trim();
            return !(yearToken.toLowerCase() === 'jan') &&
                !(yearToken.toLowerCase() === 'feb') &&
                !(yearToken.toLowerCase() === 'mar') &&
                !(yearToken.toLowerCase() === 'apr') &&
                !(yearToken.toLowerCase() === 'may') &&
                !(yearToken.toLowerCase() === 'jun') &&
                !(yearToken.toLowerCase() === 'jul') &&
                !(yearToken.toLowerCase() === 'aug') &&
                !(yearToken.toLowerCase() === 'sep') &&
                !(yearToken.toLowerCase() === 'oct') &&
                !(yearToken.toLowerCase() === 'nov') &&
                !(yearToken.toLowerCase() === 'dec') &&
                isNaN(yearToken);
        };

        $scope.checkSelection = function (checkboxRef) {
            let activeCount = 0;
            let uncheckedCount = 0;
            for (let i = 0; i < $scope.goldSyncData.length; i++) {
                if ($scope.goldSyncData[i].jobStatusFlag && !$scope.goldSyncData[i].noSrcQuery) {
                    activeCount++;
                    if (!$scope.goldSyncData[i].selected) {
                        uncheckedCount++;
                    }
                }
            }
            $scope.checkedCount = activeCount - uncheckedCount;
            $scope.partialSelection = false;
            $scope.fullSelection = false;
            $scope.reverseToggle = false;
            $scope.disableSyncAll = true;
            if (activeCount > 0 && uncheckedCount === 0) {
                // all checkboxes are selected if uncheckedCount is 0
                $scope.disableSyncAll = false;
                $scope.fullSelection = true;
            } else if (uncheckedCount < activeCount) {
                $scope.disableSyncAll = false;
                $scope.partialSelection = true;
                $scope.reverseToggle = true; // reverse the selection toggle in partial selection mode
            } else {
                if (checkboxRef)
                    checkboxRef.checked = false;
            }
        };

        $scope.toggleSelection = function ($event) {
            if ($scope.reverseToggle)
                $event.target.checked = false;
            $scope.goldSyncData.forEach((row) => {
                if (row.jobStatusFlag)
                    row.selected = $event.target.checked;
            });
            $scope.checkSelection($event.target);
        };

        $scope.sync = function (row) {
           
            // const taxYears = $scope.selectedTaxYears.map(({tax_year}) => tax_year);
            const jobIds = [];
            jobIds.push(row.job_id);
            const srcTypes = [];
            srcTypes.push(row.selectedSrcType.fromData);
            const job = [];
            job.push(row);
            if ($scope.groupTypeSelected != "EO_NATIVE") {
                $scope.syncApiData(jobIds,srcTypes,job);
            }
            else {
                $scope.syncEONativeApiData(jobIds,srcTypes, job);
            }
        };

        $scope.syncSelected = function () {
            const selectedJobs = $scope.goldSyncData.filter((data) => data.selected);         
            const jobIds = selectedJobs.map(({job_id}) => job_id);
            const srcTypes = selectedJobs.map(({selectedSrcType}) => selectedSrcType.fromData);
            if ($scope.groupTypeSelected != "EO_NATIVE") {
                $scope.syncApiData(jobIds,srcTypes,selectedJobs);
            }
            else {
                $scope.syncEONativeApiData(jobIds,srcTypes, selectedJobs);
            }
        };
        $scope.syncApiData = function(jobIds,srcTypes,selectedJobs) {
            const taxYears = $scope.selectedTY;
            const groupType = $scope.groupTypeSelected;
            if(!groupType){
                AlertService.add('warning', 'Please select sync type from the dropdown!', 4000);
                return;
            }
            if (!taxYears) {
                $scope.mulSelectDropDownDoShake = true;
                $timeout(() => {
                    $scope.mulSelectDropDownDoShake = false;
                    $scope.$apply();
                }, 350);
                AlertService.add('warning', 'Please select tax years from the dropdown!', 4000);
                return;
            }
            const scenario = $scope.selectedScenario.map(({ scenario }) => scenario);
            if (groupType && groupType === "GOLD" && scenario && scenario.length === 0) {
                $scope.mulSelectDropDownDoShake = true;
                $timeout(() => {
                    $scope.mulSelectDropDownDoShake = false;
                    $scope.$apply();
                }, 350);
                AlertService.add('warning', 'Please select scenario from dropdown!', 4000);
                return;
            }
            GoldSyncFactory.syncApiData(taxYears, jobIds, srcTypes, scenario).then((response) => {
                if (response.data.callSuccess) {
                    AlertService.add('success', 'Synchronization successfully initiated.', 4000); 
                    selectedJobs.forEach((job) => {
                        job.selected = false;
                    });
                    $scope.checkSelection(); 
                } else {
                    throw new Error('callSuccess 0');
                }
            }).catch((err) => {
                AlertService.add('error', 'Synchronization failed to start!', 4000);
            });
        }
        $scope.syncEONativeApiData = function(jobIds,srcTypes,selectedJobs) {
            const groupType = $scope.groupTypeSelected;
            if(!groupType){
                AlertService.add('warning', 'Please select sync type from the dropdown!', 4000);
                return;
            }
            if (selectedJobs[0].src_details != null) {
                //validate taxyears null or undefined like scenario
                GoldSyncFactory.syncEONativeApiData(jobIds, srcTypes, selectedJobs, USER_SETTINGS.user.client.client_datasource).then((response) => {
                    if (response.data.callSuccess) {
                    AlertService.add('success', 'Synchronization successfully initiated.', 4000); 
                    selectedJobs.forEach((job) => {
                        job.selected = false;
                    });
                    $scope.checkSelection(); 
                } else {
                    throw new Error('callSuccess 0');
                }
            }).catch((err) => {
                AlertService.add('error', 'Synchronization failed to start!', 4000);
            });
            }

            else {
                GoldSyncFactory.syncEONativeRPData(jobIds, selectedJobs, USER_SETTINGS.user.client.client_datasource).then((response) => {
                    if (response.data.callSuccess) {
                    AlertService.add('success', 'Synchronization successfully initiated.', 4000); 
                    selectedJobs.forEach((job) => {
                        job.selected = false;
                    });
                    $scope.checkSelection(); 
                } else {
                    throw new Error('callSuccess 0');
                }
            }).catch((err) => {
                AlertService.add('error', 'Synchronization failed to start!', 4000);
            });
            }
        }
        $scope.editWithoutSelection = function () {
            $scope.tabIndex = 1;
            $scope.jobToEdit = undefined;
            $scope.invalidForm = true;
            $scope.newObject = false;
        };

        $scope.editJobDetails = function (job) {
            $scope.tabIndex = 1;
            $scope.jobToEdit = job;
            $scope.jobToEdit.selectedSrcType = $scope.jobToEdit.srcType;
            $scope.jobToEdit.selectedGrpType = $scope.jobToEdit.grp_type;
            $scope.jobToEdit.src_details = $scope.jobToEdit.selectedSrcType.fromData === 'DS' ? $scope.jobToEdit.src_query : $scope.jobToEdit.api_url;
            $scope.invalidForm = false;
            $scope.newObject = false;
        };

        // establish web socket connection for gold sync
        MessageService.establishConnection(GENERAL_CONFIG.gold_sync_api_url + '/gold-sync-websockets', (client) => {
            MessageService.subscribeToChannelWithCallback(GENERAL_CONFIG.gold_sync_api_url + '/gold-sync-websockets',
                '/gs-notify/' + USER_SETTINGS.user.sso_id, function (payload) {
                    try {
                        let notification = JSON.parse(payload.body);
                        if (notification.consolidateMsg === 'true') {
                            AlertService.add('success', notification.notificationMessage, 4000);
                        } else {
                            if (notification.apiDetail && notification.type === 'success') {
                                // update last_run_dt and updated_by_name fields
                                const updatedApiDetail = JSON.parse(notification.apiDetail);
                                const updatedJob = $scope.goldSyncData.find(data => data.job_id === updatedApiDetail.job_id);
                                updatedJob.last_run_dt = updatedApiDetail.last_run_dt;
                                updatedJob.updated_by_name = updatedApiDetail.updated_by_name;
                            }
                            $rootScope.$broadcast('pushNotifications', notification);
                        }
                        $rootScope.$apply();
                    } catch (e) {
                        console.error(e);
                    }
                },client);
        });
    }

    return controllers;
}
);
