/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './FormCreditsPartIIICtrl',
    './FormCreditsPartIIIService',

], function () {
    'use strict';

  
   return angular.module('app.Form-creditsPartIII', ['app.FormCreditsPartIIICtrl','app.FormCreditsPartIIIService'])

    });