define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.projectReviewService', [])
                          .factory("ProjectReviewFactory", ['$http', 'GENERAL_CONFIG', 'GlobalService', function ($http, GENERAL_CONFIG, GlobalService) {
                              const projectReviewFactory = {};
                              projectReviewFactory.tableIndexCache = 0;
                              projectReviewFactory.menuOpenCache = true;
                              projectReviewFactory.projectListCache = [];
                              projectReviewFactory.transStepDataCache = [];
                              projectReviewFactory.transDataCache = [];

                              projectReviewFactory.resetCache = function () {
                                  projectReviewFactory.tableIndexCache = 0;
                                  projectReviewFactory.menuOpenCache = true;
                                  projectReviewFactory.projectListCache = [];
                                  projectReviewFactory.transStepDataCache = [];
                                  projectReviewFactory.transDataCache = [];
                                  projectReviewFactory.selectedProjectCache = undefined;
                              };

                              projectReviewFactory.getProjectsByTaxYear = function (taxYear) {
                                  const promise = $http({
                                      method: 'GET',
                                      url: `${GENERAL_CONFIG.custom_api_url}/projectReview/getProjectsByTaxyear?taxYear=${taxYear}`
                                  })
                                  return promise;
                              };

                              projectReviewFactory.getProjectSteps = function (project_id, filterParams) {
                                  const promise = $http({
                                      method: 'GET',
                                      url: `${GENERAL_CONFIG.custom_api_url}/projectReview/getProjectSteps?project_id=${project_id}&tax_year=${filterParams.tax_year}&scenario=${filterParams.scenario}&filingKey=${filterParams.filing_key}&parent_me_string=${filterParams.parent_me_string}`
                                  })
                                  return promise;
                              };

                              projectReviewFactory.getTransactionData = function (project_id, filterParams) {
                                  const promise = $http({
                                      method: 'GET',
                                      url: `${GENERAL_CONFIG.custom_api_url}/projectReview/getTransactionData?project_id=${project_id}&tax_year=${filterParams.tax_year}&scenario=${filterParams.scenario}&filingKey=${filterParams.filing_key}&parent_me_string=${filterParams.parent_me_string}`

                                  })
                                  return promise;
                              }

                              return projectReviewFactory;
                          }]);
    return services;
});
