define([
    'angular'

], function () {
    'use strict';
    
    var controllers =  angular.module('app.systemMessageCtrl',[])
        .controller('systemMessageCtrl', ['systemMessageFactory','AlertService', 'USER_SETTINGS',
        '$scope','$filter', systemMessageCtrl]);

        function systemMessageCtrl(systemMessageFactory,AlertService, USER_SETTINGS,$scope,$filter) {
            var vm = this;
            vm.title = "System Message";
            vm.createSystemMessageHdr = "";
            vm.editSystemMessageHdr = "";
            vm.searchText = "";
            vm.notifSelectedTypeVal = "system";
            vm.selectedMsgFilterVal = "show all"

            vm.clientList = {}
            vm.systemNotificationList = [];
            vm.systemNotificationTotalList = [];
            vm.systemNotificationListByMsgFilter = [];
            vm.systemNotificationData = [];
            vm.systemMsgAppKeyList = [];
            vm.taxTypeList = [];
            vm.userList = [];
            vm.selectedUserList = [];
            
            vm.systemMsgBodyEditor = {};
            vm.messageEvents = {};
            vm.selectedSysMsgData = {};
            vm.selectedClient = {};
            
            vm.clients = USER_SETTINGS.user.clients;
            // vm.clients = [{
            //     "client_key": 0,
            //     "client_name": "Dev Client"
            // }, {
            //     "client_key": 1,
            //     "client_name": "Dev Client1"
            // }, {
            //     "client_key": 2,
            //     "client_name": "Dev Client2"
            // }];
            vm.showSystemMessageContent = false;
            vm.itemSelected = false;
            vm.customValidations = false;
            vm.showNotifLoader = false;

            vm.startDateOptions = {
                dateDisabled: false,
                formatYear: 'yyyy',
                startingDay: 1,
                showWeeks: false,
                minDate: new Date()
            };

            vm.endDateOptions = {
                dateDisabled: false,
                formatYear: 'yyyy',
                startingDay: 1,
                showWeeks: false
            };

            vm.userTypes = {
                selected:{},
                list:[
                    {name: "User", value: 1},{name: "Editor", value: 2},{name: "Admin", value: 3},
                    {name: "Developer", value: 4},{name: "Super Admin", value: 5}
                ],
                disabled:false
            };
            vm.clientList.values = _.forEach(vm.clients, function(eachItem) {
                eachItem.LABEL = eachItem.client_name;
                eachItem.VALUE = eachItem.client_key;
            });
            vm.clientList ={
                param_name: "Client",
                values: vm.clientList.values,
                label_name: "label",
                value_name: "value",
                label: "",
                selected:[]
            }
            vm.taxType ={
                param_name: "Tax Type",
                values: [],
                label_name: "label",
                value_name: "value",
                label: "",
                selected:[]
            }

            vm.appKey ={
                param_name: "App Key",
                values: [],
                label_name: "label",
                value_name: "value",
                label: "",
                selected:[]
            }

            // Gets all the system user list..
            vm.getAllUsersList = function() {
                systemMessageFactory.getAllUsers().then(function(data) {
                    vm.userList = data.jsonObject;
                });
            }
            vm.getAllUsersList();

            // Gets all the system messsage/notif list..
            vm.getAllSystemNotifData = function() {
                systemMessageFactory.getAllSystemNotif().then(function(data) {
                    vm.systemNotificationTotalList = data.system_notif_list;
                    vm.systemMsgAppKeyList = data.app_list;
                    vm.taxTypeList = data.tax_type_list;
                    vm.changeNotifType(vm.notifSelectedTypeVal);
                    vm.messageFilter(vm.selectedMsgFilterVal);

                   vm.taxType.values = vm.taxTypeList;
                   vm.appKey.values = vm.systemMsgAppKeyList
                });
            }
            vm.getAllSystemNotifData();

            // this will change the main notification type filter on system message header(sorts the data based on system/client type)..
            vm.changeNotifType = function(selectedType) {
                if(selectedType == "system") {
                    vm.systemNotificationList = _.filter(vm.systemNotificationTotalList, {notif_type: selectedType});
                    vm.messageFilter(vm.selectedMsgFilterVal);
                } else if(selectedType == "client") {
                    vm.changeClientType();
                }
            }

            // used to sorts the left panel notification list based on the client selection..
            vm.changeClientType = function() {
               /*  vm.systemNotificationList = _.filter(vm.systemNotificationTotalList, 
                    {notif_type: vm.notifSelectedTypeVal, 
                        client_keys: JSON.stringify(vm.selectedClient.client_name? 
                            vm.selectedClient.client_name.client_key : "")}) */
                 vm.systemNotificationList = [];
                  vm.systemNotificationTotalList.filter(function(i){
                     if( i.notif_type == vm.notifSelectedTypeVal && 
                        ((vm.selectedClient && vm.selectedClient.client_name && vm.selectedClient.client_name.client_key) ? 
                        i.client_keys.indexOf(vm.selectedClient.client_name.client_key) > -1     : 
                        i.client_keys == "")){
                            vm.systemNotificationList.push(i);
                     }
                })
                vm.messageFilter(vm.selectedMsgFilterVal);
            }

            // this will change notification list based on selection archive/show all from the whole list..
            vm.messageFilter = function(selectedMsgFilterType) {
                vm.showNotifLoader = true;
                vm.selectedSysMsgData = {};
                vm.showSystemMessageContent = false;
                if(selectedMsgFilterType == "archive") {
                    vm.systemNotificationListByMsgFilter = _.filter(vm.systemNotificationList, {notif_status: "closed"});
                } else if(selectedMsgFilterType == "show all") {
                    vm.systemNotificationListByMsgFilter = _.filter(vm.systemNotificationList, {notif_status: "open"});
                }
                vm.showNotifLoader = false;
            }

            // this will archive the selected list and removes from the show all list..
            vm.archiveList = function(archiveItem) {
                _.filter(vm.systemNotificationList, function(item) {
                    if(item.notif_msg_key == archiveItem.notif_msg_key) {
                        item.notif_status = vm.selectedMsgFilterVal == 'archive' ? "open":'closed';
                        vm.saveSysMsg(item);
                    }
                });
                
                vm.messageFilter(vm.selectedMsgFilterVal);
                AlertService.add("success", "Notification was archived successfully", 4000);
            }
            

            //Creats message rendered object..
            vm.messageEvents.rendered = function (systemMsgBodyEditor) {
                vm.systemMsgBodyEditor = systemMsgBodyEditor;
            };

            vm.createNotifListData = function () {
                vm.createSystemMessageHdr = "Create New";
                vm.showSystemMessageContent = true;
                vm.customValidations = false;
                vm.systemMsgValidations.$submitted = false;
                vm.selectedSysMsgData = {};
                vm.systemMsgBodyEditor.setData("");
                vm.appKey.selected = [];
                vm.taxType.selected = [];
                vm.userTypes.selected = "";
                vm.clientList.selected = [];
                vm.selectedUserList = [];
                _.each(vm.clientList.values, function(item){
                    if(item.isChecked) {
                        item.isChecked = false;
                    }
                });
                _.each(vm.appKey.values,function(o){
                    if(o.isChecked){
                        o.isChecked = false;
                    }
                })
                _.each(vm.taxType.values,function(o){
                    if(o.isChecked){
                        o.isChecked = false;
                    }
                })


                _.each(vm.systemNotificationList, function(item) {
                    if(item.active){
                        item.active = false;
                        item.selected = false;
                    }
                });
                
            }
            
            vm.editNotifListData = function(list) {
                vm.createSystemMessageHdr = "Edit";
                vm.showSystemMessageContent = true;
                list.active = true;
                vm.selectedUserList = [];
                vm.selectedSysMsgData = angular.copy(list);
             //   vm.clients = USER_SETTINGS.user.clients;

                vm.userTypes.selected = "";
                _.forEach(vm.userTypes.list, function(item) {
                    if(item.value == vm.selectedSysMsgData.notif_user_type)
                    vm.userTypes.selected = item;
                });
                vm.clientList.selected = [];
                _.each(vm.appKey.values,function(o){
                    if(o.isChecked){
                        o.isChecked = false;
                    }
                })
                _.each(vm.taxType.values,function(o){
                    if(o.isChecked){
                        o.isChecked = false;
                    }
                })
                vm.appKey.selected = JSON.parse(vm.selectedSysMsgData.app_key);
                vm.taxType.selected = JSON.parse(vm.selectedSysMsgData.tax_type);
                
               _.forEach(vm.clients, function(eachItem) {
                    _.map(vm.selectedSysMsgData.client_keys, function(p) {
                        if (eachItem.client_key == p) {
                            var obj = {
                                    LABEL : eachItem.client_name,
                                    VALUE : eachItem.client_key}
                                    vm.clientList.selected.push(obj);
                        }
                    });
                });
                /* vm.clientList.selected  */
               
                var users = (vm.selectedSysMsgData.notif_user_list) ? (vm.selectedSysMsgData.notif_user_list).split(','):[];//JSON.parse(vm.selectedSysMsgData.notif_user_list);
                _.filter(vm.userList, function(o) {
                    _.each(users, function(p) {
                      if (o.SSO_ID == p) {
                        vm.selectedUserList.indexOf(o) === -1 ? vm.selectedUserList.push(o) : vm.selectedUserList; 
                       // vm.selectedUserList.push(o);
                      }
                    });
                });
                vm.selectedSysMsgData.notif_start_date = new Date(vm.selectedSysMsgData.notif_start_date);
                vm.selectedSysMsgData.notif_end_date = new Date(vm.selectedSysMsgData.notif_end_date);
                // used to get the selected item from the notification/message list
                _.filter(vm.systemNotificationList, function(item) {
                    if(vm.selectedSysMsgData.notif_msg_key == item.notif_msg_key) {
                        item.selected = true;
                    } else {
                        item.selected = false;
                    }
                });
                vm.activateNote(vm.selectedSysMsgData);
            }
            
            vm.activateNote = function(messageBody) {
                vm.systemMsgBodyEditor.setData(JSON.parse(messageBody.notif_msg_body));
            }

      
            vm.saveSysMsg = function(item) {
                console.log("vm.systemMsgBodyEditor.getData()",vm.systemMsgBodyEditor.getData());
                console.log("Saveeeeeee", vm.selectedSysMsgData);
                if (vm.systemMsgValidations.$invalid) {
                    vm.customValidations = true;
                    vm.systemMsgValidations.$submitted = true;
                    AlertService.add("danger", "Please correct the errors below", 4000);
                    return;
                }
                if(item != undefined){
                    item.notif_start_date = new Date(item.notif_start_date)
                    item.notif_end_date  = new Date(item.notif_end_date)
                    vm.data = item;
                }else{
                    vm.data = {
                        notif_msg_key   : vm.selectedSysMsgData.notif_msg_key,
                        notif_msg_title : vm.selectedSysMsgData.notif_msg_title,
                        notif_msg_body  : JSON.stringify(vm.systemMsgBodyEditor.getData()),
                        business_rights : "",
                        notif_user_list : _.map(vm.selectedUserList,'SSO_ID').join(','),
                        tax_type   		: JSON.stringify(vm.taxType.selected),//'1,2',
                        notif_user_type : vm.userTypes.selected.value,
                        app_key   		: JSON.stringify(vm.appKey.selected),//'7,4' ,
                        notif_start_date: vm.selectedSysMsgData.notif_start_date,
                        notif_end_date  : vm.selectedSysMsgData.notif_end_date,
                        client_keys   	: vm.notifSelectedTypeVal == 'system' ? 999999999 : 
                                            _.map(vm.clientList.selected, 'VALUE').join(','),
                        notif_status    : 'open',
                        notif_type      : vm.notifSelectedTypeVal
                    }
                }
                var _poastData = [];
                _poastData.push(vm.data);
                systemMessageFactory.saveSysMsg(_poastData).then((response) =>{
                    if(response && response.callSuccess == 1){
                        AlertService.add("success", "System Notification Created Successfully", 4000);                        
                        vm.getAllSystemNotifData();
                    }else{
                        AlertService.add("error", response.errorMessage, 4000);                        

                    }
                })

            }

            vm.searchUserList = function(searcItem) {
                vm.searchResults = _.filter(vm.userList, function(itr) {
                    if(isNaN(searcItem)) {
                        var label = itr.DISPLAY_NAME.toLowerCase();
                        return label.indexOf(searcItem.toLowerCase())!== -1;
                    } else {
                        var sso = itr.SSO_ID;
                        return sso.indexOf(searcItem)!== -1;
                    }
                });
            }

            vm.selectedUser = function(user) {
                vm.selectedUserList.indexOf(user) === -1 ? vm.selectedUserList.push(user) : vm.selectedUserList; 
               // vm.selectedUserList.push(user);
                vm.searchText = "";
            }

            vm.deleteUser = function(index) {
                vm.selectedUserList.splice(index, 1);
            }

            vm.openMessageModal = function() {
                
            }


            
            

        }
        return controllers;
    });