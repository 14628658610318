define([
	'uiMask',
    'angular'
], function (uiMask) {
    'use strict';
    var controllers = angular.module('app.Form1120LPCCtrl', ['ui.mask'])
    
        .controller('Form1120LPCController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'F1120LPCServiceFactory', 'USER_SETTINGS','$filter',Form1120LPCController]);
    	function Form1120LPCController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, F1120LPCServiceFactory, USER_SETTINGS, $filter) {
            var vm = this;
	   		vm.enableDisableSub = 'Include';
    		vm.originalData = [];
    		vm.userSettings = USER_SETTINGS;
    		vm.logged_in_user = vm.userSettings.user.sso_id;
    		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
			vm.currentyear = filterParams.tax_year;
    		vm.leId = rowData.LEID;
            vm.formName = 'Domestic Headers';
            vm.hgt = '96%';
            vm.cdr_no = rowData.MARS;
            vm.leType = rowData.LE_TYPE;
            vm.disableDate = true;
            vm.isSaveClicked = false;

            $scope.entityList = {
    			"Tax Year": vm.currentyear,
    			"LEID": rowData.LEID,
    			"LE Name": rowData.LE_NAME
    		};
            
 
    		var saveParamDtls = [];
    		var tempDataObj = {};
    		vm.showFormLoader = true;
    		vm.corporationList = [];
    		vm.entityList = [];
    		vm.entitiesData = [];


            function fetchDomesticHeadersData() {
                var params = { "action_code": 'g68nns', "tax_year": vm.currentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": rowData.COMBINATION_KEY,"entity_type": rowData.ENTITY_TYPE};

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                	vm.formList =  data.jsonObject;

					for(var i=0;i < vm.formList.length; i++){
						if(rowData.CONSOL_LEVEL_FLAG == 'Y'){
							if(vm.formList[i].LINE_NO != 'E5'){
								vm.formList[i]['conditionalField'] = true;
							}
						}else{
							if(vm.formList[i].LINE_NO == 'E5' && rowData.CONSOL_GROUP_TYPE == 'GROUP'){
								vm.formList[i]['conditionalField'] = true;
							}

						}

					}
                	              	
    				vm.originalData = angular.copy(vm.formList);
    				initFormView(vm.formList);
                });
            };

            
    		function init(){
    			fetchDomesticHeadersData();
    		};
    		init(); 
            


			vm.save = function(saveType) { 
				vm.isSaveClicked = true;
				var returnClobSettingsObj = {};
				var fDomesticSaveDtls = [];

					var combinationKey = 0;
					
						vm.saveData = $filter("filter")(vm.formList); //, {IS_CHANGED_FLAG:'Y'}
						
						if(vm.saveData.length == 0){
							AlertService.add("error", "No chnages to save.");
							vm.isSaveClicked = false;
							return;
						}
						
						for(var i=0;i < vm.saveData.length;i++){
							var returnObj = {};

							returnObj['form_name'] =  vm.saveData[i].IRS_FORM_NO;
							returnObj['part_no'] =  vm.saveData[i].PART_NO;
							returnObj['line_no'] =  vm.saveData[i].LINE_NO;
							returnObj['schd_no'] =  vm.saveData[i].SCHEDULE_NAME;
							returnObj['section_name'] =  vm.saveData[i].SECTION_NAME;
							returnObj['form_key'] =  vm.saveData[i].FORM_KEY;
							returnObj['combination_key'] = vm.saveData[i].COMBINATION_KEY;
							returnObj['form_key'] = vm.saveData[i].FORM_KEY;
							returnObj['form_line_key'] = vm.saveData[i].FORM_LINE_KEY;
							returnObj['line_attrib_key'] = vm.saveData[i].LINE_ATTRIB_KEY;
							returnObj['attrib_type'] = vm.saveData[i].ATTRIB_TYPE;
							returnObj['attrib_prop_key'] = vm.saveData[i].ATTRIB_PROP_KEY;
							returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REF_KEY;
							returnObj['attrib_name'] = vm.saveData[i].LINE_DESCRIPTION;
							returnObj['attrib_value'] = vm.saveData[i].ATTRIB_VALUE;
							returnObj['entity_type'] = vm.saveData[i].ENTITY_TYPE;
							returnObj['trans_details_key'] = vm.saveData[i].TRANS_DETAILS_KEY;
							returnObj['trans_type_key'] = vm.saveData[i].TRANS_TYPE_KEY;
							returnObj['edit_type'] = vm.saveData[i].EDIT_TYPE;
							//returnObj['is_changed_flag'] = vm.saveData[i].IS_CHANGED_FLAG;

							fDomesticSaveDtls.push(returnObj);
						}

						var message = "Form has been successfully saved/updated";


						returnClobSettingsObj['sso_id'] = vm.logged_in_user;
						returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
						returnClobSettingsObj['form_name'] = vm.formList[0].FORM_NAME;
						returnClobSettingsObj['combination_key'] = vm.formList[0].COMBINATION_KEY;
		
						sendDetails(returnClobSettingsObj,fDomesticSaveDtls, message);
					

			};

			function sendDetails(returnClobSettingsObj, fDomesticSaveDtls, message) {
				F1120LPCServiceFactory.savefDomesticHeaders(returnClobSettingsObj,fDomesticSaveDtls).then(function(result) {

					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						//vm.crudLoading = false;
						AlertService.add("success", message, 4000);
						var args = {
								combination_key: returnClobSettingsObj.combination_key,
								gridFilter: {
									combination_key: returnClobSettingsObj.combination_key
								}
						};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
					};

				});
			}	


    		vm.reset = function (){
    			vm.formList = angular.copy(vm.originalData);
    		//	vm.validateSubForm();// This is called here to reset line 5a
    		
    		};

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
            
			
            function initFormView(filteredFormData) {
    			vm.formList = filteredFormData;
    		};

        }
});