define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.cfcsWithQualDefsCtrl', []);
    var controllers = module.controller('cfcsWithQualDefsMainCtrl',
            		['$element','menuDataService','workspaceFactory','gtapsCommonTemplateFactory','WorkflowTCFactory','$uibModal','GlobalService', 'AlertService', '$timeout', '$window', '$scope','JsonObjectFactory', '$parse', '$rootScope', 'GENERAL_CONFIG', 'cfcsWithQualDefsFactory', cfcsWithQualDefsMainCtrl]);

		function cfcsWithQualDefsMainCtrl(element,menuDataService,workspaceFactory,gtapsCommonTemplateFactory, WorkflowTCFactory, $uibModal, GlobalService, AlertService, $timeout, $window, $scope,JsonObjectFactory, $parse, $rootScope, GENERAL_CONFIG, cfcsWithQualDefsFactory) {
			var vm = this;
			vm.loading = false;
			vm.name = "cfcsWithQualDefsMainCtrl";
			$scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
            
			var activeScreenEp = workspaceFactory.activeScreen;
			vm.cacheAllActiveData = {};
			
			//using these parameters from common controller
			/*vm.total959c3EPPoolFuncCurr;
			vm.totalNH959c3EPPoolFuncCurr;
			vm.totalPtiFuncCurr;
			vm.total959c3EPPoolUSD;
			vm.totalNH959c3EPPoolUSD;
			vm.totalPtiUSD;
			vm.totalTaxPool;
			vm.totalNHTaxPool;
			vm.hovering959c3Ep;
			vm.hovering959c3Taxes;
			vm.totalUSDBasisInPti;
			vm.effectiveRateOn959c3TotalEp;
			vm.effectiveRateOn959c3TotalEpNH;
			vm.estimatedPtiExchangeGainLoss;
			vm.recaptureAccounts = "No";
			vm.qualifiedDeficits = "No";*/
			vm.baseCurrency = "USD";

			//labels
			vm.pageTitle = "CFC's with Qualified Deficits";
			vm.labelSummary = "Summary";
			/*vm.labelTotal959c3EPPoolUSD = "Total Section 959(c)(3) E&P Pool (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalPtiUSD = "Total PTI (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalTaxPool = "Total Tax Pool";
			vm.labelTotalUSDBasisInPti = "Total USD Basis in PTI";
			vm.labelEffectiveRateOn959c3TotalEp = "Effective Rate on 959(c)(3) Total E&P";
			vm.labelEstimatedPtiExchangeGainLoss = "Estimated PTI Exchange Gain/(Loss)";
			vm.labelRecaptureAccts = "Recapture Accounts";
			vm.labelQualDefs = "Qualified Deficits";*/
			vm.labelTotalEPTaxes = "Total E&P / Taxes";
			vm.labelNonHoveringEPTaxes = "Non-Hovering E&P / Taxes";
			vm.labelTotalSection959c3TaxPool = "Total Section 959(c)(3) Tax Pool";
			vm.labelHovering959c3Ep = "Hovering 959(c)(3) E&P";
			vm.labelHovering959c3Taxes = "Hovering 959(c)(3) Taxes";

			if (menuDataService) {
				vm.menuData = menuDataService.data;
			}
			if (angular.isUndefined(workspaceFactory.activeScreen.filter_key) || workspaceFactory.activeScreen.filter_key === null) {
				workspaceFactory.activeScreen.filter_key = 41;
			}

			//Copy this controller object data from workspaceFactory.activeScreen.data if its already available
			if (angular.isDefined(workspaceFactory.activeScreen.data) && workspaceFactory.activeScreen.data !== 'LOAD') {
				//populateCachedData(workspaceFactory.activeScreen.data);
				refreshData();
			} else if (angular.isDefined(workspaceFactory.activeScreen.data) && workspaceFactory.activeScreen.data === 'LOAD') {
				refreshData();
			} else {
				vm.hoLeid = '';
				vm.entityName = '';
				vm.hoTaxCode = '';
				vm.funcCurrency = "";
				vm.funcCurrDescription = "";
				vm.selectedHoLeidParams;

				//Data Lists
				vm.columnNames = [];
				vm.qualDefsData = [];
				vm.subfCategoryNames = [];
				vm.subfCategoriesData = [];
				vm.displayTable = false;

				vm.showData = false;
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
			}
			
			function populateCachedData(cachedData) {
				vm.hoLeid = cachedData.hoLeid;
				vm.entityName = cachedData.entityName;
				vm.hoTaxCode = cachedData.hoTaxCode;
				vm.funcCurrency = cachedData.funcCurrency;
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
				
				/*vm.total959c3EPPoolFuncCurr = cachedData.total959c3EPPoolFuncCurr;
				vm.totalNH959c3EPPoolFuncCurr = cachedData.totalNH959c3EPPoolFuncCurr;
				vm.totalPtiFuncCurr = cachedData.totalPtiFuncCurr;
				vm.total959c3EPPoolUSD = cachedData.total959c3EPPoolUSD;
				vm.totalNH959c3EPPoolUSD = cachedData.totalNH959c3EPPoolUSD;
				vm.totalPtiUSD = cachedData.totalPtiUSD;
				vm.totalTaxPool = cachedData.totalTaxPool;
				vm.totalNHTaxPool = cachedData.totalNHTaxPool;
				vm.hovering959c3Ep = cachedData.hovering959c3Ep;
				vm.hovering959c3Taxes = cachedData.hovering959c3Taxes;
				vm.totalUSDBasisInPti = cachedData.totalUSDBasisInPti;
				vm.effectiveRateOn959c3TotalEp = cachedData.effectiveRateOn959c3TotalEp;
				vm.effectiveRateOn959c3TotalEpNH = cachedData.effectiveRateOn959c3TotalEpNH;
				vm.estimatedPtiExchangeGainLoss = cachedData.estimatedPtiExchangeGainLoss;
				vm.recaptureAccounts = cachedData.recaptureAccounts;
				vm.qualifiedDeficits = cachedData.qualifiedDeficits;*/
				vm.selectedHoLeidParams = cachedData.selectedHoLeidParams;;
	
				//Data Lists
				vm.columnNames = cachedData.columnNames;
				vm.qualDefsData = cachedData.qualDefsData;
				vm.subfCategoryNames = cachedData.subfCategoryNames;
				vm.subfCategoriesData = cachedData.subfCategoriesData;
				vm.displayTable = cachedData.displayTable;
	
				vm.showData = true;
			}

			// $scope.$watch(function() { 
			// 	return (angular.isDefined(workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.loading : true);
			// }, function(newValue, oldValue) {
			// 	if (newValue === false && newValue !== oldValue
			// 		&& angular.isDefined(workspaceFactory.activeScreen.filters.filters)) {
			// 		//Refresh the filters for which data is not loaded using the last filter in the ResfreshList
			// 		angular.forEach(workspaceFactory.activeScreen.filters.filters, function(filter) {
			// 			if (angular.isUndefined(filter.values) || filter.values === null) {
			// 				if (filter.refresh_list && filter.refresh_list.length) {
			// 					var refresh_array = filter.refresh_list.split(',');
			// 					var requiredFilter = _.find(workspaceFactory.activeScreen.filters.filters, {
			// 						'filter_key': refresh_array[refresh_array.length-1]
			// 					});
			// 					if (typeof requiredFilter === 'undefined' || requiredFilter.selected === null ||
			// 						_.isEmpty(requiredFilter.selected)) {
			// 						//Do Nothing
			// 					} else {
			// 						workspaceFactory.activeScreen.filters.checkDataRefresh(requiredFilter, workspaceFactory.activeScreen.filter_key);
			// 					}
			// 				}
			// 			}
			// 		});
			// 	}
			// },true);

			$scope.$on('dataFilters:refreshGrid', function (event, args) {
				refreshData();
			});

			function refreshData() {
				vm.showData = true;
				workspaceFactory.activeScreen.filters.getFilterParams();
				workspaceFactory.setCurrentFilters(workspaceFactory.activeScreen);
				workspaceFactory.activeScreen.loading_data = true;
				var filterTaxYear;
				var filterScenario;
				var filterFilingGroup;
				var filterHoLeidKey;
				var hoLeid;
				var hoCdrNo;
				var hoReportingPeriod;
				if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams)) {
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.tax_year)) {
						filterTaxYear = workspaceFactory.activeScreen.filters.filterParams.tax_year;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.scenario)) {
						filterScenario = workspaceFactory.activeScreen.filters.filterParams.scenario;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.filing_key)) {
						filterFilingGroup = workspaceFactory.activeScreen.filters.filterParams.filing_key;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key)) {
						if (workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key === null) {
							//Do Nothing
						} else {
							filterHoLeidKey = JSON.parse(workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key);
							if (angular.isDefined(filterHoLeidKey.HO_LEID)) {
								hoLeid = filterHoLeidKey.HO_LEID;
								vm.hoLeid = hoLeid;
							}
							if (angular.isDefined(filterHoLeidKey.HO_LE_NAME)) {
								vm.entityName = filterHoLeidKey.HO_LE_NAME;
							}
							if (angular.isDefined(filterHoLeidKey.HO_CDR_NO)) {
								hoCdrNo = filterHoLeidKey.HO_CDR_NO;
								vm.hoTaxCode = hoCdrNo;
							}
							if (angular.isDefined(filterHoLeidKey.HO_REPORTING_PERIOD)) {
								hoReportingPeriod = filterHoLeidKey.HO_REPORTING_PERIOD;
							}
							if (angular.isDefined(filterHoLeidKey.HO_FUNCTIONAL_CURRENCY)) {
								vm.funcCurrency = filterHoLeidKey.HO_FUNCTIONAL_CURRENCY;
							}
						}
					}
					vm.selectedHoLeidParams =  {"ho_leid":hoLeid,"ho_cdr_no":hoCdrNo,"ho_reporting_period":hoReportingPeriod, 
												"filing_key":filterFilingGroup, "tax_year":filterTaxYear,"scenario":filterScenario};
				}
				//Reset taxHistoryOptions as Entity changed
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
				vm.columnNames = [];
				vm.qualDefsData = [];
				vm.subfCategoryNames = [];
				vm.subfCategoriesData = [];
				loadGtapsCfcsWithQualDefsData();
			}

			function loadGtapsCfcsWithQualDefsData(selectedTaxYear)
			{
				vm.loading = true;
				if (angular.isDefined(selectedTaxYear) && selectedTaxYear !== '') {
					vm.selectedHoLeidParams.tax_year = selectedTaxYear;
					var index = vm.otherTaxYearsData.findIndex(otherTaxYearRow => (otherTaxYearRow.TAX_YEAR == rowId));
					if (index >= 0) {
						vm.selectedHoLeidParams.scenario = vm.otherTaxYearsData[index].SCENARIO;
						vm.selectedHoLeidParams.parent_me_string = vm.otherTaxYearsData[index].ME_KEY;
					}
				}
				cfcsWithQualDefsFactory.getGtapsCfcsWithQualDefsData(vm.selectedHoLeidParams).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
							vm.displayTable = false;
						} else {				
							vm.cfcsWithQualDefsData = data.jsonObject;
							vm.cacheAllActiveData.cfcsWithQualDefsData = data.jsonObject;
							vm.loadAllData();
							vm.loading = false;
						}
						//workspaceFactory.activeScreen.loading_data = false;
						//workspaceFactory.setCache(workspaceFactory.activeScreen, vm);
				});
			}

			function resetData() {
				/*vm.total959c3EPPoolFuncCurr = 0;
				vm.totalNH959c3EPPoolFuncCurr = 0;
				vm.totalPtiFuncCurr = 0;
				vm.total959c3EPPoolUSD = 0;
				vm.totalNH959c3EPPoolUSD = 0;
				vm.totalPtiUSD = 0;
				vm.totalTaxPool = 0;
				vm.totalNHTaxPool = 0;
				vm.hovering959c3Ep = 0;
				vm.hovering959c3Taxes = 0;
				vm.totalUSDBasisInPti = 0;
				vm.effectiveRateOn959c3TotalEp = 0;
				vm.effectiveRateOn959c3TotalEpNH = 0;
				vm.estimatedPtiExchangeGainLoss = 0;
				vm.recaptureAccounts = "No";
				vm.qualifiedDeficits = "No";*/
			}

			vm.loadAllData = function() {
				//Reset all Data
				resetData();
				vm.columnNames = [];
				vm.qualDefsData = [];
				vm.subfCategoryNames = [];
				vm.subfCategoriesData = [];
				vm.loading = false;

				/*if (vm.cfcsWithQualDefsData[0].hasOwnProperty("RECAPTURE_ACCT_COUNT")) {
					vm.recaptureAccounts = vm.cfcsWithQualDefsData[0].RECAPTURE_ACCT_COUNT > 0 ? "Yes" : "No";
				}
				if (vm.cfcsWithQualDefsData[0].hasOwnProperty("QUAL_DEF_COUNT")) {
					vm.qualifiedDeficits = vm.cfcsWithQualDefsData[0].QUAL_DEF_COUNT > 0 ? "Yes" : "No";
				}
				if (vm.cfcsWithQualDefsData[0].hasOwnProperty("PTI_BASIS_TOTAL_AMT")) {
					vm.totalUSDBasisInPti = vm.cfcsWithQualDefsData[0].PTI_BASIS_TOTAL_AMT;
				}
				if (vm.cfcsWithQualDefsData[0].hasOwnProperty("EP_SUMMARY_INFO_JSON")) {
					var epSummaryInfoJson = JSON.parse(vm.cfcsWithQualDefsData[0].EP_SUMMARY_INFO_JSON);
					if (epSummaryInfoJson.length > 0) {
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EP_FC")) {
							vm.total959c3EPPoolFuncCurr = epSummaryInfoJson[0].POST_1986C3EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3EP_FC")) {
							vm.totalNH959c3EPPoolFuncCurr = epSummaryInfoJson[0].POST_HD_1986C3EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EPUSD")) {
							vm.total959c3EPPoolUSD = epSummaryInfoJson[0].POST_1986C3EPUSD;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3EPUSD")) {
							vm.totalNH959c3EPPoolUSD = epSummaryInfoJson[0].POST_HD_1986C3EPUSD;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3TAXES")) {
							vm.totalTaxPool = epSummaryInfoJson[0].POST_1986C3TAXES;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3TAXES")) {
							vm.totalNHTaxPool = epSummaryInfoJson[0].POST_HD_1986C3TAXES;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("HD_EP_FC")) {
							vm.hovering959c3Ep = epSummaryInfoJson[0].HD_EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("HD_TAX_FC")) {
							vm.hovering959c3Taxes = epSummaryInfoJson[0].HD_TAX_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("ETRONPOST_1986C3EP")) {
							vm.effectiveRateOn959c3TotalEp = epSummaryInfoJson[0].ETRONPOST_1986C3EP;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("ETRONPOST_HD_1986C3EP")) {
							vm.effectiveRateOn959c3TotalEpNH = epSummaryInfoJson[0].ETRONPOST_HD_1986C3EP;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("PTIFC")) {
							vm.totalPtiFuncCurr = epSummaryInfoJson[0].PTIFC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("PTIUSD")) {
							vm.totalPtiUSD = epSummaryInfoJson[0].PTIUSD;
						}
					}
				}*/

				vm.subfCategoryNames = [];
				vm.subfCategoriesData = [];
				if (vm.cfcsWithQualDefsData[0].hasOwnProperty("GET_SUBF_AMTS_JSON")) {
					var subfAmtsJson = JSON.parse(vm.cfcsWithQualDefsData[0].GET_SUBF_AMTS_JSON);
					angular.forEach(subfAmtsJson, function(subfAmtsRow) {
						if (subfAmtsRow.hasOwnProperty("CATEGORY_NAME") && subfAmtsRow.hasOwnProperty("AMT")) {
							vm.subfCategoryNames.push(subfAmtsRow.CATEGORY_NAME);
							vm.subfCategoriesData.push(subfAmtsRow);
						}
					});
				}
				//Calculate estimatedPtiExchangeGainLoss
				if (angular.isDefined(vm.totalPtiUSD) && angular.isDefined(vm.totalUSDBasisInPti)) {
					vm.estimatedPtiExchangeGainLoss = vm.totalPtiUSD - vm.totalUSDBasisInPti;
				}

				var index = 0;
				vm.columnNames = [];
				vm.qualDefsData = [];
				angular.forEach(vm.cfcsWithQualDefsData, function(qualDefsRow) {
					if (index === 0) {
						//Do Nothing
					} else {
						if (vm.columnNames.length === 0) {
							angular.forEach(qualDefsRow, function(value, columnName) {
								if (columnName.toUpperCase() !== 'ENDRATE' && columnName.toUpperCase() !== 'REPORTING_PERIOD') {
									vm.columnNames.push(columnName);
								}
							});
						}
						vm.qualDefsData.push(qualDefsRow);
					}
					index++;
				});
				//Check if table has to be displayed or not
				if (vm.columnNames && vm.columnNames.length > 1) {
					vm.displayTable = true;
				} else{
					vm.displayTable = false;
				}
				vm.cacheAllActiveData.commonPtiData = gtapsCommonTemplateFactory.statusPti.jsonObject;
				  console.log("|||||||| cmmon pti data||||||||||" , vm.cacheAllActiveData.commonPtiData)
				vm.cacheAllActiveData.commonPtepData = gtapsCommonTemplateFactory.statusPtep.jsonObject;
				activeScreenEp.loading_data = false;
			    workspaceFactory.setCache(activeScreenEp, vm.cacheAllActiveData);
			}

			/*vm.goToScreen = function(screenName) {
				if (vm.menuData) {
					angular.forEach(vm.menuData, function (channelNode) {
						if (channelNode.label === 'Tax Return') {
							angular.forEach(channelNode.children, function (taskNode) {
								if (taskNode.label === 'GTAPS') {
									angular.forEach(taskNode.children, function (activityNode) {
										if (activityNode.label === screenName) {
											var _node = activityNode;
											console.log("activity:", _node);
											if (_node.children && _node.children.length) {
												_node.collapsed = !_node.collapsed;
											} else {
												menuDataService.removeSelected();
												_node.selected = true;
												menuDataService.setSelected(_node); //THIS DOES THE HIGHLIGHTING OF PARENT NODES
												$scope.currentNode = _node;
												if (_node.screens && _node.screens.length && _node.screens[0].link) {
													_node.screens[0].filters = workspaceFactory.activeScreen.filters;
													_node.screens[0].data = "LOAD";
													workspaceFactory.setActivity(_node, true);
												} else {
													$state.go('404', {}, {
														inherit: true
													});
													AlertService.add("warning", "No screens defined for this activity");
												}
											}
										}
									});
								}
							});
						}
					});
				}
			}*/
		}
});
