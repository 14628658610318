define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F1120ScheduleKCtrl', [])

    	.directive("limitToMax", function() {
		return {
			link: function(scope, element, attributes) {
				element.on("keydown keyup", function(e) {
					if (Number(element.val()) > Number(attributes.max) &&
					  e.keyCode != 46 // delete
					  &&
					  e.keyCode != 8 // backspace
					) {
						e.preventDefault();
						element.val(attributes.max);
					}
					});
				}
			};
		})

        .controller('F1120ScheduleKController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','$filter',F1120ScheduleKController]);
    	function F1120ScheduleKController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, $filter) {
            var vm = this;

            vm.modalTitle = "Schedule K Other Information";
    		vm.originalData = [];
    		vm.userSettings = USER_SETTINGS;
    		vm.logged_in_user = vm.userSettings.user.sso_id;
    		vm.leId = rowData.LEID;
			vm.isSaveClicked = false;
            vm.formName = '1120';
        	getcodeMastDetails();
            vm.hgt = '96%';
            vm.cdr_no = rowData.MARS;
            vm.leType = rowData.LE_TYPE;
            vm.systemFlag = 'D';
            vm.einErrorList = [];

            vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
            $scope.entityList = {
    			"Tax Year": rowData.TAX_YEAR,
    			"LEID": rowData.LEID,
    			"LE Name": rowData.LE_NAME
    		};
    		$scope.dropdownSetting ={
    			enableSearch: true,
    			selectionLimit: 1,
    			scrollableHeight: '200px', 
    			scrollable: true,
    			showUncheckAll:false,
    			showCheckAll:false,
    			smartButtonTextConverter: function(itemText, originalItem) { 
                    return itemText; 
    			},
    			smartButtonTextProvider:function(selectionArray) { return selectionArray[0].label || "Select"; },
    		};

    		$scope.multiselectEvents1 = {
    			onItemSelect: function (item) {
                    console.log(item);
    			},
    			onItemDeselect: function (item) {
    				console.log('deselect',item);

    			}
    		};
 
    		var saveParamDtls = [];
    		var tempDataObj = {};
    		$scope.scheduleKFormView = {rows:[]};
    		vm.showFormLoader = true;
    		vm.corporationList = [];
    		vm.entityList = [];
    		vm.entitiesData = [];
    		vm.individualsList = [];
    		vm.codeMastMap = [];

            function fetchSchKrecords() {
                var params = { "action_id": 31333, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY, "form_name": "F1120", "schedule_name": "K", "part_no" : "NA"};

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
    				for(var i=1;i<data.irsForm.formItemList.length;i++){
    					saveParamDtls = [];
    					tempDataObj = {};
    					saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
    					var rows = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
    					tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
                    	tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;
                    	if(rows > 2){
                    		iterateArray(rows, data.irsForm.formItemList[i].dataRowList[0].dataCellList);
                    	}
    					tempDataObj.line_order = saveParamDtls[8];
    					tempDataObj.saveParams = saveParamDtls;
    					$scope.scheduleKFormView.rows.push(tempDataObj);
    				}
  
    			
				  if(vm.codeMastMap.length>1){
	              	   angular.forEach(vm.codeMastMap, function(obj,key){
	                  	   if($scope.scheduleKFormView.rows[5].value == obj.CODE_NAME){
	                  		 $scope.scheduleKFormView.rows[5].value = obj.CODE_NAME;
	                  	   }
	                     }) 
	                     console.log('code list response success');
	                 } 
    				vm.originalData = angular.copy($scope.scheduleKFormView);
    				initFormView($scope.scheduleKFormView);
    				vm.showFormLoader = false; 
                });
            };

            //check for 7
            var iterateArray = function(count, array){
            	if(count == 3){
    				tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    				tempDataObj.attrib_order_a = array[2].key.split('~')[2];
    				
    				if(tempDataObj.num == "2c" ){
    					tempDataObj.value_user_input = array[2].value;
    				}
    				//line 3
    				if(tempDataObj.num == "3a" || tempDataObj.num == "3b" || tempDataObj.num == '3c' ){
						var line3Value = _.filter($scope.scheduleKFormView.rows, function(o) { 
							return o.num == '3'; 
						})
						if(String(line3Value[0].value) != "true"){
							tempDataObj.value_user_input = array[2].value;
							tempDataObj.conditionalField = true;
						}else{
							tempDataObj.value_user_input = array[2].value;
							tempDataObj.conditionalField = false;
						}
						
					}
    				
    				//line 7
    				if(tempDataObj.num == "7a" || tempDataObj.num == "7b" || tempDataObj.num == '7c' ){
						var line7Value = _.filter($scope.scheduleKFormView.rows, function(o) { 
							return o.num == '7'; 
						})
						if(String(line7Value[0].value) != "true"){
							tempDataObj.value_user_input = array[2].value;
							tempDataObj.conditionalField = true;
						}else{
							tempDataObj.value_user_input = array[2].value;
							tempDataObj.conditionalField = false;
						}
						
					}
    				//line 26
    				if(tempDataObj.num == "26a" || tempDataObj.num == "26b" ){
						var line26Value = _.filter($scope.scheduleKFormView.rows, function(o) { 
							return o.num == '26'; 
						})
						if(String(line26Value[0].value) != "true"){
							tempDataObj.value_user_input = array[2].value;
							tempDataObj.conditionalField = true;
						}else{
							tempDataObj.value_user_input = array[2].value;
							tempDataObj.conditionalField = false;
						}
						
					}
    				if(array[2].value == null){//checkbox
    					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '8' ||  tempDataObj.num == '11'){
    						tempDataObj.value = false;
    					}else {
    							tempDataObj.value = '';
    						}
    				}else if(array[2].value === "true"){
    					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '8' ||  tempDataObj.num == '11'){
    						tempDataObj.value = true;
    					}else {
    						tempDataObj.value = '';
    					}
    				}else if (array[2].value === "false"){
    					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '8' || tempDataObj.num == '11'){
    						tempDataObj.value = false;
    					}else {
    						tempDataObj.value = '';
    					}
    				}else if (array[2].value === "0"){
    					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '8' || tempDataObj.num == '11'){
    						tempDataObj.value = false;
    					}else {
    						tempDataObj.value = '';
    					}
    				}else if (array[2].value == 'NA'){
    					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '8' || tempDataObj.num == '11'){
    						tempDataObj.value = false;
    					}else {
    						tempDataObj.value = '';
    					}
    				}else {
    					tempDataObj.value = array[2].value;
    				}
    			}else if(count == 4){
    				if(tempDataObj.num == "1c"){
    					tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    					tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    					tempDataObj.attrib_order_a = array[2].key.split('~')[2];
    					tempDataObj.value = array[2].value;
    					tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
    					tempDataObj.attrib_name_b = array[3].key.split('~')[0];
    					tempDataObj.attrib_order_b = array[3].key.split('~')[2];
    					tempDataObj.value_user_input = array[3].value;
    					if(tempDataObj.value == null){
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}else if(tempDataObj.value === "true"){
    						tempDataObj.value = true;
    						tempDataObj.conditionalField = false;
    					}else if (array[2].value === "false"){
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}else if (array[2].value === "0"){
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}else {
    						tempDataObj.value = false;
    						tempDataObj.conditionalField = true;
    					}
    				}else{
    					tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    					tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    					tempDataObj.attrib_order_a = array[2].key.split('~')[2];

    					tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
    					tempDataObj.attrib_name_b = array[3].key.split('~')[0];
    					tempDataObj.attrib_order_b = array[3].key.split('~')[2];

    					if(array[2].value === "true"){
    						tempDataObj.value = true;
    					}else if(array[3].value === "true"){
    						tempDataObj.value = false;
    					}else{
    						tempDataObj.value = 'NA';
    					}

    					if(tempDataObj.num == '4a'){
    						tempDataObj.JSON_DATA = vm.entitiesData;
    					}else if(tempDataObj.num == '4b'){
    						tempDataObj.JSON_DATA = vm.individualsList;
    					}else if(tempDataObj.num == '5a'){
    						tempDataObj.JSON_DATA = vm.corporationList;
    					}else{
    						tempDataObj.JSON_DATA = vm.entityList;
    					}
    				}
    			}else{
    				tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
    				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
    				tempDataObj.attrib_order_a = array[2].key.split('~')[2];

    				tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
    				tempDataObj.attrib_name_b = array[3].key.split('~')[0];
    				tempDataObj.attrib_order_b = array[3].key.split('~')[2];

    				if(array[2].value === "true"){
    					tempDataObj.value = true;
    				}else if(array[3].value === "true"){
    					tempDataObj.value = false;
    				}else{
    					tempDataObj.value = 'NA';
    				}
    				tempDataObj.lineAttrKey_c = array[4].key.split('~')[1];
    				tempDataObj.attrib_name_c = array[4].key.split('~')[0];
    				tempDataObj.attrib_order_c = array[4].key.split('~')[2];
    				if(tempDataObj.value === true){
    					if(tempDataObj.num == "3a" || tempDataObj.num == "3b" || tempDataObj.num == "3c" || tempDataObj.num == "7a" || tempDataObj.num == '7b' || tempDataObj.num == '7c' || tempDataObj.num == '13' || tempDataObj.num == '21' || tempDataObj.num == '25' || tempDataObj.num == '26a' || tempDataObj.num == '26b'){
    						tempDataObj.value_user_input = array[4].value;
    						tempDataObj.conditionalField = false;
    					}
    				}else{
    					if(tempDataObj.num == "3a" || tempDataObj.num == "3b" || tempDataObj.num == "3c" || tempDataObj.num == "7a" || tempDataObj.num == '7b' || tempDataObj.num == '7c' || tempDataObj.num == '13' || tempDataObj.num == '21' || tempDataObj.num == '25' || tempDataObj.num == '26a' || tempDataObj.num == '26b'){
    						tempDataObj.value_user_input = array[4].value;
    						tempDataObj.conditionalField = true;
    					}
    				}
    			}
    		};
    		
    		vm.limitDecimal = function(val, line, i, attr){//value_user_input
    			if(((line == '7a' || line == '26a' || line == '26b') && attr == null)){
    				var index = _.findIndex($scope.scheduleKFormView.rows, {num: line});
    				if (Number(val) > 100){
    					$scope.scheduleKFormView.rows[index].value_user_input = 100;
    				}
    			}else{
    				var index = _.findIndex($scope.scheduleKFormView.rows, {num: line});
    				if (Number(val) > 100){
    					$scope.scheduleKFormView.rows[index].JSON_DATA[i][attr] = 100;
    				}
    				
    			}
            };


            function fetchSubTableRecords(){
                var params = {
                	combination_key: rowData.COMBINATION_KEY,
                	schedule: "K"
                };
    			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=2xuiy5', params).then(function (data) {
    				angular.forEach(data.jsonObject, function (row, key) {
    					var temp;
    					if(row.LINE_NO == '4a1'){
    						// row.DATA_ATTRIB_D = vm.allCountries.filter(country => row.DATA_ATTRIB_D == country.COUNTRY_ID );
    						row.DATA_ATTRIB_D = findCountryID(row.DATA_ATTRIB_D);
    						row.DATA_ATTRIB_E = parseFloat(row.DATA_ATTRIB_E);
    	            		vm.entitiesData.push(row);
    					}else if(row.LINE_NO == '4b1'){
    						// row.DATA_ATTRIB_C = vm.allCountries.filter(country => row.DATA_ATTRIB_C == country.COUNTRY_ID );
    						row.DATA_ATTRIB_C = findCountryID(row.DATA_ATTRIB_C);
    						row.DATA_ATTRIB_D = parseFloat(row.DATA_ATTRIB_D);
    	            		vm.individualsList.push(row);
    					}else if(row.LINE_NO == '5a1'){
    						// row.DATA_ATTRIB_C = vm.allCountries.filter(country => row.DATA_ATTRIB_C == country.COUNTRY_ID );
    						row.DATA_ATTRIB_C = findCountryID(row.DATA_ATTRIB_C);
    	            		row.DATA_ATTRIB_D = parseFloat(row.DATA_ATTRIB_D);
    	            		vm.corporationList.push(row);
    	            	}else{
    						// row.DATA_ATTRIB_D = vm.allCountries.filter(country => row.DATA_ATTRIB_D == country.COUNTRY_ID );
    						row.DATA_ATTRIB_C = findCountryID(row.DATA_ATTRIB_C);
    	            		row.DATA_ATTRIB_D = parseFloat(row.DATA_ATTRIB_D);
    	            		vm.entityList.push(row);
    	            	}
    	        	});
                });
    		};
    		function findCountryID(temp){
    			for (let i =0 ; i< vm.allCountries.length ;i++){
    				if(vm.allCountries[i].id == temp){
    					return [vm.allCountries[i]];
    				}
    			}
    			return [];
    		}
    		
    		function fetchCountryList(){
    			var params = {
                	// combination_key: rowData.COMBINATION_KEY,
                	// schedule: "K"
                };
    			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=kgdgoh').then(function (data) {
    				vm.allCountries = []
    				data.jsonObject.forEach(function(country, index){
    					if(country.COUNTRY_CODE == "US") {
    						vm.USCountry_index =  index;
    					}
    					vm.allCountries.push({"id":country.COUNTRY_CODE,"label":country.COUNTRY_DESC})
    				});
                });
    		}
    		function fetchUSStateList(){
    			var params = {
                	// combination_key: rowData.COMBINATION_KEY,
                	// schedule: "K"
                };
    			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=yh4p2z').then(function (data) {
    				vm.allUSStates =[];
    				 data.jsonObject.forEach(function(state){
    					 vm.allUSStates.push({"id":state.STATE_CODE,"label":state.STATE_DESC})
    				 });
                });
    		}
            
    		//get EIN values
    		function fetchEINList() {
				var params = { "action_code": '0jdhck', "sso_id": vm.logged_in_user, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": GlobalService.globalParams.jcd_key, "form_no":'F1120_K_EIN' };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					vm.einList = data.jsonObject;

				});
			}
            
         // get code mast details data for line 2 dropdown
	        function getcodeMastDetails(){
	      	  var params = {
	     	   			"action_code": 'kdep1i',
	     	   			"sso_id": vm.logged_in_user ,
	     	   			"tax_year": rowData.TAX_YEAR,
	     	   			"jcd_key": GlobalService.globalParams.jcd_key,
	     	   			"scenario": rowData.SCENARIO
	     	   		 } 
	   	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
	   	             if (data.callSuccess === "1" ) {
	   	            	vm.codeMastMap = data.jsonObject;
	   	             } else {
	   	            	 if(data.errorMessage === "no access"){
	   	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
	   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	   	               }else {
	   	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
	   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	   	                 }
	   	             }
	
	   	         });
   	     	};
   	     	
   	     	//for line 2a dropdown
   	     	vm.updateColumnB = function(cVal){
   	   	     	if ( typeof(cVal) !== "undefined" && cVal !== null ) {
   	   	     	angular.forEach(vm.codeMastMap, function(obj,key){
   		     			if(obj.CODE_NAME == cVal){
   		     			$scope.scheduleKFormView.rows[6].value = obj.CODE_DESC;
   		     			}
   		     		})
   		     		console.log(cVal);
   	   	     	}else{
   	   	     		 $scope.scheduleKFormView.rows[6].value ='';
   	   	     	}
   	   	     		
   	     	}
   	     	
   	     	//for EIN validation
   	     	vm.einValidation = function(ein, line_no){
	   	     	var pattern = /^\d{9}$/;
	   	     	vm.validEIN = pattern.test(ein);
	   	     	
	   	     	if(!vm.validEIN  && (ein != null && ein !== "")){
	   	     		var string = ein.toUpperCase(); 
		   	     	if(!_.find(vm.einList, {CODE_DESC: string})) {
		   	     		if(!_.find(vm.einErrorList, {num: line_no})){
		   	     		var returnObj1 = {};
		   	     			returnObj1['num'] =  line_no;
		   	     			returnObj1['valid'] =  vm.validEIN;
		   	     			vm.einErrorList.push(returnObj1);
//		   	     			vm.einErrorList.push(vm.validEIN , line_no);
		   	     		}
		   	     	}else{
		   	     		vm.validEIN = true;
			   	     	_.remove(vm.einErrorList, function(currentObject) {
			   	     		return currentObject.num === line_no;
			   	     	});
		   	     	}
	   	     	}else{
			   	      	_.remove(vm.einErrorList, function(currentObject) {
			   	     		return currentObject.num === line_no;
			   	     	});
	   	     	}
   	     	}

    		function initSchK(){
    			fetchUSStateList();
    			fetchCountryList().then(function(){
    				fetchSubTableRecords();
    				fetchSchKrecords();
    				fetchEINList();
    			});
    			
    			
    		};
    		initSchK(); 
            
    
    		//Checkbox check check for 7
    		$scope.fieldVisibility = function (index, state, name, rowNum){
    			if(index <= 3){
    				if(state && index == 1){
    					setValue([2,3], [3], true);
    				}else if(state && index == 2){
    					setValue([1,3], [3], true);
    				} else{
    					setValue([1,2], [3], !$scope.scheduleKFormView.rows[3].conditionalField);
    				}
    			//}else if(index == 8){
    			//	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
    			}else if(index == 32){
    				checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
    			}else if(index == 41){
    				checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
    			}else if(index == 48){
    				checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
    			}else if (rowNum == 3){//line 3
    				
    				if((name == 'yes' && !state) || (name == 'no' && state)){
        				$scope.scheduleKFormView.rows[index].value = 'NA';
    				};
    				
    				if(state){
    					var line3Values = _.filter($scope.scheduleKFormView.rows, function(o) { 
    						if(o.num === '3a' || o.num === '3b' || o.num === '3c'){
    							o.conditionalField = false;
    							return ; 
    						}
						});
    				}else{
    					var line3Values = _.filter($scope.scheduleKFormView.rows, function(o) { 
    						if(o.num === '3a' || o.num === '3b' || o.num === '3c'){
    							o.conditionalField = true;
    							o.value_user_input = '';
    							o.value = '';
								if(vm.einErrorList.length != 0 && o.num === '3c'){
									_.remove(vm.einErrorList, function(currentObject) {
										return currentObject.num === o.num;
									});
								}
    							return ; 
    						}
						});
    				}
    				
    			}else if (rowNum == 7){//line 7
    				
    				if((name == 'yes' && !state) || (name == 'no' && state)){
        				$scope.scheduleKFormView.rows[index].value = 'NA';
    				};
    				
    				if(state){
    					var line7Values = _.filter($scope.scheduleKFormView.rows, function(o) { 
    						if(o.num === '7a' || o.num === '7b' || o.num === '7c'){
    							o.conditionalField = false;
    							return ; 
    						}
						});
    				}else{
    					var line7Values = _.filter($scope.scheduleKFormView.rows, function(o) { 
    						if(o.num === '7a' || o.num === '7b' || o.num === '7c'){
    							o.conditionalField = true;
    							o.value_user_input = '';
    							o.value = '';
    							return ; 
    						}
						});
    				}
    			}else if (rowNum == 26){//line 26
    				if((name == 'yes' && !state) || (name == 'no' && state)){
        				$scope.scheduleKFormView.rows[index].value = 'NA';
    				};
    				
    				if(state){
    					var line26Values = _.filter($scope.scheduleKFormView.rows, function(o) { 
    						if(o.num === '26a' || o.num === '26b'){
    							o.conditionalField = false;
    							return ; 
    						}
						});
    				}else{
    					var line26Values = _.filter($scope.scheduleKFormView.rows, function(o) { 
    						if(o.num === '26a' || o.num === '26b'){
    							o.conditionalField = true;
    							o.value_user_input = '';
    							o.value = '';
    							return ; 
    						}
						});
    				}
    					
    			}else {
    				$scope.scheduleKFormView.rows[index].conditionalField = !$scope.scheduleKFormView.rows[index].conditionalField;
    				if((name == 'yes' && !state) || (name == 'no' && state)){
    					$scope.scheduleKFormView.rows[index].value = 'NA';
    				};
    			}
    		};

    		function setValue(arr, toggle_i, state){
    			angular.forEach(arr, function (value, key) {
    				$scope.scheduleKFormView.rows[value].value = false;
    			});
    			$scope.scheduleKFormView.rows[toggle_i].conditionalField = state;
    			$scope.scheduleKFormView.rows[toggle_i].value_user_input = '';
    		};

    		function checkBoxToggle (index, name, state, arr, val, attr){
    			if((name == 'yes' && !state) || (name == 'no' && state)){
    				$scope.scheduleKFormView.rows[index].value = 'NA';
    				if(name == 'yes' && !state){
    					angular.forEach(arr, function (value, key) {
    						$scope.scheduleKFormView.rows[value].conditionalField = true;
    						$scope.scheduleKFormView.rows[value].value_user_input = '';
    					});
    				}
    			}else{
    				toggleCondition(arr, state, val, attr);
    			}
    		};

    		function toggleCondition (index, state, type, attr){
    			angular.forEach(index, function (value, key) {
                	if(($scope.scheduleKFormView.rows[value].conditionalField && !state) || (!$scope.scheduleKFormView.rows[value].conditionalField && state)){
    					return;
    				}else {
    					$scope.scheduleKFormView.rows[value].conditionalField = !$scope.scheduleKFormView.rows[value].conditionalField;
    					$scope.scheduleKFormView.rows[value].value_user_input = '';
    				}
            	});
    		};

    		vm.addRows = function(type, i){
    			var newRow = {
    				OCCURRENCE : $scope.scheduleKFormView.rows[i].JSON_DATA.length + 1,
    				DATA_ATTRIB_A : "",
    				DATA_ATTRIB_B : "",
    				DATA_ATTRIB_C : [vm.allCountries[vm.USCountry_index]],
    				DATA_ATTRIB_D : ""
    			};
    			if(type == 'entity'){
    				newRow.DATA_ATTRIB_C = "";
    				newRow.DATA_ATTRIB_D = [vm.allCountries[vm.USCountry_index]];
    				newRow.DATA_ATTRIB_E = "";
    			}

    			$scope.scheduleKFormView.rows[i].JSON_DATA.push(newRow);
    		};

            vm.saveSchdKForm = function (url, data){
                var jsonSettings = {
                	"tax_year": rowData.TAX_YEAR,
                	"scenario": rowData.SCENARIO,
                	"jcd_key": rowData.JCD_KEY,
                	"combination_key": rowData.COMBINATION_KEY,
                	"trans_type_key": data[0].editrows[0][0].trans_type_key
                };
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(data);
                var jsonSettings  = JSON.stringify(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                params.process_name =  'F1120_SCHD_K'; /*workspaceFactory.activeScreen.label;*/
                params.combination_key = rowData.COMBINATION_KEY;
                params.trans_type_key = data[0].editrows[0][0].trans_type_key;
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                    console.log("saveSchdKForm() response.data.jsonObj ::",response.data.jsonObject);
    				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
    					$uibModalInstance.dismiss('cancel');
    					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
    					$rootScope.$emit('gridUpdate', args);
    					AlertService.add("success", "Transactions saved successfully", 4000);
    				}else{
						vm.isSaveClicked = false;
    					if(response.data && response.data.errorMessage){
                            AlertService.add("error", response.data.errorMessage,4000);
                        }else{
                            AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                        }
    				}
                    return response.data;
                });
                console.log("------------At the end-----------", promise);
                return promise;
            };

            vm.save = function(){
    			var formRowDataA = {};
    			var formRowDataB = {};
    			var tempArray = [];
				vm.isSaveClicked = true;
    			/*if(!vm.isValid()){
    				AlertService.add("error", "Please provide the mandatory fields.",4000);
    				return;
    			}*/
    			if(vm.einErrorList.length != 0){
    				AlertService.add("error", "Please enter a valid Employer Identification Number");
					vm.isSaveClicked = false;
					return;
    				
    			}
//    			if(!vm.validEIN){
//					AlertService.add("error", "Please enter a valid Employer Identification Number");
//					vm.isSaveClicked = false;
//					return;
//				}
    			for(var i=0; i<$scope.scheduleKFormView.rows.length ;i++){
    				
    				formRowDataA = {
    					tax_year: rowData.TAX_YEAR,
    					trans_type_key: $scope.scheduleKFormView.rows[i].saveParams[1],
    					line_order: $scope.scheduleKFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleKFormView.rows[i].attrib_order_a,
                        line_description: $scope.scheduleKFormView.rows[i].description,
    					combination_key: rowData.COMBINATION_KEY,
    					group_obj_key: rowData.GROUP_OBJ_KEY,
    					form_key: $scope.scheduleKFormView.rows[i].saveParams[2],
    					line_no: $scope.scheduleKFormView.rows[i].num,
    					occurence: $scope.scheduleKFormView.rows[i].saveParams[7],
    					line_attrib_key: $scope.scheduleKFormView.rows[i].lineAttrKey_a,
    					Attribute_Name: $scope.scheduleKFormView.rows[i].attrib_name_a,
    					//Attribute_Value: $scope.scheduleKFormView.rows[i].value === true ? 'true' : $scope.scheduleKFormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleKFormView.rows[i].value === false ? 'false' :  $scope.scheduleKFormView.rows[i].value ? $scope.scheduleKFormView.rows[i].value.toString() : '',
    					Attribute_Value: ($scope.scheduleKFormView.rows[i].num == '2c' || $scope.scheduleKFormView.rows[i].num == '3a' || $scope.scheduleKFormView.rows[i].num == '3b' || $scope.scheduleKFormView.rows[i].num == '3c' || $scope.scheduleKFormView.rows[i].num == '7a' || $scope.scheduleKFormView.rows[i].num == '7c' || $scope.scheduleKFormView.rows[i].num == '26a' || $scope.scheduleKFormView.rows[i].num == '26b') ? $scope.scheduleKFormView.rows[i].value_user_input : $scope.scheduleKFormView.rows[i].value === true ? 'true' : $scope.scheduleKFormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleKFormView.rows[i].value === false ? 'false' :  $scope.scheduleKFormView.rows[i].value ? $scope.scheduleKFormView.rows[i].value.toString() : '',
    					trans_dtls_key: $scope.scheduleKFormView.rows[i].saveParams[3],
    					trans_status: $scope.scheduleKFormView.rows[i].saveParams[6],
    					is_dirty: ''
    				};
    				if((formRowDataA.line_no == '5a1' || formRowDataA.line_no == '5b1' || formRowDataA.line_no == '4a1' || formRowDataA.line_no == '4b1') && ($scope.scheduleKFormView.rows[i-1].value == true && $scope.scheduleKFormView.rows[i-1].JSON_DATA.length > 0)){
    					var attributes = formRowDataA.line_no == '4b1' || formRowDataA.line_no == '5a1' || formRowDataA.line_no == '5b1'  ? ['A', 'B', 'C', 'D'] : ['A', 'B', 'C', 'D', 'E'];
    					var tempDataArray = [];
    					formRowDataA.JSON_DATA = [];
    					angular.forEach($scope.scheduleKFormView.rows[i-1].JSON_DATA, function (row, rowKey) {
    						var hasData = false;
    						tempDataArray = [];
    						angular.forEach(attributes, function (value, colKey) {
    							var index = formRowDataA.JSON_DATA.length / 4 == 0 ? 1 : formRowDataA.JSON_DATA.length / 4 + 1;
    							var tempObj, tempValue ;
    							if(Array.isArray(row["DATA_ATTRIB_" + value])){
    								tempValue = row["DATA_ATTRIB_" + value].length ? row["DATA_ATTRIB_" + value][0].id : "" ;
    							}else{
    								tempValue = row["DATA_ATTRIB_" + value] ;
    							}

    							tempObj = {
    								OCCURRENCE : index,	
    								ATTRIB_NAME : "DATA_ATTRIB_" + value,
    								ATTRIB_VALUE : tempValue // Array.isArray(row["DATA_ATTRIB_" + value]) ?  row["DATA_ATTRIB_" + value][0].id : row["DATA_ATTRIB_" + value] 
    							}
    							
    							
    							
    							if(tempObj.ATTRIB_VALUE !== undefined && tempObj.ATTRIB_VALUE !== ""){
    								hasData = true;
    							}
    							tempDataArray.push(tempObj);
    						});
    						if(hasData){
    							angular.forEach(tempDataArray, function (column, colKey) {
    								formRowDataA.JSON_DATA.push(column);
    							});
    						}
    					});
    				}else{
    					formRowDataA.JSON_DATA = [];
    				}
    				tempArray.push(formRowDataA);

    				if(typeof $scope.scheduleKFormView.rows[i].attrib_name_b !== "undefined"){
    					if($scope.scheduleKFormView.rows[i].num == '1c'){
    						formRowDataB = {
    							tax_year: rowData.TAX_YEAR,
    							trans_type_key: $scope.scheduleKFormView.rows[i].saveParams[1],
    							line_order: $scope.scheduleKFormView.rows[i].line_order,
    	                    	attrib_order: $scope.scheduleKFormView.rows[i].attrib_order_b,
    	                    	line_description: $scope.scheduleKFormView.rows[i].description,
    							combination_key: rowData.COMBINATION_KEY,
    							group_obj_key: rowData.GROUP_OBJ_KEY,
    							form_key: $scope.scheduleKFormView.rows[i].saveParams[2],
    							line_no: $scope.scheduleKFormView.rows[i].num,
    							occurence: $scope.scheduleKFormView.rows[i].saveParams[7],
    							line_attrib_key: $scope.scheduleKFormView.rows[i].lineAttrKey_b,
    							Attribute_Name: $scope.scheduleKFormView.rows[i].attrib_name_b,
    							Attribute_Value: $scope.scheduleKFormView.rows[i].value_user_input,
    							trans_dtls_key: $scope.scheduleKFormView.rows[i].saveParams[3],
    							trans_status: $scope.scheduleKFormView.rows[i].saveParams[6],
    							is_dirty: ''
    						};
    						tempArray.push(formRowDataB);
    					}else{
    						formRowDataB = {
    							tax_year: rowData.TAX_YEAR,
    							trans_type_key: $scope.scheduleKFormView.rows[i].saveParams[1],
    							line_order: $scope.scheduleKFormView.rows[i].line_order,
    	                    	attrib_order: $scope.scheduleKFormView.rows[i].attrib_order_b,
    	                    	line_description: $scope.scheduleKFormView.rows[i].description,
    							combination_key: rowData.COMBINATION_KEY,
    							group_obj_key: rowData.GROUP_OBJ_KEY,
    							form_key: $scope.scheduleKFormView.rows[i].saveParams[2],
    							line_no: $scope.scheduleKFormView.rows[i].num,
    							occurence: $scope.scheduleKFormView.rows[i].saveParams[7],
    							line_attrib_key: $scope.scheduleKFormView.rows[i].lineAttrKey_b,
    							Attribute_Name: $scope.scheduleKFormView.rows[i].attrib_name_b,
    							Attribute_Value: $scope.scheduleKFormView.rows[i].value === true ? 'false' : $scope.scheduleKFormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleKFormView.rows[i].value === false ? 'true' : $scope.scheduleKFormView.rows[i].value ? $scope.scheduleKFormView.rows[i].value.toString() : '',
    							trans_dtls_key: $scope.scheduleKFormView.rows[i].saveParams[3],
    							trans_status: $scope.scheduleKFormView.rows[i].saveParams[6],
    							is_dirty: ''
    						};
    						tempArray.push(formRowDataB);
    					}
    				}

    				if(typeof $scope.scheduleKFormView.rows[i].attrib_name_c !== "undefined"){
    					formRowDataB = {
    						tax_year: rowData.TAX_YEAR,
    						trans_type_key: $scope.scheduleKFormView.rows[i].saveParams[1],
    						line_order: $scope.scheduleKFormView.rows[i].line_order,
                        	attrib_order: $scope.scheduleKFormView.rows[i].attrib_order_c,
                        	line_description: $scope.scheduleKFormView.rows[i].description,
    						combination_key: rowData.COMBINATION_KEY,
    						group_obj_key: rowData.GROUP_OBJ_KEY,
    						form_key: $scope.scheduleKFormView.rows[i].saveParams[2],
    						line_no: $scope.scheduleKFormView.rows[i].num,
    						occurence: $scope.scheduleKFormView.rows[i].saveParams[7],
    						line_attrib_key: $scope.scheduleKFormView.rows[i].lineAttrKey_c,
    						Attribute_Name: $scope.scheduleKFormView.rows[i].attrib_name_c,
    						Attribute_Value: $scope.scheduleKFormView.rows[i].value_user_input,
    						trans_dtls_key: $scope.scheduleKFormView.rows[i].saveParams[3],
    						trans_status: $scope.scheduleKFormView.rows[i].saveParams[6],
    						is_dirty: ''
    					};
    					tempArray.push(formRowDataB);
    				}
    				
    			}
    			vm.formData.editrows.push(tempArray);
    			vm.mainData = [];
    			vm.mainData.push(vm.formData);
    			console.log(vm.mainData);
    			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=z9frws"; 
    			vm.saveSchdKForm(url,vm.mainData);

                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function() {
                    $scope.$broadcast("showLoader", true);
                },100);
            };


    		vm.reset = function (){
    			$scope.scheduleKFormView = angular.copy(vm.originalData);
    		};

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            function initFormView(filteredFormData) {
    			$scope.scheduleKFormView = filteredFormData;
    		};

    		//Dynamic Tabset Code
    		vm.tabs = [
    			 {id: 1, name: "Form Entry", url: "app/components/F1120/F1120-Sch-K/F1120-scheduleKEntry.html", active: true }
    	      //  {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Sch-B/F1065-scheduleBEntry.html", active: true }
    	    ];
        }
});