define([
    'angular'
], function () {
    'use strict';


    var services = angular.module('app.gridServices', [])

        .factory("GridFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG', function ($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG) {
            var gridFactory = {};

            ///////////////////////////////////////////////
            //TO BE USED WITH WORKSPACE ACTIVITIES
            ///////////////////////////////////////////////

            gridFactory.getDataSplitProcess = function (_url, _filterArray, _refresh, drillDownRefresh) {
                var refresh = (!_refresh || typeof _refresh.refresh === "undefined" ? false : _refresh.refresh),
                    activeScreen = workspaceFactory.activeScreen;
                var def = $q.defer();
                var promises = [];
                 /*********************************************************************************************************
                 *   Divya check the drilldwon refresh is 1 then clear the workspace cache make the new ajax call  08/26/2019
                  ********************************************************************************************************/
                if(drillDownRefresh == 1){
                    workspaceFactory.clearCache(workspaceFactory.activeScreen);

                }else if (!refresh && workspaceFactory.activeScreen.data) {   /****** CHECK IF DATA IS CACHED *****/
                    def.resolve(workspaceFactory.activeScreen.data);
                    workspaceFactory.activeScreen.data.firstLoad = 0; // Grid is cached so mark as loaded
                    workspaceFactory.checkFilters(workspaceFactory.activeScreen);
                    return def.promise;
                }

                //CLEAR CACHE
                if (workspaceFactory.activeScreen.data) {
                    workspaceFactory.clearCache(workspaceFactory.activeScreen);
                }

                //Tracks what filter were passed when data was loaded: We can then let the user know if the filters and data are not the same.
                workspaceFactory.setCurrentFilters(workspaceFactory.activeScreen);
                workspaceFactory.activeScreen.loading_data = true;

                angular.forEach(_filterArray, function (val, key) {
                    promises.push(gridFactory.loadData(_url, {}, val));
                });

                $q.all(promises).then(function success(dataArray) {
                    var mergedData = [];

                    ////console.log("BEFORE MERGER  ")

                    for (var i = 0; i < dataArray.length; i++) {
                        $.merge(mergedData, dataArray[i].data);
                     //mergedData.concat(dataArray[i].data).uni
                    }

                    var dataArr = dataArray[0];
                    dataArr.data = mergedData;
                    var gridData = processData(dataArr);
                    activeScreen.loading_data = false;
                    gridData.screen_key = activeScreen.screen_key;
                    gridData.firstLoad = 1;


                    workspaceFactory.setCache(activeScreen, gridData);
                    def.resolve(workspaceFactory.activeScreen.data);

                    dataArr = null;
                    gridData = null;
                    mergedData = null;
                }, function failure(err) {
                    // Can handle this is we want
                });

                promises = null;
                return def.promise;
            };



            gridFactory.loadData = function (_url, _refresh, _params) {

                var canceler = $q.defer();
                var params = _.merge({}, GlobalService.globalParams, _params);
                if ((params.tax_year && params.tax_year.length)) {
                    params.scenario = GlobalService.getScenario(params.tax_year);
                }

                var promise = $http({
                    method: "post",
                    url: _url,
                    data: params,
                    timeout: canceler.promise
                }).then(function (response) {
                    return response.data;
                });

                return promise;

            }


            function processData(activeScreen,data) {
               // var activeScreen = workspaceFactory.activeScreen;
                activeScreen.loading_data = false;
                if(data.settings.grid_type == 'import' || data.settings.grid_type == 'custom'){
                    data.settings.hasPivot = 0;
                } else{
                    data.settings.hasPivot = 1;

                }
                data.batchSelect = [];
                data.selected = {
                    color: "green",
                    select_type: null,
                    row_id: null,
                    col_id: null,
                    obj_id: null,
                    data_type: null,
                    edit_type: null,
                    validation: null,
                    action: null,
                    format: null,
                    value: null,
                    oldValue: null,
                    rowData: null,
                    editable: false,
                    element: null
                }

                data.tableState = {
                    sort: {},
                    search: {},
                    colSearch: [],
                    pagination: {
                        start: 0
                    }
                };
                data.cellData = {};
                data.vDragg = {
                    draggerPositionY: 0,
                    scrollPositionY: 0,
                    startIndex: 0,
                    endIndex: 0
                }

                data.hDragg = {
                    draggerPositionX: 0,
                    scrollPositionX: 0,
                    startIndex: 0,
                    endIndex: 0,
                    maxStartIndex: 0
                };

                data.screen_key = activeScreen.screen_key;
                data.firstLoad = 1;

                if(data && data.settings != undefined){
                   data.settings.commentType = 'all';
                   data.settings.batch_select_rules = (data.settings.batch_select_rules) ? JSON.parse(data.settings.batch_select_rules) : "";
                }
                workspaceFactory.setCache(activeScreen, data);
                processTemplates(data);
                return data;
            }

            gridFactory.getFluxDataStream = function (options, startFn, nodeFn, doneFn) {
                var params = _.merge({}, GlobalService.globalParams, options.filterObj);
                var data = JSON.stringify(params);
                oboe({
                    url: options.url,
                    method: 'POST',
                    body: params,
                    headers: {
                        'Content-Type': 'application/json',
                        'Content-Length': data.length,
                    },
                })
               .start(startFn)
               .node(options.path, nodeFn)
               .done(doneFn);
            }

            gridFactory.getData = function (_url, _refresh, _params,drillDownRefresh) {

                var refresh = (!_refresh || typeof _refresh.refresh === "undefined" ? false : _refresh.refresh),
                    activeScreen = workspaceFactory.activeScreen;

                /*********************************************************************************************************
                 *   Divya check the drilldwon refresh is 1 then clear the workspace cache make the new ajax call  08/26/2019
                ********************************************************************************************************/
                if(drillDownRefresh == 1){
                    workspaceFactory.clearCache(workspaceFactory.activeScreen);
                }else
                if (!refresh && workspaceFactory.activeScreen.data) {
                    var def = $q.defer();
                    def.resolve(workspaceFactory.activeScreen.data);
                    workspaceFactory.activeScreen.data.firstLoad = 0; // Grid is cached so mark as loaded
                    workspaceFactory.checkFilters(workspaceFactory.activeScreen);
                    return def.promise;
                }

                    //CLEAR CACHE
                if (workspaceFactory.activeScreen.data) {
                    workspaceFactory.clearCache(workspaceFactory.activeScreen);

                }
                    workspaceFactory.setCurrentFilters(workspaceFactory.activeScreen);
                    //Tracks what filter were passed when data was loaded: We can then let the user know if the filters and data are not the same.
                    workspaceFactory.activeScreen.loading_data = true;
                    var canceler = $q.defer();

                    //console.log("GlobalService.globalParams ============================== , ", GlobalService.globalParams);
                    
                   /*  if(workspaceFactory.activeScreen.screen_type == "grid-import"){
                        //Temporary fix for dynamic scenario change.
                        GlobalService.globalParams.scenario = GlobalService.globalParams.scenario_v1 ? GlobalService.globalParams.scenario_v1 : GlobalService.globalParams.scenario;
                    } */

                    var params = _.merge({}, GlobalService.globalParams, _params);
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};

                    //console.log("filterParams ============================== , ", filterParams);
                    //console.log("  workspaceFactory.activeScreen.filters ============================== , ", workspaceFactory.activeScreen.filters);
                    //console.log("params ============================== , ", params);
                    /* NOT NEEDED ANY MORE DELETE ME ----------------------------------------------------------------
                            ----------------------------------------------------------------
                            ----------------------------------------------------------------
                            ----------------------------------------------------------------
                            ///////// TEMP HACK /////////////: This will not be needed when scenario is added to filter try;
                           if( (params.tax_year && params.tax_year.length && typeof  params.scenario === "undefined" ) ){
                               params.scenario = GlobalService.getScenario(params.tax_year);
                           }

                       ///////// TEMP HACK /////////////: end
                             if( workspaceFactory.activeScreen.screen_key ){
                                params.screen_key = workspaceFactory.activeScreen.screen_key;
                            }
                         */

                    var promise = $http({
                        method: "post",
                      //  url: _url + "&records=" + 100000+ "&threshold="+50000,
                         url: _url,
                        data: params,
                        timeout: canceler.promise
                    }).then(function (response) {

                        //todo: use function in workspaceFactory to set this value
                       
                    if((response && response.data && response.data.compressed && response.data.split_response
                        || response.data && response.data.compressed ) && response.data.files == undefined){
                        response.data.data =  gridFactory.processGridData(response.data.header_keys,response.data.data);
                        var gridData = processData(activeScreen,response.data);
                        return gridData;
                    }
                    //|| (response.split_response && response.files)
                    else if(response &&( !response.data.compressed && !response.data.split_response) || (response.data.split_response && response.data.files == undefined)  ){
                   
                      var gridData = processData(activeScreen,response.data);
                        return gridData;
                    }
                    else{
                        response.data.activeScreen = activeScreen;
                         return response.data;
                    }
                });
                return promise;


            };

            gridFactory.getFilesData = function (activeScreen,files, res) {
                var def = $q.defer();
                var promises = [], gridData

                angular.forEach(files, function (val, key) {
                    var url = GENERAL_CONFIG.base_url+"/getGridDataFromFile?name=" + val + "&compressed=" + res.compressed;
                    promises.push(gridFactory.loadFiles(url));
                });

                $q.all(promises).then(function success(dataArray) {
                    for (var i = 0; i < dataArray.length; i++) {
                        $.merge(res.data, dataArray[i].data);
                    }
                    if(res.compressed){
                        res.data =  gridFactory.processGridData(res.header_keys,res.data);

                    }
                    gridData = processData(activeScreen,res);

                    delete res.files;
                    def.resolve(gridData);
                }, function failure(err) {
                    // Can handle this is we want
                });
                promises = null;
                return def.promise;
            };

            gridFactory.processGridData = function (header,data){
                var dataArray = [];

                _.each(data,function(d,i){
                    var dataObj = {};
                    for (i=0; i < d.length; i++) {
                            dataObj[header[i]] = d[i];
                    }

                    dataArray.push(dataObj);
                });
              //  console.log("dataArray",dataArray);
                return dataArray;
            }


            gridFactory.loadFiles = function (_url) {
                var canceler = $q.defer();
                var promise = $http({
                    method: "GET",
                    url: _url,
                    timeout: canceler.promise
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }




            function processTemplates(data) {


                //HEATH ADDED 2/7/2019 to parse edit_rules
                _.forEach(data.header, function (value, key) {
                    if (value.edit_rules) {
                        value.edit_rules = JSON.parse(value.edit_rules);
                    }
                });



                if (data.columnTemplates) {
                    _.forEach(data.columnTemplates, function (value, key) {
                        try {
                            value.columns = JSON.parse(value.columns);
                        } catch (e) {
                            //console.error("Column template was not a JSON String")
                            value.columns = "";
                        }
                    });
                } else {
                    data.columnTemplates = [];
                }






                if (data.header && data.header.length) {
                    var templateArr = data.header.map(function (header) {
                        return { // return what new object will look like
                            width: header.width,
                            display: header.display,
                            map: header.map
                        };
                    });
                    var defaultTemplate = {
                        name: "Default Columns",
                        template_key: 0,
                        system: 1,
                        default_template: 0,
                        columns: templateArr
                    }
                    data.columnTemplates.unshift(defaultTemplate);
                }

            }


            ///////////////////////////////////////////////
            //TO BE USED WITH STANDALONE GRIDS (CUSTOM)
            ///////////////////////////////////////////////

            gridFactory.getDataUpdate = function (_url, _params) {

                var canceler = $q.defer();
                var params = _.merge({}, GlobalService.globalParams, _params);
                var activeScreen = workspaceFactory.activeScreen;

                if ((params.tax_year && params.tax_year.length)) {
                    params.scenario = _params.scenario ? _params.scenario: GlobalService.getScenario(params.tax_year); /* 7/15/2020 */
                }

                var promise = $http({
                    method: "post",
                    url: _url,
                    data: params,
                    timeout: canceler.promise
                }).then(function (response) {
                    response.data.activeScreen = activeScreen;
                    return response.data;
                });
                return promise;
            };




            ///////////////////////////////////////////////
            // GRID INPUT SAVE
            ///////////////////////////////////////////////


            gridFactory.updateInput = function (_url, _params) {
                var canceler = $q.defer();
                var params = _.merge({}, GlobalService.globalParams, _params);
                var promise = $http({
                    method: "post",
                    url: _url,
                    data: params,
                    timeout: canceler.promise
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };






            ///////////////////////////////////////////////
            //TO BE USED WITH STANDALONE GRIDS (CUSTOM)
            ///////////////////////////////////////////////

            gridFactory.getDataCustom = function (_url, _params) {
                var canceler = $q.defer();
                // var params =  GlobalService.globalParams;
                var params = angular.merge({}, GlobalService.globalParams, (_params || {}));
                var promise = $http({
                    method: "post",
                    url: _url,
                    data: params,
                    timeout: canceler.promise
                }).then(function (response) {

                    response.data.batchSelect = [];
                    response.data.selected = {
                        color: "green",
                        select_type: null,
                        row_id: null,
                        col_id: null,
                        obj_id: null,
                        data_type: null,
                        edit_type: null,
                        validation: null,
                        action: null,
                        format: null,
                        value: null,
                        oldValue: null,
                        rowData: null,
                        editable: false,
                        element: null
                    }

                    response.data.tableState = {
                        sort: {},
                        search: {},
                        colSearch: [],
                        pagination: {
                            start: 0
                        }
                    };
                    response.data.cellData = {};
                    response.data.selectedActionTemplate={};
                    response.data.vDragg = {
                        draggerPositionY: 0,
                        scrollPositionY: 0,
                        startIndex: 0,
                        endIndex: 0
                    }

                    response.data.hDragg = {
                        draggerPositionX: 0,
                        scrollPositionX: 0,
                        startIndex: 0,
                        endIndex: 0,
                        maxStartIndex: 0
                    };


                    response.data.firstLoad = 1;
                    if(response.data && response.data.settings != undefined){
                        response.data.settings.commentType = 'all';
                        response.data.settings.batch_select_rules = (response.data.settings.batch_select_rules) ? JSON.parse(response.data.settings.batch_select_rules) : "";
                    }
                    processTemplates(response.data);
                    return response.data;
                });
                return promise;
            };


            /////////////////////////////////////////////////
            // To be used by ExportToExcel
            ////////////////////////////////////////////////
            gridFactory.getExcelUrl = function (_params) {
                var globalParams = GlobalService.globalParams;
                var finalParams = _.merge({}, globalParams, _params);
                return finalParams;
            };

 
            return gridFactory;
        }])








        .factory('GridSaveFactory', ['$state','$rootScope', '$timeout', '$interval', '$http', '$injector', 'GlobalService', 'DataFiltersFactory',
        'workspaceFactory', 'AlertService', 'GENERAL_CONFIG', function ($state,$rootScope, $timeout, $interval, $http, $injector, GlobalService, DataFiltersFactory, workspaceFactory, AlertService, GENERAL_CONFIG) {
            var saveFactory = {};

            // create an array of alerts available globally
            var saveServiceMthds = [];
            var loadSaveServiceMethod = function () {
                $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=6beggz',
                    data: {}
                }).then(function (response) {
                    saveServiceMthds = response.data.jsonObject;
                });
            }
            loadSaveServiceMethod();

            //TODO: WE will need to store the service and method with the edit info in the grid header
            saveFactory.saveAuditCellData = function (_data,auditData) {
                //console.log("audit data",data);
                var filterCached = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParamsFromCache() : {};
                var oldValue;
                if(_data.oldRowData){
                    oldValue= _data.oldRowData[_data.colData.map]
                }
                var data = {
                    state: $state.current.name,
                    filters:JSON.stringify(filterCached),
                    cell_audit_data: _data.rowData[_data.colData.map],
                    type: "grid",
                    screen_key : GlobalService.globalParams.screen_key,
                    activity_key : GlobalService.globalParams.activity_key,
                    row_key: auditData.row_key,
                    col_key: auditData.col_key,
                    location_element : auditData.location_element,
                    oldCellData : oldValue,
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + '/saveAuditCell',
                    data: data
                }).then(function (response) {
                    if(response && response.data && response.data.callSuccess == 1){
                        $rootScope.$broadcast("loadGridMessages", 'all');
                    }
                    return response;
                });
                return promise;
            };

            saveFactory.saveSwitch = function (_callName, _data) {
                switch (_callName) {
                    case "rowValidation":
                        if (_data.rowData.validated) {
                            //console.log("_data.rowData.validated: ", _data.rowData.validated);
                            _data.rowData.validated = 'false';
                        }
                        break;
                    default: {
                        var callBackMethod = function (response) {
                            if (callObj && callObj.CALLBACK_METHOD == 1) {
                                $rootScope.$broadcast("gridRefresh", true);
                            }
                            if (_data.colData.audit_cells == "1"){
                                var auditData = {
                                    col_key:_data.col_id,
                                    row_key:_data.row_id,
                                    location_element:_data.selected_type,
                                }
                                saveFactory.saveAuditCellData(_data,auditData);
                            }
                        };
                        var callObj = _.find(saveServiceMthds, {
                            SCREEN_NAME: _callName
                        });
                        var service = $injector.get(callObj.SERVICE_NAME);
                        if (_data.colData.audit_cells == "1") {
                            service[callObj.METHOD_NAME](_callName,_data).then(callBackMethod );
                          //  eval("$injector.get('" + callObj.SERVICE_NAME + "')." + callObj.METHOD_NAME + "(_callName , _data).then(" + callBackMethod + ");");
                        } else if (callObj && callObj.CALLBACK_METHOD == 1) {
                            service[callObj.METHOD_NAME](_callName,_data).then(callBackMethod );
                         //   eval("$injector.get('" + callObj.SERVICE_NAME + "')." + callObj.METHOD_NAME + "(_callName , _data).then(" + callBackMethod + ");");
                        } else if (callObj) {
                            service[callObj.METHOD_NAME](_callName,_data);
                          //  eval("$injector.get('" + callObj.SERVICE_NAME + "')." + callObj.METHOD_NAME + "(_callName , _data);");
                        }
                    }
                    break;
                }
            }



            saveFactory.update = function (url, _params) {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, filterParams, _params);
                _.merge({}, GlobalService.globalParams, params);


                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });
            }



            saveFactory.colTemplatesUpdate = function (_grid_key, _type, _data) {

                var url = GENERAL_CONFIG.base_url + "/saveGridColumnTemplate"
                var params = {};

                params.grid_key = _grid_key;
                params.type = _type;
                params.name = _data.name;
                params.template_key = _data.template_key;
                params.default_template = _data.default_template;
                params.system = _data.system;
                params.columns = JSON.stringify(_data.columns);
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                params = _.merge({}, filterParams, params);
                params = _.merge({}, GlobalService.globalParams, params);
                params.fixed_columns = _data.fixed_columns;
                params.header_height = _data.header_height;

                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    if (response.data.callSuccess != "0") {
                        var status = response.status;

                        if (_type === 'create') { //UPDATE TEMPLATE KEY
                            _data.template_key = response.data.success;
                        }
                       // $rootScope.$broadcast('loadColumnTemplates', response.data.columnTemplates);
                        return response.data;
                    } else {
                        AlertService.add("error", "Error occurred saving template data. If this continues please contact support");
                    }

                });

                return promise;
            }

            saveFactory.deleteBatchDocuments = function(params){

                var promise= $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + '/removeAllBatchDocuments',
                    data: params
                }).then(function (response) {
                    return response;
                });
                return promise;
            }







            return saveFactory;

        }])



        .factory('GridValidationFactory', ['$rootScope', '$timeout', '$interval', '$http', '$injector', 'GlobalService', 'DataFiltersFactory', 'workspaceFactory', 'AlertService', function ($rootScope, $timeout, $interval, $http, $injector, GlobalService, DataFiltersFactory, workspaceFactory, AlertService) {
            var validationFactory = {};





            validationFactory.colNetZero = function (_data, _maps,_cols, _messageKey,_bufferSize,headerObj) {


                var buffer =  250;
                var returnObject = {
                    error: false,
                    errorMsg: ""
                };

                _.forEach(_cols,function(value,key){
                    var total = 0;
                    var map  = _maps[key];
                    _.forEach(_data, function (value2, key2) {
                        if(value2[value] != null){
                            total +=  parseFloat(value2[value]);
                        }
                    });
                     total = Number(total.toFixed(0));

                    if( Math.abs(total) > _bufferSize){
                        returnObject.error = true;
                        returnObject.errorMsg += "Error: Please make sure column '"+ map + "' nets to zero. ";
                    }
                })

             /*    _.forEach(_cols, function (value, key) {
                    _.forEach(_data, function (value2, key2) {
                        //console.log("value2.value" , value2[value.colMap]);
                        if(value2[value.colMap] != null){
                            value.total +=  parseFloat(value2[value.colMap]);
                        }

                    });


                    value.total =  value.total.toFixed(0);

                    if( Math.abs(value.total) > buffer){
                        returnObject.error = true;
                        returnObject.errorMsg += "Error: Please make sure column '"+ value.colName + "' nets to zero. ";
                    }


                }); */


                if(returnObject.error){
                    _data[0][_messageKey] =  returnObject.errorMsg;
                    _data[0].row_class = 'gg-error-row';
                }else{
                    delete _data[0][_messageKey];
                    delete _data[0].row_class;
                }

                return returnObject;


            }


            validationFactory.groupNetZero = function (_data, _key, _value, _messageKey,_bufferSize,headerObj) {

                var message = ""
                var groupObject = {};
                var buffer =  250;
                var returnObject = {
                    error: false,
                    errorMsg: "Error: Please make sure all groups net to zero."
                };
               // _value = _value[0];
                var helper = {};
                var result = _data.reduce(function(r, o) {
                    var key = "";
                    var keyObj = {};
                        _.each(_key,function(v, k){
                            var kk = _.isNumber(o[v])?o[v]:o[v]!=null? o[v].replace(/\s/g, ''):o[v];
                            if(kk !=null && kk !=""){
                                if(key == ""){
                                    key =  kk;
                                    keyObj[v] = o[v];
                                }else{
                                    key = key + '-' +  kk;
                                    keyObj[v] = o[v];
                                }
                            }
                            
                        });
                        _.each(_value,function(i){
                            keyObj[i] = o[i];
                        });
                        // console.log("ket OB",keyObj);
                        // console.log("akey",key);
                        if(!helper[key]) {
                           // helper[key] = Object.assign({}, o); // create a copy of o
                           helper[key] = keyObj;
                            r.push({[key]:helper[key]});
                        } else {
                            let hasRoundOff = {};

                            _.each(_value,function(i){
                                hasRoundOff = _.find(headerObj,function(ih){
                                        return ih.map == i && ih.round_off=="1";
                                    
                                })
                                if(hasRoundOff){
                                    helper[key][i] =  Math.round(Number(helper[key][i]));
                                    helper[key][i] += Math.round(Number(o[i]));
                                }else{
                                    helper[key][i] =  Number(helper[key][i]);
                                    helper[key][i] += Number(o[i]);
                                }
                               
                            });
                            console.log('hasRoundOff',hasRoundOff);
                            console.log('helpr',helper);
                       // helper[key][_value] += Number(o[_value]);
                        }
                        return r;
                }, []);
               // console.log("adfafdaf",result);
                _.each(_data,function(item){
                    var key = "";
                    var groupObj = "";
                    _.each(_key,function(v, k){
                        var kk = _.isNumber(item[v])?item[v]:item[v]!=null?item[v].replace(/\s/g, ''):item[v];
                        if(kk!=null  && kk !=""){
                            if(key == ""){
                                key = kk
                                groupObj = v;
                            }else{
                                key = key + '-' + kk;
                                groupObj = groupObj + '-' + v;
                            }
                        }
                        
                    });
                    var rt = "";
                    for(var kk in result){
                        for(var subkey in result[kk]) {
                            if(subkey == key){
                                rt = result[kk][subkey];
                            }
                        }
                    }
                    _.each(_value,function(i){
                        // if( Math.abs(rt[i]) > _bufferSize){
                        //     returnObject.error = true;
                        //     returnObject.errorMsg += "Error: Please make sure column '"+ map + "' nets to zero. ";
                        // }
                        
                        if(Math.abs(rt[i]) > _bufferSize) {
                            item[_messageKey] = "Group does not net to zero : sum = " + groupObj;
                            item.row_class = 'gg-error-row';
                            returnObject.error = true;
                        }
                    });
                })
                /* _.forEach(_data, function (value, key) {
                    var name = value[_key]
                    if (groupObject[name]) {
                        groupObject[name] += Number(value[_value]);
                    } else {
                        groupObject[name] = Number(value[_value]);
                    }
                })


                _.forEach(_data, function (value, key) {
                    var name = value[_key];
                    if (groupObject[name] != 0) {
                        value[_messageKey] = "Group does not net to zero : sum = " + groupObject[name]
                        value.row_class = 'gg-error-row';
                        returnObject.error = true;
                    } else {
                        delete value[_messageKey];
                        delete value.row_class;
                    }
                })*/

                return returnObject;
            }



            validationFactory.requiredFields = function (_data) {

                var returnObject = {
                    error: false,
                    errorMsg: "Error: Please fill in all the required fields."
                };


                var headers = _data.header;
                var requiredError = false;
                _.forEach(_data.data, function (value, key) {

                    var rowErrorMessage = "The following field(s) are required: ";
                    var rowError = false;
                    var requiredCount = 0;
                    var mapType;

                    _.forEach(headers, function (hValue, hKey) {



                        // ------- Heath Stein | 11/14/2019
                        // ------- Added '&& value[hValue.map] !==0' to allow 0 to be submitted
                        // ------- if (hValue.required && hValue.required === "1" && !value[hValue.map] ) {
                        if (hValue.required && hValue.required === "1" && (!value[hValue.map] && value[hValue.map] !==0)) {
                            var sep = (requiredCount > 0) ? ", " : " ";
                            rowErrorMessage += sep + hValue.label
                            rowError = true;
                            requiredError = true;
                            ++requiredCount
                        } else if (hValue.required === "0") {
                            /* else if is introduced to address the following situation
                             * ISSUE:
                             * when Import Grid Column is designated as Number
                             * User is importing no values or removes a number value and submits for save
                             * the column value is becoming "" (Empty String).
                             * This is causing Number format exceptions as JAVA/DB layer getting non-number data type
                             * its applicable when the field should have some empty values
                             * FIX:
                             * Grid Column needs to be designed as DATA TYPE : Number, EDIT TYPE=TEXT, VALIDATION=NUMBER
                             *
                             * NOTE: This will make these column values defaults to 0 when values are not entered
                             * */
                            if (hValue.data_type === "number" && hValue.validation === "numeric" && hValue.edit_type === "text") {
                                if (value[hValue.map] === null || value[hValue.map] === "") {
                                    value[hValue.map] = 0;
                                }
                            }
                        } else if (hValue.required === "0") {
                            /* else if is introduced to address the following situation
                             * ISSUE:
                             * when Import Grid Column is designated as Number
                             * User is importing no values or removes a number value and submits for save
                             * the column value is becoming "" (Empty String).
                             * This is causing Number format exceptions as JAVA/DB layer getting non-number data type
                             * its applicable when the field should have some empty values
                             * FIX:
                             * Grid Column needs to be designed as DATA TYPE : Number, EDIT TYPE=TEXT, VALIDATION=NUMBER
                             *
                             * NOTE: This will
                             * */
                            if (hValue.data_type === "number" && hValue.validation === "numeric" && hValue.edit_type === "text") {
                                if (value[hValue.map] === null || value[hValue.map] === "") {
                                    value[hValue.map] = 0;
                                }
                            }
                        } else if (hValue.required === "0") {
                            /* else if is introduced to address the following situation
                             * ISSUE:
                             * when Import Grid Column is designated as Number
                             * User is importing no values or removes a number value and submits for save
                             * the column value is becoming "" (Empty String).
                             * This is causing Number format exceptions as JAVA/DB layer getting non-number data type
                             * its applicable when the field should have some empty values
                             * FIX:
                             * Grid Column needs to be designed as DATA TYPE : Number, EDIT TYPE=TEXT, VALIDATION=NUMBER
                             *
                             * NOTE: This will
                             * */
                            if (hValue.data_type === "number" && hValue.validation === "numeric" && hValue.edit_type === "text") {
                                if (value[hValue.map] === null || value[hValue.map] === "") {
                                    value[hValue.map] = 0;
                                }
                            }
                        }
                        /* its applicable when the field should have some empty values  */
                        else if (hValue.required === "0") {
                            if (hValue.data_type === "number" && hValue.validation === "numeric" && hValue.edit_type === "text") {
                                if (value[hValue.map] === null || value[hValue.map] === "") {
                                    value[hValue.map] = 0;
                                }

                            }
                        }

                    });

                    if (rowError) {
                        returnObject.error = true;
                        value.error_msg = rowErrorMessage;
                        value.row_class += " " + "gg-error-row";
                    } else {
                        delete value.error_msg;
                        delete value.row_class;
                    }


                });


                return returnObject;


            }
            
            validationFactory.issueIdValid = function (_data) {
                var issues = GlobalService.global_issues ? GlobalService.global_issues : [];
                 var returnObject = {
                    error: false,
                    errorMsg: "Error: Please fill with valid issue id."
                };
                
                
                var headers = _data.header;
                _.forEach(_data.data, function (value, key) {

                    var rowErrorMessage = "Issue id is not valid for the tax year and scenario ";
                    var rowError = false;
                    if(issues.length > 0) {
                        rowError = headers.filter(i => i.map === 'issue_key').length > 0 
                            && !(issues.map(i => i.issue_id).includes(value.issue_key.toString()));
                    }

                    if (rowError) {
                        returnObject.error = true;
                        value.error_msg = rowErrorMessage;
                        value.row_class += " " + "gg-error-row";
                    } else {
                        delete value.error_msg;
                        delete value.row_class;
                    }


                });


                return returnObject;


            }



            return validationFactory;


        }])



    return services;


});

