
define([
    'angular',
    './SupplementController',
    './SuplementService',
], function () {
    'use strict';
    return angular.module('app.supplement', ['app.SupplementController','app.SuplementService']) 
    .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider) {
        $stateProvider
            .state('supplement', {
                url: '/supplement',
                templateUrl: 'app\components\supplementDetails\supplement_details_tpl.html',
                controller: 'supplementCtrl as ctrl',
                data: {},
                access: ""
            })
    }]);

});