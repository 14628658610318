define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.Form8865SchdKServices',[])
        .factory("Form8865SchdKServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', Form8865SchdKServicesFactory])
    function Form8865SchdKServicesFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory) {


        Form8865SchdKServicesFactory.load8865SchdKWPDForEntity = function(params) {
            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function (response) {
                    return response;
                }
            );

            return promise;
        }

        Form8865SchdKServicesFactory.save8865SchdKWPDForEntity = function(_settings, _data) {
            let save_url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=4d65p3";
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonObj = JSON.stringify(_data);
            let jsonSettings =  JSON.stringify(_settings);

            console.log(jsonSettings);
            let params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);


            params.tax_year = filterParams.tax_year;
            params.scenario = filterParams.scenario;
            params.jcd_key = GlobalService.globalParams.jcd_key;
            params.process_name =  "Save F8865 Schd-K WPD for Entity";
            params.sso_id = _settings.sso_id;

            console.log('params = ', params);
            console.log('clob_data = ', params.clob_data);
            console.log('clob_settings = ', params.clob_settings);

            var promise = $http({
                method: "post",
                url: save_url,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        }

        return Form8865SchdKServicesFactory;

    }

    return services;
});