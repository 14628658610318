define([
    'angular',
    './EntityChartMapController',
    './EntityChartMapService'



], function () {
    'use strict';
    return angular.module('app.entityChartMap', ['app.entitychartmapController','app.entitychartmapService'] );


});