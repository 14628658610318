define(
		[ 'angular'

		],
		function() {
			'use strict';
			var controllers = angular.module('app.SourcingCtrl', [])
					.controller(
							'sourcingData',
							[ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'GlobalService', 'AlertService','$timeout','workspaceFactory', 'USER_SETTINGS', 'GENERAL_CONFIG','$uibModal',
								'$uibModalInstance', 'SourcingServiceFactory', 'gridData', sourcingData ])

			.controller('sourcingPercentageCheckModal', [ '$rootScope', '$scope', '$state', '$log','$uibModalInstance','AlertService', 'moreAccountsToShow','accountSourcingData','saveFlag','assestSourcing', sourcingPercentageCheckModal ])
			
			/**
			 * @Author: Abhiruchi Sharma
			 */
			
            function sourcingPercentageCheckModal($rootScope, $scope, $state, $log , $uibModalInstance, AlertService, moreAccountsToShow,accountSourcingData,saveFlag,assestSourcing){
                var vmodal = this;
				vmodal.moreAccountsToShow = angular.copy(moreAccountsToShow);
				vmodal.accountSourcingData = angular.copy(accountSourcingData);
				vmodal.saveFlag = angular.copy(saveFlag);
				vmodal.assestSourcing = angular.copy(assestSourcing);
				
				vmodal.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				vmodal.removeAccountRow = function(name){					
					var index = vmodal.accountSourcingData.indexOf(name);
					vmodal.accountSourcingData.splice(index, 1);
					for(var key in accountSourcingData){
						if(accountSourcingData[key].ACCT == name.ACCT )
							accountSourcingData[key].SOURCED_CHECKBOX = undefined;
					}
			
				};
				
				vmodal.removeOptionsRow = function(name){					
					var index = vmodal.moreAccountsToShow.indexOf(name);
					vmodal.moreAccountsToShow.splice(index, 1);
					for(var key in moreAccountsToShow){
						if(moreAccountsToShow[key].ACCT == name.ACCT )
							moreAccountsToShow[key].SOURCED_CHECKBOX = undefined;
					}
				};
				
				
				
				vmodal.saveBookPercentage = function(name){		
					
					if(vmodal.newPercentage != undefined){
						vmodal.bookPercentage = 	vmodal.newPercentage;
						// For More Options			
								for(var key in moreAccountsToShow){
									if(moreAccountsToShow[key].SOURCED_CHECKBOX != undefined && moreAccountsToShow[key].SOURCED_CHECKBOX == true){
										moreAccountsToShow[key].BOOK_PERCENT = vmodal.newPercentage;
										moreAccountsToShow[key].BOOK_REMAINDER = moreAccountsToShow[key].BOOK_TOTAL_AMT - moreAccountsToShow[key].BOOK_SOURCE_AMT - ((moreAccountsToShow[key].BOOK_TOTAL_AMT * moreAccountsToShow[key].BOOK_PERCENT)/100)
										moreAccountsToShow[key].NEW_BOOK_AMOUNT_TO_SOURCE = (moreAccountsToShow[key].BOOK_TOTAL_AMT * moreAccountsToShow[key].BOOK_PERCENT)/100;
									}	
								}
						// For General Accounts	
								for(var key in accountSourcingData){
									if(accountSourcingData[key].SOURCED_CHECKBOX != undefined && accountSourcingData[key].SOURCED_CHECKBOX == true){
										accountSourcingData[key].BOOK_PERCENT = vmodal.newPercentage;
										accountSourcingData[key].BOOK_REMAINDER = accountSourcingData[key].BOOK_TOTAL_AMT - accountSourcingData[key].BOOK_SOURCE_AMT - ((accountSourcingData[key].BOOK_TOTAL_AMT * accountSourcingData[key].BOOK_PERCENT)/100);
										accountSourcingData[key].NEW_BOOK_AMOUNT_TO_SOURCE = (accountSourcingData[key].BOOK_TOTAL_AMT * accountSourcingData[key].BOOK_PERCENT)/100;
									}
								}
						saveFlag = true;
						$uibModalInstance.dismiss('cancel');
						
					}else
						AlertService.add("error", "Please enter a valid percentage");
					

					
				};

				vmodal.saveBookSchMPercentage = function(name){		
					if(vmodal.newPercentage != undefined){
						vmodal.SchMPercentage = vmodal.newPercentage;
						
						// For More Options			
						for(var key in moreAccountsToShow){
							if(moreAccountsToShow[key].SOURCED_CHECKBOX != undefined && moreAccountsToShow[key].SOURCED_CHECKBOX == true){
								moreAccountsToShow[key].SCHDM_PERCENT = vmodal.newPercentage;
								moreAccountsToShow[key].SCHDM_REMAINDER = moreAccountsToShow[key].SCHDM_TOTAL_AMT - moreAccountsToShow[key].SCHDM_SOURCE_AMT - ((moreAccountsToShow[key].SCHDM_TOTAL_AMT * moreAccountsToShow[key].SCHDM_PERCENT)/100);
								moreAccountsToShow[key].NEW_SCHDM_AMOUNT_TO_SOURCE = ((moreAccountsToShow[key].SCHDM_TOTAL_AMT * moreAccountsToShow[key].SCHDM_PERCENT)/100);
							}
						}
			// For General Accounts	
						for(var key in accountSourcingData){
							if(accountSourcingData[key].SOURCED_CHECKBOX != undefined && accountSourcingData[key].SOURCED_CHECKBOX == true){
								accountSourcingData[key].SCHDM_PERCENT = vmodal.newPercentage;
								accountSourcingData[key].SCHDM_REMAINDER = accountSourcingData[key].SCHDM_TOTAL_AMT - accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE - ((accountSourcingData[key].SCHDM_TOTAL_AMT * accountSourcingData[key].SCHDM_PERCENT)/100);
								accountSourcingData[key].NEW_SCHDM_AMOUNT_TO_SOURCE = ((accountSourcingData[key].SCHDM_TOTAL_AMT * accountSourcingData[key].SCHDM_PERCENT)/100);
							}
						}
						
						vmodal.bookPercentage = 	vmodal.newPercentage;
						// For More Options			
						for(var key in moreAccountsToShow){
							if(moreAccountsToShow[key].SOURCED_CHECKBOX != undefined && moreAccountsToShow[key].SOURCED_CHECKBOX == true){
								moreAccountsToShow[key].BOOK_PERCENT = vmodal.newPercentage;
								moreAccountsToShow[key].BOOK_REMAINDER = moreAccountsToShow[key].BOOK_TOTAL_AMT - moreAccountsToShow[key].BOOK_SOURCE_AMT - ((moreAccountsToShow[key].BOOK_TOTAL_AMT * moreAccountsToShow[key].BOOK_PERCENT)/100)
							
							}	
						}
						// For General Accounts	
						for(var key in accountSourcingData){
							if(accountSourcingData[key].SOURCED_CHECKBOX != undefined && accountSourcingData[key].SOURCED_CHECKBOX == true){
								accountSourcingData[key].BOOK_PERCENT = vmodal.newPercentage;
								accountSourcingData[key].BOOK_REMAINDER = accountSourcingData[key].BOOK_TOTAL_AMT - accountSourcingData[key].BOOK_SOURCE_AMT - ((accountSourcingData[key].BOOK_TOTAL_AMT * accountSourcingData[key].BOOK_PERCENT)/100);
								accountSourcingData[key].NEW_BOOK_AMOUNT_TO_SOURCE = (accountSourcingData[key].BOOK_TOTAL_AMT * accountSourcingData[key].BOOK_PERCENT)/100;
							}
						}
								
						saveFlag = true;
						$uibModalInstance.dismiss('cancel');
					}else
						AlertService.add("error", "Please enter a valid percentage");
				};
				
				
				vmodal.saveSchMPercentage = function(name){		
					if(vmodal.newPercentage != undefined){
						vmodal.SchMPercentage = vmodal.newPercentage;
						
						// For More Options			
						for(var key in moreAccountsToShow){
							if(moreAccountsToShow[key].SOURCED_CHECKBOX != undefined && moreAccountsToShow[key].SOURCED_CHECKBOX == true){
								moreAccountsToShow[key].SCHDM_PERCENT = vmodal.newPercentage;
								moreAccountsToShow[key].SCHDM_REMAINDER = moreAccountsToShow[key].SCHDM_TOTAL_AMT - moreAccountsToShow[key].SCHDM_SOURCE_AMT - ((moreAccountsToShow[key].SCHDM_TOTAL_AMT * moreAccountsToShow[key].SCHDM_PERCENT)/100);
								moreAccountsToShow[key].NEW_SCHDM_AMOUNT_TO_SOURCE = ((moreAccountsToShow[key].SCHDM_TOTAL_AMT * moreAccountsToShow[key].SCHDM_PERCENT)/100);
							}
						}
			// For General Accounts	
						for(var key in accountSourcingData){
							if(accountSourcingData[key].SOURCED_CHECKBOX != undefined && accountSourcingData[key].SOURCED_CHECKBOX == true){
								accountSourcingData[key].SCHDM_PERCENT = vmodal.newPercentage;
								accountSourcingData[key].SCHDM_REMAINDER = accountSourcingData[key].SCHDM_TOTAL_AMT - accountSourcingData[key].SCHDM_SOURCE_AMT - ((accountSourcingData[key].SCHDM_TOTAL_AMT * accountSourcingData[key].SCHDM_PERCENT)/100);
								accountSourcingData[key].NEW_SCHDM_AMOUNT_TO_SOURCE = ((accountSourcingData[key].SCHDM_TOTAL_AMT * accountSourcingData[key].SCHDM_PERCENT)/100);
							}
						}
						saveFlag = true;
						$uibModalInstance.dismiss('cancel');
					}else
						AlertService.add("error", "Please enter a valid percentage");
				};
				
				
			}
			
			function sourcingData($rootScope, $scope, $state, $log,
					JsonObjectFactory, GlobalService, AlertService, $timeout, workspaceFactory, USER_SETTINGS, GENERAL_CONFIG,$uibModal,
					$uibModalInstance, SourcingServiceFactory, gridData) {
				
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.selectedTagSourcingRow = [];
				vm.loadingData = true;
				vm.selectedGieType;
				vm.loadingDataMoreOptions = true;
				vm.saveFlag = false;
				vm.isSaveClicked = false;
				vm.enableMoreOptions = false;
				vm.enableSaveButton = false;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                //Get the Default Current Year
                vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;
                vm.scenarioDesc;
                vm.qarRadioButton;	
                vm.customRowList =[];
                var object = {};
                vm.loadingDataQar = true;
                
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
								vm.defaultTag = true;
						}							
					});
				});	
				
			if (gridData != null) {
				
					var tempGridData = _.clone(gridData), groups = Object.create(null), result, finalKeyCheck = 0;

					vm.gridDataMultipleSelect = _.clone(tempGridData);
			
					if (vm.gridDataMultipleSelect != undefined) {
						if(vm.gridDataMultipleSelect.rowData.GIE_TYPE == 'ASTEXP' || vm.gridDataMultipleSelect.rowData.GIE_TYPE == 'INVESTMENTS')
							vm.assestSourcing = true;
						else
							vm.assestSourcing = false;
						
						if(vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'EDIT'){
							vm.selectedTagSourcingRow.push(vm.gridDataMultipleSelect.rowData);
							
						}
						
						vm.scenarioDesc = vm.selectedTagSourcingRow[0].SCENARIO_DESC;
						//if(vm.selectedTagSourcingRow[0].SCENARIO_DESC == 'QAR')
					}
				
				
				}
		// This method will bring all the attributes for the given GIE key		
				vm.getAttribNamebyGieName = function() {
					if(vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'EDIT'){
						
						var gie_name = vm.selectedTagSourcingRow[0].GROSS_INC_EXP;
						vm.selectedGieKey = vm.selectedTagSourcingRow[0].GIE_KEY;
						vm.selectedGieType = vm.selectedTagSourcingRow[0].GIE_TYPE;
						vm.selectedGieName = vm.selectedTagSourcingRow[0].GROSS_INC_EXP;
						vm.selectedTransCatgName = vm.selectedTagSourcingRow[0].TRANS_CATG_NAME
						//vm.defaultTag = vm.selectedTagSourcingRow[0].SOURCE_FLAG;
						
					if((vm.selectedTagSourcingRow[0].SOURCE_FLAG == 'CUSTOM' || vm.selectedTagSourcingRow[0].SOURCE_FLAG == 'BI CUSTOM') 
								/*&& vm.selectedTagSourcingRow[0].MODULE_NAME != 'EXTPCS'*/
								&& vm.selectedTagSourcingRow[0].MODULE_NAME != 'DRD'
									&& vm.selectedTagSourcingRow[0].MODULE_NAME != 'FGNPCS' && vm.selectedTagSourcingRow[0].MODULE_NAME != 'GHOST'
								&& vm.selectedTagSourcingRow[0].MODULE_NAME != '8916A' && vm.selectedTagSourcingRow[0].STATUS_FLAG != 'Locked'
									&& vm.selectedTagSourcingRow[0].IS_CHANGED != 'Yes'
							)
							vm.defaultTag = false;
						else
							vm.defaultTag = true;
						if (vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y')
							vm.defaultTag = true;
					}
						
					var params = {
							"action_code" : 'mo2qhs',
							"gie_key" : vm.selectedGieKey,
							"trans_catg_name" : vm.selectedTransCatgName,
							"module_name" : vm.selectedTagSourcingRow[0].MODULE_NAME
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.getIsWhTax = data.jsonObject;
			                	
			                	if(vm.getIsWhTax.length > 0 && vm.getIsWhTax[0].IS_WITHHOLDING_TAX == 'Y' && vm.selectedTagSourcingRow[0].MODULE_NAME != ('FTC', 'EXTPCS'))
			                	{
			                		vm.defaultTag = true;
			                		vm.enableMoreOptions = true;
			                		vm.is_wh_tax = 'Y'
			                	}
			                }
						});
					
					var params = {
							"action_code" : '6qd7am',
							"gie_name" : gie_name
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.getAttribToDisplay = data.jsonObject;
			                	
			                	vm.newArray = vm.getAttribToDisplay.map( function( el ){ 
	                                return el.ATTRIB_DISPLAY_NAME; 
	                               });
			                	
			         //       	vm.selectRadioButton();
			                	vm.getSourceTypeData();
			                	
			                }
						});
					
				}
	
				
		// Get the Source By data and pre-select the Source By for the Tag key selected		
				vm.getSourceTypeData = function() {
					var params = {
							"action_code" : '7vwqqt',
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
							vm.sourceByData = data.jsonObject;
							vm.selectedSourceTypeDesc = vm.selectedTagSourcingRow[0].SOURCE_TYPE_0;
							var exists =_.find(vm.sourceByData, ['CODE_DISP_DESC', vm.selectedTagSourcingRow[0].SOURCE_TYPE_0]) 
		                	if(exists != undefined){
		                		vm.selectedSourceType = exists.CODE_KEY;
		                		vm.previousSourceType = exists.CODE_KEY;
		                	}

							if(vm.selectedGieType != 'GP' && vm.defaultTag == false){
								_.remove(vm.sourceByData, {CODE_DISP_DESC: 'Summary'});
							}
							if(vm.selectedGieType == 'INC' && vm.defaultTag == false)
								_.remove(vm.sourceByData, {CODE_DISP_DESC: 'Percentage'});
								
					
							vm.getAccountSourcingData();
							
		                }
							
					});
				}	
		// this is called when we change the source by from amount to percentage or vice versa		
				vm.changeSourceBy = function() {	
					var templateUrl = 'app/templates/modals/confirm-action.html';
					$scope.confirmModal = $uibModal.open({
						templateUrl: templateUrl,
						scope: $scope,
						windowClass: 'custom',
						controller: ['$scope', '$stateParams', '$uibModalInstance',
							function ($scope, $stateParams, $uibModalInstance) {
								$scope.header = "Confirm";
								$scope.message = "Change Source by will delete all sourced data"
								$scope.confirm = function () {
										vm.loadingData_changeSource = true;
										var params = {
												"action_code" : 'p58j84',
												"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
												"gie_key" : vm.selectedGieKey,
												"source_by"  : vm.selectedSourceType,
												"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY,
												"asset_flag" : (vm.assestSourcing == true ? 'Y' : 'N'),
												'p_scenario' : vm.scenario,
												'p_jcd_key' :vm.jcd_key
											};
										JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
											if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
							                    AlertService.add("error", data.errorMessage);
							                    return false;
							                }else{
							                vm.saveFlag = true;
											vm.accountSourcingData = data.jsonObject;
											
											vm.TotalData = _.filter(vm.accountSourcingData, function(p){
						                		  return _.includes(['Total'], p.ACCT);  
						                		});
											_.remove(vm.accountSourcingData, {ACCT: 'Total'});
											
											
											var exists =_.find(vm.sourceByData, ['CODE_KEY',vm.selectedSourceType]) 
						                	if(exists != undefined){
						                		vm.selectedSourceTypeDesc = exists.CODE_DISP_DESC;
						                	}
											
											var args = {
//													me_code: returnClobSettingsObj.me_code,
//				                                    gridFilter: {
//				                                    	me_code: returnClobSettingsObj.me_code
//				                                    }
				                                };
									//		$uibModalInstance.dismiss('cancel');
											$rootScope.$emit('gridUpdate', args);
											vm.loadingData_changeSource = false;
											$uibModalInstance.close();
							                }
										});
										
										vm.moreAccountsToShow = [];
										vm.uniqueGieMoreOptionsList=[];
										vm.getMoreOptionsData();
										vm.selectedGieTypes = [];
										
										vm.previousSourceType = vm.selectedSourceType;
									  }
										$scope.cancel = function () {
											 vm.selectedSourceType = vm.previousSourceType;
											$scope.closeWindow(false);
											$uibModalInstance.close();
										}

										$scope.$on('$destroy', function () {
											//Do Nothing
										});
								}
											
						]
					});

					$scope.closeWindow = function () {
					}
					}
				
				
				/*vm.changeSourceBy = function() {
					if(confirm("Change Source by will delete all sourced data")) { 
						vm.loadingData_changeSource = true;
						var params = {
								"action_code" : 'p58j84',
								"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
								"gie_key" : vm.selectedGieKey,
								"source_by"  : vm.selectedSourceType,
								"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY,
								"asset_flag" : (vm.assestSourcing == true ? 'Y' : 'N')
							};
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                vm.saveFlag = true;
							vm.accountSourcingData = data.jsonObject;
							
							vm.TotalData = _.filter(vm.accountSourcingData, function(p){
		                		  return _.includes(['Total'], p.ACCT);  
		                		});
							_.remove(vm.accountSourcingData, {ACCT: 'Total'});
							
							
							var exists =_.find(vm.sourceByData, ['CODE_KEY',vm.selectedSourceType]) 
		                	if(exists != undefined){
		                		vm.selectedSourceTypeDesc = exists.CODE_DISP_DESC;
		                	}
							
							var args = {
//									me_code: returnClobSettingsObj.me_code,
//                                    gridFilter: {
//                                    	me_code: returnClobSettingsObj.me_code
//                                    }
                                };
					//		$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
							vm.loadingData_changeSource = false;
			                }
						});
						
						vm.moreAccountsToShow = [];
						vm.uniqueGieMoreOptionsList=[];
						vm.getMoreOptionsData();
						vm.selectedGieTypes = [];
						
						vm.previousSourceType = vm.selectedSourceType;
					  }else{
						  vm.selectedSourceType = vm.previousSourceType;
					  }
				}*/
				
				
		// Go get the accounts and sourcing information		
				vm.getAccountSourcingData = function() {
					
					
					if(vm.selectedTagSourcingRow[0].SOURCE_TYPE_0 == 'Summary'){
						var params = {
								"action_code" : 'bxpuay',
								"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
								"gie_key" : vm.selectedGieKey,
								"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY,
								'p_scenario' : vm.scenario,
								'p_jcd_key' :vm.jcd_key,
								'more_options':vm.is_wh_tax
							};
					}else{
						var params = {
								"action_code" : 'q3lxgm',
								"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
								"gie_key" : vm.selectedGieKey,
								"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY,
								"asset_flag" : (vm.assestSourcing == true ? 'Y' : 'N'),
								'p_scenario' : vm.scenario,
								'p_jcd_key' :vm.jcd_key
							};
					}
					
					
					/*var params = {
							"action_code" : 'q3lxgm',
							"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
							"gie_key" : vm.selectedGieKey,
							"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY
						};*/
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
							vm.accountSourcingData = data.jsonObject;
							
							/*vm.uniqueGieMoreOptionsList1 = _.uniqBy(vm.accountSourcingData , 'ALT_GIE_KEY');
							_.remove(vm.uniqueGieMoreOptionsList, {GIE_KEY: vm.selectedGieKey});*/
							
						// This is done to get the Unique values from list to select in More Options GIE_KEYS			
							vm.uniqueGieMoreOptionsList = _.uniqBy(vm.accountSourcingData , 'ALT_GIE_KEY');
						//	_.remove(vm.uniqueGieMoreOptionsList, {GIE_KEY: vm.selectedGieKey});
							
							vm.TotalData = _.filter(vm.uniqueGieMoreOptionsList, function(p){
		                		  return _.includes(['Total'], p.ACCT);  
		                		});
							
						// This is done to remove the GIE Keys which are selected in More Options	
							if(vm.uniqueGieMoreOptionsList.length > 0 ) // vm.enableMoreOptions == true
								vm.accountSourcingData = vm.accountSourcingData.filter(function( obj ) {
									return obj.GIE_KEY == vm.selectedGieKey;
									});
							/*var newObj = {};
							angular.extend(newObj,TotalData);
							vm.accountSourcingData.push(newObj);*/
							vm.getMoreOptionsData();
		                }
					});
				}
				
				
				// Go get the accounts and sourcing information	for Gross Profit and Source by Summary	
				vm.getAccountSourcingSummaryData = function() {
					var params = {
							"action_code" : 'bxpuay',
							"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
							"gie_key" : vm.selectedGieKey,
							"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY,
							'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
							vm.accountSourcingData = data.jsonObject;
							
							/*vm.uniqueGieMoreOptionsList1 = _.uniqBy(vm.accountSourcingData , 'ALT_GIE_KEY');
							_.remove(vm.uniqueGieMoreOptionsList, {GIE_KEY: vm.selectedGieKey});*/
							
						// This is done to get the Unique values from list to select in More Options GIE_KEYS			
							vm.uniqueGieMoreOptionsList = _.uniqBy(vm.accountSourcingData , 'ALT_GIE_KEY');
						//	_.remove(vm.uniqueGieMoreOptionsList, {GIE_KEY: vm.selectedGieKey});
							
						// This is done to remove the GIE Keys which are selected in More Options	
							if(vm.uniqueGieMoreOptionsList.length > 0 )
								vm.accountSourcingData = vm.accountSourcingData.filter(function( obj ) {
									return obj.GIE_KEY == vm.selectedGieKey;
									});

							vm.getMoreOptionsData();
		                }
					});
				}
				
				// Select the Radio Button depending on source type selected		
			/*	vm.changeSourceBy = function() {
					
					vm.selectedSourceType;
					
				 
				}*/
				
			
				
		// Get More Options Data		
				vm.getMoreOptionsData = function() {
					var params = {
							"action_code" : 'njanfg',
							"gie_key" : vm.selectedGieKey
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
							vm.moreOptionsData = data.jsonObject;
							if(vm.enableMoreOptions == true)
							{
								vm.moreOptionsDataWH = [];
								vm.moreOptionsData.forEach(function (item, index) {
									if (item.GIE_SHORT_DESC == 'WithHolding Taxes')
										vm.moreOptionsDataWH.push(vm.moreOptionsData[index]);
									 // console.log(item, index);
									});
							//	vm.moreOptionsDataWH = 
							}
						//	_.remove(vm.moreOptionsData, {GIE_KEY: vm.selectedGieKey});WithHolding Taxes
							vm.autoCheckMoreOptionsGie();
		                }
					});
				}
				
	// Get Accounts for Selected GIE KEYS from more options		
				vm.getMoreAccounts = function() {
					if(vm.firstTimeLoad == false){
						if(confirm("Change More Options will reset Amount to Source for More Options. Do you want to continue?")){
							vm.loadingData_new = true;
							
							vm.saveFlag = true;
							var gieTypeSelected ='';
							for ( var data in vm.selectedGieTypes) {
								if (vm.selectedGieTypes[data] != undefined && vm.selectedGieTypes[data].GIE_KEY != undefined) {
									gieTypeSelected = gieTypeSelected.concat(vm.selectedGieTypes[data].GIE_KEY);
									gieTypeSelected = gieTypeSelected.concat(",");
								}
							}
							
							if(gieTypeSelected.length > 0){
								
								var params = {
										"action_code" : 'q3lxgm',
										"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
										"gie_key" : vm.selectedGieKey,
										"more_options" : gieTypeSelected.substring(0,(gieTypeSelected.length -1)),
										"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY,
										"asset_flag" : (vm.assestSourcing == true ? 'Y' : 'N'),
										'p_scenario' : vm.scenario,
										'p_jcd_key' :vm.jcd_key
									};
								
								/*"action_code" : 'ude65e',
								"gie_key" : gieTypeSelected.substring(0,(gieTypeSelected.length -1)),
								"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY*/
								
								JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
									if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
					                    AlertService.add("error", data.errorMessage);
					                    return false;
					                }else{
									vm.moreAccountsToShow = data.jsonObject;
									
									/*vm.TotalMoreAcctData = _.filter(vm.moreAccountsToShow, function(p){
				                		  return _.includes(['Total'], p.ACCT);  
				                		});*/
									_.remove(vm.moreAccountsToShow, {ACCT: 'Total'});
									
									if(vm.firstTimeLoad == true){
										vm.firstTimeMoreAccountsData = angular.copy(vm.moreAccountsToShow);
										vm.firstTimeLoad = false;
									}
									vm.loadingData = false;
		                            if(vm.selectedSourceType != null && vm.selectedGieTypes && vm.selectedGieTypes.length > 0){
		                            setTimeout(function(){
		                            //    console.log("##############3");
		                                $scope.$applyAsync(function () {
		                                vm.loadingData_new = false;
		                            });
		                                
		                            },10);
		                            }else{
		                                vm.loadingData_new = false;
		                            }
									
					                }
								});
								vm.previousSelectedGie = vm.selectedGieTypes;
							}else{
								vm.moreAccountsToShow = [];
								vm.uniqueGieMoreOptionsList=[];
								vm.getMoreOptionsData();
								vm.selectedGieTypes = [];
								vm.loadingData = false;
								vm.loadingData_new = false;
							}
						}else{
							//reset the gietypes
							vm.getMoreOptionsData();
							vm.selectedGieTypes = vm.previousSelectedGie;
						}
					}else{

						vm.loadingData_new = true;
						var gieTypeSelected ='';
						for ( var data in vm.selectedGieTypes) {
							if (vm.selectedGieTypes[data] != undefined && vm.selectedGieTypes[data].GIE_KEY != undefined) {
								gieTypeSelected = gieTypeSelected.concat(vm.selectedGieTypes[data].GIE_KEY);
								gieTypeSelected = gieTypeSelected.concat(",");
							}
						}
						
						if(gieTypeSelected.length > 0){
							
							
							if(vm.enableMoreOptions == true && vm.moreOptionsDataWH.length == 1)			
								vm.enableSaveButton = true;
							
							var params = {
									"action_code" : 'q3lxgm',
									"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY,
									"gie_key" : vm.selectedGieKey,
									"more_options" : gieTypeSelected.substring(0,(gieTypeSelected.length -1)),
									"tag_cc_key" : vm.selectedTagSourcingRow[0].COMBINATION_KEY,
									"asset_flag" : (vm.assestSourcing == true ? 'Y' : 'N'),
									'p_scenario' : vm.scenario,
									'p_jcd_key' :vm.jcd_key
								};
							
							/*"action_code" : 'ude65e',
							"gie_key" : gieTypeSelected.substring(0,(gieTypeSelected.length -1)),
							"tag_key" : vm.selectedTagSourcingRow[0].TAG_KEY*/
							
							JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
								if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
				                    AlertService.add("error", data.errorMessage);
				                    return false;
				                }else{
								vm.moreAccountsToShow = data.jsonObject;
								/*vm.TotalMoreAcctData = _.filter(vm.moreAccountsToShow, function(p){
			                		  return _.includes(['Total'], p.ACCT);  
			                		});*/
								_.remove(vm.moreAccountsToShow, {ACCT: 'Total'});
								if(vm.firstTimeLoad == true){
									vm.firstTimeMoreAccountsData = angular.copy(vm.moreAccountsToShow);
									vm.firstTimeLoad = false;
								}
								vm.loadingData = false;
	                            if(vm.selectedSourceType != null && vm.selectedGieTypes && vm.selectedGieTypes.length > 0){
	                            setTimeout(function(){
	                            //    console.log("##############3");
	                                $scope.$applyAsync(function () {
	                                vm.loadingData_new = false;
	                            });
	                                
	                            },10);
	                            }else{
	                                vm.loadingData_new = false;
	                            }
								
				                }
							});
							
							vm.previousSelectedGie = vm.selectedGieTypes;
						}else{
							vm.moreAccountsToShow = [];
							vm.uniqueGieMoreOptionsList=[];
							vm.getMoreOptionsData();
							vm.selectedGieTypes = [];
							vm.loadingData = false;
							vm.loadingData_new = false;
						}
					}
									
				}			
				
		//Multiselect for Gie keys for more options
				
				vm.autoCheckMoreOptionsGie = function() {
					var matchedGIEKeys = [];
					if(vm.moreOptionsData && vm.uniqueGieMoreOptionsList){
						vm.uniqueGieMoreOptionsList.filter(function(defaultData){
								return vm.moreOptionsData.filter(function(item){
									if(defaultData.ALT_GIE_KEY === item.GIE_KEY){
										matchedGIEKeys.push(item);
									}
									
								})
							})
							vm.selectedGieTypes = matchedGIEKeys;
							vm.previousGieType = angular.copy(vm.selectedGieTypes);
							if(vm.firstTimeLoad == undefined)
								vm.firstTimeLoad = true;
							else
								vm.firstTimeLoad == false;
					}
				}		
				
		//CheckAll checkbox
				
				vm.checkAll = function(){
					vm.checkAllButton;
					
					if(vm.checkAllButton == true){
						for(var key in vm.accountSourcingData){
							vm.accountSourcingData[key].SOURCED_CHECKBOX = true;
							if(vm.accountSourcingData[key].ACCT == 'Total' || (vm.accountSourcingData[key].ALT_GIE_NAME == vm.accountSourcingData[key].SYS_ACCT))
								vm.accountSourcingData[key].SOURCED_CHECKBOX = false;
						}
						
						for(var key in vm.moreAccountsToShow){
							vm.moreAccountsToShow[key].SOURCED_CHECKBOX = true;
							if(vm.moreAccountsToShow[key].ACCT == 'Total' || (vm.moreAccountsToShow[key].ALT_GIE_NAME == vm.moreAccountsToShow[key].SYS_ACCT))
								vm.moreAccountsToShow[key].SOURCED_CHECKBOX = false;
						}
					}else if(vm.checkAllButton == false){
						for(var key in vm.accountSourcingData){
							vm.accountSourcingData[key].SOURCED_CHECKBOX = false;
						}
						
						for(var key in vm.moreAccountsToShow){
							vm.moreAccountsToShow[key].SOURCED_CHECKBOX = false;
						}
					}
				}
				
				vm.updateRemainder = function(data,type) {
					
					if(type == 'book'){
						data.BOOK_REMAINDER = parseInt(data.BOOK_TOTAL_AMT) - parseInt(data.BOOK_SOURCE_AMT) - (data.BOOK_AMOUNT_TO_SOURCE == null ? 0 : parseInt(data.BOOK_AMOUNT_TO_SOURCE));
						if((data.BOOK_AMOUNT_TO_SOURCE == null || data.BOOK_AMOUNT_TO_SOURCE == 0) && data.OPERATION_TYPE != 'I')
							data.OPERATION_TYPE = "C";
					}
						
					else if(type='schM' && data.SCHDM_AMOUNT_TO_SOURCE != null){
						data.SCHDM_REMAINDER = data.SCHDM_TOTAL_AMT - data.SCHDM_SOURCE_AMT - data.SCHDM_AMOUNT_TO_SOURCE;
						if((data.SCHDM_AMOUNT_TO_SOURCE == null || data.SCHDM_AMOUNT_TO_SOURCE == 0) && data.OPERATION_TYPE != 'I')
							data.OPERATION_TYPE = "C";
					}
					
					vm.saveFlag = true;
				}	
				
				vm.updatePercentRemainder = function(data,type) {
					if(type == 'book' && data.BOOK_PERCENT != null){
						data.BOOK_REMAINDER = data.BOOK_TOTAL_AMT - data.BOOK_SOURCE_AMT - ((data.BOOK_TOTAL_AMT * data.BOOK_PERCENT)/100);
						data.NEW_BOOK_AMOUNT_TO_SOURCE = (data.BOOK_TOTAL_AMT * data.BOOK_PERCENT)/100;
						if((data.BOOK_PERCENT == null || data.BOOK_PERCENT == 0) && data.OPERATION_TYPE != 'I')
							data.OPERATION_TYPE = "C";
						
					}else if(type='schM' && data.SCHDM_PERCENT != null){
						data.SCHDM_REMAINDER = data.SCHDM_TOTAL_AMT - data.SCHDM_SOURCE_AMT - ((data.SCHDM_TOTAL_AMT * data.SCHDM_PERCENT)/100);
						data.NEW_SCHDM_AMOUNT_TO_SOURCE = ((data.SCHDM_TOTAL_AMT * data.SCHDM_PERCENT)/100);
						if((data.SCHDM_PERCENT == null || data.SCHDM_PERCENT == 0) && data.OPERATION_TYPE != 'I')
							data.OPERATION_TYPE = "C";
					}
					vm.saveFlag = true;
				}
				
				
				// getting all the FTC CODE MAST to populate in drop downs
				vm.getCodeMastData = function() {
					if (vm.qarRadioButtonBkp != undefined &&  vm.qarRadioButtonBkp != vm.qarRadioButton ){
						vm.changeQarOptions();
					}else{
						vm.loadingDataQar = true;
						var params = {
								"action_code" : '31hizx',
								"cc_key" : (vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.COMBINATION_KEY: vm.selectedCodeCombination.KEY,
								'p_scenario':vm.scenario,
								'p_jcd_key' :vm.jcd_key
							};
							JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
									if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
					                    AlertService.add("error", data.errorMessage);
					                    return false;
					                }else{
					                	// vm.editCount = 0;
					                	vm.codeMastDataTempData = data.jsonObject;
					                	vm.basketCodeData = _.filter(data.jsonObject, function(p){
					                		return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
					                		});
					                	vm.fdiiCodeData = _.filter(data.jsonObject, function(p){
					                		  return _.includes(['FDII_CODE'], p.CODE_GROUP);  
					                		});
					                	vm.qarOptions = _.filter(data.jsonObject, function(p){
					                		  return _.includes(['QAR_OPTIONS'], p.CODE_GROUP);  
				                		});
					                	vm.methodCodeData = _.filter(data.jsonObject, function(p){
					                		return _.includes(['METHOD_CODE'], p.CODE_GROUP);  
					                		});
					                	vm.baseBasketCodeData = _.filter(data.jsonObject, function(p){
					                		return _.includes(['BASE_BASKET_CODE'], p.CODE_GROUP);  
				                		});
					                	vm.baseFDIICodeData = _.filter(data.jsonObject, function(p){
					                		return _.includes(['BASE_FDII_CODE'], p.CODE_GROUP);  
				                		});
					                	if(vm.selectedTagSourcingRow[0].GIE_TYPE == 'INC' || vm.selectedTagSourcingRow[0].GIE_TYPE == 'GP')
					                		vm.gieType = 'GI';
					                	else
					                		vm.gieType = 'EXP';	
					                	if (vm.qarRadioButton == 'By Custom' ){
					                		_.remove(vm.basketCodeData, {CODE_DESCRIPTION: 'Default'});
					                		vm.getCustomPopUpData();
					                	}
					                	else if (vm.qarRadioButton == 'By RAF' )
					                		vm.getQARByRAFData();
					                }
								});
					}
					
				}
				
				vm.getTIforGieCCkey = function() {
					var params = {
						"action_code" : 'zf7hlu',
						"cc_key" : (vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.COMBINATION_KEY: vm.selectedCodeCombination.KEY,
						'p_scenario':vm.scenario,
						'p_jcd_key' :vm.jcd_key,
						"gie_key" :vm.gridDataMultipleSelect.rowData.GIE_KEY,
						"issue_id" :(vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.ISSUE_KEY: 0,
					};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	// vm.editCount = 0;
			                	if(data.jsonObject && data.jsonObject.length > 0){
			                		vm.tiCCGieKey = data.jsonObject[0].TI_AMOUNT;
				                	vm.defaultTagAmount = data.jsonObject[0].DEFAULT_AMOUNT;
				                	vm.getSelectedQAROption();
			                	}
			                	else
			                		vm.loadingDataQar = false;
			                }
						});
				}
				
				vm.getSelectedQAROption = function() {
					var params = {
						"action_code" : '76vbl7',
						"cc_key" : (vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.COMBINATION_KEY: vm.selectedCodeCombination.KEY,
						'p_scenario':vm.scenario,
						'p_jcd_key' :vm.jcd_key,
						"gie_key" :vm.gridDataMultipleSelect.rowData.GIE_KEY,
						"issue_id" :(vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.ISSUE_KEY: 0
					};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	// vm.editCount = 0;
			                	if(data.jsonObject && data.jsonObject.length > 0)
			                	{
			                		vm.qarRadioButton = data.jsonObject[0].CODE_DESC;
			                		vm.qarRadioButtonBkp = vm.qarRadioButton;
			                	}
			                	if (vm.qarRadioButton == null || vm.qarRadioButton == undefined || vm.qarRadioButton == '')
			                		vm.loadingDataQar = false;
			                	else
			                		vm.getCodeMastData();
			                	
			                }
						});
				}
				
				vm.getQARByRAFData = function() {
					vm.RafDataList =[];
					vm.totalAmount = 0;
                	vm.totalApprAmount = 0;
                	vm.apprPercentage = 0;
                	vm.apprAmount = 0;
					var params = {
						"action_code" : 'jw3c7l',
						"cc_key" : (vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.COMBINATION_KEY: vm.selectedCodeCombination.KEY,
						'p_scenario':vm.scenario,
						'p_jcd_key' :vm.jcd_key,
						"gie_key" :vm.gridDataMultipleSelect.rowData.GIE_KEY,
						"gie_type" :vm.gridDataMultipleSelect.rowData.GIE_TYPE,
						"issue_id" :(vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.ISSUE_KEY: 0
					};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.totalAmount = 0;
			                	vm.totalApprAmount = 0;
			                	vm.RafDataList = data.jsonObject;
			                	if (vm.RafDataList.length == 0)
			                			vm.isSaveClicked = true;
			                	if(vm.RafDataList && vm.RafDataList.length > 0 && vm.gieType == 'GI'){
									for(var t in vm.RafDataList){
										
										vm.totalAmount = parseInt(vm.totalAmount) + parseInt(vm.RafDataList[t].RAF_AMOUNT);
										
										if(vm.RafDataList[t].SOURCE_FLAG == 'DEFAULT' || vm.RafDataList[t].SOURCE_FLAG == 'BI DEFAULT'){
											vm.RafDataList[t].CHECKBOX = 0;
											//vm.RafDataList[t].QAR_PERCENTAGE == 1;
										}else{
											vm.RafDataList[t].CHECKBOX = 1
										}

										if(vm.RafDataList[t].CHECKBOX == 0){
											vm.apprPercentage = 1;
											vm.apprPercentageAmount = vm.tiCCGieKey;
											vm.rafAmountFlagDisable = 'Y';
											vm.ExamRafType = vm.RafDataList[t].EXAM_RAF_TYPE;
											vm.totalApprAmount = parseInt(vm.totalApprAmount) + parseInt(vm.RafDataList[t].RAF_AMOUNT);
											
										}

									}
									
									vm.apprAmount = vm.RafDataList[0].QAR_AMOUNT	
									///vm.ExamRafType = vm.RafDataList[0].EXAM_RAF_TYPE
									
									if(vm.ExamRafType == 'By RAF - Amount')
									{
										vm.rafAmountFlagDisable = 'N';
									}
				                	if(vm.ExamRafType == 'By RAF - Percentage')
									{
				                		vm.apprPercentageAmount = vm.apprAmount;
				                		vm.apprPercentage = parseFloat((vm.apprAmount / vm.totalApprAmount).toFixed(5));
				                		vm.apprAmount = undefined;
										vm.rafAmountFlagDisable = 'Y';
									}
			                	}
			                	
			                	if(vm.RafDataList && vm.RafDataList.length > 0 && 
			                			vm.selectedTagSourcingRow[0].GROSS_INC_EXP  == 'EXPAA' || 
			                			vm.selectedTagSourcingRow[0].GROSS_INC_EXP == 'INTEXP' || 
			                			vm.selectedTagSourcingRow[0].GROSS_INC_EXP == 'ASTEXP')
			                	{
			                		
			                		for(var t in vm.RafDataList){
			                			if(vm.RafDataList[t].CHECKBOX == 0){
											vm.ExamRafType = vm.RafDataList[t].EXAM_RAF_TYPE;
										}
			                			if(vm.RafDataList[t].SOURCE_FLAG == 'DEFAULT' || vm.RafDataList[t].SOURCE_FLAG == 'BI DEFAULT'){
											vm.RafDataList[t].CHECKBOX = 0;
											vm.RafDataList[t].QAR_PERCENTAGE = 1;
											vm.RafDataList[t].QAR_PERCENT_AMOUNT = vm.tiCCGieKey;
											vm.rafAmountFlagDisable = 'Y';
										}else{
											vm.RafDataList[t].CHECKBOX = 1
										}	
			                		}
			                		
			                		if(vm.ExamRafType == 'By RAF - Amount')
									{
										vm.rafAmountFlagDisable = 'N';
									}
				                	if(vm.ExamRafType == 'By RAF - Percentage')
									{
				                		vm.rafAmountFlagDisable = 'Y';
				                		for(var t in vm.RafDataList){
											
				                			vm.RafDataList[t].QAR_PERCENT_AMOUNT = vm.RafDataList[t].QAR_AMOUNT ;
				                			//vm.RafDataList[t].QAR_PERCENTAGE = parseFloat((vm.RafDataList[t].QAR_AMOUNT/ vm.RafDataList[t].RAF_AMOUNT).toFixed(5));	
											vm.RafDataList[t].QAR_PERCENTAGE = parseFloat((vm.RafDataList[t].QAR_AMOUNT/ vm.tiCCGieKey).toFixed(5));	

				                			vm.RafDataList[t].QAR_AMOUNT = undefined;
										}
									}
			                	}
			                	
			                	vm.loadingDataQar = false;
			                	///vm.ExamRafType = vm.selectedTagSourcingRow[0].EXAM_RAF_TYPE_0;
			                	
			                	
			                }
						});
				}
				
				//addrowforCustom
				vm.addRow = function(){
				//	vm.loadingData = true;
					var tempObj = _.clone(vm.customRowList[0]);
					tempObj.selectedBasketCode = undefined;
					tempObj.selectedFdiiCode = undefined;
					tempObj.ratio = undefined;
					tempObj.amount = undefined;
					tempObj.ratioAmountFlag = '';					
					tempObj.OPERATION_TYPE = "I";
					var lockparams = {"action_code": 'k5eosd' };				
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
							tempObj.tagKey = data.jsonObject[0].TAG_KEY_SEQ;
							vm.customRowList.push(tempObj);
							//vm.loadingData = false;
						})
						///vm.customRowList.push(tempObj);
					
				};
				
			/*	//deleterowforCustom
				vm.deleteRow = function(selectedRowData){					
					var index = vm.customRowList.indexOf(selectedRowData);
					
					if(vm.customRowList[index].OPERATION_TYPE != undefined || vm.customRowList[index].OPERATION_TYPE != null || vm.customRowList[index].OPERATION_TYPE != "" ){					
						if(selectedRowData.OPERATION_TYPE == 'I'){
							vm.customRowList.splice(index, 1);
						}else {
							vm.customRowList[index].OPERATION_TYPE = 'D';
						}
						var countActiveRows = _.filter(vm.customRowList, function(eachRow) { if (eachRow.OPERATION_TYPE != 'D') return eachRow }).length;
						if(countActiveRows == 0){
							var newRow = {};
							newRow.selectedBasketCode = '';
							newRow.selectedFdiiCode = '';
							newRow.ratio = 0;
							newRow.amount = 0;
							newRow.OPERATION_TYPE = 'I';	
							vm.beatTypeList.push(newRow);
						}
					} 
										
				};
				*/

				vm.validateRatio = function(data) {
					vm.isSaveClicked = true;
					if ( data.ratio && ( data.ratio < 0 ||  data.ratio > 1)) {
						AlertService.add("error", "Please select Ratio between 0 to 1",5000);
						data.ratio = undefined;
						data.amount = undefined;
						data.ratioAmountFlag = '';

						//To recalculate again after input change
						vm.tempTotalAmount = 0;
						angular.forEach(vm.customRowList, function (item) {
							if(!!item.amount){
								vm.tempTotalAmount += item.amount;
							}
						});
						vm.totalAmount = vm.tempTotalAmount.toFixed(1);
					} else if(data.ratio === null){
						vm.isSaveClicked = false;
						data.amount = undefined;
						data.ratioAmountFlag = '';
						//data.ratioDisabled = true;

						//To recalculate again after input change
						vm.tempTotalAmount = 0;
						angular.forEach(vm.customRowList, function (item) {
							if(!!item.amount){
								vm.tempTotalAmount += item.amount;
							}
						});
						vm.totalAmount = vm.tempTotalAmount.toFixed(1);
					}else {
						vm.isSaveClicked = false;
						//vm.totalAmount = vm.totalAmount - data.amount + (data.ratio * vm.defaultTagAmount);
						data.amount = data.ratio * vm.defaultTagAmount;
						data.ratioAmountFlag = 'N';

						vm.tempTotalAmount = 0;
						angular.forEach(vm.customRowList, function (item) {
							if(!!item.amount){
								vm.tempTotalAmount += item.amount;
							}
						});
						vm.totalAmount = vm.tempTotalAmount.toFixed(1);
					}
				};

				vm.validateAmt = function(data) {
					vm.isSaveClicked = true;
					if(data.amount === null){
						vm.isSaveClicked = false;
						data.ratio = undefined;
						data.ratioAmountFlag = '';
						//data.ratioDisabled = false;
					}else {
						vm.isSaveClicked = false;
						vm.totalAmount = data.amount;
						//data.amount = data.ratio * vm.defaultTagAmount;
						data.ratioAmountFlag = 'Y';
					}
				};
				
				vm.updateTotalApprAmount = function(data){
					if(vm.gieType == 'GI'){
						if(data.CHECKBOX == 1)
							vm.totalApprAmount = vm.totalApprAmount - data.RAF_AMOUNT;
						else
							vm.totalApprAmount = vm.totalApprAmount + data.RAF_AMOUNT;
						
						vm.updateRafAmounts();
					}
				};
				
				vm.updateRafAmounts = function(){
					
					if(vm.apprAmount == null)
					{
						vm.rafAmountFlagDisable = '';
						vm.apprAmount == undefined
					}
					else
						vm.rafAmountFlagDisable = 'N';
					
					for( var t in vm.RafDataList){
						if (vm.RafDataList[t].CHECKBOX == 0 && vm.apprAmount != undefined)
							vm.RafDataList[t].QAR_AMOUNT = (vm.RafDataList[t].RAF_AMOUNT / vm.totalApprAmount) * vm.apprAmount;
						else 
							vm.RafDataList[t].QAR_AMOUNT = 0 ;
					}
					
				};
				
				vm.updateRafPercentage = function(){
					vm.isSaveClicked = true;
					if ( vm.apprPercentage && ( vm.apprPercentage < 0 ||  vm.apprPercentage > 1)) {
						AlertService.add("error", "Please select Ratio between 0 to 1",5000);						
					}else{
						vm.isSaveClicked = false;
						if(vm.apprPercentage == null)
						{
							vm.rafAmountFlagDisable = '';
							vm.apprPercentage ==undefined;
						}
						else
							vm.rafAmountFlagDisable = 'Y';
						
						//vm.apprPercentageAmount = vm.apprPercentage * vm.totalApprAmount;
						vm.apprPercentageAmount = vm.apprPercentage * vm.tiCCGieKey;
						
						for( var t in vm.RafDataList){
							if (vm.RafDataList[t].CHECKBOX == 0 && vm.apprPercentage != undefined)
								vm.RafDataList[t].QAR_AMOUNT = (vm.RafDataList[t].RAF_AMOUNT / vm.totalApprAmount) * vm.apprPercentageAmount;
							else 
								vm.RafDataList[t].QAR_AMOUNT = 0 ;
						}
						
					}
					
				}; 
				
				
				vm.updateRafExpAmount = function(data){
					
					if(data.QAR_AMOUNT == null)
					{
						vm.rafAmountFlagDisable = '';
						data.QAR_AMOUNT = undefined;
					}
					else
						vm.rafAmountFlagDisable = 'N';
					
				};
				
				vm.updateRafExpPercentage = function(data){
					vm.isSaveClicked = true;
					vm.totalCount = 0;
					vm.RafDataList.forEach(function (item, index) {
						vm.totalCount += item.QAR_PERCENTAGE;
						if (vm.totalCount > 1){
							AlertService.add("error", "Total percentage cannot be greater than 1", 5000);
							return;
						}
					});

					if ( data.QAR_PERCENTAGE && ( data.QAR_PERCENTAGE < 0 ||  data.QAR_PERCENTAGE > 1)) {
						AlertService.add("error", "Please select Ratio between 0 to 1",5000);						
					}else{
						vm.isSaveClicked = false;
						if(data.QAR_PERCENTAGE == null)
						{
							vm.rafAmountFlagDisable = '';
						}
						else
						{
							data.QAR_AMOUNT = undefined
							vm.rafAmountFlagDisable = 'Y';
						}
						
						//data.QAR_PERCENT_AMOUNT = data.RAF_AMOUNT * data.QAR_PERCENTAGE;
						data.QAR_PERCENT_AMOUNT = vm.tiCCGieKey * data.QAR_PERCENTAGE;
					}
				};
				
				vm.getCustomPopUpData = function() {
					vm.customRowList = [];
					vm.totalAmount = 0;
					var params = {
							"action_code" : 'xagg3n',
							"cc_key" : (vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.COMBINATION_KEY: vm.selectedCodeCombination.KEY,
							'p_scenario':vm.scenario,
							'p_jcd_key' :vm.jcd_key,
							"gie_key" :vm.gridDataMultipleSelect.rowData.GIE_KEY,
							"issue_id" :(vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.ISSUE_KEY: 0
						};		
					
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.customRowDetails = data.jsonObject; ///customRowList
			                	vm.totalAmount = 0;
			                	if(vm.customRowDetails.length > 0 ){
									for(var t in vm.customRowDetails){
										var myObject = {};
										myObject["selectedBasketCode"] = vm.customRowDetails[t].BASKET_CODE;
										myObject["selectedFdiiCode"] = vm.customRowDetails[t].FDII_CODE;										
										myObject["ratio"] = vm.customRowDetails[t].SOURCE_PERCENT;
										myObject["amount"] = vm.customRowDetails[t].AMOUNT;
										myObject["tagKey"] = vm.customRowDetails[t].TAG_KEY;
										myObject["OPERATION_TYPE"] = 'U';	
										myObject["disabled"] = true;	
										myObject["ratioDisabled"] = false;
									
					                	vm.customRowList.push(myObject);
					                	
					                	vm.totalAmount = parseInt(vm.totalAmount) + parseInt(vm.customRowDetails[t].AMOUNT);
					                	vm.isEditMode = true;
					                	vm.qarRadiokey = vm.customRowDetails[t].SOURCE_TYPE;
					                	var exists =_.find(vm.qarOptions, ['CODE_KEY',parseInt(vm.customRowDetails[t].SOURCE_TYPE)]) 
					                	if(exists != undefined){
					                		vm.qarRadioButton = exists.CODE_DESCRIPTION;
					                	}
									}
									vm.copyCustomRowList = angular.copy(vm.customRowList);									
								}else{
									//vm.loadingData = true;
									object.selectedBasketCode=undefined;
									object.selectedFdiiCode=undefined;
									object.ratio = undefined;
									object.amount = undefined;
									object.disabled = '';
									object.ratioDisabled = '';
									object["OPERATION_TYPE"] = 'I';	
									var lockparams = {"action_code": 'k5eosd' };				
									JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
										object.tagKey = data.jsonObject[0].TAG_KEY_SEQ;
										vm.customRowList.push(object);
										vm.loadingDataQar = false;
									})						
								}	
			                	
			                	vm.loadingDataQar = false;
			                }
						});
					
					
				}
				
				
				vm.getAttribNamebyGieName();	
				vm.getTIforGieCCkey();
					
						// Opening a modal when clicking Apply Percentage for mutliple Accounts		
				vm.openPopUpWindow= function(modalObj,_data){
					vm.accountSourcingData;
					vm.saveFlag = true;
            		 var modalInstance = $uibModal.open({
                        templateUrl: 'app/components/ftc/settings/sourcingSummary/sourcing-checkBox-popUp.html',
                        controller: 'sourcingPercentageCheckModal as ctrl',
                        size:"md",
                        resolve: {
                        	moreAccountsToShow: function () {
                                return vm.moreAccountsToShow;
                            },
                            accountSourcingData : function () {
                                return vm.accountSourcingData;
                            },
                            saveFlag: function () {
                                return vm.saveFlag;
                            },
                            assestSourcing :function () {
                                return vm.assestSourcing;
                            }
                        }
                    });
				};
				
				// this is called when we change the source by from amount to percentage or vice versa		
				vm.changeQarOptions = function() {	
					var templateUrl = 'app/templates/modals/confirm-action.html';
					var v_tag_list ='';
					
					if(vm.qarRadioButtonBkp == 'By RAF'){
						for(var t in vm.RafDataList){
							if(vm.RafDataList[t].QAR_TAG_KEY != undefined){
								v_tag_list = v_tag_list.concat(vm.RafDataList[t].QAR_TAG_KEY);
								v_tag_list = v_tag_list.concat(",");
							}
						}
					}
					if(vm.qarRadioButtonBkp == 'By Custom'){
						for(var t in vm.customRowList){
							if(vm.customRowList[t].tagKey != undefined){
								v_tag_list = v_tag_list.concat(vm.customRowList[t].tagKey);
								v_tag_list = v_tag_list.concat(",");
							}
						}
					}
					
					$scope.confirmModal = $uibModal.open({
						templateUrl: templateUrl,
						scope: $scope,
						windowClass: 'custom',
						controller: ['$scope', '$stateParams', '$uibModalInstance',
							function ($scope, $stateParams, $uibModalInstance) {
								$scope.header = "Confirm";
								$scope.message = "Changing to By QAR option will delete all the sourced data"
								$scope.confirm = function () {
										var params = {
												"action_code" : '0nv1s7', 
												"tag_key" : v_tag_list,
												'p_scenario' : vm.scenario,
												'p_jcd_key' :vm.jcd_key
											};
										JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
											if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
							                    AlertService.add("error", data.errorMessage);
							                    return false;
							                }else{
							                	var args = {};
							                	$rootScope.$emit('gridUpdate', args);
							                	$uibModalInstance.close();
							                	vm.qarRadioButtonBkp = vm.qarRadioButton;
//							                	vm.RafDataList = [];
//							                	vm.customRowList = [];
							                	vm.getCodeMastData();
							                }
										});
									  }
								$scope.cancel = function () {
									vm.qarRadioButton = vm.qarRadioButtonBkp;
									$scope.closeWindow(false);
									$uibModalInstance.close();
								}

								$scope.$on('$destroy', function () {
									//Do Nothing
								});
										
								}
											
							]
						});
						$scope.closeWindow = function () {
						}
					}
			
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				vm.save = function() {
					vm.isSaveClicked = true;	
					if(vm.saveFlag == true){
						var returnClobSettingsObj = {};
						var allSourcingAcctAmtDetails = [];
						
						for(var key in vm.accountSourcingData){
							var returnObj = {};
							if( ( (vm.selectedSourceTypeDesc == 'Amount' && ((vm.accountSourcingData[key].BOOK_AMOUNT_TO_SOURCE == null ? 0 : vm.accountSourcingData[key].BOOK_AMOUNT_TO_SOURCE) != 0 
																	  ||  (vm.accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE == null ? 0 : vm.accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE) != 0
																	  || vm.accountSourcingData[key].OPERATION_TYPE == 'C'))
							   || (vm.selectedSourceTypeDesc == 'Percentage' && ((vm.accountSourcingData[key].BOOK_PERCENT == null ? 0 : vm.accountSourcingData[key].BOOK_PERCENT) != 0
																			|| (vm.accountSourcingData[key].SCHDM_PERCENT == null ? 0 : vm.accountSourcingData[key].SCHDM_PERCENT) != 0
																			|| vm.accountSourcingData[key].OPERATION_TYPE == 'C'))
							 )
							&& 	vm.accountSourcingData[key].ACCT != 'Total'){
							
							returnObj["tag_key"] = vm.selectedTagSourcingRow[0].TAG_KEY;
							returnObj["alt_gie_key"] = vm.accountSourcingData[key].ALT_GIE_KEY;
							returnObj['acct_key'] =  vm.accountSourcingData[key].ACCT_KEY;
							returnObj['operation_type'] = (vm.accountSourcingData[key].OPERATION_TYPE == 'C' ? 'U' : vm.accountSourcingData[key].OPERATION_TYPE);
							
							
							if(vm.selectedSourceTypeDesc == 'Amount'){
								returnObj['source_amt'] =(vm.accountSourcingData[key].BOOK_AMOUNT_TO_SOURCE == null ? 0 :vm.accountSourcingData[key].BOOK_AMOUNT_TO_SOURCE);
								returnObj['source_percent'] = 0;
								returnObj['other_source_amt'] = (vm.accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE == null ? 0 :vm.accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE);
								returnObj['other_source_percent'] = 0;
							}else if(vm.selectedSourceTypeDesc == 'Percentage'){
								returnObj['source_amt'] = (vm.accountSourcingData[key].BOOK_TOTAL_AMT * vm.accountSourcingData[key].BOOK_PERCENT) / 100;
								returnObj['source_percent'] = vm.accountSourcingData[key].BOOK_PERCENT;
								returnObj['other_source_amt'] = (vm.accountSourcingData[key].SCHDM_TOTAL_AMT * vm.accountSourcingData[key].SCHDM_PERCENT) / 100;
								returnObj['other_source_percent'] = vm.accountSourcingData[key].SCHDM_PERCENT;
								
							}
							allSourcingAcctAmtDetails.push(returnObj);
						}	
							
							
						}
						
						if( vm.selectedSourceTypeDesc == 'Summary'){
							for(var key in vm.accountSourcingData){
							if(vm.accountSourcingData[key].BOOK_AMOUNT_TO_SOURCE != 0 || vm.accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE != 0 || vm.accountSourcingData[key].OPERATION_TYPE == 'C'){
								
								var returnObj = {};
								returnObj["tag_key"] = vm.selectedTagSourcingRow[0].TAG_KEY;
								returnObj["alt_gie_key"] = vm.accountSourcingData[key].ALT_GIE_KEY;
								returnObj['acct_key'] =  vm.accountSourcingData[key].ALT_GIE_KEY;
								returnObj['operation_type'] = (vm.accountSourcingData[key].OPERATION_TYPE == 'C' ? 'U' : vm.accountSourcingData[key].OPERATION_TYPE);
								returnObj['source_amt'] =(vm.accountSourcingData[key].BOOK_AMOUNT_TO_SOURCE == null ? 0 : vm.accountSourcingData[key].BOOK_AMOUNT_TO_SOURCE);
								returnObj['source_percent'] = 0;
								returnObj['other_source_amt'] = (vm.accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE == null ? 0 : vm.accountSourcingData[key].SCHDM_AMOUNT_TO_SOURCE);
								returnObj['other_source_percent'] = 0;
								allSourcingAcctAmtDetails.push(returnObj);
								
							}	
								
								
							}
						}
						
						//compare 1st load list with new list
						if(vm.firstTimeMoreAccountsData && vm.firstTimeMoreAccountsData.length > 0 ){
							
							
							vm.uniquePreviousList = _.uniqBy(vm.firstTimeMoreAccountsData , 'ALT_GIE_KEY');
							
							vm.uniqueNewList = _.uniqBy(vm.moreAccountsToShow , 'ALT_GIE_KEY');
							
							vm.newDeleteList = vm.uniquePreviousList.filter(item => !vm.uniqueNewList.some(other => item.ALT_GIE_KEY === other.ALT_GIE_KEY));
							
							
							for(var key in vm.newDeleteList){
							 var selectedRemovalKey = vm.newDeleteList[key].ALT_GIE_KEY
							 	for(var key1 in vm.firstTimeMoreAccountsData){
							 		
							 		if(vm.firstTimeMoreAccountsData[key1].ALT_GIE_KEY == selectedRemovalKey){
							 			
							 			var returnObj = {};
										
										returnObj["tag_key"] = vm.selectedTagSourcingRow[0].TAG_KEY;
										returnObj["alt_gie_key"] = vm.firstTimeMoreAccountsData[key1].ALT_GIE_KEY;
										returnObj['acct_key'] =  vm.firstTimeMoreAccountsData[key1].ACCT_KEY;
										returnObj['operation_type'] = 'D';
										
										if(vm.selectedSourceTypeDesc == 'Amount'){
											returnObj['source_amt'] =vm.firstTimeMoreAccountsData[key1].BOOK_AMOUNT_TO_SOURCE;
											returnObj['source_percent'] = 0;
											returnObj['other_source_amt'] = vm.firstTimeMoreAccountsData[key1].SCHDM_AMOUNT_TO_SOURCE;
											returnObj['other_source_percent'] = 0;
										}else if(vm.selectedSourceTypeDesc == 'Percentage'){
											returnObj['source_amt'] = (vm.firstTimeMoreAccountsData[key1].BOOK_TOTAL_AMT * vm.firstTimeMoreAccountsData[key1].BOOK_PERCENT) / 100;
											returnObj['source_percent'] = vm.firstTimeMoreAccountsData[key1].BOOK_PERCENT;
											returnObj['other_source_amt'] = (vm.firstTimeMoreAccountsData[key1].SCHDM_TOTAL_AMT * vm.firstTimeMoreAccountsData[key1].SCHDM_PERCENT) / 100;
											returnObj['other_source_percent'] = vm.firstTimeMoreAccountsData[key1].SCHDM_PERCENT;
										}
										allSourcingAcctAmtDetails.push(returnObj);
							 		}
							 	}
							}
							
						}
						
						
						if(vm.moreAccountsToShow && vm.moreAccountsToShow.length > 0){
								for(var key in vm.moreAccountsToShow){
									
									if(((vm.selectedSourceTypeDesc == 'Amount' && ((vm.moreAccountsToShow[key].BOOK_AMOUNT_TO_SOURCE == null ? 0 : vm.moreAccountsToShow[key].BOOK_AMOUNT_TO_SOURCE) != 0 
											  ||  (vm.moreAccountsToShow[key].SCHDM_AMOUNT_TO_SOURCE == null ? 0 : vm.moreAccountsToShow[key].SCHDM_AMOUNT_TO_SOURCE) != 0
											  || vm.moreAccountsToShow[key].OPERATION_TYPE == 'C'))
										   || (vm.selectedSourceTypeDesc == 'Percentage' && ((vm.moreAccountsToShow[key].BOOK_PERCENT == null ? 0 : vm.moreAccountsToShow[key].BOOK_PERCENT) != 0
																						|| (vm.moreAccountsToShow[key].SCHDM_PERCENT == null ? 0 : vm.moreAccountsToShow[key].SCHDM_PERCENT) != 0
																						|| vm.moreAccountsToShow[key].OPERATION_TYPE == 'C'))
										 || (vm.selectedSourceTypeDesc == 'Summary' && ((vm.moreAccountsToShow[key].BOOK_AMOUNT_TO_SOURCE == null ? 0 : vm.moreAccountsToShow[key].BOOK_AMOUNT_TO_SOURCE) != 0 
												  ||  (vm.moreAccountsToShow[key].SCHDM_AMOUNT_TO_SOURCE == null ? 0 : vm.moreAccountsToShow[key].SCHDM_AMOUNT_TO_SOURCE) != 0
												  || vm.moreAccountsToShow[key].OPERATION_TYPE == 'C')))
										&& 	vm.moreAccountsToShow[key].ACCT != 'Total')
								{
									var returnObj = {};
									
									returnObj["tag_key"] = vm.selectedTagSourcingRow[0].TAG_KEY;
									returnObj["alt_gie_key"] = vm.moreAccountsToShow[key].ALT_GIE_KEY;
									returnObj['acct_key'] =  vm.moreAccountsToShow[key].ACCT_KEY;
									returnObj['operation_type'] = (vm.moreAccountsToShow[key].OPERATION_TYPE == 'C' ? 'U' : vm.moreAccountsToShow[key].OPERATION_TYPE);
									
									if(vm.selectedSourceTypeDesc == 'Amount' || vm.selectedSourceTypeDesc == 'Summary'){
										returnObj['source_amt'] = (vm.moreAccountsToShow[key].BOOK_AMOUNT_TO_SOURCE == null ? 0 : vm.moreAccountsToShow[key].BOOK_AMOUNT_TO_SOURCE);
										returnObj['source_percent'] = 0;
										returnObj['other_source_amt'] = (vm.moreAccountsToShow[key].SCHDM_AMOUNT_TO_SOURCE == null ? 0 : vm.moreAccountsToShow[key].SCHDM_AMOUNT_TO_SOURCE);
										returnObj['other_source_percent'] = 0;
									}else if(vm.selectedSourceTypeDesc == 'Percentage'){
										returnObj['source_amt'] = (vm.moreAccountsToShow[key].BOOK_TOTAL_AMT * vm.moreAccountsToShow[key].BOOK_PERCENT) / 100;
										returnObj['source_percent'] = vm.moreAccountsToShow[key].BOOK_PERCENT;
										if(vm.moreAccountsToShow[key].SCHM_AMT != undefined)
											returnObj['other_source_amt'] = (vm.moreAccountsToShow[key].SCHM_AMT * vm.moreAccountsToShow[key].SCHDM_PERCENT) / 100;
										else
											returnObj['other_source_amt'] = 0;
										returnObj['other_source_percent'] = vm.moreAccountsToShow[key].SCHDM_PERCENT; 
									}
									allSourcingAcctAmtDetails.push(returnObj);
								}
								
							}
							
							
							
						}
						
						var message = "Data has been successfully saved/updated";
						
						returnClobSettingsObj['sso_id'] = vm.logged_in_user;
						returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
						returnClobSettingsObj['tag_key'] = vm.selectedTagSourcingRow[0].TAG_KEY;
						returnClobSettingsObj['p_tax_year'] = vm.curentyear;
						returnClobSettingsObj['p_scenario'] = vm.scenario;
						returnClobSettingsObj['p_jcd_key'] = vm.jcd_key;
						
						sendDetails(returnClobSettingsObj,allSourcingAcctAmtDetails, message,'Sourcing');
					}else{
						vm.isSaveClicked = false;
						AlertService.add("error", "No changes were made to source data");
					}
					
					
					
				};
				
				
				
				vm.saveQARCustom = function() {  ///selectedTagSourcingRow
					
					var returnClobSettingsObj = {};
					var returnObj = {};
					vm.isSaveClicked = true;
					
					for( var row in vm.customRowList){
						
						if(vm.customRowList[row].selectedBasketCode == undefined  || vm.customRowList[row].selectedFdiiCode == undefined  || vm.customRowList[row].ratio == ''
							 ||vm.customRowList[row].amount == undefined ){
								AlertService.add("error", "Please select/enter all the required fields.",4000);
								vm.isSaveClicked = false;
								return;
						}
					}
					
					
					var exists =_.find(vm.qarOptions, ['CODE_DESCRIPTION',vm.qarRadioButton]) 
                	if(exists != undefined){
                		vm.qarRadioKey = exists.CODE_KEY;
                	}
					
			
			       var qarCustomChangeDetails = [];
					for( var row in vm.customRowList){	
						
						var returnObj = {};	
						returnObj['BASKET'] = vm.customRowList[row].selectedBasketCode;
						returnObj['FDII'] = vm.customRowList[row].selectedFdiiCode;
						returnObj['RATIO'] = vm.customRowList[row].ratio * 100;
						returnObj['AMOUNT'] = vm.customRowList[row].amount;
						returnObj['GIE_KEY'] = vm.selectedTagSourcingRow[0].GIE_KEY;
						returnObj['COMBINATION_KEY'] = vm.selectedTagSourcingRow[0].COMBINATION_KEY;
						returnObj['ISSUE_ID'] = vm.selectedTagSourcingRow[0].ISSUE_KEY;
						returnObj['OPERATION_TYPE'] = vm.customRowList[row].OPERATION_TYPE;
						returnObj['QAR_OPTION'] = vm.qarRadioKey;
						returnObj['TAG_KEY'] = vm.customRowList[row].tagKey;
						var exists1 =_.find(vm.methodCodeData, ['CODE_DESC', 'Allocate']) 
						var exists2 =_.find(vm.baseBasketCodeData, ['CODE_DESC', 'SINGLE']) 
						var exists3 =_.find(vm.baseFDIICodeData, ['CODE_DESC', 'SINGLE']) 
	                	if(vm.selectedTagSourcingRow[0].GROSS_INC_EXP  == 'EXPAA' || vm.selectedTagSourcingRow[0].GROSS_INC_EXP == 'INTEXP' || vm.selectedTagSourcingRow[0].GROSS_INC_EXP == 'ASTEXP'){
	                		returnObj['METHOD'] = exists1.CODE_KEY;
	                		returnObj['BASE_BASKET'] = exists2.CODE_KEY;
	                		returnObj['BASE_FDII'] = exists3.CODE_KEY;	                		
	                	}
									
						qarCustomChangeDetails.push(returnObj);					
					}
					
					var message = "Tag has been successfully saved/updated";
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['p_tax_year'] = vm.curentyear;
					returnClobSettingsObj['p_scenario'] = vm.scenario;
					returnClobSettingsObj['p_jcd_key'] = vm.jcd_key;
					
					sendDetails(returnClobSettingsObj,qarCustomChangeDetails, message,'QARbyCustom');
		

				};
			//// QAR by RAF save	
				vm.saveQARByRaf = function() {
					
					var returnClobSettingsObj = {};
					var returnObj = {};
					vm.isSaveClicked = true;
					
					/*if((vm.apprAmount == undefined  || vm.apprAmount == null  || vm.apprAmount == '') && vm.gieType == 'GI'){
							vm.apprAmount = undefined;
						if((vm.apprPercentage == undefined  || vm.apprPercentage == null  || vm.apprPercentage == '') && vm.gieType == 'GI'){
							AlertService.add("error", "Please enter QAR Amount or Percentage before you click save.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}*/
					
					var exists =_.find(vm.qarOptions, ['CODE_DESCRIPTION',vm.qarRadioButton]) 
                	if(exists != undefined){
                		vm.qarRadioKey = exists.CODE_KEY;
                	}
					
					if (vm.rafAmountFlagDisable == 'N' )
					{
						
						if((vm.apprAmount == undefined  || vm.apprAmount == null  || vm.apprAmount == '') && vm.gieType == 'GI'){
							AlertService.add("error", "Please enter QAR Amount or Percentage before you click save.",4000);
							vm.isSaveClicked = false;
							return;
						}
						var exists =_.find(vm.qarOptions, ['CODE_DESCRIPTION','By RAF - Amount'])  /// 
	                	if(exists != undefined){
	                		vm.rafOptionAmountPercentage = exists.CODE_KEY;
	                	}
					}
					
					if(vm.rafAmountFlagDisable == 'Y')
					{
						if((vm.apprPercentage == undefined  || vm.apprPercentage == null  || vm.apprPercentage == '') && vm.gieType == 'GI'){
							AlertService.add("error", "Please enter QAR Amount or Percentage before you click save.",4000);
							vm.isSaveClicked = false;
							return;
						}
						vm.apprAmount = undefined;
						var exists =_.find(vm.qarOptions, ['CODE_DESCRIPTION','By RAF - Percentage'])  
	                	if(exists != undefined){
	                		vm.rafOptionAmountPercentage = exists.CODE_KEY;
	                	}
					}
					
					
					var qarRafChangeDetails = [];
					for( var row in vm.RafDataList){	
							var returnObj = {};	
							returnObj['GIE_KEY'] = vm.selectedTagSourcingRow[0].GIE_KEY;
							returnObj['COMBINATION_KEY'] = vm.selectedTagSourcingRow[0].COMBINATION_KEY;
							returnObj['ISSUE_ID'] = vm.selectedTagSourcingRow[0].ISSUE_KEY;
							returnObj['QAR_OPTION'] = vm.qarRadioKey;
							returnObj['EXAM_RAF_OPTION'] = vm.rafOptionAmountPercentage;
							returnObj['RAF_TAG_KEY'] = vm.RafDataList[row].RAF_TAG_KEY;
							returnObj['QAR_TAG_KEY'] = vm.RafDataList[row].QAR_TAG_KEY;
							
							returnObj['TOTAL_APPR_AMOUNT'] = vm.totalApprAmount;
							returnObj['RAF_AMOUNT'] = vm.RafDataList[row].RAF_AMOUNT;
							returnObj['CHECKBOX'] = vm.RafDataList[row].CHECKBOX;
							
							if((vm.selectedTagSourcingRow[0].GROSS_INC_EXP  == 'EXPAA' 
								|| vm.selectedTagSourcingRow[0].GROSS_INC_EXP == 'INTEXP'
								|| vm.selectedTagSourcingRow[0].GROSS_INC_EXP == 'ASTEXP') && vm.RafDataList[row].CHECKBOX == 0)
							{
								if(vm.RafDataList[row].QAR_AMOUNT == undefined  || vm.RafDataList[row].QAR_AMOUNT == null  || vm.RafDataList[row].QAR_AMOUNT == ''){
									if(vm.RafDataList[row].QAR_PERCENT_AMOUNT == undefined  || vm.RafDataList[row].QAR_PERCENT_AMOUNT == null  || vm.RafDataList[row].QAR_PERCENT_AMOUNT == ''){
										AlertService.add("error", "Please enter QAR Amount or Percentage before you click save.",4000);
										vm.isSaveClicked = false;
										return;
										}
								}
								
								returnObj['QAR_AMOUNT'] =  (vm.RafDataList[row].QAR_AMOUNT == undefined ? vm.RafDataList[row].QAR_PERCENT_AMOUNT : vm.RafDataList[row].QAR_AMOUNT);  		
		                	}else
		                		returnObj['QAR_AMOUNT'] = (vm.apprAmount == undefined ? vm.RafDataList[row].QAR_AMOUNT : vm.apprAmount);
							
							
							if (vm.RafDataList[row].CHECKBOX == 0 && vm.RafDataList[row].QAR_TAG_KEY == null )
								returnObj['OPERATION_TYPE'] = 'I';
							  else if (vm.RafDataList[row].CHECKBOX == 1 && vm.RafDataList[row].QAR_TAG_KEY == null )
								  returnObj['OPERATION_TYPE'] = 'S';
								else if (vm.RafDataList[row].CHECKBOX == 0 && vm.RafDataList[row].QAR_TAG_KEY != null )
									returnObj['OPERATION_TYPE'] = 'U';
									else if (vm.RafDataList[row].CHECKBOX == 1 && vm.RafDataList[row].QAR_TAG_KEY != null )
										returnObj['OPERATION_TYPE'] = 'D';	
										
							if (returnObj['OPERATION_TYPE'] != 'S')
								qarRafChangeDetails.push(returnObj);
						
					}
					var message = "Tag has been successfully saved/updated";
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['p_tax_year'] = vm.curentyear;
					returnClobSettingsObj['p_scenario'] = vm.scenario;
					returnClobSettingsObj['p_jcd_key'] = vm.jcd_key;
					
					sendDetails(returnClobSettingsObj,qarRafChangeDetails, message,'QARbyRAF');
				};
				
				
				function sendDetails(returnClobSettingsObj, data, message, attrib) {
					if(attrib == 'Sourcing'){
					SourcingServiceFactory.saveDefaults(returnClobSettingsObj,data).then(function(result) {
								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
									AlertService.add("error", result.data.errorMessage, 4000);
								} else {
									AlertService.add("success", message, 4000);
									var args = {
											TAG_KEY:returnClobSettingsObj.tag_key,
			                                   gridFilter: {
			                                	   TAG_KEY: returnClobSettingsObj.tag_key
			                                   }
		                                };
									$rootScope.$emit('gridUpdate', args);
									$uibModalInstance.close();									
                            	}
							});
					}
					else if (attrib == 'QARbyCustom')
						{
							SourcingServiceFactory.saveQarCustomTag(returnClobSettingsObj,data).then(function(result) {
							if (result.data.errorMessage && result.data.errorMessage !== 'null') {
								AlertService.add("error", result.data.errorMessage, 4000);
							} else {
								AlertService.add("success", message, 4000);
								var args = {
										TAG_KEY:returnClobSettingsObj.tag_key,
		                                   gridFilter: {
		                                	   TAG_KEY: returnClobSettingsObj.tag_key
		                                   }
	                                };
								$rootScope.$emit('gridUpdate', args);
								//$uibModalInstance.dismiss('cancel');
								$uibModalInstance.close();
								
                        	}

						});
						}
					else if (attrib == 'QARbyRAF')
					{
						SourcingServiceFactory.saveQarRafTag(returnClobSettingsObj,data).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									TAG_KEY:returnClobSettingsObj.tag_key,
	                                   gridFilter: {
	                                	   TAG_KEY: returnClobSettingsObj.tag_key
	                                   }
                                };
							$rootScope.$emit('gridUpdate', args);
							//$uibModalInstance.dismiss('cancel');
							$uibModalInstance.close();
							
                    	}

					});
					}
				}	
				
				
			}
			
			return controllers;

		});
