define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.schedule-MCtrl', [])
    		.controller('scheduleMCtrl', ['GlobalService','$scope', 'JsonObjectFactory','$parse', 'scheduleMGroupObjFactory', 'workspaceFactory', '$state', '$window',
					   'AlertService', 'GENERAL_CONFIG', 'ModalFactory',scheduleMCtrl])
    		//.controller('loanBalanceCtrl', ['GlobalService', 'AlertService', 'JsonObjectFactory', '$uibModalInstance', '$log', '$scope', '$parse', 'rowData', 'GENERAL_CONFIG', 'scheduleMGroupObjFactory', loanBalanceCtrl])
            .controller('scheduleMCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory','$parse', 'scheduleMGroupObjFactory', 'workspaceFactory', '$state', '$window',
				   'AlertService', 'GENERAL_CONFIG', 'ModalFactory', '$uibModalInstance', '$uibModal', '$log', 'rowData', scheduleMCtrlModal]);

		    function scheduleMCtrl(GlobalService,$scope, JsonObjectFactory, $parse, scheduleMGroupObjFactory, workspaceFactory, $state, $window,
					AlertService, GENERAL_CONFIG, ModalFactory) {
		    	scheduleMCtrlModal(GlobalService,$scope, JsonObjectFactory, $parse, scheduleMGroupObjFactory, workspaceFactory, $state, $window, AlertService, GENERAL_CONFIG,ModalFactory, null, null,
							null, null, this);
					}

            function scheduleMCtrlModal(GlobalService,$scope, JsonObjectFactory, $parse, scheduleMGroupObjFactory, workspaceFactory, $state, $window, AlertService, GENERAL_CONFIG,ModalFactory,
            		$uibModalInstance, $uibModal, $log, rowData, thisObj ) {

            	var vm = this||thisObj;
				vm.rowData = rowData||$scope.$parent.rowData;
                //vm.scheduleMDetailsTemp = [];
                $scope.irsF5471ScheduleMData = [];
                vm.adjAmts = [];
                $scope.crudLoading = false;
                $scope.irs5471SchdMNotes ={};
            	//vm.scheduleMDetails.value = "";
                vm.modalTitle = "Schedule M: Transactions Between Controlled Foreign Corporation and Shareholders or Other Related Persons";

                if($uibModalInstance) {
                    vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
                    vm.cancel = function () {
                        $uibModalInstance.close();
                        vm.modal_name = null;
                    };
                } else {
                    vm.modal_name = null;
                }

//--------------------------------------------------------------------------------------------------------------------------------------------------------------                

                vm.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };

                vm.disabled = false;
                
             
//--------------------------------------------------------------------------------------------------------------------------------------------------------------      
                
                function loadScheduleMData()
                {
                	 $scope.irsF5471ScheduleMData = [];
                	var params = {
                			"irs_form_no":'F5471',
                			"combination_key":vm.rowData.COMBINATION_KEY,
                			"jcd_key" : 250
                	}
                	vm.dataLoading = true;
                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=9lafm1', params).then(function (data) {
		        		if (data.callSuccess === "1") {
		        			vm.dataLoading = false;
		        			console.log("data.jsonObject---------------------", data.jsonObject);
		        			var irsF5471ScheduleMDataTemp = data.jsonObject;
		        			var i = 1;
		        			
		        			var previous_line_no = irsF5471ScheduleMDataTemp[i].line_no;
		        			while (i < irsF5471ScheduleMDataTemp.length) {
								var tempObj = {};
								var j = i;

								var index = 0;
								tempObj.column_description = [];
								while (j < irsF5471ScheduleMDataTemp.length && previous_line_no == irsF5471ScheduleMDataTemp[j].line_no) {
									var line_style = {};
									if (irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[0] == "Line_No") {
											tempObj.line_no = parseInt(irsF5471ScheduleMDataTemp[j].line_no);
											tempObj.line_multiplier = irsF5471ScheduleMDataTemp[j].MULTIPLIER;
											
											/*if (parseInt(irsF5471ScheduleMDataTemp[j].line_no) <= 13 ||  parseInt(irsF5471ScheduleMDataTemp[j].line_no) === 27 ||  parseInt(irsF5471ScheduleMDataTemp[j].line_no) === 28)

			        							tempObj.line_multiplier = -1;
			        						else
			        							tempObj.line_multiplier = 1;*/
											
											tempObj.saveParams = {
												"tax_year": irsF5471ScheduleMDataTemp[j].object_id.split("~")[0],
												"trans_type_key": irsF5471ScheduleMDataTemp[j].object_id.split("~")[1],
												"trans_Details_key": irsF5471ScheduleMDataTemp[j].object_id.split("~")[3],
												"occurrence": irsF5471ScheduleMDataTemp[j].object_id.split("~")[4],
												"form_key": irsF5471ScheduleMDataTemp[j].object_id.split("~")[6]
											}
										} else if (irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[0] == "TRANSACTIONS_OF_FOREIGN_CORPORATION_A" || irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[0] == 'NOTES') {
											if(irsF5471ScheduleMDataTemp[j].PROPERTY_TYPE == '0')
											{
												tempObj.line_style = {
														"background-color": '#D6D6D6',
														'width': '150px',
														'font-weight': 'bold'
													};
											}
											else
											{
												tempObj.line_style = {
														"background-color": '#f9f9f9',
														'width': '150px'
													};
											}
											
											tempObj.line_Description = irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[3];
											tempObj.total_line_type = irsF5471ScheduleMDataTemp[j].LINE_TYPE;
											
											if(irsF5471ScheduleMDataTemp[j].LINE_TYPE == 'A'|| irsF5471ScheduleMDataTemp[j].LINE_TYPE == 'T1'|| irsF5471ScheduleMDataTemp[j].LINE_TYPE == 'auto1' ){
												tempObj.line_type = 'A';
												/*var total_line_data = _.find(irsF5471ScheduleMDataTemp, {LINE_TYPE:'T1'});
												tempObj.total_line = total_line_data.line_no*/
												tempObj.total_line = 'TotalA'
												}
											else if(tempObj.line_Description == 'Notes')
											{
												tempObj.line_type = 'Notes';
												tempObj.column_description[6] = {
														"VALUE": angular.isDefined(irsF5471ScheduleMDataTemp[j].VALUE) ? irsF5471ScheduleMDataTemp[j].VALUE : null,
														"line_attrib_key": irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[1],
														"attrib_name": irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[0],
														"trans_Details_key": irsF5471ScheduleMDataTemp[j].object_id.split("~")[3],
														"occurrence" : irsF5471ScheduleMDataTemp[j].object_id.split("~")[4],
														"is_Display": false
													};
											}
											else if( irsF5471ScheduleMDataTemp[j].LINE_TYPE == 'B'|| irsF5471ScheduleMDataTemp[j].LINE_TYPE == 'T2'|| irsF5471ScheduleMDataTemp[j].LINE_TYPE == 'auto2')
												{tempObj.line_type = 'B';
												/*var total_line_data = _.find(irsF5471ScheduleMDataTemp, {LINE_TYPE:'T2'});
												tempObj.total_line = total_line_data.line_no*/
												tempObj.total_line = 'TotalB'
												}
											else
												{tempObj.line_type = 'C';
												tempObj.total_line = 'TotalC'
												}
											
										} else {
											if (irsF5471ScheduleMDataTemp[j].PROPERTY_TYPE == '9' || irsF5471ScheduleMDataTemp[j].PROPERTY_TYPE == '2') {
												var style = {};
												style = {
													'width': '150px'
												};
												
												tempObj.column_description[index] = {
													"VALUE": angular.isDefined(irsF5471ScheduleMDataTemp[j].VALUE) ? parseInt(irsF5471ScheduleMDataTemp[j].VALUE) * tempObj.line_multiplier  : null,
													"line_attrib_key": irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[1],
													"attrib_name": irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[0],
													"trans_Details_key": irsF5471ScheduleMDataTemp[j].object_id.split("~")[3],
													"occurrence" : irsF5471ScheduleMDataTemp[j].object_id.split("~")[4],
													"is_editable": (irsF5471ScheduleMDataTemp[j].PROPERTY_TYPE == '9' ? true : false),
													"is_Display": true,
													"column_style": style,
													"is_Update":'N',
													"attrib_order":parseInt(irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[4])
												}
												

											} else if (irsF5471ScheduleMDataTemp[j].PROPERTY_TYPE == '0') {
												var style = {
													"background-color": '#D6D6D6',
													'width': '150px'
												};
												tempObj.column_description[index] = {
													"VALUE": null,
													"line_attrib_key": irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[1],
													"attrib_name": irsF5471ScheduleMDataTemp[j].attrib_value.split("~")[0],
													"trans_Details_key": irsF5471ScheduleMDataTemp[j].object_id.split("~")[3],
													"occurrence" : irsF5471ScheduleMDataTemp[j].object_id.split("~")[4],
													"is_editable": false,
													"is_Display": false,
													"column_style": style
												}
											}
											
											index++;
										}
									/////////////////////////////////////////////////////////////////////////////////////////////
									previous_line_no = irsF5471ScheduleMDataTemp[j].line_no;
									j++;
								}
								if (j < irsF5471ScheduleMDataTemp.length)
									previous_line_no = irsF5471ScheduleMDataTemp[j].line_no;
								var tempIndex = $scope.irsF5471ScheduleMData.length;								
								if (angular.isDefined(tempObj.line_no))
									$scope.irsF5471ScheduleMData[tempIndex] = tempObj;
								i = j;
								
							}
							///changing the header col span value based on the sub_header leangth
							//var tempHeaderObj = _.find($scope.columnHeader, {})
		        			for(var i=0; i<$scope.irsF5471ScheduleMData.length; i++) {
		        				$scope.irsF5471ScheduleMData[i].column_description = _.sortBy($scope.irsF5471ScheduleMData[i].column_description, ['attrib_order']);
		        			}

						   // _.sortBy($scope.irsF5471ScheduleMData[tempIndex].column_description, ['attrib_order'])
		        			//$scope.irsF5471ScheduleMData =  _.sortBy($scope.irsF5471ScheduleMData, ['attrib_order']);
							console.log("final $scope.irsF5471ScheduleMData--------------", $scope.irsF5471ScheduleMData);
							
							$scope.irs5471SchdMNotes = _.find($scope.irsF5471ScheduleMData,{line_Description:'Notes'});
							
							
							
							getSchdMAdjAmt();
							
						} else {
							if (data.errorMessage === "no access") {
								AlertService.add("", "Sorry you do not have access to do the requested action.",
									4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
									"",
									"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
									4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

					});
                }

                loadScheduleMData();
                
               
//--------------------------------------------------------------------------------------------------------------------------------------------------------------  
                
                function getSchdMAdjAmt()
                {
                	var params = {
                			"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                        	"combination_key":vm.rowData.COMBINATION_KEY,
                        	"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                        	"jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                        			}
                	vm.dataLoading = true;
                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=camdc3",params).then(function(data) {
                		if (data.callSuccess === "1") {
                			vm.dataLoading = false;
                			var resposneTempData = data.jsonObject;
                			$scope.resposneTempData = data.jsonObject;
                			var temp_no = null;
                			var total_line= _.find($scope.irsF5471ScheduleMData, {total_line_type:'T1'});
                			//if(parseInt(workspaceFactory.activeScreen.filters.filterParams.tax_year) < 2018)
                				temp_no = total_line.LINE_NO;
                			//else
                				//temp_no = 13;
                			for(var i =0 ;i <resposneTempData.length; i++)
                			{   
                				var temp_line_obj = _.find($scope.irsF5471ScheduleMData, {line_no:resposneTempData[i].LINE_NO/*.toString()*/});
                				
                				if(temp_line_obj !== undefined)
                				{
                					
                					var temp_col_obj3 = _.find(temp_line_obj.column_description, {attrib_name : 'US_PERSON_FILING_RETURN_SYSTEM'});
                					var tem_col_b_obj = _.find(temp_line_obj.column_description, {attrib_name : 'US_PERSON_FILING_RETURN_OVERRIDE'});
                					if(temp_col_obj3 !== undefined)
                					{
                						//if(resposneTempData[i].US_PERSON_FILING_RETURN !==0 ) {
                							for(var j=0; j<temp_line_obj.column_description.length; j++) {
	                							if(temp_line_obj.column_description[1].trans_Details_key == "0" ){
	                								temp_line_obj.column_description[0].VALUE = resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM /** -1*/ ? resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM /** -1 */: null;
	                								temp_line_obj.column_description[1].VALUE = resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM /** -1*/ ? resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM /** -1 */: null;
		                							//}	
                							} else 
                								temp_line_obj.column_description[0].VALUE = resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM /** -1*/ ? resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM /** -1 */: null;
                								temp_line_obj.column_description[1].VALUE = tem_col_b_obj.VALUE 
                								
                						}
                						
                					}
                					var temp_col_obj1 = _.find(temp_line_obj.column_description, {attrib_name : 'ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM'});
                					var temp_col_c_obj = _.find(temp_line_obj.column_description, {attrib_name : 'ANY_DOMESTIC_PARTNERSHIP_FILING_OVERRIDE'});
                					if(temp_col_obj1 !== undefined)
                					{
                					/*	if( parseInt(resposneTempData[i].LINE_NO) > 26 && parseInt(resposneTempData[i].LINE_NO) < 31 && resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING !== 0) {
                							*/for(var j=0; j<temp_line_obj.column_description.length; j++) {
	                							if(temp_line_obj.column_description[3].trans_Details_key == "0" ){
	                								temp_line_obj.column_description[2].VALUE = resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM /** -1*/ ? resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM /** -1 */: null;
	                								temp_line_obj.column_description[3].VALUE = resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM /** -1*/ ? resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM /** -1 */: null;
	                								
	                							}else temp_line_obj.column_description[2].VALUE = resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM /** -1*/ ? resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM /** -1 */: null;
	                								temp_line_obj.column_description[3].VALUE = temp_col_c_obj.VALUE 	
                							}                						
                						
                					}
	                					var temp_col_obj2 = _.find(temp_line_obj.column_description, {attrib_name : 'FOREIGN_PARTNERSHIP_FILING_SYSTEM'});
	                					var temp_col_d_obj = _.find(temp_line_obj.column_description, {attrib_name : 'FOREIGN_PARTNERSHIP_FILING_OVERRIDE'});
	                					if(temp_col_obj2 !== undefined)
	                					{
	                						/*if( parseInt(resposneTempData[i].LINE_NO) > 26 && parseInt(resposneTempData[i].LINE_NO) < 31 && resposneTempData[i].B_AMT !==0) {*/
	                							for(var j=0; j<temp_line_obj.column_description.length; j++) {
		                							if(temp_line_obj.column_description[5].trans_Details_key == "0" ){
		                								temp_line_obj.column_description[4].VALUE = resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM /** -1*/ ? resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM /** -1 */: null;
		                								temp_line_obj.column_description[5].VALUE = resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM /** -1*/ ? resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM /** -1 */: null;
		                								//temp_line_obj.column_description[4].VALUE = resposneTempData[i].FOREIGN_PARTNERSHIP_FILING /** -1*/ ? resposneTempData[i].FOREIGN_PARTNERSHIP_FILING /** -1 */: null;
		                							}else 
		                							temp_line_obj.column_description[4].VALUE = resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM /** -1*/ ? resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM /** -1 */: null;
		                							temp_line_obj.column_description[5].VALUE = temp_col_d_obj.VALUE;	  
	                							}
	                						
	                					}
	                					
	                					var temp_col_obj4 = _.find(temp_line_obj.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION'});
	                					if(temp_col_obj4 !== undefined)
	                					{
	                						//if(resposneTempData[i].US_PERSON_FILING_RETURN !==0 ) {
	                							for(var j=0; j<temp_line_obj.column_description.length; j++) {
		                							if(temp_line_obj.column_description[6].trans_Details_key == "0" ){
		                								temp_line_obj.column_description[6].VALUE = resposneTempData[i].PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION /** -1*/ ? resposneTempData[i].PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION /** -1 */: null;
		                							//}	
	                							}else temp_line_obj.column_description[6].VALUE = temp_col_obj4.VALUE 
	                								
	                						}
	                						
	                					}
	                					var temp_col_obj5 = _.find(temp_line_obj.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_ANY_CORPORATION'});
	                					if(temp_col_obj5 !== undefined)
	                					{
	                						//if(resposneTempData[i].US_PERSON_FILING_RETURN !==0 ) {
	                							for(var j=0; j<temp_line_obj.column_description.length; j++) {
		                							if(temp_line_obj.column_description[7].trans_Details_key == "0" ){
		                								temp_line_obj.column_description[7].VALUE = resposneTempData[i].PERCENT10_US_SHAREHOLDER_ANY_CORPORATION /** -1*/ ? resposneTempData[i].PERCENT10_US_SHAREHOLDER_ANY_CORPORATION /** -1 */: null;
		                							//}	
	                							}else temp_line_obj.column_description[7].VALUE = temp_col_obj5.VALUE 
	                								
	                						}
	                						
	                					}
	                					//vm.computeRowTotal(temp_line_obj.line_no);
	                					vm.computeColTotal(temp_line_obj.line_no);
	                				}
                				}                			
                			console.log("$scope.irsF5471ScheduleMData--------------", $scope.irsF5471ScheduleMData);
                			
                		}
                		else {
							if (data.errorMessage === "no access") {
								AlertService.add("error","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"error",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						 }

                	 });
                }
                
               
                
               

//--------------------------------------------------------------------------------------------------------------------------------------------------------------  
                
                vm.computeColTotal = function (v_line_no,v_column_desc) {
	                var total_line_C = _.find($scope.irsF5471ScheduleMData, {total_line_type:'T3'});
	                var total_line_T2 = _.find($scope.irsF5471ScheduleMData, {total_line_type:'T2'});
                	
                	if (v_column_desc !== undefined && v_line_no < (total_line_C.line_no)){                		
                	
                	for(var i =0 ;i <$scope.resposneTempData.length; i++)
        			{  
						var total_line_B = _.find($scope.irsF5471ScheduleMData, {total_line_type:'T1'})
                		if(parseInt(v_line_no) < (total_line_B.line_no)){
                		var total_line = _.find($scope.irsF5471ScheduleMData, {total_line_type:'T1'});
                		}
                		else var total_line =_.find($scope.irsF5471ScheduleMData, {total_line_type:'T2'});
        				var temp_line_obj_update = _.find($scope.irsF5471ScheduleMData, {line_no:v_line_no});
        				
        				if(temp_line_obj_update !== undefined && parseInt(temp_line_obj_update.line_no) == $scope.resposneTempData[i].LINE_NO )
        				{
        					var temp_col_obj1 = _.find(temp_line_obj_update.column_description, {attrib_name : 'ANY_DOMESTIC_PARTNERSHIP_FILING_OVERRIDE'});
        					var temp_total_col_obj1 = _.find(total_line.column_description, {attrib_name : 'ANY_DOMESTIC_PARTNERSHIP_FILING_OVERRIDE'});
        					
        					if(temp_col_obj1 !== undefined){
            					var c_amt = $scope.resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM == 0 ? null :$scope.resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM.toString()
            					temp_col_obj1.VALUE	= temp_col_obj1.VALUE === '' || temp_col_obj1.VALUE === undefined ?  null : temp_col_obj1.VALUE !== null ? temp_col_obj1.VALUE.toString() :temp_col_obj1.VALUE
            							if (c_amt == null && temp_col_obj1.VALUE == null){
    	            						temp_col_obj1.is_Update = 'N';
    		        						temp_total_col_obj1.is_Update = 'Y';
    	            					}
            					if (temp_col_obj1.VALUE !== undefined && (temp_col_obj1.VALUE)  !== c_amt ){
        				    		temp_col_obj1.is_Update = 'Y'; 
        							temp_total_col_obj1.is_Update = temp_col_obj1.is_Update = 'Y';
        				    	}      					
        					   else if (temp_col_obj1.trans_Details_key!== '0'){
	        						    temp_col_obj1.is_Update = 'Y'; 
	        						    temp_total_col_obj1.is_Update = temp_col_obj1.is_Update= 'Y';
    					       }else{
	        						   temp_col_obj1.is_Update = 'N';
	        						   temp_total_col_obj1.is_Update = 'Y';
    					       }
        				     }
        					var temp_col_obj2 = _.find(temp_line_obj_update.column_description, {attrib_name : 'FOREIGN_PARTNERSHIP_FILING_OVERRIDE'});
        					var temp_total_col_obj2 = _.find(total_line.column_description, {attrib_name : 'FOREIGN_PARTNERSHIP_FILING_OVERRIDE'});
        					
        					if(temp_col_obj2 !== undefined){
            					var d_amt = $scope.resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM == 0 ? null : $scope.resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM.toString()
            					temp_col_obj2.VALUE	= temp_col_obj2.VALUE === '' || temp_col_obj2.VALUE === undefined  ? null : temp_col_obj2.VALUE !== null ? temp_col_obj2.VALUE.toString() :temp_col_obj2.VALUE
            							if (d_amt == null && temp_col_obj2.VALUE == null){
    	            						temp_col_obj2.is_Update = 'N';
    		        						temp_total_col_obj2.is_Update = 'Y';
    	            					}
            					if (temp_col_obj2.VALUE !== undefined && (temp_col_obj2.VALUE)  !== d_amt ){
        				    		temp_col_obj2.is_Update = 'Y'; 
        							temp_total_col_obj2.is_Update = 'Y';
        				    	}      					
        					   else if (temp_col_obj2.trans_Details_key!== '0'){
	        						    temp_col_obj2.is_Update = 'Y'; 
	        						    temp_total_col_obj2.is_Update = 'Y';
    					       }else{
	        						   temp_col_obj2.is_Update = 'N';
	        						   temp_total_col_obj2.is_Update = 'Y';
    					       }
        				     }
            				var temp_col_obj3 = _.find(temp_line_obj_update.column_description, {attrib_name : 'US_PERSON_FILING_RETURN_OVERRIDE'});
            				var temp_total_col_obj3 = _.find(total_line.column_description, {attrib_name : 'US_PERSON_FILING_RETURN_OVERRIDE'});
            				if(temp_col_obj3 !== undefined){
            					var b_amt = $scope.resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM == 0 ? null : $scope.resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM.toString()
            					temp_col_obj3.VALUE	= temp_col_obj3.VALUE === '' || temp_col_obj3.VALUE === undefined ? null : temp_col_obj3.VALUE !== null ? temp_col_obj3.VALUE.toString() :temp_col_obj3.VALUE
            							if (b_amt == null && temp_col_obj3.VALUE == null){
    	            						temp_col_obj3.is_Update = 'N';
    		        						temp_total_col_obj3.is_Update = 'Y';
    	            					}
            					if (temp_col_obj3.VALUE !== undefined && (temp_col_obj3.VALUE)  !== b_amt ){
        				    		temp_col_obj3.is_Update = 'Y'; 
        							temp_total_col_obj3.is_Update = 'Y';
        				    	}      					
        					   else if (temp_col_obj3.trans_Details_key!== '0'){
	        						    temp_col_obj3.is_Update = 'Y'; 
	        						    temp_total_col_obj3.is_Update = 'Y';
    					       }else{
	        						   temp_col_obj3.is_Update = 'N';
	        						   temp_total_col_obj3.is_Update = 'Y';
    					       }
        				     }
            				
            				var temp_col_obj4 = _.find(temp_line_obj_update.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION'});
            				var temp_total_col_obj4 = _.find(total_line.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION'});
            				if(temp_col_obj4 !== undefined){
            					var e_amt = $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION == 0 ? null : $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION.toString()
            					temp_col_obj4.VALUE	= temp_col_obj4.VALUE === '' || temp_col_obj4.VALUE === undefined ? null : temp_col_obj4.VALUE !== null ? temp_col_obj4.VALUE.toString() :temp_col_obj4.VALUE
            							if (e_amt == null && temp_col_obj4.VALUE == null){
    	            						temp_col_obj4.is_Update = 'N';
    		        						temp_total_col_obj4.is_Update = 'Y';
    	            					}
            							if (temp_col_obj4.VALUE !== undefined && (temp_col_obj4.VALUE)  !== e_amt ){
                				    		temp_col_obj4.is_Update = 'Y'; 
                							temp_total_col_obj4.is_Update = 'Y';
                				    	}      					
                					   else if (temp_col_obj4.trans_Details_key!== '0'){
        	        						    temp_col_obj4.is_Update = 'Y'; 
        	        						    temp_total_col_obj4.is_Update = 'Y';
            					       }else{
        	        						   temp_col_obj4.is_Update = 'N';
        	        						   temp_total_col_obj4.is_Update = 'Y';
            					       }
                				     }
            				var temp_col_obj5 = _.find(temp_line_obj_update.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_ANY_CORPORATION'});
            				var temp_total_col_obj5 = _.find(total_line.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_ANY_CORPORATION'});
            				if(temp_col_obj5 !== undefined){
            					var f_amt = $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_ANY_CORPORATION == 0 ? null : $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_ANY_CORPORATION.toString()
            					temp_col_obj5.VALUE	= temp_col_obj5.VALUE === '' || temp_col_obj5.VALUE === undefined ? null : temp_col_obj5.VALUE !== null ? temp_col_obj5.VALUE.toString() :temp_col_obj5.VALUE	
            							if (f_amt == null && temp_col_obj5.VALUE == null){
    	            						temp_col_obj5.is_Update = 'N';
    		        						temp_total_col_obj5.is_Update = 'Y';
    	            					}
            							if (temp_col_obj5.VALUE !== undefined && (temp_col_obj5.VALUE)  !== f_amt ){
                				    		temp_col_obj5.is_Update = 'Y'; 
                							temp_total_col_obj5.is_Update = 'Y';
                				    	}      					
                					   else if (temp_col_obj5.trans_Details_key!== '0'){
        	        						    temp_col_obj5.is_Update = 'Y'; 
        	        						    temp_total_col_obj5.is_Update = 'Y';
            					       }else{
        	        						   temp_col_obj5.is_Update = 'N';
        	        						   temp_total_col_obj5.is_Update = 'Y';
            					       }
                				     }
            				}
        			}
                	
                }
                	
                	if (v_column_desc !== undefined && v_line_no > total_line_T2.line_no){
                		
                    	
                    	for(var i =0 ;i <$scope.resposneTempData.length; i++)
            			{  
                    		
            				var temp_line_obj_update = _.find($scope.irsF5471ScheduleMData, {line_no:v_line_no});
            				
            				if(temp_line_obj_update !== undefined && parseInt(temp_line_obj_update.line_no) == $scope.resposneTempData[i].LINE_NO )
            				{
            					var temp_col_obj1 = _.find(temp_line_obj_update.column_description, {attrib_name : 'ANY_DOMESTIC_PARTNERSHIP_FILING_OVERRIDE'});
            					
            					if(temp_col_obj1 !== undefined){
                					var c_amt = $scope.resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM == 0 ? null :$scope.resposneTempData[i].ANY_DOMESTIC_PARTNERSHIP_FILING_SYSTEM.toString()
                					temp_col_obj1.VALUE	= temp_col_obj1.VALUE === '' || temp_col_obj1.VALUE === undefined ? null : temp_col_obj1.VALUE !== null ? temp_col_obj1.VALUE.toString() :temp_col_obj1.VALUE
                							if (c_amt == null && temp_col_obj1.VALUE == null){
        	            						temp_col_obj1.is_Update = 'N';
        	            					}
                					if (temp_col_obj1.VALUE !== undefined && (temp_col_obj1.VALUE)  !== c_amt ){
            				    		temp_col_obj1.is_Update = 'Y';
            				    	}      					
            					   else if (temp_col_obj1.trans_Details_key!== '0'){
    	        						    temp_col_obj1.is_Update = 'Y';
        					       }else{
    	        						   temp_col_obj1.is_Update = 'N';
        					       }
            				     }
            					var temp_col_obj2 = _.find(temp_line_obj_update.column_description, {attrib_name : 'FOREIGN_PARTNERSHIP_FILING_OVERRIDE'});
            					
            					if(temp_col_obj2 !== undefined){
                					var d_amt = $scope.resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM == 0 ? null : $scope.resposneTempData[i].FOREIGN_PARTNERSHIP_FILING_SYSTEM.toString()
                					temp_col_obj2.VALUE	= temp_col_obj2.VALUE === '' || temp_col_obj2.VALUE === undefined ? null : temp_col_obj2.VALUE !== null ? temp_col_obj2.VALUE.toString() :temp_col_obj2.VALUE
                							if (d_amt == null && temp_col_obj2.VALUE == null){
        	            						temp_col_obj2.is_Update = 'N';
        	            					}
                					if (temp_col_obj2.VALUE !== undefined && (temp_col_obj2.VALUE)  !== d_amt ){
            				    		temp_col_obj2.is_Update = 'Y'; 
            				    	}      					
            					   else if (temp_col_obj2.trans_Details_key!== '0'){
    	        						    temp_col_obj2.is_Update = 'Y'; 
        					       }else{
    	        						   temp_col_obj2.is_Update = 'N';
        					       }
            				     }
                				var temp_col_obj3 = _.find(temp_line_obj_update.column_description, {attrib_name : 'US_PERSON_FILING_RETURN_OVERRIDE'});
                				if(temp_col_obj3 !== undefined){
                					var b_amt = $scope.resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM == 0 ? null : $scope.resposneTempData[i].US_PERSON_FILING_RETURN_SYSTEM.toString()
                					temp_col_obj3.VALUE	= temp_col_obj3.VALUE === '' || temp_col_obj3.VALUE === undefined  ? null : temp_col_obj3.VALUE !== null? (temp_col_obj3.VALUE).toString():(temp_col_obj3.VALUE)
                							if (b_amt == null && temp_col_obj3.VALUE == null){
        	            						temp_col_obj3.is_Update = 'N';
        	            					}
                					if (temp_col_obj3.VALUE !== undefined && (temp_col_obj3.VALUE)  !== b_amt ){
            				    		temp_col_obj3.is_Update = 'Y'; 
            				    	}      					
            					   else if (temp_col_obj3.trans_Details_key!== '0'){
    	        						    temp_col_obj3.is_Update = 'Y'; 
        					       }else{
    	        						   temp_col_obj3.is_Update = 'N';
        					       }
            				     }
                				
                				var temp_col_obj4 = _.find(temp_line_obj_update.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION'});
                				if(temp_col_obj4 !== undefined){
                					var e_amt = $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION == 0 ? null : $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION.toString()
                					temp_col_obj4.VALUE	= temp_col_obj4.VALUE === '' || temp_col_obj4.VALUE === undefined  ? null : temp_col_obj4.VALUE !== null? (temp_col_obj4.VALUE).toString(): (temp_col_obj4.VALUE)
                							if (e_amt == null && temp_col_obj4.VALUE == null){
        	            						temp_col_obj4.is_Update = 'N';
        	            					}
                							if (temp_col_obj4.VALUE !== undefined && (temp_col_obj4.VALUE)  !== e_amt ){
                    				    		temp_col_obj4.is_Update = 'Y'; 
                    				    	}      					
                    					   else if (temp_col_obj4.trans_Details_key!== '0'){
            	        						    temp_col_obj4.is_Update = 'Y'; 
                					       }else{
            	        						   temp_col_obj4.is_Update = 'N';
                					       }
                    				     }
                				var temp_col_obj5 = _.find(temp_line_obj_update.column_description, {attrib_name : 'PERCENT10_US_SHAREHOLDER_ANY_CORPORATION'});
                				if(temp_col_obj5 !== undefined){
                					var f_amt = $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_ANY_CORPORATION == 0 ? null : $scope.resposneTempData[i].PERCENT10_US_SHAREHOLDER_ANY_CORPORATION.toString()
                					temp_col_obj5.VALUE	= temp_col_obj5.VALUE === '' || temp_col_obj5.VALUE === undefined ? null : temp_col_obj5.VALUE !== null? (temp_col_obj5.VALUE).toString(): (temp_col_obj5.VALUE)
                							if (f_amt == null && temp_col_obj5.VALUE == null){
        	            						temp_col_obj5.is_Update = 'N';
        	            					}
                							if (temp_col_obj5.VALUE !== undefined && (temp_col_obj5.VALUE)  !== f_amt ){
                    				    		temp_col_obj5.is_Update = 'Y'; 
                    				    	}      					
                    					   else if (temp_col_obj5.trans_Details_key!== '0'){
            	        						    temp_col_obj5.is_Update = 'Y'; 
                					       }else{
            	        						   temp_col_obj5.is_Update = 'N';
            					}
                				}	
                				}
            			}
                    	
                    }
                	
                	
                	var startIndex = null;
                	var endIndex  = null;
                	var indexLine = _.find($scope.irsF5471ScheduleMData, {total_line_type:'T1'})
                	
               
                	if(v_line_no < indexLine.line_no)
                	{
                		startIndex = 0;
                		endIndex = getIndex('A');
                	}
                	else
                	{
                		var temp = getIndex('B');
                		startIndex = temp[0];
                		endIndex = temp[1];
                	}
                	var col_b_total = null;
    				var col_c_total = null;
    				var col_d_total = null;
    				var col_e_total = null;
    				var col_f_total = null;
        			for (var i = startIndex; i <= endIndex; i++) {
        				var line_obj = null;        				
        				var total_line_obj = null;       				

        				line_obj = $scope.irsF5471ScheduleMData[i];
        				if (angular.isDefined(line_obj) && line_obj != null && angular.isDefined(line_obj.column_description) && line_obj.column_description != null) {
        					for (var j = 0; j < line_obj.column_description.length; j++) 
        					{	 
        					if(i == endIndex)
        						{
        							if(angular.isDefined(line_obj.column_description[j].VALUE))
        							{
        								if(line_obj.column_description[j].attrib_name == 'US_PERSON_FILING_RETURN_OVERRIDE')
        									line_obj.column_description[j].VALUE = col_b_total//(col_b_total == null ? 0 : col_b_total);
        								if(line_obj.column_description[j].attrib_name == 'ANY_DOMESTIC_PARTNERSHIP_FILING_OVERRIDE')
        									line_obj.column_description[j].VALUE = col_c_total//(col_c_total == null ? 0 : col_c_total);
        								if(line_obj.column_description[j].attrib_name == 'FOREIGN_PARTNERSHIP_FILING_OVERRIDE')
        									line_obj.column_description[j].VALUE = col_d_total//(col_d_total == null ? 0 : col_d_total);
        								if(line_obj.column_description[j].attrib_name == 'PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION')
        									line_obj.column_description[j].VALUE = col_e_total//(col_e_total == null ? 0 : col_e_total);
        								if(line_obj.column_description[j].attrib_name == 'PERCENT10_US_SHAREHOLDER_ANY_CORPORATION')
        									line_obj.column_description[j].VALUE = col_f_total//(col_f_total == null ? 0 : col_f_total);
        							}
        						}
        						else{
	        						if (angular.isDefined(line_obj.column_description[j]) && angular.isDefined(line_obj.column_description[j].is_Display) && line_obj.column_description[j].is_Display) 
	        						{
	        							if (angular.isDefined(line_obj.column_description[j]) && line_obj.column_description[j] != null && angular.isDefined(line_obj.column_description[j].VALUE) && line_obj.column_description[j].VALUE != null) 
	        							{		
	        								if (line_obj.column_description[j].attrib_name == 'US_PERSON_FILING_RETURN_OVERRIDE' )
	        								{
	        									if(col_b_total != null)
	        										col_b_total = (parseInt(col_b_total) + parseInt(line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE) ).toString();
	            								else
	            									col_b_total = (line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE).toString();
	        								}
	        								
	        								else if (line_obj.column_description[j].attrib_name == 'ANY_DOMESTIC_PARTNERSHIP_FILING_OVERRIDE' )
	        								{
	        									if(col_c_total != null)
	        										col_c_total = (parseInt(col_c_total) + parseInt(line_obj.column_description[j].VALUE == '' ? 0 : line_obj.column_description[j].VALUE) ).toString();
	            								else
	            									col_c_total = (line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE).toString();
	        								}
	        								else if (line_obj.column_description[j].attrib_name == 'FOREIGN_PARTNERSHIP_FILING_OVERRIDE' )
	        								{
	        									if(col_d_total != null)
	        										col_d_total = (parseInt(col_d_total) + parseInt(line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE) ).toString();
	            								else
	            									col_d_total = (line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE).toString();
	        								}
	        								else if (line_obj.column_description[j].attrib_name == 'PERCENT10_US_SHAREHOLDER_FOREIGN_CORPORATION' )
	        								{
	        									if(col_e_total != null)
	        										col_e_total = (parseInt(col_e_total) + parseInt(line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE) ).toString();
	            								else
	            									col_e_total = (line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE).toString();
	        								}
	        								else if (line_obj.column_description[j].attrib_name == 'PERCENT10_US_SHAREHOLDER_ANY_CORPORATION' )
	        								{
	        									if(col_f_total != null)
	        										col_f_total = (parseInt(col_f_total) + parseInt(line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE) ).toString();
	            								else
	            									col_f_total = (line_obj.column_description[j].VALUE == '' ? 0 :line_obj.column_description[j].VALUE).toString();
	        								}
	        									
	        							}
	        						}
        						}
        					}
        					
        				}
        			}
        		}
                
               
//--------------------------------------------------------------------------------------------------------------------------------------------------------------   
                
                function getIndex(line_type)
                {
                	var indexList=[];
               
                	if(line_type == 'A')
                	{
                		/*if(parseInt(workspaceFactory.activeScreen.filters.filterParams.tax_year) < 2018)
                			return (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 1 through 11'}));
                		else if(parseInt(workspaceFactory.activeScreen.filters.filterParams.tax_year)> 2018 && parseInt(GlobalService.globalParams.tax_year) < 2021 ){
                			return (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 1 through 12'}));
                			}
                		else {return (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 1 through 14'}));
                		}	*/
                		return(_.findIndex($scope.irsF5471ScheduleMData, {total_line_type:'T1'}));
                	}
                	else
                	{
	
						indexList[0] = (_.findIndex($scope.irsF5471ScheduleMData, {total_line_type:'T1'})) + 1;
                		indexList[1] = (_.findIndex($scope.irsF5471ScheduleMData, {total_line_type:'T2'}));
                		return indexList;
                		/*if(parseInt(workspaceFactory.activeScreen.filters.filterParams.tax_year) < 2018)
                		{
                			indexList[0] = (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 1 through 11'})) + 1;
                			indexList[1] = (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 13 through 23'}));
                			return indexList;
                		}
                		else if(parseInt(workspaceFactory.activeScreen.filters.filterParams.tax_year)> 2018 && parseInt(workspaceFactory.activeScreen.filters.filterParams.tax_year) < 2021 )
                		{
                			indexList[0] = (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 1 through 12'})) + 1;
                			indexList[1] = (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 14 through 25'}));
                			return indexList;
                		}
                		else
                		{
							indexList[0] = (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 1 through 14'})) + 1;
                			indexList[1] = (_.findIndex($scope.irsF5471ScheduleMData, {line_Description:'Add lines 16 through 29'}));
                			return indexList;
						}*/
                	}
                }
               /* vm.link = function()
                {
                	console.log("in link function------");
                	workspaceFactory.loadLink("workflow", null, "39", "67", [], {}, null);
                }*/
//--------------------------------------------------------------------------------------------------------------------------------------------------------------                  
                
                $scope.$on('workflowSave', function (event, data) {
        			vm.saveScheduleMData();
        		})
        		
//--------------------------------------------------------------------------------------------------------------------------------------------------------------  
        		
        		vm.saveScheduleMData = function () {
        			if ($scope.crudLoading) {
        				AlertService.add("info", "Please wait while we save this request", 4000);
        				return;
        			}

        			$scope.crudLoading = true;
        			
        			var editRow = [];

        			var scheduleSettings = [{
	                	"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
	                    "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
	                    "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
	                }];
        			//===================GETTING IRS-FORM DATA I  JSONOBJECT TO SAVE
        			console.log("in 5471------",$scope.irsF5471ScheduleMData);
        			for (var i = 0; i < $scope.irsF5471ScheduleMData.length-1; i++) {
        				var temp_line_obj = $scope.irsF5471ScheduleMData[i].column_description;
        				//})
        				
        				for (var j = 0; j < temp_line_obj.length; j++) {
        					
        					if (temp_line_obj[j].is_Update == 'Y') {
        						var returnObj1 = [{
        							"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
        							"trans_type_key": $scope.irsF5471ScheduleMData[i].saveParams.trans_type_key,
        							"combination_key": vm.rowData.COMBINATION_KEY,
        							"group_obj_key": vm.rowData.GROUP_OBJ_KEY,
        							"form_key": $scope.irsF5471ScheduleMData[i].saveParams.form_key,
        							"line_no": $scope.irsF5471ScheduleMData[i].line_no,
        							"occurence": ((temp_line_obj[j].occurrence == "0") ? null : temp_line_obj[j].occurrence),
        							"line_attrib_key": temp_line_obj[j].line_attrib_key,
        							"Attribute_Name": temp_line_obj[j].attrib_name,
        							"Attribute_Value": (temp_line_obj[j].VALUE === null ? '' : temp_line_obj[j].VALUE * $scope.irsF5471ScheduleMData[i].line_multiplier),
        							"trans_dtls_key": ((temp_line_obj[j].trans_Details_key == "0") ? null : temp_line_obj[j].trans_Details_key),
        							"trans_status": "A"
        						}];
        					
        						editRow.push(returnObj1);

        					}
        				}
        				
        			}

        			var returnObj2 = [{
        				"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
						"trans_type_key": $scope.irs5471SchdMNotes.saveParams.trans_type_key,
						"combination_key": vm.rowData.COMBINATION_KEY,
						"group_obj_key": vm.rowData.GROUP_OBJ_KEY,
						"form_key": $scope.irs5471SchdMNotes.saveParams.form_key,
						"line_no": $scope.irs5471SchdMNotes.line_no,
						"occurence": $scope.irs5471SchdMNotes.column_description[6] == undefined  || $scope.irs5471SchdMNotes.column_description[6] ? null : $scope.irs5471SchdMNotes.column_description[6].occurrence,
						"line_attrib_key":  $scope.irs5471SchdMNotes.column_description[6].line_attrib_key ,
						"Attribute_Name": $scope.irs5471SchdMNotes.column_description[6].attrib_name,
						"Attribute_Value": ($scope.irs5471SchdMNotes.column_description[6].VALUE != null ? $scope.irs5471SchdMNotes.column_description[6].VALUE : " "),
						"trans_dtls_key": (($scope.irs5471SchdMNotes.column_description[6].trans_Details_key == "0" || $scope.irs5471SchdMNotes.column_description[6].trans_Details_key == undefined) ? null : $scope.irs5471SchdMNotes.column_description[6].trans_Details_key),
						"trans_status": "A"
					}];
        			editRow.push(returnObj2);
        			var scheduleDetails = {
        				"addrows": [],
        				"editrows": editRow,
        				"removerows": []
        			};
        			console.log("scheduleDetails-----------------------", scheduleDetails);
        			var message = "Schedule M details are sucessfully save/edit";

        			scheduleMGroupObjFactory.saveScheduleM(scheduleSettings,
        				scheduleDetails).then(
        				function (result) {

        					if (result.errorMessage && result.errorMessage !== 'null') {
        						AlertService.add("error", result.errorMessage, 4000);
        						$scope.crudLoading = false;
        					} else {
        						vm.saveFlag = true;
        						AlertService.add("success", message, 4000);
        						
        						$scope.crudLoading = false;
        						vm.loadFlag = false;
        						vm.saveFlag = true;
        						loadScheduleMData();
        					}
        				});
        		}
                
         
           }
 });