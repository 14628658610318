define([
    'angular'
], function () {
    'use strict';

    var services = angular.module('app.dstAdminService', [])
                          .factory('DSTBasisFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTBasisFactory])
                          .factory('DSTCurrFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTCurrFactory])
                          .factory('DSTRetPeriodFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTRetPeriodFactory])
                          .factory('DSTStkTypeFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTStkTypeFactory])
                          .factory('DSTStirFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTStirFactory])
                          .factory('DSTAppMsgFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTAppMsgFactory])
                          .factory('DSTFormCreateFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTFormCreateFactory])
                          .factory('DSTStmtFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTStmtFactory])
                          .factory('DSTAdminRefreshFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTAdminRefreshFactory])
                          .factory('DSTrollOverFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTrollOverFactory])
                          .factory('DSTAdminIsFiledFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', DSTAdminIsFiledFactory]);

    function DSTBasisFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateBasisData = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=fdhs3s';
            var params = {
                'sso_id': sso_id,
                'basis_name': _data.rowData.BASIS_NAME,
                'basis_id': _data.rowData.BASIS_ID,
                'is_active': _data.rowData.IS_ACTIVE,
                'crudflag': 'U'
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteBasisData = function (rowData) {
            var params = {
                'sso_id': sso_id,
                'basis_name': rowData.BASIS_NAME,
                'basis_id': rowData.BASIS_ID,
                'is_active': rowData.IS_ACTIVE,
                'crudflag': 'D'
            };

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fdhs3s",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }

    function DSTCurrFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateCurrData = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=mxrfvu';
            var params = {
                'sso_id': sso_id,
                'currency_name': _data.rowData.CURRENCY_NAME,
                'currency_code': _data.rowData.CURRENCY_CODE,
                'currency_id': _data.rowData.CURRENCY_ID,
                'is_active': _data.rowData.IS_ACTIVE,
                'crudflag': 'U'
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteCurrData = function (rowData) {
            var params = {
                'sso_id': sso_id,
                'currency_name': rowData.CURRENCY_NAME,
                'currency_code': rowData.CURRENCY_CODE,
                'currency_id': rowData.CURRENCY_ID,
                'is_active': rowData.IS_ACTIVE,
                'crudflag': 'D'
            };

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=mxrfvu",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }

    function DSTRetPeriodFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateRetPeriodData = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=nj3taj';
            var params = {
                'sso_id': sso_id,
                'retpd_desc': _data.rowData.RETURN_TYPE_DESCRIPTION,
                'retpd_id': _data.rowData.RETURN_PERIOD_ID,
                'is_active': _data.rowData.IS_ACTIVE,
                'crudflag': 'U'
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteRetPeriodData = function (rowData) {
            var params = {
                'sso_id': sso_id,
                'retpd_desc': rowData.RETURN_TYPE_DESCRIPTION,
                'retpd_id': rowData.RETURN_PERIOD_ID,
                'is_active': rowData.IS_ACTIVE,
                'crudflag': 'D'
            };

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nj3taj",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }

    function DSTStkTypeFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateStkTypeData = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=6uvsff';
            var params = {
                'sso_id': sso_id,
                'stktype_name': _data.rowData.STOCK_TYPE_NAME,
                'stktype_id': _data.rowData.STOCK_TYPE_ID,
                'is_active': _data.rowData.IS_ACTIVE,
                'crudflag': 'U'
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteStkTypeData = function (rowData) {
            var params = {
                'sso_id': sso_id,
                'stktype_name': rowData.STOCK_TYPE_NAME,
                'stktype_id': rowData.STOCK_TYPE_ID,
                'is_active': rowData.IS_ACTIVE,
                'crudflag': 'D'
            };

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=6uvsff",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }

    function DSTStirFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateStirData = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=ya0rnu';
            var params = {
                'sso_id': sso_id,
                'reason_id': _data.rowData.REASONID,
                'reason_name': _data.rowData.REASON,
                'reason_desc': _data.rowData.REASONDESC,
                'is_active': _data.rowData.IS_ACTIVE,
                'crudflag': 'U'
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteStirData = function (rowData) {
            var params = {
                'sso_id': sso_id,  
                'reason_id': rowData.REASONID,
                'reason_name': rowData.REASON,
                'reason_desc': rowData.REASONDESC,
                'is_active': rowData.IS_ACTIVE,
                'crudflag': 'D'
            };

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ya0rnu",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        return factory;
    }

    function DSTAppMsgFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateAppMsgData = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=e03l7i';
            var params = {
                'sso_id': sso_id,  
                'message_id': _data.rowData.MESSAGEID,
                'message_name': _data.rowData.MESSAGEDETAILS,
                'message_desc': _data.rowData.MESSAGETYPE,
                'is_active': _data.rowData.ISACTIVE,
                'crudflag': 'U'
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    
                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteAppMsgData = function (rowData) {
            var params = {
                'sso_id': sso_id,  
                'message_id': rowData.MESSAGEID,
                'message_name': rowData.MESSAGEDETAILS,
                'message_desc': rowData.MESSAGETYPE,
                'is_active': rowData.ISACTIVE,
                'crudflag': 'D'
            };

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=e03l7i",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        return factory;
    }

    function DSTFormCreateFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var service = $injector.get('JsonObjectFactory');

        factory.save = function (formName, primaryType, secondaryType, tertiaryType, primaryFormTypeDesc,
            primaryFormActive, signatureFlag, electionFlag, sectionNumber, dataCollectActionFlag,
            dataCollectActionExpl, electionTerm, attachmentName, keyDiagnostic) {
            const defer = $q.defer();
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=izxt7h';
            const clobSettings = [{
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                jcd_key: workspaceFactory.activeScreen.jcd_key
            }];
            const clobData = [{
                'FORMNAME': formName,
                'PRIMARY_TYPE': primaryType,
                'SECONDARY_TYPE': secondaryType,
                'TERTIARY_TYPE': tertiaryType,
                'PRIMARYFORMTYPEDESC': primaryFormTypeDesc,
                'PRIMARY_FORM_ACTIVE': primaryFormActive,
                'SIGNATURE_FLAG': signatureFlag,
                'ELECTION_FLAG': electionFlag,
                'SECTION_NUMBER': sectionNumber,
                'DATACOLLECT_ACTION_FLAG': dataCollectActionFlag,
                'DATACOLLECT_ACTION_EXPL': dataCollectActionExpl,
                'ELECTION_TERM': electionTerm,
                'ATTACHMENT_NAME': attachmentName,
                'KEY_DIAGNOSTIC': keyDiagnostic
            }];
            const filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params = _.merge({}, GlobalService.globalParams, filterParams);
            params = _.extend({'clob_data': JSON.stringify(clobData)}, params);
            params = _.extend({'clob_settings': JSON.stringify(clobSettings)}, params);
            params.sso_id = USER_SETTINGS.user.sso_id;
            service.saveJSON(url, params)
                   .then((data) => {
                       if (data.callSuccess === '1') {
                           AlertService.add('success', 'Data saved successfully.', 4000);
                           $rootScope.$broadcast('dataFilters:refreshGrid', {
                               "refresh": true
                           });
                           defer.resolve();
                       } else {
                           AlertService.add('error', data.errorMessage);
                           defer.reject();
                       }
                   })
                   .catch((error) => {
                       console.log(error);
                       AlertService.add('error', 'Something went wrong while saving your changes, please try again!');
                       defer.reject();
                   });

            return defer.promise;
        };

        factory.update = function (formID, formName, formOrder, primaryType, secondaryType, tertiaryType, primaryFormTypeDesc,
            primaryFormActive, secondaryFormTypeDesc, secondaryFormActive, secondaryFormID = '', signatureFlag, electionFlag,
            sectionNumber, dataCollectActionFlag, dataCollectActionExpl, electionTerm, attachmentName, keyDiagnostic) {
            const defer = $q.defer();
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=o7c2yt';
            const clobSettings = [{
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                jcd_key: workspaceFactory.activeScreen.jcd_key
            }];
            const clobData = [{
                'FORMID': formID,
                'FORMNAME': formName,
                'FORMORDER': formOrder,
                'PRIMARY_TYPE': primaryType,
                'SECONDARY_TYPE': secondaryType,
                'TERTIARY_TYPE': tertiaryType,
                'PRIMARYFORMTYPEDESC': primaryFormTypeDesc,
                'PRIMARY_FORM_ACTIVE': primaryFormActive,
                'SECONDARYFORMTYPEDESC': secondaryFormTypeDesc,
                'SECONDARY_FORM_ACTIVE': secondaryFormActive,
                'SECONDARY_FORM_ID': secondaryFormID,
                'SIGNATURE_FLAG': signatureFlag,
                'ELECTION_FLAG': electionFlag,
                'SECTION_NUMBER': sectionNumber,
                'DATACOLLECT_ACTION_FLAG': dataCollectActionFlag,
                'DATACOLLECT_ACTION_EXPL': dataCollectActionExpl,
                'ELECTION_TERM': electionTerm,
                'ATTACHMENT_NAME': attachmentName,
                'KEY_DIAGNOSTIC': keyDiagnostic
            }];
            const filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params = _.merge({}, GlobalService.globalParams, filterParams);
            params = _.extend({'clob_data': JSON.stringify(clobData)}, params);
            params = _.extend({'clob_settings': JSON.stringify(clobSettings)}, params);
            params.sso_id = USER_SETTINGS.user.sso_id;
            service.saveJSON(url, params)
                   .then((data) => {
                       if (data.callSuccess === '1') {
                           AlertService.add('success', 'Data saved successfully.', 4000);
                           $rootScope.$broadcast('dataFilters:refreshGrid', {
                               "refresh": true
                           });
                           defer.resolve();
                       } else {
                           AlertService.add('error', data.errorMessage);
                           defer.reject();
                       }
                   })
                   .catch((error) => {
                       console.log(error);
                       AlertService.add('error', 'Something went wrong while saving your changes, please try again!');
                       defer.reject();
                   });

            return defer.promise;
        };

        return factory;
    }

    function DSTStmtFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.ActivateStmt = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=w1g6nw';
            var params = {
                'sso_id': sso_id,
                'statement_id': _data.rowData.STATEMENT_ID,
                'is_active': _data.rowData.IS_ACTIVE
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        return factory;
    }

    function DSTAdminRefreshFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.RefreshDSTMview = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=294ju1';
            var params = {
                'sso_id': sso_id,
                'mview_name': _data.rowData.OBJECT_NAME
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'MVIEW Refreshed Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        return factory;
    }

    function DSTrollOverFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.dstRollOver = function () {
            var filters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            const params = {
                sso_id: USER_SETTINGS.user.sso_id,
                tax_year: filters.tax_year
            };

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=i1300q",
                params:params
            }).then(function (response) {
                if(response.data.callSuccess === "1" ){
                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": false
                    });
                    AlertService.add("success", "Data RollOver Successful.", 4000);
                    return response.data;
                }else {
                    AlertService.add('error', response.data.errorMessage, 4000);
                }
            });
            
            return promise;
        }
        return factory;
    }

    function DSTAdminIsFiledFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.UpdateIsFiledStatus = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=38x972';
            var params = {
                'sso_id': sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'dcn_id': _data.rowData.DCNID,
                'filed_status_id': _data.rowData.FILED_STATUS_ID
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data Saved Successfully.', 4000);

                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        return factory;
    }
});
