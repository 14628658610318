define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.dividendsService',[])

        .factory("DividendsFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,AlertService,GlobalService,DataFiltersFactory,workspaceFactory,JsonObjectFactory,GENERAL_CONFIG) {
            var dividendsFactory = {};
            dividendsFactory.url = {};
            dividendsFactory.url.saveUpdateDividends  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tg6bvf";
            dividendsFactory.url.deleteDividends = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=t0d7co";

            dividendsFactory.getActiveScreen = function() {
                return workspaceFactory.activeScreen.screen_key;
            };

            dividendsFactory.getScreenFilters = function() {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams(): {};

                return filterParams;
            };

            dividendsFactory.getDividendTypes = function () {
                var params = {
                    "action_code":"zzi5c3"
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                    if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                       // AlertService.add("error", data.errorMessage);
                        return false;
                    }else{
                        return data.jsonObject;
                    }
                });
            };

            dividendsFactory.getTransCurrencies = function () {
                var params = {
                    "action_code":"3ucwjb"
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                    if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                       // AlertService.add("error", data.errorMessage);
                        return false;
                    }else{
                        return data.jsonObject;
                    }
                });
            };

            dividendsFactory.getEntities = function(tax_year, scenario, jcd_key, val) {
                var params = {
                    "action_code": "umt3zd",
                    "tax_year": tax_year,
                    "scenario": scenario,
                    "jcd_key": jcd_key,
                    "search_string": val
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            dividendsFactory.getPayeeEntities = function(tax_year, scenario, jcd_key, val) {
                var params = {
                    "action_code": "7bkc4j",
                    "tax_year": tax_year,
                    "scenario": scenario,
                    "jcd_key": jcd_key,
                    "search_string": val
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            

            dividendsFactory.getEntityFeeds = function(tp_key) {
                var params = {
                    "action_code": "8q0a79",
                    "tp_key": (tp_key===null || tp_key===undefined)?389124:tp_key//tp_key || '389124' /*  389124-- value added by Divya for WOrkflow 5/11/2019 */
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                   //     AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            dividendsFactory.getPayeeEntityFeeds = function(tp_key) {
                var params = {
                    "action_code": "e884ud",
                    "tp_key": (tp_key===null || tp_key===undefined)?389124:tp_key//tp_key || '389124' /*  389124-- value added by Divya for WOrkflow 5/11/2019 */
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                   //     AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            dividendsFactory.getDividendsPaid = function() {
                var params = {
                    "action_code": "9p9a6f"
                       };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                   //     AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            dividendsFactory.get304Entities = function(tax_year, scenario, jcd_key, val) {
                var params = {
                    "action_code": "vrtuvn",
                    "tax_year": tax_year,
                    "scenario": scenario,
                    "jcd_key": jcd_key,
                    "search_string": val
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            
            dividendsFactory.getDividendsRecieved = function() {
                var params = {
                    "action_code": "lw4c62"
                       };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                   //     AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            dividendsFactory.getTransId = function(tax_year, val) {
                var params = {
                    "action_code": "tf5pes",
                    "tax_year": tax_year,                    
                    "search_string": val
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                    if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                       // AlertService.add("error", data.errorMessage);
                    } else {
                        if (data.jsonObject.length) {
                            return data.jsonObject;
                        }
                    }
                });
            };
            
            dividendsFactory.saveUpdateDividends = function(_header,_trans,lockObj){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_trans);
                var jsonSettings =  JSON.stringify(_header);

                var params = filterParams;
                if(lockObj && lockObj.modal_name == 'newDividendAdd'){
                    GlobalService.modalLockFilters(lockObj.lockingObj); /*  for locking Object */
                }
                
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "DIVIDENDS";

                var promise = $http({
                    method: "post",
                    url: dividendsFactory.url.saveUpdateDividends,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };

            dividendsFactory.deleteDividends = function(_data) {

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({}, _data, params);

                var promise = $http({
                    method: "post",
                    url: dividendsFactory.url.deleteDividends,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;
            };

            return dividendsFactory;
        }]);


    return services;


});