define([
        'angular'
    ], function () {
        'use strict';
        var app = angular.module('app.StatementTrackerControllers', []);
        app.controller('TrackerGrid10Ctrl', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'TAX_YEARS', 'workspaceFactory', 'SERVER_CONFIG', 'SystemLockUnlockFactory','$rootScope','$timeout','$scope','$state','$stateParams','$uibModal', trackerGrid10Ctrl])

        function trackerGrid10Ctrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, TAX_YEARS, workspaceFactory, SERVER_CONFIG, SystemLockUnlockFactory,$rootScope,$timeout,$scope,$state,$stateParams,$uibModal) {
            var vm = this;
            vm.webComponentHtml = ``;
            vm.clientKey = GlobalService.getClient().client_key;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
            vm.baseURLs.pdfEngine = GENERAL_CONFIG.pdf_engine_api_url;
            vm.baseURLs.bulkPdfEngine = GENERAL_CONFIG.bulk_pdf_engine_api_url;
            vm.baseURLs.efile = GENERAL_CONFIG.gtw_efile_api_url;
            vm.s3uploadButtonAccessUsers = SERVER_CONFIG.configSettings.dst_pdf_gen_users ? SERVER_CONFIG.configSettings.dst_pdf_gen_users.split(',') : [];
            vm.isChildEditing = false;
            vm.statementId = $stateParams.pId;
            vm.childStatementId = $stateParams.cId;
            vm.urlTaxYear = $stateParams.taxYear;
            //get system lock status
            SystemLockUnlockFactory.getDSTSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;
                    
                    vm.inputParam = {
                        clientKey: vm.clientKey,
                        baseURLs: vm.baseURLs,
                        ssoId: USER_SETTINGS.user.sso_id,
                        roleLevel: workspaceFactory.activeScreen.parent ? workspaceFactory.activeScreen.parent.access_level : undefined,
                        systemLockStatus: vm.isLocked,
                        taxYear: vm.urlTaxYear || GlobalService.globalParams.tax_year,
                        scenario: GlobalService.globalParams.scenario,
                        latestTaxYear: TAX_YEARS[0].value,
                        s3uploadButtonAccessUsers: vm.s3uploadButtonAccessUsers,
                        dataLossConfirmedType:null,
                        statementId:vm.statementId,
                        childStatementId:vm.childStatementId
                    };
                    const html = `<gtw-statement-tracker gtw-param="{{ctrl.inputParam}}" statement-tracker-events class="gtw-web-components"></gtw-statement-tracker>`;
                    vm.webComponentHtml = html; //$sce.trustAsHtml(html);

                    
                }
            );

            vm.eventCleanup = $rootScope.$on('show-data-loss-confirmation',(e,type)=>{
                let confirmation = openConfirmationPopup();
                confirmation.result.then(()=>{
                    triggerConfirmationChange(type);
                })
                
            });

            function openConfirmationPopup(){
                return $uibModal.open({
                    templateUrl: 'app/templates/modals/confirm-action.html',
                    windowClass: 'modal-warning',
                    controller: ['$scope', '$uibModalInstance',function($scope,$uibModalInstance) {                
                        $scope.header = "Confirm";
                        $scope.message = "Are you sure you exit? Doing so will delete the Statement created just now.";
                        $scope.confirm = function () {
                            
                            $uibModalInstance.close();
                        }
                        $scope.cancel = function () {
                            $uibModalInstance.dismiss();
                        }
                
                        $scope.$on('$destroy', function () {
                            ////////////console.log("ngReally Destroyed");
                        });
                    }]
                });
            }

            vm.eventCleanup2 = $rootScope.$on('is-child-statement-edit',(e,isChildEditing)=>{
                vm.isChildEditing = isChildEditing;
            })

            $scope.$on('$destroy', function () {
                vm.eventCleanup();
                vm.eventCleanup2();
            });

            $scope.$on('$stateChangeStart', 
            function(event, toState, toParams, fromState, fromParams){ 
                    if(vm.isChildEditing){
                        event.preventDefault(); 
                        let confirmation = openConfirmationPopup();
                        confirmation.result.then(()=>{
                            triggerConfirmationChange('close');
                            vm.isChildEditing = false;
                            $state.go(toState);
                        })

                    }
            })

            function triggerConfirmationChange(type) {
                vm.inputParam.dataLossConfirmedType = type;

                $timeout(() => vm.inputParam.dataLossConfirmedType = null, 500);
            }
        }



        return app;
    }
);
