define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.WorkflowController', []);

    controllers.controller('workflowController', ['$rootScope', '$scope', '$state', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'workspaceFactory', '$location',
        'GlobalService', 'WorkflowService', '$uibModal', workflowController
    ])
        .controller('EntityController', ['$timeout', 'TAX_YEARS', '$scope', '$state', '$parse', '$rootScope', 'AlertService', 'GlobalService', 'WorkflowService', 'workspaceFactory', '$location', 'USER_SETTINGS', entityController])
        .controller('workflowDetailsCtrl', ['$scope', '$state', 'workspaceFactory', 'GlobalService', 'WorkflowService','rowData', '$uibModal', '$templateCache', '$sce', workflowDetailsCtrl])
        .controller('EntityWorkflowController', ['$rootScope', '$scope', '$state', '$http', 'ModalFactory', 'AlertService', 'workspaceFactory', 'WorkflowService', '$uibModal', '$location', 'GlobalService', entityWorkflowController])
        .controller('EntityListCtrl', ['TAX_YEARS', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal',
            'workspaceFactory', 'GlobalService', 'WorkflowService', entityListCtrl
        ])
        .controller('WorkflowDetails', ['$scope', '$state', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'workspaceFactory', workflowDetails])
        .controller('SearchEntityController', ['$scope', 'AlertService', '$timeout', searchEntityController])
        // .controller('RetainedEarningsCtrl', [RetainedEarningsCtrl])
        .controller("workflowGridLink", ['$rootScope', '$scope', '$state', '$http', 'WorkflowService', workflowGridLink])
        .controller("DocWorkflowController", ['AlertService', '$rootScope', '$scope', '$state', '$http', 'WorkflowService', '$uibModal', DocWorkflowController])
        .controller("uploadDocCtrl", ['$uibModalInstance', 'seletedDoc','wholeData', uploadDocCtrl])
        .controller("EntityPDFPreviewCtrl", ['$scope','WorkflowService','$sce','AlertService','workspaceFactory', EntityPDFPreviewCtrl])



    function entityListCtrl(TAX_YEARS, $rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal,
        workspaceFactory, GlobalService, WorkflowService) {
        var vm = this;
        $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
        vm.name = "userListCtrl";
        vm.data = [];
        vm.api = $state.current.api.get;
        var custom_data_key = "AC1245678"
        vm.taxYears = [];
        vm.taxYears = TAX_YEARS;
        vm.scenairoList = [];
        var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
        vm.buzloding = false;
        $scope.filter ={}
        vm.filterObjs = {}


        /* get the scenario List based on tax year selection 6/17/2019 */

        vm.getScenarioList = function (year) {
            vm.scenairoList = _.find(TAX_YEARS, {
                value: year.value
            }).scenarios;
            vm.defScenario = _.filter(vm.scenairoList, {
                default_scenario: 'Y'
            });
            vm.wfScenario = vm.defScenario[0].scenario;
            vm.filingGroup();
            vm.getBizzHierarchyFilter();
        }
        vm.filingGroup = function(){
            var params ={
                tax_year: vm.taxYear.value,
                scenario: vm.wfScenario,
                jcd_key: GlobalService.globalParams.jcd_key==""? 250:GlobalService.globalParams.jcd_key
            }
            WorkflowService.getFilingGroup(params).then(function (data) {
                if (data && data.data.callSuccess == "1") {
                    vm.filingGroupList = data.data.jsonObject;
                    vm.filing_key = vm.filingGroupList[0].VALUE;
                }
            })

        }
        vm.getBizzHierarchyFilter = function(){
            var params ={
                tax_year: vm.taxYear.value,
                scenario: vm.wfScenario,
                jcd_key: GlobalService.globalParams.jcd_key==""? 250:GlobalService.globalParams.jcd_key
            }
            $scope.filter.loading = true;
            $scope.filter.selected = [];
            GlobalService.getBizHeirarch(params).then(function (data) {
                if (data.data.callSuccess == "1") {
                    vm.data = data.data.jsonObject;
                    $scope.filter.values = vm.data;
                    vm.filterObjs.loading = false;
                    $scope.filter.loading = false;

                    if(cachedData && cachedData.data){
                        vm.filterMatch = false;
                        cachedData.data.mevalues = [];
                        cachedData.data.meSeleted = [];
                        cachedData.data.parent_me_string = '';
                    }
                    WorkflowService.setWorkflowMes($scope.filter);// sending and storing an instance to service, which would be helpful in caching the filter object before entire screen load..
                    console.log('buziness filter',$scope.filter);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        if (cachedData && cachedData.data) {
            vm.filterObjs.loading = false;
            vm.taxYear = _.find(TAX_YEARS, {
                value: cachedData.data.params.tax_year
            })
            vm.wfScenario = cachedData.data.params.scenario;
            vm.filing_key = cachedData.data.params.filing_key;
            vm.scenairoList = _.find(TAX_YEARS, {
                value: vm.taxYear.label
            }).scenarios
            $scope.filter.values = cachedData.data.mevalues;
            vm.filingGroupList = cachedData.data.filingList;
            $scope.filter.selected = cachedData.data.meSeleted;
            vm.filterMatch = true;
        } else {
            vm.filterObjs.loading = false;
            vm.taxYear = GlobalService.inputs.tax_year.selected;
            vm.scenairoList = vm.taxYear.scenarios;
            vm.wfScenario = GlobalService.globalParams.scenario;

            vm.filterMatch = true;
            vm.filingGroup();
            vm.check = _.isEqual(GlobalService.global_selected_me.values, WorkflowService.workflowMes.values);
            // gets the filter object from a stored instance(workflow service) and will only call the bizz hirarchy call if no cached object in service level..
            // global me selection and worflow screen level me selections are now independent to each other.. 
            if(WorkflowService.workflowMes && WorkflowService.workflowMes.values.length > 0 && vm.check == true) {
                $scope.filter = WorkflowService.workflowMes;
            // } else if (vm.check == false){
            //     $scope.filter = GlobalService.global_selected_me;
            } else {
                vm.getBizzHierarchyFilter();
            }
        }

        vm.refresh = function () {
            getData(true);
        };

        var gridUpdateListener = $rootScope.$on('gridUpdate', function (event, args) {
            getUpDate(args);
        });

        $scope.$on('$destroy', function () {
            $log.log("Unregistering listener");
            gridUpdateListener();
        });

        var getUpDate = function (args) {
            $scope.$broadcast("showDataRefresh", true);
            var params = args;
            var _filterObj = {};
            GridFactory.getDataUpdate(vm.api, params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {
                        filter: _filterObj,
                        data: data.data
                    });
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });

        }


        var getData = function (refresh) {

            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);


            //TODO: Finish CUSTOME DATA STORE

            if (cachedData && cachedData.data && !refresh) {
                vm.data = cachedData.data.data;
                vm.filterMatch = true;
                $rootScope.$broadcast("collapseLeft", function (event, data) { });
                $scope.$broadcast("dataLoaded", vm.data);
                $scope.$broadcast("showLoader", false);
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            var parent_me_string = _.map($scope.filter.selected, function(item) {
                return item.parnet;
            }).join(',');

            if(parent_me_string!=""){
                $scope.$broadcast("showLoader", true);
                vm.filterMatch = true;
                var params = {
                    tax_year: vm.taxYear.value,
                    scenario: vm.wfScenario,
                    filing_key : vm.filing_key,
                    parent_me_string: parent_me_string,

                }

                GridFactory.getDataCustom($state.current.api.get, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        vm.data = data;
                        $rootScope.$broadcast("collapseLeft", function (event, data) { });
                        $scope.$broadcast("dataLoaded", data);
                        $scope.$broadcast("showLoader", false);

                        workspaceFactory.setCustomDataCache(custom_data_key, {
                            params: params,
                            filingList : vm.filingGroupList,
                            mevalues: $scope.filter.values,
                            meSeleted : $scope.filter.selected,
                            data: vm.data
                        });
                    } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                    }
                });
            }else{
                AlertService.add("warning","Please select the ME(s) filter",4000);
            }
        }


        $timeout(function () {
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            if (cachedData && cachedData.data) {
                getData(false);
            }
        }, 5);

        /* pencil icon function */
        $scope.ctrlFn = function (_callName, _data, _type) {

            console.log("_callName ", _callName)
            console.log("_data ", _data.rowData)
            console.log("_type ", _type);
            _data.params = {
                tax_year: vm.taxYear.value,
                scenario: vm.wfScenario
            }

            $rootScope.$broadcast("workflow.addTab", _data);
            $rootScope.$broadcast("workflow.entity", _data);

        };

        //FOR ADD USER
        $scope.openModal = function (modalObj, _data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });


        };

        /* workflow entity mapping */
        vm.restult = [];

        $scope.$watch('filter.selected', function (newVal, oldVal) {
            vm.model = newVal
            console.log(" vm.model", vm.model);
            console.log("$scope.filter", $scope.filter);
            if(!_.isEqual(newVal,oldVal)){
                vm.filterMatch = false;
            }
        }, true);


    }

    function entityController($timeout, TAX_YEARS, $scope, $state, $parse, $rootScope, AlertService, GlobalService, WorkflowService, workspaceFactory, $location, USER_SETTINGS) {

        var vm = this;
        vm.show_summery = true;

        var id = $state.params.id;

        if (id.length > 0) {
            // storing the params id in service level, which is used to fetch the cached data on route change(when landing from different route).
            WorkflowService.settingId(id);
        }

        vm.workflow = {};
        vm.workflow.details = {};
        vm.workflow.steps = "";
        $scope.steps = [];
        var custom_data_key = "WFE324522";
        var entityData = [];
        var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
        var rowData = {};
        vm.percentile = 0;
        var screenOriginalSize = {};
        var entityData;
        vm.params = _.find(cachedData.data, function (item) {
            return item.code == WorkflowService.selectedId;
        })

        if ($state.params.catKey || $state.params.compKey || vm.params) {
            var catKey = $state.params.catKey;
            var compKey = $state.params.compKey;
            var taxYear = $state.params.taxYear || vm.params.data.rowData.TAX_YEAR;
            var scenario = $state.params.scenario || vm.params.data.rowData.SCENARIO;
        }
        /*  vm.taxYear = taxYear;
         vm.sceList = _.find(TAX_YEARS,{value:taxYear}).scenarios;
         vm.scenario = _.filter(vm.sceList,{scenario:scenario});
         vm.scenairo_name = vm.scenario[0].scenario_desc; */
        vm.taxYear = '';
        vm.scenario_name = '';

        vm.expandToWindow = function () {
            var element = $('#entity-tpl');
            if ($(element).width() < $('body').width()) {
                screenOriginalSize.originalPosition = $(element).offset();
                screenOriginalSize.originalWidth = $(element).width();
                screenOriginalSize.originalHeight = $(element[0]).outerHeight();
                $(element).find('.footer').css({
                    left: 0
                });
                $(element).css({
                    position: 'fixed',
                    'z-index': 1000,
                    left: screenOriginalSize.originalPosition.left,
                    top: screenOriginalSize.originalPosition.top - $(window).scrollTop(),
                    width: screenOriginalSize.originalWidth,
                    height: screenOriginalSize.originalHeight,
                    'overflow-y': 'auto'
                }).addClass('expanded');
                $(element).animate({
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0
                }, 500);
            } else {
                $(element).find('.footer').removeAttr('style');
                $(element).animate({
                    position: 'fixed',
                    height: screenOriginalSize.originalHeight,
                    left: screenOriginalSize.originalPosition.left,
                    top: screenOriginalSize.originalPosition.top - $(window).scrollTop()
                }, 500, function () {
                    $(element).removeAttr('style').removeClass('expanded');
                });
            }
        }

        /* check the whether the tab is already is created or not */
        vm.checkCreateTab = function () {
            if ($location.path().split('/').length > 2) {
                if (cachedData) {
                    // data - entity data
                    // WFS_DATA - workflow data
                    if (cachedData && cachedData.data) {
                        entityData = _.find(cachedData.data, function (item) {
                            return item.code == WorkflowService.selectedId;
                        });
                        // entity data
                        if (entityData) {
                            rowData = entityData.data.rowData;
                            vm.workflow.details = rowData;
                            if (!rowData.WFS_DATA) { // entity present & workflow not present, call API to get JSON.
                                getWorkflowByEntity(rowData, true);
                            } else if (rowData.WFS_DATA) { // both entity present & workflow present, use existing cache JSON.
                                $scope.steps = rowData.WFS_DATA;
                                vm.processWFSJSON();
                            }
                        }
                    }
                }
                var COMBINATION_KEY = $location.path().split('/')[3];
                // check already opened
                if (_.find($scope.$parent.ctrl.tabs, function (item) {
                    return item.code == COMBINATION_KEY
                })) {
                    return;
                }
                if(($scope.$parent.ctrl.tabs.length > 0 && COMBINATION_KEY.length > 0) || $state.params.id.length > 0) {
                var params = {
                    taxYear: ((entityData && entityData.data) || (entityData == "undefined")) ? entityData.data.params.tax_year : $location.path().split('/')[7],//GlobalService.globalParams.tax_year ,
                    scenario: ((entityData && entityData.data) || (entityData == "undefined")) ? entityData.data.params.scenario: $location.path().split('/')[8], //GlobalService.globalParams.scenario,
                    combinationKey: COMBINATION_KEY
                }
                WorkflowService.getEntityByID(params).then(function (data) {
                    if (data.data.callSuccess === "1") {
                        var entityData = [];
                        _.each(data.data.entityList, function (item) {
                            var keys = Object.keys(item);
                            var newItem = {};
                            for (var i = 0; i < keys.length; i++) {
                                newItem[keys[i].toUpperCase()] = item[keys[i]];
                            }
                            entityData.push(newItem);
                        });
                        rowData = entityData[0];
                        $rootScope.$broadcast("workflow.addTab", {
                            rowData
                        });
                        $rootScope.$broadcast("workflow.entity", {
                            rowData
                        });
                        if(rowData) {
                            $rootScope.$broadcast("collapseLeft", function (event, data) { });
                            getWorkflowByEntity(rowData, true);
                        } else {
                            $state.transitionTo('workflow.list');
                        }
                    } else {
                        AlertService.add("error", "error getting records");
                    }
                });
            }
            }
        }

        // $scope.$on("workflow.entity", function (event, data) {
        //     vm.workflow.details = data;
        //     console.log("details", vm.workflow.details);
        //     $scope.$apply(function () {
        //         vm.workflow.details = data;
        //     });
        // });
        vm.showDetails = null;

        vm.processWFSJSON = function () {
            $scope.list = [];
            var uniqChildren = _.uniq(_.map($scope.steps, 'cat_Name'));
            $scope.list = _.map(uniqChildren, function (childCatName) {
                var children = _.filter($scope.steps, {
                    cat_Name: childCatName
                });
                _.each(children, function (item) {
                    item.compKey = $parse('comp_Wfscomp_Key')(item) || '';
                });
                return {
                    title: childCatName,
                    children: children,
                    isNotCompleted: _.some(children, function (item) {
                        return item.comp_status_key !== 'C'
                    }),
                    catKey: $parse('cat_Wfscat_Key')(children[0]) || '',
                };
            });

            if ($state.params.catKey) {
                var selectedCat = _.find($scope.list, {
                    catKey: $state.params.catKey
                });
                if (selectedCat) {
                    selectedCat.isOpen = true;
                }
            }
            $rootScope.$broadcast("workflowsteps", $scope.list);
            console.log($scope.list);

            /* check the percentile to complete status  5/3/2019*/
            if ($scope.list) {
                var count = _.filter($scope.steps, {
                    'comp_status_key': 'C'
                });
                vm.percentile = Math.round((count.length * 100) / $scope.steps.length) || 0;

            }

        };


        /* get the workflow based on tax_type */
        function getWorkflowByEntity(rowData, isReloadState) {
            var requestPayLoad = {
                clientKey: USER_SETTINGS.user.client_key,
                taxYear: rowData.TAX_YEAR,
                //  scenario: params.data.params.tax_year,
                scenario: rowData.SCENARIO || params.data.params.scenario,
                taxType: rowData.LE_TAX_TYPE,
                rpt: rowData.REPORTING_PERIOD,
                combinationKey: rowData.COMBINATION_KEY,
                parentMeKey: rowData.PARENT_ME_KEY,
                sourceSytemFlag: rowData.SOURCE_SYSTEM_FLAG,
            }
            WorkflowService.getWFEntityMapping(requestPayLoad).then(function (data) {
                if (data.data.callSuccess && data.data.callSuccess == "1") {
                    if(data.data.workFlowList.length > 0) {
                        $scope.steps = data.data.workFlowList;
                    } else {
                        AlertService.add("warning", "No workflow List assigned to this entity, please select different Entity...");
                    }

                    rowData.WFS_DATA = _.cloneDeep($scope.steps);
                    rowData.lastParams = _.cloneDeep($state.params);
                    vm.processWFSJSON();

                    if (isReloadState) {
                        $scope.$parent.$parent.ctrl.$state.reload();
                    }
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                }
            });
        }

        $scope.onSelectComp = function (comp) {
            $scope.selectedComp = comp;
        };


        vm.toggleSummery = function () {
            vm.show_summery = !vm.show_summery;
            if (!vm.show_summery) {
                window.scrollTo(0, 20)
            }
        };
        /* workflow List html toggle */
        // vm.toggleState = function (event) {
        //     if ($state.$current.name === 'workflow.entity.workflow' && $state.params.listview === 'true') {
        //         event.preventDefault();
        //         $state.go('workflow.entity.workflow', {
        //             listview: 'false',
        //         });
        //     }
        // }

        var tempTitle;
        vm.setCurrentState = function (title) {
            tempTitle = title;
        };

        /* moving tabs  */
        $scope.$watch(function () {
            return $state.$current.name;
        }, function () {
            $timeout(function () {
                vm.currentStateTitle = tempTitle;
                var movingTab = document.querySelector('.app-nav.wizard-tabs .wizard-tab-hilighter');
                var menu = document.querySelectorAll('.app-nav.wizard-tabs li');
                var selectedTab = null;
                menu.forEach(function (item) {
                    if (item.innerText === vm.currentStateTitle) {
                        selectedTab = item
                    };
                });
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            });
        });

        vm.setCurrentState('Workflow');
        vm.checkCreateTab();


        if (catKey && compKey) {
            $state.transitionTo('workflow.entity.workflow.details', {
                'id': id,
                'catKey': catKey,
                'compKey': compKey,
                'taxYear': taxYear,//2017,
                'scenario': scenario
            });
        } else if (rowData.lastParams && rowData.lastParams.catKey && rowData.lastParams.compKey) {
            $state.transitionTo('workflow.entity.workflow.details', {
                'id': id,
                'catKey': rowData.lastParams.catKey,
                'compKey': rowData.lastParams.compKey,
                'taxYear': rowData.lastParams.taxYear,//2017,
                'scenario': rowData.lastParams.scenario,

            });
        } else {
            $state.transitionTo('workflow.entity.workflow', {
                'id': id,
                'taxYear': taxYear,//2017
                'scenario': scenario
            });
        }


        // Added more checks to avoid undefined errors..
        vm.taxYear = $location.path().split('/')[7] || (rowData && rowData.lastParams && rowData.lastParams.taxYear) ? (rowData.lastParams?rowData.lastParams.taxYear: '') : JSON.stringify(vm.params ? vm.params.data.rowData.TAX_YEAR: '') || '';//GlobalService.globalParams.tax_year ,
        vm.scenario = $location.path().split('/')[8] || (rowData && rowData.lastParams && rowData.lastParams.scenario) ? (rowData.lastParams?rowData.lastParams.scenario: '') : JSON.stringify(vm.params?vm.params.data.rowData.SCENARIO: '') || '';
        var taxYearObjFilter = _.find(TAX_YEARS, { value: vm.taxYear });
        vm.sceList = taxYearObjFilter ? taxYearObjFilter.scenarios: '';
        vm.scenairo_name = vm.sceList? _.find(vm.sceList,{scenario:vm.scenario}).scenario_desc: '';//_.filter(vm.sceList,{})//




        //setting current route in service level in workspace service.
        var routes = {};
        routes.prevoiusDashboard = 'workflow.entity.workflow.details';
        workspaceFactory.setRoutes(routes);





    }

    /* workflow detailed controller to  get the componentKey and catKey*/
    function workflowDetailsCtrl($scope, $state, workspaceFactory, GlobalService, WorkflowService, rowData, $uibModal, $templateCache, $sce) {
        var custom_data_key = "WFE324522";
        var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
        var cheData = _.find(cachedData.data, function (item) {
            return item.code == WorkflowService.selectedId;
        });
        if (cheData) {
            var rowData = cheData.data.rowData;
            var vm = this;
            vm.workFlowList = [];
            vm.workFlowList = $scope.$parent.$parent.$parent.list;
            $scope.selectedCat = _.find(vm.workFlowList, {
                catKey: $state.params.catKey
            });
            $scope.selectedComp = _.find($scope.selectedCat.children, {
                compKey: $state.params.compKey
            });
            $scope.rowData = rowData;

        }
    }

    function entityWorkflowController($rootScope, $scope, $state, $http, ModalFactory, AlertService, workspaceFactory, WorkflowService, $uibModal, $location, GlobalService) {
        var vm = this;
        vm.workflowSteps = [];
        $scope.$parent.$watch('list', function (value) {
            vm.loading = true;
            vm.workflowSteps = value;
            if(vm.workflowSteps && vm.workflowSteps.length > 0) {
                vm.loading = false;
            }
        });

        // this two statements used to add css effect based on selected link name and exisitng parmas id stored in workflow service level.
        vm.addEffect = workspaceFactory.compTitle;
        vm.checkStateId = (workspaceFactory.stateId == WorkflowService.selectedId);
        var cheData ;
        /* changing the state from one step to another */
        $scope.changeState = function (params) {
            vm.showDetails = null;
            if (params && params.compKey == undefined || params.compKey == "") {
                if ($scope.steps.length > 0) {
                    params.compKey = $scope.steps[0].comp_Wfscomp_Key;
                    params.catKey = $scope.steps[0].cat_Wfscat_Key;
                    params.taxYear = $scope.steps[0].tax_year;
                    params.scenario = $scope.steps[0].scenario;

                }
            }
            var custom_data_key = "WFE324522";
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            cheData = _.find(cachedData.data, function (item) {
                return item.code == WorkflowService.selectedId;
            });
            if (cheData) {
                var rowData = cheData.data.rowData;
                rowData.lastParams = params;
                rowData.lastParams.id = WorkflowService.selectedId;
            }
            $scope.currentView = params;
            var action_type = _.find($scope.steps, {
                comp_Wfscomp_Key: params.compKey
            });

            /* check step type whether step is link type or modal type */

            if (action_type && action_type.comp_Wf_Action_type == 'modal') {
                $state.go('workflow.entity.workflow.details', params, {
                    rowData: $scope.$parent.$parent.ctrl.workflow.details
                });
            } else if (action_type && action_type.comp_Wf_Action_type == 'link') {

                rowData.comp_name = action_type.comp_Name
                $state.go('workflow.entity.workflow.details', params, {
                    rowData: $scope.$parent.$parent.ctrl.workflow.details
                });

                $scope.$on('workflowGrid', function (event, data) {
                    workspaceFactory.loadLink("workflow", null, action_type.activity_key, action_type.screen_key, [], {}, null);
                })
            }
        };



        vm.redirectToGrid = function (action_type, event) {
            // if (action_type && action_type.comp_Wf_Action_type == 'link') {
                // rowData.comp_name = action_type.comp_Name
                //var drilldownFilters = [{"filter_key":"1","name":"tax_year","row_data_key":""},{"filter_key":"15","name":"chart_filter","row_data_key":""}];s
                var gridFilters = {
                    gridFilters:[],
                    drillDownRefresh:0,
                    filterObj:{},
                    screenFilter:[]
                };
                var drilldownFilters = action_type.filters ? JSON.parse(action_type.filters): '';
                var position = event.pageY;
                workspaceFactory.setPositionY(position);
                workspaceFactory.setCompName(action_type.comp_Name);
                workspaceFactory.setParamsId($state.params.id);

                if (drilldownFilters && drilldownFilters.filters && drilldownFilters.filters.length) {
                    _.forEach(drilldownFilters.filters, function (value, key) {
                        var _value = cheData.data.rowData[value];
                        if (_value && (_value.length || _.isNumber(_value))) {
                            var obj = {};
                            obj.predicate = value;
                            obj.value = [];
                            obj.type = "array";
                            obj.reverse = false;
                            obj.operator = "[]";
                            obj.value.push(_value);
                            gridFilters.gridFilters.push(obj);
                        }
                    });
                  //  gridFilters.drillDownRefresh = cheData.data.colData.drilldown_filter_refresh == null ? gridFilters.drillDownRefresh : cheData.data.colData.drilldown_filter_refresh
                }
                var gridFilterObj = [];
                var screenFilters = function(screenFilters){
                    _.forEach(screenFilters, function (value, key) {
                        var _value = cheData.data.rowData[value.row_data_key];
                        if (_value && (_value.length || _.isNumber(_value))) {
                            var obj = {};
                           // obj.predicate = value.name;
                            obj.value = [];
                            obj.type = "array";
                            obj.reverse = false;
                            obj.operator = "[]";
                            obj.value.push(_value);
                            if(value.row_data_key=='TAX_YEAR'||value.row_data_key == 'ME_KEY'){
                                value.value = _value+'';
                            }else if(value.row_data_key == 'CONSOL_GROUP_KEY'){
                                value.value = Number(_value);
                            }else{
                                value.value = _value;
                            }
                            gridFilters.screenFilter.push(obj);
                        }
                    });
                        gridFilters.screenFilter.forEach(item=>{
                        gridFilterObj.push({name:item.predicate,value:item.value.join('')});
                    });
                }
                if(drilldownFilters && drilldownFilters.activity_key &&  drilldownFilters.screen_key){
                    if(drilldownFilters.screenFilters && drilldownFilters.screenFilters.length){
                        screenFilters(drilldownFilters.screenFilters)
                    }
                    workspaceFactory.loadLink("workflow", null, drilldownFilters.activity_key, drilldownFilters.screen_key, drilldownFilters.screenFilters, gridFilters.gridFilters, null, drilldownFilters.action_template_ley);
                }else{
                    AlertService.add("error","Link Doest have activity and screen",4000);
                }

        // }
        }

        if ($state.params && $state.params.compKey == undefined || $state.params.compKey == "") {
            $scope.changeState($state.params);
        }
        /* preivous next buttons starts here */
        vm.onPrevNext = function (nextIndex) {
            if ($scope.currentView && $scope.list) {
                const parent = $scope.list.find(item => item.catKey === $scope.currentView.catKey)
                if (parent && parent.children) {
                    const childIndex = parent.children.findIndex((item) => item.compKey === $scope.currentView.compKey);
                    const index = childIndex + nextIndex;
                    if (parent.children[index]) {
                        $scope.changeState({
                            compKey: parent.children[index].compKey,
                            catKey: parent.catKey
                        });
                    } else if (index === -1) {
                        var parentIndex = $scope.list.indexOf(parent);
                        var prevParent = $scope.list[parentIndex - 1];
                        if (prevParent) {
                            $scope.changeState({
                                compKey: prevParent.children[prevParent.children.length - 1].compKey,
                                catKey: prevParent.catKey
                            });
                        }
                    } else if (parent.children.length === index) {
                        var parentIndex = $scope.list.indexOf(parent);
                        var nextParent = $scope.list[parentIndex + 1];
                        if (nextParent) {
                            $scope.changeState({
                                compKey: nextParent.children[0].compKey,
                                catKey: nextParent.catKey
                            });
                        }
                    }
                }
            }
        };

        vm.isFirstChild = function () {
            var firstChild = false;
            if ($scope.currentView && $scope.list) {
                const parentIndex = $scope.list.findIndex(item => item.catKey === $scope.currentView.catKey)
                if (parentIndex === 0) {
                    if ($scope.list[parentIndex]) {
                        var children = $scope.list[parentIndex].children;
                        const childIndex = children.findIndex((item) => item.compKey === $scope.currentView.compKey);
                        firstChild = childIndex === 0;
                    }
                } else {
                    firstChild = false;
                }
            }
            return firstChild;
        };

        vm.isLastChild = function () {
            var lastChild = false;
            if ($scope.currentView && $scope.list) {
                const parentIndex = $scope.list.findIndex(item => item.catKey === $scope.currentView.catKey)
                if (parentIndex === $scope.list.length - 1) {
                    if ($scope.list[parentIndex]) {
                        var children = $scope.list[parentIndex].children;
                        const childIndex = children.findIndex((item) => item.compKey === $scope.currentView.compKey);
                        lastChild = childIndex === children.length - 1;
                    }
                } else {
                    lastChild = false;
                }
            }
            return lastChild;
        }
        /* ends here */

        /* broadcasting common save for all the workflow steps */
        vm.workflowsave = function () {
            $rootScope.$broadcast('workflowSave', {
                "call": true
            });
        }
        if ($state.params.compKey) {
            $scope.changeState($state.params);
        }
    }

    /* if step type has link then below controller will load */
    function workflowGridLink($rootScope, $scope, $state, $http, WorkflowService) {
        var vm = this;
        vm.data = $scope.$parent.rowData;
        var leId = vm.data.HO_LEID;
        var cdrNo = vm.data.HO_CDR_NO;
        var rptPeriod = vm.data.HO_REPORTING_PERIOD;
        var taxYear = vm.data.TAX_YEAR;
        var leType = vm.data.LE_TYPE;
        var systemFlag = vm.data.SOURCE_SYSTEM_FLAG;
        vm.openPdf = function() {
            WorkflowService.openPdfPopup('8858',leId,cdrNo,rptPeriod,taxYear,leType,systemFlag);
        }
        console.log("vm.rowdata grid", vm.data);
        $scope.gridLink = function () {
            $rootScope.$broadcast('workflowGrid', function (event, data) { });
        }
    }

    function workflowController($rootScope, $scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, workspaceFactory, $location, GlobalService, WorkflowService, $uibModal) {
        var vm = this;
        vm.name = "Sample Application";
        vm.$state = $state;
        var custom_data_key = "WFE324522"
        var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

        var entityArray = [];
        console.log("cachedData ================================", cachedData);
        console.log("$state ================================", $state);
        console.log("workspaceFactory ================================", workspaceFactory);
        vm.tabs = [];
        /* check the cached Data has tablist of not */
        if (cachedData) {
            vm.tabs = cachedData.data;
        } else {

            workspaceFactory.setCustomDataCache(custom_data_key, vm.tabs);

        }

        /*  vm.includes = function (_link) {
             if (vm.$state.current.name.indexOf(_link) >= 0) {
                 return true;
             } else {
                 return false;
             }
         } */
        $scope.$on("workflow.addTab", function (event, data) {
                vm.addTab(data);
        });
        /* create Tab */
        vm.addTab = function (data) {
            if(data.rowData) {
            var tooltip = data.rowData.LE_NAME + " - ME:  " + data.rowData.ME_NAME + " ( " + data.rowData.ME_CODE + " )";

            if (_.find(vm.tabs, function (item) {
                return item.code == data.rowData.COMBINATION_KEY
            })) {
                AlertService.add("warn", "Entity is all ready open ", 1000);
            } else {
                var entity = {
                    name: data.rowData.LEID,
                    code: data.rowData.COMBINATION_KEY,
                    data: data,
                    tooltip: tooltip
                }
                var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
                if (cachedData) {
                    cachedData.data.push(entity);
                } else {
                    //  var entityArray = [];
                    entityArray.push(entity);
                    workspaceFactory.setCustomDataCache(custom_data_key, entityArray);
                }

            }
            setTimeout(function () {
                $scope.$apply();
            }, 500);

            $rootScope.$broadcast("collapseLeft", function (event, data) { });
        }
    }

        // used to clear data to avoid styling conflicts on workflow tab selections.
        vm.workflowTab = function(tabData) {
            if(tabData) {
                workspaceFactory.setPositionY(0);
                workspaceFactory.setCompName("");
                workspaceFactory.setParamsId("");
            }
        }

        vm.cancel = function (tab) {
            var index = _.findIndex(vm.tabs, tab);
            vm.tabs.splice(index, 1);

            // redirecting to workflow list page if no tabs
            if(vm.tabs.length == 0) {
                setTimeout(function() {
                    $state.transitionTo('workflow.list');
                });
            }

            /**
             * deleting the tab from cached tab list, which is no need because the tabs list has the same object as cached data list(causing double deletion).
             * so commenting out the code...
             * * */

            // event.stopPropogation();
            // var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            // if (cachedData && cachedData.data) {
            //     cachedData.data.splice(index, 1);
            // }
        }

        $scope.getClasses = function (tab) {
            console.log("tab : ", tab);
            return 'wf-levelA';
        }


        vm.removeTab = function (tab, $index) {
            console.log("tab : ", tab);
            console.log("$index : ", $index);
        };

    }

    function workflowDetails($scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, workspaceFactory) {
        var vm = this;

        vm.$state = $state;
        var custom_data_key = "WFE324522"
        var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

        vm.show_tax_details = true;
        vm.show_branches = true;
        vm.show_financial = true;
        vm.show_ownership = true;


        vm.toggleTax = function () {
            vm.show_tax_details = !vm.show_tax_details;
        }

        vm.toggleBranch = function () {
            vm.show_branches = !vm.show_branches;
        }

        vm.toggleFinancial = function () {
            vm.show_financial = !vm.show_financial;
        }

        vm.toggleOwnership = function () {
            vm.show_ownership = !vm.show_ownership;
        }


    }

    function searchEntityController($scope, AlertService, $timeout) {
        var vm = this;
        //vm.addEntity=[];



        vm.openEntity = function (item) {

            console.log("ksdjfljkasdfjksdhhjsddfsh", item)
        }
        vm.getEntity = function (entityList) {

            $scope.entityList = []
            return $scope.entityList;
        }
        //     vm.addEntity = function (entity) {
        //         entity.entity_name =  entity.entity_name;
        //         entity.entity_name = true;
        // }


        $scope.searchCriteriaTo = {
            parentBSLAList: [],
            bslaBusinessList: [],
            usTaxTypeList: [],
            countryOfIncorporation: [],
            homeOfficeLE: [],
            homeOfficeCDR: []

        };

        $scope.searchCriteriaInputTO = {
            parentBSLAList: [],
            bslaBusinessList: [],

            usTaxTypeList: [],
            countryOfIncorporation: [],
            homeOfficeLE: [],
            homeOfficeCDR: []
        };

        $scope.updateSearchDropdownTO = function (name, value) {

            $scope.searchCriteriaTo[name].push(value);
        }

        $scope.removeSearchDropdownTO = function (name, index) {
            $scope.searchCriteriaTo[name].splice(index, 1);
        }


    }

    function uploadDocCtrl($uibModalInstance, seletedDoc, wholeData) {
        var vm = this;
        vm.seletedDoc = seletedDoc;
        vm.wholeData = wholeData;
        vm.title = "Upload New Document";

        vm.save = function () {
            vm.wholeData.unshift({
                doc_file: "fed",
                doc_title: vm.seletedDoc.doc_title,
                entity: "test",
                sup_type: vm.seletedDoc.sup_type,
                created: "test",
                updated: "test"
            });
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
    }

    function DocWorkflowController(AlertService, $rootScope, $scope, $state, $http, WorkflowService, $uibModal) {
        var vm = this;
        vm.text = "";
        vm.documentData = [];

        vm.docMessageData = [];

        vm.uploadDoc = function (doc) {
            vm.seletedDoc = doc;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/workflow/templates/entity/upload-document.html',
                controller: 'uploadDocCtrl as ctrl',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    seletedDoc: function () {
                        return vm.seletedDoc;
                    },
                    wholeData: function () {
                        return vm.documentData;
                    },
                }
            });
        };

        vm.reply = function () {
            vm.docMessageData.unshift({ comment: vm.text, short_name: "HS", name: "Heath J stein", time: "3 hours ago..." });
            vm.text = "";
        }

        vm.delete = function (index) {
            vm.documentData.splice(index, 1);
            AlertService.add("success", "Record deleted Successfully ", 4000);
        }

    }

    function EntityPDFPreviewCtrl($scope,WorkflowService,$sce,AlertService,workspaceFactory) {
        var vm = this;
        vm.leId = $scope.$parent.ctrl.workflow.details.LEID;
        vm.formName = '1120';
        vm.taxYear = $scope.$parent.ctrl.workflow.details.TAX_YEAR;
       vm.cdrNo = $scope.$parent.ctrl.workflow.details.CDR_NO
       vm.rptPeriod = $scope.$parent.ctrl.workflow.details.REPORTING_PERIOD
        vm.leType = $scope.$parent.ctrl.workflow.details.LE_TYPE
        vm.systemflag = $scope.$parent.ctrl.workflow.details.SOURCE_SYSTEM_FLAG
    }

    return controllers;
});
