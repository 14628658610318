define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.IC-DiagCtrl', [])
    .controller('ICDiagController', ['$uibModal','$uibModalInstance','$scope','$rootScope','rowData','USER_SETTINGS','GlobalService','AlertService','ModalFactory','ICDiagFactory',ICDiagController])
			
	function ICDiagController($uibModal, $uibModalInstance, $scope, $rootScope, rowData, USER_SETTINGS, GlobalService, AlertService, ModalFactory, ICDiagFactory) {
    	
    	var vm = this;
    	vm.cancel = function(){
    		if(vm.crudLoading){
				AlertService.add("info", "The transaction is still in progress", 4000);
			}
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.rowData = rowData;
        console.log(vm.rowData);
        console.log(ModalFactory);
        vm.modalCalled = ModalFactory.response.data.modalScreen.modal_name;
        vm.userSettings = USER_SETTINGS;
        console.log(vm.userSettings);
		vm.logged_in_user = vm.userSettings.user.sso_id;
		vm.displayName = vm.userSettings.user.display_name;
		vm.entitySelected = [];
		vm.col_id = '';
		vm.cpFormData = [];
		vm.cpFormOriginalData = [];
		vm.cpFormFinalData = [];
		vm.finalSaveData = [];
		vm.clob_settings = [];
		
		vm.entityTbAmt = 0;
		vm.allocate_pending_amt = 0;
		vm.pending_amt = 0;
		vm.variance = 0;

		vm.editMode = false;
		vm.editMe = false;
		
		vm.icoAccountList = [];
		vm.selectedLine_no = '';
		vm.entityDetails = [];
		vm.selectedEntity = '';
		vm.crudLoading = false;
		
		vm.searchBy = 'L';
        vm.applicable_year = vm.rowData.TAX_YEAR;
        vm.payorDetails = [];
        vm.componentDetails = [];
        vm.componentSelected = [];     
        vm.currentLine = vm.rowData.LINE_NO;
        vm.edit_record_index = null;
        vm.edit_record_parentindex = null;
        vm.edit_record_occurrence = null;
        vm.dummyApprovedRecord = {
        		"ROW_STATUS": 	"A",
        		"ROW_TYPE":		"CH",
        		"ROW_SOURCE":	"R",
        		"DELETE_ROWS":	[],
        		"Line_data":	[]
        };        
        
        vm.combination_key = 0;
       	vm.selectedBSLA  = '';
       	vm.selectedMECode = '';
        vm.allocated_amount = 0;
        vm.total_net_amt = 0;
        vm.total_amt_allocated = 0;
  	   	vm.cur_variance = 0;
  	   	vm.total_allocated_amt = 0;
		vm.variance = 0;

        vm.form_name = 'IC_DIAG';
  	   	vm.schedule_name = 'IC_OUTSIDE_BU';
		vm.entitySelected = vm.rowData;		
		
		getAllVariableDetails();
        
        function getEntityDetails(){
        	ICDiagFactory.getEntityDetails(vm.logged_in_user).then(function (data){
				console.log("Here are the list of Entities available for the user:    ::::", data);
				vm.entityDetails = data.jsonObject;
			});
        }
        
        vm.afterEntitySelected = function(){
        	console.log("The Entity selected for Counter party addition was  ::::-------------", vm.selectedEntity);
        	var selectedEntityIndex = vm.entityDetails.findIndex(x => x.ENTITY_DETAILS == vm.selectedEntity);
        	vm.entitySelected = vm.entityDetails[selectedEntityIndex];
        	vm.selectedSource = '';
        	vm.line_no_enable = false;
        	vm.selectedColumn = '';
        	resetPageUI();
        }
        
        function getAllVariableDetails(){
       	
        	vm.combination_key = vm.rowData.COMBINATION_KEY;
        	
        	console.log("In getAllVariableDetails fn");
        	
        	getComponentDetails();
        	getICOAcctData();   	
        	     	
        }
        
        function getICOAcctData(){    
           console.log("In getICOAcctData fn");    	   
      	   ICDiagFactory.getICOutsideAcctDetails(vm.combination_key, vm.logged_in_user).then(function (data){
      		   console.log("Inside getICOAcctData ::::::::::", data);
      		   console.log("getICOAcctData json:::", data.jsonObject);
      		   vm.icoAccountList = data.jsonObject;
      		   getTotalNetAmt();
      		   getCPFormData();
      	   });      	   
         }
         
        function getTotalNetAmt(){          
          console.log("vm.icoAccountList:::::", vm.icoAccountList);
      	   var total_net_index = vm.icoAccountList.findIndex(x => x.ROW_TYPE == "TOTAL_ROW");
      	   console.log("total_net_index::::", total_net_index);
      	   if(total_net_index != -1){	      	   
	      	   var total_component = vm.icoAccountList[total_net_index];    
	      	   console.log("total_component::::", total_component);  	   
	      	   vm.total_net_amt = total_component.NET_AMT;
	      	   console.log("total_net_amt::::", vm.total_net_amt);  
	      	}
        }
        
        function getComponentDetails(){   
        	console.log("In getComponentDetails fn");     	
        	ICDiagFactory.getBSLADetails(vm.logged_in_user, vm.combination_key).then(function (data){
				console.log("Here are the list of Components/BSLAs available for this line:    ::::", data);
				vm.componentDetails = data.jsonObject;
				console.log("BSLA componentDetails json:::", vm.componentDetails);
			});
        }
        
        vm.afterBSLASelected = function(){
        	var selectedbslaIndex = vm.componentDetails.findIndex(x => x.BSLA_DETAILS == vm.selectedBSLA);
        	vm.componentSelected = vm.componentDetails[selectedbslaIndex];
        	console.log("After selecting Component the json of BSLA is ::::::::::::", vm.componentSelected);
        	console.log("ME key of selected BSLA is ::::::::::::", vm.componentSelected.ME_KEY);
        }
        
        function getCPFormData(){
        	console.log("In getCPFormData fn"); 
        	ICDiagFactory.getCPFormDetails(vm.combination_key, vm.form_name, vm.schedule_name, vm.logged_in_user).then(function (data){
        		console.log("After the CPFORM pull the data was ::::::::::", data);
				vm.cpList = data.jsonObject;
				
				var o = vm.cpList.reduce( (a,b) => {
					a[b.GROUP_ORDER] = a[b.GROUP_ORDER] || [];
					a[b.GROUP_ORDER].push(b);
					return a;
				}, {});
				
				var a = Object.keys(o).map(function(k){
					return {ROW_STATUS: o[k][0].PAYOR_STATUS, ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
				});
				vm.cpFormData = angular.copy(a);
				console.log(vm.cpFormData);
				
				var c = [];
				for (var i=0; i < vm.cpFormData.length; i++){
					if(vm.cpFormData[i].ROW_TYPE == 'CH'){
						
						if(vm.cpFormData[i].Line_data[0].OCCURRENCE != null){
							var z = vm.cpFormData[i].Line_data.reduce( (a,b) => {
								a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
								a[b.OCCURRENCE].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Lock_status: z[k][0].LOCK_STATUS, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Occurrence: z[k][0].OCCURRENCE, Line_data_occ: z[k]};
							});						
							
							
							console.log("At this point of the value of y is -------::::::", y);
							console.log("y.length-------::::::", y.length);
							
							for (var n=0; n < y.length; n++){
							
								console.log("n-------::::::", n);
								console.log("yn Line_Type-------::::::", y[n].Line_Type);
								
								if(y[n].Line_Type === 'DATA_ROW'){
									for (var j=0; j < y[n].Line_data_occ.length; j++){
										if (y[n].Line_data_occ[j].ATTRIB_SHORT_CODE === 'ALLOCATED_BU'){
											var bslaIndex = vm.componentDetails.findIndex(x => x.ME_KEY == y[n].Line_data_occ[j].ATTRIB_VALUE); 
											if(bslaIndex != null && bslaIndex != undefined && bslaIndex >= 0){       							
												y[n].Line_data_occ[j].DISPLAY_TEXT = vm.componentDetails[bslaIndex].BSLA_DETAILS;
											}
										}								
									}	
								}
							}						
							
							
							var m = angular.copy(y);							
							console.log("At this point of the value of m is -------::::::", m);	
							c = angular.copy(y[0]);
							console.log("At this point of the value of c is -------::::::", c);
							var tab_header = '';
							var tab_length = c.Line_data_occ.length;
							var tab_color = '';
							tab_header = 'Amount allocated to BU(s)';
							tab_color = 'green';				
							
							
							c.Line_Type = "HEADER_ROW";
							c.Lock_status = 0;
							c.Occurrence = "";
							c.Trans_details_key = "";
							c.Payor_Type = 'O';
							c.Payor_Status = 'P';
							c.IS_EDITABLE = "N";
							for (var j=0; j < c.Line_data_occ.length; j++){
								c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
								c.Line_data_occ[j].OCCURRENCE = 0;
								c.Line_data_occ[j].TRANS_DETAILS_KEY = 0;
								c.Line_data_occ[j].PARENT_DETAIL_KEY = null;
								c.Line_data_occ[j].REP_TRANS_ID = null;
								c.Line_data_occ[j].TRANS_ID = null;
							}
							
							y.unshift({Line_Type: "TABLE_HEADER", Line_Desc: tab_header, colLength: tab_length, Line_color: tab_color}, c);
							vm.cpFormData[i].Line_data = angular.copy(y);
							
							
						} else {
							var z = vm.cpFormData[i].Line_data.reduce( (a,b) => {
								a[b.LINE_NO] = a[b.LINE_NO] || [];
								a[b.LINE_NO].push(b);
								return a;
							}, {});
							
							console.log("At this point of the value of z is -------::::::", z);
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "HEADER_ROW", Is_Multi: z[k][0].IS_MULTI, Lock_status: z[k][0].LOCK_STATUS, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: "", Occurrence: "", Line_data_occ: z[k]};
							});
							
							console.log("At this point of the value of y is -------:::::: line 157", y);
							
							for(var j=0; j < y[0].Line_data_occ.length; j++){
								y[0].Line_data_occ[j].ATTRIB_VALUE = y[0].Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							var tab_header = '';
							var tab_length = y[0].Line_data_occ.length;
							var tab_color = '';
							tab_header = 'Amount allocated to BU(s)';
							tab_color = 'green';
							
							console.log("At this point of the value of y[0] is -------::::::", y[0]);
							
							y.unshift({Line_Type: "TABLE_HEADER", Line_Desc: tab_header, colLength: tab_length, Line_color: tab_color});
							vm.cpFormData[i].Line_data = y;
						}
					}else if(vm.cpFormData[i].ROW_TYPE == 'TH'){
						if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'TOTAL_ALLOC_AMT_LBL'){
								vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.total_allocated_amt;
						}
						/*if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'VARIANCE_LBL'){
								vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.variance;
						}*/
					}
				}
				putTotalLineAmounts();
				console.log(vm.cpFormData);
				vm.cpFormOriginalData = angular.copy(vm.cpFormData);
			});
			
		}
        
        function getRequestedAmount(index){
        	var amount = 0;
        	for(var y=0; y < vm.cpFormData[index].Line_data.length; y++){
				if(vm.cpFormData[index].Line_data[y].Line_Type == 'DATA_ROW'){
					for(var z=0; z < vm.cpFormData[index].Line_data[y].Line_data_occ.length; z++){
						if(vm.cpFormData[index].Line_data[y].Line_data_occ[z].ATTRIB_SHORT_CODE == 'ALLOCATED_AMT'){
							amount = amount + parseFloat(vm.cpFormData[index].Line_data[y].Line_data_occ[z].ATTRIB_VALUE);
						}
					}
				}
			}
        	return amount;
        }
        
        function getCalculatedAmt(){
        	console.log("In getCalculatedAmt::::");
    		for (var i=0; i < vm.cpFormData.length; i++){
    			if(vm.cpFormData[i].ROW_TYPE == 'CH'){
    				console.log("The value of new record  ::::::::::::", getRequestedAmount(i));
    				if(getRequestedAmount(i) != ""){
    					vm.total_allocated_amt = vm.total_allocated_amt + getRequestedAmount(i);
    				}
    			}        		
        	}
    		if(vm.total_net_amt == undefined){
    			vm.total_net_amt = 0;
    		}
        	vm.variance = vm.total_net_amt - vm.total_allocated_amt;        	
        }
        
        function putTotalLineAmounts(){
        	console.log("In putTotalLineAmounts::::");
        	vm.total_allocated_amt = 0;
    		vm.variance = 0;
        	getCalculatedAmt();
        	for(var i=0; i < vm.cpFormData.length; i++){
        		if(vm.cpFormData[i].ROW_TYPE == 'TH'){
        			if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'TOTAL_ALLOC_AMT_LBL'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.total_allocated_amt;
					}else if(vm.cpFormData[i].Line_data[0].ATTRIB_SHORT_CODE == 'VARIANCE_LBL'){
						vm.cpFormData[i].Line_data[1].ATTRIB_VALUE = vm.variance;
					}
        		}
        	}
        }
        
        
        function cleanFormData(){
        	vm.searchBy = 'L';
    		vm.selectedBSLA  = '';		
            vm.allocated_amount = 0;
            vm.applicable_year = vm.rowData.TAX_YEAR;    
        }
        
        vm.reset = function(){
        	cleanFormData();
        }
        
        vm.changeStatus = function(Occurrence, Trans_details_key, index, parentIndex, action_param){
        	console.log("The action of the change status along with indexes are ::::::::::::", action_param, index, parentIndex);
        	if(action_param == 'R'){
        		vm.cpFormData[parentIndex].Line_data[index].Payor_Status = 'R';
        		for(var i=0; i < vm.cpFormData[parentIndex].Line_data[index].Line_data_occ.length; i++){
        			vm.cpFormData[parentIndex].Line_data[index].Line_data_occ[i].PAYOR_STATUS = 'R';
        		}
        		vm.cpFormData[parentIndex].DELETE_ROWS.push(vm.cpFormData[parentIndex].Line_data[index]);
        		vm.cpFormData[parentIndex].Line_data.splice(index, 1);
        	}else if(action_param == 'A'){
        		//vm.cpFormData[0].Line_data[index].Payor_Status = 'A'; -- Need to build logic for Approved
        		vm.cpFormData[parentIndex].Line_data[index].Payor_Status = 'A';
        		var approvedTableIndex = vm.cpFormData.findIndex(x => x.ROW_STATUS == 'A');
        		if(approvedTableIndex != null && approvedTableIndex != undefined && approvedTableIndex >= 0){
        			if(vm.cpFormData[approvedTableIndex].ROW_TYPE == 'CH' && vm.cpFormData[approvedTableIndex].ROW_STATUS == 'A'){
        				vm.cpFormData[approvedTableIndex].Line_data.push(vm.cpFormData[parentIndex].Line_data[index]);
        			}
        		}else{
        			var tab_header = 'Amount allocated and reconciled with counter party.';
        			var tab_length = vm.cpFormData[parentIndex].Line_data[1].Line_data_occ.length;
        			vm.dummyApprovedRecord.Line_data.push({Line_Type: "TABLE_HEADER", Line_Desc: tab_header, colLength: tab_length});
        			vm.dummyApprovedRecord.Line_data.push(vm.cpFormData[parentIndex].Line_data[1]);
        			vm.dummyApprovedRecord.Line_data.push(vm.cpFormData[parentIndex].Line_data[index]);
    				vm.cpFormData.unshift(vm.dummyApprovedRecord);
    				vm.cpFormData[parentIndex+1].Line_data.splice(index, 1);
        		}
        	}
        	putTotalLineAmounts();
        }
        
        vm.submit = function(){
        	var occArray = [];
        	
        	console.log("In submit");
        	
			var occurrance = 1;
			
			for(var m=0; m < vm.cpFormData.length; m++){
				if(vm.cpFormData[m].ROW_TYPE == 'CH'){
					if(null != vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence && vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence != "" && vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence != undefined){
						occArray.push(vm.cpFormData[m].Line_data[vm.cpFormData[m].Line_data.length -1].Occurrence + 1);
					}else{
						occArray.push(1);
					}
				}
			}
			occurrance = Math.max.apply(null, occArray);
			
			if(vm.editMode == true){
				for(var i=0; i < vm.cpFormData[vm.edit_record_parentindex].Line_data.length; i++){
					if(vm.edit_record_index == i && vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Occurrence == vm.edit_record_occurrence){
						for(var j=0; j < vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ.length; j++){
							if(vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'ALLOCATED_AMT'){
								vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE = vm.allocated_amount;
								vm.cpFormData[vm.edit_record_parentindex].Line_data[i].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
							}
						}
					}
				}
			}else{
				for(var x=0; x < vm.cpFormData.length; x++){
					if(vm.cpFormData[x].ROW_TYPE == 'CH'){
						var new_row = angular.copy(vm.cpFormData[x].Line_data[1]);
						new_row.Line_Type = "DATA_ROW";
						new_row.Occurrence = occurrance;
						new_row.IS_EDITTED = "Y";
						new_row.Is_Multi = "Y";
						new_row.Lock_status = 0;
						for(var i=0; i < new_row.Line_data_occ.length; i++){						
							console.log('i = ' + i);
							console.log('ATTRIB_SHORT_CODE = ' + new_row.Line_data_occ[i].ATTRIB_SHORT_CODE);
						
							if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'ALLOCATED_AMT'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.allocated_amount;
							}else if(new_row.Line_data_occ[i].ATTRIB_SHORT_CODE == 'ALLOCATED_BU'){
								new_row.Line_data_occ[i].ATTRIB_VALUE = vm.componentSelected.ME_KEY;		
								new_row.Line_data_occ[i].DISPLAY_TEXT = vm.componentSelected.BSLA_DETAILS;				
							}
							
							new_row.Line_data_occ[i].ALLOCATED_ME_KEY = vm.componentSelected.ME_KEY;
							new_row.Line_data_occ[i].OCCURRENCE = occurrance;
							new_row.Line_data_occ[i].IS_CHANGED_FLAG = "Y";						
						}
						
						vm.cpFormData[x].Line_data.push(new_row);
					}
				}
			}
			
			putTotalLineAmounts();
			console.log("After adding the new row in Data the data was :::::::::::", vm.cpFormData);
			cleanFormData();
			vm.editMode = false;
			vm.editMe = false;
        }
        
        vm.removeExistingRecord = function(Occurrence, Trans_details_key, index, parentIndex){
        	console.log("fn removeExistingRecord::::");
        	console.log("Occurrence::::", Occurrence);
        	console.log("Trans_details_key::::", Trans_details_key);
        	console.log("index::::", index);
        	console.log("index::::", parentIndex);
        
        	if(Trans_details_key == null || Trans_details_key == undefined || Trans_details_key == '' || Trans_details_key.length <= 0 ){
        		vm.cpFormData[parentIndex].Line_data.splice(index, 1);
        	}else{
        		
        		for(var i=0; i < vm.cpFormData[parentIndex].Line_data[index].Line_data_occ.length; i++){
        			vm.cpFormData[parentIndex].Line_data[index].Line_data_occ[i].IS_CHANGED_FLAG = 'D';
        		}
        		vm.cpFormData[parentIndex].DELETE_ROWS.push(vm.cpFormData[parentIndex].Line_data[index]);
        		vm.cpFormData[parentIndex].Line_data.splice(index, 1);
        	}
        	putTotalLineAmounts();
        	console.log("After removing the record the data was :::::::::::", vm.cpFormData);
        }
        
        vm.editExistingRecord = function(Occurrence, Trans_details_key, index, parentIndex, lock_status){
        
        	console.log("fn editExistingRecord::::");
        	console.log("Occurrence::::", Occurrence);
        	console.log("Trans_details_key::::", Trans_details_key);
        	console.log("index::::", index);
        	console.log("parentIndex::::", parentIndex);
        	console.log("lock_status::::", lock_status);        
        
        	vm.edit_record_index = index;
        	vm.edit_record_parentindex = parentIndex;
            vm.edit_record_occurrence = Occurrence;
        	if(vm.cpFormData[parentIndex].ROW_TYPE == 'CH'){
        		for(var i=0; i < vm.cpFormData[parentIndex].Line_data.length; i++){
        			if(i == index && vm.cpFormData[parentIndex].Line_data[i].Occurrence == Occurrence){
        				for(var j=0; j < vm.cpFormData[parentIndex].Line_data[i].Line_data_occ.length; j++){
        					if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'ALLOCATED_AMT'){
        						vm.allocated_amount = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_VALUE;
        					}else if(vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].ATTRIB_SHORT_CODE == 'ALLOCATED_BU'){
        						vm.selectedBSLA = vm.cpFormData[parentIndex].Line_data[i].Line_data_occ[j].DISPLAY_TEXT;    					
        						
        						var selectedbslaIndex = vm.componentDetails.findIndex(x => x.BSLA_DETAILS == vm.selectedBSLA);
        						vm.componentSelected = vm.componentDetails[selectedbslaIndex];
        				   }   				   
        				   
        			    }
        	    	}
        	    }
        	}
        	if(lock_status == 1){
        		vm.editMe = true;
        		getComponentDetails();
        	}else {
        		vm.editMode = true;
        	}
        }
        
        vm.save = function(){
        	vm.finalSaveData = [];
        	if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
        	vm.cpFormFinalData = angular.copy(vm.cpFormData);
        	if(angular.equals(vm.cpFormData, vm.cpFormOriginalData)){
        		vm.cancel();
        	}else{
        		manipulateData();
         	   
        		console.log("After changes, the final data was :::::::::::=========", vm.cpFormFinalData);
    			console.log("After changes, the manipulated data was :::::::::::=========", vm.cpFormData);
    			console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalSaveData);
    			
        		vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
        		sendsaveDetails(vm.finalSaveData, vm.clob_settings, vm.form_name, vm.schedule_name);
        	}
        }
        
        function sendsaveDetails(final_data, clob_settings, form, schedule){
			var schdSave = null;
			var message = "IC Allocation is successfully updated.";
			vm.crudLoading = true;
			ICDiagFactory.saveICForm(final_data, clob_settings, form, schedule).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(null != data.data.errorMessage && data.data.errorMessage != "null"){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There wa no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					vm.cancel();
					var args = {"refresh_param": "N"};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		}
        
        function manipulateData(){
        	for(var i=0; i < vm.cpFormFinalData.length; i++){
        		if(vm.cpFormFinalData[i].ROW_TYPE == 'CH'){
        			for(var j=0; j < vm.cpFormFinalData[i].Line_data.length; j++){
        				if(vm.cpFormFinalData[i].Line_data[j].Line_Type == 'DATA_ROW'){
        					for(var k=0; k < vm.cpFormFinalData[i].Line_data[j].Line_data_occ.length; k++){
        						vm.finalSaveData.push(vm.cpFormFinalData[i].Line_data[j].Line_data_occ[k]);
        					}
        				}
        			}
        			if(vm.cpFormFinalData[i].DELETE_ROWS.length != null && vm.cpFormFinalData[i].DELETE_ROWS.length != undefined && vm.cpFormFinalData[i].DELETE_ROWS.length > 0){
        				for(var m=0; m < vm.cpFormFinalData[i].DELETE_ROWS.length; m++){
            				if(vm.cpFormFinalData[i].DELETE_ROWS[m].Line_Type == 'DATA_ROW'){
            					for(var n=0; n < vm.cpFormFinalData[i].DELETE_ROWS[m].Line_data_occ.length; n++){
            						vm.finalSaveData.push(vm.cpFormFinalData[i].DELETE_ROWS[m].Line_data_occ[n]);
            					}
            				}
            			}
        			}
        		}else if(vm.cpFormFinalData[i].ROW_TYPE == 'TH'){
        			for(var j=0; j < vm.cpFormFinalData[i].Line_data.length; j++){
        				vm.finalSaveData.push(vm.cpFormFinalData[i].Line_data[j]);
        			}
        		}
        	}
        }
    };	
	return controllers;

});