define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.jcdNewController', [])
		.controller('JcdNewController', ['$scope', '$rootScope', '$http', '$state', '$uibModal', '$log', '$timeout', 'SERVER_CONFIG', 'USER_SETTINGS', 'JdcManagerFactory', 'JsonObjectFactory', 'JCDFactory', 'AlertService','GENERAL_CONFIG', jcdNewController])
		.controller('SaveJurisdictionController', ['$scope', '$rootScope', '$http', '$state', 'rowData', '$uibModal', '$uibModalInstance', '$log', '$timeout', 'JdcManagerFactory', 'JsonObjectFactory', 'JCDFactory', 'AlertService','GENERAL_CONFIG', saveJurisdictionController])
		.controller('SaveChannelController', ['$parse', '$scope', '$rootScope', '$http', '$state', 'rowData', '$uibModal', '$uibModalInstance', '$log', '$timeout', 'JdcManagerFactory', 'JsonObjectFactory', 'JCDFactory', 'AlertService', 'ClientAdminFactory','GENERAL_CONFIG', 'USER_SETTINGS', saveChannelController])
		.controller('SaveDomainController', ['$scope', '$rootScope', '$http', '$state', 'rowData', '$uibModal', '$uibModalInstance', '$log', '$timeout', 'JdcManagerFactory', 'JsonObjectFactory', 'JCDFactory', 'AlertService','GENERAL_CONFIG', saveDomainController])
		.controller('UpdateJcdController', ['$scope', '$rootScope', '$http', '$state', 'rowData', '$uibModal', '$uibModalInstance', '$log', '$timeout', 'JdcManagerFactory', 'JsonObjectFactory', 'JCDFactory', 'AlertService','GENERAL_CONFIG', 'USER_SETTINGS', updateJcdController]);


	function saveJurisdictionController($scope, $rootScope, $http, $state, rowData, $uibModal, $uibModalInstance, $log, $timeout, JdcManagerFactory, JsonObjectFactory, JCDFactory, AlertService,GENERAL_CONFIG) {
		var vm = this;
		vm.saveButtonText = "Save";
		vm.test = "true";
		vm.jurisdictions = {
			jurisdiction_key: '',
			jurisdiction_code: '',
			jurisdiction_type: '',
			jurisdiction_priority: '',
			jurisdiction_status: '',
			jurisdiction_desc: '',
			jurisdiction_name: '',
			client: '',
			jurisdiction: '',
			domain: '',
			channel: '',
			loading: false,
			jurisLoading: false
		};

		vm.jurisStatusList = [{
			name: 'Active',
			value: 'A'
		}, {
			name: 'Inactive',
			value: 'I'
		}];
		vm.jurisTypeList = [{
			name: 'CC',
			value: 'CC'
		}, {
			name: 'ST',
			value: 'ST'
		}, {
			name: 'CGO',
			value: 'CGO'
		}];

		vm.mode = (angular.isDefined(rowData.jurisdiction) && (rowData.jurisdiction != null) && (rowData.jurisdiction != '')) ?
			(function () {
				vm.jurisdictions.jurisdiction_key = rowData.jurisdiction.jurisdiction_key;
				vm.jurisdictions.jurisdiction_code = rowData.jurisdiction.jurisdiction_code;
				vm.jurisdictions.jurisdiction_type = _.find(vm.jurisTypeList, {
					value: rowData.jurisdiction.jurisdiction_type
				});
				vm.jurisdictions.jurisdiction_priority = parseInt(rowData.jurisdiction.jurisdiction_priority);
				vm.jurisdictions.jurisdiction_status = _.find(vm.jurisStatusList, {
					value: rowData.jurisdiction.jurisdiction_status
				});
				vm.jurisdictions.jurisdiction_desc = rowData.jurisdiction.jurisdiction_desc;
				vm.jurisdictions.jurisdiction_name = rowData.jurisdiction.jurisdiction_name;
				return 'edit';
			}()) :
			'new';
		vm.title = _.capitalize(vm.mode) + ' Jurisdiction';

		vm.clearForm = function () {
			var jurisdiction_key = vm.jurisdictions.jurisdiction_key;
			vm.jurisdictions = angular.copy(vm.masterCopy);
			$scope.saveJurisdictionFrm.$setPristine();
		};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};

		vm.jurisRequiredFields = {
			jurisdiction_code: false,
			jurisdiction_type: false,
			jurisdiction_priority: false,
			jurisdiction_status: false,
			jurisdiction_desc: false,
			jurisdiction_name: false
		};
		var fieldNames = _.keys(vm.jurisRequiredFields); //Creates a Minified list containing all property names of the specified object()
		vm.jurisdictionList = [];
		setTimeout(function () {
			for (var i = 0; i < fieldNames.length; i++) { //call the cretewatchFUnction of jurisdiction screen
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.jurisdictions.', fieldNames[i], 'jurisRequiredFields');
			}
			vm.jurisdictionList = rowData.jurisdictionList;
		});

		$scope.saveButtonText = "Save";

		vm.save = function () {

			$scope.enable = "false";
			$scope.saveButtonText = "Saving";

			setTimeout(function () {
				$scope.enable = "true";
				$scope.saveButtonText = "Save";
			}, 2000);
			//	vm.jurisdictions.jurisLoading = true;
			// 		// validate the rquired fields are filled-out or not
			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveJurisdictionFrm', 'jurisdictions', vm.jurisRequiredFields)) {
				return;
			}
			vm.jurisdictions.jurisdiction_code = _.upperCase(vm.jurisdictions.jurisdiction_code).replace(' ', '');
			if (vm.mode != 'edit')
				if (_.find(vm.jurisdictionList, {
						jurisdiction_code: vm.jurisdictions.jurisdiction_code
					})) {
					AlertService.add("error", "jurisdiction code already exist!");
					return;
				}

			var payLoadForSaving = [{
				JURISDICTION_CODE: vm.jurisdictions.jurisdiction_code,
				JURISDICTION_TYPE: vm.jurisdictions.jurisdiction_type.value,
				JURISDICTION_PRIORITY: vm.jurisdictions.jurisdiction_priority,
				JURISDICTION_STATUS: vm.jurisdictions.jurisdiction_status.value,
				JURISDICTION_DESC: vm.jurisdictions.jurisdiction_desc,
				JURISDICTION_NAME: vm.jurisdictions.jurisdiction_name,
			}];
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=musbb6";
			if (vm.mode == 'edit') { //edit action code--- for update, here were are passing "jurisdiction_key"
				payLoadForSaving[0]["JURISDICTION_KEY"] = rowData.jurisdiction.jurisdiction_key;
				url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=2me4l5";
			}

			var jsonObj = JSON.stringify(payLoadForSaving);
			var jsonSettings = '{}';
			var params = _.extend({
				jsonObj: jsonObj
			}, params);
			params = _.extend({
				jsonSettings: jsonSettings
			}, params);

			JsonObjectFactory.saveJSON(url, params).then(function (data) {
				if (data.callSuccess === "1") {
					AlertService.add("success", "jurisdictions " + vm.jurisdictions.jurisdiction_code + " " + (vm.mode == 'edit' ? "edited" : "saved") + " successfully.");
					$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDCSettings', 'jurisdiction');
					vm.cancel();
					//vm.getnInitInfo();
				} else {
					AlertService.add("error", " Problem saving jurisdictions: ");
					//vm.crudLoading = false;
				}
			});

		}

	}


	function saveChannelController($parse, $scope, $rootScope, $http, $state, rowData, $uibModal, $uibModalInstance, $log, $timeout, JdcManagerFactory, JsonObjectFactory, JCDFactory, AlertService, ClientAdminFactory,GENERAL_CONFIG,USER_SETTINGS) {
		var vm = this;

		vm.title = _.capitalize(vm.mode) + ' Channel';
        vm.user_settings = USER_SETTINGS;
		vm.channels = {
			client: '',
			channel_key: '',
			channel_name: '',
			channel_desc: '',
			channel_priority: '',
			channel_status: '',
			loading: false,
			taskLoading: false
		};
		vm.channelStatusList = [{
			name: 'Active',
			value: 'A'
		}, {
			name: 'Inactive',
			value: 'I'
		}];

		//get All CLients
		/*vm.getClients = function () {
			vm.clientList = JdcManagerFactory.clientList;
			if (angular.isDefined(rowData.client) &&
				(rowData.client != null) &&
				(rowData.client != '')) {
				vm.channels.client = _.find(vm.clientList, function (obj) {
					return (obj.client_key == rowData.client.client_key);
				});
			}
		}*/
        vm.clientList = vm.user_settings.user.clients;
		vm.channels.client = vm.user_settings.user;
		vm.mode = (angular.isDefined(rowData.channel) && (rowData.channel != null) && (rowData.channel != '')) ?
			(function () {
				vm.channels.channel_key = rowData.channel.channel_key;
				vm.channels.channel_name = rowData.channel.channel_name;
				vm.channels.channel_desc = rowData.channel.channel_desc;
				vm.channels.channel_priority = parseInt(rowData.channel.channel_priority);
				vm.channels.channel_status = _.find(vm.channelStatusList, {
					value: rowData.channel.channel_status
				});
				return 'edit'
			}()) :
			'new';
		vm.clearForm = function () {
			var channel_key = vm.channels.channel_key;
			vm.channels = angular.copy(vm.masterCopy);
			$scope.saveChannelFrm.$setPristine();
		};
		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};
		vm.channelsRequiredFields = {
			channel_name: false,
			channel_desc: false,
			channel_priority: false,
			channel_status: false
		};
		var cfieldNames = _.keys(vm.channelsRequiredFields);

		vm.channelList = [];
		setTimeout(function () {
			for (var i = 0; i < cfieldNames.length; i++) { //call the cretewatchFUnction of Channel screen
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.channels.', cfieldNames[i], 'channelsRequiredFields');
			}
			vm.channelList = rowData.channelList;
		});

		vm.save = function () {

			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveChannelFrm', 'channels', vm.channelsRequiredFields)) {
				return;
			}
			JdcManagerFactory.saveChannel($scope, vm, 'channels', vm.mode, function () {
				$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDCSettings');
				vm.cancel();
			}, rowData);
		}

	}

	function saveDomainController($scope, $rootScope, $http, $state, rowData, $uibModal, $uibModalInstance, $log, $timeout, JdcManagerFactory, JsonObjectFactory, JCDFactory, AlertService,GENERAL_CONFIG) {
		var vm = this;
		vm.title = _.capitalize(vm.mode) + ' Domain';
		vm.domains = {
			domain_key: '',
			domain_name: '',
			domain_desc: '',
			domain_priority: '',
			domain_status: '',
			loading: false,
			taskLoading: false
		};
		vm.domainStatusList = [{
			name: 'Active',
			value: 'A'
		}, {
			name: 'Inactive',
			value: 'I'
		}];
		vm.mode = (angular.isDefined(rowData.domain) && (rowData.domain != null) && (rowData.domain != '')) ?
			(function () {
				vm.domains.domain_key = rowData.domain.domain_key;
				vm.domains.domain_name = rowData.domain.domain_name;
				vm.domains.domain_desc = rowData.domain.domain_desc;
				vm.domains.domain_priority = parseInt(rowData.domain.domain_priority);
				vm.domains.domain_status = _.find(vm.domainStatusList, {
					value: rowData.domain.domain_status
				});

				return 'edit'
			}()) : 'new';

		vm.clearForm = function () {
			var domain_key = vm.domains.domain_key;
			vm.domains = angular.copy(vm.masterCopy);
			$scope.saveDomainFrm.$setPristine();
		};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};

		vm.domainRequiredFields = {
			domain_name: false,
			domain_desc: false,
			domain_priority: false,
			domain_status: false
		};
		var dfieldNames = _.keys(vm.domainRequiredFields);
		vm.domainList = [];
		setTimeout(function () {

			for (var i = 0; i < dfieldNames.length; i++) { //call the cretewatchFUnction of jurisdiction screen
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.domains.', dfieldNames[i], 'domainRequiredFields');

			}
			vm.domainList = rowData.domainList;
		});

		$scope.saveButtonText = "Save";
		vm.save = function () {
			$scope.enable = "false";
			$scope.saveButtonText = "Saving";

			setTimeout(function () {
				$scope.enable = "true";
				$scope.saveButtonText = "Save";
			}, 2000);
			// validate the rquired fields are filled-out or not
			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveDomainFrm', 'domains', vm.domainRequiredFields)) {
				return;
			}

			vm.domains.domain_name = _.capitalize(vm.domains.domain_name);
			if (vm.mode != 'edit')
				if (_.find(vm.domainList, {
						domain_name: vm.domains.domain_name
					})) {
					AlertService.add("error", "channel code already exist!");
					return;
				}
			var payLoadForSaving = [{
				DOMAIN_NAME: vm.domains.domain_name,
				DOMAIN_DESC: vm.domains.domain_desc,
				DOMAIN_PRIORITY: vm.domains.domain_priority,
				DOMAIN_STATUS: vm.domains.domain_status.value,
			}];
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0495e6";

			if (vm.mode == 'edit') { //edit action code sf8fwz--- for update, here were are passing "Domain"
				payLoadForSaving[0]["DOMAIN_KEY"] = rowData.domain_key;
				url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=sf8fwz";
			}

			var jsonObj = JSON.stringify(payLoadForSaving);
			var jsonSettings = '{}';
			var params = _.extend({
				jsonObj: jsonObj
			}, params);
			params = _.extend({
				jsonSettings: jsonSettings
			}, params);

			JsonObjectFactory.saveJSON(url, params).then(function (data) {
				if (data.callSuccess === "1") {
					AlertService.add("success", "doamin " + vm.domains.domain_name + " " + (vm.mode == 'edit' ? "edited" : "saved") + " successfully.");
					$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDCSettings', 'domain');
					vm.cancel();
					//vm.getnInitInfo();
				} else {
					AlertService.add("error", " Problem saving domain: ");
					//vm.crudLoading = false;
				}
			});
		}
	}

	function updateJcdController($scope, $rootScope, $http, $state, rowData, $uibModal, $uibModalInstance, $log, $timeout, JdcManagerFactory, JsonObjectFactory, JCDFactory, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
		var vm = this;
		vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
		vm.clientList = user.clients;
		vm.mode = (angular.isDefined(rowData.jcdcomb) && (rowData.jcdcomb != null) && (rowData.jcdcomb != '')) ?
			(function () {
				vm.jdcInfo = rowData.jcdcomb;
				return 'edit'
			}()) :
			'new';

		vm.title = _.capitalize(vm.mode) + ' JCD KEY';
		/*	vm.title = 'Edit JCD Combination';	vm.mode = 'edit';*/

		vm.selectedClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });
		vm.jdcInfo = {
			client: vm.selectedClient,
			channel: '',
			jurisdiction:{
				jurisdiction_code:'US',
				jurisdiction_key: '1'
			},
			domain:{
				domain_name:'Direct',
				domain_key: '9'
			},
			loading: false,
			jcdLoading: false,
			jcdcomb: rowData.jcdcomb,
			jcdCombiList: rowData.jcdCombiList,
		};

		vm.isShowNewChannel = false;
			// createChanell toggle plus button
		vm.toggleAddNewChannelSection = function(){
			vm.isShowNewChannel = !vm.isShowNewChannel;
		}
		vm.channels = {
			client: '',
			channel_key: '',
			channel_name: '',
			channel_desc: '',
			channel_priority: '',
			channel_status: '',
			loading: false,
		};

		vm.channelStatusList = [{
			name: 'Active',
			value: 'A'
		}, {
			name: 'Inactive',
			value: 'I'
		}];
		vm.user_settings = USER_SETTINGS;

		vm.getInitInfo = function () {
			vm.jdcInfo.jcdLoading = true;
			if (vm.jdcInfo.client){
				console.log("Load all the app keys");
				JdcManagerFactory.getInitInfo({
					client: {
						value: vm.jdcInfo.client.client_key
					}
				}).then(function (data) {
				vm.channelList = data.data.jcdManage.clientChannelList;
					if (angular.isDefined(rowData.channel) &&
						(rowData.channel != null) &&
						(rowData.channel != '')
					) {
						vm.jdcInfo.channel = _.find(vm.channelList, function (obj) {
							return (obj.channel_key == rowData.channel.channel_key);
						});
					}
					vm.jdcInfo.jcdLoading = false;
				}, function () {
					AlertService.add("error", "Problem getting Initialization info.");
					vm.jdcInfo.jcdLoading = true;
				});	
			}
		};

		vm.getClients = function () {
			//vm.clientList = JdcManagerFactory.clientList;
			if (angular.isDefined(rowData.client) && (rowData.client != null) && (rowData.client != '')) {
				vm.jdcInfo.client = _.find(vm.clientList, function (obj) {
					return (obj.client_key == rowData.client.client_key);
				});
				vm.getInitInfo();
			}
		}
		vm.getClients();


		if (_.lowerCase(vm.mode) == _.lowerCase('edit')) {

		};

		vm.clearForm = function () {
			var jcd_key = vm.jdcInfo.jcd_key;
			vm.jdcInfo = angular.copy(vm.masterCopy);
			$scope.saveJurisdictionFrm.$setPristine();
		};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
			// $rootScope.$broadcast('gtw.admin.jdc.scenarioModalClose');
		};
		vm.channelsRequiredFields = {
			channel_name: false,
			channel_desc: false,
			channel_priority: false,
			channel_status: false
		};

		vm.requiredFields = {
			client: false,
			channel: false,
		};
		setTimeout(function () {
			var fieldNames = _.keys(vm.requiredFields); // _.keys--Creates a Minified list containing all property names of the specified object
			var cfieldNames = _.keys(vm.channelsRequiredFields);
			for (var i = 0; i < fieldNames.length; i++) {
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.jdcInfo.', fieldNames[i], 'requiredFields');
			}
			for (var i = 0; i < cfieldNames.length; i++) { //call the cretewatchFUnction of Channel screen
				JdcManagerFactory.createWatchFunction($scope, vm, 'ctrl.channels.', cfieldNames[i], 'channelsRequiredFields');
			}
		});

	/*	$scope.saveButtonText = "Save";
		vm.save = function () {

			$scope.enable = "false";
			$scope.saveButtonText = "Saving";

			setTimeout(function () {
				$scope.enable = "true";
				$scope.saveButtonText = "Save";
			}, 2000);

			if (_.find(rowData.jcdCombiList, {
					jurisdiction_key: vm.jdcInfo.selectedJurisdiction.jurisdiction_key,
					domain_key: vm.jdcInfo.selectedDomain.domain_key,
					channel_key: vm.jdcInfo.selectedChannel.channel_key,
					client_key: vm.jdcInfo.selectedClient.client_key
				})) {
				AlertService.add("error", "JCD Code already exist!");
				return;
			}

			var payLoadForSaving = {
				jurisdiction_key: vm.jdcInfo.selectedJurisdiction.jurisdiction_key,
				domain_key: vm.jdcInfo.selectedDomain.domain_key,
				channel_key: vm.jdcInfo.selectedChannel.channel_key,
				client_key: vm.jdcInfo.selectedClient.client_key,
				jcd_key: rowData.jcdcomb.jcd_key
			};


			JdcManagerFactory.updateJcd(payLoadForSaving).then(function (data) {
				console.log(" jcd update requested Params", data);
				//	vm.crudLoading = false;
				if (data.data.result && data.data.result.value === "1") {
					AlertService.add("success", "JCD " + data.data.result.jcdKey + " updated successfully.");
					$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDCSettings', 'jcdcomb');
					vm.cancel();
				} else {
					AlertService.add("error", "Problem saving New JCD");
					//+ data.data.result.value
				}
			});

		}*/

		vm.saveChannel = function () {
			if (JdcManagerFactory.validateRequiredFileds($scope, vm, 'saveJurisdictionFrm', 'channels', vm.channelsRequiredFields)) {
				return;
			}
			//JdcManagerFactory.saveChannel($scope, vm, 'jcdInfo','channels', vm.clientChannelList, rowData);
			JdcManagerFactory.saveChannel($scope,vm,rowData,vm.getInitInfo);
		}

		vm.save = function () {
			// validate the rquired fields are filled-out or not
			var fieldNames = _.keys(vm.requiredFields),
				isAllValidationPassed = true;
			for (var i = 0; i < fieldNames.length; i++) { //call the cretewatchFUnction of jurisdiction screen
				if ((vm.requiredFields[fieldNames[i]] = vm.jdcInfo[fieldNames[i]] ? false : true)) {
					isAllValidationPassed = false;
				}
			}
			if (!isAllValidationPassed) {
				return;
			}
			if (_.find(rowData.jcdCombiList, {
					jurisdiction_key: vm.jdcInfo.jurisdiction.jurisdiction_key,
					domain_key: vm.jdcInfo.domain.domain_key,
					channel_key: vm.jdcInfo.channel.channel_key,
					client_key: vm.jdcInfo.client.client_key

				})) {
				AlertService.add("error", "JCD Code already exist!");
				return;
			}

			var objContext = {
				client_key: vm.jdcInfo.client.client_key,
				jurisdiction_key: vm.jdcInfo.jurisdiction.jurisdiction_key,
				domain_key: vm.jdcInfo.domain.domain_key,
				channel_key: vm.jdcInfo.channel.channel_key,
			};
			JdcManagerFactory.saveNewJcd(objContext).then(function (data) {
				console.log("new jcd creation requested Params", data);
				//	vm.crudLoading = false;
				if (data.data.result && data.data.result.value === "1") {
					//AlertService.add("success", "JCD " + data.data.result.jcdKey + " saved successfully.");
					AlertService.add("success", "JCD saved successfully.");
					$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDCSettings', vm.jdcInfo.client.client_key, 4000);
					vm.cancel();
				} else {
					AlertService.add("error", "Problem saving New JCD" + data.data.result.value, 4000);
				}
			});

		}
	}

	function jcdNewController($scope, $rootScope, $http, $state, $uibModal, $log, $timeout, SERVER_CONFIG, USER_SETTINGS, JdcManagerFactory, JsonObjectFactory, JCDFactory, AlertService,GENERAL_CONFIG) {

		var vm = this;
		vm.pageTitle = "Admin - JDC Main Setting";

		vm.jcdlist = {};

		vm.jdcSettingsInfo = {
			jcdKey: null,

			jurisdiction: {
				showJurisdictions: false,
				jurisdictions: []
			},
			domain: {
				showDomains: false,
				domains: []
			},
			channel: {
				showChannels: false,
				channels: []
			},
			jcdcomb: {
				showJcds: false,
				jcds: []
			},
			filterResult: {
				client: '',
				jurisdiction: '',
				domain: '',
				channel: ''
			},
			/*selectedJurisdiction: '',
			selectedDomain: '',*/
			selectedJurisdiction: {
				jurisdiction_code: 'US',
				jurisdiction_key: '1'
			},
			selectedDomain: {
				domain_name: 'Direct',
				domain_key: '9'
			},
			selectedChannel: '',
			selectedClient: '',
			showResult: false,
			editModalLoading: false,
			resultLoading: false,
			loading: false,

			filterResult: {
				client: ''
			},
		};
		vm.masterCopy = angular.copy(vm.jdcSettingsInfo);

		vm.clientList = [];
		vm.jurisdictionList = [];
		vm.domainList = [];
		vm.channelList = [];
		vm.jcdCombiList = [];

		vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;

		vm.selectedClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });

		vm.jdcFields = {
			// selectedJurisdiction: false,
			// selectedDomain: false,
			selectedChannel: false,
			selectedClient: false,
		};

		vm.isRequiredFieldValidate = function (fieldName) {
			vm.jdcFields[fieldName] = false;
		}


	/*	$scope.saveButtonText = "Create New JCD";
		vm.saveNewCombi = function () {

			$scope.enable = "false";
			$scope.saveButtonText = "Creating New JCDCOmbination";

			setTimeout(function () {
				$scope.enable = "true";
				$scope.saveButtonText = "Create New JCD";
			}, 2000);

			// validate the rquired fields are filled-out or not
			var fieldNames = _.keys(vm.jdcFields),
				isAllValidationPassed = true;
			for (var i = 0; i < fieldNames.length; i++) { //call the cretewatchFUnction of jurisdiction screen
				if ((vm.jdcFields[fieldNames[i]] = vm.jdcSettingsInfo[fieldNames[i]] ? false : true)) {
					isAllValidationPassed = false;
				}
			}
			if (!isAllValidationPassed) {
				return;
			}
			if (_.find(vm.jcdCombiList, {
					jurisdiction_key: vm.jdcSettingsInfo.selectedJurisdiction.jurisdiction_key,
					domain_key: vm.jdcSettingsInfo.selectedDomain.domain_key,
					channel_key: vm.jdcSettingsInfo.selectedChannel.channel_key,
					client_key: vm.jdcSettingsInfo.selectedClient.client_key
				})) {
				AlertService.add("error", "JCD Code already exist!");
				return;
			}

			var objContext = {
				client_key: vm.jdcSettingsInfo.selectedClient.client_key,
				jurisdiction_key: vm.jdcSettingsInfo.selectedJurisdiction.jurisdiction_key,
				domain_key: vm.jdcSettingsInfo.selectedDomain.domain_key,
				channel_key: vm.jdcSettingsInfo.selectedChannel.channel_key,
			};
			JdcManagerFactory.saveNewJcd(objContext).then(function (data) {
				console.log("new jcd creation requested Params", data);
				//	vm.crudLoading = false;
				if (data.data.result && data.data.result.value === "1") {
					AlertService.add("success", "JCD " + data.data.result.jcdKey + " saved successfully.");
					$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDCSettings', 'jcdcomb');
					vm.cancel();
				} else {
					AlertService.add("error", "Problem saving New JCD" + data.data.result.value);
				}
			});

		}*/
		vm.addNewJurisdiction = function () {
			var data = {
				rowData: {
					jurisdictionList: vm.jurisdictionList
				}
			};
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdcMainSettings/jdc-jurisiction-tpl.html";
			modalObj.controler = "SaveJurisdictionController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, data);
		};
		vm.editJurisdiction = function (jurisdiction) {
			vm.jdcSettingsInfo.editModalLoading = true;
			var info = {
				rowData: {
					jurisdiction: jurisdiction,
					jurisdictionList: vm.jurisdictionList
				}
			};
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdcMainSettings/jdc-jurisiction-tpl.html";
			modalObj.controler = "SaveJurisdictionController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, info);
			vm.jdcSettingsInfo.editModalLoading = false;
		};
		vm.removeJurisd = function (jurisdiction_key) {

			vm.jdcSettingsInfo.editModalLoading = true;
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=183ohm";

			var payLoadForSaving = [{
				JURISDICTION_KEY: jurisdiction_key
			}];

			var jsonObj = JSON.stringify(payLoadForSaving);
			var jsonSettings = '{}';
			var params = _.extend({
				jsonObj: jsonObj
			}, params);
			params = _.extend({
				jsonSettings: jsonSettings
			}, params);
			JsonObjectFactory.saveJSON(url, params).then(function (data) {
				vm.jdcSettingsInfo.editModalLoading = false;
				if (data.callSuccess === "1") {
					AlertService.add("success", "Jurisdiction removed successfully.");
					vm.jurisdictionList = _.without(vm.jurisdictionList, _.find(vm.jurisdictionList, {
						jurisdiction_key: jurisdiction_key
					}));
					//vm.cancel();
				} else {
					AlertService.add("error", "Jurisdiction not removed.");
				}
			});

		};

		vm.addNewChannel = function () {
			var data = {
				rowData: {
					channelList: vm.channelList
				}
			};
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdcMainSettings/jdc-channel-tpl.html";
			modalObj.controler = "SaveChannelController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, data);
		};
		vm.editChannel = function (channel) {
			vm.jdcSettingsInfo.editModalLoading = true;

			var info = {
				rowData: {
					channel: channel,
					channelList: vm.channelList,
					client: {
						client_key: channel.client_key
					}
				}
			};

			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdcMainSettings/jdc-channel-tpl.html";
			modalObj.controler = "SaveChannelController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, info);
			vm.jdcSettingsInfo.editModalLoading = false;
		};
		vm.editDomain = function (domain) {
			vm.jdcSettingsInfo.editModalLoading = true;

			var info = {
				rowData: {
					domain: domain,
					domainList: vm.domainList
				}
			};

			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdcMainSettings/jdc-domain-tpl.html";
			modalObj.controler = "SaveDomainController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, info);
			vm.jdcSettingsInfo.editModalLoading = false;
		};
		vm.addNewJCD = function () {
			var data = {
				rowData: {
					jcdcomb: vm.jcdcomb,
					jcdCombiList: vm.jcdCombiList,
					clientList: vm.userSettings.user.clients,
					jurisdictionList: vm.jurisdictionList,
					domainList: vm.domainList,
					channelList: vm.channelList,
					client: vm.selectedClient,
					jurisdiction: vm.jdcSettingsInfo.selectedJurisdiction,
					channel: vm.jdcSettingsInfo.selectedChannel,
					domain: vm.jdcSettingsInfo.selectedDomain

				}
			};
			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdcMainSettings/jcd-new-tpl.html";
			modalObj.controler = "UpdateJcdController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, data)
		}
		vm.editJCDCombination = function (jcdcomb) {
			vm.jdcSettingsInfo.editModalLoading = true;
			var info = {
				rowData: {
					jcdcomb: jcdcomb,
					jcdCombiList: vm.jcdCombiList,
					clientList: vm.clientList,
					jurisdictionList: vm.jurisdictionList,
					domainList: vm.domainList,
					channelList: vm.channelList
				}
			};

			var modalObj = {};
			modalObj.template = "app/components/admin/jdcManager/jdcMainSettings/jcd-new-tpl.html";
			modalObj.controler = "UpdateJcdController as ctrl";
			modalObj.size = "lg";
			modalObj.backdrop = "static";
			vm.openModal(modalObj, info);
			vm.jdcSettingsInfo.editModalLoading = false;
		}
		vm.removeChanel = function (channel_key) {

			vm.jdcSettingsInfo.editModalLoading = true;
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=e3x0h6";

			var payLoadForSaving = [{
				CHANNEL_KEY: channel_key
			}];

			var jsonObj = JSON.stringify(payLoadForSaving);
			var jsonSettings = '{}';
			var params = _.extend({
				jsonObj: jsonObj
			}, params);
			params = _.extend({
				jsonSettings: jsonSettings
			}, params);
			JsonObjectFactory.saveJSON(url, params).then(function (data) {
				vm.jdcSettingsInfo.editModalLoading = false;
				if (data.callSuccess === "1") {
					AlertService.add("success", "Channel removed successfully.",4000);
					vm.channelList = _.without(vm.channelList, _.find(vm.channelList, {
						channel_key: channel_key
					}));
					// $rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
					// vm.cancel();
				} else {
					AlertService.add("error", "Channel is in Use,not removed.");
				}
			});

		};
		vm.addNewDomain = function () {
			var data = {
				rowData: {
					domainList: vm.domainList
				}
			};
			vm.openModal({
				template: "app/components/admin/jdcManager/jdcMainSettings/jdc-domain-tpl.html",
				controler: "SaveDomainController as ctrl",
				size: "lg"
			}, data);
		};
		vm.removeDomain = function (domain_key) {

			vm.jdcSettingsInfo.editModalLoading = true;
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=cktgju";

			var payLoadForSaving = [{
				DOMAIN_KEY: domain_key
			}];

			var jsonObj = JSON.stringify(payLoadForSaving);
			var jsonSettings = '{}';
			var params = _.extend({
				jsonObj: jsonObj
			}, params);
			params = _.extend({
				jsonSettings: jsonSettings
			}, params);
			JsonObjectFactory.saveJSON(url, params).then(function (data) {
				vm.jdcSettingsInfo.editModalLoading = false;
				if (data.callSuccess === "1") {
					AlertService.add("success", "Domain removed successfully.");
					// $rootScope.$broadcast('gtw.admin.jdc.loadResultForJDC');
					// vm.cancel();
					vm.domainList = _.without(vm.domainList, _.find(vm.domainList, {
						domain_key: domain_key
					}));
				} else {
					AlertService.add("error", "Domain not removed.");
				}
			});

		};


		vm.removeJCD = function (jcdInfo) {
			JdcManagerFactory.delteJcd(jcdInfo).then(function (data) {
				console.log(" jcd deletion requested Params", data);
				if (data.data.result && data.data.result.value === "1") {
					AlertService.add("success", "JCD " + jcdInfo.jcd_key + " remove successfully.");
					vm.jcdCombiList = _.without(vm.jcdCombiList, _.find(vm.jcdCombiList, {
						//jcd_key: jcd_key
						jcd_key: jcdInfo.jcd_key

					}));
				} else {
					AlertService.add("error", "JCD combination not removed.");
				}
			});

		};

		vm.openModal = function (modalObj, _data) {
			vm.rowData = _data.rowData;
			vm.gridData = _data;
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: modalObj.template,
				controller: modalObj.controler,
				size: modalObj.size,
				backdrop: modalObj.backdrop,
				resolve: {
					rowData: function () {
						return vm.rowData;
					},
					gridData: function () {
						return vm.gridData;
					}
				}
			});

			modalInstance.result.then(function (selectedItem) {
				$rootScope.$broadcast('gtw.admin.jdc.activityModalClose');
			}, function () {
				$rootScope.$broadcast('gtw.admin.jdc.activityModalClose');
			});
		};

		vm.toggleJcdComb = function () {
			vm.jdcSettingsInfo.jcdcomb.showJcds = !vm.jdcSettingsInfo.jcdcomb.showJcds;
		}
		vm.toggleJurisdictions = function () {
			vm.jdcSettingsInfo.jurisdiction.showJurisdictions = !vm.jdcSettingsInfo.jurisdiction.showJurisdictions;
		}
		vm.toggleChannels = function () {
			vm.jdcSettingsInfo.channel.showChannels = !vm.jdcSettingsInfo.channel.showChannels;
		}
		vm.toggleDomains = function () {
			vm.jdcSettingsInfo.domain.showDomains = !vm.jdcSettingsInfo.domain.showDomains;
		}
		$scope.$on('gtw.admin.jdc.loadResultForJDCSettings', function (event, data) {
			vm.loadResultForJDCSettings(data);
		});
		vm.loadResultForJDCSettings = function (filterParams) {
			vm.jdcSettingsInfo.resultLoading = true;
            //vm.user_settings.user
			JdcManagerFactory.loadResultForJDCSettings({clientKey:filterParams}).then(function (data) {

				vm.clientList = JdcManagerFactory.clientList;
				vm.jurisdictionList = JSON.parse(data.data.data);
				vm.domainList = JSON.parse(data.data.data2);
				vm.channelList = JSON.parse(data.data.data3);
				vm.jcdCombiList = JSON.parse(data.data.data4);
				vm.jdcSettingsInfo.resultLoading = false;
				setTimeout(function () {
					for (var key in vm.jdcFields) {
						vm.jdcFields[key] = false;
					}
				}, 2000);

			}, function () {
				vm.jdcSettingsInfo.resultLoading = false;
				AlertService.add("error", "Problem loading.");
			});
		};

        //vm.loadResultForJDCSettings();
		vm.loadResultForJDCSettings(USER_SETTINGS.user.client_key);
		setTimeout(function () {
		vm.clientList = JdcManagerFactory.clientList;
		}, 2000);
	}
	return controllers;
});