define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.CoaDiagnosticsController', [])
					.controller('coaDiagnosticsCtrl', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG','$filter', 'CoaDiagnosticsServiceFactory', 'MessageFactory','$timeout', coaDiagnosticsCtrl])
					
			function coaDiagnosticsCtrl($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, 
					USER_SETTINGS, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG,$filter, CoaDiagnosticsServiceFactory, MessageFactory,$timeout) {
				
				var vm = this;	
				vm.filteredTaxYearData = [];	
				//Get the current filing Tax Year
				vm.curentyear = GlobalService.globalParams.tax_year;
				//Get the scenario
				vm.scenario = GlobalService.globalParams.scenario;
				//JCD Key for the given custom screen
				vm.jcd_key = GlobalService.globalParams.jcd_key;
				//JCD TA Key for the given custom screen
/*				vm.jcd_ta_key;*/
				vm.selectedFromAcctKey
				//Show Scenario dropdown
				vm.showscenario = false;
				vm.examScenario = false;
				
				vm.localAccts = [];
				vm.filingEnityDetails = [];
				vm.filingEnityDetailsForSectionDetails = [];
				vm.parentDetails = [];
				vm.parentME = [];
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"scenario": GlobalService.globalParams.scenario 
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null &&  vm.is_locked[0] && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}	
						
						if(vm.is_locked != undefined && vm.is_locked !=null &&  vm.is_locked[0] && vm.is_locked[0].SCENARIO_DESC == 'EXAM'){
							vm.examScenario = true;
						}
					});
				});
				
				  $scope.$watch(function(){ return workspaceFactory.screens}, function(val){
			            vm.workspaces = workspaceFactory.getScreen();
			            $timeout(function() {
			                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
			                    vm.scrlTabsApi.init();
			                }
			            });
			        });
				
				  vm.tabs = [];
			        vm.updateFn = function(arg){
			            var obj = [];
			            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
			            vm.setTab(obj);
			        }
				/*$scope.$watch(function() { return JCDFactory.JCDList },   function() {
					if(JCDFactory.JCDList!=undefined && JCDFactory.JCDList!=null && JCDFactory.JCDList.length > 0){											
						var b = _.find(JCDFactory.JCDList, function(o) { return o.ACTIVITY_NAME.includes(workspaceFactory.activity.label); });							
						//Get the JCD Core details
						vm.jcd_key = b.JCD_KEY;
						vm.jcd_ta_key = b.JCD_TA_KEY;
						vm.task_name = b.TASK_NAME;							
					}
				});*/
				
				//Redirect the URL to a sourcing screen after closing the Modal screen 
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
								
				//Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {		
					var params = {"action_code" : 'e83e1x',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});							
						for(var indx in vm.taxYearData){
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearData[indx].CODE_DISP_DESC <= (vm.curentyear) && vm.taxYearData[indx].CODE_DISP_DESC >= (vm.curentyear-5))){
								vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
							}
						}
					});					
				};
				vm.getTaxYearDropDown();
				
				vm.getCOAData = function() {
					let params = {
			                "action_code": 'md63zh',
			                "scenario": vm.selectedScenario,
			                "tax_year":  vm.selectedTaxYear
			            };
			            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
			                .then(function(data) {
			                	console.log(data);
			                	vm.localAccts = data.jsonObject;
			                });
				}
				
				vm.getFilingGroup = function() {
					let params = {
			                "action_code": 'z60z07',
			                "scenario": vm.selectedScenario,
			                "tax_year":  vm.selectedTaxYear
			            };
			            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
			                .then(function(data) {
			                	console.log(data);
			                	vm.filingGroupDropDown = data.jsonObject;
			                });
				}
				
				vm.getFilingEnityDetails = function() {
		        	let params = {
							//"action_code": 'hmkia6',
		        				"action_code":"35gc5l",
			        			"filing_group_key":vm.filingGroup,
			        			 "scenario": vm.selectedScenario,
					                "tax_year":  vm.selectedTaxYear
					};
	            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
	                .then(function(data) {
	                	vm.filerEntity = [];
	                	vm.filingEnityDetails = data.jsonObject;
	                });
		        }
				
				vm.getParentMeDetails = function() {
					 let params = {
				                "action_code": 'hmkia6',
				            };
				            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
				                .then(function(data) {
				                	
				                	
						           let curr_data = data.jsonObject;
						           vm.parentDetails = $filter('filter')(data.jsonObject, function(data) {
				                       return (data.LEVEL_GROUP === 'Parent ME');
				                   });
				        });
				}
				
				vm.getParentMeDetails();
				
				vm.getFilingEnityDetailsForSection = function() {
		        	let params = {
							//"action_code": 'hmkia6',
		        				"action_code":"35gc5l",
			        			"filing_group_key":vm.filingGroupSection,
			        			 "scenario": vm.selectedScenario,
					                "tax_year":  vm.selectedTaxYear
					};
	            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
	                .then(function(data) {
	                	vm.filerEntitySection = [];
	                	vm.filingEnityDetailsForSectionDetails = data.jsonObject;
	                });
		        }
				
				//vm.getFilingGroup();
				
				//Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
						AlertService.add("error", "Please select the Tax Year to get the Scenario.",4000);
						return;
					}
			
					var params = {"action_code" : '7q0wiy',"tax_year" : vm.selectedTaxYear};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;		
						vm.showscenario = true;
					});					
				};
					
				//Call the Sync process of Exempt Assets - FDII - Consol
				vm.syncExemptAssetsConsol = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}					
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "p_jcd_key":vm.jcd_key, 'p_scenario' : vm.scenario};				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N' && vm.is_locked[0].SCENARIO_DESC != 'EXAM'){
						/*	var params = {"action_code" : '2rt8bp', "p_tax_year": vm.selectedTaxYear, "p_scenario" : vm.selectedScenario };		    			
							JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(result) {												
								if (result.errorMessage && result.errorMessage !== 'null') {
									AlertService.add("error", result.errorMessage, 4000);
								}
							});*/
							var params = {"sso_id":USER_SETTINGS.user.sso_id,"action_code" : 'oreknl', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario ,"jcd_key":vm.jcd_key};		    			
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {												
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});
							AlertService.add("success", "Sync Process for Exempt Assets - FDII is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync  Exempt Assets - FDII Process for selected Tax Year and Scenario.", 4000);
						}
						
					});
				}
				
				//Call the Sync process of Interest Expense - Consol
				vm.syncIntExpConsol = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}					
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "p_jcd_key":vm.jcd_key, 'p_scenario' : vm.scenario};				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N' && vm.is_locked[0].SCENARIO_DESC != 'EXAM'){
						/*	var params = {"action_code" : '2rt8bp', "p_tax_year": vm.selectedTaxYear, "p_scenario" : vm.selectedScenario };		    			
							JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(result) {												
								if (result.errorMessage && result.errorMessage !== 'null') {
									AlertService.add("error", result.errorMessage, 4000);
								}
							});*/
							var params = {"sso_id":USER_SETTINGS.user.sso_id,"action_code" : '2rt8bp', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario ,"jcd_key":vm.jcd_key};		    			
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {												
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});
							AlertService.add("success", "Sync Process for Interest Expense (Consolidated) is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Interest Expense Process for selected Tax Year and Scenario.", 4000);
						}
					});
				}
				
				//Call the Sync process of Interest Expense - Partnership
				vm.syncIntExpPartnership = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "jcd_key":vm.jcd_key};				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N'){
							var params = {"action_code" : 'rh7mdk', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario};		    			
							JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(result) {									
								/*if (result.errorMessage && result.errorMessage !== 'null') {
									AlertService.add("error", result.errorMessage, 4000);
								}*/
							});
							AlertService.add("success", "Sync Process for Interest Expense (Partnership) is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Process. Tax Return has already been filed for selected Tax Year.", 4000);
						}
					});
				}
				
				//Call the Sync process of R&D Consolidated
				vm.syncRnDConsol = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, 'p_scenario' : vm.scenario, "p_consol_group_key": '1', "p_jcd_key":vm.jcd_key};				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N' && vm.is_locked[0].SCENARIO_DESC != 'EXAM'){
							var params = {"sso_id":USER_SETTINGS.user.sso_id,"action_code" : '5ddaxy', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario,"jcd_key":vm.jcd_key};		    			
							//JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(result) {	
								/*if (result.errorMessage && result.errorMessage !== 'null') {
									AlertService.add("error", result.errorMessage, 4000);
								}*/
							//});
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {												
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});
							AlertService.add("success", "Sync Process for Research & Development (Consolidated) is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Research & Development Process for selected Tax Year and Scenario.", 4000);
						}
					});
				}
				
				//Call the Sync process of R&D Partnership
				vm.syncRnDPartnership = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "jcd_key":vm.jcd_key };				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N'){
							var params = {"action_code" : 'k6jozq', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario};		    			
							JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(result) {
								/*if (result.errorMessage && result.errorMessage !== 'null') {
									AlertService.add("error", result.errorMessage, 4000);
								}*/
							});
							AlertService.add("success", "Sync Process for Research & Development (Partnership) is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Process. Tax Return has already been filed for selected Tax Year.", 4000);
						}
					});
				}
				
				//Call the Sync process of 1118 Sync
				vm.sync1118Process = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "p_jcd_key":vm.jcd_key, 'p_scenario' : vm.scenario };				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N'){
							var params = {"sso_id":USER_SETTINGS.user.sso_id, "action_code" : '0u25qx', "filing_key":'1', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario, "jcd_key":vm.jcd_key  };		    			
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {	
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});	
							AlertService.add("success", "Sync Process for Form 1118 is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Process. Tax Return has already been filed for selected Tax Year.", 4000);
						}
						
					});
				}
					
				
				vm.getFilingGroupDropDownWoPship = function() {
					let params = {
			                "action_code": '0udsuc',
			                "scenario": vm.selectedScenario,
			                "tax_year":  vm.selectedTaxYear
			            };
			            	JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
			                .then(function(data) {
			                	console.log(data);
			                	vm.filingGroupDropWoPship = data.jsonObject;
			                	vm.showFilingGroup = true;
			                });
				}
				
				//Call the Sync process of SECTION 250
				function isValidSec250Sync() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return false;
					}	
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return false;
					}
					if(vm.filingGroupSection == undefined || vm.filingGroupSection == null || vm.filingGroupSection == ''){
						AlertService.add("error", "Please select a valid Filing Group .", 5000);
						return false;
					}
					
					if(vm.filerEntitySection == undefined || vm.filerEntitySection == null || vm.filerEntitySection == '') {
						AlertService.add("error", "Please select a valid Filing Entity .", 5000);
						return false;
					}
				}

				vm.syncSec250Process = function() {
					if(isValidSec250Sync() == false) {
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": vm.filingGroupSection, "p_jcd_key":vm.jcd_key, 'p_scenario' : vm.scenario,"FILER_LE_KEY":vm.filerEntitySection };				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N'){
							var params = {"sso_id":USER_SETTINGS.user.sso_id, "action_code" : '85xrj3', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario , "jcd_key":vm.jcd_key,"consol_group_key": vm.filingGroupSection,"FILER_LE_KEY": vm.filerEntitySection, "FEDERAL_STATE_FG" : 'F' };	 			
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {	
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});		
							AlertService.add("success", "Sync Process for Calculate Section 250 is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Process. Tax Return has already been filed for selected Tax Year.", 4000);
						}
					});
				}

				//Call the Sync process of POST SECTION 250
				vm.syncPostSec250Process = function() {
					if(isValidSec250Sync() == false) {
						return;
					}

					if (vm.selectedTaxYear < 2020) {
						AlertService.add("error", "Post-Sec.250 Sync is available only from tax year 2020!", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": vm.filingGroupSection, "p_jcd_key":vm.jcd_key, 'p_scenario' : vm.scenario,"FILER_LE_KEY":vm.filerEntitySection };				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N'){
							var params = {"sso_id":USER_SETTINGS.user.sso_id, "action_code" : 'ux0j8a', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario , "jcd_key":vm.jcd_key,"consol_group_key": vm.filingGroupSection,"FILER_LE_KEY": vm.filerEntitySection, "FEDERAL_STATE_FG" : 'F' };	 			
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {	
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});		
							AlertService.add("success", "Sync Process for Post-Section 250 is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Sync Process. Tax Return has already been filed for selected Tax Year.", 4000);
						}
					});
				}
				
				//Call the process to Rebuild FTC
				/*vm.rebuildFTCProcess = function() {
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "jcd_key":vm.jcd_key, "jcd_ta_key": vm.jcd_ta_key };				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N'){
							var params = {"action_code" : 'CHANGETHIS_ACTION_CODE', "p_tax_year": vm.selectedTaxYear, "p_scenario" : vm.selectedScenario  };	 			
							JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(result) {	
								//do nothing
							});		
							AlertService.add("success", "Process to Rebuild FTC is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Process to Rebuild FTC. Tax Return has already been filed for selected Tax Year.", 4000);
						}
					});
				}*/
			
				vm.autoTagProcess = function(){
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					
					if(vm.parentME == undefined || vm.parentME == null || vm.parentME && vm.parentME.length == 0){
						AlertService.add("error", "Please select the valid ME(s) .", 5000);
						return;
					} 
					
					if(vm.filingGroup == undefined || vm.filingGroup == null || vm.filingGroup == ''){
						AlertService.add("error", "Please select a valid Filing Group .", 5000);
						return;
					}
					
					if(vm.filerEntity == undefined || vm.filerEntity == null || vm.filerEntity && vm.filerEntity.length == 0) {
						AlertService.add("error", "Please select a valid Filing Entity .", 5000);
						return;
					}

					var params = {"sso_id":USER_SETTINGS.user.sso_id,"action_code" : 'zfwjr9', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario, "jcd_key":vm.jcd_key,"consol_group_key": vm.filingGroup,"FILER_LE_KEY":_.map(vm.filerEntity,'FILER_LE_KEY').join(),"me_key": _.map(vm.parentME,'PARENT_ME_KEY').join()};	 			
					var checkLockedbj= []; 
					for(var indx in vm.parentME){
						checkLockedbj.push({me_code:_.map(vm.parentME,'PARENT_ME_CODE')[indx],consol_group_key:vm.filingGroup});
					}
					
				///	checkLockedbj.push({me_code:_.map(vm.parentME,'PARENT_ME_CODE'),consol_group_key:vm.filingGroup});
					params.checkLockedbj = JSON.stringify(checkLockedbj);
					
					CoaDiagnosticsServiceFactory.saveAutoTag(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {	
						if (result.callSuccess != 1) {
							AlertService.add("error", result.errorMessage, 4000);
							return;
						}else
							AlertService.add("success", "Process to Create a Tag is initiated. Email will be sent, after the process is complete.", 4000);
					});		
				}
				//Auto COA DIAG Creation process

			vm.autoDiagProcess = function(){
					let acctKeys = "";
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select a valid Tax Year.", 5000);
						return;
					}
					if(vm.selectedScenario == undefined || vm.selectedScenario == null || vm.selectedScenario == ''){
						AlertService.add("error", "Please select a valid Scenario.", 5000);
						return;
					}
					if(vm.selectedFromAcctKey == undefined || vm.selectedFromAcctKey == null || vm.selectedFromAcctKey == ''){
						AlertService.add("error", "Please Enter Local Account key.", 5000);
						return;
					} else {
						vm.selectedFromAcctKey.map( info => { 
							acctKeys += info.ACCT_KEY + ",";
							console.log(info.ACCT_KEY)
						});
						
						if(acctKeys.endsWith(",")){
							acctKeys = acctKeys.substring(0,acctKeys.length-1);
						}
					}
					
					var lockparams = {"action_code": 'n10cpv', "tax_year": vm.selectedTaxYear, "p_consol_group_key": '1', "p_jcd_key":vm.jcd_key, 'p_scenario' : vm.scenario };				
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
						vm.is_locked = data.jsonObject;		
						console.log(" vm.is_locked :", vm.is_locked);
						
						if(vm.is_locked[0].LOCK_STATUS == 'N' && vm.is_locked[0].SCENARIO_DESC != 'EXAM'){
							var params = {"sso_id":USER_SETTINGS.user.sso_id, "action_code" : 'pnqj5w', "tax_year": vm.selectedTaxYear, "scenario" : vm.selectedScenario, " p_from_acct_key" : acctKeys.toString(), "jcd_key":vm.jcd_key  };	 			
							JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url+'/saveJsonObject', params).then(function(result) {	
								if (result.callSuccess != 1) {
									AlertService.add("error", result.errorMessage, 4000);
									return;
								}
							});
							vm.selectedFromAcctKey = '';
							AlertService.add("success", "Process to Fix a Acct Diag is initiated. Email will be sent, after the process is complete.", 4000);
						}else{
							AlertService.add("error", "Cannot proceed with Process to Fix a Acct Diag for selected Tax Year and Scenario.", 4000);
						}
					});					
				}
				
				
				
			}
			return controllers;
		});
