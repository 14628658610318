/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './1065ScheduleM2Ctrl',
    './1065ScheduleM2Service',

], function () {
    'use strict';

  
   return angular.module('app.1065-scheduleM2', ['app.1065ScheduleM2Ctrl','app.1065ScheduleM2Service'])

    });