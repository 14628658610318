define([
    'angular',
    './dashboard901MController',
    './dashboard901MService',
    
], function () {
    'use strict';
    return angular.module('app.m901Dashboard', ['app.dashboard901MController', 'app.dashboard901MService'])
        /*.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
            $stateProvider
                .state('workspace.901-m--dashboard-adjustment', {
                    url: '/901-m--dashboard-adjustment',
                    "templateUrl": 'app/components/Dashboard-901M/adjustmentScreen-tpl.html',
                    backdrop: 'static',
                //    "controller": "mdashboardCtrl as ctrl",
                    access: "",
                 //  "api": {"get":"loadGridJson.ge?action_id=284&grid_id=79"},
                   "noFilters":true,
                })
        }])*/

});