define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.process5713LinesController', [])

        .controller('process5713LinesController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter', 'process5713Factory', process5713LinesController]);
    	function process5713LinesController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter, process5713Factory) {
        var vm = this;

        vm.modalTitle = "Process 5713 Lines";
		vm.saveloader = false;
		vm.tags = [];
		vm.leKey = [];
		vm.leType = [];
		vm.submissionId = [];
		vm.filingGroupKey = [];
		vm.tags.push(workspaceFactory.activeScreen.filters.filterParams.submission_id);
		console.log(vm.tags);

		vm.process5713LinesData = function() {
			vm.saveloader = true;
			vm.showJsonLoader = true;
            var params = {"tax_year": workspaceFactory.activeScreen.filters.filterParams.tax_year, "submission_id": workspaceFactory.activeScreen.filters.filterParams.submission_id};

			return process5713Factory.process5713Lines(params).then(function (data) {
				if(data.callSuccess == "1" ){
					vm.saveloader = false;
					AlertService.add("success", "Successfully processed the 5713 lines data." , 4000);
					vm.cancel();
				}else{
					vm.saveloader = false;
					AlertService.add("error", data.errorMessage, 4000);
				}
            });
        };

		vm.removeTag = function (index) {
			vm.tags.splice(index, 1);
	  	};

		vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		
    }
});