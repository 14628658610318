define(
		[ 'angular',

	        './AttributeService',
		],
		function() {
			'use strict';
			var controllers = angular.module('app.AttributeCtrl', [])
			   .directive("limitToMax1", function() {
    return {
        link: function(scope, element, attributes) {
            var count = 0;
            element.bind('keypress', function(e) {
            	console.log()
                if (!(e.charCode >= 46 && e.charCode <= 57) || e.charCode === 47) {
                    e.preventDefault();
                }
                var itemValue = e.charCode !== 0 ? String.fromCharCode(e.charCode) : '';
                var actualValue = $(this).val().split('');
                var newVal = actualValue.splice(this.selectionStart, 0, itemValue).join();
                //$(this).val()+(e.charCode!==0?String.fromCharCode(e.charCode):'');

                if (String.fromCharCode(e.charCode) === ".") {

                    if ($(this).val().indexOf(".") != -1) {
                        e.preventDefault();
                    }
                }

                if ($(this).val().search(/(.*)\.[0-9][0-9]/) === 0 && newVal.length > $(this).val().length) {
                    e.preventDefault();
                }
            });
            element.on("keydown keyup", function(e) {
                var decimalData = element[0].value //element.val();
                /*if(decimalData<0 || decimalData>30){
                    console.log(" Its not in the range ..  so revert back ");
                    element[0].value=30;
                    
               }*/
                function countDecimalPlaces() {
                    var decimalPos = String(decimalData).indexOf('.');
                    if (decimalPos === -1) {
                        return 0;
                    } else {
                        return String(decimalData).length - decimalPos - 1;
                    }
                }



                // removeExtraDots();
                if (countDecimalPlaces() > 2) {
                    element.val(Number(decimalData).toFixed(2))
                }
                if (Number(element.val()) > Number(attributes.max) 
                	/*&&
                    e.keyCode != 46 // delete
                    &&
                    e.keyCode != 8 // backspace */
                ) {
                    e.preventDefault();
                    element.val(attributes.max);
                }

            });
        }
    };
})               
					.controller(
							'attributeData',
							[ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'GlobalService', 'AlertService','$timeout', 'workspaceFactory','USER_SETTINGS', 'GENERAL_CONFIG','$uibModalInstance', 'AttributeServiceFactory', 'gridData','rowData','colData','SERVER_CONFIG', 'GridFactory','$uibModal','ModalFactory',
									attributeData ])

			/**
			 * @Author: Abhiruchi Sharma
			 */
			function attributeData($rootScope, $scope, $state, $log,
					JsonObjectFactory, GlobalService, AlertService, $timeout, workspaceFactory, USER_SETTINGS, GENERAL_CONFIG,
					$uibModalInstance, AttributeServiceFactory, gridData,rowData,colData,SERVER_CONFIG,GridFactory,$uibModal,ModalFactory) {

				var vm = this;
				vm.loadingData = true;
				vm.userSettings = USER_SETTINGS;
				vm.rowData = rowData;
				vm.colData = colData;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.RADIO_TYPE;
				vm.RADIO_TYPE1;
				vm.RADIO_TYPE2;
				vm.sicCodes = [];
				vm.sicCodeData = [];
				vm.addrepoint = [];
				vm.addRepointClicked = false;
				vm.defaultsMultipleSelected = [];
				vm.selectedAttributeRow = [];
				vm.newAttributeRow = [];
				vm.gridData = {};
				vm.countryData = [];
				vm.selectedBaseBasketCode = [];
				vm.attribDesc = [];
				vm.selectedMethodCode;
				vm.tranCatgCount = 0;
				vm.methodChangeCount = 0;
				vm.baseBasketSelect = [];
				vm.baseTypeSelect =[];
				vm.allGPTransCategoryData =[];
				vm.tempRowUpdated = null;
				vm.isSourceFlag = false;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                //Get the Default Current Year
                vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;	
				vm.filteredTaxYearForCreditable = [];
				vm.methodChangedFlag = false;
				vm.isSaveClicked = false;
				vm.isLengthZero = false;
				vm.editCount = null;
				vm.editCountCheckForActualData = 0;
				vm.resetOption = false;
				vm.isResetClicked = false;
				vm.methodChangedMore = 0;
			//	vm.filteredTaxYear=[];
				var tag_key;
				vm.selectedFDIIForAllocate = undefined;
				vm.selectedBaseBasketAllocate = undefined;
				vm.selectedBaseFdiiCodesCopy = undefined;
				vm.selectedBaseBasketCodeCopy = undefined;
				vm.taxKeySeq = null;
				vm.isError = false;
				vm.transCatgChangedFlag = false;

				vm.flagForNewUpdate = -1;
				vm.isMethodChangedManually = false;
				vm.isMethodChangedManuallyEdit = false;
				vm. EditCount = -1;
				vm.manualTransCategory = false;
				vm.QARScenario = 'N';


				vm.AllocateMethodTransCatg = false;

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				vm.checkForReset = false;
				
		        if($uibModalInstance) {
		            vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
		            vm.cancel = function () {
		                $uibModalInstance.close();
		                vm.modal_name = null;
		            };
		        } else {
		            vm.modal_name = null;
		        }

							
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}	
						
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].SCENARIO_DESC == 'EXAM'){
							vm.QARScenario = 'Y';
							if(vm.gridDataMultipleSelect.length == 0){
								vm.cancel();										
								AlertService.add("error","Create Tag is not available for selected Scenario." ,'4000');
								return;
							}
						}
	
					});
							
				});
				
				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;

					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.rowData){
						if (vm.gridDataMultipleSelect != undefined) {
							
							console.log(vm.gridDataMultipleSelect,"gridDataMultipleSelect");
							
							if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit'){
								vm.selectedAttributeRow.push(vm.gridDataMultipleSelect.rowData);
							}
							vm.editCount = 0;
							vm.methodChangeCount = 0;
						}
					}else if(vm.gridDataMultipleSelect.length > 0){
						AlertService.add("error", "Please select Create Tag option from actions after un-selecting the batch update.",'4000');
						/*var args = {};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);*/
						throw new Error("You cannot create a new tag using Batch Update.");
						return;
					}
					
					

					
					
				}
								
				
				vm.reset = function(type) {
					if(type === 'add'){
						vm.selectedCodeCombination = undefined;
						vm.selectedGrossIncExp = undefined;
						vm.selectedTransCat = undefined;
						vm.flagForNewUpdate = -1;
						vm.isMethodChangedManually = false;
						vm.isMethodChangedManuallyEdit = false;
					}else{
						vm.editCountCheckForActualData = 0;
						vm.isResetClicked = true;
						vm.tranCatgCount = 0;
						vm.methodChangeCount = 0;
						vm.editCount = 1;
						vm.loadingData = true;
						vm.resetOption = true;
						vm.checkForReset = false;
						vm.selectedBeatTypeCode = '';
						vm.isMethodChangedManuallyEdit = false;
						//vm.resetOption = true;
					vm.getCodeMastData();
					vm.getTransactionCategoriesData();
					 vm.getAttribMast();
					vm.prePopulateData();
					
					//vm.editCount = 1;
					setTimeout(function(){
						
						vm.validations();
						vm.isMethodChangedManuallyEdit = false;
						//vm.resetOption = false;
					},100);
					
					/*setTimeout(function(){
						
						// vm.isResetClicked = false;
					},10000);*/
				
					
					}	
						
						
			               
					
					
					
				}

				function init() {
				//	vm.getCodeMastData();
				}
				
// getting all the FTC CODE MAST to populate in drop downs
				vm.codeMastDataTempData = null;
				vm.getCodeMastData = function() {
					var params = {
						"action_code" : '31hizx',
						"cc_key" : (vm.gridDataMultipleSelect.rowData)? vm.gridDataMultipleSelect.rowData.COMBINATION_KEY: vm.selectedCodeCombination.KEY,
						'p_scenario':vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	// vm.editCount = 0;
			                	vm.codeMastDataTempData = data.jsonObject;
			                	vm.basketCodeData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.basketCodeAllocateData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['SOURCE_CODE_ALLOCATE'], p.CODE_GROUP);  
			                		});
			                	_.remove(vm.basketCodeAllocateData, {CODE_DESCRIPTION: 'Default'});
			                	vm.fdiiCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['FDII_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.sicCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SIC_CODE_PER_ME'], p.CODE_GROUP);  
			                		});
			                	if(vm.sicCodeData.length == 1 ){
			                		vm.sicCodeData = _.filter(data.jsonObject, function(p){
				                		  return _.includes(['SIC_CODE'], p.CODE_GROUP);  
				                		});
			                	}
			                	vm.taxYearData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
			                	});	
			                	vm.sourceTypeCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SOURCE_TYPE_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.projectTagCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['PROJECT_TAG_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.yesNoData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['YES_NO'], p.CODE_GROUP);  
			                		});
			                	vm.beatTypeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BEAT_TYPE_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.relatedCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['RELATED_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.countryCodeData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
			                	});
			                	vm.htkoData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.fsiData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
			                		});
			                	/*vm.methodCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['METHOD_CODE'], p.CODE_GROUP);  
			                		});*/
			                	vm.baseFDIIData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['FDII_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.baseBasketData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['SOURCE_CODE'], p.CODE_GROUP);  
			                		});
			                	_.remove(vm.baseBasketData, {CODE_DESCRIPTION: 'Default'});
			                	vm.baseLevelData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_LEVEL_CODE'], p.CODE_GROUP);  
			                		});
			                	vm.baseEntityData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_ENTITY_CODE'], p.CODE_GROUP);  
			                		});
			                	
			                	vm.baseBasketTypeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_BASKET_CODE'], p.CODE_GROUP);  
		                		});
			                	vm.baseTypeCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_TYPE_CODE'], p.CODE_GROUP);  
		                		});
			                	
			                	vm.basefdiiSingleMultiData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_FDII_CODE'], p.CODE_GROUP);  
		                		});
			                	
			                	vm.lineNoCodeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['LINE_NO_CODE'], p.CODE_GROUP);  
		                		});
// For Edit, need to pre populate the selected data, do validations and set the default values		
			                	vm.getAttribNamebyGieName();
			                }
						});
				}
				
				
				vm.getAttribNamebyGieName = function() {
					if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit')
						var gie_name = vm.selectedAttributeRow[0].GROSS_INC_EXP;
					else
						var gie_name = vm.selectedGrossIncExp.GIE_NAME;
					
					var params = {
							"action_code" : '6qd7am',
							"gie_name" : gie_name
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.getAttribToDisplay = data.jsonObject;
			               // 	vm.newList  = angular.copy(data.jsonObject);
			                	
			                	vm.newArray = vm.getAttribToDisplay.map( function( el ){ 
	                                return el.ATTRIB_DISPLAY_NAME; 
	                               });
			                if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit')
			                	vm.getTransactionCategoriesData();	
			                vm.getAttribMast();
			                
			        //        vm.loadingData = false;
			                
			                }
						});
				}	

				// Get all the transaction categories for a given GIE_KEY			
				vm.getTransactionCategoriesData = function() {
					var params = {
							"action_code" : '1sdudl',
							"cc_key" : vm.selectedAttributeRow[0].COMBINATION_KEY,
							"gie_name" : vm.selectedAttributeRow[0].GROSS_INC_EXP,
							'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
							
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.baseTypeData = data.jsonObject;
			                	console.log("edit new");
			                	vm.transCategoryData = data.jsonObject;//TRANS_CATG_NAME
			                	var exists =_.find(vm.transCategoryData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].TRANS_CATG_NAME]) 
			                	if(exists != undefined)
			                		vm.selectedTransCat = exists.CODE_KEY;
									//vm.resetData["selectedTransCat"] = exists.CODE_KEY;
			                	vm.updateCountryField();
			                }
						});
				}
				
				// Get all the base Data for the transaction Category selected			
				vm.getBaseData = function() {
					
					if(vm.selectedAttributeRow[0] != undefined && !vm.manualTransCategory){
						var exists =_.find(vm.transCategoryData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].TRANS_CATG_NAME]) 
						if(exists != undefined)
							vm.selectedTransCat = exists.CODE_KEY;
					}
					
					var params = {
							"action_code" : 'py0jb5',
							"trans_catg_name" :vm.selectedTransCat,
							'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
							
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage !== 'null' && data.errorMessage.length > 0 ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	
			                	vm.AllocateMethodTransCatg = false;
			                	
			                	vm.baseFDIITempData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_FDII'], p.CODE_GROUP);  
			                		});
			                	
			                	var selectedBaseFdiiCodesTemp = [];
			                	 if(data.jsonObject && data.jsonObject.length > 0 && vm.baseFDIITempData.length > 0 ){
	                                    data.jsonObject.filter(function(data){
	                                        vm.baseFDIITempData.filter(function(mainData){
	                                            if(mainData.CODE_KEY === data.CODE_KEY){
	                                            	selectedBaseFdiiCodesTemp.push(mainData);
	                                            }
	                                            
	                                            
	                                        })
	                                    });
	                                   
	                                    vm.selectedBaseFdiiCodes = uniqueData(selectedBaseFdiiCodesTemp,'CODE_KEY');
	                                    vm.selectedBaseFdiiCodesCopy = angular.copy(vm.selectedBaseFdiiCodes);
	                                } else {
	                                	 vm.selectedBaseFdiiCodes = angular.copy(vm.baseFDIIData);
		                                 vm.selectedBaseFdiiCodesCopy =  angular.copy(vm.baseFDIIData);
	                                }
			                	
			                	
			                	vm.baseBasketTempData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_BASKET'], p.CODE_GROUP);  
			                		});
			                	
			                	var selectedBaseBasketCodeTemp =[];
		                		   if(data.jsonObject && data.jsonObject.length > 0 && vm.baseBasketTempData.length > 0 ){
	                                    data.jsonObject.filter(function(data){
	                                        vm.baseBasketTempData.filter(function(mainData){
	                                            if(mainData.CODE_KEY === data.CODE_KEY){
	                                            	selectedBaseBasketCodeTemp.push(mainData);
	                                            }
	                                        })
	                                    });
	                                    vm.selectedBaseBasketCode = uniqueData(selectedBaseBasketCodeTemp,'CODE_KEY');
	                                    vm.selectedBaseBasketCodeCopy = angular.copy(vm.selectedBaseBasketCode);
	                                } else {
	                                	vm.selectedBaseBasketCode = angular.copy(vm.baseBasketData);
	                                    vm.selectedBaseBasketCodeCopy = angular.copy(vm.baseBasketData);
	                                }
			                	
			                	vm.baseTypeTempData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BASE_TYPE'], p.CODE_GROUP);  
		                		});
			                	
			                	var selectedBaseTypeCodeTemp =[];
			                	
			                	if(data.jsonObject && data.jsonObject.length > 0 && vm.baseTypeTempData.length > 0 ){
			                		vm.selectedBaseTypeCode = [];
                                    data.jsonObject.filter(function(data){
                                        vm.baseTypeTempData.filter(function(mainData){
                                            if(mainData.CODE_KEY === data.CODE_KEY){
                                            	selectedBaseTypeCodeTemp.push(mainData);
                                            }
                                        })
                                    });
									//vm.changeDataCheckAgain = false;
									/*$scope.$applyAsync(function () {*/
                                    vm.selectedBaseTypeCode = uniqueData(selectedBaseTypeCodeTemp,'CODE_KEY');
									vm.selectedBaseTypeCodeTemp = uniqueData(selectedBaseTypeCodeTemp,'CODE_KEY');
									vm.transCatgChangedFlag = true;
									///vm.changeDataCheckAgain = true;
									///console.log(vm.selectedBaseTypeCode,"vm.selectedBaseTypeCode");
									//});
                                } else {
                                	 vm.selectedBaseTypeCode = angular.copy(vm.baseTypeData);
 									vm.selectedBaseTypeCodeTemp = angular.copy(vm.baseTypeData);
 									
 									console.log("edit new");
                                }
			                	
			                	
			                	
			                /*************************/
			                	if((_.filter(data.jsonObject, function(p){  return _.includes(['BASE_LEVEL_CODE'], p.CODE_GROUP);  }))[0] != null)
				                	vm.selectedBaseLevelCode = (_.filter(data.jsonObject, function(p){ return _.includes(['BASE_LEVEL_CODE'], p.CODE_GROUP); }))[0].CODE_KEY;
			                	
			                	if( (_.filter(data.jsonObject, function(p){  return _.includes(['BASE_ENTITY_CODE'], p.CODE_GROUP);  }))[0] != undefined)
			                	vm.selectedBaseEntityLevelCode = (_.filter(data.jsonObject, function(p){ return _.includes(['BASE_ENTITY_CODE'], p.CODE_GROUP);  }))[0].CODE_KEY;
			                	
			                	if((_.filter(data.jsonObject, function(p){  return _.includes(['METHOD_CODE'], p.CODE_GROUP);  }))[0] != undefined){
			                	   	vm.selectedMethodTempCode = (_.filter(data.jsonObject, function(p){ return _.includes(['METHOD_CODE'], p.CODE_GROUP);  }))[0].CODE_KEY;
			                	   	if((_.filter(data.jsonObject, function(p){ return _.includes(['METHOD_CODE'], p.CODE_GROUP);  }))[0].CODE_DESCRIPTION == 'Allocate')
		                    			{
			                	   			vm.AllocateMethodTransCatg = true;
			                	   			vm.selectedBaseBasketCode = vm.selectedBaseBasketCode[0].CODE_KEY;
			                	   			vm.selectedBaseFdiiCodes = vm.selectedBaseFdiiCodes[0].CODE_KEY;
		                    			}
			                	}
			             
			                	if(vm.methodCodeData != undefined){
			                		var exists = _.find(vm.methodCodeData , ['CODE_KEY',vm.selectedMethodTempCode]);
			                    	if(exists != undefined){
			                    		vm.selectedMethodCode = exists;                    		
			                    		
			                    		
			                    	}
			                	}
			                	
			                	 vm.flagForNewUpdate++;
			                	 vm.baseFDIIData = angular.copy(vm.baseFDIIData);
	                    		 vm.baseFDIIDataCopy = angular.copy(vm.baseFDIIData);
			                	 vm.baseBasketData = angular.copy(vm.baseBasketData);
			                	 vm.baseBasketDataCopy = angular.copy(vm.baseBasketData);
			                	 vm.baseTypeData = angular.copy(vm.baseTypeData);
			                	 vm.baseTypeDataCopy = angular.copy(vm.baseTypeData);
			                	 console.log("edit new");
			                	
		                            
			                	vm.loadingData = false;
			                	/*setTimeout(() => {
			                		console.log(vm.selectedBaseFdiiCodes,"check",vm.baseFDIIData);
			                		vm.flagForNewUpdate++;
								}, 2000);*/
			                	
			                	/*  $scope.$applyAsync(function () {
			                		  vm.selectedBaseFdiiCodes = [];
			                		  vm.loadingDataAdd = true;
			                		  setTimeout(() => {
			                			  vm.selectedBaseFdiiCodes = angular.copy(vm.selectedBaseBasketCodeCopy);
			                			 // vm.baseFDIIData = angular.copy(vm.baseFDIIData);
			                			  vm.loadingDataAdd = false;
			                		  }, 20000);
                                  });*/
			                }
						});
				}
				
				
				vm.changedTCategory = function(){
					vm.tranCatgCount = vm.tranCatgCount +  1;
				}
				vm.changedMInfo = function(){
					if(!vm.isResetClicked)
					vm.methodChangeCount = vm.methodChangeCount + 1;
				}
				vm.changeSelectedBeatWithHold = function() {
					if ( vm.selectedBeatWithhold && (vm.selectedBeatWithhold < 0 || vm.selectedBeatWithhold > 100)) {
						AlertService.add("error", "Please select BEAT Withhold between 0 to 100 only ",5000);
						vm.isError = true;
					} else {
						vm.isError = false;
					}
				}
			
				//Base on the transaction category , we need to remove and display below attribute name for Gross Profit
				vm.updateCountryField = function(manual) {
//					vm.selectedRelatedCodeDesc  ='';
					vm.defaultBaseTypeCodeInfoSet= false;
					vm.manualTransCategory = manual ? true : false;
					if(vm.loadingDataAdd == undefined)
						vm.loadingDataAdd = true;
					vm.disableBaseLevels = '';
					vm.isMethodChangedManually = false;
					vm.isMethodChangedManuallyEdit = false;
					
					var exists =_.find(vm.transCategoryData, ['CODE_KEY', vm.selectedTransCat]) 
                	if(exists != undefined)
                		{
                			vm.transCatgIsChangedFlag = exists.IS_CHANGED;
                			vm.transCatgMapDesc = exists.MAP_DESC;
                			vm.showWarning = exists.SHOW_WARINING;
                		}
					
					if(vm.showWarning == 'Y' && vm.gridDataMultipleSelect.length == 0){
						var templateUrl = 'app/templates/modals/confirm-action.html';
							$scope.confirmModal = $uibModal.open({
								templateUrl: templateUrl,
								scope: $scope,
								windowClass: 'custom',
								controller: ['$scope', '$stateParams', '$uibModalInstance',
									function ($scope, $stateParams, $uibModalInstance) {
										$scope.header = "Confirm";
										$scope.message = "Selected Transaction Category exists for another tag for same entity?"
										$scope.confirm = function () {
											
											$scope.closeWindow(false);
											$uibModalInstance.close();
											
											if(vm.transCatgIsChangedFlag == 'Y' && vm.gridDataMultipleSelect.length == 0){
												vm.showTransCatgWarning();
												}
											/*$scope.closeWindow(false);
											$uibModalInstance.close();*/
									}
										$scope.cancel = function () {
										/*	$rootScope.$emit('gridUpdate', args);*/
											$uibModalInstance.close();
											vm.cancel();
											return;
										}

										$scope.$on('$destroy', function () {
											//Do Nothing
										});
								}]
								});
								$scope.closeWindow = function () {
								}
							}
					
					if(vm.transCatgIsChangedFlag == 'Y' && vm.gridDataMultipleSelect.length == 0 && vm.showWarning == 'N'){
						vm.showTransCatgWarning();
						}
	
					if(vm.transCatgMapDesc == 'Y' && vm.transCatgIsChangedFlag == 'N') {
						vm.getBaseData();
					} else if(vm.rowData && vm.transCatgMapDesc == 'Y' && vm.transCatgIsChangedFlag == 'Y') {
						var exists = _.find(vm.methodCodeData , ['CODE_DESCRIPTION',"Best Method - FDII"]);
                    	if(exists != undefined){                    		
                    		vm.selectedMethodCode = exists;
                    	}
                    	
                		 vm.selectedBaseFdiiCodesCopy = angular.copy(vm.baseFDIIDataCopy);
	                	 vm.selectedBaseBasketCodeCopy = angular.copy(vm.baseBasketDataCopy);
	                	 vm.selectedBaseTypeCodeTemp = angular.copy(vm.baseTypeDataCopy);
	                	vm.flagForNewUpdate++;
	                	console.log("edit new");
                    	
					} 
					
					
						
					
					if(vm.transCatgIsChangedFlag == 'N'  || (vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit'))
						vm.removeSetAttributes()
					
					/*if(vm.transCatgMapDesc != 'Y' && !vm.rowData) {
						vm.selectedBaseFdiiCodes = [];
						vm.selectedBaseBasketCode = [];
						vm.selectedBaseTypeCode = [];
						vm.selectedBaseFdiiCodes =  angular.copy(vm.baseFDIIData);
						vm.selectedBaseBasketCode = angular.copy(vm.baseBasketData);
						vm.selectedBaseTypeCode = angular.copy(vm.baseTypeData);

					}*/
					
				}
				
				vm.showTransCatgWarning = function (){
					var templateUrl = 'app/templates/modals/confirm-action.html';
					$scope.confirmModal = $uibModal.open({
						templateUrl: templateUrl,
						scope: $scope,
						windowClass: 'custom',
						controller: ['$scope', '$stateParams', '$uibModalInstance',
							function ($scope, $stateParams, $uibModalInstance) {
								$scope.header = "Confirm";
								$scope.message = "Trans category definition is changed and amount will be synced by nightly process. Do you want to proceed in creating a tag?"
								$scope.confirm = function () {
									
									$scope.closeWindow(false);
									$uibModalInstance.close();
									
									vm.removeSetAttributes();
									vm.getBaseData();
									/*$scope.closeWindow(false);
									$uibModalInstance.close();*/
							}
								$scope.cancel = function () {
								/*	$rootScope.$emit('gridUpdate', args);*/
									$uibModalInstance.close();
									vm.cancel();
									return;
								}

								$scope.$on('$destroy', function () {
									//Do Nothing
								});
						}]
						});
						$scope.closeWindow = function () {
						}
				}
				
				vm.removeSetAttributes = function (){
					var exists =_.find(vm.transCategoryData, ['CODE_DESCRIPTION', 'Services']) 
                	if(exists != undefined)
                		vm.transCatgKeyService = exists.CODE_KEY;
					
					var exists =_.find(vm.transCategoryData, ['CODE_DESCRIPTION', 'Prorate Variance']) 
                	if(exists != undefined)
                		vm.transCatgKeyProrate = exists.CODE_KEY;
					
					vm.selectedGieName = (vm.selectedAttributeRow[0] != undefined ? vm.selectedAttributeRow[0].GROSS_INC_EXP : vm.selectedGrossIncExp.GIE_NAME );
					
					if(vm.getAttribToDisplay == undefined)
						vm.getAttribNamebyGieName();
				
					if(vm.selectedGieName == 'GPROFIT' && vm.transCatgKeyProrate == vm.selectedTransCat  && vm.getAttribToDisplay != undefined) {
						//vm.selectedCountryCode=undefined; 
						vm.newList  = angular.copy( vm.getAttribToDisplay);
						///_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Country'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'SIC Code'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Basket'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'FDII'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'HTKO'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'FSE'});
						
						vm.newArray = vm.newList.map( function( el ){ 
                            return el.ATTRIB_DISPLAY_NAME; 
                           });
						vm.disableBaseLevels = 'Yes';
						
					}else if(vm.selectedGieName == 'GPROFIT' && vm.transCatgKeyService == vm.selectedTransCat && vm.getAttribToDisplay != undefined){
						
						
						vm.newList  = angular.copy( vm.getAttribToDisplay);
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Basket'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base FDII'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Level'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Type'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Entity Level'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Method'});
						
	                	vm.newArray = vm.newList.map( function( el ){ 
                            return el.ATTRIB_DISPLAY_NAME; 
                           });
					}else if(vm.selectedGieName == 'GPROFIT' && vm.transCatgKeyService != vm.selectedTransCat && vm.transCatgKeyService != vm.selectedTransCat  && vm.getAttribToDisplay != undefined){
						//vm.selectedCountryCode=undefined; 
						vm.newList  = angular.copy( vm.getAttribToDisplay);
						///_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Country'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Basket'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base FDII'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Level'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Type'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Entity Level'});
						_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Method'});
						
	                	
	                	vm.newArray = vm.newList.map( function( el ){ 
                            return el.ATTRIB_DISPLAY_NAME; 
                           });

					}else{
						if(vm.getAttribToDisplay != undefined){
							if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== "Allocate" 
								&& vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== "Interest Expense"){
								
									vm.newArray  = vm.getAttribToDisplay.map( function( el ){ 
			                            return el.ATTRIB_DISPLAY_NAME; 
			                        });
								
							
							}
						}
					}
				}
				

				vm.getAttribMast = function() {
					var params = {
							"action_code" : '2nk5nz',
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.attribMastData = data.jsonObject;
			                	vm.getTradingPartnerData();
			                }
						});
				}
				
				
				// Get all the Trading partner data (all the data from mvgt for a given me)				
				vm.getTradingPartnerData = function() {
					var params = {
							"action_code" : 'ayqh3u',
							'p_scenario':   vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.tradingPartnerCodeData = data.jsonObject;
			                	vm.beatYesforeignTradingPartnerData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['RELATED_FGN_BEAT_YES'], p.CODE_GROUP);  
			                		});
			                	
			                	vm.beatNoforeignTradingPartnerData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['RELATED_FGN_BEAT_NO'], p.CODE_GROUP);  
			                		});
			                	
			                	vm.domesticTradingPartnerData = _.filter(data.jsonObject, function(p){
			                		return _.includes(['RELATED_DOMESTIC'], p.CODE_GROUP);  
			                		});
			                	
			                /*	
			                		
			                	vm.foreignTradingPartnerCodeData = angular.copy(vm.tradingPartnerCodeData);
			                	_.remove(vm.foreignTradingPartnerCodeData, {LE_COUNTRY: 'US'});
			                				                	
			                	vm.domesticTradingPartnerCodeData = angular.copy(vm.tradingPartnerCodeData);
			                		_.filter(vm.tradingPartnerCodeData, function(o){return o.LE_COUNTRY == "US"})*/
			                	
			                	vm.getMethod();
			                }
						});
				}


				//Getting the Method as it is not coming from FTC Code Mast Table		
				vm.getMethod = function() {
					var params = {
							"action_code" : 'lwtsx1',
							"gie_key" : (vm.selectedAttributeRow[0] != undefined ? vm.selectedAttributeRow[0].GIE_KEY : vm.selectedGrossIncExp.GIE_KEY),
							'p_scenario' : vm.scenario
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.methodCodeData = data.jsonObject;
			                	vm.getAllTransCatgExceptExp();
			                	vm.getDefaultsPopulateData();
			                }
						});
				}
				
				$scope.$watch('vm.baseFDIIData',function(){
                    console.log("check here",vm.baseFDIIData);
                });
				
				// Get Defaults value to populate all the default fields
				vm.getDefaultsPopulateData = function() {
					
					if(vm.selectedAttributeRow[0] != undefined)
						vm.cckey = vm.selectedAttributeRow[0].COMBINATION_KEY;
					else{
							vm.cckey = (vm.gridDataMultipleSelect == undefined )? vm.gridDataMultipleSelect.rowData.COMBINATION_KEY: vm.selectedCodeCombination.KEY;
							vm.addFlag = true;
					}
			
					var params = {
							
							"action_code" : '4tivvr',
							"gie_key" : (vm.selectedAttributeRow[0] != undefined ? vm.selectedAttributeRow[0].GIE_KEY : vm.selectedGrossIncExp.GIE_KEY),
							"cc_key" : vm.cckey,
							'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
							
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.allDefaultData = data.jsonObject;
			                	
			// setting up the Default Method   
			                	//if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.length == 0)
			                	if(vm.selectedSicCode == undefined || vm.selectedSicCode == '' || vm.addFlag == true){
			                		var defaultValues = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'SIC_CODES'])
			                		if(defaultValues){
									var exist =_.find(vm.sicCodeData, ['CODE_DESCRIPTION', defaultValues.CODE_DESCRIPTION]) 
									if(exist){
										vm.selectedSicCode = exist.CODE_KEY
									}
			                		}
			                	}
			                	//if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.length == 0)
			                	if(vm.selectedBasketCode == undefined || vm.selectedBasketCode == '' || vm.addFlag == true){
			                		var defaultValues = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'BASKET'])
			                		if(defaultValues){
									var exist =_.find(vm.basketCodeData, ['CODE_DESCRIPTION', defaultValues.CODE_DESCRIPTION]) 
									if(exist){
										vm.selectedBasketCode = exist.CODE_KEY
									}
			                		}
			                	}
			                	//if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.length == 0)
			                	if(vm.selectedFdiiCode == undefined || vm.selectedFdiiCode == '' || vm.addFlag == true){
			                		var defaultValues = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'FDII'])
			                		if(defaultValues){
									var exist =_.find(vm.fdiiCodeData, ['CODE_DESCRIPTION', defaultValues.CODE_DESCRIPTION]) 
									if(exist){
										vm.selectedFdiiCode = exist.CODE_KEY
									}
			                		}
			                	}
			                	if(vm.selectedMethodCode == undefined || vm.selectedMethodCode == '' ){ /*|| vm.addFlag == true){*/
			                		var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'METHOD'])
			                		if(exists != undefined){
			                			vm.selectedMethodCode1 = exists.CODE_DESCRIPTION;
			                		
			                		var exists = _.find(vm.methodCodeData , ['CODE_DESCRIPTION',vm.selectedMethodCode1]);
			                    	if(exists != undefined){
			                    		
			                    		vm.selectedMethodCode = exists;
			                    		
			                    	}
			                	  }
			                	}
			                	
			                	if(vm.selectedMethodTempCode != undefined){
			                		var exists = _.find(vm.methodCodeData , ['CODE_KEY',vm.selectedMethodTempCode]);
			                    	if(exists != undefined){
			                    		vm.selectedMethodCode = exists;
			                    	}
			                		
			                	}
			                	
			                	// setting up the Default Base Level                   	
			                	if(vm.selectedBaseLevelCode == undefined ){ /*|| vm.addFlag == true){*/
			                		var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'BASE_LEVEL'])
			                		if(exists != undefined){
			                			vm.selectedBaseLevelCode1 = exists.CODE_DESCRIPTION;
			                		
		                			var exists = _.find(vm.baseLevelData , ['CODE_DESCRIPTION',vm.selectedBaseLevelCode1]);
			                    	if(exists != undefined){
			                    		vm.selectedBaseLevelCode = exists.CODE_KEY;
			                    	}
			                	}
			                	}	
			                	// setting up the Default Base Entity Level    	
			                	if(vm.selectedBaseEntityLevelCode == undefined){
			                		var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'BASE_ENTITY_LEVEL'])
			                		if(exists != undefined){
			                			vm.selectedBaseEntityLevelCode1 = exists.CODE_DESCRIPTION;
			                		
		                			var exists = _.find(vm.baseEntityData , ['CODE_DESCRIPTION',vm.selectedBaseEntityLevelCode1]);
			                    	if(exists != undefined){
			                    		vm.selectedBaseEntityLevelCode = exists.CODE_KEY;
			                    	}
			                	}
			                	}	
			                	// setting up the Default FDII    	
			                	/*if(vm.selectedFdiiCode == undefined){
			                		var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'FDII'])
			                		if(exists != undefined){
			                			vm.selectedFdiiCode1 = exists.CODE_DESCRIPTION;
			                		
		                			var exists = _.find(vm.fdiiCodeData , ['CODE_DESCRIPTION',vm.selectedFdiiCode1]);
			                    	if(exists != undefined){
			                    		vm.selectedFdiiCode = exists.CODE_KEY;
			                    	}
			                	}
			                	}	*/
			                	
			                	//Setting up default Base Basket

		                        if( vm.selectedBaseBasketCode == undefined || vm.selectedBaseBasketCode == ''){ /*|| vm.addFlag == true){*/
		                            vm.defaultBaseBasket= _.filter(data.jsonObject, function(p){
		                                return _.includes(['BASE_BASKET'], p.ATTRIB_NAME);
		                                });
		                            
		                            
		                            var selectedBaseBasketCodeTemp =[];
		                               if(vm.defaultBaseBasket && vm.defaultBaseBasket.length > 0 && vm.baseBasketData &&  vm.baseBasketData.length > 0 ){
		                                   vm.defaultBaseBasket.filter(function(data){
		                                    vm.baseBasketData.filter(function(mainData){
		                                        if(mainData.CODE_KEY === data.CODE_KEY){
		                                            selectedBaseBasketCodeTemp.push(mainData);
		                                        }
		                                    })
		                                });
		                                vm.selectedBaseBasketCode = uniqueData(selectedBaseBasketCodeTemp,'CODE_KEY');
		                                vm.selectedBaseBasketCodeCopy = angular.copy(vm.selectedBaseBasketCode);
		                            }
		                        }        
		                                
		                //Setting up default Base FDII

		                        if( vm.selectedBaseFdiiCodes == undefined || vm.selectedBaseFdiiCodes == ''){ /*|| vm.addFlag == true){*/
		                            
		                            vm.defaultBaseFdii= _.filter(data.jsonObject, function(p){
		                                return _.includes(['BASE_FDII'], p.ATTRIB_NAME);
		                                });
		                            
		                            var selectedBaseFdiiCodesTemp = [];
		                           if(vm.defaultBaseFdii && vm.defaultBaseFdii.length > 0 && vm.baseFDIIData && vm.baseFDIIData.length > 0 ){
		                               vm.defaultBaseFdii.filter(function(data){
		                                   vm.baseFDIIData.filter(function(mainData){
		                                	   if(mainData.CODE_KEY === data.CODE_KEY){
		                                   //    if(mainData.CODE_KEY+"" === data.CODE_KEY+""){
		                                       selectedBaseFdiiCodesTemp.push(mainData);
		                                       }


		                                   })
		                               });
		                              // vm.editCount = 1;
		                               vm.selectedBaseFdiiCodes = uniqueData(selectedBaseFdiiCodesTemp,'CODE_KEY');
		                               vm.selectedBaseFdiiCodesCopy = angular.copy(vm.selectedBaseFdiiCodes);
									  // vm.selectedBaseFdiiCodes = vm.resetData["selectedBaseFdiiCodes"];
		                           }
		                        }		                
		                
		                if(vm.selectedBeatCode == undefined || vm.addFlag == true){
		                	var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'BEAT'])
	                		if(exists != undefined){
	                			vm.selectedBeatCode1 = exists.CODE_DESCRIPTION;
	                		
                			var exists = _.find(vm.yesNoData , ['CODE_DESCRIPTION',vm.selectedBeatCode1]);
	                    	if(exists != undefined) { 
	                    		vm.selectedBeatCode = exists.CODE_KEY;
	                    		vm.selectedBeatCodeDesc = exists.CODE_DESCRIPTION;
	                		}
	                 		}
		             	}
		                
		                if(vm.selectedSourceTypeCode == undefined || vm.addFlag == true){
		                	var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'SOURCE_TYPE'])
	                		if(exists != undefined){
	                			vm.selectedSourceTypeCode1 = exists.CODE_DESCRIPTION;
	                		
                			var exists = _.find(vm.sourceTypeCodeData , ['CODE_DESCRIPTION',vm.selectedSourceTypeCode1]);
	                    	if(exists != undefined)
	                    		vm.selectedSourceTypeCode = exists.CODE_KEY;
							  // vm.resetData["selectedSourceTypeCode"] = exists.CODE_KEY;
								
	                 		}
		             	}
		                
		                if(vm.selectedRelatedCode == undefined || vm.addFlag == true){
		                	var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'RELATED_UNRELATED'])
	                		if(exists != undefined){
	                			vm.selectedRelatedCode1 = exists.CODE_DESCRIPTION;
	                		
                			var exists = _.find(vm.relatedCodeData , ['CODE_DESCRIPTION',vm.selectedRelatedCode1]);
	                    	if(exists != undefined){
	                    		vm.selectedRelatedCode = exists.CODE_KEY;
	                    		vm.selectedRelatedCodeDesc = exists.CODE_DESCRIPTION;
	                    	}
	                    		
	                 		}
		             	}
					
		                
		                if(vm.selectedCountryCode == undefined || vm.selectedCountryCode == '' || vm.addFlag == true){
		                	var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'COUNTRY'])
	                		if(exists != undefined){
	                			vm.selectedCountryCode1 = exists.CODE_DESCRIPTION;
	                		
                			var exists = _.find(vm.countryCodeData , ['CODE_DESC',vm.selectedCountryCode1]);
	                    	if(exists != undefined) { 
	                    		vm.selectedCountryCode = exists.CODE_KEY;
	                		}
	                 		}
		             	}
		                
		                if(vm.selectedLineNo == undefined || vm.selectedLineNo == '' || vm.addFlag == true){
		                	var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'LINE_NO'])
	                		if(exists != undefined){
	                			vm.selectedLineNo1 = exists.CODE_DESCRIPTION;
	                		
                			var exists = _.find(vm.lineNoCodeData , ['CODE_DESC',vm.selectedLineNo1]);
	                    	if(exists != undefined) { 
	                    		vm.selectedLineNo = exists.CODE_KEY;
	                		}
	                 		}
		             	}
		                
//			                	vm.loadingData = false;
		                }
						});
				}
				
				
				// Get all the transaction categories for Inc and Gross Profit				
				vm.getAllTransCatgExceptExp = function(whenLengthZero) {
					var params = {
							"action_code" : '9aszao',
							'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.allTransCategoryData = data.jsonObject;//TRANS_CATG_NAME
			                	
			                	var exists = _.find(vm.allTransCategoryData, ['GIE_NAME', 'GPROFIT'])
			                	if(exists != undefined)
			                		vm.allGPTransCategoryData = exists;
			                	
			                	vm.allGPTransCategoryData = _.filter(vm.allTransCategoryData, function(p){
			                		return _.includes(['GPROFIT'],  p.GIE_NAME);  
			                		})	
			                		if(whenLengthZero){
			                			vm.baseTypeData = vm.allGPTransCategoryData;
			                			vm.isLengthZero = !vm.isLengthZero;
			                			console.log("edit new");
			                		}
			                		
			                	vm.getFSECompDefData();		
			                //	vm.loadingData = false;
			                }
						});
				}			
				
				// Get the FSE data from Component Default for a given Code Combination			
				vm.getFSECompDefData = function() {
					
					if(vm.selectedAttributeRow[0] != undefined)
						vm.cckey = vm.selectedAttributeRow[0].COMBINATION_KEY;
					else
						vm.cckey = (vm.gridDataMultipleSelect.rowData)?vm.gridDataMultipleSelect.rowData.COMBINATION_KEY:vm.selectedCodeCombination.KEY;
			
					var params = {
							
							"action_code" : '8kj84y',
							"cc_key" : vm.cckey,
							'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
							
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	vm.fseCompDefaultData = data.jsonObject;
			                	if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit')
			                	{
			                		vm.prePopulateData();
			                	}
			                	vm.validations();
			                }
						});
				}
	
				
	// Pre populate the drop downs with the pre selected Data in EDIT			
				vm.prePopulateData = function() {
					var exists = _.find(vm.basketCodeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BASKET_0]);
                	if(exists != undefined)
                	{
                		vm.selectedBasketCode = exists.CODE_KEY;
                		vm.selectedBasketCodeDesc = exists.CODE_DESCRIPTION;
                        
                		//vm.selectedBasketCode = vm.resetData["selectedBasketCode"];
                	}
                	
                	var exists = _.find(vm.sicCodeData, ['CODE_DESCRIPTION', (vm.selectedAttributeRow[0].SIC_CODES_0 ? vm.selectedAttributeRow[0].SIC_CODES_0.split('[')[0]: null)]);
                	if(exists != undefined)
                		vm.selectedSicCode = exists.CODE_KEY;
                	    
                	    //vm.selectedSicCode = vm.resetData["selectedSicCode"];

                	
                	var exists = _.find(vm.fdiiCodeData , ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].FDII_0]);
                	if(exists != undefined){
                		vm.selectedFdiiCode = exists.CODE_KEY;
						//vm.resetData["selectedFdiiCode"] = exists.CODE_KEY;
					}
                	
                	var exists = _.find(vm.yesNoData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BEAT_0]);
                	if(exists != undefined){
                			vm.selectedBeatCode = exists.CODE_KEY;
                			vm.selectedBeatCodeDesc = exists.CODE_DESCRIPTION;
                		}
                	
                	var exists = _.find(vm.beatTypeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BEAT_TYPE_0]);
                	if(exists != undefined)
                		vm.selectedBeatTypeCode = exists.CODE_KEY;
                	
                	var exists = _.find(vm.relatedCodeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].RELATED_UNRELATED_0]);
                	if(exists != undefined)
                	{
                		vm.selectedRelatedCode = exists.CODE_KEY;
                		vm.selectedRelatedCodeDesc = exists.CODE_DESCRIPTION;
                	}
           
                	/*if(vm.selectedAttributeRow[0].RELATED_UNRELATED_0 == 'Related Foreign'){
                		var exists = _.find(vm.tradingPartnerCodeData, ['LE_NAME', vm.selectedAttributeRow[0].ACTUAL_TP_KEY_0]);
                		if(exists != undefined){
                			vm.selectedTradingPartnerCode = exists.COMBINATION_KEY;
                			vm.disableCountry = 'Yes'
                		}
                	}
                	
                	if(vm.selectedAttributeRow[0].RELATED_UNRELATED_0 == 'Related Domestic'){
                		var exists = _.find(vm.tradingPartnerCodeData, ['LE_NAME', vm.selectedAttributeRow[0].ACTUAL_TP_KEY_0]);
                		if(exists != undefined){
                			vm.selectedTradingPartnerCode = exists.COMBINATION_KEY;
                			vm.disableCountry = 'Yes'
                		}
                	}*/
                	
                	
                	if(vm.selectedAttributeRow[0].RELATED_UNRELATED_0 == 'Related Foreign' || vm.selectedAttributeRow[0].RELATED_UNRELATED_0 == 'Related Domestic'){
                		 vm.selectedTradingPartnerLEID = vm.selectedAttributeRow[0].ACTUAL_TP_KEY_0;
                		 vm.selectedTradingPartnerCode = vm.selectedAttributeRow[0].ACTUAL_TP_KEY_LENAME_0;
                		 
                		 vm.disableCountry = 'Yes';
                	}
                	
                	var exists =  ////_.find(vm.countryCodeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].COUNTRY_0.split('[')[0]]);
                				_.find(vm.countryCodeData, ['CODE_DESCRIPTION', (vm.selectedAttributeRow[0].COUNTRY_0 ? vm.selectedAttributeRow[0].COUNTRY_0.split('[')[0]: null)]);
                	 
                	if(exists != undefined)
                		vm.selectedCountryCode = exists.CODE_KEY;
                	
                	var exists = _.find(vm.htkoData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].HTKO_0]);
                	if(exists != undefined)
                		vm.selectedHtkoCode = exists.CODE_KEY;
                	
                	var exists = _.find(vm.fsiData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].FSE_0]);
                	if(exists != undefined)
                	{
                		vm.selectedFsiCode = exists.CODE_KEY;
                	}
                	
                	var exists = _.find(vm.sourceTypeCodeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].SOURCE_TYPE_0]);
                	if(exists != undefined)
                		vm.selectedSourceTypeCode = exists.CODE_KEY;
                	
                	var exists = _.find(vm.projectTagCodeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].PROJECT_TAGGING_0]);
                	if(exists != undefined)
                		vm.selectedProjectTaggingCode = exists.CODE_KEY;
                	
                	vm.selectedNotes = vm.selectedAttributeRow[0].NOTES_0;
                	
                	vm.selectedBeatWithhold = vm.selectedAttributeRow[0].BEAT_WITHHOLD_0;
                	
                	var exists = _.find(vm.methodCodeData , ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].METHOD_0]);
                	if(exists != undefined){
                		vm.selectedMethodCode = exists;
                		if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit' && (vm.selectedAttributeRow[0].GROSS_INC_EXP == 'RESEARCH' || vm.selectedAttributeRow[0].GROSS_INC_EXP == 'INTEXP'|| vm.selectedAttributeRow[0].GROSS_INC_EXP == 'EXPAA' || vm.selectedAttributeRow[0].GROSS_INC_EXP == 'INVESTMENTS'))	
							 vm.changeMethod(true);
                	}
                	;
                	var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BASE_FDII_0]);
                	if(exists != undefined){
                		vm.getBaseFDIISelected();
                	}
                	
                	var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BASE_BASKET_0]);
                	if(exists != undefined){
                		vm.getBaseBasketSelected();
                	}
                	
                	var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BASE_TYPE_0]);
                	if(exists != undefined){
                		vm.getBaseTypeSelected();
					}
                	
                	var exists = _.find(vm.baseLevelData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BASE_LEVEL_0]);
                	if(exists != undefined)
                		vm.selectedBaseLevelCode = exists.CODE_KEY;
                	
                	var exists = _.find(vm.baseEntityData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].BASE_ENTITY_LEVEL_0]);
                	if(exists != undefined)
                		vm.selectedBaseEntityLevelCode = exists.CODE_KEY;
                	
                	var exists1 = _.find(vm.basketCodeData, ['CODE_DESCRIPTION','Passive']);
                	if(exists1 != undefined)
                		vm.basketCodeDisable = exists1.CODE_KEY;
                	
                	var exists = _.find(vm.lineNoCodeData, ['CODE_DESCRIPTION', vm.selectedAttributeRow[0].LINE_NO_0]);
                	if(exists != undefined)
                		vm.selectedLineNo = exists.CODE_KEY;
                	
	        	  	if(vm.fseCompDefaultData && vm.fseCompDefaultData.length == 0 && vm.basketCodeDisable == vm.selectedBasketCode)
		                vm.disableFSE = 'true';
		            else if( vm.fseCompDefaultData && vm.fseCompDefaultData.length > 0 && vm.basketCodeDisable == vm.selectedBasketCode)
		                vm.disableFSE = 'false';
		            else
		                vm.disableFSE =  undefined;
	            	}
				
				
				vm.validations = function() {
					// vm.editCount = 0;
				//Validation for Removing Passive from HTKO and FSI dropdown	 	
                	_.remove(vm.htkoData, {CODE_DESCRIPTION: 'Passive'});
                	_.remove(vm.fsiData,  {CODE_DESCRIPTION: 'Passive'});
                	
	            //Validation for Removing Summary from GROSS_INC_EXP dropdown if GROSS_INC_EXP is Gross Profit
                	var GieName = (vm.selectedAttributeRow[0] != undefined ? vm.selectedAttributeRow[0].GROSS_INC_EXP :  vm.selectedGrossIncExp.GIE_NAME)
                	var GieType = (vm.selectedAttributeRow[0] != undefined ? vm.selectedAttributeRow[0].GIE_TYPE :  vm.selectedGrossIncExp.GIE_TYPE)
                	
	                if(GieName != 'GPROFIT')
	                	_.remove(vm.sourceTypeCodeData, {CODE_DESCRIPTION: 'Summary'});
                	if(GieType == 'INC' || GieType == 'INVESTMENTS')
						_.remove(vm.sourceTypeCodeData, {CODE_DESCRIPTION: 'Percentage'});
		                	
		            vm.loadingData = false;
		            vm.loadingDataAdd = false;
		            //vm.resetOption = false;
							
			}
				
	// Getting the Base Basket Data as it is not coming from FTC Code Mast Table
				vm.getBaseBasketSelected = function() {
					var params = {
							"action_code" : 'pbh5uq',
							"tag_key" : vm.selectedAttributeRow[0].TAG_KEY,
							'p_scenario' : vm.scenario
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
                                
			                	if(vm.displaySingleSelectBase == 'true'){
			                		// single display
			                		var defaultBBCode = '';
			                		var fromAllDefault =_.find(vm.allDefaultData, ['ATTRIB_NAME', 'BASKET']);
									if(fromAllDefault){
			    					var exists = _.find(vm.baseBasketData, ['CODE_DESCRIPTION', fromAllDefault.CODE_DESCRIPTION]);
			    					
			    					if(exists != undefined)
			                		defaultBBCode  = exists.CODE_KEY;
									}
			                		vm.selectedBaseBasketCode = (data.jsonObject[0] != undefined ? data.jsonObject[0].CODE_KEY : defaultBBCode);
			                		vm.selectedBaseBasketAllocate = (data.jsonObject[0] != undefined ? data.jsonObject[0].CODE_KEY : defaultBBCode);
			                	}else{
			                		
			                		var selectedBaseBasketCodeTemp =[];
			                		   if(data.jsonObject && data.jsonObject.length > 0 && vm.baseBasketData.length > 0 ){
		                                    data.jsonObject.filter(function(data){
		                                        vm.baseBasketData.filter(function(mainData){
		                                            if(mainData.CODE_KEY === data.CODE_KEY){
		                                            	selectedBaseBasketCodeTemp.push(mainData);
		                                            }
		                                        })
		                                    });
		                                    vm.selectedBaseBasketCode = uniqueData(selectedBaseBasketCodeTemp,'CODE_KEY');
		                                    vm.selectedBaseBasketCodeCopy = angular.copy(vm.selectedBaseBasketCode);
		                                }
			                	}
                            }
						});
				}

	//Getting the Base Type Data as it is not coming from FTC Code Mast Table
				vm.getBaseTypeSelected = function() {
					var params = {
							"action_code" : '8ca0bw',
							"tag_key" : vm.selectedAttributeRow[0].TAG_KEY,
							'p_scenario' : vm.scenario,
							'p_tax_year' : vm.curentyear
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	
			                	var selectedBaseTypeCodeTemp =[];
			                	
			                	if((data.jsonObject && data.jsonObject.length > 0 && vm.baseTypeData.length > 0 && vm.transCatgChangedFlag == false) ||
			                			(data.jsonObject && data.jsonObject.length > 0 && vm.baseTypeData.length > 0 && vm.transCatgChangedFlag == true && vm.rowData)){
			                		// change
			                		vm.selectedBaseTypeCode = [];
                                    data.jsonObject.filter(function(data){
                                        vm.baseTypeData.filter(function(mainData){
                                            if(mainData.CODE_KEY === data.CODE_KEY){
                                            	selectedBaseTypeCodeTemp.push(mainData);
                                            }
                                        })
                                        vm.baseTypeDataCurrentCopy = angular.copy(vm.baseTypeData);
                                    });
                                    console.log("edit new");
									vm.changeDataCheckAgain = false;
									$scope.$applyAsync(function () {
										console.log("edit new");
									vm.baseTypeData = [];
									vm.baseTypeData = angular.copy(vm.baseTypeDataCurrentCopy);
                                    vm.selectedBaseTypeCode = uniqueData(selectedBaseTypeCodeTemp,'CODE_KEY');
									vm.selectedBaseTypeCodeTemp = uniqueData(selectedBaseTypeCodeTemp,'CODE_KEY');;
									vm.changeDataCheckAgain = true;
									
									console.log(vm.selectedBaseTypeCode,"vm.selectedBaseTypeCode");
									});
                                }
			                	
			                }
						});
				}
				
		//Getting the Base FDII Data as it is not coming from FTC Code Mast Table
				vm.getBaseFDIISelected = function() {
					var params = {
							"action_code" : '8blhrv',
							"tag_key" : vm.selectedAttributeRow[0].TAG_KEY,
							'p_scenario' : vm.scenario
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
			                    AlertService.add("error", data.errorMessage);
			                    return false;
			                }else{
			                	if(vm.displaySingleSelectBase == 'true'){
			                		// single display
			                		var defaultFDIICodeNew = '';
			                		var fromAllDefault =_.find(vm.allDefaultData, ['ATTRIB_NAME', 'FDII']);
									if(fromAllDefault){
			    					var exists = _.find(vm.baseFDIIData, ['CODE_DESCRIPTION', fromAllDefault.CODE_DESCRIPTION]);
			                		
			    					if(exists != undefined)
			    						defaultFDIICodeNew = exists.CODE_KEY;
									}
			                		vm.selectedBaseFdiiCodes =(data.jsonObject[0] != undefined ? data.jsonObject[0].CODE_KEY : defaultFDIICodeNew);
			                		vm.selectedFDIIForAllocate =(data.jsonObject[0] != undefined ? data.jsonObject[0].CODE_KEY : defaultFDIICodeNew); 
			                	}else{
			                		var selectedBaseFdiiCodesTemp = [];
	                                if(data.jsonObject && data.jsonObject.length > 0 && vm.baseFDIIData.length > 0 ){
	                                    data.jsonObject.filter(function(data){
	                                        vm.baseFDIIData.filter(function(mainData){
	                                            if(mainData.CODE_KEY === data.CODE_KEY){
	                                            selectedBaseFdiiCodesTemp.push(mainData);
	                                            }
	                                            
	                                            
	                                        })
	                                    });
	                                   
	                                    vm.selectedBaseFdiiCodes = uniqueData(selectedBaseFdiiCodesTemp,'CODE_KEY');
	                                    vm.selectedBaseFdiiCodesCopy = angular.copy(vm.selectedBaseFdiiCodes);
	                                }
			                		
			                		//vm.selectedBaseFdiiCodes = data.jsonObject;
			                	}
			                }
						});
				}
	
				vm.changeBasketCode = function(){
					
					var exists1 = _.find(vm.basketCodeData, ['CODE_DESCRIPTION','Passive']);
                	if(exists1 != undefined)
                	{
                		vm.basketCodeDisable = exists1.CODE_KEY;
                	}
                	
	        	  	if(vm.fseCompDefaultData.length == 0 && vm.basketCodeDisable == vm.selectedBasketCode)
		                vm.disableFSE = 'true';
		            else if(vm.fseCompDefaultData.length > 0 && vm.basketCodeDisable == vm.selectedBasketCode)
		                vm.disableFSE = 'false';
		            else
		                vm.disableFSE =  undefined;
			     	
					var exists1 = _.find(vm.basketCodeData, ['CODE_KEY',vm.selectedBasketCode]);
                	if(exists1 != undefined)
                	{
                		vm.selectedBasketCodeDesc = exists1.CODE_DESCRIPTION;
                	}
                	
                	_.remove(vm.htkoData, {CODE_DESCRIPTION: 'Passive'});
                	_.remove(vm.fsiData,  {CODE_DESCRIPTION: 'Passive'});
                	
                  	vm.selectedHtkoCode =  undefined;
	        	  	vm.selectedFsiCode =  undefined;
				}
	//Reset the values when we Change Entity			
				vm.changeEntity = function() {
					
					vm.selectedGrossIncExp = undefined;
					vm.selectedTransCat=undefined;
					vm.selectedBasketCode=undefined;
					vm.selectedSicCode=undefined;		
					vm.selectedFdiiCode=undefined;
					vm.selectedBeatTypeCode=undefined;
					vm.selectedRelatedCode=undefined;
					vm.selectedTradingPartnerCode=undefined;
					vm.selectedCountryCode=undefined;
					vm.selectedHtkoCode=undefined;
					vm.selectedFsiCode=undefined;
					vm.selectedSourceTypeCode=undefined;
					vm.selectedProjectTaggingCode=undefined;
					vm.selectedNotes=undefined;
					vm.selectedMethodCode=undefined;
					vm.selectedBaseFdiiCodes=undefined;
					vm.selectedBaseBasketCode=undefined;
					vm.selectedBaseTypeCode=undefined;
					vm.selectedBaseLevelCode=undefined;
					vm.selectedBaseEntityLevelCode=undefined;
					vm.selectedBeatWithhold=undefined;
	
				}


				vm.changeInCreateMethod = function(isMethodChangedManually){
					vm.isMethodChangedManually = isMethodChangedManually;
				};
				
				vm.changeInCreateMethodEdit = function(isMethodChangedManually){
					vm.isMethodChangedManuallyEdit = isMethodChangedManually;
					vm.isMethodChangedManuallyEditCount++;
				};
				
	// If Beat Code is changed to Yes, need to disable the Related/Unrelated drop down with RELATED value 
	// If Beat Code is changed to No, need to disable the Beat Type drop down				
				vm.changeBeatCode = function(){
					var exists = _.find(vm.yesNoData, ['CODE_KEY', vm.selectedBeatCode]);
                	if(exists != undefined)
                		vm.selectedBeatCodeDesc = exists.CODE_DESCRIPTION;
                	else{
                		vm.selectedBeatCodeDesc ='';
                		vm.selectedBeatTypeCode = '';
                		vm.selectedRelatedCode = '';
                		vm.selectedRelatedCodeDesc  =''
                		vm.selectedTradingPartnerCode = '';
            			vm.selectedCountryCode ='';
            			vm.selectedBeatWithhold=undefined;
                	}
                	if(vm.selectedBeatCodeDesc == 'Yes'){
                			vm.selectedRelatedCode = _.find(vm.relatedCodeData, ['CODE_DESCRIPTION', 'Related Foreign']).CODE_KEY ;
                			vm.selectedRelatedCodeDesc = 'Related Foreign';
                			vm.disableCountry = 'Yes';
                			vm.selectedTradingPartnerCode ='';
                			vm.selectedCountryCode ='';
                			
                			 if(vm.selectedBeatTypeCode == undefined || vm.selectedBeatTypeCode == '' || vm.addFlag == true){
     		                	var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'BEAT_TYPE'])
     	                		if(exists != undefined){
     	                			vm.selectedBeatTypeCode1 = exists.CODE_DESCRIPTION;
     	                		
                     			var exists = _.find(vm.beatTypeData , ['CODE_DESCRIPTION',vm.selectedBeatTypeCode1]);
     	                    	if(exists != undefined)
     	                    		vm.selectedBeatTypeCode = exists.CODE_KEY;
     							  // vm.resetData["selectedSourceTypeCode"] = exists.CODE_KEY;
     								
     	                 		}
     		             	}
                			
               		}else
                	if(vm.selectedBeatCodeDesc == 'No'){
                		vm.selectedBeatTypeCode = '';
                		vm.selectedBeatWithhold=undefined;
                		vm.selectedTradingPartnerCode ='';
                		vm.selectedCountryCode ='';
                	}
            			
				}
// 	If Related is picked, need to disable the trading partner.			
				vm.changeRelatedCode = function(){
					
					vm.selectedCountryCode ='';
					
					var exists = _.find(vm.relatedCodeData, ['CODE_KEY', vm.selectedRelatedCode]);
                	if(exists != undefined)
                		{
                			vm.selectedRelatedCodeDesc = exists.CODE_DESCRIPTION;
                		}
                	
                	if(vm.selectedRelatedCodeDesc == 'Related Domestic')
                		{
                			vm.selectedTradingPartnerCode = '';
                			vm.selectedCountryCode =='';
                		//	vm.selectedRelatedCode = _.find(vm.relatedCodeData, ['CODE_DESCRIPTION', 'Related Domestic']).CODE_KEY ;
                			vm.disableCountry = 'Yes';
                		}
                	if(vm.selectedRelatedCodeDesc == 'Related Foreign'){
                		vm.selectedTradingPartnerCode = '';
            			vm.selectedCountryCode =='';
            			vm.disableCountry = 'Yes';
                	}
                		
                	
                	if(vm.selectedRelatedCodeDesc == 'UnRelated')
                		{
                			vm.selectedTradingPartnerCode = '';
                	//		vm.selectedCountryCode ='';
                			vm.disableCountry = '';
                			 if(vm.selectedCountryCode == undefined || vm.selectedCountryCode == ''){
     		                	var exists = _.find(vm.allDefaultData, ['ATTRIB_NAME', 'COUNTRY'])
     	                		if(exists != undefined){
     	                			vm.selectedCountryCode1 = exists.CODE_DESCRIPTION;
     	                		
                     			var exists = _.find(vm.countryCodeData , ['CODE_DESC',vm.selectedCountryCode1]);
     	                    	if(exists != undefined) { 
     	                    		vm.selectedCountryCode = exists.CODE_KEY;
     	                		}
     	                 		}
     		             	}
                		}
                	if(vm.selectedBeatCodeDesc == undefined)
                		vm.selectedBeatCodeDesc='';
				}
					
	// This will populate the Country Dropdown with the COUNTRY of selected trading partner.			
				vm.changeTradingPartner = function(){
					
					var exists = _.find(vm.tradingPartnerCodeData, ['COMBINATION_KEY', vm.selectedTradingPartnerCode]);
                	if(exists != undefined){
                		vm.selectedCountry = exists.LE_COUNTRY;
                		vm.selectedTradingPartnerLEID = exists.COMBINATION_KEY;
                	}
					
					var exists = _.find(vm.countryCodeData, ['CODE_SHORT_DESC', vm.selectedCountry]);
					if(exists != undefined)
						vm.selectedCountryCode = exists.CODE_KEY;
					else 
						vm.selectedCountryCode ='';
					
				}
				
				vm.editCountCheckForActual = function() {
					vm.editCountCheckForActualData = vm.editCountCheckForActualData + 1;					
				}
//If GIE is INTEXP and Method is Interest Expense, we need to show base FDII , Base basket single select
//If GIE is EXP A & A and Method is Gross Income, we need to show all the Inc and GP transaction categories in Base Type
// If GIE is Exp A&A and Method is Allocate, Base FDII and Base Basket will be single select and Base Level, base Entity level and base Type will be disabled				
				vm.changeMethod = function(type){
					
					vm.displaySingleSelectBase='';
					vm.gIncomeMethodFlag='';
					vm.selectedGieName = (vm.selectedAttributeRow[0] != undefined ? vm.selectedAttributeRow[0].GROSS_INC_EXP :vm.selectedGrossIncExp? vm.selectedGrossIncExp.GIE_NAME:null );
					if(type)
					vm.manualTransCategory = false;
					
					if(vm.selectedGieName && (vm.selectedGieName == 'INTEXP' || vm.selectedGieName == 'RESEARCH')){
					
	                	if(vm.selectedMethodCode != undefined && vm.selectedMethodCode.CODE_DESCRIPTION  == 'Allocate'){
	                		vm.displaySingleSelectBase = 'true';
	                		vm.newList  = angular.copy( vm.getAttribToDisplay);
							
		                	vm.newArray = vm.newList.map( function( el ){ 
	                            return el.ATTRIB_DISPLAY_NAME; 
	                           });
		                	
	                		// single display
		                	if(vm.AllocateMethodTransCatg == false){
		                		
		                		if (vm.baseFDIIData && vm.baseFDIIData.length > 0) {
		                			vm.singleBaseFDIIData = angular.copy(vm.baseFDIIData);
		                		} else {
		                			if (vm.singleBaseFDIIData) {
		                				vm.baseFDIIData = angular.copy(vm.singleBaseFDIIData);
		                			}
		                		}
		                		
		                		if (vm.baseBasketData && vm.baseBasketData.length > 0 ) {
		                			vm.singleBaseBasketData = angular.copy(vm.baseBasketData);
		                		} else {
		                			if (vm.singleBaseBasketData) {
		                				vm.baseBasketData = angular.copy(vm.singleBaseBasketData);
		                			}
		                		}
		                		
		                		var exists = _.find(vm.baseFDIIData, ['CODE_DESC', 'US Derived DEI']);
		    					if(exists != undefined)
		                		vm.selectedBaseFdiiCodes = exists.CODE_KEY;
		    					exists = _.find(vm.baseBasketData, ['CODE_DESC', 'U.S.']);
		    					if(exists != undefined)
		                		vm.selectedBaseBasketCode = exists.CODE_KEY;
		                	}
	                		
	                	}
	                	
	                	if(vm.selectedMethodCode != undefined && (vm.selectedMethodCode.CODE_DESCRIPTION  == 'Interest Expense' || vm.selectedMethodCode.CODE_DESCRIPTION  == 'Research Expense')){
	                		vm.newList  = angular.copy( vm.getAttribToDisplay);
							_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base FDII'});
							_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Basket'});
							
		                	vm.newArray = vm.newList.map( function( el ){ 
	                            return el.ATTRIB_DISPLAY_NAME; 
	                           });
	                	}
	                		
					}
					
					
					if(vm.selectedGieName && (vm.selectedGieName == 'EXPAA' || vm.selectedGieName == 'ASTEXP' || vm.selectedGieName == 'INVESTMENTS')){
						
	               //For Method Allocate and Gross Income, show only Gprofit transaction Category in Base Type dropdown
						if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION != 'Allocate' && vm.selectedMethodCode.CODE_DESCRIPTION != 'Gross Income'){
							if(type) {
								if(vm.allGPTransCategoryData.length === 0 ){
									vm.getAllTransCatgExceptExp(true);
									//vm.baseTypeData = vm.allGPTransCategoryData;
								}else{
									vm.baseTypeData = vm.allGPTransCategoryData;
									console.log("edit new");
									// Add this may solve
		                            if(!vm.gridDataMultipleSelect.rowData) {
		                            	vm.selectedBaseTypeCode = vm.baseTypeData;
		                            } else if(vm.gridDataMultipleSelect.rowData && vm.gridDataMultipleSelect.rowData.BASE_TYPE_0 === 'MULTI') {
		                            	vm.selectedBaseTypeCode = vm.selectedBaseTypeCodeTemp;
		                            }
								}
							}
							
//							console.log(vm.baseTypeData.length,"vm.baseTypeData");
							vm.newArray  = vm.getAttribToDisplay ? vm.getAttribToDisplay.map( function( el ){ 
	                            return el.ATTRIB_DISPLAY_NAME; 
							}):[];
							
						}
						else if(vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION ==  'Allocate'){
							
							vm.displaySingleSelectBase='true';
							// single display
							if(vm.AllocateMethodTransCatg == false){
								
								if (vm.rowData) {
									setTimeout(() => {
										$("#FTC_AM_UDE_22 option[value='?']").remove();
										$("#FTC_AM_UDE_23 option[value='?']").remove();
									}, 500);
									
								}
								
		                		var exists = _.find(vm.baseFDIIData, ['CODE_DESC', 'US Derived DEI']);
		                		
		                		if(exists == undefined) {
		                			 exists = _.find(vm.baseFDIIData, ['CODE_DESCRIPTION', 'US Derived DEI']);
		                		}
		                		
		    					if(exists != undefined)
		                		vm.selectedBaseFdiiCodes = exists.CODE_KEY;
		    					
		    					exists = _.find(vm.baseBasketData, ['CODE_DESC', 'U.S.']);
		    					
		    					if(exists == undefined) {
		                			 exists = _.find(vm.baseBasketData, ['CODE_DESCRIPTION', 'U.S.']);
		                		}
		    					
		    					if(exists != undefined)
		                		vm.selectedBaseBasketCode =  parseInt(exists.CODE_KEY);
							}
						//	vm.selectedBaseFdiiCodes = undefined;
	                	//	vm.selectedBaseBasketCode = undefined;
							vm.newList  = angular.copy( vm.getAttribToDisplay);
							_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Level'});
							_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Type'});
							_.remove(vm.newList, {ATTRIB_DISPLAY_NAME: 'Base Entity Level'});
							
		                	vm.newArray = vm.newList.map( function( el ){ 
	                            return el.ATTRIB_DISPLAY_NAME; 
	                           });
						}else if (vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION ==  'Gross Income'){
							vm.gIncomeMethodFlag = 'true';
							if(type) {
								vm.baseTypeData = vm.allTransCategoryData;
								console.log("edit new");
							}
							
							if (!vm.rowData && vm.baseTypeTempData && vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION ==  'Gross Income' && vm.baseTypeTempData.length === 0) {
                    			vm.defaultBaseTypeCodeInfoSet = true;
                    		} else {
                    			vm.defaultBaseTypeCodeInfoSet = false;
                    		}
							
							vm.newArray  = vm.getAttribToDisplay.map( function( el ){ 
	                            return el.ATTRIB_DISPLAY_NAME; 
							});
						}
					}
					
					if(type) {
						vm.methodChangedFlag = !vm.methodChangedFlag;
						if(vm.rowData) {
							if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION === "Gross Income") {
	               				vm.tempGPTransInfoDetails = vm.allTransCategoryData;
	               			} else {
	               				vm.tempGPTransInfoDetails = vm.allGPTransCategoryData;
	               			}
							
							if ((vm.gridDataMultipleSelect.rowData && vm.gridDataMultipleSelect.rowData.BASE_TYPE_0 === 'ALL') || vm.editCountCheckForActualData > 0) {
								vm.selectedBaseTypeCodeTemp = vm.tempGPTransInfoDetails;
								vm.selectedBaseTypeCode = vm.tempGPTransInfoDetails;
							}
						}
						
					}
					
					
					
				}
			//This method will compare the default List with the main list of table for Base FDII and Base Basket.	
			
			
			/*$scope.$watchGroup(['vm.defaultBaseFdii', 'vm.baseFDIIData'], function(newValues, oldValues, scope) {
				console.log("check both here",vm.baseFDIIData,vm.defaultBaseFdii);
			});
			
			  $scope.$watch(
                    function watchDefaultBaseFdii( scope ) {
                        // Return the "result" of the watch expression.
                        return( vm.defaultBaseFdii );
                    },
                    function handleDefaultBaseFdiiChange( newValue, oldValue ) {
                        console.log( "fn( vm.defaultBaseFdii ):", newValue );
                    }
              );*/
			  
				
						$scope.$watchCollection(
	                    function getValue() {
	                            return( [ vm.flagForNewUpdate,vm.methodChangedFlag,vm.loadingDataAdd] );
	                    },
	                    function collectionChanged( newValue, oldValue ,$scope) {

	                    	if((vm.rowData == undefined &&  vm.flagForNewUpdate > -1) || vm.manualTransCategory) {
	                    		
	                    		vm.baseFDIIData = [];
	                    		vm.selectedBaseFdiiCodes = [];
	                    		vm.baseBasketData = [];
	                    		vm.selectedBaseBasketCode = [];
	                    		vm.baseTypeData = [];
	                    		vm.selectedBaseTypeCode = [];
	                    		console.log("edit new");
	                    		
	                    		$scope.$applyAsync(function () {
	                    	let tempData = [];
	                    	console.log("last test---");
	                    	_.forEach(_.map(vm.selectedBaseFdiiCodesCopy,'CODE_KEY'),function(value,key){
                   				 let tempFilter = _.find(vm.baseFDIIDataCopy, ['CODE_KEY', value]); 
									 if(tempFilter){
										 tempData.push(tempFilter);
									 }
                   			});
	                    	if (vm.isMethodChangedManually) {
	                    		if(vm.baseFDIIDataCopy) {
	                    			vm.selectedBaseFdiiCodes =  angular.copy(vm.baseFDIIDataCopy);
	                    		} else {
	                    			vm.selectedBaseFdiiCodes =  angular.copy(vm.defaultBaseFdii);
	                    			vm.baseFDIIDataCopy = angular.copy(vm.defaultBaseFdii);
	                    		}
	                    			
	                    	} else {
	                    		if(vm.AllocateMethodTransCatg == false) {
	                    			vm.selectedBaseFdiiCodes =  tempData;
	                    		} else {
	                    			vm.selectedBaseFdiiCodes =  tempData[0].CODE_KEY;
	                    		}
	                    	}
                   			
                   			vm.baseFDIIData = angular.copy(vm.baseFDIIDataCopy);
                   			
                   			tempData = [];
                   			_.forEach(_.map(vm.selectedBaseBasketCodeCopy,'CODE_KEY'),function(value,key){
                   				 let tempFilter = _.find(vm.baseBasketDataCopy, ['CODE_KEY', value]); //basketCodeAllocateData
									 if(tempFilter){
										 tempData.push(tempFilter);
									 }
                   			});
                   			
                   			if (vm.isMethodChangedManually) {
                   				if(vm.baseBasketDataCopy) {
		                    		vm.selectedBaseBasketCode =  angular.copy(vm.baseBasketDataCopy);
	                   			} else {
	                    			vm.selectedBaseBasketCode =  angular.copy(vm.defaultBaseBasket);
	                    			vm.baseBasketDataCopy = angular.copy(vm.defaultBaseBasket);
	                    		}
	                    	} else {
	                    		if(vm.AllocateMethodTransCatg == false) {
	                    			vm.selectedBaseBasketCode = [];
	                    			vm.selectedBaseBasketCode =  tempData;
	                    		} else {
	                    			vm.selectedBaseBasketCode =  tempData[0] ? tempData[0].CODE_KEY : null;
	                    		}
	                    	}
                   			
                   			vm.baseBasketData = angular.copy(vm.baseBasketDataCopy);
                   			
                   			tempData = [];
                   			vm.tempGPTransInfoDetails = [];
                   			if((vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION === "Gross Income")) {
                   				vm.tempGPTransInfoDetails = vm.allTransCategoryData;
                   			} else {
                   				vm.tempGPTransInfoDetails = vm.allGPTransCategoryData;
                   			}
                   			
                   			if(vm.defaultBaseTypeCodeInfoSet) {
                   				vm.selectedBaseTypeCodeTemp =  vm.allTransCategoryData;
                   			}
                   			
                			//vm.baseTypeData = angular.copy(vm.baseTypeDataCopy);
                			_.forEach(_.map(vm.selectedBaseTypeCodeTemp,'CODE_KEY'),function(value,key){
                				 let tempFilter = _.find(vm.tempGPTransInfoDetails , ['CODE_KEY', value]); //baseTypeDataCopy
								 if(tempFilter){
									 tempData.push(tempFilter);
								 }
                			});
                			
                			
                			if (vm.isMethodChangedManually) {
	                    		vm.selectedBaseTypeCode =  angular.copy(vm.tempGPTransInfoDetails); //baseTypeDataCopy
	                    	} else {
	                    		if (tempData && tempData.length > 0) {
	                    			vm.selectedBaseTypeTNewCopy = tempData;
		                			vm.selectedBaseTypeCode =  tempData;
	                    		} else {
	                    			vm.selectedBaseTypeTNewCopy = angular.copy(vm.tempGPTransInfoDetails);
		                			vm.selectedBaseTypeCode =  angular.copy(vm.tempGPTransInfoDetails);
	                    		}
	                    		
	                			
	                    	}
                			//console.log("edit new");
                			
                			vm.baseTypeData = angular.copy(vm.tempGPTransInfoDetails); //baseTypeDataCopy
                			
                			if(!vm.gridDataMultipleSelect.rowData && vm.isMethodChangedManually) {
                            	vm.selectedBaseTypeCode = vm.baseTypeData;
                            	console.log("last info");
                            }
                			
	                    		});
                   			
	                    		
	                    		
	                    		/*vm.baseFDIIData = angular.copy(vm.baseFDIIData);
	                    		vm.baseBasketData = angular.copy(vm.baseBasketData);
	                    		vm.baseTypeData =  angular.copy(vm.baseTypeData);*/
	                    		
	                    		
	                    		/*$scope.$applyAsync(function () {
	                    			let tempData = [];
	                    			_.forEach(_.map(vm.selectedBaseFdiiCodesCopy,'CODE_KEY'),function(value,key){
	                    				 let tempFilter = _.find(vm.baseFDIIData, ['CODE_KEY', value]); 
										 if(tempFilter){
											 tempData.push(tempFilter);
										 }
	                    			});
	                    			vm.selectedBaseFdiiCodes =  tempData;
	                    			vm.loadingData = true;
								});*/
	                    		
	                    		/*vm.baseFDIIDataCopy = angular.copy(vm.baseFDIIData);
=======
	                    	if(vm.rowData == undefined &&  vm.flagForNewUpdate > -1 && vm.AllocateMethodTransCatg == false) {
	                    		vm.baseFDIIDataCopy = angular.copy(vm.baseFDIIData);
>>>>>>> 4637cad165e319b53bb48314cd4c25212a23ad86
	                    		vm.baseBasketDataCopy = angular.copy(vm.baseBasketData);
	                    		vm.baseTypeDataCopy =  angular.copy(vm.baseTypeData);
	                    		
	                    		vm.baseFDIIData = [];
	                    		vm.selectedBaseFdiiCodes = [];
	                    		vm.baseBasketData = [];
	                    		vm.selectedBaseBasketCode = [];
	                    		//vm.baseTypeData = [];
	                    		//vm.selectedBaseTypeCode = [];
	                    		
	                    		setTimeout(function(){
	                    			let tempData = [];
	                    			vm.baseFDIIData = angular.copy(vm.baseFDIIDataCopy);
	                    			_.forEach(_.map(vm.selectedBaseFdiiCodesCopy,'CODE_KEY'),function(value,key){
	                    				 let tempFilter = _.find(vm.baseFDIIData, ['CODE_KEY', value]); 
										 if(tempFilter){
											 tempData.push(tempFilter);
										 }
	                    			});
	                    			vm.selectedBaseFdiiCodes =  tempData;
	                    			
	                    			tempData = [];
	                    			vm.baseBasketData = angular.copy(vm.baseBasketDataCopy);
	                    			_.forEach(_.map(vm.selectedBaseBasketCodeCopy,'CODE_KEY'),function(value,key){
	                    				 let tempFilter = _.find(vm.baseBasketData, ['CODE_KEY', value]); 
										 if(tempFilter){
											 tempData.push(tempFilter);
										 }
	                    			});
	                    			vm.selectedBaseBasketCode =  tempData;*/
	                    			
	                    			/*tempData = [];
	                    			//vm.baseTypeData = angular.copy(vm.baseTypeDataCopy);
	                    			_.forEach(_.map(vm.selectedBaseTypeCodeTemp,'CODE_KEY'),function(value,key){
	                    				 let tempFilter = _.find(vm.baseTypeDataCopy, ['CODE_KEY', value]); 
										 if(tempFilter){
											 tempData.push(tempFilter);
										 }
	                    			});
	                    			vm.selectedBaseTypeCode =  tempData;*/
	                    			
								//},200);
	                    	}
	                    });
	                    
						/*$scope.$watchCollection(
			                    function getValue() {
			                            return( [ vm.baseTypeData ] );
			                    },
			                    function collectionChanged( newValue, oldValue ,$scope) {
			                    	if(vm.rowData == undefined &&  vm.flagForNewUpdate > -1) {
			                    		vm.baseTypeDataCopy =  angular.copy(vm.baseTypeData);
			                    		
			                    		vm.selectedBaseTypeCode = [];
			                    		
			                    		setTimeout(function(){
			                    			let tempData = [];
			                    			//vm.baseTypeData = angular.copy(vm.baseTypeDataCopy);
			                    			_.forEach(_.map(vm.selectedBaseTypeCodeTemp,'CODE_KEY'),function(value,key){
			                    				 let tempFilter = _.find(vm.baseTypeDataCopy, ['CODE_KEY', value]); 
												 if(tempFilter){
													 tempData.push(tempFilter);
												 }
			                    			});
			                    			vm.selectedBaseTypeCode =  tempData;
			                    			
										},200);
			                    	}
			                    });*/
						
            	
			  $scope.$watchCollection(
                    function getValue() {
                            return( [ vm.defaultBaseFdii, vm.baseFDIIData,vm.selectedTransCat,vm.methodChangedFlag,vm.loadingData 
                            	//,vm.flagForNewUpdate 
                            	] );
                    },
                    function collectionChanged( newValue, oldValue ,$scope) {
						console.log("old new",newValue,oldValue);
						
						/*if(vm.transCatgMapDesc != "N" && oldValue && oldValue[1] != undefined && oldValue[1].length === newValue[1].length ) {
							return;
						}
						
						if(vm.transCatgMapDesc != "N" && oldValue && oldValue[4] != undefined && oldValue[4] < newValue[4]) {
							vm.selectedBaseFdiiCodes = [];
							vm.baseFDIIDataTempCopy = angular.copy(vm.baseFDIIData);
							vm.baseFDIIData = [];
							$scope.$applyAsync(function () {
								vm.selectedBaseFdiiCodes = vm.selectedBaseFdiiCodesCopy;
								vm.baseFDIIData = angular.copy(vm.baseFDIIDataTempCopy);
							});
							return;
						} */
						
						
						
						if(vm.defaultBaseFdii != undefined &&  vm.baseFDIIData != undefined && (vm.transCatgMapDesc == "N" || (vm.rowData != undefined && vm.loadingData === false && vm.manualTransCategory === false ))){
						  vm.selectedBaseFdiiCodes = [];
						  var baseFdiiTemp = [];
						  
								 vm.defaultBaseFdii.filter(function(defaultData){
									vm.baseFDIIData.filter(function(item){
										if(defaultData.CODE_DESCRIPTION === item.CODE_DESCRIPTION){
											 baseFdiiTemp.push(item);
										}
										
									});
								});
								if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData){
									// adding this for All in future which we have to uncomment
									var rowDataTemp = vm.gridDataMultipleSelect.rowData;
									
									console.log(vm.checkForReset,"vm.checkForReset");
									if(rowDataTemp.METHOD_0 == 'Allocate' || rowDataTemp.BASE_FDII_0 === undefined){
										$scope.$applyAsync(function () {
											vm.selectedBaseFdiiCodes = vm.baseFDIIData;
											});
									}
									else  if( (rowDataTemp.BASE_FDII_0 !== "ALL" &&  !vm.checkForReset) ){
											console.log(vm.editCount,"check");
											 vm.selectedBaseFdiiCodes = [];
										$scope.$applyAsync(function () {
											 let tempFilterInfo = [];	
											 
											 rowDataTemp.BASE_FDII_0.split(',').forEach((baseFDiiSplit) => {
												 let tempFilter = _.find(vm.baseFDIIData, ['CODE_DESC', baseFDiiSplit]); 
												 if(tempFilter){
													 tempFilterInfo.push(tempFilter);
												 }
											 })

											if(vm.isMethodChangedManuallyEdit && !vm.resetOption) {
												tempFilterInfo = vm.baseFDIIData;
											}
											 vm.selectedBaseFdiiCodes = tempFilterInfo;
											 vm.selectedBaseFdiiCodesNewEdit = tempFilterInfo;
											 //vm.selectedBaseFdiiCodesTempNew = tempFilterInfo;
											});
									
											 
									}else if(rowDataTemp.BASE_FDII_0 === "ALL"  ||  vm.editCount > 1 || vm.methodChangeCount !== 0){
											$scope.$applyAsync(function () {
												 vm.selectedBaseFdiiCodes = [];
												 vm.selectedBaseFdiiCodes = vm.baseFDIIData;
											});					
											
										
										
									} 
									
									if(vm.displaySingleSelectBase === "true" && vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION ==  'Allocate'){
										if(vm.selectedFDIIForAllocate === undefined || ( vm.methodChangeCount !== 0 )){
											
												vm.selectedBaseFdiiCodes = [];
												var exists = _.find(vm.baseFDIIData, ['CODE_DESC', (vm.rowData && vm.rowData.METHOD_0 === vm.selectedMethodCode.CODE_DESCRIPTION )  ? vm.rowData.BASE_FDII_0 : 'US Derived DEI']);
						                		var defaultFDIICodeNew = '';
						    					if(exists != undefined)
						    						defaultFDIICodeNew = exists.CODE_KEY;
						    					$scope.$applyAsync(function () {
						    						vm.selectedBaseFdiiCodes = defaultFDIICodeNew;
						    					});
											
											
										}else{
											$scope.$applyAsync(function () {
						    					vm.selectedBaseFdiiCodes = vm.selectedFDIIForAllocate;
						    					});
										}
										//vm.selectedBaseFdiiCodes = vm.selectedFDIIForAllocate;
									}
									
									if(oldValue[2] !== newValue[2]){
										
										console.log(oldValue,newValue);
										var exists = _.find(vm.transCategoryData , ['CODE_DESCRIPTION', rowDataTemp.TRANS_CATG_NAME]);
										
										if(vm.displaySingleSelectBase !== "true" && vm.tranCatgCount !== 0 &&  vm.selectedMethodCode 
												&& vm.selectedMethodCode.CODE_DESCRIPTION === rowDataTemp.METHOD_0  
												|| vm.displaySingleSelectBase !== "true" && (exists && exists.CODE_KEY !== newValue[2]) ) {
											$scope.$applyAsync(function () {
												if(vm.newArray.indexOf('Base FDII') > -1 && vm.selectedBaseFdiiCodesCopy === undefined) {
													vm.selectedBaseFdiiCodes = vm.baseFDIIData;
												}else {
													if(rowDataTemp.METHOD_0 === vm.selectedMethodCode.CODE_DESCRIPTION){
														vm.selectedBaseFdiiCodes = angular.copy(vm.selectedBaseFdiiCodesCopy);
													}else{
														vm.selectedBaseFdiiCodes = vm.baseFDIIData;
													}
													
												}
												 
												});
										} else  if(vm.displaySingleSelectBase === "true") {
										
											if(vm.selectedFDIIForAllocate === undefined || ( vm.methodChangeCount !== 0 )){
												vm.selectedBaseFdiiCodes = [];
												var exists = _.find(vm.baseFDIIData, ['CODE_DESC', 'US Derived DEI']);
						                		var defaultFDIICodeNew = '';
						    					if(exists != undefined)
						    						defaultFDIICodeNew = exists.CODE_KEY;
						    					$scope.$applyAsync(function () {
						    					vm.selectedBaseFdiiCodes = defaultFDIICodeNew;
						    					});
											}else{
												$scope.$applyAsync(function () {
							    					vm.selectedBaseFdiiCodes = vm.selectedFDIIForAllocate;
							    					});
											}
											
										} 
									}
									/*else if(rowDataTemp.BASE_FDII_0 === "MULTI" && vm.editCount == 1 && vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION === rowDataTemp.METHOD_0 ){
										
										/*$scope.$applyAsync(function () {
											// vm.selectedBaseFdiiCodes = [];
										});
									}
									else{
									 //vm.getDefaultsPopulateData();
									}*/
							}else{
								$scope.$applyAsync(function () {
									if(vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION ==  'Allocate'){
										var exists = _.find(vm.baseFDIIData, ['CODE_DESC', 'US Derived DEI']);
				                		var defaultFDIICodeNew = '';
				    					if(exists != undefined)
				    						defaultFDIICodeNew = exists.CODE_KEY;
										vm.selectedBaseFdiiCodes = defaultFDIICodeNew;
									}else{
										vm.selectedBaseFdiiCodes = baseFdiiTemp;
									}
									//vm.selectedBaseFdiiCodes = baseFdiiTemp;
								});
							}

						  
					   } 
					   
					   
                    }
              );
			  
			  
			  $scope.$watchCollection(
                    function getValue() {
                            return( [ vm.defaultBaseBasket, vm.baseBasketData,vm.selectedTransCat,vm.methodChangedFlag ] );
                    },
                    function collectionChanged( newValue, oldValue ,$scope) {
                    	
                    	/*if(vm.transCatgMapDesc != "N" &&  oldValue && oldValue[1] != undefined && oldValue[1].length === newValue[1].length) {
							return;
						}
						if(vm.transCatgMapDesc != "N" &&  oldValue && oldValue[4] != undefined && oldValue[4] < newValue[4]) {
							vm.selectedBaseBasketCode = [];
							vm.baseBasketDataTempCopy = angular.copy(vm.baseBasketData);
							vm.baseBasketData = [];
							$scope.$applyAsync(function () {
								vm.selectedBaseFdiiCodes =  angular.copy(vm.selectedBaseTypeCodeTemp);
								vm.baseBasketData = angular.copy(vm.baseBasketDataTempCopy);
							});
							return;
						} */
						
						if(vm.defaultBaseBasket != undefined &&  vm.baseBasketData != undefined &&  (vm.transCatgMapDesc == "N" || (vm.rowData != undefined && vm.loadingData === false && vm.manualTransCategory === false ))){
						  vm.selectedBaseBasketCode = [];
						  var basketDataTemp = [];
						 // console.log(vm.gridDataMultipleSelect.rowData,'vm.gridDataMultipleSelect.rowData');
							vm.defaultBaseBasket.filter(function(defaultData){
								return vm.baseBasketData.filter(function(item){
									if(defaultData.CODE_DESCRIPTION === item.CODE_DESCRIPTION){
										basketDataTemp.push(item);
									}
									
								})
							})
							if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData){
								// adding this for All in future which we have to uncomment
								var rowDataTemp = vm.gridDataMultipleSelect.rowData;
								if(rowDataTemp.METHOD_0 == 'Allocate' || rowDataTemp.BASE_BASKET_0 === undefined){
									$scope.$applyAsync(function () {
										vm.selectedBaseBasketCode = vm.baseBasketData;
										});
								}
								else  if( (rowDataTemp.BASE_BASKET_0 !== "ALL" &&  !vm.checkForReset) ){
									console.log(vm.editCount,"check");
									 vm.selectedBaseBasketCode = [];
								$scope.$applyAsync(function () {
									 let tempFilterInfo = [];											 
									 rowDataTemp.BASE_BASKET_0.split(',').forEach((baseFDiiSplit) => {
										 let tempFilter = _.find(vm.baseBasketData, ['CODE_DESC', baseFDiiSplit]); 
										 if(tempFilter){
											 tempFilterInfo.push(tempFilter);
										 }
									 })
									 
									 if(vm.isMethodChangedManuallyEdit && !vm.resetOption) {
												tempFilterInfo = vm.baseBasketData;
											}
									vm.selectedBaseBasketCode = tempFilterInfo;
									 //vm.selectedBaseBasketCode = tempFilterInfo;
									 //vm.selectedBaseFdiiCodesTempNew = tempFilterInfo;
									});
							
									 
							}else if(rowDataTemp.BASE_BASKET_0 === "ALL" || vm.editCount > 1 || vm.methodChangeCount !== 0){
									//|| (oldValue[3] != newValue[3])
									vm.selectedBaseBasketCode = [];
										$scope.$applyAsync(function () {
											// vm.selectedBaseBasketCode = [];
										vm.selectedBaseBasketCode = vm.baseBasketData;
										});
									}
								if(vm.displaySingleSelectBase === "true" && vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION ==  'Allocate'){
									if(vm.selectedBaseBasketAllocate === undefined  || ( vm.methodChangeCount !== 0 )){
											var exists = _.find( vm.rowData ? vm.basketCodeAllocateData : vm.baseBasketData, ['CODE_DESC', (vm.rowData && vm.rowData.METHOD_0 === vm.selectedMethodCode.CODE_DESCRIPTION)  ?  vm.rowData.BASE_BASKET_0: 'U.S.']);
					    					var defaultBBCode = '';
					    					if(exists != undefined)
					                		defaultBBCode  = exists.CODE_KEY;
					    					$scope.$applyAsync(function () {
												vm.selectedBaseBasketCode = defaultBBCode;
												});
									
									}else{
										$scope.$applyAsync(function () {
											vm.selectedBaseBasketCode = vm.selectedBaseBasketAllocate;
											});
									}
								}
								if(oldValue[2] !== newValue[2]){
									var exists = _.find(vm.transCategoryData , ['CODE_DESCRIPTION', rowDataTemp.TRANS_CATG_NAME]);
									if( vm.displaySingleSelectBase !== "true" && vm.tranCatgCount !== 0 || 
											vm.displaySingleSelectBase !== "true" && (exists && exists.CODE_KEY !== newValue[2]) ) {
										$scope.$applyAsync(function () {
											//vm.selectedBaseBasketCode = vm.baseBasketData;
											if(vm.newArray.indexOf('Base Basket') > -1 &&  vm.selectedBaseBasketCodeCopy === undefined){
												
											}else{
												if(rowDataTemp.METHOD_0 === vm.selectedMethodCode.CODE_DESCRIPTION){
													vm.selectedBaseBasketCode = angular.copy(vm.selectedBaseBasketCodeCopy);
												}else{
													vm.selectedBaseBasketCode = vm.baseBasketData;
												}
												
											}
											
											});
									}else if(vm.displaySingleSelectBase === "true"){
										if(vm.selectedBaseBasketAllocate === undefined || ( vm.methodChangeCount !== 0 )){
											var exists = _.find(vm.baseBasketData, ['CODE_DESC', 'U.S.']);
					    					var defaultBBCode = '';
					    					if(exists != undefined)
					                		defaultBBCode  = exists.CODE_KEY;
					    					$scope.$applyAsync(function () {
												vm.selectedBaseBasketCode = defaultBBCode;
												});
											}else{
												$scope.$applyAsync(function () {
													vm.selectedBaseBasketCode = vm.selectedBaseBasketAllocate;
													});
											}
									}
								}
							}else{
							$scope.$applyAsync(function () {
									//vm.selectedBaseBasketCode = basketDataTemp;
								if(vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION ==  'Allocate'){
									var exists = _.find(vm.baseBasketData, ['CODE_DESC', 'U.S.']);
			    					var defaultBBCode = '';
			    					if(exists != undefined)
			                		defaultBBCode  = exists.CODE_KEY;
									vm.selectedBaseBasketCode = defaultBBCode;
								}else{
									vm.selectedBaseBasketCode = basketDataTemp;
								}
							});
							}

						  
					   }
					   
					   
                    }
              );
			  
			  
			  
			  //,vm.selectedMethodCode,,vm.selectedBaseLevelCode
			  
			  $scope.$watchCollection(
                    function getValue() {
                            return( [ vm.baseTypeData,vm.selectedMethodCode,vm.changeDataCheckAgain,vm.methodChangedFlag,vm.isLengthZero,vm.tranCatgCount
                            	//,vm.flagForNewUpdate 
                            	] );
                    },
                    function collectionChanged( newValue, oldValue ,$scope) {
                    	
						/*if(vm.transCatgMapDesc != "N" &&  oldValue && oldValue[6] != undefined && oldValue[6] < newValue[6]) {
							vm.selectedBaseTypeCode = [];
							vm.baseTypeDataTempCopy = angular.copy(vm.baseTypeData);
							vm.baseTypeData = [];
							$scope.$applyAsync(function () {
								let tempData = [];
								_.forEach(_.map(vm.selectedBaseTypeCodeTemp,'CODE_KEY'),function(value,key){
	                				 let tempFilter = _.find(vm.baseTypeDataTempCopy, ['CODE_KEY', value]); 
									 if(tempFilter){
										 tempData.push(tempFilter);
									 }
	                			});
								vm.selectedBaseTypeCode = angular.copy(tempData);
								vm.baseTypeData = angular.copy(vm.baseTypeDataTempCopy);
							});
						} 
						
						if(vm.transCatgMapDesc != "N" &&  oldValue && oldValue[0] != undefined && oldValue[0].length === newValue[0].length) {
							return;
						}*/
						
						if(vm.transCatgChangedFlag == false && (vm.transCatgMapDesc == "N" || (vm.rowData != undefined && vm.loadingData === false && vm.manualTransCategory == false))){
                    		
							if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData){
								// adding this for All in future which we have to uncomment
								var rowDataTemp = vm.gridDataMultipleSelect.rowData;
									
											//if((rowDataTemp.BASE_TYPE_0 === "ALL" || vm.newArray && vm.newArray.indexOf('Base Type') > -1 || (oldValue[3] != newValue[3])) && rowDataTemp.BASE_TYPE_0 !== "SINGLE" && rowDataTemp.BASE_TYPE_0 !== "MULTI" ){
								 if( (rowDataTemp.BASE_TYPE_0 === "MULTI" && vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION === rowDataTemp.METHOD_0) ){
										console.log(vm.editCount,"check");
										
									$scope.$applyAsync(function () {
										
										setTimeout(function(){
											vm.selectedBaseTypeCode = [];
											 if(document.getElementById("FTC_AM_UD_24")){
												 document.getElementById("FTC_AM_UD_24").getElementsByClassName("btn-small")[2].click()
											 }
											 vm.selectedBaseTypeCode = vm.selectedBaseTypeCodeTemp;
											
											 if(document.getElementById("FTC_AM_UD_24"))
											 document.getElementById("FTC_AM_UD_24").getElementsByClassName("btn")[0].click();
										},20);
										});
						
								 } else if(rowDataTemp.BASE_TYPE_0 === "MULTI" && vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== rowDataTemp.METHOD_0) {
									let tempGPAllDetails = [];
									 if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION === "Gross Income") {
										 tempGPAllDetails = vm.allTransCategoryData;										
			                   			} else {
			                   				tempGPAllDetails = vm.allGPTransCategoryData;
			                   			}
									 
									 vm.selectedBaseTypeCode = tempGPAllDetails;
									 if (vm.isMethodChangedManuallyEdit) {
										 vm.baseTypeData = tempGPAllDetails;
										 console.log("edit new");
									 }
								 
								} else if(rowDataTemp.BASE_TYPE_0 === "ALL" || vm.editCount > 1 ||  rowDataTemp.BASE_TYPE_0 === undefined  || vm.methodChangeCount !== 0){		
											vm.selectedBaseTypeCode = [];	
											$scope.$applyAsync(function () {
													//vm.selectedBaseTypeCode = [];
													// setTimeout(function(){
														vm.selectedBaseTypeCode = vm.baseTypeData;
														console.log("2");
														console.log("edit new");
													// },10);
													
												});
											}
								
																	/*else{
												vm.selectedBaseTypeCode = [];
												$scope.$applyAsync(function () {
												vm.selectedBaseTypeCode = vm.selectedBaseTypeCodeTemp;
												});
											}*/
						}else{
						vm.selectedBaseTypeCode = [];
	                    var basketDataTemp = [];
	                   
	                    if(vm.baseTypeData ){
	                         
							$scope.$applyAsync(function () {
									vm.selectedBaseTypeCode = vm.baseTypeData;
									console.log("3");
								});
							console.log("edit new");	
	                        
	                    }
						
								}
	                      
	                    	}  
                    	
                    	
						  // vm.flagForNewUpdate++;
	                    }
              );

			  
			  $scope.$watchCollection(
                    function getValue() {
                            return( [ vm.selectedMethodCode ] );
                    },
                    function collectionChanged( newValue, oldValue ,$scope) {
                    	//console.log(vm.selectedMethodCode, !vm.loadingData , !vm.resetOption,"Check",(vm.selectedMethodCode && !vm.loadingData && !vm.reset));
                    	
                    	if(vm.isMethodChangedManually || vm.transCatgMapDesc === "Y") {
                    		vm.changeMethod(false);
                    		if(vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION !=  'Allocate')
                    			vm.flagForNewUpdate++;
                    		
                    		return;
                    	}
                    	if(vm.selectedMethodCode && !vm.loadingData && !vm.resetOption)
                    	{  
                    		vm.editCount = ++vm.editCount;
                    	}
    					console.log(vm.editCount,"Count");
						vm.changeMethod(true);
						if(vm.defaultBaseFdii == undefined && (vm.selectedCodeCombination || vm.selectedAttributeRow[0]))
						  vm.getDefaultsPopulateData();
						
						if(vm.selectedMethodCode ) {
							
						
						if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData && vm.selectedMethodCode 
								&& vm.selectedMethodCode.CODE_DESCRIPTION === vm.gridDataMultipleSelect.rowData.METHOD_0 && vm.editCount <= 1 ) {
									vm.checkForReset = false;
								}else {
									 vm.checkForReset = true; 
								}
						}
						
						/*if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.rowData){
						vm.selectedBaseFdiiCodes = [];
						$scope.$applyAsync(function () {
							vm.selectedBaseFdiiCodes = vm.baseFDIIData;
							vm.selectedBaseBasketCode = vm.baseBasketData;
							vm.selectedBaseTypeCode = vm.baseTypeData;
							});
						}*/
					}
              );
			 
			 
			 $scope.$watchCollection(
                    function getValue() {
                            return( [ vm.selectedBaseLevelCode ] );
                    },
                    function collectionChanged( newValue, oldValue ,$scope) {
						vm.baseLevelData && vm.baseLevelData.filter(function(item){
									if(item.CODE_KEY === vm.selectedBaseLevelCode){
										vm.selectedBaseLevelCodeDESC = item.CODE_DESCRIPTION;
									}
									
							})
						//vm.changeMethod();
					
					if(vm.selectedBaseLevelCode){
						
						var exists = _.find(vm.baseLevelData, ['CODE_KEY', vm.selectedBaseLevelCode]);
						if(exists && exists.CODE_DESCRIPTION === "ALL ME" ){
							$scope.$applyAsync(function () {
										 vm.selectedBaseTypeCode = vm.baseTypeData;
										 console.log("edit new");
							});
						}
					}
					}
              );
			 
				 
			  
			  
			   
              vm.getCodeCombinationData = function() {
					var params = {
							"action_code" : 'g9oqag',
							'p_scenario': vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.codeCombinationData = data.jsonObject;
							 if($state.params && $state.params.drillDown && $state.params.gridFilters && $state.params.gridFilters.length ){
			                		
							// if($state.params && $state.params.drillDown && $state.params.gridFilters.gridFilters && $state.params.gridFilters.gridFilters.length ){
		                		 
								/*var	leid = 
									(( _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['LEID'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['LEID'], p.predicate); })[0].value.length == 1)  
										?  _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['LEID'], p.predicate); })[0].value[0] : '' );
								var	cdr = ((_.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['CDR_NO'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['CDR_NO'], p.predicate); })[0].value.length == 1)
										?  _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['CDR_NO'], p.predicate); })[0].value[0] : '' ); 
								var	rp = ((_.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['REPORTING_PERIOD'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['REPORTING_PERIOD'], p.predicate); })[0].value.length == 1)
										?  _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['REPORTING_PERIOD'], p.predicate); })[0].value[0] : '' );
								var	me = ((_.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['ME_CODE'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['ME_CODE'], p.predicate); })[0].value.length == 1)
										?  _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['ME_CODE'], p.predicate); })[0].value[0] : '' );
								
								vm.gie_short_desc =  ((_.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['GIE_SHORT_DESC'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['GIE_SHORT_DESC'], p.predicate); })[0].value.length == 1)
										?  _.filter($state.params.gridFilters.gridFilters, function(p){return _.includes(['GIE_SHORT_DESC'], p.predicate); })[0].value[0] : '' );

								*/
								 
								 var	leid = 
										(( _.filter($state.params.gridFilters, function(p){return _.includes(['LEID'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters, function(p){return _.includes(['LEID'], p.predicate); })[0].value.length == 1)  
											?  _.filter($state.params.gridFilters, function(p){return _.includes(['LEID'], p.predicate); })[0].value[0] : '' );
									var	cdr = ((_.filter($state.params.gridFilters, function(p){return _.includes(['CDR_NO'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters, function(p){return _.includes(['CDR_NO'], p.predicate); })[0].value.length == 1)
											?  _.filter($state.params.gridFilters, function(p){return _.includes(['CDR_NO'], p.predicate); })[0].value[0] : '' ); 
									var	rp = ((_.filter($state.params.gridFilters, function(p){return _.includes(['REPORTING_PERIOD'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters, function(p){return _.includes(['REPORTING_PERIOD'], p.predicate); })[0].value.length == 1)
											?  _.filter($state.params.gridFilters, function(p){return _.includes(['REPORTING_PERIOD'], p.predicate); })[0].value[0] : '' );
									var	me = ((_.filter($state.params.gridFilters, function(p){return _.includes(['ME_CODE'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters, function(p){return _.includes(['ME_CODE'], p.predicate); })[0].value.length == 1)
											?  _.filter($state.params.gridFilters, function(p){return _.includes(['ME_CODE'], p.predicate); })[0].value[0] : '' );
									
									vm.gie_short_desc =  ((_.filter($state.params.gridFilters, function(p){return _.includes(['GIE_SHORT_DESC'], p.predicate); }).length != 0  && _.filter($state.params.gridFilters, function(p){return _.includes(['GIE_SHORT_DESC'], p.predicate); })[0].value.length == 1)
											?  _.filter($state.params.gridFilters, function(p){return _.includes(['GIE_SHORT_DESC'], p.predicate); })[0].value[0] : '' );

								var exists = _.find(vm.codeCombinationData, ['ME_COMB', leid+'_'+cdr+'_'+rp+'_'+me]);
								if (exists != undefined)
			                		vm.selectedCodeCombination = exists;
		                  }
							/* var comp_def_check = _.find(vm.selectedCodeCombination, ['IS_COMP_DEF']);
			                	if ( comp_def_check == "N"){
									AlertService.add("error", "Please set comp defaults.",4000);
									return;
								}					
			                	else */
			                		vm.getGrossIncExpData();
					}); 
				}
				
				vm.getGrossIncExpData = function() {
					var params = {
							"action_code" : '24mnso',
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.grossIncExpData = data.jsonObject;
							if(vm.gie_short_desc !=undefined){
								var exists = _.find(vm.grossIncExpData, ['GIE_DESC', vm.gie_short_desc]);
			                	if(exists != undefined){
			                		vm.selectedGrossIncExp = exists;
				                	vm.getTransCategoryData();
			                	}
							}
							
					});
				}
				
				
				vm.getTransCategoryData = function() {
					var params = {
							"action_code" : '1sdudl',
							"cc_key" : vm.selectedCodeCombination.KEY,
							'gie_name': vm.selectedGrossIncExp.GIE_NAME,
							'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
						};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.transCategoryData = data.jsonObject;
							vm.baseTypeData = data.jsonObject;
							console.log("edit new");
							var exists = _.find(vm.allTransCategoryData, ['GIE_NAME', 'GPROFIT'])
                            if(exists != undefined)
                                vm.allGPTransCategoryData = exists;
                            
                            vm.allGPTransCategoryData = _.filter(vm.allTransCategoryData, function(p){
                                return _.includes(['GPROFIT'],  p.GIE_NAME);
                                })
                                
							
							vm.getCodeMastData();
                            
                           
					});
					
					vm.selectedTransCat=undefined;
					vm.selectedBasketCode=undefined;
					vm.selectedSicCode=undefined;		
					vm.selectedFdiiCode=undefined;
					vm.selectedBeatTypeCode=undefined;
					vm.selectedBeatCodeDesc=undefined;
					vm.selectedRelatedCode=undefined;
					vm.selectedTradingPartnerCode=undefined;
					vm.selectedCountryCode=undefined;
					vm.selectedHtkoCode=undefined;
					vm.selectedFsiCode=undefined;
					vm.selectedSourceTypeCode=undefined;
					vm.selectedProjectTaggingCode=undefined;
					vm.selectedNotes=undefined;
					vm.selectedMethodCode=undefined;
					vm.selectedBaseFdiiCodes=undefined;
					vm.selectedBaseBasketCode=undefined;
					vm.selectedBaseTypeCode=undefined;
					vm.selectedBaseLevelCode=undefined;
					vm.selectedBaseEntityLevelCode=undefined;
					vm.selectedBeatWithhold=undefined;
					
					if(vm.selectedGrossIncExp.GIE_NAME != 'GPROFIT')
                		_.remove(vm.sourceTypeCodeData, {CODE_DESCRIPTION: 'Summary'});
				}
				
				
			//	init();
				
				
				 if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit'){
					vm.selectedGieName = vm.selectedAttributeRow[0].GROSS_INC_EXP;
					vm.getCodeMastData();
				}else{
					vm.getCodeCombinationData();
				}
					
				//Get the CODE_KEY for the given TAX_YEAR CODE_DISP_DESC 
					function getTaxYearKey(taxYearDesc) {
						var selectedTaxYearKey;
						for(var indx in vm.taxYearData){								
							if(vm.taxYearData[indx].CODE_DESC == taxYearDesc){
								selectedTaxYearKey = ''+vm.taxYearData[indx].CODE_KEY;							
							}
						}
						return selectedTaxYearKey;
					};
					
				 vm.tillCurrentTaxYear = function(curentYr, fromPriorYr, tillPriorYr) {
						vm.filteredTaxYearData = [];						
						if(vm.filteredTaxYearData.length == 0){
							for(var indx in vm.taxYearData){
								if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' && (vm.taxYearData[indx].CODE_DESC <= (curentYr-fromPriorYr) && vm.taxYearData[indx].CODE_DESC >= (curentYr-tillPriorYr))){
									vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
								}
							}
							if(vm.selectedAttributeRow[0] && vm.selectedAttributeRow[0].APPLICABLE_YEAR_0 != undefined )
								vm.applicableYearKey =  getTaxYearKey(vm.selectedAttributeRow[0].APPLICABLE_YEAR_0);
							else
								vm.applicableYearKey =  getTaxYearKey(filterParams.tax_year);
						}
						
						/*if(vm.filteredTaxYearForCreditable && vm.filteredTaxYearForCreditable.length == 0){
							for(var indx in vm.taxYearData){								
								if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' && (vm.taxYearData[indx].CODE_DESC <= (curentYr-fromPriorYr) && vm.taxYearData[indx].CODE_DESC >= (curentYr-tillPriorYr))){
									vm.filteredTaxYearForCreditable.push(vm.taxYearData[indx]);
								}
							}
							//Defaulting the Applicable Year to Default Current Year as per Mary Cordier   
							//TODO disable the dropdown for GE with default as Current Year and enable for Other Clients - need to make this change once client specific implementation is done
							vm.applicableYearKey =  getTaxYearKey(filterParams.tax_year);
						}*/
					};
					

					//On load -  populate the Applicable Year drop down 
					$scope.$watch(function() { return vm.taxYearData }, function() {
						if(vm.taxYearData!=undefined){
							vm.tillCurrentTaxYear(vm.curentyear,0,11);
						}
					});	
					
					/*$scope.$watch(function() { return vm.baseTypeData }, function() {
						if(vm.baseTypeData && vm.baseTypeData.length == 0){
							console.log("vm.baseTypeData","length is 0");
						}
					});*/
					
					$scope.$watch(function() { return vm.selectedCodeCombination }, function() {
						if (vm.selectedCodeCombination && vm.selectedCodeCombination['IS_COMP_DEF'] == "N"){
							vm.isError = true;
							AlertService.add("error", "Please set Defaults for the selected Entity.",4000);
						}else{
							vm.isError = false;
						}
					});
					
					
					
					//$scope.$watch(function() { return vm.basketCodeData }, function() {
						 $scope.$watchCollection(
				                    function getValue() {
				                            return( [ vm.basketCodeData,vm.newArray,vm.fdiiCodeData,vm.allDefaultData,vm.methodCodeData,vm.transCategoryData ] );
				                    },
				                    function collectionChanged( newValue, oldValue ,$scope) {
				                    	
				                    
				                    	/*	if(vm.basketCodeData && vm.basketCodeData.length > 0 && vm.allDefaultData && vm.allDefaultData.length >0 ){
							//console.log(newValue,oldValue);
							
							if(vm.selectedBasketCode === undefined){
								var fromAllDefault =_.find(vm.allDefaultData, ['ATTRIB_NAME', 'BASKET']);
								if(fromAllDefault){
									var exists = _.find(vm.basketCodeData, ['CODE_DESCRIPTION', fromAllDefault.CODE_DESCRIPTION]);
				                	if(exists != undefined)
				                	{
				                		vm.selectedBasketCode = exists.CODE_KEY;
				                		vm.selectedBasketCodeDesc = exists.CODE_DESCRIPTION;
				                        
				                		//vm.selectedBasketCode = vm.resetData["selectedBasketCode"];
				                	}
								}
								
							}*/
							
							/*if(vm.fdiiCodeData && vm.fdiiCodeData.length > 0 && vm.selectedFdiiCode === undefined && vm.allDefaultData && vm.allDefaultData.length >0 ){
								var fromAllDefault =_.find(vm.allDefaultData, ['ATTRIB_NAME', 'FDII']);
								if(fromAllDefault){
								var exists = _.find(vm.fdiiCodeData , ['CODE_DESCRIPTION', fromAllDefault.CODE_DESCRIPTION]);
			                	if(exists != undefined){
			                		vm.selectedFdiiCode = exists.CODE_KEY;
									//vm.resetData["selectedFdiiCode"] = exists.CODE_KEY;
								}
								}
							}*/
							
							if(vm.methodCodeData && vm.methodCodeData.length > 0 && vm.transCategoryData && vm.transCategoryData.length > 0  && vm.selectedTransCat && vm.selectedMethodCode == undefined &&  vm.allDefaultData && vm.allDefaultData.length >0){
		                		var exists = _.find(vm.transCategoryData, ['CODE_KEY', vm.selectedTransCat])
		                		var checkWithLabel = "";
		                		if(exists != undefined){
		                			vm.selectTCEData = exists.CODE_DESCRIPTION;
		                		if((vm.selectedGieName === "GPROFIT" &&  vm.selectTCEData === "Prorate Variance") ){
		                			checkWithLabel = "Sales";
		                		}else if ( vm.selectedGieName === "Expenses A&A"){
		                			checkWithLabel = "Best Method - FDII";
		                		}
		                		var exists = _.find(vm.methodCodeData , ['CODE_DESCRIPTION',checkWithLabel]);
		                    	if(exists != undefined){
		                    		
		                    		vm.selectedMethodCode = exists;
		                    		
		                    	}
		                	  }
		                	}
					//	}
					});
					
						 
				vm.changeReset = function(){
					vm.resetOption = false;
				}
				$rootScope.$on('showSourcePage', function (event, args) {
		            const {data} = args
		            vm.showSoucePage(data,vm.isSourceFlag);
		        });
				 
				 $scope.openModal = function (modalObj, _data) {
			            $scope.rowData = _data.rowData;
			            $scope.gridData = _data;
			            $scope.colData = _data.colData;


			            var separator = 'c='
			            if (!GENERAL_CONFIG.cache_templates) {
			                separator += Math.random().toString(36).slice(2);
			            } else {
			                separator += SERVER_CONFIG.configSettings.release_version;
			            }

			            var modalInstance = $uibModal.open({
			                animation: true,
			                templateUrl: modalObj.template + '?' + separator,
			                controller: modalObj.controler,
			                size: modalObj.size,
			                backdrop: modalObj.backdrop, // added by Omi-07/24/2019(for disabling the modal outside click)
			                resolve: {
			                    rowData: function () {
			                        return $scope.rowData;
			                    },
			                    colData: function () {
			                        return $scope.colData;
			                    },
			                    gridData: function () {
			                        return $scope.gridData;
			                    }
			                }
			            });

			            modalInstance.result.then(function (selectedItem) {
			                $scope.selected = selectedItem;
			            }, function () {
			                $log.info('Modal dismissed at: ' + new Date());
			                $log.info('$scope.rowData', $scope.rowData);
			            });
			        };



				
				vm.showSoucePage = function(data,isFlag, tagKeyNew){
					if(isFlag){
						let tempColInfo = {
							    "grid_header_key": "568755",
							    "grid_key": "204",
							    "label": "Source",
							    "full_label": null,
							    "map": "EDIT",
							    "width": 80,
							    "data_type": "string",
							    "format": null,
							    "edit_type": "form",
							    "validation": null,
							    "editable": "y",
							    "display": "y",
							    "call_back": "newTagSummaryFTC",
							    "header_class": null,
							    "col_class": "",
							    "drilldown": null,
							    "priority": 2,
							    "new_row_editable": "0",
							    "static_values": null,
							    "required": "0",
							    "data_api": null,
							    "apply_rules": null,
							    "edit_rules": null,
							    "edit_rule_message": null,
							    "edit_icon": "edit",
							    "object_id": "1"
							  };
									let rowDataInfo = data.length === 1 ? data[0]:data.find((item) => item.TAG_KEY === tagKeyNew.toString());
									let sourceGridInfo = {_callName:"newTagSummaryFTC",_data:{rowData:rowDataInfo,colData:tempColInfo},_type:"form"};
									ModalFactory.getModal(sourceGridInfo._callName).then(function (data) {
			                            var modalObj = {};
			                            modalObj.template = data.modalScreen.modal_template;
			                            modalObj.controler = data.modalScreen.modal_controler;
			                            modalObj.size = data.modalScreen.modal_size;
			                            modalObj.backdrop = "static"; // added by Omi-07/24/2019(for disabling the modal outside click)
			                            $scope.openModal(modalObj, sourceGridInfo._data);
			                        })
									vm.isSourceFlag = false;
									
					}
				}
				
				vm.getCurrentUpdatedRecord = function(params,taxKeyNew){
					var _params = {}
					if (taxKeyNew) {
						_params = {
								object_id:undefined,
	                               gridFilter: {
	                            	   object_id: undefined
	                               }
	                        };
					}else{
						_params = params;
					}
					
					 var filterObj = (typeof _params.gridFilter !== 'undefined') ? _params.gridFilter : null;
			            delete _params['gridFilter'];
			            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
			            var params = _.merge({}, filterParams, _params);
			            $scope.$broadcast("showDataRefresh", true);
			            GridFactory.getDataUpdate($state.current.api.get, params).then(function (dataInfo) {
			            	const {data}=dataInfo;
						 	vm.showSoucePage(data,vm.isSourceFlag,taxKeyNew);
						 	$scope.$broadcast("showDataRefresh", false);
						});
				}
	// Save Proc when adding a new Tag Attribute			
				vm.save = function(isSourceFlag) {
		            /* if (vm.crudloadig){
		            	 AlertSerice.add("info","Please select atleast one Attribute");
		            	 return;
		             }
		             vm.crudLoading= true;*/
					vm.isSourceFlag = isSourceFlag;
					vm.isSaveClicked = true; 
					var returnClobSettingsObj = {};
					var attribManagementChangeDetails = [];
					var returnObj = {};
					/*if(vm.newArray === undefined){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select values before proceding", 4000);
							return;
					}*/
					
	
			        if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit'){
						tag_key = vm.selectedAttributeRow[0].TAG_KEY;
						returnObj['COMBINATION_KEY'] =  vm.selectedAttributeRow[0].COMBINATION_KEY;
						returnObj['TAG_KEY'] = vm.selectedAttributeRow[0].TAG_KEY;
						returnObj['GIE_KEY'] = vm.selectedAttributeRow[0].GIE_KEY;
						returnObj['OPERATION_TYPE'] = 'MU';
					}else if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.length > 0){
						tag_key = vm.gridDataMultipleSelect[0].data.TAG_KEY;
                        returnObj['COMBINATION_KEY'] =  vm.gridDataMultipleSelect[0].data.COMBINATION_KEY;
                        returnObj['TAG_KEY'] = vm.gridDataMultipleSelect[0].data.TAG_KEY;
                        returnObj['GIE_KEY'] = vm.gridDataMultipleSelect[0].data.GIE_KEY;
                        returnObj['OPERATION_TYPE'] = 'BU';
                    }else{
						if(vm.selectedCodeCombination == undefined ||  vm.selectedGrossIncExp == undefined ||  vm.selectedTransCat == undefined ){
								
							AlertService.add("error", "Please select Entity, Gross Inc/Exp and Trans Category to proceed", 4000);
							vm.isSaveClicked = false;
								return;
						}
							
						returnObj['COMBINATION_KEY'] =  vm.selectedCodeCombination.KEY;
						returnObj['GIE_KEY'] = vm.selectedGrossIncExp.GIE_KEY;
						returnObj['OPERATION_TYPE'] = 'I';
					}
			        
			        var exists = _.find(vm.relatedCodeData , ['CODE_DESCRIPTION','UnRelated']);
                	if(exists != undefined){
                		if(vm.selectedRelatedCode == exists.CODE_KEY)
                			vm.disableTP = true;
                		else
                			vm.disableTP = '';
             		}
			        
			        if(vm.newArray.indexOf('Trading Partner') > -1 && vm.disableTP != true){
						if(vm.selectedTradingPartnerCode == undefined || vm.selectedTradingPartnerCode == '' ){
							AlertService.add("error", "Please select Trading Partner to proceed", 4000);
							vm.isSaveClicked = false;
								return;
						}else{
							if(vm.gridDataMultipleSelect && vm.gridDataMultipleSelect.colData && vm.gridDataMultipleSelect.colData.map == 'Edit')
								returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'ACTUAL_TP_KEY']).ATTRIB_KEY +'_0'] = vm.selectedTradingPartnerLEID;
							else
								returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'ACTUAL_TP_KEY']).ATTRIB_KEY +'_0'] = vm.selectedTradingPartnerCode;
						}
					}
			        
					if(vm.newArray.indexOf('Country') > -1){
						if(vm.selectedCountryCode == undefined || vm.selectedCountryCode == '' ){
							AlertService.add("error", "Please select Country to proceed", 4000);
							vm.isSaveClicked = false;
								return;
						}else
							returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = vm.selectedCountryCode;
					}

		
					returnObj['TRANS_CATG_KEY'] = vm.selectedTransCat;
					
					if(vm.newArray &&  vm.newArray.indexOf('Basket') > -1)
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = vm.selectedBasketCode;
					
					if(vm.newArray && vm.newArray.indexOf('SIC Code') > -1)
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'SIC_CODES']).ATTRIB_KEY +'_0'] = vm.selectedSicCode;
					
					if(vm.newArray &&  vm.newArray.indexOf('FDII') > -1)
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'FDII']).ATTRIB_KEY +'_0'] = vm.selectedFdiiCode;
					
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT']).ATTRIB_KEY +'_0'] = vm.selectedBeatCode;
					if(vm.selectedBeatTypeCode && vm.selectedBeatTypeCode != '')
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT_TYPE']).ATTRIB_KEY +'_0'] = vm.selectedBeatTypeCode;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'RELATED_UNRELATED']).ATTRIB_KEY +'_0'] = vm.selectedRelatedCode;
					/*if(vm.selectedTradingPartnerCode != null){
						var exists = _.find(vm.tradingPartnerCodeData, ['LE_NAME', vm.selectedTradingPartnerCode]);
	                	if(exists != undefined){
	                		vm.selectedTradingPartnerLEID = exists.COMBINATION_KEY;
	                	}
						//returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'TP_FEED']).ATTRIB_KEY +'_0'] = vm.selectedTradingPartnerCode;//selectedTradingPartnerLEID vm.selectedTradingPartnerCode;
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'ACTUAL_TP_KEY']).ATTRIB_KEY +'_0'] = vm.selectedTradingPartnerCode;
					}*/
					
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'HTKO']).ATTRIB_KEY +'_0'] = vm.selectedHtkoCode;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'FSE']).ATTRIB_KEY +'_0'] = vm.selectedFsiCode;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'SOURCE_TYPE']).ATTRIB_KEY +'_0'] = vm.selectedSourceTypeCode;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'PROJECT_TAGGING']).ATTRIB_KEY +'_0'] = vm.selectedProjectTaggingCode;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'NOTES']).ATTRIB_KEY +'_0'] = vm.selectedNotes;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'APPLICABLE_YEAR']).ATTRIB_KEY +'_0'] = vm.applicableYearKey;
					returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'LINE_NO']).ATTRIB_KEY +'_0'] = vm.selectedLineNo;
					if(vm.selectedBeatWithhold >= 0)
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT_WITHHOLD']).ATTRIB_KEY +'_0'] = vm.selectedBeatWithhold;
					
					if(vm.displaySingleSelectBase == 'true'){
						if(vm.selectedBaseFdiiCodes != '' && vm.selectedBaseFdiiCodes != undefined &&  vm.newArray.indexOf('Base FDII') > -1 ){
							returnObj['BASE_FDII_DATA'] = vm.selectedBaseFdiiCodes;
							var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','SINGLE']);
		                	if(exists != undefined)
		                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}
						
						if(vm.selectedBaseBasketCode != '' && vm.selectedBaseBasketCode != undefined && vm.newArray.indexOf('Base Basket') > -1){
							returnObj['BASE_BASKET_DATA'] =	vm.selectedBaseBasketCode;
							var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','SINGLE']);
		                	if(exists != undefined)
		                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}
					}else{
						if(vm.newArray.indexOf('Base FDII') > -1){
							var baseFdiiSelected ='';
							for ( var data in vm.selectedBaseFdiiCodes) {
								if (vm.selectedBaseFdiiCodes[data] != undefined && vm.selectedBaseFdiiCodes[data].CODE_KEY != undefined) {
									baseFdiiSelected = baseFdiiSelected.concat(vm.selectedBaseFdiiCodes[data].CODE_KEY);
									baseFdiiSelected = baseFdiiSelected.concat(",");
								}
							}
							returnObj['BASE_FDII_DATA'] = baseFdiiSelected.substring(0,(baseFdiiSelected.length -1));
							

							if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length == 1){
								var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','SINGLE']);
			                	if(exists != undefined && vm.newArray.indexOf('Base FDII') > -1)
			                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
			                 // }else if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length > 1){
			                	// adding this for All in future which we have to uncomment
							}else if(vm.selectedBaseFdiiCodes != undefined && vm.baseFDIIData && vm.selectedBaseFdiiCodes.length === vm.baseFDIIData.length){
								var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','ALL']);
			                	if(exists != undefined)
			                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
							}else  if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length > 1){
								var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','MULTI']);
			                	if(exists != undefined )
			                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
							}
						
						}
						
						if(vm.newArray.indexOf('Base Basket') > -1){
							var baseBasketSelected ='';
							for ( var data in vm.selectedBaseBasketCode) {
								if (vm.selectedBaseBasketCode[data] != undefined && vm.selectedBaseBasketCode[data].CODE_KEY != undefined) {
									baseBasketSelected = baseBasketSelected.concat(vm.selectedBaseBasketCode[data].CODE_KEY);
									baseBasketSelected = baseBasketSelected.concat(",");
								}
							}
							returnObj['BASE_BASKET_DATA'] = baseBasketSelected.substring(0,(baseBasketSelected.length -1));

							if(vm.selectedBaseBasketCode != undefined && vm.selectedBaseBasketCode.length == 1){
								var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','SINGLE']);
			                	if(exists != undefined && vm.newArray.indexOf('Base Basket') > -1)
			                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
			                	// adding this for All in future which we have to uncomment
							}else if(vm.selectedBaseBasketCode != undefined && vm.baseBasketData && vm.selectedBaseBasketCode.length === vm.baseBasketData.length){
								var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','ALL']);
			                	if(exists != undefined)
			                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
							}else if(vm.selectedBaseBasketCode != undefined && vm.selectedBaseBasketCode.length > 1){
								var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','MULTI']);
			                	if(exists != undefined)
			                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
							}
						
						}
					}
					
					if(vm.newArray.indexOf('Method') > -1 && vm.selectedMethodCode != undefined)
						returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'METHOD']).ATTRIB_KEY +'_0'] =vm.selectedMethodCode.CODE_KEY;
					if(vm.newArray.indexOf('Base Level') > -1)
							returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_LEVEL']).ATTRIB_KEY +'_0'] = vm.selectedBaseLevelCode;
					if(vm.newArray.indexOf('Base Entity Level') > -1)
							returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_ENTITY_LEVEL']).ATTRIB_KEY +'_0'] = vm.selectedBaseEntityLevelCode;
					
					
					if(vm.newArray.indexOf('Base Type') > -1){
						var baseTypeSelected ='';
						for ( var data in vm.selectedBaseTypeCode) {
							if (vm.selectedBaseTypeCode[data] != undefined && vm.selectedBaseTypeCode[data].CODE_KEY != undefined) {
								baseTypeSelected = baseTypeSelected.concat(vm.selectedBaseTypeCode[data].CODE_KEY);
								baseTypeSelected = baseTypeSelected.concat(",");
							}
						}
						returnObj['BASE_TYPE_DATA'] = baseTypeSelected.substring(0,(baseTypeSelected.length -1));
						
						
						if(vm.selectedBaseTypeCode != undefined && vm.selectedBaseTypeCode.length == 1){
							var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','SINGLE']);

		                	if(exists != undefined && vm.newArray.indexOf('Base Type') > -1)
		                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						//}else if(vm.selectedBaseTypeCode != undefined &&  vm.selectedBaseTypeCode.length > 1){
							// adding this for All in future which we have to uncomment
						}else if(vm.selectedBaseTypeCode != undefined && vm.baseTypeData && vm.selectedBaseTypeCode.length === vm.baseTypeData.length){
							var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','ALL']);
		                	if(exists != undefined)
		                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}else  if(vm.selectedBaseTypeCode != undefined &&  vm.selectedBaseTypeCode.length > 1){
							var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','MULTI']);
		                	if(exists != undefined && vm.newArray.indexOf('Base Type') > -1)
		                		returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
						}
						
					}
					
					// adding this for All in future which we have to uncomment
					if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_FDII_DATA'] && vm.baseFDIIData 
					&& returnObj['BASE_FDII_DATA'].split(",").length === vm.baseFDIIData.length){
						 returnObj['BASE_FDII_DATA'] = "";
					}
					
					if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_BASKET_DATA'] && vm.baseBasketData 
					&& returnObj['BASE_BASKET_DATA'].split(",").length === vm.baseBasketData.length){
						 returnObj['BASE_BASKET_DATA'] = "";
					}
					
					if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_TYPE_DATA'] && vm.baseTypeData 
					&& returnObj['BASE_TYPE_DATA'].split(",").length === vm.baseTypeData.length){
						 returnObj['BASE_TYPE_DATA'] = "";
					}
					
					
					var message = "Tag has been successfully saved/updated";
					attribManagementChangeDetails.push(returnObj);
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['tag_key'] = tag_key;
					returnClobSettingsObj['p_tax_year'] = vm.curentyear;
					returnClobSettingsObj['p_scenario'] = vm.scenario;
					returnClobSettingsObj['p_jcd_key'] = vm.jcd_key;
					
					if(vm.newArray && vm.newArray.indexOf('Base FDII') > -1 && vm.displaySingleSelectBase == 'true' && vm.selectedBaseFdiiCodes && (vm.selectedBaseFdiiCodes == '' || vm.selectedBaseFdiiCodes == null) ){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select Base FDII", 4000);
							return;
					}
					
					
					if(vm.newArray &&  vm.newArray.indexOf('Base Basket') > -1 && vm.displaySingleSelectBase == 'true' && vm.selectedBaseBasketCode && (vm.selectedBaseBasketCode == '' || vm.selectedBaseBasketCode == null) ){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select Base Basket", 4000);
							return;
					}
					
					
					if(vm.newArray &&  vm.newArray.indexOf('Base FDII') > -1 && vm.selectedBaseFdiiCodes && vm.selectedBaseFdiiCodes.length == 0 ){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select Base FDII", 4000);
							return;
					}
					
					if(vm.newArray &&  vm.newArray.indexOf('Base Basket') > -1 && vm.selectedBaseBasketCode && vm.selectedBaseBasketCode.length == 0 ){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select Base Basket", 4000);
							return;
					}
					
					
					if(vm.newArray &&  vm.newArray.indexOf('Base Type') > -1 && vm.selectedBaseTypeCode && vm.selectedBaseTypeCode.length == 0 ){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select Base Type", 4000);
							return;
					}
					
					if(vm.newArray &&  vm.newArray.indexOf('Base Level') > -1 && vm.selectedBaseLevelCode && (vm.selectedBaseLevelCode == ''  || vm.selectedBaseLevelCode == null)){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select Base Level", 4000);
							return;
					}
					
					if(vm.newArray &&  vm.newArray.indexOf('Base Entity Level') > -1 && vm.selectedBaseEntityLevelCode && (vm.selectedBaseEntityLevelCode == ''  || vm.selectedBaseEntityLevelCode == null)){
						vm.isSaveClicked = false;	
						AlertService.add("error", "Please select Base Entity Level", 4000);
							return;
					}
					
					if (vm.newArray &&  vm.newArray.indexOf('Basket')> -1 && vm.selectedBasketCode && (vm.selectedBasketCode == '' || vm.selectedBasketCode == null)){
						 vm.isSaveClicked = false;
						 AlertService.add("error","Please select Basket", 4000);
						 return;
						
					}
					
					if (vm.newArray &&  vm.newArray.indexOf('FDII')> -1 && vm.selectedFdiiCode && (vm.selectedFdiiCode == '' || vm.selectedFdiiCode == null)) {
						vm.isSaveClicked = false;
						AlertService.add ("error","Please select FDII", 4000);
					}
					
					if (vm.newArray &&  vm.newArray.indexOf('Source Type')> -1 && vm.selectedSourceTypeCode && (vm.selectedSourceTypeCode == '' || vm.selectedSourceTypeCode == null)){
						vm.isSaveClicked = false;
						AlertService.add ("error","Please select Source Type", 4000);	
					}
					
					if (vm.newArray &&  vm.newArray.indexOf('SIC Code')> -1 && vm.selectedSicCode && (vm.selectedSicCode == '' || vm.selectedSicCode == null)){
						vm.isSaveClicked = false;
						AlertService.add ("error" , "Please select SIC CODE" , 4000);
					}
					if ((vm.selectedBeatCodeDesc === "Yes" && (vm.selectedBeatWithhold === undefined || vm.selectedBeatWithhold === '' || vm.selectedBeatWithhold === null)) || (vm.selectedBeatWithhold && (vm.selectedBeatWithhold < 0 || vm.selectedBeatWithhold > 100))) {
						vm.isSaveClicked = false;
						AlertService.add("error", "Please select BEAT Withhold between 0 to 100 only ",5000);
						return;
					}
					if(returnObj && returnObj['OPERATION_TYPE'] === 'I'){
						var lockparams = {
								"action_code": 'k5eosd',
							};				
						JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
							vm.tagKeySeq = data.jsonObject[0].TAG_KEY_SEQ;
							returnClobSettingsObj['tag_key'] = vm.tagKeySeq;
							attribManagementChangeDetails[0].TAG_KEY = vm.tagKeySeq;
							var checkLockObj =[];
			                 checkLockObj.push({combination_key:vm.selectedCodeCombination.KEY});
			                 var LockObj = {};
			                 LockObj.modal_name=ModalFactory.response.data.modalScreen.modal_name,
			                 LockObj.lockingObj=checkLockObj;

							sendDetails(returnClobSettingsObj,attribManagementChangeDetails, message,vm.tagKeySeq, LockObj);
						})
					}else{
						sendDetails(returnClobSettingsObj,attribManagementChangeDetails, message,null, null);
					}
					

				};
				
				function sendDetails(returnClobSettingsObj, attribManagementChangeDetails, message,taxKeySeq, LockObj) {
					AttributeServiceFactory.saveDefaults(returnClobSettingsObj,attribManagementChangeDetails, LockObj).then(function(result) {

								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
									vm.isSaveClicked = false;
									AlertService.add("error", result.data.errorMessage, 4000);
									
								} else {
									AlertService.add("success", message, 4000);
									var args = {
											TAG_KEY:returnClobSettingsObj.tag_key,
			                                   gridFilter: {
			                                	   TAG_KEY: returnClobSettingsObj.tag_key
			                                   }
		                                };
									$rootScope.$emit('gridUpdate', args);
									$uibModalInstance.close();
									if(taxKeySeq != null)
									vm.getCurrentUpdatedRecord(args,taxKeySeq);
                            	};

							});
				}
				

				
				
				
		if(gridData  && gridData.colData && gridData.colData.label !== undefined){
					let label = gridData.colData.label;
					let params = {'sso_id':vm.userSettings.user.sso_id,
							'tax_year':vm.curentyear,
							'scenario':vm.scenario,
							'tag_key': gridData.rowData.TAG_KEY};					
					let action = null;
					switch(label){
					case 'Base Basket':
						action = 'pbh5uq';
						vm.title = 'Base Basket';
						break;
					case 'Base Type':
						action = '8ca0bw';
						vm.title = 'Base Type';
						break;
					case 'Base FDII':
						action = '8blhrv';
						vm.title = 'Base FDII';
						break;
					
					}
					if (action){
						
					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code='+action, params).then(function(data) {
						vm.baseData = data.jsonObject;	
						console.log("vm.baseData	:: ",vm.baseData);
			    	
			    	});
				  }
				}
		
		function uniqueData(arrayData,filterBy){
            var elementId = [];

            var newArr = arrayData.filter(el => {
                if (elementId.indexOf(el[filterBy]) === -1) {
                    // If not present in array, then add it
                    elementId.push(el[filterBy]);
                    return true;
                } else {
                    // Already present in array, don't add it
                    return false;
                }
            });
              
              
            return newArr;
        }
        
		// vm.changeReset();
		
		
		}
				
			
		
			return controllers;

		});