define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.F8858-schdC1Ctrl', [])
        .controller('f8858SchdC1Ctrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse','workspaceFactory'
            , 'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', 'form8858ScheduleC1GroupObjFactory', 'ModalFactory', f8858SchdC1Ctrl])
        .controller('f8858SchdC1CtrlModal', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse','workspaceFactory'
            , 'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', 'form8858ScheduleC1GroupObjFactory', 'ModalFactory', '$uibModalInstance', 'rowData', 'colData', 'gridData', f8858SchdC1CtrlModal]);

    function f8858SchdC1Ctrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory
        , AlertService, CommonUtilitiesFactory, GENERAL_CONFIG, form8858ScheduleC1GroupObjFactory, ModalFactory) {
        f8858SchdC1CtrlModal(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory
            , AlertService, CommonUtilitiesFactory, GENERAL_CONFIG, form8858ScheduleC1GroupObjFactory,ModalFactory, null, null, null, null, this);
    }

    function f8858SchdC1CtrlModal(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory
        , AlertService, CommonUtilitiesFactory, GENERAL_CONFIG, form8858ScheduleC1GroupObjFactory,ModalFactory, $uibModalInstance, rowData, colData, gridData,thisObj) {
    	var vm = this||thisObj;
        vm.rowData = rowData||$scope.$parent.rowData;
        vm.invalidSaveMessage = "";
        vm.invalidInputMessage = "";
        vm.invalidInputFlag = true;
        vm.data = {};
        $scope.crudLoading = false;
        $scope.irsF8858ScheduleC1Data = [];
        vm.modalTitle = "Form 8858 - Schedule C-1: Section 987 Gain or Loss Information";
        vm.countries = null;
        vm.currencyValue = vm.rowData.FUNCTIONAL_CURRENCY;
        vm.parentLEName = vm.rowData.PARENT_LEID + " - " + vm.rowData.PARENT_LE_NAME + " / " + vm.rowData.PARENT_CDR_NO;
        console.log(vm.parentLEName);
        var saveParamDtls = [];
        var tempDataObj = {
            num: '',
            description: '',
            value: ''
        };
        //vm.currencies = null;
        vm.currencyDesc = "";
        
        vm.initLoadData = function(){
            
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var param = _.merge({}, GlobalService.globalParams, filterParams);
            param.action_code= "f5sne6";
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', param).then(function (data) {
                vm.filingGroup =  data.jsonObject;              
                getCurrencies();
                loadIRSF8858ScheduleC1data();
               
               
                
                
            });

        };
        vm.initLoadData();

        if(angular.isObject($uibModalInstance)) {
            vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
            vm.cancel = function () {
                var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
                $rootScope.$emit('gridUpdate', args);
                $uibModalInstance.close();
                vm.modal_name = null;
            };
           // $scope.showCancel = true;
        } else {
            vm.modal_name = null;
                    }
     
        function getCurrencies() {
            
            var params = {
            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            };

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3ucwjb", params)
            .then(function(data) {
            if (data.callSuccess === "1") {
            vm.currencies = data.jsonObject;
            getCurrencyDisplayName();
            }
               });
               }
       
        

        function getCurrencyDisplayName() {
            for(var i = 0; i < vm.currencies.length; i++)
            {
                if(vm.currencies[i].CURR_CODE === vm.currencyValue)
                {
                    vm.currencyDesc  = vm.currencies[i].CURR_DESC;
                    break;
                }
            }
        }
       // getCurrencyDisplayName();

        function loadIRSF8858ScheduleC1data() {
            var params = {
                "action_code": 'hc0c3w',
                "tax_year": GlobalService.globalParams.tax_year,
                "scenario": GlobalService.globalParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "irs_form_no": 'F8858',
                "combination_key": vm.rowData.COMBINATION_KEY
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("data for 8858", data);
                    var irsF8858ScheduleC1Data = data.jsonObject;
                    console.log("irsF8858ScheduleC1Data", irsF8858ScheduleC1Data);
                    var i = 1;
                    var previous_line_no = irsF8858ScheduleC1Data[i].line_no;

                    while (i < irsF8858ScheduleC1Data.length) {
                        var tempObj = {};
                        var j = i;
                        var count = 0;
                        while (j < irsF8858ScheduleC1Data.length && previous_line_no === irsF8858ScheduleC1Data[j].line_no) {
                            if (irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_NO") {
                                tempObj.propertyType = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.line_no = irsF8858ScheduleC1Data[j].line_no;
                                tempObj.saveParams = {
                                    "tax_year": irsF8858ScheduleC1Data[j].object_id.split("~")[0],
                                    "trans_type_key": irsF8858ScheduleC1Data[j].object_id.split("~")[1],
                                    "trans_Details_key": irsF8858ScheduleC1Data[j].object_id.split("~")[3],
                                    "occurrence": irsF8858ScheduleC1Data[j].object_id.split("~")[4],
                                    "form_key": irsF8858ScheduleC1Data[j].object_id.split("~")[6]
                                }
                            } else if (irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_DESCRIPTION") {
                                tempObj.line_description = irsF8858ScheduleC1Data[j].attrib_value.split("~")[3];
                            }  
                            else if(irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_1_VALUE_8858_A")
                            {tempObj.propertyType = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.line_description1 = irsF8858ScheduleC1Data[j].attrib_value.split("~")[3];
                                tempObj.value = {
                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
                                    "line_attrib_key":irsF8858ScheduleC1Data[j].attrib_value.split("~")[1],
                                    "attrib_name" : irsF8858ScheduleC1Data[j].attrib_value.split("~")[0]
                                }
                            }else if (irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_1_VALUE_8858_B") {
                                tempObj.propertyType1 = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.line_description1 = irsF8858ScheduleC1Data[j].attrib_value.split("~")[3];
                                tempObj.value_1 = {
                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
                                    "line_attrib_key": irsF8858ScheduleC1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF8858ScheduleC1Data[j].attrib_value.split("~")[0]
                                };

                            }else if (irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_2_VALUE_8858_A") {
                                tempObj.propertyType = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.line_description1 = irsF8858ScheduleC1Data[j].attrib_value.split("~")[3];
                                tempObj.value = {
                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
                                    "line_attrib_key": irsF8858ScheduleC1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF8858ScheduleC1Data[j].attrib_value.split("~")[0]
                                };
                            } else if (irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_2_VALUE_8858_B") {

                                tempObj.propertyType1 = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.line_description1 = irsF8858ScheduleC1Data[j].attrib_value.split("~")[3];
                                tempObj.value_1 = {
                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
                                    "line_attrib_key": irsF8858ScheduleC1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF8858ScheduleC1Data[j].attrib_value.split("~")[0]
                                };
                            }else if (irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_3_VALUE_8858_A") {
                                tempObj.propertyType = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.line_description1 = irsF8858ScheduleC1Data[j].attrib_value.split("~")[3];
                                tempObj.value = {
                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
                                    "line_attrib_key": irsF8858ScheduleC1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF8858ScheduleC1Data[j].attrib_value.split("~")[0]
                                };
                            } else if (irsF8858ScheduleC1Data[j].attrib_value.split("~")[0] === "LINE_3_VALUE_8858_B") {

                                tempObj.propertyType1 = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.line_description1 = irsF8858ScheduleC1Data[j].attrib_value.split("~")[3];
                                tempObj.value_1 = {
                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
                                    "line_attrib_key": irsF8858ScheduleC1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF8858ScheduleC1Data[j].attrib_value.split("~")[0]
                                };

                            } else
                            {if (count > 0) {
                        		if (count == 1) {
                        			tempObj.attrib_name = [tempObj.attrib_name];
                        			tempObj.value = [tempObj.value];
                        		}
                    			tempObj.attrib_name[count] = irsF8858ScheduleC1Data[j].attrib_value.split("~")[0];
                    			tempObj.value[count] = {
	                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
	                                    "line_attrib_key": irsF8858ScheduleC1Data[j].attrib_value.split("~")[1]
	                             }
                        	} else {
                            	tempObj.propertyType = irsF8858ScheduleC1Data[j].PROPERTY_TYPE;
                                tempObj.isEditableFlag = 'Y';
                             
                                tempObj.attrib_name = irsF8858ScheduleC1Data[j].attrib_value.split("~")[0];
                                tempObj.value = {
                                    "VALUE": irsF8858ScheduleC1Data[j].VALUE,
                                    "line_attrib_key": irsF8858ScheduleC1Data[j].attrib_value.split("~")[1]
                                }
                        	}
                            }
                            previous_line_no = irsF8858ScheduleC1Data[j].line_no;
                            if (irsF8858ScheduleC1Data[j].PROPERTY_TYPE == 13 ) {
                    			count++;
                    		}
                            j++;
                        }
                        if(j < irsF8858ScheduleC1Data.length)
                            previous_line_no = irsF8858ScheduleC1Data[j].line_no;
                        var tempIndex =  $scope.irsF8858ScheduleC1Data.length;
                        $scope.irsF8858ScheduleC1Data.push(tempObj);
                        i = j;
                    }
                    
                } else {
                    if (data.errorMessage === "Access Denied") {
                        AlertService.add("", "Sorry you do not have access to do the requested action.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
                vm.originalData = angular.copy($scope.irsF8858ScheduleC1Data);
            });
        }
        

        vm.saveScheduleC1Form = function () {

            var editRow = [];
            var addRow = [];
            var deleteRow = [];
            var scheduleSettings = [{
                "tax_year":GlobalService.globalParams.tax_year,
                "scenario": GlobalService.globalParams.scenario,
                "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key}];

            if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            $scope.crudLoading = true;
            vm.checkingForValidSave($scope.irsF8858ScheduleC1Data);

            if($scope.irsF8858ScheduleC1Data.length > 0) {
                var saveObject = [];
                var temporaryObject = $scope.irsF8858ScheduleC1Data;

                for (var loopIndex = 0; loopIndex < temporaryObject.length; loopIndex++){
                    if(loopIndex < 3){
                        for(var key in temporaryObject[loopIndex]) {
                            if (!temporaryObject[loopIndex].hasOwnProperty(key)) continue;

                            var obj = temporaryObject[loopIndex][key];

                            if (obj.hasOwnProperty('attrib_name')) {
                                var value = angular.isDefined(obj.VALUE)? obj.VALUE : 0;
                                var lineAttribKey = obj.line_attrib_key;
                                var attribName = obj.attrib_name;

                                saveObject = [{
                                    "tax_year": GlobalService.globalParams.tax_year,
                                    "trans_type_key": temporaryObject[loopIndex].saveParams.trans_type_key,
                                    "combination_key": vm.rowData.COMBINATION_KEY,
                                    "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                                    "form_key": temporaryObject[loopIndex].saveParams.form_key,
                                    "line_no": temporaryObject[loopIndex].line_no,
                                    "occurence": ((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
                                    "line_attrib_key": lineAttribKey,
                                    "Attribute_Name": attribName,
                                    "Attribute_Value": value,
                                    "trans_dtls_key": ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
                                    "trans_status": "A"
                                }];
                                editRow.push(saveObject);
                            }
                        }
                    }                   

                    if (temporaryObject[loopIndex].hasOwnProperty('propertyType') &&
                    		temporaryObject[loopIndex].propertyType==13) {
                    	for (var k=0; k < temporaryObject[loopIndex].attrib_name.length; k++) {
                    saveObject = [{
                        "tax_year": GlobalService.globalParams.tax_year,
                        "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
                        "combination_key": vm.rowData.COMBINATION_KEY,
                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                        "form_key":temporaryObject[loopIndex].saveParams.form_key,
                        "line_no":temporaryObject[loopIndex].line_no,
                        "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
                        "line_attrib_key" : temporaryObject[loopIndex].value[k].line_attrib_key,
                        "Attribute_Name": temporaryObject[loopIndex].attrib_name[k],
                        "Attribute_Value":temporaryObject[loopIndex].value[k].VALUE,
                        "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
                        "trans_status":"A"
                    }];
                    editRow.push(saveObject);
                    	}
                }
                }
            }

            if(vm.invalidSaveFlag && vm.invalidInputFlag){
                var scheduleC1Details = {"addrows":[], "editrows":editRow, "removerows":[]};
                var message = "Schedule C1 details successfully saved";

                form8858ScheduleC1GroupObjFactory.saveF8858ScheduleC1(scheduleSettings,
                    scheduleC1Details).then(
                    function(result) {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error", result.errorMessage, 4000);
                            $scope.crudLoading = false;
                        } else {
                            vm.saveFlag = true;
                            AlertService.add("success", message, 4000);
                            $scope.crudLoading = false;
                        }
                    });
            }
            else if (!vm.invalidInputFlag){
                AlertService.add("error", vm.invalidInputMessage, 6000);
                $scope.crudLoading = false;
            }
            else {
                AlertService.add("error", vm.invalidSaveMessage, 6000);
                $scope.crudLoading = false;
            }
        };

        vm.checkingForValidSave = function(tempData){
            vm.invalidSaveFlag = true;
            vm.invalidSaveMessage = "Please correct the below information - ";
            if(tempData[0].value.VALUE < 0 || tempData[0].value_1.VALUE < 0){
                vm.invalidSaveFlag = false;
                vm.invalidSaveMessage += " Line 1 FDE/FB Amount or Recipient Amount should be debit balance. ";
                return vm.invalidSaveFlag;
                
            }

            if(tempData[0].value.VALUE > 0 && (tempData[0].value_1.VALUE == 0 || tempData[0].value_1.VALUE == undefined)){
                vm.invalidSaveFlag = false;
                vm.invalidSaveMessage += " Line 1 Recipient Amount is required. ";
                return vm.invalidSaveFlag;
            }
            if(tempData[1].value.VALUE > 0 && (tempData[1].value_1.VALUE == 0 || tempData[1].value_1.VALUE == undefined)){
                vm.invalidSaveFlag = false;
                vm.invalidSaveMessage += " Line 2 Recipient Amount is required. ";
                return vm.invalidSaveFlag;
            }
            if(tempData[2].value.VALUE > 0 && (tempData[2].value_1.VALUE == 0 || tempData[2].value_1.VALUE == undefined)){
                vm.invalidSaveFlag = false;
                vm.invalidSaveMessage += " Line 3 Recipient Amount is required. ";
                return vm.invalidSaveFlag;
            }
           
        };
/*
        vm.validateInput = function(tempData, index){
            var line = tempData.line_no;
            vm.invalidInputFlag = true;
            vm.invalidInputMessage = "";

            if(!angular.isDefined(tempData.value.VALUE) && tempData.value_1.VALUE == 0){
                vm.invalidInputFlag = false; 
                vm.invalidInputMessage += "Line " + line + " - FDE/FB Amount is Required. ";
            }
            else if(!angular.isDefined(tempData.value_1.VALUE) && tempData.value.VALUE !== null) {
                vm.invalidInputFlag = false;
                vm.invalidInputMessage += "Line " + line + " - Recipient Amount is Required. ";
            }

            return vm.invalidInputFlag;
        };*/

        vm.onRadioBtnSelection = function(row, index, value) {
        	if (value === 'Y') {
        		row.value[1].VALUE = null;
        	} else if (value === 'N') {
        		row.value[0].VALUE = null;
        	}
        }
       /* vm.reset = function () {
            $scope.crudLoading = false;
            $scope.irsF8858ScheduleC1Data = angular.copy(vm.formListOriginal);
        }
*/
       /*  save from workflow 5/14/2019 
        $scope.$on('workflowSave',function(event,data){
            vm.saveScheduleC1Form();
        })
*/


    }
});