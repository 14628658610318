define(
    ['angular'

    ],
    function () {
        'use strict';

        var controllers = angular.module(
            'app.entityUpdateController', []).controller(
                'entityUpdateController',
                ['$scope', '$rootScope', '$http', 'GlobalService',
                    '$uibModalInstance', 'ModalFactory',
                    'AlertService', 'rowData', 'gridData',
                    'workspaceFactory', 'JsonObjectFactory',
                    '$timeout', 'GENERAL_CONFIG', 'USER_SETTINGS',
                    'entityUpdateFactory',
                    entityUpdateController])

        function entityUpdateController($scope, $rootScope,
            $http, GlobalService, $uibModalInstance, ModalFactory,
            AlertService, rowData, gridData, workspaceFactory,
            JsonObjectFactory, $timeout, GENERAL_CONFIG, USER_SETTINGS,
            entityUpdateFactory,
        ) {

            var vm = this;
            vm.formData = rowData;
            vm.title = "Entity Update";
            vm.tier = "** SELECT TIER VALUE **";
            vm.columnName = "";
            vm.columnValue = "";
            vm.column_name_data = [];
            vm.clob_settings = [];
            vm.clob_data = [];
            vm.crudLoading = false;
            vm.tableName = "";
            vm.validationInfo = "";
            vm.tierSelected = false;
            vm.columnNameSelected = false;
            vm.TRANS_TYPE_DATA = [];
            vm.transactionData = [];
            vm.isLoading = true;
            vm.screen_key = workspaceFactory.activeScreen.screen_key;
            vm.GT_SYNC_DATA = [];
            vm.cng_type = '';

            vm.init = function () {
                vm.isLoading = true;
                var Promise1 = entityUpdateFactory.getTierData();
                var Promise2 = entityUpdateFactory.getTransType(rowData.COMBINATION_KEY);
                var Promise3 = entityUpdateFactory.getSyncDropdownData();
                Promise.all([Promise1, Promise2, Promise3]).then((response) => {
                    vm.tier_data = response[0].jsonObject;
                    vm.TRANS_TYPE_DATA = response[1].jsonObject ? vm.getDropdownData(response[1].jsonObject) : [];
                    vm.GT_SYNC_DATA = response[2].jsonObject ? response[2].jsonObject : [];
                    vm.isLoading = false;
                    $scope.$digest()
                }).catch((e) => {
                    console.log(e);
                    AlertService.add("error",
                        e, 4000);
                    vm.isLoading = false;
                    $scope.$digest();
                })

            };

            vm.getDropdownData = function (data) {
                return {
                    label: "Transactions",
                    label_name: 'LABEL',
                    param_name: "Transactions",
                    selected: [],
                    value_name: 'VALUE',
                    values: data
                }
            }
            vm.init();

            vm.cleanupChangelog = function() {
                vm.crudLoading = true;
                entityUpdateFactory.entityChangeCleanup(vm.cng_type.VALUE, vm.formData.LE_KEY).then(
                    function (result) {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error",
                                result.errorMessage, 4000);
                            vm.crudLoading = false;

                        } else {
                            AlertService.add("success", "Update Success!", 4000);
                            vm.crudLoading = false;
                            $uibModalInstance.dismiss('cancel');
                            $rootScope
                                .$emit(
                                    'gridUpdate',
                                    {});
                        }
                    });
            }


            vm.changeTier = function () {
                entityUpdateFactory.getColumnNames(vm.tier.VALUE).then(response => {
                    vm.column_name_data = response.jsonObject;
                    vm.tableName = response.jsonObject[0].TABLE_NAME;
                });
                vm.tierSelected = true;
                vm.columnNameSelected = false;
                vm.columnName = "** SELECT COLUMN NAME **";
                vm.columnValue = "";
                vm.validationInfo = "";
            }

            vm.selectTransType = function () {
                vm.transactionData = [];
                vm.TRANS_TYPE_DATA.selected.forEach((data) => {
                    vm.transactionData.push({
                        COMBINATION_KEY: rowData.COMBINATION_KEY,
                        TRANS_TYPE_KEY: data.VALUE
                    })
                })

            }

            vm.changeColumnName = function () {
                vm.columnNameSelected = true;
                vm.validationInfo = `This input field is ${vm.columnName.DATA_TYPE}`;
                if (vm.columnName.DATA_TYPE !== "DATE") {
                    vm.validationInfo += `, and the length is ${vm.columnName.DATA_LENGTH}`;
                }
                if (vm.columnName.DATA_TYPE === "DATE") {
                    vm.validationInfo += `, and the date format should be 'MM/DD/YYYY'`;
                }
                if (vm.columnName.NULLABLE === "N") {
                    vm.validationInfo += ', and should not be null';
                }
            }
            vm.cancel = function () {

                $uibModalInstance.dismiss('cancel');

            };

            vm.checkValidations = function () {
                if (vm.columnName.DATA_TYPE !== "DATE") {
                    if (vm.columnValue.length > vm.columnName.DATA_LENGTH) {
                        AlertService.add("error",
                            `Length of Column Value is greater than ${vm.columnName.DATA_LENGTH}`, 4000);
                        vm.crudLoading = false;
                        return false;
                    }
                }
                else if (vm.columnName.DATA_TYPE === "DATE") {
                    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                    if (!(date_regex.test(vm.columnValue))) {
                        AlertService.add("error",
                            `Date should be in correct format of MM/dd/yyyy`, 4000);
                        vm.crudLoading = false;
                        return false;
                    }
                }

                if (vm.columnName.NULLABLE === "N" && vm.columnValue === "") {
                    AlertService.add("error",
                        `Column Value cannot be null`, 4000);
                    vm.crudLoading = false;
                    return false;
                }

                return true;
            }

            vm.save = function () {
                vm.crudLoading = true;
                if (vm.checkValidations()) {
                    vm.formData.COLUMN_NAME = vm.columnName.COLUMN_NAME;
                    vm.formData.COLUMN_VALUE = vm.columnValue;
                    vm.clob_data.push(vm.formData);
                    entityUpdateFactory.saveEntity(vm.clob_data, vm.clob_settings, vm.tier.VALUE).then(
                        function (result) {

                            if (result.errorMessage
                                && result.errorMessage !== 'null') {
                                AlertService.add("error",
                                    result.errorMessage, 4000);
                                vm.crudLoading = false;

                            } else {
                                AlertService.add("success", "Update Success!", 4000);
                                vm.crudLoading = false;
                                $uibModalInstance.dismiss('cancel');
                                $rootScope
                                    .$emit(
                                        'gridUpdate',
                                        {});
                            }
                        });
                }
            }

            vm.deleteCodeCombi = function (del_type) {
                vm.isLoading = true;
                if (del_type == 'DELETE_TRANS') {
                    vm.selectTransType();
                } else {
                    vm.transactionData = [{ COMBINATION_KEY: rowData.COMBINATION_KEY }];
                }
                entityUpdateFactory.deleteCodeCombi(vm.transactionData, del_type).then(
                    function (result) {

                        if (result.errorMessage
                            && result.callSuccess == "0") {
                            AlertService.add("error",
                                result.errorMessage, 4000);

                            vm.isLoading = false;

                        } else {
                            AlertService.add("success", "Delete Successful!", 4000);

                            $uibModalInstance.dismiss('cancel');
                            $rootScope
                                .$emit(
                                    'gridUpdate',
                                    {});
                            vm.isLoading = false;

                        }

                    }).catch((e) => {
                        console.log(e);
                        AlertService.add("error",
                            e, 4000);
                        vm.isLoading = false;
                    })
            }


        }
        return controllers;
    });