define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.dstAdminController', [])
                            .controller('DSTBasisCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTBasisFactory',
                                'workspaceFactory', 'gridData', 'AlertService', DSTBasisCtrl])
                            .controller('DSTCurrCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTCurrFactory',
                                'workspaceFactory', 'gridData', 'AlertService', DSTCurrCtrl])
                            .controller('DSTRetPeriodCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTRetPeriodFactory',
                                'workspaceFactory', 'gridData', 'AlertService', DSTRetPeriodCtrl])
                            .controller('DSTStkTypeCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTStkTypeFactory',
                                'workspaceFactory', 'gridData', 'AlertService', DSTStkTypeCtrl])
                            .controller('DSTStirCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTStirFactory',
                                'workspaceFactory', 'gridData', 'AlertService', DSTStirCtrl])
                            .controller('DSTAppMsgCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTAppMsgFactory',
                                'workspaceFactory', 'gridData', 'AlertService', DSTAppMsgCtrl])
                            .controller('dstFormCreateController', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTFormCreateFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', dstFormCreateCtrl])
                            .controller('dstRollOverController', ['$rootScope', '$timeout', '$uibModalInstance', 'DSTrollOverFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', dstRollOverController]);


        function DSTBasisCtrl($rootScope, $timeout, $uibModalInstance, DSTBasisFactory, workspaceFactory, gridData, AlertService) {
            var vm = this;
            vm.delete = function () {
                //var rows = gridData.map(({data}) => data);
                let rowData = gridData[gridData.length-1].data;
                DSTBasisFactory.deleteBasisData(rowData).then((data) => {
                    if (data.callSuccess === '1') {
                        $timeout(function () {
                            var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                            var args = {};
                            var filterParams = { gridFilter: gridFilters };
                            args = _.merge({}, args, filterParams);
                            $rootScope.$emit('gridUpdate', args);
                        });
                        AlertService.add('success', 'Data deleted successfully!', 4000);
                    } else {
                        AlertService.add('error', 'Something went wrong, please try again!');
                    }
                }).catch(() => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }).finally(() => {
                    $uibModalInstance.dismiss('cancel');
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function DSTCurrCtrl($rootScope, $timeout, $uibModalInstance, DSTCurrFactory, workspaceFactory, gridData, AlertService) {
            var vm = this;
            vm.delete = function () {
                //var rows = gridData.map(({data}) => data);
                let rowData = gridData[gridData.length-1].data;
                DSTCurrFactory.deleteCurrData(rowData).then((data) => {
                    if (data.callSuccess === '1') {
                        $timeout(function () {
                            var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                            var args = {};
                            var filterParams = { gridFilter: gridFilters };
                            args = _.merge({}, args, filterParams);
                            $rootScope.$emit('gridUpdate', args);
                        });
                        AlertService.add('success', 'Data deleted successfully!', 4000);
                    } else {
                        AlertService.add('error', 'Something went wrong, please try again!');
                    }
                }).catch(() => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }).finally(() => {
                    $uibModalInstance.dismiss('cancel');
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function DSTRetPeriodCtrl($rootScope, $timeout, $uibModalInstance, DSTRetPeriodFactory, workspaceFactory, gridData, AlertService) {
            var vm = this;
            vm.delete = function () {
                //var rows = gridData.map(({data}) => data);
                let rowData = gridData[gridData.length-1].data;
                DSTRetPeriodFactory.deleteRetPeriodData(rowData).then((data) => {
                    if (data.callSuccess === '1') {
                        $timeout(function () {
                            var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                            var args = {};
                            var filterParams = { gridFilter: gridFilters };
                            args = _.merge({}, args, filterParams);
                            $rootScope.$emit('gridUpdate', args);
                        });
                        AlertService.add('success', 'Data deleted successfully!', 4000);
                    } else {
                        AlertService.add('error', 'Something went wrong, please try again!');
                    }
                }).catch(() => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }).finally(() => {
                    $uibModalInstance.dismiss('cancel');
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function DSTStkTypeCtrl($rootScope, $timeout, $uibModalInstance, DSTStkTypeFactory, workspaceFactory, gridData, AlertService) {
            var vm = this;
            vm.delete = function () {
                //var rows = gridData.map(({data}) => data);
                let rowData = gridData[gridData.length-1].data;
                DSTStkTypeFactory.deleteStkTypeData(rowData).then((data) => {
                    if (data.callSuccess === '1') {
                        $timeout(function () {
                            var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                            var args = {};
                            var filterParams = { gridFilter: gridFilters };
                            args = _.merge({}, args, filterParams);
                            $rootScope.$emit('gridUpdate', args);
                        });
                        AlertService.add('success', 'Data deleted successfully!', 4000);
                    } else {
                        AlertService.add('error', 'Something went wrong, please try again!');
                    }
                }).catch(() => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }).finally(() => {
                    $uibModalInstance.dismiss('cancel');
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function DSTStirCtrl($rootScope, $timeout, $uibModalInstance, DSTStirFactory, workspaceFactory, gridData, AlertService) {
            var vm = this;
            vm.delete = function () {
                //var rows = gridData.map(({data}) => data);
                let rowData = gridData[gridData.length-1].data;
                DSTStirFactory.deleteStirData(rowData).then((data) => {
                    if (data.callSuccess === '1') {
                        $timeout(function () {
                            var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                            var args = {};
                            var filterParams = { gridFilter: gridFilters };
                            args = _.merge({}, args, filterParams);
                            $rootScope.$emit('gridUpdate', args);
                        });
                        AlertService.add('success', 'Data deleted successfully!', 4000);
                    } else {
                        AlertService.add('error', 'Something went wrong, please try again!');
                    }
                }).catch(() => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }).finally(() => {
                    $uibModalInstance.dismiss('cancel');
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function DSTAppMsgCtrl($rootScope, $timeout, $uibModalInstance, DSTAppMsgFactory, workspaceFactory, gridData, AlertService) {
            var vm = this;
            vm.delete = function () {
                //var rows = gridData.map(({data}) => data);
                let rowData = gridData[gridData.length-1].data;
                DSTAppMsgFactory.deleteAppMsgData(rowData).then((data) => {
                    if (data.callSuccess === '1') {
                        $timeout(function () {
                            var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                            var args = {};
                            var filterParams = { gridFilter: gridFilters };
                            args = _.merge({}, args, filterParams);
                            $rootScope.$emit('gridUpdate', args);
                        });
                        AlertService.add('success', 'Data deleted successfully!', 4000);
                    } else {
                        AlertService.add('error', 'Something went wrong, please try again!');
                    }
                }).catch(() => {
                    AlertService.add('error', 'Something went wrong, please try again!');
                }).finally(() => {
                    $uibModalInstance.dismiss('cancel');
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function dstFormCreateCtrl($rootScope, $timeout, $uibModalInstance, DSTFormCreateFactory, workspaceFactory, gridData, AlertService) {
            var vm = this;
            var rowData = gridData.rowData;
            console.log(rowData);
            if (rowData) {
                vm.title = 'Edit';
                vm.formName = rowData.FORMNAME;
                vm.formOrder = rowData.FORMORDER;
                vm.primaryType = rowData.PRIMARY_TYPE;
                vm.secondaryType = rowData.SECONDARY_TYPE;
                vm.tertiaryType = rowData.TERTIARY_TYPE;
                vm.secNumber = rowData.SECTION_NUMBER;
                vm.primFormTypeDesc = rowData.PRIMARYFORMTYPEDESC;
                vm.sigType = rowData.SIGNATURE_FLAG === 1 || rowData.SIGNATURE_FLAG === 'Yes' ? '1' : '0';
                vm.elecType = rowData.ELECTION_FLAG === 1 || rowData.ELECTION_FLAG === 'Yes' ? '1' : '0';
                vm.elecTerm = rowData.ELECTION_TERM;
                vm.primFormStatus = rowData.PRIMARY_FORM_ACTIVE === 1 ? '1' : '0';
                vm.secFormTypeDesc = rowData.SECONDARYFORMTYPEDESC;
                vm.secFormStatus = rowData.SECONDARY_FORM_ACTIVE === 1 ? '1' : '0';
                vm.attchName = rowData.ATTACHMENT_NAME;
                vm.dcActionFlag = rowData.DATACOLLECT_ACTION_FLAG === 1 || rowData.DATACOLLECT_ACTION_FLAG === 'Yes' ? '1' : '0';
                vm.dcExplanation = rowData.DATACOLLECT_ACTION_EXPL;
                vm.keyDiagnostic = rowData.KEY_DIAGNOSTIC;
            } else {
                vm.title = 'Create';
            }
    
            vm.save = function () {
                if (vm.title === 'Create') {
                    DSTFormCreateFactory.save(vm.formName, vm.primaryType, vm.secondaryType, vm.tertiaryType, vm.primFormTypeDesc, vm.primFormStatus,
                                            vm.sigType, vm.elecType, vm.secNumber, vm.dcActionFlag, vm.dcExplanation, vm.elecTerm, vm.attchName, vm.keyDiagnostic)
                                        .finally(() => vm.cancel());
                } else if (vm.title === 'Edit') {
                    DSTFormCreateFactory.update(rowData.FORMID, vm.formName, vm.formOrder, vm.primaryType, vm.secondaryType, vm.tertiaryType, vm.primFormTypeDesc,
                                            vm.primFormStatus, vm.secFormTypeDesc, vm.secFormStatus, rowData.SECONDARY_FORM_ID, vm.sigType, vm.elecType,
                                            vm.secNumber, vm.dcActionFlag, vm.dcExplanation, vm.elecTerm, vm.attchName, vm.keyDiagnostic)
                                        .finally(() => vm.cancel());
                }
            };
    
            vm.cancel = function () {
                $uibModalInstance.close();
            };
        }

        function dstRollOverController($rootScope, $timeout, $uibModalInstance, DSTrollOverFactory,
            workspaceFactory, gridData, AlertService) {
                let vm = this;
       
                vm.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                }
                vm.refresh = function () {
                    DSTrollOverFactory.dstRollOver().then(function (response) {
                        AlertService.add('success', 'Data RollOver is In-Progress.', 4000);
                        $uibModalInstance.close();
                    });
                }
            }
    
        return controllers;    
                
});