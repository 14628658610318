define([
    'angular'
], function () {
    'use strict';


    var controllers = angular.module('app.updateGhostSettlementCtrl',  [])
	.controller('updateGhostSettlementCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
		'GlobalService','USER_SETTINGS','updateGhostSettlementFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData','gridData', updateGhostSettlementCtrl]);


    	function updateGhostSettlementCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope, ModalFactory, AlertService, $timeout,
					   GlobalService,USER_SETTINGS,updateGhostSettlementFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {	
				
				
			}


});
