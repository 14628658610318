
define([
    'angular',
    './AlertController'


], function () {
    'use strict';
    return angular.module('app.alerts', ['app.alertController'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
            $stateProvider
                .state('alerts', {
                    url: '/alerts',
                    templateUrl: 'app/components/alerts/alerts-tpl.html',
                    data:{ncyBreadcrumbLabel: 'Tasks'},
                    access:"",
                })
        }])

});