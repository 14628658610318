define([
    'moment',
    'angular'
], function (moment) {
    'use strict';

    var services = angular.module('app.ecConfigurationActionsService',[])
        .factory("ecConfigurationActionsService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG','JsonObjectFactory', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,JsonObjectFactory) {

                var ecConfigurationActionsService = {};

                var URLS = {
                    GET_ENTITY_DETAILS: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32023",
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj',
                    GET_EC_CONFIG_ITEM_LIST: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32769",
                    GET_EC_CONFIG_DETAILS: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32028",

                }

                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};


                ecConfigurationActionsService.loadAllOverrideEntities = function(_data, _details){
                    var  params = {
                                   action_code: 'lvcm1n', 
                                };

                    return JsonObjectFactory.getJSONObj(URLS.GET_ENTITY_DETAILS,params).then((response)=>{
                        if(response.callSuccess === "1" ){

                            let arr = response.jsonObject.map(elem => {
                                if(elem.START_DATE)
                                    elem.START_DATE = moment(elem.START_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.END_DATE)
                                    elem.END_DATE = moment(elem.END_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.ACQUISITION_DATE)
                                    elem.ACQUISITION_DATE = moment(elem.ACQUISITION_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_INCORPORATED)
                                    elem.DATE_INCORPORATED = moment(elem.DATE_INCORPORATED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_MERGED)
                                    elem.DATE_MERGED = moment(elem.DATE_MERGED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_DISSOLVED)
                                    elem.DATE_DISSOLVED = moment(elem.DATE_DISSOLVED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_SOLD)
                                    elem.DATE_SOLD = moment(elem.DATE_SOLD,'YYYY-MM-DD').format('MM/DD/YYYY');
                                return elem;
                            });

                            return arr;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Override Entities.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Override Entities. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                // ecConfigurationActionsService.loadAllConfigItems = function(){
                //     var  params = {
                //         action_code: 'wq0juz', 
                //     };

                //     return JsonObjectFactory.getJSONObj(URLS.GET_EC_CONFIG_ITEM_LIST,params).then((response)=>{
                //         if(response.callSuccess === "1" ){
                //             console.log(response);
                //             return response.jsonObject;
                //         }else{
                //             if(data.errorMessage === "no access"){
                //                 AlertService.add("error", "Sorry you do not have access to retrive Config Items.", 4000);
                //             }else{
                //                 AlertService.add("error", "Unalbe to retrieve config items. If you continue to experience this issue please visit the help page to contact support.", 4000);
                //             }
                //         }
                //         return [];
                //     })
                // };

                // ecConfigurationActionsService.loadAllConfigDetails = function(){
                //     var  params = {
                //         action_code: 'csq301', 
                //     };

                //     return JsonObjectFactory.getJSONObj(URLS.GET_EC_CONFIG_DETAILS,params).then((response)=>{
                //         if(response.callSuccess === "1" ){
                //             console.log(response);
                //             return response.jsonObject;
                //         }else{
                //             if(data.errorMessage === "no access"){
                //                 AlertService.add("error", "Sorry you do not have access to retrive Config Details.", 4000);
                //             }else{
                //                 AlertService.add("error", "Unalbe to retrieve config details. If you continue to experience this issue please visit the help page to contact support.", 4000);
                //             }
                //         }
                //         return [];
                //     })
                // };
                
                ecConfigurationActionsService.save =function(){

                };


                return ecConfigurationActionsService;

        }]);

    return services;

});