define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F8916LimCtrl', [])

    	.directive("limitToMax", function() {
		return {
			link: function(scope, element, attributes) {
				element.on("keydown keyup", function(e) {
					if (Number(element.val()) > Number(attributes.max) &&
					  e.keyCode != 46 // delete
					  &&
					  e.keyCode != 8 // backspace
					) {
						e.preventDefault();
						element.val(attributes.max);
					}
					});
				}
			};
		})

        .controller('F8916LimController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','$filter',F8916LimController]);
    	function F8916LimController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, $filter) {
            var vm = this;

     		vm.originalData = [];
     		vm.userSettings = USER_SETTINGS;
     		vm.logged_in_user = vm.userSettings.user.sso_id;
     		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
 			vm.currentyear = filterParams.tax_year;
     		vm.leId = rowData.LEID;
//            vm.formName = 'F7004';
            vm.hgt = '96%';
            vm.cdr_no = rowData.MARS;
            vm.leType = rowData.LE_TYPE;
            vm.disableDate = true;
            vm.isSaveClicked = false;

            function fetch8916records() {
//                var params = { "action_id": 31333, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, "combination_key": rowData.COMBINATION_KEY,"form_name": "8916", "schedule_name": "NA", "part_no" : "NA"};
//
//                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {
//            	GlobalService.globalParams.scenario
            	   var params = { "action_code": 'sl17nu', "tax_year": vm.currentyear, "scenario": 32, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": rowData.COMBINATION_KEY,"form_no": "8916", "schedule_name": "Limitation", "part_no" : "NA", "section_name": "NA", "jcd_ta_key": null};

                   return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                	vm.modalTitle = data.jsonObject[0].ATTRIB_DESCRIPTION;
                	vm.formHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"FH"});
					vm.tableHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"TH"});
                	vm.formList1 =  data.jsonObject.slice(1);

                	vm.formList = vm.formList1.filter(function( obj ) {
						return obj.ROW_TYPE !== 'TH';
					});
  
                	
                	vm.outerList = [];
					for(var i=0;i<vm.formList.length;i++){
						if(vm.formList[i].FORM_LINE_KEY != null){
							if(vm.outerList != null && vm.outerList.length > 0){
								var tempCheck1 = $filter("filter")(vm.outerList, {FORM_LINE_KEY:vm.formList[i].FORM_LINE_KEY, IS_FORM_DISPLAY:'Y'});
							}
							if(tempCheck1 == undefined || tempCheck1.length==0){
								vm.outerList.push(vm.formList[i]);
							}

						}
					}
					
					for(var j=0;j<vm.formList.length;j++){
						if(vm.formList[j].ATTRIB_SHORT_CODE == "F8916_2c1_LINE_PRE_LIMITATION"){
							vm.formList[j].ATTRIB_VALUE = 500;
						} else if(vm.formList[j].ATTRIB_SHORT_CODE == "F8916_2c1_LINE_INPUT"){
							vm.formList[j].ATTRIB_VALUE = -300;
						}else if(vm.formList[j].ATTRIB_SHORT_CODE == "F8916_2d1_LINE_PRE_LIMITATION"){
							vm.formList[j].ATTRIB_VALUE = -100;
							
						}else if(vm.formList[j].ATTRIB_SHORT_CODE == "F8916_2e2_LINE_PRE_LIMITATION"){
							vm.formList[j].ATTRIB_VALUE = 600;
							
						}else if(vm.formList[j].ATTRIB_SHORT_CODE == "F8916_4a1_LINE_PRE_LIMITATION"){
							vm.formList[j].ATTRIB_VALUE = -200;
							
						}else if(vm.formList[j].ATTRIB_SHORT_CODE == "F8916_4b1_LINE_PRE_LIMITATION"){
							vm.formList[j].ATTRIB_VALUE = -100;
							
						}
							
					}

					vm.originalData = angular.copy(vm.formList);
					vm.originalOutterData = angular.copy(vm.outerList);
					vm.fetchModalCalcData();
    			//	initFormView($scope.f8916FormView);
    				vm.showFormLoader = false; 
                });
            };

/*  
    		vm.validateLines = function(line) {
    				var disabledLines = _.filter($scope.f8916FormView.rows, function(o) { 
						return (o.num == '3' || o.num == '5' || o.num == '6' || o.num == '8'); 
					})
    			
					//ENABLE ONCE UTILITY IS CHANGED
					for(var i=0; i < disabledLines.length; i++){
						
						var txt = disabledLines[i].formula;
						var result = txt.match(/\((.*)\)/);
						if(result != null){
						  var finalRes = result[1].split(/[-+,/\\*\\]/);
						}
					
						for(var g=0; g < finalRes.length; g++){
							var apprVal12 = _.filter($scope.f8916FormView.rows, function(o) { 
								return o.num == finalRes[g].trim();
							})
							
							if(apprVal12[0].value == ''){
								apprVal12[0].value = 0;
							}
							result[1] = result[1].replace(apprVal12[0].num,apprVal12[0].value);
						}
						
						if(null!= eval(result[1]) && typeof eval(result[1]) !== 'undefined' && parseInt(eval(result[1])) === parseInt(eval(result[1]))){
							
							 disabledLines[i].value = eval(result[1]);
						}
						
						
//						var output = [];
//						 output = _(apprVal).groupBy('attrib_name_a')
//							    .map((objs, key) => ({
//							        'attrib_name_a': key,
//							        'value': _.sumBy(objs, 'value') })).value();
						 
					} 
					
					
					_.forEach($scope.f8916FormView.rows, function(a) {
						if((a.value == undefined || isNaN(a.value))){
							a.value=0;
						}
					});
					
					//line 3
					$scope.f8916FormView.rows[9].value = (parseInt($scope.f8916FormView.rows[0].value) + parseInt($scope.f8916FormView.rows[1].value) + parseInt($scope.f8916FormView.rows[2].value) + parseInt($scope.f8916FormView.rows[3].value) +
							parseInt($scope.f8916FormView.rows[4].value) + parseInt($scope.f8916FormView.rows[5].value) + parseInt($scope.f8916FormView.rows[6].value) + parseInt($scope.f8916FormView.rows[7].value)  + parseInt($scope.f8916FormView.rows[8].value));
    				
    				//line 5
					$scope.f8916FormView.rows[19].value = (parseInt($scope.f8916FormView.rows[10].value) + parseInt($scope.f8916FormView.rows[11].value) + parseInt($scope.f8916FormView.rows[12].value) + parseInt($scope.f8916FormView.rows[13].value) +
					parseInt($scope.f8916FormView.rows[14].value) + parseInt($scope.f8916FormView.rows[15].value) + parseInt($scope.f8916FormView.rows[16].value) + parseInt($scope.f8916FormView.rows[17].value)  + parseInt($scope.f8916FormView.rows[18].value));
					
					
					//line 6
					$scope.f8916FormView.rows[20].value = (parseInt($scope.f8916FormView.rows[9].value) - parseInt($scope.f8916FormView.rows[19].value));
					

					//line 8
					$scope.f8916FormView.rows[22].value = (parseInt($scope.f8916FormView.rows[21].value) + parseInt($scope.f8916FormView.rows[20].value));
    		} */
    		
    		vm.fetchModalCalcData = function() {
				var params = { "action_code": '1ubsuu', "tax_year": vm.curentyear, "scenario": null, "jcd_key": GlobalService.globalParams.jcd_key, 
						"form_no":"8916","schedule_name":"Limitation","part_no":"NA","section_name":"NA" };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					vm.formCalcData =  data.jsonObject;
					vm.validateSubForm();
				});
			}
    		
    		
			vm.validateSubForm = function(line) {
				vm.form8936DataChanged1 = false;
				vm.form8936DataChanged2 = false;
				if(line == null || line.DATA_TYPE == 'number' ){
					for(var g=0; g<vm.formCalcData.length; g++){
						vm.calcAmt(vm.formCalcData[g].ATTRIB_CALC_FORMULA,vm.formCalcData[g].LINE_NO,vm.formCalcData[g].LINE_ATTRIB_KEY,vm.formCalcData[g].ATTRIB_COL,vm.formCalcData[g].EDIT_TYPE);
					}

				}else{
					line.IS_CHANGED_FLAG = 'Y';

				}
			};
			

			//Note to use calcAmt::::---------------------------------- 
			//1). Example to call calcAmt is if line1d = (line1a + line1b + line1c) then you need to call calcAmt("(1a)+(1b)+(1c)", "1d");
			//2). If line17d = line17c*0.40 then you need to call calcAmt("(17c)*40/100", "17d"); (no Float values in formula)
			//3). Need to check if the line has Multiplier
//			function calcAmt(formula, line_no, attrib_key, attrib_col, edit_type){
			vm.calcAmt = function(formula, line_no, attrib_key, attrib_col, edit_type){
				vm.currentFormula = [];
				vm.currentFormula = formula;
				console.log("Formula-----" , vm.currentFormula);
				var calcLineAmt = 0;

				var lineAmt = 0;
					var matches = formula.match(/\b[^\d\s]+\b/g);
					if(matches ==  null){
						var lineFormulaArray = formula.split(/[+-/\\*\\]/);
					}else{
						var formulaLength = formula.length;
						var subFormula = "";
						subFormula = formula.substring(formula.indexOf("("),formulaLength-1);
						var lineFormulaArray = subFormula.split(/[+-,/\\*\\]/);
					}

				for(var i=0; i<lineFormulaArray.length; i++){
					if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
						lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");

						var currentTabOldAtrribVal = $filter("filter")(vm.originalData, {FORM_NAME:"8916"});
						var oldAttribVal = $filter("filter")(currentTabOldAtrribVal, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
						var currentTabNewAtrribVal = _.filter(vm.formList, function(o) { 
							return o.ATTRIB_SHORT_CODE == lineFormulaArray[i].trim(); 
						})
						lineAmt = parseFloat(currentTabNewAtrribVal[0].ATTRIB_VALUE);

						if(lineAmt != parseFloat(oldAttribVal[0].ATTRIB_VALUE)){
							currentTabNewAtrribVal[0].IS_CHANGED_FLAG = 'Y';
						}
						if(parseFloat(lineAmt)>= 0){
							formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
						}else{
							formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
						}
					}
				}

				if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself

					var changedFormulaAttribVal = $filter("filter")(vm.formList, {LINE_NO : line_no, LINE_ATTRIB_KEY : attrib_key});
					var calculatedVal = eval(formula);

					if(parseFloat(changedFormulaAttribVal[0].ATTRIB_VALUE) != calculatedVal.toFixed()){
//						if(formula.substring(0, 8) == 'Math.min' || formula.substring(0, 8) == 'Math.max'){
						if (formula.substring(0, 4) == 'Math') {
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal;
						}else{
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
						}
						console.log("Changed Flag-----" , changedFormulaAttribVal[0].LINE_ATTRIB_KEY);
						changedFormulaAttribVal[0].IS_CHANGED_FLAG = 'Y';
					}else{
//						if(formula.substring(0, 8) == 'Math.min' || formula.substring(0, 8) == 'Math.max'){
						if (formula.substring(0, 4) == 'Math') {
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal;
						}else{
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
						}
					}
				}

			};

    		
    		function init8916(){
    				fetch8916records();
//    				vm.fetchModalCalcData();
    		};
    		init8916(); 
            

            vm.save8916Form = function (url, data){
                var jsonSettings = {
                	"tax_year": rowData.TAX_YEAR,
                	"scenario": rowData.SCENARIO,
                	"jcd_key": rowData.JCD_KEY,
                	"combination_key": rowData.COMBINATION_KEY,
                	"trans_type_key": data[0].editrows[0][0].trans_type_key
                };
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(data);
                var jsonSettings  = JSON.stringify(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                params.process_name =  'F1120_8916'; /*workspaceFactory.activeScreen.label;*/
                params.combination_key = rowData.COMBINATION_KEY;
                params.trans_type_key = data[0].editrows[0][0].trans_type_key;
                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                    console.log("save8916Form() response.data.jsonObj ::",response.data.jsonObject);
    				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
    					$uibModalInstance.dismiss('cancel');
    					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
    					$rootScope.$emit('gridUpdate', args);
    					AlertService.add("success", "Transactions saved successfully", 4000);
    				}else{
    					if(response.data && response.data.errorMessage){
                            AlertService.add("error", response.data.errorMessage,4000);
                        }else{
                            AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                        }
    				}
                    return response.data;
                });
                console.log("------------At the end-----------", promise);
                return promise;
            };

            vm.save = function(){
    			var formRowDataA = {};
    			var formRowDataB = {};
    			var tempArray = [];
    			/*if(!vm.isValid()){
    				AlertService.add("error", "Please provide the mandatory fields.",4000);
    				return;
    			}*/
    			for(var i=0; i<$scope.f8916FormView.rows.length ;i++){
    				
    				formRowDataA = {
    					tax_year: rowData.TAX_YEAR,
    					trans_type_key: $scope.f8916FormView.rows[i].saveParams[1],
    					line_order: $scope.f8916FormView.rows[i].line_order,
                        attrib_order: $scope.f8916FormView.rows[i].attrib_order_a,
                        line_description: $scope.f8916FormView.rows[i].description,
    					combination_key: rowData.COMBINATION_KEY,
    					group_obj_key: rowData.GROUP_OBJ_KEY,
    					form_key: $scope.f8916FormView.rows[i].saveParams[2],
    					line_no: $scope.f8916FormView.rows[i].num,
    					occurence: $scope.f8916FormView.rows[i].saveParams[7],
    					line_attrib_key: $scope.f8916FormView.rows[i].lineAttrKey_a,
    					Attribute_Name: $scope.f8916FormView.rows[i].attrib_name_a,
//    					Attribute_Value: $scope.f8916FormView.rows[i].value ? $scope.f8916FormView.rows[i].value.toString() : '',
    					Attribute_Value: $scope.f8916FormView.rows[i].num == '1' ? null : $scope.f8916FormView.rows[i].value ? $scope.f8916FormView.rows[i].value.toString() : '',
    					trans_dtls_key: $scope.f8916FormView.rows[i].saveParams[3],
    					trans_status: $scope.f8916FormView.rows[i].saveParams[6],
    					is_dirty: ''
    				};
    				formRowDataA.JSON_DATA = [];
    				tempArray.push(formRowDataA);

    				
    			}
    			vm.formData.editrows.push(tempArray);
    			vm.mainData = [];
    			vm.mainData.push(vm.formData);
    			console.log(vm.mainData);
    			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=z9frws"; 
    			vm.save8916Form(url,vm.mainData);

                $rootScope.$broadcast("gridRefresh", true);

                $timeout(function() {
                    $scope.$broadcast("showLoader", true);
                },100);
            };


    		vm.reset = function (){
    			$scope.f8916FormView = angular.copy(vm.originalData);
    		};

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            function initFormView(filteredFormData) {
    			$scope.f8916FormView = filteredFormData;
    		};

    		//Dynamic Tabset Code
    		vm.tabs = [
    			 {id: 1, name: "Form Entry", url: "app/components/F1120/F8916Lim/F8916LimEntry.html", active: true }
    	    ];
        }
});