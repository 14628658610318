define([
	'angular'

], function () {
	'use strict';


	var services =  angular.module('app.clientAdminService',[])
		.factory("ClientAdminFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG','AlertService', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,JsonObjectFactory,GENERAL_CONFIG,AlertService) {
			var clientAdminFactory = {};
			var steppers = [
				{
					stepNo: 1,
					stepTitle: "General Information",
					templateUrl:"app/components/admin/client/templates/default-tab.html",
					saveMethod:"saveGI",
					enabled:true,
					isCurrent:true
				},
				{
					stepNo: 2,
					stepTitle: "Application Setup",
					templateUrl:"app/components/admin/client/templates/setup-client-tab.html",
					saveMethod:"saveAppSetup"
				},
				{
					stepNo: 3,
					stepTitle: "Client Landing Details",
					templateUrl:"app/components/admin/client/templates/client_landng_dtls.html",
					saveMethod:"saveClientLandingDtls"
				},
				{
					stepNo: 4,
					stepTitle: "Workflow Setup",
					templateUrl: "app/components/admin/client/templates/user-workflow-setup.html",
					saveMethod:"saveClientWorkflow"
				},
			];

			var URLS = {
				FETCH: GENERAL_CONFIG.admin_api_url + '/getAllClients',
				UPDATE: GENERAL_CONFIG.admin_api_url + '/saveClient',
				DELETE: GENERAL_CONFIG.base_url + '/deleteClient',
				CLIENT_APP_SETUP: GENERAL_CONFIG.base_url+'/getNavItemsByClient',
				VALIDATE_DB_SHORT_NAME: GENERAL_CONFIG.base_url + '/getAllClientsWithDbShortName',
				SAVE_APPLICATION_SETUP: GENERAL_CONFIG.admin_api_url+ '/saveAppSetup',
				WORKFLOW_WORKFLOW_TYPE: GENERAL_CONFIG.admin_api_url + '/getWorkflowTypes',
				WORKFLOW_TREE: GENERAL_CONFIG.admin_api_url + '/getWorkflowTree',
				WORKFLOW_SAVE: GENERAL_CONFIG.admin_api_url + '/saveClientWorkflow',
				WORKFLOW_TY_SCENARIO_FILTER: GENERAL_CONFIG.admin_api_url + '/getTaxyearAndScenarioFilters',
				WORKFLOW_REMOVE_HAS_NEW: GENERAL_CONFIG.admin_api_url + '/removeNewButton',
				WORKFLOW_PUSH_NEW: GENERAL_CONFIG.admin_api_url + '/pushNewButton',
				ADD_WORKFLOW: GENERAL_CONFIG.admin_api_url + '/addWorkflow',
				EDIT_WORKFLOW: GENERAL_CONFIG.admin_api_url + '/editWorkflow',
				DELETE_WORKFLOW_NODE: GENERAL_CONFIG.admin_api_url + '/deleteWorkflowNode',
				GET_ALL_VERSION_NUMBERS: GENERAL_CONFIG.admin_api_url + '/getAllVersionNumbers',
				GET_ONLY_DEFAULT_WORKFLOW_TREE: GENERAL_CONFIG.admin_api_url + '/getOnlyDefaultWorkflowTree',
				GET_WORKFLOW_COMPONENT: GENERAL_CONFIG.admin_api_url + '/getWorkflowComponent',
				SAVE_ROLLOVER_WORKFLOW: GENERAL_CONFIG.admin_api_url + '/rolloverdefaultWorkflow',
				CHECk_CLIENT_NAME: GENERAL_CONFIG.admin_api_url + '/checkClientName',
				PUSH: GENERAL_CONFIG.admin_api_url + '/rds/takeSnapshotOfStarterClient',
				TOGGLE_CLIENT_ACTIVE_STATUS: GENERAL_CONFIG.admin_api_url + '/toggleClientActiveStatus'
			}
			clientAdminFactory.push = function() {
				var promise = $http({
					method: "POST",
					url: URLS.PUSH,
					data: {}
				}).then(function (response) {
					if(response){
						return response;
					} else {
						AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
					}
				});
				return promise;
			};
			clientAdminFactory.getSteppers = function() {
				return steppers;
			}
			clientAdminFactory.getClientSetupData = function(data) {
				var promise = $http({
					method: "POST",
					url: URLS.CLIENT_APP_SETUP,
					data: data
				}).then(function (response) {
					if(response.data.callSuccess === "1"){
						return response.data;
					} else {
						if(response.data.errorMessage === "no access"){
							AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
						} else {
							AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
						}
					}
				});
				return promise;
			};
			clientAdminFactory.getEnvironmentType=function(){
				var promise = $http({
					method: "get",
					url:GENERAL_CONFIG.admin_api_url + '/getEnvType',

				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.clientLandingDataPost = function(data,url) {
				var promise = $http({
					method: "POST",
					url: GENERAL_CONFIG.admin_api_url + url,
					data: data
				}).then(function (response) {
					if(response.data.callSuccess === "1"){
						return response.data;
					}else {
						if(response.data.errorMessage === "no access"){
							AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
						}else {
							AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
						}
					}
				});
				return promise;
			};


			clientAdminFactory.validateDbShortName = function(dbShortName) {
				var params = {
					"data": JSON.stringify(clintClone)
				};

				var promise = $http({
					method: "post",
					url: URLS.VALIDATE_DB_SHORT_NAME,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.removeNewButton = function(wfscomp_key, clientKey) {
				var params = {
					"wfstKey": wfscomp_key,
					"client_key" : clientKey
				};

				var promise = $http({
					method: "post",
					url: URLS.WORKFLOW_REMOVE_HAS_NEW,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.editWorkflow = function(name, description, active, selected, versionNumber,templatePath, controllerPath, selectedModalSize, parentCssClass, selectedActionType,
													   updIns, node,selectedActivityKey,
													   selectedScreenKey,filterJsonString) {

				var params = {
					"name": name,
					"description": description,
					"isActive": active,
					"isSelected": selected,
					"versionNumber":versionNumber,
					"templatePath": templatePath,
					"controllerPath": controllerPath,
					"selectedModalSize": selectedModalSize,
					"parentCssClass": parentCssClass,
					"selectedActionType": selectedActionType,
					"updInsType":updIns,
					"node": JSON.stringify(node),
					"activityKey": selectedActivityKey,
					"screenKey": selectedScreenKey,
					"filter_json": (filterJsonString)
				};

				var promise = $http({
					method: "post",
					url: URLS.EDIT_WORKFLOW,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.getWorkflowComponent = function(p_type, client_key, wfscomp_key, tax_year, scenario) {
				var params = {
					"p_type":p_type,
					"client_key" : client_key,
					"wfscomp_key": wfscomp_key,
					"tax_year": tax_year,
					"scenario": scenario
				};
				var promise = $http({
					method: "post",
					url: URLS.GET_WORKFLOW_COMPONENT,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.addWorkflow = function(client_key, name, description, selectedCategory, priority,
													  templatePath,
													  controllerPath,
													  selectedModalSize,
													  parentCssClass,
													  selectedActionType,
													  selectedIsActive,
													  selectedActivityKey,
													  selectedScreenKey,
													  filterJsonString,
													  wfstKey, taxYear, scenario) {

				var params = {
					"clientKey": client_key,
					"name": name,
					"description": description,
					"selectedCategory": selectedCategory,
					"priority": priority,
					"templatePath": templatePath,
					"controllerPath": controllerPath,
					"acitivityKey": selectedActivityKey,
					"screenKey": selectedScreenKey,
					"selectedModalSize": selectedModalSize,
					"parentCssClass": parentCssClass,
					"selectedActionType": selectedActionType,
					"selectedIsActive": selectedIsActive,
					"filter_json": filterJsonString,
					"wfstKey": wfstKey,
					"taxYear": taxYear,
					"scenario": scenario
				};

				var promise = $http({
					method: "post",
					url: URLS.ADD_WORKFLOW,
					data: params
				}).then((response) =>  {
					return response;
				});

				return promise;
			}

			clientAdminFactory.pushNewButton = function(childId, client_key, wfstKey) {
				var params = {

					"childId": childId,
					"client_key" : client_key,
					"wfstKey": wfstKey
				};

				var promise = $http({
					method: "post",
					url: URLS.WORKFLOW_PUSH_NEW,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.checkClientNameDuplicate = function(clientName) {
				var params = {client_name:clientName}

				var promise = $http({
					method: "post",
					url: URLS.CHECk_CLIENT_NAME,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.getAllVersionNumbers = function(p_type, client_key, wfscomp_key, tax_year, scenario) {
				var params = {
					"p_type":p_type,
					"client_key" : client_key,
					"wfscomp_key": wfscomp_key,
					"tax_year": tax_year,
					"scenario": scenario
				};

				var promise = $http({
					method: "post",
					url: URLS.GET_ALL_VERSION_NUMBERS,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.getWorkflowTypes = function (client_key) {

				var params = {
					"clientKey": client_key
				};

				var promise = $http({
					method: "get",
					url: URLS.WORKFLOW_WORKFLOW_TYPE,
					params: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.getTaxYearAndScenarioFilters = function (client_key) {

				var params = {
					"clientKey": client_key
				};

				var promise = $http({
					method: "get",
					url: URLS.WORKFLOW_TY_SCENARIO_FILTER,
					params: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.getWorkflowTree = function (wfst_key, tax_year, scenario, client_key) {

				var params = {
					"wfstKey": wfst_key,
					"taxYear": tax_year,
					"scenario": scenario,
					"clientKey": client_key
				};

				var promise = $http({
					method: "post",
					url: URLS.WORKFLOW_TREE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.getOnlyDefaultWorkflowTree = function (wfst_key, tax_year, scenario, client_key) {

				var params = {
					"wfstKey": wfst_key,
					"taxYear": tax_year,
					"scenario": scenario,
					"clientKey": client_key
				};

				var promise = $http({
					method: "post",
					url: URLS.GET_ONLY_DEFAULT_WORKFLOW_TREE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.saveClientWorkflow = function (wfst_key, tax_year, scenario, client_key, selectedItems) {
				var params = {
					"wfstKey": wfst_key,
					"taxYear": tax_year,
					"scenario": scenario,
					"clientKey": client_key,
					"clientWorkflowItems": JSON.stringify(selectedItems)
				}

				var promise = $http({
					method: "post",
					url: URLS.WORKFLOW_SAVE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.getAllClients = function() {

				var params = {};

				var promise = $http({
					method: "post",
					url: URLS.FETCH,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			};

			clientAdminFactory.saveClient = function(client) {


				console.log("client ======= : " , client);

				var clintClone = _.cloneDeep(client)


				if(clintClone.flow){
					delete clintClone['flow'];
				}

				var params = {
					"data": JSON.stringify(clintClone)
				};


				var promise = $http({
					method: "post",
					url: URLS.UPDATE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.toggleClientActiveStatus = function(client_key, is_active) {

				var params = {
					"clientKey": client_key,
					"clientActive": is_active
				};

				var promise = $http({
					method: "post",
					url: URLS.TOGGLE_CLIENT_ACTIVE_STATUS,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.deleteClient = function(client_key) {

				var params = {
					"data": client_key
				};

				var promise = $http({
					method: "post",
					url: URLS.DELETE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.deleteClientLogo = function(client_key, fileName) {

				var params = {
					"data": client_key
					, "flowFilename": fileName
				};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.admin_api_url + '/removeClientLogo',
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.removeClientLogoTempFolder = function() {

				var params = {};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.admin_api_url + '/removeClientLogoTempFolder',
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.testClientDataSourceConn = function(client_datasource) {

				var params = {"data": client_datasource};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.admin_api_url + '/testClientDataSourceConn',
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}
			/**
			 * Params: clientKey, navigation items to be updated based on selection.
			 * Function: Updates the IS_SELECTED column
			 * and the *_NAME Column in the TGT_ACTIVITY/TGT_CHANNEL/TGT_TASK Tabels
			 */
			clientAdminFactory.saveAppSetup = function(clientKey, navItems) {
				var params =JSON.stringify(navItems);

				var promise = $http({
					method: "post",
					url: URLS.SAVE_APPLICATION_SETUP + "?clientKey=" + clientKey,
					data: params,
					headers: {
						"Content-Type": "application/json"
					}
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			clientAdminFactory.deleteWorkflowNode = function(wfs_key, client_key, is_active, type) {
				var params = {
					"wfs_key": wfs_key,
					"client_key": client_key,
					"is_active": is_active,
					"type": type
				};

				var promise = $http({
					method: "post",
					url: URLS.DELETE_WORKFLOW_NODE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			//SAVE_ROLLOVER_WORKFLOW
			clientAdminFactory.rolloverdefaultWorkflow = function (srctaxYear, srcScenario, taxType, desttaxYear, destScenario, ssMMScenario) {
				var params = {
					"srctaxYear": srctaxYear,
					"srcScenario": srcScenario,
					"taxType": taxType,
					"desttaxYear": desttaxYear,
					"destScenario": destScenario,
					"ssMMScenario":ssMMScenario
				}

				var promise = $http({
					method: "post",
					url: URLS.SAVE_ROLLOVER_WORKFLOW,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			/* dvalaboju001 Build Task Heirarchy 3/18/2020 */
			clientAdminFactory.taskHierarchy = function(jsonAry){
				var mergedArray = [];
					console.log(jsonAry);

					var taskList=[];
					console.log("mergerd",mergedArray);
					var task= jsonAry;
					var times = function (n, str) {
									var result = '';
									for (var i = 0; i < n; i++) {
										result += str;
									}
									return result;
								};
					var recur = function (item, level, arr) {
						if(item.type == 'task'){
							arr.push({
						label: item.label,
						id: item.id,
						level: item.level,
						indent: times(level, '\u00A0\u00A0\u00A0'),
						children: item.children
						});
						if (item.children){
						item.children.forEach(function (item){
							recur(item, level + 1, arr);
						});
						}
						}

					};
					task.forEach(function (item) {
						recur(item, 0, taskList);
					});
					task = taskList;
					console.log("adadfadsfdfasdfasd",taskList);
					return task;
			}


			return clientAdminFactory;
		}])

	return services;
});
