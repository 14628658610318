define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.post86EpHistoryReportCtrl', []);
    var controllers = module.controller('post86EpHistoryReportMainCtrl',
            		['$element','menuDataService','workspaceFactory','gtapsCommonTemplateFactory','WorkflowTCFactory','$uibModal','GlobalService', 'AlertService', '$timeout', '$window', '$scope','JsonObjectFactory', '$parse', '$rootScope', 'GENERAL_CONFIG', 'post86EpHistoryReportFactory', post86EpHistoryReportMainCtrl]);

		function post86EpHistoryReportMainCtrl(element,menuDataService,workspaceFactory,gtapsCommonTemplateFactory, WorkflowTCFactory, $uibModal, GlobalService, AlertService, $timeout, $window, $scope,JsonObjectFactory, $parse, $rootScope, GENERAL_CONFIG, post86EpHistoryReportFactory) {
			var vm = this;
			vm.name = "post86EpHistoryReportMainCtrl";
			vm.loading = false;
			$scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
			var activeScreenEp = workspaceFactory.activeScreen;
			vm.cacheAllActiveData = {};
			
			//using these parameters from common controller
			/*vm.total959c3EPPoolFuncCurr;
			vm.totalNH959c3EPPoolFuncCurr;
			vm.totalPtiFuncCurr;
			vm.total959c3EPPoolUSD;
			vm.totalNH959c3EPPoolUSD;
			vm.totalPtiUSD;
			vm.totalTaxPool;
			vm.totalNHTaxPool;
			vm.hovering959c3Ep;
			vm.hovering959c3Taxes;
			vm.totalUSDBasisInPti;
			vm.effectiveRateOn959c3TotalEp;
			vm.effectiveRateOn959c3TotalEpNH;
			vm.estimatedPtiExchangeGainLoss;
			vm.recaptureAccounts = "No";
			vm.qualifiedDeficits = "No";*/
			vm.baseCurrency = "USD";

			//labels
			vm.pageTitle = "Post-86 E&P History Report - Income/(Deficit)";
			vm.labelSummary = "Summary";
			/*vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
			vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
			vm.labelTotal959c3EPPoolUSD = "Total Section 959(c)(3) E&P Pool (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalPtiUSD = "Total PTI (Currency = " + vm.baseCurrency + ")";
			vm.labelTotalTaxPool = "Total Tax Pool";
			vm.labelTotalUSDBasisInPti = "Total USD Basis in PTI";
			vm.labelEffectiveRateOn959c3TotalEp = "Effective Rate on 959(c)(3) Total E&P";
			vm.labelEstimatedPtiExchangeGainLoss = "Estimated PTI Exchange Gain/(Loss)";
			vm.labelRecaptureAccts = "Recapture Accounts";
			vm.labelQualDefs = "Qualified Deficits";*/
			vm.labelYear = "Year";
			vm.labelTotalEPTaxes = "Total E&P / Taxes";
			vm.labelNonHoveringEPTaxes = "Non-Hovering E&P / Taxes";
			vm.labelTotalSection959c3TaxPool = "Total Section 959(c)(3) Tax Pool";
			vm.labelHovering959c3Ep = "Hovering 959(c)(3) E&P";
			vm.labelHovering959c3Taxes = "Hovering 959(c)(3) Taxes";
			vm.labelDescription = "Description";
			vm.labelCite =  "Cite";
			vm.labelDocument = "Document";
			vm.labelTotalAmount = "Total Amount";

			//Data Lists
			vm.dataLoading = [];
			
			if (menuDataService) {
				vm.menuData = menuDataService.data;
			}
			if (angular.isUndefined(workspaceFactory.activeScreen.filter_key) || workspaceFactory.activeScreen.filter_key === null) {
				workspaceFactory.activeScreen.filter_key = 41;
			}

			//Copy this controller object data from workspaceFactory.activeScreen.data if its already available
			if (angular.isDefined(workspaceFactory.activeScreen.data) && workspaceFactory.activeScreen.data !== 'LOAD') {
				// populateCachedData(workspaceFactory.activeScreen.data);
				refreshData();
			} else if (angular.isDefined(workspaceFactory.activeScreen.data) && workspaceFactory.activeScreen.data === 'LOAD') {
				refreshData();
			} else {
				vm.hoLeid = '';
				vm.entityName = '';
				vm.hoTaxCode = '';
				vm.funcCurrency = "";
				vm.funcCurrDescription = "";
				vm.selectedHoLeidParams;

				//Data Lists
				vm.taxYearSL = [];
				vm.columnNames = [];
				vm.epTotalAmts = [];
				vm.ptiTotalAmts = [];
				vm.lineDescData = [];
				vm.lineDescs = [];
				vm.lineDescsCite = [];
				vm.displayTable = false;
				vm.taxYearData = [];
				vm.otherTaxYearsData = [];

				//vm.selectedTaxYear = 2017;
				vm.columns = [];
				//vm.epHistoryData = [];
				vm.epHistoryOptions = [];
				vm.columnDefs = [];
				vm.dataLoading = [];
				//vm.epHistoryDataTableInitialized = false;

				vm.showData = false;
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
			}
			
			function populateCachedData(cachedData) {
				vm.hoLeid = cachedData.hoLeid;
				vm.entityName = cachedData.entityName;
				vm.hoTaxCode = cachedData.hoTaxCode;
				vm.funcCurrency = cachedData.funcCurrency;
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";

				/*vm.total959c3EPPoolFuncCurr = cachedData.total959c3EPPoolFuncCurr;
				vm.totalNH959c3EPPoolFuncCurr = cachedData.totalNH959c3EPPoolFuncCurr;
				vm.totalPtiFuncCurr = cachedData.totalPtiFuncCurr;
				vm.total959c3EPPoolUSD = cachedData.total959c3EPPoolUSD;
				vm.totalNH959c3EPPoolUSD = cachedData.totalNH959c3EPPoolUSD;
				vm.totalPtiUSD = cachedData.totalPtiUSD;
				vm.totalTaxPool = cachedData.totalTaxPool;
				vm.totalNHTaxPool = cachedData.totalNHTaxPool;
				vm.hovering959c3Ep = cachedData.hovering959c3Ep;
				vm.hovering959c3Taxes = cachedData.hovering959c3Taxes;
				vm.totalUSDBasisInPti = cachedData.totalUSDBasisInPti;
				vm.effectiveRateOn959c3TotalEp = cachedData.effectiveRateOn959c3TotalEp;
				vm.effectiveRateOn959c3TotalEpNH = cachedData.effectiveRateOn959c3TotalEpNH;
				vm.estimatedPtiExchangeGainLoss = cachedData.estimatedPtiExchangeGainLoss;
				vm.recaptureAccounts = cachedData.recaptureAccounts;
				vm.qualifiedDeficits = cachedData.qualifiedDeficits;*/
				vm.selectedHoLeidParams = cachedData.selectedHoLeidParams;;
	
				//Data Lists
				vm.taxYearSL = cachedData.taxYearSL;
				vm.columnNames  = cachedData.columnNames;
				vm.epTotalAmts = cachedData.epTotalAmts;
				vm.ptiTotalAmts = cachedData.ptiTotalAmts;
				vm.lineDescData = cachedData.lineDescData;
				vm.lineDescs = cachedData.lineDescs;
				vm.lineDescsCite = cachedData.lineDescsCite;
				vm.displayTable = cachedData.displayTable;
				vm.taxYearData = cachedData.taxYearData;
				vm.otherTaxYearsData = cachedData.otherTaxYearsData;
	
				//vm.selectedTaxYear = 2017;
				vm.columns = cachedData.columns;
				//vm.epHistoryData = [];
				vm.epHistoryOptions = cachedData.epHistoryOptions;
				vm.columnDefs = cachedData.columnDefs;
				vm.dataLoading = cachedData.dataLoading;
				//vm.epHistoryDataTableInitialized = false;
	
				vm.showData = true;
			}

			// $scope.$watch(function() { 
			// 	return (angular.isDefined(workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.loading : true);
			// }, function(newValue, oldValue) {
			// 	if (newValue === false && newValue !== oldValue
			// 		&& angular.isDefined(workspaceFactory.activeScreen.filters.filters)) {
			// 		//Refresh the filters for which data is not loaded using the last filter in the ResfreshList
			// 		angular.forEach(workspaceFactory.activeScreen.filters.filters, function(filter) {
			// 			if (angular.isUndefined(filter.values) || filter.values === null) {
			// 				if (filter.refresh_list && filter.refresh_list.length) {
			// 					var refresh_array = filter.refresh_list.split(',');
			// 					var requiredFilter = _.find(workspaceFactory.activeScreen.filters.filters, {
			// 						'filter_key': refresh_array[refresh_array.length-1]
			// 					});
			// 					if (typeof requiredFilter === 'undefined' || requiredFilter.selected === null ||
			// 						_.isEmpty(requiredFilter.selected)) {
			// 						//Do Nothing
			// 					} else {
			// 						workspaceFactory.activeScreen.filters.checkDataRefresh(requiredFilter, workspaceFactory.activeScreen.filter_key);
			// 					}
			// 				}
			// 			}
			// 		});
			// 	}
			// },true);

			$scope.$on('dataFilters:refreshGrid', function (event, args) {
				refreshData();
			});

			function refreshData() {
				vm.showData = true;
				workspaceFactory.activeScreen.filters.getFilterParams();
				workspaceFactory.setCurrentFilters(workspaceFactory.activeScreen);
				workspaceFactory.activeScreen.loading_data = true;
				var filterTaxYear;
				var filterScenario;
				var filterFilingGroup;
				var filterHoLeidKey;
				var hoLeid;
				var hoCdrNo;
				var hoReportingPeriod;
				//var parentMeString;
				if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams)) {
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.tax_year)) {
						filterTaxYear = workspaceFactory.activeScreen.filters.filterParams.tax_year;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.scenario)) {
						filterScenario = workspaceFactory.activeScreen.filters.filterParams.scenario;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.filing_key)) {
						filterFilingGroup = workspaceFactory.activeScreen.filters.filterParams.filing_key;
					}
					if (angular.isDefined(workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key)) {
						if (workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key === null) {
							//Do Nothing
						} else {
							filterHoLeidKey = JSON.parse(workspaceFactory.activeScreen.filters.filterParams.fir_ho_leid_key);
							if (angular.isDefined(filterHoLeidKey.HO_LEID)) {
								hoLeid = filterHoLeidKey.HO_LEID;
								vm.hoLeid = hoLeid;
							}
							if (angular.isDefined(filterHoLeidKey.HO_LE_NAME)) {
								vm.entityName = filterHoLeidKey.HO_LE_NAME;
							}
							if (angular.isDefined(filterHoLeidKey.HO_CDR_NO)) {
								hoCdrNo = filterHoLeidKey.HO_CDR_NO;
								vm.hoTaxCode = hoCdrNo;
							}
							if (angular.isDefined(filterHoLeidKey.HO_REPORTING_PERIOD)) {
								hoReportingPeriod = filterHoLeidKey.HO_REPORTING_PERIOD;
							}
							if (angular.isDefined(filterHoLeidKey.HO_FUNCTIONAL_CURRENCY)) {
								vm.funcCurrency = filterHoLeidKey.HO_FUNCTIONAL_CURRENCY;
							}
						}
					}
					vm.selectedHoLeidParams =  {"ho_leid":hoLeid,"ho_cdr_no":hoCdrNo,"ho_reporting_period":hoReportingPeriod,
												"filing_key":filterFilingGroup, "tax_year":filterTaxYear,"scenario":filterScenario};
				}
				//Reset epHistoryOptions as Entity changed
				vm.labelTotal959c3EPPoolFuncCurr = "Total Section 959(c)(3) E&P Pool (Functional Currency = " + vm.funcCurrency + ")";
				vm.labelTotalPtiFuncCurr = "Total PTI (Functional Currency = " + vm.funcCurrency + ")";
				vm.taxYearSL = [];
				vm.taxYearData = [];
				vm.otherTaxYearsData = [];
				vm.epHistoryOptions = [];
				vm.columns = [];
				vm.columnDefs = [];
				//vm.epHistoryDataTableInitialized = false;
				loadGTAPSPost86EpHistoryData();
			}

			function loadGTAPSPost86EpHistoryData(selectedTaxYear)
			{
				vm.loading = true;
				if (angular.isDefined(selectedTaxYear) && selectedTaxYear !== '') {
					vm.selectedHoLeidParams.tax_year = selectedTaxYear;
					var index = vm.otherTaxYearsData.findIndex(otherTaxYearRow => (otherTaxYearRow.TAX_YEAR == rowId));
					if (index >= 0) {
						vm.selectedHoLeidParams.scenario = vm.otherTaxYearsData[index].SCENARIO;
						vm.selectedHoLeidParams.parent_me_string = vm.otherTaxYearsData[index].ME_KEY;
					}
				}
				post86EpHistoryReportFactory.getGtapsPost86EpHistoryData(vm.selectedHoLeidParams).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
							vm.displayTable = false;
						} else {				
							vm.post86EpHistoryData = data.jsonObject;
							vm.cacheAllActiveData.post86EpHistoryData = data.jsonObject;
							vm.loadHistoryDataForTaxYear(selectedTaxYear);
							vm.loading = false;
							
						}
						
				});
			}

		
			
			function resetData() {
				/*vm.total959c3EPPoolFuncCurr = 0;
				vm.totalNH959c3EPPoolFuncCurr = 0;
				vm.totalPtiFuncCurr = 0;
				vm.total959c3EPPoolUSD = 0;
				vm.totalNH959c3EPPoolUSD = 0;
				vm.totalPtiUSD = 0;
				vm.totalTaxPool = 0;
				vm.totalNHTaxPool = 0;
				vm.hovering959c3Ep = 0;
				vm.hovering959c3Taxes = 0;
				vm.totalUSDBasisInPti = 0;
				vm.effectiveRateOn959c3TotalEp = 0;
				vm.effectiveRateOn959c3TotalEpNH = 0;
				vm.estimatedPtiExchangeGainLoss = 0;
				vm.recaptureAccounts = "No";
				vm.qualifiedDeficits = "No";*/
			}
			
			vm.loadHistoryDataForTaxYear = function(selectedTaxYear) {
				//Reset all Data
				resetData();
				vm.columnNames = [];
				vm.epTotalAmts = [];
				vm.ptiTotalAmts = [];
				vm.lineDescData = [];
				vm.lineDescs = [];
				vm.lineDescsCite = [];
				vm.loading = false;
				if (vm.taxYearSL && vm.taxYearSL.length > 0) {
					//Do Nothing
				} else if (vm.post86EpHistoryData[0].hasOwnProperty("TAX_YEAR_LIST_JSON")) {
					var taxYearListJson = JSON.parse(vm.post86EpHistoryData[0].TAX_YEAR_LIST_JSON);
					if (taxYearListJson.length > 0) {
						//Populate vm.taxYearSL with distinct tax years
						angular.forEach(taxYearListJson, function(taxYear) {
							if (angular.isDefined(taxYear.TAX_YEAR)) {
								var index = vm.taxYearSL.findIndex( taxYearRow => (taxYearRow.label === taxYear.TAX_YEAR && taxYearRow.value === taxYear.TAX_YEAR));
								//don't push it if it already exists
								if (index === -1) {
									vm.taxYearSL.push({label: taxYear.TAX_YEAR, value: taxYear.TAX_YEAR});
								}
							}
						});
					} else {
						vm.displayTable = false;
						return;
					}
				}

				if (vm.otherTaxYearsData && vm.otherTaxYearsData.length > 0) {
					//Do Nothing
				} else if (vm.post86EpHistoryData[0].hasOwnProperty("OTHER_TAX_YEARS_DATA_JSON")) {
					vm.otherTaxYearsData = JSON.parse(vm.post86EpHistoryData[0].OTHER_TAX_YEARS_DATA_JSON);
				}
				/*if (vm.post86EpHistoryData[0].hasOwnProperty("RECAPTURE_ACCT_COUNT")) {
					 vm.recaptureAccounts = vm.post86EpHistoryData[0].RECAPTURE_ACCT_COUNT > 0 ? "Yes" : "No"; 
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("QUAL_DEF_COUNT")) {
					 vm.qualifiedDeficits = vm.post86EpHistoryData[0].QUAL_DEF_COUNT > 0 ? "Yes" : "No"; 
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("PTI_BASIS_TOTAL_AMT")) {
					vm.totalUSDBasisInPti = vm.post86EpHistoryData[0].PTI_BASIS_TOTAL_AMT;
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("EP_SUMMARY_INFO_JSON")) {
					var epSummaryInfoJson = JSON.parse(vm.post86EpHistoryData[0].EP_SUMMARY_INFO_JSON);
					if (epSummaryInfoJson.length > 0) {
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EP_FC")) {
							vm.total959c3EPPoolFuncCurr = epSummaryInfoJson[0].POST_1986C3EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3EP_FC")) {
							vm.totalNH959c3EPPoolFuncCurr = epSummaryInfoJson[0].POST_HD_1986C3EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3EPUSD")) {
							vm.total959c3EPPoolUSD = epSummaryInfoJson[0].POST_1986C3EPUSD;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3EPUSD")) {
							vm.totalNH959c3EPPoolUSD = epSummaryInfoJson[0].POST_HD_1986C3EPUSD;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_1986C3TAXES")) {
							vm.totalTaxPool = epSummaryInfoJson[0].POST_1986C3TAXES;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("POST_HD_1986C3TAXES")) {
							vm.totalNHTaxPool = epSummaryInfoJson[0].POST_HD_1986C3TAXES;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("HD_EP_FC")) {
							vm.hovering959c3Ep = epSummaryInfoJson[0].HD_EP_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("HD_TAX_FC")) {
							vm.hovering959c3Taxes = epSummaryInfoJson[0].HD_TAX_FC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("ETRONPOST_1986C3EP")) {
							vm.effectiveRateOn959c3TotalEp = epSummaryInfoJson[0].ETRONPOST_1986C3EP;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("ETRONPOST_HD_1986C3EP")) {
							vm.effectiveRateOn959c3TotalEpNH = epSummaryInfoJson[0].ETRONPOST_HD_1986C3EP;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("PTIFC")) {
							vm.totalPtiFuncCurr = epSummaryInfoJson[0].PTIFC;
						}
						if (epSummaryInfoJson[0].hasOwnProperty("PTIUSD")) {
							vm.totalPtiUSD = epSummaryInfoJson[0].PTIUSD;
						}
					}
				}*/
				//Calculate estimatedPtiExchangeGainLoss
				if (angular.isDefined(vm.totalPtiUSD) && angular.isDefined(vm.totalUSDBasisInPti)) {
					vm.estimatedPtiExchangeGainLoss = vm.totalPtiUSD - vm.totalUSDBasisInPti;
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("GET_EP_CATEGORY_JSON")) {
					var epCategoryJson = JSON.parse(vm.post86EpHistoryData[0].GET_EP_CATEGORY_JSON);
					var previousPtiType = vm.totalUSDBasisInPti;
					angular.forEach(epCategoryJson, function(epCategory) {
						if (epCategory.hasOwnProperty("PTI_TYPE")) {
							if (previousPtiType === "" || epCategory.PTI_TYPE !== previousPtiType) {
								vm.columnNames.push("Total " + epCategory.PTI_TYPE);
							}
							previousPtiType = epCategory.PTI_TYPE;
						}
						if (epCategory.hasOwnProperty("CATEGORY_NAME")) {
							vm.columnNames.push(epCategory.CATEGORY_NAME);
						}
					});
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("GET_EP_TOTAL_AMTS_JSON")) {
					var epTotalAmtsJson = JSON.parse(vm.post86EpHistoryData[0].GET_EP_TOTAL_AMTS_JSON);
					var previousPtiType = "";
					angular.forEach(epTotalAmtsJson, function(epTotalAmt) {
						if (epTotalAmt.hasOwnProperty("LINE_DESC") && epTotalAmt.hasOwnProperty("TOTAL_AMT")) {
							vm.epTotalAmts[epTotalAmt.LINE_DESC] = epTotalAmt.TOTAL_AMT;
						}
					});
				}
				if (vm.post86EpHistoryData[0].hasOwnProperty("GET_PTI_TOTAL_AMTS_JSON")) {
					var ptiTotalAmtsJson = JSON.parse(vm.post86EpHistoryData[0].GET_PTI_TOTAL_AMTS_JSON);
					var previousPtiType = "";
					var ptiTypeDataPerColumn;
					angular.forEach(ptiTotalAmtsJson, function(ptiTotalAmt) {
						if (ptiTotalAmt.hasOwnProperty("LINE_DESC") && ptiTotalAmt.hasOwnProperty("PTI_TYPE") &&
							ptiTotalAmt.hasOwnProperty("TOTAL_AMT")) {
							ptiTypeDataPerColumn = {};
							//ptiTypeDataPerColumn["Total " + ptiTotalAmt.PTI_TYPE] = ptiTotalAmt.TOTAL_AMT;
							if(!angular.isDefined(vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]))
								{
								  vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]= [];
								}
							vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]["Total " + ptiTotalAmt.PTI_TYPE]=ptiTotalAmt.TOTAL_AMT;
							//vm.ptiTotalAmts[ptiTotalAmt.LINE_DESC]= (ptiTypeDataPerColumn);
						}
					});
				}
				var index;
				var cite;
				var prevLineDesc = "";
				var lineDescDataPerColumn = {};
				var amtsLen = 0;
				angular.forEach(vm.post86EpHistoryData, function(lineDescRow) {
					amtsLen++;
					cite = "";
					if (angular.isDefined(lineDescRow.LINE_DESC)) {
						index = -1;
						index = vm.lineDescs.findIndex( lineDesc => (lineDescRow.LINE_DESC === lineDesc));
						//don't push it if it already exists
						if (index === -1) {
							if (angular.isDefined(lineDescRow.CITE)) {
								cite = lineDescRow.CITE;
							}
							vm.lineDescs.push(lineDescRow.LINE_DESC);
							vm.lineDescsCite[lineDescRow.LINE_DESC] = cite;
							vm.lineDescData[lineDescRow.LINE_DESC] = {};
						}
						if (prevLineDesc === "") {
							prevLineDesc = lineDescRow.LINE_DESC;
						} else if (prevLineDesc !== lineDescRow.LINE_DESC || amtsLen === vm.post86EpHistoryData.length) {
							vm.lineDescData[prevLineDesc] = lineDescDataPerColumn;
							lineDescDataPerColumn = {};
							prevLineDesc = lineDescRow.LINE_DESC;
						}
						if (angular.isDefined(lineDescRow.CATEGORY_NAME) && angular.isDefined(lineDescRow.AMT)) {
							lineDescDataPerColumn[lineDescRow.CATEGORY_NAME] = lineDescRow.AMT;
						}
					}
				});
				//Check if table has to be displayed or not
				if (vm.columnNames && vm.columnNames.length > 1 &&
					vm.lineDescs && vm.lineDescs.length > 0) {
					vm.displayTable = true;
				} else{
					vm.displayTable = false;
				}
				loadDataForGTTable(selectedTaxYear);
			}

			function loadDataForGTTable(selectedTaxYear) {
				var epHistoryData = [];
				if (vm.columns.length <= 0 && vm.columnNames && vm.columnNames.length > 0) {
					//vm.columns.push({title:"Tax Year", data: "Tax Year"});
					vm.columns.push({title:"Description", data: "Description"});
					vm.columns.push({title:"Cite", data: "Cite"});
					vm.columns.push({title:"Total Amount", data: "Total Amount"});
					vm.columnDefs.push({
							targets: 2,
							render: $.fn.dataTable.render.number(','),
							sClass: "rightAlign"
						}); 
					var i = 3;
					angular.forEach(vm.columnNames, function(columnName) {
						vm.columns.push({title: columnName, data: columnName});
						vm.columnDefs.push({
								targets: i,
								render: $.fn.dataTable.render.number(','),
								sClass: "rightAlign"
							}); 
						i++;
					});
				}
				if (vm.lineDescs && vm.lineDescs.length > 0) {
					var i = 0;
					angular.forEach(vm.lineDescs, function(lineDesc) {
						epHistoryData.push({});
						var columnName = '';
						angular.forEach(vm.columns, function(column) {
							columnName = column.title;
							// if (columnName && columnName.toUpperCase() === 'TAX YEAR') {
							// 	epHistoryData[i][columnName] = selectedTaxYear;
							// } else 
							if (columnName && columnName.toUpperCase() === 'DESCRIPTION') {
								epHistoryData[i][columnName] = lineDesc;
							} else if (columnName && columnName.toUpperCase() === 'CITE') {
								epHistoryData[i][columnName] = angular.isDefined(vm.lineDescsCite[lineDesc]) ? vm.lineDescsCite[lineDesc] : '';
							} else if (columnName && columnName.toUpperCase() === 'TOTAL AMOUNT') {
								epHistoryData[i][columnName] = angular.isDefined(vm.epTotalAmts[lineDesc]) ? vm.epTotalAmts[lineDesc] : '';
							} else if (columnName && columnName.toUpperCase().startsWith('TOTAL')) {//For PTI Type totals
								epHistoryData[i][columnName] = angular.isDefined(vm.ptiTotalAmts[lineDesc][columnName]) ? vm.ptiTotalAmts[lineDesc][columnName] : '';
							} else {
								epHistoryData[i][columnName] = angular.isDefined(vm.lineDescData[lineDesc][columnName]) ? vm.lineDescData[lineDesc][columnName] : '';
							}
						});
						i++;
					});
				}
				vm.taxYearData[selectedTaxYear] = epHistoryData;
				vm.epHistoryOptions[selectedTaxYear] = {
					data: vm.taxYearData[selectedTaxYear],
					columns: vm.columns,
					groupBy: "Tax Year",
					columnDefs: vm.columnDefs,//[
							// {
							// 		targets: 5,
							// 		render: function (data, type, row) {
							// 			if (angular.isDefined(data)) {
							// 				if (data < 0) {
							// 						return "<span style='color:red'>($ " + Math.abs(data).toLocaleString() + " )</span>"
							// 				}
							// 				else {
							// 						return data.toLocaleString();
							// 				}
							// 			}
							// 		}
	
							// }
					//],
					fixedHeader: true,
					paging: false,
					// bLengthChange: false,
					// fixedHeader: true,
					// scrollY: 400,
					scroller: false,
					searching: false,
					bSort: false,
					"order": false,
	
				};
				vm.cacheAllActiveData.commonPtiData = gtapsCommonTemplateFactory.statusPti.jsonObject;
				  console.log("|||||||| cmmon pti data||||||||||" , vm.cacheAllActiveData.commonPtiData)
				vm.cacheAllActiveData.commonPtepData = gtapsCommonTemplateFactory.statusPtep.jsonObject;
				activeScreenEp.loading_data = false;
			    workspaceFactory.setCache(activeScreenEp, vm.cacheAllActiveData);
			}

			/*vm.loadPost86EpHistoryDataForTaxYear = function() {
				loadGTAPSPost86EpHistoryData();
			}*/

			vm.getColumnValue = function(lineDesc, columnName) {
				if (columnName && columnName.toUpperCase() === "DESCRIPTION") {
					return lineDesc;
				}
				if (angular.isDefined(vm.lineDescData[lineDesc][columnName])) {
					return vm.lineDescData[lineDesc][columnName];
				} else if (columnName && columnName.toUpperCase().startsWith("TOTAL")) {
					if (angular.isDefined(vm.ptiTotalAmts[lineDesc][columnName])) {
						return vm.ptiTotalAmts[lineDesc][columnName];
					} else {//For Totals return 0
						return 0;
					}
				}
				return "";
			}
			
			registerEvents();

			function registerEvents() {
				//On Drilldown
				$(element).on('click', 'td.details-control', function (e) {
					if (angular.isUndefined(vm.selectedHoLeidParams) || vm.selectedHoLeidParams === null) {
						return;
					}
					var rowId = $(this)[0].getAttribute('rowId');//$(this).attr('rowId');
					// if (vm.dataLoading[rowId]) {
					// 	AlertService.add("info", "Please wait while we complete this request", 4000);
					// 	return;
					// }
					vm.dataLoading[rowId] = true;
					var tr = $(this).closest('tr');
					console.log(tr)
					//var row = $scope.table.row(tr);
					var row;
					try {
						row = $("#epHistoryMainTable").DataTable().row(tr);
					} catch (e) {
						console.log('Error while initializing dataTable');
					}
					//vm.epHistoryOptions[vm.selectedTaxYear].data = row.data().children;
					//Check if Data is already available for this taxYear
					if (angular.isUndefined(vm.taxYearData[rowId]) || (vm.taxYearData[rowId]).length <= 0) {
						vm.selectedHoLeidParams.tax_year = rowId;
						var index = vm.otherTaxYearsData.findIndex(otherTaxYearRow => (otherTaxYearRow.TAX_YEAR == rowId));
						if (index >= 0) {
							vm.selectedHoLeidParams.scenario = vm.otherTaxYearsData[index].SCENARIO;
							vm.selectedHoLeidParams.parent_me_string = vm.otherTaxYearsData[index].ME_KEY;
						}
						post86EpHistoryReportFactory.getGtapsPost86EpHistoryData(vm.selectedHoLeidParams).then(
							function(data) {
								if (data.callSuccess !== "1") {
									AlertService.add("error", data.errorMessage, 4000);
									vm.displayTable = false;
								} else {
									vm.post86EpHistoryData = data.jsonObject;
									vm.loadHistoryDataForTaxYear(rowId);
									var temp = "<div class='wrapper'><table id='table-" + rowId + "' class='display nowrap table table-bordered table-striped sticky-header table-condensed' style='width:100%'></table></div>"
									if (row.child.isShown()) {
										row.child.hide();
										tr.removeClass('shown');
										$("#epHistoryMainTable").DataTable().draw();
										console.log('table redrawn');
									}
									else {
										row.child(temp).show();
										tr.addClass('shown');
									}
									var dataTableOptions = Object.assign({}, vm.epHistoryOptions[rowId]);
									dataTableOptions.searching = false;//scope.searchable;
									//dataTableOptions.responsive = true;
									dataTableOptions.dom = 't';
									dataTableOptions.order = false;
									dataTableOptions.scrollY = '35vh';
									//dataTableOptions.scrollX = true;
									$("#table-" + rowId).DataTable(dataTableOptions);
									vm.dataLoading[rowId] = false;
								}
//								workspaceFactory.setCache(workspaceFactory.activeScreen, vm);
								 workspaceFactory.setCache(activeScreenEp, vm.cacheAllActiveData);
								
						});
					} else {
						var temp = "<div class='wrapper'><table id='table-" + rowId + "' class='display nowrap table table-bordered table-striped sticky-header table-condensed' style='width:100%'></table></div>"
						if (row.child.isShown()) {
							row.child.hide();
							tr.removeClass('shown');
							$("#epHistoryMainTable").DataTable().draw();
							console.log('table redrawn');
						}
						else {
							row.child(temp).show();
							tr.addClass('shown');
						}
						var dataTableOptions = Object.assign({}, vm.epHistoryOptions[rowId]);
						dataTableOptions.searching = false;//scope.searchable;
						//dataTableOptions.responsive = true;
						dataTableOptions.order = false;
						dataTableOptions.dom = 't';
						dataTableOptions.scrollY = '35vh';
						//dataTableOptions.scrollX = true;
						$("#table-" + rowId).DataTable(dataTableOptions);
						vm.dataLoading[rowId] = false;
						//workspaceFactory.setCache(workspaceFactory.activeScreen, vm);
						 workspaceFactory.setCache(activeScreenEp, vm.cacheAllActiveData);
					}
				});
			}

			// $timeout(function () {
			// 	$("#epHistoryMainTable").DataTable().rows().every(function(rowIdx, tableLoop, rowLoop){
			// 		if (rowIdx == 0 && !this.child.isShown()){
			// 		  //trigger the click event for the details
			// 		  $('td.details-control', this.node()).trigger('click');
			// 		}
			// 	 });
			// }, 2000);

			$scope.$watch(function() { 
					return ($.fn.dataTable.isDataTable("#epHistoryMainTable")) ? $("#epHistoryMainTable").DataTable().data().count() : $("#epHistoryMainTable").DataTable({info: false, sort: false, scrollCollapse: true,searching:false, paging: false, scroller: false,order: false}).data().count();
				}, function(newValue, oldValue) {
				$("#epHistoryMainTable").DataTable().rows().every(function(rowIdx, tableLoop, rowLoop){
					if (rowIdx == 0 && !this.child.isShown()){
					//trigger the click event for the details
					$('td.details-control', this.node()).trigger('click');
					}
				});
			},true);

			/*vm.goToScreen = function(screenName) {
				if (vm.menuData) {
					angular.forEach(vm.menuData, function (channelNode) {
						if (channelNode.label === 'Tax Return') {
							angular.forEach(channelNode.children, function (taskNode) {
								if (taskNode.label === 'GTAPS') {
									angular.forEach(taskNode.children, function (activityNode) {
										if (activityNode.label === screenName) {
											var _node = activityNode;
											console.log("activity:", _node);
											if (_node.children && _node.children.length) {
												_node.collapsed = !_node.collapsed;
											} else {
												menuDataService.removeSelected();
												_node.selected = true;
												menuDataService.setSelected(_node); //THIS DOES THE HIGHLIGHTING OF PARENT NODES
												$scope.currentNode = _node;
												if (_node.screens && _node.screens.length && _node.screens[0].link) {
													_node.screens[0].filters = workspaceFactory.activeScreen.filters;
													_node.screens[0].data = "LOAD";
													workspaceFactory.setActivity(_node, true);
												} else {
													$state.go('404', {}, {
														inherit: true
													});
													AlertService.add("warning", "No screens defined for this activity");
												}
											}
										}
									});
								}
							});
						}
					});
				}
			}*/
		}
});
