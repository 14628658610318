define([
    'angular',
    './ghostConsoleController',
    './ghostConsoleService'

], function () {
    'use strict';
    return angular.module('app.ghostConsole', ['app.ghostHomeController','app.ghostHomeService'] )
    
    .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider) {
        $stateProvider
            .state('ghost-console', {
                url: '/ghost-console',
                templateUrl: 'app/components/Custom/ghostConsole/ghost_console_tpl.html',
                controller: 'ghostHomeController as ctrl',
                data: {},
                access: ""
            })
    }]);

});