define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.ptiSummaryReportService',[])
       .factory("ptiSummaryReportFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	   		,'$q', '$http', '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory' 
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
    			   ,$q, $http, $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var ptiSummaryReportFactory ={};
	           ptiSummaryReportFactory.url ={};
	           ptiSummaryReportFactory.url.getGtapsPtiSummaryReportData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=vz4pf4";//Action id 1194
			 
			   ptiSummaryReportFactory.url.getGtapsPtepSummaryReportData = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=8m6hpz";


		       ptiSummaryReportFactory.getGtapsPtiSummaryReportData = function(passedParams) {
					var params = {"process_name":"GTAPS Get CFC's with Recapture Accounts"};
				   params = _.merge({},  passedParams, params);
		           var promise = $http({
		        	   method: "post",
		               url: ptiSummaryReportFactory.url.getGtapsPtiSummaryReportData,
		               data: params
		           }).then(function (response) {
		               //var status = response.status;
		               return response.data;
		           });
		
		           return promise;
			   };
			   
			   ptiSummaryReportFactory.getGtapsPtepSummaryReportData = function(passedParams) {
				var params = {};
				params = _.merge({},  passedParams, params);
					var promise = $http({
						method: "post",
						url: ptiSummaryReportFactory.url.getGtapsPtepSummaryReportData,
						data: params
					}).then(function (response) {
						//var status = response.status;
						return response.data;
					});
			
					return promise;
				};
			
           
	           return ptiSummaryReportFactory;
       }]);

   	  return services;
});