define([
    'angular',
    './controversyProjectDashboardCtrl'

], function () {
    'use strict';

  
   return angular.module('app.controversy-projects', ['app.controversyProjectDashboardCtrl'])
   .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
    $stateProvider
        .state('dashboard-project', {
            url: '/dashboard-project',
            templateUrl: 'app/components/ControversyApp/controversy-projects/controversy-project-dashboard.html',
            controller: 'controversyProjectDashboardController as ctrl',
            type:'custom',
        });
    }]);
   
});