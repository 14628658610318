/**
 * Created by 212544474 on 8/7/2017.
 */
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.wwniM3ReconService',[])
        .factory("wwniM3ReconFactory",['$http','$rootScope', '$log','JsonObjectFactory','AlertService', '$injector','workspaceFactory','GlobalService','$stateParams','GENERAL_CONFIG', function($http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,$stateParams,GENERAL_CONFIG) {
            var factory = {};
            var returnObj = {};

            factory.saveWWNIM3Comments = function(_callName , _data){
                
                
                $rootScope.$broadcast('gridRefresh', false); 
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=699";
                
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.object_id = _data.rowData.object_id;
               
                //params.tax_year = _data.rowData.TAX_YEAR;
                params.leid = _data.rowData.LEID;
                params.cdr_no = _data.rowData.CDR_NO;
                //params.reporting_period = _data.rowData.REPORTING_PERIOD;
                params.bsla_code = _data.rowData.BSLA_CODE;
                params.reporting_curr = _data.rowData.CURRENCY_CODE;
                params.comments = _data.rowData.COMMENTS;
                
                console.log('params to save');
                console.log(params);
               $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        AlertService.add("success", "Comment has been saved", 3000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        //_data.rowData[_data.colData.map] = "";
                        $rootScope.$broadcast('gridRefresh', false);
                    }

                });
            }
            return factory;
        }])

    return services;
});
