define([
    'angular',
    './PshipSplAllocationController'



], function () {
    'use strict';
    return angular.module('app.PshipSplAllocation', ['app.PshipSplAllocationController'] );

});
