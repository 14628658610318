define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.section965TaxStmtService',[])

        .factory("Section965TaxStmtFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','AlertService','DataFiltersFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,AlertService,DataFiltersFactory,JsonObjectFactory,workspaceFactory,GENERAL_CONFIG) {
            var section965TaxStmtFactory = {};
            section965TaxStmtFactory.url = {};
            section965TaxStmtFactory.url.saveData  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9qnvdz";
       
            section965TaxStmtFactory.getActiveScreen = function() {
                return workspaceFactory.activeScreen.screen_key;
            };
            
            section965TaxStmtFactory.getSchdCData = function () {
                var params = {
                    "action_code":"gnrnp1"
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                    console.log(data);
                    if(data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                        AlertService.add("error", data.errorMessage);
                        return false;
                    }else{
                       
                        return data.jsonObject;
                    }
                });
               
            };

            section965TaxStmtFactory.saveData = function(_data){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);

                params.ghost_scenario = _data.ghost_scenario;
                params.row_order = _data.row_order;
                params.total_amt = _data.total_amt;
                params.ge_total_amt = _data.ge_total_amt;
                params.gecs_total_amt = _data.gecs_total_amt;
                params.election_type = _data.election_type;

                
                var promise = $http({
                    method: "post",
                    url: section965TaxStmtFactory.url.saveData,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;


            };


            return section965TaxStmtFactory;
        }]);


    return services;


});