/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.qreentityService',[])

        .factory("QeFactoryA",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
        	 var tbbsreclassFactory = {};
             tbbsreclassFactory.url = {};
            tbbsreclassFactory.url.saveTBBSReclass  =  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=gr0s3k";
           // GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hhba22"
             /*var URLS = {
                 FETCH: 'getReclass.ge',
                 UPDATE: 'saveReclass.ge'
             }*/

             tbbsreclassFactory.getActiveScreen = function() {
                 return workspaceFactory.activeScreen.screen_key;
             };
             
             tbbsreclassFactory.getData = function (_url,_params) {
                 console.log("TEST/////////////////////////////////////////////////");
             };



             /*reclassFactory.saveReclass = function(_data,_accounts){
                 var accounts = {jsonObj:JSON.stringify(_accounts)}
                 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                 var params = _.merge({},  filterParams , _data);
                 _.merge({},  GlobalService.globalParams , params);
                 _.extend(params, accounts);



                 console.log("params:============================ " , params);

                 var promise = $http({
                     method: "post",
                     url: URLS.UPDATE,
                     data: params
                 }).then(function (response) {
                     var status = response.status;
                     console.log("response: " , response)
                     console.log("response.status: " , response.status)
                     return response.data;
                 });
                 return promise;
             }*/
             
             tbbsreclassFactory.saveTBBSReclass = function(_data,_accounts){

                 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                 var jsonObj = JSON.stringify(_accounts);
                 var jsonSettings =  JSON.stringify(_data);

                 var params = filterParams;
                 params = _.merge({},  GlobalService.globalParams , params);
                 params =  _.extend({jsonObj:jsonObj}, params);
                 params =  _.extend({jsonSettings:jsonSettings}, params);
                 //params =  _.extend({jsonSettings:jsonSettings}, _data);
                 params.process_name =  "RECLASS_TBBS_SAVE";
                 console.log("reclass params to save");
                 console.log(params);
                 var promise = $http({
                     method: "post",
                     url: tbbsreclassFactory.url.saveTBBSReclass,
                     data: params
                 }).then(function (response) {
                     var status = response.status;
                     return response.data;
                 });

                 return promise;

             };
             
             tbbsreclassFactory.deleteReclass = function(_data) {
               //94   reclassFactory.url.deleteReclass
               console.log('_data passed to delete reclass function');
               console.log(_data);
               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
               var jsonObj = JSON.stringify(_data);
               var jsonSettings =  JSON.stringify(_data);
               
                 var params = filterParams;
                 params = _.merge({},  GlobalService.globalParams , params);
                 params =  _.extend({jsonObj:jsonObj}, params);
                 params =  _.extend({jsonSettings:jsonSettings}, params);
                 params.process_name =  "RECLASS_TBBS_DELETE";
                 console.log("reclass params to delete");
                 console.log(params);
                 var promise = $http({
                     method: "post",
                     url: tbbsreclassFactory.url.deleteReclass,
                     data: params
                 }).then(function (response) {
                     var status = response.status;
                     return response.data;
                 });

                 return promise;

             };

             return tbbsreclassFactory;
         }]);


     return services;


 });