define([

	'angular'

], function () {

	'use strict';

	var controllers = angular.module('app.scheduleJController', [])
		.controller('scheduleJController', ['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData', 'JsonObjectFactory', 'scheduleJFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', scheduleJController]);

	function scheduleJController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, JsonObjectFactory, scheduleJFactory, GENERAL_CONFIG, USER_SETTINGS, workspaceFactory) {
		var vm = this;
		vm.modalData = [];
		vm.disable = true;
		vm.loading = false;
		vm.crudLoading = false;
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
		getModalData();
		function getModalData() {
			vm.loading = true;
			scheduleJFactory.getModalData().then(response => {
				vm.modalData = response.jsonObject;
				if(vm.modalData && vm.modalData.length>0){
					
				}
				else {
					AlertService.add("error", "No data found!");
				}
				vm.loading = false;
			});
		}

		vm.valuechanged = function () {
            vm.disable = false;
        }

		vm.checkbox = function (index) {
			if(index == 1 && vm.modalData[index].CHECKBOX_VALUE == 'Y') {
				vm.modalData[0].CHECKBOX_VALUE = 'N';
			}
			else if(index == 0 && vm.modalData[index].CHECKBOX_VALUE == 'Y') {
				vm.modalData[1].CHECKBOX_VALUE = 'N';
			}
			vm.modalData = angular.copy(vm.modalData);
			vm.disable = false;
		}

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.reset = function() {
            getModalData();
        }

		vm.save = function () {
            vm.clob_settings = [];
            var message = "Form Details are successfully updated."
            vm.crudLoading = true;
            vm.clob_settings = { tax_year: parseInt(filterParams.tax_year)};
            scheduleJFactory.saveModal(vm.modalData, vm.clob_settings).then(data => {
                if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
					
				}
            });
        }
	}

	return controllers;

});