define([
    'angular',
    './CalcsFilingGroupStatusController',
    './CalcsFilingGroupStatusService'

], function () {
    'use strict';
    return angular.module('app.CalcsFilingGroupStatus', ['app.CalcsFilingGroupStatusController','app.CalcsFilingGroupStatusService'] )
    
    .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider) {
        $stateProvider
            .state('calcs-filing-group-data-setup', {
                url: '/calcs-filing-group-data-setup',
                templateUrl: 'app/components/Custom/CalcsFilingGroupStatus/CalcsFilingGroupStatus_tpl.html',
                controller: 'CalcsFilingGroupStatusController as ctrl',
                data: {},
                access: ""
            })
    }]);

});