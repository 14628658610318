define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.ghostftceNonghostDeleteCtrl',  [])
		.controller('ghostftceNonghostDeleteCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','USER_SETTINGS','firghostftceNonghostScreenFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', ghostftceNonghostDeleteCtrl]);


	function ghostftceNonghostDeleteCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,USER_SETTINGS,firghostftceNonghostScreenFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		var vm = this;
		vm.deletePayment = function(){
			var RP_SEQ_ID = vm.rowData.FTC_ELIGIBILITY_SEQUENCE;
	            var paymentJson = [{rp_seq_id: vm.rowData.FTC_ELIGIBILITY_SEQUENCE}];
	        	//validateJson(paymentJson);
	        	vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
	        	var success_message = "The  Recipient was successfully deleted";
				$rootScope.$emit('gridUpdate', {}); 
				//init();
				$uibModalInstance.close();				
	        	console.log("The save json data was :::::",paymentJson);
	        	firghostftceNonghostScreenFactory.deletePayment(paymentJson, vm.clob_settings, vm.logged_in_user).then(function(data){
	        		console.log("Result from rep Trans save data was ---------------::::", data);
	        		if(null != data.data.errorMessage && data.data.errorMessage != "null"){
	        			console.log("There was the error message and the message is ", data.data.errorMessage);
	        			AlertService.add("error", data.data.errorMessage, 4000);
	        		}else {
	        			console.log("There wa no error message and the message was ", data.data.errorMessage);
	        			AlertService.add("success", success_message, 4000);
						$rootScope.$emit('gridUpdate', {}); 
                        //init();
                        $uibModalInstance.close();
	        		}
	        	});
	        }
		
		
        vm.rowData = rowData ||$scope.$parent.rowData;
        vm.colData = colData;
        vm.gridData = gridData;
        vm.globalParams = GlobalService.globalParams;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        console.log("Here is the starting point and the rowData was ::::::::", vm.rowData);
        console.log("Here is the starting point and the global Params was ::::::::", vm.globalParams);
        vm.leid = vm.rowData.LEID;
        vm.cdr_no = vm.rowData.CDR_NO;
        vm.combination_key = vm.rowData.HO_COMBINATION_KEY;
		vm.adjAmts = [];
        vm.modalTitle = "Delete Payment";
        vm.isDelete = true;
        
        vm.cancel = function () {
			$scope.crudLoading = false;
    		console.log("in cancel");
    		$uibModalInstance.dismiss('cancel');
        };
	}
	  }); 