define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.ghostUpdateCombinationCtrl', [])
            .controller('ghostUpdateCombinationCtrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',ghostUpdateCombinationCtrl]
            );

        function ghostUpdateCombinationCtrl($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.userSettings = USER_SETTINGS;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;            
            vm.tax_year = filterParams.tax_year;
            var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
            vm.globalParams = JSON.stringify(tempObj);
            vm.checkLockeOdbj =[];
            vm.baseFilterParams = [];
            vm.dataLoading = true;

            $scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = filterParams.scenario;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.checkLockeOdbj.push({"scenario_key":vm.scenario, "tax_year":vm.tax_year, "scenarioCode": vm.scenarioCode, "is_issue_key_enabled":'N'});

                    vm.baseFilterParams.push(
                        {"tax_year":filterParams.tax_year,
                        "scenario":filterParams.scenario, 
                        "me_string":filterParams.me_string, 
                        "ho_leid_opt":filterParams.ho_leid_opt, 
                        "filing_key": filterParams.filing_key,
                        "screen_key" :filterParams.screen_key
                    });

                vm.baseFilterParamsStr = JSON.stringify(filterParams);	
                 
                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);
                    
                    const html = `<gtw-ghost-update-combination-ce id="close-currconfirm-info" base-urls="{{ctrl.baseURLs}}" global-params="{{ctrl.globalParams}}" filter-params="{{ctrl.baseFilterParamsStr}}"
                    			//	tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" sso-id="{{ctrl.logged_in_user}}" 
                                scenario-code = "{{ctrl.scenarioCode}}" check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" modal-name = "{{modal_name}}"class="gtw-web-components"></gtw-ghost-update-combination-ce>`;
                    vm.webComponentghostUpdateCombinationSyncHtml = html;

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {
                	$window.document.querySelector('gtw-ghost-update-combination-ce')
                           .addEventListener('close-ghostUpdateCombinationconfirm-info', (event)=>{
                               console.log('event',event);
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {

                        $window.document.querySelector('gtw-ghost-update-combination-ce')
                            .addEventListener('save-ghostUpdateCombinationsync-info', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-ghostUpdateCombinationsync-info'){
									var args = {};
                        			$rootScope.$emit('gridUpdate', args);
                                   /* $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });*/
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added ...');
                        vm.dataLoading = false;
                    },3000
                    
                );
                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
);
