define(
		[ 'angular', './QbaiService', ],
		function() {
			'use strict';

			var controllers = angular.module('app.QbaiCtrl',
					[ 'app.QbaiServices' ]).controller(
					'qbaiCtrl',
					[ '$scope', '$rootScope', '$state', '$http',
							'AlertService', 'USER_SETTINGS', 'GlobalService',
							'JsonObjectFactory', 'workspaceFactory','$filter',
							'GENERAL_CONFIG', 'qbaiFactory', 'rowData',
							'gridData', '$uibModal', '$uibModalInstance',
							qbaiCtrl ])
							
							controllers.directive(
							'allowDecimalNumbers',
							function() {
								return {
									restrict : 'A',
									link : function(scope, elm, attrs, ctrl) {
										elm
												.on(
														'keydown',
														function(event) {
															var $input = $(this);
															console.log("-->  $input : ", $input);
															var value = $input
																	.val();
															console.log("-->  value : ", value);
															value = value
																	.replace(
																			/[-+][^0-9\.]/g,
																			'')
															var findsDot = new RegExp(
																	/\./g)
															var containsDot = value
																	.match(findsDot)
															if (containsDot != null
																	&& ([
																			46,
																			110,
																			190 ]
																			.indexOf(event.which) > -1)) {
																event
																		.preventDefault();
																return false;
															}
															$input.val(value);
															if (event.which == 64
																	|| event.which == 16) {
																// numbers
																return false;
															}
															if ([ 8, 13, 27,
																	37, 38, 39,
																	40, 110 ]
																	.indexOf(event.which) > -1) {
																// backspace,
																// enter,
																// escape,
																// arrows
																return true;
															} else if (event.which >= 48
																	&& event.which <= 57) {
																// numbers
																return true;
															} else if (event.which >= 96
																	&& event.which <= 105) {
																// numpad number
																return true;
															} else if ([ 46,
																	110, 190 ]
																	.indexOf(event.which) > -1) {
																// dot and
																// numpad dot
																return true;
															} else {
																event
																		.preventDefault();
																return false;
															}
														});
									}
								}
							})
			function qbaiCtrl($scope, $rootScope, $state, $http, AlertService,
					USER_SETTINGS, GlobalService, JsonObjectFactory,
					workspaceFactory,$filter, GENERAL_CONFIG, qbaiFactory, rowData,
					gridData, $uibModal, $uibModalInstance) {
				var vm = this;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                vm.curentyear = filterParams.tax_year; 
                vm.scenario = filterParams.scenario;
                vm.jcd_key  = GlobalService.globalParams.jcd_key;	
				
				vm.isSaveClicked = false;
				vm.rowData = rowData;
				vm.gridData = gridData;
				vm.userSettings = USER_SETTINGS;
				vm.isCreate = (gridData.length == 0) ? true : false;
				vm.getDefaultData = function() {
					if (!vm.isCreate) {
						vm.q1 = (vm.rowData && vm.rowData.Q1 !== undefined) ? vm.rowData.Q1
								: null;
						vm.q2 = (vm.rowData && vm.rowData.Q2 !== undefined) ? vm.rowData.Q2
								: null;
						vm.q3 = (vm.rowData && vm.rowData.Q3 !== undefined) ? vm.rowData.Q3
								: null;
						vm.q4 = (vm.rowData && vm.rowData.Q4 !== undefined) ? vm.rowData.Q4
								: null;
						vm.average = (vm.rowData && vm.rowData.AVERAGE !== undefined) ? vm.rowData.AVERAGE
								: null;
					}
				}
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
								vm.cancel();										
								AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
								return;
						}							
					});
				});
				vm.getDefaultData();
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
				vm.reset = function() {
					vm.getDefaultData();
				};
				
				vm.save = function() {
					vm.isSaveClicked = true;
					if((vm.q1 == undefined  || vm.q1 == null)  
						|| (vm.q2 == undefined  || vm.q2 == null)  
							 || (vm.q4 == undefined  || vm.q4 == null ) 
								 || (vm.q3 == undefined  || vm.q3 == null ) 
									 || (vm.average == undefined  || vm.average == null)  ){
						AlertService.add("error", "Please enter all the required fields.",4000);
						vm.isSaveClicked = false;
						return;
					}
					
					
					var details = [], returnObj = {}, otherInfo = {};
					var isError = false, errorInfo = "";
					otherInfo['sso_id'] = vm.userSettings.user.sso_id;
					otherInfo['screen_key'] = GlobalService.globalParams.screen_key;
					otherInfo['tax_year'] = GlobalService.globalParams.tax_year;
					otherInfo['scenario'] = GlobalService.globalParams.scenario;
					otherInfo['jcd_key'] = GlobalService.globalParams.jcd_key;

				//	returnObj["type"] = vm.isCreate ? 'I' : 'U';
					returnObj["leid"] = vm.rowData.LEID;
					returnObj["tax_code"] = vm.rowData.TAX_CODE;
					returnObj["reporting_period"] = vm.rowData.REPORTING_PERIOD;
					returnObj["me_code"] = vm.rowData.ME_CODE;
					returnObj["q1"] = vm.q1;
					returnObj['q2'] = vm.q2;
					returnObj["q3"] = vm.q3;
					returnObj['q4'] = vm.q4;
					returnObj["average"] = vm.average;
					returnObj['p_tax_year'] = vm.curentyear;
					returnObj['p_scenario'] = vm.scenario;
					returnObj['p_jcd_key'] = vm.jcd_key;
					if (!vm.isCreate) {
						// returnObj = _.extend(vm.rowData, returnObj);
						//returnObj["tag_key"] = vm.rowData.TAG_KEY;
						//returnObj["combination_key"] = vm.rowData.COMBINATION_KEY;
						/*returnObj["leid"] = vm.rowData.LEID;
						returnObj["tax_code"] = vm.rowData.TAX_CODE;
						returnObj["reporting_period"] = vm.rowData.REPORTING_PERIOD;
						returnObj["me_code"] = vm.rowData.ME_CODE;*/
					}

					details.push(returnObj);
					var message = "QBAI has been successfully saved/updated";
					if (isError == false) {
						sendDetails(returnObj, details, message, otherInfo);
					}
				}
				function sendDetails(returnObj, details, message, otherInfo) {
					qbaiFactory.save(returnObj, details, otherInfo).then(
							function(result) {
								vm.isSaveClicked = false;
								if (result.errorMessage
										&& result.errorMessage !== 'null') {

									AlertService.add("error",
											result.errorMessage, 4000);
									vm.isSaveClicked = false;

								} else {
									AlertService.add("success", message, 4000);
									$uibModalInstance.dismiss('cancel');
									var args = {};
									$rootScope.$emit('gridUpdate', args);
								}
							});

				}
			}
			return controllers;
		});