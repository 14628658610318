define([
	'angular'

], function () {
	'use strict';

	var services = angular.module('app.schdmSelector', [])
		.factory("SchdmtagFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','$injector','AlertService','GlobalService','DataFiltersFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,$injector,AlertService,GlobalService,DataFiltersFactory,JsonObjectFactory,workspaceFactory,GENERAL_CONFIG) {
		var factory = {};
		var service = $injector.get('JsonObjectFactory');
		factory.autoCallToAction = function(_callName, _data){
			var checked = '';
			if (_data.rowData.SCHDM_SELECTOR === 1) {
				checked = true;
			}else {
				checked = false;
			}
			 
			var params = { 
				"scenario" :GlobalService.globalParams.scenario,
				"jcd_key" : GlobalService.globalParams.jcd_key,
				"tax_year" : _data.rowData.TAX_YEAR,
				"combination_key" : _data.rowData.COMBINATION_KEY,
				"checkedFlag" : checked
			}
			 
			var jsonObj = JSON.stringify(_data.rowData);
			params =  _.extend({jsonObj:jsonObj}, params);
			 
//			var promise= JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=8c5xg1', params).then(function(data) {
//			if (data.callSuccess === "1") {
//				var args_update = {
//					updatekey: _data.rowData.COMBINATION_KEY,
//					gridFilter : {
//						updatekey:_data.rowData.COMBINATION_KEY,
//					}
//				};
//				$rootScope.$emit('gridUpdate', args_update);
//			}else {
//				if(data.errorMessage){
//					AlertService.add("error",data.errorMessage, 3000);
//				}else{
//					AlertService.add("error","Data retrieval failed", 3000);
//				}
//			}
//		}); 
//			return promise;
			
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=8c5xg1";
	           
	          
          	  service.saveJSON(url , params).then(function(data){
		
							if (data.callSuccess === "1") {

								AlertService
										.add(
												"success",
												"Data has been Save",
												3000);

								var args_update = {
										updatekey: _data.rowData.COMBINATION_KEY,
										gridFilter : {
											updatekey:_data.rowData.COMBINATION_KEY,
										}
									};
								$rootScope
										.$emit(
												'gridUpdate',
												args_update);
							} else {
								if (data.errorMessage
										&& data.errorMessage.length > 0
										&& data.errorMessage !== 'null') {
									AlertService.add("error",
											data.errorMessage);
								} 
							else{
								AlertService
										.add(
												"error",
												"Data retrieval failed",
												3000);
							}
							}

						});


	
		};
		return factory;
	}]);
	return services;
});