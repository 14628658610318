define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.relatedPartyPaymentCtrl',  [])
		.controller('relatedPartyPaymentCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','firRelatedPartyPaymentSaveFactory' , 'firRelatedPartyPaymentScreenFactory', '$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', relatedPartyPaymentCtrl]);

			/*.controller('relatedPartyPaymentCtrlModal', ['CommonUtilitiesFactory', '$filter','$scope','$rootScope',  'ModalFactory', 'AlertService', '$timeout',
				'GlobalService','firRelatedPartyPaymentSaveFactory', 'JsonObjectFactory','GENERAL_CONFIG', '$uibModalInstance',
				'rowData', 'colData', 'gridData',  relatedPartyPaymentCtrlModal]);*/


	function relatedPartyPaymentCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,firRelatedPartyPaymentSaveFactory, firRelatedPartyPaymentScreenFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {

		/*relatedPartyPaymentCtrlModal(CommonUtilitiesFactory, $filter,$scope,$rootScope,ModalFactory, AlertService, $timeout,
				GlobalService,firRelatedPartyPaymentSaveFactory,$uibModalInstance, JsonObjectFactory,GENERAL_CONFIG, null,
				null, {}, {},this);*/


	/*function relatedPartyPaymentCtrlModal(CommonUtilitiesFactory, $filter,$scope,$rootScope,ModalFactory, AlertService, $timeout,
			GlobalService,firRelatedPartyPaymentSaveFactory,$uibModalInstance, JsonObjectFactory, GENERAL_CONFIG, rowData, colData, gridData){*/

		var vm = this;
            	//var vm = this||thisObj;
				vm.rowData = rowData ||$scope.$parent.rowData;
				vm.adjAmts = [];
                vm.modalTitle = "Select Payment Type";
              vm.selectedPaymentType;

//                function getPaymentType(){
//                vm.paymentType = CommonUtilitiesFactory.getPaymentType();
//                }
              function getPaymentType(){
              	firRelatedPartyPaymentScreenFactory.getPaymentType(vm.rowData).then(function(data){
              		console.log("The Payment Types from DB was :::::::", data.jsonObject);
              		vm.paymentType = data.jsonObject;
              	});
              }
                getPaymentType();

                vm.savePartyPaymentType=function(){
                	firRelatedPartyPaymentSaveFactory.savePaymentType(vm.rowData,vm.selectedPaymentType).then(
    						function(data) {
    							if (data.callSuccess === "1") {
    								AlertService.add("success", "payment Type saved successfully");
    								var args = {rp_seq_id: vm.rowData.rp_seq_id};
    								$rootScope.$emit('gridUpdate', args);
    			    				$uibModalInstance.dismiss('cancel');
    							} else {
									if(result.errorMessage){
										AlertService.add("error", response.data.errorMessage,4000);
									}else{
										AlertService.add("error", vm.saveError,4000);
									}


    							}
    							//vm.loading = false;
    							$scope.crudLoading = false;
    						}
    					);
                }


             /*     var vm = this||thisObj;
				vm.rowData = rowData ||$scope.$parent.rowData;
				vm.adjAmts = [];
                vm.modalTitle = "Select New Payment Record";
              vm.selectedNewpaymentRecord;

                function getGoldRecipient(){
                vm.paymentType = CommonUtilitiesFactory.getNewPaymentRecord();
                }
                getGoldRecipient();

                vm.savePartyPaymentType=function(){
                	firRelatedPartyPaymentSaveFactory.getGoldRecipient(vm.rowData,vm.selectedGoldrecipient).then(
    						function(data) {
    							if (data.callSuccess === "1") {
    								AlertService.add("success", "payment Type saved successfully");
    								var args = {rp_seq_id: vm.rowData.rp_seq_id};
    								$rootScope.$emit('gridUpdate', args);
    			    				$uibModalInstance.dismiss('cancel');
    							} else {
    								AlertService.add("error", vm.saveError);
    							}
    							//vm.loading = false;
    							$scope.crudLoading = false;
    						}
    					);
                }
                var vm = this||thisObj;
				vm.rowData = rowData ||$scope.$parent.rowData;
				vm.adjAmts = [];
                vm.modalTitle = "Select New Payment Record";
              vm.selectedNewpaymentRecord;

                function getGoldRecipient(){
                vm.paymentType = CommonUtilitiesFactory.getNewPaymentRecord();
                }
                getFIRRecipient();

                vm.savePartyPaymentType=function(){
                	firRelatedPartyPaymentSaveFactory.getFIRRecipient(vm.rowData,vm.selectedFIRRecipient).then(
    						function(data) {
    							if (data.callSuccess === "1") {
    								AlertService.add("success", "payment Type saved successfully");
    								var args = {rp_seq_id: vm.rowData.rp_seq_id};
    								$rootScope.$emit('gridUpdate', args);
    			    				$uibModalInstance.dismiss('cancel');
    							} else {
    								AlertService.add("error", vm.saveError);
    							}
    							//vm.loading = false;
    							$scope.crudLoading = false;
    						}
    					);
                }*/

					vm.cancel = function () {
    				$scope.crudLoading = false;
    				console.log("in cancel");
    				$uibModalInstance.dismiss('cancel');
                }








	}
  });