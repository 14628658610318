define(
		[ 'angular',

		'./QarTaxes2018Service',

		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.QarTaxes2018Ctrl', [ 'app.QarTaxes2018Service' ])
					

					.controller(
							'qarTaxes2018',
							[ '$rootScope', '$scope', '$state', '$log',
									'JsonObjectFactory', 'AlertService',
									'$timeout', '$uibModal',
									'$uibModalInstance', 'rowData', 'gridData',
									'GENERAL_CONFIG', 'QarTaxes2018Factory',
									'$http', 'USER_SETTINGS', 'GlobalService',
									'workspaceFactory', '$filter', qarTaxes2018 ]);
			// Directives based on http://stackoverflow.com/a/24271309/3894163
			function qarTaxes2018($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, $timeout, $uibModal,
					$uibModalInstance, rowData, gridData, GENERAL_CONFIG,
					QarTaxes2018Factory, $http, USER_SETTINGS, GlobalService,
					workspaceFactory, $filter) {

				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.rowData = rowData;
				vm.isSaveClicked = false;
				var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
						.getFilterParams()
						: {};
				vm.curentyear = filterParams.tax_year; // GlobalService.globalParams.tax_year;
				vm.scenario = filterParams.scenario;
				vm.jcd_key = GlobalService.globalParams.jcd_key;
				vm.filterTaxYear = filterParams.tax_year;
				vm.basketDetails = [];
				vm.generatedLeidDropDown = [];
				vm.countryData = [];
				vm.basket = '';
				vm.genLeid = vm.rowData ? vm.rowData.BRTAX_LEID:null;
				vm.selectedCountryDesc = '';
				
				vm.autoPreloadInfo = function() {
					vm.getBasketDetails();
					vm.getGeneratedLeidDropDown(vm.rowData );
					vm.getCountryData();
				}

				vm.getBasketDetails = function() {
					var params = {
						"action_code" : 'dbty56',
						"p_scenario" : vm.scenario,
						"p_jcd_key" : vm.jcd_key,
						"cc_key" : vm.rowData.CODE_COMBINATION,
					};
					JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.basketDetails = _
													.filter(
															data.jsonObject,
															function(p) {
																return _
																		.includes(
																				[ 'SOURCE_CODE' ],
																				p.CODE_GROUP);
															});
											vm.basket = vm.rowData.BASKET;
											
										}
									});
				}
				vm.getGeneratedLeidDropDown = function(selectedRowData) {	
					var params = {
									"action_code" : 'yxpce4',
									"p_tax_year" : vm.filterTaxYear, 
									"p_scenario" : vm.scenario,
									"ho_leid" : selectedRowData.HO_LEID,
									"ho_cdr_no" : selectedRowData.HO_CDR_NO,
									"ho_reporting_pd" : selectedRowData.HO_REPORTING_PERIOD, 
								};					 
					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {										
					      vm.generatedLeidDropDown = data.jsonObject;						      
					});						
				};

				vm.getCountryData = function() {		
					var params = {"action_code" : 'e83e1x', "tax_year" : vm.filterTaxYear };					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						
						vm.countryData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);  
	                	});
						vm.selectedCountryDesc =  vm.rowData.COUNTRY;
					});
				}
				vm.reset = function() {
					vm.autoPreloadInfo();
				}
				
				vm.autoPreloadInfo();
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				}
				
				

				

				function sendDetails(returnObj, transactionDetails, message,
						otherInfo) {
					QarTaxes2018Factory.save(returnObj, transactionDetails,
							otherInfo).then(
							function(result) {
								vm.isSaveClicked = false;
								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									var startIndex = result.errorMessage
											.indexOf("(") + 1;
									var lastIndex = result.errorMessage
											.indexOf(")");
									var errorKey = result.errorMessage
											.substring(startIndex, lastIndex);
									AlertService.add("error",
											result.errorMessage, 4000);
									vm.isSaveClicked = false;
								} else {
									AlertService.add("success", message, 4000);
									$uibModalInstance.dismiss('cancel');
									var args = {};
									/*if (returnObj.object_id) {
			                            args = {
			                            	object_id:returnObj.object_id,	
		                                    scenario:otherInfo.scenario,
		                                    tax_year : vm.filterTaxYear
			                            };
			                        }*/
									$rootScope.$emit('gridUpdate', args);
								}
							});

				}
				vm.save = function() {
					var returnObj = {}, transactionDetails = [], message = "", otherInfo = {}, errorInfo = "";
					// Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					returnObj["HO_LEID"] = vm.rowData.HO_LEID;
					returnObj["LEID"] = vm.rowData.LEID;
					returnObj["CDR_NO"] = vm.rowData.CDR_NO;
					returnObj["REPORTING_PERIOD"] = vm.rowData.REPORTING_PERIOD;
					returnObj["ME_CODE"] = vm.rowData.ME_CODE;
					returnObj["BRTAX_LEID"] =vm.genLeid;
					returnObj["BASKET"] = vm.basket;
					returnObj["COUNTRY"] = vm.selectedCountryDesc;
					returnObj["BASKET_KEY"] = _.find(vm.basketDetails, ['CODE_DESCRIPTION', vm.basket]).CODE_KEY;
					returnObj["COUNTRY_KEY"] = _.find(vm.countryData, ['CODE_DISP_DESC', vm.selectedCountryDesc]).CODE_KEY;
							returnObj["ADJ_KEY"] =  vm.rowData.ADJ_KEY; 
							returnObj["object_id"] = vm.rowData.object_id;
							transactionDetails.push(returnObj);

					otherInfo['sso_id'] = vm.userSettings.user.sso_id;
					otherInfo['SCREEN_KEY'] = GlobalService.globalParams.screen_key;
					otherInfo['tax_year'] = vm.filterTaxYear;
					otherInfo['scenario'] = vm.scenario;
					otherInfo['jcd_key'] = GlobalService.globalParams.jcd_key;

					console.log(returnObj,returnObj["BASKET_KEY"],returnObj["COUNTRY_KEY"],"return")
					message = "QAR Adjustment has been successfully saved/updated";
					if (vm.isSaveClicked)						
						sendDetails(returnObj, transactionDetails, message,			otherInfo);
				}

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				

			}

			return controllers;

		});