define([
    'angular',
    './hoveringDeficitCalculationCtrl',
    './hoveringDeficitCalculationService', 
], function () {
    'use strict';
    return angular.module('app.FIR_Hovering_Deficit', ['app.hoveringDeficitCalculationCtrl', 'app.hoveringDeficitCalculationService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('hovering-deficit-calculation', {
                url: '/hovering-deficit-calculation',
                templateUrl: 'app/components/FIR_Hovering_Deficit/hoveringDeficitCalculation.html',
                backdrop: 'static',
                data:{},
                access:""
            })
    }])

});