define([ 'angular'

], function() {
	'use strict';

	var controllers = angular.module('app.DefaultsFTCController', [])
			.controller(
					'defaultsFTCController',
					[ '$scope', '$rootScope', '$state', '$http',
							'ModalFactory', 'AlertService',
							'JsonObjectFactory', 'workspaceFactory','GENERAL_CONFIG',
							defaultsFTCController ])

	function defaultsFTCController($scope, $rootScope, $state, $http,
			ModalFactory, AlertService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG) {
		var vm = this;
		vm.test = "defaultsFTCController";
		vm.branchCountrys = [];
		vm.sicCodes = [];
		vm.sicCodeData = [];
		vm.getBranchCountry = function() {
			vm.branchCountrys = [];
			var params = {
				"action_code" : 'p6lqtn',
			};
			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
					function(data) {
						console.log(data, "getBranchCountry");
						vm.branchCountrys = [ 'india', 'china' ];
					});
		}
		vm.getSicCodes = function() {
			vm.sicCodes = [];
			var params = {
				"action_code" : 'f01062',
			};
			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
					function(data) {
						vm.sicCodeData = data.jsonObject;
						vm.sicCodeData.map(function(sitCodeObj) {
							sitCodeObj['SIC_CODE_DESC'] = sitCodeObj.SIC_CODE + "-"
									+ sitCodeObj.SIC_DESC
						});
						console.log(vm.sicCodeData, "getSicCodes");
						// vm.branchCountrys = ['india','china'];
					});
		}

		vm.getBranchCountry();
		vm.getSicCodes();
	}

	return controllers;
});