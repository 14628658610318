define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.ProjectController', [])
                             .controller('ProjCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'ProjectFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', ProjCtrl])
                             .controller('ProjStCtrl', ['$rootScope', '$timeout', '$uibModalInstance', 'ProjectStepFactory',
                                 'workspaceFactory', 'gridData', 'AlertService', ProjStCtrl]);

    function ProjCtrl($rootScope, $timeout, $uibModalInstance, ProjectFactory, workspaceFactory, gridData, AlertService) {
        var vm = this;
        vm.delete = function () {
            var rows = gridData.map(({data}) => data);
            ProjectFactory.deleteProjectData(rows).then((data) => {
                if (data.callSuccess === '1') {
                    $timeout(function () {
                        var gridFilters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                        var args = {};
                        var filterParams = { gridFilter: gridFilters };
                        args = _.merge({}, args, filterParams);
                        $rootScope.$emit('gridUpdate', args);
                    });
                    AlertService.add('success', 'Data deleted successfully!', 4000);
                } else {
                    AlertService.add("error", data.errorMessage, 4000);
                   // AlertService.add('error', 'Something went wrong, please try again!');
                }
            }).catch(() => {
                AlertService.add('error', 'Something went wrong, please try again!');
            }).finally(() => {
                $uibModalInstance.dismiss('cancel');
            });
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function ProjStCtrl($rootScope, $timeout, $uibModalInstance, ProjectStepFactory, workspaceFactory, gridData, AlertService) {
        var vm = this;
        vm.delete = function () {
            var rows = gridData.map(({data}) => data);
            ProjectStepFactory.deleteProjectStepData(rows).then((data) => {
                if (data.callSuccess === '1') {
                    $timeout(function () {
                        var tableFilters = workspaceFactory.activeScreen.data.tableState.colSearch;
                        var filters = [];
                        for (var i = 0; i < tableFilters.length; i++) {
                            filters.push({});
                            filters[i][tableFilters[i].predicate] = tableFilters[i].value;
                        }
                        var gridFilters = filters.length > 0 && Object.assign(...filters);
                        var filterParams = { gridFilter: gridFilters };
                        var args = {};
                        args = _.merge({}, args, filterParams);
                        $rootScope.$emit('gridUpdate', args);
                    });
                    AlertService.add('success', 'Data deleted successfully!', 4000);
                } else {
                   AlertService.add("error", data.errorMessage, 4000);
                   // AlertService.add('error', 'Something went wrong, please try again!');
                }
            }).catch(() => {
                AlertService.add('error', 'Something went wrong, please try again!');
            }).finally(() => {
                $uibModalInstance.dismiss('cancel');
            });
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    return controllers;
});
