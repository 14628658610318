/**
 * Created by 400371031 on 1/20/2021.
 */

define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.relatedPartyclassificationService',[])

        .factory('relatedPartyClassificationFactory',['$q','$http','$timeout','$rootScope','$log','DataFiltersFactory','workspaceFactory','JsonObjectFactory','USER_SETTINGS','AlertService','GlobalService','GENERAL_CONFIG',
        function relatedPartyClassificationFactory($q,$http,$timeout,$rootScope,$log,DataFiltersFactory,workspaceFactory,JsonObjectFactory,USER_SETTINGS,AlertService,GlobalService,GENERAL_CONFIG) {
            var factory = {};
            var URLS = {
					
					SAVE_CATEGORY : GENERAL_CONFIG.base_url
							+ "/saveJsonObject?action_code=ybb4n2",
					SAVE_MODAL : GENERAL_CONFIG.base_url
							+ "/saveJsonObject?action_code=7fgs26"
				}
            var userSettings = USER_SETTINGS;
			var logged_in_user = userSettings.user.sso_id;

            factory.savecategorymodal = function(
					_callName, _data) {

				var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
						.getFilterParams()
						: {};
				var jsonObj = JSON.stringify(_data);
				var jsonSettings = JSON
						.stringify(_callName);

				var params = filterParams;
				params = _.merge({},
						GlobalService.globalParams,
						params);
				params = _.extend({
					jsonObj : jsonObj
				}, params);
				params = _.extend({
					jsonSettings : jsonSettings
				}, params);
				// params =
				// _.extend({jsonSettings:jsonSettings},
				// _data);
				params.process_name = "INSERT_CATEGORY_DATA";
				params.sso_id = logged_in_user;
				console.log(params);
				var promise = $http({
					method : "post",
					url : URLS.SAVE_CATEGORY,
					data : params
				}).then(function(response) {
					var status = response.status;
					return response.data;
				});

				return promise;

			}
            factory.saveupdatecategorymodal = function(
					_callName, _data) {

				var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
						.getFilterParams()
						: {};
				var jsonObj = JSON.stringify(_data);
				var jsonSettings = JSON
						.stringify(_callName);

				var params = filterParams;
				params = _.merge({},
						GlobalService.globalParams,
						params);
				params = _.extend({
					jsonObj : jsonObj
				}, params);
				params = _.extend({
					jsonSettings : jsonSettings
				}, params);
				// params =
				// _.extend({jsonSettings:jsonSettings},
				// _data);
				params.process_name = "UPDATE_CATEGORY_DATA";
				params.sso_id = logged_in_user;
				console.log(params);
				var promise = $http({
					method : "post",
					url : URLS.SAVE_MODAL,
					data : params
				}).then(function(response) {
					var status = response.status;
					return response.data;
				});

				return promise;

			}
            
	     
	      
	        
			
			


            return factory;

        }]);


    return services;


});
