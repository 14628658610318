define([
   'angular'

], function () {
   'use strict';

    var services =  angular.module('app.Form5471-G1InfoService',[])
        .factory("form5471ScheduleG1GroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
            ,'$q', '$http',
            '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'

            ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                ,$q, $http,$stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
                var factory = {};
                var returnObj = {};
                var form5471ScheduleG1GroupObjFactory ={};
                form5471ScheduleG1GroupObjFactory.url ={};
                form5471ScheduleG1GroupObjFactory.url.save_Form_5471_Schedule_G = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vlm7h1";

                form5471ScheduleG1GroupObjFactory.saveForm5471ScheduleG1 = function(_data,Details){

                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var jsonSettings =  JSON.stringify(_data);
                    var jsonObj = JSON.stringify(Details);
                    //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    //               params =  _.extend({jsonSettings:jsonSettings}, _data);
                    params.process_name =  "Form_5471_Schedule_G1_Save";
                    var promise = $http({
                        method: "post",
                        url: form5471ScheduleG1GroupObjFactory.url.save_Form_5471_Schedule_G,
                        data: params
                    }).then(function (response) {
                        var status = response.status;
                        return response.data;
                    });

                    return promise;
                };
                
                return form5471ScheduleG1GroupObjFactory;
            }]);

   return services;
});