define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.ExpConsolCtrl', [])
					.controller('expConsolCtrl', ['$scope','$rootScope','$state', '$http', 'gridData', '$uibModalInstance', 'ModalFactory','GridFactory', 'AlertService','GlobalService', 
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'ExpConsolFactory', expConsolCtrl])
							    
			function expConsolCtrl($scope, $rootScope, $state, $http, gridData, $uibModalInstance, ModalFactory, GridFactory, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, ExpConsolFactory) {
				var vm = this;	
				//Tax Year Dropdown options
				vm.filteredTaxYearData = [];
				//Filing Group Dropdown options
				vm.filingGroupData;
				//Derived FDII Data
				vm.fdiiDerivedData = [];
				//GIE_NAME drop down
				vm.gieNamesDropdown = [];
				//To get the Grid Filter Params 
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.scenario = GlobalService.globalParams.scenario;   //filterParams.scenario;   
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				
				//Disable the Delete Button
				vm.showDelete = true;
				//Label names for the UI textboxes
				vm.label1; vm.label2;
				
				//cancel the modal
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"p_scenario" : vm.scenario,
						"p_jcd_key" :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				//reset the form
				vm.reset = function(){		
					//console.log(" Reset the Modal ");
					if(vm.operationType == 'I'){
						vm.selectedAdjKey = '';
						vm.selectedFilingGroup = '';
						vm.selectedGIECodeKey = '';
						vm.fdiiAmt = '';
						vm.giltiAmt = '';
					}else{
						vm.loadSavedExpenseConsole();
					}
				}
				
				//Get the Data from FTC Code Mast 
				vm.getFtcCodeMastForDropDown = function() {		
					var params = {"action_code" : 'lfmzzq',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.taxYearsData = _.filter(data.jsonObject, function(p){
	                		 return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});
						vm.fdiiCodeData = _.filter(data.jsonObject, function(p){
	                		 return _.includes(['FDII_CODE'], p.CODE_GROUP);  
	                	});
						vm.fdiiDerivedData = _.filter(vm.fdiiCodeData, function(p){
	                		 return _.includes(['GILTI','FGN Derived DEI'], p.CODE_SHORT_DESC);  
	                	});						
					});
					
					$scope.$watch(function() { return vm.taxYearsData },   function() {
						if(vm.taxYearsData!=undefined || vm.taxYearsData!=null){
							for(var indx in vm.taxYearsData){
								if(vm.taxYearsData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearsData[indx].CODE_DISP_DESC <= (vm.filterTaxYear) && vm.taxYearsData[indx].CODE_DISP_DESC >= (vm.filterTaxYear-11))){
									vm.filteredTaxYearData.push(vm.taxYearsData[indx]);							
								}
							}
						}
					});
				};
				vm.getFtcCodeMastForDropDown();	
				
				//Get the GIE Names Dropdown
				vm.getGieNamesDropdown = function(){					
					var params = {"action_code" : '9qgldf',};		    			
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.gieNamesDropdown = data.jsonObject;		
						//console.log("gieNamesDropdown : ", vm.gieNamesDropdown );
					});
				}
				vm.getGieNamesDropdown();
				
				//Get Filing groups Drop Down	
				vm.getFilingGroups = function() {
		    		var params = {"action_code" : 'gk7crv',};		    			
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.filingGroupData = data.jsonObject;		
						//console.log("filingGroupData : ", vm.filingGroupData);
					});
				}
				vm.getFilingGroups();
				
				//Get the Saved Exp Consol Configurations	
				vm.getSavedExpConsolConf = function() {
		    		var params = {"action_code" : 'qnr7oy', "scenario" : vm.scenario};		    			
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.existingExpConsolData = data.jsonObject;		
						console.log("existingExpConsolData : ", vm.existingExpConsolData);
					});
				}
				
				//Get the GIE_NAME for the given GIE_KEY 
				function getGieName(gieKey) {
					var selectedGieName;
					for(var indx in vm.gieNamesDropdown){								
						if(vm.gieNamesDropdown[indx].GIE_KEY == gieKey){
							selectedGieName = ''+vm.gieNamesDropdown[indx].GIE_NAME;							
						}
					}
					return selectedGieName;
				};
				
				//On change of a GIE dropdown set the textbox labels
				vm.gieNameChange = function(){
					if(vm.selectedGIECodeKey){
						if(getGieName(vm.selectedGIECodeKey) == 'ASSET'){
							vm.label1 = 'FDII';
							vm.label2 = 'Section 250';
						}else{
							vm.label1 = 'Foreign Derived DEI';
							vm.label2 = 'GILTI';
						}
					}
				}
				
				//on load of modal, check for existing saved data
				vm.loadSavedExpenseConsole =  function() {
					//When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
					if (gridData != null) {					
						var tempGridData = _.clone(gridData), groups = Object
								.create(null), result, finalKeyCheck = 0;					
					
						vm.gridDataMultipleSelect = _.clone(tempGridData);					
										
						if (vm.gridDataMultipleSelect != undefined) {						
							//console.log("vm.gridDataMultipleSelect:-: ", vm.gridDataMultipleSelect.rowData);
							if (vm.gridDataMultipleSelect.rowData != undefined) {	
								var selectedRow = _.clone(vm.gridDataMultipleSelect.rowData);
								//Set the update params
								vm.operationType = 'U';
								vm.selectedTaxYear = ''+selectedRow["TAX_YEAR"];
								vm.selectedAdjKey = ''+selectedRow["ADJ_KEY"];
								vm.selectedFilingGroup = ''+selectedRow["FILING_GRP_KEY"];
								vm.selectedGIECodeKey = ''+selectedRow["GIE_KEY"];
								vm.fdiiAmt = parseInt(selectedRow["FGN_Derived_DEI_0"]);
								vm.giltiAmt = parseInt(selectedRow["GILTI_0"]);
								vm.showDelete = true;
							}else{							
								//Set the insert params
								vm.operationType = 'I';
								vm.selectedTaxYear = ''+vm.filterTaxYear;
								vm.selectedAdjKey = null;
								vm.showDelete = false;
							}
							vm.getSavedExpConsolConf();
						}
					}
					vm.gieNameChange(); 
				}
				vm.loadSavedExpenseConsole();
				
				//delete the form
				vm.deleteExpAnA = function(){		
					//console.log(" Delete the Exp A&A Conf ");
					
					var clobSettingsObj = {};
					clobSettingsObj['sso_id'] = vm.logged_in_user;
					clobSettingsObj['scenario'] = vm.scenario;
					console.log("returnClobSettingsObj-----" + clobSettingsObj);
					
					var returnObj = {};
					returnObj["TAX_YEAR"] = vm.selectedTaxYear;
					returnObj['ADJ_KEY'] = vm.selectedAdjKey == null? 0: vm.selectedAdjKey;
					returnObj['FILING_GRP_KEY'] = vm.selectedFilingGroup;
					returnObj['GIE_KEY'] = vm.selectedGIECodeKey;
					returnObj[_.find(vm.fdiiDerivedData, ['CODE_SHORT_DESC', 'FGN Derived DEI']).CODE_KEY +'_0'] = 0;
					returnObj[_.find(vm.fdiiDerivedData, ['CODE_SHORT_DESC', 'GILTI']).CODE_KEY +'_0'] = 0;
					returnObj["OPERATION_TYPE"] = 'D';	
					
					var clobDataDetails = [];
					clobDataDetails.push(returnObj);
					console.log("saveDataDetails  -- ", clobDataDetails);
					
					var message = "Expense A&A Configuration Amount(s) have been deleted.";
					//send the Data to Delete					
					sendDeleteDetails(clobSettingsObj, clobDataDetails, message);
				}
				
				//Send Details to the Service Layer for persisting the data
				function sendDeleteDetails(clobSettingsObj, clobDataDetails, message) {					
					ExpConsolFactory.savePivotedData(clobSettingsObj, clobDataDetails).then(function(result) {
						if (result.errorMessage && result.errorMessage !== 'null') {
							AlertService.add("error", result.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							$rootScope.$broadcast('gridRefresh', true);
							$uibModalInstance.dismiss('cancel');
						};
					});
				}
				
				//Save the Investment Amt
				vm.save = function(){	
					vm.isSaveClicked = true;		
					console.log(" vm.fdiiAmt ", vm.fdiiAmt);
					if(vm.selectedTaxYear == undefined  || vm.selectedTaxYear == null  || vm.selectedTaxYear == ''
						|| vm.selectedFilingGroup == undefined  || vm.selectedFilingGroup == null  || vm.selectedFilingGroup == ''
							|| vm.selectedGIECodeKey == undefined  || vm.selectedGIECodeKey == null  || vm.selectedGIECodeKey == ''
								|| vm.fdiiAmt == undefined  || vm.fdiiAmt == null  || vm.fdiiAmt == '' || vm.fdiiAmt == Number.isNaN || isNaN(vm.fdiiAmt)
									|| vm.giltiAmt == undefined  || vm.giltiAmt == null  || vm.giltiAmt == '' || vm.giltiAmt == Number.isNaN || isNaN(vm.giltiAmt) ){
						AlertService.add("error", "Please select/enter all the required fields.",4000);
						vm.isSaveClicked = false;
						return;
					}
					if(true){   //if(vm.operationType == 'I'){						
						var existsSuchRecords = _.find(vm.existingExpConsolData, function(o) {								
							return (o.TAX_YEAR == vm.selectedTaxYear && o.FILING_GRP_KEY == vm.selectedFilingGroup 
										&& o.GIE_KEY == vm.selectedGIECodeKey && o.ADJ_KEY !=vm.selectedAdjKey ); 
						});						
						if(existsSuchRecords){
							AlertService.add("error", "The Filing Group Amounts are already configured for this selection.",4000);
							vm.isSaveClicked = false;
							return;
						}
					}
					
					var clobSettingsObj = {};
					clobSettingsObj['sso_id'] = vm.logged_in_user;
					clobSettingsObj['scenario'] = vm.scenario;
					console.log("returnClobSettingsObj-----" + clobSettingsObj);
					
					var returnObj = {};
					returnObj["TAX_YEAR"] = vm.selectedTaxYear;
					returnObj['scenario'] = vm.scenario;
					returnObj['ADJ_KEY'] = vm.selectedAdjKey == null? 0: vm.selectedAdjKey;
					returnObj['FILING_GRP_KEY'] = vm.selectedFilingGroup;
					returnObj['GIE_KEY'] = vm.selectedGIECodeKey;
					returnObj[_.find(vm.fdiiDerivedData, ['CODE_SHORT_DESC', 'FGN Derived DEI']).CODE_KEY +'_0'] = vm.fdiiAmt;
					returnObj[_.find(vm.fdiiDerivedData, ['CODE_SHORT_DESC', 'GILTI']).CODE_KEY +'_0'] = vm.giltiAmt;
					returnObj["OPERATION_TYPE"] = vm.operationType;	
					
					var clobDataDetails = [];
					clobDataDetails.push(returnObj);
					console.log("saveDataDetails  -- ", clobDataDetails);
					
					var message = "Expense A&A Configuration Amount(s) have been saved/updated";
					//send to save the Data					
					sendSaveDetails(clobSettingsObj, clobDataDetails, message);
				}
				
				//Send Details to the Service Layer for persisting the data
				function sendSaveDetails(clobSettingsObj, clobDataDetails, message) {					
					ExpConsolFactory.savePivotedData(clobSettingsObj, clobDataDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									/*me_code: returnClobSettingsObj.me_code,
	                                gridFilter: {
	                                	me_code: returnClobSettingsObj.me_code
	                                }*/
	                            };
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
			}		
			
			return controllers;
		});