define([
    'angular',
    './diagnosticDashboardCtrl',
    './diagnosticDashboardService'
], function () {
    'use strict';
    return angular.module('app.FIR_diagnostic_center', ['app.diagnosticDashboardCtrl', 'app.diagnosticDashboardService'] )
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('fir-diagnostics-dashboard', {
                url: '/fir-diagnostics-dashboard',
                templateUrl: 'app/components/FIR_diagnostic_center/index.html',
                controller: 'diagnosticCenterController as ctrl'
            })
    }])

});