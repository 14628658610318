define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.signoffUserService',[])
        .factory("AnalyzeTaxesServiceFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
	           
        	 var returnObj = {};
	           var AnalyzeTaxesServiceFactory ={};
	           AnalyzeTaxesServiceFactory.url ={};

	           
        	AnalyzeTaxesServiceFactory.url.saveCurrentSignoff = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=84f3zb";
        	
	          
	           
	           AnalyzeTaxesServiceFactory.saveCurrentSignoff = function(_callName ,_data){
	               console.log("data", _data);
	               console.log("callname",_callName);
                   var rowData = _data.rowData;
	               //$rootScope.$broadcast('gridRefresh', false); 
	               returnObj.combination_key = _data.rowData.COMBINATION_KEY;

	               var service = $injector.get('JsonObjectFactory');
	               var defer = $q.defer(); 
	              
	               var jsonObj = [{
	            	 combination_key:_data.rowData.COMBINATION_KEY,
	            	 chart_key:_data.rowData.CHART_KEY,
	                trans_header_key:_data.rowData.SIGNOFF_TRANS_HEADER_KEY === undefined ? 0 : _data.rowData.SIGNOFF_TRANS_HEADER_KEY,
	           		signoff_y_n : _data.rowData.SIGNOFF_Y_N,
	           		issue_key: _data.rowData.ISSUE_KEY
	   			}];
	           	console.log(jsonObj);
	           	
	            var jsonSettings = [{
	            	 combination_key:_data.rowData.COMBINATION_KEY,
	                tax_year:_data.rowData.TAX_YEAR
	   			}];
	          
	           	
	               
	               var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var params = _.merge({}, GlobalService.globalParams, filterParams);
	               var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
	               var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
	   	            	
	   	            	 /*var promise = $http({
	   	          			 method: "POST",
	   	          			 url: AnalyzeTaxesServiceFactory.url.saveCurrentSignoff,
	   	          			 data: params
	   	          		}). then(function (response){
	   	          				console.log(response.data);
	   	          				if(response.data.callSuccess === "1"){
	   	                            AlertService.add("success", "Data saved Successfully.",3000);
	   	                            var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
	   	                                         $rootScope.$emit('gridUpdate', args);
	   	                        }else{
	   	                            //AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                                    rowData.SIGNOFF_Y_N  = null;
                                    AlertService.add("error", response.data.errorMessage, 4000);
                                    $rootScope.$broadcast('gridRefresh', true);
	   	                        }
	   	          			
	   	          				return response.data;
	   	          				})
	   	          			
	   	          			return promise;		      */    
	               
	               service.saveJSON(AnalyzeTaxesServiceFactory.url.saveCurrentSignoff,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   AlertService.add("success", "Data saved Successfully", 3000);
		        		   var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
                              $rootScope.$emit('gridUpdate', args);
	                        $rootScope.$emit('gridUpdate', args);
							defer.resolve(data);
						}else {
							rowData.SIGNOFF_Y_N  = null;
	                     AlertService.add("error", data.errorMessage, 3000);
	                     defer.reject(data);
	                 }},function(data){
	                 defer.reject(data);
	             });
	             return defer.promise;			   
	            
	            };
	           
	            
	   return AnalyzeTaxesServiceFactory;
	          }]);

	      
	      return services;
	   });