const { ForceDirectedNetwork } = require("gojs");

define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.CalcsFilingGroupStatusController',[])
        .directive('captureCustomEvents', ['$document', '$timeout', '$window', 'workspaceFactory', function ($document, $timeout, $window, workspaceFactory) {
            return {
            restrict: 'A',
            link: function (scope, $element, attr) {
                $element.bind('load-screen', function(event) {
                    let screen = event.originalEvent.detail;
                    workspaceFactory.loadLink("link", "{}",screen.activity_key+'',screen.screen_key+'');
                    event.stopPropagation();
                });
            }
            };
        }])
        .controller('CalcsFilingGroupStatusController', ['USER_MENU','$rootScope','$scope', '$http', 'GlobalService', 'ModalFactory', 'AlertService','JsonObjectFactory','$timeout','ReportableTransactionFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory', 'CalcsFilingGroupStatusFactory', 'TAX_YEARS', CalcsFilingGroupStatusController])
        function CalcsFilingGroupStatusController(USER_MENU,$rootScope, $scope, $http, GlobalService, ModalFactory, AlertService, JsonObjectFactory,$timeout,ReportableTransactionFactory,USER_SETTINGS,GENERAL_CONFIG,workspaceFactory, CalcsFilingGroupStatusFactory, TAX_YEARS){
            
            var vm =this;
            const currentUser = USER_SETTINGS.user;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
            vm.clientKey = currentUser.client_key;
            var activeScreen = workspaceFactory.activeScreen;
            var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
            vm.globalParams = JSON.stringify(tempObj);
            vm.activeScreensList = [];
            vm.listOfActiveScreens = [];
            vm.filterObjs = workspaceFactory.activeScreen.filters;

            vm.taxYearsList = [];
            vm.FromScenarioList = [];
            vm.ToScenarioList = [];
            vm.FromFilingGroupList = [];
            vm.ToFilingGroupList = [];

            vm.fromTaxYearFlag = false;
            vm.toTaxYearFlag = false;

            angular.forEach(TAX_YEARS, function(row){
                vm.taxYearsList.push(row);
            });
            var selectedTaxYearIndex = _.findIndex( vm.taxYearsList, { 'label': GlobalService.globalParams.tax_year });
            

            //Tax Years dropdown data.
            vm.selectedFromTaxYear = vm.taxYearsList[selectedTaxYearIndex];
            vm.selectedToTaxYear = vm.taxYearsList[selectedTaxYearIndex];

            vm.selectedFromScenario = [];
            vm.selectedToScenario = [];

            vm.selectedFromFilingGroup = [];
            vm.selectedToFilingGroup = [];

            // https://dev.gtw.pwc.com/gateway/gtw/loadJsonObject?action_code=7q0wiy&&jcd_key=250&scenario=40&tax_year=2020
            // https://dev.gtw.pwc.com/gateway/gtw/loadJsonObject?action_id=762&jcd_key=250&scenario=40&tax_year=2020
            
            // function fetchFromTaxYear() {
            //     vm.showJsonLoader = true;
    
            //     return CalcsFilingGroupStatusFactory.getFromTaxYear().then(function (data) {
            //         console.log(data);
            //         vm.showJsonLoader = false;
            //     });
            // }

            function fetchFromScenario(taxYear, scenario) {
                vm.showJsonLoader = true;
                var params = {
                    tax_year : taxYear,
                    scenario : scenario,
                    jcd_key : 250
                };
                return CalcsFilingGroupStatusFactory.getFromScenario(params).then(function (data) {
                    console.log(data);
                    vm.FromScenarioList = data.data.jsonObject;
                    var selectedScenarioIndex = _.findIndex( vm.FromScenarioList, { 'VALUE': parseInt(scenario) });
                    vm.selectedFromScenario = vm.FromScenarioList[selectedScenarioIndex];
                    vm.showJsonLoader = false;
                });
            }

            function fetchToScenario(taxYear, scenario) {
                vm.showJsonLoader = true;
                var params = {
                    tax_year : taxYear,
                    scenario : scenario,
                    jcd_key : 250
                };
                return CalcsFilingGroupStatusFactory.getFromScenario(params).then(function (data) {
                    console.log(data);
                    vm.ToScenarioList = data.data.jsonObject;
                    var selectedScenarioIndex = _.findIndex( vm.ToScenarioList, { 'VALUE': parseInt(scenario) });
                    vm.selectedToScenario = vm.ToScenarioList[selectedScenarioIndex];
                    vm.showJsonLoader = false;
                });
            }

            function fetchFromFilingGroup(taxYear, scenario) {
                vm.showJsonLoader = true;
                var params = {
                    tax_year : taxYear,
                    scenario : scenario,
                    jcd_key : 250
                };
                return CalcsFilingGroupStatusFactory.getFromFilingGroup(params).then(function (data) {
                    console.log(data);
                    vm.FromFilingGroupList = data.data.jsonObject;
                    vm.selectedFromFilingGroup = vm.FromFilingGroupList[0];
                    vm.fromTaxYearFlag = false;
                });
            }

            function fetchToFilingGroup(taxYear, scenario) {
                vm.showJsonLoader = true;
                var params = {
                    tax_year : taxYear,
                    scenario : scenario,
                    jcd_key : 250
                };
                return CalcsFilingGroupStatusFactory.getFromFilingGroup(params).then(function (data) {
                    console.log(data);
                    vm.ToFilingGroupList = data.data.jsonObject;
                    vm.selectedToFilingGroup = vm.ToFilingGroupList[0];
                    vm.toTaxYearFlag = false;
                });
            }

            vm.fromTaxYearChange = function(tax_year){
                vm.fromTaxYearFlag = true;
                fetchFromScenario(tax_year.value, tax_year.scenario);
                fetchFromFilingGroup(tax_year.value, tax_year.scenario);
            };

            vm.toTaxYearChange = function(tax_year){
                vm.toTaxYearFlag = true;
                fetchToScenario(tax_year.value, tax_year.scenario);
                fetchToFilingGroup(tax_year.value, tax_year.scenario);
            };

            vm.copySelectedChanges = function() {
                vm.showJsonLoader = true;
                var params = {
                    from_tax_year : vm.selectedFromTaxYear.value,
                    from_scenario : vm.selectedFromScenario.VALUE,
                    from_fg : vm.selectedFromFilingGroup.VALUE,
                    to_tax_year : vm.selectedToTaxYear.value,
                    to_scenario : vm.selectedToScenario.VALUE,
                    to_fg : vm.selectedToFilingGroup.VALUE,
                    tax_year : GlobalService.globalParams.tax_year,
                    scenario : GlobalService.globalParams.scenario,
                    jcd_key : GlobalService.globalParams.jcd_key
                };
                params = _.merge({}, tempObj, params);
                return CalcsFilingGroupStatusFactory.saveCopyChanges(params).then(function (data) {
                    console.log(data);
                    if (data.data.callSuccess == "1") {
                        AlertService.add("success", "Filing group data copied successfully.",4000);
                    } else {
                        AlertService.add("error", "Error while copying filing group data.");
                    }
                });
            }
            
            //fetchFromTaxYear();
            fetchFromScenario(vm.selectedFromTaxYear.value, vm.selectedFromTaxYear.scenario);
            fetchToScenario(vm.selectedFromTaxYear.value, vm.selectedFromTaxYear.scenario);

            fetchFromFilingGroup(vm.selectedToTaxYear.value, vm.selectedToTaxYear.scenario);
            fetchToFilingGroup(vm.selectedToTaxYear.value, vm.selectedToTaxYear.scenario);
        }
        return controllers;
    })