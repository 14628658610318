define(
    [ 'angular',
        './ftcCommentsService',
    ],
    function() {
        'use strict';

        var controllers = angular
                .module('app.ftcCommentsCtrl', ['app.ftcCommentsService'])
                .controller('ftcCommentsCtrl',
                        [ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService','workspaceFactory',
                                '$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','FTCCommentsFactory', '$http', 'USER_SETTINGS', ftcCommentsCtrl ])

        function ftcCommentsCtrl($rootScope, $scope, $state, $log,
                JsonObjectFactory, AlertService, GlobalService, workspaceFactory, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, FTCCommentsFactory,$http,USER_SETTINGS) {

            var vm = this;	
            //Show the loading Spinner
            vm.loadingData = true;		
            vm.userSettings = USER_SETTINGS;
            vm.logged_in_user = vm.userSettings.user.sso_id;
            //Selected Row on the Grid passed to Modal/Popup to display in top table
            vm.selectedGridRow = [];
            vm.rowData = {};

            //A flag to enable and disable save button
            vm.isSaveClicked = false;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};	
            //Get the Grid filter Scenario
            vm.scenario = filterParams.scenario;  	
            //Get the jcd_key from left nav
            vm.jcd_key  = GlobalService.globalParams.jcd_key;
            
            //cancel the pop-up
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
            
            //To get the Lock_Y_N flag from backend
            var lockparams = {
                    "action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
                };				
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
                vm.is_locked = data.jsonObject;				
                //To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
                $scope.$watch(function() { return vm.is_locked }, function() {
                    if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
                            vm.cancel();										
                            AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
                            return;
                    }							
                });
            });

             //When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
             if (gridData != null) {					
                var tempGridData = _.clone(gridData), groups = Object
                        .create(null), result, finalKeyCheck = 0;					
                
                vm.gridDataMultipleSelect = _.clone(tempGridData);					
                
                if (vm.gridDataMultipleSelect != undefined) {						
                    vm.selectedGridRow.push( _.clone(vm.gridDataMultipleSelect.rowData));
                    vm.rowData = vm.selectedGridRow[0];
                    getAllComments();	
                }
            }

            //---------Comments

             //Get all the comments saved for this specific row
            function getAllComments(){
                var params = {
                        "action_code": '8n9er0',
                        screen_key: GlobalService.globalParams.screen_key,
                        object_id:	vm.rowData.OBJECT_ID, 
                        tax_year:	filterParams.tax_year,
                        scenario: 	filterParams.scenario,
                        jcd_key: 	GlobalService.globalParams.jcd_key
                }
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
                    console.log(data.jsonObject, "all comments");
                    vm.allComments = data.jsonObject;
                });
            }
            
            // if(gridData.colData.label === "Comments"){
            //     getAllComments(vm.rowData);
            // }
            
            
            vm.save = function(){
                if(vm.COMMENTS == undefined || vm.COMMENTS == null || vm.COMMENTS == ''){
                    AlertService.add("error", "Please enter a valid comments",4000);
                    return;
                }
                
                if(vm.COMMENTS.length > 4000){
                    AlertService.add("error", "Please comments entered cannot be more than 4000.",4000);
                    return;
                }
                
                var returnClobSettingsObj = {},message ="Comment saved successfully",otherInfo = {};
                returnClobSettingsObj['sso_id']  = vm.logged_in_user;
                returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
                returnClobSettingsObj['scenario'] = vm.scenario;
                returnClobSettingsObj['object_id']  =  vm.rowData.OBJECT_ID;
                returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
                returnClobSettingsObj['tax_year'] = filterParams.tax_year;
                returnClobSettingsObj['comments'] =  vm.COMMENTS.replace(/’/g, "'");
                returnClobSettingsObj['OPERATION_TYPE'] = "I";
                returnClobSettingsObj['object_id'] = vm.rowData.OBJECT_ID;
                console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
                
                otherInfo = returnClobSettingsObj;
                    
                // SAVE the Standard Reason and Non_Creditable Amount
                var returnObj = {};		
                returnObj['object_id'] = vm.rowData.OBJECT_ID;					
                returnObj['comments'] = vm.COMMENTS;
                returnObj['OPERATION_TYPE'] = "I";
                
                var defaultsChangeDetails = [];
                defaultsChangeDetails.push(returnObj);
                //invoking the send details
                sendDetails(returnClobSettingsObj, defaultsChangeDetails,otherInfo, message)
            }
                
            function sendDetails(returnClobSettingsObj, defaultsChangeDetails,otherInfo, message) {
                FTCCommentsFactory.saveComments(returnClobSettingsObj,defaultsChangeDetails,otherInfo).then(function(result) {
                    if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                        AlertService.add("error", result.data.errorMessage, 4000);
                        vm.isSaveClicked = false;
                    } else {
                            AlertService.add("success", message, 4000);
                            
                            var args = {
                                    object_id:returnClobSettingsObj.object_id,
                                    gridFilter: {
                                        object_id: returnClobSettingsObj.object_id
                                    }
                            };
                            // $uibModalInstance.dismiss('cancel');
                            $rootScope.$emit('gridUpdate', args);	
                            $uibModalInstance.dismiss('cancel');
                        };
                    });
                }
        }
    }
);