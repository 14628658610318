define(
		[ 'angular'

		],
		function() {
			'use strict';

			var services = angular
					.module('app.dcsDiagnosticsService', [])
					.factory(
							"DcsDiagnosticsServiceFactory",
							[
									'$q',
									'$http',
									'$stateParams',
									'$timeout',
									'$rootScope',
									'$log',
									'GlobalService',
									'JsonObjectFactory',
									'workspaceFactory',
									'AlertService',
									'$injector',
									'$parse',
									'GENERAL_CONFIG',
									function($q, $http, $stateParams, $timeout,
											$rootScope, $log, GlobalService,
											JsonObjectFactory,
											workspaceFactory, AlertService,
											$injector, $parse, GENERAL_CONFIG) {

										var returnObj = {};
										var DcsDiagnosticsServiceFactory = {};
										DcsDiagnosticsServiceFactory.url = {};
										var service = $injector
												.get('JsonObjectFactory');
										// DcsDiagnosticsServiceFactory.url.saveDCSDiagnostics
										// = GENERAL_CONFIG.base_url +
										// "/saveJsonObject?action_code=9q1a26";

										// saveFirDiagnosticsAction
										// saveDcsDiagnosticsAction
										// saveTBBSDiagnostics
										DcsDiagnosticsServiceFactory.save_DCS_Diagnostics = function(
												_callName, _data) {

											/*
											 * var params = { "action_code" :
											 * '9q1a26', "process_name":
											 * 'Save_DCS_DIAGNOSTICS'
											 *  }
											 */

											var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
													.getFilterParams()
													: {};
											var params = _.merge({},
													GlobalService.globalParams,
													filterParams);
											var jsonObj = JSON
													.stringify(_data.rowData);
											var jsonSetting = JSON
													.stringify(_data.rowData);
											params = _.extend({
												jsonSetting : jsonSetting
											}, params);

											params = _.extend({
												jsonObj : jsonObj
											}, params);
								            params.process_name =  "Save_DCS_DIAGNOSTICS";							  	           
											var url = GENERAL_CONFIG.base_url
													+ "/saveJsonObject?action_code=9q1a26";

											service
													.saveJSON(url, params)
													.then(
															function(data)

															{
																if (data.callSuccess === "1") {

																	AlertService
																			.add(
																					"success",
																					"Data has been Saved",
																					3000);

																	var args = {

																		combination_keys : _data.rowData.COMBINATION_KEY,

																		gridFilter : {
																			COMBINATION_KEY : _data.rowData.COMBINATION_KEY
																		}
																	};

																	$rootScope
																			.$emit(
																					'gridUpdate',
																					args);
																} else {
																	AlertService.add("error",
																			data.errorMessage,3000);
																}

															});


										};

										return DcsDiagnosticsServiceFactory;
									} ]);

			return services;
		});