
define([
    'angular',
    './TaxCycleWorkflowController'


], function () {
    'use strict';
    return angular.module('app.TaxCycleWorkflow', ['app.TaxCycleWorkflowController'])
        /*.config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {

            $stateProvider
                .state('tax-placemat-workflow', {
                    url: '/tax-placemat-workflow',
                    templateUrl: 'app/components/taxCycleWorkflow/index.html',
                    data:{},
                    access:""
                })
        }])*/

});