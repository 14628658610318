define(
		[ 'angular',
			'./ftcDocumentationService',

		],
		function() {
			'use strict';

			var controllers = angular.module('app.DocumentationCtrl', [])
					.controller('DocumentationCtrl', ['$scope','$rootScope','$state', '$http', 'AlertService', 'USER_SETTINGS', 'GlobalService', 
						'JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 'DocumentationFactory','rowData', 'gridData', '$uibModal', '$uibModalInstance','$filter',DocumentationCtrl])
							    
			function DocumentationCtrl($scope, $rootScope, $state, $http, AlertService,USER_SETTINGS, GlobalService,
					JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, DocumentationFactory, rowData, gridData, $uibModal, $uibModalInstance,$filter) {


				var vm = this;	
				vm.userSettings = USER_SETTINGS;
				//Logged user id
				vm.logged_in_user = vm.userSettings.user.sso_id;
				//Show the loading Spinner
				vm.loadingData = true;	
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//A edit modal
				vm.isEditMode = false;
				//To get the Grid Filter Params 
				var filterParams = workspaceFactory.activeScreen.filters.getFilterParams();
				//Get the Grid filter Tax Year  
				vm.filterTaxYear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; 
				//Get the Grid filter Scenario
				vm.scenario = filterParams.scenario;        //GlobalService.globalParams.scenario; 
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				//Flag to show or hide the Parent MEs dropdown
				vm.showParentMes = false;
				//Flag to show or hide the Distinct MEs dropdown
				vm.showDistinctMes = false;
				//Flag to show or hide the HO LEs dropdown
				vm.showHoEntities = false;
				//Flag to show or hide the Entities dropdown
				vm.showEachEntities = false;
				//Flag to show or hide the filing group dropdown
				vm.showFilingGrps = false;
				//Loading the Modal popup screen
				vm.loading = true;
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv',
						"p_scenario" : vm.scenario,
						"p_jcd_key" :  vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				
				//Get the Entities for the Modal dropdown
				vm.getDistinctLEIDsData = function() {					
					var params = {"action_code" : 'n37ya4', "filing_key" : "0", "tax_year" : vm.filterTaxYear};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.codeCombinationData = data.jsonObject;
						console.log(" vm.codeCombinationData  :", vm.codeCombinationData);
					}); 
				}
				vm.getDistinctLEIDsData();
				
				//Get the Entities for the Modal dropdown
				vm.getDistinctMEData = function() {					
					var params = {"action_code" : 'km8ief', "filing_key" : "0", "tax_year" : vm.filterTaxYear};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.distinctMEData = data.jsonObject;
						console.log(" vm.distinctMEData  :", vm.distinctMEData);
					}); 
				}
				vm.getDistinctMEData();
				
				//Get the Home Office Entities for the Modal dropdown
				vm.getDistinctHomeOfficeData = function() {					
					var params = {"action_code" : 'a91oun', "filing_key" : "0", "tax_year" : vm.filterTaxYear};
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.distinctHomeOfficeData = data.jsonObject;
						console.log(" vm.distinctHomeOfficeData  :", vm.distinctHomeOfficeData);
					}); 
				}
				vm.getDistinctHomeOfficeData();
								
				//Get the Parent ME Drop-Down
				vm.getParentMeDropdown = function() {  					
					var params = {"action_code" : 'hmkia6', "sso_id" : vm.logged_in_user, "tax_year" : vm.filterTaxYear, "scenario" : vm.scenario };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.parentMEDropDown = data.jsonObject;
						console.log(" vm.parentMEDropDown  :", vm.parentMEDropDown);
					});
				}
				vm.getParentMeDropdown();
												
				//Get the Data from Filing Group 
				vm.getFilingGrpDropDown = function() {		
					var params = {"action_code" : 'gk7crv',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.filingGroupData = data.jsonObject;
						console.log(" Filing Group : ", data.jsonObject);						
					});
				}
				vm.getFilingGrpDropDown();
				
				//On Selection of Apportionment Dropdown
				vm.onSelectOption = function() {					
					vm.loading = true;
					console.log(" selectedLevel : ", vm.selectedLevel);					
					if(vm.selectedLevel && vm.selectedLevel.CODE_SHORT_DESC == 'PARENT_ME'){							
						vm.showParentMes = true;						
						vm.showDistinctMes = false;
						vm.showHoEntities = false;
						vm.showEachEntities = false;
						vm.showFilingGrps = false;
						vm.loading = false;
					}
					
					if(vm.selectedLevel && vm.selectedLevel.CODE_SHORT_DESC == 'ME'){							
						vm.showParentMes = false;
						vm.showDistinctMes = true;
						vm.showHoEntities = false;
						vm.showEachEntities = false;
						vm.showFilingGrps = false;
						vm.loading = false;
					}
					
					if(vm.selectedLevel && vm.selectedLevel.CODE_SHORT_DESC == 'HO_LE'){							
						vm.showParentMes = false;
						vm.showDistinctMes = false;
						vm.showHoEntities = true;
						vm.showEachEntities = false;
						vm.showFilingGrps = false;
						vm.loading = false;
					}
					
					if(vm.selectedLevel && vm.selectedLevel.CODE_SHORT_DESC == 'LE'){							
						vm.showParentMes = false;
						vm.showDistinctMes = false;
						vm.showHoEntities = false;
						vm.showEachEntities = true;
						vm.showFilingGrps = false;
						vm.loading = false;
					}
					
					if(vm.selectedLevel && vm.selectedLevel.CODE_SHORT_DESC == 'FG'){							
						vm.showParentMes = false;
						vm.showDistinctMes = false;
						vm.showHoEntities = false;
						vm.showEachEntities = false;
						vm.showFilingGrps = true;
						vm.loading = false;
					}
					console.log(" Edit mode vm.loading final : ", vm.loading);
				}				
				
				//Get the Data from FTC Code Mast 
				vm.getDocLvlCodeMast = function() {		
					var params = {"action_code" : 'hn5awf',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.levelCodeMastData = data.jsonObject;
						console.log(" Document Level : ", data.jsonObject);
					});
				}
				vm.getDocLvlCodeMast();
				
				vm.setSavedValues = function(){
					//When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
					if (gridData != null) {					
						var tempGridData = _.clone(gridData), groups = Object
								.create(null), result, finalKeyCheck = 0;					
					
						vm.gridDataMultipleSelect = _.clone(tempGridData);					
										
						if (vm.gridDataMultipleSelect != undefined) {						
							console.log("vm.gridDataMultipleSelect:-: ", vm.gridDataMultipleSelect.rowData);
							if (vm.gridDataMultipleSelect.rowData != undefined) {	
							
								//Set the update params
								vm.operationType = 'U';	
								//Is it a Edit Mode
								vm.isEditMode = true;
								
								//Get the saved data
								$scope.$watch(function() { return vm.levelCodeMastData }, function() {
									if(vm.levelCodeMastData != undefined || vm.levelCodeMastData != null){
										for(var indx in vm.levelCodeMastData){											
											if(vm.levelCodeMastData[indx].CODE_KEY == vm.gridDataMultipleSelect.rowData.LEVEL_KEY){
												vm.selectedLevel = vm.levelCodeMastData[indx];
												if(vm.levelCodeMastData[indx].CODE_SHORT_DESC == "PARENT_ME"){
													vm.selectedParentMe = parseInt(vm.gridDataMultipleSelect.rowData.VALUE_KEY);																										
												}else if(vm.levelCodeMastData[indx].CODE_SHORT_DESC == "ME"){
													vm.selectedDistinctMe = parseInt(vm.gridDataMultipleSelect.rowData.VALUE_KEY);																									
												}else if(vm.levelCodeMastData[indx].CODE_SHORT_DESC == "HO_LE"){
													vm.selectedHoEntity = vm.gridDataMultipleSelect.rowData.VALUE_KEY;																										
												}else if(vm.levelCodeMastData[indx].CODE_SHORT_DESC == "LE"){
													vm.selectedLeEntity = parseInt(vm.gridDataMultipleSelect.rowData.VALUE_KEY);																										
												}else if(vm.levelCodeMastData[indx].CODE_SHORT_DESC == "FG"){
													vm.selectedFilingGrp = parseInt(vm.gridDataMultipleSelect.rowData.VALUE_KEY);																								
												}						
											}
										}										
										vm.onSelectOption();
										vm.loadingData = false;	
																		
									}
								});
								
								
							}else{							
								//Set the insert params
								vm.operationType = 'I'; 
								//Enable after Loading Data 
								vm.loadingData = false;
								//Is it a Edit mode or Add Mode
								vm.isEditMode = false;
								
								//Clear if any saved ones
								vm.selectedLevel = '';
								vm.selectedParentMe = '';
								vm.selectedDistinctMe = '';
								vm.selectedFilingGrp = '';
								vm.selectedHoEntity = '';
								vm.selectedLeEntity = '';
								vm.loading = false;
							}											
						}
					}
					
				}
								
				//Saving the Data with percentages
				vm.save = function(){					
					
					//To Block user from saving for Return filed year
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
						vm.cancel();										
						AlertService.add("error", "You cannot perform this action as Tax return is already filed.",4000);
						return;
					}	
					
					if(vm.selectedLevel == undefined || vm.selectedLevel == null || vm.selectedLevel == ''){
						AlertService.add("error", "Please select all the required fields.",4000);						
						return;
					}
					
					if(vm.selectedLevel.CODE_SHORT_DESC == "PARENT_ME" 
							&& (vm.selectedParentMe == undefined || vm.selectedParentMe == null || vm.selectedParentMe.toString() == '')){
						AlertService.add("error", "Please select a valid Parent ME",4000);						
						return;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "ME" 
							&& (vm.selectedDistinctMe == undefined || vm.selectedDistinctMe == null || vm.selectedDistinctMe.toString() == '')){
						AlertService.add("error", "Please select a valid ME.",4000);						
						return;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "HO_LE" 
							&& (vm.selectedHoEntity == undefined || vm.selectedHoEntity == null || vm.selectedHoEntity.toString() == '')){
						AlertService.add("error", "Please select a valid Home Office LEID.",4000);						
						return;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "LE" 
							&& (vm.selectedLeEntity == undefined || vm.selectedLeEntity == null || vm.selectedLeEntity.toString() == '')){
						AlertService.add("error", "Please select a valid LEID.",4000);						
						return;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "FG" 
							&& (vm.selectedFilingGrp == undefined || vm.selectedFilingGrp == null || vm.selectedFilingGrp.toString() == '')){
						AlertService.add("error", "Please select a valid Filing Group.",4000);						
						return;
					}
					
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					
					var dataToBeSaved = [];
					var returnObj = {};					
					returnObj['level_key'] = vm.selectedLevel.CODE_KEY;	
					if(vm.selectedLevel.CODE_SHORT_DESC == "PARENT_ME"){
						returnObj['value_key'] = vm.selectedParentMe;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "ME"){
						returnObj['value_key'] = vm.selectedDistinctMe;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "HO_LE"){
						returnObj['value_key'] = vm.selectedHoEntity;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "LE"){
						returnObj['value_key'] = vm.selectedLeEntity;
					}else if(vm.selectedLevel.CODE_SHORT_DESC == "FG"){
						returnObj['value_key'] = vm.selectedFilingGrp;
					}
					returnObj["operation_type"] = vm.operationType;	
					if(vm.operationType == 'U'){
						returnObj['document_key'] = vm.gridDataMultipleSelect.rowData.DOCUMENT_KEY;
						returnObj['tax_year'] = vm.gridDataMultipleSelect.rowData.TAX_YEAR;
					}
						
					console.log("returnObj::FNL:: ",returnObj);									
			
					//Add to a clob_data to be send to the DB	
					dataToBeSaved.push(returnObj);
			
					
					var message = "Documentation related Level and Value has been successfully saved/updated";								
																		
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['tax_year'] = vm.filterTaxYear; //GlobalService.globalParams.tax_year;
					returnClobSettingsObj['scenario'] = vm.scenario;					
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;	
					returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;				
					returnClobSettingsObj['object_id'] = vm.gridDataMultipleSelect.rowData? vm.gridDataMultipleSelect.rowData.object_id:'';
					
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
										
					//send to save the Data
					sendDetails(returnClobSettingsObj, dataToBeSaved, message);										
				}
				
				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, dataToBeSaved, message) {
					DocumentationFactory.saveData(returnClobSettingsObj, dataToBeSaved).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id: returnClobSettingsObj.object_id,
	                                gridFilter: {
	                                	object_id: returnClobSettingsObj.object_id
	                                }
	                            };							
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
								
				//reset the form
				vm.reset = function(){
					if(vm.operationType == 'I'){
						vm.selectedLevel = '';
						vm.selectedParentMe = '';
						vm.selectedDistinctMe = '';
						vm.selectedFilingGrp = '';
						vm.selectedHoEntity = '';
						vm.selectedLeEntity = '';
					}else{						
						vm.setSavedValues();		
					}						
				};	
				
				
				$scope.$watchGroup(
				        [function () { return vm.distinctHomeOfficeData; },   
				         function () { return vm.codeCombinationData; }],  
				  function() {
					if(vm.distinctHomeOfficeData!=undefined && vm.distinctHomeOfficeData!= null 
							&& vm.codeCombinationData!=undefined && vm.codeCombinationData!=null){
						//Calling the Modal Popup only after all the dropdown data is loaded
						vm.setSavedValues();
					}					
				});
				
			
			}		
		
			return controllers;
			
		});