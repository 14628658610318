define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.ecThresholdSetupActionsController', [])
    .controller('ecThresholdSetupCreateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecThresholdSetupActionsService', ecThresholdSetupCreateActionController])
    .controller('ecThresholdSetupUpdateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecThresholdSetupActionsService', ecThresholdSetupUpdateActionController])
    .controller('ecThresholdSetupDeleteActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','ecThresholdSetupActionsService', ecThresholdSetupDeleteActionController]);
 
    function ecThresholdSetupCreateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,ecThresholdSetupActionsService){
        var vm = this;
        vm.modalTitle = "EC Threshold Management";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy',
            onClose: function(dateText, inst) {   
            }
        };

        vm.thresholdEntity_bckup = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            TABLE_ID:'', 
            SCHEMA_OWNER:'', 
            TABLE_NAME:'', 
            REFRESH_TYPE:'', 
            FROM_TAX_YEAR:'', 
            TO_TAX_YEAR:'', 
            TABLE_CLASSIFICATION:'', 
            SOURCE_SCHEMA:'', 
            SOURCE_TABLE_NAME:'', 
            TABLE_TYPE:'', 
            SRC_TO_TGT_VAR_THRESHOLD:'',
            CLASSIFICATION_DESC:'',
            CLASSIFICATION_KEY:''
        };

        

        vm.thresholdEntity_bckup.TAX_YEAR = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.thresholdEntity_bckup.SCHEMA_OWNER = "GTWFDM01";
        vm.thresholdEntity_bckup.SOURCE_SCHEMA = "GTWFDM01";
        vm.thresholdEntity_bckup.TABLE_TYPE = "TRANSACTIONAL";
        vm.thresholdEntity_bckup.FROM_TAX_YEAR = new Date(workspaceFactory.activeScreen.filters.getFilterParams().tax_year - 1, '0', '1');
        vm.thresholdEntity_bckup.TO_TAX_YEAR = new Date(9999+"", '0', '1');
        
        vm.thresholdEntity = angular.copy(vm.thresholdEntity_bckup);

        let loadThresholdRefreshType = function(){
            
            ecThresholdSetupActionsService.loadThresholdRefreshType().then((refreshType) => {
                console.log("load all Refresh Type", refreshType);
                vm.allrefreshTypes = refreshType;
            });
        }

        let loadThresholdClassification = function(){
            
            ecThresholdSetupActionsService.loadThresholdClassification().then((classificationKey) => {
                console.log("load all Classification", classificationKey);
                vm.allTableClasses = classificationKey;
            });
        }

        let loadThresholdTableType = function(){
            
            ecThresholdSetupActionsService.loadThresholdTableType().then((tableType) => {
                console.log("load all Table Type", tableType);
                vm.allTableTypes = tableType;
            });
        }

        let loadThresholdValue = function(){
            
            ecThresholdSetupActionsService.loadThresholdValue().then((thresholdValue) => {
                console.log("load all Threshold Value", thresholdValue);
                vm.allThresholdValues = thresholdValue;
            });
        }

        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
            loadThresholdRefreshType();
            loadThresholdClassification();
            loadThresholdTableType();
            loadThresholdValue();           
        };
		vm.init();

        vm.isValid = function(){
            if(vm.thresholdEntity.TAX_YEAR == null || 
                vm.thresholdEntity.TAX_YEAR == undefined || 
                vm.thresholdEntity.TAX_YEAR == ""){
                     vm.thresholdEntity.TAX_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.thresholdEntity.SCHEMA_OWNER == null || 
                vm.thresholdEntity.SCHEMA_OWNER == undefined || 
                vm.thresholdEntity.SCHEMA_OWNER == ""){
                     vm.thresholdEntity.SCHEMA_OWNER_NOT_VALID = true;
                     return false;
            }
            if(vm.thresholdEntity.TABLE_NAME == null || vm.thresholdEntity.TABLE_NAME == undefined || vm.thresholdEntity.TABLE_NAME == ""){
                     vm.thresholdEntity.TABLE_NAME_NOT_VALID = true;
                     return false;
            }
            if(vm.thresholdEntity.REFRESH_TYPE == null || vm.thresholdEntity.REFRESH_TYPE == undefined || vm.thresholdEntity.REFRESH_TYPE == ""){
                vm.thresholdEntity.REFRESH_TYPE_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.SOURCE_SCHEMA == null || vm.thresholdEntity.SOURCE_SCHEMA == undefined || vm.thresholdEntity.SOURCE_SCHEMA == ""){
                vm.thresholdEntity.SOURCE_SCHEMA_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.SOURCE_TABLE_NAME == null || vm.thresholdEntity.SOURCE_TABLE_NAME == undefined || vm.thresholdEntity.SOURCE_TABLE_NAME == ""){
                vm.thresholdEntity.SOURCE_TABLE_NAME_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.TABLE_TYPE == null || vm.thresholdEntity.TABLE_TYPE == undefined || vm.thresholdEntity.TABLE_TYPE == ""){
                vm.thresholdEntity.TABLE_TYPE_NOT_VALID = true;
                return false;
            }

            if(vm.thresholdEntity.FROM_TAX_YEAR == null || vm.thresholdEntity.FROM_TAX_YEAR == undefined || vm.thresholdEntity.FROM_TAX_YEAR == ""){
                vm.thresholdEntity.FROM_TAX_YEAR_NOT_VALID = true;
                return false;
            }

            if(vm.thresholdEntity.TO_TAX_YEAR == null || vm.thresholdEntity.TO_TAX_YEAR == undefined || vm.thresholdEntity.TO_TAX_YEAR == ""){
                vm.thresholdEntity.TO_TAX_YEAR_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.CLASSIFICATION_KEY == null || vm.thresholdEntity.CLASSIFICATION_KEY == undefined || vm.thresholdEntity.CLASSIFICATION_KEY == ""){
                vm.thresholdEntity.CLASSIFICATION_KEY_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD == null || vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD == undefined || vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD == ""){
                vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD_NOT_VALID = true;
                return false;
            }
            

            return true;
        }
        
        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.thresholdEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            jsonObj.FROM_TAX_YEAR = jsonObj.FROM_TAX_YEAR.getFullYear().toString();
            jsonObj.TO_TAX_YEAR = jsonObj.TO_TAX_YEAR.getFullYear().toString();
            //jsonObj = ecThresholdSetupActionsService.convertDateFormates(jsonObj);
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nddq6c";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Threshold Management created successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.upper = function(colName) {
            vm.thresholdEntity.SCHEMA_OWNER = colName.toUpperCase();
        }
        vm.upperTableName = function(colName) {
            vm.thresholdEntity.TABLE_NAME = colName.toUpperCase();
        }
        vm.upperSource  = function(colName) {
            vm.thresholdEntity.SOURCE_SCHEMA = colName.toUpperCase();
        }
        vm.upperSourceTableName = function(colName) {
            vm.thresholdEntity.SOURCE_TABLE_NAME = colName.toUpperCase();
        }
        vm.upperRefreshType = function(colName) {
            vm.thresholdEntity.REFRESH_TYPE = colName.toUpperCase();
        }

        vm.reset = function (){
			vm.thresholdEntity = angular.copy(vm.thresholdEntity_bckup);
		};

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };

    }

    function ecThresholdSetupUpdateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, ecThresholdSetupActionsService){
        var vm = this;

        vm.modalTitle = "Threshold Management";
		vm.showFormLoader = true;


        vm.isSavingUpdate = false;
        vm.isSavingDelete = false;
        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year',
            dateFormat: 'yyyy',
            onClose: function(dateText, inst) {   
            }
        };
 		
        vm.thresholdEntity_bckup = gridData.rowData;
        vm.thresholdEntity_bckup.FROM_TAX_YEAR = new Date(gridData.rowData.FROM_TAX_YEAR,'0','1');
        vm.thresholdEntity_bckup.TO_TAX_YEAR = new Date(gridData.rowData.TO_TAX_YEAR,'0','1');
        vm.thresholdEntity = angular.copy(vm.thresholdEntity_bckup);
        


        let loadThresholdRefreshType = function(){
            
            ecThresholdSetupActionsService.loadThresholdRefreshType().then((refreshType) => {
                console.log("load all Refresh Type", refreshType);
                vm.allrefreshTypes = refreshType;
            });
        }

        let loadThresholdClassification = function(){
            
            ecThresholdSetupActionsService.loadThresholdClassification().then((classificationKey) => {
                console.log("load all Classification", classificationKey);
                vm.allTableClasses = classificationKey;
            });
        }

        let loadThresholdTableType = function(){
            
            ecThresholdSetupActionsService.loadThresholdTableType().then((tableType) => {
                console.log("load all Table Type", tableType);
                vm.allTableTypes = tableType;
            });
        }

        let loadThresholdValue = function(){
            
            ecThresholdSetupActionsService.loadThresholdValue().then((thresholdValue) => {
                console.log("load all Threshold Value", thresholdValue);
                vm.allThresholdValues = thresholdValue;
            });
        }

        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
            loadThresholdRefreshType();
            loadThresholdClassification();
            loadThresholdTableType();
            loadThresholdValue();           
        };
		vm.init();
        
        vm.isValid = function(){
            if(vm.thresholdEntity.REFRESH_TYPE == null || vm.thresholdEntity.REFRESH_TYPE == undefined || vm.thresholdEntity.REFRESH_TYPE == ""){
                vm.thresholdEntity.REFRESH_TYPE_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.SOURCE_SCHEMA == null || vm.thresholdEntity.SOURCE_SCHEMA == undefined || vm.thresholdEntity.SOURCE_SCHEMA == ""){
                vm.thresholdEntity.SOURCE_SCHEMA_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.SOURCE_TABLE_NAME == null || vm.thresholdEntity.SOURCE_TABLE_NAME == undefined || vm.thresholdEntity.SOURCE_TABLE_NAME == ""){
                vm.thresholdEntity.SOURCE_TABLE_NAME_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.TABLE_TYPE == null || vm.thresholdEntity.TABLE_TYPE == undefined || vm.thresholdEntity.TABLE_TYPE == ""){
                vm.thresholdEntity.TABLE_TYPE_NOT_VALID = true;
                return false;
            }

            if(vm.thresholdEntity.FROM_TAX_YEAR == null || vm.thresholdEntity.FROM_TAX_YEAR == undefined || vm.thresholdEntity.FROM_TAX_YEAR == ""){
                vm.thresholdEntity.FROM_TAX_YEAR_NOT_VALID = true;
                return false;
            }

            if(vm.thresholdEntity.TO_TAX_YEAR == null || vm.thresholdEntity.TO_TAX_YEAR == undefined || vm.thresholdEntity.TO_TAX_YEAR == ""){
                vm.thresholdEntity.TO_TAX_YEAR_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.TABLE_CLASSIFICATION == null || vm.thresholdEntity.TABLE_CLASSIFICATION == undefined || vm.thresholdEntity.TABLE_CLASSIFICATION == ""){
                vm.thresholdEntity.TABLE_CLASSIFICATION_NOT_VALID = true;
                return false;
            }
            if(vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD == null || vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD == undefined || vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD == ""){
                vm.thresholdEntity.SRC_TO_TGT_VAR_THRESHOLD_NOT_VALID = true;
                return false;
            }

            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSavingUpdate = true;

            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            // let jsonSettings = {
            //     tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
            //     // "scenario": rowData.SCENARIO,
            //     // "jcd_key": rowData.JCD_KEY, 
            //     // "combination_key": rowData.COMBINATION_KEY, 
			// };
            //jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.thresholdEntity);
            jsonObj = _.merge({}, jsonObj , filterParams);
            //jsonObj.IS_UPDATE = 'True';
            //jsonObj = ecThresholdSetupActionsService.convertDateFormates(jsonObj);
            jsonObj.FROM_TAX_YEAR = jsonObj.FROM_TAX_YEAR.getFullYear().toString();
            jsonObj.TO_TAX_YEAR = jsonObj.TO_TAX_YEAR.getFullYear().toString();
            jsonObj = JSON.stringify(jsonObj);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            // params = _.extend({
            //     jsonSettings: jsonSettings
			// }, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=56p7mo";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Threshold Management Updated successfully", 4000);
                    console.log("updated", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        
            
        vm.delete = function(_table_id){
            console.log("Delete function called");
            console.log(_table_id);
            vm.isSavingDelete = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                table_id: _table_id
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nz85y7";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Threshold Management deleted successfully", 4000);
                    console.log("deleted", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.reset = function (){
			vm.thresholdEntity = angular.copy(vm.thresholdEntity_bckup);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function ecThresholdSetupDeleteActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS,ecThresholdSetupActionsService){
                                                    
        var vm = this;

        vm.delete = function(){
            console.log("Delete function called");
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            
            var rowData = gridData.map(({data}) => data);
            // let rowData = gridData[gridData.length-1].data;
            var jsonObj = JSON.stringify(rowData);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                json_data: jsonObj
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=p8r1d0";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Threshold Management deleted successfully", 4000);
                    console.log("deleted", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    return controllers;
});
