define([
    'angular'
    
], function () {
    'use strict';

    var controllers =  angular.module('app.sltDataGenerationController',[])
    .controller('sltDataGenerationController', ['$scope', '$rootScope', '$state', '$log', 'rowData', 'ObjectDiff', 'JsonObjectFactory', 'AlertService', '$timeout', '$parse', 'JCDFactory', 'ClientAdminFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', '$uibModalInstance', 'sltDataGenerationFactory', 'GlobalService', sltDataGenerationController])

    function sltDataGenerationController($scope, $rootScope, $state, $log, rowData, ObjectDiff, JsonObjectFactory, AlertService, $timeout, $parse, JCDFactory, ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS, $uibModalInstance, entitySyncLogFactory, GlobalService) {

        var vm = this;
        vm.title = "Data Generation Dates/Error";
        vm.loading = false;
        vm.slt_api_response_data = [];
        vm.sltParams = rowData;
        vm.syncParams = "";
        vm.client = GlobalService.getClient();
		vm.client_key = vm.client.client_key;

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.getLogJSON = function(){
            vm.loading = true;
			return entitySyncLogFactory.getSltResponseData(vm.client_key, vm.sltParams.SUBMISSION_ID, vm.sltParams.LE_KEY, vm.sltParams.MEF_GROUP_KEY).then(function (data) {
				if(data.callSuccess == "1" ){
                    vm.slt_data_generation_data = data.jsonObject;
                    vm.loading = false;
				}else{
					vm.loading = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };

        vm.getLogJSON();
    }
    return controllers;
});
