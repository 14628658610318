define([
    'angular'

], function () {
    'use strict';
    var services = angular.module('app.updateDirectOwnerService', [])
    .factory("NonFinancialFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService',
    'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
    function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,
        DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
            var nonFinancialFactory = {};
            nonFinancialFactory.url ={};
           
            
            nonFinancialFactory.url.save_direct_owner = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=q44xa7";
            
            nonFinancialFactory.saveDirectOwner = function(_data,Details) {
        		
	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "save_Direct_Owner";
	           var promise = $http({
	        	   method: "post",
	               url: nonFinancialFactory.url.save_direct_owner,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });
	
	           return promise;
     	  };
     	  
     	  nonFinancialFactory.saveTaxOwner = function(_data,Details) {
        		
	           var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "save_Tax_Owner";
	           var promise = $http({
	        	   method: "post",
	               url: nonFinancialFactory.url.save_direct_owner,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });
	
	           return promise;
     	  };
			
		
				
            nonFinancialFactory.saveNonFinancial = function (_callName, _data) {
	
			function getCatgFilerData()
				        		{
				            	 var params = {
				                "action_code": 'ot2ww8',
				                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
				                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
				                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
				                "combination_key": _data.rowData.HO_COMBINATION_KEY,
				                "attrib_name": _data.colData.map,
				                "action": 'grid'
				                
				            	};
				            	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				                if (data.callSuccess === "1") {
				                    var newData = data.jsonObject;
				                    
				                    if(angular.isDefined(newData[0])){
					
										if (_data.rowData[_data.colData.map] == parseInt(newData[0].VALUE)){
										     var override_flag  = 'N';
										     }
									    else {
										    var override_flag  = 'Y';
										    }
					 					}
					 					else {
												var override_flag  = 'Y';
										}
				                    
				                    }
				            
				            var firEin = _data.rowData.FIR_EIN;
            				if(_data.colData.map === 'FIR_EIN' && firEin!=null && firEin.length!=9){
            				var intr=setInterval(function(){
                            	AlertService.add("error", "FIR_EIN has to be 9 digits");
                           		clearInterval(intr);
                           		$rootScope.$apply();
                        		},1000);

            					_data.rowData.FIR_EIN = _data.oldRowData.FIR_EIN;
            					return false;
                    			}
            					if(firEin != null && _data.colData.map ==='FIR_EIN' && firEin.length === 9){
                        		firEin = firEin.substring(0, 2) + "-" + firEin.substring(2);
                    	  }         
				            var json_arrOBJ = [{
                            fir_ein : (_data.colData.map === 'COPY_FROM_GOLD') ? _data.rowData.GOLD_EIN : firEin,
                            is_disregarded_date : (_data.colData.map === 'DISREGARDED_DATE_8858') ? "Y" : "N",
                            disregarded_date_8858 : _data.rowData.DISREGARDED_DATE_8858,
                            is_direct_owner_delete : (_data.colData.map === 'DELETE_DIRECT_OWNER') ? "Y" : "N",
                            is_direct_owner_assign : (_data.colData.map === 'ASSIGN_DIRECT_OWNER') ? "Y" : "N",
                            //direct_owner : (_data.vm && _data.vm.owner &&_data.vm.owner.selectedleid )?_data.vm.owner.selectedleid.PARENT_LE_ID : (_data.rowData.DELETE_DIRECT_OWNER === 1 && _data.rowData.DIRECT_OWNER_8858) ? "" :_data.rowData.ASSIGN_DIRECT_OWNER
                        //direct_owner : (_data.vm && _data.vm.owner &&_data.vm.owner.selectedleid ) ? _data.vm.owner.selectedleid.PARENT_LE_ID : _data.rowData.ASSIGN_DIRECT_OWNER,
                        // direct_owner : (_data.colData.map === 'ASSIGN_DIRECT_OWNER')? '':(_data.vm && _data.vm.owner &&_data.vm.owner.selectedleid ) ? _data.vm.owner.selectedleid.PARENT_LE_ID :  _data.rowData.ASSIGN_DIRECT_OWNER,
                            direct_owner : (_data.vm && _data.vm.owner &&_data.vm.owner.selectedleid ) ? _data.vm.owner.selectedleid.PARENT_LE_ID :  _data.rowData.DIRECT_OWNER_8858,
                            category_1 : (_data.rowData.CATEGORY_1 === 1) ? 1 : 0,
                            category_2 : (_data.rowData.CATEGORY_2 === 1) ? 1 : 0,
                            category_3 : (_data.rowData.CATEGORY_3 === 1) ? 1 : 0,
                            category_4 : (_data.rowData.CATEGORY_4 === 1) ? 1 : 0,
                            category_5 : (_data.rowData.CATEGORY_5 === 1) ? 1 : 0,
                            category_1a : (_data.rowData.CATEGORY_1A === 1) ? 1 : 0,
                            category_1b : (_data.rowData.CATEGORY_1B === 1) ? 1 : 0,
                            category_1c : (_data.rowData.CATEGORY_1C === 1) ? 1 : 0,
                            category_5a : (_data.rowData.CATEGORY_5A === 1) ? 1 : 0,
                            category_5b : (_data.rowData.CATEGORY_5B === 1) ? 1 : 0, 	
                            category_5c : (_data.rowData.CATEGORY_5C === 1) ? 1 : 0, 		
                            is_ein_update :  (_data.colData.map === 'FIR_EIN' || _data.colData.map === 'COPY_FROM_GOLD') ? "Y" : "N",
                            is_category_update_1 : (_data.colData.map ==='CATEGORY_1') ? "Y" : "N",
                            is_category_update_2 : (_data.colData.map==='CATEGORY_2') ? "Y" : "N",
                            is_category_update_3 : (_data.colData.map==='CATEGORY_3') ? "Y" : "N",
                            is_category_update_4 : ( _data.colData.map==='CATEGORY_4') ? "Y" : "N",
                            is_category_update_5 : (_data.colData.map==='CATEGORY_5' ) ? "Y" : "N",
                            is_category_update_1a : (_data.colData.map ==='CATEGORY_1A') ? "Y" : "N",
                            is_category_update_1b : (_data.colData.map==='CATEGORY_1B') ? "Y" : "N",
                            is_category_update_1c : (_data.colData.map==='CATEGORY_1C') ? "Y" : "N",
                            is_category_update_5a : ( _data.colData.map==='CATEGORY_5A') ? "Y" : "N",
                            is_category_update_5b : (_data.colData.map==='CATEGORY_5B' ) ? "Y" : "N",
                            is_category_update_5c : (_data.colData.map==='CATEGORY_5C' ) ? "Y" : "N",
                            // Added new for setting up override flag ----Mona 1/04/2022
                            is_category_override_1 : (_data.colData.map ==='CATEGORY_1'&& override_flag == 'Y') ? "Y" : "N",
                            is_category_override_3 : (_data.colData.map==='CATEGORY_3' && override_flag == 'Y') ? "Y" : "N",
                            is_category_override_4 : ( _data.colData.map==='CATEGORY_4'&& override_flag == 'Y') ? "Y" : "N",
                            is_category_override_5 : (_data.colData.map==='CATEGORY_5' && override_flag == 'Y') ? "Y" : "N",
                            is_category_override_1a : (_data.colData.map ==='CATEGORY_1A'&& override_flag == 'Y') ? "Y" : "N",
                            is_category_override_5a : ( _data.colData.map==='CATEGORY_5A'&& override_flag == 'Y') ? "Y" : "N"	

                        }];
                    //updateDirectOwner
                        var jsonSettings = [{
                            tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                            combination_key: _data.rowData.COMBINATION_KEY,
                            tax_type: _data.rowData.TAX_TYPE
                        }];


                        var service = $injector.get('JsonObjectFactory');
                		var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zqcwb8";

                        var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                        var params = _.merge({}, GlobalService.globalParams, filterParams);
                        params = _.extend({ jsonSettings: JSON.stringify(jsonSettings)}, params);
                        params = _.extend({jsonObj: JSON.stringify(json_arrOBJ)}, params);

                        service.saveJSON(url, params).then(function (data) {
                            if (data.callSuccess === "1") {
                                AlertService.add("success", "Data saved Successfully.", 4000);
                                var args = {
                                        COMBINATION_KEY: _data.rowData.COMBINATION_KEY,
                                        gridFilter: {
                                            COMBINATION_KEY: _data.rowData.COMBINATION_KEY
                                        }
                                    };
                                    $rootScope.$emit('gridUpdate', args);
                            } else {
                                AlertService.add("error", data.errorMessage,4000);
                            }
                          
                          });
				        });
					}
			getCatgFilerData();
				
            }
            return nonFinancialFactory;
        }
    ])
});