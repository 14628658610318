define([
   'angular',
   './genericFormScreenControllers',
   './genericFormScreenDirectives',
   './genericFormScreenService'
   

], function () {
   'use strict';
   return angular.module('app.genericForms', ['app.genericFormScreenControllers','app.genericFormScreenDirectives',
       'app.genericFormScreenService'])
  
});