define([
    'angular',
    './Section174AdjAllocationsController',
    './Section174AdjAllocationsService',
    './Section174Comment'



], function () {
    'use strict';
    return angular.module('app.section174AdjAllocations', ['app.section174AdjAllocationsController','app.section174AdjAllocationsService','app.sec174Comments'] );


});
