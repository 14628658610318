define(
[ 'angular'

],
function() {
	'use strict';

	var controllers = angular.module('app.batchRequestController', [])
	.controller('batchRequestController', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance','ModalFactory','AlertService','batchRequestServiceFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','AggRerunServiceFactory', 'rowData', 'gridData', 'ObjectDiff', batchRequestController])
	
	function batchRequestController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, batchRequestServiceFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,AggRerunServiceFactory,rowData, gridData, ObjectDiff) {
		var vm = this;
		vm.title = "Request Input";
		vm.monitoringData = {};
		vm.monitoringDataCount = 0;
		vm.showJsonLoader = false;
		vm.fileToDownload = "";

		function fetchMonitoringData() {
			vm.showJsonLoader = true;
            var params = { "request_id": rowData.REQUEST_ID, "ist_tkn": rowData.IST_REQ_TKN};

			return batchRequestServiceFactory.getMonitoringData(params).then(function (data) {
				var tempMonitoringData = JSON.parse(data.data.jsonObject.clob_data);
				vm.fileToDownload = tempMonitoringData.file_name;
				if(!Array.isArray(tempMonitoringData)){
					angular.forEach(tempMonitoringData, function(value, key) {
						if(value.includes("{") || value.includes("[")){
							tempMonitoringData[key] = JSON.parse(value);
						}
					});
				}
				vm.monitoringData = ObjectDiff.objToJsonView(tempMonitoringData);
				vm.showJsonLoader = false;
            });
        }
	
		fetchMonitoringData();

		vm.downloadFile = function(){
			console.log("The file is being downloaded");
			let downloadURL= GENERAL_CONFIG.base_url +'/download'+'?'+'file_name=' + vm.fileToDownload + "&client_key=" + USER_SETTINGS.user.client_key;
            window.open(downloadURL,'blank');
		};

		vm.cancel = function(){
			closeModal();
		}

		function closeModal(){
			$uibModalInstance.dismiss('cancel');
		}

	}
	return controllers;
});