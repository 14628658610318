define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.irs4797Service',[])

        .factory("Irs4797Factory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory, GENERAL_CONFIG) {
            var irs4797Factory = {};


            var URLS = {
                FETCH: GENERAL_CONFIG.base_url + '/getIrs4797adj',
                UPDATE: GENERAL_CONFIG.base_url + '/saveIrs4797adj'
            }


            irs4797Factory.getData = function (_url,_params) {
                console.log("TEST/////////////////////////////////////////////////");
            }



            irs4797Factory.saveIrs4797adj = function(_data,_accounts){
                var accounts = {jsonObj:JSON.stringify(_accounts)}
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , _data);
                _.merge({},  GlobalService.globalParams , params);
                _.extend(params, accounts);



                console.log("params:============================ " , params);

                var promise = $http({
                    method: "post",
                    url: URLS.UPDATE,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    console.log("response: " , response)
                    console.log("response.status: " , response.status)
                    return response.data;
                });
                return promise;
            }

            return irs4797Factory;
        }])








    return services;


});