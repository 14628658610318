define(
    [ 'angular',
        './cfcInvestmentAdjService',
    ],
    function() {
        'use strict';

        var controllers = angular
                .module('app.cfcInvestmentAdjCtrl', ['app.cfcInvestmentAdjService'])
                .controller('cfcInvestmentAdjCtrl',
                        [ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService','workspaceFactory',
                                '$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','cfcInvestmentAdjFactory', 'FTCCommentsFactory', '$http', cfcInvestmentAdjCtrl ])

        function cfcInvestmentAdjCtrl($rootScope, $scope, $state, $log,
                JsonObjectFactory, AlertService, GlobalService, workspaceFactory, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, cfcInvestmentAdjFactory,FTCCommentsFactory,$http) {

            var vm = this;	
            //Show the loading Spinner
            vm.loadingData = true;		
           
            //Selected Row on the Grid passed to Modal/Popup to display in top table
            vm.selectedGridRow = [];

            vm.cfc_combination_key = 0;

            vm.adjObj = {};
            vm.adjustmentDtls = [];
            vm.selectedAdjType;
            vm.totalAdjAmt = 0;
            vm.isSaveAndClose = false;
            vm.dateOptions = {};

            //A flag to enable and disable save button
            vm.isSaveClicked = false;
            var savedOnce = false;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};	
            //Get the Grid filter Scenario
            vm.scenario = filterParams.scenario;  	
            //Get the jcd_key from left nav
            vm.jcd_key  = GlobalService.globalParams.jcd_key;
            
            //cancel the pop-up
            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
                if (savedOnce == true) {
                    $rootScope.$emit('gridUpdate', vm.args);
                }
            };
            
            //To get the Lock_Y_N flag from backend
            var lockparams = {
                    "action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
                };				
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
                vm.is_locked = data.jsonObject;				
                //To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
                $scope.$watch(function() { return vm.is_locked }, function() {
                    if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
                            vm.cancel();										
                            AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
                            return;
                    }							
                });
            });

            //Retrieve the adjustment types dropdown from FTC CODE MAST
            vm.getAdjustmentTypes = function() {		
                var params = {"action_code" : 'ozwsxq', "p_tax_year": filterParams.tax_year,"p_code_group": 'CFC_ADJ_TYPES'}; 					
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                        vm.adjustmentTypeDropdown = data.jsonObject;
                        vm.selectedAdjType = "";
                });					
            }
            vm.getAdjustmentTypes();

            //Retrieve the existing adjustment records saved for the given Code Combination Key 
            vm.getAdjustmentDtls = function() {			
                vm.loadingAdjList = true;		
                var params = {"action_code" : 'gxplsp',"p_cfc_combination_key":vm.cfc_combination_key}; 
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                        vm.adjustmentDtls = data.jsonObject;
                        vm.loadingData = false;
                        vm.loadingAdjList = false;
                        vm.calculateTotalAdjAmount();
                });					
            }

             //When click on edit(pencil icon) from Grid Data Screen that brings the Popup on load 
             if (gridData != null) {					
                var tempGridData = _.clone(gridData), groups = Object
                        .create(null), result, finalKeyCheck = 0;					
                
                vm.gridDataMultipleSelect = _.clone(tempGridData);					
                
                if (vm.gridDataMultipleSelect != undefined) {						
                    vm.selectedGridRow.push( _.clone(vm.gridDataMultipleSelect.rowData));
                    vm.cfc_combination_key = vm.selectedGridRow[0].CFC_COMBINATION_KEY;
                    vm.getAdjustmentDtls();	
                }
            }


            vm.editRow = function(editObj){
                //var tempAdjDate = new Date(editObj.ADJ_DATE);
                var tempObj = {};	
                tempObj["operation_type"] = 'U';
                tempObj["adj_key"] = editObj.ADJ_KEY;
                tempObj["adj_type_key"] = editObj.ADJ_TYPE_KEY;
                tempObj["adj_desc"] = editObj.ADJ_DESC;
                let dateSplitted = editObj.ADJ_DATE.split('-');
                tempObj["adj_date"] = new Date(dateSplitted[2], +dateSplitted[1] - 1, dateSplitted[0]);
//                tempObj["adj_date"] = new Date(editObj.ADJ_DATE);
                tempObj["amount"] = editObj.AMOUNT;
                vm.adjObj = tempObj;
                vm.adjObj.adj_type_key = editObj.ADJ_TYPE_KEY;
                vm.selectedAdjType = editObj.ADJ_TYPE_KEY;
            };

            vm.saveAndClose = function() {	
                vm.isSaveAndClose = true;
                vm.save();
            }

            vm.save = function() {	
                console.log("received the Adjustment details: ", vm.adjObj); 					
                 vm.isSaveClicked = true;
                
                if (vm.selectedAdjType == undefined  || vm.selectedAdjType == null  || vm.selectedAdjType == ""  
                    || vm.adjObj.adj_date == undefined  || vm.adjObj.adj_date == null 
                    || vm.adjObj.amount == undefined  || vm.adjObj.amount == null 
                ) {
                    AlertService.add("error", "Please select/enter all the required fields.",4000);
                    vm.isSaveClicked = false;
                    return;
                }
                prepAndSendDetails();
            }

            function prepAndSendDetails() {
                var returnClobSettingsObj = {};
                 returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                 returnClobSettingsObj['object_id'] = vm.cfc_combination_key;
                 vm.adjObj.cfc_combination_key = vm.cfc_combination_key;
                 vm.adjObj.adj_date = vm.adjObj.adj_date.getDate() + '-' + (+vm.adjObj.adj_date.getMonth() + 1) + '-' + vm.adjObj.adj_date.getFullYear();
                 vm.adjObj.adj_type_key = vm.selectedAdjType;
                 console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
                 console.log("adjObj-----" + vm.adjObj);
                 var message = "Adjustment Amount(s) have been saved/updated";			
                 //send to save the Data					
                 sendDetails(returnClobSettingsObj, vm.adjObj, message);  
            }
           
            //Send Details to the Service Layer for persisting the data
            function sendDetails(returnClobSettingsObj, defaultsChangeDetails, message) {
                cfcInvestmentAdjFactory.saveData(returnClobSettingsObj,defaultsChangeDetails).then(function(result) {
                    if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                        AlertService.add("error", result.data.errorMessage, 4000);
                        vm.isSaveClicked = false;
                    } else {
                        AlertService.add("success", message, 4000);
                        vm.args = {
                                object_id: returnClobSettingsObj.object_id,
                                gridFilter: {
                                    object_id: returnClobSettingsObj.object_id
                                }
                            };
                        if (vm.isSaveAndClose == true) {
                            $uibModalInstance.dismiss('cancel');
                            $rootScope.$emit('gridUpdate', vm.args);
                        } else {
                            vm.reset();
                            vm.getAdjustmentDtls();	    
                            savedOnce = true;
                        }
                        
                        vm.isSaveClicked = false;
                        };
                });
            }	
            
            vm.calculateTotalAdjAmount = function() {
                vm.totalAdjAmt = 0;
                for (var item in vm.adjustmentDtls) {
                    vm.totalAdjAmt = vm.totalAdjAmt + vm.adjustmentDtls[item].AMOUNT;
                }
                vm.totalAdjAmt = vm.totalAdjAmt.toFixed(2);
            }

            vm.reset = function() {
                vm.adjObj = {};
                vm.selectedAdjType = "";
            }

        }
    }
);