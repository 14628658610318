
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.htkoConfigService',[])

    .factory('HTKOConfigFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var htkoConfigFactory = {};
        htkoConfigFactory.url = {};
        htkoConfigFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ve1rvv";       
          
        htkoConfigFactory.saveData = function(_settings, _data){
        		var params = {};
        		var jsonObj = JSON.stringify(_data);
                var jsonSettings =  JSON.stringify(_settings);
                var isIssueIdEnabled =  GlobalService.isIssueIDEnabled(_settings['tax_year'],_settings['scenario']);   

        		params = _.merge({},  _settings, params);
        		params = _.extend({clob_data:jsonObj}, params);
                params = _.extend({clob_settings:jsonSettings}, params);
                params = _.merge({}, isIssueIdEnabled, params); 

                params = getLockObjForModal(_settings['combination_key'], params); 
                
        		console.log("saveConfig......params::",params);        
        		
        		var promise = $http({
                    method: "post",
                    url: htkoConfigFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
        	
        	return promise;
        } 
        
        function getLockObjForModal(filer_combination_key, params) { // Created this method since modal without grid is not working with GlobalService.modalLockFilters()
            var checkLockObj =[];
            checkLockObj.push({combination_key:filer_combination_key});
            var lockObj = {};
            lockObj.lockingObj=checkLockObj;	
            if(lockObj){
                params = _.extend({checkLockedbj: JSON.stringify(lockObj.lockingObj)}, params); //Make sure to keep the name checkLockedbj - as in the GlobalService so that api picks it
            }
            return params;
        }
        
        return htkoConfigFactory;
    }])

    return services;

});