define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.sltDataGenerationService',[])

        .factory("sltDataGenerationFactory",['GENERAL_CONFIG', 'AlertService', '$http', '$rootScope',
                function(GENERAL_CONFIG, AlertService, $http, $rootScope) {
          var sltDataGenerationFactory = {};
          
            var URLS = {
                getSltResponse: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32716"
            }

            sltDataGenerationFactory.getSltResponseData = function(client_key, submission_id, le_key, filing_group_key) {
                var promise = $http({
                    method: "GET",
                    url: URLS.getSltResponse + '&client_key='+ client_key +'&submission_id=' + submission_id + '&le_key='+ le_key +'&filing_group_key=' + filing_group_key, 
                }).then(function(response) {
                    if(response.data.callSuccess === "1") {
                        return response.data;
                    } else {
                        AlertService.add("error", "No users list found", 4000);
                    }
                });
                return promise;
            }

            return sltDataGenerationFactory;
        }])

    return services;
});