define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.firf5471h1Ctrl', [])
            .controller('firf5471h1Ctrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',firf5471h1Ctrl]
            );

        function firf5471h1Ctrl($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentFirF5471H1Html = '';
            vm.userSettings = USER_SETTINGS;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;            
            vm.tax_year = filterParams.tax_year;
            var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
            vm.globalParams = JSON.stringify(tempObj);
            vm.checkLockeOdbj =[];
            vm.baseFilterParams = [];
            //vm.dataLoading = true;
            vm.rowData = JSON.stringify(rowData);
            vm.baseURLs = JSON.stringify(vm.baseURLs);

            vm.modalName = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = filterParams.scenario;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.checkLockeOdbj.push({"scenario_key":vm.scenario, "tax_year":vm.tax_year, "jcd_key" : vm.jcd_key, "scenarioCode": vm.scenarioCode, "is_issue_key_enabled":'N'});

                    vm.baseFilterParams.push(
                        {"tax_year":filterParams.tax_year,
                        "scenario":filterParams.scenario, 
                        "me_string":filterParams.me_string, 
                        "ho_leid_opt":filterParams.ho_leid_opt, 
                        "filing_key": filterParams.filing_key,
                        "screen_key" :filterParams.screen_key
                    });

                vm.baseFilterParamsStr = JSON.stringify(filterParams);	
                 
                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);
                    console.log("ctrl.baseURLs ---", vm.baseURLs);
                    console.log("ctrl.rowData ---", vm.rowData);
                    console.log("ctrl.globalParams ---", vm.globalParams);
                    console.log("ctrl.baseFilterParamsStr ---", vm.baseFilterParamsStr);
                    console.log("ctrl.checkLockeOdbjStr ---", vm.checkLockeOdbjStr);
                    console.log("ctrl.modalName ---", vm.modalName);
                    console.log("ctrl.logged_in_user ---", vm.logged_in_user);
                    const html = `<gtw-fir-f5471-h1-ce id= "close-firf5471h1confirm-info" id ="save-firf5471h1save-info" base-urls="{{ctrl.baseURLs}}" row-data="{{ctrl.rowData}}" 
                    global-params="{{ctrl.globalParams}}" filter-params="{{ctrl.baseFilterParamsStr}}" check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" 
                    modal-name="{{ctrl.modalName}}" sso-id="{{ctrl.logged_in_user}}" class="gtw-web-components"></gtw-fir-f5471-h1-ce>`;
                    vm.webComponentFirF5471H1Html = html;

                }
            );
            
            if ($uibModalInstance) {

                $timeout(() => {
                	$window.document.querySelector('gtw-fir-f5471-h1-ce')
                           .addEventListener('close-firf5471h1confirm-info', (event)=>{
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {
                        $window.document.querySelector('gtw-fir-f5471-h1-ce')
                            .addEventListener('save-firf5471h1save-info', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-firf5471h1save-info'){
									var args = {};
                        			$rootScope.$emit('gridUpdate', args);
                                   /* $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });*/
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added 1...');
                        vm.dataLoading = false;
                    },3000
                    
                );
                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
);
