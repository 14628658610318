define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K1',[])
        .factory("K1Factory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K1Factory])
    function K1Factory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K1Factory.loadIssueDropdown = function(taxYear, scenario, jcd_key) {

            console.log(taxYear, scenario, jcd_key);

            let issueList;
            let params = {
                "action_code" : 't748jd', "tax_year" : taxYear, "scenario": scenario, "jcd_key": jcd_key
            };
            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(response) {
                    issueList = response.jsonObject;
                    return issueList;
                });
            return promise;
        }

        K1Factory.loadAllDropdowns = function(tax_year) {

            let allDropdownList;
            let params = {
                "action_code" : 'go3irt', "tax_year" : tax_year
            };
            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(response) {
                    allDropdownList = response.jsonObject;
                    return allDropdownList;
                });
            return promise;

        }

        K1Factory.loadAllockFormDetails = function(tabName, ctrl) {

            let dataParams =  {"action_code" : '36djph', "tax_year": ctrl.tax_year, "scenario": ctrl.scenario, "section_name": '', "leid" : ctrl.PSHIP_LEID, "cdr_no": ctrl.PSHIP_TAX_CODE, "rp": ctrl.PSHIP_REPORTING_PERIOD, "bsla": ctrl.PSHIP_BUSINESS, "curr": ctrl.PSHIP_CURRENCY, "ptr_leid": ctrl.POSTING_PARTNER_LEID, "ptr_cdr_no": ctrl.POSTING_PARTNER_TAX_CODE, "ptr_rp": ctrl.POSTING_PARTNER_REPORTING_PERIOD, "ptr_bsla": ctrl.POSTING_PARTNER_BUSINESS, "ptr_curr": ctrl.POSTING_PARTNER_CURRENCY, "sso_id": ctrl.logged_in_user, "cc_key": ctrl.COMBINATION_KEY, "ptr_cc_key": ctrl.PTR_COMBINATION_KEY};

            let obj;

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', dataParams).then(function (response) {

                ctrl.allFormData = response.jsonObject;

                ctrl.partHeaderIIFormData = $filter("filter")(ctrl.allFormData, {ATTRIB_NAME:"EXTK1_K1_II_PART_HEADER"});
                ctrl.k1caplibFormData = $filter("filter")(ctrl.allFormData, {SECTION_NAME:"K1CAPLIB"});
                ctrl.partHeaderIIIFormData = $filter("filter")(ctrl.allFormData, {ATTRIB_NAME:"EXTK1_K1_III_PART_HEADER"});
                ctrl.tiFormData= $filter("filter")(ctrl.allFormData, {SECTION_NAME:"TI"});
                ctrl.creditFormData = $filter("filter")(ctrl.allFormData, {SECTION_NAME:"CREDIT"});
                ctrl.amtaceFormData = $filter("filter")(ctrl.allFormData, {SECTION_NAME:"AMTACE"});


                ctrl.otherFormData = $filter("filter")(ctrl.allFormData, {SECTION_NAME:"OTHER"});
                for(let x = 0; x < ctrl.otherFormData.length; x++) {
                    obj = ctrl.otherFormData[x];
                    if(ctrl.isJson(obj.ATTRIB_VALUE) && obj.SUB_FORM != null )
                        obj.ATTRIB_VALUE = JSON.parse(obj.ATTRIB_VALUE);
                    ctrl.OtherFormDataObj.push(obj);
                }
                ctrl.otherFormData = ctrl.OtherFormDataObj;
                ctrl.taxcapitalFormData = $filter("filter")(ctrl.allFormData, {SECTION_NAME:"TAXCAPITAL"});
                ctrl.tiLoading = false;

                return ctrl;

            });

            return promise;

        }

        K1Factory.loadIssueDetails = function(ctrl) {
            let issueParams = {"action_code" : '68yejg', "tax_year": ctrl.TAX_YEAR, "scenario": ctrl.scenario,"jcd_key": ctrl.jcd_key, "combination_key":ctrl.COMBINATION_KEY, "ptr_combination_key":ctrl.PTR_COMBINATION_KEY};

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', issueParams).then(function (response) {
                console.log('loaded issue = ' , response.jsonObject);
                if(response.jsonObject.length > 0) {
                    ctrl.selectedIssue = response.jsonObject[0].ISSUE_ID_SHORT_DESC;
                }
                console.log('selectedIssue = ', ctrl.selectedIssue);
                return ctrl;
            });

            return promise;
        }

        K1Factory.formCalcDataAll = function(ctrl) {

            let formulaParams = {"action_code" : 'gb8qgn', "tax_year": ctrl.tax_year, "scenario": ctrl.scenario, "part_no":ctrl.pn,"section_name":ctrl.sm};

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', formulaParams).then(function (response) {
                ctrl.allFormFormulaList =  response.jsonObject;
                console.log('allFormFormulaList = ', ctrl.allFormFormulaList);
                return ctrl;
            });

            return promise;
        }


        K1Factory.applyFormulaMain = function(row, ctrl) {

            console.log('section = ' , row.SECTION_NAME, ', line = ', row.LINE_NO);

            row.IS_CHANGED_FLAG = 'Y';
            if(row.DATA_TYPE === 'number') {

                if(row.SECTION_NAME == 'K1CAPLIB' || row.SECTION_NAME == 'TI' || row.SECTION_NAME == 'CREDIT' || row.SECTION_NAME == 'AMTACE' || row.SECTION_NAME == 'OTHER' || row.SECTION_NAME == 'TAXCAPITAL') {
                    ctrl.formFormulaList =   $filter("filter")(ctrl.allFormFormulaList, {SECTION_NAME:row.SECTION_NAME, ATTRIB_CALC_FORMULA:row.SECTION_NAME});
                    ctrl.formList = $filter("filter")(ctrl.allFormData, {SECTION_NAME:row.SECTION_NAME});
                    //$filter("filter")(ctrl.allFormData, {SECTION_NAME:row.SECTION_NAME})
                    for(let i=0; i < ctrl.formFormulaList.length; i++) {
                        let calcFormula = ctrl.formFormulaList[i].ATTRIB_CALC_FORMULA;
                        if(calcFormula != null && calcFormula.trim().length > 0)
                            calcAmt(ctrl,calcFormula,ctrl.formFormulaList[i].LINE_NO,ctrl.formFormulaList[i].LINE_ATTRIB_KEY, row.DATA_TYPE, ctrl.formList);
                    }
                }

                // evaluate dependent lines
                ctrl.formListTaxCap =  $filter("filter")(ctrl.allFormData, {SECTION_NAME:'TAXCAPITAL'});

                ctrl.formulaListTaxCap =  $filter("filter")(ctrl.allFormFormulaList, {SECTION_NAME:'TAXCAPITAL'});

                ctrl.taxCapitalFormulaList_K1CAPLIB = $filter("filter")(ctrl.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'K1CAPLIB'});
                ctrl.taxCapitalFormulaList_TI = $filter("filter")(ctrl.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'TI'});
                ctrl.taxCapitalFormulaList_OTHER = $filter("filter")(ctrl.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'OTHER'});
                //ctrl.taxCapitalFormulaList_FSI = $filter("filter")(ctrl.formulaListTaxCap,{ATTRIB_CALC_FORMULA:'FSI'});

                for(let s = 0; s < ctrl.taxCapitalFormulaList_K1CAPLIB.length; s++) {
                    ctrl.taxCapitalFormulaList_DEPENDENT.push(ctrl.taxCapitalFormulaList_K1CAPLIB[s]);
                }
                for(let s = 0; s < ctrl.taxCapitalFormulaList_TI.length; s++) {
                    ctrl.taxCapitalFormulaList_DEPENDENT.push(ctrl.taxCapitalFormulaList_TI[s]);
                }
                for(let s = 0; s < ctrl.taxCapitalFormulaList_OTHER.length; s++) {
                    ctrl.taxCapitalFormulaList_DEPENDENT.push(ctrl.taxCapitalFormulaList_OTHER[s]);
                }

                for(let i=0; i < ctrl.taxCapitalFormulaList_DEPENDENT.length; i++) {
                    let calcFormula = ctrl.taxCapitalFormulaList_DEPENDENT[i].ATTRIB_CALC_FORMULA;
                    if(calcFormula != null && calcFormula.trim().length > 0)
                        calcTaxCapitalAmt(ctrl,calcFormula,ctrl.taxCapitalFormulaList_DEPENDENT[i].LINE_NO,ctrl.taxCapitalFormulaList_DEPENDENT[i].LINE_ATTRIB_KEY, row.DATA_TYPE, ctrl.formListTaxCap, 'main');
                }

                //re-evaluate only those formula lines having only TAXCAPITAL in them; each element in this formula are TAXCAPITAL lines which gets changed by other screen changes
                ctrl.formFormulaList =   $filter("filter")(ctrl.allFormFormulaList, {SECTION_NAME:'TAXCAPITAL', ATTRIB_CALC_FORMULA:'TAXCAPITAL'});
                ctrl.formList = $filter("filter")(ctrl.allFormData, {SECTION_NAME:'TAXCAPITAL'});

                for(let i=0; i < ctrl.formFormulaList.length; i++) {
                    let calcFormula = ctrl.formFormulaList[i].ATTRIB_CALC_FORMULA;
                    if(calcFormula != null && calcFormula.trim().length > 0)
                        calcAmt(ctrl,calcFormula,ctrl.formFormulaList[i].LINE_NO,ctrl.formFormulaList[i].LINE_ATTRIB_KEY, row.DATA_TYPE, ctrl.formList);
                }

                /*if(ctrl.countryPresent) {

                    if(row.SECTION_NAME == 'TI' && row.FLC_CODE != null && ( row.FLC_CODE == 'CGNI' || row.FLC_CODE == 'DIV' ||row.FLC_CODE == 'RENTINC' ||row.FLC_CODE == 'ROYALTY' ||row.FLC_CODE == 'INTINC' ||row.FLC_CODE == 'NETGNLS' ||row.FLC_CODE == 'OTHINC') ) {

                        let selCountryKey = $filter("filter")(ctrl.countryList,{CODE_DESCRIPTION:ctrl.mySelectedCountry.COUNTRY_DESCRIPTION})[0]['CODE_KEY'];
                        ctrl.showSelectedCountryFSIBasket(selCountryKey);
                    }
                }*/


            }

            //return ctrl;
        }


        function calcAmt(ctrl,formula, line_no, attrib_key, dataType, targetFormList){

            let lineAmt = 0;
            let matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
            let lineFormulaArray;
            let formulaOperandText;

            console.log('------- formula ----------' , formula);

            if(matches ==  null){
                lineFormulaArray = formula.split(/[+-/\\*\\]/);
            }

            for(let i = 0; i<lineFormulaArray.length; i++){

                lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                formulaOperandText = lineFormulaArray[i].trim();

                ctrl.sourceList = ctrl.formList;

                let newTemp1 = _.find(ctrl.sourceList, {ATTRIB_NAME: formulaOperandText, DATA_TYPE: 'number'});

                lineAmt = parseFloat(newTemp1/*[0]*/.ATTRIB_VALUE);
                if(newTemp1/*[0]*/.IS_EDITABLE_CELL == 'Y' && dataType != null){

                    //newTemp1[0].IS_CHANGED_FLAG = 'Y';
                }
                if(parseFloat(lineAmt)>= 0){
                    formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                }
                else
                    formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
            }

            if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself

                let newTemp123 = $filter("filter")(/*ctrl.formList*/targetFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                let originalValue = newTemp123[0].ATTRIB_VALUE;
                let xyz = eval(formula);

                newTemp123[0].ATTRIB_VALUE = xyz.toFixed();
                if(dataType != null && (originalValue != newTemp123[0].ATTRIB_VALUE) ){
                    newTemp123[0].IS_CHANGED_FLAG = 'Y';
                }
            }

        }

        function calcTaxCapitalAmt(ctrl,formula, line_no, attrib_key, dataType, targetFormList, tabName){

            let lineAmt = 0;
            let matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
            let lineFormulaArray;
            let formulaOperandText;
            let hasGT = false;
            let hasLT = false;

            console.log('------- formula ----------' , formula);

            if(matches ==  null){
                lineFormulaArray = formula.split(/[+-/\\*\\]/);
            }

            for(let i = 0; i<lineFormulaArray.length; i++){

                lineAmt = 0;
                hasGT = false;
                hasLT = false;

                lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");


                formulaOperandText = lineFormulaArray[i].trim();

                if(formulaOperandText.includes('>')) {
                    hasGT = true;
                    formulaOperandText = formulaOperandText.replace('>','');
                }
                else if(formulaOperandText.includes('<')) {
                    hasLT = true;
                    formulaOperandText = formulaOperandText.replace('<','');
                }

                if (tabName == 'main') {
                    ctrl.sourceList =  ctrl.allFormData ;
                }
                else if(tabName == 'fsi') {
                    ctrl.sourceList = [];
                    for(let s = 0; s < ctrl.fsiFormData.length; s++) {
                        ctrl.sourceList.push(ctrl.fsiFormData[s]);
                    }
                }

                let newTemp1 = _.find(ctrl.sourceList, {ATTRIB_NAME: formulaOperandText, DATA_TYPE: 'number'});

                if(tabName == 'main') {
                    lineAmt = parseFloat(newTemp1/*[0]*/.ATTRIB_VALUE);
                }
                else if(tabName == 'fsi') {
                    for(let x = 1; x < newTemp1.DYNAMIC_COUNTRY.length; x++) {
                        lineAmt = lineAmt + parseFloat(newTemp1.DYNAMIC_COUNTRY[x].ATTRIB_VALUE);
                    }
                }


                if(newTemp1/*[0]*/.IS_EDITABLE_CELL == 'Y' && dataType != null){

                    //newTemp1[0].IS_CHANGED_FLAG = 'Y';
                }
                if(hasGT == false && hasLT == false) {

                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+formulaOperandText+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+formulaOperandText+")","("+lineAmt+")");
                }
                else if (hasGT == true || hasLT == true)  {

                    if(hasGT == true) {

                        if(parseFloat(lineAmt)> 0){
                            formula = formula.replace("("+formulaOperandText+"(>)"+")",lineAmt);
                        }
                        else
                            formula = formula.replace("("+formulaOperandText+"(>)"+")",0);
                    }
                    else if (hasLT == true)  {

                        if(parseFloat(lineAmt) < 0){
                            formula = formula.replace("("+formulaOperandText+"(<)"+")","("+lineAmt+")");
                        }
                        else
                            formula = formula.replace("("+formulaOperandText+"(<)"+")",0);
                    }
                }

                console.log('formula b4 eval : ' + formula);

            }

            if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself

                let newTemp123 = $filter("filter")(/*ctrl.formList*/targetFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                //let newTemp123 = $filter("filter")(ctrl.allFormList, {LINE_NO:line_no,LINE_ATTRIB_KEY:attrib_key});
                let originalValue = newTemp123[0].ATTRIB_VALUE;
                let xyz = eval(formula);

                newTemp123[0].ATTRIB_VALUE = xyz.toFixed();
                if(dataType != null && (originalValue != newTemp123[0].ATTRIB_VALUE) ){
                    newTemp123[0].IS_CHANGED_FLAG = 'Y';
                }
            }

        }


        return K1Factory;

    }

    return services;
});