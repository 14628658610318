define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.FormScheduleService', [])

        .factory("FormScheduleFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,
                DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
                var factory = {};
                    
                factory.saveScheduleCDetails = function (_callName, _data, isNew) {
                    var defer = $q.defer();
                    if(_data.rowData && _data.rowData.SCENARIO != _data.rowData.SCENARIO_KEY) {
                        AlertService.add("error", "Selected Scenario is Locked, please verify and update the amount.");
                        defer.reject();
                        return defer.promise;
                    }
                    var clob_setting = {};
                    var clob_data = [];
                    var params = {};
                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ljhm8x";
                    clob_data.push(_data.rowData);
                    clob_setting = {tax_year: _data.rowData.TAX_YEAR};
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    var tempObj = GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario);
                    params.scenarioCode = tempObj.scenarioCode;
                    params.is_issue_key_enabled = tempObj.is_issue_key_enabled;
                    params.process_name = "SCH_C_EDIT_SAVE";
                    params.tax_year = _data.rowData.TAX_YEAR;
                    params.scenario = _data.rowData.SCENARIO;
                    params.jcd_key = _data.rowData.JCD_KEY;
                    params = _.extend({
                        clob_data: JSON.stringify(clob_data)
                    }, params);
                    params = _.extend({
                        clob_setting: JSON.stringify(clob_setting)
                    }, params)
                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data saved Successfully.", 4000);
                                var args = {
                                    tax_year: _data.rowData.TAX_YEAR
                                };
                                $rootScope.$emit('gridUpdate', args);
                                defer.resolve(data);
                        } else {
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                            defer.reject(data);
                        }
                    }
                    ,function(data){
                        defer.reject(data);
                    });
                    return defer.promise;
                }
                return factory;
            }
        ])
    return services;
});