define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.relatedPartyPaymentService', [])
     .factory("firRelatedPartyPaymentSaveFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'USER_SETTINGS', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, USER_SETTINGS, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
    	 var firRelatedPartyPaymentSaveFactory = {};
         var returnObj = {};
         firRelatedPartyPaymentSaveFactory.url = {};
         firRelatedPartyPaymentSaveFactory.url.saveFIRrelatedPartyPayment = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=vxe5m1";//6qr0av";
         firRelatedPartyPaymentSaveFactory.url.savePaymentType = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=479nmu";
         firRelatedPartyPaymentSaveFactory.url.updatePaymentSubType = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=9lpndo";
         //firRelatedPartyPaymentSaveFactory.url.getGoldRecipient = GENERAL_CONFIG.base_url+"/loadJsonObject?action_code=o2nttw";
        // firRelatedPartyPaymentSaveFactory.url.getGoldRecipient = GENERAL_CONFIG.base_url+"/loadJsonObject?action_code=u7bzcs";
      //   firRelatedPartyPaymentSaveFactory.url.getRelatedPartyPayment = GENERAL_CONFIG.base_url+"/loadJsonObject?action_code=pum4mc";

         var service = $injector.get('JsonObjectFactory');
         var defer = $q.defer();
         var filing_key = workspaceFactory.activeScreen.filters.filterParams.filing_key;
         firRelatedPartyPaymentSaveFactory.saveCashPaymentFlag = function(_callName , _data){
            console.log("data", _data);
            console.log("callname - ",_callName);
				$rootScope.$broadcast('gridRefresh', false);
				//Do Nothing if old and new values are same
				if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]){
					return;
				}				
				var ssoId = USER_SETTINGS.user.sso_id;
               
				var params = {"filing_key":filing_key,"rp_seq_id": _data.rowData["RP_SEQ_ID"], "sso_id": ssoId, "column_name": _data.col_id,
						"column_value": '' + (_data.rowData["CASH_PAYMENT"] === 1 ? 'Y' : 'N')};
				 params.process_name =  "saveCashPaymentFlag";				 		
				 service.saveJSON(firRelatedPartyPaymentSaveFactory.url.saveFIRrelatedPartyPayment, params).then(function (data) {
                     if (data.callSuccess === "1") {
                         AlertService.add("success", "Data saved Successfully.", 4000);                         
     						var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                             $rootScope.$emit('gridUpdate', args);                        
                         defer.resolve(data);
                     } else {
                         AlertService.add("error", data.errorMessage);
                         defer.reject(data);
                     }
                 },function(data){
                     defer.reject(data);
                 });
                 return defer.promise;
         }

         firRelatedPartyPaymentSaveFactory.saveIncludeFlag = function(_callName , _data){
             console.log("data", _data);
             console.log("callname - ",_callName);

				$rootScope.$broadcast('gridRefresh', false);
				//Do Nothing if old and new values are same
				if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id]){
					return;
				}
			
				var ssoId = USER_SETTINGS.user.sso_id;
				var params = {"filing_key":filing_key,"rp_seq_id": _data.rowData["RP_SEQ_ID"], "sso_id": ssoId, "column_name": _data.col_id,
						"column_value": '' + (_data.rowData["INCLUDE"] === 1 ? 'Y' : 'N')};
				 params.process_name =  "saveIncludeFlag";		 			
				 service.saveJSON(firRelatedPartyPaymentSaveFactory.url.saveFIRrelatedPartyPayment, params).then(function (data) {
                     if (data.callSuccess === "1") {
                         AlertService.add("success", "Data saved Successfully.", 4000);                         
     						var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                             $rootScope.$emit('gridUpdate', args);                        
                         defer.resolve(data);
                     } else {
                         AlertService.add("error", data.errorMessage);
                         defer.reject(data);
                     }
                 },function(data){
                     defer.reject(data);
                 });
                 return defer.promise;
         }
         
         firRelatedPartyPaymentSaveFactory.saveTaxAmtFlag = function(_callName , _data){
        	 console.log("data", _data);
             console.log("callname - ",_callName);

				$rootScope.$broadcast('gridRefresh', false);
				//Do Nothing if old and new values are same
				//if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id])

				if(_data.rowData[_data.col_id] === '' || _data.rowData[_data.col_id] === undefined)
				{   
					
					var intr=setInterval(function(){ 
		                        AlertService.add("error", "Please enter valid  W/H Tax Amount");
		                       clearInterval(intr);
		                       $rootScope.$apply();
		                    },1000);
		           var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                   $rootScope.$emit('gridUpdate', args); 
		           return false;
		           		
				}
				else if(_data.rowData[_data.col_id] < 0) {
						AlertService.add("error", "Error!!! W/H Tax Amount has to be greater than or equal to zero");
						return;
					 }

				var ssoId = USER_SETTINGS.user.sso_id;
				var params = {"filing_key":filing_key,"rp_seq_id": _data.rowData["RP_SEQ_ID"], "sso_id": ssoId, "column_name": _data.col_id, "column_value": '' + _data.rowData["TAX_AMT"]}
				 params.process_name =  "saveTaxAmtFlag";				
				 
				 service.saveJSON(firRelatedPartyPaymentSaveFactory.url.saveFIRrelatedPartyPayment, params).then(function (data) {
                     if (data.callSuccess === "1") {
                         AlertService.add("success", "Data saved Successfully.", 4000);                         
     						var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                             $rootScope.$emit('gridUpdate', args);                        
                         defer.resolve(data);
                     } else {
                         AlertService.add("error", data.errorMessage);
                         defer.reject(data);
                     }
                 },function(data){
                     defer.reject(data);
                 });
                 return defer.promise;
         }

         firRelatedPartyPaymentSaveFactory.saveAmtPaid = function(_callName , _data){
             console.log("data", _data);
             console.log("callname - ",_callName);

				$rootScope.$broadcast('gridRefresh', false);
				//Do Nothing if old and new values are same
				//if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id])
				
				
				//var newvalue = _data.rowData[_data.col_id] === '' || _data.rowData[_data.col_id] === undefined ? 0 :_data.rowData[_data.col_id];

				if(_data.rowData[_data.col_id] === '' || _data.rowData[_data.col_id] === undefined)
				{   
					
					var intr=setInterval(function(){ 
		                        AlertService.add("error", "Please enter valid Amount Paid");
		                       clearInterval(intr);
		                       $rootScope.$apply();
		                    },1000);
		           var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                   $rootScope.$emit('gridUpdate', args); 
		           return false;
		           		
				}
				else if(_data.rowData[_data.col_id] < 0) {
							var intr=setInterval(function(){ 
		                        AlertService.add("error", "Amt Paid In HO has to be greater than zero");
		                       clearInterval(intr);
		                       $rootScope.$apply();
		                    },1000);
		            		return false;
					 }

				var ssoId = USER_SETTINGS.user.sso_id;
				var params = {"filing_key":filing_key,"rp_seq_id": _data.rowData["RP_SEQ_ID"], "sso_id": ssoId, "column_name": _data.col_id, "column_value": '' + _data.rowData["AMT_PAID"]}
				 params.process_name =  "saveAmtPaid";
				
				 service.saveJSON(firRelatedPartyPaymentSaveFactory.url.saveFIRrelatedPartyPayment, params).then(function (data) {
                     if (data.callSuccess === "1") {
                         AlertService.add("success", "Data saved Successfully.", 4000);                         
     						var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                             $rootScope.$emit('gridUpdate', args);                        
                         defer.resolve(data);
                     } else {
                         AlertService.add("error", data.errorMessage);
                         defer.reject(data);
                     }
                 },function(data){
                     defer.reject(data);
                 });
                 return defer.promise;
         }         

         firRelatedPartyPaymentSaveFactory.saveAmtReceivedInHO = function(_callName , _data){
             console.log("data", _data);
             console.log("callname - ",_callName);

				$rootScope.$broadcast('gridRefresh', false);
				//Do Nothing if old and new values are same
				//if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id])
				//if(_data.rowData[_data.col_id] < 0)

			if(_data.rowData[_data.col_id] === '' || _data.rowData[_data.col_id] === undefined)
				{   
					
					var intr=setInterval(function(){ 
		                        AlertService.add("error", "Please enter valid Amount Received");
		                       clearInterval(intr);
		                       $rootScope.$apply();
		                    },1000);
		           var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                   $rootScope.$emit('gridUpdate', args); 
		           return false;
		           		
				}
				else if(_data.rowData[_data.col_id] > 0) {
        			var intr=setInterval(function(){ 
                        AlertService.add("error", "Amt Received In HO has to be less than zero");
                       clearInterval(intr);
                       $rootScope.$apply();
                    },1000);
            		return false;
						
				}

				var ssoId = USER_SETTINGS.user.sso_id;
				var params = {"filing_key":filing_key,"rp_seq_id": _data.rowData["RP_SEQ_ID"], "sso_id": ssoId, "column_name": _data.col_id, "column_value": '' + _data.rowData["AMT_RECEIVED"]}
				 params.process_name =  "saveAmtReceivedInHO";
				
				 service.saveJSON(firRelatedPartyPaymentSaveFactory.url.saveFIRrelatedPartyPayment, params).then(function (data) {
                     if (data.callSuccess === "1") {
                         AlertService.add("success", "Data saved Successfully.", 4000);                         
     						var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                             $rootScope.$emit('gridUpdate', args);                        
                         defer.resolve(data);
                     } else {
                         AlertService.add("error", data.errorMessage);
                         defer.reject(data);
                     }
                 },function(data){
                     defer.reject(data);
                 });
                 return defer.promise;
         }

         firRelatedPartyPaymentSaveFactory.savePaymentType = function(_rowdata, paymentType){
          //   console.log("callname - ",_callName);
				$rootScope.$broadcast('gridRefresh', false);
				//Do Nothing if old and new values are same
				//if (_data.rowData[_data.col_id] === _data.oldRowData[_data.col_id])

				if(_rowdata["PAYMENT_TYPE"] === paymentType.PAYMENT_TYPE
					&& _rowdata["PAYMENT_SUB_TYPE"] === paymentType.PAYMENT_SUB_TYPE)
				{
					return;
				}
				
				var ssoId = USER_SETTINGS.user.sso_id;
				var params = {"rp_seq_id": _rowdata["RP_SEQ_ID"], "payment_type": paymentType.PAYMENT_TYPE,"payment_sub_type": paymentType.PAYMENT_SUB_TYPE}
			//	 params.process_name =  "saveTaxAmtFlag";				
				params.payment_type = " ";
				
				 service.saveJSON(firRelatedPartyPaymentSaveFactory.url.savePaymentType, params).then(function (data) {
                     if (data.callSuccess === "1") {
                         AlertService.add("success", "Data saved Successfully.", 4000);                         
     						var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                             $rootScope.$emit('gridUpdate', args);                        
                         defer.resolve(data);
                     } else {
                         AlertService.add("error", data.errorMessage);
                         defer.reject(data);
                     }
                 },function(data){
                     defer.reject(data);
                 });
                 return defer.promise;
         }


         firRelatedPartyPaymentSaveFactory.updatePaymentSubType = function(_rowdata, paymentSubType){
             //   console.log("callname - ",_callName);
   				$rootScope.$broadcast('gridRefresh', false);
   				if(_rowdata["PAYMENT_TYPE"] === paymentType.PAYMENT_TYPE
   					&& _rowdata["PAYMENT_SUB_TYPE"] === paymentSubType.PAYMENT_SUB_TYPE)
   				{
   					return;
   				}
   				

   				var ssoId = USER_SETTINGS.user.sso_id;
   				var params = {"rp_seq_id": _rowdata["RP_SEQ_ID"], "payment_type": paymentType.PAYMENT_TYPE,"payment_sub_type": paymentSubType.PAYMENT_SUB_TYPE}
   			//	 params.process_name =  "saveTaxAmtFlag";   				
   				params.payment_sub_type = " ";
   				
   				 service.saveJSON(firRelatedPartyPaymentSaveFactory.url.updatePaymentSubType, params).then(function (data) {
                     if (data.callSuccess === "1") {
                         AlertService.add("success", "Data saved Successfully.", 4000);                         
     						var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.HO_COMBINATION_KEY}};
                             $rootScope.$emit('gridUpdate', args);                        
                         defer.resolve(data);
                     } else {
                         AlertService.add("error", data.errorMessage);
                         defer.reject(data);
                     }
                 },function(data){
                     defer.reject(data);
                 });
                 return defer.promise;
            }
    	 return firRelatedPartyPaymentSaveFactory;
     }]);

    return services;



});



