define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.firSanctionedCountriesCtrl', [])
        .controller('sanctionedCountriesCtrl', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory','$parse', 'sanctionedCountriesGroupObjFactory',
            'AlertService', 'GENERAL_CONFIG', 'ModalFactory',sanctionedCountriesCtrl])
        .controller('sanctionedCountriesCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory', '$parse','sanctionedCountriesGroupObjFactory',
            'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',sanctionedCountriesCtrlModal]);


    function sanctionedCountriesCtrl(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, sanctionedCountriesGroupObjFactory,
                                     AlertService, GENERAL_CONFIG, ModalFactory) {
        sanctionedCountriesCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, sanctionedCountriesGroupObjFactory,
            AlertService, GENERAL_CONFIG,ModalFactory, null, null, null, null, this);
    }


    function sanctionedCountriesCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, sanctionedCountriesGroupObjFactory,
                                          AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance, rowData, colData, gridData,thisObj) {


        var vm = this||thisObj;
        vm.rowData = rowData||$scope.$parent.rowData;

        $scope.sanctionedCountriesData = [];
        $scope.crudLoading = false;

        vm.sanctionedCountriesDetails = [];
        vm.sanctionedCountriesDetailsOriginal = [];
        vm.sanctionedCountriesDetails.value = "";

        vm.isSaveClicked = false;

        if($uibModalInstance) {
            vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
            vm.cancel = function () {
                var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
                $rootScope.$emit('gridUpdate', args);
                $uibModalInstance.close();
                vm.modal_name = null;
            };
            $scope.showCancel = true;
        } else {
            vm.modal_name = null;
            $scope.showCancel = false;
        }

        vm.loadFlag = false;
        vm.saveFlag = true;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.modalTitle = "Sanctioned Countries";

        vm.getFormData = function() {
            var params= {
                "combination_key": vm.rowData.COMBINATION_KEY,
                "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            };

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=h1g222",params).then(function(data) {
                console.log("CHECK THE ROWDATA",vm.rowData);
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                    //  AlertService.add("error", data.errorMessage);
                    return false;
                } else {
                    vm.data = JsonObjectFactory.arrObjToLower(data.jsonObject);
                    console.log("&&&&&&&&&&&*****************&&&&&&&&&&&",vm.data);
                    angular.forEach(vm.data, function(obj) {
                        obj.sanctioned_country = vm.data[0].sanctioned_country;
                        obj.illegal_payments = vm.data[0].illegal_payments;
                    });
                }
            });
        };

        vm.getFormData();

        vm.reset = function () {
            vm.getFormData();
            $scope.sanctionedCountriesData = angular.copy(vm.sanctionedCountriesData);
        };

        vm.clicked = function (value){
            console.log("value ---", value);
        };

       /* vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
             var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY:vm.rowData.COMBINATION_KEY}};
             $rootScope.$emit('gridUpdate', args);
        };
*/

        vm.save = function() {
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vp3h7r";
            var jsonObj = [];
            var jsonSettings = [];

            jsonObj = [{
                sanctioned_country:vm.data[0].sanctioned_country,
                illegal_payments:vm.data[0].illegal_payments
            }];
            jsonSettings = [{
                "combination_key" : vm.rowData.COMBINATION_KEY,
                "tax_year": GlobalService.globalParams.tax_year,
                "scenario": GlobalService.globalParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            }];
            console.log("array",jsonObj);
            console.log("settings",jsonSettings);
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);

            params = _.extend({jsonSettings: JSON.stringify(jsonSettings) }, params);
            params = _.extend({ jsonObj: JSON.stringify(jsonObj)}, params);

            console.log("Params",params);

            console.log('Sanctioned Countries: ', params);
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    AlertService.add("success", "Saved successfully.", 3000);
                    vm.cancel();
                } else {
                    AlertService.add("error", " Problem saving ", 3000);
                }

            });
        }

        /* save from workflow 5/14/2019 */
        $scope.$on('workflowSave',function(event,data){
            vm.save();
        })


    }

});