define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.submissionDashboardController',[])
        .controller('submissionDashboardCtrl', ['$scope', '$rootScope', submissionDashboardCtrl])
        .controller('addDormatController',['$scope','$rootScope','GlobalService','JsonObjectFactory','$uibModalInstance','gridData',
        'rowData','colData','AlertService','submissionDashboardFactory','GENERAL_CONFIG',addDormatController])
        .controller('removeDormatController',['$scope','$rootScope','GlobalService','JsonObjectFactory','$uibModalInstance','gridData',
        'rowData','colData','AlertService','submissionDashboardFactory','GENERAL_CONFIG',removeDormatController])
        .controller('summaryDiagCtrl', ['$scope', '$rootScope', 'workspaceFactory', 'AlertService', 'submissionDashboardFactory', summaryDiagCtrl])


    function submissionDashboardCtrl($scope,$rootScope) {
        console.log("_Controller is RUNNING");

        var vm = this;

        vm.pageTitle = "_Controller"



        vm.save = function(){

        }

    }

    function addDormatController($scope,$rootScope,GlobalService,JsonObjectFactory,$uibModalInstance,gridData,rowData,colData,AlertService,
        submissionDashboardFactory,GENERAL_CONFIG){
        var vm = this;
        vm.title = "Add Dormat";
        vm.entities = [];
        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
        //begin PKG_GT_DIAGNOSTICS.SUBMISSION_SIGNOFF(?,?,?,?,?,?,?);end;
        function init(){
            $scope.crudLoading = false;
                if( !_.isArray(gridData)){
                    var obj  = {}
                    obj.row_id = gridData.rowData.object_id;
                    obj.data = gridData.rowData;
                    gridData = [];

                    gridData.push(obj);

                }
                if (typeof rowData !== 'undefined') {
                            vm.rowData = rowData;
                }
                vm.entities  = _.clone(gridData);
                console.log("entities", vm.entities);
            }

        vm.saveDormat = function(){
            var params = {},
                url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fdsa48";
            vm.cc_keys = [];

            _.each(vm.entities,function(item){
                if(item.data && item.data.TB_LOADED===""){
                    vm.cc_keys.push({cc_key:(item.data.COMBINATION_KEY || item.data.CC_KEY)});
                }else if(item.data && item.data.TB_LOADED && item.data.TB_LOADED != 1){
                    vm.cc_keys.push({cc_key:(item.data.COMBINATION_KEY || item.data.CC_KEY)});
                }
                // else{
                //     vm.cc_keys.push({cc_key:(item.data.COMBINATION_KEY || item.data.CC_KEY)});
                // }
            });
            console.log("****cc_keys****",vm.cc_keys);
            params = {
                tax_year : vm.entities[0].data.TAX_YEAR,
                cc_keys : JSON.stringify(vm.cc_keys),
                status: 1,
                del_flag:'N'
            }

            console.log("****cc_keys****",params);//
            //JsonObjectFactory.saveJSON(url, params).then(function (data) {
         if(vm.cc_keys && vm.cc_keys.length>0){
            submissionDashboardFactory.addDormat(url, params).then(function (response){
                if(response && response.data && response.data.callSuccess === "1"){
                    AlertService.add("success","TB Inactive saved successfully",4000);
                    vm.cancel();
                    if(vm.cc_keys && vm.cc_keys.length<10){
                        var cc_keys = _.map(vm.cc_keys,'cc_key').join(',');
                        var args = {
                            combination_keys: cc_keys,
                            gridFilter :{
                                CC_KEY : cc_keys
                          }

                        }
                        $rootScope.$emit('gridUpdate', args);
                    }else{
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": true
                        });
                    }



                }else{
                    AlertService.add("error",response.data.errorMessage,4000);
                }
            });
         }else{
            AlertService.add("warning","please select the empty circle records",4000);
             vm.cancel();
         }
        }
        vm.removeEntity = function(index){
            vm.entities.splice(index,1);
            if( vm.entities.length === 0 ){
                $uibModalInstance.dismiss('cancel');
            }
        }
        init();

    }
    function removeDormatController($scope,$rootScope,GlobalService,JsonObjectFactory,$uibModalInstance,gridData,rowData,colData,AlertService,
        submissionDashboardFactory,GENERAL_CONFIG){
        var vm = this;
        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
        function init(){
            $scope.crudLoading = false;
                if( !_.isArray(gridData)){
                    var obj  = {}
                    obj.row_id = gridData.rowData.object_id;
                    obj.data = gridData.rowData;
                    gridData = [];

                    gridData.push(obj);

                }
                if (typeof rowData !== 'undefined') {
                            vm.rowData = rowData;
                }
                vm.entities  = _.clone(gridData);
                console.log("entities", vm.entities);
            }

        vm.removeDormat = function(){
            var params = {},
                url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fdsa48";
            vm.cc_keys = [];

            _.each(vm.entities,function(item){
                if(item.data && item.data.TB_LOADED =='N/A'){
                    vm.cc_keys.push({cc_key:(item.data.COMBINATION_KEY || item.data.CC_KEY)});
                }
            });
            params ={
                tax_year : vm.entities[0].data.TAX_YEAR,
                cc_keys : JSON.stringify(vm.cc_keys),
                status: 1,
                del_flag:'Y'

             }

             console.log("****cc_keys****",params);//
            //JsonObjectFactory.saveJSON(url, params).then(function (data) {
            if(vm.cc_keys && vm.cc_keys.length>0){
                submissionDashboardFactory.addDormat(url, params).then(function (response){
                    if(response && response.data && response.data.callSuccess === "1"){
                        AlertService.add("success","TB Inactive saved successfully",4000);
                        vm.cancel();
                        if(vm.cc_keys && vm.cc_keys.length<10){
                            var cc_keys = _.map(vm.cc_keys,'cc_key').join(',');
                            var args = {
                                combination_keys: cc_keys,
                                gridFilter :{
                                    CC_KEY : cc_keys
                            }

                            }
                            $rootScope.$emit('gridUpdate', args);
                        }else{
                            $rootScope.$broadcast('dataFilters:refreshGrid', {
                                "refresh": true
                            });
                        }



                    }else{
                        AlertService.add("error",response.data.errorMessage,4000);
                    }
                });
            }else{
                AlertService.add("warning","please select the N/A records",4000);
                vm.cancel();
            }



        }
        vm.removeEntity = function(index){
            vm.entities.splice(index,1);
            if( vm.entities.length === 0 ){
                $uibModalInstance.dismiss('cancel');
            }
        }
        init();



    }

    function summaryDiagCtrl($scope, $rootScope, workspaceFactory, AlertService, submissionDashboardFactory) {
        var vm = this;
        vm.filterTemplate = 'app/components/dataFilters/grid-filters-tpl.html';
        vm.activeScreenLocal = workspaceFactory.activeScreen;
        vm.filtersCache = vm.activeScreenLocal.filters ? vm.activeScreenLocal.filters.getFilterParams() : {};
        if (vm.filtersCache) {
            vm.taxYear = vm.filtersCache.tax_year
            vm.scenario = vm.filtersCache.scenario;
            vm.filingKey = vm.filtersCache.filingKey;
            vm.parentMeString = vm.filtersCache.parent_me_string;
            vm.system = vm.filtersCache.system;
        }
        vm.diagList = null;
        vm.dtInstance = {};
        vm.entityDiagTable = {};

        $scope.$on('dataFilters:refreshGrid', function (event, data) {
            vm.getData();
        });

        vm.getData = function () {
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            vm.taxYear = filterParams.tax_year
            vm.scenario = filterParams.scenario;
            vm.filingKey = filterParams.filing_key;
            vm.parentMeString = filterParams.parent_me_string;
            vm.system = filterParams.system;

            vm.loadingList = true;
            submissionDashboardFactory.getSummaryDiagList(vm.taxYear, vm.scenario, vm.filingKey, vm.parentMeString, vm.system)
                                      .then((response) => {
                                          if (response.data.callSuccess === '1') {
                                              vm.diagList = response.data.viewList;
                                              if (vm.diagList.length === 0) {
                                                  AlertService.add('warning', 'No diagnostics data found for selected filter combinations, please try again!', 4000);
                                                  return;
                                              }
                                              // configure datatable settings
                                              vm.entityDiagTable.options = {
                                                  data: vm.diagList,
                                                  extraOptions: {
                                                      componentSettings: {
                                                          rowGrouping: true,
                                                          rowGroupingBy: 'group_desc',
                                                          tableOptions: {
                                                              columns: [
                                                                  {
                                                                      title: 'Group Description',
                                                                      data: 'group_desc',
                                                                      width: 200,
                                                                      orderable: false,
                                                                      className: 'data-column',
                                                                      rowGrouping: true,
                                                                      visible: false
                                                                  },
                                                                  {title: 'Diagnostic Description', data: 'diag_desc', width: 200, orderable: false, className: 'data-column'},
                                                                  {title: 'Resolved Count', data: 'recolved_count', width: 200, orderable: false, className: 'data-column text-center'},
                                                                  {title: 'Unresolved Count', data: 'unresolved_count', width: 200, orderable: false, className: 'data-column text-red text-center'},
                                                              ],
                                                              paging: false
                                                          }
                                                      }
                                                  }
                                              };
                                          }
                                      })
                                      .catch((e) => {
                                          AlertService.add('error', 'Error with loading the diagnostics information, please try again!');
                                      })
                                      .finally(() => {
                                          vm.loadingList = false;
                                      });
        };
    }

    return controllers;

});
