/**
 * Client Admin Directives
 * @author asyed037
 */
define(["angular"], function() {
  "use strict";
  var directives = angular
    .module("app.ClientAdminDirectives", [])
    /**
     * Tree selector
     * Used in application setup and workflow setup.
     * Internally uses the Tree View directive.
     * Implements Selection logic, select all, collapse all and preview features
     */
    .directive("treeSelector", treeSelector)
    /**
     * Stepper
     * Used in Add/Edit client popup.
     * Renders steps provided in the data
     */
    .directive("stepper", stepper)
    /**
     * Tree View
     * Used in the Tree Selector directive for Tree view.
     * Renders a hierarchical tree view with ability to select
     */
    .directive("customTreeView", ['AlertService', 'ClientAdminFactory', '$rootScope', customTreeView])
    /**
     * Tree Node
     * Used in the Tree view directive for each node.
     * Renders  a checkbox , a label and Implements  edit and versioning controls(TBD)
     */
    .directive("treeNode", treeNode);

  function treeSelector() {
    var directive = {
      restrict: "A",
      scope: {
        defaultNodes: "=",
        clientNodes: "=",
        client: "=",
        showPreview: "=",
        type: "@",
        wfstKey: "="
      },
      bindToController: true,
      controller: ['$uibModal', '$rootScope',treeSelectorController],
      controllerAs: "treeSelectorCtrl",
      templateUrl:
        "app/components/admin/client/templates/tree-selector.drv.html",
      link: link
    };

    return directive;

    function link(scope, element, attrs) {

    }
    function treeSelectorController ($uibModal, $rootScope) {
      var vm = this;
      vm.selectedAll = true;
      vm.selectedMsg = "Select ALL";
      vm.toggleCollapse = function() {
        vm.collapsed = !vm.collapsed;
        collapseNode(vm.defaultNodes,vm.defaultChildShowFlags);
        collapseNode(vm.clientNodes,vm.clientChildShowFlags);
      };
      //recursive loop to collapse all nodes
      function collapseNode(treeData,flags) {
        for (var index in treeData) {
          treeData[index].showChildren = !vm.collapsed;
          if(treeData[index].nav_level !== "1")
            flags['show_'+ treeData[index].parent_id] = !vm.collapsed;
         // collapseNode(treeData[index].children);
        }
      }
      $rootScope.$on("check.custom.nodes", function(event, data) {
        vm.checkCustomNodes(data.node);
      })
      vm.checkCustomNodes = function(node) {
        var children = vm.clientNodes.filter(function(item) {
          return item.parent_id === node.child_id && item.is_custom === '1';
        });

        if(children.length === 0){
          return;
        }
        for (var i = 0; i < children.length; i++) {
          children[i].is_selected = node.is_selected;
          vm.checkCustomNodes(children[i]);
        }
      }
      vm.copyNodeDefaultToClient = function(node) {
        var elem = vm.clientNodes.find(function(item) {
          return item.child_id === node.child_id && item.nav_level === node.nav_level;
        });
        if(elem){
          if (elem.client_key === '0') {
            elem.is_selected = node.is_selected;
            if (elem.is_selected === 0) {
              elem.is_active = node.is_selected.toString();
            } else {
              elem.is_active = '1';
            }
          } else {
            elem.is_selected = node.is_selected;
            //asyed:
            //remove custom elements too, acc. to requirement.
            //But once a custom element is removed how can we add it back as it is not present on the default side?

            if(elem.type === "category"){
              var customChildrens = vm.clientNodes.filter(function(item) {
                return item.parent_id === elem.child_id && item.is_custom === "1";
              });
              console.log("custom elements found: " + customChildrens.length);
              for (var i = 0; i < customChildrens.length; i++) {
                 customChildrens[i].is_selected = elem.is_selected;
                
              }
            }
          }
          
        }
        else
          console.log("not found in client side nodes", node);
      };
      vm.copyDefaultTree = function() {
    	vm.selectedAll = !vm.selectedAll;
    	if(vm.selectedAll){
    		vm.selectedMsg = "Select ALL";
    	}else{
    		vm.selectedMsg = "UnSelect ALL";
    	}
    		
        for (var index in vm.defaultNodes) {
          vm.defaultNodes[index].is_selected = vm.selectedAll?0:2;
        }
      };

      vm.addCategory = function() {
        $uibModal.open({
          animation: true,
          templateUrl: 'app/components/admin/client/templates/add-category-workflow.html',
          controller: 'clientWorkflowAddModalController as ctrl',
          size: 'md',
          backdrop: 'static',
          resolve: {
            defaultTreeData: function() {
              return vm.defaultNodes;
            },
            clientTreeData: function() {
              return vm.clientNodes;
            },
            flag: function() {
              return "client";
            },
            origin: function() {
              return "Category";
            }
          }
        });
      };
      vm.addStep = function() {
        $uibModal.open({
          animation: true,
          templateUrl: 'app/components/admin/client/templates/add-step-workflow.html',
          controller: 'clientWorkflowAddModalController as ctrl',
          size: 'md',
          backdrop: 'static',
          resolve: {
            defaultTreeData: function() {
              return vm.defaultNodes;
            },
            clientTreeData: function() {
              return vm.clientNodes;
            },
            flag: function() {
              return "client";
            },
            origin: function() {
              return "Step";
            }
          }
        });
      };

      vm.checkIfDragDisable = function() {
        if (vm.clientNodes.length !=0) {
          if (!vm.clientNodes[0].client_key || vm.clientNodes[0].client_key > 0) {
            return true;
          }
        }
        return false;
      }
    }
  }

  function treeNode() {
    var directive = {
      restrict: "A",
      scope: {
        value: "=",
        showChildren: "=",
        treeData: "=",
        client: "=",
        checkParentNodes: "&",
        checkAllChildrenNodes: "&",
        ispreview: "@",
        isEditable: "@",
        onSelected: "&",
        pushNewB: "&",
        wfstKey: "=",
        type: "=",
        collapseChildren: "&"
      },
      bindToController: true,
      controller: ['$uibModal', 'ClientAdminFactory', 'AlertService', '$rootScope',treeNodeController],
      controllerAs: "treeNodeCtrl",
      templateUrl: "app/components/admin/client/templates/tree-node.drv.html",
      link: link
    };

    return directive;
    function link(scope, element, attrs, treeNodeCtrl) {
      //  scope.value.nav_level = parseInt(scope.value.nav_level);
      //       scope.value.show = true;

      scope.$watch("treeNodeCtrl.value.is_selected", function() {
        if (treeNodeCtrl.ispreview === "true") {
          var hide =
            treeNodeCtrl.value.is_selected <= 0 ||
            typeof treeNodeCtrl.value.is_selected === "undefined";
          if (hide) {
            element[0].style.display = "none";
          } else {
            element[0].style.display = "block";
          }
        } else {
          //On change of a checkbox of left side the values gets copied to right side
          treeNodeCtrl.onSelected({ node: treeNodeCtrl.value });
        }
      });
    }
    function treeNodeController($uibModal, ClientAdminFactory, AlertService, $rootScope) {
      var vm = this;
      vm.value.domId =  vm.value.name.replace(/ /g,'_').toLowerCase() + "_" + vm.value.type + (vm.ispreview === "true"? "_pre": "");

      vm.change = function($event) {
        if (vm.ispreview === "true") {
          return;
        }
        vm.value.is_selected =
          $event.currentTarget.children.length === 0 ||
          $event.currentTarget.children[0].className.indexOf("indicator") > 0
            ? 2
            : 0;
          //	if(vm.value.is_selected > 0)
          //		vm.value.unsaved = true;
        selectedDependentNodes();
      };
      function selectedDependentNodes() {
        //select all the dependent parent checkboxes
        vm.checkParentNodes({ node: vm.value });
        //select all the children checkboxes
        vm.checkAllChildrenNodes({ node: vm.value });
      }

      vm.edit = function() {
        vm.editing = true;
        vm.retainLabel = vm.value.name;
        vm.workflowType = vm.value.type.charAt(0).toUpperCase() + vm.value.type.substring(1);
      };
      
      vm.editWorkflow = function() {
        $rootScope.isEditAllowed = true;
        $rootScope.$broadcast("gtw.admin.client.isEditAllowed", {node: vm.value, editAllowed: $rootScope.isEditAllowed});
        if (!$rootScope.isEditAllowed) {
          AlertService.add("warning", "Please save before editing.");
          return;
        }
        vm.editing = false;
        var wfscomp_key;
        var p_type;
        p_type = vm.value.type;
        if (p_type == "component") {
          wfscomp_key = vm.value.child_id.replace("$$", "");
        } else {
          wfscomp_key = vm.value.child_id.replace("##", "");
        }
        ClientAdminFactory.getWorkflowComponent(
        		vm.value.type,
	      vm.value.client_key,
	      wfscomp_key,
	      vm.value.tax_year,
	      vm.value.scenario
	    ).then(
	      function(data) {
	        if (data.data.workflowcomponentList != "") {
	          vm.workflowcomponentList = data.data.workflowcomponentList;
	          $uibModal.open({
	              animation: true,
	              templateUrl: 'app/components/admin/client/templates/edit-workflow-modal.html',
	              controller: 'clientWorkflowEditModalController as ctrl',
                size: 'md',
                backdrop: 'static',
	              resolve: {
	                node: function() {
	                  return vm.value;
	                },
	            	compList: function() {
	                return vm.workflowcomponentList;
	              }
	              }
	            });
	        } else {
	          return;
	        }
	      },
	      function() {
	        AlertService.add("error", "Problem in getWorkflowComponent().");
	      }
	    );
        
      }
      vm.save = function() {
        vm.editing = false;
      };
      vm.cancel = function() {
        vm.editing = false;
        vm.value.name = vm.retainLabel;
      };
      vm.ignoreNewB = function($event) {
        var childId;
        childId = vm.value.child_id.replace("$$", "");
        ClientAdminFactory.removeNewButton(
          childId,
          vm.client.client_key
        ).then(
          function(data) {
            if (data.data.result.message == "success") {
              vm.value.has_new = 0;
              $rootScope.$broadcast("gtw.admin.client.getWorkflowTree", 
              {tax_year: node.tax_year, scenario: node.scenario, client_key: vm.client.client_key});
              AlertService.add("success", "Successfully Ignored The New Version.");
            } else {
              AlertService.add("error", "Problem Ignoring The New Version.");
              return;
            }
          },
          function() {
            AlertService.add("error", "Problem Ignoring The New Version.");
          }
        );
      };

      vm.pushNewBtnClick = function(node) {
        //    	 var client_comp_key;
        //    	 client_comp_key = vm.value.child_id.replace('$$', '');
        //vm.pushNewB({node:node});

        var childId;
        childId = vm.value.child_id.replace("$$", "");

        ClientAdminFactory.pushNewButton(
          childId,
          vm.client.client_key,
          vm.wfstKey
        ).then(
          function(data) {
            if (data.data.result.message == "success") {
              node.has_new = 0;
              $rootScope.$broadcast("gtw.admin.client.getWorkflowTree", 
              {tax_year: node.tax_year, scenario: node.scenario, client_key: vm.client.client_key});
              AlertService.add("success", "Successfully Replaced With New Version.");
            } else {
              AlertService.add("error", "Problem Replacing With New Version.");
              return;
            }
          },
          function() {
            AlertService.add("error", "Problem Replacing With New Version.");
          }
        );
      };
    }
  }

  function customTreeView(AlertService, ClientAdminFactory, $rootScope) {
    var directive = {
      restrict: "A",
      scope: {
        treeData: "=",
        client: "=",
        check: "&",
        ispreview: "@",
        isEditable: "@",
        onSelected: "&",
        type: "=",
        pushNewB: "&",
        wfstKey: "=",
        showChildFlags:'=',
        checkIfDragDisable: "&"
      },
      bindToController: true,
      controller: ['$scope','$timeout',customTreeViewController],
      controllerAs: "treeViewCtrl",
      templateUrl: "app/components/admin/client/templates/tree-view.drv.html",
      link: link
    };

    return directive;
    function link(scope, element, attrs, ctrl) {
      //scope.checkChildren();


    }
    function customTreeViewController($scope,$timeout) {
      var vm = this;
      vm.renderTree = true;
      vm.client.maxLevel = _.max(vm.treeData.map(function(item){return item.nav_level}));
      console.log("maxlevel-->",vm.client.maxLevel);
      if (vm.ispreview === "true") {
        vm.showChildren = true;
      }

      $scope.$on("gtw.admin.client.forceRerenderTree", function(event, data) {
         if(data === vm.client.client_key){
          vm.renderTree = false;
          //Force Re render to make sure ng-init gets triggered
          $timeout(function() {
            vm.renderTree = true;            
          })
         }
      });
      vm.onNodeSelected = function(value) {
        if(!(typeof value.is_selected === "undefined"))
          vm.onSelected({ node: value });
      };

      vm.collapseChildren = function(node,rootNode) {
        if(node.nav_level === vm.client.maxLevel){
          return;
        }

        vm.showChildFlags["show_" + node.child_id] = rootNode.showChildren;
        node.showChildren = rootNode.showChildren;

        for (var i = 0; i < vm.treeData.length; i++) {
          if(node.child_id === vm.treeData[i].parent_id){
            vm.collapseChildren(vm.treeData[i],rootNode);
          }          
        }

      };

      vm.checkClientKey = function() {
        return vm.checkIfDragDisable();
      }

      vm.onDrag = function(srcList, srcIndex, targetList, targetIndex) {
        // var srcNodePriority = srcList[srcIndex].priority;
        // var targetNodePriority = srcList[targetIndex-1].priority;
        // srcList[targetIndex-1].priority = srcNodePriority;
        // srcList[srcIndex].priority = targetNodePriority;
        var sourceItem = srcList[srcIndex];
        var targetItem = srcList[targetIndex];
        var priorityCount = 1;
        var priority = "1";

        if (sourceItem.parent_id !== targetItem.parent_id) {
          AlertService.add("warning", "Not allowed to drag component to this location.");
          return;
        }
    
        if (srcIndex > targetIndex) {
          targetList.splice(targetIndex, 0, srcList[srcIndex]);
          if (srcList == targetList && targetIndex <= srcIndex) srcIndex++;
          srcList.splice(srcIndex, 1);
          srcIndex--;
          for (var element in srcList) {
            if (srcList[element].parent_id === srcList[srcIndex].parent_id) {
              srcList[element].priority = priority;
              priorityCount++;
              priority = priorityCount.toString();
            }
          }
        } else {
          targetIndex++;
          targetList.splice(targetIndex, 0, srcList[srcIndex]);
          if (srcList == targetList && targetIndex <= srcIndex) srcIndex++;
          srcList.splice(srcIndex, 1);
          for (var element in srcList) {
            if (srcList[element].parent_id === srcList[srcIndex].parent_id) {
              srcList[element].priority = priority;
              priorityCount++;
              priority = priorityCount.toString();
            }
          }
        }


				return true;
			};

      vm.checkAllChildrenNodes = function(node) {       
        var children = vm.treeData.filter(function(item) {
          return item.parent_id === node.child_id;
        });

        if(children.length === 0){
          return;
        }
        for (var i = 0; i < children.length; i++) {
        	children[i].is_selected = node.is_selected;
        //	if(children[i].is_selected > 0){
        	//	children[i].unsaved = true;
        //	}else{
        //		children[i].unsaved = false;
        //	}
          vm.checkAllChildrenNodes(children[i]);
        }
        $rootScope.$broadcast("check.custom.nodes", {node: node});
      };
      //Traverse to the top of the hierarchy to determine their states.
      vm.checkParent = function(element) {
        var value = 0;
        if (element.parent_id == null) {
          return;
        }

        var parent = vm.treeData.find(function(item) {
          return item.child_id === element.parent_id
        });
        if (!parent) {
          return;
        }
        var children = vm.treeData.filter(function(item) {
          return item.parent_id === parent.child_id;
        });

        var selectedItems = children.filter(function(item) {
          return item.is_selected !== 0 && typeof item.is_selected !== "undefined";
        });
        var intermitentItems = selectedItems.filter(function(item) {
          return item.is_selected === 1;
        });
        if (selectedItems.length === children.length) {
        //	parent.unsaved = true;
            value = 2;
        } else if (selectedItems.length > 0) {
        //	parent.unsaved = true;
            value = 1;
        }
        if (intermitentItems.length > 0) {
          value = 1;
        }
        // if(value ===0){
        // 	parent.unsaved = false;
        // }
        parent.is_selected = value;

        vm.checkParent(parent);
      };

    }
  }

  function stepper() {
    var directive = {
      link: link,
      restrict: "A",
      scope: {
        stepperData: "=",
        selectedStep: "=",
        stepChanged: "&"
      },
      templateUrl: "app/components/admin/client/templates/stepper.drv.html"
    };
    return directive;

    function link(scope, element, attrs) {
      scope.selectedStep = scope.stepperData[0];
    }
  }

  return directives;
});
