define([
    'angular'

], function () {
    'use strict';
    var controllers = angular.module('app.firRepointCtrl', [])
        .controller('firRepointCtrl', ['$scope', '$state', '$http', 'ModalFactory', 'AlertService',
            'JsonObjectFactory', 'firRepointFectory', '$timeout', 'rowData', 'colData', 'gridData', 'GENERAL_CONFIG', 'workspaceFactory', '$uibModalInstance', 'CodeComboFactory', 'GlobalService', firRepointCtrl])
    

    function firRepointCtrl($scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, firRepointFectory, $timeout, rowData, colData, gridData, GENERAL_CONFIG, workspaceFactory, $uibModalInstance, CodeComboFactory,
        GlobalService) {

        var vm = this;
        var a = 1;
        vm.showLookUP = false;
        vm.acctzeroflag = false;
        vm.rowData = rowData;
        vm.curr = vm.rowData.FC;
        vm.colData = colData;
        vm.showRepointingError = false;
        vm.showRepointingDescError = false;
        vm.sys_acc = {};
        $scope.ACCT_TYPE = rowData.ACCT_TYPE
        $scope.CHART_DISPLAY = rowData.LOCAL_CHART_CODE
        $scope.LOCAL_ACCT_DISPLAY = rowData.LOCAL_ACCT
        $scope.SYSTEM_ACC_DISPLAY = rowData.SYSTEM_ACCT
       console.log("rowData-----", vm.rowData);
        
        $scope.crudLoading = false;
        vm.repointingAmount = 0;
        vm.cyAmount = vm.rowData.TAX_AMT_FEED_CURR;
        vm.elimAmount = vm.rowData.ELIM_AMT_FEED_CURR;
        vm.adjAmountFeed = vm.rowData.ADJUSTED_TAX_AMT_FEED_CURR;
        vm.adjAmount5471 = vm.rowData.ADJUSTED_5471_TAX_AMT;
        vm.adjAmount8858 = vm.rowData.ADJUSTED_8858_TAX_AMT;
        vm.curr8858 = (vm.rowData.CURR_8858 === undefined ? null : vm.rowData.CURR_8858);
        vm.curr5471 = vm.rowData.HO_CURR;
        vm.pyAmount = vm.rowData.BOY_AMT;
        vm.curr5471 = vm.rowData.HO_CURR;
        vm.is_data_saved = false;
        vm.remainingCyAmount = vm.rowData.ADJUSTED_TAX_AMT_FEED_CURR;
        vm.group_key = 0;
        
        var returnObjD = {};
        var sendobj = [];

        vm.Repoint = {
            SYSC_ACCT: '',
            SYS_ACCT_KEY: '',
            TRANS_HEADER_KEY: '',
            TRANS_DETAILS_KEY: '',
            IRS_Form: '',
            IRS_SCHEDULE: '',
            CHECK_COL: '',
            IRS_LINE : '',
            IRS_LINE_DESC : '',
            CHECK_SYS_MAP_ACCT: '',
            LOOK_UP_BTN: '',
            AMMOUNT: '',
            newadded: '',
            ADJUSTMENT_DESC: ''
        }
        vm.sys_acc_dropdown_data = [];
        vm.sys_acc_lookup_data = [];
        vm.repoint_data        =[];
        vm.loadLookupForm = [];
        vm.loadLookupSchedule = [];
        vm.loadLookupLine = [];
        vm.loadLookupColumn = [];
        vm.loadLookupSysMapAcct = [];
        vm.repointList = [];
        vm.tempDeletedRepointList = [];
        var sendobj = [];
        var uniquesendobj = [];
        vm.addRepointClicked = false;
        vm.lookUpMapping = false;
        vm.loadLookUpData = false;

        vm.applyflag = "";
        vm.sort_key = {
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        };
        vm.sort_key1 = {
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        };

        if (vm.rowData.COMBINATION_KEY) {
            CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
            CodeComboFactory.toggle = false;
            $scope.fromScreen = true;
            CodeComboFactory.showFormBtn = false;
        } else {
            CodeComboFactory.toggle = true;
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            sendobj = [];
            returnObjD = {};
        }

        function init() {
            $scope.crudLoading = false;
            getSystemAcctLookUP();
            //getSystmAcctDropDown();
            fetchSystemAcctData();
            getDefaultSystemAcctValues();
        }
        init();

        vm.reset = function () {
            $scope.crudLoading = false;
            //vm.OLD_DESC = Repoint.ADJUSTMENT_DESC;
        	/*vm.Repoint.ADJUSTMENT_DESC = "";
        	for (var i = 0 ; i<vm.repointList.length;i++){
        		vm.repointList[i].SYSC_ACCT = '';
        		vm.repointList[i].AMMOUNT = '';
        		vm.repointAmt();
        	}*/
            if (vm.repoint_data.length > 0) {
                //vm.Repoint.ADJUSTMENT_DESC = vm.OLD_DESC;

            	vm.sys_acc_lookup_data = [];
            	vm.repoint_data = [];
                getSystemAcctLookUP();
                //getSystmAcctDropDown();
                fetchSystemAcctData();
                getDefaultSystemAcctValues();
            }
            else {
                //vm.OLD_DESC = Repoint.ADJUSTMENT_DESC;
            	vm.repointList = [];
            	vm.sys_acc_lookup_data = [];
            	vm.repoint_data = [];
                vm.Repoint.ADJUSTMENT_DESC = "";
                getSystemAcctLookUP();
                //getSystmAcctDropDown();
                fetchSystemAcctData();
                getDefaultSystemAcctValues();
                vm.repointAmt();
                

            }
            //vm.changeSystemacct();
        }

        vm.sortBy = function (key, value) {
            switch (value) {
                case 0:
                    vm.sort_key[key] = 1;
                    vm.reverseSort = false;
                    break;
                case 1:
                    vm.sort_key[key] = 2;
                    vm.reverseSort = true;
                    break;
                case 2:
                    vm.sort_key[key] = 0;
                    vm.reverseSort = "";
                    break;
            }
        }

        vm.sortBy1 = function (key, value) {
            switch (value) {
                case 0:
                    vm.sort_key1[key] = 1;
                    vm.reverseSort1 = false;
                    break;
                case 1:
                    vm.sort_key1[key] = 2;
                    vm.reverseSort1 = true;
                    break;
                case 2:
                    vm.sort_key1[key] = 0;
                    vm.reverseSort1 = "";
                    break;
            }
        }

        vm.toggleLookup = function () {
            vm.lookUpMapping = !vm.lookUpMapping;
        };

        vm.resetLookup = function (row) {
            vm.lookupresethaskey = row.$$hashKey;
            for (var i = 0; i < vm.repointList.length; i++) {
                if (vm.repointList[i].$$hashKey == vm.lookupresethaskey) {
                    vm.repointList[i].IRS_Form = "";
                    vm.repointList[i].IRS_SCHEDULE = "";
                    vm.repointList[i].IRS_LINE = "";
                    vm.repointList[i].CHECK_COL = "";
                    vm.repointList[i].CHECK_SYS_MAP_ACCT = "";
                }
            }
        }

        vm.applyChanges = function (row) {
            vm.sysacctvalue = row.CHECK_SYS_MAP_ACCT;
            vm.haskey = row.$$hashKey;
            for (var i = 0; i < vm.repointList.length; i++) {
                if (vm.repointList[i].$$hashKey == vm.haskey) {
                    vm.repointList[i].SYSC_ACCT = vm.sysacctvalue;
                }
            }
            vm.applyflag = true;
        }

        vm.toggleLookUPSection = function (repointObj) {
            repointObj.showLookUP = !repointObj.showLookUP;
        }

        vm.adjDescChanged = function () {
            if (vm.repointList.length > 0 && (vm.Repoint.ADJUSTMENT_DESC == '' || typeof vm.Repoint.ADJUSTMENT_DESC == 'undefined')) {
                vm.showRepointingDescError = true;
            } else {
                vm.showRepointingDescError = false;
            }
            return vm.showRepointingDescError;
        }

        vm.addRepoint = function () {
            $timeout(function () {
                vm.addRepointClicked = true;
                vm.applyflag = false;
                console.log(vm.Repoint.OBJ);
                var tempTransHeader_key = 0;
                for(var i = 0; i < vm.repointList.length ; i++)
                {
                	if(vm.repointList[i].TRANS_HEADER_KEY > 0)
                	{
                		tempTransHeader_key = vm.repointList[i].TRANS_HEADER_KEY;
                		break;
                	}
                }
                vm.group_key = vm.group_key+1;
                var lookupData = angular.copy(vm.sys_acc_lookup_data);
                for(var i =0; i < vm.loadSystemAndLocalAccountData.length; i++)
                {
                	vm.repointList.push({
                		IRS_Form: vm.loadSystemAndLocalAccountData[i].FORM,
                    	IRS_SCHEDULE: vm.loadSystemAndLocalAccountData[i].SCHEDULE,
                    	IRS_LINE : vm.loadSystemAndLocalAccountData[i].LINE,
                    	IRS_LINE_DESC : vm.loadSystemAndLocalAccountData[i].LINE + " - " + vm.loadSystemAndLocalAccountData[i].LINE_DESC,
                        CHECK_COL: (vm.loadSystemAndLocalAccountData[i].COL.split(",").length > 1 ? null : vm.loadSystemAndLocalAccountData[i].COL),
                        LOOK_UP_BTN: null, // need to makesure
                        AMMOUNT: vm.Repoint.AMMOUNT,
                        MAIN_ACCT_Y_N : 'Y',
                        REPOINT_ACCT_KEY : 0,
                        DISABLED: false,
                        DELETE_BTN : (i == 0) ? true : false,
                        GROUP_KEY : vm.group_key,
                        TRANS_HEADER_KEY : tempTransHeader_key,
                        loadLookupSchedule : filterDataByKey("FORM", 'SCHEDULE', lookupData, vm.loadSystemAndLocalAccountData[i].FORM, ''),
                        loadLookupLineDesc : filterDataByKey("SCHEDULE", 'LINE_DESC', lookupData, vm.loadSystemAndLocalAccountData[i].SCHEDULE, vm.loadSystemAndLocalAccountData[i].FORM),
                	    loadLookupColumn : filterDataByKey("LINE_DESC", 'COL', lookupData, vm.loadSystemAndLocalAccountData[i].LINE + " - " + vm.loadSystemAndLocalAccountData[i].LINE_DESC, vm.loadSystemAndLocalAccountData[i].SCHEDULE),
                	})
                }
            });
            
            console.log("repointList after loading lookup data-------", vm.repointList);

        }
       
        vm.change = function(checkboxVal, repointObj){
        	if(checkboxVal == true)
        	{
	        	for(var i = 0; i<vm.loadSystemAndLocalAccountData.length; i++)
	        	{
	        	  if(vm.loadSystemAndLocalAccountData[i].FORM !== repointObj.IRS_Form )
	        	  {
		        	vm.repointList.push({
		            	IRS_Form: vm.loadSystemAndLocalAccountData[i].FORM,
		            	IRS_SCHEDULE: vm.loadSystemAndLocalAccountData[i].SCHEDULE,
		            	IRS_LINE :vm.loadSystemAndLocalAccountData[i].LINE,
		            	IRS_LINE_DESC :vm.loadSystemAndLocalAccountData[i].LINE_DESC,
		                CHECK_COL: ( (vm.loadSystemAndLocalAccountData[i].COL).split(",").length > 2 )? 'N/A': vm.loadSystemAndLocalAccountData[i].COL,
		                LOOK_UP_BTN: null, // need to makesure
		                AMMOUNT: repointObj.AMMOUNT,
		                MAIN_ACCT_Y_N : 'N',
		                DISABLED: true,
		                CHECKED : false,
		                GROUP_KEY : repointObj.GROUP_KEY
		            });
	        	  }
	        	  else if(vm.loadSystemAndLocalAccountData[i].SCHEDULE !== repointObj.IRS_SCHEDULE)
	             {
	        		  vm.repointList.push({
	  	            	IRS_Form: vm.loadSystemAndLocalAccountData[i].FORM,
	  	            	IRS_SCHEDULE: vm.loadSystemAndLocalAccountData[i].SCHEDULE,
	  	            	IRS_LINE :vm.loadSystemAndLocalAccountData[i].LINE,
	  	            	IRS_LINE_DESC :vm.loadSystemAndLocalAccountData[i].LINE_DESC,
	  	                CHECK_COL: ( (vm.loadSystemAndLocalAccountData[i].COL).split(",").length > 2 )? 'N/A': vm.loadSystemAndLocalAccountData[i].COL,
	  	                LOOK_UP_BTN: null, 
	  	                AMMOUNT: repointObj.AMMOUNT,
	  	                MAIN_ACCT_Y_N : 'N',
	  	                DISABLED: true,
	  	                CHECKED : false,
	  	                GROUP_KEY : repointObj.GROUP_KEY
	  	            });
	             }
	        	}
        	}
        	else
        	{
        		var i = 0;
        		while(i < sendobj.length)
            	{
            		if(sendobj[i].GROUP_KEY  == repointObj.GROUP_KEY && sendobj[i].MAIN_ACCT_Y_N !== 'Y')
            		{
            			sendobj[i].TYPE = "D";
            			sendobj.splice(i,1);
            		}
            		else
            			++i;
            	}
            	var j = 0;
            	while(j < vm.repointList.length)
            	{
            		if(vm.repointList[j].GROUP_KEY  == repointObj.GROUP_KEY && vm.repointList[j].MAIN_ACCT_Y_N !== 'Y')
            		{
            			vm.repointList[j].TYPE = "D";
            			vm.repointList.splice(j,1);
            		}
            		else
            			++j;
            	}
        	}
        }

        vm.changeFormData = function () {
        	vm.irsFormFlag= false;
        	vm.irsScheduleFlag= false;
        	//vm.irsLineFlag= false;
            for (var a in vm.repointList) {
                if (vm.repointList[a].IRS_Form === "" || vm.repointList[a].IRS_Form === undefined ) {
                    vm.repointList[a].irsFormFlag = true;
                    vm.irsFormFlag = true;
                }
                else {
                    vm.repointList[a].irsFormFlag = false;
                }
                if (vm.repointList[a].IRS_SCHEDULE === "" || vm.repointList[a].IRS_SCHEDULE === undefined ) {
                    vm.repointList[a].irsScheduleFlag = true;
                    vm.irsScheduleFlag = true;
                }
                else {
                    vm.repointList[a].irsScheduleFlag = false;
                }
                if(vm.repointList[a].IRS_SCHEDULE === 'M' && vm.repointList[a].IRS_Form == '5471' && vm.repointList[a].IRS_LINE !== ""   && (vm.repointList[a].CHECK_COL == ''  || vm.repointList[a].CHECK_COL == undefined || vm.repointList[a].CHECK_COL == null))
                {
                	vm.repointList[a].irsColFlag = true;
                	vm.irsFormFlag = true;
                }
                else if(vm.repointList[a].IRS_SCHEDULE === 'M' && vm.repointList[a].IRS_Form == '8858' && vm.repointList[a].IRS_LINE !== ""   && (vm.repointList[a].CHECK_COL == ''  || vm.repointList[a].CHECK_COL == undefined || vm.repointList[a].CHECK_COL == null))
                {	
                	vm.repointList[a].irsColFlag = true;
                	vm.irsFormFlag = true;
                }
                else if(vm.repointList[a].IRS_SCHEDULE === 'N' && vm.repointList[a].IRS_Form == '8865' && vm.repointList[a].IRS_LINE !== ""  && (vm.repointList[a].CHECK_COL == ''  || vm.repointList[a].CHECK_COL == undefined || vm.repointList[a].CHECK_COL == null))
                {	
                	vm.repointList[a].irsColFlag = true;
                	vm.irsFormFlag = true;
                }
                else
                	vm.repointList[a].irsColFlag = false;
                /*if (vm.repointList[a].IRS_LINE === "" || vm.repointList[a].IRS_LINE === undefined ) {
                    vm.repointList[a].irsLineFlag = true;
                    vm.irsLineFlag = true;
                }
                else {
                    vm.repointList[a].irsLineFlag = false;
                }*/
            }
        }
        
        
        vm.addToJson = function (repointObj, isDefaultRecord) {
            var returnObjD = {};

            if (isDefaultRecord) {
                returnObjD["TYPE"] = 'E';
            } else {
                returnObjD["TYPE"] = 'I';
            }

            returnObjD['COMBINATION_KEY'] = rowData.COMBINATION_KEY;
            /*returnObjD['TAX_YEAR'] = rowData.TAX_YEAR;
            returnObjD['SCENARIO'] = GlobalService.globalParams.scenario;
            returnObjD['JCD_KEY'] = GlobalService.globalParams.jcd_key;*/
            returnObjD['LOCAL_CHART_KEY'] = rowData.LOCAL_CHART_KEY;
            returnObjD['LOCAL_ACCT'] = rowData.LOCAL_ACCT;
            returnObjD['NEW_REPOINT_FORM'] = repointObj.IRS_Form;
            returnObjD['NEW_REPOINT_SCHEDULE'] = repointObj.IRS_SCHEDULE;
            returnObjD['NEW_REPOINT_LINE'] = repointObj.IRS_LINE;
            returnObjD['NEW_REPOINT_COL'] = repointObj.CHECK_COL == 'N/A' ? null : repointObj.CHECK_COL;
            returnObjD['OLD_REPOINT_FORM'] = (repointObj.OLD_REPOINT_FORM == undefined)? repointObj.IRS_Form : repointObj.OLD_REPOINT_FORM;
            returnObjD['OLD_REPOINT_SCHEDULE'] = (repointObj.OLD_REPOINT_SCHEDULE == undefined) ? repointObj.IRS_SCHEDULE : repointObj.OLD_REPOINT_SCHEDULE;
            returnObjD['OLD_REPOINT_LINE'] = (repointObj.OLD_REPOINT_LINE == undefined) ? repointObj.IRS_LINE : repointObj.OLD_REPOINT_LINE;
            returnObjD['OLD_REPOINT_COL'] = (repointObj.OLD_REPOINT_COL == undefined ) ? (repointObj.CHECK_COL == 'N/A' ? null : repointObj.CHECK_COL ): repointObj.OLD_REPOINT_COL;
            returnObjD['OLD_ADJ_AMT'] = repointObj.AMMOUNT;
            returnObjD['NEW_ADJ_AMT'] = repointObj.AMMOUNT;
            returnObjD['TRANS_HEADER_KEY'] = repointObj.TRANS_HEADER_KEY != '' ? repointObj.TRANS_HEADER_KEY : '';
            returnObjD['TRANS_DETAILS_KEY'] = repointObj.TRANS_DETAILS_KEY != '' ? repointObj.TRANS_DETAILS_KEY : '';
            returnObjD['OLD_ADJUSTMENT_DESC'] = vm.OLD_DESC;
            returnObjD['NEW_ADJUSTMENT_DESC'] = vm.Repoint.ADJUSTMENT_DESC;
            returnObjD['SOURCE_SYSTEM'] = vm.rowData.SOURCE_SYSTEM;
            returnObjD['REPOINT_ACCT_KEY'] = repointObj.REPOINT_ACCT_KEY;
            returnObjD['MAIN_ACCT_Y_N'] = repointObj.MAIN_ACCT_Y_N;
            returnObjD['TP_KEY'] = (vm.rowData.TP_KEY == undefined) ? 0 : vm.rowData.TP_KEY;
            returnObjD['GROUP_KEY'] = repointObj.GROUP_KEY;

            sendobj.push(returnObjD);
        }
        
        vm.removeRepointAmount = function(index, group_key){
        	if(sendobj.length > index){
        		sendobj[index].TYPE = "D";
        		vm.tempDeletedRepointList.push(sendobj[index]);
        		sendobj.splice(index,1);
        	}
        	vm.repointList.splice(index,1);
        	var indexArr = [];
        	var i = 0 ;
        	while(i < sendobj.length)
        	{
        		if(sendobj[i].GROUP_KEY  == group_key )
        		{
        			sendobj[i].TYPE = "D";
        			sendobj.splice(i,1);
        		}
        		else
        			++i;
        	}
        	var j = 0;
        	while(j < vm.repointList.length)
        	{
        		if(vm.repointList[j].GROUP_KEY  == group_key)
        		{
        			vm.repointList[j].TYPE = "D";
        			vm.repointList.splice(j,1);
        		}
        		else
        			++j;
        	}
        	vm.repointAmt();
        	vm.adjDescChanged();
        	
        }	 
        vm.repointAmt = function() {
        	vm.repointingAmount = 0;
        	vm.acctzeroflag = false;
        	var temp_group_key = null;
        	angular.forEach(vm.repointList, function(repoint, key ){
        		if(repoint.AMMOUNT != null && repoint.AMMOUNT != "" && repoint.GROUP_KEY !== temp_group_key){
        			var x = parseInt(repoint.AMMOUNT, 10);
            		vm.repointingAmount = vm.repointingAmount + x;
            		temp_group_key = repoint.GROUP_KEY;
        		}
        		 /*if(vm.repointingAmount  > vm.cyAmount){
        			 vm.showRepointingError = true;
                 }else {
                	 vm.showRepointingError = false;
                 }*/
        		
        	});
        	vm.remainingCyAmount = vm.cyAmount + vm.elimAmount - vm.repointingAmount;
        	vm.adjAmount5471 = (vm.cyAmount*vm.rowData.HO_FX_RATE) +vm.rowData.HO_ELIM_AMT+ - (vm.repointingAmount*vm.rowData.HO_FX_RATE);
        	vm.adjAmount8858 = (vm.rowData.CURR_8858 === undefined ? 0 : (vm.cyAmount - vm.repointingAmount)*vm.rowData.FX_RATE_8858 );
        	
        	for(var d in vm.repointList) 
    		{
    		
    		vm.repointList[d].acctzeroflag = "";
    		
    		if(vm.repointList[d].AMMOUNT == 0 || vm.repointList[d].AMMOUNT == undefined || vm.repointList[d].AMMOUNT == 'NaN')
    			{
    			vm.repointList[d].acctzeroflag = true;
    			vm.acctzeroflag = true;
    			}
    		}
        }
       //to update edited repoint amount for that perticular group - which has same repoint_acct_key 
        vm.updateAmt = function(group_key, amt){
        	var filterData = vm.repointList.filter(function(object, key){
        		if(object.GROUP_KEY == group_key)
        			return true;
        	});
        	
        	filterData.forEach(function(object, key){
        		object.AMMOUNT = amt;
        	});
        	
        	vm.repointAmt();
        }
        
        vm.save = function () {

            if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
           console.log("vm.repointList---------------before", vm.repointList);
            vm.changeFormData();
            console.log("vm.repointList---------------after", vm.repointList);
            $scope.crudLoading = true;
            vm.acctzeroflag = false;
           
           // if (vm.irsFormFlag === true || vm.irsScheduleFlag === true || vm.irsLineFlag === true) {
           if (vm.irsFormFlag === true || vm.irsScheduleFlag === true ) {
                AlertService.add("danger",
                    "Please correct the errors below", 4000);
                $scope.crudLoading = false;
                throw new ("This is not an error. This is just to abort javascript!");
            }
            else {
                /*angular.forEach(vm.repointList, function (repointObj, key) {
                    angular.forEach(vm.sys_acc_dropdown_data, function (sys_acc, key) {
                        if (repointObj.IRS_FORM == sys_acc.SYS_DROPDOWN) {
                            repointObj.IRS_SCHEDULE = sys_acc.SYS_ACCT_KEY;
                        }
                    });
                });
*/

                var finalRepointList = JSON.parse(JSON.stringify(vm.repointList));
                console.log(vm.adjDescChanged());

                for (var d in vm.repointList) {

                    vm.repointList[d].acctzeroflag = "";

                    if (vm.repointList[d].AMMOUNT == 0 || vm.repointList[d].AMMOUNT == undefined || !vm.repointList[d].AMMOUNT || vm.repointList[d].AMMOUNT == null || vm.repointList[d].AMMOUNT == 'NaN') {
                        vm.repointList[d].acctzeroflag = true;
                        vm.acctzeroflag = true;
                    }
                }


                if (!vm.showRepointingError && !vm.showRepointingDescError && !vm.acctzeroflag) {

                    for (var i = 0; i < finalRepointList.length; i++) {
                        var repointSpliced = false;
                        if (sendobj.length > i) {
                            sendobj[i].NEW_ADJUSTMENT_DESC = vm.Repoint.ADJUSTMENT_DESC;
                            if (sendobj[i].TYPE == "E") {
                                sendobj[i].NEW_REPOINT_FORM = finalRepointList[i].IRS_Form;
                                sendobj[i].NEW_REPOINT_SCHEDULE = finalRepointList[i].IRS_SCHEDULE;
                                sendobj[i].NEW_REPOINT_LINE = finalRepointList[i].IRS_LINE;
                                sendobj[i].NEW_REPOINT_COL = finalRepointList[i].CHECK_COL;
                                sendobj[i].NEW_ADJ_AMT = finalRepointList[i].AMMOUNT;
                                sendobj[i].REPOINT_ACCT_KEY = finalRepointList[i].REPOINT_ACCT_KEY;
                                sendobj[i].GROUP_KEY = finalRepointList[i].GROUP_KEY;
                                if(sendobj[i].NEW_REPOINT_FORM == sendobj[i].OLD_REPOINT_FORM && sendobj[i].NEW_REPOINT_SCHEDULE == sendobj[i].OLD_REPOINT_SCHEDULE && sendobj[i].NEW_REPOINT_LINE == sendobj[i].OLD_REPOINT_LINE )
                                {
                                	if(sendobj[i].NEW_REPOINT_COL == sendobj[i].OLD_REPOINT_COL)
                                		sendobj[i].LINE_CHANGE = 'N';
                                	else 
                                		sendobj[i].LINE_CHANGE = 'Y';
                                }
                                else
                                {
                                	sendobj[i].LINE_CHANGE = 'Y';
                                }
                                //The below IF condition makes sure that there are changes in either system account or adjustment amount or description.
                                //If neither of those values are changed, we are splicing them from SendObj ARRAY
                                if (sendobj[i].OLD_REPOINT_FORM == sendobj[i].NEW_REPOINT_FORM && sendobj[i].OLD_REPOINT_SCHEDULE == sendobj[i].NEW_REPOINT_SCHEDULE && sendobj[i].OLD_REPOINT_LINE == sendobj[i].NEW_REPOINT_LINE && sendobj[i].OLD_REPOINT_COL == sendobj[i].NEW_REPOINT_COL && sendobj[i].OLD_ADJ_AMT == sendobj[i].NEW_ADJ_AMT && sendobj[i].NEW_ADJUSTMENT_DESC == sendobj[i].OLD_ADJUSTMENT_DESC) {
                                    sendobj.splice(i, 1);
                                    finalRepointList.splice(i, 1);
                                    i--;
                                }
                            }
                        } else {
                            vm.addToJson(finalRepointList[i], false);
                        }
                    }
                    var finalSendObj = sendobj.concat(vm.tempDeletedRepointList);
                    console.log("finalSendObj-----", finalSendObj);
                    var jsonSetting = {'TAX_YEAR':rowData.TAX_YEAR, 
                    'tax_year':rowData.TAX_YEAR,
                    'SCENARIO' : GlobalService.globalParams.scenario,
                    'JCD_KEY' : GlobalService.globalParams.jcd_key};
                    var message = 'Repoint has been saved successfully';
                  saveTrialBalanceData(jsonSetting, finalSendObj, message);
                }
                else {
                    $scope.crudLoading = false;
                }
            }
        }

        function saveTrialBalanceData(jsonSetting, uniquesendobj, message) {
            firRepointFectory.saveTb(jsonSetting,
                uniquesendobj, vm.rowData.SOURCE_SYSTEM).then(
                    function (result) {
                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error",
                                result.errorMessage, 4000);
                            $scope.crudLoading = false;

                        } else {
                            AlertService.add("success", message, 4000);
                            $scope.crudLoading = false;
                            vm.is_data_saved = true;
                            $uibModalInstance.dismiss('cancel');
                            var update_combination_key = rowData.COMBINATION_KEY
                            var update_local_acct_key = rowData.LOCAL_ACCT
                            var args = {
                               object_id:vm.rowData.object_id,
                                combination_key: update_combination_key,
                                local_acct_key: update_local_acct_key,

                                gridFilter: {object_id:vm.rowData.object_id,
                                    combination_key: update_combination_key,
                                    local_acct_key: update_local_acct_key,
                                }
                            };


                            $scope.$emit('gridUpdate', args);
                            // init();
                        }
                    });

        }

        
        function fetchSystemAcctData() {
            var params = {
                "action_code": '4fmxwf',
                "tax_year": rowData.TAX_YEAR,
                "local_acct": rowData.LOCAL_ACCT_KEY,
                "sys_acct": rowData.SYSTEM_ACCT_KEY
            }
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
                function (data) {
                    if (data.callSuccess === "1") {
                    	vm.loadSystemAndLocalAccountData = [];
                        vm.loadSystemAndLocalAccountData = data.jsonObject;
                        if(vm.rowData.SYSTEM_ACCT_TYPE == 6 || vm.rowData.SYSTEM_ACCT_TYPE == 7)
                        {
	                        var form5471_m_count = 0;
	                        var form8858_m_count = 0;
	                        var form8865_n_count = 0;
	                        for(var i = 0; i < vm.loadSystemAndLocalAccountData.length; i++)
	                        {
	                        	if(vm.loadSystemAndLocalAccountData[i].FORM == '5471' && vm.loadSystemAndLocalAccountData[i].SCHEDULE == 'M')
	                        		form5471_m_count++;
	                        	if(vm.loadSystemAndLocalAccountData[i].FORM == '8858' && vm.loadSystemAndLocalAccountData[i].SCHEDULE == 'M')
	                        	{
	                        		form8858_m_count++;
	                        		if(vm.loadSystemAndLocalAccountData[i].COL.split(",").length > 1)
	                        		{
	                        			if(rowData.TAX_TYPE == 'BRUS')
	                        			{
	                        				if(vm.loadSystemAndLocalAccountData[i].COL.split(",")[0].includes('_US'))
	                        					vm.loadSystemAndLocalAccountData[i].COL = vm.loadSystemAndLocalAccountData[i].COL.split(",")[0];
	                        				else
	                        					vm.loadSystemAndLocalAccountData[i].COL = vm.loadSystemAndLocalAccountData[i].COL.split(",")[1];
	                        			}
	                        			else
	                        			{
	                        				if(vm.loadSystemAndLocalAccountData[i].COL.split(",")[0].includes('_US'))
	                        					vm.loadSystemAndLocalAccountData[i].COL = vm.loadSystemAndLocalAccountData[i].COL.split(",")[1];
	                        				else
	                        					vm.loadSystemAndLocalAccountData[i].COL = vm.loadSystemAndLocalAccountData[i].COL.split(",")[0];
	                        			}
	                        				
	                        		}
	                        		
	                        	}
	                        	if(vm.loadSystemAndLocalAccountData[i].FORM == '8865' && vm.loadSystemAndLocalAccountData[i].SCHEDULE == 'N')
	                        		form8865_n_count++;
	                        }
	                        if(form5471_m_count == 0)
	                        {
	                        	var tempObj = {
	                        			"ROW_ORDER" : "1",
	                        		    "SYS_ACCT_KEY" : vm.rowData.SYSTEM_ACCT_KEY,
	                        		    "FORMS" : "5471",
	                        		    "FORM" : "5471",
	                        		    "SCHEDULE" : "M",
	                        		    "LINE" : "",
	                        		    "LINE_DESC" : "",
	                        		    "COL" : "",
	                        		    "FROM_CHART_KEY" : null
	                        	}
	                        	vm.loadSystemAndLocalAccountData.push(tempObj);
	                        }
	                        if(form8858_m_count == 0)
	                        {
	                        	var tempObj = {
	                        			"ROW_ORDER" : "2",
	                        		    "SYS_ACCT_KEY" : vm.rowData.SYSTEM_ACCT_KEY,
	                        		    "FORMS" : "8858",
	                        		    "FORM" : "8858",
	                        		    "SCHEDULE" : "M",
	                        		    "LINE" : "",
	                        		    "LINE_DESC" : "",
	                        		    "COL" : "",
	                        		    "FROM_CHART_KEY" : null
	                        	}
	                        	vm.loadSystemAndLocalAccountData.push(tempObj);
	                        }
	                        if(form8865_n_count == 0)
	                        {
	                        	var tempObj = {
	                        			"ROW_ORDER" : "3",
	                        		    "SYS_ACCT_KEY" : vm.rowData.SYSTEM_ACCT_KEY,
	                        		    "FORMS" : "8865",
	                        		    "FORM" : "8865",
	                        		    "SCHEDULE" : "N",
	                        		    "LINE" : "",
	                        		    "LINE_DESC" : "",
	                        		    "COL" : "",
	                        		    "FROM_CHART_KEY" : null
	                        	}
	                        	vm.loadSystemAndLocalAccountData.push(tempObj);
	                        }
                        }
                    } else {
                        if (data.errorMessage === "no access") {
                            AlertService
                                .add(
                                    "",
                                    "Sorry you do not have access to do the requested action.",
                                    4000);
                            vm.userMessage = "!! Unable to perform Selected Action...";
                        } else {
                            AlertService
                                .add(
                                    "",
                                    "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                                    4000);
                            vm.userMessage = "!! Unable to perform Selected Action...";
                        }
                    }

                });
        }





        function getDefaultSystemAcctValues() {
            var params = {
                "action_code": 'uoezbr',
                "scenario": GlobalService.globalParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key,
                "tax_year": GlobalService.globalParams.tax_year,
                "source_sys": vm.rowData.SOURCE_SYSTEM,
                "combination_key": vm.rowData.COMBINATION_KEY,
                "local_acct": vm.rowData.LOCAL_ACCT,
                "local_chart_key": vm.rowData.LOCAL_CHART_KEY

            }
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage !== 'null' && data.errorMessage.length > 0) {
                    AlertService.add("error", data.errorMessage);
                    return false;
                } else {
                    vm.repoint_data = data.jsonObject;
                    if (vm.repoint_data.length > 0) {
                        vm.repointList = [];
                        var temp_group_key = null;
                        angular.forEach(vm.repoint_data, function (sys_acc_data, key) {
                            vm.Repoint.SYSC_ACCT = sys_acc_data.SYS_ACCT;
                            vm.Repoint.AMMOUNT = Number(sys_acc_data.AMOUNT);
                            vm.Repoint.ADJUSTMENT_DESC = sys_acc_data.ADJUSTMENT_DESC;
                            vm.Repoint.SYS_ACCT_KEY = sys_acc_data.SYS_ACCT_KEY;
                            vm.Repoint.TRANS_HEADER_KEY = sys_acc_data.TRANS_HEADER_KEY;
                            vm.Repoint.TRANS_DETAILS_KEY = sys_acc_data.TRANS_DETAILS_KEY;
                            vm.Repoint.IRS_Form = sys_acc_data.FORM;
                            vm.Repoint.IRS_SCHEDULE = sys_acc_data.SCHEDULE;
                            vm.Repoint.IRS_LINE = sys_acc_data.LINE;
                            vm.Repoint.CHECK_COL = sys_acc_data.COL;
                            vm.Repoint.IRS_LINE_DESC = sys_acc_data.LINE_DESC;
                            vm.OLD_DESC = vm.Repoint.ADJUSTMENT_DESC;
                            vm.Repoint.REPOINT_ACCT_KEY = sys_acc_data.REPOINT_ACCT_KEY;
                            vm.Repoint.MAIN_ACCT_Y_N = sys_acc_data.MAIN_ACCT_Y_N;
                            vm.Repoint.DISABLED = false;
                            //vm.Repoint.CHECKED = getCheckedFlag(sys_acc_data.REPOINT_ACCT_KEY, vm.repoint_data);
                            vm.Repoint.DELETE_BTN = (temp_group_key !== sys_acc_data.GROUP_KEY ? true : false);
                            vm.Repoint.GROUP_KEY = sys_acc_data.GROUP_KEY;
                            vm.group_key = sys_acc_data.GROUP_KEY;
                            
                            temp_group_key = sys_acc_data.GROUP_KEY;

                            vm.repointList.push({
                                SYSC_ACCT: vm.Repoint.SYSC_ACCT,
                                SYS_ACCT_KEY: vm.Repoint.SYS_ACCT_KEY,
                                TRANS_HEADER_KEY : vm.Repoint.TRANS_HEADER_KEY, 
                                TRANS_DETAILS_KEY: vm.Repoint.TRANS_DETAILS_KEY,
                                IRS_Form: vm.Repoint.IRS_Form,
                                IRS_SCHEDULE: vm.Repoint.IRS_SCHEDULE,
                                IRS_LINE : vm.Repoint.IRS_LINE,
                                CHECK_COL: vm.Repoint.CHECK_COL,
                                IRS_LINE_DESC : vm.Repoint.IRS_LINE_DESC,
                                //CHECK_SYS_MAP_ACCT: vm.Repoint.CHECK_SYS_MAP_ACCT,
                                LOOK_UP_BTN: vm.repointList.LOOK_UP_BTN,
                                AMMOUNT: vm.Repoint.AMMOUNT,
                                REPOINT_ACCT_KEY : vm.Repoint.REPOINT_ACCT_KEY,
                                MAIN_ACCT_Y_N : vm.Repoint.MAIN_ACCT_Y_N,
          	                	DISABLED: vm.Repoint.DISABLED,
          	                	//CHECKED : vm.Repoint.CHECKED,
          	                	DELETE_BTN : vm.Repoint.DELETE_BTN,
          	                	GROUP_KEY : vm.Repoint.GROUP_KEY
                            });

                            vm.addToJson(vm.Repoint, true);
                        });
                        vm.Repoint.SYSC_ACCT = '';
                        vm.Repoint.AMMOUNT = '';
                        vm.Repoint.SYS_ACCT_KEY = '';
                        vm.Repoint.TRANS_HEADER_KEY = '';
                        vm.Repoint.IRS_Form = '';
                        vm.Repoint.IRS_SCHEDULE = '';
                        vm.Repoint.CHECK_COL = '';
                        vm.Repoint.IRS_LINE = '';
                        vm.Repoint.IRS_LINE_DESC = '';
                        vm.repointAmt();
                    }
                }
            })
            console.log('repointList-------', vm.repointList);
        }
        function getCheckedFlag(rp_acct_key, lookupData){
        	var arr = [];
        	lookupData.forEach(function(object, index){
        		if(object.REPOINT_ACCT_KEY == rp_acct_key)
        			arr.push(object.REPOINT_ACCT_KEY);
        	});
        	if(arr.length > 1)
        		return true;
        	else
        		return false;
        }
        
        function getSystemAcctLookUP() {
            var params = {
                "action_code": 'zdfsb6',
                "screen_key": rowData.SCREEN_KEY,
                "acct_type": rowData.SYSTEM_ACCT_TYPE
            }
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                    return false;
                } else {
                	
                    vm.sys_acc_lookup_data = data.jsonObject;
                    for (var index_ in data.jsonObject) {
                    	var index = vm.loadSystemAndLocalAccountData.map(function(e){ return e.FORM; }).indexOf(data.jsonObject[index_].FORM);
                    	if(index != -1)
                    		vm.loadLookupForm.push(data.jsonObject[index_].FORM);
                    }
                    vm.loadLookupForm = _.uniq(vm.loadLookupForm, JSON.stringify);
                    var lookupData = angular.copy(vm.sys_acc_lookup_data);
                    for(var i=0; i < vm.repointList.length; i++)
                    {
                    	vm.repointList[i].loadLookupSchedule = filterDataByKey("FORM", 'SCHEDULE', lookupData, vm.repointList[i].IRS_Form, '');
                    	
                    	vm.repointList[i].loadLookupLineDesc = filterDataByKey("SCHEDULE", 'LINE_DESC', lookupData, vm.repointList[i].IRS_SCHEDULE, vm.repointList[i].IRS_Form);
                    	
                    	vm.repointList[i].loadLookupColumn = filterDataByKey("LINE_DESC", 'COL', lookupData, vm.repointList[i].IRS_LINE_DESC, vm.repointList[i].IRS_SCHEDULE);
                    }
                    vm.loadLookUpData = true;
                    return data.jsonObject;
                }
            })

        }
        vm.changeLine = function(repointObj)
       {
        	repointObj.IRS_LINE = repointObj.IRS_LINE_DESC.split("-")[0].replace(/^\s+|\s+$/g, '');
       }
        
        vm.getLookupNext = function (key, nextLookupKey, repointObj) {
            var lookupData = angular.copy(vm.sys_acc_lookup_data);
            var a = 'SYS_ACCT';
            switch (key) {
                case 1:
                    repointObj.loadLookupSchedule = filterDataByKey("FORM", nextLookupKey, lookupData, repointObj.IRS_Form, '');
                    break;
                case 2:
                    repointObj.loadLookupLineDesc = filterDataByKey("SCHEDULE", nextLookupKey, lookupData, repointObj.IRS_SCHEDULE, repointObj.IRS_Form);
                    
                    //repointObj.loadLookupLine = filterDataByKey("SCHEDULE", "LINE", lookupData, repointObj.IRS_SCHEDULE);
                    break;
                case 3:
                    repointObj.loadLookupColumn = filterDataByKey("LINE_DESC", nextLookupKey, lookupData, repointObj.IRS_LINE_DESC, repointObj.IRS_SCHEDULE);
                    
                    break;
                case 4:
                    repointObj.loadLookupSysMapAcct = filterDataByKey("COL", nextLookupKey, lookupData, repointObj.CHECK_COL, '');

                    break;
                case 5:
                    repointObj.loadLookupSysMapAcct = filterDataByKey("LINE", nextLookupKey, lookupData, repointObj.CHECK_COL, '');
                    break;
            }

        }
       
        function filterDataByKey(currentLookupKey, nextLookupKey, lookupData, currentLookupValue, preLookUpValue) {
            var nextLookupData = [];

            var currentLookupFilter = lookupData.filter(function (object, index) {
            	if(nextLookupKey == 'SCHEDULE')
            	{
            		var uniqueSched = [];
            		for(var k = 0; k < vm.loadSystemAndLocalAccountData.length; k++)
            		{
            			if(vm.loadSystemAndLocalAccountData[k].FORM == currentLookupValue)
            				uniqueSched.push(vm.loadSystemAndLocalAccountData[k].SCHEDULE);
            		}
            		
            		if(object[currentLookupKey] == currentLookupValue && _.indexOf(uniqueSched, object['SCHEDULE']) != -1) 
            			return true;
            		
            	}
            	else if(nextLookupKey == 'LINE_DESC')
            	{
            		if (object[currentLookupKey] == currentLookupValue && object['FORM'] == preLookUpValue)
            			return true;
            	}
            	else if(nextLookupKey == 'COL')
            	{
            		if (object[currentLookupKey] == currentLookupValue && object['SCHEDULE'] == preLookUpValue)
            			return true;
            	}
            	else
            	{
            		if(object[currentLookupKey] == currentLookupValue) 
            			return true;
                }
            });

            currentLookupFilter.forEach(function (object, index) {
            	
            		nextLookupData.push(object[nextLookupKey]);
            });
            nextLookupData = _.uniq(nextLookupData, JSON.stringify);
            if(nextLookupKey == 'LINE_DESC')
            {
            	//TO SHOW LINE LIST IN ASCENDING ORDER of LINE NO 
            	nextLookupData = _.sortBy(nextLookupData, function(line) {return parseInt(line.split("-")[0].match(/(\d+)/)); });
            }
            lookupData = currentLookupFilter;
            return nextLookupData;
        }

    }
    
    return controllers;

});
