define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.WorkflowService', [])

        .factory('WorkflowService', ['$rootScope', '$http', 'AlertService', 'GlobalService', 'JsonObjectFactory', 'GENERAL_CONFIG', '$uibModal',
            function ($rootScope, $http, AlertService, GlobalService, JsonObjectFactory, GENERAL_CONFIG, $uibModal) {
                var WorkFlowFactory = {};

                // used to store the state parmas id in service level.
                WorkFlowFactory.selectedId = [];
                WorkFlowFactory.settingId = function(id) {
                    WorkFlowFactory.selectedId = id;
                }

                WorkFlowFactory.allowGlobalMes = true;

                WorkFlowFactory.workflowMes = [];
                WorkFlowFactory.setWorkflowMes = function(mes) {
                    WorkFlowFactory.workflowMes = mes;
                }

                WorkFlowFactory.list = [
                    {id: 1, name: "Superman", powers: "All", age: 1087, height: "6'1", weight: "1200"},
                    {id: 2, name: "The Incredible Hulk", powers: "Strength", age: 54, height: "6'3", weight: "400"},
                    {id: 3, name: "Batman", powers: "Inventiveness", age: 44, height: "6'1", weight: "210"}
                ]

                WorkFlowFactory.states = {};
                WorkFlowFactory.saveState = function(state) {
                    return WorkFlowFactory.states = state;
                }

                WorkFlowFactory.getList = function () {
                    return WorkFlowFactory.list;
                }

                WorkFlowFactory.openPdfPopup = function (formName, leId, cdrNo, rptPeriod, taxYear, leType, systemFlag) {
                    $uibModal.open({
                        animation: true,
                        template: "<div class='modal-header'><div class='modal-title'>Form " + formName + "<i class='fa fa-times pull-right' role='button' uib-tooltip='Close' ng-click='uibModalInstance.close()'></i><i     role=\"button\" uib-tooltip=\"Expand\" tooltip-placement=\"left\" tooltip-trigger=\"mouseenter\" style=\"padding-right:5px\" class=\"fa fa-expand pull-right\" id='expand-pdf'></i></div></div><div class='modal-body'>" +
                            "<pdf-preview le-id='leId' form-name='formName' div-height=\"'80vh'\" tax-year='taxYear' cdr-no='cdrNo' rpt-period='rptPeriod' leType='leType' systemFlag='systemFlag'></pdf-preview></div>",
                        controller: ['$scope', '$uibModalInstance', 'leId', 'cdrNo', 'rptPeriod', 'taxYear', 'leType', 'systemFlag', function ($scope, $uibModalInstance, leId, cdrNo, rptPeriod, taxYear, leType, systemFlag) {
                            $scope.leId = leId;//"LZ0007";
                            $scope.formName = formName;//"1120L";
                            $scope.rptPeriod = rptPeriod;//"1120L";
                            $scope.taxYear = taxYear;//"1120L";
                            $scope.cdrNo = cdrNo;//"1120L";
                            $scope.leType = leType;
                            $scope.systemFlag = systemFlag;
                            $scope.uibModalInstance = $uibModalInstance;

                        }],
                        size: 'lg',
                        // backdrop: 'static',
                        resolve: {
                            leId: function () {
                                return leId;
                            },
                            cdrNo: function () {
                                return cdrNo;
                            },
                            rptPeriod: function () {
                                return rptPeriod;
                            },
                            taxYear: function () {
                                return taxYear;
                            },
                            leType: function () {
                                return leType;
                            },
                            systemFlag: function () {
                                return systemFlag;
                            }
                        }
                    });
                }

                WorkFlowFactory.getWFEntityMapping = function (params) {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/getWorflowEntity',
                        data: params
                    }).then(function (response) {
                        return response;
                    });

                    return promise;
                };
                WorkFlowFactory.getFilingGroup = function(params){
                    var promise = $http({
                        method: "GET",
                        url: GENERAL_CONFIG.base_url + '/loadJsonObject?action_id=762&tax_year=' + params.tax_year  + '&scenario=' + params.scenario + '&jcd_key=' + params.jcd_key,
                        data: params
                    }).then(function (response) {
                        return response;
                    });

                    return promise;
                }
                WorkFlowFactory.getEntityByID = function (params) {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/getEntityDetailsById',
                        data: params
                    }).then(function (response) {
                        return response;
                    });

                    return promise;
                };

                WorkFlowFactory.fillFormPorforma = function (formSub = '', irsFormName = '', formName, entity, s3_url,form_sub_cat, tax_year, cdr_no, rpt_period, le_type, system_flag, leName, ein = '', schedule = '', line = '', partNo = 'NA', selectedEntityType = '', selectedConsolGroup = '') {
                    if(le_type === 'PSHIP'){
                        le_type = 'HO';
                    }
                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.pdf_engine_api_url + '/fillFormPorforma?irs_parent_form_no='+irsFormName+'&irs_form_no=' + formName + "&s3_url=" + s3_url+ "&form_sub_cat="+form_sub_cat+ "&form_sub="+formSub + "&entity=" + entity +
                            '&tax_year=' + GlobalService.globalParams.tax_year + '&cdr_no=' + cdr_no + '&rpt_period=' + rpt_period + '&le_type=' + le_type + '&system_flag=' + system_flag +
                            '&jcdKey=250' + '&scenario=' + GlobalService.globalParams.scenario + '&leName=' + leName + '&ein=' + ein + '&schedule=' + schedule + '&line=' + line + '&part=' + partNo + '&entity_type=' + selectedEntityType + '&cgkey=' + selectedConsolGroup,
                        responseType: 'arraybuffer'
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.fillFormAndMergePorforma = function (jobId = '', isNewProforma, formSub, irsParentFormList, irsFormList, s3UrlList, form_sub_cat, entity, taxYear, cdrNo, rptPeriod, leType, systemFlag, leName, ein, schedule, line, partNo, selectedEntityType = '', selectedConsolGroup = '') {
                    if(leType === 'PSHIP'){
                        leType = 'HO';
                    }
                    var promise = $http({
                        method: 'post',
                        url: GENERAL_CONFIG.pdf_engine_api_url + '/fillFormAndMergePorformaAsync?irs_parent_form_no_list=' + irsParentFormList + '&irs_form_no_list=' + irsFormList +  "&form_sub="+formSub  + '&entity=' +
                            entity + '&tax_year=' + GlobalService.globalParams.tax_year + '&cdr_no=' + cdrNo + '&rpt_period=' + rptPeriod + '&le_type=' + leType + '&system_flag=' + systemFlag +
                            '&jcdKey=250' + '&scenario=' + GlobalService.globalParams.scenario + '&leName=' + leName + '&ein=' + ein + '&schedule=' + schedule + '&line=' + line + '&part=' + partNo + '&jobId=' + jobId + '&is_new_proforma=' + isNewProforma + '&entity_type=' + selectedEntityType + '&cgkey=' + selectedConsolGroup,
                        responseType: 'arraybuffer'
                    }).then(function (response) {
                        return response.data;
                    });
                    return promise;
                };

                WorkFlowFactory.uploadPdf = function (pdfPath, formName) {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/uploadPdf?pdfPath=' + pdfPath + "&irs_form_no=" + formName,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.doesTaxFormExist = function (formName, taxYear, formType = '') {
                    var tax_year = taxYear || GlobalService.globalParams.tax_year;

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.pdf_engine_api_url + '/doesTaxFormExist?irs_form_no=' + formName + '&tax_year=' + tax_year + '&formType=' + formType,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.getPdfpFormS3UrlList = function (formNames, formType = '') {
                    var promise = $http({
                        method: "get",
                        url: GENERAL_CONFIG.pdf_engine_api_url + '/getPdfpFormS3UrlList?irs_form_no_list=' + formNames + '&tax_year=' + GlobalService.globalParams.tax_year + '&formType=' + formType,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.deleteTaxForm = function (formName) {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/deleteTaxForm?irs_form_no=' + formName,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.populateConsolViewDropdown = function () {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/populateConsolViewDropdown',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.populateEntityViewDropdown = function () {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/populateEntityViewDropdown',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };
                WorkFlowFactory.getViewDataForDropdown = function (srcSystemFlag, reportingPeriod, taxYear, jcdKey, scenario, letype) {
                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.pdf_engine_api_url + '/getViewForDropdown?src_system_flag=' + srcSystemFlag + '&reportingPeriod=' + reportingPeriod + '&taxYear=' + taxYear + '&jcdKey=' + jcdKey + '&scenario=' + scenario + '&leType=' + letype,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                }

                WorkFlowFactory.populateDomesticViewDropdown = function () {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/populateDomesticViewDropdown',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.populateForeignViewDropdown = function () {

                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/populateForeignViewDropdown',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };

                WorkFlowFactory.getTaxForms = function (selectedView, cdrNo, reportingPeriod, taxYear, scenario, leType, selectedEntityType, selectedConsolGroup) {
                    var promise = $http({
                        method: "post",
                        url: GENERAL_CONFIG.custom_api_url + '/getTaxForms?selectedView=' + selectedView + '&reportingPeriod=' + reportingPeriod + '&scenario=' + scenario + '&taxYears=' + taxYear + '&leType=' + leType + '&cdrNo=' + cdrNo + '&entity_type=' + selectedEntityType + '&cgkey=' + selectedConsolGroup,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(function (response) {
                        return response.data;
                    });

                    return promise;
                };


                return WorkFlowFactory


            }])


    return services;


});
