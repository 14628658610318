define(["angular"], function () {
  "use strict";

  var controllers = angular
    .module("app.s3ManagementCtrl", [])
    .controller("s3ManagementUploadCtrl", [
      "GENERAL_CONFIG",
      "rowData",
      "USER_SETTINGS",
      "SERVER_CONFIG",
      s3ManagementUploadCtrl,
    ]);

  function s3ManagementUploadCtrl(GENERAL_CONFIG, rowData, USER_SETTINGS,SERVER_CONFIG) {
    const vm = this;

    vm.s3FileUploadInput = JSON.stringify({
      fileKey: rowData?.FILE_KEY,
      baseUrl: GENERAL_CONFIG.admin_api_url,
      ssoId: USER_SETTINGS.user.sso_id,
      clientKey: rowData ?
         ((rowData.S3_CLIENT_KEY === null || rowData.S3_CLIENT_KEY === undefined) ? '' : rowData.S3_CLIENT_KEY)
          : USER_SETTINGS.user.client_key,
      path:rowData?.FILE_PATH || '',
      readOnlyS3Paths: SERVER_CONFIG.configSettings.read_only_s3_paths,
      canEditPath:true,
      onlyCore:(rowData && (rowData.S3_CLIENT_KEY === null || rowData.S3_CLIENT_KEY === undefined)) ? true : false
    });
  }
});
