/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.clientLandingService',[])

        .factory("clientLadingFactory",['$location', '$state', '$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory', 'USER_MENU', 'GENERAL_CONFIG', 'menuDataService', function($location, $state, $q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory, USER_MENU, GENERAL_CONFIG, menuDataService) {
            var factory = {};
            factory.searchLink = false;

            factory.getClientLandingDtls = function(_url,_params){

                // getCommentByContext

                 var promise = $http({
                     method: "post",
                     url: _url,
                     data: _params
                 }).then(function (response) {
                     return response.data;
                 });


                 return promise;
            };

            factory.checkStateLink = function(stateLink) {
                _.forEach(USER_MENU, function(eachMenuItem) {
                    _.filter(eachMenuItem.children, function(childMenuItem) {
                        _.filter(childMenuItem.children, function(child) {
                            _.find(child.screens, function(itemLink) {
                                if (itemLink.link == stateLink) {
                                    factory.searchLink = true;
                                }
                            });
                        });
                    });
                });
            }

            factory.landingPage = function(state, modalStateLink) {
                $http.get(GENERAL_CONFIG.base_url + '/getUserNavigator').then(function (res) {
                    var menuData =  res.data;
                    USER_MENU = menuDataService.setData(menuData);
                    
                    //Directly route to DST Home Page if access to only DST.
                    if(menuData.length >0 && menuData[0].id == '1060'){
                        // $location.path('workspace.dst-home-page'); // commented as dst homepage isn't moved to production and this change is done part of RM-19168
                        $location.path(state);
                    }else{
                        $location.path(state);
                    }
                    setTimeout(()=> {
                            location.reload();
                    })
                    // factory.checkStateLink(modalStateLink);

                    // // Modules assigned to the user has the specfic route, will take to that specific route..
                    // if(USER_MENU && USER_MENU.length > 0 && factory.searchLink == true) {
                    //   $location.path(state);
                    //   setTimeout(()=> {
                    //     location.reload();
                    //     })

                    // // Modules assigned to user, but did not find the specific route requested from the modal will take to first available route from his user menu object..
                    // } else if(USER_MENU && USER_MENU.length > 0 && factory.searchLink == false) {
                    //   var defaultLinkModal = USER_MENU[0].children[0].children[0].screens[0].link;
                    //   $state.go(defaultLinkModal);
                    //   setTimeout(function() {
                    //     location.reload();
                    //   }, 1000);

                    // //No modules assigned to a user, will go to No modules page..
                    // //Now, handling this check, in controller itslef, keeping this for safeside.
                    // } else if(USER_MENU.callSuccess == "0" && factory.searchLink == false) {
                    //   $state.go("no-modules");
                    //   setTimeout(function() {
                    //     location.reload();
                    //   }, 1000);
                    // }

                    $rootScope.$broadcast('client-landing-modal-close');

                  });
            }

            return factory;

        }])
        return services;


    });