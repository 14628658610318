define([
	'angular'
], function () {
	'use strict';
	

	var controllers = angular.module('app.ghostftceNonghostScreenCtrl',  [])
		.controller('ghostftceNonghostScreenCtrl', [ 'CommonUtilitiesFactory','workspaceFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','USER_SETTINGS','firghostftceNonghostScreenFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', ghostftceNonghostScreenCtrl])


	
		 .controller('ghostftceNonghostControllerModal', ['$rootScope','workspaceFactory', '$scope', '$filter', 'AlertService', 'CodeComboFactory', 'JsonObjectFactory',
	            'firghostftceNonghostScreenFactory', 'GENERAL_CONFIG', 'ModalFactory', 'rowData', 'colData', ghostftceNonghostControllerModal
	        ]);


	    function ghostftceNonghostControllerModal($rootScope, workspaceFactory,$scope, $filter, AlertService, CodeComboFactory, JsonObjectFactory,
	    		firghostftceNonghostScreenFactory, GENERAL_CONFIG, ModalFactory, rowData, colData) {
	    	ghostftceNonghostScreenCtrl($rootScope, $scope, $filter, AlertService, CodeComboFactory, JsonObjectFactory,
	        		firghostftceNonghostScreenFactory, GENERAL_CONFIG, ModalFactory, null, rowData, colData, this);
	    }
	    function ghostftceNonghostScreenCtrl(CommonUtilitiesFactory,workspaceFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
	    			   GlobalService,USER_SETTINGS,firghostftceNonghostScreenFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		var vm = this;
        vm.rowData = rowData ||$scope.$parent.rowData;
        vm.colData = colData;
        vm.gridData = gridData;
        vm.globalParams = GlobalService.globalParams;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        console.log("Here is the starting point and the rowData was ::::::::", vm.rowData);
        console.log("Here is the starting point and the global Params was ::::::::", vm.globalParams);
        vm.tax_year = vm.globalParams.tax_year;
       /* vm.ho_leid = vm.rowData.HO_LEID;
        vm.ho_cdr_no = vm.rowData.HO_CDR_NO;
        vm.ho_combination_key = vm.rowData.HO_COMBINATION_KEY;*/
		vm.adjAmts = [];
        vm.modalTitle = "Add New Payment";
        vm.selectedPaymentType;
        vm.Recipient = [];
       // vm.FIRRecipient = [];
        vm.paymentTypes = [];
        vm.selectedRecp = '';
       // vm.selectedFIRRecp = '';
        vm.selectedPaymentType = '';
        vm.dateSelected = null; //new Date();
        vm.amountPaid = 0;
        vm.amountPaid2 = 0;
        vm.amountReceived = -0;
        vm.amountReceived2 = 0;
        vm.withholdingTax = 0;
        vm.cash_payment = '';
        vm.country_eligible = '';;
        $scope.lazyLoadObj = {};
        $scope.lazyLoadObjRecp = {};
        $scope.local_acc_dropdown_data = [];
        $scope.local_payor_dropdown_data = [];
        vm.loadingPayor = false;
        vm.loadingRecp = false;
   
        
        vm.modalTitle = "Add New Payment";
        if (angular.isObject($uibModalInstance)) {
            vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;
            vm.cancel = function() {
                $scope.crudLoading = false;
                console.log("in cancel");
                $uibModalInstance.close();
                vm.modal_name = null;
            };
        } else {
            vm.modal_name = null;
        }
        
        vm.openCalendar = function() {
            vm.calendar.opened = true;
          };
          
          vm.calendar = {
                  opened: false
              };
        
        $scope.$watch(function() {
        	return vm.amountReceived;
        }, function(newValue, oldValue) {
        	console.log(":: ghostftceNonghostScreenCtrl $watch amountReceived", {newValue, oldValue});
        	if(!newValue) {
        		vm.amountReceived = -0
        	} else if(+newValue >= 0) {
        		vm.amountReceived = -newValue
        	}
        	console.log(":: ghostftceNonghostScreenCtrl $watch amountReceived", {newValue, oldValue});
        }, true)
        
        
       /* function getRecipient(){
        	firghostftceNonghostScreenFactory.getRecipients(vm.rowData).then(function(data){
        		console.log("The Recipients from DB was :::::::", data.jsonObject);
        		vm.Recipient = data.jsonObject;
        	});
        }
        
        function getPayor(){
        	firghostftceNonghostScreenFactory.getPayor(vm.rowData).then(function(data){
        		console.log("The Pyor from DB was :::::::", data.jsonObject);
        		vm.Payor = data.jsonObject;
        	});
        }*/
        function getRecipient(){
        	firghostftceNonghostScreenFactory.getRecipients(vm.rowData).then(function(data){
        		console.log("The Recipients from DB was :::::::", data.jsonObject);
        		vm.Recipient = data.jsonObject;
        		$scope.local_rcp_dropdown_data = vm.Recipient;
         	   	//console.log("check this out a::::::: ", $scope.local_acc_dropdown_data);
         	   	$scope.lazyLoadObjRecp.totalDrpList = $scope.local_rcp_dropdown_data;
         	   	console.log("check this out b::::::: ", $scope.lazyLoadObj.totalDrpList);
         	   	vm.loadingRecp = true;
        	   	$scope.lazyLoadObjRecp.cachedDrpList = $scope.local_rcp_dropdown_data.slice(0, 10);
        	});
        }
        
        function getPayor(){
        	firghostftceNonghostScreenFactory.getPayor(vm.rowData).then(function(data){
        		console.log("The Recipients from DB was :::::::", data.jsonObject);
        		vm.Payor = data.jsonObject;
        		$scope.local_payor_dropdown_data = vm.Payor;
         	   	//console.log("check this out a::::::: ", $scope.local_acc_dropdown_data);
         	   	$scope.lazyLoadObj.totalDrpList = $scope.local_payor_dropdown_data;
         	   	console.log("check this out payor::::::: ", $scope.lazyLoadObj.totalDrpList);
         	   	vm.loadingPayor = true;
        	   	$scope.lazyLoadObj.cachedDrpList = $scope.local_payor_dropdown_data.slice(0, 10);
        	});
        }
        function getFIRRecipient(){
        	firghostftceNonghostScreenFactory.getFIRRecipients(vm.rowData).then(function(data){
        		console.log("The FIR Recipients from DB was :::::::", data.jsonObject);
        		vm.FIRRecipient = data.jsonObject;
        	});
        }
        
        function getPaymentType(){
        	firghostftceNonghostScreenFactory.getPaymentType(vm.rowData).then(function(data){
        		console.log("The Payment Types from DB was :::::::", data.jsonObject);
        		vm.paymentTypes = data.jsonObject;
        	});        	
        }

        
        vm.disableAllFields = function() {
        	if (!vm.selectedPayor || !vm.selectedRecp || !vm.selectedRecp || !vm.dateSelected) {
        		return true;
        	}
        	return false;
        }
        vm.isAmtReceivedDisabled = function() {
        	if (!vm.disableAllFields()) {
        		return false;
        	} else {
        		return true;
        	}
        	return false;
        }
        vm.isAmtPaidDisabled = function() {
        	if (!vm.disableAllFields()) {
        		return false;
        	} else {
        		return true;
        	}
        	return false;
        }
       
       vm.iswithholdingTaxDisabled = function() {
        	if (!vm.disableAllFields()) {
        		return false;
        	} else {
        		return true;
        	}
        	return false;
        } 
      
       
       function  buildJson(){
    	    
           vm.selectedRecp = '';
           vm.selectedPayor = '';
           vm.selectedPaymentType = '';
           vm.dateSelected = new Date();
           vm.amountPaid = 0;
           vm.amountPaid2 = 0;
           vm.amountReceived = 0;
           vm.amountReceived2 = 0;
           vm.withholdingTax = 0;
           vm.cashPayment = 0;
           vm.countryEligible = 0;
  			var selectedClientsArray = [];
  			var formMappingObj = {};
  			
  			vm.paymentJson['selectedRecp']= vm.selectedRecp;
  		    vm.paymentJson['selectedPayor']= vm.selectedPayor;
  			vm.paymentJson['selectedPaymentType'] = vm.selectedPaymentType;
  			vm.paymentJson['dateSelected'] =  vm.dateSelected;
  			vm.paymentJson['amountPaid'] = vm.amountPaid;
  			vm.paymentJson['amountPaid2'] =  vm.amountPaid2;
  			vm.paymentJson['amountReceived'] =  vm.amountReceived;
  			vm.paymentJson['amountReceived2'] = vm.amountReceived2;
		    vm.paymentJson['withholdingTax'] = vm.withholdingTax;
  			vm.paymentJson['cashPayment'] =  vm.cashPayment;
  			vm.paymentJson['countryEligible'] =  vm.countryEligible;
  			 isJsonBuilt = true;
  			
  				
  		}

        getRecipient();
        getPayor();
      //  getFIRRecipient();
        getPaymentType();
           
		vm.cancel = function () {
			$scope.crudLoading = false;
    		console.log("in cancel");
    		$uibModalInstance.dismiss('cancel');
        };
        
        vm.printSelectedRecipient = function(){
        	console.log("The selcted recipient was ::::::::::", vm.selectedRecp);
        
       
        }
        
        vm.printSelectedPayor = function(){
        	console.log("The selcted recipient was ::::::::::", vm.selectedPayor);
        
       
        }
        vm.validateAmt = function(val, wtd_avg_rate, objType, fieldToUpdate, fieldName) {
        	$scope.form.rppForm[fieldName].$setValidity("invalidAmt", true);
        	 if (fieldName == 'tax_base_val' && val < 0) {
                 console.log('invalid amt ' + fieldName);
                 $scope.form.rppForm[fieldName].$setValidity("invalidAmt", false);
                 return;
             }
            convertToUS(val, wtd_avg_rate, objType, fieldToUpdate, fieldName);           
        };
        
        function convertToUS(val, wtd_avg_rate, objType, fieldToUpdate, fieldName) {

            if(objType === 'PAIDOBJ') {
                vm.amountPaid2 = Math.round(val * wtd_avg_rate);
            } else if(objType === 'RECEIVEDOBJ') {
                vm.amountReceived2 = Math.round(val * wtd_avg_rate);
            } 
            else if(objType === 'CASHDISTOBJ') {
                vm.TAX_BASE_VAL_USD = Math.round(val * wtd_avg_rate);
            } 

        };
        
        vm.formatDate = function() {
        	var payment_date_selected = null;
        	var tax_year = parseInt(workspaceFactory.activeScreen.filters.getFilterParams().tax_year || "2019") 
        	
        		if(!!vm.dateSelected && vm.dateSelected.getFullYear() > tax_year){
        			vm.date_flag = true;
        			return;
        		}
        		else{
             		vm.date_flag = false;
        		}
            
        	
        };

        
        vm.saveNewPayment = function(){
        	
        	vm.formatDate();
        	if(vm.date_flag == true || !vm.dateSelected || vm.dateSelected == null){            
            	vm.date_flag =true;
            	 AlertService.add("error", "Please enter valid date",4000);
            	 return;
        	} else{
        	var tempPaymentDate =  $filter('date')(vm.dateSelected, 'MM/dd/yyyy');
            	 }
        	console.log(":: saveNewPayment ::", vm, this)
        	vm.rowData =  vm.rowData || {};
        	var selectedRecp = vm.selectedRecp || {}
        	var selectedPayor = vm.selectedPayor || {}
        	var paymentJson = [{
            	"tax_year" 			: workspaceFactory.activeScreen.filters.filterParams.tax_year,       
            	"payor_leid"	    : selectedPayor.LEGAL_ENTITY_ID,
                "payor_cdr_no"	    : selectedPayor.GECC_ENTITY_ID,                                                           
                "filing_group"		: workspaceFactory.activeScreen.filters.filterParams.filing_key,
                "scenario"     		: workspaceFactory.activeScreen.filters.filterParams.scenario,
                "recipient_leid"	: selectedRecp.LEGAL_ENTITY_ID,
                "recipient_cdr_no"	: selectedRecp.GECC_ENTITY_ID,                  
                "payment_type"  	: vm.selectedPaymentType.PAYMENT_TYPE,
                "include"        	: "Y",
                "payment_source"  	: "M",
                "payment_date"      : tempPaymentDate,
                "cash_payment" 		: vm.cash_payment ==='' && vm.selectedPaymentType.PAYMENT_TYPE === "DIVIDEND" ? 'Y'
                					 :vm.cash_payment === '' && vm.selectedPaymentType.PAYMENT_TYPE !== "DIVIDEND"? 'N':vm.cash_payment,
                "amt_paid"     		: vm.amountPaid,     
                "amt_received" 		: vm.amountReceived,
                "tax_amt"      		: vm.withholdingTax,    
                "us_foreign_ind"	: "U",
                "same_country"   	: vm.country_eligible ===''?'N':vm.country_eligible,   
                "payment_sub_type" 	: vm.selectedPaymentType.PAYMENT_SUB_TYPE,  
                "ho_cpaymentey" : vm.rowData.HO_COMBINATION_KEY,
                "tax_basis_amt" :vm.TAX_BASE_VAL
            }];
        	//validateJson(paymentJson);
			//vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
			vm.clob_settings = [{tax_year:workspaceFactory.activeScreen.filters.getFilterParams().tax_year,sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
        	var success_message = "The new Recipient was successfully added";
			console.log("The save json data was :::::",paymentJson);
			/* checkLock obj for locking 12/11/2020 */
			var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
			var checkLockObj =[];
			checkLockObj.push({consol_group_key:filterParams.filing_key});
			/*  */
        	firghostftceNonghostScreenFactory.addNewPayment(paymentJson, vm.clob_settings, vm.logged_in_user,checkLockObj).then(function(data){
        		console.log("Result from rep Trans save data was ---------------::::", data);
        		if(null != data.data.errorMessage && data.data.errorMessage != "null"){
        			console.log("There was the error message and the message is ", data.data.errorMessage);
        			AlertService.add("error", data.data.errorMessage, 4000);
        		}else {
        			console.log("There wa no error message and the message was ", data.data.errorMessage);
        			AlertService.add("success", success_message, 4000);
        			$uibModalInstance.dismiss('cancel');
        			var args = {combination_key: vm.rowData.HO_COMBINATION_KEY, gridFilter: {combination_key: vm.rowData.HO_COMBINATION_KEY,object_id:vm.rowData.OBJECT_ID,}};
        			$rootScope.$emit('gridUpdate', args);
        		}
        	});
        }
        		
	}
  });        