/**
 * Created by 212544474 on 4/1/2016.
 */
define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.methodSettlementController',[])
        .controller('MethodSettlementController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData' ,'JsonObjectFactory','$timeout','MethodSettlementFactory','workspaceFactory','GENERAL_CONFIG', methodSettlementController])
        .controller('GofSettlementController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData' ,'JsonObjectFactory','$timeout','MethodSettlementFactory','workspaceFactory','GENERAL_CONFIG', gofSettlementController])


    function gofSettlementController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, JsonObjectFactory,$timeout,MethodSettlementFactory,GENERAL_CONFIG) {


        var vm = this;
        vm.title = "Gof Settlement";
        vm.bankerArray = [];
        vm.loading = true;
        vm.selectedBanker = null;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };



        (function isLocked(){
            if( rowData.Is_Locked_0 && rowData.Is_Locked_0 === 1 ){
                $timeout(function() {
                    AlertService.add("warning", "This LEID / Division combination is locked", 4000);
                    vm.cancel();
                },30);
            }
        })();


        function getBankerArray(){
            var params =  {leid:rowData.LEID,division:rowData.Division};
            MethodSettlementFactory.getBankerArray(params).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                    vm.loading = false;
                }else{
                    vm.bankerArray = result.jsonObject;
                    vm.loading = false;
                    vm.selectedBanker = _.find( result.jsonObject, {'BANKER':1});
                }
            });

        }


        getBankerArray();

        vm.save = function () {
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            vm.crudLoading = true;

            var params =  {leid:vm.selectedBanker.LEID,division:vm.selectedBanker.DIVISION};


            MethodSettlementFactory.setBanker(params).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    vm.crudLoading = false;
                    AlertService.add("success", "Banker has been set " , 1000);
                    $uibModalInstance.dismiss('cancel');
                    var args = {leid:rowData.LEID};
                    $rootScope.$emit('gridUpdate', args);
                }
            });

        };






    }




    function methodSettlementController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData, JsonObjectFactory,$timeout,MethodSettlementFactory,workspaceFactory,GENERAL_CONFIG){


        var vm = this;
        vm.title = "Method Of Settlement";
        vm.crudLoading = false;
        vm.settlements = [];
        vm.amountToSettle = rowData.Settlement__Outflow__Inflow_0 ? rowData.Settlement__Outflow__Inflow_0 : 0;
        vm.settleAmount = 0;
        vm.loading = false;

        vm.disabled = {
            "cCode": false,
        }








        ////console.log(" =========================================================================:  ");
        ////console.log(" rowData:  " ,  rowData);
        ////console.log(" =========================================================================:  ");

        vm.settlement = {
            type:"",
            buc:"",
            adn:"",
            amount:"",
            erp:"",
            company_code:"",
            me:"",
            leid:"",
            sys_desc:"",
            cCodeSet:false
        }

        vm.types = [
            {
                value: 'IBS',
                label: 'IBS'
            },
            {
                value: 'ACA',
                label: 'ACA'
            }
        ];

        vm.erp = [
            {
                value: 'CCL',
                label: 'CCL'
            },
            {
                value: 'NAV',
                label: 'NAV'
            },
            {
                value: 'ORA',
                label: 'ORA'
            },
            {
                value: 'Other',
                label: 'Other'
            }
        ];



        (function isLocked(){
            if( rowData.Is_Locked_0 && rowData.Is_Locked_0 === 1 ){
                $timeout(function() {
                    AlertService.add("warning", "This LEID / Division combination is locked", 4000);
                    vm.cancel();
                },30);
            }
        })();




        vm.addSettlement = function () {
            vm.settlements.push(_.clone(vm.settlement))
        };

        vm.removeSettlement = function(_index){
            vm.settlements.splice(_index,1);
            setSettleAmount();

        }


        function getSettlement(){
            vm.loading = true;
            var returnObj = {};
            returnObj.leid = rowData.LEID;
            returnObj.division = rowData.Division;
            returnObj.object_id = rowData.object_id;


            MethodSettlementFactory.getSettlementArray(returnObj).then(function (result) {
                vm.loading = false;
                if(result.callSuccess !== "1" ){
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                   if( result.jsonObject &&  result.jsonObject.length > 0 ){
                       setSetSettlement(result.jsonObject);

                       ////console.log("result.jsonObject : " , result.jsonObject)

                       setSettleAmount();
                   }else{
                       vm.addSettlement()
                       setSettleAmount();
                   }
                }
            });


        }


        vm.clearSelection = function(settlement){
            settlement.cCodeSet = false;
            settlement.company_code = "";
            settlement.foreign_curr = "";
        }

        vm.setCompanyCode = function (settlement) {
            settlement.foreign_curr =  settlement.company_code.CUR;
            settlement.cCodeSet = true;
        };


            vm.deleteSettlement = function () {
           // settlement.cCodeSet = true;

            var params = {
                "action_id": 38,
                "leid" : rowData.LEID,
                "division" : rowData.Division
            }

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge(params, GlobalService.globalParams, filterParams);

           // //console.log("params" , params)

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                }else{
                    var args = {leid:rowData.LEID};
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Method of Settlement was deleted" , 3000);
                    $uibModalInstance.dismiss('cancel');
                }
            });

        };



        vm.getCompanyCodes = function(val) {
            var params = {
                "action_id": 37,
                "search_string": val
            }

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {

                       // //console.log(" data" , data);
                        return data.jsonObject;
                    }
                }
            });
        };


        function setSetSettlement(_array){

            ////console.log("_array: " , _array );


            _.forEach(_array, function(value, key) {



                //console.log("value " , value);

                var settlement = {
                    type: _.find(vm.types, ['value', value.SETTLEMENT_TYPE]),
                    buc:( value.BUC_CODE ) ? value.BUC_CODE : "",
                    adn:( value.ADN_CODE ) ? value.ADN_CODE : "",
                    company_code:( value.COMPANY ) ? {NAME:value.COMPANY} : "",
                    erp: _.find(vm.erp, ['value', value.ERP]),
                    foreign_curr: (value.FOREIGN_CURR) ? value.FOREIGN_CURR : "",
                    me:( value.ME_CODE ) ? value.ME_CODE : "",
                    leid:( value.LE_CODE ) ? value.LE_CODE : "",
                    amount:value.SETTLEMENT_AMT,
                    sys_desc:( value.SYS_DESC ) ? value.SYS_DESC : "",
                    cCodeSet: false
                }


                if( settlement.erp && settlement.erp.value == 'CCL' ){
                    settlement.cCodeSet = true;
                }



                vm.settlements.push(_.clone(settlement))
            });

            ////console.log("vm.settlements " , vm.settlements);

        }




        function setSettleAmount(){


            var num = 0;
            for (var i=0; i < vm.settlements.length; i++) {
               var amount = vm.settlements[i].amount;
                if(amount && amount != ""  ){
                     num +=  parseFloat(amount);
                 }
            }


            vm.settleAmount = parseFloat(num - vm.amountToSettle).toFixed(2);
        }


        vm.onChange =  function (){
            setSettleAmount();
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };




        vm.save = function () {


            ////console.log("vm.settlements", vm.settlements)


            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            if (vm.addressFrom.$invalid) {
                vm.settlementFrom.$submitted = true;
                AlertService.add("danger", "Please correct the errors below", 4000);
                return;
            }

            vm.crudLoading = true;
            var returnObj = {};
            returnObj.leid = rowData.LEID;
            returnObj.division = rowData.Division;
            returnObj.object_id = rowData.object_id;
            returnObj.message = "Method Of Settlement was updated"

             var settlementArray = _.cloneDeep(vm.settlements);

            _.forEach(settlementArray, function(object) {
                object.type = object.type.value;
                object.buc = ( object.type === 'IBS' ) ? object.buc : "";
                object.adn = ( object.type === 'IBS' ) ? object.adn : "";
                object.company_code = ( object.type !== 'IBS' && object.erp.value === 'CCL' ) ? object.company_code.NAME : "";
                object.leid = ( object.type !== 'IBS' && object.erp.value !== 'CCL' ) ? object.leid : "";
                object.me = ( object.type !== 'IBS' && object.erp.value !== 'CCL' ) ? object.me : "";
                object.erp = ( object.type !== 'IBS' ) ? object.erp.value : "";
                object.sys_desc = (object.erp === 'Other') ? object.sys_desc : "";
            });



           settlementCRUDHttps(returnObj,settlementArray);
        };




        vm.validateBucAdn = function(_index){
            var params = {buc_code:vm.settlements[_index].buc,adn_code:vm.settlements[_index].adn}
           if( (!vm.settlements[_index].buc || vm.settlements[_index].buc.length === 0) || ( !vm.settlements[_index].adn || vm.settlements[_index].adn.length === 0  ) ){
                return false;
            }

            vm.settlements[_index].valid = 'loading';
            MethodSettlementFactory.validateBucAdn(params).then(function (result) {
                if( result.callSuccess === "1"){
                    vm.settlements[_index].valid = true;
                }else{
                    vm.settlements[_index].valid = false;
                }
            })
        }



        function settlementCRUDHttps(_returnObj,_settlementArray){
           MethodSettlementFactory.saveSettlementArray(_returnObj,_settlementArray).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    AlertService.add("success", _returnObj.message , 4000);
                    vm.crudLoading = false;
                    var args = {leid:rowData.LEID};
                    $rootScope.$emit('gridUpdate', args);
                    //init();
                }
            });
        }




        function cancel(){
            $uibModalInstance.dismiss('cancel');
            AlertService.add("error", "Not ready to be settled  ", 4000);
        }


        function init(){
            /*
            if(rowData.Ready_to_Settle_0 != 'Yes'){
                $timeout(cancel, 30);
                return false;
            }
            */

            getSettlement();
        }

        init();
    }///////////////END CTRL

    return controllers;


});