define([
    'angular',
    './batchRequestController',
    './batchRequestService'



], function () {
    'use strict';
    return angular.module('app.batchRequest', ['app.batchRequestController','app.batchRequestService'] );


});