define(["angular"], function () {
    "use strict";
    angular
        .module("app.EfileAdminController", ["ui.select"])
        .controller("EfileAdminController", [
            '$rootScope',
            'GENERAL_CONFIG',
            'USER_SETTINGS',
            'SERVER_CONFIG',
            'EfileFactory',
            'AlertService',
            'GlobalService',
            'MessageService',
            'TAX_YEARS',
            EfileAdminController
        ])
        .controller("UploadIRSSchemaController",["USER_SETTINGS","gridData",'AlertService', 'EfileFactory', '$rootScope','$uibModalInstance', UploadIRSSchemaController])
        .controller("FileSubmissionToIRS",["USER_SETTINGS","rowData",FileSubmissionToIRS])
        .controller("PingIRSController",["USER_SETTINGS","rowData",'EfileFactory','AlertService',PingIRSController])
        .controller("DownloadAckController",["USER_SETTINGS","rowData",DownloadAckController]);


    function EfileAdminController($rootScope, GENERAL_CONFIG, USER_SETTINGS, SERVER_CONFIG, EfileFactory, AlertService, GlobalService, MessageService, TAX_YEARS) {
        let vm = this;

        vm.activeTab = 0;
        vm.fedState = 'FEDERAL';
        vm.jcdKey = '250';
        vm.canSignOff = SERVER_CONFIG.configSettings.can_signoff_efile.includes(USER_SETTINGS.user.sso_id);
        vm.taxYearsObj = angular.copy(TAX_YEARS);
        vm.taxYears = [];
        for (let index in vm.taxYearsObj) {
            if (vm.taxYearsObj.hasOwnProperty(index))
                vm.taxYears.push(vm.taxYearsObj[index]);
        }
        vm.selectedTaxYear = vm.taxYears.find(taxYear => taxYear.value === GlobalService.globalParams.tax_year) || vm.taxYears[0];
        vm.scenarios = findScenariosForJCD();
        vm.selectedScenario = getDefaultScenario();
        vm.selectedTaxPayers = [];
        vm.mulSelectCustomTexts = {
            buttonDefaultText: 'Select tax payers',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };
        vm.taxPayersDropdownSettings = {
            smartButtonMaxItems: 1,
            enableSearch: true,
            buttonClasses: 'mul-select-btn',
            scrollable: true,
            scrollableHeight: 300,
            displayProp: 'label'
        };
        vm.stateGroupsMulSelectCustomTexts = {
            buttonDefaultText: 'Select state filing groups',
            checkAll: 'Select all',
            uncheckAll: 'Unselect all'
        };
        vm.stateGroupsDropdownSettings = {
            smartButtonMaxItems: 1,
            enableSearch: true,
            buttonClasses: 'mul-select-btn',
            scrollable: true,
            scrollableHeight: 300,
            displayProp: 'LABEL'
        };
        vm.taxPayersDisabled = true;
        vm.consolGroups = [];
        vm.taxPayers = [];
        vm.efiles = null;
        vm.allUSStates = [];

        vm.generateZipFEDEnabled = (SERVER_CONFIG.clientConfig.efile_push_job_enable_zip_gen_on_push_fed === 'Y');
        vm.hasEfilePushPrivilege = SERVER_CONFIG.clientConfig.efile_push_privileged_sso_list.includes(USER_SETTINGS.user.sso_id);

        function findScenariosForJCD() {
            return vm.selectedTaxYear.scenarios.filter(scenario => scenario.jcd_key === vm.jcdKey);
        }

        function getDefaultScenario() {
            return vm.scenarios.find(scenario => scenario.default_scenario === 'Y') || vm.scenarios[0];
        }

        vm.fedStateChanged = function () {
            vm.efiles = null;
            vm.selectedState = null;
            vm.selectedConsolGroup = null;
            vm.selectedConsolGroups = [];
            vm.consolGroups = [];
            vm.selectedTaxPayers = [];
            vm.taxPayersDisabled = true;
            vm.jcdKey = vm.fedState === 'STATE' ? '253' : '250';
            vm.scenarios = findScenariosForJCD();
            vm.selectedScenario = getDefaultScenario();
            getConsolGroup();
        };

        // vm.changeState = function () {
        //     getConsolGroup();
        // };

        vm.changeTaxYear = function () {
            vm.efiles = null;
            vm.scenarios = findScenariosForJCD();
            vm.selectedScenario = getDefaultScenario();
            getConsolGroup();
        };

        vm.changeScenario = function () {
            getConsolGroup();
            vm.fedState === 'FEDERAL' && vm.getEFileSystemsList();
        };

        vm.changeConsolGroup = function () {
            vm.efiles = null;
            if (vm.fedState === 'FEDERAL') {
                vm.getTaxPayerList();
            } else {
                vm.getEFileSystemsList();
            }
        };

        vm.taxPayersChanged = function () {
            vm.getEFileSystemsList();
        };

        // function fetchUSStateList() {
        //     vm.statesLoading = true;
        //     EfileFactory.getAllUSStates()
        //                 .then(function (data) {
        //                     data.jsonObject.forEach(function (state) {
        //                         vm.allUSStates.push({"id": state.STATE_CODE, "label": state.STATE_DESC});
        //                     });
        //                 })
        //                 .catch((error) => {
        //                     vm.allUSStates = [];
        //                     AlertService.add('error', error.toString());
        //                 })
        //                 .finally(() => {
        //                     vm.statesLoading = false;
        //                 });
        // }
        //
        // fetchUSStateList();

        // load consolidated groups list
        function getConsolGroup() {
            if (!vm.selectedScenario) {
                vm.consolGroups = [];
                return;
            }
            let stateId = vm.selectedState ? vm.selectedState.id : undefined;
            vm.taxPayers = [];
            vm.selectedTaxPayers = [];
            vm.taxPayersDisabled = true;
            vm.consolGroupsLoading = true;
            EfileFactory.getFilingGroups(vm.selectedTaxYear.value, vm.selectedScenario.scenario, vm.fedState, stateId)
                        .then((data) => {
                            if (data.callSuccess === '1') {
                                vm.consolGroups = data.jsonObject;
                            } else {
                                throw new Error(data.errorMessage);
                            }
                        })
                        .catch((error) => {
                            AlertService.add('error', error.toString());
                        })
                        .finally(() => {
                            vm.consolGroupsLoading = false;
                        });
        }

        getConsolGroup();

        vm.getTaxPayerList = function () {
            if (vm.selectedConsolGroup) {
                let stateId = vm.selectedState ? vm.selectedState.id : undefined;
                vm.taxPayersLoading = true;
                vm.taxPayersDisabled = true;
                vm.taxPayers = [];
                vm.selectedTaxPayers = [];
                EfileFactory.getTaxPayerList(vm.selectedTaxYear.value, vm.selectedScenario.scenario, vm.fedState, stateId, vm.selectedConsolGroup.FILING_GROUP)
                            .then((data) => {
                                if (data.callSuccess === '1') {
                                    vm.taxPayers = data.jsonObject;
                                    vm.taxPayers.forEach((taxPayer) => {
                                        taxPayer.label = taxPayer.LEID + ' - ' + taxPayer.LE_NAME;
                                    });
                                    if (vm.taxPayers.length === 1) {
                                        vm.selectedTaxPayers.push(vm.taxPayers[0]);
                                        vm.taxPayersChanged();
                                    } else if (vm.taxPayers.length === 0) {
                                        AlertService.add('warning', 'No tax payers found for given selections!', 4000);
                                    }
                                } else {
                                    throw new Error(data.errorMessage);
                                }
                            })
                            .catch((error) => {
                                AlertService.add('error', error.toString());
                            })
                            .finally(() => {
                                vm.taxPayersDisabled = false;
                                vm.taxPayersLoading = false;
                            });
            }
        };

        vm.getEFileSystemsList = function () {
            if (vm.selectedTaxYear.value && vm.selectedScenario.scenario && (vm.selectedTaxPayers.length > 0 || (vm.fedState === 'STATE' && vm.selectedConsolGroups.length > 0))) {
                vm.leids = vm.selectedTaxPayers.map(({LEID}) => LEID);
                vm.tableLoading = true;
                vm.efiles = [];
                EfileFactory.getEFileSystemsList(vm.selectedTaxYear.value, vm.selectedScenario.scenario, vm.leids, vm.fedState)
                            .then((response) => {
                                if (response.data.callSuccess === '1') {
                                    vm.efiles = response.data.eFileSystemsList;
                                } else {
                                    throw new Error(response.data.errorMessage);
                                }
                            })
                            .catch((error) => {
                                vm.efiles = [];
                                AlertService.add('error', error.toString());
                            })
                            .finally(() => {
                                vm.tableLoading = false;
                            });
            }
        };

        vm.setInitials = function (efile) {
            if (efile.signOff_by) {
                var initials = efile.signOff_by.split(' ');
                for (var i in initials) {
                    initials[i] = initials[i][0];
                }
                efile.initials = initials.join('');
            } else {
                return 'US';
            }
        };

        vm.doSignOff = function (efile) {
            efile.signOffInProgress = true;
            if (efile.signOff) {
                EfileFactory.doSignOff(vm.selectedTaxYear.value, vm.selectedScenario.scenario, vm.leids, efile.system_id, vm.fedState, 1)
                            .then((response) => {
                                if (response.data.callSuccess === '1') {
                                    AlertService.add('success', 'Sign off success!', 4000);
                                } else {
                                    throw new Error(response.data.errorMessage);
                                }
                            })
                            .catch((error) => {
                                AlertService.add('error', error.toString());
                            })
                            .finally(() => {
                                vm.getEFileSystemsList();
                            });
            } else {
                EfileFactory.undoSignOff(vm.selectedTaxYear.value, vm.selectedScenario.scenario, vm.leids, efile.system_id, vm.fedState)
                            .then((response) => {
                                if (response.data.callSuccess === '1') {
                                    AlertService.add('success', 'Sign off undo success!', 4000);
                                } else {
                                    throw new Error(response.data.errorMessage);
                                }
                            })
                            .catch((error) => {
                                AlertService.add('error', error.toString());
                            })
                            .finally(() => {
                                vm.getEFileSystemsList();
                            });
            }
        };

        vm.hasSelectedSystems = function () {
            return _.some(vm.efiles, ["selected", true]);
        };

        vm.push = function () {
            let stateId = vm.fedState === 'FEDERAL' ? 'FEDERAL' : 'NA';
            vm.leids = vm.fedState === 'FEDERAL' ? vm.selectedTaxPayers.map(({LEID}) => LEID) : 'NA';
            vm.cdrs = vm.fedState === 'FEDERAL' ? vm.selectedTaxPayers.map(({CDR_NO}) => CDR_NO) : 'NA';
            vm.rpPeriods = vm.fedState === 'FEDERAL' ? vm.selectedTaxPayers.map(({REPORTING_PERIOD}) => REPORTING_PERIOD) : 'NA';
            let filingGroup = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.FILING_GROUP : _.chain(vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup : vm.selectedConsolGroups)
                                                                                                 .map('VALUE')
                                                                                                 .join(',')
                                                                                                 .value();
            let groupName = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.GROUP_NAME : _.chain(vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup : vm.selectedConsolGroups)
                                                                                             .map('GROUP_NAME')
                                                                                             .join(',')
                                                                                             .value();
            let systemList = _.chain(vm.efiles)
                              .filter(["selected", true])
                              .map('system_id')
                              .join(',')
                              .value();
            vm.pushing = true;
            EfileFactory.pushData(
                vm.fedState,
                stateId,
                vm.selectedTaxYear.value,
                vm.selectedScenario.scenario,
                filingGroup,
                groupName,
                vm.leids,
                vm.cdrs,
                vm.rpPeriods,
                systemList,
                "1")//vm.generateZip ? "1": (vm.fedState === 'STATE'? "1": "0"))
                        .finally(() => {
                            vm.efiles.forEach((efile) => {
                                efile.selected = false;
                            });
                            vm.pushing = false;
                        });
        };

        vm.generatePdfs = function () {
            vm.leids = vm.fedState === 'FEDERAL' ? vm.selectedTaxPayers.map(({LEID}) => LEID) : 'NA';
            // let filingGroup = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.FILING_GROUP : vm.selectedConsolGroup.VALUE;
            let filingGroup = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.FILING_GROUP : _.chain(vm.selectedConsolGroups)
                                                                                                 .map('VALUE')
                                                                                                 .join(',')
                                                                                                 .value();
            let groupName = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.GROUP_NAME : _.chain( vm.selectedConsolGroups)
                                                                                             .map('GROUP_NAME')
                                                                                             .join(',')
                                                                                             .value();
            let filingType = vm.fedState === 'FEDERAL' ? 'EFILE' : vm.fedState;
            vm.generating = true;
            EfileFactory.generatePDFOnly(vm.selectedTaxYear.value, vm.selectedScenario.scenario, vm.leids, filingGroup, filingType, groupName)
                        .then((response) => {
                            if (response.data.callSuccess === "0" && !response.data.errorMessage) {
                                AlertService.add("error", "PDF files generation failed to initiate!");
                            } else if (response.data.callSuccess === "0" && response.data.errorMessage) {
                                AlertService.add("error", response.data.errorMessage);
                            } else {
                                AlertService.add("success", "PDF files generation has been successfully initiated!", 4000);
                            }
                        })
                        .finally(() => {
                            vm.generating = false;
                        });
        };

          vm.generateXMLs = function () {
              vm.leids = vm.fedState === 'FEDERAL' ? vm.selectedTaxPayers.map(({LEID}) => LEID) : 'NA';
              // let filingGroup = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.FILING_GROUP : vm.selectedConsolGroup.VALUE;
              let filingGroup = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.FILING_GROUP : _.chain(vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup : vm.selectedConsolGroups)
                                                                                                   .map('VALUE')
                                                                                                   .join(',')
                                                                                                   .value();

            let groupName = vm.fedState === 'FEDERAL' ? vm.selectedConsolGroup.GROUP_NAME : _.chain(vm.selectedConsolGroups)
                                                                                .map('GROUP_NAME')
                                                                                .join(',')
                                                                                .value();                                                                                                    
              let filingType = vm.fedState === 'FEDERAL' ? 'EFILE' : vm.fedState;
              vm.generating = true;
              EfileFactory.generateXMLOnly(vm.selectedTaxYear.value, vm.leids, filingType, filingGroup,groupName)
                          .then((response) => {
                              AlertService.add("success", "XML files generation has been successfully Initiated !", 4000);
                          })
                          .finally(() => {
                              vm.generating = false;
                          });
        };
          vm.refreshView = function () {
            vm.generating = true;
            EfileFactory.refreshView(vm.fedState)
                .then((response) => {            
                        AlertService.add("success", "View Refreshed Successfully", 4000);
                })
                .finally(() => {
                    vm.generating = false;
                });
        };

        MessageService.subscribeToChannelWithCallback(GENERAL_CONFIG.base_url + '/gtw-websockets', '/efile-notify/' +
            USER_SETTINGS.user.sso_id, function (payload) {
            try {
                const notification = JSON.parse(payload.body);
                const context = JSON.parse(notification.context);
                if (context.status === '1') {
                    AlertService.add('success', 'Migration success for system: ' + context.systemId);
                    $rootScope.$broadcast('pushNotifications', notification);
                } else {
                    AlertService.add('error', 'Migration failed for system: ' + context.systemId);
                }
            } catch (error) {
                console.error(error.toString());
            }
        })
    }

    function UploadIRSSchemaController(USER_SETTINGS,gridData,AlertService,EfileFactory,$rootScope,$uibModalInstance){
        var vm = this;
        let rowData = gridData[gridData.length-1].data;
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        vm.taxYear = rowData.TAX_YEAR;
        vm.formType = "7004";
        vm.loadSchema = function () {
            let file = vm.schemaZipFile;
            vm.loading = true;
            EfileFactory.loadSchema(vm.taxYear, vm.formType, vm.schemaVersion, file.name, file).then((res) => {
                vm.loading = false;
                if(res.data.callSuccess === "1")
                {
                    AlertService.add('success', "The IRS schema was successfully loaded!");
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                }
                else
                {
                    AlertService.add('error', res.data.errorMessage);
                }
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
            });

        }
    }

    function FileSubmissionToIRS(USER_SETTINGS,rowData){
        var vm = this;
        console.log(rowData);
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
    }

    function PingIRSController(USER_SETTINGS,rowData,EfileFactory,AlertService){
        var vm = this;
        console.log(rowData);
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        vm.pinging = false

        vm.pingToIRS = function() {
            vm.pinging = true;
            EfileFactory.pingIRS("TEST").then((res)=>{
                vm.pinging = false;
                if(res.data.callSuccess === "1")
                    AlertService.add("success","Successfully Pinged IRS!");
                else
                    AlertService.add("error","Failed to Ping IRS!");
            })
        }
    }
    
    function DownloadAckController(USER_SETTINGS,rowData){
        var vm = this;
        console.log(rowData);
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
    }
});
