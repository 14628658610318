define([
	   'angular'

	], function () {
	   'use strict';

	   var services =  angular.module('app.calc163jSummaryByEntityLeMeService',[])
	        .factory("Calc163jSummaryLeMeFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
	    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
		           
	        	 var returnObj = {};
		           var Calc163jSummaryLeMeFactory ={};
		           Calc163jSummaryLeMeFactory.url ={};
		           
				   	var row_data = {};

					function setEditRows (obj_id, status){
						row_data[obj_id] = status;
					}

					function getEditStatus (obj_id){
						return row_data[obj_id];
					}
		           
		           Calc163jSummaryLeMeFactory.url.save163jLineAmounts= GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ms2967";
	        	
		           
		           
		           Calc163jSummaryLeMeFactory.save163jLineAmounts = function(_callName ,_data){
						var isEdit = getEditStatus(_data.row_id);
						if(!!isEdit){
							AlertService.add("warning", "Data save in progress, you can edit once the data is saved", 4000);
							return;
						}
						setEditRows(_data.row_id, true);
		               console.log("data", _data);
		               console.log("callname",_callName);
	                   var rowData = _data.rowData;
	                   var service = $injector.get('JsonObjectFactory');
					   var defer = $q.defer();
		               //$rootScope.$broadcast('gridRefresh', false); 
		               returnObj.combination_key = _data.rowData.COMBINATION_KEY;

		              // var service = $injector.get('JsonObjectFactory');
		              var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
		              var params = _.merge({}, GlobalService.globalParams, filterParams);
		               var jsonObj = [{
		            	   combination_key: _data.rowData.COMBINATION_KEY,
	                       acct_key: _data.rowData[_data.colData.map+'_ACCT_KEY'],
                           amount: _data.rowData[_data.colData.map]
		   			}];
		           	console.log(jsonObj);
		           	
		            var jsonSettings = [{
	            	le_key:_data.rowData.LE_KEY,
	                tax_year:_data.rowData.TAX_YEAR,
	                scenario:filterParams.scenario,
	                jcd_key:GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
	   			     }];
		           	
		               
		              
		               var params = _.extend({jsonObj: JSON.stringify(jsonObj)}, params);
		               var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);
		   	            
		               service.saveJSON(Calc163jSummaryLeMeFactory.url.save163jLineAmounts,params).then(function (data) {	
                           //console.log(response.data);
                           if(data.callSuccess === "1"){
                               AlertService.add("success", "Data saved Successfully.",3000);
							   setEditRows(_data.row_id, false);
                               var args = {COMBINATION_KEYS: _data.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: _data.rowData.COMBINATION_KEY,object_id:_data.rowData.object_id}};
                                  $rootScope.$emit('gridUpdate', args);
                                           defer.resolve(data);
                           }else {
								setEditRows(_data.row_id, false);
                               AlertService.add("error", data.errorMessage);
                               defer.reject(data);
                           }
                       },function(data){
                           defer.reject(data);
                       });
                       return defer.promise;
   				}
		            
		            
		   return Calc163jSummaryLeMeFactory;
		          }]);

		      
		      return services;
		   });


