define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module(
					'app.section174AdjAllocationsController', []).controller(
					'Section174AdjAllocationsController',
					[ '$rootScope', '$scope', '$http', 'GlobalService',
							'$uibModalInstance', 'ModalFactory',
							'AlertService', 'rowData', 'gridData',
							'JsonObjectFactory', '$timeout',
							'Section174AdjAllocationsFactory',
							'GENERAL_CONFIG',
							section174AdjAllocationsController ])

			function section174AdjAllocationsController($rootScope, $scope,
					$http, GlobalService, $uibModalInstance, ModalFactory,
					AlertService, rowData, gridData, JsonObjectFactory,
					$timeout, Section174AdjAllocationsFactory, GENERAL_CONFIG) {

				var vm = this;
				$scope.crudLoading = false;
				$scope.rowData = rowData || $scope.$parent.rowData;
				vm.checksystemaccount = [];

				// vm.changeColorvariance = false;
				function init() {
					$scope.crudLoading = false;
					$scope.sectionLoading = true;
					vm.entities = [];
					$scope.crudLoading = false;
					var sendobj = [];
					vm.section174adjList = [];
					vm.mappingList = [];
					vm.applyflag = "";
					var getwwniModalcallFlag = false;
					vm.tagetlistADD = false;
					vm.defaultvalue = 0
					vm.totalVariancea = 0;
					vm.totalVarianceRemaina = 0;
					vm.totalVarianceAllocateda = 0;
					vm.loading = false;
					vm.sic_code_all = [];
					vm.sic_code_display = [];
					vm.in_out_all = [];
					vm.in_out_display = [];

					getmodalSection174();
					getsicCode();
					getinoutData();
				}
				/*
				 * vm.itemTracker= function(item) { return item.ACCT + '-' +
				 * item.OCCURRENCE + '-'+ item.PLUS_MINUS_FLAG + '-'
				 * +item.SIC_CODE_KEY; }
				 */
				function getinoutData() {
					var params = {
						"action_code" : 'ucyo2g',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"combi_key" : rowData.COMBINATION_KEY

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.in_out_all = [];
											vm.in_out_display = [];

											vm.in_out_all = data.jsonObject;

											for ( var cc in vm.in_out_all) {
												vm.in_out_display
														.push(vm.in_out_all[cc].IN_OUT_FLAG);
											}
										}
									})
				}
				function getsicCode() {
					var params = {
						"action_code" : '8y8czx',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"combi_key" : rowData.COMBINATION_KEY,
						"chart_key" : rowData.CHART_KEY,
					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
										} else {

											vm.sic_code_all = [];
											vm.sic_code_display = [];

											vm.sic_code_all = data.jsonObject;
											for ( var c in vm.sic_code_all) {
												vm.sic_code_display
														.push(vm.sic_code_all[c].SIC_CODE_DESC);
											}

										}
									})
				}

				function getmodalSection174() {
					$scope.sectionLoading = true;
					var params = {
						"action_code" : 'rsj4du',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"combi_key" : rowData.COMBINATION_KEY,
						"chart_key" : rowData.CHART_KEY,
						"acct_key" : rowData.ACCT_KEY

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											return false;
											$scope.sectionLoading = false;

										} else {

											vm.section174adjList = [];
											vm.section174adjList = data.jsonObject;

											for ( var dd in vm.section174adjList) {
												vm.section174adjList[dd].OLD_SECTION174_BOOK = vm.section174adjList[dd].BOOK_AMT_174;
												vm.section174adjList[dd].OLD_ACCT_KEY = vm.section174adjList[dd].ACCT_KEY;
												vm.section174adjList[dd].OLD_SIC_CODE = vm.section174adjList[dd].SIC_CODE_KEY;
												vm.section174adjList[dd].OLD_IN_OUT = vm.section174adjList[dd].IN_OUT_174;
												vm.section174adjList[dd].NEW_SECTION174_BOOK = vm.section174adjList[dd].BOOK_AMT_174;
												vm.section174adjList[dd].NEW_TAX_ADJ174 = vm.section174adjList[dd].TAX_ADJ_174;
												vm.section174adjList[dd].NEW_ACCT_KEY = vm.section174adjList[dd].ACCT_KEY;
												vm.section174adjList[dd].NEW_SIC_CODE = vm.section174adjList[dd].SIC_CODE_KEY;
												vm.section174adjList[dd].NEW_IN_OUT = vm.section174adjList[dd].IN_OUT_174;
												// new values
												vm.section174adjList[dd].NEW_SECTION174_TEMP_TAX_ADJ = vm.section174adjList[dd].TEMP_TAX_ADJ_174;
												vm.section174adjList[dd].OLD_SECTION174_TEMP_TAX_ADJ = vm.section174adjList[dd].TEMP_TAX_ADJ_174;
												vm.section174adjList[dd].NEW_SECTION174_PERM_TAX_ADJ = vm.section174adjList[dd].PERM_TAX_ADJ_174;
												vm.section174adjList[dd].OLD_SECTION174_PERM_TAX_ADJ = vm.section174adjList[dd].PERM_TAX_ADJ_174;
												vm.section174adjList[dd].sic_code_flag = false;
												vm.section174adjList[dd].in_out_flag = false;

											}

											$scope.sectionLoading = false;

										}
									})
				}
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				vm.addRow = function(row, index) {
					// var row1 = JSON.parse(JSON.stringify(row));

					var row1 = {};

					var index_postion = index + 1;

					row1.type = "I";
					row1.PLUS_MINUS_FLAG = "MINUS";
					row1.BOOK_AMT_FLAG = row.BOOK_AMT_FLAG;
					row1.TEMP_TAX_AMT_FLAG = row.TEMP_TAX_AMT_FLAG;
					row1.PERM_TAX_AMT_FLAG = row.PERM_TAX_AMT_FLAG;
					row1.BOOK_AMT_174 = 0;
					row1.BOOK_AMT = 0;
					row1.TEMP_TAX_ADJ = 0;
					row1.PERM_TAX_ADJ = 0;

					row1.TEMP_TAX_ADJ_174 = 0;
					row1.PERM_TAX_ADJ_174 = 0;
					row1.ACCT = row.ACCT;
					row1.ACCT_KEY = row.ACCT_KEY;
					row1.SIC_CODE = "";
					row1.SIC_CODE_KEY = "";
					row1.IN_OUT_174 = "";
					row1.ADJ_TYPE = "";

					row1.OLD_SECTION174_BOOK = 0;
					row1.OLD_SECTION174_TEMP_TAX_ADJ = 0;
					row1.OLD_SECTION174_PERM_TAX_ADJ = 0;
					row1.OLD_ACCT_KEY = row.ACCT_KEY;
					row1.OLD_SIC_CODE = "";
					row1.OLD_IN_OUT = "";
					row1.NEW_SECTION174_BOOK = 0;
					row1.NEW_SECTION174_TEMP_TAX_ADJ = 0;
					row1.NEW_SECTION174_PERM_TAX_ADJ = 0;
					row1.NEW_ACCT_KEY = row.ACCT_KEY;
					row1.NEW_SIC_CODE = "";
					row1.NEW_IN_OUT = "";

					row1.sic_code_flag = false;
					row1.in_out_flag = false;

					// vm.section174adjList.push(row1);

					vm.section174adjList.splice(index_postion, 0, row1)

					// vm.section174adjList.sort(compare);
				}
				function compare(a, b) {
					let comparison = 0;
					if (parseInt(a.ACCT) > parseInt(b.ACCT)) {
						comparison = 1;
					} else if (parseInt(a.ACCT) < parseInt(b.ACCT)) {
						comparison = -1;
					} else if (a.OCCURRENCE == '1') {
						comparison = 1;
					}
					return comparison;
				}

				vm.deleteRow = function(row) {
					for ( var t in vm.section174adjList) {
						if (vm.section174adjList[t].$$hashKey == row.$$hashKey) {
							vm.section174adjList.splice(t, 1);

						}
					}
				}
				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}

					$scope.crudLoading = true;
					vm.sendobj = [];
					// vm.crudLoading = true;

					var dummyJson = {};
					// sendobj = [];
					for (var s = 0; s < vm.section174adjList.length; s++) {

						var save_acct_key = "";
						for ( var aa in vm.sic_code_all) {
							if (vm.section174adjList[s].SIC_CODE == vm.sic_code_all[aa].SIC_CODE_DESC) {
								save_acct_key = vm.sic_code_all[aa].SIC_CODE_KEY
							}
						}
						if (vm.section174adjList[s].TAX_ADJ_174 != 0
								|| vm.section174adjList[s].BOOK_AMT_174 != 0) {
							if (vm.section174adjList[s].SIC_CODE == ""
									|| vm.section174adjList[s].SIC_CODE == undefined
									|| vm.section174adjList[s].IN_OUT_174 == ""
									|| vm.section174adjList[s].IN_OUT_174 == undefined) {
								AlertService.add("error",
										"Please Select SIC Code/IN OUT", 4000);
								$scope.crudLoading = false;

								return;
							}

						}

						dummyJson = {};
						dummyJson.TAX_YEAR = GlobalService.globalParams.tax_year;
						dummyJson.SCENARIO = GlobalService.globalParams.scenario;
						dummyJson.JCD_KEY = GlobalService.globalParams.jcd_key;
						dummyJson.CHART_KEY = rowData.CHART_KEY;
						/*
						 * dummyJson.OLD_SECTION174_BOOK
						 * =vm.section174List[s].OLD_Section_174_Book;
						 * dummyJson.NEW_SECTION174_BOOK =
						 * vm.section174List[s].Section_174_Book;
						 * dummyJson.OLD_TAX_ADJ174
						 * =vm.section174List[s].OLD_Section_174_Book;
						 * dummyJson.NEW_TAX_ADJ174 =
						 * vm.section174List[s].Section_174_Book;
						 * dummyJson.COMBINATION_KEY = rowData.COMBINATION_KEY;
						 * dummyJson.OLD_ACCT_KEY =
						 * vm.section174List[s].LOCAL_ACCT_KEY;
						 * dummyJson.NEW_ACCT_KEY = save_acct_key;
						 * dummyJson.OLD_SIC_CODE =
						 * vm.section174List[s].LOCAL_ACCT_KEY;
						 * dummyJson.NEW_SIC_CODE =
						 * vm.section174List[s].LOCAL_ACCT_KEY;
						 * dummyJson.OLD_IN_OUT =
						 * vm.section174List[s].LOCAL_ACCT_KEY;
						 * dummyJson.NEW_IN_OUT =
						 * vm.section174List[s].LOCAL_ACCT_KEY;
						 */
						// dummyJson.OLD_SECTION174_TAX_ADJ =
						// vm.section174List[s].OLD_Tax_Adj_174;
						// dummyJson.NEW_SECTION174_TAX_ADJ =
						// vm.section174List[s].Tax_Adj_174;
						dummyJson.OLD_SECTION174_BOOK = vm.section174adjList[s].OLD_SECTION174_BOOK;
						dummyJson.NEW_SECTION174_BOOK = vm.section174adjList[s].BOOK_AMT_174;
						dummyJson.COMBINATION_KEY = rowData.COMBINATION_KEY;
						dummyJson.OLD_ACCT_KEY = vm.section174adjList[s].OLD_ACCT_KEY;
						dummyJson.NEW_ACCT_KEY = vm.section174adjList[s].NEW_ACCT_KEY;
						dummyJson.OLD_SIC_CODE = vm.section174adjList[s].OLD_SIC_CODE;
						dummyJson.NEW_SIC_CODE = save_acct_key;
						dummyJson.OLD_IN_OUT = vm.section174adjList[s].OLD_IN_OUT;
						dummyJson.NEW_IN_OUT = vm.section174adjList[s].IN_OUT_174;
						dummyJson.OLD_SECTION174_TEMP_TAX_ADJ = vm.section174adjList[s].OLD_SECTION174_TEMP_TAX_ADJ;
						dummyJson.OLD_SECTION174_PERM_TAX_ADJ = vm.section174adjList[s].OLD_SECTION174_PERM_TAX_ADJ;
						dummyJson.NEW_SECTION174_TEMP_TAX_ADJ = vm.section174adjList[s].TEMP_TAX_ADJ_174;
						dummyJson.NEW_SECTION174_PERM_TAX_ADJ = vm.section174adjList[s].PERM_TAX_ADJ_174;

						vm.sendobj.push(dummyJson);
					}

					Section174AdjAllocationsFactory
							.saveProjectTagWSec(vm.sendobj, vm.sendobj)
							.then(
									function(result) {

										if (result.callSuccess !== "1") {
											AlertService.add("error",
													result.errorMessage, 4000);
											$scope.crudLoading = false;

										} else {
											AlertService
													.add(
															"success",
															"Data has been saved successfully ",
															4000);
											$uibModalInstance.dismiss('cancel');
											var args = {
												cc_key : (rowData != undefined) ? rowData.COMBINATION_KEY
														: gridData[0].data.COMBINATION_KEY,

												gridFilter : {
													cc_key : (rowData != undefined) ? rowData.COMBINATION_KEY
															: gridData[0].data.COMBINATION_KEY,

												}
											};
											$rootScope
													.$emit('gridUpdate', args);
											/*
											 * $timeout(function() {
											 * 
											 * var args = { refresh_cc_key :
											 * (rowData != undefined) ?
											 * rowData.SOURCE_COMBINATION_KEY :
											 * gridData[0].data.SOURCE_COMBINATION_KEY,
											 * 
											 * gridFilter : { refresh_cc_key :
											 * (rowData != undefined) ?
											 * rowData.SOURCE_COMBINATION_KEY :
											 * gridData[0].data.SOURCE_COMBINATION_KEY, } };
											 * $rootScope.$emit('gridUpdate',
											 * args); });
											 */
										}
									});
				}

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				// vm.book_174_change = function (index,data)
				// {
				// var total_book_174 = 0;
				// for (var a in vm.section174adjList)
				// {
				// ///logic compare account //need parameter from backend
				//						
				// }
				// vm.section174adjList[a].BOOK_AMT = total_book_174;
				// }
				//				
				// vm.tax_174_change = function (index,data)
				// {
				// var total_tax_174 = 0;
				// for (var a in vm.section174adjList)
				// {
				// ///logic compare account //need parameter from backend
				//						
				// }
				// vm.section174adjList[a].TAX_ADJ = total_tax_174;
				// }
				init();

				vm.reset = function() {
					init();
				}

				// 4bjt2k

				// getWWNImodalData();

			}
			return controllers;

		});
