
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.partnershipServicesNew',[])

        .factory("PartnerAllocServicesNewFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', PartnerAllocServicesNewFactory])


        function PartnerAllocServicesNewFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory) {

            var partnerAllocServicesFactory = {};
            console.log('... factory called ...');
            
            PartnerAllocServicesNewFactory.url = {};

            PartnerAllocServicesNewFactory.savePartnerAllocationForm = function(action_code, _data, Details) {

                PartnerAllocServicesNewFactory.url.defaults_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=" + action_code;

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(Details);
                _data['jcd_key'] = GlobalService.globalParams.jcd_key;
                var jsonSettings =  JSON.stringify(_data);

                console.log(jsonSettings);
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({clob_data:jsonObj}, params);
                params =  _.extend({clob_settings:jsonSettings}, params);
                params.process_name =  "Partner Allocation Setup New";
                params.jcd_key = GlobalService.globalParams.jcd_key;
                params.sso_id = _data.sso_id;

                console.log('params = ', params);
                console.log('clob_data = ', params.clob_data);
                console.log('clob_settings = ', params.clob_settings);
                var promise = $http({
                    method: "post",
                    url: PartnerAllocServicesNewFactory.url.defaults_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
                return promise;
            };

            PartnerAllocServicesNewFactory.loadSK3Details = function(ctrl) {

                let dataParams = {"action_code" : 'fp23ed', "tax_year" : ctrl.TAX_YEAR, "scenario" : ctrl.scenario, "jcd_key": ctrl.jcd_key, "pship_cc_key" : ctrl.COMBINATION_KEY, "ptr_cc_key" : ctrl.PTR_COMBINATION_KEY};

                let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', dataParams).then(
                    function (response) {
                        return response;
                    }
                );

                return promise;

            }

            PartnerAllocServicesNewFactory.loadSK3AttachmentDetails = function(ctrl) {

                let attachmentParams = {"action_code" : 'uqnczz', "tax_year" : ctrl.TAX_YEAR, "scenario" : ctrl.scenario, "jcd_key": ctrl.jcd_key, "pship_cc_key" : ctrl.COMBINATION_KEY, "ptr_cc_key" : ctrl.PTR_COMBINATION_KEY, "form_key": ctrl.form_key, "schedule_name": ctrl.schedule_name, "sso_id":ctrl.logged_in_user};

                let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', attachmentParams).then(
                    function (response) {
                        return response;
                    }
                );

                return promise;

            }




            PartnerAllocServicesNewFactory.loadSK3FormulaDetails = function(ctrl) {
                let formulaParamsSK3 = {"action_code" : '6v54vu', "tax_year" : ctrl.TAX_YEAR, "scenario" : ctrl.scenario, "jcd_key": ctrl.jcd_key, "part_no":ctrl.pn,"section_name":ctrl.sm};

                let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', formulaParamsSK3).then(function (response) {
                    return response;
                });

                return promise;
            }

            PartnerAllocServicesNewFactory.saveK3Attachments = function (_url, _params) {
                var promise = $http({
                    method: "post",
                    url: _url,
                    data: _params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            return PartnerAllocServicesNewFactory;

        }




        return services;
});