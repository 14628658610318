define([
    'angular',
    './ghostftceNonghostScreenService', 
    './ghostftceNonghostScreenCtrl',
    './ghostftceNonghostDeleteCtrl',
    
], function () {
    'use strict';
    return angular.module('app.FIR_Ghost_FTCE_Nonghost_Screen', ['app.ghostftceNonghostScreenService','app.ghostftceNonghostScreenCtrl','app.ghostftceNonghostDeleteCtrl'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        // $stateProvider
        //     .state('sourcing-input-screen', {
        //         url: '/sourcing-input-screen',
        //         templateUrl: 'app/components/FIR_Sourcing_Input/sourcingInputScreen.html',
        //         backdrop: 'static',
        //         data:{},
        //         access:""
        //     })
    }])

});