define(
    [ 'angular'
    ],
    function() {
    'use strict';

    var controllers = angular.module('app.bizzLockCtrl', [])
    .controller('bizzLockController', ['bizzHierViewFactory', '$scope', "AlertService", "$filter", "GlobalService","entityLockingFactory","GENERAL_CONFIG","JsonObjectFactory","workspaceFactory","$rootScope",  bizzLockController])


    function bizzLockController(bizzHierViewFactory, $scope, AlertService, $filter,
         GlobalService,entityLockingFactory,GENERAL_CONFIG,JsonObjectFactory,workspaceFactory, $rootScope) {
        var vm = this;
        vm.searchText = '';
        $scope.trees = {};
        $scope.trees.nodes = [];
        $scope.trees.currentAlertMessage = '';
        vm.collapsed = false;
        vm.treeFilter = $filter('uiTreeFilter');
        vm.lockBusiness = [];
        $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
        var activeScreen = workspaceFactory.activeScreen;
        vm.menuObj = [];
        vm.childMenuObj = [];
        vm.totalSelected = 0;
        vm.selected  = [];
        vm.selectedArray  = [];

        if(activeScreen && activeScreen.data){
            vm.showBackdrop = false;
            $scope.trees.currentAlertMessage = activeScreen.data.currentAlertMessage;
            $scope.trees.loading = activeScreen.data.loading;
            $scope.trees.nodes = activeScreen.data.nodes;
        } else {
            vm.showBackdrop = true;
        }

       function getBZHYUserView() {
            $scope.trees.loading = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = {
                tax_year: filterParams.tax_year,
                scenario :filterParams.scenario,
                jcd_key :GlobalService.globalParams.jcd_key  
            }
            
            entityLockingFactory.getBusinessForLock(params).then((response) => {
                if (response.callSuccess === '1') {
                    vm.showBackdrop = false;
                    const nodes = response.jsonObject;
                    $scope.trees.nodes = nodes;
                    workspaceFactory.setCache(activeScreen, $scope.trees);
                    workspaceFactory.setCurrentFilters(activeScreen);
                    
                    if (nodes.length === 0) {
                        $scope.trees.currentAlertMessage = 'There are no businesses have assigned to you, please contact system administrator for access!';
                    } else {
                        $scope.trees.currentAlertMessage = '';
                    }
                } else {
                    $scope.trees.nodes = [];
                    AlertService.add('warning', 'No business hierarchy found!', 4000);
                }
            }).catch((error) => {
                $scope.trees.nodes = [];
                AlertService.add('error', 'Something went wrong while fetching data!');
            }).finally(() => {
                $scope.trees.loading = false;
            })
        } 

        $scope.$on('dataFilters:refreshGrid', function (event, data) {
            getBZHYUserView();
        });

        workspaceFactory.checkFilters(workspaceFactory.activeScreen);

        $scope.$watch('trees.nodes', function(newVal, oldVal) {
            if( typeof newVal !== "undefined" && newVal !== null){
                vm.reset();
                vm.setTreeParent(newVal,'children' );
                vm.menuObj = newVal;
            }
            vm.getTotalSelected();
        });

        $scope.$watch('trees.nodes.selected', function (newVal, oldVal) {
            vm.selected = newVal;
            vm.selectedArray = newVal;
            /* if (vm.selected && vm.selected.length && vm.selected.length > 0) {
                iterateChildren($scope.filter.values);
            } */
        }, true);

        vm.reset = function() {
            vm.totalSelected = 0;
            vm.childMenuObj = [];
            vm.selected  = [];
            vm.selectedArray  = [];
        }

        vm.setTreeParent = function(_collection,_children) {
            for (var i = 0; i < _collection.length; i++) {
                var thisChildren =  _collection[i][_children];
                if( thisChildren && thisChildren.length ){
                    vm.setTreeParent(thisChildren,_children);
                }

                for(var n in thisChildren){
                    thisChildren[n].parent = _collection[i];
                }
            }
        }

        vm.getTotalSelected = function() {
            vm.totalSelected = getSelected(vm.menuObj);
        }

        function getSelected(){
            vm.selected  = [];
            var selected = 0;

            _.each(vm.menuObj, function(item){
                if(item.selected ){
                    selected++;
                    vm.selected.push(item.key);
                }
                if(item.children && item.children.length > 0) {
                    item.numSelected = recursiveSelected(item);
                }
            })

            function recursiveSelected(collection){
                var selected = 0;
                function recursiveSelectedInner(collection) {
                    _.each(collection.children, function(item){
                        if(item.selected ){
                            vm.selected.push(item.key);
                            selected++;
                        }
                        if(item.children && item.children.length > 0) {
                            recursiveSelectedInner(item);
                        }
                    });
                }
                recursiveSelectedInner(collection)
                return selected;
            }
            return selected;
        }


        vm.setChildren= function(collection){
            var selected = collection.selected;
            function setEm(_collection){
                _.each(_collection.children, function(item){
                    item.selected = selected;
                  // if(vm.lockBusiness && vm.lockBusiness.length>0){
                       if(_.filter(vm.lockBusiness,{mekey:item.mekey,selected:selected}).length==0){
                        vm.lockBusiness.push({
                            me_leid_name:item.name,
                            me_leid: item.mekey,
                            lock_y_n: selected ? 'Y' : 'N',
                            class_type:'business'
                        })
                       }
                        
                 //  }
                    

                    if(item.children && item.children.length > 0){
                        setEm(item);
                    }
                });
            }

            setEm(collection);
            checkParent(collection);
            getSelected();
            vm.change();
        };

        function checkParent(_item){
            var allSelected = true;
            if(_.filter(vm.lockBusiness,{mekey:_item.mekey,selected:_item.selected}).length==0){
                vm.lockBusiness.push({
                    me_leid_name:_item.name,
                    me_leid: _item.mekey,
                    lock_y_n:  _item.selected ? 'Y' : 'N',
                    class_type:'business'
                })
               }
             if(_item.parent) {
                _.each(_item.parent.children, function(item){
                    if(!item.selected ){
                        allSelected = false;
                    }
                });
                _item.parent.selected = allSelected;
                if(_.filter(vm.lockBusiness,{mekey:_item.parent.mekey,allSelected:_item.parent.selected}).length==0){
                    vm.lockBusiness.push({
                        me_leid_name:_item.parent.name,
                        me_leid: _item.parent.mekey,
                        lock_y_n:  _item.parent.selected ? 'Y' : 'N',
                        class_type:'business'
                    })
                   }

                if( _item.parent.parent ){
                    checkParent(_item.parent);
                }
            }
        }

        vm.change = function () {
              createdSelectedArray();
              $scope.trees.nodes.selected = vm.selectedArray;
        }

        function createdSelectedArray(){
            vm.selectedArray  = [];
            for (var i = 0; i < vm.selected.length; i++) {
                var newObj = {}
                newObj[$scope.trees.nodes.value_name] = vm.selected[i];
                newObj.parnet = vm.selected[i];
                newObj.level = vm.selected[i];
                vm.selectedArray.push(newObj);
            }
        }

        vm.toggleCollapse = function () {
            vm.collapsed = !vm.collapsed;
            var treeScope = _getRootNodesScope();
            if (vm.collapsed) {
                treeScope.$broadcast('angular-ui-tree:collapse-all');
            } else {
                treeScope.$broadcast('angular-ui-tree:expand-all');

            }
        };

        function _getRootNodesScope() {
            var treeElement = angular.element(document.getElementById('view-tree'));
            if (treeElement) {
                var treeScope = (typeof treeElement.scope === 'function') ?
                                treeElement.scope() : undefined;
                return treeScope;
            }
            return undefined;
        }

        vm.filter = function (node, searchText) {
            $filter('uiTreeFilter')(node, searchText, ['name', 'mekey']);
        }
        
        vm.save = function(){
            console.log("selecte",vm.selected);
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = {
                tax_year:filterParams.tax_year,
                scenario:filterParams.scenario,
                jcd_key: GlobalService.globalParams.jcd_key,
              //  parent_me_string:filterParams.parent_me_string,
                lockedjsonObj: JSON.stringify(vm.lockBusiness)
            }
            var url = GENERAL_CONFIG.base_url + '/saveEntityBusinessConsolLock'
            JsonObjectFactory.saveJSON(url,params).then(function(data){
                if(data.callSuccess == "1"){
                    AlertService.add("success","Business Lock saved Successfully",4000);
                    entityLockingFactory.refreshCacheObjectbyKey('GET_BUSINESS_LOCKED_ENTITIES',params.tax_year,params.scenario,params.jcd_key);

                }else{
                   AlertService.add("error", data.errorMessage);
                }
        });
    }
        
    }

    return controllers;
});