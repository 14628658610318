define([
    'angular'
    
], function () {
    'use strict';

    var controllers =  angular.module('app.sltKeySetupController',[])
    .controller('sltKeySetupController', ['$scope', '$rootScope', '$state', '$log', 'rowData', 'ObjectDiff', 'JsonObjectFactory', 'AlertService', '$timeout', '$parse', 'JCDFactory', 'ClientAdminFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', '$uibModalInstance', 'sltKeySetupFactory', 'GlobalService', sltKeySetupController])

    function sltKeySetupController($scope, $rootScope, $state, $log, rowData, ObjectDiff, JsonObjectFactory, AlertService, $timeout, $parse, JCDFactory, ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS, $uibModalInstance, entitySyncLogFactory, GlobalService) {

        var vm = this;
        vm.title = "Submission/Key Setup";
        vm.loading = false;
        vm.sltParams = rowData;
        vm.syncParams = "";
        vm.noRecords = false;
        vm.slt_key_setup_data = [];
        vm.totalRecords = 0;
        vm.client = GlobalService.getClient();
		vm.client_key = vm.client.client_key;

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.getLogJSON = function(){
            vm.loading = true;
			return entitySyncLogFactory.getKeySetupData(vm.sltParams.TAX_YEAR, vm.sltParams.MEF_GROUP_KEY).then(function (data) {
				if(data.callSuccess == "1" ){
                    if(data.jsonObject && data.jsonObject != null && data.jsonObject.length > 0){
                        vm.slt_key_setup_data = data.jsonObject;
                        vm.loading = false;
                    }else{
                        vm.loading = false;
                        vm.noRecords = true;
                    }
				}else{
					vm.loading = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };
        
        vm.resync = function(){
            vm.loading = true;
            var params = {
                "tax_year": GlobalService.globalParams.tax_year,
                "client_key": vm.client_key
            };

			return entitySyncLogFactory.reSyncGroup(vm.sltParams.TAX_YEAR, vm.sltParams.MEF_GROUP_KEY).then(function (data) {
				if(data.data.callSuccess == "1" ){
					vm.loading = false;
					AlertService.add("success", "Re-sync Group Execute Successfully", 4000);
                    vm.slt_key_setup_data = data.data.jsonObject;
				}else{
					vm.loading = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };
        vm.getLogJSON();
    }
    return controllers;
});
