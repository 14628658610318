define([
    'angular',
    './firRepointCtrl',
    './firRepointService', 
    
], function () {
    'use strict';
    return angular.module('app.firRepoint', ['app.firRepointCtrl', 'app.firRepointService'] );
});

