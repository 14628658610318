define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.LockingBuEntityConsolService', [])

        .factory("entityLockingFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService',
            'DataFiltersFactory', 'workspaceFactory', 'AlertService', '$injector','JsonObjectFactory','GENERAL_CONFIG',
            function ($q, $http, $stateParams, $timeout, $rootScope,$log, GlobalService,
                DataFiltersFactory, workspaceFactory, AlertService, $injector,JsonObjectFactory,GENERAL_CONFIG) {
                    var factory = {};

                    factory.refreshCacheObjectbyKey = function(cacheKey,tax_year,scenario,jcd_key){
                        var _params = {
                            objectKey: cacheKey,
                            tax_year:tax_year,
                            scenario: scenario,
                            jcd_key: jcd_key
                        }
                        var promise = $http({
                            method: "post",
                            url: GENERAL_CONFIG.common_api_url + '/refreshCachedObjectByKey',
                            data: _params
                        }).then(function (response) {
                            return response.data;
                        });
    
                        return promise;
                    }
                    factory.saveEntitiLocking  = function (_callName, _data, isNew) {
                       // var json_arrOBJ = [];
                        var defer = $q.defer();
                        var json_obj = {};
                        if(workspaceFactory.activeScreen.screen_key == 10742){
                            json_obj = {
                                me_leid:_data.rowData.ME_CODE,
                                me_leid_name: _data.rowData.ME_NAME,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'business',
                                cache_key: 'GET_BUSINESS_LOCKED_ENTITIES'
                            }
                        }else if(workspaceFactory.activeScreen.screen_key == 10738){
                            json_obj = {
                                me_leid: _data.rowData.HO_LEID,
                                me_leid_name: _data.rowData.HO_LE_NAME,
                                consol_group_key: _data.rowData.CONSOL_GROUP_KEY,
                                rpt_period: _data.rowData.HO_REPORTING_PERIOD,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'entity',
                                cache_key: 'GET_LOCKED_LEIDS'
    
                            }
                        }
						else if(workspaceFactory.activeScreen.screen_key == 11118){
                            json_obj = {
                                me_leid:_data.rowData.ME_CODE,
                                me_leid_name: _data.rowData.ME_NAME,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'ghost'
                            }
                        }else if(workspaceFactory.activeScreen.screen_key == 10743){
                            json_obj = {
                                me_leid: _data.rowData.CONSOL_GROUP_KEY,
                                me_leid_name: _data.rowData.GROUP_NAME,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'consol',
                                cache_key : 'GET_CONSOL_LOCK_ENTITIES'
                            }
                        }else if(workspaceFactory.activeScreen.screen_key == 10769){
                            json_obj = {
                                me_leid: _data.rowData.SOURCE_SYSTEM_FLAG,
                                me_leid_name: _data.rowData.SOURCE_SYSTEM_FLAG_NAME,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'domain'
                            }
                        }
                        else if(workspaceFactory.activeScreen.screen_key == 11133){
                            json_obj = {
                                me_leid: _data.rowData.CONSOL_GROUP_KEY,
                                me_leid_name: _data.rowData.GROUP_NAME,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'partnership',
                            }
                        }
                        else if(workspaceFactory.activeScreen.screen_key == 11413){
                            json_obj = {
                                me_leid: _data.rowData.CONSOL_GROUP_KEY,
                                me_leid_name: _data.rowData.GROUP_NAME,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'WWNI',
                            }
                        }
                        else if(workspaceFactory.activeScreen.screen_key == 11614){
                            json_obj = {
                                me_leid: _data.rowData.CONSOL_GROUP_KEY,
                                me_leid_name: _data.rowData.GROUP_NAME,
                                lock_y_n: _data.rowData.LOCK_Y_N == 1 ? 'Y' : 'N',
                                class_type:'partnership_credit',
                            }
                        }

                        var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                        var params = {
                            tax_year:filterParams.tax_year,
                            scenario:filterParams.scenario,
                            jcd_key: GlobalService.globalParams.jcd_key,
                            parent_me_string:filterParams.parent_me_string,
                            lockedjsonObj: JSON.stringify(json_obj)
                        }
                        var url = GENERAL_CONFIG.base_url + '/saveEntityBusinessConsolLock'
                        JsonObjectFactory.saveJSON(url,params).then(function(data){
                            if(data.callSuccess == "1"){
                                AlertService.add("success","Entitiy Lock saved Successfully",4000);
                                var args = {
                                };
                                
                                $rootScope.$emit('gridUpdate', args);
                                defer.resolve(data);
                                if(json_obj.cache_key !=null){
                                    factory.refreshCacheObjectbyKey(json_obj.cache_key,params.tax_year,params.scenario,params.jcd_key);
                                }
                                
                            }else{
                                AlertService.add("error", data.errorMessage);
                            defer.reject(data);
                            }
                        },function(data){
                                defer.reject(data);
                        });
                        return defer.promise;
                    
                    }
                    factory.getBusinessForLock = function(_params){
                            var promise = $http({
                                method: "post",
                                url: GENERAL_CONFIG.base_url + '/getBusinssLockList',
                                data: _params
                            }).then(function (response) {
                                return response.data;
                            });
        
                            return promise;
                    };
                return factory;

            }
        ]);
        return services;
})