define([
'angular',
'./firEpBumpSummaryCtrl',
'./firEpBumpSummaryService'

], function () {
'use strict';
return angular.module('app.firEpBumpSummary', ['app.firEpBumpSummaryCtrl','app.firEpBumpSummaryService'] )



});