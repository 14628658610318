define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.tagging163jService',[])

        .factory("Tagging163jFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,JsonObjectFactory,workspaceFactory,GENERAL_CONFIG) {
            var factory = {};

            var URLS = {
                FETCH: GENERAL_CONFIG.base_url + '/getProjectTag',
                UPDATE: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=6awamo',
                UPDATES: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=zopevh',
                DEFAULTS: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=0swobv',
                RESET: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=73go4u',
                SYNCFORM: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=fo0v1g'
            }
            
            factory.saveProjectTag = function(_tags){

                var data = {jsonObj:JSON.stringify(_tags)}
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonSettings = [{
	                tax_year:filterParams.tax_year,
	                scenario:filterParams.scenario,
	                jcd_key:GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
	   			     }];
                var params = _.merge({},  filterParams , data);
                params= _.merge({},  GlobalService.globalParams , params);
                var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);



                var promise = $http({
                    method: "post",
                    url: URLS.UPDATE,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });


                return promise;
            }
            
            factory.saveSystemProjectTag = function(_tags){

                var data = {jsonObj:JSON.stringify(_tags)}
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonSettings = [{
	                tax_year:filterParams.tax_year,
	                scenario:filterParams.scenario,
	                jcd_key:GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
	   			     }];
                var params = _.merge({},  filterParams , data);
                params= _.merge({},  GlobalService.globalParams , params);
                var params = _.extend({jsonSetting: JSON.stringify(jsonSettings)}, params);



                var promise = $http({
                    method: "post",
                    url: URLS.UPDATES,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });


                return promise;
            }
            
            factory.applyDefaults = function(_data,Details){
				
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				var jsonObj = JSON.stringify(Details);
				var jsonSettings =  JSON.stringify(_data);
				
				console.log(jsonSettings);
				var params = filterParams;
				params.combination_key = _data.combination_key;
				params.sso_id = _data.sso_id;
				params = _.merge({},  GlobalService.globalParams , params);
				params =  _.extend({clob_data:jsonObj}, params);
				params =  _.extend({clob_settings:jsonSettings}, params);
				params.process_name =  "APPLY_DEFAULT_163J_TAGS";
				console.log(params);
				var promise = $http({
					method: "post",
					url: URLS.DEFAULTS,
					data: params
				}).then(function (response) {
					var status = response.status;
					return response;
				});
				return promise;
			};
			
         factory.resetDefaults = function(_data,Details){
				
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				var jsonObj = JSON.stringify(Details);
				var jsonSettings =  JSON.stringify(_data);
				
				console.log(jsonSettings);
				var params = filterParams;
				params.combination_key = _data.combination_key;
				params.sso_id = _data.sso_id;
				params = _.merge({},  GlobalService.globalParams , params);
				params =  _.extend({clob_data:jsonObj}, params);
				params =  _.extend({clob_settings:jsonSettings}, params);
				params.process_name =  "RESET_DEFAULT_163J_TAGS";
				console.log(params);
				var promise = $http({
					method: "post",
					url: URLS.RESET,
					data: params
				}).then(function (response) {
					var status = response.status;
					return response;
				});
				return promise;
			};
			
			factory.syncForm = function(_data,Details){
				
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				var jsonObj = JSON.stringify(Details);
				var jsonSettings =  JSON.stringify(_data);
				
				console.log(jsonSettings);
				var params = filterParams;
				params.combination_key = _data.combination_key;
				params.sso_id = _data.sso_id;
				params = _.merge({},  GlobalService.globalParams , params);
				params =  _.extend({clob_data:jsonObj}, params);
				params =  _.extend({clob_settings:jsonSettings}, params);
				params.process_name =  "SYNC_163J_TO_FORM_8990";
				console.log(params);
				var promise = $http({
					method: "post",
					url: URLS.SYNCFORM,
					data: params
				}).then(function (response) {
					var status = response.status;
					return response;
				});
				return promise;
			};



            return factory;

        }])


    return services;


});