const {VERSION} = require("lodash");

define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.newProformaGtwCtrl', [])
    .controller('newProformaFormCtrl', ['$scope', 'AlertService','NewProformaFactory','workspaceFactory', 
    '$timeout','GENERAL_CONFIG','dataFormatterFactory','GridFactory','NewProformaTabFactory','$state','$rootScope', newProformaFormCtrl]);
    function newProformaFormCtrl($scope,AlertService,NewProformaFactory, workspaceFactory, 
        $timeout,GENERAL_CONFIG,dataFormatterFactory,GridFactory,NewProformaTabFactory,$state,$rootScope) {
        var vm = this;

        console.log('I am called');
        $scope.$on("proformaView.addTab", function (event, data) {
            console.log('first');
            vm.addTabData(data);
        });
        $scope.tabList = [];
        vm.tabList=[];
        vm.addTabData = function (data) {
            var tooltip = data.elementName;
            var isFound = _.find(vm.tabList, function (item) {
                return item.code == tooltip;
            }) 
            if (isFound != undefined) {
                AlertService.add("warn", "Tab is already open ", 1000);
                setTimeout(function() {
                    $state.transitionTo('workspace.newproformagtw.formreview',{id: isFound.code})
                 });
                  vm.workflowTab(isFound);
            }  
            else {
                var entity = {
                    name: data.leName,
                    code: data.elementName,
                    data: data,
                    tooltip: tooltip,
                    loading_data:false,
                    link: 'workspace.newproformagtw.formreview({'+'id:'+data.elementName+'})',
                    active:false
  
                }
                 vm.tabList.push(entity);
                NewProformaFactory.setTabData(vm.tabList);
                $scope.tabList = NewProformaFactory.tabList;
                $timeout(function() {
                    if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
                        vm.scrlTabsApi.init();
                    }
                });
                setTimeout(function() {
                    $state.transitionTo('workspace.newproformagtw.formreview',{id: entity.code})
                 });
                  vm.workflowTab(entity);
            }
            
            console.log('tabList',vm.tabList);
               /*  setTimeout(function () {
                    $scope.$apply();
                }, 500); */
        }
        
        if($state.current.name=='workspace.newproformagtw'){
            $state.go('workspace.newproformagtw.gtwview12');
        }
        vm.updateFn = function (arg) {
            var obj = [];
            /* vm.tabList = _.each(vm.tabList,function(i){
                if(i.active){
                    i.active = !i.active;
                }
            }) */
             obj = vm.tabList.find(link => link.code === arg.tabScrollTitle);
            // obj.active = true;
           setTimeout(function() {
              $state.go('workspace.newproformagtw.formreview',{id: arg.tabScrollTitle})
           });
            vm.workflowTab(obj);
        }
        if(NewProformaFactory.tabList && NewProformaFactory.tabList.length>0){
            vm.tabList = NewProformaFactory.tabList;
        }
     
        vm.cancel = function (tab) {
            var index = _.findIndex(vm.tabList, tab);
            vm.tabList.splice(index, 1);
            vm.tabId = '';
            // redirecting to formreview list page if no tabs
            if(vm.tabList.length == 0) {
                setTimeout(function() {
                    $state.transitionTo('workspace.newproformagtw.gtwview12');
                });
            }
            
            NewProformaFactory.setTabData(vm.tabList)

        }
        // used to clear data to avoid styling conflicts on workflow tab selections.
        vm.tabId = '';
        vm.workflowTab = function(tabData) {

            vm.tabId = tabData;
            var tabId = vm.tabId && vm.tabId.code;
            vm.tabList = _.each(vm.tabList,function(i){
                if(i.active){
                    i.active = !i.active;
                }
                if(i.code == tabData.code){
                    i.active = true;
                }
            })
            var _data = _.filter(NewProformaFactory.tabList,{code:tabId});
            _data.active = true;
            vm.leName = _data[0].data.leName;
            vm.schedule = _data[0].data.schedule;
            vm.line = _data[0].data.line;
            vm.colId = _data[0].data.colId;
            
            vm.lineTotal = dataFormatterFactory.getFormatter("Number").func(_data[0].data.lineTotal.split(',').join(''));
            vm.params = {
                sso_id: _data[0].data.sso,
                tax_year: _data[0].data.taxYear,
                scenario: _data[0].data.scenario,
                jcd_key: _data[0].data.jcdKey,
                source_system: _data[0].data.sourceSystem,
                summary_level: _data[0].data.summaryLevel,
                me_codes: _data[0].data.meCodes,
                combination_key:_data[0].data.combinationKeys,
                form: _data[0].data.form,
                schedule: '',
                part_no: '',
                line: '',
                element: _data[0].data.elementName
            }
            $timeout(function() {
                getData(tabId);
            }, 5)
          $scope.action_key = 32033;
          $scope.grid_key = 5719;
        }

    

        vm.api = GENERAL_CONFIG.base_url +  "/loadGridJson?action_id=32033&grid_id=5719"
        $scope.ctrlFn = function(_callName,_data,_type) {


        }
        var getData = function(tabId) {
           /*  if(vm.tabList && vm.tabList.length==1){
                $timeout(function(){
                    $rootScope.$broadcast("showLoader", true);            
                },20);  
            }else{
                $timeout(function(){
                    $rootScope.$broadcast("showLoader", true);            
                },5);  
            } */
             vm.loading = true;
            $timeout(function(){
                $rootScope.$broadcast("showLoader", vm.loading);            
            },5);      
           
            vm.gridData = [];
            vm.gridData.DATA = [];
            var data = {
                api: vm.api,
                params: vm.params,
                tabId: tabId
            }
            NewProformaTabFactory.getTabGridData(data).then(function(response){
                $timeout(function(){
                    $rootScope.$broadcast("showLoader", vm.loading);            
                },5);     
                if (response.gridData && response.gridData.callSuccess === "1") {
                    vm.data = response.gridData;
                    console.log('response',response);
                    $rootScope.$broadcast("dataLoaded", response.gridData);
                    // $rootScope.$broadcast("showLoader", false);
                    $timeout(function(){
                        $rootScope.$broadcast("showLoader", false);            
                    },20);   

                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            })
        }
        $scope.openModal = function (modalObj,_data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
    }


    return controllers;
});

