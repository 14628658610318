define(
    [ 'angular'

        ],
        function() {
        'use strict';

        var controllers = angular.module('app.controversyProjLibCtrl', [])
        .controller('controversyProjLibController', [ controversyProjLibController])


        function controversyProjLibController() {

        }
        return controllers;
    })