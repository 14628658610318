define([
    'angular'
], function () {
    'use strict';


    var controllers = angular.module('app.schedule-GCtrl', [])
            .controller('scheduleGCtrl', ['GlobalService','$scope', 'JsonObjectFactory','$parse', 'scheduleGServiceFactory', 'workspaceFactory', '$state', '$window',
					   'AlertService', 'GENERAL_CONFIG', 'ModalFactory',scheduleGCtrl])
			.controller('scheduleGCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory', '$parse','scheduleGServiceFactory', 'workspaceFactory', '$state', '$window',
			'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',scheduleGCtrlModal]);


			function scheduleGCtrl(GlobalService,$scope, JsonObjectFactory, $parse, scheduleGServiceFactory, workspaceFactory, $state, $window,
				AlertService, GENERAL_CONFIG, ModalFactory) {
					scheduleGCtrlModal(GlobalService,$scope, JsonObjectFactory, $parse, scheduleGServiceFactory, workspaceFactory, $state, $window, AlertService, GENERAL_CONFIG,ModalFactory, null,
						null, null, null,this);
				}


            function scheduleGCtrlModal(GlobalService,$scope, JsonObjectFactory,  $parse, scheduleGServiceFactory, workspaceFactory, $state, $window,
            		AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,
					rowData, colData, gridData,thisObj) {}

});

