define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.CreateSytemAccountCtrl', []);

    controllers.controller('CreateSytemAccountCtrl', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory',
       'AlertService','JsonObjectFactory','SystemGroupObjFactory','SystemMappingFactory','rowData','USER_SETTINGS', 'GENERAL_CONFIG',createSytemAccountCtrl])
    
    function createSytemAccountCtrl ($rootScope,$scope,$http,$timeout,GlobalService,$uibModalInstance,ModalFactory,AlertService,JsonObjectFactory,SystemGroupObjFactory,SystemMappingFactory,rowData,USER_SETTINGS,GENERAL_CONFIG) {

    	// TODO: the hard coded jcd_key in this file will be removed once the global params bug is fixed
   	 var vm = this;
   	vm.userSettings = USER_SETTINGS;
    var user = vm.userSettings.user;
    vm.clients = user.clients;
   	 vm.accountsTableData = [];
   	 var lookupData = [];
     var lookupData1 = [];
     var lookupData2 = [];
     var lookupData3 = [];
     var lookupData4 = [];
     vm.accountTypes = [];
     vm.sys_acc_dropdown_data = [];
     vm.sourceSystemList = [];
     vm.selectedFromSystemAcct = '';
     vm.clientList = [];
     vm.selectedClientList = [];
     vm.tempClientHolderList = [];
     vm.sysAcctObj = {};
     vm.clientsSelected = false;
     vm.createFrom = '';
     var isJsonBuilt = false;
     vm.showAcctError = false;
     vm.showAcctDescError = false;
     vm.starter_ds='';
     function init() {
 		fetchSystemAcctLookup();
 		
 		vm.loading = true;
 		getAcctTypeList();
 		getSysAcctDropdownList();
 		getSourceSystemList();
 		getSysChartList();
 		getFormChartList();
 		getClientDropdownList();
 	}
 	init();
	$scope.crudLoading = false;
	var sampleObject = {
		form : '',
		schedule : '',
		line : '',
		column : '',
		section : '',
		part : '',
		form_chart_key : '',
		form_seq_key: ""
	};
	vm.formLineMapping = [Object.assign({},sampleObject)];
	 
 
 	
 	$scope.yourEvents = {onItemSelect: function(item) {
		item.is_active = 'A';
		if(vm.selectedClientList.length>0){
			vm.clientsSelected = true;
		}
		
		},onItemDeselect: function(item) {
			item.is_active = 'N';
			if(vm.selectedClientList.length==0){
				vm.clientsSelected = false;
			}
		}
	};
 	

   	 vm.removeEntity = function(_index){
   		var item = vm.selectedClientList[_index];
   		vm.selectedClientList.splice(_index,1);
   		item.is_active = 'N';
   		if(vm.selectedClientList.length==0){
   			vm.clientsSelected = false;
   		}
     }
   	 
   	 vm.sys_acc = {};
   	 vm.orderByField = 'FORM';
        vm.reverseSort = false;
        vm.showDetails = {text:'lookup'};

        vm.sort_key = {'1':0,'2':0,'3':0,'4':0,'5':0};
        vm.lookUpMapping = false;
       
   	
   	 vm.sortBy = function(key,value){
                     	switch(value){
                     		case 0:
                     		vm.sort_key[key] = 1;
                     		vm.reverseSort = false;
                     		break;
                     		case 1:
                     		vm.sort_key[key] = 2;
                     		vm.reverseSort = true;
                     		break;
                     		case 2:
                     		vm.sort_key[key] = 0;
                     		vm.reverseSort = "";
                     		break;
                     	}
        }
   	 /** This function hits the DB and retrieves System as well as Local Data **/
   //
   	 vm.cancel = function(){
        $uibModalInstance.dismiss('cancel');
   	 	};
   	  vm.reset = function(){
   	 		 $scope.crudLoading = false;
   	 		 init();
   	 		 vm.chartacct_data = {
   					 localacct:rowData.LOCAL_ACCT_DISPLAY,
   					 chart:rowData.CHART_DISPLAY,
   	 				 systemacct:rowData.ACCT,
   	 				 systemacct_des:rowData.ACCT_DESC,
                        acctType:rowData.ACCT_TYPE,
                        tdcCode:rowData.TDC_CODE
   	 		 }
   	   };

       vm.resetLookup = function(){
			
			vm.formLineMapping =[];
			vm.formLineMapping.push(Object.assign({},sampleObject));
	   };

	   vm.addNewFormLineMapping = function () {
			vm.formLineMapping.push(Object.assign({},sampleObject));
		};
       
       
       
   	 vm.formLookupFlagContent = true;

          vm.toggleLookup = function () {
			vm.lookUpMapping = !vm.lookUpMapping;
			vm.showDetails.text = 'lookup';
			vm.changeddropdownflag = false;
			vm.formLineMapping =[];
			vm.formLineMapping.push(Object.assign({},sampleObject));
      };
      
     
      // get new system account pop up related data which is Account Type, Source System, Client Dropdown
      function getAcctTypeList(){
    	  var params = {
   	   			"action_code": 've7098',
   	   			"tax_year": GlobalService.globalParams.tax_year,
   	   			"jcd_key":250
   	   		 } 
 	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
 	        //	 console.log("Response Data ::",data);
 	             if (data.callSuccess === "1" ) {
 	            	 vm.accountTypes = data.jsonObject;
                    //  console.log(vm.loadSystemAndLocalAccountData,"Check Here Y1");
 	             }
 	             else
 	               {
 	            	 if(data.errorMessage === "no access"){
 	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
 	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
 	               }
 	            	 else
 	            	 {
 	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
 	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
 	                 }
 	             }

 	         });
 	     };
 	     
 	    function getSysAcctDropdownList(){
 	    	if(!vm.sys_acc_dropdown_data.length>0){ 
 	    	
 	    	  var params = {
 	   	   			"action_code": '0q4gwm',
 	   	   			"tax_year": GlobalService.globalParams.tax_year,
 	   	   			"jcd_key": 250
 	   	   		 } 
 	 	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
 	 	             if (data.callSuccess === "1" ) {
 	 	            	 vm.sys_acc_dropdown_data = data.jsonObject;// [];
 	 	             }
 	 	             else
 	 	               {
 	 	            	 if(data.errorMessage === "no access"){
 	 	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
 	 	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
 	 	               }
 	 	            	 else
 	 	            	 {
 	 	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
 	 	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
 	 	                 }
 	 	             }

 	 	         });
 	 	     }
 	    	
 	    	}; 
 	    	
 	    	function getSysChartList() {
 	            var params = {
 	                "action_code": 'yw3l4h',
 	                "tax_year": GlobalService.globalParams.tax_year,
 	               "jcd_key":250
 	            }
 	           // vm.sysdropdownloading = true;
 	            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
 	                if (data.callSuccess === "1") {
 	                	 vm.sysChartList = [];
 		                   vm.sysChartList = data.jsonObject;
 	                     
 	                } else {
 	                    if (data.errorMessage === "no access") {
 	                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
 	                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
 	                    } else {
 	                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
 	                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
 	                    }
 	                }

 	            });
 	        }; 
 	        
 	       function getFormChartList() {
	            var params = {
	                "action_code": 'wkl7ky',
	                "tax_year": GlobalService.globalParams.tax_year,
	                "jcd_key":250
	            }
	            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
	                if (data.callSuccess === "1") {
	                   vm.formChartList = [];
	                   vm.formChartList = data.jsonObject;
	                     
	                } else {
	                    if (data.errorMessage === "no access") {
	                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
	                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	                    } else {
	                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
	                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	                    }
	                }

	            });
	        };
 	     
 	     
 	    function getSourceSystemList(){
   	     	var params = {
   	   			"action_code": 'umsehd',
   	   			"tax_year": GlobalService.globalParams.tax_year,
   	   		    "jcd_key":250
   	   		 }
   	     	vm.sysacctloading = true;
   	     //	console.log("Input Data fetchSystemAcctData**********::",params);
   	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
   	        	// console.log("Response Data ::",data);
   	             if (data.callSuccess === "1" ) {
   	            	vm.sourceSystemList = data.jsonObject;
   	             }
   	             else
   	               {
   	            	 if(data.errorMessage === "no access"){
   	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
   	               }
   	            	 else
   	            	 {
   	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
   	                 }
   	             }

   	         });
   	     };
   	     
   	  function getClientDropdownList(){
	   		var arrayLength = vm.clients.length;
	
			for (var i = 0; i < arrayLength; i++) {
				if(vm.clients[i].client_key != 0){ 
					var displayClientObj = {}
					displayClientObj["id"] = vm.clients[i].client_datasource;
					displayClientObj["label"] = vm.clients[i].client_name;
					displayClientObj["is_active"] = 'N';
					console.log(displayClientObj);
					vm.clientList.push(displayClientObj);
				}else{
					vm.starter_ds =  vm.clients[i].client_datasource;
				}
			}
			vm.loading = false;
 	  }; 
 	     
 	     //////////////////////////////////////////////////////////////////////////////////////////////////////
      
	     
   	     

          function fetchSystemAcctData(){
   	     	var params = {
   	   			"action_code": 'jpwsqv',
   	   			"tax_year": rowData.TAX_YEAR,
   	   			"local_acct": rowData.LOCAL_ACCT_KEY,
   	   			"sys_acct": rowData.ACCT_KEY,
   	   			"jcd_key":250
   	   			//"sys_acct": (typeof vm.sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY:vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY

   	   		 }
   	     	vm.sysacctloading = true;
   	     //	console.log("Input Data fetchSystemAcctData**********::",params);
   	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
   	        	// console.log("Response Data ::",data);
   	             if (data.callSuccess === "1" ) {
   	            	 vm.loadSystemAndLocalAccountData = data.jsonObject;
   	            	 if(vm.loadSystemAndLocalAccountData.length > 0){
   	            	//	 console.log ('yes, show the botton table');
   	            	 }
   	            	 vm.sysacctloading = false;
   	            	// console.log(data.jsonObject,"Check Here Y1");
                      //  console.log(vm.loadSystemAndLocalAccountData,"Check Here Y1");
   	             }
   	             else
   	               {
   	            	 if(data.errorMessage === "no access"){
   	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
   	               }
   	            	 else
   	            	 {
   	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
   	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
   	                 }
   	             }

   	         });
   	     };
    
          function fetchSystemAcctLookup(){
        		  var params = {
                          "action_code": 'sv6aun',
                          "tax_year": GlobalService.inputs.tax_year.selected.value,
                          "jcd_key": 250
                      } 
              return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                  if (data.callSuccess === "1" ) {

               	   vm.loadSystemAcctLookupTemp = data.jsonObject;
                      vm.loadLookupForm = [];
                      vm.loadLookupSchedule = [];
                      vm.loadLookupLine = [];
                      vm.loadLookupColumn = [];
                      vm.loadLookupSysMapAcct = [];
                      vm.loadLookupSysMapAcct1 = [];
                      vm.lookupcheckdata = data.jsonObject;
                      for(var index_ in data.jsonObject ){
                          vm.loadLookupForm.push(data.jsonObject[index_].FORM);
                      }
                      vm.loadLookupForm = _.uniq(vm.loadLookupForm, JSON.stringify)
                  }
                  else
                  {
                      if(data.errorMessage === "no access"){
                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                          vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                      }
                      else
                      {
                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                          vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                      }
                  }

              });
          };
 
        vm.formDelete =  function(_item){
            var index = _.findIndex(vm.loadSystemAndLocalAccountData, { 'FORM_ACCT_KEY': _item.FORM_ACCT_KEY });
                           //HERE WE WOULD CALL SERVICE DELETE
            var objSettings = [];
            var returnObj = {};
            returnObj.type = 'D';
            returnObj['FORM_ACCT_KEY']  =  _item.FORM_ACCT_KEY 
            returnObj["object_id"]= rowData.object_id
            returnObj['system_acct_key'] = rowData.SYS_ACCT_KEY;
            returnObj['tax_year'] = rowData.TAX_YEAR;
            returnObj['jcd_key'] =  GlobalService.globalParams.jcd_key; 
            returnObj['scenario'] = GlobalService.globalParams.scenario;
            returnObj['screen_key'] = GlobalService.globalParams.screen_key;
            var IRSFormDetails = angular.copy(vm.loadSystemAndLocalAccountData);
        }
        
   		 vm.formEdit = function(_item,index){
   				 	
   			       vm.CHECK_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT; 
   			       vm.SYSTEM_ACCT_KEY = _item.ACCT_KEY; 
   			       vm.FORM_ACCT_KEY = _item.FORM_ACCT_KEY; 
   			       vm.old_form_acct_key = _item.FORM_ACCT_KEY;
   			       vm.showDetails.text = 'edit';
   			       vm.currentEditedIndex = index;
   			       
   			       vm.changeddropdownflag = true;
   			       
   			       
   			       
   			       vm.lookUpMapping = true;
   			       vm.CHECK_FORM = _item.FORM;
   			       var b = _item.FORM.trim();
   			       vm.CHECK_SCHEDULE = _item.SCHEDULE;
   			       var c =_item.SCHEDULE.trim();
   			       vm.CHECK_LINE = _item.LINE+" - "+_item.LINE_DESC;
   			       var d =_item.LINE+" - "+_item.LINE_DESC.trim();
   			       vm.CHECK_COL = _item.COL;
   			       vm.CHECK_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT;
   			       vm.formEditFlagContent = true;
   			        var testdata = vm.lookupcheckdata;
   			       var nexttestLookupDataschedule =[];
   			       var scheduletestvalue  = testdata.filter(function(object,index){
   			        //  console.log(object,index)
   			       if(object["FORM"] ==  b )
   			       {
   			               return true;
   			       }
   			       });
   			
   			     scheduletestvalue.forEach(function(object,index){
   			        nexttestLookupDataschedule.push(object["SCHEDULE"]);
   			     });
   			      nexttestLookupDataschedule =  _.uniq(nexttestLookupDataschedule, JSON.stringify);
   			      vm.loadLookupSchedule = nexttestLookupDataschedule;
   			      var nexttestLookupDataLine =[];
   			
   				   var Linetestvalue  = testdata.filter(function(object,index){
   					  //     console.log(object,index)
   					    if(object["FORM"] ==  b  && object["SCHEDULE"] ==  c )
   					    {
   					            return true;
   					    }
   				   });
   			
   				Linetestvalue.forEach(function(object,index){
   				    nexttestLookupDataLine.push(object["LINE"]);
   				   });
   			   nexttestLookupDataLine =  _.uniq(nexttestLookupDataLine, JSON.stringify);
   			   vm.loadLookupLine = nexttestLookupDataLine;
   			
   			 var nexttestLookupDataCol =[];
   			 var COltestvalue  = testdata.filter(function(object,index){
   			     if(object["FORM"] ==  b  && object["SCHEDULE"] ==  c  && object["LINE"] == d)
   			     {
   			             return true;
   			     }
   			});
   			
   			 COltestvalue.forEach(function(object,index){
   			     nexttestLookupDataCol.push(object["COL"]);
   			   });
   			    nexttestLookupDataCol =  _.uniq(nexttestLookupDataCol, JSON.stringify);
   			  vm.loadLookupColumn= nexttestLookupDataCol;
             }
   		 
   		 vm.formAdd = function(){
   			vm.showDetails.text = 'add';
   			vm.lookUpMapping = true;
   			vm.changeddropdownflag = true;
   		    
			vm.CHECK_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT;
			vm.formLineMapping =[];
			vm.formLineMapping.push(Object.assign({},sampleObject));
					  
   		 }
        
         vm.cancel = function(){
             $uibModalInstance.dismiss('cancel');
         };
         
         vm.newSysAccountChanged = function(){
     		if(vm.newSysAccount.length>0){
     			vm.showAcctError= false;
     		}
     		}
     		
     		vm.newSysAccountDescChanged = function(){
     			if(vm.newSysAccountDesc.length>0){
     				vm.showAcctDescError= false;
     				}
     		}
              
              vm.taxTypeDDsettings = {
                      scrollableHeight: '200px',
                      left: '94px',
                      scrollable: true,
                      enableSearch: true
              };
         
         function  validateData(){
        	 var errorStr = '';
			 var iserrorStrBuilt = false;
			 var haveError = false;
        	 if(vm.newSysAccount=="" || vm.newSysAccount ==null){
				 haveError = true;
        		 vm.showAcctError = true;
			 }
        	 if(vm.newSysAccountDesc=="" || vm.newSysAccountDesc ==null){
				 haveError = true;
        		 vm.showAcctDescError = true;
			 }
        	 if(vm.acctType.ACCT_TYPE=="" || vm.acctType.ACCT_TYPE ==null){
				 haveError = true;
        		 errorStr = errorStr + " Account Type,";
        		 iserrorStrBuilt = true;
			 }
        	 if(vm.sourceSystem.SOURCE_SYSTEM=="" || vm.sourceSystem.SOURCE_SYSTEM ==null){
				 haveError = true;
        		 errorStr = errorStr + " Source system,";
        		 iserrorStrBuilt = true;
			 }
        	 if(vm.selectedSysChart=="" || vm.selectedSysChart ==null){
				 haveError = true;
        		 errorStr = errorStr + " System chart,";
        		 iserrorStrBuilt = true;
			 }
        	 if(iserrorStrBuilt){
				 haveError = true;
        		 AlertService.add("danger", "Please select " + errorStr, 3000);
        	 }
        	
             if(vm.isClientSpecific && vm.selectedClientList.length <= 0){
				 haveError = true;
            	 AlertService.add("danger", "Please select atleast 1 client", 3000);
            	 }
			 if(vm.createFrom === 'fromExistingSysAcct'){
				 if(vm.selectedFromSystemAcct == "" || vm.selectedFromSystemAcct == null){
					 haveError = true;
					 AlertService.add("danger", "Please select from system account", 3000);
				 } 
			 }
		     if(vm.createFrom === 'fromNewFormMapping'){
				for(var formLine of vm.formLineMapping){
					if(!formLine.form || !formLine.schedule || !formLine.column || !formLine.section || !formLine.part || !formLine.form_chart_key){
						haveError = true;
						AlertService.add("danger", "Please select all possible form mapping details", 3000); 
					} 
				}
			 }
			 return haveError;
         }
         
   		function  buildJson(){
   			var clientObj = {};
   			var clientObj1 = {};
   			var clientObj2 = {};
   			var selectedClientsArray = [];
   			var formMappingObj = {};
   			
   			vm.sysAcctObj['account']= vm.newSysAccount;
   			vm.sysAcctObj['account_desc'] = vm.newSysAccountDesc;
   			vm.sysAcctObj['account_type'] = vm.acctType.ACCT_TYPE;
   			vm.sysAcctObj['source_sys'] =  vm.sourceSystem.SOURCE_SYSTEM;
   			vm.sysAcctObj['is_client_specific'] = vm.isClientSpecific ==1 ? true : false;
   			vm.sysAcctObj['sys_chart_key'] =  vm.selectedSysChart;
   			
   			
   			vm.sysAcctObj['starter_ds'] =  vm.starter_ds;
   			
			vm.sysAcctObj['fromSysAcctKey'] = vm.createFrom === 'fromExistingSysAcct'?vm.selectedFromSystemAcct:'';
			vm.sysAcctObj['form_data'] = vm.formLineMapping;
		   								   
		    if(!vm.sysAcctObj.is_client_specific){
   				angular.forEach(vm.clientList, function(obj, key) {
   				  obj.is_active = 'A';
   				selectedClientsArray.push(obj.label);
   				});
   			}else{
   				angular.forEach(vm.clientList, function(obj, key) {
     				  if(obj.is_active == 'A'){
     					 selectedClientsArray.push(obj.label);
     			     }
   				});
   			}
		    if(vm.selectedClientList.length > 0){
		    	angular.forEach(vm.selectedClientList, function(obj, key) {
	   				  obj.is_active = 'A';
	   				selectedClientsArray.push(obj.label);
	   				});
		    }
   				vm.sysAcctObj['selectedClients'] = vm.clientList;
   				vm.sysAcctObj['selectedClientsString'] =  selectedClientsArray.join(',');
   				isJsonBuilt = true;
   				
   		}
        
   		 vm.save = function(){
   			    if(validateData()){
					   return ;
				   }
   			    buildJson();
   				 if(isJsonBuilt){
   					vm.loading = true; 
	   				 SystemMappingFactory.createNewSysAccount(vm.sysAcctObj).then(function (data) {
	   					if (data.callSuccess === 1 ) {
	   					 AlertService.add("success", "New system account created successfully", 3000);
	   		            }else { 
	   		            	var failedClients = '';
	   		            	if(data.failedCients.includes(",")){
                                failedClients =data.failedCients.join();
	   		            	}else {
	   		            		 failedClients =data.failedCients;
	   		            	} 
	   		             AlertService.add("danger", "System account creation failed for " + failedClients +" .Select failed clients and try again after sometime", 4000);
	   		            }
	   					vm.loading = false; 
	   					vm.cancel();
	   		         }) 
	   		     }
        };

        function IRSFormCRUDHttp(returnObj,IRSFormDetails, message) {
       	 if(returnObj.type == 'D'){ 
       		 SystemGroupObjFactory.deleteSystemFormDetails(returnObj,IRSFormDetails).then(function (result) {
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                        $scope.crudLoading = false;
                    } 
                })
       	 }else{
           	 SystemGroupObjFactory.saveIRSFormDetails(returnObj,IRSFormDetails).then(function (result) {
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        AlertService.add("error", result.errorMessage, 4000);
                        $scope.crudLoading = false;
                    } else {
                        AlertService.add("success", message, 4000);
                        vm.loadSystemAndLocalAccountData = angular.copy(IRSFormDetails);
                        $scope.crudLoading = false;
                    }
                });
       	 }
            
        }
        
		vm.getLookupNext = function(key,nextLookupKey,currentLookupValue){
			var lookupData = angular.copy(vm.lookupcheckdata);
			var a = "SYS_ACCT";
				
			switch(key){
				case 1:
					vm.loadLookupSchedule =  filterDataByKey("FORM",nextLookupKey,lookupData,currentLookupValue);
					break;
				case 2:
					vm.loadLookupLine =  filterDataByKey1("SCHEDULE",nextLookupKey,lookupData1,currentLookupValue);
					break;
				case 3:
					vm.loadLookupColumn =  filterDataByKey2("LINE",nextLookupKey,lookupData2,currentLookupValue);
					vm.loadLookupSysMapAcct = filterDataByKey22("LINE",a,lookupData2,currentLookupValue);
					break;
				case 4:
					vm.loadLookupSection=  filterDataByKey3("COL",nextLookupKey,lookupData3,currentLookupValue);
					break;
				case 5:
					vm.loadLookupPart=  filterDataByKey4("SECTION",nextLookupKey,lookupData4,currentLookupValue);
					break;
				// case 6:
				// 	vm.loadLookupSysMapAcct=  filterDataByKey5("PART",nextLookupKey,lookupData3,currentLookupValue);
				// 	break;
				
			}
		}

   		function filterDataByKey(currentLookupKey,nextLookupKey,lookupData,currentLookupValue){
   			    var nextLookupData = [];
   			    var index  = _.findIndex(vm.loadSystemAcctLookupTemp, { 'FORM': currentLookupValue })
   			    vm.FORM_ACCT_KEY = vm.loadSystemAcctLookupTemp[index].FORM_ACCT_KEY;
   		   	    var currentLookupFilter = lookupData.filter(function(object,index){
   			    if(object[currentLookupKey] == currentLookupValue)
   			    {
   			    	    return true;
   			    }
   			    });
   		
   			   currentLookupFilter.forEach(function(object,index){
   			        nextLookupData.push(object[nextLookupKey]);
   			   });
   			  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
   			  lookupData1 = currentLookupFilter;
   		   	//  console.log(lookupData1 , "check Here IMPORTANT inside");
   			  return nextLookupData ;
   		}
   	  
   	function filterDataByKey1(currentLookupKey,nextLookupKey,lookupData1,currentLookupValue){
   	    var nextLookupData = [];
      	    var currentLookupFilter = lookupData1.filter(function(object,index){
   		    if(object[currentLookupKey] == currentLookupValue)
   		    {
   		    	    return true;
   		    }
   	    });

   	   currentLookupFilter.forEach(function(object,index){
   	        nextLookupData.push(object[nextLookupKey]);
   	   });
   	  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
   	  lookupData2 = currentLookupFilter;
   	  return nextLookupData ;
   	}
   	  
   	function filterDataByKey2(currentLookupKey,nextLookupKey,lookupData2,currentLookupValue){
   	    var nextLookupData = [];
      	    var currentLookupFilter = lookupData2.filter(function(object,index){
   		    if(object[currentLookupKey] == currentLookupValue)
   		    {
   		    	    return true;
   		    }
   	    });

   	   currentLookupFilter.forEach(function(object,index){
   	        nextLookupData.push(object[nextLookupKey]);
   	   });
   	  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
   	  lookupData3 = currentLookupFilter;
   	  return nextLookupData ;
   	}

   	function filterDataByKey22(currentLookupKey,a,lookupData2,currentLookupValue){
           var nextLookupData = [];
         
           var currentLookupFilter = lookupData2.filter(function(object,index){
   	        if(object[currentLookupKey] == currentLookupValue)
   	        {
   	                return true;
   	        }
           });

          currentLookupFilter.forEach(function(object,index){
               nextLookupData.push(object[a]);
               
          });
         nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
         return nextLookupData ;
       }
   	function filterDataByKey3(currentLookupKey,nextLookupKey,lookupData3,currentLookupValue){
		var nextLookupData = [];
			var currentLookupFilter = lookupData3.filter(function(object,index){
			if(object[currentLookupKey] == currentLookupValue)
			{
					return true;
			}
		});

		currentLookupFilter.forEach(function(object,index){
				nextLookupData.push(object[nextLookupKey]);
		});
		nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
		lookupData4 = currentLookupFilter;
		return nextLookupData ;
	}
	function filterDataByKey4(currentLookupKey,nextLookupKey,lookupData4,currentLookupValue){
		var nextLookupData = [];
		var currentLookupFilter = lookupData4.filter(function(object,index){
			if(object[currentLookupKey] == currentLookupValue){
					return true;
			}
		});

		currentLookupFilter.forEach(function(object,index){
				nextLookupData.push(object[nextLookupKey]);
		});
		nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
		var lookupData5 = currentLookupFilter;
		return nextLookupData ;
	}
        
    vm.formchanged = function(index){
	 		vm.formLineMapping[index].schedule = null;
	 		vm.formLineMapping[index].line = null;
	 		vm.formLineMapping[index].column = null;
	 		vm.formLineMapping[index].section = null;
	 		vm.formLineMapping[index].part = null;				
    };
    	
    vm.schedulechanged = function(index){
    	vm.formLineMapping[index].line = null;
 		vm.formLineMapping[index].column = null;
 		vm.formLineMapping[index].section = null;
 		vm.formLineMapping[index].part = null;	
    };
    vm.linechanged = function(index){
 		vm.formLineMapping[index].column = null;
 		vm.formLineMapping[index].section = null;
 		vm.formLineMapping[index].part = null;	
    };
    
    vm.columnchanged = function(index){
 		vm.formLineMapping[index].section = null;
 		vm.formLineMapping[index].part = null;	
	};
	vm.sectionChanged = function (index){
 		vm.formLineMapping[index].part = null;	
	};
    }
    
    return controllers;
});