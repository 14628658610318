define([
    'angular'
    
], function () {
    'use strict';

    var controllers =  angular.module('app.jobConsoleCtrl',[])
    .controller('jobConsoleListCtrl', ['$scope','$rootScope','$state','$log','GridFactory','AlertService','$timeout','$uibModal', '$parse', 'ClientAdminFactory','GENERAL_CONFIG','USER_SETTINGS', jobConsoleListCtrl])
    .controller('jobConsoleCtrl', ['$scope', '$rootScope', '$state', '$log', 'rowData', 'JsonObjectFactory', 'AlertService', '$timeout', '$parse', 'JCDFactory', 'ClientAdminFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', '$uibModalInstance', 'jobConsoleFactory', 'GlobalService', jobConsoleCtrl])
    .controller('logHistoryCtrl', ['$scope','$rootScope','$state','$log','GridFactory','AlertService','$timeout','$uibModal', '$parse', 'ClientAdminFactory','GENERAL_CONFIG','USER_SETTINGS', logHistoryCtrl])

    function jobConsoleCtrl($scope, $rootScope, $state, $log, rowData, JsonObjectFactory, AlertService, $timeout, $parse, JCDFactory, ClientAdminFactory, GENERAL_CONFIG, USER_SETTINGS, $uibModalInstance, jobConsoleFactory, GlobalService) {

        var vm = this;
        vm.title = "Job Console Edit";
        vm.crudLoading = false;
        vm.jobConsoleParams = {
            JOB_ID : '',
            JOB_TYPE : '',
            DOMAIN_TYPE : '',
            JOB_NAME : '',
            IS_ACTIVE : '',
            IS_ENABLE : '',
            SCHEDULE : '',
            PROC_CALL : '',
            DEFAULT_INPUT : '',
            NOTIF_PROCESS_NAME : '',
            JOB_PARAMS: ''
        };

        vm.isActiveList = ['Y', 'N'];
        vm.isEnableList = ['Y', 'N'];
        vm.jobType = ['STORED_PROCEDURE'];
        vm.domainTypeList = [           
            'Foreign',
            'State',
            'CbCr',
            'Insurance',
            'E-File',
            'Domestic',
            'DST',
            'Partnerships',
            'FTC/FDII',
            'Others'
        ];

        vm.jobConsoleParams = rowData;
        vm.jobConsoleParams.JOB_PARAMS = angular.copy(rowData.DEFAULT_INPUT);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.execute = function(){
            vm.crudLoading = true;
            var params = {
                "job_id": vm.jobConsoleParams.JOB_ID, 
                "job_params": (vm.jobConsoleParams.JOB_PARAMS !== undefined) ? vm.jobConsoleParams.JOB_PARAMS : '' ,
                "tax_year": GlobalService.globalParams.tax_year, 
                "scenario": GlobalService.globalParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key
            };

			return jobConsoleFactory.executeJob(params).then(function (data) {
				if(data.data.callSuccess == "1" ){
					vm.crudLoading = false;
					AlertService.add("success", "Job Started Successfully, check the job log for the status.", 4000);
					//vm.cancel();
				}else{
					vm.crudLoading = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        };

        vm.update = function(){
            var jsonData  = JSON.stringify(vm.jobConsoleParams);

            var params = {
                "tax_year": GlobalService.globalParams.tax_year, 
                "scenario": GlobalService.globalParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key
            };
            params =  _.extend({json_data: jsonData}, params);
            
            return JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=csibch', params).then(function (data) {
				if(data.callSuccess == "1" ){
					vm.crudLoading = false;
                    $rootScope.$emit('gridUpdate', {});
					AlertService.add("success", "Job Updated Successfully", 4000);
					vm.cancel();
				}else{
					vm.crudLoading = false;
					AlertService.add("error", data.errorMessage, 4000);
				}
            });
		};

    }

    function jobConsoleListCtrl($scope,$rootScope,$state,$log,GridFactory,AlertService,$timeout,$uibModal, $parse, ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS) {

        var vm = this;
        vm.name = "Action List";
        vm.userSettings = USER_SETTINGS;
        vm.filter = {
            client_key:vm.userSettings.user.client.client_key
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            gridUpdateListener();
        });

        vm.refresh = function(){
            getData();
        };

        vm.selected = null;

        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];

            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", data.errorMessage);
                }
            });
        }

        vm.onClientChange = function() {
            getData();
        };

        var getUpDate = function(_params,_filterObj) {
            var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
                params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $timeout(function() {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);

        vm.addAction = function(){
            var data = {};

            var modalObj = {};
            modalObj.template = "app/components/admin/jobConsole/job-console-tpl.html";
            modalObj.controler = "jobConsoleCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,data);
        }


        $scope.ctrlFn = function(_callName,_data,_type) {
            var modalObj = {};
            modalObj.template = "app/components/admin/jobConsole/job-console-tpl.html";
            modalObj.controler = "jobConsoleCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,_data);
        };

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

    }

    function logHistoryCtrl($scope,$rootScope,$state,$log,GridFactory,AlertService,$timeout,$uibModal, $parse, ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS) {

        var vm = this;
        vm.name = "Action List";
        vm.userSettings = USER_SETTINGS;
        vm.filter = {
            client_key:vm.userSettings.user.client.client_key
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            gridUpdateListener();
        });

        vm.refresh = function(){
            getData();
        };

        vm.selected = null;

        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];

            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?action_id=32592&grid_id=5902&tax_year=2020", vm.filter).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", data.errorMessage);
                }
            });
        }

        vm.onClientChange = function() {
            getData();
        };

        var getUpDate = function(_params,_filterObj) {
            var additionalParams = {}
            var params = _.merge({}, additionalParams, _params);
                params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get, params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $timeout(function() {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);

        vm.addAction = function(){
            var data = {};

            var modalObj = {};
            modalObj.template = "app/components/admin/jobConsole/job-console-tpl.html";
            modalObj.controler = "jobConsoleCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,data);
        }


        $scope.ctrlFn = function(_callName,_data,_type) {
            var modalObj = {};
            modalObj.template = "app/components/admin/jobConsole/job-console-tpl.html";
            modalObj.controler = "jobConsoleCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,_data);
        };

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
    }

    return controllers;

});
