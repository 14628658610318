define([
    'angular'

 ], function () {
    'use strict';
    //FormScheduleDController

   var controllers = angular.module('app.FormScheduleDCtrl', [])
        .controller('FormScheduleDController', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
            'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','ModalFactory',FormScheduleDController])
        .controller('FormScheduleDControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout',
            'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance','rowData', 'colData', 'gridData',FormScheduleDControllerModal])    ;

    function FormScheduleDController(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$q,AlertService,GENERAL_CONFIG,ModalFactory) {
        FormScheduleDControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$q,AlertService,GENERAL_CONFIG,ModalFactory,{},{}, {}, {},this);
    }


    function FormScheduleDControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,workspaceFactory,$http,$q,AlertService,GENERAL_CONFIG,ModalFactory,$uibModalInstance,
        rowData, colData, gridData,thisObj) {
       // var vm = this;
       var vm = this||thisObj;
       vm.rowData = ( $scope.$parent.rowData == undefined ) ? rowData : $scope.$parent.rowData;//rowData||$scope.$parent.rowData;//
       var rowData = vm.rowData;

        vm.modalTitle = "Schedule D (Form 1040): Capital Gains and Losses - Increase (Decrease) To Taxable Income";
        vm.gainLoss = 0;
        vm.schdDSum = 0;
        vm.schdmSum1 = 0;
        vm.schdmSum6 = 0;
        vm.editTotalRows = [];
        vm.editTotalRows_1 = [];
        vm.line1FormLineKey = 0;
        vm.line6FormLineKey = 0;
        var count = 0;
        vm.temprow1_editdata = [];
        vm.temprow6_editdata = [];
        vm.selectedTabName = 'Form Entry';
        vm.d_form_key = 2;
        vm.onTabChange = function(e) {
            vm.selectedTabName = e;
            $timeout(function() {
                var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            }, 50);
        };

        vm.schdDSumInitialValue = 0;

        vm.unallocatedAmount = 0;
        vm.unallocatedAmountF = 0;
        vm.unUsedCapLossCarryOver = 0;
        vm.unUsedCapLossCarryOverOrig = 0;
        if($uibModalInstance) {
            vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
            vm.cancel = function () {
                $uibModalInstance.close();
                vm.modal_name = null;
            };
        } else {
            vm.modal_name = null;
        }

        vm.schdmVal = 0;
        vm.schdmValues = [];
        vm.schdmValues1 = [];

        vm.schdDAmounts = [[],[]];
        vm.temp = 0;

        vm.temp0 = 0;
        vm.temp1 = 0;

        vm.temp0E = 0;
        vm.temp1E = 0;

        vm.t1 = 0;
        vm.t = 0;

        vm.beforeEdit_1 = 0;
        vm.beforeEdit_6 = 0;

        $scope.displayViewValue_1 = [];
        $scope.displayEditValue_1 = [];

        $scope.displayViewValue_6 = [];
        $scope.displayEditValue_6 = [];


        $scope.displayViewValueComment_1 = [];
        $scope.displayEditValueComment_1 = [];

        $scope.displayViewValueComment_6 = [];
        $scope.displayEditValueComment_6 = [];


        $scope.displayLine4View = true;
        //$scope.displayLine4Edit = false;
        $scope.displayLine4Edit = true;

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.taxYear = parseInt(rowData.TAX_YEAR);
        vm.scenario = parseInt(rowData.SCENARIO);
        vm.jcd = 250//rowData.JCD_KEY;
        vm.combinationKey = parseInt(rowData.COMBINATION_KEY);
        vm.groupObjKey = parseInt(rowData.GROUP_OBJ_KEY);



        vm.colData1_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :31, "Attribute_Name": "PROPERTY_DESC", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData2_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :32, "Attribute_Name": "DATE_ACQ", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData3_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :33, "Attribute_Name": "DATE_SOLD", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData4_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :34, "Attribute_Name": "SALE_PRICE", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData5_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :35, "Attribute_Name": "COST_BASIS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData6_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :37, "Attribute_Name": "TRANSACTION_TYPE", "Attribute_Value" : "Perm", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData7_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :253, "Attribute_Name": "LOSS_TRANSACTION", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData8_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :38, "Attribute_Name": "WORTHLESS_FLAG", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData9_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :39, "Attribute_Name": "IS_SEC_754", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData10_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :40, "Attribute_Name": "IS_OTH_FLOW_THRU", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData11_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :36, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData12_1 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :41, "Attribute_Name": "COMMENTS", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

        vm.recordData_1 = [vm.colData1_1, vm.colData2_1,vm.colData3_1, vm.colData4_1,vm.colData5_1, vm.colData6_1,vm.colData7_1, vm.colData8_1,vm.colData9_1, vm.colData10_1,vm.colData11_1, vm.colData12_1];

        vm.generatedHtml = "app/components/Forms/Form-scheduleD/Form-StylesheetView_test.html";
        /** PoC Mallesh**/
        vm.styleSheetView = function(){
            console.log("styleSheetView..");
            var params = {};
            var url = "gtwStyleSheetViewPoc.ge";
            console.log(params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                console.log(response.data);
                vm.generatedHtml =  response.data.jsonObject[0].htmlPage;
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;

        }

        vm.formData_1 = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };

        vm.formData_1_errorMessages = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };

        vm.colData_line4 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :4, "occurence" :1, "line_attrib_key" :47, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.recordData_line4 = [vm.colData_line4];
        vm.formData_line4 = {
            "editrows"  : []
        };

        vm.colData1_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :53, "Attribute_Name": "PROPERTY_DESC", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData2_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :54, "Attribute_Name": "DATE_ACQ", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData3_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :55, "Attribute_Name": "DATE_SOLD", "Attribute_Value" : new Date(), "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData4_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :56, "Attribute_Name": "SALE_PRICE", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData5_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :57, "Attribute_Name": "COST_BASIS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData6_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :59, "Attribute_Name": "TRANSACTION_TYPE", "Attribute_Value" : "Perm", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData7_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :264, "Attribute_Name": "LOSS_TRANSACTION", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData8_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :60, "Attribute_Name": "WORTHLESS_FLAG", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData9_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :61, "Attribute_Name": "IS_SEC_754", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData10_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :62, "Attribute_Name": "IS_OTH_FLOW_THRU", "Attribute_Value" : "N", "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData11_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :6, "occurence" :null, "line_attrib_key" :58, "Attribute_Name": "GAIN_LOSS", "Attribute_Value" : 0, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};
        vm.colData12_6 = {"tax_year":vm.taxYear, "trans_type_key" : 8, "combination_key" :vm.combinationKey, "group_obj_key" :vm.groupObjKey, "form_key" :2, "line_no" :1, "occurence" :null, "line_attrib_key" :63, "Attribute_Name": "COMMENTS", "Attribute_Value" : null, "trans_dtls_key" : null, "trans_status" : "A", "is_dirty": "N"};

        vm.recordData = [vm.colData1_6, vm.colData2_6,vm.colData3_6, vm.colData4_6,vm.colData5_6, vm.colData6_6,vm.colData7_6, vm.colData8_6,vm.colData9_6, vm.colData10_6,vm.colData11_6,vm.colData12_6];

        vm.formData_6 = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };
        vm.formData_6_errorMessages = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };

        console.log('-------------- Modal Title ---------------',vm.modalTitle);
        console.log('formData_6 = ',  vm.formData_6);

        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };

        $scope.scheduleDFormEntry = {
            section1:{
                part1Title: "Short-Term Capital Gains and Losses - Assests Held One Year or Less",
                singlerows:["Total Gain (Loss) per Tax Return", "0"],
                tableHeaders:["1040 Line","Description of Property","Date Acquired","Date Sold","Sales Price","Cost or Other Basis","Transaction Type","Loss Transaction?","Worthless Stock?","Section 754?","Gain or (Loss)","Is Other Flow Thru?"],
                bodyRows:[{
                    num: "1",
                    description:"Add Gain or (Loss) Details"
                }, {
                    num: "4",
                    description:"Unused capital loss carryover (attach computation)"
                }],
                rows: [{}]
            },
            section2: {
                part2Title: "Long-Term Capital Gains and Losses - Assests Held More Than One Year",
                emptyRows: [{}],
                bodyRows: [{
                    description: "Total Schd D Gain or (Loss)"
                }, {
                    description: "Unallocated Amount"
                }],
                rows: [{}]
            }
        }

        console.log('section1 rows length : ' , $scope.scheduleDFormEntry.section1.rows.length);

        $scope.scheduleDFormEntry.section1.rows.splice(0, 1);
        $scope.scheduleDFormEntry.section2.rows.splice(0, 1);

        $scope.myViewValue = true;
        $scope.myEditValue = false;

        function fetchSchDrecords() {

            //var params = { "action_id": 372, "tax_year": rowData.TAX_YEAR, "group_obj_key": 201473/*rowData.GROUP_OBJ_KEY*/, "combination_key": rowData.COMBINATION_KEY}

            var params = { "action_id": 372, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": vm.jcd, /*"group_obj_key": 201473*/ "combination_key": rowData.COMBINATION_KEY}
            //console.log ('---rowData---', rowData);
            //console.log("Params for schedule D", params);

            if(rowData.UNALLOCATED_AMT == undefined){
            	vm.unallocatedAmount = 0;
                vm.unallocatedAmountF = 0;
            }else{
            	vm.unallocatedAmount = rowData.UNALLOCATED_AMT;
                vm.unallocatedAmountF = rowData.UNALLOCATED_AMT;
            }

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {

                console.log("Response Data ::",data);

                findAdPopulateLineAttribKeys(vm.recordData_1,data,3);
                findAdPopulateLineAttribKeys(vm.recordData,data,6);
                findAdPopulateLineAttribKeys(vm.recordData_line4,data,4);
                console.log('vm.recordData_1::',vm.recordData_1);
                console.log('vm.recordData_4::',vm.recordData_line4);
                console.log('vm.recordData_6::',vm.recordData);
                //if (data.callSuccess === "1" ) {
                //vm.scheduleDRecords = data.pivotedJsonObj[1].DATA;
                vm.line1RecordSize = data.irsForm.formItemList[3].dataRowList.length;

                vm.unUsedCapLossCarryOver = data.irsForm.formItemList[4].dataRowList[0].dataCellList[2].value;

                if(vm.unUsedCapLossCarryOver === null) {
                    vm.unUsedCapLossCarryOver = 0;
                }

                vm.unUsedCapLossCarryOver = parseInt(vm.unUsedCapLossCarryOver, 0);

                vm.unUsedCapLossCarryOverOrig = vm.unUsedCapLossCarryOver;

                var object_id = data.irsForm.formItemList[4].dataRowList[0].rowDimensions.object_id;
                var x = object_id.split("~");
                var occurrence =  x[4];
                var transDetailKey = x[3];
                vm.d_form_key = parseInt(x[5]);

                vm.recordData_line4[0].trans_dtls_key = transDetailKey;

                vm.formData_line4.editrows.push(vm.recordData_line4);

                vm.line6RecordSize = data.irsForm.formItemList[6].dataRowList.length;

                // get headers for section 1 (line 1)
                vm.scheduleDLine1HeadersAll = data.irsForm.formItemList[3].dataRowList[0].dataCellList;
                vm.clonedHeaders1 = angular.copy(vm.scheduleDLine1HeadersAll);

                var object_id_line1 = data.irsForm.formItemList[3].dataRowList[0].rowDimensions.object_id;
                var l = object_id_line1.split("~");
                vm.line1FormLineKey = parseInt(l[2]);
                console.log('line1FormLineKey = ', vm.line1FormLineKey);

                vm.scheduleDLine1Headers = vm.clonedHeaders1.splice(0, 12);
                vm.scheduleDLine1HeaderComment = data.irsForm.formItemList[3].dataRowList[0].dataCellList[12];
                console.log('scheduleDLine1Headers = ', vm.scheduleDLine1Headers);


                //get headers for section 1 (line 6)
                vm.scheduleDLine6HeadersAll = data.irsForm.formItemList[3].dataRowList[0].dataCellList;
                vm.clonedHeaders6 = angular.copy(vm.scheduleDLine6HeadersAll);

                var object_id_line6 = data.irsForm.formItemList[6].dataRowList[0].rowDimensions.object_id;
                var l = object_id_line6.split("~");
                vm.line6FormLineKey = parseInt(l[2]);
                console.log('line6FormLineKey = ', vm.line6FormLineKey);

                vm.scheduleDLine6Headers = vm.clonedHeaders6.splice(0, 12);
                vm.scheduleDLine6HeaderComment = data.irsForm.formItemList[3].dataRowList[0].dataCellList[12];
                console.log('scheduleDLine6Headers = ', vm.scheduleDLine6Headers);

                var obj_id_section_1 =  data.irsForm.formItemList[3].dataRowList[0].rowDimensions.object_id;
                var split_object1 = obj_id_section_1.split("~");
        		var occur1 = split_object1[4];
        		var endsWithZero_1 = false;
        		if(occur1 == 0){
        			endsWithZero_1 = true;
        		}else{
        			endsWithZero_1 = false;
        		}
                var obj_id_section_6 =  data.irsForm.formItemList[6].dataRowList[0].rowDimensions.object_id;
                var split_object6 = obj_id_section_6.split("~");
        		var occur6 = split_object6[4];
        		var endsWithZero_6 = false;
        		if(occur6 == 0){
        			endsWithZero_6 = true;
        		}else{
        			endsWithZero_6 = false;
        		}

                if(vm.line1RecordSize > 0 && !endsWithZero_1 ) {

                    vm.scheduleDLine1Records = data.irsForm.formItemList[3].dataRowList;

                    angular.forEach(vm.scheduleDLine1Records, function(value, key){

                        //console.log('--- scheduleDLine1Records ---', key, value.dataCellList[12].value);

                        if(value.dataCellList[2].value != 'VARIOUS') {

                            value.dataCellList[2].value = new Date(value.dataCellList[2].value);
                        }

                        if(value.dataCellList[3].value != 'VARIOUS') {

                            value.dataCellList[3].value = new Date(value.dataCellList[3].value);
                        }

                        vm.gainLoss = value.dataCellList[11].value;

                        vm.schdmSum1 = vm.schdmSum1+ parseInt(vm.gainLoss, 0);

                        //vm.schdDSum = vm.schdDSum + parseInt(vm.gainLoss, 0);

                        $scope.displayViewValue_1.push(true);
                        $scope.displayEditValue_1.push(false);

                        $scope.displayViewValueComment_1.push(true);
                        $scope.displayEditValueComment_1.push(false);

                        var clonedRecordData = angular.copy(vm.recordData_1);


                        var objId = value.rowDimensions.object_id;

                        var y = objId.split("~");
                        var occ = y[4];
                        var transDetailKey = y[3];

                        angular.forEach(clonedRecordData, function(value, key) {

                            value.trans_dtls_key = transDetailKey;
                            value.occurence = occ;
                        });

                        vm.formData_1.editrows.push(clonedRecordData);
                        console.log('Edit Row Data befor calling Save Function is ::---------------', vm.formData_1.editrows);

                    });

                    vm.scheduleDLine1RecordsOrig = angular.copy(vm.scheduleDLine1Records);
                }


                if(vm.line6RecordSize > 0 && !endsWithZero_6 ) {

                    vm.scheduleDLine6Records = data.irsForm.formItemList[6].dataRowList;

                    angular.forEach(vm.scheduleDLine6Records, function(value, key){

                        console.log('--- scheduleDLine6Records ---', key, value.dataCellList[12].value);

                        if(value.dataCellList[2].value != 'VARIOUS') {

                            value.dataCellList[2].value = new Date(value.dataCellList[2].value);
                        }

                        if(value.dataCellList[3].value != 'VARIOUS') {

                            value.dataCellList[3].value = new Date(value.dataCellList[3].value);
                        }

                        vm.gainLoss = value.dataCellList[11].value;

                        //vm.schdDSum = vm.schdDSum + parseInt(vm.gainLoss, 0);
                        vm.schdmSum6 = vm.schdmSum6 + parseInt(vm.gainLoss, 0);

                        $scope.displayViewValue_6.push(true);
                        $scope.displayEditValue_6.push(false);

                        $scope.displayViewValueComment_6.push(true);
                        $scope.displayEditValueComment_6.push(false);


                        console.log('--- displayViewValueComment_6 ---', $scope.displayViewValueComment_6[key]);
                        console.log('--- displayEditValueComment_6 ---', $scope.displayEditValueComment_6[key]);

                        var clonedRecordData = angular.copy(vm.recordData);


                        var objId = value.rowDimensions.object_id;

                        var y = objId.split("~");
                        var occ = y[4];
                        var transDetailKey = y[3];

                        angular.forEach(clonedRecordData, function(value, key) {

                            value.trans_dtls_key = transDetailKey;
                            value.occurence = occ;
                        });

                        vm.formData_6.editrows.push(clonedRecordData);

                    });

                    vm.scheduleDLine6RecordsOrig = angular.copy(vm.scheduleDLine6Records);

                }

                vm.schdDSum = vm.schdmSum1 + vm.schdmSum6 + vm.unUsedCapLossCarryOver;

                console.log('--- schdDSum --- = ', vm.schdDSum);

                //$scope.scheduleDFormEntry.section1.singlerows[1] = vm.schdDSum - vm.unallocatedAmount;

                if(rowData.TB_AMT == undefined){
                	$scope.scheduleDFormEntry.section1.singlerows[1] = 0;
                } else{
                	$scope.scheduleDFormEntry.section1.singlerows[1] = rowData.TB_AMT;
                }

                vm.schdDSumInitialValue = vm.schdDSum;

                /*findAdPopulateLineAttribKeys(vm.recordData_1,data,3);
                findAdPopulateLineAttribKeys(vm.recordData,data,6);
                findAdPopulateLineAttribKeys(vm.recordData_line4,data,4);
                console.log('vm.recordData_1::',vm.recordData_1);
                console.log('vm.recordData_4::',vm.recordData_line4);
                console.log('vm.recordData_6::',vm.recordData);*/

            });

        }

        function findAdPopulateLineAttribKeys(staticData,serverData,rowNum){
       	 	var row1cellKey = [];
            angular.forEach(staticData, function(item, index) {
           	 	if(rowNum===4){
           	 		index = 1;
           	 	}
            	row1cellKey = serverData.irsForm.formItemList[rowNum].dataRowList[0].dataCellList[index+1].key.split('~');
            	if(item.Attribute_Name===row1cellKey[0]){
            		item.line_attrib_key = parseInt(row1cellKey[1]);
            	}
            });
        }


        fetchSchDrecords();

        vm.sectionAdd = function(line) {
            var date1, date2;
            var clonedRcordData;
            vm.formData_1_errorMessages = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };

            vm.formData_6_errorMessages = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };

            console.log('--- sectionAdd called for line ---', line);


            if(line == 1) {

                $scope.scheduleDFormEntry.section1.rows.push({});
                clonedRcordData = angular.copy(vm.recordData_1);


                /*date1 = clonedRcordData[1].Attribute_Value;
                clonedRcordData[1].Attribute_Value = vm.convertDate(date1);

                date2 = clonedRcordData[2].Attribute_Value;
                clonedRcordData[2].Attribute_Value = vm.convertDate(date2);*/


                vm.formData_1.addrows.push(clonedRcordData );
                console.log('formData_1 = ', vm.formData_1);
            }
            else if(line == 6) {
                $scope.scheduleDFormEntry.section2.rows.push({});
                clonedRcordData = angular.copy(vm.recordData);

                /*date1 = clonedRcordData[1].Attribute_Value;
                clonedRcordData[1].Attribute_Value = vm.convertDate(date1);

                date2 = clonedRcordData[2].Attribute_Value;
                clonedRcordData[2].Attribute_Value = vm.convertDate(date2);*/

                vm.formData_6.addrows.push(clonedRcordData);
                console.log('formData = ', vm.formData_6);
            }

        }

        vm.sectionRemove = function(line, index) {
            if(line == 1) {

                $scope.scheduleDFormEntry.section1.rows.splice(index, 1);
                // TO DO
                var adjustAmount = vm.formData_1.addrows[index][10].Attribute_Value;
                vm.schdDSum = vm.schdDSum - adjustAmount;
                vm.unallocatedAmount = vm.unallocatedAmount + adjustAmount;
                if (index in vm.schdDAmounts[0]) {
                    vm.schdDAmounts[0].splice(index, 1);
                }
                vm.temp0 = vm.temp0 - adjustAmount;
                vm.formData_1.addrows.splice(index, 1);
            }
            else if(line == 6) {
                $scope.scheduleDFormEntry.section2.rows.splice(index, 1);
                // TO DO
                var adjustAmount = vm.formData_6.addrows[index][10].Attribute_Value;
                vm.schdDSum = vm.schdDSum - adjustAmount;
                vm.unallocatedAmount = vm.unallocatedAmount + adjustAmount;
                if (index in vm.schdDAmounts[1]) {
                    vm.schdDAmounts[1].splice(index, 1);
                }
                vm.temp1 = vm.temp1 - adjustAmount;
                vm.formData_6.addrows.splice(index, 1);
            }
        }

        vm.getDateValue = function(dateString) {

            return new Date(dateString);

        }

        vm.getTransDtlsKey = function(object_id){
            var x = object_id.split("~");
            var transDetailKey = x[3];
            return transDetailKey;
        }

        vm.editLine4 = function() {

            $scope.displayLine4View = !$scope.displayLine4View;
            $scope.displayLine4Edit = !$scope.displayLine4Edit;

            if($scope.displayLine4View == false && $scope.displayLine4Edit == true) {
                vm.formData_line4.editrows[0][0].Attribute_Value = vm.unUsedCapLossCarryOver;
            }
            if($scope.displayLine4View == true && $scope.displayLine4Edit == false) {
                vm.formData_line4.editrows[0][0].is_dirty = 'N';
                vm.schdDSum = parseInt(vm.schdDSum) - parseInt(vm.unUsedCapLossCarryOver);
                $scope.scheduleDFormEntry.section1.singlerows[1] = vm.schdDSum - vm.unallocatedAmount;
                vm.unUsedCapLossCarryOver = vm.unUsedCapLossCarryOverOrig;
                //vm.schdmSum6 = parseInt(vm.schdmSum6) - parseInt(vm.unUsedCapLossCarryOver);

            }
        }

        vm.removeExistingRecord = function(line, index, object_id) {

            console.log(' removeExistingRecord called for line & index ', line, index, object_id);
            var x = object_id.split("~");
            var occurrence =  x[4];
            var transDetailKey = x[3];
            var removeRow = {"tax_year": vm.taxYear, "trans_type_key": 8, "combination_key": vm.combinationKey, "group_obj_key": 201473, "form_key": 2, "line_no": 1, "occurence": occurrence, "trans_dtls_key": transDetailKey, "trans_status": "A"};
            var gainLossAtIndex;

            if(line == 1) {

                vm.formData_1.removerows.push(removeRow);
                gainLossAtIndex = vm.scheduleDLine1Records[index].dataCellList[11].value;
                vm.schdDSum = vm.schdDSum - gainLossAtIndex;
                //vm.schdDSum = vm.schdmSum1 + vm.schdmSum6;
                vm.schdmSum1 = vm.schdmSum1 - gainLossAtIndex;
                vm.unallocatedAmount = parseInt(vm.unallocatedAmount) + parseInt(gainLossAtIndex);
                vm.scheduleDLine1Records.splice(index, 1);
            }
            else if(line == 6) {

                vm.formData_6.removerows.push(removeRow);
                gainLossAtIndex = vm.scheduleDLine6Records[index].dataCellList[11].value;
                vm.schdDSum = vm.schdDSum - gainLossAtIndex;
                //vm.schdDSum = vm.schdmSum1 + vm.schdmSum6;
                vm.schdmSum6 = vm.schdmSum6 - gainLossAtIndex;
                vm.unallocatedAmount = parseInt(vm.unallocatedAmount) + parseInt(gainLossAtIndex);
                vm.scheduleDLine6Records.splice(index, 1);
            }

            window.alert("Please click on 'Save and Proceed' to Delete this Transcation. Otherwise click on 'Cancel'");

        }

        vm.editExistingRecord = function(line, index, object_id) {

            console.log(' editExistingRecord called for line & index ', line, index, object_id);

            if(line == 1) {

                $scope.displayViewValue_1[index] = !$scope.displayViewValue_1[index];
                $scope.displayEditValue_1[index] = !$scope.displayEditValue_1[index];

                $scope.displayViewValueComment_1[index] = !$scope.displayViewValueComment_1[index];
                $scope.displayEditValueComment_1[index] = !$scope.displayEditValueComment_1[index];

                if($scope.displayViewValue_1[index] == false && $scope.displayEditValue_1[index] == true) {

                    console.log('original values for row ' , index , ' = ', vm.scheduleDLine1RecordsOrig[index].dataCellList);

                    vm.formData_1.editrows[index][0].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[1].value;

                    if(vm.scheduleDLine1Records[index].dataCellList[2].value === 'VARIOUS')
                        vm.formData_1.editrows[index][1].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[2].value;
                    else {

                        var date = vm.scheduleDLine1Records[index].dataCellList[2].value  ;
                        vm.formData_1.editrows[index][1].Attribute_Value = vm.convertDate(date);
                        //vm.scheduleDLine1Records[index].dataCellList[2].value = vm.convertDate(date);

                    }

                    if(vm.scheduleDLine1Records[index].dataCellList[3].value === 'VARIOUS')
                        vm.formData_1.editrows[index][2].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[3].value;
                    else {

                        var date = vm.scheduleDLine1Records[index].dataCellList[3].value  ;
                        vm.formData_1.editrows[index][2].Attribute_Value = vm.convertDate(date);
                        //vm.scheduleDLine1Records[index].dataCellList[3].value =  vm.convertDate(date);

                    }

                    vm.formData_1.editrows[index][3].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[4].value;
                    vm.formData_1.editrows[index][4].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[5].value;
                    vm.formData_1.editrows[index][5].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[6].value;
                    vm.formData_1.editrows[index][6].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[7].value;
                    vm.formData_1.editrows[index][7].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[8].value;
                    vm.formData_1.editrows[index][8].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[9].value;
                    vm.formData_1.editrows[index][9].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[10].value;
                    vm.formData_1.editrows[index][10].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[11].value;
                    vm.formData_1.editrows[index][11].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[12].value;
                }

                if($scope.displayViewValue_1[index] == true && $scope.displayEditValue_1[index] == false) {

                    for (var i = 0; i <= 11; i++) {
                        vm.formData_1.editrows[index][i].is_dirty='N';
                    }

                    for (var i = 0; i <= 11; i++) {
                        vm.scheduleDLine1Records[index].dataCellList[i+1].value = vm.scheduleDLine1RecordsOrig[index].dataCellList[i+1].value;
                    }

                    vm.temp0E = vm.temp0E - (vm.formData_1.editrows[index][10].Attribute_Value - vm.scheduleDLine1RecordsOrig[index].dataCellList[11].value );

                    vm.schdDSum = vm.schdDSumInitialValue + vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E;
                    vm.unallocatedAmount = vm.unallocatedAmountF - (vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E );
                }

            }

            else if(line == 6) {

                $scope.displayViewValue_6[index] = !$scope.displayViewValue_6[index];
                $scope.displayEditValue_6[index] = !$scope.displayEditValue_6[index];


                $scope.displayViewValueComment_6[index] = !$scope.displayViewValueComment_6[index];
                $scope.displayEditValueComment_6[index] = !$scope.displayEditValueComment_6[index];


                if($scope.displayViewValue_6[index] == false && $scope.displayEditValue_6[index] == true) {

                    console.log('original values for row ' , index , ' = ', vm.scheduleDLine6RecordsOrig[index].dataCellList);

                    vm.formData_6.editrows[index][0].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[1].value;

                    if(vm.scheduleDLine6Records[index].dataCellList[2].value === 'VARIOUS')
                        vm.formData_6.editrows[index][1].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[2].value;
                    else {

                        var date = vm.scheduleDLine6Records[index].dataCellList[2].value  ;
                        vm.formData_6.editrows[index][1].Attribute_Value = vm.convertDate(date);
                        //vm.scheduleDLine6Records[index].dataCellList[2].value = vm.convertDate(date);
                    }

                    if(vm.scheduleDLine6Records[index].dataCellList[3].value === 'VARIOUS')
                        vm.formData_6.editrows[index][2].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[3].value;
                    else {

                        var date = vm.scheduleDLine6Records[index].dataCellList[3].value  ;
                        vm.formData_6.editrows[index][2].Attribute_Value = vm.convertDate(date);
                        //vm.scheduleDLine6Records[index].dataCellList[3].value = vm.convertDate(date);
                    }

                    vm.formData_6.editrows[index][3].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[4].value;
                    vm.formData_6.editrows[index][4].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[5].value;
                    vm.formData_6.editrows[index][5].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[6].value;
                    vm.formData_6.editrows[index][6].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[7].value;
                    vm.formData_6.editrows[index][7].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[8].value;
                    vm.formData_6.editrows[index][8].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[9].value;
                    vm.formData_6.editrows[index][9].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[10].value;
                    vm.formData_6.editrows[index][10].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[11].value;
                    vm.formData_6.editrows[index][11].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[12].value;
                }

                if($scope.displayViewValue_6[index] == true && $scope.displayEditValue_6[index] == false) {

                    for (var i = 0; i <= 11; i++) {
                        vm.formData_6.editrows[index][i].is_dirty='N';
                    }

                    for (var i = 0; i <= 11; i++) {
                        vm.scheduleDLine6Records[index].dataCellList[i+1].value = vm.scheduleDLine6RecordsOrig[index].dataCellList[i+1].value;
                    }


                    vm.temp1E = vm.temp1E - (vm.formData_6.editrows[index][10].Attribute_Value - vm.scheduleDLine6RecordsOrig[index].dataCellList[11].value );

                    vm.schdDSum = vm.schdDSumInitialValue + vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E;
                    vm.unallocatedAmount = vm.unallocatedAmountF - (vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E );


                }
            }

        }

        vm.line4Change = function() {

            console.log('--- line4Change() called --- ');
            vm.formData_line4.editrows[0][0].Attribute_Value = vm.unUsedCapLossCarryOver;
            vm.formData_line4.editrows[0][0].is_dirty='Y';
            console.log('--- line4Change() attribute value = ', vm.formData_line4.editrows[0][0].Attribute_Value);

        }

        vm.errorChange = function(line, rowNum, colNum){
            if(line == 1 && null!= vm.formData_1_errorMessages.addrows && vm.formData_1_errorMessages.addrows.length >0 ){
                vm.formData_1_errorMessages.addrows[rowNum][colNum] = null;
            }
            if(line == 6 && null!= vm.formData_6_errorMessages.addrows && vm.formData_6_errorMessages.addrows.length >0 ){
                vm.formData_6_errorMessages.addrows[rowNum][colNum] = null;
            }
        }

        vm.propChange = function(line, colNum, index) {

            console.log('---------------- propchange() called ----------------------');


            if(null!= vm.temprow1_editdata && vm.temprow1_editdata.length >0){
                vm.formData_1.editrows = vm.temprow1_editdata;
            }
            if(null!= vm.temprow6_editdata && vm.temprow6_editdata.length >0){
                vm.formData_6.editrows = vm.temprow6_editdata;
            }

            if(line == 1) {


                if(null != vm.scheduleDLine1Records[index].dataCellList[colNum+1].error_message && vm.scheduleDLine1Records[index].dataCellList[colNum+1].error_message.length > 0){
                    vm.scheduleDLine1Records[index].dataCellList[colNum+1].error_message = null;
                }

                if(null!=vm.editTotalRows_1 && vm.editTotalRows_1.length >0){
                    vm.editTotalRows_1[colNum].column_error = null;
                }

                if(colNum == 1 || colNum == 2) {


                    console.log('--- prop2Change() called for line, column & index = ', line, colNum, index);
                    if(vm.scheduleDLine1Records[index].dataCellList[colNum+1].value == 'VARIOUS')
                        vm.formData_1.editrows[index][colNum].Attribute_Value = 'VARIOUS' /*vm.scheduleDLine1Records[index].dataCellList[colNum+1].value*/;
                    else {
                        var date = vm.scheduleDLine1Records[index].dataCellList[colNum+1].value  ;
                        vm.formData_1.editrows[index][colNum].Attribute_Value = vm.convertDate(date);
                    }
                    vm.formData_1.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop2Change() attribute value = ', vm.formData_1.editrows[index][colNum].Attribute_Value);


                }
                else {
                    console.log('--- prop1Change() called for line, column & index = ', line, colNum, index);
                    vm.formData_1.editrows[index][colNum].Attribute_Value = vm.scheduleDLine1Records[index].dataCellList[colNum+1].value;
                    vm.formData_1.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop1Change() attribute value = ', vm.formData_1.editrows[index][colNum].Attribute_Value);
                }



            }
            else if(line == 6) {

                console.log("EditTotalRows::  -----", vm.editTotalRows);


                if(null != vm.scheduleDLine6Records[index].dataCellList[colNum+1].error_message && vm.scheduleDLine6Records[index].dataCellList[colNum+1].error_message.length > 0){
                    vm.scheduleDLine6Records[index].dataCellList[colNum+1].error_message = null;
                }



                if(null!=vm.editTotalRows && vm.editTotalRows.length > 0){
                    vm.editTotalRows[colNum].column_error = null;
                }

                if(colNum == 1 || colNum == 2) {

                    console.log('colNum = ', colNum);


                    console.log('-----------------' , vm.scheduleDLine6Records[index].dataCellList[colNum+1].value , '------------------');
                    console.log('-----------------' , vm.scheduleDLine6Records[index].dataCellList[2].value , '------------------');



                    console.log('--- prop2Change() called for line, column & index = ', line, colNum, index);




                    if(vm.scheduleDLine6Records[index].dataCellList[colNum+1].value == 'VARIOUS')
                        vm.formData_6.editrows[index][colNum].Attribute_Value = 'VARIOUS' /*vm.scheduleDLine6Records[index].dataCellList[colNum+1].value*/;
                    else {
                        var date = vm.scheduleDLine6Records[index].dataCellList[colNum+1].value  ;
                        vm.formData_6.editrows[index][colNum].Attribute_Value = vm.convertDate(date);
                    }
                    vm.formData_6.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop2Change() attribute value = ', vm.formData_6.editrows[index][colNum].Attribute_Value);

                }
                else {
                    console.log('--- prop1Change() called for lline, column & index = ', line, colNum, index);
                    vm.formData_6.editrows[index][colNum].Attribute_Value = vm.scheduleDLine6Records[index].dataCellList[colNum+1].value;
                    vm.formData_6.editrows[index][colNum].is_dirty='Y';
                    console.log('--- prop1Change() attribute value = ', vm.formData_6.editrows[index][colNum].Attribute_Value);
                }

            }

        }


        vm.handle_date = function(edit, line, colNum, index) {

            if(edit == false) {

                if(colNum == 1 || colNum == 2) {

                    if(line == 1) {

                        if(vm.formData_1.addrows[index][colNum].Attribute_Value == 'VARIOUS' ||  vm.formData_1.addrows[index][colNum].Attribute_Value == 'various') {
                            vm.formData_1.addrows[index][colNum].Attribute_Value = 'VARIOUS';
                        }
                        else {
                            var date = vm.formData_1.addrows[index][colNum].Attribute_Value  ;
                            //vm.formData_1.addrows[index][colNum].Attribute_Value = vm.convertDate(date);
                        }

                    }
                    if(line == 6) {

                        if(vm.formData_6.addrows[index][colNum].Attribute_Value == 'VARIOUS' ||  vm.formData_6.addrows[index][colNum].Attribute_Value == 'various') {
                            vm.formData_6.addrows[index][colNum].Attribute_Value = 'VARIOUS';
                        }
                        else {
                            var date = vm.formData_6.addrows[index][colNum].Attribute_Value  ;
                            //vm.formData_6.addrows[index][colNum].Attribute_Value = vm.convertDate(date);
                        }
                    }

                }

            }


            if(edit == true) {

                if(colNum == 1 || colNum == 2) {

                    console.log(colNum, vm.scheduleDLine6Records[index].dataCellList[colNum+1].value);


                    if(line == 1) {

                        //console.log('--- prop2Change() called for line, column & index = ', line, colNum, index);
                        if(vm.scheduleDLine1Records[index].dataCellList[colNum+1].value == 'VARIOUS'  || vm.scheduleDLine1Records[index].dataCellList[colNum+1].value == 'various') {
                            vm.scheduleDLine1Records[index].dataCellList[colNum+1].value = 'VARIOUS';
                            vm.formData_1.editrows[index][colNum].Attribute_Value = 'VARIOUS' /*vm.scheduleDLine1Records[index].dataCellList[colNum+1].value*/;
                        }

                        else {
                            var date = vm.scheduleDLine1Records[index].dataCellList[colNum+1].value  ;
                            vm.formData_1.editrows[index][colNum].Attribute_Value = date;
                        }
                        //vm.formData_1.editrows[index][colNum].is_dirty='Y';
                        //console.log('--- prop2Change() attribute value = ', vm.formData_1.editrows[index][colNum].Attribute_Value);
                    }
                    if(line == 6) {

                        //console.log('--- prop2Change() called for line, column & index = ', line, colNum, index);
                        if(vm.scheduleDLine6Records[index].dataCellList[colNum+1].value == 'VARIOUS' || vm.scheduleDLine6Records[index].dataCellList[colNum+1].value == 'various') {
                            vm.scheduleDLine6Records[index].dataCellList[colNum+1].value = 'VARIOUS';
                            vm.formData_6.editrows[index][colNum].Attribute_Value = 'VARIOUS' /*vm.scheduleDLine6Records[index].dataCellList[colNum+1].value*/;
                        }

                        else {
                            var date = vm.scheduleDLine6Records[index].dataCellList[colNum+1].value  ;
                            vm.formData_6.editrows[index][colNum].Attribute_Value = date;
                        }
                        //vm.formData_6.editrows[index][colNum].is_dirty='Y';
                        //console.log('--- prop2Change() attribute value = ', vm.formData_6.editrows[index][colNum].Attribute_Value);

                    }
                }
            }

        }


        vm.adjustGainLoss = function() {
            //vm.schdDSum = parseInt(vm.schdDSumInitialValue) + parseInt(vm.unUsedCapLossCarryOver);
            //vm.unallocatedAmount = vm.unallocatedAmountF - parseInt(vm.unUsedCapLossCarryOver);
            vm.schdDSum = vm.schdmSum1 + vm.schdmSum6 + vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E+ parseInt(vm.unUsedCapLossCarryOver);

            vm.unallocatedAmount = $scope.scheduleDFormEntry.section1.singlerows[1] - vm.schdDSum;

            //vm.unallocatedAmount = vm.unallocatedAmountF - (vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E + parseInt(vm.unUsedCapLossCarryOver));

        }

        $scope.setGainLoss = function (line, edit, index) {

            var temp0 = 0;
            var temp1 = 0;
            var temp0E = 0;
            var temp1E = 0;
            var gainLossPrice = 0;
            var costOFOtherBasis = 0;
            var gainLossPerProp = 0;


            if(line == 1) {

                if(edit == false) {

                    gainLossPrice = vm.formData_1.addrows[index][3].Attribute_Value;
                    costOFOtherBasis = vm.formData_1.addrows[index][4].Attribute_Value;
                    if(gainLossPrice == undefined) {gainLossPrice = 0;}
                    if(costOFOtherBasis == undefined) {costOFOtherBasis = 0;}
                    gainLossPerProp = parseInt(gainLossPrice) - parseInt(costOFOtherBasis);
                    vm.formData_1.addrows[index][10].Attribute_Value = gainLossPerProp;

                    if (index in vm.schdDAmounts[0]) {
                        vm.schdDAmounts[0].splice(index, 1);
                    }
                    vm.schdDAmounts[0].splice(index, 0, gainLossPerProp);

                    angular.forEach(vm.schdDAmounts[0], function(value, key){
                        temp0 = temp0 + parseInt(value);
                    });

                    vm.temp0 = temp0;
                }
                else if(edit == true) {

                    gainLossPrice = vm.formData_1.editrows[index][3].Attribute_Value;
                    costOFOtherBasis = vm.formData_1.editrows[index][4].Attribute_Value;
                    if(gainLossPrice == undefined) {gainLossPrice = 0;}
                    if(costOFOtherBasis == undefined) {costOFOtherBasis = 0;}
                    gainLossPerProp = parseInt(gainLossPrice) - parseInt(costOFOtherBasis);

                    if(vm.beforeEdit_1 == 0)
                        vm.beforeEdit_1 =   vm.scheduleDLine1RecordsOrig[index].dataCellList[11].value;

                    vm.formData_1.editrows[index][10].Attribute_Value = gainLossPerProp;
                    vm.scheduleDLine1Records[index].dataCellList[11].value = gainLossPerProp;
                    temp0E = gainLossPerProp - vm.beforeEdit_1;

                    vm.temp0E = vm.temp0E + temp0E ;

                    /*angular.forEach(vm.formData_1.editrows, function(value, key){
                        if(value[3].is_dirty == 'N' || value[4].is_dirty == 'N') {
                            vm.temp0E = vm.temp0E + temp0E ;
                        }
                    });
                    vm.temp0E = temp0E;*/

                    vm.beforeEdit_1 = gainLossPerProp;
                }

            }
            else if(line == 6) {

                if(edit == false) {

                    gainLossPrice = vm.formData_6.addrows[index][3].Attribute_Value;
                    costOFOtherBasis = vm.formData_6.addrows[index][4].Attribute_Value;
                    if(gainLossPrice == undefined) {gainLossPrice = 0;}
                    if(costOFOtherBasis == undefined) {costOFOtherBasis = 0;}
                    gainLossPerProp = parseInt(gainLossPrice) - parseInt(costOFOtherBasis);
                    vm.formData_6.addrows[index][10].Attribute_Value = gainLossPerProp;

                    if (index in vm.schdDAmounts[1]) {
                        vm.schdDAmounts[1].splice(index, 1);
                    }
                    vm.schdDAmounts[1].splice(index, 0, gainLossPerProp);
                    angular.forEach(vm.schdDAmounts[1], function(value, key){
                        temp1 = temp1 + parseInt(value);
                    });

                    vm.temp1 = temp1;
                }
                else if(edit == true) {

                    gainLossPrice = vm.formData_6.editrows[index][3].Attribute_Value;
                    costOFOtherBasis = vm.formData_6.editrows[index][4].Attribute_Value;
                    if(gainLossPrice == undefined) {gainLossPrice = 0;}
                    if(costOFOtherBasis == undefined) {costOFOtherBasis = 0;}
                    gainLossPerProp = parseInt(gainLossPrice) - parseInt(costOFOtherBasis);


                    if(vm.beforeEdit_6 == 0)
                        vm.beforeEdit_6 =   vm.scheduleDLine6RecordsOrig[index].dataCellList[11].value;


                    vm.formData_6.editrows[index][10].Attribute_Value = gainLossPerProp;
                    vm.scheduleDLine6Records[index].dataCellList[11].value = gainLossPerProp;

                    temp1E = gainLossPerProp - vm.beforeEdit_6;


                    /*angular.forEach(vm.formData_6.editrows, function(value, key){
                        temp1E = temp1E + parseInt(value[10].Attribute_Value);
                    });*/
                    vm.temp1E = vm.temp1E + temp1E ;

                    vm.beforeEdit_6 = gainLossPerProp;

                }

            }

            vm.schdDSum = vm.schdmSum1 + vm.schdmSum6 /*vm.schdDSumInitialValue*/ + vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E+ parseInt(vm.unUsedCapLossCarryOver);


            //vm.unallocatedAmount = vm.unallocatedAmountF - (vm.temp0 + vm.temp1 + vm.temp0E + vm.temp1E + parseInt(vm.unUsedCapLossCarryOver));

            vm.unallocatedAmount = $scope.scheduleDFormEntry.section1.singlerows[1] - vm.schdDSum;

        };

        $scope.$on('workflowSave',function(event,data){
			vm.save();
	   })

        vm.saveSchddForm = function (url, data){
            var jsonSettings = { "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": vm.jcd };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            console.log("Json Data::",jsonObj);

            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            console.log("Params::::----------->>>>>>>>>", params);

            params.process_name =  workspaceFactory.activeScreen.label;
            if(params.process_name == undefined){
            	params.process_name = "Form Schedule D Save";
            }
            console.log(params);
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                console.log('--------------------------------------------' , response.data , '----------------------------------------------');
                console.log("saveSchddForm() response.data.jsonObj ::",response.data.jsonObject);
                if(null!=response.data.jsonObject && response.data.jsonObject.length > 0){
                    AlertService.add("danger", "Please correct the errors below", 4000);
                    vm.formData_1_errorMessages = response.data.jsonObject[0];
                    vm.formData_6_errorMessages = response.data.jsonObject[1];
                    console.log("vm.formData_1_errorMessages ::",vm.formData_1_errorMessages);
                    if(null!=vm.formData_1_errorMessages.editrows && vm.formData_1_errorMessages.editrows.length > 0){
                        //edit rows loop
                        angular.forEach(vm.formData_1_errorMessages.editrows, function(value, key){
                            vm.editTotalRows_1 = value;
                            //edit column loop
                            angular.forEach(vm.editTotalRows_1, function(value, key){
                                vm.trans_dtls_key_errorMessage_1 = value.trans_dtls_key;
                                vm.errorMeaage_1 = value.column_error;
                                vm.Line_attrib_key_1 = value.line_attrib_key;
                                vm.object_id = value.tax_year + '~' + value.trans_type_key + '~' + vm.line1FormLineKey + '~' + value.trans_dtls_key + '~' + value.occurence + '~' + value.form_key;
                                //console.log(" vm.object_id for line 1 is :::", vm.object_id);
                                angular.forEach(vm.scheduleDLine1Records, function(value, key){
                                    vm.eachrowData_1 = value;
                                    if(value.rowDimensions.object_id == vm.object_id){
                                        angular.forEach(vm.eachrowData_1.dataCellList, function(value, key){
                                            var y = value.key.split("~");
                                            var LineAttribKey = y[1];
                                            if(LineAttribKey == vm.Line_attrib_key_1){
                                                vm.eachrowData_1.dataCellList[key].error_message = vm.errorMeaage_1;
                                                //console.log("The error Message for each column is that was populated for column was::---  ", vm.eachrowData_1.dataCellList[key].error_message);
                                            }
                                        })
                                    }

                                })
                                //console.log("The error Message that was populated for row was::---  ", vm.object_id);
                            })
                        });
                    }
                    if(null!=vm.formData_6_errorMessages.editrows && vm.formData_6_errorMessages.editrows.length > 0){
                        angular.forEach(vm.formData_6_errorMessages.editrows, function(value, key){
                            vm.editTotalRows = value;
                            vm.trand_detls_key_array = [];
                            angular.forEach(vm.editTotalRows, function(value, key){
                                vm.trans_dtls_key_errorMessage_6 = value.trans_dtls_key;
                                vm.errorMeaage_6 = value.column_error;
                                vm.Line_attrib_key_6 = value.line_attrib_key;
                                vm.object_id = value.tax_year + '~' + value.trans_type_key + '~' + vm.line6FormLineKey + '~' + value.trans_dtls_key + '~' + value.occurence + '~' + value.form_key;
                                //console.log(" vm.object_id for line 6 is :::", vm.object_id);
                                angular.forEach(vm.scheduleDLine6Records, function(value, key){
                                    vm.eachrowData_6 = value;
                                    if(value.rowDimensions.object_id == vm.object_id){
                                        angular.forEach(vm.eachrowData_6.dataCellList, function(value, key){
                                            var y = value.key.split("~");
                                            var LineAttribKey = y[1];
                                            if(LineAttribKey == vm.Line_attrib_key_6){
                                                vm.eachrowData_6.dataCellList[key].error_message = vm.errorMeaage_6;
                                                //console.log("The error Message for each column is that was populated for column was::---  ", vm.eachrowData_1.dataCellList[key].error_message);
                                            }
                                        })
                                    }

                                })
                                //console.log("The error Message that was populated was::---  ", vm.trans_dtls_key_errorMessage_6 ,vm.errorMeaage_6);
                            })
                        });
                    }
                    //console.log("saveSchddForm() vm.formData_1_errorMessages ::",vm.formData_1_errorMessages);
                }else{
                    if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
                        //$uibModalInstance.dismiss('cancel');
                    	if($uibModalInstance) {
                            vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
                            vm.cancel = function () {
                                $uibModalInstance.close();
                                vm.modal_name = null;
                            };
                        } else {
                            vm.modal_name = null;
                        }
                        var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                        AlertService.add("success", "Transactions saved successfully", 4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support");
                    }
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        }

        vm.save = function(){

            var rowIsDirty = false;
            var singleRow;
            console.log('--- save function called ---');

            //console.log($scope.entryForm);
            var cleanIndices = [];

            //rowData.FORM_AMT = vm.schdDSum;  //4544;
            //rowData.UNALLOCATED_AMT = vm.unallocatedAmount;   //555555;

            console.log('rowData ==================== ', rowData );

            cleanIndices = [];

            angular.forEach(vm.formData_1.editrows, function(value, key){

                rowIsDirty = false;
                console.log(value[0]);

                if(value[0].is_dirty === "Y" || value[1].is_dirty === "Y" || value[2].is_dirty === "Y" || value[3].is_dirty === "Y" || value[4].is_dirty === "Y" || value[5].is_dirty === "Y" || value[6].is_dirty === "Y" || value[7].is_dirty === "Y" || value[8].is_dirty === "Y" || value[9].is_dirty === "Y" ||value[10].is_dirty === "Y" || value[11].is_dirty === "Y")
                    rowIsDirty = true;

                if(!rowIsDirty) {
                    cleanIndices.push(key);
                }

            });

            vm.temprow1_editdata = angular.copy(vm.formData_1.editrows);

            // keep only dirty rows, by removing clean rows
            for (var i = cleanIndices.length -1; i >= 0; i--)
                vm.formData_1.editrows.splice(cleanIndices[i],1);


            cleanIndices = [];

            angular.forEach(vm.formData_6.editrows, function(value, key){

                rowIsDirty = false;
                console.log(value[0]);

                if(value[0].is_dirty === "Y" || value[1].is_dirty === "Y" || value[2].is_dirty === "Y" || value[3].is_dirty === "Y" || value[4].is_dirty === "Y" || value[5].is_dirty === "Y" || value[6].is_dirty === "Y" || value[7].is_dirty === "Y" || value[8].is_dirty === "Y" || value[9].is_dirty === "Y" ||value[10].is_dirty === "Y" || value[11].is_dirty === "Y")
                    rowIsDirty = true;

                if(!rowIsDirty) {
                    cleanIndices.push(key);
                }

            });

            vm.temprow6_editdata = angular.copy(vm.formData_6.editrows);

            // keep only dirty rows, by removing clean rows
            for (var i = cleanIndices.length -1; i >= 0; i--)
                vm.formData_6.editrows.splice(cleanIndices[i],1);

            console.log('formData_1 = ', vm.formData_1);
            console.log('formData_line4 = ', vm.formData_line4);
            console.log('formData_6 = ', vm.formData_6);

            //$rootScope.$broadcast("showLoader", false);

            angular.forEach(vm.formData_1.addrows, function(value, key){

                singleRow = angular.copy(value);
                angular.forEach(singleRow, function(val, k) {
                    if(k == 1 || k == 2) {

                        console.log("The  value of Date Acquired is :: ----", singleRow[k].Attribute_Value);
                        if(singleRow[k].Attribute_Value != "VARIOUS") {
                            var date = singleRow[k].Attribute_Value;
                            singleRow[k].Attribute_Value = vm.convertDate(date);
                        }
                    }
                });
                vm.formData.addrows.push(singleRow);
            });

            angular.forEach(vm.formData_1.editrows, function(value, key){
                console.log("It is in the Editrows Loop. Please check it.........");
                singleRow = angular.copy(value);
                angular.forEach(singleRow, function(val, k) {
                    if(k == 3 || k == 4 || k == 10){
                        if(!angular.isNumber(singleRow[k].Attribute_Value)){
                            var number = singleRow[k].Attribute_Value;
                            singleRow[k].Attribute_Value = parseInt(number, 10);
                            console.log('The converted number is----', singleRow[k].Attribute_Value);
                        }
                    }
                });

                vm.formData.editrows.push(singleRow);
            });
            angular.forEach(vm.formData_1.removerows, function(value, key){
                vm.formData.removerows.push(value);
            });
            angular.forEach(vm.formData_6.addrows, function(value, key){
                singleRow = angular.copy(value);
                angular.forEach(singleRow, function(val, k) {
                    if(k == 1 || k == 2) {
                        if(singleRow[k].Attribute_Value != "VARIOUS") {
                            var date = singleRow[k].Attribute_Value;
                            singleRow[k].Attribute_Value = vm.convertDate(date);
                        }
                    }
                });
                vm.formData.addrows.push(singleRow);
            });
            angular.forEach(vm.formData_6.editrows, function(value, key){
                console.log("It is in the Editrows Loop. Please check it.........");
                singleRow = angular.copy(value);
                angular.forEach(singleRow, function(val, k) {
                    if(k == 3 || k == 4 || k == 10){
                        if(!angular.isNumber(singleRow[k].Attribute_Value)){
                            var number = singleRow[k].Attribute_Value;
                            singleRow[k].Attribute_Value = parseInt(number, 10);
                            console.log('The converted number is----', singleRow[k].Attribute_Value);
                        }
                    }
                });
                vm.formData.editrows.push(singleRow);
            });
            angular.forEach(vm.formData_6.removerows, function(value, key){
                vm.formData.removerows.push(value);
            });

            if(vm.formData_line4.editrows[0][0].is_dirty == 'Y') {
                vm.formData.editrows.push(vm.formData_line4.editrows[0]);
            }

            console.log('-------------- formData -------------------- = ', vm.formData);

            vm.addrows= vm.formData.addrows;
            vm.editrows= vm.formData.editrows;
            vm.removerows= vm.formData.removerows;

            if((null!=vm.addrows && vm.addrows.length >0) || (null!= vm.editrows && vm.editrows.length >0) || (null!= vm.removerows && vm.removerows.length >0)){
                console.log('The rows are not empty----------');
                //logic for pushing form key dynamically
                vm.formData.addrows.forEach((item) => item.forEach((childItem) => childItem.form_key = vm.d_form_key));
                vm.formData.editrows.forEach((item) => item.forEach((childItem) => childItem.form_key = vm.d_form_key));
                vm.formData.removerows.forEach((item) => item.form_key = vm.d_form_key);

                vm.mainData = [];
                vm.mainData.push(vm.formData);
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=gzvngm";
                vm.saveSchddForm(url,vm.mainData);

            }else{
                //$uibModalInstance.dismiss('cancel');
            	if($uibModalInstance) {
                    vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
                    vm.cancel = function () {
                        $uibModalInstance.close();
                        vm.modal_name = null;
                    };
                } else {
                    vm.modal_name = null;
                }
            }

            vm.formData = {
                "addrows"   : [],
                "editrows"  : [],
                "removerows": []
            };
            //$uibModalInstance.dismiss('cancel');

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        }

        vm.convertDate = function (dateObject) {
            console.log('convertDate called');
            if (dateObject) {
                var month = (dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1);
                var date = dateObject.getDate() < 10 ? '0' +  dateObject.getDate() : dateObject.getDate();
                var year = dateObject.getFullYear();
                return month + '/' + date + '/' + year;
            } else {
                return null;
            }
        }

        // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        $scope.scheduleDFormView = {
            section1: {
                part1Title:"Short-Term Capital Gains and Losses—Assets Held One Year or Less",
                tableHeaders: ["1040 Line","See instructions for how to figure the amounts to enter on the lines below. This form may be easier to complete if you round off cents to whole dollars.","(d)Proceeds(sales price)","(e)Cost(or other basis) ","(g)Adjustments to gain or loss from Form(s) 8949, Part I,line 2, column (g)","(h) Gain or (loss)Subtract column (e)from column (d) and combine the result with column (g)"],
                rows: [{
                    num:"1",
                    description:"1a. Totals for all short-term transactions reported on Form 1099-B for which basis was reported to the IRS and for which you have no adjustments (see instructions).However, if you choose to report all these transactions on Form 8949, leave this line blank and go to line 1b .",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    description:"1b. Totals for all transactions reported on Form(s) 8949 with Box A checked",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    num:"2",
                    description:"Totals for all transactions reported on Form(s) 8949 with Box B checked",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    num:"3",
                    description:"Totals for all transactions reported on Form(s) 8949 with Box C checked",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    num:"4",
                    description:"Short-term gain from Form 6252 and short-term gain or (loss) from Forms 4684, 6781, and 8824",
                    gain:{show: true, val: "0"}
                }, {
                    num:"5",
                    description:"Net short-term gain or (loss) from partnerships, S corporations, estates, and trusts from Schedule(s) K-1",
                    gain:{show: true, val: "0"}
                }, {
                    num:"6",
                    description:"Short-term capital loss carryover. Enter the amount, if any, from line 8 of your Capital Loss Carryover Worksheet in the instructions",
                    gain:{show: true, val: "0"}
                }, {
                    num:"7",
                    description:"Net short-term capital gain or (loss). Combine lines 1a through 6 in column (h). If you have any long-term capital gains or losses, go to Part II below. Otherwise, go to Part III on the back",
                    gain:{show: true, val: "0"}
                }]
            },
            section2: {
                part2Title: "Long-Term Capital Gains and Losses—Assets Held More Than One Year",
                rows: [{
                    num:"8",
                    description: "8a. Totals for all long-term transactions reported on Form 1099-B for which basis was reported to the IRS and for which you have no adjustments (see instructions).However, if you choose to report all these transactions on Form 8949, leave this line blank and go to line 8b",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    description: "8b. Totals for all transactions reported on Form(s) 8949 with Box D checked",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    num: "9",
                    description: "Totals for all transactions reported on Form(s) 8949 with Box E checked ",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    num: "10",
                    description: "Totals for all transactions reported on Form(s) 8949 with Box F checked.",
                    proceeds:{show:true, val: "0"},
                    cost:{show:true, val: "0"},
                    adjustments:{show:true, val: "0"},
                    gain:{show: true, val: "0"}
                }, {
                    num: "11",
                    description: "Gain from Form 4797, Part I; long-term gain from Forms 2439 and 6252; and long-term gain or (loss)from Forms 4684, 6781, and 8824",
                    gain:{show: true, val: "0"}
                }, {
                    num: "12",
                    description: "Net long-term gain or (loss) from partnerships, S corporations, estates, and trusts from Schedule(s) K-1",
                    gain:{show: true, val: "0"}
                }, {
                    num: "13",
                    description: "Capital gain distributions. See the instructions",
                    gain:{show: true, val: "0"}
                }, {
                    num: "14",
                    description: "Long-term capital loss carryover. Enter the amount, if any, from line 13 of your Capital Loss Carryover Worksheet in the instructions",
                    gain:{show: true, val: "0"}
                }, {
                    num: "15",
                    description: "Net long-term capital gain or (loss). Combine lines 8a through 14 in column (h). Then go to Part III on the back",
                    gain:{show: true, val: "0"}
                }]
            },
            section3: {
                part3Title: "Summary",
                rows: [{
                    num: "16",
                    description: "Combine lines 7 and 15 and enter the result",
                    gain:{show: true, val: "0"}
                }, {
                    description: "• If line 16 is a gain, enter the amount from line 16 on Form 1040, line 13, or Form 1040NR, line 14. Then go to line 17 below."
                }, {
                    description: "• If line 16 is a loss, skip lines 17 through 20 below. Then go to line 21. Also be sure to complete line 22."
                }, {
                    description: "• If line 16 is zero, skip lines 17 through 21 below and enter -0- on Form 1040, line 13, or Form 1040NR, line 14. Then go to line 22."
                }, {
                    num: "17",
                    description: "Are lines 15 and 16 both gains? ",
                }, {
                    description: "Yes. Go to line 18.",
                }, {
                    description:"No. Skip lines 18 through 21, and go to line 22."
                }, {
                    num: "18",
                    description: "If you are required to complete the 28% Rate Gain Worksheet (see instructions), enter the amount, if any, from line 7 of that worksheet",
                    gain:{show: true, val: "0"}
                }, {
                    num: "19",
                    description: "If you are required to complete the Unrecaptured Section 1250 Gain Worksheet (see instructions), enter the amount, if any, from line 18 of that worksheet.",
                    gain:{show: true, val: "0"}
                }, {
                    num: "20",
                    description: "Are lines 18 and 19 both zero or blank?"
                }, {
                    description: "Yes. Complete the Qualified Dividends and Capital Gain Tax Worksheet in the instructions for Form 1040, line 44 (or in the instructions for Form 1040NR, line 42).Don't complete lines 21 and 22 below."
                }, {
                    description: "No. Complete the Schedule D Tax Worksheet in the instructions. Don't complete lines 21 and 22 below."
                }, {
                    num: "21",
                    description: "If line 16 is a loss, enter here and on Form 1040, line 13, or Form 1040NR, line 14, the smaller of:"
                }, {
                    description: "• The loss on line 16 or"
                }, {
                    description: "• ($3,000), or if married filing separately, ($1,500)",
                    gain:{show: true, val: "0"}
                }, {
                    description: " Note: When figuring which amount is smaller, treat both amounts as positive numbers."
                }, {
                    num: "22",
                    description: "Do you have qualified dividends on Form 1040, line 9b, or Form 1040NR, line 10b? "
                }, {
                    description: "Yes. Complete the Qualified Dividends and Capital Gain Tax Worksheet in the instructions for Form 1040, line 44 (or in the instructions for Form 1040NR, line 42). "
                }, {
                    description: "No. Complete the rest of Form 1040 or Form 1040NR."
                }]

            }

        }

    }
});