
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.extEntityMaintenanceServices',[])
        .factory("ExtEntityMaintenanceServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', ExtEntityMaintenanceServicesFactory])

    function ExtEntityMaintenanceServicesFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory) {

        var ExtEntityMaintenanceServicesFactory = {};
        ExtEntityMaintenanceServicesFactory.url = {};
        ExtEntityMaintenanceServicesFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=icldo6";

        ExtEntityMaintenanceServicesFactory.saveExternalEntitySetup = function(_settings, _data) {

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(_data);
            var jsonSettings =  JSON.stringify(_settings);
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);


            params.tax_year = filterParams.tax_year;
            params.scenario = filterParams.scenario;
            params.jcd_key = GlobalService.globalParams.jcd_key;
            params.process_name =  "External Entity Setup";
            params.sso_id = _settings.sso_id;

            console.log('params = ', params);
            console.log('clob_data = ', params.clob_data);
            console.log('clob_settings = ', params.clob_settings);

            var promise = $http({
                method: "post",
                url: ExtEntityMaintenanceServicesFactory.url.save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };

        return ExtEntityMaintenanceServicesFactory;
    }

    return services;

});
