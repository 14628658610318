define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.rerunXmlGenerationController', [])

        .controller('rerunXmlGenerationController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS', 'gridData', '$filter', 'rerunXmlGenerationServiceFactory', rerunXmlGenerationController]);
    	function rerunXmlGenerationController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, gridData, $filter, rerunXmlGenerationServiceFactory) {
        var vm = this;

        vm.modalTitle = "Re Run XML Generation";
		vm.saveloader = false;
		vm.job_id = [];
		vm.leKey = [];
		vm.dataLevel = [];
		vm.filingGroupKey = [];
		angular.forEach(gridData, function(value, key) {
			vm.job_id.push(value.data.JOB_ID);
		});

		vm.regenerateProcess = function() {
			vm.saveloader = true;
			vm.showJsonLoader = true;
			var filterParams = GlobalService.globalParams;
			var params = filterParams;
            params = _.extend({"job_id": vm.job_id.toString(), "xml_pdf_flag": "XML" }, params);

			return rerunXmlGenerationServiceFactory.saveRegenerateProcess(params).then(function (data) {
				if(data.callSuccess == "1" ){
					vm.saveloader = false;
					AlertService.add("success", "Re run PDF Generation process executed successfully" , 4000);
					$rootScope.$emit('gridUpdate', {});
					vm.cancel();
				}else{
					vm.saveloader = false;
					AlertService.add("error", data.data.message, 4000);
				}
            });
        }

		vm.removeTag = function (index) {
			vm.job_id.splice(index, 1);
	  	};

		vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
		};
		
    }
});