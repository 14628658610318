define([
    'angular',
    './tagging163jController',
    './tagging163jService'


], function () {
    'use strict';
    return angular.module('app.FIR_163j_Tagging', ['app.tagging163jController','app.tagging163jService'] );


});