define([
    'angular'

], function () { 
    'use strict';

    var controllers =   angular.module('app.fedFormController',[])
        .controller('FedFormGenericController', ['$rootScope','$scope', '$http', '$state','GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData' ,'JsonObjectFactory','FedFormFactory', fedFormGenericController]);



    function fedFormGenericController($rootScope, $scope, $http, $state, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, JsonObjectFactory,FedFormFactory){

    	 var vm = this;
    	console.log("Inside FedForm Controller");
    	vm.rowData = rowData;
   	
    	vm.scenario = GlobalService.globalParams.scenario;
    	vm.taxYear = GlobalService.globalParams.tax_year;
    	vm.jcd = GlobalService.globalParams.jcd_key;
    	console.log("&&&&&&&&&&&>>>>><<<< Form Id: ",$state.current.api);

    	vm.origIrsForm;
    	vm.currentForm;
    	 vm.crudLoading = false;
    	 vm.processedGrps = [];

        function init(){
        	
           	if( vm.rowData.COMBINATION_KEY  ) {

                console.log("yyyyyyyy vm.rowData.COMBINATION_KEY  FOUND " , vm.rowData.COMBINATION_KEY );
                console.log(">>>>vm.scenario : ",vm.scenario);

                CodeComboFactory.setCombinationCodeByKey(vm.rowData.COMBINATION_KEY);
                CodeComboFactory.toggle = false;
                CodeComboFactory.showFormBtn = false;
                console.log( vm.rowData.COMBINATION_KEY );

           	}else{

                console.log("nnnnnnnnnnnnnnnnn vm.rowData.COMBINATION_KEY NOT FOUND " , vm.rowData.COMBINATION_KEY );
                CodeComboFactory.toggle = true;
            }
           	reload();
        }//end init
        
        init();       
        
        function reload() {
        	 vm.crudLoading = true;
        FedFormFactory.loadGenForm(vm.rowData.TB_AMT,vm.rowData.COMBINATION_KEY,vm.taxYear, vm.jcd,vm.scenario,$state.current.api.form_id).then(function(result){
        	vm.currentForm = result.irsForm;
        	 if(vm.currentForm.callSuccess !== "1" ){
                 vm.crudLoading = false;
                 AlertService.add("error", currentForm.errorMessage, 4000);
             }else{            	 
	           	  var res = result;
	           	  //first keep a copy of the irsForm
	           	  
	           	  vm.origIrsForm = angular.copy(vm.currentForm); 
	           	  console.log("load data",JSON.stringify(res));
	           	  //vm.title = "Federal Form "+vm.currentForm.name;
	          	  vm.formLines =  vm.currentForm.formItemList;
	          	  vm.title = vm.formLines[0].dataRowList[0].dataCellList[0].value;
	          	  console.log("Form Title is ::::::::" + vm.title);
	              vm.crudLoading = false;
	              //AlertService.add("success", "New Form Page Loaded" , 4000);
             }
        });
        }
        
        //FedFormFactory.getData("",""); 
        
        vm.addNewRow = function(_dataRowList){
        	console.log("within addNewRow");
        	var _newDataRowTemplate = angular.copy(_dataRowList[0]);
//        	console.log("_dataRowList.newDataRowTemplate :"+JSON.stringify(_newDataRowTemplate));//using the header row
        	_newDataRowTemplate.dataCellList.splice(0, 1); //remove first cell.
        	_newDataRowTemplate =  prepareNewDataRowTemplate(_newDataRowTemplate,_dataRowList.length-1);
//        	console.log("newDataRow :"+JSON.stringify(_newDataRowTemplate));
        	_dataRowList.push(_newDataRowTemplate);
        }//end addNewRow
        
        function prepareNewDataRowTemplate(_newDataRowTemplate,_currentOccurrence){
        	_newDataRowTemplate.rowDimensions.TRANS_DETAILS_KEY = "0";
        	var _newOccurrence = _currentOccurrence+1;
        	_newDataRowTemplate.rowDimensions.OCCURRENCE = _newOccurrence;
        	_newDataRowTemplate.rowDimensions.ROW_TYPE = "DT";
        	//_newDataRowTemplate.dataCellList.splice(0,1);//remove first cell..
        	angular.forEach(_newDataRowTemplate.dataCellList, function(_cell) {
        		//_cell.key = "0";
        		_cell.value="";
        		_cell.property.smartReadonly = false;//can edit cell
    			_cell.property.backgroundColor = "#FFFFFF";
        	});
        	return _newDataRowTemplate;
        }
        
        vm.setUpForEdit = function(_dataRow){
        	console.log("within setForEdit :");        	
        	//_dataRow.readonly = false;
        	angular.forEach(_dataRow.dataCellList, function(_cell) {
        		if(_cell.property.is_editable_cell){
        			//if cell is editable and dataRow is NOT readonly.
        			_cell.property.smartReadonly = false;//can edit cell now.
        			_cell.property.backgroundColor = "#FFFFFF";
        		}
        		else
        		{
        			_cell.property.smartReadonly = true; //dont edit cell
        			_cell.property.backgroundColor = "#C4C5C6";
        		}
        	});
        }//end setForEdit
        
        vm.processCellValue = function(_cell,_dataRow,_line){
        	if(_cell.dependancyProcessor == null)
    		{
        		console.log("%%%% Cell Name: "+_cell.name);
    			//return _cell.value;
    		}
        	/*else if(_cell.dependancyProcessor.resultCellIds == null){
        		//Dependancy Processor present but no resultCells.//So operand Cells must be present..
        		console.log("##### Cell Name: "+_cell.name);
        		vm.processDependancy(_cell,_cell,_line);
        		//return _cell.value;
        	}*/
        	else if(_cell.dependancyProcessor.resultCellIds != null){        	
        		//Dependancy Processor present and resultCells not null..use that
        		angular.forEach(_cell.dependancyProcessor.resultCellIds, function(resultId) {
        			console.log("@@@@ ResultId: "+resultId);
        			
        			_line.totalLineValue = "0"//reset and recalculate
        				var firstRow = true;
        			angular.forEach(_line.dataRowList, function(thisRow) {
        				if(!firstRow){//skip first Row - col header row
        			//Fetch the Result DataCell for this row.
        			var resultCell;
        			var thisCell;
        			
        			angular.forEach(thisRow.dataCellList, function(dataCell) {        				
        				var keepGoing = true;
        				console.log("dataCell.key="+dataCell.key);
        				if(keepGoing){
        				if(dataCell.key == resultId){
        						resultCell = dataCell;
        						keepGoing = false;//break
        						console.log("Found Result!");
        					}
        				}
        				//find the appropriate cell for the row
        				
        				if(keepGoing){
        					if(dataCell.key == _cell.key){//Note: keys remain same across dataRows.
        						thisCell = dataCell;
        						keepGoing = false;//break
        				  }
        				}        				
        		
        			});
        			if(resultCell != null && thisCell != null){
        				console.log("@@@@Res "+resultCell.value);
        				vm.processDependancy(resultCell,thisCell,_line);
        				console.log("@@@@%% "+thisCell.value);
        			}
        		  }
        		  firstRow = false;
        		});
        		});
        			//Now do group operation.
	        		vm.processedGrps = [];//reset
	        		vm.processGroupOp(_line);

        		//return _cell.value;
        	}
        }
  
        vm.processDependancy = function(_cell,_thisCell,_thisLine){
        	var formula = _cell.dependancyProcessor.formula;
        	//formula = "a-b-c";
        	console.log(formula);
        	console.log("_thisCell.key: "+_thisCell.key);
        	var operandsList = _cell.dependancyProcessor.operands;
        	var varArr = ["a","b","c","d","e","f"];
        	var a,b,c,d,e,f;
        	
        	if(operandsList != null){
        	for(var i=0;i<operandsList.length;i++){
        		var val;
        		console.log("operandsList[i].key: "+ operandsList[i].key);
        		if(operandsList[i].key == _thisCell.key)
        			{
        				val = _thisCell.value;
        				operandsList[i].value = val;
        				console.log("^^^^^: "+val);
        			}
        		else
        			{
        				val = operandsList[i].value;
        				console.log("XXXXXX: "+val);
        			}
        		eval(varArr[i]+"="+val);        		
        	}
	        	console.log("a="+a);
	    		console.log("b="+b);
	    		console.log("c="+c);
	    		//_cell.value = "8888";
	        	_cell.value = eval(formula);
	        	console.log("Evaluated value = "+_cell.value);
	        	_thisLine.totalLineValue = _thisLine.totalLineValue-(-_cell.value);
	        	console.log("Total Line value = "+_thisLine.totalLineValue);
	        	
        	}
        	//return _depProcessor.evaluatedValue;
        }//end processDependancy
        
        function existsInArray( arr, item ) {

            for( var i = 0; i < arr.length; i++ ) 
                if( arr[ i ] === item ) return true;

            return false; 

        }
        
        /**
         * Called from template
         */
        vm.processGroupOperation = function(_formLine){
        	vm.processedGrps = [];//reset
        	vm.processGroupOp(_formLine);
        }
        
        vm.processGroupOp = function(_formLine){
        	var resultLine;
        	
        	console.log(_formLine);
        	console.log(_formLine.groupOperationIds);
        	angular.forEach(_formLine.groupOperationIds, function(groupOperationId) { 
        		
        		var continueLoop = false;
        		if(!existsInArray(vm.processedGrps,groupOperationId)){
        			vm.processedGrps.push(groupOperationId);
        			continueLoop = true;
        		}        		
        		if(continueLoop){
        		console.log("Group Operation Outer Loop = "+groupOperationId);
		        	var _grpOpId = groupOperationId;
		        	var total;
		        	var resultCell;		        	
		        	var varArr = ["a","b","c","d","e","f"];
		        	var a,b,c,d,e,f;
		        	//first fetch all lines in the group
		        	//var index=0;
		        	var i=0;
		        	angular.forEach(vm.formLines, function(_line){ //check all lines in model..
		        		var keepGoing = true;
		        		console.log("_line: "+_line.objectDimensions.LINE_NO);
		        		angular.forEach(_line.groupOperationIds, function(theGrpOpId) {
		        		if(theGrpOpId == _grpOpId){
		        			//groupLines[index] = _line;
		        			console.log("Group Operation = "+_grpOpId);
		        			//one of the lines will be a Result Line		        			
		        			angular.forEach(_line.dataRowList, function(dataRow){ //All rows within Line		        				
		        				angular.forEach(dataRow.dataCellList, function(cell){ //All cells within Row		        					
		        					
		        	 					if(resultCell == null){	
			        						if(cell.resultCellGroupId == _grpOpId){
			        							resultCell = cell;
			        							resultLine = _line;//****************
			        							console.log("Found Result Cell = "+ resultCell.name);
			        							console.log("Result Cell Key = "+ resultCell.key);
			        							keepGoing = false;
			        						}//end if grpId Check
			        					 }
		        	 				if(keepGoing){//if resultCell then continue to next iteration in loop..
		        					if(dataRow.rowDimensions.ROW_TYPE == 'DT' &&  _line.objectDimensions.IS_MULTI == 'Y' ){
		        						if(cell.totalCell){
		        							//var val = cell.value;
		        							var val = _line.totalLineValue;
		        							console.log("Multi Line Eval = "+val);
		        							eval(varArr[i]+"="+val);
		        							console.log(varArr[i]+"="+val);
		        							i++;
		        							keepGoing = false;
		        						}
		        					}
		        	 				}// 
		        	 				if(keepGoing){
		        					if(_line.objectDimensions.IS_MULTI == 'N'){
		        						if(cell.name == 'GAIN_LOSS'){//TODO remove this hardcoding
		        							var val =  cell.value;
		        							console.log("Single Line Eval = "+val);
		        							eval(varArr[i]+"="+val);
		        							console.log(varArr[i]+"="+val);
		        							i++;
		        						}
		        					 }
		        	 				}
		        					
		        					
		        				});		        			   
		        				
		        			});		        			
		               			
		        		 }//end if
		        		});//end for
		        		//index++;
			        	});//end for        	
			        	var formula = resultCell.dependancyProcessor.formula;
			        	console.log("Formula: "+formula);
			        	resultCell.value = eval(formula);
			        	console.log("Part Total Value = "+resultCell.value);			        	
			        	//Now iterate again -- using the resultLine set in this iteration.        		
            	}     	
        	});//end forEach        	
        
        	if(resultLine != null){//exit out when the resultLine is no more set.
        		vm.processGroupOp(resultLine);
        	}
        }
        
       
        
    vm.deleteRow = function(_rowsList, _index,_rowId){
    	  vm.crudLoading = true; 
    	  _rowsList.splice(_index, 1);
    	  FedFormFactory.deleteRowData(_rowId,vm.rowData.COMBINATION_KEY,vm.taxYear, vm.jcd,vm.scenario).then(function(result){   
    		  if(result !== "1" ){
	              vm.crudLoading = false;
	              AlertService.add("error", currentForm.errorMessage, 4000);
	          }else{  
		           	vm.crudLoading = false;
		           	AlertService.add("success", "The Row was successfully Deleted" , 4000);
		           	// Reload the form again.
		        	//reload();	           
	          }   	  
    	  });
       }
        
      
      
      vm.saveFormData = function(){  
    	  vm.crudLoading = true;  	  
    	 
    	  //console.log ("Genric Form Scope :"+JSON.stringify($scope.genericForm));
    	  
    	  FedFormFactory.saveData(vm.formLines,vm.rowData.COMBINATION_KEY,$scope.genericForm,vm.taxYear, vm.jcd,vm.scenario).then(function(result){  
    		  console.log("RESULT: "+result);
    		  if(result === "1"){
		           	vm.crudLoading = false;
		           	AlertService.add("success", "The Form Data was successfully Saved" , 4000);
		           	// Reload the form again.
		        	reload();	           
	          }else if (result === "0" ){
	        	  vm.crudLoading = false;
	              AlertService.add("error", result.errorMessage, 4000);
	          }
	          else {//NO_SAVE
	        	  vm.crudLoading = false;
                          if(result=== ""){
                              AlertService.add("error", "Enter valid data in the highlighted fields." , 4000);
                          }
                          else {
			      AlertService.add("warning", "Nothing has been saved" , 4000);
                          }
	        	 }
      });
    	  if (vm.crudLoading) {
              AlertService.add("info", "Please wait while we save this request", 4000);
              return;
           }  
      }
        
        function getOrigLine(_formSection,_formLine,_origIrsForm){
        	angular.forEach(_origIrsForm.formSections, function(value, key) {
        		var formSection =value;
        		if(formSection.id ==  _formSection.id)
        		{
        			angular.forEach(formSection.formLines, function(value2, key2) {
        				var formLine =value2;
        				if(formLine.id ==  _formLine.id){
        					return formLine;
        				}
        			});
        		}
        	});
        	return null;
       }//end getOrigLine
        
        function getOrigCell(_formSection,_formLine,_dataRow,_dataCell,_origIrsForm){
        	angular.forEach(_origIrsForm.formSections, function(value, key) {
        		var formSection =value;
        		if(formSection.id ==  _formSection.id)
        		{
        			 angular.forEach(formSection.formLines, function(value2, key2) {
        				var formLine =value2;
        				angular.forEach(formLine.dataRows, function(value3, key3) {
        					var dataRow =value3;
        					angular.forEach(dataRow.dataCells, function(value4, key4) {
        						var dataCell = value4;
        						if(dataCell.id ==  _dataCell.id){
                					return dataCell;
                				}
        					});
        				});
        				
        			});
        		}
        	});
        	return null;
       }//end getOrigCell
        ///////////////////////////////POC POC POC //////////////////////////////////
     // new one
       vm.getEmptyCells = function(len){
            var emptyCell = [];
            if (len<1) { len = 0; }
            for(var i = 0; i < 1 - len; i++){
                emptyCell.push(i);
            }        
            return emptyCell;
        }
       // 
        vm.reset = function () {
            init();
            vm.currentForm =  angular.copy(vm.origIrsForm);
            vm.title = "Federal Form "+vm.currentForm.name;
     	  	vm.formLines =  vm.currentForm.formItemList;
        }
        
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }///////////////END CTRL
    return controllers;


});