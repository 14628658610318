

define([
    'angular',
    './updateGhostSettlementService',
    './updateGhostSettlementController'

], function () {
    'use strict';
    return angular.module('app.updateGhostSettlement', ['app.updateGhostSettlementCtrl','app.updateGhostSettlementService'] )

});
