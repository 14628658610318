define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.bizzHierViewServices', [])
    .factory("bizzHierViewFactory", ['$http', 'GENERAL_CONFIG', 'GlobalService',
        function ($http, GENERAL_CONFIG, GlobalService) {
            const bizzHierViewFactory = {};
            const JCD_KEY = 250;

            function requestUrlBuilder(url) {
                if (typeof (url) !== 'string') {
                    throw new Error('Url is not type of string.');
                }

                this.url = url;
                this.paramList = '';
                this.isFirstParam = true;

                this.get = function () {
                    return this.url + this.endpoint + this.paramList;
                };

                this.setEndpoint = function (endpoint) {
                    if (!this.url || this.url.length === 0) throw Error('Bad Url');
                    this.endpoint = endpoint;
                    return this;
                };

                this.appendParam = function (paramName, paramVal) {
                    if (!this.url || this.url.length === 0) throw Error('Bad Url');
                    if (paramName !== undefined && paramVal !== undefined) {
                        this.paramList += (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                        this.isFirstParam = false;
                    }
                    return this;
                }
            };

            bizzHierViewFactory.getBZHYUserPrivileges = function (taxYear, scenario) {
                const requestUrl = new requestUrlBuilder(GENERAL_CONFIG.admin_api_url);
                return $http.post(requestUrl.setEndpoint('/getBZHYUserPrivileges')
                                            .appendParam('tax_year', taxYear)
                                            .appendParam('scenario', scenario)
                                            .appendParam('jcd_key', JCD_KEY)
                                            .appendParam('activity_key', GlobalService.globalParams.activity_key)
                                            .appendParam('access', 1)
                                            .get());
            };

            bizzHierViewFactory.moveBusinessHierarchy = function (
                meKey,
                meParentKey,
                srcHierarchyId,
                descHierarchyId) {

                var params = {
                    "meKey":meKey,
                    "meParentKey":meParentKey,
                    "srcHierarchyId":srcHierarchyId,
                    "descHierarchyId":descHierarchyId,
                    "tax_year":GlobalService.globalParams.tax_year,
                    "scenario":GlobalService.globalParams.scenario,
                    "jcd_key":GlobalService.globalParams.jcd_key,
                    "activity_key":GlobalService.globalParams.activity_key,
                    "access": 2
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/moveBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            bizzHierViewFactory.mergeBusinessHierarchy = function (
                sourceMeKey,
                DestinationMeKey,
                hierarchyId,
                deleteBu
                ) {
                var redirectURL = "";
                var params = {
                    "tax_year":GlobalService.globalParams.tax_year,
                    "scenario":GlobalService.globalParams.scenario,
                    "jcd_key":GlobalService.globalParams.jcd_key,
                    "feedMeKeyList": sourceMeKey,
                    "feedToMeKeyList":DestinationMeKey,
                    "activity_key":GlobalService.globalParams.activity_key,
                    "access": 2
                };
                if(deleteBu){
                    redirectURL = '/business/redirectAndDelete';
                    params.hierarchyId = hierarchyId;
                }else{
                    redirectURL = '/business/redirect';
                }
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + redirectURL,
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            bizzHierViewFactory.autoPushBusinessHierarchy = function (
                json,
                tax_year,
                scenario,
                jcd_key,
                hierarchyId) {

                var params = {
                    "json": json,
                    "tax_year": tax_year,
                    "scenario": scenario,
                    "jcd_key": jcd_key,
                    "hierarchyId": hierarchyId,
                    "activity_key":GlobalService.globalParams.activity_key,
                    "access": 2
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/importExcelBizzHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            bizzHierViewFactory.editBusinessUnit = function (
                meKey,
                groupObjKey,
                description,
                bUCode,
                line_of_business,
                detail_bsla_ind,
                srcHierarchyId,
                hierarchyId, 
                taxYear, 
                estDataCollLvl, 
                jcdKey, 
                scenarioId) {

                var params = {
                    "meKey": meKey,
                    "groupObjKey": groupObjKey,
                    "description": description,
                    "bUCode": bUCode,
                    "line_of_business": line_of_business,
                    "detail_bsla_ind": detail_bsla_ind,
                    "srcHierarchyId": srcHierarchyId,
                    "hierarchyId": hierarchyId,
                    "taxYear": taxYear,
                    "estDataCollLvl": estDataCollLvl,
                    "jcdKey": jcdKey,
                    "scenarioId": scenarioId,
                    "activity_key":GlobalService.globalParams.activity_key,
                    "access": 2
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/editBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            bizzHierViewFactory.deleteBusinessUnit = function (
                meKey,
                meParentKey,
                groupObjKey,
                hierarchyId, 
                taxYear, 
                jcdKey, 
                scenarioId) {

                var params = {
                    "meKey": meKey,
                    "meParentKey": meParentKey,
                    "groupObjKey": groupObjKey,
                    "hierarchyId": hierarchyId,
                    "taxYear": taxYear,
                    "jcdKey": jcdKey,
                    "scenarioId": scenarioId,
                    "activity_key":GlobalService.globalParams.activity_key,
                    "access": 2
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/deleteBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            bizzHierViewFactory.addBusinessUnit = function (
                meCode,
                meNonGoldName,
                scenarioId,
                meParentKey,
                taxYear,
                jcdKey,
                hierarchyId,
                line_of_business,
                detail_bsla_ind, 
                estDataCollLvl) {

                var params = {
                    "meCode": meCode,
                    "meGoldName": 'None',
                    "meNonGoldName": meNonGoldName,
                    "refreshIndicator": 'N',
                    "srcSystemFlag": 'TAX',//OR 'GOLD'?
                    "scenarioId": scenarioId,
                    "meParentKey": meParentKey,
                    "status": 'A',
                    "taxYear": taxYear,
                    "jcdKey": jcdKey,
                    "hierarchyId": hierarchyId,
                    "line_of_business": line_of_business,
                    "detail_bsla_ind": detail_bsla_ind,
                    "estDataCollLvl": estDataCollLvl,
                    "activity_key":GlobalService.globalParams.activity_key,
                    "access": 2
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/addBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            bizzHierViewFactory.uploadExcelBizzHierarchy = function(file, filename, skipHeader) {
                var fd = new FormData();
                fd.append('file', file);
                var promise = $http.post(GENERAL_CONFIG.admin_api_url + '/userUploadBizzHierarchy?&fileName=' + filename + '&skipHeader=' + skipHeader + '&activity_key=' + GlobalService.globalParams.activity_key + '&access=2' , fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                }).then(function (response) {
                    return response;
                });

                return promise;
            };

            return bizzHierViewFactory;
        }]);
    return services;
});
