define([
        'angular',
        './CustomReportController'
    ], function () {
        'use strict';
        var $dstCustomReportState = null;
        var module = angular.module('app.dstCustomReport', ['app.CustomReportController'])
                                    .provider('$dstCustomReportState', ['$stateProvider', function ($stateProvider) {
                                        this.$get = function () {
                                            return {
                                                addState: function (stateName, url, controllerAs, templatePrefix, api, gridType, params, views) {
                                                    $entityMgmtAppState.state('custom-report.' + stateName, {
                                                        url: '/' + url,
                                                        type: gridType,
                                                        "api": api,
                                                        templateUrl: templatePrefix ? templatePrefix : null,
                                                        controller: controllerAs ? controllerAs : null,
                                                        params: params,
                                                        resolve: {},
                                                        views: views
                                                    });
                                                }
                                            };
                                        }
                                    }])
                                    .config(['$stateProvider', '$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider, $urlRouterProvider, GENERAL_CONFIG) {
                                        $dstCustomReportState = $stateProvider;
                                        $stateProvider.state('custom-report', {
                                                          url: '/custom-report',
                                                          "templateUrl": "app/components/DST/custom-report/custom-report-10.html",
                                                          "controller": "CustomReport10Ctrl as ctrl",
                                                          "noFilters": true,
                                                          access: ""
                                                      });
                                    }]);

        return module;
    }
);
