
define([
    'angular',
    './NotifMsgController',
    './NotifMsgService'
], function () {
    'use strict';
    return angular.module('app.notifMessages', ['app.notiMesgController','app.notifMesgService'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('notifMessages', {
                url: '/notifMessages',
                templateUrl: 'app/components/notificationMessageRouting/notificationMessage-tpl.html',
                // data:{ncyBreadcrumbLabel: 'NOTIF'},
                access:""
            })
    }])
});