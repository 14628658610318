define([
   'angular'

], function () {
   'use strict';

   var controllers =  angular.module('app.firSysFormChartAcctMapController',[]);

   controllers.controller('firSysFormChartAcctMapCtrl',
    ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory',
       'AlertService','JsonObjectFactory','FirSysFormGroupObjFactory','rowData', 'GENERAL_CONFIG',firSysFormChartAcctMapCtrl
   ])

 var firLookupData = [];
 	var firLookupData1 = [];
 	var firLookupData2 = [];
 	var firLookupData3 = [];
 	var firLookupData4 = [];
   function format(value, replace) {
		if (!value) {
			return value;
		}
		var target = value.toString();
		if (replace === undefined) {
			return target;
		}
		if (!angular.isArray(replace) && !angular.isObject(replace)) {
			return target.split('$0').join(replace);
		}
		var token = (angular.isArray(replace) && '$') || ':';

		angular.forEach(replace, function(value, key) {
			target = target.split(token + key).join(value);
		});
		return target;
	}

   controllers.value('customFirSelectDefaultsSystemForm', {
		displayText: 'Select...',
		emptyListText: 'There are no items to display',
		emptySearchResultText: 'No results match "$0"',
		addText: 'Add',
		searchDelay: 300,
	});

   controllers.directive('customFirSelectSystemForm', [
		'$parse',
		'$compile',
		'$timeout',
		'$q',
		'customFirSelectDefaultsSystemForm',
		function($parse, $compile, $timeout, $q, baseOptions) {
			var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

			return {
				restrict: 'A',
				require: 'ngModel',
				link: function(scope, elem, attrs, controller) {
					var customSelect = attrs.customFirSelectSystemForm;
					if (!customSelect) {
						throw new Error('Expected custom-select attribute value.');
					}
					scope.$watch("ctrl.fir_sys_acc.SYS_ACCT.SYS_ACCT",function(){
						scope.$emit('sysForm.SYS_ACCT_CHANGED', '');
					});
					var match = customSelect.match(CS_OPTIONS_REGEXP);
					if (!match) {
						throw new Error(
							'Expected expression in form of ' +
								"'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'" +
								" but got '" +
								customSelect +
								"'."
						);
					}

					elem.addClass('dropdown custom-select');

					// Ng-Options break down
					var displayFn = $parse(match[2] || match[1]),
						valueName = match[3],
						valueFn = $parse(match[2] ? match[1] : valueName),
						values = match[4],
						valuesFn = $parse(values),
						track = match[5],
						trackByExpr = track ? ' track by ' + track : '',
						dependsOn = attrs.csDependsOn;

					var options = getOptions(),
						timeoutHandle,
						lastSearch = '',
						focusedIndex = -1,
						matchMap = {};

					var itemTemplate =
							elem.html().trim() || '{{' + (match[2] || match[1]) + '}}',
						dropdownTemplate =
							'<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">' +
							'<span>{{displayText}}</span>' +
							'<b></b>' +
							'</a>' +
							'<div class="dropdown-menu">' +
							'<div stop-propagation="click" class="custom-select-search">' +
							'<input class="' +
							attrs.selectClass +
							'" type="text" autocomplete="off" ng-model="searchTerm" />' +
							'</div>' +
							'<ul role="menu">' +
							'<li role="presentation" ng-repeat="' +
							valueName +
							' in matches' +
							trackByExpr +
							'">' +
							'<a role="menuitem" tabindex="-1" href ng-click="select(' +
							valueName +
							')">' +
							itemTemplate +
							'</a>' +
							'</li>' +
							'<li ng-hide="matches.length" class="empty-result" stop-propagation="click">' +
							'<em class="muted">' +
							'<span ng-hide="searchTerm">{{emptyListText}}</span>' +
							'<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>' +
							'</em>' +
							'</li>' +
							'</ul>' +
							'<div class="custom-select-action">' +
							(typeof options.onAdd === 'function'
								? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
								: '') +
							'</div>' +
							'</div>';

					// Clear element contents
					elem.empty();

					// Create dropdown element
					var dropdownElement = angular.element(dropdownTemplate),
						anchorElement = dropdownElement.eq(0).dropdown(),
						inputElement = dropdownElement.eq(1).find(':text'),
						ulElement = dropdownElement.eq(1).find('ul');

					// Create child scope for input and dropdown
					var childScope = scope.$new(true);
					configChildScope();

					// Click event handler to set initial values and focus when the dropdown is shown
					anchorElement.on('click', function(event) {
						if (childScope.disabled) {
							return;
						}
						childScope.$apply(function() {
							lastSearch = '';
							childScope.searchTerm = '';
						});

						focusedIndex = -1;
						inputElement.focus();

						// If filter is not async, perform search in case model changed
						if (!options.async) {
							getMatches('');
						}
					});

					if (dependsOn) {
						scope.$watch(dependsOn, function(newVal, oldVal) {
							if (newVal !== oldVal) {
								childScope.matches = [];
								childScope.select(undefined);
							}
						});
					}

					// Event handler for key press (when the user types a character while focus is on the anchor element)
					anchorElement.on('keypress', function(event) {
						if (!(event.altKey || event.ctrlKey)) {
							anchorElement.click();
						}
					});

					// Event handler for Esc, Enter, Tab and Down keys on input search
					inputElement.on('keydown', function(event) {
						if (!/(13|27|40|^9$)/.test(event.keyCode)) return;
						event.preventDefault();
						event.stopPropagation();

						switch (event.keyCode) {
							case 27: // Esc
								anchorElement.dropdown('toggle');
								break;
							case 13: // Enter
								selectFromInput();
								break;
							case 40: // Down
								focusFirst();
								break;
							case 9: // Tab
								anchorElement.dropdown('toggle');
								break;
						}
					});

					// Event handler for Up and Down keys on dropdown menu
					ulElement.on('keydown', function(event) {
						if (!/(38|40)/.test(event.keyCode)) return;
						event.preventDefault();
						event.stopPropagation();

						var items = ulElement.find('li > a');

						if (!items.length) return;
						if (event.keyCode == 38) focusedIndex--; // up
						if (event.keyCode == 40 && focusedIndex < items.length - 1)
							focusedIndex++; // down
						if (focusedIndex >= 0) {
							items.eq(focusedIndex).focus();
						} else {
							focusedIndex = -1;
							inputElement.focus();
						}
					});

					resetMatches();

					// Compile template against child scope
					$compile(dropdownElement)(childScope);
					elem.append(dropdownElement);

					// When model changes outside of the control, update the display text
					controller.$render = function() {
						setDisplayText();
					};

					// Watch for changes in the default display text
					childScope.$watch(getDisplayText, setDisplayText);

					childScope.$watch(
						function() {
							return elem.attr('disabled');
						},
						function(value) {
							childScope.disabled = value;
						}
					);

					childScope.$watch('searchTerm', function(newValue) {
						if (timeoutHandle) {
							$timeout.cancel(timeoutHandle);
						}

						var term = (newValue || '').trim();
						timeoutHandle = $timeout(
							function() {
								getMatches(term);
							},
							// If empty string, do not delay
							(term && options.searchDelay) || 0
						);
					});

					// Support for autofocus
					if ('autofocus' in attrs) {
						anchorElement.focus();
					}

					var needsDisplayText;
					function setDisplayText() {
						console.log(controller,"controller");
						var locals = {};
						locals[valueName] = controller.$modelValue;
						var text = displayFn(scope, locals);
						if (text === undefined) {
							var map = matchMap[hashKey(controller.$modelValue)];
							if (map) {
								text = map.label;
							}
						}

						needsDisplayText = !text;
						childScope.displayText = text || controller.$modelValue // text || options.displayText;
					}

					function getOptions() {
						return angular.extend(
							{},
							baseOptions,
							scope.$eval(attrs.customSelectOptionsSf)
						);
					}

					function getDisplayText() {
						options = getOptions();
						return options.displayText;
					}

					function focusFirst() {
						var opts = ulElement.find('li > a');
						if (opts.length > 0) {
							focusedIndex = 0;
							opts.eq(0).focus();
						}
					}

					// Selects the first element on the list when the user presses Enter inside the search input
					function selectFromInput() {
						var opts = ulElement.find('li > a');
						if (opts.length > 0) {
							var ngRepeatItem = opts.eq(0).scope();
							var item = ngRepeatItem[valueName];
							childScope.$apply(function() {
								childScope.select(item);
							});
							anchorElement.dropdown('toggle');
						}
					}

					function getMatches(searchTerm) {
						var locals = { $searchTerm: searchTerm };
						$q.when(valuesFn(scope, locals)).then(
							function(matches) {
								if (!matches) return;

								if (searchTerm === inputElement.val().trim() /* && hasFocus*/) {
									matchMap = {};
									childScope.matches.length = 0;
									for (var i = 0; i < matches.length; i++) {
										locals[valueName] = matches[i];
										var value = valueFn(scope, locals),
											label = displayFn(scope, locals);

										matchMap[hashKey(value)] = {
											value: value,
											label: label,
										};

										childScope.matches.push(matches[i]);
									}
								}

								if (needsDisplayText) setDisplayText();
							},
							function() {
								resetMatches();
							}
						);
					}

					function resetMatches() {
						childScope.matches = [];
						focusedIndex = -1;
					}

					function configChildScope() {
						childScope.addText = options.addText;
						childScope.emptySearchResultText = options.emptySearchResultText;
						childScope.emptyListText = options.emptyListText;

						childScope.select = function(item) {
							var locals = {};
							locals[valueName] = item;
							var value = valueFn(childScope, locals);
							childScope.displayText =
								displayFn(childScope, locals) || options.displayText;
							controller.$setViewValue(value);
							anchorElement.focus();
							typeof options.onSelect === 'function' && options.onSelect(item);
						};

						childScope.add = function() {
							$q.when(options.onAdd(), function(item) {
								if (!item) return;

								var locals = {};
								locals[valueName] = item;
								var value = valueFn(scope, locals),
									label = displayFn(scope, locals);

								matchMap[hashKey(value)] = {
									value: value,
									label: label,
								};

								childScope.matches.push(item);
								childScope.select(item);
							});
						};

						childScope.format = format;
						setDisplayText();
					}

					var current = 0;
					function hashKey(obj) {
						if (obj === undefined) return 'undefined';

						var objType = typeof obj,
							key;

						if (objType == 'object' && obj !== null) {
							if (typeof (key = obj.$$hashKey) == 'function') {
								// must invoke on object to keep the right this
								key = obj.$$hashKey();
							} else if (key === undefined) {
								key = obj.$$hashKey = 'cs-' + current++;
							}
						} else {
							key = obj;
						}

						return objType + ':' + key;
					}
				},
			};
		},
	]);

   controllers.directive('stopPropagation', function() {
		return {
			restrict: 'A',
			link: function(scope, elem, attrs, ctrl) {
				var events = attrs['stopPropagation'];
				elem.bind(events, function(event) {
					event.stopPropagation();
				});
			},
		};
	});


   function firSysFormChartAcctMapCtrl ($rootScope,$scope,$http,$timeout,GlobalService,$uibModalInstance,ModalFactory,AlertService,JsonObjectFactory,FirSysFormGroupObjFactory,rowData,GENERAL_CONFIG) {


	 var vm = this;
	 console.log("rowData------------", rowData);
	 vm.fir_sys_acc = {};
	 vm.loadingFirSysAcct = true;
	 vm.orderByField = 'FORM';
     vm.reverseSort = false;
     vm.showFirDetails = {text:'lookup'};
     vm.sort_key = {'1':0,'2':0,'3':0,'4':0,'5':0};
     vm.firLookUpMapping = false;
     ///////////////////////
     vm.firLookupForm = [];
		vm.firLookupSchedule = [];
		vm.firLookupLine = [];
		vm.firLookupColumn = [];
		vm.firLookupSysMapAcct = [];
     ////////////////////////
     

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	 
	 
	 function init() {
		 getFirSystemAcctdropdown();
         fetchFirSystemAcctLookup();
	 }
	 init();

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	 
	 
	 vm.cancel = function(){
	     $uibModalInstance.dismiss('cancel');
		 	};

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		 	
	 vm.save = function(){
				 
				 if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
			                return;
			            }
				  $scope.crudLoading = true;
				 
	         if (vm.screenFrom.$invalid) {
	             vm.screenFrom.$submitted = true;
	             AlertService.add("danger", "Please correct the errors below", 4000);
	             return;
	         }

	        $timeout(function(){
	        	 var objSettings = [];
	        	 var jsonSetting = {
	        			 'TAX_YEAR' : rowData.TAX_YEAR,
	    	             'JCD_KEY' :  GlobalService.globalParams.jcd_key,
	    	             'SCENARIO' : GlobalService.globalParams.scenario
	        	 };
	             var returnObj = [];
	             var jsonObj = {};
	             
	             jsonObj['SYSTEM_ACCT_KEY'] = rowData.SYS_ACCT_KEY;
	             jsonObj['GROUP_OBJ_KEY'] = rowData.GROUP_OBJ_KEY;
	             
	             var index  = _.findIndex(vm.firSystemAcctLookupTemp, { 'FORM': vm.CHECK_FIR_FORM.trim(),'SCHEDULE':vm.CHECK_FIR_SCHEDULE.trim(),'LINE':vm.CHECK_FIR_LINE.trim(),'COL':vm.CHECK_FIR_COL.trim() });
	             
	             if(vm.showFirDetails.text == 'add'){
	            	 jsonObj['NEW_FORM_ACCT_KEY'] = vm.firSystemAcctLookupTemp[index].FORM_ACCT_KEY;
	            	 jsonObj['OLD_FORM_ACCT_KEY'] = null;
	            	 jsonObj['MAP_TYPE'] = 'SYSTEM(US)_TO_FORM_MAP';
	             }else if(vm.showFirDetails.text == 'edit'){
	            	 jsonObj['OLD_FORM_ACCT_KEY'] =  vm.old_fir_form_acct_key == undefined ? null : vm.old_fir_form_acct_key;
	            	 jsonObj['NEW_FORM_ACCT_KEY'] =  vm.firSystemAcctLookupTemp[index].FORM_ACCT_KEY;
	            	
	            	 jsonObj['MAP_TYPE'] = vm.old_fir_form_map_type;
	             }
	             returnObj.push(jsonObj);
	             console.log('returnObj--------------', returnObj);
	             console.log('jsonSetting------------', jsonSetting);
	             var message = "IRS Form has been successfully save/updated";
	             //IRSFormCRUDHttp(returnObj,IRSFormDetails, message)
	             
	             FirSysFormGroupObjFactory.saveFirIRSFormDetails(jsonSetting, returnObj).then(function (result) {
	                 if (result.errorMessage && result.errorMessage !== 'null') {
	                     AlertService.add("error", result.errorMessage, 4000);
	                     $scope.crudLoading = false;
	                 } else {
	                     AlertService.add("success", message, 4000);
	                     fetchSystemAcctFormData(rowData.SYS_ACCT_KEY); 
	                     //vm.loadSystemAndLocalAccountData = angular.copy(IRSFormDetails);
	                     $scope.crudLoading = false;
	                 }
	             });
	        });
	     };
//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	 
	  $scope.crudLoading = false;
	  
	 vm.firSystemAcctDetails  ={
			 acct_type : rowData.ACCT_TYPE,
			 system_acct : rowData.SYSTEM_ACCT,
			 system_acct_desc : rowData.SYSTEM_ACCT_DESC,
			 system_acct : rowData.SYS_ACCT_KEY
	 }

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	 

	 vm.firToggleLookup = function () {
  	   vm.CHECK_FIR_FORM = "";
         vm.CHECK_FIR_SCHEDULE = "";
         vm.CHECK_FIR_LINE = ""
         vm.CHECK_FIR_COL = "";
         vm.CHECK_FIR_SYS_MAP_ACCT = "";
         vm.firLookUpMapping = !vm.firLookUpMapping;
        vm.showFirDetails.text = 'lookup';
        //vm.changeddropdownflag = false;
     };
     
//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	    
   
     vm.firFormAdd = function(){
    	 vm.showFirDetails.text = 'add';
		 vm.firLookUpMapping = true;
		 //vm.changeddropdownflag = true;
	           
	     vm.CHECK_FIR_FORM = "";
	     vm.CHECK_FIR_SCHEDULE = "";
	     vm.CHECK_FIR_LINE = ""
	     vm.CHECK_FIR_COL = "";
	     vm.CHECK_FIR_SYS_MAP_ACCT = vm.fir_sys_acct_selected;
     }
     
 //-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		 
	
     function getFirSystemAcctdropdown(){
		 var params = {
					"action_code" : 'pfwnw7',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key
			}
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
					function(data) {
						console.log("Response Data ::", data);
						if (data.callSuccess === "1") {
							vm.fir_sys_acc_dropdown_data = [];
							//vm.pl_acct_flag = true;
							for ( var index_ in data.jsonObject) {
								vm.fir_sys_acc_dropdown_data.push(data.jsonObject[index_].SYS_DROPDOWN);
							}
							vm.fir_sys_acc_dropdown_data_TEMP = data.jsonObject;
							
							var index = _.findIndex(vm.fir_sys_acc_dropdown_data_TEMP,{'SYS_ACCT' : rowData.SYSTEM_ACCT});
							
							vm.fir_sys_acc.SYS_ACCT = vm.fir_sys_acc_dropdown_data_TEMP[index];
							vm.fir_sys_acc.SYS_ACCT_TEMP = vm.fir_sys_acc_dropdown_data_TEMP[index];
							vm.fir_sys_acct_selected = (vm.fir_sys_acc_dropdown_data_TEMP[index] !== undefined && vm.fir_sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN !== null) ? (vm.fir_sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN)
									: 'Please select System Account';
							
							fetchSystemAcctFormData(vm.fir_sys_acct_selected.SYS_ACCT_KEY);
							vm.loadingFirSysAcct = false;
							
						} else {
							if (data.errorMessage === "no access") {
								AlertService.add("", "Sorry you do not have access to do the requested action.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add("", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

					});
	 }

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	 
	 function fetchSystemAcctFormData(sys_acct_key) 
	{
		 
		if (vm.fir_sys_acc.SYS_ACCT == undefined) {
			return;
		} else { 
				var params = {
						"action_code" : '4fmxwf',
						"tax_year" : rowData.TAX_YEAR,
						//"local_acct" : rowData.LOCAL_ACCT_KEY,
						"sys_acct" :(typeof vm.fir_sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.fir_sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY:vm.fir_sys_acc.SYS_ACCT.SYS_ACCT_KEY
				};
		}
			vm.loadingFirIRSform = true;
			//console.log("Input Data fetchSystemAcctData**********::",
					//params);
			return JsonObjectFactory
					.getJSONObj(
							GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
								console.log("Response Data ::", data);
								if (data.callSuccess === "1") {
									vm.firIRSFormData = data.jsonObject;
									vm.loadingIRSform = false;
									console.log("form Data-----", vm.firIRSFormData);
									
								} else {
									if (data.errorMessage === "no access") {
										AlertService
												.add(
														"",
														"Sorry you do not have access to do the requested action.",
														4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									} else {
										AlertService
												.add(
														"",
														"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
														4000);
										vm.userMessage = "!! Unable to perform Selected Action...";
									}
								}

							});
	}
	 
//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	 
	 
	 function fetchFirSystemAcctLookup(){
		 vm.loadingFirLookup = true;
		 
			var params = {
					"action_code" : 'oz9yxt',
					"tax_year" : GlobalService.globalParams.tax_year,
					"scenario" : GlobalService.globalParams.scenario,
					"jcd_key" : GlobalService.globalParams.jcd_key
		     }
			
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(
					function(data) {
						console.log("Response Data x ::", data);
						if (data.callSuccess === "1") {

							vm.firSystemAcctLookupTemp = data.jsonObject;
							vm.firLookupForm = [];
							vm.firLookupSchedule = [];
							vm.firLookupLine = [];
							vm.firLookupColumn = [];
							vm.firLookupSysMapAcct = [];
							vm.firLookupSysMapAcct1 = [];
							vm.firLookupcheckdata = data.jsonObject;

							for ( var index_ in data.jsonObject) {
								vm.firLookupForm
										.push(data.jsonObject[index_].FORM);
							}
							vm.firLookupForm = _.uniq(
									vm.firLookupForm,
									JSON.stringify)
							vm.loadingFirLookup = false;
							
						} else {
							vm.loadingFirLookup = false;
							if (data.errorMessage === "no access") {
								AlertService
										.add(
												"",
												"Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService
										.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

					});
	 }
	 
	
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
	 
	 vm.formEdit = function(_item,index){
		 	
	       vm.CHECK_FIR_SYS_MAP_ACCT = vm.fir_sys_acct_selected; 
	       vm.SYSTEM_ACCT_KEY = _item.SYSTEM_ACCT_KEY; 
	       vm.FORM_ACCT_KEY = _item.FORM_ACCT_KEY; 
	       vm.old_fir_form_acct_key = _item.FORM_ACCT_KEY;
	       vm.old_fir_form_map_type = _item.MAP_TYPE,
	       vm.showFirDetails.text = 'edit';
	       vm.currentEditedIndex = index;
	       
	      // vm.changedfirdropdownflag = true;
	       
	       
	       
	       vm.firLookUpMapping = true;
	       vm.CHECK_FIR_FORM = _item.FORMS;
	       var b = _item.FORMS.trim();
	       vm.CHECK_FIR_SCHEDULE = _item.SCHEDULE;
	       var c =_item.SCHEDULE.trim();
	       vm.CHECK_FIR_LINE = _item.LINE+" - "+_item.LINE_DESC;
	       var d =_item.LINE;//+" - "+_item.LINE_DESC.trim();
	       vm.CHECK_FIR_COL = _item.COL;
	       vm.CHECK_FIR_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT;
	       vm.firFormEditFlagContent = true;
	        var testdata = vm.firLookupcheckdata == undefined? [] : vm.firLookupcheckdata; 
	       var nextFIRtestLookupDataschedule =[];
	       var firScheduletestvalue  = testdata.filter(function(object,index){
	       if(object["FORM"] ==  b )
	       {
	               return true;
	       }
	       });
	
	       firScheduletestvalue.forEach(function(object,index){
	    	   nextFIRtestLookupDataschedule.push(object["SCHEDULE"]);
	     });
	       nextFIRtestLookupDataschedule =  _.uniq(nextFIRtestLookupDataschedule, JSON.stringify);
	      vm.firLookupSchedule = nextFIRtestLookupDataschedule;
	      console.log("vm.firLookupSchedule----------------", vm.firLookupSchedule);
	      var nextFIRtestLookupDataLine =[];
	
		   var firLinetestvalue  = testdata.filter(function(object,index){
			       
			    if(object["FORM"] ==  b  && object["SCHEDULE"] ==  c )
			    {
			            return true;
			    }
		   });
	
		   firLinetestvalue.forEach(function(object,index){
			nextFIRtestLookupDataLine.push(object["LINE"]);
		   });
		nextFIRtestLookupDataLine =  _.uniq(nextFIRtestLookupDataLine, JSON.stringify);
	   vm.firLookupLine = nextFIRtestLookupDataLine;
	   console.log("vm.firLookupLine----------------", vm.firLookupLine);
	 var nextFIRtestLookupDataCol =[];
	 var COltestFIRvalue  = testdata.filter(function(object,index){
	     if(object["FORM"] ==  b  && object["SCHEDULE"] ==  c  && object["LINE"] == d)
	     {
	             return true;
	     }
	});
	
	 COltestFIRvalue.forEach(function(object,index){
		 nextFIRtestLookupDataCol.push(object["COL"]);
	   });
	 nextFIRtestLookupDataCol =  _.uniq(nextFIRtestLookupDataCol, JSON.stringify);
	  vm.firLookupColumn= nextFIRtestLookupDataCol;
	  console.log("vm.firLookupColumn----------------", vm.firLookupColumn);
	  vm.CHECK_FIR_SYS_MAP_ACCT = vm.fir_sys_acct_selected;
}
	 
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
	
	 vm.getFirLookupNext = function(key, nextLookupKey,
				currentLookupValue) {
			var firLookupData = angular.copy(vm.firLookupcheckdata);
			var a = "SYS_ACCT";

			switch (key) {
			case 1:
				vm.firLookupSchedule = filterFirDataByKey("FORM",
						nextLookupKey, firLookupData, currentLookupValue);

				break;
			case 2:
				vm.firLookupLine = filterFirDataByKey1("SCHEDULE",
						nextLookupKey, firLookupData1, currentLookupValue);
				break;
			case 3:
				vm.firLookupColumn = filterFirDataByKey2("LINE",
						nextLookupKey, firLookupData2, currentLookupValue);
				vm.firLookupSysMapAcct = filterFirDataByKey22("LINE", a,
						firLookupData2, currentLookupValue);
				break;

			case 4:
				vm.firLookupSysMapAcct = filterFirDataByKey3("COL",
						nextLookupKey, firLookupData3, currentLookupValue);
				break;
			case 5:
				vm.firLookupSysMapAcct = filterFirDataByKey4("LINE",
						nextLookupKey, firLookupData2, currentLookupValue);
				break;
			}

		}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		 
	 vm.sortBy = function(key, value) {
			$timeout(function() {
				switch (value) {
				case 0:
					vm.sort_key[key] = 1;
					vm.reverseSort = false;
					break;
				case 1:
					vm.sort_key[key] = 2;
					vm.reverseSort = true;
					break;
				case 2:
					vm.sort_key[key] = 0;
					vm.reverseSort = "";
					break;
				}
			});
		}
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	
	 vm.firFormchanged = function(_item) {
			vm.CHECK_FIR_SCHEDULE = null;
			vm.CHECK_FIR_LINE = null;
			vm.CHECK_FIR_COL = null;
			//vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------			
		
		vm.firSchedulechanged = function(_item) {
			vm.CHECK_FIR_LINE = null;
			vm.CHECK_FIR_COL = null;
			//vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.firLinechanged = function(_item) {
			vm.CHECK_FIR_COL = null;
			//vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};
		
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
		vm.firColumnchanged = function(_item) {
			//vm.CHECK_FIR_SYS_MAP_ACCT = null;
		};

		

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
				
				function filterFirDataByKey(currentLookupKey, nextLookupKey,
						firLookupData, currentLookupValue) {
					var nextLookupData = [];

					var currentFirLookupFilter = firLookupData.filter(function(
							object, index) {
						//console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentFirLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					firLookupData1 = currentFirLookupFilter;
					console.log(firLookupData1, "check Here IMPORTANT inside");
					return nextLookupData;
				}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

				function filterFirDataByKey1(currentLookupKey, nextLookupKey,
						firLookupData1, currentLookupValue) {
					var nextLookupData = [];
					var currentLookupFilter = firLookupData1.filter(function(
							object, index) {
						//console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					firLookupData2 = currentLookupFilter;

					return nextLookupData;
				}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
				
				function filterFirDataByKey2(currentLookupKey, nextLookupKey,
						firLookupData2, currentLookupValue) {
					var nextLookupData = [];
					var nextLookupData1 = [];
					var currentLookupFilter = firLookupData2.filter(function(
							object, index) {
						//console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);

					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					firLookupData3 = currentLookupFilter;
					return nextLookupData;
				}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
				
				function filterFirDataByKey22(currentLookupKey, a, firLookupData2,
						currentLookupValue) {
					var nextLookupData = [];

					var currentLookupFilter = firLookupData2.filter(function(
							object, index) {
						//console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[a]);

					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					return nextLookupData;
				}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
				
				function filterFirDataByKey3(currentLookupKey, nextLookupKey,
						firLookupData3, currentLookupValue) {
					var nextLookupData = [];
					var currentLookupFilter = firLookupData3.filter(function(
							object, index) {
						//console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					firLookupData4 = currentLookupFilter;
					return nextLookupData;
				}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------		
				
				function filterFirDataByKey4(currentLookupKey, nextLookupKey,
						firLookupData4, currentLookupValue) {
					var nextLookupData = [];
					var currentLookupFilter = firLookupData4.filter(function(
							object, index) {
						//console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					return nextLookupData;
				}
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------	
		
	 
   }
   return controllers;

});