
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.aggrerunService',[])

    .factory('AggRerunServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
            var aggRerunServiceFactory = {};
            aggRerunServiceFactory.url = {};
    
            aggRerunServiceFactory.reRunAggEntities = function(_data,Details,type){
            	if(type == 'aggRerun'){
            		aggRerunServiceFactory.url.rerun = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nllr6f";
                    aggRerunServiceFactory.process_name = "Rerun Aggregation Entities";
            	}else if (type == 'consolCalcSchJ' ){
            		aggRerunServiceFactory.url.rerun = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zo8k0k";   
                    aggRerunServiceFactory.process_name = "Rerun Consol Calc Sch J";        		
            	}else if (type == 'consolCalcCredits'){
                    aggRerunServiceFactory.url.rerun = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=zo8k0k";
                    aggRerunServiceFactory.process_name = "Rerun Consol Calc Credits";//91b2u5
                }

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params.combination_key = _data.combination_key;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  aggRerunServiceFactory.process_name;
            console.log(params);
            var promise = $http({
                method: "post",
                url: aggRerunServiceFactory.url.rerun,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        return aggRerunServiceFactory
    }])

    return services;

});