define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.Form1065ScheduleCCtrl', [])
        .controller('F1165SCHDCController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$filter','$rootScope', '$timeout', 'rowData', 'colData', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', F1165SCHDCController]);


    function F1165SCHDCController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $filter,$rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        
        // modalTitle shouldn't be hardcoded.
        vm.modalTitle = "Schedule C- Additional Information for Schedule M-3 Filers";
        vm.isSaving = false;
        
        
        vm.formData = {
            "addrows": [],
            "editrows": [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME,
            // "LE TYPE": rowData.LE_TYPE,
            //"LEDGER": rowData.LEDGER,
            // "ME": rowData.ME
        };

        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = vm.modalTitle + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';

        var saveParamDtls = [];
        var tempDataObj = {};
        $scope.scheduleFFormView = {rows: []};
        vm.showFormLoader = true;

        function fetchSchCrecords() {
            var params = { 
                "action_id": 31259, 
                "tax_year": rowData.TAX_YEAR, 
                "scenario": rowData.SCENARIO_KEY, 
                "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ 
                "combination_key": rowData.COMBINATION_KEY, 
                "schedule_name": "C"};
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadPivotFormJson', params).then(function (data) {
                // vm.modalTitle = data.irsForm.formItemList[0].dataRowList[0].dataCellList[1].name; // get the title from service
               for (var i = 0; i < data.irsForm.formItemList.length; i++) {
                    saveParamDtls = [];
                    tempDataObj = {};
                    
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    tempDataObj.saveParams = saveParamDtls;                   

                    for (var j = 0; j < data.irsForm.formItemList[i].dataRowList[0].dataCellList.length; j++) {
                        if (j == 0) {
                            tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 1) {
                            tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 2) {
                            tempDataObj.lineAttrKeyA = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
							}
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                tempDataObj.value1 = "";
                            } else if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == "true") {
                                tempDataObj.value1 = true;
                            } else if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == "false"){
                                tempDataObj.value1 = false;
                            }else{
                                tempDataObj.value1 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                            }
                        } else {
                            tempDataObj.lineAttrKeyB = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
							}
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null) {
                                tempDataObj.value2 = "";
                            } else if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == "true"){
                                tempDataObj.value2 = true;
                            } else if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == "false"){
                                tempDataObj.value2 = false;
                            } else {
                                tempDataObj.value2 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                            }
                        }
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    $scope.scheduleFFormView.rows.push(tempDataObj);
                }
                $scope.scheduleFFormView.rows[0].value1 = rowData.LE_NAME;
                $scope.scheduleFFormView.rows[1].value1 = rowData.EIN;
                vm.formListOriginal = angular.copy($scope.scheduleFFormView);
                initFormView($scope.scheduleFFormView);
                vm.showFormLoader = false;
            });
        }

        function fetchSchFimports() {
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "status": 'Y'
            };
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=wwledr', params).then(function (data) {
                vm.BatchList = data.jsonObject;
                angular.forEach(vm.BatchList, function (value, key) { 
                    vm.BatchList[key].TOGGLE = false; 
                });
                vm.showRequestLoader = false;
            });
        }

        fetchSchCrecords();
        // fetchSchFimports();
        // loadTransactions
        
        function loadTransactions(index, trans_key){
            vm.batchLoader = true;
            var params = { "client_key": USER_SETTINGS.user.client_key,
                "process_name": workspaceFactory.activeScreen.label,
                "transaction_key": trans_key
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=l6gzle', params).then(function (data) {
                var tempData = data.jsonObject;
                var currLineNo;
                var tempBatchList = {};
                var tempBatchData = [];
                angular.forEach(tempData, function (value, key) {
                    if(key == 0){
                        currLineNo = tempData[key].LINE_NO;
                        tempBatchList = tempData[key];
                    }else{
                        if(tempData[key].LINE_NO == currLineNo){
                            tempBatchList.ATTRIBUTE_VALUE_B = tempData[key].ATTRIBUTE_VALUE;
                            tempBatchData.push(tempBatchList);
                        }else{
                            currLineNo = tempData[key].LINE_NO;
                            tempBatchList = {};
                            tempBatchList = tempData[key];
                        }
                    }
                });
                vm.batchLoader = false;
                vm.showBatchData = true;
                vm.BatchListData = tempBatchData;
            });
        };

        vm.unselectOther = function(index, type){
			if(type == 'YES'){
				$scope.scheduleFFormView.rows[index].value2 = false;
			}else {
				$scope.scheduleFFormView.rows[index].value1 = false;
			}
		};

        vm.saveSchddForm = function (url, data) {
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);
            params.process_name = workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isSaving =false;
                var status = response.status;
                
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage);
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function () {
            var formRowDataA = {};
            var formRowDataB = {};
            var tempArray = [];
            vm.isSaving = true;
            for (var i = 0; i < $scope.scheduleFFormView.rows.length; i++) {
                formRowDataA = {
                    tax_year: rowData.TAX_YEAR,
                    trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                    line_order: $scope.scheduleFFormView.rows[i].line_order,
                    attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_a,
                    line_description: $scope.scheduleFFormView.rows[i].description,
                    combination_key: rowData.COMBINATION_KEY,
                    group_obj_key: rowData.GROUP_OBJ_KEY,
                    form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                    line_no: $scope.scheduleFFormView.rows[i].num,
                    occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                    line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyA,
                    Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_a,
                    Attribute_Value: $scope.scheduleFFormView.rows[i].value1,
                    trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                    trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                    is_dirty: ''
                };
                tempArray.push(formRowDataA);
                if(i>1){
                    
                    formRowDataB = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleFFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_b,
                        line_description: $scope.scheduleFFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleFFormView.rows[i].num,
                        occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyB,
                        Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_b,
                        Attribute_Value: $scope.scheduleFFormView.rows[i].value2,
                        trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                        is_dirty: ''
                    };
                    tempArray.push(formRowDataB);    
                }
                
                
            }
            vm.formData.editrows.push(tempArray);
            vm.mainData = [];
            vm.mainData.push(vm.formData);
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tkgaam";
            var saveDiagURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ftmltp";
            vm.saveSchddForm(url, vm.mainData);
            var diagData = [];
            angular.forEach($scope.unallocatedAmount, function (array, key) {
                angular.forEach(array, function (obj, key) {
                    if(key === 3){
                        diagData.push(obj);
                    }
                });
            });
            
            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function () {
                $scope.$broadcast("showLoader", true);
            }, 100);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.reset = function () {
            $scope.scheduleFFormView = angular.copy(vm.formListOriginal);
		}
        
        function initFormView(filteredFormData) {
            $scope.scheduleFFormView = filteredFormData;
        }

        //Dynamic Tabset Code
        vm.tabs = [
            {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Sch-C/F1065-scheduleCEntry.html", active: true },
            // {id: 2, name: "Form View", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFView.html", active: false},
            // {id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFVersion.html", active: false},
            // {id: 4, name: "PDF", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFPdf.html", active: false}
        ];
    }
});