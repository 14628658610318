define([
    'angular'

], function () {
    'use strict';

    var defaultChartConfig = {
        chart: {
            style: {
                fontFamily: '"PwC Helvetica Neue", sans-serif !important'
            }
        },
        lang: {
            thousandsSep: ',',
            numericSymbols: ['K', 'M', 'B','T']
        },
        boost: {
            enabled: true,
            useGPUTranslations: true,
          },
        title: '',
        credits: {
            enabled: false
        },
        yAxis: {},
        xAxis: {
            scrollbar: {
                enabled: false
            },
            min: 0,
            step: 1,
            tickInterval: 1,
            categories: [],
            labels: {
                formatter: function () {
                    if (typeof this.value === 'string' && this.value.includes("_")) {
                        this.value = this.value.split("_").join(" ")
                    }
                    return this.value
                }
            }

        },
        exporting: {
            allowHTML: true,
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                }
            },
            menuItemDefinitions: {
                chartOptions: {
                    text: 'Chart Options'
                },
            }
        },
        tooltip: {
            enabled: true,
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                    inside: true
                }

            },
            sunburst:{
                allowDrillToNode: true,
                cursor: 'pointer',
                dataLabels: {
                    // format: '{point.name}',
                    filter: {
                        property: 'innerArcLength',
                        operator: '>',
                        value: 16
                    },
                    rotationMode: 'circular'
                },
                levels: [{
                    level: 1,
                    levelIsConstant: false,
                    dataLabels: {
                        filter: {
                            property: 'outerArcLength',
                            operator: '>',
                            value: 64
                        }
                    }
                }, {
                    level: 2,
                    colorByPoint: true
                },
                {
                    level: 3,
                    colorVariation: {
                        key: 'brightness',
                        to: -0.5
                    }
                }, {
                    level: 4,
                    colorVariation: {
                        key: 'brightness',
                        to: 0.5
                    }
                }]

            },
            variablepie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                minPointSize: 50,
                innerSize: '40%',
                zMin: 10,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name} : </b> {point.percentage:.2f} %',
                },
            },

            packedbubble: {
                minSize: '20px',
                maxSize: '150px',
                draggable:false,
                // zMin: 0,
                // zMax: 1000,
                // useSimulation: false,
                layoutAlgorithm: {
                    enableSimulation:false,
                    splitSeries: false,
                    gravitationalConstant: 0.02
                },
                dataLabels: {
                    enabled: true,
                    format: '{point.name}',
                    // filter: {
                    //     property: 'percentage',
                    //     operator: '>',
                    //     value: 50
                    // },
                    // style: {
                    //     color: 'black',
                    //     textOutline: 'none',
                    //     fontWeight: 'normal'
                    // }
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name} : </b> {point.percentage:.2f} %',
                },
            },
        },
        series: []
    };

    var defaultDataTableConfig = {
        rowButtons: [
            {
                template: '<span class="datatable-dropdown-wrapper pull-right"><button class="btn btn-md add-comment-btn" ><span class="caret"></span></button></span>',

            }
        ],
        dom: 'tS',
        responsive: true,
        paging: false,
        bFilter: true,
        deferRender: false,
        fixedHeader: true,
        scrollY: 380,
        scrollCollapse: true,
        scrollResize: true,
    }

    var darkThemeHighcharts = {
        colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
            '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
        chart: {
            backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                stops: [
                    [0, '#2a2a2b'],
                    [1, '#3e3e40']
                ]
            },
            plotBorderColor: '#606063'
        },
        xAxis: {
            gridLineColor: '#707073',
            labels: {
                style: {
                    color: '#E0E0E3'
                }
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            title: {
                style: {
                    color: '#A0A0A3'
                }
            }
        },
        yAxis: {
            gridLineColor: '#707073',
            labels: {
                style: {
                    color: '#E0E0E3'
                }
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            tickWidth: 1,
            title: {
                style: {
                    color: '#A0A0A3'
                }
            }
        },
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            style: {
                color: '#F0F0F0'
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    color: '#F0F0F3',
                    style: {
                        fontSize: '13px'
                    }
                },
                marker: {
                    lineColor: '#333'
                }
            },
            boxplot: {
                fillColor: '#505053'
            },
            candlestick: {
                lineColor: 'white'
            },
            errorbar: {
                color: 'white'
            }
        },
        legend: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            itemStyle: {
                color: '#E0E0E3'
            },
            itemHoverStyle: {
                color: '#FFF'
            },
            itemHiddenStyle: {
                color: '#606063'
            },
            title: {
                style: {
                    color: '#C0C0C0'
                }
            }
        },
        credits: {
            style: {
                color: '#666'
            }
        },
        labels: {
            style: {
                color: '#707073'
            }
        },
        drilldown: {
            activeAxisLabelStyle: {
                color: '#F0F0F3'
            },
            activeDataLabelStyle: {
                color: '#F0F0F3'
            }
        },
        navigation: {
            buttonOptions: {
                symbolStroke: '#DDDDDD',
                theme: {
                    fill: '#505053'
                }
            }
        },
        // scroll charts
        rangeSelector: {
            buttonTheme: {
                fill: '#505053',
                stroke: '#000000',
                style: {
                    color: '#CCC'
                },
                states: {
                    hover: {
                        fill: '#707073',
                        stroke: '#000000',
                        style: {
                            color: 'white'
                        }
                    },
                    select: {
                        fill: '#000003',
                        stroke: '#000000',
                        style: {
                            color: 'white'
                        }
                    }
                }
            },
            inputBoxBorderColor: '#505053',
            inputStyle: {
                backgroundColor: '#333',
                color: 'silver'
            },
            labelStyle: {
                color: 'silver'
            }
        },
        navigator: {
            handles: {
                backgroundColor: '#666',
                borderColor: '#AAA'
            },
            outlineColor: '#CCC',
            maskFill: 'rgba(255,255,255,0.1)',
            series: {
                color: '#7798BF',
                lineColor: '#A6C7ED'
            },
            xAxis: {
                gridLineColor: '#505053'
            }
        },
        scrollbar: {
            barBackgroundColor: '#808083',
            barBorderColor: '#808083',
            buttonArrowColor: '#CCC',
            buttonBackgroundColor: '#606063',
            buttonBorderColor: '#606063',
            rifleColor: '#FFF',
            trackBackgroundColor: '#404043',
            trackBorderColor: '#404043'
        }
    };
      

    angular.module('app.dashboardConfig', [])
        .constant('defaultChartConfig', defaultChartConfig)
        .constant('defaultDataTableConfig', defaultDataTableConfig)
        .constant('darkThemeHighcharts', darkThemeHighcharts)

});