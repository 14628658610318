define([
        'angular',
        './StatementTrackerController',
        './StatementTrackerService'
    ], function () {
        'use strict';
        var $dstStatementTrackerState = null;
        var workflowModule = angular.module('app.dstStatementTracker', ['app.StatementTrackerControllers', 'app.StatementTrackerService'])
                                    .provider('$dstStatementTrackerState', ['$stateProvider', function ($stateProvider) {
                                        this.$get = function () {
                                            return {
                                                addState: function (stateName, url, controllerAs, templatePrefix, api, gridType, params, views) {
                                                    $entityMgmtAppState.state('statement-tracker.' + stateName, {
                                                        url: '/' + url,
                                                        type: gridType,
                                                        "api": api,
                                                        templateUrl: templatePrefix ? templatePrefix : null,
                                                        controller: controllerAs ? controllerAs : null,
                                                        params: params,
                                                        resolve: {},
                                                        views: views
                                                    });
                                                }
                                            };
                                        }
                                    }])
                                    .config(['$stateProvider', '$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider, $urlRouterProvider, GENERAL_CONFIG) {
                                        $dstStatementTrackerState = $stateProvider;
                                        $stateProvider.state('statement-tracker', {
                                                          url: '/statement-tracker/:taxYear/:pId/:cId',
                                                          "templateUrl": "app/components/DST/statement-tracker/tracker-grid-10.html",
                                                          "controller": "TrackerGrid10Ctrl as ctrl",
                                                          "noFilters": true,
                                                          params: {
                                                            pId: {
                                                                value:"",
                                                                squash: true
                                                            },
                                                            cId: {
                                                                value:"",
                                                                squash: true
                                                            },
                                                            taxYear: {
                                                                value:"",
                                                                squash: true
                                                            }
                                                          },
                                                          access: ""
                                                      })
                                    }]);

        return workflowModule;
    }
);
