/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.ProjectTagServce',[])

        .factory("ProjectTaggingFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
            var factory = {};

            var URLS = {
                FETCH: GENERAL_CONFIG.base_url + '/getProjectTag',
                UPDATE: GENERAL_CONFIG.base_url + '/saveProjectTag'
            }

            factory.saveProjectTag = function(_schdMs){

                var data = {jsonObj:JSON.stringify(_schdMs)}
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , data);
                _.merge({},  GlobalService.globalParams , params);




                var promise = $http({
                    method: "post",
                    url: URLS.UPDATE,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });


                return promise;
            }



            return factory;

        }])


    return services;


});