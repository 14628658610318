define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.FormScheduleACtrl', [])
        .controller('F1120LSCHDAController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG',F1120LSCHDAController]);
    function F1120LSCHDAController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG) {
        var vm = this;
        vm.modalTitle = "Schedule A Dividends, Inclusions, Dividends-Recieved Deduction, and Other Special Deductions";
        vm.showFormLoader = true;
        vm.showDiagLoader = true;
        var tempDataObj = {};
        vm.scheduleAFormView = {rows:[]};
        vm.formData = [];
        vm.accountData = [];
        vm.totalLines = [];
        vm.mainLineLoader = false;
        var screen_key = workspaceFactory.activeScreen.screen_key;
        var currType;
        var main_indx;
        var main_sub_indx;
        var acct_indx;
        vm.no_acct_child = false;
        vm.no_main_sub_data = false;
        vm.show_toggle = true;
        vm.show_toggle_total = true;
        vm.no_total_lines = false;
		vm.leId = rowData.LEID;
        vm.formName = '1120L';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';

        function fetchSchdFormView() {
            var params = { "action_id": 1245, "screen_key": screen_key, "tax_year": rowData.TAX_YEAR, "form_no": rowData.FORM_NO,"schd_name": rowData.SCHD_NAME, "scenario": rowData.SCENARIO, "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ "combination_key": rowData.COMBINATION_KEY};
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                for (var i = 0; i < data.jsonObject.length; i++) {
                    tempDataObj = {};
                    tempDataObj.num = data.jsonObject[i].LINE_NO;
                    tempDataObj.description = data.jsonObject[i].LINE_DESCRIPTION;
                    tempDataObj.value1 = data.jsonObject[i].DIV_AND_INC_A;
                    tempDataObj.percent = data.jsonObject[i].PERCENT_B;
                    tempDataObj.value2 = data.jsonObject[i].DED_A_TIMES_B;
                    vm.scheduleAFormView.rows.push(tempDataObj);
                    vm.showFormLoader = false;
                };             
            });
        };
        var main_indx = 0;
        vm.getAccts = function(type, state, is_pt, acct, index, line_no){
            vm.no_main_sub_data = false;
            vm.no_total_lines = false;
            currType = type;
            if(is_pt == 1){
                main_indx = "init";
                main_sub_indx = "init";
                acct_indx = "init";
            }

            if(main_indx === "init" && type === "main"){
                main_indx = index;
            }else if (main_sub_indx === "init" && type === "main_sub"){
                main_sub_indx = index;
            }else if(acct_indx === "init" && type === "accounts"){
                acct_indx = index;
            }
            
            if(currType === 'main'){
                if(!!vm.formData[index].main_reload){
                    vm.formData[index].toggleTotal = !state;
                    vm.formData[index].main_loader = true;
                    vm.formData[index].main_reload = 0;  
                    if(vm.formData.length >1 && main_indx !== index){
                        vm.formData[main_indx].toggleTotal = false;
                        vm.formData[main_indx].main_reload = 1;
                        vm.totalLines = [];
                        vm.main_sub_data = [];
                        vm.acct_data = [];
                        main_indx = index;
                    }
                }else{
                    vm.formData[index].toggleTotal = !state;
                    return;
                }
            }else if(currType === 'main_sub'){
                if(!!vm.totalLines[index].main_sub_reload){
                    vm.totalLines[index].toggle = !state;
                    vm.totalLines[index].main_sub_loader = true;
                    vm.totalLines[index].main_sub_reload = 0;
                    if(vm.totalLines.length >1 &&  main_sub_indx !== index){
                        vm.totalLines[main_sub_indx].toggle = false;
                        vm.totalLines[main_sub_indx].main_sub_reload = 1;
                        vm.main_sub_data = [];
                        vm.acct_data = [];
                        main_sub_indx = index;
                    }
                }else{
                    vm.totalLines[index].toggle = !state;
                    return;
                }
            }else if(currType === 'accounts'){
                if(!!vm.main_sub_data[index].child_reload){
                    vm.main_sub_data[index].toggle = !state;
                    vm.main_sub_data[index].child_loader = true;
                    vm.main_sub_data[index].child_reload = 0;
                    if(vm.main_sub_data.length >1 &&  acct_indx !== index){
                        vm.main_sub_data[acct_indx].toggle = false;
                        vm.main_sub_data[acct_indx].child_reload = 1;
                        vm.acct_data = [];
                        acct_indx = index;
                    }
                }else{
                    vm.main_sub_data[index].toggle = !state;
                    return;
                }
            } else if (currType === 'main_acct'){
                if(!!vm.accountData[index].main_reload){
                    vm.accountData[index].toggle = !state;
                    vm.accountData[index].main_loader = true;
                    vm.accountData[index].main_reload = 0;
                }else{
                    vm.accountData[index].toggle = !state;
                    return;
                }
            }
            var params = { "action_id": 30098 , "tax_year": rowData.TAX_YEAR, "screen_key": screen_key, "acct_key": acct, "combination_key": rowData.COMBINATION_KEY, "is_parent": is_pt, "line_no":line_no};
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if(currType == "parent" && data.jsonObject.length > 1){
                    if(data.jsonObject[1].TYPE == "account"){
                        vm.showDiagLoader = false;
                        vm.formData.push(data.jsonObject[0]);
                        vm.formData[0].toggleTotal = false;
                        vm.formData[0].main_reload = 1;
                        vm.accountData.push(data.jsonObject[1]);
                        vm.accountData[0].toggle = false;
                        vm.accountData[0].main_reload = 1;
                        vm.accountData[0].main_loader = false;
                    }else{
                        vm.createMapping(currType, index, data.jsonObject);
                    }
                }else{
                    vm.createMapping(currType, index, data.jsonObject);
                }
            });
        };

        vm.createMapping = function(currType, index, data){
            if(currType === 'parent'){
                vm.formData = data;
                angular.forEach(vm.formData, function(value, key){
                    vm.formData[key].toggleTotal = false;
                    vm.formData[key].main_reload = 1;
                    vm.formData[key].main_loader = false;
                });
                vm.showDiagLoader = false;
            }else if(currType === "main"){
                if(data.length > 0){
                    vm.formData[index].main_loader = false;
                    vm.totalLines = data;
                    angular.forEach(vm.totalLines, function(value, key){
                        vm.totalLines[key].toggle = false;
                        vm.totalLines[key].main_sub_reload = 1;
                        vm.totalLines[key].main_sub_loader = false;
                    });
                    if(vm.totalLines[0].TYPE === "sys-form"){
                        vm.show_toggle_total = false;
                    }
                }else {
                    vm.formData[index].main_loader = false;
                    vm.no_total_lines = true;

                }
            }else if (currType === "main_sub"){                
                if(data.length > 0){
                    vm.main_sub_data = data;
                    angular.forEach(vm.main_sub_data, function(value, key){
                        vm.main_sub_data[key].toggle = false;
                        vm.main_sub_data[key].child_reload = 1;
                        vm.main_sub_data[key].child_loader = false;
                    });
                    if(vm.main_sub_data[0].TYPE === "sys-form"){
                        vm.show_toggle = false;
                    }
                    vm.totalLines[index].main_sub_loader = false;
                }else {
                    vm.no_main_sub_data = true;
                    vm.totalLines[index].main_sub_loader = false;
                }               
            }else if (currType === "main_acct"){
                if(data.length > 0){
                    vm.acctChild = data;
                    vm.accountData[0].main_loader = false;
                }else{
                    vm.accountData[0].main_loader = false;
                    vm.no_acct_child = true;
                }
            }else {
                vm.acct_data = data;
                vm.main_sub_data[index].child_loader = false;
            }
        };

        fetchSchdFormView();
        vm.getAccts("parent", "", 1, "", "", rowData.LINE_NO);

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
});