
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.updateChangeListService',[])

    .factory('updateChangeListServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
            var updateChangeListServiceFactory = {};
            updateChangeListServiceFactory.url = {};
    
            updateChangeListServiceFactory.updateGeneratedOn = function(clob_settings, clob_data){
            	
            	updateChangeListServiceFactory.url.updateURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=jvs67d";

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(clob_data);
            var jsonSettings =  JSON.stringify(clob_settings);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "Update Generated On";
            console.log(params);
            var promise = $http({
                method: "post",
                url: updateChangeListServiceFactory.url.updateURL,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        return updateChangeListServiceFactory
    }])

    return services;

});