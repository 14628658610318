define(
		[ 'angular'
			
		],
		function() {
			'use strict';

			var controllers = angular.module('app.AssetsRolloverCtrl', [])
					.controller('assetsRolloverCtrl', ['$scope','$rootScope','$state', '$http', 'ModalFactory','GridFactory', 'JCDFactory', 'AlertService', 'GlobalService','USER_SETTINGS',
						'initiateApprFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG','$filter','$timeout', assetsRolloverCtrl])
					
			function assetsRolloverCtrl($scope, $rootScope, $state, $http, ModalFactory,GridFactory, JCDFactory, AlertService, GlobalService, USER_SETTINGS, 
					initiateApprFactory,JsonObjectFactory, workspaceFactory, GENERAL_CONFIG, $filter,$timeout) {
				
				var vm = this;	
				//vm.selectedTaxYear = GlobalService.globalParams.tax_year; 	
				//Tax Year Dropdown
				vm.filteredTaxYearData = [];				
				//Set the Loggin USER settings details
				vm.logged_in_user = USER_SETTINGS.user.sso_id;
				//Flag to show Spinner
				vm.loading = true;
				//Flag to show the parent me
				vm.loadParentMEDropdown = false;
				
				//Redirect the URL to a sourcing screen after closing the Modal screen 
				vm.redirectUrl = function(selectedScreenLink){							
					$state.transitionTo(selectedScreenLink, null, {'reload':true});					
				}
				
				//Get the Parent ME Drop-Down
				vm.getParentMeDropdown = function() {	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select the Tax Year to run the Apportionment.",4000);
						return;
					}
					vm.loadParentMEDropdown = true;
					vm.parentMEDropDown = [];
					vm.selectedParentMes = '';
					
					//var params = {"action_code" : 'vxg30f', "sso_id" : vm.logged_in_user, "tax_year" : vm.selectedTaxYear };
					var params = {"action_code" : '07roqs', "sso_id" : vm.logged_in_user, "p_tax_year" : vm.selectedTaxYear, "p_scenario" : vm.selectedScenario };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.parentMEDropDown =  $filter('filter')(data.jsonObject, function(data) {
			                       					return (data.LEVEL_GROUP === 'Parent ME');
			                   						});	
							vm.loading = false;
					});
				}
				//vm.getParentMeDropdown();	
				
				//Get the Tax Year Dropdown
				vm.getTaxYearDropDown = function() {	
					vm.selectedTaxYear = '';
					
					var params = {"action_code" : 'e83e1x',};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {						
						vm.taxYearData = _.filter(data.jsonObject, function(p){
	                		  return _.includes(['TAX_YEAR'], p.CODE_GROUP);  
	                	});							
						for(var indx in vm.taxYearData){
							if(vm.taxYearData[indx].CODE_GROUP == 'TAX_YEAR' 
									&& (vm.taxYearData[indx].CODE_DISP_DESC <= (GlobalService.globalParams.tax_year) && vm.taxYearData[indx].CODE_DISP_DESC >= (GlobalService.globalParams.tax_year-5))){
								vm.filteredTaxYearData.push(vm.taxYearData[indx]);							
							}
						}						
					});					
				};
				vm.getTaxYearDropDown();
				
				//Get the Scenario Dropdown
				vm.getScenarioDropDown = function() {	
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null){
						AlertService.add("error", "Please select the Tax Year to get the Filing Group.",4000);
						return;
					}
			
					var params = {"action_code" : 'va45va',"p_tax_year" : vm.selectedTaxYear};					
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						vm.scenarioDropDown =  data.jsonObject;							
						vm.scenarioRAF = _.filter(vm.scenarioDropDown, function(p){
	                		  return _.includes(['Return As Filed'], p.LABEL);  
						});	
						vm.selectedScenario = (vm.scenarioRAF!=undefined && vm.scenarioRAF.length>0) ? vm.scenarioRAF[0].VALUE : undefined;	
						vm.getParentMeDropdown();
					});					
				};
				
				$scope.$watch(function(){ return workspaceFactory.screens}, function(val){
		            vm.workspaces = workspaceFactory.getScreen();
		            $timeout(function() {
		                if(vm.scrlTabsApi && vm.scrlTabsApi.init) {
		                    vm.scrlTabsApi.init();
		                }
		            });
		        });
			
			  vm.tabs = [];
		        vm.updateFn = function(arg){
		            var obj = [];
		            obj = vm.workspaces.find(link => link.label === arg.tabScrollTitle);
		            vm.setTab(obj);
		        }
		        
				///vm.getScenarioDropDown();
				
				/*
				$scope.$watch(function() { return JCDFactory.JCDList },   function() {
					if(JCDFactory.JCDList!=undefined && JCDFactory.JCDList!=null && JCDFactory.JCDList.length > 0){											
						var b = _.find(JCDFactory.JCDList, function(o) { return o.ACTIVITY_NAME.includes(workspaceFactory.activity.label); });							
						//Get the JCD Core details
						vm.jcd_key = b.JCD_KEY;
						vm.jcd_ta_key = b.JCD_TA_KEY;
					}
				});*/
				
				//Execute/Run the Component Defaults Rollover process
				vm.runAssetsRolloverProcess = function(){
					
					if(vm.selectedTaxYear == undefined || vm.selectedTaxYear == null || vm.selectedTaxYear == ''){
						AlertService.add("error", "Please select the Tax Year to run the Apportionment.",4000);
						return;
					}
					
					if(vm.selectedParentMes == undefined || vm.selectedParentMes == null || vm.selectedParentMes == ""
							//|| vm.selectedParentMes.length == 0
					){
						AlertService.add("error", "Please select the ME(s) to run the rollover process.",4000);
						return;
					}	
					
					//Send the input params 
					var rolloverObj = {};
					rolloverObj['sso_id'] = vm.logged_in_user;
					rolloverObj["p_tax_year"] = vm.selectedTaxYear;	
					rolloverObj["p_scenario"] = vm.selectedScenario;
					//rolloverObj["p_me_key"] = _.map(vm.selectedParentMes, 'PARENT_ME_KEY').toString();
					rolloverObj["p_me_key"] = vm.selectedParentMes;
					rolloverObj["jcd_key"] = GlobalService.globalParams.jcd_key; //vm.jcd_key;
															
					//Clob Details
					var clobDataDetails = [];
					clobDataDetails.push(rolloverObj);					
					//Send details
					sendRolloverDetails(clobDataDetails);
				}
				
				//Send Details to the Service Layer for running the process
				function sendRolloverDetails(clobDataDetails) {		
					var message = "For the selection made, the process to run the rollover is triggered.";
					
					initiateApprFactory.executeAssetsRollover(clobDataDetails).then(function(result) {
						console.log("  result  :", result );
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							// vm.isSaveClicked = false;
							return;
						} else {
							AlertService.add("success", message, 4000);								
	                    }
					});
				}
				
				//To Reset the selected values
				vm.reset = function(){
					vm.selectedTaxYear = '';
					vm.loadParentMEDropdown = false;
					vm.parentMEDropDown = [];
					vm.selectedParentMes = '';						
				}
			     			
			}
			return controllers;
		});