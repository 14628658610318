define(
    ['angular'],
    function(){
        'use strict';
        let services =  angular.module('app.allocBatchRerunService',[])
            .factory('allocBatchRerunServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService', '$injector','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, $injector, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){

                let allocBatchRerunServiceFactory = {};
                allocBatchRerunServiceFactory.url = {};

                allocBatchRerunServiceFactory.reRunAllocation = function(settings,data) {

                    allocBatchRerunServiceFactory.url.rerun = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=dc90p3";

                    let service      = $injector.get('JsonObjectFactory');
                    let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    let jsonSettings = JSON.stringify(settings);
                    let jsonObj      = JSON.stringify(data);

                    let params          = _.extend({clob_settings:jsonSettings}, params);
                    params              =  _.extend({clob_data:jsonObj}, params);
                    params.tax_year     = filterParams.tax_year;
                    params.scenario     = filterParams.scenario;
                    params.jcd_key      = GlobalService.globalParams.jcd_key;
                    params.sso_id       = settings.sso_id;
                    params.process_name =  "Partnership Re-allocation Submission";

                    let promise = service.saveJSON(allocBatchRerunServiceFactory.url.rerun,params).then(
                        function (response) {
                            if(response.callSuccess === "1"){
                                return response;
                            }
                            else {
                                return response
                            }
                        },
                        function(response){
                            return response;
                        }
                    );
                    return promise;
                };
                return allocBatchRerunServiceFactory;
            }])
        return services;
    }
);