require(['angular'], function () {

    var ngModule = angular.module('slimScroll', []);
    ngModule.value("slimScrollConfig", {});

    angular.module('app.userAdminDirectives',[])


        .directive( 'gtClickSelect', ['$compile','$parse','$state','AlertService', function( $compile,$parse,$state,AlertService) {
            return {
                restrict: 'A',
                require: '^gtUserAssignMenu',
                scope:{
                    menuData: '='
                },
                link:function ( scope, element, attrs, ctrl ) {
                }
            };
        }])



        .directive( 'gtUserAssignTree', ['$injector','$compile','$parse','$state','AlertService','UserAdminFactory', '$uibModal', 'SERVER_CONFIG', function( $injector,$compile,$parse,$state,AlertService,UserAdminFactory, $uibModal, SERVER_CONFIG) {
            return {
                restrict: 'A',
                require: '^gtUserAssignMenu',
                scope:{
                    level: '=',
                    menuData: '=',
                    actionType: '=',
                    rowPrimaryKey: '=',
                    btnDisabled: '=',
                    clientKey: '=',
                    userId: '=',
                    enableModuleAssign: '=',
                    serverConfigFlag: '=',
                },

                link:function ( scope, element, attrs, ctrl ) {
                    var treeId = attrs.treeId;
                    var nodeLabel = attrs.nodeLabel || 'name';
                    var nodeChildren = attrs.nodeChildren || 'children';

                    scope.nextLevel = parseInt(scope.level, 10)+1;
                    scope.menuClick = function(node){
                        ctrl.toggleFolder(node);
                    };

                    scope.changeGroupRole = function(_roleHigh, _group) {
                        var updated = [];
                        function getUpdated(roleHigh, group) {
                            if (angular.isArray(group.children)) {
                                group.children.forEach(function(item) {
                                    getUpdated(roleHigh, item);
                                });
                            }
                            if (angular.isArray(group.roles)) {
                                if (roleHigh === '1') {
                                    group.role = _.find(group.roles, { level: Math.max.apply(Math, _.map(group.roles, 'level')).toString()});

                                    updated.push(group);
                                } else if (roleHigh === '-1') {
                                    var role = _.find(group.roles, { level: Math.min.apply(Math, _.filter(_.map(group.roles, 'level'), (level) => level!== '0')).toString()});
                                    if (role) {
                                        group.role = role;
                                        updated.push(group);
                                    }
                                } else {
                                    group.role = _.find(group.roles, { level: '0'});
                                    updated.push(group);
                                }
                            }
                        }
                        getUpdated(_roleHigh, _group);
                        scope.setAccess(updated);
                    };

                    scope.setAccess = function(nodes) {
                        var dynamicService = $injector.get(scope.actionType);
                        var params = nodes.map(function(node) {
                            return {
                                jcd_ta_key: node.jcd_ta_key,
                                jcd_ta_role_key: node.role.value,
                                user_id: scope.userId,
                                client_key: scope.clientKey
                            };
                        })
                        // if(scope.enableModuleAssign){
                        //     if(SERVER_CONFIG.configSettings.enable_module_assign){
                        //         if(SERVER_CONFIG.configSettings.enable_module_assign == "true"){
                        //             dynamicService.setAccessAll(JSON.stringify(params), scope.userId, scope.rowPrimaryKey, scope.clientKey);
                        //         }else{
                        //             AlertService.add("error", "Please go to Template Assign for module access.", 4000);
                        //         }
                        //     }else{
                        //         dynamicService.setAccessAll(JSON.stringify(params), scope.userId, scope.rowPrimaryKey, scope.clientKey);
                        //     }
                        // }else{
                        //     dynamicService.setAccessAll(JSON.stringify(params), scope.userId, scope.rowPrimaryKey, scope.clientKey);
                        // }
                        dynamicService.setAccessAll(JSON.stringify(params), scope.userId, scope.rowPrimaryKey, scope.clientKey);
                    };

                    scope.getClass= function(value){
                        if(value > 0){
                            return "btn-success";
                        } else{
                            return "btn-default";
                        }
                    }
                    
                    scope.openUserAccessModal = function(id) {
                        var modalInstance = $uibModal.open({
                            templateUrl: 'app/components/admin/users/user-assign-tpl.html',
                            controller: 'userAssignCtrl as ctrl',
                            size: 'md',
                            backdrop:"static",
                            resolve: {
                                rowData: {
                                    "taskKey": id
                                }
                            },
                        });
                        return modalInstance;
                    }

                    scope.getSelectedCount = function(node, total) {
                        if(!node || !node.children) {
                            return 0;
                        }
                        var count = 0;
                        var countMethod = function(node) {
                            _.forEach(node.children, function(child) {
                                // gets the count of selected value from the dropdown..
                                if(!total) {
                                    if(child && child.role && child.role.value > 0) {
                                        ++count;
                                    }
                                // gets all activity count value..
                                } else {
                                    if(child && child.role) {
                                        ++count
                                    }
                                }

                             if(child && child.children) {
                                countMethod(child);
                             }
                            });
                        }
                        countMethod(node);
                        return count;
                    }

                    var template = '' +
                        '<ul>' +
                        '   <li data-ng-repeat="node in menuData" class="card" data-ng-hide="node.hidden">' +
                        '       <div class="activity-num">{{getSelectedCount(node, false)}}/{{getSelectedCount(node, true)}}</div>' +
                        '       <div class="card-header" data-ng-class="{\'selected\': node.selected, \'screen screen-space\': !node.children.length}">' +
                        '           <b class="ellip" style="width: calc(100% - 210px);">{{node.'+nodeLabel+'}}</b>' +
                        '           <span style="float: right"><i class="fa pls-icon" ng-click="menuClick(node);" data-ng-class="{\'fa-plus-square-o\': !node.collapsed, \'fa-minus-square-o\': node.collapsed}"></i></span>' +
                        '           <i class="fa fa-user-circle-o user" uib-popover-template="\'userPopover.html\'" popover-class="user-popOver" popover-placement="right" popover-append-to-body="true" popover-trigger="\'mouseenter\'"></i>'+
                        '           <span style="float: right" ng-show="btnDisabled !== \'Y\'">' +
                        '               <b>Assign: </b>' +
                        '               <select class="assign-dropdown" ng-disabled="serverConfigFlag == \'false\'" ng-change="changeGroupRole(dropdown, node)" ng-model="dropdown">' +
                        '                   <option value="" disabled selected>select Level</option>'+
                        '                   <option value="0">No Access</option>' +
                        '                   <option value="-1">Lowest Level</option>' +
                        '                   <option value="1">Highest Level</option>' +
                        '               </select>' +
                        '           </span>' +
                        '       </div>' +
                        '       <div class="card-body" data-ng-show="node.collapsed">' +
                        '           <div class="channel">{{node.'+nodeLabel+'}}</div>' +
                        '           <div class="user-assign-btn-group">' +
                        '               <button class="btn user-btn" ng-click="openUserAccessModal(node.id)" ng-disabled="btnDisabled !== \'N\'"><i class="fa fa-user-circle user-icn"></i>Assign User</button>' +
                        '           </div>' +
                        '           <ul class="tree-structure" role-change="setAccess([$node])" tree-structure data="node" btn-disabled="btnDisabled" server-config-flag="serverConfigFlag" node-label="' + nodeLabel + '">' +
                        '           </ul>' +
                        '       </div>' +
                        '   </li>' +
                        '</ul>'+
                        '<script type="text/ng-template" id="userPopover.html">'+
                        '<div class="user-popOver">'+
                        '   <ul ng-repeat= "user in node.ownerList" ng-if="node.ownerList.length">'+
                        '       <li>{{user.name}}</li>'+
                        // '       <li>{{user.value}}</li>'+
                        '   </ul>'+
                        '   <ul ng-if="!node.ownerList.length">'+
                                '<li>No Users Assigned</li>'+
                        '   </ul>'+
                        '</div>'+
                        '<script>';

                    element.html('').append( $compile( template )( scope ) );
                }
            };
        }])

        .directive('treeStructure', ['$compile', 'SERVER_CONFIG', function($compile, SERVER_CONFIG) {
            return {
                restrict: 'EA',
                scope: {
                    data: '=',
                    btnDisabled: '=',
                    roleChange: '&',
                    serverConfigFlag: '=',
                },
                controller: ['$scope', '$injector', function($scope, $injector) {
                    $scope.getClass= function(value){
                        if(value > 0){
                            return "btn-success";
                        } else{
                            return "btn-default";
                        }
                    };
                    $scope.setAccess = function(node, role) {
                        node.role = role;
                        $scope.roleChange({ $node: node });
                    };
                }],
                link:function ( scope, element, attrs ) {


                    var nodeLabel = attrs.nodeLabel || 'name';
                    var btnDisabled = scope.btnDisabled === 'Y' ? ' disabled': '';
                    var template = '' +
                        '<li ng-repeat="ch in data.children" ng-class="{\'has-children\': ch.children.length}">' +
                        '   <div class="clearfix">' +
                        '       <span class="task">{{ch.' + nodeLabel + '}}</span>' +
                        '        <div class="pull-right" ng-if="ch.roles"  uib-dropdown > '+
                        '            <button type="button" class="btn btn-xs'+ btnDisabled +'" ng-disabled="serverConfigFlag == \'false\'" ng-class="getClass(ch.role.value)"  uib-dropdown-toggle  auto-close="true"> '+
                        '                <span class="btn-text">{{ ch.role.label || ch.roles[0].label}}</span>' +
                        '                <span class="fa fa-caret-down caret-icon"></span> ' +
                        '            </button> ' +
                        '           <ul class="dropdown-menu" role="menu" aria-labelledby="split-button"> '+
                        '                <li>' +
                        '                    <a href="" ng-repeat="role in ch.roles" ng-click="setAccess(ch,role)">{{ role.label }}</a>' +
                        '                </li> '+
                        '           </ul> '+
                        '        </div> '+
                        '   </div>' +
                        '   <ul class="tree-structure" ng-if="ch.children" btn-disabled="btnDisabled" role-change="setAccess($node, $node.role)" tree-structure data="ch" node-label="' + nodeLabel + '">' +
                        '   </ul>' +
                        '</li>';
                        element.html('').append( $compile( template )( scope ) );
                }
            };
        }])


         .directive( 'gtUserAssignMenu', ['$compile','$parse','$state', function( $compile,$parse,$state ) {
            return {
                restrict: 'A',
                scope:{
                    menuData: '=',
                    userId: '=',
                    loading: '=',
                    actionType: '=',
                    rowPrimaryKey: '=',
                    btnDisabled: '=',
                    clientKey: '=',
                    enableModuleAssign: '=',
                    serverConfigFlag: '=',
                },
                template:
                //  '<div class="gt-header" ng-show="!loading">' +
                //     '<div class="pull-right">' +
                //         '<i ng-click="toggleAll(true)"  class="fa fa-folder"></i>' +
                //         '<i ng-click="toggleAll(false)" class="fa fa-folder-open "></i>' +
                //     '</div>' +
                // '</div>' +
                '<div modal-loader loading="loading"></div>' +
                '<div ng-if="menuData == null"><div><pre style="text-align: "><h3>Sorry there are currently no modules for this client</h3></pre> </div></div>' +
                '<div gt-user-assign-tree level="0" menu-data="menuData" user-id="userId" row-primary-key="rowPrimaryKey" action-type="actionType" btn-disabled="btnDisabled" client-key="clientKey" enable-module-assign="enableModuleAssign" server-config-flag="serverConfigFlag"></div>',
                controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

                    var setTreeAttribute = function(_collection,_predicate,_children,value){
                        for (var i = 0; i < _collection.length; i++) {
                            var  n = _collection[i][_predicate];
                            var children = _collection[i][_children];
                            if(children && children.length ){
                                setTreeAttribute(children,_predicate,_children,value );
                            }
                            _collection[i][_predicate] = value;
                        }
                    };

                    $scope.toggleAll = function(val){
                        setTreeAttribute($scope.menuData,'collapsed','children',val);
                    };

                    this.toggleFolder = function(node){
                        node.collapsed = !node.collapsed;
                    };

                }],
                link: function ( scope, element, attrs ) {
                }
            };
        }])





        .directive( 'gtBusSearch', ['$compile','$parse','$state', function( $compile,$parse,$state ) {
            return {
                restrict: 'A',
                template: '<div class="search-input"><i class="fa fa-search search"></i><input placeholder="filter businesses" ng-modal="filterData" ng-keyup="filterMenu()" type="text" ><i class="fa fa-times close-x" data-ng-click="resetFilter()"> </i></div>',
                replace: true,
                scope: {
                    menuData: '=',
                },
                require: '^gtBusinessAssignMenu',
                link: function ( scope, element, attrs,ctrl ) {
                    scope.filterData = '';
                    scope.filterMenu = function(){
                        var searchString = element.find("input").val();
                        ctrl.setTreeAttribute(scope.menuData,'collapsed','children',false);
                        ctrl.setTreeAttribute(scope.menuData,'hidden','children',false);
                        ctrl.filterTree(scope.menuData, searchString, 'name', 'children');

                        if( searchString === "" && ctrl.collapsed){
                            ctrl.setTreeAttribute(scope.menuData,'collapsed','children',true);
                        }


                    }
                    scope.resetFilter = function(){
                        element.find("input").val("");
                        scope.filterMenu();

                    };
                }
            };
        }])



        .directive( 'gtBusinessAssignMenu', ['$compile','$parse','$state','$window','$injector','AlertService', function( $compile,$parse,$state,$window,$injector,AlertService) {
            return {
                restrict: 'A',
                scope:{
                    menuData: '=',
                    userId: '=',
                    loading: '=',
                    renderingMode: '=',
                    taxYear: '=',
                    reportingMonth: '=',
                    clientKey: '=',
                    groupObjKey: '=',
                    callBackService: '='
                },
                template: '<div class="gt-dark-header" id="gt-bus-assign"  ng-show="!loading">' +
                '<div class="pull-left"><div gt-bus-search menu-data="menuData" ></div></div>' +
                '<div class="pull-right">' +
                '<div  ng-if="renderingMode===0" ng-click="assignAll()" class="btn btn-default btn-xs" style="margin-right:10px"> Assign All</div>' +
                '<div  ng-if="renderingMode===0" ng-click="unAssignAll()"  class="btn btn-default btn-xs" style="margin-right:30px"> Unassign All</div>' +
                '<div ng-if="renderingMode===2" ng-click="finalizeAsIs()" class="btn btn-default btn-xs" style="margin-right:10px">Finalize</div>' +
                '<div ng-if="renderingMode===2" ng-click="override()" ng-disabled="true" class="btn btn-default btn-xs" style="margin-right:10px">Override</div>' +
                '<div ng-if="renderingMode===2" ng-click="discard()"  ng-disabled="true" class="btn btn-default btn-xs" style="margin-right:10px">Discard</div>' +
                '<i ng-click="toggleAll(true)"  class="fa fa-folder"> {{ collapsed }} </i>' +
                '<i ng-click="toggleAll(false)" class="fa fa-folder-open "></i>' +
                '</div>' +
                '</div>' +
                '<div modal-loader loading="loading">' +
                '<div style="padding-right:10px" slim-scroll="{height: 400, color: \'#5cd6fb\', railColor:\'#000000\', railVisible: true, railOpacity:100, distance: \'2px\', alwaysVisible: true  }">' +
                '<div gt-business-assign-tree=""  menu-data="menuData" ></div>' +
                '</div>' +
                '</div>',
                controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

                    var that  = this;
                    this.collapsed = true;
                    $scope.callBackService    = $attrs.callBackService;
                    var proxyService         = $injector.get($scope.callBackService);
                    console.log("proxyService ::",proxyService);

                    this.setTreeAttribute = function(_collection,_predicate,_children,value){
                        for (var i = 0; i < _collection.length; i++) {
                            var  n = _collection[i][_predicate];
                            var children = _collection[i][_children];
                            if(children && children.length ){
                                this.setTreeAttribute(children,_predicate,_children,value )
                            }
                            _collection[i][_predicate] = value;
                        }
                    }

                    $scope.getScrollHeight = function(){
                        var top = angular.element('#gt-bus-assign')[0].getBoundingClientRect().top;
                        return  $window.innerHeight - ( top + 40 );
                    }



                    function setTreeParent(_collection,_children){
                        for (var i = 0; i < _collection.length; i++) {
                            var thisChildren =  _collection[i][_children];
                            if( thisChildren && thisChildren.length ){
                                setTreeParent(thisChildren,_children);
                            }
                            for(var n in thisChildren){
                                thisChildren[n].parent = _collection[i];
                            }
                            //SET SCREEN PARENT
                            if(  _collection[i].type === 'activity' && _collection[i].screens.length  ){
                                var screens =  _collection[i].screens;
                                for(var n in screens){
                                    screens[n].parent = _collection[i];
                                }
                            }
                        }
                    }


                    $scope.toggleAll = function(val){
                        that.setTreeAttribute($scope.menuData,'collapsed','children',val);
                        that.collapsed = val;
                    };

                    this.toggleFolder = function(node){
                        node.collapsed = !node.collapsed;
                    };


                    $scope.assignAll = function(){
                        that.setTreeAttribute($scope.menuData,'selected','children',true);
                        that.countActiveNodes();
                    };

                    $scope.finalizeAsIs = function(){
                        console.log("Finalizing it now... wait");
                        var data = {};
                        data.sso_id=$scope.userId;
                        data.taxyear=$scope.taxYear;
                        data.rpt_mth=$scope.reportingMonth;
                        data.client_key=$scope.clientKey;
                        data.grp_obj_key=$scope.groupObjKey;
                        console.log("finalizeAsIs().data ::",data);
                        $scope.loading = true;
                        proxyService.finalizeStagedBizHierarchy(data).then(function (data) {
                  		    $scope.loading = false;
                  		    var callStatus = parseInt(data.callSuccess);
                            if(callStatus === 1 ){
                                console.log("data", data);
                                AlertService.add("success", "Business Hierarchy Staged Data Applied Successfully to Tax Year:"+$scope.taxYear+" Group Obj Key: "+$scope.groupObjKey, 4000);
                            }else if(callStatus < 0){
                            	console.log("data", data);
                                AlertService.add("error", "Failed Business Hierarchy Finalization at STEP:"+callStatus+"for Tax Year:"+$scope.taxYear+" Group Obj Key: "+$scope.groupObjKey, 4000);
                            }
                            else {
                                if(data.errorMessage === "no access"){
                                    AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                                }else{
                                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                                }
                            }
                        });

                    };

                    $scope.override = function(){
                    	console.log("Not enabled yet...");
                    	alert("This functionality is not available yet..");
                    };

                    $scope.discard = function(){
                        console.log("Discarding all....");
                    };

                    $scope.unAssignAll  = function(){
                        that.setTreeAttribute($scope.menuData,'selected','children',false);
                        that.countActiveNodes();
                    };



                    this.selectAll = function(node,value){
                        this.setTreeAttribute($scope.menuData,'selected','children',value);
                    };

                    this.selectBranch = function(node,value){
                        this.setTreeAttribute(node.children,'selected','children',value);
                    };


                   function CountNodes(node){
                       var selectedNum = 0;
                        function countChildren(node){
                            var nodeCount = 0;
                            _.forEach(node.children, function(value, key) {
                                if(value.children){
                                    nodeCount += countChildren(value);
                                }else if( value.selected ){
                                    ++selectedNum
                                    ++nodeCount;
                                }
                            });

                            node.numSelected = nodeCount;
                            return nodeCount;
                        }
                       countChildren(node);
                       return selectedNum;
                    }



                    this.countActiveNodes = function(){
                        _.forEach($scope.menuData, function(value, key) {
                            value.numSelected = CountNodes(value)
                        })
                    }


                    this.parentAllCheck = function(node){
                        var allChecked = true;
                        var parent = node.parent;
                        if(parent && parent.children){
                            _.forEach(node.parent.children, function(value, key) {
                                if(!value.selected) {
                                    allChecked = false;
                                }

                            });
                            parent.selected = (allChecked) ? true : false;
                            //IF PARENT HAS A PARENTS CHECK NODE ALSO
                            if(parent.parent ){
                                this.parentAllCheck(parent);
                            }
                        }
                    };


                    this.filterTree = function(collection, value, predicate, children) {
                        for (var i = 0; i < collection.length; i++) {
                            var  n = collection[i][predicate];
                            var checkChildren = true;
                            if(n){
                                if(n && _.includes(n.toLowerCase(), value.toLowerCase()) ){
                                    collection[i].hidden=false;
                                    checkChildren = false;
                                }else{
                                    collection[i].hidden=true;
                                }
                                if (checkChildren && collection[i][children] && collection[i][children].length  ) {
                                    this.filterTree(collection[i][children], value, predicate, children )
                                    var parentHidden = true;
                                    var innerChildren = collection[i][children];
                                    if(innerChildren.length ){
                                        for (var p = 0; p < innerChildren.length; p++) {
                                            if( !innerChildren[p].hidden ){
                                                parentHidden = false;
                                            }
                                        }
                                    }

                                    if( checkChildren  ){
                                        collection[i].hidden = parentHidden;
                                    }
                                }
                            } //end  if( n  )
                        }//end for
                    };//end filterTree


                    var removeTreeAttribute = function(_collection,_predicate,_children){
                        for(var i = 0; i < _collection.length; i++) {
                            var  n = _collection[i][_predicate];
                            var children = _collection[i][_children];
                            if(children && children.length ){
                                removeTreeAttribute(children,_predicate,_children )
                            }
                            if( n && n ===  _predicate){
                                _collection[i][_predicate] = undefined;
                            }
                        }
                    }



                    function checkAllParents(){
                        _.forEach($scope.menuData, function(value, key) {
                            that.topAllCheck(value);
                        });

                    }


                    this.topAllCheck = function(node){
                        var allChecked = true;

                        if( node.children){

                            _.forEach(node.children, function(value, key) {

                                if(node.children ){
                                    that.topAllCheck(value);
                                }
                                if(!value.selected) {
                                    allChecked = false;
                                }
                            });
                            node.selected = (allChecked) ? true : false;
                            //IF PARENT HAS A PARENTS CHECK NODE ALSO
                        }


                    };








                    //THIS SET THE MENU TO COLLAPSED ON LOAD
                    $scope.$watch(
                        "menuData",
                        function( newValue, oldValue ) {
                            if(newValue){
                                setTreeParent($scope.menuData,'children' );
                                that.setTreeAttribute($scope.menuData,'collapsed','children',true);
                                checkAllParents();
                                that.countActiveNodes();
                            }
                        }
                    );




                    this.userId = $scope.userId;
                }],
                link: function ( scope, element, attrs ) {

                }
            };
        }])


    .directive( 'gtBusinessAssignTree', ['$compile','$parse','$state','AlertService','UserAdminFactory', function( $compile,$parse,$state,AlertService,UserAdminFactory) {
        return {
            restrict: 'A',
            require: '^gtBusinessAssignMenu',
            scope:{
                menuData: '=',
            },

            link:function ( scope, element, attrs, ctrl ) {
                var treeId = attrs.treeId;
                var nodeLabel = attrs.nodeLabel || 'name';
                var nodeChildren = attrs.nodeChildren || 'children';

                scope.menuClick = function(node){
                    if( node.type === 'activity'){
                        ctrl.toggleFolder(node);
                    }else{
                        ctrl.toggleFolder(node);
                    }
                };


                scope.updateSelected = function(node){
                    event.stopPropagation();
                    if(node.children ){
                        ctrl.selectBranch(node, (node.selected) ? true : false );
                    }
                    ctrl.parentAllCheck(node);
                    ctrl.countActiveNodes();
                }



                //TODO: have {{node.name}} - {{node.key}}  combined in one property called display-name
                var template = '' +
                    '<ul><li data-ng-repeat="node in menuData"  data-ng-hide="node.hidden" >' +
                    '<div ng-click="menuClick(node); $event.stopPropagation();" class="depth_{{node.level}}"  data-ng-class="node.children? \'level\' : \'node\'" >'  +
                    '<div class="pull-left">    <input ng-click="updateSelected(node)" type="checkbox" ng-model="node.selected" ng-disabled = "node.disableCheckbox">   <span ng-if="node.numSelected > 0" style="background-color:#f5857c " class="badge">{{ node.numSelected }}</span>    {{node.'+nodeLabel+'}} - {{node.key}}     </div>' +
                    '<div class="pull-right">' +
                        // '<div gt-click-select data-ng-show="node.type === \'activity\'"  class="badge" >None</div>' +
                    '<i data-ng-show="node.' + nodeChildren + '.length && node.collapsed" class="fa fa-folder"></i>' +
                    '<i data-ng-show="node.' + nodeChildren + '.length && !node.collapsed" class="fa fa-folder-open "></i>' +
                    '<i style="color:#1ca5cf" data-ng-show="!node.children && node.selected" class="fa fa-circle "></i>' +
                    '</div>' +
                    '</div>' +
                    '<div data-ng-hide="node.collapsed"   gt-business-assign-tree menu-data="node.children" ></div>' +
                    '</li></ul>';

                element.html('').append( $compile( template )( scope ) );
            }
        };
    }])




})
