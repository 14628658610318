define([
    'angular',
    './ProjectTagCtrl',
    './ProjectTagServce'



], function () {
    'use strict';
    return angular.module('app.projectTag', ['app.ProjectTagCtrl','app.ProjectTagServce'] );


});