define([
    'angular'

], function () {
    'use strict';

    var controllers =  angular.module('app.GoldController',[])
        .controller('GoldAddressController', ['$rootScope','$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'rowData' ,'JsonObjectFactory','$timeout','GENERAL_CONFIG', goldAddressController])

    function goldAddressController($rootScope,$scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, rowData ,JsonObjectFactory,$timeout,GENERAL_CONFIG) {

        var vm = this;
        rowData = JsonObjectFactory.objToLower(rowData);
        console.log("rowData: " , rowData);
        vm.title = "Address Change: " + rowData.legal_entity_id + " - " + rowData.legal_entity_name;
        vm.step = 'update' 
        vm.address = {};
        vm.selectedAddress = {};
        vm.irsAddress = false;
        vm.entityLinkDisabled = true;
        vm.selectedEntityId = "";
        vm.entitySet = false;
        vm.legalEntityString = rowData.legal_entity_id + " - " + rowData.legal_entity_name;
        
        var NEW_ADDRESS = 'new';
        var UPDATE_ADDRESS = 'update';
        var CHANGE_ADDRESS = 'change';
        vm.entityTypeLookUpSelected = false;
        vm.entityTypeBlankSelected = false;
        vm.entityTypeCurrentSelected = true;
        
        
        
        

        updateAddress();
       
        vm.changeStep = function(_step){
            vm.step = _step;
            if(vm.step === NEW_ADDRESS){
                newAddress();
            }else if(vm.step === UPDATE_ADDRESS){
                updateAddress();
            }else if(vm.step === CHANGE_ADDRESS){

            }
        }


        vm.states = GlobalService.getStates();
        vm.countries = GlobalService.getCountries();



        /**
         * Retrieving  GOLD Address By Address Id
         * Author: Mallesh
         * Since: 01/01/2018
         * 
         * Calls the following stored proc
         * PKG_GTW_ADDRESS_MGMT.GET_ADDRESS_USING_ID(?,?,?,?,?)
         * 1. IN - SSI_ID
         * 2. IN - ADDRESS_ID
         * 3. OUT
         * 4. OUT
         * 5. OUT
         * 
         * 
         */
        function updateAddress(){
            vm.loading = true;
            vm.address.address_id = rowData.ADDRESS_ID;
            
            var params = {
                "action_code": 'b8xqv1',   
                "address_id": rowData.address_id
            }
            console.log("Retrieving Address :",params); 	

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                	vm.address = JsonObjectFactory.objToLower(data.jsonObject[0]);
                	console.log("Retrived Address : ",vm.address);
                }else{
                	console.log("Exception while retrieving GOLD Address Data...");
                }
                vm.loading = false;
            });

            vm.loading = false;
            if(vm.address.irs_address_line1 == vm.address.irs_address_line1 ){
                vm.irsAddress = true;
            }
        }

        function newAddress(){
            vm.irsAddress = false;
            vm.address.address_id = ""
            vm.address.address_line1 = "";
            vm.address.address_line2 = "";
            vm.address.address_city = "";
            vm.address.country = "";
            vm.address.state = "";
            vm.address.address_start_date = new Date("1/1/1998");
            vm.address.address_end_date  = new Date("12/31/9999");
            vm.address.irs_address_line1 = "";
            vm.address.irs_address_line2 = "";
            vm.address.irs_address_city = "";
            vm.address.OBJECT_ID = rowData.key;
            
        }



        vm.setIrsAddress = function(){
                vm.address.irs_address_line1 = (vm.irsAddress)  ?  vm.address.address_line1 : "";
                vm.address.irs_address_line2 = (vm.irsAddress)  ?    vm.address.address_line2 : "";
                vm.address.irs_address_city = (vm.irsAddress)  ?     vm.address.address_city : "";
        }


        vm.updateAddress = function(){
            if(vm.irsAddress){
            vm.address.irs_address_line1 = vm.address.address_line1;
            vm.address.irs_address_line2 = vm.address.address_line2;
            vm.address.irs_address_city = vm.address.address_city;
            }
        }

        
        /**
         * Handles Saving modified GOLD Address [Update, Change and New GOLD Address 
         * Author: Heath/Mallesh
         * Since: 01/01/2018
         * 
         * Calls the following stored proc
         * PKG_GTW_ADDRESS_MGMT.UPDATE_EXISTING_ADDRESS(?,?,?,?);
         * PKG_GTW_ADDRESS_MGMT.UPDATE_ENTITY_NAME_ADDR_ID(?,?,?,?,?,?);
         * PKG_GTW_ADDRESS_MGMT.CREATE_NEW_ADDRESS(?,?,?,?,?);
         * 
         * Action Codes: 72ge4k, 4j7huk and fe783a
         */
        vm.save =  function(_step){
        	if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

        	vm.crudLoading = true;

        	if(_step===UPDATE_ADDRESS){
        		if (vm.addressFrom.$invalid) {
                    vm.addressFrom.$submitted = true;
                    AlertService.add("danger", "Please correct the errors below", 4000);
                    vm.crudLoading = false;
                    return;
                }
                var service = JsonObjectFactory;
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=72ge4k";
                var addressPayloadUpper = []; 
                addressPayloadUpper[0] = JsonObjectFactory.objToUpper(vm.address);
                var jsonObj = JSON.stringify(addressPayloadUpper);
                console.log("Update Address jsonObj: ",jsonObj);
                var jsonSettings = '{}';

                var params = _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                service.saveJSON(url , params).then(function(data){
                    if(data.callSuccess === "1"){
                        AlertService.add("success", "Address saved Successfully.",4000);
                        vm.crudLoading = false;
                        var args = {};
                        $rootScope.$emit('gridUpdate', args);
                        vm.cancel();
                    }else{
                        AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                        vm.crudLoading = false;
                    }
                });
        	}else if(_step===CHANGE_ADDRESS){
        		
        		var service = JsonObjectFactory;
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=4j7huk";
                var addressId = ""+vm.selectedAddress.address_string.address_id;
                var addressPayloadUpper = [{OBJECT_ID: rowData.key,ADDRESS_ID:vm.selectedAddress.address_string.address_id,LEGAL_ENTITY_NAME: rowData.legal_entity_name}];
                var jsonObj = JSON.stringify(addressPayloadUpper);
                var jsonSettings = '{}';
                console.log("Change Address jsonObj:",jsonObj);
                var params = _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                service.saveJSON(url , params).then(function(data){
                    if(data.callSuccess === "1"){
                        AlertService.add("success", "Address changed Successfully.",4000);
                        vm.crudLoading = false;
                        var args = {};
                        $rootScope.$emit('gridUpdate', args);
                        vm.cancel();
                    }else{
                        AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                        vm.crudLoading = false;
                    }
                });
        	}else if(_step===NEW_ADDRESS){
        		if (vm.addressFrom.$invalid) {
                    vm.addressFrom.$submitted = true;
                    AlertService.add("danger", "Please correct the errors below", 4000);
                    vm.crudLoading = false;
                    return;
                }
        		
        		if(vm.entityTypeLookUpSelected && vm.address.OBJECT_ID === null){
        			vm.addressFrom.$submitted = true;
                    AlertService.add("danger", "Please Lookup and Select Entity", 4000);
                    vm.crudLoading = false;
                    return;
        		}
        		
                var service = JsonObjectFactory;
                var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fe783a";
                var addressPayloadUpper = []; 
                addressPayloadUpper[0] = JsonObjectFactory.objToUpper(vm.address);
                var jsonObj = JSON.stringify(addressPayloadUpper);
                console.log("New Address jsonObj:",jsonObj);
                var jsonSettings = '{}';

                var params = _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                service.saveJSON(url , params).then(function(data){
                    if(data.callSuccess === "1"){
                        AlertService.add("success", "Address Created Successfully.",4000);
                        vm.crudLoading = false;
                        var args = {};
                        $rootScope.$emit('gridUpdate', args);
                        vm.cancel();
                    }else{
                        AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                        vm.crudLoading = false;
                    }
                });
        	}
        	
        }



        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }


        /**
         * Gold Address lookup. Selected Address will be set for changing current entity's address to selected address
         * 
         */
        vm.setAddress = function(address){
            console.log("Selected Address : " , address);
            vm.selectedAddress = address;
            vm.addressSet = true;
        }


        vm.clearSelection = function(address){
            console.log("Clearing Address : " , address)
            vm.addressSet = false;
        }
        
        /**
         * Sets Entity when user uses Entity Lookup while creating a new Address
         */
        vm.setEntity = function(address){
        	vm.entitySet = true;
        	vm.address.OBJECT_ID = vm.address.entity_string.key;
        	console.log("Linking Entity : ",vm.address);
        }
        
        
        /**
         * Clears Entity when user uses Entity Lookup while creating a new Address
         */
        vm.clearLinkedEntity = function(){
        	vm.entitySet = false;
        	vm.address.entity_string = null;
        	vm.address.OBJECT_ID = null;
        	console.log("Clearing Entity : ",vm.address);
        }

        /**
         * Sets appropriate check box for Linking Entity to a new Address
         */
        vm.setLookupEntityLinkType  = function(){
        	if(vm.entityTypeLookUpSelected){
        		vm.entityTypeBlankSelected = false;
                vm.entityTypeCurrentSelected = false;
                vm.address.OBJECT_ID = null;
                vm.entityLinkDisabled = false;
                vm.address.entity_string = "";
        	}else{
        		vm.entityTypeBlankSelected = false;
                vm.entityTypeCurrentSelected = true;
                vm.address.OBJECT_ID = rowData.key;
                vm.entityLinkDisabled = true;
                vm.address.entity_string = "";
        	}
        } 
        
        vm.setCurrentEntityLinkType  = function(){
        	if(vm.entityTypeCurrentSelected){
        		vm.entityTypeLookUpSelected = false;
                vm.entityTypeBlankSelected = false;
                vm.entityLinkDisabled = true;
                vm.address.entity_string = "";
                vm.address.OBJECT_ID = rowData.key;
        	}
        } 
        
        vm.setBlankEntityLinkType  = function(){
        	if(vm.entityTypeBlankSelected){
	        	vm.entityTypeLookUpSelected = false;
	            vm.entityTypeCurrentSelected = false;
	            vm.entityLinkDisabled = true;
	            vm.address.entity_string = "";
	            vm.address.OBJECT_ID = null;
        	}else{
        		vm.entityTypeLookUpSelected = false;
	            vm.entityTypeCurrentSelected = true;
	            vm.address.OBJECT_ID = rowData.key;
	            vm.entityLinkDisabled = true;
	            vm.address.entity_string = "";
        	}
        } 

        /**
         * GOLD Address Lookup  
         * 
         * Author: Heath/Mallesh
         * Since: 01/02/2018
         * 
         * Calls the following stored proc
         * GET_ADDRESS_ON_SEARCH(?,?,?,?);
         * 1. IN - ACTION_CODE
         * 2. IN - SEARCH_STRING [Address] 
         */
        vm.getAddress = function(val) {
        	
        	 var params = {
	             "action_code": 'o0p09p',
	             "search_string": val
             }
        	 console.log("Address Lookup: ", params);
            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

            	 if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                     AlertService.add("error", data.errorMessage);
                 } else {
                 	var goldAddressLookupResults = JsonObjectFactory.arrObjToLower(data.jsonObject);
                 	console.log(goldAddressLookupResults);
                 	if (goldAddressLookupResults) {
                         return goldAddressLookupResults;
                     }
                 }	
            });
        };
        
        /**
         * GOLD Entity Lookup  
         * 
         * Author: Heath/Mallesh
         * Since: 01/02/2018
         * 
         * Calls the following stored proc
         * GET_ENTITIES_ON_SEARCH(?,?,?,?);
         * 1. IN - ACTION_CODE
         * 2. IN - SEARCH_STRING [Entity Name]
         */
        vm.getLinkedEntity = function(val){
    		var params = {
	             "action_code": '8fr4bk',
	             "search_string": val
            }
       	 	console.log("Entity Lookup: ", params);
    		return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
           	 if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                	var goldEntityLookupResults = JsonObjectFactory.arrObjToLower(data.jsonObject);
                	console.log(goldEntityLookupResults);
                	if (goldEntityLookupResults) {
                        return goldEntityLookupResults;
                    }
                }	
           });
        };
    
    
    }
    
    
    
    

    return controllers;

});