/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.recurringEPService',[])

        .factory("RecurringEPFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','AlertService','DataFiltersFactory','workspaceFactory','JsonObjectFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,AlertService,DataFiltersFactory,workspaceFactory,JsonObjectFactory,GENERAL_CONFIG) {
            var recurringEPFactory = {};
            recurringEPFactory.url = {};
            recurringEPFactory.url.saveAdjs  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=13dgei";
            recurringEPFactory.url.deleteAdjs = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=2e9aww";

            /*var URLS = {
                FETCH: 'getReclass.ge',
                UPDATE: 'saveReclass.ge'
            }*/

            recurringEPFactory.getActiveScreen = function() {
                return workspaceFactory.activeScreen.screen_key;
            };
            
            recurringEPFactory.getScreenFilters = function() {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                        .getFilterParams(): {};

                return filterParams;
            };

        
            recurringEPFactory.getLineDetails = function(mainScreenKey, le_tax_type) {
                var params = {
                    "action_code" : (le_tax_type === 'PTSP' || le_tax_type == 'BRPTP' || le_tax_type == 'BRPTSP') ? 'r7pupz' : '6mnbxg',
                    "screen_key" : mainScreenKey
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
                    if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                        AlertService.add("error",data.errorMessage);
                        return false;
                    } else {

                        return data.jsonObject;
                    }
                });

            };

            recurringEPFactory.getAccounts = function(val, mainScreenKey, chart_key, parent_acct_key, acct_type_str) {

                var params = {
                    "action_code" : 'vgrdg8',
                    "screen_key" : mainScreenKey,
                    "search_string" : val,
                    "chart_key" : chart_key,
                    "parent_acct_key": parent_acct_key,
                    "acct_type" : acct_type_str
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+ '/loadJsonObject',params)
                                        .then(function(data) {
                                            if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                                                AlertService.add("error",data.errorMessage);
                                                return false;
                                            } else {
                                                return data.jsonObject;
                                            }
                                        });
            }; 

            recurringEPFactory.getExistingData = function(combination_key, chart_key, type, ep_auto_key) {

                var params = {
                    "action_code" : 'lm9x8i',
                    "chart_key" : chart_key,
                    "type":type,
                    "combination_key":combination_key,
                    "ep_auto_key": ep_auto_key
                };

                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+ '/loadJsonObject',params)
                                        .then(function(data) {
                                            if (data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                                                AlertService.add("error",data.errorMessage);
                                                return false;
                                            } else {
                                                return data.jsonObject;
                                            }
                                        });
            };

            
            recurringEPFactory.saveAdjs = function(_headerData,_transData){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                
                var jsonSettings =  JSON.stringify(_headerData);
                var jsonObj = JSON.stringify(_transData);
                
               console.log('jsonObj', jsonObj);
               console.log('jsonSettings', jsonSettings);
                
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);

                console.log("recurring ep params to save");
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: recurringEPFactory.url.saveAdjs,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            
            recurringEPFactory.deleteAdjs = function(_data) {
              
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj}, params);
                
                console.log("tax adjs params to delete");
                console.log(params);
                var promise = $http({
                    method: "post",
                    url: recurringEPFactory.url.deleteAdjs,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };

            return recurringEPFactory;
        }]);


    return services;


});