define([
    'angular',
    'datatables',
    './GoldSyncCtrl',
    './GoldSyncServices',
    './EditDetailsCtrl',
    './LogHistoryCtrl'
], function () {
    'use strict';
    return angular.module('app.goldSync', ['app.goldSyncServices', 'app.goldSyncCtrl', 'app.editDetailsCtrl', 'app.logHistoryCtrl', 'ui.toggle', 'ngAnimate', 'datatables'])
});