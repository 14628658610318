define([
    'angular',
    './Irs4797Controllers',
    './Irs4797Service'

], function () {
    'use strict';
    return angular.module('app.irs4797', ['app.irs4797Controller','app.irs4797Service'] )


});