define([
    'angular'

], function () {
    'use strict';
    
    /* NOTE:  changed on 05/04/2019
	  Below two controllers functionalities are same, if we are using same modal in both places grid & workflow, we need to define the controllers like below
		textInformationCtrl		: this controller is used on workflow Step
		textInformationCtrlModal	: this controller is used on Grid Modal
*/


    var controllers = angular.module('app.Form8858-textInformationCtrl', [])
    .controller('textInformationCtrl', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory','$parse', 'scheduleGGroupObjFactory',
			   'AlertService', 'GENERAL_CONFIG', 'ModalFactory',textInformationCtrl])
	.controller('textInformationCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory', '$parse','scheduleGGroupObjFactory',
	'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',textInformationCtrlModal]);


	function textInformationCtrl(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, scheduleGGroupObjFactory,
		AlertService, GENERAL_CONFIG, ModalFactory) {
		textInformationCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, scheduleGGroupObjFactory, AlertService, GENERAL_CONFIG,ModalFactory, null, null, null, null, this);
		}


    function textInformationCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, scheduleGGroupObjFactory,
    		AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,
			rowData, colData, gridData,thisObj) {
    
   
    	var vm = this||thisObj;
        vm.rowData = rowData||$scope.$parent.rowData;
        vm.scheduleGDetails = [];
        vm.scheduleGDetailsOriginal = [];
    	vm.scheduleGDetails.value = "";
    	
    	 vm.scheduleIDetails = [];
         vm.scheduleIDetailsOriginal = [];
     	vm.scheduleIDetails.value = "";
    	vm.schd_g = {};
    	vm.schd_i = {};    	
    	vm.error_flag = false;
    	
    	$scope.scheduleGData = [];
    	$scope.scheduleIData = [];
    	
    	//vm.isSaveClicked = false;
    	
    	 $scope.crudLoading = false;
    	 
    	var saveParamDtls = [];
        var tempDataObj = {
            num :'',
            description : '',
            value :''
        };
        vm.filingGroup=[];
    	
    	
    	 vm.calendar = {
             opened: false
         };
    	 
    	vm.tabTitle = ["Schedule G Other Information", "Schedule I Other Information"];
    	
    	
        vm.selectedCountry='';
        vm.addressType={};
        vm.countries = null;
        
        vm.modalTitle = "Form 8858- Information Return of U.S. Persons With Respect To Foreign Disregarded Entities";
        if (angular.isObject($uibModalInstance)) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				//var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
				//$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};			
		} else {
			vm.modal_name = null;			
		}
        
        vm.selectedGroup= {};
        vm.selectedFiledOnBehalf = {};
        vm.selectedGroup1 = {}; 
       // vm.PrincipalBusinessActivity = scheduleGGroupObjFactory.getPrincipalBusinessActivity();
        
        
        vm.updated = function(filingID) {
			if(vm.irsF8858ScheduleNAData.length >0){
			 var filing_group_db = vm.irsF8858ScheduleNAData.find(x=> x.attrib_name === '8858_NA_FILER_LEGAL_ENTITY_ID')
			 filing_group_db.VALUE == filingID ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FILER_LEGAL_ENTITY_ID','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FILER_LEGAL_ENTITY_ID','Y');
			 
		}
        	vm.selectedGroup = getFilingGroup(filingID); 
        	vm.filingGroup1= _.filter(vm.filingGroup, function(obj){
                return obj.LEGAL_ENTITY_ID !== vm.selectedGroup.LEGAL_ENTITY_ID ;
            });       	
        }
        
        
       vm.addressType={};
       vm.attribData = [];
       vm.countryVar;
       vm.countryCust;
       vm.countryAgent;
       vm.radioIn = 'N';
       vm.countries = null;       
       vm.enabledBRUSTaxType = false;
       vm.enabledBRPTPTaxType = false;
       vm.enabledBRCFCTaxType = false;    
       vm.refrence_iddisabled = false;   
       vm.batch_flag_2a = false;
       vm.batch_flag_2b = false;

       vm.openCalendar = function() {
        		vm.calendar.opened = true;  
        	};     	
     	
        	
        	vm.init = function(){
        		var param = {};
        		 var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                 var param = _.merge({}, GlobalService.globalParams, filterParams);
        		//"scenario":workspaceFactory.activeScreen.filters.filterParams.scenario,
        		param.action_code= "f5sne6";
        		               JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', param).then(function (data) {
        		               	vm.filingGroup =  data.jsonObject;
        		               	vm.filingGroup1 = data.jsonObject;
        		               	getFormData();
        		               	get8858Line1Data();
        		               	
                                vm.loadscheduleIData();
        		               });
        		               
        	}
        	vm.init();
        	
        	//GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
        	
        	/*function getPrincipalBusinessActivity() {
                
        		var params = {
                        "action_code":'y5ypqx',
                        "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                         "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                         "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
                        
                    };
                    return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                        if (data.callSuccess === "1") {
                            vm.PrincipalBusinessActivity = data.jsonObject; 
                        }
                        });
        		               }*/

        	var PrincipalBusinessActivity = {};
        	       PrincipalBusinessActivity.action_code = "y5ypqx";
        	       JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject", PrincipalBusinessActivity).then(function (data) {
        	           if(data.callSuccess === "1"){
        	            vm.PrincipalBusinessActivity = data.jsonObject;
        	           }
        	       });
        	
        	vm.updated1 = function(activityDesc) {
				if(vm.irsF8858ScheduleNAData.length >0){
			 		var principal_business_db = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_PRINCIPAL_ACTIVITY_CODE')
					 	principal_business_db.VALUE == activityDesc ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_PRINCIPAL_ACTIVITY_CODE','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_PRINCIPAL_ACTIVITY_CODE','Y');
			 			
					}
                vm.selectedGroup1 = getPrincipalBusinessActivity(activityDesc);
                return activityDesc
                };
                
                
                
                function get8858Line1Data()
				        		{
				            	 var params = {
				                "action_code": '6tbecb',
				                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
				                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
				                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
				                "combination_key": vm.rowData.HO_COMBINATION_KEY,
				                "ho_leid": vm.rowData.LEID,
				                "ho_cdr_no": vm.rowData.CDR_NO,
				                "ho_rpt_pd": vm.rowData.REPORTING_PERIOD
				                
				                
				            	};
				            	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
				                if (data.callSuccess === "1") {
				                    vm.line1Data = data.jsonObject;
				                     }
				                    });
						}	
        	
        	
        	function getFormData()
            {
                var params = {
                    "action_code":'mpx16c',//'y5ypqx',
                    "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                     "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                     "irs_form_no" : 'F8858',
                    "combination_key": vm.rowData.COMBINATION_KEY
                };
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                    if (data.callSuccess === "1") {
                        vm.irsF8858ScheduleNAData = data.jsonObject;
                        vm.attribData = [];
                        vm.irsF8858ScheduleNADataTemp = data.jsonObject;
                        vm.arraySize = vm.irsF8858ScheduleNADataTemp;
                        console.log("check this out ::::::: ", vm.irsF8858ScheduleNADataTemp);

                        var attribName = null;
                        var attribValue = null;
                        for(var i=1; i <data.jsonObject.length; i++) {
                        var tempString = vm.irsF8858ScheduleNAData[i].object_id;
                        var line_no_new = vm.irsF8858ScheduleNAData[i].line_no;
                        var tempValue = vm.irsF8858ScheduleNAData[i].attrib_value;
                        attribName = tempValue.split('~')[0];
                        attribValue =  ((vm.irsF8858ScheduleNAData[i].VALUE=== "0" )? '':vm.irsF8858ScheduleNAData[i].VALUE);//vm.irsF8858ScheduleNAData[i].VALUE;
                        var updateFlag = vm.irsF8858ScheduleNAData[i].OVERRIDE_FLAG;
                        
                     if( vm.irsF8858ScheduleNAData[i].BATCH_FLAG == 'Y'&& vm.irsF8858ScheduleNAData[i].line_no == '2A'){
						vm.batch_flag_2a = true;
                    	};
                    	
                     if( vm.irsF8858ScheduleNAData[i].BATCH_FLAG == 'Y'&& vm.irsF8858ScheduleNAData[i].line_no == '2B'){
						vm.batch_flag_2b = true;
                    	}; 	
                        
                        vm.irsF8858ScheduleNAData[i] = {
                        i : vm.irsF8858ScheduleNAData[i],
                        "tax_year": tempString.split('~')[0],
                        "trans_type_key":tempString.split('~')[1],
                        "attrib_name":tempValue.split('~')[0],
                        "attrib_desc":tempValue.split('~')[3],
                        "line_attrib_key":tempValue.split('~')[1],
                        "trans_details_key":tempString.split('~')[3],
                        "occurrence":tempString.split('~')[4],
                        "VALUE":vm.irsF8858ScheduleNAData[i].VALUE,
                        "form_key":tempString.split('~')[6],
                    	"is_update":updateFlag,
                    	"line_no":line_no_new
                        };
                        if(attribName === '8858_NA_FDE_BEGIN_DATE') {
                            vm.attribData['8858_NA_FDE_BEGIN_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                        } else if(attribName === '8858_NA_FDE_END_DATE') {
                        	vm.attribData['8858_NA_FDE_END_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                        }else if(attribName === '8858_NA_ENTITY_ORGANIZATION_DATE') {
                        	vm.attribData['8858_NA_ENTITY_ORGANIZATION_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                        }else if(attribName === '8858_NA_FDE_EFFECTIVE_DATE') {
                        	vm.attribData['8858_NA_FDE_EFFECTIVE_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                        } else if (attribName === '8858_NA_FDE_FB_US_COUNTRY' && angular.isDefined(attribValue) && attribValue === 'US') {
                        	  vm.countryVar = attribValue ;  
                   		     vm.attribData['8858_NA_FDE_FB_US_COUNTRY'] = attribValue;
                   		     vm.attribData['8858_NA_FDE_FB_F_COUNTRY'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_F_ADDR_1'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_F_ADDR_2'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_F_CITY'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_F_STATE'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_F_ZIP'] = null;
                           } else if (attribName === '8858_NA_FDE_FB_F_COUNTRY' && angular.isDefined(attribValue) && attribValue !== 'US') { 
                           	  vm.countryVar = attribValue ;  
                   		     vm.attribData['8858_NA_FDE_FB_F_COUNTRY'] = attribValue;
                   		     vm.attribData['8858_NA_FDE_FB_US_COUNTRY'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_US_ADDR_1'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_US_ADDR_2'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_US_CITY'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_US_STATE'] = null;
                   		     vm.attribData['8858_NA_FDE_FB_US_ZIP'] = null;
                           }else if (attribName === '8858_NA_PERSONS_BOOK_US_COUNTRY' && angular.isDefined(attribValue) && attribValue === 'US') {
                    	        vm.countryCust = attribValue ;  
                   		     vm.attribData['8858_NA_PERSONS_BOOK_US_COUNTRY'] = attribValue;
                   		     vm.attribData['8858_NA_PERSONS_BOOK_F_COUNTRY'] = null;
                				 vm.attribData['8858_NA_PERSONS_BOOK_F_ADDR_1'] = null;
                				 vm.attribData['8858_NA_PERSONS_BOOK_F_ADDR_2'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_F_CITY'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_F_STATE'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_F_ZIP'] = null;
                           } else if (attribName === '8858_NA_PERSONS_BOOK_F_COUNTRY' && angular.isDefined(attribValue) && attribValue !== 'US') { 
                        	        vm.countryCust = attribValue ;  
                   		     vm.attribData['8858_NA_PERSONS_BOOK_F_COUNTRY'] = attribValue;
                   		     vm.attribData['8858_NA_PERSONS_BOOK_US_COUNTRY'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_US_ADDR_1'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_US_ADDR_2'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_US_CITY'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_US_STATE'] = null;
                			     vm.attribData['8858_NA_PERSONS_BOOK_US_ZIP'] = null;
                           }else if(attribName === '8858_NA_FILER_LEGAL_ENTITY_ID'){
                        	   angular.forEach(vm.filingGroup, function(fg) {
                       	   		   if (attribValue == fg.LEGAL_ENTITY_ID) {
                       	   			   vm.selectedGroup = fg;
                       	   		   }
                   	   	   });
                       	   }/*else if(attribName === '8858_NA_PRINCIPAL_BUSINESS_ACTIVITY'){
                        	   angular.forEach(vm.PrincipalBusinessActivity, function(hg) {
                       	   		   if (vm.attribData['8858_NA_PRINCIPAL_BUSINESS_ACTIVITY'] == hg.ACTIVITY_DESC) {
                       	   			   vm.selectedGroup1 = hg;  
                       	   		   }
                       	   	   });
                        	   }*/else if(attribName === '8858_NA_FILER_BEGIN_DATE') {
                              	vm.attribData['8858_NA_FILER_BEGIN_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                              }else if(attribName === '8858_NA_FILER_END_DATE') {
                              	vm.attribData['8858_NA_FILER_END_DATE'] = new Date(attribValue);//, "MM/dd/yyyy");
                              }else if (attribName === '8858_NA_INIT_FORM_Y' && attribValue === '1'){
                      		    vm.radioIn = 'I';
                    			vm.attribData['8858_NA_INIT_FORM_Y'] = attribValue;
                              }else if (attribName === '8858_NA_FINAL_FORM_Y' && attribValue === '1'){
                      		    vm.radioIn = 'F';
                    			vm.attribData['8858_NA_FINAL_FORM_Y'] = attribValue;
                              }
                              else if (attribName === '8858_NA_FDE_FB_EIN'&& (angular.isUndefined(attribValue) ||attribValue == ""))
       						{
                		 vm.refrence_iddisabled = true;
            				}
            			else {
                     	vm.attribData[attribName]=attribValue;
                     }
                 }
             }
                    else 
                    {
                        if (data.errorMessage === "Access Denied") {
                            AlertService.add("","Sorry you do not have access to do the requested action.",
                                4000);
                            vm.userMessage = "!! Unable to perform Selected Action...";
                        } else {
                            AlertService.add(
                                "-------------------------xyz",
                                "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                                4000);
                            vm.userMessage = "!! Unable to perform Selected Action...";
                        }
                    }
                   
                });
            }	
            
            
            
            vm.findIndex_text = function(data, attribName,value){
            	var findData = data.find(x => x.attrib_name === attribName);
            	findData.is_update = value; 
            	return findData;
       		}
          
          vm.reset = function () {
        	  $scope.crudLoading = false;/*
              vm.getFormData();
              $scope.scheduleGData = angular.copy(vm.originalGData);      
              $scope.scheduleIData = angular.copy(vm.originalIData);*/
              vm.init();
             
              
          }

        vm.loadscheduleGData = function()
        {
            var params = {
                "action_code": '3527y2',
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "irs_form_no" : 'F8858',
                "combination_key":  vm.rowData.COMBINATION_KEY//675366
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("data.jsonObject---------------------", data.jsonObject);
                    var scheduleGData = data.jsonObject;
                    var i = 1;

                    var previous_line_no = scheduleGData[i].line_no;
                    while( i < scheduleGData.length )
                    {
                        var tempObj = {};
                        var j = i;
                        var count = 0;
                        while(j <scheduleGData.length && previous_line_no == scheduleGData[j].line_no)
                        {
                            if(scheduleGData[j].attrib_value.split("~")[0] == "LINE_NO")
                            {
                            	tempObj.propertyType = scheduleGData[j].PROPERTY_TYPE;                            	
                                tempObj.line_no = scheduleGData[j].line_no;
                                tempObj.parent_line_no = scheduleGData[j].PARENT_LINE_NO;
                                tempObj.col_id = scheduleGData[j].COL_ID;
                                tempObj.parent_col_id = scheduleGData[j].PARENT_COL_ID;
                                tempObj.line_type = scheduleGData[j].LINE_TYPE;
                                tempObj.saveParams = {
                                    "tax_year":scheduleGData[j].object_id.split("~")[0],
                                    "trans_type_key" : scheduleGData[j].object_id.split("~")[1],
                                    "trans_Details_key" : scheduleGData[j].object_id.split("~")[3],
                                    "form_key" : scheduleGData[j].object_id.split("~")[6],
                                    "occurrence" : scheduleGData[j].object_id.split("~")[4]
                                }
                            }
                            else if(scheduleGData[j].attrib_value.split("~")[0] == "LINE_DESCRIPTION")
                            {
                                tempObj.line_description = scheduleGData[j].attrib_value.split("~")[3];
                            }                            
                           
                            else
                            {
                            	if (count > 0) {
                            		if (count == 1) {
                            			tempObj.attrib_name = [tempObj.attrib_name];
                            			tempObj.value = [tempObj.value];
                            		}
                        			tempObj.attrib_name[count] = scheduleGData[j].attrib_value.split("~")[0];
                        			tempObj.value[count] = {
    	                                    "VALUE": scheduleGData[j].VALUE,
    	                                    "line_attrib_key": scheduleGData[j].attrib_value.split("~")[1]
    	                             }
                            	} else {
	                                tempObj.propertyType = scheduleGData[j].PROPERTY_TYPE;
	                                tempObj.isEditableFlag = 'Y';
	                                tempObj.isVisibleFlag = 'Y';
	                                tempObj.isSaveValidFlagG = 'Y';
	                                tempObj.attrib_name = scheduleGData[j].attrib_value.split("~")[0];
	                                tempObj.value = {
	                                    "VALUE": scheduleGData[j].VALUE,
	                                    "line_attrib_key": scheduleGData[j].attrib_value.split("~")[1]
	                                }
                            	}
                            }
                            previous_line_no = scheduleGData[j].line_no;
                            if (scheduleGData[j].PROPERTY_TYPE == 13) {
                    			count++;
                    		}
                            j++;
                        }
                        if(j < scheduleGData.length)
                            previous_line_no = scheduleGData[j].line_no;
                        var tempIndex = $scope.scheduleGData.length;                        
                        $scope.scheduleGData.push(tempObj);
                        console.log("irsF8858ScheduleGDatafinal---------", $scope.scheduleGData) 

                        i = j;
                    }

                }
                else
                {
                    if (data.errorMessage === "no access") {
                        AlertService.add("","Sorry you do not have access to do the requested action.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
                /*for(var i = 0;i<$scope.scheduleGData.length;i++){
                	 vm.checkingForValidation($scope.scheduleGData[i]);
                }*/
                
                
                vm.enabledBRUSTaxType = (vm.rowData.TAX_TYPE === 'BRUS'||vm.rowData.TAX_TYPE === 'BRPTP');
                $scope.enabledBRUSTaxType = vm.enabledBRUSTaxType;
    			if(!vm.enabledBRUSTaxType) {
    				for(var k=13;k<25;k++){
    					$scope.scheduleGData[k].isEditableFlag = 'N';
    					$scope.scheduleGData[k].isVisibleFlag = 'N'; 
    					$scope.scheduleGData[k].value.VALUE = null;
    				}
    			}
    			
    			vm.enabledBRCFCTaxType = (vm.rowData.TAX_TYPE === 'BRCFC'||vm.rowData.TAX_TYPE === 'BRUS'||vm.rowData.TAX_TYPE === 'BRPTP');
    			if(!vm.enabledBRCFCTaxType) {
    				$scope.scheduleGData[12].isEditableFlag = 'N';
    				$scope.scheduleGData[12].isVisibleFlag = 'N'
    				$scope.scheduleGData[12].value[0].VALUE = null;
    				$scope.scheduleGData[12].value[1].VALUE = null;
    			}
    				
    			vm.originalGData = angular.copy($scope.scheduleGData);
    			
                setAmt();
            });
        }
       
        
        function setAmt()
        {
        	var params = {
        			"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
        			"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
        			"combination_key":vm.rowData.COMBINATION_KEY, //674867,        			
        			"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
        	}
        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=a0gmzp",params).then(function(data) {
        		console.log("Response Data x ::", data.jsonObject);
        		if (data.callSuccess === "1") {
        			var temp_amt = data.jsonObject[0].SEC_901M_AMT;
        			var temp_amt1 = data.jsonObject[0].SEC_909_SUSP_TAX;
        			var temp_amt2 = data.jsonObject[0].SEC_909_TAX_RELEASED;
        			
        			vm.autoList1 = _.filter($scope.scheduleGData, function(p){
			                       return _.includes(['auto1'], p.line_type) ;
			                		});	
			         vm.autoList2 = _.filter($scope.scheduleGData, function(p){
			                       return _.includes(['auto2'], p.line_type) ;
			                		});	
			        vm.autoList3 = _.filter($scope.scheduleGData, function(p){
			                       return _.includes(['auto3'], p.line_type) ;
			                		});
			        vm.value1 = $scope.scheduleGData.find(x=> x.attrib_name == vm.autoList1[0].attrib_name);	 
			        vm.value2 = $scope.scheduleGData.find(x=> x.attrib_name == vm.autoList2[0].attrib_name);	
			        vm.value3 = $scope.scheduleGData.find(x=> x.attrib_name == vm.autoList3[0].attrib_name);	      		
			                		
        			if(temp_amt != 0  )
       			      {			
       				    vm.value1.value[0].VALUE = 'Y';
        				vm.value1.value[1].VALUE = null;
       			    }
       			else
       			  {
	              if( vm.value1.value[0].VALUE === 'Y'){
       				    vm.value1.value[0].VALUE = 'Y';
       				    vm.value1.value[1].VALUE = null;
       			    }else{
       			    	vm.value1.value[1].VALUE = 'N';
       			    	vm.value1.value[0].VALUE = null;
       			    }       				
       			}        			
        			if(temp_amt1 && temp_amt2 != 0 )
       			    {
						vm.value2.value[0].VALUE = 'Y';
        				vm.value2.value[1].VALUE = null;
       			    }
       			else
       			{    if(vm.value2.value[0].VALUE === 'Y'){
       				     vm.value2.value[0].VALUE = 'Y';
       				     vm.value2.value[1].VALUE = null;
       			        }else {
       			         vm.value2.value[1].VALUE = 'N';
       			         vm.value2.value[0].VALUE = null;
       			        }
       			}
       			
       			if(vm.value3.VALUE === undefined && vm.value3.value[1].VALUE === undefined){
       				     vm.value3.value[0].VALUE = 'Y';
       				     vm.value3.value[1].VALUE = null;
       			        }else if (vm.value3.value[0].VALUE === 'Y'){
       			         vm.value3.value[0].VALUE = 'Y';
       			         vm.value3.value[1].VALUE = null;
       			        }else {
       			         vm.value3.value[1].VALUE = 'N';
       			         vm.value3.value[0].VALUE = null;
       			        }
        			
       			console.log("vm.scheduleGDetails---------", vm.scheduleGDetails);
        			
        			
        		}
        		else {
					if (data.errorMessage === "no access") {
						AlertService.add("error","Sorry you do not have access to do the requested action.",
										4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
										"error",
										"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
										4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
				}
        		
        	});
        	
        }
        vm.loadscheduleGData();
        
        
        
        vm.loadscheduleIData = function()
        {
            var params = {
                "action_code": 'zkn7je',
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
            	"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
            	"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "irs_form_no" : 'F8858',
                "combination_key": vm.rowData.COMBINATION_KEY
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("data.jsonObject---------------------", data.jsonObject);
                    var scheduleIData = data.jsonObject;
                    var i = 1;

                    var previous_line_no = scheduleIData[i].line_no;
                    while( i < scheduleIData.length )
                    {
                        var tempObj = {};
                        var j = i; 
                        var count = 0;
                        while(j <scheduleIData.length && previous_line_no == scheduleIData[j].line_no)
                        {
                            if(scheduleIData[j].attrib_value.split("~")[0] == "LINE_NO")
                            {
                            	tempObj.propertyType = scheduleIData[j].PROPERTY_TYPE;
                                tempObj.line_no = scheduleIData[j].line_no;
                                tempObj.saveParams = {
                                    "tax_year":scheduleIData[j].object_id.split("~")[0],
                                    "trans_type_key" : scheduleIData[j].object_id.split("~")[1],
                                    "trans_Details_key" : scheduleIData[j].object_id.split("~")[3],
                                    "form_key" : scheduleIData[j].object_id.split("~")[6],
                                    "occurrence" : scheduleIData[j].object_id.split("~")[4]
                                }
                            }
                            else if(scheduleIData[j].attrib_value.split("~")[0] == "LINE_DESCRIPTION")
                            {
                                tempObj.line_description = scheduleIData[j].attrib_value.split("~")[3];
                            }                         
                            else
                            {
                            	if (count > 0) {
                            		if (count == 1) {
                            			tempObj.attrib_name = [tempObj.attrib_name];
                            			tempObj.value = [tempObj.value];
                            		}
                        			tempObj.attrib_name[count] = scheduleIData[j].attrib_value.split("~")[0];
                        			tempObj.value[count] = {
    	                                    "VALUE": scheduleIData[j].VALUE,
    	                                    "line_attrib_key": scheduleIData[j].attrib_value.split("~")[1]
    	                             }
                            	} else {
                            	tempObj.propertyType = scheduleIData[j].PROPERTY_TYPE;
                                tempObj.isEditableFlag = 'Y';
                                tempObj.isVisibleFlag = 'Y';
                                tempObj.attrib_name = scheduleIData[j].attrib_value.split("~")[0];
                                tempObj.value = {
                                    "VALUE": scheduleIData[j].VALUE,
                                    "line_attrib_key": scheduleIData[j].attrib_value.split("~")[1]
                                }
                            }
                            }
                            previous_line_no = scheduleIData[j].line_no;
                            if (scheduleIData[j].PROPERTY_TYPE == 13) {
                    			count++;
                    		}
                            j++;
                        }
                        if(j < scheduleIData.length)
                            previous_line_no = scheduleIData[j].line_no;
                        var tempIndex = $scope.scheduleIData.length;                       
                        $scope.scheduleIData.push(tempObj);


                        i = j;
                    }
                    
        			$scope.scheduleIData = _.sortBy($scope.scheduleIData, 'line_no')
                }
                else
                {
                    if (data.errorMessage === "no access") {
                        AlertService.add("","Sorry you do not have access to do the requested action.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
                for(var i = 0;i<$scope.scheduleIData.length;i++){
               	 vm.checkingForValidationForI($scope.scheduleIData[i]);
               }
                
                vm.enabledBRUSTaxType = (vm.rowData.TAX_TYPE === 'BRCFC'||vm.rowData.TAX_TYPE === 'BRUS'||vm.rowData.TAX_TYPE === 'BRPTP');
    			if(!vm.enabledBRUSTaxType) {
    				for(var i = 0;i<$scope.scheduleIData.length;i++){
    					$scope.scheduleIData[i].isEditableFlag = 'N';
    					$scope.scheduleIData[i].isVisibleFlag = 'N';    					
    					$scope.scheduleIData[i].value[0].VALUE = null;
    					$scope.scheduleIData[i].value[1].VALUE = null;
    				}
    			
    			}
                vm.originalIData = angular.copy($scope.scheduleIData);
                console.log("Final $scope.scheduleIData--------------", $scope.scheduleIData);
            });
        }
        //vm.loadscheduleIData();

        function getCountries() {
            
        	var params = {
        			    "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                         "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
        	};

        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=kbuwei", params)
        	.then(function(data) {
        	if (data.callSuccess === "1") {
        	vm.countries = data.jsonObject;
        	}
        	    });
        	    }
        	    
        getCountries();
        
        function getCurrencies() {
            
            var params = {
            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            };

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3ucwjb", params)
            .then(function(data) {
            if (data.callSuccess === "1") {
            vm.currencies = data.jsonObject;
            }
               });
               }
        getCurrencies();
      
        function getStates() {
            

            var params = {
            		"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                     "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            };

        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=6eyqnz", params)
        	.then(function(data) {
        	if (data.callSuccess === "1") {
        	vm.states = data.jsonObject;
        	}
        	});
        	                }
      
        getStates();
        
        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        function getFilingGroup(filingID){
            var tempObj = {};
            for(var i = 0; i<vm.filingGroup.length; i++)
            {
                if(vm.filingGroup[i].LEGAL_ENTITY_ID == filingID)
                {
                    tempObj  = vm.filingGroup[i];
                    break;
                }
            }
            return tempObj;
        }
        function getPrincipalBusinessActivity(activityDesc){
            var tempObj = {};
            for(var i = 0; i<vm.PrincipalBusinessActivity.length; i++)
            {
                if(vm.PrincipalBusinessActivity[i].ACTIVITY_DESC === activityDesc)
                {
                    tempObj  = vm.PrincipalBusinessActivity[i];
                    break;
                }
            }
            return tempObj;
        }

     
        function getFiledOnBehalf(leid)
        {
            var tempObj = {};
            for(var i = 0; i<vm.goldLookupList.length; i++)
            {
                if(vm.goldLookupList[i].legal_entity_id == leid)
                {
                    tempObj = vm.goldLookupList[i];
                    break;
                }
            }
            return tempObj;
        }
         vm.radioClicked = function(value, value1, value2)
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON']))
        		{
        			if(vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON'] == 'APPLD FOR' || vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON'] == 'FOREIGNUS' )
        			{
        				vm.attribData['8858_NA_FDE_FB_EIN'] = null;
        				value1 = null;
        				vm.refrence_iddisabled  = true;
        			}
        			var ein_reason = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_EIN_MISSING_REASON')
			 			ein_reason.VALUE == value ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN_MISSING_REASON','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN_MISSING_REASON','Y');	
			 			
			 	     var ident_number = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_EIN')
			 	     	 var iden_num =  ident_number.VALUE == undefined ? undefined: value1;		
			 			 ident_number.VALUE == iden_num ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','Y');				
        			
        			var refr_id = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_REFERENCE_ID')
        			var refrence_d =  refr_id.VALUE == ' ' && value2 == ' '? null :refr_id.VALUE	;
        			vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] = refr_id.VALUE == ' '? null :vm.attribData['8858_NA_FDE_FB_REFERENCE_ID']
        			    
			 		refrence_d == vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_REFERENCE_ID','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_REFERENCE_ID','Y');	
			 		
        		}
        		
        		

        
        };

        vm.uncheckedRadio = function(value,value1,value2)
        {
        	console.log("inchange---------")
        	
        		if(angular.isDefined(vm.attribData['8858_NA_FDE_FB_EIN']))
        		{
        			if(vm.attribData['8858_NA_FDE_FB_EIN'] != null)
        			{
        				vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON'] = null;
        				value1 = null;
        				vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] = null;
        				value2 = null;
        			}
        		}
        		
        		
						
			  var identifying_number = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_EIN')
			       var ein_value =  identifying_number.VALUE == undefined && value == '' ? undefined: value;			  
			       identifying_number.VALUE == ein_value ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','Y');	
			 
			 var ein_missing_reason = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_EIN_MISSING_REASON')
			      var missing_reason = ein_missing_reason.VALUE == undefined ? undefined : value1;
			     ein_missing_reason.VALUE == missing_reason? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN_MISSING_REASON','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN_MISSING_REASON','Y');	
			 
			 var refrence_id_2 = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_REFERENCE_ID')
			 if((value == undefined || value == null || value == '') && (value2 == undefined || value2 == null || value2 == ''|| value2 == ' ') &&  
			 		(refrence_id_2.VALUE !== ' ' ) ){
						vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] = refrence_id_2.VALUE; 
				}
		     var refrence =  refrence_id_2.VALUE == ' '? null :refrence_id_2.VALUE	;
			 refrence == vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_REFERENCE_ID','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_REFERENCE_ID','Y');	
			 						
        		
				
		   
		   if(vm.attribData['8858_NA_FDE_FB_EIN'] == undefined ||vm.attribData['8858_NA_FDE_FB_EIN'] == null || vm.attribData['8858_NA_FDE_FB_EIN'] == '')
        			{
        				vm.refrence_iddisabled  = true;
        			}
        		else {
						vm.refrence_iddisabled  = false;
						}		
        	
        } ;
        
        
         vm.ref_id = function(value, value1, value2)
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_FDE_FB_REFERENCE_ID']))
        		{
        			var refrence_id = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_REFERENCE_ID')
        			var refrence_id_d =  refrence_id.VALUE == ' '? null :refrence_id.VALUE	;
        			    value = value == '' ? null : value;
			 			refrence_id_d == value ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_REFERENCE_ID','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_REFERENCE_ID','Y');	
			 			 
        			var ein_missing_reason = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_EIN_MISSING_REASON')
			 			ein_missing_reason.VALUE == vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON'] ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN_MISSING_REASON','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN_MISSING_REASON','Y');	
			 			
			 	     var identifying_number = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_FB_EIN')
			 	     	 var identity=  identifying_number.VALUE === undefined ? undefined: value2;		
			 			 //identifying_number.VALUE == value2 ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','Y');			
        				identifying_number.VALUE == identity? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_FB_EIN','Y');		
        			
        		}
        		
        		

        
        };
        
           vm.changeCountryLaws = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_ENTITY_COUNTRY_LAWS']))
        		{
        			var country_law = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_ENTITY_COUNTRY_LAWS')  
        			country_law.VALUE == value ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_ENTITY_COUNTRY_LAWS','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_ENTITY_COUNTRY_LAWS','Y');	
			 		
        		}

        
        };
        
         vm.changeDateOrg = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_ENTITY_ORGANIZATION_DATE']))
        		{
        		value = $filter('date')(value, "MM/dd/yyyy");
        		var country_Org = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_ENTITY_ORGANIZATION_DATE')
			 	     country_Org.VALUE == value ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_ENTITY_ORGANIZATION_DATE','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_ENTITY_ORGANIZATION_DATE','Y');	
			 		
        		}

        
        };
        
        vm.changeEffectiveDate = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_FDE_EFFECTIVE_DATE']))
        		{
        		value = $filter('date')(value, "MM/dd/yyyy");
        		var effective_date = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_FDE_EFFECTIVE_DATE')
			 	     effective_date.VALUE == value ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_EFFECTIVE_DATE','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_FDE_EFFECTIVE_DATE','Y');	
			 		
        		}

        
        };
        
         vm.changePrincipalPlace = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_COUNTRY_PRINCIPAL_BUSINESS_ACVTY']))
        		{
        			var principal_place = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_COUNTRY_PRINCIPAL_BUSINESS_ACVTY')
			 			principal_place.VALUE == value ? vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_COUNTRY_PRINCIPAL_BUSINESS_ACVTY','N') : vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_COUNTRY_PRINCIPAL_BUSINESS_ACVTY','Y');	
			 		
        		}

        
        };
        
        
           vm.changeArticle = function(value)
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_TREATY_ARTICLE_NUMBER']))
        		{
        			var country_law = vm.line1Data.find(x=> x.ATTRIB_NAME === 'F8858_NA_TREATY_ARTICLE_NUMBER')  
        			 vm.findIndex_text(vm.irsF8858ScheduleNAData, '8858_NA_TREATY_ARTICLE_NUMBER','Y') ;	
			 		
        		}

	};
        
        

       /* vm.clicked = function()
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_2B_EIN_MISSING_REASON']) && angular.isDefined(vm.attribData['8858_NA_2B_EIN']))
        		{
        			if(vm.attribData['8858_NA_2B_EIN_MISSING_REASON'] == 'APPLD FOR' || vm.attribData['8858_NA_2B_EIN_MISSING_REASON'] == 'FOREIGNUS' )
        			{
        				vm.attribData['8858_NA_2B_EIN'] = null;
        			}
        			else
        			{
        				vm.attribData['8858_NA_2B_EIN_MISSING_REASON'] = vm.attribData['8858_NA_2B_EIN'];
        			}
        		}

        
        };
        
        vm.unchecked = function()
        {
        	console.log("inchange---------")
        	
        		if(angular.isDefined(vm.attribData['8858_NA_2B_EIN_MISSING_REASON']) && angular.isDefined(vm.attribData['8858_NA_2B_EIN']))
        		{
        			if(vm.attribData['8858_NA_2B_EIN'] != null)
        			{
        				vm.attribData['8858_NA_2B_EIN_MISSING_REASON'] = vm.attribData['8858_NA_2B_EIN'];
        			}
        		}
        	
        };*/
        
        vm.clicked_a = function()
        {
        
        		if(angular.isDefined(vm.attribData['8858_NA_US_AGENT_EIN_MISSING_REASON']) /*&& angular.isDefined(vm.attribData['8858_NA_US_AGENT_EIN'])*/)
        		{
        			if(vm.attribData['8858_NA_US_AGENT_EIN_MISSING_REASON'] == 'APPLD FOR' || vm.attribData['8858_NA_US_AGENT_EIN_MISSING_REASON'] == 'FOREIGNUS' )
        			{
        				vm.attribData['8858_NA_US_AGENT_EIN'] = null;
        			}
        			/*else
        			{
        				vm.attribData['8858_NA_US_AGENT_EIN_MISSING_REASON'] = vm.attribData['8858_NA_US_AGENT_EIN'];
        			}*/
        		}

        
        };
        
        vm.unchecked_a = function()
        {
        	console.log("inchange---------")
        	
        		if(/*angular.isDefined(vm.attribData['8858_NA_US_AGENT_EIN_MISSING_REASON']) &&*/ angular.isDefined(vm.attribData['8858_NA_US_AGENT_EIN']))
        		{
        			if(vm.attribData['8858_NA_US_AGENT_EIN'] != null)
        			{
        				vm.attribData['8858_NA_US_AGENT_EIN_MISSING_REASON'] = null;
        			}
        		}
        	
        };
     
        vm.personBnssChange = function(){
            if(vm.attribData['8858_NA_2B_FLAG'] === "N")
            {
                vm.attribData['8858_NA_PERSONS_BOOK_NAME_1'] = null;
                vm.attribData['8858_NA_PERSONS_BOOK_NAME_2'] = null;
                //vm.attribData['8858_NA_2B_EIN'] = null;
				vm.attribData['8858_NA_PERSONS_BOOK_F_COUNTRY'] = null;
				vm.attribData['8858_NA_PERSONS_BOOK_F_ADDR_1'] = null;
				vm.attribData['8858_NA_PERSONS_BOOK_F_ADDR_2'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_F_CITY'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_F_STATE'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_F_ZIP'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_US_COUNTRY'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_US_ADDR_1'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_US_ADDR_2'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_US_CITY'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_US_STATE'] = null;
			    vm.attribData['8858_NA_PERSONS_BOOK_US_ZIP'] = null;
			    vm.countryCust = null;
			    
			    
            }
            if(vm.attribData['8858_NA_2B_FLAG'] === "P")
                vm.attribData['8858_NA_PERSONS_BOOK_NAME_2'] = null;
        };

        vm.refreshGoldIdLookup = function(searchInput) {
            vm.filteredGoldIds = (vm.goldLookupList || []).filter(function(item) {
                var legalEntityId = ($parse('legal_entity_id')(item) || '') + '-' + ($parse('legal_entity_name')(item) || '') ;
                return legalEntityId.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
            });
            vm.filteredGoldIds = vm.filteredGoldIds.splice(0, 10);
        };
        
        vm.saveForm = function () {

            var editRow = [];
            var addRow = [];
            var deleteRow = [];
            var scheduleSettings = [{
            	 "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                 "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                 "jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                         }];
            
            
             if(
            (vm.attribData['8858_NA_FDE_FB_EIN'] == undefined || vm.attribData['8858_NA_FDE_FB_EIN'] == null  || vm.attribData['8858_NA_FDE_FB_EIN'] == '' 
            )
			&& (vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON']  == undefined || vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON'] == null  || vm.attribData['8858_NA_FDE_FB_EIN_MISSING_REASON'] == '' 
			 )
			 && (vm.attribData['8858_NA_FDE_FB_REFERENCE_ID']  == undefined || vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] == null  || vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] == '' || vm.attribData['8858_NA_FDE_FB_REFERENCE_ID'] == ' '
			 )
			){ 
				vm.error_flag = true;
				AlertService.add("error", "Please enter Identifying number or EIN missing reason Or Reference ID number ",4000);
	     	    return;
				
			} 
			else {
				vm.error_flag = false;
			}
			
			
            if(angular.isDefined(vm.attribData['8858_NA_FDE_BEGIN_DATE']) ) {
            	vm.attribData['8858_NA_FDE_BEGIN_DATE'] = $filter('date')(vm.attribData['8858_NA_FDE_BEGIN_DATE'], "MM/dd/yyyy");
        	}
         if(angular.isDefined(vm.attribData['8858_NA_FDE_END_DATE']) ) {
         	vm.attribData['8858_NA_FDE_END_DATE'] = $filter('date')(vm.attribData['8858_NA_FDE_END_DATE'], "MM/dd/yyyy");
     	    }
         if(angular.isDefined(vm.attribData['8858_NA_ENTITY_ORGANIZATION_DATE']) ) {
         	vm.attribData['8858_NA_ENTITY_ORGANIZATION_DATE'] = $filter('date')(vm.attribData['8858_NA_ENTITY_ORGANIZATION_DATE'], "MM/dd/yyyy");
     	}
      if(angular.isDefined(vm.attribData['8858_NA_FDE_EFFECTIVE_DATE']) ) {
      	vm.attribData['8858_NA_FDE_EFFECTIVE_DATE'] = $filter('date')(vm.attribData['8858_NA_FDE_EFFECTIVE_DATE'], "MM/dd/yyyy");
  	    }
      if(angular.isDefined(vm.attribData['8858_NA_FILER_BEGIN_DATE']) ) {
       	vm.attribData['8858_NA_FILER_BEGIN_DATE'] = $filter('date')(vm.attribData['8858_NA_FILER_BEGIN_DATE'], "MM/dd/yyyy");
   	}
    if(angular.isDefined(vm.attribData['8858_NA_FILER_END_DATE']) ) {
    	vm.attribData['8858_NA_FILER_END_DATE'] = $filter('date')(vm.attribData['8858_NA_FILER_END_DATE'], "MM/dd/yyyy");
	    }
    if(angular.isDefined(vm.attribData['8858_NA_PRINCIPAL_ACTIVITY_CODE'])){
  		vm.attribData['8858_NA_PRINCIPAL_BUSINESS_ACTIVITY'] = vm.selectedGroup1.ACTIVITY_CODE;
  	 }
         if(vm.countryVar === 'US') {
    		 vm.attribData['8858_NA_FDE_FB_US_COUNTRY'] = vm.countryVar; 
    		 vm.attribData['8858_NA_FDE_FB_F_COUNTRY'] = null;
   		     vm.attribData['8858_NA_FDE_FB_F_ADDR_1'] = null;
   		     vm.attribData['8858_NA_FDE_FB_F_ADDR_2'] = null;
   		     vm.attribData['8858_NA_FDE_FB_F_CITY'] = null;
   		     vm.attribData['8858_NA_FDE_FB_F_STATE'] = null;
   		     vm.attribData['8858_NA_FDE_FB_F_ZIP'] = null;
    		
    		} else{ 
	    		 vm.attribData['8858_NA_FDE_FB_F_COUNTRY'] = vm.countryVar;
	       		 vm.attribData['8858_NA_FDE_FB_US_COUNTRY'] = null;
	   		     vm.attribData['8858_NA_FDE_FB_US_ADDR_1'] = null;
	   		     vm.attribData['8858_NA_FDE_FB_US_ADDR_2'] = null;
	   		     vm.attribData['8858_NA_FDE_FB_US_CITY'] = null;
	   		     vm.attribData['8858_NA_FDE_FB_US_STATE'] = null;
	   		     vm.attribData['8858_NA_FDE_FB_US_ZIP'] = null;
    		  }	
         if(vm.countryCust === 'US') {
     		vm.attribData['8858_NA_PERSONS_BOOK_US_COUNTRY'] = vm.countryCust;  
    		    vm.attribData['8858_NA_PERSONS_BOOK_F_COUNTRY'] = null;
 			vm.attribData['8858_NA_PERSONS_BOOK_F_ADDR_1'] = null;
 			vm.attribData['8858_NA_PERSONS_BOOK_F_ADDR_2'] = null;
 		    vm.attribData['8858_NA_PERSONS_BOOK_F_CITY'] = null;
 		    vm.attribData['8858_NA_PERSONS_BOOK_F_STATE'] = null;
 		    vm.attribData['8858_NA_PERSONS_BOOK_F_ZIP'] = null;
     		} else{ 
     			vm.attribData['8858_NA_PERSONS_BOOK_F_COUNTRY'] = vm.countryCust;
     			vm.attribData['8858_NA_PERSONS_BOOK_US_COUNTRY'] = null;
  			    vm.attribData['8858_NA_PERSONS_BOOK_US_ADDR_1'] = null;
  			    vm.attribData['8858_NA_PERSONS_BOOK_US_ADDR_2'] = null;
  			    vm.attribData['8858_NA_PERSONS_BOOK_US_CITY'] = null;
  			    vm.attribData['8858_NA_PERSONS_BOOK_US_STATE'] = null;
  			    vm.attribData['8858_NA_PERSONS_BOOK_US_ZIP'] = null;
     		  }	
          if((angular.isDefined(vm.selectedGroup) && vm.selectedGroup)){
 	      	   if(vm.selectedGroup.REG_ADDRESS_COUNTRY === 'US') {
	      		 vm.attribData['8858_NA_FILER_US_ADDR_1'] = vm.selectedGroup.FILER_ADDR_LINE1;
	   		     vm.attribData['8858_NA_FILER_US_ADDR_2'] = vm.selectedGroup.FILER_ADDR_LINE2;
	   		     vm.attribData['8858_NA_FILER_US_CITY'] = vm.selectedGroup.REG_ADDRESS_CITY;
	   		     vm.attribData['8858_NA_FILER_US_STATE'] = vm.selectedGroup.REG_ADDRESS_STATE;
	   		     vm.attribData['8858_NA_FILER_US_COUNTRY'] = vm.selectedGroup.REG_ADDRESS_COUNTRY;
	   		     vm.attribData['8858_NA_FILER_US_ZIP'] = vm.selectedGroup.REG_ADDRESS_ZIP;
	   		     vm.attribData['8858_NA_FILER_BEGIN_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_BEGIN;
	   		     vm.attribData['8858_NA_FILER_END_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_END;
	   		     vm.attribData['8858_NA_FILER_NAME_1'] = vm.selectedGroup.LEGAL_ENTITY_NAME;
    		     vm.attribData['8858_NA_FILER_LEGAL_ENTITY_ID'] = vm.selectedGroup.LEGAL_ENTITY_ID;
	   		     vm.attribData['8858_NA_FILER_EIN'] = vm.selectedGroup.FILER_EIN;
	   		     vm.attribData['8858_NA_FILER_F_ADDR_1'] = null;
	        	 vm.attribData['8858_NA_FILER_F_ADDR_2'] = null;
	        	 vm.attribData['8858_NA_FILER_F_CITY'] = null;
	        	 vm.attribData['8858_NA_FILER_F_STATE'] = null;
	        	 vm.attribData['8858_NA_FILER_F_COUNTRY'] = null;
	        	 vm.attribData['8858_NA_FILER_F_ZIP'] = null;
 	              } else {
	 	              	vm.attribData['8858_NA_FILER_F_ADDR_1'] = vm.selectedGroup.FILER_ADDR_LINE1;
	                 	vm.attribData['8858_NA_FILER_F_ADDR_2'] = vm.selectedGroup.FILER_ADDR_LINE2;
	                 	vm.attribData['8858_NA_FILER_F_CITY'] = vm.selectedGroup.REG_ADDRESS_CITY;
	                 	vm.attribData['8858_NA_FILER_F_STATE'] = vm.selectedGroup.REG_ADDRESS_STATE;
	                 	vm.attribData['8858_NA_FILER_F_COUNTRY'] =vm.selectedGroup.REG_ADDRESS_COUNTRY;
	                 	vm.attribData['8858_NA_FILER_F_ZIP'] = vm.selectedGroup.REG_ADDRESS_ZIP;
	                 	vm.attribData['8858_NA_FILER_BEGIN_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_BEGIN;
	          		    vm.attribData['8858_NA_FILER_END_DATE'] = vm.selectedGroup.FILER_TAX_YEAR_END;
	          		    vm.attribData['8858_NA_FILER_NAME_1'] = vm.selectedGroup.LEGAL_ENTITY_NAME;
	          		    vm.attribData['8858_NA_FILER_LEGAL_ENTITY_ID'] = vm.selectedGroup.LEGAL_ENTITY_ID;
	         		    vm.attribData['8858_NA_FILER_EIN'] = vm.selectedGroup.FILER_EIN;
	         		    vm.attribData['8858_NA_FILER_US_ADDR_1'] = null;
	          		    vm.attribData['8858_NA_FILER_US_ADDR_2'] = null;
	          		    vm.attribData['8858_NA_FILER_US_CITY'] = null;
	          		    vm.attribData['8858_NA_FILER_US_STATE'] = null;
	          		    vm.attribData['8858_NA_FILER_US_COUNTRY'] = null;
	          		    vm.attribData['8858_NA_FILER_US_ZIP'] = null;
 	          	   }
          }
         
          if (vm.radioIn === 'I'){
    			vm.attribData['8858_NA_INIT_FORM_Y'] = '1';
    			vm.attribData['8858_NA_INIT_FORM_N'] = null;
    			vm.attribData['8858_NA_FINAL_FORM_Y'] = null;
    			vm.attribData['8858_NA_FINAL_FORM_N'] = '0';
    			
    		}else if (vm.radioIn === 'F') {
    			vm.attribData['8858_NA_FINAL_FORM_Y'] = '1';
      			vm.attribData['8858_NA_FINAL_FORM_N'] = null; 
    			vm.attribData['8858_NA_INIT_FORM_N'] = '0';
        		vm.attribData['8858_NA_INIT_FORM_Y'] = null;
    		}
    		else if (vm.radioIn === 'N') {
    			vm.attribData['8858_NA_FINAL_FORM_Y'] = null;
      			vm.attribData['8858_NA_FINAL_FORM_N'] = null; 
    			vm.attribData['8858_NA_INIT_FORM_N'] = null;
        		vm.attribData['8858_NA_INIT_FORM_Y'] = null;
    		}
        		
          
            if(vm.irsF8858ScheduleNAData.length > 0) {
                var saveObject = [];
                var temporaryObject = vm.irsF8858ScheduleNAData;

                for (var loopIndex = 1; loopIndex < temporaryObject.length; loopIndex++){
                   
                    saveObject = [{
                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                        "trans_type_key":temporaryObject[loopIndex].trans_type_key,
                        "combination_key": vm.rowData.COMBINATION_KEY,
                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                        "form_key":temporaryObject[loopIndex].form_key,
                        "line_no":temporaryObject[loopIndex].i.line_no,
                        "occurence":((temporaryObject[loopIndex].occurrence === "0") ? null : temporaryObject[loopIndex].occurrence),
                        "line_attrib_key" : temporaryObject[loopIndex].line_attrib_key,
                        "Attribute_Name": temporaryObject[loopIndex].attrib_name,
                        "Attribute_Value":((vm.attribData[temporaryObject[loopIndex].attrib_name] === '' )? '' :vm.attribData[temporaryObject[loopIndex].attrib_name]),//vm.attribData[temporaryObject[loopIndex].attrib_name],
                        "trans_dtls_key" : ((temporaryObject[loopIndex].trans_details_key === "0") ? null : temporaryObject[loopIndex].trans_details_key),
                        "trans_status":"A",
                        "override_y_n":((temporaryObject[loopIndex].is_update === undefined)? 'N' : temporaryObject[loopIndex].is_update)
                    }];
                    editRow.push(saveObject);
                }

            }

           

           // if(vm.isSaveValidFlagNA){
                var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                var message = "Textual Info details successfully saved";


                scheduleGGroupObjFactory.saveScheduleNA(scheduleSettings,
                    scheduleDetails).then(
                    function(result) {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error", result.errorMessage, 4000);
                          
                        } else {
                            vm.saveFlag = true;
                            AlertService.add("success", message, 4000);
                            getFormData();
                          
                        }
                    });
            /*}
            else{
                AlertService.add("error", vm.invalidSaveMessage, 6000);
               
            }*/
        };

        
        /* save from workflow 5/14/2019 */
        $scope.$on('workflowSave',function(event,data){
            vm.saveScheduleGData();
        })
        
        vm.saveScheduleGData = function(scheduleGIForm)
        {
        	 vm.saveForm();
        	 if ($scope.crudLoading) {
	                AlertService.add("info", "Please wait while we save this request", 4000);
	                return;
	            }
			 $scope.crudLoading = true;
			 
			 if (vm.error_flag == true){
				$scope.crudLoading = false;
				AlertService.add("error", "Please enter Identifying number or EIN missing reason Or Reference ID number ",4000);
				return;
			 }
    		
    			var editRow = [];
            	var addRow = [];
            	var deleteRow = [];
            	var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
              		  					"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
              		  					"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key}];
            	
            	
            	if($scope.scheduleGData.length > 0) {
                    //console.log("This is the length", $scope.irsF5471ScheduleGData.length);
                    var saveObject = [];
                    var temporaryObject = $scope.scheduleGData;
                    //console.log("Temporary object", temporaryObject);

                    /*for (var loop = 0; loop < temporaryObject.length; loop++){                    		 
                        saveObject = [{
                        	"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                            "trans_type_key":temporaryObject[loop].saveParams.trans_type_key,
                            "combination_key":vm.rowData.COMBINATION_KEY,
							"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
                            "form_key":temporaryObject[loop].saveParams.form_key,
                            "line_no":temporaryObject[loop].line_no,
                            "occurence":((temporaryObject[loop].saveParams.occurrence == "0") ? null : temporaryObject[loop].saveParams.occurrence),
                            "line_attrib_key" : temporaryObject[loop].value.line_attrib_key,
                            "Attribute_Name": temporaryObject[loop].attrib_name,
                            "Attribute_Value":temporaryObject[loop].value.VALUE,
                            "trans_dtls_key" : ((temporaryObject[loop].saveParams.trans_Details_key == "0") ? null : temporaryObject[loop].saveParams.trans_Details_key),
                            "trans_status":"A"
                        }];
                        editRow.push(saveObject);*/
                    for (var loopIndex = 0; loopIndex < temporaryObject.length; loopIndex++){
                    	if (temporaryObject[loopIndex].hasOwnProperty('propertyType') &&
                        		temporaryObject[loopIndex].propertyType==13) {
                            for (var k=0; k < temporaryObject[loopIndex].attrib_name.length; k++) {
                                    saveObject = [{
                                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                                        "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
                                        "combination_key": vm.rowData.COMBINATION_KEY,
                                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                                        "form_key":temporaryObject[loopIndex].saveParams.form_key,
                                        "line_no":temporaryObject[loopIndex].line_no,
                                        "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
                                        "line_attrib_key" : temporaryObject[loopIndex].value[k].line_attrib_key,
                                        "Attribute_Name": temporaryObject[loopIndex].attrib_name[k],
                                        "Attribute_Value":temporaryObject[loopIndex].value[k].VALUE,
                                        "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
                                        "trans_status":"A"
                                    }];
                                    editRow.push(saveObject);
                            }
                        	
                        } else {
    	                    saveObject = [{
    	                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
    	                        "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
    	                        "combination_key": vm.rowData.COMBINATION_KEY,
    	                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
    	                        "form_key":temporaryObject[loopIndex].saveParams.form_key,
    	                        "line_no":temporaryObject[loopIndex].line_no,
    	                        "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
    	                        "line_attrib_key" : temporaryObject[loopIndex].value.line_attrib_key,
    	                        "Attribute_Name": temporaryObject[loopIndex].attrib_name,
    	                        "Attribute_Value":temporaryObject[loopIndex].value.VALUE,
    	                        "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
    	                        "trans_status":"A"
    	                    }];
    	                    editRow.push(saveObject);
                        }
                    
                    }
                    //console.log("This is editRow {{{{{}}}}}", editRow);

                }
            	if($scope.scheduleIData.length) {
            		 var saveObject = [];
                     var temporaryObject = $scope.scheduleIData;
                	
                	for(var loopIndex = 0; loopIndex < temporaryObject.length; loopIndex++)
                	{
                		if (temporaryObject[loopIndex].hasOwnProperty('propertyType') &&
                    		temporaryObject[loopIndex].propertyType==13) {
                		for (var k=0; k < temporaryObject[loopIndex].attrib_name.length; k++) {
                		var tempObj = [{
                				"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
    							"trans_type_key": temporaryObject[loopIndex].saveParams.trans_type_key,
    							"combination_key":vm.rowData.COMBINATION_KEY,
								"group_obj_key":vm.rowData.GROUP_OBJ_KEY,
    							"form_key":temporaryObject[loopIndex].saveParams.form_key,
    							"line_no": temporaryObject[loopIndex].line_no,
    							"occurence":(( temporaryObject[loopIndex].saveParams.occurrence == "0") ? null :  temporaryObject[loopIndex].saveParams.occurrence),
    							"line_attrib_key" :  temporaryObject[loopIndex].value[k].line_attrib_key,
    							"Attribute_Name":  temporaryObject[loopIndex].attrib_name[k],
    							"Attribute_Value":temporaryObject[loopIndex].value[k].VALUE,
    							"trans_dtls_key" : (( temporaryObject[loopIndex].saveParams.trans_Details_key == "0") ? null :  temporaryObject[loopIndex].saveParams.trans_Details_key),
    							"trans_status":"A"}];
                    		
                    		editRow.push(tempObj);
                		
                	}
                		}
                	
                   else {
     	                    saveObject = [{
     	                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
     	                        "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
     	                        "combination_key": vm.rowData.COMBINATION_KEY,
     	                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
     	                        "form_key":temporaryObject[loopIndex].saveParams.form_key,
     	                        "line_no":temporaryObject[loopIndex].line_no,
     	                        "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
     	                        "line_attrib_key" : temporaryObject[loopIndex].value.line_attrib_key,
     	                        "Attribute_Name": temporaryObject[loopIndex].attrib_name,
     	                        "Attribute_Value":temporaryObject[loopIndex].value.VALUE,
     	                        "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
     	                        "trans_status":"A"
     	                    }];
     	                    editRow.push(saveObject);
                         }
                     
            	}
			        }
			       
            
        		
        		vm.checkingForValidSave($scope.scheduleGData,"G");
        		vm.checkingForValidSave($scope.scheduleIData ,"I");
        		if(vm.isSaveValidFlagG && vm.isSaveValidFlagI){
        			var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                	console.log("scheduleGIDetails-----------------------", scheduleDetails);
            		var message = "Schedule G and I details are sucessfully save/edit";            		
        			
	        		scheduleGGroupObjFactory.saveScheduleG(scheduleSettings,
	    					scheduleDetails).then(
	    					function(result) {
	
	    						if (result.errorMessage
	    								&& result.errorMessage !== 'null') {
	    							AlertService.add("error",
	    									result.errorMessage, 4000);
	    							$scope.crudLoading = false;
	    						} else {
	    							vm.saveFlag = true;
	    							AlertService.add("success", message, 4000);
	    							$scope.crudLoading = false;
	    							
	    							
	    						}
	    					});
        		}else{
    				$scope.crudLoading = false;
        			if(!vm.isSaveValidFlagG && !vm.isSaveValidFlagI){
        				AlertService.add("error", vm.invalidSaveMessageG +"\n" + vm.invalidSaveMessageI , 6000)
        				
        			}else if(!vm.isSaveValidFlagG){
        				AlertService.add("error", vm.invalidSaveMessageG , 6000);
        				
        			}else if(!vm.isSaveValidFlagI){
        				AlertService.add("error", vm.invalidSaveMessageI , 6000);
        				
        			}
        			
        		}
    		
        }
        
        vm.onRadioBtnSelection = function(row, index, value) {
        	if (value === 'Y') {
        		row.value[1].VALUE = null;
        	} else if (value === 'N') {
        		row.value[0].VALUE = null;
        	}
        }
        
        vm.checkingForValidation = function (row, index){
            //console.log(index);
            console.log("Row for Testing", row);
            if(row.propertyType == '13'){
	 			var col_type = 'radio'
			}
			else if(row.propertyType == '7'){
				var col_type =  'amount';
			}
            
            
            if(col_type == 'radio'){
			 var occList = _.filter($scope.scheduleGData, function(o) { 
						return (o.parent_line_no  == row.line_no )
					});               		
		      }
		else if ((col_type == 'radio' && row.line_no!== row.parent_line_no) || (col_type == 'amount')){
			var occList = _.filter($scope.scheduleGData,{line_no:row.line_no}) 
			
			};
			
		console.log("acctlist---------", occList);
		   occList.forEach(function(currentObj){
			if(currentObj['line_no'] == currentObj['parent_line_no'] && currentObj['col_id'] == currentObj['parent_col_id']
			&& currentObj['line_type'] !== 'dcl'){
			return;
			}
			var attribute = currentObj['attrib_name'];
			var col_id = currentObj['col_id'];
			var parent_col_id = currentObj['parent_col_id'];
		
           if(col_type == 'radio' && row.line_type !== 'dcl'){
						if(row.value[1].VALUE == 'N'&& col_id == parent_col_id){							
						vm.findIndex_1($scope.scheduleGData, attribute,'N','Y');						
						}
						else if (row.value[0].VALUE == 'Y'&& col_id == parent_col_id ) 
						{
						vm.findIndex_1($scope.scheduleGData, attribute,'Y','N');
						}
						
						
	       }
	       
	       if(col_type == 'amount' && row.line_type !== 'dcl'){
			 vm.validLine_no =  currentObj['parent_line_no'];
		         vm.findLineNoData = $scope.scheduleGData.find(x => x.line_no === vm.validLine_no);
		         if ((vm.findLineNoData.value[0].VALUE == undefined &&  vm.findLineNoData.value[1].VALUE == undefined) ||
		         	(vm.findLineNoData.value[0].VALUE == null &&  vm.findLineNoData.value[1].VALUE == null)){
					 vm.findIndex_1($scope.scheduleGData, attribute,'Y','Y');
					 }
				 if (vm.findLineNoData.value[1].VALUE !== undefined &&  vm.findLineNoData.value[1].VALUE !== null){
					 vm.findIndex_1($scope.scheduleGData, attribute,'N','Y');
					 }
				if (vm.findLineNoData.value[0].VALUE !== undefined &&  vm.findLineNoData.value[0].VALUE !== null){
					 vm.findIndex_1($scope.scheduleGData, attribute,'Y','Y');
					 }	 	 
		
			  /*if(row.value.VALUE !== undefined || row.value.VALUE !== null){							
				   vm.findIndex_1($scope.scheduleGData, attribute,'Y','Y');						
				}
			if(row.value.VALUE == undefined || row.value.VALUE == null){							
				   vm.findIndex_1($scope.scheduleGData, attribute,'N','Y');						
				}	*/
						
						
	       }
	       
	       if (row.line_type == 'dcl'){			
			   vm.findIndex_1($scope.scheduleGData, attribute,'N','Y');		
	          }	       
	        });	       
	        vm.findIndex_1 = function(data, attribName,value,saveFlag){
            	var findData = data.find(x => x.attrib_name === attribName);
            		if ((findData.col_id == findData.parent_col_id)&& (findData.line_type !== 'dcl')){
						if(findData.value.VALUE !== undefined && findData.value.VALUE !== null){
							findData.isEditableFlag = value;
							findData.isSaveValidFlagG = 'Y';
							}
							else{
							findData.isEditableFlag = value;
							findData.isSaveValidFlagG = saveFlag;			
							}				
						if(value == 'N'){
							findData.isEditableFlag = 'N';
							findData.value.VALUE = null;	
							findData.isSaveValidFlagG == 'Y';				    
						if (angular.isDefined(findData.value[1]) && findData.value[1].VALUE !== undefined ){
						    findData.value[0].VALUE = null;
						  };
						}
						
						if (angular.isDefined(findData.value[0]) && findData.value[0].VALUE !== undefined){
						findData.value[1].VALUE = null;
						  };
						
					}
					if(findData.line_type == 'dcl'){
						
						findData.isEditableFlag = 'N';
						findData.isSaveValidFlagG == 'Y';
					}
				
					
					
            		
            	    return findData;
      	   }
        
        }
          
       
        
       
        vm.checkingForValidationForI = function (row, index){
        	
        	 if(row.line_no === '1'){
                 if(row.value[1].VALUE === 'N'){
                 	$scope.scheduleIData[1].isEditableFlag = 'N';
                     $scope.scheduleIData[1].value[1].VALUE = null;
                     $scope.scheduleIData[1].value[0].VALUE = null;
                     $scope.scheduleIData[2].isEditableFlag = 'N';
                     $scope.scheduleIData[2].value[1].VALUE = null;
                     $scope.scheduleIData[2].value[0].VALUE = null;
                     $scope.scheduleIData[3].isEditableFlag = 'N';
                     $scope.scheduleIData[3].value.VALUE = null;
                     
                 }
                 else if(row.value[0].VALUE === 'Y'){
                 	$scope.scheduleIData[1].isEditableFlag = 'Y';
                     $scope.scheduleIData[2].isEditableFlag = 'Y';
                     $scope.scheduleIData[3].isEditableFlag = 'Y';
               
                 }
             }
        	 
        	 if(row.line_no === '2'){
                 if(row.value[1].VALUE === 'N'){
                     $scope.scheduleIData[2].isEditableFlag = 'N';
                     $scope.scheduleIData[2].value[0].VALUE = null;
                     $scope.scheduleIData[3].isEditableFlag = 'N';
                     $scope.scheduleIData[2].value[1].VALUE = null;
                     $scope.scheduleIData[3].value.VALUE = null;
                     
                 }
                 else if(row.value[0].VALUE === 'Y'){
                     $scope.scheduleIData[2].isEditableFlag = 'Y';
                     $scope.scheduleIData[3].isEditableFlag = 'Y';
               
                 }
             }
             
           
            if(row.line_no === '3'){
                if(row.value[1].VALUE === 'N' ||  $scope.scheduleIData[0].value.VALUE === 'N' ||  $scope.scheduleIData[1].value.VALUE === 'N'){
                    $scope.scheduleIData[3].isEditableFlag = 'N';
                    $scope.scheduleIData[3].value.VALUE = null;
                    
                }
                else if(row.value[0].VALUE === 'Y'){
                    $scope.scheduleIData[3].isEditableFlag = 'Y';
              
                }
            }
           
        };
        
        vm.checkingForValidSave = function (scope,scheduleType) {
            //console.log("This is scope man ----------", scope);
           
           
            if(scheduleType == "G"){
	
				 for(var i = 0;i<$scope.scheduleGData.length;i++){
                if ($scope.scheduleGData[i].isSaveValidFlagG == 'N'){
				vm.isSaveValidFlagG = false;
				vm.invalidSaveMessageG = "Please check the below errors"
				AlertService.add("error", vm.invalidSaveMessageG, 6000);
			    return;
					}
				else vm.isSaveValidFlagG = true;	
               }
            	/* vm.isSaveValidFlagG = true;
            	vm.invalidSaveMessageG = "Error saving Schedule G data - ";
                if(scope[5].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[6].value.VALUE) || scope[6].value.VALUE === null) || 
                		 (!angular.isDefined(scope[7].value.VALUE) || scope[7].value.VALUE === null))){
                    vm.isSaveValidFlagG = false;
                    vm.invalidSaveMessageG += "Check values for lines 6b and 6C. ";
                }

                if(scope[8].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[9].value.VALUE) || scope[9].value.VALUE === null) || 
               		 (!angular.isDefined(scope[10].value.VALUE) || scope[10].value.VALUE === null))){
                   vm.isSaveValidFlagG = false;
                   vm.invalidSaveMessageG += "Check values for lines 7b and 7c. ";
               }

                if(scope[13].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[14].value.VALUE) || scope[14].value.VALUE === null))){
                      vm.isSaveValidFlagG = false;
                      vm.invalidSaveMessageG += "Check values for lines 10b ";
                  }
                
                if(scope[15].value[0].VALUE === 'Y' &&  ((!angular.isDefined(scope[16].value.VALUE) || scope[16].value.VALUE === null) || 
                  		 (!angular.isDefined(scope[17].value.VALUE) || scope[17].value.VALUE === null))){
                    vm.isSaveValidFlagG = false;
                    vm.invalidSaveMessageG += "Check values for lines 11b ans 11c ";
                }
                
                if(scope[21].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[22].value.VALUE) || scope[22].value.VALUE === null))){
                    vm.isSaveValidFlagG = false;
                    vm.invalidSaveMessageG += "Check values for lines 12e ";
                }
                
                if(scope[23].value[0].VALUE === 'Y' && ((!angular.isDefined(scope[24].value.VALUE) || scope[24].value.VALUE === null))){
                    vm.isSaveValidFlagG = false;
                    vm.invalidSaveMessageG += "Check values for lines 13b ";
                }
                return vm.isSaveValidFlagG;*/
                
            }else if(scheduleType == "I"){
            	 vm.isSaveValidFlagI = true;
            	vm.invalidSaveMessageI = "Error saving Schedule I data - ";
            	if(scope[2].value.VALUE === 'Y' && ((!angular.isDefined(scope[3].value.VALUE) || scope[3].value.VALUE === null))){
                    vm.isSaveValidFlagI = false;
                    vm.invalidSaveMessageI += "Check values for lines 4 ";
                }
            	 return vm.isSaveValidFlagI;
            }

           
        }; 
        
    }
});