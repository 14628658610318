define([
  'angular'

], function () {
  'use strict';

  var services =  angular.module('app.localSysChartAcctMapDescService',[])
      .factory("LocalDescGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
          ,'$q', '$http',
          '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG'
         
          ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                  ,$q, $http,
                   $stateParams, $timeout, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
          var factory = {};
          var returnObj = {};
          var localDescGroupObjFactory ={};
          localDescGroupObjFactory.url ={};
          localDescGroupObjFactory.url.local_to_system_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hhba22"

              localDescGroupObjFactory.getAccntDetails = function(_data){
              console.log("_data ::",_data);
              var params= {
                      "action_code": 'hhba22',
                      "action_id":374,
                      "OBJECT_ID": _data.OBJECT_ID
              }



                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                    console.log("groupObjFactory.getAccntDetails() data::",data);

                    if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                       AlertService.add("error", data.errorMessage);
                       return false;
                   }else{
                       return data.jsonObject;
                   }
               });


             }
          localDescGroupObjFactory.saveLocalDesc = function(_data,Details){

              var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
              var jsonObj = JSON.stringify(Details);
              var jsonSettings =  JSON.stringify(_data);
              //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings

              console.log(jsonSettings);
              var params = filterParams;
              params = _.merge({},  GlobalService.globalParams , params);
              params =  _.extend({jsonObj:jsonObj}, params);
              params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
              params.process_name =  "LOCAL_DESC_SAVE";
              console.log(params);
              var promise = $http({
                  method: "post",
                  url: localDescGroupObjFactory.url.local_to_system_save,
                  data: params
              }).then(function (response) {
                  var status = response.status;
                  return response.data;
              });

              return promise;

          };

return localDescGroupObjFactory;
      }]);

  return services;
});