
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.SpecialAllocationService',[])

    .factory('SpecialAllocationServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var specialAllocationServiceFactory = {};
        specialAllocationServiceFactory.url = {};
        specialAllocationServiceFactory.url.attribute_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ss9ljh";
        specialAllocationServiceFactory.url.beat_pship_ptr_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=al5qkz";

        specialAllocationServiceFactory.saveDefaults = function(_data,Details){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "pship-ptr-grid-save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: specialAllocationServiceFactory.url.attribute_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        specialAllocationServiceFactory.saveBeatPtrRatio = function(_data,Details){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "beat-pship-ptr-grid-save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: specialAllocationServiceFactory.url.beat_pship_ptr_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        return specialAllocationServiceFactory
    }])

    return services;

});