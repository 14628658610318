
define([
    'angular',
    './GoldController',
    './GoldService'
    

], function () {
    'use strict';
    return angular.module('app.gold', ['app.GoldController','app.goldService'])
        .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {

            $stateProvider
                .state('gold', {
                    url: '/gold',
                    templateUrl: 'app/components/gold/gold.html',
                    data:{},
                    access:""
                })
        }])

});