			define([
			'angular'
			
			
			
			], function () {
			'use strict';
			
			
			
			var services = angular.module('app.firEpBumpSummaryService',[])
			.factory("firEpBumpSummaryServiceFactory",['$q','$http','$rootScope','$log','JsonObjectFactory','AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams','GENERAL_CONFIG', function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
			var firEpBumpSummaryServiceFactory = {};
			firEpBumpSummaryServiceFactory.url = {};
			var returnObj = {};
			var logged_in_user = USER_SETTINGS.user.sso_id;
			
			firEpBumpSummaryServiceFactory.updateIndicator = function(_data){
			console.log("data", _data);
			firEpBumpSummaryServiceFactory.url.url_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=wa7y6d";
			
			$rootScope.$broadcast('gridRefresh', false);
			
			var jsonObj = _data;
			var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
			var jsonSettings = [{'tax_year':filterParams.tax_year, 'scenario':filterParams.scenario, 'jcd_key':(GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
			//var saveData = true;
			var params = _.merge({}, GlobalService.globalParams, filterParams);
			params = _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
			params = _.extend({jsonSettings:JSON.stringify(jsonSettings)}, params);
			//params.object_id = _data.rowData.object_id;
			// params.sso_id = logged_in_user;
			
			params.process_name = "Save/Update First Tier 965 Indicator";
			
			var promise = $http({
			method: "post",
			url: firEpBumpSummaryServiceFactory.url.url_save,
			data: params
			}).then(function (response) {
			var status = response.status;
			return response;
			});
			return promise;
			}
			
			return firEpBumpSummaryServiceFactory;
			}]);
			
			
			
			return services;
			});