/**
 * Created by 212544474 on 2/17/2016.
 */

 define(
	[ 'angular'],
	function() {
		'use strict';

		var services = angular
				.module('app.consolGroupEditService', [])

				.factory("ConsolGroupEditFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'AlertService', '$injector', 'DataFiltersFactory', 'workspaceFactory', 'GENERAL_CONFIG','USER_SETTINGS',
					function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, AlertService, $injector, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,USER_SETTINGS) {
						var consolGroupEditFactory = {};
						consolGroupEditFactory.url = {};
						consolGroupEditFactory.url.getTaxYears = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=i0gxca";//Action id 32106
						consolGroupEditFactory.url.getRAFScenario = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=lkh2g7";//Action id 32145
						consolGroupEditFactory.url.getHeadleid = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=0lvk86";//Action id 32107
						consolGroupEditFactory.url.getCurrencies = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=3ucwjb";//Action id 158
						consolGroupEditFactory.url.getTaxOwners = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=fzyjyg";//Action id 32110
						consolGroupEditFactory.url.getNonMembersList = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=th6d65";//Action id 32117
						consolGroupEditFactory.url.getMembersList = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=x91mvh";//Action id 32118
						consolGroupEditFactory.url.getCountryList = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=a4wyxl";//Action id 31651						
						
						consolGroupEditFactory.url.getMembersConsolAmts = GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=gfb8hh";//Action id 32124
						consolGroupEditFactory.url.saveUpdateConsolGroups = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=01adx5";//Action id 32112
						consolGroupEditFactory.url.saveUpdateConsolMembers = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0b0lfy";//Action id 32122
						consolGroupEditFactory.url.saveUpdateConsolGroupAmts = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9khqkb";//Action id 32123
						consolGroupEditFactory.url.saveUpdateTaxOwners = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=cbds5v";//Action id 32392
						
						consolGroupEditFactory.getScreenFilters = function() {
							var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
									.getFilterParams(): {};
							console.log('workspaceFactory"',workspaceFactory.activeScreen);
							return filterParams;
						};

						
						consolGroupEditFactory.getTaxYears = function() {
							var params = {};
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getTaxYears,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.getRAFScenario = function(passedParams) {
							var params = passedParams;
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getRAFScenario,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.getHeadleid = function(passedParams) {
							var params = passedParams;
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getHeadleid,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.getCurrencies = function(passedParams) {
							var params = passedParams;
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getCurrencies,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.getTaxOwners = function() {
							var params = {};
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getTaxOwners,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};
						
						consolGroupEditFactory.getNonMembersList = function(passedParams) {
							var params = passedParams;
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getNonMembersList,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.getMembersList = function(passedParams) {
							var params = passedParams;
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getMembersList,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.getCountryList = function(passedParams) {
							var params = passedParams;
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getCountryList,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.getMembersConsolAmts = function(passedParams) {
							var params = passedParams;
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.getMembersConsolAmts,
								data: params
							}).then(function (response) {
								//var status = response.status;
								return response.data;
							});
			
							return promise;
						};

						consolGroupEditFactory.saveUpdateGroups = function(_data, reqParams){

							var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
							var jsonObj = JSON.stringify(_data);
							var jsonSettings =  {};
							//var reqParams = JSON.stringify(reqParams);
							var params = reqParams;
							//params = _.merge({},  GlobalService.globalParams , params); 
							//params = _.merge({},  reqParams, params); 
							params =  _.extend({jsonObj:jsonObj}, params);
							params =  _.extend({jsonSettings:jsonSettings}, params);
							//params =  _.extend({jsonSettings:jsonSettings}, _data);
							params.process_name =  "FTR_CONSOL_GROUP_SAVE_EDIT";
							console.log("CONSOL GROUP params to save");
							console.log(params);
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.saveUpdateConsolGroups,
								data: params
							}).then(function (response) {
								var status = response.status;
								return response.data;
							});
			
							return promise;
			
						};

						consolGroupEditFactory.saveUpdateConsolMembers = function(_data, reqParams){

							var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
							var jsonObj = JSON.stringify(_data);
							var jsonSettings =  {};
			
							var params = reqParams;
							//params = _.merge({},  GlobalService.globalParams , params);
							//params = _.merge({},  reqParams, params);
							params =  _.extend({jsonObj:jsonObj}, params);
							params =  _.extend({jsonSettings:jsonSettings}, params);
							//params =  _.extend({jsonSettings:jsonSettings}, _data);
							params.process_name =  "FTR_CONSOL_MEMBERS_SAVE_EDIT";
							console.log("CONSOL GROUP params to save");
							console.log(params);
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.saveUpdateConsolMembers,
								data: params
							}).then(function (response) {
								var status = response.status;
								return response.data;
							});
			
							return promise;
			
						};
						
						consolGroupEditFactory.saveUpdateConsolGroupAmts = function(_data, reqParams){

							var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
							var jsonObj = JSON.stringify(_data);
							var jsonSettings =  {};

							var params = reqParams;
							//params = _.merge({},  GlobalService.globalParams , params);
							//params = _.merge({},  reqParams, params);
							params =  _.extend({jsonObj:jsonObj}, params);
							params =  _.extend({jsonSettings:jsonSettings}, params);
							//params =  _.extend({jsonSettings:jsonSettings}, _data);
							params.process_name =  "FTR_CONSOL_GROUP_AMTS_SAVE_EDIT";
							console.log("CONSOL GROUP params to save");
							console.log(params);
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.saveUpdateConsolGroupAmts,
								data: params
							}).then(function (response) {
								var status = response.status;
								return response.data;
							});
			
							return promise;
			
						};

						consolGroupEditFactory.saveUpdateTaxOwners = function(_data, reqParams){

							var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
							var jsonObj = JSON.stringify(_data);
							var jsonSettings =  {};

							var params = reqParams;
							//params = _.merge({},  GlobalService.globalParams , params);
							//params = _.merge({},  reqParams, params);
							params =  _.extend({jsonObj:jsonObj}, params);
							params =  _.extend({jsonSettings:jsonSettings}, params);
							//params =  _.extend({jsonSettings:jsonSettings}, _data);
							params.process_name =  "FTR_CONSOL_GROUP_TAX_OWNER_SAVE_EDIT";
							console.log("CONSOL GROUP params to save");
							console.log(params);
							var promise = $http({
								method: "post",
								url: consolGroupEditFactory.url.saveUpdateTaxOwners,
								data: params
							}).then(function (response) {
								var status = response.status;
								return response.data;
							});
			
							return promise;
			
						};

						return consolGroupEditFactory;
					}]);
				
				return services;

	});