/**
 * Created by 502117899 on 10/15/2015.
 */
define([
    'angular',
    './CodeCombinationsControllers',
    './CodeCombinationsServices',


], function () {
    'use strict';
   return angular.module('app.codeCombinations', ['app.codeCombinationsController','app.codeCombinationsServices'])
     //return angular.module('app.codeCombinations',[]);


       .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
           $stateProvider

               .state('codeCombinations', {
                   url: '/code-combinations',
                   templateUrl: 'app/components/codeCombinations/code-combinations-tpl.html',
                   access: ""
               })


       }])





    });