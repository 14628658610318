define(
        [ 'angular'

        ],
        function() {
            'use strict';
            var controllers = angular.module('app.AttributeBatchCtrl', [])
                    .controller(
                            'attributeBatchData',
                            [ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'GlobalService', 'AlertService','$timeout', 'USER_SETTINGS', 'workspaceFactory','GENERAL_CONFIG','$uibModalInstance', 'AttributeBatchServiceFactory', 'gridData',
                                    attributeBatchData ])
                    .controller(
                            'attributeLockUnlockData',
                            [ '$rootScope', '$scope', '$state', '$log','JsonObjectFactory', 'GlobalService', 'AlertService','$timeout', 'USER_SETTINGS', 'workspaceFactory','GENERAL_CONFIG','$uibModalInstance', 'AttributeBatchServiceFactory', 'gridData',
                                attributeLockUnlockData ])            

            /**
             * @Author: Harika Kadiyala
             */
            function attributeBatchData($rootScope, $scope, $state, $log,JsonObjectFactory, GlobalService, AlertService, $timeout, USER_SETTINGS, workspaceFactory, GENERAL_CONFIG,$uibModalInstance, AttributeBatchServiceFactory, gridData) {

                var vm = this;
                vm.loadingData = true;
                vm.userSettings = USER_SETTINGS;
                vm.logged_in_user = vm.userSettings.user.sso_id;
                /*vm.selectedBaseBasketCode = [];
                vm.selectedMethodCode = [];*/
                vm.selectedBaseFdiiCodesTemp = [];
                vm.selectedBaseBasketCodeTemp =[];
                vm.lockUnlockSelected = [];

                vm.baseBasketSelect = [];
                vm.baseTypeSelect = [];
                vm.allTransCategoryData = [];
                vm.allGPTransCategoryData =[];
                vm.baseTypeData=[];
                vm.selectedTempBaseTypeData=[];
                vm.attributeData = [];
                vm.sicCodeData = [];
                vm.isSaveClicked = false;
                vm.disableBaseLevel = false;
                vm.counter = { "ghost":0, "drd":0}
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                //Get the Default Current Year
                vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
                vm.scenario = filterParams.scenario;
                vm.QARScenario = 'N';
                vm.jcd_key  = GlobalService.globalParams.jcd_key  ;
                vm.countryCodeData = [];

                vm.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

                vm.attributeData = [{CODE_KEY:'METHOD & BASE',CODE_DESCRIPTION:'METHOD & BASE'},
                    {CODE_KEY:'SIC_CODE',CODE_DESCRIPTION:'SIC CODE'},{CODE_KEY:'BASKET',CODE_DESCRIPTION:'BASKET'},{CODE_KEY:'FDII_CODE',CODE_DESCRIPTION:'FDII'}
                    ,{CODE_KEY:'BEAT_CODE',CODE_DESCRIPTION:'BEAT'} ,{CODE_KEY:'COUNTRY_CODE',CODE_DESCRIPTION:'COUNTRY'}];


                //vm.attributeData = [{CODE_KEY:'METHOD & BASE',CODE_DESCRIPTION:'METHOD & BASE'}]  ;          



                //To get the Lock_Y_N flag from backend
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var lockparams = {
                        "action_code": 'n10cpv',
                        'p_scenario' : vm.scenario,
                        'p_jcd_key' :vm.jcd_key
                    };            
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
                    vm.is_locked = data.jsonObject;
                    $scope.$watch(function() { return vm.is_locked }, function() {
                        if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
                                vm.cancel();                                      
                                AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
                                return;
                        }  
                        if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].SCENARIO_DESC == 'EXAM'){
							vm.QARScenario = 'Y';
							/*if(vm.QARScenario == 'Y'){
								vm.cancel();										
								AlertService.add("error","Cannot Batch Update Tag for selected Scenario." ,'4000');
								return;
							}*/
						}
                    });
                });


                // On Load of modal.. it will put it in modal Table
                if (gridData != null) {

                    var tempGridData = _.clone(gridData), groups = Object
                            .create(null), result, finalKeyCheck = 0;

                    vm.gridDataMultipleSelect = _.clone(tempGridData);

                    if (vm.gridDataMultipleSelect != undefined) {  
                        console.log("gridDataMultipleSelect:: ", vm.gridDataMultipleSelect);
                        //To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
                        vm.is_return_filed = vm.gridDataMultipleSelect[0].data.IS_RETURN_FILED;
                        vm.is_locked = vm.gridDataMultipleSelect[0].data.STATUS_FLAG;

                        /*
                        if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked == 'Locked'){                              
                            AlertService.add("error", "You cannot perform this operation as the Tag is Locked.",'4000');
                            throw new Error("You cannot perform this operation as the Tag is Locked.");
                            return;                            
                        }*/

                        if(vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "EXPAA"){
                            vm.grossIncExp = _.filter(vm.gridDataMultipleSelect, function(o){
                                return o.data.GROSS_INC_EXP !== "EXPAA"
                            })
                        }else if(vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "ASTEXP"){
                            vm.grossAsset = _.filter(vm.gridDataMultipleSelect, function(o){
                                return o.data.GROSS_INC_EXP !== "ASTEXP"
                            })
                            vm.attributeData = [{CODE_KEY:'METHOD & BASE',CODE_DESCRIPTION:'METHOD & BASE'}]  ;

                             }else if(vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "INVESTMENTS"){
                                  vm.grossAsset = _.filter(vm.gridDataMultipleSelect, function(o){
                                      return o.data.GROSS_INC_EXP !== "INVESTMENTS"
                                   })
                              }else if(vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "INTEXP"){
                                   vm.grossIntExp = _.filter(vm.gridDataMultipleSelect, function(o){
                                       return o.data.GROSS_INC_EXP !== "INTEXP"
                                    })
                                }else if(vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "RESEARCH"){
                                     vm.grossResearch = _.filter(vm.gridDataMultipleSelect, function(o){
                                         return o.data.GROSS_INC_EXP !== "RESEARCH"
                                     })
                                 }else{
                                     vm.grossIncExpTrans = _.filter(vm.gridDataMultipleSelect, function(o){
                                         return o.data.GROSS_INC_EXP !== "GPROFIT" || o.data.TRANS_CATG_NAME !== "Prorate Variance"
                                      })
                                  }
                       

                        //Logic to Displaying the Warning Messages while LOCK' Tags exists
                        vm.isLockedWarningExists = false;                      
                        for ( var data in vm.gridDataMultipleSelect) {                                  
                            if(vm.gridDataMultipleSelect[data].data.STATUS_FLAG == 'Locked') {
                                 vm.isLockedWarningExists = true;                              
                            }
                        }
                        if(vm.isLockedWarningExists){
                            vm.isLockedWarningMsgs = "Cannot update the Locked Tag";
                        }


                        /*if(vm.grossIncExp && vm.grossIncExp.length > 0 || vm.grossIncExpTrans && vm.grossIncExpTrans.length > 0 || vm.grossAsset && vm.grossAsset.length > 0 ||
                                vm.grossIntExp && vm.grossIntExp.length > 0 || vm.grossResearch && vm.grossResearch.length > 0){
                            AlertService.add("error","You can not select Income or Gross Profit Non Prorate Tags ", 400000);                          
                            throw new Error("Please Select Only One Gross Inc/Exp!");
                            return;
                        }*/
                    }


                }

                function init() {
                }

                // getting all the FTC CODE MAST to populate in drop downs
                vm.getCodeMastData = function(onChangeCheck) {
                    var params = {
                        "action_code" : '31hizx',
                        'p_scenario':vm.scenario,
                        'p_jcd_key' :vm.jcd_key
                    };
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                            if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                                AlertService.add("error", data.errorMessage);
                                return false;
                            }else{

                                vm.baseFDIIData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['FDII_CODE'], p.CODE_GROUP);
                                    });
                                vm.baseBasketData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['SOURCE_CODE'], p.CODE_GROUP);
                                    });
                                _.remove(vm.baseBasketData, {CODE_DESCRIPTION: 'Default'});
                                vm.basketCodeAllocateData = _.filter(data.jsonObject, function(p){
                                    return _.includes(['SOURCE_CODE_ALLOCATE'], p.CODE_GROUP);
                                    });
                                _.remove(vm.basketCodeAllocateData, {CODE_DESCRIPTION: 'Default'});
                                vm.baseLevelData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['BASE_LEVEL_CODE'], p.CODE_GROUP);
                                    });
                                vm.baseEntityData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['BASE_ENTITY_CODE'], p.CODE_GROUP);
                                    });

                                vm.baseBasketTypeData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['BASE_BASKET_CODE'], p.CODE_GROUP);
                                });

                                vm.basefdiiSingleMultiData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['BASE_FDII_CODE'], p.CODE_GROUP);
                                });
                                vm.baseTypeCodeData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['BASE_TYPE_CODE'], p.CODE_GROUP);
                                });
                                vm.sicCodeData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['SIC_CODE'], p.CODE_GROUP);
                                    });
                                vm.countryCodeData = _.filter(data.jsonObject, function(p){
                                    return _.includes(['COUNTRY_CODE'], p.CODE_GROUP);
                                    });
                               /* vm.baseBasketDataAttributeInfo = _.filter(data.jsonObject, function(p){
                                      return _.includes(['SOURCE_CODE'], p.CODE_GROUP);
                                    });*/
                                
                                
                                vm.counter["ghost"] = _.filter(vm.gridDataMultipleSelect, function(p){
                                    return  p.data.MODULE_NAME == "GHOST"
                                 }).length;
                                
                                vm.counter["drd"] = _.filter(vm.gridDataMultipleSelect, function(p){
                                    return  p.data.MODULE_NAME == "DRD"
                                 }).length;
                                
                                if(vm.gridDataMultipleSelect.length == vm.counter["ghost"] || 
                                		vm.gridDataMultipleSelect.length == vm.counter["drd"] || 
                                		vm.gridDataMultipleSelect.length == vm.counter["drd"] + vm.counter["ghost"] ){
    								vm.baseBasketDataAttributeInfo = _.filter(data.jsonObject, function(p){
    									return _.includes(['SOURCE_CODE_ALLOCATE'], p.CODE_GROUP);
    									});
    							}else{
    						        vm.baseBasketDataAttributeInfo = _.filter(data.jsonObject, function(p){
    								return _.includes(['SOURCE_CODE'], p.CODE_GROUP);
    								});
    							     }
                                vm.baseFdiiDataAttributeInfo = _.filter(data.jsonObject, function(p){
                                      return _.includes(['FDII_CODE'], p.CODE_GROUP);
                                    });
                                vm.yesNoData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['YES_NO'], p.CODE_GROUP);  
			                		});
			                	vm.beatTypeData = _.filter(data.jsonObject, function(p){
			                		  return _.includes(['BEAT_TYPE_CODE'], p.CODE_GROUP);  
			                		});
                                /*vm.attributeData = _.filter(data.jsonObject, function(p){
                                      return _.includes(['Y'], p.BU_IND);
                                });*/


                                if(onChangeCheck!== false)
                                vm.getMethod();
                            }
                        });
                }

                //Getting the Method as it is not coming from FTC Code Mast Table      
                vm.getMethod = function() {

                    vm.gie_key = vm.gridDataMultipleSelect[0].data.GIE_KEY;
                    var params = {
                            "action_code" : 'lwtsx1',
                            "gie_key" : vm.gie_key,
                            'p_scenario' : vm.scenario
                        };
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                            if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                                AlertService.add("error", data.errorMessage);
                                return false;
                            }else{
                            	vm.methodCodeData = data.jsonObject;
                            	vm.getAllTransCatgExceptExp();
                            	if((vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "EXPAA" || 
                            			vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "ASTEXP" || 
                            			vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "INTEXP" ||
                            			vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP == "INVESTMENTS") 
                            			&& vm.QARScenario == 'Y'){
                            			for (var i = vm.methodCodeData.length - 1; i >= 0; i--) {
                            				if (vm.methodCodeData[i].CODE_DESCRIPTION !='Allocate') {
                            					vm.methodCodeData.splice(i,1);
                            		    }
                            		}
                            	}
                            }
                        });
                }

                vm.getAllTransCatgExceptExp = function() {
                    var params = {
                            "action_code" : '9aszao',
                            'p_scenario' : vm.scenario,
                            'p_jcd_key' :vm.jcd_key
                        };
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                            if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                                AlertService.add("error", data.errorMessage);
                                return false;
                            }else{
                                vm.allTransCategoryData = data.jsonObject;//For Expense A& A and Method= GROSS Income

                                //For Gross Profit and For Expense A & A and Method not equAL TO GROSS Income
                                vm.allGPTransCategoryData = _.filter(vm.allTransCategoryData, function(p){
                                    return _.includes(['GPROFIT'],  p.GIE_NAME);
                                    })
                                    if(vm.selectedGieName == 'EXPAA' || vm.selectedGieName == 'ASTEXP' || vm.selectedGieName == 'INVESTMENTS' ){

                                   //For Method Allocate and Gross Income, show only Gprofit transaction Category in Base Type dropdown
                                        if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION != 'Allocate' && vm.selectedMethodCode.CODE_DESCRIPTION != 'Gross Income'){
                                            vm.baseTypeData = vm.allGPTransCategoryData;
                                        }else if (vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION ==  'Gross Income'){
                                            vm.baseTypeData = vm.allTransCategoryData;
                                        }
                                        }else{
                                        vm.baseTypeData = vm.allGPTransCategoryData;
                                     }    


                            }
                        });
                }  


                vm.removeAttribBatchUpdateRow = function(rowData){                
                    var index = vm.gridDataMultipleSelect.indexOf(rowData);
                    vm.gridDataMultipleSelect.splice(index, 1);

                    vm.isLockedWarningExists = false;
                    vm.isLockedWarningMsgs = null;
                    for ( var data in vm.gridDataMultipleSelect) {  
                        console.log("  vm.gridDataMultipleSelect[data].data.STATUS_FLAG  : ", vm.gridDataMultipleSelect[data].data.STATUS_FLAG);
                        if(vm.gridDataMultipleSelect[data].data.STATUS_FLAG == 'Locked') {
                             vm.isLockedWarningExists = true;                          
                        }
                    }

                    console.log("  vm.isLockedWarningExists  : ", vm.isLockedWarningExists);
                    if(vm.isLockedWarningExists){
                        vm.isLockedWarningMsgs = "Cannot update the Locked Tag";
                    }
                };

                vm.changeSelectedBeatWithHold = function() {
					if ( vm.selectedBeatWithhold && (vm.selectedBeatWithhold < 0 || vm.selectedBeatWithhold > 100)) {
						AlertService.add("error", "Please select BEAT Withhold between 0 to 100 only ",5000);
						vm.isSaveClicked = true;
					} else {
						vm.isSaveClicked = false;
					}
				}
                
                vm.changeAttribute = function(){
                    let expectedGross_Inc_exp = ["EXPAA","ASTEXP","INVESTMENTS","INTEXP","RESEARCH","GPROFIT","Prorate Variance"];
                    let filterInfo,combinationFilterCheck,combinationFilterCheckGP,isErrorChangeSelection = false,
                    specific_type = vm.gridDataMultipleSelect ? vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP:null,
                            specific_tranc = vm.gridDataMultipleSelect ? vm.gridDataMultipleSelect[0].data.TRANS_CATG_NAME:null;

                    if(vm.selectedAttributes && vm.selectedAttributes.CODE_DESCRIPTION === 'METHOD & BASE') {
                        combinationFilterCheck = _.filter(vm.gridDataMultipleSelect, function(o){
                                return _.includes(specific_type, o.data.GROSS_INC_EXP );
                        });

                        filterInfo = _.filter(vm.gridDataMultipleSelect, function(o){
                            return _.includes(expectedGross_Inc_exp, o.data.GROSS_INC_EXP );
                        })

                        if(specific_type === "GPROFIT" ){
                            combinationFilterCheckGP = _.filter(vm.gridDataMultipleSelect, function(o){
                                return _.includes(specific_type, o.data.GROSS_INC_EXP ) && _.includes("Prorate Variance", o.data.TRANS_CATG_NAME );
                            });
                        }
                        if(filterInfo.length !== vm.gridDataMultipleSelect.length){                        
                            AlertService.add("error","You can not select Income or Gross Profit Non Prorate Tags ", 400000);
                            isErrorChangeSelection = true;
                        } else if(combinationFilterCheck.length !== vm.gridDataMultipleSelect.length){
                            AlertService.add("error","Select only GP Prorate Tags or Expense A&A ", 400000);
                            isErrorChangeSelection = true;
                        } else if(combinationFilterCheckGP && combinationFilterCheckGP.length !== vm.gridDataMultipleSelect.length){
                                AlertService.add("error","Select only GP Prorate Tags", 400000);
                                isErrorChangeSelection = true;
                        }else{
                            isErrorChangeSelection = false;
                        }

                        if(isErrorChangeSelection){
                            vm.isSaveClicked = true;
                        }else{
                            vm.isSaveClicked = false;
                        }



                        vm.getMethod();
                    }  

                    if(vm.selectedAttributes && vm.selectedAttributes.CODE_DESCRIPTION === 'SIC CODE') {
                        filterInfo = _.filter(vm.gridDataMultipleSelect, function(o){
                            return o.data.GIE_TYPE == "INC" || o.data.GROSS_INC_EXP === "RESEARCH" || (o.data.GIE_TYPE == "GP" && o.data.TRANS_CATG_NAME !== "Prorate Variance")
                        })
                        if(filterInfo.length !== vm.gridDataMultipleSelect.length){                        
                            AlertService.add("error","You can select Income, Research or Gross Profit Non Prorate Tags ", 400000);
                            vm.isSaveClicked = true;
                        }else{
                            vm.isSaveClicked = false;
                        }
                    }  

                    if(vm.selectedAttributes && (vm.selectedAttributes.CODE_DESCRIPTION === 'BASKET' || vm.selectedAttributes.CODE_DESCRIPTION === 'FDII')) {
                        filterInfo = _.filter(vm.gridDataMultipleSelect, function(o){
                            return o.data.GIE_TYPE == "INC" || (o.data.GIE_TYPE == "GP" && o.data.TRANS_CATG_NAME !== "Prorate Variance")
                        })
                        if(filterInfo.length !== vm.gridDataMultipleSelect.length){                        
                            AlertService.add("error","You can select Income or Gross Profit Non Prorate Tags ", 400000);
                            vm.isSaveClicked = true;
                        }else{
                            vm.isSaveClicked = false;
                        }
                    }
                    if(vm.selectedAttributes && (vm.selectedAttributes.CODE_DESCRIPTION === 'BEAT')) {
                        filterInfo = _.filter(vm.gridDataMultipleSelect, function(o){
                            return o.data.GIE_TYPE === "OTH_EXP" || o.data.GIE_TYPE === "EXP"; 
                        })
                        if(filterInfo.length !== vm.gridDataMultipleSelect.length){                        
                            AlertService.add("error","BEAT  is applicable only for Expense Tags ", 400000);
                            vm.isSaveClicked = true;
                        } else {
                        	vm.isSaveClicked = false;
                        }
                    }
                    
                    
                }

                //If GIE is INTEXP and Method is Interest Expense, we need to show base FDII , Base basket single select
                //If GIE is EXP A & A and Method is Gross Income, we need to show all the Inc and GP transaction categories in Base Type
                // If GIE is Exp A&A and Method is Allocate, Base FDII and Base Basket will be single select and Base Level, base Entity level and base Type will be disabled              
                vm.changeMethod = function(){

                    vm.displaySingleSelectBase='';
                    vm.gIncomeMethodFlag='';
                    vm.intExpHideBaseFields='false';
                    if(gridData[0].data.GIE_SHORT_DESC === "Gross Profit" && gridData[0].data.TRANS_CATG_NAME === "Prorate Variance"){
                        var exists = _.find(vm.baseLevelData, ['CODE_SHORT_DESC','ME']);
                        vm.selectedBaseLevelCode = exists ? exists.CODE_KEY:[]
                        var existNew = _.find(vm.baseEntityData, ['CODE_SHORT_DESC','COMPONENT']);
                        vm.selectedBaseEntityLevelCode = existNew ? existNew.CODE_KEY :[]
                        vm.disableBaseLevel = true;
                    }else{
                        vm.disableBaseLevel = false;
                        vm.selectedBaseLevelCode = [];
                        vm.selectedBaseEntityLevelCode = [];
                    }
                    if(vm.gridDataMultipleSelect[0].data != undefined){
                        vm.selectedGieName = vm.gridDataMultipleSelect[0].data.GROSS_INC_EXP;
                    }

                    if(vm.selectedGieName == 'EXPAA' || vm.selectedGieName == 'ASTEXP' || vm.selectedGieName == 'INVESTMENTS' ){

                   //For Method Allocate and Gross Income, show only Gprofit transaction Category in Base Type dropdown
                        if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION != 'Allocate' && vm.selectedMethodCode.CODE_DESCRIPTION != 'Gross Income'){
                            vm.gIncomeMethodFlag = 'false';
                            vm.baseTypeData = vm.allGPTransCategoryData;

                        }else if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION ==  'Allocate'){
                            vm.displaySingleSelectBase='true';
                            _.remove(vm.selectedBaseFdiiCodes, undefined);
                            _.remove(vm.selectedBaseBasketCode, undefined);
                        }else if (vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION ==  'Gross Income'){
                            vm.gIncomeMethodFlag = 'true';
                            vm.baseTypeData = vm.allTransCategoryData;

                        }
                    }
                    if(vm.selectedGieName == 'INTEXP' || vm.selectedGieName == 'RESEARCH'){

                       //For Method Allocate and Gross Income, show only Gprofit transaction Category in Base Type dropdown
                            if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION ==  'Allocate'){
                                vm.displaySingleSelectBase='true';
                                _.remove(vm.selectedBaseFdiiCodes, undefined);
                                _.remove(vm.selectedBaseBasketCode, undefined);
                            }else if (vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION ==  'Interest Expense' || vm.selectedMethodCode.CODE_DESCRIPTION ==  'Research Expense'){
                                vm.intExpHideBaseFields = 'true';
                            }
                        }
                }
                vm.resetBatch = function(){
                	vm.selectedAttributes = "";
                	vm.selectedBeatTypeCode = "";
                	vm.selectedBeatWithhold = "";
                    vm.selectedMethodCode = "";
                    vm.selectedBaseFdiiCodes = [];
                    vm.selectedBaseBasketCode = [];
                    vm.selectedBaseTypeCode =  [];
                    vm.selectedBaseLevelCode = [];
                    vm.selectedBaseEntityLevelCode = [];
                    vm.getCodeMastData();
                }

                $scope.$watchCollection(
                    function getValue() {
                            return( [ vm.selectedMethodCode ] );
                    },
                    function collectionChanged( newValue, oldValue ,$scope) {

                        $scope.$applyAsync(function () {
                        vm.selectedBaseFdiiCodes = [];
                        vm.selectedBaseBasketCode = [];
                        vm.selectedBaseTypeCode = [];
                        vm.selectedBaseLevelCode = [];
                        vm.selectedBaseEntityLevelCode = [];
                        vm.getCodeMastData(false);
                        vm.getAllTransCatgExceptExp();
                        if(gridData[0].data.GIE_SHORT_DESC === "Gross Profit" && gridData[0].data.TRANS_CATG_NAME === "Prorate Variance"){
                            var exists = _.find(vm.baseLevelData, ['CODE_SHORT_DESC','ME']);
                            vm.selectedBaseLevelCode = exists ? exists.CODE_KEY:[]
                            var existNew = _.find(vm.baseEntityData, ['CODE_SHORT_DESC','COMPONENT']);
                            vm.selectedBaseEntityLevelCode = existNew ? existNew.CODE_KEY :[]
                            vm.disableBaseLevel = true;
                        }else{
                            vm.disableBaseLevel = false;
                            vm.selectedBaseLevelCode = [];
                            vm.selectedBaseEntityLevelCode = [];
                        }
                        });
                    }
              );

            //This method will compare the default List with the main list of table for Base FDII and Base Basket.
//              vm.updateMultiselectData = function(){
//                    // console.log(vm.defaultBaseBasket);
//                    vm.selectedBaseFdiiCodes = [];
//                    vm.selectedBaseBasketCode = [];
//                    vm.selectedBaseTypeCode = [];
//                    var basketDataTemp = [],baseFdiiTemp = [];
//                    setTimeout(function(){
//                    if(vm.defaultBaseFdii && vm.baseFDIIData){
//                     vm.defaultBaseFdii.filter(function(defaultData){
//                        vm.baseFDIIData.filter(function(item){
//                            if(defaultData.CODE_DESCRIPTION === item.CODE_DESCRIPTION){
//                                 baseFdiiTemp.push(item);
//                            }
//                          
//                        });
//                    });
//                    vm.selectedBaseFdiiCodes = baseFdiiTemp;
//                    }
//                  
//                    if(vm.defaultBaseBasket && vm.baseBasketData){
//                    vm.defaultBaseBasket.filter(function(defaultData){
//                        return vm.baseBasketData.filter(function(item){
//                            if(defaultData.CODE_DESCRIPTION === item.CODE_DESCRIPTION){
//                                basketDataTemp.push(item);
//                            }
//                          
//                        })
//                    });;// vm.defaultBaseBasket; //vm.baseBasketData;
//                    vm.selectedBaseBasketCode = basketDataTemp;
//                    }
//                  
//                    vm.selectedBaseTypeCode = vm.baseTypeData;
//                    }, 100);
//                  
//                }
//            
//              // This is the function for selecting the base type of Gross Profit when Method is selected as Gross Income under Exp A&A      
//              vm.updateMethodMultiselectData = function(){
//                    vm.selectedBaseTypeCode = [];
//                    var basketDataTemp = [];
//                    setTimeout(function(){
//                    if(vm.baseTypeData && vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION === "Gross Income"){
//                         vm.baseTypeData.filter(function(item){
//                            if(item.GIE_DESC === "GROSS PROFIT"){
//                                basketDataTemp.push(item);
//                            }
//                          
//                        })
//                  
//                         vm.selectedBaseTypeCode = basketDataTemp;
//                    }else{
//                        vm.selectedBaseTypeCode = vm.baseTypeData;
//                    }
//                      
//                    }, 100);
//                }

          vm.getAttribMast = function() {
                var params = {
                        "action_code" : '2nk5nz',
                    };
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                        if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                            AlertService.add("error", data.errorMessage);
                            return false;
                        }else{
                            vm.attribMastData = data.jsonObject;
                        }
                    });
            }

            vm.getCodeMastData();
            vm.getAttribMast();

            vm.save = function() {
                vm.isSaveClicked = true;
                /*if (vm.crudloading){
                    AlertService.add("info", "Please wait while we save this request" , 1000);
                    return;
                }
                vm.crudloading = true;
                if(vm.gridDataMultipleSelect.length == 0 ){
                    AlertService.add("error", "Please select atleast one Attribute Management Mapping");
                    vm.crudLoading= false;
                    return;
                }*/



                if(vm.selectedAttributes && vm.selectedAttributes.CODE_DESCRIPTION === 'METHOD & BASE' && vm.selectedMethodCode  && vm.selectedMethodCode.CODE_DESCRIPTION == "Allocate"){
                    if(vm.selectedMethodCode == "" || vm.selectedBaseFdiiCodes == "" || vm.selectedBaseFdiiCodes == undefined || vm.selectedBaseBasketCode == "" || vm.selectedBaseBasketCode == undefined){
                        vm.isSaveClicked = false;
                        AlertService.add("error", "Please select all the attributes");
                        return;
                    }
                    _.remove(vm.selectedBaseTypeCode, undefined);
                }else{
                if(( vm.selectedAttributes &&  vm.selectedAttributes.CODE_DESCRIPTION === 'METHOD & BASE' &&  (vm.selectedMethodCode == undefined  || (vm.selectedAttributes && vm.selectedMethodCode.CODE_DESCRIPTION != "Interest Expense" && vm.selectedMethodCode.CODE_DESCRIPTION != "Research Expense")))){
                        if(vm.selectedMethodCode == undefined
                        || vm.selectedBaseFdiiCodes == undefined || (vm.selectedBaseFdiiCodes && vm.selectedBaseFdiiCodes.length == 0)
                        || vm.selectedBaseBasketCode == undefined  || (vm.selectedBaseBasketCode && vm.selectedBaseBasketCode.length == 0)
                        || vm.selectedBaseTypeCode == undefined || (vm.selectedBaseTypeCode && vm.selectedBaseTypeCode.length == 0)
                        || vm.selectedBaseLevelCode == undefined || (vm.selectedBaseLevelCode && vm.selectedBaseLevelCode.length == 0)
                        || vm.selectedBaseEntityLevelCode == undefined || (vm.selectedBaseEntityLevelCode && vm.selectedBaseEntityLevelCode.length == 0)){
                            vm.isSaveClicked = false;
                            AlertService.add("error", "Please select all the attributes");
                            return;
                        }
                    }
                }

                if(vm.selectedAttributes && vm.selectedAttributes.CODE_DESCRIPTION === 'SIC CODE' && vm.selectedSICCOde == undefined ){
                    vm.isSaveClicked = false;
                    AlertService.add("error", "Please select SIC Code");
                    return;
                }

                if(vm.selectedAttributes && vm.selectedAttributes.CODE_KEY === 'COUNTRY_CODE' && vm.selectedCountryCode == undefined ){
                    vm.isSaveClicked = false;
                    AlertService.add("error", "Please select Country Code");
                    return;
                }

                if(vm.selectedAttributes &&  vm.selectedAttributes.CODE_DESCRIPTION === 'BASKET' && vm.selectedBasketInfoByAttr == undefined ) {
                    vm.isSaveClicked = false;
                    AlertService.add("error", "Please select Basket");
                    return;
                }
                if(vm.selectedAttributes && vm.selectedAttributes.CODE_DESCRIPTION === 'FDII' && vm.selectedBaseFdiiInfoByAttr == undefined ) {
                    vm.isSaveClicked = false;
                    AlertService.add("error", "Please select FDII");
                    return;
                }
                
                if(vm.selectedAttributes && vm.selectedAttributes.CODE_DESCRIPTION === 'BEAT' && 
                		(vm.selectedBeatCode == undefined || vm.selectedBeatCode.CODE_DESCRIPTION == undefined ||   
                				(vm.selectedBeatCode.CODE_DESCRIPTION == 'Yes' && (vm.selectedBeatTypeCode === undefined || vm.selectedBeatTypeCode === ""  || vm.selectedBeatWithhold === undefined ||  vm.selectedBeatWithhold === null|| vm.selectedBeatWithhold === "")) ) ) {
                    vm.isSaveClicked = false;
                    AlertService.add("error", "Please select BEAT and its fields");
                    return;
                }
                
                if(vm.selectedAttributes && (vm.selectedAttributes.CODE_DESCRIPTION === 'BEAT') 
                		&&  (vm.selectedBeatCode.CODE_DESCRIPTION == 'Yes' ||  vm.selectedBeatCode.CODE_DESCRIPTION == 'No')) {
                    let filterInfo = _.filter(vm.gridDataMultipleSelect, function(o){
                        return o.data.GIE_TYPE === "OTH_EXP" || o.data.GIE_TYPE === "EXP"; 
                    })
                    if(filterInfo.length !== vm.gridDataMultipleSelect.length){                        
                        AlertService.add("error","BEAT is applicable only for Expense Tags ", 400000);
                        vm.isSaveClicked = false;
                        return;
                    }
                }
                var returnClobSettingsObj = {};
                var attribManagementBatchUpdateDetails = [];
                var returnObj = {};

                var baseTypeSelected ='';
                for ( var data in vm.selectedBaseTypeCode) {
                    if (vm.selectedBaseTypeCode[data] != undefined && vm.selectedBaseTypeCode[data].CODE_KEY != undefined) {
                        baseTypeSelected = baseTypeSelected.concat(vm.selectedBaseTypeCode[data].CODE_KEY);
                        baseTypeSelected = baseTypeSelected.concat(",");
                    }
                }
                if(baseTypeSelected != ''){
                    returnObj['BASE_TYPE_DATA'] = baseTypeSelected.substring(0,(baseTypeSelected.length -1));
                }


                if(vm.displaySingleSelectBase == 'true'){

                    returnObj['BASE_FDII_DATA'] = vm.selectedBaseFdiiCodes;
                    var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','SINGLE']);
                    if(exists != undefined)
                        returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;

                    returnObj['BASE_BASKET_DATA'] = vm.selectedBaseBasketCode;
                    var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','SINGLE']);
                    if(exists != undefined)
                        returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;

                }else{

                    if(vm.intExpHideBaseFields == 'false'){

                        var baseFdiiSelected ='';

                        for ( var data in vm.selectedBaseFdiiCodes) {
                            if (vm.selectedBaseFdiiCodes[data] != undefined && vm.selectedBaseFdiiCodes[data].CODE_KEY != undefined) {
                                baseFdiiSelected = baseFdiiSelected.concat(vm.selectedBaseFdiiCodes[data].CODE_KEY);
                                baseFdiiSelected = baseFdiiSelected.concat(",");
                            }
                        }
                        returnObj['BASE_FDII_DATA'] = baseFdiiSelected.substring(0,(baseFdiiSelected.length -1));

                        var baseBasketSelected ='';
                        for ( var data in vm.selectedBaseBasketCode) {
                            if (vm.selectedBaseBasketCode[data] != undefined && vm.selectedBaseBasketCode[data].CODE_KEY != undefined) {
                                baseBasketSelected = baseBasketSelected.concat(vm.selectedBaseBasketCode[data].CODE_KEY);
                                baseBasketSelected = baseBasketSelected.concat(",");
                            }
                        }
                        returnObj['BASE_BASKET_DATA'] = baseBasketSelected.substring(0,(baseBasketSelected.length -1));

                        if(vm.selectedBaseBasketCode != undefined && vm.selectedBaseBasketCode.length == 1){
                            var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','SINGLE']);
                            if(exists != undefined)
                                returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                        }else if(vm.selectedBaseBasketCode != undefined && vm.baseBasketData && vm.selectedBaseBasketCode.length === vm.baseBasketData.length){
                            var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','ALL']);
                            if(exists != undefined)
                                returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                        }else if(vm.selectedBaseBasketCode != undefined && vm.selectedBaseBasketCode.length > 1){
                            var exists = _.find(vm.baseBasketTypeData, ['CODE_DESCRIPTION','MULTI']);
                            if(exists != undefined)
                                returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_BASKET']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                        }

                        if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length == 1){
                            var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','SINGLE']);
                            if(exists != undefined)
                                returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                        }else if(vm.selectedBaseFdiiCodes != undefined && vm.baseFDIIData && vm.selectedBaseFdiiCodes.length === vm.baseFDIIData.length){
                            var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','ALL']);
                            if(exists != undefined)
                                returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                        }else if(vm.selectedBaseFdiiCodes != undefined && vm.selectedBaseFdiiCodes.length > 1){
                            var exists = _.find(vm.basefdiiSingleMultiData, ['CODE_DESC','MULTI']);
                            if(exists != undefined)
                                returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_FDII']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                        }
                    }

                }

                if(vm.selectedBaseTypeCode != undefined && vm.selectedBaseTypeCode.length == 1){
                    var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','SINGLE']);
                    if(exists != undefined)
                        returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                }else if(vm.selectedBaseTypeCode != undefined && vm.baseTypeData && vm.selectedBaseTypeCode.length === vm.baseTypeData.length){
                    var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','ALL']);
                    if(exists != undefined)
                        returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                }else if(vm.selectedBaseTypeCode != undefined &&  vm.selectedBaseTypeCode.length > 1){
                    var exists = _.find(vm.baseTypeCodeData, ['CODE_DESCRIPTION','MULTI']);
                    if(exists != undefined)
                        returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_TYPE']).ATTRIB_KEY +'_0'] = exists.CODE_KEY;
                }

                if(vm.selectedMethodCode)
                returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'METHOD']).ATTRIB_KEY +'_0'] = vm.selectedMethodCode.CODE_KEY;

                if(vm.selectedBaseLevelCode != undefined && vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION != "Allocate" && vm.selectedMethodCode.CODE_DESCRIPTION != "Interest Expense" && vm.selectedMethodCode.CODE_DESCRIPTION != "Research Expense")
                    returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_LEVEL']).ATTRIB_KEY +'_0'] = vm.selectedBaseLevelCode;
                if(vm.selectedBaseEntityLevelCode != undefined && vm.selectedMethodCode &&  vm.selectedMethodCode.CODE_DESCRIPTION != "Allocate" && vm.selectedMethodCode.CODE_DESCRIPTION != "Interest Expense" && vm.selectedMethodCode.CODE_DESCRIPTION != "Research Expense")
                    returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASE_ENTITY_LEVEL']).ATTRIB_KEY +'_0'] = vm.selectedBaseEntityLevelCode;


                // Doing this so that no data is inserted for ALL in tables.
                if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_FDII_DATA'] && vm.baseFDIIData && returnObj['BASE_FDII_DATA'].split(",").length === vm.baseFDIIData.length){
                     returnObj['BASE_FDII_DATA'] = "";
                }

                if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_BASKET_DATA'] && vm.baseBasketData && returnObj['BASE_BASKET_DATA'].split(",").length === vm.baseBasketData.length){
                     returnObj['BASE_BASKET_DATA'] = "";
                }

                if(vm.selectedMethodCode && vm.selectedMethodCode.CODE_DESCRIPTION !== 'Allocate' && returnObj['BASE_TYPE_DATA'] && vm.baseTypeData && returnObj['BASE_TYPE_DATA'].split(",").length === vm.baseTypeData.length){
                     returnObj['BASE_TYPE_DATA'] = "";
                }

                if(vm.selectedAttributes.CODE_DESCRIPTION === 'SIC CODE')
                    returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'SIC_CODES']).ATTRIB_KEY +'_0'] = vm.selectedSICCOde.CODE_KEY;

                if(vm.selectedAttributes.CODE_KEY === 'COUNTRY_CODE')
                    returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'COUNTRY']).ATTRIB_KEY +'_0'] = vm.selectedCountryCode.CODE_KEY;

                if(vm.selectedAttributes.CODE_DESCRIPTION === 'BASKET')
                   returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BASKET']).ATTRIB_KEY +'_0'] = vm.selectedBasketInfoByAttr.CODE_KEY;

                if(vm.selectedAttributes.CODE_DESCRIPTION === 'FDII')
                   returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'FDII']).ATTRIB_KEY +'_0'] = vm.selectedBaseFdiiInfoByAttr.CODE_KEY;
                
                if(vm.selectedAttributes.CODE_DESCRIPTION === 'BEAT') {
                    returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT']).ATTRIB_KEY +'_0'] = vm.selectedBeatCode.CODE_KEY;
                
                if (vm.selectedBeatCode && vm.selectedBeatCode.CODE_DESCRIPTION === 'Yes') {
                	returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT_TYPE']).ATTRIB_KEY +'_0'] = vm.selectedBeatTypeCode; 
                	returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT_WITHHOLD']).ATTRIB_KEY +'_0'] = vm.selectedBeatWithhold;
                } else {
                	returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT_TYPE']).ATTRIB_KEY +'_0'] = ""; 
                	returnObj[_.find(vm.attribMastData, ['ATTRIB_NAME', 'BEAT_WITHHOLD']).ATTRIB_KEY +'_0'] = "";
                }
                }
                
                for(var key in vm.gridDataMultipleSelect){
                    var returnObjloop=angular.copy(returnObj);

                    returnObjloop['COMBINATION_KEY'] =  vm.gridDataMultipleSelect[key].data.COMBINATION_KEY;
                    returnObjloop['TAG_KEY'] = vm.gridDataMultipleSelect[key].data.TAG_KEY;
                    returnObjloop['GIE_KEY'] = vm.gridDataMultipleSelect[key].data.GIE_KEY;
                    returnObjloop['OPERATION_TYPE'] = 'BU';

                    var message = "Tag has been successfully saved/updated";
                    attribManagementBatchUpdateDetails.push(returnObjloop);
                }

                returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
                returnClobSettingsObj['p_tax_year'] = vm.curentyear;
                returnClobSettingsObj['p_scenario'] = vm.scenario;
                returnClobSettingsObj['p_jcd_key'] = vm.jcd_key;
                sendDetails(returnClobSettingsObj,attribManagementBatchUpdateDetails, message);

            };

            function sendDetails(returnClobSettingsObj, attribManagementBatchUpdateDetails, message) {
                AttributeBatchServiceFactory.saveDefaults(returnClobSettingsObj,attribManagementBatchUpdateDetails).then(function(result) {

                    if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                        AlertService.add("error", result.data.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                        var args = {
                                me_code: returnClobSettingsObj.me_code,
                                gridFilter: {
                                    me_code: returnClobSettingsObj.me_code
                                }
                            };
                        $uibModalInstance.dismiss('cancel');
                        $rootScope.$emit('gridUpdate', args);
                    };

                });
            }      

          }

        //A seperate controller for the Lock and Unlock Tags Screens  
        function attributeLockUnlockData($rootScope, $scope, $state, $log,JsonObjectFactory, GlobalService, AlertService, $timeout, USER_SETTINGS, workspaceFactory, GENERAL_CONFIG,$uibModalInstance, AttributeBatchServiceFactory, gridData) {

                var vm = this;
                vm.loadingData = true;
                vm.userSettings = USER_SETTINGS;
                vm.logged_in_user = vm.userSettings.user.sso_id;          
                vm.lockUnlockSelected = [];
                vm.isLockedWarningMsgs;
                vm.isUnlockedWarningMsgs;          
                vm.isErrorExists = false;              
                vm.is_return_filed;
                vm.is_locked;
                vm.isSaveClicked = false;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                vm.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

                // On Load of modal.. it will put it in modal Table
                if (gridData != null) {

                    var tempGridData = _.clone(gridData), groups = Object
                            .create(null), result, finalKeyCheck = 0;

                    vm.gridDataMultipleSelect = _.clone(tempGridData);                
                    if (vm.gridDataMultipleSelect != undefined) {                      

                        //If the Tax Return is filed then block user from proceeding further
                        vm.is_return_filed = vm.gridDataMultipleSelect[0].data.IS_RETURN_FILED;    
                        if(vm.is_return_filed != undefined && vm.is_return_filed !=null && vm.is_return_filed == 'Y'){                                                                
                            AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
                            throw new Error("You cannot perform this action as Tax return is already filed.");
                            return;
                        }      

                        //Logic to Displaying the Warning Messages while LOCK'ing and UNLOCK'ing of the Tags
                        vm.isLockedWarningExists = false;
                        vm.isUnLockedWarningExists = false;
                        for ( var data in vm.gridDataMultipleSelect) {                                  
                            if(vm.gridDataMultipleSelect[data].data.STATUS_FLAG == 'Locked') {                            
                                 vm.gridDataMultipleSelect[data].data["LOCK_STATUS"] = 'Already Locked';
                                 vm.isLockedWarningExists = true;
                            }else{
                                vm.gridDataMultipleSelect[data].data["LOCK_STATUS"] = '';
                            }

                            if(vm.gridDataMultipleSelect[data].data.STATUS_FLAG == 'Unlocked') {  
                                 vm.gridDataMultipleSelect[data].data["UNLOCK_STATUS"] = 'Already Unlocked';
                                 vm.isUnLockedWarningExists = true;
                            }else{
                                 vm.gridDataMultipleSelect[data].data["UNLOCK_STATUS"] = '';
                            }
                            vm.lockUnlockSelected.push(vm.gridDataMultipleSelect[data].data);                      

                        }
                        if(vm.isLockedWarningExists){
                            vm.isLockedWarningMsgs = "Please resolve the warnings before Save";
                        }


                        
					}//end of grid selected row
			}
				
			//Remove the Entry from Pop-up
			vm.removeLockUnLockRow = function(name){					
				var index = vm.lockUnlockSelected.indexOf(name);					
				vm.lockUnlockSelected.splice(index, 1);	
				
				vm.isLockedWarningExists = false;
				vm.isUnLockedWarningExists = false;
				vm.isLockedWarningMsgs = null;
				vm.isUnlockedWarningMsgs = null;
				for ( var data in vm.lockUnlockSelected) {                                    
					if(vm.lockUnlockSelected[data].STATUS_FLAG == 'Locked') {                             
						vm.isLockedWarningExists = true;
                   	}
                   	if(vm.lockUnlockSelected[data].STATUS_FLAG == 'Unlocked') {   
                   	 	vm.isUnLockedWarningExists = true;
                   	}
               } 
               if(vm.isLockedWarningExists){
               	vm.isLockedWarningMsgs = "Please resolve the warnings before Save";  
               }             
               if(vm.isUnLockedWarningExists){
               	vm.isUnlockedWarningMsgs = "Please resolve the warnings before Save";
               } 
			}
		
			//Save the selected Tags that get Locked
			vm.saveTagsToGetLocked = function() {
				vm.isSaveClicked = true;
				if(vm.lockUnlockSelected.length == 0 ){
					vm.isSaveClicked = false;
					AlertService.add("error", "Please select atleast one row for Locking");
					return;
				}	
				
				console.log("Logged in user: ",vm.logged_in_user); 
				vm.recordsToNotify = [];
				var allTagKeys = '';
				for ( var item in vm.lockUnlockSelected) {
					vm.recordsToNotify.push(vm.lockUnlockSelected[item]);
					vm.recordsToNotify["CURRENT_USER"] = vm.logged_in_user;
					vm.recordsToNotify["NOTIFY_USER"] = vm.lockUnlockSelected[item].UPDATED_BY;
					if(vm.lockUnlockSelected[item].STATUS_FLAG != 'Locked'){
						if(allTagKeys == ''){
							allTagKeys = allTagKeys + vm.lockUnlockSelected[item].TAG_KEY;								
						}else{
							allTagKeys = allTagKeys +","+ vm.lockUnlockSelected[item].TAG_KEY;			
						}
					}
				}				
				console.log("vm.recordsToSave : ", vm.recordsToNotify);
				
				var message = "Selected Tags has been locked.";					
				//Save the data 
				sendDetailsForLockUnLock(allTagKeys,'L', message,vm.recordsToNotify);
			}
			
			//Save the selected Tags that get Locked
			vm.saveTagsToGetUnLocked = function() {
				vm.isSaveClicked = true;
				if(vm.lockUnlockSelected.length == 0 ){
					vm.isSaveClicked = false;
					AlertService.add("error", "Please select atleast one row for Locking");
					return;
				}	
				vm.recordsToNotify = [];
				var allTagKeys = '';
				for ( var item in vm.lockUnlockSelected) {					
					vm.lockUnlockSelected[item]["EDITING_USER"] = vm.logged_in_user;
					vm.lockUnlockSelected[item]["NOTIFY_USER"] = vm.lockUnlockSelected[item].UPDATED_BY;
					vm.recordsToNotify.push(vm.lockUnlockSelected[item]);
					
					
					if(vm.lockUnlockSelected[item].STATUS_FLAG != 'Unlocked'){
						if(allTagKeys == ''){						
							allTagKeys = allTagKeys + vm.lockUnlockSelected[item].TAG_KEY;								
						}else{
							allTagKeys = allTagKeys +","+ vm.lockUnlockSelected[item].TAG_KEY;			
						}
					}
				}
				console.log("vm.recordsToSave : ", vm.recordsToNotify);
				
				var message = "Selected Tags has been unlocked.";					
				//Save the data 
				sendDetailsForLockUnLock(allTagKeys,'U',  message,vm.recordsToNotify);
			}
			
			//Send Details to Service to Save the data
			function sendDetailsForLockUnLock(allTagKeys,lockFlag, message, _notificationData) {
				AttributeBatchServiceFactory.saveLockAndUnLocking(allTagKeys,lockFlag).then(function(result) {
					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
							AlertService.add("success", message, 4000);
							var args = {}; //(workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};							
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
														
							//if(lockFlag == 'U'){
							//	AttributeBatchServiceFactory.sendNotification(_notificationData);
							//}
                     	};
				});
			}
				
		  }	

		return controllers;

	});
