define([
    'angular'

], function () {
    'use strict';

    let controllers =  angular.module('app.partnershipControllersNew',[])
        .controller('partnerAllocationControllerNew', ['GlobalService','$rootScope','$scope','$state','$log','GridFactory','JsonObjectFactory','$filter','AlertService','$timeout','$uibModal','GENERAL_CONFIG','SERVER_CONFIG','USER_SETTINGS','K3PartIAttachmentFactory','K3PartVIIAttachmentFactory','K3PartXAttachmentFactory','K3PartXIAttachmentFactory','K3PartXIIIAttachmentFactory','workspaceFactory', 'PartnerAllocServicesNewFactory','K1Factory','K3Page1Factory','K3PartIFactory','K3PartIIFactory','K3PartIIIFactory','K3PartIVFactory','K3PartVFactory','K3PartVIFactory','K3PartVIIFactory','K3PartVIIIFactory','K3PartIXFactory','K3PartXFactory','K3PartXIFactory','K3PartXIIIFactory','$uibModalInstance','rowData',partnerAllocationControllerNew])

    function partnerAllocationControllerNew(GlobalService,$rootScope,$scope,$state,$log,GridFactory,JsonObjectFactory,$filter,AlertService,$timeout,$uibModal,GENERAL_CONFIG,SERVER_CONFIG,USER_SETTINGS,K3PartIAttachmentFactory,K3PartVIIAttachmentFactory,K3PartXAttachmentFactory,K3PartXIAttachmentFactory,K3PartXIIIAttachmentFactory,workspaceFactory,PartnerAllocServicesNewFactory,K1Factory,K3Page1Factory,K3PartIFactory,K3PartIIFactory,K3PartIIIFactory,K3PartIVFactory,K3PartVFactory,K3PartVIFactory,K3PartVIIFactory,K3PartVIIIFactory,K3PartIXFactory,K3PartXFactory,K3PartXIFactory,K3PartXIIIFactory,$uibModalInstance,rowData){

        console.log('Called the modal mock-up for external');

        let vm = this;
        vm.loading = true;
        vm.tiLoading = true;
        vm.allocation_heading = null;
        vm.isSaveClicked = false;

        vm.SERVER_CONFIG = SERVER_CONFIG;

        vm.alphaArray = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','aa','ab','ac','ad','ae','af','ag','ah','ai','aj','ak','al','am','an','ao','ap','aq','ar','as','at','au','av','aw','ax','ay','az'];

        vm.postingMetaData = [];
        vm.countryKeys = [];
        vm.countryDetails = [];
        vm.partHeaderElement = {};
        vm.clonedCountryHeaderElement = {};
        vm.partHeaderIIFormData = [];
        vm.partHeaderIIIFormData = [];
        vm.partHeaderIIIFSIFormData = [];
        vm.tiFormData= [];
        vm.creditFormData = [];
        vm.amtaceFormData = [];
        vm.otherFormData = [];
        vm.taxcapitalFormData = [];
        vm.formData = [];
        vm.issueList = [];
        vm.issueID = 0;
        vm.selectedIssue = ''; // {}

        vm.allDropdownList = [];
        vm.OtherFormDataObj = [];

        vm.partHeaderDynamic = [];
        vm.partDetailDynamic = [];

        vm.showK1Caplib = false;
        vm.showTI = true;
        vm.showCredits = false;
        vm.showAMTACE = false;
        vm.showOTHER = false;
        vm.showTAXCAPITAL = false;
        vm.showFSI = true;

        vm.rowObject = [];
        vm.allTableRows = [];
        vm.colspan = 2;

        vm.allFormFormulaList=[];
        vm.formFormulaList=[];

        vm.formListTaxCap = [];
        vm.formulaListTaxCap = [];

        vm.tiLinesByFLCCode;

        vm.taxCapitalFormulaList_K1CAPLIB = [];
        vm.taxCapitalFormulaList_TI = [];
        vm.taxCapitalFormulaList_CREDIT = [];
        vm.taxCapitalFormulaList_AMTACE = [];
        vm.taxCapitalFormulaList_OTHER = [];
        vm.taxCapitalFormulaList_FSI = [];

        vm.taxCapitalFormulaList_DEPENDENT = [];

        vm.form_key;
        vm.schedule_name;

        vm.sn = null;
        vm.pn = null;
        vm.sm = null;

        vm.formList = [];

        vm.sourceList = [];

        vm.firstRow = {};

        vm.tax_year_begin;
        vm.tax_year_end;

        vm.pship_ein;
        vm.pship_le_name;
        vm.pship_address;
        vm.pship_details;
        vm.ptr_ein;
        vm.ptr_le_name;
        vm.ptr_address;
        vm.ptr_details;

        vm.all_1065SK3Data = [];
        vm.all_1065SK3AttachmentData = [];
        vm.category_key;
        vm.category_code;
        vm.page1Header_1065SK3Data = [];
        vm.page1_E_lines_1065SK3Data = [];
        vm.E_line_Numbers = [];
        vm.E_line_No_Desc_map = new Map();
        vm.allFormFormulaListSK3 = [];


        vm.part_I_header_1065SK3Data = [];
        vm.part_I_1065SK3Data = [];


        vm.part_II_header_1065SK3Data = [];

        vm.part_II_s1_header_1065SK3Data = [];
        vm.part_II_S1_1065SK3Data = [];
        vm.part_II_S1_1065SK3DataTransposed = [];

        vm.part_II_s2_header_1065SK3Data = [];
        vm.part_II_S2_1065SK3Data = [];
        vm.part_II_S2_1065SK3DataTransposed = [];

        vm.part_II_s1_24h_n_s2_54_1065SK3DataTransposed = [];
        vm.part_II_s2_55_1065SK3DataTransposed = {};

        vm.partIIS1Changes = [];
        vm.partIIS2Changes = [];

        vm.p2s1_line_Numbers = [];
        vm.p2s2_line_Numbers = [];
        vm.p2s1_line_No_Desc_map = new Map();
        vm.p2s2_line_No_Desc_map = new Map();

        vm.part_III_header_1065SK3Data = [];

        vm.part_III_s1_header_1065SK3Data = [];
        vm.part_III_S1_1065SK3Data = [];
        vm.part_III_S1_1065SK3Data_line1 = [];
        vm.part_III_S1_line1_1065SK3DataTransposed = [];
        vm.part_III_S1_1065SK3DataTransposed = [];

        vm.partIIIS1L1Changes = [];
        vm.partIIIS1L2Changes = [];
        vm.partIIIS2Changes = [];
        vm.partIIIS3Changes = [];
        vm.partIIIS4Changes = [];
        vm.partIIIS5Changes = [];

        vm.countryArray = [];

        vm.other;
        vm.other_p1;
        vm.other_p2;
        vm.wrapCols = 4;
        vm.countries = 1;
        vm.wraplength = 740;
        vm.subRowLength = 1273;
        vm.repeatorEndIndexE = 0;
        vm.repeatorEndIndexF = 0;

        vm.part_III_s2_header_1065SK3Data = [];
        vm.part_III_S2_1065SK3Data = [];
        vm.part_III_S2_1065SK3DataTransposed = [];

        vm.part_III_s3_header_1065SK3Data = [];
        vm.part_III_S3_1065SK3Data = [];
        vm.part_III_S3_1065SK3DataTransposed = [];

        vm.part_III_s4_header_1065SK3Data = [];
        vm.part_III_S4_1065SK3Data = [];
        vm.part_III_S4_1065SK3DataTransposed = [];

        vm.part_III_s5_header_1065SK3Data = [];
        vm.part_III_S5_1065SK3Data = [];
        vm.part_III_S5_1065SK3DataTransposed = [];

        vm.part_IV_header_1065SK3Data = [];
        vm.part_IV_s1_header_1065SK3Data = [];
        vm.part_IV_S1_1065SK3Data = [];
        vm.part_IV_s2_header_1065SK3Data = [];

        vm.part_IV_S2_1065SK3Data_line_9_10_11 = [];
        vm.part_IV_S2_1065SK3Data_line_9_10_11_Transposed = [];
        vm.part_IV_S2_1065SK3Data_line12 = [];

        vm.part_IV_s3_header_1065SK3Data = [];
        vm.part_IV_S3_1065SK3Data = [];
        vm.part_IV_S3_1065SK3Data_Transposed = [];


        vm.part_VIII_1065SK3DataTransposed_deletePart = [];


        vm.taxTypes = [];
        vm.currencyCodes = [];
        vm.categoryCodes = [];

        vm.showPartI = false;
        vm.showPartII = false;
        vm.showPartIII = false;
        vm.showPartIV = false;
        vm.showPartV = false;
        vm.showPartVI = false;
        vm.showPartVII = false;
        vm.showPartVIII = false;
        vm.showPartIX = false;
        vm.showPartX = false;
        vm.showPartXI = false;
        vm.showPartXII = false;
        vm.showPartXIII = false;

        vm.part7;
        vm.part10;
        vm.part11;
        vm.part13;


        /*vm.upload_url_10 = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachments?part=X';
        vm.upload_url_11 = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachments?part=XI';
        vm.upload_url_13 = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachments?part=XII';*/

        var URLS = {
            saveAttachemnt: GENERAL_CONFIG.admin_api_url + '/saveK3AttachmentDocuments'
        }

        vm.load_attachments_url = GENERAL_CONFIG.admin_api_url + '/loadAttachmentsByFileKeySet';



        vm.existingFilesList_pVII = [];

        vm.ACTIVE_Y_OR_N=rowData.ACTIVE_Y_OR_N;
        vm.ALLOCATION_PERCETAGE=rowData.ALLOCATION_PERCETAGE;
        vm.ALLOCATION_RATIO_TYPE=rowData.ALLOCATION_RATIO_TYPE;
        vm.EFFECTIVE_DATE_BEGIN=rowData.EFFECTIVE_DATE_BEGIN;
        vm.EFFECTIVE_DATE_END=rowData.EFFECTIVE_DATE_END;
        vm.EFFECTIVE_OWNERSHIP_PERCENTAGE=rowData.EFFECTIVE_OWNERSHIP_PERCENTAGE;
        vm.GENERAL_PARTNER=rowData.GENERAL_PARTNER;
        vm.PARTNER_HO_LEID=rowData.PARTNER_HO_LEID;
        vm.PARTNER_HO_NAME=rowData.PARTNER_HO_NAME;
        vm.PARTNER_HO_REPORTING_PERIOD=rowData.PARTNER_HO_REPORTING_PERIOD;
        vm.PARTNER_HO_TAX_CODE=rowData.PARTNER_HO_TAX_CODE;
        vm.PARTNER_HO_US_TAX_TYPE=rowData.PARTNER_HO_US_TAX_TYPE;
        vm.PARTNER_LEID=rowData.PARTNER_LEID;
        vm.PARTNER_NAME=rowData.PARTNER_NAME;
        vm.PARTNER_REPORTING_PERIOD=rowData.PARTNER_REPORTING_PERIOD;
        vm.PARTNER_TAX_CODE=rowData.PARTNER_TAX_CODE;
        vm.PARTNER_US_TAX_TYPE=rowData.PARTNER_US_TAX_TYPE;
        vm.POSTING_PARTNER_LEID=rowData.POSTING_PARTNER_LEID;
        vm.POSTING_PARTNER_NAME=rowData.POSTING_PARTNER_NAME;
        vm.POSTING_PARTNER_REPORTING_PERIOD=rowData.POSTING_PARTNER_REPORTING_PERIOD;
        vm.POSTING_PARTNER_TAX_CODE=rowData.POSTING_PARTNER_TAX_CODE;
        vm.POSTING_PARTNER_BUSINESS = rowData.POSTING_PARTNER_BUSINESS;
        vm.POSTING_PARTNER_CURRENCY = rowData.POSTING_PARTNER_CURRENCY;
        vm.PSHIP_BEGIN_DATE=rowData.PSHIP_BEGIN_DATE;
        vm.PSHIP_BUSINESS=rowData.PSHIP_BUSINESS;
        vm.PSHIP_COMPANY_CODE=rowData.PSHIP_COMPANY_CODE;
        vm.PSHIP_CURRENCY=rowData.PSHIP_CURRENCY;
        vm.PSHIP_END_DATE=rowData.PSHIP_END_DATE;
        vm.PSHIP_HO_COMPANY_CODE=rowData.PSHIP_HO_COMPANY_CODE;
        vm.PSHIP_HO_LEID=rowData.PSHIP_HO_LEID;
        vm.PSHIP_HO_NAME=rowData.PSHIP_HO_NAME;
        vm.PSHIP_HO_REPORTING_PERIOD=rowData.PSHIP_HO_REPORTING_PERIOD;
        vm.PSHIP_HO_TAX_CODE=rowData.PSHIP_HO_TAX_CODE;
        vm.PSHIP_HO_US_TAX_TYPE=rowData.PSHIP_HO_US_TAX_TYPE;
        vm.PSHIP_INTERNAL_EXTERNAL_INDICATOR=rowData.PSHIP_INTERNAL_EXTERNAL_INDICATOR;
        vm.PSHIP_LEID=rowData.PSHIP_LEID;
        vm.PSHIP_NAME=rowData.PSHIP_NAME;
        vm.PSHIP_REPORTING_PERIOD=rowData.PSHIP_REPORTING_PERIOD;
        vm.PSHIP_TAX_CODE=rowData.PSHIP_TAX_CODE;
        vm.PSHIP_US_TAX_TYPE=rowData.PSHIP_US_TAX_TYPE;
        vm.TAX_MATTERS_PARTNER=rowData.TAX_MATTERS_PARTNER;
        vm.US_RETURN_PREPARED_BY=rowData.US_RETURN_PREPARED_BY;

        vm.PSHIP_PTR_POSTING_KEY = rowData.PSHIP_PTR_POSTING_KEY;
        vm.COMBINATION_KEY = rowData.COMBINATION_KEY;
        vm.PTR_COMBINATION_KEY = rowData.PTR_COMBINATION_KEY;
        vm.chart_key = [];

        vm.form_key = rowData.FORM_KEY;
        vm.schedule_name = rowData.SCHEDULE_NAME;
        vm.attachment_parts = rowData.ATTACHMENT_PARTS;

        vm.upload_url_VII;
        vm.upload_url_X;
        vm.upload_url_XI;
        vm.upload_url_XIII;

        vm.form_type;

        if(vm.PSHIP_HO_US_TAX_TYPE == 'PTSP' || vm.PSHIP_HO_US_TAX_TYPE == 'NCPTSP' || vm.PSHIP_HO_US_TAX_TYPE == 'NCPTSPNF') {
            vm.form_type = '8865';
        }
        else {
            vm.form_type = '1065';
        }



        let attachmentParts = [];

        if(vm.attachment_parts != null) {
            attachmentParts = vm.attachment_parts.split(',');
        }

        if(attachmentParts.indexOf('I') != -1) {
            vm.part1 = attachmentParts[attachmentParts.indexOf('I')];
        }

        if(attachmentParts.indexOf('VII') != -1) {
            vm.part7 = attachmentParts[attachmentParts.indexOf('VII')];
        }
        if(attachmentParts.indexOf('X') != -1) {
            vm.part10 = attachmentParts[attachmentParts.indexOf('X')];
        }
        if(attachmentParts.indexOf('XI') != -1) {
            vm.part11 = attachmentParts[attachmentParts.indexOf('XI')];
        }
        if(attachmentParts.indexOf('XIII') != -1) {
            vm.part13 = attachmentParts[attachmentParts.indexOf('XIII')];
        }

        vm.title = 'Partnership Details: ' + vm.PSHIP_LEID + ' / ' +  vm.PSHIP_REPORTING_PERIOD + ' - ' + 'Partner Details: ' +  vm.POSTING_PARTNER_LEID + ' / ' + vm.POSTING_PARTNER_REPORTING_PERIOD;

        let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

        console.log('------------- filterParams --------------', filterParams);

        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;

        if(vm.PSHIP_INTERNAL_EXTERNAL_INDICATOR === "INTERNAL") {
            vm.allocation_heading = 'Partner Allocation - Schedule K';
        }
        else if(vm.PSHIP_INTERNAL_EXTERNAL_INDICATOR === "EXTERNAL") {    //"EXTERNAL"
            vm.allocation_heading = 'Partner Allocation - External K1';
        }

        console.log('vm.allocation_heading = ', vm.allocation_heading);

        vm.TAX_YEAR = filterParams.tax_year;
        vm.scenario = filterParams.scenario;
        vm.jcd_key = GlobalService.globalParams.jcd_key;


        vm.taxYears = [];

        vm.taxYear = {};

        vm.taxYear['label'] = '2021';
        vm.taxYear['value'] = '2021';
        vm.taxYears.push(angular.copy(vm.taxYear));

        vm.taxYear['label'] = '2020';
        vm.taxYear['value'] = '2020';
        vm.taxYears.push(angular.copy(vm.taxYear));

        vm.taxYear['label'] = '2019';
        vm.taxYear['value'] = '2019';
        vm.taxYears.push(angular.copy(vm.taxYear));

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.checkbox_12_value = false;

        vm.daterow = {};
        vm.daterow['calendar'] = {opened:false};

        vm.currentDate = new Date();

        vm.continue = false;

        vm.loadIssueDropdown = function(tax_year, scenario, jcd_key) {
           K1Factory.loadIssueDropdown(tax_year, scenario, jcd_key).then(function(issueList) {
                vm.issueList = issueList;

               vm.loadAllDropdowns(filterParams.tax_year);



            });
        }

        vm.loadAllDropdowns = function(tax_year) {
            K1Factory.loadAllDropdowns(tax_year).then(function(allDropdownList) {
                vm.allDropdownList = allDropdownList;
                //vm.formCalcDataAll();
            });
        }

        vm.loadCountryList = function() {

            K3PartIIFactory.loadCountryList(filterParams.tax_year, vm.logged_in_user).then(function(countryCodeList) {
                vm.countryCodeList = countryCodeList;

                vm.loadSIC_Codes();

            });
        }

        vm.getcountryList = function() {
            K3PartIIIFactory.getCountryList(filterParams.tax_year, vm.logged_in_user).then(function(countryList) {
                vm.countryList = countryList;
                vm.getTaxTypes();

            });
        }

        vm.loadSIC_Codes = function() {

            K3PartIIFactory.loadSIC_Codes(filterParams.tax_year).then(function(SIC_codes) {
                vm.SIC_codes = SIC_codes;

                vm.getcountryList();

            });

        }

        vm.getTaxTypes = function() {
            K3PartIIIFactory.getTaxTypes(filterParams.tax_year).then(function(taxTypes) {
                vm.taxTypes = taxTypes;
                vm.getCurrencyCodes();

            });
        }

        vm.getCurrencyCodes = function() {
            K3PartVFactory.getCurrencyCodes(filterParams.tax_year).then(function(currencyCodes) {
                vm.currencyCodes = currencyCodes;
                vm.getCategoryCodes();

            });
        }

        vm.getCategoryCodes = function() {
            K3PartVIFactory.getCategoryCodes(filterParams.tax_year).then(function(categoryCodes) {
                vm.categoryCodes = categoryCodes;
            });
        }

        vm.loadAllockFormDetails = function(tabName) {
            K1Factory.loadAllockFormDetails(tabName, vm).then(function(ctrl){
                vm = ctrl;
                K1Factory.loadIssueDetails(vm).then(function(ctrl) {
                    vm = ctrl;
                    vm.loadIssueDropdown(vm.TAX_YEAR, vm.scenario, vm.jcd_key);
                });
            });
        }

        vm.formCalcDataAll = function() {
            K1Factory.formCalcDataAll(vm).then(function (ctrl){
                vm = ctrl;
            });
        }

        vm.displayDropDownList = function(form_line_key,code_group) {
            let dropDownList = [];
            let listOfSelectedGroup = $filter("filter")(vm.allDropdownList, {CODE_GROUP:code_group});
            let dropDownElement = {};
            for(let i = 0; i < listOfSelectedGroup.length; i++) {
                dropDownElement = {};
                dropDownElement['name'] = listOfSelectedGroup[i].CODE_DESC;
                dropDownElement['value'] = listOfSelectedGroup[i].CODE_KEY;
                dropDownList.push(dropDownElement);
            }
            //console.log('form_line_key=', form_line_key ,' dropDownList=', dropDownList);
            return dropDownList;
        }

        vm.formCalcDataSK3 = function() {
            PartnerAllocServicesNewFactory.loadSK3FormulaDetails(vm).then(function(response) {
                vm.allFormFormulaListSK3 = response.jsonObject;
            });
        }

        vm.loadSK3FormDetails = function(tabName) {
            console.log('tabName = ', tabName);
            //let defaultCategoryParams = {"action_code" : 'gmxuns', "tax_year" : vm.TAX_YEAR};
            let dataParams = {"action_code" : 'fp23ed', "tax_year" : vm.TAX_YEAR, "scenario" : vm.scenario, "jcd_key": vm.jcd_key, "pship_cc_key" : vm.COMBINATION_KEY, "ptr_cc_key" : vm.PTR_COMBINATION_KEY};

            //K3PartIIFactory.getDefaultCategory(vm, vm.TAX_YEAR).then(function(ctrl){
                //vm = ctrl;
                PartnerAllocServicesNewFactory.loadSK3Details(vm).then(function(response){
                    if(response.jsonObject.length) {
                        vm.all_1065SK3Data = response.jsonObject;
                        vm.form_key = vm.all_1065SK3Data[0].FORM_KEY;
                        vm.schedule_name = vm.all_1065SK3Data[0].SCHEDULE_NAME;
    
                        PartnerAllocServicesNewFactory.loadSK3AttachmentDetails(vm).then(function(response){
    
                            vm.all_1065SK3AttachmentData = response.jsonObject;
    
                            K3PartIIFactory.getDefaultCountryCode(filterParams.tax_year).then(function(defaultCountryCode) {
    
                                vm.defaultCountryCode = defaultCountryCode;
    
                                K3Page1Factory.renderSK3Page1Details(vm);
                                K3PartIFactory.renderSK3PartIDetails(vm);
                                K3PartIIFactory.renderSK3PartIIDetails(vm);
                                K3PartIIIFactory.renderSK3PartIIIDetails(vm);
                                K3PartIVFactory.renderSK3PartIVDetails(vm);
                                K3PartVFactory.renderSK3PartVDetails(vm);
                                K3PartVIFactory.renderSK3PartVIDetails(vm);
                                K3PartVIIFactory.renderSK3PartVIIDetails(vm);
                                K3PartVIIIFactory.renderSK3PartVIIIDetails(vm);
                                K3PartIXFactory.renderSK3PartIXDetails(vm);
                                K3PartXFactory.renderSK3PartXDetails(vm);
                                K3PartXIFactory.renderSK3PartXIDetails(vm);
                                K3PartXIIIFactory.renderSK3PartXIIIDetails(vm);
                                vm.loading = false;
    
                                K3PartIIFactory.getDefaultCategory(vm, vm.TAX_YEAR).then(function(ctrl){
                                    vm = ctrl;
                                    vm.loadCountryList();
                                });
                            });
    
                        });
                    }
                    else {
                        vm.loading = false;
                        AlertService.add("error", "No data found for Schedule K-3", 4000)
                    }
                    

                });
            //});

        }

        ////////////////////////////////// All these are called at popup open - starts ///////////////////////////////////


        if(vm.form_key != null && vm.schedule_name != null && attachmentParts.length > 0) {

            if (vm.part1 != null) {
                vm.upload_url_I = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachment?tax_year='+vm.TAX_YEAR+'&scenario='+vm.scenario+'&jcd_key='+vm.jcd_key+'&sso_id='+vm.logged_in_user+'&combination_key='+vm.COMBINATION_KEY+'&ptr_combination_key='+vm.PTR_COMBINATION_KEY+ '&form_key='+vm.form_key+'&schedule_name='+vm.schedule_name+'&partNo='+vm.part1+'&section_name=NA';

                console.log('vm.upload_url_I = ' , vm.upload_url_I);
            }

            if (vm.part7 != null) {
                vm.upload_url_VII = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachment?tax_year='+vm.TAX_YEAR+'&scenario='+vm.scenario+'&jcd_key='+vm.jcd_key+'&sso_id='+vm.logged_in_user+'&combination_key='+vm.COMBINATION_KEY+'&ptr_combination_key='+vm.PTR_COMBINATION_KEY+ '&form_key='+vm.form_key+'&schedule_name='+vm.schedule_name+'&partNo='+vm.part7+'&section_name=NA';

               console.log('vm.upload_url_VII = ' , vm.upload_url_VII);
            }
            if (vm.part10 != null) {
                vm.upload_url_X = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachment?tax_year='+vm.TAX_YEAR+'&scenario='+vm.scenario+'&jcd_key='+vm.jcd_key+'&sso_id='+vm.logged_in_user+'&combination_key='+vm.COMBINATION_KEY+'&ptr_combination_key='+vm.PTR_COMBINATION_KEY+ '&form_key='+vm.form_key+'&schedule_name='+vm.schedule_name+'&partNo='+vm.part10+'&section_name=NA';
                console.log('vm.upload_url_X = ' , vm.upload_url_X);
            }
            if (vm.part11 != null) {
                vm.upload_url_XI = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachment?tax_year='+vm.TAX_YEAR+'&scenario='+vm.scenario+'&jcd_key='+vm.jcd_key+'&sso_id='+vm.logged_in_user+'&combination_key='+vm.COMBINATION_KEY+'&ptr_combination_key='+vm.PTR_COMBINATION_KEY+ '&form_key='+vm.form_key+'&schedule_name='+vm.schedule_name+'&partNo='+vm.part11+'&section_name=NA';
                console.log('vm.upload_url_XI = ' , vm.upload_url_XI);
            }
            if (vm.part13 != null) {
                vm.upload_url_XIII = GENERAL_CONFIG.admin_api_url + '/uploadK3Attachment?tax_year='+vm.TAX_YEAR+'&scenario='+vm.scenario+'&jcd_key='+vm.jcd_key+'&sso_id='+vm.logged_in_user+'&combination_key='+vm.COMBINATION_KEY+'&ptr_combination_key='+vm.PTR_COMBINATION_KEY+ '&form_key='+vm.form_key+'&schedule_name='+vm.schedule_name+'&partNo='+vm.part13+'&section_name=NA';
                console.log('vm.upload_url_XIII = ' , vm.upload_url_XIII);
            }

        }

        vm.remove_url = GENERAL_CONFIG.admin_api_url + '/deleteStoredAttachment';

        vm.download_url = GENERAL_CONFIG.admin_api_url + '/downloadPshipAttachment';

        vm.formCalcDataSK3();
        vm.loadSK3FormDetails('1065_SK3');

        vm.formCalcDataAll();
        vm.loadAllockFormDetails('TI');

        //vm.loadIssueDropdown(vm.TAX_YEAR, vm.scenario, vm.jcd_key);

        //vm.loadAllDropdowns(filterParams.tax_year);



        //vm.formCalcDataAll();

        //vm.loadCountryList();

        //vm.loadSIC_Codes();



        //vm.getcountryList();

        //vm.getTaxTypes();

        //vm.getCurrencyCodes();

        //vm.getCategoryCodes();



        ////////////////////////////////// All these are called at popup open - ends ///////////////////////////////////

        vm.onTabChange = function(e) {
            vm.selectedTabName = e;
            $timeout(function() {
                let movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
                let selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
                if (movingTab && selectedTab) {
                    $(movingTab).width(selectedTab.offsetWidth);
                    $(movingTab).height(selectedTab.offsetHeight)
                        .css('line-height', selectedTab.offsetHeight + 'px')
                        .css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
                }
            }, 200);
        };

        vm.showHideSection = function (id) {

            console.log('showHideSection called for :' ,  id, angular.element(document.getElementById(id)));

            if(id === 'k1caplib')
                vm.showK1Caplib = !vm.showK1Caplib;

            if(id === 'ti')
                vm.showTI = !vm.showTI;

            if(id === 'credits')
                vm.showCredits = !vm.showCredits;

            if(id === 'amtace')
                vm.showAMTACE = !vm.showAMTACE;

            if(id === 'other')
                vm.showOTHER = !vm.showOTHER;

            if(id === 'taxcapital')
                vm.showTAXCAPITAL = !vm.showTAXCAPITAL;

            let className = angular.element(document.getElementById(id))[0].className;

            if( className === 'ng-scope')
                angular.element(document.getElementById(id))[0].className='shown' + " " + className;
            else
                angular.element(document.getElementById(id))[0].className='ng-scope';
        }

        // called for every editable text box on-change (main tab)
        vm.applyFormulaMain = function(row) {

            K1Factory.applyFormulaMain(row,vm);
        }

        vm.addRowOccurrenceP2 = function(line_no, transposedSectionData) {
            K3PartIIFactory.addRowOccurrenceP2(vm, line_no, transposedSectionData);
        }

        vm.removeRowOccurrenceP2 = function(line_no, Occurrence, index, transposedSectionData){
            K3PartIIFactory.removeRowOccurrenceP2(vm, line_no, Occurrence, index, transposedSectionData);
        }

        vm.addRowOccurrenceP3 = function(line_no, transposedSectionData) {
            K3PartIIIFactory.addRowOccurrenceP3(vm, line_no, transposedSectionData);
        }

        vm.removeRowOccurrenceP3 = function(line_no, Occurrence, index, transposedSectionData){
            K3PartIIIFactory.removeRowOccurrenceP3(vm, line_no, Occurrence, index, transposedSectionData);
        }

        vm.addCountryColumnP3 = function(line_no, transposedSectionData) {
            K3PartIIIFactory.addCountryColumnP3(vm, line_no, transposedSectionData);
        }

        vm.removeCountryColumnP3 = function(line_no, col_occurrence, col_index, transposedSectionData){
            K3PartIIIFactory.removeCountryColumnP3(vm, line_no, col_occurrence, col_index,  transposedSectionData);
        }

        vm.addRowOccurrenceP4 = function(line_no, transposedSectionData) {
            K3PartIVFactory.addRowOccurrenceP4(vm, line_no, transposedSectionData);
        }

        vm.removeRowOccurrenceP4 = function(line_no, Occurrence, index, transposedSectionData){
            K3PartIVFactory.removeRowOccurrenceP4(vm, line_no, Occurrence, index, transposedSectionData);
        }

        vm.addRowOccurrenceP5 = function(line_no, transposedSectionData) {
            K3PartVFactory.addRowOccurrenceP5(vm, line_no, transposedSectionData);
        }

        vm.removeRowOccurrenceP5 = function(line_no, Occurrence, index, transposedSectionData){
            K3PartVFactory.removeRowOccurrenceP5(vm, line_no, Occurrence, index, transposedSectionData);
        }

        vm.addRowOccurrenceP6 = function(line_no, transposedSectionData) {
            K3PartVIFactory.addRowOccurrenceP6(vm, line_no, transposedSectionData);
        }

        vm.removeRowOccurrenceP6 = function(line_no, Occurrence, index, transposedSectionData){
            K3PartVIFactory.removeRowOccurrenceP6(vm, line_no, Occurrence, index, transposedSectionData);
        }

        vm.addRowOccurrenceP8 = function(line_no, transposedPartData) {
            K3PartVIIIFactory.addRowOccurrenceP8(vm, line_no, transposedPartData);
        }

        vm.removeRowOccurrenceP8 = function(line_no, Occurrence, index,transposedPartData) {
            K3PartVIIIFactory.removeRowOccurrenceP8(vm, line_no, Occurrence, index, transposedPartData);
        }

        vm.addPartOccurrenceP8 = function() {
            K3PartVIIIFactory.addPartOccurrenceP8(vm);
        }

        vm.removePartOccurrenceP8 = function(part, event) {
            K3PartVIIIFactory.removePartOccurrenceP8(vm, part);
            event.preventDefault(); // this will stop triggering other events, hence fixes the route change issue.
        }
        vm.calcAlpha = function(rowOccurence) {
            return String.fromCharCode(rowOccurence+64);
        }

        vm.applyFormulaSK3P2 = function(row,col) {
            console.log('PART_NO = ', col.PART_NO, " SECTION_NAME", col.SECTION_NAME, " LINE_NO = ", col.LINE_NO, " ROW_OCCURRENCE = ", col.ROW_OCCURRENCE, " COL_OCCURRENCE = ", col.COL_OCCURRENCE, " ATTRIBUTE_VALUE =  " , col.ATTRIBUTE_VALUE );
            let attrib_val = col.ATTRIBUTE_VALUE;
            let attrib_name = col.ATTRIB_NAME;
            let formulaCellObj = $filter("filter")(vm.allFormFormulaListSK3, {PART_NO:col.PART_NO,SECTION_NAME:col.SECTION_NAME,LINE_NO:col.LINE_NO,ATTRIB_NAME:'1065_K3_'+col.PART_NO+'_'+col.SECTION_NAME+'_'+col.LINE_NO+'_'})[0];
            //let formula = formulaObj.ATTRIB_CALC_FORMULA;

            K3PartIIFactory.calcAmtSK3P2(vm,formulaCellObj, attrib_name, attrib_val, row, col);

        }

        vm.applyFormulaSK3P3 = function(row,col) {
            console.log('PART_NO = ', col.PART_NO, " SECTION_NAME", col.SECTION_NAME, " LINE_NO = ", col.LINE_NO, " ROW_OCCURRENCE = ", col.ROW_OCCURRENCE, " COL_OCCURRENCE = ", col.COL_OCCURRENCE, " ATTRIBUTE_VALUE =  " , col.ATTRIBUTE_VALUE );
            let attrib_val = col.ATTRIBUTE_VALUE;
            let attrib_name = col.ATTRIB_NAME;
            let formulaCellObj = $filter("filter")(vm.allFormFormulaListSK3, {PART_NO:col.PART_NO,SECTION_NAME:col.SECTION_NAME,LINE_NO:col.LINE_NO,ATTRIB_NAME:'1065_K3_'+col.PART_NO+'_'+col.SECTION_NAME+'_'+col.LINE_NO+'_'})[0];
            //let formula = formulaObj.ATTRIB_CALC_FORMULA;

            K3PartIIIFactory.calcAmtSK3P3(vm,formulaCellObj, attrib_name, attrib_val, row, col);

        }

        vm.applyFormulaSK3P4 = function(row,col) {
            console.log('PART_NO = ', col.PART_NO, " SECTION_NAME", col.SECTION_NAME, " LINE_NO = ", col.LINE_NO, " ROW_OCCURRENCE = ", col.ROW_OCCURRENCE, " COL_OCCURRENCE = ", col.COL_OCCURRENCE, " ATTRIBUTE_VALUE =  " , col.ATTRIBUTE_VALUE );
            let attrib_val = col.ATTRIBUTE_VALUE;
            let attrib_name = col.ATTRIB_NAME;
            let formulaCellObj = $filter("filter")(vm.allFormFormulaListSK3, {PART_NO:col.PART_NO,SECTION_NAME:col.SECTION_NAME,LINE_NO:col.LINE_NO,ATTRIB_NAME:'1065_K3_'+col.PART_NO+'_'+col.SECTION_NAME+'_'+col.LINE_NO+'_'})[0];
            //let formula = formulaObj.ATTRIB_CALC_FORMULA;

            K3PartIVFactory.calcAmtSK3P4(vm,formulaCellObj, attrib_name, attrib_val, row, col);

        }

        vm.applyFormulaSK3P6 = function(row,col) {
            console.log('PART_NO = ', col.PART_NO, " SECTION_NAME", col.SECTION_NAME, " LINE_NO = ", col.LINE_NO, " ROW_OCCURRENCE = ", col.ROW_OCCURRENCE, " COL_OCCURRENCE = ", col.COL_OCCURRENCE, " ATTRIBUTE_VALUE =  " , col.ATTRIBUTE_VALUE );
            let attrib_val = col.ATTRIBUTE_VALUE;
            let attrib_name = col.ATTRIB_NAME;
            let formulaCellObj = $filter("filter")(vm.allFormFormulaListSK3, {PART_NO:col.PART_NO,SECTION_NAME:col.SECTION_NAME,LINE_NO:col.LINE_NO,ATTRIB_NAME:'1065_K3_'+col.PART_NO+'_'+col.SECTION_NAME+'_'+col.LINE_NO+'_'})[0];
            //let formula = formulaObj.ATTRIB_CALC_FORMULA;

            K3PartVIFactory.calcAmtSK3P6(vm);

        }

        vm.applyFormulaSK3P9 = function(row,col) {
            console.log('PART_NO = ', col.PART_NO, " SECTION_NAME", col.SECTION_NAME, " LINE_NO = ", col.LINE_NO, " ROW_OCCURRENCE = ", col.ROW_OCCURRENCE, " COL_OCCURRENCE = ", col.COL_OCCURRENCE, " ATTRIBUTE_VALUE =  " , col.ATTRIBUTE_VALUE );
            let attrib_val = col.ATTRIBUTE_VALUE;
            let attrib_name = col.ATTRIB_NAME;
            let formulaCellObj = $filter("filter")(vm.allFormFormulaListSK3, {PART_NO:col.PART_NO,SECTION_NAME:col.SECTION_NAME,LINE_NO:col.LINE_NO,ATTRIB_NAME:'1065_K3_'+col.PART_NO+'_'+col.SECTION_NAME+'_'+col.LINE_NO+'_'})[0];
            //let formula = formulaObj.ATTRIB_CALC_FORMULA;

            K3PartIXFactory.calcAmtSK3P9(vm,formulaCellObj, attrib_name, attrib_val, row, col);

        }

        vm.applyFormulaSK3P8 = function(part,row,col) {

            console.log('PART_NO = ', col.PART_NO, " PART_OCCURRENCE = ", col.PART_OCCURRENCE, " SECTION_NAME", col.SECTION_NAME, " LINE_NO = ", col.LINE_NO, " ROW_OCCURRENCE = ", col.ROW_OCCURRENCE, " COL_OCCURRENCE = ", col.COL_OCCURRENCE, " ATTRIBUTE_VALUE =  " , col.ATTRIBUTE_VALUE );
            let attrib_val = col.ATTRIBUTE_VALUE;
            let attrib_name = col.ATTRIB_NAME;
            let formulaCellObjs = $filter("filter")(vm.allFormFormulaListSK3, {PART_NO:col.PART_NO,SECTION_NAME:col.SECTION_NAME});
            let partCH = $filter("filter")(part.PART_DATA, {ROW_TYPE:'CH'});
            K3PartVIIIFactory.calcAmtSK3P8(vm,partCH, formulaCellObjs, attrib_name, attrib_val, row, col);
        }

        vm.removeFileI = function(file,index) {
            K3PartIFactory.removeFile(vm,file,index);
        }

        vm.downloadAttachmentFileI = function(file) {
            K3PartIFactory.downloadAttachmentFile(vm,file);
        }

        vm.removeFileVII = function(file,index) {
            K3PartVIIFactory.removeFile(vm,file,index);
        }

        vm.downloadAttachmentFileVII = function(file) {
            K3PartVIIFactory.downloadAttachmentFile(vm,file);
        }

        vm.removeFileX = function(file,index) {
            K3PartXFactory.removeFile(vm,file,index);
        }

        vm.downloadAttachmentFileX = function(file) {
            K3PartXFactory.downloadAttachmentFile(vm,file);
        }
        vm.removeFileXI = function(file,index) {
            K3PartXIFactory.removeFile(vm,file,index);
        }

        vm.downloadAttachmentFileXI = function(file) {
            K3PartXIFactory.downloadAttachmentFile(vm,file);
        }
        vm.removeFileXIII = function(file,index) {
            K3PartXIIIFactory.removeFile(vm,file,index);
        }

        vm.downloadAttachmentFileXIII = function(file) {
            K3PartXIIIFactory.downloadAttachmentFile(vm,file);
        }


        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

        vm.setChange = function(row, item) {

            if(row.ROW_TYPE == 'PL') {
                row.IS_CHANGED_FLAG = 'Y';
                row.ROW_OCCURRENCE = 1;
                row.COL_OCCURRENCE = 1;

                /*if(row.LINE_NO == 12) {
                    if(row.ATTRIBUTE_VALUE !== undefined) {
                        if(row.ATTRIBUTE_VALUE == 1) {
                            vm.checkbox_12_value = true;
                        }
                        else if(row.ATTRIBUTE_VALUE == 0) {
                            vm.checkbox_12_value = false;
                        }
                    }
                    else {
                        vm.checkbox_12_value = false;
                    }
                }*/
            }
            else if(row.ROW_TYPE == 'DT') {
                row.IS_CHANGED_FLAG = 'Y';
                row.ROW_OCCURRENCE = 1;
                row.COL_OCCURRENCE = 1;
            }
            else if(row.ROW_TYPE == 'CH') {

                for (let j = 0; j < item.Line_data_occ.length; j++){
                    item.Line_data_occ[j].ROW_OCCURRENCE = item.Row_Occurrence;
                    item.Line_data_occ[j].COL_OCCURRENCE = 1;
                    item.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
                }
                //row.ROW_OCCURRENCE = rowOccurrence;
                //row.COL_OCCURRENCE = 1;
            }



        }

        vm.setCountry = function(colIndex, countryCode) {
            K3PartIIIFactory.setCountry(vm, colIndex, countryCode);
        }

        vm.isJson = function(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        vm.reset = function(tabIndex) {
            console.log('reset button clicked');
        }

        vm.openCalendar = function(cell) {
            //var row = {};
            //row = vm.partnerList[index];
            //vm.daterow['calendar'].opened = true;
            cell['calendar'].opened = true;
        };

        // converts the fize to actual bytes..
        vm.formatBytes = function (bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        };

        vm.saveAndPost = function() {
            console.log('save and Post button clicked');
            if( vm.issueList.length > 0 && vm.issueList[0].SCENARIO_TYPE_CODE == 'EXAM') {
                if(vm.selectedIssue == '') {
                    AlertService.add("danger", 'Please select an issue from the list', 4000);
                    vm.isSaveClicked = false;
                }
                else {
                    vm.issueID = vm.selectedIssue.split('--')[0];
                    handleSave('oofs04');
                }
            }
            else {
                vm.issueID = 0;
                handleSave('oofs04');
            }
        }

        function handleSave(action_code) {

            vm.isSaveClicked = true;
            vm.saveData;
            let returnClobSettingsObj = {};
            let EXTK3FormSaveDtls = [];
            let allSaveData = [];
            let tabNames = ['TI', '1065_SK3'];
            let returnObj;
            let val;
            let message;

            vm.page1HeaderChanges = [];
            vm.page1ELinesChanges = [];
            vm.partIChanges = [];
            vm.partIIS1Changes = [];
            vm.partIIS2Changes = [];


            for(let j = 0; j < tabNames.length; j++) {
                if( j == 0) { // TI tab
                    vm.saveData = [];
                    vm.saveData = $filter("filter")(vm.allFormData, {IS_CHANGED_FLAG:'Y', ATTRIB_COL:'a'});
                }
                if(j == 1) { // SK3 tab
                    vm.saveData = [];

                    K3Page1Factory.obtainChanges(vm);
                    K3PartIFactory.obtainChanges(vm);
                    K3PartIIFactory.obtainChanges(vm);
                    K3PartIIIFactory.obtainChanges(vm);
                    K3PartIVFactory.obtainChanges(vm);
                    K3PartVFactory.obtainChanges(vm);
                    K3PartVIFactory.obtainChanges(vm);
                    K3PartVIIIFactory.obtainChanges(vm);
                    K3PartIXFactory.obtainChanges(vm);
                }
                allSaveData.push(vm.saveData);
            }
            //console.log('allSaveData=' + allSaveData);

            //console.log('TI data', allSaveData[0]);
            console.log('--- SK3 data to save --- ', allSaveData[1]);

            //vm.continue = true; // temporary mark it true

            if(vm.continue == false) {

                if(allSaveData[1].length > 0) {

                    let validationStat = validateSK3Data(allSaveData[1]);

                    if(validationStat == false) {

                        AlertService.add("danger", vm.ERROR_MESSAGE, 15000);
                        vm.isSaveClicked = false;
                    }
                    vm.continue = validationStat;

                }
                else {
                    vm.continue = true;
                }

            }


            if(vm.continue) {

                for(let key in allSaveData) {
                    vm.saveData = allSaveData[key];
                    if(key == 0) { // TI tab
                        for(let i= 0;i < vm.saveData.length;i++) {
                            returnObj = {};
                            returnObj['section_name'] = 'main';
                            returnObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                            returnObj['combination_key'] = vm.COMBINATION_KEY;
                            returnObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                            returnObj['chart_key'] = vm.saveData[i].CHART_REF_KEY;
                            returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REFERENCE_KEY;

                            if(typeof vm.saveData[i].ATTRIB_VALUE === 'object' && vm.saveData[i].SUB_FORM != null)
                                val = JSON.stringify(vm.saveData[i].ATTRIB_VALUE);
                            else
                                val = vm.saveData[i].ATTRIB_VALUE;

                            returnObj['allocation_amount'] = /*vm.saveData[i].ATTRIB_VALUE*/ val;
                            returnObj['flow_through'] = 0;
                            EXTK3FormSaveDtls.push(returnObj);
                        }
                    }
                    if(key == 1) { // SK3 tab
                        returnObj = {};
                        //returnObj['tab_name'] = 'SK3';
                        //returnObj['data_payload'] = vm.saveData;
                        for(let i = 0; i< vm.saveData.length; i++) {
                            returnObj = vm.saveData[i];
                            if(returnObj.LINE_NO != '24h') {
                                EXTK3FormSaveDtls.push(returnObj);
                            }
                        }
                    }
                }
                //console.log(EXTK3FormSaveDtls);

                message = "Partner Allocation Details successfully saved and posted";

                returnClobSettingsObj['tax_year'] = vm.TAX_YEAR
                returnClobSettingsObj['scenario'] = vm.scenario
                returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                returnClobSettingsObj['issue_id'] = vm.issueID;
                returnClobSettingsObj['pship_ptr_posting_key'] = vm.PSHIP_PTR_POSTING_KEY;
                returnClobSettingsObj['combination_key'] = vm.COMBINATION_KEY;
                returnClobSettingsObj['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                //returnClobSettingsObj['chart_key'] = vm.chart_key[0];

                sendDetails(action_code, returnClobSettingsObj,EXTK3FormSaveDtls, message);
            }

        }

        function validateSK3Data(sk3Data) {
            vm.HAS_ERROR = false;
            vm.ERROR_MESSAGE = null;
            console.log('sk3Data = ' , sk3Data);
            vm = K3PartIFactory.validateSK3DataPartI(vm, _.filter(sk3Data,{PART_NO:"I"}));

            if(!vm.HAS_ERROR) {
                vm = K3PartIIFactory.validateSK3DataPartII(vm, _.filter(sk3Data, {PART_NO: "II"}));
            }
            if(!vm.HAS_ERROR) {
                vm = K3PartIIIFactory.validateSK3DataPartIII(vm, _.filter(sk3Data,{PART_NO:"III"}));
            }

            if(!vm.HAS_ERROR) {
                vm = K3PartIVFactory.validateSK3DataPartIV(vm, _.filter(sk3Data,{PART_NO:"IV"}));
            }

            if(!vm.HAS_ERROR) {
                vm = K3PartVFactory.validateSK3DataPartV(vm, _.filter(sk3Data,{PART_NO:"V"}));
            }

            if(!vm.HAS_ERROR) {
                vm = K3PartVIFactory.validateSK3DataPartVI(vm, _.filter(sk3Data,{PART_NO:"VI"}));
            }

            if(!vm.HAS_ERROR) {
                vm = K3PartVIIIFactory.validateSK3DataPartVIII(vm, _.filter(sk3Data,{PART_NO:"VIII"}));
            }

            if(!vm.HAS_ERROR) {
                vm = K3PartIXFactory.validateSK3DataPartIX(vm, _.filter(sk3Data,{PART_NO:"IX"}));
            }

            return !vm.HAS_ERROR;
        }



        function sendDetails(action_code, returnClobSettingsObj, EXTK3FormSaveDtls, message) {

            PartnerAllocServicesNewFactory.savePartnerAllocationForm(action_code, returnClobSettingsObj,EXTK3FormSaveDtls).then(function(result) {

                if (result.data.errorMessage && result.data.errorMessage !== 'null') {
                    vm.isSaveClicked = false;
                    AlertService.add("error", result.data.errorMessage, 4000);
                } else {

                    AlertService.add("success", message, 4000);
                    let args = {
                        pship_leid: returnClobSettingsObj.pship_leid,
                        gridFilter: {
                            pship_leid: returnClobSettingsObj.pship_leid
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);

                    /*let params = {};

                    params['tax_year'] = vm.TAX_YEAR;
                    params['scenario'] = vm.scenario;
                    params['jcd_key']  = vm.jcd_key;
                    params['sso_id']   = vm.logged_in_user;
                    params['combination_key'] = vm.COMBINATION_KEY;
                    params['ptr_combination_key'] = vm.PTR_COMBINATION_KEY;
                    params['parts'] = [];

                    let attachmnentDetails = [];
                    let filesToDelete = [];

                    let attachmentParts = $filter("filter")(vm.all_1065SK3Data,{ROW_TYPE: 'AH'});
                    for(let x = 0; x < attachmentParts.length; x++) {
                        if(x == 0) {
                            params['form_key']  = attachmentParts[x].FORM_KEY;
                            params['schedule_name'] = attachmentParts[x].SCHEDULE_NAME;
                            params.parts.push(attachmentParts[x].PART_NO);
                            params['section_name'] = attachmentParts[x].SECTION_NAME;
                        }
                        else {
                            params.parts.push(attachmentParts[x].PART_NO);
                        }
                    }
                    //params['attachments'] = vm.attachmentTO_VII.attachmented_files.toString();

                    if(vm.attachmentTO_VII.hasOwnProperty('stored_files') == false ) {
                        attachmnentDetails.push(vm.attachmentTO_VII.partNo+'~'+vm.attachmentTO_VII.attachmented_files.toString());
                    }
                    else if(vm.attachmentTO_VII.hasOwnProperty('stored_files') &&  vm.attachmentTO_VII.stored_files.length == 0) {
                        attachmnentDetails.push(vm.attachmentTO_VII.partNo+'~'+vm.attachmentTO_VII.attachmented_files.toString());
                    }
                    else if(vm.attachmentTO_VII.hasOwnProperty('stored_files') &&  vm.attachmentTO_VII.stored_files.length > 0) {
                        attachmnentDetails.push(vm.attachmentTO_VII.partNo+'~'+(vm.attachmentTO_VII.attachmented_files.filter(x => vm.attachmentTO_VII.stored_files.indexOf(x) === -1)).toString());
                    }

                    // do same for all other parts - X, XI & XIII


                    params['attachments'] = attachmnentDetails;


                    if(vm.attachmentTO_VII.hasOwnProperty('files_to_delete') && vm.attachmentTO_VII.files_to_delete.length > 0) {
                        for(let x = 0; x < vm.attachmentTO_VII.files_to_delete.length; x++) {
                            filesToDelete.push(vm.attachmentTO_VII.files_to_delete[x]);
                        }
                    }

                    // do same for all other parts - X, XI & XIII

                    params['files_to_delete'] = (filesToDelete.length == 1) ? filesToDelete : filesToDelete.join(",");



                    console.log('params for saving attachments = ', params);

                    PartnerAllocServicesNewFactory.saveK3Attachments(URLS.saveAttachemnt,params).then(function(result){
                        if (result.callSuccess == null) {
                            vm.isSaveClicked = false;
                            AlertService.add("error", 'Error occurred saving the attachments. If this continues please contact support', 4000);
                        }
                        else if(result.callSuccess === "1" ) {
                            AlertService.add("success", message, 4000);
                            let args = {
                                pship_leid: returnClobSettingsObj.pship_leid,
                                gridFilter: {
                                    pship_leid: returnClobSettingsObj.pship_leid
                                }
                            };
                            $uibModalInstance.dismiss('cancel');
                            $rootScope.$emit('gridUpdate', args);
                        }

                    });*/


                };

            });

        }

        vm.checkValueEnteredInSection = function(sectionData) {
            let valueExists = false;

            for(let l = 0; l < sectionData.length; l++) {
                let line = sectionData[l];
                let data = _.filter(line.Line_data, {Line_Type: 'DATA_ROW'});
                for(let d = 0; d < data.length; d++) {
                    for(let dc = 0; dc < data[d].Line_data_occ.length; dc++) {
                        if( data[d].Line_data_occ[dc].ATTRIB_COL == '0') {
                            if('ATTRIBUTE_VALUE' in data[d].Line_data_occ[dc] && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE !== undefined) {
                                if(data[d].Line_data_occ[dc].ATTRIBUTE_VALUE != null && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE.trim().length != 0) {
                                    valueExists = true;
                                    break;
                                }
                            }

                        }
                        else if(data[d].Line_data_occ[dc].ATTRIB_COL != '0' &&  data[d].Line_data_occ[dc].hasOwnProperty('ATTRIBUTE_VALUE') && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE !== undefined &&  data[d].Line_data_occ[dc].ATTRIBUTE_VALUE != 0) {
                            valueExists = true;
                            break;

                        }
                    }
                    if(valueExists) break;
                }
                if(valueExists) break;
            }

            return valueExists;
        }

        vm.checkValueEnteredInPart8 = function(partData) {
            let valueExists = false;

            for(let l = 0; l < partData.length; l++) {
                let line = partData[l];
                let data = _.filter(line.Line_data, {Line_Type: 'DATA_ROW'});
                for(let d = 0; d < data.length; d++) {
                    if(data[d].Is_Multi == 'Y') {
                        for(let dc = 0; dc < data[d].Line_data_occ.length; dc++) {
                            if( data[d].Line_data_occ[dc].ATTRIB_COL == '0' || data[d].Line_data_occ[dc].ATTRIB_COL == 'a') {
                                if('ATTRIBUTE_VALUE' in data[d].Line_data_occ[dc] && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE !== undefined) {
                                    if(data[d].Line_data_occ[dc].ATTRIBUTE_VALUE != null && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE.trim().length != 0) {
                                        valueExists = true;
                                        break;
                                    }
                                }

                            }
                            else if( (data[d].Line_data_occ[dc].ATTRIB_COL == 'b' || data[d].Line_data_occ[dc].ATTRIB_COL == 'c') && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE != 0) {
                                valueExists = true;
                                break;

                            }
                        }
                        if(valueExists) break;
                    }
                    else if (data[d].Is_Multi == 'N' && (data[d].Line_no != '1' && data[d].Line_no != '5') ) {
                        for(let dc = 0; dc < data[d].Line_data_occ.length; dc++) {
                            if( data[d].Line_data_occ[dc].ATTRIB_COL == 'a') {
                                if('ATTRIBUTE_VALUE' in data[d].Line_data_occ[dc] && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE !== undefined) {
                                    if(data[d].Line_data_occ[dc].ATTRIBUTE_VALUE != null && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE.trim().length != 0) {
                                        valueExists = true;
                                        break;
                                    }
                                }

                            }
                            else if( (data[d].Line_data_occ[dc].ATTRIB_COL == 'b' || data[d].Line_data_occ[dc].ATTRIB_COL == 'c') && data[d].Line_data_occ[dc].ATTRIBUTE_VALUE != 0) {
                                valueExists = true;
                                break;

                            }
                        }
                        if(valueExists) break;
                    }

                }
                if(valueExists) break;
            }

            return valueExists;
        }

    }

    return controllers;

});
