define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.entityClassificationAutomation', [])
    .factory("EntityClassificationFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', 'GlobalService','DataFiltersFactory','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log, AlertService,GlobalService,DataFiltersFactory,JsonObjectFactory,workspaceFactory,GENERAL_CONFIG) {
        var entityClassificationFactory = {};
        
        entityClassificationFactory.saveReviewEntityClassification = function(_callName, _data){
        	var checked = '';
        	 if (_data.rowData.REVIEW === 1) {
           		 checked = true;
           	 }else {
           		 checked = false;
           	 }
        	 
        	 var params = {
      	           "action_code" : 'g9uv87', 
      	           "scenario" :GlobalService.globalParams.scenario,
      	           "jcd_key" : GlobalService.globalParams.jcd_key,
      	           "tax_year" : GlobalService.globalParams.tax_year,
      	        
      	           "checkedFlag" : checked
                }
        	 
        	 var jsonObj = JSON.stringify(_data.rowData);
        	 var chart_key = _data.rowData.SUBMIT_CHART
        	 var acct_key  = _data.rowData.POSTING_ACCT
        	 var combination_key = _data.rowData.COMBINATION_KEY
        	 params =  _.extend({jsonObj:jsonObj}, params);
        	 
        	 var promise= JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/saveJsonObject', params).then(function(data) {
        		 if (data.callSuccess === "1") {
        			 if (checked) {
     					AlertService.add("success", "Data has been checked", 3000);
            			 }else{
            				AlertService.add("success", "Data has been unchecked", 3000);
            			 }
//        			 var args = {
//        					 chart:chart_key,
//        					 acct:acct_key,
//        					 combi:combination_key,
// 						
// 						gridFilter : {
// 							chart:chart_key,
// 							 acct:acct_key,
// 							 combi:combination_key,
// 						}
// 					};
//    
//        			 $rootScope.$emit('gridUpdate', args);
        		 } else {
 					   AlertService.add("error","Data retrieval failed", 3000);
 				}
				
			});
        	 
            return promise;

        };
        return entityClassificationFactory;
    }]);


return services;


});