
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.SourcingService',[])

    .factory('SourcingServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
	        var sourcingServiceFactory = {};
	        sourcingServiceFactory.url = {};
	        sourcingServiceFactory.url.attribute_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=w9eqdy";
	        sourcingServiceFactory.url.qar_custom_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=u2qza7";
	        sourcingServiceFactory.url.qar_raf_save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=yswmqc";
	        sourcingServiceFactory.url.qar_delete_data = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0nv1s7";
	
	
	        sourcingServiceFactory.saveDefaults = function(_data,Details){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "sourcing-grid-save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: sourcingServiceFactory.url.attribute_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        sourcingServiceFactory.saveQarCustomTag = function(_data,Details){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "qar-custom-tag-save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: sourcingServiceFactory.url.qar_custom_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        sourcingServiceFactory.saveQarRafTag = function(_data,Details){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "qar-raf-tag-save";
            console.log(params);
            var promise = $http({
                method: "post",
                url: sourcingServiceFactory.url.qar_raf_save,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        sourcingServiceFactory.deleteQarRafTag = function(_data,Details){

            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(Details);
            var jsonSettings =  JSON.stringify(_data);
            //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
            
            console.log(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);
            params.process_name =  "qar_delete_data";
            console.log(params);
            var promise = $http({
                method: "post",
                url: sourcingServiceFactory.url.qar_delete_data,
                data: params
            }).then(function (response) {
                var status = response.status;
                return response;
            });
            return promise;
        };
        
        return sourcingServiceFactory
    }])

    return services;

});