define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.TaxCycleWorkflowController', [])

        .filter('highlight', ['$sce', function ($sce) {
            return function (text, phrase) {
                if (phrase) text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
                    '<span class="highlighted">$1</span>')
                return $sce.trustAsHtml(text)
            }
        }])

        .controller('TaxCycleWorkflowController', ['workspaceFactory', 'WorkflowTCFactory', '$uibModal', '$scope', '$rootScope', 'GlobalService', '$sce', 'processMapFactory', 'USER_MENU', 'menuDataService', TaxCycleWorkflowController])
        .controller('userAssignModalCtrl', ['$uibModalInstance', 'JsonObjectFactory', 'AlertService', 'GENERAL_CONFIG', 'rowData', 'USER_SETTINGS','workspaceFactory','MessageFactory','$state','$filter', userAssignModalCtrl])
        .controller('userActionModalCtrl', ['$uibModalInstance', 'workspaceFactory', 'WorkflowTCFactory', 'GlobalService', 'DataFiltersFactory', '$timeout', 'active_screen', userActionModalCtrl])
        //  .controller('userAssignModalCtrl', ['$uibModalInstance', 'JsonObjectFactory', 'AlertService', userAssignModalCtrl]);
        .controller('taxUserAssignCtrl', ['USER_SETTINGS', 'GENERAL_CONFIG', '$uibModalInstance', 'JsonObjectFactory', 'AlertService', 'rowData', taxUserAssignCtrl]);

    function TaxCycleWorkflowController(workspaceFactory, WorkflowTCFactory, $uibModal, $scope, $rootScope, GlobalService, $sce, processMapFactory, USER_MENU, menuDataService) {
        console.log("_TaxCycleWorkflow is RUNNING");
        var vm = this;
        vm.pageTitle = "Tax Placemat";
        vm.showFilter = false;
        vm.highlightRows = {num:'',activity_key:''};
        //{"process_id":4,"activity_key":"617"}
        if(GlobalService.getUserSession('task_comment_data')){
            vm.highlightRows = JSON.parse(GlobalService.getUserSession('task_comment_data'));
            vm.highlightRows['num'] = vm.highlightRows.process_id;
        }

        vm.taxTypeDDsettings = {
            scrollableHeight: '200px',
            left: '94px',
            scrollable: true,
            enableSearch: true
        };
        vm.taxTypeDetailsSelection = [];
        vm.customFilter = 'a';
        vm.taxTypeDetails = [];
        var findObj = {};
        vm.taxYears = _.clone(GlobalService.inputs["tax_year"].values);
        findObj["value"] = GlobalService.globalParams["tax_year"];
        vm.selectedYear = _.find(vm.taxYears, findObj);
        var tax_data = {};
        vm.noData = false;
        //caching starts here
        var custom_data_key = "TCX324522";
        var cachedData = workspaceFactory.getDataCache(custom_data_key);

        var checked_tax_type_data_key = "CTTDKC324522456777";
        var cached_checked_tax_type_data = workspaceFactory.getDataCache(checked_tax_type_data_key);

        var tax_type_data_key = "TTDKC4678765";
        var cached_tax_type_data = workspaceFactory.getDataCache(tax_type_data_key);

        /* percentage caching 6/24/2019 */
        var percent_data_key = "PERK890890";
        var cached_percentage_data = workspaceFactory.getDataCache(percent_data_key);
        vm.percentile = 0;

        var active_tp_screen = "ATPS24682244";
        var cached_active_tp_screen = workspaceFactory.getDataCache(active_tp_screen);
        //caching ends here

        vm.data = {}
        getTaxTypeData();

        vm.clearSearch = function () {
            vm.filterData = '';
        };

        workspaceFactory.workflowObj = false;


        vm.goToScreen = function (_activity_key, _screen_key, _dummy_activity) {
            var activity;
            if(!!_dummy_activity){
                activity =  findActivity("544");
            }else{
                activity =  findActivity(_activity_key);
            }
            var screen =  findScreen(activity,_screen_key);
            menuDataService.removeSelected();
            activity.selected = 'selected';
            menuDataService.setSelected(activity);
            workspaceFactory.setActivity(activity, true, screen);
        };

        function findActivity(_activity_key){
            var activity = null;
            function search(value2){
                if(value2.type === "activity" && value2.activity_key === _activity_key ){
                    activity = value2;
                    return false;
                }
                if(value2.children){
                    _.forEach(value2.children, function (_value,_key) {
                        search(_value);
                    });
                }
            };
            _.forEach(USER_MENU, function (value,key) {
                if(value.children) {
                    search(value);
                }
            });
            return activity;
        }

        function findScreen(_activity,_screen_key){
            var  screen = _.find(_activity.screens, {screen_key:_screen_key } );
            return screen;
        }

        vm.onCircleClick = function (event, row, matItem) {
            console.log("circle fired");
            event.stopPropagation();
            var data = {};
            data.actionItem = row;
            data.taxYear = vm.selectedYear.value;
            data.taxType = taxTypeSelection;
            data.taxTypeDetailsSelection = vm.taxTypeDetailsSelection;
            data.reloadBackground = getTaxData//vm.getData;
            data.placematId = tax_data.placematId;
          //  data.reloadBackground = getTaxData//vm.getData;
            var modalObj = {};

            modalObj.animation = true,
                modalObj.template = 'app/components/taxCycleWorkflow/signOff-actions.html',
                modalObj.controler = 'taxUserAssignCtrl as ctrl',
                modalObj.windowClass = 'tax-userAssign-modal',
                modalObj.backdrop = 'static',
                //   modalObj.size= 'md';

                vm.openModal(modalObj, data)
        };

        vm.onCrossHairClick = function (event, row, matItem, selectedRow) {
            event.stopPropagation();
            vm.filters = {
                jcd_key : selectedRow.jcd_key,
                jcd_ta_key : selectedRow.jcd_ta_key,
                activity_key : selectedRow.activity_key,
                screen_key : selectedRow.screen_key,
                screen_type : "grid-standard"
            };
            workspaceFactory.activeScreen.filter_key = selectedRow.filter_group_key;
            delete workspaceFactory.activeScreen.filters;
            /*workspaceFactory.activeScreen.filter_key = selectedRow.filter_group_key;
            workspaceFactory.activeScreen.filter_inherit = 1;
            workspaceFactory.activeScreen.activity_key = selectedRow.activity_key;
            workspaceFactory.activeScreen.screen_key = selectedRow.screen_key;
            workspaceFactory.activeScreen.parent = {
                screens: row.children
            };
            workspaceFactory.activeScreen.jcd_key = selectedRow.jcd_key;
            workspaceFactory.activeScreen.jcd_ta_key = selectedRow.jcd_ta_key;
            delete workspaceFactory.activeScreen.filters;*/
            GlobalService.setFilters(vm.filters);
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/taxCycleWorkflow/user-actions.html',
                controller: 'userActionModalCtrl as ctrl',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    active_screen: function () {
                        return vm.filters;
                    }
                }
            });
        };

        vm.onUserClick = function (event, row, matItem) {
            console.log("userclick fired");
            event.stopPropagation();
            var data = {};
            _.extend(row,{
                parentActive : matItem.active,
                parentlabel : matItem.label,
                num : matItem.num,
                parentProcess_id : matItem.process_id,
                parentProcess_from : matItem.process_from,
                parentProcess_to : matItem.process_to
            })
            data.actionItem = row;
            data.taxYear = vm.selectedYear.value;
            data.taxType = taxTypeSelection;
            data.taxTypeDetailsSelection = vm.taxTypeDetailsSelection;
            data.reloadBackground = getTaxData//vm.getData;
            data.placematId = tax_data.placematId;
            var modalObj = {};
            modalObj.animation = true,
                modalObj.template = 'app/components/taxCycleWorkflow/user-Assign-Modal.html',
                modalObj.controler = 'userAssignModalCtrl as ctrl',
                modalObj.keyboard = false;
                modalObj.backdrop = "static";
                modalObj.size = 'md'
            vm.openModal(modalObj, data)
        };

        vm.openModal = function (modalObj, _data) {
            vm.rowData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                windowClass: modalObj.windowClass,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                console.log('Modal dismissed at: ' + new Date());
                console.log('$scope.rowData', $scope.rowData);
            });
        };

        function taxTypeSelection(detailSelection) {
            var taxTypeString = [];
            _.each(detailSelection, function (v, k) {
                taxTypeString.push(v.id);
            });
            return taxTypeString.toString();
        };

        function loadTaxTypeData(){
            vm.loading = true;
            WorkflowTCFactory.getTaxTypeDetails().then(function (data) {
                if (data.callSuccess === "1") {
                    vm.showFilter = true;
                    vm.taxTypeDetails = data.data;
                    _.each(data.data, function (v, k) {
                        vm.taxTypeDetailsSelection.push(v);
                    });
                    processMapFactory.fetchTaxPlacemats(GlobalService.getClient().client_key).then(function (data) {
                        if(data.callSuccess === "1" ){
                            var keys = {};
                            keys["screen_key"] = parseInt(workspaceFactory.activeScreen.screen_key);
                            tax_data = _.find(data.data, keys);
                            if(tax_data == undefined){
                                tax_data = _.find(data.data, {placematName: cached_active_tp_screen});
                            }
                            workspaceFactory.setDataCache("all_placemats", data.data);
                            getTaxData(vm.selectedYear.value, taxTypeSelection(vm.taxTypeDetailsSelection), tax_data.placematId);
                        }
                    });

                    workspaceFactory.setDataCache(tax_type_data_key, vm.taxTypeDetails);
                    console.log('tax type details : ' + vm.taxTypeDetails);
                } else {
                    if (data.errorMessage === "no access") {
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    } else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        };

        function getTaxTypeData() {
           var current_screen = workspaceFactory.activeScreen.label;
            if (!!cachedData) {
                if(current_screen !== cached_active_tp_screen){
                    loadTaxTypeData();
                }else{
                    vm.data = cachedData;
                    rowHighlight(vm.data);
                    vm.taxTypeDetailsSelection = cached_checked_tax_type_data;
                    vm.taxTypeDetails = cached_tax_type_data;
                    vm.percentile = cached_percentage_data;
                    vm.showFilter = true;
                }
            } else {
                loadTaxTypeData();
            }
        };

        /* row expand or highlight 08/21/2019  */
        function rowHighlight(data){
            vm.TotalChildrenLength = 0;
            /* total children Length  */
            _.each(data, function (item) {
                vm.TotalChildrenLength = vm.TotalChildrenLength + _.filter(item.values, "signoff_status").length;
                _.each(item.values,function(i){
                    if(!i.expanded){
                        i.expanded = (vm.highlightRows.num == item.process_id && vm.highlightRows.activity_key==i.activity_key);
                        if(i.expanded){
                            i.hightlight=i.expanded;
                        }
                    }
                })
            });
        }

        function getTaxData(tax_year, taxTypeString, id) {
            WorkflowTCFactory.loadObject(tax_year, taxTypeString, id).then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data.data;

                    /*  percentage calculation 6/24/2019 */
                    if (vm.data.length > 0) {
                        vm.noData = false;
                        vm.completedStatus = 0;
                        rowHighlight(vm.data);
                        /* completed Status length  */
                        _.each(vm.data, function (item) {
                            vm.completedStatus = vm.completedStatus  +  _.filter(item.values, {
                                signoff_status: 'C'
                            }).length;
                        });

                        vm.percentile = Math.round((vm.completedStatus * 100) / vm.TotalChildrenLength) || 0;
                        /* 5/23/2019 */
                    }else{
                        vm.noData = true;
                    }
                    workspaceFactory.setDataCache(custom_data_key, vm.data); /* 5/23/2019 */
                    workspaceFactory.setDataCache(checked_tax_type_data_key, vm.taxTypeDetailsSelection);
                    workspaceFactory.setDataCache(percent_data_key, vm.percentile);
                    workspaceFactory.setDataCache(active_tp_screen, workspaceFactory.activeScreen.label);
                } else {
                    if (data.errorMessage === "no access") {
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    } else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
                vm.loading = false;
            });
        };

        vm.getData = function () {
            vm.loading = true;
            if(tax_data.placematId == undefined){
                var all_placemats = workspaceFactory.getDataCache("all_placemats");
                tax_data = _.find(all_placemats, {placematName: cached_active_tp_screen});
            }
            getTaxData(vm.selectedYear.value, taxTypeSelection(vm.taxTypeDetailsSelection), tax_data.placematId);
        };
    }

    function userAssignModalCtrl($uibModalInstance, JsonObjectFactory, AlertService, GENERAL_CONFIG, rowData, USER_SETTINGS,workspaceFactory,MessageFactory,$state,$filter) {
        var vm = this;
        console.log("rowData", rowData);
        console.log("workaspdfas",workspaceFactory);
        vm.rowData = rowData;
        vm.pageTitle = "User Assign";
        vm.selectedUsers = [];
        vm.taskAction = {
            targetDate : '',
            assign : [],
            comment_key : ''
        }

        vm.dateOptions = {
            minDate: new Date()
        }
        /* get user assign list with target date 08/15/2019*/
        vm.getUserAssignDtls = function(){
            var url = GENERAL_CONFIG.admin_api_url + "/getActivityAssginDetailsByKey";
            var params = {
                activity_key: rowData.actionItem.activity_key,
                client_key: USER_SETTINGS.user.client_key,
            }

            console.log("params", params);
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    // vm.taskAction.targetDate = (data && data.taskTargetDate) ? data.taskTargetDate : '';
                    vm.taskAction.targetDate= (data && data.taskTargetDate) ? new Date(data.taskTargetDate) :'';
                    vm.taskAction.assign = (data && data.assign.length>0) ? data.assign : [];
                    vm.taskAction.comment_key = (data && data.commentKey) ? data.commentKey : '';
                    console.log("saved");
                    if(rowData && rowData.placematId !== undefined){
                        vm.rowData.reloadBackground(rowData.taxYear,rowData.taxType(rowData.taxTypeDetailsSelection), rowData.placematId);
                    }

                } else {
                    console.log("error")
                }

            });
        }
        vm.getUserAssignDtls();
        vm.getUser = function (val) {
            var params = {
                "action_id": 76,
                "search_string": val
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };

        vm.removeUser = function (user, index) {
            var url = GENERAL_CONFIG.admin_api_url + "/removeUserSignoff";
            var params = {
                activity_key: rowData.actionItem.activity_key,
                client_key: USER_SETTINGS.user.client_key,
                ssoId: user,
                commentKey : vm.taskAction.comment_key
            }

            console.log("paramsdelet", params);
            //    vm.selectedUsers.splice(index, 1);
            vm.taskAction.assign.splice(index, 1);
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === 1) {
                    if(rowData && rowData.placematId !== undefined){
                        vm.rowData.reloadBackground(rowData.taxYear,rowData.taxType(rowData.taxTypeDetailsSelection), rowData.placematId);
                    }


                } else {
                    console.log("delteed")
                }

            });
        }
        vm.assignUser = function (user) {
            var check = _.some(vm.taskAction.assign,{NAME:user.NAME});
            /* check if object is already there or not, if it is not there push the user object */
            if(check){
            }else{
                vm.taskAction.assign.push(user);
            }

        }
        vm.onSelect = function (user) {
        //    vm.selectedUsers.push(angular.copy(item));
            var url = GENERAL_CONFIG.admin_api_url + "/activityUserSignoffAssign";
            var users = [];
            _.each(vm.taskAction.assign,function(item){
                users.push({NAME: item.NAME,SSO_ID: item.VALUE});
            })
            var params = {
                activity_key: rowData.actionItem.activity_key,
                client_key: USER_SETTINGS.user.client_key,
                userlist:  JSON.stringify(users)//item.VALUE
            }
            console.log("params", params);
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === 1) {
                    console.log("saved");
                  // vm.rowData.reloadBackground(rowData.taxYear,rowData.taxType(rowData.taxTypeDetailsSelection));
                 //  vm.rowData.reloadBackground(rowData.taxYear,rowData.taxType(rowData.taxTypeDetailsSelection),rowData.placematId);
                } else {
                    console.log("error")
                }

            });
        };


        /* assign user save intergration with task 08/20/2019 */
        vm.taskSave = function(){
            var taskUrl = GENERAL_CONFIG.base_url + "/addComment";
            var assign = _.map(vm.taskAction.assign, 'VALUE').toString();
            var screenKey = workspaceFactory.activeScreen.screen_key ? workspaceFactory.activeScreen.screen_key : '-100',
                activityKey = workspaceFactory.activeScreen.activity_key ? workspaceFactory.activeScreen.activity_key : '-102';
            var row_key,col_key;
            if (rowData) {
                row_key = rowData.actionItem.parentProcess_id;
                col_key = rowData.actionItem.activity_key;
            }
            var taskObject = {
                state: $state.current.name,
                filters: JSON.stringify([{tax_year:rowData.taxYear,tax_type:_.map(rowData.taxTypeDetailsSelection,'id').join(',')}]),
                tableState: '',
                data: JSON.stringify({process_id:rowData.actionItem.parentProcess_id,activity_key:rowData.actionItem.activity_key}),
                type: "taxPlacemat",
                screen_key: screenKey,
                activity_key: activityKey,
                row_key: row_key,
                col_key: col_key,
                element: '',//gridData.selected.select_type,
                comment_key: vm.taskAction.comment_key ? vm.taskAction.comment_key : '',
                is_public: 0,
                message: "This Action is Coming from TaxPlacemat" + rowData.actionItem.name,
                notify: '',
                tags: '',
                value: '',
                subject: rowData.actionItem.name,
                task_action_item: 1,
                task_target_date: $filter('date')(vm.taskAction.targetDate, 'yyyy/MM/dd'),
                assign: assign,
                tax_activity_key : rowData.actionItem.activity_key,
                source  : 'T'
            }
            if(vm.taskAction.comment_key){
                MessageFactory.CommentCrud(GENERAL_CONFIG.base_url + '/updateComment', taskObject).then(function (data) {
                    console.log(data);
                    vm.crudLoading = false;
                    if (data.callSuccess && data.callSuccess === "1") {
                        vm.onSelect(assign);
                        $uibModalInstance.dismiss('cancel');
                        if(rowData && rowData.placematId !== undefined){
                            vm.rowData.reloadBackground(rowData.taxYear,rowData.taxType(rowData.taxTypeDetailsSelection),rowData.placematId);
                        }
                      //  $rootScope.setReloadCommentsFlag(true);
                    } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                    }
                });
            }else{
                MessageFactory.CommentCrud(taskUrl, taskObject).then(function (data) {
                    console.log(data);
                    if (data.callSuccess && data.callSuccess === "1") {
                        vm.onSelect(assign)
                        vm.cancel();
                        if(rowData && rowData.placematId !== undefined){

                            vm.rowData.reloadBackground(rowData.taxYear,rowData.taxType(rowData.taxTypeDetailsSelection),rowData.placematId);
                        }
                    } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                    }
                });
            }
        }
        vm.removeItem = function (index) {
            vm.selectedUsers.splice(index, 1);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function userActionModalCtrl($uibModalInstance, workspaceFactory, WorkflowTCFactory, GlobalService, DataFiltersFactory, $timeout, active_screen) {
        var vm = this;
        vm.pageTitle = "Actions";
        vm.loader = true;
        vm.noAction = false;
        //var keys = workspaceFactory.activeScreen;
        var keys = active_screen;
        DataFiltersFactory.onLoad();

        vm.getAllActions = function () {
            WorkflowTCFactory.loadGridActions(keys.screen_key).then(function (data) {
                if (data.callSuccess === "1") {
                    if (data.jsonObject.length !== 0) {
                        vm.ActionList = data.jsonObject;
                    } else {
                        vm.noAction = true;
                    }
                    vm.loader = false;
                } else {
                    if (data.errorMessage === "no access") {
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    } else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
            });
        };

        vm.getAllActions();
        vm.goToScreen = function (key) {
            var allSelected = true;
            vm.selectMsg = false;
            var filterParams = workspaceFactory.activeScreen.filters.getFilterParams();
            var filterGroups = GlobalService.getFilterGroup();
            var tableState = {
                colSearch: [],
                pagination: {
                    start: 0
                },
                search: {},
                sort: {}
            };
            var filterJson = _.map(filterGroups, function (item) {
                return {
                    filter_key: item.filter_key,
                    name: item.param_name,
                    value: filterParams[item.param_name]
                }
            });

            GlobalService.setActionKey(key);
            var filter_json = filterJson;
            var table_state = tableState;
            var type = 'link';
            _.each(filterJson, function (filter) {
                if(Array.isArray(filter.value)){
                    if(filter.value.length == 0){
                        allSelected = false;
                    }
                }else{
                    if(!filter.value){
                        allSelected = false;
                    }
                }
            });
            if (allSelected) {
                vm.cancel();
                workspaceFactory.loadLink(type, null, keys.activity_key, keys.screen_key, filter_json, table_state, null, key);
            } else {
                vm.selectMsg = true;
                $timeout(function () {
                    vm.selectMsg = false;
                }, 2000);
            }
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function taxUserAssignCtrl(USER_SETTINGS, GENERAL_CONFIG, $uibModalInstance, JsonObjectFactory, AlertService, rowData) {
        var vm = this;
        vm.rowData = rowData;
        vm.pageTitle = "User Assign";
        /*  vm.selectedTaxUsers = [
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
             {"usr_name": "Heath J Stein", "usr_num": "123456777777"},
         ] */

        vm.nullobj = [
        /*     {
            "key": "212544474",
            "value": "Heath Stein - (212544474)"} */
        ]

        vm.selectedTaxUsers = (rowData.actionItem && rowData.actionItem.userAssign) ? rowData.actionItem.userAssign : vm.nullobj;

        vm.signOffStatus = rowData.actionItem.signoff_status;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function () {

            var url = GENERAL_CONFIG.admin_api_url + "/updateSignOffStatus";
            var params = {
                activity_key: rowData.actionItem.activity_key,
                client_key: USER_SETTINGS.user.client_key,
                status: vm.signOffStatus
            }
            console.log("params", params);
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === 1) {
                    console.log("saved");
                    //  vm.rowData.reloadBackground();
                    if(rowData && rowData.placematId!= undefined){
                        vm.rowData.reloadBackground(rowData.taxYear,rowData.taxType(rowData.taxTypeDetailsSelection), rowData.placematId);
                    }

                    vm.cancel();
                } else {
                    console.log("error")
                }

            });
        };
    }

    return controllers;
});