/**
 * @author Vijay Ramagiri This is to get and save Interest Expenses 
 * 
 * 
 */
define(
		[ 'angular',
		    './InterestRnDExpenseApprService',
		],
		function() {
			'use strict';

			var controllers = angular
					.module('app.TotalRnDExpenseCtrl', ['app.InterestRnDExpenseApprService'])
					.controller('totalRnDExpenseCtrl',
							[ '$rootScope', '$scope', '$state', '$log',	'JsonObjectFactory', 'AlertService','GlobalService',
									'$timeout', '$uibModal','$uibModalInstance','rowData', 'gridData','GENERAL_CONFIG','USER_SETTINGS','workspaceFactory','$filter','InterestExpenseFactory','$http', totalRnDExpenseCtrl ])
					 
				/*	controllers.directive('allowDecimalNumbers', function() {
						return { restrict : 'A', link : function(scope, elm, attrs, ctrl) {
								elm.on('keydown', function(event) {
									var $input = $(this);
								console.log("-->  $input : ", $input);
									var value = $input.val();
								console.log("-->  value : ", value);
									value = value.replace(/[-+][^0-9\.]/g,'')
									
									var findsDot = new RegExp(/\./g)
									var containsDot = value.match(findsDot)
									if (containsDot != null && ([ 46,110,190 ].indexOf(event.which) > -1)) {
											event.preventDefault();
											return false;
									}
									$input.val(value);
									if (event.which == 64 || event.which == 16) {
										// numbers
										return false;
									}
									if ([ 8, 13, 27, 37, 38, 39, 40, 110 ].indexOf(event.which) > -1) {
										// backspace,enter,escape,arrows
										return true;
									} else if (event.which >= 48 && event.which <= 57) {
										// numbers
										return true;
									} else if (event.which >= 96 && event.which <= 105) {
										// numpad number
										return true;
									} else if ([ 46, 110, 190 ].indexOf(event.which) > -1) {
										// dot and numpad dot
										return true;
									} else {
										event.preventDefault();
										return false;
									}
					});
				}
			}
		})*/
			function totalRnDExpenseCtrl($rootScope, $scope, $state, $log,
					JsonObjectFactory, AlertService, GlobalService, $timeout, $uibModal, $uibModalInstance, rowData, gridData, GENERAL_CONFIG, USER_SETTINGS,workspaceFactory, $filter, InterestExpenseFactory,$http) {

				var vm = this;	
				vm.userSettings = USER_SETTINGS;
				//Logged user id
				vm.logged_in_user = vm.userSettings.user.sso_id;
				//Show the loading Spinner
				vm.loadingData = true;	
				//A flag to enable and disable save button
				vm.isSaveClicked = false;
				//A edit modal
				vm.isEditMode = false;
				//A enable SIC CODE dropdown for given ME
				vm.isEnableSICCode = false;
				//Enable or Disable SIC CODE dropdown while loading the sic codes
				vm.isLoadingMode = false;
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				//Get the Grid filter Scenario
				vm.scenario = GlobalService.globalParams.scenario;
				//Get the jcd_key from left nav
				vm.jcd_key  = GlobalService.globalParams.jcd_key;
				
				//To get the Lock_Y_N flag from backend
				var lockparams = {
						"action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
					};				
				JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
					vm.is_locked = data.jsonObject;				
					//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
					$scope.$watch(function() { return vm.is_locked }, function() {
						if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
							vm.cancel();										
							AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
							return;
						}							
					});
				});
				//Get the Parent ME Drop-Down
				vm.getParentMeDropdown = function() {
					var params = {"action_code" : 'vxg30f', "sso_id" : vm.logged_in_user, };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
							vm.parentMEs = data.jsonObject;
							console.log("vm.parentMEs : ", vm.parentMEs );
						});
				}
				vm.getParentMeDropdown();		
				
				//Get the SIC CODE Drop-Down				
				vm.getSicCodeDropdown = function(selectedMeKey) {
					vm.isLoadingMode = true;
					var params = {"action_code" : 'ju4y7t', "me_key" : selectedMeKey, };
					JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
						if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
		                    AlertService.add("error", data.errorMessage);
		                    return false;
		                }else{
		                	vm.sicCodeFromCodeMast = data.jsonObject;  
		                	vm.isEnableSICCode = true;
		                	vm.isLoadingMode = false;
		                }
					});
				}
				//vm.getSicCodeDropdown();	
				
				//Get the CODE_DESCRIPTION for the given SIC_CODE's  CODE_KEY 
				function getSicCodeDesc(codeKey) {
					var selectedSicCodeDesc;
					for(var indx in vm.sicCodeFromCodeMast){							
						if(vm.sicCodeFromCodeMast[indx].CODE_KEY == codeKey){
							selectedSicCodeDesc = ''+vm.sicCodeFromCodeMast[indx].CODE_DESCRIPTION;							
						}
					}
					return selectedSicCodeDesc;
				};
								
				//Set the Saved Values to the Modal parameters
				vm.setSavedValues = function(){
					//Grid Data from Branch Tax  Screen
					if (gridData != null) {	
						//console.log("::: ->  -> gridData:: ", gridData);
						//console.log("::: ->  ->  rowData:: ", rowData);
						var tempGridData = _.clone(gridData), groups = Object
								.create(null), result, finalKeyCheck = 0;					
						
						vm.gridDataMultipleSelect = _.clone(tempGridData);
						if (vm.gridDataMultipleSelect != undefined) {	
							//console.log("::: ->  ->  gridData:: ", vm.gridDataMultipleSelect);
							if (vm.gridDataMultipleSelect.rowData != undefined) {
								vm.adjKey = vm.gridDataMultipleSelect.rowData.ADJ_KEY;													
								vm.comments = vm.gridDataMultipleSelect.rowData.COMMENTS;
								vm.meKey = ''+vm.gridDataMultipleSelect.rowData.ME_KEY;
								vm.getSicCodeDropdown(vm.meKey);
								vm.sicCodeKey = ''+vm.gridDataMultipleSelect.rowData.SIC_CODE_KEY;
								vm.amount = vm.gridDataMultipleSelect.rowData.T_861_ADJUSTMENTS_MANUAL;								
								vm.adjName = vm.gridDataMultipleSelect.rowData.ADJ_NAME;
								vm.object_id = vm.gridDataMultipleSelect.rowData.object_id;
								vm.isEditMode = true;	
								vm.loadingData = false;								
							}else{
								vm.loadingData = false;
								vm.isEditMode = false;
							}							
						}
					}
				}				
				vm.setSavedValues();			
				
				//Delete Interest Expense Adjustment amount
				vm.remove = function(){
					vm.save('delete');
				};
				
				//Saving the Data for both Non Creditable and Creditable Tax forms 
				vm.save = function(saveFlag){					
					if(vm.adjName == null || vm.adjName == '' || vm.meKey == null || vm.meKey == '' 
							|| vm.sicCodeKey == null	|| vm.sicCodeKey == '' ){
						AlertService.add("error", "Please select/enter all the fields.",4000);
						return;
					}
					
					if(vm.amount == null || vm.amount == ''){
						AlertService.add("error", "Please enter a valid Adjustment Amount.",4000);
						return;
					}
			
					//Disable the Save button till the processing completes
					vm.isSaveClicked = true;
					var message = "";									
																		
					var returnClobSettingsObj = {};
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;					
					returnClobSettingsObj['tax_year'] = GlobalService.globalParams.tax_year;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['jcd_key'] = GlobalService.globalParams.jcd_key;
					returnClobSettingsObj['scenario'] = GlobalService.globalParams.scenario;
					returnClobSettingsObj['object_id'] = vm.object_id;
					
					console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
										
					var returnObj = {};					
					returnObj['ADJUSTMENT_NAME'] = vm.adjName;			
					returnObj["ME_KEY"] = vm.meKey;					
					returnObj["SIC_CODE"] = vm.sicCodeKey;						
					returnObj['AMOUNT'] = vm.amount;
					returnObj['COMMENTS'] = vm.comments;
					returnObj['FORM_NO'] = ''; //'Non - 8916A';
					returnObj['IS_SINGLE'] = 'S';
					if(saveFlag == 'save') {
						if(vm.adjKey){
							if(vm.adjKey!=undefined && vm.adjKey!=null){
								returnObj["ADJUSTMENT_KEY"] = vm.adjKey; 
								returnObj["OPERATION_TYPE"] = 'U';
								returnObj["GROUP_KEY"] = vm.adjKey; 
							}else{
								returnObj["ADJUSTMENT_KEY"] = null;
								returnObj["OPERATION_TYPE"] = 'I';
								returnObj["GROUP_KEY"] = null;
							}
						}else{
							returnObj["ADJUSTMENT_KEY"] = null;
							returnObj["OPERATION_TYPE"] = 'I';
							returnObj["GROUP_KEY"] = null;
						}
						message = "R&D Expense Adjustment Amount has been successfully saved/updated";
					}else if(saveFlag == 'delete'){
						returnObj["ADJUSTMENT_KEY"] = vm.adjKey; 
						returnObj["OPERATION_TYPE"] = 'D';
						returnObj["GROUP_KEY"] = vm.adjKey; 
						message = "R&D Expense Adjustment Amount has been removed";
					}
					
					console.log("returnObj::FNL:: ",returnObj);
											
					var defaultsChangeDetails = [];
					defaultsChangeDetails.push(returnObj);					
										
					//send to save the Data
					sendDetails(returnClobSettingsObj,defaultsChangeDetails, message);										
				}

				
				//Send Details to the Service Layer for persisting the data
				function sendDetails(returnClobSettingsObj, interestAndExpDetails, message) {
					InterestExpenseFactory.saveData(returnClobSettingsObj,interestAndExpDetails).then(function(result) {
						if (result.data.errorMessage && result.data.errorMessage !== 'null') {
							AlertService.add("error", result.data.errorMessage, 4000);
							vm.isSaveClicked = false;
						} else {
							AlertService.add("success", message, 4000);
							var args = {
									object_id: returnClobSettingsObj.object_id,
	                                gridFilter: {
	                                	object_id: returnClobSettingsObj.object_id
	                                }
	                            };
							vm.loadingData = false;
							$uibModalInstance.dismiss('cancel');
							$rootScope.$emit('gridUpdate', args);
	                     	};
					});
				}
				
				
				//cancel the pop-up
				vm.cancel = function() {
		    		$uibModalInstance.dismiss('cancel');
				};
				
				//reset the form
				vm.reset = function(){	
					if(vm.isEditMode){
						vm.setSavedValues();
					}else{
						vm.adjName = '';
						vm.meKey = '';
						vm.sicCodeKey = '';
						vm.amount = '';
						vm.comments = '';
					}
				};				
			}			
			return controllers;

		});
	