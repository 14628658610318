define(
    ['angular'],
    function(){
        'use strict';
        let services =  angular.module('app.pshipSetupDiagService',[])
            .factory('pshipSetupDiagServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService', '$injector','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, $injector, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
                let pshipSetupDiagServiceFactory = {};
                pshipSetupDiagServiceFactory.url = {};

                pshipSetupDiagServiceFactory.markAsReviewed = function(settings, data) {

                    pshipSetupDiagServiceFactory.url.rerun = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=9k1t9p";

                    let service      = $injector.get('JsonObjectFactory');
                    let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    let jsonSettings = JSON.stringify(settings);
                    let jsonObj      = JSON.stringify(data);

                    let params          = _.extend({clob_settings:jsonSettings}, params);
                    params              =  _.extend({clob_data:jsonObj}, params);
                    params.tax_year     = filterParams.tax_year;
                    params.scenario     = filterParams.scenario;
                    params.jcd_key      = GlobalService.globalParams.jcd_key;
                    params.sso_id       = settings.sso_id;
                    params.process_name =  "Mark Partnerships As Reviewed";
                    params.scenarioCode = "RAF";
                    params.is_issue_key_enabled = "N";

                    let promise = $http.post(pshipSetupDiagServiceFactory.url.rerun,params).then(
                        function (response) {
                            if(response.callSuccess === "1"){
                                return response;
                            }
                            else {
                                return response
                            }
                        },
                        function(response){
                            return response;
                        }
                    );
                    return promise;
                };
                return pshipSetupDiagServiceFactory;
            }])
        return services;
    }
);