define([
    'angular',
    '../../adminDashboardCtrl'

], function () {
    'use strict';

    angular.module('app.adminDashboardCtrl')
        .controller("dataTablesSettingsCtrl", ["ClientAdminFactory","$scope", "AlertService", "USER_MENU", "GENERAL_CONFIG", "JsonObjectFactory","dataFormatterFactory", dataTablesSettingsCtrl])

    function dataTablesSettingsCtrl(ClientAdminFactory,$scope, AlertService, USER_MENU, GENERAL_CONFIG, JsonObjectFactory,dataFormatterFactory) {
        // var parentScope = $scope.$parent.$parent;
        var emptyColObj = { title: "New Column", data: null, searchable: false, formatAmount: false, visible: true };

        $scope.comp = {};
        // $scope.compoenent.drillDown = {};

        $scope.$on('component-object:loaded', function (e, component) {
            $scope.component = component;
            $scope.componentSettings = component.extraOptions.componentSettings;
            $scope.jsonPlaceholder = JSON.stringify([{
                "key": "Aviation", "filter_json": [
                    { "filter_key": "1", "name": "tax_year", "value": "2017" }, { "filter_key": "24", "name": "scenario", "value": 27 }, { "filter_key": "45", "name": "filing_key", "value": 1 }, { "filter_key": "9", "name": "parent_me_string", "value": "75837,75737" }]
            },
            { "key": "Appliances", "filter_json": [{ "filter_key": "1", "name": "tax_year", "value": "2017" }, { "filter_key": "24", "name": "scenario", "value": 27 }, { "filter_key": "45", "name": "filing_key", "value": 1 }, { "filter_key": "9", "name": "parent_me_string", "value": "75720,75582" }] },
            { "key": "Energy Connections", "filter_json": [{ "filter_key": "1", "name": "tax_year", "value": "2017" }, { "filter_key": "24", "name": "scenario", "value": 27 }, { "filter_key": "45", "name": "filing_key", "value": 1 }, { "filter_key": "9", "name": "parent_me_string", "value": "75854,75776,75868,76017,75810,75712,75573,75214,76041" }] },
            { "key": "GE Digital", "filter_json": [{ "filter_key": "1", "name": "tax_year", "value": "2017" }, { "filter_key": "24", "name": "scenario", "value": 27 }, { "filter_key": "45", "name": "filing_key", "value": 1 }, { "filter_key": "9", "name": "parent_me_string", "value": "75854,75776,75868,76017,75810,75712,75573,75214,76041" }] },
            { "key": "GE Power", "filter_json": [{ "filter_key": "1", "name": "tax_year", "value": "2017" }, { "filter_key": "24", "name": "scenario", "value": 27 }, { "filter_key": "45", "name": "filing_key", "value": 1 }, { "filter_key": "9", "name": "parent_me_string", "value": "75542,76039,76040,76038,75869,75989,75756,75584,75714,76019,75723,75716,75995,75536,75822,75763,75741,75958,75982" }] }]);

            $scope.aggregators = [
                {
                    name:"Sum",
                    func:"sum",
                    params:[]
                },
                {
                    name:"Average",
                    func:"mean",
                    params:[]
                },
                {
                    name:"Count",
                    func:"count",
                    params:[]
                },
                {
                    name:"Custom QRE Aggregation",
                    func:"qreAggregation",
                    params:[
                        // {name:"Divisor",data:"countProp"}
                    ]
                }
            ]
            //Set Defaults

            $scope.componentSettings.tableOptions = $scope.componentSettings.tableOptions || {};
            $scope.componentSettings.tableOptions.columns = $scope.componentSettings.tableOptions.columns || [];
            $scope.componentSettings.tableOptions.columnDefs = $scope.componentSettings.tableOptions.columnDefs || [];

            $scope.componentSettings.childTableOptions = $scope.componentSettings.childTableOptions || {};
            $scope.componentSettings.childTableOptions.columns = $scope.componentSettings.childTableOptions.columns || [];
            $scope.componentSettings.drilldownFilterJson = $scope.componentSettings.drilldownFilterJson || {};
            $scope.componentSettings.drilldownEnabled = $scope.componentSettings.drilldownEnabled || false;
            if ($scope.componentSettings.tableOptions.columns.length > 0) {
                $scope.componentSettings.tableOptions.columns.map(function (item) {
                    if(!item.rowGrouping){
                        item.visible = true;
                    }

                    return item;
                })
            }
            if ($scope.componentSettings.drilldownEnabled) {
                $scope.getChannel();
            }
            if (!_.isEmpty($scope.componentSettings.drilldownFilterJson)) {
                if (_.isString($scope.componentSettings.drilldownFilterJson)) {
                    $scope.componentSettings.drilldownFilterJson = JSON.parse($scope.componentSettings.drilldownFilterJson);
                }
                $scope.componentSettings.task = $scope.componentSettings.drilldownFilterJson.task_key;
                $scope.filter_json = $scope.componentSettings.drilldownFilterJson.screenFilters;
                $scope.getActivites($scope.componentSettings.drilldownFilterJson.task_key, $scope.componentSettings.drilldownFilterJson.activity_key);
                $scope.comp.drilldownToScreenId = ($scope.componentSettings.drilldownFilterJson.screen_key);
                $scope.getScreens($scope.componentSettings.drilldownFilterJson.activity_key, $scope.comp.drilldownToScreenId);
                $scope.componentSettings.drilldownFilterJson = JSON.stringify($scope.componentSettings.drilldownFilterJson);
            }

        })

        $scope.setAggregatorInputs = function(col) {
            col.aggregatorInputs = _.find($scope.aggregators,["func",col.aggregator]).params;
        }
        $scope.rowGroupingChanged = function () {

                var rowGroupingItemIndex;
                var rowGroupingItem = $scope.componentSettings.tableOptions.columns.find(function (item, index) {
                    if (item.rowGrouping) {
                        rowGroupingItemIndex = index
                        return item
                    }
                })
                if (rowGroupingItem) {
                    $scope.componentSettings.tableOptions.columns.splice(rowGroupingItemIndex, 1)
                }
                // return

                if ($scope.componentSettings.rowGrouping) {
                    $scope.componentSettings.tableOptions.columns.push({
                        name: $scope.componentSettings.rowGroupingBy,
                        data: $scope.componentSettings.rowGroupingBy,
                        visible: false,
                        rowGrouping: true
                    })
                    $scope.componentSettings.tableOptions.drawCallback = function (settings) {
                        var rowGroupingItemIndex;
                        var rowGroupingItem = $scope.componentSettings.tableOptions.columns.find(function (item, index) {
                            if (item.rowGrouping) {
                                rowGroupingItemIndex = index
                            }
                        })
                        var groupColumn = rowGroupingItemIndex;
                        // console.log(groupColumn)
                        var api = this.api();
                        var rows = api.rows({ page: 'current' }).nodes();
                        var last = null;
                        console.log("Settings" , settings)
                        console.log("API" , this.api)
                        api.column(groupColumn, { page: 'current' }).data().each(function (group, i) {
                            console.log("Group" , group)
                            if (last !== group) {
                                $(rows).eq(i).before(
                                    '<tr class="group"><td colspan="5">' + group + '</td></tr>'
                                );

                                last = group;
                            }
                        });
                    }
                    console.log("Component Settings " , $scope.componentSettings)
                    // $scope.updateComponent();
                }
            }

            $scope.groupingChanged = function () {
                if (!$scope.componentSettings.grouping || $scope.componentSettings.showChildRows === false) {
                    var plusColumn = $scope.componentSettings.tableOptions.columns[0];

                    if (plusColumn && plusColumn.className === "details-control") {
                        $scope.componentSettings.tableOptions.columns.splice(0, 1);
                    }
                } else if($scope.componentSettings.showChildRows !== false){
                    $scope.componentSettings.tableOptions.columns.unshift({ className: 'details-control', data: null, orderable: false, defaultContent: '' , uneditable : true });
                }

                $scope.updateComponent();

            }

            $scope.drillDownChanged = function () {
                var drillDownObj = {
                    className: 'drill-down text-center', data: null, orderable: false, defaultContent: '', uneditable: true,
                    render: function () {
                        return "<i class='fa fa-chain'></i>"
                    }
                };
                if (!$scope.componentSettings.drilldownEnabled) {
                    var drillDownColumn = _.find($scope.componentSettings.tableOptions.columns, ['className', 'drill-down text-center']);

                    if (drillDownColumn) {
                        $scope.componentSettings.tableOptions.columns.splice($scope.componentSettings.tableOptions.columns.indexOf(drillDownColumn), 1);
                    }
                    var drillDownChildColumn = _.find($scope.componentSettings.childTableOptions.columns, ['className', 'drill-down text-center']);

                    if (drillDownChildColumn) {
                        $scope.componentSettings.childTableOptions.columns.splice($scope.componentSettings.childTableOptions.columns.indexOf(drillDownChildColumn), 1);
                    }
                } else if ($scope.componentSettings.grouping) {
                    $scope.componentSettings.tableOptions.columns.splice(1, 0, drillDownObj);
                    $scope.componentSettings.childTableOptions.columns.splice(0, 0, drillDownObj);
                } else {
                    $scope.componentSettings.tableOptions.columns.unshift(drillDownObj);
                }

                $scope.updateComponent();
            }

            $scope.updateComponent = function () {
                $scope.$emit('component-object:changed', $scope.componentSettings);
            }

            $scope.droppedAction = function (dragId, dropId) {
                var dragEl = angular.element('#' + dragId)
                // var dataProps = dropId.split('_');


                if (dropId.includes('data')) {
                    _.set($scope, dropId, dragEl[0].innerText);
                } else {
                    _.set($scope, dropId, dragEl[0].innerText);
                }

                if (dropId.includes('tableOptions')) {
                    setAmountsAndNonAmounts();
                }

                angular.element(".dropColumns").find('p').removeClass('lvl-over');

                $scope.$apply();
                $scope.updateComponent();

            }
            $scope.setAmountsAndNonAmounts = setAmountsAndNonAmounts;
            function setAmountsAndNonAmounts() {
                $scope.componentSettings.amountColumns = [];
                $scope.componentSettings.nonAmountColumns = [];
                for (var i = 0; i < $scope.componentSettings.tableOptions.columns.length; i++) {
                    var col = $scope.componentSettings.tableOptions.columns[i];
                    if (col.data === null) {
                        continue;
                    }
                    if (typeof $scope.component.previewSheetData.data[0][col.data] === 'number' && col.data !== 'TAX_YEAR' && !col.rowGrouping) {//TODO: Find better solutions, for now hard coding tax year to exclude it from amount fields.
                        $scope.componentSettings.amountColumns.push(col.data);
                    } else {
                        $scope.componentSettings.nonAmountColumns.push(col.data);

                    }
                }

            }


            

            $scope.addColRow = function (columnsProp) {
                var columns = _.get($scope, columnsProp);

                columns.push(angular.copy(emptyColObj));

            }
            $scope.removeColRow = function (columnsProp, index) {
                var columns = _.get($scope, columnsProp);
                // if ($scope.componentSettings.grouping && columns[index].data === $scope.componentSettings.groupColumnsBy) {
                //     AlertService.add('warning', 'Cannot Remove this column as group by is applied');
                //     return;
                // }

                columns.splice(index, 1);

                $scope.updateComponent();
            }
            $scope.getChannel = function () {
                console.log("USER_MENU", USER_MENU);
                $scope.Channels = _.filter(USER_MENU, { id: "5" });
                $scope.Channel = ClientAdminFactory.taskHierarchy($scope.Channels[0].children);//$scope.Channels[0].children
            }
            // }
            if ($scope.componentSettings && $scope.componentSettings.drilldownEnabled) {
                $scope.getChannel();
            }
            $scope.getActivites = function (task, activity_key) {
                $scope.activities = [];
                _.filter($scope.Channel, function (item) {
                    if (item.id == task) {
                        $scope.activities = item.children;
                    }
                })
                if (activity_key) {
                    $scope.componentSettings.drilldownToActivityId = activity_key;//$scope.componentSettings.drilldownFilterJson.activity_key
                }

            }

            $scope.getScreens = function (activity, screen_key) {
                $scope.screens = [];
                _.filter($scope.activities, function (item) {
                    if (item.id == activity) {
                        $scope.screens = item.screens;
                    }
                    if (screen_key) {
                        $scope.comp.drilldownToScreenId = _.find($scope.screens, { id: screen_key });
                    }
                })
            }
            $scope.getFilters = function (screen) {
                $scope.filter_json = [];

                if (screen && screen.filter_key) {
                    var url = GENERAL_CONFIG.base_url + '/getFilters',
                        params = {
                            filter_group_key: screen.filter_key
                        };
                    JsonObjectFactory.getJSONObj(url, params).then(function (data) {
                        $scope.componentSettings.drilldownFilterJson = {};
                        if (data.callSuccess == '1') {
                            if (data.filterGroup && data.filterGroup.filters) {
                                _.each(data.filterGroup.filters, function (item) {
                                    $scope.filter_json.push({ filter_key: item.filter_key, name: item.param_name, row_data_key: "", })
                                });
                                console.log("filter Json", $scope.filter_json);
                                $scope.componentSettings.drilldownFilterJson = JSON.stringify({
                                    task_key: $scope.componentSettings.task, activity_key: $scope.componentSettings.drilldownToActivityId, filter_group_key: screen.filter_key,
                                    screen_key: screen.id,
                                    screenFilters: $scope.filter_json
                                });

                                console.log("adfasdfadsf", $scope.componentSettings.drilldownFilterJson);
                            }
                        }
                    });
                }
            }

            $scope.$watch('componentSettings.drilldownFilterJson', function (newValue, oldValue) {
                if (newValue !== undefined) {
                    console.log(newValue);
                    console.log("oldvlaue", oldValue);
                    $scope.component.drilldownFilterJson = newValue;
                }
            });

            $scope.updateDrillDownJSON = function (index,rowDataKey, value) {
                var filter = JSON.parse($scope.componentSettings.drilldownFilterJson);
                _.each(filter.screenFilters, function (item, indexA) {
                    if (index == indexA) {
                        if(rowDataKey!=null){
                            item.row_data_key = rowDataKey;
                        }
                       // if(val)
                       // item.value = value;
                        if (value == '' || value == undefined) {
                            delete item.value;
                        }else{
                            item.value = value;
                        }
                    }
                });
                console.log("Adfasdf", filter)
                $scope.componentSettings.drilldownFilterJson = JSON.stringify(filter);
            }
        }

    })