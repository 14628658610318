const { data } = require("jquery");

define([
    'moment',
    'angular'
  ], function (moment) {
    'use strict';

    var controllers = angular.module('app.businessSignOffDescCtrl', [])
    .controller('signOffDescCtrl', ['businessSignOffDescFactory', 'AlertService', 'GENERAL_CONFIG','$uibModal','GlobalService','$scope','workspaceFactory','$rootScope','USER_SETTINGS', signOffDescCtrl])
    .controller('businessSignOffEditModal', ['selectedBusinessList', '$uibModalInstance', 'AlertService','workspaceFactory','GlobalService','businessSignOffDescFactory','USER_SETTINGS', businessSignOffEditModal]);

    function signOffDescCtrl(businessSignOffDescFactory, AlertService, GENERAL_CONFIG, $uibModal,GlobalService,$scope,workspaceFactory,$rootScope,USER_SETTINGS){
        var vm = this;
        vm.list = {};
        vm.meFilterList = [];
        vm.signOffLoading = false;
        vm.filtersSelected = false;
        vm.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
        var activeScreen = workspaceFactory.activeScreen;
        workspaceFactory.checkFilters(workspaceFactory.activeScreen);
        vm.filterByMonth = function(){
            vm.filteredList = [];
            if(vm.list.monthSearch && vm.list.monthSearch.value){
                vm.filteredList =  _.filter(vm.list.data,{'SIGN_OFF_GROUP_LABEL':vm.list.monthSearch.value});
                if(vm.list.meFilterList.model && vm.list.meFilterList.model.length>0){
                    vm.filteredList = vm.filteredList.filter(function(item){
                        return vm.list.meFilterList.model.indexOf(item.PARENT_ME_CODE) !== -1;
                    });
                }

            }
            workspaceFactory.setCache(activeScreen, vm.list);

        }
        function buildFilter(data){
            var filterObj = {};
            filterObj.model = [];
            filterObj.selected = [];
            filterObj.values = [];
            filterObj.name = "BU(s)  ";
            _.each(data,function(item){
                filterObj.values.push({name: item.PARENT_ME_NAME,value: item.PARENT_ME_CODE});
            });
            console.log("meFilter",filterObj);
            return filterObj;
        }
        if(activeScreen.data){
            activeScreen.loading_data = false;
            vm.list.data = activeScreen.data.data;
            vm.list.monthFilterList = activeScreen.data.monthFilterList;
            vm.list.monthSearch = activeScreen.data.monthSearch;
            vm.list.meFilterList = activeScreen.data.meFilterList;//buildFilter(vm.list.data);
            vm.filterByMonth();

            vm.filtersSelected = true;
            vm.signOffLoading = false;
        }

        vm.getBList = function() {
            var filterParams =  activeScreen.filters.getFilterParams();
                filterParams.jcd_key = GlobalService.globalParams.jcd_key;
                activeScreen.loading_data = true;
            businessSignOffDescFactory.getSignOffList(filterParams).then(function(data) {
                vm.list.monthFilterList  = [];
                if(data.callSuccess === "1") {
                    vm.list.data = data.jsonObject;
                    var filterList = _.uniqBy(vm.list.data, 'SIGN_OFF_GROUP_LABEL');
                    _.each(filterList,function(i){
                        var item = {}
                        item.name = i.SIGN_OFF_GROUP_LABEL;
                        item.value = i.SIGN_OFF_GROUP_LABEL;
                        vm.list.monthFilterList.push(item);


                    });
                    /* var x = {};
                    x.name = "AUGUST";
                    x.value = "AUGUST";
                    vm.monthFilterList.push(x);*/
                    vm.list.monthFilter = vm.monthFilterList;
                    vm.list.monthSearch = vm.list.monthFilterList[0];
                   // vm.monthSearch = vm.monthFilterList[0];
                   vm.list.meFilterList = buildFilter(vm.list.data);
                    vm.filterByMonth(vm.list.monthSearch);
                  //  vm.meFilterList = buildFilter(vm.list.data);
                    activeScreen.loading_data = false;
                    vm.filtersSelected = true;
                    vm.signOffLoading = false;



                   // console.log("meFilter",vm.list.meFilterList);

                  //  workspaceFactory.setCache(activeScreen, vm.list);
                    workspaceFactory.setCurrentFilters(workspaceFactory.activeScreen);

                } else if(data.errorMessage){
                    AlertService.add("error", data.errorMessage, 5000);
                } else {
                    AlertService.add("error", "request failed", 5000);
                }

            })
        }
        $scope.$on('dataFilters:refreshGrid', function (event, args) {
            vm.signOffLoading = true;
            vm.getBList();
        });

        vm.openCmtEditModal = function(list) {
            list.reloadBackGround = vm.getBList;
            $uibModal.open({
                animation: true,
                templateUrl: 'app/components/dashboardExecutive/business-signOff-desc/Business-comment-edit-modal.html',
                controller: 'businessSignOffEditModal as ctrl',
                size: 'md',
                backdrop: 'static',
                resolve: {
                  selectedBusinessList: () => list
                }
              });
        }
        vm.save = function(data) {
            var jsonObj = {
                "parent_me_code":data.PARENT_ME_CODE,
                "parent_me_name":data.PARENT_ME_NAME,
                "signed_off": data.SIGNED_OFF == 1? 1: 0,
                "signed_off_desc": data.SIGN_OFF_DESC,
                "sign_off_id": data.SIGN_OFF_ID

            }
            jsonObj.signed_off_by  = USER_SETTINGS.user.sso_id;
            var params = {
                tax_year: workspaceFactory.activeScreen.filters.filterParams.tax_year,
                scenario:workspaceFactory.activeScreen.filters.filterParams.scenario,
                jcd_key: GlobalService.globalParams.jcd_key,
                jsonObj: JSON.stringify(jsonObj)
            }
            businessSignOffDescFactory.saveSignOffCmt(params).then(function(data) {
                if(data && data.callSuccess == 1){

                    vm.getBList();
                    AlertService.add("success", "Business Signed Off Successfully...", 5000);
                }else{
                    vm.getBList();
                    AlertService.add("error", data.errorMessage, 5000);
                }

            });
        }

    }

    function businessSignOffEditModal(selectedBusinessList, $uibModalInstance, AlertService,workspaceFactory,GlobalService,businessSignOffDescFactory,USER_SETTINGS) {
        var vm = this;


        vm.editSignOffListObj = selectedBusinessList;
        vm.desc = selectedBusinessList.SIGN_OFF_DESC;


        vm.save = function() {
            var jsonObj = {
                "parent_me_code":selectedBusinessList.PARENT_ME_CODE,
                "parent_me_name":selectedBusinessList.PARENT_ME_NAME,
                "signed_off": selectedBusinessList.SIGNED_OFF,
                "signed_off_by":"",
                "signed_off_desc": vm.desc,
                "sign_off_id": selectedBusinessList.SIGN_OFF_ID

            }
            var params = {
                tax_year: workspaceFactory.activeScreen.filters.filterParams.tax_year,
                scenario:workspaceFactory.activeScreen.filters.filterParams.scenario,
                jcd_key: GlobalService.globalParams.jcd_key,
                jsonObj: JSON.stringify(jsonObj)
            }
            businessSignOffDescFactory.saveSignOffCmt(params).then(function(data) {
                if(data && data.callSuccess == 1){
                    vm.cancel();
                    selectedBusinessList.reloadBackGround();
                    AlertService.add("success", "Business saved successfully...", 5000);

                }else{
                    AlertService.add("error", data.errorMessage, 5000);
                }

            });
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };



    }

    return controllers;
});