define(
[ 'angular'

],
function() {
	'use strict';

	var controllers = angular.module('app.actSyncFtcController', [])
	.controller('actSyncFtcController', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService','ModalFactory','AlertService','actSyncFtcServiceFactory','USER_SETTINGS','GENERAL_CONFIG', 'ObjectDiff', actSyncFtcController])
	
	function actSyncFtcController($rootScope, $scope, $http, $filter, $timeout, GlobalService, ModalFactory, AlertService, actSyncFtcServiceFactory, USER_SETTINGS, GENERAL_CONFIG,ObjectDiff) {
		var vm = this;
		vm.showJsonLoader = false;
		vm.names = ["Insert", "Update"];

		vm.actSyncFtcData = {
			dropdown : "",
			fromChartKey: "",
			sysActKey : "",
			gieKey : ""
		};

		vm.saveTagSourcing = function() {
			console.log(vm.actSyncFtcData);
			vm.showJsonLoader = true;
            actSyncFtcServiceFactory.saveActSyncFtcData(vm.actSyncFtcData).then(function (result) {
				if (result.errorMessage && result.errorMessage !== 'null') {
					AlertService.add("error", result.errorMessage, 4000);
					$scope.showJsonLoader = false;
				} else {
					AlertService.add("success", "Data Saved Successfully.", 4000);
					$scope.showJsonLoader = false;
				}
			});
        }

	}
	return controllers;
});