define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module(
					'app.localSysChartAcctMapController', []);

			controllers.controller('LocalSysChartAcctMapController', [
					'$rootScope', '$scope', '$http', '$timeout',
					'GlobalService', '$uibModalInstance', 'ModalFactory',
					'AlertService', 'JsonObjectFactory',
					'LocalGroupObjFactory', 'rowData', 'gridData',
					'GENERAL_CONFIG', 'USER_SETTINGS',
					localSysChartAcctMapController ])

			var lookupData = [];
			var lookupData1 = [];
			var lookupData2 = [];
			var lookupData222 = [];
			var lookupData333 = [];
			var lookupData3 = [];
			var lookupData4 = [];

			function format(value, replace) {
				if (!value) {
					return value;
				}
				var target = value.toString();
				if (replace === undefined) {
					return target;
				}
				if (!angular.isArray(replace) && !angular.isObject(replace)) {
					return target.split('$0').join(replace);
				}
				var token = (angular.isArray(replace) && '$') || ':';

				angular.forEach(replace, function(value, key) {
					target = target.split(token + key).join(value);
				});
				return target;
			}

			controllers.value('customSelectDefaultsLocalSystem', {
				displayText : 'Select...',
				emptyListText : 'There are no items to display',
				emptySearchResultText : 'No results match "$0"',
				addText : 'Add',
				searchDelay : 300,
			});

			controllers
					.directive(
							'customSelectLocalSystem',
							[
									'$parse',
									'$compile',
									'$timeout',
									'$q',
									'customSelectDefaultsLocalSystem',
									function($parse, $compile, $timeout, $q,
											baseOptions) {
										var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

										return {
											restrict : 'A',
											require : 'ngModel',
											link : function(scope, elem, attrs,
													controller) {
												var customSelect = attrs.customSelectLocalSystem;
												if (!customSelect) {
													throw new ('Expected custom-select attribute value.');
												}
												scope
														.$watch(
																"ctrl.sys_acc.SYS_ACCT.SYS_ACCT",
																function() {
																	// scope.getChartAcctTableData();
																	scope
																			.$emit(
																					'SYS_ACCT_CHANGED',
																					'');
																});
												var match = customSelect
														.match(CS_OPTIONS_REGEXP);

												if (!match) {
													throw new ('Expected expression in form of '
															+ "'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'"
															+ " but got '"
															+ customSelect + "'.");
												}

												elem
														.addClass('dropdown custom-select');

												// Ng-Options break down
												var displayFn = $parse(match[2]
														|| match[1]), valueName = match[3], valueFn = $parse(match[2] ? match[1]
														: valueName), values = match[4], valuesFn = $parse(values), track = match[5], trackByExpr = track ? ' track by '
														+ track
														: '', dependsOn = attrs.csDependsOn;

												var options = getOptions(), timeoutHandle, lastSearch = '', focusedIndex = -1, matchMap = {};

												var itemTemplate = elem.html()
														.trim()
														|| '{{'
														+ (match[2] || match[1])
														+ '}}', dropdownTemplate = '<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">'
														+ '<span>{{displayText}}</span>'
														+ '<b></b>'
														+ '</a>'
														+ '<div class="dropdown-menu">'
														+ '<div stop-propagation="click" class="custom-select-search">'
														+ '<input class="'
														+ attrs.selectClass
														+ '" type="text" autocomplete="off" ng-model="searchTerm" />'
														+ '</div>'
														+ '<ul role="menu">'
														+ '<li role="presentation" ng-repeat="'
														+ valueName
														+ ' in matches'
														+ trackByExpr
														+ '">'
														+ '<a role="menuitem" tabindex="-1" href ng-click="select('
														+ valueName
														+ ')">'
														+ itemTemplate
														+ '</a>'
														+ '</li>'
														+ '<li ng-hide="matches.length" class="empty-result" stop-propagation="click">'
														+ '<em class="muted">'
														+ '<span ng-hide="searchTerm">{{emptyListText}}</span>'
														+ '<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>'
														+ '</em>'
														+ '</li>'
														+ '</ul>'
														+ '<div class="custom-select-action">'
														+ (typeof options.onAdd === 'function' ? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
																: '')
														+ '</div>' + '</div>';

												// Clear element contents
												elem.empty();

												// Create dropdown element
												var dropdownElement = angular
														.element(dropdownTemplate), anchorElement = dropdownElement
														.eq(0).dropdown(), inputElement = dropdownElement
														.eq(1).find(':text'), ulElement = dropdownElement
														.eq(1).find('ul');

												// Create child scope for input
												// and dropdown
												var childScope = scope
														.$new(true);
												configChildScope();

												// Click event handler to set
												// initial values and focus when
												// the dropdown is shown
												anchorElement
														.on(
																'click',
																function(event) {
																	if (childScope.disabled) {
																		return;
																	}
																	childScope
																			.$apply(function() {
																				lastSearch = '';
																				childScope.searchTerm = '';
																			});

																	focusedIndex = -1;
																	inputElement
																			.focus();

																	// If filter
																	// is not
																	// async,
																	// perform
																	// search in
																	// case
																	// model
																	// changed
																	if (!options.async) {
																		getMatches('');
																	}
																});

												if (dependsOn) {
													scope
															.$watch(
																	dependsOn,
																	function(
																			newVal,
																			oldVal) {
																		if (newVal !== oldVal) {
																			childScope.matches = [];
																			childScope
																					.select(undefined);
																		}
																	});
												}

												// Event handler for key press
												// (when the user types a
												// character while focus is on
												// the anchor element)
												anchorElement
														.on(
																'keypress',
																function(event) {
																	if (!(event.altKey || event.ctrlKey)) {
																		anchorElement
																				.click();
																	}
																});

												// Event handler for Esc, Enter,
												// Tab and Down keys on input
												// search
												inputElement
														.on(
																'keydown',
																function(event) {
																	if (!/(13|27|40|^9$)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	switch (event.keyCode) {
																	case 27: // Esc
																		anchorElement
																				.dropdown('toggle');
																		break;
																	case 13: // Enter
																		selectFromInput();
																		break;
																	case 40: // Down
																		focusFirst();
																		break;
																	case 9: // Tab
																		anchorElement
																				.dropdown('toggle');
																		break;
																	}
																});

												// Event handler for Up and Down
												// keys on dropdown menu
												ulElement
														.on(
																'keydown',
																function(event) {
																	if (!/(38|40)/
																			.test(event.keyCode))
																		return;
																	event
																			.preventDefault();
																	event
																			.stopPropagation();

																	var items = ulElement
																			.find('li > a');

																	if (!items.length)
																		return;
																	if (event.keyCode == 38)
																		focusedIndex--; // up
																	if (event.keyCode == 40
																			&& focusedIndex < items.length - 1)
																		focusedIndex++; // down
																	if (focusedIndex >= 0) {
																		items
																				.eq(
																						focusedIndex)
																				.focus();
																	} else {
																		focusedIndex = -1;
																		inputElement
																				.focus();
																	}
																});

												resetMatches();

												// Compile template against
												// child scope
												$compile(dropdownElement)(
														childScope);
												elem.append(dropdownElement);

												// When model changes outside of
												// the control, update the
												// display text
												controller.$render = function() {
													setDisplayText();
												};

												// Watch for changes in the
												// default display text
												childScope.$watch(
														getDisplayText,
														setDisplayText);

												childScope
														.$watch(
																function() {
																	return elem
																			.attr('disabled');
																},
																function(value) {
																	childScope.disabled = value;
																});

												childScope
														.$watch(
																'searchTerm',
																function(
																		newValue) {
																	if (timeoutHandle) {
																		$timeout
																				.cancel(timeoutHandle);
																	}

																	var term = (newValue || '')
																			.trim();
																	timeoutHandle = $timeout(
																			function() {
																				getMatches(term);
																			},
																			// If
																			// empty
																			// string,
																			// do
																			// not
																			// delay
																			(term && options.searchDelay) || 0);
																});

												// Support for autofocus
												if ('autofocus' in attrs) {
													anchorElement.focus();
												}

												var needsDisplayText;

												function setDisplayText() {
													var locals = {};
													locals[valueName] = controller.$modelValue;
													var text = displayFn(scope,
															locals);
													if (text === undefined) {
														var map = matchMap[hashKey(controller.$modelValue)];
														if (map) {
															text = map.label;
														}
													}

													needsDisplayText = !text;
													childScope.displayText = text
															|| controller.$modelValue // text
												}

												function getOptions() {
													return angular
															.extend(
																	{},
																	baseOptions,
																	scope
																			.$eval(attrs.customSelectOptionsLocalSystem));
												}

												function getDisplayText() {
													options = getOptions();

													return options.displayText;
												}

												function focusFirst() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														focusedIndex = 0;
														opts.eq(0).focus();
													}
												}

												// Selects the first element on
												// the list when the user
												// presses Enter inside the
												// search input
												function selectFromInput() {
													var opts = ulElement
															.find('li > a');
													if (opts.length > 0) {
														var ngRepeatItem = opts
																.eq(0).scope();
														var item = ngRepeatItem[valueName];
														childScope
																.$apply(function() {
																	childScope
																			.select(item);
																});
														anchorElement
																.dropdown('toggle');
													}
												}

												function getMatches(searchTerm) {
													var locals = {
														$searchTerm : searchTerm
													};
													$q
															.when(
																	valuesFn(
																			scope,
																			locals))
															.then(
																	function(
																			matches) {
																		if (!matches)
																			return;

																		if (searchTerm === inputElement
																				.val()
																				.trim()
																		/*
																		 * &&
																		 * hasFocus
																		 */
																		) {
																			matchMap = {};
																			childScope.matches.length = 0;
																			for (var i = 0; i < matches.length; i++) {
																				locals[valueName] = matches[i];
																				var value = valueFn(
																						scope,
																						locals), label = displayFn(
																						scope,
																						locals);

																				matchMap[hashKey(value)] = {
																					value : value,
																					label : label,
																				};

																				childScope.matches
																						.push(matches[i]);
																			}
																		}

																		if (needsDisplayText)
																			setDisplayText();
																	},
																	function() {
																		resetMatches();
																	});
												}

												function resetMatches() {
													childScope.matches = [];
													focusedIndex = -1;
												}

												function configChildScope() {
													childScope.addText = options.addText;
													childScope.emptySearchResultText = options.emptySearchResultText;
													childScope.emptyListText = options.emptyListText;

													childScope.select = function(
															item) {
														var locals = {};
														locals[valueName] = item;
														var value = valueFn(
																childScope,
																locals);
														childScope.displayText = displayFn(
																childScope,
																locals)
																|| options.displayText;
														controller
																.$setViewValue(value);

														anchorElement.focus();

														typeof options.onSelect === 'function'
																&& options
																		.onSelect(item);
													};

													childScope.add = function() {
														$q
																.when(
																		options
																				.onAdd(),
																		function(
																				item) {
																			if (!item)
																				return;

																			var locals = {};
																			locals[valueName] = item;
																			var value = valueFn(
																					scope,
																					locals), label = displayFn(
																					scope,
																					locals);

																			matchMap[hashKey(value)] = {
																				value : value,
																				label : label,
																			};

																			childScope.matches
																					.push(item);
																			childScope
																					.select(item);
																		});
													};

													childScope.format = format;

													setDisplayText();
												}

												var current = 0;

												function hashKey(obj) {
													if (obj === undefined)
														return 'undefined';

													var objType = typeof obj, key;

													if (objType == 'object'
															&& obj !== null) {
														if (typeof (key = obj.$$hashKey) == 'function') {
															// must invoke on
															// object to keep
															// the right this
															key = obj
																	.$$hashKey();
														} else if (key === undefined) {
															key = obj.$$hashKey = 'cs-'
																	+ current++;
														}
													} else {
														key = obj;
													}

													return objType + ':' + key;
												}
											},
										};
									}, ]);

			controllers.directive('stopPropagation', function() {
				return {
					restrict : 'A',
					link : function(scope, elem, attrs, ctrl) {
						var events = attrs['stopPropagation'];
						elem.bind(events, function(event) {
							event.stopPropagation();
						});
					},
				};
			});

			function localSysChartAcctMapController($rootScope, $scope, $http,
					$timeout, GlobalService, $uibModalInstance, ModalFactory,
					AlertService, JsonObjectFactory, LocalGroupObjFactory,
					rowData, gridData, GENERAL_CONFIG, USER_SETTINGS) {

				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.accountsTableData = [];
				vm.loading = false;
				vm.showDetails = {
					text : 'lookup'
				};
				vm.sys_acc = {};
				var displayafterdelete = "";
				vm.orderByField = 'FORM';
				vm.orderByField1 = 'TRIGGER_ACCT';
				vm.reverseSort = false;
				vm.reverseSort1 = false;
				vm.setSaveflag = false;
				vm.loadingsyacct = true;
				vm.loadingIRSform = true;
				vm.sort_key = {
					'1' : 0,
					'2' : 0,
					'3' : 0,
					'4' : 0,
					'5' : 0,
					'6' : 0,
					'7' : 0
				};
				vm.sort_key1 = {
					'1' : 0,
					'2' : 0,
					'3' : 0,
					'4' : 0,
					'5' : 0,
					'6' : 0,
					'7' : 0,
					'8' : 0,
					'9' : 0
				};
				vm.lookUpMapping = false;
				vm.irsformdata = false;
				vm.passgriddata = _.clone(gridData);
				vm.localAccountMultipleSelected = [];
				vm.localAccountKeys = [];
				vm.localacctdisplay = [];
				vm.localacctdisplay1 = [];
				vm.selectedSystemAccountKey = null;
				vm.localaccounts = [];
				vm.tempsystemaccount = [];
				vm.templocalaccount = [];
				vm.triggeraccount = [];
				vm.taxstreamaccount = [];
				vm.scdhmtype = [];
				vm.postingtac = [];
				vm.postingacct = [];
				vm.methodofcalc = [];
				vm.scdhmtype_all = [];
				vm.postingtac_all = [];
				vm.postingacct_all = [];
				vm.methodofcalc_all = [];
				vm.displaydata = [];
				var tempdisplaydata = {};
				vm.editflag = false;
				vm.taxsensitiveinit = false;
				vm.pl_acct_flag = false;
				var schdm_temp_value = "";
				var schdm_temp_value_edit = "";
				var schdm_temp_tbbs = "";
				vm.tempEditdata = [];
				vm.currentRow = [];
				vm.checksensitiveaccount = [];
				$scope.crudLoading = false;
				vm.trans_flag = false;
				vm.trans_flag_loading = false;
				vm.trans_details = [];
				var returnObj = {};
				vm.CHECK_FORM = "";
				vm.CHECK_SCHEDULE = "";
				vm.CHECK_SECTION = "";
				vm.CHECK_PART = "";
				vm.CHECK_LINE = "";
				vm.CHECK_COL = "";
				vm.CHECK_SYS_MAP_ACCT = '';
				// new added

				vm.noRecords = true;

				// Column names for the Audit tab, to be used in the view (need
				// to fix this)
				vm.viewColumnNames = [ "Updated On", "Local Chart",
						"System Chart", "Local Acct", "System Acct",
						"DML Type", "Old New Flag", "Updated By" ];
				// Initializing the sort_key object, which is used to track
				// whether a column is being sorted in ascending or descending
				// order, or if it is not explicitly sorted
				// audit_sort_key[i] = 0 means that it is not being sorted
				// audit_sort_key[i] = 1 means that it is being sorted in
				// ascending order
				// audit_sort_key[i] = 2 means that it is being sorted in
				// descending order
				vm.audit_sort_key = {};
				for (var i = 1; i < 8; i++) {
					vm.audit_sort_key[i] = 0;
				}

				vm.getAuditSummary = function() {
					var params = {
						"action_code" : 'f67kgj',
						"sso_id" : vm.logged_in_user,
						"tax_year" : GlobalService.globalParams.tax_year,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"local_acct_key" : rowData.LOCAL_ACCT_KEY
					};

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params).then(function(data) {
								vm.auditData = data.jsonObject;

								// Checking to see if any data was returned
								if (vm.auditData && vm.auditData.length) {
									vm.noRecords = false;
								} else {
									vm.noRecords = true;
								}
							});
				}

				// Function to handle sorting columns in Audit tab
				// colNum is the number of the column which was clicked
				// sortKey is the sort key which was passed from the HTML
				vm.sortBy = function(colNum, sortKey) {
					s
					vm.column = vm.columnNames[colNum - 1];
					if (sortKey === 0 || sortKey === 2) {
						vm.audit_sort_key[colNum] = 1;
						vm.reverse = false;
					} else if (sortKey === 1) {
						vm.audit_sort_key[colNum] = 2;
						vm.reverse = true;
					}

					// When one column is chosen to be sorted, we set the other
					// columns to be labeled as
					// "neutral," that is, they are not being sorted on
					for (var i = 1; i < 9; i++) {
						if (i === Number(colNum)) {
							continue;
						} else {
							vm.audit_sort_key[i] = 0;
						}
					}
				}

				var changetemplatetempdata = [];
				vm.tempTBBSAcct = [];
				if (rowData == null) {
					for ( var index_ in gridData) {
						vm.localaccounts
								.push(gridData[index_].data.LOCAL_CHART_ACCT_KEY);
					}
					vm.localaccounts = _.uniq(vm.localaccounts, JSON.stringify)
					var paramlocalaccounts = vm.localaccounts.join();
					console.log(vm.localaccounts);
					console.log(paramlocalaccounts);
				}

				if (rowData == null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;

					tempGridData.forEach(function(a) {
						groups[a.data.SYSTEM_ACCT] = groups[a.data.SYSTEM_ACCT]
								|| [];
						groups[a.data.SYSTEM_ACCT].push(a);
					});

					result = Object.keys(groups).map(function(k) {
						var temp = {};
						temp[k] = groups[k];
						return temp;
					});

					var resultCheck = result.map(function(a) {
						console.log(a, "a");
						var keys = Object.keys(a);
						return {
							key : keys[0],
							length : a[keys[0]].length
						};
					});
					var length = Math.max.apply(Math, resultCheck.map(function(
							o) {
						return o.length;
					}))
					var checkKeyFinal = 0;
					for ( var res in resultCheck) {
						if (resultCheck[res].length == length) {
							checkKeyFinal = resultCheck[res].key

						}
					}
					for ( var resData in result) {
						var rsultKeys = Object.keys(result[resData]);
						if (rsultKeys[0] == checkKeyFinal) {
							vm.localacctdisplay = result[resData][checkKeyFinal];
						}

					}
				} else {
					vm.localacctdisplay = rowData;
				}
				console.log(vm.localacctdisplay, "vm.localacctdisplay");
				vm.gridDataMultipleSelect = _.clone(vm.localacctdisplay);
				vm.buildLocalAccounts = function() {
					if (vm.gridDataMultipleSelect != undefined) {
						for ( var data in vm.gridDataMultipleSelect) {
							if (vm.gridDataMultipleSelect[data].data != undefined) {
								vm.localAccountMultipleSelected
										.push(vm.gridDataMultipleSelect[data].data.LOCAL_ACCT_DISPLAY);
								vm.localAccountKeys
										.push(vm.gridDataMultipleSelect[data].data.LOCAL_ACCT_KEY);
							}

						}
					}
				}
				if (rowData == null) {
					vm.buildLocalAccounts();
				}

				vm.passgriddata = _.clone(gridData);

				vm.checksystemaccount = [];
				vm.local_acct_grid_update = [];

				if ($uibModalInstance) {
					vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name
							: null;
				}
				
				if (vm.modal_name == "localSysAcctMap"){
				if (rowData == null) {

					for ( var index_ in gridData) {
						vm.checksystemaccount
								.push(gridData[index_].data.SYS_ACCT_KEY);
						vm.local_acct_grid_update
								.push(gridData[index_].data.LOCAL_ACCT_KEY);
					}
					vm.checksystemaccount = _.uniq(vm.checksystemaccount,
							JSON.stringify)
					vm.local_acct_grid_update = _.uniq(
							vm.local_acct_grid_update, JSON.stringify)
					vm.local_acct_grid_update = vm.local_acct_grid_update
							.join();
					var paramssystemaccount = vm.checksystemaccount;
					if (vm.checksystemaccount.length > 1) {
						stopSystemaccountSelection();

					}
				}
				}
				function stopSystemaccountSelection() {

					AlertService.add("",
							"Please Select Only One System Account.", 400000);
					vm.userMessage = "!! Unable to perform Selected Action...";
					throw new ("This is not an error. This is just to abort javascript!");

				}

				if (rowData != null) {
					vm.selectedSysAcc = rowData.SYSTEM_ACCT;
				} else {
					vm.selectedSysAcc = gridData[0].data.SYSTEM_ACCT;
				}

				if (rowData != null) {

					vm.chartacct_data = {
						localacct : rowData.LOCAL_ACCT_DISPLAY,
						localaccts : (vm.localAccountMultipleSelected.length > 0) ? vm.localAccountMultipleSelected
								: rowData.LOCAL_ACCT_DISPLAY,
						chart : rowData.CHART_DISPLAY,
						systemacct : rowData.SYSTEM_ACCT,
						systemacct_des : rowData.SYSTEM_ACCT_DESC,
						acctType : rowData.ACCT_TYPE || rowData.SYSTEM_ACCT
					}

				} else {
					vm.chartacct_data = {
						localacct : gridData[0].data.LOCAL_ACCT_DISPLAY,
						localaccts : (vm.localAccountMultipleSelected.length > 0) ? vm.localAccountMultipleSelected
								: rowData.LOCAL_ACCT_DISPLAY,
						chart : gridData[0].data.CHART_DISPLAY,
						systemacct : (vm.localAccountMultipleSelected.length > 0) ? vm.localacctdisplay[0].data.SYSTEM_ACCT
								: rowData.SYSTEM_ACCT,
						systemacct_des : gridData[0].data.SYSTEM_ACCT_DESC,
						acctType : gridData[0].data.ACCT_TYPE
								|| gridData[0].data.SYSTEM_ACCT
					}
				}
				if (vm.localAccountMultipleSelected.length > 0) {
					vm.chartacct_data.localaccts = vm.chartacct_data.localaccts
							.map(function(item) {
								return item;
							}).join('\n');
				}

				vm.chartacct_data.rows = 5;
				vm.sortBy = function(key, value) {
					$timeout(function() {
						switch (value) {
						case 0:
							vm.sort_key[key] = 1;
							vm.reverseSort = false;
							break;
						case 1:
							vm.sort_key[key] = 2;
							vm.reverseSort = true;
							break;
						case 2:
							vm.sort_key[key] = 0;
							vm.reverseSort = "";
							break;
						}
					});
				}

				vm.sortBy1 = function(key, value) {
					$timeout(function() {
						switch (value) {
						case 0:
							vm.sort_key1[key] = 1;
							vm.reverseSort1 = false;
							break;
						case 1:
							vm.sort_key1[key] = 2;
							vm.reverseSort1 = true;
							break;
						case 2:
							vm.sort_key1[key] = 0;
							vm.reverseSort1 = "";
							break;
						}
					});
				}

				/**
				 * This function hits the DB and retrieves System as well as
				 * Local Data *
				 */
				//	 
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				vm.reset = function() {
					vm.setSaveflag = false;
					vm.taxsensitiveinit = false;
					fetchtaxsensitivedata(false);
					vm.resetLookup();
					init();

					if (rowData != null) {
						vm.chartacct_data = {

							localaccts : (vm.localAccountMultipleSelected.length > 0) ? vm.localAccountMultipleSelected
									: rowData.LOCAL_ACCT_DISPLAY,
							chart : rowData.CHART_DISPLAY,
							systemacct : rowData.SYSTEM_ACCT,
							systemacct_des : rowData.SYSTEM_ACCT_DESC,
							acctType : rowData.ACCT_TYPE || rowData.SYSTEM_ACCT
						}
					} else {
						vm.chartacct_data = {

							localaccts : (vm.localAccountMultipleSelected.length > 0) ? vm.localAccountMultipleSelected
									: rowData.LOCAL_ACCT_DISPLAY,
							chart : gridData[0].data.CHART_DISPLAY,
							systemacct : gridData[0].data.SYSTEM_ACCT,
							systemacct_des : gridData[0].data.SYSTEM_ACCT_DESC,
							acctType : gridData[0].data.ACCT_TYPE
									|| gridData[0].data.SYSTEM_ACCT
						}
					}

					if (vm.localAccountMultipleSelected.length > 0) {
						vm.chartacct_data.localaccts = vm.chartacct_data.localaccts
								.map(function(item) {
									return item;
								}).join('\n');
					}

				};
				vm.confirm_delete = function() {
					vm.trans_available();
				}
				vm.trans_available = function() {
					var local_acct_send = gridData[0].data.LOCAL_ACCT_KEY;
					if (gridData.length > 1) {
						for ( var s in gridData) {
							local_acct_send += '_'
									+ gridData[s].data.LOCAL_ACCT_KEY;
						}
					}
					var params = {
						"action_code" : 'sqdwvm',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"local_acct_key" : local_acct_send,
						"chart_key" : gridData[0].data.LOCAL_CHART_KEY

					}

					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.errorMessage.length > 0
												&& data.errorMessage !== 'null') {
											AlertService.add("error",
													data.errorMessage);
											vm.trans_flag = false;
											vm.trans_flag_loading = true;
										} else {

											vm.trans_details
													.push(data.jsonObject);
											if (data.jsonObject.length == 0) {
												var temp_confirm_obj = {};
												var deleteDetails = [];

												for ( var e in gridData) {
													temp_confirm_obj = {};
													temp_confirm_obj['system_acct_key'] = gridData[e].data.SYS_ACCT_KEY;
													temp_confirm_obj['local_acct_key'] = gridData[e].data.LOCAL_ACCT_KEY;
													temp_confirm_obj['local_chart_key'] = gridData[e].data.LOCAL_CHART_KEY;
													temp_confirm_obj['tax_year'] = GlobalService.globalParams.tax_year;
													temp_confirm_obj['scenario'] = GlobalService.globalParams.scenario;
													temp_confirm_obj['jcd_key'] = GlobalService.globalParams.jcd_key;

													deleteDetails
															.push(temp_confirm_obj);
												}
												var message = "Data has been successfully Deleted";

												saveCRUDHttpDelete(
														temp_confirm_obj,
														deleteDetails, message);

											} else {
												$uibModalInstance
														.dismiss('cancel');
												AlertService
														.add(
																"info",
																"Transactions are available with this account , Please Delete Transactions First",
																7000);

											}
										}
									})

					/*
					 * else{ var temp_confirm_obj = {}; var deleteDetails = [];
					 * 
					 * for ( var e in gridData) { temp_confirm_obj = {};
					 * temp_confirm_obj['system_acct_key'] =
					 * gridData[e].data.SYS_ACCT_KEY;
					 * temp_confirm_obj['local_acct_key'] =
					 * gridData[e].data.LOCAL_ACCT_KEY;
					 * temp_confirm_obj['local_chart_key'] =
					 * gridData[e].data.LOCAL_CHART_KEY;
					 * temp_confirm_obj['tax_year'] = gridData[e].data.TAX_YEAR;
					 * deleteDetails.push(temp_confirm_obj); } var message =
					 * "Data has been successfully Deleted";
					 * 
					 * 
					 * 
					 * 
					 * 
					 * if (flag_value == false){ //
					 * saveCRUDHttpDelete(deleteDetails, deleteDetails,
					 * message); } else{ vm.trans_flag = true; } }
					 */

				}

				function saveCRUDHttpDelete(confirm_obj, confirm_obj_details,
						message) {
					LocalGroupObjFactory
							.DeleteChartAcctMap(confirm_obj,
									confirm_obj_details)
							.then(
									function(result) {

										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage, 4000);
										} else {
											$uibModalInstance.dismiss('cancel');
											AlertService.add("success",
													message, 4000);

											$timeout(function() {
												// vm.loadSystemTaxsensitivitydata
												// = vm.deletedata;

												var args = {
														local_acct_key : (rowData == null) ? vm.local_acct_grid_update
																: rowData.LOCAL_ACCT_KEY,
														
														gridFilter : {
															local_acct_key : (rowData == null) ? vm.local_acct_grid_update
																	: rowData.LOCAL_ACCT_KEY,
															
														}
													};
												$rootScope.$emit('gridUpdate',
														args);
											});
										}
									});
				}
				vm.save = function() {

					if ($scope.crudLoading) {
						AlertService.add("info",
								"Please wait while we save this request", 4000);
						return;
					}
					$scope.crudLoading = true;

					var index = _.findIndex(vm.sys_acc_dropdown_data_TEMP, {
						'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
					});
					vm.selectedDropDownData = vm.sys_acc_dropdown_data_TEMP[index];
					returnObj = {};
					var tempobj = {};
					returnObj.type = 'E';
					returnObj["object_id"] = (rowData != undefined) ? rowData.object_id
							: vm.localacctdisplay[0].data.object_id
					returnObj['system_acct_key_old'] = (rowData != undefined) ? rowData.SYS_ACCT_KEY
							: gridData[0].data.SYS_ACCT_KEY;
					returnObj['system_acct_key_new'] = vm.selectedDropDownData.SYS_ACCT_KEY;
					returnObj['map_type'] = (rowData != undefined) ? rowData.MAP_TYPE
							: gridData[0].data.MAP_TYPE;
					returnObj['group_object_key'] = (rowData != undefined) ? rowData.GROUP_OBJ_KEY
							: gridData[0].data.GROUP_OBJ_KEY;
					returnObj['tax_year'] = (rowData != undefined) ? rowData.TAX_YEAR
							: gridData[0].data.TAX_YEAR;
					returnObj['local_chart_key'] = (rowData != undefined) ? rowData.LOCAL_CHART_KEY
							: gridData[0].data.LOCAL_CHART_KEY;
					returnObj['acct_type'] = vm.chartacct_data.acctType;
							returnObj['local_acct_key'] = (rowData != undefined) ? rowData.LOCAL_ACCT_KEY
									: gridData[0].data.LOCAL_ACCT_KEY,
							returnObj['scenario'] = GlobalService.globalParams.scenario;
					var message = "Data has been successfully save";
					vm.chartacct_data.local_system_accounts = vm.chartacct_data.localaccts
							.split('\n')
							.map(
									function(item, index) {
										return {
											type : returnObj.type,
											object_id : (rowData != undefined) ? returnObj.object_id
													: vm.localacctdisplay[index].data.OBJECT_ID,
											system_acct_key_old : returnObj.system_acct_key_old,
											system_acct_key_new : returnObj.system_acct_key_new,
											local_acct_key : (rowData != undefined) ? rowData.LOCAL_ACCT_KEY
													: vm.localacctdisplay[index].data.LOCAL_ACCT_KEY,
											map_type : returnObj.map_type,
											group_object_key : returnObj.group_object_key,
											tax_year : returnObj.tax_year,
											local_chart_key : returnObj.local_chart_key,
											scenario : returnObj.scenario,
											local_account : item,
											system_account : vm.CHECK_SYS_ACCT
										};
										return {
											local_account_keys : gridData[index].data.LOCAL_ACCT_KEYS,
											local_account : item,
											system_account : vm.CHECK_SYS_ACCT
										};
									})
					var data = vm.loadSystemTaxsensitivitydata;
					var taxsensitiveDetails = [];
					for ( var y in vm.loadSystemTaxsensitivitydata)

					{
						if (vm.loadSystemTaxsensitivitydata[y].saveFlag == true) {
							vm.loadSystemTaxsensitivitydata[y].newuserdescvalue = vm.loadSystemTaxsensitivitydata[y].USER_DESC_DEFAULT;
							vm.loadSystemTaxsensitivitydata[y].newscdhmtypevalue = vm.loadSystemTaxsensitivitydata[y].SCHDM_TYPE_DEFAULT;
							vm.loadSystemTaxsensitivitydata[y].newpostingtacvalue = vm.loadSystemTaxsensitivitydata[y].POSTING_TAC_DEFAULT;
							vm.loadSystemTaxsensitivitydata[y].newpostingacctvalue = vm.loadSystemTaxsensitivitydata[y].POSTING_ACCT_DEFAULT;
							vm.loadSystemTaxsensitivitydata[y].newtbbsacctvalue = vm.loadSystemTaxsensitivitydata[y].TAX_STREAM_ACCT_DEFAULT;
							vm.loadSystemTaxsensitivitydata[y].newmethodofcalcvalue = vm.loadSystemTaxsensitivitydata[y].METHOD_OF_CALC_DEFAULT;

						}

					}

					for ( var ye in vm.loadSystemTaxsensitivitydata)

					{
						if (vm.loadSystemTaxsensitivitydata[ye].saveFlag == true) {

							if (vm.loadSystemTaxsensitivitydata[ye].newtbbsacctvalue === undefined
									|| vm.loadSystemTaxsensitivitydata[ye].newtbbsacctvalue === ' - '
									|| vm.loadSystemTaxsensitivitydata[ye].newtbbsacctvalue == "----------Chart Based Accounts---------"
									|| vm.loadSystemTaxsensitivitydata[ye].newtbbsacctvalue == "----------All Other Accounts---------") {
								AlertService.add("error",
										"Please Select TBBS Acct", 4000);
								$scope.crudLoading = false;
								throw new ("This is not an error. This is just to abort javascript!");
							}

						}

					}

					for ( var x in vm.loadSystemTaxsensitivitydata) {

						if (vm.loadSystemTaxsensitivitydata[x].saveFlag == true) {
							tempobj = {};
							tempobj.type = 'E';
							tempobj['tax_year'] = (rowData != undefined) ? rowData.TAX_YEAR
									: gridData[0].data.TAX_YEAR;
							tempobj['scenario'] = GlobalService.globalParams.scenario;
							tempobj["triggerchart"] = vm.loadSystemTaxsensitivitydata[x].TRIGGER_CHART;
							tempobj["triggeracct"] = vm.loadSystemTaxsensitivitydata[x].TRIGGER_ACCT;
							tempobj["newtemplatevalue"] = vm.loadSystemTaxsensitivitydata[x].SCHDM_TEMPLATE_DEFAULT;

							// changes adding template_id

							for ( var g in vm.loadSystemTaxsensitivitydata) {
								if (tempobj["newtemplatevalue"] == vm.loadSystemTaxsensitivitydata[g].SCHDM_TEMPLATE) {
									tempobj["newtemplateid"] = vm.loadSystemTaxsensitivitydata[g].SCHDM_TEMPLATE_ID;
									tempobj["prefered_y_n"] = vm.loadSystemTaxsensitivitydata[g].PREFERED_Y_N;
									break;
								}
							}

							tempobj["oldtemplatevalue"] = vm.loadSystemTaxsensitivitydata[x].oldtemplatevalue;
							tempobj["olduserdescvalue"] = vm.loadSystemTaxsensitivitydata[x].olduserdescvalue;
							tempobj["newuserdescvalue"] = vm.loadSystemTaxsensitivitydata[x].USER_DESC_DEFAULT;
							tempobj["scdhmid"] = vm.loadSystemTaxsensitivitydata[x].SCHDM_ID;

							// new ones
							tempobj["oldpostingtac"] = vm.loadSystemTaxsensitivitydata[x].oldpostingtacvalue;
							tempobj["newpostingtac"] = vm.loadSystemTaxsensitivitydata[x].newpostingtacvalue;
							tempobj["oldschdmtype"] = vm.loadSystemTaxsensitivitydata[x].oldscdhmtypevalue;
							tempobj["newschdmtype"] = vm.loadSystemTaxsensitivitydata[x].newscdhmtypevalue;
							tempobj["oldpostingacct"] = vm.loadSystemTaxsensitivitydata[x].oldpostingacctvalue;
							tempobj["newpostingacct"] = vm.loadSystemTaxsensitivitydata[x].newpostingacctvalue;
							tempobj["oldtbbsacct"] = vm.loadSystemTaxsensitivitydata[x].oldtbbsacctvalue;
							tempobj["newtbbsacct"] = vm.loadSystemTaxsensitivitydata[x].newtbbsacctvalue;
							tempobj["oldmethodofcalc"] = vm.loadSystemTaxsensitivitydata[x].oldmethodofcalcvalue;
							tempobj["newmethodofcalc"] = vm.loadSystemTaxsensitivitydata[x].newmethodofcalcvalue;
							tempobj["oldmultipliervalue"] = vm.loadSystemTaxsensitivitydata[x].oldmultipliervalue;
							tempobj["newmultipliervalue"] = vm.loadSystemTaxsensitivitydata[x].MULTIPLIER;

							for ( var c in vm.loadSystemTaxsensitivitydata[x].schdmtypedropdown_all) {
								if (tempobj["newschdmtype"] == vm.loadSystemTaxsensitivitydata[x].schdmtypedropdown_all[c].SCHDM_TYPE) {
									tempobj["newschdmtype"] = vm.loadSystemTaxsensitivitydata[x].schdmtypedropdown_all[c].SCHDM_TYPE_SAVE;
								}

							}
							for ( var c in vm.loadSystemTaxsensitivitydata[x].methodofcaldropdown_all) {
								if (tempobj["newmethodofcalc"] == vm.loadSystemTaxsensitivitydata[x].methodofcaldropdown_all[c].METHOD_OF_CALC_DESC) {
									tempobj["newmethodofcalc"] = vm.loadSystemTaxsensitivitydata[x].methodofcaldropdown_all[c].METHOD_OF_CALC;
								}

							}
							for ( var c in vm.loadSystemTaxsensitivitydata[x].postingtacdropdown_all) {
								if (tempobj["newpostingtac"] == vm.loadSystemTaxsensitivitydata[x].postingtacdropdown_all[c].POSTING_TAC) {
									tempobj["newpostingtac"] = vm.loadSystemTaxsensitivitydata[x].postingtacdropdown_all[c].POSTING_TAC_SAVE;
								}

							}
							for ( var c in vm.loadSystemTaxsensitivitydata[x].postingacctdropdown_all) {
								if (tempobj["newpostingacct"] == vm.loadSystemTaxsensitivitydata[x].postingacctdropdown_all[c].POSTING_ACCT) {
									tempobj["newpostingacct"] = vm.loadSystemTaxsensitivitydata[x].postingacctdropdown_all[c].POSTING_ACCT_SAVE;
								}

							}

							for ( var c in vm.loadSystemTaxsensitivitydata[x].tbbsacct_all) {
								if (tempobj["newtbbsacct"] == vm.loadSystemTaxsensitivitydata[x].tbbsacct_all[c].TAX_STREAM_ACCT) {
									tempobj["newtbbsacct"] = vm.loadSystemTaxsensitivitydata[x].tbbsacct_all[c].TAX_STREAM_ACCT_SAVE;
								}

							}

							taxsensitiveDetails.push(tempobj);
						}
					}

					var message = "Data has been successfully save";
					saveCRUDHttp(vm.chartacct_data.local_system_accounts,
							taxsensitiveDetails, message);
				}

				function saveCRUDHttp(returnObj, details, message) {

					LocalGroupObjFactory
							.saveIRSFormDetails(returnObj, details)
							.then(
									function(result) {
										// vm.setSaveflag = false;
										if (result.callSuccess == "0"
												&& result.errorMessage
												&& result.errorMessage !== 'null') {
											var messageCustom = null;
											if (result.errorMessage
													.includes('Invalid System Account Number')) {
												var systemMessage = (details != undefined && details.length > 0) ? details[0].system_acct_key_new
														: '';

												messageCustom = result.errorMessage
														+ 'System Account Key : '
														+ systemMessage;
											}
											if (result.errorMessage
													.includes('Invalid Local Account Number')) {
												var localMessage = details
														.map(function(msg) {
															return 'Local Account Key : '
																	+ msg.local_acct_key
																	+ '\n';
														});
												messageCustom = result.errorMessage
														+ localMessage;
											}

											if (messageCustom === null) {
												messageCustom = result.errorMessage;
											}
											AlertService.add("error",
													messageCustom, 4000);
											$scope.crudLoading = false;

										} else if (result.callSuccess == "1") {
											AlertService.add("success",
													message, 4000);
											$uibModalInstance.dismiss('cancel');
											var args = {
												local_acct_key : (rowData == null) ? vm.local_acct_grid_update
														: rowData.LOCAL_ACCT_KEY,
												sys_acct_key : (typeof vm.sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY
														: vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY,

												gridFilter : {
													local_acct_key : (rowData == null) ? vm.local_acct_grid_update
															: rowData.LOCAL_ACCT_KEY,
													sys_acct_key : (typeof vm.sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY
															: vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY,

												}
											};

											$rootScope
													.$emit('gridUpdate', args);

											for (var index = 0; index < vm.loadSystemTaxsensitivitydata.length; index++) {

												vm.loadSystemTaxsensitivitydata[index].isEditable = false;
												vm.loadSystemTaxsensitivitydata[index].saveFlag = false;
											}
										}
									});

				}
				vm.resetLookup = function() {
					vm.CHECK_FORM = '';
					vm.CHECK_SCHEDULE = '';
					vm.CHECK_SECTION = '';
					vm.CHECK_PART = '';
					vm.CHECK_LINE = '';
					vm.CHECK_COL = '';
					vm.CHECK_SYS_MAP_ACCT = '';
				};

				function init() {
					fetchtaxsensitivedata(false);
					getSystemAcctdropdown();
					fetchSystemAcctLookup();
					$scope.crudLoading = false;
				}
				init();

				vm.toggleLookup = function() {
					vm.lookUpMapping = !vm.lookUpMapping;
				};

				vm.taxsensitivetoggleLookup = function() {
					vm.taxsensitivelookUpMapping = !vm.taxsensitivelookUpMapping;
				}

				vm.formEdit = function(row) {
					vm.loading = true;
					fetchTBBSAcctData(row);
					$timeout(function() {
						var rowindex = _.findIndex(
								vm.loadSystemTaxsensitivitydata, {
									'$$hashKey' : row.$$hashKey
								});
						for (var index = 0; index < vm.loadSystemTaxsensitivitydata.length; index++) {
							if (rowindex == index
									&& vm.loadSystemTaxsensitivitydata[index].saveFlag != true) {
								vm.loadSystemTaxsensitivitydata[index].saveFlag = true;
								vm.loadSystemTaxsensitivitydata[rowindex].oldscdhmtypevalue = row.SCHDM_TYPE_DEFAULT_SAVE;
								vm.loadSystemTaxsensitivitydata[rowindex].oldpostingtacvalue = row.POSTING_TAC_DEFAULT_SAVE;
								vm.loadSystemTaxsensitivitydata[rowindex].oldpostingacctvalue = row.POSTING_ACCT_DEFAULT_SAVE;
								vm.loadSystemTaxsensitivitydata[rowindex].oldtbbsacctvalue = row.TAX_STREAM_ACCT_DEFAULT_SAVE;
								vm.loadSystemTaxsensitivitydata[rowindex].oldmethodofcalcvalue = row.METHOD_OF_CALC_DEFAULT_SAVE;
								vm.loadSystemTaxsensitivitydata[rowindex].oldtemplatevalue = row.SCHDM_TEMPLATE_DEFAULT;
								vm.loadSystemTaxsensitivitydata[rowindex].olduserdescvalue = row.USER_DESC_DEFAULT;
								vm.loadSystemTaxsensitivitydata[rowindex].oldmultipliervalue = row.MULTIPLIER;
							}
						}
						row.isEditable = true;
						vm.showDetails.text = 'edit';
						// taxsensitive display dropdowns
						vm.templocalaccount = [];
						vm.postingacct = [];
						vm.scdhmtype = [];
						vm.taxstreamaccount = [];
						vm.methodofcalc = [];
						vm.postingtac = [];
						vm.postingacct_all = [];
						vm.scdhmtype_all = [];
						vm.taxstreamaccount_all = [];
						vm.methodofcalc_all = [];
						vm.postingtac_all = [];
						changetemplatetempdata = [];
						for ( var j in vm.loadSystemTaxsensitivitydata) {
							if (row.TRIGGER_ACCT == vm.loadSystemTaxsensitivitydata[j].TRIGGER_ACCT) {

								if (vm.loadSystemTaxsensitivitydata[j].SCHDM_TEMPLATE)
									vm.templocalaccount
											.push(vm.loadSystemTaxsensitivitydata[j].SCHDM_TEMPLATE);
							}
						}
						vm.templocalaccount = _.uniq(vm.templocalaccount,
								JSON.stringify)

						// vm.temploacalaccount_disabled = [];
						// var temploacalaccount_disabled = {};

						// for (var s in vm.templocalaccount)
						//								
						// {
						// temploacalaccount_disabled = {};
						// if (vm.templocalaccount[s]===
						// '----------Preferred---------' ||
						// vm.templocalaccount[s] === '-------Non
						// Preferred-------' )
						// {
						// temploacalaccount_disabled["one"] = true;
						// temploacalaccount_disabled["two"] =
						// vm.templocalaccount[s];
						// }
						// else {
						// temploacalaccount_disabled["one"] = false;
						// temploacalaccount_disabled["two"] =
						// vm.templocalaccount[s];
						// }
						// vm.temploacalaccount_disabled.push(temploacalaccount_disabled);
						// }
					});

					$timeout(function() {
						var params = {};
						var systemaccountsedit = "";
						var tempDropData = {};
						schdm_temp_value_edit = "";
						vm.checksystemaccountedit = [];
						var postingtac_sys_acct = "";

						for ( var d in vm.loadSystemTaxsensitivitydata) {
							if (row.SCHDM_TEMPLATE_DEFAULT == vm.loadSystemTaxsensitivitydata[d].SCHDM_TEMPLATE_DEFAULT) {
								schdm_temp_value_edit = vm.loadSystemTaxsensitivitydata[d].SCHDM_TEMPLATE_ID_DEFAULT;
							}
						}

						var index = _.findIndex(vm.sys_acc_dropdown_data_TEMP,
								{
									'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
								});
						tempDropData = vm.sys_acc_dropdown_data_TEMP[index];
						if (rowData != null) {
							params = {
								"action_code" : 'kbaflc',
								"tax_year" : GlobalService.globalParams.tax_year,
								"scenario" : GlobalService.globalParams.scenario,
								"jcd_key" : GlobalService.globalParams.jcd_key,
								"sys_acct" : rowData.LOCAL_CHART_KEY + "_"
										+ rowData.LOCAL_ACCT_KEY + "-"
										+ tempDropData.SYS_ACCT_KEY,
								// "schdm_temp" : row.SCHDM_TEMPLATE_DEFAULT,
								"schdm_temp" : schdm_temp_value_edit
							}
							postingtac_sys_acct = rowData.LOCAL_CHART_KEY + "_"
									+ rowData.LOCAL_ACCT_KEY + "-"
									+ tempDropData.SYS_ACCT_KEY;
						} else {

							for ( var i in gridData) {
								vm.checksystemaccountedit
										.push(gridData[i].data.LOCAL_CHART_KEY
												+ "_"
												+ gridData[i].data.LOCAL_ACCT_KEY
												+ "-"
												+ tempDropData.SYS_ACCT_KEY);

							}
							vm.checksystemaccountedit = _.uniq(
									vm.checksystemaccountedit, JSON.stringify)
							systemaccountsedit = vm.checksystemaccountedit
									.join();

							params = {
								"action_code" : 'kbaflc',
								"tax_year" : GlobalService.globalParams.tax_year,
								"scenario" : GlobalService.globalParams.scenario,
								"jcd_key" : GlobalService.globalParams.jcd_key,
								"sys_acct" : systemaccountsedit,
								// "schdm_temp" : row.SCHDM_TEMPLATE_DEFAULT,
								"schdm_temp" : schdm_temp_value_edit,
							}
							postingtac_sys_acct = systemaccountsedit;
						}
						return JsonObjectFactory
								.getJSONObj(
										GENERAL_CONFIG.base_url
												+ '/loadJsonObject', params)
								.then(
										function(data) {

											vm.loading = false;
											if (data.callSuccess === "1") {

												vm.tempEditdata = data.jsonObject;
												changetemplatetempdata = vm.tempEditdata;
												var rowindexofchangetemplate = row.$$hashKey;
												var rowindexselectedvalue = row.SCHDM_TEMPLATE_DEFAULT;
												for (var m = 0; m < changetemplatetempdata.length; m++) {
													if (rowindexselectedvalue == changetemplatetempdata[m].SCHDM_TEMPLATE) {
														/*
														 * var tempePostingData =
														 * {};
														 * tempePostingData["POSTING_ACCT"] =
														 * changetemplatetempdata[m].POSTING_ACCT;
														 * tempePostingData["POSTING_ACCT_SAVE"] =
														 * changetemplatetempdata[m].POSTING_ACCT_SAVE;
														 * 
														 * vm.postingacct_all
														 * .push(tempePostingData);
														 * vm.postingacct
														 * .push(changetemplatetempdata[m].POSTING_ACCT);
														 */

														var tempeschdmtypeData = {};
														tempeschdmtypeData["SCHDM_TYPE"] = changetemplatetempdata[m].SCHDM_TYPE;
														tempeschdmtypeData["SCHDM_TYPE_SAVE"] = changetemplatetempdata[m].SCHDM_TYPE_SAVE;
														vm.scdhmtype_all
																.push(tempeschdmtypeData);
														vm.scdhmtype
																.push(changetemplatetempdata[m].SCHDM_TYPE);

														// vm.taxstreamaccount.push(changetemplatetempdata[m].TAX_STREAM_ACCT);

														var methodofcalcData = {};
														methodofcalcData["METHOD_OF_CALC_DESC"] = changetemplatetempdata[m].METHOD_OF_CALC_DESC;
														methodofcalcData["METHOD_OF_CALC"] = changetemplatetempdata[m].METHOD_OF_CALC;
														vm.methodofcalc_all
																.push(methodofcalcData);
														vm.methodofcalc
																.push(changetemplatetempdata[m].METHOD_OF_CALC_DESC);

														/*
														 * var postingtacData =
														 * {};
														 * postingtacData["POSTING_TAC"] =
														 * changetemplatetempdata[m].POSTING_TAC;
														 * postingtacData["POSTING_TAC_SAVE"] =
														 * changetemplatetempdata[m].POSTING_TAC_SAVE;
														 * vm.postingtac_all
														 * .push(postingtacData)
														 * vm.postingtac
														 * .push(changetemplatetempdata[m].POSTING_TAC);
														 */

													}
												}
												getPostingEditTacData();

												function getPostingEditTacData() {
													var params = {
														"action_code" : 'iemg45',
														"tax_year" : GlobalService.globalParams.tax_year,
														"scenario" : GlobalService.globalParams.scenario,
														"jcd_key" : GlobalService.globalParams.jcd_key,
														"sys_acct" : postingtac_sys_acct,
														"sys_acct_de" : row.POSTING_TAC_DEFAULT_SAVE,
														"template":schdm_temp_value_edit

													}
													return JsonObjectFactory
															.getJSONObj(
																	GENERAL_CONFIG.base_url
																			+ '/loadJsonObject',
																	params)
															.then(
																	function(
																			data) {

																		if (data.callSuccess === "1") {
																			vm.postingtac_all = [];
																			vm.postingtac = [];
																			vm.postingtac_all = data.jsonObject;
																			for (var d = 0; d < vm.postingtac_all.length; d++) {
																				vm.postingtac
																						.push(vm.postingtac_all[d].POSTING_TAC);
																			}
																			vm.postingtac = _
																					.uniq(
																							vm.postingtac,
																							JSON.stringify)

																			fetchPostingacct_edit_template();
																			function fetchPostingacct_edit_template() {

																				var params = {
																					"action_code" : 'sdcmbv',
																					"tax_year" : GlobalService.globalParams.tax_year,
																					"scenario" : GlobalService.globalParams.scenario,
																					"jcd_key" : GlobalService.globalParams.jcd_key,
																					"sys_acct" : row.POSTING_TAC_DEFAULT_SAVE,
																					"pa_acct" : row.POSTING_ACCT_DEFAULT_SAVE,
																					"chart_key" : (rowData != undefined) ? rowData.LOCAL_CHART_KEY
																							: gridData[0].data.LOCAL_CHART_KEY

																				}
																				return JsonObjectFactory
																						.getJSONObj(
																								GENERAL_CONFIG.base_url
																										+ '/loadJsonObject',
																								params)
																						.then(
																								function(
																										data) {

																									if (data.callSuccess === "1") {
																										vm.postingacct = [];
																										vm.postingacct_all = [];
																										vm.postingacct_all = data.jsonObject;
																										for (var d = 0; d < vm.postingacct_all.length; d++) {
																											vm.postingacct
																													.push(vm.postingacct_all[d].POSTING_ACCT);
																										}

																										// vm.loading
																										// =
																										// false;
																										vm.postingacct = _
																										.uniq(
																												vm.postingacct,
																												JSON.stringify)
																								vm.scdhmtype = _
																										.uniq(
																												vm.scdhmtype,
																												JSON.stringify)

																								vm.methodofcalc = _
																										.uniq(
																												vm.methodofcalc,
																												JSON.stringify)
																								// vm.postingtac
																								// =
																								// _.uniq(
																								// vm.postingtac,
																								// JSON.stringify)

																								var rowindexofdp = _
																										.findIndex(
																												vm.loadSystemTaxsensitivitydata,
																												{
																													'$$hashKey' : row.$$hashKey
																												});
																								vm.loadSystemTaxsensitivitydata[rowindexofdp].postingtacdropdown_all = vm.postingtac_all;
																								vm.loadSystemTaxsensitivitydata[rowindexofdp].methodofcaldropdown_all = vm.methodofcalc_all;
																								vm.loadSystemTaxsensitivitydata[rowindexofdp].postingacctdropdown_all = vm.postingacct_all;
																								vm.loadSystemTaxsensitivitydata[rowindexofdp].schdmtypedropdown_all = vm.scdhmtype_all;

																								vm.loadSystemTaxsensitivitydata[rowindexofdp].postingtacdropdown = vm.postingtac;
																								vm.loadSystemTaxsensitivitydata[rowindexofdp].methodofcaldropdown = vm.methodofcalc;
																								vm.loadSystemTaxsensitivitydata[rowindexofdp].postingacctdropdown = vm.postingacct;
																								// vm.loadSystemTaxsensitivitydata[rowindexofdp].postingacctdropdown
																								// =
																								// vm.postingacct;
																								vm.loadSystemTaxsensitivitydata[rowindexofdp].schdmtypedropdown = vm.scdhmtype;
																								vm.loading = false;

																									} else {
																										if (data.errorMessage === "no access") {
																											AlertService
																													.add(
																															"",
																															"Sorry you do not have access to do the requested action.",
																															4000);
																											vm.userMessage = "!! Unable to perform Selected Action...";
																											vm.loading = false;

																										} else {
																											AlertService
																													.add(
																															"",
																															"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																															4000);
																											vm.userMessage = "!! Unable to perform Selected Action...";
																											vm.loading = false;

																										}
																									}

																								});
																			}

																		
																			// vm.loading
																			// =
																			// false;
																		} else {
																			if (data.errorMessage === "no access") {
																				AlertService
																						.add(
																								"",
																								"Sorry you do not have access to do the requested action.",
																								4000);
																				vm.userMessage = "!! Unable to perform Selected Action...";
																				// vm.loading
																				// =
																				// false;

																			} else {
																				AlertService
																						.add(
																								"",
																								"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																								4000);
																				vm.userMessage = "!! Unable to perform Selected Action...";
																				// vm.loading
																				// =
																				// false;

																			}
																		}

																	});
												}

											} else {
												if (data.errorMessage === "no access") {
													AlertService
															.add(
																	"",
																	"Sorry you do not have access to do the requested action.",
																	4000);
													vm.userMessage = "!! Unable to perform Selected Action...";
												} else {
													AlertService
															.add(
																	"",
																	"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																	4000);
													vm.userMessage = "!! Unable to perform Selected Action...";
												}
											}
										});
					});
				}

				vm.changeTemplate = function(_item) {
					vm.loading = true;
					var tempDropData = {};
					schdm_temp_value = "";
					changetemplatetempdata = [];
					vm.postingacct_all = [];
					vm.scdhmtype_all = [];
					vm.taxstreamaccount_all = [];
					vm.methodofcalc_all = [];
					vm.postingtac_all = [];
					var postingtac_sys_acct = "";
					var index = _.findIndex(vm.sys_acc_dropdown_data_TEMP, {
						'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
					});
					tempDropData = vm.sys_acc_dropdown_data_TEMP[index];
					// fetchTBBSAcctData(_item);
					var par = {};
					var systemaccountschangetemplate = "";
					vm.checksystemaccountchangetemplate = [];
					for ( var d in vm.loadSystemTaxsensitivitydata) {
						if (_item.SCHDM_TEMPLATE_DEFAULT == vm.loadSystemTaxsensitivitydata[d].SCHDM_TEMPLATE) {
							schdm_temp_value = vm.loadSystemTaxsensitivitydata[d].SCHDM_TEMPLATE_ID;
						}
					}
					if (rowData != null) {

						par = {
							"action_code" : 'kbaflc',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,

							"sys_acct" : rowData.LOCAL_CHART_KEY + "_"
									+ rowData.LOCAL_ACCT_KEY + "-"
									+ tempDropData.SYS_ACCT_KEY,
							// "local_accts": rowData.SYS_ACCT_KEY,
							// "schdm_temp" : _item.SCHDM_TEMPLATE_DEFAULT,
							"schdm_temp" : schdm_temp_value,
						}
						postingtac_sys_acct = rowData.LOCAL_CHART_KEY + "_"
								+ rowData.LOCAL_ACCT_KEY + "-"
								+ tempDropData.SYS_ACCT_KEY

					} else {

						for ( var i in gridData) {
							vm.checksystemaccountchangetemplate
									.push(gridData[i].data.LOCAL_CHART_KEY
											+ "_"
											+ gridData[i].data.LOCAL_ACCT_KEY
											+ "-" + tempDropData.SYS_ACCT_KEY);

						}
						vm.checksystemaccountchangetemplate = _.uniq(
								vm.checksystemaccountchangetemplate,
								JSON.stringify)
						systemaccountschangetemplate = vm.checksystemaccountchangetemplate
								.join();

						par = {
							"action_code" : 'kbaflc',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"sys_acct" : systemaccountschangetemplate,
							// "local_accts": paramssystemaccount,
							// "schdm_temp" : _item.SCHDM_TEMPLATE_DEFAULT,
							"schdm_temp" : schdm_temp_value,
						}
						postingtac_sys_acct = systemaccountschangetemplate;
					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									par)
							.then(
									function(data) {

										// vm.loading = false;
										if (data.callSuccess === "1") {
											vm.tempEditdata = data.jsonObject;
											vm.setSaveflag = true;
											_item.updated = true;

											changetemplatetempdata = vm.tempEditdata;
											var rowindexofchangetemplate = _item.$$hashKey;
											var rowindexselectedvalue = _item.SCHDM_TEMPLATE_DEFAULT;
											var indexof = "";
											var displayuserdesc = "";
											var displaypostingacctvalue = "";
											var displayscdhmtype = "";
											var displaypostingtacvalue = "";
											var displaymultipliervalue = "";
											var displaytbbsacctvalue = "";
											var displaymethodofcalcvalue = "";
											// save default added
											var savedisplaypostingacctvalue = "";
											var savedisplayscdhmtype = "";
											var savedisplaypostingtacvalue = "";
											var savedisplaytbbsacctvalue = "";
											var savedisplaymethodofcalcvalue = "";

											var posting_tac_de_save = "";
											var posting_acct_de_save = "";

											/*
											 * for(var n in
											 * vm.loadSystemTaxsensitivitydata) {
											 * vm.loadSystemTaxsensitivitydata[n].dispalyDefaultonChange =
											 * false; }
											 */
											for (var m = 0; m < changetemplatetempdata.length; m++) {
												if (rowindexselectedvalue == changetemplatetempdata[m].SCHDM_TEMPLATE) {
													displayuserdesc = changetemplatetempdata[m].USER_DESC;
													displaypostingtacvalue = changetemplatetempdata[m].POSTING_TAC_DEFAULT;
													displaymultipliervalue = changetemplatetempdata[m].MULTIPLIER;
													displayscdhmtype = changetemplatetempdata[m].SCHDM_TYPE_DEFAULT;
													displaytbbsacctvalue = changetemplatetempdata[m].TAX_STREAM_ACCT_DEFAULT;
													displaymethodofcalcvalue = changetemplatetempdata[m].METHOD_OF_CALC_DESC;
													displaypostingacctvalue = changetemplatetempdata[m].POSTING_ACCT_DEFAULT;
													//rc change
													if(rowData != null){
														if (rowData.ACCT_TYPES == '5'|| rowData.ACCT_TYPES == '6'
															|| rowData.ACCT_TYPES == '7' )
															{
															if ( displaymethodofcalcvalue == 'Reverse Current Year Balance')
															{
																posting_tac_de_save = rowData.SYS_ACCT_KEY;
																posting_acct_de_save = rowData.LOCAL_ACCT_KEY;
															}
															else
																{
																posting_tac_de_save = changetemplatetempdata[m].POSTING_TAC_DEFAULT_SAVE;
																posting_acct_de_save = changetemplatetempdata[m].POSTING_ACCT_DEFAULT_SAVE;
																}
															}
														else
														{
															posting_tac_de_save = changetemplatetempdata[m].POSTING_TAC_DEFAULT_SAVE;
															posting_acct_de_save = changetemplatetempdata[m].POSTING_ACCT_DEFAULT_SAVE;
														}
													}
													
													else if(rowData == null){
														if (gridData[0].data.ACCT_TYPES === '5'|| gridData[0].data.ACCT_TYPES === '6'
															|| gridData[0].data.ACCT_TYPES === '7')
															{
															if ( displaymethodofcalcvalue == 'Reverse Current Year Balance'){
																posting_tac_de_save = gridData[0].data.SYS_ACCT_KEY;
																posting_acct_de_save = gridData[0].data.LOCAL_ACCT_KEY;
															}
															else
																{
																posting_tac_de_save = changetemplatetempdata[m].POSTING_TAC_DEFAULT_SAVE;
																posting_acct_de_save = changetemplatetempdata[m].POSTING_ACCT_DEFAULT_SAVE;
																}
															}
														else
														{
															posting_tac_de_save = changetemplatetempdata[m].POSTING_TAC_DEFAULT_SAVE;
															posting_acct_de_save = changetemplatetempdata[m].POSTING_ACCT_DEFAULT_SAVE;
														}
													}
													else {
														posting_tac_de_save = changetemplatetempdata[m].POSTING_TAC_DEFAULT_SAVE;
														posting_acct_de_save = changetemplatetempdata[m].POSTING_ACCT_DEFAULT_SAVE;
													}	
													
													
													
													// save default added

													/*
													 * savedisplaypostingacctvalue =
													 * changetemplatetempdata[m].POSTING_ACCT_DEFAULT_SAVE;
													 * savedisplayscdhmtype =
													 * changetemplatetempdata[m].SCHDM_TYPE_DEFAULT_SAVE;
													 * savedisplaypostingtacvalue =
													 * changetemplatetempdata[m].POSTING_TAC_DEFAULT_SAVE;
													 * savedisplaytbbsacctvalue =
													 * changetemplatetempdata[m].TAX_STREAM_ACCT_DEFAULT_SAVE;
													 * savedisplaymethodofcalcvalue =
													 * changetemplatetempdata[m].METHOD_OF_CALC_DEFAULT_SAVE;
													 */
													/*
													 * var tempePostingData =
													 * {};
													 * tempePostingData["POSTING_ACCT"] =
													 * changetemplatetempdata[m].POSTING_ACCT;
													 * tempePostingData["POSTING_ACCT_SAVE"] =
													 * changetemplatetempdata[m].POSTING_ACCT_SAVE;
													 * 
													 * vm.postingacct_all
													 * .push(tempePostingData);
													 * vm.postingacct
													 * .push(changetemplatetempdata[m].POSTING_ACCT);
													 */

													var tempeschdmtypeData = {};
													tempeschdmtypeData["SCHDM_TYPE"] = changetemplatetempdata[m].SCHDM_TYPE;
													tempeschdmtypeData["SCHDM_TYPE_SAVE"] = changetemplatetempdata[m].SCHDM_TYPE_SAVE;
													vm.scdhmtype_all
															.push(tempeschdmtypeData);
													vm.scdhmtype
															.push(changetemplatetempdata[m].SCHDM_TYPE);

													// vm.taxstreamaccount.push(changetemplatetempdata[m].TAX_STREAM_ACCT);

													var methodofcalcData = {};
													methodofcalcData["METHOD_OF_CALC_DESC"] = changetemplatetempdata[m].METHOD_OF_CALC_DESC;
													methodofcalcData["METHOD_OF_CALC"] = changetemplatetempdata[m].METHOD_OF_CALC;
													vm.methodofcalc_all
															.push(methodofcalcData);
													vm.methodofcalc
															.push(changetemplatetempdata[m].METHOD_OF_CALC_DESC);

													/*
													 * var postingtacData = {};
													 * postingtacData["POSTING_TAC"] =
													 * changetemplatetempdata[m].POSTING_TAC;
													 * postingtacData["POSTING_TAC_SAVE"] =
													 * changetemplatetempdata[m].POSTING_TAC_SAVE;
													 * vm.postingtac_all
													 * .push(postingtacData)
													 * vm.postingtac
													 * .push(changetemplatetempdata[m].POSTING_TAC);
													 */

												}

											}
											vm.scdhmtype = _.uniq(vm.scdhmtype,
													JSON.stringify)

											vm.methodofcalc = _.uniq(
													vm.methodofcalc,
													JSON.stringify)
											getPostingEditTacData_CHANGE();

											function getPostingEditTacData_CHANGE() {
												var params = {
													"action_code" : 'iemg45',
													"tax_year" : GlobalService.globalParams.tax_year,
													"scenario" : GlobalService.globalParams.scenario,
													"jcd_key" : GlobalService.globalParams.jcd_key,
													"sys_acct" : postingtac_sys_acct,
													"sys_acct_de" : posting_tac_de_save,
													"template": schdm_temp_value

												}
												return JsonObjectFactory
														.getJSONObj(
																GENERAL_CONFIG.base_url
																		+ '/loadJsonObject',
																params)
														.then(
																function(data) {

																	if (data.callSuccess === "1") {
																		vm.postingtac_all = [];
																		vm.postingtac = [];
																		vm.postingtac_all = data.jsonObject;
																		for (var d = 0; d < vm.postingtac_all.length; d++) {
																			vm.postingtac
																					.push(vm.postingtac_all[d].POSTING_TAC);
																		}
																		vm.postingtac = _
																				.uniq(
																						vm.postingtac,
																						JSON.stringify)

																		// postinag
																		// acct
																		// get

																		fetchPostingacct_change_template();

																		function fetchPostingacct_change_template() {

																			var params = {
																				"action_code" : 'sdcmbv',
																				"tax_year" : GlobalService.globalParams.tax_year,
																				"scenario" : GlobalService.globalParams.scenario,
																				"jcd_key" : GlobalService.globalParams.jcd_key,
																				"sys_acct" : posting_tac_de_save,
																				"pa_acct" : posting_acct_de_save,
																				"chart_key" : (rowData != undefined) ? rowData.LOCAL_CHART_KEY
																						: gridData[0].data.LOCAL_CHART_KEY

																			}
																			return JsonObjectFactory
																					.getJSONObj(
																							GENERAL_CONFIG.base_url
																									+ '/loadJsonObject',
																							params)
																					.then(
																							function(
																									data) {

																								if (data.callSuccess === "1") {
																									vm.postingacct = [];
																									vm.postingacct_all = [];
																									vm.postingacct_all = data.jsonObject;
																									for (var d = 0; d < vm.postingacct_all.length; d++) {
																										vm.postingacct
																												.push(vm.postingacct_all[d].POSTING_ACCT);
																									}

																									vm.postingacct = _
																											.uniq(
																													vm.postingacct,
																													JSON.stringify)

																									// vm.postingtac
																									// =
																									// _.uniq(
																									// vm.postingtac,
																									// JSON.stringify)

																									// vm.loading
																									// =
																									// false;

																									for (var k = 0; k < vm.loadSystemTaxsensitivitydata.length; k++) {

																										if (vm.loadSystemTaxsensitivitydata[k].$$hashKey == _item.$$hashKey) {
																											vm.loadSystemTaxsensitivitydata[k].USER_DESC_DEFAULT = displayuserdesc;
																											vm.loadSystemTaxsensitivitydata[k].MULTIPLIER = displaymultipliervalue;
																											vm.loadSystemTaxsensitivitydata[k].SCHDM_TYPE_DEFAULT = displayscdhmtype;
																											vm.loadSystemTaxsensitivitydata[k].TAX_STREAM_ACCT_DEFAULT = displaytbbsacctvalue;
																											vm.loadSystemTaxsensitivitydata[k].METHOD_OF_CALC_DEFAULT = displaymethodofcalcvalue;
																											//new RC
																											if(rowData != null){
																												if (rowData.ACCT_TYPES == '5'|| rowData.ACCT_TYPES == '6'
																													|| rowData.ACCT_TYPES == '7' )
																													{
																													if ( vm.loadSystemTaxsensitivitydata[k].METHOD_OF_CALC_DEFAULT == 'Reverse Current Year Balance'){
																													var posting_tac_display_RC = "";
																													posting_tac_display_RC =  (rowData != undefined) ? rowData.SYSTEM_ACCT + " - " + rowData.SYSTEM_ACCT_DESC:
																														gridData[0].SYSTEM_ACCT + " - " + gridData[0].SYSTEM_ACCT_DESC;
																														vm.loadSystemTaxsensitivitydata[k].POSTING_TAC_DEFAULT = posting_tac_display_RC;
																														vm.loadSystemTaxsensitivitydata[k].POSTING_ACCT_DEFAULT = vm.loadSystemTaxsensitivitydata[k].TRIGGER_ACCT_DISPLAY;
																													}
																													else
																														{
																														vm.loadSystemTaxsensitivitydata[k].POSTING_TAC_DEFAULT = displaypostingtacvalue;
																														vm.loadSystemTaxsensitivitydata[k].POSTING_ACCT_DEFAULT = displaypostingacctvalue;
																														}
																													}
																												else
																												{
																												vm.loadSystemTaxsensitivitydata[k].POSTING_TAC_DEFAULT = displaypostingtacvalue;
																												vm.loadSystemTaxsensitivitydata[k].POSTING_ACCT_DEFAULT = displaypostingacctvalue;
																												}
																											}
																											
																											else if(rowData == null){
																												if (gridData[0].data.ACCT_TYPES === '5'|| gridData[0].data.ACCT_TYPES === '6'
																													|| gridData[0].data.ACCT_TYPES === '7')
																													{
																													if ( vm.loadSystemTaxsensitivitydata[k].METHOD_OF_CALC_DEFAULT == 'Reverse Current Year Balance'){
																													var posting_tac_display_RC = "";
																													posting_tac_display_RC =  (rowData != undefined) ? rowData.SYSTEM_ACCT + " - " + rowData.SYSTEM_ACCT_DESC:
																														gridData[0].SYSTEM_ACCT + " - " + gridData[0].SYSTEM_ACCT_DESC;
																														vm.loadSystemTaxsensitivitydata[k].POSTING_TAC_DEFAULT = posting_tac_display_RC;
																														vm.loadSystemTaxsensitivitydata[k].POSTING_ACCT_DEFAULT = vm.loadSystemTaxsensitivitydata[k].TRIGGER_ACCT_DISPLAY;
																													}
																													else
																														{
																														vm.loadSystemTaxsensitivitydata[k].POSTING_TAC_DEFAULT = displaypostingtacvalue;
																														vm.loadSystemTaxsensitivitydata[k].POSTING_ACCT_DEFAULT = displaypostingacctvalue;
																														}
																													}
																												else
																												{
																												vm.loadSystemTaxsensitivitydata[k].POSTING_TAC_DEFAULT = displaypostingtacvalue;
																												vm.loadSystemTaxsensitivitydata[k].POSTING_ACCT_DEFAULT = displaypostingacctvalue;
																												}
																											}
																											else {
																												vm.loadSystemTaxsensitivitydata[k].POSTING_TAC_DEFAULT = displaypostingtacvalue;
																												vm.loadSystemTaxsensitivitydata[k].POSTING_ACCT_DEFAULT = displaypostingacctvalue;
																											}																			
																											vm.loadSystemTaxsensitivitydata[k].updated = true;

																											vm.loadSystemTaxsensitivitydata[k].dispalyDefaultonChange = true;

																											vm.loadSystemTaxsensitivitydata[k].newtemplatevalue = "";
																											vm.loadSystemTaxsensitivitydata[k].newuserdescvalue = displayuserdesc;
																											vm.loadSystemTaxsensitivitydata[k].newscdhmtypevalue = displayscdhmtype;
																											vm.loadSystemTaxsensitivitydata[k].newpostingtacvalue = displaypostingtacvalue;
																											vm.loadSystemTaxsensitivitydata[k].newpostingacctvalue = displaypostingacctvalue;
																											vm.loadSystemTaxsensitivitydata[k].newtbbsacctvalue = displaytbbsacctvalue;
																											vm.loadSystemTaxsensitivitydata[k].newmethodofcalcvalue = displaymethodofcalcvalue;
																											// vm.loadSystemTaxsensitivitydata[k].newmultipliervalue
																											// ="";

																											vm.loadSystemTaxsensitivitydata[k].postingtacdropdown_all = vm.postingtac_all;
																											vm.loadSystemTaxsensitivitydata[k].methodofcaldropdown_all = vm.methodofcalc_all;
																											vm.loadSystemTaxsensitivitydata[k].postingacctdropdown_all = vm.postingacct_all;
																											vm.loadSystemTaxsensitivitydata[k].schdmtypedropdown_all = vm.scdhmtype_all;

																											vm.loadSystemTaxsensitivitydata[k].postingtacdropdown = vm.postingtac;
																											vm.loadSystemTaxsensitivitydata[k].methodofcaldropdown = vm.methodofcalc;
																											vm.loadSystemTaxsensitivitydata[k].postingacctdropdown = vm.postingacct;

																											// vm.loadSystemTaxsensitivitydata[k].postingacctdropdown
																											// =
																											// vm.postingacct;
																											vm.loadSystemTaxsensitivitydata[k].schdmtypedropdown = vm.scdhmtype;

																											fetchTBBSAcctData_temp(_item);

																											function fetchTBBSAcctData_temp(
																													_item) {
																												// vm.loading
																												// =
																												// true;
																												var tempDropData = {};
																												vm.checksystemaccountttbs = [];
																												var systemaccountschangetbbs = "";
																												schdm_temp_tbbs = "";
																												var index = _
																														.findIndex(
																																vm.sys_acc_dropdown_data_TEMP,
																																{
																																	'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
																																});
																												tempDropData = vm.sys_acc_dropdown_data_TEMP[index];
																												for ( var g in vm.loadSystemTaxsensitivitydata) {
																													if (_item.SCHDM_TEMPLATE_DEFAULT == vm.loadSystemTaxsensitivitydata[g].SCHDM_TEMPLATE) {
																														schdm_temp_tbbs = vm.loadSystemTaxsensitivitydata[g].SCHDM_TEMPLATE_ID;
																													}
																												}
																												var schdm_type_key = "";
																												var schdm_method_key = "";

																												for ( var gg in vm.loadSystemTaxsensitivitydata[k].schdmtypedropdown_all) {
																													if (displayscdhmtype === vm.loadSystemTaxsensitivitydata[k].schdmtypedropdown_all[gg].SCHDM_TYPE) {
																														schdm_type_key = vm.loadSystemTaxsensitivitydata[k].schdmtypedropdown_all[gg].SCHDM_TYPE_SAVE;
																													}
																												}

																												for ( var gh in vm.loadSystemTaxsensitivitydata[k].methodofcaldropdown_all) {
																													if (displaymethodofcalcvalue === vm.loadSystemTaxsensitivitydata[k].methodofcaldropdown_all[gh].METHOD_OF_CALC_DESC) {
																														schdm_method_key = vm.loadSystemTaxsensitivitydata[k].methodofcaldropdown_all[gh].METHOD_OF_CALC;
																													}
																												}
																												if (rowData != null) {
																													var params = {
																														"action_code" : 'wfk6zt',
																														"tax_year" : GlobalService.globalParams.tax_year,
																														"sys_acct" : rowData.LOCAL_CHART_KEY
																																+ "_"
																																+ rowData.LOCAL_ACCT_KEY
																																+ "-"
																																+ tempDropData.SYS_ACCT_KEY,
																														"local_accts" : rowData.SYS_ACCT_KEY,
																														// "schdm_temp"
																														// :
																														// _item.SCHDM_TEMPLATE_DEFAULT,
																														"schdm_temp" : schdm_temp_tbbs,
																														"schdm_type" : schdm_type_key,
																														"schdm_method" : schdm_method_key,
																														'source_system' : rowData.SOURCE_SYSTEM

																													}
																												} else {

																													for ( var iii in gridData) {
																														vm.checksystemaccountttbs
																																.push(gridData[iii].data.LOCAL_CHART_KEY
																																		+ "_"
																																		+ gridData[iii].data.LOCAL_ACCT_KEY
																																		+ "-"
																																		+ tempDropData.SYS_ACCT_KEY);

																													}
																													vm.checksystemaccountttbs = _
																															.uniq(
																																	vm.checksystemaccountttbs,
																																	JSON.stringify)
																													systemaccountschangetbbs = vm.checksystemaccountttbs
																															.join();

																													var params = {
																														"action_code" : 'wfk6zt',
																														"tax_year" : GlobalService.globalParams.tax_year,
																														"sys_acct" : systemaccountschangetbbs,
																														"local_accts" : paramssystemaccount,
																														// "schdm_temp"
																														// :
																														// _item.SCHDM_TEMPLATE_DEFAULT,
																														"schdm_temp" : schdm_temp_tbbs,
																														"schdm_type" : schdm_type_key,
																														"schdm_method" : schdm_method_key,
																														'source_system' : gridData[0].data.SOURCE_SYSTEM
																													}

																												}
																												console
																														.log(
																																"Input Data fetchSystemAcctData**********::",
																																params);
																												return JsonObjectFactory
																														.getJSONObj(
																																GENERAL_CONFIG.base_url
																																		+ '/loadJsonObject',
																																params)
																														.then(
																																function(
																																		data) {

																																	if (data.callSuccess === "1") {
																																		vm.tempTBBSAcct = [];
																																		vm.taxstreamaccount = [];
																																		vm.tempTBBSAcct = data.jsonObject;
																																		for (var d = 0; d < vm.tempTBBSAcct.length; d++) {
																																			vm.taxstreamaccount
																																					.push(vm.tempTBBSAcct[d].TAX_STREAM_ACCT);
																																		}
																																		vm.taxstreamaccount = _
																																				.uniq(
																																						vm.taxstreamaccount,
																																						JSON.stringify)

																																		for ( var hta in vm.loadSystemTaxsensitivitydata) {
																																			if (_item.$$hashKey == vm.loadSystemTaxsensitivitydata[hta].$$hashKey) {
																																				vm.loadSystemTaxsensitivitydata[hta].tbbsacct_all = vm.tempTBBSAcct;
																																				vm.loadSystemTaxsensitivitydata[hta].tbbsacctdropdown = vm.taxstreamaccount;
																																				vm.loadSystemTaxsensitivitydata[hta].TAX_STREAM_ACCT_DEFAULT = vm.taxstreamaccount[0];

																																			}
																																		}
																																		vm.loading = false;
																																	} else {
																																		if (data.errorMessage === "no access") {
																																			AlertService
																																					.add(
																																							"",
																																							"Sorry you do not have access to do the requested action.",
																																							4000);
																																			vm.userMessage = "!! Unable to perform Selected Action...";
																																		} else {
																																			AlertService
																																					.add(
																																							"",
																																							"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																																							4000);
																																			vm.userMessage = "!! Unable to perform Selected Action...";
																																		}
																																	}

																																});
																											}
																											;

																											vm.loadSystemTaxsensitivitydata[k].tbbsacct_all = vm.tempTBBSAcct;
																											vm.loadSystemTaxsensitivitydata[k].tbbsacctdropdown = vm.taxstreamaccount;
																										}
																									}

																									// vm.loading
																									// =
																									// false;
																								} else {
																									if (data.errorMessage === "no access") {
																										AlertService
																												.add(
																														"",
																														"Sorry you do not have access to do the requested action.",
																														4000);
																										vm.userMessage = "!! Unable to perform Selected Action...";
																										vm.loading = false;

																									} else {
																										AlertService
																												.add(
																														"",
																														"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																														4000);
																										vm.userMessage = "!! Unable to perform Selected Action...";
																										vm.loading = false;

																									}
																								}

																							});
																		}

																	} else {
																		if (data.errorMessage === "no access") {
																			AlertService
																					.add(
																							"",
																							"Sorry you do not have access to do the requested action.",
																							4000);
																			vm.userMessage = "!! Unable to perform Selected Action...";
																			// vm.loading
																			// =
																			// false;

																		} else {
																			AlertService
																					.add(
																							"",
																							"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																							4000);
																			vm.userMessage = "!! Unable to perform Selected Action...";
																			// vm.loading
																			// =
																			// false;

																		}
																	}

																});
											}

										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});
				}

				vm.formDelete = function(_item) {
					$timeout(function() {
						vm.deletedata = vm.loadSystemTaxsensitivitydata;
						var lengthoftaxsensitivedata = vm.loadSystemTaxsensitivitydata.length;
						for (var d = lengthoftaxsensitivedata; d--;) {
							console
									.log(vm.loadSystemTaxsensitivitydata[d].TRIGGER_ACCT);
							if (vm.loadSystemTaxsensitivitydata[d].TRIGGER_ACCT === _item.TRIGGER_ACCT) {
								vm.loadSystemTaxsensitivitydata.splice(d, 1);
								console.log(d);
							}
						}
						vm.deletetriggeraccount = _item.TRIGGER_ACCT;
						vm.deletetemplatevalue = _item.SCHDM_TEMPLATE_DEFAULT;
						vm.deleteuserdesc = _item.USER_DESC_DEFAULT;
						vm.deletescdhmvalue = _item.SCHDM_TYPE_DEFAULT;
						vm.deletepostingtac = _item.POSTING_TAC_DEFAULT;
						vm.deletepostingacct = _item.POSTING_ACCT_DEFAULT;
						vm.deletemethodofcal = _item.METHOD_OF_CALC_DEFAULT;
						vm.multiplier = _item.MULTIPLIER;
						vm.deletescdhmid = _item.SCHDM_ID;
						vm.deletetriggerchart = _item.TRIGGER_CHART;
						var index = _.findIndex(vm.sys_acc_dropdown_data_TEMP,
								{
									'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
								});
						vm.selectedDropDownData = vm.sys_acc_dropdown_data_TEMP[index];
						var returnObjD = {};
						var returnlocaltosyetemaccount = {};
						var taxsensitiveDeletedata = [];
						returnObjD.type = 'D';
						returnObjD["object_id"] = (rowData != undefined) ? rowData.object_id
								: gridData[0].data.object_id;
						returnObjD['tax_year'] = (rowData != undefined) ? rowData.TAX_YEAR
								: gridData[0].data.TAX_YEAR;
						returnObjD['scenario'] = GlobalService.globalParams.scenario;
						returnObjD['triggeracct'] = vm.deletetriggeraccount;
						// returnObjD['posting_tac'] = vm.deletepostingtac;
						// returnObjD['postint_acct'] = vm.deletepostingacct;
						returnObjD['scdhmid'] = vm.deletescdhmid;
						returnObjD['triggerchart'] = vm.deletetriggerchart;

						// local to system

						returnlocaltosyetemaccount.type = 'E';
						returnlocaltosyetemaccount["object_id"] = (rowData != undefined) ? rowData.object_id
								: vm.localacctdisplay[0].data.object_id
						returnlocaltosyetemaccount['system_acct_key_old'] = (rowData != undefined) ? rowData.SYS_ACCT_KEY
								: gridData[0].data.SYS_ACCT_KEY;
						returnlocaltosyetemaccount['system_acct_key_new'] = vm.selectedDropDownData.SYS_ACCT_KEY;
						returnlocaltosyetemaccount['map_type'] = (rowData != undefined) ? rowData.MAP_TYPE
								: gridData[0].data.MAP_TYPE;
						returnlocaltosyetemaccount['group_object_key'] = (rowData != undefined) ? rowData.GROUP_OBJ_KEY
								: gridData[0].data.GROUP_OBJ_KEY;
						returnlocaltosyetemaccount['tax_year'] = (rowData != undefined) ? rowData.TAX_YEAR
								: gridData[0].data.TAX_YEAR;
						returnlocaltosyetemaccount['local_chart_key'] = (rowData != undefined) ? rowData.LOCAL_CHART_KEY
								: gridData[0].data.LOCAL_CHART_KEY;
						returnlocaltosyetemaccount['acct_type'] = vm.chartacct_data.acctType;
								returnlocaltosyetemaccount['local_acct_key'] = (rowData != undefined) ? rowData.LOCAL_ACCT_KEY
										: gridData[0].data.LOCAL_ACCT_KEY,
								returnlocaltosyetemaccount['scenario'] = GlobalService.globalParams.scenario;

						vm.chartacct_data.local_system_accounts = vm.chartacct_data.localaccts
								.split('\n')
								.map(
										function(item, index) {
											return {
												type : returnlocaltosyetemaccount.type,
												object_id : (rowData != undefined) ? returnlocaltosyetemaccount.object_id
														: vm.localacctdisplay[index].data.OBJECT_ID,
												system_acct_key_old : returnlocaltosyetemaccount.system_acct_key_old,
												system_acct_key_new : returnlocaltosyetemaccount.system_acct_key_new,
												local_acct_key : (rowData != undefined) ? rowData.LOCAL_ACCT_KEY
														: vm.localacctdisplay[index].data.LOCAL_ACCT_KEY,
												map_type : returnlocaltosyetemaccount.map_type,
												group_object_key : returnlocaltosyetemaccount.group_object_key,
												tax_year : returnlocaltosyetemaccount.tax_year,
												local_chart_key : returnlocaltosyetemaccount.local_chart_key,
												scenario : returnlocaltosyetemaccount.scenario,
												local_account : item,
												system_account : vm.CHECK_SYS_ACCT
											};
											return {
												local_account_keys : gridData[index].data.LOCAL_ACCT_KEYS,
												local_account : item,
												system_account : vm.CHECK_SYS_ACCT
											};
										})

						taxsensitiveDeletedata.push(returnObjD);
						var message = "Data has been deleted successfully";
						sendDeleteDetails(
								vm.chartacct_data.local_system_accounts,
								taxsensitiveDeletedata, message)
					});
				}

				function sendDeleteDetails(returnlocaltosyetemaccount,
						taxsensitiveDeletedata, message) {
					LocalGroupObjFactory
							.DeleteTax(returnlocaltosyetemaccount,
									taxsensitiveDeletedata)
							.then(
									function(result) {

										if (result.errorMessage
												&& result.errorMessage !== 'null') {
											AlertService.add("error",
													result.errorMessage, 4000);
										} else {
											$uibModalInstance.dismiss('cancel');
											AlertService.add("success",
													message, 4000);

											$timeout(function() {
												// vm.loadSystemTaxsensitivitydata
												// = vm.deletedata;

												var args = {
													local_acct_key : returnObj.local_acct_key,
													sys_acct_key : rowData.SYS_ACCT_KEY,
													chart_filter : rowData.LOCAL_CHART_KEY,
													gridFilter : {
														SYS_ACCT_KEY1 : returnlocaltosyetemaccount['system_acct_key_new'],
													}
												};
												$rootScope.$emit('gridUpdate',
														args);
											});
										}
									});
				}

				// fetchTBBS ACCT DATA
				// schdm_type:String:null,schdm_method:String:null,

				vm.schdmTypechange = function(t) {

					fetchTBBSAcctData(t);

				}

				vm.methodOfchange = function(m) {

					fetchTBBSAcctData(m);
				}

				vm.postingTacAcctChange = function(n) {

					fetchPostingAcctData(n);
				}

				function fetchPostingAcctData(_data) {

					var postingtac_key = "";
					vm.loading = true;
					var posting_acct_key = "";

					for ( var ps in _data.postingtacdropdown_all) {
						if (_data.POSTING_TAC_DEFAULT === _data.postingtacdropdown_all[ps].POSTING_TAC) {
							postingtac_key = _data.postingtacdropdown_all[ps].POSTING_TAC_SAVE;
						}
					}

					for ( var paa in _data.postingacctdropdown_all) {
						if (_data.POSTING_ACCT_DEFAULT === _data.postingacctdropdown_all[paa].POSTING_ACCT) {
							posting_acct_key = _data.postingacctdropdown_all[paa].POSTING_ACCT_SAVE;
						}
					}
					var params = {
						"action_code" : 'sdcmbv',
						"tax_year" : GlobalService.globalParams.tax_year,
						"scenario" : GlobalService.globalParams.scenario,
						"jcd_key" : GlobalService.globalParams.jcd_key,
						"sys_acct" : postingtac_key,
						"pa_acct" : posting_acct_key,
						"chart_key" : (rowData != undefined) ? rowData.LOCAL_CHART_KEY
								: gridData[0].data.LOCAL_CHART_KEY

					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.tempPostingAcct = [];
											vm.posting_acct_list = [];
											vm.tempPostingAcct = data.jsonObject;
											for (var d = 0; d < vm.tempPostingAcct.length; d++) {
												vm.posting_acct_list
														.push(vm.tempPostingAcct[d].POSTING_ACCT);
											}
											vm.posting_acct_list = _.uniq(
													vm.posting_acct_list,
													JSON.stringify)

											for ( var ht in vm.loadSystemTaxsensitivitydata) {
												if (_data.$$hashKey == vm.loadSystemTaxsensitivitydata[ht].$$hashKey) {
													vm.loadSystemTaxsensitivitydata[ht].postingacctdropdown_all = vm.tempPostingAcct;
													vm.loadSystemTaxsensitivitydata[ht].postingacctdropdown = vm.posting_acct_list;
													vm.loadSystemTaxsensitivitydata[ht].POSTING_ACCT_DEFAULT = vm.posting_acct_list[0];
													// = "";
												}
											}
											vm.loading = false;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.loading = false;

											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.loading = false;

											}
										}

									});
				}

				function fetchTBBSAcctData(_item) {
					vm.loading = true;
					var tempDropData = {};
					vm.checksystemaccountttbs = [];
					var systemaccountschangetbbs = "";
					schdm_temp_tbbs = "";
					var hash_key = "";
					hash_key = _item.$$hashKey;
					var index = _.findIndex(vm.sys_acc_dropdown_data_TEMP, {
						'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
					});
					tempDropData = vm.sys_acc_dropdown_data_TEMP[index];
					for ( var g in vm.loadSystemTaxsensitivitydata) {
						if (_item.SCHDM_TEMPLATE_DEFAULT == vm.loadSystemTaxsensitivitydata[g].SCHDM_TEMPLATE) {
							schdm_temp_tbbs = vm.loadSystemTaxsensitivitydata[g].SCHDM_TEMPLATE_ID;
						}
					}
					var schdm_type_key = "";
					var schdm_method_key = "";

					for ( var gg in _item.schdmtypedropdown_all) {
						if (_item.SCHDM_TYPE_DEFAULT === _item.schdmtypedropdown_all[gg].SCHDM_TYPE) {
							schdm_type_key = _item.schdmtypedropdown_all[gg].SCHDM_TYPE_SAVE;
						}
					}

					for ( var gh in _item.methodofcaldropdown_all) {
						if (_item.METHOD_OF_CALC_DEFAULT === _item.methodofcaldropdown_all[gh].METHOD_OF_CALC_DESC) {
							schdm_method_key = _item.methodofcaldropdown_all[gh].METHOD_OF_CALC;
						}
					}

					if (schdm_type_key === "") {
						schdm_type_key = _item.SCHDM_TYPE_DEFAULT_SAVE
					}
					if (schdm_method_key === "") {
						schdm_method_key = _item.METHOD_OF_CALC_DEFAULT_SAVE
					}
					if (rowData != null) {
						var params = {
							"action_code" : 'wfk6zt',
							"tax_year" : GlobalService.globalParams.tax_year,
							"sys_acct" : rowData.LOCAL_CHART_KEY + "_"
									+ rowData.LOCAL_ACCT_KEY + "-"
									+ tempDropData.SYS_ACCT_KEY,
							"local_accts" : rowData.SYS_ACCT_KEY,
							// "schdm_temp" : _item.SCHDM_TEMPLATE_DEFAULT,
							"schdm_temp" : schdm_temp_tbbs,
							"schdm_type" : schdm_type_key,
							"schdm_method" : schdm_method_key,
							'source_system' : rowData.SOURCE_SYSTEM

						}
					} else {

						for ( var i in gridData) {
							vm.checksystemaccountttbs
									.push(gridData[i].data.LOCAL_CHART_KEY
											+ "_"
											+ gridData[i].data.LOCAL_ACCT_KEY
											+ "-" + tempDropData.SYS_ACCT_KEY);

						}
						vm.checksystemaccountttbs = _.uniq(
								vm.checksystemaccountttbs, JSON.stringify)
						systemaccountschangetbbs = vm.checksystemaccountttbs
								.join();

						var params = {
							"action_code" : 'wfk6zt',
							"tax_year" : GlobalService.globalParams.tax_year,
							"sys_acct" : systemaccountschangetbbs,
							"local_accts" : paramssystemaccount,
							// "schdm_temp" : _item.SCHDM_TEMPLATE_DEFAULT,
							"schdm_temp" : schdm_temp_tbbs,
							"schdm_type" : schdm_type_key,
							"schdm_method" : schdm_method_key,
							'source_system' : gridData[0].data.SOURCE_SYSTEM
						}

					}
					console.log("Input Data fetchSystemAcctData**********::",
							params);
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.tempTBBSAcct = [];
											vm.taxstreamaccount = [];
											vm.tempTBBSAcct = data.jsonObject;
											for (var d = 0; d < vm.tempTBBSAcct.length; d++) {
												vm.taxstreamaccount
														.push(vm.tempTBBSAcct[d].TAX_STREAM_ACCT);
											}
											vm.taxstreamaccount = _.uniq(
													vm.taxstreamaccount,
													JSON.stringify)

											for ( var ht in vm.loadSystemTaxsensitivitydata) {
												if (hash_key == vm.loadSystemTaxsensitivitydata[ht].$$hashKey) {
													vm.loadSystemTaxsensitivitydata[ht].tbbsacct_all = vm.tempTBBSAcct;
													vm.loadSystemTaxsensitivitydata[ht].tbbsacctdropdown = vm.taxstreamaccount;
													vm.loadSystemTaxsensitivitydata[ht].TAX_STREAM_ACCT_DEFAULT = vm.taxstreamaccount[0];
													// = "";
												}
											}
											vm.loading = false;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});
				}
				;

				function fetchtaxsensitivedata(checkDropDownChange) {
					var params = {}, tempDropData = {};
					vm.pl_acct_flag = true;
					vm.checksystemaccountcheckDropdownchange = [];
					var paramssystemaccountcheckDropdownchange = "";
					if (checkDropDownChange) {
						var index = _.findIndex(vm.sys_acc_dropdown_data_TEMP,
								{
									'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
								});
						tempDropData = vm.sys_acc_dropdown_data_TEMP[index];
						if (tempDropData.CODE_NAME === '5'
								|| tempDropData.CODE_NAME === '6'
								|| tempDropData.CODE_NAME === '7') {
							vm.pl_acct_flag = false;
						}
						var tempDropDataSysAccount = tempDropData.SYS_ACCT_KEY;
						
						if (rowData == null) {
							for ( var i in gridData) {
								vm.checksystemaccountcheckDropdownchange
										.push(gridData[i].data.LOCAL_CHART_KEY
												+ "_"
												+ gridData[i].data.LOCAL_ACCT_KEY
												+ "-" + tempDropDataSysAccount);

							}
							vm.checksystemaccountcheckDropdownchange = _.uniq(
									vm.checksystemaccountcheckDropdownchange,
									JSON.stringify)
							paramssystemaccountcheckDropdownchange = vm.checksystemaccountcheckDropdownchange
									.join();

						}

						params = {
							"action_code" : '5rvabb',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"sys_acct" : (rowData != null) ? rowData.LOCAL_CHART_KEY
									+ "_"
									+ rowData.LOCAL_ACCT_KEY
									+ "-"
									+ tempDropData.SYS_ACCT_KEY
									: paramssystemaccountcheckDropdownchange,
						// "local_accts": tempDropData.SYS_ACCT_KEY,

						}
					} else if (rowData != null) {
						params = {
							"action_code" : '5rvabb',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"sys_acct" : rowData.LOCAL_CHART_ACCT_KEY
						// "local_accts": rowData.SYS_ACCT_KEY,
						}
					} else {
						params = {
							"action_code" : '5rvabb',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key,
							"sys_acct" : paramlocalaccounts
						// "local_accts": paramssystemaccount,
						}
					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {
										if (data.callSuccess === "1") {
											
											
											vm.loadSystemTaxsensitivitydata = data.jsonObject;
											for ( var a in vm.loadSystemTaxsensitivitydata) {
												vm.loadSystemTaxsensitivitydata[a].newtemplatevalue = "";
												vm.loadSystemTaxsensitivitydata[a].newuserdescvalue = "";
												vm.loadSystemTaxsensitivitydata[a].newscdhmtypevalue = "";
												vm.loadSystemTaxsensitivitydata[a].newpostingtacvalue = "";
												vm.loadSystemTaxsensitivitydata[a].newpostingacctvalue = "";
												vm.loadSystemTaxsensitivitydata[a].newtbbsacctvalue = "";
												vm.loadSystemTaxsensitivitydata[a].newmethodofcalcvalue = "";
												vm.loadSystemTaxsensitivitydata[a].newmultipliervalue = "";
											}

											for (var v = 0; v < vm.loadSystemTaxsensitivitydata.length; v++) {
												// if
												// (vm.loadSystemTaxsensitivitydata[v].SCHDM_TEMPLATE_DEFAULT
												// != ' - ') {

												if (!('SCHDM_TEMPLATE_DEFAULT' in vm.loadSystemTaxsensitivitydata[v])) {
													vm.loadSystemTaxsensitivitydata[v].SCHDM_TEMPLATE_DEFAULT = "  Please Select Template  ";
												}

											}
											if (vm.loadSystemTaxsensitivitydata.length > 1) {
												vm.taxsensitiveinit = true;
											}

											if (checkDropDownChange) {

												for (var ts = 0; ts < vm.loadSystemTaxsensitivitydata.length; ts++) {
													if (vm.loadSystemTaxsensitivitydata[ts].SCHDM_TEMPLATE_DEFAULT == '  Please Select Template  '
															&& vm.CHECK_SYS_ACCT
																	.charAt(0) === 'Y') {
														// vm.loadSystemTaxsensitivitydata[ts].SCHDM_TEMPLATE_DEFAULT
														// = " Please Select ";
														AlertService
																.add(
																		"warning",
																		"Selected system account is tax sensitive",
																		40000);

														return;
													} else if (vm.loadSystemTaxsensitivitydata[ts].SCHDM_TEMPLATE_DEFAULT != '  Please Select Template  '
															&& vm.CHECK_SYS_ACCT
																	.charAt(0) === 'N') {
														AlertService
																.add(
																		"warning",
																		"Selected system account is not tax sensitive",
																		40000);
														return;
													}
												}
											}
											for ( var i in data.jsonObject) {
												data.jsonObject[i].isEditable = false;
												data.jsonObject[i].saveFlag = false;
												data.jsonObject[i].oldtemplatevalue = "";
												data.jsonObject[i].olduserdescvalue = "";

												data.jsonObject[i].oldscdhmtypevalue = "";
												data.jsonObject[i].oldpostingtacvalue = "";
												data.jsonObject[i].oldpostingacctvalue = "";
												data.jsonObject[i].oldtbbsacctvalue = "";
												data.jsonObject[i].oldmethodofcalcvalue = "";
												data.jsonObject[i].oldmultipliervalue = "";
												vm.tempsystemaccount
														.push(data.jsonObject[i].SCHDM_TEMPLATE);
												vm.triggeraccount
														.push(data.jsonObject[i].TRIGGER_ACCT);

											}

											vm.tempsystemaccount = _.uniq(
													vm.tempsystemaccount,
													JSON.stringify)
											vm.triggeraccount = _.uniq(
													vm.triggeraccount,
													JSON.stringify)

											var temptrigger = vm.triggeraccount;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});
				}
				;

				function fetchSystemAcctData() {

					if (rowData != null) {

						if (vm.sys_acc.SYS_ACCT == undefined) {
							return;
						} else {
							var params = {
								"action_code" : '5hsimn',
								"tax_year" : rowData.TAX_YEAR,
								"local_acct" : rowData.LOCAL_ACCT_KEY,
								"sys_acct" : (typeof vm.sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY
										: vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY

							}
						}
					} else {
						if (vm.sys_acc.SYS_ACCT == undefined) {
							return;
						}
						var params = {
							"action_code" : '5hsimn',
							"tax_year" : gridData[0].data.TAX_YEAR,
							"local_acct" : gridData[0].data.LOCAL_ACCT_KEY,
							"sys_acct" : (typeof vm.sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY
									: vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY

						}

					}

					vm.loadingIRSform = true;
					console.log("Input Data fetchSystemAcctData**********::",
							params);
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.loadSystemAndLocalAccountData = data.jsonObject;
											vm.loadingIRSform = false;
											console.log(data.jsonObject,
													"Check Here Y1");
											console
													.log(
															vm.loadSystemAndLocalAccountData,
															"Check Here Y1");
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});
				}
				;

				function getSystemAcctdropdown() {
					if (rowData != null) {
						var params = {
							"action_code" : '9bappg',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key

						/*
						 * "tax_year" : '2016', "screen_key" :
						 * rowData.SCREEN_KEY, "acct_type" : 1
						 */
						}
					} else {
						var params = {
							"action_code" : '9bappg',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key
						}
					}
					// alert('params: '+ params);
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {
											vm.sys_acc_dropdown_data = [];
											vm.pl_acct_flag = true;
											for ( var index_ in data.jsonObject) {
												vm.sys_acc_dropdown_data
														.push(data.jsonObject[index_].SYS_DROPDOWN);
											}

											console
													.log("sys_acc_dropdown_data "
															+ vm.sys_acc_dropdown_data);

											vm.sys_acc_dropdown_data_TEMP = data.jsonObject;

											if (rowData != null) {
												var index = _
														.findIndex(
																vm.sys_acc_dropdown_data_TEMP,
																{
																	'SYS_ACCT' : rowData.SYSTEM_ACCT
																});
											} else {
												var index = _
														.findIndex(
																vm.sys_acc_dropdown_data_TEMP,
																{
																	'SYS_ACCT' : vm.localacctdisplay[0].data.SYSTEM_ACCT
																});

											}
											vm.sys_acc.SYS_ACCT = vm.sys_acc_dropdown_data_TEMP[index];
											vm.sys_acc.SYS_ACCT_TEMP = vm.sys_acc_dropdown_data_TEMP[index];
											vm.CHECK_SYS_ACCT = (vm.sys_acc_dropdown_data_TEMP[index] !== undefined && vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN !== null) ? (vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN)
													: 'Please select System Account';

											if (vm.CHECK_SYS_ACCT != 'Please select System Account') {

												if (vm.sys_acc_dropdown_data_TEMP[index].CODE_NAME === '5'
														|| vm.sys_acc_dropdown_data_TEMP[index].CODE_NAME === '6'
														|| vm.sys_acc_dropdown_data_TEMP[index].CODE_NAME === '7')

												{
													vm.pl_acct_flag = false;
												}
											}
											fetchSystemAcctData(vm.sys_acc.SYS_ACCT);
											vm.loadingsyacct = false;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
											}
										}

									});
				}
				;

				function fetchSystemAcctLookup() {
					vm.irsformdata = true;

					if (rowData != null) {
						var params = {
							"action_code" : '5gdshx',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key
						}
					} else {
						var params = {
							"action_code" : '5gdshx',
							"tax_year" : GlobalService.globalParams.tax_year,
							"scenario" : GlobalService.globalParams.scenario,
							"jcd_key" : GlobalService.globalParams.jcd_key
						}
					}
					return JsonObjectFactory
							.getJSONObj(
									GENERAL_CONFIG.base_url + '/loadJsonObject',
									params)
							.then(
									function(data) {

										if (data.callSuccess === "1") {

											vm.loadSystemAcctLookupTemp = data.jsonObject;
											vm.loadLookupForm = [];
											vm.loadLookupSchedule = [];
											vm.loadLookupSection = [];
											vm.loadLookupPart = [];
											vm.loadLookupLine = [];
											vm.loadLookupColumn = [];
											vm.loadLookupSysMapAcct = [];
											vm.loadLookupSysMapAcct1 = [];
											vm.lookupcheckdata = data.jsonObject;

											for ( var index_ in data.jsonObject) {
												vm.loadLookupForm
														.push(data.jsonObject[index_].FORM);
											}
											vm.loadLookupForm = _.uniq(
													vm.loadLookupForm,
													JSON.stringify)
											vm.irsformdata = false;
										} else {
											if (data.errorMessage === "no access") {
												AlertService
														.add(
																"",
																"Sorry you do not have access to do the requested action.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.irsformdata = false;
											} else {
												AlertService
														.add(
																"",
																"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
																4000);
												vm.userMessage = "!! Unable to perform Selected Action...";
												vm.irsformdata = false;
											}
										}

									});

				}
				;

				$scope.$on('sysForm.SYS_ACCT_CHANGED', function(event, data) {
					vm.getChartAcctTableData();
					// vm.fetchtaxsensitivedata(true);
				});

				vm.getChartAcctTableData = function() {
					// vm.taxsensitivelookUpMapping = true;
					// fetchtaxsensitivedata(true);
					var index = _.findIndex(vm.sys_acc_dropdown_data_TEMP, {
						'SYS_DROPDOWN' : vm.CHECK_SYS_ACCT
					});
					if (vm.sys_acc.SYS_ACCT !== undefined) {
						vm.sys_acc.SYS_ACCT.SYS_ACCT = (index != -1) ? vm.sys_acc_dropdown_data_TEMP[index]
								: vm.sys_acc.SYS_ACCT_TEMP;
					} else {
						vm.sys_acc.SYS_ACCT = {};
						vm.sys_acc.SYS_ACCT.SYS_ACCT = (index != -1) ? vm.sys_acc_dropdown_data_TEMP[index]
								: vm.sys_acc.SYS_ACCT_TEMP;
					}
					vm.selectedSystemAccountKey = angular
							.copy(vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY);
					fetchSystemAcctData(vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY);
					vm.selectedSysAcc = vm.sys_acc.SYS_ACCT.SYS_ACCT;
					vm.chartacct_data.systemacct_des = (vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_DESC != undefined) ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_DESC
							: vm.sys_acc.SYS_ACCT.SYS_ACCT_DESC;
					// if (rowData && rowData.ACCT_TYPE.trim().length == 0) {
					vm.chartacct_data.acctType = vm.selectedSysAcc.ACCT_TYPE;
					// }
				}
				// loadSystemAcctLookupTempchecking

				vm.getLookupNext = function(key, nextLookupKey,
						currentLookupValue) {
					var lookupData = angular.copy(vm.lookupcheckdata);
					var a = "SYS_ACCT";

					switch (key) {
					case 1:
						vm.loadLookupSchedule = filterDataByKey("FORM",
								nextLookupKey, lookupData, currentLookupValue);
						break;
					case 2:
						vm.loadLookupSection = filterDataByKey222("SCHEDULE",
								nextLookupKey, lookupData222,
								currentLookupValue);

						break;
					case 3:
						vm.loadLookupPart = filterDataByKey333(
								"FORM_SECTION_NAME", nextLookupKey,
								lookupData333, currentLookupValue);

						break;
					case 4:
						vm.loadLookupLine = filterDataByKey1("FORM_PART_NO",
								nextLookupKey, lookupData1, currentLookupValue);
						break;
					case 5:
						vm.loadLookupColumn = filterDataByKey2("LINE",
								nextLookupKey, lookupData2, currentLookupValue);
						vm.loadLookupSysMapAcct = filterDataByKey22("LINE", a,
								lookupData2, currentLookupValue);
						break;

					case 6:
						vm.loadLookupSysMapAcct = filterDataByKey3("COL",
								nextLookupKey, lookupData3, currentLookupValue);
						break;
					case 7:
						vm.loadLookupSysMapAcct = filterDataByKey4("LINE",
								nextLookupKey, lookupData2, currentLookupValue);
						break;

					}

					console.log(vm.CHECK_SCHEDULE);
				}

				function filterDataByKey(currentLookupKey, nextLookupKey,
						lookupData, currentLookupValue) {
					var nextLookupData = [];

					var currentLookupFilter = lookupData.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					lookupData222 = currentLookupFilter;

					return nextLookupData;

				}
				function filterDataByKey222(currentLookupKey, nextLookupKey,
						lookupData222, currentLookupValue) {
					var nextLookupData = [];

					var currentLookupFilter = lookupData222.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					lookupData333 = currentLookupFilter;

					return nextLookupData;
				}
				function filterDataByKey333(currentLookupKey, nextLookupKey,
						lookupData333, currentLookupValue) {
					var nextLookupData = [];

					var currentLookupFilter = lookupData333.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					lookupData1 = currentLookupFilter;

					return nextLookupData;
				}
				function filterDataByKey1(currentLookupKey, nextLookupKey,
						lookupData1, currentLookupValue) {
					var nextLookupData = [];
					var currentLookupFilter = lookupData1.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					lookupData2 = currentLookupFilter;

					return nextLookupData;
				}

				function filterDataByKey2(currentLookupKey, nextLookupKey,
						lookupData2, currentLookupValue) {
					var nextLookupData = [];
					var nextLookupData1 = [];
					var currentLookupFilter = lookupData2.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);

					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					lookupData3 = currentLookupFilter;

					return nextLookupData;
				}

				function filterDataByKey22(currentLookupKey, a, lookupData2,
						currentLookupValue) {
					var nextLookupData = [];

					var currentLookupFilter = lookupData2.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[a]);

					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);

					return nextLookupData;
				}

				function filterDataByKey3(currentLookupKey, nextLookupKey,
						lookupData3, currentLookupValue) {
					var nextLookupData = [];
					var currentLookupFilter = lookupData3.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					lookupData4 = currentLookupFilter;
					return nextLookupData;
				}

				function filterDataByKey4(currentLookupKey, nextLookupKey,
						lookupData4, currentLookupValue) {
					var nextLookupData = [];
					var currentLookupFilter = lookupData4.filter(function(
							object, index) {
						console.log(object, index)
						if (object[currentLookupKey] == currentLookupValue) {
							return true;
						}
					});

					currentLookupFilter.forEach(function(object, index) {
						nextLookupData.push(object[nextLookupKey]);
					});
					nextLookupData = _.uniq(nextLookupData, JSON.stringify);
					return nextLookupData;
				}

				vm.applyChanges = function() {
					vm.CHECK_SYS_ACCT = vm.CHECK_SYS_MAP_ACCT;
					vm.getChartAcctTableData();
				}
				vm.formchanged = function(_item) {
					vm.CHECK_SCHEDULE = null;
					vm.CHECK_SECTION = null;
					vm.CHECK_PART = null;
					vm.CHECK_LINE = null;
					vm.CHECK_COL = null;
					vm.CHECK_SYS_MAP_ACCT = null;
					if (vm.loadLookupSchedule.length == 1) {
						vm.CHECK_SCHEDULE = vm.loadLookupSchedule[0];
						vm.getLookupNext(2, 'FORM_SECTION_NAME',
								vm.CHECK_SCHEDULE);
						vm.schedulechanged(vm.CHECK_SCHEDULE);
					}
					;

				};
				vm.schedulechanged = function(_item) {
					vm.CHECK_SECTION = null;
					vm.CHECK_PART = null;
					vm.CHECK_LINE = null;
					vm.CHECK_COL = null;
					vm.CHECK_SYS_MAP_ACCT = null;
					if (vm.loadLookupSection.length == 1) {
						vm.CHECK_SECTION = vm.loadLookupSection[0];
						vm.getLookupNext(3, 'FORM_PART_NO', vm.CHECK_SECTION);
						vm.sectionchanged(vm.CHECK_SECTION);
					}
					;
				};

				//
				vm.sectionchanged = function(_item) {
					vm.CHECK_PART = null;
					vm.CHECK_LINE = null;
					vm.CHECK_COL = null;
					vm.CHECK_SYS_MAP_ACCT = null;
					if (vm.loadLookupPart.length == 1) {
						vm.CHECK_PART = vm.loadLookupPart[0];
						vm.getLookupNext(4, 'LINE', vm.CHECK_PART);
						vm.partchanged(vm.CHECK_PART);
					}
					;
				}
				vm.partchanged = function(_item) {
					vm.CHECK_LINE = null;
					vm.CHECK_COL = null;
					vm.CHECK_SYS_MAP_ACCT = null;
					if (vm.loadLookupLine.length == 1) {
						vm.CHECK_LINE = vm.loadLookupLine[0];
						vm.getLookupNext(5, 'COL', vm.CHECK_LINE);
						vm.linechanged(vm.CHECK_LINE);
					}
					;
				}

				vm.linechanged = function(_item) {
					vm.CHECK_COL = null;
					vm.CHECK_SYS_MAP_ACCT = null;
					if (vm.loadLookupColumn.length == 1) {
						vm.CHECK_COL = vm.loadLookupColumn[0];
						vm.getLookupNext(6, 'SYS_ACCT', vm.CHECK_COL);
						vm.columnchanged(vm.CHECK_COL);
					}
					;
				};
				vm.columnchanged = function(_item) {
					vm.CHECK_SYS_MAP_ACCT = null;
					if (vm.loadLookupSysMapAcct.length == 1) {
						vm.CHECK_SYS_MAP_ACCT = vm.loadLookupSysMapAcct[0];
						// vm.getLookupNext(6,'SYS_ACCT',vm.CHECK_COL);
					}
					;
				};
			}
			return controllers;

		});
