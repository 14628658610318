define([
    'js.cookie',
    'angular'

], function () {
    'use strict';

    var module = angular.module('app.dataFiltersController', []);

    module.directive('fileModel', ['$parse', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            }
        };
    }])

    var controllers = module
        .controller('DataFiltersController', ['$rootScope', '$scope', '$state', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'DataFiltersFactory', 'GlobalService', '$timeout', 'workspaceFactory', 'USER_SETTINGS','GENERAL_CONFIG','$uibModal', dataFiltersController])
        .controller('ImportController', ['DataFiltersFactory','$rootScope', '$window', '$scope', '$state', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'GlobalService', '$timeout', 'workspaceFactory', '$uibModal', 'GridValidationFactory', 'GENERAL_CONFIG', 'USER_SETTINGS', importController])
        .controller('ImportPasteController', ['$rootScope', '$window', '$scope', '$state', '$http', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'GlobalService', '$timeout', 'workspaceFactory', '$uibModalInstance', importPasteController])
        .controller('importUploadController', ['$rootScope', '$window', '$scope', '$state', '$http', 'ModalFactory', 'AlertService', 'FileSizeService', 'SERVER_CONFIG', 'JsonObjectFactory', 'GlobalService', '$timeout', 'workspaceFactory', '$uibModalInstance', 'GENERAL_CONFIG', importUploadController])
        .controller('batchListModelCtrl', ['$rootScope', '$http', '$filter', '$uibModalInstance', 'GENERAL_CONFIG', 'workspaceFactory', 'AlertService','DataFiltersFactory', 'GlobalService', 'TAX_YEARS', batchListModelCtrl])
        .controller('screenDocsController', ['USER_SETTINGS','$uibModalInstance', 'GENERAL_CONFIG', 'workspaceFactory','$timeout', screenDocsController])

    function dataFiltersController($rootScope, $scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, DataFiltersFactory, GlobalService, $timeout, workspaceFactory, USER_SETTINGS, GENERAL_CONFIG,$uibModal) {
        var vm = this;
        vm.name = "Data Filters Controller";
        vm.videoPanel = false;
        vm.istoggleFilter = false;

        ////set up filters if they are not already set
        DataFiltersFactory.onLoad();

        const currentUser = USER_SETTINGS.user;
        vm.videoBaseURL = GENERAL_CONFIG.admin_api_url;
        vm.clientKey = currentUser.client_key;
        vm.screenKey = workspaceFactory.activeScreen.screen_key;
        vm.user = JSON.stringify({sso:currentUser.sso_id,role:currentUser.user_type});
        document.addEventListener('playvideo',()=>{
            vm.toggleVideoPanel();
        });

        vm.toggleVideoPanel = function() {
            console.log('playvideo');
            // vm.videoPanel = !vm.videoPanel;
        }
        
        vm.filterObjs = workspaceFactory.activeScreen.filters;
        vm.filterMatch = workspaceFactory.checkFilters(workspaceFactory.activeScreen);
        vm.screenType = workspaceFactory.activeScreen.screen_type;


        /* highlight the selected filter number and checked selected filters from the databuoy -- added on 03/28/2019*/

        var applyFilter = function (filters) {
            filters = _.each(filters, function (itemObj) {
                var iterateChildren = function (item) {
                    _.each(item, function (value) {
                        value.active = itemObj.selected.indexOf(value.key) > -1 ? true : value.VALUE ? itemObj.selected.indexOf(value.VALUE.toString()) > -1 :
                        itemObj.selected.indexOf(value.mekey.toString()) > -1 ? true : false;
                        value.selected = value.active;
                        value.check = value.active;

                        if (value.children) {
                            iterateChildren(value.children);
                        }
                    });
                }
                if (itemObj.selected && itemObj.selected.length && itemObj.selected.length > 0) {
                    iterateChildren(itemObj.values);
                }
            });
            return filters;
        }

        /* if the activescreen has COMMENTLINK then will call the appluFilters for highlight the selected Filters 4/9/2019  */
         if(!_.isEmpty(workspaceFactory.activeScreen.commentLink)){
          if (workspaceFactory.activeScreen.commentLink != undefined || workspaceFactory.activeScreen.commentLink != null &&
             workspaceFactory.activeScreen.commentLink.filters.length>0) {
            vm.filterObjs = workspaceFactory.activeScreen.filters;
            if(vm.filterObjs && vm.filterObjs.filters.length>0){
                applyFilter(vm.filterObjs.filters);
            }

        }
         }

        //IF FILTER ARE ALREADY LOADED


            $scope.$on('dataFilters:filtersLoaded', function (event, data) {
                vm.filterObjs = workspaceFactory.activeScreen.filters;
                if(!_.isEmpty(workspaceFactory.activeScreen.commentLink)){
                   if ((workspaceFactory.activeScreen.commentLink != undefined || workspaceFactory.activeScreen.commentLink != null)
                    &&  workspaceFactory.activeScreen.commentLink.filters && workspaceFactory.activeScreen.commentLink.filters.length>0) {
                    if(vm.filterObjs && vm.filterObjs.filters.length>0){
                        applyFilter(vm.filterObjs.filters);
                    }
                }
                }
                /* 2/21/2021 for toggle filter  */
                vm.istoggleFilter = true;
                vm.toggleFilterGroup = [];
                var y = _.find(workspaceFactory.activeScreen.filters.filters,{param_name:'filter_by'});
                if(y && y.selected){
                    var xy = _.find(workspaceFactory.activeScreen.filters.filters,{filter_key: y.selected.VALUE})  ;
                    var selectedToggleFilter = _.filter(workspaceFactory.activeScreen.filters.toggleFilterGroup,{
                        filter_key:y.selected.VALUE});
                    if( workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.toggleSelectedFilter ){
                        workspaceFactory.activeScreen.filters.toggleSelectedFilter = selectedToggleFilter;
                    }
                     
                    vm.toggleFilterGroup.push(xy);  
                    setTimeout(function(){
                        $rootScope.$apply();
                    },10);
                               
                }
            });
            

            $scope.$on('filter:updated', function (event, data) {
                // you could inspect the data to see if what you care about changed, or just update your own scope
                vm.istoggleFilter = true;
                vm.toggleFilterGroup = [];
                /* 2/21/2021 for toggle filter  */
                var y = _.find(workspaceFactory.activeScreen.filters.filters,{param_name:'filter_by'});
                if(y && y.selected){
                    var xy = _.find(workspaceFactory.activeScreen.filters.filters,{filter_key: y.selected.VALUE})  ;
                    var selectedToggleFilter = _.filter(workspaceFactory.activeScreen.filters.toggleFilterGroup,{
                        filter_key:y.selected.VALUE});
                    if( workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.toggleSelectedFilter ){
                        workspaceFactory.activeScreen.filters.toggleSelectedFilter = selectedToggleFilter;
                    }
                     
                    vm.toggleFilterGroup.push(xy);  
                    setTimeout(function(){
                        $rootScope.$apply();
                    },10);          
                }
                vm.filterMatch =  workspaceFactory.checkFilters(workspaceFactory.activeScreen);
            });


        vm.refresh = function () {
              if(workspaceFactory.activeScreen && !_.isEmpty(workspaceFactory.activeScreen.commentLink)){
                    workspaceFactory.activeScreen.commentLink= {}
                }

            if (workspaceFactory.activeScreen.filters.checkRequiredFilters()) {
                // custom screen key requirement on schedule J screen to show warning message as data load takes time.
                if(workspaceFactory.activeScreen.screen_key == '11086') {
                    let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                    let params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=vdmjzx', params).then((data) => {
                        if(data.jsonObject[0][':B1'] == '1') {
                            AlertService.add("error", "Filer Submission is Locked, please unlock the Submission to refresh the Consolidation Calculations.")
                            return;
                        } else {
                            AlertService.add("warning", "Consolidation calculations have been triggered, please wait while the data is loading!", 4000);
                        }
                    });
                }
                vm.filterMatch = true;
                $rootScope.$broadcast('dataFilters:refreshGrid', {
                    "refresh": true
                });

            }
        };

        vm.launchCustomModalForSpecificScreen = function(screenId){
            console.log('launchCustomModalForSpecificScreen');
            
            //10898 : default screen
            var template_url = 'app/components/stateEntityGroups/stateGroupDetails/state-group-create-details-tpl.html';
            var controller = 'StateAddGroupController as ctrl';
            let size = 'lg';

            if(screenId == 10895){
                template_url = 'app/components/stateEntityGroups/externalStateEntity/external-state-create-tpl.html';
                controller = 'externalStateEntityCreateController as ctrl';
            }else if(screenId == 11149){
                template_url = 'app/components/entityClassification/batchActions/entity-classificaiton-create.html';
                controller = 'entityClassificationCreateActionController as ctrl';
            }else if(screenId == 11163){
                template_url = 'app/components/entityClassification/scenarioSetup/scenario-setup.html';
                controller = 'scenarioSetupCreateActionController as ctrl';
            }else if(screenId == 11165){
                template_url = 'app/components/entityClassification/referenceTable/reference-table-create.html';
                controller = 'referenceTableCreateActionController as ctrl';
            }else if(screenId == 11166){
                template_url = 'app/components/entityClassification/ecConfiguration/ec-configuration-create.html';
                controller = 'ecConfigurationCreateActionController as ctrl';
            }else if(screenId == 11167){
                template_url = 'app/components/entityClassification/entityFilling/entity-filling.html';
                controller = 'entityFillingCreateActionController as ctrl';
            }else if(screenId == 11446){
                template_url = 'app/components/entityClassification/ecRefreshSetup/ec-refresh-setup-create.html';
                controller = 'ecRefreshSetupCreateActionController as ctrl';
            }else if(screenId == 11451){
                template_url = 'app/components/entityClassification/ecQuartermapSetup/ec-quartermap-create.html';
                controller = 'ecQuarterMapCreateActionsController as ctrl';
            }else if(screenId == 11442){
                template_url = 'app/components/sltApiDashboard/sltAddDataService/slt-add-data-service.html';
                controller = 'sltAddDataserviceController as ctrl';
            }else if(screenId == 11462){
                template_url = 'app/components/efileAdmin/templates/efile-software-data-create-edit.html';
                controller = 'EfileDataAdminController as ctrl';
            }else if(screenId == 11463){
                template_url = 'app/components/efileAdmin/templates/efile-add-edit-read-only.html';
                controller = 'EfileAddEditObjController as ctrl';
            }else if(screenId == 11464){
                template_url = 'app/components/efileAdmin/templates/efile-additional-elements.html';
                controller = 'EfileAddtionalElementsController as ctrl';
            }else if(screenId == 11465){
                template_url = 'app/components/efileAdmin/templates/efile-properties.html';
                controller = 'EfilePropertiesController as ctrl';
            }else if(screenId == 11466){
                template_url = 'app/components/efileAdmin/templates/efile-schema-set.html';
                controller = 'EfileSchemaSetController as ctrl';
            }else if(screenId == 11467){
                template_url = 'app/components/efileAdmin/templates/efile-data-definition.html';
                controller = 'EfileDataDefinitionController as ctrl';
            }else if(screenId == 11468){
                template_url = 'app/components/entityClassification/ecThresholdSetup/ec-threshold-setup-create.html';
                controller = 'ecThresholdSetupCreateActionController as ctrl';
			} else if(screenId == 11476){
                template_url = 'app/components/efileAdmin/templates/efile-tmef-config.html';
                controller = 'EfileConfigController as ctrl';
			} else if(screenId == 11477){
                template_url = 'app/components/efileAdmin/templates/efile-tmef-entity-map.html';
                controller = 'EfileEntityMapController as ctrl';
			} else if(screenId == 11555){
                template_url = 'app/components/entityClassification/851configData/851configData.html';
                controller = 'configDataController as ctrl';
			} else if(screenId == 11554){
                template_url = 'app/components/form851/851exclusionList/851exclusionList.html';
                controller = 'exclusionListController as ctrl';
			} else if(screenId == 11616){
                template_url = 'app/components/default-sourcing-override/sourcing-override.html';
                controller = 'sourcingOverrideController as ctrl';
			} else if(screenId == 11618){
                template_url = 'app/components/efileAdmin/templates/xml-vs-elements.html';
                controller = 'XmlVsElementsController as ctrl';
                size = 'sm';
			} 

            
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: template_url,
                controller: controller,
                size: size,
                resolve: {
                    rowData: function () {
                        return '';
                    },
                    gridData: function() {
                        return '';
                    }
                }
            });
        }

        vm.openDocument = function(){
             var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/dataFilters/screen-docs/screen-document-tpl.html',
                controller: 'screenDocsController as ctrl',
                size: 'lg',
                resolve: {}
            });
        }
    }

    function deleteImportHeaders(data) {
        var error_msg_index = _.findIndex(data.header, {
            'map': 'error_msg'
        });
        if (error_msg_index > -1) {
            data.header.splice(error_msg_index, 1)
        }
        var validated_index = _.findIndex(data.header, {
            'map': 'validated'
        });
        if (validated_index > -1) {
            data.header.splice(validated_index, 1)
        }
    }

    function importPasteController($rootScope, $window, $scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, GlobalService, $timeout, workspaceFactory, $uibModalInstance) {
        var vm = this;
        vm.name = "Import Paste Controller";
        vm.data = "";
        vm.pasteData = "";


        vm.importObj = workspaceFactory.activeScreen.importScreen;

        $timeout(function () {
            angular.element('.gg-hidden-paste-area').trigger('focus');
        }, 100)

        $(document).on("click", ".modal-dialog", function () {
            angular.element('.gg-hidden-paste-area').trigger('focus');
        });


        vm.pasteXls = function () {
            $timeout(function () {
                $rootScope.$broadcast('modalDataPaste', vm.pasteData);
                vm.pasteData = "";
                vm.cancel();
            }, 1)
        }


        vm.cancel = function () {
            closeModal();
        }

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        }

    }

    function importUploadController($rootScope, $window, $scope, $state, $http, ModalFactory, AlertService, FileSizeService, SERVER_CONFIG, JsonObjectFactory, GlobalService, $timeout, workspaceFactory, $uibModalInstance, GENERAL_CONFIG) {

        var vm = this;
        vm.base_url = GENERAL_CONFIG.base_url;
        vm.title = "Existing Batch Transactions";
        vm.onlyTxtFiles = false;
        if (workspaceFactory.activeScreen.screen_key == 366 || workspaceFactory.activeScreen.screen_key == 237) {
            vm.onlyTxtFiles = true;
        }
        vm.cancel = function () {
            closeModal();
        }

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        }


        /****************************************** start of callback functions used by flowjs ******************************************/

        $scope.failCount = 0;

        $scope.fileAdded = function (file, event, flow) {

            var fileExtension = file.getExtension().toLowerCase();
            var allowedFileExtensions = ',xls,xlt,xlm,xlsx,xlsm,xltx,xltm,txt,';
            var isAllowedFileExtension = (allowedFileExtensions.indexOf(',' + fileExtension + ',') >= 0);
            var isFileSizeWithinLimit = (file.size <= SERVER_CONFIG.configSettings.fileUploadMaxSize);
            var isFileAdded = (isAllowedFileExtension && isFileSizeWithinLimit);

            file.error = false;
            file.message = '';

            if (!isFileAdded) {

                if (!isAllowedFileExtension) {

                    file.error = true;
                    file.message = file.name + ' is invalid.';
                    flow.cancel();
                }

                if (!isFileSizeWithinLimit) {

                    file.error = true;
                    file.message = file.name + ' exceeds the allowed maximum size of ' + FileSizeService.readableFileSize(SERVER_CONFIG.configSettings.fileUploadMaxSize);
                    flow.cancel();
                }
            }

            return true;
        }

        $scope.setFiles = function (files, flow) {
            if (files[0].error == false) {
                flow.upload();
            }
        };

        $scope.setErrorFile = function (file, message, flow) {
            file.error = true;
            file.message = file.name + ' is invalid or exceeds allowed maximum size of ' + FileSizeService.readableFileSize(SERVER_CONFIG.configSettings.fileUploadMaxSize);
            flow.cancel();
        };

        $scope.fileSuccess = function (file, message, flow) {
            var parsedMessage = JSON.parse(message);

            if (parsedMessage.uploadStatus == 'success') {
                var fileName = parsedMessage.flowFilename;
                var fileParam = {
                    name: fileName
                };
                $http.get(GENERAL_CONFIG.base_url + '/getGridDataFromTemplate', {
                    params: fileParam
                }).success(function (response) {
                    /* response.data undefined check 5/14/2019 */
                    if (response.data === undefined) {
                        AlertService.add("warning", 'Empty File Can not be uploaded! Please make sure file has Data', 4000);
                        $rootScope.$broadcast('showFilterGroup', {
                            showFilter: false
                        });
                    }
                    /* ends here */
                    else if (response.data && response.data.length > 1) {
                        if (!!response.data[1].EIN) {
                            var tax_year = response.data[1].TAX_YEAR;
                            //var tax_year = response.data[0].COL_3;
                            if(response.raw_data){
                                GlobalService.setRawData(response.raw_data);
                            }
                            $rootScope.$broadcast('showFilterGroup', {
                                showFilter: true,
                                 einNo: response.data[1].EIN,
                                chart_type: response.data[1].CHART_TYPE,
                                feed_file: response.feed_file,
                                tax_year: tax_year,
                                trans_file: response.trans_file 
                            }); 

                            $http.get(GENERAL_CONFIG.base_url + '/getSchPGridDataFromTemplate', {
                                params:fileParam }
                            ).success(function (pResponse){

                                if (pResponse.data === undefined) {
                                    AlertService.add("warning", 'Empty File Can not be uploaded! Please make sure file has Data', 4000);
                                    $rootScope.$broadcast('showFilterGroup', {
                                        showFilter: false
                                    });
                                }
                                else if(pResponse.raw_data && pResponse.raw_data.length > 1) {

                                    if(pResponse.raw_data) {
                                        GlobalService.setpRawData(pResponse.raw_data);
                                    }
                                }

                            }).error(function(pResponse){
                                file.error = true;
                                file.message = file.name;
                                flow.cancel();
                                AlertService.add("error", response.errorMessage, 4000);
                            });

                        } else {
                            /** Added else condition to make Filters work in case if the data length is >1 row and no EIN found case **/
                            $rootScope.$broadcast('showFilterGroup', {
                                showFilter: true
                            });
                        }
                    } else {
                        $rootScope.$broadcast('showFilterGroup', {
                            showFilter: true
                        });
                    }
                    vm.cancel();
                    $rootScope.$broadcast('templateLoaded', response);
                }).error(function (response) {
                    file.error = true;
                    file.message = file.name;
                    flow.cancel();
                    AlertService.add("error", response.errorMessage, 4000);
                });
            } else if (parsedMessage.uploadStatus == 'error') {
                file.error = true;
                file.message = parsedMessage.message;
            }

        };

        $scope.dragEnter = function () {
            $scope.style = {
                border: '2px solid #37d631'
            };
        };

        $scope.dragLeave = function () {
            $scope.style = {};
        };

        /****************************************** end of callback functions used by flowjs ******************************************/

    }

    /**
     * Functionality for Existing Batch Transaction Modal.
     */
    function batchListModelCtrl($rootScope, $http, $filter, $uibModalInstance, GENERAL_CONFIG, workspaceFactory, AlertService, DataFiltersFactory, GlobalService, TAX_YEARS) {
        var vm = this;

        vm.title = 'Existing Batch Transactions';
        vm.batchList = [];
        vm.filter = {};
        vm.showBatchLoader = false;
        vm.gridFilters_Data = false;
        vm.dropdownBtn = true;
        vm.dropdownSelectionMsg = true;
        vm.showClearBtn = false;
        vm.selectBox = false;
        vm.noDataFoundMsg = false;
        vm.noSearchDataFound = false;
        vm.filteredBatchList = [];
        var filteredBatchListCopy = [];
        var stage_keys = [];
        var onLoadData = false;
        var findObj = {};
        vm.taxYears = _.clone(GlobalService.inputs["tax_year"].values);
        findObj["value"] = GlobalService.globalParams["tax_year"];
        vm.selectedYear = angular.copy(_.find(vm.taxYears, findObj));
        vm.types = [{
            name: "Processed",
            id: 'Y'
        }, {
            name: "Unprocessed",
            id: 'N'
        }, {
            name: "Removed",
            id: 'D'
        }];

        vm.typeSelection = function () {
            vm.dropdownBtn = false;
        };
        vm.loadProcess = function (type, year) {
            vm.selectBox = true;
            vm.dropdownBtn = true;
            vm.showClearBtn = true;
            vm.showBatchLoader = true;
            vm.dropdownSelectionMsg = false;
            vm.searchEntity = '';
            getBatchTransactions(type.id, year);
        };

        vm.loadTransactions = function (index, id, value, tag) {
            vm.filteredBatchList[index].show_transaction = true;
            var screenkey = workspaceFactory.activeScreen.screen_key;
            var batchData = {};
            $http.get(GENERAL_CONFIG.common_api_url + '/getBatchProcessesInput' + '?stage_key=' + id + '&screen_key=' + screenkey).then(function (res) {
                if(res.data.callSuccess == '1'){
                    vm.transactionClob = JSON.parse(res.data.jsonObject.clob_data);
                    angular.forEach(vm.transactionClob, function (row) {
                        row.object_id = row.object_id.toString();
                    });
                    batchData.data = vm.transactionClob;
                    if(tag == 'NA'){
                        batchData.tagNo = [];
                    }else{
                        batchData.tagNo = tag.split(',');
                    }
                    var gridData = workspaceFactory.activeScreen.data;
                    var batchProcessData = JSON.parse(res.data.jsonObject.clob_data);
                    deleteImportHeaders(gridData);
                    $rootScope.$broadcast('showFilterGroup', {
                        showFilter: true,
                        saveBtn: value,
                        einNo: (batchProcessData.length > 1 && batchProcessData[1].EIN != undefined) ? batchProcessData[1].EIN : null,
                        chart_type: (batchProcessData.length > 1 && batchProcessData[1].CHART_TYPE != undefined) ? batchProcessData[1].CHART_TYPE : null,
                        feed_file: "",
                        tax_year: (batchProcessData.length > 1 && batchProcessData[1].TAX_YEAR != undefined) ? batchProcessData[1].TAX_YEAR : null,
                        trans_file: "",
                        saveBtn: value
                    });
                    vm.filteredBatchList[index].show_transaction = false;
                    $rootScope.$broadcast('templateLoaded', batchData);
                    closeModal();
                }else{
                    vm.filteredBatchList[index].show_transaction = false;
                    AlertService.add("error", res.data.errorMessage, 4000);
                }
            });
        };

        vm.searchEntityList = function(search_txt){
            stage_keys = [];
            angular.forEach(vm.filteredBatchList, function (item, index) {
                stage_keys.push(item.TRANSACTION_ID);
            });
            vm.filteredBatchList = [];
            vm.noSearchDataFound = false;
            vm.showBatchLoader = true;
            vm.tempArray = [];
            DataFiltersFactory.searchService(stage_keys.toString(), search_txt).then(function (data){
                if(data.jsonObject.length > 0){
                    for(var i = 0; i < data.jsonObject.length; i++) {
                        for(var j = 0; j < filteredBatchListCopy.length; j++) {
                            if(filteredBatchListCopy[j].TRANSACTION_ID === data.jsonObject[i].TRANSACTION_ID) {
                                vm.tempArray.push(filteredBatchListCopy[j]);
                                break;
                            }
                        }
                    }
                    vm.filteredBatchList = vm.tempArray;
                    vm.showBatchLoader = false;
                }else{
                    vm.showBatchLoader = false;
                    vm.noSearchDataFound = true;
                }
            });
        };

        vm.clearEntityList = function(){
            vm.filter = {};
            vm.searchEntity = '';
            vm.noSearchDataFound = false;
            vm.filteredBatchList = filteredBatchListCopy;
        };

        vm.cancel = function () {
            closeModal();
        };

        vm.deleteTran = function (trans_id, index) {
            var index = index;
            vm.filteredBatchList[index].delete_transaction = true;
            var screenkey = workspaceFactory.activeScreen.screen_key;
            $http.get(GENERAL_CONFIG.common_api_url + '/deleteBatchProcess' + '?stage_key=' + trans_id+'&screen_key='+screenkey+'&tax_year='+vm.selectedYear.value).then(function (data) {
                if (data.data.callSuccess === "1") {
                    vm.filteredBatchList[index].delete_transaction = false;
                    AlertService.add("success", "Transaction Deleted Successfully.", 4000);
                    vm.filteredBatchList.splice(index, 1);
                } else {
                    vm.filteredBatchList[index].delete_transaction = false;
                    if (data.data.errorMessage === "no access") {
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    } else {
                        if(data.data.errorMessage != ""){
                            AlertService.add("error", data.data.errorMessage, 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                }
            });
        };

        vm.clearTransView = function () {
            vm.selectBox = false;
            vm.dropdownBtn = false;
            vm.showClearBtn = false;
            vm.selectedType = 'Select statusdad';
            vm.filteredBatchList = [];
            vm.dropdownSelectionMsg = true;
            vm.noDataFoundMsg = false;
            vm.gridFilters_Data = false;
            vm.noSearchDataFound = false;
            vm.filter = {};
        };

        // used to close the modal.
        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        // Used to filter the user records or transactions as per the key entered in the search field.
        vm.filterBatchList = function () {
            var filtered = vm.batchList.filter(function (item) {
                if (vm.filter.user) {
                    if (isNaN(parseInt(vm.filter.user))) {
                        var userName = item.USER_FIRST_NAME + ' ' + item.USER_LAST_NAME;
                        return userName.toLowerCase().includes(vm.filter.user.toLowerCase());
                    } else {
                        return item.USER_REF.includes(vm.filter.user);
                    }
                } else {
                    return true;
                }
            });

            // Used to filter the date range by selecting the options from Date Range dropdown.
            if (vm.filter.dateRange && vm.filter.dateRange.from && vm.filter.dateRange.to) {
                filtered = filtered.filter(function (item) {
                    var date = new Date(item.CREATED_ON);
                    return (date.getTime() >= vm.filter.dateRange.from.getTime() &&
                        date.getTime() <= vm.filter.dateRange.to.getTime());
                });
            }

            vm.filteredBatchList = $filter('filter')(filtered, vm.filter.obj);
            if(onLoadData){
                filteredBatchListCopy = angular.copy(vm.filteredBatchList);
                onLoadData = false;
            }
        };

        // Mapping the Mock Json Object.
        function getBatchTransactions(id, year) {
            var processName = encodeURIComponent(workspaceFactory.activeScreen.label);
            var screenkey = workspaceFactory.activeScreen.screen_key;
            $http.get(GENERAL_CONFIG.common_api_url + '/getBatchProcesses' + '?process_name=' + processName + '&processed=' + id + '&screen_key=' + screenkey + '&tax_year=' + year).then(function (res) {
                if (res.data.jsonObject.length > 0) {
                    vm.scenairoList = _.find(TAX_YEARS, {
                        value: year
                    }).scenarios;
                    angular.forEach(res.data.jsonObject, function (item, index) {
                        item.show_transaction = false;
                        if(item.IMPORT_TAG == null){
                            item.IMPORT_TAG = "NA";
                        };
                        item.SCENARIO_DESC = _.find(vm.scenairoList, {
                            scenario: item.SCENARIO
                        }).scenario_desc
                    });
                    console.log(GlobalService);
                    console.log(TAX_YEARS);
                    
                    console.log(vm.scenairoList);
                    vm.gridFilters_Data = true;
                    vm.batchList = res.data.jsonObject;
                    vm.showBatchLoader = false;
                    onLoadData = true;
                    vm.filterBatchList();
                } else {
                    vm.noDataFoundMsg = true;
                    vm.showBatchLoader = false;
                }
            });
        };
        //getBatchTransactions();
    }

    //TODO:

    function screenDocsController(USER_SETTINGS,$uibModalInstance, GENERAL_CONFIG, workspaceFactory,$timeout){
        console.log('I am screen docs');
        var vm = this;
        vm.videoBaseURL = GENERAL_CONFIG.admin_api_url;
        const currentUser = USER_SETTINGS.user;

        vm.clientKey = currentUser.client_key;
        vm.screenKey = workspaceFactory.activeScreen.screen_key;
        vm.screenName = workspaceFactory.activeScreen.label;
        vm.user = JSON.stringify({sso:currentUser.sso_id,role:currentUser.user_type,system_access_level:currentUser.client.user_type_key});
        if ($uibModalInstance) {
            $timeout(() => {
                document.querySelector('gtw-screen-documents-ce')
                    .addEventListener('document-modal-cancel', (event)=>{
                        $uibModalInstance.close();
                    });
            },500);
        }
    }
    function importController(DataFiltersFactory,$rootScope, $window, $scope, $state, $http, ModalFactory, AlertService, JsonObjectFactory, GlobalService, $timeout, workspaceFactory, $uibModal, GridValidationFactory, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        vm.name = "Import Controller";
        vm.step = 1;
        vm.pasteXls = null;
        vm.importObj = {};
        vm.showSaveBtn = false;
        if (GlobalService.globalParams.screen_key == 237 || 
            GlobalService.globalParams.screen_key == 11471 || 
            GlobalService.globalParams.screen_key == 11287 || 
            GlobalService.globalParams.screen_key == 11294 ||
            GlobalService.globalParams.screen_key == 11324 ||
            GlobalService.globalParams.screen_key == 11327) {
            vm.showSaveBtn = GlobalService.getInsuranceSaveBtnState();
        }
        var activeScreen = workspaceFactory.activeScreen;
        vm.showUploadBtn = activeScreen.screen_key == 11471 ? false : true; //RM-21709 Temporary fix to not show Upload button on Ins Sch P Feed Data Screen
         if((activeScreen && activeScreen.filters &&  activeScreen.filters.filters.length>0)&& activeScreen.cachedData){
            vm.showFilter = true;
        }else{
            vm.showFilter = GlobalService.getBslaFilterState() || false;
        }
        vm.feed_file = '';
        vm.trans_file = '';
        vm.crudLoading = false;
        vm.cancelcrudLoading = false;

        
        vm.batchTags = [];

        const currentUser = USER_SETTINGS.user;
        vm.videoBaseURL = GENERAL_CONFIG.admin_api_url;
        vm.clientKey = currentUser.client_key;
        vm.screenKey = workspaceFactory.activeScreen.screen_key;
        vm.user = JSON.stringify({sso:currentUser.sso_id,role:currentUser.user_type});
        document.addEventListener('playvideo',()=>{
            vm.toggleVideoPanel();
        });

        vm.toggleVideoPanel = function() {
            console.log('playvideo');
            // vm.videoPanel = !vm.videoPanel;
        }

        $scope.$on('showFilterGroup', function (event, args) {
            vm.showFilter = args.showFilter;
            vm.feed_file = args.feed_file;
            vm.trans_file = args.trans_file;
            if(args.saveBtn !== undefined && !args.saveBtn){
                delete vm.importObj.saving;
            }else if(args.saveBtn !== undefined && args.saveBtn){
                vm.importObj.saving = false;
            }else{
                vm.importObj.saving = false;
            }
            if (args.einNo) {
                GlobalService.setBslaFilterState(args.showFilter);
                GlobalService.setEIN_TAXYEAR(args.einNo.replace(/[^\w\s]/gi, ''), parseInt(args.tax_year), args.chart_type);
            }

        });

        //IF THIS IS THE FIRST LOAD THEN ADD IMPORTSCREEN OPTIONS
        if (typeof workspaceFactory.activeScreen.importScreen === "undefined") {
            workspaceFactory.activeScreen.importScreen = {
                step: 1,
                dirty: false,
                submitted: false,
                times_submitted: 0,
                job_number: null,
                saving: false,
                records: 0,
                cancelcrudLoading:false,
                crudLoading:false,
                errorLength:null
            };
        }

        vm.importObj = workspaceFactory.activeScreen.importScreen;
        vm.importObj.errorLength =( workspaceFactory.activeScreen.importScreen !=undefined  && 
                                    workspaceFactory.activeScreen.importScreen.errorLength !=null)?
                                    workspaceFactory.activeScreen.importScreen.errorLength:null;

        if (activeScreen.saving_data != undefined && activeScreen.saving_data) {
            vm.importObj.cancelcrudLoading = true;
            vm.importObj.crudLoading = true;
            $timeout(function () {
                $rootScope.$broadcast('showLoader', true);
            }, 1000)
        }

        vm.addBatchTag = function(tag){
            if(vm.batchTags.length < 5){
                vm.batchTags.push(tag);
                vm.tagNumber = "";
            }else{
                AlertService.add("error", "Cannot exceed more than 5 Tag's.", 4000);
            }
        };

        vm.removeBatchTag = function(i){
            vm.batchTags.splice(i, 1);
        };

        vm.pasteData = function ($event) {
            if (pasteExport.hasExtension) {
                var clipBoard = pasteExport.readClipboard();
            } else {
                vm.openModal();
            }
        };

        vm.openModal = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/dataFilters/grid-import-paste.html',
                controller: 'ImportPasteController as ctrl',
                size: 'sm',
                resolve: {}
            });

            modalInstance.result.then(function (selectedItem) {}, function () {});
        };


        vm.downloadTemp = function () {

            var screenkey = workspaceFactory.activeScreen.screen_key;
            var activityKey = workspaceFactory.activeScreen.activity_key;
            var downloadTemplUrl = '';

            //Temporary fix for dynamic scenario change.
            GlobalService.globalParams.scenario = GlobalService.globalParams.scenario_v1 ? GlobalService.globalParams.scenario_v1 : GlobalService.globalParams.scenario;                  
                  downloadTemplUrl = GENERAL_CONFIG.base_url + '/downloadTemplate?screenkey=' + screenkey+"&client_key="+ sessionStorage.getItem("client_key") 
                        +'&tax_year='+GlobalService.globalParams.tax_year +'&scenario='+GlobalService.globalParams.scenario
                            +'&activityKey='+activityKey;
                // window.open(downloadTemplUrl, 'blank');

            // })
      fetch(downloadTemplUrl, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('Authorization')
            }
        })
        .then(response => response.blob())
        .then(blob => {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = workspaceFactory.activeScreen.data.settings.grid_name;//fileName;//'filename.ext';  // provide the file name here
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // window.open(blobUrl, '_blank');
        })
        .catch(error => console.error(error));
     
        };


        vm.uploadTemp = function () {
            console.log("Upload template");
            vm.openFileChooserPopup();
        };


        vm.openBatchList = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/dataFilters/templates/batch-import-list-mdl.html',
                controller: 'batchListModelCtrl as ctrl',
                size: 'md',
                resolve: {

                }
            });

            modalInstance.result.then(
                function (selectedItem) {},
                function () {}
            );
        }

        vm.openFileChooserPopup = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/components/dataFilters/grid-import-upload.html',
                controller: 'importUploadController as ctrl',
                size: 'lg',
                resolve: {

                }
            });

            modalInstance.result.then(
                function (selectedItem) {
                    $rootScope.$broadcast('gtw.dataFilters');
                },
                function () {
                    $rootScope.$broadcast('gtw.dataFilters');
                }
            );
        };


        vm.cancel = function () {
            if (activeScreen && (activeScreen.id == '237' || activeScreen.id == '11471' || activeScreen.id == '11287'
            || activeScreen.id == '11294' || activeScreen.id == '11324' || activeScreen.id == '11327')) {
                vm.showSaveBtn = true;
            }
            vm.showFilter = false;
            vm.tagNumber = "";
            vm.batchTags = [];
            if (vm.importObj.cancelcrudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            vm.importObj.cancelcrudLoading = true;
            vm.importObj.errorLength = null;
            GlobalService.setBslaFilterState(false);
            GlobalService.setRawData([]);
            vm.importObj.step = 1;
            var gridData = activeScreen.data;//workspaceFactory.activeScreen.data;
            gridData.data = [];
            gridData.data.push({
                object_id: "1"
            });
            vm.importObj.records = 0;
            deleteImportHeaders(gridData);
            $rootScope.$broadcast('dataLoaded', gridData);
            vm.importObj.cancelcrudLoading = false;
            activeScreen.saving_data = false;
            //Deleting below filters object to reload the filter dropdowns again after canceling the data load and uploading the feed file again.
            delete workspaceFactory.activeScreen.filters;
        }


        function prepareJsonForSave(_data, _dirtyRows) {
            var jsonString = "";
            if (_dirtyRows) {
                var filteredData = _.filter(_data, {
                    'validated': 'false'
                });
                jsonString = JSON.stringify(filteredData);

            } else {
                jsonString = JSON.stringify(_data);

            }
            return jsonString;
        }

        $scope.$on('showSaveButton', function (event, args) {
            vm.showSaveBtn = args.showBtn;
            GlobalService.setInsuranceSaveBtnState(args.showBtn);
        });

        vm.save = function () {
            vm.importObj.submitted = true;
            activeScreen.saving_data = true;
            if (vm.importObj.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            vm.importObj.crudLoading = true;
            vm.importObj.cancelcrudLoading = true;
            var gridData = activeScreen.data;
            var url = $state.current.api.save;
            var saveAPI = url.split('/');
            var round_off_maps = [];

            _.forEach(workspaceFactory.activeScreen.data.header, function(header){
                if(header.round_off === '1'){
                    round_off_maps.push(header.map);
                }
            });


            if (typeof url === 'undefined') {
                AlertService.add("warning", "Save call is not set in the screen. Please contact support to correct the issue");
                return false;
            }


            console.log("============================ $state ===========================", $state)


            var requiredValidations = GridValidationFactory.requiredFields(gridData);
            var issueIdValidations = !requiredValidations.error ? GridValidationFactory.issueIdValid(gridData) : "";


            console.log(" requiredValidations " , requiredValidations)


            if (requiredValidations.error || issueIdValidations.error) {
                AlertService.add("error", requiredValidations.error ? requiredValidations.errorMsg : issueIdValidations.errorMsg, 3000);
                vm.importObj.saving = false;
                vm.importObj.crudLoading = false;
                vm.importObj.cancelcrudLoading = false;
                $rootScope.$broadcast('gridRefreshPipe', {});
                return false;
            }
            // this needs to come from db settings in grid should be an array of possible validations





            /****dynamic screen validation 9/21/2020 */
            if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data.settings){
                if(workspaceFactory.activeScreen.data.settings.screen_validation == 1 && workspaceFactory.activeScreen.data.settings.screen_validation_rules != undefined){
                    var validationRule = JSON.parse(workspaceFactory.activeScreen.data.settings.screen_validation_rules);
                    var customValidations = "";
                    var x =""
                    _.each(validationRule,function(item){
                         x = GridValidationFactory[item.rule_type](gridData.data,item.colMap,  item.colAmount,'error_msg',item.buffer_size,gridData.header);
                         if(customValidations != "" && customValidations.error){
                                customValidations.errorMsg = customValidations.errorMsg + x.errorMsg;
                                customValidations.error = customValidations.error ? customValidations.error : x.error;
                         }else{
                            customValidations = x;
                         }
                    });
                    console.log("customValidations",customValidations);
                    if( customValidations.error){
                        AlertService.add("warn", customValidations.errorMsg  , 3000);
                        $rootScope.$broadcast('gridRefreshPipe', {});
                        // vm.crudLoading = false;
                        // vm.cancelcrudLoading = false;
                        vm.importObj.saving = false;
                        vm.importObj.crudLoading = false;
                        vm.importObj.cancelcrudLoading = false;
                        activeScreen.saving_data  = false;
                        return false;
                    }
                }
            }

               /* issue Id security check  9/2/2022*/
               let scenarioCode = '';
               if (workspaceFactory.activeScreen.filters.getFilters().length>1){                
                    let foundScenario =  _.find(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'});
                    if(foundScenario && foundScenario.selected){
                        scenarioCode = foundScenario.selected;
                    }
               }
                                   
               if( scenarioCode && scenarioCode.ISSUE_ID_ENABLED == 'Y' && ( GlobalService.globalParams.issue_id == '')){
   
                   AlertService.add("error", "Please select the Global Issue id",4000);
                   $rootScope.$broadcast('gridRefreshPipe', {});
                   vm.importObj.saving = false;
                   vm.importObj.crudLoading = false;
                   vm.importObj.cancelcrudLoading = false;
                   activeScreen.saving_data  = false;
                   return false;
               }
            /* commented 754-778 on 9/22/2020 */
        //     //TODO: THIS NEED TO COME FROM A DB SETTINGS TABLE
        //     if( workspaceFactory.activeScreen.screen_key === "171"){
        //         var customValidations = GridValidationFactory.colNetZero(gridData.data,[{colName:'Cy Amount', colMap:'cy_amount', total:0},{colName:'Py Amount', colMap:'py_amount', total:0}],'error_msg');
        //         if( customValidations.error){
        //             AlertService.add("warn", customValidations.errorMsg  , 3000);
        //             $rootScope.$broadcast('gridRefreshPipe', {});
        //             vm.crudLoading = false;
        //             vm.cancelcrudLoading = false;
        //             return false;
        //         }
        // }

        //  //TODO: THIS NEED TO COME FROM A DB SETTINGS TABLE
        // /* 5/29/2020 185-import-reclass-domestic, 1078-import-reclass-foreign */
        //     if( workspaceFactory.activeScreen.screen_key === "185" ||  workspaceFactory.activeScreen.screen_key==="10078" ){
        //         var customValidations = GridValidationFactory.groupNetZero(gridData.data,'reclass_description','amount','error_msg');
        //         if( customValidations.error){
        //             AlertService.add("error", customValidations.errorMsg  , 3000);
        //             $rootScope.$broadcast('gridRefreshPipe', {});
        //             vm.crudLoading = false;
        //             vm.cancelcrudLoading = false;
        //             return false;
        //         }
        //     }





            $rootScope.$broadcast('showLoader', true);
            _.forEach(gridData.data, function(row){
                delete row.validated;
                delete row.col_class;
                row.Error = "";
                row.Validated = "";
                _.forEach(round_off_maps, function(key){
                    row[key] = Math.round(row[key]);
                });
            });

            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params.EIN = GlobalService.getEIN();
            params.entityType = GlobalService.getChartType();
            params.tag = vm.batchTags.length > 0 ? vm.batchTags.join() : "";
            params.record_count = gridData.data.length;
            //TODO: GET THIS FROM SCREEN
            params.process_name = workspaceFactory.activeScreen.label;
            params.clob_settings = JSON.stringify(_.cloneDeep(params));
            params.clob_data = (vm.importObj.times_submitted > 0) ? prepareJsonForSave(gridData.data, true) : prepareJsonForSave(gridData.data, false);
            var jsonString = (vm.importObj.times_submitted > 0) ? prepareJsonForSave(gridData.data, true) : prepareJsonForSave(gridData.data, false);

            params.grid_key = workspaceFactory.activeScreen.data.settings.grid_key;
            console.log("params ", params);

            var errorArray = [];
            if (url.indexOf('gridInsBatchImportValidation') > 0) {
                params.clob_data = JSON.stringify(gridData.data);
                //params.clob_data = '';
                params.clob_settings = JSON.parse(params.clob_settings);
                params.clob_settings.feed_file = vm.feed_file;
                params.clob_settings.trans_file = vm.trans_file;
                params.clob_settings = JSON.stringify(params.clob_settings);
            }
            console.log("modified Param", params);

         
            
            JsonObjectFactory.saveJSON(url, params).then(function (response) {

                if (response.callSuccess != "1") {
                    vm.importObj.submitted = false;
                    vm.importObj.saving = false;
                    vm.importObj.crudLoading = false;
                    vm.importObj.cancelcrudLoading = false;
                    activeScreen.saving_data  = false;
                    $rootScope.$broadcast('showLoader', false);
                    AlertService.add("error", response.errorMessage);
                } else {
                    vm.importObj.submitted = false;
                    vm.importObj.saving = false
                    errorArray = response.errorList;
                    activeScreen.saving_data = false;
                    var errors = false;
                    var errorObject = {};
                    _.forEach(errorArray, function (v, k) {
                        _.forEach(v.error_columns, function (v1, k1) {
                            errorObject[k1] = v1;
                        });
                    });
                    gridData.errorObject = errorObject;
                    _.forEach(gridData.data, function (value, key) {
                        var index = _.findIndex(errorArray, function (o) {
                            return o.object_id == value.object_id;
                        });

                        if (index > -1) {
                            value.error_msg = errorArray[index].error_message;
                            
                            vm.importObj.errorLength = errorArray.length;
                            activeScreen.importScreen.errorLength = errorArray.length;
                            value.row_class = "gg-error-row";
                            value.col_class = "gg-error-col"
                            value.validated = 'false';
                            errors = true;
                        } else {
                            delete value.error_msg;
                            value.row_class = null;
                            //  value.col_class = null;
                            value.validated = 'true';
                            // vm.errorLength = null;
                        }
                    });
                    gridData.tableState.sort = {
                        predicate: 'error_msg'
                    };

                    if (errors) {
                        AlertService.add("error", "Batch Import could not be saved, please check the errors below.", 2000);
                    } else {
                        AlertService.add("success", "Batch Import was successfully saved.", 2000);
                        vm.importObj.records = gridData.data.length;
                        vm.importObj.step = 3;

                        /*  after successfull COA Batch importing refreshing the all the screen filters to get the new chart filter  - added by divya 7/13/2019 */
                        if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.is_filter_refresh === "1"){
                            /* 2/12/2020 check the list of refresh screen list */
                              var screenRefresh = [];
                            if(workspaceFactory.activeScreen.screens_refresh_list && workspaceFactory.activeScreen.screens_refresh_list.indexOf(',') > -1) {
                                screenRefresh = workspaceFactory.activeScreen.screens_refresh_list.split(',');
                            }else{
                                screenRefresh.push(workspaceFactory.activeScreen.screens_refresh_list);
                            }
                           var screensArray = [];
                           for(var i=0;i<workspaceFactory.screens.length;i++){
                               for(var j=0;j<screenRefresh.length;j++){
                                    if(workspaceFactory.screens[i].screen_key === screenRefresh[j]){
                                        screensArray.push(workspaceFactory.screens[i]);

                                    }
                               }
                           }
                           console.log("scresnsArrayafdsfas",screensArray);
                           if(screensArray.length){
                               for(var i=0;i<screensArray.length;i++){
                                DataFiltersFactory.onChange(screensArray[i].filters,screensArray[i].filter_key,screensArray);
                               }

                           }

                        }
                        /* ends here */
                    }
                  //  $rootScope.$broadcast('hideLoader');
                    if(workspaceFactory.activeScreen.screen_key == gridData.screen_key){
                        $rootScope.$broadcast('hideLoader');
                        vm.importObj.saving = false
                        vm.importObj.crudLoading = false;
                        vm.importObj.cancelcrudLoading = false;
                        $rootScope.$broadcast('dataUpdate', gridData);
                        
                    }
                }
                vm.importObj.saving = false
                vm.importObj.crudLoading = false;
                vm.importObj.cancelcrudLoading = false;
                activeScreen.saving_data = false;
            });
            return true;
        }

        $scope.$on('modalDataPaste', function (event, data) {
            pasteExport.processXls(data)
        });

        // Logic to Transpose data if applicable
        function transposeData (workspaceFactory, _data){
            var transpose_maps = [];
            _.forEach(workspaceFactory.activeScreen.data.header, function(header){
                if(header.transpose && header.transpose !== null){
                    transpose_maps.push({[header.map] : header.transpose});
                }
            });
            _.forEach(_data.data, function(row){
                _.forEach(transpose_maps, function(map){
                    angular.forEach(map, function(value, key){
                        if(row[key]){
                            row[key] = value === 'uppercase' ? row[key].toUpperCase() : row[key].toLowerCase();
                        }
                    });
                });
            });
        };

        $scope.$on('templateLoaded', function (_event, _data) {
            var gridData = workspaceFactory.activeScreen.data === undefined ? _data.data : workspaceFactory.activeScreen.data;

            transposeData(workspaceFactory, _data);

            gridData.data = _data.data;

            vm.tagNumber = "";
            if(_data.tagNo){
                vm.batchTags = _data.tagNo;
            }else{
                vm.batchTags = [];
            }
            
            /* check the max xls rows 5/11/2020  ::: future use*/
          /*   if( _data.data.length >  gridData.settings.max_xls_rows ){
                AlertService.add("warning","Max allowed rows "+ gridData.settings.max_xls_rows,4000);
                return;
            } */

            //Logic to show error while batch upload
            if(_data.errorList !== undefined && _data.errorList.length > 0){
                var errorArray = _data.errorList;
                var errors = false;

                _.forEach(gridData.data, function (value, key) {
                    var index = _.findIndex(errorArray, function (o) {
                        return o.object_id == value.object_id;
                    });

                    if (index > -1) {
                        value.error_msg = errorArray[index].error_message;
                        vm.errorLength = errorArray.length;
                        if(workspaceFactory.activeScreen.importScreen && workspaceFactory.activeScreen.importScreen.errorLength){
                            workspaceFactory.activeScreen.importScreen.errorLength = vm.errorLength;
                        }
                       
                        value.row_class = "gg-error-row";
                        value.col_class = "gg-error-col"
                        value.validated = 'false';
                        errors = true;
                    } else {
                        delete value.error_msg;
                        value.row_class = null;
                        value.validated = 'true';
                    }
                });
                gridData.tableState.sort = {
                    predicate: 'error_msg'
                };
                if (errors) {
                    AlertService.add("error", "Please check below batch import errors.", 2000);
                }
            }

            /**************************************************************************************
             * starts Here this is form workflow triable balance import custom grid screen  5/9/2019
             * ***************************************************************************************/

            if (workspaceFactory.activeScreen.data === undefined && gridData.settings === undefined) {
                gridData.settings = {
                    attachments: 0,
                    batch_rule_message: null,
                    batch_select: 0,
                    batch_select_rules: "",
                    commentType: "all",
                    comments: 1,
                    default_row_height: 30,
                    expand_cols: 0,
                    fixed_columns: 2,
                    grid_action: [],
                    grid_desc: "Trial Balance Batch Import",
                    grid_key: "106",
                    grid_name: "Trial Balance Batch Import",
                    grid_type: "import",
                    header_height: "35",
                    is_pivot: "N",
                    max_xls_rows: 1000,
                    row_identifier: null,
                    total_class: null,
                    total_identifier: null
                };
                // if(gridData.header===undefined){
                gridData.header = [{
                        "grid_header_key": "78989",
                        "grid_key": "106",
                        "label": null,
                        "full_label": null,
                        "map": "object_id",
                        "width": 40,
                        "data_type": "number",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "n",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 1,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": "0",
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    },
                    {
                        "grid_header_key": "78990",
                        "grid_key": "106",
                        "label": "LEID",
                        "full_label": null,
                        "map": "leid",
                        "width": 120,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 2,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    },
                    {
                        "grid_header_key": "78991",
                        "grid_key": "106",
                        "label": "CDR",
                        "full_label": null,
                        "map": "cdr_number",
                        "width": 90,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 3,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78992",
                        "grid_key": "106",
                        "label": "Reporting Period",
                        "full_label": null,
                        "map": "reporting_period",
                        "width": 120,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 4,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78993",
                        "grid_key": "106",
                        "label": "ME",
                        "full_label": null,
                        "map": "me_code",
                        "width": 120,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 5,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78994",
                        "grid_key": "106",
                        "label": "Ledger",
                        "full_label": null,
                        "map": "ledger",
                        "width": 120,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 6,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78995",
                        "grid_key": "106",
                        "label": "Currency",
                        "full_label": null,
                        "map": "curr",
                        "width": 90,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 7,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78996",
                        "grid_key": "106",
                        "label": "CHART",
                        "full_label": null,
                        "map": "local_chart",
                        "width": 90,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 8,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78997",
                        "grid_key": "106",
                        "label": "Account",
                        "full_label": null,
                        "map": "local_acct",
                        "width": 120,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 9,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78998",
                        "grid_key": "106",
                        "label": "Acct Desc",
                        "full_label": null,
                        "map": "local_acct_desc",
                        "width": 90,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 10,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "78999",
                        "grid_key": "106",
                        "label": "System Acct",
                        "full_label": null,
                        "map": "system_acct",
                        "width": 90,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 11,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "79000",
                        "grid_key": "106",
                        "label": "Trading Partner",
                        "full_label": null,
                        "map": "trading_partner",
                        "width": 120,
                        "data_type": "string",
                        "format": null,
                        "edit_type": null,
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 12,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "79001",
                        "grid_key": "106",
                        "label": "Cy Amount",
                        "full_label": null,
                        "map": "cy_amount",
                        "width": 120,
                        "data_type": "string",
                        "format": "numberWithCommas",
                        "edit_type": "text",
                        "validation": "numeric",
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 13,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }, {
                        "grid_header_key": "79002",
                        "grid_key": "106",
                        "label": "Py amount",
                        "full_label": null,
                        "map": "py_amount",
                        "width": 90,
                        "data_type": "string",
                        "format": null,
                        "edit_type": "text",
                        "validation": null,
                        "editable": "y",
                        "display": "y",
                        "call_back": null,
                        "header_class": null,
                        "col_class": "",
                        "drilldown": null,
                        "priority": 14,
                        "new_row_editable": "0",
                        "static_values": null,
                        "required": null,
                        "data_api": null,
                        "apply_rules": null,
                        "edit_rules": null,
                        "edit_rule_message": null,
                        "edit_icon": null
                    }
                ]
                //   };
                gridData.columnTemplates = [{
                    default_template: 0,
                    name: "Default Columns",
                    system: 1,
                    template_key: 0,
                    columns: [{
                            width: 40,
                            display: "y",
                            map: "object_id"
                        },
                        {
                            width: 120,
                            display: "y",
                            map: "leid"
                        },
                        {
                            width: 90,
                            display: "y",
                            map: "cdr_number"
                        },
                        {
                            width: 120,
                            display: "y",
                            map: "reporting_period"
                        },
                        {
                            width: 120,
                            display: "y",
                            map: "me_code"
                        },
                        {
                            width: 120,
                            display: "y",
                            map: "ledger"
                        },
                        {
                            width: 90,
                            display: "y",
                            map: "curr"
                        },
                        {
                            width: 90,
                            display: "y",
                            map: "local_chart"
                        },
                        {
                            width: 120,
                            display: "y",
                            map: "local_acct"
                        },
                        {
                            width: 90,
                            display: "y",
                            map: "local_acct_desc"
                        },
                        {
                            width: 90,
                            display: "y",
                            map: "system_acct"
                        },
                        {
                            width: 120,
                            display: "y",
                            map: "trading_partner"
                        },
                        {
                            width: 120,
                            display: "y",
                            map: "cy_amount"
                        },
                        {
                            width: 90,
                            display: "y",
                            map: "py_amount"
                        }
                    ]
                }]
                gridData.selected = {
                    action: undefined,
                    colData: {
                        apply_rules: null,
                        call_back: null,
                        col_class: "",
                        data_api: null,
                        data_type: "string",
                        display: "y",
                        drilldown: null,
                        edit_icon: null,
                        edit_rule_message: null,
                        edit_rules: null,
                        edit_type: "text",
                        editable: "y",
                        format: null,
                        full_label: null,
                        grid_header_key: "78990",
                        grid_key: "106",
                        header_class: null,
                        label: "LEID",
                        map: "leid",
                        new_row_editable: "0",
                        object_id: "1",
                        priority: 2,
                        required: null,
                        static_values: null,
                        validation: null,
                        width: 120
                    },
                    col_id: "leid",
                    color: "green",
                    data_type: "string",
                    edit_type: "text",
                    editable: "y",
                    format: null,
                    new_row_editable: "0",
                    obj_id: "1-leid",
                    oldValue: undefined,
                    rowData: {
                        object_id: "1"
                    },
                    row_id: "1",
                    select_type: "cell",
                    static_values: null,
                    validation: null,
                    value: undefined,
                }
                gridData.tableState = {
                    colSearch: [],
                    pagination: {
                        start: 0
                    },
                    search: {},
                    sort: {}
                }
                gridData.cellData = {}
            }

            /**************************************************************************************
             * ENDS Here this is form workflow triable balance import custom grid screen  5/9/2019
             * ***************************************************************************************/
            pasteExport.loadGrid(gridData);
        });





        var pasteExport = {
            extensionId: 'npkadhmnkkhdbojjjmenafcnjadkcfme', //GOOD
            allowed: true,
            hasExtension: false,
            requiredVersion: 0.1,

            checkForExtension: function () {
                var that = this;
                if (window.chrome && chrome.runtime) {
                    chrome.runtime.sendMessage(
                        this.extensionId, {
                            method: 'getVersion'
                        },
                        function (response) {
                            if (response) {
                                if (response >= that.requiredVersion) {
                                    pasteExport.hasExtension = true;
                                } else {
                                    pasteExport.hasExtension = false;
                                }
                            } else {
                                console.log("the required version of the chrome extension " + that.extensionId + " is not installed");
                            }
                        }
                    )
                }
                return pasteExport.hasExtension;
            },

            readClipboard: function () {
                var that = pasteExport;
                chrome.runtime.sendMessage(
                    that.extensionId, {
                        method: 'getClipboard'
                    },
                    function (response) {
                        that.processXls(response);
                    }
                )
            },

            processXls: function (_data) {
                var that = this;
                $timeout(function () {
                    var text = _data;
                    var rows = text.split("\n");
                    var cols = text.split("\t");
                    var gridData = workspaceFactory.activeScreen.data;
                    // var headerArray = gridData.header
                    var headerArray = _.filter(gridData.header, {
                        display: "y"
                    }); /*when headers disply should be Y values */
                    var dataArray = [];
                    gridData.data = [];
                    deleteImportHeaders(gridData);
                    if (rows.length && cols.length > 2) {
                        for (var i = 0; i < rows.length; i++) {
                            if (rows[i] != "" && typeof rows[i] !== 'undefined') {
                                var newRow = {};
                                var cells = rows[i].split("\t");
                                newRow[headerArray[0].map] = (i + 1).toString();
                                for (var c = 0; c < cells.length; c++) {
                                    var hI = c + 1;
                                    if (headerArray[hI] !== undefined) {
                                        if (headerArray[hI].validation) {
                                            var rawValue = cells[c];
                                            var value = that.validate(cells[c], headerArray[hI].validation);
                                            newRow[headerArray[hI].map] = value;
                                        } else {
                                            newRow[headerArray[hI].map] = cells[c];
                                        }
                                    }
                                }
                                gridData.data.push(newRow);
                            }
                        }
                        that.loadGrid(gridData);
                        $rootScope.$broadcast('showFilterGroup', {
                            showFilter: true
                        });

                        /* check max paste rows 5/11/2020 below code::: future use */
/*                         if(gridData && gridData.data && gridData.data.length<= workspaceFactory.activeScreen.data.settings.max_xls_rows){
                               that.loadGrid(gridData);
                               $rootScope.$broadcast('showFilterGroup', {
                                   showFilter: true
                               });
                           }else{
                               AlertService.add("warning", "Max allowed rows " + workspaceFactory.activeScreen.data.settings.max_xls_rows, 4000);
                               return;
                           } */

                    } else {
                        AlertService.add("warning", "The data you have pasted from the clip board does not appear to be data copied from an XLS file.", 4000);
                    }
                })
            },



            loadGrid: function (gridData) {
                /* if gridData.data undefined case redirect to step 1 5/14/2019 */
                if (gridData.data === undefined) {
                    vm.importObj.step = 1;
                } else {
                    this.addImportHeaders(gridData);
                    vm.importObj.step = 2
                    vm.importObj.dirty = true;
                    transposeData(workspaceFactory, gridData);
                    $rootScope.$broadcast('dataLoaded', gridData);
                }

            },



            getVersion: function () {
                var that = this;
                chrome.runtime.sendMessage(
                    this.extensionId, {
                        method: 'getVersion'
                    },
                    function (response) {
                        return response
                    }
                )
            },

            validate: function (value, validation) {
                var returnVal = "";
                if (validation == 'numeric') {
                    if (value && !isNaN(value)) {
                        returnVal = Number(value);
                    }
                    return returnVal;
                } else if (validation == 'date') {
                    return returnVal;
                }
                return value;
            },

            addImportHeaders: function (data) {
                var errorHeader = {
                    call_back: null,
                    col_class: "",
                    data_type: "string",
                    display: "y",
                    drilldown: null,
                    edit_type: "text",
                    editable: "n",
                    format: null,
                    full_label: null,
                    grid_header_key: "168",
                    grid_key: "10",
                    header_class: null,
                    label: "Error Message",
                    map: "error_msg",
                    object_id: "error_msg",
                    priority: 2,
                    validation: null,
                    width: 170
                }
                var validationHeader = {
                    call_back: null,
                    col_class: "",
                    data_type: "string",
                    display: "y",
                    drilldown: null,
                    edit_type: "text",
                    editable: "n",
                    format: null,
                    full_label: null,
                    grid_header_key: "168",
                    grid_key: "10",
                    header_class: null,
                    label: "Validated",
                    map: "validated",
                    object_id: "validated",
                    priority: 2,
                    validation: null,
                    width: 100
                }

                data.header.splice(1, 0, errorHeader);
                data.header.splice(data.header.length, 0, validationHeader);
            }
        }

        //CHECK IF EXTENSION IS INSTALLED
        pasteExport.checkForExtension();

        vm.openDocument = function(){
            var modalInstance = $uibModal.open({
               animation: true,
               templateUrl: 'app/components/dataFilters/screen-docs/screen-document-tpl.html',
               controller: 'screenDocsController as ctrl',
               size: 'lg',
               resolve: {}
           });
       }

    }
   

    return controllers;

});
