/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.cbcReportingService',[])

        .factory("cbcReportingFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','AlertService','USER_SETTINGS','GENERAL_CONFIG','$injector', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,AlertService,USER_SETTINGS,GENERAL_CONFIG,$injector) {
            var factory = {};
            factory.url = {};
            var service = $injector.get('JsonObjectFactory');

            if(workspaceFactory && workspaceFactory.activeScreen && workspaceFactory.activeScreen.activity_key == '30'){
                factory.url.savetaxesPaid  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=54";
                factory.url.deletetaxesPaid  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=56";
                factory.url.tbl1Comments  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=57";
                factory.url.tbl2Comments  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=58";
                factory.url.tbl2Check = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=59";
                factory.url.notify = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=71";
                factory.url.parent_le = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=72";
                factory.url.cashTaxesSignOff = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=96";
            }else if(workspaceFactory && workspaceFactory.activeScreen && workspaceFactory.activeScreen.activity_key == '5606'){
                factory.url.savetaxesPaid  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31075";
                factory.url.deletetaxesPaid  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31076";
                factory.url.tbl1Comments  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31069";
                factory.url.tbl2Comments  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31070";
                factory.url.tbl2Check = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31071";
                factory.url.notify = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31072";
                factory.url.parent_le = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31073";
                factory.url.cashTaxesSignOff = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31068";
            }
            
            factory.url.refreshTable2 = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31653";
            factory.url.refreshCbyE = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31685";
            factory.url.refreshStateless = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32597";
            factory.url.pushStateless = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32613";





            var jsonSettings = [{metadata1:"5RT4982YTE6"},{metadata2:"1PQ982YTE6"}];

            factory.refreshStatelessData = function() {
                const url = factory.url.refreshStateless; 
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id
                };
                
                var promise = $http({
                    method: "post",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": true
                        });
                        AlertService.add("success", "Data is successfully refreshed.", 4000);
                        return response.data;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.pushStatelessData = function() {
                const url = factory.url.pushStateless; 
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id
                };
                
                var promise = $http({
                    method: "post",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": false
                        });
                        AlertService.add("success", "Data is successfully pushed to table1.", 4000);
                        return response.data;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.refreshCbyEData = function() {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                const url = factory.url.refreshCbyE; 
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year : filters.tax_year,
                    scenario: filters.scenario,
                    jcd_key: 250
                };
                
                var promise = $http({
                    method: "post",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": true
                        });
                        AlertService.add("success", "Data is successfully refreshed.", 4000);
                        return response.data;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.refreshTable2Data = function() {
                var filters = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                const url = factory.url.refreshTable2;//GENERAL_CONFIG.base_url + `&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${params.tax_year}`;  
                const params = {
                    sso_id : USER_SETTINGS.user.sso_id,
                    tax_year:filters.tax_year,
                    scenario: filters.scenario,
                    jcd_key: 250
                };
                
                var promise = $http({
                    method: "post",
                    url: url,
                    params:params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": true
                        });
                        AlertService.add("success", "Data is successfully refreshed.", 4000);
                        return response.data;
                    }else {
                        AlertService.add('error', response.data.errorMessage, 4000);
                    }
                });

                return promise;
            }

            factory.updateBpData = function (_callName, _data) {
                var json_arrOBJ = [];
                var defer = $q.defer();
                var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=f1ah9x';
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                // params.process_name = 'UPDATE_PDF_DD_DATA';
                json_arrOBJ.push(_data.rowData);
                var clob_settings = [{
                        screen_key:params.screen_key
                    }];
                params = {
                    sso_id: USER_SETTINGS.user.sso_id,
                    clob_data: JSON.stringify(json_arrOBJ),
                    tax_year:params.tax_year,
                    clob_settings:JSON.stringify(clob_settings)
                };

                service.saveJSON(url, params).then(function (data) {
                    if (data.callSuccess === '1') {
                        AlertService.add('success', 'Data saved Successfully.', 4000);
                        var args = {
                            rrn: _data.rowData.RRN,
                            gridFilter: {
                                rrn: _data.rowData.RRN
                            }
                        };
                        $rootScope.$emit('gridUpdate', args);
                        defer.resolve(data);
                    } else {
                        AlertService.add('error', data.errorMessage);
                        defer.reject(data);
                    }
                }, function (data) {
                    defer.reject(data);
                });
                return defer.promise;
            };

            factory.saveComments= function(_callName , _data){
               // GOLD_ID,MARS_ID
                var url = factory.url.tbl1Comments;
                var params = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                params = _.merge({},  GlobalService.globalParams , params);


                if(_data.rowData.MARS_ID && _data.rowData.GOLD_ID){
                    params.country_code = _data.rowData.LE_Country_Code;
                    params.comments =  _data.rowData.COMMENTS;
                    params.mars_id = _data.rowData.MARS_ID;
                    params.gold_id =  _data.rowData.GOLD_ID;
                }else{
                    url =  factory.url.tbl2Comments;
                    params.object_id = _data.rowData.object_id;
                    params.comments = _data.rowData.Comments;
                }


                var promise = $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                });

                return promise;

            };


            factory.saveTaxesPaid = function(_array){
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_array);
                jsonSettings =  JSON.stringify(jsonSettings);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                params.process_name =  "taxes_paid_save";

                var promise = $http({
                    method: "post",
                    url: factory.url.savetaxesPaid,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            }

            factory.deleteTaxesPaid = function(obj_id){
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params.base_supp_key =  obj_id;

                var promise = $http({
                    method: "post",
                    url: factory.url.deletetaxesPaid,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            }




            factory.countryParentLe = function(_callName , _data) {
                if( typeof _data.rowData.Parent_LE === 'undefined' ){
                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    return false;
                }

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params.parent_le =   _data.rowData.Parent_LE;
                params.beps_pk = _data.rowData.object_id;

                var promise = $http({
                    method: "post",
                    url: factory.url.parent_le,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }

                });

                return promise;
            }



            factory.countrySignOff = function(_callName , _data) {
                if( typeof _data.rowData.Notified === 'undefined' ){
                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    return false;
                }

                 function updateGrid(){
                    if( _data.rowData.Notified === 1 ){
                        //TODO: Fix this in GRID - ( 1 or "1")
                        _data.rowData.Notified = "1"
                        _data.rowData.Notified_By =  USER_SETTINGS.user.sso_id;
                    }else{
                        _data.rowData.Notified = "0"
                        delete _data.rowData.Notified_By;
                    }
                }

                function failedUpdate(){
                    _data.rowData.Notified = (  _data.rowData.Notified === "0") ?  _data.rowData.Notified === "1" :  _data.rowData.Notified === "0";
                }


                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params.notified =   _data.rowData.Notified;
                params.beps_pk = _data.rowData.object_id;

                var promise = $http({
                    method: "post",
                    url: factory.url.notify,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        updateGrid();
                        return response.data;
                    }else {
                        failedUpdate();
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }).catch(function(e){
                    failedUpdate();
                });

                return promise;
            }





            factory.cashTaxesSignOff = function(_callName , _data) {

                if( typeof _data.rowData.Cash_Taxes === 'undefined' ){
                    AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    return false;
                }

                var params = _.merge({},  GlobalService.globalParams , params);
                params.beps_pk = _data.rowData.object_id;
                params.cash_taxes = _data.rowData.Cash_Taxes;

                ///////////START HERE
                var promise = $http({
                    method: "post",
                    url: factory.url.cashTaxesSignOff,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        _data.rowData.Cash_Taxes = ( _data.rowData.Cash_Taxes === 1 ) ? 0 : 1;
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }).catch(function(e){
                    _data.rowData.Cash_Taxes = ( _data.rowData.Cash_Taxes === 1 ) ? 0 : 1;
                });

                return promise;
            }




            factory.checkTheBox = function(_callName , _data) {

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = filterParams;

                //HS - 7-11-2017 Make sure row is not signed off. If so do not allow changes
                if(_data.colData.map !== "Signed_Off_0" && _data.rowData.Signed_Off_0 && _data.rowData.Signed_Off_0 === 1 ){
                    $timeout( function(){
                        AlertService.add("warning", "Sorry this row has been signed off, you can not make changes at this time", 4000);
                    }, 2 )
                    _data.rowData[_data.colData.map] = ( _data.rowData[_data.colData.map] == 1) ? 0 : 1;
                    return false;
                }

                params = _.merge({},  GlobalService.globalParams , params);
                params.beps_pk = _data.rowData.object_id;
                params.checked =  _data.rowData[_data.colData.map];
                params.cat_desc =  _data.colData.map;

                var promise = $http({
                    method: "post",
                    url: factory.url.tbl2Check,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    if(response.data.callSuccess === "1" ){
                        return response.data;
                    }else {
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                });

            }


            return factory;

        }])
                           .factory('schdATbl3DataFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                               'USER_SETTINGS', 'GENERAL_CONFIG', schdATbl3DataFactory])
                           .factory('MELockDataFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                               'USER_SETTINGS', 'GENERAL_CONFIG', MELockDataFactory])
                           .factory('CbCrAdjDataEntryFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                               'USER_SETTINGS', 'GENERAL_CONFIG', CbCrAdjDataEntryFactory])
                               .factory('CbCrVarianceFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                               'USER_SETTINGS', 'GENERAL_CONFIG', CbCrVarianceFactory])

    function schdATbl3DataFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');

        factory.toggleActivate = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=79vvlx';
            var params = {
                'sso_id': sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'scenario': workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                'activate': _data.rowData.ACTIVATE === 1 ? 'Y' : 'N',
                'sno': _data.rowData.SNO
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        sno: _data.rowData.SNO,
                        gridFilter: {
                            sno: _data.rowData.SNO
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.toggleApplyToAllJuris = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=ipa6up';
            var params = {
                'sso_id': sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'scenario': workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                'all_tax_jcd': _data.rowData.APPLY_TO_ALL_TAX_JURISDICTIONS === 1 ? 'Y' : 'N',
                'sno': _data.rowData.SNO
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        sno: _data.rowData.SNO,
                        gridFilter: {
                            sno: _data.rowData.SNO
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        return factory;
    }

    function MELockDataFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');

        factory.toggleLocked = function (_callName, _data) {
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=wgn1de';
            var params = {
                'sso_id': sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'scenario': workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                'me_code': _data.rowData.MAINTENANCE_OWNER,
                'tb_lock_flag': _data.rowData.TB_LOCK_Y_N === 1 ? 'Y' : 'N',
                'adj_lock_flag': _data.rowData.ADJ_LOCK_Y_N === 1 ? 'Y' : 'N',
                'comments': _data.rowData.COMMENTS
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.toggleMasterLocked = function (_callName, _data) {
            
            if (USER_SETTINGS.user.system_access_level < 3) {
                AlertService.add("warning", "You don't have access to update this Master Flag", 5000);
                $rootScope.$apply();
                return;
            };

            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=kt0xfz';
            var params = {
                'sso_id': sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'scenario': workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                'me_code': _data.rowData.MAINTENANCE_OWNER,
                'master_lock_flag': _data.rowData.MASTER_LOCK_Y_N === 1 ? 'Y' : 'N'
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        return factory;
    }

    function CbCrAdjDataEntryFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');

        factory.saveCbCrAdjDataEntryDetails = function (_callName, _data, isNew) {
            if (factory.saving) {
                AlertService.add('warning', 'Grid data is being updated, please wait.', 4000);
                return;
            }
            var clob_data = [];
            var params = {};
            // var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=guepv1";
            clob_data.push(_data.rowData);
            clob_data[0].edited_column = _data.colData.map;
            clob_data[0].edited_value = _data.rowData[_data.colData.map];

            params.process_name = "CbCr_Adjustments_Data_Entry";
            params = _.extend({
                clob_data: JSON.stringify(clob_data)
            }, params);
            params = _.extend({
                sso_id
            }, params);
            params = _.merge({}, GlobalService.globalParams, params);
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            params.scenario = filterParams.scenario;
            params = _.merge({}, params, filterParams);

            factory.saving = true;
            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    //AlertService.add("success", "Data saved Successfully.", 4000);
                    var args = {
                        tax_year: _data.rowData.TAX_YEAR
                    };
                    $rootScope.$emit('gridUpdate', args);
                    // defer.resolve(data);
                } else if (data.errorMessage && data.errorMessage !== "") {
                    AlertService.add("error", data.errorMessage, 4000);
                    // defer.reject(data);
                } else {
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                    // defer.reject(data);
                }
            }).finally(() => {
                factory.saving = false;
            });
        };
        return factory;
    }
    function CbCrVarianceFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.saveCbCrVarianceType = function (_callName, _data) {
            var defer = $q.defer();
            console.log(_data.rowData);
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=h4bo44';
            var params = {
                'sso_id': sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'scenario': workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                'user_variance': _data.rowData.USER_MODIFIED,
                'variance_key': _data.rowData.VARIANCE_KEY,
                'category': _data.rowData.CATEGORY,
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    
                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        
        }

        factory.toggleVarianceActivate = function (_callName, _data) {
            var defer = $q.defer();
            console.log(_data.rowData);
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=6nchqj';
            var params = {
                'sso_id': sso_id,
                'tax_year': workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                'scenario': workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                'activate': _data.rowData.ACTIVATE === 1 ? 'Y' : 'N',
                'variance_key': _data.rowData.VARIANCE_KEY,
            };

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    
                    $rootScope.$emit('gridUpdate', {});
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        return factory;
    }

    return services;


});
