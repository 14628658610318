define(
    ['angular'

    ],
    function () {
        'use strict';

        var controllers = angular.module('app.schPSaveCtrl', [])
            .controller('schPSaveCtrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'schPSaveServiceFactory', 'rowData', 'gridData', schPSaveCtrl])


        function schPSaveCtrl($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, schPSaveServiceFactory, rowData, gridData) {
            var vm = this;
            vm.userSettings = USER_SETTINGS;
            vm.logged_in_user = vm.userSettings.user.sso_id;
            vm.defaultsMultipleSelected = [];
            vm.gridData = [];
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            vm.currentyear = filterParams.tax_year;
            vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
            vm.isSaveClicked = false;


            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };



            vm.confirm_save = function () {
                gridData.forEach(element => {
                    vm.gridData.push(element.data);
                });
                var returnClobSettingsObj = {};
                if(filterParams.CDR == "0" || filterParams.LEID == "0" || filterParams.bsla == "0" || filterParams.led_curr == "0" || filterParams.reporting_p == "0") {
                    AlertService.add("error", "Please Select all the entity parameters to Save", 4000);
                }
                else {
                    returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                    returnClobSettingsObj['tax_year'] = vm.currentyear;
                    returnClobSettingsObj['CDR'] = filterParams.CDR;
                    returnClobSettingsObj['LEID'] = filterParams.LEID;
                    returnClobSettingsObj['bsla'] = filterParams.bsla;
                    returnClobSettingsObj['led_curr'] = filterParams.led_curr;
                    returnClobSettingsObj['reporting_p'] = filterParams.reporting_p;
                    var message = "Schedule P Data Submitted for Save!";
                    sendDetails(returnClobSettingsObj,  message);
                }

                

            };

            function sendDetails(returnClobSettingsObj, message) {
                $rootScope.$broadcast("showLoader", true);
                $uibModalInstance.dismiss('cancel');
                const dataToSend = [];
                vm.gridData.forEach((data) => {
                    dataToSend.push({
                        SECTION: data.SECTION,
                        ACCT_TYPE: data.ACCT_TYPE,
                        object_id: data.object_id,
                        CHART_TYPE: data.CHART_TYPE,
                        TAX_YEAR: data.TAX_YEAR,
                        COL_25: data.COL_25,
                        COL_26: data.COL_26,
                        COL_35: data.COL_35,
                        COL_34: data.COL_34,
                        COL_2: data.COL_2
                    });
                })
                
                
                schPSaveServiceFactory.saveDetails(returnClobSettingsObj, dataToSend).then(function (result) {
                    if(parseInt(result.data.callSuccess) == 1) {
                        AlertService.add("success", message, 4000);
                        
                    }
                    else {
                        AlertService.add("error", result.data.errorMessage, 4000);
                    }
                    $rootScope.$broadcast("showLoader", false);
                });
            }



        }
        return controllers;
    });