define([
    'angular',
    './giltiCalcGridService',
    './giltiCalcGridController'

], function () {
    'use strict';
    return angular.module('app.giltiCalcGridData', ['app.giltiCalcGridCtrl','app.giltiCalcGridService'] )

});


















