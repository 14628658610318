define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.sysFormChartAcctMapService',[])
       .factory("SystemGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
    	  ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG'
    	   , function($rootScope, $log,JsonObjectFactory,AlertService, $injector
    		    	  ,$q, $http,
    		           $stateParams, $timeout, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
           var factory = {};
           var returnObj = {};
           var systemGroupObjFactory ={};
           systemGroupObjFactory.url = {};
           systemGroupObjFactory.url.saveIRSForm  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=hhba23";
   //        systemGroupObjFactory.url.deleteSystemForm = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=94";
           systemGroupObjFactory.getAccntDetails = function(_data){
               console.log("_data ::",_data);
               var params= {
                       "action_code": '3jfcdi',
                       "OBJECT_ID": _data.OBJECT_ID
               }

               
               
                 return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',params).then(function(data) {
                     console.log("groupObjFactory.getAccntDetails() data::",data);

                     if(data.errorMessage.length > 0 && data.errorMessage !== 'null' ) {
                        AlertService.add("error", data.errorMessage);
                        return false;
                    }else{
                        return data.jsonObject;
                    }
                });
              
             
              }
           
           systemGroupObjFactory.saveIRSFormDetails = function(_data,IRSFormDetails){

               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
               var jsonObj = JSON.stringify(IRSFormDetails);
               var jsonSettings =  JSON.stringify(_data);
               console.log(jsonSettings);
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
               params =  _.extend({jsonSettings:jsonSettings}, params);
//               params =  _.extend({jsonSettings:jsonSettings}, _data);
               params.process_name =  "IRSFORM_SAVE";
               console.log(params);
               var promise = $http({
                   method: "post",
                   url: systemGroupObjFactory.url.saveIRSForm,
                   data: params
               }).then(function (response) {
                   var status = response.status;
                   return response.data;
               });

               return promise;

           };
           
           systemGroupObjFactory.deleteSystemFormDetails = function(_data,IRSFormDetails) {

               console.log('_data passed to delete reclass function');
               console.log(_data);
               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
               var jsonObj = JSON.stringify(IRSFormDetails);
               var jsonSettings =  JSON.stringify(_data);
               console.log(jsonSettings);
               var params = filterParams;
               params = _.merge({},  GlobalService.globalParams , params);
               params =  _.extend({jsonObj:jsonObj}, params);
               params =  _.extend({jsonSettings:jsonSettings}, params);

                 console.log("SYSTEM FORM params to delete");
                 console.log(params);
                 var promise = $http({
                     method: "post",
                     url: systemGroupObjFactory.url.deleteSystemForm,
                     data: params
                 }).then(function (response) {
                     var status = response.status;
                     return response.data;
                 });

                 return promise;

             };
return systemGroupObjFactory;
       }]);

   return services;
});