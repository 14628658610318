define([
	'angular',
    './notifCtrl',
    './notifService'
    
    ],function () {
        'use strict';
        return angular.module('app.adminnotif', ['app.notifCtrl','app.notifService'])
        
        .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
            $stateProvider
		        .state('admin.notif-engine', {
		     	   url: '/notif-engine',
		            templateUrl: 'app/components/admin/notifEngine/notif-list-tpl.html',
                    type:'',
                    backdrop: 'static',
		            onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
		            api: {},
		            access: 4
		            
		        })
		        
		         .state("admin.notif-engine.notif", {
                   params: {
                       data: null
                   },
                   onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {
                       $uibModal.open({
                           templateUrl: 'app/components/admin/notifEngine/notif-tpl.html',
                           controller: 'notifCtrl as ctrl',
                        //    backdrop: true,
                           size:"lg",
                           backdrop: 'static',
                           windowClass: 'right fade'
                       }).result.finally(function() {
                           $state.go('admin.notif-engine');
                           $rootScope.$emit('gridRefresh');
                       });
                   }],
                    abstract: true
                })
		        
		        .state("admin.notif-engine.notif.details", {
                    parent: 'admin.notif-engine.notif',
                    access: 3,
                    views:{
                        "modal@": {
                             templateUrl: 'app/components/admin/notifEngine/notif-details-tpl.html',
                             backdrop: 'static',
                            controller: 'notifDetailCtrl as ctrl',

                        }
                    }
                })
            
                 .state("admin.notif-engine.notif.maint", {
                        access: 3,
                        views:{
                            "modal@": {
                                 templateUrl: 'app/components/admin/notifEngine/maint-notif.html',
                                 backdrop: 'static',
                                controller: 'notifMaintCtrl as ctrl',

                            }
                        }
                    })
                
                .state("admin.notif-engine.notif.publishers", {
                    parent: 'admin.notif-engine.notif',
                    access: 3,
                    views:{
                        "modal@": {
                             templateUrl: 'app/components/admin/notifEngine/notif-publishers-tpl.html',
                             backdrop: 'static',
                            controller: 'notifPublishersCtrl as ctrl',

                        }
                    }
                })
                .state("admin.notif-engine.notif.render", {
                    parent: 'admin.notif-engine.notif',
                    access: 3,
                    views:{
                        "modal@": {
                             templateUrl: 'app/components/admin/notifEngine/notif-render-tpl.html',
                             backdrop: 'static',
                            controller: 'notifRenderCtrl as ctrl',

                        }
                    }
                })
                .state("admin.notif-engine.notif.writer", {
                    parent: 'admin.notif-engine.notif',
                    access: 3,
                    views:{
                        "modal@": {
                             templateUrl: 'app/components/admin/notifEngine/notif-writer-tpl.html',
                             backdrop: 'static',
                            controller: 'notifWriterCtrl as ctrl',

                        }
                    }
                });
             
        }]);
});


