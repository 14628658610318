/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './Controllers',
    './Service',
    'd3',
    'c3'


], function () {
    'use strict';
   return angular.module('app.SampleApp', ['app.SampleAppController','app.SampleAppServices','app.SampleAppServices'])
     //return angular.module('app.codeCombinations',[]);


       .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider,GENERAL_CONFIG) {
           $stateProvider
               .state('sampleApplication', {
                   url: '/sample-application',
                   templateUrl: 'app/components/sampleApplication/index.html',
                   access: ""
               })

               .state('sampleApplication.dashboard', {
                   url: '/dashboard',
                   templateUrl: 'app/components/sampleApplication/templates/dashboard-tpl.html',
                   access: ""
               })

               .state('sampleApplication.dashboard5', {
                   url: '/dashboard5',
                   template: '<h1>Test</h1>',
                   access: ""
               })


               .state('sampleApplication.chart', {
                   url: '/chart',
                   templateUrl: 'app/components/sampleApplication/templates/chart-tpl.html',
                   access: ""
               })

               .state('sampleApplication.list', {
                   url: '/list',
                   templateUrl: 'app/components/sampleApplication/templates/list-tpl.html',
                   access: ""
               })


               .state('sampleApplication.list.edit', {
                   url: '/edit/:id',
                   templateUrl: 'app/components/sampleApplication/templates/form-edit-tpl.html',
                   access: ""
               })

               .state('sampleApplication.list.add', {
                   url: '/add',
                   templateUrl: 'app/components/sampleApplication/templates/form-edit-tpl.html',
                   access: ""
               })


               .state('sampleApplication.grid', {
                   "templateUrl": "app/components/grid/grid-standard-tpl.html",
                   "controller": "GirdUserController as ctrl",
                   type:'grid',
                   "api": {"get":GENERAL_CONFIG.base_url+"/loadGridJson?action_id=12&grid_id=5&tax_year=2014"},
                   "noFilters":true,
                   access: ""
               })




       }])





    });