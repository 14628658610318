
define([
    'angular'
], function () {
    'use strict';

	var module = angular.module('app.EpDrilldownCtrl', []);
    var controllers = module.controller('epDrilldownMainCtrl',
    		['$state','GridFactory','ModalFactory','$uibModalInstance','menuDataService','workspaceFactory','WorkflowTCFactory','$uibModal','GlobalService', 'AlertService', '$timeout', '$window', '$scope','JsonObjectFactory','rowData','$parse', '$rootScope', 'GENERAL_CONFIG','EpDrilldownScreenFactory',epDrilldownMainCtrl]);
		    function epDrilldownMainCtrl($state,GridFactory,ModalFactory,$uibModalInstance,menuDataService,workspaceFactory, WorkflowTCFactory, $uibModal, GlobalService, AlertService, $timeout, $window, $scope,JsonObjectFactory,rowData, $parse, $rootScope, GENERAL_CONFIG,EpDrilldownScreenFactory) {
			
		    var vm = this;
			vm.rowData= rowData;				
			vm.name = "epDrilldownMainCtrl";	
			vm.modalTitle = "E & P Adjustments";
			
			vm.loading = false;
			refreshData();			
		    vm.cancel = function () {
			        $uibModalInstance.dismiss('cancel');	
			        };
			
			$scope.$on('dataFilters:refreshGrid', function (event, args) {
				refreshData();
			});

			vm.checkNumber =  function(val){
				if(angular.isNumber(val)){
					 return true;
				}else{
					return false;
				}
			}
			
			function refreshData() {
				
		          
		           var firHoLeidFilter = {};
		           firHoLeidFilter.HO_LEID = vm.rowData.HO_LEID;
		           firHoLeidFilter.HO_CDR_NO = vm.rowData.HO_CDR_NO;
		           firHoLeidFilter.HO_REPORTING_PERIOD = vm.rowData.HO_REPORTING_PERIOD;
		           firHoLeidFilter = JSON.stringify(firHoLeidFilter);
		           var args = { "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
		              		  "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
		              			"jcd_key" : GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
         					"screen_key":GlobalService.globalParams.screen_key,
		        		       "filing_key":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.filing_key :workspaceFactory.activeScreen.filters.filterParams.filing_key,
		        		       "fir_ho_leid":workspaceFactory.activeScreen.filters === undefined 
		        		       ? "" : firHoLeidFilter
		           }
		           vm.selectedHoLeidParams =  args;
		           vm.columnNames = {};  
		           
		           loadEpDrilldownScreenData();
			}
		
			
			function loadEpDrilldownScreenData()
			{
				vm.loading = true;
				vm.displayTable = false;
				EpDrilldownScreenFactory.getEpDrilldownScreenData(vm.selectedHoLeidParams).then(
					function(data) {
						if (data.callSuccess !== "1") {
							AlertService.add("error", data.errorMessage, 4000);
							vm.loading = false;
							vm.displayTable = false;
						} else {				
							vm.initialEpDrilldownScreenData = data.jsonObject;
							vm.loadAllData();
							vm.loading = false;
						}
						workspaceFactory.activeScreen.loading_data = false;
						workspaceFactory.setCache(workspaceFactory.activeScreen);
				});
			}
			
			vm.loadAllData = function() {
				
				loadEpDrilldownTableData();
				if (vm.columnNames) {
					vm.displayTable = true;
				} else{
					vm.displayTable = false;
				}
			}
			
			vm.dispChildColumn = function(initialEpDrilldownScreenRow){
				
				initialEpDrilldownScreenRow.SHOW_DATA = !initialEpDrilldownScreenRow.SHOW_DATA;
				//Update child FIR Account rows as well
				vm.initialEpDrilldownScreenData.filter(function(epDrilldownScreenRow) {
					if (epDrilldownScreenRow.hasOwnProperty('DISP_ACCT') && epDrilldownScreenRow.DISP_ACCT
						&& epDrilldownScreenRow.DISP_ACCT === initialEpDrilldownScreenRow.DISP_ACCT
						&& epDrilldownScreenRow.ACCOUNT_KEY === 0) {
						epDrilldownScreenRow.SHOW_DATA = !epDrilldownScreenRow.SHOW_DATA;
					}
				});
				
			}

			function loadEpDrilldownTableData() {		

			var parentEpDrilldownRow = null;			
			vm.EpDrilldownScreenData = angular.copy(vm.initialEpDrilldownScreenData);
			vm.tableHeader = [];
			vm.showChildRow = '';
   			for(var k in vm.EpDrilldownScreenData[0]){
   				if(k == 'ACCT_DESC' ){
   					vm.tableHeader.push('Account Description');
   				}else if(k =='DISP_ACCT' ){
   					vm.tableHeader.push('Account');
   				}else if(k =='TOTAL' ){
   					vm.tableHeader.push('Current Year Amount');
   				}else{
   					vm.tableHeader.push(k);
   				}
   				
   			} 
   			for(var i=0; i<vm.EpDrilldownScreenData.length;i++ ){

   					vm.initialEpDrilldownScreenData[i]['Account Description'] = vm.initialEpDrilldownScreenData[i].ACCT_DESC;
   					vm.initialEpDrilldownScreenData[i]['Account'] = vm.initialEpDrilldownScreenData[i].DISP_ACCT;
   					vm.initialEpDrilldownScreenData[i]['Current Year Amount'] = vm.initialEpDrilldownScreenData[i].TOTAL ;
   					if(vm.initialEpDrilldownScreenData[i]['Account Description'] == 'Earnings_and_Profits'){
   						vm.initialEpDrilldownScreenData[i]['Account Description'] = 'Earnings and Profits';
   					}
   					if(vm.initialEpDrilldownScreenData[i]['Account Description'] == 'Total_EP_Adjustments'){
   						vm.initialEpDrilldownScreenData[i]['Account Description'] = 'Total EP Adjustments';
   					}
   					if(vm.EpDrilldownScreenData[i].ROW_TYPE === 'EP' || vm.EpDrilldownScreenData[i].ROW_TYPE === 'EP_Child') {
   						vm.EpDrilldownScreenData[i].SHOW_DATA = false;
   					}	
   					

   			}
			
		
			
			}
		}
});