define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.deltaPostingController', [])
            .controller('deltaPostingController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'deltaPostingServiceFactory',
                'rowData', 'gridData', deltaPostingController]
            );

        function deltaPostingController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, deltaPostingServiceFactory, rowData, gridData) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.userSettings = USER_SETTINGS;

            vm.tax_year = filterParams.tax_year;
            vm.scenario = filterParams.scenario;
            vm.jcd_key = GlobalService.globalParams.jcd_key;
            vm.logged_in_user = vm.userSettings.user.sso_id;

            vm.currentyear = filterParams.tax_year;
            vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
            vm.isSaveClicked = false;
            vm.syncSaveDtls = [];

            vm.syncSaveDtls.push(rowData);



            vm.confirm_posting = function() {

                let returnClobSettingsObj = {};

                returnClobSettingsObj['tax_year'] = vm.tax_year;
                returnClobSettingsObj['scenario'] = vm.scenario;
                returnClobSettingsObj['jcd_key'] = vm.jcd_key;
                returnClobSettingsObj['sso_id'] = vm.logged_in_user;
                returnClobSettingsObj['combination_key'] = rowData.COMBINATION_KEY;
                returnClobSettingsObj['tp_key'] = rowData.TP_KEY;
                returnClobSettingsObj['pship_ptr_posting_key'] = rowData.PSHIP_PTR_POSTING_KEY;

                let ecSaveDtls = vm.syncSaveDtls;
                if(vm.syncSaveDtls != undefined && vm.syncSaveDtls.length > 0) {
                    let message = "Delta Posting successfully completed.";
                    sendDetails([returnClobSettingsObj],ecSaveDtls, message);
                }
            };

            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            function sendDetails(returnClobSettingsObj, ecSaveDtls, message) {
                $rootScope.$broadcast("showLoader", true);
                $uibModalInstance.dismiss('cancel');
                deltaPostingServiceFactory.triggerDeltaPosting(returnClobSettingsObj,ecSaveDtls).then(function(result) {
                    if (result.errorMessage && result.errorMessage !== 'null') {
                        vm.isSaveClicked = false;
                        AlertService.add("error", result.errorMessage, 4000);
                    } else {
                        AlertService.add("success", message, 4000);
                    };
                    $rootScope.$broadcast("showLoader", false);
                });
            }
        }

        return controllers;
    }
);