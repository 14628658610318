define([
    'angular',
    'ui-tree',
    'ui-tree-filter',
    './BizzHierCtrl',
    './BizzHierServices'



], function () {
    'use strict';
    return angular.module('app.bizzHier', ['app.bizzHierServices','app.bizzHierCtrl','ui.toggle','ngAnimate','ui.tree','ui.tree-filter']);
    
    //goldSyncNew
    
});