define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.Form5471-G1InfoCtrl', [])
    
	.controller('scheduleG1InfoCtrlModal', ['GlobalService','$scope', 'JsonObjectFactory','$filter','$rootScope','CommonUtilitiesFactory','workspaceFactory', '$parse','form5471ScheduleG1GroupObjFactory',
	'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',scheduleG1InfoCtrlModal])
	
	
	function scheduleG1InfoCtrlModal(GlobalService,$scope, JsonObjectFactory,$filter,$rootScope,CommonUtilitiesFactory,workspaceFactory, $parse, form5471ScheduleG1GroupObjFactory,
    		AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,
			rowData, colData, gridData,thisObj) {
				
	var vm = this||thisObj;
    vm.rowData = rowData||$scope.$parent.rowData;
    vm.scheduleG1Details = [];
        vm.scheduleG1DetailsOriginal = [];
        vm.scheduleG1Details.value = "";
        vm.schedule_g1 = {};
        vm.invalidSaveMessage = "";
        vm.data = {};
        $scope.irsF5471ScheduleG1Data = [];
        
       vm.modalTitle = "Schedule G1 - Cost Sharing Arrangement";
        if (angular.isObject($uibModalInstance)) {
			vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
			vm.cancel = function () {
				/*var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
				$rootScope.$emit('gridUpdate', args);*/
				$uibModalInstance.close();
				vm.modal_name = null;
			};			
		} else {
			vm.modal_name = null;			
		}


				
				
	  vm.initLoadData = function(){
        	vm.dataLoading = true;
            loadIRSF5471ScheduleG1Data();
          
        	};
        vm.initLoadData();
        
        
        
        function loadF5471ScheduleGLine7Data()
                    {
            var params = {
                "action_code": '74ng9y',
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "combination_key": vm.rowData.HO_COMBINATION_KEY
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                    vm.temp_value = data.jsonObject[0] === undefined ? '0': data.jsonObject[0].ATTRIB_VALUE;
                    if( vm.temp_value  == '0'){
						vm.save_flag = false;
						}
						else {
							vm.save_flag = true;
						}
                  
                }
                else
                {
                    if (data.errorMessage) {
                        AlertService.add("",data.errorMessage ,
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
                
               
            });
        }
	
	
	
	
	
	
			
	 function loadIRSF5471ScheduleG1Data()
                    {
            var params = {
                "action_code": '1k04gp',
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key,
                "irs_form_no" : 'F5471',
                "combination_key": vm.rowData.HO_COMBINATION_KEY
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
                if (data.callSuccess === "1") {
                    var irsF5471ScheduleG1Data = data.jsonObject;
                    var i = 1;

                    var previous_line_no = irsF5471ScheduleG1Data[i].line_no;
                    while( i < irsF5471ScheduleG1Data.length )
                    {
                        var tempObj = {};
                        var j = i;
                        var count = 0;
                        while(j <irsF5471ScheduleG1Data.length && previous_line_no === irsF5471ScheduleG1Data[j].line_no)
                        {
                            if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "LINE_NO")
                            {
                                tempObj.propertyType = irsF5471ScheduleG1Data[j].PROPERTY_TYPE;
                                tempObj.line_no = irsF5471ScheduleG1Data[j].line_no;
                                tempObj.saveParams = {
                                    "tax_year":irsF5471ScheduleG1Data[j].object_id.split("~")[0],
                                    "trans_type_key" : irsF5471ScheduleG1Data[j].object_id.split("~")[1],
                                    "trans_Details_key" : irsF5471ScheduleG1Data[j].object_id.split("~")[3],
                                    "occurrence" : irsF5471ScheduleG1Data[j].object_id.split("~")[4],
                                    "form_key" : irsF5471ScheduleG1Data[j].object_id.split("~")[6]
                                }
                            }
                            else if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "LINE_DESCRIPTION")
                            {
                                tempObj.line_description = irsF5471ScheduleG1Data[j].attrib_value.split("~")[3];
                            }                            
                            else if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "COMPARABLE_UNCONTROLLED_Y" )
                            {   
                                tempObj.value_comparable = {
                                    "VALUE": irsF5471ScheduleG1Data[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleG1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleG1Data[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleG1Data[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "INCOME_METHOD_Y")
                            {
                                tempObj.value_income = {
                                    "VALUE": irsF5471ScheduleG1Data[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleG1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleG1Data[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleG1Data[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "ACQUISITION_PRICE_METHOD_Y" )
                            {
                                tempObj.value_acquisition = {
                                    "VALUE": irsF5471ScheduleG1Data[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleG1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleG1Data[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleG1Data[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "MARKET_CAPITALIZATION_Y")
                            {
                                tempObj.value_market = {
                                    "VALUE": irsF5471ScheduleG1Data[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleG1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleG1Data[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleG1Data[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "RESIDUAL_PROFIT_Y")
                            {
                                tempObj.value_residual = {
                                    "VALUE": irsF5471ScheduleG1Data[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleG1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleG1Data[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleG1Data[j].PROPERTY_TYPE
                                };
                            }
                            else if(irsF5471ScheduleG1Data[j].attrib_value.split("~")[0] === "UNSPECIFIED_METHODS_Y")
                            {
                                tempObj.value_unspecified = {
                                    "VALUE": irsF5471ScheduleG1Data[j].VALUE,
                                    "line_attrib_key":irsF5471ScheduleG1Data[j].attrib_value.split("~")[1],
                                    "attrib_name": irsF5471ScheduleG1Data[j].attrib_value.split("~")[0],
                                    "property_type": irsF5471ScheduleG1Data[j].PROPERTY_TYPE
                                };
                            }
                            else
                            {
                            	if (count > 0) {
                            		if (count == 1) {
                            			tempObj.attrib_name = [tempObj.attrib_name];
                            			tempObj.value = [tempObj.value];
                            		}
                        			tempObj.attrib_name[count] = irsF5471ScheduleG1Data[j].attrib_value.split("~")[0];
                        			tempObj.value[count] = {
    	                                    "VALUE": irsF5471ScheduleG1Data[j].VALUE,
    	                                    "line_attrib_key": irsF5471ScheduleG1Data[j].attrib_value.split("~")[1]
    	                             }
                            	} else {
	                                tempObj.propertyType = irsF5471ScheduleG1Data[j].PROPERTY_TYPE;
	                                tempObj.isEditableFlag = 'Y';
	                                tempObj.attrib_name = irsF5471ScheduleG1Data[j].attrib_value.split("~")[0];
	                                tempObj.value = {
	                                    "VALUE": tempObj.line_no == 'FormNo'?'Form 1':irsF5471ScheduleG1Data[j].VALUE,
	                                    "line_attrib_key": irsF5471ScheduleG1Data[j].attrib_value.split("~")[1]
	                                }
                            	}
                            }
                            previous_line_no = irsF5471ScheduleG1Data[j].line_no;
                    		if (irsF5471ScheduleG1Data[j].PROPERTY_TYPE == 13 ) {
                    			count++;
                    		}
                            j++;
                        }
                        if(j < irsF5471ScheduleG1Data.length)
                            previous_line_no = irsF5471ScheduleG1Data[j].line_no;
                        var tempIndex = $scope.irsF5471ScheduleG1Data.length;
                        $scope.irsF5471ScheduleG1Data.push(tempObj);
                        console.log("irsF5471ScheduleGDatafinal---------", $scope.irsF5471ScheduleG1Data)                        
                        i = j;
                        
                    }
                    loadF5471ScheduleGLine7Data();
                }
                else
                {
                    if (data.errorMessage) {
                        AlertService.add("",data.errorMessage ,
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    } else {
                        AlertService.add(
                            "",
                            "An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
                            4000);
                        vm.userMessage = "!! Unable to perform Selected Action...";
                    }
                }
                
               
            });
        }
        
        
       
   
   vm.onRadioBtnSelection = function(row, index, value) {
        	if (value === 'Y') {
        		row.value[1].VALUE = null;
        	} else if (value === 'N') {
        		row.value[0].VALUE = null;
        	}
        }
        
        

        vm.formatNumberForDecimal = function(value, attrName) {
            vm.schedule_g[attrName] = parseInt(Number(value));

        }



   vm.checkingForValidation = function (row, index){
        	 if(row.line_no === '5a'){
                if(row.value[1].VALUE === 'N'){
				   var line_5a = $scope.irsF5471ScheduleG1Data.find(x=> x.attrib_name === 'LINE_5B_VALUE_5471_G1')
				   vm.findIndex_1($scope.irsF5471ScheduleG1Data, 'LINE_5B_VALUE_5471_G1','N');
				   vm.findIndex_1($scope.irsF5471ScheduleG1Data, 'UNSPECIFIED_METHODS_N','N')					   
                }
                else{
                    vm.findIndex_1($scope.irsF5471ScheduleG1Data, 'LINE_5B_VALUE_5471_G1','Y');
                    vm.findIndex_1($scope.irsF5471ScheduleG1Data, 'UNSPECIFIED_METHODS_N','Y');
                }
            }
            if(row.line_no === '5c'){
				if (!angular.isDefined(row.value_acquisition.VALUE) ||row.value_acquisition.VALUE == '0'){
						row.value_acquisition.VALUE = null; 
					}
					if (!angular.isDefined(row.value_comparable.VALUE) ||row.value_comparable.VALUE == '0'){
						row.value_comparable.VALUE = null; 
					}
					if (!angular.isDefined(row.value_income.VALUE) ||row.value_income.VALUE == '0'){
						row.value_income.VALUE = null; 
					}
					if (!angular.isDefined(row.value_market.VALUE) ||row.value_market.VALUE == '0'){
						row.value_market.VALUE = null; 
					}
					if (!angular.isDefined(row.value_residual.VALUE) ||row.value_residual.VALUE == '0'){
						row.value_residual.VALUE = null; 
					}
					if (!angular.isDefined(row.value_unspecified.VALUE) ||row.value_unspecified.VALUE == '0'){
						row.value_unspecified.VALUE = null; 
					}

            }          
        };

        vm.formatNumberForDecimal = function(value, attrName) {
            vm.schedule_g[attrName] = parseInt(Number(value));

        }
        
        vm.findIndex_1 = function(data, attribName,value){
            	var findData = data.find(x => x.attrib_name === attribName);
            	if (findData.propertyType == '7'){
            		findData.isEditableFlag = value; 
            		findData.value.VALUE = null;
            	}
            	if(findData.propertyType ==  '11'){
					if (!angular.isDefined(findData.value_acquisition.VALUE) ||findData.value_acquisition.VALUE == '0'){
						findData.value_acquisition.VALUE = null; 
					}
					if (!angular.isDefined(findData.value_comparable.VALUE) ||findData.value_comparable.VALUE == '0'){
						findData.value_comparable.VALUE = null; 
					}
					if (!angular.isDefined(findData.value_income.VALUE) ||findData.value_income.VALUE == '0'){
						findData.value_income.VALUE = null; 
					}
					if (!angular.isDefined(findData.value_market.VALUE) ||findData.value_market.VALUE == '0'){
						findData.value_market.VALUE = null; 
					}
					if (!angular.isDefined(findData.value_residual.VALUE) ||findData.value_residual.VALUE == '0'){
						findData.value_residual.VALUE = null; 
					}
					if (!angular.isDefined(findData.value_unspecified.VALUE) ||findData.value_unspecified.VALUE == '0'){
						findData.value_unspecified.VALUE = null; 
					}
            		findData.isEditableFlag = value;
				}
            	return findData;
      	 }
        

        vm.checkingForValidSave = function (scope) {
			if (vm.save_flag == false){
				vm.isSaveValidFlag = false;
				vm.invalidSaveMessage = "Please select Y as Line 7 value in Schedule G";
			}
            else {
			vm.isSaveValidFlag = true;
            vm.invalidSaveMessage = "Error saving Schedule G data - ";
            vm.line_validation = scope.filter(entry=> entry["line_no"] === '5a');
            vm.line_validation_5c = scope.filter(entry=> entry["line_no"] === '5c');
           
            
            if(vm.line_validation[0].value[0].VALUE === 'Y'){
                if(/*(!angular.isDefined(vm.line_validation_5c[0].value_comparable.VALUE) || vm.line_validation_5c[0].value_comparable.VALUE === null) && (!angular.isDefined(vm.line_validation_5c[0].VALUE)
                    ||vm.line_validation_5c[0].value_acquisition.VALUE === null)  && (!angular.isDefined(vm.line_validation_5c[0].value_income.VALUE) || vm.line_validation_5c[0].value_income.VALUE === null)
                    && (!angular.isDefined(vm.line_validation_5c[0].value_market.VALUE) || vm.line_validation_5c[0].value_market.VALUE === null) && (!angular.isDefined(vm.line_validation_5c[0].value_residual.VALUE) 
                    || vm.line_validation_5c[0].value_residual.VALUE === null)
                    && (!angular.isDefined(vm.line_validation_5c[0].value_unspecified.VALUE) || vm.line_validation_5c[0].value_unspecified.VALUE === null)*/
                    (!angular.isDefined(vm.line_validation_5c[0].value_comparable.VALUE)) && (!angular.isDefined(vm.line_validation_5c[0].VALUE)) && (!angular.isDefined(vm.line_validation_5c[0].value_income.VALUE))
                    && (!angular.isDefined(vm.line_validation_5c[0].value_market.VALUE))&&(!angular.isDefined(vm.line_validation_5c[0].value_residual.VALUE))
                    &&(!angular.isDefined(vm.line_validation_5c[0].value_unspecified.VALUE))){
                    vm.isSaveValidFlag = false;
                    vm.invalidSaveMessage += "Check values for line 5c. "
                }
                else if(vm.line_validation_5c[0].value_comparable.VALUE == '0' && vm.line_validation_5c[0].value_acquisition.VALUE == '0' && vm.line_validation_5c[0].value_income.VALUE == '0'
                    	&& vm.line_validation_5c[0].value_market.VALUE == '0' && vm.line_validation_5c[0].value_residual.VALUE == '0' && vm.line_validation_5c[0].value_unspecified.VALUE == '0'){
                    	vm.isSaveValidFlag = false;
                    	vm.invalidSaveMessage += "Check values for line 5c. "
                }
                else if(vm.line_validation_5c[0].value_comparable.VALUE == null && vm.line_validation_5c[0].value_acquisition.VALUE == null && vm.line_validation_5c[0].value_income.VALUE == null
                    	&& vm.line_validation_5c[0].value_market.VALUE == null && vm.line_validation_5c[0].value_residual.VALUE == null && vm.line_validation_5c[0].value_unspecified.VALUE == null){
                    	vm.isSaveValidFlag = false;
                    	vm.invalidSaveMessage += "Check values for line 5c. "
                }
            }
            return vm.isSaveValidFlag;
            }
        };
        
   
   vm.saveScheduleG1Form = function () {

            var editRow = [];
            var addRow = [];
            var deleteRow = [];
            var scheduleSettings = [{
                "tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                "scenario": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
                "jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
            }];

            /*if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            $scope.crudLoading = true;*/

            vm.checkingForValidSave($scope.irsF5471ScheduleG1Data);

            if($scope.irsF5471ScheduleG1Data.length > 0) {
                var saveObject = [];
                var value = '';
                var temporaryObject = $scope.irsF5471ScheduleG1Data;

                for (var loopIndex = 0; loopIndex < temporaryObject.length; loopIndex++){
                   
                    if(loopIndex === 6 ){
                        for(var key in temporaryObject[loopIndex]){
                            if (!temporaryObject[loopIndex].hasOwnProperty(key)) continue;

                            var obj = temporaryObject[loopIndex][key];
                            if(obj.hasOwnProperty('attrib_name')){
                                if(temporaryObject[loopIndex].line_no === '5c') {
                                    if(obj.VALUE === "0") {
                                        value = '';
                                    } else {
                                        value = obj.VALUE;
                                    }
                                } else {
                                    value = obj.VALUE;
                                }
                                
                                var lineAttribKey = obj.line_attrib_key;
                                var attribName = obj.attrib_name;
                                
                                saveObject = [{
                                    "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                                    "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
                                    "combination_key": vm.rowData.HO_COMBINATION_KEY,
                                    "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                                    "form_key":temporaryObject[loopIndex].saveParams.form_key,
                                    "line_no":temporaryObject[loopIndex].line_no,
                                    "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
                                    "line_attrib_key" : lineAttribKey,
                                    "Attribute_Name": attribName,
                                    "Attribute_Value":value,
                                    "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
                                    "trans_status":"A"
                                }];
                                editRow.push(saveObject);
                            }
                        }
                    }
                    
                    if (temporaryObject[loopIndex].hasOwnProperty('propertyType') &&
                    		temporaryObject[loopIndex].propertyType==13) {
                        for (var k=0; k < temporaryObject[loopIndex].attrib_name.length; k++) {
                                saveObject = [{
                                    "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
                                    "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
                                    "combination_key": vm.rowData.HO_COMBINATION_KEY,
                                    "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
                                    "form_key":temporaryObject[loopIndex].saveParams.form_key,
                                    "line_no":temporaryObject[loopIndex].line_no,
                                    "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
                                    "line_attrib_key" : temporaryObject[loopIndex].value[k].line_attrib_key,
                                    "Attribute_Name": temporaryObject[loopIndex].attrib_name[k],
                                    "Attribute_Value":temporaryObject[loopIndex].value[k].VALUE,
                                    "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
                                    "trans_status":"A"
                                }];
                                editRow.push(saveObject);
                        }
                    	
                    } else {
	                    saveObject = [{
	                        "tax_year": workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
	                        "trans_type_key":temporaryObject[loopIndex].saveParams.trans_type_key,
	                        "combination_key": vm.rowData.HO_COMBINATION_KEY,
	                        "group_obj_key": vm.rowData.GROUP_OBJ_KEY,
	                        "form_key":temporaryObject[loopIndex].saveParams.form_key,
	                        "line_no":temporaryObject[loopIndex].line_no,
	                        "occurence":((temporaryObject[loopIndex].saveParams.occurrence === "0") ? null : temporaryObject[loopIndex].saveParams.occurrence),
	                        "line_attrib_key" : temporaryObject[loopIndex].value.line_attrib_key,
	                        "Attribute_Name": temporaryObject[loopIndex].attrib_name,
	                        "Attribute_Value":temporaryObject[loopIndex].value.VALUE,
	                        "trans_dtls_key" : ((temporaryObject[loopIndex].saveParams.trans_Details_key === "0") ? null : temporaryObject[loopIndex].saveParams.trans_Details_key),
	                        "trans_status":"A"
	                    }];
	                    editRow.push(saveObject);
                    }
                }

            }
            if(vm.isSaveValidFlag){
                var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
                var message = "Schedule G1 details successfully saved";

               

                form5471ScheduleG1GroupObjFactory.saveForm5471ScheduleG1(scheduleSettings,
                    scheduleDetails).then(
                    function(result) {

                        if (result.errorMessage
                            && result.errorMessage !== 'null') {
                            AlertService.add("error", result.errorMessage, 4000);
                            //$scope.crudLoading = false;
                        } else {
                            vm.saveFlag = true;
                            AlertService.add("success", message, 4000);
                           // $scope.crudLoading = false;
                        }
                    });
            }
            else{
                AlertService.add("error", vm.invalidSaveMessage, 6000);
               // $scope.crudLoading = false;
            }
        };

				
				}
				});
	///////////////////////////////////
	
