define([
   'angular'

], function () {
   'use strict';

   var controllers =  angular.module('app.auditCtrl',[])

       .controller('auditListCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','ModalFactory','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','USER_SETTINGS','GENERAL_CONFIG','AuditDataFactory', auditListCtrl])

        function auditListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,USER_SETTINGS,GENERAL_CONFIG, AuditDataFactory) {
       var vm = this;
       vm.showGrid = true;
       vm.name = "auditListCtrl";
       vm.api = $state.current.api.get;
       vm.data = [];
       vm.users = [];
       vm.userSettings = USER_SETTINGS;
       vm.logged_in_user = vm.userSettings.user.sso_id;
       vm.filter = {
    		   audit_type: 1
       };

       vm.dateFilter = {
    		   value_name: "VALUE",
    		   label_name: "LABEL"
       }

       vm.dateFilter1 = {
    		   value_name: "VALUE",
    		   label_name: "LABEL"
       }

        vm.filters = {};
        vm.search;
        vm.filters.user = [];
        vm.filters.user_list = {};
        vm.filters.user_list.selected = [];
        vm.filters.user_list.name = "User(s)"
        vm.filters.user_list.values = [];

       vm.onAuditChange = function(){
    	   vm.showGrid = false;
    	   if(vm.filter.audit_type == 2){
               vm.showGrid = false;
    		   vm.userName = "";
    		   if(!vm.users.length){
    			   getUserNames();
    		   }

    	   }
       }

       vm.onUserChange = function(){
    	   console.log('Userrr', vm.userName);
       }

       var getUserNames = function(){
    	   $scope.$broadcast("showLoader", true);
    	   vm.loading = true;
    	   AuditDataFactory.getAllUserNames().then(function(data){
    		   $scope.$broadcast("showLoader", false);
               vm.users = data.jsonObject;
               _.each(vm.users, function (selected) {
                vm.filters.user_list.values.push({
                    value: selected.SSOID,
                    name: selected.SSOID + '-' + selected.USERNAME
                });
            });
    	   })
       }

       vm.audit_data_types = [
           {label:"Date Range Audit Data" , value: 1 },
           {label:"User Audit Data" , value: 2}
           /*,{label:"Refresh Audit Data REDIS" , value: 3 }*/

       ];




       vm.auditEngine = function(){
           var args = {};
           if(vm.filter.audit_type == 1){
               vm.api = GENERAL_CONFIG.base_url + '/loadGridJson?action_code=wvs4c4&grid_id=225';
               args = {
                       sso_id : vm.logged_in_user,
                      start_dt :  vm.dateFilter1.selected? vm.dateFilter1.selected.VALUE:"",
                      end_dt :  vm.dateFilter.selected? vm.dateFilter.selected.VALUE: ""
               }
           }

           if(vm.filter.audit_type == 2){
               vm.api = GENERAL_CONFIG.base_url + '/loadGridJson?action_code=k9p9st&grid_id=224';
               vm.filters.user_list.selected = [];
               args = {
                       sso_id : vm.logged_in_user,
                       user_sso_id : vm.filters.user[0]
               }
           }

          getData(args);
      };



       var getData = function(params) {
           $scope.$broadcast("showLoader", true);
           vm.loading = true;
           vm.gridData = [];
           vm.gridData.DATA = [];
           vm.showGrid = true;

           GridFactory.getDataCustom(vm.api,params).then(function (data) {
        	   vm.loading = false;
               if (data.callSuccess === "1") {
               	vm.data = data;
                   $scope.$broadcast("dataLoaded", data);
                   $scope.$broadcast("showLoader", false);
               } else {
                   AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
               }
           });
       }

       /*vm.auditEngine();*/



   }
   return controllers;
});