
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.validationRulesServices',[])

        .factory("ValidationRulesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope) {
            var validationRulesFactory = {};
            var totalModulesAssigned = 0;
            
           
            validationRulesFactory.getRulesData = function(data){
            	//?action_id=308&grid_id=86.ge
            	var promise = $http({
                    method: "POST",
                    url: data.url,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            }
            
            validationRulesFactory.getRulesAllData = function(data){
            	var static_json_url = 'app/json/rule_engine_config_sidd.json';
            	var promise = $http({
                    method: "GET",
                    url: static_json_url,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            }
            
            validationRulesFactory.fireRulesOnGridData = function(data){
            	//?action_id=308&grid_id=86.ge
            	var promise = $http({
                    method: "POST",
                    url: data.url,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            }
            return validationRulesFactory;
        }])

    return services;
});