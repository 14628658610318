define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartIII',[])
        .factory("K3PartIIIFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K3PartIIIFactory])
    function K3PartIIIFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K3PartIIIFactory.getCountryList = function(taxYear, sso_id) {
            let countryList;
            let  params = {
                "action_code" : 'hs9y95', "tax_year" : taxYear, "sso_id": sso_id
            };

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(response) {
                    countryList = response.jsonObject;
                    console.log('countryList = ', countryList);
                    return countryList;
                }
            );

            return promise;

        }

        K3PartIIIFactory.getTaxTypes = function(taxYear) {
            let taxTypes;
            let  params = {
                "action_code" : 'x4ulzf', "tax_year" : taxYear
            };

            let promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(
                function(response) {
                    taxTypes = response.jsonObject;
                    console.log('taxTypes = ', taxTypes);
                    return taxTypes;
                }
            );

            return promise;
        }

        K3PartIIIFactory.renderSK3PartIIIDetails = function(ctrl) {

            let colIndex = 0;
            let countryCode;
            ctrl.part_III_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:'N/A', ATTRIB_NAME: '1065_K3_III_N/A'});
            ctrl.part_III_s1_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:'1', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_III_1'});

            ctrl.part_III_S1_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:1, ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_III_1'});

            ctrl.part_III_S1_1065SK3Data_line1 = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:1, ROW_TYPE: 'CH', LINE_NO: '1'});

            for(let x = 0; x < ctrl.part_III_S1_1065SK3Data_line1.length; x++) {
                if('ATTRIB_ACCT_REFERENCE_KEY' in ctrl.part_III_S1_1065SK3Data_line1[x]) {
                    if(ctrl.part_III_S1_1065SK3Data_line1[x].ATTRIB_ACCT_REFERENCE_KEY != null)
                        ctrl.part_III_S1_1065SK3Data_line1[x]['IS_CHANGED_FLAG'] = 'Y';
                }
            }
            ctrl.part_III_S1_line1_1065SK3DataTransposed = transpose_part_III_Section(ctrl.part_III_S1_1065SK3Data_line1);

            ctrl.part_III_S1_1065SK3Data_line2 = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:1, ROW_TYPE: 'CH', LINE_NO: '2'});
            ctrl.part_III_S1_1065SK3Data_line2Heading = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:1, ROW_TYPE: 'DT', LINE_NO: '2', IS_MULTI:'N'});
            ctrl.part_III_S1_line2_1065SK3DataTransposed = transpose_part_III_Section(ctrl.part_III_S1_1065SK3Data_line2);

            ctrl.part_III_s2_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:'2', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_III_2'});
            ctrl.part_III_S2_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME: '2', ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_III_2'});

            for(let x = 0; x < ctrl.part_III_S2_1065SK3Data.length; x++) {
                if('ATTRIB_ACCT_REFERENCE_KEY' in ctrl.part_III_S2_1065SK3Data[x]) {
                    if (ctrl.part_III_S2_1065SK3Data[x].ATTRIB_ACCT_REFERENCE_KEY != null)
                        ctrl.part_III_S2_1065SK3Data[x]['IS_CHANGED_FLAG'] = 'Y';
                }
            }
            ctrl.part_III_S2_1065SK3DataTransposed = transpose_part_III_Section(ctrl.part_III_S2_1065SK3Data);

            ctrl.part_III_s3_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:'3', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_III_3'});
            ctrl.part_III_S3_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:3, ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_III_3'});

            for(let x = 0; x < ctrl.part_III_S3_1065SK3Data.length; x++) {
                if('ATTRIB_ACCT_REFERENCE_KEY' in ctrl.part_III_S3_1065SK3Data[x]) {
                    if (ctrl.part_III_S3_1065SK3Data[x].ATTRIB_ACCT_REFERENCE_KEY != null)
                        ctrl.part_III_S3_1065SK3Data[x]['IS_CHANGED_FLAG'] = 'Y';
                }
            }
            ctrl.part_III_S3_1065SK3DataTransposed = transpose_part_III_Section(ctrl.part_III_S3_1065SK3Data);


            ctrl.part_III_s4_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:'4', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_III_4'});
            ctrl.part_III_S4_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:4, ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_III_4'});
            for(let x = 0; x < ctrl.part_III_S4_1065SK3Data.length; x++) {
                if('ATTRIB_ACCT_REFERENCE_KEY' in ctrl.part_III_S4_1065SK3Data[x]) {
                    if (ctrl.part_III_S4_1065SK3Data[x].ATTRIB_ACCT_REFERENCE_KEY != null)
                        if(ctrl.part_III_S4_1065SK3Data[x].DATA_TYPE === 'dateVarious') {
                            ctrl.part_III_S4_1065SK3Data[x]['calendar'] = {opened:false};
                            if(ctrl.part_III_S4_1065SK3Data[x].ATTRIBUTE_VALUE != null) {
                                ctrl.part_III_S4_1065SK3Data[x].ATTRIBUTE_VALUE = new Date(ctrl.part_III_S4_1065SK3Data[x].ATTRIBUTE_VALUE)
                            }
                            else {
                                ctrl.part_III_S4_1065SK3Data[x].ATTRIBUTE_VALUE = new Date();
                            }
                        }
                }
            }
            ctrl.part_III_S4_1065SK3DataTransposed = transpose_part_III_Section(ctrl.part_III_S4_1065SK3Data);


            ctrl.part_III_s5_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:'5', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_III_5'});
            ctrl.part_III_S5_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:5, ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_III_5'});

            for(let x = 0; x < ctrl.part_III_S5_1065SK3Data.length; x++) {
                if('ATTRIB_ACCT_REFERENCE_KEY' in ctrl.part_III_S5_1065SK3Data[x]) {
                    if (ctrl.part_III_S5_1065SK3Data[x].ATTRIB_ACCT_REFERENCE_KEY != null)
                        ctrl.part_III_S5_1065SK3Data[x]['IS_CHANGED_FLAG'] = 'Y';
                }
            }

            ctrl.part_III_S5_1065SK3DataTransposed = transpose_part_III_Section(ctrl.part_III_S5_1065SK3Data);


            let other_description = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'III', SECTION_NAME:1, ROW_TYPE: 'CH', LINE_NO: '1', ATTRIB_NAME: '1065_K3_III_1_1_e'})[0].ATTRIB_DESCRIPTION;
            let array = other_description.split(' ');
            ctrl.other = array[0];
            ctrl.other_p1 = array[1].split('_')[0] + ' ' + array[1].split('_')[1];
            ctrl.other_p2 = array[2].split('_')[0] + ' ' + array[2].split('_')[1];

            ctrl.countries = $filter("filter")($filter("filter") (ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ,{ATTRIB_NAME: '1065_K3_III_1_1_e'}).length;

            ctrl.wrapCols = 4 + ( $filter("filter") (ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 8);
            ctrl.wraplength = 195 * 3 + 195 * (ctrl.countries);

            ctrl.subRowLength = 163 + ctrl.wraplength + 165 * 2;


            ctrl.wrapColsS3 = 3 + ( $filter("filter") (ctrl.part_III_S3_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 7);
            ctrl.wraplengthS3 = 195 * 2 + 195 * (ctrl.countries);

            ctrl.wrapColsS5 = 5 + ( $filter("filter") (ctrl.part_III_S5_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 9);
            ctrl.wraplengthS5 = 160 + 163 * 3 + 163 * (ctrl.countries);


            for(let a = 0; a < ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data[0].Line_data_occ.length; a++) {
                if(a >= 5) {
                    if('COUNTRY_CODE' in ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[a]) {
                        countryCode = ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[a].COUNTRY_CODE;
                        ctrl.countryArray.splice(colIndex, 1, countryCode);
                        ctrl.part_III_S2_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[5+colIndex]['COUNTRY_CODE'] = countryCode;
                        ctrl.part_III_S3_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[4+colIndex]['COUNTRY_CODE'] = countryCode;
                        ctrl.part_III_S5_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[6+colIndex]['COUNTRY_CODE'] = countryCode;
                        colIndex = colIndex + 1;
                    }
                }
            }

            return ctrl;
        }

        K3PartIIIFactory.setCountry = function(ctrl, colIndex, countryCode) {
            console.log('colIndex = ' , colIndex + ' ' + ' countryCode = ' , countryCode);
            ctrl.countryArray.splice(colIndex, 1, countryCode);
            console.log('countryArray = ', ctrl.countryArray);

            ctrl.part_III_S2_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[5+colIndex].COUNTRY_CODE = countryCode;
            ctrl.part_III_S3_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[4+colIndex].COUNTRY_CODE = countryCode;
            ctrl.part_III_S5_1065SK3DataTransposed[0].Line_data[0].Line_data_occ[6+colIndex].COUNTRY_CODE = countryCode;


            return ctrl;
        }

        function transpose_part_III_Section(sectionData) {
            let transposedSectionData = [];

            let o = sectionData.reduce((a, b) => {
                a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
                a[b.LINE_ORDER].push(b);
                return a;
            }, {});
            let a = Object.keys(o).map(function (k) {
                return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], DELETE_COLS: [],Line_data: o[k]};
            });
            transposedSectionData = a;

            let c = [];

            for (let i = 0; i < transposedSectionData.length; i++) {

                let z = transposedSectionData[i].Line_data.reduce( (a,b) => {
                    a[b.ROW_OCCURRENCE] = a[b.ROW_OCCURRENCE] || [];
                    a[b.ROW_OCCURRENCE].push(b);
                    return a;
                }, {});

                let y = Object.keys(z).map(function (k) {
                    return { Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED: "N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Row_Occurrence: /*row_occ*/ z[k][0].ROW_OCCURRENCE, Line_data_occ: z[k]};
                });

                if (i == 0) {


                    let m = angular.copy(y);


                    c = angular.copy(y[0]);
                    c.Line_Type = "HEADER_ROW";
                    c.Row_Occurrence = "";
                    c.Trans_details_key = "";
                    c.IS_EDITABLE = "Y";
                    for (var j=0; j < c.Line_data_occ.length; j++){
                        c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
                    }
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
                    }
                    else {
                        y.unshift(c);
                    }


                }
                else {
                    let key = Object.keys(z)[0];
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift({Line_Type: "SUB_ROW", Line_no: z[key][0].LINE_NO, Line_data_occ: []});
                    }
                }

                transposedSectionData[i].Line_data = angular.copy(y);

            }

            console.log('transposedSectionData = ' , transposedSectionData);

            return transposedSectionData;
        }

        K3PartIIIFactory.addRowOccurrenceP3 = function(ctrl, line_no, transposedSectionData){

            let row_occurrence;

            for(let i = 0; i < transposedSectionData.length; i++) {
                if(transposedSectionData[i].ROW_TYPE == 'CH') {
                    row_occurrence = transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length -1].Row_Occurrence + 1;
                }

                let new_row = angular.copy(transposedSectionData[i].Line_data[transposedSectionData[i].Line_data.length - 1]);
                new_row.Line_Type = "DATA_ROW";
                new_row.Row_Occurrence = row_occurrence;
                new_row.IS_EDITTED = "Y";

                if(new_row.Line_no == line_no){

                    for (let j = 0; j < new_row.Line_data_occ.length; j++){
                        if ( (new_row.Line_data_occ[j].EDIT_TYPE === "currency" || new_row.Line_data_occ[j].EDIT_TYPE === "label") && new_row.Line_data_occ[j].DATA_TYPE === "number"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = 0;
                        } else if (new_row.Line_data_occ[j].EDIT_TYPE === "text" && new_row.Line_data_occ[j].DATA_TYPE === "string"){
                            new_row.Line_data_occ[j].ATTRIBUTE_VALUE = null;
                        }
                        new_row.Line_data_occ[j].ROW_OCCURRENCE = row_occurrence;
                        new_row.Line_data_occ[j].COL_OCCURRENCE = 1;
                        //new_row.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
                    }
                    transposedSectionData[i].Line_data.push(new_row);
                }

            }


            /*if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '1' ) {
                if(transposedSectionData[0].Line_data[0].Line_data_occ[0].LINE_NO === '1') {
                    ctrl.part_III_S1_line1_1065SK3DataTransposed =  transposedSectionData;
                }
                else if(transposedSectionData[0].Line_data[0].Line_data_occ[0].LINE_NO === '2A') {
                    ctrl.part_III_S1_line2_1065SK3DataTransposed = transposedSectionData;
                }
            }
            else if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '4') {
                ctrl.part_III_S4_1065SK3DataTransposed = transposedSectionData;
            }*/

            console.log('transposedSectionData = ' , transposedSectionData);

            return ctrl;
        }

        K3PartIIIFactory.removeRowOccurrenceP3 = function(ctrl, line_no, Occurrence, index, transposedSectionData) {

            console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);

            for (var i=0; i < transposedSectionData.length; i++){
                if(transposedSectionData[i].ROW_TYPE == 'CH'){
                    for(let j=0; j < transposedSectionData[i].Line_data.length; j++){
                        if(j == index){
                            if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && null != transposedSectionData[i].Line_data[j].Row_Occurrence && transposedSectionData[i].Line_data[j].Row_Occurrence != "" && transposedSectionData[i].Line_data[j].Row_Occurrence == Occurrence){

                                if(transposedSectionData[i].Line_data[j].IS_EDITTED == 'N') {
                                    transposedSectionData[i].DELETE_ROWS.push(transposedSectionData[i].Line_data[j]);
                                }
                                transposedSectionData[i].Line_data.splice(j,1);
                            }else if((transposedSectionData[i].Line_data[j].Line_Type == "DATA_ROW" || transposedSectionData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && transposedSectionData[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
                                transposedSectionData[i].Line_data.splice(j,1);
                            }
                        }
                    }
                }
            }

            /*if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '1' ) {
                if(transposedSectionData[0].Line_data[0].Line_data_occ[0].LINE_NO === '1') {
                    ctrl.part_III_S1_line1_1065SK3DataTransposed =  transposedSectionData;
                }
                else if(transposedSectionData[0].Line_data[0].Line_data_occ[0].LINE_NO === '2A') {
                    ctrl.part_III_S1_line2_1065SK3DataTransposed = transposedSectionData;
                }
            }
            else if(transposedSectionData[0].Line_data[0].Line_data_occ[0].SECTION_NAME === '4') {
                ctrl.part_III_S4_1065SK3DataTransposed = transposedSectionData;
            }*/


            return ctrl;
        }

        K3PartIIIFactory.addCountryColumnP3 = function(ctrl,line_no, transposedSectionData) {

            /* --------------------------------------------------section 1 --------------------------------------------------*/
            let section = '1';
            let country_col = 'e';
            addCountryColumnP3BySection(ctrl,section, line_no, transposedSectionData);

            ctrl.countries = $filter("filter")($filter("filter") (ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ,{/*ATTRIB_NAME: '1065_K3_III_1_1_e'*/'ATTRIB_COL': country_col}).length;

            ctrl.wrapCols = 4 + ( $filter("filter") (ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 8);
            ctrl.wraplength = 195 * 3 + 195 * (ctrl.countries);

            ctrl.subRowLength = 163 + ctrl.wraplength + 165 * 2;

            /* --------------------------------------------------section 2 --------------------------------------------------*/
            section = '2';
            country_col = 'e';
            addCountryColumnP3BySection(ctrl,section, line_no, ctrl.part_III_S2_1065SK3DataTransposed);

            ctrl.countries = $filter("filter")($filter("filter") (ctrl.part_III_S2_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ,{/*ATTRIB_NAME: '1065_K3_III_1_1_e'*/'ATTRIB_COL': country_col}).length;

            ctrl.wrapCols = 4 + ( $filter("filter") (ctrl.part_III_S2_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 8);
            ctrl.wraplength = 195 * 3 + 195 * (ctrl.countries);

            ctrl.subRowLength = 163 + ctrl.wraplength + 165 * 2;

            /* -------------------------------------------------- section 3 -------------------------------------------------- */
            section = '3';
            country_col = 'd';
            addCountryColumnP3BySection(ctrl,section, line_no, ctrl.part_III_S3_1065SK3DataTransposed);

            ctrl.wrapColsS3 = 3 + ( $filter("filter") (ctrl.part_III_S3_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 7);
            ctrl.wraplengthS3 = 195 * 2 + 195 * (ctrl.countries);

            /* -------------------------------------------------- section 5 --------------------------------------------------*/
            section = '5';
            country_col = 'f';
            addCountryColumnP3BySection(ctrl,section, line_no, ctrl.part_III_S5_1065SK3DataTransposed);

            ctrl.wrapColsS5 = 5 + ( $filter("filter") (ctrl.part_III_S5_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 9);
            ctrl.wraplengthS5 = 160 + 163 * 3 + 163 * (ctrl.countries);

            return ctrl;
        }

        function addCountryColumnP3BySection(ctrl,section, line_no, transposedSectionData) {

            console.log(' addCountryColumnP3 : ', line_no, transposedSectionData);
            let headerRow;
            let headerRows;
            let dataRow;
            let dataRows;
            let colCountb4Country;
            let countryColObjs;
            let countryColObj;
            let countryColObjClone;
            let country_col;

            if(section === '1' || section === '2') {
                country_col = 'e';
            }
            else if(section === '3') {
                country_col = 'd';
            }
            else if(section === '5') {
                country_col = 'f';
            }

            // ------------------------- Add cloned country column in header row ----------------------------------------------
            for(let x = 0; x < transposedSectionData.length; x++) {
                let line = transposedSectionData[x];
                headerRows = $filter("filter")(transposedSectionData[x].Line_data,{Line_Type:'HEADER_ROW'});
                headerRow = headerRows[0];
                let lineNo = headerRow.Line_no;
                if(line_no == lineNo) {
                    break;
                }
            }
            let countryColObjsH = $filter("filter")(headerRow.Line_data_occ,{/*ATTRIB_NAME: '1065_K3_III_1_1_e'*/'ATTRIB_COL':country_col});
            let countryColObjH = countryColObjsH[0];
            let countryColObjHClone = angular.copy(countryColObjH);
            countryColObjHClone.COL_OCCURRENCE = countryColObjsH.length + 1;
            countryColObjHClone.COUNTRY_CODE = null;


            if(country_col === 'd') {
                colCountb4Country = 3;
            }
            else if(country_col === 'e') {
                colCountb4Country = 4;
            }
            else if(country_col === 'f') {
                colCountb4Country = 5;
            }

            let positionToAddCountry = colCountb4Country + countryColObjsH.length + 1;

            headerRows = $filter("filter")(transposedSectionData[0].Line_data,{Line_Type:'HEADER_ROW'});
            headerRow = headerRows[0];
            headerRow.Line_data_occ.splice(positionToAddCountry, 0, countryColObjHClone);
            console.log('headerRow after adding a country col ', headerRow);


            //-------------------------------- Add cloned country column for each DATA_ROW ----------------------------
            for(let x = 0; x < transposedSectionData.length; x++) {
                dataRows = $filter("filter")(transposedSectionData[x].Line_data,{Line_Type:'DATA_ROW'});
                for(let y = 0; y < dataRows.length; y++) {
                    dataRow = dataRows[y];

                    countryColObjs = $filter("filter")(dataRow.Line_data_occ,{/*ATTRIB_NAME: '1065_K3_III_1_1_e'*/ 'ATTRIB_COL':country_col});
                    countryColObj = countryColObjs[0];
                    countryColObjClone = angular.copy(countryColObj);
                    countryColObjClone.COL_OCCURRENCE = countryColObjs.length + 1;
                    countryColObjClone.ATTRIBUTE_VALUE = 0;
                    countryColObjClone.COUNTRY_CODE = null;

                    dataRow.Line_data_occ.splice(positionToAddCountry, 0, countryColObjClone);
                    console.log('dataRow after adding a country col ', dataRow);
                }
            }
            if(section === '1') {
                ctrl.part_III_S1_line1_1065SK3DataTransposed = transposedSectionData;
            }
            else if(section === '2') {
                ctrl.part_III_S2_1065SK3DataTransposed = transposedSectionData;
            }
            else if(section === '3') {
                ctrl.part_III_S3_1065SK3DataTransposed = transposedSectionData;
            }
            else if('section' === '5') {
                ctrl.part_III_S5_1065SK3DataTransposed = transposedSectionData;
            }
            //return transposedSectionData;
        }

        K3PartIIIFactory.removeCountryColumnP3 = function(ctrl,line_no, col_occurrence, col_index, transposedSectionData) {

            /* ----------------------------------------------------- section 1 ----------------------------------------------------- */
            let section = '1';
            let country_col = 'e';
            removeCountryColumnP3BySection(ctrl,section, line_no, country_col, col_occurrence, col_index, ctrl.part_III_S1_line1_1065SK3DataTransposed);
            ctrl.countries = $filter("filter")($filter("filter") (ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ,{'ATTRIB_COL': country_col}).length;
            ctrl.wrapCols = 4 + ( $filter("filter") (ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 8);
            ctrl.wraplength = 195 * 3 + 195 * (ctrl.countries);
            ctrl.subRowLength = 163 + ctrl.wraplength + 165 * 2;
            reEvaluateFormulaForTotalLinesP3(ctrl, section, ctrl.part_III_S1_line1_1065SK3DataTransposed);

            /* ----------------------------------------------------- section 2 ----------------------------------------------------- */
            section = '2';
            country_col = 'e';
            removeCountryColumnP3BySection(ctrl,section, line_no, country_col, col_occurrence, col_index, ctrl.part_III_S2_1065SK3DataTransposed);
            ctrl.wrapCols = 4 + ( $filter("filter") (ctrl.part_III_S2_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 8);
            ctrl.wraplength = 195 * 3 + 195 * (ctrl.countries);
            reEvaluateFormulaForTotalLinesP3(ctrl, section, ctrl.part_III_S2_1065SK3DataTransposed);

            /* ----------------------------------------------------- section 3 ----------------------------------------------------- */
            section = '3';
            country_col = 'd';
            removeCountryColumnP3BySection(ctrl,section, line_no, country_col, col_occurrence, col_index, ctrl.part_III_S3_1065SK3DataTransposed);
            ctrl.wrapColsS3 = 3 + ( $filter("filter") (ctrl.part_III_S3_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 7);
            ctrl.wraplengthS3 = 195 * 2 + 195 * (ctrl.countries);
            reEvaluateFormulaForTotalLinesP3(ctrl, section, ctrl.part_III_S3_1065SK3DataTransposed);

            /* ----------------------------------------------------- section 5 ----------------------------------------------------- */
            section = '5';
            country_col = 'f';
            removeCountryColumnP3BySection(ctrl,section, line_no, country_col, col_occurrence, col_index, ctrl.part_III_S5_1065SK3DataTransposed);
            ctrl.wrapColsS5 = 5 + ( $filter("filter") (ctrl.part_III_S5_1065SK3DataTransposed[0].Line_data, {Line_Type:'DATA_ROW'})[0].Line_data_occ.length - 9);
            ctrl.wraplengthS5 = 160 + 163 * 3 + 163 * (ctrl.countries);
            reEvaluateFormulaForTotalLinesP3(ctrl, section, ctrl.part_III_S5_1065SK3DataTransposed);

            if(ctrl.countryArray.length > 1) {
                ctrl.countryArray.splice(col_index, 1);
            }

            return ctrl;
        }

        function removeCountryColumnP3BySection(ctrl,section, line_no, country_col, col_occurrence, col_index, transposedSectionData) {

            let headerRow;
            let dataRow;
            let dataRows;
            let colCountb4Country;

            if(section === '1' || section === '2') {
                colCountb4Country = 5;
            }
            else if(section === '3') {
                colCountb4Country = 4;
            }
            if(section === '5') {
                colCountb4Country = 6;
            }

            console.log(' removeCountryColumnP3 : ', line_no, col_occurrence, col_index, transposedSectionData);

            // -- Remove country column from header row
            let line = transposedSectionData[0];
            headerRow = $filter("filter")(transposedSectionData[0].Line_data,{Line_Type:'HEADER_ROW'})[0];

            console.log('headerRow b4 delete a country col =',headerRow);
            headerRow.Line_data_occ.splice( /*5*/ colCountb4Country + col_index,1);
            console.log('headerRow after delete a country col=',headerRow);

            // -- Remove country column from each data row
            for(let x = 0; x < transposedSectionData.length; x++) {
                dataRows = $filter("filter")(transposedSectionData[x].Line_data,{Line_Type:'DATA_ROW'});
                for(let y = 0; y < dataRows.length; y++) {
                    dataRow = dataRows[y];
                    console.log('dataRow b4 removing a country col ', dataRow);
                    //dataRow['DELETE_COLS'] = dataRow.Line_data_occ[colCountb4Country + col_index];
                    transposedSectionData[x]['DELETE_COLS'].push(dataRow.Line_data_occ[colCountb4Country + col_index]);
                    dataRow.Line_data_occ.splice( /*5*/ colCountb4Country + col_index,1);
                    console.log('dataRow after removing a country col ', dataRow);
                }
            }

            if(section === '1') {
                ctrl.part_III_S1_line1_1065SK3DataTransposed = transposedSectionData;
            }
            else if(section === '2') {
                ctrl.part_III_S2_1065SK3DataTransposed = transposedSectionData;
            }
            else if(section === '3') {
                ctrl.part_III_S3_1065SK3DataTransposed = transposedSectionData;
            }
            else if('section' === '5') {
                ctrl.part_III_S5_1065SK3DataTransposed = transposedSectionData;
            }

        }

        K3PartIIIFactory.calcAmtSK3P3 = function(ctrl,formulaObj, cell_name, cell_value, row, col) {

            console.log('in calcAmtSK3');
            console.log('cell_name = ', cell_name);
            console.log('cell_value = ', cell_value);

            let lineAmt = 0;
            let lineFormulaArray;
            let formulaOperandText;
            let targetCellObj;
            let formula;
            let matches;
            let originalValue;

            // find the related formula for the cell whose value is changed
            let formulaObjs = $filter("filter")(ctrl.allFormFormulaListSK3, {ATTRIB_CALC_FORMULA:'('+cell_name+')'});

            for(let k = 0; k < formulaObjs.length; k++) {
                lineAmt = 0;
                formula = formulaObjs[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }

                for(let i = 0; i<lineFormulaArray.length; i++) {
                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();
                    console.log('formulaOperandText = ', formulaOperandText);

                    if(k == 0) {

                        if($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText}).length > 1) {
                            lineAmt = 0;
                            for(let x = 0; x < $filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText}).length; x++) {

                                lineAmt = lineAmt + parseFloat($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText})[x].ATTRIBUTE_VALUE);
                            }

                        }
                        else
                            lineAmt = parseFloat($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText})[0].ATTRIBUTE_VALUE);
                    }


                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");

                    //console.log('In-step Formula (value replaced) : ' , formula);
                }
                //console.log('Final Formula (value replaced) : ' , formula);

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);
                    //console.log('formula_cell_name = ', formulaObjs[k].ATTRIB_NAME , ' , formula_evaluated_value = ', xyz);
                    if(k == 0) {
                        targetCellObj = $filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaObjs[k].ATTRIB_NAME})[0];

                        originalValue = targetCellObj.ATTRIBUTE_VALUE;
                        if(originalValue != xyz) {
                            ctrl.setChange(targetCellObj,row);
                        }
                        targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();

                    }

                }
            }

            return ctrl;

        }

        function reEvaluateFormulaForTotalLinesP3(ctrl, section, transposedSectionData) {

            let dataRow;
            let formulaObj;
            let formula;
            let lineFormulaArray;
            let formulaOperandText;
            let lineAmt = 0;
            let targetCellObj;
            let originalValue;
            let matches;

            for(let l = 0; l < transposedSectionData.length; l++) {

                let line = transposedSectionData[l];
                let dataRows = $filter("filter")(line.Line_data, {Line_Type: 'DATA_ROW'});

                for (let x = 0; x < dataRows.length; x++) {

                    dataRow = dataRows[x];


                    formulaObj = $filter("filter")(ctrl.allFormFormulaListSK3, {PART_NO: 'III', SECTION_NAME: section, LINE_NO: dataRow.Line_no})[0];

                    if(formulaObj != undefined) {

                        if('ATTRIB_CALC_FORMULA' in formulaObj)  {

                            formula = formulaObj.ATTRIB_CALC_FORMULA;
                            matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                            console.log('------- formula ----------', formula);

                            if (matches == null) {
                                lineFormulaArray = formula.split(/[+-/\\*\\]/);
                            }

                            for (let i = 0; i < lineFormulaArray.length; i++) {

                                lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                                formulaOperandText = lineFormulaArray[i].trim();
                                console.log('formulaOperandText = ', formulaOperandText);

                                if ($filter("filter")(dataRow.Line_data_occ, {ATTRIB_NAME: formulaOperandText}).length > 1) {
                                    lineAmt = 0;
                                    for (let x = 0; x < $filter("filter")(dataRow.Line_data_occ, {ATTRIB_NAME: formulaOperandText}).length; x++) {

                                        lineAmt = lineAmt + parseFloat($filter("filter")(dataRow.Line_data_occ, {ATTRIB_NAME: formulaOperandText})[x].ATTRIBUTE_VALUE);
                                    }
                                } else
                                    lineAmt = parseFloat($filter("filter")(dataRow.Line_data_occ, {ATTRIB_NAME: formulaOperandText})[0].ATTRIBUTE_VALUE);

                                if (parseFloat(lineAmt) >= 0) {
                                    formula = formula.replace("(" + lineFormulaArray[i].trim() + ")", lineAmt);
                                } else
                                    formula = formula.replace("(" + lineFormulaArray[i].trim() + ")", "(" + lineAmt + ")");

                            }

                            if (null != eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself

                                let xyz = eval(formula);
                                targetCellObj = $filter("filter")(dataRow.Line_data_occ, {ATTRIB_NAME: formulaObj.ATTRIB_NAME})[0];

                                originalValue = targetCellObj.ATTRIBUTE_VALUE;
                                if (originalValue != xyz) {
                                    ctrl.setChange(targetCellObj, dataRow);
                                }
                                targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();
                            }
                        }
                    }

                }
            }

            if(section === '1') {
                ctrl.part_III_S1_line1_1065SK3DataTransposed = transposedSectionData;
            }
            else if(section === '2') {
                ctrl.part_III_S2_1065SK3DataTransposed = transposedSectionData;
            }
            else if(section === '3') {
                ctrl.part_III_S3_1065SK3DataTransposed = transposedSectionData;
            }
            else if('section' === '5') {
                ctrl.part_III_S5_1065SK3DataTransposed = transposedSectionData;
            }

        }

        K3PartIIIFactory.obtainChanges = function(ctrl) {

            let a;
            let b;
            let c;
            let cells = [];
            let p;
            let countryCode;
            let countryKey;
            let countryOcc = 0;


            ctrl.partIIIS1L1Changes = [];
            ctrl.partIIIS1L2Changes = [];
            ctrl.partIIIS2Changes = [];
            ctrl.partIIIS3Changes = [];
            ctrl.partIIIS4Changes = [];
            ctrl.partIIIS5Changes = [];

            console.log('ctrl.saveData = ' , ctrl.saveData);

            if(ctrl.saveData.length > 0) {

                let part3Data = _.filter(ctrl.saveData, {PART_NO: "III"});
                if(part3Data !== undefined && part3Data.length > 0) {
                    ctrl.saveData = ctrl.saveData.filter(element => element.PART_NO === "III");
                }

            }


            if(ctrl.countryArray.length > 0 || ctrl.checkValueEnteredInSection(ctrl.part_III_S1_line1_1065SK3DataTransposed)) {
                //-------------------- section 1 line 1 -------------------------------
                for (let x = 0; x < ctrl.part_III_S1_line1_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_III_S1_line1_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type: 'DATA_ROW'});
                    for (let y = 0; y < b.length; y++) {
                        countryOcc = 0;
                        for (let z = 0; z < b[y].Line_data_occ.length; z++) {
                            if (b[y].Line_data_occ[z].ATTRIB_COL === 'e') {
                                countryKey = null;
                                countryCode = ctrl.countryArray[countryOcc];

                                for (let t = 0; t < ctrl.countryList.length; t++) {
                                    if (ctrl.countryList[t].CODE == countryCode) {
                                        countryKey = ctrl.countryList[t].KEY;
                                        break;
                                    }
                                }
                                b[y].Line_data_occ[z].COUNTRY_KEY = countryKey;
                                b[y].Line_data_occ[z].COUNTRY_CODE = countryCode;
                                countryOcc = countryOcc + 1;
                                b[y].Line_data_occ[z].COL_OCCURRENCE = countryOcc;
                            }
                        }
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG: 'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIIIS1L1Changes.push(c);
                        }
                    }
                }

                //handle delete of row occurrence
                for (let x = 0; x < ctrl.part_III_S1_line1_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_III_S1_line1_1065SK3DataTransposed[x];
                    if (p.DELETE_ROWS.length > 0) {
                        for (let d = 0; d < p.DELETE_ROWS.length; d++) {
                            let lineData = p.DELETE_ROWS[d];
                            for (let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                            }
                            ctrl.partIIIS1L1Changes.push(lineData.Line_data_occ);
                        }
                    }
                }

                //handle delete of col occurrence
                for (let x = 0; x < ctrl.part_III_S1_line1_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_III_S1_line1_1065SK3DataTransposed[x];

                    if ('DELETE_COLS' in p) {
                        if (p.DELETE_COLS.length > 0) {
                            for (let d = 0; d < p.DELETE_COLS.length; d++) {
                                let lineData = p.DELETE_COLS[d];
                                lineData.IS_CHANGED_FLAG = 'D';
                                ctrl.partIIIS1L1Changes.push(lineData);
                            }
                        }
                    }
                }

            }

            //-------------------- section 1 line 2 -------------------------------
            if(ctrl.checkValueEnteredInSection(ctrl.part_III_S1_line2_1065SK3DataTransposed)) {

                for(let x = 0; x < ctrl.part_III_S1_line2_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_III_S1_line2_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIIIS1L2Changes.push(c);
                        }
                    }
                }


                //handle delete of row occurrence
                for(let x = 0; x < ctrl.part_III_S1_line2_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_III_S1_line2_1065SK3DataTransposed[x];
                    if(p.DELETE_ROWS.length > 0) {
                        for(let d = 0; d < p.DELETE_ROWS.length; d++) {
                            let lineData = p.DELETE_ROWS[d];
                            for(let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                            }
                            ctrl.partIIIS1L2Changes.push(lineData.Line_data_occ);
                        }
                    }
                }
            }



            if(ctrl.countryArray.length > 0 || ctrl.checkValueEnteredInSection(ctrl.part_III_S2_1065SK3DataTransposed)) {

                //-------------------- section 2 -------------------------------
                for (let x = 0; x < ctrl.part_III_S2_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_III_S2_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type: 'DATA_ROW'});
                    for (let y = 0; y < b.length; y++) {
                        countryOcc = 0;
                        for (let z = 0; z < b[y].Line_data_occ.length; z++) {
                            if (b[y].Line_data_occ[z].ATTRIB_COL === 'e') {
                                countryKey = null;
                                countryCode = ctrl.countryArray[countryOcc];

                                for (let t = 0; t < ctrl.countryList.length; t++) {
                                    if (ctrl.countryList[t].CODE == countryCode) {
                                        countryKey = ctrl.countryList[t].KEY;
                                        break;
                                    }
                                }
                                b[y].Line_data_occ[z].COUNTRY_KEY = countryKey;
                                b[y].Line_data_occ[z].COUNTRY_CODE = countryCode;
                                countryOcc = countryOcc + 1;
                                b[y].Line_data_occ[z].COL_OCCURRENCE = countryOcc;
                            }
                        }

                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG: 'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIIIS2Changes.push(c);
                        }
                    }
                }

                //handle delete of col occurrence
                for (let x = 0; x < ctrl.part_III_S2_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_III_S2_1065SK3DataTransposed[x];

                    if ('DELETE_COLS' in p) {
                        if (p.DELETE_COLS.length > 0) {
                            for (let d = 0; d < p.DELETE_COLS.length; d++) {
                                let lineData = p.DELETE_COLS[d];
                                lineData.IS_CHANGED_FLAG = 'D';
                                ctrl.partIIIS2Changes.push(lineData);
                            }
                        }
                    }

                }

            }
            if(ctrl.countryArray.length > 0 || ctrl.checkValueEnteredInSection(ctrl.part_III_S3_1065SK3DataTransposed)) {
            //-------------------- section 3 -------------------------------
                for(let x = 0; x < ctrl.part_III_S3_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_III_S3_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        countryOcc = 0;
                        for(let z = 0; z < b[y].Line_data_occ.length; z++) {
                            if (b[y].Line_data_occ[z].ATTRIB_COL === 'd') {
                                countryKey = null;
                                countryCode = ctrl.countryArray[countryOcc];

                                for (let t = 0; t < ctrl.countryList.length; t++) {
                                    if (ctrl.countryList[t].CODE == countryCode) {
                                        countryKey = ctrl.countryList[t].KEY;
                                        break;
                                    }
                                }
                                b[y].Line_data_occ[z].COUNTRY_KEY = countryKey;
                                b[y].Line_data_occ[z].COUNTRY_CODE = countryCode;
                                countryOcc = countryOcc + 1;
                                b[y].Line_data_occ[z].COL_OCCURRENCE = countryOcc;
                            }
                        }

                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIIIS3Changes.push(c);
                        }
                    }
                }

                //handle delete of col occurrence
                for(let x = 0; x < ctrl.part_III_S3_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_III_S3_1065SK3DataTransposed[x];
                    if('DELETE_COLS' in p) {
                        if(p.DELETE_COLS.length > 0) {
                            for(let d = 0; d < p.DELETE_COLS.length; d++) {
                                let lineData = p.DELETE_COLS[d];
                                lineData.IS_CHANGED_FLAG = 'D';
                                ctrl.partIIIS3Changes.push(lineData);
                            }
                        }
                    }

                }
            }

            //-------------------- section 4 -------------------------------


            if(ctrl.checkValueEnteredInSection(ctrl.part_III_S4_1065SK3DataTransposed)) {

                for(let x = 0; x < ctrl.part_III_S4_1065SK3DataTransposed.length; x++) {

                    a = ctrl.part_III_S4_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            console.log('c = ', c);
                            for(let a = 0; a < c.length; a++) {
                                if(c[a].DATA_TYPE === 'dateVarious') {
                                    let dateTaxPaid =  c[a].ATTRIBUTE_VALUE;
                                    c[a].ATTRIBUTE_VALUE = ((dateTaxPaid.getMonth() > 8) ? (dateTaxPaid.getMonth() + 1) : ('0' + (dateTaxPaid.getMonth() + 1)))
                                        + '/' + ((dateTaxPaid.getDate() > 9) ? dateTaxPaid.getDate() : ('0' + dateTaxPaid.getDate()))
                                        + '/' + dateTaxPaid.getFullYear();
                                }
                            }
                            b[y].Line_data_occ = c;
                            ctrl.partIIIS4Changes.push(c);
                        }
                    }
                }

                //handle delete of row occurrence
                for(let x = 0; x < ctrl.part_III_S4_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_III_S4_1065SK3DataTransposed[x];
                    if(p.DELETE_ROWS.length > 0) {
                        for(let d = 0; d < p.DELETE_ROWS.length; d++) {
                            let lineData = p.DELETE_ROWS[d];
                            for(let dd = 0; dd < lineData.Line_data_occ.length; dd++) {
                                lineData.Line_data_occ[dd].IS_CHANGED_FLAG = 'D';
                            }
                            ctrl.partIIIS4Changes.push(lineData.Line_data_occ);
                        }
                    }
                }

            }



            if(ctrl.countryArray.length > 0 || ctrl.checkValueEnteredInSection(ctrl.part_III_S5_1065SK3DataTransposed)) {

                //-------------------- section 5 -------------------------------
                for(let x = 0; x < ctrl.part_III_S5_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_III_S5_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        countryOcc = 0;
                        for(let z = 0; z < b[y].Line_data_occ.length; z++) {
                            if(b[y].Line_data_occ[z].ATTRIB_COL === 'f') {
                                countryKey = null;
                                countryCode = ctrl.countryArray[countryOcc];

                                for(let t = 0; t < ctrl.countryList.length; t++) {
                                    if(ctrl.countryList[t].CODE == countryCode) {
                                        countryKey =  ctrl.countryList[t].KEY;
                                        break;
                                    }
                                }
                                b[y].Line_data_occ[z].COUNTRY_KEY = countryKey;
                                b[y].Line_data_occ[z].COUNTRY_CODE = countryCode;
                                countryOcc = countryOcc + 1;
                                b[y].Line_data_occ[z].COL_OCCURRENCE = countryOcc;
                            }
                        }

                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIIIS5Changes.push(c);
                        }
                    }
                }

                //handle delete of col occurrence
                for(let x = 0; x < ctrl.part_III_S5_1065SK3DataTransposed.length; x++) {
                    p = ctrl.part_III_S5_1065SK3DataTransposed[x];
                    if('DELETE_COLS' in p) {
                        if(p.DELETE_COLS.length > 0) {
                            for(let d = 0; d < p.DELETE_COLS.length; d++) {
                                let lineData = p.DELETE_COLS[d];
                                lineData.IS_CHANGED_FLAG = 'D';
                                ctrl.partIIIS5Changes.push(lineData);
                            }
                        }
                    }

                }
            }

            for(let row = 0; row < ctrl.partIIIS1L1Changes.length; row++) {
                cells = ctrl.partIIIS1L1Changes[row]
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }
            for(let row = 0; row < ctrl.partIIIS1L2Changes.length; row++) {
                cells = ctrl.partIIIS1L2Changes[row];
                for(let c = 0; c < cells.length; c++) {
                    ctrl.saveData.push(cells[c]);
                }
            }
            for(let row = 0; row < ctrl.partIIIS2Changes.length; row++) {
                cells = ctrl.partIIIS2Changes[row];
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }
            for(let row = 0; row < ctrl.partIIIS3Changes.length; row++) {
                cells = ctrl.partIIIS3Changes[row];
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }
            for(let row = 0; row < ctrl.partIIIS4Changes.length; row++) {
                cells = ctrl.partIIIS4Changes[row];
                for(let c = 0; c < cells.length; c++) {
                    ctrl.saveData.push(cells[c]);
                }
            }
            for(let row = 0; row < ctrl.partIIIS5Changes.length; row++) {
                cells = ctrl.partIIIS5Changes[row];
                if(Array.isArray(cells)) {
                    for(let c = 0; c < cells.length; c++) {
                        ctrl.saveData.push(cells[c]);
                    }
                }
                else {
                    ctrl.saveData.push(cells);
                }
            }

            return ctrl;
        }

        K3PartIIIFactory.validateSK3DataPartIII = function(ctrl, k3Data) {
            let cell;
            let line_no;
            let section_name;
            let countryCells;
            let sicCodeMatched = false;
            let countryMatched = false;
            let taxTypeMatched = false;

            let lineObj;
            let lineData;
            let cellObj;
            let cellValue;
            let cellValues = [];
            let dupeMsg;
            let dupeCountries = [];

            if(ctrl.countryArray.length >= 2) {
                dupeCountries = ctrl.countryArray.filter((item, index) => ctrl.countryArray.indexOf(item) !== index);
                if (dupeCountries.length > 0) {
                    ctrl.ERROR_MESSAGE = 'Duplicate Country(s) exist(s) in the country dropdowns ';
                    ctrl.HAS_ERROR = true;
                }
            }

            if(!ctrl.HAS_ERROR) {

                if(k3Data != undefined) {

                    for(let i = 0; i < k3Data.length; i++) {

                        cell = k3Data[i];
                        line_no = cell.LINE_NO;
                        section_name = cell.SECTION_NAME;

                        if(ctrl.countryArray.length > 0) {
                            countryCells = $filter("filter")($filter("filter")(ctrl.part_III_S1_line1_1065SK3DataTransposed[0].Line_data,{Line_Type: 'DATA_ROW'})[0].Line_data_occ, {ATTRIB_COL: 'e'});
                            for(let c = 1; c < countryCells.length; c++) {
                                if(ctrl.countryArray[c] == undefined) {
                                    ctrl.ERROR_MESSAGE = 'Country Code from dropdown ' + (c+1) + ' must be selected for ~ ' + ' Part: ' + cell.PART_NO + ' Section: 1, 2, 3 and 5';
                                    break;
                                }
                            }
                        }

                        if(ctrl.ERROR_MESSAGE != null) {
                            ctrl.HAS_ERROR = true;
                            break;
                        }

                        if(section_name == '1') {
                            if(line_no == '1') {

                                // validating if text/numbers entered in the box(es) but no country selected from dropdown
                                if( cell.ATTRIB_COL == '0' && 'ATTRIBUTE_VALUE' in cell && cell.ATTRIBUTE_VALUE !== undefined && cell.ATTRIBUTE_VALUE != null && cell.ATTRIBUTE_VALUE.trim().length != 0 ) {
                                    if(ctrl.countryArray.length == 0) {
                                        ctrl.ERROR_MESSAGE = 'Country Code(s) must be selected from dropdown for ~ ' + ' Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {

                                        sicCodeMatched = false;
                                        for(let k = 0; k < ctrl.SIC_codes.length; k++ ) {
                                            if(ctrl.SIC_codes[k].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                sicCodeMatched = true;
                                                break;
                                            }
                                        }
                                        if(sicCodeMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid SIC Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }

                                    }
                                }
                                else if(cell.ATTRIB_COL != '0' && cell.ATTRIBUTE_VALUE != 0) {
                                    if(ctrl.countryArray.length == 0) {
                                        ctrl.ERROR_MESSAGE = 'Country Code(s) must be selected from dropdown for ~ ' + ' Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                }
                            }
                            else if(line_no == '2A' || line_no == '2B') {
                                if(cell.ATTRIB_COL == '0') {
                                    if('ATTRIBUTE_VALUE' in cell && cell.ATTRIBUTE_VALUE !== undefined && cell.ATTRIBUTE_VALUE != null && cell.ATTRIBUTE_VALUE.trim().length != 0) {
                                        sicCodeMatched = false;
                                        for(let k = 0; k < ctrl.SIC_codes.length; k++ ) {
                                            if(ctrl.SIC_codes[k].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                sicCodeMatched = true;
                                                break;
                                            }
                                        }
                                        if(sicCodeMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid SIC Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }
                                }
                            }
                        }
                        else if(section_name == '2') {

                            // validating if numbers entered in the box(es) but no country selected from dropdown

                            if(cell.ATTRIB_COL != '0' && cell.ATTRIBUTE_VALUE != 0) {
                                if(ctrl.countryArray.length == 0) {
                                    ctrl.ERROR_MESSAGE = 'Country Code(s) must be selected from dropdown for ~ ' + ' Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                }

                            }
                        }
                        else if(section_name == '3') {

                            // validating if numbers entered in the box(es) but no country selected from dropdown
                            if(cell.ATTRIB_COL != '0' && cell.ATTRIBUTE_VALUE != 0) {
                                if(ctrl.countryArray.length == 0) {
                                    ctrl.ERROR_MESSAGE = 'Country Code(s) must be selected from dropdown for ~ ' + ' Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                }

                            }
                        }
                        else if(section_name == '4') {
                            if(line_no == '1') {

                                if(cell.ATTRIB_COL == '0') {

                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == undefined || cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE.trim() == '' || cell.ATTRIBUTE_VALUE.trim() == "") {

                                        ctrl.ERROR_MESSAGE = 'Country Code Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {
                                        countryMatched = false;
                                        for(let j = 0; j < ctrl.countryCodeList.length; j++ ) {
                                            if(ctrl.countryCodeList[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                countryMatched = true;
                                                break;
                                            }
                                        }
                                        if(countryMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid Country Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }

                                }
                                else if(cell.ATTRIB_COL == '1') {
                                    if(cell.ATTRIBUTE_VALUE == undefined || cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE.trim() == '' || cell.ATTRIBUTE_VALUE.trim() == "") {
                                        ctrl.ERROR_MESSAGE = 'Paid/Accrued Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no ;
                                    }
                                }
                                else if(cell.ATTRIB_COL == 'a') {

                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == undefined || cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE.trim() == '' || cell.ATTRIBUTE_VALUE.trim() == "") {

                                        ctrl.ERROR_MESSAGE = 'Tax Type Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {
                                        taxTypeMatched = false;
                                        for(let j = 0; j < ctrl.taxTypes.length; j++ ) {
                                            if(ctrl.taxTypes[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                taxTypeMatched = true;
                                                break;
                                            }
                                        }
                                        if(taxTypeMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid Tax Type ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }
                                }

                            }
                            else if(line_no == '2A' || line_no == '2B' || line_no == '2C' || line_no == '2D' || line_no == '2E' || line_no == '2F' || line_no == '2G') {

                                if(cell.ATTRIB_COL == 'a') {

                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == undefined || cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE.trim() == '' || cell.ATTRIBUTE_VALUE.trim() == "") {

                                        ctrl.ERROR_MESSAGE = 'Tax Type Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {
                                        taxTypeMatched = false;
                                        for(let j = 0; j < ctrl.taxTypes.length; j++ ) {
                                            if(ctrl.taxTypes[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                taxTypeMatched = true;
                                                break;
                                            }
                                        }
                                        if(taxTypeMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid Tax Type ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }
                                }

                            }
                            else if(line_no == '3' ) {

                                if(cell.ATTRIB_COL == '0') {

                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == undefined || cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE.trim() == '' || cell.ATTRIBUTE_VALUE.trim() == "") {

                                        ctrl.ERROR_MESSAGE = 'Country Code Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {
                                        countryMatched = false;
                                        for(let j = 0; j < ctrl.countryCodeList.length; j++ ) {
                                            if(ctrl.countryCodeList[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                countryMatched = true;
                                                break;
                                            }
                                        }
                                        if(countryMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid Country Code ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }

                                }
                                if(cell.ATTRIB_COL == '1') {

                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == undefined || cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE.trim() == '' || cell.ATTRIBUTE_VALUE.trim() == "") {

                                        ctrl.ERROR_MESSAGE = 'Related Tax Year Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }


                                }
                                else if(cell.ATTRIB_COL == 'a') {

                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == undefined || cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE.trim() == '' || cell.ATTRIBUTE_VALUE.trim() == "") {

                                        ctrl.ERROR_MESSAGE = 'Tax Type Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                    else {
                                        taxTypeMatched = false;
                                        for(let j = 0; j < ctrl.taxTypes.length; j++ ) {
                                            if(ctrl.taxTypes[j].CODE_SHORT_DESC == cell.ATTRIBUTE_VALUE) {
                                                taxTypeMatched = true;
                                                break;
                                            }
                                        }
                                        if(taxTypeMatched == false) {
                                            ctrl.ERROR_MESSAGE = 'Invalid Tax Type ~ ' + cell.ATTRIBUTE_VALUE + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                        }
                                    }
                                }
                            }

                        }
                        else if(section_name == '5') {
                            if(line_no == '1' || line_no == '2') {

                                // validating if numbers entered in the box(es) but no country selected from dropdown
                                if(cell.ATTRIB_COL != '0' && cell.ATTRIBUTE_VALUE != 0) {
                                    if(ctrl.countryArray.length == 0) {
                                        ctrl.ERROR_MESSAGE = 'Country Code(s) must be selected from dropdown for ~ ' + ' Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }

                                }
                            }
                        }

                        if(ctrl.ERROR_MESSAGE != null) {
                            ctrl.HAS_ERROR = true;
                            break;
                        }
                    }
                }
            }

            if(!ctrl.HAS_ERROR) {

                let part_III_S1_S4_TxData = [];
                if(ctrl.checkValueEnteredInSection(ctrl.part_III_S1_line1_1065SK3DataTransposed)) {
                    for(let x = 0; x < ctrl.part_III_S1_line1_1065SK3DataTransposed.length; x++) {
                        part_III_S1_S4_TxData.push(ctrl.part_III_S1_line1_1065SK3DataTransposed[x]);
                    }
                }
                if(ctrl.checkValueEnteredInSection(ctrl.part_III_S1_line2_1065SK3DataTransposed)) {
                    for(let x = 0; x < ctrl.part_III_S1_line2_1065SK3DataTransposed.length; x++) {
                        part_III_S1_S4_TxData.push(ctrl.part_III_S1_line2_1065SK3DataTransposed[x]);
                    }
                }
                if(ctrl.checkValueEnteredInSection(ctrl.part_III_S4_1065SK3DataTransposed)) {
                    for(let x = 0; x < ctrl.part_III_S4_1065SK3DataTransposed.length; x++) {
                        part_III_S1_S4_TxData.push(ctrl.part_III_S4_1065SK3DataTransposed[x]);
                    }
                }

                for(let x = 0; x < part_III_S1_S4_TxData.length; x++) {

                    cellValues = [];
                    lineObj = part_III_S1_S4_TxData[x];
                    lineData = $filter("filter")(lineObj.Line_data, {Line_Type:'DATA_ROW'});

                    if(lineData.length > 1) {

                        for(let y = 0; y < lineData.length; y++) {
                            cellObj = $filter("filter")(lineData[y].Line_data_occ,{ATTRIB_COL:'0'})[0];
                            cellValue = cellObj.ATTRIBUTE_VALUE;
                            cellValues.push(cellValue);
                        }
                        let duplicates = cellValues.reduce(function(acc, el, i, arr) {
                            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
                        }, []);
                        console.log('duplicates = ' , duplicates);
                        if(duplicates.length > 0) {
                            if(cellObj.SECTION_NAME == '1' && (lineData[0].Line_no == '1' || lineData[0].Line_no == '2A' || lineData[0].Line_no == '2B')) {
                                dupeMsg = 'Duplicate SIC Code(s) ~ ';
                            }
                            else if(cellObj.SECTION_NAME == '4' &&  (lineData[0].Line_no == '1' || lineData[0].Line_no == '3') ) {
                                dupeMsg = 'Duplicate Country Code(s) ~ ';
                            }
                            ctrl.ERROR_MESSAGE = dupeMsg + duplicates[0] + ' in Part: ' + cellObj.PART_NO + ' Section: '+ cellObj.SECTION_NAME +  ' Line: ' +  lineData[0].Line_no ;
                            ctrl.HAS_ERROR = true;
                            break;
                        }
                    }
                }

            }

            return ctrl;
        }

        return K3PartIIIFactory;

    }

    return services;
});