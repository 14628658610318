define(
    [ 'angular'

        ],
        function() {
        'use strict';

        var controllers = angular.module('app.auditIDRCtrl', [])
        .controller('auditIDRController', [ auditIDRController])


        function auditIDRController() {

        }
        return controllers;
    })