
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.reTriggerMessageService',[])

    .factory('reTriggerMessageServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var reTriggerMessageServiceFactory = {};
        
        reTriggerMessageServiceFactory.saveRetriggerMessage = function(params){
            var params = params;
            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.bulk_pdf_engine_api_url + '/reTriggerSNSMessagePublish?client_key=' + params.client_key + '&le_key=' + params.le_key + '&le_type=' + params.le_type + '&submissionId=' + params.submissionId,
                //data: params
            }).then(function (response) {
                return response;
            });
            return promise;
        };
        
        return reTriggerMessageServiceFactory
    }])

    return services;

});