define([
    'angular',
    './SectionDetailsCtrl',
    './SectionDetailsService'

], function () {
    'use strict';
    return angular.module('app.SectionDetails', ['app.SectionDetailsCtrl', 'app.SectionDetailsService']);

});
