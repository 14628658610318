/**
 * Created by 400371031 on 4/27/2021.
 */

define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.1120SubConsolService',[])

        .factory('F1120SubConsolFactory',['$q','$http','$timeout','$rootScope','$log','DataFiltersFactory','workspaceFactory','JsonObjectFactory','AlertService','GlobalService','GENERAL_CONFIG',
        function relatedPartyPaymentFactory($q,$http,$timeout,$rootScope,$log,DataFiltersFactory,workspaceFactory,JsonObjectFactory,AlertService,GlobalService,GENERAL_CONFIG) {
            var factory = {};
            factory.url = {};
            factory.url.savesubconsolData  =  GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=jcbfdm";
            factory.savesubconsolData = function(_data,_accounts){

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var jsonObj = JSON.stringify(_accounts);
                var jsonSettings =  JSON.stringify(_data);

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                //params =  _.extend({jsonSettings:jsonSettings}, _data);
                params.process_name =  "CONSOL/SUBCONSOLE_DATA_SAVE";
               
                var promise = $http({
                    method: "post",
                    url: factory.url.savesubconsolData,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            };
            
            return factory;

        }]);


    return services;


});
