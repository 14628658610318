define([
    'angular',
    './controversyProjLibCtrl'

], function () {
    'use strict';

  
   return angular.module('app.controversyProjectLib', ['app.controversyProjLibCtrl'])
   .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
    $stateProvider
        .state('project-library', {
            url: '/project-library',
            templateUrl: 'app/components/controversyProjectLib/controvery-proj-lib.html',
            controller: 'controversyProjLibController as ctrl'
        });
    }]);
   
});