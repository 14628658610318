define([
    'angular'

], function () {
    'use strict';
    var services = angular.module('app.adminDashboardService', [])
    .factory('adminDdashboardFactory', [
        'GENERAL_CONFIG',
        '$rootScope',
        '$state',
        'USER_MENU',
        '$timeout',
        'menuDataService',
        'GlobalService',
        'AlertService',
        'JsonObjectFactory',
        '$q',
        '$http',
        '$filter',
        'workspaceFactory',
        adminDdashboardFactory
    ])
    function adminDdashboardFactory(GENERAL_CONFIG,$rootScope, $state, USER_MENU, $timeout, menuDataService, GlobalService, AlertService, JsonObjectFactory, $q, $http, $filter,workspaceFactory) {
        var adminDdashboardFactory = {};

        adminDdashboardFactory.getActionData = function (url,dashboard) {
            var promise = $http({
                method: "post",
                url: url,
                data: dashboard
            }).then(function (response) {
                return response.data;
            });
            return promise;
        }
        adminDdashboardFactory.postData = function(url,params){
            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + url,
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        }

        return adminDdashboardFactory;

    }
    return services;
});