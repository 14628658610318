define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.submitterDiagnosticsCtrl', [])
        .controller('submitterDiagnosticsController', [submitterDiagnosticsController]);

    function submitterDiagnosticsController() {
    var vm= this;
    }
});