define([
   'angular',
   './EpDrilldownCtrl',
   './EpDrilldownService',
], function () {
   'use strict';
   return angular.module('app.FIR_EP_Drilldown', ['app.EpDrilldownCtrl','app.EpDrilldownService'] )
   .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
       $stateProvider
           .state('ep-drilldown', {
               url: '/ep-drilldown',
               templateUrl: 'app/components/FIR_EP_Drilldown/EpDrilldown.html',
               data:{},
               access:""
           })
   }])

});