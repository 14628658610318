define([
    'angular',
    './ConsolidationLedgersCtrl',
    './ConsolidationLedgersService',

], function () {
    'use strict';

  
   return angular.module('app.ConsolidationLedgers', ['app.ConsolidationLedgersCtrl','app.ConsolidationLedgersService'])

    });