define([
    'angular'
], function () {
    'use strict';



    var controllers = angular.module('app.adjPriorYearCtrl', [])
            .controller('adjPriorYearCtrl', ['GlobalService','$scope', 'JsonObjectFactory','$parse', 'AdjPriorYearFactory', 'workspaceFactory', '$state', '$window',
					   'AlertService', 'GENERAL_CONFIG', 'ModalFactory', '$uibModalInstance', 'rowData', 'colData', 'gridData', adjPriorYearCtrl]);
    
    function adjPriorYearCtrl(GlobalService,$scope, JsonObjectFactory, $parse, AdjPriorYearFactory, workspaceFactory, $state, $window,
			AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance, rowData, colData, gridData) {
    	
    	
    	var vm = this;
    	vm.rowData = rowData;
    	vm.modalTitle = 'Adjust Beginning Balances(Local)';
    	vm.scenarios = [];
    	vm.currencies = [];
    	vm.selected_curr_exg_rate = null;
    	vm.selected_scenario = null;
    	$scope.crudLoading = false;
    	
    	function init()
    	{
    		getScenario();
    		getCurrencies();
    	}
    	init();
    	
//--------------------------------------------------------------------------------------------------------------------------------------------------    	
    	
    	function getScenario()
    	{
    		var params = {
        			"action_code": 'e8m65o',
        			"group_obj_key": vm.rowData.PY_GROUP_OBJ_KEY,
        			"tax_year" : GlobalService.globalParams.tax_year,
        			"scenario" :GlobalService.globalParams.scenario,
        			"jcd_key" : GlobalService.globalParams.jcd_key === '' ? 250 :  GlobalService.globalParams.jcd_key
    		};
    		console.log("params-------------", params);
        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        		if(data.callSuccess === "1") {
        			vm.scenarios = data.jsonObject;
        			vm.selected_scenario = _.find(vm.scenarios,{GROUP_OBJ_KEY: vm.rowData.PY_GROUP_OBJ_KEY});
        		}
        		else
	        	{
        			if (data.errorMessage) {
        				AlertService.add("",data.errorMessage,
											4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
										"",
										"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
										4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
	        	}

	        });
    		
    	}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
    	
    	function getCurrencies()
    	{
    		console.log("vm.rowData-------------", vm.rowData);
    		var params = {
        			"action_code": 'x1q2ya',
        			"tax_year" : GlobalService.globalParams.tax_year,
        			"scenario" :GlobalService.globalParams.scenario,
        			"jcd_key" : GlobalService.globalParams.jcd_key === '' ? 250 :  GlobalService.globalParams.jcd_key,
        			"cy_curr" : vm.rowData.CURR
    		};
    		console.log("params-------------", params);
        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
        		if(data.callSuccess === "1") {
        			vm.currencies = data.jsonObject;
        			console.log("vm.currencies--------------", vm.currencies);
        			vm.selected_currency = _.find(vm.currencies,{CURR: vm.rowData.PY_CURR});
        			console.log("vm.selected_currency---------", vm.selected_currency);
        			vm.selected_curr_exg_rate = vm.selected_currency.WTD_AVG_RATE;
        		}
        		else
	        	{
        			if (data.errorMessage ) {
        				AlertService.add("",data.errorMessage,
											4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
										"",
										"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
										4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
	        	}

	        });
    	}

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
    	
    	vm.changeCurrency = function(){
    		console.log("vm.selected_currency--------------", vm.selected_currency);
    		vm.selected_curr_exg_rate = vm.selected_currency.WTD_AVG_RATE;
    	}
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------    
    	
    	vm.save = function()
    	{
    		if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
	                return;
	        }
    		
    		$scope.crudLoading = true;
    		var jsonSettings = {};
    		var jsonObj = [];
    		var returnObj = {
    				'CY_COMBINATION_KEY'   : vm.rowData.COMBINATION_KEY,
    				'PY_COMBINATION_KEY'   : vm.rowData.PY_COMBINATION_KEY,
    				'OLD_PY_GROUP_OBJ_KEY' : vm.rowData.PY_GROUP_OBJ_KEY,
    				'NEW_PY_GROUP_OBJ_KEY' : vm.selected_scenario.GROUP_OBJ_KEY,
    		}
    		
    		jsonObj.push(returnObj);
    		
    		AdjPriorYearFactory.updatePriorYearScenario(jsonSettings,
					jsonObj).then( function(result) {

						if (result.errorMessage
								&& result.errorMessage !== 'null') {
							AlertService.add("error",
									result.errorMessage, 4000);
						} else {
							AlertService.add("success", 'Scenario has been updated successfully', 4000);
							$scope.crudLoading = false;
						}
					});
    		//3jibvt
    	}
    	
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    	
    	vm.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
    	
    	
    }

 });

    