define(
    ['angular',

        './IntExpAdjService',

    ],
    function() {
        'use strict';

        var controllers = angular
            .module('app.IntExpAdjCtrl', ['app.IntExpAdjService'])
            .value('customSelectDefaultsrndinc', {
				displayText : 'Select...',
				emptyListText : 'There are no items to display',
				emptySearchResultText : 'No results match "$0"',
				addText : 'Add',
				searchDelay : 300,
			})
			.directive(
					'customSelectrndinc',
					[
							'$parse',
							'$compile',
							'$timeout',
							'$q',
							'customSelectDefaultsrndinc',
							function($parse, $compile, $timeout, $q,
									baseOptions) {
								var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

								return {
									restrict : 'A',
									require : 'ngModel',
									link : function(scope, elem, attrs,
											controller) {
										var customSelect = attrs.customSelectrndinc;
										if (!customSelect) {
											throw new (
													'Expected custom-select attribute value.');
										}
										scope
												.$watch(
														"ctrl.sys_acc.SYS_ACCT.SYS_ACCT",
														function() {
															// scope.getChartAcctTableData();
															scope
																	.$emit(
																			'SYS_ACCT_CHANGED',
																			'');
														});
										var match = customSelect
												.match(CS_OPTIONS_REGEXP);

										if (!match) {
											throw new (
													'Expected expression in form of '
															+ "'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'"
															+ " but got '"
															+ customSelect
															+ "'.");
										}

										elem
												.addClass('dropdown custom-select');

										// Ng-Options break down
										var displayFn = $parse(match[2]
												|| match[1]), valueName = match[3], valueFn = $parse(match[2] ? match[1]
												: valueName), values = match[4], valuesFn = $parse(values), track = match[5], trackByExpr = track ? ' track by '
												+ track
												: '', dependsOn = attrs.csDependsOn;

										var options = getOptions(), timeoutHandle, lastSearch = '', focusedIndex = -1, matchMap = {};

										var itemTemplate = elem.html()
												.trim()
												|| '{{'
												+ (match[2] || match[1])
												+ '}}', dropdownTemplate = '<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">'
												+ '<span>{{displayText}}</span>'
												+ '<b></b>'
												+ '</a>'
												+ '<div class="dropdown-menu">'
												+ '<div stop-propagation="click" class="custom-select-search">'
												+ '<input class="'
												+ attrs.selectClass
												+ '" type="text" autocomplete="off" ng-model="searchTerm" />'
												+ '</div>'
												+ '<ul role="menu">'
												+ '<li role="presentation" ng-repeat="'
												+ valueName
												+ ' in matches'
												+ trackByExpr
												+ '">'
												+ '<a role="menuitem" tabindex="-1" href ng-click="select('
												+ valueName
												+ ')">'
												+ itemTemplate
												+ '</a>'
												+ '</li>'
												+ '<li ng-hide="matches.length" class="empty-result" stop-propagation="click">'
												+ '<em class="muted">'
												+ '<span ng-hide="searchTerm">{{emptyListText}}</span>'
												+ '<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>'
												+ '</em>'
												+ '</li>'
												+ '</ul>'
												+ '<div class="custom-select-action">'
												+ (typeof options.onAdd === 'function' ? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
														: '')
												+ '</div>' + '</div>';

										// Clear element contents
										elem.empty();

										// Create dropdown element
										var dropdownElement = angular
												.element(dropdownTemplate), anchorElement = dropdownElement
												.eq(0).dropdown(), inputElement = dropdownElement
												.eq(1).find(':text'), ulElement = dropdownElement
												.eq(1).find('ul');

										// Create child scope for input
										// and dropdown
										var childScope = scope
												.$new(true);
										configChildScope();

										// Click event handler to set
										// initial values and focus when
										// the dropdown is shown
										anchorElement
												.on(
														'click',
														function(event) {
															if (childScope.disabled) {
																return;
															}
															childScope
																	.$apply(function() {
																		lastSearch = '';
																		childScope.searchTerm = '';
																	});

															focusedIndex = -1;
															inputElement
																	.focus();

															// If filter
															// is not
															// async,
															// perform
															// search in
															// case
															// model
															// changed
															if (!options.async) {
																getMatches('');
															}
														});

										if (dependsOn) {
											scope
													.$watch(
															dependsOn,
															function(
																	newVal,
																	oldVal) {
																if (newVal !== oldVal) {
																	childScope.matches = [];
																	childScope
																			.select(undefined);
																}
															});
										}

										// Event handler for key press
										// (when the user types a
										// character while focus is on
										// the anchor element)
										anchorElement
												.on(
														'keypress',
														function(event) {
															if (!(event.altKey || event.ctrlKey)) {
																anchorElement
																		.click();
															}
														});

										// Event handler for Esc, Enter,
										// Tab and Down keys on input
										// search
										inputElement
												.on(
														'keydown',
														function(event) {
															if (!/(13|27|40|^9$)/
																	.test(event.keyCode))
																return;
															event
																	.preventDefault();
															event
																	.stopPropagation();

															switch (event.keyCode) {
															case 27: // Esc
																anchorElement
																		.dropdown('toggle');
																break;
															case 13: // Enter
																selectFromInput();
																break;
															case 40: // Down
																focusFirst();
																break;
															case 9: // Tab
																anchorElement
																		.dropdown('toggle');
																break;
															}
														});

										// Event handler for Up and Down
										// keys on dropdown menu
										ulElement
												.on(
														'keydown',
														function(event) {
															if (!/(38|40)/
																	.test(event.keyCode))
																return;
															event
																	.preventDefault();
															event
																	.stopPropagation();

															var items = ulElement
																	.find('li > a');

															if (!items.length)
																return;
															if (event.keyCode == 38)
																focusedIndex--; // up
															if (event.keyCode == 40
																	&& focusedIndex < items.length - 1)
																focusedIndex++; // down
															if (focusedIndex >= 0) {
																items
																		.eq(
																				focusedIndex)
																		.focus();
															} else {
																focusedIndex = -1;
																inputElement
																		.focus();
															}
														});

										resetMatches();

										// Compile template against
										// child scope
										$compile(dropdownElement)(
												childScope);
										elem.append(dropdownElement);

										// When model changes outside of
										// the control, update the
										// display text
										controller.$render = function() {
											setDisplayText();
										};

										// Watch for changes in the
										// default display text
										childScope.$watch(
												getDisplayText,
												setDisplayText);

										childScope
												.$watch(
														function() {
															return elem
																	.attr('disabled');
														},
														function(value) {
															childScope.disabled = value;
														});

										childScope
												.$watch(
														'searchTerm',
														function(
																newValue) {
															if (timeoutHandle) {
																$timeout
																		.cancel(timeoutHandle);
															}

															var term = (newValue || '')
																	.trim();
															timeoutHandle = $timeout(
																	function() {
																		getMatches(term);
																	},
																	// If
																	// empty
																	// string,
																	// do
																	// not
																	// delay
																	(term && options.searchDelay) || 0);
														});

										// Support for autofocus
										if ('autofocus' in attrs) {
											anchorElement.focus();
										}

										var needsDisplayText;
										function setDisplayText() {
											var locals = {};
											locals[valueName] = controller.$modelValue;
											var text = displayFn(scope,
													locals);
											if (text === undefined) {
												var map = matchMap[hashKey(controller.$modelValue)];
												if (map) {
													text = map.label;
												}
											}

											needsDisplayText = !text;
											childScope.displayText = text
													|| controller.$modelValue // text
										}

										function getOptions() {
											return angular
													.extend(
															{},
															baseOptions,
															scope
																	.$eval(attrs.customSelectOptionsrnd));
										}

										function getDisplayText() {
											options = getOptions();
											
											return options.displayText;
										}

										function focusFirst() {
											var opts = ulElement
													.find('li > a');
											if (opts.length > 0) {
												focusedIndex = 0;
												opts.eq(0).focus();
											}
										}

										// Selects the first element on
										// the list when the user
										// presses Enter inside the
										// search input
										function selectFromInput() {
											var opts = ulElement
													.find('li > a');
											if (opts.length > 0) {
												var ngRepeatItem = opts
														.eq(0).scope();
												var item = ngRepeatItem[valueName];
												childScope
														.$apply(function() {
															childScope
																	.select(item);
														});
												anchorElement
														.dropdown('toggle');
											}
										}

										function getMatches(searchTerm) {
											var locals = {
												$searchTerm : searchTerm
											};
											$q
													.when(
															valuesFn(
																	scope,
																	locals))
													.then(
															function(
																	matches) {
																if (!matches)
																	return;

																if (searchTerm === inputElement
																		.val()
																		.trim() /*
																				 * &&
																				 * hasFocus
																				 */) {
																	matchMap = {};
																	childScope.matches.length = 0;
																	for (var i = 0; i < matches.length; i++) {
																		locals[valueName] = matches[i];
																		var value = valueFn(
																				scope,
																				locals), label = displayFn(
																				scope,
																				locals);

																		matchMap[hashKey(value)] = {
																			value : value,
																			label : label,
																		};

																		childScope.matches
																				.push(matches[i]);
																	}
																}

																if (needsDisplayText)
																	setDisplayText();
															},
															function() {
																resetMatches();
															});
										}

										function resetMatches() {
											childScope.matches = [];
											focusedIndex = -1;
										}

										function configChildScope() {
											childScope.addText = options.addText;
											childScope.emptySearchResultText = options.emptySearchResultText;
											childScope.emptyListText = options.emptyListText;

											childScope.select = function(
													item) {
												var locals = {};
												locals[valueName] = item;
												var value = valueFn(
														childScope,
														locals);
												childScope.displayText = displayFn(
														childScope,
														locals)
														|| options.displayText;
												controller
														.$setViewValue(value);

												anchorElement.focus();

												typeof options.onSelect === 'function'
														&& options
																.onSelect(item);
											};

											childScope.add = function() {
												$q
														.when(
																options
																		.onAdd(),
																function(
																		item) {
																	if (!item)
																		return;

																	var locals = {};
																	locals[valueName] = item;
																	var value = valueFn(
																			scope,
																			locals), label = displayFn(
																			scope,
																			locals);

																	matchMap[hashKey(value)] = {
																		value : value,
																		label : label,
																	};

																	childScope.matches
																			.push(item);
																	childScope
																			.select(item);
																});
											};

											// childScope.format = format;

											setDisplayText();
										}

										var current = 0;
										function hashKey(obj) {
											if (obj === undefined)
												return 'undefined';

											var objType = typeof obj, key;

											if (objType == 'object'
													&& obj !== null) {
												if (typeof (key = obj.$$hashKey) == 'function') {
													// must invoke on
													// object to keep
													// the right this
													key = obj
															.$$hashKey();
												} else if (key === undefined) {
													key = obj.$$hashKey = 'cs-'
															+ current++;
												}
											} else {
												key = obj;
											}

											return objType + ':' + key;
										}
									},
								};
							}, ])

	.directive('stopPropagation', function() {
		return {
			restrict : 'A',
			link : function(scope, elem, attrs, ctrl) {
				var events = attrs['stopPropagation'];
				elem.bind(events, function(event) {
					event.stopPropagation();
				});
			},
		};
	})
           .directive(
							'allowDecimalNumbers',
							function() {
								return {
									restrict : 'A',
									link : function(scope, elm, attrs, ctrl) {
										elm
												.on(
														'keydown',
														function(event) {
															var $input = $(this);
															console.log("-->  $input : ", $input);
															var value = $input
																	.val();
															console.log("-->  value : ", value);
															value = value
																	.replace(
																			/[-+][^0-9\.]/g,
																			'')
															var findsDot = new RegExp(
																	/\./g)
															var containsDot = value
																	.match(findsDot)
															if (containsDot != null
																	&& ([
																			46,
																			110,
																			190 ]
																			.indexOf(event.which) > -1)) {
																event
																		.preventDefault();
																return false;
															}
															$input.val(value);
															if (event.which == 64
																	|| event.which == 16) {
																// numbers
																return false;
															}
															if ([ 8, 13, 27,
																	37, 38, 39,
																	40, 110 ]
																	.indexOf(event.which) > -1) {
																// backspace,
																// enter,
																// escape,
																// arrows
																return true;
															} else if (event.which >= 48
																	&& event.which <= 57) {
																// numbers
																return true;
															} else if (event.which >= 96
																	&& event.which <= 105) {
																// numpad number
																return true;
															} else if ([ 46,
																	110, 190 ]
																	.indexOf(event.which) > -1) {
																// dot and
																// numpad dot
																return true;
															} else {
																event
																		.preventDefault();
																return false;
															}
														});
									}
								}
							})

            .controller(
                'intExpAdj',
                ['$rootScope', '$scope', '$state', '$log',
                    'JsonObjectFactory', 'AlertService',
                    '$timeout', '$uibModal', '$uibModalInstance', 'rowData',
                    'gridData', 'GENERAL_CONFIG', 'IntExpAdjFactory', '$http', 'USER_SETTINGS', 'GlobalService', 'workspaceFactory', '$filter', 'ModalFactory', intExpAdj
                ]);
        // Directives based on http://stackoverflow.com/a/24271309/3894163
        function intExpAdj($rootScope, $scope, $state, $log,
            JsonObjectFactory, AlertService, $timeout, $uibModal, $uibModalInstance, rowData, gridData,
            GENERAL_CONFIG, IntExpAdjFactory, $http, USER_SETTINGS, GlobalService, workspaceFactory, $filter, ModalFactory) {

            var vm = this;
            vm.userSettings = USER_SETTINGS;
            vm.isCreate = (gridData.length == 0 || gridData.colData.map == "Add") ? true : false;
            vm.rowData = rowData;
            vm.entityDetails = [];
			vm.adjustmentTypeDetails = [];
			vm.sicCodeDetails = [];
			vm.basketDetails = [];
			vm.fdiiDetails = [];
			vm.intExpAdjDetails = [];
			//A flag to enable and disable save button
			vm.isSaveClicked = false;
			var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
			vm.curentyear = filterParams.tax_year;   //GlobalService.globalParams.tax_year; // //'' + new Date().getFullYear();
            vm.scenario = filterParams.scenario;
            vm.jcd_key  = GlobalService.globalParams.jcd_key;	
			//To get the Lock_Y_N flag from backend
			var lockparams = {
					"action_code": 'n10cpv',
					'p_scenario' : vm.scenario,
					'p_jcd_key' :vm.jcd_key
				};				
			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
				vm.is_locked = data.jsonObject;				
				//To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
				$scope.$watch(function() { return vm.is_locked }, function() {
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
						vm.cancel();										
						AlertService.add("error","Tax Return has already been filed for tax year " + filterParams.tax_year + "." ,'4000');
						return;
					}	
					if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].SCENARIO_DESC == 'EXAM'){
						vm.cancel();										
						AlertService.add("error","Create Adjustment is not available for selected Scenario." ,'4000');
						return;
					}
				});
			});
			
			 let params = {
		                "action_code": 'g9oqag',
		                'p_scenario' : vm.scenario,
						'p_jcd_key' :vm.jcd_key
		            },exist=null;
		            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
		                .then(function(data) {
		                    vm.entityDetails = data.jsonObject;
		                    if(!vm.isCreate)
		                    exist = _.find(vm.entityDetails, ['KEY', vm.rowData.COMBINATION_KEY]);
		                    vm.entity = (vm.isCreate == false) ? exist : undefined;
		            });
		            
		            
		    vm.onEdit = function() {
		    	 if(!vm.isCreate && vm.rowData) {
			    	  params = {
			    			    
				                "action_code": '75d4wv',
				                /*'sso_id': vm.userSettings.user.sso_id,
								'tax_year':GlobalService.globalParams.tax_year,
								'p_scenario' : vm.scenario,
								'p_jcd_key' :vm.jcd_key,
				                'cc_key':vm.rowData.COMBINATION_KEY,
				                'sic_code':vm.rowData.SIC_CODE_KEY*/
				                'sso_id': vm.userSettings.user.sso_id,
				                'p_tax_year' : vm.curentyear,
								'p_scenario':   vm.scenario,
								'p_jcd_key' :vm.jcd_key,
								'cc_key':vm.rowData.COMBINATION_KEY
								
				                
				            },exist=null;
				            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
				                .then(function(data) {
				                    vm.intExpAdjDetails = data.jsonObject;
				                    vm.intExpAdjDetails.forEach(details => {
				                    	details['operation-type'] = 'U';
				                    })
				                    console.log(vm.intExpAdjDetails,"intExpAdjDetails");
				                    vm.updateTotal();
				            });
			      } 
		    }     
		            
			vm.autoLoadData = function(){
				
           
        
            params = {

                    "action_code": 'dbty56',
                    "cc_key": vm.rowData ? vm.rowData.COMBINATION_KEY : null,
                    'p_scenario' : vm.scenario,
    				'p_jcd_key' :vm.jcd_key
                };
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                    .then(function(data) {
                        let collection  = data.jsonObject;

                        vm.adjustmentTypeDetails = $filter('filter')(data.jsonObject, function(data) {
                            return (data.CODE_GROUP === 'ADJ_TYPE_CODE');
                        });
                       
                        
                        vm.basketDetails = $filter('filter')(data.jsonObject, function(data) {
                            return (/*data.CODE_GROUP === 'SOURCE_CODE' ||*/ data.CODE_GROUP === 'SOURCE_CODE_NEW');
                        });
                        
                       
                        
                        vm.fdiiDetails = $filter('filter')(data.jsonObject, function(data) {
                            return (data.CODE_GROUP === 'FDII_CODE');
                        });
                       
                        
                        if(!vm.isCreate){

                        	vm.adjustmentName = vm.rowData.adjustmentName;// have to check this
                        	vm.adjustmentAmount = vm.rowData.ADJ_AMT;
                        	vm.comments = vm.rowData.COMMENTS;
                        }else{
                        	vm.sicCode = {};
                        	vm.adjustmentType  = {};
                        	vm.basket = {};
                        	vm.fdii = {};
                        	vm.adjustmentAmount = null;
                        	vm.comments = null;
                        	
                        }
                        
                        
                });
			}
			
			
			vm.getCodeCombination = function() {
				var params = {
	                "action_code": 'g9oqag',
	                'p_scenario' : vm.scenario,
					'p_jcd_key' :vm.jcd_key
	            },exist=null;
	            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
	                .then(function(data) {
	                  //  vm.entityDetails = data.jsonObject;
	                    /*if(!vm.isCreate)
	                    exist = _.find(vm.entityDetails, ['KEY', vm.rowData.COMBINATION_KEY]);
	                    vm.entity = (vm.isCreate == false) ? exist : undefined;*/
	            });
			}
			
			
			vm.deleteRow = function(selectedRowData) {
				var index = vm.intExpAdjDetails.indexOf(selectedRowData),count=0;
				vm.intExpAdjDetails.forEach(detail => {
					if (!detail['operation-type']) {
						count++;
					}
				})
				// if (count === 1) return;
				selectedRowData['isDeleted'] = true;
				selectedRowData['operation-type'] =  'D';
				vm.intExpAdjDetails[index] = selectedRowData;
			}
			vm.updatedRow = function(selectedRowData) {
				var index = vm.intExpAdjDetails.indexOf(selectedRowData);
				selectedRowData['operation-type'] =  'U';
				vm.intExpAdjDetails[index] = selectedRowData;
			}
			vm.updateTotal = function() {
				vm.total =  0;
				vm.intExpAdjDetails.forEach(function(list) { 
					if (list['operation-type'] !== 'D')
					vm.total += parseInt(list.AMOUNT  ? list.AMOUNT : 0);
				})
			}
			
			vm.getAllData = function(){
				var params = {
		                    "action_code": 'dbty56',
		                    "cc_key":vm.entity.KEY,
		                    'p_scenario' : vm.scenario,
							'p_jcd_key' :vm.jcd_key
		                };
		                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
		                    .then(function(data) {
		                        let collection  = data.jsonObject;
		                        

		                         vm.adjustmentTypeDetails = $filter('filter')(data.jsonObject, function(data) {
		                            return (data.CODE_GROUP === 'ADJ_TYPE_CODE');
		                        });
		                       
		                        
		                        vm.basketDetails = $filter('filter')(data.jsonObject, function(data) {
		                            return (/*data.CODE_GROUP === 'SOURCE_CODE' ||*/ data.CODE_GROUP === 'SOURCE_CODE_NEW');
		                        });
		                        
		                       
		                        
		                        vm.fdiiDetails = $filter('filter')(data.jsonObject, function(data) {
		                            return (data.CODE_GROUP === 'FDII_CODE');
		                        });
		                    }); }
			
			
			if(!vm.rowData)
				vm.getCodeCombination();
			
			vm.autoLoadData();
			vm.onEdit();
			
			vm.reset = function(){
			   vm.entity = undefined;
			   vm.adjustmentType = null;
			   vm.adjustmentName = null;
			   vm.sicCode = null;
			   vm.basket = null;
			   vm.fdii = null;
			   vm.adjustmentAmount = null;
			   vm.comments = null;
			   vm.autoLoadData();
			}
			
			
			
			vm.resetEdit = function() {
				vm.onEdit();
			}
			
			
			
                /*params = {
                        "action_code": '31hizx',
                    };
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                        .then(function(data) {
                        	let collection  = data.jsonObject;
                        	vm.adjTypeDetails = $filter('filter')(data.jsonObject, function(data) {
                                return (data.CODE_GROUP === 'ADJ_TYPE_CODE');
                        	});
                       });*/
                
                function sendDetails(returnObj, transactionDetails, message,otherInfo, LockObj) {
                	IntExpAdjFactory.save(returnObj,
                        transactionDetails,otherInfo, LockObj).then(
                        function(result) {
                        	vm.isSaveClicked = false;
                            if (result.errorMessage &&
                                result.errorMessage !== 'null') {
                                var startIndex = result.errorMessage.indexOf("(") + 1;
                                var lastIndex = result.errorMessage.indexOf(")");
                                var errorKey = result.errorMessage.substring(startIndex, lastIndex);
                                AlertService.add("error",
                                        result.errorMessage, 4000);
                                vm.isSaveClicked = false;
                            } else {
                                AlertService.add("success", message, 4000);
                                $uibModalInstance.dismiss('cancel');
                                var args = {};
                                $rootScope.$emit('gridUpdate', args);
                            }
                        });

                }
            vm.save = function(){
            	var returnObj = {}, transactionDetails = [], message = "",otherInfo = {} ,errorInfo=""; var checkLockObj =[];
            	//Disable the Save button till the processing completes
				vm.isSaveClicked = true;
				
            	if(vm.isCreate && (vm.entity === undefined  ||  ( vm.entity && vm.entity.KEY  === undefined) || 
            	  ( vm.adjustmentName === undefined  ||   vm.adjustmentName  === '') ||                   
                	 (vm.adjustmentAmount == undefined  || vm.adjustmentAmount == null ||  vm.adjustmentAmount == '')) ){
            		 errorInfo = "Please select all required fields ";
                     AlertService.add("error", errorInfo, 4000);
                     vm.isSaveClicked = false;
                     return 0;
            	}
            	
            	if(vm.isCreate &&  vm.adjustmentAmount != undefined  &&  vm.adjustmentAmount == 0){
                  		 errorInfo = "Adjustment amount should not be 0";
                           AlertService.add("error", errorInfo, 4000);
                           vm.isSaveClicked = false;
                           return 0;
                 }
            	
            	/* if(vm.adjustmentName === undefined  ||   vm.adjustmentName  === '' ){
                     errorInfo = "Please Give Adjustment Name ";
                     AlertService.add("error", errorInfo, 4000);
                     return 0;
                 }*/
            	 
            	 /*if(vm.entity === undefined  ||  ( vm.entity && vm.entity.KEY  === undefined) ){
                     errorInfo = "Please select Entity ";
                     AlertService.add("error", errorInfo, 4000);
                     return 0;
                 }
            	 
            	 if(vm.adjustmentType === undefined  ||  ( vm.adjustmentType && vm.adjustmentType.CODE_KEY  === undefined) ){
                     errorInfo = "Please select Adjustment Type ";
                     AlertService.add("error", errorInfo, 4000);
                     return 0;
                 }
            	 
            	 if(vm.sicCode === undefined  ||  (vm.sicCode && vm.sicCode.CODE_KEY  === undefined )){
                     errorInfo = "Please select SIC Code ";
                     AlertService.add("error", errorInfo, 4000);
                     return 0;
                 }
            	 
            	 if(vm.basket === undefined  ||  (vm.basket && vm.basket.CODE_KEY  === undefined) ){
                     errorInfo = "Please select Basket ";
                     AlertService.add("error", errorInfo, 4000);
                     return 0;
                 }
            	 
            	 if(vm.fdii === undefined  ||  (vm.fdii && vm.fdii.CODE_KEY  === undefined )){
                     errorInfo = "Please select FDII ";
                     AlertService.add("error", errorInfo, 4000);
                     return 0;
                 }
            	 
            	 if(vm.adjustmentAmount === undefined ||  vm.adjustmentAmount.length ===0){
            		 errorInfo = "Please select Adjustment Amount ";
                     AlertService.add("error", errorInfo, 4000);
                     return 0;
            	 }*/
            	
            	 otherInfo['sso_id'] = vm.userSettings.user.sso_id;
            	 otherInfo['SCREEN_KEY'] = GlobalService.globalParams.screen_key;
            	 otherInfo['tax_year'] = vm.curentyear;
            	 otherInfo['scenario'] = GlobalService.globalParams.scenario;
            	 otherInfo['jcd_key'] = GlobalService.globalParams.jcd_key;
            	

            	if(!vm.isCreate){
            		returnObj["IS_SINGLE"]= 'S';
            		returnObj["TRANS_TYPE"] = 'INT_EXP_ADJ';
            		vm.intExpAdjDetails.forEach(details => {
            			if(details['operation-type'] === 'D'  || details['operation-type'] === 'U') {
            				if((details['ADJ_NAME'] === undefined  ||   details['ADJ_NAME']  === '' ) || (details['AMOUNT'] === undefined  || details['AMOUNT'] === null  ||   details['AMOUNT']  === '' )){
                                errorInfo = "Please fill all required fields ";
                                AlertService.add("error", errorInfo, 4000);
                                vm.isSaveClicked = false;
                               // return 0;
                            }
            				
            				let newObject = {};
            				newObject['COMBINATION_KEY'] = vm.rowData['COMBINATION_KEY'] ;
            			//	newObject['SIC_CODE'] = vm.rowData['SIC_CODE_KEY'] ;
            				newObject['ADJUSTMENT_KEY'] = details['ADJ_KEY'] ;
            				newObject['GROUP_KEY'] = details['ADJ_KEY'];
            				newObject['COMMENTS'] = details['COMMENTS'];
                    		newObject['ADJUSTMENT_NAME'] = details['ADJ_NAME'];
                    		newObject['AMOUNT'] = details['AMOUNT'];
                    		newObject["TRANS_TYPE"] = 'INT_EXP_ADJ';
                    		newObject['OPERATION_TYPE'] = details['operation-type'];
                    		
                    		transactionDetails.push(newObject);
            			} 
            		})
            		
            		
            		/*returnObj["ADJUSTMENT_KEY"]= vm.rowData.ADJ_KEY;
            		returnObj["GROUP_KEY"]=vm.rowData.GROUP_KEY;*/
            	} else {
            		returnObj["ENTITY"] = vm.entity.KEY;
                	returnObj["COMBINATION_KEY"] =  vm.entity.KEY;
                //	returnObj["ADJ_TYPE_KEY"] = vm.adjustmentType.CODE_KEY;
                //	returnObj["SIC_CODE"] = vm.sicCode.CODE_KEY
                	returnObj["IS_SINGLE"]= 'S';
                	returnObj["TRANS_TYPE"] = 'INT_EXP_ADJ';
                	returnObj["ADJUSTMENT_NAME"] = vm.adjustmentName;
            		returnObj["AMOUNT"] = vm.adjustmentAmount;
                	returnObj["COMMENTS"] = vm.comments;
                	returnObj["OPERATION_TYPE"]= 'I';
                	transactionDetails.push(returnObj);
                	
	                 checkLockObj.push({combination_key:vm.entity.KEY});
	                 var LockObj = {};
	                 LockObj.modal_name=ModalFactory.response.data.modalScreen.modal_name,
	                 LockObj.lockingObj=checkLockObj;
            	}
            	returnObj["TRANS_TYPE"] = 'INT_EXP_ADJ';
            	
            	
            	message = "Interest Expense Adjustment has been successfully saved/updated";
            	if ( vm.isSaveClicked ) 
            	sendDetails(returnObj, transactionDetails, message,otherInfo, LockObj);
            }
            
            vm.saveEdit = function(){
            	console.log("save in Edit");
            }
            vm.cancel = function() {
				$uibModalInstance.dismiss('cancel');
			};
			
			vm.settingAdjustmentAmount = function(event){
				if(vm.adjustmentAmount){
					vm.adjustmentAmount = vm.adjustmentAmount.replace(/(?!^-)[^0-9\.]/g, '')
					let amount =  vm.adjustmentAmount;
					if(amount.indexOf('.') !== -1){
						let amountSp = amount.split('.'),bPre=0,aPre=0,amt = 0;
							bPre = amountSp[0];
							aPre = amountSp[1];
							if(bPre && bPre.length > 20){
									bPre = bPre.substring(0, 19)
							}
							if(aPre && aPre.length > 2){
								aPre = aPre.substring(0, 2)
							}
							vm.adjustmentAmount =  bPre+"."+aPre;
					}else if(amount.length > 20){
						amount = amount.substring(0, amount.length - 1)
						vm.adjustmentAmount = amount;
					}
				}
				/*if(vm.adjustmentAmount){
					let amount =  vm.adjustmentAmount.toString();
					if(amount.indexOf('.') !== -1){
						if(amount.length > 18){
							//return false;
							amount = amount.substring(0, amount.length - 1)
							vm.adjustmentAmount = amount;
						}
					}else if(amount.length > 17){
						amount = amount.substring(0, amount.length - 1)
						vm.adjustmentAmount = parseInt(amount);
					}
					
				}*/
				//return event.keyCode !== 69;
			}
           
        }
    
        return controllers;

    });