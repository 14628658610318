define([
        'angular',
        'datatables',
        './EntityMgmtAppController',
        './EntityMgmtAppService'
    ], function () {
        'use strict';
        var $entityMgmtAppState = null;
        var workflowModule = angular.module('app.entityMgmtApp', ['datatables', 'app.EntityMgmtAppController', 'app.EntityMgmtAppService'])
                                    .provider('$entityMgmtAppState', ['$stateProvider', function ($stateProvider) {
                                        this.$get = function () {
                                            return {
                                                addState: function (stateName, url, controllerAs, templatePrefix, api, gridType, params, views) {
                                                    $entityMgmtAppState.state('entity-mgmt.' + stateName, {
                                                        url: '/' + url,
                                                        type: gridType,
                                                        "api": api,
                                                        templateUrl: templatePrefix ? templatePrefix : null,
                                                        controller: controllerAs ? controllerAs : null,
                                                        params: params,
                                                        resolve: {},
                                                        views: views
                                                    });
                                                }
                                            };
                                        }
                                    }])
                                    .config(['$stateProvider', '$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider, $urlRouterProvider, GENERAL_CONFIG) {
                                        $entityMgmtAppState = $stateProvider;
                                        $stateProvider.state('entity-mgmt', {
                                                          url: '/entity-mgmt',
                                                          templateUrl: 'app/components/EntityMgmtApp/index.html',
                                                          access: ""
                                                      })
                                                      .state('entity-mgmt.list', {
                                                          url: '/list',
                                                          "templateUrl": "app/components/EntityMgmtApp/list-tpl.html",
                                                          "controller": "EntityListCtrl as ctrl",
                                                          type: 'grid',
                                                          "api": {"get": GENERAL_CONFIG.base_url + "/loadGridJson?action_code=pthp8s&grid_id=5509"},
                                                          "noFilters": true,
                                                          access: ""
                                                      })
                                                      // .state('entity-mgmt.diag-summary', {
                                                      //     url: '/diag-summary',
                                                      //     "templateUrl": "app/components/EntityMgmtApp/diag-summary-tpl.html",
                                                      //     "controller": "DiagSummaryCtrl as ctrl",
                                                      //     type: 'grid',
                                                      //     "api": {"get": GENERAL_CONFIG.base_url + "/loadGridJson?action_id=31511&grid_id=5492"},
                                                      //     "noFilters": true,
                                                      //     access: ""
                                                      // })
                                                      // .state('entity-mgmt.diag-maint', {
                                                      //     url: '/diag-maintenance',
                                                      //     "templateUrl": "app/components/EntityMgmtApp/diag-maint-tpl.html",
                                                      //     access: ""
                                                      // })
                                                      .state('entity-mgmt.entity', {
                                                          url: '/entity/:id',
                                                          templateUrl: 'app/components/EntityMgmtApp/entity.html',
                                                          access: ""
                                                      })
                                                      .state('entity-mgmt.entity.entity_details', {
                                                          url: '/entity_details',
                                                          templateUrl: 'app/components/EntityMgmtApp/details-tpl.html',
                                                          access: ""
                                                      })
                                    }]);

        return workflowModule;
    }
);