const { element } = require("angular");

define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.163jAutoEPDataCtrl', [])
    .controller('post163jAutoEPDataCtrl', ['$injector','$scope','GENERAL_CONFIG','AlertService','ModalFactory','GlobalService','rowData','gridData','colData','GridSaveFactory','USER_SETTINGS', '$uibModal', '$log', 'workspaceFactory','$uibModalInstance', post163jAutoEPDataCtrl])


function post163jAutoEPDataCtrl($injector,$scope,GENERAL_CONFIG,AlertService, ModalFactory, GlobalService,rowData,gridData,colData,GridSaveFactory,USER_SETTINGS, $uibModal, $log, workspaceFactory,$uibModalInstance) {
       var vm = this;
       vm.users = [];
       vm.userSettings = USER_SETTINGS;
       vm.logged_in_user = vm.userSettings.user.sso_id;
       $scope.crudLoading = false;
       vm.gridData = gridData;
       vm.batch_process= 'Y';
      // vm.ccKeysString = null;
       vm.modalName = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
       vm.saveData = [];

    vm.cancel = function(){
        $uibModalInstance.dismiss('cancel');
    }
     var activeScreenData = workspaceFactory.activeScreen.data.data;
    vm.refreshData = function(){
	
	          if ($scope.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
	                return;
	        }
    		
    		$scope.crudLoading = true;
	    
        vm.gridObj = {};
        if (vm.gridData != undefined && vm.modalName == 'EPPosting163J') {
          
          for(var key in vm.gridData){
           
                vm.saveData.push({
                  object_id: this.gridData[key].data.object_id,
                  combination_key: this.gridData[key].data.COMBINATION_KEY
                }
              );
           }
           var ccKeys = vm.saveData.map(item => item.combination_key)
		   vm.ccKeysString = ccKeys.join(',');
           vm.batch_process = 'N';
        }
        else if(vm.modalName == 'EPPosting163JBatch')
        {
           vm.batch_process = 'Y';
        }
                    
        console.log(workspaceFactory.activeScreen.data)

                var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=gqc24t";
                   
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = {
	                sso_id:vm.logged_in_user,
        			tax_year:	filterParams.tax_year,
        			scenario: 	filterParams.scenario,
        			jcd_key: 	GlobalService.globalParams.jcd_key,
        			ho_leid_opt:filterParams.ho_leid_opt,
                    combination_key : vm.ccKeysString,
                    batch_process : vm.batch_process,
        			parent_me_string:filterParams.parent_me_string,
        			screen_key:GlobalService.globalParams.screen_key,
        			filing_key:filterParams.filing_key,
        			system:filterParams.system
        			} 
                    
                    service.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            AlertService.add("success", "Data synced Successfully.", 4000);
                            $scope.crudLoading = false;
                            vm.cancel();
                        }
                        else{
                            AlertService.add("error", data.errorMessage, 4000);
                            $scope.crudLoading = false;
                            vm.cancel();
                        }
                    }
                );
                }
        }
    return controllers;

});