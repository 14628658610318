/**
 * Created by 502117899 on 10/15/2015.
 * the whole dataFilters component should be re written to make it more simple and easier to read.
 * right now its very convoluted and over complicated,
 *
 */

//TODO:the whole dataFilters component should be re written to make it more simple and easier to read. Right now its very convoluted and over complicated,

define([
    'angular',
    './DataFiltersController',
    './DataFiltersService',
    './DataFiltersDirectives'


], function () {
    'use strict';
    return angular.module('app.dataFilters', ['app.dataFiltersController','app.dataFiltersService','app.dataFiltersDirective'])
});