
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.ruleEngineNewConfigService',[])

        .factory("RuleEngineNewConfigFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory', function($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory) {
            var ruleEngineNewConfigFactory = {};
            const baseURLLoad = GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=';
            const baseURLSave = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=';
            const URLs = {
                save: 'dszxm0',
                update: 'yublv5',
                linkedAction: GENERAL_CONFIG.common_api_url +'/getDroolsRulesConfigDataByClient',
                delinkAction: 'nrvl3o',
                addAction:'zxs8ob',
                linkCachedObjects:'g1pp14',
                delinkCacheObject: 'cl2s8u',
            };
           
            ruleEngineNewConfigFactory.saveNewRuleData = function(data){
                console.log("Request Payload ::",data);
                data= {
                    url:URLs.save,
                    ...data
                }
            	var promise = $http({
                    method: "POST",
                    url: baseURLLoad+URLs.save,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            };

            ruleEngineNewConfigFactory.updateRuleData = function(data){
                console.log("Request Payload ::",data);
                data= {
                    url:URLs.update,
                    ...data
                }
            	var promise = $http({
                    method: "POST",
                    url: baseURLSave+URLs.update,
                    data: data
                }).then(function (response) {
                    return response.data;
                });
            	
            	return promise;
            };

            ruleEngineNewConfigFactory.getLinkedAction = function(data){
                console.log("Request Payload getLinkedAction::",data);
                
            	var promise = $http({
                    method: "POST",
                    url: URLs.linkedAction,
                    data: data
                }).then(function (response) {

                    return response.data;
                });
            	
            	return promise;
            };

            ruleEngineNewConfigFactory.delinkAction = function(data){
                console.log("Request Payload getLinkedAction::",data);

                var promise = $http({
                    method: "POST",
                    url: baseURLSave + URLs.delinkAction,
                    data: data
                }).then(function (response) {

                    return response.data;
                });
            	
            	return promise;

            };
            
            ruleEngineNewConfigFactory.addAction = function(data){

                console.log("Request Payload getLinkedAction::",data);

                var promise = $http({
                    method: "POST",
                    url: baseURLLoad + URLs.addAction,
                    data: data
                }).then(function (response) {

                    return response.data;
                });
            	
            	return promise;

            }

            ruleEngineNewConfigFactory.getLinkedCachedObjects = function(data){

                console.log("Request Payload getLinkedAction::",data);
                
            	var promise = $http({
                    method: "POST",
                    url: baseURLLoad + URLs.linkedCachedObjects,
                    data: data
                }).then(function (response) {

                    return response.data;
                });
            	
            	return promise;

            }

            ruleEngineNewConfigFactory.delinkCacheObject = function(data){

                console.log("Request Payload getLinkedAction::",data);

                var promise = $http({
                    method: "POST",
                    url: baseURLLoad + URLs.delinkCacheObject,
                    data: data
                }).then(function (response) {

                    return response.data;
                });
            	
            	return promise;

            }

            ruleEngineNewConfigFactory.addCacheObject = function(data){

                console.log("Request Payload getLinkedAction::",data);

                var promise = $http({
                    method: "POST",
                    url: baseURLLoad + URLs.linkCachedObjects,
                    data: data
                }).then(function (response) {

                    return response.data;
                });
            	
            	return promise;

            }

            return ruleEngineNewConfigFactory;
        }])

    return services;
});