define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.firSanctionedCountriesService',[])
       .factory("sanctionedCountriesGroupObjFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
           ,'$q', '$http',
           '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                   ,$q, $http,
                   $stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var service = $injector.get('JsonObjectFactory');
	           var sanctionedCountriesGroupObjFactory ={};
			   var url ={};
			   var url = GENERAL_CONFIG.base_url  + "/saveJsonObject?action_code=vp3h7r";
	           
			   
			   factory.saveSanctionedCoutries = function(_callName , _data){
	               
	               var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               //var filters = workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getFilters();
	              // var filterIndex = filters.find(x=> x.param_name === 'scenario')
                   var rowData = _data.rowData;
	               var jsonObj = [];
	               var jsonSettings = [];

	               jsonObj = [{
	                   sanctioned_country:_data.rowData.SANCTIONED_COUNTRY ,
	                   illegal_payments:_data.rowData.ILLEGAL_PAYMENTS,
	                   ISSUE_KEY: GlobalService.globalParams.issue_id === undefined ? null : parseInt(GlobalService.globalParams.issue_id)
	                 //  primary_scenario : filterIndex.selected.CODE.trim().toUpperCase() === 'EXAM' || filterIndex.selected.CODE.trim().toUpperCase() === 'RAF'? 'Y' :'N'
	               }];
	               jsonSettings = [{
	                   "combination_key" : _data.rowData.COMBINATION_KEY,
	                   "tax_year": GlobalService.globalParams.tax_year,
	                   "scenario": GlobalService.globalParams.scenario,
	                   "jcd_key": GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key
	               }];
	  	           
	               var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
	               var params = _.merge({}, GlobalService.globalParams, filterParams);

	               params = _.extend({jsonSettings: JSON.stringify(jsonSettings) }, params);
	               params = _.extend({ jsonObj: JSON.stringify(jsonObj)}, params);
	  	           
	  	          var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=vp3h7r";
	  	           
	  	          
	  	          	  service.saveJSON(url , params).then(function(data){
	                  if(data.callSuccess === "1"){
	                      AlertService.add("success", "Data saved Successfully.",4000);
	                      var args = {
                                  combination_key: _data.rowData.COMBINATION_KEY,
                                  gridFilter: {
                                      combination_key: _data.rowData.COMBINATION_KEY,object_id:_data.rowData.OBJECT_ID
                                  }
                              };
                              $rootScope.$emit('gridUpdate', args);//$rootScope.$emit('gridUpdate', args);
	                  }else{
                          rowData.ILLEGAL_PAYMENTS = null;
                          rowData.SANCTIONED_COUNTRY = null;
                          AlertService.add("error", data.errorMessage, 4000);
                          $rootScope.$broadcast('gridRefresh', true);
	                      //AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
	                  }
	              });
	            }
	            return factory;
	        }])

	    return services;
	});

    	 