define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.adminFiltersController', [])
        .controller('adminFiltersController', ['USER_SETTINGS','$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal', '$parse', 'ClientAdminFactory', 'GENERAL_CONFIG', adminFiltersController])
        .controller('saveFiltersController', ['JdcManagerFactory', 'rowData', '$uibModalInstance', 'USER_SETTINGS', 'JsonObjectFactory', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal', '$parse', 'ClientAdminFactory', 'GENERAL_CONFIG', saveFiltersController]);

    function adminFiltersController(USER_SETTINGS,$rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal, $parse, ClientAdminFactory) {

        var vm = this;
        vm.name = "Screen List";
        vm.filter = {
            client_key: JSON.stringify( USER_SETTINGS.user.client.client_key)
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function (event, args) {
            var filterObj = (typeof args.gridFilter !== 'undefined') ? args.gridFilter : null;
            delete args['gridFilter'];
            getUpDate(args, filterObj);
        });


        $scope.$on('$destroy', function iVeBeenDismissed() {
            console.log("SCREEN WAS THIS DESTROYED ");
            gridUpdateListener();
        });

        vm.refresh = function () {
            getData();
        };

        // get the clientList initail load of page
        vm.getClientList = function () {
            ClientAdminFactory.getAllClients().then(function (res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function (client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
                // vm.clients = $parse('data.clientResult.clientList')(res);
            });
        };
        vm.getClientList();

        vm.onClientChange = function () {
            getData();
        };

        vm.addScreen = function () {
            var data = {};
            var modalObj = {};
            modalObj.template = "app/components/admin/adminFilters/admin-filter-tpl.html";
            modalObj.controler = "saveFiltersController as ctrl";
            modalObj.size = "lg";
           // modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.openModal(modalObj, data);
        };

        var getData = function () {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];

            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("data : ", data);
                    $scope.$broadcast("dataLoaded", data);

                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });

        };


        var getUpDate = function (_params, _filterObj) {
            var additionalParams = {};
            var params = _.merge({}, additionalParams, _params);
            params = _.merge({}, params, vm.filter);
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get, params).then(function (data) {
                if (data.callSuccess === "1") {
                    console.log("data", data);
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {
                        filter: _filterObj,
                        data: data.data
                    });
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        };






        $timeout(function () {
            $scope.$broadcast("showLoader", true);
            getData();
        }, 5);




        $scope.ctrlFn = function (_callName, _data) {
            var modalObj = {};
            modalObj.template = "app/components/admin/adminFilters/admin-filter-tpl.html";
            modalObj.controler = "saveFiltersController as ctrl";
            modalObj.size = "lg";
            //modalObj.keyboard = false;
            modalObj.backdrop = "static";
            vm.openModal(modalObj, _data);
        };



        vm.openModal = function (modalObj, _data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
               // keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData', $scope.rowData);
            });
        };


    }

    function saveFiltersController(JdcManagerFactory, rowData, $uibModalInstance, USER_SETTINGS, JsonObjectFactory, $rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal, $parse, ClientAdminFactory, GENERAL_CONFIG) {
        var vm = this;
        vm.crudLoadingFilter = false;
        vm.crudLoading = false;
        vm.rowData = rowData;
        vm.filterGroup = {
                filter_group_key: '',
                filter_group_name: '',
                filter_group_type: '',
                filter_group_desc: ''
            },
            vm.filter = {
                filter_key: '',
                param_name: '',
                label: '',
                ui_type: '',
                multiple: '',
                data_url: '',
                data_type: '',
                default_value: '',
                required: '',
                refresh_list: '',
                ui_type_other: '',
                static_values: '',
                filterList: []

            },
            vm.filterMap = {
                filter_key: '',
                filter_map_key: '',
                filter_priority: '',
                Filters: []
            };
        vm.multiple = [{
                name: 'single',
                value: 0
            },
            {
                name: 'multiple',
                value: 1
            }
        ];
        vm.required = [{
            name: 'No',
            value: 0
        },
        {
            name: 'Yes',
            value: 1
        }
    ];
        vm.dataType = [{
                name: 'static',
                value: 'static'
            },
            {
                name: 'load',
                value: 'load'
            },
            {
                name: 'inherit',
                value: 'inherit'
            },
            {
                name:'text',
                value:'text'
            }
        ];
        vm.filtersList = [];
        vm.filterMapList = [];
        var filterKey = '';
        var filterMapKey = '';
        vm.title = "Filter Group";

        vm.addFilter = function () {
            vm.filtersList.unshift({
                filter_key: vm.filter.filter_key,
                param_name: vm.filter.param_name,
                label: vm.filter.label,
                ui_type: vm.filter.ui_type || vm.filter.ui_type_other,
                multiple: vm.filter.multiple,
                data_url: vm.filter.data_url,
                data_type: vm.filter.data_type,
                default_value: vm.filter.default_value,
                required: vm.filter.required,
                refresh_list: vm.filter.refresh_list,
                static_values: vm.filter.static_values,
                ui_type_other: vm.filter.ui_type_other
            });
        };

        vm.deleteFilter = function (filterkey, index) {
            if (filterkey) {
                var url = GENERAL_CONFIG.base_url + '/removeFilter';
                var params = {
                    clientKey: USER_SETTINGS.user.client_key,
                    filterKey: filterkey
                };
                JsonObjectFactory.saveJSON(url, params).then(function (data) {
                    if (data.callSuccess === "1") {

                        vm.getFilters();
                        vm.getFilterByKey();
                        console.log("saved");
                    } else {
                        console.log("error");
                    }
                });

            } else {
                vm.filtersList.splice(index, 1);
            }

        };
        vm.addFilterMap = function () {
            vm.filterMapList.unshift({
                filter_map_key: vm.filterMap.filter_map_key,
                filter_key: vm.filterMap.filter_key,
                filter_priority: vm.filterMap.filter_priority
            });
        };

        vm.deleteFilterMap = function (filterMapKey, index) {
            if (filterMapKey) {
                var url = GENERAL_CONFIG.base_url + '/removeFilterMap';
                var params = {
                    clientKey: USER_SETTINGS.user.client_key,
                    filterMapKey: filterMapKey //vm.filterMap.filter_map_key
                };
                JsonObjectFactory.saveJSON(url, params).then(function (data) {
                    if (data.callSuccess === "1") {

                        vm.getFilters();
                        vm.getFilterByKey();
                        console.log("saved");
                    } else {
                        console.log("error");
                    }
                });

            } else {
                vm.filterMapList.splice(index, 1);
            }



        };


        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        //vm.clearFilter = function () {

        	//vm.filtersList=  {selected:null, list:[]};
        	//vm.filtersList=  [];
//        	vm.filtersList[index].param_name='';
        	//vm.

        	vm.clearFilter = function () {
                _.filter(vm.filtersList, function(item) {
                    item.param_name = "";
                    item.multiple = "";
                    item.required = "";
                    item.label = "";
                    item.data_url = "";
                    item.refresh_list = "";
                    item.ui_type = "";
                    item.data_type = "";
                    item.default_value = "";
                    item.ui_type_other = "";
                });
            };


       // }
        vm.isParamNameMissing = function (index) {
            return $scope.saveFilterFrm['param_name_' + index].$dirty && vm.isMissing(vm.filtersList[index].param_name);
        };

        vm.isLabelMissing = function (index) {
            return $scope.saveFilterFrm['label_' + index].$dirty && vm.isMissing(vm.filtersList[index].label);
        };

        vm.isUITypelMissing = function (index) {
            return $scope.saveFilterFrm['ui_type_' + index].$dirty && vm.isMissing(vm.filtersList[index].ui_type);
        };

        vm.isMultipleMissing = function (index) {
            return $scope.saveFilterFrm['multiple_' + index].$dirty && vm.isMissing(vm.filtersList[index].multiple);
        };
        vm.isDataUrlMissing = function (index) {
            return $scope.saveFilterFrm['data_url_' + index].$dirty && vm.isMissing(vm.filtersList[index].data_url);
        };
        vm.isDataTypeMissing = function (index) {
            return $scope.saveFilterFrm['data_type_' + index].$dirty && vm.isMissing(vm.filtersList[index].data_type);
        };
        vm.isRequiredMissing = function (index) {
            return $scope.saveFilterFrm['required_' + index].$dirty && vm.isMissing(vm.filtersList[index].required);
        };
        vm.isStaticJsonMissing = function (index) {
            return $scope.saveFilterFrm['static_values_' + index].$dirty && vm.isMissing(vm.filtersList[index].static_values);
        };
        vm.isUIOtherMissing = function(index){
            return $scope.saveFilterFrm['ui_type_other_' + index].$dirty && vm.isMissing(vm.filtersList[index].ui_type_other);
        };

        vm.isMissing = function (obj) {
            return (angular.isUndefined(obj) || (obj === null) || (obj === ''));
        };


        vm.saveFilters = function () {

            if (vm.crudLoadingFilter) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            var url = GENERAL_CONFIG.base_url + "/saveUpdateFiltersByClient";

            var isAnyParamNameMissing = false;
            var isAnyLabelMissing = false;
            var isAnyUiTypeMissing = false;
            var isAnyUITypeOtherMissing = false;
            var isMultipleMissing = false;
            var isDataUrlMissing = false;
            var isDataTypeMissing = false;
            var isRequiredMissing = false;
            var isStaticJsonMissing = false;
            angular.forEach(vm.filtersList, function (frmElement, index) {
                $scope.saveFilterFrm['param_name_' + index].$dirty = true;
                $scope.saveFilterFrm['label_' + index].$dirty = true;
                $scope.saveFilterFrm['ui_type_' + index].$dirty = true;
                if(frmElement.data_type === 'load'){
                    $scope.saveFilterFrm['data_url_' + index].$dirty = true;
                    isDataUrlMissing = isDataUrlMissing || vm.isDataUrlMissing(index);
                }else if (frmElement.data_type === 'static'){
                    $scope.saveFilterFrm['static_values_' + index].$dirty = true;
                    isStaticJsonMissing = isStaticJsonMissing || vm.isStaticJsonMissing(index);
                }
                if(frmElement.ui_type === 'Other'){
                    $scope.saveFilterFrm['ui_type_other_' + index].$dirty = true;
                    isAnyUITypeOtherMissing = isAnyUITypeOtherMissing || vm.isUIOtherMissing(index);
                }
                $scope.saveFilterFrm['multiple_' + index].$dirty = true;

                $scope.saveFilterFrm['data_type_' + index].$dirty = true;
                $scope.saveFilterFrm['required_' + index].$dirty = true;


                isAnyParamNameMissing = isAnyParamNameMissing || vm.isParamNameMissing(index);
                isAnyLabelMissing = isAnyLabelMissing || vm.isLabelMissing(index);
                isAnyUiTypeMissing = isAnyUiTypeMissing || vm.isUITypelMissing(index);
                //
                isMultipleMissing = isMultipleMissing || vm.isMultipleMissing(index);

                isDataTypeMissing = isDataTypeMissing || vm.isDataTypeMissing(index);
                isRequiredMissing = isRequiredMissing || vm.isRequiredMissing(index);

            });

            var isRequiredFieldsMissing = isAnyParamNameMissing || isAnyLabelMissing || isAnyUiTypeMissing || isMultipleMissing || isAnyUITypeOtherMissing||
                                          isDataUrlMissing || isDataTypeMissing || isRequiredMissing || isStaticJsonMissing;
            if (isRequiredFieldsMissing) {
                return;
            }
            vm.crudLoadingFilter = true;
            console.log("paramsssdf", params);
              _.each(vm.filtersList, function (item) {
                if (item.ui_type == "Ohter") {
                    item.ui_type = item.ui_type_other;
                }
                if(item.data_type == "load"){
                    item.static_values = "";
                }
               // return item;
            });
            console.log("save filtersList",vm.filtersList);
            var params = {
                client_key: USER_SETTINGS.user.client_key,
                filterJson: JSON.stringify(vm.filtersList)
            };

            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    vm.crudLoadingFilter = false;
                    vm.getFilters();
                    console.log("filter saved");
                    AlertService.add("success","FiltersSaved Successfully",4000);
                } else {
                    vm.crudLoadingFilter = false;
                    console.log("filter save error");
                    AlertService.add("error","Error Occured, while saveing",4000);
                }
            });

        };

        vm.getFilters = function () {
            var url = GENERAL_CONFIG.base_url + "/getFiltersUIType";
            var params = {
                clientKey: USER_SETTINGS.user.client_key,
            };
            console.log("paramsssdf", params);
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    vm.filterMap.filters = data.FilterList;
                    var x = _.uniq(_.map(vm.filterMap.filters, 'ui_type'));
                    /* convert array to object */
                    vm.ui_type = (Object.keys(x).map(key => ({
                        name: x[key],
                        value: x[key]
                    })));

                    vm.other = [{
                        name: "Other",
                        value: "Other"
                    }];
                    vm.ui_type = _.unionBy(vm.ui_type, vm.other);
                    console.log("other", vm.ui_type);


                    console.log("saved", vm.uiType);
                } else {
                    console.log("error");
                }
            });
        };
        vm.getFilters();
        vm.save = function () {
            if (vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }

            var url = GENERAL_CONFIG.base_url + "/saveFilterGroupByClient";
            var params = {
                client_key: USER_SETTINGS.user.client_key,
                filter_group_key: (vm.filterGroup.filter_group_key === null || vm.filterGroup.filter_group_key ===undefined) ? null : vm.filterGroup.filter_group_key,
                filter_group_name: vm.filterGroup.filter_group_name,
                filter_group_desc: vm.filterGroup.desc,
                filter_group_type: vm.filterGroup.filter_group_type,
                filterGroupJson: JSON.stringify(vm.filterMapList)
            };

            vm.crudLoading = true;
            console.log("paramsssdf", params);
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === "1") {
                    $rootScope.$emit('gridUpdate', {});
                    vm.getFilters();
                    vm.getFilterByKey();
                    vm.crudLoading = false;
                    AlertService.add("success","FilterGroup Saved Successfully",4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error","Error Occured, while saveing",4000);
                    vm.crudLoading = false;
                }
            });
        };
        vm.getFilterByKey = function () {
            var url = GENERAL_CONFIG.base_url + "/getFiltersByKey";
            var params = {
                client_key: USER_SETTINGS.user.client_key,
                filter_group_key: vm.rowData === undefined ? '' : vm.rowData.FILTER_GROUP_KEY
            };
            JsonObjectFactory.saveJSON(url, params).then(function (data) {

                if (data.callSuccess === "1") {
                   // $rootScope.$emit('gridUpdate', {});
                    vm.filtersList = data.filterData;
                    vm.filterGroup = data.filterGroupData[0];
                    vm.filterMapList = data.filterMapData;
                  console.log("saved", data);

                } else {
                   console.log("error");

                }
            });
        };
        vm.getFilterByKey();

    }
    return controllers;

});