define([
    'angular',
    './systemLockUnlockController',
    './systemLockUnlockService',
], function () {
    'use strict';
    return angular.module('app.systemLockUnlock', ['app.SystemLockUnlockController', 'app.SystemLockUnlockService'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('shutdown-dst-app', {
                url: '/shutdown-dst-app',
                "templateUrl": "app/components/DST/shutdown-dst-app/system-lock-unlock.html",
                "controller": "SystemLockUnlockCtrl as ctrl",
                "noFilters": true,
                access: ""
            })
        }]);
}
);
