define([
   'angular'

], function () {
   'use strict';

    var services =  angular.module('app.ghostSettlementReclassesService',[])
        .factory("firGhostSettlementScreenFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
            ,'$q', '$http',
            '$stateParams', '$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'

            ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                ,$q, $http,$stateParams, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
                var factory = {};
                var returnObj = {};
                var firGhostSettlementScreenFactory ={};

             firGhostSettlementScreenFactory.deleteSettlementReclasses = function(deleteData, clob_settings, sso_id){
    		 var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=s3iych";
    		 var jsonSettings = JSON.stringify(clob_settings);
    		 var jsonObj = JSON.stringify(deleteData);
			 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		 var params = filterParams;

    		 params = _.merge({},  GlobalService.globalParams , params);
			 params.filing_key = workspaceFactory.activeScreen.filters.filterParams.filing_key;
    		 params.sso_id = sso_id;
    		 params.rp_seq_id = deleteData[0].rec_seq_key;
    		 console.log(params);
    		 var promise = $http({
    			 	method: "post",
    		        url: url,
    		        data: params
    		     }).then(function (response) {
    		    	 var status = response.status;
    		         return response;
    		     });
    		 return promise;
    	 }
                
                return firGhostSettlementScreenFactory;
            }]);

   return services;
});