define([
   'angular'

], function () {
   'use strict';

   var services =  angular.module('app.qrecreditAllocation',[])
       .factory("QREcreditallocationFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector'
           ,'$q', '$http','$stateParams', 'USER_SETTINGS' ,'$timeout', 'GlobalService','DataFiltersFactory', 'GENERAL_CONFIG','workspaceFactory'
    	   
    	   ,function($rootScope,$log,JsonObjectFactory,AlertService, $injector
                   ,$q, $http,$stateParams,USER_SETTINGS, $timeout, GlobalService,DataFiltersFactory, GENERAL_CONFIG,workspaceFactory) {
	           var factory = {};
	           var returnObj = {};
	           var userSettings = USER_SETTINGS;
			   var logged_in_user = userSettings.user.sso_id;
			   var service = $injector.get('JsonObjectFactory');
			   var url ={};
	           
			   
			   factory.savecreditallocation = function(_callName, _data){
	        	
	        	 var params = {
	      	           "tax_year" : GlobalService.globalParams.tax_year,
	      	           "scenario" :GlobalService.globalParams.scenario,
	      	           "jcd_key" : GlobalService.globalParams.jcd_key,
	                }
	        	 
	        	 var jsonObj = JSON.stringify(_data.rowData);
	        	 var jsonSettings = JSON.stringify(_data.rowData);
	             params = _.extend({jsonSettings: jsonSettings}, params);
	        	 params =  _.extend({jsonObj:jsonObj}, params);
	        	var combination_key = _data.rowData.CY_COMBINATION_KEY;
	        	var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=rn8ybp";
	        	/* var promise= JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/saveJsonObject', params).then(function(data) {
	        		 if (data.callSuccess === "1") {
	        			  {
	     					AlertService.add("success", "Data has been saved", 3000);
	            			 }
	        			 var args = {
	        					
	        				CY_COMBINATION_KEY:combination_key,
	 						
	 						gridFilter : {
	 						
	 							CY_COMBINATION_KEY:combination_key,
	 						}
	 					};
	    
	        			 $rootScope.$emit('gridUpdate', args);
	        		 } else {
	        			 if (data.errorMessage
									&& data.errorMessage.length > 0
									&& data.errorMessage !== 'null') {
								AlertService.add("error",
										data.errorMessage);
							} else{
	 					   AlertService.add("error","Data retrieval failed", 3000);
							}
	 				}
					
				});
	        	 
	            return promise;*/
	        	service.saveJSON(url , params).then(function(data){
	        		
	        		 if (data.callSuccess === "1") {
	        			  {
	     					AlertService.add("success", "Data has been saved", 3000);
	            			 }
	        			 var args = {
	        					
	        				CY_COMBINATION_KEY:combination_key,
	 						
	 						gridFilter : {
	 						
	 							CY_COMBINATION_KEY:combination_key,
	 						}
	 					};
	    
	        			 $rootScope.$emit('gridUpdate', args);
	        		 } else {
	        			 if (data.errorMessage
									&& data.errorMessage.length > 0
									&& data.errorMessage !== 'null') {
								AlertService.add("error",
										data.errorMessage);
							} else{
	 					   AlertService.add("error","Data retrieval failed", 3000);
							}
	 				}

				});
	        };
			 
			   

	            return factory;
	        }])

	    return services;
	});

    	 