define([
    'angular'

], function () {
    'use strict';




    var controllers =  angular.module('app.validationRulesControllers',[])
        .controller('validationRulesController', ['$rootScope','$scope','$state','rowData','$uibModal','$log','GridFactory','ModalFactory','$timeout', 'ValidationRulesFactory','JsonObjectFactory','$uibModalInstance', 'GENERAL_CONFIG', validationRulesController])



    function validationRulesController($rootScope,$scope,$state,rowData,$uibModal,$log,GridFactory,ModalFactory,$timeout,ValidationRulesFactory,JsonObjectFactory,$uibModalInstance,GENERAL_CONFIG) {
        var vm = this;
        vm.title = "GTW Rules Engine - Validation Framework - GRID-POC";
        vm.gridDataAfterRules = [];
        vm.rowDataAfterRules = {};
        vm.selectedRow = rowData;
        console.log("vm.selectedRow ::",vm.selectedRow);
        vm.loading = false;
        
        vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
			$rootScope.$broadcast('gtw.admin.rulesEngine.rulesModalClose');
		};
		
		vm.fireRulesOnGridData = function(){
			var data = {};
			vm.loading = true;
			data.url = GENERAL_CONFIG.base_url + "/applyValidationRulesBulk";
			ValidationRulesFactory.fireRulesOnGridData(data).then(function (data) {
				vm.loading = false;
            	console.log("fireRulesOnGridData data ::",data);
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                } else {
                	vm.gridDataAfterRules = data.jsonObject[0].rules_data;
                    console.log("vm.gridDataAfterRules :: ",vm.gridDataAfterRules);
                }
             });
		}

        vm.applyValidationRules = function(){
            var data = {};
            vm.loading = true;
            data.url = GENERAL_CONFIG.base_url + "/applyValidationRules";
            data.id = vm.selectedRow.ID;
            data.le_id = vm.selectedRow.LEID;
            data.me_id = vm.selectedRow.MEID;
            data.purchase_non_us = vm.selectedRow.PURCHASENONUS;
            data.purchase_us = vm.selectedRow.PURCHASEUS;
            data.tax_code = vm.selectedRow.TAXCODE;
            data.currency = vm.selectedRow.CURRENCY;
            data.puchase_base_curr = vm.selectedRow.PURCHASEBASECURR;
            data.taxable_income = vm.selectedRow.TAXABLEINCOME;
            data.tax_rate = vm.selectedRow.TAXRATE;
            data.total_tax = vm.selectedRow.TOTALTAX;
            data.xchange_rate = vm.selectedRow.XCHANGERATE
            
            
            
    		console.log("applyValidationRules() data ::",data);
            ValidationRulesFactory.getRulesData(data).then(function (data) {
            	vm.loading = false;
            	console.log("data ::",data);
                if (data.callSuccess !== "1") {
                    AlertService.add("error", data.errorMessage);
                } else {
                	vm.rowDataAfterRules = data.jsonObject;
                    console.log("vm.rowDataAfterRules :: ",vm.rowDataAfterRules);
                }
             });

        };
        
        vm.applyValidationRules();
       

    }


    return controllers;

});
