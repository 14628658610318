define([
    'angular'

], function () {
    'use strict';
    let services =  angular.module('app.pshipServices1065K3PartIX',[])
        .factory("K3PartIXFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', '$filter',K3PartIXFactory])

    function K3PartIXFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory,$filter) {

        K3PartIXFactory.renderSK3PartIXDetails = function(ctrl) {

            ctrl.part_IX_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IX', SECTION_NAME:'NA', ROW_TYPE: 'PH', ATTRIB_NAME: '1065_K3_IX_NA_NA'});

            ctrl.part_IX_s1_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IX', SECTION_NAME:'1', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_IX_1_NA'});
            ctrl.part_IX_s1_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IX', SECTION_NAME:'1', ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_IX_1'});
            ctrl.part_IX_s1_1065SK3DataTransposed = transpose_part_IX_Section(ctrl.part_IX_s1_1065SK3Data);

            ctrl.part_IX_s2_header_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IX', SECTION_NAME:'2', ROW_TYPE: 'SH', ATTRIB_NAME: '1065_K3_IX_2_NA'});
            ctrl.part_IX_s2_1065SK3Data = $filter("filter")(ctrl.all_1065SK3Data, {PART_NO:'IX', SECTION_NAME:'2', ROW_TYPE: 'CH', ATTRIB_NAME: '1065_K3_IX_2'});
            ctrl.part_IX_s2_1065SK3DataTransposed = transpose_part_IX_Section(ctrl.part_IX_s2_1065SK3Data);

            return ctrl;
        }


        function transpose_part_IX_Section(sectionData) {
            let transposedSectionData = [];

            let o = sectionData.reduce((a, b) => {
                a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
                a[b.LINE_ORDER].push(b);
                return a;
            }, {});
            let a = Object.keys(o).map(function (k) {
                return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
            });
            transposedSectionData = a;

            let c = [];

            for (let i = 0; i < transposedSectionData.length; i++) {

                let z = transposedSectionData[i].Line_data.reduce( (a,b) => {
                    a[b.ROW_OCCURRENCE] = a[b.ROW_OCCURRENCE] || [];
                    a[b.ROW_OCCURRENCE].push(b);
                    return a;
                }, {});

                let y = Object.keys(z).map(function (k) {
                    return { Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED: "N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Row_Occurrence: /*row_occ*/ z[k][0].ROW_OCCURRENCE, Line_data_occ: z[k]};
                });

                if (i == 0) {


                    let m = angular.copy(y);


                    c = angular.copy(y[0]);
                    c.Line_Type = "HEADER_ROW";
                    c.Row_Occurrence = "";
                    c.Trans_details_key = "";
                    c.IS_EDITABLE = "Y";
                    for (var j=0; j < c.Line_data_occ.length; j++){
                        c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
                    }
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
                    }
                    else {
                        y.unshift(c);
                    }


                }
                else {
                    let key = Object.keys(z)[0];
                    if (transposedSectionData[i].Line_data[0].IS_MULTI == 'Y') {
                        y.unshift({Line_Type: "SUB_ROW", Line_no: z[key][0].LINE_NO, Line_data_occ: []});
                    }
                }

                transposedSectionData[i].Line_data = angular.copy(y);

            }

            return transposedSectionData;

        }


        K3PartIXFactory.calcAmtSK3P9 = function(ctrl,formulaObj, cell_name, cell_value, row, col) {

            console.log('in calcAmtSK3');
            console.log('cell_name = ', cell_name);
            console.log('cell_value = ', cell_value);

            let lineAmt = 0;
            let lineFormulaArray;
            let formulaOperandText;
            let targetCellObj;
            let formula;
            let matches;
            let originalValue;
            let line;
            let lineDataList = [];
            let lineData;
            let cellList;

            // find the related formula for the cell whose value is changed
            let formulaObjs = $filter("filter")(ctrl.allFormFormulaListSK3, {ATTRIB_CALC_FORMULA:'('+cell_name+')'});

            for(let k = 0; k < formulaObjs.length; k++) {

                lineAmt = 0;
                formula = formulaObjs[k].ATTRIB_CALC_FORMULA;
                matches = formula.match(/\b[^\d\s]+\b/g);  //     /\b[^\d\s]+\b/g
                console.log('------- formula ----------' , formula);

                if(matches ==  null){
                    lineFormulaArray = formula.split(/[+-/\\*\\]/);
                }

                for(let i = 0; i<lineFormulaArray.length; i++) {

                    lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
                    formulaOperandText = lineFormulaArray[i].trim();
                    console.log('formulaOperandText = ', formulaOperandText);

                    if(k == 0) {

                        for(let x = 0; x < ctrl.part_IX_s2_1065SK3DataTransposed.length; x++) {
                          line =  ctrl.part_IX_s2_1065SK3DataTransposed[x];
                          lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                          for(let y = 0; y < lineDataList.length; y++) {
                              lineData = lineDataList[y];
                              cellList = lineData.Line_data_occ;
                              for(let z = 0; z < cellList.length; z++) {
                                  if(cellList[z].ATTRIB_NAME === formulaOperandText) {
                                      lineAmt = parseFloat(cellList[z].ATTRIBUTE_VALUE);
                                      break;
                                  }
                              }
                          }
                        }
                        //lineAmt = parseFloat($filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaOperandText})[0].ATTRIBUTE_VALUE);
                    }

                    if(parseFloat(lineAmt)>= 0){
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
                    }
                    else
                        formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");


                }

                if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))) {//verifying for NaN by comparing the amount with itself
                    let xyz = eval(formula);


                    for(let x = 0; x < ctrl.part_IX_s2_1065SK3DataTransposed.length; x++) {
                        line =  ctrl.part_IX_s2_1065SK3DataTransposed[x];
                        lineDataList = $filter("filter")(line.Line_data, {Line_Type:'DATA_ROW'});
                        for(let y = 0; y < lineDataList.length; y++) {
                            lineData = lineDataList[y];
                            if(lineData.Line_no == '19') {
                                row =  lineData;
                                break;
                            }
                        }
                    }

                    if(k == 0) {
                        targetCellObj = $filter("filter")(row.Line_data_occ,{ATTRIB_NAME:formulaObjs[k].ATTRIB_NAME})[0];

                        originalValue = targetCellObj.ATTRIBUTE_VALUE;
                        if(originalValue != xyz) {
                            ctrl.setChange(targetCellObj,row);
                        }
                        targetCellObj.ATTRIBUTE_VALUE = xyz.toFixed();

                    }

                }
            }

            return ctrl;

        }

        K3PartIXFactory.obtainChanges = function(ctrl) {


            let a;
            let b;
            let c;
            let cells = [];

            ctrl.partIXS1Changes = [];
            ctrl.partIXS2Changes = [];


            if(ctrl.saveData.length > 0) {

                let part9Data = _.filter(ctrl.saveData, {PART_NO: "IX"});
                if(part9Data !== undefined && part9Data.length > 0) {
                    ctrl.saveData = ctrl.saveData.filter(element => element.PART_NO === "IX");
                }

            }

            if(ctrl.checkValueEnteredInSection(ctrl.part_IX_s1_1065SK3DataTransposed)) {
                for(let x = 0; x < ctrl.part_IX_s1_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_IX_s1_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIXS1Changes.push(c);
                        }
                    }
                }
            }

            if(ctrl.checkValueEnteredInSection(ctrl.part_IX_s2_1065SK3DataTransposed)) {
                for(let x = 0; x < ctrl.part_IX_s2_1065SK3DataTransposed.length; x++) {
                    a = ctrl.part_IX_s2_1065SK3DataTransposed[x];
                    b = $filter("filter")(a.Line_data, {Line_Type:'DATA_ROW'});
                    for( let y = 0; y < b.length; y++) {
                        c = $filter("filter")(b[y].Line_data_occ, {IS_CHANGED_FLAG:'Y'});
                        if (c.length > 0) {
                            b[y].Line_data_occ = c;
                            ctrl.partIXS2Changes.push(c);
                        }
                    }
                }
            }

            for(let row = 0; row < ctrl.partIXS1Changes.length; row++) {
                cells = ctrl.partIXS1Changes[row]
                for(let c = 0; c < cells.length; c++) {
                    ctrl.saveData.push(cells[c]);
                }
            }
            for(let row = 0; row < ctrl.partIXS2Changes.length; row++) {
                cells = ctrl.partIXS2Changes[row];
                for(let c = 0; c < cells.length; c++) {
                    ctrl.saveData.push(cells[c]);
                }
            }

            return ctrl;

        }

        K3PartIXFactory.validateSK3DataPartIX = function(ctrl, k3Data) {
            let cell;
            let line_no;

            if(k3Data != undefined) {

                for(let i = 0; i < k3Data.length; i++) {

                    cell = k3Data[i];
                    line_no = cell.LINE_NO;

                    if(cell.IS_CHANGED_FLAG == 'Y') {
                        if(ctrl.checkValueEnteredInSection(ctrl.part_IX_s2_1065SK3DataTransposed)) {
                            if(line_no == '16') {
                                if(cell.ATTRIB_COL == '0') {
                                    ctrl.ERROR_MESSAGE = null;
                                    if(cell.ATTRIBUTE_VALUE == null || cell.ATTRIBUTE_VALUE == '' || cell.ATTRIBUTE_VALUE == "") {

                                        ctrl.ERROR_MESSAGE = 'Other Payments -- Specify  Cannot be empty ~ ' + ' in Part: ' + cell.PART_NO + ' Section: '+ cell.SECTION_NAME +  ' Line: ' +  line_no + ' Row Occurrence: ' + cell.ROW_OCCURRENCE;
                                    }
                                }
                            }
                        }

                        if(ctrl.ERROR_MESSAGE != null) {
                            ctrl.HAS_ERROR = true;
                            break;
                        }
                    }
                }
            }

            return ctrl;

        }


        return K3PartIXFactory;

    }
    return services;
});