define(
    ['angular'],
    function() {
        'use strict';

        var controllers = angular.module('app.estRolloverctrl',[])
            .controller('estRolloverctrl',['GlobalService', 'AlertService', 'JsonObjectFactory',  '$parse', 
                        '$rootScope','$scope','$http','$filter','$timeout','USER_SETTINGS',
                        'GENERAL_CONFIG', 'workspaceFactory','estRolloverServiceFactory','estqtrmaintenanceServiceFactory',
                         estRolloverctrl]
                         );

    function estRolloverctrl(GlobalService, AlertService, JsonObjectFactory,$parse, $rootScope,$scope,
         $http, $filter, $timeout,USER_SETTINGS,GENERAL_CONFIG, workspaceFactory,estRolloverServiceFactory,estqtrmaintenanceServiceFactory
        ) {
            $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
            var vm = this;
            vm.rollover = {
                tax_year: "",
                out_tax_year : "",
                from_qtr:"",
                to_qtr: "",
                rollover_status: 0
            };
    
            vm.rollovers = [];
            vm.taxYearList = [];
            vm.outTaxYearList = [];
            vm.quaterList = [];
            
           /*  vm.quaterList = [
                {"name":"Quarter 1","value":1},
                {"name":"Quarter 2","value":2},
                {"name":"Quarter 3","value":3},
                {"name":"Quarter 4","value":4},     
                {"name":"Extension","value":5}
            ]; */
            vm.toQuarterList = [];
            vm.lockedQuarter = [];
            vm.fromQuarterList = [];
            vm.getFromAndToQuarterList = function(allQuaterList,fromCache){
                var action_id = 31590;
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = {
                    tax_year: filterParams.tax_year,
                    scenario :filterParams.scenario,
                    jcd_key :GlobalService.globalParams.jcd_key,
                    out_year: filterParams.out_year,
                    workflow:filterParams.wf_name
                }    
                if(!fromCache){
                    vm.lockedQuarter = estRolloverServiceFactory.getLockedQuarters();
                    if(vm.lockedQuarter && vm.lockedQuarter.length>0){
                        _.each(vm.lockedQuarter,function(i){
                            _.each(allQuaterList,function(j){
                                if(i.QTR==j.VALUE){
                                    if(_.filter(vm.fromQuarterList,{VALUE:i.QTR}).length==0){
                                        vm.fromQuarterList.push(j); 
                                    }
                                   
                                }
                            })
                        })
                    }
                }
                estqtrmaintenanceServiceFactory.getMaintanceData(params,action_id).then((response) => {
                    console.log("response quartereList",response);
                    
                    vm.lockedQuarter = response.jsonObject;
                    estRolloverServiceFactory.setLockedQuarter(vm.lockedQuarter);
                    if(vm.lockedQuarter && vm.lockedQuarter.length>0){
                        _.each(vm.lockedQuarter,function(i){
                            _.each(allQuaterList,function(j){
                                if(i.QTR==j.VALUE){
                                    if(_.filter(vm.fromQuarterList,{VALUE:i.QTR}).length==0){
                                        vm.fromQuarterList.push(j); 
                                    }
                                   
                                }
                            })
                        })
                    }
                    getquarterrolloverdataView();
                })
               
            }
            /* getAll Quarters */
            vm.getALLQuarterList = function(){
                var action_id = 31592;
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = {
                    tax_year: filterParams.tax_year,
                    scenario :filterParams.scenario,
                    jcd_key :GlobalService.globalParams.jcd_key,
                    out_year: filterParams.out_year,
                    workflow:filterParams.wf_name
                }    
                estqtrmaintenanceServiceFactory.getMaintanceData(params,action_id).then((response) => {
                    console.log("response quartereList",response);
                    vm.quaterList = response.jsonObject;
                    estqtrmaintenanceServiceFactory.setALLQuarterList(vm.quaterList);
                    vm.getFromAndToQuarterList(vm.quaterList,true);
                
                
                })
        }
            vm.getToQuarters = function(selectedQuarter,index){
                if(vm.quaterList && vm.quaterList.length)
              var toQuarter = _.filter(vm.quaterList,function(item){
                      return (item.value||item.VALUE) > (selectedQuarter && (selectedQuarter.value
                        ||selectedQuarter.VALUE) != undefined ? (selectedQuarter.value|| selectedQuarter.VALUE) : selectedQuarter);
              });
              if(index != undefined){
                  vm.toQuarterList[index]= toQuarter;
              }else{
                  vm.toQuarterList.push(toQuarter);
              }
            }
            var activeScreen = workspaceFactory.activeScreen;
             
            workspaceFactory.checkFilters(workspaceFactory.activeScreen);
            if(activeScreen && activeScreen.data){
                vm.showBackdrop = false;
                vm.rollovers = [];
                vm.result = activeScreen.data
                vm.quaterList = estqtrmaintenanceServiceFactory.getALLQuarterList();
                vm.getFromAndToQuarterList(vm.quaterList,false)
                    angular.forEach(vm.result, function (role,index) {
                        vm.getToQuarters(role.from_quarter,index);
                        vm.rollovers.push({
                            tax_year: role.tax_year,//_.find(vm.taxYearList,{VALUE:role.tax_year}),
                            out_tax_year: role.out_year,// _.find(vm.outTaxYearList,{VALUE:role.out_year}),
                            from_quarter:_.find(vm.fromQuarterList,{VALUE:role.from_quarter}),//_.find(vm.quaterList,function(i){return i.VALUE == role.from_quarter}),
                            to_quarter:_.find(vm.toQuarterList[index],{VALUE:role.to_quarter}),// _.find(vm.toQuarterList,function(i){return i.VALUE == role.from_quarter}),
                            rollover_status:role.rollover_status == 'Y'? 1: 0
                        });
                    });
                    workspaceFactory.checkFilters(workspaceFactory.activeScreen);
            } else {
                vm.showBackdrop = true;
            }
            function getquarterrolloverdataView(fromSave){
                if(!fromSave){
                    $scope.loading = true;
                }
                console.log("on the way");
                vm.quaterList = estqtrmaintenanceServiceFactory.getALLQuarterList();
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = {
                    tax_year: filterParams.tax_year,
                    scenario :filterParams.scenario,
                    jcd_key :GlobalService.globalParams.jcd_key,
                    out_year: filterParams.out_year,
                    workflow:filterParams.wf_name
                }          
                estRolloverServiceFactory.getRolloverData(params).then((response) => {
                    console.log("response",response);
                    vm.rollovers = [];
                    vm.result = JsonObjectFactory.arrObjToLower(response.jsonObject);//response.jsonObject;
                       
                    
                    if (vm.result.length > 0) {
                        
                        angular.forEach(vm.result, function (role,index) {
                            vm.getToQuarters(role.from_quarter,index);
                            vm.rollovers.push({
                                tax_year: role.tax_year,//_.find(vm.taxYearList,{VALUE:role.tax_year}),
                                out_tax_year: role.out_year,
                                 from_quarter:_.find(vm.fromQuarterList,{VALUE:role.from_quarter}),//_.find(vm.quaterList,function(i){return i.VALUE == role.from_quarter}),
                                to_quarter:_.find(vm.toQuarterList[index],{VALUE:role.to_quarter}),
                                rollover_status:role.rollover_status == 'Y'? 1: 0
                            });
                        });
                    }
                    workspaceFactory.setCache(activeScreen, vm.result);
                    workspaceFactory.setCurrentFilters(activeScreen);
                    vm.showBackdrop = false;
                    $scope.loading = false;
                })
            }
        
            $scope.$on('dataFilters:refreshGrid', function (event, data) {
                vm.getALLQuarterList();
               // getquarterrolloverdataView();
            });

        
            vm.addRollover = function(){
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
               
              
                vm.rollovers.push({
                    tax_year: filterParams.tax_year,
                    out_tax_year: filterParams.out_year,
                    from_quarter: vm.rollover.from_quarter,
                    to_quarter: vm.rollover.to_quater,
                    rollover_status: vm.rollover.rollover_status
                });

                vm.getToQuarters(vm.rollover.from_quarter)
            }
            vm.isRowAdded = function(data,index){
                if(vm.rollovers && vm.rollovers.length>0){
                    var x = vm.rollovers[index];
                    var isFound = _.filter(vm.rollovers, function(item,i){
                        return (item.from_quarter == x.from_quarter && item.to_quarter == x.to_quarter && i != index);
                    });
                    if(isFound && isFound.length>0){
                        AlertService.add('warning',"Selected Quarters are already roll-forwarded",4000);
                        vm.rollovers.splice(index, 1);
                        return;
                    }
                 }
            }

            vm.deleteRollover = function(index){
                    vm.rollovers.splice(index, 1);
                    vm.toQuarterList.splice(index, 1);
            }
            vm.isMissing = function (obj) {
                return (angular.isUndefined(obj) || (obj == null) || (obj === ''));
            };
            vm.fromQuarterMissing = function(index){
                return $scope.saveRolloverFrm['from_quarter_' + index].$dirty && vm.isMissing(vm.rollovers[index].from_quarter);
            }
            vm.toQuarterMissing = function(index){
                return  $scope.saveRolloverFrm['from_quarter_' + index].$dirty && vm.isMissing(vm.rollovers[index].to_quarter);
            }
            vm.saveRollover = function(obj,index){
               
                    var isFromQuarterMissing = false;
                    var isToQuarterMissing = false;
                    angular.forEach(vm.rollovers, function (frmElement, index) {
                        $scope.saveRolloverFrm['from_quarter_' + index].$dirty = true;
                        $scope.saveRolloverFrm['to_quarter_' + index].$dirty = true;
        
                        isFromQuarterMissing = isFromQuarterMissing | vm.fromQuarterMissing(index);
                        isFromQuarterMissing = isFromQuarterMissing | vm.toQuarterMissing(index);
                    })
                    vm.isFromQuarterMissing = vm.fromQuarterMissing(index);
                    vm.isToQuarterMissing = vm.toQuarterMissing(index);
                    var isRequiredFieldsMissing =   isFromQuarterMissing || isToQuarterMissing;
                    if(isRequiredFieldsMissing){
                        vm.rollovers[index].rollover_status = 0;
                        return;
                    }                        
                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params.tax_year = obj.tax_year;
                    params.out_year = obj.out_tax_year;
                    params.scenario = filterParams.scenario;//33
                    params.from_qtr = obj.from_quarter.VALUE;
                    params.to_qtr = obj.to_quarter.VALUE;
                    params.workflow = filterParams.wf_name;
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=31540";
                    JsonObjectFactory.saveJSON(url, params).then(function (data) {
                        if (data.callSuccess === "1") {
                            getquarterrolloverdataView({fromSave:true});
                            AlertService.add("success", "Data saved Successfully.", 4000);
                        } else {
                            AlertService.add("error", data.errorMessage, 4000);
                        }
                    });
                

            }
            vm.cancelModel = function($index){
                vm.rollovers[$index].rollover_status =0;
                console.log("clicked");
               // $uibModalInstance.close();
            }
        }
            return controllers;
        }
);