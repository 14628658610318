/**
 * Dasboards Directives
 */
define(['highstock',"angular"], function (Highcharts) {
    "use strict";

    // Load module after Highcharts is loaded
    require('highcharts-more')(Highcharts); 
    require('highcharts-3d')(Highcharts); 
    require('highcharts-exporting')(Highcharts); 
    require('highcharts-treemap')(Highcharts); 
    require('highcharts-variable-pie')(Highcharts); 
    require('highcharts-drilldown')(Highcharts); 

    Highcharts.setOptions({
        lang: {
            numericSymbols: ['K', 'M', 'B','T']
        }
    });
    var directives = angular
        .module("app.dashboardDirectives", [])


        .directive('hcMultiSeriesChart', hcMultiSeriesChart)

        .directive('hcSingleSeriesChart', hcSingleSeriesChart)

        .directive('hcTaxReconciliation', hcTaxReconciliation)

        .directive('hcSunBurst', hcSunBurst)

        .directive('hcTreeMap', hcTreeMap)

        // .directive('gtDatatable', gtDatatable)

        .directive('percentageBar', percentageBar)

        .directive('kpiBox', kpiBox)

        .directive('horizontalScroll', horizontalScroll)

        .directive('regularTreeMap', regularTreeMap)

        .directive('comparisionChart', comparisionChart);


    horizontalScroll.$inject = [];
    function horizontalScroll() {
        return {
            link: link,

        }
        function link(scope, element, attr) {

            $(element).on("mousewheel", scrollHorizontally);//chrome, IE
            $(element).on("DOMMouseScroll", scrollHorizontally);//firefox




            function scrollHorizontally(e) {

                e = window.event || e.originalEvent;
                var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                console.log("delta", delta)
                $(element)[0].scrollLeft -= delta * 100;
                e.preventDefault();
            }
        }
    }

    hcSunBurst.$inject = [];
    function hcSunBurst() {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            controller: SunBurstController,
            controllerAs: 'sunBurstCtrl',
            link: link,
            scope: {
                data: '='
            }
        };
        return directive;

        function link(scope, element, attrs, sunBurstCtrl) {

            var panel = element.parent();

            if (sunBurstCtrl.fullWidth)
                sunBurstCtrl.options.chart.width = panel.width();

            sunBurstCtrl.chart = Highcharts.chart(element[0], sunBurstCtrl.options);
        }
        /* @ngInject */
        function SunBurstController() {
            var vm = this;

            vm.options = {

                chart: {
                    height: '340px'
                },

                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                series: [{
                    type: "sunburst",
                    data: vm.data,
                    allowDrillToNode: true,
                    cursor: 'pointer',
                    dataLabels: {
                        format: '{point.name}',
                        filter: {
                            property: 'innerArcLength',
                            operator: '>',
                            value: 16
                        }
                    },
                    levels: [{
                        level: 1,
                        levelIsConstant: false,
                        dataLabels: {
                            filter: {
                                property: 'outerArcLength',
                                operator: '>',
                                value: 64
                            }
                        }
                    }, {
                        level: 2,
                        colorByPoint: true
                    },
                    {
                        level: 3,
                        colorVariation: {
                            key: 'brightness',
                            to: -0.5
                        }
                    }, {
                        level: 4,
                        colorVariation: {
                            key: 'brightness',
                            to: 0.5
                        }
                    }]

                }],
                tooltip: {
                    headerFormat: "",
                    pointFormat: 'The population of <b>{point.name}</b> is <b>{point.value}</b>'
                }
            }
        }
    }

    // .directive('horizontalScroll' , horizontalScroll);



    // horizontalScroll.$inject = [];
    // function horizontalScroll(){
    //     var directive = {
    //         link : link,
    //         scope : {},
    //         controller : horizontalScrollController,
    //         controllerAs : 'horizontalScrollCtrl',
    //         bindToController : true,
    //         replace : true
    //     }
    // }    

    hcTaxReconciliation.$inject = ['$timeout'];
    function hcTaxReconciliation($timeout) {
        var directive = {
            link: link,
            replace: true,
            templateUrl: "app/components/dashboardExecutive/templates/pieChartCommon-tpl.html",
            scope: {
                masterData: "=",
                data: "=",
                multiSeriesGroupBy: "@",
                singleSeriesGroupBy: "@",
                popupGroupBy: "@",
                singleSeriesChartOptions: '=',
                amountProperty: "@"
            },
            bindToController: true,
            controller: ['$scope', parentController],
            controllerAs: 'parentCtrl'
        }
        return directive;

        function link(scope, element, attrs, parentCtrl) {
            var resizerSensor;
            scope.$watch('parentCtrl.data', function (newVal, oldVal) {
                if (!_.isEqual(newVal, oldVal)) {
                    parentCtrl.singleSeriesChartOptions.series[0].data = newVal;
                    console.log('filtered Pie')
                }
            })



        }

        function parentController($scope) {
            var vm = this;
            console.log("Parent Controller VM", vm)
            if (vm.multiSeriesGroupBy) {
                vm.hideBar = true;

            }
            else {
                vm.hideBar = false;

            }

            vm.singleSeriesChartOptions.series[0].point = {};
            vm.singleSeriesChartOptions.series[0].point.events = {};

            vm.singleSeriesChartOptions.series[0].point.events.click = vm.hideBar ? showBarChart : defaultDataPointClick;

            $timeout(function () {
                showBarChart(vm.singleSeriesChartOptions.series[0].data[0].name);
                vm.selectedName = vm.singleSeriesChartOptions.series[0].data[0].name;
            }, 100);

            $scope.$watch('parentCtrl.masterData', function (newVal, oldVal) {
                if (!_.isEqual(newVal, oldVal)) {
                    //mock the click event
                    //TODO: Improve logic
                    showBarChart();
                    console.log('filtered nested bar')
                }
            })

            function showBarChart(name) {

                if (name) {
                    vm.selectedName = name;
                    if (name.type) {
                        vm.selectedName = this.name
                    }

                }

                //TODO: Refactory logic using lodash
                var series = getInsightChart(vm.masterData, vm.singleSeriesGroupBy, vm.multiSeriesGroupBy, vm.selectedName, false, null, vm.amountProperty);


                vm.sideChartOptions = {
                    chartTypes: ['Column', 'Bar', 'Line'],
                    chart: {
                        type: 'bar',

                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: _.map(series, 'name'),
                        scrollbar: {
                            enabled: true,
                        },
                        min: 0,
                        max: 7

                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: false,
                    plotOptions: {
                        series: {
                            pointPadding: 0.9,
                            pointWidth: 20,

                            dataLabels: {
                                enabled: true,
                                overflow: 'justify',
                                allowOverlap: false,
                                align: "right",
                                formatter: function () {
                                    return '<span class="text-center" style="margin: 0px !important;font-size : 12px;">' + convertAmount(this.y) + '</span>'
                                }

                            }
                        }

                    },
                    series: [{
                        data: _.map(series, 'amount'),
                    }],
                    exporting: {
                        chartOptions: {


                            plotOptions: {
                                series: {
                                    pointPadding: 0.9,
                                    pointWidth: 5,
                                    dataLabels: false,
                                }
                            },
                            xAxis: [{
                                categories: _.map(series, 'name'),
                                scrollbar: {
                                    enabled: false,
                                },

                            }]
                        }
                    }

                }
                if (name)
                    $scope.$apply();
            }

            function defaultDataPointClick(e) {
                var selectedName = this.name;

                if ($(".ui-dialog")[0]) {
                    $(".ui-dialog").remove();
                }
                var $div = $('<div></div>')
                    .dialog({
                        title: this.name,
                        width: 800,
                        height: 700,
                        closeText: '',
                        show: {
                            effect: 'fold',
                            horizFirst: true
                        },
                        hide: 'fold',
                        resizable: true,
                        position: {
                            of: e.point.graphic.element,
                            my: 'left bottom',
                            at: 'right bottom'
                        },
                        create: function (event, ui) {
                            var widget = $(this).dialog("widget");
                            $(".ui-dialog-titlebar-close span.ui-button-icon", widget).removeClass("ui-icon-closethick").addClass("fa fa-times");
                        },
                        // open:function() {
                        //     $(".ui-dialog").css("position", "absolute")
                        //     $(".ui-dialog").css("top", srcElementPos.top)
                        //     $(".ui-dialog").css("left", srcElementPos.left + 160);
                        // }
                        // dialogClass: 'dialogPosition',
                        // position: [0,28]
                    });
                getInsightChart(vm.masterData, vm.singleSeriesGroupBy, vm.popupGroupBy, selectedName, true, $div, vm.amountProperty);
            }
        }
    }


    hcMultiSeriesChart.$inject = ['$timeout', '$filter'];
    function hcMultiSeriesChart($timeout) {
        var directive = {
            link: link,
            scope: {
                id: '@',
                options: '=',
                sheetData: '=',
                tooltipPopup: '=',
                popupGroupBy: '@',
                fullWidth: '=',
                isResize: '=',
                displayProperty: '@',
                amountProperty: '@'
            },
            bindToController: true,
            controller: ['$scope', '$filter',multiChartController],
            controllerAs: "multiChartCtrl"
        };
        return directive;

        function link(scope, element, attrs, multiChartCtrl) {
            // var panel = element.closest('.gt-dashboard-ex');
            var panel = element.parent().parent();
            var resizerSensor;
            console.log("Summary multi chart options", multiChartCtrl.options);


            var timeout;

            multiChartCtrl.options.chart.events.load = function () {
                resizerSensor = new ResizeSensor(panel, function () {

                    // clearTimeout(timeout);

                    // timeout = setTimeout(doneResizing, 50);//This is to avoid calling the setsize on each animation frame

                    // function doneResizing() {
                    var width;
                    var height
                    // if (multiChartCtrl.isResize) {
                    if (multiChartCtrl.fullWidth) {
                        width = $(panel).width();
                        height = $(panel).height() - 35;
                    } else {
                        width = multiChartCtrl.chart.chartWidth;
                        height = $(panel).height();
                    }
                    if (multiChartCtrl.chart)
                        multiChartCtrl.chart.setSize(width, height, false);
                    console.log('resize detected width:' + width + ' height:' + $(panel).height() + ' fullwidth:' + multiChartCtrl.fullWidth);
                    // }
                    // }

                });
            }



            // Wait for Container to render.
            $timeout(function () {
                if (multiChartCtrl.fullWidth)
                    multiChartCtrl.options.chart.width = panel.width();
                multiChartCtrl.chart = Highcharts.chart(element[0], multiChartCtrl.options);

                // multiChartCtrl.chart.options.exporting.buttons.contextButton.menuItems.push('Column')

            }, 500)

            // chart.setSize(panel.width()-50,panel.height());

            var watcher = scope.$watch(
                "multiChartCtrl.options",
                function (newVal, oldVal) {
                    if (newVal) {
                        if (newVal.chart.type != oldVal.chart.type) {
                            if (multiChartCtrl.chart)
                                multiChartCtrl.chart.destroy();//Force trigger the animation
                            multiChartCtrl.chart = Highcharts.chart(element[0], newVal);
                            console.log('multi series chart hard refreshed');

                        } else {
                            if (!_.isEqual(newVal.series, oldVal.series)) {
                                // _.each(multiChartCtrl.chart.series,function(series,index) {
                                //     if(series)
                                //         series.remove(false);
                                // })
                                var length = multiChartCtrl.chart.series.length;
                                // if (multiChartCtrl.options.xAxis.scrollbar) {

                                //     if (length < multiChartCtrl.options.xAxis.maxData) {
                                //         multiChartCtrl.options.xAxis.scrollbar.enabled = false;
                                //  tc       multiChartCtrl.options.xAxis.min = null;
                                //         multiChartCtrl.options.xAxis.max = null;
                                //     }
                                //     else {
                                //         multiChartCtrl.options.xAxis.scrollbar.enabled = true;
                                //         multiChartCtrl.options.xAxis.min = 0;
                                //         multiChartCtrl.options.xAxis.max = multiChartCtrl.options.xAxis.maxData;
                                //     }
                                // }

                                for (var i = 0; i < length; i++) {
                                    multiChartCtrl.chart.series[0].remove(false);
                                }
                                _.each(newVal.series, function (series, index) {
                                    multiChartCtrl.chart.addSeries(series);
                                })
                                multiChartCtrl.chart.redraw();
                                console.log('multi series chart changed, redrawn');
                            }
                            if (multiChartCtrl.chart)
                                multiChartCtrl.chart.update(newVal);
                        }
                    }
                },
                true
            );
            scope.$on('$destroy', function () {
                console.log('chart destroyed');
                watcher();
                if (multiChartCtrl.chart) multiChartCtrl.chart.destroy();
                multiChartCtrl.chart = null;
                element.remove();
                if (resizerSensor)
                    resizerSensor.detach();
            })

        }

        /* @ngInject */
        function multiChartController($scope, $filter) {
            var vm = this;
            //Common Defaults
            vm.options.plotOptions = vm.options.plotOptions || {};
            vm.options.plotOptions.series = vm.options.plotOptions.series || {};
            vm.options.plotOptions.series.point = vm.options.plotOptions.series.point || {};
            vm.options.plotOptions.series.point.events = vm.options.plotOptions.series.point.events || {};
            vm.options.plotOptions.series.dataLabels = vm.options.plotOptions.series.dataLabels || {};
            vm.options.plotOptions.column = vm.options.plotOptions.column || {};
            vm.options.plotOptions.bar = vm.options.plotOptions.bar || {};
            vm.options.yAxis = vm.options.yAxis || {};
            vm.options.yAxis.title = vm.options.yAxis.title || { text: "Y-Axis" };
            vm.options.chart = vm.options.chart || {};
            vm.options.chart.type = vm.options.chart.type || 'column';
            vm.options.chart.events = vm.options.chart.events || {};
            vm.options.chart.animation = _.isUndefined(vm.options.chart.animation) ? true : vm.options.chart.animation;
            // vm.options.plotOptions.series.pointPadding = vm.options.plotOptions.series.pointPadding || 0.9;
            vm.options.plotOptions.series.borderWidth = vm.options.plotOptions.series.borderWidth || 0;
            // vm.options.plotOptions.series.minPointLength = vm.options.plotOptions.series.minPointLength || 3;
            // vm.options.plotOptions.series.dataLabels.align = vm.options.plotOptions.series.dataLabels.align || 'center'
            // 
            vm.options.plotOptions.series.dataLabels.allowOverlap = _.isUndefined(vm.options.plotOptions.series.dataLabels.allowOverlap) ? false : vm.options.plotOptions.series.dataLabels.allowOverlap;

            vm.options.plotOptions.series.dataLabels.overflow = _.isUndefined(vm.options.plotOptions.series.dataLabels.overflow) ? 'justify' : vm.options.plotOptions.series.dataLabels.overflow;
            vm.options.plotOptions.series.dataLabels.useHTML = _.isUndefined(vm.options.plotOptions.series.dataLabels.useHTML) ? true : vm.options.plotOptions.series.dataLabels.useHTML;
            vm.options.plotOptions.series.dataLabels.enabled = _.isUndefined(vm.options.plotOptions.series.dataLabels.enabled) ? true : vm.options.plotOptions.series.dataLabels.enabled;
            vm.options.plotOptions.series.dataLabels.formatter = vm.options.plotOptions.series.dataLabels.formatter || defaultDataLabelFormatter;
            vm.options.plotOptions.series.cursor = vm.options.plotOptions.series.cursor || 'pointer';
            if (vm.tooltipPopup)
                vm.options.plotOptions.series.point.events.click = vm.options.plotOptions.series.point.events.click || defaultDataPointClick;
            //Column chart defaults
            // vm.options.plotOptions.column.pointWidth = vm.options.plotOptions.column.pointWidth || 120;
            vm.options.plotOptions.column.stacking = vm.options.plotOptions.column.stacking || 'normal';
            // vm.options.plotOptions.bar.pointWidth = vm.options.plotOptions.bar.pointWidth || 70;
            vm.options.plotOptions.bar.stacking = _.isUndefined(vm.options.plotOptions.bar.stacking) ? false : 'normal';
            vm.options.responsive = vm.options.responsive || {};
            vm.options.responsive.rules = vm.options.responsive.rules || [];
            vm.options.responsive.rules.push({ condition: { maxWidth: 1500, minHeight: 350 } });

            vm.options.tooltip = vm.options.tooltip || {};
            vm.options.tooltip.useHTML = _.isUndefined(vm.options.tooltip.useHTML) ? true : vm.options.tooltip.useHTML;
            vm.options.tooltip.backgroundColor = vm.options.tooltip.backgroundColor || "white";
            vm.options.tooltip.borderColor = vm.options.tooltip.borderColor || "#9ab";
            vm.options.tooltip.style = vm.options.tooltip.style || {};
            vm.options.tooltip.style.zIndex = vm.options.tooltip.style.zIndex || 100;
            vm.options.tooltip.formatter = vm.options.tooltip.formatter || defaultTooltipFormatter;
            vm.options.exporting = vm.options.exporting || {};
            vm.options.exporting.allowHTML = true;
            vm.options.exporting.buttons = vm.options.exporting.buttons || {};
            vm.options.exporting.buttons.contextButton = vm.options.exporting.buttons.contextButton || {};
            vm.options.exporting.menuItemDefinitions = vm.options.exporting.menuItemDefinitions || {};
            vm.options.exporting.buttons.contextButton.menuItems = ["separator", "viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
            vm.options.exporting.menuItemDefinitions.chartOptions = {
                text: 'Chart Options',
            }
            //Adding Chart Configurations
            if (vm.options.chartTypes) {
                for (var i = 0; i < vm.options.chartTypes.length; i++) {
                    vm.options.exporting.menuItemDefinitions[vm.options.chartTypes[i]] = {
                        text: vm.options.chartTypes[i],
                        onclick: changeChartType
                    }
                    vm.options.exporting.buttons.contextButton.menuItems.unshift(vm.options.chartTypes[i])
                }
            }

            var $div = null;
            ////////////

            function changeChartType(e) {
                console.log("Chart Type Clicked", e.target.innerText.toLowerCase());
                vm.options.chart.type = e.target.innerText.toLowerCase();
                $scope.$apply()
            }

            function defaultTooltipFormatter() {
                if (this.y < 0) {
                    return '<span>' + this.series.name + " : " + "(" + $filter('convertAmount')(Math.abs(this.y), '$') + ' )</span>';

                }
                else {
                    return '<span>' + this.series.name + " : " + $filter('convertAmount')(this.y, '$') + '</span>';

                }

            }

            function formatY(amount) {
                return "$" + amount.toLocaleString();
            }

            function defaultDataLabelFormatter() {
                if (this.y === 0) {
                    return null
                }
                else if (this.y < 0) {
                    return '<span class="text-center" style="margin: 0px !important;font-size : 12px;">' + this.point.series.name + " : </span><span class='text-center negative-data' style='margin: 0px !important;font-size : 12px;'>" + "(" + $filter('convertAmount')(Math.abs(this.y), '$') + ' )</span>';

                }
                else if (this.y > 0) {
                    return '<span class="text-center" style="margin: 0px !important;font-size : 12px;">' + this.point.series.name + " : </span><span class='text-center' style='margin: 0px !important;font-size : 12px;'>" + $filter('convertAmount')(this.y, '$') + '</span>';
                }

            };
            function defaultDataPointClick(e) {
                var selectedCategory = this.category;
                if ($(".ui-dialog")[0]) {
                    $(".ui-dialog").remove();
                }
                $div = $('<div></div>')
                    .dialog({
                        title: this.name,
                        width: 800,
                        height: 700,
                        closeText: '',
                        show: {
                            effect: 'fold',
                            horizFirst: true
                        },
                        hide: 'fold',
                        resizable: true,
                        position: {
                            of: e.point.graphic.element,
                            my: 'left bottom',
                            at: 'right bottom'
                        },
                        create: function (event, ui) {
                            var widget = $(this).dialog("widget");
                            $(".ui-dialog-titlebar-close span.ui-button-icon", widget).removeClass("ui-icon-closethick").addClass("fa fa-times");
                        },
                        // open:function() {
                        //     $(".ui-dialog").css("position", "absolute")
                        //     $(".ui-dialog").css("top", srcElementPos.top)
                        //     $(".ui-dialog").css("left", srcElementPos.left + 160);
                        // }
                        // dialogClass: 'dialogPosition',
                        // position: [0,28]
                    });

                getInsightChart(vm.sheetData, vm.displayProperty, vm.popupGroupBy, selectedCategory, true, $div, vm.amountProperty);
            }





        }
    }


    // gtDatatable.$inject = ['$compile', '$filter', '$uibModal' , '$templateCache'];
    // function gtDatatable($compile, $filter, $uibModal , $templateCache) {
    //     // Usage:The parent div of the grid should have position:relative as the grid will fill the parent as absolute.
    //     //
    //     // Creates:
    //     //
    //     var directive = {
    //         link: link,
    //         scope: {
    //             tableId: '@',
    //             groupBy: '=',
    //             searchable: '=',
    //             footerType: "@",
    //             headerGroupBy: "=",
    //             sheetData: "=",
    //             childTableOptions: '=',
    //             parentTableOptions: "=",
    //             childTableColumnProperties: "=",
    //             parentTableColumnProperties: "=",
    //             topFilter: '=',
    //             temp : '@'
    //         },
    //         //     <div class='context-toolbar'>
    //         //     <div class='group-data'>
    //         //         <i class='fa fa-object-group' ng-click="groupByList()">

    //         //         </i>
    //         //         <div class="group-by-list" ng-if="toggleGroupBy">
    //         //         <div class="wrapper">
    //         //             <ul>
    //         //                 <div ng-repeat="node in groupNames">
    //         //                     <li>{{node.name}} </li>
    //         //                     <hr />
    //         //                 </div>
    //         //             </ul>
    //         //         </div>
    //         //     <div>
    //         //     </div>
    //         // </div>
    //         template: `<div class='grid-wrapper'>
            
    //                     </div>`,
    //         controller: gtDatatableController
    //     };
    //     return directive;

    //     function link(scope, element, attrs) {
    //         if (scope.childTableOptions) {
    //             scope.childTableOptions.columns = buildChildTableColumns(scope.childTableColumnProperties.columns)
    //         }


    //         var childTableOptions = _.cloneDeep(scope.childTableOptions);
    //         var totalRevenues = {};
    //         var selectedRevenueProperty = [];
    //         var actualOptions;
    //         var groupedArray = [];
    //         var resizerSensor;
    //         var global;
    //         var oldGroupBy;
    //         var oldData;
    //         var remainingData;
    //         var nonAggregateColumnsSafeCopy = [];
    //         var trs;

    //         if (scope.parentTableColumnProperties.nonAggregateColumns) {
    //             nonAggregateColumnsSafeCopy = scope.parentTableColumnProperties.nonAggregateColumns.slice(0);
    //         }
    //         var parentTableOptionsSafeCopy = angular.copy(scope.parentTableOptions)
    //         selectedRevenueProperty = scope.parentTableColumnProperties.aggregateColumns;

    //         $(element[0].firstChild).append("<table id=" + scope.tableId + " class='table table-bordered table-striped parent-table table-responsive table-condensed'></table>");

    //         console.log("scope.id : 999999999999999999999999", scope.tableId)

    //         // scope.parentTableOptions.searching = scope.searchable;

    //         scope.table = scope.groupBy ? groupTable() : renderGrid();

    //         $(element[0]).find('tfoot').addClass(scope.footerType);

    //         var panel = element.closest('.gt-dashboard-ex');
    //         // chart.setSize(panel.width()-50,panel.height());
    //         if (panel.length > 0) {
    //             var grids = panel.find('.grid-wrapper');
    //             if (grids.length > 1) {
    //                 grids.css({
    //                     position: 'relative'
    //                 })
    //             }
    //             var timeout;
    //             resizerSensor = new ResizeSensor(panel, function () {

    //                 clearTimeout(timeout);

    //                 timeout = setTimeout(doneResizing, 50);//This is to avoid calling the setsize on each animation frame

    //                 function doneResizing() {
    //                     // console.log('resize detected, panel height',panel.height())
    //                     var grids = panel.find('.grid-wrapper');
    //                     console.log(grids.length + " grids found in the panel.");
    //                     grids.height((100 / grids.length) - 5 + '%');
    //                     if (scope.groupBy) {
    //                         scope.table.fnDraw();
    //                         console.log('table redrawn');
    //                     }
    //                 }
    //             });
    //         }

    //         registerEvents();
    //         // buildDataColumns();

    //         var watcherForGroupBy = scope.$watch('groupBy', function (newVal, oldVal) {
    //             if (newVal && !_.isEqual(newVal, oldVal)) {

    //                 if (scope.groupBy) {
    //                     oldGroupBy = oldVal;
    //                     scope.groupBy = newVal;
    //                     if (!nonAggregateColumnsSafeCopy.includes(scope.groupBy)) {
    //                         for (var i = 0; i < nonAggregateColumnsSafeCopy.length; i++) {
    //                             if (nonAggregateColumnsSafeCopy[i] === oldGroupBy) {
    //                                 nonAggregateColumnsSafeCopy[i] = scope.groupBy
    //                             }
    //                         }
    //                     }
    //                     groupTable(false);
    //                 } else {
    //                     filterTable(scope.sheetData);
    //                 }
    //             }

    //         })
    //         var watcher = scope.$watch('sheetData', function (newVal, oldVal) {
    //             if (newVal && !_.isEqual(newVal, oldVal)) {
    //                 // if (oldData !== oldVal) {
    //                 //     oldData = newVal;
    //                 if (scope.groupBy) {
    //                     if (!nonAggregateColumnsSafeCopy.includes(scope.groupBy)) {
    //                         for (var i = 0; i < nonAggregateColumnsSafeCopy.length; i++) {
    //                             if (nonAggregateColumnsSafeCopy[i] === oldGroupBy) {
    //                                 nonAggregateColumnsSafeCopy[i] = scope.groupBy
    //                             }
    //                         }
    //                     }
    //                     groupTable(false);
    //                 } else {
    //                     filterTable(scope.sheetData);
    //                 }

    //             }
    //             // }
    //         })

    //         scope.$on('$destroy', function () {
    //             console.log('datatable destroyed');
    //             watcher();
    //             watcherForGroupBy();
    //             element.remove();
    //             if (resizerSensor)
    //                 resizerSensor.detach();
    //         })

    //         ////////////

    //         function renderGrid() {

    //             if (scope.parentTableOptions.footer) {
    //                 var footer = "<tfoot><tr>";
    //                 _.each(scope.parentTableOptions.columns, function (col) {
    //                     footer += "<th></th>"
    //                 })
    //                 footer += "</tr></tfoot>";
    //                 $(element).find('tfoot').remove();
    //                 $(element).find("#" + scope.tableId).append(footer);
    //                 $(element[0]).find('tfoot').addClass(scope.footerType);
    //             }
    //             if (scope.table) {
    //                 scope.table.fnClearTable();
    //                 scope.table.fnDestroy();
    //             }




    //             scope.parentTableOptions.deferRender = true;
    //             buildDataColumns(scope.parentTableOptions);
    //             contextGroupBy(scope.parentTableOptions);
    //             // setFirstColumn(scope.parentTableOptions)
    //             scope.table = $(element[0].firstChild).find("#" + scope.tableId).dataTable(scope.parentTableOptions);

    //             // scope.table.fnDraw();
    //             return scope.table
    //         }

    //         function contextGroupBy(parentTableOptions) {
    //             console.log("Template test" , $templateCache.get(scope.temp))
    //             parentTableOptions.buttons = [];
    //             var nonRevenueColumns = `<span uib-dropdown on-toggle="toggled(open)">
    //             <a href id="simple-dropdown" uib-dropdown-toggle>
    //                 <i class='fa fa-filter'></i>
    //             </a>
    //             <ul class="dropdown-menu dropdown-menu-left" ng-click="$event.stopPropagation()" uib-dropdown-menu aria-labelledby="simple-dropdown">
    //                 <uib-tabset active="active" class="uib-tabset">
    //                     <uib-tab  select="stopTabClose($event)">
    //                         <uib-tab-heading><img src="../../../assets/img/diagram.png" /></uib-tab-heading>
    //                         <p class="tab-pane--heading">Group By</p>
    //                         <div class="tab-pane--content">
    //                             <ul class="list-style-none padding-0">
    //                                 <li ng-repeat='node in groupNames' ng-class="{'group-active' : node.active}" ng-click="groupSelect(node.oldValue)">
    //                                     <a>{{node.name}}</a>
    //                                 </li>
    //                             </ul> 
    //                         </div>
    //                     </uib-tab> 
                    
    //                     <uib-tab>
    //                         <uib-tab-heading><i class="fa fa-filter"></i></uib-tab-heading>
    //                         <p class="tab-pane--heading">Custom Filter</p>
    //                         <div class="tab-pane--content">
    //                            Helllo
    //                         </div>
    //                     </uib-tab> 
    //                 </uib-tabset> 
    //             </ul>
    //         </span>`


    //             var revenueColumns = `<span uib-dropdown on-toggle="toggled(open)">
    //         <a href id="simple-dropdown" uib-dropdown-toggle>
    //             <i class='fa fa-filter'></i>
    //         </a>
    //         <ul class="dropdown-menu dropdown-menu-right" ng-click="$event.stopPropagation()" uib-dropdown-menu aria-labelledby="simple-dropdown">
    //         <uib-tabset active="active" class="uib-tabset">
    //             <uib-tab select="stopTabClose($event)">
    //                 <uib-tab-heading><i class="fa fa-filter"></i></uib-tab-heading>
    //                 <p class="tab-pane--heading">Filters</p>
    //                 <div class="tab-pane--content">
    //                     <div class="filter-section">
    //                         <input type="radio" class="input-radio" name="filterValue" value="positive">
    //                         <span>Positive</span>
    //                     </div>
    //                     <div class="filter-section">
    //                         <input type="radio" class="input-radio" name="filterValue" value="negative">
    //                         <span>Negative</span>
    //                     </div>

    //                     <div class="clear-filter">
    //                         <button type="button" class="btn btn-sm btn-default">Clear Filter</button>
    //                     </div>
    //                 </div>    
    //             </uib-tab> 
    //         </uib-tabset> 
    //         </ul>
    //         </span>`
    //             var nonRevenueColumnsCompiled = $compile(nonRevenueColumns)(scope);
    //             var revenueColumnsCompiled = $compile(revenueColumns)(scope);
    //             parentTableOptions.buttons.push(
    //                 {
    //                     text: nonRevenueColumnsCompiled,
    //                     action: function () {
    //                         // scope.showContext();
    //                     }
    //                 },
    //                 {
    //                     text: revenueColumnsCompiled,
    //                     action: function () {
    //                         // scope.showContext();
    //                     }
    //                 }
                    
    //             )
    //         }

    //         function groupTable(changeData, groupingSettingChanged) {
    //             // if (groupingSettingChanged) {
    //             //     var groupedData = _.groupBy(dataSafeCopy, scope.groupBy);
    //             // }
    //             // else {

    //             var groupedData = _.groupBy(scope.sheetData, scope.groupBy);
    //             // }
    //             groupedArray = [];

    //             getTotalRevenue(groupedData, selectedRevenueProperty)




    //             //If Correct Group Parameter Given
    //             if (!groupedData.undefined) {
    //                 buildGroupedArray(groupedData, totalRevenues);
    //                 actualOptions = angular.copy(childTableOptions);
    //                 // getGroupedArray(groupedData)
    //                 // if (!groupingSettingChanged) {
    //                 //     actualOptions = angular.copy(scope.childTableOptions);
    //                 // }

    //                 if (changeData) {
    //                     // table.fnClearTable();
    //                     filterTable(groupedArray);
    //                     return scope.table;
    //                 } else {
    //                     scope.parentTableOptions = optionsForParentTable(groupedArray)
    //                     var table = renderGrid();
    //                     // if (scope.childTableOptions.footer) {
    //                     //     var footer = "<tfoot><tr>";
    //                     //     _.each(scope.childTableOptions.columns, function (col) {
    //                     //         footer += "<th></th>"
    //                     //     })
    //                     //     footer += "</tr></tfoot>";
    //                     //     $(element).find("#" + scope.tableId).append(footer);
    //                     // }
    //                     // if (scope.table) {
    //                     //     scope.table.fnClearTable();
    //                     //     scope.table.fnDestroy();
    //                     // }
    //                     // var table = $(element[0].firstChild).find("#" + scope.tableId).dataTable(scope.childTableOptions);
    //                     // table.fnDraw();
    //                     return table;
    //                 }


    //             } else {
    //                 element[0].textContent = "Invalid Group-By Parameter";
    //                 console.log("Invalid Group-By Parameter");
    //                 return null;
    //             }
    //         }
    //         function filterTable(data) {
    //             scope.table.fnClearTable();
    //             scope.table.fnAddData(data);
    //             scope.table.fnDraw();
    //             console.log('data filtered and table redrawn');

    //         }

    //         function getTotalRevenue(groupedData, selectedRevenueProperty) {

    //             console.log(" selectedRevenueProperty ", selectedRevenueProperty);

    //             console.log(" groupedData ", groupedData);

    //             for (var key in groupedData) {
    //                 totalRevenues[key] = [];
    //                 //Aggreagating amounts based on the grouping
    //                 var total = {};
    //                 _.each(groupedData[key], function (item) {
    //                     _.each(selectedRevenueProperty, function (amount) {
    //                         total[amount] = parseInt(total[amount] || 0) + parseInt(item[amount]);


    //                     })
    //                 })

    //                 for (var i = 0; i < selectedRevenueProperty.length; i++) {
    //                     for (var k in total) {
    //                         if (k === selectedRevenueProperty[i]) {
    //                             totalRevenues[key].push({ [selectedRevenueProperty[i]]: total[k] });
    //                         }
    //                     }
    //                 }


    //                 console.log(totalRevenues[key], "  ------------------------------------------------- totalRevenues[key]");


    //             }
    //         }

    //         function buildChildTableColumns(columns) {
    //             var tableColumns = [];
    //             for (var i = 0; i < columns.length; i++) {
    //                 tableColumns.push({ title: columns[i].replace(/_/g, " "), data: columns[i] });
    //             }
    //             return tableColumns;
    //         }

    //         function optionsForParentTable(groupedArray) {
    //             //TODO
    //             _.map(parentTableOptionsSafeCopy.columns, function (value, index) {
    //                 if (value.data === oldGroupBy) {
    //                     value.data = scope.groupBy;
    //                     value.mData = scope.groupBy;
    //                 }
    //             })
    //             parentTableOptionsSafeCopy['data'] = groupedArray;
    //             parentTableOptionsSafeCopy['aaData'] = groupedArray;
    //             parentTableOptionsSafeCopy['searching'] = scope.searchable;
    //             return parentTableOptionsSafeCopy
    //         }

    //         function buildGroupedArray(groupedData, totalRevenues) {
    //             var tableDataCollection = {};
    //             for (var key in groupedData) {
    //                 tableDataCollection[key] = {};
    //                 _.forEach(nonAggregateColumnsSafeCopy, function (property, index) {
    //                     _.forEach(groupedData[key], function (value, i) {
    //                         tableDataCollection[key][property] = value[property]
    //                     });
    //                 });
    //                 // tableDataCollection[key]["name"] = key;
    //                 tableDataCollection[key]["children"] = groupedData[key];
    //                 for (var i = 0; i < groupedData[key].length; i++) {
    //                     if (groupedData[key][i].commentObject !== undefined && groupedData[key][i].commentObject !== {})
    //                         tableDataCollection[key]["commentObject"] = groupedData[key][i].commentObject
    //                 }

    //                 if (scope.headerGroupBy) {
    //                     var groupByProperty = []

    //                     _.chain(groupedData[key]).find(function (value, index) {
    //                         //Change this
    //                         return value
    //                     }).map(function (val, key) {
    //                         if (key === scope.headerGroupBy[0]) {
    //                             groupByProperty.push(val)
    //                         }
    //                     }).value()
    //                     tableDataCollection[key][scope.headerGroupBy[0]] = groupByProperty.toString();
    //                 }
    //             }
    //             for (var key in totalRevenues) {
    //                 for (var i = 0; i < totalRevenues[key].length; i++) {
    //                     for (var k in tableDataCollection) {
    //                         if (key === k) {
    //                             tableDataCollection[k][_.keys(totalRevenues[key][i]).toString()] = parseInt(_.values(totalRevenues[key][i]));
    //                         }
    //                     }
    //                 }
    //             }
    //             getGroupedArray(tableDataCollection);
    //         }

    //         function getGroupedArray(_data) {
    //             for (var key in _data) {
    //                 groupedArray.push(
    //                     _data[key]
    //                 )
    //             }
    //         }

    //         // function setFirstColumn(parentTableOptions) {

    //         //     parentTableOptions.columns.unshift({
    //         //         data: null,
    //         //         defaultContent: '',
    //         //         orderable: false,
    //         //         className: 'text-center',
    //         //         render: function (data, type, row, meta) {
    //         //             var html = "";
    //         //             for (var i = 0; i < parentTableOptions.rowButtons.length; i++) {
    //         //                 html = html + parentTableOptions.rowButtons[i].template
    //         //             }
    //         //             return html
    //         //         },
    //         //         // fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
    //         //         //     for (var i = 0; i < parentTableOptions.rowButtons.length; i++) {
    //         //         //         if (parentTableOptions.rowButtons[i].clickAction === "expand") {
    //         //         //             $(nTd).children()[i].onclick = registerEvents()
    //         //         //         }
    //         //         //         else {
    //         //         //             $(nTd).children()[i].onclick = parentTableOptions.rowButtons[i].clickAction
    //         //         //         }
    //         //         //     }
    //         //         // }
    //         //     })

    //         // }



    //         function buildDataColumns(table) {
    //             for (var i = 0; i < table.columns.length; i++) {
    //                 if (table.columns[i].data !== null) {


    //                     table.columns[i].fnCreatedCell = function (nTd, sData, oData, iRow, iCol) {
    //                         for (let j = 0; j < table.rowButtons.length; j++) {

    //                             $(nTd).append(table.rowButtons[j].template)

    //                         }

    //                         if (oData.commentObject && oData.commentObject[iCol]) {
    //                             $(nTd).prepend("<span><i class='fa fa-comment-o info-icon' id=" + iRow + "-" + iCol + "></i></span>")
    //                         }

    //                         $(nTd).find('.add-comment-btn').on('click', openModal())

    //                         $(nTd).find('.info-icon').on('click', function (e) {

    //                             var tr = $(this).closest('tr');
    //                             var row = scope.table.api().row(tr);
    //                             scope.commentObject = oData.commentObject[iCol];

    //                             var buildTableObject = {
    //                                 sData: sData,
    //                                 rowNum: iRow,
    //                                 colNum: iCol,
    //                                 event: e,
    //                                 commentData: scope.commentObject,
    //                                 row: row,
    //                                 tr: tr
    //                             }



    //                             $(e.target).toggleClass("info-icon-active")

    //                             //If table is present in row child
    //                             if (row.child.isShown()) {
    //                                 //If comment table is of same cell 
    //                                 if (!$(e.target).hasClass('info-icon-active')) {
    //                                     //Slide up the previous comment table
    //                                     commentTableSlideUp(buildTableObject)
    //                                 }
    //                                 //If comment table is of different cell /child data table is displayed
    //                                 else {
    //                                     //In case child data table is shown then toggle '-' icon to '+' icon
    //                                     $(tr).removeClass('shown')
    //                                     //Remove active info icon from row cells
    //                                     $(tr).find('.info-icon').removeClass('info-icon-active')
    //                                     //Add active info icon on row cell
    //                                     $(e.target).addClass('info-icon-active')
    //                                     //Slide up the previous comment/child data table and show current comment table
    //                                     commentTableSlideUp(buildTableObject, true)
    //                                 }


    //                                 // if ($(tr[0].nextElementSibling).find("table")[0].id === iRow + '-' + iCol) {

    //                                 //     $('div.comment-table', row.child()).slideUp(function () {
    //                                 //         row.child.hide();
    //                                 //         tr.removeClass('shown');
    //                                 //     })
    //                                 //     scope.$apply();
    //                                 // }
    //                                 // else {
    //                                 //     $('div.comment-table', row.child()).slideUp(function () {
    //                                 //         row.child.hide();
    //                                 //         tr.removeClass('shown');
    //                                 //         buildMessageTable(sData, iRow, iCol, scope.commentObject, row, e)
    //                                 //     })

    //                                 // }

    //                                 // // scope.table.fnDraw();
    //                                 // console.log('table redrawn');
    //                             }
    //                             //If no table present in row child then display current comment table
    //                             else {

    //                                 buildMessageTable(buildTableObject)
    //                             }
    //                         })


    //                     }
    //                 }
    //             }

    //         }

    //         function openModal(comment) {
    //             return function (e) {
    //                 var ctrlString = "AddCommentController as ctrl";
    //                 scope.gridData = {};
    //                 if (comment) {
    //                     ctrlString = "EditCommentController as ctrl";
    //                     scope.gridData.comment_key = comment.comment_key;
    //                 }
    //                 scope.rowData = [];
    //                 scope.colData = [];
    //                 var modalInstance = $uibModal.open({
    //                     animation: true,
    //                     templateUrl: "app/components/messages/templates/add-comment-modal-tpl.html",
    //                     controller: ctrlString,
    //                     size: "md",
    //                     resolve: {
    //                         rowData: function () {
    //                             return scope.rowData;
    //                         },
    //                         colData: function () {
    //                             return scope.colData;
    //                         },
    //                         gridData: function () {
    //                             return scope.gridData;
    //                         }
    //                     }
    //                 });
    //                 modalInstance.result.then(function (selectedItem) {
    //                     scope.selected = selectedItem;
    //                 }, function () {
    //                     if (comment) {
    //                         if (isUpdateClicked)
    //                             $scope.loadComment($scope._SelectedComment);
    //                         isUpdateClicked = false;
    //                     }
    //                 });
    //             }
    //         }

    //         function commentTableSlideUp(tableObject, flag) {
    //             scope.$apply();
    //             //Check if row child is comment table or child table
    //             var element = $((tableObject.tr)[0].nextElementSibling).find('.comment-table')[0] ? $((tableObject.tr)[0].nextElementSibling).find('.comment-table')[0] : $((tableObject.tr)[0].nextElementSibling).find('.child-data-table')[0];

    //             $(element, tableObject.row.child()).slideUp(function () {
    //                 tableObject.row.child.hide();
    //                 // tr.removeClass('shown');
    //                 if (flag)
    //                     buildMessageTable(tableObject)
    //             })
    //             scope.$apply();
    //         }

    //         // function amountFomatter(data) {
    //         //     var dataInHTML;
    //         //     var dataToShow = data;
    //         //     if (typeof data === "number") {

    //         //         dataInHTML = data < 0 ? "<span style='color:red' class='datatable-revenue'>(" + $filter('convertAmount')(Math.abs(dataToShow), "$") + ")</span>" : "<span class='datatable-revenue'>" + $filter('convertAmount')(dataToShow, "$") + "</span>";

    //         //     }
    //         //     else {
    //         //         dataInHTML = "<span>" + dataToShow;
    //         //     }
    //         //     return dataInHTML
    //         // }

    //         function buildMessageTable(tableObject) {
    //             var table = '<div class="comment-table"><table class="table table-bordered message-table" id=' + tableObject.rowNum + '-' + tableObject.colNum + '>' +
    //                 '<tbody>' +
    //                 '<tr>' +
    //                 '<td class="v-middle min-width-250" rowspan="2"><div class="short-name">{{parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].shortName}}</div><div class="name-time-wrapper"><div class="message-name"> {{parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].name}} </div><div class="message-time">' + tableObject.commentData.messageTime + '</div></div></td>' +
    //                 '<td rowspan="1">{{parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].message}} </td>' +
    //                 '<td class="v-middle min-width-250" rowspan="2"><div ng-repeat="file in parentTableOptions.data[' + tableObject.rowNum + '].commentObject[' + tableObject.colNum + '].attach" class="message-attachments"><i class="fa fa-file file-icon"></i><span class="file-name">{{file.fileName}}</span></td>' +
    //                 '<td class="v-middle text-center" rowspan="2"><div><i class="fa fa-remove delete-icon"></i></div><div><i class="fa fa-pencil edit-icon"></i></div></td>' +
    //                 '</tr>' +
    //                 '<tr>' +
    //                 '<td rowspan="1">Cell Data' + ': ' + tableObject.sData + '</td>' +
    //                 '</tr>' +
    //                 '</tbody>' +
    //                 ' </table></div>'
    //             // $(table).slideDown(400)
    //             var html = $compile(table)(scope)
    //             scope.$apply()
    //             tableObject.row.child(html).show();
    //             $('div.comment-table', tableObject.row.child()).slideDown()
    //             // $(table).slideDown(400)
    //         }




    //         function registerEvents() {
    //             // var $demo1;
    //             //On Drilldown
    //             $(element).on('click', 'td.details-control', function (e) {

    //                 global = e;
    //                 var tr = $(this).closest('tr');
    //                 var row = scope.table.api().row(tr);

    //                 //If table is present in row child
    //                 if (row.child.isShown()) {
    //                     //If comment table is already present then slide it up and show child data table
    //                     if ($(tr).find('.info-icon').hasClass('info-icon-active')) {
    //                         $('div.comment-table', row.child()).slideUp(function () {
    //                             row.child.hide();
    //                             $(tr).find('.info-icon').removeClass('info-icon-active')
    //                             renderChildDataTable(actualOptions, row, e, tr)
    //                         })

    //                     }
    //                     //If Comment Table is not present but child data table is present then toggle 
    //                     else {
    //                         row.child.hide();
    //                         tr.removeClass('shown');
    //                     }
    //                     // scope.table.fnDraw();
    //                     console.log('table redrawn');
    //                 }
    //                 //If no table is shown
    //                 else {

    //                     renderChildDataTable(actualOptions, row, e, tr)

    //                     // $('.dataTables_scrollBody').children()[1].style.display = "none"
    //                 }




    //                 // $('.sticky-header').floatThead('destroy')
    //                 // $demo1 = $('.sticky-header1');
    //                 // $demo1.floatThead({
    //                 //    scrollContainer : function($table){
    //                 //        return $table.closest(".wrapper");
    //                 //    }
    //                 // });



    //                 ////////////






    //             });




    //         }

    //         function filteredChildData(data, filter) {
    //             var filteredData = [];
    //             for (var i = 0; i < filter.value.length; i++) {
    //                 //Get Data For Each Top Level Filter
    //                 var subFilteredData = _.chain(data).filter(function (val, index) {
    //                     if (_.keys(val).includes(filter.predicate)) {

    //                         if (filter.value[i] === val[filter.predicate]) {
    //                             return val
    //                         }
    //                     }
    //                     else {
    //                         return true;
    //                     }

    //                 }).value()

    //                 filteredData = filteredData.concat(subFilteredData)
    //             }
    //             return filteredData
    //         }

    //         function renderChildDataTable(actualOptions, row, e, tr) {
    //             if (actualOptions.isAjax && actualOptions.ajax !== undefined) {

    //                 actualOptions.ajax.dataSrc = function (json) {
    //                     //Filter Child Ajax Data With Top Level Filters
    //                     var childData = _.chain(json).filter([scope.groupBy, row.data()[scope.groupBy]]).value()
    //                     var childDataSafeArr = childData.slice(0);

    //                     _.forEach(scope.topFilter, function (value) {
    //                         if (value.value.length > 0) {
    //                             childDataSafeArr = filteredChildData(childDataSafeArr, value);
    //                         }
    //                     })

    //                     return childDataSafeArr


    //                 }
    //                 actualOptions.paging = false;
    //             }
    //             else {
    //                 actualOptions.fixedHeader = true;
    //                 actualOptions.paging = true;
    //                 actualOptions.scroller = true;
    //                 actualOptions.deferRender = true;
    //                 actualOptions.scrollY = 300;
    //                 actualOptions.scrollCollapse = true;
    //                 actualOptions.scrollResize = true;
    //                 actualOptions.data = row.data().children;
    //             }

    //             var temp = "<div class='wrapper child-data-table'><table id='table-" + e.clientX + "' class='table table-bordered table-striped sticky-header  table-condensed' style='width:100%'></table></div>"
    //             row.child(temp).show();
    //             // $('div.comment-table', row.child()).slideDown()
    //             tr.addClass('shown');
    //             var dataTableOptions = Object.assign({}, actualOptions);
    //             dataTableOptions.searching = scope.searchable;
    //             // dataTableOptions.deferRender = true;
    //             // dataTableOptions.responsive = true;
    //             // dataTableOptions.dom = 't';
    //             scope.childTable = $("#table-" + e.clientX).dataTable(dataTableOptions);
    //         }

    //         // $('.dataTables_scrollBody').scroll(function (e) {
    //         //     if (scope.childTable !== undefined) {

    //         //         if ($($(scope.childTable).children()[1].children[19]).position().top > e.target.clientHeight && remainingData.length > 0) {

    //         //             var dataToAdd;
    //         //             if (remainingData.length > 20) {
    //         //                 dataToAdd = remainingData.splice(0, 20);
    //         //                 scope.childTable.fnAddData(dataToAdd);
    //         //             }
    //         //             else {
    //         //                 scope.childTable.fnAddData(remainingData);
    //         //                 remainingData = [];
    //         //             }


    //         //         }
    //         //     }
    //         //     // var trs = $("#table-" +1+ " tbody").children();
    //         //     // if ($(trs[19]).position().top > e.target.clientHeight && remainingData.length > 0) {

    //         //     //     var dataToAdd;
    //         //     //     if (remainingData.length > 20) {
    //         //     //         dataToAdd = remainingData.splice(0,20);
    //         //     //         scope.childTable.fnAddData(dataToAdd);
    //         //     //     }
    //         //     //     else{
    //         //     //         scope.childTable.fnAddData(remainingData);
    //         //     //         remainingData = [];
    //         //     //     }


    //         //     // }
    //         // })



    //     }

    //     function gtDatatableController($scope) {
    //         console.log("Datatable Controller")
    //         $scope.groupNames = [];// Only non revenue properties
    //         var sheetDataKeys = _.keys($scope.sheetData[0]);


    //         _.forEach(sheetDataKeys, function (value, i) {


    //             if (value !== 'object_id' && value !== 'AMOUNT' && value !== 'TAX_YEAR' && !$scope.parentTableColumnProperties.aggregateColumns.includes(value)) {
    //                 var newValue = value.split("_").join(" ");
    //                 $scope.groupNames.push({ name: newValue, oldValue: value, active: false })
    //             }

    //         })

    //         $scope.groupNames.map(function (name) {
    //             if (name.oldValue === $scope.groupBy) {
    //                 name.active = true;
    //             }
    //             return name;
    //         })

    //         $scope.groupByList = function () {
    //             $scope.toggleGroupBy = !$scope.toggleGroupBy
    //         }

    //         $scope.groupSelect = function (value) {
    //             $scope.groupBy = value;

    //             $scope.groupNames.map(function (name) {
    //                 if(name.oldValue !== $scope.groupBy){
    //                     name.active = false;
    //                 }
    //                 else if (name.oldValue === $scope.groupBy) {
    //                     name.active = true;
    //                 }
    //                 return name;
    //             })
    //         }
    //         $scope.stopTabClose = function (e) {
    //             if (e) {
    //                 e.stopPropagation()
    //             }
    //         }
    //     }
    // }

    hcSingleSeriesChart.$inject = ['$filter'];
    function hcSingleSeriesChart() {
        var directive = {
            link: link,
            scope: {
                id: '@',
                options: '='
            },
            bindToController: true,
            controller: ['$filter', '$scope',singleSeriesChartController],
            controllerAs: "singleSeriesCtrl"
        };
        return directive;

        function link(scope, element, attrs, singleSeriesCtrl) {

            singleSeriesCtrl.chart = Highcharts.chart(element[0], singleSeriesCtrl.options);

            var watcher = scope.$watch(
                "singleSeriesCtrl.options",
                function (newVal, oldVal) {
                    if (!singleSeriesCtrl.chart) {
                        return;
                    }
                    if (newVal) {
                        if (newVal.chart.type != oldVal.chart.type) {
                            singleSeriesCtrl.chart.destroy();//Force trigger the animation

                            singleSeriesCtrl.chart = Highcharts.chart(element[0], newVal);
                            console.log('single series chart hard refreshed');

                        } else {
                            if (!_.isEqual(newVal.series, oldVal.series)) {
                                singleSeriesCtrl.chart.series[0].setData(newVal.series[0].data, false, true);
                            }
                            singleSeriesCtrl.chart.update(newVal);
                        }
                    }
                },
                true
            );
            scope.$on('$destroy', function () {
                console.log('chart destroyed');
                watcher();
                if (singleSeriesCtrl.chart) singleSeriesCtrl.chart.destroy();
                singleSeriesCtrl.chart = null;
                element.remove();
            })
        }

        /* @ngInject */
        function singleSeriesChartController($filter, $scope) {
            var vm = this;
            //Common Defaults
            vm.options.plotOptions = vm.options.plotOptions || {};
            vm.options.plotOptions.series = vm.options.plotOptions.series || {};
            vm.options.plotOptions.series.point = vm.options.plotOptions.series.point || {};
            vm.options.plotOptions.series.point.events = vm.options.plotOptions.series.point.events || {};
            vm.options.plotOptions.series.dataLabels = vm.options.plotOptions.series.dataLabels || {};
            vm.options.plotOptions.pie = vm.options.plotOptions.pie || {};
            vm.options.plotOptions.pie.minPointSize = vm.options.plotOptions.pie.minPointSize || null,
                vm.options.plotOptions.pie.innerSize = vm.options.plotOptions.pie.innerSize || null,
                vm.options.plotOptions.pie.zMin = vm.options.plotOptions.pie.zMin || null,
                vm.options.plotOptions.pie.showInLegend = _.isUndefined(vm.options.plotOptions.pie.showInLegend) ? true : false
            vm.options.chart.type = vm.options.chart.type || 'pie';
            vm.options.plotOptions.variablepie = vm.options.plotOptions.variablepie || {};
            vm.options.plotOptions.variablepie.minPointSize = vm.options.plotOptions.variablepie.minPointSize || 50,
                vm.options.plotOptions.variablepie.innerSize = vm.options.plotOptions.variablepie.innerSize || '40%',
                vm.options.plotOptions.variablepie.zMin = vm.options.plotOptions.variablepie.zMin || 10,
                vm.options.plotOptions.series.dataLabels.align = vm.options.plotOptions.series.dataLabels.align || 'center';
            vm.options.plotOptions.series.dataLabels.overflow = vm.options.plotOptions.series.dataLabels.overflow || false;
            vm.options.plotOptions.series.dataLabels.useHTML = vm.options.plotOptions.series.dataLabels.useHTML === true ? true : false;
            vm.options.plotOptions.series.dataLabels.enabled = vm.options.plotOptions.series.dataLabels.enabled === true ? true : false;
            vm.options.plotOptions.series.dataLabels.formatter = vm.options.plotOptions.series.dataLabels.formatter || defaultDataLabelFormatter;
            vm.options.plotOptions.series.point.events.click = vm.options.plotOptions.series.point.events.click || defaultDataPointClick;
            vm.options.exporting = vm.options.exporting || {};
            vm.options.exporting.allowHTML = true;
            vm.options.exporting.buttons = vm.options.exporting.buttons || {};
            vm.options.exporting.buttons.contextButton = vm.options.exporting.buttons.contextButton || {};
            vm.options.exporting.menuItemDefinitions = vm.options.exporting.menuItemDefinitions || {};
            vm.options.exporting.buttons.contextButton.menuItems = ["separator", "viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
            vm.options.exporting.menuItemDefinitions.chartOptions = {
                text: 'Chart Options',
            }
            //Adding Chart Configurations
            if (vm.options.chartTypes) {
                for (var i = 0; i < vm.options.chartTypes.length; i++) {
                    vm.options.exporting.menuItemDefinitions[vm.options.chartTypes[i]] = {
                        text: vm.options.chartTypes[i],
                        onclick: changeChartType
                    }
                    vm.options.exporting.buttons.contextButton.menuItems.unshift(vm.options.chartTypes[i])
                }
            }

            ////////////
            function formatY(amount) {
                return "$" + amount;
            }

            function changeChartType(e) {
                if (e.target.innerText === 'Donut') {
                    vm.options.chart.type = 'variablepie';
                }
                else {
                    vm.options.chart.type = e.target.innerText.toLowerCase();
                }
                $scope.$apply()
            }

            function defaultDataLabelFormatter() {
                console.log("Pie chart this", this)
                if (this.y === 0) {
                    return null
                }
                if (this.point.isNegative) {
                    return '<span class="text-center" style="margin: 0px !important;font-size : 13px;">' + this.point.name + " : </span><span class='text-center negative-data' style='margin: 0px !important;font-size : 13px;'>" + "(" + $filter('convertAmount')(Math.abs(this.y), '$') + ' )</span>';

                }
                else {
                    return '<span class="text-center" style="margin: 0px !important;font-size : 13px;">' + this.point.name + " : </span><span class='text-center' style='margin: 0px !important;font-size : 13px;'>" + $filter('convertAmount')(this.y, '$') + '</span>';
                }

            };
            function defaultDataPointClick(e) {
                // console.log( $(e.srcElement)[0].width.animVal.value)
            }
        }
    }

    hcTreeMap.$inject = [];
    function hcTreeMap() {
        var directive = {
            link: link,
            scope: {
                id: '@',
                type: '@',
                layoutAlgorithm: '@',
                options: '=',
                sheetData: '=',
                itemClick: '&',
                selectedItems: '=',
                expandable: '='
            },
            template: "<div class='treeMapWrapper'>" +
                "<div class='icon  clearfix' uib-tooltip='Expand/Collapse' tooltip-placement='right'" +
                "tooltip-trigger='mouseenter' ng-click='hcTreeMapCtrl.toggleTreeMap()' title = 'Expand/Collapse Tree'>" +
                "<i class='fa fa-angle-right'>" +
                "</i>" +
                "<div class='peices'>" +
                "</div>" +
                "<div class='peices'>" +
                "</div>" +
                "<div class='peices'>" +
                "</div>" +
                "<i class='fa fa-angle-left'>" +
                "</i>" +
                "</div>" +
                "<div class='treemap'></div>" +
                "</div>",
            bindToController: true,
            controller: ['$scope', '$filter',hcTreeMapController],
            controllerAs: 'hcTreeMapCtrl'
        }

        return directive;

        function link(scope, element, attrs, hcTreeMapCtrl) {

            var initialWidth = hcTreeMapCtrl.options.chart.width;
            var seriesDataSafeCopy = _.cloneDeep(hcTreeMapCtrl.options.series[0].data);
            // var chartWidth = element[0].firstChild.parentElement.clientWidth;
            // hcTreeMapCtrl.options.chart.width = hcTreeMapCtrl.options.series[0].data.length * 75 * 3.75;
            // hcTreeMapCtrl.options.chart.width =chartWidth;
            hcTreeMapCtrl.chart = Highcharts.chart($(element[0]).find('.treemap')[0], hcTreeMapCtrl.options);

            var minValue = pixelToVal(300, hcTreeMapCtrl.options.series[0].data);
            console.log('Min Value ', minValue)
            _.find(hcTreeMapCtrl.options.series[0].data, function (item) {
                if (item.value < minValue) {
                    item.value = minValue;
                }
            });
            var minDataCopy = _.cloneDeep(hcTreeMapCtrl.options.series[0].data);
            //Setting Data with new min values
            hcTreeMapCtrl.chart.series[0].setData(hcTreeMapCtrl.options.series[0].data);

            var selectedPoints = [];
            var container = $(element).find('.highcharts-container');
            container.on("mousewheel", scrollHorizontally);//chrome, IE
            container.on("DOMMouseScroll", scrollHorizontally);//firefox


            function scrollHorizontally(e) {

                e = window.event || e.originalEvent;
                var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                console.log("delta", delta)
                container[0].scrollLeft -= delta * 100;
                e.preventDefault();
            }



            hcTreeMapCtrl.toggleTreeMap = function (e) {
                hcTreeMapCtrl.expand = !hcTreeMapCtrl.expand
                var container = $(element).find('.highcharts-container');
                var icon = $(element).find('.icon');
                if (!icon.hasClass('icon-compressed')) {
                    icon.addClass('icon-compressed');


                    hcTreeMapCtrl.chart.series[0].setData(seriesDataSafeCopy, false, false, true);
                    hcTreeMapCtrl.chart.setSize(element[0].firstChild.parentElement.clientWidth, hcTreeMapCtrl.chart.options.height);

                    $(container).unbind("mousewheel");
                    $(container).unbind("DOMMouseScroll");
                    container[0].style.overflowX = "hidden"; //TODO : Find Better Logic For This , Not Working Right Now
                    icon.find('i').addClass('rotate');
                } else {
                    icon.removeClass('icon-compressed');
                    icon.children().addClass('streching');

                    setTimeout(function () {
                        icon.children().removeClass('streching');
                        icon.find('i').removeClass('rotate');
                    }, 500)

                    container.on("mousewheel", scrollHorizontally);//chrome, IE
                    container.on("DOMMouseScroll", scrollHorizontally);//firefox
                    hcTreeMapCtrl.chart.series[0].setData(minDataCopy, false, false, true);
                    hcTreeMapCtrl.chart.setSize(initialWidth, hcTreeMapCtrl.chart.options.height);

                }


                // hcTreeMapCtrl.chart.update(hcTreeMapCtrl.options);
            }
            //Converts pixels to values based on the max value of the data provided
            function pixelToVal(pixels, data) {

                var val = {};

                var maxValue = _.maxBy(data, 'value').value;
                var rectCollection = $(element).find('.highcharts-level-group-1')[0].childNodes;
                console.log('max Value', maxValue);

                var rectOfMaxValue = _.maxBy(rectCollection, 'width.animVal.value');
                var maxNodePixels = $(rectOfMaxValue)[0].width.animVal.value;
                console.log('max node pixels', maxNodePixels);

                return pixels * maxValue / maxNodePixels;
            }
            var watcher = scope.$watch(
                "hcTreeMapCtrl.options",
                function (newVal, oldVal) {
                    if (!hcTreeMapCtrl.chart) {
                        return;
                    }
                    if (newVal) {
                        if (newVal.series[0].type != oldVal.series[0].type) {
                            hcTreeMapCtrl.chart.destroy();//Force trigger the animation

                            hcTreeMapCtrl.chart = Highcharts.chart(element[0], newVal);
                            console.log('Tree map chart hard refreshed');

                        } else {
                            if (!_.isEqual(newVal.series, oldVal.series)) {
                                // if (hcTreeMapCtrl.selectedItems.selected.length > 0) {

                                //     treeMapClickHandler()
                                // }

                                hcTreeMapCtrl.chart.series[0].setData(newVal.series[0].data, true, true);
                            }
                            // hcTreeMapCtrl.chart.update(newVal);
                        }
                    }
                },
                true
            );

            function treeMapClickHandler(e) {
                console.log('in treeMapClickHandler', e);
                if (e.point) {
                    if (e.point.name !== 'all') {
                        if (selectedPoints.includes(e.point.name)) {
                            e.point.graphic.element.style.opacity = 0.5
                            selectedPoints.splice(selectedPoints.indexOf(e.point.name), 1);
                        }
                        else {
                            selectedPoints.push(e.point.name);
                            e.point.graphic.element.style.opacity = 1;
                            var container = $(element).find('.highcharts-container');
                            container.animate({
                                scrollLeft: container.scrollLeft()
                                    + $(e.point.graphic.element).position().left
                                    - $(e.point.graphic.element).width() / 2
                                    - container.width() / 2
                            })
                            console.log('left:', container.position().left);
                        }
                    } else {
                        selectedPoints = [];
                        $(element).find('.highcharts-container').animate({
                            scrollLeft: 0
                        });
                    }

                    for (var counter = 0; counter < e.data.length; counter++) {
                        if (e.selectedItems) {
                            if (!e.selectedItems.includes(e.data[counter].name)) {
                                changeOpacity(e, counter)
                            }
                        }
                        else {
                            if (e.point.name !== e.data[counter].name) {
                                changeOpacity(e, counter)
                            }
                        }

                    }
                }




                console.log("Selected Points", selectedPoints)
            }
            function changeOpacity(e, i) {
                if (e.data[i].graphic) {
                    if (e.point.name === 'all') {
                        e.data[i].graphic.element.style.opacity = "";
                    }
                    else if (e.data[i].graphic.element.style.opacity !== '1') {
                        e.data[i].graphic.element.style.opacity = 0.5;
                    }
                }
            }
            var selectionWatcher = scope.$watch('hcTreeMapCtrl.selectedItems.selected', function (newVal, oldVal) {
                console.log('in watch selectedItems:', newVal);
                console.log('in watch chart:', hcTreeMapCtrl.chart);
                var changedItem;

                if (newVal.length > oldVal.length) {
                    changedItem = _.differenceBy(newVal, oldVal, 'value');

                } else {
                    changedItem = _.differenceBy(oldVal, newVal, 'value');

                }
                if (changedItem.length === 0) {
                    var selectedItems = _.chain(hcTreeMapCtrl.selectedItems.selected).groupBy('name').keys().value();
                    for (var i = 0; i < selectedItems.length; i++) {
                        var selectedPoint = hcTreeMapCtrl.chart.series[0].data.find(function (item) {
                            return item.name === selectedItems[i];
                        })
                        treeMapClickHandler({
                            point: selectedPoint,
                            data: hcTreeMapCtrl.chart.series[0].data,
                            selectedItems: selectedItems
                        })
                    }
                    return
                }


                if (newVal.length === 0 || changedItem.length === 0) {
                    treeMapClickHandler({
                        point: { name: "all" },
                        data: hcTreeMapCtrl.chart.series[0].data
                    })
                    return;
                }
                var selectedPoint = hcTreeMapCtrl.chart.series[0].data.find(function (item) {
                    return item.name === changedItem[0].name;
                })
                treeMapClickHandler({
                    point: selectedPoint,
                    data: hcTreeMapCtrl.chart.series[0].data
                })

            }, true)

            scope.$on('$destroy', function () {
                watcher();
                selectionWatcher();
                if (hcTreeMapCtrl.chart) hcTreeMapCtrl.chart.destroy();
                hcTreeMapCtrl.chart = null;
                element.remove();
            })
        }

        function hcTreeMapController($scope, $filter) {
            var vm = this;
            console.log("Opcaity", vm.opacityAtBeginning)
            var selectedPoints = [];
            vm.options.plotOptions = vm.options.plotOptions || {};
            vm.options.plotOptions.series = vm.options.plotOptions.series || {};
            vm.options.plotOptions.series.dataLabels = vm.options.plotOptions.series.dataLabels || {};
            vm.options.plotOptions.series.dataLabels.overflow = vm.options.plotOptions.series.dataLabels.overflow || false;
            // vm.options.plotOptions.series.dataLabels.useHTML = vm.options.plotOptions.series.dataLabels.useHTML // No Need For This 
            vm.options.plotOptions.series.dataLabels.enabled = vm.options.plotOptions.series.dataLabels.enabled
            vm.options.plotOptions.series.dataLabels.allowOverlap = vm.options.plotOptions.series.dataLabels.allowOverlap || false;
            vm.options.series = vm.options.series || [];
            vm.options.series[0].layoutAlgorithm = vm.options.series[0].layoutAlgorithm || 'stripes';
            vm.options.plotOptions.series.events = {};
            vm.options.plotOptions.series.events.click = treeMESelected;
            vm.options.plotOptions.series.dataLabels.formatter = dataLabelFormatter;
            // Highcharts.seriesTypes.treemap.prototype.myCustomAlgorithm = customTreeLayout;
            // vm.options.series[0].layoutAlgorithm = "myCustomAlgorithm";
            vm.options.tooltip = vm.options.tooltip || {};
            vm.options.tooltip.formatter = vm.options.tooltip.formatter || defaultTooltipFormatter;
            vm.options.tooltip.useHTML = true;
            vm.expand = true;


            function defaultTooltipFormatter() {
                if (this.point.isNegative) {
                    return this.key + " :  " + "-$" + $filter('convertAmount')(this.point.actualValue, "$");
                }
                else {
                    return this.key + " : $" + $filter('convertAmount')(this.point.actualValue, "$");
                }
            }
            function dataLabelFormatter() {
                if (this.point.isNegative) {
                    return this.key + " :  " + "-" + $filter('convertAmount')(this.point.actualValue, "$");
                }
                else {
                    return this.key + " : " + $filter('convertAmount')(this.point.actualValue, "$");
                }
            }


            function customTreeLayout(parent, children) {

                // console.log(chartWidth)
                console.log(parent)
                console.log(children)
                var x = parent.x,
                    y = parent.y,
                    w,
                    h,
                    childrenAreas = [];
                Highcharts.each(children, function (child) {
                    // console.log("children length", children.length)
                    // console.log("parent", parent)
                    w = parent.width / children.length;
                    h = parent.height;
                    // if (w > 10000) {
                    //     w = 500
                    // }
                    // if (w < 300) {
                    //     w = 200
                    // }
                    // These return values are required for each child
                    childrenAreas.push({
                        x: x,
                        y: y,
                        width: w,
                        height: h
                    });

                    x += w;
                    console.log("child", child)
                });
                return childrenAreas;
            };

            function treeMESelected(e) {
                console.log('in treemeselected selectedItems:', vm.selectedItems);

                var me = _.find(vm.selectedItems.values, ['name', e.point.name]);

                var isSelected = vm.selectedItems.selected.find(function (item) { return item.name === e.point.name });
                if (!isSelected) {
                    vm.selectedItems.selected.push({
                        name: me.name,
                        value: me.value
                    });
                } else {
                    vm.selectedItems.selected.splice(_.findIndex(vm.selectedItems.selected, me), 1)
                }
                $scope.$apply(function () {
                    vm.itemClick();
                })
            }


        }
    }

    percentageBar.$inject = [];
    function percentageBar() {
        var directive = {
            link: link,
            templateUrl: "app/components/dashboardExecutive/templates/percentageBar.html",
            bindToController: true,
            controller: [percentageBarController],
            controllerAs: "percentageBarCtrl",
            scope: {
                sheetData: '='
            }
        }
        return directive;

        function link(scope, element, attrs, percentageBarCtrl) {
            var taxable = percentageBarCtrl.sheetData.taxableIncome;
            var book = percentageBarCtrl.sheetData.bookIncome;
            var total = taxable + book;
            scope.taxablePercentage = ((taxable / total) * 100).toFixed(2) + "%";
            scope.bookTaxPercentage = ((book / total) * 100).toFixed(2) + "%";


        }

        function percentageBarController() {
            var vm = this;
        }
    }

    regularTreeMap.$inject = ['$filter'];
    function regularTreeMap() {
        var directive = {
            link: link,
            scope: {
                options: '=',
                selectedItems: '='
            },
            bindToController: true,
            controller: ['$filter',customTreeMapController],
            controllerAs: 'customTreeMapCtrl',
            // templateUrl: "app/components/dashboardExecutive/templates/customTreeMap.html",
        }
        return directive;

        function link(scope, element, attrs, customTreeMapCtrl) {
            customTreeMapCtrl.options.chart.width = $(element).parent().width();
            customTreeMapCtrl.chart = Highcharts.chart(element[0], customTreeMapCtrl.options);
            // var nodeClick =  ? clickNode : null

            var watcher = scope.$watch(
                "customTreeMapCtrl.options",
                function (newVal, oldVal) {
                    if (!customTreeMapCtrl.chart) {
                        return;
                    }
                    if (newVal) {
                        if (newVal.series[0].type != oldVal.series[0].type) {
                            customTreeMapCtrl.chart.destroy();//Force trigger the animation

                            customTreeMapCtrl.chart = Highcharts.chart(element[0], newVal);
                            console.log('Tree map chart hard refreshed');

                        } else {
                            if (!_.isEqual(newVal.series, oldVal.series)) {
                                customTreeMapCtrl.chart.series[0].setData(newVal.series[0].data, true, true);
                            }
                            // hcTreeMapCtrl.chart.update(newVal);
                        }
                    }
                },
                true
            );
            scope.$on('$destroy', function () {
                watcher();
                if (customTreeMapCtrl.chart) customTreeMapCtrl.chart.destroy();
                customTreeMapCtrl.chart = null;
                element.remove();
            })

            // var container = $(element).find('.nodes');
            // container.on("mousewheel", scrollHorizontally);//chrome, IE
            // container.on("DOMMouseScroll", scrollHorizontally);//firefox


            // function scrollHorizontally(e) {

            //     e = window.event || e.originalEvent;
            //     var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
            //     console.log("delta", delta)
            //     container[0].scrollLeft -= delta * 100;
            //     e.preventDefault();
            // }
            // var watcher = scope.$watch('customTreeMapCtrl.options.nodes', function (newVal, oldVal) {
            //     if (!_.isEqual(newVal, oldVal)) {
            //         totalAmount = _.sumBy(scope.customTreeMapCtrl.options.nodes, 'value')
            //         calculateNodeWidths(scope.customTreeMapCtrl.options.areaWidth, scope.customTreeMapCtrl.options.nodes, totalAmount, parseFloat(scope.customTreeMapCtrl.options.nodeOptions.size.minWidthPercentage))
            //     }
            // })


        }







        function customTreeMapController($filter) {
            var vm = this;
            vm.options.chart = vm.options.chart || {}
            vm.options.tooltip = vm.options.tooltip || {};
            vm.options.tooltip.formatter = vm.options.tooltip.formatter || defaultTooltipFormatter;



            function defaultTooltipFormatter() {
                if (this.point.isNegative) {
                    return "<span>" + this.key + " :  </span>" + "<span style='color:red'>" + $filter('convertAmount')(this.point.value, "$") + "</span>";
                }
                else {
                    return "<span>" + this.key + " :  </span>" + "<span'>" + $filter('convertAmount')(this.point.value, "$") + "</span>";
                }
            }
            // if(vm.options.series[0].levels.length > 0){
            //     for(var i = 0 ; i < vm.options.series[0].levels.length ; i++){
            //         vm.options.series[0].levels[i].dataLabels = vm.options.series[0].levels[i].dataLabels || {};
            //         vm.options.series[0].levels[i].dataLabels.formatter = defaultDataLabelFormatter
            //     }
            // }


            // function defaultDataLabelFormatter(){
            //     return "<span>" + this.point.name + "</span><span> : " + $filter('convertAmount')(this.point.value , "$") + "</span>"
            // }
            //     vm.format = function(){
            //         return this.areaWidth
            //     }


            // if (vm.options.nodeOptions.tooltip.enabled) {
            //     vm.tooltipFormat = vm.format.bind(vm.options)
            //     console.log("binded function", vm.tooltipFormat())
            // }


        }

    }

    comparisionChart.$inject = [];
    function comparisionChart() {
        var directive = {
            link: link,
            scope: {
                options: "=",
                sheetData: "=",
                selectedItems: "=",
                callbackFn: '&'
            },
            bindToController: true,
            controller: [comparisionChartController],
            controllerAs: 'comparisionChartCtrl',
            templateUrl: "app/components/dashboardExecutive/templates/comparisionChart.html",
        }

        return directive;

        function link(scope, element, attrs, comparisionChartCtrl) {
            console.log(comparisionChartCtrl.selectedItems)

            var watcher = scope.$watch('comparisionChartCtrl.sheetData', function (newVal, oldVal) {

                var notSelectedCollection = [];
                var selectedItems = _.chain(newVal).groupBy(comparisionChartCtrl.options.groupedBy).keys().value();

                notSelectedCollection = comparisionChartCtrl.options.data.filter(function (item, index) {
                    return !selectedItems.includes(item.me_name);
                })



                for (var i = 0; i < selectedItems.length; i++) {
                    comparisionChartCtrl.options.data.map(function (item, index) {
                        if (selectedItems.includes(item.me_name)) {
                            item.selected = true;
                        }
                        else {
                            item.selected = false;
                        }
                        return item;
                    })
                }


                moveToSelectedEntity(element, _.chain(comparisionChartCtrl.selectedItems.selected).groupBy('value').keys().value())

                console.log(comparisionChartCtrl.options.data)

                scope.$on('$destroy', function () {
                    watcher();
                    element.remove();
                })


            }, true)


            function moveToSelectedEntity(element, selectedId) {
                if (selectedId.length > 0) {
                    var selectedNode = $(element).find("#" + selectedId[selectedId.length - 1]);
                    $(element).find(".comparision-chart").animate({
                        scrollLeft: $(element).find(".comparision-chart").scrollLeft()
                            + selectedNode.position().left
                            - selectedNode.width() / 2
                            - $(element).find(".comparision-chart").width() / 4
                    }, 200)
                    console.log(selectedNode)
                }
            }
        }

        function comparisionChartController() {
            var vm = this;

            vm.nodeClicked = function (node) {
                var items = _.map(vm.selectedItems.selected, 'name');
                if (!items.includes(node.me_name)) {

                    vm.selectedItems.selected.push({
                        name: node.me_name,
                        value: node.me_code
                    })

                }
                else {
                    vm.selectedItems.selected.splice(items.indexOf(node.me_name), 1)
                }
                vm.callbackFn()

            }
        }
    }

    kpiBox.$inject = ['$filter'];
    function kpiBox($filter) {
        var directive = {
            link: link,
            scope: {
                sheetData: '=',
                boxTitleOne: '@',
                boxTitleTwo: '@',
                boxTitleThree: '@',
                kpiData: '='
            },
            bindToController: true,
            controller: kpiBoxController,
            controllerAs: 'kpiBoxCtrl',
            templateUrl: "app/components/dashboardExecutive/templates/kpiBox.html",
        }
        return directive;

        function link(scope, attrs, element, kpiBoxCtrl) {
            function setData() {
                kpiBoxCtrl.kpiData.bookIncome = $filter('convertAmount')(kpiBoxCtrl.kpiData.bookIncome, "$");
                kpiBoxCtrl.kpiData.taxableIncome = $filter('convertAmount')(kpiBoxCtrl.kpiData.taxableIncome, "$");
                kpiBoxCtrl.kpiData.taxAdjustment = $filter('convertAmount')(kpiBoxCtrl.kpiData.taxAdjustment, "$");
            }

            setData()

            scope.$watch('kpiBoxCtrl.kpiData', function (newVal, oldVal) {
                if (!_.isEqual(newVal, oldVal)) {
                    setData();
                }
            })
        }

        function kpiBoxController() {
            var vm = this;
        }

    }


    function getInsightChart(masterData, selectedProperty, groupedBy, selectedName, isPopUpChart, renderToDiv, amountToTotal) {
        //REFACTORED
        var sortedData = _.chain(masterData).filter(function (item, i) {
            if (item[selectedProperty] === selectedName)
                return item;
        }).groupBy(groupedBy).map(function (value, i) {
            return {
                name: i,
                amount: _.sumBy([amountToTotal], _.partial(_.sumBy, value))
            }
        }).sortBy('amount').reverse().value();
        if (isPopUpChart)
            loadInsightChart(_.map(sortedData, 'name'), _.map(sortedData, 'amount'), renderToDiv)

        else
            return sortedData;
    }

    function loadInsightChart(xAxis, yAxis, $div) {
        window.chart = new Highcharts.Chart({
            chart: {
                renderTo: $div[0],
                type: 'bar',

            },
            title: {
                text: null
            },
            xAxis: {
                categories: xAxis,
            },
            plotOptions: {
                series: {
                    pointPadding: 0.9,
                    pointWidth: 10
                }
            },
            series: [{

                data: yAxis,
            }]
        });
    }

    function convertAmount(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9

            ? (Number(labelValue) / 1.0e+9).toFixed(2) + "B"
            // Six Zeroes for Millions 
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? (Number(labelValue) / 1.0e+6).toFixed(2) + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? (Number(labelValue) / 1.0e+3).toFixed(2) + "K"

                    : Number(labelValue).toFixed(2);
    }
})