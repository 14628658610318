define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.entityFillingActionsController', [])
    .controller('entityFillingCreateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','entityFillingActionsService', '$filter', entityFillingCreateActionController])
    .controller('entityFillingUpdateActionController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$rootScope', '$timeout', 'workspaceFactory', '$http', '$q','rowData', 'gridData', 'colData', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS','entityFillingActionsService', entityFillingUpdateActionController]);
 
    function entityFillingCreateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS,entityFillingActionsService, $filter){
        var vm = this;

        vm.modalTitle = "Filing Dates Setup";
		vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.dateOptions = {};
        vm.scenarioCode = $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0] ? $filter("filter")(workspaceFactory.activeScreen.filters.filters, { param_name: 'scenario' })[0].selected.CODE : 'RAF';

		if (vm.isIssueKeyEnabled === undefined) {
			if (vm.scenarioCode !== undefined) {
				if (vm.scenarioCode === 'RAF') {
					vm.isIssueKeyEnabled = 'N';
				}
				else {
					vm.isIssueKeyEnabled = 'Y';
				}
			}
		}

        vm.entityFiling_bckup = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            ENTITY_ID:"",
            GROUP_OBJ_KEY:"",
            SCENARIO:"",        
            LEGAL_ENTITY_ID:"", 
            TAX_YEAR_BEGIN:"",  
            TAX_YEAR_END:"",    
            FILING_CYCLE:"",    
            ACTIVE_Y_N:"",      
            ROLLFWD_Y_N:"",
            SCENARIO_TYPE_CODE:"",
            SCENARIO_DESC:""     

        };

        vm.entityFiling_bckup.TAX_YEAR = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.entityFiling_bckup.FILING_CYCLE = "CYE";
        vm.entityFiling_bckup.TAX_YEAR_BEGIN = "01/01/"+workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.entityFiling_bckup.TAX_YEAR_END = "12/31/"+workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
        vm.entityFiling_bckup.ACTIVE_Y_N = "Y";
        vm.entityFiling_bckup.ROLLFWD_Y_N = "Y";
        vm.entityFiling = angular.copy(vm.entityFiling_bckup);
        


        let loadScenarioTypeCode = function(){
            
            entityFillingActionsService.loadScenarioTypeCode().then((scenarioTypeCode) => {
                console.log("load all Scenario Type Code", scenarioTypeCode);
                vm.allscenarioTypeCode = scenarioTypeCode;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadScenarioDesc = function(selectedValue){
            
            entityFillingActionsService.loadScenarioDesc(selectedValue).then((scenarioDesc) => {
                console.log("load all Scenario Desc", scenarioDesc);
                vm.allscenarioDesc = scenarioDesc;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }

        let loadGroupObjKey = function(selectedVal1, selectedVal2){
            
            entityFillingActionsService.loadGroupObjKey(selectedVal1, selectedVal2).then((groupObjByYear) => {
                console.log("load all Group Obj", groupObjByYear);
                vm.allGroupObjByYear = groupObjByYear;
                //vm.classificationsApiCompleted = true;
                //stopLoader();
            });
        }
        let loadAllEntities =function(selectedVal1){
            entityFillingActionsService.loadAllEntities(selectedVal1).then((leids) => {
                console.log("load all entities Id", leids);
                vm.allLeids = leids;
            });
        }
        
        let loadAllFilingCycleType =function(){
            entityFillingActionsService.loadAllFilingCycleType().then((filingCycleTypes) => {
                console.log("load all Filing Cycle Types", filingCycleTypes);
                vm.allFilingCycleTypes = filingCycleTypes;
            });
        }

        let loadAllAppScenario =function(){
            entityFillingActionsService.loadAllAppScenario().then((appScenarios) => {
                console.log("load all Filing Cycle Types", appScenarios);
                vm.allAppScenarios = appScenarios;
            });
        }
        

        vm.init =function(){
            //TODO
            vm.showEntityLoader = false;
            loadScenarioTypeCode();
            loadAllFilingCycleType();
            loadAllAppScenario();

        };
		vm.init();

        vm.onScenarioTypeCodeChange = function(){
            console.log(vm.entityFiling.SCENARIO_TYPE_CODE);
            loadScenarioDesc(vm.entityFiling.SCENARIO_TYPE_CODE);
            vm.entityFiling.GROUP_OBJ_KEY = undefined;
            
        }; 
        vm.onPopulateGroupObjYear = function(){
            console.log(vm.entityFiling.SCENARIO_TYPE_CODE+'............'+ vm.entityFiling.SCENARIO_DESC);
            loadGroupObjKey(vm.entityFiling.SCENARIO_TYPE_CODE, vm.entityFiling.SCENARIO_DESC);
        };

        vm.onPopulateFilingLEID = function(){
            console.log(vm.entityFiling.GROUP_OBJ_KEY);
            loadAllEntities(vm.entityFiling.GROUP_OBJ_KEY);
            
        };
        vm.onPopulateTaxDate = function(){
            if(vm.entityFiling.FILING_CYCLE === "CYE"){
                vm.entityFiling.TAX_YEAR_BEGIN = "01/01/"+vm.entityFiling.TAX_YEAR;
                vm.entityFiling.TAX_YEAR_END = "12/31/"+vm.entityFiling.TAX_YEAR;
            }else{
                vm.entityFiling.TAX_YEAR_BEGIN = "10/01/"+vm.entityFiling.TAX_YEAR;
                vm.entityFiling.TAX_YEAR_END = "09/30/"+(Number(vm.entityFiling.TAX_YEAR) +1);
            }
        }

        vm.isValid = function(){
            if(vm.entityFiling.TAX_YEAR == null || vm.entityFiling.TAX_YEAR == undefined || vm.entityFiling.TAX_YEAR == ""){
                     vm.entityFiling.TAX_YEAR_NOT_VALID = true;
                     return false;
            }
            if(vm.entityFiling.SCENARIO_TYPE_CODE == null || vm.entityFiling.SCENARIO_TYPE_CODE == undefined || vm.entityFiling.SCENARIO_TYPE_CODE == ""){
                     vm.entityFiling.SCENARIO_TYPE_CODE_NOT_VALID = true;
                     return false;
            }
            if(vm.entityFiling.SCENARIO_DESC == null || vm.entityFiling.SCENARIO_DESC == undefined || vm.entityFiling.SCENARIO_DESC == ""){
                vm.entityFiling.SCENARIO_DESC_NOT_VALID = true;
                return false;
            }
            if(vm.entityFiling.GROUP_OBJ_KEY == null || vm.entityFiling.GROUP_OBJ_KEY == undefined || vm.entityFiling.GROUP_OBJ_KEY == ""){
                vm.entityFiling.GROUP_OBJ_KEY_NOT_VALID = true;
                return false;
            }
            if(vm.entityFiling.LEGAL_ENTITY_ID == null || vm.entityFiling.LEGAL_ENTITY_ID == undefined || vm.entityFiling.LEGAL_ENTITY_ID == ""){
                vm.entityFiling.LEGAL_ENTITY_ID_NOT_VALID = true;
                return false;
            }

            if(vm.entityFiling.FILING_CYCLE == null || vm.entityFiling.FILING_CYCLE == undefined || vm.entityFiling.FILING_CYCLE == ""){
                vm.entityFiling.FILING_CYCLE_NOT_VALID = true;
                return false;
            }
            if(vm.entityFiling.SCENARIO == null || vm.entityFiling.SCENARIO == undefined || vm.entityFiling.SCENARIO == ""){
                vm.entityFiling.SCENARIO_NOT_VALID = true;
                return false;
            }

            if(vm.entityFiling.TAX_YEAR_BEGIN == null || vm.entityFiling.TAX_YEAR_BEGIN == undefined || vm.entityFiling.TAX_YEAR_BEGIN == ""){
                     vm.entityFiling.TAX_YEAR_BEGIN_NOT_VALID = true;
                     return false;
            }
            if(vm.entityFiling.TAX_YEAR_END == null || vm.entityFiling.TAX_YEAR_END == undefined || vm.entityFiling.TAX_YEAR_END == ""){
                     vm.entityFiling.TAX_YEAR_END_NOT_VALID = true;
                     return false;
            }
            
            return true;
        }

        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.entityFiling);
            
            jsonObj = _.merge({}, jsonObj , filterParams);
            jsonObj = entityFillingActionsService.convertDateFormates(jsonObj);
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=qd33ho";
            params.is_issue_key_enabled = vm.isIssueKeyEnabled;
            params.scenarioCode = vm.scenarioCode;
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "EC Filing Dates created successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.upper = function(filingCycle) {
            vm.entityFiling.FILING_CYCLE = filingCycle.toUpperCase();
        }

        vm.reset = function (){
			vm.entityFiling = angular.copy(vm.entityFiling_bckup);
		};

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };
    }

    function entityFillingUpdateActionController (GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q,rowData, gridData, colData, AlertService, GENERAL_CONFIG, USER_SETTINGS, entityFillingActionsService){
        var vm = this;

        vm.modalTitle = "Filing Dates Setup";
		vm.showFormLoader = true;
        vm.isSavingUpdate = false;

        vm.showEntityLoader = false;
        
        vm.dateOptions = {
        };
 		
 		
        vm.entityFiling_bckup = gridData.rowData;

        vm.entityFiling = angular.copy(vm.entityFiling_bckup);


        let loadAllFilingCycleType =function(){
            entityFillingActionsService.loadAllFilingCycleType().then((filingCycleTypes) => {
                console.log("load all Filing Cycle Types", filingCycleTypes);
                vm.allFilingCycleTypes = filingCycleTypes;
            });
        }

        vm.init =function(){
            //TODO
            loadAllFilingCycleType();

        };
		vm.init();

        vm.onPopulateTaxDate = function(){
            if(vm.entityFiling.FILING_CYCLE === "CYE"){
                vm.entityFiling.TAX_YEAR_BEGIN = "01/01/"+vm.entityFiling.TAX_YEAR;
                vm.entityFiling.TAX_YEAR_END = "12/31/"+vm.entityFiling.TAX_YEAR;
            }else{
                vm.entityFiling.TAX_YEAR_BEGIN = "10/01/"+vm.entityFiling.TAX_YEAR;
                vm.entityFiling.TAX_YEAR_END = "09/30/"+(Number(vm.entityFiling.TAX_YEAR) +1);
            }
        }

        vm.isValid = function(){
            if(vm.entityFiling.FILING_CYCLE == null || vm.entityFiling.FILING_CYCLE == undefined || vm.entityFiling.FILING_CYCLE == ""){
                vm.entityFiling.FILING_CYCLE_NOT_VALID = true;
                return false;
            }
            if(vm.entityFiling.TAX_YEAR_BEGIN == null || vm.entityFiling.TAX_YEAR_BEGIN == undefined || vm.entityFiling.TAX_YEAR_BEGIN == ""){
                     vm.entityFiling.TAX_YEAR_BEGIN_NOT_VALID = true;
                     return false;
            }
            if(vm.entityFiling.TAX_YEAR_END == null || vm.entityFiling.TAX_YEAR_END == undefined || vm.entityFiling.TAX_YEAR_END == ""){
                     vm.entityFiling.TAX_YEAR_END_NOT_VALID = true;
                     return false;
            }
            return true;
        }
        
        vm.save= function(){
            if(!vm.isValid()){
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSavingUpdate = true;
            
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year
                // "scenario": rowData.SCENARIO,
                // "jcd_key": rowData.JCD_KEY, 
                // "combination_key": rowData.COMBINATION_KEY, 
			};
            jsonSettings = JSON.stringify(jsonSettings);

            let jsonObj = angular.copy(vm.entityFiling);
            
            jsonObj = _.merge({}, jsonObj , filterParams);
            jsonObj = entityFillingActionsService.convertDateFormates(jsonObj);
            jsonObj = JSON.stringify(jsonObj);

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams);
            params = _.extend({
                json_data: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
			}, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=lrpf1c";
            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingUpdate = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "EC Filing Dates Updated successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };


        vm.remove = function(_tax_year, _group_obj_key, _legal_entity_id){
            console.log("Remove function called");
            console.log(_tax_year+'............'+_group_obj_key+'............'+_legal_entity_id);
            vm.isSavingRemove = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                tax_year: _tax_year,
                group_obj_key: _group_obj_key,
                legal_entity_id: _legal_entity_id
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=e2evpp";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingRemove = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Filing Date Setup removed successfully", 4000);
                    console.log("Removed", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };

        vm.delete = function(_tax_year, _group_obj_key, _legal_entity_id){
            console.log("Delete function called");
            console.log(_tax_year+'............'+_group_obj_key +'............'+ _legal_entity_id);
            vm.isSavingDelete = true;
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                tax_year: _tax_year,
                group_obj_key: _group_obj_key,
                legal_entity_id: _legal_entity_id
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=h6eb8q";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    // $rootScope.$emit('gridUpdate', {});
                    $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
                    AlertService.add("success", "Filing Dates Setup deleted successfully", 4000);
                    console.log("deleted", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };
        
        vm.upper = function(filingCycle) {
            vm.entityFiling.FILING_CYCLE = filingCycle.toUpperCase();
        }

        vm.reset = function (){
			vm.entityFiling = angular.copy(vm.entityFiling_bckup);
		};

        vm.cancel = function () {
            $rootScope.$broadcast('dataFilters:refreshGrid', {"refresh": true});
            $uibModalInstance.dismiss('cancel');
        };
    }
    return controllers;
});
