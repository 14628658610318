define([
        'angular'
    ], function () {
        'use strict';
        const controllers = angular.module('app.newProformaDashboardCtrl', [])
                                   .controller('NewProformaDashboardCtrl', ['$rootScope', '$scope', '$sce', 'NewProformaFactory', 'NewProformaEfileViewFactory', 'WorkflowService', 'AlertService', '$uibModal', 'TAX_YEARS', 'GlobalService','$state', newProformaDashboardCtrl]);

        function newProformaDashboardCtrl($rootScope, $scope, $sce, NewProformaFactory, NewProformaEfileViewFactory, WorkflowService, AlertService, $uibModal, TAX_YEARS, GlobalService,$state) {
            const vm = this;
            vm.selectedFilterType = 'Efile';
            $state.current.name === 'workspace.state-efile-view' ? vm.isState = true: vm.isState = false;
            $state.current.name === 'workspace.state-efile-view' ? vm.cacheStore = 'STATE': vm.cacheStore = 'FED';
            // vm.isState = true;

            vm.sharedStates = NewProformaFactory.sharedStates;
            vm.efileFilters = {};
            vm.efileFiltersSelected = {};

            vm.efileFilters.taxYears = TAX_YEARS;
            
            vm.taxPayers ={
                param_name: "Tax Payers",
                values: [],
                label_name: "label",
                value_name: "value",
                label: "Tax Payers",
                selected:[],
                loading: false
            }
            if(NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected) {
                vm.efileFiltersSelected.taxYear = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxYear;
                vm.efileFilters.scenarios = vm.efileFiltersSelected.taxYear.scenarios.filter(function(s) {
                    return s.jcd_key === (vm.isState ? "253" : "250");
                })
                vm.efileFiltersSelected.scenario = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.scenario;
                vm.efileFiltersSelected.taxPayer = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer;
                vm.taxPayers.selected.push(NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer);
                vm.efileFiltersSelected.consolGroup = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.consolGroup;
                vm.taxPayers.values = [...NewProformaEfileViewFactory.cache[vm.cacheStore].taxPayers];
                vm.efileFilters.taxPayers = [...NewProformaEfileViewFactory.cache[vm.cacheStore].taxPayers];
                NewProformaEfileViewFactory.cache[vm.cacheStore].consolGroups && (vm.efileFilters.consolGroups = [...NewProformaEfileViewFactory.cache[vm.cacheStore].consolGroups]);
            }else{
                vm.efileFiltersSelected.taxYear = GlobalService.inputs.tax_year.selected;
                vm.efileFilters.scenarios = vm.efileFiltersSelected.taxYear.scenarios.filter(function(s) {
                    return s.jcd_key === (vm.isState ? "253" : "250");
                })
                vm.efileFiltersSelected.scenario = vm.efileFilters.scenarios.find(scenario => scenario.default_scenario === 'Y') || vm.efileFilters.scenarios[0] || {};
                
                loadFilingGroups();
            }

           

                


            vm.switchFilters = function (filterType) {
                if (vm.jurisLoading || vm.consolGroupsLoading || vm.taxPayersLoading) return;
                if (vm.selectedFilterType !== filterType) NewProformaFactory.resetStates();
                vm.selectedFilterType = filterType;
                if (NewProformaEfileViewFactory.cache[vm.cacheStore].treeData && !NewProformaEfileViewFactory.cache[vm.cacheStore].treeData[0].noDataFlag)
                    NewProformaFactory.sharedStates.showBulkExport = true;
            };

            function loadFilingGroups() {
                if(vm.isState){
                    loadTaxPayers();
                    return;
                }
                let selectedTaxYear = vm.efileFiltersSelected.taxYear.value;

                vm.efileFilters.consolGroups = [];
                vm.efileFiltersSelected.consolGroup = null;
                vm.consolGroupsLoading = true;
                NewProformaFactory.getFilingGroups(selectedTaxYear)
                                  .then((response) => {
                                      if (response.data.callSuccess === '1') {
                                          vm.efileFilters.consolGroups = response.data.jsonObject;
                                          vm.efileFiltersSelected.consolGroup = vm.efileFiltersSelected.consolGroup || vm.efileFilters.consolGroups[0];
                                          
                                          loadTaxPayers();
                                      } else {
                                          AlertService.add('error', 'Error loading filters: ' + response.data.errorMessage.toString());
                                      }
                                  })
                                  .finally(() => {
                                      vm.consolGroupsLoading = false;
                                  });
            }
            

            function loadTaxPayers() {
                let selectedTaxYear, selectedConsolGroup;
                selectedTaxYear = vm.efileFiltersSelected.taxYear.value;
                vm.efileFiltersSelected.consolGroup && (selectedConsolGroup = vm.efileFiltersSelected.consolGroup.VALUE);

                vm.efileFilters.taxPayers = [];
                vm.efileFiltersSelected.taxPayer = null;
                vm.taxPayersLoading = true;
                vm.taxPayers.loading = true;
                NewProformaFactory.getTaxPayers(selectedTaxYear, selectedConsolGroup,vm.isState,vm.efileFiltersSelected.scenario.scenario,vm.isState ?253:250)
                                  .then((response) => {
                                      if (response.data.callSuccess === '1') {
                                          vm.efileFilters.taxPayers = response.data.jsonObject;
                                        vm.taxPayers.selected = [];
                                        vm.taxPayers.values = response.data.jsonObject;
                                        NewProformaEfileViewFactory.cache[vm.cacheStore].taxPayers  = vm.taxPayers;
                                        vm.taxPayers.loading  = false;
                                          //vm.efileFiltersSelected.taxPayer = vm.efileFiltersSelected.taxPayer || vm.efileFilters.taxPayers[0];
                                      } else {
                                          AlertService.add('error', 'Error loading filters: ' + response.data.errorMessage.toString());
                                      }
                                  })
                                  .finally(() => {
                                      vm.taxPayersLoading = false;
                                  });
            }

            vm.changeTaxYear = function () {
                // vm.efileFiltersSelected.scenario = vm.efileFiltersSelected.taxYear.scenarios.find(scenario => scenario.default_scenario === 'Y');
                vm.efileFilters.scenarios = vm.efileFiltersSelected.taxYear.scenarios.filter(function(s) {
                    return s.jcd_key === (vm.isState ? "253" : "250");
                })
                vm.efileFiltersSelected.scenario = vm.efileFilters.scenarios.find(scenario => scenario.default_scenario === 'Y') || vm.efileFilters.scenarios[0] || {};
                
                loadFilingGroups();
            };

            vm.changeScenario = function () {
                loadFilingGroups();
            };

            vm.changeConsolGroup = function () {
                loadTaxPayers();
            };

            vm.loadIRSTree = function () {
                if (!vm.efileFiltersSelected.taxYear || !vm.efileFiltersSelected.scenario ||
                    (!vm.isState && !vm.efileFiltersSelected.consolGroup) || !vm.taxPayers.selected.length)//|| !vm.efileFiltersSelected.taxPayer)
                    return;
                    vm.efileFiltersSelected.taxPayer = vm.taxPayers.selected[0];
                NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected = vm.efileFiltersSelected;
                vm.efileFilters.consolGroups && (NewProformaEfileViewFactory.cache[vm.cacheStore].consolGroups = [...vm.efileFilters.consolGroups]);
                NewProformaEfileViewFactory.cache[vm.cacheStore].taxPayers = [...vm.efileFilters.taxPayers];
               //NewProformaEfileViewFactory.cache[vm.cacheStore].taxPayers = [...vm.taxPayers.selected[0]];

                $scope.$broadcast('loadIRSTree');
            };

            vm.selectAll = function () {
                if (vm.selectedFilterType === 'PDF') {
                    $scope.$broadcast('selectAll::PDF');
                } else {
                    $scope.$broadcast('selectAll::Efile');
                }
            };

            vm.merge = function () {
                if (vm.selectedFilterType === 'PDF') {
                    $scope.$broadcast('merge::PDF');
                } else {
                    $scope.$broadcast('merge::Efile');
                }
            };

            vm.export = function () {
                if (vm.selectedFilterType === 'PDF') {
                    $scope.$broadcast('showBulkExportModal::PDF');
                } else {
                    $scope.$broadcast('showBulkExportModal::Efile');
                }
            };
        }

        return controllers;
    }
);
