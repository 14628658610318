
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.process5713LinesService',[])

    .factory('process5713Factory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', 
    function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var process5713Factory = {};
        
        process5713Factory.process5713Lines = function(params){
            var params = params;
            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=9vmram',
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        
        return process5713Factory
    }])

    return services;

});