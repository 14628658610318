define([
    'angular',
    './WwniSubCategoryController',
    './WwniSubCategoryService'



], function () {
    'use strict';
    return angular.module('app.wwniSubCategory', ['app.wwniSubCategoryController','app.wwniSubCategoryService'] );


});
