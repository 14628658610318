define([
    'angular',
    './m3PayorAllocateController',
    './m3PayorAllocateServce'


], function () {
    'use strict';
    return angular.module('app.m3PayorAllocate', ['app.m3PayorAllocateController','app.m3PayorAllocateServce'] );


});