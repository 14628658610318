/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.section174Service',[])

        .factory("Section174Factory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','JsonObjectFactory','AlertService', 'GlobalService','DataFiltersFactory',
        			'workspaceFactory','GENERAL_CONFIG', 
        	function($q, $http, $stateParams,$timeout, $rootScope, $log, JsonObjectFactory,AlertService, GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
            var factory = {};
          
            factory.getSectionDetails = function(_callName, _data){
            	var checked = '';
           	 if (_data.rowData.SECTION_174 === 1) {
           		 checked = true;
           	 }else {
           		 checked = false;
           	 }
           	 
           	 var params = {
         	           "action_code" : 'mfnpur', 
         	           "scenario" :GlobalService.globalParams.scenario,
         	           "jcd_key" : GlobalService.globalParams.jcd_key,
         	           "tax_year" : _data.rowData.TAX_YEAR,
         	           "combination_key" : _data.rowData.COMBINATION_KEY,
         	           "checkedFalg" : checked
                   }
           	 
           	 var jsonObj = JSON.stringify(_data.rowData);
           	 var transdetailskey =_data.rowData.TRANS_DETAILS_KEY
           	 params =  _.extend({jsonObj:jsonObj}, params);
           	 var promise= JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function(data) {
           		 if (data.callSuccess === "1") {
           			 if (checked) {
    					AlertService.add("success", "Data has been checked", 3000);
           			 }else{
           				AlertService.add("success", "Data has been unchecked", 3000);
           			 }
           			var args = {
							updatekey:transdetailskey,
						
						gridFilter : {
							updatekey:transdetailskey,
						}
					};
					$rootScope.$emit('gridUpdate', args);
    				} else {
    					   AlertService.add("error","Data retrieval failed",3000);
    				}
   			});
           	 
               return promise;

           };

            return factory;

        }])


    return services;


});