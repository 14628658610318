
define(["angular","moment","jsZip"], function (a,moment,jsZip) {
    window.JSZip = jsZip;
    "use strict";
    var directives = angular
        .module("app.dashboardDatatableNew", [])

        .directive('gtDatatable2', gtDatatable)
        .directive('gtCommentTable',gtCommentTable);

    gtDatatable.$inject = ['$compile', '$filter', '$uibModal', '$templateCache', 'defaultDataTableConfig', 'workspaceFactory','$timeout','dataFormatterFactory','GENERAL_CONFIG','MessageFactory','AlertService','USER_SETTINGS','$rootScope'];
    function gtDatatable($compile, $filter, $uibModal, $templateCache, defaultTableConfig, workspaceFactory,$timeout,dataFormatterFactory,
        GENERAL_CONFIG,MessageFactory,AlertService,USER_SETTINGS,$rootScope) {
        // Usage:The parent div of the grid should have position:relative as the grid will fill the parent as absolute.
        //
        // Creates:
        //
        var directive = {
            link: link,
            scope: {
                options: '=',
                dtInstance:'=?'
            },

            template: "<div class='grid-wrapper'></div>",
            controller: gtDatatableController
        };


        function link(scope, element, attrs) {
            var tableId = "component-" + (scope.options.component_id || 'new') + (scope.options.previewMode ? '-preview':'');
            scope.rowsToExpand = [];
            scope.dtInstance = {
                getDtInstance:function() {
                    return scope._dtInstance;
                }
            }

            var parentElementPadding = parseInt(element.parent().css('padding-right')) + parseInt(element.parent().css('padding-left'));
            element.children().first().css('width','calc(100% - '+parentElementPadding+'px)')
            var footer = "";
            if (_.some(scope.tableOptions.columns, ["showTotal", true])) {
                footer = '<tfoot><tr>';
                _.each(scope.tableOptions.columns, function (value, index) {
                    footer += '<th></th>';
                });
                footer += '</tr></tfoot>';
            }

            $(element[0].firstChild).append("<table id='" + tableId + "' style='width:100%;' class='table table-bordered table-striped parent-table  table-condensed'>" + footer + "</table>");
            var container = null,resizerSensor = null;



            scope.tableOptions.initComplete = onInitComplete;
            scope.tableOptions.drawCallback = onDrawCallBack;
            scope.tableOptions.footerCallback = footerCallback;
            scope.tableOptions.createdRow = createdRowCallback;
            // scope.tableOptions.footer = true;


            setupActions(scope.tableOptions);
            scope.childTableOptions && setupActions(scope.childTableOptions);

            renderTable();


            if (scope.options.extraOptions.componentSettings.grouping || scope.options.extraOptions.componentSettings.showChildRows) {
                addExpandClickEvent();

                setConditionalRowExpand();
            }

            if (scope.options.extraOptions.componentSettings.drilldownEnabled) {
                addDrillDownEvent();
            }

            /**
             * METHODS
             */

            /**RENDER TABLES */
            function renderTable() {
                //contextGroupBy()

              //  addRowButtons(scope.tableOptions,scope.options.commentObj);
                setDefaultContent(scope.tableOptions.columns);
                enableSearch();
                if(scope.tableOptions.buttons){
                    enableButtons();
                }

                $timeout(function () {
                    container = element.children()[0];
                    if (scope.tableOptions.columns.length === 0) {
                        container.find('#' + tableId).append('<h4 class="component-error-message">Please add columns to view table</h4>');
                        return;
                    } else {
                        var drillDownCol = _.find(scope.tableOptions.columns, ['className', 'drill-down text-center']);
                        if (drillDownCol)
                            drillDownCol.render = function () {
                                return "<i class='fa fa-chain'></i>"
                            }
                        if (scope.childTableOptions) {
                            var drillDownCol = _.find(scope.childTableOptions.columns, ['className', 'drill-down text-center']);
                            if (drillDownCol)
                                drillDownCol.render = function () {
                                    return "<i class='fa fa-chain'></i>"
                                }
                        }
                    }

                    scope.tableOptions.responsive = false;
                    scope.tableOptions.scrollX = true;
                    scope._dtInstance = $(element[0].firstChild).find("#" + tableId).dataTable(scope.tableOptions);

                    expandCachedRows();

                }, 200);
            }

            function enableSearch() {
                if (scope.tableOptions.searching) {
                    scope.tableOptions.dom = 'f' + scope.tableOptions.dom;
                }
            }
            function enableButtons() {
                if (scope.tableOptions.searching) {
                    scope.tableOptions.dom = 'B' + scope.tableOptions.dom;
                }
            }

            function setupActions(options){
                options.columns.forEach(function(col,index) {

                    if(!col.actions || col.actions.length === 0){
                        return;
                    }

                    col.fnCreatedCell = function(nTd, sData, oData, iRow, iCol) {
                        col.actions.forEach(function(action) {
                            if(!action.targetSelector || !action.event){
                                return;
                            }
                            $(nTd).find(action.targetSelector).on(action.event,function(e) {
                                action.callback(oData,e);
                            })
                        })
                    }
                })
            }



            function addRowButtons(table,commentObj) {
                if (table && table.columns) {
                    for (var i = 0; i < table.columns.length; i++) {
                        if (table.columns[i].data !== null) {

                            table.columns[i].fnCreatedCell = function (nTd, sData, oData, iRow, iCol) {
                                for (let j = 0; j < table.rowButtons.length; j++) {

                                    $(nTd).prepend(table.rowButtons[j].template)

                                }
                                if(commentObj && oData && commentObj.length>0){
                                    _.each(commentObj,function(i){
                                        if(i.iRow == iRow && i.iCol == iCol){
                                           $(nTd).prepend("<span><i class='fa fa-comment-o info-icon' id=" + iRow + "-" + iCol + "></i></span>");
                                           if(i.applyColor){
                                                $(nTd).css('background-color','rgb(227, 222, 74)');
                                           }

                                        }
                                    })
                                }

                                $(nTd).find('.add-comment-btn').on('click', openModal({oData:oData,locationId:nTd,value: sData, row_id: tableId + '-' + iRow  , col_id: iCol, tableState:{},element:'cell', commentData: scope.commentObject}))


                                $(nTd).find('.info-icon').on('click', function (e) {

                                    var tr = $(this).closest('tr');
                                    var row = scope._dtInstance.api().row(tr);
                                    scope.commentObject = commentObj;
                                    var cellComment = _.find(commentObj,{iRow:iRow,iCol:iCol});

                                    var buildTableObject = {
                                        sData: sData,
                                        rowNum: iRow,
                                        colNum: iCol,
                                        event: e,
                                        commentData: cellComment ? cellComment.comments : [],
                                        row: row,
                                        tr: tr
                                    };

                                    $(e.target).toggleClass("info-icon-active")

                                    //If table is present in row child
                                    if (row.child.isShown()) {
                                        //If comment table is of same cell
                                        if (!$(e.target).hasClass('info-icon-active')) {
                                            //Slide up the previous comment table
                                            commentTableSlideUp(buildTableObject)
                                        }
                                        //If comment table is of different cell /child data table is displayed
                                        else {
                                            //In case child data table is shown then toggle '-' icon to '+' icon
                                            $(tr).removeClass('shown')
                                            //Remove active info icon from row cells
                                            $(tr).find('.info-icon').removeClass('info-icon-active')
                                            //Add active info icon on row cell
                                            $(e.target).addClass('info-icon-active')
                                            //Slide up the previous comment/child data table and show current comment table
                                            commentTableSlideUp(buildTableObject, true);
                                        }
                                    }
                                    //If no table present in row child then display current comment table
                                    else {

                                        buildMessageTable(buildTableObject)
                                    }
                                });
                            }
                        }
                    }
                }



            }

            function commentTableSlideUp(tableObject, flag) {
                scope.$apply();
              /*   var element = $((tableObject.tr)[0].nextElementSibling).find('.comment-table')[0] ? $((tableObject.tr)[0].nextElementSibling).find('.comment-table')[0] : $((tableObject.tr)[0].nextElementSibling).find('.child-data-table')[0];

              $(element,tableObject.row.child()).slideUp(function(){
                    tableObject.row.child.hide();
                    if(flag){
                        buildMessageTable(tableObject);
                    }

                }); */
               // if ($(tr).find('.info-icon').hasClass('info-icon-active')) {
                    $('div.comment-table', tableObject.row.child()).slideUp(function () {
                        tableObject.row.child.hide();
                      //  $(tr).find('.info-icon').removeClass('info-icon-active')

                       // renderChildDataTable(scope.childTableOptions, row, e, tr)
                       if(flag){
                        buildMessageTable(tableObject);
                    }
                    })

              //  }

                scope.$apply();

            }

            function openModal(comment) {

                return function (e) {
                    var ctrlString = "AddCommentController as ctrl";
                    comment.type = 'dashboard';
                    scope.gridData = {};
                    if (comment && comment.comment_key) {
                        ctrlString = "EditCommentController as ctrl";
                        scope.gridData.comment_key = comment.comment_key;
                        scope.gridData.row_key = comment.row_id;

                       // scope.gridData.type = 'dashboard';
                    }else{
                        scope.gridData = {
                            selected: comment
                        }
                    }
                    scope.gridData.type = 'dashboard';
                    scope.rowData = [];
                    scope.colData = [];
                    console.log("openm Modal clicked");
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: "app/components/messages/templates/add-comment-modal-tpl.html",
                        controller: ctrlString,
                        size: "md",
                        resolve: {
                            rowData: function () {
                                return scope.rowData;
                            },
                            colData: function () {
                                return scope.colData;
                            },
                            gridData: function () {
                                return scope.gridData;
                            }
                        }
                    });
                    modalInstance.result.then(function (selectedItem) {
                        scope.selected = selectedItem;
                    }, function () {
                        if (comment) {
                        }
                    });
                }
            }

            function buildMessageTable(tableObject){
                var messageDiv = "";
                scope.tableObject11 = tableObject;
                scope.componentId = scope.options.component_id;

                var table= '<div class="comment-table" gt-comment-table table-data="tableObject11" component-id="componentId"></div>';
                var html = $compile(table)(scope);
                scope.$apply();
                tableObject.row.child(html).show();

                $('div.comment-table', tableObject.row.child()).slideDown();
            }

            function downloadDocument(comment){

                MessageFactory.downloadMsgAttachment(comment.fileName, comment.commentKey,comment.docKey);
            }
            function resolveMessages(comment){
                console.log("dlete clicked");
                if(comment){
                    comment.staus = 'closed';
                    if(comment.sso_id == USER_SETTINGS.user.sso_id || USER_SETTINGS.user.system_level_access>=3){
                        MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + '/resolveComment', comment).then(function (_data) {
                            if(_data && _data.callSuccess == 1){
                                AlertService.add('success',"Message Resolved",4000);
                                $rootScope.$broadcast("loadDashboardMessages",scope.options.component_id);
                            }
                        });
                    }
                }
            }

            function onInitComplete() {
                //checking for false to not make old dashboard break when showChildRows is undefined
                if (scope.options.extraOptions.componentSettings.grouping &&
                     scope.options.extraOptions.componentSettings.showChildRows !== false &&
                     !scope.options.extraOptions.componentSettings.rowGrouping)
                    enableGroupingOnTheFly.call(this);

                enableColSearch.call(this);
                scope.tableOptions.initCallBack && scope.tableOptions.initCallBack.call(this);
            }

            function footerCallback(row, data, start, end, display) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function (i) {
                    var v = typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                        i : 0;
                    return _.isNaN(v) ? 0: v;
                };





                api.columns().every(function (col) {
                    if(col === 0){
                        $(api.column(col).footer()).html('Grand Total');
                    }
                    if (!scope.tableOptions.columns[col].showTotal) {
                        return;
                    }
                    // Total over all pages
                    var total = api
                        .column(col)
                        .data()
                        .reduce(function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer
                    $(api.column(col).footer()).html(
                        scope.dataTableAmountFormatter(total)
                    );
                })
            }

            function onDrawCallBack() {
                if (scope.options.extraOptions.componentSettings.rowGrouping) {
                    enableRowGrouping.call(this)
                }

                setTimeout(function() {
                    if (container)
                        resizerSensor = new ResizeSensor(container, resizeTable);
                })//to avoid initial resize sensing by waiting for the whole grid to be rendered on UI

            }
            function createdRowCallback (row, data, index ) {
                if(data.rowExpanded){
                    scope.rowsToExpand.push(row);
                }

                if(scope.options.extraOptions.componentSettings.conditionalRowStyling){
                    var condition = scope.options.extraOptions.componentSettings.rowStylingCondition;

                    condition && (condition = condition.replace(/\{(.*?)}/g, function(a, token) {
                        return data[token];
                    }))
                    try {
                        var result = scope.$eval(condition);

                        if(result === true){
                            $(row).addClass(scope.options.extraOptions.componentSettings.rowStylingClassName);
                        }
                    } catch (error) {

                    }
                }
            }

            function expandCachedRows () {
                scope.rowsToExpand.forEach(function(row) {
                    rowExpandClickHandler(null,row);
                })
            }

            function enableRowGrouping() {

                var rowGroupingItemIndex;
                scope.tableOptions.columns.find(function (item, index) {
                    if (item.rowGrouping) {
                        rowGroupingItemIndex = index;

                    }

                })
                var groupColumn = rowGroupingItemIndex;
                console.log(groupColumn)
                var api = this.api();
                var rows = api.rows({ page: 'current' }).nodes();
                var last = null;

                api.column(groupColumn, { page: 'current' }).data().each(function (group, i) {
                    console.log(group)
                    if (last !== group) {
                        $(rows).eq(i).before(
                            '<tr class="group"><td colspan="'+scope.tableOptions.columns.length+'">' + group + '</td></tr>'
                        );

                        last = group;
                    }
                });

            }

            /**
             * Set defaultContent = "-" for all columns if not set
             * @param {*} columns
             */
            function setDefaultContent(columns){
                columns.forEach(function (col) {
                    if (col.data && !col.defaultContent)
                        col.defaultContent = "-";
                })
            }

            function setConditionalRowExpand () {
                var detailControlCol = scope.tableOptions.columns[0];

                if(!detailControlCol.className ||  detailControlCol.className !== 'details-control'){
                    return;
                }

                if (!detailControlCol.fnCreatedCell) {
                  detailControlCol.fnCreatedCell = function (nTd, sData, oData, iRow, iCol) {
                    if(!_.isUndefined(oData.HAS_SUB_LABEL) && oData.HAS_SUB_LABEL === 0){//TODO: HAS_SUB_LABEL should come from options and 0 to falsy
                        $(nTd).removeClass('details-control');
                    }
                  };
                }
            }



            /**
             * Column filter Methods
             */

            function enableColSearch() {
                this.api().columns().every(function () {
                    var that = this;

                    if (!that.settings()[0].aoColumns[that[0]].searchable) {
                        return;
                    }

                    var filterToggle = $('<i class="fa fa-filter pull-right" style="margin-top:3px;cursor:pointer;margin-right: 3px;"></i></br>')
                        .appendTo(that.header())

                    var inputBox = $('<input class="col-md-12" type="text" onclick="event.stopImmediatePropagation()"/>')
                        .appendTo(that.header())

                    $(inputBox).hide();

                    $(filterToggle).on('click', function (e) {
                        $(inputBox).toggle();

                        e.stopImmediatePropagation();
                    })


                    $(inputBox).on('keyup change clear', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    });
                });
            }
            /**
             * Enables user to be able to change the grouping to get insights
             */
            function enableGroupingOnTheFly() {
                this.api().columns().every(function () {
                    var that = this;

                    // if (that.settings()[0].aoColumns[that[0]].data !== scope.options.extraOptions.componentSettings.groupColumnsBy) {
                    if (that[0][0] != 0) {
                        return;
                    }
                    var allColumns = _.chain(scope.options.data[0]).pickBy(_.isString).keys().value();

                    var allColumnsTemplate = '';
                    _.each(allColumns, function (col, key) {
                        var tick = "";
                        if (col === scope.options.extraOptions.componentSettings.groupColumnsBy) {
                            tick = '<i class="fa fa-check" style="margin-right:5px;"></i>'
                        }
                        allColumnsTemplate += '<div class="dropdown-item" style="color:black;font-weight:100;padding: 0 10px;cursor:pointer;border-bottom: .5px solid grey;">' + tick + col + '</div>';
                    })

                    var dropdownToggle = $('<div class="dropdown" style="margin: 0 5px;">'
                        + ' <button class="btn btn-secondary btn-tranparent dropdown-toggle" title="Change Grouping" style="padding: 0;border: none;background: transparent;" type="button" id="groupByMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">'
                        + '  <i class="fa fa-bars"></i> '
                        + ' </button>'
                        + ' <div class="dropdown-menu group-by-menu" aria-labelledby="groupByMenuButton" style="padding: 0;">'
                        //    +'<div style="color: white;padding: 5px;background: #5a5a5a;">Change Grouping:</div>'
                        + allColumnsTemplate
                        + ' </div>'
                        + '</div>');

                    $(that.header()).removeClass('sorting_asc sorting_desc').addClass('grouping');
                    $(that.header()).html(dropdownToggle);

                    // Stop the dropdown toggle to cause column sort
                    $(dropdownToggle).unbind('click');
                    $(dropdownToggle).on('click', function (e) {
                        e.stopPropagation();
                        $(this).find('.dropdown-menu').css({
                            position:'fixed',
                            left:e.clientX,
                            top:e.clientY
                        })//default absolute version is not working with the scrolling enabled as it is getting hidden in the overflow.
                        $(this).find('.dropdown-menu').toggle();
                    })

                    $(dropdownToggle).find('.dropdown-item').unbind('click');
                    $(dropdownToggle).find('.dropdown-item').on('click', function (e) {


                        var amountCols = scope.options.extraOptions.componentSettings.amountColumns || [];
                        var nonAmountCols = scope.options.extraOptions.componentSettings.nonAmountColumns || [];
                        var sheetData = angular.copy(scope.options.data);
                        var groupColumnsBy = e.target.textContent;

                        scope._dtInstance.fnDestroy();

                        // function getColToUpdate(colToUpdate) {
                        //     return scope.options.extraOptions.componentSettings.tableOptions.columns.find(function (item) {
                        //         return item.data === colToUpdate;
                        //     })
                        // }

                        // var colToUpdate = scope.options.extraOptions.componentSettings.tableOptions.columns[0]
                        // Get column on which grouping is applied if available on main table
                        // var colToUpdate = getColToUpdate(groupColumnsBy)

                        // // If column not available on main table, get the current column which has group by button
                        // if (!colToUpdate) {
                        //     colToUpdate = getColToUpdate(scope.options.extraOptions.componentSettings.groupColumnsBy)
                        // }

                        var colToUpdate = scope.options.extraOptions.componentSettings.tableOptions.columns.find(function (item) {
                            return item.data === scope.options.extraOptions.componentSettings.groupColumnsBy;
                        })
                        if (colToUpdate) {
                            colToUpdate.data = groupColumnsBy;
                            colToUpdate.title = "Group Name (" + groupColumnsBy + ")";
                        }

                        scope.tableOptions = angular.copy(scope.options.extraOptions.componentSettings.tableOptions);

                        scope.tableOptions.initComplete = onInitComplete;
                        scope.tableOptions.drawCallback = onDrawCallBack;

                        for (var key in nonAmountCols) {
                            if (nonAmountCols[key] === scope.options.extraOptions.componentSettings.groupColumnsBy) {
                                nonAmountCols[key] = groupColumnsBy;
                                break;
                            }
                        }

                        scope.tableOptions.data = scope.groupBy(sheetData, groupColumnsBy, nonAmountCols, amountCols,scope.tableOptions.columns);


                        scope.options.extraOptions.componentSettings.groupColumnsBy = groupColumnsBy;

                        scope.setDefaultOrderby();

                        if (scope.tableOptions && scope.tableOptions.columns && scope.tableOptions.columns.length > 0) {
                            scope.tableOptions.columns.forEach(function (col, index) {
                                if (col.formatAmount || col.format) {
                                    col.render = scope.dataTableAmountFormatter;
                                }
                            })
                        }

                        // scope._dtInstance = $(element[0].firstChild).find("#" + tableId).dataTable(scope.tableOptions);
                        renderTable();

                        // setTimeout(function () {

                        //     scope._dtInstance.fnAdjustColumnSizing();
                        //     scope._dtInstance.fnDraw();
                        // }, 3000)
                        // this.api().columns.adjust();
                    })


                });
                 this.api().columns.adjust();
            }

            /**RESIZE TABLE */
            var timeout = null;

            function resizeTable() {
                if(timeout)
                    clearTimeout(timeout);

                console.log("resize called for table with id:" , scope._dtInstance ? scope._dtInstance[0].id : 'null');

                // timeout = setTimeout(doneResizing, 50);//This is to avoid calling the setsize on each animation frame
                scope._dtInstance.api().columns.adjust();
                function doneResizing() {
                    // console.log('resize detected, panel height',panel.height())
                    //var grids = panel.find('.grid-wrapper');
                    //console.log(grids.length + " grids found in the panel.");
                    //grids.height((100 / grids.length) - 5 + '%');
                    if (scope.groupBy) {
                        scope._dtInstance.api().columns.adjust().draw();
                        // scope._dtInstance.fnDraw();
                        console.log('table redrawn');
                    }
                }
            }
            /**DOM EVENTS */

            function addDrillDownEvent() {

                $(element).on('click', 'td.drill-down i', function (e) {

                    var tr = $(this).closest('tr');
                    var row = $(e.currentTarget.parentElement.parentElement.parentElement.parentElement).dataTable().api().row(tr);
                    var rowData = row.data();

                    var stFilter = JSON.parse(scope.options.extraOptions.componentSettings.drilldownFilterJson);

                    var _filter_json = "";
                    var filterJson = _.find(stFilter, { key: rowData[scope.options.extraOptions.componentSettings.drillDownBy] });


                    if (filterJson && filterJson.filter_json) {
                        _filter_json = filterJson.filter_json;
                    } else {
                        _filter_json = stFilter;
                    }
                    var _table_state = "";
                    var _location_obj = "";
                    var gridFilters = {
                        gridFilters: [],
                        drillDownRefresh: 0,
                        filterObj: {},
                        screenFilter: []
                    };

                    var screenFilters = function (screenFilters) {
                        _.forEach(screenFilters, function (value, key) {
                            var _value = rowData[value.row_data_key];
                            /*2/16/2020 dvalaboju001 scenario*/
                            if (value.name == 'parent_me_string' || value.name == 'scenario' || value.name == 'filing_key') {
                                _value =  rowData.children ? rowData.children[0][value.row_data_key] :  rowData[value.row_data_key];
                            }
                            if (_value && (_value.length || _.isNumber(_value))) {
                                var obj = {};
                                obj.predicate = value.name;
                                obj.value = [];
                                obj.type = "array";
                                obj.reverse = false;
                                obj.operator = "[]";
                                obj.value.push(_value);
                                if (value.row_data_key == 'TAX_YEAR') {
                                    value.value = _value + '';
                                } else if (value.row_data_key == 'SCENARIO') {
                                    value.value = _.isString(_value) ? parseInt(_value) : _value;
                                } else {
                                    value.value = _value;
                                }

                                gridFilters.screenFilter.push(obj);
                            }
                        });
                        var gridFilterObj = [];
                        gridFilters.screenFilter.forEach(item => {
                            gridFilterObj.push({ name: item.predicate, value: item.value.join('') });
                        });
                    }
                    if (_filter_json && _filter_json.screenFilters) {
                        screenFilters(_filter_json.screenFilters)
                    }

                    // workspaceFactory.loadLink("dashboard", "{}", scope.options.extraOptions.componentSettings.drilldownToActivityId,
                    //     scope.options.extraOptions.componentSettings.drilldownToScreenId, _filter_json, _table_state, _location_obj, "");
                    workspaceFactory.loadLink("dashboard", "{}", _filter_json.activity_key,
                        _filter_json.screen_key, _filter_json.screenFilters, gridFilters.gridFilters, _location_obj, _filter_json.action_template_ley);
                })

            }

            function addExpandClickEvent() {
                // var $demo1;
                //On Drilldown
                $(element).on('click', 'td.details-control', rowExpandClickHandler);
            }
            function rowExpandClickHandler(e,row) {

                // global = e;
                var tr = row ? $(row) : $(this).closest('tr');
                var row = scope._dtInstance.api().row(tr);

                //If table is present in row child
                if (row.child.isShown()) {
                    //If comment table is already present then slide it up and show child data table
                    if ($(tr).find('.info-icon').hasClass('info-icon-active')) {
                        $('div.comment-table', row.child()).slideUp(function () {
                            row.child.hide();
                            $(tr).find('.info-icon').removeClass('info-icon-active')

                            renderChildDataTable(scope.childTableOptions, row, e, tr)
                        })

                    }
                    //If Comment Table is not present but child data table is present then toggle
                    else {
                        row.child.hide();
                        var cachedData = _.find(scope.options.data,row.data());
                        cachedData && (cachedData.rowExpanded = false);
                        tr.removeClass('shown');
                    }
                    // scope._dtInstance.fnDraw();
                    console.log('table redrawn');
                }
                //If no table is shown
                else {
                    renderChildDataTable(scope.childTableOptions, row, e, tr)
                }
            }
            function renderChildDataTable(actualOptions, row, e, tr) {

                setDefaultContent(actualOptions.columns);

                if (actualOptions.isAjax && actualOptions.ajax !== undefined) {

                    actualOptions.ajax.url = scope.childTableOptions.ajaxUrlCopy.replace(/{(.*?)}/g, function(a, token) {
                        var result = (row.data()[token] || '');
                        return result;
                    });

                    actualOptions.data = [];

                    actualOptions.ajax.dataSrc = function (json) {
                        if (json[scope.childTableOptions.ajax.dataProp]) return json[scope.childTableOptions.ajax.dataProp];

                        //Filter Child Ajax Data With Top Level Filters
                        // var childData = _.chain(json).filter([scope.groupBy, row.data()[scope.groupBy]]).filter().value();

                        // var childDataSafeArr = childData.slice(0);

                        // _.forEach(scope.topFilter, function (value) {
                        //     if (value.value.length > 0) {
                        //         childDataSafeArr = filteredChildData(childDataSafeArr, value);
                        //     }
                        // })

                        // return childDataSafeArr


                    }
                    actualOptions.paging = false;
                }
                else {
                    // if (row.data()['TAX_YEAR']) {
                    //     row.data().children = row.data().children.filter(function (item, index) {
                    //         return item['TAX_YEAR'] === row.data()['TAX_YEAR']
                    //     })
                    // }
                    var childDataProp = scope.options.extraOptions.componentSettings.childDataProp || 'children';
                    var data = row.data();
                    var cachedData = _.find(scope.options.data,data);
                    cachedData && (cachedData.rowExpanded = true);
                    actualOptions.data = data ? data[childDataProp] : [];

                    if(scope.options.extraOptions.componentSettings.childGrouping){

                        actualOptions.data = scope.groupBy(actualOptions.data, scope.options.extraOptions.componentSettings.groupChildColumnsBy,
                            _.chain(actualOptions.columns).filter((col)=>{ return _.isString(actualOptions.data[0][col.data]) })
                            .map(col => col.data).value(),
                            _.chain(actualOptions.columns).filter((col)=>{ return _.isNumber(actualOptions.data[0][col.data]) })
                            .map(col => col.data).value(),
                            actualOptions.columns);
                    }
                }

                actualOptions.scrollY = '';
                var temp = "<div class='child-data-table'><table class='table table-bordered table-striped sticky-header  table-condensed' style='width:100%'></table></div>"
                row.child(temp).show();
                tr.addClass('shown');
                var dataTableOptions = Object.assign({}, actualOptions);
                // dataTableOptions.initComplete = onInitComplete;

                dataTableOptions.columns.forEach(function (col, index) {
                    if (col.formatAmount || col.format) {
                        col.render = scope.dataTableAmountFormatter;
                    }
                })
                if (dataTableOptions.columns.length === 0) {
                    $(row.child().find('table')).append('Please add columns to view child table');
                    return;
                }
                scope.childTable = $(row.child().find('table')).dataTable(dataTableOptions);
            }



            scope.$on('$destroy', function () {
                if (resizerSensor)
                    resizerSensor.detach();
            })
        }
        gtDatatableController.$inject = ['$scope', 'defaultDataTableConfig', '$compile','dataFormatterFactory'];
        function gtDatatableController($scope, defaultDataTableConfig, $compile,dataFormatterFactory) {

            /**DEFAULTS */
            var grouping = _.isUndefined($scope.options.extraOptions.componentSettings.grouping) ? false : $scope.options.extraOptions.componentSettings.grouping;
            var amountCols = $scope.options.extraOptions.componentSettings.amountColumns || [];
            var nonAmountCols = $scope.options.extraOptions.componentSettings.nonAmountColumns || [];
            var sheetData = angular.copy($scope.options.data);
            var groupColumnsBy = $scope.options.extraOptions.componentSettings.groupColumnsBy;
            $scope.tableOptions = _.merge(angular.copy(defaultDataTableConfig), $scope.options.extraOptions.componentSettings.tableOptions);
            var activeScreen = workspaceFactory.activeScreen;

            var filterParams = {};

            activeScreen && activeScreen.filters && (filterParams = activeScreen.filters.getFilterParams());

            /**INITIALIZATIONS */
            setRowGroupIndicatorColumn();

            $scope.tableOptions.data = sheetData;
            console.log("Table Options", $scope.tableOptions)

            if ($scope.options.extraOptions.componentSettings.childTableOptions) {
                console.log("Co Settings", $scope.options.extraOptions.componentSettings)
                $scope.childTableOptions = angular.copy(_.merge($scope.options.extraOptions.componentSettings.childTableOptions, defaultDataTableConfig));
                // make a copy of the ajax url in case of a template url is sent for child table
                $scope.childTableOptions.ajax && $scope.childTableOptions.ajax.url && ($scope.childTableOptions.ajaxUrlCopy = $scope.childTableOptions.ajax.url);
            }


            if (grouping) {
                /**GROUPING DATA BASED ON THE PARAMS */
                $scope.tableOptions.data = groupBy(sheetData, groupColumnsBy, nonAmountCols, amountCols,$scope.tableOptions.columns);

            }

            if ($scope.tableOptions && $scope.tableOptions.columns && $scope.tableOptions.columns.length > 0) {
                $scope.tableOptions.columns.forEach(function (col, index) {
                    if (col.formatAmount || col.format) {
                        col.render = dataTableAmountFormatter;
                    }
                })
            }

            setDefaultOrderby();

            setupColumnNames($scope.tableOptions.columns);
            $scope.childTableOptions && setupColumnNames($scope.childTableOptions.columns);


            /**MEMBER FUNCITONS */

            /**
             * Exposing methods to Link
             */
            $scope.dataTableAmountFormatter = dataTableAmountFormatter;
            $scope.groupBy = groupBy;
            $scope.setDefaultOrderby = setDefaultOrderby;


            /**
             * Dynamic Column Manipulations
             */
            function setRowGroupIndicatorColumn() {
                if (grouping &&
                    $scope.options.extraOptions.componentSettings.showChildRows !== false &&
                    $scope.options.extraOptions.componentSettings.addGroupNameCol !== false) {
                    // $scope.options.extraOptions.componentSettings.tableOptions.columns =
                    _.remove($scope.tableOptions.columns, function (item) {
                        return item.data === groupColumnsBy && !item.groupIndicator;
                    }); //To Remove duplicate column which is not a groupIndicator
                    // Add group by column if doesn't exists
                    var groupByColumn = $scope.tableOptions.columns.find(function (item) {
                        return item.groupIndicator;
                    });
                    let title = "Group Name (" + groupColumnsBy + ")";
                    if (!groupByColumn) {
                        let index = $scope.tableOptions.columns.findIndex(function (item) {
                            return item.hasOwnProperty("title");
                        });
                        if (index < 0) {
                            index = 1;
                        }
                        $scope.tableOptions.columns.splice(index, 0, {
                            title: title,
                            data: groupColumnsBy,
                            groupIndicator: true,
                            defaultContent: ""
                        });
                        // nonAmountCols.push(groupColumnsBy);
                    }
                    else {
                        groupByColumn.data = groupColumnsBy;
                        groupByColumn.title = title;
                    }
                    if (!nonAmountCols.includes(groupColumnsBy)) {
                        //newly pushed row will be absent in nonAmountsCols
                        nonAmountCols.push(groupColumnsBy);
                    }
                }
                else {
                    _.remove($scope.tableOptions.columns, function (item) {
                        return item.groupIndicator;
                    });
                }
            }

            function setupColumnNames(columns){
                if(!filterParams) return;

                columns.forEach(function(col) {
                    col.title = evaluateTitle(col.title);
                })


                function evaluateTitle(title){

                    //replace tokens
                    title &&
                    (title = title.replace(/\{(.*?)}/g, function(a, token) {


                        let result = (filterParams[token] || '');
                        // tokens.push(result);
                        return result;
                    }));

                    //evalutaion expressions
                    title &&
                    (title = title.replace(/\[(.*?)]/g, function(a, expression) {
                        let result = $scope.$eval(expression);
                        // tokens.push(result);
                        return result;
                    }));

                    return title;
                }
            }



            /**
             * Formatters
             */

            function dataTableAmountFormatter( data, type, row, meta ) {
                if(type === 'display' || type === 'filter' || typeof type === 'undefined') {//footer gives undefined
                    var dataToShow = data;

                    var col =meta? meta.settings.aoColumns[meta.col]: { formatAmount: true };//meta is undefined for footer.
                    var formatter = dataFormatterFactory.getFormatter(col.format) || (col.formatAmount ? dataFormatterFactory.getFormatter('Amount') : null);
                    if(!formatter || (!col.format && !col.formatAmount)){
                        return data;
                    }

                    //var cellData = data < 0 ? "<span style='color:red' class='datatable-revenue'>(" + $filter('convertAmount')(Math.abs(dataToShow), "$") + ")</span>" : "<span class='datatable-revenue'>" + $filter('convertAmount')(dataToShow, "$") + "</span>";
                    return `<div class="dt-cell-data ${formatter.name.toLowerCase()}">${_.isUndefined(data) ? '-' : (formatter.func? formatter.func(data,undefined,col.prefix || '') : data)}</div>`;
                }else{
                    console.log({data,type,row,meta})
                    return data;
                }
            }

            /**Data Manipulations Methods*/

            function groupBy(data, groupBy, nonNumericCols, numericAmountCols,tableCols) {
                return _.chain(data).groupBy(groupBy).map(function (groupedData, key) {
                    var dataObj = {
                        children: groupedData
                    }
                    nonNumericCols.forEach(function (col) {
                        let column = {..._.find(tableCols,["data",col])};
                        
                        if(column.aggregator !== 'count'){
                            dataObj[col] = groupedData[0][col];
                            return;
                        }

                        dataObj[col] = groupedData.length;

                    })

                    if($scope.options.extraOptions.componentSettings.defaultOrderby){
                        //Add the order column to the dataset
                        dataObj[$scope.options.extraOptions.componentSettings.defaultOrderby] = groupedData[0][$scope.options.extraOptions.componentSettings.defaultOrderby];
                    }

                    var booleanCols = _.chain(groupedData[0]).keys()
                                            .filter((item)=>item.length > 4 && item.substr(0,4).toLowerCase() === "has_").value();//All booleans/bit should start with 'has_'

                    booleanCols.forEach(function (col) {
                        dataObj[col] = groupedData[0][col]
                    })


                    numericAmountCols.forEach(function (col) {
                        let column = {..._.find(tableCols,["data",col])};

                        column && (column.params = column.params || {});
                        column && (column.params.dataObj = data);
                        column && (column.params.col = col);

                        dataObj[col] = _.chain(groupedData)
                                            .map(col)[
                                                (column ? column.aggregator : 'sum') || 'sum'
                                            ](column ? column.params : null).value();
                        // dataObj[col] = _.sumBy(groupedData, col)
                    })
                    return dataObj;

                }).value()
            }

            function setDefaultOrderby() {
                $scope.tableOptions.order = [];

                if ($scope.options.extraOptions.componentSettings.defaultOrderby){
                    $scope.tableOptions.data = _.sortBy($scope.tableOptions.data,$scope.options.extraOptions.componentSettings.defaultOrderby);//not working for props that are not added in DT columns
                }

                if($scope.options.extraOptions.componentSettings.rowGrouping){
                    $scope.tableOptions.ordering = false
                }
            }


            /**Other Methods*/




        }
        return directive;
    }

    gtCommentTable.$inject = ['$compile', '$filter', '$uibModal','GENERAL_CONFIG','MessageFactory','AlertService','USER_SETTINGS','$rootScope'];
    function gtCommentTable($compile, $filter, $uibModal,
        GENERAL_CONFIG,MessageFactory,AlertService,USER_SETTINGS,$rootScope) {
            var directive = {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    tableData: '=',
                    componentId: '='
                },
                controller: ['$scope', function ($scope) {
                    var vm = this;
                    vm.resolveLoading = false;
                    vm.minimise = true;
                    vm.tableData = $scope.tableData;
                    vm.component_id = $scope.componentId;
                    console.log("tableadata", vm.tableData);
                    console.log("component id",vm.componentId);

                   $scope.timeago = function (date) {
                        var date = new Date(date);
                        return moment(date).fromNow();
                  }
                    vm.downloadAttachment = function(fileName,commentKey,docKey){
                        MessageFactory.downloadMsgAttachment(fileName, commentKey,docKey);
                    }
                    vm.resolveComment = function(comment,index){
                        if(comment){
                            var comment = {
                                comment_key : comment.comment_key,
                                sso_id: comment.sso_id,
                                status: 'closed'
                            }
                            comment.staus = 'closed';
                            vm.tableData.commentData[index].resolveLoading = true;
                            if(comment.sso_id == USER_SETTINGS.user.sso_id || USER_SETTINGS.user.system_level_access>=3){
                                MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + '/resolveComment', comment).then(function (_data) {
                                    if(_data && _data.callSuccess == 1){
                                        AlertService.add('success',"Message Resolved",4000);
                                        vm.tableData.commentData[index].resolveLoading = false;
                                        $rootScope.$broadcast("loadDashboardMessages",vm.component_id);
                                    }
                                });
                            }
                        }
                    }
                    vm.openModal = function(comment){
                      //  return function (e) {
                            var ctrlString = "AddCommentController as ctrl";
                            comment.type = 'dashboard';
                            $scope.gridData = {};
                            if (comment && comment.comment_key) {
                                ctrlString = "EditCommentController as ctrl";
                                $scope.gridData.comment_key = comment.comment_key;
                                $scope.gridData.row_key = comment.row_key;

                               // scope.gridData.type = 'dashboard';
                            }else{
                                $scope.gridData = {
                                    selected: comment
                                }
                            }
                            $scope.gridData.type = 'dashboard';
                            $scope.rowData = [];
                            $scope.colData = [];
                            console.log("openm Modal clicked");
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: "app/components/messages/templates/add-comment-modal-tpl.html",
                                controller: ctrlString,
                                size: "md",
                                resolve: {
                                    rowData: function () {
                                        return $scope.rowData;
                                    },
                                    colData: function () {
                                        return $scope.colData;
                                    },
                                    gridData: function () {
                                        return $scope.gridData;
                                    }
                                }
                            });
                            modalInstance.result.then(function (selectedItem) {
                                $scope.selected = selectedItem;
                            }, function () {
                                if (comment) {
                                }
                            });
                        }
                  //  }

                }],
                templateUrl: 'app/components/dashboardExecutive/dashboardDatatable/comment-table-tpl.html',
                link: function ($scope, element, attrs) {


                }

        }
        return directive;
    }
})