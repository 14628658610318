
define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.ghostHomeService',[])

        .factory("ghostConsoleFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', 
        function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {
        
        }]);
        return services;

    });

