define([
    'angular'
], function () {
    'use strict';

    var services = angular.module('app.PdfDataService', [])
                          .factory('PdfDdDataFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', PdfDdDataFactory])
                          .factory('PdfFbDataFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', PdfFbDataFactory])
                          .factory('PdfS3DataFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', PdfS3DataFactory])
                          .factory('PdfRdDataFactory', ['$q', '$http', '$rootScope', 'GlobalService', 'workspaceFactory', 'AlertService', '$injector',
                              'USER_SETTINGS', 'GENERAL_CONFIG', PdfRdDataFactory]);

    function PdfDdDataFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateDdData = function (_callName, _data) {
            var json_arrOBJ = [];
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=rlgql2';
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);
            params.process_name = 'UPDATE_PDF_DD_DATA';
            json_arrOBJ.push(_data.rowData);
            params = _.extend({
                sso_id: sso_id,
                clob_data: JSON.stringify(json_arrOBJ)
            }, params);

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        rrn: _data.rowData.RRN,
                        gridFilter: {
                            rrn: _data.rowData.RRN
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteDdData = function (data) {
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            // var jsonSettings = JSON.stringify(_data1);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                sso_id: sso_id,
                clob_data: jsonObj
            }, params);
            // params = _.extend({jsonSettings: jsonSettings}, params);
            params.process_name = "";

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=rqomik",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }


    function PdfFbDataFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateFbData = function (_callName, _data) {
            var json_arrOBJ = [];
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=0klmyf';
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);
            params.process_name = 'UPDATE_FORM_BUCKET_DATA';
            json_arrOBJ.push(_data.rowData);
            params = _.extend({
                sso_id: sso_id,
                clob_data: JSON.stringify(json_arrOBJ)
            }, params);

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        rrn: _data.rowData.RRN,
                        gridFilter: {
                            rrn: _data.rowData.RRN
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteFbData = function (data) {
            var jsonObj = JSON.stringify(data);
            // var jsonSettings = JSON.stringify(_data1);
            var params = {};
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                sso_id: sso_id,
                clob_data: jsonObj
            }, params);
            // params = _.extend({jsonSettings: jsonSettings}, params);
            params.process_name = "";

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0hcs6z",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }


    function PdfS3DataFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');
        factory.updateS3Data = function (_callName, _data) {
            var json_arrOBJ = [];
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=6odqlj';
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);
            params.process_name = 'UPDATE_S3_DATA';
            json_arrOBJ.push(_data.rowData);
            params = _.extend({
                sso_id: sso_id,
                clob_data: JSON.stringify(json_arrOBJ)
            }, params);

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        rrn: _data.rowData.RRN,
                        gridFilter: {
                            rrn: _data.rowData.RRN
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteS3Data = function (data) {
            var jsonObj = JSON.stringify(data);
            // var jsonSettings = JSON.stringify(_data1);
            var params = {};
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                sso_id: sso_id,
                clob_data: jsonObj
            }, params);
            // params = _.extend({jsonSettings: jsonSettings}, params);
            params.process_name = "";

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fh8yrw",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }


    function PdfRdDataFactory($q, $http, $rootScope, GlobalService, workspaceFactory, AlertService, $injector, USER_SETTINGS, GENERAL_CONFIG) {
        var factory = {};
        var sso_id = USER_SETTINGS.user.sso_id;
        var service = $injector.get('JsonObjectFactory');

        factory.updateRdData = function (_callName, _data) {
            var json_arrOBJ = [];
            var defer = $q.defer();
            var url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=f21qk2';
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var params = _.merge({}, GlobalService.globalParams, filterParams);
            params.process_name = 'UPDATE_REPEATED_DATA';
            json_arrOBJ.push(_data.rowData);
            params = _.extend({
                sso_id: sso_id,
                clob_data: JSON.stringify(json_arrOBJ)
            }, params);

            service.saveJSON(url, params).then(function (data) {
                if (data.callSuccess === '1') {
                    AlertService.add('success', 'Data saved Successfully.', 4000);
                    var args = {
                        rrn: _data.rowData.RRN,
                        gridFilter: {
                            rrn: _data.rowData.RRN
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    defer.resolve(data);
                } else {
                    AlertService.add('error', data.errorMessage);
                    defer.reject(data);
                }
            }, function (data) {
                defer.reject(data);
            });
            return defer.promise;
        };

        factory.deleteRdData = function (data) {
            var jsonObj = JSON.stringify(data);
            // var jsonSettings = JSON.stringify(_data1);
            var params = {};
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                sso_id: sso_id,
                clob_data: jsonObj
            }, params);
            // params = _.extend({jsonSettings: jsonSettings}, params);
            params.process_name = "";

            var promise = $http({
                method: "post",
                url: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=q09cxt",
                data: params
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        return factory;
    }

    return services;
});
