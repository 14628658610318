define([
    'angular'

], function () {
    'use strict';

    var services = angular.module('app.F4626NewService', [])
        .factory("F4626NewFactory", ['$q', '$http', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory',
            'workspaceFactory', 'AlertService', 'JsonObjectFactory', 'GENERAL_CONFIG',
            function ($q, $http, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory,
                workspaceFactory, AlertService, JsonObjectFactory, GENERAL_CONFIG) {

                var factory = {};
                factory.formConfigData = {};

                factory.fetch4626Details = function (rowData, partNo, scheduleName) {
                    var jcd_key = 0;
                    if (rowData.JCD_KEY == undefined) {
                        jcd_key = 250;
                    } else jcd_key = rowData.JCD_KEY;
                    var jcd_key = 250;
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = {
                        "action_code": 'q9i7om', "tax_year": filterParams.tax_year, "scenario": filterParams.scenario, "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ "combination_key": rowData.COMBINATION_KEY, "schedule_name": "N/A", "part_no": partNo,
                        "fg_key": filterParams.filing_key, "group_type": '', "form_name": "F4626", "section_name": scheduleName
                    };
                    return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

                        if (data.callSuccess && data.callSuccess.length > 0 && data.callSuccess !== '1') {
                            AlertService.add("error", data.errorMessage);
                            return false;
                        } else {
                            factory.formConfigData = data.jsonObject;

                            return data.jsonObject;
                        }
                    });
                }

                factory.saveForm4626 = function (final_data, clob_settings, partNo, scheduleName, rowData) {
                    console.log("Clob_setting was done as:::::::::------------", clob_settings);
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=yr77t9";

                    var jcd_key = 0;
                    if (rowData.JCD_KEY == undefined) {
                        jcd_key = 250;
                    } else jcd_key = rowData.JCD_KEY;
                
                    var jsonSettings = { "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO, "jcd_key": jcd_key };
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var jsonObj = JSON.stringify(final_data);
                    var json_Settings = [];
                    json_Settings.push(jsonSettings);
                    var jsonSettings = JSON.stringify(json_Settings);
                    console.log("Json Data::", jsonObj);

                    var params = filterParams;
                    params = _.merge({}, GlobalService.globalParams, params);
                    params = _.extend({ clob_data: jsonObj }, params);
                    params = _.extend({ clob_settings: jsonSettings }, params);
                    console.log("Params::::----------->>>>>>>>>", params);
                    params.process_name = "Form 4626 Save";
                    
                    params.part_no = partNo;
                    params.schedule_name = "N/A";
                    params.form_name = "F4626";
                    params.section_name = scheduleName;
                    params.sso_id = "400412387"

                    return JsonObjectFactory.saveJSON(url, params).then(function (response) {
                        var status = response.status;
                        return response;
                    });
                    return promise;
                }
                return factory;

            }]);

    return services;

});
