define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.PshipCodeMastServices',[])
        .factory("PshipCodeMastServicesFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService','AlertService','USER_SETTINGS','$rootScope','JsonObjectFactory','workspaceFactory', PshipCodeMastServicesFactory])
    function PshipCodeMastServicesFactory($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS,$rootScope,JsonObjectFactory,workspaceFactory) {

        let save_url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=cxka0b";

        PshipCodeMastServicesFactory.saveACodeMastRecord = function(_settings, _data) {

            let params = {};
            let jsonObj = JSON.stringify(_data);
            let jsonSettings =  JSON.stringify(_settings);
            params =  _.extend({clob_data:jsonObj}, params);
            params =  _.extend({clob_settings:jsonSettings}, params);

            params.process_name =  "Partnership Code Mast Save";
            params.sso_id = _settings.sso_id;

            console.log('params = ', params);

            let promise = $http({method: "post", url: save_url, data: params
            }).then(function (response) {
                let status = response.status;
                return response;
            });

            return promise;

        }

        return PshipCodeMastServicesFactory;

    }

    return services;
});