define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.section965Controller', [])
            .controller('Section965AdjsController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData', 'colData', 'JsonObjectFactory', 'Section965Factory', section965AdjsController])
            .controller('Section965FgController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData', 'colData', 'JsonObjectFactory', 'Section965Factory', section965FgController])
            .controller('Section965NovDecAdjsController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData', 'colData', 'JsonObjectFactory', 'Section965Factory', section965NovDecAdjsController])
            .controller('Section9651231AdjsController', ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData', 'colData', 'JsonObjectFactory', 'Section965Factory', section9651231AdjsController]);



    function section965AdjsController($rootScope, $scope, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, colData, JsonObjectFactory, Section965Factory) {
        var vm = this;
        vm.rowData = rowData;
        vm.colData = colData;
        
        vm.title = 'Section 965 Adjustments';
        vm.show965AdjsForm = false;
        vm.ADJ_AMT = 0;
        vm.COMMENTS = "";
        function init() {
            vm.show965AdjsForm = true;
        };
      
        init();

        vm.cancel = function() {
            //var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
            
            //var args = {};
            //$rootScope.$emit('gridUpdate', args);
            $uibModalInstance.dismiss('cancel');    
        };

        vm.save = function() {
            console.log('adj amt: ' + vm.ADJ_AMT);
            console.log('COMMENTS: ' + vm.COMMENTS);
              
                if ( vm.crudLoading) {
                    AlertService.add("info", "Please wait while we save this request", 4000);
                    return;
                }
                vm.crudLoading = true;
                var params =  {
                    ghost_scenario: vm.rowData.GHOST_SCENARIO, 
                    leid: vm.rowData.LEID, 
                    tax_code: vm.rowData.CDR_NO, 
                    filing_group: vm.rowData.FILING_GROUP,
                    adj_amt: vm.ADJ_AMT,
                    comments: vm.COMMENTS
                };
                Section965Factory.saveAmtsComments(params).then(function (result) {
                    if(result.callSuccess !== "1" ){
                        vm.crudLoading = false;
                        AlertService.add("error", result.errorMessage, 4000);
                    }else{
                        vm.crudLoading = false;
                        AlertService.add("success", "Adjustment Amounts and Comments have been saved " , 1000);
                        $uibModalInstance.dismiss('cancel');
                        var args = {leid:vm.rowData.LEID};
                        $rootScope.$emit('gridUpdate', args);
                    }
                });
        };

        vm.reset = function () {
            init();
        };
       
       
    }///////////////END CTRL
    
    function section965FgController($rootScope, $scope, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, colData, JsonObjectFactory, Section965Factory) {
        var vm = this;
        vm.rowData = rowData;
        vm.colData = colData;
        
        vm.title = 'Section 965 Filing Group';
        vm.show965FgForm = false;
        vm.filingGroups = {selected:null, list:[], disabled:true};
        
        function initFg() {
            console.log(vm.rowData);
            console.log(vm.colData);
            console.log('active screen section965FgController:' + Section965Factory.getActiveScreen());
            vm.show965FgForm = true;
            getAllFilingGroups();
        };
        
        initFg();
        
        function getAllFilingGroups() {
            var filingIndex = 0;
            var params = {
                scr_filing_group: vm.rowData.FILING_GROUP
            };

            Section965Factory.getFilingGroups(params).then(function(res) {
                
                vm.filingGroups.list = res;
                if(vm.rowData.NEW_FILING_GROUP) {
                    filingIndex = _.findIndex(vm.filingGroups.list, {'FILING_GROUP': vm.rowData.NEW_FILING_GROUP});
                    vm.filingGroups.selected = vm.filingGroups.list[filingIndex];
                } else {
                    vm.filingGroups.selected = vm.filingGroups.list[0];
                }
                              
               
            });
        };

        vm.cancel = function() {
            //var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
            
            //var args = {};
            //$rootScope.$emit('gridUpdate', args);
            $uibModalInstance.dismiss('cancel');    
        };

        vm.save = function() {
           
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            vm.crudLoading = true;
            var params =  {
                ghost_scenario: vm.rowData.GHOST_SCENARIO, 
                leid: vm.rowData.LEID, 
                tax_code: vm.rowData.CDR_NO, 
                filing_group: vm.rowData.FILING_GROUP,
                new_filing_group: vm.filingGroups.selected.FILING_GROUP
            };
            Section965Factory.saveFilingGroup(params).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    vm.crudLoading = false;
                    AlertService.add("success", "Filing Group has been updated " , 1000);
                    $uibModalInstance.dismiss('cancel');
                    var args = {leid:vm.rowData.LEID};
                    $rootScope.$emit('gridUpdate', args);
                }
            });
        };

        vm.reset = function () {
            initFg();
        };
       
       
    }

    function section965NovDecAdjsController($rootScope, $scope, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, colData, JsonObjectFactory, Section965Factory) {
        var vm = this;
        vm.rowData = rowData;
        vm.colData = colData;
        
        vm.title = "";
        vm.show965NovDecForm = false;
        vm.filingGroups = {selected:null, list:[], disabled:true};
        vm.ADJ_AMT = 0;
        vm.COMMENTS = "";
        vm.adjType = "";
        vm.USER_UPDATED = "";
        vm.DATE_UPDATED = "";
        function initNovDecAdjs() {
           
            if(vm.colData.map === "NOV_YE_11_2_ADJ_AMT") {
                vm.title = "Section 965 November Year End Adjustments";
                vm.adjType = "NOV";
                vm.ADJ_AMT = vm.rowData.NOV_YE_11_2_ADJ_AMT;
                vm.COMMENTS = vm.rowData.NOV_YE_ADJ_COMMENTS;
                vm.USER_UPDATED = vm.rowData.NOV_ADJ_USER_UPDATED;
                vm.DATE_UPDATED = vm.rowData.NOV_ADJ_DATE_UPDATED;
            } else {
                vm.title = "Section 965 December Year End Adjustments";
                vm.adjType = "DEC";
                vm.ADJ_AMT = vm.rowData.DEC_YE_11_2_ADJ_AMT;
                vm.COMMENTS = vm.rowData.DEC_YE_ADJ_COMMENTS;
                vm.USER_UPDATED = vm.rowData.DEC_ADJ_USER_UPDATED;
                vm.DATE_UPDATED = vm.rowData.DEC_ADJ_DATE_UPDATED;
            }

            vm.show965NovDecForm = true;
        };
        
        initNovDecAdjs();
        
       

        vm.cancel = function() {
            //var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
            
            //var args = {};
            //$rootScope.$emit('gridUpdate', args);
            $uibModalInstance.dismiss('cancel');    
        };

        vm.save = function() {
           
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            vm.crudLoading = true;
            var params =  {
                ghost_scenario: vm.rowData.GHOST_SCENARIO, 
                leid: vm.rowData.LEID, 
                tax_code: vm.rowData.CDR_NO, 
                filing_group: vm.rowData.FILING_GROUP,
                adj_type: vm.adjType,
                nov_adj_amt: (vm.adjType==='NOV' ? vm.ADJ_AMT : 0),
                nov_adj_comments: (vm.adjType==='NOV' ? vm.COMMENTS : ""),
                dec_adj_amt: (vm.adjType==='NOV' ? 0 : vm.ADJ_AMT),
                dec_adj_comments: (vm.adjType==='NOV' ? "" : vm.COMMENTS)
            };
                      
            Section965Factory.saveNovDecAdjs(params).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    vm.crudLoading = false;
                    AlertService.add("success", "Adjustments have been saved successfully " , 1000);
                    $uibModalInstance.dismiss('cancel');
                    var args = {leid:vm.rowData.LEID};
                    $rootScope.$emit('gridUpdate', args);
                }
            });
        };

        vm.reset = function () {
            initNovDecAdjs();
        };
       
       
    }

    function section9651231AdjsController($rootScope, $scope, $http, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, colData, JsonObjectFactory, Section965Factory) {
        var vm = this;
        vm.rowData = rowData;
        vm.colData = colData;
        
        vm.title = "Section 965 12/31 E&P Adjustments";
        vm.show965EPAdjsForm = false;
        vm.ADJ_AMT = 0;
        vm.COMMENTS = "";
        vm.adjType = "";
        vm.USER_UPDATED = "";
        vm.DATE_UPDATED = "";
        function init1231Adjs() {
            vm.ADJ_AMT = vm.rowData.TOTAL_EP_12_31_ADJ_AMT;
            vm.COMMENTS = vm.rowData.TOTAL_EP_12_31_COMMENTS;
            vm.USER_UPDATED = vm.rowData.EP_ADJ_USER_UPDATED;
            vm.DATE_UPDATED = vm.rowData.EP_ADJ_DATE_UPDATED;
            vm.show965EPAdjsForm = true;
        };
        
        init1231Adjs();
       
        vm.cancel = function() {
            //var args = {combination_keys: vm.rowData.COMBINATION_KEY, gridFilter: {COMBINATION_KEY: vm.rowData.COMBINATION_KEY}};
            
            //var args = {};
            //$rootScope.$emit('gridUpdate', args);
            $uibModalInstance.dismiss('cancel');    
        };

        vm.save = function() {
           
            if ( vm.crudLoading) {
                AlertService.add("info", "Please wait while we save this request", 4000);
                return;
            }
            vm.crudLoading = true;
            var params =  {
                ghost_scenario: vm.rowData.GHOST_SCENARIO, 
                leid: vm.rowData.LEID, 
                tax_code: vm.rowData.CDR_NO, 
                filing_group: vm.rowData.FILING_GROUP,
                ep_1231adj_amt: vm.ADJ_AMT,
                ep_1231adj_comments: vm.COMMENTS
            };
            console.log('params to save: ');
            console.log(params);
            Section965Factory.saveEP1231Adjs(params).then(function (result) {
                if(result.callSuccess !== "1" ){
                    vm.crudLoading = false;
                    AlertService.add("error", result.errorMessage, 4000);
                }else{
                    vm.crudLoading = false;
                    AlertService.add("success", "Adjustments have been saved successfully " , 1000);
                    $uibModalInstance.dismiss('cancel');
                    var args = {leid:vm.rowData.LEID};
                    $rootScope.$emit('gridUpdate', args);
                }
            });
        };

        vm.reset = function () {
            init1231Adjs();
        };
       
       
    }
    return controllers;


}); 