define([
    'angular'
], function () {
    'use strict';

	/* NOTE:  changed on 3/21/2019
			  Below two controllers functionalities are same, if we are using same modal in both places grid & workflow, we need to define the controllers like below
				scheduleICtrl		: this controller is used on workflow Step
				scheduleICtrlModal	: this controller is used on Grid Modal
	*/


    var controllers = angular.module('app.schedule-ICtrl', [])
            .controller('scheduleICtrl', ['GlobalService','$scope', 'JsonObjectFactory','$parse', 'scheduleIServiceFactory', 'workspaceFactory', '$state', '$window',
					   'AlertService', 'GENERAL_CONFIG', 'ModalFactory',scheduleICtrl])
			.controller('scheduleICtrlModal', ['GlobalService','$scope', 'JsonObjectFactory', '$parse','scheduleIServiceFactory', 'workspaceFactory', '$state', '$window',
			'AlertService', 'GENERAL_CONFIG','ModalFactory','$uibModalInstance', 'rowData', 'colData', 'gridData',scheduleICtrlModal]);


			function scheduleICtrl(GlobalService,$scope, JsonObjectFactory, $parse, scheduleIServiceFactory, workspaceFactory, $state, $window,
				AlertService, GENERAL_CONFIG, ModalFactory) {
					scheduleICtrlModal(GlobalService,$scope, JsonObjectFactory, $parse, scheduleIServiceFactory, workspaceFactory, $state, $window, AlertService, GENERAL_CONFIG,ModalFactory, null,
						null, null, null,this);
				}


            function scheduleICtrlModal(GlobalService,$scope, JsonObjectFactory,  $parse, scheduleIServiceFactory, workspaceFactory, $state, $window,
            		AlertService, GENERAL_CONFIG, ModalFactory, $uibModalInstance,
					rowData, colData, gridData,thisObj) {
				//var vm = this;
				var vm = this||thisObj;
				vm.rowData = rowData || $scope.$parent.rowData;
				$scope.irsF5471ScheduleIData = [];
                $scope.irsF5471ScheduleIOriginalData = [];
            	//console.log(vm.scheduleIDetails);
            	//vm.scheduleIDetails.value = "";
				vm.modalTitle = "Schedule I: Summary of Shareholder's Income From Foreign Corporation";
				$scope.crudLoading = false;
				vm.GHOSTAmt = []; /* added on 4/10/2019 */
				vm.loadFlag = false;

				if(angular.isObject($uibModalInstance)) {
                    vm.modal_name = ModalFactory.response.data? ModalFactory.response.data.modalScreen.modal_name:null;
                    vm.cancel = function () {
                        $uibModalInstance.close();
                        vm.modal_name = null;
                    };
                } else {
                    vm.modal_name = null;
                }

                /////////LOAD SHCEDULE I DETAILS FROM GENERIC PROC//////////////
				function loadShareholderIncome(){

					var dataCache = workspaceFactory.customDataCache[0];
					//console.log("dataCache-------------", dataCache);
					//console.log("$state.params-----------", $state.params);
					var id = $state.params.id;
					var entityData = angular.isDefined(_.find(dataCache.data, {code:id})) ? _.find(dataCache.data, {code:id}) : _.find(dataCache.data, {code:parseInt(id)});
					//console.log("entityData---------", entityData);

               	 	$scope.irsF5471ScheduleIData = [];
                    $scope.irsF5471ScheduleIOriginalData = [];
               	 var params = {
		        			"action_code": 'pru0jb',
		        			"irs_form_no":'F5471',
		        			"tax_year" : GlobalService.globalParams.tax_year,//2018,
		        			"scenario" :GlobalService.globalParams.scenario,//28,
	            			"combination_key": vm.rowData.HO_COMBINATION_KEY,//vm.rowData.COMBINATION_KEY,
	            			"jcd_key" : GlobalService.globalParams.jcd_key === '' ? 250 :  GlobalService.globalParams.jcd_key
		        };
		        	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
		        		if (data.callSuccess === "1") {
		        			console.log("data.jsonObject---------------------", data.jsonObject);
		        			var irsF5471ScheduleIDataTemp = data.jsonObject;
		        			var i = 1;

		        			var previous_line_no = irsF5471ScheduleIDataTemp[i].line_no;
		        			//console.log("irsF5471ScheduleIDataTemp[j]----------------", irsF5471ScheduleIDataTemp[j]);
		        			while( i < irsF5471ScheduleIDataTemp.length  )
		        			{
		        				var tempObj = {};
		        				var j = i;


		        				while(j <irsF5471ScheduleIDataTemp.length && previous_line_no == irsF5471ScheduleIDataTemp[j].line_no)
		        				{
		        					if(irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[0] == "LINE_NO")
		        					{
		        						if( parseInt(GlobalService.globalParams.tax_year) < 2018 && irsF5471ScheduleIDataTemp[j].line_no == '6' )
		        						{
		        							tempObj.backgroudColor = {
		        								"background": "#D6D6D6",
		        								"font-weight": "bold"
		        							 }
		        					     }
		        						else
		        							tempObj.backgroudColor = {};

		        						tempObj.line_no = irsF5471ScheduleIDataTemp[j].line_no;

		        						tempObj.saveParams = {
		        								"tax_year":irsF5471ScheduleIDataTemp[j].object_id.split("~")[0],
		        								"trans_type_key" : irsF5471ScheduleIDataTemp[j].object_id.split("~")[1],
		        								"trans_Details_key" : irsF5471ScheduleIDataTemp[j].object_id.split("~")[3],
		        								"occurrence" : irsF5471ScheduleIDataTemp[j].object_id.split("~")[4],
		        								"form_key" : irsF5471ScheduleIDataTemp[j].object_id.split("~")[6]
		        						}
		        					}
		        					else if(irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[0] == "LINE_DESCRIPTION")
		        					{
		        						tempObj.line_Description = irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[3];
		        					}
		        					else if(irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[0] == "FIR_OVERRIDE_AMT" )
		        					{
		        						tempObj.FIR_OVERRIDE_VALUE = {
		        								"VALUE": angular.isDefined(irsF5471ScheduleIDataTemp[j].VALUE) ? irsF5471ScheduleIDataTemp[j].VALUE : null,
		        								    "line_attrib_key":irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[1],
		        								    "attrib_name":irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[0],
		        								    "is_editable": (irsF5471ScheduleIDataTemp[j].PROPERTY_TYPE == '9' ? true: false),
			        								"is_Display" : (irsF5471ScheduleIDataTemp[j].PROPERTY_TYPE == '0' ? false: true),
			        								"column_style" : (irsF5471ScheduleIDataTemp[j].PROPERTY_TYPE == '0' ? {"background-color": '#D6D6D6', 'width': '250px'} : {})
		        						}

		        					}
		        					else if(irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[0] == "GHOST_AMT")
		        					{
		        						tempObj.GHOST_AMT = {
		        											 "VALUE": angular.isDefined(irsF5471ScheduleIDataTemp[j].VALUE) ? parseInt(irsF5471ScheduleIDataTemp[j].VALUE) : null,
		        								             "line_attrib_key":irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[1],
		        								             "attrib_name":irsF5471ScheduleIDataTemp[j].attrib_value.split("~")[0],
		        								             "is_editable": (irsF5471ScheduleIDataTemp[j].PROPERTY_TYPE == '9' ? true: false),
		 			        								"is_Display" : (irsF5471ScheduleIDataTemp[j].PROPERTY_TYPE == '0' && parseInt(GlobalService.globalParams.tax_year) >=  2018  ? false: true),
		 			        								"column_style" : (irsF5471ScheduleIDataTemp[j].PROPERTY_TYPE == '0' ? {"background-color": '#D6D6D6', 'width': '200px'} : {})
		        								            }
		        					}
		        				 previous_line_no = irsF5471ScheduleIDataTemp[j].line_no;
		        					j++;
		        				 }
		        				if(j < irsF5471ScheduleIDataTemp.length)
		        					previous_line_no = irsF5471ScheduleIDataTemp[j].line_no;
		        				var tempIndex = $scope.irsF5471ScheduleIData.length;
		        				$scope.irsF5471ScheduleIData[tempIndex] = tempObj;

	                			i = j;
		        			}

		        			console.log("final $scope.irsF5471ScheduleIData--------------", $scope.irsF5471ScheduleIData);

		        			getGHOSTAmt();
		        		}
		        		else
		        		{
		        			if (data.errorMessage === "no access") {
								AlertService.add("","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
		        		}

		        	});
               }

			   loadShareholderIncome();

			   /* save from workflow 5/14/2019 */
			   $scope.$on('workflowSave',function(event,data){
					vm.save();
			   })
//////////////////////////////////////////FUNCTION TO SAVE DETAILS/////////////////////////////////////////////
                var updateOverrideFlag = [];//WILL STORE DETAILS OF ATTRIBUTES< WHICH G+GHOST FLAG NEEDS TO BE UPDATED
                vm.save = function()
                {
                	if ($scope.crudLoading) {
		                AlertService.add("info", "Please wait while we save this request", 4000);
			                return;
			        }

                	$scope.crudLoading = true;
            	   updateOverrideFlag = [];
                	var editRow = [];
                	var addRow = [];
                	var deleteRow = [];

                	var scheduleSettings = [{"tax_year":GlobalService.globalParams.tax_year,"scenario":GlobalService.globalParams.scenario,"jcd_key":GlobalService.globalParams.jcd_key === '' || GlobalService.globalParams.jcd_key === undefined ? 250 :  GlobalService.globalParams.jcd_key}];
                	for(var i = 0; i< $scope.irsF5471ScheduleIData.length; i++)
                	{
                		if($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.is_Display)
                		{
		                	var returnObj1 = [{
									"tax_year":GlobalService.globalParams.tax_year,//2018
									"trans_type_key":$scope.irsF5471ScheduleIData[i].saveParams.trans_type_key,
									"combination_key":vm.rowData.HO_COMBINATION_KEY,
									"group_obj_key": vm.rowData.GROUP_OBJ_KEY,//2018134,
									"form_key":$scope.irsF5471ScheduleIData[i].saveParams.form_key,
									"line_no":$scope.irsF5471ScheduleIData[i].line_no,
									"occurence":(($scope.irsF5471ScheduleIData[i].saveParams.occurrence == "0") ? null : $scope.irsF5471ScheduleIData[i].saveParams.occurrence),
									"line_attrib_key" : $scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.line_attrib_key,
									"Attribute_Name":$scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.attrib_name,
									"Attribute_Value": ($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE != null ? $scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE : 0) ,
									"trans_dtls_key" : (($scope.irsF5471ScheduleIData[i].saveParams.trans_Details_key == "0") ? null : $scope.irsF5471ScheduleIData[i].saveParams.trans_Details_key),
									"trans_status":"A"}];

		                	editRow.push(returnObj1);
                		}
                	}
                	////checking for overridden GHOST or restored GHOST Amounts to update override flag
                	for(var i = 0; i< vm.GHOSTAmt.length; i++)
                	{
                		var lineObj = _.find($scope.irsF5471ScheduleIData, {line_no:vm.GHOSTAmt[i].LINE_NO} );

                		if( angular.isDefined(lineObj) && (lineObj.FIR_OVERRIDE_VALUE.VALUE != vm.GHOSTAmt[i].ADJ_AMT &&  vm.GHOSTAmt[i].OVERRIDE_Y_N != 'Y' && vm.GHOSTAmt[i].TRANS_DETAILS_KEY != null)  || (lineObj.FIR_OVERRIDE_VALUE.VALUE == vm.GHOSTAmt[i].ADJ_AMT &&  vm.GHOSTAmt[i].OVERRIDE_Y_N != 'N' && vm.GHOSTAmt[i].TRANS_DETAILS_KEY != null) )
                		{
                			var tempFlagObj = [{
            					"trans_header_key":vm.GHOSTAmt[i].TRANS_HEADER_KEY,
    							"trans_details_key":vm.GHOSTAmt[i].TRANS_DETAILS_KEY,
    							"trans_type_key":vm.GHOSTAmt[i].TRANS_TYPE_KEY,
    							"line_attrib_key":vm.GHOSTAmt[i].LINE_ATTRIB_KEY,
    							"form_key":vm.GHOSTAmt[i].FORM_KEY,
    							"OVERRIDE_Y_N":(vm.GHOSTAmt[i].OVERRIDE_Y_N =='Y'? 'N' : 'Y')
    						}];
    						updateOverrideFlag.push(tempFlagObj);
                		}
                	}



					var message = "Schedule I details are sucessfully saved/edited";
					var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
					console.log("scheduleDetails----------------",scheduleDetails);
					console.log("updateOverrideFlag--------------------", updateOverrideFlag);
					//vm.rowData = rowData;
					sendDetails(scheduleSettings,scheduleDetails,message);
                }


//FUNCTION TO GET GHOST AMT///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                function getGHOSTAmt()
                {
            		 vm.GHOSTAmt = [];
                	//console.log("GlobalService.globalParams-------------------", GlobalService.globalParams);

                	var dataCache = workspaceFactory.customDataCache[0];
					//console.log("dataCache-------------", dataCache);
					//console.log("$state.params-----------", $state.params);
					var id = $state.params.id;
					var entityData = angular.isDefined(_.find(dataCache.data, {code:id})) ? _.find(dataCache.data, {code:id}) : _.find(dataCache.data, {code:parseInt(id)});


                	var params = {
                			"tax_year":GlobalService.globalParams.tax_year,//2018
                			"combination_key":vm.rowData.HO_COMBINATION_KEY,
                			"scenario":GlobalService.globalParams.scenario,//28
                			"jcd_key":250
                	}
                	return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=odx6u9",params).then(function(data) {
                		//console.log("Response Data x ::", data.jsonObject);
                		if (data.callSuccess === "1") {
                			//console.log('resopnse_Data for GHOST Amt------------', data.jsonObject);
                			var responseData = data.jsonObject;
                			//console.log("responseData---------", responseData);
	                		for(var j=0; j < responseData.length; j++)
	                		{
	                				vm.GHOSTAmt[j] = {
	                						"LINE_NO":responseData[j].LINE_NO,
	                						"ADJ_AMT" : responseData[j].ADJ_AMT,
	            							"LINE_ATTRIB_KEY" : responseData[j].LINE_ATTRIB_KEY,
	            							"FORM_KEY" : responseData[j].FORM_KEY,
	            							"OVERRIDE_Y_N" : (angular.isDefined(responseData[j].OVERRIDE_Y_N) ? responseData[j].OVERRIDE_Y_N : null ),
	            							"TRANS_DETAILS_KEY" : (angular.isDefined(responseData[j].TRANS_DETAILS_KEY) ? responseData[j].TRANS_DETAILS_KEY : null ),
	            							"TRANS_HEADER_KEY"	: (angular.isDefined(responseData[j].TRANS_HEADER_KEY) ? responseData[j].TRANS_HEADER_KEY : null ),
	            							"TRANS_TYPE_KEY"	: (responseData[j].TRANS_TYPE_KEY != null ? responseData[j].TRANS_TYPE_KEY : 36 )
	                				}
	                		}

	                		console.log("vm.GHOSTAmt---------------", vm.GHOSTAmt);


	                		vm.loadFlag = true;
	                		vm.restoreGHOSTAmt();

                		}
                		else {
							if (data.errorMessage === "no access") {
								AlertService.add("error","Sorry you do not have access to do the requested action.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							} else {
								AlertService.add(
												"error",
												"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
												4000);
								vm.userMessage = "!! Unable to perform Selected Action...";
							}
						}

                	});
                }


//TO RESTORE GHOST AMT//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                vm.restoreGHOSTAmt = function()
                {
                	console.log("inrestoreGhostAmt----------");
                	if(vm.loadFlag)
                	{
                		for(var i = 0; i<$scope.irsF5471ScheduleIData.length; i++)
                		{
                			//console.log("in forn loop ----- $scope.irsF5471ScheduleIData[i]-------------", $scope.irsF5471ScheduleIData[i]);
                			var tempGHOSTObj = _.find(vm.GHOSTAmt,{LINE_NO:$scope.irsF5471ScheduleIData[i].line_no});
                			//console.log("tempGHOSTObj-----------------", tempGHOSTObj);
                			if(angular.isDefined(tempGHOSTObj) && tempGHOSTObj.OVERRIDE_Y_N == 'N')
                			{
                				$scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE = tempGHOSTObj.ADJ_AMT;
                			}
                			if(angular.isDefined(tempGHOSTObj))
                			{
                				//console.log("in if");
                				$scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE = tempGHOSTObj.ADJ_AMT;
                			}
                		}
                		vm.computeTotal('a');
                		angular.copy($scope.irsF5471ScheduleIData, $scope.irsF5471ScheduleIOriginalData);

                		vm.loadFlag = false;
                	}
                	else
                	{
                		vm.saveFlag = false;
                		console.log("in else----");
                		for(var i = 0; i<$scope.irsF5471ScheduleIData.length; i++)
                		{
                			var tempGHOSTObj = _.find(vm.GHOSTAmt,{LINE_NO:$scope.irsF5471ScheduleIData[i].line_no});

                			if(angular.isDefined(tempGHOSTObj) )
                			{
                				$scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE = tempGHOSTObj.ADJ_AMT;
                			}
                		}
                		console.log("$scope.irsF5471ScheduleIData-------------in restore ghost amt", $scope.irsF5471ScheduleIData);
                		vm.computeTotal('b');
                	}
                }

//TO GET TOTAL FOR LINE-6/--for Tax_year < 2019 (because, there are no total in 2018)////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				vm.computeTotal = function(col_name){
					var total = null;
					var index= 0;
					if(col_name == 'b')
					{
						var i = 0;
						while( $scope.irsF5471ScheduleIData[i].line_no != '6'  )
						{
							if( angular.isDefined($scope.irsF5471ScheduleIData[i]) && angular.isDefined($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE) &&  angular.isDefined($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE) && $scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE && $scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE != null)
							{
								if(total != null)
										total = (parseInt(total) + parseInt($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE)) .toString();
									else
										total = $scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE.toString();
							}
							i++;
						}
						if(i == 5)
						{
							if(angular.isDefined($scope.irsF5471ScheduleIData[i]) && angular.isDefined($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE) &&  angular.isDefined($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE) )
							{
								if(total != null)
									$scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE = parseInt(total);
								else
									$scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE = null ;
							}
						}
					}
					else
					{
						var i = 0;
						while( $scope.irsF5471ScheduleIData[i].line_no != '6'  )
						{
							if( angular.isDefined($scope.irsF5471ScheduleIData[i]) && angular.isDefined($scope.irsF5471ScheduleIData[i].GHOST_AMT) &&  angular.isDefined($scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE) && $scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE && $scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE != null)
							{
								if(total != null)
										total = (parseInt(total) + parseInt($scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE)) .toString();
									else
										total = $scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE.toString();
							}
							i++;
						}
						if(i == 5)
						{
							if(angular.isDefined($scope.irsF5471ScheduleIData[i]) && angular.isDefined($scope.irsF5471ScheduleIData[i].GHOST_AMT) &&  angular.isDefined($scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE) )
							{
								if(total != null)
									$scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE = parseInt(total);
								else
									$scope.irsF5471ScheduleIData[i].GHOST_AMT.VALUE = null ;
							}
						}
					}
 				}


//TO SEND DETAILS TO SERVICE TO SAVE///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
               function sendDetails(scheduleSettings, scheduleDetails, message) {
            	   scheduleIServiceFactory.saveScheduleI(scheduleSettings,
							scheduleDetails).then(
							function(result) {

								if (result.errorMessage
										&& result.errorMessage !== 'null') {
									AlertService.add("error",
											result.errorMessage, 4000);
								} else {
									AlertService.add("success", message, 4000);
									vm.saveFlag = true;
									if(updateOverrideFlag.length > 0)
									{
										var jsonSettings = [{"combination_key":vm.rowData.HO_COMBINATION_KEY, "tax_year":GlobalService.globalParams.tax_year}];
										var jsonDetails = updateOverrideFlag;
										console.log("json-------", jsonDetails);
										scheduleIServiceFactory.updateFlag(jsonSettings,
												jsonDetails).then(
												function(result) {

													if (result.errorMessage
															&& result.errorMessage !== 'null') {
														$scope.crudLoading = false;
														//AlertService.add("error",
																//result.errorMessage, 4000);
													} else {
														//AlertService.add("success", message, 4000);
														$scope.crudLoading = false;
													}
												});
									}
									$scope.crudLoading = false;
									loadShareholderIncome();


								}
							});

				}

//CANCEL//////////////////////////////////////////////////////////////////////////////////////////////////////////////
               vm.cancel = function () {
            	   if(vm.saveFlag)
	                {
	                	for(var i = 0; i < $scope.irsF5471ScheduleIData; i++ )
	                	{
	                		if( ($scope.irsF5471ScheduleIData.length > 0 &&  $scope.irsF5471ScheduleIOriginalData.length > 0) && (angular.isDefined($scope.irsF5471ScheduleIData[i]) && angular.isDefined($scope.irsF5471ScheduleIOriginalData[i]) ) && angular.isDefined($scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE) &&  angular.isDefined( $scope.irsF5471ScheduleIOriginalData[i].FIR_OVERRIDE_VALUE.VALUE)  )
	                		{
	                			if( $scope.irsF5471ScheduleIData[i].FIR_OVERRIDE_VALUE.VALUE != $scope.irsF5471ScheduleIOriginalData[i].FIR_OVERRIDE_VALUE.VALUE )
	                			{
	                				vm.saveFlag = false;
                    				break;
	                			}
	                		}
	                	}
               	}
               	if(vm.saveFlag)
               	{
	                	//var args = {combination_keys: vm.rowData.combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.combination_key}};
	                    //$rootScope.$emit('gridUpdate', args);
	                    $uibModalInstance.dismiss('cancel');
               	}
               	else
            	{
            		var templateUrl = 'app/templates/modals/confirm-action.html';
            		$scope.confirmModal = $uibModal.open({
                        templateUrl: templateUrl,
                        scope: $scope,
                        windowClass: 'custom',
                        controller: ['$scope', '$stateParams', '$uibModalInstance',
                            function ($scope, $stateParams, $uibModalInstance) {
                        	$scope.header = "Confirm";
                        	$scope.message = "Changes has been made in the screen below. Do you want to continue without saving data?"
                                $scope.confirm = function () {
                                	$scope.closeWindow(true);
                                	 $uibModalInstance.close();

                                }
                                $scope.cancel = function () {
                                	$scope.closeWindow(false);
                                   $uibModalInstance.close();
                                }

                                $scope.$on('$destroy', function () {
                                    ////////////console.log("ngReally Destroyed");
                                });

                            }
                        ]
                    });
            		$scope.closeWindow = function(flag)
            		{
            			if(flag)
            			{
            				var args = {combination_keys: vm.rowData.HO_combination_key, gridFilter: {HO_COMBINATION_KEY: vm.rowData.HO_combination_key}};
    	                    $rootScope.$emit('gridUpdate', args);
    	                    $uibModalInstance.dismiss('cancel');
            			}

            		}

            	}
                  // $uibModalInstance.dismiss('cancel');
               };

//TO RESET THE FORM AS BEFORE SAVED//////////////////////////////////////////////////////////////////////////////////////////////////////
               vm.reset = function () {
            	   $scope.crudLoading = false;
            	   vm.saveFlag = true;
            	   angular.copy($scope.irsF5471ScheduleIOriginalData, $scope.irsF5471ScheduleIData);
               }

            }

});

