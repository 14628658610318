define([
	'angular'
], function () {
	'use strict';

	/* NOTE:  changed on 3/21/2019
			  Below two controllers functionalities are same, if we are using same modal in both places grid & workflow, we need to define the controllers like below
				scheduleJCtrl		: this controller is used on workflow Step
				scheduleJCtrlModal	: this controller is used on Grid Modal
	*/
	var controllers = angular.module('app.schedule-JCtrl', [])
		.controller('scheduleJCtrl', [ 'GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope', '$scope',
			'scheduleJGroupObjFactory', 'GENERAL_CONFIG', scheduleJCtrl
		])

		.controller('scheduleJCtrlModal', [ 'GlobalService', 'AlertService', 'JsonObjectFactory', 'ModalFactory', '$parse', '$rootScope', '$scope',
			'scheduleJGroupObjFactory', 'GENERAL_CONFIG', '$uibModalInstance', '$uibModal', 'rowData', 'colData', 'gridData', scheduleJCtrlModal
		])

		.filter('split', function () {
			return function (input, splitChar, splitIndex) {
				// do some bounds checking here to ensure it has that index
				return input.split(splitChar)[splitIndex];
			}
		});

	function scheduleJCtrl( GlobalService, AlertService, JsonObjectFactory, ModalFactory, $parse, $rootScope, $scope, scheduleJGroupObjFactory, GENERAL_CONFIG) {
		scheduleJCtrlModal(GlobalService, AlertService, JsonObjectFactory, ModalFactory, $parse, $rootScope, $scope, scheduleJGroupObjFactory, GENERAL_CONFIG, null, null, null, null, null, this);
	}


	function scheduleJCtrlModal( GlobalService, AlertService, JsonObjectFactory, ModalFactory, $parse, $rootScope, $scope, scheduleJGroupObjFactory, GENERAL_CONFIG, $uibModalInstance, $uibModal,
		rowData, colData, gridData, thisObj) {
		var vm = this || thisObj;
		vm.rowData = rowData || $scope.$parent.rowData;
		$scope.irsF5471ScheduleJData = [];
		$scope.irsF5471ScheduleJOriginalData = [];
		$scope.columnHeader = [];
		$scope.columnSubHeader = [];
		vm.form_header = null;
		vm.table_header = null;
		vm.GHOSTAmt = [];

		$scope.crudLoading = false;

		vm.col_total_line_arr = [];

		$scope.partHeader = [];
		if (angular.isObject($uibModalInstance)) {
			vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;
			vm.cancel = function () {
				var args = {
					combination_keys: vm.rowData.HO_combination_key,
					gridFilter: {
						HO_COMBINATION_KEY: vm.rowData.HO_combination_key
					}
				};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.close();
				vm.modal_name = null;
			};
		} else {
			vm.modal_name = null;
		}


		if (parseInt(GlobalService.globalParams.tax_year) < 2018) {
			vm.partFlag = false;
			vm.col_total_line_arr = [{
					"line_no": '3',
					"line_no_for_total": [{
						"line_no": "1",
						"multiplier": 1
					}, {
						"line_no": "2A",
						"multiplier": 1
					}, {
						"line_no": "2B",
						"multiplier": -1
					}]
				},
				{
					"line_no": '6A',
					"line_no_for_total": [{
						"line_no": "1",
						"multiplier": 1
					}, {
						"line_no": "4",
						"multiplier": 1
					}, {
						"line_no": "5A",
						"multiplier": -1
					}],
					"copy_total_line_no": '7'
				},
				{
					"line_no": '6B',
					"line_no_for_total": [{
						"line_no": "3",
						"multiplier": 1
					}, {
						"line_no": "4",
						"multiplier": -1
					}, {
						"line_no": "5B",
						"multiplier": -1
					}],
					"copy_total_line_no": '7'
				},
			];
		} else {
			vm.partFlag = true;
			vm.col_total_line_arr = [{
					"line_no": 'I-1C',
					"line_no_for_total": [{
						"line_no": "I-1A",
						"multiplier": 1
					}, {
						"line_no": "I-1B",
						"multiplier": 1
					}]
				},
				{
					"line_no": 'I-7',
					"line_no_for_total": [{
							"line_no": "I-1C",
							"multiplier": 1
						},
						{
							"line_no": "I-2A",
							"multiplier": 1
						},
						{
							"line_no": "I-2B",
							"multiplier": 1
						},
						{
							"line_no": "I-3",
							"multiplier": 1
						},
						{
							"line_no": "I-4",
							"multiplier": 1
						},
						{
							"line_no": "I-5A",
							"multiplier": 1
						},
						{
							"line_no": "I-5B",
							"multiplier": 1
						},
						{
							"line_no": "I-6",
							"multiplier": 1
						}
					]
				},
				{
					"line_no": 'I-14',
					"line_no_for_total": [{
							"line_no": "I-7",
							"multiplier": 1
						},
						{
							"line_no": "I-8",
							"multiplier": 1
						},
						{
							"line_no": "I-9",
							"multiplier": 1
						},
						{
							"line_no": "I-10",
							"multiplier": 1
						},
						{
							"line_no": "I-11",
							"multiplier": 1
						},
						{
							"line_no": "I-12",
							"multiplier": 1
						},
						{
							"line_no": "I-13",
							"multiplier": 1
						}
					]
				},
			];
		}

		vm.chnageColor = {
			"color": "red"
		};

		
		vm.loadFlag = false;
		vm.saveFlag = true;
		vm.modalTitle = "Schedule J: Accumulated Earnings and Profits (E&P) of Controlled Foreign Corporation";
//Cancel////////////////////////////
		vm.cancel = function () {
			if (vm.saveFlag) {
				for (var i = 0; i < $scope.irsF5471ScheduleJData.length; i++) {
					if (($scope.irsF5471ScheduleJData.length > 0 && $scope.irsF5471ScheduleJOriginalData.length > 0) && (angular.isDefined($scope.irsF5471ScheduleJData[i]) && angular.isDefined($scope.irsF5471ScheduleJOriginalData[i]))) {
						if (!angular.equals($scope.irsF5471ScheduleJOriginalData[i].column_description, $scope.irsF5471ScheduleJData[i].column_description)) {
							vm.saveFlag = false;
							break;
						}
					}
				}
			}
			if (vm.saveFlag) {
				var args = {
					combination_keys: vm.rowData.HO_combination_key,
					gridFilter: {
						HO_COMBINATION_KEY: vm.rowData.HO_combination_key
					}
				};
				$rootScope.$emit('gridUpdate', args);
				$uibModalInstance.dismiss('cancel');
			} else {
				var templateUrl = 'app/templates/modals/confirm-action.html';
				$scope.confirmModal = $uibModal.open({
					templateUrl: templateUrl,
					scope: $scope,
					windowClass: 'custom',
					controller: ['$scope', '$stateParams', '$uibModalInstance',
						function ($scope, $stateParams, $uibModalInstance) {
							$scope.header = "Confirm";
							$scope.message = "Changes has been made in the screen below. Do you want to continue without saving data?"
							$scope.confirm = function () {
								$scope.closeWindow(true);
								$uibModalInstance.close();

							}
							$scope.cancel = function () {
								$scope.closeWindow(false);
								$uibModalInstance.close();
							}

							$scope.$on('$destroy', function () {
								////////////console.log("ngReally Destroyed");
							});

						}
					]
				});
				$scope.closeWindow = function (flag) {
					if (flag) {
						var args = {
							combination_keys: vm.rowData.HO_combination_key,
							gridFilter: {
								HO_COMBINATION_KEY: vm.rowData.HO_combination_key
							}
						};
						$rootScope.$emit('gridUpdate', args);
						$uibModalInstance.dismiss('cancel');
					}

				}

			}
		};

//RESET//////////////////////////////////////////
		vm.reset = function () {
			$scope.crudLoading = false;
			vm.saveFlag = true;
			angular.copy($scope.irsF5471ScheduleJOriginalData, $scope.irsF5471ScheduleJData);
			for (var i = 0; i < $scope.irsF5471ScheduleJData.length; i++) {
				if (_.find(vm.col_total_line_arr, {
						line_no: $scope.irsF5471ScheduleJData[i].line_no
					}) == undefined && $scope.irsF5471ScheduleJData[i].line_no.split("-")[0] != 'II')
					vm.computeRowTotal($scope.irsF5471ScheduleJData[i].line_no);
			}
			vm.getColTotal('II');
		}


////LOAD FUNCTION////////////////////////
		function loadScheduleJData() {
			$scope.irsF5471ScheduleJData = [];
			$scope.irsF5471ScheduleJOriginalData = [];
			$scope.columnHeader = [];
			$scope.columnSubHeader = [];
			vm.form_header = null;
			vm.table_header = null;
			vm.GHOSTAmt = [];
			$scope.partHeader = [];
			var params = {
				"action_code": 'dlxcms',
				"irs_form_no": 'F5471',
				"combination_key": vm.rowData.HO_COMBINATION_KEY, //vm.rowData.COMBINATION_KEY,
				"jcd_key": 250
			};
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
				if (data.callSuccess === "1") {
					console.log("data.jsonObject---------------------", data.jsonObject);
					var irsF5471ScheduleJDataTemp = data.jsonObject;
					var i = 1;

					var previous_line_no = irsF5471ScheduleJDataTemp[i].line_no;
					var line_no_arr = [];
					//console.log("irsF5471ScheduleIDataTemp[j]----------------", irsF5471ScheduleIDataTemp[j])
					vm.headerFlag = true;
					while (i < irsF5471ScheduleJDataTemp.length) {
						var tempObj = {};
						var j = i;

						var index = 0;
						tempObj.column_description = [];
						while (j < irsF5471ScheduleJDataTemp.length && previous_line_no == irsF5471ScheduleJDataTemp[j].line_no) {
							if (irsF5471ScheduleJDataTemp[j].object_id.split("~")[5] == 'FH')
								vm.form_header = irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3];
							else if (irsF5471ScheduleJDataTemp[j].object_id.split("~")[5] == 'TH')
								vm.table_header = irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3];
							else if (irsF5471ScheduleJDataTemp[j].object_id.split("~")[5] == 'CH') {
								if (irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[0] != 'PREVIOUSLY_TAXED_E_AND_P_HEADER') {
									var tempHeaderObj = {
										"row_span_value": 2,
										"header_value": irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3]
									};
								} else {
									var tempHeaderObj = {
										"col_span_value": (parseInt(GlobalService.globalParams.tax_year) < 2018) ? 3 : 9,
										"header_value": irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3]
									};
								}
								$scope.columnHeader.push(tempHeaderObj);
							} else if (irsF5471ScheduleJDataTemp[j].object_id.split("~")[5] == 'PH')
								$scope.partHeader.push(irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3]);
							else if (irsF5471ScheduleJDataTemp[j].object_id.split("~")[5] == 'CSH')
								$scope.columnSubHeader.push(irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3]);
							else {
								if (irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[0] == "LINE_NO") {
									tempObj.line_no = irsF5471ScheduleJDataTemp[j].line_no;
									if (irsF5471ScheduleJDataTemp[j].line_no.split("-").length > 1)
										tempObj.part_no = irsF5471ScheduleJDataTemp[j].line_no.split("-")[0];

									tempObj.saveParams = {
										"tax_year": irsF5471ScheduleJDataTemp[j].object_id.split("~")[0],
										"trans_type_key": irsF5471ScheduleJDataTemp[j].object_id.split("~")[1],
										"trans_Details_key": irsF5471ScheduleJDataTemp[j].object_id.split("~")[3],
										"occurrence": irsF5471ScheduleJDataTemp[j].object_id.split("~")[4],
										"form_key": irsF5471ScheduleJDataTemp[j].object_id.split("~")[6]
									}
								} else if (irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[0] == "LINE_DESCRIPTION") {
									tempObj.line_Description = irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3];
									//if(irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3] == 'Current year E&P' || irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[3] == 'Current year deficit in E&P')
									//	line_no_arr.push(irsF5471ScheduleJDataTemp[j].line_no);

								} else {
									if (irsF5471ScheduleJDataTemp[j].PROPERTY_TYPE == '9' || irsF5471ScheduleJDataTemp[j].PROPERTY_TYPE == '8') {
										var style = {};
										//if(irsF5471ScheduleJDataTemp[j].PROPERTY_TYPE == '8' && line_no_arr.indexOf(irsF5471ScheduleJDataTemp[j].line_no) != -1 )
										style = {
											'width': '150px'
										};
										//else
										//style = {'font-weight': 'bold', 'width': '150px'};

										tempObj.column_description[index] = {
											"VALUE": angular.isDefined(irsF5471ScheduleJDataTemp[j].VALUE) ? irsF5471ScheduleJDataTemp[j].VALUE : null,
											"line_attrib_key": irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[1],
											"attrib_name": irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[0],
											"is_editable": (irsF5471ScheduleJDataTemp[j].PROPERTY_TYPE == '9' ? true : false),
											"attrib_order":parseInt(irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[4]),
											"trans_details_key":irsF5471ScheduleJDataTemp[j].object_id.split("~")[3],
											"occurrence": irsF5471ScheduleJDataTemp[j].object_id.split("~")[4],
											"is_Display": true,
											"column_style": style
										}

									} else if (irsF5471ScheduleJDataTemp[j].PROPERTY_TYPE == '0') {
										var style = {
											"background-color": '#D6D6D6',
											'width': '150px'
										};
										tempObj.column_description[index] = {
											"VALUE": null,
											"line_attrib_key": irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[1],
											"attrib_name": irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[0],
											"attrib_order":parseInt(irsF5471ScheduleJDataTemp[j].attrib_value.split("~")[4]),
											"trans_details_key":irsF5471ScheduleJDataTemp[j].object_id.split("~")[3],
											"occurrence": irsF5471ScheduleJDataTemp[j].object_id.split("~")[4],
											"is_editable": false,
											"is_Display": false,
											"column_style": style
										}
									}
									index++;
								}
							}
							previous_line_no = irsF5471ScheduleJDataTemp[j].line_no;
							j++;
						}
						if (j < irsF5471ScheduleJDataTemp.length)
							previous_line_no = irsF5471ScheduleJDataTemp[j].line_no;
						var tempIndex = $scope.irsF5471ScheduleJData.length;
						if (angular.isDefined(tempObj.line_no))
						{
							tempObj.column_description = _.sortBy(tempObj.column_description, 'attrib_order');
							$scope.irsF5471ScheduleJData[tempIndex] = tempObj;
						}

						i = j;
					}
					///changing the header col span value based on the sub_header leangth
					//var tempHeaderObj = _.find($scope.columnHeader, {})
					console.log("final $scope.irsF5471ScheduleJData--------------", $scope.irsF5471ScheduleJData);
					if (parseInt(GlobalService.globalParams.tax_year) < 2018) {
						setAdjAmt(); // FOR NOW NO data available now for 2018 - will change when 2018 data available in database

					} else
						setGHOSTAmt();
					//angular.copy($scope.irsF5471ScheduleJData, $scope.irsF5471ScheduleJOriginalData);
				} else {
					if (data.errorMessage === "no access") {
						AlertService.add("", "Sorry you do not have access to do the requested action.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
							"",
							"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
				}

			});
		}

		loadScheduleJData();

//====================================get tax adj amt==================================//
		function setAdjAmt() {
			var params = {
				"tax_year": GlobalService.globalParams.tax_year,
				"combination_key": vm.rowData.HO_COMBINATION_KEY,
				"scenario": GlobalService.globalParams.scenario,
				"jcd_key": 250
			}
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=pg37o0", params).then(function (data) {
				console.log("Response Data x ::", data.jsonObject);
				if (data.callSuccess === "1") {
					var temp_adj_amt = data.jsonObject[0];
					if (angular.isDefined(temp_adj_amt)) {
						var line_obj = _.find($scope.irsF5471ScheduleJData, {
							line_no: temp_adj_amt.LINE_NO 
						});
						console.log("line_obj----", line_obj);
						var col_obj = _.find(line_obj.column_description, {
							attrib_name: 'UNDISTRIBUTED_EARNINGS'
						});
						col_obj.VALUE = (temp_adj_amt.ADJ_AMT_1 < 0 ? temp_adj_amt.ADJ_AMT_1 * -1 : temp_adj_amt.ADJ_AMT_1);
					} else {
						var line_obj = _.find($scope.irsF5471ScheduleJData, {
							line_no: '2A'
						})
						var col_obj = _.find(line_obj.column_description, {
							attrib_name: 'UNDISTRIBUTED_EARNINGS'
						});
						col_obj.VALUE = 0;
						var line_obj = _.find($scope.irsF5471ScheduleJData, {
							line_no: '2B'
						})
						var col_obj = _.find(line_obj.column_description, {
							attrib_name: 'UNDISTRIBUTED_EARNINGS'
						});
						col_obj.VALUE = 0;
					}
					console.log("$scope.irsF5471ScheduleJData---------", $scope.irsF5471ScheduleJData);
					setGHOSTAmt();
				} else {
					if (data.errorMessage === "no access") {
						AlertService.add("error", "Sorry you do not have access to do the requested action.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
							"error",
							"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
				}
			});

		}
//=================GETTING GHOST AMOUNTS FROM DTABASE==================//
		function setGHOSTAmt() {
			console.log("GlobalService.globalParams-----------", GlobalService.globalParams);
			var params = {
				"tax_year": GlobalService.globalParams.tax_year,
				"combination_key": vm.rowData.HO_COMBINATION_KEY,
				"scenario": GlobalService.globalParams.scenario,
				"jcd_key": GlobalService.globalParams.jcd_key || 250
			}
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=k6iobc", params).then(function (data) {
				//console.log("Response Data x ::", data.jsonObject);
				if (data.callSuccess === "1") {
					//console.log('resopnse_Data for GHOST Amt------------', data.jsonObject);
					var responseData = data.jsonObject;
					console.log("responseData---------", responseData);
					var i = 0;
					if (responseData.length > 0) {
						while (i < responseData.length) {
							var j = i;
							var tempObj = {};
							var line_no = responseData[i].LINE_NO;
							tempObj.line_no = responseData[i].LINE_NO;
							var k = 0;
							tempObj.col_data = [];
							while (j < responseData.length && line_no == responseData[j].LINE_NO) {
								tempObj.col_data[k] = {
									"ADJ_AMT": responseData[j].ADJ_AMT,
									"LINE_ATTRIB_KEY": responseData[j].LINE_ATTRIB_KEY,
									"ATTRIB_NAME": responseData[j].ATTRIB_NAME,
									"FORM_KEY": responseData[j].FORM_KEY,
									"OVERRIDE_Y_N": (angular.isDefined(responseData[j].OVERRIDE_Y_N) ? responseData[j].OVERRIDE_Y_N : null),
									"TRANS_DETAILS_KEY": (angular.isDefined(responseData[j].TRANS_DETAILS_KEY) ? responseData[j].TRANS_DETAILS_KEY : null),
									"TRANS_HEADER_KEY": (angular.isDefined(responseData[j].TRANS_HEADER_KEY) ? responseData[j].TRANS_HEADER_KEY : null),
									"TRANS_TYPE_KEY": (responseData[j].TRANS_TYPE_KEY != null ? responseData[j].TRANS_TYPE_KEY : null)
								}
								j++;
								k++;
							}
							i = j;
							var index = vm.GHOSTAmt.length;
							vm.GHOSTAmt[index] = tempObj;

						}
						vm.loadFlag = true;
						
						vm.restoreGHOSTAmt();
						console.log("vm.GHOSTAmt--------------", vm.GHOSTAmt);
					} else {
						for (var i = 0; i < $scope.irsF5471ScheduleJData.length; i++) {
							if (_.find(vm.col_total_line_arr, {
									line_no: $scope.irsF5471ScheduleJData[i].line_no
								}) == undefined && $scope.irsF5471ScheduleJData[i].line_no.split("-")[0] != 'II')
								vm.computeRowTotal($scope.irsF5471ScheduleJData[i].line_no);
						}
						vm.getColTotal('II');
						vm.loadFlag = false;
						angular.copy($scope.irsF5471ScheduleJData, $scope.irsF5471ScheduleJOriginalData);
					}
				} else {
					if (data.errorMessage === "no access") {
						AlertService.add("error", "Sorry you do not have access to do the requested action.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					} else {
						AlertService.add(
							"error",
							"An  has occurred, If you continue to experience this issue please visit the help page to contact support.",
							4000);
						vm.userMessage = "!! Unable to perform Selected Action...";
					}
				}

			});
			
		}
//==========to restore GHOST AMT===============//////////
		vm.restoreGHOSTAmt = function () {
			var ghost_amt_Change_line_arr = [];
			if (vm.loadFlag) {
				for (var i = 0; i < vm.GHOSTAmt.length; i++) {
					var irs_line_obj = _.find($scope.irsF5471ScheduleJData, {
						line_no: vm.GHOSTAmt[i].line_no
					});
					ghost_amt_Change_line_arr.push(vm.GHOSTAmt[i].line_no);
					var tempGHOSTObj = vm.GHOSTAmt[i].col_data;
					for (var j = 0; j < tempGHOSTObj.length; j++) {
						var irs_line_attrib_obj = _.find(irs_line_obj.column_description, {
							attrib_name: tempGHOSTObj[j].ATTRIB_NAME
						});
						if (irs_line_attrib_obj.is_editable && tempGHOSTObj[j].OVERRIDE_Y_N == 'N')
							irs_line_attrib_obj.VALUE = tempGHOSTObj[j].ADJ_AMT;

					}
				}
				//vm.loadFlag = false;
				
			} else {
				vm.saveFlag = false;
				for (var i = 0; i < vm.GHOSTAmt.length; i++) {
					var irs_line_obj = _.find($scope.irsF5471ScheduleJData, {
						line_no: vm.GHOSTAmt[i].line_no
					});
					ghost_amt_Change_line_arr.push(vm.GHOSTAmt[i].line_no);
					var tempGHOSTObj = vm.GHOSTAmt[i].col_data;
					for (var j = 0; j < tempGHOSTObj.length; j++) {
						var irs_line_attrib_obj = _.find(irs_line_obj.column_description, {
							attrib_name: tempGHOSTObj[j].ATTRIB_NAME
						});
						if (irs_line_attrib_obj.is_editable)
							irs_line_attrib_obj.VALUE = tempGHOSTObj[j].ADJ_AMT;
					}
				}
			}
			console.log("ghost_amt_Change_line_arr----", ghost_amt_Change_line_arr);
			for (var i = 0; i < ghost_amt_Change_line_arr.length; i++) {
				if (ghost_amt_Change_line_arr[i].split("-")[0] != 'II')
					vm.computeRowTotal(ghost_amt_Change_line_arr[i]);
				else
					vm.getColTotal(ghost_amt_Change_line_arr[i].split("-")[0]);
			}
			if(vm.loadFlag && ghost_amt_Change_line_arr.length > 0)
			{
				angular.copy($scope.irsF5471ScheduleJData, $scope.irsF5471ScheduleJOriginalData);
				vm.loadFlag = false;
			}
			//vm.computeRowTotal('1');
			//vm.computeRowTotal('');
			console.log("$scope.irsF5471ScheduleJData-------after ghost amt---------------", $scope.irsF5471ScheduleJData);
			console.log("$scope.irsF5471ScheduleJOriginalData-------after ghost amt---------------", $scope.irsF5471ScheduleJOriginalData);
		}
//=============== COMPUTE THE ROW TOTAL BASED ON LINE_NO
		vm.computeRowTotal = function (v_line_no) {
			var total = null;
			var line_obj = _.find($scope.irsF5471ScheduleJData, {
				line_no: v_line_no
			});
			if (angular.isDefined(line_obj) && line_obj != null && angular.isDefined(line_obj.column_description) && line_obj.column_description != null) {
				var i = 0;
				while (i < line_obj.column_description.length - 1) {
					if (line_obj.column_description[i].is_Display) {
						if (angular.isDefined(line_obj.column_description[i]) && line_obj.column_description[i] != null && angular.isDefined(line_obj.column_description[i].VALUE) && line_obj.column_description[i].VALUE != null) {
							if (total != null)
								total = (parseInt(total) + parseInt(line_obj.column_description[i].VALUE)).toString();
							else
								total = line_obj.column_description[i].VALUE.toString();
						}
					}
					i++;
				}
				if (angular.isDefined(line_obj.column_description[i]) && line_obj.column_description[i] != null && angular.isDefined(line_obj.column_description[i].VALUE) && (line_obj.column_description[i].is_Display)) {
					if (total != null)
						line_obj.column_description[i].VALUE = total;
					else
						line_obj.column_description[i].VALUE = null;
				}

			}
			vm.computeColTotal();

		}
//------------Compute column total for lines in "vm.col_total_line_arr" array-----------------------------////////////////////////////////
		vm.computeColTotal = function () {
			for (var i = 0; i < vm.col_total_line_arr.length; i++) {
				var total_line_obj = null;
				var total_line_obj1 = null;

				total_line_obj = _.find($scope.irsF5471ScheduleJData, {
					line_no: vm.col_total_line_arr[i].line_no
				});
				total_line_obj1 = angular.isDefined(vm.col_total_line_arr[i].copy_total_line_no) ? (_.find($scope.irsF5471ScheduleJData, {
					line_no: vm.col_total_line_arr[i].copy_total_line_no
				})) : null;
				if (angular.isDefined(total_line_obj) && total_line_obj != null && angular.isDefined(total_line_obj.column_description) && total_line_obj.column_description != null) {
					for (var j = 0; j < total_line_obj.column_description.length; j++) {
						var total = null;
						if (angular.isDefined(total_line_obj.column_description[j]) && angular.isDefined(total_line_obj.column_description[j].is_Display) && total_line_obj.column_description[j].is_Display) {
							var line_arr = vm.col_total_line_arr[i].line_no_for_total;
							for (var k = 0; k < line_arr.length; k++) {
								var line_obj = _.find($scope.irsF5471ScheduleJData, {
									line_no: line_arr[k].line_no
								});

								if (angular.isDefined(line_obj) && line_obj != null) {
									var line_attrib_obj = _.find(line_obj.column_description, {
										attrib_name: total_line_obj.column_description[j].attrib_name
									});

									if (angular.isDefined(line_attrib_obj) && line_attrib_obj != null && angular.isDefined(line_attrib_obj.VALUE) && line_attrib_obj.VALUE != null) {
										if (total != null)
											total = (parseInt(total) + (parseInt(line_attrib_obj.VALUE) * line_arr[k].multiplier)).toString();
										else
											total = (parseInt(line_attrib_obj.VALUE) * line_arr[k].multiplier).toString();
									}
								}
							}
							if (angular.isDefined(total_line_obj.column_description[j].VALUE))
								total_line_obj.column_description[j].VALUE = total;

							if (angular.isDefined(total_line_obj1) && total_line_obj1 != null && angular.isDefined(total_line_obj1.column_description[j].VALUE))
								total_line_obj1.column_description[j].VALUE = total;
						}
					}
				}
				
				vm.getRowTotal(vm.col_total_line_arr[i].line_no);
				if (angular.isDefined(vm.col_total_line_arr[i].copy_total_line_no))
					vm.getRowTotal(vm.col_total_line_arr[i].copy_total_line_no);
			}
		}
//GET ROW TOTAL (function must be called from "vm.computeColTotal function")  /////////////////////////////////////
		vm.getRowTotal = function (v_line_no) {
			var total = null;
			var line_obj = _.find($scope.irsF5471ScheduleJData, {
				line_no: v_line_no
			});
			if (angular.isDefined(line_obj) && line_obj != null && angular.isDefined(line_obj.column_description) && line_obj.column_description != null) {
				var i = 0;
				while (i < line_obj.column_description.length - 1) {
					if (line_obj.column_description[i].is_Display) {
						if (angular.isDefined(line_obj.column_description[i]) && line_obj.column_description[i] != null && angular.isDefined(line_obj.column_description[i].VALUE) && line_obj.column_description[i].VALUE != null) {
							if (total != null)
								total = (parseInt(total) + parseInt(line_obj.column_description[i].VALUE)).toString();
							else
								total = line_obj.column_description[i].VALUE.toString();
						}
					}
					i++;
				}
				if (angular.isDefined(line_obj.column_description[i]) && line_obj.column_description[i] != null && angular.isDefined(line_obj.column_description[i].VALUE) && line_obj.column_description[i].is_Display) {
					if (total != null)
						line_obj.column_description[i].VALUE = total;
					else
						line_obj.column_description[i].VALUE = null;
				}

			}

		}

///////for tax_year >= 2018 part-II (get total)///////////////////////////////////
		vm.getColTotal = function (part_no) {
			var total = null;
			for (var i = 0; i < $scope.irsF5471ScheduleJData.length; i++) {
				if ($scope.irsF5471ScheduleJData[i].part_no == part_no) {
					if ($scope.irsF5471ScheduleJData[i].line_no != 'II-4') {
						var line_attrib_obj = $scope.irsF5471ScheduleJData[i].column_description[0];
						if (angular.isDefined(line_attrib_obj) && line_attrib_obj != null && angular.isDefined(line_attrib_obj.VALUE) && line_attrib_obj.VALUE != null) {
							if (total != null)
								total = (parseInt(total) + parseInt(line_attrib_obj.VALUE)).toString();
							else
								total = line_attrib_obj.VALUE.toString();
						}
					} else if ($scope.irsF5471ScheduleJData[i].line_no == 'II-4') {
						if (angular.isDefined($scope.irsF5471ScheduleJData[i].column_description[0].VALUE))
							$scope.irsF5471ScheduleJData[i].column_description[0].VALUE = total;
					}

				}
			}
		}

		vm.change = function (index) {
			if (!vm.scheduleJDetails[index].VALUE)
				vm.scheduleJDetails[index].VALUE = "0";
		}
//SAVE FUCTION////////////////////////////////
		/* save listener from workflow step */

		$scope.$on('workflowSave', function (event, data) {
			vm.saveScheduleJData();
		})
		vm.saveScheduleJData = function () {
			console.log("$scope.irsF5471ScheduleJData-----------", $scope.irsF5471ScheduleJData);
			if ($scope.crudLoading) {
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}

			//$scope.crudLoading = true;

			var updateOverrideFlag = [];
			var editRow = [];

			var scheduleSettings = [{
				"tax_year": GlobalService.globalParams.tax_year,
				"scenario": GlobalService.globalParams.scenario,
				"jcd_key": 250
			}];
			//===================GETTING IRS-FORM DATA I  JSONOBJECT TO SAVE
			for (var i = 0; i < $scope.irsF5471ScheduleJData.length; i++) {
				var temp_line_obj = $scope.irsF5471ScheduleJData[i].column_description;
				var ghost_line_obj = _.find(vm.GHOSTAmt, {
					line_no: $scope.irsF5471ScheduleJData[i].line_no
				})

				for (var j = 0; j < temp_line_obj.length; j++) {
					if (temp_line_obj[j].is_Display && temp_line_obj[j].VALUE != null) {
						var returnObj1 = [{
							"tax_year": GlobalService.globalParams.tax_year,
							"trans_type_key": $scope.irsF5471ScheduleJData[i].saveParams.trans_type_key,
							"combination_key": vm.rowData.HO_COMBINATION_KEY,
							"group_obj_key": vm.rowData.GROUP_OBJ_KEY,
							"form_key": $scope.irsF5471ScheduleJData[i].saveParams.form_key,
							"line_no": $scope.irsF5471ScheduleJData[i].line_no,
							"occurence": ((temp_line_obj[j].occurrence == "0") ? null : temp_line_obj[j].occurrence),
							"line_attrib_key": temp_line_obj[j].line_attrib_key,
							"Attribute_Name": temp_line_obj[j].attrib_name,
							"Attribute_Value": (temp_line_obj[j].VALUE != null ? temp_line_obj[j].VALUE : 0),
							"trans_dtls_key": ((temp_line_obj[j].trans_details_key == "0") ? null : temp_line_obj[j].trans_details_key),
							"trans_status": "A"
						}];

						editRow.push(returnObj1);

						if (angular.isDefined(ghost_line_obj) && temp_line_obj[j].is_editable) {
							var ghost_attrib_obj = _.find(ghost_line_obj.col_data, {
								ATTRIB_NAME: temp_line_obj[j].attrib_name
							});
							if (angular.isDefined(ghost_attrib_obj) && ((temp_line_obj[j].VALUE != ghost_attrib_obj.ADJ_AMT && ghost_attrib_obj.OVERRIDE_Y_N != 'Y' && ghost_attrib_obj.TRANS_DETAILS_KEY != null) || (temp_line_obj[j].VALUE == ghost_attrib_obj.ADJ_AMT && ghost_attrib_obj.OVERRIDE_Y_N != 'N' && ghost_attrib_obj.TRANS_DETAILS_KEY != null))) {
								var ghostJSONObj = [{
									"trans_header_key": ghost_attrib_obj.TRANS_HEADER_KEY,
									"trans_details_key": ghost_attrib_obj.TRANS_DETAILS_KEY,
									"trans_type_key": ghost_attrib_obj.TRANS_TYPE_KEY,
									"line_attrib_key": ghost_attrib_obj.LINE_ATTRIB_KEY,
									"form_key": ghost_attrib_obj.FORM_KEY,
									"OVERRIDE_Y_N": ghost_attrib_obj.OVERRIDE_Y_N == 'Y' ? 'N' : 'Y'
								}];
								updateOverrideFlag.push(ghostJSONObj);
							}
						}
					}
				}

			}
			var scheduleDetails = {
				"addrows": [],
				"editrows": editRow,
				"removerows": []
			};
			console.log("scheduleDetails-----------------------", scheduleDetails);
			console.log("updateOverrideFlag-----------------------", updateOverrideFlag);
			var message = "Schedule J details are sucessfully save/edit";

			scheduleJGroupObjFactory.saveScheduleJ(scheduleSettings,
				scheduleDetails).then(
				function (result) {

					if (result.errorMessage && result.errorMessage !== 'null') {
						AlertService.add("error", result.errorMessage, 4000);
						$scope.crudLoading = false;
					} else {
						vm.saveFlag = true;
						AlertService.add("success", message, 4000);
						if (updateOverrideFlag.length > 0) {
							var jsonSettings = [{
								"combination_key": vm.rowData.HO_COMBINATION_KEY,
								"tax_year": GlobalService.globalParams.tax_year
							}];
							var jsonDetails = updateOverrideFlag;
							scheduleJGroupObjFactory.updateFlag(jsonSettings, jsonDetails).then(function (result) {
								if (result.errorMessage && result.errorMessage !== 'null') {
									$scope.crudLoading = false;
									//AlertService.add("error", result.errorMessage, 4000);
								} else {
									//AlertService.add("success", message, 4000);
									$scope.crudLoading = false;
								}
							});
						}
						$scope.crudLoading = false;
						vm.loadFlag = false;
						vm.saveFlag = true;
						loadScheduleJData();
					}
				});

		}

	}



});