/**
 * Created by 212544474 on 2/17/2016.
 */

define([
    'angular'

], function () {
    'use strict';


    var services =  angular.module('app.reportableService',[])

        .factory("ReportableTransactionFactory",['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $stateParams,$timeout, $rootScope, $log,GlobalService,DataFiltersFactory,workspaceFactory,GENERAL_CONFIG) {

            var factory = {};
            factory.url = {};
            factory.url.saveReportableBatch  = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=75";
            factory.url.getReportableBatch  = GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=?";


            factory.getReportableBatch = function(_params){

                var params = _.merge({},  GlobalService.globalParams , _params);

                var promise = $http({
                    method: "get",
                    url: factory.url.getReportableBatch,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;


            }



            factory.saveReportableBatch = function(_array){
                var filterParams = {};
                var jsonObj = JSON.stringify(_array);
                var jsonSettings =  {};

                var params = filterParams;
                params = _.merge({},  GlobalService.globalParams , params);
                params =  _.extend({jsonObj:jsonObj}, params);
                params =  _.extend({jsonSettings:jsonSettings}, params);
                params.process_name =  "reportable_transaction_save_batch";
                params.action_id = 75;




                var promise = $http({
                    method: "post",
                    url: factory.url.saveReportableBatch,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;

            }


            /*
            factory.saveProjectTag = function(_schdMs){

                var data = {jsonObj:JSON.stringify(_schdMs)}
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({},  filterParams , data);
                _.merge({},  GlobalService.globalParams , params);



                console.log("params:============================ " , params);

                var promise = $http({
                    method: "post",
                    url: URLS.UPDATE,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    console.log("response: " , response)
                    console.log("response.status: " , response.status)
                    return response.data;
                });
                return promise;
            }


            */

            return factory;

        }])


    return services;


});