define([
    'angular',
    './partnershipControllersNew',
    './partnershipServicesNew',
    './pshipServices1065K1',
    './pshipServices1065K3Page1',
    './pshipServices1065K3PartI',
    './pshipServices1065K3PartII',
    './pshipServices1065K3PartIII',
    './pshipServices1065K3PartIV',
    './pshipServices1065K3PartV',
    './pshipServices1065K3PartVI',
    './pshipServices1065K3PartVII',
    './pshipServices1065K3PartVIII',
    './pshipServices1065K3PartIX',
    './pshipServices1065K3PartX',
    './pshipServices1065K3PartXI',
    './pshipServices1065K3PartXIII'
   
    
], function () {
    'use strict';
    return angular.module('app.partnershipNew', ['app.partnershipControllersNew','app.partnershipServicesNew','app.pshipServices1065K1','app.pshipServices1065K3Page1','app.pshipServices1065K3PartI','app.pshipServices1065K3PartII','app.pshipServices1065K3PartIII','app.pshipServices1065K3PartIV','app.pshipServices1065K3PartV','app.pshipServices1065K3PartVI','app.pshipServices1065K3PartVII','app.pshipServices1065K3PartVIII','app.pshipServices1065K3PartIX','app.pshipServices1065K3PartX','app.pshipServices1065K3PartXI','app.pshipServices1065K3PartXIII'])
    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
      
    	
    }]);
        
});
