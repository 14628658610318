define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.SectionDetailsCtrl', [])
    .controller('SectionDetailsCtrl', ['GlobalService', 'AlertService','$uibModal','$uibModalInstance','$scope','$rootScope','dataRow','rowData','sourceRow', 'SectionDetailsFactory','USER_SETTINGS',SectionDetailsCtrl])
			
	function SectionDetailsCtrl(GlobalService, AlertService, $uibModal, $uibModalInstance, $scope, $rootScope, dataRow, rowData, sourceRow, SectionDetailsFactory, USER_SETTINGS) {
    	
    	var vm = this;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.validForm = false;
        vm.sectionFormData = [];
    	vm.cancel = function(){
    		if(vm.crudLoading){
				AlertService.add("info", "The transaction is still in progress", 4000);
			}
            $uibModalInstance.dismiss('cancel');
        };

        vm.dataRow = dataRow;
        vm.rowData = rowData;
        vm.sectionName = sourceRow.sectionVal;
        vm.occurrence  = sourceRow.occurrence;
        vm.tax_year  = sourceRow.tax_year;
        vm.scenario  = sourceRow.scenario;
        vm.sel_multiplier = "";
        vm.percentDrpDwnData = [{"LABEL":"100%",VALUE:100},
                                {"LABEL":"80% if the farmland was disposed of within the 6th year",VALUE:80},
                                {"LABEL":"60% if the farmland was disposed of within the 7th year",VALUE:60},
                                {"LABEL":"40% if the farmland was disposed of within the 8th year",VALUE:40},
                                {"LABEL":"20% if the farmland was disposed of within the 9th year",VALUE:20}];
        
        function customCheckForSection1250 (index) {
            if(vm.sectionFormData[index].ATTRIB_SHORT_CODE == 'LINE_26a_ADDL_DEPR_19'){
                var gain_loss_index = vm.dataRow.Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "GAIN_LOSS_19");
                var gain_loss_amt = vm.dataRow.Line_data_occ[gain_loss_index].ATTRIB_VALUE;
                var line_26a_amt = vm.sectionFormData[index].ATTRIB_VALUE;
                var line_26d_index = vm.sectionFormData.findIndex(x => x.ATTRIB_SHORT_CODE == "LINE_26d_ADDL_DEPR_19");          
            
                if(parseFloat(gain_loss_amt) < parseFloat(line_26a_amt)){                   
                    vm.sectionFormData[line_26d_index].IS_EDITABLE = "N";
                    vm.sectionFormData[line_26d_index].ATTRIB_VALUE = 0;
                }
                else{                    
                    vm.sectionFormData[line_26d_index].IS_EDITABLE = "Y";
                }                                                
            }            
        }

        function fetchSectionModalData(){
            SectionDetailsFactory.fetchSectionDetails(vm.sectionName, vm.logged_in_user, vm.occurrence, vm.tax_year, vm.scenario, vm.rowData.COMBINATION_KEY).then((data) => {
                console.log('fetchSectionDetails::::::::::::', data);
                vm.sectionFormData = data.jsonObject;
                if(angular.equals(vm.sectionName,"1250")){
                    var line_26a_index = vm.sectionFormData.findIndex(x => x.ATTRIB_SHORT_CODE == "LINE_26a_ADDL_DEPR_19");
                    customCheckForSection1250(line_26a_index);
                }   
                else if(angular.equals(vm.sectionName,"1252")){    
                    var line_27a_multiplier_index = vm.sectionFormData.findIndex(x => x.ATTRIB_SHORT_CODE == "LINE_27a_MULTIPLIER_19");
                    var line_27a_multiplier_val = vm.sectionFormData[line_27a_multiplier_index].ATTRIB_VALUE;
                    var multplier_drpdwn_sel_index = vm.percentDrpDwnData.findIndex(x => x.VALUE == line_27a_multiplier_val);
                    vm.sel_multiplier = vm.percentDrpDwnData[multplier_drpdwn_sel_index];
                    
                }             
            });
        }       

        fetchSectionModalData();

        vm.valuechanged = function(index) {           
            vm.sectionFormData[index].IS_CHANGED_FLAG = "Y";
            customCheckForSection1250(index);         
        }

        vm.drpDownChange = function(current_row, index){        	
            vm.sectionFormData[index].ATTRIB_VALUE = vm.sel_multiplier.VALUE; 
        }

        vm.save = function() {                       
            var clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
            var message = "Section Details are successfully updated."
			vm.crudLoading = true;

            for(var i=0; i < vm.sectionFormData.length; i++){
                vm.sectionFormData[i].OCCURRENCE = vm.occurrence;
            }         
           
			SectionDetailsFactory.saveSection(vm.sectionFormData, clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
				}
			});
        }
        
    }

	return controllers;

});
