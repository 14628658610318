define([
    'angular',
    './pshipSetupDiagController',
    './pshipSetupDiagService'



], function () {
    'use strict';
    return angular.module('app.pshipSetupDiag', ['app.pshipSetupDiagController','app.pshipSetupDiagService'] );

});