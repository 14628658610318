define([
    'angular'

], function() {
    'use strict';

    var controllers = angular.module('app.basketMaintenanceCtrl', [])
        .controller('basketMaintenanceCtrl', ['$rootScope', '$scope', '$filter', 'AlertService', 'JsonObjectFactory', 'CommonUtilitiesFactory',
            'GlobalService', 'USER_SETTINGS', 'basketMaintenanceFactory', 'GENERAL_CONFIG', 'ModalFactory', '$uibModalInstance', 'rowData', 'colData', 'gridData', basketMaintenanceCtrl
        ])


        .controller('basketMaintenanceControllerModal', ['$rootScope', '$scope', '$filter', 'AlertService', 'CodeComboFactory', 'JsonObjectFactory',
            'basketMaintenanceFactory', 'GENERAL_CONFIG', 'ModalFactory', 'rowData', 'colData', basketMaintenanceControllerModal
        ]);
    //
    function basketMaintenanceControllerModal($rootScope, $scope, $filter, AlertService, CodeComboFactory, JsonObjectFactory,
        basketMaintenanceFactory, GENERAL_CONFIG, ModalFactory, rowData, colData) {
        basketMaintenanceCtrl($rootScope, $scope, $filter, AlertService, CodeComboFactory, JsonObjectFactory,
            basketMaintenanceFactory, GENERAL_CONFIG, ModalFactory, null, rowData, colData, this);
    }

    function basketMaintenanceCtrl($rootScope, $scope, $filter, AlertService, JsonObjectFactory, CommonUtilitiesFactory,
        GlobalService, USER_SETTINGS, basketMaintenanceFactory, GENERAL_CONFIG, ModalFactory, $uibModalInstance, rowData, colData, gridData, thisObj) {
        var vm = this;
        rowData = rowData || {}
        vm.rowData = rowData
        /* vm.rowData = rowData;
         vm.colData = colData;*/
        vm.gridData = gridData;
        $scope.form = {
            divForm: {}
        };
        vm.globalParams = GlobalService.globalParams;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        // console.log("Here is the starting point and the rowData was ::::::::", vm.rowData);
        console.log("Here is the starting point and the global Params was ::::::::", vm.globalParams);
        vm.tax_year = vm.globalParams.tax_year;
        vm.basketName = '';
        vm.selectedBasket = [];
        vm.selectedSource = [];
        vm.sourceSelected = "";
       vm.selectedFtc = [];
        vm.ftcSelected = "";
        vm.selectedPassive = [];
        vm.passiveSelected = "";
        vm.selectedSubF = [];
        vm.subfSelected = "";
        vm.selectedFphci = [];
        vm.fphciSelected = "";
        vm.selected902Name = [];
        vm.name902Selected = "";
      ///  vm.selectedCdrNo902 = [];
        console.log(":: basketMaintenanceCtrl :: ", vm)

        //       if(!!vm.rowData) {
        getExistingBaskets();
        getSourceCodes();
        getFtcCodes();
        getPassiveCodes();
        getSubfCodes();
        getFphciCodes();
        get902Name();
        //getCdrNo902();
        //       }

        //       		return false;

        vm.modalTitle = "Add New Basket";
        if (angular.isObject($uibModalInstance)) {
            vm.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;
            vm.cancel = function() {
                $scope.crudLoading = false;
                console.log("in cancel");
                $uibModalInstance.close();
                vm.modal_name = null;
            };
        } else {
            vm.modal_name = null;
        }
        
        vm.updateBasket = function(){
        	vm.basketName = vm.sourceSelected.DISPLAY_NAME + ' - ' + vm.ftcSelected.DISPLAY_NAME + ' - ' + vm.passiveSelected.DISPLAY_NAME + ' - ' + vm.subfSelected.DISPLAY_NAME + ' - ' + vm.fphciSelected.DISPLAY_NAME;
        };
        function getExistingBaskets() {
            basketMaintenanceFactory.getExistingBaskets(vm.tax_year).then(function(data) {
                console.log("The Baskets from DB was :::::::", data.jsonObject);
                vm.selectedBasket = data.jsonObject;
            });
        }

        function getSourceCodes() {
            basketMaintenanceFactory.getSourceCodes(vm.tax_year).then(function(data) {
                console.log("The Source codes from DB was :::::::", data.jsonObject);
                vm.selectedSource = data.jsonObject;
            });
        }
        
        function getFtcCodes() {
            basketMaintenanceFactory.getFtcCodes(vm.tax_year).then(function(data) {
                console.log("The Ftc records from DB was :::::::", data.jsonObject);
                vm.selectedFtc = data.jsonObject;
            });
        }

        function getPassiveCodes() {
            basketMaintenanceFactory.getPassiveCodes(vm.tax_year).then(function(data) {
                console.log("The Passive Codes from DB was :::::::", data.jsonObject);
                vm.selectedPassive = data.jsonObject;
            });
        }

        function getSubfCodes() {
            basketMaintenanceFactory.getSubfCodes(vm.tax_year).then(function(data) {
                console.log("The Subf Codes from DB was :::::::", data.jsonObject);
                vm.selectedSubF = data.jsonObject;
            });
        }

        function getFphciCodes() {
            basketMaintenanceFactory.getFphciCodes(vm.tax_year).then(function(data) {
                console.log("The Fphci Codes from DB was :::::::", data.jsonObject);
                vm.selectedFphci = data.jsonObject;
            });
        }
        function get902Name() {
            basketMaintenanceFactory.get902Name(vm.tax_year).then(function(data) {
                console.log("The 902  Name from DB  was :::::::", data.jsonObject);
                vm.selected902Name = data.jsonObject;
            });
        }

        vm.disableAllFields = function() {
        	if (!vm.selectedSource || !vm.selectedFtc  || !vm.selectedSubF) {
        		return true;
        	}
        	return false;
        }
        vm.ispassiveIdDisabled = function() {
        	if (angular.isDefined(vm.ftcSelected) && vm.ftcSelected.FTC== 'Passive') {
        		return false;
        	} else {
        		vm.passiveSelected= "";
        		return true;
        	}
        }
       
       vm.isfphciIdDisabled = function() {
	       	if (angular.isDefined(vm.subfSelected) && vm.subfSelected.SUBF== 'FPHCI') {
	    		return false;
	    	} else {
	    		vm.fphciSelected= "";
	    		return true;
	    	}
        } 
        
        vm.cancel = function() {
            var args = {};
            //$rootScope.broadcast("gridRefresh", {});
            $rootScope.$emit('gridUpdate', args);
            $uibModalInstance.dismiss('cancel');
        };



        function buildJson() {

            vm.basketName = '';
            //	vm.selectedBasket = '';
            vm.selectedSource = '';  
            vm.selectedFtc = '';
            vm.selectedPassive = '';
            vm.selectedSubF = '';
            vm.selectedFphci = '';
             vm.selected902Name = '';
           ///  vm.selectedCdrNo902 = '';
         var selectedClientsArray = [];
            var formMappingObj = {};

            vm.basketJson['basketId'] = vm.basketId;
            vm.basketJson['basketName'] = vm.basketName;
            vm.basketJson['selectedSource'] = vm.selectedSource;
           vm.basketJson['selectedFtc'] = vm.selectedFtc;
            vm.basketJson['selectedPassive'] = vm.selectedPassive;
            vm.basketJson['selectedSubF'] = vm.selectedSubF;
            vm.basketJson['selectedFphci'] = vm.selectedFphci ;
           vm.basketJson['selected902Name'] =  vm.selected902Name;
            ///vm.basketJson['selectedCdrNo902'] = vm.selectedCdrNo902;
            isJsonBuilt = true;
        }



        vm.printbasketName = function() {
            console.log("The selcted source was ::::::::::", vm.selectedBasket);
        }

        vm.printselectedSource = function() {
            console.log("The selcted source was ::::::::::", vm.selectedSource);
        }
        
       
       
        vm.printselectedFtcId = function() {
            console.log("The selcted FTCId was ::::::::::", vm.selectedFtcId);
        }

        vm.printselectedPassiveId = function() {
            console.log("The selcted PassiveId was ::::::::::", vm.selectedPassiveId);
        }
        vm.printselectedSubFId = function() {
            console.log("The selcted SubFId was ::::::::::", vm.selectedSubFId);
        }

        vm.printselectedFphciId = function() {
            console.log("The selcted FphciId was ::::::::::", vm.selectedFphciId);
        }
         vm.printselected902Name = function(){
         	console.log("The selcted 902 Name  was ::::::::::", vm.selected902Name);
         }
         

         
        vm.saveNewBasket = function() {
        	if(!vm.fphciSelected || vm.isfphciIdDisabled()) {
        		var proceed = confirm("FPHCI column is not selected still goahead and  save ?")
        		if(!proceed) {
        			return false;
        		}
        	}
        	 var basketJson = {
              /* "tax_year"      : vm.globalParams.tax_year,
               "filing_group"		: 1,*/
            	 "basket_name"     : vm.basketName,
                "source_id" 	: vm.sourceSelected.SOURCE_ID,
                "source_percent" : vm.sourceSelected.SOURCE_PERCENT,
                "ftc_id"    	: vm.ftcSelected.FTC_ID,
                "passive_id"	: vm.passiveSelected.PASSIVE_ID,
                "subf_id"   	: vm.subfSelected.SUBF_ID,
                "fphci_id"  	: vm.fphciSelected.FPHCI_ID,
                "leid_902"      : vm.selectedLeid902,
                "cdr_no_902"    : vm.selectedCdrNo902,
                "Basket_Category" : "U",
				"Basket_Status" : "I",
				"from_year"		:vm.globalParams.tax_year,
				"to_year"		:"9999",
				"leid_902"     : vm.name902Selected.LEID_902,
				"cdr_no_902"   : vm.name902Selected.CDR_NO_902
					
					


            };
            vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
            var success_message = "The new Basket was successfully added";
            console.log("The save json data was :::::", basketJson);
            basketMaintenanceFactory.addNewBasket(basketJson, vm.clob_settings, vm.logged_in_user).then(function(data) {
            	console.log("Result from  save data was ---------------::::", data);
        		if(null != data.errorMessage && data.errorMessage != "null"){
        			console.log("There was the error message and the message is ", data.errorMessage);
        			AlertService.add("error", data.errorMessage, 4000);
        		}else {
        			console.log("There wa no error message and the message was ", data.errorMessage);
        			AlertService.add("success", success_message, 4000);
        			$uibModalInstance.dismiss('cancel');
        			var args = {combination_key: tax_year.HO_COMBINATION_KEY, gridFilter: {combination_key: tax_year.HO_COMBINATION_KEY}};
        			$rootScope.$emit('gridUpdate', args);
        		}
				});

        }
        vm.reset = function() {
            init();
        };
        function init() {
        	
        }
         init();

//        return controllers;

    }
});


