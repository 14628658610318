
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.adjOpeningReserveBalancesService',[])
        .factory("AdjOpeningReserveBalancesFactory",['$rootScope', '$log','JsonObjectFactory','AlertService', '$injector','GlobalService', 'workspaceFactory','GENERAL_CONFIG', 
        	function($rootScope, $log,JsonObjectFactory,AlertService, $injector,GlobalService, workspaceFactory,GENERAL_CONFIG) {
            var factory = {};
            var returnObj = {};

            factory.saveLocalPurchaseAdj = function(_callName , _data){
            	console.log("data", _data);
               
                  _data.rowData.ADJUSTED_BALANCE = parseInt(_data.rowData.BOY_AMT) + parseInt(_data.rowData.ADJUSTMENTS);
                 /*  $rootScope.$broadcast('gridRefresh', false); */
                    
                    returnObj.combination_key = _data.rowData.COMBINATION_KEY;

                    var service = $injector.get('JsonObjectFactory');
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=o2v5nd";
                    var jsonObj = JSON.stringify(_data.rowData);
                    var jsonSettings = '{}';
                    var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = _.merge({}, GlobalService.globalParams, filterParams);
                    var params = _.extend({jsonObj:jsonObj}, params);
                    params =  _.extend({jsonSettings:jsonSettings}, params);
                    params.process_name =  "saveLocalPurchaseAdj";

                   service.saveJSON(url, params).then(function(data){
                        if(data.callSuccess === "1"){
                            AlertService.add("success", "Data saved Successfully.",4000);
                        }else{
                            AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                        }
                        
                        var args = {tax_year:_data.rowData.TAX_YEAR, scenario:_data.rowData.SCENARIO, jcd_key: _data.rowData.JCD_KEY, trans_details_key: _data.rowData.TRANS_DETAILS_KEY, gridFilter: {TAX_YEAR:_data.rowData.TAX_YEAR, SCENARIO:_data.rowData.SCENARIO, JCD_KEY: _data.rowData.JCD_KEY, TRANS_DETAILS_KEY: _data.rowData.TRANS_DETAILS_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                         
                    });

                  
                
            }
            return factory;
        }])

    return services;
});
