define([

	'angular'

], function () {

	'use strict';

	var controllers =  angular.module('app.scheduleDController', [])
		.controller('ScheduleDadjController', ['$rootScope', '$scope', '$http', 'GlobalService', '$uibModalInstance', 'ModalFactory', 'AlertService', 'CodeComboFactory', 'rowData', 'JsonObjectFactory', 'ScheduleDFactory','GENERAL_CONFIG', scheduleDadjController]);

	function scheduleDadjController($rootScope, $scope, $http, GlobalService, $uibModalInstance, ModalFactory, AlertService, CodeComboFactory, rowData, JsonObjectFactory, ScheduleDFactory,GENERAL_CONFIG) {

		var scheddEditFormFieldsOrig = {};
		$scope.scheddEditFormFields = { "TRANS_TYPE_CHK" : false, "WORTHLESS_FLAG_IND" : false, "IS_SEC_754_IND" : false };

		$scope.title = "Schedule D Adjustments";
		$scope.rowData = rowData;
		$scope.lineOneGLs = [];
		$scope.lineFourUnusedCapLossCarryover = '';
		$scope.lineSixGLs = [];
		$scope.noLineOneGLRecords = false;
		$scope.noLineSixGLRecords = false;

		function init() {

			$scope.lineOneGLs = [];
			$scope.lineFourUnusedCapLossCarryover = '';
			$scope.lineSixGLs = [];
			$scope.noLineOneGLRecords = false;
			$scope.noLineSixGLRecords = false;
			
			getLineOneGLs();
			getLineSixGLs();

			getLineFourUnusedCapLossCarryover();

			clearScheddEditForm();

		}

		init();

		$scope.cancel = function () {
			clearScheddEditForm();
			$uibModalInstance.dismiss('cancel');
		}

		$scope.loadRowEdit = function (rowInfo)	 {
			angular.copy(rowInfo, $scope.scheddEditFormFields);
			setScheddEditFormCheckboxes();
			angular.copy($scope.scheddEditFormFields, scheddEditFormFieldsOrig);
		}

		$scope.resetScheddEdit = function () {
			angular.copy(scheddEditFormFieldsOrig, $scope.scheddEditFormFields);
			$scope.scheddEditForm.$setPristine();
			$scope.scheddEditForm.$submitted = false;
		}

		$scope.cancelScheddEdit = function () {
			clearScheddEditForm();
			$scope.scheddEditForm.$submitted = false;
		}

		$scope.adjustmentDelete = function (adjustmentInfo) {
			if (adjustmentInfo.LINE_NO == "1") {
				var delindex = _.findIndex($scope.lineOneGLs, { 'ROWNUM': adjustmentInfo.ROWNUM });
				$scope.lineOneGLs.splice(delindex,1);
				if ($scope.lineOneGLs.length <= 0) {
					$scope.showLineOneForm = false;
					$scope.noLineOneGLRecords = true;
				} else {
					$scope.showLineOneForm = true;
					$scope.noLineOneGLRecords = false;
				}
			} else if (adjustmentInfo.LINE_NO == "6") {
				var delindex = _.findIndex($scope.lineSixGLs, { 'ROWNUM': adjustmentInfo.ROWNUM });
				$scope.lineSixGLs.splice(delindex,1);
				if ($scope.lineSixGLs.length <= 0) {
					$scope.showLineSixForm = false;
					$scope.noLineSixGLRecords = true;
				} else {
					$scope.showLineSixForm = true;
					$scope.noLineSixGLRecords = false;
				}
			}
			recalcLineGLs();
            AlertService.add("success", adjustmentInfo.DESCRIPTION + " has been successfully deleted", 4000);
		}

		$scope.saveScheddEdit = function () {

			if ($scope.scheddEditForm.$invalid) {
				$scope.scheddEditForm.$submitted = true;
				AlertService.add("danger", "Please correct the errors below", 4000);
				return;
			}

			if ($scope.crudLoading) {
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}

			var url = GENERAL_CONFIG.base_url + "/saveFedForm?process_name=ScheduleDdata";
			
			reconcileScheddEditFormCheckboxes();
			
			var arrayShell = [];
			arrayShell.push($scope.scheddEditFormFields);

			JsonObjectFactory.saveJSONObj(url, arrayShell).then(function (responce) {
				if (responce === "1") {
					if ($scope.scheddEditFormFields.ROWNUM === "") {
						adjustmentAdded();
					} else {
						adjustmentEdited();
					}
					recalcLineGLs();
					$scope.cancelScheddEdit();
				} else {
					AlertService.add("error", "An error occurred while saving", 4000);
				}
			});

		}

		function adjustmentAdded() {
			var addedAdjustment = angular.copy($scope.scheddEditFormFields);
			addedAdjustment.ROWNUM = -1;
			if (addedAdjustment.LINE_NO == "1") {
				$scope.lineOneGLs.push(addedAdjustment);
				$scope.showLineOneForm = true;
				$scope.noLineOneGLRecords = false;
			} else if (addedAdjustment.LINE_NO == "6") {
				$scope.lineSixGLs.push(addedAdjustment);
				$scope.showLineSixForm = true;
				$scope.noLineSixGLRecords = false;
			}
			AlertService.add("success", addedAdjustment.DESCRIPTION  + " has been successfully added", 4000);
		}

		function adjustmentEdited() {
			var editedAdjustment = angular.copy($scope.scheddEditFormFields);
			if (editedAdjustment.LINE_NO == "1") {
				var edtindex = _.findIndex($scope.lineOneGLs, { 'ROWNUM': editedAdjustment.ROWNUM });
				$scope.lineOneGLs[edtindex] = editedAdjustment;
			} else if (editedAdjustment.LINE_NO == "6") {
				var edtindex = _.findIndex($scope.lineSixGLs, { 'ROWNUM': editedAdjustment.ROWNUM });
				$scope.lineSixGLs[edtindex] = editedAdjustment;
			}
			AlertService.add("success", editedAdjustment.DESCRIPTION  + " has been successfully edited", 4000);
		}
		
		function recalcLineGLs() {
			
			var newsum = 0;
			
			_.forEach($scope.lineOneGLs, function(value, key) {
                newsum += parseInt(value.GAIN_LOSS);
            });
			
			newsum += parseInt($scope.lineFourUnusedCapLossCarryover);
			
			_.forEach($scope.lineSixGLs, function(value, key) {
                newsum += parseInt(value.GAIN_LOSS);
            });
			
			$scope.rowData.AGGR_CGNI = newsum;
			$scope.rowData.AGGR_DIFF = newsum - parseInt($scope.rowData.AGGR_TB);
			
		}
		
		function clearScheddEditForm() {
			
			$scope.scheddEditFormFields = {};
			
			$scope.scheddEditFormFields.DESCRIPTION = "";
			$scope.scheddEditFormFields.DATE_ACQ = "";
			$scope.scheddEditFormFields.DATE_SOLD = "";
			$scope.scheddEditFormFields.SALE_PRICE = "";
			$scope.scheddEditFormFields.COST_BASIS = "";
			$scope.scheddEditFormFields.GAIN_LOSS = "";
			$scope.scheddEditFormFields.TRANS_TYPE = "";
			$scope.scheddEditFormFields.TRANS_TYPE_CHK = "";
			$scope.scheddEditFormFields.WORTHLESS_FLAG = "";
			$scope.scheddEditFormFields.IS_SEC_754 = "";
			$scope.scheddEditFormFields.LINE_NO = "";
			$scope.scheddEditFormFields.COMMENTS = "";
			$scope.scheddEditFormFields.ROWNUM = "";
			
			setScheddEditFormCheckboxes();

			scheddEditFormFieldsOrig = {};

			if( $scope.scheddEditForm ) {
				$scope.scheddEditForm.$setPristine();
				$scope.scheddEditForm.$setUntouched();
			}

		}

		function setScheddEditFormCheckboxes() {

			/**
			 * 
			 * For assurance, testing least-likely candidate first.
			 * 
			 * Test for Y since that is the only affirmation for true.
			 * A false may be N, empty, null, etc.
			 * 
			 */
			if ( $scope.scheddEditFormFields.TRANS_TYPE_CHK === "Y" ) {
				$scope.scheddEditFormFields.TRANS_TYPE_CHK_IND = true;
			} else {
				$scope.scheddEditFormFields.TRANS_TYPE_CHK_IND = false;
			}

			if ( $scope.scheddEditFormFields.WORTHLESS_FLAG === "Y" ) {
				$scope.scheddEditFormFields.WORTHLESS_FLAG_IND = true;
			} else {
				$scope.scheddEditFormFields.WORTHLESS_FLAG_IND = false;
			}

			if ( $scope.scheddEditFormFields.IS_SEC_754 === "Y" ) {
				$scope.scheddEditFormFields.IS_SEC_754_IND = true;
			} else {
				$scope.scheddEditFormFields.IS_SEC_754_IND = false;
			}

		}

		function reconcileScheddEditFormCheckboxes() {

			if ( !$scope.scheddEditFormFields.TRANS_TYPE_CHK_IND ) {
				$scope.scheddEditFormFields.TRANS_TYPE_CHK = "N";
			} else {
				$scope.scheddEditFormFields.TRANS_TYPE_CHK = "Y";
			}

			if ( !$scope.scheddEditFormFields.WORTHLESS_FLAG_IND ) {
				$scope.scheddEditFormFields.WORTHLESS_FLAG = "N";
			} else {
				$scope.scheddEditFormFields.WORTHLESS_FLAG = "Y";
			}

			if ( !$scope.scheddEditFormFields.IS_SEC_754_IND ) {
				$scope.scheddEditFormFields.IS_SEC_754 = "N";
			} else {
				$scope.scheddEditFormFields.IS_SEC_754 = "Y";
			}

		}

		function getLineOneGLs() {

			var params = {
					"action_id": 43,
					"tax_year": $scope.rowData.TAX_YEAR,
					"scenario": $scope.rowData.SCENARIO,
					"reporting_period": $scope.rowData.REPORTING_PERIOD,
					"leid": $scope.rowData.LEID,
					"cdr_no": $scope.rowData.CDR_NO,
					"bsla_code": $scope.rowData.BSLA_CODE,
					"line_no": 1,
			}

			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

				$scope.lineOneGLs = data.jsonObject;

				if($scope.lineOneGLs.length === 0 ){
					$scope.noLineOneGLRecords = true;
				}

			});

		};

		function getLineSixGLs() {

			var params = {
					"action_id": 43,
					"tax_year": $scope.rowData.TAX_YEAR,
					"scenario": $scope.rowData.SCENARIO,
					"reporting_period": $scope.rowData.REPORTING_PERIOD,
					"leid": $scope.rowData.LEID,
					"cdr_no": $scope.rowData.CDR_NO,
					"bsla_code": $scope.rowData.BSLA_CODE,
					"line_no": 6,
			}

			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

				$scope.lineSixGLs = data.jsonObject;

				if($scope.lineSixGLs.length === 0 ){
					$scope.noLineSixGLRecords = true;
				}

			});

		};
		
		function getLineFourUnusedCapLossCarryover() {

			var params = {
					"action_id": 44,
					"tax_year": $scope.rowData.TAX_YEAR,
					"scenario": $scope.rowData.SCENARIO,
					"reporting_period": $scope.rowData.REPORTING_PERIOD,
					"leid": $scope.rowData.LEID,
					"cdr_no": $scope.rowData.CDR_NO,
					"bsla_code": $scope.rowData.BSLA_CODE
			}

			JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {

				/******** TODO ********************************************
				 * 
				 * Talk to Heath about alternative to ACTION_TYPE 'list'
				 * 
				 */
				var lineFourHits = [];
				lineFourHits = data.jsonObject;

				if(lineFourHits.length === 0 ){
					$scope.lineFourUnusedCapLossCarryover = 0;
				} else {
					$scope.lineFourUnusedCapLossCarryover = lineFourHits[0].RETASFILED_SCHDM_AMT;
				}

			});

		};

	} /////////////// END CTRL

	return controllers;

});