define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.TasksController', ['dndLists'])
        .controller('TasksController', ['GlobalService', 'workspaceFactory', '$state', '$rootScope', '$scope', '$http', 'MessageFactory', 'GENERAL_CONFIG', 'AlertService', '$uibModal', 'USER_SETTINGS', 'TAX_YEARS', taskController]);

    function taskController(GlobalService, workspaceFactory, $state, $rootScope, $scope, $http, MessageFactory, GENERAL_CONFIG, AlertService, $uibModal, USER_SETTINGS, TAX_YEARS) {
        $scope.pageTitle = "Tasks";
        // $scope.actionList = {};
        $scope.actionList = [];
        var vm = this;
        vm.selected = {
            id: 1
        };
        vm.inputText = '';
        vm.actionList = {};
        var URLS = {
            loadTasksBySSO: '/loadTasksBySso',
            updateTaskByKey: '/updateTaskStatus',
            getComment: '/commentByKey',
            commentReply: '/commentReply'
        }
        vm.comment = {};
        /* vm.taxYears = TAX_YEARS;
        vm.getScenarioList = function (year) {
            vm.scenairoList = _.find(TAX_YEARS, {
                value: year.value
            }).scenarios;
            vm.defScenario = _.filter(vm.scenairoList, {
                default_scenario: 'Y'
            });
            vm.wfScenario = vm.defScenario[0].scenario;
        }
        vm.taxYear = GlobalService.inputs.tax_year.selected;
        vm.scenairoList = vm.taxYear.scenarios;
        vm.wfScenario = GlobalService.globalParams.scenario;
 */
        vm.filters = {};
        vm.search;
        vm.filters.me = [];
        vm.filters.me_list = {};
        vm.filters.me_list.selected = [];
        vm.filters.me_list.name = "ME(s)"
        vm.filters.me_list.values = [];

        vm.filters.created = [];
        vm.filters.created_by = {};
        vm.filters.created_by.selected = [];
        vm.filters.created_by.name = "Created By"
        vm.filters.created_by.values = [];

        vm.filters.assign = [];
        vm.filters.assign_to = {};
        vm.filters.assign_to.selected = [];
        vm.filters.assign_to.name = "Assign To"
        vm.filters.assign_to.values = [];

        vm.createdList = [];
        vm.assignList = [];

        vm.loadTasks = function () {
            //  vm.loading = true;
            var params = {
                status: status ? status : 'open'
            }
            MessageFactory.getComments(GENERAL_CONFIG.base_url + URLS.loadTasksBySSO, params).then(function (data) {
                if (data && data.callSuccess === "1") {
                    vm.taskList = data.TaskList;
                    if (vm.taskList && vm.taskList.length > 0) {
                        var x = _.filter(vm.taskList, function (task) {
                            return task.tags
                        });
                        var z = [];
                        if (x && x.length) {
                            for (var i = 0; i < x.length; i++) {
                                z.push(JSON.parse(x[i].tags));
                            }
                        }
                        var tx = [], sce = [],  parent = [];
                        for (var i = 0; i < z.length; i++) {
                            var zy = z[i];
                            for (var j = 0; j < zy.length; j++) {
                                if (zy[j].tax_year)
                                    tx = tx.concat(zy[j].tax_year);
                                if (zy[j].scenario)
                                    sce = sce.concat(zy[j].scenario);
                                if (zy[j].parent_me_string)
                                    parent = parent.concat(zy[j].parent_me_string);
                            }
                        }
                        vm.filters.taxYears = _.uniqBy(tx, 'value');
                        vm.filters.scenairoList = _.uniqBy(sce, 'value');
                        vm.taxYear = vm.filters.taxYears[0];
                        vm.wfScenario = vm.filters.scenairoList[0];
                        vm.filters.me_list.values = [];
                        _.each(_.uniqBy(parent, 'value'), function (me) {
                            me.name = me.value + ' - ' + me.label;
                            vm.filters.me_list.values.push(me);
                        });


                        _.each(_.uniqBy(vm.taskList, 'created_by'), function (selected) {
                            vm.filters.created_by.values.push({
                                value: selected.created_by,
                                name: selected.created_by + '-' + selected.display_name
                            });
                        });
                        var assignUsers = [];
                        for (var i = 0; i < vm.taskList.length; i++) {
                            assignUsers = assignUsers.concat(vm.taskList[i].assignList);
                        }
                        _.each(_.uniqBy(assignUsers, 'sso_id'), function (assign) {
                            vm.filters.assign_to.values.push({
                                value: assign.sso_id,
                                name: assign.sso_id + '-' + assign.display_name
                            })
                        })

                        vm.uncompleteTaskList = _.filter(vm.taskList, function (item) {
                            return item.task_status != 'C'
                        });
                        /* update the task Count on Header */
                        $rootScope.updateTaskCount((vm.uncompleteTaskList.length < 0) ? 0 : vm.uncompleteTaskList.length);
                        $scope.actionList['actionRequiredList'] = _.filter(vm.taskList, {
                            task_status: 'A'
                        });
                        $scope.actionList['inprogressList'] = _.filter(vm.taskList, {
                            task_status: 'I'
                        });
                        $scope.actionList['completeList'] = _.filter(vm.taskList, {
                            task_status: 'C'
                        });
                    }

                    //                    vm.loading = false
                }
                console.log("actionList", $scope.actionList);

            });
        }
        vm.loadTasks();
        vm.openActionModal = function (value) {
       //     var ctrlString = value.comment_key? "EditCommentController as ctrl":"AddCommentController as ctrl";
            vm.gridData = {};
            vm.rowData = [];
            vm.colData = [];
            if (value) {
                // vm.gridData.previewURL = GENERAL_CONFIG.base_url + "/previewCommentAttachement?commentKey=" + value.commentKey + "&fileName=" + value.fileName;
                vm.gridData.parent_key = value.commentKey;
                vm.gridData.file_name = value.fileName;
                vm.gridData.comment_key = value.commentKey;
            }

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: (value == null || value == undefined) ?"app/components/messages/templates/add-comment-modal-tpl.html" :value.templateName  ,
                controller: (value == null || value == undefined) ? "AddCommentController as ctrl":value.contrlString ,
                size: "md",
                backdrop: "static",
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    colData: function () {
                        return vm.colData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {
                vm.selected = selectedItem;
            }, function () {
                vm.loadTasks();
            });
        }
        vm.previewAttachmentFile = function (fileName, commentKey) {
            var params = {
                templateName: 'app/components/messages/templates/file-preview-modal.html',
                contrlString: 'previewAttachmentController as ctrl',
                fileName: fileName,
                commentKey: commentKey
            }
            vm.openActionModal(params);
        }
        vm.openEditModal = function(details){
            var params = {
                templateName: 'app/components/messages/templates/add-comment-modal-tpl.html',
                contrlString: 'EditCommentController as ctrl',
                commentKey: details.comment_key
            }
            vm.openActionModal(params);
        }
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.prepareDummyList = function () {
            $http.get('app/json/task-fake-data.json').then(function (response) {
                $scope.actionList['actionRequiredList'] = response.data.actionRequiredList;
                console.log(response.data.actionRequiredList, "listttttttttttttttttttttttttttttt");
                $scope.actionList['inprogressList'] = response.data.inprogressList;
                $scope.actionList['inprogressList2'] = response.data.inprogressList2;

                vm.actioncount = response.data.actionRequiredList.length;
                // console.log("length of actionrequiredlist",vm.actioncount);
                vm.inprogressList1Count = response.data.inprogressList.length;
                vm.inprogressList2Count = response.data.inprogressList2.length;

            });
        }
        vm.timeago = function (date) {
            var date = new Date(date);
            return moment(date).fromNow();
        }
        $scope.messageDetail = {};

        /*  get the task details by comment Key */
        $scope.showMessageDetail = function (message) {
            $scope.messageDetail = message;
            vm.messgeType = $scope.messageDetail.is_public === 0 ? 'Private' : 'Public';
            vm.hasDatabuoy = (message.data && _.keys(JSON.parse(message.data)).length > 0) ? true : false;
            // vm.date = new Date(message.date).getTime();
            var data = {
                comment_key: message.comment_key,
                status: message.status
            };
            MessageFactory.getComments(GENERAL_CONFIG.base_url + URLS.getComment, data).then(function (data) {
                console.log("======================", data);
                vm.isMessageClicked = true;
                if (data.callSuccess && data.callSuccess === "1") {
                    if (data && data.comment) {
                        if (data.comment.replyList) {
                            vm.comment = data.comment;
                            for (var i = 0; i < data.comment.replyList.length; i++) {
                                data.comment.replyList[i].dateM = new Date(data.comment.replyList[i].date).getTime();
                                data.comment.replyList[i].isSender = data.comment.replyList[i].sso_id == USER_SETTINGS.user.sso_id;
                                data.comment.replyList[i].date = moment().format("MM/DD/YYYY")
                            }
                            vm.comment.replyList = data.comment.replyList;
                        }

                        var formatBytes = function bytesToSize(bytes) {
                            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                            if (bytes == 0) return '0 Byte';
                            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
                        };
                        if (data.comment.attachmentList) {
                            //var attachment,fileType;
                            for (var i = 0; i < data.comment.attachmentList.length; i++) {
                                var attachment = data.comment.attachmentList[i];
                                var fileType = attachment.file_type;
                                attachment.file_name = attachment.file_name.replace(data.comment.comment_key + "//", '');
                                attachment.icon = ['doc', 'docx'].indexOf(fileType) > -1 ? 'fa-file-word-o' : ['xls', 'xlsx', 'ods'].indexOf(fileType) > -1 ? 'fa-file-excel-o' : ['pdf'].indexOf(fileType) > -1 ? 'fa-file-pdf-o' : ['jpg', 'jpeg', 'png', 'svg', 'PNG'].indexOf(fileType) > -1 ? 'fa-file-image-o' : ['ppt', 'pptx'].indexOf(fileType) > -1 ? 'fa-file-powerpoint-o' : ['wpd', 'rtf', 'txt'].indexOf(fileType) > -1 ? 'fa-file-text-o' : '';

                                attachment.fS = formatBytes(attachment.file_size);

                            }
                            vm.comment.attachmentList = data.comment.attachmentList;

                        }
                        if (data.comment.assignList) {
                            vm.comment.assignList = data.comment.assignList;

                        }
                        if (data.comment.notifyList) {
                            vm.comment.notifyList = data.comment.notifyList;

                        }
                        if (data.comment.taskAuditList) {
                            for (var i = 0; i < data.comment.taskAuditList.length; i++) {
                               /*  data.comment.taskAuditList[i].taskStatusChange = "Task Status Changed To" + (data.comment.taskAuditList[i].task_status == 'A' ? ' Action Required ' :
                                        data.comment.taskAuditList[i].task_status == 'I' ? ' Incomplete ' : ' Complete ') + ' On ' + data.comment.taskAuditList[i].updated_on + ' By ' +
                                    data.comment.taskAuditList[i].updated_display_name */
                                    data.comment.taskAuditList[i].taskStatusChange = data.comment.taskAuditList[i].task_audit_desc + ' On ' + data.comment.taskAuditList[i].updated_on
                                /*  + 'to'+ (data.comment.taskAuditList[i].task_status == 'A'? 'Action Required'
                                : data.comment.taskAuditList[i].task_status == 'I' ? 'Incomplete' : 'Complete') + 'On' + data.comment.taskAuditList[i].updated_on */
                            }
                            //vm.comment.taskAuditList = data.comment.taskAuditList;
                        }
                    }

                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                }
            });
            $scope.expanded = true;
        }
        /* donwload the file 7/24/2019 */
        vm.downloadAttachmentFile = function (fileName, commentKey) {
            MessageFactory.downloadMsgAttachment(fileName, commentKey);
        }


        /* Comment Reply  */
        vm.commentReply = function (message, msgtype) {
            var params = {
                comment_key: message.comment_key,
                message: vm.replyMessage,
                type: msgtype.type == "add" ? 'add' : 'update'
            };
            MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.commentReply, params).then(function (data) {
                if (data.callSuccess && data.callSuccess === "1") {
                    vm.replyMessage = "";
                    //  vm.comment.replyList = data.replyList;
                    $scope.showMessageDetail(message);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                }

            })

        }
        vm.deleteRepliedMessages = function (message) {
            var objContext = {
                comment_key: message.comment_key,
                message: message.message,
                type: 'delete'
            };
            MessageFactory.CommentReplyCrud(GENERAL_CONFIG.base_url + URLS.commentReply, objContext).then(function (data) {
                vm.crudLoading = false;
                vm.comment.replyList = _.without(vm.comment.replyList,
                    _.find(vm.comment.replyList, {
                        comment_key: message.comment_key
                    }));
                if (data.callSuccess && data.callSuccess === "1") {} else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
                }
            });
        }

        vm.launchTaskBuoy = function (comment) {
            var _data = {};
            // console.log("vm.comment " , vm.comment);
            var location_obj = {
                col_key: comment.col_key,
                row_key: comment.row_key
            }
            var filter_json = JSON.parse(comment.filter_json);
            var table_state = JSON.parse(comment.table_state);
            GlobalService.setUserSession('task_comment_data',comment.data);
            workspaceFactory.loadLink("comment", comment, comment.activity_key, comment.screen_key, filter_json, table_state, location_obj);
        }
        /* drag & drop task from one list to another 7/22/2019 */
        $scope.onDrop = function (srcList, srcIndex, targetList, targetIndex, status,data, comment_key) {
            // Copy the item from source to target.
            targetList.splice(targetIndex, 0, srcList[srcIndex]);
            // Remove the item from the source, possibly correcting the index first.
            // We must do this immediately, otherwise ng-repeat complains about duplicates.
            if (srcList == targetList && targetIndex <= srcIndex)
                srcIndex++;
            srcList.splice(srcIndex, 1);

            if (srcList != targetList) {
                if (status && comment_key) {
                    var assign = _.map(data.assignList, 'sso_id').toString();
                    var  notify= _.map(data.notifyList, 'sso_id').toString();
                    var params = {
                        comment_key: data.comment_key,
                        status: status,
                        comment_status :  data.status,
                        assign : assign,
                        notify : notify,
                        task_old_status : data.task_status,
                    }
                    vm.updateTask(params);
                }
            }

            // By returning true from dnd-drop we signalize we already inserted the item.
            return true;
        };
        /* update the task from one state to another  */
        vm.updateTask = function (data) {
            var params = {
                comment_key: data.comment_key,
                task_status: data.status,
                comment_status :  data.status,
                assign : data.assign,
                notify : data.notify,
                task_old_status : data.task_old_status

            }
            params.comment_status = (data.comment_status === 'closed' && (data.status === 'I' || data.status === 'A')) ? "open" :
                                    (data.comment_status === 'open' && (data.status === 'I' || data.status === 'A')) ?"open":"closed";
            MessageFactory.getComments(GENERAL_CONFIG.base_url + URLS.updateTaskByKey, params).then(function (data) {
                if (data && data.callSuccess === "1") {
                    vm.loadTasks();

                    AlertService.add("success", "Task Status Updated Successfully", 4000);
                } else {
                    AlertService.add("error", "Problem while updating the Task", 4000);
                }

            })
        }
        $scope.idSelected = null;
        $scope.setSelected = function (idSelected) {
            $scope.idSelected = idSelected;
        };
    }

    return controllers;
});