define([
	'angular'
], function () {
	'use strict';

	var controllers = angular.module('app.basketMaintenanceDeleteCtrl',  [])
		.controller('basketMaintenanceDeleteCtrl', ['CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
			'GlobalService','USER_SETTINGS','basketMaintenanceFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', basketMaintenanceDeleteCtrl]);


	function basketMaintenanceDeleteCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout,
						   GlobalService,USER_SETTINGS,basketMaintenanceFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
		var vm = this;
		vm.deleteBasket = function(){
			var BASKET_ID = vm.rowData.BASKET_ID;
	            var basketJson = [{basket_id: vm.rowData.BASKET_ID}];
	        	//validateJson(paymentJson);
	        	vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
	        	var success_message = "The  Basket was successfully deleted";
	        	console.log("The save json data was :::::",basketJson);
	        	basketMaintenanceFactory.deleteBasket(basketJson, vm.clob_settings, vm.logged_in_user).then(function(data){
	        		console.log("Result from rep Trans save data was ---------------::::", data);
	        		if(null != data.errorMessage && data.errorMessage != "null"){
	        			console.log("There was the error message and the message is ",data.errorMessage);
	        			AlertService.add("error", data.errorMessage, 4000);
	        		}else {
	        			console.log("There wa no error message and the message was ",data.errorMessage);
	        			AlertService.add("success", success_message, 4000);
	        			$uibModalInstance.dismiss('cancel');
	        			var args = {basket_id: rowData.BASKET_ID, gridFilter: {basket_id: rowData.BASKET_ID}};
	        			$rootScope.$emit('gridUpdate', args);
	        		}
	        	});
	        }
		
		
        vm.rowData = rowData ||$scope.$parent.rowData;
        vm.colData = colData;
        vm.gridData = gridData;
        vm.globalParams = GlobalService.globalParams;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        console.log("Here is the starting point and the rowData was ::::::::", vm.rowData);
        console.log("Here is the starting point and the global Params was ::::::::", vm.globalParams);
      //  vm.ho_leid = vm.rowData.HO_LEID;
      //  vm.ho_cdr_no = vm.rowData.HO_CDR_NO;
       vm.basket_id = vm.rowData.BASKET_ID;
		//vm.adjAmts = [];
        vm.modalTitle = "Delete Basket";
        vm.isDelete = true;
        
        vm.cancel = function () {
			$scope.crudLoading = false;
    		console.log("in cancel");
    		$uibModalInstance.dismiss('cancel');
        };
	}
	  }); 