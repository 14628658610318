

define([
    'angular',
    './ghostSystemService',
    './ghostSystemController'

], function () {
    'use strict';
    return angular.module('app.ghostSystemStatus', ['app.ghostSystemCtrl','app.ghostSystemService'] )

});
