define([
    'angular'

], function () {
    'use strict';


    var services = angular.module('app.bizzHierServices', [])

        .factory("BizzHierFactory", ['$q', '$http', '$log', 'GENERAL_CONFIG', 'GlobalService', 'AlertService', 'USER_SETTINGS', '$rootScope', function ($q, $http, $log, GENERAL_CONFIG, GlobalService, AlertService, USER_SETTINGS, $rootScope) {
            var bizzHierFactory = {};


            bizzHierFactory.getBizzHierarchyTree = function (hierarchyId, taxYear, scenario , jcd) {
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/getBusinessHeirarchyTree?hierarchyId=' + hierarchyId + '&taxYear=' + taxYear + '&scenario=' + scenario + '&jcdKey=' + jcd
                }).then(function (response) {
                    // if(response.data.callSuccess === "1" ){
                    return response.data;
                    // }else {
                    //     if(response.data.errorMessage === "no access"){
                    //         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    //     }else{
                    //         AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    //     }
                    // }
                });
                return promise;
            }

            bizzHierFactory.getScenariosJcd = function (taxYear) {
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/getScenariosJCDs?taxYear=' + taxYear
                }).then(function (response) {
                    // if(response.data.callSuccess === "1" ){
                    return response.data;
                    // }else {
                    //     if(response.data.errorMessage === "no access"){
                    //         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    //     }else{
                    //         AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    //     }
                    // }
                });
                return promise;
            }

            bizzHierFactory.syncGoldBusinessTree = function (taxYear, scenarioId, jcdKey) {
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/syncGoldHierarchy?taxYear=' + taxYear + '&scenario=' + scenarioId + '&jcd=' + jcdKey
                }).then(function (response) {
                    if (response.data.callSuccess === "1") {
                        AlertService.add("success", "Gold synced successfully", 3000);
                        return response.data;
                    } else {

                        AlertService.add("error", response.data.errorMessage, 4000);
                    }
                });
                return promise;
            }

            bizzHierFactory.getHierachyList = function (taxYear, scenarioId, jcdKey, appCode) {
                var promise = $http({
                    method: "POST",
                    // url: '/gtw/loadHierarchyTreeValue?taxYear='+taxYear,'
                    // url: 'http://localhost:8085/gtw/loadHierarchyTreeValues?taxYear='+taxYear,
                    // url: '/gtw/loadHierarchyTreeValue,
                    url: GENERAL_CONFIG.admin_api_url + '/getHierarchiesForApp?taxYear=' + taxYear + '&scenarioId=' + scenarioId + '&jcdKey=' + jcdKey + '&appCode=' + appCode,
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }
            bizzHierFactory.getAllApps = function () {
                var promise = $http({
                    method: "POST",
                    // url: '/gtw/loadHierarchyTreeValue?taxYear='+taxYear,'
                    // url: 'http://localhost:8085/gtw/loadHierarchyTreeValues?taxYear='+taxYear,
                    // url: '/gtw/loadHierarchyTreeValue,
                    url: GENERAL_CONFIG.admin_api_url + '/getAllApps',
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            bizzHierFactory.createHierarchy = function (srcHierarchyId, newHierarchyName, appCode, taxYear, scenarioId, jcdKey) {
                var promise = $http({
                    method: "POST",
                    // url: '/gtw/saveHierarchyTreeType?type='+data,
                    url: GENERAL_CONFIG.admin_api_url + '/createHierarchy?&srcHierarchyId=' + srcHierarchyId + '&newHierarchyName=' + newHierarchyName + '&appCode=' + appCode + '&taxYear=' + taxYear + '&scenarioId=' + scenarioId + '&jcdKey=' + jcdKey,
                }).then(function (response) {
                    return response.data;

                });
                return promise;
            }

            bizzHierFactory.uploadExcelBizzHierarchy = function(fileName, taxYear, scenarioId, jcdKey, hierarchyName, channelKey, skipHeader) {
                var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.admin_api_url + '/uploadExcelBizzHierarchy?&fileName=' + fileName + '&taxYear=' + taxYear + '&scenarioId=' + scenarioId + '&jcdKey=' + jcdKey + '&hierarchyName=' + hierarchyName + '&channelKey=' + channelKey + '&skipHeader=' + skipHeader
                }).then(function (response) {
                    return response;
                });

                return promise;
            }

            bizzHierFactory.uploadTemplate = function(flowFilename, file) {
                var fd = new FormData();
                fd.append('file', file);
                var promise = $http.post(GENERAL_CONFIG.base_url + '/uploadTemplate?&flowFilename=' +  flowFilename, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                }).then(function (response) {
                    return response;
                });

                return promise;
            }

            bizzHierFactory.addBusinessUnit = function (
                meCode,
                meNonGoldName,
                scenarioId,
                meParentKey,
                taxYear,
                jcdKey,
                hierarchyId,
                line_of_business,
                detail_bsla_ind,
                estDataCollLvl) {

                var params = {
                    "meCode": meCode,
                    "meGoldName": 'None',
                    "meNonGoldName": meNonGoldName,
                    "refreshIndicator": 'N',
                    "srcSystemFlag": 'TAX',//OR 'GOLD'?
                    "scenarioId": scenarioId,
                    "meParentKey": meParentKey,
                    "status": 'A',
                    "taxYear": taxYear,
                    "jcdKey": jcdKey,
                    "hierarchyId": hierarchyId,
                    "line_of_business": line_of_business,
                    "detail_bsla_ind": detail_bsla_ind,
                    "estDataCollLvl": estDataCollLvl
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/addBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            bizzHierFactory.editBusinessUnit = function (
                meKey,
                groupObjKey,
                description,
                bUCode,
                line_of_business,
                detail_bsla_ind,
                srcHierarchyId,
                hierarchyId,
                taxYear, 
                estDataCollLvl, 
                jcdKey, 
                scenarioId) {

                var params = {
                    "meKey": meKey,
                    "groupObjKey": groupObjKey,
                    "description": description,
                    "bUCode": bUCode,
                    "line_of_business": line_of_business,
                    "detail_bsla_ind": detail_bsla_ind,
                    "srcHierarchyId": srcHierarchyId,
                    "hierarchyId": hierarchyId,
                    "taxYear": taxYear,
                    "estDataCollLvl": estDataCollLvl,
                    "jcdKey": jcdKey,
                    "scenarioId": scenarioId,
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/editBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            bizzHierFactory.moveBusinessHierarchy = function (
                meKey,
                meParentKey,
                srcHierarchyId,
                descHierarchyId
            ) {

                var params = {
                    "meKey":meKey,
                    "meParentKey":meParentKey,
                    "srcHierarchyId":srcHierarchyId,
                    "descHierarchyId":descHierarchyId,
                     "tax_year":GlobalService.globalParams.tax_year,
                    "scenario":GlobalService.globalParams.scenario,
                    "jcd_key":GlobalService.globalParams.jcd_key
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/moveBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            bizzHierFactory.deleteBusinessUnit = function (
                meKey,
                meParentKey,
                groupObjKey,
                hierarchyId,
                taxYear, 
                jcdKey, 
                scenarioId) {

                var params = {
                    "meKey": meKey,
                    "meParentKey": meParentKey,
                    "groupObjKey": groupObjKey,
                    "hierarchyId": hierarchyId,
                    "taxYear": taxYear,
                    "jcdKey": jcdKey,
                    "scenarioId": scenarioId
                };
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.admin_api_url + '/deleteBusinessHierarchy',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            bizzHierFactory.getDefaultHierarchy = function(appCode , taxYear , scenarioId , jcdKey){
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/hierarchy/getDefault?appCode=' + appCode + '&taxYear=' + taxYear + '&scenarioId=' + scenarioId + '&jcdKey=' + jcdKey
                }).then(function (response) {
                    // if(response.data.callSuccess === "1" ){
                    return response.data;
                    // }else {
                    //     if(response.data.errorMessage === "no access"){
                    //         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    //     }else{
                    //         AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    //     }
                    // }
                });
                return promise;
            }

            bizzHierFactory.assignBizzHierarchy = function(hierarchyId){
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/hierarchy/assign?hierarchyId=' + hierarchyId
                }).then(function (response) {
                    // if(response.data.callSuccess === "1" ){
                    return response.data;
                    // }else {
                    //     if(response.data.errorMessage === "no access"){
                    //         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    //     }else{
                    //         AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    //     }
                    // }
                });
                return promise;
            }

            return bizzHierFactory;
        }])




    return services;


});
