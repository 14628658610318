define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.f1118SchdCController', [])
            .controller('f1118SchdCCtrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',f1118SchdCCtrl]
            );

        function f1118SchdCCtrl($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.userSettings = USER_SETTINGS;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
            vm.globalParams = JSON.stringify(GlobalService.globalParams);
            vm.checkLockeOdbj =[];
            vm.filterParams = filterParams;
            vm.rowDataSchdC = gridData.length == 0? {} : JSON.stringify(gridData.rowData);;
            vm.panelHeader = 'Schedule C Details';

            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = filterParams.scenario;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.combination_key = filterParams.filer_entity_key;
                    vm.checkLockeOdbj.push({"rowData": gridData.rowData, "scenario_key":vm.scenario});

                    console.log(vm.tax_year , ' : ', vm.scenario, ' : ', vm.jcd_key, ' : ', vm.logged_in_user , ' : ', vm.screen_key , ' : ', vm.combination_key , ' : ', vm.filterParams);
                    console.log('scenarioCode:' + vm.scenarioCode + ' is_issue_key_enabled: ' + GlobalService.globalParams.is_issue_key_enabled);
                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);

                    const html = `<gtw-f1118-schd-c-ce id="open-sourcing-basket-list" base-urls="{{ctrl.baseURLs}}" global-params="{{ctrl.globalParams}}" filter-params="{{ctrl.filterParams}}" tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" screen-key="{{ctrl.screen_key}}" sso-id="{{ctrl.logged_in_user}}" cc-key="{{ctrl.combination_key}}"  scenario-code = "{{ctrl.scenarioCode}}" check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" row-data = "{{ctrl.rowDataSchdC}}" schd-type = "F1118_C" panel-header = "{{ctrl.panelHeader}}" class="gtw-web-components"></gtw-f1118-schd-c-ce>`;
                    vm.webComponentHtml = html;

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {
                	$window.document.querySelector('gtw-f1118-schd-c-ce')
                           .addEventListener('open-f1118-schd-c', (event)=>{
                               console.log('event',event);
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {

                        $window.document.querySelector('gtw-f1118-schd-c-ce')
                            .addEventListener('save-f1118-schd-c', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-f1118-schd-c'){
                                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added ...');
                    },3000
                );

                $scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
);
