define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.InterCompanyDiagnCtrl', [])
        .controller('interCompanytrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse','workspaceFactory'
            , 'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', interCompanytrl]);


    function interCompanytrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory
        , AlertService, CommonUtilitiesFactory, GENERAL_CONFIG) {
            var vm = this;
            vm.tableData = [
                {
                   "actt_dtls":"12001610-OTHER CURRENT ASSESTS",
                   "net_amount":"635,326"
                },
                {
                   "actt_dtls":"1620100-INVESTMENT IN ASSCICIATED COMP",
                   "net_amount":"390,293,461"
                },

             ]
    }
});