define([
    'angular',
    './jobConsoleCtrl',
    './jobConsoleService'
], function () {
    'use strict';
    return angular.module('app.jobConsole', ['app.jobConsoleCtrl', 'app.jobConsoleService'])
    .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
        $stateProvider
            .state('admin.job-console-list', {
                url: '/job-console-list',
                templateUrl: 'app/components/admin/jobConsole/job-console-list-tpl.html',
                // backdrop: 'static',
                // controller: 'jobConsoleCtrl as ctrl',
                type:'grid',
                api:{"get":GENERAL_CONFIG.base_url + "/loadGridJson?action_id=32584&grid_id=5898&tax_year=2020"},
                noFilters:true,
                access: 4
            })
    }]);
});