define([
    'angular',
    './schedule-GCtrl',
    './schedule-GService',
    ], function () {
    'use strict';
    return angular.module('app.FIR_5471_Schedule_G', ['app.schedule-GCtrl', 'app.schedule-GService'] );
});



