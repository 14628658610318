define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.maintainanceOwnerController', [])
            .controller('maintainanceOwnerController', ['$rootScope', '$scope', '$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory', 'maintainanceOwnerServiceFactory',
                'rowData', 'gridData', maintainanceOwnerController])

                .controller('maintainanceOwnerDeleteCtrl', [ 'CommonUtilitiesFactory', '$filter','$scope','$rootScope', 'ModalFactory', 'AlertService', '$timeout',
                'GlobalService','USER_SETTINGS','maintainanceOwnerServiceFactory','$uibModalInstance','JsonObjectFactory','GENERAL_CONFIG','rowData', 'colData', 'gridData', maintainanceOwnerDeleteCtrl]);

        function maintainanceOwnerController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, maintainanceOwnerServiceFactory, rowData, gridData) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.userSettings = USER_SETTINGS;
            vm.tax_year = filterParams.tax_year;
            vm.scenario = filterParams.scenario;
            vm.jcd_key = GlobalService.globalParams.jcd_key;
            vm.logged_in_user = vm.userSettings.user.sso_id;

            vm.currentyear = filterParams.tax_year;
            vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
            vm.isSaveClicked = false;
            vm.syncSaveDtls = [];
            vm.maintainanceOwner = [];
            vm.basketObject = {
                ID : 1,
                BASKET_NAME : "", 
                DM_FI_STAT : "", 
                POST_BASKET_NAME : ""
            };

            if(gridData != null) {

                vm.gridDataMultipleSelect =  _.clone(gridData);

                if (vm.gridDataMultipleSelect != undefined) {

                    for(let key in vm.gridDataMultipleSelect) {
                        let gridObj = {};
                        gridObj["combination_key"] = vm.gridDataMultipleSelect[key].data.COMBINATION_KEY;
                        vm.syncSaveDtls.push(gridObj);
                    }
                }
            }

            vm.addNew = function (){
                //vm.basketObject.ID = vm.basketPost.length;
                vm.maintainanceOwner.push({
                    ID : vm.maintainanceOwner.length,
                    PARENT_ME_CODE : "", 
                    MAINTENANCE_OWNER : ""
                });
            };

            vm.deleteRow = function(i){
                vm.maintainanceOwner.splice(i, 1)
            }

            vm.saveMaintainanceOwner = function() {
                console.log(vm.basketPost);
                vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
                maintainanceOwnerServiceFactory.saveMaintainanceOwner(vm.maintainanceOwner, vm.clob_settings).then(function (data){
                    if(data.data.callSuccess == '1'){
                        vm.crudLoading = false;
                        AlertService.add("success", "Data added successfully", 4000);
                        $uibModalInstance.dismiss('cancel');
                        var args = {};
                        $rootScope.$emit('gridUpdate', args);
                    }else {
                        vm.crudLoading = false;
                        AlertService.add("error", data.data.errorMessage, 4000);
                    }
                });
            }

            vm.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        function maintainanceOwnerDeleteCtrl(CommonUtilitiesFactory, $filter,$scope,$rootScope,  ModalFactory, AlertService, $timeout, GlobalService,USER_SETTINGS,maintainanceOwnerServiceFactory, $uibModalInstance,JsonObjectFactory, GENERAL_CONFIG,rowData, colData, gridData) {
            var vm = this;
            vm.rowData = rowData ||$scope.$parent.rowData;
            vm.colData = colData;
            vm.gridData = gridData;
            vm.globalParams = GlobalService.globalParams;
            vm.userSettings = USER_SETTINGS;
            vm.logged_in_user = vm.userSettings.user.sso_id;
            vm.ho_leid = vm.rowData.HO_LEID;
            vm.ho_cdr_no = vm.rowData.HO_CDR_NO;
            vm.ho_combination_key = vm.rowData.HO_COMBINATION_KEY;
            vm.adjAmts = [];
            vm.modalTitle = "Delete Maintenance Owner";
            vm.isDelete = true;

            vm.deletePayment = function(){
                var RP_SEQ_ID = vm.rowData.RP_SEQ_ID;
                var paymentJson = [{rp_seq_id: vm.rowData.RP_SEQ_ID}];
                vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
                var success_message = "Maintenance owner map deleted successfully.";
                maintainanceOwnerServiceFactory.deletePayment(vm.rowData, vm.clob_settings, vm.logged_in_user).then(function(data){
                    if(null != data.data.errorMessage && data.data.errorMessage != "null"){
                        AlertService.add("error", data.data.errorMessage, 4000);
                    }else {
                        AlertService.add("success", success_message, 4000);
                        $uibModalInstance.dismiss('cancel');
                        var args = {combination_key: rowData.HO_COMBINATION_KEY, gridFilter: {combination_key: rowData.HO_COMBINATION_KEY},object_id:vm.rowData.object_id,action:"delete"};
                        $rootScope.$emit('gridUpdate', args);
                    }
                });
            }

            vm.cancel = function () {
                $scope.crudLoading = false;
                console.log("in cancel");
                $uibModalInstance.dismiss('cancel');
            };
        }

        return controllers;
    }
);