define(
    ['angular'],
    function(){
        'use strict';
        let services =  angular.module('app.maintainanceOwnerService',[])
            .factory('maintainanceOwnerServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService', '$injector','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, $injector, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){

                let maintainanceOwnerServiceFactory = {};
                maintainanceOwnerServiceFactory.url = {};

                maintainanceOwnerServiceFactory.saveMaintainanceOwner = function(final_data, clob_settings) {
                    console.log("Clob_setting was done as:::::::::------------", clob_settings);
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=2dg949";
                    var clob_settings = JSON.stringify(clob_settings);
                    var clob_data = JSON.stringify(final_data);
                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    
                    var params = filterParams;
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({clob_data: clob_data}, params);
                    params =  _.extend({clob_settings: clob_settings}, params);
                    params.process_name =  "Basket Post 965";
                    console.log(params);
                    var promise = $http({
                        method: "post",
                        url: url,
                        data: params
                    }).then(function (response) {
                        var status = response.status;
                        return response;
                    });
                    return promise;
                };

                maintainanceOwnerServiceFactory.deletePayment = function(rowData, clob_settings, sso_id){
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=rtt2wc";
                    var jsonSettings = JSON.stringify(clob_settings);
                    var clob_data = JSON.stringify(rowData);
                    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                    var params = filterParams;
       
                    params = _.merge({},  GlobalService.globalParams , params);
                    params =  _.extend({clob_data: clob_data}, params);
                    //params =  _.extend({jsonObj:jsonObj}, params);
                    // params =  _.extend({jsonSettings:jsonSettings}, params);
                    params.filing_key = workspaceFactory.activeScreen.filters.filterParams.filing_key;
                    params.sso_id = sso_id;
                    //params.rp_seq_id = paymentData[0].rp_seq_id;
                    //params.filing_group =  1;
                    console.log(params);
                    var promise = $http({
                            method: "post",
                           url: url,
                           data: params
                        }).then(function (response) {
                            var status = response.status;
                            return response;
                        });
                    return promise;
                }

                return maintainanceOwnerServiceFactory;
            }])
        return services;
    }
);