define([
    'angular',
    './basketPostController',
    './basketPostService'



], function () {
    'use strict';
    return angular.module('app.basketPost', ['app.basketPostController','app.basketPostService'] );

});