define([
    'angular',
    './RecommendedAutomationsController',
    './RecommendedAutomationsService'



], function () {
    'use strict';
    return angular.module('app.recommendedAutomations', ['app.recommendedAutomationsController','app.recommendedAutomationsService'] );


});
