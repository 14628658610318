define([
   'angular'

], function () {
   'use strict';

   var controllers =  angular.module('app.sysFormChartAcctMapController',[]);

   controllers.controller('SysFormChartAcctMapController',
    ['$rootScope', '$scope', '$http', '$timeout', 'GlobalService', '$uibModalInstance', 'ModalFactory',
       'AlertService','JsonObjectFactory','SystemGroupObjFactory','rowData', 'GENERAL_CONFIG',sysFormChartAcctMapController
   ])

 var lookupData = [];
   var lookupData1 = [];
   var lookupData2 = [];
   var lookupData3 = [];
   var lookupData4 = [];
   function format(value, replace) {
		if (!value) {
			return value;
		}
		var target = value.toString();
		if (replace === undefined) {
			return target;
		}
		if (!angular.isArray(replace) && !angular.isObject(replace)) {
			return target.split('$0').join(replace);
		}
		var token = (angular.isArray(replace) && '$') || ':';

		angular.forEach(replace, function(value, key) {
			target = target.split(token + key).join(value);
		});
		return target;
	}

   controllers.value('customSelectDefaultsSystemForm', {
		displayText: 'Select...',
		emptyListText: 'There are no items to display',
		emptySearchResultText: 'No results match "$0"',
		addText: 'Add',
		searchDelay: 300,
	});

   controllers.directive('customSelectSystemForm', [
		'$parse',
		'$compile',
		'$timeout',
		'$q',
		'customSelectDefaultsSystemForm',
		function($parse, $compile, $timeout, $q, baseOptions) {
			var CS_OPTIONS_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

			return {
				restrict: 'A',
				require: 'ngModel',
				link: function(scope, elem, attrs, controller) {
					var customSelect = attrs.customSelectSystemForm;
					if (!customSelect) {
						throw new Error('Expected custom-select attribute value.');
					}
					scope.$watch("ctrl.sys_acc.SYS_ACCT.SYS_ACCT",function(){
						scope.$emit('sysForm.SYS_ACCT_CHANGED', '');
					});
					var match = customSelect.match(CS_OPTIONS_REGEXP);
					if (!match) {
						throw new Error(
							'Expected expression in form of ' +
								"'_select_ (as _label_)? for _value_ in _collection_[ track by _id_]'" +
								" but got '" +
								customSelect +
								"'."
						);
					}

					elem.addClass('dropdown custom-select');

					// Ng-Options break down
					var displayFn = $parse(match[2] || match[1]),
						valueName = match[3],
						valueFn = $parse(match[2] ? match[1] : valueName),
						values = match[4],
						valuesFn = $parse(values),
						track = match[5],
						trackByExpr = track ? ' track by ' + track : '',
						dependsOn = attrs.csDependsOn;

					var options = getOptions(),
						timeoutHandle,
						lastSearch = '',
						focusedIndex = -1,
						matchMap = {};

					var itemTemplate =
							elem.html().trim() || '{{' + (match[2] || match[1]) + '}}',
						dropdownTemplate =
							'<a class="dropdown-toggle" data-toggle="dropdown" href ng-class="{ disabled: disabled }">' +
							'<span>{{displayText}}</span>' +
							'<b></b>' +
							'</a>' +
							'<div class="dropdown-menu">' +
							'<div stop-propagation="click" class="custom-select-search">' +
							'<input class="' +
							attrs.selectClass +
							'" type="text" autocomplete="off" ng-model="searchTerm" />' +
							'</div>' +
							'<ul role="menu">' +
							'<li role="presentation" ng-repeat="' +
							valueName +
							' in matches' +
							trackByExpr +
							'">' +
							'<a role="menuitem" tabindex="-1" href ng-click="select(' +
							valueName +
							')">' +
							itemTemplate +
							'</a>' +
							'</li>' +
							'<li ng-hide="matches.length" class="empty-result" stop-propagation="click">' +
							'<em class="muted">' +
							'<span ng-hide="searchTerm">{{emptyListText}}</span>' +
							'<span class="word-break" ng-show="searchTerm">{{ format(emptySearchResultText, searchTerm) }}</span>' +
							'</em>' +
							'</li>' +
							'</ul>' +
							'<div class="custom-select-action">' +
							(typeof options.onAdd === 'function'
								? '<button type="button" class="btn btn-primary btn-block add-button" ng-click="add()">{{addText}}</button>'
								: '') +
							'</div>' +
							'</div>';

					// Clear element contents
					elem.empty();

					// Create dropdown element
					var dropdownElement = angular.element(dropdownTemplate),
						anchorElement = dropdownElement.eq(0).dropdown(),
						inputElement = dropdownElement.eq(1).find(':text'),
						ulElement = dropdownElement.eq(1).find('ul');

					// Create child scope for input and dropdown
					var childScope = scope.$new(true);
					configChildScope();

					// Click event handler to set initial values and focus when the dropdown is shown
					anchorElement.on('click', function(event) {
						if (childScope.disabled) {
							return;
						}
						childScope.$apply(function() {
							lastSearch = '';
							childScope.searchTerm = '';
						});

						focusedIndex = -1;
						inputElement.focus();

						// If filter is not async, perform search in case model changed
						if (!options.async) {
							getMatches('');
						}
					});

					if (dependsOn) {
						scope.$watch(dependsOn, function(newVal, oldVal) {
							if (newVal !== oldVal) {
								childScope.matches = [];
								childScope.select(undefined);
							}
						});
					}

					// Event handler for key press (when the user types a character while focus is on the anchor element)
					anchorElement.on('keypress', function(event) {
						if (!(event.altKey || event.ctrlKey)) {
							anchorElement.click();
						}
					});

					// Event handler for Esc, Enter, Tab and Down keys on input search
					inputElement.on('keydown', function(event) {
						if (!/(13|27|40|^9$)/.test(event.keyCode)) return;
						event.preventDefault();
						event.stopPropagation();

						switch (event.keyCode) {
							case 27: // Esc
								anchorElement.dropdown('toggle');
								break;
							case 13: // Enter
								selectFromInput();
								break;
							case 40: // Down
								focusFirst();
								break;
							case 9: // Tab
								anchorElement.dropdown('toggle');
								break;
						}
					});

					// Event handler for Up and Down keys on dropdown menu
					ulElement.on('keydown', function(event) {
						if (!/(38|40)/.test(event.keyCode)) return;
						event.preventDefault();
						event.stopPropagation();

						var items = ulElement.find('li > a');

						if (!items.length) return;
						if (event.keyCode == 38) focusedIndex--; // up
						if (event.keyCode == 40 && focusedIndex < items.length - 1)
							focusedIndex++; // down
						if (focusedIndex >= 0) {
							items.eq(focusedIndex).focus();
						} else {
							focusedIndex = -1;
							inputElement.focus();
						}
					});

					resetMatches();

					// Compile template against child scope
					$compile(dropdownElement)(childScope);
					elem.append(dropdownElement);

					// When model changes outside of the control, update the display text
					controller.$render = function() {
						setDisplayText();
					};

					// Watch for changes in the default display text
					childScope.$watch(getDisplayText, setDisplayText);

					childScope.$watch(
						function() {
							return elem.attr('disabled');
						},
						function(value) {
							childScope.disabled = value;
						}
					);

					childScope.$watch('searchTerm', function(newValue) {
						if (timeoutHandle) {
							$timeout.cancel(timeoutHandle);
						}

						var term = (newValue || '').trim();
						timeoutHandle = $timeout(
							function() {
								getMatches(term);
							},
							// If empty string, do not delay
							(term && options.searchDelay) || 0
						);
					});

					// Support for autofocus
					if ('autofocus' in attrs) {
						anchorElement.focus();
					}

					var needsDisplayText;
					function setDisplayText() {
						console.log(controller,"controller");
						var locals = {};
						locals[valueName] = controller.$modelValue;
						var text = displayFn(scope, locals);
						if (text === undefined) {
							var map = matchMap[hashKey(controller.$modelValue)];
							if (map) {
								text = map.label;
							}
						}

						needsDisplayText = !text;
						childScope.displayText = text || controller.$modelValue // text || options.displayText;
					}

					function getOptions() {
						return angular.extend(
							{},
							baseOptions,
							scope.$eval(attrs.customSelectOptionsSf)
						);
					}

					function getDisplayText() {
						options = getOptions();
						return options.displayText;
					}

					function focusFirst() {
						var opts = ulElement.find('li > a');
						if (opts.length > 0) {
							focusedIndex = 0;
							opts.eq(0).focus();
						}
					}

					// Selects the first element on the list when the user presses Enter inside the search input
					function selectFromInput() {
						var opts = ulElement.find('li > a');
						if (opts.length > 0) {
							var ngRepeatItem = opts.eq(0).scope();
							var item = ngRepeatItem[valueName];
							childScope.$apply(function() {
								childScope.select(item);
							});
							anchorElement.dropdown('toggle');
						}
					}

					function getMatches(searchTerm) {
						var locals = { $searchTerm: searchTerm };
						$q.when(valuesFn(scope, locals)).then(
							function(matches) {
								if (!matches) return;

								if (searchTerm === inputElement.val().trim() /* && hasFocus*/) {
									matchMap = {};
									childScope.matches.length = 0;
									for (var i = 0; i < matches.length; i++) {
										locals[valueName] = matches[i];
										var value = valueFn(scope, locals),
											label = displayFn(scope, locals);

										matchMap[hashKey(value)] = {
											value: value,
											label: label,
										};

										childScope.matches.push(matches[i]);
									}
								}

								if (needsDisplayText) setDisplayText();
							},
							function() {
								resetMatches();
							}
						);
					}

					function resetMatches() {
						childScope.matches = [];
						focusedIndex = -1;
					}

					function configChildScope() {
						childScope.addText = options.addText;
						childScope.emptySearchResultText = options.emptySearchResultText;
						childScope.emptyListText = options.emptyListText;

						childScope.select = function(item) {
							var locals = {};
							locals[valueName] = item;
							var value = valueFn(childScope, locals);
							childScope.displayText =
								displayFn(childScope, locals) || options.displayText;
							controller.$setViewValue(value);
							anchorElement.focus();
							typeof options.onSelect === 'function' && options.onSelect(item);
						};

						childScope.add = function() {
							$q.when(options.onAdd(), function(item) {
								if (!item) return;

								var locals = {};
								locals[valueName] = item;
								var value = valueFn(scope, locals),
									label = displayFn(scope, locals);

								matchMap[hashKey(value)] = {
									value: value,
									label: label,
								};

								childScope.matches.push(item);
								childScope.select(item);
							});
						};

						childScope.format = format;
						setDisplayText();
					}

					var current = 0;
					function hashKey(obj) {
						if (obj === undefined) return 'undefined';

						var objType = typeof obj,
							key;

						if (objType == 'object' && obj !== null) {
							if (typeof (key = obj.$$hashKey) == 'function') {
								// must invoke on object to keep the right this
								key = obj.$$hashKey();
							} else if (key === undefined) {
								key = obj.$$hashKey = 'cs-' + current++;
							}
						} else {
							key = obj;
						}

						return objType + ':' + key;
					}
				},
			};
		},
	]);

   controllers.directive('stopPropagation', function() {
		return {
			restrict: 'A',
			link: function(scope, elem, attrs, ctrl) {
				var events = attrs['stopPropagation'];
				elem.bind(events, function(event) {
					event.stopPropagation();
				});
			},
		};
	});


   function sysFormChartAcctMapController ($rootScope,$scope,$http,$timeout,GlobalService,$uibModalInstance,ModalFactory,AlertService,JsonObjectFactory,SystemGroupObjFactory,rowData,GENERAL_CONFIG) {


	 var vm = this;
	 vm.sysacctloading = true;
	 vm.sysdropdownloading = true;
	 vm.accountsTableData = [];
	 function init() {
		 getSystemAcctdropdown();
         fetchSystemAcctLookup();
	 }
	 init();
	  $scope.crudLoading = false;
	 vm.sys_acc = {};
	 vm.orderByField = 'FORM';
     vm.reverseSort = false;
     vm.showDetails = {text:'lookup'};

     vm.sort_key = {'1':0,'2':0,'3':0,'4':0,'5':0};
     vm.lookUpMapping = false;

	 vm.selectedSysAcc = rowData.SYSTEM_ACCT;
	 vm.chartacct_data = {
			 localacct:rowData.LOCAL_ACCT_DISPLAY,
			 chart:rowData.CHART_DISPLAY,
			 systemacct:rowData.SYSTEM_ACCT,
			 systemacct_des:rowData.SYSTEM_ACCT_DESC,
			 acctType:rowData.ACCT_TYPE,
			 tdcCode:rowData.TDC_CODE
	 }
	 vm.sortBy = function(key,value){
                  	switch(value){
                  		case 0:
                  		vm.sort_key[key] = 1;
                  		vm.reverseSort = false;
                  		break;
                  		case 1:
                  		vm.sort_key[key] = 2;
                  		vm.reverseSort = true;
                  		break;
                  		case 2:
                  		vm.sort_key[key] = 0;
                  		vm.reverseSort = "";
                  		break;
                  	}
     }
	 /** This function hits the DB and retrieves System as well as Local Data **/
//
	 vm.cancel = function(){
     $uibModalInstance.dismiss('cancel');
	 	};
	 	vm.reset = function(){
	 		 $scope.crudLoading = false;
	 		 init();
	 		 vm.chartacct_data = {
					 localacct:rowData.LOCAL_ACCT_DISPLAY,
					 chart:rowData.CHART_DISPLAY,
	 				 systemacct:rowData.SYSTEM_ACCT,
	 				 systemacct_des:rowData.SYSTEM_ACCT_DESC,
                     acctType:rowData.ACCT_TYPE,
                     tdcCode:rowData.TDC_CODE
	 		 }
	    };

       vm.resetLookup = function(){
           vm.CHECK_FORM = '';
           vm.CHECK_SCHEDULE = '';
           vm.CHECK_LINE = '';
           vm.CHECK_COL = '';
           vm.CHECK_SYS_MAP_ACCT = '';
       };
      
	 vm.formLookupFlagContent = true;

       vm.toggleLookup = function () {
    	   vm.CHECK_FORM = "";
           vm.CHECK_SCHEDULE = "";
           vm.CHECK_LINE = ""
           vm.CHECK_COL = "";
           vm.CHECK_SYS_MAP_ACCT = "";
           vm.lookUpMapping = !vm.lookUpMapping;
          vm.showDetails.text = 'lookup';
          vm.changeddropdownflag = false;
       };

       function fetchSystemAcctData(){
	     	var params = {
	   			"action_code": '6hsjmn',
	   			"tax_year": rowData.TAX_YEAR,
	   			"local_acct": rowData.LOCAL_ACCT_KEY,
	   			"sys_acct": (typeof vm.sys_acc.SYS_ACCT.SYS_ACCT == "object") ? vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_KEY:vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY

	   			 }
	     	vm.sysacctloading = true;
	     	console.log("Input Data fetchSystemAcctData**********::",params);
	     	 return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
	        	 console.log("Response Data ::",data);
	             if (data.callSuccess === "1" ) {
	            	 vm.loadSystemAndLocalAccountData = data.jsonObject;
	            	 vm.sysacctloading = false;
	            	 console.log(data.jsonObject,"Check Here Y1");
                     console.log(vm.loadSystemAndLocalAccountData,"Check Here Y1");
	             }
	             else
	               {
	            	 if(data.errorMessage === "no access"){
	                   AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	               }
	            	 else
	            	 {
	                   AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
	                   vm.userMessage = "ERROR!! Unable to perform Selected Action...";
	                 }
	             }

	         });
	     };
	     
	 function  getSystemAcctdropdown(){
           var params = {
               "action_code": '9bappg',
               "tax_year": rowData.TAX_YEAR,
               "screen_key": rowData.SCREEN_KEY,
               "acct_type": rowData.ACCT_TYPES
           }
           vm.sysdropdownloading = true;
           return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
               if (data.callSuccess === "1") {
                   vm.sys_acc_dropdown_data = [];
                   for(var index_ in data.jsonObject ){
                       vm.sys_acc_dropdown_data.push(data.jsonObject[index_].SYS_DROPDOWN);
                   }
                   vm.sys_acc_dropdown_data_TEMP = data.jsonObject;
                   var index = _.findIndex( vm.sys_acc_dropdown_data_TEMP, { 'SYS_ACCT':rowData.SYSTEM_ACCT});
                   vm.sys_acc.SYS_ACCT = vm.sys_acc_dropdown_data_TEMP[index];
                   vm.sys_acc.SYS_ACCT_TEMP =  vm.sys_acc_dropdown_data_TEMP[index];
                   vm.CHECK_SYS_ACCT = vm.sys_acc_dropdown_data_TEMP[index].SYS_DROPDOWN;
                   vm.sysdropdownloading = false;
                   fetchSystemAcctData(vm.sys_acc.SYS_ACCT);

               }else{
                   if(data.errorMessage === "no access"){
                       AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                       vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                   }else{
                       AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                       vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                   }
               }

           });
       };

       function fetchSystemAcctLookup(){
           var params = {
               "action_code": '5gdshx',
               "tax_year": rowData.TAX_YEAR,
               "screen_key": rowData.SCREEN_KEY,
               "acct_type": rowData.ACCT_TYPES

           }
           console.log("Input Data x **********::",params);
           return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                console.log("Response Data x ::",data);
               if (data.callSuccess === "1" ) {

            	   vm.loadSystemAcctLookupTemp = data.jsonObject;
                   vm.loadLookupForm = [];
                   vm.loadLookupSchedule = [];
                   vm.loadLookupLine = [];
                   vm.loadLookupColumn = [];
                   vm.loadLookupSysMapAcct = [];
                   vm.loadLookupSysMapAcct1 = [];
                   vm.lookupcheckdata = data.jsonObject;
                   for(var index_ in data.jsonObject ){
                       vm.loadLookupForm.push(data.jsonObject[index_].FORM);
                   }
                   vm.loadLookupForm = _.uniq(vm.loadLookupForm, JSON.stringify)
                   //vm.CHECK_FORM = vm.loadLookupForm_TEMP[-1].FORM;
               }
               else
               {
                   if(data.errorMessage === "no access"){
                       AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                       vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                   }
                   else
                   {
                       AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                       vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                   }
               }

           });
       };

    /* $scope.$on('sysForm.SYS_ACCT_CHANGED', function (event, data) {
    	 vm.getChartAcctTableData();
     });
     vm.getChartAcctTableData = function (){
    	 var index = _.findIndex( vm.sys_acc_dropdown_data_TEMP, { 'SYS_DROPDOWN':vm.CHECK_SYS_ACCT});
    	 vm.sys_acc.SYS_ACCT.SYS_ACCT = (index != -1)?vm.sys_acc_dropdown_data_TEMP[index]:vm.sys_acc.SYS_ACCT_TEMP;
    	 fetchSystemAcctData(vm.sys_acc.SYS_ACCT.SYS_ACCT_KEY);
    	 vm.selectedSysAcc=vm.sys_acc.SYS_ACCT.SYS_ACCT;
    	 vm.chartacct_data.systemacct_des=(vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_DESC != undefined)?vm.sys_acc.SYS_ACCT.SYS_ACCT.SYS_ACCT_DESC:vm.sys_acc.SYS_ACCT.SYS_ACCT_DESC;

     }*/
     
     vm.formDelete =  function(_item){
         var index = _.findIndex(vm.loadSystemAndLocalAccountData, { 'FORM_ACCT_KEY': _item.FORM_ACCT_KEY });
                        //HERE WE WOULD CALL SERVICE DELETE
         var objSettings = [];
         var returnObj = {};
         returnObj.type = 'D';
         returnObj['FORM_ACCT_KEY']  =  _item.FORM_ACCT_KEY 
         returnObj["object_id"]= rowData.object_id
         returnObj['system_acct_key'] = rowData.SYS_ACCT_KEY;
         returnObj['tax_year'] = rowData.TAX_YEAR;
         returnObj['jcd_key'] =  GlobalService.globalParams.jcd_key; 
         returnObj['scenario'] = GlobalService.globalParams.scenario;
         returnObj['screen_key'] = GlobalService.globalParams.screen_key;
         var IRSFormDetails = angular.copy(vm.loadSystemAndLocalAccountData);
     }
     
		 vm.formEdit = function(_item,index){
				 	
			       vm.CHECK_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT; 
			       vm.SYSTEM_ACCT_KEY = _item.SYSTEM_ACCT_KEY; 
			       vm.FORM_ACCT_KEY = _item.FORM_ACCT_KEY; 
			       vm.old_form_acct_key = _item.FORM_ACCT_KEY;
			       vm.showDetails.text = 'edit';
			       vm.currentEditedIndex = index;
			       
			       vm.changeddropdownflag = true;
			       
			       
			       
			       vm.lookUpMapping = true;
			       vm.CHECK_FORM = _item.FORM;
			       var b = _item.FORM.trim();
			       vm.CHECK_SCHEDULE = _item.SCHEDULE;
			       var c =_item.SCHEDULE.trim();
			       vm.CHECK_LINE = _item.LINE+" - "+_item.LINE_DESC;
			       var d =_item.LINE+" - "+_item.LINE_DESC.trim();
			       vm.CHECK_COL = _item.COL;
			       vm.CHECK_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT;
			       vm.formEditFlagContent = true;
			        var testdata = vm.lookupcheckdata;
			       var nexttestLookupDataschedule =[];
			       var scheduletestvalue  = testdata.filter(function(object,index){
			          console.log(object,index)
			       if(object["FORM"] ==  b )
			       {
			               return true;
			       }
			       });
			
			     scheduletestvalue.forEach(function(object,index){
			        nexttestLookupDataschedule.push(object["SCHEDULE"]);
			     });
			      nexttestLookupDataschedule =  _.uniq(nexttestLookupDataschedule, JSON.stringify);
			      vm.loadLookupSchedule = nexttestLookupDataschedule;
			      var nexttestLookupDataLine =[];
			
				   var Linetestvalue  = testdata.filter(function(object,index){
					       console.log(object,index)
					    if(object["FORM"] ==  b  && object["SCHEDULE"] ==  c )
					    {
					            return true;
					    }
				   });
			
				Linetestvalue.forEach(function(object,index){
				    nexttestLookupDataLine.push(object["LINE"]);
				   });
			   nexttestLookupDataLine =  _.uniq(nexttestLookupDataLine, JSON.stringify);
			   vm.loadLookupLine = nexttestLookupDataLine;
			
			 var nexttestLookupDataCol =[];
			 var COltestvalue  = testdata.filter(function(object,index){
			     if(object["FORM"] ==  b  && object["SCHEDULE"] ==  c  && object["LINE"] == d)
			     {
			             return true;
			     }
			});
			
			 COltestvalue.forEach(function(object,index){
			     nexttestLookupDataCol.push(object["COL"]);
			   });
			    nexttestLookupDataCol =  _.uniq(nexttestLookupDataCol, JSON.stringify);
			  vm.loadLookupColumn= nexttestLookupDataCol;
          }
		 
		 vm.formAdd = function(){
			 vm.showDetails.text = 'add';
			 vm.lookUpMapping = true;
			 vm.changeddropdownflag = true;
		           
		           vm.CHECK_FORM = "";
		           vm.CHECK_SCHEDULE = "";
		           vm.CHECK_LINE = ""
		           vm.CHECK_COL = "";
		           vm.CHECK_SYS_MAP_ACCT = vm.CHECK_SYS_ACCT;
		 }
     
		 vm.save = function(){
			 
			 if ($scope.crudLoading) {
	                AlertService.add("info", "Please wait while we save this request", 4000);
		                return;
		            }
			  $scope.crudLoading = true;
			 
         if (vm.screenFrom.$invalid) {
             vm.screenFrom.$submitted = true;
             AlertService.add("danger", "Please correct the errors below", 4000);
             return;
         }

        $timeout(function(){
         var objSettings = [];
         var returnObj = {};
         returnObj.type =  (vm.showDetails.text == 'add')?'A':'E';
         returnObj["object_id"]= rowData.object_id
         returnObj['system_acct_key'] = rowData.SYS_ACCT_KEY;
         returnObj['tax_year'] = rowData.TAX_YEAR;
         returnObj['jcd_key'] =  GlobalService.globalParams.jcd_key; 
         returnObj['scenario'] = GlobalService.globalParams.scenario;
         returnObj['screen_key'] = GlobalService.globalParams.screen_key;

         var IRSFormDetails = angular.copy(vm.loadSystemAndLocalAccountData);
         var index  = _.findIndex(vm.loadSystemAcctLookupTemp, { 'FORM': vm.CHECK_FORM.trim(),'SCHEDULE':vm.CHECK_SCHEDULE.trim(),'LINE':vm.CHECK_LINE.trim(),'COL':vm.CHECK_COL.trim() })
         if(returnObj.type == 'A'){
        	 IRSFormDetails.push(vm.loadSystemAcctLookupTemp[index]);
         }
         
         if(vm.showDetails.text == 'add'){
        	 returnObj['FORM_ACCT_KEY'] = vm.loadSystemAcctLookupTemp[index].FORM_ACCT_KEY;
         }else if(vm.showDetails.text == 'edit'){
        	 returnObj['OLD_FORM_ACCT_KEY'] =  vm.old_form_acct_key;
        	 returnObj['NEW_FORM_ACCT_KEY'] =  vm.loadSystemAcctLookupTemp[index].FORM_ACCT_KEY;
        	 IRSFormDetails[vm.currentEditedIndex] = vm.loadSystemAcctLookupTemp[index];
         }
         
         var message = "IRS Form has been successfully save/updated";
         IRSFormCRUDHttp(returnObj,IRSFormDetails, message)
		 });
     };

     function IRSFormCRUDHttp(returnObj,IRSFormDetails, message) {
    	 if(returnObj.type == 'D'){ 
    		 SystemGroupObjFactory.deleteSystemFormDetails(returnObj,IRSFormDetails).then(function (result) {
                 if (result.errorMessage && result.errorMessage !== 'null') {
                     AlertService.add("error", result.errorMessage, 4000);
                     $scope.crudLoading = false;
                 } 
             })
    	 }else{
        	 SystemGroupObjFactory.saveIRSFormDetails(returnObj,IRSFormDetails).then(function (result) {
                 if (result.errorMessage && result.errorMessage !== 'null') {
                     AlertService.add("error", result.errorMessage, 4000);
                     $scope.crudLoading = false;
                 } else {
                     AlertService.add("success", message, 4000);
                     vm.loadSystemAndLocalAccountData = angular.copy(IRSFormDetails);
                     $scope.crudLoading = false;
                 }
             });
    	 }
         
     }
     
	     vm.getLookupNext = function(key,nextLookupKey,currentLookupValue){
		     var lookupData = angular.copy(vm.lookupcheckdata);
		     var a = "SYS_ACCT";
		     
		        switch(key){
		        case 1:
		             vm.loadLookupSchedule =  filterDataByKey("FORM",nextLookupKey,lookupData,currentLookupValue);
		             
		               break;
		        case 2:
		             vm.loadLookupLine =  filterDataByKey1("SCHEDULE",nextLookupKey,lookupData1,currentLookupValue);
		                                     break;
		              
		        case 3:
		        	vm.loadLookupColumn =  filterDataByKey2("LINE",nextLookupKey,lookupData2,currentLookupValue);
		
		                      vm.loadLookupSysMapAcct = filterDataByKey22("LINE",a,lookupData2,currentLookupValue);
		                      break;
		        case 4:
		        	vm.loadLookupSysMapAcct=  filterDataByKey3("COL",nextLookupKey,lookupData3,currentLookupValue);
		                      break;
		        case 5:
		        	vm.loadLookupSysMapAcct=  filterDataByKey4("SYS_ACCT",nextLookupKey,lookupData4,currentLookupValue);
		                      break;
		              }
	     }

		function filterDataByKey(currentLookupKey,nextLookupKey,lookupData,currentLookupValue){
			    var nextLookupData = [];
			    var index  = _.findIndex(vm.loadSystemAcctLookupTemp, { 'FORM': currentLookupValue })
			    vm.FORM_ACCT_KEY = vm.loadSystemAcctLookupTemp[index].FORM_ACCT_KEY;
		   	    var currentLookupFilter = lookupData.filter(function(object,index){
			    if(object[currentLookupKey] == currentLookupValue)
			    {
			    	    return true;
			    }
			    });
		
			   currentLookupFilter.forEach(function(object,index){
			        nextLookupData.push(object[nextLookupKey]);
			   });
			  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
			  lookupData1 = currentLookupFilter;
		   	  console.log(lookupData1 , "check Here IMPORTANT inside");
			  return nextLookupData ;
		}
	  
	function filterDataByKey1(currentLookupKey,nextLookupKey,lookupData1,currentLookupValue){
	    var nextLookupData = [];
   	    var currentLookupFilter = lookupData1.filter(function(object,index){
		    if(object[currentLookupKey] == currentLookupValue)
		    {
		    	    return true;
		    }
	    });

	   currentLookupFilter.forEach(function(object,index){
	        nextLookupData.push(object[nextLookupKey]);
	   });
	  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
	  lookupData2 = currentLookupFilter;
	  return nextLookupData ;
	}
	  
	function filterDataByKey2(currentLookupKey,nextLookupKey,lookupData2,currentLookupValue){
	    var nextLookupData = [];
   	    var currentLookupFilter = lookupData2.filter(function(object,index){
		    if(object[currentLookupKey] == currentLookupValue)
		    {
		    	    return true;
		    }
	    });

	   currentLookupFilter.forEach(function(object,index){
	        nextLookupData.push(object[nextLookupKey]);
	   });
	  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
	  lookupData3 = currentLookupFilter;
	  return nextLookupData ;
	}

	function filterDataByKey22(currentLookupKey,a,lookupData2,currentLookupValue){
        var nextLookupData = [];
      
        var currentLookupFilter = lookupData2.filter(function(object,index){
	        if(object[currentLookupKey] == currentLookupValue)
	        {
	                return true;
	        }
        });

       currentLookupFilter.forEach(function(object,index){
            nextLookupData.push(object[a]);
            
       });
      nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
      return nextLookupData ;
    }
	function filterDataByKey3(currentLookupKey,nextLookupKey,lookupData3,currentLookupValue){
	    var nextLookupData = [];
   	    var currentLookupFilter = lookupData3.filter(function(object,index){
		    if(object[currentLookupKey] == currentLookupValue)
		    {
		    	    return true;
		    }
	    });

	   currentLookupFilter.forEach(function(object,index){
	        nextLookupData.push(object[nextLookupKey]);
	   });
	  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
	  lookupData4 = currentLookupFilter;
	  return nextLookupData ;
	}
	
	function filterDataByKey4(currentLookupKey,nextLookupKey,lookupData4,currentLookupValue){
	    var nextLookupData = [];
   	    var currentLookupFilter = lookupData4.filter(function(object,index){
		    if(object[currentLookupKey] == currentLookupValue)
		    {
		    	    return true;
		    }
	    });

	   currentLookupFilter.forEach(function(object,index){
	        nextLookupData.push(object[nextLookupKey]);
	   });
	  nextLookupData =  _.uniq(nextLookupData, JSON.stringify);
   	  return nextLookupData ;
	}
     
     /*vm.applyChanges = function(){
        vm.CHECK_SYS_ACCT = vm.CHECK_SYS_MAP_ACCT;
        vm.getChartAcctTableData();
     }*/
     
     vm.formchanged = function(_item){
	 		vm.CHECK_SCHEDULE = null;
	 		vm.CHECK_LINE = null;
	 		vm.CHECK_COL = null;
	 		
	 		if( vm.changeddropdownflag != true)
	 		{
	 			vm.CHECK_SYS_MAP_ACCT= null;
	 		}
     };
     	
     vm.schedulechanged = function(_item){
 	 vm.CHECK_LINE = null;
 	 vm.CHECK_COL = null;
 	 if( vm.changeddropdownflag != true)
 		  {
 			vm.CHECK_SYS_MAP_ACCT= null;
 		  }
     };
     vm.linechanged = function(_item){
     vm.CHECK_COL = null;
 	 if( vm.changeddropdownflag != true)
 		  {
 			vm.CHECK_SYS_MAP_ACCT= null;
 		  }
     };
     
     vm.columnchanged = function(_item){
	     if( vm.changeddropdownflag != true)
	   	 {
	   		vm.CHECK_SYS_MAP_ACCT= null;
	   	 }
     };
   }
   return controllers;

});
