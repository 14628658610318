define([
    'angular'
], function () {
    'use strict';

    var services = angular.module('app.entityChangeLogJobRunService',[])
        .factory("entityChangeLogJobRunService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log','GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG', 'JsonObjectFactory', 'AlertService',
            function($q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG, JsonObjectFactory, AlertService) {

                var entityChangeLogJobRunService = {};

                entityChangeLogJobRunService.enableDisableClientForChangeLogRun = function (_callName, _data, isNew) {
                    var defer = $q.defer();
                    console.log("enableDisableGroupNotification"+_callName+"_"+_data);
                    var params = {
                        tax_year: GlobalService.globalParams.tax_year,
                        scenario: GlobalService.globalParams.scenario,
                        client_key: _data.rowData.CLIENT_KEY,
                        jcd_key: GlobalService.globalParams.jcd_key,
                        enable_disable_run: _data.rowData.CHANGE_LOG_RUN_ENABLED
                    }
                    var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=32967";
                    JsonObjectFactory.saveJSON(url,params).then(function(data){
                        if(data.callSuccess == "1"){
                            AlertService.add("success","Succesfully updated client for change log run.",4000);                            
                        }else{
                            AlertService.add("error", data.errorMessage);
                        }
                    },function(data){
                            defer.reject(data);
                    });
                    return defer.promise;
                }

                return entityChangeLogJobRunService;

        }]);

    return services;

});