define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.reviewadjController', [])
					.controller('reviewDtlsController', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','ReviewAdjServiceFactory', 'rowData', 'gridData', reviewDtlsController])

			
			function reviewDtlsController($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,ReviewAdjServiceFactory,rowData, gridData) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.syncSaveDtls = [];
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year;
				vm.modalName = ModalFactory.response.data.modalScreen.modal_name;
				vm.isSaveClicked = false;
				vm.ObjectIDLIST = [];
				
				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};

				vm.confirm_exclude_all = function() {
					vm.sendpopupData = [];
					var tmp_json = {};
					var all_object_id = gridData[0].data.OBJECT_ID;
					for ( var e in gridData) {
						tmp_json = {};
						tmp_json.TAX_YEAR = GlobalService.globalParams.tax_year;
						tmp_json.SCENARIO = GlobalService.globalParams.scenario;
						tmp_json.JCD_KEY = GlobalService.globalParams.jcd_key;
						
						//tmp_json.IS_EXCLUDED = "ALL YEAR EXCLUDED";
						//ALL YEARS , CURRENT YEAR , NOT EXCLUDED
						tmp_json.EXCLUDED_YEAR = "ALL YEARS";
						tmp_json.COMBINATION_KEY = gridData[e].data.COMBINATION_KEY;
						tmp_json.SCHDM_ID = gridData[e].data.SCHDM_ID;
						tmp_json.SCHDM_USER_DESC = gridData[e].data.SCHDM_USER_DESC;
						tmp_json.SCHDM_TYPE = gridData[e].data.SCHDM_TYPE;
						tmp_json.METHOD_OF_CALC = gridData[e].data.METHOD_OF_CALC;
						tmp_json.MULTIPLIER = gridData[e].data.MULTIPLIER;
						tmp_json.AMOUNT = gridData[e].data.AMOUNT;
						tmp_json.TRIGGER_ACCT_KEY = gridData[e].data.TRIGGER_ACCT_KEY;
						tmp_json.TRIGGER_CHART_KEY = gridData[e].data.TRIGGER_CHART_KEY;
						tmp_json.POSTING_ACCT_KEY = gridData[e].data.POSTING_ACCT_KEY;
						tmp_json.POSTING_TAC_KEY = gridData[e].data.POSTING_TAC_KEY;
						tmp_json.TBBS_ACCT_KEY = gridData[e].data.TBBS_ACCT_KEY;
						tmp_json.COUNTER_PARTY = gridData[e].data.COUNTER_PARTY;
						tmp_json.TBBS_ACCT_KEY = gridData[e].data.TBBS_ACCT_KEY;
						tmp_json.COUNTER_PARTY = gridData[e].data.COUNTER_PARTY;
						tmp_json.PROVISION_YN = gridData[e].data.PROVISION_YN;
						tmp_json.TP_KEY = gridData[e].data.TP_KEY;
						vm.sendpopupData.push(tmp_json);
						all_object_id += '_' + gridData[e].data.OBJECT_ID;
						vm.ObjectIDLIST.push({OBJECT_ID:gridData[e].data.OBJECT_ID});

					}
					
					ReviewAdjServiceFactory
					.savereviewadj(vm.sendpopupData, vm.sendpopupData)
					.then(
							function(result) {

								if (result.callSuccess !== "1") {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoading = false;

								} else {
									AlertService
											.add(
													"success",
													"Data has been saved successfully ",
													4000);
									$uibModalInstance.dismiss('cancel');
									if( vm.ObjectIDLIST && _.isArray(vm.ObjectIDLIST) && vm.ObjectIDLIST.length<=10){
										
										var arg2= (Array.prototype.map.call(vm.ObjectIDLIST, s=> s.OBJECT_ID).toString());
										  var args = {
												OBJECT_ID : arg2,
												gridFilter : vm.ObjectIDLIST
										  }
										  $rootScope.$emit('gridUpdate', args);
									}else{
										$rootScope.$broadcast('dataFilters:refreshGrid', {
											  "refresh": true
										  });
									}
	

								}
							});

				};

				vm.confirm_exclude_current = function() {
					vm.sendpopupData = [];
					var tmp_json = {};
					var all_object_id = gridData[0].data.OBJECT_ID;
					for ( var e in gridData) {
						tmp_json = {};
						tmp_json.TAX_YEAR = GlobalService.globalParams.tax_year;
						tmp_json.SCENARIO = GlobalService.globalParams.scenario;
						tmp_json.JCD_KEY = GlobalService.globalParams.jcd_key;
						
						//tmp_json.IS_EXCLUDED = "ALL YEAR EXCLUDED";
						//ALL YEARS , CURRENT YEAR , NOT EXCLUDED
						tmp_json.EXCLUDED_YEAR = "CURRENT YEAR";
						tmp_json.COMBINATION_KEY = gridData[e].data.COMBINATION_KEY;
						tmp_json.SCHDM_ID = gridData[e].data.SCHDM_ID;
						tmp_json.SCHDM_USER_DESC = gridData[e].data.SCHDM_USER_DESC;
						tmp_json.SCHDM_TYPE = gridData[e].data.SCHDM_TYPE;
						tmp_json.METHOD_OF_CALC = gridData[e].data.METHOD_OF_CALC;
						tmp_json.MULTIPLIER = gridData[e].data.MULTIPLIER;
						tmp_json.AMOUNT = gridData[e].data.AMOUNT;
						tmp_json.TRIGGER_ACCT_KEY = gridData[e].data.TRIGGER_ACCT_KEY;
						tmp_json.TRIGGER_CHART_KEY = gridData[e].data.TRIGGER_CHART_KEY;
						tmp_json.POSTING_ACCT_KEY = gridData[e].data.POSTING_ACCT_KEY;
						tmp_json.POSTING_TAC_KEY = gridData[e].data.POSTING_TAC_KEY;
						tmp_json.TBBS_ACCT_KEY = gridData[e].data.TBBS_ACCT_KEY;
						tmp_json.COUNTER_PARTY = gridData[e].data.COUNTER_PARTY;
						tmp_json.TBBS_ACCT_KEY = gridData[e].data.TBBS_ACCT_KEY;
						tmp_json.COUNTER_PARTY = gridData[e].data.COUNTER_PARTY;
						tmp_json.PROVISION_YN = gridData[e].data.PROVISION_YN;
						tmp_json.TP_KEY = gridData[e].data.TP_KEY;
						vm.sendpopupData.push(tmp_json);
						all_object_id += '_' + gridData[e].data.OBJECT_ID;
						vm.ObjectIDLIST.push({OBJECT_ID:gridData[e].data.OBJECT_ID});

					}
					
					ReviewAdjServiceFactory
					.savereviewadj(vm.sendpopupData, vm.sendpopupData)
					.then(
							function(result) {

								if (result.callSuccess !== "1") {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoading = false;

								} else {
									AlertService
											.add(
													"success",
													"Data has been saved successfully ",
													4000);
									$uibModalInstance.dismiss('cancel');
									if( vm.ObjectIDLIST && _.isArray(vm.ObjectIDLIST) && vm.ObjectIDLIST.length<=10){
										
										var arg2= (Array.prototype.map.call(vm.ObjectIDLIST, s=> s.OBJECT_ID).toString());
										  var args = {
												OBJECT_ID : arg2,
												gridFilter : vm.ObjectIDLIST
										  }
										  $rootScope.$emit('gridUpdate', args);
									}else{
										$rootScope.$broadcast('dataFilters:refreshGrid', {
											  "refresh": true
										  });
									}
	

								}
							});

				};	
				vm.confirm_include = function() {
					vm.sendpopupData = [];
					var tmp_json = {};
					var all_object_id = gridData[0].data.OBJECT_ID;
					for ( var e in gridData) {
						tmp_json = {};
						tmp_json.TAX_YEAR = GlobalService.globalParams.tax_year;
						tmp_json.SCENARIO = GlobalService.globalParams.scenario;
						tmp_json.JCD_KEY = GlobalService.globalParams.jcd_key;
						
						//tmp_json.IS_EXCLUDED = "ALL YEAR EXCLUDED";
						//ALL YEARS , CURRENT YEAR , NOT EXCLUDED
						tmp_json.EXCLUDED_YEAR = "INCLUDE CURRENT YEAR";
						tmp_json.COMBINATION_KEY = gridData[e].data.COMBINATION_KEY;
						tmp_json.SCHDM_ID = gridData[e].data.SCHDM_ID;
						tmp_json.SCHDM_USER_DESC = gridData[e].data.SCHDM_USER_DESC;
						tmp_json.SCHDM_TYPE = gridData[e].data.SCHDM_TYPE;
						tmp_json.METHOD_OF_CALC = gridData[e].data.METHOD_OF_CALC;
						tmp_json.MULTIPLIER = gridData[e].data.MULTIPLIER;
						tmp_json.AMOUNT = gridData[e].data.AMOUNT;
						tmp_json.TRIGGER_ACCT_KEY = gridData[e].data.TRIGGER_ACCT_KEY;
						tmp_json.TRIGGER_CHART_KEY = gridData[e].data.TRIGGER_CHART_KEY;
						tmp_json.POSTING_ACCT_KEY = gridData[e].data.POSTING_ACCT_KEY;
						tmp_json.POSTING_TAC_KEY = gridData[e].data.POSTING_TAC_KEY;
						tmp_json.TBBS_ACCT_KEY = gridData[e].data.TBBS_ACCT_KEY;
						tmp_json.COUNTER_PARTY = gridData[e].data.COUNTER_PARTY;
						tmp_json.TBBS_ACCT_KEY = gridData[e].data.TBBS_ACCT_KEY;
						tmp_json.COUNTER_PARTY = gridData[e].data.COUNTER_PARTY;
						tmp_json.PROVISION_YN = gridData[e].data.PROVISION_YN;
						tmp_json.TP_KEY = gridData[e].data.TP_KEY;
						vm.sendpopupData.push(tmp_json);
						all_object_id += '_' + gridData[e].data.OBJECT_ID;
						vm.ObjectIDLIST.push({OBJECT_ID:gridData[e].data.OBJECT_ID});

					}
					
					ReviewAdjServiceFactory
					.savereviewadj(vm.sendpopupData, vm.sendpopupData)
					.then(
							function(result) {

								if (result.callSuccess !== "1") {
									AlertService.add("error",
											result.errorMessage, 4000);
									$scope.crudLoading = false;

								} else {
									AlertService
											.add(
													"success",
													"Data has been saved successfully ",
													4000);
									$uibModalInstance.dismiss('cancel');
									if( vm.ObjectIDLIST && _.isArray(vm.ObjectIDLIST) && vm.ObjectIDLIST.length<=10){
										
										var arg2= (Array.prototype.map.call(vm.ObjectIDLIST, s=> s.OBJECT_ID).toString());
										  var args = {
												OBJECT_ID : arg2,
												gridFilter : vm.ObjectIDLIST
										  }
										  $rootScope.$emit('gridUpdate', args);
									}else{
										$rootScope.$broadcast('dataFilters:refreshGrid', {
											  "refresh": true
										  });
									}
	

								}
							});

				};	
				
			}
			return controllers;
		});