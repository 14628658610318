
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.RdSalesAdjPercentageService',[])

    .factory('rdSalesAdjPercentageFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var rdSalesAdjPercentageFactory = {};
        rdSalesAdjPercentageFactory.url = {};
        rdSalesAdjPercentageFactory.url.save = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=gtfg5p";       
          
        rdSalesAdjPercentageFactory.savePercentage = function(_settings, _data){
        	    var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        		var params = filterParams;
        		var jsonObj = JSON.stringify(_data);
                var jsonSettings =  JSON.stringify(_settings);
              
        		params = _.merge({},  _data[0] , params);
                params = _.merge({},  GlobalService.globalParams , params);
        		params = _.extend({clob_data:jsonObj}, params);
        		params = _.extend({clob_settings:jsonSettings}, params);		  
        		
        		console.log("saveConfig......params::",params);        
        		
        		var promise = $http({
                    method: "post",
                    url: rdSalesAdjPercentageFactory.url.save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
        		
        		
        	
        	return promise;
        } 
        
        return rdSalesAdjPercentageFactory;
    }])

    return services;

});