define([
    'angular'

], function () {
    'use strict';


    var controllers =  angular.module('app.gridOnModalCtrl',[])
    .controller('gridOnModalCtrl', ['$uibModalInstance','$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','ModalFactory','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','GENERAL_CONFIG', 'USER_SETTINGS', gridOnModalCtrl])
    
    function gridOnModalCtrl($uibModalInstance,$rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        vm.name = "";
        vm.title = "Data"
        vm.data = [];
        vm.api = "/loadGridJson?action_id=30953&grid_id=5660";//$state.current.api.get;
        vm.params = {client_key:0};

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

   
    }
    return controllers;

});

