define([
    'angular',
    './Section174AController',
    './Section174AdjService'



], function () {
    'use strict';
    return angular.module('app.section174Adj', ['app.section174adjController','app.section174adjService'] );


});
