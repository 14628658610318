define(
    [ 'angular'

        ],
        function() {
        'use strict';

        var controllers = angular.module('app.controversyDashboardComponentCtrl', [])
        .controller('controversyDashboadController', [ controversyDashboadController])


        function controversyDashboadController() {
            var vm = this;

            vm.singleSeriesChartOptions = {
                chartTypes: ['Pie', 'Donut'],
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height: '300'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    series: {

                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name} : </b> {point.percentage:.1f} %',
                            // style: {
                            //     color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                            // }
                        },

                    },

                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: "",
                    data: [{
                        name: 'Chrome',
                        y: 61.41,
                        sliced: true,
                        selected: true
                    }, {
                        name: 'Internet Explorer',
                        y: 11.84
                    }, {
                        name: 'Firefox',
                        y: 10.85
                    }, {
                        name: 'Edge',
                        y: 4.67
                    }, {
                        name: 'Safari',
                        y: 4.18
                    }, {
                        name: 'Sogou Explorer',
                        y: 1.64
                    }, {
                        name: 'Opera',
                        y: 1.6
                    }, {
                        name: 'QQ',
                        y: 1.2
                    }, {
                        name: 'Other',
                        y: 2.61
                    }]
                }],
            }

            vm.bubleChart = {
                chart: {
                    type: 'packedbubble',
                    height: '200'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },
                legend: {
                    layout: 'vertical',
                    floating: false,
                    align: 'right',
                    verticalAlign: 'middle',
                    right: 0,
                    width: 100,
                    labelFormatter: function () {
                        return this.name;
                    }
                },
                plotOptions: {
                    packedbubble: {
                        maxSize: '100%',
                        zMin: 0,
                        zMax: 1000,
                        layoutAlgorithm: {
                            gravitationalConstant: 0.05,
                            splitSeries: true,
                            seriesInteraction: false,
                            dragBetweenSeries: true,
                            parentNodeLimit: true
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                            filter: {
                                property: 'y',
                                operator: '>',
                                value: 10
                            },
                            style: {
                                color: 'black',
                                textOutline: 'none',
                                fontWeight: 'normal'
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Europe',
                    data: [
                        {
                        name: 'Germany',
                        value: 767.1
                    }, {
                        name: 'Croatia',
                        value: 20.7
                    },
                    {
                        name: "Belgium",
                        value: 97.2
                    },
                    {
                        name: "Czech Republic",
                        value: 111.7
                    },
                    {
                        name: "Netherlands",
                        value: 158.1
                    },
                    {
                        name: "Spain",
                        value: 241.6
                    },
                    {
                        name: "Ukraine",
                        value: 249.1
                    },
                    {
                        name: "Poland",
                        value: 298.1
                    },
                    {
                        name: "France",
                        value: 323.7
                    },
                    {
                        name: "Romania",
                        value: 78.3
                    },
                    {
                        name: "United Kingdom",
                        value: 415.4
                    }, {
                        name: "Turkey",
                        value: 353.2
                    }, {
                        name: "Italy",
                        value: 337.6
                    },
                    {
                        name: "Greece",
                        value: 71.1
                    },
                    {
                        name: "Austria",
                        value: 69.8
                    },
                    {
                        name: "Belarus",
                        value: 67.7
                    },
                    {
                        name: "Serbia",
                        value: 59.3
                    },
                    {
                        name: "Finland",
                        value: 54.8
                    },
                    {
                        name: "Bulgaria",
                        value: 51.2
                    },
                    {
                        name: "Portugal",
                        value: 48.3
                    },
                    {
                        name: "Norway",
                        value: 44.4
                    },
                    {
                        name: "Sweden",
                        value: 44.3
                    },
                    {
                        name: "Hungary",
                        value: 43.7
                    },
                    {
                        name: "Switzerland",
                        value: 40.2
                    },
                    {
                        name: "Denmark",
                        value: 40
                    },
                    {
                        name: "Slovakia",
                        value: 34.7
                    },
                    {
                        name: "Ireland",
                        value: 34.6
                    }]
                }, {
                    name: 'Africa',
                    data: [{
                        name: "Senegal",
                        value: 8.2
                    },
                    {
                        name: "Cameroon",
                        value: 9.2
                    },
                    {
                        name: "Zimbabwe",
                        value: 13.1
                    },
                    {
                        name: "Ghana",
                        value: 14.1
                    },
                    {
                        name: "Kenya",
                        value: 14.1
                    },
                    {
                        name: "Sudan",
                        value: 17.3
                    },
                    {
                        name: "Tunisia",
                        value: 24.3
                    },
                    {
                        name: "Angola",
                        value: 25
                    },
                    {
                        name: "Libya",
                        value: 50.6
                    },
                    {
                        name: "Ivory Coast",
                        value: 7.3
                    }]
                }, {
                    name: 'Oceania',
                    data: [{
                        name: "Australia",
                        value: 409.4
                    },
                    {
                        name: "New Zealand",
                        value: 34.1
                    },
                    {
                        name: "Papua New Guinea",
                        value: 7.1
                    }]
                }, {
                    name: 'North America',
                    data: [{
                        name: "Costa Rica",
                        value: 7.6
                    },
                    {
                        name: "Honduras",
                        value: 8.4
                    },
                    {
                        name: "Jamaica",
                        value: 8.3
                    },
                    {
                        name: "Panama",
                        value: 10.2
                    }]
                }, {
                    name: 'South America',
                    data: [{
                        name: "El Salvador",
                        value: 7.2
                    },
                    {
                        name: "Uruguay",
                        value: 8.1
                    },
                    {
                        name: "Bolivia",
                        value: 17.8
                    },
                    {
                        name: "Trinidad and Tobago",
                        value: 34
                    },
                    {
                        name: "Ecuador",
                        value: 43
                    },
                    {
                        name: "Chile",
                        value: 78.6
                    },
                    {
                        name: "Peru",
                        value: 52
                    },
                    {
                        name: "Colombia",
                        value: 74.1
                    },
                    {
                        name: "Brazil",
                        value: 501.1
                    }, {
                        name: "Argentina",
                        value: 199
                    },
                    {
                        name: "Venezuela",
                        value: 195.2
                    }]
                }, {
                    name: 'Asia',
                    data: [{
                        name: "Nepal",
                        value: 6.5
                    },
                    {
                        name: "Georgia",
                        value: 6.5
                    },
                    {
                        name: "Brunei Darussalam",
                        value: 7.4
                    },
                    {
                        name: "Kyrgyzstan",
                        value: 7.4
                    },
                    {
                        name: "Afghanistan",
                        value: 7.9
                    },
                    {
                        name: "Myanmar",
                        value: 9.1
                    },
                    {
                        name: "Mongolia",
                        value: 14.7
                    },
                    {
                        name: "Sri Lanka",
                        value: 16.6
                    },
                    {
                        name: "Bahrain",
                        value: 20.5
                    },
                    {
                        name: "Yemen",
                        value: 22.6
                    },
                    {
                        name: "Jordan",
                        value: 22.3
                    },
                    {
                        name: "Lebanon",
                        value: 21.1
                    },
                    {
                        name: "Azerbaijan",
                        value: 31.7
                    },
                    {
                        name: "Singapore",
                        value: 47.8
                    },
                    {
                        name: "Hong Kong",
                        value: 49.9
                    },
                    {
                        name: "Syria",
                        value: 52.7
                    },
                    {
                        name: "DPR Korea",
                        value: 59.9
                    },
                    {
                        name: "Israel",
                        value: 64.8
                    },
                    {
                        name: "Turkmenistan",
                        value: 70.6
                    }]
                }]
            }

            vm.projectsTableData = {
                tHeadData: ["First Tax Rep. Year", "Project Name", "Business", "Project Type", "Primary Contact(s)", "Countries with tax", "Status"],
                tBodyData: [
                    {year: 2018, P_Name: "Advisor Text", B_Name: "Business", P_Type: "Cash Repsitioning", P_contact: "John Doe", C_tax: "United States", status: "O"},
                    {year: 2018, P_Name: "Advisor Text", B_Name: "Business", P_Type: "Cash Repsitioning", P_contact: "John Doe", C_tax: "United States", status: "C"},
                    {year: 2018, P_Name: "Advisor Text", B_Name: "Business", P_Type: "Cash Repsitioning", P_contact: "John Doe", C_tax: "United States", status: "S"},
                    {year: 2018, P_Name: "Advisor Text", B_Name: "Business", P_Type: "Cash Repsitioning", P_contact: "John Doe", C_tax: "United States", status: "T"},
                    {year: 2018, P_Name: "Advisor Text", B_Name: "Business", P_Type: "Cash Repsitioning", P_contact: "John Doe", C_tax: "United States", status: "O"}
                ]
            };


            vm.activityObject = {
                title: "Recent Activity",
                eachActivity: [
                    {date: "21 June2019", time: "10:00 am", desc: "John Doe project created", color: "red"},
                    {date: "21 June2019", time: "10:00 am", desc: "John Doe project created", color: "#19c5ae"},
                    {date: "21 June2019", time: "10:00 am", desc: "John Doe project created", color: "#5374e5"},
                    {date: "21 June2019", time: "10:00 am", desc: "John Doe project created", color: "#5374e5"},
                    {date: "21 June2019", time: "10:00 am", desc: "John Doe project created", color: "#19c5ae"}
                ]
            }

        }
        return controllers;
    })