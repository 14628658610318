define([
   'angular'

], function () {
   'use strict';

   var controllers = angular.module('app.MaxLoanBalanceCtrl', [])
      .controller('maxLoanCtrl', ['GlobalService', '$filter', '$scope', '$rootScope', 'JsonObjectFactory', '$parse', 'workspaceFactory', 'AlertService', 'CommonUtilitiesFactory', 'GENERAL_CONFIG', maxLoanCtrl]);


   function maxLoanCtrl(GlobalService, $filter, $scope, $rootScope, JsonObjectFactory, $parse, workspaceFactory, AlertService, CommonUtilitiesFactory, GENERAL_CONFIG) {
      var vm = this;
      vm.tableData = [{
            "acc_code": "001021000",
            "acc_desc": "Trade or Accounts Receivable - Related Parties - Interest Bearing",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": "0",
            "max_bal": "0"
         },
         {
            "acc_code": "001021001",
            "acc_desc": "Trade or Accounts Receivable - Related Parties - Non-Interest Bearing",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": "0",
            "max_bal": "0"
         },
         {
            "acc_code": "001021005",
            "acc_desc": "A/R - Related US",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": "0",
            "max_bal": "0"
         },
         {
            "acc_code": "001061000",
            "acc_desc": "Loans and Advances to Related U.S. companies",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": "0",
            "max_bal": "20"
         },
         {
            "acc_code": "001061000",
            "acc_desc": "Loans and Advances to Related U.S. companies",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": "0",
            "max_bal": "0"
         },
         {
            "acc_code": "001061001",
            "acc_desc": "Loans and Advances to Related Non-U.S. companies",
            "py_bal": "0",
            "trialBal_submitted": "16,688",
            "reclass_adjust": "0",
            "net_amount": "16,688",
            "max_bal": "8344"
         },
         {
            "acc_code": "001061001",
            "acc_desc": "Loans and Advances to Related Non-U.S. companies",
            "py_bal": "0",
            "trialBal_submitted": "14,443",
            "reclass_adjust": "0",
            "net_amount": "14,443",
            "max_bal": "7,221"
         },
         {
            "acc_code": "002011000",
            "acc_desc": "Trade or Accounts Payable - Related Parties",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": "0",
            "max_bal": "0"
         },
         {
            "acc_code": "002011005",
            "acc_desc": "A/P - Related US",
            "py_bal": "0",
            "trialBal_submitted": "48,323",
            "reclass_adjust": "0",
            "net_amount": "48,323",
            "max_bal": "24,161"
         },
         {
            "acc_code": "002031000",
            "acc_desc": "Borrowing from related U.S. companies (excluding trade payables)",
            "py_bal": "0",
            "trialBal_submitted": "14,442",
            "reclass_adjust": "0",
            "net_amount": "14,442",
            "max_bal": "7,221"
         },
         {
            "acc_code": "002031000",
            "acc_desc": "Borrowing from related U.S. companies (excluding trade payables)",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": "0",
            "max_bal": "0"
         },
         {
            "acc_code": "002032000",
            "acc_desc": "Borrowing from related non-U.S. companies (excluding trade payables)",
            "py_bal": "0",
            "trialBal_submitted": "-63,251,534",
            "reclass_adjust": "0",
            "net_amount": "-63,251,534",
            "max_bal": "-94,991,359"
         },
         {
            "acc_code": "002032000",
            "acc_desc": "Borrowing from related non-U.S. companies (excluding trade payables)",
            "py_bal": "0",
            "trialBal_submitted": "0",
            "reclass_adjust": "0",
            "net_amount": " 0",
            "max_bal": "0"
         }
      ];
      /* save from workflow 5/14/2019 */
      $scope.$on('workflowSave', function (event, data) {
         vm.save();
      });
      vm.save = function () {
         AlertService.add("success", "Data save Successfully", 4000);
      }
   }
});