define([
    'angular'
], function () {
    'use strict';
    var services = angular.module('app.scheduleF5471-OService', [])
     .factory("scheduleFOServiceFactory", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'JsonObjectFactory', 'workspaceFactory', 'AlertService', '$injector','$parse', 'GENERAL_CONFIG',
    	 function ($q, $http,$stateParams,$timeout, $rootScope, $log, GlobalService, JsonObjectFactory, workspaceFactory, AlertService, $injector,$parse, GENERAL_CONFIG){
    	 var scheduleFOServiceFactory = {};
         var returnObj = {};
		 var saveApiCalled = false;
         scheduleFOServiceFactory.url = {};
         scheduleFOServiceFactory.url.saveFIRScheduleO = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=6qr0av";//6qr0av";
    	 //scheduleFOServiceFactory.url.saveStockAcquisition = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=3kk6ql";
    	 //scheduleFOServiceFactory.url.saveStockDiposition = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=3kk6ql"; 
    	 //scheduleFOServiceFactory.url.saveReorganization = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=3kk6ql";
    	 //scheduleFOServiceFactory.url.deleteSchedule = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=3fvhuy";

         scheduleFOServiceFactory.saveSchedOEnabledFlag = function(_callName , _data){
            console.log("data", _data);
			
            
           $rootScope.$broadcast('gridRefresh', false); 
            
            if(!saveApiCalled){
				saveApiCalled = true;
				if(_callName === 'save_schd_o_enabled') {
					/*var service = $injector.get('JsonObjectFactory');
					var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=mellbc";
					var jsonObj = JSON.stringify(_data.rowData);
					var jsonSettings = '{}';
					var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
					var params = _.merge({}, GlobalService.globalParams, filterParams);
					var params = _.extend({jsonObj:jsonObj}, params);
					params =  _.extend({jsonSettings:jsonSettings}, params);
					params.process_name =  _callName;
					params.combination_code =  _data.rowData.HO_COMBINATION_KEY;
					params.flag_val =  _data.rowData.SCHD_O_ENABLED;
					
					service.saveJSON(url, params).then(function(data){
						if(data.callSuccess === "1"){
							AlertService.add("success", "Data saved Successfully.",3000);
						}else{
							AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
						}

						if(_data.rowData.I_TRANS_ID === 0 && _callName === 'save_stmt_flag_checked') {
							var args = {combination_keys: returnObj.combination_key, gridFilter: {HO_COMBINATION_KEY: returnObj.combination_key}};
							$rootScope.$emit('gridUpdate', args);
						}
					});*/
					
					var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
											"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
											"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
					var editRow = [];
					var tempJsonObj = [{
						"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
						"trans_type_key":_data.rowData.SCHEDULE_O_5471_ENABLED_TRANS_TYPE_KEY,
						"combination_key":_data.rowData.COMBINATION_KEY,
						"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
						"form_key":_data.rowData.FORM_KEY,
						"line_no":_data.rowData.SCHEDULE_O_5471_ENABLED_LINE_NO,
						"occurence":((_data.rowData.SCHEDULE_O_5471_ENABLED_OCCURRENCE == "0") ? null : _data.rowData.SCHEDULE_O_5471_ENABLED_OCCURRENCE),
						"line_attrib_key" : _data.rowData.SCHEDULE_O_5471_ENABLED_LINE_ATTRIB_KEY,
						"Attribute_Name":_data.rowData.SCHEDULE_O_5471_ENABLED_ATTRIB_NAME,
						"Attribute_Value":((_data.rowData.SCHD_O_ENABLED == 0 ) ? 'N' : 'Y'),
						"trans_dtls_key" : ((_data.rowData.SCHEDULE_O_5471_ENABLED_TRANS_DETAILS_KEY== "0") ? null : _data.rowData.SCHEDULE_O_5471_ENABLED_TRANS_DETAILS_KEY),
						"trans_status":"A"
					}];
					
					editRow.push(tempJsonObj);
					var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
					console.log("scheduleDetails--------------", scheduleDetails);
					
					scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings, scheduleDetails,'INLINE').then(function(result) {
						if (result.errorMessage && result.errorMessage !== 'null') 
						{
							AlertService.add("error", result.errorMessage, 1000);
							saveApiCalled = false;
						} 
						else
						{
							AlertService.add("success", "Data is successfully saved/edited", 1000);
							var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
							$rootScope.$emit('gridUpdate', args);
							saveApiCalled = false;
						}
					});
				}
				else
				{
					var scheduleSettings = [{"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
											"scenario":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.scenario :workspaceFactory.activeScreen.filters.filterParams.scenario,
											"jcd_key":(GlobalService.globalParams.jcd_key === '' ||  GlobalService.globalParams.jcd_key === undefined) ? '250' : GlobalService.globalParams.jcd_key}];
					var editRow = [];
					var tempJsonObj = [{
						"tax_year":workspaceFactory.activeScreen.filters === undefined ? GlobalService.globalParams.tax_year :workspaceFactory.activeScreen.filters.filterParams.tax_year,
						"trans_type_key":_data.rowData.STMT_FLAG_TRANS_TYPE_KEY,
						"combination_key":_data.rowData.COMBINATION_KEY,
						"group_obj_key":_data.rowData.GROUP_OBJ_KEY,
						"form_key":_data.rowData.FORM_KEY,
						"line_no":_data.rowData.STMT_FLAG_LINE_NO,
						"occurence":((_data.rowData.STMT_FLAG_OCCURRENCE == "0") ? null : _data.rowData.STMT_FLAG_OCCURRENCE),
						"line_attrib_key" : _data.rowData.STMT_FLAG_LINE_ATTRIB_KEY,
						"Attribute_Name":_data.rowData.STMT_FLAG_ATTRIB_NAME,
						"Attribute_Value":((_data.rowData.STMT_FLAG_CHECKED == 0 ) ? 'N' : 'Y'),
						"trans_dtls_key" : ((_data.rowData.STMT_FLAG_TRANS_DETAILS_KEY== "0") ? null : _data.rowData.STMT_FLAG_TRANS_DETAILS_KEY),
						"trans_status":"A"
					}];
					
					editRow.push(tempJsonObj);
					var scheduleDetails = {"addrows":[], "editrows":editRow, "removerows":[]};
					console.log("scheduleDetails--------------", scheduleDetails);
					
					scheduleFOServiceFactory.saveFIRScheduleO(scheduleSettings, scheduleDetails,'INLINE').then(function(result) {
						if (result.errorMessage && result.errorMessage !== 'null') 
						{
							AlertService.add("error", result.errorMessage, 1000);
							saveApiCalled = false;
						} 
						else
						{
							AlertService.add("success", "Data is successfully saved/edited", 1000);
							var args = {combination_keys: _data.rowData.COMBINATION_KEY, gridFilter: {HO_COMBINATION_KEY: _data.rowData.COMBINATION_KEY}};
							$rootScope.$emit('gridUpdate', args);
							saveApiCalled = false;
						}
					});
				}
			}else{
				AlertService.add("warning", "Please wait, Save call is in progress", 2000);
			}
         }
    		 
        scheduleFOServiceFactory.saveFIRScheduleO = function(_data,Details,call_type){
        	 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
	           var jsonSettings =  JSON.stringify(_data);
	           var jsonObj = JSON.stringify(Details);
	               //read each element in jsonSettings and loop through jsonObj and each json string add element and value from jsonSettings
	           var params = filterParams;
	           var service = $injector.get('JsonObjectFactory');
			   var defer = $q.defer();
	           params = _.merge({},  GlobalService.globalParams , params);
	           params =  _.extend({jsonObj:jsonObj}, params);
	           params =  _.extend({jsonSettings:jsonSettings}, params);
	//               params =  _.extend({jsonSettings:jsonSettings}, _data);
	           params.process_name =  "Schedule_O_Save";
	           if(call_type == 'INLINE'){
	        	   service.saveJSON(scheduleFOServiceFactory.url.saveFIRScheduleO,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   //AlertService.add("success", "Data is successfully saved/edited.", 4000);
							defer.resolve(data);
						}else {
	                     AlertService.add("error", data.errorMessage);
	                     defer.reject(data);
	                 }},function(data){
	                 defer.reject(data);
	             });
	             return defer.promise;
			   }
	           else{
	           var promise = $http({
	        	   method: "post",
	               url: scheduleFOServiceFactory.url.saveFIRScheduleO,
	               data: params
	           }).then(function (response) {
	               var status = response.status;
	               return response.data;
	           });	
	           return promise;
    	     };
        };
       
        
    	 
    	 scheduleFOServiceFactory.deleteSchdule = function(data){
    		 var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
    		 var jsonObj = JSON.stringify(data);
    		 
             var params = filterParams;
             params = _.merge({},  GlobalService.globalParams , params);
             params =  _.extend({jsonObj:jsonObj}, params);
    		 var promise = $http({
    			 method: "POST",
    			 url: scheduleFOServiceFactory.url.deleteSchedule,
    			 data: params
    		}). then(function (response){
    				//console.log()
    				return response.data;
    				})
    			
    			return promise;	
    	 }
    	 
    	 /*scheduleFOServiceFactory.saveStockAcquisition = function(data){
    		 data.json_string = JSON.stringify(data);
    		 var promise = $http({
    			 method: "POST",
    			 url: scheduleFOServiceFactory.url.saveStockAcquisition,
    			 data: data
    		}). then(function (response){
    				console.log()
    				return response.data;
    				})
    			
    			return promise;	
    	 }
    	
    	 
    	 scheduleFOServiceFactory.saveStockDiposition = function(data){
    		 data.json_string = JSON.stringify(data);
    		 var promise = $http({
    			 method: "POST",
    			 url: scheduleFOServiceFactory.url.saveStockDiposition,
    			 data: data
    		}). then(function (response){
    				console.log()
    				return response.data;
    				})
    			
    			return promise;	

    	 
    	 }
    	 
    	 scheduleFOServiceFactory.saveReorganization = function(data){
    		 data.json_string = JSON.stringify(data);
    		 var promise = $http({
    			 method: "POST",
    			 url: scheduleFOServiceFactory.url.saveReorganization,
    			 data: data
    		}). then(function (response){
    				console.log()
    				return response.data;
    				})
    			
    			return promise;	

    	 
    	 }
    	 */
     
    	 return scheduleFOServiceFactory;
     }]);
    
    return services;
    
     	
     
});