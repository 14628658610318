
define(
    ['angular'

    ],
    function () {
        'use strict';

        var services = angular
            .module('app.exclusionListService', [])

            .factory(
                "exclusionListFactory",
                [
                    '$q',
                    '$http',
                    '$log',
                    '$rootScope',
                    'AlertService',
                    'GlobalService',
                    'USER_SETTINGS',
                    '$injector',
                    'JsonObjectFactory',
                    'workspaceFactory',
                    'GENERAL_CONFIG',
                    function ($q, $http, $log, $rootScope,
                        AlertService, GlobalService,
                        USER_SETTINGS, $injector,
                        JsonObjectFactory,
                        workspaceFactory, GENERAL_CONFIG) {
                        let exclusionList = {};
                        exclusionList.loadConsolGroups = function () {
                            let params = {
                                action_code: 'n34mwg'
                            };
                            let filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                            params = _.merge(params, GlobalService.globalParams, filterParams);
                            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32516", params).then((response) => {
                                if (response.callSuccess === "1") {
                                    return response.jsonObject;
                                }
                            });
                        }


                        return exclusionList;
                    }
                ])
        return services;
    });