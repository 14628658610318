define([
  'angular'
], function () {
  'use strict';

  var controllers = angular.module('app.clientLandingPageCtrl', [])
      .controller('clientLandingPageCtrl', ['clientLadingFactory','$location', 'GlobalService', 'JsonObjectFactory', '$scope', '$rootScope', '$http', '$state', '$uibModal', '$log', '$timeout', 'SERVER_CONFIG', 'USER_SETTINGS', 'ClientAdminFactory', 'AlertService', 'GENERAL_CONFIG', 'MessageService','MessageFactory', 'USER_MENU', clientLandingPageCtrl])
      .controller('clientFlipModalCtrl', ['$timeout', '$scope', '$rootScope', 'clientLadingFactory', 'GENERAL_CONFIG', '$http', '$state', 'GlobalService', '$location', '$filter', 'client', 'clientLanDtls', '$uibModalInstance', 'USER_MENU', 'AlertService', 'settingFactory', clientFlipModalCtrl]);


  function clientLandingPageCtrl(clientLadingFactory,$location, GlobalService, JsonObjectFactory, $scope, $rootScope, $http, $state, $uibModal, $log, $timeout, SERVER_CONFIG, USER_SETTINGS, ClientAdminFactory, AlertService, GENERAL_CONFIG, MessageService,MessageFactory, USER_MENU) {
      var vm= this;
      vm.loading = true;
      vm.clientLanDtls = [];
      vm.searchLink = false;
      vm.clientBtnLoader = false;
      vm.selectedClientKey = {};

      vm.getClientLandingDetails = function(){
        clientLadingFactory.getClientLandingDtls(GENERAL_CONFIG.base_url + '/getClientLandDtls').then(function(data){
        vm.loading = false;
        if (data.jsonObect.callSuccess !== "1" && data.jsonObect.clientLanDtls ){
              AlertService.add("error", data.errorMessage);
          } else {
        _.each(data.jsonObect.clientLanDtls,function(item){
            var keys=Object.keys(item);
            var newItem={};
            for(var i=0;i<keys.length;i++){
                newItem[keys[i].toUpperCase()]=item[keys[i]];
            }
            var url = GENERAL_CONFIG.admin_api_url + '/downloadClientLogo/'+newItem.CLIENT_KEY;
            newItem.logo_client = vm.imageURl(newItem); //
            console.log('newItem',newItem.logo_client);

           // newItem.logo_client = GENERAL_CONFIG.admin_api_url + '/downloadClientLogo/'+newItem.CLIENT_KEY
            vm.clientLanDtls.push(newItem);
        });
          console.log("clientLanding Details ",vm.clientLanDtls)
          }
      });
      }
      vm.imageURl = function(newItem){
        var config = {
          responseType: 'blob'
        };
  
          $http.get(GENERAL_CONFIG.admin_api_url + '/downloadClientLogo/'+newItem.CLIENT_KEY,config).then(function(response) {
            var blob = new Blob([response.data], {type: 'image/jpeg'});
            var urlCreator = window.URL || window.webkitURL;
            newItem.logo_client = urlCreator.createObjectURL(blob);
            return urlCreator.createObjectURL(blob);
        });
      }

      vm.getClientLandingDetails();

      vm.openClientDetailsModal = function(event, client) {
        $rootScope.isBrowserBackButton = false;
        if(!vm.clientBtnLoader) {
          $uibModal.open({
            animation: true,
            templateUrl: 'app/components/clientLandingPage/client-details-flipModal.html',
            controller: 'clientFlipModalCtrl as ctrl',
            windowClass: 'modal modal-flip client-modal',
            backdrop: 'static',
            resolve: {
              client: function () {
                return client;
              },
              clientLanDtls: function () {
                return vm.clientLanDtls;
              },
            }
          });
        }
      }

      vm.client = GlobalService.getClient();
      vm.clientName = vm.client.client_name;

      vm.clientLanding= function(key, state, stateLink, event) {
        event.preventDefault();
        event.stopPropagation();
        $rootScope.isBrowserBackButton = false;
        vm.clientBtnLoader = true;
        vm.selectedClientKey = key;
        GlobalService.setClient(key).then(function (data) {
          if (data.callSuccess === "1") {
              $rootScope.donotLogoutUser =true; // shouldn't logout on reload flag used in GlobalController
              clientLadingFactory.landingPage(state, stateLink);
            } else {
              vm.clientBtnLoader = false;
              var switchToClient = _.filter(vm.clientLanDtls, {
                  CLIENT_KEY: data.switch_to_client_key.toString()
              });
              if (null != switchToClient && switchToClient.length > 0) {
                  vm.clientName = switchToClient[0].CLIENT_NAME;
              } else {
                  vm.clientName = "";
              }
              failedToChangeClientNoModules();
            }
        });
      }

      $scope.failedToChangeClientNoModules = function () {
        console.log("Failed to Change Client");
      }

      // Used to trigger popup message to warn user and not to route to workspace when no modules assigned.
      function failedToChangeClientNoModules() {
        $timeout(function () {
            var el = document.getElementById('client_switch_failed_noModules');
            angular.element(el).triggerHandler('click');
        }, 0);
      }

  }

  function clientFlipModalCtrl($timeout, $scope, $rootScope, clientLadingFactory, GENERAL_CONFIG, $http, $state, GlobalService, $location, $filter, client, clientLanDtls, $uibModalInstance, USER_MENU, AlertService, settingFactory) {
    var vm= this;
    vm.client= client;
    vm.crudLoading = '';
    vm.modalClientLanDtls = clientLanDtls;
    vm.clientName = GlobalService.getClient().client_name;

    vm.launchClient = function(key, state, modalStateLink, id) {
      vm.crudLoading = id;
      GlobalService.setClient(key).then(function (data) {
        if (data.callSuccess === "1") {
            $rootScope.donotLogoutUser =true; // shouldn't logout on reload flag used in GlobalController
            clientLadingFactory.landingPage(state, modalStateLink);
        } else {
            var switchToClient = _.filter(vm.modalClientLanDtls, {
                CLIENT_KEY: data.switch_to_client_key.toString()
            });
            if (null != switchToClient && switchToClient.length > 0) {
                vm.clientName = switchToClient[0].CLIENT_NAME;
            } else {
                vm.clientName = "";
            }
            failedToChangeClientNoModules();
            vm.crudLoading = '';
        }
      });

      $scope.failedToChangeClientNoModules = function () {
        console.log("Failed to Change Client");
      }

      // Used to trigger popup message to warn user and not to route to workspace when no modules assigned.
      function failedToChangeClientNoModules() {
        $timeout(function () {
            var el = document.getElementById('client_switch_failed_noModules_CLModal');
            angular.element(el).triggerHandler('click');
        }, 0);
      }

    }

    vm.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $rootScope.$on('client-landing-modal-close', function() {
      vm.cancel();
    });

  }

  return controllers;
});