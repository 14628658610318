define(
    ['angular'],
    function() {
        'use strict';
        let controllers = angular.module('app.spotrates-all-curr-syncCtrl', [])
            .controller('spotRatesAllCurrSyncCtrl', ['$rootScope', '$scope', '$http', '$filter', '$timeout', '$window','GlobalService', '$uibModal', '$uibModalInstance', 'ModalFactory', 'AlertService', 'JsonObjectFactory', 'USER_SETTINGS', 'GENERAL_CONFIG', 'workspaceFactory',
                'rowData', 'gridData', 'SystemLockUnlockFactory',spotRatesAllCurrSyncCtrl]
            );

        function spotRatesAllCurrSyncCtrl($rootScope, $scope, $http, $filter, $timeout, $window,GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory, USER_SETTINGS, GENERAL_CONFIG, workspaceFactory, rowData, gridData, SystemLockUnlockFactory) {

            let vm = this;
            let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

            vm.webComponentHtml = '';
            vm.userSettings = USER_SETTINGS;
            vm.baseURLs = {};
            vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
            vm.baseURLs.api = GENERAL_CONFIG.base_url;
            vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;            
            vm.tax_year = filterParams.tax_year;
            var tempObj = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams);
            vm.globalParams = JSON.stringify(tempObj);
            vm.checkLockeOdbj =[];



            SystemLockUnlockFactory.getSystemLockUnlockStatus().then(
                () => {
                    vm.isLocked = (SystemLockUnlockFactory.isLocked === undefined) ? "1": SystemLockUnlockFactory.isLocked;

                    vm.tax_year = filterParams.tax_year;
                    vm.scenario = GlobalService.globalParams.scenario;
                    vm.jcd_key = GlobalService.globalParams.jcd_key;
                    vm.scenarioCode = GlobalService.globalParams.scenarioCode;
                    vm.logged_in_user = vm.userSettings.user.sso_id;
                    vm.screen_key = workspaceFactory.activeScreen.screen_key;
                    vm.checkLockeOdbj.push({"scenario_key":vm.scenario, "tax_year":vm.tax_year, "scenarioCode": vm.scenarioCode, "is_issue_key_enabled":'N'});

                  //  console.log(vm.tax_year , ' : ', vm.scenario, ' : ', vm.jcd_key, ' : ', vm.logged_in_user , ' : ', vm.screen_key , ' : ', vm.combination_key , ' : ', vm.checkLockeOdbj);

                    vm.checkLockeOdbjStr = JSON.stringify(vm.checkLockeOdbj);
                    
                    const html = `<gtw-spotrates-all-curr-sync-ce id="close-currconfirm-info" base-urls="{{ctrl.baseURLs}}" global-params="{{ctrl.globalParams}}" 
                    			//	tax-year="{{ctrl.tax_year}}" scenario="{{ctrl.scenario}}" jcd-key="{{ctrl.jcd_key}}" screen-key="{{ctrl.screen_key}}" sso-id="{{ctrl.logged_in_user}}" 
                                scenario-code = "{{ctrl.scenarioCode}}" check-locked-obj ="{{ctrl.checkLockeOdbjStr}}" class="gtw-web-components"></gtw-spotrates-all-curr-sync-ce>`;
                    vm.webComponentSpotRatesHtml = html;

                }
            );


            if ($uibModalInstance) {

                $timeout(() => {
                	$window.document.querySelector('gtw-spotrates-all-curr-sync-ce')
                           .addEventListener('close-currconfirm-info', (event)=>{
                               console.log('event',event);
                               $uibModalInstance.close();
                           });
                        console.log('...event listener added ...');
                    },3000
                );

                $timeout(() => {

                        $window.document.querySelector('gtw-spotrates-all-curr-sync-ce')
                            .addEventListener('save-currsync-info', (event)=>{
                                console.log('event',event);
                                if(event?.detail == 'save-currsync-info'){
									var args = {};
                        			$rootScope.$emit('gridUpdate', args);
                                   /* $rootScope.$broadcast('dataFilters:refreshGrid', {
                                        "refresh": true
                                    });*/
                                }
                                $uibModalInstance.close();
                            });
                        console.log('...event listener added ...');
                    },3000
                );

                $scope.modal_name = ModalFactory.response.data ? ModalFactory.response.data.modalScreen.modal_name : null;

                $scope.cancel = function() {
                    $uibModalInstance.close();
                    $scope.modal_name = null;
                };

            } else {
                $scope.modal_name = null;
            }

        }

        return controllers;
    }
);
