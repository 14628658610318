define([
    'angular'

 ], function () {
    'use strict';
    
    
    var controllers = angular.module('app.F-4797Ctrl', [])
    .controller('F4797Controller', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout', '$uibModal',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4797Factory', 'ModalFactory',F4797Controller])
    .controller('F4797ControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout', '$uibModal',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'F4797Factory', 'ModalFactory','$uibModalInstance','rowData', 'colData', 'gridData',F4797ControllerModal])    ;

    function F4797Controller(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,$uibModal,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4797Factory,ModalFactory) {
    	F4797ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,$uibModal,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4797Factory,ModalFactory, {},{}, {}, {},this);
    }


	function F4797ControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,$uibModal,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,F4797Factory,ModalFactory,$uibModalInstance,
        rowData, colData, gridData,thisObj) {
		console.log("------------At the start-----------");
		
		var vm = this;
		vm.rowData = rowData;
		console.log(rowData);
		vm.globalParams = GlobalService.globalParams;		
		vm.scenario 		    = GlobalService.globalParams.scenario;
		vm.glbl_issue_id  		= GlobalService.globalParams.issue_id;
		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		vm.local_issue_id  		= '';
		vm.bShowSaveButton 		= true;
		vm.cur_scenario_type    = '';
		console.log("The Global Params like tax_year, scenario and jcd are ::::::::::::", vm.globalParams);
		vm.f4797Header = [];
		vm.f4797List = [];
		vm.f4797OriginalData = [];
		vm.f4797FormData = [];
		vm.f4797ColumnHeader = [];
		vm.f4797FinalData = [];
		vm.unAllocatedAmt = 0;
		vm.crudLoading = false;		
		vm.section = [];		
		vm.classification = {
			'NA': 'NA',
			'Worthless': 'Worthless Stock',
			'Other': 'Other Flow Thru'
		};
		vm.isValidScenario = false;
		vm.isExamScenario = GlobalService.inputs.tax_year.selected.scenario_type_code === 'EXAM' && checkExamScenario();
		function checkExamScenario() {
			const filters = workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getFilters();
			if (!filters) {
				return false;
			}
			const isExamScenario = filters.some((filter) => {
				return filter.param_name.trim().toLowerCase() === 'scenario' &&
					filter.selected.CODE &&
					filter.selected.CODE.trim().toUpperCase() === 'EXAM';
			});
			return isExamScenario;
		}
		
		vm.cancel = function() {
			console.log("Form 4797 data from DB is :::::::::::", vm.f4797FormData);
			console.log("Form 4797 data from original Data is saved in :::::::::::", vm.f4797OriginalData);
			if(angular.equals(vm.f4797FormData, vm.f4797OriginalData)){
				$uibModalInstance.dismiss('cancel');
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
					vm.cancelModal();
				}
				else if(confirm("Sales of Business Property Information is not save. Do you want to close Form without saving changes?")){
					$uibModalInstance.dismiss('cancel');
				}
			}
			
		};
		
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		console.log(vm.logged_in_user);
		function getF4797Data(){
			F4797Factory.fetchF4797Details(rowData, vm.logged_in_user, filterParams.scenario).then(function (data){
				console.log("Came back to controller from Service...............:::::::::", data);
				vm.f4797Header = data.jsonObject[0];				
				vm.f4797List = data.jsonObject.slice(1);
				for(var i=0;i<vm.f4797List.length;i++){
					if(!vm.isValidScenario && vm.f4797List[i].T_SCENARIO_TYPE_CODE){
						vm.isValidScenario = vm.checkEditScenario(vm.f4797List[i].T_SCENARIO_TYPE_CODE,  vm.f4797List[i].T_SCENARIO,  vm.f4797List[i].ISSUE_KEY);
					}
				}
				var o = vm.f4797List.reduce( (a,b) => {
					a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
					a[b.LINE_ORDER].push(b);
					return a;
				}, {});
				
				var a = Object.keys(o).map(function(k){
					return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
				});
				
				vm.f4797FormData = a;
				console.log(vm.f4797FormData);
				
				var c = [];
				
				for (var i=0; i < vm.f4797FormData.length; i++){
					if(vm.f4797FormData[i].ROW_TYPE == 'CH'){
						
						if(vm.f4797FormData[i].Line_data[0].OCCURRENCE != null){
							var z = vm.f4797FormData[i].Line_data.reduce( (a,b) => {
								a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
								a[b.OCCURRENCE].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Occurrence: z[k][0].OCCURRENCE, Line_data_occ: z[k]};
							});
							
							console.log("At this point of the value of y is -------::::::", y);
							var m = angular.copy(y);
							console.log("At this point of the value of m is -------::::::", m);
							
							for(var x=0; x < m.length; x++){
								var d = (2*x)+1;
								y.splice(d, 0, m[x]);
								console.log("At this point of the value of y inside loop is -------::::::", y);
								y[d].Line_Type = "DATA_COMMENTS";
								y[d].Line_data_occ = [y[2*x].Line_data_occ[y[2*x].Line_data_occ.length -1]];
							}
							
							c = angular.copy(y[0]);
							c.Line_Type = "HEADER_ROW";
							c.Occurrence = "";
							c.Trans_details_key = "";
							c.IS_EDITABLE = "Y";
							for (var j=0; j < c.Line_data_occ.length; j++){
								c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
							vm.f4797FormData[i].Line_data = angular.copy(y);
							
						} else {
							var z = vm.f4797FormData[i].Line_data.reduce( (a,b) => {
								a[b.LINE_NO] = a[b.LINE_NO] || [];
								a[b.LINE_NO].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "HEADER_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: "", Occurrence: "", Line_data_occ: z[k]};
							});
							
							for(var j=0; j < y[0].Line_data_occ.length; j++){
								y[0].Line_data_occ[j].ATTRIB_VALUE = y[0].Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.push({Line_Type:"SUB_ROW", Line_no:y[0].Line_no, Line_data_occ: []});
							vm.f4797FormData[i].Line_data = y;
						}
					} else if(vm.f4797FormData[i].ROW_TYPE == 'DT'){
						for(var j=0; j < vm.f4797FormData[i].Line_data.length; j++){
							if(vm.f4797FormData[i].Line_data[j].IS_EDITABLE == 'Y' && vm.f4797FormData[i].Line_data[j].EDIT_TYPE == 'currency' && vm.f4797FormData[i].Line_data[j].DATA_TYPE == 'number'){
								vm.f4797FormData[i].Line_data[j].ATTRIB_VALUE = parseInt(vm.f4797FormData[i].Line_data[j].ATTRIB_VALUE);
							}
						}
					}
				}
				
				console.log(vm.f4797FormData);
				vm.f4797OriginalData = angular.copy(vm.f4797FormData);
				getUnallocatedAmt();
			});
			
		}
		getF4797Data();

		function getSectionTypes(){
			F4797Factory.getSectionTypes(vm.logged_in_user, rowData).then((data) => {				
				vm.section = [];   
				for (var i = 0; i < data.jsonObject.length; i++) {
					vm.section.push(data.jsonObject[i].VALUE);
				}				
			})
		}
		getSectionTypes();

		vm.reset = function(){
			getF4797Data();
		}
		
		vm.checkEditScenario = function (s_type_code, t_scenario, t_issue){        	
        	if(vm.scenario == t_scenario && ((s_type_code == 'EXAM' && vm.glbl_issue_id == t_issue) || s_type_code != 'EXAM')){
        		return true;
        	}        	
        	return false;        	
        }		
		
		vm.save = function(){
			vm.finalData = [];
			vm.clob_settings = [];
			console.log(vm.f4797FormData );
			console.log("Original Data was ::::::::==========", vm.f4797OriginalData);
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			vm.f4797FinalData = angular.copy(vm.f4797FormData);
			if(angular.equals(vm.f4797FormData, vm.f4797OriginalData)){
				vm.cancel();
			}else{
				updateFormDataValues();
				var returnValue = validateFormValues();
				if(returnValue == 1){
					return false;
				}
				if(vm.unAllocatedAmt != 0){
					if(!confirm("Allocated Amount is not fully adjusted among Gain or (Loss). Confirm to save the changes.")){
						return false;
					}
				}
				for(var x=0; x < vm.f4797FinalData.length; x++){
					if(vm.f4797FinalData[x].ROW_TYPE == 'CH'){
						vm.f4797FinalData[x].Line_data.splice(0, 2);
						if(vm.f4797FinalData[x].Line_data.length != null && vm.f4797FinalData[x].Line_data.length != undefined){
							for(var j=0; j < vm.f4797FinalData[x].Line_data.length; j++){
								vm.f4797FinalData[x].Line_data[j].Line_data_occ[vm.f4797FinalData[x].Line_data[j].Line_data_occ.length-1] = vm.f4797FinalData[x].Line_data[j+1].Line_data_occ[0];
								vm.f4797FinalData[x].Line_data.splice(j+1, 1);
								/*if(vm.f4797FinalData[x].Line_data[j].IS_EDITTED == "Y" && (vm.f4797FinalData[x].Line_data[j].Occurrence == "" || vm.f4797FinalData[x].Line_data[j].Occurrence == null)){
									vm.finalData.push(vm.f4797FinalData[x].Line_data[j].Line_data_occ);
								}else if (vm.f4797FinalData[x].Line_data[j].IS_EDITTED == "Y" && (vm.f4797FinalData[x].Line_data[j].Occurrence != "" && vm.f4797FinalData[x].Line_data[j].Occurrence != null)){
									vm.finalData.push(vm.f4797FinalData[x].Line_data[j].Line_data_occ);
								}*/
								for(var k = 0; k < vm.f4797FinalData[x].Line_data[j].Line_data_occ.length; k++){
									vm.finalData.push(vm.f4797FinalData[x].Line_data[j].Line_data_occ[k]);
								}
							}
						}
						if(vm.f4797FinalData[x].DELETE_ROWS.length != null && vm.f4797FinalData[x].DELETE_ROWS.length != undefined){
							for(var j=0; j < vm.f4797FinalData[x].DELETE_ROWS.length; j++){
								for(var k = 0; k < vm.f4797FinalData[x].DELETE_ROWS[j].Line_data_occ.length; k++){
									vm.f4797FinalData[x].DELETE_ROWS[j].Line_data_occ[k].IS_CHANGED_FLAG = "D";
									vm.finalData.push(vm.f4797FinalData[x].DELETE_ROWS[j].Line_data_occ[k]);
								}
							}
						}
					}
					if(vm.f4797FinalData[x].ROW_TYPE == 'TH' || vm.f4797FinalData[x].ROW_TYPE == 'DT'){
						for(var j=0; j < vm.f4797FinalData[x].Line_data.length; j++){
							vm.finalData.push(vm.f4797FinalData[x].Line_data[j]);
						}
					}
				}
				console.log("After changes, the final data was :::::::::::=========", vm.f4797FinalData);
				console.log("After changes, the manipulated data was :::::::::::=========", vm.f4797FormData);
				console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
				vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
				sendDetails(vm.finalData, vm.clob_settings);
			}
		}
		
		function sendDetails(final_data, clob_settings){
			var message = "Form 4797 Details are successfully updated."
			vm.crudLoading = true;
			F4797Factory.saveForm4797(final_data, clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(data.data.errorMessage != 'null'){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					vm.crudLoading = false;
					console.log("There was no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					$uibModalInstance.dismiss('cancel');
					var args = {};
					$rootScope.$emit('gridUpdate', args);
				}
			});
		}
		
		function getDateFormat(date_value){
			console.log("The value of the date for the row that needs to convert is ::::::", date_value);
			var date_selected = null;
			if(date_value != "VARIOUS"){
				date_selected = ((date_value.getMonth() > 8) ? (date_value.getMonth() + 1) : ('0' + (date_value.getMonth() + 1))) + '/' + ((date_value.getDate() > 9) ? date_value.getDate() : ('0' + date_value.getDate())) + '/' + date_value.getFullYear();
			}else date_selected = "VARIOUS";
			
			return date_selected;
		}
		
		function updateFormDataValues(){
			for (var i=0; i < vm.f4797FinalData.length; i++){
				if(vm.f4797FinalData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4797FinalData[i].Line_data.length; j++){
						if(vm.f4797FinalData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
							for(var l=0; l < vm.f4797FinalData[i].Line_data[j].Line_data_occ.length; l++){
								if(vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious" && vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various"){
									vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = getDateFormat(vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
								} else if(vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "string" && vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "checkbox"){
									if(vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == null ||vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == undefined){
										vm.f4797FinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = "N";
									}
								}
							}
						}
					}
				}
			}
		}
		
		function validateFormValues(){
			var returnValue = 0;
			var dateSold = null;
			var dateAcquired = null;
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4797FormData[i].Line_data.length; j++){
						var line_no = vm.f4797FormData[i].Line_data[j].Line_no;
						if(vm.f4797FormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.f4797FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
							var property_desc_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "PROPERTY_DESC_"+line_no);
							var date_acquired_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "DATE_ACQ_"+line_no);
							var date_sold_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "DATE_SOLD_"+line_no);
							var cost_basis_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "COST_BASIS_"+line_no);
							var depreciation_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "DEPRECIATION_ALLOWED_"+line_no);
							var gain_loss_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "GAIN_LOSS_"+line_no);
							var classification_type_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "FLOW_TYPE_"+line_no);
							var classification_type_value;
							if(classification_type_index !== -1) {
								classification_type_value = vm.f4797FormData[i].Line_data[j].Line_data_occ[classification_type_index].ATTRIB_VALUE;
							}
							var worthless_flag_index = vm.f4797FormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "WORTHLESS_FLAG_"+line_no);
							var wothless_flag_value;
							if(worthless_flag_index !== -1) {
								wothless_flag_value = vm.f4797FormData[i].Line_data[j].Line_data_occ[worthless_flag_index].ATTRIB_VALUE;
							}
							if(vm.f4797FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE == null || vm.f4797FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE == undefined ||vm.f4797FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE.length <=0){
								vm.f4797FormData[i].Line_data[j].Line_data_occ[property_desc_index].HAS_ERROR = "Y";
								vm.f4797FormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE_ERROR = "Enter Description of Property.";
								returnValue = 1; 
							}
							if(vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE != "VARIOUS"){
								if(typeof vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE === 'object' && vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE.getYear() > (parseInt(vm.globalParams.tax_year)-1900)){
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].HAS_ERROR = "Y";
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired year has to be current tax year or prior year.";
									returnValue = 1;
								} else if(typeof vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE === 'string' && vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE.match(/\d{4}$/)[0] > parseInt(vm.globalParams.tax_year)){
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].HAS_ERROR = "Y";
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired year has to be current tax year or prior year.";
									returnValue = 1;
								}
							}
							if(vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE != "VARIOUS"){
								if(typeof vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE === 'object' && vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE.getYear() > (parseInt(vm.globalParams.tax_year)-1900)){
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "The Date Sold year has to be same as tax year.";
									returnValue = 1;
								} else if(typeof vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE === 'string' && vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE.match(/\d{4}$/)[0] > parseInt(vm.globalParams.tax_year)){
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "The Date Sold year has to be same as tax year.";
									returnValue = 1;
								}
							}
							if(vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE != "VARIOUS" && vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE != "VARIOUS"){
								var date_qcuired =  Date.parse(vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE);
								var date_sold = Date.parse(vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE);
								if(date_qcuired > date_sold){
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].HAS_ERROR = "Y";
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired should be prior to Date Sold";
									returnValue = 1;
								}
								dateAcquired =  vm.f4797FormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE;
								dateSold = vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE;
								var diff_in_dates = getDiffDates(dateAcquired, dateSold);
								if(diff_in_dates > 365 && line_no == "10"){
									if(worthless_flag_index !== -1) {
										if(wothless_flag_value == "N"){
											vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
											vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "Date Range must be shorter than one year for Part II.";
											returnValue = 1;
										}else{
											if(!confirm("The Worthless Stock record was not in Date range. Please confirm to save the changes.")){
												return false;
											}
										}
									}
									if(classification_type_index !== -1) {
										if(classification_type_value !== "Worthless"){
											vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
											vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "Date Range must be shorter than one year for Part II.";
											returnValue = 1;
										}else{
											if(!confirm("The Worthless Stock record was not in Date range. Please confirm to save the changes.")){
												return false;
											}
										}
									}
								} else if((diff_in_dates <= 365 && line_no == "2") || (diff_in_dates <= 365 && line_no == "19")){
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
									vm.f4797FormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "Date Range must be longer than one year. If otherwise, please enter this data in Part II.";
									returnValue = 1;
								}
							}
							if(vm.f4797FormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE == 0 && (!vm.f4797FormData[i].Line_data[j+1].Line_data_occ[0].hasOwnProperty("ATTRIB_VALUE") ||
									(vm.f4797FormData[i].Line_data[j+1].Line_data_occ[0].hasOwnProperty("ATTRIB_VALUE") && (vm.f4797FormData[i].Line_data[j+1].Line_data_occ[0].ATTRIB_VALUE == null || vm.f4797FormData[i].Line_data[j+1].Line_data_occ[0].ATTRIB_VALUE == undefined)))){
								vm.f4797FormData[i].Line_data[j].Line_data_occ[cost_basis_index].HAS_ERROR = "Y";
								vm.f4797FormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE_ERROR = "When Cost or Other Basis amount is 0, an explanation comment must be entered!";
								returnValue = 1;
							}
							if(vm.f4797FormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE < 0 ){
								vm.f4797FormData[i].Line_data[j].Line_data_occ[cost_basis_index].HAS_ERROR = "Y";
								vm.f4797FormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE_ERROR = "As the Amounts are in Increase/Decrease format, Cost Basis should not be less than zero!";
								returnValue = 1;
							}
							if(line_no == "2" && vm.f4797FormData[i].Line_data[j].Line_data_occ[depreciation_index].ATTRIB_VALUE != 0 && vm.f4797FormData[i].Line_data[j].Line_data_occ[gain_loss_index].ATTRIB_VALUE > 0){
								vm.f4797FormData[i].Line_data[j].Line_data_occ[gain_loss_index].HAS_ERROR = "Y";
								vm.f4797FormData[i].Line_data[j].Line_data_occ[gain_loss_index].ATTRIB_VALUE_ERROR = "Please enter Gain Transactions with depreciation in Part III.";
								returnValue = 1;
							}
							if(line_no == "19" && vm.f4797FormData[i].Line_data[j].Line_data_occ[gain_loss_index].ATTRIB_VALUE < 0){
								vm.f4797FormData[i].Line_data[j].Line_data_occ[gain_loss_index].HAS_ERROR = "Y";
								vm.f4797FormData[i].Line_data[j].Line_data_occ[gain_loss_index].ATTRIB_VALUE_ERROR = "Please enter Loss Transactions in Part I.";
								returnValue = 1;
							}
						}
					}
				}
				else if (vm.f4797FormData[i].ROW_TYPE == 'DT'){					
					for(var j=0; j < vm.f4797FormData[i].Line_data.length; j++){
						var line_no = vm.f4797FormData[i].Line_data[j].LINE_NO;						
						var gain_loss_index = vm.f4797FormData[i].Line_data.findIndex(x => x.ATTRIB_SHORT_CODE == "GAIN_LOSS_"+line_no);
						if(line_no == "8" && gain_loss_index !== -1 && vm.f4797FormData[i].Line_data[gain_loss_index].ATTRIB_VALUE < 0){							
							vm.f4797FormData[i].Line_data[j].HAS_ERROR = "Y";
							vm.f4797FormData[i].Line_data[j].ATTRIB_VALUE_ERROR = "Please enter a valid amount for Line 8 in Part I.";
							returnValue = 1;
						}
					}
				}
			}
			return returnValue;
		}
		
		function getDiffDates(dateAcquired, dateSold){
			if(typeof dateAcquired === 'string'){
				dateAcquired = convertToDate(dateAcquired);
				dateSold = convertToDate(dateSold);
			}
			
			return ((dateSold -  dateAcquired)/ (1000 * 3600 * 24));
		}
		
		function convertToDate(str)
		{
		   var dateArr = str.split("/");
		   return new Date(dateArr[2], dateArr[0], dateArr[1])
		}
		
		function getUnallocatedAmt(){
			for(var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'TH'){
					if(vm.f4797FormData[i].Line_data[1].DESCRIPTION == 'Unallocated Amount' && vm.f4797FormData[i].Line_data[1].DATA_TYPE == 'number' && vm.f4797FormData[i].Line_data[1].ATTRIB_VALUE != null && vm.f4797FormData[i].Line_data[1].ATTRIB_VALUE != undefined){
						vm.unAllocatedAmt = parseInt(vm.f4797FormData[i].Line_data[1].ATTRIB_VALUE);
						console.log("the unallocatedamt was updated. And the unallocated amt is :::::", vm.unAllocatedAmt);
					}
				}
			}
		}
		
		vm.AddNewRow = function(line_no){
			console.log("The new line is created under :::::::", line_no);
			var occurrance = 1;
			var trans_details_key = null;
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'CH'){
					if(null != vm.f4797FormData[i].Line_data[vm.f4797FormData[i].Line_data.length -1].Occurrence && vm.f4797FormData[i].Line_data[vm.f4797FormData[i].Line_data.length -1].Occurrence != "" && vm.f4797FormData[i].Line_data[vm.f4797FormData[i].Line_data.length -1].Occurrence != undefined){
						occurrance = vm.f4797FormData[i].Line_data[vm.f4797FormData[i].Line_data.length -1].Occurrence + 1;
						trans_details_key = vm.f4797FormData[i].Line_data[vm.f4797FormData[i].Line_data.length -1].Trans_details_key;
					}
					
					var new_row = angular.copy(vm.f4797FormData[i].Line_data[0]);
					new_row.Line_Type = "NEW_EDIT_ROW";
					new_row.Occurrence = occurrance;
					new_row.Trans_details_key = trans_details_key;
					new_row.IS_EDITTED = "Y";
					
					if(new_row.Line_no == line_no){
						for (var j=0; j < new_row.Line_data_occ.length; j++){
							if(new_row.Line_data_occ[j].EDIT_TYPE == "custom_date_various" && new_row.Line_data_occ[j].DATA_TYPE == "dateVarious"){
								new_row.Line_data_occ[j].ATTRIB_VALUE = new Date();
							} else if (new_row.Line_data_occ[j].EDIT_TYPE == "currency" && new_row.Line_data_occ[j].DATA_TYPE == "number"){
								new_row.Line_data_occ[j].ATTRIB_VALUE = 0;
							} else if (new_row.Line_data_occ[j].EDIT_TYPE == "label" && new_row.Line_data_occ[j].DATA_TYPE == "string"){
							} else {	
								new_row.Line_data_occ[j].ATTRIB_VALUE = null;
							}
							new_row.Line_data_occ[j].OCCURRENCE = occurrance;
							new_row.Line_data_occ[j].TRANS_DETAILS_KEY = trans_details_key;
							new_row.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
						}
						vm.f4797FormData[i].Line_data.push(new_row);
					}
					
					var new_row_comments = angular.copy(vm.f4797FormData[i].Line_data[0]);
					new_row_comments.Line_Type = "NEW_EDIT_COMMENT_ROW";
					new_row_comments.Occurrence = occurrance;
					new_row_comments.Trans_details_key = trans_details_key;
					if(new_row_comments.Line_no == line_no){
						new_row_comments.Line_data_occ = [new_row_comments.Line_data_occ[new_row_comments.Line_data_occ.length-1]];
						console.log(new_row_comments.Line_data_occ);
						new_row_comments.Line_data_occ[0].OCCURRENCE = occurrance;
						new_row_comments.Line_data_occ[0].ATTRIB_VALUE = null;
						new_row_comments.Line_data_occ[0].TRANS_DETAILS_KEY = trans_details_key;
						new_row_comments.Line_data_occ[0].IS_CHANGED_FLAG = "Y";
						vm.f4797FormData[i].Line_data.push(new_row_comments);
					}
				}
			}
			console.log(vm.f4797FormData);
		}
		
		vm.editExistingRecord = function(line_no, occurrence, index){
			console.log("These are the line_no and occurrence for the editting rows", line_no, occurrence, index);
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4797FormData[i].Line_data.length; j++){
						if(j == index || j == index+1){
							if(vm.f4797FormData[i].Line_data[j].Line_Type == "DATA_ROW" && vm.f4797FormData[i].Line_data[j].Line_no == line_no && vm.f4797FormData[i].Line_data[j].Occurrence == occurrence){
								vm.f4797FormData[i].Line_data[j].Line_Type = "NEW_EDIT_ROW";
								for(var l=0; l < vm.f4797FormData[i].Line_data[j].Line_data_occ.length; l++){
									if(vm.f4797FormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "currency" && vm.f4797FormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "number"){
										vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = parseInt(vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
									} else if (vm.f4797FormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various" && vm.f4797FormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious"){
										if (vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE != "VARIOUS"){
											vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = new Date(vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
										}
									}
									//vm.f4797FormData[i].Line_data[j].Line_data_occ[l].IS_CHANGED_FLAG = "Y";
								}
							} else if (vm.f4797FormData[i].Line_data[j].Line_Type == "DATA_COMMENTS" && vm.f4797FormData[i].Line_data[j].Line_no == line_no && vm.f4797FormData[i].Line_data[j].Occurrence == occurrence){
								vm.f4797FormData[i].Line_data[j].Line_Type = "NEW_EDIT_COMMENT_ROW";
								//vm.f4797FormData[i].Line_data[j].Line_data_occ[0].IS_CHANGED_FLAG = "Y";
							}
						}
					}
					
				}
			}
			console.log(vm.f4797FormData);
		}
		
		vm.removeExistingRecord = function(line_no, Occurrence, index){
			console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4797FormData[i].Line_data.length; j++){
						if(j == index){
							if((vm.f4797FormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.f4797FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && vm.f4797FormData[i].Line_data[j].Line_no == line_no && null != vm.f4797FormData[i].Line_data[j].Occurrence && vm.f4797FormData[i].Line_data[j].Occurrence != "" && vm.f4797FormData[i].Line_data[j].Occurrence == Occurrence){
								vm.f4797FormData[i].DELETE_ROWS.push(vm.f4797FormData[i].Line_data[j]);
								vm.f4797FormData[i].Line_data.splice(j,2);
							}else if((vm.f4797FormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.f4797FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && vm.f4797FormData[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
								vm.f4797FormData[i].Line_data.splice(j,2);
							}
						}
					}
				}
			}
			vm.calcFinalAmounts();
			console.log(vm.f4797FormData);
		}
		
		vm.saveNewEdittedRecord = function(line_no, occurrence, index){
			console.log("These are the line_no and occurrence that are newly added or editted", line_no, occurrence, index);
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.f4797FormData[i].Line_data.length; j++){
						if(j== index || j== index + 1){
							if(vm.f4797FormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW" && vm.f4797FormData[i].Line_data[j].Line_no == line_no && vm.f4797FormData[i].Line_data[j].Occurrence == occurrence){
								vm.f4797FormData[i].Line_data[j].Line_Type = "DATA_ROW";
							}else if(vm.f4797FormData[i].Line_data[j].Line_Type == "NEW_EDIT_COMMENT_ROW" && vm.f4797FormData[i].Line_data[j].Line_no == line_no && vm.f4797FormData[i].Line_data[j].Occurrence == occurrence){
								vm.f4797FormData[i].Line_data[j].Line_Type = "DATA_COMMENTS";
							}
						}
						if(j== index){
							for(var l=0; l < vm.f4797FormData[i].Line_data[j].Line_data_occ.length; l++){
								if(vm.f4797FormData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.f4797FormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious" && vm.f4797FormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various"){
									vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = getDateFormat(vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
								} else if(vm.f4797FormData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.f4797FormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "string" && vm.f4797FormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "checkbox"){
									if(vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == null ||vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == undefined){
										vm.f4797FormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = "N";
									}
								}
							}
						}
					}
				}
			}
			console.log(vm.f4797FormData);
		}
		
		vm.valuechanged = function(parent_index, occurrence, line_no, index){
			console.log("These are the parent_index, occurrence and index of the editted field", parent_index, occurrence, line_no, index);
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'CH'){
					if(vm.f4797FormData[i].Line_data[parent_index] != null && vm.f4797FormData[i].Line_data[parent_index] != undefined){
						if(vm.f4797FormData[i].Line_data[parent_index].Line_Type == "NEW_EDIT_COMMENT_ROW"){
							vm.f4797FormData[i].Line_data[parent_index].Line_data_occ[0].IS_CHANGED_FLAG = "Y";
						}else {
							if(vm.f4797FormData[i].Line_data[parent_index].Line_no == line_no && vm.f4797FormData[i].Line_data[parent_index].Occurrence == occurrence){
								vm.f4797FormData[i].Line_data[parent_index].Line_data_occ[index+1].IS_CHANGED_FLAG = "Y";
								vm.f4797FormData[i].Line_data[parent_index].Line_data_occ[index+1].HAS_ERROR = "N";
							}
						}
					}
				}
				if(vm.f4797FormData[i].ROW_TYPE == 'DT'){
					if(i == parent_index && vm.f4797FormData[i].Line_data[index] != null && vm.f4797FormData[i].Line_data[index] != undefined){
						vm.f4797FormData[i].Line_data[index].IS_CHANGED_FLAG = "Y";
					}
				}
			}
			console.log(vm.f4797FormData);
		}
		
		vm.updateAmount = function(index, line_no){
			console.log("We are updating the amounts for this column:::::: ", index);
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'CH' && vm.f4797FormData[i].Line_data[0].Line_no == line_no){
					for(var j=0; j < vm.f4797FormData[i].Line_data[index].Line_data_occ.length; j++ ){
						if(vm.f4797FormData[i].Line_data[index].Line_data_occ[j].ATTRIB_CALC_FORMULA != null){
							var line_data_values = vm.f4797FormData[i].Line_data[index].Line_data_occ;
							vm.f4797FormData[i].Line_data[index].Line_data_occ[j].ATTRIB_VALUE = vm.calcLineFormula(vm.f4797FormData[i].Line_data[index].Line_data_occ[j].ATTRIB_CALC_FORMULA, line_data_values);
							vm.f4797FormData[i].Line_data[index].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
							vm.f4797FormData[i].Line_data[index].Line_data_occ[j].HAS_ERROR = "N";
						}
					}
				}
			}
			vm.calcFinalAmounts();
		}
		
		vm.calcLineFormula = function(formula, line_data_values){
			console.log("Given formula and index of the row are ", formula, line_data_values);
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					var currentTabAtrribVal = $filter("filter")(line_data_values, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var lineAmt = parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			console.log("Given formula and index of the row are ", formula, line_data_values);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		}
		
		//console.log(vm.calcFormula((SALE_PRICE_1)-(COST_BASIS_1), 2));
		vm.calcFinalAmounts = function(){
			for (var i=0; i < vm.f4797FormData.length; i++){
				if(vm.f4797FormData[i].ROW_TYPE == 'TH'){
					for(var j=0; j < vm.f4797FormData[i].Line_data.length; j++){
						if(vm.f4797FormData[i].Line_data[j].ATTRIB_CALC_FORMULA != null){
							vm.f4797FormData[i].Line_data[j].ATTRIB_VALUE = vm.calc_final_amt(vm.f4797FormData[i].Line_data[j].ATTRIB_CALC_FORMULA);
							vm.f4797FormData[i].Line_data[j].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}
			getUnallocatedAmt();
		}
		
		vm.calc_final_amt = function(formula){	
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				var lineAmt = 0;
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					for(var k=0; k < vm.f4797FormData.length; k++){
						if(vm.f4797FormData[k].ROW_TYPE == 'CH'){
							for(var j=0; j < vm.f4797FormData[k].Line_data.length; j++){
								if(vm.f4797FormData[k].Line_data[j].Line_Type == "DATA_ROW" || vm.f4797FormData[k].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
									var row_data = vm.f4797FormData[k].Line_data[j].Line_data_occ;
									var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
									if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
										lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
									}
								}
							}
						} else {
							var row_data = vm.f4797FormData[k].Line_data;
							var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
							if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
								lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
							}
						}
					}
					//console.log("Formula before Replacing with amount========", formula);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			//console.log("Given formula and index of the row are ", formula);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		}
	
		vm.enterSectionDetails = function (attrib_short_code, mainIndex, parentIndex, attribValue, occurrence) {			
			console.log("enterSectionDetails Params::::", attrib_short_code, mainIndex, parentIndex, attribValue, occurrence);
			console.log(vm.f4797FormData);
			var sourceRow = [];
			sourceRow.sectionVal = attribValue;
			sourceRow.occurrence = occurrence;
			sourceRow.tax_year = GlobalService.globalParams.tax_year;
			sourceRow.scenario = GlobalService.globalParams.scenario;
			var short_desc = attrib_short_code.substring(0, attrib_short_code.lastIndexOf("_"));
			
			if(short_desc != null && short_desc == "SECTION_TYPE" && (attribValue !== "1245")){
				$uibModal.open({
					templateUrl: 'app/components/Forms/SectionDetails/SectionDetailsModal.html',
					controller: 'SectionDetailsCtrl as ctrl',
					size: 'md',
					backdrop: 'static',
					resolve: {
						dataRow: function () {							
							return vm.f4797FormData[mainIndex].Line_data[parentIndex];
						},
						rowData: function () {
							return vm.rowData;
						},
						sourceRow: function () {
							return sourceRow;
						}
					}
				});
			}
		}

	}
});
