define([
	'angular'

], function () {
	'use strict';


	var services = angular.module('app.jdcManagerService', [])
		.factory("JdcManagerFactory", ['AlertService','ClientAdminFactory', '$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'GlobalService', 'DataFiltersFactory',
		'workspaceFactory', 'JsonObjectFactory', 'GENERAL_CONFIG','Dashboard901Factory',
		function (AlertService,ClientAdminFactory, $q, $http, $stateParams, $timeout, $rootScope, $log, GlobalService, DataFiltersFactory,
			workspaceFactory, JsonObjectFactory ,GENERAL_CONFIG,Dashboard901Factory) {
			var jdcManagerFactory = {};


			var URLS = {
				FETCH: GENERAL_CONFIG.base_url+ '/getApplicationJCDList',
				UPDATE: GENERAL_CONFIG.base_url+ '/saveActivity',
				DELETE: GENERAL_CONFIG.base_url+ '/deleteActivity',
				FETCH_KEY: GENERAL_CONFIG.base_url+ '/fetch_jcd_key',
				NEWJCDURL: GENERAL_CONFIG.base_url+ '/addNewJcd',
				delteJcdURL: GENERAL_CONFIG.base_url+ '/deleteJCDCom',
				updateJCDURL :GENERAL_CONFIG.base_url+ '/updateNewJcd',
                GET_TAX_TYPE_DETAILS: GENERAL_CONFIG.base_url + '/getTaxTypeData',
                SHUFFLE_ACTIVITY : GENERAL_CONFIG.base_url + '/shuffleActivity',
                TASKS_ACTIVITIES : GENERAL_CONFIG.base_url + '/refreshTaskActivity',
				GET_SCENARIO_TYPE_CODES : GENERAL_CONFIG.base_url + '/getScenarioTypeCodes',
				SAVE_COPY_ACCESS : GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fwd8b9",
				GET_ACTIVITY_USERS : GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=sgsbng",
				SAVE_TEMPLATE_MAPPING : GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=f36j6z"
			}

			var dragKey;

            jdcManagerFactory.setDragKey = function(key){
                dragKey = key;
            }

            jdcManagerFactory.getDragKey = function(){
                return dragKey;
            }

			jdcManagerFactory.getInitInfo = function (filterParams) {
				var params = {
					"clientKey": filterParams.client.value
				};
				var promise = $http({
					method: "post",
					url:GENERAL_CONFIG.base_url+ '/getInitInfoForJCD',
					data: params
				}).then(function (response) {
					return response;
				});
				return promise;
			};

			jdcManagerFactory.getTaxTypeDetails = function () {
				var promise = $http({
					method: "GET",
					url: URLS.GET_TAX_TYPE_DETAILS
				}).then(function (response) {
					return response;
				});
				return promise;
			};

			jdcManagerFactory.getScenarioTypeCodes = function () {
				var promise = $http({
					method: "GET",
					url: URLS.GET_SCENARIO_TYPE_CODES
				}).then(function (response) {
					return response;
				});
				return promise;
			};

            jdcManagerFactory.getInitNewJcd = function() {

            	var params = {};

            	var promise = $http({
            		method: "post",
            		url: GENERAL_CONFIG.base_url+ '/getNewJCDKeyInput',
            		data: params
            	}).then(function (response) {
            		return response;
            	});

            	return promise;
            } ;

			// watch function to check required fileds

			jdcManagerFactory.createWatchFunction = function ($scope, vm,moduleName, fieldName,requiredFields) {
				$scope.$watch(moduleName+fieldName, function (newVal, oldVal) {
					console.log(newVal, oldVal);
					//$scope.saveTaskFrm['task_status'].$dirty && vm.isMissing(vm.task.task_status);
					if (oldVal == "" && newVal !== ""){
						if (newVal && jdcManagerFactory.isMissing(newVal)) {
							vm[requiredFields][fieldName] = true;
						}
					}else{
						vm[requiredFields][fieldName] = false;
					}
				});
			}

			//saveChannel
			jdcManagerFactory.saveChannel = function($scope,vm,rowData,callBackMethod){

				if (vm.mode != 'edit')
				if (_.find(vm.channelList, function(item){ return item.channel_name.toLowerCase() == vm.channels.channel_name.toLowerCase()} )) {
					AlertService.add("error", "channel '"+vm.channels.channel_name+"' already exist!");
					return;
				}

				var payLoadForSaving = [{
					CHANNEL_NAME: vm.channels.channel_name,
					CHANNEL_DESC: vm.channels.channel_desc,
					CHANNEL_PRIORITY: vm.channels.channel_priority,
					CHANNEL_STATUS: vm.channels.channel_status.value,
					CLIENT_KEY: vm.channels.client.client_key
				}];
				var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=nhuasw";
				if (vm.mode == 'edit') { //edit action code--- for update, here were are passing "channel_key"
					payLoadForSaving[0]["CHANNEL_KEY"] = vm.channels.channel_key||rowData.channel.channel_key;
					url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=h29d87";
				}

				var jsonObj = JSON.stringify(payLoadForSaving);
				var jsonSettings = '{}';
				var params = _.extend({
					jsonObj: jsonObj
				}, params);
				params = _.extend({
					jsonSettings: jsonSettings
				}, params);

				JsonObjectFactory.saveJSON(url, params).then(function (data) {
					vm.channels.loading = false;
					if (data.callSuccess === "1") {
						AlertService.add("success", "channel " + vm.channels.channel_name + " " + (vm.mode == 'edit' ? "edited" : "saved") + " successfully.");
						$rootScope.$broadcast('gtw.admin.jdc.loadResultForJDCSettings', vm.channels.client.client_key);
						//vm.clearFormChannel();
						if(callBackMethod){
							callBackMethod();
						}
					} else {
						AlertService.add("error", " Problem saving channel: ");
					}
				});

			}
			// saveTask --- Task Screen and activity screen task

			jdcManagerFactory.saveTask = function ($scope, vm, formName,mode,callBackMethod,rowData) {
				var payLoadForSaving = [];
				var dataArray = [];
				if(mode === 'reorder'){
					dataArray = vm[formName].tasksList;
				}else{
					dataArray.push(vm[formName]);
				}
				var filterParams = {
					client: {
						name: vm[formName].client.client_name,
						value: vm[formName].client.client_key
					},

					jurisdiction: {
						name: 'US',
						value: 1
					},
					domain: {
						name: 'Direct',
						value: 9
					},
					channel: {
						name: vm[formName].channel.channel_name,
						value: vm[formName].channel.channel_key
					}
				};

				// JCD validation

				jdcManagerFactory.getJCDKey(filterParams).then(function (response) {
					if (!response.data.jcdKey || response.data.jcdKey == 0) {
						AlertService.add("error", "Please select valid JCD combination !");
						return;
					}
					// saving the channel screen payLoad in DB (create,update)
					function taskMapping(obj, i){
						payLoadForSaving.push({
							TASK_PRIORITY: i,
							TASK_KEY: obj.task_key ? obj.task_key : undefined,
							TASK_NAME: obj.task_name,
							TASK_DESC: obj.task_desc,
							TASK_STATUS: obj.task_status.value ? obj.task_status.value : obj.task_status,
							JCD_KEY: response.data.jcdKey,
							PARENT_TASK_KEY: obj.parentTask,
							CLIENT_KEY: filterParams.client.value
				        })
				        
			        	angular.forEach(obj.sub_task, function(obj, i){
			        		taskMapping(obj, i + 1);
			        	});
					}
					angular.forEach(dataArray, function(obj, i){
				        taskMapping(obj, dataArray.length == 1 ? dataArray[0].task_priority : i + 1);
				    });

					var url = GENERAL_CONFIG.base_url+ "/saveJsonObject?action_code=6aoylm";
					if (mode == 'edit' || mode == 'reorder') { //edit action code 4dk04a
						url = GENERAL_CONFIG.base_url+ "/saveJsonObject?action_code=4dk04a";
					}
					var jsonObj = JSON.stringify(payLoadForSaving);
					var jsonSettings = '{}';
					var params = _.extend({
						jsonObj: jsonObj
					}, params);
					params = _.extend({
						jsonSettings: jsonSettings
					}, params);
					JsonObjectFactory.saveJSON(url, params).then(function (data) {
						if (data.callSuccess === "1") {
							if(callBackMethod){
								callBackMethod();
							}
						} else {
							AlertService.add("error", " Problem saving task: " + data.errorMessage,4000);
						}
					});
				});
			};

			jdcManagerFactory.shuffleActivity = function(activities) {
            	var params =  _.extend({data :activities});
            	var promise = $http({
            		method: "POST",
            		url: URLS.SHUFFLE_ACTIVITY,
            		data: params
            	}).then(function (response) {
            		return response.data;
            	});
            	return promise;
            };

			function uploadTaskImage(taskKey,taskType,vm){


				var objContext = {

					parent_key: taskKey,//vm.rowData.COMBINATION_KEY,
					parent_type: taskType,
					attachments: vm.attachmentTO.attachmented_files.toString(),
					oldFiles: _.map(vm.attachmentList, "file_name","attachment_key").toString()
				};
				Dashboard901Factory.saveAttachment(GENERAL_CONFIG.base_url + '/saveAttachmentDocuments', objContext).then(function (data) {
					console.log(data);
					vm.crudLoading = false;
					//   if (data.callSuccess && data.unreadMessageCount === "callSuccess") {
					if (data.callSuccess === "1") {
							console.log("attachem ++++ data", data);
							$uibModalInstance.dismiss('cancel');
							AlertService.add("callSuccess", "attachment saved callSuccessfully.", 4000);
					} else {
							AlertService.add("error", "Error occurred fetching data. If this continues please contact support", 4000);
					}
				});
			}
			// validate the requiredFields based on the formName and moduleName and requiredFileds array

			jdcManagerFactory.validateRequiredFileds = function ($scope, vm, formName, moduleName,requiredFields) {
				var fieldNames = _.keys(requiredFields),
					isRequiredFieldsMissing = false;
				for (var i = 0; i < fieldNames.length; i++) {
					if(formName==null){
						if (jdcManagerFactory.checkDirtyAndMissing($scope, vm, null, moduleName, fieldNames[i])) {
							isRequiredFieldsMissing = true;
							requiredFields[fieldNames[i]] = true;
					} else {
						requiredFields[fieldNames[i]] = false;
					}
					}else{
						$scope[formName][fieldNames[i]].$dirty = true;
						if (jdcManagerFactory.checkDirtyAndMissing($scope, vm, formName, moduleName, fieldNames[i])) {
							isRequiredFieldsMissing = true;
							requiredFields[fieldNames[i]] = true;
						} else {
							requiredFields[fieldNames[i]] = false;
						}
					}
				}

				return isRequiredFieldsMissing;
			}

			// get the clientList initail load of page

			jdcManagerFactory.clientList = [];
			jdcManagerFactory.getAllClients = function () {
				return ClientAdminFactory.getAllClients();
			}
			jdcManagerFactory.isMissing = function (obj) {
				return (angular.isUndefined(obj) || (obj == null) || (obj == ''));
			}

			jdcManagerFactory.checkDirtyAndMissing = function ($scope, vm, formName, saveTOName, fieldName) {
				if(formName==null){
					return jdcManagerFactory.isMissing(vm[saveTOName][fieldName]);
				}else{
					return $scope[formName][fieldName].$dirty && jdcManagerFactory.isMissing(vm[saveTOName][fieldName]);
				}

			};

			jdcManagerFactory.selectedJCDKey = null;
			jdcManagerFactory.getJCDKey = function (filterParams) {

				var params = {
					"clientKey": filterParams.client.value,
					"jurisdictionKey": filterParams.jurisdiction.value,
					"domainKey": filterParams.domain.value,
					// "jurisdictionKey": 1,
					// "domainKey": 9,
					"channelKey": filterParams.channel.value
				};

				var promise = $http({
					method: "post",
					url: URLS.FETCH_KEY,
					data: params
				}).then(function (response) {
					console.log("response :  ", response);
					jdcManagerFactory.selectedJCDKey = response.data.jcdKey;
					return response;
				});

				return promise;
			};


			jdcManagerFactory.loadResultForJDC = function (filterParams) {
				var params = {
					"clientKey": filterParams.client.value,
					"jurisdictionKey": filterParams.jurisdiction.value,
					"domainKey": filterParams.domain.value,
					// "jurisdictionKey": 1,
					// "domainKey": 9,
					"channelKey": filterParams.channel.value
				};
				var promise = $http({
					method: "post",
					url: URLS.FETCH,
					data: params
				}).then(function (response) {
					console.log("response :  ", response);
					return response;
				});
				return promise;
			};

			jdcManagerFactory.getTasksHierarchy = function(filterParams){
				var params = {
					"clientKey": filterParams.client.value,
					"jurisdictionKey": filterParams.jurisdiction.value,
					"domainKey": filterParams.domain.value,
					"channelKey": filterParams.channel.value
				};
                var promise = $http({
                    method: "post",
                    url: URLS.TASKS_ACTIVITIES,
                    data: params
                }).then(function (response) {
                    if(response.data !== undefined){
                        return response.data;
                    }else {
                        if(response.data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
                return promise;
            };

			jdcManagerFactory.loadResultForJDCSettings = function (filterParams) {

				var params = {
					"clientKey": filterParams.clientKey
				};
				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.base_url+ '/ajaxGetJcdSettings',
					//data: {}
					data : params
				}).then(function (response) {
					console.log("response :  ", response);
					return response;
				});
				return promise;
			};

			//getting the task list based on JCD combination

			jdcManagerFactory.getTaskForJCD = function (filterParams,doConverstion) {

				var params = {
					"clientKey": filterParams.client.value,
					"jurisdictionKey": filterParams.jurisdiction.value,
					"domainKey": filterParams.domain.value,
					"channelKey": filterParams.channel.value
				};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.base_url+ '/getTaskForJCD',
					data: params
				}).then(function (response) {
					console.log("jdc service tasks",response);
					if(doConverstion && response.data.taskList){
						return addIndent(response.data.taskList);
					}else{
						return [];
					}
				});
				return promise;
			}

			function addIndent(list){
				var taskList=[];
				/* task Hierarchy starts here */

				function transformToTree(list){
					var nodes = {};
					return list.filter(function(obj){
						var id = obj["task_key"],parentId = obj["parent_task_key"];
						nodes[id] = _.defaults(obj, nodes[id],{children:[]});
						parentId && (nodes[parentId] = (nodes[parentId] || { children: [] }))["children"].push(obj);
						return !parentId;
					});
				}
				/* call the JSON TREE */
				list = transformToTree(list);
				/* task Hierarchy for drop dwon  */
				var times = function (n, str) {
					var result = '';
					for (var i = 0; i < n; i++) {
						result += str;
					}
					return result;
				};

				var recur = function (item, level, arr) {
					arr.push({
						task_name: item.task_name,
						task_key: item.task_key,
						level: level,
						indent: times(level, '\u00A0\u00A0\u00A0')
					});
					if (item.children){
						item.children.forEach(function (item){
							recur(item, level + 1, arr);
						});
					}
				};
				list.forEach(function (item) {
					recur(item, 0, taskList);
				});
				/* Ends Task Hierarchy Ends Here */
				console.log("task hierarchy",taskList);
				return taskList;
			}

		 jdcManagerFactory.saveNewJcd = function(jcdNew){
			var params = {
				"data": JSON.stringify(jcdNew)
			};

                var promise = $http({
                    method: "post",
                    url: URLS.NEWJCDURL,
                    data: params
                }).then(function (response) {
                    return response;
                });
                return promise;
            };
			jdcManagerFactory.delteJcd = function(jcdNew){
				var params = {
					"data": JSON.stringify(jcdNew)
				};
				var promise = $http({
						method: "post",
						url: URLS.delteJcdURL,
						data: params
					}).then(function (response) {
						return response;
					});
				return promise;
			};
			//
			jdcManagerFactory.updateJcd = function(jcdNew){
				var params = {
					"data": JSON.stringify(jcdNew)
				};
				var promise = $http({
						method: "post",
						url: URLS.updateJCDURL,
						data: params
					}).then(function (response) {
						return response;
					});
				return promise;
			};

			jdcManagerFactory.saveActivity = function (activity) {

				var params = {
					"data": JSON.stringify(activity),
					"jcdTaRoleKeysToBeDeleted": activity.jcdTaRoleKeysToBeDeleted
				};

				var promise = $http({
					method: "post",
					url: URLS.UPDATE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			jdcManagerFactory.saveCopyAccess = function (mappedData, sourceKey, targetKey, jcdKey, clientKey, taxYear, scenario) {

				var params = {
					"roles_map": JSON.stringify(mappedData),
					"src_activity_key": sourceKey,
					"target_activity_key": targetKey,
					"jcd_key": jcdKey,
					"client_key": clientKey,
					"tax_year": taxYear,
					"scenario": scenario
				};

				var promise = $http({
					method: "post",
					url: URLS.SAVE_COPY_ACCESS,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			jdcManagerFactory.getActivityUsers = function (sourceKey, jcdKey, clientKey, taxYear, scenario) {

				var params = {
					"activity_key": sourceKey,
					"jcd_key": jcdKey,
					"client_key": clientKey,
					"tax_year": taxYear,
					"scenario": scenario
				};

				var promise = $http({
					method: "post",
					url: URLS.GET_ACTIVITY_USERS,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			jdcManagerFactory.deleteActivity = function (activity_key,jcd_key) {

				var params = {
					"activityKey": activity_key,
					"jcdKey" : jcd_key
				};

				var promise = $http({
					method: "post",
					url: URLS.DELETE,
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			jdcManagerFactory.getActivity = function (activity_key,client_key) {

				var params = {
					"activityKey": activity_key,
					"clientKey":client_key
				};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.base_url+ '/getJcdActivity',
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			jdcManagerFactory.getTask = function (task_key) {

				var params = {
					"taskKey": task_key
				};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.base_url+ '/getJcdTask',
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}


			jdcManagerFactory.getJurisdiction = function (jurisdiction_key) {

				var params = {
					"jurisdictionKey": jurisdiction_key
				};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.base_url+ '/getJuris',
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			jdcManagerFactory.getScenario = function (scenario) {

				var params = {
					"scenario": scenario
				};

				var promise = $http({
					method: "post",
					url: GENERAL_CONFIG.base_url+ '/geScenario',
					data: params
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			//indno2
			jdcManagerFactory.getTaxYearForJCD = function(filterParams){
				var params = {
					//"action_code":"indno2",
					"client_key": filterParams.client.value,
					"jurisdiction_key": filterParams.jurisdiction.value,
					"channel_key": filterParams.channel.value,
					"domain_key": filterParams.domain.value

				};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+ "/loadJsonObject?action_id=307", params).then(function (data) {
					//return JsonObjectFactory.getJSONObj('loadJsonObject', params).then(function (data) {
					if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
						AlertService.add("error", data.errorMessage);
						return false;
					} else {

						return data.jsonObject;
					}
				});

				return promise;
			}

			jdcManagerFactory.saveMapping = function (activity, mappedData) {
				var params = {
					"activity_key": activity.activity_key,
					"jcd_ta_key": activity.jcd_key,
					"template_role_map": mappedData
				};
				var data =  _.extend({clob_data : JSON.stringify(params) });
				

				var promise = $http({
					method: "post",
					url: URLS.SAVE_TEMPLATE_MAPPING,
					data: data
				}).then(function (response) {
					return response;
				});

				return promise;
			}

			return jdcManagerFactory;
		}])

	return services;
});