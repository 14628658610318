define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.formReviewDashboardCtrl', [])
                             .controller('FormReviewDashboardCtrl', ['$rootScope', 'FormReviewFactory', 'NewProformaFormReviewFactory' ,'GlobalService', 'TAX_YEARS', '$uibModal', 'AlertService', 'USER_SETTINGS', formReviewDashboardCtrl]);

    function formReviewDashboardCtrl($rootScope, FormReviewFactory, NewProformaFormReviewFactory, GlobalService, TAX_YEARS, $uibModal, AlertService, USER_SETTINGS) {
        var vm = this;
        vm.recordsCount = 0;
        vm.loadingList = false;
        vm.loadingForms = false;
        vm.expandPdfView = true;
        vm.pdfs = [];
        vm.flatData = [];
        vm.formsPerEntity = [];
        vm.isSelectAll = false;
        vm.noDataMsg = '';
        vm.requestForEntities = null;
        vm.filterCatMapping = {
            'C': 'Corporation',
            'P': 'Partnership'
        };
        vm.filterLocMapping = {
            'F': 'Foreign',
            'D': 'Domestic'
        };
        vm.filter = FormReviewFactory.formReviewFilter;
        const clientName = USER_SETTINGS.user.client.client_name;
        vm.showBSLAFilter = clientName.toUpperCase().includes('AON');
        vm.searchTerm = '';
        vm.formSearchTerm = '';

        // refresh list on global params change
        $rootScope.$on('GlobalParams is updated', function () {
            vm.selectedEntity = null;
            vm.selectedForm = null;
            vm.irs_form_name = null;
            vm.expandPdfView = true;
            vm.getEntities();
        });

        vm.isProdEnv = function () {
            //return window.location.hostname.startsWith('gtw');
            return false;
        };

        vm.initialize = function () {
            vm.getEntities(true);
        };

        vm.catChanged = function (category) {
            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = '';
            vm.filter.category = category;
            if (vm.filter.category === 'P') {
                if (vm.filter.location === 'D' && vm.filter.lEType === 'BSLA')
                    vm.filter.lEType = 'HO';
                else if (vm.filter.location === 'F' && (vm.filter.lEType === 'HO' || vm.filter.lEType === 'CONSOL'))
                    vm.filter.lEType = 'PSHIP';
                // force Foreign selection in Prod
                if (vm.isProdEnv()) {
                    vm.filter.location = 'F';
                    vm.filter.lEType = 'PSHIP';
                }
            } else {
                if (vm.filter.location === 'F' && vm.filter.lEType === 'PSHIP')
                    vm.filter.lEType = 'HO';
            }
            vm.getEntities();
        };

        vm.locationChanged = function (location) {
            if (vm.isProdEnv() && vm.filter.category === 'P') return;

            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = '';
            vm.filter.location = location;
            // reset le type on unavailable options
            if (vm.filter.category === 'C') {
                if (vm.filter.location === 'F' && (vm.filter.lEType === 'BSLA' || vm.filter.lEType === 'HOO'))
                    vm.filter.lEType = 'HO';
                else if (vm.filter.location === 'D' && vm.filter.lEType === 'CONSOL')
                    vm.filter.lEType = 'HO';
            } else {
                if (vm.filter.location === 'D' && vm.filter.lEType === 'PSHIP')
                    vm.filter.lEType = 'HO';
                else if (vm.filter.location === 'F' && (vm.filter.lEType === 'HO' || vm.filter.lEType === 'HOO'))
                    vm.filter.lEType = 'PSHIP';
            }
            vm.getEntities();
        };

        vm.typeChanged = function (lEType) {
            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = '';
            vm.filter.lEType = lEType;
            if (vm.filter.lEType === 'BSLA') {
                vm.filter.entityType = '';
            } else {
                vm.filter.entityType = '';
            }
            vm.getEntities();
        };

        vm.entityTypeChanged = function (lEType, entityType) {
            vm.loadingForms = false;
            vm.selectedEntity = vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = '';
            vm.filter.lEType = lEType;
            vm.filter.entityType = entityType;
            vm.getEntities();
        };


        // make sequential calls to initialize the screen with default selected entity
        vm.getEntities = function (sequentialCall = false) {
            vm.recordsCount = 0;
            vm.loadingList = true;
            vm.isSelectAll = false;
            vm.flatData = [];
            vm.treeData = [];
            vm.pdfs = [];
            vm.searchTerm = '';
            vm.formSearchTerm = '';
            vm.noDataMsg = 'Please select an entity from the list!';
            const selectedCategory = vm.filter.category;
            const selectedLocation = vm.filter.location;
            const selectedType = vm.filter.lEType;

            if (vm.filter.lEType === 'BSLA') {
                // load data from cache or fetch it from server if cache is empty
                if ((vm.treeData = vm.getEntitiesFromCache()).length === 0) {
                    FormReviewFactory.getViewForDropdown(selectedCategory, selectedLocation, selectedType).then(
                        (response) => {
                            const list = response.data.viewList;
                            // ignore the response if previous filter selection when api call is made
                            // does not match the current selection
                            if (!vm.filterMatch(selectedCategory, selectedLocation, selectedType)) {
                                return;
                            }
                            if (list && list.length > 0) {
                                vm.treeData = FormReviewFactory.formReviewCache[selectedCategory + '_' + selectedLocation + '_' + selectedType + '_' + GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario] = list;
                            } else {
                                vm.treeData = [{
                                    me_label: 'No Data Available!',
                                    noDataFlag: true
                                }];
                            }
                            vm.loadingList = false;
                        },
                        (err) => {
                            vm.loadingList = false;
                        }
                    );
                } else {
                    vm.loadingList = false;
                }
            } else {
                if ((vm.flatData = vm.getEntitiesFromCache()).length === 0) {
                    FormReviewFactory.getViewForDropdown(selectedCategory, selectedLocation, selectedType).then(
                        (response) => {
                            const list = response.data.viewList;
                            // ignore the response if previous filter selection when api call is made
                            // does not match the current selection
                            if (!vm.filterMatch(selectedCategory, selectedLocation, selectedType)) {
                                return;
                            }
                            if (list && list.length > 0) {
                                list.forEach((item) => {
                                    item.SOURCE_SYSTEM_FLAG = this.filter.location;
                                });
                                vm.flatData = FormReviewFactory.formReviewCache[selectedCategory + '_' + selectedLocation + '_' + selectedType + '_' + GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario] = list;

                                // call next API
                                if (sequentialCall) {
                                    vm.entitySelected(vm.flatData[0], sequentialCall);
                                }
                            } else {
                                vm.flatData = [{
                                    name: 'No Data Available!',
                                    noDataFlag: true
                                }];
                            }
                            vm.loadingList = false;
                        },
                        (err) => {
                            vm.loadingList = false;
                        }
                    );
                } else {
                    vm.loadingList = false;
                }
            }
        };

        vm.filterMatch = function (selectedCat, selectedLocation, selectedType) {
            return selectedCat === this.filter.category && selectedLocation === this.filter.location && selectedType === this.filter.lEType;
        };

        vm.getEntitiesFromCache = function () {
            return FormReviewFactory.formReviewCache[vm.filter.category + '_' + vm.filter.location + '_' + vm.filter.lEType + '_' + GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario] || [];
        };

        vm.listFilter = (item) => {
            if (this.searchTerm.length > 0) {
                return item.name.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase());
            }
            return true;
        };

        vm.formFilter = (pdf) => {
            if (this.formSearchTerm.length > 0) {
                pdf.isDisplay = pdf.checked || pdf.form_name.toString().toLowerCase().includes(this.formSearchTerm.toString().toLowerCase());
                return pdf.isDisplay;
            }
            pdf.isDisplay = true;
            return pdf.isDisplay;
        };

        vm.treeFilter = (item) => {
            // search based on the filtered result
            if (this.searchTerm.length > 0) {
                return item.me_label.toString().concat(item.me_code.toString()).toLowerCase().includes(this.searchTerm.toString().toLowerCase());
            } else {
                return !item.hidden;
            }
        };

        vm.getRecordsCount = () => {
            if (vm.flatData.length > 0) {
                if (vm.flatData[0].noDataFlag) {
                    return 0;
                }
                return vm.flatData.length;
            } else if (vm.treeData.length > 0) {
                if (vm.treeData[0].noDataFlag) {
                    return 0;
                }
                return vm.treeData.length;
            }
        };

        vm.toggle = (item) => {
            let index = this.treeData.indexOf(item) + 1;
            // Collapse
            if (!item.childrenHidden) {
                item.childrenHidden = true;
                for (let i = index; i < this.treeData.length; i++) {
                    let prev = i - 1;
                    if (this.treeData[i].lvl <= item.lvl) {
                        return;
                    } else {
                        this.treeData[i].hidden = true;
                        // this.treeData[i].childrenHidden = false;
                    }
                    // collapse folder icon if children are hidden
                    if (this.treeData[prev].lvl < this.treeData[i].lvl) {
                        this.treeData[prev].childrenHidden = true;
                    }
                }
            }
            // Expand
            else {
                item.childrenHidden = false;
                for (let i = index; i < this.treeData.length; i++) {
                    if (this.treeData[i].lvl <= item.lvl) {
                        return;
                    } else if (this.treeData[i].lvl - 1 === item.lvl) {
                        this.treeData[i].hidden = false;
                    } else {
                        this.treeData[i].hidden = true;
                    }
                }
            }
        };

        vm.treeLoaded = () => {
            this.loading = false;
        };

        vm.isSelected = (item) => {
            return item.name === vm.selectedEntity;
        };

        vm.entitySelected = (item, sequentialCall = false) => {
            vm.pdfs = [];
            vm.isLoadPdf = false;
            vm.loadingForms = true;
            vm.noDataMsg = '';
            if (vm.selectedForm !== undefined) vm.expandPdfView = true;
            vm.selectedForm = vm.selectedFormIdent = vm.irs_form_name = vm.formSearchTerm = '';
            vm.isSelectAll = false;
            vm.leName = item.le_name;
            vm.leEin = item.ein;

            if (vm.filter.lEType === 'BSLA') {
                vm.selectedEntity = item.me_label;
                vm.selectedView = item.me_code;
                vm.cdrNo = item.cdr_no;
                vm.rpPeriod = item.reporting_period;
                vm.leName = item.me_label;
            } else {
                vm.selectedEntity = item.name;
                var pieces = item.value.split('/');
                vm.selectedView = pieces[0];
                vm.cdrNo = pieces[1];
                vm.rpPeriod = item.name.substring(item.name.lastIndexOf('/') + 1, item.name.lastIndexOf(')'));
            }

            const selectedCatLocal = vm.filter.category;
            const selectedLocLocal = vm.filter.location;
            const selectedLETypeLocal = vm.filter.lEType;
            const selectedEntityLocal = vm.filter.entityType;
            const selectedViewLocal = vm.selectedView; // entity id (le_id/me_code)

            // NewProformaFormReviewFactory.getDiagStatus(GlobalService.globalParams.tax_year, vm.filter.lEType, vm.selectedView)
            //                             .then((response) => {
            //                                 if (response.data.callSuccess === '1') {
            //                                     let diagList = response.data.diagList;
            //                                     let failedList = diagList.filter((diag) => diag.diag_status === '0');
            //                                     showEntityDiagModal(diagList, failedList.length, diagList.length);
            //                                 }
            //                             });

            // read forms from cache
            if (getFormsFromCache(selectedCatLocal, selectedLocLocal, selectedLETypeLocal, selectedEntityLocal, selectedViewLocal)) return;

            if (vm.filter.category === 'C' && vm.filter.location === 'D') {
                FormReviewFactory.getTaxFormsCorpDomestic(vm.selectedView, vm.rpPeriod, vm.filter.lEType, vm.cdrNo, vm.filter.location, vm.filter.category, vm.filter.entityType, 1)
                                 .then((responses) => {
                                     if (selectedCatLocal !== vm.filter.category || selectedLocLocal !== vm.filter.location ||
                                         selectedLETypeLocal !== vm.filter.lEType || selectedEntityLocal != vm.filter.entityType || selectedViewLocal !== vm.selectedView) return; // ignore the response
                                     const getTaxFormsResponse = responses[0];
                                     const getJobIdForCorpResponse = responses[1];
                                     handleForms(getTaxFormsResponse, sequentialCall, selectedCatLocal, selectedLocLocal, selectedLETypeLocal, selectedEntityLocal, selectedViewLocal, getJobIdForCorpResponse.data.jobId);
                                 })
                                 .catch((error) => {
                                     if (selectedCatLocal !== vm.filter.category || selectedLocLocal !== vm.filter.location ||
                                         selectedLETypeLocal !== vm.filter.lEType || selectedEntityLocal != vm.filter.entityType || selectedViewLocal !== vm.selectedView) return; // ignore the response
                                     vm.pdfs = [];
                                     vm.noDataMsg = 'No Forms Available!';
                                     console.error(error);
                                     AlertService.add('error', 'Something went wrong with getting the forms!');
                                 })
                                 .finally(() => {
                                     vm.loadingForms = false;
                                 });
            } else {
                FormReviewFactory.getTaxForms(vm.selectedView, vm.rpPeriod, vm.filter.lEType, vm.cdrNo, vm.filter.location, vm.filter.category).then(
                    (response) => {
                        if (selectedCatLocal !== vm.filter.category || selectedLocLocal !== vm.filter.location ||
                            selectedLETypeLocal !== vm.filter.lEType || selectedEntityLocal != vm.filter.entityType || selectedViewLocal !== vm.selectedView) return; // ignore the response
                        handleForms(response, sequentialCall, selectedCatLocal, selectedLocLocal, selectedLETypeLocal, selectedEntityLocal, selectedViewLocal);
                    }
                ).catch(() => {
                    if (selectedCatLocal !== vm.filter.category || selectedLocLocal !== vm.filter.location ||
                        selectedLETypeLocal !== vm.filter.lEType || selectedEntityLocal != vm.filter.entityType || selectedViewLocal !== vm.selectedView) return; // ignore the response
                    vm.pdfs = [];
                    vm.noDataMsg = 'No Forms Available!';
                }).finally(() => {
                    vm.loadingForms = false;
                });
            }
        };

        function getFormsFromCache(selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView) {
            if (vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView]) {
                vm.jobId = vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].jobId || '';
                vm.pdfs = vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].pdfs || [];
                vm.loadingForms = false;
                return true;
            }
            return false;
        }

        function handleForms(formsResponse, sequentialCall, selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView, jobId) {
            vm.loadingForms = false;

            if (formsResponse.data.taxForms && formsResponse.data.taxForms.length > 0) {
                vm.pdfs = [];
                var formTypes = JSON.parse(formsResponse.data.taxForms[0].jsonString);
                formTypes.forEach((formType) => {
                    const typeName = Object.keys(formType)[0];
                    const subTypes = formType[typeName];
                    subTypes.forEach((subType) => {
                        const forms = subType['attachment_form'];
                        forms.forEach((form) => {
                            form.checked = false;
                            form.label = form.irs_form && form.bucket_sub !== '5471' ? form.irs_form + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form_name : (form.form_name.toLowerCase().includes('schedule') ? form.form_name.replace('Schedule', '-') : form.form_name);
                            form.label = form.label.toLowerCase().startsWith('f') ? form.label.substring(1) : (form.label.toLowerCase().startsWith('irs') ? form.label.substring(3) : form.label);
                            form.label = form.subform && form.form_sub_cat === 'DIFF_PDF' ? form.label + '-' + form.subform : form.label;
                            form.le_name = vm.leName;
                            form.ein = vm.leEin;
                            vm.pdfs.push(form);
                        })
                    });
                });
                // sort forms based on form_order field
                //vm.pdfs.sort((a, b) => a.form_order > b.form_order ? 1 : -1);
                vm.pdfs.sort((a, b) => Number(a.form_order) > Number(b.form_order) ? 1 : -1);

                // getting job id on the forms
                jobId = vm.pdfs[0].job_id || jobId;

                // set forms cache
                vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView] = {};
                vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].pdfs = angular.copy(vm.pdfs);
                vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].jobId = angular.copy(jobId);

                // make sequential call
                if (sequentialCall) {
                    vm.loadPDF(vm.pdfs[0]);
                }
            } else {
                vm.pdfs = [];
                vm.noDataMsg = 'No Forms Available!';
            }
        }

        function getJobId(selectedCat, selectedLoc, selectedLEType, selectedEntityType, selectedView) {
            if (vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView])
                return vm.formsPerEntity[GlobalService.globalParams.tax_year + '_' + GlobalService.globalParams.scenario + '_' + selectedCat + '_' + selectedLoc + '_' + selectedLEType + '_' + selectedEntityType + '_' + selectedView].jobId || '';
            else return '';
        }

        function showEntityDiagModal(diagList, failed, total) {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/components/newProforma/entity-diagnostics-modal.html',
                windowClass: 'entity-diag-modal',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.failed = failed;
                    $scope.total = total;
                    $scope.leid = vm.selectedView;
                    $scope.dtInstance = {};
                    $scope.entityDiagTable = {};
                    // configure datatable settings
                    $scope.entityDiagTable.options = {
                        data: diagList,
                        extraOptions: {
                            componentSettings: {
                                tableOptions: {
                                    columns: [
                                        {title: 'Diagnostic Key', data: 'diag_key', width: 70, orderable: false, className: 'data-column'},
                                        {title: 'Diagnostic Code', data: 'diag_name', width: 70, orderable: false, className: 'data-column'},
                                        {title: 'Diagnostic Description', data: 'diag_desc', width: 200, orderable: false, className: 'data-column'},
                                        {
                                            title: 'Diagnostic Status',
                                            data: 'diag_status',
                                            width: 70,
                                            orderable: false,
                                            className: 'data-column text-center',
                                            render: function (data, type) {
                                                if (type !== 'display') return data;
                                                let color = '';
                                                if (data === '0') {
                                                    color = 'style="color: red;"';
                                                    return `<span ${color}>FAILED</span>`;
                                                } else {
                                                    color = 'style="color: #008700;"';
                                                    return `<span ${color}>PASSED</span>`;
                                                }
                                            }
                                        },
                                    ],
                                    paging: false
                                }
                            }
                        }
                    };
                }]
            })
        }

        vm.selectAllPDFs = () => {
            vm.pdfs.forEach((pdf) => {
                pdf.checked = !vm.isSelectAll;
            });
            vm.isSelectAll = !vm.isSelectAll;
        };

        vm.getSelectedPDFs = () => {
            let selectedPDFs = [];
            vm.pdfs.forEach((pdf) => {
                if (pdf.checked) {
                    selectedPDFs.push({'form_name': pdf.form_name, 'subform': pdf.subform && pdf.form_sub_cat === "DIFF_PDF" ? '-' + pdf.subform : ''});
                }
            });
            return selectedPDFs;
        };

        vm.merge = () => {
            const formNames = vm.getSelectedPDFs();
            const mergePopupRef = $uibModal.open({
                animation: true,
                windowClass: 'mergeList',
                template: `
                <div class="modal-header">
                  <div class="modal-title">Merge PDFs
                    <i ng-click="uibModalInstance.close()" class="fa fa-times pull-right"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div style="font-weight: bolder;">
                    The following forms will be merged into one PDF, are you sure you want to continue?
                  </div>
                  <div style="padding: 20px 20px;">
                    <span ng-if="isLoading">Please wait while we retrieve the data...</span>
                    <span ng-if="hasLoadErr" style="color: red;">Data not available, please try again!</span>
                    <div ng-if="!isLoading" style="margin-bottom: 5px;">
                      <ul dnd-list="s3List" dnd-effect-allowed="move">
                        <li data-ng-repeat="item in s3List"
                        dnd-draggable="item"
                        dnd-moved="s3List.splice($index, 1)"
                        dnd-effect-allowed="move">
                            <dnd-nodrag>
                                <div dnd-handle class="handle">:::</div>
                                <div class="name">
                                    <span style="margin-right: 5px;">{{item.label}}:</span>
                                    <span><i ng-class="item.exist ? 'fa-check' : 'fa-times'" class="fa" ng-style="{color: item.exist ? 'green' : 'red'}"></i></span>
                                </div>
                            </dnd-nodrag>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ng-if="hasInvalidForms">Note: forms marked with x do not exist, and will be excluded from merge.</div>
                </div>
                <div class="modal-footer">
                  <span style="color: red; margin-right: 10px;">
                    Please drag & drop to change the order of the PDF forms
                  </span>
                  <span ng-if="!isLoading && !isMergeValid" style="color: red; margin-right: 10px;">
                    Selection contains less than two valid forms!
                  </span>
                  <button class="btn btn-info btn-sm" data-ng-disabled="isLoading || !isMergeValid" ng-click="merge()">
                    Merge
                  </button>
                  <button class="btn btn-warning btn-sm" ng-click="uibModalInstance.close()">Cancel</button>
                </div>
                `,
                controller: ['$scope', '$uibModalInstance', 'formNames', 'WorkflowService', function ($scope, $uibModalInstance, formNames, WorkflowService) {
                    $scope.isLoading = true;
                    $scope.formNames = formNames;
                    $scope.uibModalInstance = $uibModalInstance;
                    var formNoList = formNames.map(formName => formName.form_name);

                    WorkflowService.getPdfpFormS3UrlList(formNoList).then((response) => {
                        if (response && response.callSuccess && response.result) {
                            $scope.s3List = response.result.filter(s3 => s3 !== null);
                            console.log(formNames);
                            formNames.forEach((form) => {
                                let existingS3Urls = $scope.s3List.find((s3Url) => {
                                    if (form && form.form_name === s3Url.irs_form_no && !s3Url.exist) {
                                        console.log(form);
                                        console.log("s3Url-", s3Url);
                                        s3Url.label = form.form_name + form.subform;
                                        s3Url.exist = true;
                                        return true;
                                    }
                                });
                                if (!existingS3Urls) {
                                    let s3Url = {};
                                    s3Url.label = form.form_name + form.subform;
                                    s3Url.exist = false;
                                    s3Url.s3_url = null;
                                    $scope.s3List.push(s3Url);
                                }
                            });
                            $scope.hasInvalidForms = formNames.length > $scope.s3List.length;
                            $scope.isMergeValid = $scope.s3List.length >= 2;
                            $scope.isLoading = false;
                        } else {
                            $scope.isLoading = false;
                            $scope.hasLoadErr = true;
                        }
                    });

                    $scope.merge = function () {
                        $uibModalInstance.close($scope.s3List);
                    };
                }],
                resolve: {
                    formNames: () => formNames
                }
            });

            mergePopupRef.result.then((s3List) => {
                if (s3List) {
                    s3List = s3List.filter(s3 => s3 !== null && s3.s3_url !== null);
                    const irsFormNoList = s3List.map(({irs_form_no}) => {
                        return irs_form_no;
                    });
                    const irsFormNoListIdent = s3List.map(({irs_form_no, subform}) => {
                        return irs_form_no + (subform && form_sub_cat === "DIFF_PDF" ? '-' + subform : '');
                    });
                    const s3UrlList = s3List.map(({s3_url}) => {
                        return s3_url;
                    });
                    vm.loadPDFs(irsFormNoList, irsFormNoListIdent, s3UrlList);
                }
            })
        };

        vm.loadPDF = (pdf) => {
            vm.expandPdfView = false;
            vm.isMerge = false;
            vm.s3UrlList = [];
            vm.taxYear = GlobalService.globalParams.tax_year;
            vm.selectedFormIdent = pdf.form_name + (pdf.subform && pdf.form_sub_cat === "DIFF_PDF" ? '-' + pdf.subform : '');
            vm.selectedForm = pdf.form_name;
            vm.irs_form_name = pdf.irs_form;
            vm.jobId = getJobId(vm.filter.category, vm.filter.location, vm.filter.lEType, vm.filter.entityType, vm.selectedView);
            vm.schedule = pdf.schedule;
            vm.line = pdf.line_no;
            vm.partNo = pdf.part_no;
            vm.form_sub = pdf.subform;
            vm.form_sub_cat = pdf.form_sub_cat;
            vm.isLoadPdf = true;
            vm.le_name = pdf.le_name;
            vm.le_ein = pdf.ein;
            // call new proforma api
            vm.callNewProforma = (vm.filter.category === 'P' && vm.filter.location === 'D') ||
            (!(clientName.toUpperCase().includes('AON') && vm.filter.lEType === 'BSLA') && pdf.is_new_proforma === '1');
        };

        vm.loadPDFs = (irsFormNoList, irsFormNoListIdent, s3UrlList) => {
            vm.expandPdfView = false;
            vm.isMerge = true;
            vm.s3UrlList = s3UrlList;
            vm.taxYear = GlobalService.globalParams.tax_year;
            vm.selectedForm = irsFormNoList;
            vm.selectedFormIdent = irsFormNoListIdent;
            vm.le_name = vm.leName;
            vm.le_ein = vm.leEin;
            vm.jobId = getJobId(vm.filter.category, vm.filter.location, vm.filter.lEType, vm.filter.entityType, vm.selectedView);
            vm.irs_form_name = vm.pdfs.filter(i => i.checked).map(({irs_form}) => {
                return irs_form
            });
            vm.schedule = vm.pdfs.filter(pdf => pdf.checked).map(({schedule}) => {
                return schedule
            });
            vm.line = vm.pdfs.filter(pdf => pdf.checked).map(({line_no}) => {
                return line_no
            });
            vm.partNo = vm.pdfs.filter(pdf => pdf.checked).map(({part_no}) => {
                return part_no
            });
            vm.form_sub = vm.pdfs.filter(pdf => pdf.checked).map(({subform}) => {
                return subform
            });
            vm.form_sub_cat = vm.pdfs.filter(pdf => pdf.checked).map(({form_sub_cat}) => {
                return form_sub_cat
            });
            vm.is_new_proforma = vm.pdfs.filter(pdf => pdf.checked).map(({is_new_proforma}) => {
                return is_new_proforma
            });
            vm.isLoadPdf = true;
            // call new proforma api under PSHIP / Domestic
            vm.callNewProforma = vm.filter.category === 'P' && vm.filter.location === 'D';
        };

        vm.getSelectedForms = () => {
            return vm.selectedForm ? vm.selectedForm.toString() : '';
        };
    }

    return controllers;
});
