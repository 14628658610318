define([
    'angular',
    'ui-tree',
    'ui-tree-filter',
    './bizzLockCtrl',
], function () {
    'use strict';
    return angular.module('app.businessLockScreen', ['app.bizzLockCtrl', 'ui.toggle', 'ngAnimate', 'ui.tree', 'ui.tree-filter'])
                  .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider) {

                  }]);
});
