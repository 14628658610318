
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.reviewadjService',[])

    .factory('ReviewAdjServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){        	 var reautomations = {};
	 var reautomations = {};


    var URLS = {
       // FETCH: GENERAL_CONFIG.base_url + '/getProjectTag', // we can use this when we have more URL'S
        SAVE: GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=g9uv87"
    }

    reautomations.savereviewadj = function(_data,_schdMs){

        var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
        var jsonObj = JSON.stringify(_schdMs);
        var jsonSettings =  JSON.stringify(_data);

        var params = filterParams;
        params = _.merge({},  GlobalService.globalParams , params);
        params =  _.extend({jsonObj:jsonObj}, params);
        params =  _.extend({jsonSettings:jsonSettings}, params);
        //params =  _.extend({jsonSettings:jsonSettings}, _data);
        params.process_name =  "SAVE_REVIEW_ADJ_AUTOMATIONS";
        console.log(params);
        var promise = $http({
            method: "post",
            url: URLS.SAVE,
            data: params
        }).then(function (response) {
            var status = response.status;
            return response.data;
        });

        return promise;

    
    }



    return reautomations;
    }])

    return services;

});