/**
 * Created by 400371031 on 12/4/2019.
 */

define([
    'angular',
    './ReportableTransactionCtrl',
    './ReportableTransactionService'

], function () {
    'use strict';
    return angular.module('app.ReportableTransaction', ['app.ReportableTransactionCtrl', 'app.ReportableTransactionService']);

    /*.config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
        $stateProvider
            .state('Forms.RepTransaction', {
                url: '/ReportableTransactionModal',
                templateUrl: 'app/components/Forms/RepTransaction/ReportableTransactionModal.html',
                controller: 'ReportableTransactionCtrl as ctrl',
                type:'',
                // onEnter: ['$rootScope','$uibModal', '$state', function($rootScope,$uibModal, $state) {}],
                api: {},
                access: 4
            });
            
    }]);*/

});