define(
		[ 'angular'

		],
		function() {
			'use strict';

			var controllers = angular.module('app.1065ScheduleM2Ctrl', [])
					.controller('F1065M2Ctrl', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModal', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','F1065ScheduleM2ServiceFactory', 'rowData', 'gridData', F1065M2Ctrl])

			
			function F1065M2Ctrl($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModal, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,F1065ScheduleM2ServiceFactory,rowData, gridData) {
				var vm = this;
				vm.userSettings = USER_SETTINGS;
				vm.logged_in_user = vm.userSettings.user.sso_id;
				vm.defaultsMultipleSelected = [];
				vm.gridData = {};
				vm.partnerShipObj = {};
				var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
				vm.currentyear = filterParams.tax_year;
				vm.isSaveClicked = false;
				vm.loadingData = true;
				vm.tableHeader = [];
				vm.originalTabsList = [];
				vm.originalTablsOutterList = [];
				vm.issue_description = '';

				vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

				vm.title = 'Edit M-2/K-1 Partner Capital Accounts on a tax basis or 704b basis ( Tax Year: ' + vm.currentyear + ' - Scenario: ' +  vm.scenarioLabel + ' )'; 

				vm.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
				
			
		        function fetch1065PartnerRecords() {

					if(rowData.F1065_M2K1_1_OVERRIDE_TAX_0 != undefined) {
						vm.section_name = 'TAX'
					}
					else if(rowData.F1065_M2K1_1_OVERRIDE_0 != undefined) {
						vm.section_name = 'GAAP'
					}
		        	var params = {
		            		"action_code": '74y1n1', 
		            		"sso_id": vm.logged_in_user, 
		            		"tax_year": vm.currentyear, 
		            		"scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": rowData.COMBINATION_KEY, "jcd_ta_key": null,"section_name":vm.section_name};
		            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						if(data.jsonObject[0].ISSUE_KEY != 0) {
							vm.issue_description = data.jsonObject[0].ISSUE_SHORT_DESC;
						}
						else {
							vm.issue_description = '';
						}
		            	vm.formHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"FH"});
						vm.tableHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"TH"});
						
						vm.formList = data.jsonObject.filter(function( obj ) {
							return obj.ROW_TYPE !== 'FH' && obj.ROW_TYPE !== 'TH' ;
						});
						
						vm.outerList = [];
						for(var i=0;i<vm.formList.length;i++){
							if(vm.formList[i].FORM_LINE_KEY != null){
								if(vm.outerList != null && vm.outerList.length > 0){
									var tempCheck1 = $filter("filter")(vm.outerList, {FORM_LINE_KEY:vm.formList[i].FORM_LINE_KEY, IS_FORM_DISPLAY:'Y'});
								}
								if(tempCheck1 == undefined || tempCheck1.length==0){
									vm.outerList.push(vm.formList[i]);
								}
							}
						}
//						vm.outerList = _.uniq(_.map(vm.formList, 'FORM_LINE_KEY'));
//						vm.outerList1 = _.uniq(vm.formList, function(obj) {
//							return obj.IS_FORM_DISPLAY == 'Y' ;
//							});
						
		        		
						//vm.formListOriginal = angular.copy(vm.formList);
						
						
//						vm.allTabsFormHeader.push(vm.formHeader);
//						vm.allTabsTableHeader.push(vm.tableHeader);
//
//						vm.allTabsList.push(vm.formList); //Existing Data
//						vm.allTablsOutterList.push(vm.outerList);

						vm.originalData = angular.copy(vm.formList);
						vm.originalOutterData = angular.copy(vm.outerList);

						vm.originalTabsList.push(vm.originalData); // Previous Original Data
						vm.originalTablsOutterList.push(vm.originalOutterData);
						
						vm.loadingData = false;
		            });
		        }

		        fetch1065PartnerRecords();
		        
		        vm.itemTracker= function(item) {
		        	  return item.LINE_ORDER + '-' + item.ATTRIB_ORDER;
		        	}
		        
		        
				vm.fetchModalCalcData = function(order) {
//					
//					var params = { "action_code": 'veltqh', "tax_year": vm.currentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
//							"form_no":'1065',"schedule_name":'M2K1',"part_no":'',"section_name":'' };
					var params = { "action_code": 'veltqh', "tax_year": vm.currentyear, "scenario": GlobalService.globalParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
							"form_no":'1065',"schedule_name":'M2K1',"part_no":null,"section_name":vm.section_name };
					return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
						vm.formCalcData =  data.jsonObject;
					});
				}
		       
				vm.fetchModalCalcData();
				
				vm.allocate = function() {
					//excluded lines 5,8,9 because we don't want to allocate amounts for total lines
					var partnerAttributes = _.filter(vm.formList, function(o) { 
						return (o.ATTRIB_CLASS == 'PTR_ATTRIB' && (o.LINE_NO != '5' && o.LINE_NO != '8' &&  o.LINE_NO != '9'))
					})
					//partners calculations
					for(var p=0; p < partnerAttributes.length; p++){
						
						var overrideAttribute = _.filter(vm.formList, function(o) { 
							return o.ATTRIB_CLASS == 'DEF_ATTRIB' &&  o.LINE_ORDER == partnerAttributes[p].LINE_ORDER && o.ATTRIB_COL == 'b';
						})
						
						//calculations for partners based on ratio
						var partnerCalcVal = eval((overrideAttribute[0].ATTRIB_VALUE) * (partnerAttributes[p].POSTING_RATIO));
						
						//line Partners - Begin: This change is to allocate amount for last partner correctly 4/13/2021
						var linePartners = _.filter(vm.formList, function(o) { 
							return o.ATTRIB_CLASS == 'PTR_ATTRIB' &&  o.LINE_ORDER == partnerAttributes[p].LINE_ORDER && o.LINE_NO == partnerAttributes[p].LINE_NO;
						});
						var partnerTotalSum = 0;
						for(var q=0; q < linePartners.length; q++){
							// Do something if is the last iteration of the array 4/13
							if((q + 1) == (linePartners.length)){
								if (linePartners[q].ATTRIB_DESCRIPTION == partnerAttributes[p].ATTRIB_DESCRIPTION){
									partnerCalcVal = eval((overrideAttribute[0].ATTRIB_VALUE) - (partnerTotalSum));
								}
							}else{
								partnerTotalSum += parseInt(linePartners[q].ATTRIB_VALUE);
							}
						}
						//End
						
					    if(vm.section_name == 'TAX' && partnerAttributes[p].LINE_NO == '3'){
					    	partnerAttributes[p].ATTRIB_VALUE =  partnerAttributes[p].ATTRIB_VALUE;
					    }else {
					    	partnerAttributes[p].ATTRIB_VALUE = partnerCalcVal.toFixed();
					    }
					    
					}
					 //added 4/13/2021 This change is to calculate amounts for line 5,8,9 on allocate by calc Formula
				    //vm.varianceCalc();
					var calcTotalLines = [];
					calcTotalLines = overrideAttribute.concat(linePartners);
					for(var c=0; c < calcTotalLines.length; c++){
						vm.validateSubForm(calcTotalLines[c]);
					}
				}
				
				vm.varianceCalc = function() {
					// ALL VARIANCE ATTRIBUTES
					var varianceAttributes = _.filter(vm.formList, function(o) { 
						return o.ATTRIB_CLASS == 'DEF_ATTRIB' && o.ATTRIB_COL == 'c';
					})
					
					//partners calculations
					for(var v=0; v < varianceAttributes.length; v++){
						
						var overrideAttribute = _.filter(vm.formList, function(o) { 
							return o.ATTRIB_CLASS == 'DEF_ATTRIB' &&  o.LINE_ORDER == varianceAttributes[v].LINE_ORDER && o.ATTRIB_COL == 'b';
						})
						//will give all partners for that line
						var allPartnerAttributes = _.filter(vm.formList, function(o) { 
							return o.ATTRIB_CLASS == 'PTR_ATTRIB' &&  o.LINE_ORDER == varianceAttributes[v].LINE_ORDER; 
						})	
						var totalSum = 0;
						for(var x = 0; x <allPartnerAttributes.length; x++){
							 totalSum += parseInt(allPartnerAttributes[x].ATTRIB_VALUE);
						}
						//varinace Calc
						var varianceCalcVal = eval((overrideAttribute[0].ATTRIB_VALUE) - parseInt(totalSum));
						if(vm.section_name == 'TAX' && varianceAttributes[v].LINE_NO == '3')
						    varianceAttributes[v].ATTRIB_VALUE = varianceAttributes[v].ATTRIB_VALUE;
						else
						varianceAttributes[v].ATTRIB_VALUE = varianceCalcVal.toFixed();
						
					}
				}
				
				vm.validateSubForm = function(line) {
					if(line == null || line.DATA_TYPE == 'number'){
//						if(line.ATTRIB_CLASS == 'DEF_ATTRIB'){
							for(var g=0; g<vm.formCalcData.length; g++){
								vm.calcAmt(vm.formCalcData[g].ATTRIB_CALC_FORMULA, vm.formCalcData[g].LINE_NO, vm.formCalcData[g].LINE_ATTRIB_KEY, vm.formCalcData[g].LINE_ORDER, vm.formCalcData[g].ATTRIB_ORDER, line.ATTRIB_ORDER, line.ATTRIB_CLASS);
							}
//						}
				
						vm.varianceCalc();
					}
				};
				
				
				//Note to use calcAmt::::---------------------------------- 
				//1). Example to call calcAmt is if line1d = (line1a + line1b + line1c) then you need to call calcAmt("(1a)+(1b)+(1c)", "1d");
				//2). If line17d = line17c*0.40 then you need to call calcAmt("(17c)*40/100", "17d"); (no Float values in formula)
				//3). Need to check if the line has Multiplier
//				function calcAmt(formula, line_no, attrib_key, attrib_col, edit_type){
				vm.calcAmt = function(formula, line_no, attrib_key, line_order, attrib_order, line_attrib_order, attrib_class){
					vm.currentFormula = [];
					vm.currentFormula = formula;
					console.log("Formula-----" , vm.currentFormula);
					var calcLineAmt = 0;
					var lineAmt = 0;
					var partnerAmt = 0;
		
						var matches = formula.match(/\b[^\d\s]+\b/g);
						if(matches ==  null){
							var lineFormulaArray = formula.split(/[+-/\\*\\]/);
						}else{
							var formulaLength = formula.length;
							var subFormula = "";
							subFormula = formula.substring(formula.indexOf("("),formulaLength-1);
							var lineFormulaArray = subFormula.split(/[+-,/\\*\\]/);
						}

					for(var i=0; i<lineFormulaArray.length; i++){
						if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
							lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
							
							var currentTabNewAtrribVal = _.filter(vm.formList, function(o) { 
								return o.ATTRIB_SHORT_CODE == lineFormulaArray[i].trim(); 
							})
							
							if(attrib_class == 'PTR_ATTRIB'){
								//start  partners
								//get all partners to sum total lines
								var allPartnersAttribs = _.filter(vm.formList, function(o) { 
									return o.ATTRIB_ORDER == line_attrib_order && o.ATTRIB_CLASS == 'PTR_ATTRIB'; 
								})
								
								var currentPartnerNewAtrribVal = _.filter(allPartnersAttribs, function(o) { 
								return o.ATTRIB_SHORT_CODE == currentTabNewAtrribVal[0].ATTRIB_SHORT_CODE.trim(); 
								})
								
								lineAmt = parseFloat(currentPartnerNewAtrribVal[0].ATTRIB_VALUE);
							//end partners
							}else{
								lineAmt = parseFloat(currentTabNewAtrribVal[0].ATTRIB_VALUE);
								
							}
								

							//we dont need to compare old and new attrib
//							var currentTabOldAtrribVal = $filter("filter")(vm.originalTabsList, {FORM_NAME:'1065'});
//							var oldAttribVal = $filter("filter")(currentTabOldAtrribVal[0], {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
							
//							if(lineAmt != parseFloat(oldAttribVal[0].ATTRIB_VALUE)){
//								currentTabNewAtrribVal[0].IS_CHANGED_FLAG = 'Y';
//							}
							if(parseFloat(lineAmt)>= 0){
								formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
							}else{
								formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
							}
						}
					}

					if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself
						if(attrib_class == 'PTR_ATTRIB'){
							var changedFormulaAttribVal = $filter("filter")(vm.formList, {LINE_NO : line_no, LINE_ORDER : line_order, ATTRIB_ORDER : line_attrib_order });
						}else{
							var changedFormulaAttribVal = $filter("filter")(vm.formList, {LINE_NO : line_no, LINE_ORDER : line_order, ATTRIB_ORDER : attrib_order });
						}
						var calculatedVal = eval(formula);

						if(parseFloat(changedFormulaAttribVal[0].ATTRIB_VALUE) != calculatedVal.toFixed()){
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
								
							console.log("Changed Flag-----" , changedFormulaAttribVal[0].LINE_ATTRIB_KEY);
							//changedFormulaAttribVal[0].IS_CHANGED_FLAG = 'Y';
						}else{
								changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
						}


					}
				};
				
				
				if (gridData != null) {

					var tempGridData = _.clone(gridData), groups = Object
							.create(null), result, finalKeyCheck = 0;
					
					vm.gridDataMultipleSelect = _.clone(tempGridData);
					
					if (vm.gridDataMultipleSelect != undefined) {
								vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect);
								
								vm.partnerShipObj["combination_key"] = vm.defaultsMultipleSelected[0].rowData.COMBINATION_KEY;
								vm.partnerShipObj["leid"] = vm.defaultsMultipleSelected[0].rowData.LEID;
								vm.partnerShipObj["tax_code"] = vm.defaultsMultipleSelected[0].rowData.CDR_NO;
								vm.partnerShipObj["reporting_period"] = vm.defaultsMultipleSelected[0].rowData.REPORTING_PERIOD;
								vm.partnerShipObj["me_code"] = vm.defaultsMultipleSelected[0].rowData.ME_CODE;
								vm.partnerShipObj["entity_name"] = vm.defaultsMultipleSelected[0].rowData.LE_NAME;
//								vm.partnerShipObj["entity_name"] = vm.defaultsMultipleSelected[0].rowData.ENTITY_NAME;
								vm.partnerShipObj["tax_year_begin"] = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_BEGIN, "MM/dd");
								vm.partnerShipObj["tax_year_end"] = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_END, "MM/dd/yyyy");
					}

				} 
				
				
				//reset the form
				vm.reset = function(){
					vm.formList = angular.copy(vm.originalData);
					vm.outerList = angular.copy(vm.originalOutterData);

					vm.originalTabsList.push(vm.formList); // Previous Original Data
					vm.originalTablsOutterList.push(vm.outerList);
				};
				

				vm.save = function() {
					vm.isSaveClicked = true;
					
					var returnClobSettingsObj = {};
					var schdM2SaveDtls = [];
					vm.scheduleM2SaveList = _.filter(vm.formList, function(o) { 
					    return o.ATTRIB_COL != 'a'; 
					 })
					for(var key in vm.scheduleM2SaveList){
						var returnObj = {};
						
						returnObj["object_id"] = gridData.rowData.object_id;
						returnObj['combination_key'] = (vm.scheduleM2SaveList[key].ATTRIB_CLASS == 'DEF_ATTRIB') ? vm.scheduleM2SaveList[key].COMBINATION_KEY : vm.scheduleM2SaveList[key].PTR_COMBINATION_KEY;
						returnObj['ho_combination_key'] = vm.scheduleM2SaveList[key].COMBINATION_KEY;
						returnObj['form_key'] = vm.scheduleM2SaveList[key].FORM_KEY;
						returnObj['form_line_key'] = vm.scheduleM2SaveList[key].FORM_LINE_KEY;
						returnObj['line_attrib_key'] = vm.scheduleM2SaveList[key].LINE_ATTRIB_KEY;
						returnObj['trans_type_key'] = vm.scheduleM2SaveList[key].TRANS_TYPE_KEY;
						returnObj['attrib_type'] = vm.scheduleM2SaveList[key].ATTRIB_TYPE;
						returnObj['attrib_prop_key'] = vm.scheduleM2SaveList[key].ATTRIB_PROP_KEY;
						returnObj['acct_ref_key'] = vm.scheduleM2SaveList[key].ACCT_REF_KEY;
						returnObj['consol_group_key'] = vm.scheduleM2SaveList[key].CONSOL_GROUP_KEY;
						returnObj['attrib_value'] = vm.scheduleM2SaveList[key].ATTRIB_VALUE;
						//returnObj['occurrence'] = (vm.scheduleM2List[key].OCCURRENCE != null) ? parseInt(vm.scheduleM2List[key].OCCURRENCE) : 1;
						var message = "Defaults has been successfully saved/updated";
						schdM2SaveDtls.push(returnObj);
						
					}
				
					returnClobSettingsObj['sso_id'] = vm.logged_in_user;
					returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
					returnClobSettingsObj['form_name'] = "1065";
					returnClobSettingsObj['schedule_name'] = "M2K1";
					returnClobSettingsObj['combination_key'] = vm.scheduleM2SaveList[0].COMBINATION_KEY;

					sendDetails(returnClobSettingsObj,schdM2SaveDtls, message);

				};

				function sendDetails(returnClobSettingsObj, schdM2SaveDtls, message) {
					F1065ScheduleM2ServiceFactory.saveSchdM2(returnClobSettingsObj,schdM2SaveDtls).then(function(result) {

								if (result.data.errorMessage && result.data.errorMessage !== 'null') {
									 vm.isSaveClicked = false;
									AlertService.add("error", result.data.errorMessage, 4000);
								} else {
									//vm.crudLoading = false;
									AlertService.add("success", message, 4000);
									var args = {
											COMBINATION_KEY: returnClobSettingsObj.combination_key,
		                                    gridFilter: {
		                                    	COMBINATION_KEY: returnClobSettingsObj.combination_key
		                                    }
		                                };
									$uibModalInstance.dismiss('cancel');
									$rootScope.$emit('gridUpdate', args);
                            	};

							});
				}
			}
			return controllers;
		});