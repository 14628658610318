define([
    'angular'
], function () {
    'use strict';

    var controllers = angular.module('app.newProformaEfileViewCtrl', [])
        .controller('NewProformaEfileViewCtrl', ['$rootScope', '$scope', '$uibModal', 'USER_SETTINGS', 'NewProformaFactory', 'NewProformaEfileViewFactory', 'GlobalService', 'AlertService', 'workspaceFactory', 'MessageService', 'GENERAL_CONFIG', '$state', newProformaEfileViewCtrl])
        .controller('NoopController', [noopController])
        .controller('bulkDownloadCtrl', ['$rootScope', 'NewProformaFactory', 'rowData', '$uibModalInstance', 'AlertService', 'USER_SETTINGS', '$state', bulkDownloadCtrl])
        .controller('ViewBulkSelectionCtrl', ['$rootScope', 'NewProformaFactory', 'rowData', '$uibModalInstance', 'USER_SETTINGS', viewBulkSelectionCtrl])
        .controller('RetryFailedEntitiesController', ['$rootScope', 'NewProformaEfileViewFactory', 'gridData', '$uibModalInstance', 'USER_SETTINGS', 'NewProformaFactory', 'AlertService', retryFailedEntitiesController]);

    function newProformaEfileViewCtrl($rootScope, $scope, $uibModal, USER_SETTINGS, NewProformaFactory, NewProformaEfileViewFactory, GlobalService, AlertService, workspaceFactory, MessageService, GENERAL_CONFIG, $state) {
        var vm = this;
        $state.current.name === 'workspace.state-efile-view' ? vm.isState = true : vm.isState = false;
        $state.current.name === 'workspace.state-efile-view' ? vm.cacheStore = 'STATE' : vm.cacheStore = 'FED';
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });
        vm.cacheLocal = NewProformaEfileViewFactory.cache[vm.cacheStore];
        vm.formsPerEntity = NewProformaEfileViewFactory.cache[vm.cacheStore].gtwViewFormsCache || {},
        vm.selectedFormIdent = NewProformaEfileViewFactory.cache[vm.cacheStore].gtwViewSelectedForm || {};
        vm.activeScreenLocal = workspaceFactory.activeScreen;
        vm.expandAll = vm.isRefreshing = false;
        vm.expandAllTxt = 'Collapse All';
        vm.noDataMsg = '';
        vm.pdfs = [];
        vm.searchTerm = '';
        vm.formSearchTerm = '';
        vm.breadCrumb = '';
        let treeDataSet = new Set;
        let parentNodesAhead = [];

        $scope.$on('loadIRSTree', () => {
            const selectedTaxYearLocal = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxYear.value;
            const selectedTaxPayerLocal = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer.VALUE;
            vm.selectedTaxYear = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxYear.value;
            vm.selectedScenario = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.scenario.scenario;
            vm.selectedTaxPayer = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer.VALUE;
            vm.selectedEntity = null;
            vm.pdfs = [];
            vm.formsPerEntity = NewProformaEfileViewFactory.cache[vm.cacheStore].gtwViewFormsCache = {};
            vm.breadCrumb = vm.selectedFormIdent = '';
            vm.expandPdfView = true;
            vm.isLoadPdf = false;
            vm.loadingList = true;
            NewProformaFactory.resetStates();
            NewProformaEfileViewFactory.cache[vm.cacheStore].loadFiltersFromCache = true;
            NewProformaEfileViewFactory.getIRSView(vm.selectedTaxYear, vm.selectedTaxPayer, vm.selectedScenario, vm.isState ? 253 : 250, vm.isState ? "STATE" : "FED", 'Efile')
                .then((response) => {
                    if (vm.selectedTaxYear !== selectedTaxYearLocal || vm.selectedTaxPayer !== selectedTaxPayerLocal)
                        return;
                    const list = response.data.viewList;
                    if (list && list.length > 0) {
                        list.forEach((item) => {
                            item.hidden = false;
                            item.isCached = false;
                        });
                        vm.treeData = NewProformaEfileViewFactory.cache[vm.cacheStore].treeData = list;
                        hideParentNodesOnFiltersSelection();
                        hideParentNodesOnFiltersSelection();//second time for upper levels
                        buildLabel();
                        vm.treeDataFiltered = angular.copy(vm.treeData);
                        (!vm.isState) && collapseOnLvl(1);
                        vm.noDataMsg = 'Please select an entity from the tree!';
                        NewProformaFactory.sharedStates.showBulkExport = true;
                    } else {
                        vm.treeDataFiltered = vm.treeData = NewProformaEfileViewFactory.cache[vm.cacheStore].treeData = [{
                            label: 'No Data Available!',
                            noDataFlag: true
                        }];
                        NewProformaFactory.sharedStates.showBulkExport = false;
                        if (response.data.callSuccess !== '1') {
                            throw new Error(response.data.message);
                        }
                    }
                })
                .catch((err) => {
                    AlertService.add('warning', (err) ? err.toString() : 'Something went wrong while retrieving the data!');
                })
                .finally(() => {
                    vm.loadingList = false;
                    NewProformaEfileViewFactory.cache[vm.cacheStore].modalFormsCache = null;
                    workspaceFactory.setCache(vm.activeScreenLocal, NewProformaEfileViewFactory.cache[vm.cacheStore].treeData);
                });
        });

        if (vm.cacheLocal.loadFiltersFromCache) {
            loadFilters();
        }

        vm.expandCollapseAll = function () {
            vm.expandAll = !vm.expandAll;
            if (vm.expandAll) {
                vm.expandAllTxt = 'Expand All';
                if (vm.treeDataFiltered) {
                    vm.treeDataFiltered.forEach((row) => {
                        row.childrenHidden = true;
                        row.lvl !== 0 && (row.hidden = true);
                    });
                }
            } else {
                vm.expandAllTxt = 'Collapse All';
                if (vm.treeDataFiltered) {
                    vm.treeDataFiltered.forEach((row) => {
                        row.childrenHidden = false;
                        row.hidden = false;
                    });
                }
            }
        };

        function buildLabel() {
            vm.treeData.forEach((node) => {
                if (!node.is_leaf || ((node.cdr_no === null
                    && node.reporting_period === null && node.le_name === null))) {
                    node.label = node.leid;
                } else {
                    node.label = `${node.le_name.trim()} (${node.leid}/${node.cdr_no}/${node.reporting_period})`;
                }
            });
        }

        function hideParentNodesOnFiltersSelection() {
            if (vm.treeData && vm.treeData.length > 0) {
                vm.consolGroup = vm.cacheLocal.filtersSelected.consolGroup;
                //Hiding Nodes based on dropdown selection
                if (!vm.consolGroup) {
                    //state
                    vm.treeData = vm.treeData.filter((item) => (vm.getRecordsCount(item.leid) > 0 || (item.is_leaf && item.le_name != null)));
                    return;
                }
                if (vm.consolGroup.VALUE === 2) {
                    vm.treeData = vm.treeData.filter((item) => item.path.includes("Corporation (Non Consol)"));
                } else if (vm.consolGroup.VALUE === 3) {
                    vm.treeData = vm.treeData.filter((item) => item.path.includes("Partnerships"));
                } else {
                    vm.treeData = vm.treeData.filter((item) => item.path.includes("Corporation (Consol)") && (vm.getRecordsCount(item.leid) > 0 || (item.is_leaf && item.le_name != null)));
                }

            }
            NewProformaEfileViewFactory.cache[vm.cacheStore].treeData = vm.treeData;
        }

        function collapseOnLvl(lvl) {
            const nodesOnLvl = vm.treeDataFiltered.filter(item => item.lvl === lvl);
            nodesOnLvl.forEach((node) => {
                vm.toggleIRSView(node);
            });
        }

        function loadFilters() {
            vm.selectedTaxYear = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxYear.value;
            vm.selectedScenario = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.scenario.scenario;
            vm.selectedTaxPayer = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer.VALUE;
            vm.consolGroup = NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.consolGroup;
        }

        vm.hideNodes = function (node) {
            return !node.hidden;
        };

        vm.filterTreeData = function () {
            if (vm.searchTerm.length === 0) {
                vm.treeDataFiltered = angular.copy(vm.treeData);
                vm.treeDataFiltered.forEach(node => clearHiddenFlag(node));
                return;
            }

            treeDataSet.clear();
            parentNodesAhead = [];
            for (let i = 0; i < vm.treeData.length; i++) {
                let node = vm.treeData[i];
                clearHiddenFlag(node);
                if (!node.is_leaf) {
                    parentNodesAhead.push(node);
                }
                if (node.label.toLowerCase().includes(vm.searchTerm.toLowerCase())) {
                    findParents(node.path); // add parents
                    treeDataSet.add(node); // add itself
                    if (!node.is_leaf) {
                        i = findChildren(node.lvl, i + 1); // add children and update index
                    }
                }
            }

            vm.treeDataFiltered = Array.from(treeDataSet);
        };

        function clearHiddenFlag(node) {
            node.hidden = false;
            node.childrenHidden = false;
        }

        function findParents(path) {
            let nodes = path.split('/');
            if (nodes.length > 2) {
                nodes = nodes.slice(1, -1);
                nodes.forEach(node => {
                    let parentNode = parentNodesAhead.find(({ label }) => label === node.trim());
                    if (!parentNode) {
                        return;
                    }
                    treeDataSet.add(parentNode);
                });
            }
        }

        function findChildren(parentLvl, index) {
            while (index < vm.treeData.length) {
                let node = vm.treeData[index];
                if (node.lvl > parentLvl) {
                    treeDataSet.add(node);
                    index++;
                } else {
                    break;
                }
            }
            return index;
        }

        vm.getTotalRecordsCount = () => {
            return vm.treeDataFiltered.filter(item => item.is_leaf === 1).length;
        };

        vm.getRecordsCount = (parent) => {
            var rowsCount = 0;
            if (vm.treeData.length > 0) {
                if (!vm.treeData[0].noDataFlag) {
                    rowsCount = vm.treeData.filter(item => item.parent === parent).length;
                }
            }
            return rowsCount;
        };

        vm.toggleIRSView = function (view) {
            vm.toggle(vm.treeDataFiltered, view);
        }

        vm.toggle = function (tree, item) {
            if (item.is_leaf === 1)
                return;

            let index = tree.indexOf(item) + 1;
            // Collapse
            if (!item.childrenHidden) {
                item.childrenHidden = true;
                for (let i = index; i < tree.length; i++) {
                    let prev = i - 1;
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else {
                        tree[i].hidden = true;
                        // tree[i].childrenHidden = false;
                    }
                    // collapse folder icon if children are hidden
                    if (tree[prev].lvl < tree[i].lvl) {
                        tree[prev].childrenHidden = true;
                    }
                }
            }
            // Expand
            else {
                item.childrenHidden = false;
                for (let i = index; i < tree.length; i++) {
                    if (tree[i].lvl <= item.lvl) {
                        return;
                    } else if (tree[i].lvl - 1 === item.lvl) {
                        tree[i].hidden = false;
                    } else {
                        tree[i].hidden = true;
                    }
                }
            }
        };

        vm.getPDFs = function (selectedEntity, useCache = false) {
            if (selectedEntity.is_leaf === 0) {
                vm.toggleIRSView(selectedEntity);
                return;
            } else if (selectedEntity.le_name === null) {
                return;
            }

            if(!useCache){
                vm.expandPdfView = true;
                vm.selectedFormIdent = NewProformaEfileViewFactory.cache[vm.cacheStore].selectedPdfCached = {};
            }

            vm.breadCrumb = selectedEntity.path.replaceAll(/\//gi, ' -> ').replace((vm.isState ? selectedEntity.le_name: selectedEntity.leid), selectedEntity.label);
            vm.isLoadPdf = false;
            vm.selectedEntity = NewProformaEfileViewFactory.cache[vm.cacheStore].selectedEntity = selectedEntity;
            vm.parent = selectedEntity.parent;
            vm.leName = selectedEntity.le_name;
            const returnType = selectedEntity.path.includes('Partnerships') ? '1065' : '1120';
            const businessType = vm.consolGroup ? (vm.consolGroup.VALUE === 3 ? 'P' : 'C') : 'C';
            vm.cdrNo = selectedEntity.cdr_no || '';
            vm.rptPeriod = selectedEntity.reporting_period || '';
            vm.consol_type = selectedEntity.consol_type || '';
            vm.src_system_flag = selectedEntity.source_system_flag || '';
            NewProformaFactory.sharedStates.showSelectAll = NewProformaFactory.sharedStates.isSelectAll = false;
            NewProformaFactory.sharedStates.showMerge = false;
            NewProformaFactory.sharedStates.showExpand = false;
            vm.loadingForms = true;

            // read forms from cache
            if (getFormsFromCache(vm.parent, vm.leName, selectedEntity.leid, vm.cdrNo, vm.rptPeriod, vm.consol_type)) return;
            
            getPDFs(selectedEntity,  selectedEntity.leid, returnType, businessType);
        };

        function getPDFs(selectedEntity, selectedEntityLeid, returnType, businessType, isRefresh = false) {
            vm.pdfs = [];
            vm.loadingForms = true;
            vm.isRefreshing = (!isRefresh) ? false: true;
            vm.selectedFormIdent = {};
            
            NewProformaEfileViewFactory.getPDFs(vm.selectedTaxYear, vm.selectedTaxPayer, selectedEntityLeid, vm.selectedEntity.key_id, returnType, businessType, vm.cdrNo, vm.rptPeriod, vm.isState ? "STATE" : "FED")
                .then((response) => {
                    if (vm.selectedEntity.leid !== selectedEntity.leid || vm.selectedEntity.cdr_no !== selectedEntity.cdr_no || 
                        vm.selectedEntity.reporting_period !== selectedEntity.reporting_period || vm.selectedEntity.le_name !== selectedEntity.le_name || 
                        vm.selectedEntity.source_system_flag !== selectedEntity.source_system_flag || vm.selectedEntity.consol_type !== selectedEntity.consol_type)
                        return;
                    if (response.data.callSuccess !== '1') {
                        throw new Error(response.data.message);
                    }
                    selectedEntity && (vm.updateEntitiesCache(selectedEntity, true));
                    if (response.data.callSuccess === '1' && response.data.formList.length > 0) {
                        vm.pdfs = response.data.formList;
                        createFormLabel();

                        vm.noDataMsg = '';
                        // set forms cache
                        const entityCacheParams = vm.parent + '_' + vm.leName + '_' + selectedEntityLeid + '_' + vm.cdrNo + '_' 
                            + vm.rptPeriod + '_' + vm.consol_type;
                        vm.formsPerEntity[entityCacheParams] = {};
                        vm.formsPerEntity[entityCacheParams].pdfs = angular.copy(vm.pdfs);
                        vm.formsPerEntity[entityCacheParams].isRefreshing = angular.copy(vm.isRefreshing);
                    } else {
                        vm.noDataMsg = 'No Forms Available!';
                    }
                    vm.isRefreshing = false;
                })
                .catch((err) => {
                    if (vm.selectedEntity.leid !== selectedEntity.leid || vm.selectedEntity.cdr_no !== selectedEntity.cdr_no || 
                        vm.selectedEntity.reporting_period !== selectedEntity.reporting_period || vm.selectedEntity.le_name !== selectedEntity.le_name || 
                        vm.selectedEntity.source_system_flag !== selectedEntity.source_system_flag || vm.selectedEntity.consol_type !== selectedEntity.consol_type)
                        return;
                    vm.noDataMsg = 'Something went wrong, please contact support!';
                })
                .finally(() => {
                    NewProformaFactory.sharedStates.showSelectAll = vm.expandPdfView = true;
                    NewProformaFactory.sharedStates.showMerge = true;
                    vm.loadingForms = vm.isLoadPdf = false;
                })
        }

        function createFormLabel() {
            vm.pdfs.forEach((form) => {
                if (form.is_whitepaper) {
                    form.label = form.irs_form_no + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form;
                } else {
                    form.label = form.subform && form.form_sub_cat === 'DIFF_PDF' ? (form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form) + '_' + form.subform :
                        (form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form);
                    form.form_sub_cat === 'SAME_PDF' && (form.subform = null);
                }
            });

            vm.pdfs = _.uniqBy(vm.pdfs, 'label');
        }

        function getFormsFromCache(parent, leName, selectedEntityLeid, cdrNo, rptPeriod, consol_type) {
            const entityCacheParams = parent + '_' + leName + '_' + selectedEntityLeid + '_' + cdrNo + '_' + rptPeriod + '_' + consol_type;
            if (vm.formsPerEntity[entityCacheParams]) {
                vm.noDataMsg = '';
                vm.pdfs = vm.formsPerEntity[entityCacheParams].pdfs || [];
                unCheckPdfs();
                vm.isRefreshing = vm.formsPerEntity[entityCacheParams].isRefreshing ;
                vm.loadingForms = false;
                NewProformaFactory.sharedStates.showSelectAll = NewProformaFactory.sharedStates.showMerge  = true;
                return true;
            }
            return false;
        }

        function unCheckPdfs(){
            if (vm.pdfs && vm.pdfs.length > 0) {
                vm.pdfs.forEach((pdf) => {
                    pdf.checked = false;
                });
            }
        }

        vm.updateEntitiesCache = (entity, isCached = false) => {
            var cachedEntity = vm.cacheLocal.treeData.find(item => item.path === entity.path && item.leid === entity.leid 
                && item.cdr_no === entity.cdr_no && item.reporting_period === entity.reporting_period)
            cachedEntity && (cachedEntity.isCached = isCached);
            entity.isCached = isCached;
        }

        vm.clearCache = function (entity) {
            delete vm.formsPerEntity[entity.parent + '_' + entity.le_name + '_' + entity.leid + '_' + entity.cdr_no + '_' 
                + entity.reporting_period + '_' + entity.consol_type];
            vm.updateEntitiesCache(entity, false);
        }

        vm.refresh = function () {
            vm.isRefreshing = true;
            var entity = vm.treeDataFiltered.find(item => item.path === vm.selectedEntity.path);
            const returnType = entity.path.includes('Partnerships') ? '1065' : '1120';
            const businessType = vm.consolGroup ? (vm.consolGroup.VALUE === 3 ? 'P' : 'C') : 'C';
            vm.updateEntitiesCache(entity, false);
            getPDFs(entity, entity.leid, returnType, businessType, true);
        }

        vm.formFilter = function (pdf) {
            if (vm.formSearchTerm.length > 0) {
                pdf.isDisplay = pdf.checked || pdf.label.toString().toLowerCase().includes(vm.formSearchTerm.toString().toLowerCase());
                return pdf.isDisplay;
            }
            pdf.isDisplay = true;
            return pdf.isDisplay;
        };

        $scope.$on('showBulkExportModal::Efile', () => {
            vm.export();
        });

        $scope.$on('$destroy', function () {
            NewProformaEfileViewFactory.cache[vm.cacheStore].gtwViewFormsCache = angular.copy(vm.formsPerEntity);
            NewProformaEfileViewFactory.cache[vm.cacheStore].selectedEntity = angular.copy(vm.selectedEntity);
            NewProformaEfileViewFactory.cache[vm.cacheStore].gtwViewSelectedForm = angular.copy(vm.selectedFormIdent);
            NewProformaEfileViewFactory.cache[vm.cacheStore].flags = NewProformaEfileViewFactory.cache[vm.cacheStore].flags || {};
            NewProformaEfileViewFactory.cache[vm.cacheStore].flags.expandPdfView = vm.expandPdfView;
        })

        vm.export = function () {
            $uibModal.open({
                animation: true,
                windowClass: 'npr-bulk-export-modal',
                templateUrl: 'app/components/newProforma/efile-bulk-export-modal.html',
                controller: ['$scope', 'NewProformaEfileViewFactory', 'AlertService', 'USER_SETTINGS', '$uibModalInstance', '$timeout',
                    function ($scope, NewProformaEfileViewFactory, AlertService, USER_SETTINGS, $uibModalInstance, $timeout) {
                        let treeDataSet = new Set();
                        let parentNodesAhead = [];
                        $scope.currentTheme = vm.currentTheme;
                        $scope.stepExpanded = [true, false, false];
                        $scope.clientName = USER_SETTINGS.user.client.client_name;
                        $scope.entities = angular.copy(vm.treeData);
                        $scope.entitiesFiltered = $scope.entities;
                        $scope.filingType = !vm.consolGroup ? 'C' : (vm.consolGroup.VALUE === 3 ? 'P' : 'C');
                        // $scope.pdfType = true;
                        $scope.formats = [
                            { type: "PDF", name: "PDF", selected: true, isformat: true },
                            { type: "XML", name: "XML", selected: false, isformat: true },
                            { type: "Merge", name: "Single PDF (by Entity)", selected: false, isformat: false }
                        ];
                        $scope.search = {};
                        $scope.zippedExport = true;
                        $scope.exportDisable = true;
                        $scope.noFormsMsg = '';

                        $scope.expandAccordion = function (accordionIndex) {
                            $scope.hideStepContent = true;
                            for (var i = 0; i < $scope.stepExpanded.length; i++) {
                                $scope.stepExpanded[i] = i === accordionIndex;
                            }
                            $timeout(() => {
                                $scope.hideStepContent = false;
                            }, 300);
                        };

                        $scope.toggleIRSView = function (view, $event) {
                            vm.toggle($scope.entitiesFiltered, view);
                            $event.preventDefault();
                        };

                        $scope.toggleForm = function (form, $event) {
                            vm.toggle($scope.formsFiltered, form);
                            $event.preventDefault();
                        };

                        $scope.getRecordsCount = (type, parent) => {
                            var rowsCount = 0;
                            var treeData = (type == "forms") ? $scope.formsFiltered : $scope.entitiesFiltered;
                            if (treeData.length > 0) {
                                if (!treeData[0].noDataFlag) {
                                    rowsCount = treeData.filter(item => item.parent === parent).length;
                                }
                            }
                            return rowsCount;
                        };

                        if (!NewProformaEfileViewFactory.cache[vm.cacheStore].modalFormsCache) {
                            $scope.formLoading = true;
                            $scope.foreignFormsDisplay = false;
                            NewProformaFactory.getBulkExportForms(
                                NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxYear.value,
                                $scope.filingType === 'C' ? '1120' : '1065',
                                'Efile',
                                NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer.VALUE,
                                vm.isState ? NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer.VALUE : ''
                            ).then((response) => {
                                if (response.data.callSuccess === '1') {
                                    $scope.forms = NewProformaEfileViewFactory.cache[vm.cacheStore].modalFormsCache = response.data.viewList;
                                    $scope.forms.forEach((form) => {
                                        if (form.is_whitepaper) {
                                            form.label = form.irs_form_no + '-Sch_' + form.schedule + '-' + form.line_no + ' ' + form.form;
                                        } else {
                                            form.label = form.form_sub && form.form_sub_cat === 'DIFF_PDF' ? ((form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form) + '_' + form.form_sub) :
                                                (form.form.toLowerCase().includes('schedule') ? form.form.replace('Schedule', '-') : form.form);
                                            form.form_sub_cat === 'SAME_PDF' && (form.form_sub = null);
                                        }
                                        form.hidden = form.lvl > 1;
                                        form.childrenHidden = form.lvl === 1;
                                        if (form.path.includes('Corporate Domestic')) {
                                            form.type = 'D';
                                        } else if (form.path.includes('Corporate Foreign')) {
                                            form.type = 'F';
                                        }
                                    });
                                    $scope.forms = _.uniqBy($scope.forms, 'label');
                                    $scope.formsFiltered = $scope.forms.filter(item => vm.isState ? item.type === 'D' : true);
                                    $scope.foreignFormsDisplay = vm.isState ? false : true;
                                }
                            }).catch(() => {
                                $scope.noFormsMsg = 'Forms unavailable.';
                            }).finally(() => {
                                $scope.formLoading = false;
                            });
                        } else {
                            // reset form states
                            NewProformaEfileViewFactory.cache[vm.cacheStore].modalFormsCache.forEach((form) => {
                                form.childrenHidden = form.lvl >= 1;
                                form.hidden = form.lvl >= 2;
                                form.selected = false;
                            });
                            $scope.formsFiltered = $scope.forms = NewProformaEfileViewFactory.cache[vm.cacheStore].modalFormsCache || [];
                            $scope.foreignFormsDisplay = vm.isState ? false : true;
                        }

                        $scope.searchEntity = function () {
                            if ($scope.search.entitySearchTerm.length === 0) {
                                $scope.entitiesFiltered = $scope.entities;
                                return;
                            }
                            filterTreeData($scope.entities, $scope.search.entitySearchTerm, 'entity');
                        };

                        $scope.searchForm = function () {
                            if ($scope.search.formSearchTerm.length === 0) {
                                $scope.formsFiltered = $scope.forms;
                                return;
                            }
                            filterTreeData($scope.forms, $scope.search.formSearchTerm, 'form');
                        };

                        function filterTreeData(treeData, searchTerm, type) {
                            const labelKey = type === 'entity' ? 'label' : 'form';
                            treeDataSet.clear();
                            parentNodesAhead = [];
                            for (let i = 0; i < treeData.length; i++) {
                                let node = treeData[i];
                                if (!node.is_leaf) {
                                    parentNodesAhead.push(node);
                                }
                                if (node[labelKey].toLowerCase().includes(searchTerm.toLowerCase())) {
                                    findParents(node.path, labelKey); // add parents
                                    node.hidden = false; // reveal the item after search
                                    treeDataSet.add(node); // add itself
                                    if (!node.is_leaf) {
                                        i = findChildren(treeData, node.lvl, i + 1); // add children and update index
                                    }
                                }
                            }

                            if (type === 'entity') {
                                $scope.entitiesFiltered = Array.from(treeDataSet);
                            } else {
                                $scope.formsFiltered = Array.from(treeDataSet);
                            }
                        }

                        function findParents(path, labelKey) {
                            let nodes = path.split('/');
                            if (nodes.length > 2) {
                                nodes = nodes.slice(1, -1);
                                nodes.forEach(node => {
                                    let parentNode = parentNodesAhead.find((parent) => parent[labelKey] === node.trim());
                                    if (!parentNode) {
                                        return;
                                    }
                                    parentNode.childrenHidden = false;
                                    treeDataSet.add(parentNode);
                                });
                            }
                        }

                        function findChildren(treeData, parentLvl, index) {
                            while (index < treeData.length) {
                                let node = treeData[index];
                                if (node.lvl > parentLvl) {
                                    node.hidden = false;
                                    treeDataSet.add(node);
                                    index++;
                                } else {
                                    break;
                                }
                            }
                            return index;
                        }

                        $scope.selectEntity = function (item, $event) {
                            item.selected = !item.selected;
                            if (!item.is_leaf) {
                                const index = $scope.entitiesFiltered.indexOf(item);
                                for (let i = index + 1; i < $scope.entitiesFiltered.length; i++) {
                                    if ($scope.entitiesFiltered[i].lvl <= item.lvl) {
                                        break;
                                    }
                                    $scope.entitiesFiltered[i].selected = item.selected;
                                }
                            }
                            enableDisableExport();
                            $event.preventDefault();
                        };

                        $scope.selectForm = function (item, $event) {
                            item.selected = !item.selected;

                            if (!item.is_leaf) {
                                const index = $scope.formsFiltered.indexOf(item);
                                for (let i = index + 1; i < $scope.formsFiltered.length; i++) {
                                    if ($scope.formsFiltered[i].lvl <= item.lvl) {
                                        break;
                                    }
                                    $scope.formsFiltered[i].selected = item.selected;
                                }
                            }

                            // if (item.selected) {
                            //     if (item.type === 'D') {
                            //         $scope.domFormSelected = true;
                            //     } else {
                            //         $scope.forFormSelected = true;
                            //     }
                            //     if ($scope.domFormSelected && $scope.forFormSelected) {
                            //         $scope.zippedExport = true;
                            //     }
                            // } else {
                            //     checkUnselectedForms(item.type);
                            // }
                            enableDisableExport();

                            $event.preventDefault();
                        };

                        function enableDisableExport() {
                            const selectedEntities = $scope.entities.filter(item => item.selected && item.is_leaf);
                            const selectedForms = $scope.forms.filter(item => item.selected && item.is_leaf);
                            const formatSelectedCount = $scope.formats.filter(item => item.selected);
                            if (selectedEntities.length == 0 || selectedForms.length == 0 || formatSelectedCount.length == 0)
                                $scope.exportDisable = true;
                            else
                                $scope.exportDisable = false;
                        }

                        $scope.selectFormat = function (formatType, formatSected) {
                            if (formatType === "Merge" && formatSected === true) {
                                $scope.zippedExport = false;
                                $scope.formats.find(item => item.type === "XML").selected = false;
                                $scope.formats.find(item => item.type === "PDF").selected = true;
                            }
                            else if(formatType === "XML" && formatSected === true){
                                $scope.zippedExport = true;
                                $scope.formats.find(item => item.type === "Merge").selected = false;
                            }else if(formatType === "PDF" && formatSected === false){
                                $scope.zippedExport = true;
                                $scope.formats.find(item => item.type === "Merge").selected = false;
                            } else {
                                $scope.zippedExport = true;
                            }
                            enableDisableExport();
                        }

                        // function checkUnselectedForms(type) {
                        //     const hasCheckedForms = $scope.forms.some((form) => {
                        //         return form.type === type && form.selected;
                        //     })
                        //     if (hasCheckedForms) {
                        //         return;
                        //     } else if (type === 'D') {
                        //         $scope.domFormSelected = false;
                        //     } else if (type === 'F') {
                        //         $scope.forFormSelected = false;
                        //     }
                        // }

                        $scope.export = function () {
                            $scope.exportLoading = true;
                            const selectedEntities = $scope.entities.filter(item => item.selected && item.is_leaf);
                            const selectedForms = $scope.forms.filter(item => item.selected && item.is_leaf);
                            const selectedFormats = $scope.formats.filter(item => item.selected && item.isformat)
                                .map(item => item.name).toString();

                            let entities = [];
                            let forms = [];
                            selectedEntities.forEach((entity) => {
                                let entityObj = {};
                                entityObj.leid = entity.leid;
                                entityObj.le_name = entity.le_name;
                                entityObj.cdr_no = entity.cdr_no;
                                entityObj.reporting_period = entity.reporting_period;
                                entityObj.system_flag = entity.source_system_flag;
                                entityObj.le_type = 'HO';
                                entityObj.path = entity.path;
                                entityObj.consol_type = entity.consol_type;
                                entityObj.key_id = entity.key_id || '';
                                entities.push(entityObj);
                            });

                            selectedForms.forEach((form) => {
                                let formObj = {};
                                formObj.form_sub = form.form_sub ? (vm.consolGroup.VALUE === 3 ?form.form_sub.split('_')[0] : form.form_sub ) : ''; // removes reporting period
                                formObj.irs_form = form.subform && form.form_sub_cat === 'DIFF_PDF' ? form.form + '_' + formObj.form_sub : form.form;
                                formObj.form_sub_cat = form.form_sub_cat;
                                formObj.wpirs_form_no = form.is_whitepaper ? (form.irs_form_no.toLowerCase().startsWith('f') ? form.irs_form_no.substring(1)
                                    : (form.irs_form_no.toLowerCase().startsWith('irs') ? form.irs_form_no.substring(3) : form.irs_form_no)) : ''; //removing F and IRS from formName
                                formObj.schedule = form.schedule;
                                formObj.line = form.line_no;
                                formObj.part_no = form.part_no || 'NA';
                                formObj.is_whitepaper = form.is_whitepaper;
                                forms.push(formObj);
                            });

                            let payload = {
                                entities: entities,
                                forms: forms
                            }
                            NewProformaFactory.pdfsBulkExport(
                                vm.selectedTaxYear,
                                vm.selectedScenario,
                                vm.selectedTaxPayer,
                                $scope.zippedExport ? 'Zip' : 'Merge',
                                selectedFormats,
                                vm.isState ? "STATE" : 'EFILE',
                                $scope.filingType === 'C' ? '1120' : '1065',
                                !vm.consolGroup ? 'C' : (vm.consolGroup.VALUE === 3 ? 'P' : 'C'),
                                vm.isState ? '253' : '250',//'250',
                                vm.selectedTaxPayer,
                                0,
                                0,
                                NewProformaEfileViewFactory.cache[vm.cacheStore].filtersSelected.taxPayer.LABEL,
                                payload,
                                'Efile'
                            ).then((response) => {
                                AlertService.add('success', 'Your Bulk Export Request has been Initiated!', 4000);
                                console.log(response);
                            })
                                .catch((err) => {
                                    AlertService.add('warning', 'Something went wrong while sending Bulk Request!');
                                    console.log(err.toString());
                                })
                                .finally(() => {
                                    $scope.exportLoading = false;
                                    $uibModalInstance.close();
                                });
                        };
                    }]
            });
        };

        $scope.$on('selectAll::Efile', () => {
            vm.selectAll();
        });

        vm.selectForm = function () {
            NewProformaFactory.sharedStates.enableMerge = vm.getSelectedPDFs().length > 1;
        };

        vm.selectAll = () => {
            vm.pdfs.forEach((pdf) => {
                if (pdf.isDisplay) {
                    pdf.checked = !NewProformaFactory.sharedStates.isSelectAll;
                }
            });
            NewProformaFactory.sharedStates.isSelectAll = !NewProformaFactory.sharedStates.isSelectAll;
            NewProformaFactory.sharedStates.enableMerge = NewProformaFactory.sharedStates.isSelectAll;
        };

        vm.getSelectedPDFs = () => {
            let selectedPDFs = [];
            vm.pdfs.forEach((pdf) => {
                if (pdf.checked) {
                    selectedPDFs.push({ 'form_name': pdf.form, 'subform': pdf.subform && pdf.form_sub_cat === "DIFF_PDF" ? '-' + pdf.subform : '' });
                }
            });
            return selectedPDFs;
        };

        $scope.$on('merge::Efile', () => {
            vm.merge();
        });

        vm.merge = () => {
            const formNames = vm.getSelectedPDFs();
            const mergePopupRef = $uibModal.open({
                animation: true,
                template: `
                <div class="modal-header">
                  <div class="modal-title">Merge PDFs
                    <i ng-click="uibModalInstance.close()" class="fa fa-times pull-right"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div style="font-weight: bolder;">
                    The following forms will be merged into one PDF, are you sure you want to continue?
                  </div>
                  <div style="padding: 20px 20px;">
                    <span ng-if="isLoading">Please wait while we retrieve the data...</span>
                    <span ng-if="hasLoadErr" style="color: red;">Data not available, please try again!</span>
                    <div ng-if="!isLoading" ng-repeat="formName in formNames" style="margin-bottom: 5px;">
                      <span style="margin-right: 5px;">{{formName.form_name + formName.subform}}:</span>
                      <span><i ng-class="formName.exist ? 'fa-check' : 'fa-times'" class="fa" ng-style="{color: formName.exist ? 'green' : 'red'}"></i></span>
                    </div>
                  </div>
                  <div ng-if="hasInvalidForms">Note: forms marked with x do not exist, and will be excluded from merge.</div>
                </div>
                <div class="modal-footer">
                  <span ng-if="!isLoading && !isMergeValid" style="color: red; margin-right: 10px;">
                    Selection contains less than two valid forms!
                  </span>
                  <button class="btn btn-info btn-sm" data-ng-disabled="isLoading || !isMergeValid" ng-click="merge()">
                    Merge
                  </button>
                  <button class="btn btn-warning btn-sm" ng-click="uibModalInstance.close()">Cancel</button>
                </div>
                `,
                controller: ['$scope', '$uibModalInstance', 'formNames', 'WorkflowService', function ($scope, $uibModalInstance, formNames, WorkflowService) {
                    $scope.isLoading = true;
                    $scope.formNames = formNames;
                    $scope.uibModalInstance = $uibModalInstance;
                    var formNoList = formNames.map(formName => formName.form_name);

                    WorkflowService.getPdfpFormS3UrlList(formNoList, 'Efile').then((response) => {
                        if (response && response.callSuccess && response.result) {
                            $scope.s3List = response.result.filter(s3 => s3 !== null);
                            $scope.s3List.forEach((s3Url) => {
                                let existingFormList = formNames.filter((formName) => {
                                    return s3Url && formName.form_name === s3Url.irs_form_no;
                                });
                                if (existingFormList) {
                                    existingFormList.forEach((existingForm) => {
                                        existingForm.exist = true;
                                    });
                                }
                            });
                            $scope.hasInvalidForms = formNames.length > $scope.s3List.length;
                            $scope.isMergeValid = $scope.s3List.length >= 2;
                            $scope.isLoading = false;
                        } else {
                            $scope.isLoading = false;
                            $scope.hasLoadErr = true;
                        }
                    });

                    $scope.merge = function () {
                        $uibModalInstance.close($scope.s3List);
                    };
                }],
                resolve: {
                    formNames: () => formNames
                }
            });

            mergePopupRef.result.then((s3List) => {
                if (s3List) {
                    s3List = s3List.filter(s3 => s3 !== null);
                    const irsFormNoList = s3List.map(({ irs_form_no }) => {
                        return irs_form_no;
                    });
                    const irsFormNoListIdent = s3List.map(({ irs_form_no, subform }) => {
                        return irs_form_no + (subform && form_sub_cat === "DIFF_PDF" ? '-' + subform : '');
                    });
                    const s3UrlList = s3List.map(({ s3_url }) => {
                        return s3_url;
                    });
                    vm.loadPDFs(irsFormNoList, irsFormNoListIdent, s3UrlList);
                }
            })
        };

        vm.loadPDF = (pdf, useCache) => {
            NewProformaEfileViewFactory.cache[vm.cacheStore].selectedPdfCached = pdf;
            NewProformaEfileViewFactory.cache[vm.cacheStore].loadMergedPdfs = false;

            NewProformaFactory.sharedStates.showExpand = true;

            vm.src_type = vm.isState ? "STATE" : "EFILE";
            vm.usePdfFromCache = useCache;
            vm.expandPdfView = false;
            vm.isMerge = false;
            vm.s3UrlList = [];
            vm.selectedFormIdent = pdf;
            vm.selectedForm = pdf.form;
            vm.irs_form_name = pdf.is_whitepaper ? pdf.irs_form_no.toLowerCase().startsWith('f') ? pdf.irs_form_no.substring(1) : pdf.irs_form_no.toLowerCase().startsWith('irs') ? pdf.irs_form_no.substring(3) : pdf.irs_form_no : '';
            vm.schedule = pdf.schedule || '';
            vm.line = pdf.line_no || '';
            vm.partNo = pdf.part_no || 'NA';
            vm.form_sub = pdf.subform ? (vm.consolGroup.VALUE === 3 ? pdf.subform.split('_')[0] : pdf.subform ): ''; // removes reporting period
            vm.form_sub_cat = pdf.form_sub_cat || '';
            vm.scenarioLocal = GlobalService.globalParams.scenario;
            vm.isAttachment = pdf.is_attachment || '';
            vm.keyId = vm.selectedEntity.key_id;
            vm.isLoadPdf = true;
        };

        vm.loadPDFs = (irsFormNoList, irsFormNoListIdent, s3UrlList) => {
            NewProformaFactory.sharedStates.showExpand = vm.isMerge = true;
            vm.expandPdfView = false;
            vm.src_type = vm.isState ? "STATE" : "EFILE";
            vm.s3UrlList = s3UrlList;
            vm.taxYear = vm.selectedTaxYear;
            vm.scenarioLocal = vm.selectedScenario;
            vm.selectedForm = irsFormNoList;
            vm.selectedFormIdent.mergeLabel = irsFormNoListIdent;
            vm.le_name = vm.selectedEntity.le_name;
            vm.irs_form_name = vm.pdfs.filter(pdf => irsFormNoList.includes(pdf.form)).map(({ irs_form_no }) => {
                return irs_form_no
            });
            vm.schedule = vm.pdfs.filter(pdf => irsFormNoList.includes(pdf.form)).map(({ schedule }) => {
                return schedule
            });
            vm.line = vm.pdfs.filter(pdf => irsFormNoList.includes(pdf.form)).map(({ line_no }) => {
                return line_no
            });
            vm.form_sub = vm.pdfs.filter(pdf => irsFormNoList.includes(pdf.form)).map(({ subform }) => {
                return subform
            });
            vm.form_sub_cat = vm.pdfs.filter(pdf => irsFormNoList.includes(pdf.form)).map(({ form_sub_cat }) => {
                return form_sub_cat
            });
            vm.partNo = vm.pdfs.filter(pdf => irsFormNoList.includes(pdf.form)).map(({ part_no }) => {
                return part_no || 'NA'
            });
            vm.isAttachment = vm.pdfs.filter(pdf => irsFormNoList.includes(pdf.form)).map(({ is_attachment }) => {
                return is_attachment
            });
            vm.keyId = vm.selectedEntity.key_id;
            vm.isLoadPdf = true;
        };

        function loadFromCache() {
            vm.treeData = vm.cacheLocal.treeData;
            hideParentNodesOnFiltersSelection();
            hideParentNodesOnFiltersSelection();//second time for upper levels
            vm.treeDataFiltered = angular.copy(vm.treeData);
            vm.noDataMsg = 'Please select an entity from the tree!';
            
            vm.expandPdfView = !vm.selectedFormIdent.mergeLabel && NewProformaEfileViewFactory.cache[vm.cacheStore].flags ? 
                NewProformaEfileViewFactory.cache[vm.cacheStore].flags.expandPdfView : true;
            vm.selectedEntity = NewProformaEfileViewFactory.cache[vm.cacheStore].selectedEntity;
            if (vm.selectedEntity) {
                vm.getPDFs(vm.selectedEntity, true);
            }

            vm.selectedFormIdent = NewProformaEfileViewFactory.cache[vm.cacheStore].selectedPdfCached;
            if (vm.selectedFormIdent) {
                NewProformaEfileViewFactory.cache[vm.cacheStore].flags.usePdfFromCache = true;
                !angular.equals(vm.selectedFormIdent, {}) && !vm.selectedFormIdent.mergeLabel && vm.loadPDF(vm.selectedFormIdent, true);
            } else {
                vm.expandPdfView = true;
            }

            if (vm.treeData && vm.treeData.length > 0) {
                NewProformaFactory.sharedStates.showBulkExport = true;
            }
            else {
                NewProformaFactory.sharedStates.showBulkExport = false;
            }
        }

        loadFromCache();

        function establishWebSocketConnection() {
            // establish pdf engine websocket connection
            MessageService.establishConnection(GENERAL_CONFIG.pdf_engine_api_url + '/pdf-engine-websockets', (client) => {
                MessageService.subscribeToChannelWithCallback(GENERAL_CONFIG.pdf_engine_api_url + '/pdf-engine-websockets', '/notify/efile-view/' + USER_SETTINGS.user.sso_id, function(payload) {
                    $rootScope.$broadcast("efile-view:websocket-message", payload);
                },client);
            });
            // // establish bulk pdf engine websocket connection
            // MessageService.establishConnection(GENERAL_CONFIG.bulk_pdf_engine_api_url + '/bulk-pdf-engine-websockets', () => {
            //     MessageService.subscribeToChannelWithCallback(GENERAL_CONFIG.bulk_pdf_engine_api_url + '/bulk-pdf-engine-websockets', '/notify/' + USER_SETTINGS.user.sso_id, function(payload) {
            //         $rootScope.$broadcast("bulk-export:websocket-message", payload);
            //     })
            // });
        }

        NewProformaEfileViewFactory.cache[vm.cacheStore].listener && NewProformaEfileViewFactory.cache[vm.cacheStore].listener();
        NewProformaEfileViewFactory.cache[vm.cacheStore].listener = $rootScope.$on('efile-view:websocket-message', function(ev, payload) {
            handleMessage(payload); //, "EfileNotification"
        })

        // NewProformaEfileViewFactory.cache[vm.cacheStore].bulkExportListener && NewProformaEfileViewFactory.cache[vm.cacheStore].listener();
        // NewProformaEfileViewFactory.cache[vm.cacheStore].bulkExportListener = $rootScope.$on('bulk-export:websocket-message', function(ev, payload) {
        //     handleMessage(payload, "BulkExportNotification");
        // })

        function handleMessage(payload) { //, notifyType
            var notification = JSON.parse(payload.body);
            console.log("notification payload", notification);
            // if(notifyType === "EfileNotification"){
                AlertService.add(notification.status, notification.message, 4000);
                $rootScope.$apply();
                if ($state.current.name === "workspace.bulk-export-history" || $state.current.name === "workspace.state-bulk-export-history"){
                    $rootScope.$broadcast('dataFilters:refreshGrid', {
                        "refresh": true
                    });
                }
            // }
        }

        if (!window.location.origin.includes("localhost")){
            establishWebSocketConnection();
        }

        // function handleGridUpdateMessage(payload) {
        //     var notification = JSON.parse(payload.body);
        //     console.log("notification payload", notification);
        //     AlertService.add(notification.status, notification.message, 4000);
        //     $rootScope.$apply();
        //     if ($state.current.name === "workspace.bulk-export-history" || $state.current.name === "workspace.state-bulk-export-history"){
        //         // $rootScope.$broadcast('dataFilters:refreshGrid', {
        //         //     "refresh": true
        //         // });
        //         var args = {
        //             REQUEST_ID: notification.requestId,
        //             ENTITIES_COMPLETED: notification.entitiesCompleted, 
        //             gridFilter: {
        //                 REQUEST_ID: notification.requestId,
        //                 ENTITIES_COMPLETED: notification.entitiesCompleted
        //             }
        //         };
        //         // $rootScope.$emit('gridUpdate', args);

        //         $rootScope.$broadcast("dataUpdate", {
        //             filter: {
        //                 REQUEST_ID: notification.requestId,
        //             },
        //             data: {
        //                 REQUEST_ID: notification.requestId,
        //                 ENTITIES_COMPLETED: notification.entitiesCompleted
        //             }
        //         });
        //     }
        // }

        // setTimeout(function(){
        //     var payload = "{\"requestId\":390,\"message\":\"Bulk Export Request Completed for the Tax Payer: GE Healthcare USA Holding LLC  (MS0240)\",\"status\":\"success\",\"taxYear\":\"2020\",\"entitiesCompleted\":\"2/2\"}";
        //     handleGridUpdateMessage({body: payload});
        // }, 15000)
    }

    function noopController() {
    }

    function bulkDownloadCtrl($rootScope, NewProformaFactory, rowData, $uibModalInstance, AlertService, USER_SETTINGS, $state) {
        var vm = this;
        vm.isState = $state.current.name === "workspace.state-bulk-export-history" ? true : false;
        vm.downloading = vm.systemGeneratedRequest = false;
        
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });
        
        if(rowData["USER_NAME"] === "System" && !rowData["S3_URL"]) {
            vm.downloadMessage = "System generated files are available in " + (vm.isState ? "TMEF_STATE_ENTITY_XML": "TMEF_ENTITY_XML")
                + " table, please contact Tax IT Manager to download those files.";
            vm.systemGeneratedRequest = true;
        }

        vm.downloadFile = function () {
            if (!rowData.S3_URL) {
                AlertService.add('warning', 'Something went wrong while downloading!', 4000);
                return;
            }
            vm.downloading = true;
            NewProformaFactory.downloadS3(rowData.S3_URL).then(response => {
                var a = document.createElement("a");
                a.href = response.data.presignedS3Url;
                let url = response.data.presignedS3Url.split("?")[0].split("/");
                a.download = url[url.length - 1];
                a.target = "_blank";
                a.click();
                $uibModalInstance.close();
            })
                .catch((err) => {
                    console.log(err.toString());
                });
        }
    }

    function viewBulkSelectionCtrl($rootScope, NewProformaFactory, rowData, $uibModalInstance, USER_SETTINGS) {
        var vm = this;

        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });

        vm.formsFiltered = [];
        vm.options = {
            extraOptions: {
                componentSettings: {
                    rowGrouping: true,
                    rowGroupingBy: 'ENTITY',
                    tableOptions: {
                        searching: true,
                        ordering: true,
                        columns: [
                            {
                                data: 'ENTITY', title: 'Entity', rowGrouping: true,
                                visible: false
                            },
                            {
                                data: 'FORM_INDICATOR', title: 'Form',
                                render: function (data) {
                                    let result = data.includes('-->') ? data.split('-->')[0]: "An error has occured while processing the entity.";
                                    return result;
                                }
                            },
                            {
                                data: 'FORM_INDICATOR', title: 'PDF Status', className: 'text-center',
                                render: function (data) {
                                    let message = data.split('-->')[1];
                                    let lastStatus = message === 'success';
                                    let status = message ? message.split('EOF').filter(i => i.includes("PDF"))[0]: "failed";
                                    if ((status && status.includes("success")) || lastStatus)
                                        return '<i class="fa fa-check color-green"></i><span class="hidden">Success/Completed</span>';
                                    else if (status && (status.includes("failed") || status.includes("Exception")))
                                        return '<i class="fa fa-times color-red"></i><span class="hidden">Failed</span>';
                                    else
                                        return '<i class="fa fa-minus color-grey"></i><span class="hidden">Not generated</span>';
                                }
                            },
                            {
                                data: 'FORM_INDICATOR', title: 'XML Status', className: 'text-center',
                                render: function (data) {
                                    let message = data.split('-->')[1];
                                    let lastStatus = message === 'success' && rowData.FORMAT.includes("XML");
                                    let status = message ? message.split('EOF').filter(i => i.includes("XML"))[0]: "failed";
                                    if ((status && status.includes("success")) || lastStatus)
                                        return '<i class="fa fa-check color-green"></i><span class="hidden">Success/Completed</span>';
                                    else if (status && (status.includes("failed") || status.includes("Exception")))
                                        return '<i class="fa fa-times color-red"></i><span class="hidden">Failed</span>';
                                    else
                                        return '<i class="fa fa-minus color-grey"></i><span class="hidden">Not generated</span>';
                                }
                            }
                        ],
                        language: {
                            emptyTable: "No Forms were found/generated for selected entities.",
                        },
                        initCallBack: () => {
                            vm.formsLoading = false;
                        }
                    }
                }
            }
        }

        function loadDetails() {
            vm.formsLoading = vm.dataLoading = true;
            NewProformaFactory.getRequestDetails(rowData.REQUEST_ID).then(function (response) {
                vm.formsFiltered = response.data.jsonObject;
                vm.options.data = response.data.jsonObject;
                vm.dataLoading = false;
            })
        }

        loadDetails();

    }

    function retryFailedEntitiesController($rootScope, NewProformaEfileViewFactory, gridData, $uibModalInstance, USER_SETTINGS, NewProformaFactory, AlertService) {
        var vm = this;
        let rowData = gridData[gridData.length-1].data;
        vm.status = rowData.STATUS;

        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });

        // if(!(rowData.REQUESTED_BY.startsWith("800") && rowData["USER_NAME"] === "System")){
        //     vm.userInitiatedRequest = true;
        // }
        // else
         if (rowData.STATUS === 'In Progress') {
            
        }
        else{
            vm.userInitiatedRequest = false;
            vm.loading = true;
            NewProformaEfileViewFactory.getFailedEntities(rowData.TAX_YEAR,rowData.REQUEST_ID).then(function(response) {
                vm.loading = false;
                vm.entities = response.data.entities;
            })
        }

        vm.retryBulkExport = () =>{
            vm.loading = true;
            let payload = {
                entities: "All",
                forms: "All"
            }
            NewProformaFactory.pdfsBulkExport(
                rowData.TAX_YEAR,
                rowData.SCENARIO,
                rowData.TAX_PAYER || '',
                rowData.REQUEST_TYPE,
                rowData.FORMAT,
                (rowData.CONTENT_TYPE === "STATE")? "STATE" : 'EFILE',
                rowData.RETURN_TYPE,
                rowData.BUSINESS_TYPE,
                rowData.JCD_KEY,
                rowData.GROUP_KEY || '',
                rowData.REQUEST_ID,
                rowData.REQUESTED_BY,
                (rowData.CONTENT_TYPE === "STATE")? rowData.GROUP_NAME: rowData.TAX_PAYER,
                payload
            ).then((response) => {
                AlertService.add('success', 'Your Bulk Export Request has been Initiated!', 4000);
                console.log(response);
            })
            .catch((err) => {
                AlertService.add('warning', 'Something went wrong while sending Bulk Request!');
                console.log(err.toString());
            })
            .finally(() => {
                vm.loading = false;
                $rootScope.$broadcast('dataFilters:refreshGrid', {
                    "refresh": true
                });
                $uibModalInstance.close();
            });
        }
    }

    return controllers;
});
