
define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.ExpConsolService',[])

    .factory('ExpConsolFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG',  
    	function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
    	
        var expConsolFactory = {};
        expConsolFactory.url = {};
        expConsolFactory.url.exp_consol_save = GENERAL_CONFIG.base_url + "/saveJsonAsPivotObject?action_code=nc60wm";       
          
        expConsolFactory.savePivotedData = function(_settings, _data){
        		var params = {};
        		var jsonObj = JSON.stringify(_data);
                var jsonSettings =  JSON.stringify(_settings);
              
        		params = _.merge({},  GlobalService.globalParams , params);
        		params = _.extend({clob_data:jsonObj}, params);
        		params = _.extend({clob_settings:jsonSettings}, params);		  
        		
        		console.log("expConsolFactory......params::",params);        
        		
        		var promise = $http({
                    method: "post",
                    url: expConsolFactory.url.exp_consol_save,
                    data: params
                }).then(function (response) {
                    var status = response.status;
                    return response;
                });
        		
        	
        	
        	return promise;
        } 
        
        return expConsolFactory;
    }])

    return services;

});