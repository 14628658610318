define([
    'moment',
    'angular'
], function (moment) {
    'use strict';

    var services = angular.module('app.entityClassificationActionsService',[])
        .factory("entityClassificationActionsService", ['$q', '$http', '$stateParams', '$timeout', '$rootScope', '$log', 'AlertService', '$injector', 'GlobalService','DataFiltersFactory','workspaceFactory', 'GENERAL_CONFIG','JsonObjectFactory', 
            function($q, $http, $stateParams, $timeout, $rootScope, $log, AlertService, $injector, GlobalService, DataFiltersFactory, workspaceFactory, GENERAL_CONFIG,JsonObjectFactory) {

                var entityClassificationActionsService = {};

                var URLS = {
                    GET_ONLY_ENTITY: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32525",
                    GET_ENTITY_DETAILS: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32023",
                    GET_CLASSIFICATION: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32071",
                    TAX_CONSOLE_GROUP: GENERAL_CONFIG.base_url + "/loadJsonObject?action_id=32433",
                    FETCH: GENERAL_CONFIG.base_url + '/getScheduleDadj',
                    UPDATE: GENERAL_CONFIG.base_url + '/saveScheduleDadj',

                }

                let filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};

                entityClassificationActionsService.loadAllEntities = function(_data, _details){
                    var  params = {
                        action_code: 'p15w5t', 
                    };

                    return JsonObjectFactory.getJSONObj(URLS.GET_ONLY_ENTITY,params).then((response)=>{
                        if(response.callSuccess === "1" ){
                            console.log(response);
                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retriveclassifications.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve classifications. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityClassificationActionsService.loadAllOverrideEntities = function(_data, _details){
                    var  params = {
                                   action_code: 'lvcm1n', 
                                };

                    return JsonObjectFactory.getJSONObj(URLS.GET_ENTITY_DETAILS,params).then((response)=>{
                        if(response.callSuccess === "1" ){

                            let arr = response.jsonObject.map(elem => {
                                if(elem.START_DATE)
                                    elem.START_DATE = moment(elem.START_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.END_DATE)
                                    elem.END_DATE = moment(elem.END_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.GOLD_START_DATE)
                                    elem.GOLD_START_DATE = moment(elem.GOLD_START_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.GOLD_END_DATE)
                                    elem.GOLD_END_DATE = moment(elem.GOLD_END_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.ACQUISITION_DATE)
                                    elem.ACQUISITION_DATE = moment(elem.ACQUISITION_DATE,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_INCORPORATED)
                                    elem.DATE_INCORPORATED = moment(elem.DATE_INCORPORATED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_MERGED)
                                    elem.DATE_MERGED = moment(elem.DATE_MERGED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_DISSOLVED)
                                    elem.DATE_DISSOLVED = moment(elem.DATE_DISSOLVED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_SOLD)
                                    elem.DATE_SOLD = moment(elem.DATE_SOLD,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_SPAWNED_CONSUMED)
                                    elem.DATE_SPAWNED_CONSUMED = moment(elem.DATE_SPAWNED_CONSUMED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.DATE_LIQUIDATED)
                                    elem.DATE_LIQUIDATED = moment(elem.DATE_LIQUIDATED,'YYYY-MM-DD').format('MM/DD/YYYY');
                                if(elem.RETURN_PREPARED_BY != null && elem.RETURN_PREPARED_BY != undefined)
                                    elem.RETURN_PREPARED_BY = elem.RETURN_PREPARED_BY.trim();
                                return elem;
                            });

                            return arr;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Override Entities.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Override Entities. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };
                
                entityClassificationActionsService.loadClassifications= function(_data, _details){
                    var  params = {
                                   action_code: 'cragj2', 
                                };

                    return JsonObjectFactory.getJSONObj(URLS.GET_CLASSIFICATION,params).then((response)=>{
                        if(response.callSuccess === "1" ){

                            console.log(response);

                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retriveclassifications.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve classifications. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityClassificationActionsService.loadTaxConsoleGroup= function(_data, _details){
                    var  params = {
                                   action_code: 'r7liui', 
                                };

                    return JsonObjectFactory.getJSONObj(URLS.TAX_CONSOLE_GROUP,params).then((response)=>{
                        if(response.callSuccess === "1" ){

                            console.log(response);

                            return response.jsonObject;
                        }else{
                            if(data.errorMessage === "no access"){
                                AlertService.add("error", "Sorry you do not have access to retrive Tax COnsole Group.", 4000);
                            }else{
                                AlertService.add("error", "Unalbe to retrieve Tax COnsole Group. If you continue to experience this issue please visit the help page to contact support.", 4000);
                            }
                        }
                        return [];
                    })
                };

                entityClassificationActionsService.save =function(){

                };

                entityClassificationActionsService.formatDateFeilds=function(elem){
                                if(elem.START_DATE)
                                    elem.START_DATE = moment(elem.START_DATE).format('MM/DD/YYYY');
                                if(elem.END_DATE)
                                    elem.END_DATE = moment(elem.END_DATE).format('MM/DD/YYYY');
                                if(elem.GOLD_START_DATE)
                                    elem.GOLD_START_DATE = moment(elem.GOLD_START_DATE).format('MM/DD/YYYY');
                                if(elem.GOLD_END_DATE)
                                    elem.GOLD_END_DATE = moment(elem.GOLD_END_DATE).format('MM/DD/YYYY');
                                if(elem.ACQUISITION_DATE)
                                    elem.ACQUISITION_DATE = moment(elem.ACQUISITION_DATE).format('MM/DD/YYYY');
                                if(elem.DATE_INCORPORATED)
                                    elem.DATE_INCORPORATED = moment(elem.DATE_INCORPORATED).format('MM/DD/YYYY');
                                if(elem.DATE_MERGED)
                                    elem.DATE_MERGED = moment(elem.DATE_MERGED).format('MM/DD/YYYY');
                                if(elem.DATE_DISSOLVED)
                                    elem.DATE_DISSOLVED = moment(elem.DATE_DISSOLVED).format('MM/DD/YYYY');
                                if(elem.DATE_SOLD)
                                    elem.DATE_SOLD = moment(elem.DATE_SOLD).format('MM/DD/YYYY');
                                if(elem.DATE_SPAWNED_CONSUMED)
                                    elem.DATE_SPAWNED_CONSUMED = moment(elem.DATE_SPAWNED_CONSUMED).format('MM/DD/YYYY');
                                if(elem.DATE_LIQUIDATED)
                                    elem.DATE_LIQUIDATED = moment(elem.DATE_LIQUIDATED).format('MM/DD/YYYY');
                        return elem;                                    

                }

                return entityClassificationActionsService;

        }]);

    return services;

});